import { BlTable } from '@blacklake-web/component';
import { FetchProgressReportListAdjustRecordResponse } from 'src/api/ytt/mfg-domain/productionTask';
import { formatAdjustmentHistoryToTableData } from 'src/page/productionPlanning/progressReport/helper/utils';

interface Props {
  dataSource: FetchProgressReportListAdjustRecordResponse['data'] | undefined;
}

const AdjustmentHistoryTable = (props: Props) => {
  // 对于内容跨行，最新 antd 4.21.2 中是使用 onCell 函数，这里的写法是网上搜到的
  const { tableColumns, tableDataSource } = formatAdjustmentHistoryToTableData(props.dataSource);

  return <BlTable columns={tableColumns} dataSource={tableDataSource} bordered />;
};

export default AdjustmentHistoryTable;
