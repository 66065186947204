export const toDetail = (id: number) => {
  return `/resource/energyMeter/standingBook/${id}/detail`;
};

export const goToDetail = (id: number) => {
  window.reactRouterHistoryInstance.push(toDetail(id));
};

export const goToCreate = () => {
  window.reactRouterHistoryInstance.push('/resource/energyMeter/standingBook/create');
};

export const goToEdit = (id: number) => {
  window.reactRouterHistoryInstance.push(`/resource/energyMeter/standingBook/${id}/edit`);
};

export const goToCopy = (id: number) => {
  window.reactRouterHistoryInstance.push(`/resource/energyMeter/standingBook/${id}/copy`);
};

export const goToList = () => {
  window.reactRouterHistoryInstance.push('/resource/energyMeter/standingBook');
};
