import { RecordListLayout } from 'layout';
import { useState } from 'react';
import { DeliveryScheduleNoteListMaterialItem } from '../interface';
import { BlIcon, LinkTooltip, SingleOperationLogComponent } from 'src/components';
import { toEdit, toDetail, toCreate } from '../navigate';
import { fetchDeliveryScheduleNoteListMaterial } from 'src/api/ytt/order-domain/deliveryScheduleNote';
import { dataColumnsForMaterialLineList, materialsFilterList } from './columns';
import { handleDistribute, handleClosed, endOrder } from '../operate';
import _ from 'lodash';
import authDict from 'src/utils/auth';
import { gcObject } from 'utils';
import { useDispatch } from 'react-redux';
import lookup from 'src/dict';
import { Modal } from 'antd';
import { CRUD } from 'src/dict/common';
import { DeliveryScheduleNoteExecStatus } from 'src/dict/purchase';
import { useHistory } from 'react-router-dom';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { formatDateTimeRangeToUnix } from 'src/utils/formatters/dateTime';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

const MaterialsLineList = () => {
  const history = useHistory();
  const openModal = useOpenExImportModal();
  // 选中行的key，用以批量操作
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectedRow, setSelectRow] = useState<any[]>([]);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<number>();
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const refresh = () => {
    setRefreshMarker(Date.now());
  };
  const dispatch = useDispatch();
  const dataColumns = [
    {
      title: '编号',
      dataIndex: 'code',
      width: 150,
      sorter: true,
      render: (
        code: string,
        record: DeliveryScheduleNoteListMaterialItem,
        index: number,
        config: any,
      ) => {
        return (
          <LinkTooltip
            text={code}
            to={toDetail(record.deliveryScheduleNoteId as number)}
            width={config.contentWidth}
            auth={authDict.deliveryScheduleNote_detail}
          />
        );
      },
    },
    ...dataColumnsForMaterialLineList,
  ];
  const mainMenu = [
    {
      title: '导出',
      auth: authDict.deliveryScheduleNote_export,
      icon: <BlIcon type="icondaochu" />,
      showExport: true,
      onClick: () =>
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.deliveryScheduleNote,
        }),
    },
    {
      title: '新建交货计划单',
      auth: authDict.deliveryScheduleNote_add,
      showExport: true,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        history.push(toCreate());
      },
      items: [
        {
          title: '导入',
          auth: authDict.deliveryScheduleNote_import,
          showExport: true,
          onClick: () =>
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.deliveryScheduleNote,
            }),
        },
      ],
    },
  ];
  const getOperationList = (record: DeliveryScheduleNoteListMaterialItem) => {
    return _.compact([
      {
        title: lookup('crud', CRUD.view),
        auth: authDict.deliveryScheduleNote_detail,
        onClick: () => {
          history.push(toDetail(record.deliveryScheduleNoteId!));
        },
      },
      {
        title: lookup('crud', CRUD.edit),
        auth: authDict.deliveryScheduleNote_edit,
        disabled:
          record.execStatus === DeliveryScheduleNoteExecStatus.finished ||
          record.execStatus === DeliveryScheduleNoteExecStatus.closed,
        onClick: () => {
          history.push(toEdit(record.deliveryScheduleNoteId as number));
        },
      },
      {
        title: lookup('crud', CRUD.issued),
        auth: authDict.deliveryScheduleNote_dispatch,
        disabled:
          record.execStatus !== DeliveryScheduleNoteExecStatus.created &&
          record.execStatus !== DeliveryScheduleNoteExecStatus.rejected,
        onClick: () => {
          handleDistribute([record], [record.deliveryScheduleNoteId!], refresh);
        },
      },
      {
        title: '结束',
        auth: authDict.deliveryScheduleNote_finish,
        disabled:
          record.execStatus !== DeliveryScheduleNoteExecStatus.undetermined &&
          record.execStatus !== DeliveryScheduleNoteExecStatus.executing,
        onClick: () => {
          endOrder([record], [record.deliveryScheduleNoteId!], [record.code!], 'single', refresh);
        },
      },
      {
        title: '关闭',
        auth: authDict.deliveryScheduleNote_close,
        disabled:
          record.execStatus !== DeliveryScheduleNoteExecStatus.created &&
          record.execStatus !== DeliveryScheduleNoteExecStatus.rejected,
        onClick: () => {
          Modal.confirm({
            title: '关闭交货计划单',
            content: `关闭交货计划单后不能再进行任何操作，确认关闭<${record.code}>吗？`,
            okText: '关闭',
            cancelText: '暂不关闭',
            onOk: async () => {
              await handleClosed([record], [record.deliveryScheduleNoteId!], refresh);
            },
          });
        },
      },
      {
        title: '操作记录',
        auth: authDict.deliveryScheduleNote_operrecord,
        onClick: () => {
          setCurrentId(record.deliveryScheduleNoteId);
          setVisibleLog(true);
        },
      },
    ]);
  };
  const batchMenu = [
    {
      title: '下发',
      auth: authDict.deliveryScheduleNote_dispatch,
      onClick: (onSuccess: any, onFail: any) => {
        handleDistribute(
          selectedRow,
          _.map(selectedRow, 'deliveryScheduleNoteId'),
          onSuccess,
          onFail,
          refresh,
        );
      },
    },
    {
      title: '结束',
      auth: authDict.deliveryScheduleNote_finish,
      onClick: (onSuccess: any, onFail: any) => {
        endOrder(
          selectedRow,
          _.map(selectedRow, 'deliveryScheduleNoteId'),
          _.map(selectedRow, 'code'),
          'multiple',
          () => {
            onSuccess?.();
            refresh();
          },
          onFail,
        );
      },
    },
    {
      title: '关闭',
      auth: authDict.deliveryScheduleNote_close,
      onClick: (onSuccess: any, onFail: any) => {
        Modal.confirm({
          title: '关闭交货计划单',
          content: '关闭交货计划单后不能再进行任何操作，确认关闭吗？',
          okText: '关闭',
          cancelText: '暂不关闭',
          onOk: async () => {
            await handleClosed(
              selectedRow,
              _.map(selectedRow, 'deliveryScheduleNoteId'),
              onSuccess,
              onFail,
              refresh,
            );
          },
          onCancel: async () => {
            await onFail();
          },
        });
      },
    },
  ];
  const formatDataToQuery = (params: any) => {
    const {
      createdAt,
      updatedAt,
      supplier,
      demandTime,
      creator,
      material,
      operator,
      owner,
      purchaseOrderIds,
      ...rest
    } = params;
    const options = {
      ...formatDateTimeRangeToUnix('createdAtFrom', 'createdAtTo', createdAt),
      ...formatDateTimeRangeToUnix('updatedAtFrom', 'updatedAtTo', updatedAt),
      ...formatDateTimeRangeToUnix('demandTimeFrom', 'demandTimeTo', demandTime),
      creatorIds: _.isEmpty(creator) ? null : _.map(creator, 'value'),
      operatorIds: _.isEmpty(operator) ? null : _.map(operator, 'value'),
      ownerIds: _.isEmpty(owner) ? null : _.map(owner, 'value'),
      materialIds: _.isEmpty(material) ? null : _.map(material, 'value'),
      purchaseOrderIds: _.isEmpty(purchaseOrderIds) ? null : _.map(purchaseOrderIds, 'value'),
      supplierIds: supplier?.map((item: any) => item.key),
      ...rest,
    };

    dispatch.excelImport.updateBusinessData({
      businessData: gcObject.filterEmptyProperty(options),
    });

    return gcObject.filterEmptyProperty(options);
  };
  const formatDataToFormDisplay = (data: any) => {
    const { createdAt = [], updatedAt = [], demandTime = [], ...resData } = _.cloneDeep(data);

    return {
      ...resData,
      createdAt: momentData(createdAt),
      updatedAt: momentData(updatedAt),
      demandTime: momentData(demandTime),
    };
  };

  return (
    <>
      <RecordListLayout
        requestFn={fetchDeliveryScheduleNoteListMaterial}
        columns={dataColumns}
        rowKey="id"
        batchMenu={batchMenu}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectedKeys: any, row) => {
          setSelectedRowKeys(selectedKeys);
          if (row) {
            setSelectRow(row);
          }
        }}
        placeholder="请输入编号、供应商、物料"
        useIndex={false}
        filterList={materialsFilterList}
        getOperationList={getOperationList}
        mainMenu={mainMenu}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        refreshMarker={refreshMarker}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={currentId as number}
          objectCode={OBJECT_OF_CODE.deliveryScheduleNote}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default MaterialsLineList;
