import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import { Form } from 'antd';
import type { FormInstance } from 'antd';

import { FormListProps } from 'antd/lib/form';
import { remove, cloneDeep } from 'lodash';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import BlSortFormList from 'src/components/sortList/BlSortFormList';
import UserSelectModal from 'src/page/organization/components/userAndDepartmentSelect/index';
import { replaceSign } from 'src/utils/constants';
import { arrayIsEmpty } from 'src/utils/array';

interface Props {
  form: FormInstance;
  filedName: FormListProps['name'];
}

const UserTable: FC<Props> = (props) => {
  const { form: formInst, filedName } = props;

  const baseFiledName: any = !arrayIsEmpty(filedName) ? filedName : [filedName];

  const [showSelectModal, setShowSelectModal] = useState<boolean>(false);
  const [choosedValue, setChoosedValue] = useState<any>();

  useEffect(() => {
    const list = formInst.getFieldValue(filedName);

    setChoosedValue(list);
  }, [props]);

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '序号',
        dataIndex: 'lineNos',
        render: (text: string, field) => {
          return (
            <Form.Item
              fieldKey={[field.fieldKey, 'lineNos']}
              name={(field.name, 'lineNos')}
              style={{ marginBottom: '0' }}
            >
              {field.name}
            </Form.Item>
          );
        },
      },
      {
        title: '用户编号',
        dataIndex: 'code',
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'code']}
              fieldKey={[field.fieldKey, 'code']}
              style={{ marginBottom: '0' }}
            >
              {formInst.getFieldValue([...baseFiledName, field.name, 'code']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '用户名称',
        dataIndex: 'name',
        render: (text: string, field) => {
          return (
            <Form.Item
              fieldKey={[field.fieldKey, 'name']}
              name={[field.name, 'name']}
              style={{ width: '100%', marginBottom: '0' }}
            >
              {formInst.getFieldValue([...baseFiledName, field.name, 'name']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
    ];
  };

  return (
    <>
      <BlSortFormList
        form={formInst}
        name={filedName as string}
        renderColumns={() => getColumns()}
        isNeedDrag={false}
        handleAdd={() => {
          setShowSelectModal(true);
        }}
        buttonText={'批量添加'}
        onDelete={(index) => {
          const choosedValueCopy = cloneDeep(choosedValue);

          remove(choosedValueCopy, (i, n) => {
            return index.find((e) => e === n);
          });
          setChoosedValue(choosedValueCopy);
        }}
      />
      {showSelectModal && (
        <UserSelectModal
          isMultiple
          isSelectUser
          visible={showSelectModal}
          onClose={() => setShowSelectModal(false)}
          submitData={(val: any) => {
            setChoosedValue(val);
            formInst.setFields([
              {
                name: filedName,
                value: val,
              },
            ]);
          }}
          choosedData={choosedValue}
        />
      )}
    </>
  );
};

export default UserTable;
