import React, { useEffect, useState } from 'react';
import { DataFormLayoutForModal, DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Tabs, Form, Checkbox, message, Modal } from 'antd';
import _ from 'lodash';
import * as fieldForm from './fieldFormItems';
import { fetchCustomFieldCreateCustomField } from 'src/api/ytt/metadata-domain/custom_field';
import { appEnum, appDict } from 'src/dict';
import { FormChoiseValue, handleAssemblyChoiceValueList } from './utilsFun';
import { FieldType, ObjectCategory } from 'src/dict/customField';
import modal from 'antd/lib/modal';
import { YN } from 'src/dict/common';

interface createProps {
  visible: Boolean;
  onCancel?: () => void;
  objectId: number | string;
  refreshTable: () => void;
  objectCategory: number;
  objectName: string;
  canSetIsRequired?: boolean;
}

function CreateComponent(props: createProps) {
  const { visible, objectCategory, objectName, canSetIsRequired, onCancel, refreshTable } = props;
  const objectId = Number(props.objectId);
  const [activeKey, setactiveKey] = useState<string>(String(appEnum.CustomField.FieldType.text));
  const [keepOn, sekeepOn] = useState(true);
  const [modalForm] = Form.useForm();
  const [formChoiseValue, setFormChoiseValue] = useState<FormChoiseValue[]>([]);
  const [isResetFormOption, setIsResetFormOption] = useState(-1);
  const [refreshInfoMaker, setRefreshInfoMaker] = useState<number>();
  const refresh = () => setRefreshInfoMaker(new Date().getTime());
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [enableNumberRule, setEnableNumberRule] = useState(false);

  useEffect(() => {
    setEnableNumberRule(false);
  }, [visible]);

  const TabChangeCallback = (key: number) => {
    switch (key) {
      case FieldType.text: {
        return fieldForm.singleLine({
          form: modalForm,
          refresh,
          objectId,
          enableNumberRule,
          setEnableNumberRule,
          isCustomObject: objectCategory === ObjectCategory.customObject,
        });
      }
      case FieldType.textArea: {
        return fieldForm.multiLine({ form: modalForm });
      }
      case FieldType.select: {
        return fieldForm.singleChoice({
          tab: 'single',
          name: 'singleOptionList',
          form: modalForm,
          data: formChoiseValue,
          type: 'create',
          isResetFormOption,
          canSetIsRequired,
        });
      }
      case FieldType.multiSelect: {
        return fieldForm.multiChoice({
          tab: 'multiple',
          name: 'mutipleOptionList',
          form: modalForm,
          data: formChoiseValue,
          type: 'create',
          isResetFormOption,
          canSetIsRequired,
        });
      }
      case FieldType.boolean: {
        return fieldForm.boolItems({ form: modalForm });
      }
      case FieldType.number: {
        return fieldForm.numItems({ form: modalForm });
      }
      case FieldType.integer: {
        return fieldForm.intItems({ form: modalForm });
      }
      case FieldType.date: {
        return fieldForm.dateItems({ form: modalForm });
      }
      case FieldType.url:
        return fieldForm.linkItems({ form: modalForm });
      case FieldType.reference: {
        return fieldForm.quoteItems({
          form: modalForm,
          data: {
            objectId,
            isRefer: 1,
          },
        });
      }
      case FieldType.relation: {
        return fieldForm.relationItems({ form: modalForm });
      }
      case FieldType.subordinate: {
        return fieldForm.subordinateItems({
          form: modalForm,
          objectName,
        });
      }
      case FieldType.appendix: {
        return fieldForm.appendixItems();
      }
      default:
        return [];
    }
  };
  const referObjectInfo = (key: number) => {
    if (key === appEnum.CustomField.FieldType.relation) {
      return fieldForm.referObjectItems({
        form: modalForm,
        isNeedType: true,
        objectParams:
          objectCategory === ObjectCategory.preObject
            ? { objectCategory: ObjectCategory.customObject }
            : null,
      });
    }
    if (key === appEnum.CustomField.FieldType.subordinate) {
      return fieldForm.referObjectItems({
        form: modalForm,
      });
    }
    return [];
  };
  // 通过activeKey的值切换表单内容
  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    column: 1,
    items: [
      ...referObjectInfo(Number(activeKey)),
      ...fieldForm.baseItems({ form: modalForm }),
      ...TabChangeCallback(Number(activeKey)),
    ],
  };

  const renderLeftContext = () => {
    const customFieldsType = appDict.customField.fieldType.filter((i: { value: any }) => {
      if (objectCategory === ObjectCategory.preObject) {
        return (
          i.value !== appEnum.CustomField.FieldType.relationSub &&
          i.value !== appEnum.CustomField.FieldType.subordinate
        );
      }
      return i.value !== appEnum.CustomField.FieldType.relationSub;
    });

    return (
      <Tabs
        onTabClick={async (index) => {
          setactiveKey(index);
          refresh();
          modalForm.resetFields();
        }}
        activeKey={activeKey}
        tabPosition={'left'}
        destroyInactiveTabPane
      >
        {customFieldsType.map(
          (item: {
            label:
              | boolean
              | React.ReactChild
              | React.ReactFragment
              | React.ReactPortal
              | null
              | undefined;
            value: any;
          }) => (
            <Tabs.TabPane tab={item.label} key={String(item.value)} />
          ),
        )}
      </Tabs>
    );
  };

  // 预处理字段数据，返回要提交的数据
  const preProcess = (value: any) => {
    const { reference, singleOptionList, mutipleOptionList, defaultOption, numberRuleId, ...rest } =
      value;
    const formData = {
      relatedObjectId: Number(objectId),
      fieldCategory: appEnum.CustomField.FieldCategory.customFields,
      fieldType: activeKey,
      isNumberRuleConfig: value.isNumberRuleConfig, // 是否配置编号规则，自定义字段默认为是，也就是1
      numberRuleId: -1, // 选择编号规则，默认为-1
      ...rest,
    };

    if (Number(activeKey) === appEnum.CustomField.FieldType.select) {
      formData.choiceValueList = handleAssemblyChoiceValueList(
        singleOptionList,
        [defaultOption],
        appEnum.CustomField.FieldType.select,
      );
    }
    if (Number(activeKey) === appEnum.CustomField.FieldType.multiSelect) {
      formData.choiceValueList = handleAssemblyChoiceValueList(
        mutipleOptionList,
        [defaultOption],
        appEnum.CustomField.FieldType.multiSelect,
      );
    }

    if (Number(activeKey) === appEnum.CustomField.FieldType.reference) {
      formData.referenceChain = value.referenceChain.join('#');
    }

    if ('datetimeFormat' in value && formData.defaultValue) {
      formData.defaultValue = formData.defaultValue.format(value.datetimeFormat);
    }
    formData.reference = reference?.value;

    if (numberRuleId) {
      formData.numberRuleId = numberRuleId.value;
    }
    return formData;
  };

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();

      if (Number(activeKey) === appEnum.CustomField.FieldType.select) {
        if (!value.singleOptionList) {
          message.error('选项信息中选项至少添加一个');
          throw new Error('');
        }
        if (value.singleOptionList.length > 1000) {
          message.error(
            `选项信息中选项不可超过1000个,已超过${value.singleOptionList.length - 1000}`,
          );
          throw new Error('');
        }
      }
      if (Number(activeKey) === appEnum.CustomField.FieldType.multiSelect) {
        if (!value.mutipleOptionList) {
          message.error('选项信息中选项至少添加一个');
          throw new Error('');
        }
        if (value.mutipleOptionList.length > 1000) {
          message.error(
            `选项信息中选项不可超过1000个,已超过${value.singleOptionList.length - 1000}`,
          );
          throw new Error('');
        }
      }

      if (Number(activeKey) === appEnum.CustomField.FieldType.reference) {
        if (value.referenceChain.length <= 1) {
          message.error('请选择字段');
          throw new Error('');
        }
      }
      setSubmitting(true);
      try {
        const res = await fetchCustomFieldCreateCustomField(preProcess(value));

        if (res && res.code === 200 && res.subCode !== '403') {
          message.success('创建成功！');
          refreshTable();
          setEnableNumberRule(false);
          // 判断是否连续新建
          if (keepOn) {
            modalForm?.resetFields();
            setIsResetFormOption(new Date().getTime());
            return;
          }
          onCancel && onCancel();
        } else {
          modal.warning({
            title: '提示',
            content: <>{res.message}</>,
            okText: '确定',
          });
          setSubmitting(false);
        }
      } finally {
        setSubmitting(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      width={810}
      onClose={onCancel}
      content={
        <DataFormLayout
          form={modalForm}
          formProps={{
            preserve: false,
            onValuesChange: (changedValues) => {
              if (
                _.isNumber(changedValues?.isRequired) &&
                changedValues?.isRequired === appEnum.Common.YN.yes &&
                !canSetIsRequired
              ) {
                Modal.warning({
                  title: '此对象暂时无法设置必填的自定义字段',
                  okText: '确定',
                  onOk: () => {
                    modalForm.setFieldsValue({ isRequired: appEnum.Common.YN.no });
                  },
                });
              }
            },
          }}
          title="新建字段"
          leftContext={renderLeftContext()}
          extra={
            <Checkbox
              onChange={() => {
                sekeepOn(!keepOn);
              }}
              defaultChecked={keepOn}
            >
              连续新建
            </Checkbox>
          }
          info={[baseInfo]}
          onCancel={onCancel}
          onFinish={handleFinish}
          confirmLoading={submitting}
          bodyStyle={{ height: document.body.clientHeight - 210 }}
        />
      }
    />
  );
}

export default CreateComponent;
