import { useEffect, useState, useContext, useRef } from 'react';
import { Select, Alert, Empty, Button } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import _ from 'lodash';

import { BlIcon } from 'src/components';
import {
  fetchResourceMonitorDynamicList,
  FetchResourceMonitorDynamicListResponse,
} from 'src/api/ytt/resource-domain/resourceMonitor';
import { ResourceIdContext } from '../../../constants';
import { MonitorDataType, MonitorParamInfoType } from '../../constants';
import ParamChart from './ParamChart';
import Styles from '../index.module.scss';

const REFRESH_INTERVAL = 1000 * 60;

const formatDataToDisplay = (responseData: ApiResponseData) => {
  if (responseData) {
    const { monitorData, monitorIndexes, ...rest } = responseData;
    const viewOptions =
      monitorIndexes?.map((monitorIndex) => ({
        label: monitorIndex.baseLineName!,
        value: monitorIndex.baseLineId!,
      })) || [];
    const formattedMonitorData: MonitorDataType = {};

    monitorData?.forEach((monitorPoint) => {
      const { baseLines, time, val, recordTime } = monitorPoint;

      baseLines?.forEach((baseLine) => {
        const { baseLineId, ...rest } = baseLine;
        const corespondBaseLine = _.find(monitorIndexes, { baseLineId });
        const baseLineType = corespondBaseLine?.baseLineType;

        if (formattedMonitorData[baseLineId!]) {
          formattedMonitorData[baseLineId!].push({
            time: time!,
            val: val!,
            baseLineType: baseLineType!,
            recordTime,
            ...rest,
          });
        } else {
          formattedMonitorData[baseLineId!] = [
            {
              time: time!,
              val: val!,
              baseLineType: baseLineType!,
              recordTime,
              ...rest,
            },
          ];
        }
      });
    });

    return {
      viewOptions,
      monitorData: formattedMonitorData,
      paramInfo: { ...rest },
    };
  }
  return { viewOptions: undefined, monitorData: undefined };
};

type ApiResponseData = FetchResourceMonitorDynamicListResponse['data'];

export default function ParamCard({
  paramId,
  refreshMarker,
  strechChart,
  fullScreen,
  onlyChild,
}: {
  paramId: number;
  refreshMarker: number;
  strechChart: (id: number) => void;
  fullScreen: boolean;
  onlyChild: boolean;
}) {
  const [monitorData, setMonitorData] = useState<MonitorDataType>();
  const [chartParamInfo, setParamInfo] = useState<MonitorParamInfoType>();
  const [viewOptions, setViewOptions] = useState<LabeledValue[]>([]);
  const [selectedView, setSelectedView] = useState<LabeledValue>();
  const [showWarning, setShowWarning] = useState(false);
  const [showSwitchView, setShowSwitchView] = useState(false);
  const { resourceId } = useContext(ResourceIdContext);
  const intervalRef = useRef<number>();
  const popupContainerRef = useRef<HTMLDivElement | null>(null);

  /**
   * 接口返回的新数据里不包括当前视图时，为了保留当前视图的数据，不能把整个监控数据替换掉
   * 需要保留正在显示的那一条
   * @param apiMonitorData 接口返回的新数据
   */
  const updateMonitorData = (apiMonitorData: MonitorDataType) => {
    if (!monitorData) {
      setMonitorData(apiMonitorData);
    } else {
      setMonitorData({
        ...apiMonitorData,
        [selectedView!.value]: monitorData[selectedView!.value],
      });
    }
  };

  const loadMonitorData = async (paramId: number) => {
    const response = await fetchResourceMonitorDynamicList({ deviceId: resourceId!, paramId });
    // const response = await fetchResourceMonitorDynamicList({
    //   deviceId: 1648036372940003,
    //   paramId: 1650355713133020,
    // });
    const responseData = response?.data;
    // const responseData = mockData.data;
    const {
      viewOptions,
      monitorData: apiMonitorData,
      paramInfo,
    } = formatDataToDisplay(responseData);

    setViewOptions(viewOptions || []);
    // 参数信息可能发生变更
    setParamInfo(paramInfo);

    if (_.isNil(selectedView)) {
      // 如果之前没有被选中的视图，默认选中第一项
      setSelectedView(viewOptions?.[0]);
    } else if (viewOptions?.every((option) => option.value !== selectedView.value)) {
      setShowWarning(true);
      updateMonitorData(apiMonitorData);
      return;
    }

    setShowWarning(false);
    setMonitorData(apiMonitorData);
  };

  // 选中视图/参数信息发生改变以后，需要重新绑定interval的load函数，否则会用闭包里的老selectedView而出错
  useEffect(() => {
    if (intervalRef.current && selectedView) {
      clearInterval(intervalRef.current);
      // 这里加window.只是为了让ts知道这里的setInverval是浏览器的setInverval
      // 否则它会认为这是NodeJS的setInterval，报类型错误
      intervalRef.current = window.setInterval(() => {
        loadMonitorData(paramId);
      }, REFRESH_INTERVAL);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [selectedView, chartParamInfo]);

  useEffect(() => {
    // 立刻加载一次数据
    loadMonitorData(paramId);
    // 开始计时，每分钟重新加载一次数据
    intervalRef.current = window.setInterval(() => {
      loadMonitorData(paramId);
    }, REFRESH_INTERVAL);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [paramId]);

  useEffect(() => {
    loadMonitorData(paramId);
  }, [refreshMarker]);

  return (
    <>
      <div
        className={Styles.paramCard}
        ref={popupContainerRef}
        onMouseLeave={() => {
          setShowSwitchView(false);
        }}
      >
        {/* <Spin spinning={!monitorData} /> */}
        {selectedView && monitorData && chartParamInfo ? (
          <>
            <ParamChart data={monitorData[selectedView?.value]} paramInfo={chartParamInfo} />
            <div className={Styles.iconBox}>
              {
                // 只有一个参数的时候不需要展开
                !onlyChild && (
                  <Button
                    className={Styles.stretchIcon}
                    size="small"
                    onClick={() => strechChart(paramId)}
                    icon={<BlIcon type={!fullScreen ? 'iconquanping' : 'iconquxiaoquanping'} />}
                  />
                )
              }

              <Button
                size="small"
                onClick={() => {
                  loadMonitorData(paramId);
                }}
                icon={<BlIcon className={Styles.refreshIcon} type="iconshuaxin" />}
              />
              <div className={Styles.switchViewBox}>
                {showSwitchView && (
                  <Select
                    style={{ minWidth: 100 }}
                    options={viewOptions}
                    labelInValue
                    value={selectedView}
                    getPopupContainer={() => popupContainerRef.current!}
                    size="small"
                    onChange={(selectedOption: LabeledValue) => {
                      setSelectedView(selectedOption);
                      setShowWarning(false);
                    }}
                    onSelect={() => {
                      setShowSwitchView(false);
                    }}
                  />
                )}
                <Button
                  size="small"
                  icon={
                    <BlIcon
                      className={Styles.refreshIcon}
                      onClick={() => {
                        setShowSwitchView(!showSwitchView);
                      }}
                      type="icona-xianxingqiehuan"
                    />
                  }
                />
              </div>
            </div>
          </>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {showWarning && (
          <Alert
            className={Styles.noViewWarning}
            message="该生产任务当前未在执行，可以切换查看其他视图"
            type="warning"
            showIcon
            closable
          />
        )}
      </div>
    </>
  );
}
