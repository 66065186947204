import React from 'react';
import { FormInstance } from 'antd';
import { BlCascader } from '../referCascader';
import _ from 'lodash';

interface Props {
  form: FormInstance;
  data: {
    objectId: number;
    isRefer?: number;
  };
  isEdit?: boolean;
}

export const quoteItems = (props: Props) => {
  return props.data?.isRefer
    ? [
        props.data?.isRefer && {
          label: '引用字段',
          name: 'referenceChain',
          rules: [{ required: true, message: '请选择引用字段' }],
          render: () => {
            return <BlCascader objectId={props.data.objectId} disabled={props.isEdit} />;
          },
        },
      ]
    : [];
};
