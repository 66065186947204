import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Button, message, Modal } from 'antd';
import { Cell, Dom, Edge, Graph, Model, Node, View } from '@antv/x6';
import dagreLayout from 'src/page/analyse/dataSetManagement/components/relationChart/basicLayout/layout';
import {
  getBasicConfig,
  baseEdgeInfo,
  getbaseNodeInfo,
} from 'src/page/analyse/dataSetManagement/components/relationChart/basicLayout/graphConfig';
import {
  fetchDataSetQueryAllObject,
  fetchDataSetDataSetObjectChoose,
} from 'src/api/ytt/e-report-domain/dataSet';
import SelectModal from './chooseModal';
import { RelationObject, RelationFieldInfo } from '../index.d';
import { BlIcon } from '@blacklake-web/component';
import lookup from 'src/dict';
import SearchList from './searchList';
import _ from 'lodash';
import insertCss from 'insert-css';

const Show = (props: {
  isEdit: boolean;
  saveData: (data: any) => Promise<any>;
  initialData: any;
  form: any;
}) => {
  const [editNode, setEditNode] = useState<Cell | null>(null);
  const [editEdge, setEditEdge] = useState<Edge | null>(null);
  const [graph, setGraph] = useState<Graph | null>(null);
  const [objectList, setObjectList] = useState<RelationObject[]>([]);
  const [parentObjectList, setParentObjectList] = useState<RelationObject[]>([]);
  const [childrenObjectList, setChildrenObjectList] = useState<RelationObject[]>([]);
  const [showSelectModel, setShowSelectModal] = useState<boolean>(false);
  const [isEditField, setIsEditField] = useState<boolean>(false);
  const [relationObject, setRelationObject] = useState<RelationObject | null>(null);

  const ref = React.useRef(null);
  const { isEdit, initialData, saveData, form } = props;

  /** paint */

  const bindEvents = (_graph: Graph) => {
    _graph?.on('node:selected', (args: { cell: Cell; node: any }) => {
      const { objectId, nodeRefFieldInfo } = args?.cell?.getData();
      const childrenList = args?.node._model
        .getSuccessors(args.cell)
        .filter((_cell: Cell) => args?.node._model.isNeighbor(_cell, args.cell));

      setEditNode(args?.cell);
      fetchObjectList(
        objectId,
        _graph?.isRootNode(args.cell),
        _.map(childrenList, (cell) => cell.getData()),
        nodeRefFieldInfo,
      );
    });
    _graph.on(
      'node:delete',
      ({
        view,
        node,
        cell,
        e,
      }: {
        view: { cell: Cell };
        cell: Cell;
        node: { _model: Model; store: any };
        e: any;
      }) => {
        e.stopPropagation();
        const { displayName, objectName } = cell.getData();
        let title = `确定删除${displayName || objectName}`;
        const children = node._model.getSuccessors(view.cell);

        if (children?.length) {
          title = '删除该对象的同时，将同时删除其关联的下级对象，确定继续删除';
        }

        const deleteNode = () => {
          const preNode = node._model?.getPredecessors(cell, { distance: 1 })[0];
          const nodeRefFieldInfo = preNode
            ?.getData()
            .nodeRefFieldInfo?.filter(
              (field: { postNodeUniqueValue: any; oneToManyMark: boolean }) =>
                field.postNodeUniqueValue !== cell.getData().nodeUniqueValue || field.oneToManyMark,
            );

          preNode?.setData({ ...preNode.getData(), nodeRefFieldInfo }, { overwrite: true });
          if (children?.length) {
            node._model.removeCells(children);
          }
          view.cell.remove();
          form.setFieldsValue({ isUnSave: true });
        };

        Modal.confirm({
          title,
          onOk: () => {
            deleteNode();
          },
        });
      },
    );

    _graph.on('node:removed', () => {
      if (_graph.getNodes()?.length === 0) {
        fetchAllObject();
        setEditNode(null);
      }
    });
    _graph.on('edge:selected', async ({ edge }: { cell: Cell; edge: Edge }) => {
      const { data } = await fetchDataSetDataSetObjectChoose({
        objectId: edge.getSourceCell()?.getData()?.objectId,
        root: _graph?.isRootNode(edge.getSourceCell()),
      });

      const relaitonObject = data?.outObjectList?.find(
        (object) => object.objectId === edge.getTargetNode()?.getData().objectId,
      );

      setEditEdge(edge);
      if (relaitonObject?.shipRefFieldInfoList?.length > 1) {
        setEditNode(edge.getSourceCell());
        setRelationObject(relaitonObject);
        setShowSelectModal(true);
        setIsEditField(true);
      }
    });
  };

  const layout = (_graph: Graph, data: any) => {
    _graph.cleanSelection();
    _graph?.fromJSON(dagreLayout.layout(data));
    _graph?.centerContent();
  };

  const initData = (_graph: Graph) => {
    const { objectNodeInfoList, objectRelationShipInfoList } = initialData;

    if (objectNodeInfoList && objectRelationShipInfoList) {
      const nodes = objectNodeInfoList?.map(
        (node: { nodeUniqueValue: number; displayName: any; rootObject: number }) => ({
          id: String(node?.nodeUniqueValue),
          attrs: {
            label: {
              text: Dom.breakText(
                `${node?.displayName}${node?.rootObject === 0 ? '（根节点）' : ''}`,
                { width: 130, height: 45 },
              ),
            },
          },
          data: node,
          ...getbaseNodeInfo(true),
        }),
      );

      const edges = objectRelationShipInfoList?.map(
        (relationShip: {
          id: any;
          objectName: any;
          preNodeUniqueValue: number;
          postNodeUniqueValue: number;
          refField: any[];
          joinRelation: number;
        }) => ({
          source: String(relationShip?.preNodeUniqueValue),
          target: String(relationShip?.postNodeUniqueValue),
          label:
            relationShip?.refField[0]?.preRefFieldDisplayName +
            lookup('customReport.relationShip', relationShip?.joinRelation),
          data: {
            targetType: relationShip?.joinRelation,
            currentRefField: relationShip?.refField[0],
            ...relationShip,
          },
          ...baseEdgeInfo,
        }),
      );

      layout(_graph, { nodes, edges });
    }
  };

  const initGraph = () => {
    if (!graph && ref?.current) {
      const newGraph = new Graph({
        ...getBasicConfig(true),
        container: ref?.current,
        width: ref?.current?.getBoundingClientRect(),
      });

      insertCss(`.x6-edge:hover path:nth-child(2){
        stroke: #ffa940;
      }
      
      .x6-edge-selected path:nth-child(2){
        stroke: #ffa940;
      }`);

      setGraph(newGraph);
      initData(newGraph);
      bindEvents(newGraph);
    } else {
      setTimeout(() => initGraph(), 2000);
    }
  };
  /** -------paint end ----*/

  useEffect(() => {
    initGraph();
  }, []);

  const fetchObjectList = async (
    objectId: number,
    isRoot = false,
    childrenList: any[],
    nodeRefFieldInfo: any[],
  ) => {
    try {
      const { data } = await fetchDataSetDataSetObjectChoose({ objectId, root: isRoot });

      /** 过滤已选择子节点，如果有多个关联字段需要每个字段都已经被关联 */
      const canChooseChildren =
        _.filter(data?.outObjectList, (object) => {
          if (
            childrenList.findIndex((_children) => _children.objectId === object.objectId) !== -1
          ) {
            if (object.oneToManyMark) {
              const list = nodeRefFieldInfo?.filter(
                (field: { postObjectId: number; refObjectId: number }) =>
                  field.postObjectId === object.objectId || field.refObjectId === object.objectId,
              );

              return list?.length !== object?.shipRefFieldInfoList?.length;
            }
            return false;
          }
          return true;
        }) || [];

      setChildrenObjectList(canChooseChildren);
      setParentObjectList(data?.enterObjectList || []);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllObject = () => {
    fetchDataSetQueryAllObject().then((res) => {
      const { data } = res;

      setObjectList(data);
    });
  };

  useEffect(() => {
    if (!isEdit) {
      fetchAllObject();
    }
  }, []);

  const handleAddObject = ({
    object,
    isAddParent,
  }: {
    object: RelationObject;
    isRoot?: boolean;
    isAddParent?: boolean;
  }) => {
    if (!graph) {
      message.error('图像构造中');
    }
    if (object?.oneToManyMark) {
      setRelationObject(object);
      setShowSelectModal(true);
      return;
    }
    if (isAddParent) {
      addParentNode({
        object,
        currentRefField: object?.shipRefFieldInfoList?.[0],
        refField: object?.shipRefFieldInfoList,
      });
      setObjectList([]);
    } else {
      object?.shipRefFieldInfoList?.[0] &&
        addChildrenNode({
          object,
          currentRefField: object?.shipRefFieldInfoList?.[0],
          refField: object?.shipRefFieldInfoList,
        });
    }
    form.setFieldsValue({ isUnSave: true });
    setEditNode(null);
  };

  const addNode = ({
    id,
    label,
    data,
  }: {
    id: string | number;
    label: string;
    data: RelationObject;
  }) => {
    graph?.addNode({
      id: String(id),
      attrs: {
        label: {
          text: Dom.breakText(label, { width: 130, height: 45 }),
        },
      },
      data: {
        ...data,
        displayName: data?.objectName,
        nodeUniqueValue: id,
      },
      ...getbaseNodeInfo(true),
    });
  };

  const addEdge = ({
    sourceId,
    targetId,
    label,
    object,
    refField,
    currentRefField,
  }: {
    sourceId: string | number;
    targetId: string | number;
    label: string;
    object: RelationObject;
    currentRefField?: RelationFieldInfo;
    refField?: RelationFieldInfo[];
  }) => {
    graph?.addEdge({
      source: String(sourceId),
      target: String(targetId),
      label,
      data: {
        ...currentRefField,
        currentRefField,
        refField,
        targetType: object?.targetType,
        postNodeObjectId: object?.objectId,
        preNodeObjectId: editNode?.getData()?.objectId,
        postNodeUniqueValue: currentRefField?.postNodeUniqueValue,
        preNodeUniqueValue: currentRefField?.preNodeUniqueValue,
      },
      ...baseEdgeInfo,
    });
  };

  const addParentNode = ({
    object,
    currentRefField,
    refField,
  }: {
    object: RelationObject;
    currentRefField?: RelationFieldInfo;
    refField?: RelationFieldInfo[];
  }) => {
    const newNodeId = new Date().getTime().toString();
    const edgeLabel = `${currentRefField?.preRefFieldDisplayName}
    ${lookup('customReport.relationShip', object?.targetType)}`;
    const newRefField = {
      ...currentRefField,
      postNodeUniqueValue: editNode?.id,
      preNodeUniqueValue: newNodeId,
      refFieldDisplayName: currentRefField?.preRefFieldDisplayName,
      refFieldId: currentRefField?.preRefFieldId,
      refFieldName: currentRefField?.preRefFieldName,
      refObjectId: currentRefField?.postObjectId,
    };

    addNode({
      id: newNodeId,
      label: `${object.objectName}(根节点)`,
      data: object,
    });
    /** 删除原来的根节点标识 */
    editNode?.setAttrs({
      label: {
        text: editNode.getData().displayName,
      },
    });
    if (editNode) {
      addEdge({
        sourceId: newNodeId,
        targetId: editNode?.id,
        label: edgeLabel,
        object,
        refField,
        currentRefField: newRefField,
      });
    }
    graph &&
      layout(graph, {
        /** 添加关联字段到节点上 */
        nodes: _.map(_.map(graph.getNodes(), 'store.data'), (node) => {
          if (editNode && node.id === newNodeId) {
            node.data.nodeRefFieldInfo = [newRefField];
          }
          return node;
        }),
        edges: _.map(graph.getEdges(), 'store.data'),
      });
  };

  const addChildrenNode = ({
    object,
    currentRefField,
    refField,
  }: {
    object: RelationObject;
    currentRefField: RelationFieldInfo;
    refField?: RelationFieldInfo[];
  }) => {
    const newNodeId = new Date().getTime().toString();
    const edgeLabel = `${currentRefField?.preRefFieldDisplayName}
    ${lookup('customReport.relationShip', object?.targetType)}`;
    const newRefField = {
      ...currentRefField,
      postNodeUniqueValue: newNodeId,
      preNodeUniqueValue: editNode?.id,
      refFieldDisplayName: currentRefField?.preRefFieldDisplayName,
      refFieldId: currentRefField?.preRefFieldId,
      refFieldName: currentRefField?.preRefFieldName,
      refObjectId: currentRefField?.postObjectId,
    };

    addNode({
      id: newNodeId,
      label: `${object.objectName}`,
      data: object,
    });
    if (editNode) {
      addEdge({
        sourceId: editNode?.id,
        targetId: newNodeId,
        label: edgeLabel,
        object,
        refField,
        currentRefField: newRefField,
      });
    }

    graph &&
      layout(graph, {
        /** 添加关联字段到节点上 */
        nodes: _.map(_.map(graph.getNodes(), 'store.data'), (node) => {
          if (editNode && node.id === editNode.id) {
            node.data.nodeRefFieldInfo = (editNode.getData().nodeRefFieldInfo || []).concat(
              newRefField,
            );
          }
          return node;
        }),
        edges: _.map(graph.getEdges(), 'store.data'),
      });
  };

  const closeChooseModal = () => {
    setIsEditField(false);
    setShowSelectModal(false);
  };

  const saveChooseField = (relationField: RelationFieldInfo, refField: RelationFieldInfo[]) => {
    if (!editNode) {
      message.error('请先选择操作节点');
      return;
    }
    if (!relationField) {
      closeChooseModal();
      return;
    }

    if (isEditField) {
      const newRefFieldInfo = editNode
        ?.getData()
        .nodeRefFieldInfo?.map(
          (field: {
            refObjectId?: string;
            postObjectId: string | undefined;
            refFieldId?: string;
          }) => {
            if (
              (field?.postObjectId === relationField?.postObjectId ||
                field?.refObjectId === relationField?.postObjectId) &&
              field?.refFieldId === editEdge?.getData().currentRefField.preRefFieldId
            ) {
              return {
                ...field,
                preRefFieldId: relationField.preRefFieldId,
                preRefFieldDisplayName: relationField.preRefFieldDisplayName,
                refFieldDisplayName: relationField.preRefFieldDisplayName,
                refFieldId: relationField.preRefFieldId,
                refFieldName: relationField.preRefFieldName,
              };
            }
            return field;
          },
        );

      editNode?.setData({
        nodeRefFieldInfo: newRefFieldInfo,
      });
      editEdge?.setData({
        currentRefField: relationField,
      });
      const label = editEdge?.getLabelAt(0);

      const newLabel = {
        attrs: {
          ...label?.attrs,
          label: {
            ...label?.attrs?.label,
            text:
              relationField?.preRefFieldDisplayName +
              lookup('customReport.relationShip', editEdge?.getData().targetType),
          },
        },
      };

      editEdge?.setLabelAt(0, newLabel);
    } else {
      relationObject &&
        addChildrenNode({ object: relationObject, currentRefField: relationField, refField });
    }
    form.setFieldsValue({ isUnSave: true });
    closeChooseModal();
    setEditNode(null);
  };

  const saveObjectNodes = () => {
    const objectNodeInfoList = graph?.getNodes().map((node: Node) => {
      const {
        objectId,
        objectName,
        displayName,
        refFieldInfoList,
        shipRefFieldInfoList,
        nodeRefFieldInfo,
        ...rest
      } = node.getData();

      return {
        ...rest,
        objectId,
        displayName: objectName || displayName,
        rootObject: Number(!graph?.isRootNode(node)),
        nodeRefFieldInfo,
      };
    });

    const objectRelationShipInfoList = graph?.getEdges().map((edge: Edge) => {
      const { currentRefField, targetType, postNodeObjectId, preNodeObjectId } = edge.getData();

      return {
        postNodeUniqueValue: edge.getTargetCellId(),
        preNodeUniqueValue: edge.getSourceCellId(),
        postNodeObjectId,
        preNodeObjectId,
        postNodeName: currentRefField.postObjectName,
        preNodeName: currentRefField.preObjectName,
        refField: [currentRefField],
        joinRelation: targetType,
        joinType: 0,
      };
    });

    form.setFieldsValue({ isUnSave: false });
    saveData({ objectNodeInfoList, objectRelationShipInfoList });
  };

  return (
    <div>
      <Row gutter={16}>
        <Col span={8}>
          <Card title="选择关联对象" bodyStyle={{ height: '554px', overflow: 'scroll' }}>
            {editNode ? (
              <Row gutter={24}>
                <Col xxl={12} xl={24}>
                  <SearchList
                    list={childrenObjectList}
                    addFunc={handleAddObject}
                    editNodeData={editNode.getData()}
                  />
                </Col>
                <Col xxl={12} xl={24}>
                  <SearchList
                    list={parentObjectList}
                    addFunc={(parmas) => handleAddObject({ ...parmas, isAddParent: true })}
                    editNodeData={editNode.getData()}
                    title={'关联父对象'}
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <SearchList
                  list={objectList}
                  addFunc={handleAddObject}
                  isAddParent
                  title={'关联根节点'}
                />
              </Row>
            )}
          </Card>
        </Col>
        <Col span={16}>
          <Card
            title={
              <Button>
                <BlIcon
                  type="iconshuaxin"
                  size={16}
                  onClick={() => {
                    graph?.centerContent();
                  }}
                />
              </Button>
            }
            extra={
              <Button type="primary" onClick={() => saveObjectNodes()}>
                保存并预览
              </Button>
            }
          >
            <div ref={ref} />
          </Card>
        </Col>
      </Row>
      {showSelectModel && (
        <SelectModal
          showSelectModel={showSelectModel}
          closeModal={() => {
            setShowSelectModal(false);
            setIsEditField(false);
          }}
          objectInfo={{ relationObject, currenObject: editNode }}
          saveChooseField={saveChooseField}
        />
      )}
    </div>
  );
};

export default Show;
