import React, { useCallback, useEffect, useState } from 'react';
import useTreeContent from 'src/layout/treeContent';
import { Button, Form } from 'antd';
import DataPrivilegeContent from 'src/page/organization/components/dataPrivilege/DataPrivilegeContent';
import { formatDataPrivilege, formatFormData } from 'src/page/organization/components/utils';
import { fetchDataPrivilegeUpdate } from 'src/api/ytt/privilege-domain/dataPrivilege';
import styles from '../role.module.scss';
import _ from 'lodash';
import { useDataPrivilege } from 'src/page/organization/components/dataPrivilege/utils';

type DataPrivilegeProps = {
  roleId: number;
  title?: string;
  disabled?: boolean;
  getDataSource?: (prop: any) => void;
};

const DataPrivilege: React.FC<DataPrivilegeProps> = (props) => {
  const { title, roleId, getDataSource, disabled } = props;
  const [disableSave, setDisableSave] = useState(true);
  const treeData = useDataPrivilege(roleId);
  const [form] = Form.useForm();
  const getView = useCallback(
    (treeNode) => {
      return treeNode ? (
        <DataPrivilegeContent
          disabled={disabled}
          form={form}
          id={roleId}
          item={treeNode}
          key={treeNode.key}
        />
      ) : null;
    },
    [disabled, form, roleId],
  );

  const submit = useCallback(
    (treeNode) => {
      form.validateFields().then(() => {
        const data = formatFormData(roleId, form.getFieldsValue(true));
        const res = treeData.reduce((prev, cur) => {
          const _cur = cur.key === treeNode.key ? data : cur?.items ?? [];

          return prev.concat(_cur);
        }, []);

        fetchDataPrivilegeUpdate(res).then(() => {
          setDisableSave(true);
        });
      });
    },
    [form, roleId, treeData],
  );

  useEffect(() => {
    if (getDataSource) {
      getDataSource({ disableSave });
    }
  }, [disableSave, getDataSource]);

  const treeWebContent = useTreeContent({
    treeData,
    style: { height: 436, overflowY: 'scroll' },
    checkable: false,
    isTreeNode: false,
    isListTree: true,
    detailContent: (treeNode) => {
      const _submit = () => submit(treeNode);

      return (
        <>
          <div className={styles.titleContent}>
            <span className={styles.authTitle}>{title}</span>
            {disabled ? null : (
              <Button disabled={disableSave} type="primary" onClick={_submit}>
                保存
              </Button>
            )}
          </div>
          <Form
            onValuesChange={(value, allValues) => {
              const curValues = formatDataPrivilege(roleId, allValues);
              const hasChange = !_.isEqual(treeNode?.items, curValues.items);

              if (hasChange) {
                setDisableSave(false);
              }
            }}
            style={{ padding: '20px' }}
            form={form}
          >
            {getView(treeNode)}
          </Form>
        </>
      );
    },
    onCheck: () => {},
  });

  return <>{treeWebContent}</>;
};

export default DataPrivilege;
