import { Form, message, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import { DetailLayoutMenuItem } from 'src/layout/detail/DetailLayout.type';
import confirm from 'src/page/knowledgeManagement/share/modalConfirm';
import {
  fetchMaterialAttributeDelete,
  fetchMaterialAttributeDetail,
  FetchMaterialAttributeDetailResponse,
} from 'src/api/ytt/material-domain/Web_MaterialAttribute';
import _ from 'lodash';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import authDict from 'src/utils/auth';

interface Props {
  id: number;
  showEdit: () => void;
  showOperationRecord: () => void;
  refreshDetailsMarker: number | undefined;
  refreshTable?: () => void;
  onCancel?: () => void;
}
interface AttributeItemsList {
  attributeId: number;
  content: string;
  id: number;
  index: number;
}
const DetailsBaseInfo = (props: Props) => {
  const {
    id: attrId,
    showEdit,
    showOperationRecord,
    refreshDetailsMarker,
    refreshTable,
    onCancel,
  } = props;
  const [dataSource, setDataSource] = useState<FetchMaterialAttributeDetailResponse>();
  const [delForm] = Form.useForm();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);

  const init = async () => {
    try {
      const res: any = await fetchMaterialAttributeDetail({ id: attrId });

      setDataSource(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = async (id: number | number[]) => {
    try {
      const value = await delForm?.validateFields();
      const ids = typeof id === 'number' ? [id] : id;
      const { code, message: mes } = await fetchMaterialAttributeDelete({
        ids,
        reason: value.delReason,
      });

      if (code === 200) {
        message.success('删除成功');
        // 关闭details
        typeof onCancel === 'function' && onCancel();
        // 刷新 list
        typeof refreshTable === 'function' && refreshTable();
        return;
      }
      message.error(mes);
    } catch (error) {
      console.log(error);
    }
  };
  const initBaseMenu = useCallback(
    () =>
      _.compact([
        // {
        //   key: 'delete',
        //   onClick: () => {
        //     confirm('是否删除当前单位', delForm, () => {
        //       handleFinish(attrId);
        //     });
        //   },
        //   // icon: <<BlIcon type="iconshanchulajitong1" /> />,
        //   title: '删除',
        // },
        {
          key: 'record',
          auth: authDict.materialattributeitem_operrecord,
          onClick: () => {
            setVisibleLog(true);
          },
          // icon: <FileTextOutlined />,
          title: '操作记录',
        },
        {
          key: 'edit',
          auth: authDict.materialattributeitem_edit,
          onClick: () => {
            showEdit();
          },
          icon: <BlIcon type="iconbianji" />,
          title: '编辑',
        },
      ]),
    [attrId],
  );

  const baseInfo: DetailLayoutInfoBlock = {
    items: [
      {
        label: '属性项',
        dataIndex: 'name',
        isFullLine: true,
        render: (text: string) => text ?? '-',
      },
      {
        label: '属性值列表',
        dataIndex: 'attributeItemsList',
        isFullLine: true,
        render: (list: AttributeItemsList[]) => {
          return (
            <Table
              style={{ width: '100%' }}
              pagination={false}
              columns={[
                {
                  title: '属性值',
                  dataIndex: 'content',
                },
              ]}
              dataSource={list}
            />
          );
        },
      },
    ],
    column: 1,
  };
  const infoArray = [baseInfo];

  useEffect(() => {
    if (attrId) {
      init();
    }
  }, [refreshDetailsMarker]);
  return (
    <>
      <DetailLayout
        title={'属性详情'}
        baseMenu={initBaseMenu()}
        info={infoArray}
        dataSource={dataSource}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={attrId as number}
          objectCode={OBJECT_OF_CODE.materialAttributeItem}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailsBaseInfo;
