import React from 'react';
import { BlTable } from 'src/components';
import { Switch } from 'antd';
import { replaceSign } from 'src/utils/constants';
import {
  TABLE_TYPE_ENUM,
  RESET_CYCLE_TYPE,
  WATER_SYSTEM_TYPE,
  TABLE_TYPE_TYPE,
  NUMBER_RULES_STATUS_ENUM,
} from '../constant';
import { ElementFields, ElementList, FormatConfigData } from '../interface';

interface TableProps {
  dataSource: ElementList[];
  formatConfigData: FormatConfigData[];
}

const BaseTable = (props: TableProps) => {
  const { dataSource, formatConfigData } = props;

  const dataColumns = [
    {
      title: '序号',
      width: 80,
      dataIndex: 'sortNo',
      render: (sortNo: number) => {
        return sortNo;
      },
    },
    {
      title: '类型',
      dataIndex: 'elementType',
      width: 80,
      render: (elementType: number) => TABLE_TYPE_TYPE.get(elementType),
    },
    {
      title: '长度',
      dataIndex: 'elementLength',
      width: 80,
      render: (elementLength: string) => elementLength || replaceSign,
    },
    {
      title: '元素来源',
      dataIndex: 'elementFields',
      width: 200,
      render: (elementFields: ElementFields[]) => {
        return elementFields?.length > 1 ? elementFields?.[1] : elementFields?.[0];
      },
    },
    {
      title: '格式',
      dataIndex: 'formatConfigId',
      width: 120,
      render: (formatConfigId: number, record: ElementList) => {
        const { elementType } = record;

        if (
          elementType === TABLE_TYPE_ENUM.CONSTANT ||
          elementType === TABLE_TYPE_ENUM.SERIAL_NUMBER
        ) {
          return replaceSign; // 常量/流水号返回-
        }

        return formatConfigData?.find((node: FormatConfigData) => node?.id === formatConfigId)
          ?.displayType;
      },
    },
    {
      title: '设置值',
      dataIndex: 'constantValue',
      width: 150,
      render: (constantValue: string, record: ElementList) => {
        const { elementType } = record;

        if (elementType !== TABLE_TYPE_ENUM.CONSTANT) return replaceSign; // 除常量类型,返回-

        return constantValue ?? replaceSign;
      },
    },
    {
      title: '起始值',
      dataIndex: 'startValue',
      width: 100,
      render: (startValue: string, record: ElementList) => {
        const { elementType } = record;

        if (elementType !== TABLE_TYPE_ENUM.SERIAL_NUMBER) return replaceSign; // 除流水号类型,返回-

        return startValue ?? replaceSign;
      },
    },
    {
      title: '步长',
      dataIndex: 'stepSize',
      width: 80,
      render: (stepSize: string, record: ElementList) => {
        const { elementType } = record;

        if (elementType !== TABLE_TYPE_ENUM.SERIAL_NUMBER) return replaceSign; // 除流水号类型,返回-

        return stepSize ?? replaceSign;
      },
    },
    {
      title: '流水码制',
      dataIndex: 'codeFormat',
      width: 100,
      render: (codeFormat: number, record: ElementList) => {
        const { elementType } = record;

        if (elementType !== TABLE_TYPE_ENUM.SERIAL_NUMBER) return replaceSign; // 除流水号类型,返回-

        return WATER_SYSTEM_TYPE.get(codeFormat) || replaceSign;
      },
    },
    {
      title: '编号依据',
      dataIndex: 'numberBasis',
      width: 100,
      render: (numberBasis: number, record: ElementList) => {
        const { elementType } = record;

        if (elementType === TABLE_TYPE_ENUM.SERIAL_NUMBER) return replaceSign; // 流水号类型,返回-

        return <Switch checked={numberBasis === NUMBER_RULES_STATUS_ENUM.ENABLED} disabled />;
      },
    },
    {
      title: '重置周期',
      dataIndex: 'resetPeriod',
      width: 150,
      render: (resetPeriod: number, record: ElementList) => {
        const { elementType } = record;

        if (elementType !== TABLE_TYPE_ENUM.SERIAL_NUMBER) return replaceSign; // 除流水号类型,返回-

        return RESET_CYCLE_TYPE.get(resetPeriod) || replaceSign;
      },
    },
  ];

  return (
    <BlTable
      columns={dataColumns}
      scroll={{ x: 1200, y: 800 }}
      dataSource={dataSource}
      style={{ width: '100%' }}
      rowKey={(record) => {
        const { id } = record;

        return id;
      }}
    />
  );
};

export default BaseTable;
