/**
 * 对象布局列表
 */

import { useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { toString, toNumber, isString } from 'lodash';
import { message } from 'antd';
import { TabList } from 'src/components';
import { RecordListLayout } from 'src/layout';
import { fetchLayoutList, fetchLayoutDelete } from 'src/api/ytt/layout-domain';
import lookup from 'src/dict';
import { pageType as pageTypeMappings } from 'src/dict/customLayout/mappings';
import { qs } from 'src/utils';
import authDict from 'src/utils/auth';
import { parseLayoutTypes } from '../utils';
import type { RecordListLayoutProps, OperationListItem } from '@blacklake-web/layout';
import type { LayoutListItem } from '../type';

// 切换tab时，query参数会被整体重置，需要保留的参数必须放入这个列表
const sharedUrlParams = [
  'objectCategory',
  'objectCode',
  'objectName',
  'subject',
  'layoutTypes',
  'canSetIsRequired',
];
const placeholder = '请输入布局名称';
const columns = [
  {
    title: '布局名称',
    dataIndex: 'name',
    width: 300,
  },
  {
    title: '所属终端',
    dataIndex: 'terminal',
    width: 150,
    render: (text: number[]) => text?.map(lookup('customLayout.terminal')).join('、'),
  },
  {
    title: '是否默认',
    dataIndex: 'defaultSup',
    width: 120,
    render: lookup('yn'),
  },
  {
    title: '是否预置',
    dataIndex: 'preset',
    width: 120,
    render: lookup('yn'),
  },
];

const getPageType = () => toNumber(qs.parse().tabKey);

const LayoutList = () => {
  const history = useHistory();
  const [refreshMarker, refresh] = useReducer(() => Date.now(), 0);

  const { objectCode, layoutTypes: layoutTypesStr } = qs.parse();
  const layoutTypes = parseLayoutTypes(layoutTypesStr);
  const mainMenu = [
    {
      title: '分配布局',
      icon: 'iconfenpei',
      auth: authDict.layout_assign_layout,
      onClick: () => {
        history.push(`${location.pathname}/assignLayout${location.search}`);
      },
    },
    {
      title: '新建布局',
      icon: 'iconxinjiantianjia',
      auth: authDict.layout_add,
      onClick: () => {
        history.push(`${location.pathname}/createLayout${location.search}`);
      },
      items: [],
    },
  ];

  const getOperationList = (record: LayoutListItem): OperationListItem[] => {
    return [
      {
        title: '查看',
        auth: authDict.layout_detail,
        onClick: () =>
          history.push(`${location.pathname}/viewLayout/${record.id}${location.search}`),
      },
      {
        title: '编辑',
        auth: authDict.layout_edit,
        disabled: !!record.preset,
        onClick: () =>
          history.push(`${location.pathname}/editLayout/${record.id}${location.search}`),
      },
      {
        title: '复制',
        auth: authDict.layout_add,
        onClick: () =>
          history.push(`${location.pathname}/copyLayout/${record.id}${location.search}`),
      },
      {
        title: '删除',
        auth: authDict.layout_remove,
        disabled: !!record.preset,
        reasonconfirm: true,
        onClick: (reason: string) =>
          // @ts-ignore
          fetchLayoutDelete({ id: record.id, reason: isString(reason) ? reason : '' }).then(() => {
            message.success('删除完成。');
            refresh();
          }),
      },
    ];
  };

  const commonProps: RecordListLayoutProps<any> = {
    columns,
    getOperationList,
    mainMenu,
    maxOperationCount: 3,
    placeholder,
    formatDataToQuery: (params) => ({ ...params, type: getPageType(), objectCode }),
    requestFn: fetchLayoutList,
    refreshMarker,
  };

  // 一期不做列表页布局
  const tabList = pageTypeMappings
    .slice(0, 2)
    .filter((m) => layoutTypes.includes(m.value))
    .map(({ label, value }) => ({
      tab: `${label}布局`,
      key: toString(value),
      component: (
        <RecordListLayout {...commonProps} configcacheKey={`custom-layout-type-${value}`} />
      ),
    }));

  return (
    <TabList
      type="card"
      tabList={tabList}
      defaultKey={toString(layoutTypes[0])}
      storageKey="customLayout"
      sharedUrlParams={sharedUrlParams}
    />
  );
};

export default LayoutList;
export * from './edit';
export * from './assign';
