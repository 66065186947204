import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { DetailLayoutForDrawer, DetailLayoutInfoBlock, DetailLayout } from 'layout';
import { gcArray, gcString } from 'utils';
import {
  fetchCustomFieldGetById,
  FetchCustomFieldGetByIdResponse,
  fetchChoiceValueListByFieldId,
  fetchCustomFieldEnableCustomField,
  fetchCustomFieldDisableCustomField,
} from 'src/api/ytt/metadata-domain/custom_field';
import { baseInfoItems } from './components/baseInfo';
import selectOptionInfo from './components/selectOptionInfo';
import moment from 'moment';
import { AvatarDisplay } from 'src/components/avatar/show';
import { replaceSign } from 'src/utils/constants';
import _, { isNumber } from 'lodash';
import { BlIcon, showErrorListMessage } from 'src/components';
import lookup, { appEnum } from 'src/dict';
import { message, Spin } from 'antd';
import { FieldType, FieldCategory } from 'src/dict/customField';
import authDict from 'src/utils/auth';
import { FailPopProps } from '../fieldsList/index.d';

interface DetailProps {
  visible: boolean;
  onCloseDetail: () => void;
  refreshTable: () => void;
  fieldId: number;
  fieldCategory: number;
  showEdit: () => void;
}

type DetailData = FetchCustomFieldGetByIdResponse['data'] & {
  options?: {
    optionTitle: string;
    optionsStatus: number;
  }[];
  fieldType?: FieldType;
};

const FieldDetail = (props: DetailProps) => {
  const [dataSource, setDataSource] = useState<DetailData>({});
  const { visible, onCloseDetail, fieldCategory, fieldId, showEdit, refreshTable } = props;
  const isPreField = fieldCategory === FieldCategory.preFields;
  const onChangeStatusFail = (data: FailPopProps) => {
    const { successCount, failCount, errorList, type } = data;

    showErrorListMessage({
      title: `${type}失败`,
      data: errorList,
      columns: [
        {
          title: '字段编号',
          dataIndex: 'fieldCode',
          key: 'fieldCode',
        },
        {
          title: '字段编号',
          dataIndex: 'fieldName',
          key: 'fieldName',
        },
        {
          title: '失败原因',
          dataIndex: 'detail',
          key: 'detail',
        },
      ],
      operateName: type,
      successCount,
      failCount,
      width: 580,
    });
  };

  const handleChangeStatus = async (status?: number) => {
    try {
      const { data } = await (status === appEnum.Common.UsageStatus.disabled
        ? fetchCustomFieldEnableCustomField([{ id: Number(dataSource?.id) }])
        : fetchCustomFieldDisableCustomField([{ id: Number(dataSource?.id) }]));

      if (!gcArray.isEmpty(data?.fail) && data?.fail.length) {
        onChangeStatusFail({
          successCount: data?.success.length,
          failCount: data?.fail.length,
          errorList: data?.fail,
          type: `${status === appEnum.Common.UsageStatus.disabled ? '启用' : '停用'}`,
        });
      } else {
        message.success(`${status === appEnum.Common.UsageStatus.disabled ? '启用' : '停用'}成功`);
        fetchData();
        refreshTable();
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const baseMenu = [
    {
      key: 'changeStatus',
      onClick: () => handleChangeStatus(dataSource.isUsed),
      title: lookup('common.changeStatusAction', dataSource.isUsed),
      auth: authDict.field_enable_disable,
    },
    // {
    //   key: 'look',
    //   onClick: () => {
    //     console.log('look button');
    //   },
    //   title: '查看日志',
    // },
    {
      key: 'edit',
      onClick: () => {
        showEdit();
      },
      auth: authDict.field_edit,
      title: '编辑',
    },
  ];

  const fetchData = async () => {
    try {
      const { data: detailData } = await fetchCustomFieldGetById({
        id: fieldId,
        fieldCategory,
      });
      const newData: DetailData = { ...detailData };

      if (
        detailData?.fieldType === FieldType.select ||
        detailData?.fieldType === FieldType.multiSelect
      ) {
        const { data } = await fetchChoiceValueListByFieldId({
          fieldId,
          isUsed: [appEnum.Common.UsageStatus.disabled, appEnum.Common.UsageStatus.enabled],
        });

        newData.options = data?.map((option: any) => {
          return {
            optionTitle: option.choiceValue,
            optionStatus: option.isUsed,
            isDefault: option.isDefault,
          };
        });
      }
      if (newData?.isRefer) {
        newData.fieldType = FieldType.reference;
      }
      setDataSource(newData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const numberRuleInfo = {
    label: '编号规则',
    dataIndex: 'numberRule',
    render: (value: number, record: any) => {
      if (record.numberRuleId === -1) {
        return '-';
      }
      return (
        <Link to={`/systemManagement/numberRulesList/${record.numberRuleId}/detail`}>
          {record.numberRuleName}
        </Link>
      );
    },
  };

  const baseInfo = useCallback(() => {
    const hasNumberRule =
      dataSource.fieldType === FieldType.text &&
      (!isPreField || (isPreField && dataSource.isNumberRuleConfig));

    return {
      title: '基本信息',
      items: _.compact([
        { label: '字段编号', dataIndex: 'fieldCode' },
        {
          label: '字段类别',
          dataIndex: 'fieldCategory',
          render: lookup('customField.fieldCategoryString'),
        },
        { label: '字段名称', dataIndex: 'fieldName' },
        {
          label: '字段类型',
          dataIndex: 'fieldType',
          render: (fieldType: number, record: any) => {
            return record.isRefer ? '引用字段' : lookup('customField.fieldType', fieldType);
          },
        },
        {
          label: '字段提示',
          dataIndex: 'fieldRemind',
          render: (text: string) => (gcString.isEmpty(text) ? '-' : text),
        },
        ...(dataSource.fieldType ? baseInfoItems[dataSource?.fieldType] : []),
        hasNumberRule && numberRuleInfo,
      ]),
      column: 2,
    } as DetailLayoutInfoBlock;
  }, [dataSource]);

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator) => {
          if (!creator?.id) {
            return '-';
          }
          return (
            <AvatarDisplay
              id={creator?.id}
              name={creator?.name}
              avatarUrl={creator?.avatarUrl}
              key={creator?.id}
              isShowTag={false}
              isUser
            />
          );
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        isFullLine: true,
        render: (value: number) => {
          if (isNumber(value)) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss');
          }
          return replaceSign;
        },
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator) => {
          if (!operator?.id) {
            return '-';
          }
          return (
            <AvatarDisplay
              id={operator?.id}
              name={operator?.name}
              avatarUrl={operator?.avatarUrl}
              key={operator?.id}
              isShowTag={false}
              isUser
            />
          );
        },
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        isFullLine: true,
        render: (value: number) => {
          if (isNumber(value)) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss');
          }
          return replaceSign;
        },
      },
    ],
    column: 2,
  };
  let infoArray = [baseInfo(), otherInfo];

  if (
    dataSource?.fieldType === FieldType.select ||
    dataSource?.fieldType === FieldType.multiSelect
  ) {
    infoArray = [baseInfo(), selectOptionInfo, otherInfo];
  }

  return (
    <DetailLayoutForDrawer
      visible={visible}
      onClose={onCloseDetail}
      width={680}
      content={
        dataSource.id ? (
          <DetailLayout
            baseMenu={baseMenu}
            title={'字段详情'}
            info={infoArray}
            dataSource={dataSource}
          />
        ) : (
          <Spin style={{ width: '100%' }} />
        )
      }
    />
  );
};

export default FieldDetail;
