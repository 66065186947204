import React, { useMemo } from 'react';
import { Button, Modal } from 'antd';
import { BlIcon } from '@blacklake-web/component';
import color from 'src/components/styles/color';

interface Props {
  visible: Boolean;
  isEdit: Boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
  handleClose?: () => void;
}

export default function ConfirmModal({
  visible,
  isEdit,
  handleOk,
  handleCancel,
  handleClose,
}: Props) {
  const createOrEdit = useMemo(() => {
    return isEdit ? '编辑' : '新建';
  }, [isEdit]);
  const title = useMemo(() => {
    return `SOP方案${createOrEdit}成功`;
  }, [createOrEdit]);
  const content = useMemo(() => {
    return ` SOP方案已${createOrEdit}成功，您可以继续编辑方案内步骤信息，或者返回SOP方案列表页面`;
  }, [createOrEdit]);
  const titleRender = (
    <div style={{ display: 'flex', alignItems: 'center', color: color.titleColor }}>
      <BlIcon
        type="iconchenggong"
        style={{ marginRight: '18px', fontSize: '22px', color: color.primaryColor }}
      />
      {title}
    </div>
  );

  return (
    <Modal
      visible={Boolean(visible)}
      width={480}
      title={titleRender}
      onCancel={() => {
        if (typeof handleClose === 'function') {
          handleClose();
        }
      }}
      footer={[
        <Button key="cancel" onClick={handleCancel} style={{ color: color.textColorPrimary }}>
          返回SOP方案列表
        </Button>,
        <Button
          key="ok"
          type="primary"
          onClick={() => {
            if (typeof handleOk === 'function') {
              handleOk();
            }
          }}
          style={{ marginLeft: '16px' }}
        >
          继续编辑方案
        </Button>,
      ]}
    >
      <span style={{ color: color.textColorPrimary }}> {content}</span>
    </Modal>
  );
}
