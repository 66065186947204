import { useState, useEffect, useContext, memo } from 'react';
import { Calendar, Typography, FormInstance, message as Message, Popover } from 'antd';
import { HeaderRender } from 'antd/lib/calendar/generateCalendar';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import 'moment/locale/zh-cn';

import { BlIcon } from 'src/components';
import styles from './index.module.scss';
import CalendarTooltip from './CalendarTooltip';
import { CalendarDateSwitcher } from '../../components';

import {
  FetchWorkerCalendarPreViewRequest,
  FetchWorkerCalendarPreViewResponse,
  fetchWorkerCalendarPreView,
} from 'src/api/ytt/calendar-domain';
import { SpecialDayDataRecordProps, SpecialDayContext, CalendarDataType } from '../../constants';

// 引入moment，把locale设置成浏览器的语言
moment.locale(navigator.language.toLowerCase());

/** 固定班制日历组件的props */
interface CalendarDisplayPanelProps {
  className: string;
  // 日历所在form的instance，仅仅在新建/编辑时使用
  form?: FormInstance;
  clearFlag?: number;
  // 日历数据，仅仅在查看详情时使用
  calendarData?: CalendarDataType;
}

// 班次数据类型
type CalendarShiftData = FetchWorkerCalendarPreViewResponse['data'];

// 根据moment计算请求后台的previewCalendar接口时用到的viewStartTime和viewEndTime
// viewStartTime和viewEndTime为moment所在月的panel的第一个日期和最后一个日期
// 第一个日期为该月1日所在周的周一，最后一个日期为第一个日期后整42天（日历渲染6行，每行7个）
const getPanelDateRange = (value: Moment) => {
  const viewStartTime = value.clone().startOf('month').startOf('week');
  const viewEndTime = viewStartTime.clone().add(42, 'days');

  return {
    viewStartTime,
    viewEndTime,
  };
};

function CalendarDisplayPanel(props: CalendarDisplayPanelProps) {
  const { className, form, clearFlag, calendarData } = props;
  const [calendarShiftData, setCalendarShiftData] = useState<CalendarShiftData>(); // 从后台取得的日期排班信息数组
  // Calendar组件绑定的value，为日历当前选中的月种的某一天的moment
  const [calendarValue, setCalendarValue] = useState<Moment>(moment());
  const [selectedValue, setSelectedValue] = useState<Moment>(); // 当前选中的日期，高亮用
  const [validDateRange, setValidDateRange] = useState<[Moment, Moment]>();
  const [showPopover, setShowPopover] = useState(false);

  // 为了与特殊日联动用的context
  const { specialDayState } = useContext(SpecialDayContext);
  // 在特殊日表格中编辑特殊日之后，要刷新日历的显示，displayMoment为特殊日所在月
  const { refreshCalendar, displayMoment } = specialDayState;

  // 传入calendarData的时候是查看详情，此时为只读模式
  // 如果是可编辑模式，开始日期和结束日期与日历同时可编辑，为了获取实时最新数据，必须传入form而不是calendarData
  const readOnly = calendarData !== undefined;

  // 渲染日历格
  const calendarCellRenderer = (date: Moment) => {
    // 从后台返回的日期班次数组中，找到对应当前日历单元格日期的那天的数据，如果没有，则为未分配状态（未分配状态在日历的时间范围外）
    const formatedDate = date.startOf('day').valueOf();
    const dateData = calendarShiftData?.find((shiftData) => {
      return shiftData.day === formatedDate;
    }) || {
      day: formatedDate,
      shiftDetail: {
        name: '未分配',
        id: -1,
        color: undefined,
      },
      specialDayName: undefined,
    };
    const { shiftDetail, specialDayName } = dateData;
    const dataColor = shiftDetail?.color;
    let cellColor;

    // 后台返回的单元格背景色有2种格式，有#与没有#，没有需要添加上
    if (dataColor) {
      if (dataColor.startsWith('#')) {
        cellColor = dataColor;
      } else {
        cellColor = `#${dataColor}`;
      }
    } else {
      cellColor = 'white';
    }

    // 只有当月的日期在点击后会显示tooltip，非当月日期点击后自带antd的跳转月份效果
    if (date.month() === calendarValue.month()) {
      return (
        <Popover
          trigger="click"
          placement="bottomLeft"
          arrowPointAtCenter
          autoAdjustOverflow
          align={{ offset: [-20, -40] }}
          getPopupContainer={(triggerNode) => triggerNode}
          overlayClassName={styles.calendarTooltip}
          // 当单元格是当前点击的日期时，响应popover的onVisibleChange
          visible={showPopover && selectedValue?.isSame(date, 'day')}
          onVisibleChange={(popoverVisible: boolean) => {
            setShowPopover(popoverVisible);
          }}
          content={
            <CalendarTooltip
              dataSource={dateData}
              readOnly={readOnly}
              onClose={() => {
                setShowPopover(false);
              }}
            />
          }
        >
          <div
            className={[
              styles.calendarCell,
              selectedValue?.isSame(formatedDate, 'day') ? styles.selected : '',
            ].join(' ')}
            style={{ backgroundColor: cellColor }}
          >
            {specialDayName && (
              <div className={styles.specialDayTag} title={specialDayName}>
                {specialDayName}
              </div>
            )}
            <div className={styles.calendarCellDate}>{date.date()}</div>
            <div className={styles.calendarCellShift}>{dateData?.shiftDetail.name}</div>
          </div>
        </Popover>
      );
    }
    return (
      <div className={styles.calendarCell} style={{ backgroundColor: cellColor }}>
        <div className={styles.calendarCellDate}>{date.date()}</div>
        <div className={styles.calendarCellShift} title={dateData?.shiftDetail.name}>
          {dateData?.shiftDetail.name}
        </div>
      </div>
    );
  };

  // 生成日历头上的日期切换组件
  const calendarHeaderRenderer: HeaderRender<Moment> = ({ value, onChange }) => {
    let calendarStartDate: Moment;
    let calendarEndDate: Moment;

    // 如果有form，则为新建/编辑/复制，否则则为查看
    if (form) {
      calendarStartDate = form.getFieldValue('startDate');
      calendarEndDate = form.getFieldValue('endDate');
    } else {
      // 查看详情页，直接从当前传入的属性中获取开始日期和结束日期
      calendarStartDate = moment(calendarData?.startTime);
      calendarEndDate = moment(calendarData?.endTime);
    }
    return (
      <CalendarDateSwitcher
        startDate={calendarStartDate}
        endDate={calendarEndDate}
        switcherClassName={styles.calendarDisplayHeader}
        iconClassName={styles.calendarHeaderIcon}
        value={value}
        onChange={onChange}
      />
    );
  };

  // 加载预览数据：排班后日期的数组
  const loadPreviewData = async (requestData: FetchWorkerCalendarPreViewRequest) => {
    const response = await fetchWorkerCalendarPreView(requestData);

    if (response.code === 200) {
      setCalendarShiftData(response.data);
    } else {
      Message.error(response.message);
    }
    console.log('load preview data finished');
  };

  /**
   * 准备预览数据
   * @param current 刷新目标日期
   */
  const previewCalendar = async (current?: Moment) => {
    try {
      let requestData: FetchWorkerCalendarPreViewRequest;

      // 如果有calendarData，为查看/只读模式，参数直接从calendarData中获取
      // 这里没有用readOnly作为判断条件，是因为calendarData受ts限制不可以为空
      if (calendarData) {
        const startTime = calendarData.startTime;
        const endTime = calendarData.endTime;
        const shiftRuleId = calendarData?.constantVo.shiftRule.shiftRuleId;

        current = current ?? moment(startTime);
        const panelDateRange = getPanelDateRange(current);
        const { viewStartTime, viewEndTime } = panelDateRange;

        requestData = {
          startTime,
          endTime,
          shiftRuleId,
          specialDayConfigs: calendarData.constantVo.specialDays,
          viewStartTime: viewStartTime?.startOf('day').valueOf(),
          viewEndTime: viewEndTime?.startOf('day').valueOf(),
        };
        // 设置日历的vlidateDateRange
        setValidDateRange([moment(startTime), moment(endTime)]);
      } else {
        // 验证form，检验开始日期，结束日期和排班规则有没有选
        await form?.validateFields(['startDate', 'endDate', 'shiftRule']);
        current = current ?? (form?.getFieldValue('startDate') as Moment);
        const panelDateRange = getPanelDateRange(current);
        const { viewStartTime, viewEndTime } = panelDateRange;
        const { startDate, endDate, shiftRule, specialDays } = form?.getFieldsValue();

        requestData = {
          startTime: startDate.startOf('day').valueOf(),
          endTime: endDate.startOf('day').valueOf(),
          shiftRuleId: shiftRule.value,
          specialDayConfigs: specialDays?.map((specialDay: SpecialDayDataRecordProps) => {
            const { specialDayIndex, ...rest } = specialDay;

            return _.omit({ ...rest, index: specialDayIndex }, 'specialDayId');
          }),
          viewStartTime: viewStartTime?.startOf('day').valueOf(),
          viewEndTime: viewEndTime?.startOf('day').valueOf(),
        };

        // 在新建的时候，form里get到到startDate为当前日的，antd的datepicker被选中的具体时间，而特殊日则定为该日0点0分
        // 为了解决这个错误，用该日0点来设置日历范围
        setValidDateRange([startDate.clone().startOf('day'), endDate]);
      }

      // 设置current为当前日历显示的月份
      setCalendarValue(current);
      // 清空之前选中的日期
      setSelectedValue(undefined);
      // 加载预览数据
      loadPreviewData(requestData);
    } catch (error) {
      console.log('validate calendar form error: ', error);
    }
  };

  // 用于连续新建时清空日历数据
  const clearCalendar = () => {
    setCalendarShiftData(undefined);
    setSelectedValue(undefined);
  };

  useEffect(() => {
    // 第一次render的时候不需要加载日历，0是clearFlag这个state的初始值
    if (!_.isNil(clearFlag) && clearFlag !== 0) {
      clearCalendar();
    }
  }, [clearFlag]);

  // 在特殊日改变之后刷新日历预览
  useEffect(() => {
    // 第一次render的时候不需要加载日历，0是refreshCalendar的初始值
    if (refreshCalendar !== 0) {
      previewCalendar(displayMoment);
    }
  }, [refreshCalendar]);

  useEffect(() => {
    if (calendarData) {
      previewCalendar();
    }
  }, [JSON.stringify(calendarData)]);

  return (
    <div className={className}>
      <span className={styles.calendarTitleContainer}>
        <Typography.Title level={5} className={styles.calendarDisplayTitle}>
          工作日历
        </Typography.Title>
        {!readOnly && (
          <BlIcon
            type="iconshuaxin"
            className={styles.calendarHeaderIcon}
            size={16}
            onClick={() => {
              previewCalendar();
            }}
          />
        )}
      </span>
      <div className={styles.calendarDisplayBody}>
        {calendarShiftData && calendarShiftData.length > 0 ? (
          <Calendar
            style={{ height: 650, overflow: 'hidden' }}
            fullscreen
            validRange={validDateRange}
            headerRender={calendarHeaderRenderer}
            dateFullCellRender={calendarCellRenderer}
            onPanelChange={previewCalendar}
            value={calendarValue}
            onSelect={setSelectedValue}
          />
        ) : (
          <div className={styles.noPreviewContainer}>
            <div className={styles.noPreviewHint}>暂无预览</div>
            <a
              onClick={() => {
                previewCalendar();
              }}
            >
              点击预览
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(CalendarDisplayPanel, _.isEqual);
