import React, { useEffect, useState } from 'react';
import { DetailLayout, DetailLayoutForModal } from 'layout';
import { BlIcon, BlTable } from 'src/components';
import lookup, { appEnum } from 'src/dict';
import { FetchPlanOperationSchemeGetResponse } from 'src/api/ytt/plo-domain/plannOrderOperation';
import PlanCalculationSchemeEdit from './baseForm';
import { Button } from 'antd';
import { map } from 'lodash';

//

interface FeedingControlDetailProps {
  dataSource: FetchPlanOperationSchemeGetResponse['data'];
  onChange: () => void;
}

const PlanCalculationSchemeDetail = (props: FeedingControlDetailProps) => {
  const { dataSource, onChange } = props;

  const [planOperationSchemeEdit, setPlanOperationSchemeEdit] = useState<any>(false);
  const [supplySelectedRowKeys, setSupplySelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowKeys, setSelectRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    const { requirementSourceType, supplySourceType } = dataSource || {};
    const supplySourceTypeSelect = supplySourceType?.filter((e) => e.status);
    const requirementSourceTypeSelect = requirementSourceType?.filter((e) => e.status);

    setSupplySelectedRowKeys(map(supplySourceTypeSelect, 'id'));
    setSelectRowKeys(map(requirementSourceTypeSelect, 'id'));
  }, [dataSource]);

  const renderRequirementSourceType = () => {
    const columns = [
      {
        title: '单据名称',
        dataIndex: ['orderType'],
        render: (text: any) => lookup('planned.reqSourceTypeNameMap', text),
      },
      {
        title: '单据类型',
        dataIndex: ['orderType'],
        render: (text: any) => lookup('planned.reqSourceTypeTypeMap', text),
      },
    ];

    return (
      <BlTable
        scroll={{ x: 'max-content', y: 'auto' }}
        dataSource={dataSource?.requirementSourceType}
        columns={columns}
        pagination={false}
        rowSelection={{
          selectedRowKeys,
          getCheckboxProps: () => ({
            disabled: true,
          }),
        }}
        rowKey={(field) => field?.id}
      />
    );
  };

  const renderSupplySourceType = () => {
    const columns = [
      {
        title: '单据名称',
        dataIndex: ['orderType'],
        render: (text: any) => lookup('planned.reqSourceTypeNameMap', text),
      },
      {
        title: '单据类型',
        dataIndex: ['orderType'],
        render: (text: any) => lookup('planned.reqSourceTypeTypeMap', text),
      },
      {
        title: '优先级',
        dataIndex: ['priority'],
        render: (_text: number) => {
          return _text;
        },
      },
    ];

    return (
      <BlTable
        scroll={{ x: 'max-content', y: 'auto' }}
        dataSource={dataSource?.supplySourceType}
        columns={columns}
        pagination={false}
        rowSelection={{
          selectedRowKeys: supplySelectedRowKeys,
          getCheckboxProps: () => ({
            disabled: true,
          }),
        }}
        rowKey={(field) => field?.id}
      />
    );
  };

  const getBaseInfo = () => {
    const baseInfo = [
      {
        title: '基本信息',
        items: [
          {
            label: '计划方案编号',
            dataIndex: 'code',
          },
          {
            label: '计划展望期',
            dataIndex: 'expectationDate',
          },
          {
            label: '计划运算范围',
            dataIndex: 'operationRange',
            render: (text: any) => lookup('planned.OperationRangeDisplayMap', text?.[0]),
          },
          {
            label: '需求优先级',
            dataIndex: 'requirementPriority',
            render: (text: any) => lookup('planned.RequirementPriorityDisplayMap', text),
          },
          {
            label: '预留释放设置',
            dataIndex: 'reserveLiberate',
            render: (text: any) => lookup('planned.ReserveLiberateDisplayMap', text),
          },
        ],
      },
      {
        title: '需求设置',
        items: [
          {
            isFullLine: true,
            dataIndex: 'requirementSourceType',
            render: () => renderRequirementSourceType(),
          },
        ],
      },
      {
        title: '供应设置',
        items: [
          {
            isFullLine: true,
            dataIndex: 'supplySourceType',
            render: () => renderSupplySourceType(),
          },
          {
            label: '是否考虑非主产出',
            dataIndex: 'notMainOutput',
            render: (text: number) => lookup('common.yn', text),
          },
          {
            label: '是否考虑替代料',
            dataIndex: 'replace',
            render: (text: number) => lookup('common.yn', text),
          },
        ],
      },
    ];

    return baseInfo;
  };

  return (
    <>
      <DetailLayout
        title="计划运算方案详情"
        info={getBaseInfo()}
        dataSource={dataSource}
        baseMenu={[
          {
            key: appEnum.Common.CRUD.edit,
            icon: <BlIcon type="iconbianji" />,
            //   auth: authDict.productionorder_edit,
            title: lookup('crud', appEnum.Common.CRUD.edit),
            buttonRender: (
              <Button
                type="primary"
                onClick={() => {
                  setPlanOperationSchemeEdit(true);
                }}
                style={{ marginRight: 30 }}
              >
                编辑
              </Button>
            ),
          },
        ]}
      />

      <DetailLayoutForModal
        visible={planOperationSchemeEdit}
        onClose={() => {
          setPlanOperationSchemeEdit(undefined);
        }}
        content={
          <PlanCalculationSchemeEdit
            dataSource={dataSource}
            onChange={() => {
              onChange();
              setPlanOperationSchemeEdit(undefined);
            }}
          />
        }
      />
    </>
  );
};

export default PlanCalculationSchemeDetail;
