import React from 'react';
import { Tabs } from 'antd';
import { BlTable } from 'src/components';
import RelationChart from './displayChart';

const DataTab = ({
  fieldInfo,
  previewData,
  objectViewData,
}: {
  fieldInfo?: any[];
  previewData?: any[];
  objectViewData?: { nodes: any[]; edges: any[] };
}) => {
  const columns = fieldInfo
    .filter((i) => i.checked)
    .map((field, index) => {
      const { fieldDisplayName } = field;

      return {
        title: fieldDisplayName,
        dataIndex: index,
        width: 150,
        fixed: index === 0 ? 'left' : false,
      };
    });

  return (
    <Tabs defaultActiveKey="preview" type="card">
      <Tabs.TabPane tab={'数据预览'} key={'dataPreview'}>
        <BlTable columns={columns} dataSource={previewData} scroll={{ x: 1000 }} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={'对象视图'} key={'preview'}>
        <RelationChart data={objectViewData} />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default DataTab;
