/**
 * @mappings 公共名-值映射表
 */

import {
  YN,
  YNB,
  YNS,
  SF,
  CRUD,
  Turn,
  Time,
  UsageStatus,
  FieldType,
  FieldCategory,
  ObjectCategory,
  TargetType,
  ViewModal,
} from '.';
import { mappingsFactory } from '../utils';

export const yn = mappingsFactory(['是', YN.yes], ['否', YN.no]);
export const ynb = [
  { label: '是', value: YNB.yes },
  { label: '否', value: YNB.no },
];
export const yns = [
  { label: '是', value: YNS.yes },
  { label: '否', value: YNS.no },
];

export const sf = mappingsFactory(
  ['失败', SF.failure],
  ['成功', SF.success],
  ['部分成功', SF.partSuccess],
);

export const crud = mappingsFactory(
  ['新建', CRUD.create],
  ['编辑', CRUD.edit],
  ['删除', CRUD.delete],
  ['查看', CRUD.view],
  ['复制', CRUD.copy],
  ['下发', CRUD.issued],
  ['启用', CRUD.enable],
  ['停用', CRUD.disable],
  ['操作记录', CRUD.operrecord],
);

export const turn = mappingsFactory(['开启', Turn.on], ['关闭', Turn.off]);

export const time = mappingsFactory(
  ['秒', Time.second],
  ['分钟', Time.minute],
  ['小时', Time.hour],
  ['日', Time.day],
  ['月', Time.month],
  ['年', Time.year],
);

export const usageStatus = mappingsFactory(
  ['启用中', UsageStatus.enabled],
  ['停用中', UsageStatus.disabled],
);
/** 根据当前状态返回动作名, 如: 当前状态为『启用中』, 则返回『停用』 */
export const changeStatusAction = mappingsFactory(
  ['启用', UsageStatus.disabled],
  ['停用', UsageStatus.enabled],
);
/**
 * 视图模式
 */
export const viewModal = mappingsFactory(
  ['按单据展示', ViewModal.documents],
  ['按明细展示', ViewModal.detail],
);

export const fieldType = mappingsFactory(
  ['单行文本', FieldType.text],
  ['数值', FieldType.number],
  ['多行文本', FieldType.textArea],
  ['单选', FieldType.select],
  ['多选', FieldType.multiSelect],
  ['布尔值', FieldType.boolean],
  ['整数', FieldType.integer],
  ['日期时间', FieldType.date],
  ['链接', FieldType.url],
  ['引用字段', FieldType.reference],
  ['关联关系', FieldType.relation],
  ['主从关系', FieldType.subordinate],
);

export const fieldCategoryString = mappingsFactory(
  ['自定义字段', FieldCategory.customFields],
  ['预设字段', FieldCategory.preFields],
);

export const objectCategory = mappingsFactory(
  ['自定义对象', ObjectCategory.customObject],
  ['标准对象', ObjectCategory.preObject],
);

export const targetType = mappingsFactory(
  ['多选(对n)', TargetType.multiChoice],
  ['单选(对1)', TargetType.singleChoice],
);
