/**
 * 展示文件列表详情，包括可以预览文件
 */
import { useState, forwardRef } from 'react';
import { message } from 'antd';
import { BlUpload } from '@blacklake-web/component';
import { UploadFile } from 'antd/lib/upload/interface';
import Preview from './preview';
import styles from './styles.module.scss';
import authDict, { hasAuth } from 'src/utils/auth';
import { courseDownload, formatterUrl } from '../../share';
import { File } from 'src/components/upload/BcAttachment';

interface Props {
  ref?: any;
  files?: Array<File>;
  value?: any;
  showPreviewIcon?: boolean;
  showRemoveIcon?: boolean;
  showDownloadIcon?: boolean;
  onChange?: (files: any) => void;
  preview?: boolean;
  customerId?: number; // 跨租户的协同附件预览时，需要的参数
}

const UploadFileList = forwardRef((props: Props) => {
  const {
    preview = false,
    files = [],
    showPreviewIcon = false,
    showRemoveIcon = false,
    showDownloadIcon = true,
    onChange,
    customerId,
    ...rest
  } = props;
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewFile, setPreviewFile] = useState<any>(null);
  const viewFile = (file: any) => {
    if (!preview || !hasAuth(authDict.document_view_file)) return;
    if (file.status !== 'done' && !file.url) {
      message.warn('当前文件无法预览');
      return;
    }
    setPreviewVisible(true);
    setPreviewFile(file.uid);
  };

  return (
    <>
      <BlUpload
        defaultFiles={files}
        showUploadList={{
          showDownloadIcon: hasAuth(authDict.document_down_file),
          showPreviewIcon,
          showRemoveIcon,
        }}
        onChange={onChange}
        className={styles['attach-upload']}
        {...(customerId ? { canPreview: true } : { onPreview: viewFile })}
        onDownload={(file: UploadFile<any>) => {
          if (file.url && file.name) {
            courseDownload(formatterUrl(file.url), file.name);
          }
        }}
        {...rest}
      />
      {previewVisible && (
        <Preview
          visible={previewVisible}
          fileList={files}
          fileId={previewFile}
          onCancel={() => setPreviewVisible(false)}
        />
      )}
    </>
  );
});

export default UploadFileList;
