import { Form, FormInstance, Input, Modal } from 'antd';

const showConfirmCloseOrder = (form: FormInstance, onOk: () => any) => {
  Modal.confirm({
    title: '关闭后将无法对计划订单进行操作，确认关闭？',
    width: 500,
    content: (
      <Form form={form}>
        <Form.Item label="关闭原因" name="operateReason">
          <Input.TextArea maxLength={1000} />
        </Form.Item>
      </Form>
    ),
    onOk,
  });
};

export default showConfirmCloseOrder;
