import { useEffect, useState, useCallback, useRef, useReducer, useMemo, ReactNode } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import _ from 'lodash';
import { omit } from 'lodash/fp';
import classnames from 'classnames';
import useTreeContent from 'src/layout/treeContent';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import {
  Form,
  Input,
  Select,
  Switch,
  InputNumber,
  message,
  Button,
  Space,
  Empty,
  Spin,
} from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { useToggle } from 'react-use';
import {
  fetchSopDetail,
  fetchSopStepDetail,
  fetchSopStepAdd,
  fetchSopStepUpdate,
  fetchSopStepDelete,
  fetchSopStepSort,
} from 'src/api/ytt/sop-domain';
import lookup from 'src/dict';
import {
  StepEntityType,
  PrivilegeType,
  NextStepType,
  DigitalType,
  ControlType,
  GroupType,
  BizType,
  FieldSource,
} from 'src/dict/sop';
import { time as timeMappings } from 'src/dict/common/mappings';
import {
  privilegeType as privilegeTypeMappings,
  digitalType as digitalTypeMappings,
  nextStepType as nextStepTypeMappings,
  groupType as groupTypeMappings,
  loopExecRestrictRule as loopExecRestrictRuleMappings,
} from 'src/dict/sop/mappings';
import LimitableSelect from 'src/components/select/limitableSelect';
import { BlIcon, FormulaEditorModal, NumberRulesStandardHook } from 'src/components';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { textValidator2 } from 'src/utils/formValidators';
import ControlEditor from '../control/editor';
import { NEW_CONTROL_ID, NEW_STEP_NUMBER_ID, stepDefaultValue } from '../constants';
import RoleSelect from 'src/page/organization/components/roleSelect';
import {
  isParentParallel,
  hasLoopableAncestor,
  getSiblingsById,
  getSiblingsByParentId,
  countSteps,
  isLastStep,
  getById,
  getAncestorIdsByParentId,
  mapSteps,
} from '../share/stepUtils';
import extractIdsForStep, {
  extractIdsForStepofResource,
  extractIdsForStepofUser,
} from '../share/extractIdsForStep';
import { delTreeNode, formatTree, insertNewTreeNode } from '../share/formatTree';
import { arrayItemProps } from '../share/formatProps';
import type {
  LocalControlItem,
  StepOverviewData,
  StepOverviewList,
  StepRowData,
  StepTreeData,
} from '../types';
import styles from './style.module.scss';
import LeftTree from '../components/leftTree';
import useStepSearch from '../share/useStepSearch';
import newNodeIsSaved from '../share/validate';
import { EventDataNode } from 'antd/lib/tree';
import { customField2Lv } from '../share/controlFieldFormat';
import { BASIC_PATH } from 'src/page/knowledgeManagement/reportTemplate/constants';
import { differenceByObjArray } from '../share/common';

interface RouteParams {
  sopId: string;
  stepId?: string;
}

const switchTextProps = {
  checkedChildren: '开',
  unCheckedChildren: '关',
};
const MAX_LAYER_COUNT = 30;
const OMIT_BY_EQUAL = '_localKey';

const EditSOPStep = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { sopId } = useParams<RouteParams>();
  const { pathname } = useLocation();
  const isReportTemplate = pathname.includes(BASIC_PATH);
  // 必传sopId
  // 当前新建的是步骤或步骤组
  const [currentNode, setCurrentNode] = useState<StepTreeData | undefined>(undefined);
  // wendy TODO 需要验证及测试一下再去了unused
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [leftData, setLeftData] = useState<StepTreeData[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [steps, setSteps] = useState<StepOverviewList | StepOverviewData[]>([]);
  const isCreate = currentNode?.id === NEW_STEP_NUMBER_ID;
  const [isFolded, expand] = useToggle(!isCreate);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [basicInfoData, setBasicInfoData] = useState(stepDefaultValue);
  const [detailLoading, setDetailLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [, forceUpdate] = useReducer((i) => i + 1, 0);
  // 控件的本地唯一标识。因新增的控件无id，所有控件统一使用 localKey 作为唯一标识
  const [localKeys, setLocalKeys] = useState<string[]>([]);
  const [manualNextStepOptions, setManualNextStepOptions] = useState<LabeledValue[]>([]);
  const [ancestorGroupProps, setAncestorGroupProps] = useState({
    isParallel: false,
    hasLoop: false,
  });
  // 用于判断是否展示业务控件
  const [bizType, setBizType] = useState(BizType.general);
  // 步骤、控件与SOP方案关联的唯一标识
  const referenceId = useRef<number | undefined>(undefined);
  const [originFormValue, setOriginFormValue] = useState({});
  const formProps = {
    preserve: true,
    initialValues: basicInfoData,
    labelCol: { flex: '120px' },
  };
  // 是否为步骤组
  const isGroup = currentNode?.type === StepEntityType.stepGroup;
  const stepEntityName = useMemo(() => {
    return lookup('sop.stepEntityType', Number(currentNode?.type) || 0);
  }, [currentNode]);
  const objectCode = isGroup ? 'SubStepGroup' : 'SubStep';
  const getValue = form.getFieldValue;
  const showSelfLoopTimeConfig = () =>
    getValue('enableSelfLoop') && getValue('singleLoopTimeRestrict');

  const maxHeight = document.getElementById('stepEditPage')?.clientHeight; // treeContent的高度

  const { searchSteps, getDisplaySteps } = useStepSearch();

  const fetchDetails = async (isSort?: boolean) => {
    setListLoading(true);
    const { data } = await fetchSopDetail({ sopId: _.toNumber(sopId) }, { legacy: true });

    setListLoading(false);
    const steps = data?.steps ?? [];

    setSteps(steps);
    setLeftData(formatTree(steps));
    // 根据当前的id
    if (isSort) {
      setCurrentNode(getById(steps as StepOverviewList, selectedKeys[0]) as StepTreeData);
    }
  };

  const fetchSopStepDetailData = (config: { id: number; referenceId: number }) => {
    const { id, referenceId } = config;

    setDetailLoading(true);
    fetchSopStepDetail(
      {
        id,
        referenceId,
      },
      { legacy: true },
    )
      .then((res) => {
        setCurrentNode(res?.data as StepTreeData);
        setSelectedKeys(res?.data?.id ? [res?.data?.id] : []);
        form.resetFields();
        // contorls的数据display 转换
        const formatControls = _.get(res?.data, 'controls')?.map((item) => {
          switch (item.controlType) {
            case ControlType.resourceWithSelectedParams: {
              const resourceIds = customField2Lv(_.get(item, 'bizField.resourceIds'), {
                keyName: 'id',
                labelName: 'name',
              });

              _.set(item, 'bizField.resourceIds', resourceIds);

              return item;
            }

            case ControlType.resourceWithFixedParams: {
              const resourceIds = customField2Lv(_.get(item, 'bizField.resourceIds'), {
                keyName: 'id',
                labelName: 'name',
              });
              const paramIds = customField2Lv(_.get(item, 'bizField.paramIds'), {
                keyName: 'id',
                labelName: 'paramsName',
              });

              _.set(item, 'bizField.resourceIds', resourceIds);
              _.set(item, 'bizField.paramIds', paramIds);

              return item;
            }
            case ControlType.associatedField:
              if (item.fieldSource === FieldSource.currentSheet) {
                const localControl = _.get(item, 'localControl');

                if (isReportTemplate) {
                  return {
                    ...item,
                    ...{
                      localControl: _.isNumber(localControl?.id)
                        ? _.toNumber(localControl?.id)
                        : undefined,
                    },
                  };
                }
                return {
                  ...item,
                  ...{
                    localControl: localControl?.treeIds,
                  },
                };
              }

              return item;
            case ControlType.numberRules:
              _.set(item, 'numberRule.label', _.get(item, 'numberRule.name'));
              _.set(item, 'numberRule.value', _.get(item, 'numberRule.id'));

              return item;

            case ControlType.numeric: {
              const unitObj = !_.get(item, 'unitId')
                ? undefined
                : {
                    label: _.get(item, 'unitName'),
                    value: _.get(item, 'unitId'),
                    key: _.get(item, 'unitId'),
                  };

              _.set(item, 'unitId', unitObj);

              return item;
            }

            default:
              return item;
          }
        });

        const formValue = {
          ..._.omit(res?.data, 'controls'),
          ...{
            controls: formatControls,
          },
        };

        // form.setFieldsValue(formValue as any);
        form.setFieldsValue(formValue);
        setOriginFormValue(formValue);

        _.delay(() => setDetailLoading(false), 10);
      })
      .finally(() => {
        setDetailLoading(false);
      });
  };

  // 切换执行权限时, 清空其他权限下的选项
  const onChangePrivilegeType = useCallback((type: PrivilegeType) => {
    let clearProps: string[] = [];

    if (type === PrivilegeType.users) {
      clearProps = ['authRoles', 'authDepartments'];
    } else if (type === PrivilegeType.departments) {
      clearProps = ['authUsers', 'authRoles'];
    } else if (type === PrivilegeType.roles) {
      clearProps = ['authUsers', 'authDepartments'];
    } else if (type === PrivilegeType.executors) {
      clearProps = ['authUsers', 'authDepartments'];
    }
    form.setFieldsValue(_.pick(stepDefaultValue, clearProps));
    forceUpdate();
  }, []);
  // 关闭电子签名时, 清空已选的权限对象
  const onSwitchDSign = useCallback((enable: boolean) => {
    if (!enable) {
      form.setFieldsValue(
        _.pick(stepDefaultValue, ['digitalType', 'digitalUserIds', 'digitalRoleIds']),
      );
    }
    forceUpdate();
  }, []);
  // 切换电子签名类型时, 清空其他选项对应的类型
  const onChangeDSignType = useCallback((type: DigitalType) => {
    if (type === DigitalType.users) {
      form.setFieldsValue(_.pick(stepDefaultValue, ['digitalRoleIds']));
    } else if (type === DigitalType.roles) {
      form.setFieldsValue(_.pick(stepDefaultValue, ['digitalUserIds']));
    }
    forceUpdate();
  }, []);
  // 切换下个步骤类型时, 清空人工选择下的步骤
  const onChangeNextStepType = useCallback((type: NextStepType) => {
    if (type !== NextStepType.specified) {
      form.setFieldsValue(_.pick(stepDefaultValue, ['manualNextStepIds']));
    }
    if (type !== NextStepType.condition) {
      form.setFieldsValue(_.pick(stepDefaultValue, ['formulaId']));
    }
    forceUpdate();
  }, []);
  // 关闭或打开循环时, 重置循环配置
  const onSwitchLoop = useCallback(() => {
    form.setFieldsValue(
      _.pick(stepDefaultValue, [
        'loopCount',
        'singleLoopTimeRestrict',
        'singleLoopIntervalTime',
        'loopIntervalUnit',
        'loopExecRestrictRule',
        'nextStepType',
        'manualNextStepIds',
      ]),
    );
    _.delay(forceUpdate, 0);
  }, []);
  // 关闭单次循环时间限制时, 清空已有的相关配置
  const onSwitchSingleLoopTimeRestrict = useCallback((enable: boolean) => {
    if (!enable) {
      form.setFieldsValue(_.pick(stepDefaultValue, ['singleLoopIntervalTime', 'loopIntervalUnit']));
    }
    forceUpdate();
  }, []);
  // 并行切串行时, 清空电子签名
  const onSwitchGroupType = useCallback((groupType: GroupType) => {
    if (groupType === GroupType.serial) {
      form.setFieldsValue(
        _.pick(stepDefaultValue, [
          'enableDigitalSignature',
          'digitalType',
          'digitalUserIds',
          'digitalRoleIds',
        ]),
      );
    }
    forceUpdate();
  }, []);

  const baseItems: DataFormLayoutInfoBlock['items'] = _.compact([
    ...NumberRulesStandardHook({
      label: `${stepEntityName}编号`,
      form,
      edit: !isCreate,
      disabledState: !isCreate,
      objectCode,
      fieldCode: 'code',
      rules: [{ max: 64, message: '不超过64个字符' }, { validator: textValidator2 }],
    }),
    {
      label: `${stepEntityName}名称`,
      name: 'name',
      rules: [{ required: true }, { type: 'string', max: 64, message: '不超过64个字符' }],
      render: () => <Input />,
    },
    isGroup && {
      label: '类型',
      name: 'groupType',
      rules: [{ required: true }],
      render: () => <Select options={groupTypeMappings} onChange={onSwitchGroupType} />,
    },
    !isGroup && {
      label: '执行权限',
      required: true,
      render: () => {
        const privilegeType = getValue('privilegeType');
        let name = '';
        let content = null;
        let useFormatProps = false;

        if (privilegeType === PrivilegeType.users) {
          name = 'authUsers';
          content = (
            <UserOrDepartmentSelectWithDialog isNewFormat labelInValue isMultiple isSelectUser />
          );
        } else if (privilegeType === PrivilegeType.departments) {
          name = 'authDepartments';
          content = (
            <UserOrDepartmentSelectWithDialog isNewFormat labelInValue isMultiple={false} />
          );
        } else if (privilegeType === PrivilegeType.roles) {
          name = 'authRoles';
          content = <RoleSelect />;
          useFormatProps = true;
        }
        return (
          <>
            <Form.Item name="privilegeType" rules={[{ required: true }]} noStyle>
              <Select options={privilegeTypeMappings} onChange={onChangePrivilegeType} />
            </Form.Item>
            {content ? (
              <Form.Item
                name={name}
                rules={[
                  {
                    required: true,
                    message: `请选择${lookup('sop.privilegeType', privilegeType)}`,
                  },
                ]}
                style={{ margin: '10px 0 0' }}
                {...(useFormatProps ? arrayItemProps : {})}
              >
                {content}
              </Form.Item>
            ) : null}
          </>
        );
      },
    },
    !isGroup && {
      label: '电子签名',
      name: 'enableDigitalSignature',
      valuePropName: 'checked',
      render: () => <Switch {...switchTextProps} onChange={onSwitchDSign} />,
    },
    getValue('enableDigitalSignature') && {
      label: '电子签名权限',
      required: true,
      render: () => {
        const digitalType = getValue('digitalType');
        let name = '';
        let content = null;
        let useFormatProps = false;

        if (digitalType === DigitalType.users) {
          name = 'digitalUserIds';
          content = (
            <UserOrDepartmentSelectWithDialog isNewFormat isMultiple={false} isSelectUser />
          );
        } else if (digitalType === DigitalType.roles) {
          name = 'digitalRoleIds';
          content = <RoleSelect />;
          useFormatProps = true;
        }
        return (
          <>
            <Form.Item noStyle name="digitalType" rules={[{ required: true }]}>
              <Select onChange={onChangeDSignType} options={digitalTypeMappings} />
            </Form.Item>
            {name ? (
              <Form.Item
                name={name}
                rules={[
                  { required: true, message: `请选择${lookup('sop.digitalType', digitalType)}` },
                ]}
                style={{ margin: '10px 0 0' }}
                {...(useFormatProps ? arrayItemProps : {})}
              >
                {content}
              </Form.Item>
            ) : null}
          </>
        );
      },
    },
    {
      label: '循环开关',
      name: 'enableSelfLoop',
      valuePropName: 'checked',
      render: () => <Switch {...switchTextProps} onChange={onSwitchLoop} />,
    },
    getValue('enableSelfLoop') && {
      label: '循环次数',
      name: 'loopCount',
      rules: [
        { required: true, message: '请填写循环次数' },
        { type: 'number', min: 2, message: '循环次数必须大于等于2' },
        { type: 'number', max: 99, message: '循环次数不能大于99' },
      ],
      render: () => <InputNumber precision={0} />,
    },
    getValue('enableSelfLoop') && {
      label: '单次循环时间限制',
      name: 'singleLoopTimeRestrict',
      valuePropName: 'checked',
      render: () => <Switch {...switchTextProps} onChange={onSwitchSingleLoopTimeRestrict} />,
    },
    showSelfLoopTimeConfig() && {
      label: '单次循环时间',
      name: 'singleLoopIntervalTime',
      rules: [
        { required: true, message: '请填写单次循环时间' },
        { type: 'number', min: 1, message: '单次循环时间必须大于等于1' },
      ],
      render: () => <InputNumber min={1} precision={0} />,
    },
    showSelfLoopTimeConfig() && {
      label: '单次循环时间单位',
      name: 'loopIntervalUnit',
      render: () => <Select options={_.take(timeMappings, 2)} />,
    },
    getValue('enableSelfLoop') &&
      getValue('singleLoopTimeRestrict') && {
        label: '循环执行限制',
        name: 'loopExecRestrictRule',
        render: () => <Select options={loopExecRestrictRuleMappings} />,
      },
    // 不支持配置后续步骤的情况: 1. 新建时 2. 终止步骤 3. 并行步骤组内的子步骤 4. 步骤组 5. 循环开启时
    isCreate ||
    getValue('last') ||
    ancestorGroupProps.isParallel ||
    isGroup ||
    getValue('enableSelfLoop')
      ? {
          name: 'nextStepType',
          hidden: true,
          render: () => null,
        }
      : {
          label: '跳转方式',
          name: 'nextStepType',
          render: () => <Select options={nextStepTypeMappings} onChange={onChangeNextStepType} />,
        },
    getValue('nextStepType') === NextStepType.specified && {
      label: '可选步骤',
      // colon: false,
      name: 'manualNextStepIds',
      rules: [{ required: true, message: '请选择后续步骤' }],
      render: () => (
        <LimitableSelect options={manualNextStepOptions} mode="multiple" maxCount={7} allowClear />
      ),
    },
    // getValue('nextStepType') === NextStepType.condition && {
    //   label: '执行条件',
    //   name: 'execRule',
    //   rules: [
    //     { required: true, message: '请填写执行条件' },
    //     { type: 'string', max: 1000, message: '执行条件最多1000个字符' },
    //   ],
    //   render: () => <Input.TextArea maxLength={1000} />,
    // },
    // 后台反馈条件判断，为3时用于自定义函数跳转逻辑
    getValue('nextStepType') === NextStepType.condition && {
      label: '自定义函数跳转逻辑',
      // colon: false,
      name: 'formulaId',
      rules: [{ required: true, message: '请输入公式' }],
      render: () => <FormulaEditorModal objectCode={'SOPTask'} />,
    },
    {
      name: 'first',
      hidden: true,
      render: () => null,
    },
    {
      name: 'last',
      hidden: true,
      render: () => null,
    },
    // 循环的步骤组内的子步骤不支持配置结束后执行
    // !ancestorGroupProps.hasLoop && {
    //   label: '结束后执行',
    //   name: 'execAfterFinish',
    //   render: () => <Input.TextArea />,
    // },
  ]);
  let baseInfoItems = baseItems;

  if (isFolded) {
    // 步骤组不折叠
    if (isGroup) {
      expand();
    } else {
      baseInfoItems = [
        ...baseItems.slice(0, 2),
        {
          isFullLine: true,
          render: () => (
            <Button type="link" onClick={expand} style={{ marginLeft: 34 }}>
              展开全部
            </Button>
          ),
        },
      ];
    }
  }
  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    title: `${stepEntityName}基本信息`,
    items: baseInfoItems,
  };

  const controlList: DataFormLayoutInfoBlock = {
    column: 1,
    title: '控件信息',
    items: [
      {
        label: '',
        isFullLine: true,
        render: () => (
          <ControlEditor
            loading={detailLoading}
            form={form}
            localKeys={localKeys}
            setLocalKeys={(localKeys) => {
              setLocalKeys(localKeys);
            }}
            bizType={bizType}
          />
        ),
      },
    ],
  };
  const info =
    currentNode?.type === StepEntityType.stepGroup ? [baseInfo] : [baseInfo, controlList];

  const handleCancel = (isEdit?: boolean) => {
    if (isEdit) {
      fetchSopStepDetailData({
        id: _.toNumber(currentNode?.id),
        referenceId: currentNode?.referenceId as number,
        // referenceId: referenceId.current as number,
      });

      return;
    }
    // 检查是否有未保存的字段, 将结果刷新到页面上后再跳转
    forceUpdate();
    // 去除新增未成功的 treeNode
    const newTree = delTreeNode(steps as StepTreeData[], NEW_STEP_NUMBER_ID);

    setLeftData(formatTree(newTree));
    setCurrentNode(undefined);
    setSelectedKeys([]);
  };

  const handleFinish = () => {
    form
      .validateFields()
      .then(() => {
        const clearProps = omit(['creatorId', 'operatorId', 'createdAt', 'updatedAt']);
        // validateFields拿不到当前未渲染的控件数据, 故需另取一次
        const values = clearProps(form.getFieldsValue(true));
        // const refId = referenceId.current;
        const refId = currentNode?.referenceId;

        // 按照本地顺序给控件排序
        const controls = localKeys.map((key, idx) => {
          let value = values.controls.find((item: LocalControlItem) => item._localKey === key);

          // 新控件不传id
          if (value.id === NEW_CONTROL_ID) {
            value = _.omit(value, 'id');
          } else {
            value = clearProps(value);
          }
          const handleFormat = () => {
            value.optionValue = value.optionValue?.map(
              (item: { key?: string; value?: string | number; label: string }) => {
                return {
                  label: item.label,
                  value: item.label,
                };
              },
            );
          };

          // 如果是选择器控件，处理optionValue
          value.controlType === ControlType.selector && handleFormat();

          return {
            ...value,
            sortIndex: idx + 1,
            stepId: _.toNumber(currentNode?.id),
            referenceId: refId,
          };
        });

        // 被删除的控件加上删除标记, 放到队尾;
        // fix:如果未落库，那么被删除的控件不用传给后台
        // const deletedControls = values.controls
        //   .filter(
        //     (item: LocalControlItem) =>
        //       _.has(item, 'id') &&
        //       _.get(item, 'id') !== NEW_CONTROL_ID &&
        //       !localKeys.includes(item._localKey),
        //   )
        //   .map((item: LocalControlItem) => ({ ...clearProps(item), deleteFlag: true }));

        // _.get(originFormValue, 'controls') 和页面UI展示控件一致，需要求最初从后台获取的控件和页面实时控件的差集，即为被删除id
        const deletedControls = differenceByObjArray(
          _.get(originFormValue, 'controls'),
          form.getFieldValue('controls'),
        ).map((item: LocalControlItem) => ({
          ...clearProps(item),
          deleteFlag: true,
        }));

        // 删去本地key
        const controlValues = controls.concat(deletedControls).map(omit('_localKey'));
        // const formatControlValues = ControlType.user
        //   ? controlValues.map((item) => {
        //       return extractIdsForStepofUser(item);
        //     })
        //   : controlValues;
        // wendy TODO
        // 新建步骤同时添加了控件时，将controls里stepId:-999 标识改为 stepId:0
        const compactControlValues = controlValues.map((item) => {
          if (item.stepId === NEW_STEP_NUMBER_ID) {
            return { ...item, ...{ stepId: 0 } };
          }
          return item;
        });

        // return {
        //   ...item,
        //   ...{
        //     bizField: extractIdsForStepofResource(['resourceIds'], item.bizField),
        //   },
        // };
        const formatControlValues = compactControlValues.map((item) => {
          switch (item.controlType) {
            case ControlType.user:
              return extractIdsForStepofUser(item);
            case ControlType.resourceWithFixedParams:
              return {
                ...item,
                ...{
                  bizField: extractIdsForStepofResource(['resourceIds', 'paramIds'], item.bizField),
                },
              };
            case ControlType.resourceWithSelectedParams:
              return {
                ...item,
                ...{
                  bizField: extractIdsForStepofResource(['resourceIds'], item.bizField),
                },
              };
            case ControlType.associatedField: {
              if (item.fieldSource === FieldSource.currentSheet) {
                const localControlIds = _.get(item, 'localControl');

                return {
                  ..._.omit(item, 'relateObject'),
                  ...{
                    localControl: localControlIds?.pop(),
                  },
                };
              }
              return item;
            }
            case ControlType.numeric: {
              const unitId = _.get(item, 'unitId')?.value;

              _.set(item, 'unitId', unitId);

              return item;
            }
            case ControlType.numberRules:
              _.set(item, 'ruleId', _.get(item, 'numberRule.value'));

              return _.omit(item, 'numberRule');

            default:
              return item;
          }
        });

        const submitValue: any = extractIdsForStep({
          ...values,
          referenceId: refId,
          controls: formatControlValues,
        });
        const handler = async () => {
          message.success(`${lookup('sop.stepEntityType', currentNode?.type)}保存成功！`);
          await fetchDetails();
        };

        setSubmitting(true);
        if (isCreate) {
          submitValue.id = undefined;
          submitValue.type = currentNode?.type;
          if (currentNode?.parentId) {
            submitValue.parentId = _.toNumber(currentNode?.parentId);
          }
          if (currentNode?.prevId) {
            submitValue.prevId = _.toNumber(currentNode?.prevId);
          }

          fetchSopStepAdd(submitValue)
            .then((res) => {
              handler();
              fetchSopStepDetailData({
                id: _.toNumber(res?.data?.stepId),
                // referenceId: referenceId.current as number,
                referenceId: res?.data?.referenceId as number,
              });
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => setSubmitting(false));
        } else {
          submitValue.id = _.toNumber(currentNode?.id);
          submitValue.referenceId = _.toNumber(currentNode?.referenceId);
          // wendy TODO  submitValue 未统一
          submitValue.prevId = _.toNumber(currentNode?.prevId);
          submitValue.last = currentNode?.last;
          submitValue.first = currentNode?.first;

          fetchSopStepUpdate(submitValue)
            .then((res) => {
              handler();
              fetchSopStepDetailData({
                // id: _.toNumber(submitValue.id),
                id: _.toNumber(res?.data?.stepId),
                // referenceId: referenceId.current as number,
                referenceId: res?.data?.referenceId as number,
              });
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => setSubmitting(false));
        }
      })
      .catch((errInfo) => {
        console.error(errInfo);
        message.error('请正确填写步骤配置');
      });
  };

  const handleCreateStepOrGroup = (stepType: StepEntityType) => {
    handleAddNewStep({
      parentId: 0,
      prevId: 0,
      type: stepType,
      node: currentNode,
    });
  };
  const handleNeedSave = () => {
    const newValues = form.getFieldsValue(true);

    const formChanged = !_.isEqual(
      {
        ...newValues,
        controls: newValues?.controls?.map(omit(OMIT_BY_EQUAL)),
      },
      originFormValue,
    );
    const needSave = currentNode ? formChanged : false;

    return !newNodeIsSaved(selectedKeys[0], needSave, currentNode);
  };
  const selectNodeFn = (node: StepOverviewData) => {
    if (handleNeedSave()) {
      return;
    }
    const { id, referenceId } = node;

    fetchSopStepDetailData({
      id: _.toNumber(id),
      referenceId: referenceId as number,
    });
    setSelectedKeys([id]);
  };
  const expandFn = (
    expandedKeys: React.Key[],
    info: {
      node: EventDataNode;
      expanded: boolean;
    },
  ) => {
    setExpandedKeys([]);

    if (!info?.expanded) {
      setExpandedKeys(expandedKeys.filter((item) => item !== info?.node?.id));
    } else {
      setExpandedKeys(expandedKeys);
    }
  };

  const handleAddNewStep = async (config: {
    parentId: any;
    prevId: any;
    type: any;
    node: any;
    isDelete?: any;
    isChild?: any;
  }) => {
    const { parentId, prevId, type, isDelete, node, isChild } = config;

    if (isChild && node?.ancestorCounts >= MAX_LAYER_COUNT - 1) {
      message.error(`最多添加${MAX_LAYER_COUNT}层`);
      return;
    }

    if (handleNeedSave()) {
      return;
    }
    if (isDelete) {
      try {
        await fetchSopStepDelete({
          id: node?.id,
          referenceId: _.toNumber(node?.referenceId),
          name: node?.name,
          reason: form.getFieldValue('delReason'),
          type: _.toNumber(node?.type),
        });

        await fetchDetails();
        // 删除本步骤时，不请求step/_detail接口
        if (node.id !== currentNode?.id) {
          await fetchSopStepDetailData({
            id: _.toNumber(currentNode?.id),
            referenceId: currentNode?.referenceId as number,
          });
        }

        // 如果删除的是当前id，则选择第一个数据
        if (node?.id === currentNode?.id) {
          setCurrentNode(undefined);
          setSelectedKeys([]);
        }
        // delForm.resetFields();
        message.success('删除成功！');
      } catch (error) {
        console.log('error', error);
      }
    } else {
      const { stepCount, stepGroupCount } = countSteps(steps);
      const title = `${lookup('sop.stepEntityType', type)}${
        (type === StepEntityType.stepGroup ? stepGroupCount : stepCount) + 1
      }`;
      const { newSteps, newNode } = insertNewTreeNode(
        _.cloneDeep(formatTree(steps)),
        parentId,
        prevId,
        type,
        title,
        node?.referenceId || referenceId.current,
      );

      setCurrentNode(newNode);
      setSelectedKeys([newNode.id]);
      setExpandedKeys(getAncestorIdsByParentId(steps, parentId));

      form.resetFields();
      form.setFieldsValue(_.cloneDeep(stepDefaultValue));
      form.setFields([
        {
          name: 'name',
          value: title,
        },
        // 如果是新建, 根据树结构填写是否为起始、终止步骤
        { name: 'first', value: _.toNumber(newNode.prevId) === 0 },
        { name: 'last', value: isLastStep(steps, _.toNumber(newNode.prevId)) },
      ]);

      setLeftData(formatTree(newSteps));
      setSteps(newSteps);
    }
    // 新建时清空原有页面数据
    setOriginFormValue({});
  };

  const handleSopStepSort = async (treeData: StepOverviewList) => {
    if (handleNeedSave()) {
      return;
    }

    try {
      setDetailLoading(true);
      // setLeftData(formatTree(treeData));
      await fetchSopStepSort(mapSteps(treeData, extractIdsForStep));
      await fetchDetails(true);

      setDetailLoading(false);
    } catch (error) {
      message.error('拖动排序失败，请刷新页面重试');
    }
  };

  // TODO  暂修改localkeys,后续能否在组相关清空整个preview的值
  useEffect(() => {
    setLocalKeys([]);

    if (currentNode?.type === StepEntityType.stepGroup || currentNode?.id === NEW_STEP_NUMBER_ID) {
      expand(false);
    } else {
      expand(true);
    }
  }, [currentNode]);

  useEffect(() => {
    setListLoading(true);
    fetchSopDetail({ sopId: _.toNumber(sopId) }, { legacy: true })
      .then((res) => {
        setListLoading(false);

        const steps = res.data?.steps ?? [];

        // 从SOP方案详情里取出 referenceId, 用于查步骤详情
        referenceId.current = res.data?.referenceId;
        setBizType(res.data?.bizType!);

        if (steps.length) {
          fetchSopStepDetailData({
            id: _.toNumber(formatTree(steps)[0].key),
            // referenceId: referenceId.current as number,
            referenceId: res.data?.referenceId as number,
          });
          // setCurrentNode(formatTree(steps)[0]);
          setSelectedKeys([formatTree(steps)[0].id]);
          setSteps(steps);
          setLeftData(formatTree(steps));
        }
      })
      .finally(() => {
        setListLoading(false);
      });
  }, []);

  useEffect(() => {
    const parentId = _.toNumber(currentNode?.parentId);
    // 找到父节点, 拉出所有兄弟步骤（不包括自己）
    // 如果是新建, 根据parentId找; 如果是编辑, 根据自己的id找
    const siblings = isCreate
      ? getSiblingsByParentId(steps, parentId)
      : getSiblingsById(steps, _.toNumber(currentNode?.id));

    setManualNextStepOptions(
      siblings.map(({ name, id }) => ({ label: name, value: id as number })),
    );
    setAncestorGroupProps({
      isParallel: isCreate
        ? isParentParallel(steps, parentId, true)
        : isParentParallel(steps, _.toNumber(currentNode?.id)),
      hasLoop: isCreate
        ? hasLoopableAncestor(steps, parentId, true)
        : hasLoopableAncestor(steps, _.toNumber(currentNode?.id)),
      // isParallel: isParentParallel(steps, parentId, true),
      // hasLoop: hasLoopableAncestor(steps, parentId, true),
    });
  }, [currentNode, steps]);

  const shouldLeave = useCallback(() => {
    const newValues = form.getFieldsValue(true);

    const noChange = currentNode
      ? _.isEqual(
          {
            ...newValues,
            controls: newValues?.controls?.map(omit(OMIT_BY_EQUAL)),
          },
          originFormValue,
        )
      : false;

    // eslint-disable-next-line no-alert
    return noChange || window.confirm('您当前步骤编辑的内容尚未保存，是否退出？');
  }, [form, originFormValue, currentNode]);

  useEffect(() => {
    const unblock = history.block(() => {
      if (shouldLeave()) {
        unblock();
        return;
      }
      return false;
    });

    return () => unblock();
  }, [shouldLeave]);

  const formTitle = (title: string) => (
    <div className="bl-descriptionTitle">
      <div className={classnames(styles.textExplise)}>
        <span className="title-left-border" />
        <span className="title-left" title={title}>
          {title}
        </span>
      </div>
    </div>
  );
  const renderEmpty = (children?: ReactNode) => {
    return (
      <div style={{ padding: '24px', height: '100%', backgroundColor: '#ffffff' }}>
        {children}
        <Empty className={styles.center} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );
  };
  const detailContent = () => {
    return !currentNode ? (
      renderEmpty(formTitle('基本信息'))
    ) : (
      <DataFormLayout
        key={currentNode?.id}
        form={form}
        formProps={formProps}
        formLayout="horizontal"
        footer={false}
        info={info}
        confirmLoading={submitting}
        infoBlockStyleProps={{ paddingBottom: 0, borderBottom: 'none' }}
        // loading={detailLoading}
      />
    );
  };
  const ds = formatTree(getDisplaySteps(steps as StepRowData[]) as StepTreeData[]);

  const treeRender = (
    <div className={styles.treeWrap}>
      {formTitle('SOP步骤列表')}
      <div className={styles.treeSearch}>
        <Input.Search
          // style={{ width: '252px' }}
          // style={{ maxWidth: '252px' }}
          style={{ maxWidth: '100%' }}
          placeholder="请输入搜索关键字"
          onSearch={(val) => {
            if (handleNeedSave()) {
              return;
            }
            const result = searchSteps(val, steps as StepRowData[]);

            if (!result) {
              return;
            }
            const { ancestors } = result;

            setExpandedKeys(ancestors);
          }}
          allowClear
        />
      </div>
      <div className={styles.btnGroup}>
        <Space size={[16, 8]} wrap>
          <Button onClick={() => handleCreateStepOrGroup(StepEntityType.stepGroup)}>
            <BlIcon type="iconxinjiantianjia" />
            {`新建${lookup('sop.stepEntityType', StepEntityType.stepGroup)}`}
          </Button>
          <Button type="primary" onClick={() => handleCreateStepOrGroup(StepEntityType.step)}>
            <BlIcon type="iconxinjiantianjia" />
            {`新建${lookup('sop.stepEntityType', StepEntityType.step)}`}
          </Button>
        </Space>
      </div>
      <div className={styles.treeCon}>
        <LeftTree
          treeData={ds}
          originTreeData={formatTree(steps)}
          selectedKeys={selectedKeys}
          expandedKeys={expandedKeys}
          selectNodeFn={selectNodeFn}
          expandFn={expandFn}
          createStepFn={handleAddNewStep}
          sopStepSortFn={handleSopStepSort}
          reFetch={fetchDetails}
          needSaveFn={handleNeedSave}
        />
      </div>
    </div>
  );

  const view = useTreeContent({
    isSearch: false,
    draggable: true,
    treeRender,
    detailContent,
    showLine: { showLeafIcon: false },
    height: '100%',
    resizableProp: {
      width: 330,
      height: '100%',
      minConstraints: [188, maxHeight],
    },
    showExpandBtn: true,
    virtual: false,
    checkable: false,
  });

  return (
    <>
      <div className={styles.stepEditWrap} id="stepEditPage">
        <Spin spinning={listLoading || detailLoading}>
          <div className={styles.stepEditCon}>{view}</div>
          <div className={styles.footer}>
            <div className="steps-action" style={{ display: 'flex', justifyContent: 'center' }}>
              <Space size={24}>
                <Button
                  key="back"
                  onClick={() => {
                    _.delay(() => history.push(`/sop/processEngine/scheme/${sopId}/edit`), 100);
                  }}
                >
                  返回步骤列表
                </Button>
                {selectedKeys[0] === NEW_STEP_NUMBER_ID && (
                  <Button key="cancel" disabled={!currentNode} onClick={() => handleCancel()}>
                    取消新建
                  </Button>
                )}

                {selectedKeys[0] !== NEW_STEP_NUMBER_ID && (
                  <Button
                    key="cancalEdit"
                    disabled={!currentNode}
                    onClick={() => handleCancel(true)}
                  >
                    取消编辑
                  </Button>
                )}
                <Button
                  key="ok"
                  disabled={!currentNode || submitting}
                  type="primary"
                  onClick={handleFinish}
                >
                  确认
                </Button>
              </Space>
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
};

export default EditSOPStep;
