/**
 * @mappings 通用的名-值映射表
 */

import { TemplateType } from '.';
import { mappingsFactory } from '../utils';

export const templateType = mappingsFactory(
  ['生产报告', TemplateType.productionReport],
  ['维保报告', TemplateType.maintenanceReport],
  ['维修报告', TemplateType.repairReport],
);
