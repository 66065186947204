import React from 'react';
import { HELP_TIP_CONTENT_TEXT } from 'src/page/import/constaint';
import styles from '../import.module.scss';
import { BlIcon } from 'components';
import { Popover } from 'antd';

type Props = {
  title: string;
};

const PageHeader: React.FC<Props> = (props) => {
  const { title } = props;
  /**
   * tip帮助标签内容
   */
  const helpTipContent = () => (
    <div style={{ padding: 8, backgroundColor: '#fff' }}>
      {HELP_TIP_CONTENT_TEXT?.map((text) => (
        <div key={text}>{text}</div>
      ))}
    </div>
  );

  return (
    <div className={styles.header}>
      <span className={styles.text}>{title}</span>
      <Popover
        title={'帮助提示'}
        overlayStyle={{ width: 300 }}
        color={'#f2f2f2'}
        overlayClassName={styles.helpPopover}
        mouseLeaveDelay={0.3}
        content={helpTipContent}
        placement="rightTop"
      >
        <BlIcon className={styles.helpIcon} type="iconwentizhiyinbangzhu" />
        <span>帮助</span>
      </Popover>
    </div>
  );
};

export default PageHeader;
