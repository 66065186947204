import { useEffect, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';
import { Form, Checkbox, message, DatePicker, Input, Popover } from 'antd';
import { validatorCheckTwoSidesTrim } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import { NumberRulesStandardHook, SearchSelect } from 'src/components';
import { CRUD, ObjectCode, YN } from 'src/dict/common';
import _ from 'lodash';
import { fetchBatchNoSave, FetchBatchNoSaveRequest } from 'src/api/ytt/material-domain/Batch_No';
import { getMaterialbBatchAttrs } from 'src/entity/material';
import { LabeledValue } from 'antd/lib/select';
import _Time from 'src/utils/dataTypes/time';
import { fetchInventoryElementBatchDetail } from 'src/api/ytt/inventory-domain';
import { BatchAttrs } from 'src/dict/material';
import { replaceSign } from 'src/utils/constants';
import { numberAlphabetSpecialSymbols } from 'src/utils/formValidators';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import {
  formatCustomFieldsInData,
  ICustomFields,
  injectCustomFieldInfos,
  useCustomFieldCombinedData,
} from 'src/components/customField';

interface Props {
  refreshTable?: () => void;
  refreshDetails?: () => void;
  onCancel?: () => void;
  saveCallback?: (data: any) => void;
  batchNo?: string | undefined;
  materialId?: number | undefined;
  materialinfo?: any;
}

// 选择框参数，物料状态，1启用中、0已停用
const materialParams = {
  enableFlag: 1,
  batchManagementEnable: YN.yes,
};
// 选择框参数，客户状态，1启用中、0已停用
const customerParams = {
  status: [1],
};
// 选择框参数，供应商，1启用中、0已停用
const supplierParams = {
  status: [1],
};

const BaseInfo = (props: Props) => {
  const {
    onCancel,
    refreshTable,
    refreshDetails,
    saveCallback,
    batchNo,
    materialId,
    materialinfo,
  } = props;
  const [form] = Form.useForm();
  /** 获取对象自定义字段 */
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.batchNo);
  const type = _.isUndefined(batchNo) ? CRUD.create : CRUD.edit;
  const [keepOn, sekeepOn] = useState(type === CRUD.create && !materialinfo);
  const defaultStyle = {
    width: '100%',
  };
  const defaultMatertial = {
    value: JSON.stringify(materialinfo || {}),
    key: JSON.stringify(materialinfo || {}),
    label: materialinfo?.baseInfo?.code || '',
  };
  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    items: [
      ...NumberRulesStandardHook({
        label: '批次号',
        isFullLine: true,
        form,
        edit: type === CRUD.edit,
        objectCode: ObjectCode.batchNo,
        fieldCode: 'code',
        rules: [
          {
            max: 255,
            message: '不可超过255个字符',
          },
          { validator: validatorCheckTwoSidesTrim('批次号') },
          numberAlphabetSpecialSymbols,
        ],
      }),
      {
        label: '物料',
        isFullLine: true,
        name: 'materialId',
        rules: [{ required: true, message: '物料必填' }],
        initialValue: defaultMatertial,
        render: () => (
          <SearchSelect
            labelInValue
            disabled={materialinfo?.baseInfo?.code}
            fetchType={'materialCompleteInfo'}
            placeholder={'请选择'}
            params={materialParams}
          />
        ),
      },
      {
        noStyle: true,
        isFullLine: true,
        dependencies: ['materialId'],
        render: (FormItemProps: any) => () => {
          const material = JSON.parse(form.getFieldValue('materialId')?.value ?? '{}');
          const batchAttrs = getMaterialbBatchAttrs(material, BatchAttrs.customer);

          return (
            <Form.Item
              label={'客户'}
              name={'customer'}
              hidden={!batchAttrs.batchManagementEnable || !batchAttrs.displayEnable}
              rules={[
                {
                  required:
                    batchAttrs.batchManagementEnable &&
                    batchAttrs.displayEnable &&
                    !batchAttrs.nullEnable,
                  message: '客户必填',
                },
              ]}
              {...FormItemProps}
            >
              <SearchSelect
                labelInValue
                fetchType={'customer'}
                placeholder={'请选择'}
                params={customerParams}
              />
            </Form.Item>
          );
        },
      },
      {
        noStyle: true,
        isFullLine: true,
        dependencies: ['materialId'],
        render: (FormItemProps: any) => () => {
          const material = JSON.parse(form.getFieldValue('materialId')?.value ?? '{}');
          const batchAttrs = getMaterialbBatchAttrs(material, BatchAttrs.supplier);

          return (
            <Form.Item
              label={'供应商'}
              name={'supplier'}
              hidden={!batchAttrs.batchManagementEnable || !batchAttrs.displayEnable}
              rules={[
                {
                  required:
                    batchAttrs.batchManagementEnable &&
                    batchAttrs.displayEnable &&
                    !batchAttrs.nullEnable,
                  message: '供应商必填',
                },
              ]}
              {...FormItemProps}
            >
              <SearchSelect
                labelInValue
                fetchType={'supplier'}
                placeholder={'请选择'}
                params={supplierParams}
              />
            </Form.Item>
          );
        },
      },
      {
        noStyle: true,
        isFullLine: true,
        dependencies: ['materialId'],
        render: (FormItemProps: any) => () => {
          const material = JSON.parse(form.getFieldValue('materialId')?.value ?? '{}');
          const batchAttrs = getMaterialbBatchAttrs(material, BatchAttrs.supplierBatch);

          return (
            <Form.Item
              label={'供应商批次'}
              name={'supplierBatch'}
              hidden={!batchAttrs.batchManagementEnable || !batchAttrs.displayEnable}
              rules={[
                {
                  required:
                    batchAttrs.batchManagementEnable &&
                    batchAttrs.displayEnable &&
                    !batchAttrs.nullEnable,
                  message: '供应商批次必填',
                },
                {
                  max: 255,
                  message: '不可超过255个字符',
                },
                { validator: validatorCheckTwoSidesTrim('供应商批次') },
                numberAlphabetSpecialSymbols,
              ]}
              validateFirst
              {...FormItemProps}
            >
              <Input placeholder={'请输入'} />
            </Form.Item>
          );
        },
      },
      {
        noStyle: true,
        isFullLine: true,
        dependencies: ['materialId'],
        render: (FormItemProps: any) => () => {
          const material = JSON.parse(form.getFieldValue('materialId')?.value ?? '{}');
          const batchAttrs = getMaterialbBatchAttrs(material, BatchAttrs.productionDate);

          return (
            <Form.Item
              label={'生产日期'}
              name={'produceAt'}
              hidden={!batchAttrs.batchManagementEnable || !batchAttrs.displayEnable}
              rules={[
                {
                  required:
                    batchAttrs.batchManagementEnable &&
                    batchAttrs.displayEnable &&
                    !batchAttrs.nullEnable,
                  message: '生产日期必填',
                },
              ]}
              {...FormItemProps}
            >
              <DatePicker format={'YYYY-MM-DD'} style={defaultStyle} />
            </Form.Item>
          );
        },
      },
      {
        noStyle: true,
        isFullLine: true,
        dependencies: ['materialId'],
        render: (FormItemProps: any) => () => {
          const material = JSON.parse(form.getFieldValue('materialId')?.value ?? '{}');
          const batchAttrs = getMaterialbBatchAttrs(material, BatchAttrs.validUntil);

          return (
            <Form.Item
              label={'有效期至'}
              name={'validTo'}
              hidden={!batchAttrs.batchManagementEnable || !batchAttrs.displayEnable}
              rules={[
                {
                  required:
                    batchAttrs.batchManagementEnable &&
                    batchAttrs.displayEnable &&
                    !batchAttrs.nullEnable,
                  message: '有效期至必填',
                },
              ]}
              {...FormItemProps}
            >
              <DatePicker format={'YYYY-MM-DD'} style={defaultStyle} />
            </Form.Item>
          );
        },
      },
    ],
    column: 1,
  };

  const customInfo: DataFormLayoutInfoBlock = injectCustomFieldInfos({
    customFields: customFields || {},
    type: 'form',
    formConfig: { form },
  });

  const initEdit = async () => {
    try {
      if (!batchNo) return;
      const res: any = await fetchInventoryElementBatchDetail({ batchNo, materialId });

      form.setFieldsValue({ ...res?.data, code: res?.data?.batchNo });
    } catch (err) {
      console.log(err);
    }
  };

  const handleFormateCreate = (value: {
    code: string;
    numberRuleId: number;
    materialId: LabeledValue;
    customer: LabeledValue;
    supplier: LabeledValue;
    supplierBatch: string;
    produceAt: string; // '2021-12-15T08:56:58.254Z';
    validTo: string; // '2021-12-30T08:57:01.175Z';
    customFields?: ICustomFields;
  }): FetchBatchNoSaveRequest => {
    const material = JSON.parse(value?.materialId.value as string);

    return {
      batchNo: value?.code || undefined,
      numberRuleId: value?.numberRuleId || undefined,
      materialId: material?.baseInfo?.id || undefined,
      customerId: Number(value?.customer?.value) || undefined,
      supplierId: Number(value?.supplier?.value) || undefined,
      supplierBatch: value?.supplierBatch || undefined,
      produceAt: value?.produceAt ? Number(_Time.formatToUnix(value?.produceAt)) : undefined,
      validTo: value?.validTo ? Number(_Time.formatToUnix(value?.validTo)) : undefined,
      customFields: value?.customFields,
    };
  };
  const handleFinish = async () => {
    try {
      const value = await form?.validateFields();
      const submitValue = handleFormateCreate(value);
      const formatCustom = formatCustomFieldsInData({ data: submitValue, customFields });

      switch (type) {
        case CRUD.create: {
          const { data } = await fetchBatchNoSave(formatCustom);

          if (typeof saveCallback === 'function') {
            const value = {
              label: (
                <Popover
                  placement="rightTop"
                  content={
                    <ul style={{ margin: 0 }}>
                      <li style={{ margin: '2px 0' }}>
                        批次号:<span> {data?.batchNo || replaceSign}</span>
                      </li>
                      <li style={{ margin: '2px 0' }}>
                        客户:<span> {data?.customer?.name || replaceSign}</span>
                      </li>
                      <li style={{ margin: '2px 0' }}>
                        供应商:<span> {data?.supplier?.name || replaceSign}</span>
                      </li>
                      <li style={{ margin: '2px 0' }}>
                        供应商批次:<span> {data?.supplierBatch || replaceSign}</span>
                      </li>
                      <li style={{ margin: '2px 0' }}>
                        生产日期:
                        <span>
                          {' '}
                          {data?.produceAt ? _Time.formatDate(data?.produceAt) : replaceSign}
                        </span>
                      </li>
                      <li style={{ margin: '2px 0' }}>
                        有效期至:
                        <span>
                          {' '}
                          {data?.validTo ? _Time.formatDate(data?.validTo) : replaceSign}
                        </span>
                      </li>
                    </ul>
                  }
                >
                  <div style={{ width: '100%' }}>{data?.batchNo}</div>
                </Popover>
              ),
              value: JSON.stringify(data),
              key: JSON.stringify(data),
            };

            saveCallback(value);
          }
          message.success('创建成功');
          break;
        }

        case CRUD.edit:
          console.log('表单 value', value);

          message.success('编辑成功');
          break;

        default:
          break;
      }
      if (keepOn) {
        typeof refreshTable === 'function' && refreshTable();
        form.resetFields();
        return;
      }
      onCancel && onCancel();
      typeof refreshTable === 'function' && refreshTable();
      refreshDetails && refreshDetails();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    switch (type) {
      case CRUD.create:
        break;

      case CRUD.edit:
        initEdit();
        break;

      default:
        break;
    }
  }, []);

  return (
    <DataFormLayout
      form={form}
      info={[baseInfo, customInfo]}
      title={type === CRUD.create ? '新建批次' : '编辑批次'}
      extra={
        type === CRUD.create &&
        !materialinfo && (
          <Checkbox
            onChange={() => {
              sekeepOn(!keepOn);
            }}
            defaultChecked={keepOn}
          >
            连续新建
          </Checkbox>
        )
      }
      onCancel={onCancel}
      onFinish={handleFinish}
    />
  );
};

export default BaseInfo;
