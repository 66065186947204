import { Descriptions } from 'antd';

import { TagTooltip } from 'src/components';
import { formatDateForRender } from 'src/utils/formatters';
import { calendarTypeDisplayMap, CalendarType, CalendarDataType } from '../../constants';

const fieldLabels = {
  code: '日历编号',
  name: '日历名称',
  startTime: '开始日期',
  endTime: '结束日期',
  managers: '负责人',
  type: '日历类型',
};

interface CalendarBasicInfoProps {
  basicInfoData: CalendarDataType;
}

export default function CalendarBasicInfo(props: CalendarBasicInfoProps) {
  const { basicInfoData } = props;

  return (
    <Descriptions title="基本信息" colon column={2}>
      <Descriptions.Item label={fieldLabels.code}>{basicInfoData?.code}</Descriptions.Item>
      <Descriptions.Item label={fieldLabels.name}>{basicInfoData?.name}</Descriptions.Item>
      <Descriptions.Item label={fieldLabels.startTime}>
        {formatDateForRender(basicInfoData?.startTime)}
      </Descriptions.Item>
      <Descriptions.Item label={fieldLabels.endTime}>
        {formatDateForRender(basicInfoData?.endTime)}
      </Descriptions.Item>
      <Descriptions.Item label={fieldLabels.managers}>
        <TagTooltip
          list={basicInfoData?.managers?.map((manager) => ({
            id: manager.managerId,
            name: manager.managerName,
            key: manager.managerId,
            avatarUrl: manager.avatarUrl,
          }))}
        />
      </Descriptions.Item>
      <Descriptions.Item label={fieldLabels.type}>
        {basicInfoData?.type === undefined
          ? ''
          : calendarTypeDisplayMap[basicInfoData?.type as CalendarType]}
      </Descriptions.Item>
    </Descriptions>
  );
}
