import React, { useEffect, useMemo, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from '@blacklake-web/layout';
import { useForm } from 'antd/lib/form/Form';
import { useHistory, useLocation } from 'react-router-dom';
import { DatePicker, Input, message, Table } from 'antd';
import { SearchSelect } from 'src/components';
import { fetchBatchNoDetail, fetchBatchNoUpdate } from 'src/api/ytt/material-domain/Batch_No';
import _ from 'lodash';
import { replaceSign } from 'src/utils/constants';
import { getMaterialbBatchAttrs } from 'src/entity/material';
import { BatchAttrs } from 'src/dict/material';
import { formatEditBatchParams, formatFormInitialValue } from '../utils';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import {
  formatCustomFieldsInData,
  initCustomFieldsInData,
  injectCustomFieldInfos,
  useCustomFieldCombinedData,
} from 'src/components/customField';

const BatchEdit: React.FC = () => {
  const [form] = useForm();
  const history = useHistory();
  const location = useLocation<{ materialId: number; batchNo: string }>();
  const [dataSource, setDataSource] = useState<any>(null);
  /** 获取对象自定义字段 */
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.batchNo);

  const materialInfo: DataFormLayoutInfoBlock = {
    column: 2,
    title: '物料信息',
    items: [
      {
        label: '物料名称',
        name: 'materialName',
        render: () => <span>{_.get(dataSource, 'materialInfo.baseInfo.name', replaceSign)}</span>,
      },
      {
        label: '物料编号',
        name: 'materialCode',
        render: () => <span>{_.get(dataSource, 'materialInfo.baseInfo.code', replaceSign)}</span>,
      },
      {
        label: '物料属性',
        name: 'materialAttribute',
        isFullLine: true,
        render: () => {
          const materialAttributes = _.get(dataSource, 'materialInfo.attribute', []);

          return _.isEmpty(materialAttributes) ? (
            replaceSign
          ) : (
            <Table
              style={{ width: 440 }}
              columns={[
                { dataIndex: 'name', title: '属性项' },
                { dataIndex: ['attributeItem', 'content'], title: '属性值' },
              ]}
              dataSource={materialAttributes}
              pagination={false}
              size={'small'}
            />
          );
        },
      },
      {
        label: '物料规格',
        name: 'materialSpecification',
        render: () => (
          <span>{_.get(dataSource, 'materialInfo.baseInfo.specification', replaceSign)}</span>
        ),
      },
    ],
  };

  /** 获取字段交互规则 */
  const getFieldInteractRule = (material: any, fieldEnum: number) => {
    const attrs = getMaterialbBatchAttrs(material, fieldEnum);

    return {
      show: Boolean(attrs.batchManagementEnable && attrs.displayEnable),
      required: Boolean(attrs.batchManagementEnable && attrs.displayEnable && !attrs.nullEnable),
    };
  };

  const getBatchInfo = (material: any) => {
    const customer = getFieldInteractRule(material, BatchAttrs.customer);
    const supplier = getFieldInteractRule(material, BatchAttrs.supplier);
    const supplierBatch = getFieldInteractRule(material, BatchAttrs.supplierBatch);
    const productionDate = getFieldInteractRule(material, BatchAttrs.productionDate);
    const validUntil = getFieldInteractRule(material, BatchAttrs.validUntil);
    const batchInfo: DataFormLayoutInfoBlock = {
      column: 2,
      title: '批次信息',
      items: _.compact([
        {
          label: '批次号',
          name: 'batchNo',
          rules: [
            {
              required: true,
              message: '批次号必填',
            },
          ],
          render: () => <Input disabled placeholder={'请输入'} />,
        },
        customer.show && {
          label: '客户',
          name: 'customer',
          rules: [
            {
              required: customer.required,
              message: '客户必填',
            },
          ],
          render: () => (
            <SearchSelect
              fetchType={'customer'}
              placeholder={'请选择'}
              params={{
                status: [1],
              }}
            />
          ),
        },
        supplier.show && {
          label: '供应商',
          name: 'supplier',
          rules: [
            {
              required: supplier.show,
              message: '供应商必填',
            },
          ],
          render: () => (
            <SearchSelect
              fetchType={'supplier'}
              placeholder={'请选择'}
              params={{
                status: [1],
              }}
            />
          ),
        },
        supplierBatch.show && {
          label: '供应商批次',
          name: 'supplierBatch',
          rules: [
            {
              required: supplierBatch.required,
              message: '供应商批次必填',
            },
          ],
          render: () => <Input placeholder={'请输入'} />,
        },
        productionDate.show && {
          label: '生产日期',
          name: 'produceAt',
          rules: [
            {
              required: productionDate.required,
              message: '生产日期必填',
            },
          ],
          render: () => <DatePicker placeholder={'请选择'} />,
        },
        validUntil.show && {
          label: '有效期至',
          name: 'validTo',
          rules: [
            {
              required: validUntil.required,
              message: '有效期至必填',
            },
          ],
          render: () => <DatePicker placeholder={'请选择'} />,
        },
      ]),
    };

    return batchInfo;
  };

  const fetchDetail = async (materialId: number, batchNo: string) => {
    const res = await fetchBatchNoDetail({ materialId, batchNo });
    const { data, message: msg, code } = res || {};

    if (code !== 200) {
      message.error(msg);
      return;
    }

    const afterFormatInitialValue = formatFormInitialValue(data);

    const afterFormatCustomFieldsValue = initCustomFieldsInData(afterFormatInitialValue);

    form.setFieldsValue(afterFormatCustomFieldsValue);

    setDataSource(data);
  };

  useEffect(() => {
    const { materialId, batchNo } = location?.state ?? {};

    if (materialId && batchNo) {
      fetchDetail(materialId, batchNo);
    }
  }, []);

  const onFinish = () => {
    form.validateFields().then(async (values) => {
      const _options = formatEditBatchParams(values);
      const options = formatCustomFieldsInData({ data: _options, customFields });

      const res = await fetchBatchNoUpdate({ ...options, id: dataSource?.id });
      const { code, message: msg } = res || {};

      if (code !== 200) {
        message.error(msg);
        return;
      }
      message.success('编辑成功');
      history.goBack();
    });
  };

  return (
    <DataFormLayout
      form={form}
      title={'编辑批次'}
      info={[
        materialInfo,
        getBatchInfo(dataSource?.materialInfo),
        injectCustomFieldInfos({
          customFields,
          type: 'form',
          formConfig: { form },
        }),
      ]}
      onCancel={() => history.goBack()}
      onFinish={onFinish}
    />
  );
};

export default BatchEdit;
