import React, { useEffect, useMemo } from 'react';
import { Button, Form, Select, Spin } from 'antd';
import { BlIcon } from 'src/components';
import { FormInstance } from 'antd/es/form/Form';
import DataPrivilegeTable from './DataPrivilegeTable';
import { DataPrivilegeLogicOperationMap } from 'src/dict/user/mappings';
import { LOGIC_OPERATION_ENUM } from 'src/dict/user';
import { OptionsType } from 'src/page/organization/components/dataPrivilege/types';
import { formatList, useGetDataByBizObjectId, useGetFieldByBizObjectId } from './utils';

type DataPrivilegeContentProps = {
  id: number;
  item: any;
  form: FormInstance<any>;
  disabled?: boolean;
};

const DataPrivilegeContent: React.FC<DataPrivilegeContentProps> = (props) => {
  const { id, item, form, disabled = false } = props;
  const dataSource = useGetFieldByBizObjectId(id, item.key);
  const roleList = useGetDataByBizObjectId(id, item.key);

  return useMemo(() => {
    const fieldName = `${dataSource?.bizObjectCode}_list`;
    const initialValue = formatList(dataSource?.list, roleList);
    const fieldOptions = dataSource?.list?.map<OptionsType>(({ fieldName, fieldId, ...rest }) => ({
      label: fieldName as string,
      value: fieldId,
      ...rest,
    }));

    return dataSource ? (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span style={{ marginBottom: '24px' }}>筛选符合以下</span>
          <Form.Item
            name={`${dataSource?.bizObjectCode}_logicOperation`}
            initialValue={LOGIC_OPERATION_ENUM.and}
          >
            <Select
              options={DataPrivilegeLogicOperationMap}
              disabled
              style={{
                width: '120px',
                margin: '0 12px',
              }}
            />
          </Form.Item>
          <span style={{ marginBottom: '24px' }}>条件的数据</span>
        </div>
        <Form.List name={fieldName} initialValue={initialValue}>
          {(fields, { add, remove }) => (
            <>
              <DataPrivilegeTable
                dataSource={dataSource}
                fields={fields}
                fieldOptions={fieldOptions || []}
                form={form}
                remove={remove}
                disabled={disabled}
                listFieldName={fieldName}
              />
              {!disabled && (
                <Button
                  type="dashed"
                  block
                  onClick={add}
                  icon={<BlIcon type="iconxinjiantianjia" />}
                  style={{
                    height: 32,
                    marginTop: 10,
                  }}
                >
                  添加一行
                </Button>
              )}
            </>
          )}
        </Form.List>
      </>
    ) : (
      <div style={{ textAlign: 'center' }}>
        <Spin />
      </div>
    );
  }, [dataSource, disabled, roleList]);
};

export default DataPrivilegeContent;
