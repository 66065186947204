import { FormInstance, Select, Form } from 'antd';
import _ from 'lodash';
import { feedingQualityStatus as feedingQualityStatusMapping } from 'src/dict/material/mappings';
import { ConvertUnit } from '../..';
import { FeedingQualityStatus, UnitType } from 'src/dict/material';

interface Props {
  form: FormInstance;
}

export const manufactureBasicInfo = (props: Props) => {
  const { form } = props;

  return [
    {
      label: '投料质量',
      name: 'qcStatus',
      initialValue: [FeedingQualityStatus.qualified, FeedingQualityStatus.concession],
      render: () => <Select options={feedingQualityStatusMapping} mode={'multiple'} />,
    },
    {
      shouldUpdate: (prev: any, next: any) => {
        return prev.transformUnit !== next.transformUnit;
      },
      noStyle: true,
      render: (FormItemProps: any) => () => {
        const transformUnit = _.filter(
          form.getFieldValue('transformUnit'),
          ({ enableFlag }) => enableFlag,
        );
        const feedUnitId = form.getFieldValue('feedUnitId');
        const unitList = transformUnit
          .map((item: ConvertUnit) => {
            if (item.convertType !== UnitType.auxUnit && item.enableFlag) {
              return {
                label: item.toUnitId?.label,
                value: item.toUnitId?.value,
              };
            }
            return false;
          })
          .filter(Boolean);

        // 确认此次修改的单位，是否还在formList
        if (!_.find(transformUnit, ({ toUnitId }) => toUnitId?.value === feedUnitId?.value)) {
          // feedUnitId = 主单位
          form.setFields([{ name: 'feedUnitId', value: transformUnit[0].fromUnitId }]);
        }

        return (
          <Form.Item name={'feedUnitId'} label={'投料单位'} {...FormItemProps}>
            <Select allowClear labelInValue placeholder={'请选择'} options={unitList} />
          </Form.Item>
        );
      },
    },
  ];
};
