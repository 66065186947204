import React, { useEffect, useState } from 'react';
import { Badge, Select } from 'antd';
import { FilterItem, RecordListLayout } from 'layout';
import { BlIcon, LinkTooltip, SearchSelect, SingleOperationLogComponent } from 'components';
import { replaceSign } from 'src/utils/constants';
import { map } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import _Time from 'src/utils/dataTypes/time';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { fieldTypeList } from 'src/utils';
import {
  toMatchRuleCreate,
  toMatchRuleDetail,
  toMatchRuleEdit,
} from '../templateManagement/navigation';
import { CreatorData } from 'src/page/knowledgeManagement/calendarManagement/shift/interface';
import { OBJECT_CATEGORY_MAP, TableRowType } from './interface';
import _String from 'src/utils/dataTypes/string';
import _Array from 'src/utils/dataTypes/array';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import {
  fetchMatchRuleMatchRuleStartStop,
  fetchMatchRuleQueryMatchRulePage,
} from 'src/api/ytt/e-report-domain/matchRule';
import { batchRequest } from './utils';
import { STATUS_ENUM, STATUS_ENUM_MAP } from '../templateManagement/constant';
import { fetchDataSetQueryAllObject } from 'src/api/ytt/e-report-domain/dataSet';

interface Props extends RouteComponentProps {}

const ObjectMatchRuleList = (props: Props) => {
  const { history } = props;
  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>(0);
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [matchId, setMatchId] = useState<any>();
  const [searchList, setSearchList] = useState([] as any);

  const fetchAllObject = () => {
    fetchDataSetQueryAllObject().then((res) => {
      const { data } = res;

      setSearchList(data);
    });
  };

  useEffect(() => {
    fetchAllObject();
  }, []);

  // 刷新页面
  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  const dataColumns = [
    {
      title: '规则编号',
      dataIndex: 'code',
      width: 200,
      sorter: true,
      isFilter: true,
      render: (code: string) => code ?? replaceSign,
    },
    {
      title: '规则名称',
      dataIndex: 'name',
      width: 200,
      isFilter: true,
      render: (name: string, record: TableRowType, index: number, config: any) => (
        <LinkTooltip
          to={toMatchRuleDetail(record?.id)}
          text={name}
          width={config.contentWidth}
          auth={authDict.eReportMatch_detail}
        />
      ),
    },
    {
      title: '对象类别',
      dataIndex: 'objectCategory',
      width: 200,
      isFilter: true,
      render: (objectCategory: number) => OBJECT_CATEGORY_MAP.get(objectCategory),
    },
    {
      title: '业务对象',
      dataIndex: 'objectName',
      width: 200,
      isFilter: true,
      sorter: true,
      render: (objectName: string) => objectName ?? replaceSign,
    },
    {
      title: '启用状态',
      dataIndex: 'status',
      width: 200,
      isFilter: true,
      sorter: true,
      render: (status: number) => {
        const useStatusMap = new Map([
          [STATUS_ENUM.ENABLED, <Badge text="启用中" status="success" />],
          [STATUS_ENUM.DISABLED, <Badge text="停用中" status="error" />],
        ]);

        return useStatusMap.get(status);
      },
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 120,
      render: (creator: CreatorData) => {
        return (
          <AvatarDisplay
            id={creator?.id}
            name={creator?.name}
            avatarUrl={creator?.avatarUrl}
            key={creator?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 200,
      sorter: true,
      render: (createAt: Date) => {
        if (!createAt) return replaceSign;
        return _Time.format(createAt);
      },
    },
    {
      title: '更新人',
      dataIndex: 'modifier',
      width: 150,
      defaultColConfig: {
        display: false,
      },
      render: (modifier: CreatorData) => {
        return (
          <AvatarDisplay
            id={modifier?.id}
            name={modifier?.name}
            avatarUrl={modifier?.avatarUrl}
            key={modifier?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 200,
      sorter: true,
      defaultColConfig: {
        display: false,
      },
      render: (updateAt: Date, record: any) => {
        if (!updateAt) return _Time.format(record?.createAt) ?? replaceSign;
        return _Time.format(updateAt);
      },
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '规则编号',
      name: 'code',
      type: fieldTypeList?.text,
    },
    {
      label: '规则名称',
      name: 'name',
      type: fieldTypeList?.text,
    },
    {
      label: '业务对象',
      name: 'objectCodeList',
      type: fieldTypeList?.multiSelect,
      renderItem: (
        <Select
          placeholder="请输入业务对象"
          showSearch
          labelInValue
          mode="multiple"
          allowClear
          filterOption={(inputValue, option) => {
            return (option?.label ?? '').indexOf(inputValue) > -1;
          }}
          options={searchList
            ?.filter((e: any) => e?.objectCategory === 1)
            ?.map((e: any) => {
              return {
                label: e?.objectName,
                value: e?.objectCode,
                key: e?.objectCode,
              };
            })}
        />
      ),
    },
    {
      label: '启用状态',
      name: 'statusList',
      type: fieldTypeList?.multiSelect,
      renderItem: (
        <Select
          mode="multiple"
          placeholder={'请选择'}
          options={map(STATUS_ENUM_MAP, (item: any) => {
            return {
              label: item?.name,
              value: item?.id,
            };
          })}
        />
      ),
    },
    {
      label: '创建人',
      name: 'creatorId',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect labelInValue fetchType={'user'} mode="multiple" />,
    },
    {
      label: '创建时间',
      name: 'createdAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '更新人',
      name: 'operatorId',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect labelInValue fetchType={'user'} mode="multiple" />,
    },
    {
      label: '更新时间',
      name: 'updatedAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
  ];

  const toolbar = [
    {
      title: '启用',
      icon: <BlIcon type="iconqiyong" />,
      auth: authDict.eReportMatch_enable_disable,
      onClick: (onSuccess: any, onFail: any) => {
        batchRequest(
          'batch',
          fetchMatchRuleMatchRuleStartStop,
          { matchRuleIdList: selectedRowKeys, status: 2 },
          '启用',
          onSuccess,
          onFail,
        );
      },
    },
    {
      title: '停用',
      icon: <BlIcon type="icnntingyong" />,
      auth: authDict.eReportMatch_enable_disable,
      onClick: (onSuccess: any, onFail: any) => {
        batchRequest(
          'batch',
          fetchMatchRuleMatchRuleStartStop,
          { matchRuleIdList: selectedRowKeys, status: 1 },
          '停用',
          onSuccess,
          onFail,
        );
      },
    },
  ];

  const mainMenu = [
    {
      title: '新建对象匹配规则',
      auth: authDict.eReportMatch_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        history.push(toMatchRuleCreate());
      },
      items: [],
    },
  ];

  const formatDataToQuery = (params: any) => {
    const {
      code,
      name,
      objectCodeList,
      operatorId,
      creatorId,
      createdAt,
      updatedAt,
      sorter,
      statusList,
      ...rest
    } = params;

    const relParams = {
      ...rest,
    };

    if (!_String.isEmpty(code)) {
      relParams.code = code;
    }

    if (!_String.isEmpty(name)) {
      relParams.name = name;
    }

    if (createdAt) {
      relParams.createdAtStart = Number(_Time.formatToUnix(createdAt[0]));
      relParams.createdAtEnd = Number(_Time.formatToUnix(createdAt[1]));
    }
    if (updatedAt) {
      relParams.updatedAtStart = Number(_Time.formatToUnix(updatedAt[0]));
      relParams.updatedAtEnd = Number(_Time.formatToUnix(updatedAt[1]));
    }

    if (!_Array.isEmpty(objectCodeList)) {
      relParams.objectCodeList = map(objectCodeList, 'value');
    }

    if (!_Array.isEmpty(statusList)) {
      relParams.statusList = statusList;
    }

    if (!_Array.isEmpty(creatorId)) {
      relParams.creatorId = map(creatorId, 'value');
    }
    if (!_Array.isEmpty(operatorId)) {
      relParams.operatorId = map(operatorId, 'value');
    }

    if (sorter) {
      relParams.sorter = map(sorter, (node: any) => {
        return {
          order: node?.order,
          field: node?.field,
        };
      });
    }

    return relParams;
  };

  const formatDataToFormDisplay = (data: any) => {
    const { createdAt, updatedAt } = data;

    const retData = { ...data };

    if (createdAt) {
      retData.createdAt = momentData(createdAt);
    }
    if (updatedAt) {
      retData.updatedAt = momentData(updatedAt);
    }

    return retData;
  };

  const getOperationList = (record: TableRowType) => {
    return [
      {
        title: '查看',
        auth: authDict.eReportMatch_detail,
        onClick: () => {
          history.push(toMatchRuleDetail(record?.id));
        },
      },
      {
        title: '编辑',
        auth: authDict.eReportMatch_edit,
        disabled: record?.status === STATUS_ENUM.ENABLED,
        onClick: () => history.push(toMatchRuleEdit(record?.id, 'list')),
      },
      {
        title: record?.status === STATUS_ENUM.ENABLED ? '停用' : '启用',
        auth: authDict.eReportMatch_enable_disable,
        onClick: async () => {
          const storeDisplay = record?.status === STATUS_ENUM.ENABLED ? '停用' : '启用';

          batchRequest(
            'single',
            fetchMatchRuleMatchRuleStartStop,
            {
              matchRuleIdList: [record?.id],
              status:
                record?.status === STATUS_ENUM.ENABLED ? STATUS_ENUM.DISABLED : STATUS_ENUM.ENABLED,
            },
            storeDisplay,
            refresh,
          );
        },
      },
      {
        title: '操作记录',
        auth: authDict.eReportMatch_operrecord,
        onClick: () => {
          setMatchId(record?.id);
          setVisibleLog(true);
        },
      },
    ];
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        batchMenu={toolbar}
        getOperationList={getOperationList}
        userAuth={getAuthFromLocalStorage()}
        placeholder={'请输入规则编号、规则名称'}
        selectedRowKeys={selectedRowKeys}
        configcacheKey="numberRulesCol" // 储存列配置
        onSelectedRowKeys={(selectKey: any) => {
          setSelectRowKeys(selectKey);
        }}
        requestFn={fetchMatchRuleQueryMatchRulePage}
        refreshMarker={refreshMarker}
        columns={dataColumns}
        mainMenu={mainMenu}
        filterList={filterList}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={matchId}
          objectCode={'eReportMatch'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default ObjectMatchRuleList;
