import { useCallback, useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import { message as Message } from 'antd';
import _ from 'lodash';
import { DetailLayoutInfoBlock, DetailLayout, DetailLayoutInfoItem } from 'layout';

import { fetchResourceSchedulerDetail } from 'src/api/ytt/resource-domain/collectionRules';
import { AvatarDisplay } from 'src/components/avatar/show';
import { BlIcon, SingleOperationLogComponent } from 'components';
import { gcTime } from 'src/utils';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { valueOrHolder } from 'src/utils/formatters';
import { appEnum } from 'src/dict';
import { CollectionRuleDetailType } from '../interface';
import { goToEdit } from '../navigates';
import { fieldLabels } from '../constants';
import { autoFrequencyRender } from '../components/AutoFrequency';

interface DetailContentProps extends RouteChildrenProps<{ id: string }> {}

const CollectionRuleDetail = (props: DetailContentProps) => {
  const { match, history } = props;
  const [detailData, setDetailData] = useState<CollectionRuleDetailType>({});
  const [loading, setLoading] = useState(false);
  const [visibleLog, setVisibleLog] = useState(false);

  const loadData = async (id?: string) => {
    if (id) {
      try {
        setLoading(true);
        const response = await fetchResourceSchedulerDetail({ id: _.toNumber(id) });

        setLoading(false);
        const { code, data, message } = response;

        if (code === 200 && data) {
          setDetailData(data);
        } else {
          Message.error(message);
        }
      } catch (err) {
        history.goBack();
      }
    }
  };

  useEffect(() => {
    loadData(match?.params?.id);
  }, []);

  const baseMenu = useCallback(() => {
    const { id } = detailData;

    if (_.isNil(id)) return [];
    return [
      {
        title: '操作记录',
        key: 'look',
        onClick: () => {
          setVisibleLog(true);
        },
        auth: authDict.collectionrule_operrecord,
      },
      {
        title: '编辑',
        key: 'edit',
        icon: <BlIcon type="iconbianji" />,
        onClick: () => {
          goToEdit(id);
        },
        auth: authDict.collectionrule_edit,
        type: 'primary',
      },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);

  const baseInfo = useCallback((): DetailLayoutInfoBlock => {
    const baseItems: DetailLayoutInfoItem[] = [
      { label: fieldLabels.code, dataIndex: 'code' },
      { label: fieldLabels.name, dataIndex: 'name' },
      {
        label: fieldLabels.autoBeginTime,
        dataIndex: 'autoBeginTime',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: fieldLabels.autoEndTime,
        dataIndex: 'autoEndTime',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: fieldLabels.autoFrequency,
        dataIndex: 'autoFrequency',
        render: autoFrequencyRender,
      },
    ];

    return {
      title: '基本信息',
      items: baseItems,
      column: 2,
    };
  }, [detailData]);

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: any) =>
          valueOrHolder(creator, (creator: any) => (
            <AvatarDisplay {...creator} isShowTag={false} isUser />
          )),
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: any) =>
          valueOrHolder(operator, (operator: any) => (
            <AvatarDisplay {...operator} isShowTag={false} isUser />
          )),
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
    ],
    column: 2,
  };

  return (
    <DetailLayout
      loading={loading}
      baseMenu={baseMenu()}
      title="数采规则详情"
      info={[baseInfo(), otherInfo]}
      dataSource={detailData}
      userAuth={getAuthFromLocalStorage()}
    >
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailData?.id ?? 0}
          objectCode={appEnum.Common.ObjectCode.DataCollectionRule}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </DetailLayout>
  );
};

export default CollectionRuleDetail;
