import { ReactNode, useEffect, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { checkTwoSidesBackslash, textValidator1, textValidator2 } from 'src/utils/formValidators';
import { FormInstance, Input, Select } from 'antd';
import { NumberRulesStandardHook } from 'src/components';
import MaterialFormTable from '../components/materialTable';
import { fetchShipmentNoteDetail } from 'src/api/ytt/order-domain/invoice';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { InvoiceStatus } from 'src/dict/sales';
import { InvoiceOrigin } from 'src/dict/invoice';
import _ from 'lodash';
import { appDict } from 'src/dict';
import { initFormValues } from '../utils';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import {
  injectCustomFieldInfos,
  initCustomFieldsInData,
  useCustomFieldCombinedData,
} from 'src/components/customField';

const { TextArea } = Input;

interface baseInfoProps {
  isCreating: boolean;
  onCancel: () => void;
  onFinish: (
    setLoading: (loading: boolean) => void,
    customFields: any,
    itemCustomFields: any,
  ) => void;
  title: string;
  form: FormInstance;
  extra?: ReactNode;
  refreshMarker?: number;
  orderEditId?: number;
}

const CreateAndEditBaseInfo = (props: baseInfoProps) => {
  const { onCancel, title, onFinish, form, extra, refreshMarker, isCreating, orderEditId } = props;
  const [loading, setLoading] = useState(true);
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const { invoiceInfo } = useSelector((state: RootState) => state?.supplyChain, shallowEqual);
  // 自定义字段
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.ShipmentsNote);
  const itemCustomFields = useCustomFieldCombinedData(OBJECT_OF_CODE.ShipmentsNoteItem);

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 3,
    title: '基本信息',
    items: _.compact([
      ...NumberRulesStandardHook({
        label: '发货单编号',
        form,
        name: 'invoiceCode',
        edit: !isCreating,
        objectCode: 'ShipmentsNote',
        fieldCode: 'code',
        rules: [
          { max: 64, message: '不可超过64字符' },
          { validator: textValidator2 },
          { validator: checkTwoSidesBackslash },
        ],
        refreshMarker,
        disabledState: disableEdit,
      }),
      {
        label: '发货单来源',
        name: 'invoiceType',
        render: () => (
          <Select
            disabled={disableEdit}
            options={appDict.invoice.invoiceOrigin}
            onChange={() => {
              form.setFieldsValue({
                materials: [],
              });
            }}
          />
        ),
      },
      {
        label: '备注',
        name: 'remark',
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
      {
        label: '编辑原因',
        name: 'editReason',
        rules: [{ max: 255, message: '不超255个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
        hidden: isCreating,
      },
    ]),
  };
  const materialInfo: DataFormLayoutInfoBlock = {
    title: '物料信息',
    items: [
      {
        isFullLine: true,
        dependencies: ['invoiceType'],
        render: () => () =>
          (
            <MaterialFormTable
              form={form}
              disableEdit={disableEdit}
              isCreate={isCreating}
              name={'materials'}
              itemCustomFields={itemCustomFields}
            />
          ),
      },
    ],
  };
  const handleDetailOrder = async () => {
    const res = await fetchShipmentNoteDetail({ id: orderEditId! });

    setLoading(false);

    const initialValue = {
      invoiceCode: res.data?.code,
      invoiceType: res.data?.origin,
      editReason: res.data?.editReason,
      remark: res.data?.remark,
      materials: res.data?.items,
      customFields: res.data?.customFields,
    };
    const afterFormatCustomFielsValue = initCustomFieldsInData(initialValue);

    form.setFieldsValue(afterFormatCustomFielsValue);
    setDisableEdit(res.data?.noteStatus === InvoiceStatus.inprogress);
  };

  useEffect(() => {
    if (orderEditId) {
      // 编辑时
      handleDetailOrder();
      return;
    }
    const initialValue = { customFields };

    if (query.source === 'salesOrder') {
      // 销售订单 => 新建发货单
      Object.assign(initialValue, {
        invoiceType: invoiceInfo.invoiceType + 1 || InvoiceOrigin.normal, // 发货单的来源 比 销售订单来源枚举值增1
        materials: initFormValues(invoiceInfo.items, !_.isUndefined(orderEditId)) || [],
      });
    } else if (query.source === 'collaborativeDeliverySchedule') {
      // 协同交货计划订单 => 新建发货单
      Object.assign(initialValue, {
        invoiceType: invoiceInfo.invoiceType || InvoiceOrigin.collaborativeDeliverySchedule,
        materials: initFormValues(invoiceInfo.items, !_.isUndefined(orderEditId)) || [],
      });
    } else {
      // 普通新建
      Object.assign(initialValue, {
        materials: [],
        invoiceType: InvoiceOrigin.normal,
      });
    }
    const afterFormatCustomFielsValue = initCustomFieldsInData(initialValue);

    form.setFieldsValue(afterFormatCustomFielsValue);
    setLoading(false);
  }, [orderEditId]);

  return (
    <DataFormLayout
      form={form}
      title={title}
      info={[
        baseInfo,
        injectCustomFieldInfos({
          customFields,
          type: 'form',
          formConfig: { form }, // form相关配置
        }),
        materialInfo,
      ]}
      onCancel={onCancel}
      onFinish={() => {
        onFinish(
          (loading) => {
            setLoading(loading);
          },
          customFields,
          itemCustomFields,
        );
      }}
      extra={extra}
      loading={loading}
    />
  );
};

export default CreateAndEditBaseInfo;
