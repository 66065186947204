/**
 * 可拖拽列表通用组件
 */

import { FC, useRef } from 'react';
import { List, Form } from 'antd';
import classnames from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableItem, { DraggableItemProps } from './draggableItem';
import Empty from './empty';
import { useResizeObserver } from 'src/components/overflowTooltip';
import styles from './styles.module.scss';
import { NamePath } from 'antd/lib/form/interface';

interface Props
  extends Pick<DraggableItemProps, 'form' | 'renderItem' | 'getInitItemData' | 'filter'> {
  name: NamePath;
  /** 是否自动添加拖拽上下文。默认为false，需自行在<DraggableList>外面加一层 \<DndProvider backend={HTML5Backend}> */
  withDndProvider?: boolean;
  /** 是否自动添加表单上下文。默认为false，需自行用<Form>组件包裹<DraggableList> */
  withForm?: boolean;
  className?: string;
  itemClassName?: string;
  /** 选择列表项的标题文本所在的元素，即文本结点的父元素，用于判断是否需要渲染Tooltip。 */
  itemTitleSelector?: string;
}

const DraggableList: FC<Props> = ({
  name,
  withDndProvider = false,
  withForm = false,
  className,
  itemClassName,
  ...rest
}) => {
  const listRef = useRef<HTMLDivElement | null>(null);

  useResizeObserver({ ref: listRef });

  const component = (
    <div ref={listRef} className={styles['draggable-list-wrapper']}>
      <List className={classnames(styles['draggable-list'], className)} split={false}>
        <Form.List name={name}>
          {(fields, operation) =>
            fields.map((item) => (
              <DraggableItem
                {...item}
                {...operation}
                {...rest}
                listNamePath={name}
                classname={itemClassName}
              />
            ))
          }
        </Form.List>
      </List>
      {!!rest.getInitItemData && (
        <Empty listNamePath={name} form={rest.form} getInitItemData={rest.getInitItemData!} />
      )}
    </div>
  );

  if (withDndProvider) {
    if (withForm) {
      return (
        <DndProvider backend={HTML5Backend}>
          <Form form={rest.form}>{component}</Form>
        </DndProvider>
      );
    } else {
      return <DndProvider backend={HTML5Backend}>{component}</DndProvider>;
    }
  } else if (withForm) {
    return <Form form={rest.form}>{component}</Form>;
  }
  return component;
};

export default DraggableList;
