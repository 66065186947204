import React, { useCallback, useEffect, useState } from 'react';
import { DataFormLayoutForModal, DataFormLayout } from 'layout';
import { Checkbox, Form, Input, message, Select, InputNumber } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { textValidator2 } from 'src/utils/formValidators';
import { ObjectCode, CRUD } from 'src/dict/common';
import {
  fetchResourceTypeInsert,
  fetchResourceTypeModify,
  fetchResourceTypeGet,
} from 'src/api/ytt/resource-domain/resourceCategory';
import { NumberRulesStandardHook } from 'src/components';

interface ActionProps {
  visible: Boolean;
  id: number;
  type: string;
  businessLevel: number;
  parentName?: string;
  parentId: number;
  businessType: number;
  businessTypeEnum?: any;
  onCancel?: () => void;
  refreshTable?: () => void;
}
const CategoryAction = (props: ActionProps) => {
  const {
    visible,
    onCancel,
    type,
    id,
    refreshTable,
    businessLevel,
    parentName,
    businessType,
    businessTypeEnum,
    parentId,
  } = props;
  const [modalForm] = Form.useForm();
  const [keepOn, setKeepOn] = useState<boolean>(false);
  const [, setRefreshMarker] = useState<number>();

  const getData = async (_id: number) => {
    try {
      const { data } = await fetchResourceTypeGet({ id: _id });

      if (data) {
        modalForm.setFieldsValue({
          ...data,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (type === 'edit' && id) {
      getData(id);
    }
    if (type === 'create') {
      modalForm.setFieldsValue({
        businessType,
        parentName,
        businessLevel,
      });
    }
  }, [id]);

  const baseInfo = useCallback(() => {
    const info = {
      title: '',
      column: 1,
      items: [
        {
          label: '业务类型',
          name: 'businessType',
          rules: [{ required: true, message: '请选择业务类型' }],
          render: () => {
            const options = Object.keys(businessTypeEnum).map((key) => {
              return { label: businessTypeEnum[key], value: Number(key) };
            });

            return (
              <Select
                placeholder="请选择业务类型"
                value={businessType}
                options={options}
                disabled={businessLevel !== 1 || type === CRUD.edit}
              />
            );
          },
        },
        {
          label: '上级分类',
          name: 'parentName',
          render: () => <Input disabled />,
        },
        {
          label: '分类层级',
          name: 'businessLevel',
          rules: [{ required: true }],
          render: () => <InputNumber disabled />,
        },
        ...NumberRulesStandardHook({
          label: '分类编号',
          form: modalForm,
          edit: type === 'edit',
          objectCode: ObjectCode.ResourceType,
          fieldCode: 'code',
          rules: [{ max: 255, message: '不超过255字符' }, { validator: textValidator2 }],
        }),
        {
          label: '分类名称',
          name: 'name',
          render: () => <Input placeholder="请输入分类名称" />,
          rules: [{ required: true, max: 100, message: '不超过100个字符' }],
        },
        {
          label: '备注',
          name: 'remarks',
          rules: [{ max: 1000, message: '不超过1000字符' }],
          render: () => <TextArea placeholder="请输入备注" showCount maxLength={1000} />,
        },
      ],
    };

    if (type === 'edit') {
      info.items.push({
        label: '修改原因',
        name: 'editReason',
        rules: [{ max: 1000, message: '不超过1000字符' }],
        render: () => <TextArea placeholder="请输入编辑原因" showCount maxLength={1000} />,
      });
    }

    return info;
  }, [type]);

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();
      const requestData = value;

      delete requestData.numberRuleId;
      delete requestData.parentName;
      requestData.fieldCode = 'code';
      if (parentId) {
        requestData.parentId = parentId;
      }
      if (type === 'edit') {
        requestData.id = id;
        await fetchResourceTypeModify(requestData);
      } else {
        await fetchResourceTypeInsert(requestData);
      }

      message.success(type === 'create' ? '创建成功！' : '编辑成功！');
      if (type === 'create' && keepOn) {
        modalForm.resetFields();
        setRefreshMarker(Math.random());
        modalForm.setFieldsValue({
          businessType,
          parentName,
          businessLevel,
        });
        return;
      }
      onCancel && onCancel();
      refreshTable?.();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      width={800}
      onClose={onCancel}
      content={
        <DataFormLayout
          form={modalForm}
          title={type === 'create' ? '新建分类' : '编辑分类'}
          extra={
            type === 'create' && (
              <Checkbox
                onChange={() => {
                  setKeepOn(!keepOn);
                }}
                defaultChecked={keepOn}
              >
                连续新建
              </Checkbox>
            )
          }
          info={[baseInfo()]}
          onCancel={onCancel}
          onFinish={handleFinish}
        />
      }
    />
  );
};

export default CategoryAction;
