import React, { ReactNode } from 'react';
import PDFViewer from '../drivers/PDFViewer';
import PhotoViewer from '../drivers/PhotoViewer';
import UnsupportedViewer from '../drivers/UnsupportedViewer';
import VideoViewer from '../drivers/VideoViewer';
import { FileType } from '../type';

interface FileViewerProps {
  fileType: FileType;
  filePathList: string[];
  onError?: () => void;
  errorComponent?: ReactNode;
  unsupportedComponent?: ReactNode;
}

/**
 * 文件预览
 * @param fileType 文件类型
 * @param filePathList 文件地址集合
 */
const FileViewer: React.FC<FileViewerProps> = (props) => {
  const getDriver = ({
    fileType,
    filePathList,
  }: Omit<FileViewerProps, 'fileType,filePathList'>) => {
    switch (fileType) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
      case 'png': {
        return <PhotoViewer filePathList={filePathList} />;
      }
      // TODO　暂时只支持图片预览，待完善
      // case 'pdf': {
      //   return <PDFViewer />;
      // }
      // case 'mp4': {
      //   return <VideoViewer />;
      // }
      default: {
        return <UnsupportedViewer />;
      }
    }
  };

  return (
    <div style={{ width: '100%', maxHeight: '70vh', overflowY: 'auto' }}>{getDriver(props)}</div>
  );
};

export default FileViewer;
