export const BASE_URL = '/sales/salesManagement/salesOrder';
export const BASEPURCHASE_URL = '/sales/salesManagement/purchaseRequisition';
export const CustomerMaterialRegist_URL = '/modeling/customerModeling/materialsRegisterList';
export const Invoice_URL = '/sales/salesManagement/invoice';

export const toDetail = (id: number) => {
  return `${BASE_URL}/${id}/detail`;
};
export const toEdit = (id: number) => {
  return `${BASE_URL}/${id}/edit`;
};
export const toCreate = () => {
  return `${BASE_URL}/create`;
};

export const toList = () => {
  return `${BASE_URL}`;
};
/**
 * 采购申请 页面
 */
export const toPurchaseDetail = (id: number) => {
  return `${BASEPURCHASE_URL}/${id}/detail`;
};
/**
 * 邀请注册物料 页面
 */
export const toCustomerMaterialRegist_URL = () => {
  return `${CustomerMaterialRegist_URL}`;
};
/**
 * 新建发货单 页面
 */

export const toCreateInvoice_URL = () => {
  return `${BASE_URL}/invoice/create?source=salesOrder`;
};
/**
 * 发货单列表 页面
 */
export const toInvoiceList_URL = () => {
  return `${Invoice_URL}"`;
};
