import { DetailLayoutForDrawer } from 'layout';
import type { CustomerDetailLayoutProps } from '../interface';
import DetailsBaseInfo from './baseInfo';

export default function CustomerDetail(props: CustomerDetailLayoutProps) {
  const { onClose } = props;
  // DetailLayout正在添加统一的loading方法，添加完以后可以用loading属性替代customerData的判断

  return (
    <DetailLayoutForDrawer
      content={<DetailsBaseInfo {...props} />}
      visible
      width={680}
      onClose={onClose}
    />
  );
}
