import React from 'react';
import { BlTable } from 'src/components';
import { lookup } from 'src/dict';
import { EnergyMeterDetailType } from '../index.d';

interface DetailListProps {
  dataSource: EnergyMeterDetailType['resourceParamsList'];
}

const DetailList = (props: DetailListProps) => {
  const { dataSource } = props;

  return (
    <BlTable
      style={{ width: '100%' }}
      dataSource={dataSource}
      scroll={{ x: 1500 }}
      columns={[
        {
          title: '能源仪表参数',
          dataIndex: 'paramsName',
          width: 150,
        },
        {
          title: '参数类型',
          dataIndex: 'paramsType',
          width: 150,
          render: lookup('resources.paramType'),
        },
        {
          title: '参数单位',
          dataIndex: 'unitName',
          width: 150,
        },
        {
          title: '参数标准值',
          dataIndex: 'paramsStandard',
          width: 150,
        },
        {
          title: '参数上限',
          dataIndex: 'paramsUpperLimit',
          width: 150,
        },
        {
          title: '参数下限',
          dataIndex: 'paramsLowerLimit',
          width: 150,
        },
        {
          title: '参数上上限',
          dataIndex: 'paramsTopLimit',
          width: 150,
        },
        {
          title: '参数下下限',
          dataIndex: 'paramsFloorLimit',
          width: 150,
        },
        {
          title: '参数读取API',
          dataIndex: 'paramsReadApi',
          width: 150,
        },
        {
          title: '参数下发API',
          dataIndex: 'paramsDownApi',
          width: 150,
        },
      ]}
    />
  );
};

export default DetailList;
