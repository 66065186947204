import { useState, useEffect } from 'react';
import { RecordListLayout } from 'layout';
import { Badge, message, Select } from 'antd';
import { fieldTypeList, gcObject } from 'utils';
import _ from 'lodash';
import { BlIcon, LinkTooltip, showErrorListMessage } from 'src/components';
import { getUserList } from 'src/services/organization/userManage';
import {
  terminalList,
  bulkActionList,
  userStatusList,
  UserTypes,
  lockStatusList,
  INUSE_ON,
  ACTIVATE_NOTYET,
  INUSE_OFF,
  LOCK_STATUS_TRUE,
} from '../constants';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import { RoleDisplay } from 'src/components/avatar/show';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { getRoleList } from 'src/services/organization/rolesApi';
import { RoleOption } from '../index.d';
import { SingleOperationLogComponent } from 'components';
import { roleTagRender } from '../userUtils';
import { USER_BATCH_ACTION, USER_STATUS_ENUM } from 'src/dict/user';
import {
  fetchUserActivateUser,
  fetchUserAwakenUser,
  fetchUserUnlockUser,
} from 'src/api/ytt/user-domain/user';
import ShowBulkConfirmModal from './BulkComfirmModal';
import { renderDepartmentArray, renderRoleArray, renderUser } from 'src/page/share/renderUser';
import { isAdminUser } from '../../share/utils';
import authDict from 'src/utils/auth';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { useDispatch } from 'react-redux';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { injectCustomFieldColumns } from 'src/components/customField';
import { fetchCustomFieldInstanceGetByObjectCode } from 'src/api/ytt/custom-object-domain';

interface ListTableProps {
  departmentId: number;
  departmentName?: string;
  isShowChildrenUser: boolean;
  setUserId: (id: number) => void;
  setShowActionModal: (type: boolean) => void;
  setIsBulkAction: (type: boolean) => void;
  setActionType: (type: string) => void;
  setBulkIds: (ids: number[]) => void;
  isRefreshTable: number;
  history: any;
  setUserValues: (value: any) => void;
}

interface Params {
  column: any;
  pageNum: number;
  pageSize: number;
  search?: string;
  orderBy?: string;
  orderDirection?: string;
  departmentId?: string;
  departmentName?: string;
  superiorIdList?: number[];
}
interface filterData {
  terminal?: number;
  active?: number;
  roleList?: number[];
}

interface BatchActionParams {
  actionType: string;
  selectDataKeys?: number[];
  status?: number;
  onSuccess?: any;
  onFail?: any;
}

const ListTable = (props: ListTableProps) => {
  const [refreshMarker, setRefreshMarker] = useState<number>(0);
  const [resetRefreshMarker, setResetRefreshMarker] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [optionKeys, setOptionKeys] = useState<number[]>([]);
  const [, setCustomInfo] = useState<any[]>([]);
  const [roleList, setRoleList] = useState<RoleOption[]>([]);
  const [visibleLog, setVisibleLog] = useState(false);
  const [noticeVisible, setNoticeVisible] = useState(false);
  const [statusType, setStatusType] = useState<number>(USER_STATUS_ENUM.disable);
  const [logInstanceId, setLogInstanceId] = useState(0);
  const [customFields, setCustomFields] = useState<any>();
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  const {
    departmentId,
    departmentName,
    isShowChildrenUser,
    isRefreshTable,
    setUserId,
    setShowActionModal,
    setIsBulkAction,
    setActionType,
    setBulkIds,
    history,
    setUserValues,
  } = props;

  const fetchTableData = async (params: any) => {
    const res = await getUserList(params, departmentId, isShowChildrenUser);

    setCustomInfo(res.data.list[0]?.customFieldItemList || []);
    return res;
  };

  useEffect(() => {
    setRefreshMarker(Math.random());
  }, [isRefreshTable]);

  useEffect(() => {
    setResetRefreshMarker(Math.random());
  }, [departmentId, isShowChildrenUser]);

  useEffect(() => {
    const { roles } = getRoleList({ page: 1, size: 500 });

    roles.then((res) => {
      const _roleList = res.data?.list.map((i: any) => {
        return {
          label: i.name,
          value: i.id,
        };
      });

      setRoleList(_roleList || []);
    });
  }, []);

  const showUserDetail = (userId: number) => {
    history.push(`/organization/userManagement/detail?id=${userId}`);
  };

  const dataColumns: any[] = [
    {
      title: '用户编号',
      dataIndex: 'code',
      type: fieldTypeList.text,
      isFilter: true,
      sorter: true,
      width: 120,
      fixed: 'left',
      render: (value: string, record: any, index: number, config: any) => {
        return record?.terminal === UserTypes.SCM_VIRTUAL ? (
          value
        ) : (
          <LinkTooltip
            to={`/organization/userManagement/detail?id=${record.id}`}
            text={value}
            auth={authDict.user_detail}
            width={config.contentWidth}
          />
        );
      },
    },
    {
      title: '用户名',
      dataIndex: 'name',
      type: fieldTypeList.text,
      isFilter: true,
      sorter: true,
      width: 150,
      render: (value: string, record: any, index: number, config: any) => {
        return record?.terminal === UserTypes.SCM_VIRTUAL ? (
          value
        ) : (
          <LinkTooltip
            to={`/organization/userManagement/detail?id=${record.id}`}
            text={value}
            auth={authDict.user_detail}
            width={config.contentWidth}
          />
        );
      },
    },
    {
      title: '账号',
      dataIndex: 'username',
      type: fieldTypeList.text,
      isFilter: true,
      sorter: true,
      width: 150,
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      type: fieldTypeList.text,
      isFilter: true,
      sorter: true,
      width: 150,
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
    },
    {
      title: '直属上级',
      dataIndex: 'superior',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
      render: renderUser,
    },
    {
      title: '部门',
      dataIndex: 'departmentVOList',
      type: fieldTypeList.text,
      isFilter: false,
      width: 150,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple />,
      render: (list: any[]) => renderDepartmentArray(list),
    },
    {
      title: '角色',
      dataIndex: 'roleList',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      selectProps: {
        allowClear: true,
        mode: 'multiple',
        tagRender: roleTagRender,
        filterOption: (inputValue: string, option: RoleOption) =>
          String(option?.label)?.indexOf(inputValue) > -1,
        children: roleList.map((role) => (
          <Select.Option value={role.value} label={role.label} key={role.value}>
            <RoleDisplay name={role.label} id={role.value} key={role.value} />
          </Select.Option>
        )),
      },
      render: (list: any[]) => renderRoleArray(list),
    },
    {
      title: '账号类型',
      dataIndex: 'terminal',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      selectProps: {
        allowClear: true,
        options: terminalList
          .filter((item) => item.value !== UserTypes.TV)
          .map((item) => {
            return {
              label: item.label,
              value: String(item.value),
            };
          }),
      },
      render: (terminal: number) => {
        const showTerminalList = terminalList.concat([
          {
            label: 'OpenAPI账号',
            value: UserTypes.OPEN_API,
          },
          {
            label: '供应链虚拟账号',
            value: UserTypes.SCM_VIRTUAL,
          },
          {
            label: '接口账号',
            value: UserTypes.INTERFACE,
          },
        ]);
        const index = _.findIndex(showTerminalList, { value: terminal });

        return showTerminalList[index]?.label || '-';
      },
    },
    {
      title: '账号状态',
      dataIndex: 'active',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      selectProps: {
        allowClear: true,
        options: userStatusList.map((status) => {
          return {
            label: status.displayName,
            value: String(status.value),
          };
        }),
      },
      render: (_status: number) => {
        let color: PresetStatusColorType = 'success';

        if (_status === 0) {
          color = 'error';
        }
        if (_status === 2) {
          color = 'processing';
        }
        if (_status === 3) {
          color = 'default';
        }
        const index = _.findIndex(userStatusList, { value: _status });

        return (
          <span>
            <Badge status={color} /> {userStatusList[index]?.displayName || '-'}
          </span>
        );
      },
    },
    {
      title: '锁定状态',
      dataIndex: 'lockStatus',
      type: fieldTypeList.select,
      selectProps: {
        options: lockStatusList,
      },
      isFilter: true,
      width: 150,
      render: (status: boolean) => (
        <span>
          <Badge status={status ? 'success' : 'error'} /> {status ? '已锁定' : '未锁定'}
        </span>
      ),
    },
    {
      title: '备注',
      dataIndex: 'desc',
      type: fieldTypeList.text,
      width: 150,
    },
    // ...customColumns,
  ];

  const formatDataToQuery = (params: any) => {
    const { roleList, quickSearch, superior, ...rest } = params;

    const _params: Params = {
      roleIdList: roleList,
      ...rest,
    };

    if (quickSearch) {
      _params.search = params.quickSearch;
    }

    if (superior) {
      _params.superiorIdList = _.map(params?.superior, 'value');
    }
    const resParams = gcObject.filterEmptyProperty(_params);

    dispatch.excelImport.updateBusinessData({ businessData: resParams });
    return resParams;
  };

  const errorColumns = [
    {
      title: '用户编号',
      dataIndex: 'code',
    },
    {
      title: '用户名称',
      dataIndex: 'name',
    },
    {
      title: '失败原因',
      dataIndex: 'failureReason',
    },
  ];

  /**
   * 批量操作
   * @param actionType 操作方式
   */
  const batchAction = async ({
    actionType,
    status,
    selectDataKeys,
    onSuccess,
    onFail,
  }: BatchActionParams) => {
    setOptionKeys(selectDataKeys || selectedRowKeys);
    switch (actionType) {
      // 激活
      case USER_BATCH_ACTION.activate: {
        setStatusType(USER_STATUS_ENUM.active);
        setNoticeVisible(true);
        onFail?.();
        break;
      }
      // 启停用
      case USER_BATCH_ACTION.updateStatus: {
        setStatusType(status as number);
        setNoticeVisible(true);
        onFail?.();
        break;
      }
      // 解锁
      case USER_BATCH_ACTION.lock: {
        const res = await fetchUserUnlockUser({ idList: selectDataKeys || selectedRowKeys });
        const { code, data } = res;
        const { updateFailureVOList } = data || {};

        if (code !== 200) {
          onFail?.();
          break;
        }
        if (updateFailureVOList && updateFailureVOList?.length > 0) {
          showErrorListMessage({
            title: '解锁出现失败',
            data: updateFailureVOList,
            columns: errorColumns,
            operateName: '解锁',
            successCount: 1,
            failCount: 1,
            width: 600,
          });
          onFail?.();
        } else {
          onSuccess?.();
          message.success('解锁成功');
        }
        break;
      }
      default:
        message.error('未知操作类型');
        onFail?.();
        break;
    }
  };

  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      icon: <BlIcon type="icondaochu" />,
      auth: authDict.user_export,
      onClick: () =>
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.user,
        }),
    },
    {
      title: '新建用户',
      auth: authDict.user_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        const name = encodeURIComponent(encodeURIComponent(departmentName!));
        const id = departmentId;
        let url = '/organization/userManagement/createUser';

        if (id) {
          url += `?departmentId=${id}&departmentName=${name}`;
        }
        history.push(url);
      },
      items: [
        {
          title: '导入',
          showExport: true,
          auth: authDict.user_import,
          onClick: () =>
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.user,
            }),
        },
      ],
    },
  ];

  const batchMenu = [
    {
      title: '配置部门',
      auth: authDict.user_edit,
      onClick: (onSuccess: any, onFail: any) => {
        setActionType(bulkActionList.setDepartment);
        setIsBulkAction(true);
        setShowActionModal(true);
        onFail();
      },
    },
    {
      title: '配置角色',
      auth: authDict.user_edit,
      onClick: (onSuccess: any, onFail: any) => {
        setActionType(bulkActionList.setRole);
        setIsBulkAction(true);
        setShowActionModal(true);
        onFail();
      },
    },
    {
      title: '配置账号类型',
      auth: authDict.user_edit,
      onClick: (onSuccess: any, onFail: any) => {
        setActionType(bulkActionList.setTerminal);
        setIsBulkAction(true);
        setShowActionModal(true);
        onFail();
      },
    },
    {
      title: '激活',
      auth: authDict.user_active,
      onClick: (onSuccess: any, onFail: any) => {
        batchAction({
          actionType: USER_BATCH_ACTION.activate,
          selectDataKeys: selectedRowKeys,
          onSuccess,
          onFail,
        });
      },
    },
    {
      title: '启用',
      auth: authDict.user_enable_disable,
      onClick: (onSuccess: any, onFail: any) => {
        batchAction({
          actionType: USER_BATCH_ACTION.updateStatus,
          status: USER_STATUS_ENUM.enable,
          onSuccess,
          onFail,
        });
      },
    },
    {
      title: '停用',
      auth: authDict.user_enable_disable,
      onClick: (onSuccess: any, onFail: any) => {
        batchAction({
          actionType: USER_BATCH_ACTION.updateStatus,
          status: USER_STATUS_ENUM.disable,
          onSuccess,
          onFail,
        });
      },
    },
    {
      title: '解锁',
      auth: authDict.user_unlock,
      onClick: (onSuccess: any, onFail: any) => {
        batchAction({
          actionType: USER_BATCH_ACTION.lock,
          onSuccess,
          onFail,
        });
      },
    },
  ];

  const filterList = dataColumns
    .filter((i) => i.isFilter)
    .map((column: any) => {
      const filter: any = {
        label: column.title,
        name: column.dataIndex,
        type: column.type,
        rules: column.rules,
        renderItem: column.renderItem,
      };

      if (column.type === fieldTypeList.select) {
        filter.selectProps = column.selectProps;
      }
      if (column.type === fieldTypeList.date && column.dateFormat) {
        filter.dateFormat = column.dateFormat;
      }
      return filter;
    });

  const formatDataToDisplay = (params: filterData) => {
    const filterDataDisplay: any = _.cloneDeep(params);

    if (params?.terminal) {
      const index = _.findIndex(terminalList, { value: Number(params.terminal) });

      filterDataDisplay.terminal = terminalList[index]?.label;
    }
    if (params?.active) {
      filterDataDisplay.active = params?.active ? '启用' : '停用';
    }

    return filterDataDisplay;
  };

  const config = {
    getOperationList: (record: any) => {
      const { id, roleList, lockStatus, active } = record;

      return record?.terminal === UserTypes.SCM_VIRTUAL
        ? []
        : _.compact([
            {
              title: '查看',
              auth: authDict.user_detail,
              onClick: () => showUserDetail(record.id),
            },
            {
              title: '重置密码',
              auth: authDict.user_reset_password,
              onClick: () => {
                setUserId(record.id);
                setActionType('resetPassword');
                setIsBulkAction(false);
                setShowActionModal(true);
                setUserValues(record);
              },
            },
            active === ACTIVATE_NOTYET && {
              title: '激活',
              auth: authDict.user_active,
              onClick: async () => {
                await fetchUserActivateUser({ idList: [id] });
                message.success('激活成功');
                setRefreshMarker(Math.random());
              },
            },
            !isAdminUser(roleList) &&
              active === INUSE_ON && {
                title: '停用',
                auth: authDict.user_enable_disable,
                onClick: () => {
                  batchAction({
                    actionType: USER_BATCH_ACTION.updateStatus,
                    status: USER_STATUS_ENUM.disable,
                    selectDataKeys: [id],
                  });
                },
              },
            active === INUSE_OFF && {
              title: '启用',
              auth: authDict.user_enable_disable,
              onClick: async () => {
                await fetchUserAwakenUser({ idList: [id], active: USER_STATUS_ENUM.enable });
                message.success('启用成功');
                setRefreshMarker(Math.random());
              },
            },
            lockStatus === LOCK_STATUS_TRUE && {
              key: 'unlock',
              title: '解锁',
              auth: authDict.user_unlock,
              onClick: () => {
                batchAction({
                  actionType: USER_BATCH_ACTION.lock,
                  selectDataKeys: [id],
                  onSuccess: () => setRefreshMarker(Math.random()),
                });
              },
            },
            {
              title: '编辑',
              auth: authDict.user_edit,
              onClick: () => history.push(`/organization/userManagement/editUser?id=${id}`),
            },
            !isAdminUser(roleList) &&
              record?.terminal !== UserTypes.OPEN_API && {
                title: '复制',
                auth: authDict.user_add,
                onClick: () =>
                  history.push(`/organization/userManagement/copyUser?id=${id}&type=copy`),
              },
            {
              title: '查看用户操作记录',
              auth: authDict.user_operrecord,
              onClick: () => {
                setLogInstanceId(id);
                setVisibleLog(true);
              },
            },
          ]);
    },
  };

  /** 获取对象自定义字段 */
  const fetchCustomFields = async () => {
    const res = await fetchCustomFieldInstanceGetByObjectCode({
      objectCode: OBJECT_OF_CODE.user,
    });

    setCustomFields((res?.data as any) || {});
  };

  useEffect(() => {
    fetchCustomFields();
  }, []);

  return (
    <>
      <RecordListLayout
        filterList={filterList}
        requestFn={async (params) => fetchTableData(params)}
        mainMenu={mainMenu}
        batchMenu={batchMenu}
        placeholder={'请输入用户名称或编号'}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={formatDataToDisplay}
        columns={injectCustomFieldColumns({
          columns: dataColumns, // 原本的列
          customFields, // 自定义字段信息
          objectCode: OBJECT_OF_CODE.user, // 从对象code
          type: 'detail', // 使用类型
        })}
        refreshMarker={refreshMarker}
        resetRefreshMarker={resetRefreshMarker}
        selectedRowKeys={selectedRowKeys}
        rowKey="id"
        onSelectedRowKeys={(selectKey) => {
          setSelectedRowKeys(selectKey.map((key) => Number(key)));
          setBulkIds(selectKey.map((key) => Number(key)));
        }}
        {...config}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={logInstanceId}
          objectCode={'User'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      {noticeVisible && (
        <ShowBulkConfirmModal
          visible={noticeVisible}
          close={() => setNoticeVisible(false)}
          idList={optionKeys}
          type={statusType}
          refreshList={() => setRefreshMarker(Math.random())}
        />
      )}
    </>
  );
};

export default ListTable;
