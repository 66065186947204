/**
 * @description 映射
 */

import {
  USER_STATUS_ENUM,
  DEPARTMENT_STATUS_ENUM,
  LOGIC_OPERATION_ENUM,
  DATA_PRIVILEGE_OPERATION_ENUM,
} from '.';
import { mappingsFactory } from '../utils';

export const userStatus = mappingsFactory(
  ['启用', USER_STATUS_ENUM.enable],
  ['停用', USER_STATUS_ENUM.disable],
  ['激活', USER_STATUS_ENUM.active],
);

export const departmentStatus = mappingsFactory(
  ['停用', DEPARTMENT_STATUS_ENUM.disable],
  ['启用', DEPARTMENT_STATUS_ENUM.enable],
);

/** 数据权限筛选条件 */
export const DataPrivilegeLogicOperationMap = mappingsFactory(
  ['任一', LOGIC_OPERATION_ENUM.or],
  ['所有', LOGIC_OPERATION_ENUM.and],
);

/** 数据权限字段筛选条件 */
export const dataPrivilegeOperations = mappingsFactory(
  ['等于', DATA_PRIVILEGE_OPERATION_ENUM.equal],
  ['包含', DATA_PRIVILEGE_OPERATION_ENUM.include],
  ['不等于', DATA_PRIVILEGE_OPERATION_ENUM.notEqual],
  ['不包含', DATA_PRIVILEGE_OPERATION_ENUM.exclude],
  ['为空', DATA_PRIVILEGE_OPERATION_ENUM.isnull],
  ['不为空', DATA_PRIVILEGE_OPERATION_ENUM.notNull],
  ['当前用户', DATA_PRIVILEGE_OPERATION_ENUM.curUser],
  ['当前部门', DATA_PRIVILEGE_OPERATION_ENUM.curDepartment],
  ['等于任意一个', DATA_PRIVILEGE_OPERATION_ENUM.equalAnyone],
  ['不等于任意一个', DATA_PRIVILEGE_OPERATION_ENUM.notEqualAnyone],
  ['同时包含（多选）', DATA_PRIVILEGE_OPERATION_ENUM.containsAll],
  ['大于等于', DATA_PRIVILEGE_OPERATION_ENUM.gte],
  ['小于等于', DATA_PRIVILEGE_OPERATION_ENUM.lte],
  ['选择范围', DATA_PRIVILEGE_OPERATION_ENUM.range],
);
