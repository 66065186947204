export const BASE_URL = '/purchase/purchaseManagement/purchaseOrder';

export const toDetail = (id: number) => {
  return `${BASE_URL}/${id}/detail`;
};
export const toEdit = (id: number) => {
  return `${BASE_URL}/${id}/edit`;
};
export const toCreate = () => {
  return `${BASE_URL}/create`;
};

export const toList = () => {
  return `${BASE_URL}`;
};

export const toCreateReturnOrder = () => {
  return `${BASE_URL}/createPurchaseReturnOrder`;
};

export const toCreateDeliveryOrder = (id: number) => {
  return `${BASE_URL}/createDeliveryOrder?id=${id}`;
};

export const toCreateDeliveryScheduleNote = () => {
  return `${BASE_URL}/createDeliveryScheduleNote`;
};
