import React, { useEffect, useState } from 'react';
import { Form, message as Message } from 'antd';
import BaseInfo from './components/createAndEditBaseInfo';
import { ShiftId, ShiftRuleInfoCreateCOS } from './interface';
import { match, RouteComponentProps } from 'react-router-dom';
import { fetchShiftRuleGet, fetchShiftRuleUpdate } from 'src/api/ytt/calendar-domain';
import { throttleFn } from '../../warehouse/utils';

interface EditProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const EditScheduling = (props: EditProps) => {
  const { match, history } = props;
  const schedulingId = Number(match?.params?.id);
  const [detailData, setDetailData] = useState({} as any);

  const [batched, setBatched] = useState(false);
  const [batchValue, setBatchValue] = useState<ShiftId>({} as any);

  const handleSetBatched = (batch: boolean) => {
    setBatched(batch);
  };
  const handleSetBatchValue = (batchValue: ShiftId) => {
    setBatchValue(batchValue);
  };

  const [editForm] = Form.useForm();

  const handleFinish = async () => {
    try {
      const value = await editForm?.validateFields();

      const { shiftRuleInfoCreateCOS, ...rest } = value;

      const _shiftRuleInfoUpdateCOS = shiftRuleInfoCreateCOS?.map(
        (node: ShiftRuleInfoCreateCOS) => {
          return {
            ...node,
            shiftId: node?.shiftId?.key,
          };
        },
      );

      const { data, message } = await fetchShiftRuleUpdate({
        ...rest,
        shiftRuleInfoUpdateCOS: _shiftRuleInfoUpdateCOS,
        id: schedulingId,
      });

      if (data) {
        Message.success('编辑成功');
        history.goBack();
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await fetchShiftRuleGet({ id: schedulingId }, { legacy: true });

      if (data) {
        setDetailData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [schedulingId]);

  return (
    <BaseInfo
      onFinish={throttleFn(handleFinish)}
      onCancel={() => {
        history.goBack();
      }}
      title={'编辑排班规则'}
      form={editForm}
      initialData={detailData}
      edit
      batched={batched}
      batchValue={batchValue}
      handleSetBatched={handleSetBatched}
      handleSetBatchValue={handleSetBatchValue}
    />
  );
};

export default EditScheduling;
