import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Drawer, Empty, Spin, Space } from 'antd';
//
import {
  fetchMaintenanceSuspendRecordList,
  FetchMaintenanceSuspendRecordListResponse,
} from 'src/api/ytt/resource-domain/maintenanceTask/suspend';
import styles from './style.module.scss';
import { valueOrHolder } from 'src/utils/formatters';
import AutoTimer from '../../components/autoTimer';
import { AvatarDisplay } from 'src/components/avatar/show';
import { gcTime } from 'src/utils';

interface TimeOutRecordProps {
  recordId?: number;
  onClose: () => void;
}

enum SuspendStatus {
  /** 暂停中 */
  SUSPENDED = 0,
  /** 暂停结束 */
  SUSPENDED_END,
}

const SuspendStatusMap = new Map([
  [SuspendStatus.SUSPENDED, '暂停中'],
  [SuspendStatus.SUSPENDED_END, '暂停结束'],
]);

type SuspendItem = NonNullable<
  NonNullable<FetchMaintenanceSuspendRecordListResponse['data']>['suspendRecordList']
>[0];

const TimeOutRecord = (props: TimeOutRecordProps) => {
  const { recordId, onClose } = props;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<FetchMaintenanceSuspendRecordListResponse['data']>({});

  useEffect(() => {
    if (recordId) {
      setLoading(true);
      fetchMaintenanceSuspendRecordList({ maintenanceTaskId: recordId })
        .then((res) => {
          const resData = _.get(res, 'data');

          setData(resData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [recordId]);

  const renderInfo = (
    item: SuspendItem,
    config: {
      label: React.ReactNode;
      dataIndex: string;
      render: (value: any) => React.ReactNode;
    },
  ) => {
    const { label, dataIndex, render } = config;

    const value = _.get(item, dataIndex);

    return (
      <p>
        <Space align="start">
          <div style={{ minWidth: 70 }}>{label}：</div>
          <span>{valueOrHolder(value, render)}</span>
        </Space>
      </p>
    );
  };

  /**
   * 『暂停中』状态的black
   */
  const renderTimeOutingBlack = (item: SuspendItem) => {
    const descs = [
      {
        label: '暂停状态',
        dataIndex: 'status',
        render: (status: SuspendStatus) => SuspendStatusMap.get(status),
      },
      {
        label: '暂停开始时间',
        dataIndex: 'beginTime',
        render: gcTime.formatLine,
      },
      {
        label: '暂停开始人',
        dataIndex: 'beginUser',
        render: (beginUser: any) => <AvatarDisplay {...beginUser} isShowTag={false} isUser />,
      },
      {
        label: '已暂停时长',
        dataIndex: 'duration',
        render: (duration: number | undefined) => <AutoTimer startSec={duration} auto />,
      },
      {
        label: '暂停原因',
        dataIndex: 'reason',
        render: (reason: string) => reason,
      },
    ];

    return (
      <div className={styles.timeOutBlack}>
        {_.map(descs, (config) => renderInfo(item, config))}
      </div>
    );
  };

  /**
   * 『暂停结束』状态的black
   */
  const renderTimeOutedBlack = (item: SuspendItem) => {
    const descs = [
      {
        label: '暂停状态',
        dataIndex: 'status',
        render: (status: SuspendStatus) => SuspendStatusMap.get(status),
      },
      {
        label: '暂停开始时间',
        dataIndex: 'beginTime',
        render: gcTime.formatLine,
      },
      {
        label: '暂停开始人',
        dataIndex: 'beginUser',
        render: (beginUser: any) => <AvatarDisplay {...beginUser} isShowTag={false} isUser />,
      },
      {
        label: '暂停结束时间',
        dataIndex: 'endTime',
        render: gcTime.formatLine,
      },
      {
        label: '暂停结束人',
        dataIndex: 'endUser',
        render: (endUser: any) => <AvatarDisplay {...endUser} isShowTag={false} isUser />,
      },
      {
        label: '暂停时长',
        dataIndex: 'duration',
        render: (duration: number | undefined) => <AutoTimer startSec={duration} />,
      },
      {
        label: '暂停原因',
        dataIndex: 'reason',
        render: (reason: string) => reason,
      },
    ];

    return (
      <div className={styles.timeOutBlack}>
        {_.map(descs, (config) => renderInfo(item, config))}
      </div>
    );
  };

  return (
    <Drawer visible={!!recordId} title="任务暂停记录" onClose={onClose} width={800}>
      <Spin spinning={loading}>
        {_.isEmpty(data?.suspendRecordList) ? (
          <Empty />
        ) : (
          <>
            <div className={styles.timeOutInfo}>
              <span>暂停次数：{data?.suspendRecordList?.length ?? 0}次</span>
              <span>
                任务暂停总时长：
                <AutoTimer
                  startSec={_.sum(_.map(data?.suspendRecordList, 'duration'))}
                  auto={!!_.find(data?.suspendRecordList, ['status', SuspendStatus.SUSPENDED])}
                />
              </span>
            </div>
            <div>
              {_.map(data?.suspendRecordList, (item) => {
                if (item.status === SuspendStatus.SUSPENDED) {
                  return renderTimeOutingBlack(item);
                } else if (item.status === SuspendStatus.SUSPENDED_END) {
                  return renderTimeOutedBlack(item);
                }

                return null;
              })}
            </div>
          </>
        )}
      </Spin>
    </Drawer>
  );
};

export default TimeOutRecord;
