/**
 * @mappings 自定义字段-值映射表
 */

import {
  FieldType,
  FieldCategory,
  TargetType,
  RelationDeleteOption,
  ViewRuleEnum,
  SubDeleteOption,
} from '.';
import { mappingsFactory } from '../utils';

export const fieldType = mappingsFactory(
  ['单行文本', FieldType.text],
  ['数值', FieldType.number],
  ['附件', FieldType.appendix],
  ['多行文本', FieldType.textArea],
  ['单选', FieldType.select],
  ['多选', FieldType.multiSelect],
  ['布尔值', FieldType.boolean],
  ['整数', FieldType.integer],
  ['日期时间', FieldType.date],
  ['链接', FieldType.url],
  ['引用字段', FieldType.reference],
  ['关联字段', FieldType.relation],
  ['从对象', FieldType.relationSub],
  ['主从关系字段', FieldType.subordinate],
);

export const fieldCategoryString = mappingsFactory(
  ['自定义字段', FieldCategory.customFields],
  ['预设字段', FieldCategory.preFields],
);

export const targetType = mappingsFactory(
  ['多选(对n)', TargetType.multiChoice],
  ['单选(对1)', TargetType.singleChoice],
);

export const deleteOptions = mappingsFactory(
  ['解除关联关系', RelationDeleteOption.allowed],
  ['提示不允许删除', RelationDeleteOption.notAllowed],
);

export const viewRule = mappingsFactory(
  ['自身控制', ViewRuleEnum.bySelf],
  ['受父对象控制', ViewRuleEnum.byParent],
);

export const subDeleteRule = mappingsFactory(
  ['主对象删除从对象删除', SubDeleteOption.delateAll],
  ['提示不允许删除', SubDeleteOption.notAllowed],
);
