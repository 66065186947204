import { Models } from '@rematch/core';
import { user } from './user';
import { contextInfo } from './context';
import { excelImport } from './import';
import { transferMaterials } from './transferMaterials';
import { signature } from './signature';
import { logoDisplay } from './logo';
import { supplyChain } from './supplyChain';
import { message } from './message';
import { resources } from './resources';
import { workbench } from './workbench';
import { energyMeter } from './energyMeter';

export interface RootModel extends Models<RootModel> {
  user: typeof user;
  contextInfo: typeof contextInfo;
  excelImport: typeof excelImport;
  transferMaterials: typeof transferMaterials;
  signature: typeof signature;
  logoDisplay: typeof logoDisplay;
  supplyChain: typeof supplyChain;
  message: typeof message;
  resources: typeof resources;
  workbench: typeof workbench;
  energyMeter: typeof energyMeter;
}

export type RootState = { [key in keyof RootModel]: RootModel[key]['state'] };

export const models: RootModel = {
  user,
  contextInfo,
  excelImport,
  transferMaterials,
  signature,
  logoDisplay,
  supplyChain,
  message,
  resources,
  workbench,
  energyMeter,
};
