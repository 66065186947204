import {
  ProductionOrderTypesEnum,
  BusinessStatusEnum,
  SuspendedStatusEnum,
  GoodsIssusStatusEnum,
  GenerateRulesEnum,
  ItemTypeEnum,
  BatchGenerationTypeEnum,
  AcquisitionApplicationEnum,
  GenerationEnum,
  SubstitutionStrategyEnum,
  pickModeEnum,
  WorkOrderCloseTypeEnum,
  SourceType,
  WorkOrderStatus,
  WOCloseFilterTypeEnum,
  QualityStatusEnum,
} from '.';
import { mappingsFactory } from '../utils';

/** 工单类型字段map */
export const ProductionOrderTypesEnumDisplayMap = mappingsFactory(
  ['普通', ProductionOrderTypesEnum.ORDINARY],
  ['返工', ProductionOrderTypesEnum.REWORK],
  ['改制', ProductionOrderTypesEnum.RESTRUCTURING],
  ['维修', ProductionOrderTypesEnum.MAINTAIN],
);

/** 业务状态字段map */
export const BusinessStatusEnumDisplayMap = mappingsFactory(
  ['草稿', BusinessStatusEnum.DRAFT],
  ['新建', BusinessStatusEnum.CREATED],
  ['已下发', BusinessStatusEnum.SCHEDULED],
  ['执行中', BusinessStatusEnum.IN_PROGRESS],
  ['已完工', BusinessStatusEnum.COMPLETED],
  ['已关闭', BusinessStatusEnum.CLOSED],
);

/** 暂停状态字段map */
export const SuspendedStatusEnumDisplayMap = mappingsFactory(
  ['未暂停', SuspendedStatusEnum.NOTSUSPENDED],
  ['暂停中', SuspendedStatusEnum.PAUSED],
);

/** 发料状态字段map */
export const GoodsIssusStatusEnumDisplayMap = mappingsFactory(
  ['未发料', GoodsIssusStatusEnum.UNRELEASED],
  ['部分发料', GoodsIssusStatusEnum.PARTIALISSUE],
  ['全部发料', GoodsIssusStatusEnum.ALLISSUES],
  ['超额发料', GoodsIssusStatusEnum.OVERISSUE],
);
/** 生成规则 */
export const GenerateRulesEnumDisplayMap = mappingsFactory(
  ['未暂停', GenerateRulesEnum.GENERATE],
  ['暂停中', GenerateRulesEnum.NOTGENERATE],
);

/** 领料方式 */
export const ItemTypeEnumDisplayMap = mappingsFactory(
  ['标准件', ItemTypeEnum.STANDARD],
  ['替代件', ItemTypeEnum.REPLACE],
);

/** 批号方式 */
export const BatchGenerationTypeDisplayMap = mappingsFactory(
  ['批次号规则', BatchGenerationTypeEnum.NUMBERINGRULES],
  ['批次号', BatchGenerationTypeEnum.BATCH],
  ['不生成', BatchGenerationTypeEnum.DOESNOTGENERATE],
);

/** 领料申请单据 */
export const AcquisitionApplicationEnumDisplayMap = mappingsFactory(
  ['生产工单', AcquisitionApplicationEnum.PRODUCTIONWORKORDER],
  ['生产任务', AcquisitionApplicationEnum.PRODUCTIONTASK],
);

/** 生成方式 */
export const GenerationEnumDisplayMap = mappingsFactory(
  ['手工录入', GenerationEnum.manual],
  ['拆分', GenerationEnum.split],
  ['合并', GenerationEnum.merge],
  ['改制', GenerationEnum.transform],
  ['计划订单投放', GenerationEnum.launch],
  ['排程', GenerationEnum.scheduling],
);

/** 替代策略 */
export const SubstitutionStrategyMap = mappingsFactory(
  ['混用替代', SubstitutionStrategyEnum.HYBRID],
  ['整批替代', SubstitutionStrategyEnum.COMPLETE],
);

/** 替代料领料方式 */
export const pickModeMap = mappingsFactory(
  ['按需领料', pickModeEnum.ONDEMAND],
  ['不领料', pickModeEnum.NOPICKMODE],
);

/**
 * 生产工单关闭类型
 */
export const WorkOrderCloseTypeMap = mappingsFactory(
  ['正常关闭', WorkOrderCloseTypeEnum.normalShutdown],
  ['异常关闭', WorkOrderCloseTypeEnum.abnormalShutdown],
);

/** 来源单据类型map */
export const SourceTypeDisplayMap = mappingsFactory(
  ['生产工单', SourceType.produceWorkOrder],
  ['销售订单', SourceType.salesOrder],
  ['计划订单', SourceType.ploOrder],
);

/** 工单状态 */
export const WOStatusDisplayMap = mappingsFactory(
  ['已下发', WorkOrderStatus.dispatch],
  ['执行中', WorkOrderStatus.perform],
  ['已完工', WorkOrderStatus.completed],
  ['已关闭', WorkOrderStatus.closed],
);

/** 工单状关闭态 - 生产任务使用 */
export const WOCloseTypeDisplayMap = mappingsFactory(
  ['未关闭', WOCloseFilterTypeEnum.notClosed],
  ['正常关闭', WOCloseFilterTypeEnum.normalShutdown],
  ['异常关闭', WOCloseFilterTypeEnum.abnormalShutdown],
);

/**
 * 报工记录中的质量状态
 */
export const QualityStatusOptions = mappingsFactory(
  ['合格', QualityStatusEnum.qualified],
  ['不合格', QualityStatusEnum.unqualified],
);
