import React, { useState, useCallback, useEffect } from 'react';
import { Form, Button, Row, Col } from 'antd';
import update from 'immutability-helper';
import { BlIcon } from 'src/components';
import AddModal from './addAndEdit';
import { CheckItemGroup as Group } from 'src/page/quality/qcConfig/index.d';
import CheckItemGroup from './checkItemGroup';
import _ from 'lodash';
import { CheckItem } from 'src/page/quality/models/dto';
import { appEnum } from 'src/dict';
import { LOGIC } from 'src/page/quality/models/constants';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { useCustomFieldCombinedData } from 'src/components/customField';

const CheckItemInfo = (props: { form: any }) => {
  const { form } = props;
  const [groups, setGroups] = useState<Group[]>([]);
  const [refresh, setRefresh] = useState<number>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [groupKey, setGroupKey] = useState<number>(0);
  const [visibleList, setVisibleList] = useState<number[]>([]);
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.checkItemRecord);

  const [createForm] = Form.useForm();

  const initialValues = {
    checkCountType: appEnum.Quality.CheckCountTypeEnum.none,
    executeRemark: true,
    executeUpload: true,
    key: null,
  };

  useEffect(() => {
    if (form.getFieldValue('checkItemList')?.length) {
      setGroups(form.getFieldValue('checkItemList'));
      setRefresh(Math.random());
    }
  }, []);

  const addGroup = () => {
    const newKey = _.last(groups) ? Number(_.last(groups)?.key) + 1 : 0;
    const checkItemList = form.getFieldValue('checkItemList') || [];

    checkItemList?.push({ key: newKey, name: '', list: [] });
    form.setFieldsValue({ checkItemList });
    setGroups(checkItemList);
    setRefresh(Math.random());
  };

  const judgeVisible = (key: number) => {
    return visibleList.includes(key);
  };

  const extendGroup = (key: number) => {
    if (!judgeVisible(key)) {
      setVisibleList((preList) => [...preList, key]);
    }
  };

  const deleteVisible = (key: number) => {
    setVisibleList((preList) => preList.filter((i) => i !== key));
  };

  const clearVisible = () => {
    setVisibleList([]);
  };

  const extendsAll = () => {
    setVisibleList(_.map(groups, 'key'));
  };

  const deleteGroup = (_groupKey: number) => {
    if (_groupKey > -1) {
      const newGroup = groups.filter((i) => i.key !== _groupKey);

      setGroups(newGroup);
      setRefresh(Math.random());
      form.setFieldsValue({ checkItemList: newGroup });
    }
  };

  const openAddModal = (_groupKey: number) => {
    setIsEdit(false);
    setGroupKey(_groupKey);
    setModalVisible(true);
    createForm.resetFields();
    createForm.setFieldsValue({ ...initialValues });
  };

  const openEditModal = (_groupKey: number, checkItemData: CheckItem) => {
    setModalVisible(true);
    setIsEdit(true);
    setGroupKey(_groupKey);
    createForm.setFieldsValue({
      ...checkItemData,
      logic: checkItemData.logic === LOGIC.null ? null : checkItemData.logic,
    });
  };

  const openCopyModal = (_groupKey: number, checkItemData: CheckItem) => {
    setModalVisible(true);
    setIsEdit(false);
    setGroupKey(_groupKey);
    createForm.setFieldsValue({
      ...checkItemData,
      logic: checkItemData.logic === LOGIC.null ? null : checkItemData.logic,
      key: null,
    });
  };

  const delteCheckItem = (_groupKey: number, checkItemList: CheckItem[]) => {
    const data = getGroupCheckItemList(_groupKey);

    const newCheckItemList = data?.filter((i: CheckItem) => {
      if (
        !_.find(
          checkItemList,
          (checkItemData) => i.checkItem.value === checkItemData.checkItem.value,
        )
      ) {
        return i;
      }
    });

    const newGroups = groups.map((group: Group) => {
      if (group.key === _groupKey) {
        group.list = newCheckItemList;
      }
      return group;
    });

    setGroups(newGroups);
  };

  const getGroupCheckItemList = (key: number): CheckItem[] => {
    if (groups.length) {
      const index = _.findIndex(groups, (i: Group) => i.key === key);
      const list = groups[index]?.list ?? [];

      return list;
    }
    return [];
  };

  const submitCheckItem = async (_groupKey: number, data: any, isEdit: boolean) => {
    const groupData = getGroupCheckItemList(_groupKey);

    console.log(groupData);
    let newList = groupData.concat([data]);

    if (isEdit) {
      newList = groupData.map((i: any) => {
        if (i.key === data.key) {
          return data;
        }
        return i;
      });
    }
    const checkItemFormData = form.getFieldValue('checkItemList');

    const newGroupList = _.map(checkItemFormData, (group) => {
      if (group.key === _groupKey) {
        group.list = newList;
      }
      return group;
    });

    form.setFieldsValue({ checkItemList: newGroupList });
    setGroups(checkItemFormData);
    setRefresh(Math.random());
    setModalVisible(false);
  };

  const changeCheckItemList = (_groupKey: number, list: CheckItem[]) => {
    if (groups.length) {
      const index = _.findIndex(groups, (i: Group) => i.key === _groupKey);

      groups[index].list = list;
      setGroups(groups);
      setRefresh(Math.random());
    }
  };

  const validateRequired = () => {
    return () => {
      return new Promise((resolve, reject) => {
        const checkItemList = form.getFieldValue('checkItemList');

        if (!checkItemList || !checkItemList?.length) {
          return reject('至少添加一个检验分组');
        }
        return resolve(true);
      });
    };
  };

  const moveGroup = (dragIndex: number, hoverIndex: number) => {
    const dragCard = groups[dragIndex];

    setGroups(
      update(groups, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      }),
    );
    setRefresh(Math.random());
  };

  const renderGroup = useCallback(() => {
    return {
      title: '检验项明细列表',
      columns: 1,
      items: [
        {
          label: '检验项',
          name: 'checkItemList',
          required: true,
          isFullLine: true,
          rules: [{ validator: validateRequired() }],
          labelCol: { flex: '120px' },
          render: () => (
            <>
              <div>
                {groups.length ? (
                  <Row style={{ marginBottom: 10 }}>
                    <Col flex={12}>
                      {/* <Button disabled type={'link'}>
                        批量导入
                      </Button> */}
                    </Col>
                    <Col flex={'40px'}>
                      {visibleList.length === groups.length ? (
                        <Button
                          style={{ height: 32 }}
                          icon={<BlIcon type="iconshouqi" />}
                          onClick={() => clearVisible()}
                        >
                          展开全部
                        </Button>
                      ) : (
                        <Button
                          style={{ height: 32 }}
                          icon={<BlIcon type="iconzhankai" />}
                          onClick={() => extendsAll()}
                        >
                          收起全部
                        </Button>
                      )}
                    </Col>
                  </Row>
                ) : null}
              </div>
              <div style={{ width: '100%' }}>
                {groups?.map((group) => (
                  <CheckItemGroup
                    key={group.key}
                    groupKey={group.key}
                    deleteGroup={deleteGroup}
                    openAddModal={openAddModal}
                    form={form}
                    openEditModal={openEditModal}
                    openCopyModal={openCopyModal}
                    checkItemList={getGroupCheckItemList(group.key)}
                    delteCheckItem={delteCheckItem}
                    extendGroup={extendGroup}
                    judgeVisible={judgeVisible}
                    deleteVisible={deleteVisible}
                    changeCheckItemList={changeCheckItemList}
                    moveGroup={moveGroup}
                    checkType={form.getFieldValue('checkType')}
                    customFields={customFields}
                  />
                ))}
              </div>
              <Button
                type={'primary'}
                icon={<BlIcon type="iconxinjiantianjia" />}
                onClick={addGroup}
                style={{ marginLeft: 20 }}
              >
                添加分组
              </Button>
              {modalVisible && (
                <AddModal
                  visible={modalVisible}
                  form={createForm}
                  isEdit={isEdit}
                  submitData={submitCheckItem}
                  closeModal={() => setModalVisible(false)}
                  groupKey={groupKey}
                  checkItemList={groups.find((group) => group.key === groupKey)?.list || []}
                  checkType={form.getFieldValue('checkType')}
                  customFields={customFields}
                  checkEntityType={form.getFieldValue('checkEntityType')}
                  customCount={form.getFieldValue('checkEntityUnitCount')}
                />
              )}
            </>
          ),
        },
      ],
    };
  }, [refresh, modalVisible, isEdit, visibleList.length]);

  return renderGroup();
};

export default CheckItemInfo;
