import CreateQrCode from 'src/page/qrCodePrint/createCode';
import ApprovalList from 'src/page/universal/approvalManagement/approval';
import ApprovalDetail from 'src/page/universal/approvalManagement/approval/detail';
import authDict from 'src/utils/auth';
import EditReportTemplate from './edit';
import ReportTemplateList from './list';
import { BASIC_PATH, QR_PATH, APPROVALMANAGEMENT } from './constants';

const reportTemplateRouteConfig = [
  {
    path: APPROVALMANAGEMENT,
    menu: '审批管理',
    breadcrumb: '审批管理',
    redirectPath: `${APPROVALMANAGEMENT}/approval`,
    children: [
      {
        path: `${APPROVALMANAGEMENT}/approval`,
        auth: authDict.approvalInstance_view,
        menu: '审批单',
        breadcrumb: '审批单',
        component: ApprovalList,
        children: [
          {
            path: `${APPROVALMANAGEMENT}/approval/:id/detail`,
            auth: authDict.approvalInstance_detail,
            menu: '审批单详情',
            breadcrumb: '审批单详情',
            component: ApprovalDetail,
          },
          {
            path: `${APPROVALMANAGEMENT}/approval_task/:id/detail`,
            menu: '审批任务详情',
            breadcrumb: '审批任务详情',
            component: ApprovalDetail,
          },
        ],
      },
    ],
  },
  {
    path: BASIC_PATH,
    menu: '报告模板',
    breadcrumb: '报告模板',
    children: [
      {
        path: `${BASIC_PATH}/list`,
        menu: '报告模板',
        auth: authDict.reporttemplate_view,
        component: ReportTemplateList,
      },
      {
        path: `${BASIC_PATH}/create`,
        breadcrumb: '创建',
        component: EditReportTemplate,
        auth: authDict.reporttemplate_add,
      },
      {
        path: `${BASIC_PATH}/:id/edit`,
        breadcrumb: '编辑',
        component: EditReportTemplate,
        auth: authDict.reporttemplate_edit,
      },
      {
        path: `${BASIC_PATH}/copy`,
        breadcrumb: '复制',
        component: EditReportTemplate,
        auth: authDict.reporttemplate_add,
      },
    ],
  },

  {
    path: `${QR_PATH}`,
    menu: '标识码打印',
    breadcrumb: '标识码打印',
    children: [
      {
        path: `${QR_PATH}/print`,
        auth: authDict.identificationcode_view,
        menu: '标识码打印',
        component: CreateQrCode,
      },
    ],
  },
];

export default reportTemplateRouteConfig;
