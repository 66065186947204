import { useState, useCallback, useEffect } from 'react';
import { Form, Checkbox, Switch, message, Space, InputNumber, Input } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';
import {
  validateBlInputNumberInt,
  validatorNumberDigtis,
} from 'src/page/custom_fields/fieldsList/components/verificationRules/verificationRules';

import {
  fetchWorkOrderConfigGet,
  fetchWorkOrderConfigSave,
  FetchWorkOrderConfigSaveRequest,
} from 'src/api/ytt/med-domain/work_order';
import { debounce } from 'lodash';

interface SetProductionWorkOrderProps {
  onCancel: () => void;
}

const titleStyle = { paddingRight: 10, minWidth: 120, display: 'inline-block', textAlign: 'right' };

const SetProductionWorkOrder = (props: SetProductionWorkOrderProps) => {
  const [form] = Form.useForm<any>();
  const { onCancel } = props;
  const [switchCompletion, setSwitchCompletion] = useState<Boolean>();

  const [switchFinish, setonSwitchFinish] = useState<Boolean>();

  useEffect(() => {
    fetchWorkOrderConfigGet()
      .then((res) => {
        console.log('res: ', res);
        const { data } = res;

        const initData = formatDataToInit(data);

        console.log('initData: ', initData);

        form.setFieldsValue(initData);
      })
      .catch((err) => {
        console.log('err: ', err);
        // history.goBack();
      })
      .finally(() => {
        // setLoading(false);
      });
  }, []);

  const formatDataToInit = (data: any) => {
    const { pickOrderConfigVO, closeConfig, finishConfig } = data;

    /** 初始化领料状态 */
    const _pickOrderConfigVO = {
      // ...pickOrderConfigVO,
      splitByProcess: !!pickOrderConfigVO?.splitByProcess,
      splitByRequirementTime: !!pickOrderConfigVO?.splitByRequirementTime,
      splitByWorkOrder: !!pickOrderConfigVO?.splitByWorkOrder,
    };

    /** 初始化结束状态 */
    const _finishConfig = {
      ...finishConfig,
      autoFinish: !!finishConfig?.autoFinish,
      checkExecuting: !!finishConfig?.checkExecuting,
      checkMainDeliveredAmountMinLimit: !!finishConfig?.checkMainDeliveredAmountMinLimit,
      checkMainFinishedAmountMinLimit: !!finishConfig?.checkMainFinishedAmountMinLimit,
      checkNotMainDeliveredAmountMinLimit: !!finishConfig?.checkNotMainDeliveredAmountMinLimit,
      checkNotMainFinishedAmountMinLimit: !!finishConfig?.checkNotMainFinishedAmountMinLimit,
      checkPause: !!finishConfig?.checkPause,
      checkAllTaskTerminated: !!finishConfig?.checkAllTaskTerminated,
    };

    /** 初始化关闭状态 */
    const _closeConfig = {
      ...closeConfig,
      autoClose: !!closeConfig?.autoClose,
      checkFinished: !!closeConfig?.checkFinished,
      checkMainDeliveredAmountMinLimit: !!closeConfig?.checkMainDeliveredAmountMinLimit,
      checkMainFinishedAmountMinLimit: !!closeConfig?.checkMainFinishedAmountMinLimit,
      checkNotMainDeliveredAmountMinLimit: !!closeConfig?.checkNotMainDeliveredAmountMinLimit,
      checkNotMainFinishedAmountMinLimit: !!closeConfig?.checkNotMainFinishedAmountMinLimit,
      checkPause: !!closeConfig?.checkPause,
      checkAllTaskTerminated: !!closeConfig?.checkAllTaskTerminated,
    };

    if (finishConfig?.autoFinish) {
      setSwitchCompletion(true);
    }
    if (closeConfig?.autoClose) {
      setonSwitchFinish(true);
    }

    return {
      pickOrderConfigVO: { ..._pickOrderConfigVO },
      closeConfig: { ..._closeConfig },
      finishConfig: { ..._finishConfig },
    };
  };

  // 工单自动完工
  const onSwitchCompletion = useCallback((enable: boolean) => {
    if (enable) {
      setSwitchCompletion(true);
      message.success(
        '开启生产工单自动完工后，系统自动会在每日00:00点完工所有符合完工条件的生产工单',
      );
    } else {
      setSwitchCompletion(false);
    }
  }, []);

  // 工单自动关闭
  const onSwitchFinish = useCallback((enable: boolean) => {
    if (enable) {
      setonSwitchFinish(true);
      message.success(
        '开启生产工单自动关闭后，系统自动会在每日00:00点完工所有符合完工条件的生产工单',
      );
    } else {
      setonSwitchFinish(false);
    }
  }, []);

  const baseInfo: DataFormLayoutInfoBlock = {
    title: '',
    // column: 2,
    items: [
      {
        label: 'id',
        name: ['pickOrderConfigVO', 'id'],
        hidden: true,
        render: () => <Input />,
      },
      {
        label: '',
        validateFirst: true,

        render: () => (
          <Space>
            <span style={titleStyle}>领料申请分单原则</span>
            <Form.Item
              name={['pickOrderConfigVO', 'splitByWorkOrder']}
              fieldKey={['pickOrderConfigVO', 'splitByWorkOrder']}
              style={{ marginBottom: '0' }}
              valuePropName="checked"
            >
              <Checkbox>生产工单</Checkbox>
            </Form.Item>
            <Form.Item
              name={['pickOrderConfigVO', 'splitByProcess']}
              fieldKey={['pickOrderConfigVO', 'splitByProcess']}
              style={{ marginBottom: '0' }}
              valuePropName="checked"
            >
              <Checkbox>投料工序</Checkbox>
            </Form.Item>
            <Form.Item
              name={['pickOrderConfigVO', 'splitByRequirementTime']}
              fieldKey={['pickOrderConfigVO', 'splitByRequirementTime']}
              style={{ marginBottom: '0' }}
              valuePropName="checked"
            >
              <Checkbox>需求时间</Checkbox>
            </Form.Item>
          </Space>
        ),
      },
      {
        label: '',
        validateFirst: true,
        isFullLine: true,
        render: () => (
          <Form.Item
            name={['finishConfig', 'autoFinish']}
            fieldKey={['autoFinish']}
            style={{ marginBottom: '0' }}
            valuePropName="checked"
          >
            <>
              <span style={titleStyle}>工单自动完工</span>

              <Switch onChange={onSwitchCompletion} />
            </>
          </Form.Item>
        ),
      },
      {
        validateFirst: true,
        hidden: !switchCompletion,
        isFullLine: true,
        render: () => (
          <Form.Item
            name={['finishConfig', 'checkExecuting']}
            fieldKey={['finishConfig', 'checkExecuting']}
            style={{ marginBottom: '0' }}
            valuePropName="checked"
            initialValue={{ checked: true }}
          >
            <>
              <span style={{ paddingRight: 13 }}>设置自动完工条件</span>

              <Checkbox disabled>生产工单业务状态为“执行中”</Checkbox>
            </>
          </Form.Item>
        ),
      },
      {
        hidden: !switchCompletion,
        isFullLine: true,
        render: () => (
          <Form.Item
            name={['finishConfig', 'checkPause']}
            fieldKey={['finishConfig', 'checkPause']}
            style={{ marginBottom: '0', marginLeft: 125 }}
            valuePropName="checked"
            initialValue={{ checked: true }}
          >
            <Checkbox disabled>生产工单暂停状态为“未暂停”</Checkbox>
          </Form.Item>
        ),
      },
      {
        hidden: !switchCompletion,
        isFullLine: true,
        render: () => (
          <Form.Item
            name={['finishConfig', 'checkAllTaskTerminated']}
            fieldKey={['finishConfig', 'checkAllTaskTerminated']}
            style={{ marginBottom: '0', marginLeft: 125 }}
            valuePropName="checked"
            initialValue={{ checked: true }}
          >
            <Checkbox disabled>生产工单相关生产状态不为“新建、暂停中、执行中”</Checkbox>
          </Form.Item>
        ),
      },
      {
        hidden: !switchCompletion,
        isFullLine: true,
        render: () => (
          <Space>
            <Form.Item
              name={['finishConfig', 'checkMainFinishedAmountMinLimit']}
              fieldKey={['finishConfig', 'checkMainFinishedAmountMinLimit']}
              style={{ marginBottom: '0', marginLeft: 125 }}
              valuePropName="checked"
              initialValue={{ checked: true }}
            >
              <Checkbox disabled>生产工单主产出总报工数 大于等于计划生产数*</Checkbox>
            </Form.Item>
            <Form.Item
              name={['finishConfig', 'mainFinishedAmountMinLimit']}
              fieldKey={['finishConfig', 'mainFinishedAmountMinLimit']}
              style={{ marginBottom: '0' }}
              rules={[
                { required: !!switchCompletion, message: '必填' },
                {
                  validator: validateBlInputNumberInt(0, 1000, false, false, '总报工数'),
                },
                {
                  validator: validatorNumberDigtis(4),
                },
              ]}
            >
              <InputNumber /> %
            </Form.Item>
          </Space>
        ),
      },
      {
        hidden: !switchCompletion,
        isFullLine: true,
        render: () => (
          <Space>
            <Form.Item
              name={['finishConfig', 'checkNotMainFinishedAmountMinLimit']}
              fieldKey={['finishConfig', 'checkNotMainFinishedAmountMinLimit']}
              style={{ marginBottom: '0', marginLeft: 125 }}
              valuePropName="checked"
              initialValue={{ checked: false }}
            >
              <Checkbox checked>生产工单非主产出总报工数 大于等于计划生产数*</Checkbox>
            </Form.Item>
            <Form.Item
              name={['finishConfig', 'notMainFinishedAmountMinLimit']}
              fieldKey={['finishConfig', 'notMainFinishedAmountMinLimit']}
              style={{ marginBottom: '0' }}
            >
              <InputNumber /> %
            </Form.Item>
          </Space>
        ),
      },
      {
        hidden: !switchCompletion,
        isFullLine: true,
        render: () => (
          <Space>
            <Form.Item
              name={['finishConfig', 'checkMainDeliveredAmountMinLimit']}
              fieldKey={['finishConfig', 'checkMainDeliveredAmountMinLimit']}
              style={{ marginBottom: '0', marginLeft: 125 }}
              valuePropName="checked"
              initialValue={{ checked: true }}
            >
              <Checkbox disabled>生产工单主产出入库数 大于等于计划生产数*</Checkbox>
            </Form.Item>
            <Form.Item
              name={['finishConfig', 'mainDeliveredAmountMinLimit']}
              fieldKey={['finishConfig', 'mainDeliveredAmountMinLimit']}
              style={{ marginBottom: '0' }}
            >
              <InputNumber /> %
            </Form.Item>
          </Space>
        ),
      },
      {
        hidden: !switchCompletion,
        isFullLine: true,
        render: () => (
          <Space>
            <Form.Item
              name={['finishConfig', 'checkNotMainDeliveredAmountMinLimit']}
              fieldKey={['finishConfig', 'checkNotMainDeliveredAmountMinLimit']}
              style={{ marginBottom: '0', marginLeft: 125 }}
              valuePropName="checked"
              initialValue={{ checked: false }}
            >
              <Checkbox checked>生产工单非主产出入库数 大于等于计划生产数*</Checkbox>
            </Form.Item>
            <Form.Item
              name={['finishConfig', 'notMainDeliveredAmountMinLimit']}
              fieldKey={['finishConfig', 'notMainDeliveredAmountMinLimit']}
              style={{ marginBottom: '0' }}
            >
              <InputNumber /> %
            </Form.Item>
          </Space>
        ),
      },
      {
        label: '',
        validateFirst: true,
        isFullLine: true,
        render: () => (
          <Form.Item
            name={['closeConfig', 'autoClose']}
            fieldKey={['closeConfig', 'autoClose']}
            style={{ marginBottom: '0' }}
            valuePropName="checked"
          >
            <>
              <span style={titleStyle}>工单自动关闭</span>

              <Switch onChange={onSwitchFinish} />
            </>
          </Form.Item>
        ),
      },
      {
        validateFirst: true,
        hidden: !switchFinish,
        label: '',
        isFullLine: true,
        render: () => (
          <Form.Item
            name={['closeConfig', 'checkFinished']}
            fieldKey={['closeConfig', 'checkFinished']}
            style={{ marginBottom: '0' }}
            valuePropName="checked"
            initialValue={{ checked: true }}
          >
            <>
              <span style={{ paddingRight: 13 }}>设置自动关闭条件</span>
              <Checkbox disabled>生产工单业务状态为“已完工”</Checkbox>
            </>
          </Form.Item>
        ),
      },
      {
        hidden: !switchFinish,
        isFullLine: true,
        render: () => (
          <Form.Item
            name={['closeConfig', 'checkPause']}
            fieldKey={['closeConfig', 'checkPause']}
            style={{ marginBottom: '0', marginLeft: 125 }}
            valuePropName="checked"
            initialValue={{ checked: true }}
          >
            <Checkbox disabled>生产工单暂停状态为“未暂停”</Checkbox>
          </Form.Item>
        ),
      },
      {
        hidden: !switchFinish,
        isFullLine: true,
        render: () => (
          <Form.Item
            name={['closeConfig', 'checkAllTaskTerminated']}
            fieldKey={['closeConfig', 'checkAllTaskTerminated']}
            style={{ marginBottom: '0', marginLeft: 125 }}
            valuePropName="checked"
            initialValue={{ checked: true }}
          >
            <Checkbox disabled>生产工单下所有生产任务状态不为“新建、暂停中、执行中”</Checkbox>
          </Form.Item>
        ),
      },
      {
        hidden: !switchFinish,
        isFullLine: true,
        render: () => (
          <Space>
            <Form.Item
              name={['closeConfig', 'checkMainFinishedAmountMinLimit']}
              fieldKey={['closeConfig', 'checkMainFinishedAmountMinLimit']}
              style={{ marginBottom: '0', marginLeft: 125 }}
              valuePropName="checked"
              initialValue={{ checked: true }}
            >
              <Checkbox disabled>生产工单主产出总报工数 大于等于计划生产数*</Checkbox>
            </Form.Item>
            <Form.Item
              name={['closeConfig', 'mainFinishedAmountMinLimit']}
              fieldKey={['closeConfig', 'mainFinishedAmountMinLimit']}
              style={{ marginBottom: '0' }}
              rules={[
                { required: !!switchFinish, message: '必填' },
                {
                  validator: validateBlInputNumberInt(0, 1000, false, false, '总报工数'),
                },
                {
                  validator: validatorNumberDigtis(4),
                },
              ]}
            >
              <InputNumber /> %
            </Form.Item>
          </Space>
        ),
      },
      {
        hidden: !switchFinish,
        isFullLine: true,
        render: () => (
          <Space>
            <Form.Item
              name={['closeConfig', 'checkNotMainFinishedAmountMinLimit']}
              fieldKey={['closeConfig', 'checkNotMainFinishedAmountMinLimit']}
              style={{ marginBottom: '0', marginLeft: 125 }}
              valuePropName="checked"
              initialValue={{ checked: false }}
            >
              <Checkbox checked>生产工单非主产出总报工数 大于等于计划生产数*</Checkbox>
            </Form.Item>
            <Form.Item
              name={['closeConfig', 'notMainFinishedAmountMinLimit']}
              fieldKey={['closeConfig', 'notMainFinishedAmountMinLimit']}
              style={{ marginBottom: '0' }}
            >
              <InputNumber /> %
            </Form.Item>
          </Space>
        ),
      },
      {
        hidden: !switchFinish,
        isFullLine: true,
        render: () => (
          <Space>
            <Form.Item
              name={['closeConfig', 'checkMainDeliveredAmountMinLimit']}
              fieldKey={['closeConfig', 'checkMainDeliveredAmountMinLimit']}
              style={{ marginBottom: '0', marginLeft: 125 }}
              valuePropName="checked"
              initialValue={{ checked: true }}
            >
              <Checkbox disabled>生产工单主产出入库数 大于等于计划生产数*</Checkbox>
            </Form.Item>
            <Form.Item
              name={['closeConfig', 'mainDeliveredAmountMinLimit']}
              fieldKey={['closeConfig', 'mainDeliveredAmountMinLimit']}
              style={{ marginBottom: '0' }}
            >
              <InputNumber /> %
            </Form.Item>
          </Space>
        ),
      },
      {
        hidden: !switchFinish,
        isFullLine: true,
        render: () => (
          <Space>
            <Form.Item
              name={['closeConfig', 'checkNotMainDeliveredAmountMinLimit']}
              fieldKey={['closeConfig', 'checkNotMainDeliveredAmountMinLimit']}
              style={{ marginBottom: '0', marginLeft: 125 }}
              valuePropName="checked"
              initialValue={{ checked: false }}
            >
              <Checkbox checked>生产工单非主产出入库数 大于等于计划生产数*</Checkbox>
            </Form.Item>
            <Form.Item
              name={['closeConfig', 'notMainDeliveredAmountMinLimit']}
              fieldKey={['closeConfig', 'notMainDeliveredAmountMinLimit']}
              style={{ marginBottom: '0' }}
            >
              <InputNumber /> %
            </Form.Item>
          </Space>
        ),
      },
    ],
  };

  const handleFinish = debounce(async () => {
    try {
      const formValue = await form?.validateFields();

      const { pickOrderConfigVO, closeConfig, finishConfig } = formValue;
      const submitVal: FetchWorkOrderConfigSaveRequest = {
        ...formValue,
        pickOrderConfig: {
          ...pickOrderConfigVO,
          splitByProcess: Number(pickOrderConfigVO.splitByProcess),
          splitByRequirementTime: Number(pickOrderConfigVO.splitByRequirementTime),
          splitByWorkOrder: Number(pickOrderConfigVO.splitByWorkOrder),
        },
        finishConfig: {
          ...finishConfig,
          autoFinish: Number(finishConfig.autoFinish),
          checkExecuting: Number(finishConfig.checkExecuting),
          checkMainDeliveredAmountMinLimit: Number(finishConfig.checkMainDeliveredAmountMinLimit),
          checkMainFinishedAmountMinLimit: Number(finishConfig.checkMainFinishedAmountMinLimit),
          checkNotMainDeliveredAmountMinLimit: Number(
            finishConfig.checkNotMainDeliveredAmountMinLimit,
          ),
          checkNotMainFinishedAmountMinLimit: Number(
            finishConfig.checkNotMainFinishedAmountMinLimit,
          ),
          checkPause: Number(finishConfig.checkPause),
          checkAllTaskTerminated: Number(finishConfig.checkAllTaskTerminated),
        },
        closeConfig: {
          ...closeConfig,
          autoClose: Number(closeConfig.autoClose),
          checkMainDeliveredAmountMinLimit: Number(closeConfig.checkMainDeliveredAmountMinLimit),
          checkMainFinishedAmountMinLimit: Number(closeConfig.checkMainFinishedAmountMinLimit),
          checkNotMainDeliveredAmountMinLimit: Number(
            closeConfig.checkNotMainDeliveredAmountMinLimit,
          ),
          checkNotMainFinishedAmountMinLimit: Number(
            closeConfig.checkNotMainFinishedAmountMinLimit,
          ),
          checkPause: Number(closeConfig.checkPause),
          checkAllTaskTerminated: Number(closeConfig.checkAllTaskTerminated),
          checkFinished: Number(closeConfig.checkFinished),
        },
      };

      // fetchWorkOrderAutoFinishConfigSave
      // console.log(form.getFieldsValue());
      await fetchWorkOrderConfigSave(submitVal).then(() => {
        message.success('设置成功');
        onCancel();
      });
    } catch (error) {
      console.log('error: ', error);
    }
  }, 1500);

  return (
    <DataFormLayout
      form={form}
      info={[baseInfo]}
      title={'设置'}
      onCancel={() => {
        onCancel();
      }}
      onFinish={() => {
        handleFinish();
      }}
    />
  );
};

export default SetProductionWorkOrder;
