import { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import qs from 'qs';
import { Form, message as Message } from 'antd';
import _ from 'lodash';

import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';

import {
  fetchPickOrderSplitByWorkOrderList,
  fetchPickOrderSplitByInputMaterialList,
  fetchPickOrderSplitByProcessList,
  fetchPickOrderBatchCreate,
  FetchPickOrderBatchCreateRequest,
} from 'src/api/ytt/med-domain/store-requisition';
import { lookup } from 'src/dict';
import { CRUD } from 'src/dict/common';
import { PickOrderType, SplitRequisitionOrigin } from 'src/dict/storeRequisition';
import { showErrorListMessage } from 'src/components/message/errorMessageList';
import { ErrorNotification } from 'src/components/notification';
import { SplitResultListType, StoreRequisitionTableRowDataType } from '../constants';
import StoreRequisitionTable from '../components/StoreRequisitionTable';

/**
 * 根据不同的入口获取分单数据
 * @param originType 入口类型，从url中获取，数据类型为枚举值SplitRequisitionOrigin
 * @param ids 入口处选择的id数组，从url种获取，因此是','分割的字符串
 * */
const fetchSplitDataFunction = (
  originType: string | qs.ParsedQs | string[] | qs.ParsedQs[] | undefined,
  ids: string | qs.ParsedQs | string[] | qs.ParsedQs[] | undefined,
) => {
  const param = ids!
    .toString()
    .split(',')
    .map((id) => Number(id));

  switch (Number(originType) as SplitRequisitionOrigin) {
    case SplitRequisitionOrigin.productionOrder:
      return fetchPickOrderSplitByWorkOrderList({
        workOrderIds: param,
      });
    case SplitRequisitionOrigin.bom:
      return fetchPickOrderSplitByInputMaterialList({
        inputMaterialIds: param,
      });
    case SplitRequisitionOrigin.processPlanning:
      return fetchPickOrderSplitByProcessList({
        processIds: param,
      });
  }
};

/**
 * 后台树形结构数据展开为表单需要的列表数据
 * 同时加上渲染需要的字段rowSpan，并转换sourceWarehouseId和targetWarehouseId(组件需要它们是LabledValue)
 */
const formatLinesData = (apiData: SplitResultListType) => {
  const formattedLineData: StoreRequisitionTableRowDataType[] = [];

  apiData?.forEach((order) => {
    if (order.orderLines) {
      order.orderLines.forEach((orderLine, idx: number) => {
        const {
          sourceWarehouseId,
          sourceWarehouseName,
          sourceWarehouse,
          targetWarehouseId,
          targetWarehouseName,
          targetWarehouse,
          lineNo,
        } = orderLine;

        formattedLineData.push({
          ...orderLine,
          line: lineNo,
          code: order.code,
          rowSpan: idx === 0 ? order.orderLines.length : 0,
          sourceWarehouseId: sourceWarehouseId
            ? {
                value: {
                  id: sourceWarehouseId,
                  name: sourceWarehouseName,
                  code: sourceWarehouse?.code,
                },
                label: sourceWarehouseName!,
              }
            : undefined,
          targetWarehouseId: targetWarehouseId
            ? {
                value: {
                  id: targetWarehouseId,
                  name: targetWarehouseName,
                  code: targetWarehouse?.code,
                },
                label: targetWarehouseName!,
              }
            : undefined,
        });
      });
    }
  });
  return formattedLineData;
};

/**
 * 表单需要的列表数据转换为新建接口入参格式
 */
const formatRequestParam = (formData: StoreRequisitionTableRowDataType[]) => {
  const createParam: FetchPickOrderBatchCreateRequest['createPickOrderCOs'] = [];

  let currentCode: string;
  let remainCount = 0;
  let currentOrderIndex: number;

  formData.forEach((materialLine: StoreRequisitionTableRowDataType) => {
    const currentRequisitionIndex = _.findIndex(createParam, {
      pickOrderCode: materialLine.code,
    });
    const {
      code,
      sourceWarehouseId,
      targetWarehouseId,
      batchNo,
      rowSpan,
      requestPickAmount,
      material,
      ...rest
    } = _.omit(materialLine, [
      'inputProcessCode',
      'inputProcessName',
      'inputProcessId',
      'productionDepartment',
      'sourceWarehouseName',
      'supplierList',
      'targetWarehouseName',
      'workCenter',
      'workOrderCode',
    ]);
    const materialObject = {
      ...rest,
      sourceWarehouseId: sourceWarehouseId ? (sourceWarehouseId.value?.id as number) : undefined,
      targetWarehouseId: targetWarehouseId?.value?.id as number,

      batchCode: batchNo?.key
        ? {
            id: JSON.parse(batchNo?.key)?.id as number,
            code: JSON.parse(batchNo?.key)?.batchNo as string,
          }
        : undefined,
      requestPickAmount: String(requestPickAmount?.amount),
      unitId: requestPickAmount?.unitId!,
      materialId: material?.baseInfo?.id!,
    };

    if (currentRequisitionIndex < 0 && remainCount === 0) {
      remainCount = rowSpan;
      currentCode = code;
      createParam.push({
        pickOrderCode: currentCode!,
        pickOrderType: PickOrderType.product,
        materials: [materialObject],
      });
      currentOrderIndex = createParam.length - 1;
    } else {
      createParam[currentOrderIndex].materials.push(materialObject);
    }

    remainCount--;
  });

  return createParam;
};

// 传给form的设置
// preserve: 在删除字段时保留字段值
// antd的form会在rerender的时候删除所有字段，如果该字段为false，使用form全程不可以rerender
const formProps = {
  preserve: true,
};

// 表单字段名
const tableDataFormFieldName = 'materials';

// 创建结果表格列配置
const errorColumns = [
  {
    title: '领料申请编号',
    dataIndex: 'pickOrderCode',
  },
  {
    title: '失败原因',
    dataIndex: 'reason',
  },
];

/**
 * 新建领料申请
 * 领料申请是比较特殊的新建模式，只支持批量新建，即选中一些生产工单/用料清单/工序计划，由后台进行分单然后一起填写属性
 * 但在编辑/查看详情时，是单个编辑/查看的，因此领料申请的新建和编辑无法共用组件
 * */
export default function CreateStoreRequisition(props: RouteComponentProps) {
  const { search } = useLocation();
  const { history } = props;
  const urlParams = qs.parse(search, { ignoreQueryPrefix: true });
  // 入口路径
  // const originPath = pathArray[pathArray.length - 2];
  const { ids, originType } = urlParams;

  const [storeRequisitionListLoading, setStoreRequisitionListLoading] = useState(false);
  const [createForm] = Form.useForm();

  const dialogTitle = `${lookup('crud', CRUD.create)}领料申请`;
  const goBackToList = () => {
    history.goBack();
  };

  // deep linking 如果没有正确的参数，返回列表页
  if (!ids || !originType) goBackToList();

  const tableInfo: DataFormLayoutInfoBlock[] = [
    {
      items: [
        {
          label: '',
          isFullLine: true,
          render: () => (
            <StoreRequisitionTable
              form={createForm}
              mode={CRUD.create}
              noDataHandler={goBackToList}
            />
          ),
        },
      ],
    },
  ];

  /** 批量创建领料单并提示结果 */
  const createStoreRequisition = async () => {
    try {
      await createForm.validateFields();
      const { materials } = createForm.getFieldsValue();
      const createParam = formatRequestParam(materials);

      setStoreRequisitionListLoading(true);
      const { data } = await fetchPickOrderBatchCreate({ createPickOrderCOs: createParam });

      setStoreRequisitionListLoading(false);
      if (data) {
        const { failCount, successCount, failDetails } = data;

        // 全部新建成功，显示新建成功
        if (failCount === 0) {
          Message.success('新建成功');
        } else if (successCount === 0) {
          // 全部新建失败，提示新建失败及原因
          if (failDetails) {
            ErrorNotification({
              message: '新建失败',
              description: (
                <>
                  {failDetails.map((fail) => (
                    <div key={fail.pickOrderCode}>{fail.reason}</div>
                  ))}
                </>
              ),
            });
          } else {
            Message.error('新建失败！');
          }
        } else if (failDetails) {
          // 否则用表格显示删除结果及原因统计的table
          showErrorListMessage({
            title: '新建出现错误',
            data: failDetails,
            columns: errorColumns,
            operateName: '新建',
            successCount,
            failCount,
          });
        }
      }
      goBackToList();
    } catch (e) {
      setStoreRequisitionListLoading(false);
      // console.log('e :>> ', e);
    }
  };

  useEffect(() => {
    const loadSplitResult = async () => {
      setStoreRequisitionListLoading(true);
      try {
        const splitResult = await fetchSplitDataFunction(originType, ids);

        setStoreRequisitionListLoading(false);
        const { code, data } = splitResult;

        if (code === 200 && data) {
          const formattedDataSource = formatLinesData(data);

          createForm.setFieldsValue({ [tableDataFormFieldName]: formattedDataSource });
        }
      } catch (e) {
        setStoreRequisitionListLoading(false);
      }
    };

    loadSplitResult();
  }, []);

  return (
    <DataFormLayout
      form={createForm}
      info={tableInfo}
      title={dialogTitle}
      infoBlockStyleProps={{ padding: 0, margin: 0, borderBottom: 'none' }}
      onCancel={goBackToList}
      onFinish={createStoreRequisition}
      loading={storeRequisitionListLoading}
      formProps={formProps}
    />
  );
}
