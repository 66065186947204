import { DataFormLayout, DataFormLayoutInfoBlock } from '@blacklake-web/layout';
import { DatePicker, Form, Input, message, Select } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import {
  fetchProduceTaskDetail,
  fetchProduceTaskUpdate,
} from 'src/api/ytt/mfg-domain/productionTask';
import _ from 'lodash';
import {
  formatFormDataToSubmitData,
  formatResponseToFormData,
} from 'src/page/productionPlanning/productionTask/helper/utils';
import {
  fetchWorkOrderGetResourceGroupsForProcess,
  FetchWorkOrderGetResourceGroupsForProcessResponse,
} from 'src/api/ytt/med-domain/work_order';
import {
  ResourceGroupTypeEnum,
  UserResourceGroupBizTypeEnum,
} from 'src/page/productionPlanning/productionTask/helper/constants';
import { SearchSelect } from 'components';
import OtherResourceFormTable from 'src/page/productionPlanning/productionTask/components/OtherResourceFormTable';
import { validatorCheckTwoSidesTrim } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import { validateBlInputText3 } from 'src/page/custom_fields/fieldsList/components/verificationRules/verificationRules';

interface Props extends RouteComponentProps<{ id?: string }> {}

const EditProductionTask = (props: Props) => {
  const { match, history } = props;
  const id = match.params.id;
  const [form] = Form.useForm();
  const [workCenterGroupResponseList, setWorkCenterGroupResponseList] = useState<
    | NonNullable<
        FetchWorkOrderGetResourceGroupsForProcessResponse['data']
      >['workCenterGroupResponseList']
    | null
  >(null);
  const [loading, setLoading] = useState(false);
  const baseResourceGroupList = workCenterGroupResponseList?.slice(0, 3);
  const userResourceGroup = baseResourceGroupList?.find(
    (item) => item.groupType === ResourceGroupTypeEnum.user,
  );
  const departmentResourceGroup = baseResourceGroupList?.find(
    (item) => item.groupType === ResourceGroupTypeEnum.department,
  );
  const deviceResourceGroup = baseResourceGroupList?.find(
    (item) => item.groupType === ResourceGroupTypeEnum.device,
  );
  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,
    title: '基本信息',
    items: [
      {
        label: '任务编号',
        name: 'taskCode',
        validateFirst: true,
        rules: [
          { required: true, message: '生产任务编号不能为空' },
          { max: 255, message: '不超过255字符' },
          { validator: validatorCheckTwoSidesTrim() },
          { validator: validateBlInputText3() },
        ],
        render: () => <Input placeholder="请输入" />,
      },
      {
        label: '状态',
        name: 'taskStatus',
        render: () => <Input disabled />,
      },
      {
        label: '任务物料编号',
        name: 'materialCode',
        render: () => <Input disabled />,
      },
      {
        label: '任务物料名称',
        name: 'materialName',
        render: () => <Input disabled />,
      },
      {
        label: '工序编号',
        name: 'relatedProcessCode',
        render: () => <Input disabled />,
      },
      {
        label: '工序名称',
        name: 'relatedProcessName',
        render: () => <Input disabled />,
      },
      {
        label: '标识码',
        name: 'taskIdentifyCode',
        rules: [
          { max: 255, message: '不超过255字符' },
          { validator: validatorCheckTwoSidesTrim() },
          { validator: validateBlInputText3() },
        ],
        render: () => <Input />,
      },
      {
        label: '工作中心',
        name: 'workCenter',
        render: () => <Input disabled />,
      },
      {
        label: '执行人',
        render: () => {
          return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Input
                disabled
                value={
                  userResourceGroup?.bizType === UserResourceGroupBizTypeEnum.user
                    ? '指定用户'
                    : '指定部门'
                }
                style={{ width: 100, marginRight: 5 }}
              />
              <Form.Item style={{ width: '100%', marginBottom: 0 }} name="executorIds">
                {userResourceGroup?.bizType === UserResourceGroupBizTypeEnum.user ? (
                  <Select
                    labelInValue
                    mode="multiple"
                    allowClear
                    showArrow
                    placeholder={'请选择'}
                    options={userResourceGroup?.userList?.map(({ name, id }) => ({
                      label: name || null,
                      value: id || 0,
                    }))}
                  />
                ) : (
                  <SearchSelect
                    fetchType={'departmentListUserUnderDepartment'}
                    mode="multiple"
                    labelInValue
                    placeholder={'请选择部门下用户'}
                    searchFieldName="search"
                    params={{
                      departmentIds: userResourceGroup?.bizIdList,
                    }}
                  />
                )}
              </Form.Item>
            </div>
          );
        },
      },
      {
        label: '生产部门',
        name: 'departmentIds',
        render: () => (
          <Select
            labelInValue
            mode="multiple"
            allowClear
            showArrow
            placeholder={'请选择'}
            options={departmentResourceGroup?.departmentList?.map(({ name, id }) => ({
              label: name || null,
              value: id || 0,
            }))}
          />
        ),
      },
      {
        label: '设备',
        name: 'equipmentIds',
        render: () => (
          <Select
            labelInValue
            mode="multiple"
            allowClear
            showArrow
            placeholder={'请选择'}
            options={deviceResourceGroup?.resourceList?.map(({ name, id }) => ({
              label: name || null,
              value: id || 0,
            }))}
          />
        ),
      },
      {
        label: '计划开始时间',
        name: 'planStartTime',
        rules: [{ required: true, message: '计划开始时间不能为空' }],
        render: () => <DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />,
      },
      {
        label: '计划结束时间',
        name: 'planEndTime',
        rules: [
          { required: true, message: '计划结束时间不能为空' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const startAt = getFieldValue('planStartTime');

              if (!value || startAt < value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('计划结束时间必须大于计划开始时间'));
            },
          }),
        ],
        render: () => <DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />,
      },
      {
        label: '备注',
        name: 'remark',
        render: () => <Input.TextArea />,
      },
    ],
  };
  const otherResourceInfo: DataFormLayoutInfoBlock = {
    title: '其他资源',
    items: [
      {
        label: '',
        isFullLine: true,
        render: () => {
          return <OtherResourceFormTable name="otherResources" form={form} />;
        },
      },
    ],
  };
  const getDetailDataAndFillInForm = async (id: number) => {
    if (!_.isFinite(id)) return;
    const res = await fetchProduceTaskDetail({ taskId: id });

    if (!res.data) return;

    const res2 = await fetchWorkOrderGetResourceGroupsForProcess({
      processId: res.data.relatedProcessId,
      workOrderId: res.data.relatedWorkOrderId,
    });

    if (!res2.data) {
      form.setFieldsValue(formatResponseToFormData(res.data));
    } else {
      form.setFieldsValue(
        formatResponseToFormData(res.data, res2.data.workCenterGroupResponseList?.slice(3)),
      );
      setWorkCenterGroupResponseList(res2.data.workCenterGroupResponseList);
    }
  };

  useEffect(() => {
    setLoading(true);
    getDetailDataAndFillInForm(Number(id)).finally(() => {
      setLoading(false);
    });
  }, [id]);

  const handleFinish = async () => {
    if (!id) return;
    try {
      await form.validateFields();
      const submitData = formatFormDataToSubmitData(form.getFieldsValue(true), {
        taskId: Number(id),
      });

      setLoading(true);
      const res = await fetchProduceTaskUpdate(submitData).then();

      if (res.code === 200) {
        message.success('编辑生产任务成功');
        handleCancel();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleCancel = useCallback(() => {
    history.goBack();
  }, []);

  return (
    <DataFormLayout
      form={form}
      title="编辑"
      info={_.compact([
        baseInfo,
        workCenterGroupResponseList && workCenterGroupResponseList?.length > 3
          ? otherResourceInfo
          : null,
      ])}
      onFinish={handleFinish}
      onCancel={handleCancel}
      loading={loading}
    />
  );
};

export default EditProductionTask;
