import { toNumber } from 'lodash';
import { FieldType } from 'src/dict/common';

export const isSelectableField = (fieldType: FieldType) =>
  fieldType === FieldType.select || fieldType === FieldType.multiSelect;

export const choiceToLabelValue = ({ choiceCode, choiceValue }: any) => ({
  label: choiceValue,
  value: choiceCode,
});

export const getFieldRealValueProp = (fieldType: FieldType) => {
  return isSelectableField(fieldType) ? 'choiceValues' : 'fieldValue';
};

export const parseLayoutTypes = (layoutTypesStr: string) => {
  return layoutTypesStr.length > 0 ? layoutTypesStr.split(',').map(toNumber) : [];
};
