import React from 'react';
import { DetailLayout, DetailLayoutMenuItem } from 'layout';
import { Badge, message, Space } from 'antd';
import { replaceSign } from 'src/utils/constants';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { CreatorData } from 'src/page/knowledgeManagement/calendarManagement/shift/interface';
import { BlTable, TagTooltip, Tooltip } from 'src/components';
import _Time from 'src/utils/dataTypes/time';
import { UsageStatus } from 'src/dict/common';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { ApprovalDesignationObjectEnum } from 'src/dict/processManagement';
import { useHistory } from 'react-router-dom';
import { getDetailPageUrl as getTriggerRuleDetailPageUrl } from 'src/page/organization/triggerRule/utils/navigation';
import { getDetailPageUrl } from 'src/page/organization/triggerAction/utils/navigation';
import { DetailLayoutInfoBlock } from 'src/layout/detail';
import lookup from 'src/dict';

interface DetailBaseInfoProps {
  detail: any;
  baseMenu?: DetailLayoutMenuItem[];
}

export const DetailBaseInfo = ({ detail, baseMenu }: DetailBaseInfoProps) => {
  const history = useHistory();

  const source = 2;
  // 节点col
  const column = [
    {
      title: '编号',
      dataIndex: 'code',
      width: 150,
      render: (code: string) => code ?? replaceSign,
    },
    {
      title: '名称',
      dataIndex: 'name',
      width: 150,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '审批人添加方式',
      dataIndex: 'approverType',
      width: 150,
      render: (approverType: string) =>
        lookup('processManagement.approvalDesignationObjectOptions', approverType) ?? replaceSign,
    },
    {
      title: '审批人/部门/角色',
      dataIndex: 'approverList',
      width: 200,
      render: (approverList: any[], { approverType }: any) => (
        <TagTooltip
          list={approverList}
          isUser={approverType === ApprovalDesignationObjectEnum.user}
          max={5}
          type={approverType === ApprovalDesignationObjectEnum.role ? 'role' : undefined}
        />
      ),
    },
    {
      title: '审批方式',
      dataIndex: 'approveMethod',
      width: 200,
      render: (approveMethod: string) =>
        lookup('processManagement.approvalDesignationMethodOptions', approveMethod) ?? replaceSign,
    },
  ];

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      { label: '编号', dataIndex: 'code' },
      { label: '名称', dataIndex: 'name' },
      {
        label: '类型',
        dataIndex: 'type',
        render: (type: number) => lookup('processManagement.typeOptions', type),
      },
      { label: '审批对象', dataIndex: 'approvalObjectName' },
      {
        label: '触发规则编号',
        dataIndex: 'triggerRuleCode',
        render: (triggerRuleCode: string) => {
          return (
            <a
              onClick={() => {
                if (hasAuth(authDict.triggerrules_detail)) {
                  history.push(getTriggerRuleDetailPageUrl(detail?.triggerRuleId, source));
                } else {
                  message.info('您无查看权限');
                }
              }}
            >
              <Tooltip text={triggerRuleCode ?? replaceSign} width={150} />
            </a>
          );
        },
      },
      {
        label: '触发事件编号',
        dataIndex: 'triggerActionCode',
        render: (triggerActionCode: string) => {
          return (
            <a
              onClick={() => {
                if (hasAuth(authDict.triggeraction_detail)) {
                  history.push(getDetailPageUrl(`${source}`, detail?.triggerActionId));
                } else {
                  message.info('您无查看权限');
                }
              }}
            >
              <Tooltip text={triggerActionCode ?? replaceSign} width={150} />
            </a>
          );
        },
      },
    ],
  };

  const statusInfo: DetailLayoutInfoBlock = {
    title: '状态信息',
    items: [
      {
        label: '启用状态',
        dataIndex: 'status',
        render: (status: number) => {
          const useStatusMap = new Map([
            [UsageStatus.enabled, <Badge text="启用中" status="success" />],
            [UsageStatus.disabled, <Badge text="停用中" status="error" />],
          ]);

          return useStatusMap.get(status);
        },
      },
    ],
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: CreatorData) => {
          return (
            <Space>
              <AvatarDisplay
                id={creator?.id}
                name={creator?.name}
                avatarUrl={creator?.avatarUrl}
                key={creator?.id}
                isShowTag={false}
                isUser
              />
            </Space>
          );
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: (createdAt: Date) => {
          if (!createdAt) return replaceSign;
          return _Time.format(createdAt);
        },
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: CreatorData) => {
          return (
            <Space>
              <AvatarDisplay
                id={operator?.id}
                name={operator?.name}
                avatarUrl={operator?.avatarUrl}
                key={operator?.id}
                isShowTag={false}
                isUser
              />
            </Space>
          );
        },
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: (updatedAt: Date) => {
          if (!updatedAt) return replaceSign;
          return _Time.format(updatedAt);
        },
      },
    ],
  };

  const nodeInfo: DetailLayoutInfoBlock = {
    title: '流程节点',
    items: [
      {
        label: '',
        dataIndex: 'nodeList',
        render: () => {
          return (
            <div
              style={{
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <BlTable
                columns={column}
                scroll={{ x: 1200, y: 800 }}
                dataSource={detail?.nodeList}
                style={{ width: '100%' }}
                rowKey={(record) => record?.id!}
              />
            </div>
          );
        },
      },
    ],
  };

  return (
    <DetailLayout
      userAuth={getAuthFromLocalStorage()}
      title="审批流程方案详情"
      info={[baseInfo, statusInfo, otherInfo, nodeInfo]}
      dataSource={detail}
      baseMenu={baseMenu}
    />
  );
};
