import React from 'react';
import { DetailLayout, DetailLayoutMenuItem } from 'layout';
import { Badge, Space } from 'antd';
import { replaceSign } from 'src/utils/constants';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { CreatorData } from 'src/page/knowledgeManagement/calendarManagement/shift/interface';
import { TagList } from 'src/components';
import { map } from 'lodash';
import _Time from 'src/utils/dataTypes/time';
import { UsageStatus } from 'src/dict/common';
import { ALLOW_REJECTION_TYPE, NOTICE_TYPE, REMIND_TYPE } from '../constant';
import _Array from 'src/utils/dataTypes/array';
import _String from 'src/utils/dataTypes/string';
import { getAuthFromLocalStorage } from 'src/utils/auth';

interface DetailBaseInfoProps {
  detail: any;
  baseMenu?: DetailLayoutMenuItem[];
}

export const DetailBaseInfo = ({ detail, baseMenu }: DetailBaseInfoProps) => {
  const baseInfo = [
    {
      title: '基本信息',
      items: [
        { label: '通知模板编号', dataIndex: 'code' },
        { label: '通知模板名称', dataIndex: 'name' },
        {
          label: '启用状态',
          dataIndex: 'status',
          width: 200,
          render: (subTemplateStatus: number) => {
            const useStatusMap = new Map([
              [UsageStatus.enabled, <Badge text="启用中" status="success" />],
              [UsageStatus.disabled, <Badge text="停用中" status="error" />],
            ]);

            return useStatusMap.get(subTemplateStatus);
          },
        },
        {
          label: '备注',
          dataIndex: 'remark',
          render: (remark: string) => (_String.isEmpty(remark) ? replaceSign : remark),
        },
      ],
    },
    {
      title: '通知信息',
      items: [
        { label: '业务对象', dataIndex: 'bizObjectName' },
        // { label: '业务类型', dataIndex: 'bizType' },
        { label: '通知标题', dataIndex: 'messageTitle' },
        { label: '通知内容', dataIndex: 'messageContent' },
        {
          label: '接收人',
          dataIndex: 'messageReceivers',
          render: (messageReceivers: any[]) => {
            if (_Array.isEmpty(messageReceivers)) return replaceSign;

            const _messageReceivers = map(messageReceivers, (e: any) => {
              return {
                value: e?.receiverId,
                label: e?.receiverName,
              };
            });

            return <TagList dataSource={_messageReceivers} maxShow={3} />;
          },
        },
        {
          label: '提醒形式',
          dataIndex: 'remindMethod',
          render: (remindMethod: number[]) => {
            if (_Array.isEmpty(remindMethod)) return replaceSign;
            return (
              <span>{remindMethod?.map((node: number) => REMIND_TYPE.get(node)).join('、')}</span>
            );
          },
        },
        {
          label: '通知订阅',
          dataIndex: 'messageSubscription',
          render: (messageSubscription: number[]) => {
            if (_Array.isEmpty(messageSubscription)) return replaceSign;
            return (
              <span>
                {messageSubscription?.map((node: number) => NOTICE_TYPE.get(node)).join('、')}
              </span>
            );
          },
        },
        {
          label: '拒收设置',
          dataIndex: 'allowRejection',
          render: (allowRejection: number) => ALLOW_REJECTION_TYPE.get(allowRejection),
        },
        {
          label: '触发规则',
          dataIndex: 'rules',
          render: (rules: any[]) => {
            if (_Array.isEmpty(rules)) return replaceSign;

            const _rules = map(rules, (e: any) => {
              return {
                value: e?.triggerRuleId,
                label: e?.triggerRuleName ?? replaceSign,
              };
            });

            return <TagList dataSource={_rules} maxShow={3} />;
          },
        },
      ],
    },
    {
      title: '其他信息',
      items: [
        {
          label: '创建人',
          dataIndex: 'creator',
          render: (creator: CreatorData) => {
            return (
              <Space>
                <AvatarDisplay
                  id={creator?.id}
                  name={creator?.name}
                  avatarUrl={creator?.avatarUrl}
                  key={creator?.id}
                  isShowTag={false}
                  isUser
                />
              </Space>
            );
          },
        },
        {
          label: '创建时间',
          dataIndex: 'createdAt',
          isFullLine: true,
          render: (createdAt: Date) => {
            if (!createdAt) return replaceSign;
            return _Time.format(createdAt);
          },
        },
        {
          label: '更新人',
          dataIndex: 'operator',
          render: (operator: CreatorData) => {
            return (
              <Space>
                <AvatarDisplay
                  id={operator?.id}
                  name={operator?.name}
                  avatarUrl={operator?.avatarUrl}
                  key={operator?.id}
                  isShowTag={false}
                  isUser
                />
              </Space>
            );
          },
        },
        {
          label: '更新时间',
          dataIndex: 'updatedAt',
          render: (updatedAt: Date) => {
            if (!updatedAt) return replaceSign;
            return _Time.format(updatedAt);
          },
        },
      ],
    },
  ];

  return (
    <DetailLayout
      userAuth={getAuthFromLocalStorage()}
      title="通知模板详情"
      info={baseInfo}
      dataSource={detail}
      baseMenu={baseMenu}
    />
  );
};
