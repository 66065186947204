import React from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import PositionDetailInfo from '../components/positionDetailBaseInfo';

interface Props extends RouteComponentProps {
  match: match<{ id: string }>;
}

const PositionDetail = (props: Props) => {
  const { match } = props;
  const positionId = Number(match?.params?.id);

  return <PositionDetailInfo positionId={positionId} type="page" />;
};

export default PositionDetail;
