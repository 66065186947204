import { useState } from 'react';
import _, { isEmpty } from 'lodash';
import { BlTable } from '@blacklake-web/component';
import { Button, message } from 'antd';
import { ColumnProps } from 'antd/es/table';
import lookup from 'src/dict';
import { getMaterialAttrs } from 'src/entity/material';
import { DetailLayoutForModal } from 'src/layout';
import _Time from 'src/utils/dataTypes/time';
import { WorkOrderResponseInputMaterial } from '../createAndEdit/index.type';
import FeedingControlDetail from './feedingControlDetail';
import { getDemandAmount, getStandardAmount } from '../utils';
import { TagList } from 'src/components';
import AlternativeMaterialsDetail from 'src/page/productionPlanning/productionOrder/detail/alternativeMaterialsDetail';
import Big from 'big.js';
import QueryReservationDetail from 'src/page/planned/reserve/queryReservation/detail';
import { ReservedOrderType } from 'src/page/planned/planningOperation/constants';
import { fetchReservedCommonData } from 'src/page/planned/reserve/utils';
import { BusinessStatusEnum } from 'src/dict/productionPlanning';
import { replaceSign } from 'src/utils/constants';
import authDict, { hasAuth } from 'src/utils/auth';

interface SubItemMaterialTabProps {
  dataSource?: any;
}

const SubItemMaterialTab = (props: SubItemMaterialTabProps) => {
  const { dataSource } = props;

  const [feedingControlVisiableData, setFeedingControlVisiableData] =
    useState<WorkOrderResponseInputMaterial>();

  const [alternativeMaterialsData, setAlternativeMaterialsData] =
    useState<WorkOrderResponseInputMaterial>();

  const [queryReservation, setQueryReservation] = useState<any>(false);

  const getColumns = (): ColumnProps<WorkOrderResponseInputMaterial>[] => {
    const mainPlannedAmount = _.get(dataSource, [
      'bomOutputMaterials',
      0,
      'plannedAmount',
      'amount',
    ]);

    const baseColumns: ColumnProps<WorkOrderResponseInputMaterial>[] = [
      {
        title: '项次',
        dataIndex: 'seq',
        fixed: 'left',
        width: 100,
      },
      {
        title: '物料编号',
        dataIndex: ['material', 'baseInfo', 'code'],
        fixed: 'left',
        width: 200,
      },
      {
        title: '物料名称',
        dataIndex: ['material', 'baseInfo', 'name'],
        width: 150,
      },
      {
        title: '物料属性',
        dataIndex: 'attributes',
        width: 150,
        render: (text, record: any) => <TagList dataSource={getMaterialAttrs(record.material)} />,
      },
      {
        title: '物料分类',
        dataIndex: ['material', 'category', 'name'],
        width: 150,
      },
      {
        title: '物料规格',
        dataIndex: ['material', 'baseInfo', 'specification'],
        width: 150,
      },
      {
        title: '用量:分子',
        dataIndex: 'inputAmountNumerator',
        width: 150,
      },
      {
        title: '用量:分母',
        dataIndex: 'inputAmountDenominator',
        width: 150,
      },
      {
        title: '标准用量',
        dataIndex: 'standardAmount',
        width: 150,
        render: (text, record) => {
          const useRatio = Number(record?.useRatio) ?? 0;

          const plannedAmount = mainPlannedAmount ?? 0;

          const mainPlann = new Big(plannedAmount).times(new Big(useRatio)).div(new Big('100'));

          return getStandardAmount({
            mainPlannedAmount: Number(mainPlann),
            inputAmountNumerator: _.toNumber(record?.inputAmountNumerator),
            inputAmountDenominator: _.toNumber(record?.inputAmountDenominator),
          });
        },
      },
      {
        title: '需求数',
        dataIndex: 'demandAmount',
        width: 150,
        render: (text, record) => {
          const useRatio = Number(record?.useRatio) ?? 0;

          const plannedAmount = mainPlannedAmount ?? 0;

          const mainPlann = new Big(plannedAmount).times(new Big(useRatio)).div(new Big('100'));

          return getDemandAmount({
            mainPlannedAmount: Number(mainPlann),
            inputAmountNumerator: _.toNumber(record?.inputAmountNumerator),
            inputAmountDenominator: _.toNumber(record?.inputAmountDenominator),
            lossRate: _.toNumber(record?.lossRate),
          });
        },
      },
      {
        title: '单位',
        dataIndex: 'unitName',
        width: 150,
      },
      {
        title: '损耗率%',
        dataIndex: 'lossRate',
        width: 150,
      },
      {
        title: '版本号',
        dataIndex: 'version',
        width: 200,
      },
      {
        title: '需求时间',
        dataIndex: 'requirementTime',
        width: 200,
        render: (text: any) => (text ? _Time.format(text) : undefined),
      },
      {
        title: '领料方式',
        dataIndex: 'pickMode',
        width: 150,
        render: (text: any) => lookup('bom.pickMode', text),
      },
      {
        title: '指定供应商',
        dataIndex: 'supplierList',
        width: 200,
        render: (text: any) => _.map(text, 'name').join(','),
      },
      {
        title: '申请领料数',
        dataIndex: ['requestPickAmount', 'amountDisplay'],
        width: 150,
      },
      {
        title: '领料发料数',
        dataIndex: ['pickOrderIssuedAmount', 'amountDisplay'],
        width: 150,
      },
      {
        title: '领料收料数',
        dataIndex: ['receivePickAmount', 'amountDisplay'],
        width: 150,
      },
      {
        title: '实际投料数',
        dataIndex: ['inputAmount', 'amountDisplay'],
        width: 150,
      },
      {
        title: '指定投料工序',
        dataIndex: 'specificProcessInput',
        width: 150,
        render: (text: any) => lookup('yn', text),
      },
      {
        title: '投料工序号',
        dataIndex: ['inputProcessNum'],
        width: 150,
      },
      {
        title: '投料管控',
        dataIndex: 'bomFeedingControls',
        width: 100,
        render: (text, record) => {
          const { bomFeedingControls } = record;

          return !isEmpty(bomFeedingControls) ? (
            <Button
              type="link"
              onClick={() => {
                setFeedingControlVisiableData(record);
              }}
              style={{ padding: 0 }}
            >
              查看
            </Button>
          ) : null;
        },
      },
      {
        title: '拆分控件投料',
        dataIndex: 'splitSopControlInput',
        width: 150,
        render: (text: any) => lookup('yn', text),
      },
      {
        title: '替代方案',
        dataIndex: 'workOrderAlternativePlan',
        width: 200,
        render: (text, record) => {
          const { workOrderAlternativePlan } = record;

          return !isEmpty(workOrderAlternativePlan) ? (
            <Button
              type="link"
              onClick={() => {
                setAlternativeMaterialsData(record);
              }}
              style={{ padding: 0 }}
            >
              查看
            </Button>
          ) : null;
        },
      },
      {
        title: '使用比例',
        dataIndex: 'useRatio',
        width: 150,
      },
      {
        title: '行备注',
        dataIndex: 'remark',
        width: 150,
      },
      {
        title: '操作',
        dataIndex: 'id',
        width: 100,
        fixed: 'right',
        render: (text, record) => {
          const { materialId, id } = record;

          if (
            dataSource?.workOrderBaseCO?.status === BusinessStatusEnum.CLOSED ||
            !hasAuth(authDict.PlanReserved_detail)
          ) {
            return replaceSign;
          }

          return (
            <a
              onClick={() => {
                fetchReservedCommonData({
                  lineId: id,
                  materialId,
                  orderId: dataSource?.workOrderBaseCO?.id,
                  type: ReservedOrderType.PRODUCT_ORDER,
                }).then((reservedCommonData) => {
                  if (!reservedCommonData) {
                    message.error('暂无预留');
                  }
                  setQueryReservation(reservedCommonData);
                });
              }}
            >
              查询预留
            </a>
          );
        },
      },
    ];

    return baseColumns;
  };

  return (
    <div style={{ padding: 24 }}>
      <BlTable<WorkOrderResponseInputMaterial>
        dataSource={dataSource?.bomInputMaterials}
        columns={getColumns()}
        scroll={{ x: 'max-content' }}
        expandable={{ childrenColumnName: 'bomInputMaterialLines' }}
        rowKey="id"
        // sticky
        resizableCol
        useColConfig
        tableConfigKey={'workOrderSubItemMaterialTab'}
      />
      <DetailLayoutForModal
        visible={!isEmpty(feedingControlVisiableData)}
        onClose={() => {
          setFeedingControlVisiableData(undefined);
        }}
        content={<FeedingControlDetail dataSource={feedingControlVisiableData} />}
      />
      <DetailLayoutForModal
        visible={!isEmpty(alternativeMaterialsData)}
        onClose={() => {
          setAlternativeMaterialsData(undefined);
        }}
        content={<AlternativeMaterialsDetail dataSource={alternativeMaterialsData} />}
      />
      <DetailLayoutForModal
        visible={queryReservation}
        onClose={() => {
          setQueryReservation(false);
        }}
        content={
          <QueryReservationDetail
            dataSource={queryReservation}
            onChange={() => {
              setQueryReservation(false);
            }}
          />
        }
      />
    </div>
  );
};

export default SubItemMaterialTab;
