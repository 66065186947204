import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Space, Dropdown, Menu, Form, message, Button } from 'antd';
import { RecordListLayout } from 'src/layout';
import CreateAndEdit from './createAndEdit/createAndEdit';
import {
  ACTION_CREATE,
  ACTION_CREATE_CHIRLD,
  ACTION_EDIT,
  CATEGORYTYPE_SYSTEMPRESET,
} from './constant';
import {
  fetchMaterialCategoryList,
  fetchMaterialCategoryDelete,
  FetchMaterialCategoryListResponse,
} from 'src/api/ytt/material-domain/Web_MaterialCategory';
import { BlIcon, SingleOperationLogComponent, Tooltip } from 'components';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import { replaceSign } from 'src/utils/constants';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import authDict from 'src/utils/auth';
import lookup from 'src/dict';
import { CRUD } from 'src/dict/common';

interface TableRowType {
  code: string;
  count: number;
  id: number;
  isLeaf: number;
  level: number;
  name: string;
  parent: TableRowType[];
  remark: string;
  type: number;
  children: TableRowType[];
}
const MaterialCategoryList = () => {
  const [createAndeditVisible, setCreateAndeditVisible] = useState(false);
  const [actionType, setActionType] = useState(ACTION_CREATE);
  const [arrtId, setArrtId] = useState<number | undefined>(undefined);
  const [arrtRecord, setArrtRecord] = useState<FetchMaterialCategoryListResponse>();
  const [selectedRowKeys] = useState<(number | string)[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [, setRefreshDetailsMarker] = useState<number | undefined>(undefined);
  const [delForm] = Form.useForm();

  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);

  const handleFinish = async (id?: number | any[]) => {
    try {
      const value = await delForm?.validateFields();
      let ids;

      if (id) {
        // 操作列的删除
        ids = typeof id === 'number' ? [id] : id;
      } else {
        // 按钮的删除
        ids = selectedRowKeys;
      }
      const { code, message: mes } = await fetchMaterialCategoryDelete({
        ids,
        reason: value.delReason,
      });

      if (code === 200) {
        message.success('删除成功');
        // 刷新list
        setRefreshMarker(new Date().getTime());
        return;
      }
      message.error(mes);
    } catch (error) {
      console.log(error);
    }
  };
  const dataColumns = [
    {
      title: '分类编号',
      dataIndex: 'code',
      type: 'text',
      isFilter: true,
      sorter: true,
      width: 150,
    },
    {
      title: '分类名称',
      dataIndex: 'name',
      type: 'text',
      isFilter: true,
      sorter: true,
      minWidth: 150,
      fixed: 'left',
      render: (text: string, record: TableRowType, index: number, config: any) => {
        if (record.type === CATEGORYTYPE_SYSTEMPRESET) {
          return (
            <span>
              <Tooltip text={text ?? replaceSign} width={config.contentWidth} />
              <Tooltip placement="topLeft" text={'系统预置分类，不可编辑和删除'}>
                <BlIcon type="iconwentizhiyinbangzhu" style={{ marginLeft: '10px' }} />
              </Tooltip>
            </span>
          );
        }
        return <Tooltip text={text ?? replaceSign} width={config.contentWidth} />;
      },
    },
    {
      title: '分类层级',
      dataIndex: 'level',
      width: 150,
    },
    {
      title: '分类物料数',
      dataIndex: 'count',
      width: 150,
    },
  ];
  const config = {
    mainMenu: [
      {
        title: '导出',
        showExport: true,
        auth: authDict.materialcategory_export,
        icon: <BlIcon type="icondaochu" />,
        onClick: () => {
          openModal({
            optType: 'export',
            businessType: BUSINESS_TYPE.materialType,
          });
        },
      },
      {
        title: '新建分类',
        auth: authDict.materialcategory_add,
        icon: <BlIcon type="iconxinjiantianjia" />,
        onClick: () => {
          setArrtId(undefined);
          setActionType(ACTION_CREATE);
          setCreateAndeditVisible(true);
        },
        items: [
          {
            title: '导入',
            showExport: true,
            auth: authDict.materialcategory_import,
            onClick: () => {
              openModal({
                optType: 'import',
                businessType: BUSINESS_TYPE.materialType,
              });
            },
          },
        ],
      },
    ],
    batchMenu: [
      {
        title: '删除',
        onClick: (onSuccess: any, onFail: any) => {
          onFail();
          BLDelConfirm('是否删除当前分类及分类下所有子分类', delForm, () => {
            handleFinish();
          });
        },
      },
    ],
    getOperationList: (record: any) => {
      const { id, type, level } = record;

      if (type === CATEGORYTYPE_SYSTEMPRESET) {
        return;
      }
      const isLeaf = level >= 4;

      return [
        {
          title: '新增子分类',
          disabled: isLeaf,
          auth: authDict.materialcategory_add,
          onClick: () => {
            setArrtId(id);
            setArrtRecord(record);
            setActionType(ACTION_CREATE_CHIRLD);
            setCreateAndeditVisible(true);
          },
        },
        {
          title: lookup('crud', CRUD.edit),
          auth: authDict.materialcategory_edit,
          onClick: () => {
            setArrtId(id);
            setArrtRecord(record);
            setCreateAndeditVisible(true);
            setActionType(ACTION_EDIT);
          },
        },
        // {
        //   title: lookup('crud', CRUD.delete),
        //   onClick: () => {
        //     BLDelConfirm('是否删除当前属性项', delForm, () => {
        //       handleFinish(id);
        //     });
        //   },
        // },
        {
          title: '操作记录',
          auth: authDict.materialcategory_operrecord,
          onClick: () => {
            setArrtId(id);
            setVisibleLog(true);
          },
        },
      ];
    },
    columns: dataColumns.map((column) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isFilter, type, ...rest } = column;

      return { ...rest };
    }),
    rowKey: 'id',
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((column) => {
        const filter = {
          label: column.title,
          name: column.dataIndex,
          type: column.type,
        } as any;

        return filter;
      }),
  };
  const formatDataToQuery = (params: any) => {
    dispatch.excelImport.updateBusinessData({ businessData: params });
    return params;
  };
  const formatDataToDisplay = (params: any = {}) => {
    const retData = { ...params };

    return retData;
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        useIndex={false}
        columns={config.columns}
        filterList={config.filterList}
        rowKey={config.rowKey}
        // batchMenu={config.batchMenu}
        mainMenu={config.mainMenu}
        getOperationList={config.getOperationList}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={formatDataToDisplay}
        requestFn={fetchMaterialCategoryList}
        refreshMarker={refreshMarker}
        configcacheKey={'materialCategory'}
      />
      {createAndeditVisible && (
        <CreateAndEdit
          id={arrtId}
          record={arrtRecord}
          type={actionType}
          visible={createAndeditVisible}
          onCancel={() => setCreateAndeditVisible(false)}
          refreshTable={() => {
            setRefreshMarker(new Date().getTime());
          }}
          refreshDetails={() => {
            setRefreshDetailsMarker(new Date().getTime());
          }}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={arrtId as number}
          objectCode={OBJECT_OF_CODE.materialCategory}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(MaterialCategoryList);
