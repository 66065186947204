import React, { ReactNode, useContext, useEffect } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Form, Input, Checkbox, Select, FormInstance, Popover } from 'antd';
import { QUALITY, STATUS_ENUM, StoreContext } from '../constant';
import { PositionDetailData } from '../interface';
import { QrCode, BlIcon, NumberRulesStandardHook } from 'components';
import { textValidator1, textValidator2 } from 'src/utils/formValidators';

const { Option } = Select;

const { TextArea } = Input;

interface baseInfoProps {
  onCancel: () => void;
  onFinish: () => void;
  title: string;
  initialData?: PositionDetailData;
  form: FormInstance;
  extra?: ReactNode;
  edit: boolean;
  qcCtrl?: boolean;
  setQcCtrlFn?: (e: boolean) => void;
  refreshMarker?: number;
  storeQcCtrl?: number; // 仓库的状态管理开启状态
  storeCtrlList?: number[]; // 仓库的状态管理状态
}

const PositionBaseInfo = (props: baseInfoProps) => {
  const {
    onCancel,
    title,
    onFinish,
    initialData,
    form,
    extra,
    edit,
    setQcCtrlFn,
    qcCtrl,
    refreshMarker,
    storeQcCtrl,
    storeCtrlList,
  } = props;

  const options = QUALITY.map(({ name, id }) => <Option value={id}>{name}</Option>);

  const { parentAreaSource, storeHouseSource } = useContext(StoreContext);

  const { parentAreaName, parentAreaCode } = parentAreaSource;
  const { StoreHouseName, StoreHouseCode } = storeHouseSource;

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    items: [
      { label: '仓库名称', render: () => (edit ? initialData?.warehouseName : StoreHouseName) },
      { label: '仓库编号', render: () => (edit ? initialData?.warehouseCode : StoreHouseCode) },
      { label: '上级区域名称', render: () => (edit ? initialData?.parentName : parentAreaName) },
      { label: '上级区域编号', render: () => (edit ? initialData?.parentCode : parentAreaCode) },
      {
        label: '仓位名称',
        name: 'name',
        rules: [
          { required: true, message: '仓位名称不能为空' },
          { validator: textValidator1 },
          { max: 255, message: '不超过255个字符' },
        ],
        render: () => <Input placeholder="请输入" />,
      },
      ...NumberRulesStandardHook({
        label: '仓位编号',
        form,
        refreshMarker,
        edit,
        objectCode: 'StorageLocation',
        fieldCode: 'code',
        rules: [{ max: 255, message: '不超过255字符' }, { validator: textValidator2 }],
      }),
      {
        label: '标识码',
        name: 'qrCode',
        hidden: !edit,
        rules: [{ validator: textValidator1 }, { max: 256, message: '不超过256个字符' }],
        render: () => (
          <Input
            suffix={
              <Popover
                placement="rightTop"
                content={
                  <div>
                    <QrCode value={initialData?.qrCode ?? initialData?.code ?? ''} />
                    <div style={{ textAlign: 'center' }}>
                      {initialData?.qrCode ?? initialData?.code}
                    </div>
                  </div>
                }
              >
                <BlIcon type="iconerweima" />
              </Popover>
            }
          />
        ),
      },
      {
        label: '质量管理',
        render: () => (
          <Form.Item name="enableQcCtrl" valuePropName="checked" style={{ marginBottom: 0 }}>
            <Checkbox
              value={STATUS_ENUM?.ENABLED}
              disabled={edit || !storeQcCtrl}
              onChange={(e) => {
                typeof setQcCtrlFn === 'function' && setQcCtrlFn(e?.target?.checked);
              }}
            >
              启用质量管理
            </Checkbox>
          </Form.Item>
        ),
      },
      {
        label: '质量范围',
        name: 'qcCtrlList',
        rules: [{ required: edit ? Boolean(storeQcCtrl) : qcCtrl, message: '质量范围不可为空' }],
        hidden: edit ? !storeQcCtrl : !qcCtrl,
        render: () => <Select mode="multiple">{options}</Select>,
      },
      {
        label: '编辑原因',
        name: 'operateReason',
        hidden: !edit,
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
    ],
  };

  useEffect(() => {
    if (initialData) {
      form?.setFieldsValue({
        name: initialData?.name,
        code: initialData?.code,
        qcCtrlList: initialData?.qcCtrlList,
        enableQcCtrl: Boolean(initialData?.enableQcCtrl),
        qrCode: initialData?.qrCode ?? initialData?.code,
      });
    } else {
      form?.setFieldsValue({
        qcCtrlList: storeCtrlList, // 设置初值状态为仓库的选中状态管理
      });
    }
  }, [initialData, storeCtrlList]);

  return (
    <DataFormLayout
      form={form}
      title={title}
      info={[baseInfo]}
      onCancel={onCancel}
      onFinish={onFinish}
      extra={extra}
    />
  );
};

export default PositionBaseInfo;
