import React, { useEffect, useState } from 'react';
import { Badge, message } from 'antd';
import { RecordListLayout } from 'src/layout';
import { fieldTypeList, gcArray, gcObject } from 'src/utils';
import { AreaDetailDrawer } from './detail';
import Action from './action';
import { STATUS_VALUE, STATUS_TYPE } from './constants';
import _ from 'lodash';
import {
  fetchLocationGetListByParent,
  fetchLocationGetList,
  fetchLocationDeactivate,
  fetchLocationActivate,
  fetchLocationActivateBatch,
  fetchLocationDeactivateBatch,
} from 'src/api/ytt/resource-domain/areaConfig';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { TableRowType } from '.';
import { BlIcon, LinkTooltip, SingleOperationLogComponent } from 'src/components';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { useHistory } from 'react-router-dom';
import { replaceSign } from 'src/utils/constants';
interface UserColumnProps {
  id: number;
  name: string;
  avatarUrl: string;
}

const AreaConfigList = (props: { history: any }) => {
  const [detailVisible, setDetailVisible] = useState<boolean>(false);
  const [detailId, setDetailId] = useState<number>(0);
  const [actionType, setActionType] = useState<string>('');
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [actionVisible, setActionVisible] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [parentLevel, setParentLevel] = useState<any>({});
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  const history = useHistory();

  const LAST_LEVEL = 5;

  // const showResourceDetail = (id: number) => {
  //   setDetailId(id);
  //   setDetailVisible(true);
  // };
  const FIRST_LEVEL = 1;

  const formatChildren = (node: any) => {
    if (node.hasChildren) {
      node.children = [];
    } else {
      node.children = null;
    }
    return node;
  };

  const findTreeNode = (data: TableRowType[], id: number, nodeData: any[]) => {
    data?.find((node) => {
      if (node.id === id) {
        node.hasLoadedData = true;
        if (nodeData && nodeData.length) {
          node.children = nodeData.map((_node) => {
            return { ...formatChildren(_node), parentName: node.name };
          });
        } else {
          node.children = null;
        }
        return true;
      }
      if (node.children && node.children.length) {
        findTreeNode(node.children, id, nodeData);
      }
      return false;
    });
  };

  const fetchTableData = async (level = FIRST_LEVEL, parentId = 0) => {
    const { data } = await fetchLocationGetListByParent({ parentId });

    if (level === FIRST_LEVEL) {
      setDataSource(data?.map((item) => formatChildren(item)) || []);
    } else {
      findTreeNode(dataSource, parentId, data || []);
      setDataSource(_.cloneDeep(dataSource));
    }
  };

  const refreshData = () => {
    setRefreshMarker(Math.random());
    fetchTableData().then(() => {
      setExpandedRowKeys([]);
    });
  };

  const formatSearchData = (data: TableRowType, expandedRowKeys: number[]) => {
    if (!data.hasChildren) {
      data.children = null;
    }
    data.hasLoadedData = true;
    if (data?.children && data?.children.length) {
      const childrenList = data.children;

      childrenList.forEach((_item) => {
        formatSearchData(_item, expandedRowKeys);
      });
      if (!expandedRowKeys.includes(data.id)) {
        expandedRowKeys.unshift(data.id);
      }
    }
    return expandedRowKeys;
  };

  const searchData = async (params: any) => {
    const sendData = _.cloneDeep(params);

    if (sendData) {
      delete sendData.page;
      delete sendData.size;
    }
    if (!gcObject.isEmpty(gcObject.filterEmptyProperty(sendData))) {
      const { data } = await fetchLocationGetList({ ...sendData });
      const expandedKeys = formatSearchData(data as TableRowType, []);

      setDataSource([data]);
      setExpandedRowKeys(expandedKeys);
      return { data: { list: [data] } };
    }
    refreshData();
  };

  useEffect(() => {
    const hasSearch = props.history.location.search.substring(1).split('&').length > 1;

    if (!hasSearch) {
      refreshData();
    }
  }, []);

  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      auth: authDict.location_export,
      icon: <BlIcon type="icondaochu" />,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.location,
        });
      },
    },
    {
      title: '导入',
      showExport: true,
      auth: authDict.location_import,
      icon: <BlIcon type="icondaoru" />,
      onClick: () => {
        openModal({
          optType: 'import',
          businessType: BUSINESS_TYPE.location,
        });
      },
    },
  ];

  const fetchChildrenNode = async (currentNodeLevel = 1, id = 0) => {
    setIsLoading(true);
    fetchTableData(currentNodeLevel + 1, id).then(() => {
      setIsLoading(false);
    });
  };

  const batchMenu = [
    {
      title: '启用',
      key: 'enable',
      onClick: () => fetchLocationActivateBatch(selectedRows),
      auth: authDict.location_enable_disable,
    },
    {
      title: '停用',
      key: 'disable',
      onClick: () => fetchLocationDeactivateBatch(selectedRows),
      auth: authDict.location_enable_disable,
    },
    // {
    //   title: '删除',
    //   key: 'delete',
    //   onClick: (onSuccess: any, onFail: any) => {
    //     fetchLocationDeleteBatch(selectedRows);
    //     BLDelConfirm(
    //       '删除后将无法恢复，确认删除？',
    //       delForm,
    //       () => {
    //         fetchLocationDeleteBatch(selectedRows).then(() => {
    //           refreshData();
    //         });
    //       },
    //       onFail(),
    //     );
    //   },
    // },
  ];

  const findParentStatus = (parentId?: number) => {
    if (_.isNil(parentId)) return;
    const findNode = (currentLevelNodes: any[] | undefined): any => {
      const targetNode = currentLevelNodes?.find((node) => node.id === parentId);

      if (targetNode) return targetNode;
      return currentLevelNodes?.reduce((prev, curr) => prev || findNode(curr.children), undefined);
    };

    return findNode(dataSource)?.status;
  };

  const operateList = (recordData: TableRowType) => {
    const { id, code, name, status, level, parentId } = recordData;
    const statusOperate = status === STATUS_VALUE.ENABLED ? '停用' : '启用';
    const parentStatus = findParentStatus(parentId);

    return [
      {
        title: '查看',
        auth: authDict.location_detail,
        onClick: () => history.push(`/resource/resourceConfig/area/detail?id=${id}`),
      },
      {
        title: '新建子区域',
        auth: authDict.location_add,
        disabled: status === STATUS_VALUE.DISABLED || level === LAST_LEVEL,
        onClick: () => {
          setDetailId(id);
          setActionVisible(true);
          setActionType('create');
          setParentLevel(recordData);
        },
      },
      {
        title: '编辑',
        auth: authDict.location_edit,
        disabled: level === FIRST_LEVEL,
        onClick: () => {
          setActionVisible(true);
          setActionType('edit');
          setDetailId(id);
        },
      },
      {
        title: statusOperate,
        auth: authDict.location_enable_disable,
        disabled: level === FIRST_LEVEL || parentStatus === STATUS_VALUE.DISABLED,
        onClick: async () => {
          let res = null;

          if (status === STATUS_VALUE.ENABLED) {
            res = await fetchLocationDeactivate({ id, code, name });
          } else if (status === STATUS_VALUE.DISABLED) {
            res = await fetchLocationActivate({ id, code, name });
          }
          if (res?.code === 200) {
            message.success(`${statusOperate}成功`);
            // fetchTableData(level, parentId);
            refreshData();
          }
        },
      },
      {
        title: '查看用户操作记录',
        auth: authDict.location_operrecord,
        disabled: level === FIRST_LEVEL,
        onClick: () => {
          setDetailId(id);
          setVisibleLog(true);
        },
      },
    ];
  };

  const linkToDetailRender = (
    display: string,
    record: { id: number },
    index: number,
    config: any,
  ) => (
    <LinkTooltip
      auth={authDict.location_detail}
      text={display ?? replaceSign}
      to={`/resource/resourceConfig/area/detail?id=${record?.id}`}
      width={config.contentWidth}
    />
  );

  const dataColumns = [
    {
      title: '区域名称',
      dataIndex: 'name',
      type: fieldTypeList.text,
      isFilter: true,
      width: 250,
      fixed: 'left',
      render: linkToDetailRender,
    },
    {
      title: '区域编号',
      dataIndex: 'code',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
      render: linkToDetailRender,
    },
    {
      title: '区域层级',
      dataIndex: 'level',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
    },
    {
      title: '上级区域',
      dataIndex: 'parentName',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
    },
    {
      title: '负责人',
      dataIndex: 'manager',
      filterIndex: 'managers',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
      render: (manager: { id: string | number; name: string; avatarUrl: string }) => {
        return manager?.id ? (
          <AvatarDisplay
            id={manager.id}
            name={manager.name}
            avatarUrl={manager.avatarUrl}
            isUser
            isShowTag
          />
        ) : (
          '-'
        );
      },
    },
    {
      title: '标识码',
      dataIndex: 'identifier',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
    },
    {
      title: '启用状态',
      dataIndex: 'status',
      type: fieldTypeList.select,
      isFilter: false,
      width: 150,
      render: (status: number) => (
        <span>
          <Badge status={status === STATUS_VALUE.ENABLED ? 'success' : 'error'} />
          {STATUS_TYPE.get(status)}
        </span>
      ),
    },
    {
      title: '备注',
      dataIndex: 'remark',
      type: fieldTypeList.textArea,
      isFilter: true,

      width: 150,
    },
  ];

  const onExpand = (expanded: boolean, record: TableRowType) => {
    if (expanded && !record.hasLoadedData) {
      fetchChildrenNode(record?.level, record?.id);
    }
  };

  const filterList = dataColumns
    .filter((i) => i.isFilter)
    .map((column: any) => {
      const filter: any = {
        label: column.title,
        name: column.filterIndex || column.dataIndex,
        type: column.type,
        rules: column.rules,
        renderItem: column.renderItem,
      };

      if (column.type === fieldTypeList.select || column.type === fieldTypeList.multiSelect) {
        filter.selectProps = column.selectProps;
      }
      return filter;
    });

  const formatDataToQuery = (data: any) => {
    const sendData = _.cloneDeep(data);

    if (!gcArray.isEmpty(sendData.sorter)) {
      sendData.orderBy = sendData.sorter?.[0].field;
      sendData.orderDirection = sendData.sorter?.[0].order;
    }
    if (!gcArray.isEmpty(sendData.managers)) {
      sendData.managers = sendData.managers.map(
        (item: { value: number; label: string }) => item.value,
      );
    } else {
      delete sendData.managers;
    }

    dispatch.excelImport.updateBusinessData({ businessData: sendData });
    return sendData;
  };

  const formatDataToDisplay = (data: any) => {
    const displayData = _.cloneDeep(data);

    if (!gcArray.isEmpty(displayData.managers)) {
      displayData.managers = displayData.managers.map(
        (item: { value: number; label: string }) => item.label,
      );
    } else {
      delete displayData.managers;
    }
    return displayData;
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        filterList={filterList}
        requestFn={searchData}
        mainMenu={mainMenu}
        batchMenu={batchMenu}
        placeholder={'请输入区域名称或编号'}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={formatDataToDisplay}
        columns={dataColumns}
        selectedRowKeys={selectedRowKeys}
        refreshMarker={refreshMarker}
        rowKey="id"
        onSelectedRowKeys={(selectKey, selectRows) => {
          setSelectedRowKeys(selectKey.map((key) => Number(key)));
          setSelectedRows(selectRows || []);
        }}
        isLoading={isLoading}
        customDataSource={dataSource}
        useIndex={false}
        configcacheKey={'location'}
        expandable={{
          onExpand,
          expandedRowKeys,
          expandRowByClick: false,
          onExpandedRowsChange: (expandedRows: number[]) => {
            setExpandedRowKeys(expandedRows);
          },
        }}
        pagination={false}
        getOperationList={operateList}
        userAuth={getAuthFromLocalStorage()}
      />
      {detailVisible && (
        <AreaDetailDrawer
          visible={detailVisible}
          onCloseDetail={() => setDetailVisible(false)}
          id={detailId}
          showEdit={() => {
            setActionType('edit');
            setActionVisible(true);
            setDetailId(detailId);
          }}
          refreshTable={refreshData}
          showLog={() => setVisibleLog(true)}
        />
      )}
      {actionVisible && (
        <Action
          visible={actionVisible}
          id={detailId}
          type={actionType}
          onCancel={() => setActionVisible(false)}
          parentLevel={parentLevel}
          refreshTable={refreshData}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={'Location'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default AreaConfigList;
