/**
 * SOP任务操作记录
 */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Table, DatePicker } from 'antd';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import {
  fetchSopExecTaskOperationLog,
  FetchSopExecTaskOperationLogResponse,
} from 'src/api/ytt/sop-domain';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';

const SopTaskOperationLog = () => {
  const { id } = useParams<{ id: string }>();
  const [dataSource, setDataSource] = useState<FetchSopExecTaskOperationLogResponse['data']>([]);
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null] | null>();

  const displayData =
    dateRange && !_.isEmpty(dateRange)
      ? dataSource!.filter((record) =>
          moment(record.createdAt).isBetween(
            dateRange[0]!.startOf('day'),
            dateRange[1]!.endOf('day'),
          ),
        )
      : dataSource;

  const dataColumns = [
    {
      title: 'SOP任务编号',
      dataIndex: 'code',
    },
    {
      title: '操作人',
      dataIndex: 'operatorName',
    },
    {
      title: '操作类型',
      dataIndex: 'operationType',
      render: (type: any) => type.message,
    },
    {
      title: '操作时间',
      dataIndex: 'createdAt',
      render: formatTimeForRender,
    },
  ];

  useEffect(() => {
    fetchSopExecTaskOperationLog({ sopTaskId: +id }, { legacy: false }).then((res) => {
      setDataSource(res.data);
    });
  }, []);

  return (
    <>
      <header style={{ marginBottom: 16 }}>
        <DatePicker.RangePicker value={dateRange} onChange={setDateRange} />
      </header>
      <Table columns={dataColumns} dataSource={displayData} rowKey={'id'} pagination={false} />
    </>
  );
};

export default SopTaskOperationLog;
