export const RESORCE_STATUS_VALUE = {
  SCRAP: 2,
  ENABLED: 0,
  DISABLED: 1,
};

export const RESORCE_STATUS_TYPE = new Map([
  [RESORCE_STATUS_VALUE.DISABLED, '停用中'],
  [RESORCE_STATUS_VALUE.ENABLED, '启用中'],
  [RESORCE_STATUS_VALUE.SCRAP, '已报废'],
]);

export const RESOURCE_BUSINESS_TYPE = {
  SINGLE_SELECT: 1,
  MULTIPLE_SELECT: 2,
  INPUT: 0,
};

export enum TypeEnum {
  product = 0,
  weighting,
  other,
}

export const RESORCE_BUSINESS_TYPE = {
  [TypeEnum.product]: '生产设备',
  [TypeEnum.weighting]: '称量设备',
  [TypeEnum.other]: '其他设备',
};

export const MAX_INPUT_LENGTH = 255;
export const MAX_TEXTAREA_LENGTH = 1000;
