export const BASE_URL = '/purchase/purchaseManagement/purchaseReturnOrder';

export const goToDetail = (id: number) => {
  return `${BASE_URL}/${id}/detail`;
};

export const goToEdit = (id: number) => {
  return `${BASE_URL}/${id}/edit`;
};

export const goToCreate = () => {
  return `${BASE_URL}/create`;
};

export const goToList = () => {
  return `${BASE_URL}`;
};

export const OutBoundCreatePath = () => {
  return `${BASE_URL}/createOutBound?source=purchaseReturnOrder`;
};
