import React from 'react';
import _ from 'lodash';
import { ColumnProps } from 'antd/es/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { getMaterialAttrs } from 'src/entity/material';
import { BlTable, TagList } from 'src/components';
//
import { BomResponse } from '../index.type';
import lookup from 'src/dict';
import { replaceSign } from 'src/utils/constants';

interface CoproductTabProps {
  dataSource?: BomResponse;
}

const CoproductTab = (props: CoproductTabProps) => {
  const { dataSource } = props;

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    const baseColumns: ColumnProps<any & FormListFieldData>[] = [
      {
        title: '行号',
        dataIndex: 'lineSeq',
        fixed: 'left',
        width: 120,
      },
      {
        title: '物料编号',
        dataIndex: ['material', 'baseInfo', 'code'],
        fixed: 'left',
        width: 200,
      },
      {
        title: '物料名称',
        dataIndex: ['material', 'baseInfo', 'name'],
        width: 150,
      },
      {
        title: '物料属性',
        dataIndex: 'attributes',
        width: 150,
        render: (text, record) => <TagList dataSource={getMaterialAttrs(record.material)} />,
      },
      {
        title: '物料分类',
        dataIndex: ['material', 'category', 'name'],
        width: 150,
      },
      {
        title: '物料规格',
        dataIndex: ['material', 'baseInfo', 'specification'],
        width: 150,
      },
      {
        title: '数量',
        dataIndex: 'amount',
        width: 150,
      },
      {
        title: '单位',
        dataIndex: 'unitName',
        width: 150,
      },
      {
        title: '报工工序号',
        dataIndex: ['outputProcessProcessRouteNodeSimpleVO', 'processNum'],
        width: 150,
      },
      {
        title: '报工控件编号',
        dataIndex: ['outputSopControlDTO', 'code'],
        width: 200,
      },
      {
        title: '单次报工数量',
        dataIndex: 'singleWorkReportAmount',
        width: 200,
      },
      {
        title: '报工方式',
        dataIndex: 'reportingMethods',
        width: 200,
        render: (text) => (
          <TagList
            dataSource={text?.map((item: any) => ({
              label: lookup('bom.reportingMethods', item),
              value: item,
            }))}
          />
        ),
      },
      {
        title: '是否入库',
        dataIndex: 'warehousing',
        width: 200,
        render: (text) => lookup('common.yn', text),
      },
      {
        title: '自动入库',
        dataIndex: 'autoWarehousingFlag',
        width: 200,
        render: (text) => lookup('common.yn', text) || replaceSign,
      },
    ];

    return baseColumns;
  };

  return (
    <div style={{ padding: 24 }}>
      <BlTable
        scroll={{ x: 'max-content' }}
        dataSource={dataSource?.bomOutputMaterials}
        columns={getColumns()}
        rowKey="id"
        resizableCol
        useColConfig
        tableConfigKey={'CoproductTab'}
      />
    </div>
  );
};

export default CoproductTab;
