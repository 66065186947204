/**
 * @file 数据字典工具方法
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/named
import { ValueType, Mappings, ExtendedValueType, ExtendedMappings } from './types';

export const mappingsFactory = <ML = string, MV = ValueType>(
  ...inputs: [ML, MV][]
): Mappings<ML, MV> => {
  return inputs.map(([label, value]) => ({ label, value }));
};

export const extendedMappingsFactory = (
  ...inputs: [string, ExtendedValueType][]
): ExtendedMappings => {
  return inputs.map(([label, value]) => ({ label, value }));
};

export const findByValue = (mappings: Mappings) => (value: ValueType) =>
  mappings.find((item) => value === item.value)!;
