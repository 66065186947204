export const PRODUCTION_WAY_TYPE_ENUM = new Map([
  [0, '系统默认'],
  [1, '编号规则'],
  [2, '自定义'],
]);

export const PRODUCTION_WAY_TYPE = {
  SYSTEM_DEFAULT: 0,
  NUMBER_RULE: 1,
  CUSTOMER: 2,
};

export enum CARRIER_TYPE_ENUM {
  /** 二维码 */
  QRCode = 1,
}

export enum CODE_SYSTEM_ENUM {
  /** QR Code */
  QRCode = 1,
}

export const CARRIER_TYPE = [{ id: CARRIER_TYPE_ENUM.QRCode, name: '二维码' }];

export const CODE_SYSTEM = [{ id: CODE_SYSTEM_ENUM.QRCode, name: 'QR Code' }];

export const SINGLE_PAGE_NUMBER_TYPE = {
  SINGLE: 0,
  MULTIPLE: 1,
};
