/**
 * 物料属性专用组件！！！！
 */

import React, { useEffect, useState } from 'react';
import { BlMultiCascader } from 'src/components';
import { fetchMaterialListAttribute } from 'src/api/ytt/material-domain/Web_MaterialBaseInfo';
import _ from 'lodash';
import { Tag } from 'antd';

interface Props {
  onChange?: (value: any) => void;
  value?: string[] | number[];
  placeholder?: string;
  nameLabel?: string;
}

/**
 * 统一格式化物料属性给接口
 * @param list
 * @returns
 */
export const formatMaterialAttributeToQuery = (list?: string[]) => {
  return _.isEmpty(list)
    ? undefined
    : _.map(list, (o: string | number) => {
        if (_.isString(o) && _.includes(o, '_')) {
          const item = o.split('_');

          return { id: _.toNumber(item[0]), valueId: _.toNumber(item[1]) };
        }

        return o;
      });
};

const MaterialAttributeCascader = (props: Props) => {
  const { onChange, value = [], placeholder = '请选择', nameLabel = '物料属性' } = props;
  const [optionsList, setOptionsList] = useState<any>([]);
  const [, setFetching] = useState(false);

  function handelOnChange(value: any, selectedOptions: any) {
    onChange && onChange(value);
  }
  const handleFormatData = (
    list: any[],
    fieldNames: { label: string; value: string; children: string },
    parentId?: number,
  ): any[] => {
    const data = list?.map((item: any) => {
      if (item?.leaf === 0 || !_.isEmpty(item[fieldNames.children])) {
        return {
          label: item[fieldNames.label],
          value: item[fieldNames.value],
          children: handleFormatData(item[fieldNames.children], fieldNames, item[fieldNames.value]),
        };
      }
      return {
        label: item.name,
        value: `${parentId}_${item.id}`,
      };
    });

    return data;
  };
  const handleFetchData = async (searchParams?: string) => {
    let selectOptionsList: any[] = [];
    let res: any;

    if (searchParams) {
      res = await fetchMaterialListAttribute({
        page: 1,
        size: 1000,
        quickSearch: searchParams,
      });
    } else {
      res = await fetchMaterialListAttribute({ page: 1, size: 1000 });
    }

    selectOptionsList = handleFormatData(res?.data?.list, {
      label: 'name',
      value: 'id',
      children: 'children',
    });

    return selectOptionsList;
  };
  const getList = async (searchParams?: string) => {
    setFetching(true);
    const selectOptionsList: any[] = await handleFetchData(searchParams);

    if (selectOptionsList?.length > 0) {
      setOptionsList(selectOptionsList);
    }
    setFetching(false);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <BlMultiCascader
      placeholder={`${placeholder}${nameLabel || ''}`}
      value={value}
      options={optionsList}
      onChange={handelOnChange}
      uncheckableItemValues={_.map(optionsList, 'value')}
      renderValue={(value: any, selectedItems: any) => (
        <span>
          {selectedItems.map((item: any) => (
            <Tag>
              {item.parent.label}: {item.label}
            </Tag>
          ))}
        </span>
      )}
      onSearch={handleFetchData}
      onOpen={() => {
        getList();
      }}
    />
  );
};

export default MaterialAttributeCascader;
