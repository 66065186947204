import moment from 'moment';
import { replaceSign } from 'src/utils/constants';
import { BatchSummaryRecordBizInfoOpAmountAmount } from './type';

/**
 * @description: 将数字和单位拼接
 * @param {Partial} data
 * @return {string} 返回类似 '' || '0g,1kg' 的格式
 */
export const formatWithComma = (
  data: Partial<BatchSummaryRecordBizInfoOpAmountAmount>[] | undefined,
) => {
  let str = '';

  if (!data) return str;

  data.forEach((item) => {
    str += (str ? ' , ' : '') + (item?.amount || replaceSign) + item?.unit?.name;
  });

  return str;
};

/** 编辑批次格式化接口返回值 */
export const formatFormInitialValue = (data: any) => {
  const { batchNo, customer, supplier, supplierBatch, produceAt, validTo, ...rest } = data || {};

  return {
    batchNo,
    customer: {
      label: customer?.name,
      value: customer?.id,
      key: customer?.id,
    },
    supplier: {
      label: supplier?.name,
      value: supplier?.id,
      key: supplier?.id,
    },
    supplierBatch,
    produceAt: produceAt && moment(produceAt),
    validTo: validTo && moment(validTo),
    ...rest,
  };
};

/** 编辑批次格式化保存入参 */
export const formatEditBatchParams = (data: any) => {
  const { batchNo, customer, supplier, supplierBatch, produceAt, validTo, ...rest } = data || {};

  return {
    batchNo,
    customerId: customer && customer?.value,
    supplierId: supplier && supplier?.value,
    supplierBatch,
    produceAt: produceAt && moment(produceAt).format('x'),
    validTo: validTo && moment(validTo).format('x'),
    ...rest,
  };
};
