import React from 'react';
import _ from 'lodash';
import { BlIcon, BlTable, SearchSelect, TagList } from 'src/components';
import { Form, Button, Input, InputNumber } from 'antd';
import { CheckTypeEnum } from 'src/dict/quality';
import { QcMaterial } from 'src/page/quality/models/dto';
import { STATUS_ENUM } from 'src/page/knowledgeManagement/warehouse/constant';
import { UnitType } from 'src/dict/material';
import { naturalNumber } from 'src/utils/formValidators';

interface TableProps {
  data: any;
  type: string;
}
const RelationshipInfo = (props: any) => {
  const { showSelectModal, checkType, form, setRefresh } = props;
  const deleteLine = (type: string, lineId: number) => {
    const tableList = form.getFieldValue([type]);

    form.setFieldsValue({
      [type]: tableList?.filter((data: { id: number }) => data.id !== lineId),
    });
    setRefresh();
  };

  const ProcessTable = (props: TableProps) => {
    const { type, data } = props;

    return (
      <>
        <BlTable
          columns={[
            { title: '工序编号', dataIndex: 'code', width: 200 },
            { title: '工序名称', dataIndex: 'name', width: 200 },
            {
              title: '工序次序',
              dataIndex: 'seq',
              width: 200,
              render: (seq: any, record: any, index: number) => (
                <Form.Item name={['qcConfigProcessList', index, 'seq']} rules={[naturalNumber]}>
                  <Input placeholder="请输入工序次序" />
                </Form.Item>
              ),
            },
            {
              title: '操作',
              dataIndex: 'id',
              width: 200,
              render: (id: number) => (
                <a
                  onClick={() => {
                    deleteLine('qcConfigProcessList', id);
                  }}
                >
                  删除
                </a>
              ),
            },
          ]}
          dataSource={data}
          pagination={false}
          scroll={{ y: 300 }}
          resizableCol
          useColConfig
        />
        <Button
          type="dashed"
          style={{ width: '100%' }}
          icon={<BlIcon type="iconxinjiantianjia" />}
          onClick={() => showSelectModal(type, data)}
        >
          添加工序
        </Button>
      </>
    );
  };

  const MaterialTable = (props: TableProps) => {
    const { type, data } = props;

    return (
      <>
        <BlTable
          columns={[
            { title: '物料编号', dataIndex: 'code', width: 200 },
            {
              title: '物料名称',
              dataIndex: 'name',
              width: 200,
              render: (name: string, record: QcMaterial) =>
                `${name}${record.enableFlag === STATUS_ENUM.DISABLED ? '(已停用)' : ''}`,
            },
            {
              title: '物料分类',
              dataIndex: ['category'],
              width: 200,
              render: (category) => category?.name || category?.categoryName,
            },
            {
              title: '物料属性',
              dataIndex: ['attributes'],
              width: 200,
              render: (attributes: any[]) =>
                `${
                  attributes
                    ?.map((attribute) => {
                      if (attribute.attributeName) {
                        return `${attribute.attributeName}: ${attribute.attributeItemName}`;
                      }
                      return `${attribute?.name}: ${attribute?.attributeItem?.content}`;
                    })
                    .join(',') || '-'
                }`,
            },
            { title: '主单位', dataIndex: 'unitName', width: 200 },
            {
              title: (
                <span>
                  <span style={{ color: 'red' }}>*</span>检验单位
                </span>
              ),
              dataIndex: 'qcUnit',
              width: 200,
              render: (qcUnit: any, record: any, index: number) => {
                return (
                  <Form.Item
                    name={['qcConfigMaterialList', index, 'qcUnit']}
                    rules={[{ required: true, message: '请选择检验单位' }]}
                  >
                    <SearchSelect
                      fetchType="materialUnit"
                      labelInValue
                      params={{
                        unitType: [UnitType.mainUnit, UnitType.conversionUnit],
                        materialId: record.id,
                      }}
                    />
                  </Form.Item>
                );
              },
            },
            {
              title: '操作',
              dataIndex: 'id',
              width: 200,
              render: (id: number) => (
                <a onClick={() => deleteLine('qcConfigMaterialList', id)}>删除</a>
              ),
            },
          ]}
          dataSource={data}
          pagination={false}
          scroll={{ y: 300 }}
          resizableCol
        />
        <Button
          type="dashed"
          style={{ width: '100%' }}
          icon={<BlIcon type="iconxinjiantianjia" />}
          onClick={() => showSelectModal(type, data)}
        >
          添加物料
        </Button>
      </>
    );
  };

  const ResourceTable = (props: TableProps) => {
    const { type, data } = props;

    return (
      <>
        <BlTable
          columns={[
            { title: '设备编号', dataIndex: 'code', width: 200 },
            { title: '设备名称', dataIndex: 'name', width: 200 },
            {
              title: '操作',
              dataIndex: 'id',
              width: 200,
              render: (id: number) => (
                <a onClick={() => deleteLine('qcConfigResourceList', id)}>删除</a>
              ),
            },
          ]}
          dataSource={data}
          pagination={false}
          scroll={{ y: 300 }}
          resizableCol
        />
        <Button
          type="dashed"
          style={{ width: '100%' }}
          icon={<BlIcon type="iconxinjiantianjia" />}
          onClick={() => showSelectModal(type)}
        >
          添加设备
        </Button>
      </>
    );
  };

  const CustomerTable = (props: TableProps) => {
    const { type, data } = props;

    return (
      <>
        <BlTable
          columns={[
            { title: '客户编号', dataIndex: 'code', width: 200 },
            { title: '客户名称', dataIndex: 'name', width: 200 },
            {
              title: '操作',
              dataIndex: 'id',
              width: 200,
              render: (id: number) => (
                <a onClick={() => deleteLine('qcConfigCustomerList', id)}>删除</a>
              ),
            },
          ]}
          dataSource={data}
          pagination={false}
          scroll={{ y: 300 }}
          resizableCol
        />
        <Button
          type="dashed"
          style={{ width: '100%' }}
          icon={<BlIcon type="iconxinjiantianjia" />}
          onClick={() => showSelectModal(type, data)}
        >
          添加客户
        </Button>
      </>
    );
  };

  const SupplierTable = (props: TableProps) => {
    const { type, data } = props;

    return (
      <>
        <BlTable
          columns={[
            { title: '供应商编号', dataIndex: 'code', width: 200 },
            { title: '供应商名称', dataIndex: 'name', width: 200 },
            {
              title: '操作',
              dataIndex: 'id',
              width: 200,
              render: (id: number) => (
                <a onClick={() => deleteLine('qcConfigSupplierList', id)}>删除</a>
              ),
            },
          ]}
          dataSource={data || []}
          pagination={false}
          scroll={{ y: 300 }}
          resizableCol
        />
        <Button
          type="dashed"
          style={{ width: '100%' }}
          icon={<BlIcon type="iconxinjiantianjia" />}
          onClick={() => showSelectModal(type, data)}
        >
          添加供应商
        </Button>
      </>
    );
  };

  const relationshipInfo = {
    title: '',
    items: [
      {
        label: '关联物料',
        name: 'qcConfigMaterialList',
        isFullLine: true,
        isshow: true,
        render: () => (
          <MaterialTable
            type={'qcConfigMaterialList'}
            data={form.getFieldValue('qcConfigMaterialList')}
          />
        ),
      },
      {
        label: '关联工序', // (若使用检验计划，工序必须关联)
        name: 'qcConfigProcessList',
        isFullLine: true,
        isshow:
          checkType === CheckTypeEnum.produceOriginQc ||
          checkType === CheckTypeEnum.produceQc ||
          checkType === CheckTypeEnum.inspectionQc,
        render: () => (
          <ProcessTable
            type={'qcConfigProcessList'}
            data={form.getFieldValue('qcConfigProcessList')}
          />
        ),
      },
      {
        label: '关联设备',
        name: 'qcConfigResourceList',
        isFullLine: true,
        isshow:
          checkType === CheckTypeEnum.produceOriginQc ||
          checkType === CheckTypeEnum.produceQc ||
          checkType === CheckTypeEnum.inspectionQc,
        render: () => (
          <ResourceTable
            type={'qcConfigResourceList'}
            data={form.getFieldValue('qcConfigResourceList')}
          />
        ),
      },
      {
        label: '关联客户',
        name: 'qcConfigCustomerList',
        isFullLine: true,
        isshow: checkType === CheckTypeEnum.outputFactoryQc,
        render: () => (
          <CustomerTable
            type={'qcConfigCustomerList'}
            data={form.getFieldValue('qcConfigCustomerList')}
          />
        ),
      },
      {
        label: '关联供应商',
        name: 'qcConfigSupplierList',
        isFullLine: true,
        isshow: checkType === CheckTypeEnum.inputFactoryQc,
        render: () => (
          <SupplierTable
            type={'qcConfigSupplierList'}
            data={form.getFieldValue('qcConfigSupplierList')}
          />
        ),
      },
    ],
  };

  relationshipInfo.items = relationshipInfo.items.filter((item) => item.isshow);

  return relationshipInfo;
};

export default RelationshipInfo;
