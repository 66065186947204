import React from 'react';
import type { FC } from 'react';
import { BlTable } from 'src/components';
import { DetailLayout } from 'src/layout';
import lookup from 'src/dict';

interface Props {
  dataSource: {}[];
}

export const ProcessParamDetailTable: FC<Props> = (props) => {
  const { dataSource } = props;
  const columns = [
    {
      title: '序号',
      dataIndex: 'lineNo',
      width: 120,
      render: (_text: string, _record: any, index: number) => index + 1,
    },
    {
      title: '设备参数',
      dataIndex: 'paramsName',
      width: 120,
    },

    {
      title: '参数类型',
      dataIndex: 'paramsType',
      width: 120,
      render: (paramsType: any) => lookup('resources.paramType', paramsType),
    },
    {
      title: '单位',
      dataIndex: 'unitName',
      width: 120,
    },
    {
      title: '参数标准值',
      dataIndex: 'paramsStandard',
      width: 120,
    },
    {
      title: '参数上限',
      dataIndex: 'paramsUpperLimit',
      width: 120,
    },
    {
      title: '参数下限',
      dataIndex: 'paramsLowerLimit',
      width: 120,
    },
    {
      title: '参数上上限',
      dataIndex: 'paramsTopLimit',
      width: 120,
    },
    {
      title: '参数下下限',
      dataIndex: 'paramsFloorLimit',
      width: 120,
    },
  ];

  return (
    <DetailLayout
      title="查看工艺参数"
      info={[
        {
          title: '',
          items: [
            {
              label: '',
              dataIndex: 'paramsVOList',
              render: (paramsVOList) => {
                return (
                  <BlTable
                    scroll={{ x: 'max-content', y: 'auto' }}
                    dataSource={paramsVOList}
                    columns={columns}
                    rowKey="id"
                  />
                );
              },
            },
          ],
        },
      ]}
      dataSource={dataSource}
      loading={false}
    />
  );
};
