import React, { useState } from 'react';
import { Form } from 'antd';
import { FilterItem, RecordListLayout } from 'layout';
import {
  BlIcon,
  ErrorNotification,
  LinkTooltip,
  SearchSelect,
  showErrorListMessage,
  SingleOperationLogComponent,
  Tooltip,
} from 'components';
import { replaceSign } from 'src/utils/constants';
import { fieldTypeList } from 'src/utils';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import _Time from 'src/utils/dataTypes/time';
import { useHistory } from 'react-router-dom';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { toProcessDetail, toProcessEdit, toProcessCreate } from './navigation';
import ProcessDetail from './detail';
import { fetchProcess, fetchProcessBulkDelete } from 'src/api/ytt/med-domain/process';
import { FIELD_MAP, TableRowType } from './interface';
import _ from 'lodash';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { UserColumnProps } from '../../calendarManagement/workCalendar/constants';
import _Array from 'src/utils/dataTypes/array';
import { momentData } from '../../calendarManagement/shift/utils';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import { Creator } from 'src/page/sales/salesManagement/salesOrder/interface';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';

export default function Process() {
  const history = useHistory();
  const [visibleDetail, setVisibleDetail] = useState(false);
  // const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [processId, setProcessId] = useState<any>();
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const [delForm] = Form.useForm();

  // 刷新页面
  const refresh = () => {
    const date = Date.now();

    setRefreshMarker(date);
  };

  const errorColumns = [
    {
      title: '工序名称',
      dataIndex: 'name',
      width: 200,
      render: (name: string) => name || replaceSign,
    },
    {
      title: '失败原因',
      dataIndex: 'usingShiftObjs',
      width: 700,
      render: (usingShiftObjs: any) => {
        return <>存在工艺路线正在使用该工序，调整后重试</>;
      },
    },
  ];

  // const handleDelete = async (
  //   type: string,
  //   delId: number[],
  //   onSuccess: () => void,
  //   onFail?: () => void,
  // ) => {
  //   try {
  //     const { message, data } = await fetchProcessBulkDelete({
  //       idList: delId,
  //     });

  //     // const { failedCount, successCount, failDetailVO } = data;

  //     if (data) {
  //       Message.success('删除成功');
  //       onSuccess?.();
  //       // if (failedCount === 0) {
  //       //   Message.success('删除成功');
  //       //   onSuccess?.();
  //       //   return;
  //       // }
  //       // if (successCount === 0) {
  //       //   onFail?.();
  //       //   // 全部删除失败
  //       // } else if (failedCount) {
  //       //   onSuccess?.();
  //       //   // 有失败的删除 需要展示失败信息
  //       // }

  //       // if (type === 'single') {
  //       //   ErrorNotification({
  //       //     message: '删除失败',
  //       //     description: <>存在工艺路线正在使用该工序，调整后重试</>,
  //       //   });
  //       // } else {
  //       //   showErrorListMessage({
  //       //     title: '删除出现失败',
  //       //     data: shiftDeleteDetailVOS,
  //       //     columns: errorColumns,
  //       //     operateName: '删除',
  //       //     successCount,
  //       //     failCount: failedCount,
  //       //     width: 1200,
  //       //   });
  //       return;
  //     }

  //     onFail?.();

  //     Message.error(message);
  //   } catch (error) {
  //     onFail?.();
  //     console.log(error);
  //   }
  // };

  const dataColumns = [
    {
      title: '工序编号',
      dataIndex: 'code',
      width: 200,
      sorter: true,
      render: (text: string, record: any, index: number, config: any) => (
        <LinkTooltip
          to={toProcessDetail(record?.id)}
          text={text}
          width={config.contentWidth}
          auth={authDict.process_detail}
        />
      ),
    },
    {
      title: '工序名称',
      dataIndex: 'name',
      width: 200,
      sorter: true,
      render: (text: string, record: any, index: number, config: any) => (
        <LinkTooltip
          to={toProcessDetail(record?.id)}
          text={text}
          width={config.contentWidth}
          auth={authDict.process_detail}
        />
      ),
    },
    {
      title: '负责部门',
      dataIndex: 'departmentName',
      width: 150,
      render: (departmentName: string) => departmentName ?? replaceSign,
    },
    {
      title: '工作中心',
      dataIndex: 'workCenterName',
      width: 150,
      render: (workCenterName: string) => workCenterName ?? replaceSign,
    },
    {
      title: '工序说明',
      dataIndex: 'description',
      width: 150,
      render: (description: string) => description ?? replaceSign,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 150,
      render: (creator: Creator) => {
        return (
          <AvatarDisplay
            id={creator?.id}
            name={creator?.name}
            avatarUrl={creator?.avatarUrl}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 180,
      sorter: true,
      render: (createdAt: Date) => {
        if (!createdAt) return replaceSign;
        return _Time.format(createdAt);
      },
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      width: 150,
      render: (operator: Creator) => {
        return (
          <AvatarDisplay
            id={operator?.id}
            name={operator?.name}
            avatarUrl={operator?.avatarUrl}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 180,
      sorter: true,
      render: (updatedAt: Date, record: TableRowType) => {
        if (!updatedAt) return _Time.format(record?.createdAt) ?? replaceSign; // 没有更新,默认为创建时间
        return _Time.format(updatedAt);
      },
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '工序编号',
      name: 'code',
      type: fieldTypeList.text,
    },
    {
      label: '工序名称',
      name: 'name',
      type: fieldTypeList.text,
    },
    {
      label: '所属部门',
      name: 'departmentIdList',
      type: fieldTypeList.multiSelect,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple />,
    },
    {
      label: '工作中心',
      name: 'workCenterIdList',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType="workCenter"
          style={{ width: '100%' }}
          labelInValue
          mode={'multiple'}
        />
      ),
    },
    {
      label: '创建时间',
      name: 'createdAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '创建人',
      name: 'creatorIdList',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
    {
      label: '更新时间',
      name: 'updatedAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '更新人',
      name: 'operatorIdList',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
  ];

  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      auth: authDict.process_export,
      icon: <BlIcon type="icondaochu" />,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.process,
        });
      },
    },
    {
      title: '新建工序',
      auth: authDict.process_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        history.push(toProcessCreate());
      },
      items: [
        {
          title: '导入',
          showExport: true,
          auth: authDict.process_import,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.process,
            });
          },
        },
      ],
    },
  ];

  // const toolbar = [
  //   {
  //     title: '删除',
  //     icon: <BlIcon type="iconshanchulajitong1" />,
  //     onClick: (onSuccess: any, onFail: any) => {
  //       BLDelConfirm(
  //         '删除后将无法恢复，确认删除？',
  //         delForm,
  //         () => {
  //           handleDelete('batch', selectedRowKeys, onSuccess, onFail);
  //         },
  //         () => {
  //           onFail();
  //         },
  //       );
  //     },
  //   },
  // ];

  const formatDataToQuery = (params: any) => {
    const {
      sorter,
      departmentIdList,
      createdAt,
      updatedAt,
      creatorIdList,
      operatorIdList,
      workCenterIdList,
      ...rest
    } = params;

    const relParams = { ...rest };

    if (sorter) {
      relParams.sorter = _.map(sorter, (node: any) => {
        return {
          order: node?.order,
          field: FIELD_MAP.get(sorter?.[0].field),
        };
      });
    }

    if (!_Array.isEmpty(departmentIdList)) {
      relParams.departmentIdList = _.map(departmentIdList, (node: any) => node.value);
    }
    if (!_Array.isEmpty(creatorIdList)) {
      relParams.creatorIdList = _.map(creatorIdList, (node: any) => node.value);
    }
    if (!_Array.isEmpty(operatorIdList)) {
      relParams.operatorIdList = _.map(operatorIdList, (node: any) => node.value);
    }
    if (!_Array.isEmpty(workCenterIdList)) {
      relParams.workCenterIdList = _.map(workCenterIdList, (node: any) => node.value);
    }

    if (createdAt) {
      relParams.createdStartAt = _Time.formatToUnix(createdAt[0]);
      relParams.createdEndAt = _Time.formatToUnix(createdAt[1]);
    }
    if (updatedAt) {
      relParams.updatedStartAt = _Time.formatToUnix(updatedAt[0]);
      relParams.updatedEndAt = _Time.formatToUnix(updatedAt[1]);
    }

    dispatch.excelImport.updateBusinessData({ businessData: relParams });

    return relParams;
  };

  const formatDataToFormDisplay = (data: any) => {
    const { createdAt, updatedAt } = data;

    const retData = { ...data };

    if (createdAt) {
      retData.createdAt = momentData(createdAt);
    }
    if (updatedAt) {
      retData.updatedAt = momentData(updatedAt);
    }

    return retData;
  };

  const config = {
    rowKey: (record: TableRowType) => String(record.id),
    columns: dataColumns,
    getOperationList: (record: TableRowType) => {
      return [
        {
          title: '查看',
          auth: authDict.process_detail,
          onClick: () => {
            setProcessId(record?.id);
            setVisibleDetail(true);
          },
        },
        {
          title: '编辑',
          auth: authDict.process_edit,
          onClick: () => history.push(toProcessEdit(record?.id)),
        },
        {
          title: '操作记录',
          auth: authDict.process_operrecord,
          onClick: () => {
            setVisibleLog(true);
            setProcessId(record?.id);
          },
        },
      ];
    },
    mainMenu,
    filterList,
    formatDataToQuery,
    formatDataToFormDisplay,
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        userAuth={getAuthFromLocalStorage()}
        placeholder={'输入工序编号、工序名称'}
        // batchMenu={toolbar}
        // selectedRowKeys={selectedRowKeys}
        configcacheKey="processCol"
        // onSelectedRowKeys={(selectKey: any) => {
        //   setSelectRowKeys(selectKey);
        // }}
        requestFn={(params) => {
          return fetchProcess(params, { legacy: true });
        }}
        refreshMarker={refreshMarker}
        {...config}
      />
      {visibleDetail && (
        <ProcessDetail
          visible={visibleDetail}
          closeDetail={() => {
            setVisibleDetail(false);
          }}
          processId={processId}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={processId}
          objectCode={'Process'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
}
