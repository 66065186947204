/**
 * 主对象接入基础方法
 */

import { DataFormLayoutInfoItem, DetailLayoutInfoItem } from '@blacklake-web/layout';
import { Form } from 'antd';
import _ from 'lodash';
import {
  DEFAULT_DATAINDEX,
  generateGetCustomFieldNamePathByFieldCode,
  getCustomFieldNameOrkey,
  getObjectCustomFields,
  getObjectFieldCascadings,
  getShoulUpdate,
  handleFieldChange,
} from './base';
import { getDetailCustomFieldItem } from './detailModel';
import { getFormCustomFieldItem } from './formModel';
import {
  CustomFieldComponentPropsMap,
  ICustomFieldItem,
  ICustomFieldsFnConfig,
  ICustomFieldsFnParams,
} from './interface';

export interface IMainObjCustomFieldsToEditConfig extends ICustomFieldsFnConfig {}
export interface IMainObjCustomFieldsToDetailConfig {
  dataIndex: string | string[];
}

/**
 * 获取主对象 自定义字段 在『表单』的info,返回列表直接用在 DataFormLayout 的info配置中,
 * @param customFields
 * @param objectCode 对象的code
 */
export const getMainObjCustomFieldsToEdit = (
  params: ICustomFieldsFnParams,
  config: IMainObjCustomFieldsToEditConfig,
  componentsProps?: CustomFieldComponentPropsMap,
): DataFormLayoutInfoItem[] => {
  const { customFields, objectCode } = getObjectCustomFields(params);
  const { disabled = false, ...formConfig } = (config ?? {}) as IMainObjCustomFieldsToEditConfig;
  const fieldCascadings = getObjectFieldCascadings(params);

  if (_.isEmpty(customFields) || _.isNil(objectCode)) return [];

  const infos = _.map(customFields, (dataSource, index) => {
    const formName = getCustomFieldNameOrkey({
      objectCode,
      fieldId: dataSource.fieldId,
    });
    const relatedCascading = _.find(fieldCascadings, ['refFieldCode', dataSource.fieldCode]);

    const item = getFormCustomFieldItem(dataSource, componentsProps, {
      form: config?.form,
      fieldFormName: formName,
      relatedCascading,
      getNamePathByFieldCode: generateGetCustomFieldNamePathByFieldCode(objectCode, customFields),
    });

    // 如果没有返回字段相关信息，返回null
    if (_.isNil(item)) return null;

    const shouldUpdate = relatedCascading
      ? getShoulUpdate(
          getCustomFieldNameOrkey({
            objectCode,
            fieldId: _.find(customFields, { fieldCode: relatedCascading.fieldCode! })?.fieldId!,
          }),
        )
      : undefined;

    const itemRenderer = () => {
      return item.render(
        (value: any) => {
          handleFieldChange(dataSource, { customFields, objectCode }, formConfig, undefined, value);
        },
        { disabled },
      );
    };
    const result = {
      name: getCustomFieldNameOrkey({ objectCode, fieldId: dataSource.fieldId }),
      shouldUpdate,
      ...item,
    };

    // 级联或其他需要更新的情况下
    if (shouldUpdate) {
      return {
        ..._.omit(result, ['name', 'rules', 'initialValue']),
        render: (() => () =>
          (
            <Form.Item noStyle {..._.pick(result, ['name', 'rules', 'initialValue'])}>
              {itemRenderer()}
            </Form.Item>
          )) as any,
      };
    }

    return {
      ...result,
      render: itemRenderer,
    };
  });

  return _.compact(infos);
};

/**
 * 获取主对象 自定义字段 在『详情』的info,返回列表直接用在 DetailLayout 的info配置中
 * @param customFieldObj
 * @param dataIndex 支持自定义的key
 * @returns
 */
export const getMainObjCustomFieldsToDetail = (
  params: ICustomFieldsFnParams,
  config?: IMainObjCustomFieldsToDetailConfig,
  componentsProps?: CustomFieldComponentPropsMap,
): DetailLayoutInfoItem[] => {
  const { customFields, objectCode } = getObjectCustomFields(params) || {};
  const { dataIndex = DEFAULT_DATAINDEX } = config ?? {};

  if (_.isEmpty(customFields) || _.isNil(objectCode)) return [];

  const infos = _.map(customFields, (dataSource, index) => {
    const item = getDetailCustomFieldItem(dataSource, {}, componentsProps);
    if (!item) return null;

    return {
      ...item,
      dataIndex,
      render: (record: ICustomFieldItem[]) => {
        const index_ = _.find(record, ['fieldId', dataSource.fieldId]);
        const value = _.get(index_, 'fieldValue');

        return item.format(value);
      },
    };
  });
  return _.compact(infos);
};
