import React from 'react';
import { BlSortFormList } from '@blacklake-web/component';
import { Form, FormInstance, Input } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import { SearchSelect } from 'src/components';
import { UsageStatus } from 'src/dict/common';
import { Rule } from 'antd/lib/form';
import _ from 'lodash';
import { SearchSelectOption } from 'src/components/searchSelect/configs';

interface SupplierEditTableProps {
  form: FormInstance;
  name: string;
}

const SupplierEditTable: React.FC<SupplierEditTableProps> = (props) => {
  const { form, name } = props;

  /**
   * 校验采购周期
   */
  const purchaseCycleValidator = (rule: Rule, value: string) => {
    if (!value) {
      return Promise.resolve();
    }

    const number = Number(value);

    if (
      (typeof value === 'string' && value.indexOf('.') !== -1) ||
      !_.isInteger(number) ||
      number < 1 ||
      number > 999
    ) {
      return Promise.reject('请输入大于0小于1000的整数');
    }
    return Promise.resolve();
  };

  /**
   * 校验供应商
   */
  const supplierValidator = (rule: Rule, value: SearchSelectOption, index: number) => {
    if (!value) {
      return Promise.reject('请选择供应商');
    }
    const values = form.getFieldValue(name);
    let alreadyExist = false;

    values?.forEach((item: any, i: number) => {
      if (i !== index && item?.supplier?.value === value?.value) {
        alreadyExist = true;
      }
    });
    if (alreadyExist) {
      return Promise.reject('该供应商已在货源清单中');
    }
    return Promise.resolve('');
  };

  const editableColumns: ColumnProps<any & FormListFieldData>[] = [
    {
      title: '',
      dataIndex: 'id',
      width: 1,
      render: (text, field) => {
        return (
          <Form.Item
            hidden
            name={[field.name, 'id']}
            fieldKey={[field.fieldKey, 'id']}
            style={{ marginBottom: '0' }}
          >
            <Input />
          </Form.Item>
        );
      },
    },
    {
      title: '供应商',
      dataIndex: 'supplier',
      width: 200,
      render: (text, field, index) => {
        return (
          <Form.Item
            name={[field.name, 'supplier']}
            fieldKey={[field.fieldKey, 'supplier']}
            style={{ marginBottom: '0' }}
            rules={[
              {
                required: true,
                validator: (rule: Rule, value: SearchSelectOption) =>
                  supplierValidator(rule, value, index),
              },
            ]}
          >
            <SearchSelect fetchType={'supplier'} params={{ status: [UsageStatus.enabled] }} />
          </Form.Item>
        );
      },
    },
    {
      title: '采购周期',
      dataIndex: 'purchaseCycle',
      width: 200,
      render: (text, field) => {
        return (
          <Form.Item
            name={[field.name, 'purchaseCycle']}
            fieldKey={[field.fieldKey, 'purchaseCycle']}
            style={{ marginBottom: '0' }}
            rules={[{ required: false, validator: purchaseCycleValidator }]}
          >
            <Input addonAfter="天" />
          </Form.Item>
        );
      },
    },
  ];

  return (
    <BlSortFormList
      name={name}
      form={form}
      buttonText={'添加'}
      renderColumns={() => editableColumns}
      isNeedDrag={false}
    />
  );
};

export default SupplierEditTable;
