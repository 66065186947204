/**
 * 布局编辑器
 */
import { CSSProperties, FC, useReducer } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import classnames from 'classnames';
import Source from './source';
import Previewer from './previewer';
import { EditorProps, EditorContext } from '../share';
import styles from '../styles.module.scss';

interface CategoryCardProps {
  title: string;
  extra?: string;
  style?: CSSProperties;
  bodyClassName?: string;
}

const CategoryCard: FC<CategoryCardProps> = ({ title, extra, style, bodyClassName, children }) => (
  <div className={styles.card} style={style}>
    <header className={styles['card-header']}>
      <span className={styles['card-title']}>{title}</span>
      {extra && <span className={styles['card-extra']}>{extra}</span>}
    </header>
    <main className={classnames(styles['card-main'], bodyClassName)}>{children}</main>
  </div>
);

const Editor: FC<EditorProps> = ({
  fieldList,
  sonObjectList,
  pageType,
  form,
  supportTab,
  disabled,
}) => {
  const [, forceUpdate] = useReducer(() => Date.now(), 0);

  return (
    <DndProvider backend={HTML5Backend}>
      <EditorContext.Provider
        value={{ fieldList, sonObjectList, pageType, form, supportTab, disabled, forceUpdate }}
      >
        <div id={styles['layout-editor']}>
          <CategoryCard
            title="组件及字段"
            extra={disabled ? '' : '拖拽添加'}
            style={{ flex: '0 0 260px' }}
          >
            <Source />
          </CategoryCard>
          <div className={styles.separator} />
          <CategoryCard
            title="页面信息"
            style={{ flex: 1 }}
            bodyClassName={styles['layout-editor-page-info']}
          >
            <Previewer />
          </CategoryCard>
        </div>
      </EditorContext.Provider>
    </DndProvider>
  );
};

export default Editor;
