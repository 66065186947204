import { useState } from 'react';
import { Button, Form, Input, FormInstance, message as Message, Tooltip } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/es/table';
import { BlSortFormList, BlIcon } from '@blacklake-web/component';
import _ from 'lodash';

import { numberMinMaxCheck, fractionLengthCheck, positiveInt } from 'src/utils/formValidators';
import { materialEntity } from 'src/entity';
import { lookup } from 'src/dict';
import { CRUD } from 'src/dict/common';
import { amountRender } from 'src/page/share/renderAmount';
import { SelectSalesOrderDialog, SalesOrderDetailListItemType } from '../components';
import { fieldLabels } from '../constants';
import { SalesReturnOrderAddItemType } from '../interface';
import Styles from './index.module.scss';

interface AddItemListProps {
  form: FormInstance;
  isCreating: boolean;
}

const addItemTableName = 'items';
/** 默认单位精度 */
const DEFAULT_PRECISION = 10;

export default function AddItemList({ form, isCreating }: AddItemListProps) {
  // 是否显示销售订单弹窗
  const [selectSalesOrderVisible, setSelectSalesOrderVisible] = useState(false);
  const { getFieldValue } = form;

  /**
   * 校验行号,不能重复
   */
  const validatorDuplicatedIndex = (rule: any, value: number) => {
    const formList = form.getFieldValue(addItemTableName);

    const firstIndex = _.findIndex(formList, { lineNo: value });
    const lastIndex = _.findLastIndex(formList, { lineNo: value });

    if (firstIndex !== lastIndex) {
      return Promise.reject('不能有重复的行号');
    }

    return Promise.resolve('');
  };

  const getColumns: (
    remove: (index: number | number[]) => void,
  ) => ColumnProps<any & FormListFieldData>[] = (remove) => {
    return [
      {
        title: fieldLabels.lineNo,
        dataIndex: 'lineNo',
        width: 80,
        render: (text, field) => {
          return isCreating ? (
            form.getFieldValue([addItemTableName, field.name, 'lineNo'])
          ) : (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'lineNo']}
              name={[field.name, 'lineNo']}
              rules={[
                {
                  required: true,
                  message: `${fieldLabels.lineNo}必填`,
                },
                positiveInt,
                {
                  validator: validatorDuplicatedIndex,
                },
              ]}
            >
              <Input placeholder="请输入" />
            </Form.Item>
          );
        },
      },
      {
        title: fieldLabels.materialCode,
        dataIndex: 'materialCode',
        width: 140,
        render: (text, field) =>
          form.getFieldValue([addItemTableName, field.name, 'material', 'baseInfo', 'code']),
      },
      {
        title: fieldLabels.materialName,
        dataIndex: 'materialName',
        width: 140,
        render: (text, field) =>
          form.getFieldValue([addItemTableName, field.name, 'material', 'baseInfo', 'name']),
      },
      {
        title: (
          <div className={Styles.availableAmountTitle}>
            <span>{fieldLabels.availableAmount}</span>
            <Tooltip title="可退货数：(已计划发货数-已计划退货数)与0的最大值">
              <BlIcon type="iconwentizhiyinbangzhu" className={Styles.infoIcon} />
            </Tooltip>
          </div>
        ),
        dataIndex: 'availableAmount',
        width: 130,
        render: (text, field) =>
          amountRender(form.getFieldValue([addItemTableName, field.name, 'availableAmount'])),
      },
      {
        title: fieldLabels.plannedReturnAmount,
        dataIndex: 'amount',
        width: 130,
        render: (_text, field) => {
          const inbountAmount = getFieldValue([addItemTableName, field.name, 'amount']);
          const material = getFieldValue([addItemTableName, field.name, 'material']);
          const { precisionFigure } = materialEntity.getMaterialUnitInfo(
            material,
            inbountAmount.unitId,
          );

          return (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'amount', 'amount']}
              name={[field.name, 'amount', 'amount']}
              rules={[
                {
                  required: true,
                  message: `${fieldLabels.plannedReturnAmount}必填`,
                },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: false,
                    fieldName: fieldLabels.plannedReturnAmount,
                  }),
                },
                {
                  // 精度校验从单位中获取
                  validator: fractionLengthCheck(precisionFigure ?? DEFAULT_PRECISION),
                },
              ]}
            >
              <Input placeholder="请输入" />
            </Form.Item>
          );
        },
      },
      {
        title: fieldLabels.unit,
        dataIndex: 'unit',
        width: 70,
        render: (text, field) =>
          form.getFieldValue([addItemTableName, field.name, 'amount', 'unitName']),
      },
      {
        title: fieldLabels.salesOrderCode,
        dataIndex: 'salesOrderCode',
        width: 150,
        render: (text, field) =>
          form.getFieldValue([addItemTableName, field.name, 'salesOrderCode']),
      },
      {
        title: fieldLabels.salesOrderItemLineNo,
        dataIndex: 'salesOrderItemLineNo',
        width: 120,
        render: (text, field) =>
          form.getFieldValue([addItemTableName, field.name, 'salesOrderItemLineNo']),
      },
      {
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width: 70,
        render: (text, field) => (
          // <Form.Item style={{ margin: 0 }}>
          <Button
            style={{ padding: 0 }}
            type="link"
            onClick={() => {
              remove(field?.name);
            }}
          >
            {lookup('crud', CRUD.delete)}
          </Button>
          // </Form.Item>
        ),
      },
    ];
  };

  const updateSalesReturnOrderItems = (
    newKeys: React.Key[],
    newItems: SalesOrderDetailListItemType[],
  ) => {
    const multipleCustomers = _.uniqBy(newItems, 'customer.id').length > 1;

    if (multipleCustomers) {
      Message.error('请选择同一客户的销售订单明细行');
      return;
    }

    const originItems: SalesReturnOrderAddItemType[] = form.getFieldValue(addItemTableName) ?? [];
    const maxLineNo = _.max(originItems.map((originItem) => Number(originItem.lineNo))) ?? 0;
    const newlyAddedItem = newItems
      .filter((item) => _.findIndex(originItems, { salesOrderItemId: item.id }) < 0)
      .map((item, idx) => {
        const { availableReturnAmount, id, orderId, lineNo, orderCode, ...rest } = item;

        return {
          ...rest,
          availableAmount: availableReturnAmount,
          amount: availableReturnAmount,
          salesOrderId: orderId,
          salesOrderItemId: id,
          salesOrderItemLineNo: lineNo,
          salesOrderCode: orderCode,
          lineNo: `${Number(maxLineNo) + idx + 1}`, // 接口里行号定义为了string类型
        };
      });

    form.setFieldsValue({
      [addItemTableName]: [
        ...originItems.filter((originItem) => newKeys.includes(originItem.salesOrderItemId!)),
        ...newlyAddedItem,
      ],
    });
    closeSelectModal();
  };

  const closeSelectModal = () => setSelectSalesOrderVisible(false);

  return (
    <>
      <Button
        icon={<BlIcon type="iconxinjiantianjia" />}
        onClick={() => {
          setSelectSalesOrderVisible(true);
        }}
        style={{ marginBottom: 10 }}
        type="primary"
      >
        按销售订单添加
      </Button>
      {form.getFieldValue(addItemTableName)?.length !== undefined &&
        form.getFieldValue(addItemTableName)?.length > 0 && (
          <BlSortFormList
            name={addItemTableName}
            renderColumns={getColumns}
            isNeedDrag={false}
            isNeedAdd={false}
            isNeedDelete={false}
          />
        )}
      {selectSalesOrderVisible && (
        <SelectSalesOrderDialog
          visible={selectSalesOrderVisible}
          showOriginFilter={false}
          selectedKeys={
            getFieldValue('items')?.map(
              (item: SalesReturnOrderAddItemType) => item.salesOrderItemId,
            ) ?? []
          }
          onCancel={closeSelectModal}
          onFinish={(selectedKeys, selectedRows) => {
            updateSalesReturnOrderItems(selectedKeys, selectedRows);
          }}
        />
      )}
    </>
  );
}
