import { RouteProp } from 'src/route';
import QualityAQL from 'src/page/quality/aql';
import QualityAQLDetail from 'src/page/quality/aql/detail';
import QualityAQLAction from 'src/page/quality/aql/action';
import QualityAQLNational from 'src/page/quality/aql/national';
import QcConfig from 'src/page/quality/qcConfig/list';
import QcConfigDetail from 'src/page/quality/qcConfig/detail';
import QcConfigCreateAndEdit from 'src/page/quality/qcConfig/createAndEdit';
import CheckItem from 'src/page/quality/checkItem/list';
import CheckItemClassification from 'src/page/quality/checkItemClassification/list';
import EditCheckItem from 'src/page/quality/checkItem/edit';
import CheckItemClassificationEdit from 'src/page/quality/checkItemClassification/edit';
import CheckItemDetailPage from 'src/page/quality/checkItem/detailPage';
import CheckItemClassificationDetailPage from 'src/page/quality/checkItemClassification/detailPage';
import QcDefectReasonList from 'src/page/quality/qcDefectReason/list';
import QcDefectRankList from 'src/page/quality/qcDefectRank/list';
import QcTaskList from 'src/page/quality/qcTask/list';
import QcTaskDetail from 'src/page/quality/qcTask/detail';
import QualityAdjustRecord from 'src/page/quality/qualityAdjustRecord/list';
import QualityAdjustRecordDetail from 'src/page/quality/qualityAdjustRecord/recordLine';
import authDict from 'src/utils/auth';
import QcDefectReasonPage from './qcDefectReason/components/QcDefectReasonPage';
import QcDefectReasonEdit from './qcDefectReason/components/QcDefectReasonEdit';
import QcDefectRankPage from './qcDefectRank/components/QcDefectRankPage';
import QcDefectRankEdit from './qcDefectRank/components/QcDefectRankEdit';
import QcTaskEdit from './qcTask/createAndEdit/edit';
import QcTaskExecute from './qcTask/execute';
import Inventory from './qcTask/execute/inventory';

export const qualityRouteConfig: RouteProp[] = [
  {
    path: '/quality',
    menu: '质量',
    breadcrumb: '质量',
    icon: 'iconyijicaidan-zhiliang',
    redirectPath: '/quality/qualityExecution/qcTask',
    children: [
      {
        path: '/quality/qualityExecution',
        menu: '质量执行',
        breadcrumb: '质量执行',
        redirectPath: '/quality/qualityExecution/qcTask',
        children: [
          {
            path: '/quality/qualityExecution/qcTask',
            menu: '检验任务',
            component: QcTaskList,
            auth: authDict.qualityinspectiontask_view,
            breadcrumb: '检验任务',
            children: [
              {
                path: '/quality/qualityExecution/qcTask/detail',
                menu: '',
                component: QcTaskDetail,
                breadcrumb: '检验任务详情',
                auth: authDict.qualityinspectiontask_detail,
              },
              {
                path: '/quality/qualityExecution/qcTask/create',
                auth: authDict.qualityinspectiontask_add,
                menu: '',
                component: QcTaskEdit,
                breadcrumb: '新建检验任务',
              },
              {
                path: '/quality/qualityExecution/qcTask/:id/edit',
                auth: authDict.qualityinspectiontask_edit,
                component: QcTaskEdit,
                breadcrumb: '编辑检验任务',
              },
              {
                path: '/quality/qualityExecution/qcTask/execute/:id',
                component: QcTaskExecute,
                breadcrumb: '任务执行',
                children: [
                  {
                    path: '/quality/qualityExecution/qcTask/execute/:id/inventory',
                    component: Inventory,
                    breadcrumb: '库存列表',
                  },
                ],
              },
            ],
          },
          {
            path: '/quality/qualityModeling/adjustRecord',
            menu: '质量调整记录',
            component: QualityAdjustRecord,
            auth: authDict.qualityadjustmentrecord_view,
            breadcrumb: '质量调整记录',
            children: [
              {
                path: '/quality/qualityModeling/adjustRecord/detail',
                menu: '质量调整记录详情',
                component: QualityAdjustRecordDetail,
                auth: authDict.qualityadjustmentrecord_detail,
                breadcrumb: '质量调整记录详情',
              },
            ],
          },
        ],
      },
      {
        path: '/quality/qualityModeling',
        menu: '质量主数据',
        breadcrumb: '质量主数据',
        redirectPath: '/quality/qualityModeling/checkItemList',
        children: [
          {
            path: '/quality/qualityModeling/checkItemList',
            auth: authDict.inspectionitem_view,
            component: CheckItem,
            menu: '检验项',
            logPage: true,
            breadcrumb: '检验项',
            children: [
              {
                path: '/quality/qualityModeling/checkItemList/:id/detail',
                auth: authDict.inspectionitem_detail,
                component: CheckItemDetailPage,
                breadcrumb: '详情',
              },
              {
                path: '/quality/qualityModeling/checkItemList/create',
                auth: authDict.inspectionitem_add,
                component: EditCheckItem,
                breadcrumb: '新建检验项',
              },
              {
                path: '/quality/qualityModeling/checkItemList/:id/edit',
                auth: authDict.inspectionitem_edit,
                component: EditCheckItem,
                breadcrumb: '编辑检验项',
              },
            ],
          },
          {
            path: '/quality/qualityModeling/checkItemClassification',
            auth: authDict.inspectionitemcategory_view,
            menu: '检验项分类',
            component: CheckItemClassification,
            breadcrumb: '检验项分类',
            logPage: true,
            children: [
              {
                path: '/quality/qualityModeling/checkItemClassification/:id/detail',
                auth: authDict.inspectionitemcategory_detail,
                component: CheckItemClassificationDetailPage,
                breadcrumb: '详情',
              },
              {
                path: '/quality/qualityModeling/checkItemClassification/:id/edit',
                auth: authDict.inspectionitemcategory_edit,
                component: CheckItemClassificationEdit,
                breadcrumb: '编辑检验项分类',
              },
            ],
          },
          {
            path: '/quality/qualityModeling/aql',
            menu: '自定义AQL方案',
            component: QualityAQL,
            auth: authDict.customizedaql_view,
            breadcrumb: '自定义AQL方案',
            children: [
              {
                path: '/quality/qualityModeling/aql/detail/:id',
                component: QualityAQLDetail,
                auth: authDict.customizedaql_detail,
                breadcrumb: '详情',
              },
              {
                path: '/quality/qualityModeling/aql/create',
                component: QualityAQLAction,
                auth: authDict.customizedaql_add,
                breadcrumb: '创建AQL',
              },
              {
                path: '/quality/qualityModeling/aql/edit/:id',
                component: QualityAQLAction,
                auth: authDict.customizedaql_edit,
                breadcrumb: '编辑AQL',
              },
              {
                path: '/quality/qualityModeling/aql/copy/:id',
                component: QualityAQLAction,
                auth: authDict.customizedaql_add,
                breadcrumb: '复制AQL',
              },
              {
                path: '/quality/qualityModeling/aql/national',
                component: QualityAQLNational,
                auth: authDict.customizedaql_standard,
                breadcrumb: '国标AQL',
              },
            ],
          },
          {
            path: '/quality/qualityModeling/qcDefectReason',
            menu: '不良原因',
            component: QcDefectReasonList,
            auth: authDict.causeofdefect_view,
            breadcrumb: '不良原因',
            logPage: true,
            children: [
              {
                path: '/quality/qualityModeling/qcDefectReason/:id/detail',
                component: QcDefectReasonPage,
                auth: authDict.causeofdefect_detail,
                breadcrumb: '不良原因详情',
              },
              {
                path: '/quality/qualityModeling/qcDefectReason/:id/edit',
                component: QcDefectReasonEdit,
                auth: authDict.causeofdefect_edit,
                breadcrumb: '编辑不良原因',
              },
              {
                path: '/quality/qualityModeling/qcDefectReason/create',
                component: QcDefectReasonEdit,
                auth: authDict.causeofdefect_edit,
                breadcrumb: '创建不良原因',
              },
            ],
          },
          {
            path: '/quality/qualityModeling/qcDefectRank',
            menu: '不良等级',
            component: QcDefectRankList,
            auth: authDict.defectlevel_view,
            breadcrumb: '不良等级',
            logPage: true,
            children: [
              {
                path: '/quality/qualityModeling/qcDefectRank/:id/detail',
                component: QcDefectRankPage,
                auth: authDict.defectlevel_detail,
                breadcrumb: '不良等级详情',
              },
              {
                path: '/quality/qualityModeling/qcDefectRank/create',
                component: QcDefectRankEdit,
                auth: authDict.defectlevel_edit,
                breadcrumb: '创建不良等级',
              },
              {
                path: '/quality/qualityModeling/qcDefectRank/:id/edit',
                component: QcDefectRankEdit,
                auth: authDict.defectlevel_edit,
                breadcrumb: '编辑不良等级',
              },
            ],
          },
          {
            path: '/quality/qualityModeling/qcConfig',
            menu: '检验方案',
            component: QcConfig,
            auth: authDict.qualityinspectionscheme_view,
            breadcrumb: '检验方案',
            logPage: true,
            children: [
              {
                path: '/quality/qualityModeling/qcConfig/detail',
                menu: '',
                component: QcConfigDetail,
                auth: authDict.qualityadjustmentrecord_detail,
                breadcrumb: '检验方案详情',
              },
              {
                path: '/quality/qualityModeling/qcConfig/create',
                menu: '',
                component: QcConfigCreateAndEdit,
                auth: authDict.qualityinspectionscheme_add,
                breadcrumb: '创建检验方案',
              },
              {
                path: '/quality/qualityModeling/qcConfig/edit',
                menu: '',
                component: QcConfigCreateAndEdit,
                auth: authDict.qualityinspectionscheme_edit,
                breadcrumb: '编辑检验方案',
              },
              {
                path: '/quality/qualityModeling/qcConfig/copy',
                menu: '',
                component: QcConfigCreateAndEdit,
                auth: authDict.qualityinspectionscheme_add,
                breadcrumb: '复制检验方案',
              },
            ],
          },
        ],
      },
    ],
  },
];
