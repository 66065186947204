/**
 * @page 布局分配
 */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Form, message, TableColumnProps } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';
import { BlTable, SearchSelect } from 'src/components';
import lookup from 'src/dict';
import { useBlocker, qs } from 'src/utils';
import {
  fetchLayoutRoleList,
  fetchLayoutRoleSave,
  FetchLayoutRoleSaveRequest,
} from 'src/api/ytt/layout-domain';
import { backToSettings } from '../navigation';
import EditableText from 'src/components/editableText';
import { PageType } from 'src/dict/customLayout';

const ROOT = 'root';

let currentPage = 1;
let currentSize = 20;

const roleColumn = {
  title: '角色',
  key: 'role',
  width: 220,
  fixed: 'left',
  render: (__: unknown, ___: unknown, index: number) => (
    <Form.Item
      name={[ROOT, (currentPage - 1) * currentSize + index, 0, 'name']}
      style={{ marginBottom: 0 }}
    >
      <EditableText canEdit={false} />
    </Form.Item>
  ),
};
const getLayoutEditCellRenderer =
  (objectCode: string, type: PageType, colIndex: number) =>
  (__: unknown, ___: unknown, index: number) =>
    (
      <Form.Item
        name={[ROOT, (currentPage - 1) * currentSize + index, colIndex]}
        style={{ marginBottom: 0 }}
        rules={[{ required: true, message: '默认不可为空' }]}
      >
        <SearchSelect
          fetchType="customizedLayout"
          mode="multiple"
          params={{ objectCode, type }}
          labelInValue
        />
      </Form.Item>
    );

type LayoutRoleBinds = NonNullable<
  FetchLayoutRoleSaveRequest['layoutRoles']
>[number]['layoutRoleBinds'];

export const LayoutAssign = () => {
  const [loadingType, setLoadingType] = useState<'loading' | 'submitting' | null>(null);
  const [columns, setColumns] = useState<TableColumnProps<any>[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]); // 只控制渲染行数，行内数据由form控制
  const { objectId } = useParams<{ objectId: string }>();
  const [form] = Form.useForm();
  const { resetForm, history } = useBlocker(form);

  const { tabKey, objectCode } = qs.parse();
  const pageType = _.toNumber(tabKey);

  const block: DataFormLayoutInfoBlock = {
    title: `分配${lookup('customLayout.pageType', pageType)}布局`,
    items: [
      {
        isFullLine: true,
        render: () => (
          <BlTable
            columns={columns}
            dataSource={dataSource}
            pagination={{
              showQuickJumper: true,
              onChange(page, size) {
                currentPage = page;
                currentSize = size ?? 20;
                setLoadingType(null); // 当做forceUpdate用
              },
              style: { marginBottom: 0 },
            }}
            scroll={{ x: 'max-content', y: screen.height - 460 }}
          />
        ),
      },
    ],
  };

  const onCancel = () => history.push(backToSettings(objectId));

  const onSubmit = () => {
    try {
      form.validateFields().then(() => {
        const values = form.getFieldsValue(true);
        // 重新按业务类型将数据分组
        const layoutRoles = columns.slice(1).map(({ title, key }, index) => {
          const layoutRoleBinds: LayoutRoleBinds = [];

          (values[ROOT] as any[]).forEach((row) => {
            const roleId = row[0].id;
            const layouts = row.slice(1)[index];

            if (_.isArray(layouts)) {
              layoutRoleBinds.push({
                roleId,
                layoutIds: _.map(layouts, 'value'),
              });
            }
          });

          return {
            bizType: key as string,
            bizTypeName: title as string,
            layoutRoleBinds,
          };
        });

        setLoadingType('submitting');
        fetchLayoutRoleSave({ objectCode, type: pageType, layoutRoles })
          .then(() => {
            message.success('分配完成。');
            resetForm(values);
            onCancel();
          })
          .catch(() => setLoadingType(null));
      });
    } catch (error) {
      console.log('validate assign form error: ', error);
      return null;
    }
  };

  useEffect(() => {
    currentPage = 1;
    currentSize = 20;
    setLoadingType('loading');
    fetchLayoutRoleList({ objectCode, type: pageType })
      .then(({ data }) => {
        if (_.isEmpty(data)) {
          return;
        }

        // 有多少业务类型，就有多少列（除了角色列）
        const bizColumns = data!.map(({ bizInfo }, index) => ({
          title: bizInfo?.bizTypeName,
          key: bizInfo?.bizTypeCode,
          width: 200,
          render: getLayoutEditCellRenderer(objectCode, pageType, index + 1),
        }));
        // 有多少个角色，就有多少行
        const roleList = data![0].layoutRoleBinds!.map(({ roleId, roleName }) => ({
          id: roleId,
          name: roleName,
        }));
        // 表单的数据最外层必须是一个对象，如果是数组会被强制改成对象，故加一个层级
        const formInitialvalues: any = { [ROOT]: [] };

        roleList.forEach((role) => {
          const row: any[] = [role];

          bizColumns.forEach((__, index) => {
            const bind = _.find(data![index].layoutRoleBinds, { roleId: role.id });

            row.push(
              bind?.layoutByRoles?.map((item) => ({
                label: item?.layoutName,
                value: item?.layoutId,
              })),
            );
          });
          formInitialvalues[ROOT].push(row);
        });
        setColumns([roleColumn, ...bizColumns]);
        setDataSource(roleList);
        resetForm(formInitialvalues);
      })
      .finally(() => setLoadingType(null));
  }, []);

  return (
    <DataFormLayout
      form={form}
      info={[block]}
      loading={loadingType === 'loading'}
      okText="保存"
      onFinish={onSubmit}
      confirmLoading={loadingType === 'submitting'}
      onCancel={onCancel}
    />
  );
};
