import UserSettings from './userSettings';
const UserSettingRoute = [
  {
    path: '/userSetting',
    menu: '',
    breadcrumb: '个人设置',
    component: UserSettings,
  },
];

export default UserSettingRoute;
