import React, { useState } from 'react';
import { message, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { RecordListLayout } from 'layout';
import { SingleOperationLogComponent, Tooltip } from 'src/components';
import { replaceSign } from 'src/utils/constants';
import _ from 'lodash';
import { PurchaseAuditListProps } from '../interface';
import {
  dataColumnsForPurchaseRequisition,
  purchaseRequisitionFilterList,
} from '../components/columns';
import {
  fetchPurchaseAuditAccept,
  fetchPurchaseAuditAcceptCheck,
  fetchPurchaseAuditAutoCreateMaterialRegister,
  fetchPurchaseAuditDecline,
  fetchPurchaseAuditList,
} from 'src/api/ytt/supply-chain-domain/purchase_audit';
import { toCustomerMaterialRegist_URL, toPurchaseDetail } from '../navigation';
import { PurchaseRequisitionStatus } from 'src/dict/sales';
import { gcObject, gcTime } from 'src/utils';
import lookup from 'src/dict';
import authDict from 'src/utils/auth/authDict';
import { CRUD } from 'src/dict/common';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { hasAuth } from 'src/utils/auth';

/**
 * 接受操作
 */
const handleAccept = async (record: PurchaseAuditListProps, callback: () => void) => {
  try {
    const { id } = record;
    const acceptFn = async () => {
      const { code } = await fetchPurchaseAuditAccept({
        purchaseAuditNoteId: id as number,
      });

      if (code == 200) {
        message.success('确认接受成功');
        callback();
      } else {
        message.error('接受失败');
      }
    };
    const checkFn = async () => {
      const { data } = await fetchPurchaseAuditAcceptCheck({
        purchaseAuditNoteId: id as number,
      });

      return data;
    };
    /**
     * 注册物料
     */
    const registMaterialsFn = async () => {
      const { data } = await fetchPurchaseAuditAutoCreateMaterialRegister({
        purchaseAuditNoteId: id as number,
      });

      return data;
    };

    const checkConfig = {
      title: '提示',
      content: '确认接受邀请客户发起的采购申请吗？确认接收后将生成销售订单且不支持删除销售订单',
      width: 436,
      onOk: () => {
        checkFn().then((data) => {
          // 判断是否需要创建物料注册邀请
          if (data === false) {
            // 需要
            Modal.confirm(materialRegistConfig);
          } else {
            acceptFn();
          }
        });
      },
      okText: '确认接受',
      onCancel: () => {},
      cancelText: '再想想',
      icon: null,
      closable: true,
    };
    const materialRegistConfig = {
      title: '提示',
      content:
        '当前申请单有部分客户物料未注册或正在注册中，已对未注册物料发起注册，请在「客户-查看物料注册邀请」中审核注册物料',
      width: 436,
      onOk: () => {
        registMaterialsFn().then(() => {
          /**
           * 跳转到
           */
          window.open(toCustomerMaterialRegist_URL());
          callback();
        });
      },
      okText: '审核物料注册邀请',
      okButtonProps: {
        style: {
          display: !hasAuth(authDict.customermaterialregisteredaudit_reg_list_material) && 'none',
        },
      },
      cancelButtonProps: {
        style: {
          display: 'none',
        },
      },
      icon: null,
      closable: true,
    };

    Modal.confirm(checkConfig);
  } catch (error) {
    console.log('error', error);
  }
};
/**
 * 拒绝操作
 */
const handleReject = (record: PurchaseAuditListProps, callback: () => void) => {
  try {
    const { id } = record;
    const rejectFn = async () => {
      const { code } = await fetchPurchaseAuditDecline({
        purchaseAuditNoteId: id as number,
      });

      if (code == 200) {
        message.success('确认拒绝成功');
      } else {
        message.error('拒绝失败');
      }
    };
    const config = {
      title: '提示',
      content: '确认要拒绝邀请客户发起的采购申请吗？确认后将给客户发送拒绝通知',
      width: 436,
      onOk: async () => {
        await rejectFn();
        callback();
      },
      okText: '确认拒绝',
      onCancel: () => {},
      cancelText: '再想想',
      icon: null,
      closable: true,
    };

    Modal.confirm(config);
  } catch (error) {
    console.log('error', error);
  }
};
const PurchaseRequisitionList = () => {
  const history = useHistory();
  const [refreshMarker, setRefreshMarker] = useState(0);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  /**
   * Columns
   */
  const dataColumns = [
    {
      title: '编号',
      dataIndex: 'code',
      sorter: true,
      width: 200,
      render: (code: string, record: PurchaseAuditListProps, index: number, config: any) => {
        return (
          <a
            onClick={() => {
              history.push(toPurchaseDetail(Number(record?.id)));
            }}
          >
            <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
          </a>
        );
      },
    },

    ...dataColumnsForPurchaseRequisition,
  ];
  /**
   * 列操作 ...
   */
  const getOperationList = (record: PurchaseAuditListProps) => {
    const { id } = record;

    return [
      {
        title: lookup('common.crud', CRUD.view),
        auth: authDict.purchaseauditnote_detail,
        onClick: () => {
          history.push(toPurchaseDetail(Number(record?.id)));
        },
      },
      {
        title: '接受',
        auth: authDict.purchaseauditnote_accept,
        disabled:
          record?.status === PurchaseRequisitionStatus.accepted ||
          record?.status === PurchaseRequisitionStatus.rejected,
        onClick: () => {
          handleAccept(record, () => {
            setRefreshMarker(new Date().getTime());
          });
        },
      },
      {
        title: '拒绝',
        auth: authDict.purchaseauditnote_decline,
        disabled:
          record?.status === PurchaseRequisitionStatus.accepted ||
          record?.status === PurchaseRequisitionStatus.rejected,
        onClick: () => {
          handleReject(record, () => {
            setRefreshMarker(new Date().getTime());
          });
        },
      },
      {
        title: '操作记录',
        auth: authDict.purchaseauditnote_operrecord,
        onClick: () => {
          setId(id!);
          setVisibleLog(true);
        },
      },
    ];
  };
  /**
   * 传给接口数据处理函数
   */
  const formatDataToQuery = (params: any) => {
    const { operatorIds, createdAt, updatedAt, customerIds, ...rest } = params;

    const [createdAtFrom, createdAtTo] = gcTime.formatRangeUnixToInt(createdAt);
    const [updatedAtFrom, updatedAtTo] = gcTime.formatRangeUnixToInt(updatedAt);
    const options = {
      createdAtFrom,
      createdAtTo,
      updatedAtFrom,
      updatedAtTo,
      operatorIds: operatorIds?.length ? _.map(operatorIds, 'value') : null,
      customerIds: customerIds?.length ? _.map(customerIds, 'value') : null,
      ...rest,
    };

    return gcObject.filterEmptyProperty(options);
  };
  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (param: any) => {
    const { createdAt, updatedAt, ...rest } = param;

    const result = {
      ...rest,
      createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
      updatedAt: gcTime.formatRangeTimeToMoment(updatedAt || []),
    };

    return gcObject.filterEmptyProperty(result);
  };

  return (
    <>
      <RecordListLayout<PurchaseAuditListProps>
        columns={dataColumns}
        filterList={purchaseRequisitionFilterList}
        rowKey={(record) => record?.id as number}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        configcacheKey="purchaseRequisitionList"
        requestFn={fetchPurchaseAuditList}
        useIndex={false}
        refreshMarker={refreshMarker}
        placeholder="请输入编号、客户编号、客户名称"
        getOperationList={getOperationList}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={id as number}
          objectCode={OBJECT_OF_CODE.purchaseAuditNote}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export { handleAccept, handleReject };
export default PurchaseRequisitionList;
