import { useEffect, useState } from 'react';
import { Form, message } from 'antd';
import _, { isEmpty } from 'lodash';
import { DataFormLayoutInfoBlock, DataFormLayout } from 'layout';
import BcUpload, { FileUploaderProps } from '../../../BcUpload';
import ExistFileItem from './existFile';
import { ContainerOutlined } from '@ant-design/icons';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { gcArray } from 'utils';
import { fetchFileBindDep } from 'src/api/ytt/holyfile-domain';
import { fetchDepartmentListChild } from 'src/api/ytt/user-domain/user';
import React from 'react';

const MAX_SIZE = 100;
interface Props {
  onCancel?: () => void;
  getFormData?: (data: any) => void;
  uploadConfig?: FileUploaderProps;
}

const BindForm = (props: Props) => {
  const { onCancel, getFormData, uploadConfig = {} } = props;
  const [form] = Form.useForm();
  const [rootDepartmentId, setRootDepartmentId] = React.useState<any>();

  useEffect(() => {
    fetchDepartmentListChild({}).then((rootDepartment) => {
      setRootDepartmentId(rootDepartment?.data?.[0]?.id);
      // form.setFields([
      //   {
      //     name: ['visibleDepList'],
      //     value: [{ label: '全公司', value: rootDepartment?.data?.[0]?.id }],
      //   },
      // ]);
    });
  }, []);

  const existFileItem: DataFormLayoutInfoBlock = {
    title: '选择已上传的文件',
    items: [
      {
        label: '',
        render: () => <ExistFileItem />,
      },
    ],
  };
  const uploadFileItem: DataFormLayoutInfoBlock = {
    title: '上传新文件',
    items: [
      {
        label: '',
        name: 'uploadFile',
        render: () => (
          <BcUpload
            totalMaxSize={MAX_SIZE}
            limit="attach"
            autoDelErrorFile
            draggable
            multiple
            {...uploadConfig}
          >
            <div
              style={{
                cursor: 'pointer',
                padding: '40px 50px',
                textAlign: 'center',
                borderRadius: 2,
              }}
            >
              <ContainerOutlined style={{ fontSize: '30px', marginBottom: 16 }} />
              <h4>点击或将文件拖拽到这里上传</h4>
              <p>
                仅支持上传 JPG/PNG/JPEG/{uploadConfig?.limit === undefined ? 'GIF/' : ''}
                PDF/XLSX/RAR/TXT/DOC/DOCX/ZIP/MP4/AVI/MOV 格式的文件；仅JPG/PNG/JPEG/
                {uploadConfig?.limit === undefined ? 'GIF/' : ''}
                PDF格式的文件支持预览；总文件大小限制不能超过{uploadConfig?.totalMaxSize || 100}M.
              </p>
            </div>
          </BcUpload>
        ),
      },
    ],
  };
  const depItem: DataFormLayoutInfoBlock = {
    title: '设置可见部门',
    items: [
      {
        label: '可见部门',
        name: 'visibleDepList',
        rules: [{ required: true, message: '请选择部门' }],
        render: () => (
          <UserOrDepartmentSelectWithDialog placeholder="请选择" isMultiple defaultChecked={true} />
        ),
      },
    ],
  };
  const formatterExistFile = (list?: any[]) => {
    if (!list) {
      return [];
    }
    const existFiles = _.map(list, 'value');

    return existFiles.map((item: string) => {
      const value = item ? JSON.parse(item) : {};

      return value.id;
    });
  };

  const onSubmit = async () => {
    const values = await form?.validateFields();
    const { existFileIds, uploadFile, visibleDepList } = values;

    if (gcArray.isEmpty(existFileIds) && gcArray.isEmpty(uploadFile)) {
      message.warn('请选择文件');
      return;
    }
    // if (uploadFile?.find((item: any) => item.status === 'uploading')) {
    //   message.warn('文件正在上传');
    //   return;
    // }
    const existFile = _.compact(formatterExistFile(existFileIds));
    const fileIds = existFile.concat(uploadFile || []);

    // if (!isEmpty(visibleDepList)) {
    //   const res = await fetchFileBindDep({
    //     visibleDepList: _.map(visibleDepList, 'value'),
    //     fileIds,
    //   });

    //   if (res && res.code === 200) {
    //     message.success('添加成功');
    //   }
    // }

    console.log(rootDepartmentId);
    if (rootDepartmentId) {
      console.log('???');
      const res = await fetchFileBindDep({
        visibleDepList: [rootDepartmentId],
        fileIds,
      });

      if (res && res.code === 200) {
        message.success('添加成功');
      }
    }

    getFormData?.({
      fileIds,
      visibleDepList,
    });
    onCancel?.();
  };

  return (
    <DataFormLayout
      title="添加文件"
      form={form}
      info={[existFileItem, uploadFileItem]}
      onCancel={onCancel}
      onFinish={onSubmit}
    />
  );
};

export default BindForm;
