/**
 * @mappings 通用的名-值映射表
 */

import { AssociationType, AuditStatus, RegistrationStatus, TabType } from '.';
import { mappingsFactory } from '../utils';

export const tabType = mappingsFactory(
  ['客户视图', TabType.customer],
  ['已注册物料视图', TabType.registeredMaterials],
);
export const registrationStatus = mappingsFactory(
  ['已注册', RegistrationStatus.registered],
  // ['注册中', RegistrationStatus.registering],
  ['未注册', RegistrationStatus.unregistered],
);
export const auditStatus = mappingsFactory(
  ['待处理', AuditStatus.pending],
  ['已接受', AuditStatus.accepted],
  ['已拒绝', AuditStatus.rejected],
);
export const associationType = mappingsFactory(
  ['与已有客户进行关联', AssociationType.withExistinging],
  ['新建客户并关联', AssociationType.createNew],
);
