import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Modal } from 'antd';

import { BlTable } from 'src/components';
import { ResourceListItemType } from 'src/page/resource/definitions/index.d';
import { fetchInstrumentDeepChildren } from 'src/api/ytt/resource-domain/energyMeter';

interface DetailProps {
  id?: number;
  visible: boolean;
  onCancel: () => void;
}

const DetailModal = (props: DetailProps) => {
  const { onCancel, visible, id } = props;

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<ResourceListItemType[]>([]);

  const getDataSource = async (id?: number) => {
    if (id) {
      try {
        setLoading(true);

        const res = await fetchInstrumentDeepChildren({ id });

        return res.data;
      } finally {
        setLoading(false);
      }
    }

    return {};
  };

  useEffect(() => {
    if (id) {
      getDataSource(id).then((data) => {
        if (data) setDataSource(data ? [data] : []);
      });
    }
  }, [id]);

  return (
    <Modal title="子设备详情" visible={visible} onCancel={onCancel} footer={false}>
      {!_.isEmpty(dataSource) ? (
        <BlTable
          key={'real'}
          loading={loading}
          rowKey="id"
          dataSource={dataSource}
          scroll={{ y: 500 }}
          pagination={false}
          expandable={{
            defaultExpandAllRows: true,
          }}
          columns={[
            {
              title: '设备',
              width: 300,
              dataIndex: 'name',
            },
            {
              title: '层级',
              dataIndex: 'level',
              width: 100,
            },
          ]}
        />
      ) : (
        <BlTable key={'sham'} loading={loading} dataSource={[]} columns={[]} />
      )}
    </Modal>
  );
};

export default DetailModal;
