import { useEffect, useState } from 'react';
import { FormInstance, message, Modal, Tag } from 'antd';
import { fetchPurchaseOrderListMaterial } from 'src/api/ytt/order-domain/purchaseOrder';
import { FilterItem, RecordListLayout } from 'src/layout';
import styles from './styles.module.scss';
import { fieldTypeList } from 'src/utils';
import _Array from 'src/utils/dataTypes/array';
import { purchaseSource } from 'src/dict/purchase/mappings';
import lookup from 'src/dict';
import { Attribute } from '../interface';
import { renderAmount } from '../utils';
import { SearchSelect, TagList } from 'src/components';
import { PurchaseExecStatus } from 'src/dict/purchase';
import { replaceSign } from 'src/utils/constants';
import { map } from 'lodash';
import _Time from 'src/utils/dataTypes/time';
import _String from 'src/utils/dataTypes/string';

interface Props {
  visible: Boolean;
  onCancel: () => void;
  handleFinish: (rows?: any[], selectedKeys?: number[]) => void;
  addForm: FormInstance;
  selectedKeys: number[];
}

export default function BatchAddMaterials({
  visible,
  onCancel,
  handleFinish,
  addForm,
  selectedKeys,
}: Props) {
  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>(selectedKeys);
  const [selectedRows, setSelectRows] = useState<any[]>();

  const materialColumn = [
    {
      title: '编号',
      dataIndex: 'code',
      width: 150,
      sorter: true,
    },
    {
      title: '供应商编号',
      dataIndex: ['supplier', 'code'],
      width: 150,
    },
    {
      title: '供应商名称',
      dataIndex: ['supplier', 'name'],
      width: 150,
    },
    {
      title: '来源',
      dataIndex: 'source',
      width: 150,
      render: lookup('purchase.purchaseSource'),
    },
    {
      title: '状态',
      dataIndex: 'execStatus',
      width: 150,
      render: lookup('purchase.purchaseExecStatus'),
    },
    {
      title: '行号',
      dataIndex: 'seqNum',
      width: 150,
    },
    {
      title: '物料编号',
      dataIndex: ['material', 'baseInfo', 'code'],
      width: 150,
    },
    {
      title: '物料名称',
      dataIndex: ['material', 'baseInfo', 'name'],
      width: 150,
    },
    {
      title: '物料属性',
      dataIndex: ['material', 'attribute'],
      width: 250,
      render: (attribute: Attribute[]) => {
        if (_Array.isEmpty(attribute)) {
          return replaceSign;
        }
        return (
          <TagList
            dataSource={_.map(attribute, ({ id, name, attributeItem }) => ({
              label: `${name}:${attributeItem?.content}`,
              value: id,
            }))}
          />
        );
      },
    },
    {
      title: '需求数',
      dataIndex: 'demandAmount',
      width: 150,
      render: renderAmount,
    },
    {
      title: '需求日期',
      dataIndex: 'demandTime',
      width: 150,
      sorter: true,
      render: (demandTime: number) => _Time.format(demandTime),
    },
    {
      title: '计划收货数',
      dataIndex: 'planReceivedAmount',
      width: 150,
      render: renderAmount,
    },
    {
      title: '收货发出数',
      dataIndex: 'supplierDeliverAmount',
      width: 150,
      render: renderAmount,
    },
    {
      title: '收货接收数',
      dataIndex: 'receivedAmount',
      width: 150,
      render: renderAmount,
    },
    {
      title: '计划退货数',
      dataIndex: 'planReturnAmount',
      width: 150,
      render: renderAmount,
    },
    {
      title: '退货发出数',
      dataIndex: 'returnDeliverAmount',
      width: 150,
      render: renderAmount,
    },
    {
      title: '退货接收数',
      dataIndex: 'returnReceivedAmount',
      width: 150,
      render: renderAmount,
    },
  ];

  const detailFilterList: FilterItem[] = [
    {
      label: '编号',
      name: 'code',
      type: fieldTypeList.text,
    },
    {
      label: '供应商编号',
      name: 'supplierCode',
      type: fieldTypeList.text,
    },
    {
      label: '供应商名称',
      name: 'supplierName',
      type: fieldTypeList.text,
    },
    {
      label: '来源',
      name: 'sourceList',
      type: fieldTypeList.multiSelect,
      selectProps: {
        options: purchaseSource,
        mode: 'multiple',
      },
    },
    {
      label: '物料',
      name: 'materialIds',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect placeholder={'请选择物料'} mode="multiple" fetchType="material" />,
    },
    {
      label: '需求日期',
      name: 'demandTime',
      type: fieldTypeList.date,
    },
  ];

  useEffect(() => {
    setSelectRowKeys(selectedKeys);
  }, []);

  const formatDataToQuery = (params: any) => {
    const { sorter, supplierCode, supplierName, code, materialIds, demandTime, ...rest } = params;

    const relParams = {
      ...rest,
    };

    if (demandTime) {
      relParams.demandTimeFrom = Number(_Time.formatToUnix(demandTime[0]));
      relParams.demandTimeTo = Number(_Time.formatToUnix(demandTime[1]));
    }

    if (!_String.isEmpty(code)) {
      relParams.code = code;
    }
    if (!_String.isEmpty(supplierCode)) {
      relParams.supplierCode = supplierCode;
    }
    if (!_String.isEmpty(supplierName)) {
      relParams.supplierName = supplierName;
    }

    if (!_Array.isEmpty(materialIds)) {
      relParams.materialIds = map(materialIds, 'value');
    }

    if (sorter) {
      relParams.sorter = _.map(sorter, (node: any) => {
        return {
          order: node?.order,
          field: node?.field,
        };
      });
    }

    return relParams;
  };

  return (
    <Modal
      visible={Boolean(visible)}
      title="按采购订单添加物料"
      cancelText="暂不添加"
      okText="添加"
      onCancel={() => {
        typeof onCancel === 'function' && onCancel();
        addForm.resetFields();
      }}
      width={1600}
      onOk={() => {
        if (_Array.isEmpty(selectedRows) && _Array.isEmpty(selectedRowKeys)) {
          message.error('请选中物料后再添加');
        } else {
          handleFinish(selectedRows, selectedRowKeys);
        }
      }}
      className={styles.addMaterialModal}
      destroyOnClose
      keyboard={false}
      maskClosable={false}
    >
      <RecordListLayout<any>
        scroll={{ x: true, y: 400 }}
        columns={materialColumn}
        formatDataToQuery={formatDataToQuery}
        rowKey={(record: any) => record?.id}
        filterList={detailFilterList}
        filterContaniner={false}
        useFilterWithUrl={false}
        selectedRowKeys={selectedRowKeys}
        configcacheKey="batchAddMaterial"
        onSelectedRowKeys={(selectKey: any, selectRows?: any[]) => {
          setSelectRowKeys(selectKey);
          selectRows && setSelectRows(selectRows);
        }}
        requestFn={(params: any) =>
          fetchPurchaseOrderListMaterial({
            execStatusList: [PurchaseExecStatus.executing],
            ...params,
          })
        }
      />
    </Modal>
  );
}
