/**
 * 自定义菜单
 */
import { useReducer, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { TabList } from 'src/components';
import { RecordListLayout } from 'src/layout';
import {
  fetchStandardBizObjectMenuList,
  fetchStandardBizObjectMenuDelete,
  FetchStandardBizObjectMenuListResponse,
} from 'ytt/metadata-domain/customMenu';
import authDict from 'src/utils/auth';
import SortModal from './sort';
import { path } from './constants';
import type { RecordListLayoutProps, OperationListItem } from '@blacklake-web/layout';

type ListItem = Required<FetchStandardBizObjectMenuListResponse>['data']['list'][number];

const tabData = [
  {
    tab: '自定义对象',
    key: 'customObject',
  },
];
const placeholder = '请输入菜单名称、编号';
const columns = [
  {
    title: '菜单名称',
    dataIndex: 'menuName',
    width: 300,
    sorter: true,
  },
  {
    title: '菜单编号',
    dataIndex: 'menuCode',
    width: 200,
    sorter: true,
  },
  {
    title: '菜单说明',
    dataIndex: 'menuDesc',
    width: 200,
  },
];

const MenuList = () => {
  const history = useHistory();
  const [refreshMarker, refresh] = useReducer(() => Date.now(), 0);
  const [sortModalVisible, setSortModalVisible] = useState(false);

  const mainMenu = [
    {
      title: '前台菜单排序',
      icon: 'icona-xianxingpaixu',
      auth: authDict.customMenu_manage_menu_display,
      onClick: () => {
        setSortModalVisible(true);
      },
    },
    {
      title: '新建菜单',
      icon: 'iconxinjiantianjia',
      auth: authDict.customMenu_add,
      onClick: () => {
        history.push(path.create);
      },
      items: [],
    },
  ];

  const getOperationList = (record: ListItem): OperationListItem[] => {
    const { id } = record;

    return [
      {
        title: '编辑',
        auth: authDict.customMenu_edit,
        onClick: () => history.push(generatePath(path.edit, { id })),
      },
      {
        title: '复制',
        auth: authDict.customMenu_add,
        onClick: () => history.push(generatePath(path.copy, { id })),
      },
      {
        title: '删除',
        auth: authDict.customMenu_delete,
        reasonconfirm: true,
        onClick: (reason: string) => {
          // @ts-ignore
          fetchStandardBizObjectMenuDelete({ id, reason }).then(() => {
            message.success('删除完成。');
            refresh();
          });
        },
      },
    ];
  };

  const commonProps: RecordListLayoutProps<any> = {
    columns,
    getOperationList,
    mainMenu,
    maxOperationCount: 3,
    placeholder,
    requestFn: fetchStandardBizObjectMenuList,
    refreshMarker,
  };

  const tabList = tabData.map((item) => ({
    ...item,
    component: (
      <>
        <RecordListLayout {...commonProps} configcacheKey={`interfaceManagement-${item.key}`} />
        <SortModal visible={sortModalVisible} hide={() => setSortModalVisible(false)} />
      </>
    ),
  }));

  return (
    <TabList type="line" tabList={tabList} defaultKey={tabData[0].key} storageKey="customMenu" />
  );
};

export default MenuList;
