import { BlTable } from '@blacklake-web/component';
import { TagList } from 'components';
import { getMaterialAttrs } from 'src/entity/material';
import React from 'react';
import lookup from 'src/dict';

interface Props {
  alternativeMaterials: any;
}

const AlternativeMaterialDetailTable = (props: Props) => {
  const getColumns = () => {
    return [
      {
        title: '序号',
        dataIndex: 'lineNo',
        width: 100,
        fixed: 'left',
      },
      {
        title: '优先级',
        dataIndex: 'priority',
        width: 100,
        fixed: 'left',
      },
      {
        title: '物料编号',
        dataIndex: ['material', 'baseInfo', 'code'],
        key: 'materialId',
        width: 200,
        fixed: 'left',
      },
      {
        title: '物料名称',
        dataIndex: ['material', 'baseInfo', 'name'],
        width: 200,
      },
      {
        title: '物料属性',
        dataIndex: ['material'],
        width: 200,
        render: (text: any) => <TagList dataSource={getMaterialAttrs(text)} />,
      },
      {
        title: '物料分类',
        dataIndex: ['material', 'category', 'name'],
        width: 150,
      },
      {
        title: '物料规格',
        dataIndex: ['material', 'baseInfo', 'specification'],
        width: 150,
      },
      {
        title: '使用比例',
        dataIndex: 'useRatio',
        width: 120,
      },
      {
        title: '数量:分子',
        dataIndex: 'amountNumerator',
        width: 150,
      },
      {
        title: '数量:分母',
        dataIndex: 'amountDenominator',
        width: 150,
      },
      {
        title: '单位',
        dataIndex: ['unitName'],
        width: 150,
      },
      {
        title: '领料方式',
        dataIndex: 'pickMode',
        width: 150,
        // bom.pickMode
        render: (text: number) => {
          return lookup('bom.pickMode', text);
        },
      },
      {
        title: '投料质量状态',
        dataIndex: 'feedingQualityStatus',
        width: 200,
        render: (text: number[]) => {
          return text?.map((item) => lookup('material.feedingQualityStatus', item)).join(',');
        },
      },
      {
        title: '使用比例上限(%)',
        dataIndex: 'useUpperLimitRatio',
        width: 150,
      },
      {
        title: '行备注',
        dataIndex: 'remark',
        width: 300,
      },
    ];
  };

  return (
    <BlTable
      dataSource={props.alternativeMaterials}
      columns={getColumns()}
      rowKey="lineNo"
      scroll={{ x: 'max-content' }}
    />
  );
};

export default AlternativeMaterialDetailTable;
