import React from 'react';
import { DetailLayoutForDrawer } from 'src/layout';
import DetailBaseInfo from './components/detailBaseInfo';

interface DetailProps {
  visible: boolean;
  closeDetail: () => void;
  checkClassId: number;
}

const CheckItemClassificationDetail = (props: DetailProps) => {
  const { visible, closeDetail, checkClassId } = props;

  return (
    <DetailLayoutForDrawer
      visible={visible}
      onClose={closeDetail}
      width={680}
      content={<DetailBaseInfo checkClassId={checkClassId} />}
    />
  );
};

export default CheckItemClassificationDetail;
