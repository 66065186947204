import { DataFormLayout, DataFormLayoutInfoBlock, DetailLayout } from 'layout';
import { BlSortFormList } from 'src/components';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { Form, Input } from 'antd';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { appDict, appEnum } from 'src/dict';
import { SearchSelect } from 'components';
import { fetchMaterialDomainCommV1CalculatorMultiply } from 'src/api/ytt/comm-material-domain';
import { request } from 'utils';
import _ from 'lodash';
import { validateDecimalLength } from 'src/page/warehouseManagement/utils';

const WriteOffCreate = forwardRef((props: any, ref) => {
  const { material, operateRecord, outboundOrder } = props;
  const [form] = Form.useForm();
  const [auxAmountMaxItem, setAuxAmountMaxItem] = useState<any[]>([]);
  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    // 字段项相同属性
    const getOptions = (field: any, fieldName: string) => ({
      fieldKey: [field.fieldKey, fieldName],
      name: [field.name, fieldName],
      validateFirst: true,
      validateTrigger: ['onChange', 'onBlur'],
      style: { marginBottom: '0' },
    });

    const commonColumns: ColumnProps<any & FormListFieldData>[] = [
      {
        dataIndex: 'lineNo',
        title: '行号',
        width: 100,
        render: (text: string, field) => (
          <div key={field.name}>
            {field.name + 1}
            <Form.Item {...getOptions(field, 'lineNo')} initialValue={field.name + 1} hidden>
              <Input />
            </Form.Item>
          </div>
        ),
      },
      {
        dataIndex: 'batchNo',
        title: '批次',
        width: 120,
        render: (text: any, field: any) => operateRecord[field.name]?.bizKsyAttr?.batchNo,
      },
      {
        dataIndex: 'storageLocationId',
        title: '仓储位置',
        width: 150,
        render: (text: string, field) => (
          <div key={field.name}>
            <Form.Item
              {...getOptions(field, 'storageLocationId')}
              rules={[{ required: true, message: '请选择仓储位置' }]}
              name={[field.name, 'inventoryKey', 'storageLocationId']}
            >
              <SearchSelect
                labelInValue
                searchFieldName="quickSearch"
                params={{
                  storageId: outboundOrder?.wareHouse?.id,
                  storageType: 0,
                  enableFlag: appEnum.Common.UsageStatus.enabled,
                }}
                fetchType="storageLocation"
              />
            </Form.Item>
          </div>
        ),
      },
      {
        dataIndex: 'qcStatus',
        title: '质量状态',
        width: 120,
        render: (text: any, field: any) =>
          _.find(appDict.bound.qualityStatus, ['value', operateRecord[field.name]?.qcStatus])
            ?.label,
      },
      {
        dataIndex: 'amount',
        title: '数量',
        width: 120,
        render: (text: any, field: any) => {
          return (
            <div key={field.name} style={{ display: 'flex' }}>
              <Form.Item
                {...getOptions(field, 'amount')}
                rules={[
                  { required: true, message: '请输入数量' },
                  { type: 'number', transform: (value) => Number(value), message: '请输入数字' },
                  {
                    type: 'number',
                    min: 0,
                    transform: (value) => Number(value),
                    message: '不能输入负数',
                  },
                  {
                    validator: validateDecimalLength(
                      operateRecord[field.name].amount?.amount?.unit?.precisionFigure || 0,
                    ),
                  },
                ]}
                name={[field.name, 'inventoryKey', 'opAmountRequestDTO', 'amount', 'amount']}
              >
                <Input style={{ width: 80 }} />
              </Form.Item>
              <span style={{ marginLeft: 5, whiteSpace: 'nowrap' }}>
                {operateRecord[field.name]?.amount?.amount?.unit?.name}
              </span>
            </div>
          );
        },
      },
    ];

    return _.concat(
      commonColumns,
      auxAmountMaxItem.map((item: any, i: number): any => {
        return {
          title: `辅助数量${i + 1}`,
          dataIndex: `amount${String(i)}`,
          width: 120,
          render: (text: any, field: any) => {
            if (_.isEmpty(operateRecord[field.name].amount?.auxAmountList)) {
              return null;
            }
            return (
              <div key={field.name} style={{ display: 'flex' }}>
                <Form.Item
                  {...getOptions(field, 'amount')}
                  style={{ marginBottom: 0 }}
                  rules={[
                    { required: true, message: '请输入数量' },
                    { type: 'number', transform: (value) => Number(value), message: '请输入数字' },
                    {
                      type: 'number',
                      min: 0,
                      transform: (value) => Number(value),
                      message: '不能输入负数',
                    },
                    { validator: validateDecimalLength(item?.unit?.precisionFigure || 0) },
                  ]}
                  name={[
                    field.name,
                    'inventoryKey',
                    'opAmountRequestDTO',
                    'auxAmounts',
                    i,
                    'amount',
                  ]}
                >
                  <Input style={{ width: 80 }} />
                </Form.Item>
                <span style={{ marginLeft: 5, whiteSpace: 'nowrap' }}>
                  {operateRecord[field.name]?.amount?.auxAmountList?.[i]?.unit?.name}
                </span>
              </div>
            );
          },
        };
      }),
    );
  };

  const baseInfo = {
    column: 2,
    title: '冲销信息',
    items: [
      {
        label: '出库单编号',
        dataIndex: 'code',
        render: () => outboundOrder.code,
      },
      {
        label: '行号',
        dataIndex: 'lineNo',
        render: () => material.lineNo,
      },
      {
        label: '冲销物料',
        dataIndex: 'material',
        render: () => material?.material?.baseInfo?.name,
      },
      {
        label: '仓库',
        dataIndex: 'wareHouse',
        render: () => outboundOrder?.wareHouse?.name,
      },
      {
        label: '客户',
        dataIndex: 'customer',
        render: () => outboundOrder?.customer?.name,
      },
      {
        label: '出库进度',
        dataIndex: 'doneAmount',
        render: () => `${material.doneAmount} / ${material.planAmount} ${material.unit?.name}`,
      },
      {
        label: '备注',
        dataIndex: 'remark',
        isFullLine: true,
        render: () => (
          <Form.Item name={'remark'} rules={[{ max: 1000, message: '不超过1000个字符' }]}>
            <Input.TextArea placeholder="请输入备注" showCount maxLength={1000} />
          </Form.Item>
        ),
      },
    ],
  };
  const recordInfo = {
    column: 2,
    title: '冲销库存列表',
    items: [
      {
        label: '',
        isFullLine: true,
        dataIndex: 'record',
        render: () => (
          <>
            <BlSortFormList
              isNeedAdd={false}
              form={form}
              name="list"
              renderColumns={() => getColumns()}
            />
          </>
        ),
      },
    ],
  };

  useEffect(() => {
    const maxItem = _.maxBy(operateRecord, (o: any) => o?.amount?.auxAmountList?.length);

    setAuxAmountMaxItem(maxItem?.amount?.auxAmountList || []);
    form.setFieldsValue({
      list: operateRecord.map((item: any) => {
        return {
          outboundOrderId: outboundOrder.id,
          outboundOrderRecordId: item.id,
          outboundOrderItemId: material.id,
          inventoryKey: {
            materialId: item?.material?.id,
            ...(item?.bizKsyAttr
              ? {
                  batchNoKey: {
                    batchNo: item?.bizKsyAttr?.batchNo,
                  },
                  customerId: item?.bizKsyAttr?.customer?.id,
                  inboundTime: item?.bizKsyAttr?.inboundTime,
                  ownerId: item?.bizKsyAttr?.ownerId,
                  productTime: item?.bizKsyAttr?.productTime,
                  supplierBatchNo: item?.bizKsyAttr?.supplierBatchNo,
                  supplierId: item?.bizKsyAttr?.supplier?.id,
                  validityPeriod: item?.bizKsyAttr?.validityPeriod,
                }
              : {}),
            qcStatus: item?.qcStatus,
            qrCode: item?.qrCode,
            storageLocationId: {
              label: item?.storageLocation?.storage?.name,
              value: item?.storageLocation?.storage?.id,
            },
            opAmountRequestDTO: {
              amount: {
                amount: item?.amount?.amount?.amount,
                unitId: item?.amount?.amount?.unit?.id,
              },
              // _amount: item?.amount?.amount?.amount,
              auxAmounts: item?.amount?.auxAmountList?.map((aux: any) => {
                return {
                  amount: aux.amount,
                  unitId: aux.unit?.id,
                };
              }),
            },
          },
        };
      }),
    });
  }, [operateRecord]);

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const values = await form.validateFields();

      return values;
    },
    getCustomFields: () => form.getFieldValue('customFields'),
  }));

  return (
    <Form form={form}>
      <DetailLayout title="" info={[baseInfo, recordInfo]} dataSource={{}} />
    </Form>
  );
});

export default WriteOffCreate;
