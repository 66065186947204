/**
 * @description 字段多级选择组件
 */

import { Select } from 'antd';
import _ from 'lodash';

import { BlCascader } from '@blacklake-web/component';
import { CascaderOption } from './useStandardBizToCascaderOption';

interface FieldIdCascaderProps {
  options?: CascaderOption[];
  loading?: boolean;
  disabled?: boolean;
  value?: any;
  onChange?: (selectedOptions: any) => void;
  onSelect?: (values: string[] | number[]) => void;
  placeholder?: string;
  allowClear?: boolean;
}

const FieldIdCascader: React.FC<FieldIdCascaderProps> = (props) => {
  const {
    allowClear = false,
    options,
    disabled,
    value,
    onChange,
    onSelect,
    loading = false,
    placeholder = '请选择',
  } = props;

  // disabled 的状态，仅在详情页面展示
  if (disabled) {
    if (_.isNil(value)) return <Select disabled />;
    const labels = _.map(typeof value === 'string' ? JSON.parse(value) : value, 'label');

    return <Select value={{ label: labels.join('.'), value: '' }} labelInValue disabled />;
  }

  let _value = value;
  const labels: string[] = _.map(value, 'label');

  if (_.isObject(_value?.[0])) {
    _value = _.map(_value, 'value');
  }

  // 作为「字段」选项时，字段的值只随着「业务对象」的值变化
  // options不需要实时获取，由外部传入
  return (
    <BlCascader
      allowClear={allowClear}
      changeOnSelect
      loading={loading}
      popupPlacement="bottomLeft"
      expandTrigger="hover"
      disabled={disabled}
      options={options}
      displayRender={(label) => {
        const isOptionReady = label[0] === labels[0];

        return isOptionReady ? label.join('.') : labels.join('.');
      }}
      value={_value}
      onChange={(values, selectedOptions) => {
        onChange?.(
          selectedOptions?.map(({ value, label, dataSource, info }) => ({
            value,
            label,
            dataSource,
            info,
          })),
        );
        onSelect?.(values as number[]);
      }}
      placeholder={placeholder}
    />
  );
};

export default FieldIdCascader;
