import { SearchSelect, TagList } from 'src/components';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import { fieldTypeList } from 'src/utils';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { ShipmentNoteDetailItemsResponseAmount } from '../interface';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { renderUser } from 'src/page/share/renderUser';
import { inoviceStatus, invoiceOrigin } from 'src/dict/invoice/mappings';
import lookup, { appEnum } from 'src/dict';
import _ from 'lodash';

// 订单明细展示列
export const dataColumnsForDetail = [
  {
    title: '物料编号',
    dataIndex: ['material', 'baseInfo', 'code'],
    width: 150,
    sorter: true,
    isFilter: true,
    type: fieldTypeList.text,
    filterName: 'materialCode',
    renderItem: <SearchSelect fetchType="material" labelPath="code" labelInValue mode="multiple" />,
  },
  {
    title: '物料名称',
    dataIndex: ['material', 'baseInfo', 'name'],
    isFilter: true,
    type: fieldTypeList.text,
    width: 150,
    filterName: 'materialName',
    renderItem: <SearchSelect fetchType="material" labelPath="name" labelInValue mode="multiple" />,
  },
  {
    title: '物料属性',
    dataIndex: ['material', 'attribute'],
    width: 150,
    render: (text: { name: string; attributeItem: { content: string }; id: number }[]) => {
      if (_.isEmpty(text)) return replaceSign;

      return (
        <TagList
          dataSource={_.map(text, ({ name, attributeItem, id }) => ({
            label: `${name}:${attributeItem.content}`,
            value: id,
          }))}
        />
      );
    },
  },
  {
    title: '发货单明细行行号',
    dataIndex: 'lineNo',
    width: 200,
    render: (lineNo: string) => lineNo ?? replaceSign,
  },
  {
    title: '计划发货数',
    dataIndex: ['plannedIssueAmount', 'amount'],
    width: 120,
    render: (amount: number) => amount ?? replaceSign,
  },
  {
    title: '仓库应发数',
    dataIndex: ['warehousePlannedIssueAmount', 'amount'],
    width: 120,
    render: (amount: number) => amount ?? replaceSign,
  },
  {
    title: '仓库已发数',
    dataIndex: ['warehouseIssueAmount', 'amount'],
    width: 120,
    render: (amount: number) => amount ?? replaceSign,
  },
  {
    title: '客户已收数',
    dataIndex: ['customerReceivedAmount', 'amount'],
    width: 120,
    render: (amount: string) => amount ?? replaceSign,
  },
  {
    title: '单位',
    dataIndex: ['customerReceivedAmount', 'unitName'],
    width: 120,
    render: (text: string) => text ?? replaceSign,
  },
  {
    title: '交货时间',
    dataIndex: 'deliveryDate',
    width: 200,
    isFilter: true,
    type: fieldTypeList.date,
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
    render: formatTimeForRender,
  },
  {
    title: '出库单编号',
    dataIndex: 'outboundOrderCode',
    width: 150,
    type: fieldTypeList.text,
    render: (outboundOrderCode: string) => outboundOrderCode ?? replaceSign,
  },
  {
    title: '出库单状态',
    dataIndex: 'outboundOrderStatus',
    width: 120,
    render: lookup('bound.Status'),
  },
  {
    title: '发货单编号',
    dataIndex: 'code',
    width: 200,
    isFilter: true,
    render: (code: string) => code ?? replaceSign,
  },
  {
    title: '发货单状态',
    dataIndex: 'shipmentNoteStatus',
    width: 120,
    isFilter: true,
    type: fieldTypeList.select,
    render: lookup('invoice.inoviceStatus'),
    selectProps: {
      options: inoviceStatus,
      mode: 'multiple',
    },
  },
  {
    title: '来源',
    dataIndex: 'origin',
    width: 120,
    sorter: true,
    isFilter: true,
    type: fieldTypeList.select,
    render: lookup('invoice.invoiceOrigin'),
    selectProps: {
      options: invoiceOrigin,
      mode: 'multiple',
    },
  },
  {
    title: '销售订单编号',
    dataIndex: 'salesOrderCode',
    width: 200,
    isFilter: true,
    render: (salesOrderCode: string) => salesOrderCode ?? replaceSign,
  },
  {
    title: '销售订单行号',
    dataIndex: 'salesOrderLineNo',
    width: 160,
    render: (salesOrderLineNo: string) => salesOrderLineNo ?? replaceSign,
  },
  {
    title: '协同交货计划单编号',
    dataIndex: 'cdsnCode',
    width: 200,
    isFilter: true,
    render: (cdsnCode: string) => cdsnCode ?? replaceSign,
    // renderItem: (
    //   <SearchSelect
    //     fetchType="collaborativeDeliverySchedule"
    //     labelPath="code"
    //     labelInValue
    //     mode="multiple"
    //     params={{
    //       status: [
    //         appEnum.CollaborativeDeliverySchedule.OrderStatus.inprogress,
    //         appEnum.CollaborativeDeliverySchedule.OrderStatus.finished,
    //       ],
    //     }}
    //   />
    // ),
  },
  {
    title: '协同交货计划单行号',
    dataIndex: 'cdsnItemLineNo',
    width: 200,
    render: (cdsnItemLineNo: string) => cdsnItemLineNo ?? replaceSign,
  },
  {
    title: '备注',
    dataIndex: 'remark',
    width: 160,
    render: (remark: string) => remark ?? replaceSign,
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    width: 120,
    isFilter: true,
    type: fieldTypeList.reference,
    render: renderUser,
    renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
  },
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    width: 200,
    sorter: true,
    isFilter: true,
    type: fieldTypeList.date,
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
    render: formatTimeForRender,
  },
  {
    title: '编辑人',
    dataIndex: 'operator',
    width: 150,
    isFilter: true,
    type: fieldTypeList.reference,
    render: renderUser,
    renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
  },
  {
    title: '编辑时间',
    dataIndex: 'updatedAt',
    width: 200,
    sorter: true,
    isFilter: true,
    type: fieldTypeList.date,
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
    render: formatTimeForRender,
  },
  {
    title: '结束人',
    dataIndex: 'finishUser',
    isFilter: true,
    width: 150,
    type: fieldTypeList.reference,
    render: renderUser,
    renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
  },
  {
    title: '结束时间',
    dataIndex: 'finishedAt',
    width: 200,
    isFilter: true,
    sorter: true,
    type: fieldTypeList.date,
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
    render: formatTimeForRender,
  },
];

// 详情基本信息
export const detailBaseInfo = [
  { label: '编号', dataIndex: 'code' },
  {
    label: '来源',
    dataIndex: 'origin',
    render: lookup('invoice.invoiceOrigin'),
  },
  {
    label: '客户编号',
    dataIndex: ['customer', 'code'],
    render: (code: string) => code || replaceSign,
  },
  {
    label: '客户名称',
    dataIndex: ['customer', 'name'],
    render: (name: string) => name || replaceSign,
  },
  {
    label: '状态',
    dataIndex: 'noteStatus',
    render: lookup('invoice.inoviceStatus'),
  },
  {
    label: '出库单编号',
    dataIndex: 'outboundOrderCode',
    render: (outboundOrderCode: string) => {
      return outboundOrderCode || replaceSign;
    },
  },
  {
    label: '出库单状态',
    dataIndex: 'outboundOrderStatus',
    render: (outboundOrderCode: string) => {
      return lookup('bound.Status', outboundOrderCode) || replaceSign;
    },
  },
  {
    label: '备注',
    dataIndex: 'remark',
    render: (remark: string) => remark || replaceSign,
  },
  {
    label: '创建人',
    dataIndex: ['creator', 'name'],
    render: (name: string) => name || replaceSign,
  },
  {
    label: '创建时间',
    dataIndex: 'createdAt',
    render: (createdAt: Date) => {
      if (!createdAt) return replaceSign;
      return _Time.format(createdAt);
    },
  },
  {
    label: '编辑人',
    dataIndex: ['operator', 'name'],
    render: (name: string) => name || replaceSign,
  },
  {
    label: '编辑原因',
    dataIndex: 'editReason',
    render: (editReason: string) => editReason || replaceSign,
  },
  {
    label: '编辑时间',
    dataIndex: 'updatedAt',
    render: (updatedAt: Date) => {
      if (!updatedAt) return replaceSign;
      return _Time.format(updatedAt);
    },
  },
  {
    label: '结束人',
    dataIndex: ['finishUser', 'name'],
    render: (name: string) => name || replaceSign,
  },
  {
    label: '结束时间',
    dataIndex: 'finishedAt',
    render: (finishedAt: Date) => {
      if (!finishedAt) return replaceSign;
      return _Time.format(finishedAt);
    },
  },
  {
    label: '关闭人',
    dataIndex: ['closeUser', 'name'],
    render: (name: string) => name || replaceSign,
  },
  {
    label: '关闭时间',
    dataIndex: 'closedAt',
    render: (closedAt: Date) => {
      if (!closedAt) return replaceSign;
      return _Time.format(closedAt);
    },
  },
];

// 详情-发货单明细行
export const orderDetailColumn = (origin: number) => {
  return _.compact([
    {
      title: '行号',
      dataIndex: 'lineNo',
      width: 80,
      render: (lineNo: string) => lineNo ?? replaceSign,
    },
    {
      title: '物料编号',
      dataIndex: ['material', 'baseInfo', 'code'],
      width: 200,
      render: (code: string) => code ?? replaceSign,
    },
    {
      title: '物料名称',
      dataIndex: ['material', 'baseInfo', 'name'],
      width: 200,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '物料属性',
      dataIndex: ['material', 'attribute'],
      width: 230,
      render: (text: { name: string; attributeItem: { content: string }; id: number }[]) => {
        if (_.isEmpty(text)) return replaceSign;

        return (
          <TagList
            dataSource={_.map(text, ({ name, attributeItem, id }) => ({
              label: `${name}:${attributeItem.content}`,
              value: id,
            }))}
          />
        );
      },
    },
    {
      title: '交货时间',
      dataIndex: 'deliveryDate',
      width: 200,
      render: (deliveryDate: Date) => {
        if (!deliveryDate) return replaceSign;
        return _Time.format(deliveryDate);
      },
    },
    {
      title: '计划发货数',
      dataIndex: ['plannedIssueAmount'],
      width: 200,
      render: (text: ShipmentNoteDetailItemsResponseAmount) => {
        return text?.amountDisplay ? `${text.amountDisplay} ${text.unitName}` : replaceSign;
      },
    },
    {
      title: '仓库应发数',
      dataIndex: ['warehousePlannedIssueAmount'],
      width: 200,
      render: (text: ShipmentNoteDetailItemsResponseAmount) => {
        return text?.amountDisplay ? `${text.amountDisplay} ${text.unitName}` : replaceSign;
      },
    },
    {
      title: '仓库实发数',
      dataIndex: ['warehouseIssueAmount'],
      width: 200,
      render: (text: ShipmentNoteDetailItemsResponseAmount) => {
        return text?.amountDisplay ? `${text.amountDisplay} ${text.unitName}` : replaceSign;
      },
    },
    {
      title: '客户已接收数',
      dataIndex: ['customerReceivedAmount'],
      width: 200,
      render: (text: ShipmentNoteDetailItemsResponseAmount) => {
        return text?.amount !== undefined ? `${text.amountDisplay} ${text.unitName}` : replaceSign;
      },
    },
    origin === appEnum.Invoice.InvoiceOrigin.collaborativeDeliverySchedule && {
      title: '协同交货计划单编号',
      dataIndex: 'cdsnCode',
      width: 200,
      render: (cdsnCode: string) => cdsnCode ?? replaceSign,
    },
    origin === appEnum.Invoice.InvoiceOrigin.collaborativeDeliverySchedule && {
      title: '协同交货计划单行号',
      dataIndex: 'cdsnItemLineNo',
      width: 200,
      render: (cdsnItemLineNo: string) => cdsnItemLineNo ?? replaceSign,
    },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 200,
      render: (customerName: string) => customerName ?? replaceSign,
    },
    {
      title: '出库单明细行号',
      dataIndex: 'outboundOrderLineNo',
      width: 200,
      render: (outboundOrderLineNo: string) => outboundOrderLineNo ?? replaceSign,
    },
    {
      title: '销售订单编号',
      dataIndex: 'salesOrderCode',
      width: 200,
      render: (salesOrderCode: string) => salesOrderCode ?? replaceSign,
    },
    {
      title: '销售订单明细行号',
      dataIndex: 'salesOrderLineNo',
      width: 200,
      render: (salesOrderLineNo: string) => salesOrderLineNo ?? replaceSign,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      width: 200,
      render: (remark: string) => remark ?? replaceSign,
    },
  ]);
};
