import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import ChartCard from './ChartCard';
import { customEvents } from 'src/utils/events';
import { CHART_NAME } from 'src/dict/purchase';

const ChartList: React.FC = () => {
  const chars = [
    {
      title: '订单按时完成率',
      yAxisTitle: ['采购订单数(单)', '按时完成率(%)'],
      unit: ['单', '%'],
      dataIndex: CHART_NAME.onTimeCompletionRate,
      showDateFilter: true,
      chartType: 'lineBar',
    },
    {
      title: '按时交货率',
      yAxisTitle: ['按时交货率(%)'],
      unit: '%',
      dataIndex: CHART_NAME.onTimeDeliveryRate,
      chartType: 'barGroup',
    },
    {
      title: '平均交付周期',
      yAxisTitle: ['平均交付周期(天)'],
      unit: '天',
      dataIndex: CHART_NAME.averageDeliveryCycle,
      chartType: 'barGroup',
    },
  ];

  useEffect(() => {
    window.onresize = () => {
      customEvents.emit('resize');
    };
  }, []);

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
      {chars.map((item) => (
        <Col span={12} key={item.dataIndex}>
          <ChartCard {...item} />
        </Col>
      ))}
    </Row>
  );
};

export default ChartList;
