import { useEffect, useState } from 'react';
import { Form, Input, message, Row, Checkbox, Select } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { RouteComponentProps } from 'react-router-dom';
import _, { get, isEmpty } from 'lodash';
import { UsageStatus } from 'src/dict/common';
import { SearchSelect } from 'components';
import {
  fetchTransferOrderCreate,
  fetchTransferOrderUpdate,
  fetchTransferOrderDetail,
} from 'src/api/ytt/inventory-domain/transferOrder';
import TransferMaterial from '../components/transferMaterial';
import { checkTwoSidesTrim, validateBlInputText } from 'src/utils/formValidators';
import { NumberRulesStandardHook } from 'src/components';
import { appDict, appEnum } from 'src/dict';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import {
  formatCustomFieldsInData,
  initCustomFieldsInData,
  injectCustomFieldInfos,
  useCustomFieldCombinedData,
} from 'src/components/customField';

interface Props extends RouteComponentProps {}
interface Params {
  id?: number;
}

const { StatusEnum } = appEnum.Bound;

export default function TransferOrderCreate(props: Props) {
  const [form] = Form.useForm();
  const { history, match } = props;
  const params: Params = match.params;
  const id = Number(params.id);
  const isEditMode = 'id' in params;
  const pageTitle = isEditMode ? '编辑' : '创建';
  const [keepOn, setKeepOn] = useState(false);
  const [detail, setDetail] = useState<any>(null);
  const [refreshMarker, setRefreshMaker] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.transferOrder);
  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,
    title: '基本信息',
    items: [
      ...NumberRulesStandardHook({
        label: '调拨单编号',
        form,
        edit: isEditMode,
        objectCode: 'TransferOrder',
        fieldCode: 'code',
        refreshMarker,
        rules: [
          { max: 255, message: '不超过255字符' },
          { validator: checkTwoSidesTrim },
          { validator: validateBlInputText('调拨单编号') },
        ],
      }),
      {
        label: '业务类型:',
        name: 'bizType',
        validateFirst: true,
        initialValue: 0,
        rules: [{ required: true, message: '请选择业务类型' }],
        render: () => <Select options={appDict.bound.TransferType} disabled placeholder="请选择" />,
      },
      {
        label: '发出仓库:',
        name: 'sourceWarehouseId',
        rules: [{ required: true, message: '请选择发出仓库' }],
        render: () => (
          <SearchSelect
            disabled={detail && detail.bizStatus !== StatusEnum.xinjian}
            labelInValue
            params={{ enableFlag: UsageStatus.enabled }}
            fetchType="warehouse"
          />
        ),
      },
      {
        label: '接收仓库:',
        name: 'targetWarehouseId',
        rules: [{ required: true, message: '请选择接收仓库' }],
        render: () => (
          <SearchSelect
            disabled={detail && detail.bizStatus !== StatusEnum.xinjian}
            labelInValue
            params={{ enableFlag: UsageStatus.enabled }}
            fetchType="warehouse"
          />
        ),
      },
      {
        label: '备注:',
        name: 'remark',
        rules: [{ max: 1000, message: '不超过1000个字符' }],
        render: () => <Input.TextArea placeholder="请输入" />,
      },
    ],
  };
  const materialInfo: DataFormLayoutInfoBlock = {
    title: '物料列表',
    items: [
      {
        label: '',
        isFullLine: true,
        render: () => (
          <TransferMaterial
            freezeLen={detail?.transferOrderItems?.length || 0}
            name="transferOrderItems"
            form={form}
            customFields={customFields}
          />
        ),
      },
    ],
  };

  const getDetail = async () => {
    const { data } = await fetchTransferOrderDetail({ id });

    setDetail(data);
    const afterFormatInitialValue = {
      ...data,
      sourceWarehouseId: {
        label: data?.sourceWarehouse?.name,
        value: data?.sourceWarehouse?.id,
      },
      targetWarehouseId: {
        label: data?.targetWarehouse?.name,
        value: data?.targetWarehouse?.id,
      },
      transferOrderItems: data?.transferOrderItems?.map((row: any) => {
        const {
          materialBatchInfo,
          materialInfo = {},
          storageLocation = {},
          unit = {},
          supplier = {},
          ...rest
        } = row;

        return {
          ...rest,
          materialId: {
            label: materialInfo?.baseInfo?.code,
            value: JSON.stringify(materialInfo),
            key: JSON.stringify(materialInfo),
          },
          supplierId: {
            label: supplier?.name,
            value: supplier?.id,
            key: supplier?.id,
          },
          batchNo: {
            label: materialBatchInfo?.batchNo,
            value: materialBatchInfo?.batchNo,
          },
          unitId: {
            label: unit.name,
            value: unit.id,
          },
        };
      }),
    };

    const afterFormatCustomFieldsValue = initCustomFieldsInData(afterFormatInitialValue);

    form?.setFieldsValue(afterFormatCustomFieldsValue);
  };

  const onCancel = () => {
    history.goBack();
  };
  const getMaterialInfo = (material: any) => {
    const value = JSON.parse(material?.value ?? '{}');

    return value;
  };
  const formatItemsData = (items: any) => {
    const initItems = get(detail, 'transferOrderItems');

    if (isEmpty(items)) return [];

    let lastLineNo = Number(get(initItems, [initItems?.length - 1, 'lineNo'], 0));

    return items?.map((row: any) => {
      return {
        ...row,
        batchNo: row?.batchNo?.value,
        materialId: getMaterialInfo(row.materialId)?.baseInfo?.id,
        unitId: row.unitId?.value,
        supplierId: row.supplierId?.value,
        lineNo: typeof row?.lineNo === 'string' ? row?.lineNo : ++lastLineNo,
      };
    });
  };
  const onFinish = async () => {
    const values = await form.validateFields();

    const _options = {
      ..._.omit(values, ['code_isUseRules']),
      sourceWarehouseId: values.sourceWarehouseId?.value,
      targetWarehouseId: values.targetWarehouseId?.value,
      transferOrderItems: formatItemsData(values.transferOrderItems),
    } as any;

    const options = formatCustomFieldsInData({ data: _options, customFields });

    setLoading(true);
    (isEditMode ? fetchTransferOrderUpdate({ ...options, id }) : fetchTransferOrderCreate(options))
      .then((res) => {
        if (res && res.code === 200) {
          message.success('操作成功');
          if (keepOn) {
            form.resetFields();
            setRefreshMaker(Math.random());
            return;
          }
          onCancel();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, [id]);

  return (
    <DataFormLayout
      form={form}
      confirmLoading={loading}
      title={`${pageTitle}调拨单`}
      info={[
        baseInfo,
        injectCustomFieldInfos({
          customFields,
          type: 'form',
          formConfig: { form },
        }),
        materialInfo,
      ]}
      onCancel={onCancel}
      onFinish={onFinish}
      extra={
        !isEditMode && (
          <Checkbox
            onChange={() => {
              setKeepOn(!keepOn);
            }}
            defaultChecked={keepOn}
          >
            连续新建
          </Checkbox>
        )
      }
    />
  );
}
