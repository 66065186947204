/*
 * @Author:
 * @Date: 2021-05-13 11:13:32
 * @LastEditors: Vicky Yu
 * @LastEditTime: 2021-05-14 10:35:14
 * @Description:
 * @FilePath: /web-v3/src/custom_fields/src/views/fieldsList/components/verificationRules/verificationRules.ts
 */

import { RuleObject } from 'antd/lib/form';
import _ from 'lodash';
import {
  inputNumberIntervalRules,
  inputText1Rules,
  inputText2Rules,
  inputtNumberIntRules,
  urlRules,
  maxDigtisRules,
  numberRules,
  inputText3Rules,
} from './rules';

/**
 * @description: 必须是字母、数字、下划线
 * @param {*}
 * @return {*}
 */
export const validateBlInputText1 = (name: string = '') => {
  return (_rule: RuleObject, value: string) => {
    return new Promise<void>((resolve, reject) => {
      if (!value) return resolve();
      if (!inputText1Rules(value)) reject(`${name}必须是字母、数字、下划线`);

      return resolve();
    });
  };
};

/**
 * @description: 必须是字母、中文、支持所有特殊字符
 * @param {*}
 * @return {*}
 */
export const validateBlInputText2 = (name: string = '') => {
  return (_rule: RuleObject, value: string) => {
    return new Promise<void>((resolve, reject) => {
      if (!value) return resolve();
      if (!inputText2Rules(value)) reject(`${name}必须是字母、中文、支持所有特殊字符`);

      return resolve();
    });
  };
};

/**
 * @description: 必须是字母、数字、支持所有特殊字符
 * @param {*}
 * @return {*}
 */
export const validateBlInputText3 = (name: string = '') => {
  return (_rule: RuleObject, value: string, _callback: (error?: string) => void) => {
    return new Promise<void>((resolve, reject) => {
      if (!value) return resolve();
      if (!inputText3Rules(value)) reject(`${name}必须是字母、数字、支持所有特殊字符`);

      return resolve();
    });
  };
};

/**
 * @description: 区间之内的整数
 * @param {number} min 最小值
 * @param {number} max 最大值
 * @param {*} minAllowEqual 最小值是否允许等于，默认true
 * @param {*} maxAllowEqual 最大值是否允许等于，默认true
 * @return {*}
 */
export const validateBlInputNumberIntervalInt = (
  min: number,
  max: number,
  minAllowEqual = true,
  maxAllowEqual = true,
  name: string = '',
) => {
  return (_rule: RuleObject, value: string | number) => {
    return new Promise<void>((resolve, reject) => {
      if (typeof value !== 'number') {
        if (!value) return resolve();
      }
      if (!inputtNumberIntRules(value)) reject(`${name}必须是整数`);
      const test = inputNumberIntervalRules(value, min, max, minAllowEqual, maxAllowEqual, name);

      if (!test.res) return reject(test.mes);

      resolve();
    });
  };
};

/**
 * @description: 区间之内的数
 * @param {number} min 最小值
 * @param {number} max 最大值
 * @param {*} minAllowEqual 最小值是否允许等于，默认true
 * @param {*} maxAllowEqual 最大值是否允许等于，默认true
 * @return {*}
 */
export const validateBlInputNumberInt = (
  min: number,
  max: number,
  minAllowEqual = false,
  maxAllowEqual = true,
  name: string = '',
) => {
  return (_rule: RuleObject, value: string | number) => {
    return new Promise<void>((resolve, reject) => {
      if (typeof value === 'string') {
        if (!value) return resolve();
      }
      const test = inputNumberIntervalRules(value, min, max, minAllowEqual, maxAllowEqual, name);

      if (!test.res) return reject(test.mes);

      resolve();
    });
  };
};

/**
 *  @description: 必须是网址
 * @param {*}
 * @return {*}
 */
export const validatorUrl = (name: string = '') => {
  return (_rule: RuleObject, value: string) => {
    return new Promise<void>((resolve, reject) => {
      if (!value) return resolve();
      if (!urlRules(value)) reject(`${name}必须是正确网址`);

      return resolve();
    });
  };
};

/**
 * @description: 数值不能超过上限，数值小数位数不能超过上限
 * @param {number} maxDigtis: 最大位数
 * @param {number} max: 最大值
 */
export const validatorNumberDigtis = (maxDigtis: number, max = Infinity, name: string = '') => {
  return (_rule: RuleObject, value: any) => {
    return new Promise<void>((resolve, reject) => {
      if (!value) return resolve();
      if (!numberRules(value)) reject(`${name}必须是数值`);
      if (value > max) reject(`${name}数值不能超过${max}`);
      if (!maxDigtisRules(value, maxDigtis)) reject(`${name}小数位数不能超过${maxDigtis}位`);
      return resolve();
    });
  };
};

/**
 * @description: 数值不能超过上限
 * @param {number} max: 最大值
 * @param {*} maxAllowEqual 最大值是否允许等于，默认true
 * @param {*} decimalAllow 是否允许小数，默认true
 */
export const validatorMax = (
  max = Infinity,
  maxAllowEqual = true,
  decimalAllow = true,
  name: string = '',
) => {
  return (_rule: RuleObject, value: any) => {
    return new Promise<void>((resolve, reject) => {
      if (!value) return resolve();
      if (!numberRules(value)) reject(`${name}必须是数值`);
      if (!decimalAllow && !inputtNumberIntRules(value)) reject(`${name}必须是整数`);
      if (maxAllowEqual && value > max) reject(`${name}数值不能超过${max}`);
      if (!maxAllowEqual && value >= max) reject(`${name}数值必须小于${max}`);
      return resolve();
    });
  };
};

/**
 *  @description: 字符长度不超过max
 *  @param {number} max: 最大长度数
 * @return {*}
 */
export const validatorCharacterLen = (max: number, name: string = '') => {
  return (_rule: RuleObject, value: any) => {
    return new Promise<void>((resolve, reject) => {
      if (value && value.length > max) reject(`${name}不超过${max}个字符`);

      return resolve();
    });
  };
};

// 前后不能出现空格
export const validatorCheckTwoSidesTrim = (name: string = '') => {
  return (_rule: RuleObject, value: string, _callback: (error?: string) => void) => {
    if (_.startsWith(value, ' ') || _.endsWith(value, ' ')) {
      _callback(`${name}前后不能包含空格`);
      return;
    }
    _callback();
  };
};

/**
 * @description: 百分比数值校验，校验是否为0 - 100 质检的比例树枝
 */
export const percentCheck = (message?: string) => {
  return (_rule: RuleObject, value: any) => {
    const test = inputNumberIntervalRules(value, 0, 100, false, true, '数值');

    return new Promise<void>((resolve, reject) => {
      if (!value) return resolve();
      if (!numberRules(value)) reject(`${name}必须是数值`);
      if (!test.res) return reject(message || test.mes);
      return resolve();
    });
  };
};
