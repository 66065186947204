import _ from 'lodash';
import { Big } from 'big.js';

/**
 * 用料清单 需求数
 * @param mainPlannedAmount 主产出计划生产数
 * @param inputAmountNumerator 用量分子
 * @param inputAmountDenominator 用量分母
 * @param lossRate 损耗率
 */
export const getDemandAmount = (
  baseAmount: {
    mainPlannedAmount: number;
    inputAmountNumerator: number;
    inputAmountDenominator: number;
    lossRate: number;
  },
  precise = 6,
) => {
  const mainPlannedAmount = _.get(baseAmount, 'mainPlannedAmount') || 1;
  const inputAmountNumerator = _.get(baseAmount, 'inputAmountNumerator') || 0;
  const inputAmountDenominator = _.get(baseAmount, 'inputAmountDenominator') || 1;
  const lossRate = _.get(baseAmount, 'lossRate') || 0;

  /** 1+损耗率 */
  const lossRate_1 = 1 + _.divide(lossRate, 100);

  const num = _.round(
    _.divide(
      _.multiply(_.multiply(mainPlannedAmount, inputAmountNumerator), lossRate_1),
      inputAmountDenominator,
    ),
    precise,
  );

  return num || 0;
};

/**
 * 用料清单 标准用量
 * @param mainPlannedAmount 主产出计划生产数
 * @param inputAmountNumerator 用量分子
 * @param inputAmountDenominator 用量分母
 */
export const getStandardAmount = (
  baseAmount: {
    mainPlannedAmount: number;
    inputAmountNumerator: number;
    inputAmountDenominator: number;
  },
  precise = 6,
) => {
  const mainPlannedAmount = _.get(baseAmount, 'mainPlannedAmount') || 1;
  const inputAmountNumerator = _.get(baseAmount, 'inputAmountNumerator') || 0;
  const inputAmountDenominator = _.get(baseAmount, 'inputAmountDenominator') || 1;

  const num = _.ceil(
    _.divide(_.multiply(mainPlannedAmount, inputAmountNumerator), inputAmountDenominator),
    precise,
  );

  return num || 0;
};

/**
 * 用料清单 预计生产数量
 * @param baseAmount
 * @param precise
 * @returns
 */
export const getExpectAmount = (
  baseAmount: { plannedAmount: number; productRate: number },
  // precise = 6,
) => {
  const plannedAmount = _.get(baseAmount, 'plannedAmount') || 0;

  const productRate = _.get(baseAmount, 'productRate') || 0;
  const val = new Big(plannedAmount)
    .times(new Big(productRate))
    .div(new Big('100'))
    // .toFixed(precise)
    .valueOf();

  /** 因为非主产出成品率等于主产出成品率且不可更改 */
  const num = val;

  return num || 0;
};

/**
 * 拆分计划订单插入
 * @param record
 * @param insertCount
 * @returns
 */

type TRecord<T> = T & {
  order: number;
};

export const getNextOrder = (baseOrder: number, index: number): number => {
  const baseOrderStr = baseOrder.toString();
  const additionalStr = (index + 1).toString();

  // 无小数点 ==> 第一次追加
  if (baseOrderStr.indexOf('.') === -1) {
    return baseOrder + (index + 1) * 0.01;
  }
  // 非第一次追加 ：以字符串拼接方式生成新order（这样就不用考虑要加几位小数），最后将字符串转成数值返回
  return Number(baseOrderStr + additionalStr);
};

export const getInsertedRecordList = <T>(record: TRecord<T>, insertCount: number): TRecord<T>[] => {
  if (!insertCount) return [];
  return Array.from({ length: insertCount }).map((__, i) => ({
    ...record,
    order: getNextOrder(record.order, i),
  }));
};
