import authDict from 'src/utils/auth';
import InBound from './inBound';
import InBoundCreate from './inBound/create';
import InBoundDetail from './inBound/detail';
import WriteOff from './inBound/writeOff';
import OutBound from './outBound';
import OutBoundCreate from './outBound/create';
import OutBoundDetail from './outBound/detail';
import TransferOrder from './transferOrder';
import TransferOrderCreate from './transferOrder/create';
import TransferOrderDetail from './transferOrder/detail';
export const BOUND_PATH = '/warehouseManagement/boundOrderManagement';

const BoundOrderRouteConfig = [
  {
    path: BOUND_PATH,
    menu: '出入库管理',
    breadcrumb: '出入库管理',
    redirectPath: `${BOUND_PATH}/inBound`,
    children: [
      {
        path: `${BOUND_PATH}/inBound`,
        auth: authDict.inboundorder_view,
        menu: '入库单',
        logPage: true,
        component: InBound,
        breadcrumb: '入库单',
        children: [
          {
            path: `${BOUND_PATH}/inBound/create`,
            auth: authDict.inboundorder_add,
            component: InBoundCreate,
            breadcrumb: '创建入库单',
          },
          {
            path: `${BOUND_PATH}/inBound/:id/edit`,
            auth: authDict.inboundorder_edit,
            component: InBoundCreate,
            breadcrumb: '编辑入库单',
          },
          {
            path: `${BOUND_PATH}/inBound/:id/detail`,
            component: InBoundDetail,
            auth: authDict.inboundorder_detail,
            breadcrumb: '入库单详情',
          },
          {
            path: `${BOUND_PATH}/inBound/:id/detail/writeoff`,
            component: WriteOff,
            auth: authDict.inboundorder_reversal,
            breadcrumb: '入库冲销',
          },
        ],
      },
      {
        path: `${BOUND_PATH}/outBound`,
        menu: '出库单',
        component: OutBound,
        auth: authDict.outboundorder_view,
        breadcrumb: '出库单',
        logPage: true,
        children: [
          {
            path: `${BOUND_PATH}/outBound/create`,
            component: OutBoundCreate,
            auth: authDict.outboundorder_add,
            breadcrumb: '创建出库单',
          },
          {
            path: `${BOUND_PATH}/outBound/:id/edit`,
            auth: authDict.outboundorder_edit,
            component: OutBoundCreate,
            breadcrumb: '编辑出库单',
          },
          {
            path: `${BOUND_PATH}/outBound/:id/detail`,
            component: OutBoundDetail,
            auth: authDict.outboundorder_detail,
            breadcrumb: '出库单详情',
          },
        ],
      },
      {
        path: `${BOUND_PATH}/transferOrder`,
        menu: '调拨单',
        logPage: true,
        auth: authDict.transferorder_view,
        component: TransferOrder,
        breadcrumb: '调拨单',
        children: [
          {
            path: `${BOUND_PATH}/transferOrder/create`,
            component: TransferOrderCreate,
            auth: authDict.transferorder_add,
            breadcrumb: '创建调拨单',
          },
          {
            path: `${BOUND_PATH}/transferOrder/:id/edit`,
            component: TransferOrderCreate,
            auth: authDict.transferorder_edit,
            breadcrumb: '编辑调拨单',
          },
          {
            path: `${BOUND_PATH}/transferOrder/:id/detail`,
            auth: authDict.transferorder_detail,
            component: TransferOrderDetail,
            breadcrumb: '调拨单详情',
          },
        ],
      },
    ],
  },
];

export default BoundOrderRouteConfig;
