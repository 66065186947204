/** 业务主题枚举 */
export enum BUSINESS_THEME {
  // 默认初始值，无意义，展示占位符-
  /** 生产主题 */
  product = 1,
  /** 质量主题 */
  quality,
  /** 仓储主题 */
  store,
  /** 资源主题 */
  resource,
  /** 销售主题 */
  sales,
  /** 采购 */
  procure,
  /** 业务建模 */
  businessModeling,
  /** 工作日历 */
  workCalendar,
}

/** 关联关系枚举 */
export enum RELATION_SHIP {
  onToOne,
  onToManay,
}

/** 生产主题枚举 */
export enum PRODUCT_DETAIL_THEME {
  projectData = 1,
  productPlan,
  productExecute,
}

/** 质量主题枚举 */
export enum QUALITY_DETAIL_THEME {
  qcConfig = 4,
  qcTask,
}

/** 库存主题枚举 */
export enum STORE_DETAIL_THEME {
  store = 6,
  batch,
  bound,
  allocate,
  adjust,
}

/** 资源主题枚举 */
export enum RESOURCE_DETAIL_THEME {
  equipment = 11,
}

/** 销售主题枚举 */
export enum SALES_DETAIL_THEME {
  salesOrder = 12,
  customer,
}

/** 采购主题枚举 */
export enum PROCURE_DETAIL_THEME {
  supplier = 14,
}

/** 业务主题枚举 */
export enum BUSSINESS_DETAIL_THEME {
  material = 15,
  unit,
}

/** 工作日历主题枚举 */
export enum WORK_CALENDAR_DETAIL_THEME {
  shiftList = 17,
  scheduling,
  workCalendar,
}

export enum STATUS {
  disabled = 1,
  enabled,
}
