import _ from 'lodash';
import {
  CheckTypeEnum,
  MaterialProcessEnum,
  RecordSampleEnum,
  isRecordCountSampleEnum,
  ReportPageTypeEnum,
  ReportFormatTypeEnum,
  CheckEntityTypeEnum,
} from 'src/dict/quality';
import { getStandardValue } from '../constants';
import { CheckItem, QcConfig } from '../dto';

export class QcConfigEntity {
  checkItems?: CheckItem[];
  checkType?: CheckTypeEnum;
  recordSample?: RecordSampleEnum;
  materialBatchRecordType?: MaterialProcessEnum;
  recordSummaryCount?: isRecordCountSampleEnum;
  reportFormatType?: number;
  checkEntityType?: number;
  reportPageType?: number;
  qcConfigCheckItemList?: any[];

  constructor(params: QcConfig) {
    this.checkItems = params.qcConfigCheckItemList;
    this.checkType = params.checkType;
    this.recordSample = params.recordSample;
    this.materialBatchRecordType = params.materialBatchRecordType;
    this.recordSummaryCount = params.recordSummaryCount;
    this.reportFormatType = params.reportFormatType;
    this.checkEntityType = params.checkEntityType;
    this.reportPageType = params.reportPageType;
    this.qcConfigCheckItemList = params.qcConfigCheckItemList;
  }
  /** 是否记录样本 */
  isRecordSample = () => this.recordSample === RecordSampleEnum.record;

  /** 是否记录总体 */
  isRecordTotal = () => this?.materialBatchRecordType !== MaterialProcessEnum.MATERIAL_NO_RECORD;

  /** 是否需要填写总量和抽样量 */
  isRecordSummaryCount = () => this?.recordSummaryCount === isRecordCountSampleEnum.yes;

  /** 执行页是否按照检验项填写 */
  isRecordByCheckItem = () => this.reportPageType === ReportPageTypeEnum.pageByCheckItem;

  /** 总体处理方式是否为记录不更新质量状态或者记录更新质量状态 */
  isMaterialBatchRecordType = () =>
    this.materialBatchRecordType === MaterialProcessEnum.MATERIAL_ONLY_RECORD ||
    this.materialBatchRecordType === MaterialProcessEnum.MATERIAL_RECORD_UPDATE_STATUS;

  /** 总体处理方式是否为记录更新质量状态 */
  isSingleMaterialBatchRecordType = () =>
    this.materialBatchRecordType === MaterialProcessEnum.MATERIAL_RECORD_UPDATE_STATUS;

  /** 样本记录填写格式是否为每个库存明细分别填写 */
  isReportFormatType = () => this.reportFormatType === ReportFormatTypeEnum.materialUnit;

  /** 填写份数是否为执行时自定义添加 */
  canAddReport = () => this.checkEntityType === CheckEntityTypeEnum.useExecuteAdd;

  /** 填写份数是按抽样量填写 */
  isSameAsSampleCount = () => this.checkEntityType === CheckEntityTypeEnum.SameAsSample;
  /** 填写份数是否为库存明细 */
  reportPageSameAsInventory = () => this.checkEntityType === CheckEntityTypeEnum.SameAsBatchCount;
  getCheckItems = () =>
    _.flatMap(this.qcConfigCheckItemList, (group) =>
      _.map(group.checkItemAppDetailVOS, (checkitem) => ({
        ...checkitem,
        groupName: group.groupName,
        standValue: getStandardValue(checkitem.logic, checkitem, false),
      })),
    );

  /** 是否出现 是否需要填写总量和抽样量 的设置选项 */
  canSetIsRecordSummaryCount = () =>
    this.checkEntityType === CheckEntityTypeEnum.useExecuteAdd ||
    this.checkEntityType === CheckEntityTypeEnum.Custom ||
    this.checkEntityType === CheckEntityTypeEnum.useCheckItemConfig;
}
