/*
 * @Author:
 * @Date: 2021-05-13 13:40:08
 * @LastEditors: Vicky Yu
 * @LastEditTime: 2021-05-13 18:57:29
 * @Description:
 * @FilePath: /web-v3/src/custom_fields/src/views/fieldsList/components/fieldFormItems/singleLine.tsx
 */
import React from 'react';
import { FormInstance, Input, Radio } from 'antd';
import {
  validateBlInputNumberIntervalInt,
  validatorCharacterLen,
  validatorCheckTwoSidesTrim,
} from '../verificationRules';
import { appDict, appEnum } from 'src/dict';
import _ from 'lodash';
import { SearchSelect } from 'src/components';
import { FieldBaseProps } from 'src/page/custom_fields/fieldsList/index.d';
import { YN } from 'src/dict/common';
import { yn as ynMappings } from 'src/dict/common/mappings';

interface Props extends FieldBaseProps {
  form: FormInstance;
  isNumberRuleConfig?: number;
  isEdit?: boolean;
  objectId: number;
  refresh: () => void;
  setEnableNumberRule: React.Dispatch<React.SetStateAction<boolean>>;
  enableNumberRule: boolean;
  isPrime?: boolean;
  isRef?: boolean;
  isCustomObject?: boolean;
  isUsed?: boolean;
}

export const singleLine = (props: Props) => {
  const {
    isNumberRuleConfig,
    isPreField,
    objectId,
    form,
    isEdit,
    refresh,
    enableNumberRule,
    setEnableNumberRule,
    fieldCategory,
    isPrime,
    isRef,
    isCustomObject,
    isUsed = true,
  } = props;
  const hasNumberRule = !isPreField || (isPreField && isNumberRuleConfig);
  const DEFAULT_MAX_LENGTH = 255;

  const data = [
    {
      label: '必填',
      name: 'isRequired',
      initialValue: appEnum.Common.YN.no,
      rules: [{ required: true, message: '请选择必填' }],
      render: () => (
        <Radio.Group
          disabled={isPreField && form.getFieldValue('isRequired') === appEnum.Common.YN.yes}
          options={appDict.common.yn}
        />
      ),
    },
    {
      label: '数据唯一性',
      name: 'isUnique',
      initialValue: appEnum.Common.YN.no,
      rules: [{ required: true, message: '请选择唯一属性' }],
      render: () => <Radio.Group disabled={isEdit} options={appDict.common.yn} />,
    },
    {
      label: '显示属性',
      name: 'isName',
      initialValue: YN.no,
      render: () => (
        <Radio.Group
          disabled={isPrime || !isCustomObject || isRef || !isUsed}
          options={ynMappings}
        />
      ),
    },
    {
      label: '最大长度',
      name: 'maxLength',
      rules: [
        { required: true, message: '请选择最大长度' },
        { validator: validateBlInputNumberIntervalInt(1, 255) },
      ],
      validateFirst: true,
      initialValue: DEFAULT_MAX_LENGTH,
      render: () => <Input disabled={isEdit} onChange={() => refresh()} />,
    },
    {
      label: '默认值',
      shouldUpdate: (prevValues: any, curValues: any) => {
        return prevValues.maxLength !== curValues.maxLength;
      },
      name: 'defaultValue',
      rules: [
        { validator: validatorCheckTwoSidesTrim() },
        {
          validator: validatorCharacterLen(props.form.getFieldValue('maxLength')),
        },
      ],
      hidden: form?.getFieldValue('numberRuleId')?.value > 0,
      render: () => <Input disabled={isPreField} />,
    },
    {
      label: '启用编号规则',
      name: 'isNumberRuleConfig',
      hidden: isEdit && isPreField,
      initialValue: appEnum.Common.YN.no,
      rules: [{ required: true, message: '是否启用编号规则' }],
      render: () => (
        <Radio.Group
          onChange={(e) => {
            e.target.value === appEnum.Common.YN.no
              ? setEnableNumberRule(false)
              : setEnableNumberRule(true);
          }}
          options={appDict.common.yn}
        />
      ),
    },
    {
      label: '编号规则',
      name: 'numberRuleId',
      hidden: isEdit && !hasNumberRule,
      rules:
        (fieldCategory === appEnum.CustomField.FieldCategory.customFields ||
          _.isUndefined(fieldCategory)) &&
        enableNumberRule
          ? [{ required: true, message: '请选择编号规则' }]
          : [],
      render: () => (
        <SearchSelect
          labelInValue
          fetchType={'numberingRules'}
          placeholder={'请选择'}
          disabled={!enableNumberRule}
          params={{ suitObjId: objectId, enableFlag: appEnum.Common.UsageStatus.enabled }}
          onChange={() => refresh()}
        />
      ),
    },
  ];

  return data;
};
