import React from 'react';
import { Space, Input, Button, Form } from 'antd';
import { Rule } from 'antd/lib/form';

interface BatchPopoverContentProps {
  fieldName: React.ReactNode;
  fieldComponent?: React.ReactNode;
  rules?: Rule[];
  onClose: () => void;
  onFinish: (formValues: { batchInput: any }) => void;
}

export default function BatchPopoverContent({
  fieldName,
  fieldComponent,
  rules,
  onClose,
  onFinish,
}: BatchPopoverContentProps) {
  return (
    <Form onFinish={onFinish} layout="vertical">
      <Form.Item label={fieldName} name="batchInput" style={{ width: 280 }} rules={rules}>
        {fieldComponent ?? <Input placeholder="请输入" />}
      </Form.Item>
      <div style={{ textAlign: 'right' }}>
        <Space>
          <Form.Item noStyle>
            <Button onClick={onClose}>取消</Button>
          </Form.Item>
          <Form.Item noStyle>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
}
