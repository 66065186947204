/**
 * @enum SOP业务模块枚举值
 */

/** 业务类型 */
export enum BizType {
  /** 通用业务类型 */
  general,
  /** 生产业务类型 */
  production,
  /** 维护保养 */
  maintenance,
  /** 维修 */
  repair,
}

/** 步骤实体类型 */
export enum StepEntityType {
  /** 步骤 */
  step,
  /** 步骤组 */
  stepGroup,
}

/** sop进度类型 */
export enum SopScheduleType {
  /** 基本信息 */
  basicInfo,
  /** 方案配置 */
  schemeConfiguration,
}
/** 权限类型 */
export enum PrivilegeType {
  /** 用户 */
  users = 1,
  /** 部门 */
  departments,
  /** 角色 */
  roles,
  /** 任务执行人 */
  executors,
}

/** 电子签名权限 */
export enum DigitalType {
  /** 用户 */
  users = 1,
  /** 角色 */
  roles,
}

/** 后续步骤 */
export enum NextStepType {
  /** 下个步骤 */
  next = 1,
  /** 人工选择 */
  specified,
  /** 条件判断 */
  condition,
  /** 自定义函数判断 */
  trigger,
}

/** 步骤组类型 */
export enum GroupType {
  /** 并行 */
  parallel,
  /** 串行 */
  serial,
}

/** 循环规则 */
export enum LoopRule {
  /** 循环次数 */
  count = 1,
  /** 单次循环时间限制 */
  time,
  /** 下个循环执行的标准 */
  condition,
}

/** 循环执行限制 */
export enum LoopExecRestrictRule {
  /** 上个循环结束后执行下个循环 */
  inTurn = 1,
  /** 到达循环开始时间即可执行 */
  inTime = 2,
}

/** 控件类型 */
export enum ControlType {
  /** 单行文本 */
  single = 1,
  /** 多行文本 */
  multiLine,
  /** 数字 */
  numeric,
  /** 选择器 */
  selector,
  /** 时间 */
  time,
  /** 附件 */
  file,
  /** 用户 */
  user,
  /** 工位 */
  station,
  /** 报工 */
  report,
  /** 投料 */
  feed,
  /** 关联数据 */
  associatedData,
  /** 关联字段 */
  associatedField,
  /** 资源参数-选择参数 */
  resourceWithSelectedParams = 13,
  /** 资源参数-固定参数 */
  resourceWithFixedParams = 14,
  /** 创建检验任务 */
  qcTaskOfSOP = 15,
  /** 资源报修 */
  resourceRepair = 16,
  /** 编号 */
  numberRules = 17,
}

/** 控件展示逻辑 */
export enum ShowLogic {
  /** 业务字段 */
  biz = 1,
  /** 固定值 */
  fixed,
  /** trigger公式 */
  trigger,
  /** 填写当时 */
  fillTime,
  /** 固定用户 */
  fixedUser,
  /** 当前工位 */
  currentStation,
  /** 固定工位 */
  fixedStation,
  /** 当前执行用户 */
  currentUser,
}

/** 控件默认值逻辑 */
export enum DefaultLogic {
  /** 无 */
  none,
  /** 业务字段 */
  biz,
  /** 固定值/固定文件/固定用户/固定工位 */
  fixed,
  /** trigger公式 */
  trigger,
  /** 填写当时 */
  fillTime,
  /** 固定用户->指定用户 */
  fixedUser,
  /** 当前工位 */
  currentStation,
  /** 固定工位 */
  fixedStation,
  /** 当前执行用户 */
  currentUser,
}

/** 数字控件数值范围限制逻辑 */
export enum NumericRangeLimitLogic {
  /** 无 */
  none,
  /** 区间 */
  range,
}

/** 业务字段 */
export enum BizProperty {
  /** SOP方案任务编号 */
  sopTaskNo,
  /** 所属工序名称 */
  processName,
  /** 所属工序备注 */
  processRemark,
  /** 所属项目编号 */
  projectNo,
  /** 所属项目状态 */
  projectStatus,
  /** 所属项目计划产出物料 */
  projectPlanOutputMaterial,
  /** 所属项目计划产出物料规格 */
  projectPlanOutputMaterialSpec,
  /** 所属项目备注 */
  projectRemark,
  /** 所属订单编号 */
  orderNo,
  /** 所属订单客户 */
  orderCustomer,
  /** 所属订单备注 */
  orderRemark,
  /** SOP方案任务状态 */
  sopTaskStatus,
  /** 所属生产批次批次号 */
  productionBatchNo,
  /** 所属项目计划产出数量 */
  projectPlanOutputAmount,
  /** 所属项目实际产出数量 */
  projectActualOutputAmount,
  /** SOP方案任务实际产出数量 */
  sopTaskActualOutputAmount,
  /** SOP方案任务计划开始时间 */
  sopTaskPlanStartTime,
  /** SOP方案任务计划结束时间 */
  sopTaskPlanEndTime,
  /** SOP方案任务实际开始时间 */
  sopTaskActualStartTime,
  /** SOP方案任务实际结束时间 */
  sopTaskActualEndTime,
  /** 项目创建时间 */
  projectCreateTime,
  /** 项目计划开始时间 */
  projectPlanStartTime,
  /** 项目计划结束时间 */
  projectPlanEndTime,
  /** 项目实际开始时间 */
  projectActualStartTime,
  /** 订单交货日期 */
  orderDeliveryDate,
  /** 所属工序附件 */
  processFile,
  /** 所属项目附件 */
  projectFile,
  /** 所属订单附件 */
  orderFile,
}

/** 数值格式 */
export enum NumericType {
  /** 数值 */
  value = 1,
  /** 百分比 */
  percentage,
}

/** 日期时间类型 */
export enum TimeType {
  /** 年-月-日 */
  ymd = 1,
  /** 时:分:秒 */
  hms,
  /** 年-月-日 时:分:秒 */
  ymdhms,
  /** 年-月-日 时:分 */
  ymdhm,
  /** 月-日 时:分 */
  mdhm,
  /** 日 时:分 */
  dhm,
}

/** 选择方式 */
export enum SelectorMethodType {
  /** 单选 */
  single = 1,
  /** 多选 */
  multiple,
}

/** 用户的可选范围 */
export enum UserOptionalRange {
  /** 全部用户 */
  all = 1,
  /** 指定用户 */
  appoint,
  /** 当前用户 */
  current,
}

/** 工位的可选范围 */
export enum StationOptionalRange {
  /** 全部工位 */
  all = 1,
  /** 指定工位 */
  appoint,
  /** 当前工位 */
  current,
  /** 当前工位所在车间的工位 */
  currentWorkshop,
  /** 当前工位所在的产线的工位 */
  currentProductionLine,
}

/** 文件上传方式 */
export enum UploadMethod {
  /** 本地上传 */
  localFile = 2,
  /** 拍摄上传 */
  photo = 1,
}

/** 允许的文件类型 */
export enum FileType {
  /** 文档 */
  document = 16,
  /** 图片 */
  image = 8,
  /** 音频 */
  audio = 4,
  /** 视频 */
  video = 2,
  /** 压缩包 */
  compressed = 1,
}

/** sop任务状态 */
export enum TaskStatus {
  /** 新建 */
  created,
  /** 已删除 */
  deleted,
  /** 已下发 */
  ready,
  /** 已取消 */
  canceled,
  /** 执行中 */
  executing,
  /** 暂停中 */
  suspended,
  /** 已结束 */
  finished,
}

export enum SopOperationType {
  /** 创建SOP方案 */
  createSop = 1,
  /** 启用SOP方案 */
  enableSop,
  /** 停用SOP方案 */
  disableSop,
  /** 编辑SOP方案 */
  editSop,
  /** 删除SOP方案 */
  deleteSop,
  /** 添加步骤 */
  addStep,
  /** 添加步骤组 */
  addStepGroup,
  /** 复制步骤 */
  copyStep,
  /** 复制步骤组 */
  copyStepGroup,
  /** 删除步骤 */
  deleteStep,
  /** 删除步骤组 */
  deleteStepGroup,
}

export enum StepExecStatus {
  /** 未激活 */
  inactive,
  /** 执行中 */
  executing,
  /** 已执行 */
  executed,
  /** 已结束 */
  finished,
  /** 暂停中 */
  suspended,
}

export enum StepLoopExecStatus {
  /** 生成中 */
  generating,
  /** 已生成 */
  generated,
  /** 执行中 */
  executing,
  /** 已执行 */
  executed,
  /** 暂停中 */
  suspended,
  /** 已结束 */
  finished,
}
export enum ParamGetType {
  /** 手动输入 */
  manual = 1,
  /** API手动获取 */
  api,
}

// wendy TODO  待sop方案添加 资源业务类型时，再展示
export enum ObjectEntitySource {
  /** 手动选择 */
  manual = 1,
  /** 生产任务 */
  product,
  /** 维保任务 */
  maintenance,
  /** 维修任务 */
  'repairTask',
}

/** 资源业务类型 */
export enum ResourceBusinessType {
  /** 设备 */
  'equipment',
  /** 能源仪表 */
  'energy',
  /** 参数 */
  'parameter',
}
export enum FieldSource {
  /** 本表字段 */
  currentSheet = 1,
  /** 他表字段 */
  otherSheet,
}

/** sop控件操作记录-操作类型 */
export enum ControlOperationRecord {
  /** 新建 */
  created,
  /** 编辑 */
  edited,
}
