import { blacklakeGreen } from 'src/styles/color';

/** 受控的用户类型 */
export enum UserTypes {
  /** APP */
  APP = 1,
  /** WEB和APP */
  WEB_AND_APP,
  /** 电视端 */
  TV,
  /** 供应链虚拟账号 */
  SCM_VIRTUAL,
  /** 接口账号 */
  INTERFACE,
  /** 开放平台账号 */
  OPEN_API,
}

const presetUserTypeList = [UserTypes.SCM_VIRTUAL, UserTypes.INTERFACE, UserTypes.OPEN_API];

export function isPresetUser(terminal: number) {
  return presetUserTypeList.includes(terminal);
}

export const INUSE_OFF = 0;
export const INUSE_ON = 1;
export const ACTIVATE_YES = 2;
export const ACTIVATE_NOTYET = 3;

export const LOCK_STATUS_TRUE = 1;
export const LOCK_STATUS_FALSE = 0;

/** 系统管理员角色code */
export const SYSTEM_ADMIN_CODE = 'system_admin';

// 一期先过滤掉电视端
export const terminalList = [
  {
    label: '移动端',
    value: UserTypes.APP,
  },
  {
    label: '网页端+移动端',
    value: UserTypes.WEB_AND_APP,
  },
  {
    label: '电视端',
    value: UserTypes.TV,
  },
  {
    label: '接口账号',
    value: UserTypes.INTERFACE,
  },
].filter((item) => item.value !== UserTypes.TV);

// 批量选项，再过滤掉接口账号类型
export const batchTerminalList = terminalList.filter((item) => item.value !== UserTypes.INTERFACE);

export const USER_MAX_ATTACHMENTS = 10;

export const bulkActionList = {
  setTerminal: 'setTerminal',
  setDepartment: 'setDepartment',
  setRole: 'setRole',
};

export const avatarStyle = {
  backgroundColor: blacklakeGreen,
  marginRight: 2,
  marginBottom: 2,
};

export const userStatusList = [
  {
    displayName: '启用中',
    value: 1,
  },
  {
    displayName: '停用中',
    value: 0,
  },
  {
    displayName: '未激活',
    value: 3,
  },
];

export const lockStatusList = [
  { label: '已锁定', value: LOCK_STATUS_TRUE },
  { label: '未锁定', value: LOCK_STATUS_FALSE },
];
