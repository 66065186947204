import { useState, useCallback } from 'react';

import ListTable from './user/listTable';
import { TreeWebContent } from './department';
import { DepartmentActionModal } from './department/createAndEdit';
import { UserActionModal } from './user/userActionModal';

const UserRoleManagement = (props: { history: any }) => {
  const [showDepartmentModal, setShowDepartmentModal] = useState<boolean>(false);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [isBulkAction, setIsBulkAction] = useState<boolean>(false);
  const [detailId, setDetailId] = useState<number>(0);
  const [actionType, setActionType] = useState<string>('');
  const [isShowChildrenUser, setIsShowChildrenUser] = useState<boolean>(false);
  const [bulkIds, setBulkIds] = useState<number[]>([]);
  const [isRefreshTree, setIsRefreshTree] = useState<number>(0);
  const [isRefreshTable, setIsRefreshTable] = useState<number>(0);
  const [departmentValues, setDepartmentValues] = useState<any>({});
  const [userValues, setUserValues] = useState<any>({});
  const maxHeight = document.getElementById('positions')?.clientHeight; // treeContent的高度

  const detailContent = useCallback(
    (treeNode: any) => {
      const departmentId = treeNode?.key;
      const departmentName = treeNode?.title;

      return (
        <ListTable
          departmentId={departmentId}
          departmentName={departmentName}
          isShowChildrenUser={isShowChildrenUser}
          setUserId={setDetailId}
          setShowActionModal={setShowUserModal}
          setIsBulkAction={setIsBulkAction}
          setActionType={setActionType}
          setBulkIds={setBulkIds}
          isRefreshTable={isRefreshTable}
          history={props.history}
          setUserValues={setUserValues}
        />
      );
    },
    [isShowChildrenUser, isRefreshTable],
  );

  return (
    <>
      {TreeWebContent({
        setDetailId,
        setDepartmentValues,
        detailContent,
        setShowActionModal: setShowDepartmentModal,
        setActionType,
        setIsShowChildrenUser,
        isRefreshTree,
        setIsRefreshTree,
        history: props.history,
        resizableProp: {
          height: maxHeight,
          maxConstraints: [600, maxHeight],
          minConstraints: [300, maxHeight],
          isChangeHeight: false,
        },
      })}
      {showDepartmentModal && (
        <DepartmentActionModal
          setIsRefreshTree={setIsRefreshTree}
          visible={showDepartmentModal}
          id={detailId}
          onClose={() => {
            setShowDepartmentModal(false);
            setActionType('');
            setDetailId(0);
          }}
          actionType={actionType}
          defaultValues={departmentValues}
        />
      )}
      {showUserModal && (
        <UserActionModal
          setIsRefreshTable={setIsRefreshTable}
          visible={showUserModal}
          bulkIds={bulkIds}
          isBulkAction={isBulkAction}
          id={detailId}
          onClose={() => {
            setShowUserModal(false);
            setIsBulkAction(false);
            setActionType('');
          }}
          actionType={actionType}
          defaultValues={userValues}
        />
      )}
    </>
  );
};

export default UserRoleManagement;
