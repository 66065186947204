import _ from 'lodash';
import { TabList } from 'src/components';
import { TabType } from 'src/dict/customer';
import CustomerList from './list/customerList';
import RegisteredMaterialsList from './list/registeredMaterialsList';

const List = () => {
  const tabList = _.compact([
    {
      tab: '客户',
      key: TabType.customer,
      component: <CustomerList />,
    },
    {
      tab: '已注册物料',
      key: TabType.registeredMaterials,
      component: <RegisteredMaterialsList />,
    },
  ]);

  return <TabList tabList={tabList} defaultKey={TabType.customer} />;
};

export default List;
