import { ColumnProps } from 'antd/es/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { BlTable } from '@blacklake-web/component';
import lookup from 'src/dict';
import { getMaterialAttrs } from 'src/entity/material';
import { replaceSign } from 'src/utils/constants';
import { BatchGenerationTypeEnum } from 'src/dict/productionPlanning';
import { TagList } from 'src/components';
import { Tag } from 'antd';

interface CoproductTabProps {
  dataSource?: any;
}

const CoproductTab = (props: CoproductTabProps) => {
  const { dataSource } = props;

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    const baseColumns: ColumnProps<any & FormListFieldData>[] = [
      {
        title: '行号',
        dataIndex: 'lineSeq',
        fixed: 'left',
        width: 120,
        render: (text) => {
          return String(text);
        },
      },
      {
        title: '主产出',
        dataIndex: 'main',
        fixed: 'left',
        width: 120,
        render: (text) => {
          return lookup('common.yn', text);
        },
      },
      {
        title: '物料编号',
        dataIndex: ['material', 'baseInfo', 'code'],
        fixed: 'left',
        width: 200,
      },
      {
        title: '物料名称',
        dataIndex: ['material', 'baseInfo', 'name'],
        width: 150,
      },
      {
        title: '物料分类',
        dataIndex: ['material', 'category', 'name'],
        width: 150,
      },
      {
        title: '物料规格',
        dataIndex: ['material', 'baseInfo', 'specification'],
        width: 150,
      },
      {
        title: '物料属性',
        dataIndex: 'attributes',
        width: 150,
        render: (text, record) => <TagList dataSource={getMaterialAttrs(record.material)} />,
      },
      {
        title: '计划生产数',
        dataIndex: ['plannedAmount', 'amountDisplay'],
        width: 120,
      },
      {
        title: '预计生产数',
        dataIndex: ['expectedAmount', 'amountDisplay'],
        width: 120,
      },
      {
        title: '领料收料齐套数',
        dataIndex: ['pickOrderPreparedAmount', 'amountDisplay'],
        width: 150,
      },
      {
        title: '总报工数',
        dataIndex: ['totalHoldAmount', 'amountDisplay'],
        width: 120,
      },
      {
        title: '合格报工数',
        dataIndex: ['qualifiedHoldAmount', 'amountDisplay'],
        width: 120,
      },
      {
        title: '不合格报工数',
        dataIndex: ['disqualifiedHoldAmount', 'amountDisplay'],
        width: 120,
      },
      {
        title: '入库数',
        dataIndex: ['warehousedAmount', 'amountDisplay'],
        width: 120,
      },
      {
        title: '版本号',
        dataIndex: 'version',
        width: 120,
        render: (text, record) => {
          const { main } = record;

          if (!main) {
            return replaceSign;
          }
          return text;
        },
      },
      {
        title: '工艺路线编号',
        dataIndex: 'processRouteCode',
        width: 120,
        render: (text, record) => {
          const { main } = record;

          if (!main) {
            return replaceSign;
          }
          return text;
        },
      },
      {
        title: '基准数',
        dataIndex: 'standardAmount',
        width: 120,
      },
      {
        title: '单位',
        dataIndex: 'unitName',
        width: 120,
      },
      {
        title: '成品率',
        dataIndex: 'productRate',
        width: 120,
      },
      {
        title: '生成规则',
        dataIndex: 'batchGenerationType',
        width: 120,
        render: (text) => {
          return lookup('productionPlanning.BatchGenerationTypeDisplayMap', text);
        },
      },
      {
        title: '批号',
        dataIndex: 'batch',
        width: 120,
        render: (text, record) => {
          const { batchGenerationType, batchRule, batchNumber } = record;

          if (BatchGenerationTypeEnum.NUMBERINGRULES === batchGenerationType) {
            return batchRule?.name;
          }

          if (BatchGenerationTypeEnum.BATCH === batchGenerationType) {
            return batchNumber;
          }
        },
      },
      {
        title: '报工工序号',
        dataIndex: ['outputProcessSimpleVO', 'processNum'],
        width: 120,
      },
      {
        title: '报工控件编号',
        dataIndex: 'outputSopControlCode',
        width: 200,
      },
      {
        title: '单次报工数量',
        dataIndex: 'singleWorkReportAmount',
        width: 150,
      },
      {
        title: '报工方式',
        dataIndex: 'reportingMethods',
        render: (reportingMethods) => {
          // const { reportingMethods } = processManufacturedGoods;
          return reportingMethods && reportingMethods.length > 0
            ? reportingMethods.map((item: number) => {
                return (
                  <span key={item}>
                    <Tag>{lookup('bom.reportingMethods')(item)}</Tag>
                  </span>
                );
              })
            : replaceSign;
        },
      },
      // {
      //   title: '报工方式',
      //   dataIndex: 'reportingMethods',
      //   width: 200,
      //   render: (text) => (
      //     <TagList dataSource={text?.map((item: any) => lookup('bom.reportingMethods', item))} />
      //   ),
      // },
      {
        title: '是否入库',
        dataIndex: 'warehousing',
        width: 200,
        render: (text) => lookup('common.yn', text),
      },
      {
        title: '自动入库',
        dataIndex: 'autoWarehousingFlag',
        width: 200,
        render: (text) => lookup('common.yn', text) || replaceSign,
        fixed: 'right',
      },
    ];

    return baseColumns;
  };

  return (
    <div style={{ padding: 24 }}>
      <BlTable
        scroll={{ x: 'max-content' }}
        dataSource={dataSource?.bomOutputMaterials}
        columns={getColumns()}
        rowKey="id"
        // sticky
        resizableCol
        useColConfig
        tableConfigKey={'workOrderCoproductTab'}
      />
    </div>
  );
};

export default CoproductTab;
