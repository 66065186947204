import React, { useState } from 'react';
import { Modal } from 'antd';
import styles from './transferModal.module.scss';
import UseTableTransferComponent from './useTableTransfer';
import { CheckItemType } from '../../checkItem/interface';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';

interface createProps {
  visible: Boolean;
  onCancel: () => void;
  targetKeysSource: any[];
  setCheckItemList: (lists: CheckItemType[]) => void;
}

const AddCheckItemAssociatedModal = (props: createProps) => {
  const { visible, onCancel, targetKeysSource, setCheckItemList } = props;

  const [targetSource, setTargetSource] = useState<CheckItemType[]>([]); // 穿梭框右侧目标值的临时source
  const [okDisable, setOkDisable] = useState<boolean>(true);

  const leftTableColumns = [
    {
      title: '检验项编号',
      dataIndex: 'code',
    },
    {
      title: '检验项名称',
      dataIndex: 'name',
    },
    {
      title: '检验项分类',
      dataIndex: 'categoryName',
    },
  ];
  const rightTableColumns = [
    {
      title: '检验项编号',
      dataIndex: 'code',
    },
    {
      title: '检验项名称',
      dataIndex: 'name',
    },
    {
      title: '检验项分类',
      dataIndex: 'categoryName',
    },
  ];

  const onOk = () => {
    setCheckItemList(targetSource); // 只有点击确定的时候才去真的修改关联检验项列表的数据
    onCancel();
  };

  return (
    <Modal
      onOk={throttleFn(onOk)}
      onCancel={onCancel}
      width={1200}
      okText={'保存'}
      visible={Boolean(visible)}
      title="新增关联检验项"
      className={styles.transferModal}
      okButtonProps={{ disabled: okDisable }}
    >
      <UseTableTransferComponent
        leftTableColumns={leftTableColumns}
        rightTableColumns={rightTableColumns}
        targetKeysSource={targetKeysSource}
        setTargetSourceFn={(data: CheckItemType[]) => {
          setTargetSource(data);
        }}
        changeSetDisabled={() => {
          setOkDisable(false);
        }}
      />
    </Modal>
  );
};

export default AddCheckItemAssociatedModal;
