import React from 'react';
import { DetailLayoutForDrawer } from 'layout';
import DetailsBaseInfo from './detailsBaseInfo';

interface Props {
  id: number;
  visible: Boolean;
  onCancel: () => void;
  showEdit: () => void;
  showOperationRecord: () => void;
  refreshDetailsMarker: number | undefined;
  refreshTable?: () => void;
}

const Details = (props: Props) => {
  const { visible, onCancel } = props;

  return (
    <DetailLayoutForDrawer
      visible={Boolean(visible)}
      width={680}
      content={<DetailsBaseInfo {...props} />}
      onClose={onCancel}
    />
  );
};

export default Details;
