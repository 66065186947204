import React from 'react';
import { ColumnProps } from 'antd/es/table';
import { BlTable } from 'src/components';
import { lookup } from 'src/dict';
import { gcArray } from 'src/utils';
//
import { BomResponseInputMaterial } from '../../index.type';

type FeedingControlTableItem = NonNullable<BomResponseInputMaterial['bomFeedingControls']>[0];

interface FeedingControlTableProps {
  dataSource?: FeedingControlTableItem[];
}

const FeedingControlTable = (props: FeedingControlTableProps) => {
  const { dataSource = [] } = props;

  const columns: ColumnProps<FeedingControlTableItem>[] = [
    {
      title: '行号',
      dataIndex: 'lineSeq',
      fixed: 'left',
      width: 120,
    },
    {
      title: '用量:分子',
      dataIndex: 'inputAmountNumerator',
      width: 150,
    },
    {
      title: '用量:分母',
      dataIndex: 'inputAmountDenominator',
      width: 150,
    },
    {
      title: '投料控件编号',
      dataIndex: ['inputSopControlDTO', 'code'],
      width: 200,
    },
    {
      title: '是否必投',
      dataIndex: 'inputMaterialControl',
      width: 120,
      render: (text) => lookup('yn', text),
    },
    {
      title: '投料上下限类型',
      dataIndex: 'inputBoundType',
      width: 200,
      render: (text) => lookup('bom.inputBoundType', text),
    },
    {
      title: '投料上限',
      dataIndex: 'inputUpperLimit',
      width: 150,
    },
    {
      title: '投料下限',
      dataIndex: 'inputLowerLimit',
      width: 150,
    },
    {
      title: '投料上限比例',
      dataIndex: 'inputUpperLimitRatio',
      width: 150,
    },
    {
      title: '投料下限比例',
      dataIndex: 'inputLowerLimitRatio',
      width: 150,
    },
    {
      title: '投料质量状态',
      dataIndex: 'inputQcState',
      width: 150,
      render: (text, record) => {
        const { inputQcState } = record;

        return !gcArray.isEmpty(inputQcState)
          ? inputQcState?.map((item) => lookup('material.feedingQualityStatus', item)).join(',')
          : null;
      },
    },
    {
      title: '投料类型',
      dataIndex: 'feedType',
      width: 150,
      render: (text) => lookup('material.feedingTypeMap', text),
    },
  ];

  return (
    <BlTable<FeedingControlTableItem>
      scroll={{ x: 'max-content', y: 'auto' }}
      dataSource={dataSource}
      columns={columns}
      rowKey="id"
      pagination={false}
    />
  );
};

export default FeedingControlTable;
