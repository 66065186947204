import moment from 'moment';
import React from 'react';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import { replaceSign } from 'src/utils/constants';
import FailReason from './FailReason';
import { TableRowType } from './ImportLog';
import { downloadFileByID } from 'src/components/excelBatchOption/utiles';
import lookup from 'src/dict';
import Tooltip from 'src/components/tooltip/commonToolTap';
import { Col, Row, Button } from 'antd';
import { AvatarDisplay } from 'src/components/avatar/show';

interface ImportLogDetailProps {
  dataSource: TableRowType | undefined;
}

const ImportLogDetail: React.FC<ImportLogDetailProps> = ({ dataSource }) => {
  /**
   * 下载失败文件
   */
  const downloadFailFile = (text: string, updatedAt: number) => {
    const status = dataSource?.status ?? -1;
    const isOver24Hour = moment().isAfter(moment(updatedAt).add(1, 'days'));

    if (status < 2 || isOver24Hour || !dataSource?.failFileId) {
      return text ?? replaceSign;
    }
    return (
      <Row>
        <Col span={16}>
          <span>{text}</span>
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <Button type={'primary'} onClick={() => downloadFileByID(dataSource?.failFileId)}>
            下载失败文件
          </Button>
        </Col>
      </Row>
    );
  };

  const baseInfo: DetailLayoutInfoBlock = {
    title: '操作详情',
    items: [
      {
        label: '文件名称',
        dataIndex: 'fileName',
        isFullLine: true,
        render: (text: string, record: any) => downloadFailFile(text, record?.updatedAt),
      },
      {
        label: '操作人',
        dataIndex: 'userName',
        isFullLine: true,
        render: (userName: string, record: any) => {
          return <AvatarDisplay isShowTag isUser id={record?.userId} name={userName} />;
        },
      },
      {
        label: '操作时间',
        dataIndex: 'updatedAt',
        isFullLine: true,
        render: (text: number) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : replaceSign),
      },
      {
        label: '操作结果',
        dataIndex: 'status',
        isFullLine: true,
        render: (text: number) => lookup('objectImport.importStatus', text),
      },
      {
        label: '操作详情',
        dataIndex: 'resultMsg',
        isFullLine: true,
        render: (text: string) => text ?? replaceSign,
      },
      // {
      //   label: '失败原因',
      //   dataIndex: 're',
      //   isFullLine: true,
      //   render: () => replaceSign,
      // },
    ],
  };

  return <DetailLayout title={'操作详情'} info={[baseInfo]} dataSource={dataSource} />;
};

export default ImportLogDetail;
