/**
 * @description 火山全埋点接入
 * @author Mars
 */

import _ from 'lodash';
import { userStatusList } from 'src/page/organization/userManagement/constants';

/** 正式环境app_id */
const PROD_APP_ID = 10000003;
/** 测试环境app_id */
const TEST_APP_ID = 10000002;
/** 数据发送地址 */
const CHANNEL_DOMAIN = 'https://snssdk.blacklake.cn';

interface VolcanoIn {
  start: () => void;
  initSDK: () => void;
  setConfig: (userInfo: any) => void;
  setUUID: (userId: number, orgId: number) => void;
  webLogin: () => void;
  webLogout: () => void;
}

interface VolcanoProps {
  /** 是否生产环境 */
  isProdEnv: boolean;
  /** 火山事件 */
  event: any;
  /** app_id */
  appId: number;
  /** 数据发送地址 */
  channelDomain: string;
}

/**
 * 火山埋点接入
 */
class Volcano implements VolcanoIn {
  private static volcano: Volcano;
  /** 数据发送地址 */
  private readonly channelDomain: string;
  /** app_id */
  private readonly appId: number;
  /** 是否生产环境 */
  private readonly isProdEnv: boolean = false;
  /** 火山事件 */
  private readonly event: any;

  private constructor(props: VolcanoProps) {
    this.isProdEnv = props.isProdEnv;
    this.event = props.event;
    this.appId = props.appId;
    this.channelDomain = props.channelDomain;
  }

  static getVolcano(props: VolcanoProps): Volcano {
    if (!Volcano.volcano) {
      Volcano.volcano = new Volcano(props);
    }
    return Volcano.volcano;
  }

  start(): void {
    this.event('start');
  }

  /** 初始化SDK */
  initSDK(): void {
    this.event('init', {
      app_id: this.appId, // 申请的 app_id
      channel_domain: this.channelDomain,
      log: !this.isProdEnv, // 开启调试日志
      spa: true,
      autotrack: true, // 开启全埋点采集
    });
    this.start();
  }

  /**
   * 设置用户/租户属性
   * @param userInfo 用户信息
   */
  setConfig(userInfo: any = {}): void {
    const { active, orgId, id, name, username, phone, email, lockStatus, orgBaseInfo } = userInfo;
    const {
      businessType,
      productType,
      code,
      displayName,
      organizationName,
      customerId,
      groupId,
      zone,
      active: orgActive,
    } = orgBaseInfo || {};

    const params = {
      product_type: productType,
      org_type: businessType,
      org_id: String(orgId),
      org_code: code,
      org_name: organizationName,
      org_nickname: _.isNil(displayName) ? '' : displayName,
      company_id: String(customerId),
      group_id: String(groupId),
      cloud_platform: zone,
      org_status: orgActive,
      user_id: String(id),
      user_account: username,
      phone,
      user_name: name,
      email: _.isNil(email) ? '' : email,
      acc_status: _.find(userStatusList, { value: active })?.displayName,
      user_lock: lockStatus ? '已锁定' : '未锁定',
    };

    this.event('profileSet', params);
    this.start();
  }

  /**
   * 设置UUID
   * @param userId 用户ID
   * @param orgId 租户ID
   */
  setUUID(userId: number, orgId: number): void {
    this.event('config', {
      user_unique_id: `${userId}_${orgId}`, // 设置唯一用户ID
    });
    this.start();
  }

  /** 登录上报 */
  webLogin(): void {
    this.event('web_login');
    this.start();
  }

  /** 登出上报 */
  webLogout(): void {
    this.event('web_logout');
    this.start();
    this.event('config', {
      user_unique_id: null,
    });
    this.start();
  }
}

export default Volcano.getVolcano({
  isProdEnv: window.isProdEnv,
  event: window.volcanoEvent,
  appId: window.isProdEnv ? PROD_APP_ID : TEST_APP_ID,
  channelDomain: CHANNEL_DOMAIN,
});
