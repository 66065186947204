import { FC, useState, useRef, useEffect } from 'react';
import { throttle } from 'lodash';
import { Form, List } from 'antd';
import DraggableItem from './draggableItem';
import Empty from './empty';
import styles from '../../styles.module.scss';

const FONT_SIZE = 14;

const Previewer: FC = () => {
  const previewerRef = useRef<HTMLDivElement | null>(null);
  const [overflowTextLength, setOverflowTextLength] = useState(99999);

  useEffect(() => {
    const throttledHandler = throttle(
      (entries) => {
        const componentTitleWidth =
          entries[0].target.querySelector(`.${styles['component-list-item-title']}`)?.clientWidth ??
          99999;

        setOverflowTextLength(Math.floor(componentTitleWidth / FONT_SIZE));
      },
      200,
      { trailing: true },
    );
    const observer = new ResizeObserver(throttledHandler);

    observer.observe(previewerRef.current!);

    return () => observer.disconnect();
  }, []);

  return (
    <div ref={previewerRef} className={styles['component-list-wrapper']}>
      <List className={styles['component-list']} split={false}>
        <Form.List name="components">
          {(fields, operation) =>
            fields?.map((item) => (
              <DraggableItem {...item} {...operation} overflowTextLength={overflowTextLength} />
            ))
          }
        </Form.List>
      </List>
      <Empty />
    </div>
  );
};

export default Previewer;
