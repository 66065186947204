import React, { useEffect, useState } from 'react';
import { Space, Badge, Dropdown, Menu } from 'antd';
import { FilterItem, RecordListLayout } from 'layout';
import { BlIcon, SearchSelect, SingleOperationLogComponent, Tooltip } from 'components';
import { replaceSign } from 'src/utils/constants';
import { fieldTypeList } from 'src/utils';
import {
  NUMBER_RULES_STATUS_ENUM,
  NUMBER_RULES_ENUM,
  FIELD_MAP,
  NumberRulesContext,
} from './constant';
import { map } from 'lodash';
import { gteFormatConfig, gteSuitObjIdData } from './utils';
import { EnableFlagType, SuitObjectData, TableRowType } from './interface';
import { RouteComponentProps } from 'react-router-dom';
import { toNumberCreate, toNumberDetail, toNumberEdit } from './navigation';
import NumberRulesDetail from './detail';
import {
  fetchNumberRuleList,
  fetchNumberRuleDisable,
  fetchNumberRuleEnable,
  // fetchNumberRuleDelete,
} from 'src/api/ytt/custom-domain';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import _Time from 'src/utils/dataTypes/time';
import { CreatorData } from '../knowledgeManagement/calendarManagement/shift/interface';
import { patchRequest } from '../knowledgeManagement/warehouse/utils';
import { formatTimeForRender } from 'src/utils/formatters';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';

interface Props extends RouteComponentProps {}

const NumberRulesList = (props: Props) => {
  const { history } = props;
  const [visibleDetail, setVisibleDetail] = useState(false);
  const [numberId, setNumberId] = useState<any>();
  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录

  const [refreshMarker, setRefreshMarker] = useState<number>(0);
  const [suitObjectData, setSuitObjectData] = useState([] as any);

  const [formatConfigData, setFormatConfigData] = useState([] as any);
  // const [delForm] = Form.useForm();

  const gteObjectAndFormatData = async () => {
    try {
      const selectData = await gteSuitObjIdData();

      const formatConfigData = await gteFormatConfig();

      setSuitObjectData(selectData);
      setFormatConfigData(formatConfigData);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    gteObjectAndFormatData();
  }, []);

  // 刷新页面
  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  // const errorColumns = [
  //   {
  //     title: '规则名称',
  //     dataIndex: 'ruleName',
  //     width: 250,
  //     render: (ruleName: string) => ruleName || replaceSign,
  //   },
  //   {
  //     title: '规则编号',
  //     dataIndex: 'ruleCode',
  //     width: 250,
  //     render: (ruleCode: string) => ruleCode || replaceSign,
  //   },
  //   {
  //     title: '失败原因',
  //     dataIndex: 'detail',
  //     width: 500,
  //     render: (detail: string) => detail || replaceSign,
  //   },
  // ];

  // const handleDelete = async (onSuccess: () => void, delId?: number, onFail?: () => void) => {
  //   try {
  //     const ids = delId ? [{ id: delId }] : paramsIds;

  //     const { data, message } = await fetchNumberRuleDelete(ids);

  //     if (data) {
  //       if (_Array.isEmpty(data?.fail)) {
  //         // 全部删除成功
  //         typeof onSuccess === 'function' && onSuccess?.();
  //         Message.success('删除成功');
  //         return;
  //       }

  //       if (_Array.isEmpty(data?.success)) {
  //         onFail?.();
  //         // 全部删除失败
  //       } else {
  //         onSuccess?.();
  //         // 有失败的删除 需要展示失败信息
  //       }

  //       showErrorListMessage({
  //         title: '删除出现失败',
  //         data: data?.fail,
  //         columns: errorColumns,
  //         operateName: '删除',
  //         successCount: data?.success?.length,
  //         failCount: data?.fail?.length,
  //         width: 1000,
  //       });
  //       return;
  //     }
  //     Message.error(message);
  //   } catch (error) {
  //     onFail?.();
  //     console.log(error);
  //   }
  // };

  const hasAuthRes = hasAuth(authDict.numberingrule_detail);

  const dataColumns = [
    {
      title: '规则名称',
      dataIndex: 'ruleName',
      width: 200,
      isFilter: true,
      sorter: true,
      render: (ruleName: string, record: TableRowType, index: number, config: any) => {
        return (
          <>
            {hasAuthRes ? (
              <a
                onClick={() => {
                  history.push({
                    pathname: toNumberDetail(record?.id),
                    state: { suitObjectData, formatConfigData },
                  });
                }}
              >
                <Tooltip text={ruleName ?? replaceSign} width={config.contentWidth} />
              </a>
            ) : (
              <Tooltip text={ruleName ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },
    {
      title: '规则编号',
      dataIndex: 'ruleCode',
      width: 200,
      isFilter: true,
      sorter: true,
      render: (ruleCode: string, record: TableRowType, index: number, config: any) => {
        return (
          <>
            {hasAuthRes ? (
              <a
                onClick={() => {
                  history.push({
                    pathname: toNumberDetail(record?.id),
                    state: { suitObjectData, formatConfigData },
                  });
                }}
              >
                <Tooltip text={ruleCode ?? replaceSign} width={config.contentWidth} />
              </a>
            ) : (
              <Tooltip text={ruleCode ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },
    {
      title: '适用对象',
      dataIndex: 'suitObj',
      width: 150,
      isFilter: true,
      sorter: true,
      render: (suitObj: SuitObjectData) => suitObj?.objectName ?? replaceSign,
    },
    {
      title: '状态',
      dataIndex: 'enableFlag',
      width: 150,
      sorter: true,
      isFilter: true,
      render: (enableFlag: number) => {
        const statusMap = new Map([
          [NUMBER_RULES_STATUS_ENUM.ENABLED, <Badge text="启用中" status="success" />],
          [NUMBER_RULES_STATUS_ENUM.DISABLED, <Badge text="停用中" status="error" />],
        ]);

        return statusMap.get(enableFlag);
      },
    },
    {
      title: '规则描述',
      dataIndex: 'ruleRemark',
      type: 'string',
      width: 150,
      render: (ruleRemark: string) => ruleRemark ?? replaceSign,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 120,
      render: (creator: CreatorData) => {
        return (
          <AvatarDisplay
            id={creator?.id}
            name={creator?.name}
            avatarUrl={creator?.avatarUrl}
            key={creator?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 180,
      render: (createdAt: number) => {
        if (!createdAt) return replaceSign;
        return formatTimeForRender(createdAt);
      },
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      width: 150,
      render: (operator: CreatorData) => {
        return (
          <AvatarDisplay
            id={operator?.id}
            name={operator?.name}
            avatarUrl={operator?.avatarUrl}
            key={operator?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 180,
      render: (updatedAt: number, record: any) => {
        if (!updatedAt) return _Time.format(record?.createdAt) ?? replaceSign; // 没有更新,默认为创建时间
        return formatTimeForRender(updatedAt);
      },
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '规则名称',
      name: 'name',
      type: fieldTypeList?.text,
    },
    {
      label: '规则编号',
      name: 'code',
      type: fieldTypeList?.text,
    },
    {
      label: '适用对象',
      name: 'suitObjId',
      type: fieldTypeList?.select,
      renderItem: <SearchSelect fetchType={'bizObject'} labelInValue />,
    },
    {
      label: '状态',
      name: 'enableFlag',
      type: fieldTypeList?.select,
      selectProps: {
        options: NUMBER_RULES_ENUM?.map((node: EnableFlagType) => {
          return {
            label: node?.name,
            value: node?.id,
          };
        }),
      },
    },
  ];

  const left = [
    {
      title: '新建规则',
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.numberingrule_add,
      onClick: () => {
        history.push({
          pathname: toNumberCreate(),
          state: { suitObjectData, formatConfigData },
        });
      },
      items: [],
    },
  ];

  const toolbar = [
    {
      title: '启用',
      icon: <BlIcon type="iconqiyong" />,
      auth: authDict.numberingrule_enable_disable,
      onClick: (onSuccess: any, onFail: any) => {
        patchRequest('patch', fetchNumberRuleEnable, selectedRowKeys, '启用', onSuccess, onFail);
      },
    },
    {
      title: '停用',
      icon: <BlIcon type="icontingyong" />,
      auth: authDict.numberingrule_enable_disable,
      onClick: (onSuccess: any, onFail: any) => {
        patchRequest('patch', fetchNumberRuleDisable, selectedRowKeys, '停用', onSuccess, onFail);
      },
    },
    // {
    //   title: '删除',
    //   icon: <BlIcon type="iconshanchulajitong1" />,
    //   onClick: (onSuccess: any, onFail: any) => {
    //     BLDelConfirm(
    //       '删除后将无法恢复，确认删除？',
    //       delForm,
    //       () => {
    //         handleDelete(onSuccess, undefined, onFail);
    //       },
    //       () => {
    //         onFail();
    //       },
    //     );
    //   },
    // },
  ];

  const formatDataToQuery = (params: any) => {
    const { suitObjId, sorter, ...rest } = params;

    const relParams = { ...rest };

    if (sorter) {
      relParams.sorter = map(params?.sorter, (node: any) => {
        return {
          order: node?.order,
          field: FIELD_MAP.get(node?.field),
        };
      });
    }

    return { ...relParams, suitObjId: suitObjId?.value };
  };

  const config = {
    rowKey: (record: TableRowType) => String(record.id),
    columns: dataColumns,
    getOperationList: (record: TableRowType) => {
      return [
        {
          title: '查看',
          auth: authDict.numberingrule_detail,
          onClick: () => {
            history.push({
              pathname: toNumberDetail(record?.id),
              state: { suitObjectData, formatConfigData },
            });
          },
        },
        {
          title: '编辑',
          auth: authDict.numberingrule_edit,
          onClick: () => {
            history.push({
              pathname: toNumberEdit(record?.id),
              state: { suitObjectData, formatConfigData },
            });
          },
        },
        {
          title: record?.enableFlag ? '停用' : '启用',
          auth: authDict.numberingrule_enable_disable,
          onClick: async () => {
            const numberRuleApi = record?.enableFlag
              ? fetchNumberRuleDisable
              : fetchNumberRuleEnable;
            const numberRuleDisplay = record?.enableFlag ? '停用' : '启用';

            patchRequest('single', numberRuleApi, [record?.id], numberRuleDisplay, refresh);
          },
        },
        {
          title: '操作记录',
          auth: authDict.numberingrule_operrecord,
          onClick: () => {
            setNumberId(record?.id);
            setVisibleLog(true);
          },
        },
      ];
    },
    mainMenu: left,
    batchMenu: toolbar,
    filterList,
    formatDataToQuery,
  };

  return (
    <>
      <NumberRulesContext.Provider value={{ objectData: suitObjectData, formatConfigData }}>
        <RecordListLayout<TableRowType>
          userAuth={getAuthFromLocalStorage()}
          placeholder={'输入规则名称、规则编号'}
          selectedRowKeys={selectedRowKeys}
          configcacheKey="numberRulesCol" // 储存列配置
          onSelectedRowKeys={(selectKey: any) => {
            setSelectRowKeys(selectKey);
          }}
          requestFn={(params) => fetchNumberRuleList(params, { legacy: true })}
          refreshMarker={refreshMarker}
          {...config}
        />
        {visibleDetail && (
          <NumberRulesDetail
            visible={visibleDetail}
            closeDetail={() => {
              setVisibleDetail(false);
            }}
            numberId={numberId}
          />
        )}
        {visibleLog && (
          <SingleOperationLogComponent
            visible={visibleLog}
            instanceId={numberId}
            objectCode={'NumberingRule'}
            closeDetail={() => {
              setVisibleLog(false);
            }}
          />
        )}
      </NumberRulesContext.Provider>
    </>
  );
};

export default NumberRulesList;
