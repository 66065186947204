import { BlSortFormList } from 'src/components/sortList';
import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { Form, FormInstance, Input, Select } from 'antd';
import {
  ResourceGroupTypeEnum,
  resourceGroupTypeMap,
  UserResourceGroupBizTypeEnum,
} from 'src/page/productionPlanning/productionTask/helper/constants';
import { SearchSelect } from 'components';
import { OtherResource } from 'src/page/productionPlanning/productionTask/types/edit.type';

interface Props {
  name: string;
  form: FormInstance;
}

const OtherResourceFormTable = ({ name, form }: Props) => {
  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '行号',
        dataIndex: 'lineNo',
        width: 50,
        render: (text, record, index) => {
          return <Input disabled value={index + 4} />;
        },
      },
      {
        title: '资源分类',
        dataIndex: 'groupType',
        width: 100,
        render: (text, record, index) => {
          return (
            <Input
              disabled
              value={
                resourceGroupTypeMap.find(
                  (item) => item.value === form.getFieldValue([name, index]).groupType,
                )?.label
              }
            />
          );
        },
      },
      {
        title: '资源组名称',
        dataIndex: 'groupName',
        width: 100,
        render: (text, record, index) => {
          return <Input disabled value={form.getFieldValue([name, index]).groupName} />;
        },
      },
      {
        title: '可选资源',
        dataIndex: 'resourceIds',
        width: 150,
        render: (text, record, index) => {
          const resourceInfo: OtherResource = form.getFieldValue([name, index]);

          if (resourceInfo.groupType === ResourceGroupTypeEnum.user) {
            return (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Input
                  disabled
                  value={
                    resourceInfo.bizType === UserResourceGroupBizTypeEnum.user
                      ? '指定用户'
                      : '指定部门'
                  }
                  style={{ width: 100, marginRight: 5 }}
                />
                <Form.Item name={[index, 'resourceIds']} style={{ width: '100%', marginBottom: 0 }}>
                  {resourceInfo.bizType === UserResourceGroupBizTypeEnum.user ? (
                    <Select
                      labelInValue
                      mode="multiple"
                      allowClear
                      showArrow
                      placeholder={'请选择'}
                      options={resourceInfo?.resourceIdsScope}
                    />
                  ) : (
                    <SearchSelect
                      fetchType={'departmentListUserUnderDepartment'}
                      mode="multiple"
                      labelInValue
                      placeholder={'请选择部门下用户'}
                      searchFieldName="search"
                      params={{
                        departmentIds: resourceInfo.resourceIdsScope?.map((item) => item.value),
                      }}
                    />
                  )}
                </Form.Item>
              </div>
            );
          }
          return (
            <Form.Item name={[index, 'resourceIds']} style={{ width: '100%', marginBottom: 0 }}>
              <Select
                labelInValue
                mode="multiple"
                allowClear
                showArrow
                placeholder={'请选择'}
                options={resourceInfo?.resourceIdsScope}
              />
            </Form.Item>
          );
        },
      },
    ];
  };

  return (
    <BlSortFormList
      name={name}
      isNeedDrag={false}
      isNeedAdd={false}
      isNeedDelete={false}
      renderColumns={() => getColumns()}
    />
  );
};

export default OtherResourceFormTable;
