import { RouteProp } from 'src/route';
import ResourceDefinition from './definitions';
import DefinitionAction from './definitions/actions';
import ResourceDetailContent from './definitions/detail';
import AreaConfigList from './areaConfig/list';
import ResourceTagList from './resourceTag/list';
import ResourceTagDetail from './resourceTag/details/details';
import ParmDefineList from './parmDefine/list';
import ParmDefineDetail from './parmDefine/details/details';
import authDict from 'src/utils/auth';
import ResourceCategory from './category';
import EnergyMeterList from './energyMeter';
import ResourceRecord from './resourceRecord';
import ResourceMonitor from './resourceMonitor';
import { DetailContent as EnergyMeterDetail } from './energyMeter/detail';
import EnergyMeterAction from './energyMeter/actions';
import { DetailContent } from './areaConfig/detail';
import MaintenancePlanList from './maintenancePlan';
import MaintenancePlanAction from './maintenancePlan/actions';
import MaintenancePlanDetail from './maintenancePlan/detail';
import MaintenanceTaskList from './maintenanceTask';
import MaintenanceTaskAction from './maintenanceTask/actions';
import MaintenanceTaskDetail from './maintenanceTask/detail';
import MalfunctionRecordList from './malfunctionRecord';
import MalfunctionRecordActions from './malfunctionRecord/actions';
import MalfunctionRecordDetail from './malfunctionRecord/details';
import { CollectionRuleAction, CollectionRulesList, CollectionRuleDetail } from './collectionRules';
import RepairPlanList from './repairPlan';
import RepairPlanAction from './repairPlan/actions';
import RepairPlanDetail from './repairPlan/detail';
import RepairTaskList from './repairTask';
import RepairTaskAction from './repairTask/actions';
import RepairTaskDetail from './repairTask/detail';

export const resourceRouteConfig: RouteProp[] = [
  {
    path: '/resource',
    menu: '资源',
    icon: 'iconyijicaidan-ziyuan',
    breadcrumb: '资源',
    // redirectPath: '/resource/resourceConfig/area',
    children: [
      {
        path: '/resource/resourceConfig',
        menu: '资源主数据',
        breadcrumb: '资源主数据',
        redirectPath: '/resource/resourceConfig/area',
        children: [
          {
            path: '/resource/resourceConfig/area',
            component: AreaConfigList,
            menu: '区域数据',
            logPage: true,
            breadcrumb: '区域数据',
            auth: authDict.location_view,
            children: [
              {
                path: '/resource/resourceConfig/area/detail',
                component: DetailContent,
                breadcrumb: '区域数据详情',
                auth: authDict.location_detail,
              },
            ],
          },
          {
            path: '/resource/resourceConfig/category',
            component: ResourceCategory,
            menu: '资源分类',
            logPage: true,
            breadcrumb: '资源分类',
            auth: authDict.resourcetype_view,
            children: [
              {
                path: '/resource/resourceConfig/category/detail',
                component: ResourceDetailContent,
                breadcrumb: '资源分类详情',
                auth: authDict.resourcetype_detail,
              },
            ],
          },
          {
            path: '/resource/resourceConfig/resourceTag',
            component: ResourceTagList,
            menu: '资源标签',
            logPage: true,
            breadcrumb: '资源标签',
            auth: authDict.resourcelabel_view,
            children: [
              {
                path: '/resource/resourceconfig/resourceTag/detail',
                component: ResourceTagDetail,
                breadcrumb: '资源标签详情',
                auth: authDict.resourcelabel_detail,
              },
            ],
          },
        ],
      },
      {
        path: '/resource/definitions',
        menu: '设备管理',
        breadcrumb: '设备管理',
        // redirectPath: '/resource/definitions/standingBook',
        children: [
          {
            path: '/resource/definitions/standingBook',
            component: ResourceDefinition,
            menu: '设备台账',
            logPage: true,
            breadcrumb: '设备台账',
            auth: authDict.equipment_view,
            children: [
              {
                path: '/resource/definitions/standingBook/:id/detail',
                component: ResourceDetailContent,
                breadcrumb: '设备台账详情',
                auth: authDict.equipment_detail,
              },
              {
                path: '/resource/definitions/standingBook/create',
                component: DefinitionAction,
                breadcrumb: '新建设备台账',
                auth: authDict.equipment_add,
              },
              {
                path: '/resource/definitions/standingBook/:id/edit',
                component: DefinitionAction,
                breadcrumb: '编辑设备台账',
                auth: authDict.equipment_edit,
              },
              {
                path: '/resource/definitions/standingBook/:id/copy',
                component: DefinitionAction,
                breadcrumb: '复制设备台账',
                auth: authDict.equipment_detail,
              },
            ],
          },
        ],
      },
      {
        path: '/resource/energyMeter',
        menu: '能源管理',
        breadcrumb: '能源管理',
        // redirectPath: '/resource/energyMeter/standingBook',
        children: [
          {
            path: '/resource/energyMeter/standingBook',
            component: EnergyMeterList,
            menu: '能源仪表台账',
            logPage: true,
            breadcrumb: '能源仪表台账',
            auth: authDict.energyinstrument_view,
            children: [
              {
                path: '/resource/energyMeter/standingBook/:id/detail',
                component: EnergyMeterDetail,
                breadcrumb: '能源仪表台账数据详情',
                auth: authDict.energyinstrument_detail,
              },
              {
                path: '/resource/energyMeter/standingBook/create',
                component: EnergyMeterAction,
                breadcrumb: '新建能源仪表台账',
                auth: authDict.energyinstrument_add,
              },
              {
                path: '/resource/energyMeter/standingBook/:id/edit',
                component: EnergyMeterAction,
                breadcrumb: '编辑能源仪表台账',
                auth: authDict.energyinstrument_edit,
              },
              {
                path: '/resource/energyMeter/standingBook/:id/copy',
                component: EnergyMeterAction,
                breadcrumb: '复制能源仪表台账数据',
                auth: authDict.energyinstrument_add,
              },
            ],
          },
        ],
      },
      // {
      // path: '/resource/',
      // menu: '模具管理',
      // breadcrumb: '模具管理',
      // logPage: true,
      // redirectPath: '/resource/',
      // children: [
      // ],
      // },
      {
        path: '/resource/params',
        menu: '资源参数管理',
        breadcrumb: '资源参数管理',
        // redirectPath: '/resource/params/parmDefine',
        children: [
          {
            path: '/resource/params/parmDefine',
            component: ParmDefineList,
            menu: '资源参数定义',
            logPage: true,
            breadcrumb: '资源参数定义',
            auth: authDict.resourceparameter_view,
            children: [
              {
                path: '/resource/params/parmDefine/detail',
                component: ParmDefineDetail,
                breadcrumb: '资源参数定义详情',
                auth: authDict.resourceparameter_detail,
              },
            ],
          },
          {
            path: '/resource/params/collectionRules',
            component: CollectionRulesList,
            menu: '数采规则',
            breadcrumb: '数采规则',
            auth: authDict.collectionrule_view,
            children: [
              {
                path: '/resource/params/collectionRules/:id/detail',
                component: CollectionRuleDetail,
                breadcrumb: '数采规则详情',
                auth: authDict.collectionrule_detail,
              },
              {
                path: '/resource/params/collectionRules/create',
                component: CollectionRuleAction,
                breadcrumb: '新建数采规则',
                auth: authDict.collectionrule_add,
              },
              {
                path: '/resource/params/collectionRules/:id/edit',
                component: CollectionRuleAction,
                breadcrumb: '编辑数采规则',
                auth: authDict.collectionrule_edit,
              },
            ],
          },
          {
            path: '/resource/params/resourceRecord',
            component: ResourceRecord,
            menu: '资源参数记录',
            logPage: true,
            auth: authDict.resourceparameterrecord_view,
            breadcrumb: '资源参数记录',
          },
          {
            path: '/resource/params/resourceMonitor',
            component: ResourceMonitor,
            menu: '资源参数监控',
            logPage: true,
            auth: authDict.resourceparametermonitoring_view,
            breadcrumb: '资源参数监控',
          },
        ],
      },
      {
        path: '/resource/implement',
        menu: '资源执行管理',
        breadcrumb: '资源执行管理',
        // redirectPath: '/resource/implement/maintenance/plan',
        children: [
          {
            path: '/resource/implement/maintenance/plan',
            component: MaintenancePlanList,
            menu: '资源维保方案',
            breadcrumb: '资源维保方案',
            logPage: true,
            auth: authDict.maintenanceCase_view,
            children: [
              {
                path: '/resource/implement/maintenance/plan/create',
                component: MaintenancePlanAction,
                breadcrumb: '新建资源维保方案',
                auth: authDict.maintenanceCase_add,
              },
              {
                path: '/resource/implement/maintenance/plan/:id/edit',
                component: MaintenancePlanAction,
                breadcrumb: '编辑资源维保方案',
                auth: authDict.maintenanceCase_edit,
              },
              {
                path: '/resource/implement/maintenance/plan/:id/copy',
                component: MaintenancePlanAction,
                breadcrumb: '复制资源维保方案',
                auth: authDict.maintenanceCase_add,
              },
              {
                path: '/resource/implement/maintenance/plan/:id/detail',
                component: MaintenancePlanDetail,
                breadcrumb: '资源维保方案详情',
                auth: authDict.maintenanceCase_detail,
              },
            ],
          },
          {
            path: '/resource/implement/maintenance/task',
            component: MaintenanceTaskList,
            menu: '资源维保任务',
            breadcrumb: '资源维保任务',
            logPage: true,
            auth: authDict.maintenanceTask_view,
            children: [
              {
                path: '/resource/implement/maintenance/task/create',
                component: MaintenanceTaskAction,
                breadcrumb: '新建资源维保任务',
                auth: authDict.maintenanceTask_add,
              },
              {
                path: '/resource/implement/maintenance/task/:id/edit',
                component: MaintenanceTaskAction,
                breadcrumb: '编辑资源维保任务',
                auth: authDict.maintenanceTask_edit,
              },
              {
                path: '/resource/implement/maintenance/task/:id/copy',
                component: MaintenanceTaskAction,
                breadcrumb: '复制资源维保任务',
                auth: authDict.maintenanceTask_add,
              },
              {
                path: '/resource/implement/maintenance/task/:id/detail',
                component: MaintenanceTaskDetail,
                breadcrumb: '资源维保任务详情',
                auth: authDict.maintenanceTask_detail,
              },
            ],
          },
          {
            path: '/resource/implement/repair/plan',
            component: RepairPlanList,
            menu: '资源维修方案',
            breadcrumb: '资源维修方案',
            logPage: true,
            auth: authDict.repairCase_view,
            children: [
              {
                path: '/resource/implement/repair/plan/create',
                component: RepairPlanAction,
                breadcrumb: '新建资源维修方案',
                auth: authDict.repairCase_add,
              },
              {
                path: '/resource/implement/repair/plan/:id/edit',
                component: RepairPlanAction,
                breadcrumb: '编辑资源维修方案',
                auth: authDict.repairCase_edit,
              },
              {
                path: '/resource/implement/repair/plan/:id/copy',
                component: RepairPlanAction,
                breadcrumb: '复制资源维修方案',
                auth: authDict.repairCase_add,
              },
              {
                path: '/resource/implement/repair/plan/:id/detail',
                component: RepairPlanDetail,
                breadcrumb: '资源维修方案详情',
                auth: authDict.repairCase_detail,
              },
            ],
          },
          {
            path: '/resource/implement/repair/task',
            component: RepairTaskList,
            menu: '资源维修任务',
            breadcrumb: '资源维修任务',
            logPage: true,
            auth: authDict.repairTask_view,
            children: [
              {
                path: '/resource/implement/repair/task/create',
                component: RepairTaskAction,
                breadcrumb: '新建资源维修任务',
                auth: authDict.repairTask_add,
              },
              {
                path: '/resource/implement/repair/task/:id/edit',
                component: RepairTaskAction,
                breadcrumb: '编辑资源维修任务',
                auth: authDict.repairTask_edit,
              },
              {
                path: '/resource/implement/repair/task/:id/copy',
                component: RepairTaskAction,
                breadcrumb: '复制资源维修任务',
                auth: authDict.repairTask_add,
              },
              {
                path: '/resource/implement/repair/task/:id/detail',
                component: RepairTaskDetail,
                breadcrumb: '资源维修任务详情',
                auth: authDict.repairTask_detail,
              },
            ],
          },
          {
            path: '/resource/implement/malfunctionRecord',
            component: MalfunctionRecordList,
            menu: '资源故障记录',
            breadcrumb: '资源故障记录',
            logPage: true,
            auth: authDict.resourceMalfunctionRecord_view,
            children: [
              {
                path: '/resource/implement/malfunctionRecord/create',
                component: MalfunctionRecordActions,
                breadcrumb: '新建故障记录',
                auth: authDict.resourceMalfunctionRecord_add,
              },
              {
                path: '/resource/implement/malfunctionRecord/:id/edit',
                component: MalfunctionRecordActions,
                breadcrumb: '编辑故障记录',
                auth: authDict.resourceMalfunctionRecord_edit,
              },
              {
                path: '/resource/implement/malfunctionRecord/:id/detail',
                component: MalfunctionRecordDetail,
                breadcrumb: '故障记录详情',
                auth: authDict.resourceMalfunctionRecord_detail,
              },
            ],
          },
        ],
      },
    ],
  },
];
