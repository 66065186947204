import React, { ReactNode, useContext, useEffect } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Input, FormInstance, Popover } from 'antd';
import { AreaDetailData } from '../interface';
import { QrCode, BlIcon, NumberRulesStandardHook } from 'components';
import TextArea from 'antd/lib/input/TextArea';
import { StoreContext } from '../constant';
import { replaceSign } from 'src/utils/constants';
import { textValidator1, textValidator2 } from 'src/utils/formValidators';

interface baseInfoProps {
  onCancel: () => void;
  onFinish: () => void;
  title: string;
  initialData?: AreaDetailData;
  form: FormInstance;
  edit: boolean;
  extra?: ReactNode;
  refreshMarker?: number;
}

const AreaBaseInfo = (props: baseInfoProps) => {
  const { onCancel, title, onFinish, initialData, form, edit, extra, refreshMarker } = props;

  const { parentAreaSource, storeHouseSource } = useContext(StoreContext);

  const { parentAreaName, parentAreaCode, parentAreaLevel } = parentAreaSource;

  const { StoreHouseName, StoreHouseCode } = storeHouseSource;

  const showParentAreaName = parentAreaLevel === 1 ? replaceSign : parentAreaName;

  const showParentAreaCode = parentAreaLevel === 1 ? replaceSign : parentAreaCode;

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    items: [
      {
        label: '仓库名称',
        render: () => (edit ? initialData?.warehouseName ?? replaceSign : StoreHouseName),
      },
      {
        label: '仓库编号',
        render: () => (edit ? initialData?.warehouseCode ?? replaceSign : StoreHouseCode),
      },
      {
        label: '上级区域名称',
        render: () => (edit ? initialData?.parentName ?? replaceSign : showParentAreaName),
      },
      {
        label: '上级区域编号',
        render: () => (edit ? initialData?.parentCode ?? replaceSign : showParentAreaCode),
      },
      {
        label: '区域名称',
        name: 'name',
        rules: [
          { required: true, message: '区域名称不能为空' },
          { validator: textValidator1 },
          { max: 255, message: '不超过255个字符' },
        ],
        render: () => <Input placeholder="请输入" />,
      },
      ...NumberRulesStandardHook({
        label: '区域编号',
        refreshMarker,
        form,
        edit,
        objectCode: 'Zone',
        fieldCode: 'code',
        rules: [{ max: 255, message: '不超过255字符' }, { validator: textValidator2 }],
      }),
      {
        label: '标识码',
        name: 'qrCode',
        hidden: !edit,
        rules: [{ validator: textValidator1 }, { max: 255, message: '不超过255个字符' }],
        render: () => (
          <Input
            suffix={
              <Popover
                placement="rightTop"
                content={
                  <div>
                    <QrCode value={initialData?.qrCode ?? initialData?.code ?? ''} />
                    <div style={{ textAlign: 'center' }}>
                      {initialData?.qrCode ?? initialData?.code ?? ''}
                    </div>
                  </div>
                }
              >
                <BlIcon type="iconerweima" />
              </Popover>
            }
          />
        ),
      },
      {
        label: '编辑原因',
        name: 'operateReason',
        hidden: !edit,
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
    ],
  };

  useEffect(() => {
    if (initialData) {
      form?.setFieldsValue({
        name: initialData?.name,
        code: initialData?.code,
        warehouseName: initialData?.warehouseName,
        warehouseCode: initialData?.warehouseCode,
        parentCode: initialData?.parentCode,
        parentName: initialData?.parentName,
        id: initialData?.id,
        qrCode: initialData?.qrCode ?? initialData?.code,
        remark: initialData?.remark,
      });
    } else {
      form?.setFieldsValue({
        code_isUseRules: true,
      });
    }
  }, [initialData]);

  return (
    <DataFormLayout
      extra={extra}
      form={form}
      title={title}
      info={[baseInfo]}
      onCancel={onCancel}
      onFinish={onFinish}
    />
  );
};

export default AreaBaseInfo;
