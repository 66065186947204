/**
 * 其他信息
 */
import { Form, FormInstance, Input } from 'antd';
import moment from 'moment';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { BomFormOther } from './index.type';

interface OtherInfoFormProps {
  form: FormInstance;
  name: string;
}

// 格式化 其他信息 给接口
export const formatOtherToApi = (other?: BomFormOther) => {
  return { remark: other?.remark };
};

export const formatOtherToForm = (other?: any): BomFormOther => {
  return { remark: other?.remark };
};

export const OtherInfoForm = (props: OtherInfoFormProps) => {
  const { form, name } = props;

  const operator = form.getFieldValue(['planOrderBaseCO', 'operator']);
  const creator = form.getFieldValue(['planOrderBaseCO', 'creator']);

  return (
    <div>
      <Form form={form} name={name} labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
        <Form.Item label="备注" name={[name, 'remark']}>
          <Input.TextArea maxLength={1000} showCount />
        </Form.Item>
        <Form.Item label="创建人">
          {operator?.id && (
            <AvatarDisplay
              id={creator?.id}
              name={creator?.name}
              key={creator?.id}
              avatarUrl={creator?.avatarUrl}
              isShowTag={false}
              isUser
            />
          )}
        </Form.Item>
        <Form.Item label="创建时间">
          {moment(form.getFieldValue(['planOrderBaseCO', 'createdAt'])).format(
            'YYYY-MM-DD HH:mm:ss',
          )}
        </Form.Item>
        <Form.Item label="更新人">
          {operator?.id && (
            <AvatarDisplay
              id={operator?.id}
              name={operator?.name}
              key={operator?.id}
              avatarUrl={operator?.avatarUrl}
              isShowTag={false}
              isUser
            />
          )}
        </Form.Item>
        <Form.Item label="更新时间">
          {moment(form.getFieldValue(['planOrderBaseCO', 'updatedAt'])).format(
            'YYYY-MM-DD HH:mm:ss',
          )}
        </Form.Item>
      </Form>
    </div>
  );
};
