/**
 * @page 自定义对象实例详情
 */
import { FC, useState, useEffect, useCallback } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import _, { map } from 'lodash';
import { fetchCustomObjectDetail } from 'src/api/ytt/custom-object-domain';
import { fetchCustomFieldGetListByCustomObjectCode } from 'src/api/ytt/metadata-domain/customField';
import { fetchStandardBizObjectCustomObjectGetSonObjectAndFieldById } from 'src/api/ytt/metadata-domain/objectPlatform';
import { SingleOperationLogComponent } from 'src/components';
import { CustomDetail, getDefaultDetailLayout } from 'src/page/customLayout';
import {
  transformFieldsToKV,
  transformSubObjectsToKV,
  getKVOfOtherInfo,
  getEditPath,
  getCopyPath,
  getObjectAuthCode,
} from '../utils';
import type { DetailLayoutMenuItem } from 'src/layout';
import type { FormLayoutRootConfigProps } from 'src/page/customLayout/types';
import { fetchMatchRuleQueryMatchTemplate } from 'src/api/ytt/e-report-domain/matchRule';
import ChooseTemplateModal from 'src/page/analyse/objectMatchingRule/share/chooseTemplateModal';
import { OperationCode } from '../types';

interface Props {
  objectCode: string;
  objectName: string;
}
interface Params {
  instanceId: string;
}

const CustomObjectDetail: FC<Props> = ({ objectCode, objectName }) => {
  const { instanceId } = useParams<Params>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<any>(null);

  const [layoutConfig, setLayoutConfig] = useState<FormLayoutRootConfigProps | null>(null);
  const [visibleLog, setVisibleLog] = useState(false);

  const [chooseVisible, setChooseVisible] = useState<boolean>(false);
  const [templateData, setTemplateData] = useState<any>([]);
  const [params, setParams] = useState<any>();
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [isShowReport, setIsShowReport] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const resList = await Promise.all([
        // 获取对象的字段定义
        fetchCustomFieldGetListByCustomObjectCode({ objectCode }),
        // 获取对象的从对象定义
        fetchStandardBizObjectCustomObjectGetSonObjectAndFieldById({ objectCode }),
        // 加载对象实例数据
        fetchCustomObjectDetail({
          instanceId: _.toNumber(instanceId),
          objectCode,
        }),
      ]);
      const fieldList = resList[0].data ?? [];
      const subObjectList = resList[1].data ?? [];
      const entityFieldList = resList[2].data?.fields ?? [];
      const entitySubObjects = resList[2].data?.sonObjects ?? [];

      setLayoutConfig(getDefaultDetailLayout(objectName, fieldList, subObjectList));
      setDataSource({
        ...transformFieldsToKV(entityFieldList),
        ...transformSubObjectsToKV(entitySubObjects),
        ...getKVOfOtherInfo(resList[2].data),
      });
    } finally {
      setLoading(false);
    }
  }, [objectCode, instanceId]);

  useEffect(() => {
    fetchData();
  }, [objectCode, instanceId]);

  const getMatchTemplate = async (instanceId: string, objectCode: string) => {
    const { data } = await fetchMatchRuleQueryMatchTemplate({
      instanceId,
      objectCode,
    });

    if (data) {
      setTemplateData(
        map(data, (e: any) => {
          return {
            ...e,
            instanceId,
            objectCode,
          };
        }),
      );

      setChooseVisible(true);
    }
  };

  const menu: DetailLayoutMenuItem[] = [
    {
      title: '复制',
      key: 'copy',
      icon: 'iconfuzhi',
      auth: getObjectAuthCode(objectCode, OperationCode.add),
      onClick() {
        history.push(generatePath(getCopyPath(), { instanceId }));
      },
    },
    {
      title: '查看操作记录',
      key: 'record',
      auth: getObjectAuthCode(objectCode, OperationCode.operrecord),
      onClick() {
        setVisibleLog(true);
      },
    },
    {
      title: '打印预览',
      key: 'print',
      onClick() {
        getMatchTemplate(instanceId, objectCode);
      },
    },
    {
      title: '编辑',
      key: 'edit',
      icon: 'iconbianji',
      auth: getObjectAuthCode(objectCode, OperationCode.edit),
      onClick() {
        history.push(generatePath(getEditPath(), { instanceId }));
      },
    },
  ];

  return (
    <>
      <CustomDetail
        loading={loading}
        dataSource={dataSource}
        layoutConfig={layoutConfig}
        baseMenu={menu}
      />
      <SingleOperationLogComponent
        visible={visibleLog}
        instanceId={_.toNumber(instanceId)}
        objectCode={objectCode}
        closeDetail={() => {
          setVisibleLog(false);
        }}
      />
      {chooseVisible && (
        <ChooseTemplateModal
          visible={chooseVisible}
          initData={templateData}
          onCancel={() => {
            setChooseVisible(false);
          }}
        />
      )}
    </>
  );
};

export default CustomObjectDetail;
