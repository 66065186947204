/*
 * @Author:
 * @Date: 2021-05-12 19:38:08
 * @LastEditors: Vicky Yu
 * @LastEditTime: 2021-05-13 18:57:18
 * @Description:
 * @FilePath: /web-v3/src/custom_fields/src/views/fieldsList/components/fieldFormItems/multiLine.tsx
 */
import React from 'react';
import { Input, Radio, Form, FormInstance } from 'antd';
import {
  validateBlInputNumberIntervalInt,
  validatorCharacterLen,
  validatorCheckTwoSidesTrim,
} from '../verificationRules';
import { appDict, appEnum } from 'src/dict';

interface Props {
  form: FormInstance;
  fieldCategory?: number;
  isPreField?: boolean;
  isEdit?: boolean;
  canSetIsRequired?: boolean;
}

const { TextArea } = Input;

export const multiLine = (props: Props) => {
  const { form, isEdit, isPreField } = props;
  const DEFAULT_MAX_LENGTH = 1000;

  return [
    {
      label: '必填',
      name: 'isRequired',
      initialValue: appEnum.Common.YN.no,
      rules: [{ required: true, message: '请选择必填' }],
      render: () => (
        <Radio.Group
          disabled={isPreField && form.getFieldValue('isRequired') === appEnum.Common.YN.yes}
          options={appDict.common.yn}
        />
      ),
    },
    {
      label: '数据唯一性',
      name: 'isUnique',
      initialValue: appEnum.Common.YN.no,
      rules: [{ required: true, message: '请选择唯一属性' }],
      render: () => <Radio.Group disabled={isEdit} options={appDict.common.yn} />,
    },
    {
      label: '最大长度',
      name: 'maxLength',
      initialValue: DEFAULT_MAX_LENGTH,
      rules: [
        { required: true, message: '请选择最大长度' },
        { validator: validateBlInputNumberIntervalInt(1, 1000) },
      ],
      validateFirst: true,
      render: () => (
        <Input
          disabled={isEdit}
          onChange={() => {
            form.validateFields(['defaultValue']);
          }}
        />
      ),
    },
    {
      label: '默认值',
      shouldUpdate: (prevValues: any, curValues: any) => {
        return prevValues.maxLength !== curValues.maxLength;
      },
      render: () => {
        return () => {
          return (
            <Form.Item
              name="defaultValue"
              rules={[
                { validator: validatorCheckTwoSidesTrim() },
                {
                  validator: validatorCharacterLen(props.form.getFieldValue('maxLength')),
                },
              ]}
              validateFirst
            >
              <TextArea disabled={isPreField} />
            </Form.Item>
          );
        };
      },
    },
  ];
};
