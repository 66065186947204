import { Tag } from 'antd';
import _ from 'lodash';
import { renderUser } from 'src/page/share/renderUser';
import lookup from 'src/dict';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { fieldTypeList } from 'utils';
import { FilterItem } from 'src/layout';
import { SearchSelect, TagList } from 'src/components';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { purchaseExecStatus, purchaseReturnSource } from 'src/dict/purchase/mappings';
import { PurchaseReturnOrderListMaterialItem } from '../interface';
import { renderAmount } from '../utils';
import _Array from 'src/utils/dataTypes/array';
import { replaceSign } from 'src/utils/constants';
import { Attribute } from 'src/page/sales/salesManagement/invoice/interface';

// 采购退货订单视图列左半部分
const ordersListLeft = [
  {
    title: '来源',
    dataIndex: 'source',
    width: 150,
    render: lookup('purchase.purchaseReturnSource'),
  },
  {
    title: '状态',
    dataIndex: 'execStatus',
    width: 150,
    render: lookup('purchase.purchaseExecStatus'),
  },
  {
    title: '供应商编号',
    dataIndex: ['supplier', 'code'],
    width: 150,
  },
  {
    title: '供应商名称',
    dataIndex: ['supplier', 'name'],
    width: 150,
  },
];

// 采购退货订单视图列右半部分
const ordersListRight = [
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    width: 150,
    sorter: true,
    render: formatTimeForRender,
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    width: 150,
    render: renderUser,
  },
  {
    title: '更新时间',
    dataIndex: 'updatedAt',
    width: 150,
    sorter: true,
    render: formatTimeForRender,
  },
  {
    title: '更新人',
    dataIndex: 'operator',
    width: 150,
    render: renderUser,
  },
];

// 物料行视图列中间部分
const materialsListMedium = [
  {
    title: '行号',
    dataIndex: 'seqNo',
    width: 150,
  },
  {
    title: '物料编号',
    dataIndex: ['material', 'baseInfo', 'code'],
    width: 150,
  },
  {
    title: '物料名称',
    dataIndex: ['material', 'baseInfo', 'name'],
    width: 150,
  },
  {
    title: '物料属性',
    dataIndex: ['material', 'attribute'],
    width: 150,
    render: (value: Attribute, record: PurchaseReturnOrderListMaterialItem) => {
      if (_Array.isEmpty(value)) {
        return replaceSign;
      }
      return (
        <TagList
          dataSource={_.map(value, ({ id, name, attributeItem }) => ({
            label: `${name}:${attributeItem?.content}`,
            value: id,
          }))}
        />
      );
    },
  },
  {
    title: '应退数',
    dataIndex: 'returnAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '应退时间',
    dataIndex: 'returnTime',
    width: 150,
    sorter: true,
    render: formatTimeForRender,
  },
  {
    title: '计划出库数',
    dataIndex: 'planOutboundAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '发出数',
    dataIndex: 'deliverAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '接收数',
    dataIndex: 'receivedAmount',
    width: 150,
    render: renderAmount,
  },
];

// 采购退货单视图展示列
export const dataColumnsForOrdersList = [...ordersListLeft, ...ordersListRight];

// 物料行视图展示列
export const dataColumnsForMaterialList = [
  ...ordersListLeft,
  ...materialsListMedium,
  ...ordersListRight,
];

// 采购退货单视图筛选列
export const ordersFilterList: FilterItem[] = [
  {
    label: '编号',
    name: 'code',
    type: fieldTypeList.text,
  },
  {
    label: '来源',
    name: 'source',
    type: fieldTypeList.multiSelect,
    selectProps: {
      options: purchaseReturnSource,
      mode: 'multiple',
    },
  },
  {
    label: '状态',
    name: 'execStatusList',
    type: fieldTypeList.multiSelect,
    selectProps: {
      options: purchaseExecStatus,
      mode: 'multiple',
    },
  },
  {
    label: '供应商编号',
    name: 'supplierCode',
    type: fieldTypeList.text,
  },
  {
    label: '供应商名称',
    name: 'supplierName',
    type: fieldTypeList.text,
  },
  {
    label: '创建时间',
    name: 'createdAt',
    type: fieldTypeList.date,
  },
  {
    label: '创建人',
    name: 'creator',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <UserOrDepartmentSelectWithDialog placeholder={'请选择订单创建人'} isMultiple isSelectUser />
    ),
  },
  {
    label: '更新时间',
    name: 'updatedAt',
    type: fieldTypeList.date,
  },
  {
    label: '更新人',
    name: 'operator',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <UserOrDepartmentSelectWithDialog placeholder={'请选择更新人'} isMultiple isSelectUser />
    ),
  },
];

// 物料行视图筛选列
const cloneFilterList = _.cloneDeep(ordersFilterList);

cloneFilterList.splice(
  5,
  0,
  {
    label: '物料',
    name: 'material',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <SearchSelect placeholder={'请选择物料'} mode="multiple" fetchType="materialCodeName" />
    ),
  },
  {
    label: '应退时间',
    name: 'returnAt',
    type: fieldTypeList.date,
  },
);
export const materialsFilterList: FilterItem[] = cloneFilterList;
