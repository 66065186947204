import { useState } from 'react';
import { Switch, Form } from 'antd';

import { appEnum } from 'src/dict';
import ConditionEditTableLayout from '../../share/ConditionEditTableLayout';
import RedRemindText from '../../share/RedRemindText';
import RuleConditionValue from './RuleConditionValue';
import RuleValueType from './RuleValueType';
import RuleConditionType from './RuleConditionType';
import FieldIdCascader, { useStandardBizToCascaderOption } from '../../share/FieldIdCascader';
import _ from 'lodash';

const style = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};

const MySwitch = (props: any) => {
  const oldChange = props.onChange;
  const newProps = {
    ...props,
    checked: Boolean(props.value),
    onChange: (e: any) => {
      oldChange(Number(e));
    },
  };

  return (
    <div style={{ display: 'flex', height: '100%', justifyContent: 'space-around' }}>
      <Form.Item>
        <Switch {...newProps} />
      </Form.Item>
      <div style={style}>
        {!!props.value && <Form.Item>编辑前</Form.Item>}
        <Form.Item>编辑后</Form.Item>
      </div>
    </div>
  );
};

const RuleConditionEditTable = (props: any) => {
  const { code, disabled, triggerType, form, haveEditTriggerEventType, useParent } = props;

  const [loading, setLoading] = useState<boolean>(false);

  /** 用于判断item是否要被丢弃，满足条件的item 返回true */
  const discardedItem = (item: any) => {
    // if (
    // 预置对象不支持选择自定义字段
    // 预置对象的含义是查询对象返回的objectCategory = 2
    // 自定义字段是指tree_by_code返回的fieldCategory=1
    //   objectCategory === 2 &&
    //   item.fieldCategory === appEnum.CustomField.FieldCategory.customFields
    // ) {
    //   return true;
    // }
    // // 字段类型为链接，主从关系，不支持选择
    if (
      item.fieldType === appEnum.CustomField.FieldType.url
      // item.fieldType === appEnum.CustomField.FieldType.subordinate
    ) {
      return true;
    }
    return false;
  };

  /** 获取级联组件option中的额外信息，info */
  const getDataInfo = ({ fieldType, choiceValues, targetType, reference }: any) => ({
    fieldType,
    choiceValues,
    targetType,
    reference,
  });

  /** 业务字段的级联选项，五层 */
  const fieldOptions = useStandardBizToCascaderOption(
    disabled ? '' : code,
    4, // depth
    { setLoading, discardedItem, getDataInfo },
  );

  const getNamePath = (nameList: (string | number)[], ...rest: any[]) =>
    _.concat(nameList, ...rest);
  const dataInfo = {
    colProps: { span: 4 },
    rowProps: { gutter: 24 },
    columns: [
      {
        title: '条件id',
        name: 'conditionId',
        noStyle: true,
        hide: true,
      },
      {
        title: '字段',
        colProps: { span: haveEditTriggerEventType ? 4 : 6 },
        render: (parentPath: (string | number)[], namePath: (string | number)[]) => {
          if (_.isNil(code)) {
            return <RedRemindText text="请先选择业务对象" />;
          }
          return (
            <Form.Item
              name={getNamePath(namePath, 'fieldId')}
              rules={[{ required: true, message: '请选择字段' }]}
            >
              <FieldIdCascader
                loading={loading}
                disabled={disabled}
                // 按业务动作触发， 字段选项只展开一层
                // 定时触发，字段选项展开五层
                options={
                  triggerType === appEnum.TriggerRule.TriggerType.bizEventTrigger
                    ? fieldOptions
                    : fieldOptions.map((item) => ({ ..._.omit(item, ['children']), isLeaf: true }))
                }
                onSelect={(values) => {
                  if (values.length > 1) {
                    form.setFields([
                      { name: [...parentPath, ...namePath, 'updateDataCheck'], value: 0 },
                    ]);
                  }
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '编辑数据校验',
        hide: !haveEditTriggerEventType,
        render: (parentPath: (string | number)[], namePath: (string | number)[]) => {
          return (
            <Form.Item noStyle shouldUpdate>
              {(form) => {
                const fieldId = form.getFieldValue([...parentPath, ...namePath, 'fieldId']);

                return (
                  <Form.Item name={_.concat(namePath, 'updateDataCheck')} initialValue={0}>
                    <MySwitch disabled={disabled || fieldId?.length > 1} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '条件',
        colProps: { span: haveEditTriggerEventType ? 4 : 6 },
        render: (parentPath: (string | number)[], namePath: (string | number)[]) => {
          const basePath = [...parentPath, ...namePath].join('.');
          const dependencies = [`${basePath}.updateDataCheck`, `${basePath}.fieldId`];

          return (
            <RuleConditionType
              field={{ name: namePath, parentPath, namePath }}
              dependencies={dependencies}
              disabled={disabled}
              needFormItem={false}
            />
          );
        },
      },
      {
        title: '值类型',
        colProps: { span: 6 },
        render: (parentPath: (string | number)[], namePath: (string | number)[]) => {
          const basePath = [...parentPath, ...namePath].join('.');
          const dependencies = [
            `${basePath}.updateDataCheck`,
            `${basePath}.beforeConditionType`,
            `${basePath}.afterConditionType`,
            'triggerType',
          ];

          return (
            <RuleValueType
              field={{ name: namePath, parentPath, namePath }}
              dependencies={dependencies}
              disabled={disabled}
              needFormItem={false}
              triggerType
            />
          );
        },
      },
      {
        title: '值',
        colProps: { span: 6 },
        render: (parentPath: (string | number)[], namePath: (string | number)[]) => {
          const basePath = [...parentPath, ...namePath].join('.');
          const dependencies = [
            `${basePath}.updateDataCheck`,
            `${basePath}.beforeValueType`,
            `${basePath}.afterValueType`,
            `${basePath}.beforeConditionType`,
            `${basePath}.afterConditionType`,
            `${basePath}.fieldId`,
            'objectCode',
          ];

          return (
            <RuleConditionValue
              bizLoading={loading}
              bizOptions={fieldOptions}
              field={{ name: namePath, parentPath, namePath }}
              dependencies={dependencies}
              disabled={disabled}
              needFormItem={false}
            />
          );
        },
      },
    ],
  };

  return (
    <div style={{ marginLeft: useParent === 'triggerAction' ? '' : '82px' }}>
      <ConditionEditTableLayout
        {...props}
        info={dataInfo}
        namePath={['triggerCondition', 'conditionGroups']}
        totalLevel={2}
      />
    </div>
  );
};

export default RuleConditionEditTable;
