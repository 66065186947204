import React, { useEffect, useState } from 'react';
import { DetailLayoutForDrawer, DetailLayout } from 'layout';
import { fetchBatchNoDetail } from 'src/api/ytt/material-domain/Batch_No';
import _ from 'lodash';
import { BlTable, ThumbnailImages } from 'components';
import { Badge } from 'antd';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import lookup, { appEnum } from 'src/dict';
import { fetchCustomFieldInstanceGetByObjectCode } from 'src/api/ytt/custom-object-domain';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { ICustomFields, injectCustomFieldInfos } from 'src/components/customField';
import { BatchAttrs } from 'src/dict/material';
import { getMaterialbBatchAttrs } from 'src/entity/material';

interface InventoryDetailProps {
  onClose: (close: boolean) => void;
  visible?: boolean;
  batchNo?: string;
  materialId?: number;
  refreshDetailsMarker: number | undefined;
}

const BatchDetail: React.FC<InventoryDetailProps> = (props) => {
  const { onClose, visible: visibleProp, batchNo, materialId, refreshDetailsMarker } = props;
  const [visible, setVisible] = useState<boolean>(visibleProp ?? false);
  const [baseInfo, setBaseInfo] = useState<any>([]);
  const [dataSource, setDataSource] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [customFields, setCustomFields] = useState<ICustomFields>();

  /** 获取对象自定义字段 */
  const fetchCustomFields = async () => {
    const res = await fetchCustomFieldInstanceGetByObjectCode({
      objectCode: OBJECT_OF_CODE.batchNo,
    });

    setCustomFields((res?.data as any) || {});
  };

  useEffect(() => {
    setVisible(visibleProp ?? false);
  }, [visibleProp]);

  useEffect(() => {
    fetchCustomFields();
  }, []);

  useEffect(() => {
    if (batchNo && materialId) {
      setLoading(true);
      fetchBatchNoDetail({ batchNo, materialId })
        .then((res) => {
          setDataSource(_.get(res, 'data', {}));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [batchNo, materialId, refreshDetailsMarker]);

  useEffect(() => {
    const MaterialInfo = {
      title: '物料信息',
      items: [
        {
          label: '物料名称',
          dataIndex: 'materialInfo.baseInfo.name',
        },
        { label: '物料编号', dataIndex: 'materialInfo.baseInfo.code' },
        {
          label: '物料分类',
          dataIndex: 'materialInfo.category.name',
          render: (text: string) => text || replaceSign,
        },
        {
          label: '物料状态',
          dataIndex: 'materialInfo.baseInfo.enableFlag',
          isFullLine: true,
          render: (val: number) => {
            return (
              <Badge
                status={val === appEnum.Common.UsageStatus.enabled ? 'success' : 'error'}
                text={lookup('common.usageStatus', val)}
              />
            );
          },
        },
        {
          label: '物料属性',
          dataIndex: 'materialInfo.attribute',
          isFullLine: true,
          render(val: any) {
            const columns = [
              {
                title: '属性项',
                dataIndex: 'name',
                width: 150,
              },
              {
                title: '属性值',
                dataIndex: ['attributeItem', 'content'],
                width: 150,
              },
            ];

            return <BlTable rowKey="id" columns={columns} dataSource={val} pagination={false} />;
          },
        },
        {
          label: '物料规格',
          isFullLine: true,
          dataIndex: 'materialInfo.baseInfo.specification',
        },
        {
          label: '物料图片',
          isFullLine: true,
          dataIndex: 'materialInfo.documents',
          render: (value: any) => <ThumbnailImages dataSource={value} />,
        },
        {
          label: '备注',
          isFullLine: true,
          dataIndex: 'materialInfo.baseInfo.remark',
        },
      ],
    };

    /** 获取字段交互规则 */
    const getFieldInteractRule = (material: any, fieldEnum: number) => {
      const attrs = getMaterialbBatchAttrs(material, fieldEnum);

      return {
        show: Boolean(attrs.batchManagementEnable && attrs.displayEnable),
        required: Boolean(attrs.batchManagementEnable && attrs.displayEnable && !attrs.nullEnable),
      };
    };

    const getBatchInfo = (material: any) => {
      const customer = getFieldInteractRule(material, BatchAttrs.customer);
      const supplier = getFieldInteractRule(material, BatchAttrs.supplier);
      const supplierBatch = getFieldInteractRule(material, BatchAttrs.supplierBatch);
      const productionDate = getFieldInteractRule(material, BatchAttrs.productionDate);
      const validUntil = getFieldInteractRule(material, BatchAttrs.validUntil);

      return {
        title: '批次信息',
        items: _.compact([
          {
            label: '批次号',
            dataIndex: 'batchNo',
          },
          customer.show && {
            label: '客户',
            dataIndex: 'customer.name',
          },
          supplier.show && {
            label: '供应商',
            dataIndex: 'supplier.name',
          },
          supplierBatch.show && {
            label: '供应商批次',
            dataIndex: 'supplierBatch',
          },
          productionDate.show && {
            label: '生产日期',
            dataIndex: 'produceAt',
            render: (value: number) => (value ? _Time.formatDate(value) : replaceSign),
          },
          validUntil.show && {
            label: '有效期至',
            dataIndex: 'validTo',
            render: (value: number) => (value ? _Time.formatDate(value) : replaceSign),
          },
        ]),
      };
    };

    let customInfo;

    if (customFields) {
      customInfo = injectCustomFieldInfos({ customFields, type: 'detail' });
    }

    setBaseInfo(_.compact([MaterialInfo, getBatchInfo(dataSource?.materialInfo), customInfo]));
  }, [batchNo, customFields, dataSource]);

  return (
    <DetailLayoutForDrawer
      width={800}
      visible={visible}
      onClose={onClose}
      content={
        <DetailLayout
          loading={loading}
          title={'批次详情'}
          info={baseInfo}
          dataSource={dataSource}
        />
      }
    />
  );
};

export default BatchDetail;
