import React, { useState } from 'react';
import { DataFormLayout, DataFormLayoutForModal, DataFormLayoutInfoBlock } from 'layout';
import { Form, Checkbox, message as Message, Input } from 'antd';
import { checkTwoSidesTrim } from 'src/utils/formValidators';
import { fetchQcCheckItemCategoryCreate } from 'src/api/ytt/quality-domain/checkClass';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';

interface createProps {
  visible: Boolean;
  action: number;
  closeCreateButton: () => void;
  refreshList?: () => void;
}

const CreateCheckItemClassification = (props: createProps) => {
  const { visible, action, closeCreateButton, refreshList } = props;

  const [modalForm] = Form.useForm();
  const [continuousCreate, setContinuousCreate] = useState(false);

  const extra = (
    <Checkbox
      onChange={(e) => {
        setContinuousCreate(e?.target?.checked);
      }}
    >
      连续新建
    </Checkbox>
  );

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();

      const { data } = await fetchQcCheckItemCategoryCreate(value);

      if (data) {
        Message.success('创建成功');
        typeof refreshList === 'function' && refreshList();
        if (continuousCreate) {
          modalForm.resetFields();
          return;
        }
        typeof closeCreateButton === 'function' && closeCreateButton();
        return;
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    items: [
      {
        label: '检验项分类名称',
        name: 'name',
        rules: [
          { required: true },
          { max: 255, message: '不能超过255个字符' },
          { validator: checkTwoSidesTrim },
        ],
        render: () => <Input />,
      },
    ],
  };

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      onClose={() => {
        closeCreateButton();
      }}
      width={800}
      content={
        <DataFormLayout
          form={modalForm}
          title="新建检验项分类"
          info={[baseInfo]}
          onCancel={() => {
            closeCreateButton();
          }}
          onFinish={throttleFn(handleFinish)}
          extra={action ? null : extra}
        />
      }
    />
  );
};

export default CreateCheckItemClassification;
