import React from 'react';
import { Col, Row } from 'antd';
import SummaryCard from './SummaryCard';
import { CHART_NAME } from 'src/dict/purchase';

const SummaryList: React.FC = () => {
  const summaryListData = [
    {
      title: '发货进度',
      unit: '%',
      dataIndex: CHART_NAME.deliverySchedule,
      showDateFilter: true,
    },
    {
      title: '收货进度',
      unit: '%',
      dataIndex: CHART_NAME.receiveSchedule,
      showDateFilter: true,
    },
    {
      title: '逾期收货单数',
      unit: '个',
      dataIndex: CHART_NAME.overdueRecOrderSchedule,
    },
  ];

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24 }} style={{ marginBottom: 24 }}>
      {summaryListData.map((item) => (
        <Col xs={12} sm={12} lg={6} key={item.dataIndex}>
          <SummaryCard {...item} />
        </Col>
      ))}
    </Row>
  );
};

export default SummaryList;
