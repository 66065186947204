import { useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FilterItem, RecordListLayout } from 'layout';
import { SearchSelect, BlIcon, SingleOperationLogComponent, LinkTooltip } from 'src/components';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import moment from 'moment';
import { gcObject, fieldTypeList, gcTime } from 'utils';
import { BOUND_PATH as basePath } from '../index';
import lookup, { appEnum, appDict } from 'src/dict';
import {
  fetchTransferOrderList,
  fetchTransferOrderIssue,
  fetchTransferOrderClose,
  FetchTransferOrderListResponse,
} from 'src/api/ytt/inventory-domain/transferOrder';
import { renderUser } from 'src/page/share/renderUser';
import _ from 'lodash';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { useDispatch } from 'react-redux';
import { patchTransferRequest } from '../utils';
import authDict from 'src/utils/auth';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { injectCustomFieldColumns, useCustomFieldCombinedData } from 'src/components/customField';

type TableRowType = Exclude<FetchTransferOrderListResponse['data'], undefined>['list'][number];

interface Props extends RouteComponentProps {}

const { StatusEnum } = appEnum.Bound;
const TransferOrderList = (props: Props) => {
  const { history } = props;
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const refreshCountRef = useRef(0);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [detailId, setDetailId] = useState<number>(0);
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.transferOrder);

  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const refreshTable = () => {
    // 刷新列表页
    refreshCountRef.current++;
    setRefreshMarker(refreshCountRef.current);
  };

  const requestFn = async (params: any) => {
    const res = await fetchTransferOrderList(params);

    return res;
  };

  const toDetail = (id?: number) => {
    if (!id) return;
    history.push(`${basePath}/transferOrder/${id}/detail`);
  };
  const toCreate = () => {
    history.push(`${basePath}/transferOrder/create`);
  };
  const toEdit = (id?: number) => {
    history.push(`${basePath}/transferOrder/${id}/edit`);
  };

  const dataColumns: any[] = [
    {
      title: '仓储调拨单编号',
      dataIndex: 'code',
      isFilter: true,
      sorter: true,
      width: 165,
      render: (text: string, record: any, index: number, config: any) => (
        <LinkTooltip
          to={`${basePath}/transferOrder/${record?.id}/detail`}
          text={text}
          width={config.contentWidth}
          auth={authDict.transferorder_detail}
        />
      ),
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '状态',
      dataIndex: 'bizStatus',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      render: lookup('bound.Status'),
      selectProps: {
        placeholder: '请选择',
        mode: 'multiple',
        options: appDict.bound.Status,
      },
    },
    {
      title: '业务类型',
      dataIndex: 'bizType',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      render: lookup('bound.TransferType'),
      selectProps: {
        placeholder: '请选择',
        options: appDict.bound.TransferType,
      },
    },
    {
      title: '发出仓库',
      dataIndex: 'sourceWarehouse',
      isFilter: true,
      width: 150,
      render: (value: any) => value?.name,
      renderItem: <SearchSelect mode="multiple" fetchType="warehouse" />,
    },
    {
      title: '接收仓库',
      dataIndex: 'targetWarehouse',
      isFilter: true,
      width: 150,
      render: (value: any) => value?.name,
      renderItem: <SearchSelect mode="multiple" fetchType="warehouse" />,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      inputProps: {
        placeholder: '请输入关键字',
      },
      width: 150,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      isFilter: true,
      width: 150,
      render: renderUser,
      inputProps: {
        placeholder: '请输入关键字',
      },
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择创建人" isMultiple isSelectUser />
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      width: 180,
      render: (text: any) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      isFilter: true,
      width: 150,
      render: renderUser,
      inputProps: {
        placeholder: '请输入关键字',
      },
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择更新人" isMultiple isSelectUser />
      ),
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      width: 180,
      render: (text: any) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  const config = {
    rowKey: 'id',
    // columns: dataColumns,
    columns: injectCustomFieldColumns({
      columns: dataColumns, // 原本的列
      customFields, // 自定义字段信息
      objectCode: OBJECT_OF_CODE.transferOrder, // 从对象code
      type: 'detail', // 使用类型
      inTable: true,
      columnProps: {
        to: (record: any) => `${basePath}/transferOrder/${record?.id}/detail`,
        auth: authDict.transferorder_detail,
        width: 150,
      },
    }),
    getOperationList: (record: TableRowType) => {
      const { bizStatus } = record;

      return [
        {
          title: '查看',
          auth: authDict.transferorder_detail,
          onClick: () => toDetail(record.id),
        },
        {
          title: '编辑',
          auth: authDict.transferorder_edit,
          disabled: bizStatus === StatusEnum.yiguanbi,
          onClick: () => toEdit(record.id),
        },
        {
          title: '下发',
          auth: authDict.transferorder_dispatch,
          disabled: bizStatus !== StatusEnum.xinjian,
          onClick: () => {
            patchTransferRequest(
              fetchTransferOrderIssue,
              { ids: [record?.id] },
              '下发',
              refreshTable,
            );
          },
        },
        {
          title: '关闭',
          auth: authDict.transferorder_close,
          disabled: bizStatus === StatusEnum.yiguanbi,
          onClick: () => {
            patchTransferRequest(
              fetchTransferOrderClose,
              { ids: [record?.id] },
              '关闭',
              refreshTable,
            );
          },
        },
        {
          title: '操作记录',
          auth: authDict.transferorder_operrecord,
          onClick: () => {
            if (!record.id) return;
            setDetailId(record.id);
            setVisibleLog(true);
          },
        },
      ];
    },
    batchMenu: [
      {
        title: '下发',
        auth: authDict.transferorder_dispatch,
        onClick: (onSuccess: any, onFail: any) => {
          return patchTransferRequest(
            fetchTransferOrderIssue,
            { ids: selectedRowKeys },
            '下发',
            onSuccess,
            onFail,
          );
        },
      },
      {
        title: '关闭',
        auth: authDict.transferorder_close,
        onClick: (onSuccess: any, onFail: any) => {
          return patchTransferRequest(
            fetchTransferOrderClose,
            { ids: selectedRowKeys },
            '关闭',
            onSuccess,
            onFail,
          );
        },
      },
    ],
    mainMenu: [
      {
        title: '导出',
        showExport: true,
        auth: authDict.transferorder_export,
        icon: <BlIcon type="icondaochu" />,
        onClick: () =>
          openModal({
            optType: 'export',
            businessType: BUSINESS_TYPE.transferOrder,
          }),
      },
      {
        title: '新建调拨单',
        showExport: true,
        auth: authDict.transferorder_add,
        icon: <BlIcon type="iconxinjiantianjia" />,
        onClick: toCreate,
        items: [
          {
            title: '导入',
            showExport: true,
            auth: authDict.transferorder_import,
            onClick: () =>
              openModal({
                optType: 'import',
                businessType: BUSINESS_TYPE.transferOrder,
              }),
          },
        ],
      },
    ],
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((columns: any): FilterItem => {
        const filter = {
          label: columns.title,
          name: columns.dataIndex,
          type: columns.type,
          ...columns,
        };

        return filter;
      }),
    formatDataToFormDisplay: (filterData: any) => {
      const { createdAt, updatedAt, ...rest } = filterData;

      const result = {
        ...rest,
        createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
        updatedAt: gcTime.formatRangeTimeToMoment(updatedAt || []),
      };

      return gcObject.filterEmptyProperty(result);
    },
    formatDataToQuery: (param: any) => {
      const {
        targetWarehouse,
        sourceWarehouse,
        creator,
        operator,
        createdAt,
        updatedAt,
        departmentId,
        ...rest
      } = param;
      const [createdAtFrom, createdAtTill] = gcTime.formatTimeRangeUnix(createdAt);
      const [updatedAtFrom, updatedAtTill] = gcTime.formatTimeRangeUnix(updatedAt);

      const options = {
        departmentId: departmentId?.[0]?.value,
        createdAtFrom,
        createdAtTill,
        updatedAtFrom,
        updatedAtTill,
        targetWarehouseIdList: _.isEmpty(targetWarehouse) ? null : _.map(targetWarehouse, 'value'),
        sourceWarehouseIdList: _.isEmpty(sourceWarehouse) ? null : _.map(sourceWarehouse, 'value'),
        creatorIdList: _.isEmpty(creator) ? null : _.map(creator, 'value'),
        operatorIdList: _.isEmpty(operator) ? null : _.map(operator, 'value'),
        ...rest,
      };

      const relParams = gcObject.filterEmptyProperty(options);

      dispatch.excelImport.updateBusinessData({ businessData: relParams });
      return relParams;
    },
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        placeholder={'请输入编号关键字'}
        useIndex={false}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey) => {
          setSelectRowKeys(selectKey);
        }}
        refreshMarker={refreshMarker}
        requestFn={requestFn}
        configcacheKey="transferOrderModule"
        {...config}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={'TransferOrder'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default TransferOrderList;
