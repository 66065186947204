import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Badge, Form, Input, InputNumber, Modal, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import _ from 'lodash';
import {
  FetchQcTaskDetailForEditResponse,
  fetchQcTaskSaveNeither,
} from 'src/api/ytt/quality-domain/qc_task';
import { TagTooltip } from 'src/components';
import { AvatarDisplay } from 'src/components/avatar/show';
import lookup from 'src/dict';
import { AssignStatusEnum, CheckEntityTypeEnum, QcTaskStatusEnum } from 'src/dict/quality';
import { QcTaskEntity } from 'src/page/quality/models/entities';
import { gcTime } from 'src/utils';
import { numberMinMaxCheck } from 'src/utils/formValidators';
import { DetailTab } from './detailTab';
import { blueViolet, errorRed } from 'src/styles/color';

type Qctask = FetchQcTaskDetailForEditResponse['data'];

interface BaseInfoProps {
  qcTaskData: Qctask;
  qcTaskEntity: QcTaskEntity;
  form: FormInstance;
  tabs: any[];
  tabRef?: any;
  setRefreshMaker: (value: number) => void;
}
export const getBaseInfo = ({
  qcTaskEntity,
  qcTaskData,
  form,
  setRefreshMaker,
  tabs,
  tabRef,
}: BaseInfoProps) => {
  const hasProcessAndEquipment =
    qcTaskEntity?.isProductQc() ||
    qcTaskEntity?.isInspectionQc() ||
    qcTaskEntity?.isProduceOriginQc();
  const qcUnitDisplay = qcTaskData?.qcUnit ? `(${qcTaskData?.qcUnit?.name})` : '';

  const saveCheckCount = async (checkCount?: number) => {
    const { code } = await fetchQcTaskSaveNeither({
      checkCount,
      id: Number(qcTaskData?.id),
    });

    if (code !== 0) {
      form.setFieldsValue({ checkCount: qcTaskData?.checkCount });
      setRefreshMaker(Math.random());
    }
  };

  const saveQcTotal = _.debounce(async (qcTotalCount?: number | string) => {
    const { code } = await fetchQcTaskSaveNeither({
      qcTotalCount: Number(qcTotalCount),
      id: Number(qcTaskData?.id),
    });

    if (code !== 0) {
      form.setFieldsValue({ checkCount: qcTaskData?.checkCount });
      setRefreshMaker(Math.random());
    }
  }, 500);

  const onChangeCheckCount = _.debounce((_checkCount: number) => {
    if (qcTaskEntity?.qcConfig.checkEntityType === CheckEntityTypeEnum.SameAsSample) {
      const checkCount = qcTaskData?.checkCount;

      if (_checkCount > 100) {
        Modal.confirm({
          title: '当前抽样量较大，若继续保存检验单将置为1页，是否继续？',
          onOk: () => {
            saveCheckCount(_checkCount);
          },
          onCancel: () => {
            form.setFieldsValue({
              checkCount: qcTaskData?.checkCount,
            });
          },
        });
        return;
      }
      if (_checkCount && checkCount && checkCount - _checkCount > 0) {
        Modal.confirm({
          title: '提示',
          icon: <ExclamationCircleOutlined />,
          content: `修改总抽样量将清空倒数${Math.ceil(
            checkCount - _checkCount,
          )}份检验单数据，确认继续吗`,
          okText: '确定',
          cancelText: '取消',
          onCancel: () => {
            form.setFieldsValue({
              checkCount: qcTaskData?.checkCount,
            });
          },
          onOk: async () => {
            await saveCheckCount(_checkCount);
          },
        });
        return;
      }
    }
    saveCheckCount(_checkCount);
  }, 500);

  return [
    {
      title: '基本信息',
      items: _.compact([
        {
          label: '任务编号',
          name: 'code',
          render: () => qcTaskData?.code || '-',
        },
        {
          label: '业务类型',
          name: 'checkType',
          render: () => lookup('quality.checkType', qcTaskData?.checkType) || '-',
        },
        {
          label: '布局',
          name: ['layoutInfo', 'name'],
          render: () => qcTaskData?.layoutInfo?.name || '-',
        },
        {
          label: '创建方式',
          name: ['createType'],
          render: () => lookup('quality.createType', qcTaskData?.createType),
        },
        {
          label: '可领取人',
          name: 'candidates',
          render: () => {
            const { candidates } = qcTaskData || {};

            return <TagTooltip list={candidates || []} isUser max={2} />;
          },
        },
        {
          label: '领取状态',
          name: 'getStatus',
          render: () => (
            <span>
              {qcTaskData?.getStatus === AssignStatusEnum.unAssign && <Badge status={'success'} />}
              {qcTaskData?.getStatus === AssignStatusEnum.assigned && <Badge status="default" />}
              {lookup('quality.assignStatus', qcTaskData?.getStatus)}
            </span>
          ),
        },
        {
          label: '任务状态',
          name: 'status',
          render: () => (
            <span>
              {qcTaskData?.status === QcTaskStatusEnum.unstarted && <Badge color={'yellow'} />}
              {qcTaskData?.status === QcTaskStatusEnum.finished && <Badge color={blueViolet} />}
              {qcTaskData?.status === QcTaskStatusEnum.executing && <Badge status={'success'} />}
              {qcTaskData?.status === QcTaskStatusEnum.cancled && <Badge color={errorRed} />}
              {lookup('quality.qcTaskStatus', qcTaskData?.status)}
            </span>
          ),
        },
        !qcTaskEntity?.isGeneralQc() && {
          label: '物料编号',
          name: ['material', 'code'],
          render: () => qcTaskData?.material?.code || '-',
        },
        !qcTaskEntity?.isGeneralQc() && {
          label: '物料名称',
          name: ['material', 'name'],
          render: () => qcTaskData?.material?.name || '-',
        },
        hasProcessAndEquipment && {
          label: '工序编号',
          name: ['process', 'code'],
          render: () => qcTaskData?.process?.code || '-',
        },
        hasProcessAndEquipment && {
          label: '工序名称',
          name: ['process', 'name'],
          render: () => qcTaskData?.process?.name || '-',
        },
        hasProcessAndEquipment && {
          label: '设备编号',
          name: ['equipment', 'code'],
          render: () => qcTaskData?.equipment?.code || '-',
        },
        qcTaskEntity.isOutputFactoryQc() && {
          label: '客户名称',
          name: ['customer', 'name'],
          render: () => qcTaskData?.customer?.name || '-',
        },
        qcTaskEntity.isOutputFactoryQc() && {
          label: '客户编号',
          name: ['customor', 'code'],
          render: () => qcTaskData?.customer?.code || '-',
        },
        qcTaskEntity.isInputFactoryQc() && {
          label: '供应商名称',
          name: ['supplier', 'name'],
          render: () => qcTaskData?.supplier?.name || '-',
        },
        qcTaskEntity.isInputFactoryQc() && {
          label: '供应商编号',
          name: ['supplier', 'code'],
          render: () => qcTaskData?.supplier?.code || '-',
        },
        hasProcessAndEquipment && {
          label: '设备名称',
          name: ['equipment', 'name'],
          render: () => qcTaskData?.equipment?.name || '-',
        },
        !qcTaskEntity?.isGeneralQc() && {
          label: '批次号',
          name: 'batchNumber',
          render: () => qcTaskData?.batchNumber || '-',
        },
        {
          label: '执行人',
          name: 'operator',
          render: () => {
            const { operator } = qcTaskData || {};

            return operator ? (
              <AvatarDisplay
                id={Number(operator.id)}
                name={operator.name || ''}
                avatarUrl={operator.avatarUrl}
              />
            ) : (
              '-'
            );
          },
        },
        {
          label: '创建人',
          name: 'creator',
          render: () => {
            const { creator } = qcTaskData || {};

            return creator ? (
              <AvatarDisplay
                id={Number(creator.id)}
                name={creator.name || ''}
                avatarUrl={creator.avatarUrl}
              />
            ) : (
              '-'
            );
          },
        },
        {
          label: '创建时间',
          name: 'createdAt',
          render: () => gcTime.format(qcTaskData?.createdAt) || '-',
        },
        {
          label: '更新人',
          name: 'creator',
          render: () => {
            const { creator } = qcTaskData || {};

            return creator ? (
              <AvatarDisplay
                id={Number(creator.id)}
                name={creator.name || ''}
                avatarUrl={creator.avatarUrl}
              />
            ) : (
              '-'
            );
          },
        },
        {
          label: '更新时间',
          name: 'createdAt',
          render: () => gcTime.format(qcTaskData?.createdAt) || '-',
        },
        {
          label: '检验方案',
          name: ['qcConfig', 'name'],
          render: () => qcTaskData?.qcConfig?.name || '-',
        },
        qcTaskEntity?.isShowTotalCount() && {
          label: '总量',
          name: ['qcTotalCount'],
          render: () => {
            return (
              <>
                {qcTaskEntity?.canEditTotalCount() ? (
                  <Form.Item
                    name={['qcTotalCount']}
                    rules={[{ validator: numberMinMaxCheck({ min: 0, minAllowEqual: false }) }]}
                  >
                    <Input
                      style={{ width: '200px' }}
                      disabled={qcTaskEntity?.isUnStarted()}
                      addonAfter={qcUnitDisplay}
                      onChange={async (e) => {
                        await form.validateFields(['qcTotalCount']);
                        if (Number(e.target.value) > 0) {
                          saveQcTotal(e.target.value);
                        }
                      }}
                    />
                  </Form.Item>
                ) : (
                  `${qcTaskData?.qcTotal ?? '-'}${qcUnitDisplay}`
                )}
              </>
            );
          },
        },
        qcTaskEntity?.isShowCheckCount() && {
          label: '计划抽样量',
          name: ['planCheckCount'],
          render: () => `${qcTaskData?.planCheckCount ?? '-'}${qcUnitDisplay}`,
        },
        qcTaskEntity?.isShowCheckCount() && {
          label: '总抽样量',
          dependencices: ['qcTotal'],
          render: () => {
            return (
              <>
                {qcTaskEntity?.canEditTotalCount() ? (
                  <Form.Item
                    name={['checkCount']}
                    dependencies={['qcTotal']}
                    rules={[
                      {
                        validator: numberMinMaxCheck({
                          min: 0,
                          minAllowEqual: false,
                          max: qcTaskData?.qcTotal,
                        }),
                      },
                    ]}
                  >
                    <Input
                      style={{ width: '200px' }}
                      disabled={qcTaskEntity?.isUnStarted()}
                      addonAfter={qcUnitDisplay}
                      onChange={async (e) => {
                        await form.validateFields(['checkCount']);

                        if (Number(e.target.value)) {
                          onChangeCheckCount(Number(e.target.value));
                        }
                      }}
                    />
                  </Form.Item>
                ) : (
                  `${qcTaskData?.checkCount ?? '-'}${qcUnitDisplay}`
                )}
              </>
            );
          },
        },
        {
          label: '结论判定',
          name: 'inspectionResult',
          rules: [{ required: true }],
          render: () => (
            <Select
              options={_.map(qcTaskData?.qcConfig?.inspectionResultOptions, (i) => ({
                value: i,
                label: lookup('quality.inspectionResultOptions', i),
              }))}
              disabled={qcTaskEntity?.isUnStarted()}
              style={{ width: 200 }}
              onChange={() => form.validateFields(['inspectionResult'])}
              allowClear
            />
          ),
        },
        {
          label: '备注',
          name: 'remark',
          render: () => (
            <Input.TextArea
              maxLength={1000}
              style={{ width: 200 }}
              disabled={qcTaskEntity?.isUnStarted()}
            />
          ),
        },
        qcTaskEntity?.isShowRecordResult() && {
          label: '合格量',
          name: 'qualifiedCount',
          render: () => (
            <InputNumber disabled={qcTaskEntity?.isUnStarted()} style={{ width: 200 }} />
          ),
        },
        qcTaskEntity?.isShowRecordResult() && {
          label: '不合格量',
          name: 'defectCount',
          render: () => (
            <InputNumber disabled={qcTaskEntity?.isUnStarted()} style={{ width: 200 }} />
          ),
        },
        qcTaskEntity?.isShowRecordResult() && {
          label: '让步合格量',
          name: 'qualifiedConcessionCount',
          render: () => <InputNumber disabled={qcTaskEntity?.isUnStarted()} />,
        },
        qcTaskEntity?.isShowRecordResult() && {
          label: '待检量',
          name: 'pendingCount',
          render: () => (
            <InputNumber disabled={qcTaskEntity?.isUnStarted()} style={{ width: 200 }} />
          ),
        },
      ]),
    },
    {
      title: '详细信息',
      items: [
        {
          isFullLine: true,
          name: 'detailInfo',
          render: () =>
            qcTaskEntity && (
              <DetailTab
                form={form}
                qcTask={qcTaskEntity || ({} as QcTaskEntity)}
                qcTaskData={qcTaskData || ({} as Qctask)}
                tabs={tabs}
                ref={tabRef}
              />
            ),
        },
      ],
    },
  ];
};
