import { Select, SelectProps } from 'antd';

import {
  SimpleUserItem,
  LabelInValueSelectorProps,
} from 'src/page/organization/components/userAndDepartmentSelect/index.d';

interface Props {
  placeholder?: string;
  value?: LabelInValueSelectorProps[] | SimpleUserItem[];
  options: LabelInValueSelectorProps[] | SelectProps<any>['options'];
  onChange?: (newVal: LabelInValueSelectorProps[] | SimpleUserItem[]) => void;
  isMultiple?: boolean;
}
/** LabeldValue to new format */
const lb2nf = (v: LabelInValueSelectorProps) => ({
  id: +v.value,
  name: v.label,
  avatarUrl: null,
});
/** new format to LabeldValue */
const nf2lb = (v: SimpleUserItem) => ({
  label: v.name,
  value: v.id,
});

const FormatSelect = (props: Props) => {
  const { isMultiple, placeholder = '请选择', value = [], onChange, options } = props;

  /**
   * 格式化 form 数据到 Select组件
   */
  const formatFormToSelect = (value: LabelInValueSelectorProps[] | SimpleUserItem[]) => {
    return (value as SimpleUserItem[])?.map(nf2lb);
  };

  /**
   * 格式化 Select组件 数据到 form
   */
  const formatSelectToForm = (value: LabelInValueSelectorProps[]) => {
    return value?.map(lb2nf);
  };

  // Select组件 选择值变化, 如删除一项
  const handleSelectChange = (value: LabelInValueSelectorProps[] | LabelInValueSelectorProps) => {
    let formatValue: LabelInValueSelectorProps[];

    if (!Array.isArray(value)) {
      formatValue = !value ? [] : [value];
    } else {
      formatValue = value;
    }
    onChange?.(formatSelectToForm(formatValue));
  };

  return (
    <Select
      placeholder={placeholder}
      labelInValue
      allowClear
      mode={isMultiple ? 'multiple' : undefined}
      value={formatFormToSelect(value)}
      options={options}
      onChange={handleSelectChange}
    />
  );
};

export default FormatSelect;
