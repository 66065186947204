import React from 'react';
import _ from 'lodash';
import { Tooltip } from 'antd';
import { TextToolTip } from '@blacklake-web/component';

interface RelatedLinkDataProps {
  id?: number;
  [label: string]: string | number | undefined;
}

type renderMultiLinksProps = (
  linksData: RelatedLinkDataProps[] | undefined,
  labelField: string,
  linkFunc: (id: number) => void,
  linkLength?: number,
) => React.ReactNode;

const renderMultiLinks: renderMultiLinksProps = (
  linksData,
  labelField,
  linkFunc,
  linkLength = 8,
) => {
  if (linksData) {
    const displayLinks: RelatedLinkDataProps[] = _.take(linksData, 2);
    const otherLinks: RelatedLinkDataProps[] = _.slice(linksData, 2);

    if (otherLinks && otherLinks.length > 0) {
      return (
        <>
          {displayLinks.map((displayLink) => (
            <span key={`displayLink-${displayLink.id}`}>
              <a
                onClick={() => {
                  if (displayLink.id) linkFunc(displayLink.id);
                }}
              >
                {/* TODO 整体做缩略，否则会溢出 */}
                <TextToolTip text={String(displayLink[labelField])} length={linkLength} />
              </a>
              ;
            </span>
          ))}
          <Tooltip title={otherLinks.map((link) => link[labelField]).join(';')}>...</Tooltip>
        </>
      );
    }
    return displayLinks.map((displayLink) => (
      <span key={`displayLink-${displayLink.id}`}>
        <a
          onClick={() => {
            if (displayLink.id) linkFunc(displayLink.id);
          }}
        >
          {/* TODO 整体做缩略，否则会溢出 */}
          <TextToolTip text={String(displayLink[labelField])} length={linkLength} />
        </a>
        ;
      </span>
    ));
  }
  return undefined;
};

export default renderMultiLinks;
