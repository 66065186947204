import { toNumber } from 'lodash';
import { Progress } from 'antd';
import styles from '../../styles.module.scss';

interface Props {
  numberName: string;
  authNumber: string | number;
  availableNumber: string | number;
  strokeColor: string | number;
  trailColor: string | number;
}
const LineProgress = (props: Props) => {
  const { numberName, authNumber, availableNumber, strokeColor, trailColor } = props;
  const isFile = numberName.includes('容量');
  const percent = Math.floor(
    (toNumber(availableNumber) / (toNumber(authNumber) * (isFile ? 1024 : 1))) * 100,
  );

  return (
    <div className={styles['system-use-item']}>
      <div className={styles['system-use-item-info']}>
        <div style={{ fontSize: 20 }}>{numberName}</div>
        <div style={{ fontSize: 14 }}>
          可用{isFile ? '容' : '数'}量：
          <span className={styles['system-use-item-available']}>
            {availableNumber}
            {isFile ? 'G' : ''}
          </span>
          /{authNumber}
          {isFile ? 'T' : '个'}
        </div>
      </div>
      <Progress
        type="line"
        percent={percent}
        showInfo={false}
        strokeColor={strokeColor + ''}
        trailColor={trailColor + ''}
        strokeWidth={20}
      />
    </div>
  );
};

export default LineProgress;
