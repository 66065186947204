import React, { useEffect } from 'react';
import { DetailLayout, DetailLayoutForDrawer, DetailLayoutInfoBlock } from 'layout';
import _ from 'lodash';
import { getAuthFromLocalStorage } from 'src/utils/auth';
import { appEnum, lookup } from 'src/dict';
import {
  getMainObjCustomFieldsToDetail,
  useCustomFieldCombinedData,
} from 'src/components/customField';
import { RecordDetail } from '../../models/dto';
import { BlTable } from '@blacklake-web/component';
import { BcAttachmentDetail } from 'src/components';
import { getStandardValue, LOGIC } from '../../models/constants';
import { gcTime } from 'src/utils';
import { AvatarDisplay } from 'src/components/avatar/show';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

interface RecordDetailProps {
  visible: boolean;
  closeDetail: () => void;
  data: RecordDetail;
}

const RecordDetailDrawer = (props: RecordDetailProps) => {
  const { visible, data, closeDetail } = props;
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.checkItemRecord);

  const getBaseInfo = (): DetailLayoutInfoBlock => {
    return {
      title: '基本信息',
      items: _.compact([
        {
          label: '分组名称',
          dataIndex: 'groupName',
        },
        {
          label: '检验项名称',
          dataIndex: 'checkItemName',
        },
        {
          label: '检验项编号',
          dataIndex: 'checkItemCode',
        },
        {
          label: '检验设备',
          dataIndex: 'equipments',
          render: (equipments: { name: any }[]) => {
            return equipments?.map((equipment: { name: any }) => equipment.name).join(',');
          },
        },
        {
          label: '检验项抽样量',
          dataIndex: 'taskCheckCount',
        },
        {
          label: '序号',
          dataIndex: 'seq',
        },
        {
          label: '标识码',
          dataIndex: 'qrCode',
        },
        data?.logic === LOGIC.TOLERANCE && {
          label: '上偏差',
          dataIndex: 'max',
        },
        data?.logic === LOGIC.TOLERANCE && {
          label: '下偏差',
          dataIndex: 'min',
        },
        data?.logic === LOGIC.BETWEEN && {
          label: '最大值',
          dataIndex: 'max',
        },
        data?.logic === LOGIC.BETWEEN && {
          label: '最小值',
          dataIndex: 'min',
        },
        {
          label: '标准值',
          dataIndex: 'logic',
          render: (value: LOGIC, record: RecordDetail) => getStandardValue(value, record),
        },
        {
          label: '实测值左区间',
          dataIndex: 'valueMin',
        },
        {
          label: '实测值右区间',
          dataIndex: 'valueMax',
        },
        {
          label: '文件',
          dataIndex: 'inputAttachmentId',
          render: (ids: number[]) => {
            if (ids?.length) return <BcAttachmentDetail fileIds={ids} />;
            return '-';
          },
        },
        {
          label: '单一实测值',
          dataIndex: 'result',
        },
        {
          label: '备注',
          dataIndex: 'inputRemark',
          isFullLine: true,
        },
        {
          label: '平均值',
          dataIndex: 'average',
        },
        {
          label: '样本标准偏差',
          dataIndex: 'standardDeviation',
        },
        {
          label: '创建时间',
          dataIndex: 'createdAt',
          render: (value: number) => gcTime.formatLine(value),
        },
        {
          label: '创建人',
          dataIndex: 'creator',
          render: (creator: { id: number; name: string; avatarUrl: string } | null) => {
            if (!creator) return '-';
            return (
              <AvatarDisplay
                id={creator.id}
                isShowTag
                name={creator.name}
                avatarUrl={creator.avatarUrl}
              />
            );
          },
        },
        {
          label: '更新时间',
          dataIndex: 'updatedAt',
          render: (value: number) => gcTime.formatLine(value),
        },
        {
          label: '更新人',
          dataIndex: 'operator',
          render: (operator: { id: number; name: string; avatarUrl: string } | null) => {
            if (!operator) return '-';
            return (
              <AvatarDisplay
                id={operator.id}
                isShowTag
                name={operator.name}
                avatarUrl={operator.avatarUrl}
              />
            );
          },
        },
        ...getMainObjCustomFieldsToDetail({ customFields }),
        {
          label: '单项判定',
          dataIndex: 'singleJudgment',
          render: (value: number) => lookup('quality.singleJudgment', value) || '-',
        },
      ]),
    };
  };

  const defectInfo = {
    title: '不良信息',
    items: [
      {
        label: '',
        dataIndex: 'qcDefectVOS',
        isFullLine: true,
        render: (qcDefectVOS: any[]) => (
          <BlTable
            columns={[
              { title: '不良原因', dataIndex: ['defectReason', 'name'] },
              { title: '不良等级', dataIndex: ['defectRank', 'name'] },
            ]}
            dataSource={qcDefectVOS || []}
            scroll={{ x: 'max-content' }}
          />
        ),
      },
    ],
  };

  const getInfo = () => {
    const baseInfo = [getBaseInfo(), defectInfo];

    return baseInfo;
  };

  return (
    <DetailLayoutForDrawer
      visible={visible}
      onClose={closeDetail}
      width={680}
      content={
        <DetailLayout
          title={'检验项记录详情'}
          info={getInfo()}
          dataSource={data}
          userAuth={getAuthFromLocalStorage()}
        />
      }
    />
  );
};

export default RecordDetailDrawer;
