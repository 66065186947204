import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { message as Message } from 'antd';
import _ from 'lodash';
import { RecordListLayout, FilterItem } from 'src/layout';
import CreateAndEdit from './createAndEdit/createAndEdit';
import Details from './details/details';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { useDispatch } from 'react-redux';
import { Tooltip, BlIcon, SingleOperationLogComponent, SearchSelect } from 'src/components';
import { replaceSign } from 'src/utils/constants';
import { CRUD } from 'src/dict/common';
import { fieldTypeList } from 'src/utils';
import lookup from 'src/dict';
import {
  fetchParamsDefList,
  fetchParamsDefEnable,
  fetchParamsDefStop,
} from 'src/api/ytt/resource-domain/parmDefine';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { RESORCE_BUSINESS_TYPE_MAP, paramType } from 'src/dict/resources/mappings';
import { usageStatus } from 'src/dict/common/mappings';
import renderUsageStatus from 'src/page/share/renderUsageStatus';
import ResourceClassificationCascader from '../components/ResourceClassificationCascader';
import authDict from 'src/utils/auth';

interface TableRowType {
  content?: string;
  index?: number;
  id: number;
}

const ParmDefineList = () => {
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [actionType, setActionType] = useState<string>('');
  const [resId, setResId] = useState<number | undefined>(undefined);
  const [createAndeditVisible, setCreateAndeditVisible] = useState(false);
  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  const [detailVisible, setDetailVisible] = useState<boolean>(false);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);

  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const showDetail = (id: number) => {
    setResId(id);
    setDetailVisible(true);
  };

  useEffect(() => {}, []);

  const dataColumns = [
    {
      title: '参数编号',
      dataIndex: 'code',
      type: fieldTypeList.text,
      isFilter: true,
      sorter: true,
      fixed: 'left',
      width: 140,
      render: (value: string, record: any, index: number, config: any) => (
        <a onClick={() => showDetail(record.id)}>
          <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
        </a>
      ),
    },
    {
      title: '参数名称',
      dataIndex: 'name',
      type: fieldTypeList.text,
      isFilter: true,
      sorter: true,
      fixed: 'left',
      width: 140,
      render: (value: string, record: any, index: number, config: any) => (
        <a onClick={() => showDetail(record.id)}>
          <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
        </a>
      ),
    },
    {
      title: '参数类型',
      dataIndex: 'paramsType',
      type: fieldTypeList.select,
      isFilter: true,
      width: 140,
      render: (value: number) => lookup('resources.paramType', value),
      selectProps: {
        options: paramType,
        // mode: 'multiple',
      },
    },
    {
      title: '业务类型',
      dataIndex: 'businessType',
      type: fieldTypeList.select,
      isFilter: true,
      width: 140,
      render: (value: number) => lookup('resources.RESORCE_BUSINESS_TYPE_MAP', value),
      selectProps: {
        options: RESORCE_BUSINESS_TYPE_MAP,
        // mode: 'multiple',
      },
    },
    {
      title: '参数分类',
      dataIndex: 'classificationId',
      type: fieldTypeList.select,
      isFilter: true,
      width: 140,
      render: (text: number, record: any) => {
        if (text) {
          return record?.classificationList
            ?.map((item: { name: string; id: number }) => item.name)
            .join('/');
        }
        return replaceSign;
      },
      renderItem: (
        <ResourceClassificationCascader
          onChange={() => {}}
          // resFetchData={resFetchData}
          showSearch
          placeholder={'请选择参数分类'}
          enableAddLabel={'新建分类'}
          onCreateClick={() => {}}
          enableAdd={false}
        />
      ),
    },
    {
      title: '参数单位',
      dataIndex: 'unitName',
      type: fieldTypeList.select,
      isFilter: true,
      width: 140,
      render: (text: string) => text ?? replaceSign,
      renderItem: (
        <SearchSelect
          fetchType={'unit'}
          params={{ enableFlag: 1 }}
          // mode={'multiple'}
        />
      ),
    },
    {
      title: '启用状态',
      dataIndex: 'status',
      type: fieldTypeList.select,
      isFilter: true,
      width: 140,
      render: (value: number) => renderUsageStatus(value),
      selectProps: {
        options: usageStatus,
        // mode: 'multiple',
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      type: fieldTypeList.textArea,
      width: 140,
      render: (value: string) => value,
    },
  ];
  const filterList: FilterItem[] = dataColumns
    .filter((i) => i.isFilter)
    .map((column) => {
      return _.pick(
        {
          ...column,
          label: column.title,
          name: column.dataIndex,
          type: column.type,
        },
        [
          'label',
          'name',
          'type',
          'rules',
          'renderItem',
          'selectProps',
          'inputProps',
          'datePickerProps',
          'dateFormat',
        ],
      ) as FilterItem;
    });
  const batchMenu = [
    {
      title: '启用',
      auth: authDict.resourceparameter_enable_disable,
      onClick: async () => {
        const response = await fetchParamsDefEnable({ ids: selectedRowKeys });

        if (response?.code === 200) {
          Message.success('参数已启用');
          setRefreshMarker(Date.now());
        } else {
          Message.error(response.message);
        }
      },
    },
    {
      title: '停用',
      auth: authDict.resourceparameter_enable_disable,
      onClick: async () => {
        const response = await fetchParamsDefStop({ ids: selectedRowKeys });

        if (response?.code === 200) {
          Message.success('参数已停用');
          setRefreshMarker(Date.now());
        } else {
          Message.error(response.message);
        }
      },
    },
    // {
    //   title: '删除',
    //   onClick: (onSuccess: any, onFail: any) => {},
    // },
  ];
  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      auth: authDict.resourceparameter_export,
      icon: <BlIcon type="icondaochu" />,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.params_def,
        });
      },
    },
    {
      title: '新建参数',
      auth: authDict.resourceparameter_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        setResId(undefined);
        setActionType(CRUD.create);
        setCreateAndeditVisible(true);
      },
      items: [
        {
          title: '导入',
          auth: authDict.resourceparameter_import,
          showExport: true,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.params_def,
            });
          },
        },
      ],
    },
  ];
  const getOperationList = (record: any) => {
    const { id, status } = record;

    return [
      {
        title: lookup('crud', CRUD.view),
        auth: authDict.resourceparameter_detail,
        onClick: () => showDetail(id),
      },
      {
        title: lookup('crud', CRUD.edit),
        auth: authDict.resourceparameter_edit,
        onClick: () => {
          setResId(id);
          setActionType(CRUD.edit);
          setCreateAndeditVisible(true);
        },
      },
      {
        title: '复制',
        auth: authDict.resourceparameter_add,
        onClick: () => {
          setResId(id);
          setActionType(CRUD.copy);
          setCreateAndeditVisible(true);
        },
      },
      {
        title: lookup('changeStatusAction', status),
        auth: authDict.resourceparameter_enable_disable,
        onClick: async () => {
          const response = await (!status
            ? fetchParamsDefEnable({ ids: [id] })
            : fetchParamsDefStop({ ids: [id] }));

          if (response?.code === 200) {
            Message.success(`${lookup('changeStatusAction', status)}成功`);
            setRefreshMarker(Date.now());
          } else {
            Message.error(response.message);
          }
        },
      },
      // {
      //   title: lookup('crud', CRUD.delete),
      //   onClick: () => {
      //     BLDelConfirm('是否删除当前单位', delForm, () => {
      //       handleFinish(record.id);
      //     });
      //   },
      // },
      {
        title: '操作记录',
        auth: authDict.resourceparameter_operrecord,
        disabled: record?.id === 0,
        onClick: () => {
          setResId(id);
          setVisibleLog(true);
        },
      },
    ];
  };

  const formatDataToQuery = (params: any) => {
    const { classificationId, unitName, ...rest } = params;
    const queryParams = {
      classificationId: _.isEmpty(classificationId) ? null : classificationId.concat().pop(),
      unitId: unitName?.value,
      ...rest,
    };

    dispatch.excelImport.updateBusinessData({
      businessData: { ...queryParams },
    });

    return {
      ...queryParams,
    };
  };

  const formatDataToDisplay = (params: any = {}) => {
    const retData = { ...params };

    return retData;
  };
  const getData = async (params: any) => {
    const res = await fetchParamsDefList(params);

    return res ?? [];
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        useIndex={false}
        columns={dataColumns}
        filterList={filterList}
        rowKey="id"
        batchMenu={batchMenu}
        mainMenu={mainMenu}
        getOperationList={getOperationList}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={formatDataToDisplay}
        selectedRowKeys={selectedRowKeys}
        // useColConfig={false}
        onSelectedRowKeys={(selectKey: any) => {
          setSelectRowKeys(selectKey.map((code: any) => Number(code)));
        }}
        requestFn={(parmas: any) => getData(parmas)}
        refreshMarker={refreshMarker}
        placeholder={'请输入参数名称或编号'} // 快速搜索input placeholder
      />
      {createAndeditVisible && (
        <CreateAndEdit
          id={resId}
          type={actionType}
          visible={createAndeditVisible}
          onCancel={() => setCreateAndeditVisible(false)}
          refreshTable={() => {
            setRefreshMarker(new Date().getTime());
          }}
          refreshMarker={refreshMarker}
        />
      )}
      {detailVisible && (
        <Details
          id={Number(resId)}
          visible={detailVisible}
          onCancel={() => setDetailVisible(false)}
          showEdit={() => {
            setResId(Number(resId));
            setCreateAndeditVisible(true);
            setActionType(CRUD.edit);
          }}
          showOperationRecord={() => {
            setResId(Number(resId));
            setVisibleLog(true);
          }}
          refreshDetailsMarker={refreshMarker}
          refreshTable={() => {
            setRefreshMarker(new Date().getTime());
          }}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={Number(resId)}
          objectCode={'ResourceParameter'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(ParmDefineList);
