import _ from 'lodash';

export const goToDetail = (id: number) => {
  window.reactRouterHistoryInstance.push(`/resource/definitions/standingBook/${id}/detail`);
};

export const goToCreate = (id?: number) => {
  if (!_.isNil(id)) {
    window.reactRouterHistoryInstance.push(
      `/resource/definitions/standingBook/create?parentId=${id}`,
    );
  } else {
    window.reactRouterHistoryInstance.push('/resource/definitions/standingBook/create');
  }
};

export const goToEdit = (id: number) => {
  window.reactRouterHistoryInstance.push(`/resource/definitions/standingBook/${id}/edit`);
};

export const goToCopy = (id: number) => {
  window.reactRouterHistoryInstance.push(`/resource/definitions/standingBook/${id}/copy`);
};

export const goToList = () => {
  window.reactRouterHistoryInstance.push('/resource/definitions/standingBook');
};

export const goToCreateResourceTag = () => {
  window.open('/resource/resourceTag');
};
