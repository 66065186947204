import {
  FetchApprovalDetailResponse,
  FetchApprovalListResponse,
} from 'src/api/ytt/metadata-domain/approvalList';
import { ListItemType } from 'src/types';

// 视图模式
export const VIEW_MODAL = {
  PENDING: '1',
  HANDLED: '2',
  INITIATE: '3',
};

type ApprovalListItemProps = ListItemType<FetchApprovalListResponse>;

type ApprovalDetailProps = ListItemType<FetchApprovalDetailResponse>;

export type { ApprovalListItemProps, ApprovalDetailProps };
