import { BlIcon, SingleOperationLogComponent, Tooltip } from 'components';
import { Badge, Space, Dropdown, Menu, Form, message } from 'antd';
import React, { useState } from 'react';
import { RecordListLayout } from 'src/layout';
import CreateAndEdit from './createAndEdit/createAndEdit';
import { fieldTypeList } from 'src/utils';
import Details from './details/details';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  fetchUnitList,
  fetchUnitStop,
  fetchUnitStart,
  fetchUnitDelete,
} from 'src/api/ytt/material-domain/web-unit';
import { getDefaultPrecisionFigure, patchRequest } from './utils';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import { replaceSign } from 'src/utils/constants';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { CRUD } from 'src/dict/common';
import lookup from 'src/dict';
import { usageStatus } from 'src/dict/common/mappings';
import { useDispatch } from 'react-redux';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import authDict, { hasAuth } from 'src/utils/auth';

interface TableRowType {
  name: string;
  code: string;
  enableFlag: number;
  roundingRole: number;
  precisionFigure: number;
  id: number;
}
interface Props extends RouteComponentProps {}
const UnitsList = (props: Props) => {
  const [detailVisible, setDetailVisible] = useState(false);
  const [operationRecordVisible, setOperationRecordVisible] = useState(false);
  const [createAndeditVisible, setCreateAndeditVisible] = useState(false);
  const [actionType, setActionType] = useState(CRUD.create);
  const [unitId, setUnitId] = useState<number | undefined>(undefined);
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [refreshDetailsMarker, setRefreshDetailsMarker] = useState<number | undefined>(undefined);
  const [delForm] = Form.useForm();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const handleFinish = async (id?: number | any[]) => {
    try {
      const value = await delForm?.validateFields();
      let ids;

      if (id) {
        // 操作列的删除
        ids = typeof id === 'number' ? [id] : id;
      } else {
        // 按钮的删除
        ids = selectedRowKeys;
      }
      const { code, message: mes } = await fetchUnitDelete({
        ids,
        reason: value.delReason,
      });

      if (code === 200) {
        message.success('删除成功');
        // 刷新list
        setRefreshMarker(new Date().getTime());
        return;
      }
      message.error(mes);
    } catch (error) {
      console.log(error);
    }
  };
  const dataColumns = [
    {
      title: '单位编号',
      dataIndex: 'code',
      type: 'text',
      isFilter: true,
      sorter: true,
      width: 150,
      render: (text: string, record: TableRowType, index: number, config: any) => {
        return hasAuth(authDict.unit_detail) ? (
          <a
            onClick={() => {
              setUnitId(record.id);
              setDetailVisible(true);
              // window.open(`/knowledgeManagement/materialModeling/unitsList/details/${record.id}`);
            }}
          >
            <Tooltip text={text ?? replaceSign} width={config.contentWidth} />
          </a>
        ) : (
          <Tooltip text={text ?? replaceSign} width={config.contentWidth} />
        );
      },
    },
    {
      title: '单位名称',
      dataIndex: 'name',
      type: 'text',
      isFilter: true,
      sorter: true,
      width: 150,
      render: (text: string, record: TableRowType, index: number, config: any) => {
        return hasAuth(authDict.unit_detail) ? (
          <a
            onClick={() => {
              setUnitId(record.id);
              setDetailVisible(true);
              // window.open(`/knowledgeManagement/materialModeling/unitsList/details/${record.id}`);
            }}
          >
            <Tooltip text={text ?? replaceSign} width={config.contentWidth} />
          </a>
        ) : (
          <Tooltip text={text ?? replaceSign} width={config.contentWidth} />
        );
      },
    },
    {
      title: '单位状态',
      dataIndex: 'enableFlag',
      type: fieldTypeList.select,
      isFilter: true,
      sorter: true,
      width: 150,
      selectProps: {
        options: usageStatus,
      },
      render: (text: number) => (
        <span>
          <Badge status={text ? 'success' : 'error'} /> {lookup('common.usageStatus', text)}
        </span>
      ),
    },
    {
      title: '舍入规则',
      dataIndex: 'roundingRole',
      type: 'number',
      isFilter: false,
      width: 150,
      render: (text: number) => lookup('material.roundingRule', text),
    },
    {
      title: '精度管理',
      dataIndex: 'precisionFigure',
      type: 'number',
      isFilter: false,
      width: 150,
      render: (text: number) => {
        return typeof text === 'number' ? `${getDefaultPrecisionFigure(text)}位` : replaceSign;
      },
    },
    {
      title: '单位备注',
      dataIndex: 'remark',
      isFilter: false,
      width: 150,
    },
  ];
  const config = {
    mainMenu: [
      {
        title: '导出',
        showExport: true,
        icon: <BlIcon type="icondaochu" />,
        auth: authDict.unit_export,
        onClick: () =>
          openModal({
            optType: 'export',
            businessType: BUSINESS_TYPE.unit,
          }),
      },
      {
        title: '新建单位',
        icon: <BlIcon type="iconxinjiantianjia" />,
        auth: authDict.unit_add,
        onClick: () => {
          setUnitId(undefined);
          setActionType(CRUD.create);
          setCreateAndeditVisible(true);
        },
        items: [
          {
            title: '导入',
            showExport: true,
            auth: authDict.unit_import,
            onClick: () =>
              openModal({
                optType: 'import',
                businessType: BUSINESS_TYPE.unit,
              }),
          },
        ],
      },
    ],
    batchMenu: [
      {
        title: '启用',
        auth: authDict.unit_enable_disable,
        onClick: (onSuccess: any, onFail: any) => {
          patchRequest(fetchUnitStart, selectedRowKeys, '启用成功', onSuccess, onFail);
        },
      },
      {
        title: '停用',
        auth: authDict.unit_enable_disable,
        onClick: (onSuccess: any, onFail: any) => {
          patchRequest(fetchUnitStop, selectedRowKeys, '停用成功', onSuccess, onFail);
        },
      },
      // {
      //   title: '删除',
      //   onClick: (onSuccess: any, onFail: any) => {
      //     onFail();
      //     BLDelConfirm('是否删除当前单位', delForm, () => {
      //       handleFinish();
      //     });
      //   },
      // },
    ],
    getOperationList: (record: TableRowType) => {
      const { id, enableFlag } = record;

      return [
        {
          title: lookup('crud', CRUD.view),
          auth: authDict.unit_detail,
          onClick: () => {
            setUnitId(id);
            setDetailVisible(true);
          },
        },
        {
          title: lookup('crud', CRUD.edit),
          auth: authDict.unit_edit,
          onClick: () => {
            setUnitId(id);
            setCreateAndeditVisible(true);
            setActionType(CRUD.edit);
          },
        },
        {
          title: lookup('common.changeStatusAction', enableFlag),
          auth: authDict.unit_enable_disable,
          onClick: () => {
            const api = enableFlag ? fetchUnitStop : fetchUnitStart;

            patchRequest(
              api,
              [id],
              `${lookup('common.changeStatusAction', enableFlag)}成功`,
              () => {
                setRefreshMarker(new Date().getTime());
              },
            );
          },
        },
        // {
        //   title: lookup('crud', CRUD.delete),
        //   onClick: () => {
        //     BLDelConfirm('是否删除当前单位', delForm, () => {
        //       handleFinish(record.id);
        //     });
        //   },
        // },
        {
          title: '操作记录',
          auth: authDict.unit_operrecord,
          disabled: record?.id === 0,
          onClick: () => {
            setUnitId(id);
            setVisibleLog(true);
          },
        },
      ];
    },
    columns: dataColumns.map((column) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isFilter, type, ...rest } = column;

      return { ...rest };
    }),
    rowKey: 'id',
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((column) => {
        const filter = {
          label: column.title,
          name: column.dataIndex,
          type: column.type,
        } as any;

        if (column.type === fieldTypeList.select) {
          filter.selectProps = column.selectProps;
        }
        return filter;
      }),
  };
  const formatDataToQuery = (params: any) => {
    dispatch.excelImport.updateBusinessData({ businessData: params });
    return params;
  };
  const formatDataToDisplay = (params: any = {}) => {
    // table上的筛选展示，回显处理
    const retData = { ...params };

    retData.enableFlag = lookup('common.changeStatusAction', retData?.enableFlag);

    return retData;
  };
  const formatDataToFormDisplay = (params: any) => {
    // 筛选抽屉，回显处理
    const retData = { ...params };

    if (retData?.enableFlag) {
      retData.enableFlag = Number(retData.enableFlag);
    }

    return retData;
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        columns={config.columns}
        filterList={config.filterList}
        rowKey={config.rowKey}
        batchMenu={config.batchMenu}
        mainMenu={config.mainMenu}
        getOperationList={config.getOperationList}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={formatDataToDisplay}
        formatDataToFormDisplay={formatDataToFormDisplay}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey) => {
          setSelectRowKeys(selectKey);
        }}
        requestFn={fetchUnitList}
        refreshMarker={refreshMarker}
        configcacheKey={'unit'}
      />
      {createAndeditVisible && (
        <CreateAndEdit
          id={unitId}
          type={actionType}
          visible={createAndeditVisible}
          onCancel={() => setCreateAndeditVisible(false)}
          refreshTable={() => {
            setRefreshMarker(new Date().getTime());
          }}
          refreshDetails={() => {
            setRefreshDetailsMarker(new Date().getTime());
          }}
        />
      )}
      {detailVisible && (
        <Details
          id={Number(unitId)}
          visible={detailVisible}
          onCancel={() => setDetailVisible(false)}
          showEdit={() => {
            setUnitId(Number(unitId));
            setCreateAndeditVisible(true);
            setActionType(CRUD.edit);
          }}
          showOperationRecord={() => {
            setUnitId(Number(unitId));
            setVisibleLog(true);
          }}
          refreshDetailsMarker={refreshDetailsMarker}
          refreshTable={() => {
            setRefreshMarker(new Date().getTime());
          }}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={unitId as number}
          objectCode={OBJECT_OF_CODE.materialUnit}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(UnitsList);
