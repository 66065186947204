/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../../request';

// makeRequest
function makeRequestRequired<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequestOptional<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData?: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequest<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const optional = makeRequestOptional<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  const required = makeRequestRequired<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  return (
    requestConfig.requestDataOptional ? optional : required
  ) as TRequestConfig['requestDataOptional'] extends true ? typeof optional : typeof required;
}

// Request
export type Request<TReqeustData, TRequestConfig extends RequestConfig, TRequestResult> =
  (TRequestConfig['requestDataOptional'] extends true
    ? (
        requestData?: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult
    : (
        requestData: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult) & {
    requestConfig: TRequestConfig;
  };

const mockUrl_0_7_5_0 = 'http://yapi.blacklake.tech/mock/735' as any;
const devUrl_0_7_5_0 = '' as any;
const prodUrl_0_7_5_0 = '' as any;
const dataKey_0_7_5_0 = undefined as any;

/**
 * 接口 [新建不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/79946) 的 **请求类型**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/create`
 * @更新时间 `2021-11-23 14:14:05`
 */
export interface FetchQcDefectRankCreateRequest {
  /**
   * 名称
   */
  name: string;
  /**
   * 备注
   */
  remark?: string;
}

/**
 * 接口 [新建不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/79946) 的 **返回类型**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/create`
 * @更新时间 `2021-11-23 14:14:05`
 */
export interface FetchQcDefectRankCreateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: number;
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [新建不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/79946) 的 **请求配置的类型**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/create`
 * @更新时间 `2021-11-23 14:14:05`
 */
type FetchQcDefectRankCreateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_defect_rank/create',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [新建不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/79946) 的 **请求配置**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/create`
 * @更新时间 `2021-11-23 14:14:05`
 */
const fetchQcDefectRankCreateRequestConfig: FetchQcDefectRankCreateRequestConfig = {
  mockUrl: mockUrl_0_7_5_0,
  devUrl: devUrl_0_7_5_0,
  prodUrl: prodUrl_0_7_5_0,
  path: '/quality/domain/web/v1/qc_defect_rank/create',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_5_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcDefectRankCreate',
};

/**
 * 接口 [新建不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/79946) 的 **请求函数**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/create`
 * @更新时间 `2021-11-23 14:14:05`
 */
export const fetchQcDefectRankCreate = makeRequest<
  FetchQcDefectRankCreateRequest,
  FetchQcDefectRankCreateResponse,
  FetchQcDefectRankCreateRequestConfig
>(fetchQcDefectRankCreateRequestConfig);

/**
 * 接口 [删除不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/79952) 的 **请求类型**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/delete`
 * @更新时间 `2021-11-23 14:14:05`
 */
export interface FetchQcDefectRankDeleteRequest {
  ids?: number[];
}

/**
 * 接口 [删除不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/79952) 的 **返回类型**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/delete`
 * @更新时间 `2021-11-23 14:14:05`
 */
export interface FetchQcDefectRankDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 结果标识符 1成功 2失败 3出现失败(批量删除)
     */
    code?: number;
    /**
     * 失败数量
     */
    failedCount?: number;
    /**
     * 删除失败明细
     */
    failedList?: number[];
    /**
     * 删除最终结果
     */
    result?: string;
    /**
     * 成功数量
     */
    successCount?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [删除不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/79952) 的 **请求配置的类型**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/delete`
 * @更新时间 `2021-11-23 14:14:05`
 */
type FetchQcDefectRankDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_defect_rank/delete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [删除不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/79952) 的 **请求配置**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/delete`
 * @更新时间 `2021-11-23 14:14:05`
 */
const fetchQcDefectRankDeleteRequestConfig: FetchQcDefectRankDeleteRequestConfig = {
  mockUrl: mockUrl_0_7_5_0,
  devUrl: devUrl_0_7_5_0,
  prodUrl: prodUrl_0_7_5_0,
  path: '/quality/domain/web/v1/qc_defect_rank/delete',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_5_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcDefectRankDelete',
};

/**
 * 接口 [删除不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/79952) 的 **请求函数**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/delete`
 * @更新时间 `2021-11-23 14:14:05`
 */
export const fetchQcDefectRankDelete = makeRequest<
  FetchQcDefectRankDeleteRequest,
  FetchQcDefectRankDeleteResponse,
  FetchQcDefectRankDeleteRequestConfig
>(fetchQcDefectRankDeleteRequestConfig);

/**
 * 接口 [查询不良等级详情↗](http://yapi.blacklake.tech/project/735/interface/api/79958) 的 **请求类型**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/detail`
 * @更新时间 `2021-11-23 14:14:05`
 */
export interface FetchQcDefectRankDetailRequest {
  /**
   * 创建时间
   */
  createdAt?: string;
  creator?: {
    /**
     * web-User信息VO-头像
     */
    avatarUrl?: string;
    /**
     * web-User信息VO-编号
     */
    code?: string;
    /**
     * web-User信息VO-描述
     */
    desc?: string;
    /**
     * web-User信息VO-id
     */
    id?: number;
    /**
     * web-User信息VO-name
     */
    name?: string;
    /**
     * web-User信息VO-username
     */
    username?: string;
  };
  id: number;
  /**
   * 名称
   */
  name?: string;
  operator?: {
    /**
     * web-User信息VO-头像
     */
    avatarUrl?: string;
    /**
     * web-User信息VO-编号
     */
    code?: string;
    /**
     * web-User信息VO-描述
     */
    desc?: string;
    /**
     * web-User信息VO-id
     */
    id?: number;
    /**
     * web-User信息VO-name
     */
    name?: string;
    /**
     * web-User信息VO-username
     */
    username?: string;
  };
  /**
   * 备注
   */
  remark?: string;
  /**
   * 修改时间
   */
  updatedAt?: string;
}

/**
 * 接口 [查询不良等级详情↗](http://yapi.blacklake.tech/project/735/interface/api/79958) 的 **返回类型**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/detail`
 * @更新时间 `2021-11-23 14:14:05`
 */
export interface FetchQcDefectRankDetailResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 创建时间
     */
    createdAt?: string;
    creator?: {
      /**
       * web-User信息VO-头像
       */
      avatarUrl?: string;
      /**
       * web-User信息VO-编号
       */
      code?: string;
      /**
       * web-User信息VO-描述
       */
      desc?: string;
      /**
       * web-User信息VO-id
       */
      id?: number;
      /**
       * web-User信息VO-name
       */
      name?: string;
      /**
       * web-User信息VO-username
       */
      username?: string;
    };
    id: number;
    /**
     * 名称
     */
    name?: string;
    operator?: {
      /**
       * web-User信息VO-头像
       */
      avatarUrl?: string;
      /**
       * web-User信息VO-编号
       */
      code?: string;
      /**
       * web-User信息VO-描述
       */
      desc?: string;
      /**
       * web-User信息VO-id
       */
      id?: number;
      /**
       * web-User信息VO-name
       */
      name?: string;
      /**
       * web-User信息VO-username
       */
      username?: string;
    };
    /**
     * 备注
     */
    remark?: string;
    /**
     * 修改时间
     */
    updatedAt?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [查询不良等级详情↗](http://yapi.blacklake.tech/project/735/interface/api/79958) 的 **请求配置的类型**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/detail`
 * @更新时间 `2021-11-23 14:14:05`
 */
type FetchQcDefectRankDetailRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_defect_rank/detail',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [查询不良等级详情↗](http://yapi.blacklake.tech/project/735/interface/api/79958) 的 **请求配置**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/detail`
 * @更新时间 `2021-11-23 14:14:05`
 */
const fetchQcDefectRankDetailRequestConfig: FetchQcDefectRankDetailRequestConfig = {
  mockUrl: mockUrl_0_7_5_0,
  devUrl: devUrl_0_7_5_0,
  prodUrl: prodUrl_0_7_5_0,
  path: '/quality/domain/web/v1/qc_defect_rank/detail',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_5_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcDefectRankDetail',
};

/**
 * 接口 [查询不良等级详情↗](http://yapi.blacklake.tech/project/735/interface/api/79958) 的 **请求函数**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/detail`
 * @更新时间 `2021-11-23 14:14:05`
 */
export const fetchQcDefectRankDetail = makeRequest<
  FetchQcDefectRankDetailRequest,
  FetchQcDefectRankDetailResponse,
  FetchQcDefectRankDetailRequestConfig
>(fetchQcDefectRankDetailRequestConfig);

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79964) 的 **请求类型**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/pageList`
 * @更新时间 `2021-11-23 14:14:05`
 */
export interface FetchQcDefectRankPageListRequest {
  /**
   * 名称
   */
  name?: string;
  /**
   * 排序规律：字段名 排序 ,隔开
   */
  orderBy?: string;
  /**
   * 请求页
   */
  page?: number;
  /**
   * 快速搜索条件(名称或备注)
   */
  quickSearch?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 每页大小
   */
  size?: number;
}

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79964) 的 **返回类型**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/pageList`
 * @更新时间 `2021-11-23 14:14:05`
 */
export interface FetchQcDefectRankPageListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
      /**
       * 备注
       */
      remark?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79964) 的 **请求配置的类型**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/pageList`
 * @更新时间 `2021-11-23 14:14:05`
 */
type FetchQcDefectRankPageListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_defect_rank/pageList',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79964) 的 **请求配置**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/pageList`
 * @更新时间 `2021-11-23 14:14:05`
 */
const fetchQcDefectRankPageListRequestConfig: FetchQcDefectRankPageListRequestConfig = {
  mockUrl: mockUrl_0_7_5_0,
  devUrl: devUrl_0_7_5_0,
  prodUrl: prodUrl_0_7_5_0,
  path: '/quality/domain/web/v1/qc_defect_rank/pageList',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_5_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcDefectRankPageList',
};

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79964) 的 **请求函数**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/pageList`
 * @更新时间 `2021-11-23 14:14:05`
 */
export const fetchQcDefectRankPageList = makeRequest<
  FetchQcDefectRankPageListRequest,
  FetchQcDefectRankPageListResponse,
  FetchQcDefectRankPageListRequestConfig
>(fetchQcDefectRankPageListRequestConfig);

/**
 * 接口 [编辑不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/79970) 的 **请求类型**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/update`
 * @更新时间 `2021-11-23 14:14:05`
 */
export interface FetchQcDefectRankUpdateRequest {
  /**
   * 业务id
   */
  id: number;
  /**
   * 名称
   */
  name: string;
  /**
   * 备注
   */
  remark?: string;
}

/**
 * 接口 [编辑不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/79970) 的 **返回类型**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/update`
 * @更新时间 `2021-11-23 14:14:05`
 */
export interface FetchQcDefectRankUpdateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: number;
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [编辑不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/79970) 的 **请求配置的类型**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/update`
 * @更新时间 `2021-11-23 14:14:05`
 */
type FetchQcDefectRankUpdateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_defect_rank/update',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [编辑不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/79970) 的 **请求配置**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/update`
 * @更新时间 `2021-11-23 14:14:05`
 */
const fetchQcDefectRankUpdateRequestConfig: FetchQcDefectRankUpdateRequestConfig = {
  mockUrl: mockUrl_0_7_5_0,
  devUrl: devUrl_0_7_5_0,
  prodUrl: prodUrl_0_7_5_0,
  path: '/quality/domain/web/v1/qc_defect_rank/update',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_5_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcDefectRankUpdate',
};

/**
 * 接口 [编辑不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/79970) 的 **请求函数**
 *
 * @分类 [web-不良等级↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11352)
 * @标签 `web-不良等级`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_rank/update`
 * @更新时间 `2021-11-23 14:14:05`
 */
export const fetchQcDefectRankUpdate = makeRequest<
  FetchQcDefectRankUpdateRequest,
  FetchQcDefectRankUpdateResponse,
  FetchQcDefectRankUpdateRequestConfig
>(fetchQcDefectRankUpdateRequestConfig);

/* prettier-ignore-end */
