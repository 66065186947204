import React from 'react';
import { Radio, InputNumber, FormInstance, Form } from 'antd';
import {
  validateBlInputNumberIntervalInt,
  validatorNumberDigtis,
  validatorMax,
} from '../verificationRules/index';
import { appDict, appEnum } from 'src/dict';
import { FieldBaseProps } from 'src/page/custom_fields/fieldsList/index.d';

const VALUE = 1000000000;

export const numItems = ({ form, isPreField, isEdit }: FieldBaseProps) => {
  return [
    {
      label: '最大值',
      name: 'maxValue',
      initialValue: VALUE,
      rules: [
        { required: true, message: '请输入最大值' },
        { validator: validateBlInputNumberIntervalInt(-VALUE, VALUE) },
      ],
      render: () => (
        <InputNumber disabled={isPreField} style={{ width: 300 }} placeholder="请输入" />
      ),
    },
    {
      label: '小数位数',
      name: 'decimalNumber',
      rules: [{ validator: validateBlInputNumberIntervalInt(1, 8) }],
      render: () => <InputNumber style={{ width: 300 }} placeholder="请输入" disabled={isEdit} />,
    },
    {
      label: '必填',
      name: 'isRequired',
      initialValue: appEnum.Common.YN.no,
      rules: [{ required: true, message: '请选择' }],
      render: () => (
        <Radio.Group
          disabled={isPreField && form.getFieldValue('isRequired') === appEnum.Common.YN.yes}
          options={appDict.common.yn}
        />
      ),
    },
    {
      label: '默认值',
      shouldUpdate: (prevValues: any, curValues: any) => {
        return (
          prevValues.decimalNumber !== curValues.decimalNumber ||
          prevValues.maxValue != curValues.maxValue
        );
      },
      render: () => {
        return () => {
          return (
            <Form.Item
              name="defaultValue"
              validateFirst
              rules={[
                {
                  validator: validatorNumberDigtis(form.getFieldValue('decimalNumber') || 8),
                },
                {
                  validator: validatorMax(form.getFieldValue('maxValue') || VALUE),
                },
              ]}
            >
              <InputNumber disabled={isPreField} style={{ width: 300 }} placeholder="请输入" />
            </Form.Item>
          );
        };
      },
    },
  ];
};
