/**
 * @component SOP控件执行值
 * 目前：附件，用户，关联字段控件为非string类型
 */

import { TextToolTip } from '@blacklake-web/component';
import { Button, Modal } from 'antd';
import _, { isNil } from 'lodash';
import { FC, useState } from 'react';
import { BcAttachmentDetail } from 'src/components';
import { ControlType, FieldSource } from 'src/dict/sop';
import { User } from 'src/page/share';
import { renderUserArray } from 'src/page/share/renderUser';
import { replaceSign } from 'src/utils/constants';
import ControlRecordTable from '../../execRecord/components/controlRecordTable';
import { AssociateFieldRecordType } from '../../type';

interface Props {
  controlType: number;
  controlValue: string | number[] | User[] | AssociateFieldRecordType;
}
type ModalInfo = {
  visible: boolean;
  [x: string]: any;
};

const ControlValue: FC<Props> = ({ controlType, controlValue }) => {
  const [modalInfo, setModalInfo] = useState<ModalInfo>({ visible: false });

  const renderControlValue: any = (
    controlType: ControlType,
    controlValue: string | number[] | User[] | AssociateFieldRecordType,
  ) => {
    // controlValue 目前接口只有以上类型，后台返回除约定的类型外,异常情况返回object类型时，兼容一下返回 replaceSign
    const validControlValue =
      isNil(controlValue) || !controlValue || _.isObject(controlValue) ? replaceSign : controlValue;

    switch (controlType) {
      case ControlType.file:
        if (!controlValue?.length) {
          return replaceSign;
        }
        return <BcAttachmentDetail fileIds={controlValue as number[]} />;
      case ControlType.user:
        if (!controlValue?.length) {
          return replaceSign;
        }
        return renderUserArray(controlValue as User[]);
      case ControlType.associatedField: {
        const first = _.get(controlValue, 'first');
        const second = _.get(controlValue, 'second');

        if (first === FieldSource.currentSheet) {
          const secondFirst = _.get(second, 'first');
          const secondSecond = _.get(second, 'second');

          // 需求侧：关联字段不会关联一个关联字段控件，避免后台数据异常导致不可控情况，是否加一个判断
          // if (secondFirst !== ControlType.associatedField) {
          // }
          return renderControlValue(secondFirst, secondSecond);
        } else if (first === FieldSource.otherSheet) {
          // 后台让instanceIds长度大于0时显示「查看」按钮，去请求弹窗
          if (!_.get(second, 'instanceIds')?.length) {
            return replaceSign;
          }
          return (
            <a
              type="link"
              onClick={() => {
                setModalInfo({
                  visible: true,
                  controlValue: second,
                });
              }}
            >
              查看
            </a>
          );
        }
        return <TextToolTip text={controlValue} />;
      }
      default:
        return <TextToolTip text={validControlValue} />;
    }
  };

  return (
    <>
      {renderControlValue(controlType, controlValue)}
      <Modal
        visible={modalInfo.visible}
        destroyOnClose
        title="记录结果"
        width={640}
        centered
        onCancel={() => {
          setModalInfo({ ...modalInfo, ...{ visible: false } });
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => setModalInfo({ ...modalInfo, ...{ visible: false } })}
          >
            关闭
          </Button>,
        ]}
      >
        <ControlRecordTable controlValue={modalInfo.controlValue} />
      </Modal>
    </>
  );
};

export default ControlValue;
