import React, { useState } from 'react';
import { Resizable } from 'react-resizable';
import { ResizableContentProps, ResizeData } from './ResizableContent.d';
import './Resizable.css';

const BlResizableContent = (props: ResizableContentProps) => {
  const { contentRender, width, height, isChangeHeight, isChangeWidth, style, ...restProps } =
    props;
  const [startWidth, setStartWidth] = useState(width);
  const [startHeight, setStartHeight] = useState(height);
  const onResize = (e: React.SyntheticEvent, data: ResizeData) => {
    isChangeWidth ? setStartWidth(data?.size?.width) : setStartWidth(width);
    isChangeHeight ? setStartHeight(data?.size?.height) : setStartHeight(height);
  };

  return (
    <Resizable width={startWidth} height={startHeight} onResize={onResize} {...restProps}>
      <div
        className="box"
        style={{ width: `${startWidth}px`, height: `${startHeight}px`, ...style }}
      >
        {contentRender()}
      </div>
    </Resizable>
  );
};

export default BlResizableContent;
