import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { message } from 'antd';
import { FilterItem, RecordListLayout } from 'layout';
import { BlIcon, LinkTooltip, SingleOperationLogComponent, Tooltip } from 'components';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import ReceiveModel from '../components/receive';
import moment from 'moment';
import _, { isEmpty } from 'lodash';
import { gcTime, gcObject, fieldTypeList } from 'utils';
import lookup, { appEnum, appDict } from 'src/dict';
import {
  fetchReceiveNoteList,
  fetchReceiveNoteListMaterialByIds,
} from 'src/api/ytt/order-domain/deliveryOrder';
import { DeliveryTableRowType as TableRowType } from '../index.type';
import { renderUser } from 'src/page/share/renderUser';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { detailPath, editPath, createPath, inBoundCreatePath } from '../index';
import { endOrder, dispatchOrder, closeOrder, createInbound, enableFn } from '../utils';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import authDict from 'src/utils/auth';
import { useDispatch } from 'react-redux';

interface Props extends RouteComponentProps {}

const DeliveryOrder = (props: Props) => {
  const { history } = props;
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const [selectedRow, setSelectRow] = useState<any[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [visible, setVisible] = useState<boolean>(false);
  const [ids, setIds] = useState<number[]>([]);
  const openModal = useOpenExImportModal();
  const [currentId, setCurrentId] = useState<number>();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const dispatch = useDispatch();

  const toDetail = (id?: number) => {
    if (!id) return;
    history.push(`${detailPath}?id=${id}`);
  };
  const toEdit = (id?: number) => {
    if (!id) return;
    history.push(`${editPath}?id=${id}`);
  };
  const toCreate = () => {
    history.push(`${createPath}`);
  };
  const refreshData = () => {
    setRefreshMarker(Math.random());
  };
  const dataColumns = [
    {
      title: '编号',
      dataIndex: 'code',
      sorter: true,
      isFilter: true,
      width: 150,
      render: (value: string, record: TableRowType, index: number, config: any) => (
        <LinkTooltip
          to={`${detailPath}?id=${record?.id}`}
          text={value}
          width={config.contentWidth}
          auth={authDict.receivenote_detail}
        />
      ),
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '来源',
      dataIndex: 'source',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      render: lookup('DeliveryMap.Source'),
      selectProps: {
        placeholder: '请选择',
        mode: 'multiple',
        options: appDict.DeliveryMap.Source,
      },
    },
    {
      title: '状态',
      dataIndex: 'execStatus',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      render: lookup('DeliveryMap.execStatus'),
      selectProps: {
        placeholder: '请选择',
        mode: 'multiple',
        options: appDict.DeliveryMap.execStatus,
      },
    },
    {
      title: '供应商编号',
      dataIndex: 'supplierCode',
      isFilter: true,
      width: 150,
    },
    {
      title: '供应商名称',
      dataIndex: 'supplierName',
      isFilter: true,
      width: 150,
    },
    {
      title: '协同发货单号',
      dataIndex: 'shipmentNoteCode',
      isFilter: true,
      sorter: true,
      width: 150,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 150,
      isFilter: true,
      render: renderUser,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择创建人" isMultiple isSelectUser />
      ),
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      width: 200,
      render: (text: number) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      width: 150,
      isFilter: true,
      render: renderUser,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择创建人" isMultiple isSelectUser />
      ),
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      width: 200,
      render: (text: number) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  const config = {
    rowKey: (record: TableRowType) => String(record.id),
    columns: dataColumns,
    getOperationList: (record: TableRowType) => {
      const { execStatus } = record;
      const enable = execStatus === appEnum.DeliveryEnum.execStatusEnum.executing;
      const isCreated = execStatus === appEnum.DeliveryEnum.execStatusEnum.created;

      return _.compact([
        {
          title: '查看',
          auth: authDict.receivenote_detail,
          onClick: () => toDetail(record.id),
        },
        {
          title: '编辑',
          disabled: !enable && !isCreated,
          auth: authDict.receivenote_edit,
          onClick: () => toEdit(record.id),
        },
        {
          title: '下发',
          disabled: !isCreated,
          auth: authDict.receivenote_dispatch,
          onClick: () => dispatchOrder([record?.id ?? 0], refreshData),
        },
        {
          title: '收货',
          disabled: !enable,
          auth: authDict.receivenote_receipt,
          onClick: () => {
            if (!record.id) {
              return;
            }
            setIds([record.id]);
            setVisible(true);
          },
        },
        {
          title: '关闭',
          disabled: !isCreated,
          auth: authDict.receivenote_close,
          onClick: () => closeOrder([record?.id ?? 0], refreshData),
        },
        {
          title: '结束',
          disabled: !enable,
          auth: authDict.receivenote_finish,
          onClick: () => {
            if (!record.id) return;
            endOrder({ list: [record], success: refreshData, type: 'single' });
          },
        },
        {
          title: '新建入库单',
          disabled: !enable,
          auth: authDict.inboundorder_add,
          onClick: () => createInbound(history, [record]),
        },
        {
          title: '操作记录',
          auth: authDict.receivenote_operrecord,
          onClick: () => {
            setCurrentId(record.id);
            setVisibleLog(true);
          },
        },
      ]);
    },
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((columns: any): FilterItem => {
        const filter = {
          label: columns.title,
          name: columns.dataIndex,
          ...columns,
        };

        return filter;
      }),
    formatDataToFormDisplay: (filterData: any) => {
      const { createdAt, updatedAt, ...rest } = filterData;

      const result = {
        ...rest,
        createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
        updatedAt: gcTime.formatRangeTimeToMoment(updatedAt || []),
      };

      return gcObject.filterEmptyProperty(result);
    },
    formatDataToQuery: (param: any) => {
      const { createdAt, source, execStatus, creator, operator, updatedAt, ...rest } = param;
      const [createdAtFrom, createdAtTo] = gcTime.formatTimeRangeUnix(createdAt);
      const [updatedAtFrom, updatedAtTo] = gcTime.formatTimeRangeUnix(updatedAt);

      const options = {
        createdAtFrom,
        createdAtTo,
        updatedAtFrom,
        updatedAtTo,
        creatorIds: creator?.length ? _.map(creator, 'value') : null,
        operatorIds: operator?.length ? _.map(operator, 'value') : null,
        sourceList: source,
        execStatusList: execStatus,
        ...rest,
      };

      dispatch.excelImport.updateBusinessData({
        businessData: gcObject.filterEmptyProperty(options),
      });
      return gcObject.filterEmptyProperty(options);
    },
  };

  return (
    <>
      <ReceiveModel
        onSubmit={() => {
          refreshData();
          setVisible(false);
        }}
        ids={ids}
        onCancel={() => setVisible(false)}
        visible={visible}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={currentId as number}
          objectCode={OBJECT_OF_CODE.receiveNote}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      <RecordListLayout<TableRowType>
        placeholder={'请输入编号、协同发货单号'}
        useIndex={false}
        batchMenu={[
          {
            title: '下发',
            auth: authDict.receivenote_dispatch,
            onClick: (onSuccess: any, onFail: any) => {
              dispatchOrder(
                selectedRowKeys.map((item) => Number(item)),
                refreshData,
                onSuccess,
                onFail,
              );
            },
          },
          {
            title: '收货',
            auth: authDict.receivenote_receipt,
            onClick: (onSuccess: any, onFail: any) => {
              onFail();
              if (!enableFn(selectedRow)) {
                message.error('此处仅显示收货单状态为“执行中”的数据，其他状态无法执行收货操作。');
                return;
              }
              setIds(selectedRowKeys.map((item) => Number(item)));
              setVisible(true);
            },
          },
          {
            title: '关闭',
            auth: authDict.receivenote_dispatch,
            onClick: (onSuccess: any, onFail: any) => {
              closeOrder(
                selectedRowKeys.map((item) => Number(item)),
                refreshData,
                onSuccess,
                onFail,
              );
            },
          },
          {
            title: '结束',
            auth: authDict.receivenote_finish,
            onClick: (onSuccess: any, onFail: any) => {
              onFail();
              endOrder({
                list: selectedRow,
                success: onSuccess,
                type: 'multiple',
              });
            },
          },
          {
            title: '新建入库单',
            auth: authDict.inboundorder_add,
            onClick: (onSuccess: any, onFail: any) => {
              createInbound(history, selectedRow);
              onFail();
            },
          },
        ]}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey, row) => {
          setSelectRowKeys(selectKey);
          if (row) {
            setSelectRow(row);
          }
        }}
        refreshMarker={refreshMarker}
        mainMenu={_.compact([
          {
            title: '导出',
            auth: authDict.receivenote_export,
            icon: <BlIcon type="icondaochu" />,
            showExport: true,
            onClick: () =>
              openModal({
                optType: 'export',
                businessType: BUSINESS_TYPE.receiveNote,
              }),
          },
          {
            title: '新建收货单',
            auth: authDict.receivenote_add,
            icon: <BlIcon type="icondaochu" />,
            items: [],
            showExport: true,
            onClick: () => {
              toCreate();
            },
          },
        ])}
        requestFn={fetchReceiveNoteList}
        configcacheKey="receiveOrderItemModule"
        {...config}
      />
    </>
  );
};

export default DeliveryOrder;
