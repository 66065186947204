import React, { useState } from 'react';
import { BlTable } from '@blacklake-web/component';
import { Button, message, Space } from 'antd';
import { replaceSign } from 'src/utils/constants';
import { TemplateDataSetInfoList } from '../../interface';
import TemplateDataSetDetail from './templateDataSetDetail';

interface Props {
  initData?: TemplateDataSetInfoList[];
}

export default function AddDataTabContent({ initData }: Props) {
  const [visible, setVisible] = useState<boolean>(false);

  const [initLineData, setInitLineData] = useState<TemplateDataSetInfoList>(
    {} as TemplateDataSetInfoList,
  );

  const copyText = (text?: string) => {
    if (!text) {
      message.error('复制失败');
      return;
    }
    const input = document.createElement('input');

    input.value = text;
    document.body.appendChild(input);
    input.select();
    const copyResult = document.execCommand('copy');

    document.body.removeChild(input);
    if (copyResult) {
      message.success('已复制');
    } else {
      message.error('复制失败');
    }
  };

  const dataColumns = [
    {
      title: '序号',
      dataIndex: 'serialNumber',
      width: 200,
      render: (serialNumber: number) => serialNumber,
    },
    {
      title: '模板数据集编号',
      dataIndex: 'temDataSetCode',
      width: 300,
      render: (temDataSetCode: string) => temDataSetCode,
    },
    {
      title: '模板数据集名称',
      dataIndex: 'serialNumber',
      width: 300,
      render: (serialNumber: number, record: TemplateDataSetInfoList) => {
        const line = '_';

        return serialNumber < 10
          ? `0${serialNumber}${line}${record?.dataSetName}`
          : `${serialNumber}${line}${record?.dataSetName}`;
      },
    },
    {
      title: '数据集来源',
      width: 300,
      dataIndex: 'dataSetName',
      render: (dataSetName: string) => dataSetName ?? replaceSign,
    },
    {
      title: '操作',
      width: 200,
      dataIndex: 'operation',
      render: (_: any, record: TemplateDataSetInfoList) => {
        return (
          <>
            <Space size={20}>
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => {
                  setVisible(true);
                  setInitLineData(record);
                }}
              >
                查看
              </Button>
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => copyText(record?.temDataSetCode)}
              >
                复制编号
              </Button>
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() =>
                  copyText(
                    record?.temDataSetPreviewFieldInfoList
                      ?.map((i) => i.fieldDisplayName)
                      ?.join(','),
                  )
                }
              >
                复制字段
              </Button>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <BlTable
        columns={dataColumns}
        dataSource={initData}
        rowKey={(record: any) => record?.templateDataSetId}
        id="templateDataSetId"
        scroll={{ x: 1000 }}
        style={{ width: '100%' }}
      />
      {visible && (
        <TemplateDataSetDetail
          visible={visible}
          initData={initLineData}
          onClose={() => setVisible(false)}
        />
      )}
    </div>
  );
}
