import { BlIcon } from 'src/components';
import styles from '../../styles.module.scss';

interface Props {
  licenseName: string;
  authNumber: number;
  availableNumber: number;
  note: string | undefined;
}
const SystemUsage = (props: Props) => {
  const { licenseName, authNumber, availableNumber, note } = props;

  return (
    <div className={styles['allowance-item-container']}>
      <div className={styles['allowance-item-upper']}>
        <div style={{ fontSize: 16, fontWeight: 500 }}>{licenseName}</div>
        <div>
          <span style={{ color: 'rgba(0, 0, 0, .65)' }}>
            <BlIcon type="iconshuliang" style={{ marginRight: 4, color: '#FAAD14' }} />
            授权总量：{authNumber}个
          </span>
          <span style={{ marginLeft: 24, fontWeight: 500 }}>
            <BlIcon type="iconshuliang" style={{ marginRight: 4, color: '#02B980' }} />
            可用量：{availableNumber}个
          </span>
        </div>
      </div>
      <div className={styles['allowance-item-lower']}>备注：{note}</div>
    </div>
  );
};

export default SystemUsage;
