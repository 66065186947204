import React, { useState } from 'react';
import { Space, Dropdown, Menu, Form, message as Message, Tag, Button } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { RecordListLayout } from 'layout';
import { BlIcon, SingleOperationLogComponent, TagList, Tooltip } from 'src/components';
import { CheckClassType, QcCheckItemListType } from './interface';
import { replaceSign } from 'src/utils/constants';
import { toCheckClassDetail, toCheckClassEdit } from './navigation';
import CreateCheckItemClassification from '../checkItem/components/openCreateModal';
import CheckItemClassificationDetail from './detail';
import _String from 'src/utils/dataTypes/string';
import { fetchQcCheckItemCategoryPageList } from 'src/api/ytt/quality-domain/checkClass';
import { fieldTypeList } from 'src/utils';
import { FilterItem } from '@blacklake-web/layout/dist/logList/LogListLayout.type';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';

interface Props extends RouteComponentProps {}

const CREATE_BY_CHECK_CLASS = 0; //  检验项分类的直接新建

const CheckItemClassification = (props: Props) => {
  const { history } = props;
  // const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [createVisible, setCreateVisible] = useState(false);
  const [visibleDetail, setVisibleDetail] = useState(false);
  const [checkClassId, setCheckClassId] = useState<any>();
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const [delForm] = Form.useForm();

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  // const handleDelete = async (ids: number[], onSuccess?: () => void, onFail?: () => void) => {
  //   try {
  //     const { code, message } = await fetchQcCheckItemCategoryDelete({ ids });

  //     if (code === 200) {
  //       typeof onSuccess === 'function' && onSuccess?.();
  //       Message.success('删除成功');
  //       return;
  //     }
  //     typeof onFail === 'function' && onFail?.();
  //     Message.error(message);
  //   } catch (error) {
  //     typeof onFail === 'function' && onFail?.();
  //     console.log(error);
  //   }
  // };

  const hasAuthRes = hasAuth(authDict.inspectionitemcategory_detail);

  const dataColumns = [
    {
      title: '检验项分类名称',
      width: 200,
      dataIndex: 'name',
      render: (name: string, record: CheckClassType, index: number, config: any) => {
        return (
          <>
            {hasAuthRes ? (
              <a
                onClick={() => {
                  history.push(toCheckClassDetail(record?.id));
                }}
              >
                <Tooltip text={name ?? replaceSign} width={config.contentWidth} />
              </a>
            ) : (
              <Tooltip text={name ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },
    {
      title: '关联检验项',
      width: 200,
      dataIndex: 'qcCheckItemList',
      render: (qcCheckItemList: QcCheckItemListType[]) => {
        return <TagList dataSource={qcCheckItemList} labelPath="name" valuePath="id" />;
      },
    },
  ];

  const left = [
    {
      title: '导出',
      showExport: true,
      icon: <BlIcon type="icondaochu" />,
      auth: authDict.inspectionitemcategory_export,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.qcCheckItemCategory,
        });
      },
    },
    {
      title: '新建检验项分类',
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.inspectionitemcategory_add,
      onClick: () => {
        setCreateVisible(true);
      },
      items: [
        {
          title: '导入',
          showExport: true,
          auth: authDict.inspectionitemcategory_import,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.qcCheckItemCategory,
            });
          },
        },
      ],
    },
  ];

  // const toolbar = [
  //   {
  //     title: '删除',
  //     onClick: (onSuccess: any, onFail: any) => {
  //       BLDelConfirm(
  //         '删除对应分类会使所属检验项归类到“默认分类”下，要继续删除吗',
  //         delForm,
  //         () => {
  //           handleDelete(selectedRowKeys, onSuccess, onFail);
  //         },
  //         () => {
  //           onFail();
  //         },
  //       );
  //     },
  //   },
  // ];

  const filterList: FilterItem[] = [
    {
      label: '检验项分类名称',
      name: 'name',
      type: fieldTypeList?.text,
    },
  ];

  const formatDataToQuery = (params: any) => {
    const { quickSearch, ...rest } = params;

    const relParams = {
      ...rest,
    };

    if (!_String.isEmpty(quickSearch)) {
      relParams.name = quickSearch;
    }

    dispatch.excelImport.updateBusinessData({ businessData: relParams });
    return relParams;
  };

  const config = {
    rowKey: (record: CheckClassType) => String(record.id),
    columns: dataColumns,
    getOperationList: (record: CheckClassType) => {
      return [
        {
          title: '查看',
          auth: authDict.inspectionitemcategory_detail,
          onClick: () => {
            setCheckClassId(record?.id);
            setVisibleDetail(true);
          },
        },
        {
          title: '编辑',
          disabled: record?.id === 0,
          auth: authDict.inspectionitemcategory_edit,
          onClick: () => history.push(toCheckClassEdit(record?.id)),
        },
        {
          title: '操作记录',
          auth: authDict.inspectionitemcategory_operrecord,
          disabled: record?.id === 0,
          onClick: () => {
            setCheckClassId(record?.id);
            setVisibleLog(true);
          },
        },
      ];
    },
    mainMenu: left,
    filterList,
    formatDataToQuery,
  };

  return (
    <>
      <RecordListLayout<CheckClassType>
        userAuth={getAuthFromLocalStorage()}
        // batchMenu={toolbar}
        placeholder={'输入检验项分类名称'}
        // selectedRowKeys={selectedRowKeys}
        configcacheKey="checkItemClassification"
        // onSelectedRowKeys={(selectKey: any) => {
        //   setSelectRowKeys(selectKey);
        // }}
        requestFn={fetchQcCheckItemCategoryPageList}
        refreshMarker={refreshMarker}
        resizableCol={false}
        {...config}
      />
      {createVisible && (
        <CreateCheckItemClassification
          visible={createVisible}
          action={CREATE_BY_CHECK_CLASS}
          closeCreateButton={() => {
            setCreateVisible(false);
          }}
          refreshList={refresh}
        />
      )}
      {visibleDetail && (
        <CheckItemClassificationDetail
          visible={visibleDetail}
          closeDetail={() => {
            setVisibleDetail(false);
          }}
          checkClassId={checkClassId}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={checkClassId}
          objectCode={'InspectionItemCategory'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default CheckItemClassification;
