/**
 * 带编辑icon的文本，点击icon后切换为Input
 *
 * 注意：要让text-overflow生效，必须在父级或某一级祖先节点上设置可以获得确定值的 width 或 max-width
 */
import { FC, useState } from 'react';
import { Input, InputProps, Button, Tooltip } from 'antd';
import classnames from 'classnames';
import { omit, identity } from 'lodash';
import { BlIcon } from '..';
import color from '../styles/color';
import styles from './styles.module.scss';

interface Props {
  value?: InputProps['value'];
  onChange?: InputProps['onChange'];
  inputProps?: Omit<InputProps, 'value' | 'onChange'>;
  canEdit?: boolean;
}

const EditableText: FC<Props> = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const { value, onChange, inputProps, canEdit = true } = props;

  const TextComp = (
    <span className={styles.editableTextComp}>
      <Tooltip title={value}>
        <span
          className={classnames(
            styles.editableText,
            canEdit ? styles.editableWitdh : styles.pureTextWidth,
          )}
        >
          {value}
        </span>
      </Tooltip>
      {canEdit && (
        <Button
          type="text"
          icon={<BlIcon type="iconbianji" style={{ color: color.textColorPrimary }} />}
          onClick={() => setIsEdit(true)}
        />
      )}
    </span>
  );
  const InputComp = <Input value={value} onChange={onChange} {...inputProps} />;

  return isEdit ? InputComp : TextComp;
};

export default EditableText;

interface DisplayTextProps extends Omit<Props, 'canEdit'> {
  renderText?: (s: any) => string;
}

/** 纯展示文本 */
export const DisplayText: FC<DisplayTextProps> = (props) => {
  const { value, renderText = identity } = props;

  return <EditableText {...omit(props, ['value'])} value={renderText(value)} canEdit={false} />;
};
