import React, { useState, useEffect } from 'react';
import { RecordListLayout } from 'src/layout';
import { fieldTypeList } from 'src/utils';
import { CategoryDetailDrawer } from './detail';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { fetchResourceTypeList } from 'src/api/ytt/resource-domain/resourceCategory';
import { fetchCommonGetBusinessTypeEnum } from 'src/api/ytt/resource-domain/common';
import Action from './action';
import { Select } from 'antd';
import LocalStorage from 'src/utils/localStorage';
import { FIELDS } from 'src/utils/constants';
import { useDispatch } from 'react-redux';
import { ObjectCode } from 'src/dict/common';
import authDict from 'src/utils/auth';

const ResourceCategoryList = () => {
  const [detailVisible, setDetailVisible] = useState<boolean>(false);
  const [detailId, setDetailId] = useState<number>(0);
  const [businessLevel, setBusinessLevel] = useState<number>(0);
  const [parentName, setParentName] = useState<string>('');
  const [parentId, setParentId] = useState<number>(0);
  const [businessType, setBusinessType] = useState<number>(0);
  const [actionType, setActionType] = useState<string>('');
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [actionVisible, setActionVisible] = useState<boolean>(false);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [businessTypeEnum, setBusinessTypeEnum] = useState<any>();
  const [businessTypeOptions, setBusinessTypeOptions] = useState<any>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const fetchBusinessType = async () => {
    const { data } = await fetchCommonGetBusinessTypeEnum();
    const options = Object.keys(data || {}).map((key: any) => {
      return { label: data[key], value: Number(key) };
    });

    setBusinessTypeEnum(data);
    setBusinessTypeOptions(options);
  };

  const showResourceDetail = (id: number) => {
    setDetailId(id);
    setDetailVisible(true);
  };

  useEffect(() => {
    (async () => {
      const flag: any = LocalStorage.get(FIELDS.RESOURCE_TYPE_IS_CREATE);

      await fetchBusinessType();

      if (flag === true) {
        setActionType('create');
        setBusinessLevel(1);
        setBusinessType(businessType);
        setActionVisible(true);
        LocalStorage.remove(FIELDS.RESOURCE_TYPE_IS_CREATE);
      }
    })();
  }, []);

  const businessLevelOptions = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
  ];
  const dataColumns = [
    {
      title: '分类编号',
      dataIndex: 'code',
      isFilter: true,
      sorter: true,
      width: 150,
    },
    {
      title: '分类名称',
      dataIndex: 'name',
      isFilter: true,
      sorter: true,
      width: 150,
    },
    {
      title: '分类层级',
      dataIndex: 'businessLevel',
      isFilter: true,
      width: 150,
      renderItem: (
        <Select
          placeholder="请选择分类层级"
          value={businessLevel}
          options={businessLevelOptions}
          allowClear
        />
      ),
    },
    {
      title: '业务类型',
      dataIndex: 'businessType',
      isFilter: true,
      width: 150,
      renderItem: (
        <Select placeholder="请选择业务类型" value={businessType} options={businessTypeOptions} />
      ),
      render: (businessType: number) => {
        return <div>{businessTypeEnum?.[businessType]}</div>;
      },
    },
    {
      title: '备注',
      dataIndex: 'remarks',
      width: 150,
    },
  ];
  const searchData = async (params: any) => {
    const data = await fetchResourceTypeList({ ...params });
    const expandedKeys = formatSearchData(data?.data?.list, [], params);

    if (
      params.quickSearch ||
      params.businessLevel ||
      params.businessType ||
      params.code ||
      params.name
    ) {
      setExpandedRowKeys(expandedKeys);
    }
    return data;
  };
  const formatDataToQuery = (params: any) => {
    dispatch.excelImport.updateBusinessData({ businessData: params });
    return params;
  };
  const config = {
    rowKey: 'id',
    useIndex: false,
    columns: dataColumns,
    // 操作列
    getOperationList: (record: any) => {
      const { id, businessLevel, name, businessType } = record;

      return [
        {
          title: '查看',
          auth: authDict.resourcetype_detail,
          onClick: () => showResourceDetail(id),
        },
        {
          title: '编辑',
          disabled: false,
          auth: authDict.resourcetype_edit,
          onClick: () => {
            setActionType('edit');
            setDetailId(id);
            setBusinessLevel(businessLevel);
            setActionVisible(true);
          },
        },
        // {
        //   title: '删除',
        //   disabled: false,
        //   auth: authDict.resourcetype_remove,
        //   onClick: () => { },
        // },
        {
          title: '新建子分类',
          disabled: businessLevel === 5,
          auth: authDict.resourcetype_add,
          onClick: () => {
            setActionType('create');
            setBusinessLevel(businessLevel + 1);
            setBusinessType(businessType);
            setParentName(name);
            setParentId(id);
            setActionVisible(true);
            setDetailId(id);
          },
        },
        {
          title: '操作记录',
          auth: authDict.resourcetype_operrecord,
          onClick: () => {
            setDetailId(id);
            setVisibleLog(true);
          },
        },
      ];
    },
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((column: any) => {
        const filter: any = {
          label: column.title,
          name: column.filterIndex || column.dataIndex,
          type: column.type,
          rules: column.rules,
          renderItem: column.renderItem,
        };

        if (column.type === fieldTypeList.select || column.type === fieldTypeList.multiSelect) {
          filter.selectProps = column.selectProps;
        }
        return filter;
      }),
    mainMenu: [
      {
        title: '导出',
        showExport: true,
        icon: <BlIcon type="icondaochu" />,
        auth: authDict.resourcetype_export,
        onClick: () => {
          openModal({
            optType: 'export',
            businessType: BUSINESS_TYPE.ResourceType,
          });
        },
      },
      {
        title: '新建分类',
        icon: <BlIcon type="iconxinjiantianjia" />,
        auth: authDict.resourcetype_add,
        onClick: () => {
          setActionType('create');
          setBusinessLevel(1);
          setBusinessType(0);
          setParentName('');
          setActionVisible(true);
        },
        items: [
          {
            title: '导入',
            showExport: true,
            auth: authDict.resourcetype_import,
            onClick: () => {
              openModal({
                optType: 'import',
                businessType: BUSINESS_TYPE.ResourceType,
              });
            },
          },
        ],
      },
    ],
  };
  const refreshData = () => {
    setRefreshMarker(Math.random());
    searchData({});
  };
  const formatSearchData = (list: any, expandedRowKeys: number[], params: any) => {
    list?.forEach((data: any) => {
      if (data?.children?.length == 0) {
        data.children = null;
      }
      if (
        (params.businessLevel && params.businessLevel > data.businessLevel) ||
        !params.businessLevel
      ) {
        if (data?.children && data?.children.length) {
          const childrenList = data.children;

          formatSearchData(childrenList, expandedRowKeys, params);
          if (!expandedRowKeys.includes(data.id)) {
            expandedRowKeys.unshift(data.id);
          }
        }
      }
    });

    return expandedRowKeys;
  };

  return (
    <>
      <RecordListLayout
        requestFn={async (params) => searchData(params)}
        refreshMarker={refreshMarker}
        formatDataToQuery={formatDataToQuery}
        expandable={{
          expandedRowKeys,
          expandRowByClick: false,
          onExpandedRowsChange: (expandedRows: number[]) => {
            setExpandedRowKeys(expandedRows);
          },
        }}
        {...config}
      />
      {detailVisible && (
        <CategoryDetailDrawer
          visible={detailVisible}
          onCloseDetail={() => setDetailVisible(false)}
          id={detailId}
          businessTypeEnum={businessTypeEnum}
          showEdit={(obj) => {
            setActionType(obj.type);
            setBusinessLevel(obj.businessLevel);
            setBusinessType(obj.businessType);
            setParentName(obj.parentName);
            setActionVisible(true);
            setDetailId(detailId);
          }}
          showLog={() => setVisibleLog(true)}
        />
      )}
      {actionVisible && (
        <Action
          visible={actionVisible}
          id={detailId}
          type={actionType}
          businessLevel={businessLevel}
          parentName={parentName}
          parentId={parentId}
          businessType={businessType}
          businessTypeEnum={businessTypeEnum}
          onCancel={() => setActionVisible(false)}
          refreshTable={refreshData}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={ObjectCode.ResourceType}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default ResourceCategoryList;
