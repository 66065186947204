/**
 * { id, name } 与 { label, value } 格式的相互转化
 */
import { ReactChild } from 'react';
import { LabeledValue } from 'antd/lib/select';

export type IdName = {
  id: number;
  name: ReactChild;
};

export type { LabeledValue };

export const name2label = (idname: IdName) => ({
  label: idname.name,
  value: idname.id,
});

export const label2name = (labeledValue: LabeledValue) => ({
  name: labeledValue.label,
  id: labeledValue.value,
});
