import { RecordListLayout, DataFormLayout, DataFormLayoutInfoBlock, DetailLayout } from 'layout';
import { Form } from 'antd';
import { fetchOutboundOrderOperateRecord } from 'src/api/ytt/inventory-domain/boundOrder';
import { useState, forwardRef, useImperativeHandle } from 'react';
import type {
  OutboundDetailType,
  OutboundMaterialRowType,
  OutboundTableRowType as TableRowType,
} from '../../type';
import _ from 'lodash';
import moment from 'moment';
import lookup from 'src/dict';
import { replaceSign } from 'src/utils/constants';
import { AvatarDisplay } from 'src/components/avatar/show';

interface Props {
  material: OutboundMaterialRowType;
  outboundOrder: OutboundDetailType;
}

const WriteOffRecord = forwardRef((props: Props, ref) => {
  const { material, outboundOrder } = props;
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<TableRowType[]>([]);
  const [auxAmountMaxItem, setAuxAmountMaxItem] = useState<any[]>([]);
  const fetchRecord = async (param: any) => {
    const res = await fetchOutboundOrderOperateRecord({
      outboundOrderId: outboundOrder.id,
      outboundOrderItemId: material.id,
      ...param,
    });

    if (res && res.code === 200) {
      const { list = [] } = res.data || {};
      const maxItem = _.maxBy(list, (o) => o?.amount?.auxAmountList?.length);

      setAuxAmountMaxItem(maxItem?.amount?.auxAmountList || []);
    }

    return res;
  };
  const baseInfo = {
    title: '冲销信息',
    items: [
      {
        label: '出库单编号',
        dataIndex: 'code',
        render: () => outboundOrder.code,
      },
      {
        label: '行号',
        dataIndex: 'lineNo',
        render: () => material.lineNo,
      },
      {
        label: '冲销物料',
        dataIndex: 'material',
        render: () => material?.material?.baseInfo?.name,
      },
      {
        label: '仓库',
        dataIndex: 'wareHouse',
        render: () => outboundOrder?.wareHouse?.name,
      },
      {
        label: '客户',
        dataIndex: 'customer',
        render: () => outboundOrder?.customer?.name,
      },
      {
        label: '出库进度',
        dataIndex: 'doneAmount',
        render: () => `${material.doneAmount} / ${material.planAmount} ${material.unit?.name}`,
      },
    ],
  };
  const recordInfo = {
    title: '记录列表',
    items: [
      {
        label: '',
        dataIndex: 'record',
        isFullLine: true,
        render: () => (
          <div style={{ height: 300 }}>
            <RecordListLayout<TableRowType>
              useIndex={false}
              useColConfig={false}
              batchMenu={[]}
              filterContaniner={false}
              useQuickFilter={false}
              useFilterWithUrl={false}
              selectedRowKeys={selectedRowKeys}
              onSelectedRowKeys={(selectKey: any, selectRows?: TableRowType[]) => {
                setSelectedRowKeys(selectKey);
                setSelectedRows(selectRows || []);
              }}
              columns={_.concat(
                [
                  {
                    title: '出库位置',
                    dataIndex: 'storageLocation',
                    width: 150,
                    render: (value: any) => {
                      const { storage = {} } = value || {};

                      return storage.name;
                    },
                  },
                  {
                    title: '批次',
                    dataIndex: 'bizKsyAttr',
                    width: 150,
                    render: (value: any) => value?.batchNo,
                  },
                  {
                    title: '质量状态',
                    dataIndex: 'qcStatus',
                    width: 120,
                    render: lookup('bound.qualityStatus'),
                  },
                  {
                    title: '出库数量',
                    dataIndex: 'amount',
                    width: 120,
                    render: (value: any) => {
                      const { amount = {} } = value || {};

                      return `${amount.amount} ${amount?.unit?.name || ''}`;
                    },
                  },
                ],
                auxAmountMaxItem.map((item: any, i: number): any => {
                  return {
                    title: `辅助数量${i + 1}`,
                    dataIndex: `amount${String(i)}`,
                    width: 120,
                    render: (value: any, record: any) => {
                      return record.amount?.auxAmountList[i]?.amount;
                    },
                  };
                }),
                [
                  {
                    title: '标识码',
                    width: 150,
                    dataIndex: 'qrCode',
                  },
                  {
                    title: '操作人',
                    width: 150,
                    dataIndex: 'operator',
                    render: (operator: any) => {
                      if (operator) {
                        return <AvatarDisplay {...operator} key={operator?.id} isShowTag isUser />;
                      }
                      return '-';
                    },
                  },
                  {
                    title: '操作时间',
                    width: 150,
                    dataIndex: 'operateTime',
                    render: (value: number) => moment(value).format('YYYY/MM/DD HH:mm'),
                  },
                  {
                    title: '备注',
                    width: 200,
                    dataIndex: 'remark',
                  },
                ],
              )}
              requestFn={fetchRecord}
              rowKey="id"
            />
          </div>
        ),
      },
    ],
  };

  useImperativeHandle(ref, () => ({
    getRecord: () => {
      return selectedRows;
    },
  }));

  return <DetailLayout title="" info={[baseInfo, recordInfo]} dataSource={{}} baseMenu={[]} />;
});

export default WriteOffRecord;
