import React, { ReactElement, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Badge, message, Select } from 'antd';
import { ColumnProps } from 'antd/es/table';
import {
  BlIcon,
  SearchSelect,
  showErrorListMessage,
  SingleOperationLogComponent,
  TagList,
  Tooltip,
} from 'src/components';
import { BL_SELECTED_ALL, RecordListLayout } from 'src/layout';
import { fieldTypeList, gcArray } from 'src/utils';
import { formateDateRangeForQuery2 } from 'src/utils/formatters/dateTime';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import lookup, { appDict, appEnum } from 'src/dict';
import { fetchBomList, fetchBomBulkUpdateStatus } from 'src/api/ytt/med-domain/bom';
import { getMaterialAttrs } from 'src/entity/material';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
//
import { toCreateBom, toEditBom, toDetailBom, toCopyBom } from '../navigation';
import { BomResponse } from '../index.type';
import { BomActiveColorMap } from '../constants';
import MultiDetail from '../multiDetail';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { useDispatch } from 'react-redux';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import BcBlCascader from 'src/components/Cascader';
import { intersectionValueOfId } from 'src/utils/array';
import MaterialAttributeCascader, {
  formatMaterialAttributeToQuery,
} from 'src/page/knowledgeManagement/materialModeling/material/share/Cascader/BcMultiCascader';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';

const BomList = (props: { history: any }) => {
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [multiDetailId, setMultiDetailId] = useState<number>();
  const [visibleLogId, setVisibleLog] = useState<number>();
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const { history } = props;

  const formatDataToDisplay = (data: any) => {
    const { active, createdAt, updatedAt, ...resData } = _.cloneDeep(data);

    return {
      ...resData,
      active: _.toString(active),
      createdAt: !gcArray.isEmpty(createdAt) ? createdAt : undefined,
      updatedAt: !gcArray.isEmpty(updatedAt) ? updatedAt : undefined,
    };
  };

  const formatDataToQuery = (data: any) => {
    const {
      quickSearch,
      createdAt = [],
      updatedAt = [],
      ownedDepartmentId = [],
      manufactureDepartmentId = [],
      operatorIds,
      creatorIds,
      materialCategoryIds = [],
      materialAttributes = [],
      materialCodeIds,
      materialNameIds,
      ...resData
    } = _.cloneDeep(data);

    const params = {
      ...resData,
      materialIds: intersectionValueOfId(materialCodeIds, materialNameIds),
      materialCategoryIds: !_.isEmpty(materialCategoryIds) ? materialCategoryIds : undefined,
      materialAttributes: formatMaterialAttributeToQuery(materialAttributes),
      materialLike: quickSearch,
      creatorIds: !_.isEmpty(creatorIds) ? _.map(creatorIds, 'value') : undefined,
      operatorIds: !_.isEmpty(operatorIds) ? _.map(operatorIds, 'value') : undefined,
      ...formateDateRangeForQuery2('createdAtFrom', 'createdAtTo', createdAt),
      ...formateDateRangeForQuery2('updatedAtFrom', 'updatedAtTo', updatedAt),
      ownedDepartmentIds: !_.isEmpty(ownedDepartmentId)
        ? _.map(ownedDepartmentId, 'value')
        : undefined,
      manufactureDepartmentIds: !_.isEmpty(manufactureDepartmentId)
        ? _.map(manufactureDepartmentId, 'value')
        : undefined,
    };

    dispatch.excelImport.updateBusinessData({ businessData: params });
    return params;
  };

  const formatDataToFormDisplay = (data: any) => {
    const { createdAt = [], updatedAt = [], ...resData } = _.cloneDeep(data);

    return {
      ...resData,
      createdAt: !_.isEmpty(createdAt) && _.map(createdAt, (d) => moment(d)),
      updatedAt: !_.isEmpty(updatedAt) && _.map(updatedAt, (d) => moment(d)),
    };
  };

  const refreshData = () => {
    setRefreshMarker(Math.random());
  };

  /**
   * 更新BOM状态
   */
  const updateBomsStatus = (currentAction: number, ids: React.Key[]) => {
    const toActive =
      currentAction === appEnum.Common.UsageStatus.enabled
        ? appEnum.Common.Turn.off
        : appEnum.Common.Turn.on;

    if (_.isEmpty(ids)) return Promise.reject();

    return fetchBomBulkUpdateStatus({
      active: toActive,
      ids: _.map(ids, _.toNumber),
    }).then((res) => {
      const { failCount = 0, successCount = 0, failDetails = [] } = res?.data ?? {};

      if (failCount) {
        showErrorListMessage({
          failCount,
          successCount,
          title: `${lookup('turn', toActive)}出现失败`,
          data: _.map(failDetails, (item) => ({ ...item, key: item.id })),
          columns: [
            { title: '物料编号', dataIndex: ['material', 'baseInfo', 'code'] },
            { title: '物料名称', dataIndex: ['material', 'baseInfo', 'name'] },
            {
              title: '版本号',
              dataIndex: 'version',
              render: (text: string, record: any) => (
                <a onClick={() => window.open(toDetailBom(record.id))}>
                  <Tooltip text={text} width={150} />
                </a>
              ),
            },
            { title: '失败原因', dataIndex: 'failReason', width: 200 },
            { title: '', dataIndex: 'key', width: 1, render: () => null },
          ],
        });
      } else {
        message.success(`${lookup('turn', toActive)}成功！`);
      }

      return res;
    });
  };

  /**
   * 批量更新BOM状态
   * @param currentAction
   * @returns
   */
  const batchUpdateBomsStatus = (currentAction: number) => {
    if (selectedRowKeys[0] !== BL_SELECTED_ALL) {
      return new Promise((reslove, reject) => {
        updateBomsStatus(currentAction, selectedRowKeys)
          .then((res) => {
            const { successCount = 0 } = res?.data ?? {};

            if (successCount > 0) {
              reslove(res);
            } else {
              reject();
            }
          })
          .catch(reject);
      });
    }

    return Promise.reject();
  };

  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      auth: authDict.bom_export,
      icon: <BlIcon type="icondaochu" />,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.bom,
        });
      },
    },
    {
      title: '新建物料清单',
      auth: authDict.bom_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        history.push(toCreateBom());
      },
      items: [
        {
          title: '导入',
          auth: authDict.bom_import,
          showExport: true,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.bom,
            });
          },
        },
      ],
    },
  ];

  const batchMenu = [
    {
      title: lookup('changeStatusAction', appEnum.Common.UsageStatus.disabled),
      key: appEnum.Common.UsageStatus.disabled,
      auth: authDict.bom_enable_disable,
      onClick: () => {
        return batchUpdateBomsStatus(appEnum.Common.UsageStatus.disabled);
      },
    },
    {
      title: lookup('changeStatusAction', appEnum.Common.UsageStatus.enabled),
      key: appEnum.Common.UsageStatus.enabled,
      auth: authDict.bom_enable_disable,
      onClick: () => {
        return batchUpdateBomsStatus(appEnum.Common.UsageStatus.enabled);
      },
    },
  ];

  const getOperationList = (record: any) => {
    const { active, id } = record;

    return [
      {
        title: lookup('crud', appEnum.Common.CRUD.view),
        auth: authDict.bom_detail,
        onClick: () => {
          history.push(toDetailBom(record.id));
        },
      },
      {
        title: lookup('crud', appEnum.Common.CRUD.edit),
        auth: authDict.bom_edit,
        disabled: record?.active !== appEnum.Common.UsageStatus.disabled,
        onClick: () => history.push(toEditBom(record.id)),
      },
      {
        title: lookup('changeStatusAction', active),
        auth: authDict.bom_enable_disable,
        onClick: () => {
          updateBomsStatus(active, [id]).then((res) => {
            const { successCount = 0 } = res?.data ?? {};

            if (successCount > 0) refreshData();
          });
        },
      },
      {
        title: lookup('crud', appEnum.Common.CRUD.copy),
        auth: authDict.bom_add,
        onClick: () => history.push(toCopyBom(id)),
      },
      {
        title: '多层展开',
        auth: authDict.bom_multi_lev_detail,
        onClick: () => setMultiDetailId(id),
      },
      {
        title: '操作记录',
        auth: authDict.bom_operrecord,
        onClick: () => {
          setVisibleLog(id);
        },
      },
    ];
  };

  const columns: (ColumnProps<BomResponse> & {
    filterIndex?: string;
    type?: number;
    isFilter?: boolean;
    selectProps?: any;
    renderItem?: ReactElement;
    dateFormat?: any;
  })[] = [
    {
      title: '父项物料编号',
      dataIndex: ['material', 'baseInfo', 'code'],
      filterIndex: 'materialCodeIds',
      fixed: 'left',
      isFilter: true,
      renderItem: <SearchSelect fetchType="material" labelPath="code" mode="multiple" />,
      width: 150,
    },
    {
      title: '父项物料名称',
      dataIndex: ['material', 'baseInfo', 'name'],
      filterIndex: 'materialNameIds',
      isFilter: true,
      renderItem: <SearchSelect fetchType="material" labelPath="name" mode="multiple" />,
      width: 150,
    },
    {
      title: '父项物料属性',
      dataIndex: ['material', 'attribute'],
      filterIndex: 'materialAttributes',
      renderItem: <MaterialAttributeCascader />,
      isFilter: true,
      width: 150,
      render: (_text: any, record: any) => (
        <TagList dataSource={getMaterialAttrs(record.material)} />
      ),
    },
    {
      title: '父项物料规格',
      dataIndex: ['material', 'baseInfo', 'specification'],
      width: 150,
    },
    {
      title: '父项物料分类',
      dataIndex: ['material', 'category', 'name'],
      filterIndex: 'materialCategoryIds',
      renderItem: (
        <BcBlCascader
          fetchType={'materialCategory'}
          nameLabel={'物料分类'}
          popupPlacement={'bottomRight'}
        />
      ),
      isFilter: true,
      width: 150,
    },
    {
      title: '虚拟件',
      dataIndex: 'virtual',
      isFilter: true,
      renderItem: <Select options={appDict.common.yn} allowClear />,
      width: 150,
      render: (text) => lookup('yn', text),
    },
    {
      title: '单位',
      dataIndex: 'unitName',
      width: 150,
    },
    {
      title: '版本号',
      dataIndex: 'version',
      width: 150,
      sorter: true,
      type: fieldTypeList.text,
      isFilter: true,
    },
    {
      title: '状态',
      dataIndex: 'active',
      sorter: true,
      type: fieldTypeList.select,
      isFilter: true,
      selectProps: { options: appDict.common.usageStatus },
      width: 150,
      render: (text) => (
        <Badge color={BomActiveColorMap.get(text)} text={lookup('common.usageStatus', text)} />
      ),
    },
    {
      title: '成品率%',
      dataIndex: 'productRate',
      width: 150,
    },
    {
      title: '报工工序号',
      dataIndex: ['workReportProcessRouteNodeSimpleVO', 'processNum'],
      width: 150,
    },
    {
      title: '报工控件编号',
      dataIndex: ['workReportSopControlDTO', 'code'],
      width: 150,
    },
    {
      title: '默认版本',
      dataIndex: 'defaultVersion',
      sorter: true,
      isFilter: true,
      renderItem: <Select options={appDict.common.yn} allowClear />,
      width: 150,
      render: (text) => lookup('yn', text),
    },
    {
      title: '版本说明',
      dataIndex: 'versionDescription',
      width: 150,
    },
    {
      title: '所属部门',
      dataIndex: 'ownedDepartmentName',
      filterIndex: 'ownedDepartmentId',
      isFilter: true,
      renderItem: <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple />,
      width: 150,
    },
    {
      title: '生产部门',
      dataIndex: 'manufactureDepartmentName',
      filterIndex: 'manufactureDepartmentId',
      isFilter: true,
      renderItem: <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple />,
      width: 150,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      filterIndex: 'creatorIds',
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple isSelectUser />
      ),
      isFilter: true,
      width: 150,
      render: (text) => text && <AvatarDisplay {...text} isShowTag={false} isUser />,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      sorter: true,
      type: fieldTypeList.date,
      isFilter: true,
      width: 180,
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      filterIndex: 'operatorIds',
      isFilter: true,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple isSelectUser />
      ),
      width: 150,
      render: (text) => text && <AvatarDisplay {...text} isShowTag={false} isUser />,
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      sorter: true,
      type: fieldTypeList.date,
      isFilter: true,
      width: 180,
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  const getFilterList = () => {
    return columns
      .filter((i) => i.isFilter)
      .map((column: any) => {
        const filter: any = {
          label: column.title,
          name: column.filterIndex || column.dataIndex,
          type: column.type,
          rules: column.rules,
          renderItem: column.renderItem,
          dateFormat: column?.dateFormat,
        };

        if (column.type === fieldTypeList.select || column.type === fieldTypeList.multiSelect) {
          filter.selectProps = column.selectProps;
        }
        return filter;
      });
  };

  return (
    <>
      <RecordListLayout<BomResponse>
        configcacheKey="bomRecordList"
        filterList={getFilterList()}
        requestFn={(params) => {
          return fetchBomList(params, { legacy: true });
        }}
        mainMenu={mainMenu}
        batchMenu={batchMenu}
        placeholder={'请输入父项物料编号或名称'} // 快速搜索input placeholder
        formatDataToQuery={formatDataToQuery} // 转换搜索条件进行查询（快速查询字段："quickSearch",当前页："page",分页大小："size"）
        formatDataToDisplay={formatDataToDisplay} // 转换搜索条件进行展示
        formatDataToFormDisplay={formatDataToFormDisplay}
        columns={columns} // table columns
        selectedRowKeys={selectedRowKeys}
        refreshMarker={refreshMarker}
        rowKey="id"
        onSelectedRowKeys={(selectKey: React.SetStateAction<React.Key[]>) => {
          setSelectedRowKeys(selectKey);
        }}
        getOperationList={getOperationList}
        userAuth={getAuthFromLocalStorage()}
      />
      <MultiDetail bomId={multiDetailId} onCancel={() => setMultiDetailId(undefined)} />
      {visibleLogId && (
        <SingleOperationLogComponent
          visible={!!visibleLogId}
          instanceId={visibleLogId}
          objectCode={'BOM'}
          closeDetail={() => {
            setVisibleLog(undefined);
          }}
        />
      )}
    </>
  );
};

export default BomList;
