import { useState } from 'react';
import { RecordListLayout } from 'layout';
import { fetchDeliveryScheduleNoteList } from 'src/api/ytt/order-domain/deliveryScheduleNote';
import { dataColumnsForOrdersList, ordersFilterList } from './columns';
import authDict from 'src/utils/auth';
import _ from 'lodash';
import { gcObject } from 'utils';
import { useDispatch } from 'react-redux';
import lookup from 'src/dict';
import { Modal } from 'antd';
import { CRUD } from 'src/dict/common';
import { DeliveryScheduleNoteListItem } from '../interface';
import { useHistory } from 'react-router-dom';
import { toEdit, toDetail, toCreate } from '../navigate';
import { BlIcon, LinkTooltip, SingleOperationLogComponent } from 'src/components';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { handleDistribute, handleClosed, endOrder } from '../operate';
import { DeliveryScheduleNoteExecStatus } from 'src/dict/purchase';
import { formatDateTimeRangeToUnix } from 'src/utils/formatters/dateTime';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

const DeliveryScheduleNoteOrdersList = () => {
  const history = useHistory();
  /** 用来打开弹框 */
  const openModal = useOpenExImportModal();
  // 选中行的key，用以批量操作
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectedRow, setSelectRow] = useState<any[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [currentId, setCurrentId] = useState<number>();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const refresh = () => {
    setRefreshMarker(Date.now());
  };
  const dispatch = useDispatch();
  const mainMenu = [
    {
      title: '导出',
      auth: authDict.deliveryScheduleNote_export,
      icon: <BlIcon type="icondaochu" />,
      showExport: true,
      onClick: () =>
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.deliveryScheduleNote,
        }),
    },
    {
      title: '新建交货计划单',
      auth: authDict.deliveryScheduleNote_add,
      showExport: true,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        history.push(toCreate());
      },
      items: [
        {
          title: '导入',
          auth: authDict.deliveryScheduleNote_import,
          showExport: true,
          onClick: () =>
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.deliveryScheduleNote,
            }),
        },
      ],
    },
  ];
  const dataColumns = [
    {
      title: '编号',
      dataIndex: 'code',
      width: 150,
      sorter: true,
      render: (code: string, record: DeliveryScheduleNoteListItem, index: number, config: any) => {
        return (
          <LinkTooltip
            text={code}
            to={toDetail(record.id as number)}
            width={config.contentWidth}
            auth={authDict.deliveryScheduleNote_detail}
          />
        );
      },
    },
    {
      title: '来源',
      dataIndex: 'source',
      width: 150,
      render: lookup('purchase.deliveryScheduleNoteSource'),
    },
    {
      title: '所有人',
      dataIndex: ['owner', 'name'],
      width: 150,
    },
    ...dataColumnsForOrdersList,
  ];
  const batchMenu = [
    {
      title: '下发',
      auth: authDict.deliveryScheduleNote_dispatch,
      onClick: (onSuccess: any, onFail: any) => {
        handleDistribute(selectedRow, selectedRowKeys, onSuccess, onFail, refresh);
      },
    },
    {
      title: '结束',
      auth: authDict.deliveryScheduleNote_finish,
      onClick: (onSuccess: any, onFail: any) => {
        endOrder(
          selectedRow,
          selectedRowKeys,
          _.map(selectedRow, 'code'),
          'multiple',
          () => {
            onSuccess?.();
            refresh();
          },
          onFail,
        );
      },
    },
    {
      title: '关闭',
      auth: authDict.deliveryScheduleNote_close,
      onClick: (onSuccess: any, onFail: any) => {
        Modal.confirm({
          title: '关闭交货计划单',
          content: '关闭交货计划单后不能再进行任何操作，确认关闭吗？',
          okText: '关闭',
          cancelText: '暂不关闭',
          onOk: async () => {
            await handleClosed(selectedRow, selectedRowKeys, onSuccess, onFail, refresh);
          },
          onCancel: async () => {
            await onFail();
          },
        });
      },
    },
  ];
  const getOperationList = (record: DeliveryScheduleNoteListItem) => {
    return _.compact([
      {
        title: lookup('crud', CRUD.view),
        auth: authDict.deliveryScheduleNote_detail,
        onClick: () => {
          history.push(toDetail(record.id!));
        },
      },
      {
        title: lookup('crud', CRUD.edit),
        auth: authDict.deliveryScheduleNote_edit,
        disabled:
          record.execStatus === DeliveryScheduleNoteExecStatus.finished ||
          record.execStatus === DeliveryScheduleNoteExecStatus.closed,
        onClick: () => {
          history.push(toEdit(record.id as number));
        },
      },
      {
        title: lookup('crud', CRUD.issued),
        auth: authDict.deliveryScheduleNote_dispatch,
        disabled:
          record.execStatus !== DeliveryScheduleNoteExecStatus.created &&
          record.execStatus !== DeliveryScheduleNoteExecStatus.rejected,
        onClick: () => {
          handleDistribute([record], [record.id!], refresh);
        },
      },
      {
        title: '结束',
        auth: authDict.deliveryScheduleNote_finish,
        disabled:
          record.execStatus !== DeliveryScheduleNoteExecStatus.undetermined &&
          record.execStatus !== DeliveryScheduleNoteExecStatus.executing,
        onClick: () => {
          endOrder([record], [record.id!], [record.code!], 'single', refresh);
        },
      },
      {
        title: '关闭',
        auth: authDict.deliveryScheduleNote_close,
        disabled:
          record.execStatus !== DeliveryScheduleNoteExecStatus.created &&
          record.execStatus !== DeliveryScheduleNoteExecStatus.rejected,
        onClick: () => {
          Modal.confirm({
            title: '关闭交货计划单',
            content: `关闭交货计划单后不能再进行任何操作，确认关闭<${record.code}>吗？`,
            okText: '关闭',
            cancelText: '暂不关闭',
            onOk: async () => {
              await handleClosed([record], [record.id!], refresh);
            },
          });
        },
      },
      {
        title: '操作记录',
        auth: authDict.deliveryScheduleNote_operrecord,
        onClick: () => {
          setCurrentId(record.id);
          setVisibleLog(true);
        },
      },
    ]);
  };
  const formatDataToQuery = (params: any) => {
    const { createdAt, updatedAt, supplier, creator, operator, owner, ...rest } = params || {};
    const options = {
      ...formatDateTimeRangeToUnix('createdAtFrom', 'createdAtTo', createdAt),
      ...formatDateTimeRangeToUnix('updatedAtFrom', 'updatedAtTo', updatedAt),
      creatorIds: _.isEmpty(creator) ? null : _.map(creator, 'value'),
      operatorIds: _.isEmpty(operator) ? null : _.map(operator, 'value'),
      ownerIds: _.isEmpty(owner) ? null : _.map(owner, 'value'),
      supplierIds: supplier?.map((item: any) => item.key),
      ...rest,
    };

    dispatch.excelImport.updateBusinessData({
      businessData: gcObject.filterEmptyProperty(options),
    });

    return gcObject.filterEmptyProperty(options);
  };
  const formatDataToFormDisplay = (data: any) => {
    const { createdAt = [], updatedAt = [], ...resData } = _.cloneDeep(data);

    return {
      ...resData,
      createdAt: momentData(createdAt),
      updatedAt: momentData(updatedAt),
    };
  };

  return (
    <>
      <RecordListLayout
        requestFn={fetchDeliveryScheduleNoteList}
        columns={dataColumns}
        rowKey="id"
        batchMenu={batchMenu}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectedKeys: any, row) => {
          setSelectedRowKeys(selectedKeys);
          if (row) {
            setSelectRow(row);
          }
        }}
        placeholder="请输入编号、供应商"
        useIndex={false}
        filterList={ordersFilterList}
        getOperationList={getOperationList}
        mainMenu={mainMenu}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        refreshMarker={refreshMarker}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={currentId as number}
          objectCode={OBJECT_OF_CODE.deliveryScheduleNote}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DeliveryScheduleNoteOrdersList;
