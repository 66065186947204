import { useEffect, useState } from 'react';
import { FetchCustomerMaterialRegAuditListMaterialInfoRequest } from 'src/api/ytt/supply-chain-domain/customer_material_reg_audit';
import { BlTable } from 'src/components';
import { replaceSign } from 'src/utils/constants';
import { CustomerMaterialRegAuditListMaterialInfoGetItemProps } from '../interface';

interface TableProps {
  requestFn: (parmas?: FetchCustomerMaterialRegAuditListMaterialInfoRequest) => Promise<any>;
}

const ViewMaterialsRegInfoTable = (props: TableProps) => {
  const { requestFn } = props;
  const [dataSource, setdataSource] = useState<
    Required<CustomerMaterialRegAuditListMaterialInfoGetItemProps>[]
  >([]);
  const [loading, setloading] = useState(true);

  /**
   * fetch 接口
   */
  const handleFetch = async () => {
    const { data } = await requestFn();

    setdataSource(data?.list);
    setloading(false);
  };

  /**
   * Columns
   */
  const dataColumns = [
    {
      title: '客户物料编号',
      dataIndex: 'cusMaterialCode',
      width: 150,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '客户物料名称',
      dataIndex: 'cusMaterialName',
      width: 150,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '客户物料数量',
      dataIndex: ['cusAmount', 'amountDisplay'],
      width: 150,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '客户物料单位',
      dataIndex: ['cusAmount', 'unitName'],
      width: 150,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
  ];

  /**
   * 初始化
   */
  useEffect(() => {
    if (typeof requestFn === 'function') {
      handleFetch();
    }
  }, []);

  return (
    <>
      <BlTable
        columns={dataColumns}
        dataSource={dataSource}
        pagination={false}
        rowKey={(record) => record?.id}
        style={{ width: '100%' }}
        scroll={{ y: 600 }}
        loading={loading}
      />
    </>
  );
};

export default ViewMaterialsRegInfoTable;
