import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RecordListLayout } from 'layout';
import { BlIcon, LinkTooltip, SearchSelect, SingleOperationLogComponent } from 'src/components';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { replaceSign } from 'src/utils/constants';
import { UsageStatus } from 'src/dict/common';
import { Badge, Form, message as Message, Modal } from 'antd';
import { AvatarDisplay } from 'src/components/avatar/show';
import { Creator } from 'src/page/sales/salesManagement/salesOrder/interface';
import _Time from 'src/utils/dataTypes/time';
import { toCreate, toDetail, toEdit } from './navigation';
import {
  fetchApprovalPlanDelete,
  fetchApprovalPlanDisable,
  fetchApprovalPlanEnable,
  fetchApprovalPlanList,
} from 'src/api/ytt/metadata-domain/approvalProcessScheme';
import { FilterItem } from 'src/layout/recordList';
import { fieldTypeList } from 'src/utils';
import { usageStatus } from 'src/dict/common/mappings';
import { batchRequest } from './utils';
import { ApprovalProcessColType } from './interface';
import _Array from 'src/utils/dataTypes/array';
import _String from 'src/utils/dataTypes/string';
import { map } from 'lodash';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import lookup from 'src/dict';
import { typeOptions } from 'src/dict/processManagement/mappings';

export default function ApprovalProcessList() {
  const history = useHistory();
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新页面
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [approvalProcessId, setApprovalProcessId] = useState<number>(0);
  const [delForm] = Form.useForm();

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  const handleFinish = async (id: number) => {
    try {
      // const { delReason } = await delForm?.validateFields();

      const { message, code } = await fetchApprovalPlanDelete({ id });

      if (code === 200) {
        Message.success('删除成功');
        refresh();
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  const listCol = [
    {
      title: '编号',
      dataIndex: 'code',
      sorter: true,
      width: 150,
      render: (code: string, record: ApprovalProcessColType, index: number, config: any) => (
        <LinkTooltip
          to={toDetail(record?.id)}
          text={code}
          width={config.contentWidth}
          auth={authDict.approvalScheme_detail}
        />
      ),
    },
    {
      title: '名称',
      dataIndex: 'name',
      width: 150,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '类型',
      dataIndex: 'type',
      width: 150,
      render: (type: number) => lookup('processManagement.typeOptions', type),
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 150,
      render: (status: number) => {
        const useStatusMap = new Map([
          [UsageStatus.enabled, <Badge text="启用中" status="success" />],
          [UsageStatus.disabled, <Badge text="停用中" status="error" />],
        ]);

        return useStatusMap.get(status);
      },
    },
    {
      title: '审批对象',
      dataIndex: 'approvalObjectName',
      width: 150,
      render: (approvalObjectName: string) => approvalObjectName ?? replaceSign,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 150,
      render: (creator: Creator) => {
        return (
          <AvatarDisplay
            id={creator?.id}
            name={creator?.name}
            avatarUrl={creator?.avatarUrl}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 180,
      sorter: true,
      render: (createdAt: Date) => {
        if (!createdAt) return replaceSign;
        return _Time.format(createdAt);
      },
    },
    {
      title: '编辑人',
      dataIndex: 'operator',
      width: 150,
      defaultColConfig: {
        display: false,
      },
      render: (operator: Creator) => {
        return (
          <AvatarDisplay
            id={operator?.id}
            name={operator?.name}
            avatarUrl={operator?.avatarUrl}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '编辑时间',
      dataIndex: 'updatedAt',
      width: 180,
      sorter: true,
      defaultColConfig: {
        display: false,
      },
      render: (updatedAt: Date, record: any) => {
        if (!updatedAt) return _Time.format(record?.createdAt) ?? replaceSign; // 没有更新,默认为创建时间
        return _Time.format(updatedAt);
      },
    },
  ];

  const mainMenu = [
    {
      title: '新建方案',
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.approvalScheme_add,
      onClick: () => {
        history.push(toCreate());
      },
      items: [],
    },
  ];

  const getOperationList = (record: ApprovalProcessColType) => {
    return [
      {
        title: '查看',
        auth: authDict.approvalScheme_detail,
        onClick: () => {
          history.push(toDetail(record?.id));
        },
      },
      {
        title: '编辑',
        auth: authDict.approvalScheme_edit,
        disabled: Boolean(record?.status),
        onClick: () => history.push(toEdit(record?.id)),
      },
      {
        title: record?.status ? '停用' : '启用',
        auth: authDict.approvalScheme_enable_disable,
        onClick: async () => {
          const storeApi = record?.status ? fetchApprovalPlanDisable : fetchApprovalPlanEnable;
          const storeDisplay = record?.status ? '停用' : '启用';

          if (!record?.status && !record?.hasNode) {
            return Message.warning('至少添加一个审批节点');
          }

          batchRequest(storeApi, record?.id, storeDisplay, refresh);
        },
      },
      {
        title: '删除',
        auth: authDict.approvalScheme_remove,
        disabled: Boolean(record?.status),
        onClick: () => {
          setApprovalProcessId(record?.id);
          Modal.confirm({
            okText: '确认',
            title: '确认要删除当前审批流程方案吗？',
            icon: <ExclamationCircleOutlined />,
            content: '删除后不影响当前正在执行的审批业务；确认要删除吗？',
            onOk() {
              handleFinish(record?.id);
            },
          });
        },
      },
      {
        title: '操作记录',
        auth: authDict.approvalScheme_operrecord,
        onClick: () => {
          setApprovalProcessId(record.id);
          setVisibleLog(true);
        },
      },
    ];
  };

  const filterList: FilterItem[] = [
    {
      label: '编号',
      name: 'code',
      type: fieldTypeList.text,
    },
    {
      label: '名称',
      name: 'name',
      type: fieldTypeList.text,
    },
    {
      label: '类型',
      name: 'type',
      type: fieldTypeList?.multiSelect,
      selectProps: {
        options: typeOptions,
      },
    },
    {
      label: '状态',
      name: 'status',
      type: fieldTypeList?.multiSelect,
      selectProps: {
        options: usageStatus,
      },
    },
    {
      label: '审批对象',
      name: 'approvalCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'approvalObject'}
          allowClear
          placeholder={'请选择审批对象'}
          mode="multiple"
        />
      ),
    },
    {
      label: '创建人',
      name: 'creatorId',
      type: fieldTypeList.text,
      renderItem: <SearchSelect labelInValue fetchType={'user'} mode="multiple" />,
    },
    {
      label: '创建时间',
      name: 'createdAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '编辑人',
      name: 'operatorId',
      type: fieldTypeList.text,
      renderItem: <SearchSelect labelInValue fetchType={'user'} mode="multiple" />,
    },
    {
      label: '编辑时间',
      name: 'updatedAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
  ];

  const formatDataToQuery = (params: any) => {
    const {
      quickSearch,
      code,
      name,
      status,
      type,
      approvalCode,
      creatorId,
      createdAt,
      operatorId,
      updatedAt,
      sorter,
      ...rest
    } = params;

    const relParams = { ...rest };

    if (!_String.isEmpty(quickSearch)) {
      relParams.quickSearch = quickSearch;
    }

    if (!_String.isEmpty(name)) {
      relParams.name = name;
    }

    if (!_String.isEmpty(code)) {
      relParams.code = code;
    }

    if (!_Array.isEmpty(type)) {
      relParams.type = type;
    }

    if (!_Array.isEmpty(status)) {
      relParams.status = status;
    }

    if (!_Array.isEmpty(approvalCode)) {
      relParams.approvalCode = map(approvalCode, (e: any) => JSON.parse(e?.value)?.objectCode);
    }

    if (createdAt) {
      relParams.createdAtBegin = Number(_Time.formatToUnix(createdAt[0]));
      relParams.createdAtEnd = Number(_Time.formatToUnix(createdAt[1]));
    }
    if (updatedAt) {
      relParams.updatedAtBegin = Number(_Time.formatToUnix(updatedAt[0]));
      relParams.updatedAtEnd = Number(_Time.formatToUnix(updatedAt[1]));
    }

    if (!_Array.isEmpty(creatorId)) {
      relParams.creatorId = map(creatorId, 'value');
    }
    if (!_Array.isEmpty(operatorId)) {
      relParams.operatorId = map(operatorId, 'value');
    }

    if (sorter) {
      relParams.sorter = map(sorter, (node: any) => {
        return {
          order: node?.order,
          field: node?.field,
        };
      });
    }

    return relParams;
  };

  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (data: any) => {
    const { createdAt, updatedAt } = data;

    const retData = { ...data };

    if (createdAt) {
      retData.createdAt = momentData(createdAt);
    }
    if (updatedAt) {
      retData.updatedAt = momentData(updatedAt);
    }

    return retData;
  };

  return (
    <>
      <RecordListLayout<ApprovalProcessColType>
        userAuth={getAuthFromLocalStorage()}
        columns={listCol}
        rowKey={(record: ApprovalProcessColType) => record?.id}
        placeholder={'请输入编号、名称'}
        configcacheKey="approvalProcessList"
        mainMenu={mainMenu}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        filterList={filterList}
        requestFn={fetchApprovalPlanList}
        getOperationList={getOperationList}
        refreshMarker={refreshMarker}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          closeDetail={() => {
            setVisibleLog(false);
          }}
          visible={visibleLog}
          instanceId={approvalProcessId}
          objectCode={'ApprovalScheme'}
        />
      )}
    </>
  );
}
