import _ from 'lodash';
import { fieldTypeList } from 'src/utils';

interface AutoGenerated {
  id: number;
  relatedFieldId: number;
  createdAt: string;
  creatorId: number;
  isDeleted: number;
  operatorId: number;
  orgId: number;
  updatedAt: string;
}
export interface GetFetchChoiceValue extends AutoGenerated {
  choiceValue: string;
  sequence: number;
  isDefault: number;
  isUsed: number;
}

export interface BackToBakendChoiceValue extends Partial<AutoGenerated> {
  choiceValue: string;
  sequence: number;
  isDefault: number;
  isUsed: number;
}

export interface FormChoiseValue {
  choiceValue: string;
  index: number;
  key: string;
  isUsed?: boolean;
  fieldKey: string;
}

export interface DataForForm {
  singleOptionList?: FormChoiseValue[];
  mutipleOptionList?: FormChoiseValue[];
  defaultOption: string | undefined | string[];
}

// create，edit处理的第一步： 提交表单时，处理组装数据给后端
export const handleAssemblyChoiceValueList = (
  value: { choiceValue: string; isUsed?: boolean; fieldKey: string }[],
  dafaultOption: string[] | undefined[] | string[][], // 单选 | 单选没选 | 多选
  type: number,
  fieldId?: number,
): BackToBakendChoiceValue[] => {
  const newList = value.map((item, index) => {
    let isExistUsed;

    if (item?.isUsed === false) {
      isExistUsed = Number(item.isUsed);
    } else {
      // 创建的时候没有 isUsed,默认为true
      isExistUsed = 1;
    }
    const obj = {
      choiceValue: item.choiceValue,
      sequence: index,
      isUsed: isExistUsed,
      isDefault: 0,
    } as any;

    if (item.fieldKey) {
      obj.id = Number(item.fieldKey);
    }
    if (fieldId) {
      obj.relatedFieldId = fieldId;
    }
    // 处理 默认值
    if (type === fieldTypeList.select && (dafaultOption as string[])?.includes(item.choiceValue)) {
      obj.isDefault = 1;
    }
    if (type === fieldTypeList.multiSelect && dafaultOption[0]?.includes(item.choiceValue)) {
      obj.isDefault = 1;
    }

    return obj;
  });

  return newList;
};

// edit 渲染fetch的数据
export const handleDealWithData = (
  data: GetFetchChoiceValue[],
  name: 'singleOptionList' | 'mutipleOptionList',
  type: number,
): DataForForm => {
  //
  let defaultOptionList: string | undefined | string[] = []; // 单选 | 单选没选 | 多选
  // 处理选项列表
  const choiceValueList = data?.map((item) => {
    const obj: FormChoiseValue = { choiceValue: '', index: 0, key: '', fieldKey: '' };

    if (item.isDefault === 1) {
      (defaultOptionList as string[]).push(item.choiceValue);
    }
    obj.choiceValue = item.choiceValue;
    obj.index = item.sequence;
    obj.isUsed = Boolean(item.isUsed);
    obj.key = String(item.id);
    obj.fieldKey = String(item.id);

    return obj;
  });

  // 处理 默认值
  if (type === fieldTypeList.select && defaultOptionList.length === 0) {
    defaultOptionList = undefined;
  } else if (type === fieldTypeList.select && defaultOptionList.length > 0) {
    defaultOptionList = defaultOptionList[0];
  }

  return {
    [name]: choiceValueList,
    defaultOption: defaultOptionList,
  };
};

// edit提交表单时,从原始数据中找到有id的，组装到data,提交给后端
export const handleAddIdChoiceValueList = (
  originData: GetFetchChoiceValue[],
  assemblyData: BackToBakendChoiceValue[],
): BackToBakendChoiceValue[] => {
  originData.map((item) => {
    assemblyData.map((el) => {
      if (el.id === item.id) {
        el.relatedFieldId = item.relatedFieldId;
      }

      return el;
    });

    return item;
  });

  return assemblyData;
};
