import { BlTable } from '@blacklake-web/component';
import { Button, Form, FormInstance, Input, Select } from 'antd';

import { ColumnProps } from 'antd/es/table';
import { RuleObject } from 'antd/lib/form';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FetchQcTaskDetailForEditResponse } from 'src/api/ytt/quality-domain/qc_task';
import lookup from 'src/dict';
import { inspectionResultOptions } from 'src/dict/quality/mappings';
import { NUMBER_SCALE } from 'src/page/quality/models/constants/qcConfig';
import { QcTaskEntity } from 'src/page/quality/models/entities/qcTask';
import { fractionLengthCheck, negativeNumberCheck } from 'src/utils/formValidators/customValidator';
import styles from 'src/page/quality/qcTask/styles.module.scss';

type Qctask = FetchQcTaskDetailForEditResponse['data'];
interface MaterialInfoTabProps {
  form: FormInstance;
  taskData?: Qctask;
  qcTask?: QcTaskEntity;
  canEditMaterial?: boolean;
  dataSource?: any[];
}

const MaterialInfo = (props: MaterialInfoTabProps) => {
  const { form, taskData, qcTask, canEditMaterial, dataSource } = props;
  const [, setQualifiedCountRecord] = useState<number>();
  const [, setDefectCountRecord] = useState<number>();
  const [, setPendingCountRecord] = useState<number>();
  const [, setQualifiedConcessionCountRecord] = useState<number>();
  const history = useHistory();

  // 校验合格量+不合格量+待检量+让不合格量=总抽样量
  const sumCheckCount = (num1: number, num2: number, num3: number, checkOunt: number) => {
    return (_rule: RuleObject, value: any) => {
      return new Promise<void>((resolve, reject) => {
        if (!value) return resolve();
        if (Number(value) + Number(num1 ?? 0) + Number(num2 ?? 0) + Number(num3 ?? 0) > checkOunt) {
          reject('合格量+不合格量+待检量+让不合格量=总抽样量');
        }
        return resolve();
      });
    };
  };

  const toInventory = () =>
    history.push(`/quality/qualityExecution/qcTask/execute/${qcTask?.id}/inventory`);

  const getColumns = (): ColumnProps<any>[] => {
    // 参数列存在条件
    const haveParameterColumns = qcTask?.isReportFormatType() && qcTask?.isRecordSample();

    const baseColumns: ColumnProps<any>[] = _.compact([
      {
        title: '物料名称',
        dataIndex: 'materialName',
        width: 120,
      },
      {
        title: '物料编码',
        dataIndex: 'materialCode',
        width: 120,
      },
      {
        title: '质量状态',
        dataIndex: 'qcStatus',
        width: 120,
        render: (_: any, field: any) => {
          return lookup('quality.qcStatusEnum', field?.qcStatus);
        },
      },
      {
        title: '检验数量',
        dataIndex: 'qcCount',
        width: 120,
      },
      {
        title: '检验单位',
        dataIndex: ['checkCountUnit'],
        width: 120,
        render: () => taskData?.qcUnit?.name,
      },
      taskData?.material?.auxUnitList && {
        title: '辅助数量',
        dataIndex: 'auxAmountsCount',
        width: 120,
        render: (_: any, field: any) => field?.inventory?.opAmount?.auxAmounts[0]?.amount,
      },
      taskData?.material?.auxUnitList && {
        title: '辅助单位',
        dataIndex: 'auxAmountsUnit',
        width: 120,
        render: (_: any, field: any) => field?.inventory?.opAmount?.auxAmounts[0]?.unit?.name,
      },
      {
        title: '储存位置',
        dataIndex: 'location',
        width: 120,
        render: (_: any, field: any) => {
          return `${field?.location?.storage?.name}/${field?.location?.warehouse?.name}`;
        },
      },
      {
        title: '库存类型',
        dataIndex: 'category',
        width: 120,
        render: lookup('quality.category'),
      },
      qcTask?.isRecordSample() &&
        qcTask?.isReportFormatType() && {
          title: '抽样量',
          dataIndex: 'qcCheckCount',
          width: 120,
          render: (_, field) => {
            return field?.qcCheckCount;
          },
        },
      qcTask?.isRecordSample() &&
        qcTask?.isReportFormatType() && {
          title: '辅助抽样量',
          dataIndex: ['auxAmounts'],
          width: 120,
          render: (auxAmounts: { count: number }[]) => auxAmounts?.[0]?.count,
        },
      haveParameterColumns &&
        qcTask?.isSingleMaterialBatchRecordType() && {
          title: '质量判定',
          dataIndex: 'qcStatusAfter',
          width: 140,
          render: (_: any, field: any, index: number) => {
            return (
              <Form.Item
                name={[index, 'qcStatusAfter']}
                fieldKey={[field.key, 'qcStatusAfter']}
                style={{ margin: 'auto' }}
                rules={[{ required: true, message: '请完成样本质量判定' }]}
              >
                <Select style={{ width: 120 }} options={inspectionResultOptions} />
              </Form.Item>
            );
          },
        },
      haveParameterColumns && {
        title: '合格量',
        dataIndex: 'qualifiedCount',
        width: 120,
        render: (_: any, field: any, index: number) => {
          return (
            <Form.Item
              rules={[
                { validator: negativeNumberCheck() },
                {
                  validator: fractionLengthCheck(NUMBER_SCALE),
                },
                {
                  validator: sumCheckCount(
                    form.getFieldValue(['checkMaterials', index, 'defectCount']),
                    form.getFieldValue(['checkMaterials', index, 'pendingCount']),
                    form.getFieldValue(['checkMaterials', index, 'qualifiedConcessionCount']),
                    qcTask?.checkCount || 0,
                  ),
                },
              ]}
              name={[index, 'qualifiedCount']}
              fieldKey={[field.id, 'qualifiedCount']}
              style={{ margin: 'auto' }}
            >
              <Input
                onChange={(e) => {
                  setQualifiedCountRecord(Number(e.target.value));
                }}
              />
            </Form.Item>
          );
        },
      },
      haveParameterColumns && {
        title: '不合格量',
        dataIndex: 'defectCount',
        width: 120,
        render: (_: any, field: any, index: number) => {
          return (
            <Form.Item
              rules={[
                { validator: negativeNumberCheck() },
                {
                  validator: fractionLengthCheck(NUMBER_SCALE),
                },
                {
                  validator: sumCheckCount(
                    form.getFieldValue(['checkMaterials', index, 'qualifiedCount']),
                    form.getFieldValue(['checkMaterials', index, 'pendingCount']),
                    form.getFieldValue(['checkMaterials', index, 'qualifiedConcessionCount']),
                    qcTask?.checkCount || 0,
                  ),
                },
              ]}
              name={[index, 'defectCount']}
              fieldKey={[field.id, 'defectCount']}
              style={{ margin: 'auto' }}
            >
              <Input
                onChange={(e) => {
                  setDefectCountRecord(Number(e.target.value));
                }}
              />
            </Form.Item>
          );
        },
      },
      haveParameterColumns && {
        title: '待检量',
        dataIndex: 'pendingCount',
        width: 120,
        render: (_: any, field: any, index: number) => {
          return (
            <Form.Item
              rules={[
                { validator: negativeNumberCheck() },
                {
                  validator: fractionLengthCheck(NUMBER_SCALE),
                },
                {
                  validator: sumCheckCount(
                    form.getFieldValue(['checkMaterials', index, 'qualifiedCount']),
                    form.getFieldValue(['checkMaterials', index, 'qualifiedConcessionCount']),
                    form.getFieldValue(['checkMaterials', index, 'defectCount']),
                    qcTask?.checkCount || 0,
                  ),
                },
              ]}
              name={[index, 'pendingCount']}
              fieldKey={[field.id, 'pendingCount']}
              style={{ margin: 'auto' }}
            >
              <Input
                onChange={(e) => {
                  setPendingCountRecord(Number(e.target.value));
                }}
              />
            </Form.Item>
          );
        },
      },
      haveParameterColumns && {
        title: '让步合格量',
        dataIndex: 'qualifiedConcessionCount',
        width: 120,
        render: (_: any, field: any, index: number) => {
          return (
            <Form.Item
              rules={[
                { validator: negativeNumberCheck() },
                {
                  validator: fractionLengthCheck(NUMBER_SCALE),
                },
                {
                  validator: sumCheckCount(
                    form.getFieldValue(['checkMaterials', index, 'qualifiedCount']),
                    form.getFieldValue(['checkMaterials', index, 'pendingCount']),
                    form.getFieldValue(['checkMaterials', index, 'defectCount']),
                    qcTask?.checkCount || 0,
                  ),
                },
              ]}
              name={[index, 'qualifiedConcessionCount']}
              fieldKey={[field.id, 'qualifiedConcessionCount']}
              style={{ margin: 'auto' }}
            >
              <Input
                onChange={(e) => {
                  setQualifiedConcessionCountRecord(Number(e.target.value));
                }}
              />
            </Form.Item>
          );
        },
      },

      {
        title: '',
        fixed: 'right',
        width: 50,
      },
    ]);

    return baseColumns;
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        {canEditMaterial && (
          <Button
            type="primary"
            onClick={() => {
              toInventory();
            }}
            className={styles.editMaterilButon}
          >
            {'编辑'}
          </Button>
        )}
      </div>
      <Form.List name={'checkMaterials'}>
        {() => {
          return (
            <BlTable
              dataSource={dataSource}
              columns={getColumns()}
              scroll={{ x: 'max-content' }}
              rowKey="id"
              resizableCol
              sticky
              useColConfig
              pagination={false}
              tableConfigKey={'checkMaterialsTable'}
            />
          );
        }}
      </Form.List>
    </div>
  );
};

export default MaterialInfo;
