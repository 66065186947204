import { message as Message } from 'antd';

export const batchRequest = (
  batchApi: any,
  selectId: number,
  display: string,
  onSuccess: any,
  onFail?: any,
) => {
  batchApi({ id: selectId })
    .then((res: any) => {
      const { code, message } = res;

      if (code === 200) {
        Message.success(`${display}成功`);
        onSuccess?.();
        return;
      }

      Message.error(message);
    })
    .catch((err: any) => {
      console.log('err: ', err);
      onFail?.();
    });
};

export const delRequest = (delApi: any, selectId: number, onSuccess: any, onFail?: any) => {
  delApi({ id: selectId })
    .then((res: any) => {
      const { data, message } = res;

      if (data) {
        Message.success('删除');
        onSuccess?.();
        return;
      }

      Message.error(message);
    })
    .catch((err: any) => {
      console.log('err: ', err);
      onFail?.();
    });
};
