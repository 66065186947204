/**
 * 仅当 text-overflow 截断生效时才渲染Tooltip，否则渲染纯文本
 * 需自行为祖先元素设置适当的CSS属性，以确保text-overflow生效
 */

import { useRef, FC, useEffect, useReducer, CSSProperties } from 'react';
import { Tooltip, TooltipProps } from 'antd';
import styles from './styles.module.scss';

type Props = Partial<TooltipProps> & {
  text: string;
  textStyle?: CSSProperties;
};

const OverflowTooltip: FC<Props> = ({ text, textStyle, ...rest }) => {
  const ref = useRef<HTMLElement>(null);
  const isDoubleChecking = useRef<boolean>(false);
  const [, forceUpdate] = useReducer(() => Date.now(), 0);

  useEffect(() => {
    // 首次渲染完成后再判断一下
    forceUpdate();
  }, []);

  let showTooltip = false;

  if (ref.current) {
    // 折叠的菜单展开时会出现这种情况，稍后再渲染一次
    if (ref.current.offsetWidth === 0) {
      setTimeout(() => forceUpdate(), 10);
    } else {
      showTooltip = ref.current.offsetWidth < ref.current.scrollWidth;

      if (isDoubleChecking.current) {
        isDoubleChecking.current = false;
      } else {
        setTimeout(() => forceUpdate(), 100);
        isDoubleChecking.current = true;
      }
    }
  }

  const content = (
    <span ref={ref} className={styles['overflow-tooltip-wrapper']} style={textStyle}>
      {text}
    </span>
  );

  return showTooltip ? (
    <Tooltip title={text} {...rest}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export default OverflowTooltip;
export { useResizeObserver } from './useResizeObserver';
export { useWidthObserver } from './useWidthObserver';
