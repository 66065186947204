import { Button, FormInstance, Form, Input, Radio, message } from 'antd';
import styles from './styles.module.scss';
import FilterItem from './FilterItem';
import _ from 'lodash';
import { addDisable } from '../utils';
import _Array from 'src/utils/dataTypes/array';
import { BlIcon } from '@blacklake-web/component';

interface Props {
  dataSource: any;
  modifyData: (type: string, namePath: any[]) => void;
  form: FormInstance;
  namePath: any;
  maxCount: number;
  conditionGroupFieldName: string;
  conditionItemFieldName: string;
  conditionTypeName: string;
  renderCustomerFormItem?: (namePath: any, previewFieldData: any) => void;
  addText?: string;
  level?: number;
  needDelete?: Boolean; // 是否需要删除
  needCopy?: Boolean; // 是否需要复制
  needConversion?: Boolean; // 是否需要转化
}

const Container = (props: Props) => {
  const {
    modifyData,
    form,
    namePath,
    maxCount,
    conditionGroupFieldName,
    conditionItemFieldName,
    conditionTypeName,
    addText,
    ...restProps
  } = props;

  const renderRight = () => {
    const data = form.getFieldValue([...namePath, conditionGroupFieldName]);

    return data?.map?.((node: any, index: number) => {
      if (node?.fieldFilterInfo && _Array.isEmpty(node?.nextFloorCondition)) {
        return (
          <FilterItem
            {...restProps}
            key={_.toString([...namePath, conditionGroupFieldName, index, conditionItemFieldName])}
            namePath={[...namePath, conditionGroupFieldName, index, conditionItemFieldName]}
            form={form}
            modifyData={modifyData}
            conditionGroupFieldName={conditionGroupFieldName}
            maxCount={maxCount}
          />
        );
      }
      return (
        <Container
          {...restProps}
          key={_.toString([...namePath, conditionGroupFieldName, index])}
          namePath={[...namePath, conditionGroupFieldName, index]}
          form={form}
          modifyData={modifyData}
          maxCount={maxCount}
          conditionGroupFieldName={conditionGroupFieldName}
          conditionItemFieldName={conditionItemFieldName}
          conditionTypeName={conditionTypeName}
        />
      );
    });
  };

  const options = [
    { label: '且', value: 'and' },
    { label: '或', value: 'or' },
  ];

  return (
    <Form.Item name={namePath}>
      <Input hidden />
      <div className={styles?.filterContent} id="filterContent">
        <div className={styles?.filterType}>
          <div className={styles?.filterLine}> </div>
          <Form.Item name={[...namePath, conditionTypeName]}>
            <Radio.Group
              options={options}
              value={'&&'}
              optionType="button"
              buttonStyle="outline"
              size="small"
            />
          </Form.Item>
          <div className={styles?.filterLine}> </div>
        </div>
        <div className={styles?.filterRightContent} id="filterRightContent">
          <div className="nextFloorCondition">{renderRight()}</div>
          <div>
            <Button
              onClick={() => {
                const nextFloorCondition = form?.getFieldValue(conditionGroupFieldName);

                if (!_Array.isEmpty(nextFloorCondition)) {
                  const res = addDisable(
                    form?.getFieldValue(conditionGroupFieldName)?.[0].conditionGroupFieldName,
                  );

                  if (res) {
                    message.error(`最多添加${maxCount}个筛选项`);
                  } else {
                    modifyData('add', namePath);
                  }
                } else {
                  modifyData('add', namePath);
                }
              }}
              icon={<BlIcon type="iconxinjiantianjia" />}
              type="text"
            >
              {addText ?? '添加筛选条件'}
            </Button>
          </div>
        </div>
      </div>
    </Form.Item>
  );
};

export default Container;
