import React, { useEffect, useState } from 'react';
import { BlIcon, BlTable, SearchSelect, TagList, Tooltip } from 'src/components';
import {
  Select,
  InputNumber,
  Form,
  FormInstance,
  Button,
  DatePicker,
  Checkbox,
  Input,
  Popover,
  message,
} from 'antd';
import { replaceSign } from 'src/utils/constants';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import styles from './styles.module.scss';
import moment from 'moment';
import _Time from 'src/utils/dataTypes/time';
import _, { find, compact, max, some, isEmpty, map } from 'lodash';
import { fractionLengthCheck, numberMinMaxCheck, positiveInt } from 'src/utils/formValidators';
import { appEnum } from 'src/dict';
import { SalesOrderDetailsItems, MaterialType } from '../interface';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';
import MaterialSelectModal from 'src/components/searchSelect/material/Modal';
import { TextControl } from 'src/components/text';
import {
  ICustomFields,
  initCustomFieldsInData,
  injectCustomFieldColumns,
} from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

const { Option } = Select;

interface Props {
  initialData?: SalesOrderDetailsItems[];
  form: FormInstance;
  edit: boolean;
  orderStatus?: number;
  customItemFields?: ICustomFields;
}

interface MaterialsTableNode {
  amount: number;
  checked: boolean;
  deliveryDate: Date;
  id: number;
  lineNo: number;
  materialId: {
    label: number;
    value: string;
  };
  unitId: number;
}

const MaterialFormTable = (props: Props) => {
  const { initialData, form, edit, customItemFields, orderStatus } = props;
  const [dataSource, setDataSource] = useState<Partial<MaterialsTableNode>[]>([]);
  const [batchVisible, setBatchVisible] = useState(false);
  const [batchAddVisible, setBatchAddVisible] = useState(false);
  const [showBatchLine, setShowBatchLine] = useState<boolean>(false);
  const [allIsChecked, setAllIsChecked] = useState<boolean>(false);
  const [num, setNum] = useState<number>(0);
  const [batchForm] = Form.useForm();
  const name = 'materials';
  /**
   * 格式化为表单需要的数据
   */
  const handleFormatDetailDataToForm = (values: SalesOrderDetailsItems[]) => {
    const materialValue = values?.map((item: SalesOrderDetailsItems) => {
      return {
        materialId: {
          value: JSON.stringify(item?.material),
          label: item?.material?.baseInfo?.code,
        },
        materialName: item?.material?.baseInfo?.name,
        amount: item?.amount?.amount,
        unitId: item?.material?.unit?.id,
        deliveryDate: moment(item?.deliveryDate),
        id: item.id,
        workOrderList: item?.workOrderList,
        lineNo: Number(item?.lineNo),
        originalData: 1,
        specification: item?.material?.baseInfo?.specification,
        attribute: item?.material?.attribute,
        customFields: item?.customFields,
      };
    });

    setDataSource(materialValue);

    return materialValue;
  };

  /**
   * 初始化
   */
  useEffect(() => {
    if (initialData) {
      // 原来格式化后
      const afterFormatInitialValue = handleFormatDetailDataToForm(initialData);

      // 把需要格式化的数据一次传入 initCustomFieldsInData 中，格式化内所有自定义字段，也可分步处理
      const afterFormatCustomFielsValue = initCustomFieldsInData(afterFormatInitialValue);

      form.setFieldsValue({
        materials: afterFormatCustomFielsValue,
      });
    } else {
      form.setFieldsValue({
        materials: [
          {
            amount: 1,
          },
          {
            amount: 1,
          },
        ],
      });
      setDataSource([
        {
          amount: 1,
        },
        {
          amount: 1,
        },
      ]);
    }
  }, [initialData]);

  /**
   * 拖拽功能
   */
  const scrollToBottom = () => {
    const wrapper = document.getElementById('materialTable');

    if (wrapper) {
      const dom = wrapper.getElementsByClassName('ant-table-body')[0];

      if (dom) {
        dom.scrollTop = dom.scrollHeight;
      }
    }
  };
  const DragHandle = SortableHandle(() => <BlIcon type="iconrenyituozhuai" />);
  // 拖拽功能 END

  /**
   * 校验行号,不能重复
   */
  const handleValidatorIndex = (rule: any, value: number) => {
    const listValue = _.map(form.getFieldValue('materials'), (node: any) => {
      return Number(node?.lineNo);
    });

    const firstIndex = _.findIndex(listValue, (item: number) => value === item);

    const lastIndex = _.findLastIndex(listValue, (item: number) => value === item);

    if (firstIndex != lastIndex) {
      return Promise.reject('不能有重复的行号');
    }

    return Promise.resolve('');
  };
  /**
   * 判断物料行是否允许编辑
   */
  const handleGetNowWorkOrderDisable = (index: number) => {
    const nowWorkOrderList = initialData?.[index]?.workOrderList ?? [];

    const disabledStatusWithWorkOrder =
      nowWorkOrderList &&
      some(
        nowWorkOrderList,
        (node: any) => node?.workOrderStatus !== appEnum.Sales.AssociatedWorkOrder.closed,
      ); // 若当前物料行关联了非已取消状态的工单，不支持编辑

    return disabledStatusWithWorkOrder;
  };
  /**
   * 不可使用日期
   */
  const disabledDate = (currentDate: any) => {
    return currentDate && currentDate < moment().startOf('day');
  };
  /**
   * 渲染 - 批量操作内容 - 修改交货日期
   */
  const renderBatchContent = () => {
    return (
      <Form style={{ width: '300px' }} form={batchForm}>
        <Form.Item name="batchDeliveryDate" noStyle>
          <DatePicker
            showTime
            format={'YYYY-MM-DD HH:mm:ss'}
            style={{ width: '120' }}
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item noStyle>
          <div style={{ marginTop: '16px', textAlign: 'right' }}>
            <Button
              size="small"
              onClick={() => {
                setBatchVisible(false);
              }}
            >
              取消
            </Button>
            <Button
              size="small"
              style={{ marginLeft: '8px' }}
              onClick={async () => {
                try {
                  const deliveryDate = await batchForm?.validateFields();

                  const materials = form.getFieldsValue().materials;

                  const num = materials?.filter(
                    (node: MaterialsTableNode) => node?.checked,
                  )?.length;

                  if (num === materials?.length) {
                    // 全选
                    setAllIsChecked(false);
                  }

                  const newMaterials = _.map(_.cloneDeep(materials), (node: MaterialsTableNode) => {
                    if (node?.checked) {
                      return {
                        ...node,
                        checked: false,
                        deliveryDate: deliveryDate?.batchDeliveryDate,
                      };
                    }
                    return node;
                  });

                  form.setFieldsValue({ materials: newMaterials });
                  batchForm.resetFields();
                  setShowBatchLine(false);
                  setBatchVisible(false);
                } catch (error) {
                  console.log('error: ', error);
                }
              }}
            >
              确定
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };
  /**
   * 渲染 - 添加物料 - 按钮
   */
  const renderAddActon = () => {
    return (
      <div style={{ marginBottom: 20, marginTop: 10 }}>
        <Button
          type="dashed"
          icon={<BlIcon type="iconxinjiantianjia" />}
          style={{ color: '#02B980', borderColor: '#02B980' }}
          onClick={() => {
            setBatchAddVisible(true);
          }}
        >
          添加物料
        </Button>
      </div>
    );
  };
  /**
   * 批量多选 - checkbox - OnChange
   */
  const handleCheckBoxOnChange = (type: string, e?: any) => {
    let materials = form.getFieldsValue().materials;

    if (type === 'all') {
      // 全选
      setShowBatchLine(e.target.checked);
      materials = materials?.map((node: MaterialsTableNode) => {
        return { ...node, checked: e.target.checked };
      });
      form.setFieldsValue({ materials });
    }

    if (type === 'single') {
      // 单选
      const showBatch = materials?.some((node: MaterialsTableNode) => node.checked);

      setShowBatchLine(showBatch);
    }

    const num = materials?.filter((node: MaterialsTableNode) => node?.checked)?.length;

    setNum(num);

    if (num === materials?.length) {
      setAllIsChecked(true);
    } else {
      setAllIsChecked(false);
    }
  };
  /**
   * 物料变化时，更新单位
   */
  const handleMaterialOnchange = (index: number) => {
    // 选择物料编号的时候 给单位赋值 默认为主单位
    const { materialId } = form.getFieldValue(name)[index] ?? {};

    const material = materialId && JSON.parse(materialId?.value);

    const newMaterials = form.getFieldValue(name)?.map((node: any, i: number) => {
      let newNode = { ...node };

      if (index === i) {
        newNode = {
          ...node,
          amount: 1,
          unitId: material?.unit?.id,
          specification: material?.baseInfo?.specification,
          attribute: material?.attribute,
        };
      }
      return newNode;
    });

    form.setFieldsValue({ materials: newMaterials });
  };
  /**
   * 添加物料 - 完成时函数
   */
  const handleMaterialFinish = (rows?: any[]) => {
    const isFalse = _.some(rows, (node: MaterialType) => !node?.baseInfo?.enableFlag);

    if (isFalse) {
      message.error('不能选择停用状态的物料');
      return;
    }

    const materials = form.getFieldValue(name);

    const list = materials.map((el: any) => Number(el?.lineNo));

    const num = Number(max(list)) + 1;

    const addMaterials = rows?.map((node: MaterialType, index: number) => {
      return {
        lineNo: edit ? num + index : null,
        materialId: {
          value: JSON.stringify(node),
          label: node?.baseInfo?.code,
        },
        unitId: node?.unit?.id,
        amount: 1,
        specification: node?.baseInfo?.specification,
        attribute: node?.attribute,
      };
    });

    const newMaterials = _.cloneDeep(materials).concat(addMaterials);

    form.setFieldsValue({ materials: newMaterials });

    setBatchAddVisible(false);
  };

  return (
    <Form.List name={name}>
      {(fields, { add, remove, move }) => {
        /**
         * 渲染 - 批量多选 - 交互操作
         */
        const renderBatchLine = () => {
          return (
            <div
              style={{
                marginBottom: 20,
                marginTop: 10,
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
              }}
            >
              <div style={{ marginRight: 20 }}>
                已选：
                <i>{num}</i>条
              </div>
              <Button
                onClick={() => {
                  const materials = form.getFieldsValue().materials;

                  const newMaterials = _.filter(
                    _.cloneDeep(materials),
                    (node: MaterialsTableNode) => !node?.checked,
                  );

                  form.setFieldsValue({ materials: newMaterials });
                  setShowBatchLine(false);
                  if (allIsChecked) {
                    // 如果全选被选中了 全部删除以后 全选需要为空
                    setAllIsChecked(false);
                  }
                }}
                style={{ marginRight: 10 }}
              >
                删除
              </Button>
              <Popover
                content={renderBatchContent()}
                title="批量修改交货日期"
                trigger="click"
                visible={batchVisible}
                onVisibleChange={(visible: boolean) => {
                  setBatchVisible(visible);
                }}
              >
                <Button>批量修改交货日期</Button>
              </Popover>
              <div style={{ position: 'absolute', right: 0 }}>
                <Button
                  icon={<BlIcon type="iconfanhui" />}
                  onClick={() => {
                    setShowBatchLine(false);
                    //  点击返回的时候 清空所有勾选的
                    const materials = form.getFieldsValue().materials;

                    const newMaterials = _.map(
                      _.cloneDeep(materials),
                      (node: MaterialsTableNode) => {
                        return { ...node, checked: null };
                      },
                    );

                    form.setFieldsValue({ materials: newMaterials });
                  }}
                >
                  返回
                </Button>
              </div>
            </div>
          );
        };
        /**
         *渲染 - footer - 添加操作
         */
        const getFooter = () => {
          return (
            <>
              <div style={{ display: 'flex' }}>
                <div
                  className="add"
                  onClick={() => {
                    if (edit) {
                      const materials = form.getFieldsValue().materials;

                      const list = materials.map((el: any) => Number(el?.lineNo));
                      const num = Number(max(list)) + 1;

                      const newLine = [
                        {
                          lineNo: num,
                        },
                      ];
                      const newDataSources = [...dataSource, ...newLine];

                      setDataSource(newDataSources);
                      add({ lineNo: num });
                    } else {
                      add();
                    }

                    handleCheckBoxOnChange('add');
                    setTimeout(() => {
                      scrollToBottom();
                    }, 300);
                  }}
                >
                  <span style={{ marginRight: 8 }}>
                    <BlIcon type="iconxinjiantianjia" />
                  </span>
                  添加一行
                </div>
              </div>
            </>
          );
        };
        /**
         * Columns
         */
        const getColumns = (): ColumnProps<MaterialsTableNode & FormListFieldData>[] => {
          const originalColumn = [
            {
              width: 50,
              fixed: 'left',
              dataIndex: 'delete',
              render: (_, field) => {
                const { originalData } = form.getFieldValue(name)[field.name] ?? {};

                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                  >
                    <div title="拖动">
                      <DragHandle />
                    </div>
                    <div
                      style={{
                        color: originalData ? 'rgba(0, 0, 0, 0.25)' : '#FF4D4F',
                        marginLeft: 3,
                      }}
                    >
                      <BlIcon
                        type="iconlieshanchu"
                        onClick={() => {
                          if (!originalData) {
                            remove(field?.name);
                          }
                        }}
                      />
                    </div>
                  </div>
                );
              },
            },
            {
              title:
                fields.length > 0 ? (
                  <Checkbox
                    onChange={(e: CheckboxChangeEvent) => {
                      handleCheckBoxOnChange('all', e);
                    }}
                    checked={allIsChecked}
                    disabled={edit}
                  />
                ) : (
                  ''
                ),
              width: 50,
              dataIndex: 'checked',
              fixed: 'left',
              render: (_, field) => {
                const { originalData } = form.getFieldValue(name)[field.name] ?? {};

                return (
                  <Form.Item
                    valuePropName="checked"
                    name={[field?.name, 'checked']}
                    fieldKey={[field?.key, 'checked']}
                  >
                    <Checkbox
                      onChange={(e: CheckboxChangeEvent) => {
                        handleCheckBoxOnChange('single', e);
                      }}
                      disabled={originalData}
                    />
                  </Form.Item>
                );
              },
            },
            {
              title: '行号',
              dataIndex: 'lineNo',
              key: 'lineNo',
              width: 150,
              render: (_, field) => {
                return (
                  <Form.Item
                    name={[field?.name, 'lineNo']}
                    fieldKey={[field?.key, 'lineNo']}
                    validateFirst
                    rules={[
                      { required: edit, message: '行号不能为空' },
                      { validator: handleValidatorIndex },
                      positiveInt,
                    ]}
                  >
                    {edit ? (
                      <InputNumber disabled={handleGetNowWorkOrderDisable(field?.name)} min={1} />
                    ) : (
                      field?.name + 1
                    )}
                  </Form.Item>
                );
              },
            },
            {
              title: '物料编号',
              dataIndex: 'materialId',
              key: 'materialId',
              width: 200,
              render: (_, field) => {
                return (
                  <Form.Item
                    name={[field?.name, 'materialId']}
                    fieldKey={[field?.key, 'materialId']}
                    rules={[{ required: true, message: '请选择物料编号' }]}
                  >
                    <SearchSelect
                      disabled={handleGetNowWorkOrderDisable(field?.name)}
                      fetchType={'materialCompleteInfo'}
                      labelInValue
                      params={{ enableFlag: appEnum.Common.UsageStatus.enabled }}
                      onChange={() => {
                        handleMaterialOnchange(field?.name);
                      }}
                    />
                  </Form.Item>
                );
              },
            },
            {
              title: '物料名称',
              dataIndex: 'id',
              key: 'id',
              width: 200,
              render: (_, field) => {
                return (
                  <Form.Item shouldUpdate>
                    {() => {
                      // 默认值为销售单位 无则物料主单位
                      const { materialId } = form.getFieldValue(name)[field.name] ?? {};

                      const materialName =
                        materialId && JSON.parse(materialId?.value)?.baseInfo?.name;

                      return (
                        <Form.Item
                          name={[field?.name, 'id']}
                          fieldKey={[field?.key, 'id']}
                          shouldUpdate
                        >
                          <Tooltip text={materialName ?? replaceSign} width={130} />
                          <Input style={{ display: 'none' }} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: '物料属性',
              dataIndex: 'attribute',
              width: 200,
              render: (_, field) => {
                const { attribute } = form.getFieldValue(name)[field.name] ?? {};

                if (typeof attribute === 'undefined' || isEmpty(attribute)) return replaceSign;

                return (
                  <TagList
                    dataSource={map(attribute, ({ name, attributeItem, number }) => ({
                      label: `${name}:${attributeItem.content}`,
                      value: number,
                    }))}
                  />
                );
              },
            },
            {
              title: '物料规格',
              dataIndex: 'specification',
              width: 200,
              render: (_, field) => {
                return (
                  <Form.Item
                    name={[field?.name, 'specification']}
                    fieldKey={[field?.key, 'specification']}
                  >
                    <TextControl />
                  </Form.Item>
                );
              },
            },
            {
              title: '数量',
              dataIndex: 'amount',
              key: 'amount',
              width: 150,
              render: (_, field) => {
                return (
                  <Form.Item shouldUpdate>
                    {() => {
                      // 默认值为销售单位 无则物料主单位
                      const { materialId, unitId } = form.getFieldValue(name)[field.name] ?? {};

                      const material = materialId && JSON.parse(materialId?.value);

                      const unitData = material && [material?.unit];
                      const { enablePrecision, precisionFigure } =
                        find(unitData, ['id', unitId]) || {};

                      return (
                        <Form.Item
                          name={[field?.name, 'amount']}
                          fieldKey={[field?.key, 'amount']}
                          rules={compact([
                            { required: true, message: '请输入数量' },
                            {
                              validator: numberMinMaxCheck({
                                max: 999999999,
                                min: 0,
                                minAllowEqual: false,
                              }),
                            },
                            enablePrecision && {
                              validator: fractionLengthCheck(precisionFigure),
                            },
                          ])}
                          initialValue={1}
                          shouldUpdate
                        >
                          <InputNumber stringMode disabled={!unitId} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: '单位',
              dataIndex: 'unitId',
              key: 'unitId',
              width: 150,
              render: (_, field) => {
                return (
                  <Form.Item shouldUpdate>
                    {() => {
                      // 默认值为销售单位 无则物料主单位
                      const { materialId } = form.getFieldValue(name)[field.name] ?? {};

                      const material = materialId && JSON.parse(materialId?.value);

                      const unitData = material && material?.unitList;

                      return (
                        <Form.Item
                          name={[field?.name, 'unitId']}
                          fieldKey={[field?.key, 'unitId']}
                          rules={[{ required: true, message: '请选择单位' }]}
                          shouldUpdate
                        >
                          <Select
                            style={{ width: '100%' }}
                            placeholder="请选择"
                            disabled={handleGetNowWorkOrderDisable(field?.name) || !materialId}
                          >
                            {unitData?.map((item: any) => {
                              return (
                                <Option key={item.id} value={item.id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: '交货日期',
              dataIndex: 'deliveryDate',
              key: 'deliveryDate',
              width: 200,
              render: (_, field) => {
                return (
                  <Form.Item
                    name={[field?.name, 'deliveryDate']}
                    fieldKey={[field?.key, 'deliveryDate']}
                    rules={[{ required: true, message: '请选择交货日期' }]}
                    shouldUpdate
                    initialValue={_Time.daysAfter(7)}
                  >
                    <DatePicker
                      showTime
                      format={'YYYY-MM-DD HH:mm:ss'}
                      style={{ width: '120%' }}
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                );
              },
            },
          ];

          return injectCustomFieldColumns({
            columns: originalColumn, // 原本的列
            customFields: customItemFields, // 自定义字段信息
            objectCode: OBJECT_OF_CODE.salesOrderItem, // 从对象code
            type: 'form', // 使用类型
            formConfig: { form, formName: 'materials' }, // form相关配置
            disabled: edit && orderStatus !== appEnum.Sales.OrderStatus.create,
          });
        };

        const data: any = fields?.map((f: any) => {
          return { ...f, ...dataSource[f?.name] } ?? {};
        });

        // 拖动的元素
        const SortableItem = SortableElement((itemProps: any) => <tr {...itemProps} />);
        const TableContainer = SortableContainer((tableProps: any) => <tbody {...tableProps} />);

        const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
          move(oldIndex, newIndex);
        };

        const DraggableBodyRow = (bodyRowProps: any) => {
          const index = data.findIndex((x: any) => x.key === bodyRowProps['data-row-key']);

          return <SortableItem index={index} {...bodyRowProps} />;
        };

        const DraggableContainer = (containerProps: any) => (
          <TableContainer
            useDragHandle
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...containerProps}
          />
        );

        const unIncludesName = ['drag'];

        const relColumns = edit
          ? _.filter(getColumns(), (node: any) => !unIncludesName.includes(node?.dataIndex))
          : getColumns();

        return (
          <>
            {showBatchLine ? renderBatchLine() : renderAddActon()}
            <BlTable
              columns={relColumns}
              dataSource={data}
              footer={getFooter}
              scroll={{ x: 1200, y: 685 }}
              rowKey={(field) => field?.key}
              id="materialTable"
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
              className={styles?.materialTable}
            />
            {batchAddVisible && (
              <MaterialSelectModal
                title="物料选择"
                mode={'multiple'}
                selectedValue={[]}
                visible={batchAddVisible}
                onCancel={() => setBatchAddVisible(false)}
                onOk={throttleFn(handleMaterialFinish)}
              />
            )}
          </>
        );
      }}
    </Form.List>
  );
};

export default MaterialFormTable;
