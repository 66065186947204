import React, { useState, useEffect } from 'react';
import { DetailBaseInfo } from './detailBaseInfo';
import { match, RouteComponentProps } from 'react-router-dom';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import authDict from 'src/utils/auth';
import { appEnum } from 'src/dict';
import { fetchCustomerDeliveryScheduleNoteDetail } from 'src/api/ytt/supply-chain-domain/customer_delivery_schedule_note';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { handleAccept, handleReject, handleCreateInvoice } from '../batchOperate';
import { useDispatch } from 'react-redux';
import { toCreateInvoice_URL, toInvoiceList_URL } from '../navigation';
import { InvoiceOrigin } from 'src/dict/invoice';
import { CustomerDeliveryScheduleNoteDetailProps } from '../interface';
import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

interface DetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const SalesOrderDetailPage = (props: DetailProps) => {
  const { match, history } = props;
  const orderId = Number(match?.params?.id);
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [dataSource, setDataSource] = useState<CustomerDeliveryScheduleNoteDetailProps>({});
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [returnReasonForm] = Form.useForm();

  const fetchData = async () => {
    try {
      const { data } = await fetchCustomerDeliveryScheduleNoteDetail({ id: orderId });

      setDataSource(data ?? {});
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const refresh = () => {
    setRefreshMarker(Date.now());
  };
  /**
   * 跳转到新建发货单页面
   */
  const handleJumpToCreateInvoice = (data: CustomerDeliveryScheduleNoteDetailProps[]) => {
    dispatch({
      type: 'supplyChain/formatterCdsnInvoiceInfo',
      payload: { invoiceType: InvoiceOrigin.collaborativeDeliverySchedule, data },
    });
    history.push(toCreateInvoice_URL());
  };
  /**
   * 跳转到发货单列表页面
   */
  const handleJumpToInvoiceList = () => {
    history.push(toInvoiceList_URL());
  };
  /**
   * 退回原因
   */
  const formContent = (
    <div style={{ marginTop: '30px' }}>
      <Form form={returnReasonForm}>
        <Form.Item
          label={'退回原因'}
          name={'reason'}
          rules={[
            {
              max: 1000,
              message: '最多输入1000个字符',
            },
          ]}
        >
          <TextArea placeholder={'请输入退回原因'} rows={4} />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>确认退回后将给客户发送退回通知</Form.Item>
      </Form>
    </div>
  );
  /**
   * 详情操作
   */
  const baseMenu = [
    {
      key: 'comfirm',
      title: '确认',
      auth: authDict.CustomerDeliveryScheduleNote_confirm,
      onClick: () => handleAccept([dataSource?.id!], refresh),
      disabled: dataSource?.status !== appEnum.CollaborativeDeliverySchedule.OrderStatus.pending,
    },
    {
      key: 'return',
      title: '退回',
      auth: authDict.CustomerDeliveryScheduleNote_return,
      onClick: () => handleReject(dataSource, returnReasonForm, formContent, refresh),
      disabled: dataSource?.status !== appEnum.CollaborativeDeliverySchedule.OrderStatus.pending,
    },
    {
      key: 'shipmentsnote_add',
      title: '新建发货单',
      auth: authDict.shipmentsnote_add,
      onClick: () =>
        handleCreateInvoice(
          [dataSource],
          () => {
            handleJumpToCreateInvoice([dataSource]);
          },
          () => {},
          handleJumpToInvoiceList,
          'single',
        ),
      disabled: dataSource?.status !== appEnum.CollaborativeDeliverySchedule.OrderStatus.inprogress,
    },
    {
      key: 'log',
      title: '操作记录',
      icon: <BlIcon type="iconcaozuojilu" />,
      auth: authDict.CustomerDeliveryScheduleNote_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, [orderId, refreshMarker]);

  return (
    <>
      <DetailBaseInfo detail={dataSource} baseMenu={baseMenu} loading={loading} />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={orderId}
          objectCode={OBJECT_OF_CODE.CustomerDeliveryScheduleNote}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default SalesOrderDetailPage;
