import React, { useEffect, useState } from 'react';
import { BlSortFormList } from 'src/components';
import { Input, Form, FormInstance } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import _Array from 'src/utils/dataTypes/array';
import { validatorCheckTwoSidesTrim } from 'src/page/custom_fields/fieldsList/components/verificationRules';

interface StoreDetailProps {
  initialData?: [];
  form: FormInstance;
}

const AttrFormTable = (props: StoreDetailProps) => {
  const { initialData, form } = props;
  const [dataSource, setDataSource] = useState<any>([]);

  const formatDetailDataToForm = (values: any) => {
    const elementValue = values.map((item: any) => {
      return {
        attributeId: item?.attributeId,
        content: item?.content,
        index: item?.index,
        id: item?.id,
      };
    });

    setDataSource(elementValue);

    return elementValue;
  };

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue({
        attributeItemsList: formatDetailDataToForm(initialData),
      });
    }
  }, [initialData]);

  // 校验重复
  const validateRepeat = (formm?: any) => {
    return (_rule: any, value: any) => {
      return new Promise((resolve, reject) => {
        if (!value) {
          return resolve(true);
        }
        const valueList = formm.getFieldValue('attributeItemsList');
        // 从 valueList 找出重复的项组成 []
        const list = valueList.map((el: any) => el?.content);
        const repeatList = _Array.findDuplicates(list);
        // value 如果存在 在重复项 [] 中，则 reject
        const condition = repeatList.indexOf(value);

        if (condition !== -1) {
          reject('已存在该选项！');
          return;
        }
        return resolve(true);
      });
    };
  };
  const getColumns = (
    remove: (index: number | number[]) => void,
  ): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '属性值',
        render: (text: string, field: any) => {
          return (
            <div key={field.key}>
              <Form.Item
                fieldKey={[field.fieldKey, 'content']}
                name={[field.name, 'content']}
                validateFirst
                style={{ width: '100%', marginBottom: '0' }}
                rules={[
                  { required: true, message: '属性值必填' },
                  { max: 255, message: '不超过255字符' },
                  { validator: validatorCheckTwoSidesTrim() },
                  { validator: validateRepeat(form) },
                ]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Input />
              </Form.Item>
            </div>
          );
        },
      },
    ];
  };

  return (
    <BlSortFormList
      name={'attributeItemsList'}
      form={form}
      renderColumns={(remove) => getColumns(remove)}
    />
  );
};

export default AttrFormTable;
