import React, { useContext, useEffect, useState } from 'react';
import { formatDemo } from './utils';
import { Badge } from 'antd';
import { Tooltip } from 'components';
import { DetailLayout, DetailLayoutForDrawer, DetailLayoutInfoBlock } from 'layout';
import BaseTable from './component/detailBaseTable';
import { replaceSign } from 'src/utils/constants';
import { NumberRulesContext, NUMBER_RULES_STATUS_ENUM, TABLE_TYPE_ENUM } from './constant';
import { ElementFields, SuitObjectData } from './interface';
import { fetchNumberRuleDetail } from 'src/api/ytt/custom-domain';
import _Array from 'src/utils/dataTypes/array';

interface NumberRulesDetailProps {
  visible: boolean;
  closeDetail: () => void;
  numberId: number;
}

const NumberRulesDetail = (props: NumberRulesDetailProps) => {
  const { visible, closeDetail, numberId } = props;

  const { formatConfigData } = useContext(NumberRulesContext);

  const [detail, setDetail] = useState({} as any);
  const [demo, setDemo] = useState(''); // 示例

  const fetchData = async () => {
    try {
      const { data } = await fetchNumberRuleDetail({ id: numberId }, { legacy: true });

      if (data) {
        setDetail(data);

        const newElementList = data?.elementList?.map((node: any) => {
          if (
            node?.elementType !== TABLE_TYPE_ENUM.DATE &&
            node?.elementType !== TABLE_TYPE_ENUM.VARIABLE &&
            !node?.elementFields
          ) {
            return node;
          }

          if (node?.elementType !== TABLE_TYPE_ENUM.DATE && _Array.isEmpty(node?.elementFields)) {
            return [0];
          }

          const newElementFields = node?.elementFields?.map((e: ElementFields) => e?.relFieldName);

          return { ...node, elementFields: newElementFields };
        });

        const demoStr = formatDemo(newElementList, formatConfigData);

        setDemo(demoStr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const detailInfo: DetailLayoutInfoBlock = {
    title: '·基本信息',
    items: [
      {
        label: '规则编号',
        dataIndex: 'ruleCode',
        render: (ruleCode) => ruleCode || replaceSign,
      },
      {
        label: '规则名称',
        dataIndex: 'ruleName',
        render: (ruleName) => ruleName || replaceSign,
      },
      {
        label: '适用对象',
        dataIndex: 'suitObj',
        render: (suitObj: SuitObjectData) => suitObj?.objectName ?? replaceSign,
      },
      {
        label: '状态',
        dataIndex: 'enableFlag',
        isFullLine: true,
        render: (enableFlag: number) => {
          const statusMap = new Map([
            [NUMBER_RULES_STATUS_ENUM.ENABLED, <Badge text="启用中" status="success" />],
            [NUMBER_RULES_STATUS_ENUM.DISABLED, <Badge text="停用中" status="error" />],
          ]);

          return statusMap.get(enableFlag);
        },
      },
      {
        label: '',
        dataIndex: 'sample',
        isFullLine: true,
        render: () => {
          return (
            <div
              style={{
                height: 70,
                backgroundColor: '#FAFAFA',
                padding: 14,
                marginLeft: 20,
              }}
            >
              <div>编号示例</div>
              <div style={{ fontWeight: 700 }}>
                <Tooltip text={demo ?? replaceSign} width={560} />
              </div>
            </div>
          );
        },
      },
      {
        label: '',
        dataIndex: 'table',
        isFullLine: true,
        render: () => {
          return (
            <div
              style={{
                marginLeft: 20,
              }}
            >
              <BaseTable dataSource={detail?.elementList} formatConfigData={formatConfigData} />
            </div>
          );
        },
      },
      {
        label: '备注',
        dataIndex: 'ruleRemark',
        isFullLine: true,
        render: (ruleRemark) => ruleRemark || replaceSign,
      },
    ],
    column: 2,
  };

  return (
    <DetailLayoutForDrawer
      visible={visible}
      onClose={closeDetail}
      width={680}
      content={<DetailLayout title="编号规则详情" info={[detailInfo]} dataSource={detail} />}
    />
  );
};

export default NumberRulesDetail;
