import React, { useState } from 'react';
import { Button } from 'antd';
import { BlIcon } from 'src/components';
import UserSelectModal from '../../components/userAndDepartmentSelect';
import { ReceiverType } from '../interface';
import { cloneDeep, map } from 'lodash';
import { FormInstance } from 'antd/es/form/Form';
import { getOtherTypeSelect, getTypeSelect } from '../utils';

interface Props {
  onChange?: () => void;
  value?: any;
  isSelectUser: boolean;
  form: FormInstance;
}

export default function AddUser({ onChange, value = [], isSelectUser, form }: Props) {
  const [showSelectModal, setShowSelectModal] = useState<boolean>(false);

  const relType = isSelectUser ? ReceiverType.user : ReceiverType.department;

  /**
   * 格式化 form 数据到 UserSelectModal组件
   */
  const formatFormToUserSelectModal = (value: any[]) => {
    const relValue = getTypeSelect(value, relType);

    const _value = map(relValue, (node: any) => {
      const data = JSON.parse(node?.value);

      return data;
    });

    return _value;
  };

  /**
   * 格式化 UserSelectModal组件 数据到 form
   */
  const formatUserSelectModalToForm = (value: any[]) => {
    const messageReceivers = form.getFieldValue('messageReceivers') ?? [];
    const otherTypeSelect = getOtherTypeSelect(messageReceivers, relType);
    const relSelect = otherTypeSelect.concat(value);

    return relSelect;
  };

  /**
   * UserSelectModal组件 选择值变化
   */
  const handleUserSelectModalChange = (value: any[]) => {
    const _value = map(value, (node: any) => {
      const newNode = cloneDeep(node);
      const _newNode = JSON.stringify({ ...newNode });

      return {
        key: node?.id,
        value: _newNode,
        label: node?.name,
      };
    });

    return onChange?.(formatUserSelectModalToForm(_value));
  };

  return (
    <div>
      <Button
        icon={<BlIcon type="iconxinjiantianjia" />}
        onClick={() => {
          setShowSelectModal(true);
        }}
        type="dashed"
      >
        {isSelectUser ? '添加用户' : '添加部门'}
      </Button>
      {showSelectModal && (
        <UserSelectModal
          userActives={[1]}
          isMultiple
          isSelectUser={isSelectUser}
          visible={showSelectModal}
          onClose={() => setShowSelectModal(false)}
          submitData={handleUserSelectModalChange}
          choosedData={formatFormToUserSelectModal(value)}
        />
      )}
    </div>
  );
}
