import React, { useState, useEffect, FC } from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import { getAuthFromLocalStorage } from 'src/utils/auth';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import _Time from 'src/utils/dataTypes/time';
import { replaceSign } from 'src/utils/constants';
import { TraceNodeDetailType, TraceNodeTab } from '../interface';
import SubNodeTab from './subNode';
import WarehouseTab from './warehouse';
import ProductionTab from './production';
import ParentNodeTab from './parentNode';
import { fetchTraceTraceNodeInventoryGetNodeDetail } from 'src/api/ytt/trace-domain/traceNode';
import TabList from '../share/tabList';

interface DetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const TraceNodeDetail: FC<DetailProps> = ({ match }) => {
  const traceId = Number(match?.params?.id);

  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState<TraceNodeDetailType>();

  const fetchBaseInfoData = async (traceId?: number) => {
    try {
      if (traceId) {
        // 基本信息
        const { data } = await fetchTraceTraceNodeInventoryGetNodeDetail({ traceId });

        if (data) {
          setDataSource(data);
        }
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBaseInfoData(traceId);
  }, [traceId]);

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '批次号',
        dataIndex: ['traceNode', 'batchNo'],
        render: (batchNo: string) => batchNo ?? replaceSign,
      },
      {
        label: '标识码',
        dataIndex: ['traceNode', 'qrCode'],
        render: (qrCode: string) => qrCode ?? replaceSign,
      },
      { label: '物料编号', dataIndex: ['traceNode', 'material', 'code'] },
      { label: '物料名称', dataIndex: ['traceNode', 'material', 'name'] },
      { label: '供应商名称', dataIndex: ['supplier', 'name'] },
      { label: '供应商批次', dataIndex: ['supplier', 'supplierBatchNo'] },
      {
        label: '生产日期',
        dataIndex: ['productionTime'],
        render: (productionTime: Date) => {
          return productionTime ? _Time.format(productionTime) : replaceSign;
        },
      },
    ],
  };

  const renderTabs = () => {
    const tabList = [
      {
        tab: '父级节点',
        key: TraceNodeTab.PARENT,
        component: <ParentNodeTab traceId={traceId} />,
      },
      {
        tab: '子级节点',
        key: TraceNodeTab.SUB,
        component: <SubNodeTab traceId={traceId} />,
      },
      {
        tab: '生产信息',
        key: TraceNodeTab.PRODUCTION,
        component: <ProductionTab traceId={traceId} />,
      },
      {
        tab: '仓储信息',
        key: TraceNodeTab.WAREHOUSE,
        component: <WarehouseTab traceId={traceId} />,
      },
      // {
      //   tab: '质量信息',
      //   key: TraceNodeTab.QUALITY,
      //   component: <QualityTab dataSource={dataSource?.qualityTaskSource} />,
      // },
    ];

    return <TabList tabList={tabList} />;
  };

  const tabInfo: DetailLayoutInfoBlock = {
    title: '详细信息',
    items: [
      {
        dataIndex: 'xxx',
        render: () => renderTabs(),
      },
    ],
  };

  return (
    <DetailLayout
      userAuth={getAuthFromLocalStorage()}
      title="追溯节点详情"
      info={[baseInfo, tabInfo]}
      dataSource={dataSource}
      loading={loading}
    />
  );
};

export default TraceNodeDetail;
