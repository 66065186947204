/**
 * @description 触发规则 编辑触发规则页 新建触发规则页
 */

import { useEffect, useState } from 'react';

import {
  fetchTriggerRuleDetail,
  FetchTriggerRuleDetailResponse,
} from 'src/api/ytt/trigger-domain/triggerRule';
import { CRUD } from 'src/dict/common';

import TriggerRuleForm from './components/Form';
import { getListPageUrl } from './utils/navigation';
import { formatToFormData } from './utils/formatToFormData';

const CreateTriggerRuleForm = (props: any) => {
  const {
    history,
    match: {
      params: { id: editId },
    },
    location: { search },
  } = props;
  const copyId = search?.split('=')?.[1];

  const [dataSourse, setDataSourse] = useState<FetchTriggerRuleDetailResponse['data']>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (editId || copyId) {
      setLoading(true);
      fetchTriggerRuleDetail({ ruleId: Number(editId ?? copyId) })
        .then((res) => {
          if (res.code === 200) {
            setDataSourse(formatToFormData(res.data, type() === CRUD.copy));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const type = () => {
    if (typeof editId === 'string') {
      return CRUD.edit;
    }
    if (typeof copyId === 'string') {
      return CRUD.copy;
    }
    return CRUD.create;
  };

  return (
    <TriggerRuleForm
      title={editId ? '编辑触发规则' : '新建触发规则'}
      onFinish={() => history.push(getListPageUrl())}
      onCancel={() => history.go(-1)}
      loading={loading}
      initialValues={dataSourse}
      editId={editId ? Number(editId) : editId}
      type={type()}
    />
  );
};

export default CreateTriggerRuleForm;
