/**
 * 工序连接
 */
import { replaceSign } from 'src/utils/constants';
import { DetailLayout } from 'layout';
import { memo } from 'react';
import { Tag } from 'antd';
import _ from 'lodash';
import lookup from 'src/dict';

interface Props {
  dataSource: any;
  isWorkOrder?: Boolean;
  useType?: number;
}

const ProcessEdgeForm = memo((props: Props) => {
  const { dataSource, isWorkOrder = false, useType = 0 } = props;
  const { processManufacturedGoods = {} } = dataSource || {};

  const baseInfo = {
    title: '工序接序',
    items: [
      {
        label: '前工序号',
        dataIndex: 'prevProcessNum',
      },
      {
        label: '前工序名称',
        dataIndex: 'prevProcessName',
      },
      {
        label: '后工序号',
        dataIndex: 'nextProcessNum',
      },
      {
        label: '后工序名称',
        dataIndex: 'nextProcessName',
      },
    ],
  };

  const producingInfo = {
    column: 1,
    title: '在制品',
    items: _.compact([
      {
        label: '物料编号',
        dataIndex: 'materialCode',
        render: () => processManufacturedGoods?.material?.baseInfo?.code || replaceSign,
      },
      {
        label: '物料名称',
        dataIndex: 'materialName',
        render: () => processManufacturedGoods?.material?.baseInfo?.name || replaceSign,
      },
      {
        label: isWorkOrder ? '基准数' : '数量',
        dataIndex: 'amount',
        render: () => {
          return isWorkOrder
            ? processManufacturedGoods.standardAmount
            : processManufacturedGoods.amount;
        },
      },
      {
        label: '自制单位',
        dataIndex: 'unitId',
        render: () => processManufacturedGoods?.unit?.name || replaceSign,
      },
      {
        label: '投料单位',
        dataIndex: 'feedUnitId',
        render: () => processManufacturedGoods?.feedUnit?.name || replaceSign,
      },
      isWorkOrder && {
        label: '计划生产数',
        dataIndex: 'plannedAmount',
        render: () => processManufacturedGoods?.plannedAmount?.amountDisplay || replaceSign,
      },

      isWorkOrder && {
        label: '已下发数',
        dataIndex: 'plannedAmount',
        render: () => processManufacturedGoods?.dispatchedAmount?.amountDisplay || replaceSign,
      },
      isWorkOrder && {
        label: '正在下发数',
        dataIndex: 'plannedAmount',
        render: () => processManufacturedGoods?.nowDispatchedAmount?.amountDisplay || replaceSign,
      },
      isWorkOrder && {
        label: '可下发数',
        dataIndex: 'plannedAmount',
        render: () =>
          processManufacturedGoods?.dispatchedRemainAmount?.amountDisplay || replaceSign,
      },
      isWorkOrder && {
        label: '总报工数',
        dataIndex: 'plannedAmount',
        render: () => processManufacturedGoods?.totalReportAmount?.amountDisplay || replaceSign,
      },
      isWorkOrder && {
        label: '合格报工数',
        dataIndex: 'plannedAmount',
        render: () => processManufacturedGoods?.qualifiedAmount?.amountDisplay || replaceSign,
      },
      isWorkOrder && {
        label: '不合格报工数',
        dataIndex: 'plannedAmount',
        render: () => processManufacturedGoods?.disqualifiedAmount?.amountDisplay || replaceSign,
      },
      isWorkOrder && {
        label: '入库数',
        dataIndex: 'plannedAmount',
        render: () => processManufacturedGoods?.warehousedAmount?.amountDisplay || replaceSign,
      },
      isWorkOrder && {
        label: '投料数',
        dataIndex: 'plannedAmount',
        render: () => processManufacturedGoods?.inputAmount?.amountDisplay || replaceSign,
      },
      {
        label: '是否报工',
        dataIndex: 'reportFlag',
        render: () => lookup('processRoute.yn')(processManufacturedGoods.reportFlag),
      },
      {
        label: '报工方式',
        dataIndex: 'reportingMethods',
        render: () => {
          const { reportingMethods } = processManufacturedGoods;

          return reportingMethods && reportingMethods.length > 0
            ? reportingMethods.map((item: number) => {
                return (
                  <span key={item}>
                    <Tag>{lookup('processRoute.reportingMethods')(item)}</Tag>
                  </span>
                );
              })
            : replaceSign;
        },
      },
      {
        label: '报工控件编号',
        dataIndex: 'workReportControl',
        render: () => processManufacturedGoods.workReportControlCode || replaceSign,
      },
      {
        label: '单次报工数量',
        dataIndex: 'singleWorkReportNum',
        render: () => processManufacturedGoods.singleWorkReportNum || replaceSign,
      },
      {
        label: '是否入库',
        dataIndex: 'warehousing',
        render: () => lookup('processRoute.yn')(processManufacturedGoods.warehousing),
      },
      {
        label: '自动入库',
        dataIndex: 'autoWarehousingFlag',
        render: () => lookup('processRoute.yn')(processManufacturedGoods.autoWarehousingFlag),
      },
      {
        label: '投料控件编号',
        dataIndex: 'materialFeedControl',
        render: () => processManufacturedGoods.materialFeedControlCode || replaceSign,
      },
      {
        label: '是否必投',
        dataIndex: 'feeding',
        render: () => lookup('processRoute.yn')(processManufacturedGoods.feeding),
      },
      {
        label: '投料上下限类型',
        dataIndex: 'limit',
        render: () => lookup('processRoute.feedingLimit')(processManufacturedGoods.limit),
      },
      {
        label: '投料下限',
        dataIndex: 'lowerLimit',
        render: () => 'null',
      },
      {
        label: '投料上限',
        dataIndex: 'upperLimit',
        render: () => 'null',
      },
      {
        label: '投料下限比例',
        dataIndex: 'lowerLimitRatio',
        render: () => 'null',
      },
      {
        label: '投料上限比例',
        dataIndex: 'upperLimitRatio',
        render: () => 'null',
      },
      {
        label: '投料质量状态',
        dataIndex: 'feedingQualityStatus',
        render: () => {
          const { feedingQualityStatus } = processManufacturedGoods;

          return feedingQualityStatus && feedingQualityStatus.length > 0
            ? feedingQualityStatus.map((item: number) => {
                return (
                  <span key={item}>
                    <Tag>{lookup('material.feedingQualityStatus')(item)}</Tag>
                  </span>
                );
              })
            : replaceSign;
        },
      },
      {
        label: processManufacturedGoods?.warehousing ? '投料类型' : '',
        dataIndex: 'feedType',
        render: () => {
          return processManufacturedGoods?.warehousing
            ? lookup('material.feedingTypeMap')(processManufacturedGoods.feedType) || replaceSign
            : '';
        },
      },
    ]),
  };

  return (
    <DetailLayout title="" info={[baseInfo, producingInfo]} dataSource={dataSource} baseMenu={[]} />
  );
});

export default ProcessEdgeForm;
