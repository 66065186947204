import _ from 'lodash';
import { omit } from 'lodash/fp';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Form, Input, message, Select } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import {
  fetchReportTemplateAdd,
  FetchReportTemplateAddRequest,
  fetchReportTemplateDetail,
  fetchReportTemplateUpdate,
  FetchReportTemplateUpdateRequest,
} from 'src/api/ytt/report-domain/reportTemplate';
import { BASIC_PATH } from '../constants';
import { templateType as templateTypeMappings } from 'src/dict/reportTemplate/mappings';
import ControlEditor from 'src/page/sop/scheme/control/editor';
import { BizType, ControlType, FieldSource } from 'src/dict/sop';
import { LocalControlItem } from 'src/page/sop/scheme/types';
import { NEW_CONTROL_ID } from 'src/page/sop/scheme/constants';
import { CRUD } from 'src/dict/common';
import qs from 'qs';
import { textValidator1 } from 'src/utils/formValidators';
import { extractIdsForStepofUser } from 'src/page/sop/scheme/share/extractIdsForStep';
import { TemplateType } from 'src/dict/reportTemplate';

interface RouteParams {
  id?: string;
}

const EditReportTemplate = () => {
  const history = useHistory();
  const { id } = useParams<RouteParams>();
  const { search } = useLocation();
  const [form] = Form.useForm();
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  const judgeActionTypeFn = () => {
    if (!_.isUndefined(id)) {
      return CRUD.edit;
    }
    if (query?.copyId) {
      return CRUD.copy;
    }
    return CRUD.create;
  };
  const actionType = judgeActionTypeFn();
  const [loading, setLoading] = useState(actionType === CRUD.edit || actionType === CRUD.copy);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // 控件的本地唯一标识。因新增的控件无id，所有控件统一使用 localKey 作为唯一标识
  const [localKeys, setLocalKeys] = useState<string[]>([]);
  const [basicInfoData, setBasicInfoData] = useState<{
    id: number | undefined;
    name?: string;
    category?: number;
    controls?: any[];
  }>({
    id: undefined,
    name: '',
    // category: undefined,
    category: TemplateType.productionReport,
    controls: [],
  });
  const formProps = {
    preserve: true,
    initialValues: basicInfoData,
  };

  const fetchDetails = async () => {
    const reportId = actionType === CRUD.copy ? query.copyId : id;
    const { data } = await fetchReportTemplateDetail(
      { id: _.toNumber(reportId) },
      { legacy: true },
    );

    // contorls的数据display 转换
    const formatControls = _.get(data, 'controls')?.map((item) => {
      switch (item.controlType) {
        case ControlType.associatedField:
          if (item?.fieldSource === FieldSource.currentSheet) {
            const localControl = _.get(item, 'localControl');

            return {
              ...item,
              ...{
                localControl: _.isNumber(localControl?.id)
                  ? _.toNumber(localControl?.id)
                  : undefined,
              },
            };
          }

          return item;
        case ControlType.numeric: {
          const unitObj = !_.get(item, 'unitId')
            ? undefined
            : {
                label: _.get(item, 'unitName'),
                value: _.get(item, 'unitId'),
                key: _.get(item, 'unitId'),
              };

          _.set(item, 'unitId', unitObj);

          return item;
        }
        default:
          return item;
      }
    });

    setBasicInfoData({
      id: data?.id,
      name: actionType === CRUD.copy ? `${data?.name}-副本` : data?.name,
      category: data?.category as number,
      // controls: data?.controls,
      controls: formatControls,
    });
    form.resetFields();
    _.delay(() => setLoading(false), 10);
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,
    title: '基本信息',
    items: _.compact([
      {
        name: 'id',
        hidden: true,
        render: () => <Input />,
      },
      {
        label: '报告模板名称',
        name: 'name',
        rules: [
          { required: true },
          { validator: textValidator1 },
          { max: 200, message: '不超过200个字符' },
        ],
        render: () => <Input placeholder={'请输入名称'} />,
      },
      {
        label: '模板类型',
        name: 'category',
        rules: [{ required: true }],
        render: () => (
          <Select
            options={templateTypeMappings}
            placeholder={'请选择'}
            disabled={actionType === CRUD.edit || actionType === CRUD.copy}
          />
        ),
      },
    ]),
  };
  const controlListInfo: DataFormLayoutInfoBlock = {
    column: 1,
    title: '控件信息',
    items: [
      {
        label: '',
        isFullLine: true,
        render: () => (
          <ControlEditor
            loading={loading}
            form={form}
            localKeys={localKeys}
            setLocalKeys={setLocalKeys}
            bizType={BizType.general} // general为基础控件，production为业务控件
            objectCode={'ReportTemplateControl'}
          />
        ),
      },
    ],
  };

  const handleCancel = useCallback(() => {
    history.push(BASIC_PATH);
  }, []);

  function handleFormatCreateData(values: any): FetchReportTemplateAddRequest {
    return { ...values };
  }
  const createFn = async (values: any) => {
    try {
      await fetchReportTemplateAdd(handleFormatCreateData(values));
      if (actionType === CRUD.create) {
        message.success('新建成功');
      } else if (actionType === CRUD.copy) {
        message.success('复制成功');
      }
      history.push(BASIC_PATH);
    } catch (err) {
      console.log('err', err);
    }
  };

  function handleFormatEditData(values: any): FetchReportTemplateUpdateRequest {
    return { ...values };
  }
  const editFn = async (values: any) => {
    try {
      await fetchReportTemplateUpdate(handleFormatEditData(values));
      message.success('编辑成功');
      history.push(BASIC_PATH);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleFinish = useCallback(async () => {
    form
      .validateFields()
      .then(async () => {
        const clearProps = omit(['creatorId', 'operatorId', 'createdAt', 'updatedAt']);
        // validateFields拿不到当前未渲染的控件数据, 故需另取一次
        const values = clearProps(form.getFieldsValue(true));

        // 按照本地顺序给控件排序
        const controls = localKeys.map((key, idx) => {
          let value = values.controls.find((item: LocalControlItem) => item._localKey === key);

          // 新控件不传id
          if (value.id === NEW_CONTROL_ID) {
            value = _.omit(value, 'id');
          } else {
            value = clearProps(value);
          }
          const handleFormat = () => {
            value.optionValue = value.optionValue?.map(
              (item: { key?: string; value?: string | number; label: string }) => {
                return {
                  label: item.label,
                  value: item.label,
                };
              },
            );
          };

          // 如果是选择器控件，处理optionValue
          value.controlType === ControlType.selector && handleFormat();

          return {
            ...value,
            sortIndex: idx + 1,
          };
        });

        // 删去本地key
        const controlValues = controls.map(omit('_localKey'));
        // const formatControlValues = controlValues.map((item) => {
        //   return item.controlType === ControlType.user ? extractIdsForStepofUser(item) : item;
        // });
        const formatControlValues = controlValues.map((item) => {
          switch (item.controlType) {
            case ControlType.user:
              return extractIdsForStepofUser(item);
            case ControlType.associatedField: {
              if (item.fieldSource === FieldSource.currentSheet) {
                const localControlIds = _.get(item, 'localControlIds');

                return {
                  ..._.omit(item, 'localControlIds'),
                  ...{
                    localControl: localControlIds,
                  },
                };
              }
              return item;
            }
            case ControlType.numeric: {
              const unitId = _.get(item, 'unitId')?.value;

              _.set(item, 'unitId', unitId);

              return item;
            }
            default:
              return item;
          }
        });
        const submitValue = {
          ...values,
          controls: formatControlValues,
        };

        const fn = actionType === CRUD.edit ? editFn : createFn;

        setIsSubmitting(true);
        try {
          await fn(submitValue);
        } finally {
          setIsSubmitting(false);
        }
      })
      .catch((errInfo) => {
        console.error(errInfo);
        message.error('请正确配置控件信息');
      });
  }, [localKeys]);

  useEffect(() => {
    if (actionType === CRUD.edit || actionType === CRUD.copy) {
      // 编辑,复制
      fetchDetails();
    } else {
      // 新建
    }
  }, [id]);

  return (
    <DataFormLayout
      form={form}
      formProps={formProps}
      info={[baseInfo, controlListInfo]}
      onCancel={handleCancel}
      onFinish={handleFinish}
      confirmLoading={isSubmitting}
    />
  );
};

export default EditReportTemplate;
