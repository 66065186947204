/**
 * 多产出
 */
import React from 'react';
import _ from 'lodash';
import { TextToolTip } from '@blacklake-web/component';
import { FormInstance, Input, Form, InputNumber, Select, Radio } from 'antd';
import {
  BatchOperateTableHeader,
  SearchSelect,
  BlSortFormList,
  TagList,
  MaterialSelect,
} from 'components';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/es/table';
import {
  getMaterialAttrs,
  getMaterialProduceUnit,
  getMaterialUnitInfo,
  getMaterialUnits,
  getMaterialFeedUnit,
} from 'src/entity/material';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import { appDict, appEnum } from 'src/dict';
//
import { formatMaterialToApi, formatMaterialToForm } from '../utils';
import {
  BomFormOutputMaterial,
  BomRequestOutputMaterial,
  BomResponseOutputMaterial,
} from '../index.type';

interface CoproductFormProps {
  /** 操作类型 新建或编辑 */
  type?: string;
  form: FormInstance;
  name: string;
  resetColumns?: ColumnProps<any & FormListFieldData>[]; // 通过重排来组装列columns,工单
  fixedRowFn?: (field: FormListFieldData, index: number) => boolean;
}

// 格式化 多产出物料 给接口
export const formatBomOutputMaterialsToApi = (
  bomOutputMaterials?: BomFormOutputMaterial[],
): BomRequestOutputMaterial[] => {
  if (_.isEmpty(bomOutputMaterials)) return [];

  return _.map(
    bomOutputMaterials,
    ({
      materialId,
      amount,
      unitId = 0,
      singleWorkReportAmount,
      outputProcessId,
      outputSopControlId,
      ...resItem
    }) => ({
      ...resItem,
      amount: _.toString(amount),
      unitId,
      materialId: formatMaterialToApi(materialId),
      outputProcessId: outputProcessId?.value ? _.toNumber(outputProcessId.value) : undefined,
      outputSopControlCode: outputSopControlId?.value
        ? _.toString(outputSopControlId.value)
        : undefined,
      singleWorkReportAmount: _.toString(singleWorkReportAmount),
    }),
  );
};
// 格式化 多产出物料 给form
export const formatBomOutputMaterialsToForm = (
  value: BomResponseOutputMaterial[],
  fn?: (item: BomResponseOutputMaterial) => { [index: string]: any }, // 给工单预留初始化
): BomFormOutputMaterial[] => {
  if (_.isEmpty(value)) return [];

  return _.map(value, (item) => {
    const {
      lineSeq = 10,
      material,
      outputProcessProcessRouteNodeSimpleVO,
      outputSopControlDTO,
      singleWorkReportAmount,
      amount,
      ...resItem
    } = item;

    return {
      ...resItem,
      lineSeq,
      amount: _.isString(amount) ? _.toNumber(amount) : undefined,
      singleWorkReportAmount: _.isString(singleWorkReportAmount)
        ? _.toNumber(singleWorkReportAmount)
        : undefined,
      outputProcessId: outputProcessProcessRouteNodeSimpleVO?.id
        ? {
            label: outputProcessProcessRouteNodeSimpleVO?.processNum,
            value: outputProcessProcessRouteNodeSimpleVO?.id,
          }
        : undefined,
      outputSopControlId: outputSopControlDTO?.id
        ? {
            label: outputSopControlDTO?.code,
            value: outputSopControlDTO?.code,
          }
        : undefined,
      materialId: formatMaterialToForm(material) ?? {},
      ...(fn?.(item) ?? {}),
    };
  });
};

export const CoproductForm = (props: CoproductFormProps) => {
  const { form, name, resetColumns = [], fixedRowFn, type = appEnum.Common.CRUD.edit } = props;
  const { getFieldValue, setFields } = form;

  /**
   * 校验行号,不能重复
   */
  const validatorIndex = (rule: any, value: any) => {
    const listValue = form.getFieldValue(name);

    const firstIndex = _.findIndex(listValue, { lineSeq: value });
    const lastIndex = _.findLastIndex(listValue, { lineSeq: value });

    if (firstIndex != lastIndex) {
      return Promise.reject('不能有重复的行号');
    }

    return Promise.resolve('');
  };

  /**
   * 校验单位，存在数量时必填
   */
  const validatorUnit = (fieldName: number) => {
    return (rule: any, value: any) => {
      const amount = getFieldValue([name, fieldName, 'amount']);

      if (_.isNumber(amount) && _.isNil(value)) {
        return Promise.reject('数量填写时，单位不能为空');
      }

      return Promise.resolve('');
    };
  };

  const getDefaultValue = (field: FormListFieldData, fieldStr: string, defaultValue: any) => {
    const hasValue = !_.isNil(form.getFieldValue([name, field.name, fieldStr]));

    if (hasValue) return undefined;

    return defaultValue;
  };

  /**
   * 获取行物料其他信息
   */
  const getMaterialInfo = (fieldName: number, key?: string, value?: any) => {
    let material = {};

    if (value) {
      material = JSON.parse(value?.value ?? '{}');
    } else {
      material = JSON.parse(getFieldValue([name, fieldName, 'materialId'])?.value ?? '{}');
    }

    if (key) {
      return _.get(material, key);
    }

    return material;
  };

  /**
   * 批量设置
   * @param amount
   */
  const handleBatchSetValue = (field: string, value: any) => {
    const formList = _.cloneDeep(form.getFieldValue(name));

    _.forEach(formList, (item) => {
      item[field] = value;
    });

    form.setFieldsValue({
      [name]: formList,
    });
  };

  const baseColumnsMap = new Map<string, ColumnProps<any & FormListFieldData>>([
    [
      'lineSeq',
      {
        title: '行号',
        dataIndex: 'lineSeq',
        width: 120,
        render: (_text, field, index) => {
          return (
            <Form.Item
              initialValue={getDefaultValue(field, 'lineSeq', (index + 1) * 10)}
              name={[field.name, 'lineSeq']}
              fieldKey={[field.fieldKey, 'lineSeq']}
              style={{ marginBottom: '0' }}
              rules={[{ required: true, message: '行号不能为空' }, { validator: validatorIndex }]}
            >
              <InputNumber placeholder="请输入" min={1} precision={0} step={5} />
            </Form.Item>
          );
        },
      },
    ],
    [
      'materialId',
      {
        title: <BatchOperateTableHeader titleText="物料编号" required />,
        dataIndex: 'materialId',
        width: 200,
        render: (text, field) => {
          return (
            <div>
              <Form.Item
                style={{ marginBottom: '0' }}
                fieldKey={[field.fieldKey, 'materialId']}
                name={[field.name, 'materialId']}
                rules={[{ required: true, message: '物料不能为空' }]}
              >
                <MaterialSelect
                  params={{
                    enableFlag: appEnum.Common.UsageStatus.enabled,
                    bizType: [appEnum.Material.BizType.default],
                  }}
                  onChange={(value: any) => {
                    const materialInfo = getMaterialInfo(field.name, undefined, value);

                    const unit = getMaterialProduceUnit(materialInfo, true);

                    setFields([
                      {
                        name: [name, field.name, 'unitId'],
                        value: unit?.value,
                      },
                    ]);
                  }}
                />
              </Form.Item>
            </div>
          );
        },
      },
    ],
    [
      'materialName',
      {
        title: '物料名称',
        dataIndex: 'materialName',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() => _.get(getMaterialInfo(field.name, 'baseInfo'), 'name')}
            </Form.Item>
          );
        },
      },
    ],
    [
      'attributes',
      {
        title: '物料属性',
        dataIndex: 'attributes',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() => <TagList dataSource={getMaterialAttrs(getMaterialInfo(field.name))} />}
            </Form.Item>
          );
        },
      },
    ],
    [
      'category',
      {
        title: '物料分类',
        dataIndex: 'category',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() => _.get(getMaterialInfo(field.name, 'category'), 'name')}
            </Form.Item>
          );
        },
      },
    ],
    [
      'specification',
      {
        title: '物料规格',
        dataIndex: 'specification',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() => (
                <TextToolTip
                  text={_.get(getMaterialInfo(field.name, 'baseInfo'), 'specification')}
                  width={130}
                />
              )}
            </Form.Item>
          );
        },
      },
    ],
    [
      'amount',
      {
        title: (
          <BatchOperateTableHeader
            titleText="数量"
            rules={[
              {
                validator: numberMinMaxCheck({
                  min: 0,
                  minAllowEqual: false,
                  max: 10000000,
                  fieldName: '数量',
                }),
              },
            ]}
            popoverFieldComponent={<InputNumber style={{ width: '100%' }} />}
            batchOperation={(value) => handleBatchSetValue('amount', value)}
          />
        ),
        dataIndex: 'amount',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item noStyle dependencies={[[name, field.name, 'unitId']]}>
              {() => {
                const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
                  getMaterialInfo(field.name),
                  form.getFieldValue([name, field.name, 'unitId'])?.value,
                );

                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'amount']}
                    name={[field.name, 'amount']}
                    initialValue={getDefaultValue(field, 'amount', 1)}
                    rules={_.compact([
                      {
                        validator: numberMinMaxCheck({
                          min: 0,
                          minAllowEqual: false,
                          max: 10000000,
                          fieldName: '数量',
                        }),
                      },
                      enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
                    ])}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder="请输入"
                      min={0}
                      max={10000000}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ],
    [
      'unitId',
      {
        title: '单位',
        dataIndex: 'unitId',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item
              noStyle
              dependencies={[
                [name, field.name, 'amount'],
                [name, field.name, 'materialId'],
              ]}
            >
              {() => {
                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'unitId']}
                    name={[field.name, 'unitId']}
                    rules={[{ validator: validatorUnit(field.name) }]}
                  >
                    <Select options={getMaterialUnits(getMaterialInfo(field.name))} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ],
    [
      'outputProcessId',
      {
        title: '报工工序号',
        dataIndex: 'outputProcessId',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item style={{ marginBottom: '0' }} dependencies={['processRouteId']}>
              {() => {
                const { value: processRouteId } = form.getFieldValue('processRouteId') ?? {};

                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'outputProcessId']}
                    name={[field.name, 'outputProcessId']}
                  >
                    <SearchSelect
                      disabled={_.isNil(processRouteId)}
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      fetchType="processOfprocessRouting"
                      params={{ id: processRouteId }}
                      labelInValue
                      onChange={() => {
                        /** 工序变动时，清空报工控件 */
                        form.setFields([
                          { name: [name, field.name, 'outputSopControlId'], value: undefined },
                        ]);
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ],
    [
      'outputSopControlId',
      {
        title: '报工控件编号',
        dataIndex: 'outputSopControlId',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item noStyle dependencies={[[name, field.name, 'outputProcessId']]}>
              {() => {
                const outputProcessId = form.getFieldValue([name, field.name, 'outputProcessId']);
                const processRouteId = form.getFieldValue('processRouteId');

                const params = {
                  processRouteId: processRouteId?.value,
                  processNodeNum: outputProcessId?.label,
                  controlType: appEnum.Sop.ControlType.report,
                };

                return (
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    fieldKey={[field.fieldKey, 'outputSopControlId']}
                    name={[field.name, 'outputSopControlId']}
                  >
                    <SearchSelect
                      params={params}
                      fetchType="sopControlOfProcessRouting"
                      valuePath="code"
                      disabled={!outputProcessId}
                      placeholder={'请选择'}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ],
    [
      'singleWorkReportAmount',
      {
        title: (
          <BatchOperateTableHeader
            titleText="单次报工数量"
            rules={[
              {
                validator: numberMinMaxCheck({
                  min: 0,
                  minAllowEqual: false,
                  max: 10000000,
                  fieldName: '单次报工数量',
                }),
              },
            ]}
            popoverFieldComponent={<InputNumber min={0} max={10000000} style={{ width: '100%' }} />}
            batchOperation={(value) => handleBatchSetValue('singleWorkReportAmount', value)}
          />
        ),
        dataIndex: 'singleWorkReportAmount',
        width: 170,
        render: (text, field) => {
          return (
            <Form.Item noStyle dependencies={[[name, field.name, 'unitId']]}>
              {() => {
                const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
                  getMaterialInfo(field.name),
                  form.getFieldValue([name, field.name, 'unitId']),
                );

                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'singleWorkReportAmount']}
                    name={[field.name, 'singleWorkReportAmount']}
                    rules={_.compact([
                      {
                        validator: numberMinMaxCheck({
                          min: 0,
                          minAllowEqual: false,
                          max: 10000000,
                          fieldName: '单次报工数量',
                        }),
                      },
                      enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
                    ])}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder="请输入"
                      min={0}
                      max={10000000}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ],
    [
      'reportingMethods',
      {
        title: <BatchOperateTableHeader titleText="报工方式" required />,
        dataIndex: 'reportingMethods',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'reportingMethods']}
              name={[field.name, 'reportingMethods']}
              rules={[{ required: true, message: '报工方式不能为空' }]}
              initialValue={[1, 2, 3, 4]}
            >
              <Select
                placeholder="请选择报工方式"
                mode="multiple"
                options={appDict.bom.reportingMethods}
              />
            </Form.Item>
          );
        },
      },
    ],
    [
      'warehousing',
      {
        title: <BatchOperateTableHeader titleText="是否入库" required />,
        dataIndex: 'warehousing',
        width: 170,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'warehousing']}
              name={[field.name, 'warehousing']}
              initialValue={appEnum.Common.YN.yes}
            >
              <Radio.Group
                options={appDict.common.yn}
                onChange={(e) => {
                  if (e?.target?.value) {
                    form.setFields([
                      {
                        name: [name, field.name, 'autoWarehousingFlag'],
                        value: appEnum.Common.YN.no,
                      },
                    ]);
                  } else {
                    form.setFields([
                      { name: [name, field.name, 'autoWarehousingFlag'], value: null },
                    ]);
                  }
                }}
              />
            </Form.Item>
          );
        },
      },
    ],
    [
      'autoWarehousingFlag',
      {
        title: '自动入库',
        dataIndex: 'autoWarehousingFlag',
        width: 170,
        render: (text, field) => {
          return (
            <Form.Item noStyle dependencies={[[name, field.name, 'warehousing']]}>
              {() => {
                const disableFlag = !form.getFieldValue([name, field.name, 'warehousing']);

                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'autoWarehousingFlag']}
                    name={[field.name, 'autoWarehousingFlag']}
                    initialValue={appEnum.Common.YN.yes}
                  >
                    <Radio.Group disabled={disableFlag} options={appDict.common.yn} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ],
    [
      'id',
      {
        title: '',
        dataIndex: 'id',
        width: 1,
        render: (text, field) => {
          return (
            <Form.Item
              hidden
              name={[field.name, 'id']}
              fieldKey={[field.fieldKey, 'id']}
              style={{ marginBottom: '0' }}
            >
              <Input />
            </Form.Item>
          );
        },
      },
    ],
  ]);

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    const baseColumns: ColumnProps<any & FormListFieldData>[] = [];

    if (!_.isEmpty(resetColumns)) {
      // 默认必须有的列
      const mustColumnKey = ['id'];

      _.forEach(resetColumns, (column) => {
        if (_.has(column, 'render')) {
          baseColumns.push(column);
        } else {
          const dataIndex = _.get(column, 'dataIndex', '') as string;
          const baseCol = baseColumnsMap.get(dataIndex);

          if (!_.isEmpty(baseCol) && baseCol) {
            baseColumns.push(baseCol);
          }
        }
      });

      _.forEach(mustColumnKey, (key) => {
        const mustCol = baseColumnsMap.get(key);

        if (!_.isEmpty(mustCol) && mustCol) {
          baseColumns.push(mustCol);
        }
      });
    } else {
      baseColumns.push(...Array.from(baseColumnsMap.values()));
    }

    return baseColumns;
  };

  return (
    <BlSortFormList
      form={form}
      name={name}
      renderColumns={() => getColumns()}
      isNeedDrag={false}
      maxCount={10}
      fixedRowFn={fixedRowFn}
    />
  );
};
