import { StepTreeData } from './../types';
import { NEW_STEP_NUMBER_ID } from '../constants';
import lookup from 'src/dict';
import { message } from 'antd';

const newNodeIsSaved = (
  selectedKey: number,
  isFieldsTouched: boolean,
  node: StepTreeData | undefined,
) => {
  if (selectedKey === NEW_STEP_NUMBER_ID || isFieldsTouched) {
    message.error(`请先保存${lookup('sop.stepEntityType', node?.type ?? 0)}`);
    return false;
  }
  return true;
};

export default newNodeIsSaved;
