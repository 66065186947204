// 权限点字典，自行添加，通过脚本revertAuth.js生成前，请确认是最新的auth.csv
const auth = {
  ereport_view: 'eReport.view', // 查看菜单
  ereport_operrecord: 'eReport.operrecord', // 查看操作记录
  ereport_enable_disable: 'eReport.enable_disable', // 启停用
  ereport_edit: 'eReport.edit', // 编辑
  ereport_detail: 'eReport.detail', // 查看详情
  ereport_add: 'eReport.add', // 新建
  eReportMatch_view: 'eReportMatch.view', // 对象匹配规则查看菜单
  eReportMatch_operrecord: 'eReportMatch.operrecord', // 查看操作记录
  eReportMatch_enable_disable: 'eReportMatch.enable_disable', // 启停用
  eReportMatch_edit: 'eReportMatch.edit', // 编辑
  eReportMatch_detail: 'eReportMatch.detail', // 查看详情
  eReportMatch_add: 'eReportMatch.add', // 新建
  zone_import: 'Zone.import', // 导入
  zone_export: 'Zone.export', // 导出
  zone_edit: 'Zone.edit', // 编辑
  zone_detail: 'Zone.detail', // 查看详情
  zone_add: 'Zone.add', // 新建
  workcenter_view: 'WorkCenter.view', // 查看菜单
  workcenter_operrecord: 'WorkCenter.operrecord', // 查看操作记录
  workcenter_import: 'WorkCenter.import', // 导入
  workcenter_export: 'WorkCenter.export', // 导出
  workcenter_edit: 'WorkCenter.edit', // 编辑
  workcenter_detail: 'WorkCenter.detail', // 查看详情
  workcenter_add: 'WorkCenter.add', // 新建
  workcalendar_view: 'WorkCalendar.view', // 查看菜单
  workcalendar_operrecord: 'WorkCalendar.operrecord', // 查看操作记录
  workcalendar_export: 'WorkCalendar.export', // 导出
  workcalendar_import: 'WorkCalendar.import', // 导入
  workcalendar_edit: 'WorkCalendar.edit', // 编辑
  workcalendar_detail: 'WorkCalendar.detail', // 查看详情
  workcalendar_add: 'WorkCalendar.add', // 新建
  warehouse_view: 'Warehouse.view', // 查看菜单
  warehouse_operrecord: 'Warehouse.operrecord', // 查看操作记录
  warehouse_lock_unlock: 'Warehouse.lock_unlock', // 锁定解锁仓库
  warehouse_import: 'Warehouse.import', // 导入
  warehouse_export: 'Warehouse.export', // 导出
  warehouse_enable_disable: 'Warehouse.enable_disable', // 启停用
  warehouse_edit: 'Warehouse.edit', // 编辑
  warehouse_detail: 'Warehouse.detail', // 查看详情
  warehouse_add: 'Warehouse.add', // 新建
  user_view: 'User.view', // 查看菜单
  user_unlock: 'User.unlock', // 解锁
  user_reset_password: 'User.reset_password', // 重制密码
  user_operrecord: 'User.operrecord', // 查看操作记录
  user_import: 'User.import', // 导入
  user_export: 'User.export', // 导出
  user_enable_disable: 'User.enable_disable', // 启停用
  user_edit: 'User.edit', // 编辑
  user_detail: 'User.detail', // 查看详情
  user_add: 'User.add', // 新建
  user_active: 'User.active', // 激活
  unit_view: 'Unit.view', // 查看菜单
  unit_operrecord: 'Unit.operrecord', // 查看操作记录
  unit_import: 'Unit.import', // 导入
  unit_export: 'Unit.export', // 导出
  unit_enable_disable: 'Unit.enable_disable', // 启停用
  unit_edit: 'Unit.edit', // 编辑
  unit_detail: 'Unit.detail', // 查看详情
  unit_add: 'Unit.add', // 新建
  triggerrules_view: 'TriggerRules.view', // 查看菜单
  triggerrules_enable_disable: 'TriggerRules.enable_disable', // 启停用
  triggerrules_edit: 'TriggerRules.edit', // 编辑
  triggerrules_detail: 'TriggerRules.detail', // 查看详情
  triggerrules_add: 'TriggerRules.add', // 新建
  triggeraction_view: 'TriggerAction.view', // 查看菜单
  triggeraction_enable_disable: 'TriggerAction.enable_disable', // 启停用
  triggeraction_edit: 'TriggerAction.edit', // 编辑
  triggeraction_detail: 'TriggerAction.detail', // 查看详情
  triggeraction_add: 'TriggerAction.add', // 新建
  transferrecord_view: 'TransferRecord.view', // 查看菜单
  transferrecord_export: 'TransferRecord.export', // 调拨记录记录导出
  transferorder_view: 'TransferOrder.view', // 查看菜单
  transferorder_transfer_by_order: 'TransferOrder.transfer_by_order', // 按单调拨
  transferorder_transfer: 'TransferOrder.transfer', // 普通调拨
  transferorder_operrecord: 'TransferOrder.operrecord', // 查看操作记录
  transferorder_import: 'TransferOrder.import', // 导入
  transferorder_export: 'TransferOrder.export', // 导出
  transferorder_edit: 'TransferOrder.edit', // 编辑
  transferorder_dispatch: 'TransferOrder.dispatch', // 下发
  transferorder_detail: 'TransferOrder.detail', // 查看详情
  transferorder_close: 'TransferOrder.close', // 关闭
  transferorder_add: 'TransferOrder.add', // 新建
  tenant_tenant_info_set: 'Tenant.tenant_info_set', // 租户信息设置
  supplier_view: 'Supplier.view', // 查看菜单
  supplier_scm_import: 'Supplier.scm_import', // 导入供应链供应商
  supplier_scm_export: 'Supplier.scm_export', // 导出供应链供应商
  supplier_remove_register_supplier: 'Supplier.remove_register_supplier', // 解除注册供应商
  supplier_remove_register_material: 'Supplier.remove_register_material', // 解除注册物料
  supplier_register_supplier: 'Supplier.register_supplier', // 邀请注册供应商
  supplier_register_material: 'Supplier.register_material', // 邀请注册物料
  supplier_operrecord: 'Supplier.operrecord', // 查看操作记录
  supplier_import: 'Supplier.import', // 导入
  supplier_export: 'Supplier.export', // 导出
  supplier_enable_disable: 'Supplier.enable_disable', // 启停用
  supplier_edit: 'Supplier.edit', // 编辑
  supplier_detail: 'Supplier.detail', // 查看详情
  supplier_add: 'Supplier.add', // 新建
  storagelocation_view: 'StorageLocation.view', // 查看菜单
  storagelocation_operrecord: 'StorageLocation.operrecord', // 查看操作记录
  storagelocation_lock_unlock: 'StorageLocation.lock_unlock', // 锁定解锁仓位
  storagelocation_import: 'StorageLocation.import', // 导入
  storagelocation_export: 'StorageLocation.export', // 导出
  storagelocation_enable_disable: 'StorageLocation.enable_disable', // 启停用
  storagelocation_edit: 'StorageLocation.edit', // 编辑
  storagelocation_detail: 'StorageLocation.detail', // 查看详情
  storagelocation_add: 'StorageLocation.add', // 新建
  sourcelist_view: 'SourceList.view', // 查看菜单
  sourcelist_operrecord: 'SourceList.operrecord', // 查看操作记录
  sourcelist_import: 'SourceList.import', // 导入
  sourcelist_export: 'SourceList.export', // 导出
  sourcelist_enable_disable: 'SourceList.enable_disable', // 启停用
  sourcelist_edit: 'SourceList.edit', // 编辑
  sourcelist_detail: 'SourceList.detail', // 查看详情
  sourcelist_add: 'SourceList.add', // 新建
  shipmentsnote_view: 'ShipmentsNote.view', // 查看菜单
  shipmentsnote_operrecord: 'ShipmentsNote.operrecord', // 查看操作记录
  shipmentsnote_import: 'ShipmentsNote.import', // 导入
  shipmentsnote_finish: 'ShipmentsNote.finish', // 结束
  shipmentsnote_export: 'ShipmentsNote.export', // 导出
  shipmentsnote_edit: 'ShipmentsNote.edit', // 编辑
  shipmentsnote_dispatch: 'ShipmentsNote.dispatch', // 下发
  shipmentsnote_detail: 'ShipmentsNote.detail', // 查看详情
  shipmentsnote_deliver: 'ShipmentsNote.deliver', // 发货
  shipmentsnote_close: 'ShipmentsNote.close', // 关闭
  shipmentsnote_add: 'ShipmentsNote.add', // 新建
  shiftrule_view: 'ShiftRule.view', // 查看菜单
  shiftrule_operrecord: 'ShiftRule.operrecord', // 查看操作记录
  shiftrule_edit: 'ShiftRule.edit', // 编辑
  shiftrule_detail: 'ShiftRule.detail', // 查看详情
  shiftrule_add: 'ShiftRule.add', // 新建
  shift_view: 'Shift.view', // 查看菜单
  shift_operrecord: 'Shift.operrecord', // 查看操作记录
  shift_edit: 'Shift.edit', // 编辑
  shift_detail: 'Shift.detail', // 查看详情
  shift_add: 'Shift.add', // 新建
  salesorderreturn_view: 'SalesOrderReturn.view', // 查看菜单
  salesorderreturn_receipt: 'SalesOrderReturn.receipt', // 收货
  salesorderreturn_operrecord: 'SalesOrderReturn.operrecord', // 查看操作记录
  salesorderreturn_import: 'SalesOrderReturn.import', // 导入
  salesorderreturn_finish: 'SalesOrderReturn.finish', // 结束
  salesorderreturn_export: 'SalesOrderReturn.export', // 导出
  salesorderreturn_edit: 'SalesOrderReturn.edit', // 编辑
  salesorderreturn_detail: 'SalesOrderReturn.detail', // 查看详情
  salesorderreturn_close: 'SalesOrderReturn.close', // 关闭
  salesorderreturn_add: 'SalesOrderReturn.add', // 新建
  salesorder_view: 'SalesOrder.view', // 查看菜单
  salesorder_operrecord: 'SalesOrder.operrecord', // 查看操作记录
  salesorder_import: 'SalesOrder.import', // 导入
  salesorder_finish: 'SalesOrder.finish', // 结束
  salesorder_export: 'SalesOrder.export', // 导出
  salesorder_edit: 'SalesOrder.edit', // 编辑
  salesorder_detail: 'SalesOrder.detail', // 查看详情
  salesorder_add: 'SalesOrder.add', // 新建
  soptask_view: 'SOPTask.view', // 查看菜单
  soptask_start: 'SOPTask.start', // 开始
  soptask_remove: 'SOPTask.remove', // 删除
  soptask_pause_continue: 'SOPTask.pause_continue', // 暂停/继续SOP任务
  soptask_operrecord: 'SOPTask.operrecord', // 查看操作记录
  soptask_forced_finish: 'SOPTask.forced_finish', // 强制结束SOP任务
  soptask_finish: 'SOPTask.finish', // 结束
  soptask_exec_record: 'SOPTask.exec_record', // 查看SOP任务执行记录
  soptask_edit: 'SOPTask.edit', // 编辑
  soptask_dispatch: 'SOPTask.dispatch', // 下发
  soptask_detail: 'SOPTask.detail', // 查看详情
  soptask_cancel: 'SOPTask.cancel', // 取消
  soptask_add: 'SOPTask.add', // 新建
  sopscheme_view: 'SOPScheme.view', // 查看菜单
  sopscheme_step_remove: 'SOPScheme.step_remove', // 删除步骤/步骤组
  sopscheme_step_edit: 'SOPScheme.step_edit', // 编辑步骤/步骤组
  sopscheme_step_add: 'SOPScheme.step_add', // 新建步骤/步骤组
  sopscheme_remove: 'SOPScheme.remove', // 删除
  sopscheme_operrecord: 'SOPScheme.operrecord', // 查看操作记录
  sopscheme_enable_disable: 'SOPScheme.enable_disable', // 启停用
  sopscheme_edit: 'SOPScheme.edit', // 编辑
  sopscheme_detail: 'SOPScheme.detail', // 查看详情
  sopscheme_add: 'SOPScheme.add', // 新建
  routing_view: 'Routing.view', // 查看菜单
  routing_operrecord: 'Routing.operrecord', // 查看操作记录
  routing_import: 'Routing.import', // 导入
  routing_export: 'Routing.export', // 导出
  routing_enable_disable: 'Routing.enable_disable', // 启停用
  routing_edit: 'Routing.edit', // 编辑
  routing_detail: 'Routing.detail', // 查看详情
  routing_add: 'Routing.add', // 新建
  role_view: 'Role.view', // 查看菜单
  role_edit: 'Role.edit', // 编辑
  role_detail: 'Role.detail', // 查看详情
  role_config: 'Role.config', // 权限配置
  role_add: 'Role.add', // 新建
  role_enable_disable: 'Role.enable_disable', // 启停用
  returnauditnote_view: 'ReturnAuditNote.view', // 查看菜单
  returnauditnote_operrecord: 'ReturnAuditNote.operrecord', // 查看操作记录
  returnauditnote_detail: 'ReturnAuditNote.detail', // 查看详情
  returnauditnote_decline: 'ReturnAuditNote.decline', // 拒绝
  returnauditnote_accept: 'ReturnAuditNote.accept', // 接受
  resourcetype_view: 'ResourceType.view', // 查看菜单
  resourcetype_remove: 'ResourceType.remove', // 删除
  resourcetype_operrecord: 'ResourceType.operrecord', // 查看操作记录
  resourcetype_import: 'ResourceType.import', // 导入
  resourcetype_export: 'ResourceType.export', // 导出
  resourcetype_edit: 'ResourceType.edit', // 编辑
  resourcetype_detail: 'ResourceType.detail', // 查看详情
  resourcetype_add: 'ResourceType.add', // 新建
  resourceparameterrecord_view: 'ResourceParameterRecord.view', // 查看菜单
  resourceparameterrecord_import: 'ResourceParameterRecord.import', // 导入
  resourceparameterrecord_export: 'ResourceParameterRecord.export', // 导出
  resourceparametermonitoring_view: 'ResourceParameterMonitoring.view', // 查看菜单
  resourceparametermonitoring_export: 'ResourceParameterMonitoring.export', // 导出
  resourceparameter_view: 'ResourceParameter.view', // 查看菜单
  resourceparameter_remove: 'ResourceParameter.remove', // 删除
  resourceparameter_operrecord: 'ResourceParameter.operrecord', // 查看操作记录
  resourceparameter_import: 'ResourceParameter.import', // 导入
  resourceparameter_export: 'ResourceParameter.export', // 导出
  resourceparameter_enable_disable: 'ResourceParameter.enable_disable', // 启停用
  resourceparameter_edit: 'ResourceParameter.edit', // 编辑
  resourceparameter_detail: 'ResourceParameter.detail', // 查看详情
  resourceparameter_add: 'ResourceParameter.add', // 新建
  resourcelabel_view: 'ResourceLabel.view', // 查看菜单
  resourcelabel_remove: 'ResourceLabel.remove', // 删除
  resourcelabel_operrecord: 'ResourceLabel.operrecord', // 查看操作记录
  resourcelabel_import: 'ResourceLabel.import', // 导入
  resourcelabel_export: 'ResourceLabel.export', // 导出
  resourcelabel_edit: 'ResourceLabel.edit', // 编辑
  resourcelabel_detail: 'ResourceLabel.detail', // 查看详情
  resourcelabel_add: 'ResourceLabel.add', // 新建
  reporttemplate_view: 'ReportTemplate.view', // 查看菜单
  reporttemplate_edit: 'ReportTemplate.edit', // 编辑
  reporttemplate_add: 'ReportTemplate.add', // 新建
  /** 维保任务 */
  receivenote_view: 'ReceiveNote.view', // 查看菜单
  receivenote_receipt: 'ReceiveNote.receipt', // 收货
  receivenote_operrecord: 'ReceiveNote.operrecord', // 查看操作记录
  receivenote_finish: 'ReceiveNote.finish', // 结束
  receivenote_export: 'ReceiveNote.export', // 导出
  receivenote_edit: 'ReceiveNote.edit', // 编辑
  receivenote_detail: 'ReceiveNote.detail', // 查看详情
  receivenote_add: 'ReceiveNote.add', // 新建
  receivenote_close: 'ReceiveNote.close', // 新建
  receivenote_dispatch: 'ReceiveNote.dispatch', // 新建

  qualityinspectiontask_view: 'QualityInspectionTask.view', // 查看菜单
  qualityinspectiontask_search_add: 'QualityInspectionTask.search_add', // 搜索添加库存
  qualityinspectiontask_scan_add: 'QualityInspectionTask.scan_add', // 扫码添加库存
  qualityinspectiontask_receive: 'QualityInspectionTask.receive', // 领取检验任务
  qualityinspectiontask_edit: 'QualityInspectionTask.edit', // 编辑
  qualityinspectiontask_detail: 'QualityInspectionTask.detail', // 查看详情
  qualityinspectiontask_cancel: 'QualityInspectionTask.cancel', // 取消
  qualityinspectiontask_add: 'QualityInspectionTask.add', // 新建
  qualityinspectiontask_print: 'QualityInspectionTask.print', // 打印
  qualityinspectionscheme_view: 'QualityInspectionScheme.view', // 查看菜单
  qualityinspectionscheme_operrecord: 'QualityInspectionScheme.operrecord', // 查看操作记录
  qualityinspectionscheme_import: 'QualityInspectionScheme.import', // 导入
  qualityinspectionscheme_export: 'QualityInspectionScheme.export', // 导出
  qualityinspectionscheme_enable_disable: 'QualityInspectionScheme.enable_disable', // 启停用
  qualityinspectionscheme_edit: 'QualityInspectionScheme.edit', // 编辑
  qualityinspectionscheme_detail: 'QualityInspectionScheme.detail', // 查看详情
  qualityinspectionscheme_add: 'QualityInspectionScheme.add', // 新建
  qualityadjustmentrecord_view: 'QualityAdjustmentRecord.view', // 查看菜单
  qualityadjustmentrecord_select_adjust: 'QualityAdjustmentRecord.select_adjust', // 选择调整质量
  qualityadjustmentrecord_scan_adjust: 'QualityAdjustmentRecord.scan_adjust', // 扫码调整质量
  qualityadjustmentrecord_quality_adjust: 'QualityAdjustmentRecord.quality_adjust', // 质量调整
  qualityadjustmentrecord_detail: 'QualityAdjustmentRecord.detail', // 查看详情
  purchasereturnorder_view: 'PurchaseReturnOrder.view', // 查看菜单
  purchasereturnorder_return: 'PurchaseReturnOrder.return', // 退货
  purchasereturnorder_operrecord: 'PurchaseReturnOrder.operrecord', // 查看操作记录
  purchasereturnorder_import: 'PurchaseReturnOrder.import', // 导入
  purchasereturnorder_finish: 'PurchaseReturnOrder.finish', // 结束
  purchasereturnorder_export: 'PurchaseReturnOrder.export', // 导出
  purchasereturnorder_edit: 'PurchaseReturnOrder.edit', // 编辑
  purchasereturnorder_dispatch: 'PurchaseReturnOrder.dispatch', // 下发
  purchasereturnorder_detail: 'PurchaseReturnOrder.detail', // 查看详情
  purchasereturnorder_close: 'PurchaseReturnOrder.close', // 关闭
  purchasereturnorder_add: 'PurchaseReturnOrder.add', // 新建
  purchaseorder_workbench: 'PurchaseOrder.workbench', // 采购工作台
  purchaseorder_view: 'PurchaseOrder.view', // 查看菜单
  purchaseorder_restart: 'PurchaseOrder.restart', // 重启
  purchaseorder_purchase_progress_report: 'PurchaseOrder.purchase_progress_report', // 采购进度报表
  purchaseorder_operrecord: 'PurchaseOrder.operrecord', // 查看操作记录
  purchaseorder_import: 'PurchaseOrder.import', // 导入
  purchaseorder_finish: 'PurchaseOrder.finish', // 结束
  purchaseorder_export: 'PurchaseOrder.export', // 导出
  purchaseorder_edit: 'PurchaseOrder.edit', // 编辑
  purchaseorder_dispatch: 'PurchaseOrder.dispatch', // 下发
  purchaseorder_detail: 'PurchaseOrder.detail', // 查看详情
  purchaseorder_close: 'PurchaseOrder.close', // 关闭
  purchaseorder_add: 'PurchaseOrder.add', // 新建
  purchaseauditnote_view: 'PurchaseAuditNote.view', // 查看菜单
  purchaseauditnote_operrecord: 'PurchaseAuditNote.operrecord', // 查看操作记录
  purchaseauditnote_detail: 'PurchaseAuditNote.detail', // 查看详情
  purchaseauditnote_decline: 'PurchaseAuditNote.decline', // 拒绝
  purchaseauditnote_accept: 'PurchaseAuditNote.accept', // 接受
  progressreportrecord_progress_report_adjust: 'ProgressReportRecord.progress_report_adjust', // 报工调整
  progressreportrecord_view: 'ProgressReportRecord.view', // 查看菜单
  progressreportrecord_export: 'ProgressReportRecord.export', // 导出
  productionorder_view: 'ProductionOrder.view', // 查看菜单
  productionorder_stop: 'ProductionOrder.stop', // 暂停
  productionorder_set_produce_order: 'ProductionOrder.set_produce_order', // 设置生产工单
  productionorder_progress_finish: 'ProductionOrder.progress_finish', // 完工生产工单
  productionorder_operrecord: 'ProductionOrder.operrecord', // 查看操作记录
  productionorder_import: 'ProductionOrder.import', // 导入
  productionorder_export: 'ProductionOrder.export', // 导出
  productionorder_edit: 'ProductionOrder.edit', // 编辑
  productionorder_dispatch: 'ProductionOrder.dispatch', // 下发
  productionorder_detail: 'ProductionOrder.detail', // 查看详情
  productionorder_continue: 'ProductionOrder.continue', // 继续
  productionorder_close: 'ProductionOrder.close', // 关闭
  productionorder_add: 'ProductionOrder.add', // 新建
  producetask_warehouse_inbound: 'ProduceTask.warehouse_inbound', // 仓储入库
  producetask_view: 'ProduceTask.view', // 查看菜单
  producetask_stop: 'ProduceTask.stop', // 暂停
  producetask_standard_progress: 'ProduceTask.standard_progress', // 标准流程
  producetask_progress_start: 'ProduceTask.progress_start', // 开工
  producetask_progress_report: 'ProduceTask.progress_report', // 报工
  producetask_progress_finish: 'ProduceTask.progress_finish', // 完工
  producetask_feed: 'ProduceTask.feed', // 投料
  producetask_edit: 'ProduceTask.edit', // 编辑
  producetask_detail: 'ProduceTask.detail', // 查看详情
  producetask_continue: 'ProduceTask.continue', // 继续
  producetask_cancel: 'ProduceTask.cancel', // 取消
  producetask_add_report: 'ProduceTask.add_report', // 新建报告
  producetask_set: 'ProduceTask.set', // 设置
  process_view: 'Process.view', // 查看菜单
  process_operrecord: 'Process.operrecord', // 查看操作记录
  process_import: 'Process.import', // 导入
  process_export: 'Process.export', // 导出
  process_edit: 'Process.edit', // 编辑
  process_detail: 'Process.detail', // 查看详情
  process_add: 'Process.add', // 新建
  pickorder_view: 'PickOrder.view', // 查看菜单
  pickorder_operrecord: 'PickOrder.operrecord', // 查看操作记录
  pickorder_edit: 'PickOrder.edit', // 编辑
  pickorder_detail: 'PickOrder.detail', // 查看详情
  pickorder_close: 'PickOrder.close', // 关闭
  pickorder_add: 'PickOrder.add', // 新建
  pickorder_export: 'PickOrder.export', // 导出
  pickorder_import: 'PickOrder.import', // 导入
  pickorder_complete: 'PickOrder.pick_complete', // 齐套
  outboundrecord_view: 'OutboundRecord.view', // 查看菜单
  outboundrecord_export: 'OutboundRecord.export', // 出库记录导出
  outboundorder_view: 'OutboundOrder.view', // 查看菜单
  outboundorder_outbound_reversal: 'OutboundOrder.outbound_reversal', // 出库冲销
  outboundorder_outbound_by_order: 'OutboundOrder.outbound_by_order', // 按单出库
  outboundorder_outbound: 'OutboundOrder.outbound', // 普通出库
  outboundorder_operrecord: 'OutboundOrder.operrecord', // 查看操作记录
  outboundorder_import: 'OutboundOrder.import', // 导入
  outboundorder_export: 'OutboundOrder.export', // 导出
  outboundorder_edit: 'OutboundOrder.edit', // 编辑
  outboundorder_dispatch: 'OutboundOrder.dispatch', // 下发
  outboundorder_detail: 'OutboundOrder.detail', // 查看详情
  outboundorder_close: 'OutboundOrder.close', // 关闭
  outboundorder_add: 'OutboundOrder.add', // 新建
  operationlog_view: 'OperationLog.view', // 查看菜单
  operationlog_detail: 'OperationLog.detail', // 查看详情
  object_view_custom_objects: 'Object.view_custom_objects', // 查看自定义对象列表
  object_view: 'Object.view', // 查看菜单
  object_enable_disable: 'Object.enable_disable', // 启停用
  object_edit: 'Object.edit', // 编辑
  object_detail: 'Object.detail', // 查看详情
  object_add: 'Object.add', // 新增
  numberingrule_view: 'NumberingRule.view', // 查看菜单
  numberingrule_operrecord: 'NumberingRule.operrecord', // 查看操作记录
  numberingrule_enable_disable: 'NumberingRule.enable_disable', // 启停用
  numberingrule_edit: 'NumberingRule.edit', // 编辑
  numberingrule_detail: 'NumberingRule.detail', // 查看详情
  numberingrule_add: 'NumberingRule.add', // 新建
  messagetemplate_view: 'MessageTemplate.view', // 查看菜单
  messagetemplate_enable_disable: 'MessageTemplate.enable_disable', // 启停用
  messagetemplate_edit: 'MessageTemplate.edit', // 编辑
  messagetemplate_detail: 'MessageTemplate.detail', // 查看详情
  messagetemplate_add: 'MessageTemplate.add', // 新建
  materialfeedrecord_retreat: 'MaterialFeedRecord.retreat', // 投料回撤
  materialfeedrecord_view: 'MaterialFeedRecord.view', // 查看菜单
  materialfeedrecord_export: 'MaterialFeedRecord.export', // 导出
  materialfeedrecord_detail: 'MaterialFeedRecord.detail', // 查看详情
  materialretractrecord_view: 'MaterialRetractRecord.view', // 查看菜单
  materialretractrecord_export: 'MaterialRetractRecord.export', // 导出
  materialcategory_view: 'MaterialCategory.view', // 查看菜单
  materialcategory_operrecord: 'MaterialCategory.operrecord', // 查看操作记录
  materialcategory_import: 'MaterialCategory.import', // 导入
  materialcategory_export: 'MaterialCategory.export', // 导出
  materialcategory_edit: 'MaterialCategory.edit', // 编辑
  materialcategory_add: 'MaterialCategory.add', // 新建
  materialattributeitem_view: 'MaterialAttributeItem.view', // 查看菜单
  materialattributeitem_operrecord: 'MaterialAttributeItem.operrecord', // 查看操作记录
  materialattributeitem_import: 'MaterialAttributeItem.import', // 导入
  materialattributeitem_export: 'MaterialAttributeItem.export', // 导出
  materialattributeitem_edit: 'MaterialAttributeItem.edit', // 编辑
  materialattributeitem_detail: 'MaterialAttributeItem.detail', // 查看详情
  materialattributeitem_add: 'MaterialAttributeItem.add', // 新建
  material_view: 'Material.view', // 查看菜单
  material_operrecord: 'Material.operrecord', // 查看操作记录
  material_import: 'Material.import', // 导入
  material_export: 'Material.export', // 导出
  material_enable_disable: 'Material.enable_disable', // 启停用
  material_edit: 'Material.edit', // 编辑
  material_detail: 'Material.detail', // 查看详情
  material_add: 'Material.add', // 新建
  location_view: 'Location.view', // 查看菜单
  location_operrecord: 'Location.operrecord', // 查看操作记录
  location_import: 'Location.import', // 导入
  location_export: 'Location.export', // 导出
  location_enable_disable: 'Location.enable_disable', // 启停用
  location_edit: 'Location.edit', // 编辑
  location_detail: 'Location.detail', // 查看详情
  location_add: 'Location.add', // 新建
  inventoryelementchangelog_view: 'InventoryElementChangeLog.view', // 查看菜单
  inventoryelement_view: 'InventoryElement.view', // 查看菜单
  inventoryelement_map_detail: 'InventoryElement.map_detail', // 查看库存地图
  inventoryelement_import: 'InventoryElement.import', // 导入
  inventoryelement_export: 'InventoryElement.export', // 导出
  inventoryelement_detail: 'InventoryElement.detail', // 查看详情
  inventoryelement_view_batch_inventory: 'InventoryElement.view_batch_inventory', // 查看批次库存
  inventoryelement_batch_print: 'InventoryElement.print',
  inspectionitemcategory_view: 'InspectionItemCategory.view', // 查看菜单
  inspectionitemcategory_operrecord: 'InspectionItemCategory.operrecord', // 查看操作记录
  inspectionitemcategory_import: 'InspectionItemCategory.import', // 导入
  inspectionitemcategory_export: 'InspectionItemCategory.export', // 导出
  inspectionitemcategory_edit: 'InspectionItemCategory.edit', // 编辑
  inspectionitemcategory_detail: 'InspectionItemCategory.detail', // 查看详情
  inspectionitemcategory_add: 'InspectionItemCategory.add', // 新建
  inspectionitem_view: 'InspectionItem.view', // 查看菜单
  inspectionitem_operrecord: 'InspectionItem.operrecord', // 查看操作记录
  inspectionitem_import: 'InspectionItem.import', // 导入
  inspectionitem_export: 'InspectionItem.export', // 导出
  inspectionitem_edit: 'InspectionItem.edit', // 编辑
  inspectionitem_detail: 'InspectionItem.detail', // 查看详情
  inspectionitem_add: 'InspectionItem.add', // 新建
  inboundrecord_view: 'InboundRecord.view', // 查看菜单
  inboundrecord_export: 'InboundRecord.export', // 入库记录导出
  inboundorder_view: 'InboundOrder.view', // 查看菜单
  inboundorder_reversal: 'InboundOrder.reversal', // 入库冲销
  inboundorder_operrecord: 'InboundOrder.operrecord', // 查看操作记录
  inboundorder_inbound_by_order: 'InboundOrder.inbound_by_order', // 按单入库
  inboundorder_inbound: 'InboundOrder.inbound', // 普通入库
  inboundorder_import: 'InboundOrder.import', // 导入
  inboundorder_export: 'InboundOrder.export', // 导出
  inboundorder_edit: 'InboundOrder.edit', // 编辑
  inboundorder_dispatch: 'InboundOrder.dispatch', // 下发
  inboundorder_detail: 'InboundOrder.detail', // 查看详情
  inboundorder_close: 'InboundOrder.close', // 关闭
  inboundorder_add: 'InboundOrder.add', // 新建
  identificationcode_view: 'IdentificationCode.view', // 查看菜单
  field_enable_disable: 'Field.enable_disable', // 启停用
  field_edit: 'Field.edit', // 编辑
  field_detail: 'Field.detail', // 查看详情
  field_add: 'Field.add', // 新建
  equipment_view: 'Equipment.view', // 查看菜单
  equipment_scrap: 'Equipment.scrap', // 报废
  equipment_operrecord: 'Equipment.operrecord', // 查看操作记录
  equipment_import: 'Equipment.import', // 导入
  equipment_export: 'Equipment.export', // 导出
  equipment_enable_disable: 'Equipment.enable_disable', // 启停用
  equipment_edit: 'Equipment.edit', // 编辑
  equipment_detail: 'Equipment.detail', // 查看详情
  equipment_add: 'Equipment.add', // 新建
  energyinstrument_view: 'EnergyInstrument.view', // 查看菜单
  energyinstrument_scrap: 'EnergyInstrument.scrap', // 报废
  energyinstrument_remove: 'EnergyInstrument.remove', // 删除
  energyinstrument_operrecord: 'EnergyInstrument.operrecord', // 查看操作记录
  energyinstrument_import: 'EnergyInstrument.import', // 导入
  energyinstrument_export: 'EnergyInstrument.export', // 导出
  energyinstrument_enable_disable: 'EnergyInstrument.enable_disable', // 启停用
  energyinstrument_edit: 'EnergyInstrument.edit', // 编辑
  energyinstrument_detail: 'EnergyInstrument.detail', // 查看详情
  energyinstrument_add: 'EnergyInstrument.add', // 新建
  electronicsignature_view: 'ElectronicSignature.view', // 查看菜单
  electronicsignature_enable_disable: 'ElectronicSignature.enable_disable', // 启停用
  electronicsignature_edit: 'ElectronicSignature.edit', // 编辑
  electronicsignature_detail: 'ElectronicSignature.detail', // 查看详情
  electronicsignature_add: 'ElectronicSignature.add', // 新建
  document_view_file: 'Document.view_file', // 预览文件
  document_down_file: 'Document.down_file', // 下载文件
  department_view: 'Department.view', // 查看菜单
  department_import: 'Department.import', // 导入
  department_export: 'Department.export', // 导出
  department_enable_disable: 'Department.enable_disable', // 启停用
  department_edit: 'Department.edit', // 编辑
  department_detail: 'Department.detail', // 查看详情
  department_add: 'Department.add', // 新建
  defectlevel_view: 'DefectLevel.view', // 查看菜单
  defectlevel_operrecord: 'DefectLevel.operrecord', // 查看操作记录
  defectlevel_import: 'DefectLevel.import', // 导入
  defectlevel_export: 'DefectLevel.export', // 导出
  defectlevel_edit: 'DefectLevel.edit', // 编辑
  defectlevel_detail: 'DefectLevel.detail', // 查看详情
  defectlevel_add: 'DefectLevel.add', // 新建
  dataset_view: 'Dataset.view', // 查看菜单
  dataset_operrecord: 'Dataset.operrecord', // 查看操作记录
  dataset_enable_disable: 'Dataset.enable_disable', // 启停用
  dataset_edit: 'Dataset.edit', // 编辑
  dataset_detail: 'Dataset.detail', // 查看详情
  dataset_add: 'Dataset.add', // 新建
  customizedaql_view: 'CustomizedAQL.view', // 查看菜单
  customizedaql_standard: 'CustomizedAQL.standard', // 查看AQL国标
  customizedaql_operrecord: 'CustomizedAQL.operrecord', // 查看操作记录
  customizedaql_enable_disable: 'CustomizedAQL.enable_disable', // 启停用
  customizedaql_edit: 'CustomizedAQL.edit', // 编辑
  customizedaql_detail: 'CustomizedAQL.detail', // 查看详情
  customizedaql_add: 'CustomizedAQL.add', // 新建
  customerregisteredaudit_reg_list: 'CustomerRegisteredAudit.reg_list', // 查看注册邀请列表
  customerregisteredaudit_operrecord: 'CustomerRegisteredAudit.operrecord', // 查看操作记录
  customerregisteredaudit_decline_reg: 'CustomerRegisteredAudit.decline_reg', // 拒绝注册
  customerregisteredaudit_accept_reg: 'CustomerRegisteredAudit.accept_reg', // 接受注册
  customermaterialregisteredaudit_reg_list_material:
    'CustomerMaterialRegisteredAudit.reg_list_material', // 查看物料注册邀请列表
  customermaterialregisteredaudit_operrecord: 'CustomerMaterialRegisteredAudit.operrecord', // 查看操作记录
  customermaterialregisteredaudit_decline_reg_material:
    'CustomerMaterialRegisteredAudit.decline_reg_material', // 拒绝物料注册
  customermaterialregisteredaudit_accept_reg_material:
    'CustomerMaterialRegisteredAudit.accept_reg_material', // 接受物料注册
  customer_view: 'Customer.view', // 查看菜单
  customer_operrecord: 'Customer.operrecord', // 查看操作记录
  customer_import: 'Customer.import', // 导入
  customer_export: 'Customer.export', // 导出
  customer_enable_disable: 'Customer.enable_disable', // 启停用
  customer_edit: 'Customer.edit', // 编辑
  customer_detail: 'Customer.detail', // 查看详情
  customer_add: 'Customer.add', // 新建
  causeofdefect_view: 'CauseOfDefect.view', // 查看菜单
  causeofdefect_operrecord: 'CauseOfDefect.operrecord', // 查看操作记录
  causeofdefect_import: 'CauseOfDefect.import', // 导入
  causeofdefect_export: 'CauseOfDefect.export', // 导出
  causeofdefect_edit: 'CauseOfDefect.edit', // 编辑
  causeofdefect_add: 'CauseOfDefect.add', // 新建
  causeofdefect_detail: 'CauseOfDefect.detail',
  batchno_view: 'BatchNo.view', // 查看菜单
  batchno_import: 'BatchNo.import', // 导入
  batchno_export: 'BatchNo.export', // 导出
  batchno_detail: 'BatchNo.detail', // 查看详情
  batchno_add: 'BatchNo.add', // 新建
  batchno_edit: 'BatchNo.edit', //编辑
  bom_view: 'BOM.view', // 查看菜单
  bom_operrecord: 'BOM.operrecord', // 查看操作记录
  bom_multi_lev_detail: 'BOM.multi_lev_detail', // 查看多层物料清单
  bom_import: 'BOM.import', // 导入
  bom_export: 'BOM.export', // 导出
  bom_enable_disable: 'BOM.enable_disable', // 启停用
  bom_edit: 'BOM.edit', // 编辑
  bom_detail: 'BOM.detail', // 查看详情
  bom_add: 'BOM.add', // 新建
  bom_view_list_coproduct: 'BOM.view_list_coproduct', // 查看多产物列表
  bom_view_list_subItemmaterial: 'BOM.view_list_subItemmaterial', // 查看多产物列表
  attradjustrecord_view: 'AttrAdjustRecord.view', // 查看菜单
  attradjustrecord_unbind: 'AttrAdjustRecord.unbind', // 标识码解绑
  attradjustrecord_split: 'AttrAdjustRecord.split', // 标识码拆分
  attradjustrecord_bind: 'AttrAdjustRecord.bind', // 标识码绑定
  attradjustrecord_attribute_change: 'AttrAdjustRecord.attribute_change', // 属性变更
  amountadjustrecord_view: 'AmountAdjustRecord.view', // 查看菜单
  amountadjustrecord_inventory_adjust: 'AmountAdjustRecord.inventory_adjust', // 库存调整
  alternativeplan_view: 'AlternativePlan.view', // 查看菜单
  alternativeplan_remove: 'AlternativePlan.remove', // 删除
  alternativeplan_operrecord: 'AlternativePlan.operrecord', // 查看操作记录
  alternativeplan_edit: 'AlternativePlan.edit', // 编辑
  alternativeplan_detail: 'AlternativePlan.detail', // 查看详情
  alternativeplan_add: 'AlternativePlan.add', // 新建
  alternativeplan_import: 'AlternativePlan.import', // 导入
  alternativeplan_export: 'AlternativePlan.export', // 导出
  planOrder_view: 'PlanOrder.view', // 查看菜单
  planOrder_add: 'PlanOrder.add', // 新建
  planOrder_detail: 'PlanOrder.detail', // 查看详情
  planOrder_edit: 'PlanOrder.edit', // 编辑
  planOrder_confirm: 'PlanOrder.planned_confirm', // 确认
  planOrder_launch: 'PlanOrder.launch', // 投放
  planOrder_close: 'PlanOrder.close', // 关闭
  planOrder_split: 'PlanOrder.split', // 拆分

  planOrder_set: 'PlanOrder.set', // 设置计划订单
  planOrder_operrecord: 'PlanOrder.operrecord', // 查看操作记录

  planOrder_import: 'PlanOrder.import', // 导入
  planOrder_export: 'PlanOrder.export', // 导出
  approvalScheme_view: 'ApprovalScheme.view', // 查看菜单
  approvalScheme_edit: 'ApprovalScheme.edit', // 编辑
  approvalScheme_detail: 'ApprovalScheme.detail', // 查看详情
  approvalScheme_add: 'ApprovalScheme.add', // 新建
  approvalScheme_enable_disable: 'ApprovalScheme.enable_disable', // 启停用
  approvalScheme_remove: 'ApprovalScheme.remove', // 删除
  approvalScheme_operrecord: 'ApprovalScheme.operrecord', // 查看操作记录
  approvalInstance_view: 'ApprovalInstance.view', // 查看菜单
  approvalInstance_detail: 'ApprovalInstance.detail', // 查看详情
  approvalInstance_operrecord: 'ApprovalInstance.operrecord', // 审批单查看操作记录
  approvalTask_operrecord: 'ApprovalTask.operrecord', // 审批任务查看操作记录
  /** 业务类型 */
  businessType_view: 'BusinessType.view', // 查看菜单
  businessType_add: 'BusinessType.add', // 新建
  businessType_edit: 'BusinessType.edit', // 编辑
  businessType_enable_disable: 'BusinessType.enable_disable', // 启停用
  businessType_remove: 'BusinessType.remove', // 删除
  /** 自定义布局 */
  layout_add: 'Layout.add', // 新建
  layout_assign_layout: 'Layout.assign_layout', // 分配布局
  layout_edit: 'Layout.edit', // 编辑
  layout_remove: 'Layout.remove', // 删除
  layout_view: 'Layout.view', // 查看菜单
  layout_detail: 'Layout.detail', // 查看详情
  /** 维保方案 */
  maintenanceCase_view: 'MaintenanceCase.view', // 查看菜单
  maintenanceCase_detail: 'MaintenanceCase.detail', // 查看详情
  maintenanceCase_add: 'MaintenanceCase.add', // 新建
  maintenanceCase_edit: 'MaintenanceCase.edit', // 编辑
  maintenanceCase_remove: 'MaintenanceCase.remove', // 删除
  maintenanceCase_enable_disable: 'MaintenanceCase.enable_disable', // 启停用
  maintenanceCase_operrecord: 'MaintenanceCase.operrecord', // 查看操作记录
  maintenanceCase_import: 'MaintenanceCase.import', // 导入
  maintenanceCase_export: 'MaintenanceCase.export', // 导出
  /** 维保任务 */
  maintenanceTask_view: 'MaintenanceTask.view', // 查看菜单
  maintenanceTask_detail: 'MaintenanceTask.detail', // 查看详情
  maintenanceTask_add: 'MaintenanceTask.add', // 新建
  maintenanceTask_edit: 'MaintenanceTask.edit', // 编辑
  maintenanceTask_cancel: 'MaintenanceTask.cancel', // 删除
  maintenanceTask_export: 'MaintenanceTask.export', // 导出
  maintenanceTask_pause_record: 'MaintenanceTask.pause_record', // 查看暂停记录
  maintenanceTask_operrecord: 'MaintenanceTask.operrecord', // 查看操作记录
  deliveryScheduleNote_view: 'DeliveryScheduleNote.view', // 查看菜单
  deliveryScheduleNote_add: 'DeliveryScheduleNote.add', // 新建
  deliveryScheduleNote_edit: 'DeliveryScheduleNote.edit', // 编辑
  deliveryScheduleNote_detail: 'DeliveryScheduleNote.detail', // 查看详情
  deliveryScheduleNote_dispatch: 'DeliveryScheduleNote.dispatch', // 下发
  deliveryScheduleNote_finish: 'DeliveryScheduleNote.finish', // 结束
  deliveryScheduleNote_close: 'DeliveryScheduleNote.close', // 关闭
  deliveryScheduleNote_import: 'DeliveryScheduleNote.import', // 导入
  deliveryScheduleNote_export: 'DeliveryScheduleNote.export', // 导出
  deliveryScheduleNote_operrecord: 'DeliveryScheduleNote.operrecord', // 操作记录
  /** 币种主数据 */
  currency_view: 'Currency.view', // 查看菜单
  /** 故障记录 */
  resourceMalfunctionRecord_view: 'ResourceMalfunctionRecord.view',
  resourceMalfunctionRecord_detail: 'ResourceMalfunctionRecord.detail',
  resourceMalfunctionRecord_add: 'ResourceMalfunctionRecord.add',
  resourceMalfunctionRecord_edit: 'ResourceMalfunctionRecord.edit',
  resourceMalfunctionRecord_operrecord: 'ResourceMalfunctionRecord.operrecord',
  resourceMalfunctionRecord_export: 'ResourceMalfunctionRecord.export',
  /** 协同交货计划 */
  CustomerDeliveryScheduleNote_view: 'CustomerDeliveryScheduleNote.view', // 查看菜单
  CustomerDeliveryScheduleNote_detail: 'CustomerDeliveryScheduleNote.detail', //查看详情
  CustomerDeliveryScheduleNote_operrecord: 'CustomerDeliveryScheduleNote.operrecord', // 查看操作记录
  CustomerDeliveryScheduleNote_confirm: 'CustomerDeliveryScheduleNote.confirm', // 确认
  CustomerDeliveryScheduleNote_return: 'CustomerDeliveryScheduleNote.return', // 退回
  /** 定制化报表 */
  Cus_eReport_detail: 'Cus_eReport.detail', // 查看详情
  Cus_eReport_edit: 'Cus_eReport.edit', // 编辑
  Cus_eReport_view: 'Cus_eReport.view', // 查看菜单
  Cus_eReport_view_custom_report: 'Cus_eReport.view_custom_report', // 查看定制化报表菜单
  collectionrule_view: 'DataAcquisitionRule.view', // 查看菜单
  collectionrule_detail: 'DataAcquisitionRule.detail', // 查看详情
  collectionrule_add: 'DataAcquisitionRule.add', // 新建
  collectionrule_edit: 'DataAcquisitionRule.edit', // 编辑
  collectionrule_operrecord: 'DataAcquisitionRule.operrecord', // 查看操作记录
  /**
   * 租户许可权限点
   * 排程 T-Sc-ForMN-L
   * */
  TScForMNL: 'T-Sc-ForMN-L',
  /** 智能分析平台 */
  IntelligentAnalysisPlatform_view: 'IntelligentAnalysisPlatform.view', // 查看菜单
  /** 维修任务 */
  repairTask_view: 'RepairTask.view', // 查看菜单
  repairTask_detail: 'RepairTask.detail', // 查看详情
  repairTask_add: 'RepairTask.add', // 新建
  repairTask_edit: 'RepairTask.edit', // 编辑
  repairTask_cancel: 'RepairTask.cancel', // 删除
  repairTask_pause_record: 'RepairTask.pause_record', // 查看暂停记录
  repairTask_operrecord: 'RepairTask.operrecord', // 查看操作记录
  repairTask_export: 'RepairTask.export', // 导出
  /** 维修方案 */
  repairCase_view: 'RepairCase.view', // 查看菜单
  repairCase_add: 'RepairCase.add', // 新建
  repairCase_detail: 'RepairCase.detail', // 查看详情
  repairCase_edit: 'RepairCase.edit', // 编辑
  repairCase_enable_disable: 'RepairCase.enable_disable', // 启停用
  repairCase_import: 'RepairCase.import', // 导入
  repairCase_operrecord: 'RepairCase.operrecord', // 查看操作记录
  repairCase_export: 'RepairCase.export', // 导出
  /** 自定义菜单 */
  customMenu_add: 'CustomMenu.add',
  customMenu_edit: 'CustomMenu.edit',
  customMenu_delete: 'CustomMenu.delete',
  customMenu_manage_menu_display: 'CustomMenu.manage_menu_display',
  eReportCase_view: 'eReportCase.view', // 电子单据查看菜单
  eReportCase_detail: 'eReportCase.detail', // 电子单据查看详情
  eReportCase_add: 'eReportCase.add', // 新建电子单据
  eReportCase_approve: 'eReportCase.approve', // 提交电子单据审批
  eReportCase_print: 'eReportCase.print', // 打印电子单据
  trackTrace_view: 'TrackTrace.view',
  /** 计划运算 */
  MaterialPlanConfigura_view: 'MaterialPlanConfigura.view', // 查看菜单
  MaterialPlanConfigura_add: 'MaterialPlanConfigura.add', // 新建
  MaterialPlanConfigura_import: 'MaterialPlanConfigura.import', // 导入
  MaterialPlanConfigura_edit: 'MaterialPlanConfigura.edit', // 调整物料计划配置
  MaterialPlanConfigura_planning_operation: 'MaterialPlanConfigura.planning_operation', // 计划运算
  MaterialPlanConfigura_operrecord: 'MaterialPlanConfigura.operrecord', // 查看计划运算日志
  MaterialPlanConfigura_remove: 'MaterialPlanConfigura.remove', // 移除
  /** 预留 */
  PlanReserved_detail: 'PlanReserved.detail', //查询预留
  PlanReserved_release: 'PlanReserved.release', //释放预留
  PlanReserved_edit: 'PlanReserved.edit', //编辑预留
};

export default auth;
