/**
 * @enum 物料清单(BOM)业务模块枚举值
 */

/**
 * 领料方式
 */
export enum PickMode {
  /**
   * 按需领料
   */
  demandPick = 1,
  /**
   * 不领料
   */
  notPick,
}

/**
 * 投料管控 投料上下限类型
 */
export enum InputBoundType {
  /**
   * 不管控
   */
  notControl = 1,
  /**
   * 比例
   */
  percentage,
  /**
   * 固定值
   */
  constant,
}

/**
 * 投料管控 是否必投
 */
export enum FeedingControl {
  /**
   * 非必投
   */
  no,
  /**
   * 必投
   */
  yes,
}

/**
 * 报工方式
 */
export enum ReportingMethodsEnum {
  /**
   * 扫码报工-合格
   */
  scanQualified = 1,
  /**
   * 记录报工-合格
   */
  noteQualified,
  /**
   * 扫码报工-不合格
   */
  scanUnqualified,
  /**
   * 记录报工-不合格
   */
  noteUnqualified,
}
