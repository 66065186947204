import { useEffect, useState } from 'react';
import { Checkbox, Form, FormInstance, Input, FormItemProps } from 'antd';
import { Rule } from 'antd/lib/form';

interface Props extends FormItemProps {
  form: FormInstance;
  fieldCode: string; // 对象下的字段
  namePath?: (string | number)[]; // 针对多层路径下的嵌套字段的code编号 为[]就是第一层
  checkboxLayout?: 'horizontal' | 'vertical'; // checkbox 方向
  defaultValue?: string; // 默认值
  isNumberRuleConfig?: boolean;
  rules: Rule[];
  isSonObj?: boolean;
  disabled?: boolean;
}

const defaultNamePath: Props['namePath'] = [];

export default function UseTextNumberRulesStandardHook({
  form,
  fieldCode,
  rules,
  namePath = defaultNamePath,
  checkboxLayout = 'horizontal',
  defaultValue,
  isNumberRuleConfig,
  isSonObj,
  disabled,
}: Props) {
  const [checkState, setCheckState] = useState<boolean>(false); // 是否勾选复选框
  const isNumberRuleActuallyConfiPath = [...namePath!, 'isNumberRuleActuallyConfig'];
  const fieldValue = [...namePath!, 'fieldValue'];
  const [isSonObjRule, setIsSonObjRule] = useState<boolean>(false);

  useEffect(() => {
    setCheckState(true);
    form?.setFields([{ name: isNumberRuleActuallyConfiPath, value: 1 }]);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: checkboxLayout === 'horizontal' ? 'row' : 'column',
        marginBottom: -20,
      }}
    >
      <Form.Item
        style={{ flex: 1 }}
        validateFirst
        name={fieldValue}
        rules={!checkState && !isSonObjRule ? [...rules] : []}
      >
        {checkState && <Input placeholder={'保存后按规则生成'} disabled={checkState || disabled} />}
        {!checkState && (
          <Input
            disabled={checkState || !isNumberRuleConfig || disabled}
            onChange={(e) => {
              if (isSonObj && e.target.value) {
                setIsSonObjRule(true);
              } else {
                setIsSonObjRule(false);
              }
              form?.setFields([{ name: fieldValue, value: e.target.value }]);
            }}
          />
        )}
      </Form.Item>
      {isNumberRuleConfig && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: checkboxLayout === 'vertical' ? 'flex-start' : 'flex-end',
            marginTop: checkboxLayout === 'vertical' ? 22 : 0,
            width: 100,
            marginBottom: 20,
          }}
        >
          <Checkbox
            disabled={disabled}
            checked={checkState}
            onChange={(e: any) => {
              setCheckState(e?.target?.checked);
              if (e?.target?.checked) {
                // 选中状态
                form?.setFields([{ name: isNumberRuleActuallyConfiPath, value: 1 }]);
                form?.setFields([{ name: fieldValue, value: '' }]);
              } else {
                form?.setFields([{ name: isNumberRuleActuallyConfiPath, value: 0 }]);
              }
            }}
          >
            编号规则
          </Checkbox>
        </div>
      )}
    </div>
  );
}
