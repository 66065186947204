/**
 *  @control 附件
 */
import { FC } from 'react';
import { Button } from 'antd';
import { BlIcon } from 'src/components';
import type { PreviewCompProps } from '../../types';

export const File: FC<PreviewCompProps> = ({ inputPrompt }) => {
  return (
    <Button
      style={{ pointerEvents: 'none' }}
      tabIndex={-1}
      icon={<BlIcon type="iconshangchuan1" />}
    >
      {inputPrompt || '上传附件'}
    </Button>
  );
};
