import React from 'react';
import { Modal, Table, ModalFuncProps } from 'antd';

export interface EndListMessage extends ModalFuncProps {
  data: any[];
  columns: any[];
  title: string;
  cancelText?: string;
  okText?: string;
  operateName?: string;
  successCount?: number;
  failCount?: number;
  width?: number;
  rowKey?: string;
  message?: string;
}

const TABLE_MAX_HEIGHT = 300;
const MODAL_DEFAULT_WIDTH = 700;
/**
 * 多条错误数据弹窗，内有table展示每条数据具体的错误原因
 * @param props
 * title       弹窗title
 * data        错误数据列表
 * columns     错误数据columns
 * operateName 数据操作名称用于展示如： 启用失败数
 * successCount 操作成功数据条数
 * failCount   操作失败数据条数
 * width       弹窗宽度
 */
const showEndListMessage = (props: EndListMessage) => {
  const {
    title,
    data,
    columns,
    operateName,
    successCount,
    failCount,
    width,
    rowKey = 'id',
    message,
    ...rest
  } = props;

  return Modal.confirm({
    title,
    width: width || MODAL_DEFAULT_WIDTH,
    centered: true,
    closable: true,
    content: (
      <div>
        <p>
          {message ??
            `${operateName ?? ''}成功数：${successCount ?? 0}，${operateName ?? ''}失败数：${
              failCount ?? 0
            }${failCount ? '，具体失败明细如下：' : ''}`}
        </p>
        {failCount ? (
          <Table
            dataSource={data}
            columns={columns}
            pagination={false}
            scroll={{ y: TABLE_MAX_HEIGHT }}
            rowKey={rowKey}
          />
        ) : null}
      </div>
    ),
    ...rest,
  });
};

export { showEndListMessage };
