import React, { useState, useEffect } from 'react';
import { SingleOperationLogComponent } from 'src/components';
import { DetailLayout } from 'layout';
import { Form, message, Modal } from 'antd';
import { replaceSign } from 'src/utils/constants';
import _ from 'lodash';
import authDict from 'src/utils/auth';
import { fetchAltDelete, fetchAltGet } from 'src/api/ytt/med-domain/alternative-plan';
import { RouteChildrenProps } from 'react-router';
import SuitedBomDetailTable from 'src/page/knowledgeManagement/engineerData/alternativePlan/components/detailTable/SuitedBomDetailTable';
import OriginalAlternativeMaterialDetailTable from 'src/page/knowledgeManagement/engineerData/alternativePlan/components/detailTable/OriginalAlternativeMaterialDetailTable';
import AlternativeMaterialDetailTable from 'src/page/knowledgeManagement/engineerData/alternativePlan/components/detailTable/AlternativeMaterialDetailTable';
import { renderUser } from 'src/page/share/renderUser';
import _Time from 'src/utils/dataTypes/time';
import { ENGINEER_PATH } from 'src/page/knowledgeManagement/engineerData/navigation';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import { fetchBomGet } from 'src/api/ytt/med-domain/bom';
import { getMaterialAttrs } from 'src/entity/material';
import {
  DELAY_GET_LIST_MS_GAP,
  pauseSomeTime,
} from 'src/page/knowledgeManagement/engineerData/alternativePlan/helper/utils';

interface DetailPageProps extends RouteChildrenProps<{ id: string }> {}

const DetailPage = (props: DetailPageProps) => {
  const { history } = props;
  const { match } = props;
  const id = Number(match?.params?.id);
  const [loading, setLoading] = useState(true);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState({} as any);
  const [bomDataSource, setBomDataSource] = useState([] as any);
  const toAlternativePlanList = (id?: number) => {
    if (!id) return;
    history.push(`${ENGINEER_PATH}/alternativePlan/`);
  };
  const toAlternativePlanEdit = (id?: number) => {
    history.push(`${ENGINEER_PATH}/alternativePlan/edit/${id}`);
  };
  const toAlternativePlanCopy = (id?: number) => {
    history.push(`${ENGINEER_PATH}/alternativePlan/copy/${id}`);
  };
  const [delForm] = Form.useForm();
  const handleDelete = async (ids: any[], onSuccess?: () => void) => {
    // 删除
    try {
      const res = await fetchAltDelete({
        ids: ids.map((i) => Number(i)),
        // @ts-ignore
        reason: delForm.getFieldValue('reason'),
      });
      const { data } = res;

      if (res.code !== 200) {
        message.error(res?.message);
        return;
      }

      if (!data) return;
      const deleteCount = ids.length;
      const { successCount, failDetails } = data;

      if (deleteCount === successCount) {
        message.success('删除成功');
        if (typeof onSuccess === 'function') {
          await pauseSomeTime(DELAY_GET_LIST_MS_GAP);
          onSuccess();
        }
      } else if (deleteCount === 1) {
        Modal.error({
          title: '删除失败！',
          content: failDetails[0].failReason,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      if (!id) {
        return;
      }
      const { data } = await fetchAltGet({ id });

      // 后端返回的被替代物料替代物料没有 lineNo 序号了，这里我们手动处理添加一下这个字段
      const newData = {
        ...data,
        alternativeMaterials:
          data &&
          data.alternativeMaterials &&
          data?.alternativeMaterials.map((item, index) => ({
            ...item,
            lineNo: index + 1,
          })),
        originalAlternativeMaterials:
          data &&
          data.originalAlternativeMaterials &&
          data.originalAlternativeMaterials.map((item, index) => ({
            ...item,
            lineNo: index + 1,
          })),
      };

      setLoading(false);
      setDataSource(newData);
      // 根据 bomId 获取适用的物料清单
      if (newData?.bomId) {
        fetchBomGet({ id: _.toNumber(newData?.bomId) }).then((res) => {
          if (res.code === 200) {
            const { data } = res;
            const code = data?.material?.baseInfo?.code ?? '';
            const version = data?.version ?? '';
            const name = data?.material?.baseInfo?.name ?? '';
            const attributes = getMaterialAttrs(data?.material);
            const category = data?.material?.category?.name ?? '';
            // @ts-ignore
            const specification = data?.material?.baseInfo?.specification ?? ''; // 新增的物料规格

            setBomDataSource([
              {
                key: code,
                code,
                name,
                version,
                attributes,
                category,
                specification,
              },
            ]);
          }
        });
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData().then();
  }, [id]);
  const baseInfo = {
    title: '基本信息',
    items: [
      { label: '替代方案编号', dataIndex: 'code' },
      { label: '替代方案名称', dataIndex: 'name' },
      {
        label: '所属部门',
        dataIndex: 'ownedDepartmentName',
      },
      {
        label: '替代策略',
        dataIndex: 'substitutionStrategy',
        render: (value: number) => (value === 1 ? '混用替代' : '整批替代'),
      },
      // {
      //   label: '替代组投料管控',
      //   dataIndex: '---',
      //   render: () => ---,
      // },
      {
        label: '指定物料清单',
        dataIndex: 'specificBom',
        render: (value: number) => (value === 0 ? '否' : '是'),
      },
      {
        label: '备注',
        dataIndex: 'remark',
      },
    ],
  };

  const suitedBomInfo = {
    title: '适用物料清单',
    items: [
      {
        label: '',
        dataIndex: 'bomId',
        render: () => <SuitedBomDetailTable bomDataSource={bomDataSource} />,
      },
    ],
  };

  const materialInfo = {
    title: '物料信息',
    column: 1,
    items: [
      {
        label: '',
        dataIndex: 'originalAlternativeMaterials',
        isFullLine: true,
        render: (value: []) => (
          <div style={{ paddingLeft: 24 }}>
            <p>被替代物料：</p>
            <OriginalAlternativeMaterialDetailTable originalAlternativeMaterials={value} />
          </div>
        ),
      },
      {
        label: '',
        dataIndex: 'alternativeMaterials',
        isFullLine: true,
        render: (value: []) => (
          <div style={{ paddingLeft: 24 }}>
            <p>替代物料：</p>
            <AlternativeMaterialDetailTable alternativeMaterials={value} />
          </div>
        ),
      },
    ],
  };

  const otherInfo = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: renderUser,
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: (createdAt: Date) => _Time.format(createdAt) ?? replaceSign,
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: renderUser,
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: (createdAt: Date) => _Time.format(createdAt) ?? replaceSign,
      },
    ],
  };
  const baseMenu = _.compact([
    {
      key: 'operate',
      title: '操作记录',
      auth: authDict.alternativeplan_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
    },
    {
      key: 'copy',
      title: '复制',
      auth: authDict.alternativeplan_add,
      onClick: () => {
        toAlternativePlanCopy(id);
      },
    },
    {
      key: 'delete',
      title: '删除',
      auth: authDict.alternativeplan_remove,
      onClick: async () => {
        BLDelConfirm(
          '删除后将无法恢复，确认删除？',
          delForm,
          () => {
            return handleDelete([id], () => {
              toAlternativePlanList(id);
            });
          },
          () => {},
          'reason',
        );
      },
    },
    {
      key: 'edit',
      title: '编辑',
      auth: authDict.alternativeplan_edit,
      onClick: () => {
        toAlternativePlanEdit(id);
      },
    },
  ]);

  return (
    <>
      <DetailLayout
        title="替代方案详情"
        info={_.compact([baseInfo, dataSource?.bomId && suitedBomInfo, materialInfo, otherInfo])}
        dataSource={dataSource}
        baseMenu={baseMenu}
        loading={loading}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={id}
          objectCode={'AlternativePlan'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailPage;
