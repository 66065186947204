import { useState, useRef, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FilterItem, RecordListLayout } from 'layout';
import moment from 'moment';
import { gcTime, gcObject, fieldTypeList } from 'utils';
import { BOUND_PATH as basePath } from '../index';
import {
  fetchOutboundOrderIssue,
  fetchOutboundOrderList,
  fetchOutboundOrderClose,
} from 'src/api/ytt/inventory-domain/boundOrder';
import type { OutboundTableRowType as TableRowType } from '../type';
import lookup, { appEnum, appDict } from 'src/dict';
import { BlIcon, LinkTooltip, SearchSelect, SingleOperationLogComponent } from 'src/components';
import { patchRequest } from '../utils';
import _ from 'lodash';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { renderUser } from 'src/page/share/renderUser';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import { replaceSign } from 'src/utils/constants';
import authDict from 'src/utils/auth';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { injectCustomFieldColumns, useCustomFieldCombinedData } from 'src/components/customField';

interface Props extends RouteComponentProps {}

const { StatusEnum } = appEnum.Bound;
const OutBoundList = (props: Props) => {
  const { history } = props;
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const refreshCountRef = useRef(0);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [detailId, setDetailId] = useState<number>(0);
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.outboundOrder);
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const refreshTable = () => {
    // 刷新列表页
    refreshCountRef.current++;
    setRefreshMarker(refreshCountRef.current);
  };

  const requestFn = async (params: any) => {
    const res = await fetchOutboundOrderList(params);

    return res;
  };

  const toDetail = (id?: number) => {
    if (!id) return;
    history.push(`${basePath}/outBound/${id}/detail`);
  };
  const toCreate = () => {
    history.push(`${basePath}/outBound/create`);
  };
  const toEdit = (id?: number) => {
    history.push(`${basePath}/outBound/${id}/edit`);
  };
  const getIdVersionList = (ids: (string | number)[]) => ids.map((id) => ({ id }));

  const dataColumns: any[] = [
    {
      title: '出库单编号',
      dataIndex: 'code',
      isFilter: true,
      sorter: true,
      width: 150,
      render: (text: string, record: any, index: number, config: any) => (
        <LinkTooltip
          to={`${basePath}/outBound/${record?.id}/detail`}
          text={text}
          width={config.contentWidth}
          auth={authDict.outboundorder_detail}
        />
      ),
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '业务类型',
      dataIndex: 'bizType',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      render: lookup('bound.OutBoundType'),
      selectProps: {
        placeholder: '请选择',
        mode: 'multiple',
        options: appDict.bound.OutBoundType,
      },
    },
    {
      title: '状态',
      dataIndex: 'bizStatus',
      type: fieldTypeList.select,
      isFilter: true,
      sorter: true,
      width: 150,
      render: lookup('bound.Status'),
      selectProps: {
        placeholder: '请选择',
        mode: 'multiple',
        options: appDict.bound.Status,
      },
    },
    {
      title: '仓库',
      dataIndex: 'warehouseIds',
      isFilter: true,
      width: 150,
      render: (value: any, record: TableRowType) => record?.wareHouse?.name,
      renderItem: <SearchSelect labelInValue mode="multiple" fetchType="warehouse" />,
    },
    {
      title: '客户',
      dataIndex: 'customer',
      isFilter: true,
      sorter: true,
      width: 150,
      render: (value: any) => value?.name,
      renderItem: <SearchSelect mode="multiple" labelInValue fetchType="customer" />,
    },
    {
      title: '计划出库时间',
      dataIndex: 'planTime',
      type: fieldTypeList.date,
      sorter: true,
      isFilter: true,
      width: 180,
      render: (value: number) => {
        if (!value) {
          return replaceSign;
        }
        return moment(value).format('YYYY-MM-DD HH:mm');
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      isFilter: true,
      width: 150,
      render: renderUser,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择创建人" isMultiple isSelectUser />
      ),
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      width: 180,
      render: (text: any, record: TableRowType) =>
        moment(record.createTime).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  const config = {
    rowKey: (record: TableRowType) => String(record.id),
    // columns: dataColumns,
    columns: injectCustomFieldColumns({
      columns: dataColumns, // 原本的列
      customFields, // 自定义字段信息
      objectCode: OBJECT_OF_CODE.outboundOrder, // 从对象code
      type: 'detail', // 使用类型
      inTable: true,
      columnProps: {
        to: (record: any) => `${basePath}/outBound/${record?.id}/detail`,
        auth: authDict.outboundorder_detail,
        width: 150,
      },
    }),
    getOperationList: (record: TableRowType) => {
      const { bizStatus } = record;

      return [
        {
          title: '查看',
          auth: authDict.outboundorder_detail,
          onClick: () => toDetail(record.id),
        },
        {
          title: '编辑',
          auth: authDict.outboundorder_edit,
          disabled: bizStatus === StatusEnum.yiguanbi,
          onClick: () => toEdit(record.id),
        },
        {
          title: '下发',
          auth: authDict.outboundorder_dispatch,
          disabled: bizStatus !== StatusEnum.xinjian,
          onClick: () => {
            if (!record?.id) {
              return;
            }
            patchRequest(
              fetchOutboundOrderIssue,
              getIdVersionList([record?.id]),
              '下发',
              refreshTable,
            );
          },
        },
        {
          title: '关闭',
          auth: authDict.outboundorder_close,
          disabled: bizStatus !== StatusEnum.xinjian && bizStatus !== StatusEnum.zhixing,
          onClick: () => {
            if (!record?.id) {
              return;
            }
            patchRequest(
              fetchOutboundOrderClose,
              getIdVersionList([record?.id]),
              '关闭',
              refreshTable,
            );
          },
        },
        {
          title: '操作记录',
          auth: authDict.outboundorder_operrecord,
          onClick: () => {
            if (!record.id) return;
            setDetailId(record.id);
            setVisibleLog(true);
          },
        },
      ];
    },
    batchMenu: [
      {
        title: '下发',
        auth: authDict.outboundorder_dispatch,
        onClick: (onSuccess: any, onFail: any) => {
          patchRequest(
            fetchOutboundOrderIssue,
            getIdVersionList(selectedRowKeys),
            '下发',
            onSuccess,
            onFail,
          );
        },
      },
      {
        title: '关闭',
        auth: authDict.outboundorder_close,
        onClick: (onSuccess: any, onFail: any) => {
          patchRequest(
            fetchOutboundOrderClose,
            getIdVersionList(selectedRowKeys),
            '关闭',
            onSuccess,
            onFail,
          );
        },
      },
    ],
    mainMenu: [
      {
        title: '导出',
        showExport: true,
        auth: authDict.outboundorder_export,
        icon: <BlIcon type="icondaochu" />,
        onClick: () =>
          openModal({
            optType: 'export',
            businessType: BUSINESS_TYPE.outboundOrder,
          }),
      },
      {
        title: '新建出库单',
        showExport: true,
        icon: <BlIcon type="iconxinjiantianjia" />,
        auth: authDict.outboundorder_add,
        onClick: toCreate,
        items: [
          {
            title: '导入',
            showExport: true,
            auth: authDict.outboundorder_import,
            onClick: () =>
              openModal({
                optType: 'import',
                businessType: BUSINESS_TYPE.outboundOrder,
              }),
          },
        ],
      },
    ],
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((columns: any): FilterItem => {
        const filter = {
          label: columns.title,
          name: columns.dataIndex,
          type: columns.type,
          ...columns,
        };

        return filter;
      }),
    formatDataToFormDisplay: (filterData: any) => {
      const { createdAt, planTime, ...rest } = filterData;

      const result = {
        ...rest,
        createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
        planTime: gcTime.formatRangeTimeToMoment(planTime || []),
      };

      return gcObject.filterEmptyProperty(result);
    },
    formatDataToQuery: (param: any) => {
      const {
        creator,
        createdAt,
        warehouseIds,
        planTime,
        departmentId,
        customer,
        sorter,
        ...rest
      } = param;
      const [createdAtFrom, createdAtTill] = gcTime.formatTimeRangeUnix(createdAt);
      const [planAtFrom, planAtTill] = gcTime.formatTimeRangeUnix(planTime);

      const options = {
        ...rest,
        departmentId: departmentId?.[0]?.value,
        createdAtFrom,
        createdAtTill,
        planAtFrom,
        planAtTill,
        creator: creator?.length ? _.map(creator, 'value') : null,
        warehouseIds: _.map(warehouseIds, 'value'),
        customerIds: _.map(customer, 'value'),
        sorter: sorter?.map((sort: { field: string; order: string }) => ({
          ...sort,
          field: sort?.field === 'customer' ? 'customerId' : sort?.field,
        })),
      };

      const relParams = gcObject.filterEmptyProperty(options);

      dispatch.excelImport.updateBusinessData({ businessData: relParams });
      return relParams;
    },
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        placeholder={'请输入编号关键字'}
        useIndex={false}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey) => {
          setSelectRowKeys(selectKey);
        }}
        refreshMarker={refreshMarker}
        requestFn={requestFn}
        configcacheKey="outBoundModule"
        {...config}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={'OutboundOrder'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default OutBoundList;
