import _ from 'lodash';
import { LabeledValue } from 'src/page/share';
import { CustomField } from '../types';
// import { CustomFieldGetListByObjectCode, LabelInValueSelectorProps, ShowFieldObj } from '../types';

export const customField2Lv = (
  showField: CustomField[] | undefined,
  fieldConfig: { keyName: string; labelName: string },
): LabeledValue[] => {
  const { keyName, labelName } = fieldConfig;

  const newData = showField?.map((item) => {
    return {
      label: item[labelName],
      value: item[keyName],
      key: item[keyName],
    };
  });

  return newData ?? [];
};

export const lv2CustomField = (
  lv: LabeledValue[] | undefined,
  fieldConfig: { keyName: string; labelName: string },
): CustomField[] => {
  if (!fieldConfig) {
    return lv ?? [];
  }
  const { keyName, labelName } = fieldConfig;

  if (!lv || !_.isArray(lv)) return [];

  const newLv = lv.map((item) => {
    return {
      [keyName]: item.value,
      [labelName]: _.toString(item.label),
    };
  });

  return newLv ?? [];
};
