/**
 * @description 触发事件 form表单
 */

import { useEffect, useState } from 'react';
import { Input, Select, Form, Checkbox, message, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { DataFormLayoutInfoBlock, DataFormLayout } from 'layout';
import { BlIcon, NumberRulesStandardHook, SearchSelect } from 'components';
import {
  fetchTriggerActionEdit,
  fetchTriggerActionCreate,
} from 'src/api/ytt/trigger-domain/triggerAction';
import { ObjectCode, CRUD } from 'src/dict/common';

import { appDict, appEnum } from 'src/dict';
import {
  InstanceSourceTypeEnum,
  ActionTypeEnum,
  ActionValueTypeEnum,
} from 'src/dict/triggerAction';
import { validateChineseNotSupported } from 'src/utils/formValidators';

import { formatDataToRequest } from '../utils/formatDataToRequest';
import ObjectConfigEditTable from './ObjectConfigEditTable';
import RuleConditionEditTable from 'src/page/organization/triggerRule/components/RuleConditionEditTable';
import RuleConditionDetailTable from 'src/page/organization/triggerRule/components/RuleConditionEditTable/detail';
import FieldIdCascader from '../../triggerRule/share/FieldIdCascader';
import { getFieldValueOfForm } from 'src/page/organization/triggerRule/utils/tools';
import {
  fetchTriggerRuleDetail,
  fetchTriggerRuleTreeByCode,
} from 'src/api/ytt/trigger-domain/triggerRule';
import { fetchTriggerActionCheckActionSupportObject } from 'src/api/ytt/trigger-domain';
import { fetchStandardBizObjectListPage } from 'src/api/ytt/metadata-domain';
import {
  formatObjectTreeAddDisable,
  formatObjectTreeToOptions,
  formatTriggerRuleConditionToForm,
} from 'src/page/organization/triggerAction/helper/utils';
import fetchFieldOptions from '../helper/fetchFieldOptions';
import { CascaderOption, BaseOption } from '../constant';
import _ from 'lodash';
import styles from './styles.module.scss';

const { actionTypeOptions } = appDict.triggerAction;
const LABEL_WIDTH = 120;
const INPUT_AREA_WIDTH = 440;

interface TriggerActionFormProps {
  title: string;
  loading: boolean;
  onFinish: () => void;
  onCancel: () => void;
  initialValues?: any;
  editId?: number;
  type: 'edit' | 'copy' | 'create';
}

const TriggerActionForm: React.FC<TriggerActionFormProps> = ({
  loading,
  title,
  type,
  initialValues,
  onFinish,
  onCancel,
  editId,
}) => {
  const [form] = Form.useForm();
  const [keepOn, setkeepOn] = useState<boolean>(false);
  const [refreshMarker] = useState<number>(0);
  const [fieldOptions, setFieldOptions] = useState<BaseOption[]>();
  const [triggerRuleDetail, setTriggerRuleDetail] = useState<any>();
  const [cascaderOptions, setCascaderOptions] = useState<CascaderOption[]>();
  /** 对象属性值配置表格加载中 */
  const [editTableLoading, setEditTableLoading] = useState<boolean>(false);
  /** 关联对象options加载中 */
  const [relatedLoading, setRelatedLoading] = useState<boolean>(false);

  useEffect(() => {
    if (type !== CRUD.create && initialValues) {
      form.setFieldsValue(initialValues);
      let objectCode;
      let ruleId;

      if (typeof initialValues?.objectCode?.value === 'string') {
        objectCode = JSON.parse(initialValues?.objectCode?.value)?.objectCode;
      }
      if (typeof initialValues?.triggerRuleId?.value === 'string') {
        ruleId = JSON.parse(initialValues?.triggerRuleId?.value).value;
      }

      fetchFieldOptions(objectCode, initialValues?.actionType)
        .then((res) => {
          const [, fieldIdOptions] = res;

          setFieldOptions(fieldIdOptions);
          setEditTableLoading(true);
        })
        .finally(() => setEditTableLoading(false));

      fecthTriggerRuleInfoAndSet(ruleId);
    }
  }, [initialValues]);

  useEffect(() => {
    fecthTRuleRelatedObjInfoAndSet(triggerRuleDetail?.objectCode);
  }, [triggerRuleDetail?.objectCode]);

  /** 设置触发规则的相关信息  */
  const fecthTriggerRuleInfoAndSet = (ruleId: number) => {
    if (_.isNil(ruleId)) return;
    fetchTriggerRuleDetail({ ruleId }).then((res) => {
      const ruleDetail = res?.data;

      setTriggerRuleDetail(ruleDetail);
    });
  };

  /** 获取关联对象的options */
  const fecthTRuleRelatedObjInfoAndSet = (objectCode: string) => {
    if (!objectCode) return;
    setRelatedLoading(true);
    setCascaderOptions([]);
    fetchTriggerRuleTreeByCode({
      code: objectCode,
      depth: 4,
    })
      .then((res) => {
        if (!res?.data) return;
        const cascaderOptions = formatObjectTreeToOptions(res.data);

        setCascaderOptions(cascaderOptions);
      })
      .finally(() => setRelatedLoading(false));
  };

  /** 设置「对象属性值配置」的 默认值 及 字段选项 */
  const fetchFieldInfoAndSet = (objectCode: string, actionType: number) => {
    setEditTableLoading(true);
    fetchFieldOptions(objectCode, actionType)
      .then((res) => {
        const [details, fieldIdOptions] = res;

        form.setFields([{ name: 'details', value: [] }]);
        form.setFields([{ name: 'details', value: details }]);
        setFieldOptions(fieldIdOptions);
      })
      .finally(() => setEditTableLoading(false));
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    column: 1,
    items: [
      ...NumberRulesStandardHook({
        label: '事件编号',
        form,
        edit: type === CRUD.edit,
        objectCode: ObjectCode.triggerAction,
        fieldCode: 'code',
        name: 'code',
        refreshMarker,
        required: true,
        rules: [
          { max: 100, message: '不可超过100个字符' },
          { validator: validateChineseNotSupported() },
        ],
      }),
      {
        label: '事件名称',
        name: 'name',
        rules: [
          { required: true, message: '请输入事件名称' },
          { max: 100, message: '不可超过100个字符' },
        ],
        validateFirst: true,
        render: () => <Input placeholder="请输入事件名称" />,
      },
      {
        label: '触发规则',
        name: 'triggerRuleId',
        rules: [{ required: true, message: '请选择触发规则' }],
        render: () => {
          return (
            <SearchSelect
              fetchType="triggerRuleByItem"
              placeholder={'请选择触发规则'}
              // 仅支持「手动创建」，「系统预置」的规则，且必须在「启用中」状态
              params={{
                status: appEnum.Common.UsageStatus.enabled,
                sourceType: [
                  appEnum.TriggerAction.ActionSourceType.manual,
                  appEnum.TriggerAction.ActionSourceType.preset,
                ],
              }}
              onChange={async (data: any) => {
                form.setFieldsValue({
                  objectCode: null,
                  objectFieldPath: null,
                  instanceSourceType: null,
                  details: [],
                });
                // 选择一条触发规则后，这里发请求获取触发规则的详细信息
                // 目的是为了拿到其中的 objectCode，它与其他字段息息相关获取更详细的对象信息
                if (!(typeof data?.value === 'string')) return;
                const value = JSON.parse(data?.value);
                const ruleId = value?.ruleId;

                fecthTriggerRuleInfoAndSet(ruleId);
              }}
            />
          );
        },
      },
      {
        label: '备注',
        name: 'remark',
        isFullLine: true,
        rules: [{ max: 1000, message: '不超过1000字符' }],
        render: () => <TextArea placeholder="请输入备注" />,
      },
    ],
  };

  const configInfo: DataFormLayoutInfoBlock = {
    title: '事件配置',
    column: 1,
    items: [
      {
        label: '事件类型',
        name: 'actionType',
        rules: [{ required: true, message: '请选择事件类型' }],
        initialValue: ActionTypeEnum.createRecord,
        render: () => (
          <Select
            placeholder={'请选择事件类型'}
            options={actionTypeOptions}
            onChange={() => {
              form.setFieldsValue({
                objectFieldPath: null,
                objectCode: null,
                details: [],
                instanceSourceType: null,
              });
            }}
          />
        ),
      },
      {
        shouldUpdate: (prevValues, curValues) => {
          return (
            prevValues.actionType !== curValues.actionType ||
            prevValues.triggerRuleId !== curValues.triggerRuleId
          );
        },
        // ● 更新对象来源
        //   ○ 控件展示依赖
        //     ■ 事件类型：决定隐藏和展示
        //     ■ 触发规则：决定展示“请选择触发规则”还是正常要显示控件
        //   ○ 控件数据依赖
        //     ■ 事件类型：当选择“更新记录”时，不动，当选择“新建记录”时，把数据重置为空
        wrapperCol: { flex: 'none' },
        style: { marginBottom: '0' },
        render: () => () => {
          const isUpdateRecordActionType =
            form.getFieldValue('actionType') === ActionTypeEnum.updateRecord;
          const hasSelectTriggerRule = form.getFieldValue('triggerRuleId');
          const triggerEventTypeHasEdit =
            triggerRuleDetail?.bizEventTrigger?.triggerEventType?.some(
              (item: any) => item?.code === 'edit',
            );

          if (!isUpdateRecordActionType) return null;
          return (
            <Form.Item
              labelCol={{ flex: `${LABEL_WIDTH}px` }}
              wrapperCol={{ flex: `${INPUT_AREA_WIDTH}px` }}
              style={{ marginBottom: '24px' }}
              label={
                <div style={{ whiteSpace: 'nowrap' }}>
                  <span>更新对象来源</span>
                  <Tooltip title="当触发规则的触发动作包含「编辑」时，不支持选择与触发规则业务对象相同的触发事件的业务对象">
                    <BlIcon type="iconwentizhiyinbangzhu" className="bl-title-icon" />
                  </Tooltip>
                </div>
              }
              name={hasSelectTriggerRule ? 'instanceSourceType' : undefined}
              rules={[{ required: true, message: '请选择更新对象来源' }]}
            >
              {hasSelectTriggerRule ? (
                <Select
                  placeholder="请选择"
                  options={appDict.triggerAction.instanceSourceTypeOptions.map((item) => {
                    // 当触发规则中的触发动作包含编辑时，业务对象不能是其本身了，因此将"触发规则业务对象"置灰
                    if (
                      triggerEventTypeHasEdit &&
                      item.value ===
                        appEnum.TriggerAction.InstanceSourceTypeEnum.triggerRuleBizObject
                    ) {
                      return {
                        ...item,
                        disabled: true,
                      };
                    }
                    return item;
                  })}
                  onChange={async (instanceSourceType) => {
                    form.setFieldsValue({
                      objectFieldPath: null,
                    });
                    if (
                      instanceSourceType ===
                      appEnum.TriggerAction.InstanceSourceTypeEnum.triggerRuleBizObject
                    ) {
                      // 当选择"触发规则业务对象"时
                      // "业务对象"设置为"触发规则业务对象"
                      if (!triggerRuleDetail.objectCode) return;
                      fetchFieldInfoAndSet(
                        triggerRuleDetail?.objectCode,
                        ActionTypeEnum.updateRecord,
                      );
                      const res = await fetchStandardBizObjectListPage({
                        // @ts-ignore
                        codes: [triggerRuleDetail.objectCode],
                      });
                      const objectDetail = res?.data?.list?.[0];

                      if (!objectDetail) return;
                      const value = JSON.stringify(objectDetail);

                      form.setFieldsValue({
                        objectCode: {
                          label: objectDetail.objectName,
                          value,
                          key: value,
                        },
                      });
                    } else if (
                      instanceSourceType ===
                      appEnum.TriggerAction.InstanceSourceTypeEnum
                        .triggerRuleBizObjectAssociatedObject
                    ) {
                      // 当选择"触发规则业务对象关联对象"
                      // 将"业务对象"数据重置为空
                      // 并设置"关联对象"的 cascader 树
                      form.setFieldsValue({
                        objectCode: null,
                        details: [],
                      });
                    } else {
                      // 这里的 else 是选择"手动选择业务对象"
                      form.setFields([
                        {
                          name: 'objectCode',
                          value: null,
                        },
                        {
                          name: 'details',
                          value: [],
                        },
                        {
                          name: ['triggerCondition', 'conditionGroups'],
                          value: null,
                        },
                      ]);
                      form.setFieldsValue({
                        objectCode: null,
                        details: [],
                      });
                    }
                  }}
                />
              ) : (
                <div style={{ color: 'red', paddingTop: '5px' }}>请选择触发规则</div>
              )}
            </Form.Item>
          );
        },
      },
      {
        shouldUpdate: (prevValues, curValues) => {
          return (
            prevValues.instanceSourceType !== curValues.instanceSourceType ||
            prevValues.actionType !== curValues.actionType
          );
        },
        // ● 关联对象
        //   ○ 控件展示依赖
        //     ■ 事件类型：更新事件展示
        //     ■ 更新对象来源：决定隐藏和展示
        //   ○ 控件数据依赖
        //     ■ 触发规则：将数据重置为空
        //     ■ 事件类型：把数据重置为空
        //     ■ 更新对象来源：将数据设置为空
        wrapperCol: { flex: 'none' },
        style: { marginBottom: '0' },
        render: () => () => {
          const hasSelectRuleAssociatedObject =
            form.getFieldValue('instanceSourceType') ===
            InstanceSourceTypeEnum.triggerRuleBizObjectAssociatedObject;
          /** 是否为新建事件类型 */
          const isCreateActionType =
            form.getFieldValue('actionType') === ActionTypeEnum.createRecord;

          if (!hasSelectRuleAssociatedObject || isCreateActionType) return null;
          // 如果包含编辑，将级联输入控件中与触发事件相同的业务对象置灰
          const newCascaderOptions = triggerRuleDetail?.bizEventTrigger?.triggerEventType?.some(
            (item: any) => item?.code === 'edit',
          )
            ? formatObjectTreeAddDisable(cascaderOptions, triggerRuleDetail.objectCode)
            : cascaderOptions;

          return (
            <Form.Item
              name="objectFieldPath"
              label="关联对象"
              labelCol={{ flex: `${LABEL_WIDTH}px` }}
              wrapperCol={{ flex: `${INPUT_AREA_WIDTH}px` }}
              style={{ marginBottom: '24px' }}
              rules={[{ required: true, message: '请选择关联对象' }]}
            >
              <FieldIdCascader
                loading={relatedLoading}
                options={newCascaderOptions}
                onChange={async (selectedOptions: any[]) => {
                  if (!selectedOptions) return;
                  const info = _.last(selectedOptions)?.info;

                  if (!info?.objectCode) return;

                  // 检验选择的对象是否支持可选
                  const { data: isSupportObject } =
                    await fetchTriggerActionCheckActionSupportObject({
                      objectCode: info?.objectCode,
                      actionType: 1,
                    });

                  if (!isSupportObject) {
                    message.error('该对象不支持选择');
                    form.setFields([
                      { name: 'objectFieldPath', value: undefined },
                      { name: 'objectCode', value: undefined },
                    ]);
                    return;
                  }

                  fetchFieldInfoAndSet(info?.objectCode, ActionTypeEnum.updateRecord);
                  const res = await fetchStandardBizObjectListPage({
                    // @ts-ignore
                    codes: [info?.objectCode],
                  });
                  const objectDetail = res?.data?.list?.[0];

                  if (!objectDetail) return;
                  const value = JSON.stringify(objectDetail);

                  form.setFieldsValue({
                    objectCode: {
                      label: objectDetail.objectName,
                      value,
                      key: value,
                    },
                  });
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        shouldUpdate: (prevValues, curValues) => {
          return (
            prevValues.triggerRuleId !== curValues.triggerRuleId ||
            prevValues.actionType !== curValues.actionType ||
            prevValues.instanceSourceType !== curValues.instanceSourceType
          );
        },
        // ● 业务对象
        //   ○ 控件展示依赖
        //     ■ 触发规则：决定展示“请选择触发规则”还是正常要显示的控件
        //     ■ 事件类型：决定展示不同类型 tip 提示
        //     ■ 更新对象来源：决定是否 disabled
        //   ○ 控件数据依赖
        //     ■ 触发规则：将数据重置为空
        //     ■ 事件类型：将数据重置为空
        //     ■ 更新对象来源：选择“触发规则业务对象”时，设置其数据，选择“触发规则业务对象关联对象”或“手动选择业务对象时”时，将数据重置为空
        //     ■ 关联对象：当选择一个“对象”时，设置其数据
        wrapperCol: { flex: 'none' },
        style: { marginBottom: '0' },
        render: () => () => {
          const isCreateActionType =
            form.getFieldValue('actionType') === ActionTypeEnum.createRecord;
          /** 手动选择业务对象 */
          const hasSelectRuleManualSelectBizObject =
            form.getFieldValue('instanceSourceType') ===
            InstanceSourceTypeEnum.manualSelectBizObject;
          /** 触发规则已选 */
          const hasSelectTriggerRule = form.getFieldValue('triggerRuleId');
          /** 触发规则的触发动作包含「编辑」 */
          let hasEventTypeTriggerEdit;
          /** 触发规则的触发动作包含「新增」 */
          let hasEventTypeTriggerAdd;

          if (hasSelectTriggerRule) {
            hasEventTypeTriggerAdd = triggerRuleDetail?.bizEventTrigger?.triggerEventType?.some(
              (item: any) => item?.code === 'add',
            );
            hasEventTypeTriggerEdit = triggerRuleDetail?.bizEventTrigger?.triggerEventType?.some(
              (item: any) => item?.code === 'edit',
            );
          }

          const createObjectOptionDisableFlag = isCreateActionType && hasEventTypeTriggerAdd;
          const updateObjectOptionDisableFlag = !isCreateActionType && hasEventTypeTriggerEdit;
          const objectOptionDisableFlag =
            createObjectOptionDisableFlag | updateObjectOptionDisableFlag;

          const renderToolTip = () => {
            if (isCreateActionType) {
              return (
                <Tooltip title="当触发规则的触发动作包含「新建」时，不支持选择与触发规则业务对象相同的触发事件的业务对象">
                  <BlIcon type="iconwentizhiyinbangzhu" className="bl-title-icon" />
                </Tooltip>
              );
            }
            return '';
          };

          return (
            <Form.Item
              labelCol={{ flex: `${LABEL_WIDTH}px` }}
              wrapperCol={{ flex: `${INPUT_AREA_WIDTH}px` }}
              style={{ marginBottom: '24px' }}
              label={
                <div>
                  <span>业务对象</span>
                  {renderToolTip()}
                </div>
              }
              name={hasSelectTriggerRule ? 'objectCode' : undefined}
              rules={[{ required: true, message: '请选择业务对象' }]}
              validateFirst
            >
              {!hasSelectTriggerRule ? (
                <div style={{ color: 'red', paddingTop: '5px' }}>请选择触发规则</div>
              ) : (
                <SearchSelect
                  // 如果事件类型是更新记录，则非手动选择业务状态时，不可选，自动带出
                  disabled={!isCreateActionType && !hasSelectRuleManualSelectBizObject}
                  fetchType="supportObject"
                  params={{ actionType: form.getFieldValue('actionType') }}
                  placeholder={
                    isCreateActionType || hasSelectRuleManualSelectBizObject ? '请选择业务对象' : ''
                  }
                  allowClear={false}
                  labelInValue
                  onChange={(data: any) => {
                    const actionType = form.getFieldValue('actionType');

                    if (actionType === appEnum.TriggerAction.ActionTypeEnum.updateRecord) {
                      form.setFieldsValue({
                        triggerCondition: null,
                      });
                    }
                    // 选择业务对象后，「对象属性值配置」中的字段选项依赖 objectCode，这里加上 objectCode 发请求
                    // 其中业务对象下的必填字段，不允许删除
                    if (!(typeof data?.value === 'string')) return;
                    const objectCode = JSON.parse(data?.value)?.objectCode;

                    fetchFieldInfoAndSet(objectCode, actionType);
                  }}
                  renderOption={(item) => {
                    return {
                      label: item.objectName,
                      value: JSON.stringify(item),
                      key: item.id,
                      disabled:
                        objectOptionDisableFlag && item.objectCode === triggerRuleDetail.objectCode,
                    };
                  }}
                />
              )}
            </Form.Item>
          );
        },
      },
      // 业务类型
      // {
      //   label: '业务类型',
      //   name: 'objectBizType',
      //   render: () => (
      //     <Select
      //       placeholder={'请选择业务类型'}
      //       options={appDict.triggerRule.objectBizType}
      //       allowClear
      //     />
      //   ),
      // },
      {
        isFullLine: true,
        shouldUpdate: (prevValues, curValues) => {
          return (
            prevValues.actionType !== curValues.actionType ||
            prevValues.objectCode !== curValues.objectCode ||
            prevValues.instanceSourceType !== curValues.instanceSourceType
          );
        },
        // ● 记录筛选条件
        //   ○ 控件展示依赖
        //     ■ 事件类型：决定隐藏和展示
        //     ■ 业务对象：决定隐藏和展示
        //     ■ 更新对象来源：决定隐藏和展示，还决定是否 disabled
        //   ○ 控件数据依赖
        //     ■ 触发规则：将数据重置为空
        //     ■ 事件类型：将数据重置为空
        //     ■ 更新对象来源：选择“手动选择业务对象”，将数据设置为空，选择“触发规则业务对象”或“触发规则业务对象关联对象”，将数据设置为触发规则中的条件筛选
        //     ■ 业务对象：当选择一个对象时，设置其数据
        render: () => () => {
          const isActionTypeUpdateRecord =
            form.getFieldValue('actionType') === ActionTypeEnum.updateRecord;
          // const hasSelectTriggerRule = form.getFieldValue('triggerRuleId');
          const hasSelectBizObject = form.getFieldValue('objectCode');
          const instanceSourceType = form.getFieldValue('instanceSourceType');
          const hasSelected = !_.isNil(instanceSourceType);
          const isInstanceSourceTypeManual =
            instanceSourceType === InstanceSourceTypeEnum.manualSelectBizObject;

          if (
            !hasSelected ||
            !isActionTypeUpdateRecord ||
            (isInstanceSourceTypeManual && !hasSelectBizObject)
          ) {
            return null;
          }
          const objectCode = getFieldValueOfForm(form, 'objectCode', 'objectCode');
          const objectCategory = getFieldValueOfForm(form, 'objectCode', 'objectCategory');
          const hasEventTypeTriggerEdit =
            triggerRuleDetail?.bizEventTrigger?.triggerEventType?.some(
              (item: { code: string; name: string }) => item.code === 'edit',
            );

          if (_.isNil(objectCode)) return null;

          return (
            <Form.Item label="记录筛选条件" required>
              {!isInstanceSourceTypeManual ? (
                <RuleConditionDetailTable
                  disabled
                  data={formatTriggerRuleConditionToForm(triggerRuleDetail)}
                  useParent="triggerAction"
                />
              ) : (
                <RuleConditionEditTable
                  form={form}
                  namePath={['triggerCondition', 'conditionGroups']}
                  triggerType={appEnum.TriggerRule.TriggerType.timeIntervalTrigger} // 这里的 triggerType 使用"定时触发"，让筛选条件只能为"值"，不能为引用类型
                  code={objectCode}
                  objectCategory={objectCategory}
                  haveEditTriggerEventType={hasEventTypeTriggerEdit} // 只有当触发动作包含编辑时 才显示编辑数据校验列
                  useParent="triggerAction"
                />
              )}
            </Form.Item>
          );
        },
      },
      {
        isFullLine: true,
        shouldUpdate: (prevValues, curValues) => {
          return (
            prevValues.objectCode !== curValues.objectCode ||
            _.get(prevValues, 'triggerRuleId.value') !== _.get(curValues, 'triggerRuleId.value')
          );
        },
        // ● 对象属性值配置
        //   ○ 控件展示
        //     ■ 业务对象：决定隐藏和展示
        //   ○ 控件数据依赖
        //     ■ 触发规则：将数据重置为空
        //     ■ 事件类型：将数据重置为空
        //     ■ 更新对象来源：当选择“触发规则业务对象”时，设置其数据，当选择“触发规则业务对象关联对象”或“手动选择业务对象”时，将数据重置为空
        //     ■ 关联对象：当选择一个“对象”时，设置其数据
        //     ■ 业务对象：当选择一个“对象”时，设置其数据
        render: () => () => {
          const objectCode = getFieldValueOfForm(form, 'objectCode', 'objectCode');
          // 触发规则绑定的业务对象code
          // 「引用触发规则」时，选触发规则业务对象上的字段
          const ruleBindObjectCode =
            triggerRuleDetail?.ObjectCode ??
            getFieldValueOfForm(form, 'triggerRuleId.value', 'ruleBindObjectCode') ??
            getFieldValueOfForm(form, 'triggerRuleId.value', 'bizObjectCode');

          if (!objectCode) return null;
          return (
            <Form.Item label="对象属性值配置" required>
              <ObjectConfigEditTable
                form={form}
                namePath="details"
                fieldOptions={fieldOptions}
                objectCode={objectCode}
                ruleBindObjectCode={ruleBindObjectCode}
                loading={editTableLoading}
              />
            </Form.Item>
          );
        },
      },
    ],
  };

  if (type === CRUD.edit) {
    baseInfo.items.push({
      label: '编辑原因',
      name: 'operateReason',
      isFullLine: true,
      rules: [{ max: 1000, message: '不超过1000字符' }],
      render: () => <TextArea placeholder="请输入编辑原因" />,
    });
  }

  const handleFinish = async () => {
    try {
      const value = await form?.validateFields();

      if (value?.triggerCondition && _.isEmpty(value?.triggerCondition?.conditionGroups)) {
        message.error('至少添加一条记录筛选条件');
        return;
      }
      const reqFn = type === CRUD.edit ? fetchTriggerActionEdit : fetchTriggerActionCreate;

      reqFn(formatDataToRequest(value, editId)).then((res) => {
        if (res.code === 200) {
          message.success('操作成功');
          if (keepOn) {
            form.resetFields();
          } else {
            onFinish();
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onFieldsChange = (fields: any) => {
    // 仅当 fields 为单个变动时执行依赖
    // 规避提交时的校验引起值改变
    if (fields.length > 1) return;
    fields?.forEach((field: any) => {
      /** 当前变动的字段名 */
      const curfieldName: string = _.last(field.name) as string;
      /** 当前变动的字段所在的父级 字段的路径 */
      const fieldName: (string | number)[] = _.initial(field.name) as (string | number)[];
      const item = form.getFieldValue(fieldName);
      const { valueType } = item;
      let newItemValue = { ...item, value: undefined };

      // 引用触发规则的业务对象，直接设置
      if (valueType === ActionValueTypeEnum.triggerRuleObj) {
        newItemValue = {
          ...item,
          value: { label: triggerRuleDetail?.objectName, value: triggerRuleDetail?.objectCode },
        };
      }

      switch (curfieldName) {
        case 'fieldId':
        case 'valueType':
          form.setFields([{ name: fieldName, value: newItemValue }]);
          break;
        default:
          break;
      }
    });
  };

  return (
    <div className={styles.innerFormItemControlInputGlobal}>
      <DataFormLayout
        form={form}
        title={title}
        loading={loading}
        info={[baseInfo, configInfo]}
        onFinish={handleFinish}
        formLayout="horizontal"
        onCancel={onCancel}
        formProps={{ onFieldsChange }}
        extra={
          type === CRUD.create ? (
            <Checkbox onChange={() => setkeepOn(!keepOn)} defaultChecked={false}>
              连续新建
            </Checkbox>
          ) : null
        }
      />
    </div>
  );
};

export default TriggerActionForm;
