/**
 * 来源信息
 */
import { Form, FormInstance, Select } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { NamePath } from 'antd/es/form/interface';
import { appDict, appEnum } from 'src/dict';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import { SearchSelect } from 'src/components';
import BlSortFormList from 'src/components/sortList/BlSortFormList';
import { BomFormOther } from './index.type';

interface OtherInfoFormProps {
  form: FormInstance;
  name: string;
}

// 格式化 其他信息 给接口
export const formatSourcesToApi = (other?: BomFormOther) => {
  return { remark: other?.remark };
};

export const formatSourcesToForm = (other?: any): BomFormOther => {
  return { remark: other?.remark };
};

const SourcesForm = (props: OtherInfoFormProps) => {
  const { form, name } = props;

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '销售订单编号',
        dataIndex: 'salesOrderId',
        render: (text: string, field: any) => {
          return (
            <Form.Item dependencies={[['bomOutputMaterials', '0', 'materialId']]} noStyle>
              {() => {
                const mainMaterial = form.getFieldValue(['bomOutputMaterials', '0', 'materialId']);
                const material = JSON.parse(mainMaterial?.value ?? '{}');

                return (
                  <Form.Item
                    fieldKey={[field.fieldKey, 'salesOrderId']}
                    name={[field.name, 'salesOrderId']}
                    validateTrigger={['onChange', 'onBlur']}
                    style={{ marginBottom: '0' }}
                    rules={[{ required: true, message: '销售订单编号必选' }]}
                  >
                    <SearchSelect
                      fetchType={'salesOrderListSimple'}
                      labelInValue
                      params={{ materialIds: [material?.baseInfo?.id] }}
                      onChange={() => {
                        form.setFields([
                          {
                            name: [
                              'workOrderSourceCO',
                              'sourceSalesOrderList',
                              field.name,
                              'salesOrderLine',
                            ],
                            value: undefined,
                          },
                          {
                            name: [
                              'workOrderSourceCO',
                              'sourceSalesOrderList',
                              field.name,
                              'salesOrderDeliveryTime',
                            ],
                            value: '',
                          },
                        ]);
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '销售订单行号',
        dataIndex: 'salesOrderLine',
        render: (_text, field) => {
          return (
            <Form.Item
              dependencies={[
                ['workOrderSourceCO', 'sourceSalesOrderList', field.name, 'salesOrderId'],
              ]}
              noStyle
            >
              {() => {
                const code =
                  form.getFieldValue([
                    'workOrderSourceCO',
                    'sourceSalesOrderList',
                    field.name,
                    'salesOrderId',
                  ])?.label ?? '{}';

                return (
                  <Form.Item
                    fieldKey={[field.fieldKey, 'salesOrderLine']}
                    name={[field.name, 'salesOrderLine']}
                    validateFirst
                    style={{ width: '100%', marginBottom: '0' }}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      { required: true, message: '销售订单行号必选' },
                      {
                        validator: validateRepeat(
                          form,
                          ['workOrderSourceCO', 'sourceSalesOrderList'],
                          field,
                        ),
                      },
                    ]}
                  >
                    <SearchSelect
                      fetchType={'salesOrderListSimpleLine'}
                      labelInValue
                      params={{ code }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '交货日期',
        dataIndex: 'salesOrderDeliveryTime',
        render: (_text, field) => {
          return (
            <Form.Item
              dependencies={[
                ['workOrderSourceCO', 'sourceSalesOrderList', field.name, 'salesOrderId'],
                ['workOrderSourceCO', 'sourceSalesOrderList', field.name, 'salesOrderLine'],
              ]}
              style={{ width: '100%', marginBottom: '0' }}
            >
              {() => {
                const salesOrder = JSON.parse(
                  form.getFieldValue([
                    'workOrderSourceCO',
                    'sourceSalesOrderList',
                    field.name,
                    'salesOrderLine',
                  ])?.value ?? '{}',
                );

                return (
                  <Form.Item
                    fieldKey={[field.fieldKey, 'salesOrderDeliveryTime']}
                    name={[field.name, 'salesOrderDeliveryTime']}
                    style={{ width: '100%', marginBottom: '0' }}
                  >
                    {salesOrder?.deliveryDate
                      ? _Time.format(salesOrder?.deliveryDate)
                      : replaceSign}
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ];
  };

  // 校验重复
  const validateRepeat = (recordForm: any, filedName: NamePath, filed: any) => {
    return (_rule: any, value: any) => {
      return new Promise((resolve, reject) => {
        if (!value) {
          return resolve(true);
        }
        /** 获取所有数据 */
        const valueList = recordForm.getFieldValue(filedName);

        /** 在所有数据中过滤当前项 */
        const noIncludeRecord = valueList
          ?.slice(0, filed?.name)
          .concat(valueList?.slice(filed?.name + 1));
        const recordItem = recordForm.getFieldValue([
          'workOrderSourceCO',
          'sourceSalesOrderList',
          filed?.name,
        ]);

        /** 查看当前项目是否有相同销售订单号 并且 物料行相同 */
        noIncludeRecord.map((item: any) => {
          if (
            item?.salesOrderId?.key === recordItem?.salesOrderId?.key &&
            item?.salesOrderLine?.label === value?.label
          ) {
            reject('已存在该选项!');

            return false;
          }
        });

        return resolve(true);
      });
    };
  };

  return (
    <div>
      <Form form={form} name={name} labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
        <Form.Item
          label="生成方式"
          name={[name, 'generationMethod']}
          initialValue={appEnum.ProductionPlanning.AcquisitionApplicationEnum.PRODUCTIONWORKORDER}
        >
          <Select options={appDict.productionPlanning.GenerationEnumDisplayMap} disabled />
        </Form.Item>
        <Form.Item label="来源单据类型">
          <Select disabled />
        </Form.Item>

        {/* <Form.Item label="来源单据">
          <BlTable
            dataSource={[]}
            pagination={false}
            scroll={{ y: 400 }}
            columns={[
              {
                title: '来源单据编号',
                dataIndex: 'lineNo',
                key: 'lineNo',
                width: 100,
                render: (text: number) => `${text || replaceSign}`,
              },
              {
                title: '来源单据行号',
                key: 'groupType',
                dataIndex: 'groupType',
                width: 100,
                render: (text: number) => text || replaceSign,
              },
            ]}
          />
        </Form.Item> */}
        <Form.Item label="销售订单">
          <BlSortFormList
            form={form}
            name={['workOrderSourceCO', 'sourceSalesOrderList']}
            renderColumns={() => getColumns()}
            isNeedDrag={false}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default SourcesForm;
