import React, { useEffect, useState } from 'react';
import { Form, message as Message } from 'antd';
import AreaBaseInfo from '../components/areCreateAndEditBaseInfo';
import { fetchStorageAreaInfo, fetchStorageAreaModify } from 'src/api/ytt/storage-domain/area';
import { throttleFn } from '../utils';
import { match, RouteComponentProps } from 'react-router-dom';

interface editProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const AreaEditPage = (props: editProps) => {
  const { match, history } = props;
  const areaId = Number(match?.params?.id);

  const [detailData, setDetailData] = useState({} as any);

  const [modalForm] = Form.useForm();

  const fetchData = async () => {
    try {
      const { data } = await fetchStorageAreaInfo({ id: areaId });

      if (data) {
        setDetailData(data);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [areaId]);

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();
      const jsonData = {
        ...value,
        id: detailData?.id,
        warehouseId: detailData?.warehouseId,
        parentAreaId: detailData?.parentId,
      };
      const { code, message } = await fetchStorageAreaModify(jsonData);

      if (code === 200) {
        Message.success('编辑成功');
        history.goBack();
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AreaBaseInfo
      onCancel={() => {
        history.goBack();
      }}
      onFinish={throttleFn(handleFinish)}
      title="编辑区域"
      initialData={detailData}
      form={modalForm}
      edit
    />
  );
};

export default AreaEditPage;
