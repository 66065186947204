/*
 * @Description: 面包屑导航
 */
import { matchPath } from 'react-router';
import { Link } from 'react-router-dom';
import qs from 'qs';
import _ from 'lodash';
import styles from './styles.module.scss';

type BreadcrumbInfo = {
  content: string;
  path: string;
};

const flattenRoutes = (arr: any[]): any =>
  arr.reduce((prev: any, item: any) => {
    prev.push(item);
    return prev.concat(Array.isArray(item?.children) ? flattenRoutes(item?.children) : item);
  }, []);

const getBreadcrumb = ({ flattenRoutes, curSection, pathSection }: any): BreadcrumbInfo | null => {
  const matchRoute = flattenRoutes.find((ele: any) => {
    const { breadcrumbName, path } = ele || {};

    if (!breadcrumbName || !path) {
      return false;
    }
    // 查找是否有匹配
    return matchPath(pathSection, { path, exact: true });
  });

  if (matchRoute) {
    return {
      content: matchRoute.breadcrumbName || curSection,
      path: pathSection,
    };
  }
  return null;
};

export const getBreadcrumbs = ({ flattenRoutes, location }: any) => {
  const matches: (BreadcrumbInfo | null)[] = [];

  location.pathname.split('/').reduce((prev: string, curSection: string) => {
    const pathSection = `${prev}/${curSection}`;
    const breadcrumb = getBreadcrumb({
      flattenRoutes,
      curSection,
      pathSection,
    });

    if (breadcrumb) {
      matches.push(breadcrumb);
    }
    return pathSection;
  });
  return matches;
};

const matchReg = /{{[a-zA-Z]+}}/;
const BlBreadcrumbs = (location = window.location, routes: any[] = []) => {
  const breadcrumbs = getBreadcrumbs({
    flattenRoutes: flattenRoutes(routes),
    location,
  });
  const query = qs.parse(location.search, { ignoreQueryPrefix: true }) as any;

  return (
    <div className={`ant-breadcrumb ${styles['bl-breadcrumb']}`} style={{ padding: 16 }}>
      {breadcrumbs.map((breadcrumb, index) => {
        if (!breadcrumb) {
          return '';
        }
        const matchResults = matchReg.exec(breadcrumb.content);
        let breadcrumbText = breadcrumb.content;

        // 简单起见, 暂时只替换一个字符串模板, 如有替换多个的需求, 这里要再改下
        if (matchResults) {
          const replaceValue = _.truncate(_.get(query, matchResults[0].replace(/{{|}}/g, ''), ''), {
            length: 9,
          });

          breadcrumbText = breadcrumbText.replace(matchReg, replaceValue);
        }
        let breadcrumbPath = breadcrumb.path;

        // 如果面包屑路径就是当前路径，或面包屑中存在字符串匹配项，就把当前的query参数带在路径上
        if (breadcrumbPath === location.pathname || matchResults) {
          breadcrumbPath = `${breadcrumb.path}${location.search}`;
        }

        return (
          <span key={breadcrumb.path}>
            <Link to={breadcrumbPath}>{breadcrumbText}</Link>
            {index < breadcrumbs.length - 1 && (
              <i className={styles['bl-breadcrumb-separator']}>/</i>
            )}
          </span>
        );
      })}
    </div>
  );
};

export default BlBreadcrumbs;
