import { BL_BUSINESS_NAME } from './config';

export const MAX_INPUT_LENGTH = 255;
export const MAX_TEXTAREA_LENGTH = 1000;
export const MAX_USER_COUNT = 20;

export const fieldLabels = {
  code: `${BL_BUSINESS_NAME}编号`,
  name: `${BL_BUSINESS_NAME}标题`,
  identificationCode: '标识码',
  repairPlan: '维修方案',
  businessObject: '维修业务对象',
  repairTarget: '维修目标',
  repairTargetCode: '维修目标编号',
  repairTargetArea: '维修目标区域',
  repairRequestor: '报修人',
  malfunctonClassification: '故障分类',
  malfunctionTags: '故障标签',
  malfunctionLevel: '故障等级',
  malfunctionReason: '故障原因',
  malfunctionDetail: '故障明细',
  channelType: '维修途径',
  status: `${BL_BUSINESS_NAME}状态`,
  classification: `${BL_BUSINESS_NAME}分类`,
  scanCodeFlag: '扫码确认',
  planStartTime: '计划开始时间',
  standardWorkTime: '标准工时',
  planEndTime: '计划结束时间',
  planExecutorList: '计划执行人',
  realStartTime: '实际开始时间',
  realEndTime: '实际结束时间',
  currentExecutorList: '当前执行人',
  executeType: '执行方式',
  sopPlan: 'SOP方案',
  sopTask: 'SOP任务',
  reportTemplate: '维修报告模版',
  attachment: '附件',
  createResourceType: '任务来源',
  sourceInstanceCode: '来源实例编号',
  executedDuration: '已执行时长',
  sumDuration: '总执行时长',
  remark: '备注',
  editReason: '编辑原因',
  creator: '创建人',
  createdAt: '创建时间',
  operator: '更新人',
  updatedAt: '更新时间',
};
