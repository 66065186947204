/**
 * @description 对象属性值配置, 值选项的组件
 */

import { useMemo } from 'react';
import { Form, Input, Select } from 'antd';
import { appDict, appEnum } from 'src/dict';
import { ActionValueTypeEnum } from 'src/dict/triggerAction';
import SearchSelect, { SearchSelectProps } from 'src/components/searchSelect';
import { SelectValue } from 'antd/lib/select';
import FieldIdCascader from '../../triggerRule/share/FieldIdCascader';
import MyDatePicker from '../../triggerRule/share/FormItemComponents/MyDatePicker';
import { FormulaEditorModal } from 'src/components';
import { NamePath } from 'antd/lib/form/interface';
import RedRemindText from '../../triggerRule/share/RedRemindText';
import { CascaderOption } from '../constant';

const { CustomField } = appEnum;
const triggerAction = 'triggerAction';

interface ValueComponentProps {
  /** 对象属性配置的值类型 */
  valueType: ActionValueTypeEnum;
  /** 关联的触发规则信息 */
  triggerRule: any;
  disabled: boolean;
  namePath: NamePath;
  /** 事件类型 */
  actionType: number;
  /** 值类型为「字段引用」时，给Cascader组件用的options */
  bizInfo?: { options: CascaderOption[]; loading: boolean };
  /** 值类型为「引用触发规则」时，给Cascader组件用的options */
  triggerInfo?: { options: CascaderOption[]; loading: boolean };
  fieldInfo?: FieldInfo;
  [index: string | number]: any;
}

type FieldInfo = {
  fieldType: number;
  choiceValues: any[];
  targetType: number;
  reference: string;
  isRequired?: boolean;
};

const ValueComponent: React.FC<ValueComponentProps> = ({
  valueType,
  triggerRule = {},
  fieldInfo,
  disabled,
  namePath,
  actionType,
  bizInfo = {},
  triggerInfo = {},
  ...restProps
}) => {
  const CascaderByObject = useMemo(() => {
    if (!fieldInfo) return <RedRemindText text="请先选择字段" />;
    const { fieldType, choiceValues, targetType, reference } = fieldInfo;
    const ruleBindObjectCode = triggerRule?.ruleBindObjectCode ?? triggerRule?.bizObjectCode;
    const placeholder = '请输入';
    const placeholderS = '请选择';

    // 当「值类型」选项为 「值」时，通过fieldType字段类型判断展示的组件
    if (valueType === ActionValueTypeEnum.value) {
      const searchSelectProps: SearchSelectProps<SelectValue> = {
        ...restProps,
        key: restProps.id,
        fetchType: 'triggerRuleQueryInstance',
        params: { objectCode: reference },
        disabled,
        mode: targetType === CustomField.TargetType.multiChoice ? 'multiple' : undefined,
        placeholder: placeholderS,
        labelInValue: true,
      };

      switch (fieldType) {
        case CustomField.FieldType.text:
        case CustomField.FieldType.textArea:
        case CustomField.FieldType.url:
          return (
            <Input placeholder={disabled ? '' : placeholder} disabled={disabled} {...restProps} />
          );
        case CustomField.FieldType.number:
        case CustomField.FieldType.integer:
          return (
            <Input placeholder={disabled ? '' : placeholder} disabled={disabled} {...restProps} />
          );
        case CustomField.FieldType.date:
          return (
            <MyDatePicker
              showTime
              showNow
              disabled={disabled}
              style={{ width: '100%' }}
              {...restProps}
            />
          );
        case CustomField.FieldType.boolean:
          return (
            <Select
              placeholder={placeholderS}
              options={appDict.common.yns}
              disabled={disabled}
              {...restProps}
            />
          );
        case CustomField.FieldType.select:
          return (
            <Select
              labelInValue
              placeholder={placeholderS}
              disabled={disabled}
              options={choiceValues?.map(({ choiceCode, choiceValue }: any) => ({
                label: choiceValue,
                value: choiceCode,
              }))}
              {...restProps}
            />
          );
        case CustomField.FieldType.multiSelect:
          return (
            <Select
              labelInValue
              mode="multiple"
              placeholder={disabled ? '' : placeholderS}
              disabled={disabled}
              options={choiceValues?.map(({ choiceCode, choiceValue }: any) => ({
                label: choiceValue,
                value: choiceCode,
              }))}
              {...restProps}
            />
          );
        case CustomField.FieldType.relation:
        case CustomField.FieldType.relationSub:
        case CustomField.FieldType.subordinate:
          return <SearchSelect {...searchSelectProps} placeholder={disabled ? '' : placeholderS} />;
        default:
          return <div style={{ color: 'red' }}>请先选择字段</div>;
      }
      // 当「值类型」选择「引用触发规则」时，用触发规则的对象code获取字段
      // 最多支持五层，depth = 4
    } else if (valueType === ActionValueTypeEnum.triggerRuleBiz) {
      if (!ruleBindObjectCode) return <div style={{ color: 'red' }}>请先选择触发规则</div>;
      return (
        <FieldIdCascader
          allowClear
          {...restProps}
          {...triggerInfo}
          disabled={disabled}
          placeholder={placeholderS}
        />
      );
    } else if (valueType === ActionValueTypeEnum.biz) {
      return (
        <FieldIdCascader
          allowClear
          {...restProps}
          {...bizInfo}
          disabled={disabled}
          placeholder={placeholderS}
        />
      );
    } else if (valueType === ActionValueTypeEnum.formula) {
      return <FormulaEditorModal objectCode={triggerAction} textInValue onlyShowText={disabled} />;
    } else if (valueType === ActionValueTypeEnum.triggerRuleObj) {
      return <Select disabled labelInValue />;
    }
    return null;
  }, [triggerRule, fieldInfo, valueType]);
  // 在以下两种情况下值是必填的
  // 事件类型是"创建记录"（你选了这字段，无论该字段是必填还是非必填，你都要给该字段选个值上去）
  // 该字段是"必填"的（必填字段那就一定要填写值）
  // 这里再谈一谈什么情况下字段的值是非必填，创建类型为"更新记录"，并且该字段是非必选的。因为在"更新记录"情况下，空等于让该字段置空
  const required =
    actionType === appEnum.TriggerAction.ActionTypeEnum.createRecord || fieldInfo?.isRequired;

  return (
    <Form.Item
      rules={[
        {
          required,
          message: '值不能为空',
        },
      ]}
      name={namePath}
    >
      {CascaderByObject}
    </Form.Item>
  );
};

export default ValueComponent;
