import React, { useState } from 'react';
import { Form, Checkbox, message as Message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { fetchSalesOrderCreate } from 'src/api/ytt/order-domain/salesOrder';
import CreateAndEditBaseInfo from './createAndEditBaseInfo';
import { toList } from '../navigation';
import { formatFetchValue } from '../utils';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';
import { ICustomFields } from 'src/components/customField';

interface createProps extends RouteComponentProps {}

const CreateSalesOrder = (props: createProps) => {
  const { history } = props;

  const [createForm] = Form.useForm();
  const [continuousCreate, setContinuousCreate] = useState(false);
  const [refreshMarker, setRefreshMarker] = useState<number>();

  const extra = (
    <Checkbox
      onChange={(e) => {
        setContinuousCreate(e?.target?.checked);
      }}
    >
      连续新建
    </Checkbox>
  );

  const handleFinish = async (customFields?: ICustomFields, customItemFields?: ICustomFields) => {
    try {
      const value = await createForm?.validateFields();

      const newValue = formatFetchValue(value, customFields, customItemFields);

      const { data } = await fetchSalesOrderCreate({
        header: newValue.header,
        items: newValue.items,
      });

      if (data) {
        Message.success('创建成功');
        if (continuousCreate) {
          createForm.resetFields();
          createForm.setFieldsValue({
            materials: [
              {
                amount: 1,
              },
              {
                amount: 1,
              },
            ],
          });
          setRefreshMarker(Date.now());
          return;
        }
        history.push(toList());
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <CreateAndEditBaseInfo
      edit={false}
      title="新建销售订单"
      onCancel={() => {
        history.push(toList());
      }}
      onFinish={throttleFn(handleFinish)}
      form={createForm}
      extra={extra}
      refreshMarker={refreshMarker}
    />
  );
};

export default CreateSalesOrder;
