import { Modal, Form, Checkbox, Tooltip, message as Message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import {
  fetchCustomFieldVisibleGetByObjectCodes,
  FetchCustomFieldVisibleGetByObjectCodesResponse,
  fetchCustomFieldVisibleUpsert,
} from 'src/api/ytt/supply-chain-domain/custom_fields/index';
import { FormInstance } from 'antd/lib/form';
import { YN } from 'src/dict/common';
import _ from 'lodash';

const grayColor = '#595959';

interface Props {
  /** 是否显示 */
  visible: boolean;
  onClose: () => void;
}
interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}
type CustomFieldData = Required<FetchCustomFieldVisibleGetByObjectCodesResponse>['data'][number];
const SettingModal = (props: Props) => {
  const { visible, onClose } = props;
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [orderOptions, setOrderOptions] = useState<Option[]>([]);
  const [itemOptions, setItemOptions] = useState<Option[]>([]);

  useEffect(() => {
    setModalShow(visible);
    if (visible) {
      fetchData();
    }
  }, [visible]);

  const fetchData = () => {
    fetchCustomFieldVisibleGetByObjectCodes({
      objectCodes: [OBJECT_OF_CODE.ShipmentsNote, OBJECT_OF_CODE.ShipmentsNoteItem],
    }).then((res) => {
      const { data } = res;

      /**
       *  获取 customField.relatedObjectCode 等于发货单、发货单行的自定义字段
       */
      function getCustomFields(relatedObjectCode: string) {
        return data?.filter(
          (a: CustomFieldData) => a?.customField?.relatedObjectCode === relatedObjectCode,
        );
      }

      /**
       * parmas
       * @customFields 自定义字段
       *
       * return
       * @options 格式化 自定义字段 为 label value 作为 Checkbox.Group 的 options
       * @selected 获取已配置的自定义字段  即 form initialValue
       */
      function formatOptions(customFields: CustomFieldData[] | undefined): {
        options: Option[];
        selected: string[];
      } {
        if (_.isNil(customFields)) {
          return {
            options: [],
            selected: [],
          };
        }
        const selected: string[] = [];
        const options = customFields.map((field) => {
          if (field?.customField?.id && field?.visibleFlag === YN.yes) {
            selected.push(String(field.customField.id));
          }

          return {
            label: field?.customField?.fieldName || '自定义字段',
            value: String(field?.customField?.id) || '',
          };
        });

        return {
          options,
          selected,
        };
      }

      const orderCustomFields = getCustomFields(OBJECT_OF_CODE.ShipmentsNote);
      const itemCustomFields = getCustomFields(OBJECT_OF_CODE.ShipmentsNoteItem);

      const { options: orderOptions, selected: orderSelected } = formatOptions(orderCustomFields);
      const { options: itemOptions, selected: itemSelected } = formatOptions(itemCustomFields);

      setOrderOptions(orderOptions);
      setItemOptions(itemOptions);
      form.setFieldsValue({
        shipmentsNote: orderSelected,
        shipmentsNoteItem: itemSelected,
      });
    });
  };

  const onCancel = () => {
    onClose();
  };
  const handleFinish = () => {
    const { shipmentsNote, shipmentsNoteItem } = form.getFieldsValue();

    const formatData = (data: Option[], objectCode: string, selectedOptions: string[]) =>
      data?.map((option) => {
        return {
          fieldId: Number(option.value),
          objectCode,
          visibleFlag: selectedOptions?.includes(option.value) ? YN.yes : YN.no,
        };
      });

    const orderParams = formatData(orderOptions, OBJECT_OF_CODE.ShipmentsNote, shipmentsNote) || [];
    const itemParams =
      formatData(itemOptions, OBJECT_OF_CODE.ShipmentsNoteItem, shipmentsNoteItem) || [];

    fetchCustomFieldVisibleUpsert([...orderParams, ...itemParams]).then(() => {
      Message.success('保存成功');
    });
    onClose();
  };

  return (
    <Modal
      title="发货单协同设置"
      visible={modalShow}
      centered
      onCancel={onCancel}
      onOk={handleFinish}
      okText="确认"
      maskClosable={false}
    >
      <div style={{ marginBottom: 16, color: '#262626', fontSize: '14px' }}>
        客户自定义字段可见性设置:&nbsp;
        <Tooltip
          placement="topLeft"
          title="选中的自定义字段支持在客户”收货单“的协同信息中展示及查看"
          arrowPointAtCenter
        >
          <QuestionCircleOutlined style={{ color: grayColor }} />
        </Tooltip>
      </div>
      <SearchForm form={form} selectedOptions={{ orderOptions, itemOptions }} />
    </Modal>
  );
};

interface SearchFormProps {
  form: FormInstance;
  selectedOptions: { orderOptions: Option[]; itemOptions: Option[] };
}
const SearchForm = (props: SearchFormProps) => {
  const { form, selectedOptions } = props;
  const { orderOptions, itemOptions } = selectedOptions;

  const renderItem = (name: string, label: string, options: Option[]) => {
    return (
      <Form.Item
        label={<span style={{ color: grayColor }}>{label}</span>}
        name={name}
        style={{ marginBottom: 8 }}
      >
        <Checkbox.Group options={options} />
      </Form.Item>
    );
  };
  const renderDefaultItem = (label: string) => {
    return (
      <Form.Item
        label={<span style={{ color: grayColor }}>{label}</span>}
        style={{ marginBottom: 8 }}
      >
        <div style={{ color: grayColor }}>未配置自定义字段</div>
      </Form.Item>
    );
  };

  return (
    <Form
      name="customFieldsSetting"
      form={form}
      autoComplete="off"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
    >
      {orderOptions && orderOptions.length > 0
        ? renderItem('shipmentsNote', '发货单', orderOptions)
        : renderDefaultItem('发货单')}
      {itemOptions && itemOptions.length > 0
        ? renderItem('shipmentsNoteItem', '发货单行', itemOptions)
        : renderDefaultItem('发货单行')}
    </Form>
  );
};

export default SettingModal;
