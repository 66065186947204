import _ from 'lodash';

/**
 * 获取去重后的唯一列表
 */
export function gitUniqueList<T>(data: {
  curList: T[];
  addList: T[];
  maxCount: number;
  getUniqueKey: (item: T) => string | number | undefined;
}): T[] {
  const { curList, addList, maxCount, getUniqueKey } = data;

  if (_.isEmpty(addList)) return curList;

  // 去重之后可以增加进的数据
  const ableAddList = _.differenceWith<T, T>(addList, curList, (newItem, oldItem) => {
    const newValue = getUniqueKey(newItem);
    const oldValue = getUniqueKey(oldItem);

    if (newValue && oldValue) return _.isEqual(newValue, oldValue);

    return false;
  });

  const newList = _.concat(curList, ableAddList);
  const newListLength = newList.length;

  if (newListLength > maxCount) {
    return _.dropRight(newList, newListLength - maxCount);
  }

  return newList;
}
