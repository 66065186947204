import React, { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import _ from 'lodash';
import { message } from 'antd';
//
import {
  fetchBomCreate,
  fetchBomUpdate,
  fetchBomGet,
  FetchBomGetResponse,
} from 'src/api/ytt/med-domain/bom';
import { appEnum } from 'src/dict';
//
import BaseForm from './baseForm';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { useCustomFieldCombinedData } from 'src/components/customField';

interface CreateAndEditPorps extends RouteChildrenProps<{ id: string }> {}

const CreateAndEdit = (props: CreateAndEditPorps) => {
  const { history, location, match } = props;

  const [loading, setLoading] = useState(false);
  const [initialValue, setInitialValue] = useState<FetchBomGetResponse['data']>();
  const [type, setType] = useState(appEnum.Common.CRUD.create);

  const bomCustomFields = useCustomFieldCombinedData(OBJECT_OF_CODE.SubItemMaterialChild);

  useEffect(() => {
    const fetchAndSetBomData = (id?: string) => {
      if (id) {
        setLoading(true);
        fetchBomGet({ id: _.toNumber(match?.params?.id) })
          .then((dataRes) => {
            // 设置表单初始值
            if (id && dataRes) {
              const { data } = dataRes;

              setInitialValue(data);
            }
          })
          .catch(() => {
            history.goBack();
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };

    fetchAndSetBomData(match?.params?.id);

    if (location.pathname.includes('edit')) {
      setType(appEnum.Common.CRUD.edit);
    } else if (location.pathname.includes('copy')) {
      setType(appEnum.Common.CRUD.create);
    } else {
      setType(appEnum.Common.CRUD.create);
    }
  }, []);

  const handleCreate = (value: any, cb?: () => void) => {
    setLoading(true);
    fetchBomCreate(value)
      .then(() => {
        message.success('创建成功!');

        cb?.();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (value: any) => {
    setLoading(true);
    fetchBomUpdate(value)
      .then(() => {
        message.success('编辑成功!');

        // 自定义字段异步更新，需要延迟操作
        setTimeout(() => {
          history.goBack();
          setLoading(false);
        }, 1200);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <BaseForm
      loading={loading}
      type={type}
      history={history}
      initialValue={initialValue}
      bomCustomFields={bomCustomFields}
      onSubmit={(value, cb) => {
        if (type === appEnum.Common.CRUD.create) handleCreate(value, cb);
        if (type === appEnum.Common.CRUD.edit) handleEdit(value);
      }}
    />
  );
};

export default CreateAndEdit;
