import {
  fetchTriggerActionGetField,
  FetchTriggerActionGetFieldResponse,
} from 'src/api/ytt/trigger-domain';
import { formatToOption } from '../utils/formatToFormData';

import _ from 'lodash';
import { FieldType } from 'src/dict/common';
import { ActionTypeEnum } from 'src/dict/triggerAction';
import { BaseOption } from '../constant';

type FieldInfoList = FetchTriggerActionGetFieldResponse['data'];

const formatCustomFields = (fields: FieldInfoList = []): [any[], BaseOption[]] => {
  // 此处的 requiredFields 是必选字段列表
  const requiredFields: FieldInfoList = [];
  const selectFields: FieldInfoList = [];

  fields.forEach((field) => {
    field.defaultField === 1 ? requiredFields.push(field) : selectFields.push(field);
  });

  const details = _.map(
    requiredFields,
    (
      { id, fieldName, choiceValues, fieldType, reference, targetType, isRequired, defaultField },
      index,
    ) => {
      const _fieldName = (
        <span>
          <span style={{ color: 'red' }}>* </span>
          {fieldName}
        </span>
      );

      return {
        fieldId: formatToOption(_fieldName, {
          id,
          fieldType,
          choiceValues,
          reference,
          targetType,
          isRequired,
        }),
        valueType: 0,
        seq: index + 1,
        isRequired,
        defaultField,
      };
    },
  );
  const options = _.map(
    selectFields,
    ({
      id,
      fieldName,
      choiceValues,
      fieldType,
      reference,
      targetType,
      isRequired,
      defaultField,
    }) => {
      // if (fieldType === FieldType.subordinate) return undefined;
      return formatToOption(fieldName, {
        id,
        fieldType,
        choiceValues,
        reference,
        targetType,
        isRequired,
        defaultField,
      });
    },
  );

  return [details, options.filter((item) => item) as BaseOption[]];
};

/**
 * Promise最终返回业务对象下的可选字段options 和 默认字段的信息
 *
 * [可选字段[],默认字段信息[]]
 */
const fetchFieldOptions = (objectCode: string, actionType: ActionTypeEnum) => {
  return new Promise(
    (
      resolve: (value: [any[], BaseOption[]]) => void,
      reject: (value: [any[], BaseOption[]]) => void,
    ) => {
      if (!objectCode) {
        reject([[], []]);
        return;
      }
      fetchTriggerActionGetField({ objectCode, actionType })
        .then((res) => {
          resolve(formatCustomFields(res.data ?? []));
        })
        .catch(() => reject([[], []]));
    },
  );
};

export default fetchFieldOptions;
