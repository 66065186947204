import React from 'react';
import { Input, Radio, InputNumber, Form, FormInstance } from 'antd';
import {
  validatorUrl,
  validateBlInputNumberIntervalInt,
  validatorCharacterLen,
} from '../verificationRules/index';
import { appDict, appEnum } from 'src/dict';
import { FieldBaseProps } from 'src/page/custom_fields/fieldsList/index.d';

const message = '请输入...';

export const linkItems = ({ form, isPreField, isEdit }: FieldBaseProps) => {
  return [
    {
      label: '必填',
      name: 'isRequired',
      rules: [{ required: true, message }],
      initialValue: 0,
      render: () => (
        <Radio.Group
          disabled={isPreField && form.getFieldValue('isRequired') === appEnum.Common.YN.yes}
          options={appDict.common.yn}
        />
      ),
    },
    {
      label: '最大长度',
      name: 'maxLength',
      initialValue: 1000,
      rules: [
        { required: true, message },
        { validator: validateBlInputNumberIntervalInt(1, 1000) },
      ],
      render: () => <InputNumber style={{ width: 300 }} placeholder="请输入" disabled={isEdit} />,
    },
    {
      label: '默认值',
      shouldUpdate: (prevValues: any, curValues: any) => {
        return prevValues.maxLength !== curValues.maxLength;
      },
      render: () => {
        return () => {
          return (
            <Form.Item
              name="defaultValue"
              validateFirst
              rules={[
                { validator: validatorUrl() },
                {
                  validator: validatorCharacterLen(form.getFieldValue('maxLength')),
                },
              ]}
            >
              <Input disabled={isPreField} placeholder="请输入" />
            </Form.Item>
          );
        };
      },
    },
  ];
};
