import { createContext } from 'react';
import { ParentPropsData, StoreHousePropsData } from './interface';

// 字段转化
export const FIELD_MAP = new Map([
  ['enableFlag', 'enable_flag'],
  ['bizType', 'biz_type'],
  ['isLock', 'is_lock'],
  ['name', 'name'],
  ['code', 'code'],
]);

// 仓库类型
export const BIZ_TYPE = new Map([
  [0, '车间仓库'],
  [1, '普通仓库'],
]);

export const BIZ_TYPE_ENUM = [
  { id: 0, name: '车间仓库' },
  { id: 1, name: '普通仓库' },
];

export const BIZ_TYPE__BACK_ENUM = {
  ORDINARY: 1, // 普通
  WORKSHOP: 0, // 车间
};

export const STATUS_ENUM = {
  ENABLED: 1,
  DISABLED: 0,
};

export const LOCK_ENUM_TYPE = [
  { id: 0, name: '未锁定' },
  { id: 1, name: '锁定' },
];

export const LOCK_STATUS = new Map([
  [0, '-'],
  [1, '锁定'],
]);

export const LOCK_STATUS_DISPLAY = new Map([
  [0, '未锁定'],
  [1, '锁定'],
]);

export const STORE_ENUM_TYPE = [
  { id: 0, name: '停用中' },
  { id: 1, name: '启用中' },
];

export const STATE_TYPE = new Map([
  [0, '停用中'],
  [1, '启用中'],
]);

export const STORAGE_LOCATION_TYPE = new Map([
  [0, '不启用'],
  [1, '启用中'],
]);

export const STORAGE_LOCATION_TYPE_ENUM = [
  { id: 0, name: '不启用' },
  { id: 1, name: '启用中' },
];
export const LOCK_STATUS_DISPLAY_ENUM = {
  LOCK: 1,
  UNLOCK: 0,
};

export const LOCK_STATUS_ENUM = [
  { id: 0, name: '未锁定' },
  { id: 1, name: '锁定' },
];

// 仓库质量管理范围
export const QUALITY_ENUM = new Map([
  [1, '合格'],
  [2, '让步合格'],
  [3, '待检'],
  [4, '不合格'],
  [5, '暂控'],
]);
// 仓库质量管理范围
export const QUALITY_FIELD_ENUM = new Map([
  ['qualified', '合格'],
  ['concession_qualified', '让步合格'],
  ['waiting_for_check', '待检'],
  ['faulty', '不合格'],
  ['temp_control', '暂控'],
]);

export const QUALITY = [
  { id: 1, name: '合格' },
  { id: 2, name: '让步合格' },
  { id: 3, name: '待检' },
  { id: 4, name: '不合格' },
  { id: 5, name: '暂控' },
];
// 节点类型
export const STORAGE_TYPE = new Map([
  [0, '全工厂'],
  [1, '仓库'],
]);

// 节点枚举值
export const LEVEL_TYPE_ENUM = {
  FACTORY: 0,
  STORE: 1,
  LEVEL_FIRST: 2,
  LEVEL_SECOND: 3,
  LEVEL_THIRD: 4,
  LEVEL_FOURTH: 5, // 最后节点不能再新增了
};

export const STORAGE_TYPE_TYPE_ENUM = {
  storageType: 0, // 仓库
  areaStorageType: 1, // 区域
};

export interface storeContextType {
  parentAreaSource: ParentPropsData;
  storeHouseSource: StoreHousePropsData;
}

export const StoreContext = createContext<storeContextType>({
  parentAreaSource: {},
  storeHouseSource: {
    StoreHouseId: 0,
  },
});
