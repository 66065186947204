import React from 'react';
import { Form, Input, Select, FormInstance } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { NamePath } from 'antd/lib/form/interface';
import { ColumnProps } from 'antd/lib/table';
import { BlSortFormList, SearchSelect, MaterialSelect, TagList } from 'src/components';
import { UsageStatus, YN } from 'src/dict/common';
import { getMaterialUnitInfo, getMaterialUnits, getMaterialAttrs } from 'src/entity/material';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import _, { isEqual } from 'lodash';
import BatchSearchSelect from '../../batchManagement/components/batchSearchSelect';
import { injectCustomFieldColumns } from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { TransferTypeEnum } from 'src/dict/bound';

interface Props {
  form: FormInstance;
  name: string;
  freezeLen: number;
  customFields?: any;
}

const width = '100%';

function TransferMaterial(props: Props) {
  const { form, name, freezeLen, customFields } = props;
  const getMaterialInfo = (namepath: NamePath) => {
    const value = JSON.parse(form.getFieldValue(namepath)?.value ?? '{}');

    return value;
  };
  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    // 字段项相同属性
    const getOptions = (field: any, fieldName: string) => ({
      fieldKey: [field.fieldKey, fieldName],
      name: [field.name, fieldName],
      validateFirst: true,
      validateTrigger: ['onChange', 'onBlur'],
      style: { marginBottom: '0' },
    });

    return [
      {
        dataIndex: 'lineNo',
        title: <div>行号</div>,
        width: 80,
        render: (text: string, field) => (
          <div key={field.name}>
            {field.name + 1}
            <Form.Item {...getOptions(field, 'lineNo')} initialValue={field.name + 1} hidden>
              <Input />
            </Form.Item>
          </div>
        ),
      },
      {
        dataIndex: 'materialId',
        title: '物料',
        width: 160,
        render: (text: string, field) => (
          <div key={field.key}>
            <Form.Item
              {...getOptions(field, 'materialId')}
              rules={[{ required: true, message: '请选择物料' }]}
            >
              <MaterialSelect
                params={{ enableFlag: UsageStatus.enabled }}
                onChange={(value: any) => {
                  if (!value) {
                    form.setFields([
                      {
                        name: [name, field.name, 'unitId'],
                        value: undefined,
                      },
                      {
                        name: [name, field.name, 'batchNo'],
                        value: undefined,
                      },
                    ]);
                  }
                }}
              />
            </Form.Item>
          </div>
        ),
      },
      {
        dataIndex: 'materialName',
        title: '物料名称',
        width: 160,
        render: (text: string, field: any) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() =>
                _.get(getMaterialInfo([name, field.name, 'materialId']), 'baseInfo.name') || '-'
              }
            </Form.Item>
          );
        },
      },
      {
        dataIndex: 'materialAttrs',
        title: '物料属性',
        width: 160,
        render: (text: string, field: any) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() => (
                <TagList
                  dataSource={getMaterialAttrs(getMaterialInfo([name, field.name, 'materialId']))}
                />
              )}
            </Form.Item>
          );
        },
      },
      {
        dataIndex: 'materialName',
        title: '物料规格',
        width: 160,
        render: (text: string, field: any) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() =>
                _.get(
                  getMaterialInfo([name, field.name, 'materialId']),
                  'baseInfo.specification',
                ) || '-'
              }
            </Form.Item>
          );
        },
      },
      {
        dataIndex: 'planAmount',
        title: '申请数量',
        width: 160,
        render: (text: string, field: any) => (
          <Form.Item
            dependencies={[
              [name, field.name, 'unitId'],
              [name, field.name, 'materialId'],
            ]}
            style={{ marginBottom: 0 }}
          >
            {() => {
              const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
                getMaterialInfo([name, field.name, 'materialId']),
                form.getFieldValue([name, field.name, 'unitId'])?.value,
              );

              return (
                <Form.Item
                  {...getOptions(field, 'planAmount')}
                  rules={_.compact([
                    { required: true, message: '请填写' },
                    {
                      validator: numberMinMaxCheck({
                        max: 10000000,
                        min: 0,
                        minAllowEqual: field.name >= freezeLen,
                      }),
                    },
                    enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
                  ])}
                >
                  <Input style={{ width }} placeholder="请输入" />
                </Form.Item>
              );
            }}
          </Form.Item>
        ),
      },
      {
        dataIndex: 'unitId',
        title: '单位',
        width: 160,
        render: (text: string, field: any) => (
          <Form.Item dependencies={[[name, field.name, 'materialId']]} style={{ marginBottom: 0 }}>
            {() => {
              return (
                <Form.Item
                  {...getOptions(field, 'unitId')}
                  rules={[{ required: true, message: '请选择单位' }]}
                >
                  <Select
                    labelInValue
                    placeholder={'请选择'}
                    options={getMaterialUnits(getMaterialInfo([name, field.name, 'materialId']))}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        ),
      },
      {
        dataIndex: 'batchNo',
        title: '指定批次号',
        width: 160,
        render: (text: string, field: any) => (
          <Form.Item dependencies={[[name, field.name, 'materialId']]} style={{ marginBottom: 0 }}>
            {() => {
              const material = getMaterialInfo([name, field.name, 'materialId']);

              return (
                <Form.Item {...getOptions(field, 'batchNo')}>
                  <BatchSearchSelect
                    labelInValue
                    disabled={!material?.baseInfo?.id}
                    params={{ materialIds: [material?.baseInfo?.id] }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        ),
      },
      {
        dataIndex: 'supplierId',
        width: 160,
        title: '指定供应商',
        render: (text: string, field: any) => (
          <div key={field.key}>
            <Form.Item {...getOptions(field, 'supplierId')}>
              <SearchSelect params={{ status: [YN.yes] }} labelInValue fetchType="supplier" />
            </Form.Item>
          </div>
        ),
      },
      {
        dataIndex: 'remark',
        title: '行备注',
        width: 160,
        render: (text: string, field: any) => (
          <div key={field.key}>
            <Form.Item {...getOptions(field, 'remark')}>
              <Input style={{ width }} placeholder="请输入" />
            </Form.Item>
          </div>
        ),
      },
    ];
  };

  return (
    <BlSortFormList
      buttonText="添加物料行"
      fixedRowFn={(field: any, index: number) => {
        const bizType = form.getFieldValue('bizType');
        const canDelete =
          bizType === TransferTypeEnum.picking || bizType === TransferTypeEnum.storage;

        return index < freezeLen && !canDelete;
      }}
      form={form}
      name={name}
      renderColumns={() =>
        injectCustomFieldColumns({
          columns: getColumns(), // 原本的列
          customFields, // 自定义字段信息
          objectCode: OBJECT_OF_CODE.transferOrderItem, // 从对象code
          type: 'form', // 使用类型
          formConfig: { form, formName: name },
        })
      }
      isNeedDrag={false}
    />
  );
}

export default React.memo(TransferMaterial, isEqual);
