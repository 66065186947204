import { ChangeEvent } from 'react';
import { replaceSign } from 'src/utils/constants';

const TextControl = ({
  value,
  onChange,
  format,
}: {
  value?: string | number;
  onChange?: (value: string | number) => void;
  format?: (value: string | number | undefined) => any;
}) => {
  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    onChange?.(value);
  };

  if (format) {
    return <div onChange={onValueChange}>{format(value) || replaceSign}</div>;
  }
  return <div onChange={onValueChange}>{value || replaceSign}</div>;
};
export default TextControl;
