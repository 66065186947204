import React, { useEffect, useState } from 'react';
import { Space, Button, Modal, SelectProps } from 'antd';
import { RecordListLayout } from 'src/layout';
import { fieldTypeList, gcArray, gcTime } from 'src/utils';
import { avatarDisplay } from 'src/components/avatar/show';
import {
  fetchResourcesBusinessType,
  fetchResourcesList,
  FetchResourcesListRequest,
} from 'src/api/ytt/resource-domain/resource';
import _ from 'lodash';
import { BlSearchSelect, SearchSelect, TagTooltip } from 'src/components';
import { Rule } from 'antd/lib/form';
import { FilterItem } from 'src/layout/recordList';
import {
  RESORCE_BUSINESS_TYPE,
  RESORCE_STATUS_VALUE,
  TypeEnum,
} from 'src/page/resource/definitions/constants';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import AreaCascader from 'src/components/Cascader/areaCascader';
interface SelectModalProps {
  visible: boolean;
  submitData: (data: number[], rows: any[]) => void;
  closeModal: () => void;
  isMultiple?: boolean;
  defaultSelected?: { id: number; name: string }[];
  params?: FetchResourcesListRequest;
}

type RecordType = {
  code: string;
  name: string;
  businessType: number;
  dataIndex: string;
  entityLinkCode: string;
  manufacturer: string;
};

interface Column {
  title: string;
  dataIndex: string;
  type?: number;
  isFilter?: boolean;
  width?: number;
  renderItem?: React.ReactNode;
  rules?: Rule[];
  dateFormat?: string;
  selectProps?: SelectProps<any>;
  brandModel?: number;
}
interface UserColumnProps {
  id: number;
  name: string;
  avatarUrl: string;
}

const ResourceSelectModal = (props: SelectModalProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[] | undefined>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { submitData, visible, closeModal, defaultSelected, isMultiple, params } = props;

  useEffect(() => {
    setSelectedRows(defaultSelected || []);
    setSelectedRowKeys(defaultSelected?.map(({ id }) => Number(id)));
  }, [defaultSelected]);

  const typeFormatter = ({ data }: any) => ({
    total: data?.total || 0,
    options:
      data?.map((item: any) => ({
        label: item.message,
        value: item.code,
        key: item.code,
      })) ?? [],
  });

  const fetchTableData = (params: any) => {
    params.status = RESORCE_STATUS_VALUE.ENABLED;

    return fetchResourcesList(params, { legacy: false });
  };

  const dataColumns = [
    {
      title: '设备编号',
      dataIndex: 'code',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
      fixed: 'left',
    },
    {
      title: '设备名称',
      dataIndex: 'name',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
    },
    {
      title: '业务类型',
      dataIndex: 'businessType',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      selectProps: {
        options: _.map(RESORCE_BUSINESS_TYPE, (label, value) => {
          return {
            label,
            value: Number(value),
          };
        }),
      },
      render: (type: { message: string }) => type?.message,
    },
    {
      title: '标识码',
      dataIndex: 'entityLinkCode',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
    },
    {
      title: '标识制造商',
      dataIndex: 'manufacturer',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
    },
    {
      title: '序列号',
      dataIndex: 'serialNo',
      type: fieldTypeList.text,
      width: 150,
      isFilter: true,
    },
    {
      title: '负责人',
      dataIndex: 'responsibleUser',
      isFilter: true,
      width: 150,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
      render: (users: any[]) => <TagTooltip list={users || []} max={2} />,
    },
    {
      title: '负责部门',
      dataIndex: 'responsibleDepartment',
      isFilter: true,
      width: 150,
      renderItem: (
        <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser={false} />
      ),
      render: (departments: any[]) =>
        departments?.map((department) => avatarDisplay({ ...department })),
    },
    {
      title: '出厂日期',
      dataIndex: 'outFactoryDate',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      isFilter: true,
      width: 150,
    },
    {
      title: '入厂日期',
      dataIndex: 'enterFactoryDate',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      isFilter: true,
      width: 150,
    },
    {
      title: '启用日期',
      dataIndex: 'enableDate',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      isFilter: true,
      width: 150,
    },
    {
      title: '存储区域',
      dataIndex: 'locationName',
      type: fieldTypeList.text,
      width: 150,
      renderItem: <AreaCascader placeholder="请输入选择存储区域" />,
    },
    {
      title: '品牌型号',
      dataIndex: 'brandModel',
      width: 150,
      render: (type: { message: string }) => type?.message,
      renderItem: () => <SearchSelect fetchType="brandModel" />,
    },
  ];

  const filterList: FilterItem[] = dataColumns
    .filter((i) => i.isFilter)
    .map((column: Column) => {
      const filter: any = {
        label: column.title,
        name: column.dataIndex,
        type: column.type,
        rules: column.rules,
        dateFormat: column.dateFormat,
        renderItem: column.renderItem,
      };

      if (column.type === fieldTypeList.select) {
        filter.selectProps = column.selectProps;
      }
      if (column.type === fieldTypeList.date && column.dateFormat) {
        filter.dateFormat = column.dateFormat;
      }
      return filter;
    });

  const formatDataToQuery = (data: any) => {
    let sendData = _.cloneDeep(data);

    if (params) {
      sendData = { ...params, ...sendData };
    }

    if (!gcArray.isEmpty(sendData.sorter)) {
      sendData.orderBy = sendData.sorter[0].field;
      sendData.orderDirection = sendData.sorter[0].order;
    }
    if (!gcArray.isEmpty(sendData.outFactoryDate)) {
      sendData.beginOutFactoryDate = gcTime.formatToUnix(sendData.outFactoryDate[0]);
      sendData.endOutFactoryDate = gcTime.formatToUnix(sendData.outFactoryDate[1]);
      delete sendData.outFactoryDate;
    }
    if (!gcArray.isEmpty(sendData.enterFactoryDate)) {
      sendData.beginEnterFactoryDate = gcTime.formatToUnix(sendData.enterFactoryDate[0]);
      sendData.endEnterFactoryDate = gcTime.formatToUnix(sendData.enterFactoryDate[1]);
      delete sendData.enterFactoryDate;
    }
    if (!gcArray.isEmpty(sendData.enableDate)) {
      sendData.beginEnableDate = gcTime.formatToUnix(sendData.enableDate[0]);
      sendData.endEnableDate = gcTime.formatToUnix(sendData.enableDate[1]);
      delete sendData.enableDate;
    }
    if (sendData.businessType) {
      sendData.businessType = Number(sendData.businessType);
    }

    if (!gcArray.isEmpty(sendData.responsibleDepartment)) {
      sendData.departmentIdList = sendData.responsibleDepartment.map(
        (department: { value: number }) => department.value,
      );
      delete sendData.responsibleDepartment;
    }

    if (!gcArray.isEmpty(sendData.responsibleUser)) {
      sendData.userIdList = sendData.responsibleUser.map((user: { value: number }) => user.value);
      delete sendData.responsibleUser;
    }

    if (!gcArray.isEmpty(sendData.responsibleDepartment)) {
      sendData.departmentIdList = sendData.responsibleDepartment.map(
        (department: { value: number }) => department.value,
      );
      delete sendData.responsibleDepartment;
    }

    if (!gcArray.isEmpty(sendData.responsibleUser)) {
      sendData.userIdList = sendData.responsibleUser.map((user: { value: number }) => user.value);
      delete sendData.responsibleUser;
    }

    return sendData;
  };

  const formatDataToDisplay = (data: any) => {
    const displayData = _.cloneDeep(data);

    if (!gcArray.isEmpty(displayData?.enableDate)) {
      displayData.enableDate = `${gcTime.formatLine(
        displayData.enableDate[0],
      )} ~ ${gcTime.formatLine(displayData.enableDate[1])}`;
    }
    if (!gcArray.isEmpty(data?.enterFactoryDate)) {
      displayData.enterFactoryDate = `${gcTime.formatLine(
        displayData.enterFactoryDate[0],
      )} ~ ${gcTime.formatLine(displayData.enterFactoryDate[1])}`;
    }
    if (!gcArray.isEmpty(displayData.outFactoryDate)) {
      displayData.outFactoryDate = `${gcTime.formatLine(
        displayData.outFactoryDate[0],
      )} ~ ${gcTime.formatLine(displayData.outFactoryDate[1])}`;
    }
    return displayData;
  };

  return (
    <Modal
      visible={visible}
      width={1000}
      title={'选择设备'}
      onCancel={() => closeModal()}
      footer={null}
      bodyStyle={{ padding: 0, display: 'felx', height: 600 }}
    >
      <RecordListLayout<RecordType>
        filterList={filterList}
        requestFn={fetchTableData}
        useQuickFilter={false}
        useFilterWithUrl={false}
        filterContaniner={false}
        mainMenu={[]}
        batchMenu={[]}
        formatDataToQuery={formatDataToQuery} // 转换搜索条件进行查询（快速查询字段："quickSearch",当前页："page",分页大小："size"）
        formatDataToDisplay={formatDataToDisplay} // 转换搜索条件进行展示
        columns={dataColumns} // table columns
        selectedRowKeys={selectedRowKeys}
        rowKey="id"
        onSelectedRowKeys={(selectKey, selectRows) => {
          const allData = (defaultSelected ?? []).concat(selectRows ?? []);

          setSelectedRowKeys(selectKey.map((key) => Number(key)));
          setSelectedRows(
            selectKey?.map((key) => ({ id: key, ..._.find(allData, (i) => i.id === key) })) || [],
          );
        }}
        style={{ height: 530 }}
      />
      <Space
        align={'center'}
        size={'large'}
        style={{
          padding: 10,
          justifyContent: 'center',
          borderTop: '1px solid #b1b1b12e',
          width: '100%',
          position: 'absolute',
          bottom: 0,
        }}
      >
        <Button onClick={() => closeModal()}>取消</Button>
        <Button
          type="primary"
          onClick={() => {
            submitData(selectedRowKeys || [], selectedRows);
            closeModal();
          }}
        >
          确认
        </Button>
      </Space>
    </Modal>
  );
};

export default ResourceSelectModal;
