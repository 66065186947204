import { lookup, appEnum } from 'src/dict';
import { fieldTypeList, statusValue } from 'utils';

const baseInfoItems = {
  [appEnum.CustomField.FieldType.text]: [
    { label: '最大长度', dataIndex: 'maxLength' },
    {
      label: '必填',
      dataIndex: 'isRequired',
      render: lookup('common.yn'),
    },
    {
      label: '数据唯一性',
      dataIndex: 'isUnique',
      render: lookup('common.yn'),
    },
    { label: '默认值', dataIndex: 'defaultValue' },
  ],
  [appEnum.CustomField.FieldType.textArea]: [
    { label: '最大长度', dataIndex: 'maxLength' },
    {
      label: '必填',
      dataIndex: 'isRequired',
      render: lookup('common.yn'),
    },
    { label: '默认值', dataIndex: 'defaultValue' },
  ],
  [appEnum.CustomField.FieldType.appendix]: [
    { label: '文件数量', dataIndex: ['extInfo', 'fileCount'] },
    {
      label: '必填',
      dataIndex: 'isRequired',
      render: lookup('common.yn'),
    },
  ],
  [appEnum.CustomField.FieldType.select]: [
    {
      label: '必填',
      dataIndex: 'isRequired',
      render: lookup('common.yn'),
    },
  ],
  [appEnum.CustomField.FieldType.multiSelect]: [
    {
      label: '必填',
      dataIndex: 'isRequired',
      render: lookup('common.yn'),
    },
  ],
  [appEnum.CustomField.FieldType.boolean]: [
    {
      label: '必填',
      dataIndex: 'isRequired',
      render: lookup('common.yn'),
    },
    {
      label: '默认值',
      dataIndex: 'defaultValue',
      render: (value: string) => (value ? lookup('common.yn', Number(value)) : '-'),
    },
  ],
  [appEnum.CustomField.FieldType.number]: [
    { label: '最大值', dataIndex: 'maxValue' },
    { label: '小数位数', dataIndex: 'decimalNumber' },
    {
      label: '必填',
      dataIndex: 'isRequired',
      render: lookup('common.yn'),
    },
    { label: '默认值', dataIndex: 'defaultValue' },
  ],
  [appEnum.CustomField.FieldType.integer]: [
    { label: '最大值', dataIndex: 'maxValue' },
    {
      label: '必填',
      dataIndex: 'isRequired',
      render: lookup('common.yn'),
    },
    { label: '默认值', dataIndex: 'defaultValue' },
  ],
  [appEnum.CustomField.FieldType.date]: [
    { label: '日期格式', dataIndex: 'datetimeFormat' },
    {
      label: '必填',
      dataIndex: 'isRequired',
      render: lookup('common.yn'),
    },
    { label: '默认值', dataIndex: 'defaultValue' },
  ],
  [appEnum.CustomField.FieldType.url]: [
    { label: '最大长度', dataIndex: 'maxLength' },
    {
      label: '必填',
      dataIndex: 'isRequired',
      render: lookup('common.yn'),
    },
    { label: '默认值', dataIndex: 'defaultValue' },
  ],
  [appEnum.CustomField.FieldType.reference]: [
    {
      label: '引用字段',
      dataIndex: 'referenceChainDisplay',
    },
  ],
  [appEnum.CustomField.FieldType.relation]: [
    {
      label: '关联对象',
      dataIndex: 'referenceDisplay',
    },
    {
      label: '关联类型',
      dataIndex: 'targetType',
      render: lookup('customField.targetType'),
    },
    {
      label: '级联删除',
      dataIndex: 'deleteType',
      render: () => '提示不允许删除',
    },
    {
      label: '访问控制规则',
      dataIndex: 'viewRule',
      render: () => '自身控制',
    },
    {
      label: '可选数据范围',
      dataIndex: 'dataRange',
      render: () => '全部',
    },
    {
      label: '必填',
      dataIndex: 'isRequired',
      render: lookup('common.yn'),
    },
    {
      label: '数据唯一性',
      dataIndex: 'isUnique',
      render: lookup('common.yn'),
    },
  ],
  [appEnum.CustomField.FieldType.relationSub]: [
    {
      label: '关联对象',
      dataIndex: 'referenceDisplay',
    },
    {
      label: '关联类型',
      dataIndex: 'targetType',
      render: lookup('customField.targetType'),
    },
  ],
  [appEnum.CustomField.FieldType.relationSub]: [
    {
      label: '关联对象',
      dataIndex: 'referenceDisplay',
    },
    {
      label: '关联类型',
      dataIndex: 'targetType',
      render: lookup('customField.targetType'),
    },
  ],
  [appEnum.CustomField.FieldType.subordinate]: [
    {
      label: '关联对象',
      dataIndex: 'referenceDisplay',
    },
    {
      label: '相关列表编号',
      dataIndex: 'referCode',
    },
    {
      label: '相关列表名称',
      dataIndex: 'referName',
    },
    {
      label: '级联删除',
      render: () => '主对象删除从对象删除',
    },
    {
      label: '访问控制规则',
      render: () => '受父对象控制',
    },
    {
      label: '从对象必填',
      dataIndex: 'childNecessary',
      render: lookup('common.yn'),
    },
  ],
};

export { baseInfoItems };
