import { fetchEReportInstanceInitiateApproval } from 'src/api/ytt/e-report-domain/electronicDocuments';
import { message as Message } from 'antd';
import _Time from 'src/utils/dataTypes/time';
import { filter, map } from 'lodash';
import { FieldType } from 'src/dict/common';

export const submitApprove = async (id: number, refresh: () => void) => {
  try {
    const { data, message } = await fetchEReportInstanceInitiateApproval({ instanceId: id });

    if (data) {
      refresh();
      return Message.success('提交审批成功');
    }
    Message.error(message);
  } catch (error) {
    console.log('error: ', error);
  }
};

export const renderElectronicValueDisplay = (node: any) => {
  const { choiceValues, fieldValue, fieldType, condition } = node;

  let display = fieldValue;

  if (fieldType === FieldType.date) {
    display = fieldValue ? _Time.format(Number(fieldValue)) : '';
  }

  if (fieldType === FieldType.multiSelect) {
    display = map(
      filter(choiceValues, (e: any) => fieldValue?.includes(e?.choiceCode)),
      'choiceValue',
    ).join(',');
  }

  if (fieldType === FieldType.boolean) {
    // 布尔值
    display = map(
      filter(
        [
          { choiceCode: 'true', choiceValue: '是' },
          { choiceCode: 'false', choiceValue: '否' },
        ],
        (e: any) => fieldValue?.includes(e?.choiceCode),
      ),
      'choiceValue',
    ).join(',');
  }

  if (fieldType === FieldType.select && condition === 'NOT IN') {
    display = map(
      filter(choiceValues, (e: any) => fieldValue?.includes(e?.choiceCode)),
      'choiceValue',
    ).join(',');
  }

  if (condition === 'IS NULL' || condition === 'IS NOT NULL') {
    display = '';
  }

  return display;
};
