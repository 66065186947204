import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { FilterItem, RecordListLayout } from 'src/layout';
import moment from 'moment';
import _ from 'lodash';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { useDispatch } from 'react-redux';
import { Tooltip, BlIcon } from 'src/components';
import { paramType, dataCount, rpStatus, ppStatus } from 'src/dict/resources/mappings';
import ResourceClassificationCascader from '../../components/ResourceClassificationCascader';
import { replaceSign } from 'src/utils/constants';
import { fieldTypeList } from 'src/utils';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { fetchResourcesDetail } from 'src/api/ytt/resource-domain/resource';
import { fetchInstrumentDetail } from 'src/api/ytt/resource-domain/energyMeter';
import { ListItemType } from 'src/types';
import {
  fetchResourceMonitorList,
  FetchResourceMonitorListResponse,
} from 'src/api/ytt/resource-domain/resourceMonitor';
import { FetchResourceTypeListResponse } from 'src/api/ytt/resource-domain/resourceCategory';
import authDict from 'src/utils/auth';

interface Props {
  definId: number | undefined;
  businessType: number;
  // refreshMarker?: number;
}

type CascaderDataItemType = {
  label: string;
  value: string;
  children?: CascaderDataItemType[];
  parent?: CascaderDataItemType;
};
type rCLType = ListItemType<FetchResourceTypeListResponse>;
type rMLType = ListItemType<FetchResourceMonitorListResponse>;

export default function ListView(props: Props) {
  const { definId, businessType } = props;
  const [resetRefreshMarker, setResetRefreshMarker] = useState<number | undefined>(undefined);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [selectOptions, setSelectOptions] = useState([]);

  const dispatch = useDispatch();
  const openModal = useOpenExImportModal();
  const cascaderFormatData = (list: rCLType[]): CascaderDataItemType[] => {
    const data: any = list?.map((item: rCLType) => {
      if (item.children) {
        return {
          label: item.name,
          value: item.name,
          key: item.id,
          children: cascaderFormatData(item.children as rCLType[]),
        };
      }
      return {
        label: item.name,
        value: item.name,
        key: item.id,
      };
    });

    return data || [];
  };

  const dataColumns = [
    {
      title: '资源编号',
      dataIndex: 'resourceId',
      // sorter: true,
      fixed: 'left',
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '资源名称',
      dataIndex: 'resourceName',
      fixed: 'left',
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '参数名称',
      dataIndex: 'paramName',
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '参数类型',
      dataIndex: 'paramType',
      isFilter: true,
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '参数分类',
      dataIndex: 'paramClassification',
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '参数单位',
      dataIndex: 'paramUnit',
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '参数值',
      dataIndex: 'val',
      width: 100,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '写入时间',
      dataIndex: 'ts',
      width: 110,
      render: (createdAt: number) => {
        if (!createdAt) return replaceSign;
        return moment(Math.floor(createdAt / 1000)).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: '记录时间',
      dataIndex: 'recordTime',
      // fixed: 'right',
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '开始时间',
      dataIndex: 'beginTime',
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '创建方式',
      dataIndex: 'createType',
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '资源参数异常',
      dataIndex: 'rpStatus',
      width: 140,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '工艺参数异常',
      dataIndex: 'ppStatus',
      width: 140,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '资源参数标准值',
      dataIndex: 'rpStandardVal',
      width: 150,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '资源参数上限',
      dataIndex: 'rpUpLimit',
      width: 140,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '资源参数上上限',
      dataIndex: 'rpUpsLimit',
      width: 150,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '资源参数下限',
      dataIndex: 'rpDownLimit',
      width: 140,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '资源参数下下限',
      dataIndex: 'rpDownsLimit',
      width: 150,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '关联任务类型',
      dataIndex: 'refTaskType',
      width: 140,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '关联任务编号',
      dataIndex: 'refTaskId',
      width: 140,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '工艺参数标准值',
      dataIndex: 'ppStandardVal',
      width: 150,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '工艺参数上限',
      dataIndex: 'ppUpLimit',
      width: 140,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '工艺参数上上限',
      dataIndex: 'ppUpsLimit',
      width: 150,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '工艺参数下限',
      dataIndex: 'ppDownLimit',
      width: 140,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '工艺参数下下限',
      dataIndex: 'ppDownsLimit',
      width: 150,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '当前绑定参数',
      name: 'paramIds',
      type: fieldTypeList?.multiSelect,
      renderItem: (
        <Select
          placeholder="请输入参数名称"
          mode="multiple"
          options={selectOptions}
          filterOption={(input, option) => {
            return (option!.label as unknown as string)?.includes(input);
          }}
          // onChange={(type: any) => {
          // }}
        />
      ),
    },
    {
      label: '参数分类',
      name: 'paramClassifications',
      type: fieldTypeList.relation,
      renderItem: (
        <ResourceClassificationCascader
          onChange={() => {}}
          showSearch
          multiple
          formatData={cascaderFormatData}
          placeholder={'请选择参数分类'}
          enableAddLabel={'新建分类'}
          onCreateClick={() => {}}
          enableAdd={false}
        />
      ),
    },
    {
      label: '参数类型',
      name: 'paramTypes',
      type: fieldTypeList?.multiSelect,
      renderItem: (
        <Select
          placeholder="请选择参数类型"
          mode="multiple"
          options={paramType}
          filterOption={(input, option) => {
            return (option!.label as unknown as string)?.includes(input);
          }}
          // onChange={(type: any) => {
          // }}
        />
      ),
    },
    {
      label: '记录时间',
      name: 'recordTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '资源参数异常',
      name: 'rpStatuses',
      type: fieldTypeList?.multiSelect,
      renderItem: (
        <Select
          placeholder="请选择资源参数异常"
          mode="multiple"
          options={rpStatus}
          filterOption={(input, option) => {
            return (option!.label as unknown as string)?.includes(input);
          }}
          // onChange={(type: any) => {
          // }}
        />
      ),
    },
    {
      label: '工艺参数异常',
      name: 'ppStatuses',
      type: fieldTypeList?.multiSelect,
      renderItem: (
        <Select
          placeholder="请选择工艺参数异常"
          mode="multiple"
          options={ppStatus}
          filterOption={(input, option) => {
            return (option!.label as unknown as string)?.includes(input);
          }}
          // onChange={(type: any) => {
          // }}
        />
      ),
    },
    {
      label: '数据量',
      name: 'dataCount',
      type: fieldTypeList.select,
      selectProps: {
        options: dataCount,
        defaultValue: 5000,
        // mode: 'multiple',
      },
    },
  ];
  const openModalFunc = (optType: string) => {
    openModal({
      optType,
      businessType: BUSINESS_TYPE.paramMonitor,
    });
  };

  const mainMenu = _.compact([
    ...(definId
      ? [
          {
            title: ' ',
            icon: <BlIcon type="iconshuaxin" />,
            onClick: () => {
              setResetRefreshMarker(Date.now());
            },
          },
          {
            title: '导出',
            showExport: true,
            auth: authDict.resourceparametermonitoring_export,
            icon: <BlIcon type="icondaochu" />,
            onClick: () => {
              openModalFunc('export');
            },
          },
        ]
      : []),
  ]);

  const formatDataToQuery = (params: any) => {
    const { paramIds, paramClassifications, paramTypes, recordTime, dataCount, ...rest } = params;
    const queryParams = {
      dataCount: dataCount ?? 5000,
      deviceId: definId,
      paramTypes,
      paramIds,
      ...rest,
    };

    if (!_.isEmpty(paramClassifications)) {
      queryParams.paramClassifications = [paramClassifications.join('/')];
    }
    if (recordTime) {
      queryParams.beginRecordTime = new Date(recordTime[0]).getTime();
      queryParams.endRecordTime = new Date(recordTime[1]).getTime();
    }
    dispatch.excelImport.updateBusinessData({
      businessData: { ...queryParams },
    });

    return {
      ...queryParams,
    };
  };
  const formatDataToDisplay = (params: any = {}) => {
    const retData = { ...params };

    return retData;
  };
  const fetchData = async (id: number) => {
    try {
      const fetchDetail = [fetchResourcesDetail, fetchInstrumentDetail];
      const res: any = await fetchDetail[businessType]({ id });
      const list = res?.data?.resourceParamsList?.map((item: any) => {
        const { paramsId, paramsName } = item;

        return {
          value: paramsId,
          key: paramsId,
          label: paramsName,
        };
      });

      setSelectOptions(list);
      setRefreshMarker(Date.now());
    } catch (err) {
      console.log(err);
    }
  };
  const getData = async (params: any) => {
    if (definId) {
      const res = await fetchResourceMonitorList(params);

      return res ?? [];
    }

    return [];
  };
  const initList = async () => {
    await setSelectOptions([]);
    await setResetRefreshMarker(Date.now());
    if (definId) {
      fetchData(definId);
    }
  };

  useEffect(() => {
    initList();
  }, [definId]);

  return (
    <RecordListLayout
      useIndex={false}
      columns={dataColumns}
      rowKey={(record: rMLType) =>
        `${record.deviceId ?? ''}-${record.paramId ?? ''}-${record.ts ?? ''}`
      }
      mainMenu={mainMenu}
      formatDataToQuery={formatDataToQuery}
      formatDataToDisplay={formatDataToDisplay}
      // useColConfig={false}
      filterList={filterList}
      useQuickFilter={false}
      useFilterWithUrl={false}
      requestFn={getData}
      resetRefreshMarker={resetRefreshMarker}
      refreshMarker={refreshMarker}
    />
  );
}
