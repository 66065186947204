import { useEffect, useState } from 'react';
import _ from 'lodash';
import { RecordListLayout } from 'layout';
import { fetchAttributeAdjustOrderListRecord } from 'src/api/ytt/inventory-domain/propertyChangeLog';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { SearchSelect } from 'src/components';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { gcObject, fieldTypeList } from 'utils';
import { InventoryChangeLogProps } from './types';
import { renderUser } from 'src/page/share/renderUser';
import lookup from 'src/dict';
import { replaceSign } from 'src/utils/constants';
import { fetchCustomFieldInstanceGetByObjectCode } from 'src/api/ytt/custom-object-domain';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { injectCustomFieldColumns } from 'src/components/customField';

const List = () => {
  const [refreshMarker, setRefreshMarker] = useState<number>(Date.now()); // 刷新页面
  const [customFields, setCustomFields] = useState<any>();
  const refresh = () => setRefreshMarker(Date.now());

  const dataColumns: any[] = [
    {
      title: '仓库',
      dataIndex: ['originalInvElement', 'location', 'warehouse', 'name'],
      width: 150,
    },
    {
      title: '仓位',
      dataIndex: ['originalInvElement', 'location', 'location', 'name'],
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
      key: 'locationId',
      renderItem: <SearchSelect placeholder={'请选择仓位'} fetchType="storageLocation" />,
    },
    {
      title: '业务类型',
      dataIndex: 'bizType',
      width: 150,
      render: (bizType: number) => {
        return lookup('warehouse.recordPropertyExecuteTypeEnum', bizType);
      },
    },
    {
      title: '物料编号',
      dataIndex: ['material', 'code'],
      width: 150,
    },
    {
      title: '物料名称',
      dataIndex: ['material', 'name'],
      width: 150,
    },
    {
      title: '物料',
      dataIndex: ['material', 'id'],
      isFilter: true,
      disabled: true,
      type: fieldTypeList.text,
      key: 'materialId',
      renderItem: <SearchSelect placeholder="请选择物料" fetchType="materialCodeName" />,
    },
    {
      title: '关联单号',
      dataIndex: 'relatedNumber',
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
      key: 'relatedNumber',
    },
    {
      title: '数量',
      dataIndex: ['originalInvElement', 'showAmoutMasterUnit'],
      width: 150,
      render: (amount: any) => {
        return amount ? amount?.amountDisplay + amount?.unitName : replaceSign;
      },
    },
    {
      title: '质量状态',
      dataIndex: ['originalInvElement', 'qcStatus', 'message'],
      width: 150,
    },
    {
      title: '标识码',
      dataIndex: ['originalInvElement', 'qrCode'],
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
      key: 'qrCode',
    },
    {
      title: '供应商',
      dataIndex: ['originalInvElement', 'supplier', 'name'],
      width: 150,
    },
    {
      title: '供应商批次',
      dataIndex: ['originalInvElement', 'supplierBatch'],
      width: 150,
    },
    {
      title: '客户',
      dataIndex: ['originalInvElement', 'customer', 'name'],
      width: 150,
    },
    {
      title: '批号',
      dataIndex: ['originalInvElement', 'batchNo'],
      width: 150,
    },
    {
      title: '入厂日期',
      dataIndex: ['originalInvElement', 'inboundTime'],
      width: 200,
      render: formatTimeForRender,
    },
    {
      title: '生产日期',
      dataIndex: ['originalInvElement', 'productTime'],
      width: 200,
      render: formatTimeForRender,
    },
    {
      title: '有效期至',
      dataIndex: ['originalInvElement', 'validityPeriod'],
      width: 200,
      render: formatTimeForRender,
    },
    {
      title: '操作人',
      dataIndex: 'creator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      key: 'operatorId',
      render: renderUser,
      renderItem: (
        <UserOrDepartmentSelectWithDialog
          placeholder={'请选择操作人'}
          isMultiple={false}
          isSelectUser
        />
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 200,
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      key: 'operateDate',
      render: formatTimeForRender,
    },
    {
      title: '',
      dataIndex: 'action',
      width: 60,
      fixed: 'right',
      render: () => ' ',
    },
  ];

  const filterList = dataColumns
    .filter((i) => i.isFilter)
    .map((column) => {
      const filter = {
        label: column.title,
        name: column.key,
        type: column.type,
        renderItem: column.renderItem,
      } as any;

      return filter;
    });

  const formatDataToQuery = (params: any) => {
    const { operateDate, quickSearch, locationId, materialId, operatorId, ...rest } = params || {};
    const [dateStart, dateEnd] = operateDate || [];

    const options = {
      locationId: locationId?.value,
      materialId: materialId?.value,
      operatorId: operatorId?.[0]?.value,
      qrCode: quickSearch,
      createdAtFrom: dateStart?.startOf('day').valueOf(),
      createdAtTill: dateEnd?.endOf('day').valueOf(),
      ...rest,
    };

    return gcObject.filterEmptyProperty(options);
  };
  const requestFn = (params: any) => {
    const data = fetchAttributeAdjustOrderListRecord(params, { legacy: false });

    data.then((json: any) => {
      json.data.list.map((item) => {
        if (!item?.originalInvElement) {
          item.originalInvElement = {};
        }
        item.originalInvElement.showAmoutMasterUnit = item?.originalInvElement?.beforeAmount;
        item.invElement = item.invElement.map((value) => {
          value.showAmoutMasterUnit = value?.changeAmountMasterUnit;
          return {
            originalInvElement: value,
            createdAt: item.createdAt,
            creator: item.creator,
          };
        });
      });
    });
    return data;
  };

  /** 获取对象自定义字段 */
  const fetchCustomFields = async () => {
    const res = await fetchCustomFieldInstanceGetByObjectCode({
      objectCode: OBJECT_OF_CODE.attrAdjustRecord,
    });

    setCustomFields((res?.data as any) || {});
  };

  useEffect(() => {
    fetchCustomFields();
  }, []);

  return (
    <>
      <RecordListLayout<InventoryChangeLogProps>
        columns={injectCustomFieldColumns({
          columns: dataColumns.filter((d) => !d.disabled), // 原本的列
          customFields, // 自定义字段信息
          objectCode: OBJECT_OF_CODE.attrAdjustRecord, // 从对象code
          type: 'detail', // 使用类型
        })}
        filterList={filterList}
        rowKey={'id'}
        useIndex={false}
        expandable={{ childrenColumnName: 'invElement' }}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={_.identity}
        formatDataToFormDisplay={_.identity}
        configcacheKey={'propertyChangeRecord'}
        requestFn={requestFn}
        refreshMarker={refreshMarker}
        placeholder={'输入标识码，回车快速搜索'}
      />
    </>
  );
};

export default List;
