import { useState, useEffect, useMemo } from 'react';
import { Prompt } from 'react-router-dom';
import { RouteChildrenProps } from 'react-router';
import { useSelector } from 'react-redux';
import {
  cloneDeep,
  debounce,
  isEmpty,
  get,
  unionBy,
  find,
  isEqual,
  omit,
  map,
  filter,
  compact,
  isString,
  some,
  includes,
} from 'lodash';
import { Form, Checkbox, Tabs, Badge, Button, Radio, DatePicker, Input, Select } from 'antd';
import moment from 'moment';

import { DataFormLayout } from 'src/layout';
import lookup, { appDict, appEnum } from 'src/dict';
import { RootState } from 'src/store';
import { arrayIsEmpty } from 'src/utils/array';
import { FieldCategory, ObjectCode, YN } from 'src/dict/common';
import { yn } from 'src/dict/common/mappings';
import _Time from 'src/utils/dataTypes/time';
import { judgeMaterialIsVirtual } from 'src/entity/material';
import { SubItemMaterialForm } from 'src/page/knowledgeManagement/engineerData/bom/createAndEdit/subItemMaterialForm';
import { CoproductForm } from 'src/page/knowledgeManagement/engineerData/bom/createAndEdit/coproductForm';
import { ProcessRoutingForm } from 'src/page/knowledgeManagement/engineerData/processRouting/components';

import {
  formatMaterialToForm,
  setAssociatedFormFields,
} from 'src/page/knowledgeManagement/engineerData/bom/utils';
import { fetchSalesOrderListSimple } from 'src/api/ytt/order-domain/salesOrder';
import { BcAttachmentForm, NumberRulesStandardHook, SearchSelect } from 'src/components';
import { validatorCheckTwoSidesTrim } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import TextArea from 'antd/lib/input/TextArea';
import { textValidator1, textValidator2 } from 'src/utils/formValidators';
import {
  ProductionOrderTypesEnumDisplayMap,
  BusinessStatusEnumDisplayMap,
  SuspendedStatusEnumDisplayMap,
} from 'src/dict/productionPlanning/mappings';
import { getDepartmentDetail } from 'src/services/organization/userManage';
import { validateBlInputText3 } from 'src/page/custom_fields/fieldsList/components/verificationRules/verificationRules';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { BusinessStatusEnum } from 'src/dict/productionPlanning';
import { useCustomLayoutForEdit } from 'src/components/customLayout/hooks/useCustomLayoutForEdit';

import { ProductionOrderTab, ProductionOrderTabMap } from '../constants';
import { WorkOrderRequest, ProductionOrderForm } from './index.type';
import { OtherInfoForm } from './otherInfoForm';
import SourcesForm from './sourcesForm';
import { coproductExtraColumns, subItemMaterialExtraColumns } from './productionOrderColumns';
import {
  formatProductionOrderOutputMaterialsToApi,
  formatProductionOrderInputMaterialsToApi,
  formatProcessPlanCOToApi,
  formatWorkOrderBaseCOToApi,
  formatWorkOrderSourceCOToApi,
  formatWorkOrderBaseCOToForm,
  formatBomOutputMaterialsToForm,
  formatBomInputMaterialsToForm,
  formatProcessPlanCOToForm,
  formatWorkOrderSourceCOToForm,
} from './utils';
import { toProductionOrderList } from '../navigation';
import { gcUrl } from 'src/utils';
import { formatCustomFieldValueToSubmit } from 'src/components/customLayout/uitls/dataFormat';

interface ProductionOrderFormProps extends RouteChildrenProps<{ id: string }> {
  type: string;
  onSubmit?: (value: any, cb?: () => void, draftSubmitFlag?: boolean) => void;
  initialValue?: any;
  loading: boolean;
}

interface TabList {
  key: ProductionOrderTab;
  errorCount: number;
}

const { TabPane } = Tabs;

/**
 * 格式化数据给form
 * @param value
 * @returns
 */
const formatValueToForm = (value: any): ProductionOrderForm | undefined => {
  if (isEmpty(value)) return undefined;

  const {
    bomOutputMaterials,
    bomInputMaterials,
    processPlanCO,
    workOrderBaseCO,
    workOrderSource,
    draftFlag,
    type,
    ...resValue
  } = value ?? {};

  const baseType: {
    status?: number;
    pauseFlag?: number;
    operator?: string;
    creator?: string;
  } = {};

  /** 复制初始值 */
  if (type === appEnum.Common.CRUD.copy) {
    baseType.status = appEnum.ProductionPlanning.BusinessStatusEnum.CREATED;
    baseType.pauseFlag = appEnum.ProductionPlanning.SuspendedStatusEnum.NOTSUSPENDED;
    baseType.operator = undefined;
    baseType.creator = undefined;
  }

  const workOrderType =
    workOrderBaseCO?.status === appEnum.ProductionPlanning.BusinessStatusEnum.DRAFT ? 'copy' : type;

  return {
    ...resValue,
    workOrderBaseCO:
      workOrderBaseCO && formatWorkOrderBaseCOToForm({ ...workOrderBaseCO, ...baseType, type }),
    workOrderSourceCO: workOrderSource && formatWorkOrderSourceCOToForm(workOrderSource),
    processPlanCO:
      processPlanCO && formatProcessPlanCOToForm({ ...processPlanCO, type: workOrderType }),
    bomInputMaterials:
      bomInputMaterials && formatBomInputMaterialsToForm(bomInputMaterials, workOrderType),
    bomOutputMaterials:
      bomOutputMaterials && formatBomOutputMaterialsToForm(bomOutputMaterials, workOrderType),
    draftFlag,
    enableSop: processPlanCO?.enableSop ? processPlanCO?.enableSop : YN.no,
  };
};

/**
 * 格式化数据给接口
 * @param value
 * @returns
 */
const formatValueToApi = (value: ProductionOrderForm): WorkOrderRequest => {
  const {
    bomInputMaterials,
    processPlanCO,
    workOrderBaseCO,
    workOrderSourceCO,
    bomOutputMaterials,
    draftFlag,
    enableSop,
    type,
    customFields,
    operateReason,
  } = value;

  return {
    draftFlag: !workOrderBaseCO.status || draftFlag, // 编辑草稿状态
    workOrderBaseCO: formatWorkOrderBaseCOToApi(workOrderBaseCO),
    bomOutputMaterials: formatProductionOrderOutputMaterialsToApi(bomOutputMaterials),
    bomInputMaterials: formatProductionOrderInputMaterialsToApi(bomInputMaterials),
    processPlanCO: formatProcessPlanCOToApi({ ...processPlanCO, enableSop, workOrderType: type }),
    workOrderSourceCO: formatWorkOrderSourceCOToApi(workOrderSourceCO),
    operateReason,
    customFields,
  };
};

/**
 * 格式化销售订单数据初始化界面
 * @param value
 * @returns
 */
const fmtSOToWO = async (value: any, form: any) => {
  if (!value) {
    return;
  }
  const { material, amount, orderCode, deliveryDate, lineNo } = value || {};

  await fetchSalesOrderListSimple({ code: orderCode }).then((res) => {
    const { data } = res;

    const recordItem = data?.list?.[0];
    const recordLine: any = recordItem?.items?.find((e) => e.lineNo === lineNo);

    form.setFieldsValue({
      bomOutputMaterials: [
        {
          materialId: formatMaterialToForm(material),
          plannedAmount: amount?.amount,
          lineSeq: 0,
          main: appEnum.Common.YN.yes,
        },
      ],
      workOrderSourceCO: {
        sourceSalesOrderList: [
          {
            salesOrderId: {
              label: orderCode,
              value: recordItem?.header?.id,
              key: recordItem?.header?.id,
            },
            salesOrderLine: {
              label: lineNo,
              value: JSON.stringify({
                materialId: recordLine?.material?.id,
                deliveryDate: recordLine?.deliveryDate,
              }),
              key: JSON.stringify({
                materialId: recordLine?.material?.id,
                deliveryDate: recordLine?.deliveryDate,
              }),
            },
            salesOrderDeliveryTime: _Time.format(deliveryDate),
          },
        ],
      },
    });
  });
};

const BaseForm = (props: ProductionOrderFormProps) => {
  const { type, history, onSubmit, initialValue, loading, match } = props;

  const currentUser = useSelector((state: RootState) => state.user.userInfo);

  const [keepCreate, setKeepCreate] = useState(false);

  const [displaySubItemMaterialForm, setDisplaySubItemMaterialForm] = useState(
    appEnum.Common.YN.yes,
  ); // 是否指定用料 控制用料清单显示隐藏

  const [sopEnable, setSopEnable] = useState(appEnum.Common.YN.no); // 是否启用sop 控制产出物料报工控件编号列

  const [tabList, setTabList] = useState<TabList[]>(
    Array.from(ProductionOrderTabMap.keys()).map((n) => ({ key: n, errorCount: 0 })), // tab列表和form错误数
  );
  const [activeTab, setActiveTab] = useState(
    Array.from(ProductionOrderTabMap.keys()).map((n) => `${n}`)[0],
  ); // 选中的tab
  const [isVirtualMaterial, setIsVirtualMaterial] = useState(false); // 父项物料业务范围是否是虚拟件

  const [form] = Form.useForm<ProductionOrderForm>();

  const formProps = {
    preserve: true,
    initialValues: formatValueToForm(initialValue),
    scrollToFirstError: true,
  };

  const [hasSaved, setHasSaved] = useState(false);

  const hasUnsavedChange = form.isFieldsTouched() && !hasSaved;

  useEffect(() => {
    if ((initialValue && type === appEnum.Common.CRUD.edit) || type === appEnum.Common.CRUD.copy) {
      const afterFormatInitialValue: any = formatValueToForm({ ...initialValue, type }) ?? {};

      /** 初始化用来创建指定用料flag 控制用料清单显示 */

      setDisplaySubItemMaterialForm(afterFormatInitialValue?.workOrderBaseCO?.specifiedMaterial);

      if (afterFormatInitialValue?.processPlanCO?.enableSop) {
        setSopEnable(afterFormatInitialValue?.processPlanCO?.enableSop);
      }

      setIsVirtualMaterial(judgeMaterialIsVirtual(initialValue?.material));
      form.setFieldsValue(afterFormatInitialValue);
    }
  }, [initialValue, type]);

  /**
   * 从销售订单创建工单 初始化数据
   */
  useEffect(() => {
    // 销售订单创建工单;
    if (!isEmpty(history.location.state) && type === appEnum.Common.CRUD.create) {
      fmtSOToWO(history?.location?.state, form);
    }

    // 工序计划初始框 + 产出物料主产出
    if (type === appEnum.Common.CRUD.create) {
      let planningDepartment: any = [];

      if (!arrayIsEmpty(currentUser?.departmentVOList)) {
        planningDepartment = [
          {
            label: currentUser?.departmentVOList?.[0]?.name,
            value: currentUser?.departmentVOList?.[0]?.id,
          },
        ];
      }

      form.setFields([
        {
          name: ['bomOutputMaterials'],
          value: [{}],
        },
        {
          name: ['workOrderBaseCO', 'planningUserId'],
          value: [{ label: currentUser.name, value: currentUser.id }],
        },
        {
          name: ['workOrderBaseCO', 'planningDepartmentId'],
          value: planningDepartment,
        },
      ]);
    }
  }, [history, type]);

  /**
   * 处理表单校验错误
   * @param error 不传时重置Tab错误数量
   */
  const handleFormError = (error?: unknown) => {
    const errorFields: { name: (string | number)[] }[] = get(error, 'errorFields', [{ name: [] }]);

    const newTabList: TabList[] = [];

    tabList.map(({ key }) => {
      let errorCount = 0;
      const tabField = ProductionOrderTabMap.get(key)?.filedName ?? '';

      if (!isEmpty(errorFields)) {
        unionBy([...errorFields], (item) => {
          if (find(item.name, (n) => isEqual(n, tabField))) errorCount++;
        });
      }

      newTabList.push({ key, errorCount });
    });

    setTabList(newTabList);
    setTimeout(form.validateFields);
  };

  /**
   * tab页切换
   */
  const handleTabChange = (activeKey: string) => {
    setActiveTab(activeKey);

    const { errorCount } = find(tabList, ['key', Number(activeKey)]) ?? {};

    !!errorCount && setTimeout(form.validateFields);
  };

  /**
   * 最终提交数据
   * @param value
   * @param draftFlag
   * @param draftSubmitFlag
   */
  const formatAndSave = (value = {} as any, draftFlag = false, draftSubmitFlag = false) => {
    Promise.all([formatValueToApi({ ...value, draftFlag, type })])
      .then((afterFormat) => {
        const value = afterFormat[0];

        value.customFields = formatCustomFieldValueToSubmit(
          form.getFieldValue('customFields'),
          layoutId,
        );

        if (typeof onSubmit === 'function') {
          onSubmit(
            value,
            () => {
              if (keepCreate) {
                form.resetFields();
              } else if (type === appEnum.Common.CRUD.create) {
                history.push(toProductionOrderList());
              } else {
                history.goBack();
              }
            },
            draftSubmitFlag,
          );
          setHasSaved(true);
        }
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  };

  /**
   * 保存方式有正常工单和草稿，并且草稿态下有提交和保存
   * 正常工单走form的校验后，then保存提交
   * 草稿工单走form的校验后，如果存在字段校验错误，catch中过滤忽略错误校验(计划开始结束时间、工序计划tab等)，符合过滤条件后保存提交
   * @param draftFlag 保存为草稿标识
   * @param draftSubmitFlag 保存草稿并提交标识
   */
  const handleFinish = async (draftFlag = false, draftSubmitFlag = false) => {
    form
      ?.validateFields()
      .then((res) => {
        formatAndSave(res, draftFlag, draftSubmitFlag);
      })
      .catch(async (error) => {
        const { errorFields, values } = error;
        const orderStatus = get(values, ['workOrderBaseCO', 'status']);
        const isDraftStatus = orderStatus === appEnum.ProductionPlanning.BusinessStatusEnum.DRAFT;

        form.scrollToField(errorFields?.[0]?.name);

        // 校验草稿状态时的字段
        const validatDraftStatusFields = (errorFields: any) => {
          // 草稿状态忽略校验字段信息
          const draftIgnoreCheckedFields = [
            'workOrderBaseCO.planStartTime',
            'workOrderBaseCO.planFinishTime',
            'processPlanCO',
            'bomOutputMaterials.batch',
          ];

          const otherErrorFields = filter(errorFields, ({ name }) => {
            // 过滤number类型，只判断 "string.string.string" 的格式
            const strName = filter(name, isString).join('.');

            return !some(draftIgnoreCheckedFields, (ignoreField) => includes(strName, ignoreField));
          });

          return isEmpty(otherErrorFields);
        };

        // (草稿状态 || 保存为草稿状态) && 不提交 && 校验字段的错误不包含草稿忽略字段
        if (
          (isDraftStatus || draftFlag) &&
          !draftSubmitFlag &&
          validatDraftStatusFields(errorFields)
        ) {
          formatAndSave(values, draftFlag, draftSubmitFlag);
        }
        handleFormError(error);
      });
  };

  /** 自定义字段自定义布局 */
  const { layoutId } = gcUrl.getParams();

  const { info } = useCustomLayoutForEdit({
    objectCode: ObjectCode.POConfig,
    layoutId,
    instanceId: Number(match?.params?.id),
    form,
    isCopy: type === appEnum.Common.CRUD.copy,
  });

  /**
   * 是否启用sop
   * 启用停用都清空相关的值
   * */
  const enableSopChange = (ele: any) => {
    const list = form.getFieldValue(['processPlanCO', 'processes']);

    setSopEnable(ele.target.value);

    if (ele.target.value) {
      form.setFieldsValue({
        processPlanCO: {
          processes: list.map((item: any) => ({ ...omit(item, 'reportIds') })),
        },
      });
    }

    // 用料清单
    const bomInputMaterials = cloneDeep(form.getFieldValue(['bomInputMaterials']));
    // 产出物料
    const bomOutputMaterials = cloneDeep(form.getFieldValue(['bomOutputMaterials']));
    // 工序
    const processPlanCOProcesses = cloneDeep(form.getFieldValue(['processPlanCO', 'processes']));

    /** 清空用料清单投料管控 投料控件编号 */
    const copyBomInputMaterials = setAssociatedFormFields(bomInputMaterials, [
      ['inputProcessId', undefined],
      [
        'bomFeedingControls',
        (value: any) => setAssociatedFormFields(value, [['inputSopControlId']]),
      ],
    ]);

    /** 清空产出物料 投料控件编号 */
    const copyBomOutputMaterials = setAssociatedFormFields(bomOutputMaterials, [
      ['outputProcessId', undefined],
      ['outputSopControlId', undefined],
    ]);

    /** 清空产出物料 投料控件编号 */
    const copyProcessPlanCO = setAssociatedFormFields(processPlanCOProcesses, [['sop', undefined]]);

    form.setFields([
      {
        name: ['bomInputMaterials'],
        value: copyBomInputMaterials,
      },
      {
        name: ['bomOutputMaterials'],
        value: copyBomOutputMaterials,
      },
    ]);

    form.setFieldsValue({
      processPlanCO: {
        processes: copyProcessPlanCO,
      },
    });
  };

  const coproductExtra = useMemo(
    () =>
      coproductExtraColumns({
        form,
        name: ProductionOrderTabMap.get(ProductionOrderTab.COPRODUCT)?.filedName ?? '',
        type,
        setSopEnable,
        sopEnable,
      }),
    [form, type, setSopEnable, sopEnable],
  );

  const renderBottom = () => {
    const tabWithComponent = new Map([
      [
        ProductionOrderTab.COPRODUCT,
        <div style={{ paddingBottom: 20 }}>
          <p style={{ margin: '4px 0px 16px' }}>产出信息</p>
          <CoproductForm
            form={form}
            name={ProductionOrderTabMap.get(ProductionOrderTab.COPRODUCT)?.filedName ?? ''}
            resetColumns={coproductExtra}
            fixedRowFn={(field: any, index: number) => {
              return index === 0;
            }}
            type={type}
          />
        </div>,
      ],
      [
        displaySubItemMaterialForm === appEnum.Common.YN.yes &&
          ProductionOrderTab.SUB_ITEM_MATERIAL,
        <div style={{ paddingBottom: 20 }}>
          <p style={{ margin: '4px 0px 16px' }}>用料清单</p>
          <SubItemMaterialForm
            form={form}
            name={ProductionOrderTabMap.get(ProductionOrderTab.SUB_ITEM_MATERIAL)?.filedName ?? ''}
            resetColumns={subItemMaterialExtraColumns({
              form,
              name:
                ProductionOrderTabMap.get(ProductionOrderTab.SUB_ITEM_MATERIAL)?.filedName ?? '',
              type,
            })}
            dependenciesFieldPath={{
              bomMaterial: ['bomOutputMaterials', 0, 'materialId'],
              processRoute: ['bomOutputMaterials', 0, 'processRouteId'],
              sop: ['bomOutputMaterials', 0, 'outputProcessId'],
            }}
            type={type}
            useType={1}
          />
        </div>,
      ],
      [
        ProductionOrderTab.PROCESS_PLAN,
        <Form form={form} labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
          <Form.Item
            dependencies={[['bomOutputMaterials', 0, 'processRouteId']]}
            label={'工艺路线编号:'}
          >
            {() => form.getFieldValue(['processPlanCO', 'workOrderProcessCode'])}
          </Form.Item>
          <Form.Item
            dependencies={[['bomOutputMaterials', 0, 'processRouteId']]}
            label={'工艺路线名称:'}
          >
            {() => form.getFieldValue(['processPlanCO', 'workOrderProcessName'])}
          </Form.Item>
          <Form.Item
            dependencies={[['bomOutputMaterials', 0, 'processRouteId']]}
            label={'工艺路线备注:'}
          >
            {() => form.getFieldValue(['processPlanCO', 'workOrderProcessRemark'])}
          </Form.Item>
          <Form.Item
            dependencies={[
              ['bomOutputMaterials', 0, 'processRouteId'],
              ['workOrderBaseCO', 'status'],
            ]}
          >
            {() => {
              // 编辑页除去草稿状态都禁用
              const disabledFlag =
                type === appEnum.Common.CRUD.edit &&
                ![BusinessStatusEnum.DRAFT].includes(
                  form.getFieldValue(['workOrderBaseCO', 'status']),
                );

              return (
                <Form.Item
                  name={'enableSop'}
                  label={'启用SOP:'}
                  initialValue={YN.no}
                  labelCol={{ span: 3 }}
                  wrapperCol={{ span: 20 }}
                >
                  <Radio.Group
                    onChange={(ele) => {
                      enableSopChange(ele);
                    }}
                    disabled={disabledFlag}
                  >
                    {yn.map(({ label, value }) => (
                      <Radio key={value} value={value}>
                        {label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            name={'processPlanCO'}
            initialValue={{
              processes: [{ processSeq: 1, processNum: '10' }],
            }}
          >
            <ProcessRoutingForm
              name="processPlanCO"
              form={form}
              isWorkOrder={1}
              isEditMode={Boolean(type === appEnum.Common.CRUD.edit)}
            />
          </Form.Item>
        </Form>,
      ],
      [
        ProductionOrderTab.SOURCE,
        <SourcesForm
          form={form}
          name={ProductionOrderTabMap.get(ProductionOrderTab.SOURCE)?.filedName ?? ''}
        />,
      ],
      [ProductionOrderTab.OTHER, <OtherInfoForm form={form} name={'workOrderBaseCO'} />],
    ]);

    /**
     * 父项物料是虚拟件时，需过滤
     */
    const filterVirtual = (item: TabList) => {
      const disabledTabs = [ProductionOrderTab.COPRODUCT]; // 不支持父项物料为虚拟件的tab

      if (isVirtualMaterial) {
        return !disabledTabs.includes(item.key);
      }
      return true;
    };

    return (
      <Tabs type="card" activeKey={activeTab} onChange={handleTabChange}>
        {map(filter(tabList, filterVirtual), ({ key, errorCount }) => {
          const title = ProductionOrderTabMap.get(key)?.title;

          return (
            <TabPane
              key={key}
              tab={
                <Badge size="small" count={errorCount}>
                  {title}
                </Badge>
              }
              style={{ height: '100%' }}
              forceRender
            >
              {tabWithComponent.get(key)}
            </TabPane>
          );
        })}
      </Tabs>
    );
  };

  const renderExtra = () => {
    return (
      <>
        {(type === appEnum.Common.CRUD.create || type === appEnum.Common.CRUD.copy) && (
          <Button
            type="primary"
            onClick={() => {
              // setIsDraft(true);
              handleFinish(true);
            }}
            htmlType="submit"
          >
            保存为草稿
          </Button>
        )}
        {type === appEnum.Common.CRUD.edit &&
          form.getFieldValue(['workOrderBaseCO', 'status']) ===
            appEnum.ProductionPlanning.BusinessStatusEnum.DRAFT && (
            <Button
              type="primary"
              onClick={() => {
                handleFinish(true, true);
              }}
              htmlType="submit"
            >
              保存并提交
            </Button>
          )}
        {type === appEnum.Common.CRUD.create && (
          <Checkbox
            onChange={() => {
              setKeepCreate(!keepCreate);
            }}
            checked={keepCreate}
          >
            连续新建
          </Checkbox>
        )}
      </>
    );
  };

  const baseInfoList = [
    {
      title: '基本信息',
      items: compact([
        type === appEnum.Common.CRUD.edit && {
          label: 'id',
          name: ['workOrderBaseCO', 'id'],
          hidden: true,
          render: () => <Input />,
        },
        ...NumberRulesStandardHook({
          label: '工单编号',
          form,
          edit: type === appEnum.Common.CRUD.edit,
          objectCode: ObjectCode.POConfig,
          fieldCode: 'code',
          namePath: 'workOrderBaseCO',
          rules: [
            { max: 255, message: '不超过255字符' },
            { validator: validatorCheckTwoSidesTrim() },
            { validator: textValidator2 },
          ],
        }),
        {
          label: '工单类型',
          name: ['workOrderBaseCO', 'workOrderType'],
          rules: [{ required: true, message: '工单类型必填' }],
          initialValue: appEnum.ProductionPlanning.ProductionOrderTypesEnum.ORDINARY,
          render: () => {
            return <Select options={ProductionOrderTypesEnumDisplayMap} allowClear disabled />;
          },
        },
        {
          label: '业务状态',
          name: ['workOrderBaseCO', 'status'],
          rules: [{ required: true, message: '业务状态必填' }],
          initialValue: appEnum.ProductionPlanning.BusinessStatusEnum.CREATED,
          render: () => {
            return <Select options={BusinessStatusEnumDisplayMap} allowClear disabled />;
          },
        },
        {
          label: '暂停状态',
          name: ['workOrderBaseCO', 'pauseFlag'],
          rules: [{ required: true, message: '暂停状态必填' }],
          initialValue: appEnum.ProductionPlanning.SuspendedStatusEnum.NOTSUSPENDED,
          render: () => {
            return <Select options={SuspendedStatusEnumDisplayMap} allowClear disabled />;
          },
        },
        {
          label: '标识码',
          name: ['workOrderBaseCO', 'identifier'],
          rules: [
            { max: 255, message: '不超过255字符' },
            { validator: validatorCheckTwoSidesTrim() },
            { validator: validateBlInputText3() },
          ],
          render: () => {
            return <Input />;
          },
        },
        {
          label: '指定用料',
          name: ['workOrderBaseCO', 'specifiedMaterial'],
          rules: [{ required: true, message: '指定用料必填' }],
          initialValue: appEnum.Common.YN.yes,
          render: () => {
            return (
              <Radio.Group
                options={appDict.common.yn}
                onChange={(e: any) => {
                  setDisplaySubItemMaterialForm && setDisplaySubItemMaterialForm(e?.target?.value);
                }}
              />
            );
          },
        },
        {
          label: '计划开始时间',
          name: ['workOrderBaseCO', 'planStartTime'],
          rules: [{ required: true, message: '计划开始时间必填' }],
          initialValue: moment(moment().startOf('day').format('YYYY-MM-DD')),
          render: () => {
            return <DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />;
          },
        },
        {
          label: '计划完工时间',
          name: ['workOrderBaseCO', 'planFinishTime'],
          rules: [
            { required: true, message: '计划完工时间必填' },
            {
              validator: (_: any, value: any) => {
                const startAt = form.getFieldValue(['workOrderBaseCO', 'planStartTime']);

                if (!value || startAt < value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('计划完工时间必须大于计划开始时间'));
              },
            },
          ],
          render: () => {
            return <DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />;
          },
        },
        {
          label: '设备',
          name: ['workOrderBaseCO', 'resourceId'],
          render: () => (
            <SearchSelect fetchType="resourceCompleteInfo" params={{ parentFlag: true }} />
          ),
        },
        {
          label: '班次',
          name: ['workOrderBaseCO', 'shiftId'],
          render: () => <SearchSelect fetchType="shiftListNoRest" />,
        },
        {
          label: '生产部门',
          name: ['workOrderBaseCO', 'productionDepartmentId'],
          render: () => (
            <UserOrDepartmentSelectWithDialog
              placeholder={'请选择'}
              isMultiple={false}
              onChange={(val: any) => {
                if (!arrayIsEmpty(val)) {
                  /** 带出部门上的主管 */
                  getDepartmentDetail(val?.[0]?.value).then((res) => {
                    const { data } = res;

                    form.setFields([
                      {
                        name: ['workOrderBaseCO', 'productionSupervisorId'],
                        value: [{ label: data?.owner?.name, value: data?.owner?.id }],
                      },
                    ]);
                  });
                }
              }}
            />
          ),
        },
        {
          label: '生产主管',
          name: ['workOrderBaseCO', 'productionSupervisorId'],
          render: () => (
            <UserOrDepartmentSelectWithDialog
              placeholder="请选择"
              isMultiple={false}
              isSelectUser
            />
          ),
        },
        {
          label: '计划部门',
          name: ['workOrderBaseCO', 'planningDepartmentId'],
          render: () => (
            <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple={false} />
          ),
        },
        {
          label: '计划员',
          name: ['workOrderBaseCO', 'planningUserId'],
          render: () => (
            <UserOrDepartmentSelectWithDialog
              placeholder="请选择"
              isMultiple={false}
              isSelectUser
            />
          ),
        },
        {
          label: '文件',
          name: ['workOrderBaseCO', 'fileIds'],
          render: () => <BcAttachmentForm form={form} />,
        },
        {
          label: '编辑原因',
          name: 'operateReason',
          rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
          hidden: type !== appEnum.Common.CRUD.edit,
          render: () => <TextArea placeholder="非必填" />,
        },
      ]),
    },
  ];

  const formatCustomField = () => {
    return info.map((item: any) => {
      return {
        ...item,
        items: item?.items?.filter((e: any) => e?.fieldCategory === FieldCategory.customFields),
      };
    });
  };

  return (
    <>
      <DataFormLayout
        form={form}
        loading={loading}
        info={compact([
          ...baseInfoList,
          ...formatCustomField(),
          {
            title: '详细信息',
            items: [
              {
                isFullLine: true,
                render: renderBottom,
              },
            ],
          },
        ])}
        title={`${lookup('crud', type) ?? ''}生产工单`}
        extra={renderExtra()}
        onCancel={() => {
          history.goBack();
        }}
        okText="保存"
        onFinish={debounce(handleFinish, 1500)}
        formProps={formProps}
        bodyClassName="process-routing-action-form-body"
      />
      {hasUnsavedChange && <Prompt message="您当前的生产工单内容尚未保存，是否退出？" />}
    </>
  );
};

export default BaseForm;
