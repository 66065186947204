import _ from 'lodash';

/** 获取菜单下第一个有页面的项 */
export const getFirstLeaf = (menuItem: any, initDepth = 1, hasAuthFn?: Function) => {
  let current = menuItem;
  let depthToDig = 3 - initDepth;

  while (depthToDig > 0 && _.isArray(current.children)) {
    let filteredChildren: any[] = _.compact(current.children);

    if (_.isFunction(hasAuthFn)) {
      filteredChildren = filteredChildren.filter((item) => hasAuthFn(item.auth));
    }
    if (_.isEmpty(filteredChildren)) {
      break;
    }
    current = filteredChildren[0];
    --depthToDig;
  }
  return current;
};
