import React from 'react';
import { Button, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { RecordListLayout } from 'layout';
import { fetchSalesOrderListItemDetail } from 'src/api/ytt/order-domain/salesOrder';
import { dataColumnsForDetail, detailFilterList, renderMaterial } from '../components/columns';
import { BlIcon, BlTooltip } from 'src/components';
import { DETAIL_FIELD_MAP } from '../constant';
import { SalesOrderListDetailProps } from '../interface';
import { replaceSign } from 'src/utils/constants';
import { toDetail } from '../navigation';
import _Array from 'src/utils/dataTypes/array';
import _String from 'src/utils/dataTypes/string';
import _Time from 'src/utils/dataTypes/time';
import _ from 'lodash';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import { toCreateProductionOrder } from 'src/page/productionPlanning/productionOrder/navigation';
import { intersectionValueOfId } from 'src/utils/array';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { appEnum } from 'src/dict';
import { ObjectCode } from 'src/dict/common';
import { customLayoutChooseButton } from 'src/components/customLayout/hooks/customLayoutForButton';

const SalesOrderDetailList = () => {
  const history = useHistory();
  /**
   * 主操作按钮
   */
  const left = _.compact([
    {
      title: '查看协同采购申请单',
      auth: authDict.purchaseauditnote_view,
      icon: <BlIcon type="iconcaozuojilu" />,
      onClick: () => {
        window.open('/sales/salesManagement/purchaseRequisition');
      },
    },
  ]);
  /**
   * 列操作 ...
   */
  const getOperationList = (record: Required<SalesOrderListDetailProps>) => {
    const parmas = {
      ...record,
      id: String(record?.id),
      lineNo: Number(record?.lineNo),
    };

    return _.compact([
      !_.isNil(record?.status) && {
        title: (
          <Tooltip
            title={
              record?.status === appEnum.Sales.OrderStatus.create
                ? '创建工单'
                : '数量已全部生成工单，禁止创建工单'
            }
          >
            <Button
              type="link"
              style={{ padding: 0 }}
              disabled={record?.status !== appEnum.Sales.OrderStatus.create}
            >
              创建工单
            </Button>
          </Tooltip>
        ),
        auth: authDict.productionorder_add,
        customRender: customLayoutChooseButton({
          objectCode: ObjectCode.POConfig,
          type: 'edit',
          title: (
            <Tooltip
              title={
                record?.status === appEnum.Sales.OrderStatus.create
                  ? '创建工单'
                  : '数量已全部生成工单，禁止创建工单'
              }
            >
              <Button
                type="link"
                style={{ padding: 0 }}
                disabled={record?.status !== appEnum.Sales.OrderStatus.create}
              >
                创建工单
              </Button>
            </Tooltip>
          ),
          onClick: (layoutId) => {
            history.push(toCreateProductionOrder(parmas, layoutId));
          },
        }),
      },
    ]);
  };
  /**
   * Columns
   */
  const dataColumns = [
    ...renderMaterial(),
    {
      title: '下单数量',
      dataIndex: ['amount', 'amountDisplay'],
      width: 200,
      render: (amountDisplay: string, record: SalesOrderListDetailProps) =>
        `${amountDisplay}${record?.amount?.unitName}` ?? replaceSign,
    },
    {
      title: '订单编号',
      dataIndex: 'orderCode',
      sorter: true,
      width: 200,
      render: (
        orderCode: string,
        record: SalesOrderListDetailProps,
        index: number,
        config: any,
      ) => {
        return (
          <>
            {hasAuth(authDict.salesorder_detail) ? (
              <a
                onClick={() => {
                  history.push(toDetail(record?.orderId!));
                }}
              >
                <BlTooltip text={orderCode ?? replaceSign} width={config.contentWidth} />
              </a>
            ) : (
              <BlTooltip text={orderCode ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },

    ...dataColumnsForDetail,
  ];
  /**
   * 传给接口数据处理函数
   */
  const formatDataToQuery = (params: any) => {
    const {
      sorter,
      materialIdsName,
      materialIdsCode,
      specification,
      unitIds,
      ownerIds,
      deliveryDate,
      code,
      customerIds,
      origin,
      scmPurchaseOrderCode,
      shipmentStatus,
      createdAt,
      updatedAt,
      ...rest
    } = params;

    const relParams = {
      ...rest,
    };

    if (createdAt) {
      relParams.createdAtFrom = Number(_Time.formatToUnix(createdAt[0]));
      relParams.createdAtTo = Number(_Time.formatToUnix(createdAt[1]));
    }
    if (updatedAt) {
      relParams.updatedAtFrom = Number(_Time.formatToUnix(updatedAt[0]));
      relParams.updatedAtTo = Number(_Time.formatToUnix(updatedAt[1]));
    }
    if (deliveryDate) {
      relParams.deliveryDateFrom = Number(_Time.formatToUnix(deliveryDate[0]));
      relParams.deliveryDateTo = Number(_Time.formatToUnix(deliveryDate[1]));
    }
    if (ownerIds && !_Array.isEmpty(ownerIds)) {
      relParams.ownerIds = _.map(ownerIds, 'value');
    }
    if (customerIds && !_Array.isEmpty(customerIds)) {
      relParams.customerIds = _.map(customerIds, 'value');
    }

    if (!_String.isEmpty(code)) {
      relParams.code = code;
    }

    if (
      !_Array.isEmpty(materialIdsCode) ||
      !_Array.isEmpty(materialIdsName) ||
      !_Array.isEmpty(specification)
    ) {
      relParams.materialIds = intersectionValueOfId(
        materialIdsCode,
        materialIdsName,
        specification,
      );
    }

    if (unitIds && !_Array.isEmpty(unitIds)) {
      relParams.unitIds = _.map(unitIds, 'value');
    }
    if (origin) {
      relParams.origin = origin;
    }
    if (scmPurchaseOrderCode) {
      relParams.scmPurchaseOrderCode = scmPurchaseOrderCode;
    }
    if (shipmentStatus) {
      relParams.shipmentStatus = shipmentStatus;
    }
    if (sorter) {
      relParams.sorter = _.map(sorter, (node: any) => {
        return {
          order: node?.order,
          field: DETAIL_FIELD_MAP.get(
            typeof sorter?.[0]?.field === 'string'
              ? sorter?.[0]?.field
              : sorter?.[0]?.field[2] || sorter?.[0]?.field[1],
          ),
        };
      });
    }

    return relParams;
  };
  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (data: any) => {
    const { deliveryDate, createdAtTo, updatedAtFrom } = data;

    const retData = { ...data };

    if (createdAtTo) {
      retData.createdAtTo = momentData(createdAtTo);
    }
    if (updatedAtFrom) {
      retData.updatedAtFrom = momentData(updatedAtFrom);
    }
    if (deliveryDate) {
      retData.deliveryDate = momentData(deliveryDate);
    }

    return retData;
  };
  /**
   * RecordListLayout 配置
   */
  const config = {
    rowKey: (record: SalesOrderListDetailProps) => String(record.id),
    columns: dataColumns,
    getOperationList,
    mainMenu: left,
    filterList: detailFilterList,
    formatDataToQuery,
    formatDataToFormDisplay,
  };

  return (
    <>
      <RecordListLayout<SalesOrderListDetailProps>
        userAuth={getAuthFromLocalStorage()}
        placeholder={'请输入订单编号、客户名称'}
        configcacheKey="salesOrderDetail"
        requestFn={fetchSalesOrderListItemDetail}
        {...config}
      />
    </>
  );
};

export default SalesOrderDetailList;
