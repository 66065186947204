import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';
import { Form, Checkbox, Input, message, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ResourceBusinessType, MalfunctionRecordOrign } from 'src/dict/resources';
import {
  RESORCE_BUSINESS_TYPE_MAP,
  malfunctionTypes,
  malfunctionLevel,
  malfunctionRecordOrign,
  malfunctionRecordOrignObj,
} from 'src/dict/resources/mappings';
import {
  fetchMalfunctionCreate,
  fetchMalfunctionDetail,
  fetchMalfunctionUpdate,
} from 'src/api/ytt/resource-domain/malfunctionRecord';
import lookup, { appEnum } from 'src/dict';
import { NumberRulesStandardHook, SearchSelect, BcAttachmentForm } from 'src/components';
import { CRUD } from 'src/dict/common';
import { fetchResourcesList } from 'src/api/ytt/resource-domain/resource';
import ResourceClassificationCascader from '../../components/ResourceClassificationCascader';
import {
  checkTwoSidesBackslash,
  checkTwoSidesTrim,
  textValidator2,
} from 'src/utils/formValidators';
import { BL_BUSINESS_CODE, BL_BUSINESS_NAME } from '../config';
import TagFormList from './EquipTagFormList';

interface ActionProps {
  id: string;
}

const Action = (props: RouteComponentProps<ActionProps>) => {
  const { match, history } = props;
  const listCacheData = JSON.parse(sessionStorage.getItem(BL_BUSINESS_CODE) ?? '{}');

  const id = Number(match.params.id);
  const pageType = history.location.pathname.split('/').pop();

  const [form] = Form.useForm();
  // 刷新
  const [refreshMarker, setRefreshMarker] = useState<number>(0);
  // 业务对象
  // 触发来源
  const [orign, setOrign] = useState<number>(0);
  const [keepOn, setKeepOn] = useState<boolean>(false);
  // 来源对象实例
  const [
    oringObjExp,
    // setOringObjExp,
  ] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initailBiz();
  }, []);

  const initailBiz = () => {
    // 创建时
    const isCreate = pageType === appEnum.Common.CRUD.create;

    // 所选为资源类型
    const isEquipment = listCacheData?.target?.type === 'Equipment';

    const equipmentId = _.head(_.split((listCacheData?.target?.id as string) ?? '', '_'));
    const equipmentType = listCacheData?.businessType;

    if (isCreate && isEquipment && equipmentId) {
      fetchResourcesList({ idList: [_.toNumber(equipmentId)], type: equipmentType }).then((res) => {
        const bizInfo = _.head(res?.data?.list);

        if (!_.isNil(bizInfo)) {
          const resourceId = {
            label: bizInfo.name,
            value: JSON.stringify(
              _.pick(bizInfo, ['id', 'level', 'hasChildren', 'code', 'locationName']),
            ),
          };

          form.setFieldsValue({ resourceId });
        }
      });
    }
  };

  const fetchCreate = async (req: any) => {
    const { code } = await fetchMalfunctionCreate(req);

    if (code === 200) {
      message.success('创建成功');
    }
  };
  const fetchEdit = async (req: any) => {
    const { code } = await fetchMalfunctionUpdate(req);

    if (code === 200) {
      message.success('编辑成功');
    }
  };
  const initEdit = async (resId: number | undefined) => {
    try {
      if (!resId) return;
      const res: any = await fetchMalfunctionDetail({ id: resId });
      const { data } = res;
      const {
        id,
        code,
        name,
        resourceType,
        level,
        type,
        remark,
        recordSource,
        sourceObject,
        description,
        detail,
        sourceObjectVO,
        resource,
        classification,
        tagsLinks,
        fileIds,
      } = data;
      const val: any = {
        id,
        code,
        name,
        type: { value: type },
        level: { value: level },
        recordSource: { value: recordSource },
        resourceType: { value: resourceType },
        sourceObject: { value: sourceObject },
        description,
        remark,
        detail,
        fileIds,
      };

      setOrign(recordSource);
      if (classification) {
        const { parentIds } = classification;

        val.classificationId = parentIds;
      }
      if (sourceObjectVO) {
        const { id: sourceObjectVOId, paramName, recordTime } = sourceObjectVO;

        val.sourceObjectId = {
          label: `${paramName} ${recordTime}`,
          value: sourceObjectVOId,
        };
      }

      if (resource) {
        const { id: equipmentId } = resource;
        const equipmentType = listCacheData?.businessType;

        fetchResourcesList({ idList: [_.toNumber(equipmentId)], type: equipmentType }).then(
          (res) => {
            const bizInfo = _.head(res?.data?.list);

            if (!_.isNil(bizInfo)) {
              form.setFieldsValue({
                resourceId: {
                  label: bizInfo.name,
                  value: JSON.stringify(
                    _.pick(bizInfo, ['id', 'level', 'hasChildren', 'code', 'locationName']),
                  ),
                },
              });
            }
          },
        );
      }

      if (tagsLinks) {
        console.log(tagsLinks);
        val.tagsLinks = tagsLinks.map((tag: any) => {
          const { id, labelId, label, labelValueId, labelValues } = tag;

          return {
            labelId: {
              label,
              value: JSON.stringify({
                id: labelId,
                label,
                children: labelValues,
              }),
              key: JSON.stringify({
                id: labelId,
                label,
                children: labelValues,
              }),
            },
            labelValueId,
            id,
          };
        });
      }

      form.setFieldsValue(val);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = async () => {
    setLoading(true);
    try {
      const data = await form?.validateFields();
      const {
        code,
        name,
        resourceType,
        resourceId: resId,
        type = { value: null },
        classificationId,
        level = { value: null },
        recordSource = { value: null },
        sourceObject = { value: null },
        sourceObjectId = { value: null },
        tagsLinks: tagList = [],
        ...rest
      } = data;
      const { id: resourceId } = JSON.parse(resId.value);
      const tagsLinks = tagList.map((item: any) => {
        const { labelId: label, ...res } = item;
        const { id: labelId } = JSON.parse(label.value);

        return {
          labelId,
          ...res,
        };
      });
      const value = {
        code: code.replace(/\s+/g, ' '),
        name: name && name.replace(/\s+/g, ' '),
        resourceType: resourceType.value,
        resourceId,
        type: type.value,
        classificationId: _.isEmpty(classificationId) ? null : classificationId.concat().pop(),
        level: level.value,
        recordSource: recordSource.value,
        sourceObject: sourceObject.value,
        sourceObjectId: sourceObjectId.value,
        tagsLinks,
        ...rest,
      };

      switch (pageType) {
        case CRUD.create:
          await fetchCreate(value);
          break;

        case CRUD.edit:
          await fetchEdit(value);
          break;

        case CRUD.copy:
          await fetchCreate(value);
          break;

        default:
          break;
      }
      setRefreshMarker(new Date().getTime());

      if (keepOn) {
        form.resetFields();
        initCreate();
        return;
      }

      history.goBack();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const initCreate = () => {
    form.setFieldsValue({
      code_isUseRules: true,
    });
  };
  const handleBizTypeChange = () => {
    form.setFieldsValue({ resourceId: undefined });
  };
  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '',
        name: 'id',
        hidden: true,
        render: () => <Input />,
      },
      ...NumberRulesStandardHook({
        label: '故障编号',
        form,
        edit: pageType === CRUD.edit,
        objectCode: 'ResourceMalfunctionRecord',
        fieldCode: 'code',
        rules: [
          { max: 255, message: '不可超过255字符' },
          { validator: textValidator2 },
          { validator: checkTwoSidesBackslash },
          { validator: checkTwoSidesTrim },
        ],
        refreshMarker,
        // disabledState: false,
      }),
      {
        label: '故障标题',
        name: 'name',
        rules: [{ max: 255, message: '不可超过255字符' }],
        render: () => <Input placeholder="请输入故障标题" />,
      },
      {
        label: '业务对象',
        name: 'resourceType',
        initialValue: { value: listCacheData?.businessType ?? ResourceBusinessType.equipment },
        rules: [{ required: true, message: '请选择业务对象' }],
        render: () => (
          <Select
            placeholder="请选择业务对象"
            labelInValue
            options={RESORCE_BUSINESS_TYPE_MAP}
            onChange={handleBizTypeChange}
            disabled={pageType === CRUD.edit && orign === MalfunctionRecordOrign.event}
          />
        ),
      },
      {
        label: '故障资源',
        dependencies: ['resourceType'],
        rules: [{ required: true, message: '请选择业务对象' }],
        render: () => () => {
          const bussinessType = form.getFieldValue('resourceType');

          return (
            <Form.Item
              name="resourceId"
              style={{ marginBottom: 0 }}
              rules={[{ required: true, message: '请选择故障资源' }]}
            >
              <SearchSelect
                fetchType="resourceCompleteInfo"
                params={{
                  type: bussinessType.value,
                  statusList: [
                    appEnum.Common.UsageStatus.enabled,
                    appEnum.Common.UsageStatus.disabled,
                  ],
                }}
                placeholder="请输入故障资源"
                disabled={pageType === CRUD.edit && orign === MalfunctionRecordOrign.event}
              />
            </Form.Item>
          );
        },
      },
      {
        label: '资源编号',
        shouldUpdate: true,
        render: () => () => {
          const { value = '{}' } = form.getFieldValue('resourceId') ?? {};
          const { code } = JSON.parse(value);

          return <Input placeholder="" disabled value={code} />;
        },
      },
      {
        label: '故障类型',
        name: 'type',
        render: () => (
          <Select placeholder="请选择故障类型" labelInValue options={malfunctionTypes} allowClear />
        ),
      },
      {
        label: '故障分类',
        name: 'classificationId',
        render: () => (
          <ResourceClassificationCascader
            onChange={() => {}}
            showSearch
            multiple
            params={{ businessType: ResourceBusinessType.malfunction, cascade: true }}
            placeholder={'请选择故障分类'}
            enableAddLabel={'新建分类'}
            onCreateClick={() => {}}
            enableAdd={false}
          />
        ),
      },
      {
        label: '故障等级',
        name: 'level',
        render: () => (
          <Select placeholder="请选择故障等级" labelInValue options={malfunctionLevel} allowClear />
        ),
      },
      {
        label: '记录来源',
        name: 'recordSource',
        initialValue: { value: MalfunctionRecordOrign.manual },
        render: () => (
          <Select
            placeholder="请选择记录来源"
            labelInValue
            options={malfunctionRecordOrign}
            allowClear
            disabled
          />
        ),
      },
      {
        label: '来源对象',
        name: 'sourceObject',
        // initialValue: { value: '' },
        render: () => (
          <Select
            placeholder="" // 请选择来源对象
            labelInValue
            options={malfunctionRecordOrignObj}
            allowClear
            disabled
          />
        ),
      },
      {
        label: '来源对象实例',
        name: 'sourceObjectId',
        render: () => (
          <Select
            placeholder="" // 请选择来源对象实例
            labelInValue
            options={oringObjExp}
            allowClear
            disabled
          />
        ),
      },
      {
        label: '故障原因',
        name: 'description',
        rules: [{ max: 255, message: '不可超过255字符' }],
        render: () => <Input placeholder="请输入故障原因" />,
      },
      {
        label: '故障明细',
        name: 'detail',
        rules: [{ max: 1000, message: '不可超过1000字符' }],
        render: () => <TextArea placeholder="请输入故障明细" />,
      },
      {
        label: '附件',
        name: 'fileIds',
        render: () => <BcAttachmentForm form={form} />,
      },
      {
        label: '备注',
        name: 'remark',
        rules: [{ max: 1000, message: '不可超过1000字符' }],
        render: () => <TextArea placeholder="请输入备注" />,
      },
      {
        label: '编辑原因',
        name: 'operateReason',
        hidden: pageType !== CRUD.edit,
        rules: [{ max: 1000, message: '不可超过1000字符' }],
        render: () => <TextArea placeholder="请输入编辑原因" />,
      },
    ],
  };

  const tagInfo = {
    title: '故障标签',
    items: [
      {
        isFullLine: true,
        render: () => <TagFormList form={form} />,
      },
    ],
  };

  useEffect(() => {
    switch (pageType) {
      case CRUD.create:
        initCreate();
        break;

      case CRUD.edit:
        initEdit(id);
        break;

      default:
        break;
    }
  }, [refreshMarker]);

  return (
    <DataFormLayout
      loading={loading}
      form={form}
      title={`${lookup('crud', pageType)}${BL_BUSINESS_NAME}`}
      extra={
        pageType === appEnum.Common.CRUD.create && (
          <Checkbox
            onChange={() => {
              setKeepOn(!keepOn);
            }}
            defaultChecked={keepOn}
          >
            连续新建
          </Checkbox>
        )
      }
      info={[baseInfo, tagInfo]}
      onFinish={handleFinish}
      onCancel={() => {
        history.goBack();
      }}
    />
  );
};

export default Action;
