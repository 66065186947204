import { cloneElement } from 'react';
import { isEmpty, isNil, isArray } from 'lodash';
import { FormInstance } from 'antd';
import { FieldType } from 'src/dict/common';
import type { FieldCascadingDTO, FieldDTO, NamePath } from 'src/page/customLayout/types';

type ControlFieldForestNode = {
  fieldCode: string;
  children: ControlFieldForestNode[];
};

// 在森林里查找指定的字段结点
export function findNode(
  fieldCode: string,
  forest: ControlFieldForestNode[],
): ControlFieldForestNode | null {
  const findRes = forest.find((n) => n.fieldCode === fieldCode);

  if (findRes) {
    return findRes;
  }
  const nextLevel = forest.map((n) => n.children).flat();

  if (isEmpty(nextLevel)) {
    return null;
  }
  return findNode(fieldCode, nextLevel);
}

// 根据字段级联关系，构造字段的控制关系森林
export const constructControlFieldForest = (cascadingList: FieldCascadingDTO[]) => {
  const forest: ControlFieldForestNode[] = [];

  cascadingList.forEach((cas) => {
    const node = findNode(cas.fieldCode!, forest);

    if (node) {
      // 如果这个控制字段已经出现在森林里，说明它在别的级联关系里是依赖字段，继续拓展这棵树
      node.children.push({ fieldCode: cas.refFieldCode!, children: [] });
    } else {
      // 如果不在，就往森林里新添一棵树
      forest.push({
        fieldCode: cas.fieldCode!,
        children: [{ fieldCode: cas.refFieldCode!, children: [] }],
      });
    }
  });

  return forest;
};

// 后序遍历一棵字段控制关系树, 并返回遍历内容
export const postorderTraversal = (root: ControlFieldForestNode): string[] => {
  if (isEmpty(root.children)) {
    // 叶结点对应的字段只作为受控字段，故不用加到遍历序列里
    return [];
  }
  return [...root.children.map(postorderTraversal).flat(), root.fieldCode];
};

type GetSelectableFieldCascadingHandlerParams = {
  relatedCascading?: FieldCascadingDTO;
  fullOptions: { label: string; value: string }[];
  form?: FormInstance;
  fieldInfo: FieldDTO;
  labelInValue: boolean;
  getNamePathByFieldCode?: (fieldCode: string) => NamePath;
};

/** 单选、多选输入控件的级联处理逻辑 */
export const getSelectableControlCascadingEnhancer =
  ({
    relatedCascading,
    fullOptions,
    form,
    fieldInfo,
    labelInValue = false,
    getNamePathByFieldCode,
  }: GetSelectableFieldCascadingHandlerParams) =>
  (ctrl: React.ReactElement) => {
    if (!relatedCascading || !getNamePathByFieldCode || !form) {
      return ctrl;
    }
    let res = ctrl;
    // 控制字段
    const controlFieldNamePath = getNamePathByFieldCode(relatedCascading.fieldCode!)!;
    const controlFieldValue = form.getFieldValue(controlFieldNamePath);

    // 当前字段(依赖字段)
    const { fieldCode, fieldType } = fieldInfo;
    const currentFieldNamePath = getNamePathByFieldCode(fieldCode!)!;
    const currentFieldValue = form.getFieldValue(currentFieldNamePath);

    if (isNil(controlFieldValue)) {
      res = cloneElement(ctrl, { ...ctrl.props, options: fullOptions });
    } else {
      const getValue = (formVal: any) => {
        return labelInValue ? formVal.value : formVal;
      };
      const relatedMapping = relatedCascading.mapping?.find(
        (mp) => mp.choiceCode === getValue(controlFieldValue),
      );

      if (relatedMapping) {
        const isAvailableOption = (option: { value?: string }) =>
          relatedMapping.refChoiceCodes?.includes(option.value!);
        const filteredOptions = fullOptions.filter(isAvailableOption);

        res = cloneElement(ctrl, { ...ctrl.props, options: filteredOptions });
        // 应用级联规则后只剩唯一选项时，默认将其选中
        if (relatedCascading.refUnique && filteredOptions.length === 1) {
          const onlyValue = labelInValue ? filteredOptions[0] : filteredOptions[0].value;

          form.setFields([
            {
              name: currentFieldNamePath,
              value: fieldType === FieldType.multiSelect ? [onlyValue] : onlyValue,
              errors: undefined,
            },
          ]);
        } else if (currentFieldValue && !isEmpty(currentFieldValue)) {
          // 清除不符合级联规则的选项
          // 单选、多选统一处理
          const currentValueList = isArray(currentFieldValue)
            ? currentFieldValue
            : Array.of(currentFieldValue);

          // 当前选中项不全符合级联规则时，清空选中项
          if (
            !currentValueList.every((val) => relatedMapping.refChoiceCodes?.includes(getValue(val)))
          ) {
            form.setFields([{ name: currentFieldNamePath, value: undefined }]);
          }
        }
      } else {
        // 当前字段找不到级联关系时，默认mapping为空处理1.选项置空2.值置空
        res = cloneElement(ctrl, { ...ctrl.props, options: [] });
        form.setFields([{ name: currentFieldNamePath, value: undefined }]);
        console.warn(
          `字段${relatedCascading.fieldCode}的选项${controlFieldValue}无对应的级联映射关系，请检查`,
        );
      }
    }
    return res;
  };
