import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DetailsBaseInfo from './detailsBaseInfo';

interface props {
  id: string;
}

const Details = (props: RouteComponentProps<props>) => {
  const { match } = props;
  const id = Number(match.params.id);

  return <DetailsBaseInfo id={id} />;
};

export default Details;
