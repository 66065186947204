import { useState, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, cloneDeep } from 'lodash';
import { message } from 'antd';
import { ColumnProps } from 'antd/es/table';
import BcBlMultiCascader from 'src/page/knowledgeManagement/materialModeling/material/share/Cascader/BcBlMultiCascader';
import { BlIcon, SearchSelect, TagList, SingleOperationLogComponent } from 'src/components';
import { RecordListLayout, DataFormLayoutForModal, DetailLayoutForModal } from 'src/layout';
import { fieldTypeList, gcArray, gcTime } from 'src/utils';

import { arrayIsEmpty, intersectionValueOfId } from 'src/utils/array';

import BcBlCascader from 'src/components/Cascader';
import { getMaterialAttrs } from 'src/entity/material';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';

import { PlanOrderResponseList } from '../../plannedOrder/createAndEdit/index.type';

import MaterialScheduling from '../createAndEdit';
import {
  fetchSchedulingSchedulingMaterialList,
  fetchSchedulingSchedulingMaterialDelete,
  fetchSchedulingSchedulingMaterialGet,
} from 'src/api/ytt/wos-domain';
import lookup, { appEnum } from 'src/dict';
import { replaceSign } from 'src/utils/constants';
import MaterialSchedulingDetail from '../detail';

const MaterialSchedulingList = (props: { history: any }) => {
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [operationRecordVisible, setOperationRecordVisible] = useState(false);
  const [datasetId, setDataSetId] = useState<number>(0);
  const [schedulingMaterial, setSchedulingMaterial] = useState<any>();
  const dispatch = useDispatch();

  const [materialS, setMaterialS] = useState(false); // 排程方案设置
  const [materialSchedulingDetail, setMaterialSchedulingDetail] = useState(); // 排程方案查看

  const [type, setType] = useState(appEnum.Common.CRUD.create);

  const { history } = props;

  const refreshData = () => {
    setRefreshMarker(Math.random());
  };

  useEffect(() => {
    refreshData();
  }, [history, props]);

  const formatDataToDisplay = (data: any) => {
    const { createdAt, updatedAt, plannedStartTime, plannedEndTime, requirementTime, ...resData } =
      cloneDeep(data);

    return {
      ...resData,
      createdAt: !gcArray.isEmpty(createdAt) ? createdAt : undefined,
      updatedAt: !gcArray.isEmpty(updatedAt) ? updatedAt : undefined,
      plannedStartTime: !gcArray.isEmpty(plannedStartTime) ? plannedStartTime : undefined,
      plannedEndTime: !gcArray.isEmpty(plannedEndTime) ? plannedEndTime : undefined,
      requirementTime: !gcArray.isEmpty(requirementTime) ? requirementTime : undefined,
    };
  };

  const formatDataToFormDisplay = (data: any) => {
    const { createdAt, updatedAt, plannedStartTime, plannedEndTime, requirementTime, ...rest } =
      data;

    return {
      ...rest,
      createdAt: gcTime.formatRangeTimeToMoment(createdAt),
      updatedAt: gcTime.formatRangeTimeToMoment(updatedAt),
      plannedStartTime: gcTime.formatRangeTimeToMoment(plannedStartTime),
      plannedEndTime: gcTime.formatRangeTimeToMoment(plannedEndTime),
      requirementTime: gcTime.formatRangeTimeToMoment(requirementTime),
    };
  };

  const formatDataToQuery = (data: any) => {
    const { materialCode, materialName, quickSearch, ...resData } = cloneDeep(data);

    const relParams = {
      ...resData,
    };

    // if (sorter) {
    //   relParams.sorter = map(sorter, (node: any) => {
    //     return {
    //       order: node?.order,
    //       field: FIELD_MAP.get(node?.field),
    //     };
    //   });
    // }

    const params = {
      ...resData,
      ...relParams,
      materialIds: intersectionValueOfId(materialCode, materialName),
      materialCodeOrNameLike: quickSearch,
    };

    dispatch.excelImport.updateBusinessData({ businessData: params });
    return params;
  };

  const mainMenu = [
    {
      title: '添加排程物料',
      auth: authDict.planOrder_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        setMaterialS(true);
        setType(appEnum.Common.CRUD.create);
      },
      items: [],
    },
  ];

  const batchMenu = [{}];

  const columns: (ColumnProps<PlanOrderResponseList> & {
    filterIndex?: string;
    type?: number;
    isFilter?: boolean;
    selectProps?: any;
    renderItem?: ReactElement;
    dateFormat?: any;
  })[] = [
    {
      title: '物料编号',
      dataIndex: ['material', 'baseInfo', 'code'],
      filterIndex: 'materialCode',
      width: 150,
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="material"
          labelPath="code"
          valuePath="id"
          placeholder="请输入"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      title: '物料名称',
      dataIndex: ['material', 'baseInfo', 'name'],
      filterIndex: 'materialName',
      width: 150,
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="material"
          labelPath="name"
          valuePath="id"
          placeholder="请输入"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      title: '物料属性',
      dataIndex: ['material', 'attribute'],
      filterIndex: 'materialAttributeIds',
      type: fieldTypeList.text,
      renderItem: <BcBlMultiCascader type={'materialProperty'} nameLabel={'物料属性'} />,
      width: 150,
      render: (_text: any, record: any) => (
        <TagList dataSource={getMaterialAttrs(record.material)} />
      ),
    },
    {
      title: '物料分类',
      dataIndex: ['material', 'category', 'name'],
      filterIndex: 'materialCategoryIds',
      type: fieldTypeList.text,
      renderItem: (
        <BcBlCascader
          fetchType={'materialCategory'}
          nameLabel={'物料分类'}
          popupPlacement={'bottomRight'}
        />
      ),
      width: 150,
    },
    {
      title: '物料规格',
      dataIndex: ['material', 'baseInfo', 'specification'],
      width: 150,
    },
    {
      title: '业务类型',
      dataIndex: ['material', 'baseInfo', 'bizRange'],
      width: 150,
      render: (text: number[]) => {
        if (!arrayIsEmpty(text)) {
          const arr = text.map((item: number) => {
            return lookup('material.bizRange', item);
          });

          return arr.join('、');
        }
        return replaceSign;
      },
    },
    {
      title: '排程优先级',
      dataIndex: ['priority'],
      width: 150,
    },
  ];

  const getFilterList = () => {
    return columns
      .filter((i) => i.isFilter)
      .map((column: any) => {
        const filter: any = {
          label: column.title,
          name: column.filterIndex || column.dataIndex,
          type: column.type,
          rules: column.rules,
          renderItem: column.renderItem,
          dateFormat: column.dateFormat,
        };

        if (column.type === fieldTypeList.select || column.type === fieldTypeList.multiSelect) {
          filter.selectProps = column.selectProps;
        }
        return filter;
      });
  };

  const getOperationList = (
    record: any,
  ): {
    title: string;
    auth?: string;
    onClick: () => void;
    disable?: boolean;
  }[] => {
    const { id } = record;

    const list = [
      {
        title: '查看',
        // auth: authDict.planOrder_detail,
        onClick: () => {
          fetchSchedulingSchedulingMaterialGet({ id: Number(id) }).then((res) => {
            setMaterialSchedulingDetail(res?.data);
          });
        },
      },
      {
        title: '调整配置',
        // auth: authDict.planOrder_detail,
        onClick: () => {
          setMaterialS(true);
          setType(appEnum.Common.CRUD.edit);
          setSchedulingMaterial(record);
        },
      },
      {
        title: '移除',
        // auth: authDict.planOrder_detail,
        onClick: () => {
          fetchSchedulingSchedulingMaterialDelete({ id }).then((res) => {
            const { code = 0 } = res ?? {};

            if (code === 200) refreshData();
            message.success('移除成功!');
          });
        },
      },
    ];

    return list;
  };

  return (
    <>
      <RecordListLayout<any>
        configcacheKey="schedulingSchedulingMaterialList"
        filterList={getFilterList()}
        // requestFn={fetchPlanOrderList}
        requestFn={fetchSchedulingSchedulingMaterialList}
        mainMenu={mainMenu}
        batchMenu={batchMenu}
        placeholder={'请输入计划订单编码'} // 快速搜索input placeholder
        formatDataToQuery={formatDataToQuery} // 转换搜索条件进行查询（快速查询字段："quickSearch",当前页："page",分页大小："size"）
        formatDataToDisplay={formatDataToDisplay} // 转换搜索条件进行展示
        columns={columns} // table columns
        selectedRowKeys={selectedRowKeys}
        refreshMarker={refreshMarker}
        rowKey="planOrderId"
        onSelectedRowKeys={(selectKey, selectRows) => {
          setSelectedRowKeys(selectKey);
          setSelectedRows(selectRows || []);
        }}
        pagination={{ pageSizeOptions: ['10', '20'] }}
        userAuth={getAuthFromLocalStorage()}
        getOperationList={getOperationList}
        formatDataToFormDisplay={formatDataToFormDisplay}
      />
      {/* 操作记录 */}
      {operationRecordVisible && (
        <SingleOperationLogComponent
          visible={operationRecordVisible}
          instanceId={datasetId}
          objectCode={'PlanOrder'}
          closeDetail={() => {
            setOperationRecordVisible(false);
          }}
        />
      )}
      {/* 排程方案设置 */}
      <DataFormLayoutForModal
        visible={materialS}
        onClose={() => {
          setMaterialS(false);
        }}
        content={
          <MaterialScheduling
            name="scheduleSetting"
            params={schedulingMaterial}
            history={history}
            onCancel={() => {
              setMaterialS(false);
            }}
            onChange={() => {
              setMaterialS(false);
              setTimeout(() => {
                refreshData();
              }, 1200);
            }}
            type={type}
          />
        }
      />

      {/* 排程方案查看 */}
      <DetailLayoutForModal
        visible={!isEmpty(materialSchedulingDetail)}
        onClose={() => {
          setMaterialSchedulingDetail(undefined);
        }}
        content={<MaterialSchedulingDetail params={materialSchedulingDetail} />}
      />
    </>
  );
};

export default MaterialSchedulingList;
