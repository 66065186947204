/**
 * 自定义布局详情页渲染方法
 */
import { Spin, Tabs, Empty } from 'antd';
import { v4 } from 'uuid';
import _ from 'lodash';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import { LayoutItemType } from 'src/dict/customLayout';
import type { DetailLayoutProps } from 'src/layout';
import type { FormLayoutRootConfigProps, FormLayoutConfigProps } from './types';

interface CustomDetailProps {
  /** 布局配置 */
  layoutConfig: FormLayoutRootConfigProps | null;
  /** 业务对象数据 */
  dataSource: { [p: string]: any } | null | undefined;
}

interface ICalculateForm {
  (layoutConfig: FormLayoutConfigProps): typeof layoutConfig extends FormLayoutRootConfigProps
    ? React.ReactNode
    : React.ReactNode | DetailLayoutInfoBlock;
}

export default function CustomDetail(props: CustomDetailProps & DetailLayoutProps): JSX.Element {
  const { loading, layoutConfig, dataSource } = props;

  if (!layoutConfig) {
    return (
      <div
        style={{
          height: '100%',
          background: '#fff',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Spin spinning={loading}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Spin>
      </div>
    );
  }
  // 递归渲染详情页
  const calculateForm: ICalculateForm = (layoutConf) => {
    // 顶级容器
    if (layoutConf.type === LayoutItemType.container) {
      const childrenConfig = layoutConf.children?.map(calculateForm) as
        | DetailLayoutInfoBlock[]
        | undefined;

      return (
        <DetailLayout
          {..._.omit(props, ['layoutConfig', 'dataSource'])}
          dataSource={dataSource || []}
          title={layoutConf.title}
          info={childrenConfig}
        />
      );
    }

    // Tab容器
    if (layoutConf.type === LayoutItemType.tabs) {
      return {
        title: '',
        items: [
          {
            render: () => (
              <Tabs type="card">
                {layoutConf.children
                  ?.filter((item) => item.type === LayoutItemType.tabpane)
                  .map((pane) => (
                    <Tabs.TabPane key={`tabpane-${v4()}`} tab={pane.title}>
                      {calculateForm(pane.children?.[0]!)}
                    </Tabs.TabPane>
                  ))}
              </Tabs>
            ),
          },
        ],
      };
    }

    // 信息块
    if (layoutConf.type === LayoutItemType.infoBlock) {
      const { title, children } = layoutConf;

      return { title, items: (children ?? []).map(calculateForm) };
    }

    // 字段
    if (layoutConf.type === LayoutItemType.field) {
      const { title, id, render, itemProps = {} } = layoutConf;

      return {
        ...itemProps,
        label: title,
        dataIndex: id,
        render,
      };
    }

    return null;
  };

  return <>{calculateForm(layoutConfig)}</>;
}
