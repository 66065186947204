/**
 * 业务名称
 */
export const BL_BUSINESS_NAME = '故障记录';

/**
 * 业务基础URL
 */
export const BL_BUSINESS_URL = '/resource/implement/malfunctionRecord';

/**
 * 业务编码
 */
export const BL_BUSINESS_CODE = 'malfunctionRecord';

/**
 * 对象编码
 */
export const BL_OBJECT_CODE = 'ResourceMalfunctionRecord';
