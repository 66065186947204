import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Select, SelectProps } from 'antd';
import { OptionCoreData } from 'rc-select/lib/interface';

const { Option } = Select;

export type BlSelectOption = {
  selectList?: OptionCoreData[];
  selectOptions?: SelectProps<any>;
  optionProps?: typeof Option;
  header?: React.ReactElement; // 下拉框的头部
  footer?: React.ReactElement; // 下拉框的底部
  requestFn?: () => Promise<any>; // yapi 生成的接口可以直接传入
  type?: string; // support search type， 内置请求
  handleData?: (res: any) => OptionCoreData[];
  [prop: string]: any;
};

const BlSelect: React.FC<BlSelectOption> = (props: BlSelectOption) => {
  const {
    selectList,
    header,
    footer,
    selectOptions,
    optionProps,
    requestFn,
    handleData,
    type,
    ...reset
  } = props;

  const [dataSource, setDataSource] = useState<OptionCoreData[]>([]);

  useEffect(() => {
    let selectData = [];

    if (type) {
      switch (type) {
        default: {
          break;
        }
      }
    } else if (requestFn) {
      requestFn().then(async (res: any) => {
        selectData = _.get(res, 'data', []);

        if (typeof handleData === 'function') {
          selectData = handleData(selectData);
        }
        setDataSource(selectData);
      });
    }
  }, [handleData, requestFn, type]);

  const renderList = useMemo(
    () =>
      _.map(selectList || dataSource, (item) => (
        <Option key={item.key} value={item.value} {...optionProps}>
          {item.label}
        </Option>
      )),
    [dataSource, optionProps, selectList],
  );

  const customOptions = { notFoundContent: header || footer ? <div /> : null };

  return (
    <Select
      dropdownRender={(menu) => (
        <div>
          {header}
          {menu}
          {footer}
        </div>
      )}
      allowClear
      labelInValue
      style={{ width: '100%' }}
      {...customOptions}
      {...reset}
      {...selectOptions}
    >
      {renderList}
    </Select>
  );
};

export default BlSelect;
