import { Form, Input, FormInstance, DatePicker } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import { BatchOperateTableHeader, BlSortFormList, SearchSelect } from 'src/components';
import {
  PurchaseExecStatus,
  PurchaseSource,
  PurchaseOrderMaterialCarryMode,
} from 'src/dict/purchase';
import _Time from 'src/utils/dataTypes/time';
import _, { compact, max } from 'lodash';
import { replaceSign } from 'src/utils/constants';
import { useEffect } from 'react';
import { numberMinMaxCheck, intMultipleCheck, fractionLengthCheck } from 'src/utils/formValidators';
import { materialEntity } from 'src/entity';
import { injectCustomFieldColumns, initCustomFieldsInData } from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { fetchCalculatorGetTotalAmount } from 'src/api/ytt/supply-chain-domain/calculator';
import { renderTotalPrice } from '../utils';
import { appEnum } from 'src/dict';
import { fetchPurchaseOrderDefaultOutsourceMaterialList } from 'src/api/ytt/order-domain/purchaseOrder';
interface Props {
  orderStatus: number;
  name: string;
  edit: boolean;
  form: FormInstance;
  customFields: any;
  outItemCustomFields?: any;
  carryMode: PurchaseOrderMaterialCarryMode | undefined;
}
/** 默认单位精度 */
const DEFAULT_PRECISION = 10;

export default function MaterialTable({
  name,
  form,
  edit,
  orderStatus,
  customFields,
  outItemCustomFields,
  carryMode,
}: Props) {
  const onMaterialChange = (materialId: any, index: number) => {
    const taxRate = materialId && JSON.parse(materialId?.value)?.purchaseInfo.taxRate;
    const formValue = form.getFieldsValue();
    const item = formValue?.itemList?.[index];

    if (item && taxRate) {
      item.taxRate = taxRate;
    }
    form.setFieldsValue(formValue);
  };
  const setTotalPriceChange = (index: number) => {
    const formValue = form.getFieldsValue();
    const item = formValue?.itemList?.[index];
    const amountAccuracy = JSON.parse(formValue?.currency?.value || '{}')?.amountAccuracy || 2;

    if (item?.demandAmount && item?.unitPrice) {
      fetchCalculatorGetTotalAmount({
        demandAmount: item?.demandAmount,
        unitPrice: item?.unitPrice,
        precision: amountAccuracy,
      })
        .then((res) => {
          if (res?.data?.result) {
            item.totalPrice = res?.data?.result;
          } else {
            item.totalPrice = item?.demandAmount * item?.unitPrice;
          }
        })
        .catch(() => {
          item.totalPrice = item?.demandAmount * item?.unitPrice;
        })
        .finally(() => {
          item.totalPrice = Number(item.totalPrice).toFixed(amountAccuracy);
          form.setFieldsValue(formValue);
        });
    } else {
      item.totalPrice = null;
      form.setFieldsValue(formValue);
    }
  };
  const getAndSetOutItemList = (
    bomVersion: string,
    materialId: number,
    demandAmount: number,
    lineNo: number,
    itemId: number,
  ) => {
    const outItemList = form.getFieldValue('outItemList') || [];

    fetchPurchaseOrderDefaultOutsourceMaterialList({
      bomVersion,
      materialId,
      demandAmount,
    }).then((res) => {
      const { data } = res;

      const currentOutItemList =
        data?.map((item) => {
          return initCustomFieldsInData({
            purchaseOrderItemLineNo: lineNo,
            purchaseOrderItemId: itemId,
            customFields: outItemCustomFields,
            ...item,
          });
        }) || [];
      const newOutItemList = outItemList
        .filter((item: any) => item.purchaseOrderItemLineNo != lineNo)
        .concat(currentOutItemList)
        .sort(
          (item1: any, item2: any) => item2.purchaseOrderItemLineNo - item1.purchaseOrderItemLineNo,
        );

      form.setFieldsValue({ outItemList: newOutItemList });
    });
  };
  const onDemandAmountChange = (amount: any, fieldName: number) => {
    if (amount <= 0 || !amount) {
      return;
    }
    const materialCarryMode = form.getFieldValue('materialCarryMode');
    const { materialId, id: itemId, bomVersion } = form.getFieldValue('itemList')[fieldName] ?? {};
    const id = materialId && JSON.parse(materialId?.value)?.materialId;

    if (materialCarryMode == PurchaseOrderMaterialCarryMode.carry) {
      if (!id || !bomVersion?.label) {
        return;
      }
      getAndSetOutItemList(bomVersion?.label!, id, amount, fieldName + 1, itemId);
    }
  };
  const onBomVersionChange = (
    val: any,
    materialId: number,
    demandAmount: number,
    lineNo: number,
    itemId: number,
  ) => {
    const outItemList = form.getFieldValue('outItemList') || [];

    if (!val?.label) {
      const newOutItemList = outItemList.filter(
        (item: any) => item.purchaseOrderItemLineNo != lineNo + 1,
      );

      form.setFieldsValue({ outItemList: newOutItemList });
      return;
    }
    getAndSetOutItemList(val?.label!, materialId, demandAmount, lineNo + 1, itemId);
  };
  const onMaterialDelete = (index: number[]) => {
    const outItemList = form.getFieldValue('outItemList') || [];
    const newOutItemList = outItemList?.filter(
      (item: any) => item.purchaseOrderItemLineNo != index[0] + 1,
    );

    form.setFieldsValue({ outItemList: newOutItemList });
  };
  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return compact([
      {
        title: '行号',
        dataIndex: 'seqNum',
        key: 'seqNum',
        width: 150,
        render: (_, field) => {
          const materials = form.getFieldValue('itemList');
          // 编辑行号时，新增行号为当前行号值最大加1
          const list = materials.map((el: any) => Number(el?.seqNum || 0));
          const num = Number(max(list)) + 1;

          return (
            <Form.Item
              name={[field?.name, 'seqNum']}
              fieldKey={[field?.key, 'seqNum']}
              initialValue={String(num)}
              style={{ marginBottom: 0 }}
            >
              {/* 删除物料行时,不会自动补序。在最后提交数据时，再对seqNum重新排序 */}
              {field?.name + 1}
            </Form.Item>
          );
        },
      },
      {
        title: <BatchOperateTableHeader titleText="物料名称" required />,
        dataIndex: 'materialId',
        key: 'materialId',
        width: 250,
        render: (_, field) => {
          return (
            <Form.Item
              dependencies={['supplier']}
              rules={[{ required: true, message: '请选择物料' }]}
              noStyle
            >
              {() => {
                const supplier = form.getFieldValue('supplier');

                return (
                  <Form.Item
                    name={[field?.name, 'materialId']}
                    fieldKey={[field?.key, 'materialId']}
                    rules={[{ required: true, message: '请选择物料' }]}
                    style={{ marginBottom: 0 }}
                  >
                    <SearchSelect
                      disabled={orderStatus !== PurchaseExecStatus.created}
                      placeholder="请选择物料"
                      fetchType={'materialForPurchaseOrder'}
                      onChange={(val) => onMaterialChange(val, field?.name)}
                      labelInValue
                      params={{ supplierId: supplier?.value }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: <BatchOperateTableHeader titleText="需求时间" required />,
        dataIndex: 'demandTime',
        key: 'demandTime',
        width: 250,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'demandTime']}
              fieldKey={[field?.key, 'demandTime']}
              rules={[{ required: true, message: '请选择需求时间' }]}
              shouldUpdate
              initialValue={_Time.daysAfter(7)}
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                showTime
                format={'YYYY-MM-DD HH:mm:ss'}
                disabled={orderStatus !== PurchaseExecStatus.created}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '采购周期',
        dataIndex: 'purchaseCycle',
        key: 'purchaseCycle',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item shouldUpdate noStyle>
              {() => {
                const { materialId } = form.getFieldValue('itemList')[field.name] ?? {};
                const purchaseCycle = materialId && JSON.parse(materialId?.value)?.purchaseCycle;

                return (
                  <Form.Item
                    name={[field?.name, 'purchaseCycle']}
                    fieldKey={[field?.key, 'purchaseCycle']}
                    style={{ marginBottom: 0 }}
                  >
                    {purchaseCycle ? purchaseCycle + '天' : replaceSign}
                    {/* 采购周期纯展示 由物料id带出  */}
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: <BatchOperateTableHeader titleText="需求数" required />,
        dataIndex: 'demandAmount',
        key: 'demandAmount',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item shouldUpdate noStyle>
              {() => {
                const { materialId } = form.getFieldValue('itemList')[field.name] ?? {};
                const materialObj = materialId && JSON.parse(materialId?.value);
                const unitName = materialObj?.inventoryAmount?.unitName;
                const unitId = materialObj?.inventoryAmount?.unitId;
                const { enablePrecision, precisionFigure } = materialEntity.getMaterialUnitInfo(
                  materialObj,
                  unitId,
                );

                const purchaseAmountMin = materialObj?.purchaseInfo?.purchaseAmountMin ?? 0;
                const purchaseAmount = materialObj?.purchaseInfo?.purchaseAmount ?? 0;
                const demandAmount = form.getFieldValue([name, field.name, 'demandAmount']);

                return (
                  <Form.Item
                    name={[field?.name, 'demandAmount']}
                    fieldKey={[field?.key, 'demandAmount']}
                    rules={compact([
                      { required: true, message: '请输入需求数' },
                      enablePrecision && {
                        validator: fractionLengthCheck(
                          precisionFigure ?? DEFAULT_PRECISION,
                          `请输入${precisionFigure ?? DEFAULT_PRECISION}位精度的数字`,
                        ),
                      },
                      {
                        validator: numberMinMaxCheck({
                          min: purchaseAmountMin,
                          fieldName: '需求数',
                          message: purchaseAmountMin
                            ? `请输入大于等于最小采购量${purchaseAmountMin}的值`
                            : '请输入大于0的数字',
                        }),
                      },
                      {
                        validator: intMultipleCheck({
                          baseNum: purchaseAmount,
                          compareNum: demandAmount,
                          message: `请输入采购批量${purchaseAmount}整数倍的值`,
                        }),
                      },
                    ])}
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      addonAfter={unitName}
                      type="number"
                      placeholder="请输入需求数"
                      onChange={(e) => {
                        setTotalPriceChange(field.name);
                        onDemandAmountChange(e.target.value, field.name);
                      }}
                      disabled={orderStatus !== PurchaseExecStatus.created}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '可用库存',
        dataIndex: 'inventoryAmount',
        key: 'inventoryAmount',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item shouldUpdate noStyle>
              {() => {
                const { materialId } = form.getFieldValue('itemList')[field.name] ?? {};
                const amount =
                  materialId && JSON.parse(materialId?.value)?.inventoryAmount.amountDisplay;
                const unit = materialId && JSON.parse(materialId?.value)?.inventoryAmount.unitName;

                return (
                  <Form.Item
                    name={[field?.name, 'id']}
                    fieldKey={[field?.key, 'id']}
                    style={{ marginBottom: 0 }}
                  >
                    <span>{amount ? amount + unit : replaceSign}</span>
                    {/* 可用库存纯展示 由物料编号带出 */}
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '含税单价',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item dependencies={['currency']} style={{ marginBottom: 0 }}>
              {() => {
                const item = form.getFieldValue('currency')?.value ?? '{}';
                const currency = JSON.parse(item);

                return (
                  <Form.Item
                    name={[field?.name, 'unitPrice']}
                    fieldKey={[field?.key, 'unitPrice']}
                    rules={[
                      {
                        validator: numberMinMaxCheck({
                          min: 0,
                          max: 10000000000,
                          maxAllowEqual: false,
                          minAllowEqual: false,
                          fieldName: '单价',
                          message: '请输入大于0小于10,000,000,000的数值',
                        }),
                      },
                      {
                        validator: fractionLengthCheck(
                          currency?.unitPriceAccuracy ?? DEFAULT_PRECISION,
                          `请输入${currency?.unitPriceAccuracy ?? DEFAULT_PRECISION}位精度的数字`,
                        ),
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      type="number"
                      addonBefore={currency?.symbol}
                      onChange={() => setTotalPriceChange(field.name)}
                      disabled={orderStatus !== PurchaseExecStatus.created || !currency?.name}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '税率',
        dataIndex: 'taxRate',
        key: 'taxRate',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item style={{ marginBottom: 0 }} dependencies={[['itemList', 0, 'materialId']]}>
              {() => {
                return (
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name={[field?.name, 'taxRate']}
                    fieldKey={[field?.key, 'taxRate']}
                    rules={[
                      {
                        validator: numberMinMaxCheck({
                          min: 0,
                          max: 100,
                          isInteger: true,
                          maxAllowEqual: true,
                          minAllowEqual: false,
                          fieldName: '税率',
                          message: '请输入大于0小于等于100的整数',
                        }),
                      },
                    ]}
                  >
                    <Input addonAfter="%" disabled={orderStatus !== PurchaseExecStatus.created} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '含税金额',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        width: 150,
        render: (_, field) => {
          const { totalPrice } = form.getFieldValue('itemList')[field.name] ?? {};
          const item = form.getFieldValue('currency')?.value ?? '{}';
          const currency = JSON.parse(item);

          return (
            <Form.Item
              name={[field?.name, 'totalPrice']}
              fieldKey={[field?.key, 'totalPrice']}
              style={{ marginBottom: 0 }}
            >
              {renderTotalPrice(totalPrice, currency.amountAccuracy)}
            </Form.Item>
          );
        },
      },
      carryMode === PurchaseOrderMaterialCarryMode.carry && {
        title: '物料清单版本号',
        dataIndex: 'bomVersion',
        key: 'bomVersion',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              dependencies={[
                ['source'],
                ['materialCarryMode'],
                ['itemList', field.name, 'materialId'],
              ]}
              style={{ marginBottom: 0 }}
            >
              {() => {
                const source = form.getFieldValue('source');
                const materialCarryMode = form.getFieldValue('materialCarryMode');
                const {
                  materialId,
                  demandAmount,
                  id: itemId,
                } = form.getFieldValue('itemList')[field.name] ?? {};
                const id = materialId && JSON.parse(materialId?.value)?.materialId;

                return (
                  <Form.Item
                    name={[field?.name, 'bomVersion']}
                    fieldKey={[field?.key, 'bomVersion']}
                    style={{ marginBottom: 0 }}
                  >
                    <SearchSelect
                      fetchType={'bomVersions'}
                      params={{
                        materialIds: [id],
                        active: appEnum.Common.UsageStatus.enabled,
                      }}
                      onChange={(val) =>
                        onBomVersionChange(val, id, demandAmount, field.name, itemId)
                      }
                      disabled={
                        orderStatus !== PurchaseExecStatus.created ||
                        !id ||
                        !Number(demandAmount) ||
                        materialCarryMode != PurchaseOrderMaterialCarryMode.carry
                      }
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ]);
  };

  return (
    <BlSortFormList
      buttonText="添加物料行"
      form={form}
      name={name}
      isNeedDrag={false}
      isNeedAdd={orderStatus === PurchaseExecStatus.created}
      isNeedDelete={orderStatus === PurchaseExecStatus.created}
      onDelete={onMaterialDelete}
      // 注入从对象自定义字段列
      renderColumns={() =>
        injectCustomFieldColumns({
          dataIndex: '',
          columns: getColumns(), // 原本的列
          customFields, // 自定义字段信息
          objectCode: OBJECT_OF_CODE.purchaseOrderItem, // 从对象code
          type: 'form', // 使用类型
          formConfig: { form, formName: name }, // form相关配置
          disabled: edit && orderStatus !== PurchaseExecStatus.created,
        })
      }
      listRules={[
        {
          validator: async (__, items) => {
            if (!items || _.isEmpty(items.filter((i: any) => !!i))) {
              return Promise.reject(Error('请至少添加一行物料'));
            } else if (_.uniqBy(items, 'seqNum').length < items.length) {
              return Promise.reject(Error('不能有重复行号'));
            }
            return Promise.resolve(true);
          },
        },
      ]}
    />
  );
}
