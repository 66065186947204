import { useState } from 'react';
import { BlSortFormList } from 'src/components';
import { InputNumber, Form, FormInstance, Button, message } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import { PlusOutlined } from '@ant-design/icons';
import _ from 'lodash';
import BatchAddMaterials from './batchAddMaterials';
import { fractionLengthCheck, numberMinMaxCheck, textValidator1 } from 'src/utils/formValidators';
import { SalesOrderList } from '../interface';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import TextArea from 'antd/lib/input/TextArea';
import { TextControl } from 'src/components/text';
import { getMaterialUnitInfo } from 'src/entity/material';
import { appEnum } from 'src/dict';
import { CustomerDeliveryScheduleNoteListItemProps } from '../../collaborativeDeliverySchedule/interface';
import { initFormValues } from '../utils';
import { ICustomFields } from 'src/components/customField/interface';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { injectCustomFieldColumns } from 'src/components/customField';
interface Props {
  form: FormInstance;
  disableEdit: boolean;
  isCreate: boolean; // 是否为新建状态
  name: string;
  itemCustomFields: ICustomFields;
}

interface MaterialsTableNode {
  amount: number;
  checked: boolean;
  deliveryDate: Date;
  id: number;
  lineNo: number;
  materialId: {
    label: number;
    value: string;
  };
  unitId: number;
}

const defaultStyle = {
  width: '100%',
};

const MaterialFormTable = (props: Props) => {
  const { form, disableEdit, isCreate, name, itemCustomFields } = props;

  const [batchAddVisible, setBatchAddVisible] = useState(false);
  const [addForm] = Form.useForm();
  const invoiceType = form.getFieldValue('invoiceType');

  const renderAddActon = () => {
    return (
      <div style={{ marginBottom: 20, marginTop: 10 }}>
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          style={{ color: '#02B980', borderColor: '#02B980' }}
          onClick={() => {
            setBatchAddVisible(true);
          }}
          disabled={disableEdit}
        >
          {invoiceType === appEnum.Invoice.InvoiceOrigin.collaborativeDeliverySchedule
            ? '按协同交货计划单添加'
            : '按销售订单添加'}
        </Button>
      </div>
    );
  };
  /**
   * 按销售订单添加行
   */
  const addSaleslLine = (selectedKeys: React.Key[], rows?: SalesOrderList[]) => {
    const materials = form.getFieldValue(name) ?? [];
    let newLine: any = [];

    if (rows && rows?.length > 0) {
      newLine = rows?.map((node: SalesOrderList) => {
        const { orderCode, orderId, lineNo, id, status } = node;

        if (node?.salesOrderItemId) {
          return node;
        }
        return {
          ...node,
          salesOrderId: orderId, // 销售订单id
          salesOrderCode: orderCode,
          salesOrderItemId: id, // 销售订单明细行id
          salesOrderLineNo: lineNo,
          addType: 'new', // 用来标示此明细行是否为新增
          soStatus: status, // 销售订单 状态,
        };
      });
    }
    // 去重
    const _materials = _.uniqBy([...materials, ...newLine], 'salesOrderItemId');
    // 根据用户选择selectedKeys的做删减
    const items = _materials.filter((item) => {
      const { salesOrderItemId } = item;
      const index = _.findIndex(selectedKeys, (o) => o == salesOrderItemId);

      if (index !== -1) {
        return item;
      }
    });

    form.setFieldsValue({
      materials: initFormValues(items, isCreate),
    });
  };
  /**
   * 按协同交货计划订单添加行
   */
  const addCdsnlLine = (
    selectedKeys: React.Key[],
    rows?: CustomerDeliveryScheduleNoteListItemProps[],
  ) => {
    const materials = form.getFieldValue(name) ?? [];
    let newLine: any = [];

    if (rows && rows?.length > 0) {
      newLine = rows?.map((node: CustomerDeliveryScheduleNoteListItemProps) => {
        const {
          code,
          noteId,
          lineNo,
          id,
          deliveryTime,
          status,
          soId,
          saleOrderCode,
          soItemId,
          saleOrderLineNo,
        } = node;

        if (node?.cdsnItemId) {
          return node;
        }
        return {
          ...node,
          addType: 'new', // 用来标示此明细行是否为新增
          cdsnItemId: id, // 协同交货计划单明细行id
          cdsnLineNo: lineNo, // 协同交货计划单明细行 行号,
          deliveryDate: deliveryTime, // 交货时间
          cdsnId: noteId, // 协同交货计划单id
          cdsnCode: code, // 协同交货计划单code
          cdsnStatus: status, // 协同交货计划单 状态,
          salesOrderId: soId, // 销售订单id
          salesOrderCode: saleOrderCode,
          salesOrderItemId: soItemId, // 销售订单明细行id
          salesOrderLineNo: saleOrderLineNo,
        };
      });
    }
    // 去重
    const _materials = _.uniqBy([...materials, ...newLine], 'cdsnItemId');
    // 根据用户选择selectedKeys的做删减
    const items = _materials.filter((item) => {
      const { cdsnItemId } = item;
      const index = _.findIndex(selectedKeys, (o) => o == cdsnItemId);

      if (index !== -1) {
        return item;
      }
    });

    form.setFieldsValue({
      materials: initFormValues(items, isCreate), // 默认值处理
    });
  };
  const handleMaterialFinish = (selectedKeys: React.Key[], rows) => {
    if (invoiceType === appEnum.Invoice.InvoiceOrigin.collaborativeDeliverySchedule) {
      addCdsnlLine(selectedKeys, rows);
      setBatchAddVisible(false);
      return;
    }
    const multipleCustomers = _.uniqBy(rows, 'customer.id').length > 1;

    if (multipleCustomers) {
      message.error('请选择同一客户的销售订单明细行');
      return;
    }
    addSaleslLine(selectedKeys, rows);
    setBatchAddVisible(false);
  };
  // 销售订单列
  const getSalesColumns = (): ColumnProps<MaterialsTableNode & FormListFieldData>[] => {
    return [
      {
        title: '行号',
        width: 120,
        render: (_, field) => {
          return (
            <Form.Item noStyle name={[field?.name, 'lineNo']} fieldKey={[field?.key, 'lineNo']}>
              {field?.name + 1}
            </Form.Item>
          );
        },
      },
      {
        title: '物料编号',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              noStyle
              name={[field?.name, 'material', 'baseInfo', 'code']}
              fieldKey={[field?.key, 'material', 'baseInfo', 'code']}
            >
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        title: '物料名称',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              noStyle
              name={[field?.name, 'material', 'baseInfo', 'name']}
              fieldKey={[field?.key, 'material', 'baseInfo', 'name']}
            >
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        title: '待发货数',
        width: 200,
        render: (_, field) => {
          return (
            <div style={{ display: 'flex' }}>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={[field?.name, 'availableIssueAmount', 'amountDisplay']}
                fieldKey={[field?.key, 'availableIssueAmount', 'amountDisplay']}
              >
                <TextControl />
              </Form.Item>
              <Form.Item
                name={[field?.name, 'availableIssueAmount', 'unitName']}
                fieldKey={[field?.key, 'availableIssueAmount', 'unitName']}
                style={{ marginLeft: '4px', marginBottom: 0 }}
              >
                <TextControl />
              </Form.Item>
            </div>
          );
        },
      },
      {
        title: '计划发货数',
        width: 200,
        render: (_, field) => {
          //  计划发货数
          const availableIssueAmount =
            form.getFieldValue(name)[field.name].availableIssueAmount?.amount;
          // 查询出该单位的精度信息
          const material = form.getFieldValue(name)[field.name]?.material;
          const unitId = form.getFieldValue(name)[field.name].plannedIssueAmount?.unitId;
          const unitInfo = getMaterialUnitInfo(material, unitId);
          const { precisionFigure } = unitInfo;

          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={[field?.name, 'plannedIssueAmount', 'amountDisplay']}
              fieldKey={[field?.key, 'plannedIssueAmount', 'amountDisplay']}
              rules={[
                { required: true, message: '请填写计划发货数' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    max: availableIssueAmount,
                    minAllowEqual: false,
                    fieldName: '计划发货数',
                  }),
                },
                {
                  // 精度校验从单位中获取
                  validator: fractionLengthCheck(
                    precisionFigure,
                    `请输入${precisionFigure}位精度的数字`,
                  ),
                },
              ]}
            >
              <InputNumber disabled={disableEdit} stringMode style={defaultStyle} />
            </Form.Item>
          );
        },
      },
      {
        title: '单位',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              noStyle
              name={[field?.name, 'plannedIssueAmount', 'unitName']}
              fieldKey={[field?.key, 'plannedIssueAmount', 'unitName']}
            >
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        title: '销售订单编号',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              noStyle
              name={[field?.name, 'salesOrderCode']}
              fieldKey={[field?.key, 'salesOrderCode']}
            >
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        title: '销售订单明细行号',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              noStyle
              name={[field?.name, 'salesOrderLineNo']}
              fieldKey={[field?.key, 'salesOrderLineNo']}
            >
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        render: (_, field) => {
          return (
            <Form.Item
              noStyle
              name={[field?.name, 'soStatus']}
              fieldKey={[field?.key, 'soStatus']}
              hidden
            >
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        title: '需求时间',
        dataIndex: 'deliveryDate',
        key: 'deliveryDate',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              noStyle
              name={[field?.name, 'deliveryDate']}
              fieldKey={[field?.key, 'deliveryDate']}
            >
              <TextControl format={formatTimeForRender} />
            </Form.Item>
          );
        },
      },
      {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={[field?.name, 'remark']}
              fieldKey={[field?.key, 'remark']}
              rules={[{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }]}
            >
              <TextArea placeholder="非必填" />
            </Form.Item>
          );
        },
      },
    ];
  };
  // 协同交货计划列
  const getCdsnColumns = (): ColumnProps<MaterialsTableNode & FormListFieldData>[] => {
    return [
      {
        title: '行号',
        width: 120,
        render: (_, field) => {
          return (
            <Form.Item noStyle name={[field?.name, 'lineNo']} fieldKey={[field?.key, 'lineNo']}>
              {field?.name + 1}
            </Form.Item>
          );
        },
      },
      {
        title: '物料编号',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              noStyle
              name={[field?.name, 'material', 'baseInfo', 'code']}
              fieldKey={[field?.key, 'material', 'baseInfo', 'code']}
            >
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        title: '物料名称',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              noStyle
              name={[field?.name, 'material', 'baseInfo', 'name']}
              fieldKey={[field?.key, 'material', 'baseInfo', 'name']}
            >
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        title: '待发货数',
        width: 200,
        render: (_, field) => {
          return (
            <div style={{ display: 'flex' }}>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={[field?.name, 'availableIssueAmount', 'amountDisplay']}
                fieldKey={[field?.key, 'availableIssueAmount', 'amountDisplay']}
              >
                <TextControl />
              </Form.Item>
              <Form.Item
                name={[field?.name, 'availableIssueAmount', 'unitName']}
                fieldKey={[field?.key, 'availableIssueAmount', 'unitName']}
                style={{ marginLeft: '4px', marginBottom: 0 }}
              >
                <TextControl />
              </Form.Item>
            </div>
          );
        },
      },
      {
        title: '计划发货数',
        width: 200,
        render: (_, field) => {
          //  计划发货数
          const availableIssueAmount =
            form.getFieldValue(name)[field.name].availableIssueAmount?.amount;
          // 查询出该单位的精度信息
          const material = form.getFieldValue(name)[field.name]?.material;
          const unitId = form.getFieldValue(name)[field.name].plannedIssueAmount?.unitId;
          const unitInfo = getMaterialUnitInfo(material, unitId);
          const { precisionFigure } = unitInfo;

          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={[field?.name, 'plannedIssueAmount', 'amountDisplay']}
              fieldKey={[field?.key, 'plannedIssueAmount', 'amountDisplay']}
              rules={[
                { required: true, message: '请填写计划发货数' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    max: availableIssueAmount,
                    minAllowEqual: false,
                    fieldName: '计划发货数',
                  }),
                },
                {
                  // 精度校验从单位中获取
                  validator: fractionLengthCheck(
                    precisionFigure,
                    `请输入${precisionFigure}位精度的数字`,
                  ),
                },
              ]}
            >
              <InputNumber disabled={disableEdit} stringMode style={defaultStyle} />
            </Form.Item>
          );
        },
      },
      {
        title: '单位',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              noStyle
              name={[field?.name, 'plannedIssueAmount', 'unitName']}
              fieldKey={[field?.key, 'plannedIssueAmount', 'unitName']}
            >
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        title: '协同交货计划单编号',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item noStyle name={[field?.name, 'cdsnCode']} fieldKey={[field?.key, 'cdsnCode']}>
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        title: '协同交货计划行号',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              noStyle
              name={[field?.name, 'cdsnLineNo']}
              fieldKey={[field?.key, 'cdsnLineNo']}
            >
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        render: (_, field) => {
          return (
            <Form.Item
              noStyle
              name={[field?.name, 'cdsnStatus']}
              fieldKey={[field?.key, 'cdsnStatus']}
              hidden
            >
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        title: '销售订单编号',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              noStyle
              name={[field?.name, 'salesOrderCode']}
              fieldKey={[field?.key, 'salesOrderCode']}
            >
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        title: '销售订单明细行号',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              noStyle
              name={[field?.name, 'salesOrderLineNo']}
              fieldKey={[field?.key, 'salesOrderLineNo']}
            >
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        title: '交货日期',
        dataIndex: 'deliveryDate',
        key: 'deliveryDate',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              noStyle
              name={[field?.name, 'deliveryDate']}
              fieldKey={[field?.key, 'deliveryDate']}
            >
              <TextControl format={formatTimeForRender} />
            </Form.Item>
          );
        },
      },
      {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={[field?.name, 'remark']}
              fieldKey={[field?.key, 'remark']}
              rules={[{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }]}
            >
              <TextArea placeholder="非必填" />
            </Form.Item>
          );
        },
      },
    ];
  };

  const renderColumns = () => {
    if (invoiceType === appEnum.Invoice.InvoiceOrigin.collaborativeDeliverySchedule) {
      return injectCustomFieldColumns({
        columns: getCdsnColumns(),
        customFields: itemCustomFields,
        objectCode: OBJECT_OF_CODE.ShipmentsNoteItem,
        type: 'form',
        formConfig: { form, formName: name },
      });
    }
    return injectCustomFieldColumns({
      columns: getSalesColumns(),
      customFields: itemCustomFields,
      objectCode: OBJECT_OF_CODE.ShipmentsNoteItem,
      type: 'form',
      formConfig: { form, formName: name },
    });
  };

  return (
    <>
      {renderAddActon()}
      <BlSortFormList
        form={form}
        name={name}
        isNeedDrag={false}
        isNeedAdd={false}
        renderColumns={renderColumns}
      />
      {batchAddVisible && (
        <BatchAddMaterials
          addForm={addForm}
          invoiceType={invoiceType}
          selectedKeys={() => {
            const items = form.getFieldValue(name);
            // 发货单详情 - 销售订单明细行id 为 salesOrderItemId
            // 销售订单列表 - 销售订单明细行id 为 id,经过 addSaleslLine 之后的，也会有salesOrderItemId
            // 协同交货计划明细行id 为 id,经过 addSaleslLine 之后的，会有 cdsnItemId
            const selectedKeys = items?.map(
              (item: SalesOrderList) => item?.salesOrderItemId || item?.cdsnItemId,
            );

            return selectedKeys;
          }}
          visible={batchAddVisible}
          handleFinish={handleMaterialFinish}
          onCancel={() => {
            setBatchAddVisible(false);
          }}
        />
      )}
    </>
  );
};

export default MaterialFormTable;
