/**
 * @page 对象设置
 */

import { Typography, Menu } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { qs } from 'src/utils';
import authDict, { hasAuth } from 'src/utils/auth';
import FieldsList from './fieldsList';
import LayoutList from './layout';
import BizTypeList from './bizType';
import { parseLayoutTypes } from './utils';
import styles from './styles.module.scss';

const { Title } = Typography;

const ObjectSettings = () => {
  const { objectId } = useParams<{ objectId: string }>();
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse();
  const layoutTypes = parseLayoutTypes(query.layoutTypes);

  const menuItems = _.compact([
    hasAuth(authDict.businessType_view) && { label: '业务类型', key: 'bizType' },
    { label: '字段', key: 'field' },
    layoutTypes.length > 0 && hasAuth(authDict.layout_view) && { label: '布局', key: 'layout' },
  ]);
  const currentTabKey = query.subject ?? menuItems[0].key;

  const getGoToTab = (subject: string) => () =>
    history.push(`${location.pathname}?${qs.stringify({ ...query, subject })}`);

  return (
    <div className={styles['object-settings']}>
      <div className={styles['object-settings-left']}>
        <div className={styles['title-wrapper']}>
          <Title level={5}>对象设置</Title>
        </div>
        <Menu mode="inline" defaultSelectedKeys={[currentTabKey]}>
          {menuItems.map((item) => (
            <Menu.Item key={item.key} onClick={getGoToTab(item.key)}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </div>
      <div className={styles['object-settings-right']}>
        {currentTabKey === 'bizType' && <BizTypeList />}
        {currentTabKey === 'field' && <FieldsList objectId={+objectId} />}
        {currentTabKey === 'layout' && <LayoutList />}
      </div>
    </div>
  );
};

export default ObjectSettings;
