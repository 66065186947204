import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import { replaceSign } from 'src/utils/constants';
import {
  fetchRepairCaseDetail,
  FetchRepairCaseDetailResponse,
  fetchRepairCaseStatusUpdate,
} from 'src/api/ytt/resource-domain/repairPlan';
import _ from 'lodash';
import TagTooltip from 'src/components/tooltip/tagTooltip';
import lookup, { appEnum } from 'src/dict';
import { BlIcon, BlTable, SingleOperationLogComponent, BcAttachmentDetail } from 'src/components';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { valueOrHolder } from 'src/utils/formatters';
import { goToEdit, goToCopy } from '../navigates';
import { AvatarDisplay } from 'src/components/avatar/show';
import { getStandardWorkTime } from '../utils';
import { gcTime } from 'src/utils';
import MaintenanceStatus from '../components/MaintenanceStatus';
import { PlanAssignFromMap, PlanAssignToMap } from '../constants';
import { BL_OBJECT_CODE } from '../config';

interface Props {
  id: number;
}

const DetailsBaseInfo = (props: Props) => {
  const { id: detailId } = props;
  const [dataSource, setDataSource] = useState<FetchRepairCaseDetailResponse['data']>();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const init = async () => {
    try {
      const res: any = await fetchRepairCaseDetail({ id: detailId });
      const { data } = res;

      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAndSetData = (id?: number) => {
    if (id) {
      setLoading(true);
      return fetchRepairCaseDetail({ id }).then((res) => {
        const { data } = res;

        data && setDataSource(data);

        return res;
      });
    }

    return Promise.reject();
  };

  const getChangeStatus = (stauts: number, idList: number[]) => {
    if (stauts === appEnum.Resources.EnergyMeterStatus.enabled) {
      return fetchRepairCaseStatusUpdate({
        idList,
        status: appEnum.Resources.EnergyMeterStatus.disabeld,
      });
    }
    return fetchRepairCaseStatusUpdate({
      idList,
      status: appEnum.Resources.EnergyMeterStatus.enabled,
    });
  };

  const initBaseMenu = () => {
    const { id, status = 0 } = dataSource ?? {};
    const iconList = ['icontingyong', 'iconqiyong'];

    if (_.isNil(status) || _.isNil(id)) return [];

    return _.compact([
      {
        title: lookup('common.changeStatusAction', status),
        key: 'changeStatus',
        icon: <BlIcon type={iconList[status]} />,
        auth: authDict.repairCase_enable_disable,
        onClick: () => {
          getChangeStatus(status, [detailId]).then((res) => {
            if (res.code === 200) {
              message.success(`${lookup('common.changeStatusAction', status)}成功!`);
              fetchAndSetData(detailId).finally(() => {
                setLoading(false);
              });
            }
          });
        },
      },
      {
        title: '复制',
        key: 'copy',
        auth: authDict.repairCase_add,
        icon: <BlIcon type="iconfuzhi" />,
        onClick: () => {
          goToCopy(detailId);
        },
      },
      // {
      //   key: 'delete',
      //   onClick: () => {
      //     confirm('是否删除当前单位', delForm, () => {
      //       handleFinish(detailId);
      //     });
      //   },
      //   // icon: <<BlIcon type="iconshanchulajitong1" /> />,
      //   title: '删除',
      // },
      {
        key: 'record',
        auth: authDict.repairCase_operrecord,
        onClick: () => {
          setVisibleLog(true);
        },
        title: '操作记录',
      },
      {
        key: 'edit',
        auth: authDict.repairCase_edit,
        onClick: () => {
          goToEdit(detailId);
        },
        icon: <BlIcon type="iconbianji" />,
        title: '编辑',
      },
    ]);
  };

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '维修方案编号',
        dataIndex: 'code',
        // isFullLine: true,
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '维修方案名称',
        dataIndex: 'name',
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '维修业务对象',
        dataIndex: 'bizType',
        render: (type) => valueOrHolder(lookup('resources.RESORCE_BUSINESS_TYPE_MAP', type)),
      },
      {
        label: '维修目标',
        dataIndex: ['biz', 'name'],
      },
      {
        label: '维修目标编号',
        dataIndex: ['biz', 'code'],
      },
      {
        label: '维修目标区域',
        dataIndex: ['biz', 'locationName'],
      },
      {
        label: '维修途径',
        dataIndex: 'channelType',
        render: (type) => valueOrHolder(lookup('resources.repairChannel', type)),
      },
      {
        label: '维修任务分类',
        dataIndex: 'resourceTypeTraceList',
        render: (data) => (data && data.map((item: any) => item.name).join('/')) ?? replaceSign,
      },
      {
        label: '标准工时',
        dataIndex: 'standardWorkTime',
        render: (time) => {
          return valueOrHolder(time, () => {
            const { H, M, S } = getStandardWorkTime(time);

            return `${H}小时${M}分钟${S}秒`;
          });
        },
      },
      {
        label: '计划执行人',
        dataIndex: 'planExecutorList',
        render: (users: any[]) => <TagTooltip list={users || []} isUser max={2} />,
      },
      {
        label: '扫码确认',
        dataIndex: 'scanCodeFlag',
        render: (type) => valueOrHolder(lookup('common.ynb', type)) ?? replaceSign,
      },
      {
        label: '允许转派',
        dataIndex: 'assignFlag',
        render: (type) => valueOrHolder(lookup('common.ynb', type)) ?? replaceSign,
      },
      {
        label: '谁可转派',
        dataIndex: 'assignFrom',
        render: (assignFrom) =>
          valueOrHolder(assignFrom || undefined, () =>
            _.map(
              _.filter([...PlanAssignFromMap.keys()], (key) => (assignFrom & key) === key),
              (key) => PlanAssignFromMap.get(key)?.label,
            ).join('，'),
          ) ?? replaceSign,
      },
      {
        label: '可转派给',
        dataIndex: 'assignTo',
        render: (assignTo) =>
          valueOrHolder(assignTo || undefined, () =>
            _.map(
              _.filter([...PlanAssignToMap.keys()], (key) => (assignTo & key) === key),
              (key) => PlanAssignToMap.get(key)?.label,
            ).join('，'),
          ) ?? replaceSign,
      },
      {
        label: '执行方式',
        dataIndex: 'executeType',
        render: (type) => lookup('resources.maintenanceExecuteType', type) ?? replaceSign,
      },
      {
        label: 'SOP方案',
        dataIndex: 'sopList',
        render: (list) => {
          if (_.isNil(list)) return replaceSign;
          return _.map(list, 'name') ?? replaceSign;
        },
      },
      {
        label: '报告模板',
        dataIndex: 'reportTemplateList',
        render: (list) => {
          if (_.isNil(list)) return replaceSign;
          return _.map(list, 'name').join('，') ?? replaceSign;
        },
      },
      {
        label: '启用状态',
        dataIndex: 'status',
        render: (status) => <MaintenanceStatus status={status} />,
      },
      {
        label: '备注',
        dataIndex: 'remark',
        isFullLine: true,
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '附件',
        dataIndex: 'fileIds',
        render: (fileIds: number[]) => {
          return fileIds ? <BcAttachmentDetail fileIds={fileIds} /> : replaceSign;
        },
        isFullLine: true,
      },
    ],
  };
  const malfunctionInfo: DetailLayoutInfoBlock = {
    title: '故障信息',
    items: [
      {
        label: '故障分类',
        dataIndex: 'malfunctionTraceList',
        render: (data) => (data && data.map((item: any) => item.name).join('/')) ?? replaceSign,
      },
      {
        label: '故障等级',
        dataIndex: 'malfunctionLevel',
        render: (value: number) => lookup('resources.malfunctionLevel', value) || replaceSign,
      },
    ],
  };
  const malfunctionLabel: DetailLayoutInfoBlock = {
    title: '故障标签',
    items: [
      {
        label: '',
        dataIndex: 'tagsList',
        isFullLine: true,
        render: (list: any) => {
          const data = list?.map((item: any) => {
            return {
              name: item.name,
              content: item.labelValue,
            };
          });

          return (
            <BlTable
              dataSource={data}
              pagination={false}
              rowKey={(row: any) => row.id}
              columns={[
                {
                  title: '标签项',
                  dataIndex: 'name',
                },
                {
                  title: '标签值',
                  dataIndex: 'content',
                },
              ]}
            />
          );
        },
      },
    ],
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: any) =>
          valueOrHolder(creator, (creator: any) => (
            <AvatarDisplay {...creator} isShowTag={false} isUser />
          )),
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: any) =>
          valueOrHolder(operator, (operator: any) => (
            <AvatarDisplay {...operator} isShowTag={false} isUser />
          )),
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
    ],
  };
  const infoArray = [baseInfo, malfunctionInfo, malfunctionLabel, otherInfo];

  useEffect(() => {
    if (detailId) {
      init();
    }
  }, [detailId]);
  return (
    <>
      <DetailLayout
        loading={loading}
        title={'维修方案详情'}
        baseMenu={initBaseMenu()}
        info={infoArray}
        dataSource={dataSource}
        userAuth={getAuthFromLocalStorage()}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId as number}
          objectCode={BL_OBJECT_CODE}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailsBaseInfo;
