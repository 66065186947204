/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../../request';

// makeRequest
function makeRequestRequired<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequestOptional<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData?: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequest<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const optional = makeRequestOptional<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  const required = makeRequestRequired<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  return (
    requestConfig.requestDataOptional ? optional : required
  ) as TRequestConfig['requestDataOptional'] extends true ? typeof optional : typeof required;
}

// Request
export type Request<TReqeustData, TRequestConfig extends RequestConfig, TRequestResult> =
  (TRequestConfig['requestDataOptional'] extends true
    ? (
        requestData?: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult
    : (
        requestData: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult) & {
    requestConfig: TRequestConfig;
  };

const mockUrl_0_7_6_0 = 'http://yapi.blacklake.tech/mock/735' as any;
const devUrl_0_7_6_0 = '' as any;
const prodUrl_0_7_6_0 = '' as any;
const dataKey_0_7_6_0 = undefined as any;

/**
 * 接口 [手动调整库存质量状态记录明细↗](http://yapi.blacklake.tech/project/735/interface/api/80006) 的 **请求类型**
 *
 * @分类 [web-检验物料↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11338)
 * @标签 `web-检验物料`
 * @请求头 `POST /quality/domain/web/v1/qc_material/change_qc_status/log_detail`
 * @更新时间 `2021-12-08 11:00:04`
 */
export interface FetchQcMaterialChangeQcStatusLogDetailRequest {
  /**
   * 批次号
   */
  batchNumber?: string;
  /**
   * 标识码
   */
  code?: string;
  /**
   * 调整记录id
   */
  id?: number;
  /**
   * 原质量状态(1合格, 2让步合格, 3待检, 4不合格)
   */
  qcStatusBefore?: number;
  /**
   * 标识码、存储位置、批号搜索
   */
  quickSearch?: string;
  /**
   * 存储位置
   */
  storageName?: string;
  /**
   * 有效期
   */
  validityPeriod?: string;
}

/**
 * 接口 [手动调整库存质量状态记录明细↗](http://yapi.blacklake.tech/project/735/interface/api/80006) 的 **返回类型**
 *
 * @分类 [web-检验物料↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11338)
 * @标签 `web-检验物料`
 * @请求头 `POST /quality/domain/web/v1/qc_material/change_qc_status/log_detail`
 * @更新时间 `2021-12-08 11:00:04`
 */
export interface FetchQcMaterialChangeQcStatusLogDetailResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 文件
     */
    attachmentIds?: number[];
    /**
     * 调整时间
     */
    createdAt?: string;
    /**
     * 物料编号
     */
    materialCode?: string;
    materialList?: {
      /**
       * 列表
       */
      list: {
        /**
         * 批次号
         */
        batchNumber?: string;
        /**
         * 标识码
         */
        code?: string;
        /**
         * 数量
         */
        count?: number;
        /**
         * 物料编号
         */
        materialCode?: string;
        /**
         * 物料名称
         */
        materialName?: string;
        /**
         * 原质量状态(1合格, 2让步合格, 3待检, 4不合格)
         */
        qcStatusBefore?: number;
        /**
         * 存储位置
         */
        storageName?: string;
        /**
         * 有效期
         */
        validityPeriod?: string;
      }[];
      /**
       * 当前页
       */
      page: number;
      /**
       * 总条数
       */
      total: number;
    };
    /**
     * 物料名称
     */
    materialName?: string;
    operator?: {
      /**
       * web-User信息VO-头像
       */
      avatarUrl?: string;
      /**
       * web-User信息VO-编号
       */
      code?: string;
      /**
       * web-User信息VO-描述
       */
      desc?: string;
      /**
       * web-User信息VO-id
       */
      id?: number;
      /**
       * web-User信息VO-name
       */
      name?: string;
      /**
       * web-User信息VO-username
       */
      username?: string;
    };
    /**
     * 调整后质量状态(1合格, 2让步合格, 3待检, 4不合格)
     */
    qcStatusAfter?: number;
    /**
     * 备注
     */
    remark?: string;
    /**
     * 存储位置筛选用
     */
    storageNames?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  requestId?: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [手动调整库存质量状态记录明细↗](http://yapi.blacklake.tech/project/735/interface/api/80006) 的 **请求配置的类型**
 *
 * @分类 [web-检验物料↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11338)
 * @标签 `web-检验物料`
 * @请求头 `POST /quality/domain/web/v1/qc_material/change_qc_status/log_detail`
 * @更新时间 `2021-12-08 11:00:04`
 */
type FetchQcMaterialChangeQcStatusLogDetailRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_material/change_qc_status/log_detail',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [手动调整库存质量状态记录明细↗](http://yapi.blacklake.tech/project/735/interface/api/80006) 的 **请求配置**
 *
 * @分类 [web-检验物料↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11338)
 * @标签 `web-检验物料`
 * @请求头 `POST /quality/domain/web/v1/qc_material/change_qc_status/log_detail`
 * @更新时间 `2021-12-08 11:00:04`
 */
const fetchQcMaterialChangeQcStatusLogDetailRequestConfig: FetchQcMaterialChangeQcStatusLogDetailRequestConfig =
  {
    mockUrl: mockUrl_0_7_6_0,
    devUrl: devUrl_0_7_6_0,
    prodUrl: prodUrl_0_7_6_0,
    path: '/quality/domain/web/v1/qc_material/change_qc_status/log_detail',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_7_6_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchQcMaterialChangeQcStatusLogDetail',
  };

/**
 * 接口 [手动调整库存质量状态记录明细↗](http://yapi.blacklake.tech/project/735/interface/api/80006) 的 **请求函数**
 *
 * @分类 [web-检验物料↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11338)
 * @标签 `web-检验物料`
 * @请求头 `POST /quality/domain/web/v1/qc_material/change_qc_status/log_detail`
 * @更新时间 `2021-12-08 11:00:04`
 */
export const fetchQcMaterialChangeQcStatusLogDetail = makeRequest<
  FetchQcMaterialChangeQcStatusLogDetailRequest,
  FetchQcMaterialChangeQcStatusLogDetailResponse,
  FetchQcMaterialChangeQcStatusLogDetailRequestConfig
>(fetchQcMaterialChangeQcStatusLogDetailRequestConfig);

/**
 * 接口 [手动调整库存质量状态记录列表↗](http://yapi.blacklake.tech/project/735/interface/api/80012) 的 **请求类型**
 *
 * @分类 [web-检验物料↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11338)
 * @标签 `web-检验物料`
 * @请求头 `POST /quality/domain/web/v1/qc_material/change_qc_status/log_list`
 * @更新时间 `2021-12-08 11:00:04`
 */
export interface FetchQcMaterialChangeQcStatusLogListRequest {
  /**
   * 编号
   */
  code?: string;
  /**
   * 调整时间-开始
   */
  createdAtFrom?: string;
  /**
   * 调整时间-结束
   */
  createdAtTo?: string;
  /**
   * 物料id
   */
  materialId?: number;
  /**
   * 物料名称
   */
  materialName?: string;
  /**
   * 操作人id
   */
  operatorId?: number;
  /**
   * 修改后质量状态(1合格, 2让步合格, 3待检, 4不合格)
   */
  qcStatusAfter?: number;
  /**
   * 记录编号、物料名称、物料编号、备注、操作人搜索
   */
  quickSearch?: string;
}

/**
 * 接口 [手动调整库存质量状态记录列表↗](http://yapi.blacklake.tech/project/735/interface/api/80012) 的 **返回类型**
 *
 * @分类 [web-检验物料↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11338)
 * @标签 `web-检验物料`
 * @请求头 `POST /quality/domain/web/v1/qc_material/change_qc_status/log_list`
 * @更新时间 `2021-12-08 11:00:04`
 */
export interface FetchQcMaterialChangeQcStatusLogListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * 文件
       */
      attachmentIds?: number[];
      /**
       * 记录编号
       */
      code?: string;
      /**
       * 调整时间
       */
      createdAt?: string;
      /**
       * 调整记录id
       */
      logId?: number;
      /**
       * 物料编号
       */
      materialCode?: string;
      /**
       * 物料名称
       */
      materialName?: string;
      operator?: {
        /**
         * web-User信息VO-头像
         */
        avatarUrl?: string;
        /**
         * web-User信息VO-编号
         */
        code?: string;
        /**
         * web-User信息VO-描述
         */
        desc?: string;
        /**
         * web-User信息VO-id
         */
        id?: number;
        /**
         * web-User信息VO-name
         */
        name?: string;
        /**
         * web-User信息VO-username
         */
        username?: string;
      };
      /**
       * 调整后质量状态(1合格, 2让步合格, 3待检, 4不合格)
       */
      qcStatusAfter?: number;
      /**
       * 备注
       */
      remark?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  requestId?: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [手动调整库存质量状态记录列表↗](http://yapi.blacklake.tech/project/735/interface/api/80012) 的 **请求配置的类型**
 *
 * @分类 [web-检验物料↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11338)
 * @标签 `web-检验物料`
 * @请求头 `POST /quality/domain/web/v1/qc_material/change_qc_status/log_list`
 * @更新时间 `2021-12-08 11:00:04`
 */
type FetchQcMaterialChangeQcStatusLogListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_material/change_qc_status/log_list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [手动调整库存质量状态记录列表↗](http://yapi.blacklake.tech/project/735/interface/api/80012) 的 **请求配置**
 *
 * @分类 [web-检验物料↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11338)
 * @标签 `web-检验物料`
 * @请求头 `POST /quality/domain/web/v1/qc_material/change_qc_status/log_list`
 * @更新时间 `2021-12-08 11:00:04`
 */
const fetchQcMaterialChangeQcStatusLogListRequestConfig: FetchQcMaterialChangeQcStatusLogListRequestConfig =
  {
    mockUrl: mockUrl_0_7_6_0,
    devUrl: devUrl_0_7_6_0,
    prodUrl: prodUrl_0_7_6_0,
    path: '/quality/domain/web/v1/qc_material/change_qc_status/log_list',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_7_6_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchQcMaterialChangeQcStatusLogList',
  };

/**
 * 接口 [手动调整库存质量状态记录列表↗](http://yapi.blacklake.tech/project/735/interface/api/80012) 的 **请求函数**
 *
 * @分类 [web-检验物料↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11338)
 * @标签 `web-检验物料`
 * @请求头 `POST /quality/domain/web/v1/qc_material/change_qc_status/log_list`
 * @更新时间 `2021-12-08 11:00:04`
 */
export const fetchQcMaterialChangeQcStatusLogList = makeRequest<
  FetchQcMaterialChangeQcStatusLogListRequest,
  FetchQcMaterialChangeQcStatusLogListResponse,
  FetchQcMaterialChangeQcStatusLogListRequestConfig
>(fetchQcMaterialChangeQcStatusLogListRequestConfig);

/* prettier-ignore-end */
