import React, { useEffect, useState } from 'react';
import { Checkbox, Form, FormInstance, Input, FormItemProps } from 'antd';
import { fetchCustomFieldDetailByCode } from 'src/api/ytt/metadata-domain/customField';
import styles from './styles.module.scss';
import { Rule } from 'antd/lib/form';

interface Props extends FormItemProps {
  form: FormInstance;
  edit: boolean; // 是否是编辑状态
  objectCode: string; // 对象code
  fieldCode: string; // 对象下的字段
  disabledState?: boolean; // 编辑状态是否可编辑,默认可编辑状态
  rules: Rule[];
  namePath?: (string | number)[]; // 针对多层路径下的嵌套字段的code编号 为[]就是第一层
  checkboxLayout?: 'horizontal' | 'vertical'; // checkbox 方向
  isFullLine?: boolean;
  span?: number;
  refreshMarker?: number; // 刷新
  onChange?: (e: any) => void; // code输入框的onChange
  onChangeCheckBox?: () => void; // 代表checkBox发生了改变的
}

const defaultNamePath: (string | number)[] = [];

export default function NumberRulesForm({
  label,
  form,
  edit,
  fieldCode,
  objectCode,
  rules,
  namePath = defaultNamePath,
  disabledState = false,
  checkboxLayout = 'horizontal',
  onChange,
  onChangeCheckBox,
  ...restProps
}: Props) {
  const [numberCodeId, setNumberCodeId] = useState<number>(0); // 未启用/未引用都是单文本. 唯一判断条件，是否有编号规则id

  const [isNumberRuleConfig, setIsNumberRuleConfig] = useState<number>(0); // 是否配置编号规则
  const [checkState, setCheckState] = useState<boolean>(true); // 是否勾选复选框

  const ruleIdPath = [...namePath, 'numberRuleId'];

  const fieldCodePath = [...namePath, fieldCode];

  const fetchData = async () => {
    try {
      const { data, code } = await fetchCustomFieldDetailByCode({ fieldCode, objectCode });

      if (code === 200) {
        if (data?.isNumberRuleConfig && data?.numberRuleId) {
          const code = form.getFieldValue('code');

          if (!code) {
            // 1.  没有code 直接默认使用编号规则
            form?.setFields([
              { name: ruleIdPath, value: data?.numberRuleId },
              { name: fieldCodePath, value: '' },
            ]);
            setCheckState(true);
          } else {
            // 2. 手动输入的code 但是可以选择是否使用编号规则
            setCheckState(false);
            form?.setFields([{ name: ruleIdPath, value: '' }]);
          }
          // 只要请求有编号id  这两个就一定赋值
          setNumberCodeId(data?.numberRuleId);
          setIsNumberRuleConfig(data?.isNumberRuleConfig ?? 0);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!edit) {
      const numberId = form.getFieldValue(ruleIdPath);
      // 只有form有numberId 则一定是使用了编号规则

      if (numberId) {
        setCheckState(true);
        setIsNumberRuleConfig(1);
        setNumberCodeId(numberId);
      } else {
        fetchData();
      }
    }
  }, []);

  const show = !edit && isNumberRuleConfig && numberCodeId > 0;

  const item = show ? (
    <Form.Item label={label} required>
      <div
        style={{
          display: 'flex',
          flexDirection: checkboxLayout === 'horizontal' ? 'row' : 'column',
        }}
      >
        <Form.Item name={ruleIdPath} hidden>
          <Input />
        </Form.Item>
        <Form.Item
          className={styles?.numberRulItem}
          style={{ flex: 1 }}
          validateFirst
          name={fieldCodePath}
          rules={[
            {
              required: !checkState,
              message: `${label}不能为空`,
            },
            ...rules,
          ]}
          {...restProps}
        >
          <Input
            placeholder={checkState ? '保存后按规则生成' : '请输入'}
            disabled={checkState}
            onChange={onChange}
          />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: checkboxLayout === 'vertical' ? 'flex-start' : 'flex-end',
            marginTop: checkboxLayout === 'vertical' ? 22 : 0,
            width: 100,
          }}
        >
          <Checkbox
            checked={checkState}
            onChange={(e: any) => {
              setCheckState(e?.target?.checked);
              onChangeCheckBox && onChangeCheckBox();
              if (e?.target?.checked) {
                // 选中状态
                form?.setFields([
                  { name: ruleIdPath, value: numberCodeId },
                  { name: fieldCodePath, value: '' },
                ]);
              } else {
                form?.setFields([{ name: ruleIdPath, value: '' }]);
              }
            }}
          >
            编号规则
          </Checkbox>
        </div>
      </div>
    </Form.Item>
  ) : (
    <Form.Item
      label={label}
      name={[...namePath, fieldCode]}
      validateFirst
      rules={[
        {
          required: true,
          message: `${label}不能为空`,
        },
        ...rules,
      ]}
      {...restProps}
    >
      <Input placeholder="请输入" disabled={edit && disabledState} />
    </Form.Item>
  );

  return item;
}
