import { fetchMaterialCategoryList } from 'src/api/ytt/material-domain/Web_MaterialCategory';
import {
  fetchLocationGetList,
  fetchLocationGetListByParent,
  FetchLocationGetListByParentResponse,
} from 'src/api/ytt/resource-domain/areaConfig';
import { STATUS_ENUM } from 'src/page/knowledgeManagement/warehouse/constant';
import { STATUS_VALUE } from 'src/page/resource/areaConfig/constants';
import {
  fetchTriggerRuleTreeByCode,
  FetchTriggerRuleTreeByCodeResponse,
} from 'src/api/ytt/trigger-domain/triggerRule';

const formatArea = (data: any) => {
  if (data instanceof Array) {
    return data;
  }
  return [data];
};
interface Config {
  fetchFn: ({ searchParams }: { searchParams: string | undefined }) => any; // 获取全部级联数据
  formatter?: <T>(arg: T) => any; // 接口数据format
  loadData?: (
    parentId: number,
    enableStatus?: number,
  ) => Promise<FetchLocationGetListByParentResponse>; // 动态加载数据接口
  fieldNames?: { label: string; value: string; children: string; getDataSource: any }; // 格式化为级联数据的参数
}
export const configs: { [x: string]: Config } = {
  /** 物料分类 */
  materialCategory: {
    fetchFn: ({ searchParams }: { searchParams: string | undefined }) =>
      fetchMaterialCategoryList({ page: 1, size: 1000, quickSearch: searchParams }),
  },
  // /** 区域 */
  // area: {
  //   fetchFn: (searchParams: string | undefined) => fetchLocationGetList({ searchContent: searchParams || '1' }),
  //   formatter: formatArea,
  //   loadData: async (parentId: number, enableStatus?: number) =>
  //     fetchLocationGetListByParent({ parentId, enableStatus }),
  // },

  /** 标准业务字段 */
  bizObjectTree: {
    fetchFn: ({ searchParams, ...rest }: { searchParams: string | undefined }) => {
      return fetchTriggerRuleTreeByCode(rest);
    },
    formatter: (data: FetchTriggerRuleTreeByCodeResponse['data']) => {
      return data?.fields;
    },
    fieldNames: {
      label: 'fieldName',
      value: 'id',
      children: 'object.fields',
      getDataSource: ({ fieldType, choiceValues, targetType, reference }: any) => ({
        fieldType,
        choiceValues,
        targetType,
        reference,
      }),
    },
  },
};
