/**
 * 业务名称
 */
export const BL_BUSINESS_NAME = '维保方案';

/**
 * 业务基础URL
 */
export const BL_BUSINESS_URL = '/resource/implement/maintenance/plan';

/**
 * 业务编码
 */
export const BL_BUSINESS_CODE = 'maintenancePlan';
