export const STATE_TYPE = new Map([
  [0, '停用'],
  [1, '启用中'],
]);

export enum AUTH_TYPE {
  VISIBLE = 'visible',
  EDITABLE = 'editable',
}

export enum statusEnum {
  disable_uncheck = 0, // 不可操作 + 未选中
  disable_check = 1, // 不可操作 + 选中
  enable_uncheck = 2, // 可操作 + 未选中
  enable_check = 3, // 可操作 + 选中
}
