/**
 * @page 业务类型新建/编辑
 */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { pick } from 'lodash/fp';
import { Form, Input, Radio, message } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';
import lookup from 'src/dict';
import { CRUD, UsageStatus, YN } from 'src/dict/common';
import { yn as ynMappings } from 'src/dict/common/mappings';
import {
  fetchBizTypeCreate,
  fetchBizTypeUpdate,
  fetchBizTypeGetById,
  FetchBizTypeCreateRequest,
  FetchBizTypeUpdateRequest,
  FetchBizTypeGetByIdResponse,
} from 'ytt/metadata-domain/bizType';
import { FetchRoleListRoleResponse, fetchRoleListRole } from 'ytt/user-domain/user/role';
import { useBlocker, qs } from 'src/utils';
import { checkTwoSidesTrim, textValidator3 } from 'src/utils/formValidators';
import { BatchSelect } from 'src/components';
import { RoleAvatar } from 'src/components/avatar/show';
import { DisplayText } from 'src/components/editableText';
import { backToSettings } from '../navigation';

type PathParams = {
  objectId: string;
  bizTypeId?: string;
};
type BizTypeDetail = Required<Required<FetchBizTypeGetByIdResponse>['data']>;
interface BizTypeFormData
  extends Omit<FetchBizTypeCreateRequest & FetchBizTypeUpdateRequest, 'roles'> {
  roles?: BizTypeDetail['roles'];
}
type LoadingComp = null | 'data' | 'save';

const getContentDOM = () => document.querySelector<HTMLElement>('.custom-biz-type-edit')!;

export const BizTypeEdit = () => {
  const [loadingComp, setLoadingComp] = useState<LoadingComp>(null);
  const [roleList, setRoleList] = useState<{ id: number; name: string }[]>([]);
  const { objectId, bizTypeId } = useParams<PathParams>();
  const [form] = Form.useForm();
  const { objectCode } = qs.parse();
  const { initialValues, resetForm, history } = useBlocker<BizTypeFormData>(form, {
    initValues: {
      objectCode,
      status: UsageStatus.enabled,
      isPreset: YN.no,
      roles: [],
    },
  });

  const isEdit = !!bizTypeId;

  const info: DataFormLayoutInfoBlock[] = [
    {
      title: `${lookup('crud', isEdit ? CRUD.edit : CRUD.create)}业务类型`,
      items: _.compact([
        { name: 'objectCode', hidden: true },
        isEdit ? { name: 'id', hidden: true } : null,
        {
          label: '业务类型编号',
          name: 'code',
          rules: [
            { required: true, message: '业务类型编号必填' },
            { type: 'string', max: 100, message: '不超过100个字符' },
            { validator: textValidator3 },
          ],
          render: () => <Input placeholder="请输入" disabled={isEdit} />,
        },
        {
          label: '业务类型名称',
          name: 'name',
          rules: [
            { required: true, message: '业务类型名称必填' },
            { type: 'string', max: 255, message: '不超过255个字符' },
            { validator: checkTwoSidesTrim },
          ],
          render: () => <Input placeholder="请输入" />,
        },
        {
          label: '备注',
          name: 'remark',
          rules: [
            { type: 'string', max: 1000, message: '不超过1000个字符' },
            { validator: checkTwoSidesTrim },
          ],
          render: () => <Input.TextArea placeholder="请输入" />,
        },
        {
          label: '状态',
          name: 'status',
          render: () => <DisplayText renderText={lookup('usageStatus')} />,
        },
        {
          label: '是否预置',
          name: 'isPreset',
          render: () => <Radio.Group options={ynMappings} disabled />,
        },
        {
          label: '适用角色',
          name: 'roles',
          render: () => (
            <BatchSelect
              useIdName
              options={roleList}
              optionLabelProp="label"
              renderOption={(name) => (
                <span>
                  <RoleAvatar name={name as string} />
                  {name}
                </span>
              )}
              getPopupContainer={getContentDOM}
            />
          ),
        },
      ]),
    },
  ];

  const goBack = () => history.push(backToSettings(objectId));
  const handleFinish = () => {
    form
      .validateFields()
      .then(() => {
        const values = form.getFieldsValue(true);
        const submitValues = { ...values, roles: _.map(values.roles, 'id') };

        setLoadingComp('save');
        (isEdit ? fetchBizTypeUpdate(submitValues) : fetchBizTypeCreate(submitValues))
          .then(() => {
            message.success('保存完成');
            resetForm(values);
            goBack();
          })
          .catch(() => {
            setLoadingComp(null);
          });
      })
      .catch(({ errorFields }) => {
        console.error(errorFields);
        form.scrollToField(errorFields[0].name, { behavior: 'smooth' });
      });
  };

  useEffect(() => {
    setLoadingComp('data');
    Promise.all(
      _.compact([
        fetchRoleListRole(),
        isEdit && fetchBizTypeGetById({ id: _.toNumber(bizTypeId) }),
      ]),
    )
      .then((resList) => {
        const roles = (resList[0] as FetchRoleListRoleResponse).data!.map(pick(['id', 'name']));

        setRoleList(roles as Required<typeof roles[number]>[]);
        if (isEdit) {
          resetForm((resList[1] as FetchBizTypeGetByIdResponse).data!);
        } else {
          resetForm({ ...initialValues, roles });
        }
      })
      .finally(() => setLoadingComp(null));
  }, [objectCode]);

  return (
    <DataFormLayout
      bodyClassName="custom-biz-type-edit"
      bodyStyle={{ position: 'relative' }}
      loading={loadingComp === 'data'}
      form={form}
      info={info}
      confirmLoading={loadingComp === 'save'}
      onCancel={goBack}
      onFinish={handleFinish}
    />
  );
};
