/**
 * @enum 生产工单业务模块枚举值
 */

/** 工单类型字段map */
export enum ProductionOrderTypesEnum {
  /** 普通 */
  ORDINARY = 1,
  /** 返工 */
  REWORK,
  /** 改制 */
  RESTRUCTURING,
  /** 维修 */
  MAINTAIN,
}

/** 业务状态字段map */
export enum BusinessStatusEnum {
  /** 草稿 */
  DRAFT,
  /** 新建 */
  CREATED,
  /** 已下发 */
  SCHEDULED,
  /** 执行中 */
  IN_PROGRESS,
  /** 已完工 */
  COMPLETED,
  /** 已关闭 */
  CLOSED,
}

/** 暂停状态字段map */
export enum SuspendedStatusEnum {
  /** 未暂停 */
  NOTSUSPENDED,
  /** 暂停中 */
  PAUSED,
}
/** 发料状态字段map */
export enum GoodsIssusStatusEnum {
  /** 未发料 */
  UNRELEASED = 1,
  /** 部分发料 */
  PARTIALISSUE,
  /** 全部发料 */
  ALLISSUES,
  /** 超额发料 */
  OVERISSUE,
}
/** 生成规则 */
export enum GenerateRulesEnum {
  /** 未暂停 */
  GENERATE = 1,
  /** 暂停中 */
  NOTGENERATE,
}

/** 领料方式 */
export enum ItemTypeEnum {
  /** 标准件 */
  STANDARD = 1,
  /** 替代件 */
  REPLACE,
}

/** 批号方式 */
export enum BatchGenerationTypeEnum {
  /** 编号规则 */
  NUMBERINGRULES = 1,
  /** 批号 */
  BATCH,
  /** 不生成 */
  DOESNOTGENERATE,
}

/** 领料申请单据 */
export enum AcquisitionApplicationEnum {
  /** 生产工单 */
  PRODUCTIONWORKORDER = 1,
  /** 生产任务 */
  PRODUCTIONTASK,
}

/** 生成方式 */
export enum GenerationEnum {
  /** 手工录入 */
  manual = 1,
  /** 拆分 */
  split,
  /** 合并 */
  merge,
  /** 改制 */
  transform,
  /** 计划订单投放 */
  launch,
  /** 计划订单投放 */
  scheduling,
}

/** 替代策略 */
export enum SubstitutionStrategyEnum {
  /** 混用替代 */
  HYBRID = 1,
  /** 整批替代 */
  COMPLETE,
}

/** 替代料领料方式 */
export enum pickModeEnum {
  /** 按需领料 */
  ONDEMAND = 1,
  /** 不领料 */
  NOPICKMODE,
}

/**
 * 生产工单关闭类型
 */
export enum WorkOrderCloseTypeEnum {
  normalShutdown = 1,
  abnormalShutdown,
}
/**
 * 来源单据类型
 */
export enum SourceType {
  /** 生产工单 */
  produceWorkOrder = 1,
  /** 销售订单 */
  salesOrder,
  /** 计划订单 */
  ploOrder,
}

/**
 * 工单业务状态 - 任务筛选
 */
export enum WorkOrderStatus {
  /** 已下发 */
  dispatch = 2,
  /** 执行中 */
  perform,
  /** 已完工 */
  completed,
  /** 已关闭 */
  closed,
}

/**
 * 生产工单关闭类型筛选
 */
export enum WOCloseFilterTypeEnum {
  notClosed,
  normalShutdown,
  abnormalShutdown,
}

/**
 * 报工记录中的质量状态
 */
export enum QualityStatusEnum {
  // 合格
  qualified = 1,
  // 不合格
  unqualified = 4,
}
