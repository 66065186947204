/*
 * @Author: huangsijia
 * @Date: 2021-11-23 14:10:17
 * @LastEditTime: 2022-02-25 14:08:51
 * @LastEditors: huangsijia
 * @Description: 不良原因抽屉详情
 * @FilePath: /bf-main-3/src/page/quality/qcDefectReason/components/QcDefectReasonDrawer.tsx
 */

import { BlIcon, SingleOperationLogComponent } from 'src/components';
import { QcDefectReasonType, DetailDataType } from '../interface';
import { match, RouteComponentProps, useHistory } from 'react-router-dom';
import { fetchQcDefectReasonDetail } from 'src/api/ytt/quality-domain/qc_defect_reason';
import { useState } from 'react';
import { gcUrl } from 'src/utils';
import {
  customLayoutChooseButton,
  customLayoutCreateButton,
} from 'src/components/customLayout/hooks/customLayoutForButton';
import { Button, Space } from 'antd';
import { CreatorData } from 'src/page/custom_fields/fieldsList';
import { AvatarDisplay } from 'src/components/avatar/show';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import authDict from 'src/utils/auth';
import { CustomDetailLayout } from 'src/components/customLayout';

interface DetailProps extends RouteComponentProps {
  match: match<{ id: string; layoutId: string }>;
}

const QcDefectReasonDrawer = (props: DetailProps) => {
  const { match } = props;
  const detailId = Number(match?.params?.id) ?? 0;
  const { layoutId } = gcUrl.getParams();
  const history = useHistory();
  const [dataSource, setDataSource] = useState<DetailDataType>({} as any);
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录

  const fetchData = async () => {
    try {
      const { data } = await fetchQcDefectReasonDetail({ id: detailId });

      setDataSource(data as DetailDataType);
    } catch (err) {
      console.log(err);
    }
  };

  const baseMenu = [
    // {
    //   key: 'delete',
    //   title: '删除',
    //   icon: <BlIcon type="iconshanchulajitong1" />,
    //   onClick: () => {
    //     BLDelConfirm(
    //       delMsg,
    //       delForm,
    //       () => {
    //         typeof onDelete === 'function' && onDelete();
    //         // typeof onSuccess === 'function' && onSuccess();
    //       },
    //       // onFail,
    //     );
    //   },
    // },
    {
      title: '操作记录',
      auth: authDict.causeofdefect_operrecord,
      icon: 'iconcaozuojilu',
      onClick: () => {
        setVisibleLog(true);
      },
    },
    {
      title: 'edit',
      auth: authDict.causeofdefect_edit,
      buttonRender: customLayoutChooseButton({
        title: '编辑',
        objectCode: 'CauseOfDefect',
        type: 'edit',
        buttonType: 'primary',
        icon: <BlIcon type="iconbianji" />,
        onClick: (layoutId: number) => {
          history.push(
            `/quality/qualityModeling/qcDefectReason/${detailId}/edit?layoutId=${layoutId}`,
          );
        },
      }),
    },
  ];

  return (
    <>
      <CustomDetailLayout
        objectCode={'CauseOfDefect'}
        dataSource={dataSource}
        instanceIdParamKey={detailId}
        baseMenu={baseMenu}
        layoutId={layoutId}
        title="不良原因详情"
        customInfoItems={[
          {
            label: '创建人',
            dataIndex: 'creator',
            render: (creator: CreatorData) => {
              return (
                <Space>
                  <AvatarDisplay
                    id={Number(creator?.id)}
                    name={creator?.name ?? replaceSign}
                    avatarUrl={creator?.avatarUrl}
                    key={creator?.id}
                    isShowTag={false}
                    isUser
                  />
                </Space>
              );
            },
          },
          {
            label: '创建时间',
            dataIndex: 'createdAt',
            isFullLine: true,
            render: (createdAt: Date) => _Time.format(createdAt) ?? replaceSign,
          },

          {
            label: '更新人',
            dataIndex: 'operator',
            render: (operator: CreatorData) => {
              return operator ? (
                <Space>
                  <AvatarDisplay
                    id={Number(operator?.id)}
                    name={operator?.name ?? replaceSign}
                    avatarUrl={operator?.avatarUrl}
                    key={operator?.id}
                    isShowTag={false}
                    isUser
                  />
                </Space>
              ) : (
                replaceSign
              );
            },
          },
          {
            label: '更新时间',
            dataIndex: 'updatedAt',
            render: (updatedAt: Date, record: any) => {
              return record?.operator
                ? _Time.format(updatedAt) ?? _Time.format(record?.createdAt) ?? replaceSign
                : replaceSign;
            },
          },
        ]}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={'CauseOfDefect'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default QcDefectReasonDrawer;
