import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown, Menu, Avatar } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ContextType } from 'src/page/app/constant';
import { RootState } from 'src/store';
import { BlIcon } from 'src/components';
import { useHistory } from 'react-router';
import BlackPrivacyModal from 'src/page/app/components/userPrivacy/blackPrivacyModal';
import { fetchUserGetUserOrgList, fetchUserSwitchOrg } from 'src/api/ytt/user-domain';
import ChooseFactory from 'src/page/login/components/chooseFactory';
import useBlModal from 'src/components/modal/useBlModal';
import { afterLoginHandler } from 'src/utils/login';
import { backendRoutes } from 'src/route';
import defaultAvatar from 'src/assets/default-avatar.png';
import volcano from 'src/utils/volcano';

const User = (props: any) => {
  const { onSwitchContext } = props;
  const { avatarUrl, phone, bound, orgId } = useSelector(
    (state: RootState) => state?.user.userInfo,
    shallowEqual,
  );
  const privilegeInfo = useSelector((state: RootState) => state?.user.privilegeInfo, shallowEqual);

  const dispatch = useDispatch();
  const logout = useCallback(() => {
    volcano.webLogout();
    dispatch({ type: 'user/clearUser' });
  }, [dispatch]);
  const history = useHistory();
  const [visible, setVisible] = useState<boolean>(false);
  const [privacyType, setPrivacyType] = useState<number>(0);
  const [orgList, setOrgList] = useState([] as any);
  const { hiddenProtocol } = window.hostConfig;

  const { show } = useBlModal();

  const chooseFactory = useCallback(
    (orgInfo) => {
      show({
        title: '选择您要进入的工厂',
        content: (
          <ChooseFactory
            orgInfo={orgInfo}
            onSelect={async (info: any) => {
              try {
                const res = await fetchUserSwitchOrg({ phone, orgCode: info.code });

                if (res) {
                  await afterLoginHandler({ data: { token: res?.data } });
                  window.location.reload();
                }
              } catch (error) {
                console.log('error: ', error);
              }
            }}
          />
        ),
        footer: false,
        width: 436,
      });
    },
    [show, phone],
  );

  const getChooseOrgList = async () => {
    const { data } = await fetchUserGetUserOrgList({ phone });

    setOrgList(data?.filter((item) => item.orgId !== orgId));
  };

  // 用户的手机号为绑定状态，且工厂也绑定了此手机号，允许切换工厂
  const chooseFactoryTag = orgList?.length > 0 && phone && bound;
  const hasBackstageAuth = useMemo(() => {
    const backstageMenuAuthList = backendRoutes
      .filter(({ path }) => !path.startsWith('*'))
      .map((i) => i.auth)
      .flat();

    return backstageMenuAuthList.some((auth) => auth === undefined || privilegeInfo.includes(auth));
  }, [privilegeInfo]);

  useEffect(() => {
    if (phone) getChooseOrgList();
  }, [phone, bound]);

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push('/userSetting');
        }}
        icon={<BlIcon type="icongerenshezhi" />}
      >
        个人设置
      </Menu.Item>
      {/* <Menu.Item icon={<GlobalOutlined />}>切换语言</Menu.Item> */}
      {
        // 部分特殊客户需要隐藏协议入口
        hiddenProtocol ? null : (
          <Menu.SubMenu icon={<BlIcon type="iconguanyu" />} title="关于黑湖">
            <Menu.Item
              onClick={() => {
                setVisible(true);
                setPrivacyType(0);
              }}
            >
              黑湖软件服务协议
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                setVisible(true);
                setPrivacyType(1);
              }}
            >
              个人信息保护政策
            </Menu.Item>
          </Menu.SubMenu>
        )
      }
      {hasBackstageAuth && (
        <Menu.Item
          onClick={() => {
            onSwitchContext?.(ContextType.BACKEND);
          }}
          icon={<BlIcon type="iconhoutaiguanli" />}
        >
          后台管理
        </Menu.Item>
      )}
      {chooseFactoryTag ? (
        <Menu.Item
          onClick={() => {
            chooseFactory(orgList);
          }}
          icon={<BlIcon type="icongongchang" />}
        >
          切换工厂
        </Menu.Item>
      ) : null}
      <Menu.Item onClick={logout} icon={<BlIcon type="icontuichudenglu" />}>
        退出登录
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Dropdown overlay={menu} placement="topLeft" arrow>
        <Avatar size={40} src={avatarUrl || defaultAvatar} />
      </Dropdown>
      {visible && (
        <BlackPrivacyModal
          visible={visible}
          privacyType={privacyType}
          onCancel={() => {
            setVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default User;
