import React, { useEffect } from 'react';
import {
  Button,
  Checkbox,
  Form,
  FormItemProps,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { checkTwoSidesTrim, numberAlphabetSpecialSymbols } from 'src/utils/formValidators';
import { BcAttachmentForm, NumberRulesStandardHook, SearchSelect } from 'components';
import { appDict, appEnum } from 'src/dict';
import UserDeptRoleSelect from 'src/page/organization/components/userDeptRoleSelect';
import ResourceClassificationCascader from 'src/page/resource/components/ResourceClassificationCascader';
import { fetchResourcesList } from 'src/api/ytt/resource-domain/resource';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
//
import {
  PlanAssignFromMap,
  PlanAssignToMap,
  MAX_INPUT_LENGTH,
  MAX_TEXTAREA_LENGTH,
} from '../constants';
import {
  ContentProps,
  MaintenanceCreateApiType,
  MaintenanceDetailType,
  IBaseInfoForm,
} from '../index.d';
import { BL_BUSINESS_CODE, BL_BUSINESS_NAME } from '../config';
import { MaintenanceTreeStateParams } from '../../components/maintenanceTree';
import { getStandardWorkTime, setStandardWorkTime } from '../utils';

export const formatDataToApi = (data: IBaseInfoForm, type?: string): MaintenanceCreateApiType => {
  const {
    id,
    code = '',
    assignFrom,
    assignTo,
    bizId,
    isUseAutocode = false,
    classificationId,
    standardWorkTimeH,
    standardWorkTimeM,
    standardWorkTimeS,
    planExecutorList,
    executeIds,
    fileIds,
    ...resData
  } = data;

  return {
    ...resData,
    id: type === appEnum.Common.CRUD.edit ? id : undefined,
    code: isUseAutocode ? '' : code,
    classificationId: !_.isEmpty(classificationId) ? _.last(classificationId) : undefined,
    planExecutorList: _.map(planExecutorList, ({ value, type }) => ({
      bizId: value,
      bizType: type,
    })),
    assignFrom: _.sum(assignFrom),
    assignTo: _.sum(assignTo),
    bizId: !_.isNil(bizId) ? JSON.parse(bizId.value)?.id : undefined,
    standardWorkTime: setStandardWorkTime(standardWorkTimeH, standardWorkTimeM, standardWorkTimeS),
    executeIds: _.map(executeIds, 'value'),
    fileIds: !_.isNil(fileIds) ? _.map(fileIds, _.toString) : undefined,
  };
};

export const formatDataToForm = (data: MaintenanceDetailType): IBaseInfoForm => {
  const {
    name = '',
    businessType = appEnum.Resources.ResourceBusinessType.equipment,
    assignFlag = appEnum.Common.YNB.yes,
    assignFrom = 0,
    assignTo = 0,
    biz,
    bizType = appEnum.Resources.MaintenanceType.maintain,
    resourceTypeTraceList,
    executeType = appEnum.Resources.MaintenanceExecuteType.reportTemplate,
    standardWorkTime,
    planExecutorList,
    scanCodeFlag = appEnum.Common.YNB.no,
    reportTemplateList,
    sopList,
    fileIds,
    ...resData
  } = data;

  const {
    H: standardWorkTimeH,
    M: standardWorkTimeM,
    S: standardWorkTimeS,
  } = getStandardWorkTime(standardWorkTime);

  const isSopExecute = executeType === appEnum.Resources.MaintenanceExecuteType.SOP;

  return {
    ...resData,
    name,
    businessType,
    assignFlag,
    assignFrom: _.filter([...PlanAssignFromMap.keys()], (key) => (assignFrom & key) === key),
    assignTo: _.filter([...PlanAssignToMap.keys()], (key) => (assignTo & key) === key),
    bizType,
    bizId: !_.isNil(biz)
      ? {
          label: biz.name,
          value: JSON.stringify(
            _.pick(biz, ['id', 'level', 'hasChildren', 'code', 'locationName']),
          ),
        }
      : undefined,
    executeType,
    scanCodeFlag,
    classificationId: !_.isEmpty(resourceTypeTraceList)
      ? _.compact(_.map(resourceTypeTraceList, 'id'))
      : undefined,
    planExecutorList: _.map(planExecutorList, ({ name, bizId, bizType }) => ({
      label: name,
      value: bizId,
      type: bizType,
    })),
    standardWorkTimeH,
    standardWorkTimeM,
    standardWorkTimeS,
    executeIds: isSopExecute
      ? _.map(sopList, ({ id, name }) => ({ label: name, value: id }))
      : _.map(reportTemplateList, ({ id, name }) => ({ label: name, value: id })),
    fileIds: !_.isNil(fileIds) ? _.map(fileIds, _.toNumber) : undefined,
  };
};

const ActionContent = (props: ContentProps) => {
  const { form, type, refreshMarker } = props;

  const listCacheData: MaintenanceTreeStateParams = JSON.parse(
    sessionStorage.getItem(BL_BUSINESS_CODE) ?? '{}',
  );

  useEffect(() => {
    initailBiz();
  }, []);

  /**
   * 创建时，需要根据列表页所选资源初始化维保目标
   */
  const initailBiz = () => {
    // 创建时
    const isCreate = type === appEnum.Common.CRUD.create;

    // 所选为资源类型
    const isEquipment = listCacheData?.target?.type === 'Equipment';

    const equipmentId = listCacheData?.target?.id;
    const equipmentType = listCacheData?.businessType;

    if (isCreate && isEquipment && equipmentId) {
      fetchResourcesList({ idList: [_.toNumber(equipmentId)], type: equipmentType }).then((res) => {
        const bizInfo = _.head(res?.data?.list);

        if (!_.isNil(bizInfo)) {
          const bizId = {
            label: bizInfo.name,
            value: JSON.stringify(
              _.pick(bizInfo, ['id', 'level', 'hasChildren', 'code', 'locationName']),
            ),
          };

          form.setFieldsValue({ bizId });
        }
      });
    }
  };

  const validatorWorkTime = () => {
    const { standardWorkTimeH, standardWorkTimeM, standardWorkTimeS } = form.getFieldsValue();

    // 存在一个或多个0且总和是0的时候
    if (
      (standardWorkTimeM ?? 0) + (standardWorkTimeH ?? 0) + (standardWorkTimeS ?? 0) === 0 &&
      (standardWorkTimeM === 0 || standardWorkTimeH === 0 || standardWorkTimeS === 0)
    ) {
      return Promise.reject('标准工时总时长不能为0');
    }

    return Promise.resolve('');
  };

  /**
   * 维保业务对象 change
   * @param value
   */
  const handleBizTypeChange = () => {
    form.setFieldsValue({ bizId: undefined });
  };

  const getBaseInfo = (): DataFormLayoutInfoBlock => {
    const baseInfo = {
      title: '基本信息',
      column: 2,
      items: [
        {
          label: '',
          name: 'id',
          span: 2,
          hidden: true,
          render: () => <Input />,
        },
        ...NumberRulesStandardHook({
          label: `${BL_BUSINESS_NAME}编号`,
          form,
          edit: type === 'edit',
          objectCode: OBJECT_OF_CODE.MaintenanceCase,
          fieldCode: 'code',
          refreshMarker,
          rules: [
            { max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` },
            numberAlphabetSpecialSymbols,
          ],
        }),
        {
          label: `${BL_BUSINESS_NAME}名称`,
          name: 'name',
          rules: [
            { required: true, message: `请输入${BL_BUSINESS_NAME}名称` },
            { max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` },
            { validator: checkTwoSidesTrim },
          ],
          render: () => <Input placeholder={`请输入${BL_BUSINESS_NAME}名称`} />,
        },
        {
          label: '业务类型',
          name: 'businessType',
          initialValue: appEnum.Resources.MaintenanceType.maintain,
          rules: [{ required: true, message: '请选择业务类型' }],
          render: () => (
            <Select placeholder="请选择业务类型" options={appDict.resources.maintenanceType} />
          ),
        },
        {
          label: '维保业务对象',
          name: 'bizType',
          initialValue: listCacheData?.businessType,
          rules: [{ required: true, message: '请选择业务类型' }],
          render: () => (
            <Select
              placeholder="请选择业务类型"
              options={appDict.resources.RESORCE_BUSINESS_TYPE_MAP}
              onChange={handleBizTypeChange}
            />
          ),
        },
        {
          label: '维保目标',
          dependencies: ['bizType'],
          render: () => () => {
            const bizType = form.getFieldValue('bizType');

            return (
              <Form.Item name="bizId" style={{ marginBottom: 0 }}>
                <SearchSelect
                  fetchType="resourceCompleteInfo"
                  params={{
                    type: bizType,
                    statusList: [
                      appEnum.Common.UsageStatus.enabled,
                      appEnum.Common.UsageStatus.disabled,
                    ],
                  }}
                  placeholder="请输入"
                />
              </Form.Item>
            );
          },
        },
        {
          label: '维保目标编号',
          shouldUpdate: true,
          render: () => () => {
            const { value = '{}' } = form.getFieldValue('bizId') ?? {};

            const { code } = JSON.parse(value);

            return <Input placeholder="请选择维保目标" disabled value={code} />;
          },
        },
        {
          label: '维保目标区域',
          shouldUpdate: true,
          render: () => () => {
            const { value = '{}' } = form.getFieldValue('bizId') ?? {};

            const { locationName } = JSON.parse(value);

            return <Input placeholder="请选择维保目标" disabled value={locationName} />;
          },
        },
        {
          label: '维保途径',
          name: 'channelType',
          initialValue: appEnum.Resources.MaintenanceChannel.autonomy,
          render: () => (
            <Select
              placeholder="请选择维保途径"
              options={appDict.resources.maintenanceChannel}
              allowClear
            />
          ),
        },
        {
          label: '维保任务分类',
          name: 'classificationId',
          render: () => (
            <ResourceClassificationCascader
              // resFetchData={resFetchData}
              params={{
                businessType: appEnum.Resources.ResourceBusinessType.maintenance,
                status: appEnum.Common.UsageStatus.enabled,
                cascade: true,
              }}
              showSearch
              placeholder={'维保任务分类'}
              onCreateClick={() => {}}
            />
          ),
        },
        // todo 暂时不上
        // {
        //   label: '资源占用',
        //   name: 'feild',
        //   initialValue: appEnum.Common.YNB.no,
        //   tooltip: '开启占用,维保目标在维保任务的计划时间内,不支持排产和生产',
        //   render: () => <Radio.Group options={appDict.common.ynb} />,
        // },
        {
          label: '扫码确认',
          name: 'scanCodeFlag',
          initialValue: appEnum.Common.YNB.no,
          render: () => <Radio.Group options={appDict.common.ynb} />,
        },
        {
          label: '标准工时',
          render: () => (
            <div>
              <Space>
                <Form.Item
                  name={'standardWorkTimeH'}
                  noStyle
                  rules={[{ validator: validatorWorkTime }]}
                >
                  <InputNumber max={999} min={0} precision={0} />
                </Form.Item>
                <Button type="text" size="small">
                  小时
                </Button>
                <Form.Item name={'standardWorkTimeM'} noStyle>
                  <InputNumber
                    max={59}
                    min={0}
                    precision={0}
                    onChange={() => {
                      form.validateFields(['standardWorkTimeH']);
                    }}
                  />
                </Form.Item>
                <Button type="text" size="small">
                  分钟
                </Button>
                <Form.Item name={'standardWorkTimeS'} noStyle>
                  <InputNumber
                    max={59}
                    min={0}
                    precision={0}
                    onChange={() => {
                      form.validateFields(['standardWorkTimeH']);
                    }}
                  />
                </Form.Item>
                <Button type="text" size="small">
                  秒
                </Button>
              </Space>
            </div>
          ),
        },
        {
          label: '计划执行人',
          name: 'planExecutorList',
          rules: [{ type: 'array', max: 20, message: '计划执行人最多不超过20个' }],
          render: () => <UserDeptRoleSelect isMultiple />,
        },
        {
          label: '允许转派',
          name: 'assignFlag',
          initialValue: appEnum.Common.YNB.yes,
          render: () => <Radio.Group options={appDict.common.ynb} />,
        },
        {
          noStyle: true,
          dependencies: ['assignFlag'],
          render: (formItemConfig: JSX.IntrinsicAttributes & FormItemProps<any>) => () => {
            const assignFlag = form.getFieldValue('assignFlag');

            if (!assignFlag) return null;

            return (
              <Form.Item
                {...formItemConfig}
                label="谁可转派"
                name="assignFrom"
                initialValue={[...PlanAssignFromMap.keys()]}
                rules={[{ required: true, message: '请选择谁可转派' }]}
              >
                <Checkbox.Group options={[...PlanAssignFromMap.values()]} />
              </Form.Item>
            );
          },
        },
        {
          noStyle: true,
          dependencies: ['assignFlag'],
          isFullLine: true,
          render: (formItemConfig: JSX.IntrinsicAttributes & FormItemProps<any>) => () => {
            const assignFlag = form.getFieldValue('assignFlag');

            if (!assignFlag) return null;

            return (
              <Form.Item
                {...formItemConfig}
                label="可转派给"
                name="assignTo"
                initialValue={[...PlanAssignToMap.keys()]}
                rules={[{ required: true, message: '请选择可转派给' }]}
              >
                <Checkbox.Group options={[...PlanAssignToMap.values()]} />
              </Form.Item>
            );
          },
        },
        {
          label: '执行方式',
          name: 'executeType',
          initialValue: appEnum.Resources.MaintenanceExecuteType.reportTemplate,
          rules: [{ required: true, message: '请选择执行方式' }],
          render: () => (
            <Select
              placeholder="请选择执行方式"
              options={appDict.resources.maintenanceExecuteType}
              onChange={() => {
                // 执行方式变动重置 executeIds
                form.setFieldsValue({ executeIds: undefined });
              }}
            />
          ),
        },
        {
          noStyle: true,
          dependencies: ['executeType'],
          render: (formItemConfig: JSX.IntrinsicAttributes & FormItemProps<any>) => () => {
            const executeType = form.getFieldValue('executeType');

            if (executeType === appEnum.Resources.MaintenanceExecuteType.reportTemplate) {
              return (
                <Form.Item
                  {...formItemConfig}
                  label="报告模板"
                  name={'executeIds'}
                  rules={[
                    { required: true, message: '请选择报告模板' },
                    { type: 'array', max: 20, message: '报告模板最多不超过20个' },
                  ]}
                >
                  <SearchSelect
                    fetchType="reportTemplate"
                    placeholder="请选择报告模板"
                    mode="multiple"
                    params={{ category: [appEnum.ReportTemplate.TemplateType.maintenanceReport] }}
                  />
                </Form.Item>
              );
            }

            if (executeType === appEnum.Resources.MaintenanceExecuteType.SOP) {
              return (
                <Form.Item
                  {...formItemConfig}
                  label="SOP方案"
                  name={'executeIds'}
                  rules={[
                    { required: true, message: '请选择SOP方案' },
                    { type: 'array', max: 1, message: 'SOP方案最多不超过1个' },
                  ]}
                >
                  <SearchSelect
                    fetchType="SOP"
                    placeholder="请选择SOP方案"
                    mode="multiple"
                    params={{
                      status: appEnum.Common.UsageStatus.enabled,
                      bizType: appEnum.Sop.BizType.maintenance,
                    }}
                  />
                </Form.Item>
              );
            }

            return null;
          },
        },
        {
          label: '附件',
          name: 'fileIds',
          isFullLine: true,
          render: () => <BcAttachmentForm form={form} />,
        },
        {
          label: '备注',
          name: 'remark',
          isFullLine: true,
          rules: [{ max: MAX_TEXTAREA_LENGTH, message: `不超过${MAX_TEXTAREA_LENGTH}字符` }],
          render: () => <TextArea placeholder="请输入备注" />,
        },
      ],
    };

    if (type === 'edit') {
      baseInfo.items.push({
        label: '编辑原因',
        name: 'reason',
        isFullLine: true,
        rules: [{ max: MAX_TEXTAREA_LENGTH, message: `不超过${MAX_TEXTAREA_LENGTH}字符` }],
        render: () => <TextArea placeholder="请输入编辑原因" />,
      });
    }

    return baseInfo;
  };

  return <DataFormLayout info={[getBaseInfo()]} form={form} footer={false} />;
};

export default ActionContent;
