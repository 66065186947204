import React, { useCallback, useEffect, useState } from 'react';
import { DetailLayout } from 'layout';
import DataPreview from './dataPreview';
import {
  fetchDataSetQueryDataSetDetail,
  FetchDataSetQueryDataSetDetailResponse,
  fetchDataSetDataSetStartStopProcess,
} from 'src/api/ytt/e-report-domain/dataSet';
import { gcTime, gcUrl } from 'src/utils';
import lookup, { appEnum } from 'src/dict';
import { Badge, Spin } from 'antd';
import { avatarDisplay } from 'src/components/avatar/show';
import { DetailTheme } from 'src/dict/customReport/mappings';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import { getCopyUrl, getEditUrl } from '../../navigation';
import authDict from 'src/utils/auth';

type DetailData = FetchDataSetQueryDataSetDetailResponse['data'];

const Detail = (props: { history: any }) => {
  const [data, setData] = useState<DetailData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);

  const refreshData = () => {
    setIsLoading(true);
    const { id } = gcUrl.getParams();

    fetchDataSetQueryDataSetDetail({ dataSetId: id }).then((res) => {
      setData(res.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    refreshData();
  }, []);

  const baseInfo = {
    title: '基本信息',
    items: [
      {
        label: '数据集编号',
        dataIndex: 'code',
      },
      {
        label: '数据集名称',
        dataIndex: 'dataSetName',
      },
      {
        label: '状态',
        dataIndex: 'bizSetStatus',
        render: (text: number) => (
          <Badge
            status={text === appEnum.CustomReport.STATUS.enabled ? 'success' : 'error'}
            text={lookup('customReport.status', text)}
          />
        ),
      },
      {
        label: '业务主题',
        dataIndex: 'bizTheme',
        render: (bizTheme: number) => {
          return lookup('customReport.businessTheme', bizTheme) || '-';
        },
      },
      {
        label: '细分主题',
        dataIndex: 'themeDetail',
        render: (value: number) => {
          return DetailTheme[data?.bizTheme]?.[value] || '-';
        },
      },
      {
        label: '数据集说明',
        dataIndex: 'remark',
      },
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: any) => {
          if (!creator) {
            return '-';
          }
          return avatarDisplay({ ...creator, isUser: true });
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: (date: string) => gcTime.formatLine(date),
      },
      {
        label: '修改人',
        dataIndex: 'modifier',
        render: (modifier: any) => {
          if (!modifier) {
            return '-';
          }
          return avatarDisplay({ ...modifier, isUser: true });
        },
      },
      {
        label: '修改时间',
        dataIndex: 'updatedAt',
        render: (date: string) => gcTime.formatLine(date),
      },
    ],
  };
  const dataInfo = useCallback(
    () => ({
      title: '',
      items: [
        {
          label: '',
          dataIndex: 'previewData',
          render: () => (
            <DataPreview
              fieldInfo={data?.previewFieldInfos || []}
              previewData={data?.previewData || []}
              objectViewData={{
                nodes: data?.objectNodeInfoList,
                edges: data?.objectRelationShipInfoList,
              }}
            />
          ),
        },
      ],
    }),
    [isLoading],
  );

  const baseMenu = useCallback(
    () => [
      {
        key: 'copy',
        icon: <BlIcon type="iconfuzhi" />,
        auth: authDict.dataset_add,
        onClick: () => props.history.push(getCopyUrl(data?.id)),
        title: '复制',
      },
      {
        key: 'status',
        auth: authDict.dataset_enable_disable,
        onClick: () => {
          fetchDataSetDataSetStartStopProcess({
            dataSetStatus:
              data?.bizSetStatus === appEnum.CustomReport.STATUS.enabled
                ? appEnum.CustomReport.STATUS.disabled
                : appEnum.CustomReport.STATUS.enabled,
            dataSetIdList: [data?.id],
          }).then(() => {
            return refreshData();
          });
        },
        title: lookup('customReport.changeStatusAction', data?.bizSetStatus),
      },
      {
        key: 'record',
        auth: authDict.dataset_operrecord,
        onClick: () => {
          setVisibleLog(true);
        },
        title: '操作记录',
      },
      {
        key: 'edit',
        auth: authDict.dataset_edit,
        icon: <BlIcon type="iconbianji" />,
        onClick: () => props.history.push(getEditUrl(data?.id)),
        title: '编辑',
      },
    ],
    [data],
  );

  return isLoading ? (
    <div style={{ width: '100%' }}>
      <Spin style={{ width: '100%' }} />
    </div>
  ) : (
    <>
      <DetailLayout
        title={'数据集详情'}
        info={[baseInfo, dataInfo()]}
        dataSource={data}
        baseMenu={baseMenu()}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={data?.id}
          objectCode={'Dataset'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default Detail;
