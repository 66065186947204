import { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import { BlTable, LinkTooltip } from 'src/components';
import { DetailLayout } from 'src/layout';
import lookup from 'src/dict';
import { replaceSign } from 'src/utils/constants';
import {
  fetchPlanOrderLaunchLogDetails,
  FetchPlanOrderLaunchLogDetailsResponse,
} from 'src/api/ytt/plo-domain';
import { AvatarDisplay } from 'src/components/avatar/show';

import { getResult, getStatus } from './utils';
import { toDetailProductionOrder } from 'src/page/productionPlanning/productionOrder/navigation';
import authDict from 'src/utils/auth';

interface Props extends RouteChildrenProps<{ id: string }> {}

const ResultMap = new Map([
  [1, '投放成功'],
  [2, '投放失败'],
]);

const OrderStatusMap = new Map([
  [0, '草稿'],
  [2, '新建'],
]);

const LogDetail = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState<FetchPlanOrderLaunchLogDetailsResponse['data']>();
  const [pagination, setPagination] = useState({ page: 1, size: 10 });

  const { match } = props;

  useEffect(() => {
    fetchAndSetData(pagination);
  }, []);

  const fetchAndSetData = (pagination: { page: number; size: number }) => {
    const requestId = match?.params?.id ? _.toNumber(match?.params?.id) : undefined;

    fetchPlanOrderLaunchLogDetails({ requestId, ...pagination })
      .then((res) => {
        setDataSource(res?.data);
        setPagination(pagination);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const baseInfo = [
    {
      title: '',
      items: [
        {
          label: '操作时间',
          isFullLine: true,
          dataIndex: 'createdAt',
          render: (text: any) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : replaceSign),
        },
        {
          label: '操作用户',
          isFullLine: true,
          dataIndex: 'creator',
          render: (info: any) => <AvatarDisplay {...info} isUser />,
        },
        {
          label: '操作类型',
          dataIndex: 'operateType',
          isFullLine: true,
          render: (text: any) => lookup('planned.OperateTypeDisplayMap', text),
        },
        {
          label: '操作结果',
          isFullLine: true,
          dataIndex: 'failNum',
          render: (text: any, record: any) => getStatus(record ?? {}),
        },
        {
          label: '操作详情',
          isFullLine: true,
          dataIndex: 'successCount',
          render: (text: any, record: any) => getResult(record ?? {}),
        },
        {
          label: '',
          isFullLine: true,
          dataIndex: 'productRate',
          render: () => {
            return (
              <BlTable
                dataSource={dataSource?.details}
                onChange={(pagination) => {
                  fetchAndSetData({
                    page: pagination.current ?? 1,
                    size: pagination.pageSize ?? 10,
                  });
                }}
                columns={[
                  {
                    title: '操作结果',
                    width: 100,
                    dataIndex: 'status',
                    render: (status: any) => {
                      return ResultMap.get(status);
                    },
                  },
                  {
                    title: '计划订单编号',
                    width: 150,
                    dataIndex: 'planOrderCode',
                  },
                  {
                    title: '投放类型',
                    width: 150,
                    dataIndex: 'launchType',
                    render: (text: any) => lookup('planned.LaunchTypeEnumDisplayMap', text),
                  },
                  {
                    title: '单据编号',
                    width: 150,
                    dataIndex: 'workOrderCode',
                    render: (workOrderCode: any, record) => {
                      const { workOrderId } = record || '';

                      if (workOrderId) {
                        return (
                          <LinkTooltip
                            to={toDetailProductionOrder(workOrderId)}
                            text={workOrderCode}
                            width={150}
                            auth={authDict.productionorder_detail}
                          />
                        );
                      }
                      return workOrderCode;
                    },
                  },
                  {
                    title: '单据状态',
                    width: 150,
                    dataIndex: 'orderStatus',
                    render: (status: any) => OrderStatusMap.get(status),
                  },
                  { title: '失败原因', width: 200, dataIndex: 'failReason' },
                ]}
                pagination={{
                  pageSize: pagination.size,
                  current: pagination.page,
                }}
              />
            );
          },
        },
      ],
    },
  ];

  return (
    <DetailLayout loading={loading} title="投放日志详情" info={baseInfo} dataSource={dataSource} />
  );
};

export default LogDetail;
