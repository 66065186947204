import { useEffect, useState } from 'react';
import { Form, Input, Row, message, Checkbox, Select } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { NumberRulesStandardHook } from 'src/components';
import _, { get, isEmpty } from 'lodash';
import { UsageStatus } from 'src/dict/common';
import { SearchSelect } from 'components';
import InBoundMaterial from '../components/inBoundMaterial';
import { appEnum, appDict } from 'src/dict';
import {
  fetchInboundOrderCreate,
  fetchInboundOrderUpdate,
  fetchInboundOrderDetail,
} from 'src/api/ytt/inventory-domain/boundOrder';
import { checkTwoSidesTrim, validateBlInputText } from 'src/utils/formValidators';
import qs from 'qs';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { IN_SOURCE_TYPE } from 'src/dict/warehouse';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import {
  getFormCustomFieldItem,
  getObjectCustomFields,
  ICustomFieldItem,
  ICustomFields,
  initCustomFieldsInData,
  injectCustomFieldInfos,
  useCustomFieldCombinedData,
} from 'src/components/customField';

interface Props extends RouteComponentProps {}
interface Params {
  id?: number;
}

const { StatusEnum } = appEnum.Bound;

export default function InBoundOrderCreate(props: Props) {
  const [form] = Form.useForm();
  const { history, match } = props;
  const params: Params = match.params;
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  const id = Number(params.id);
  const isEditMode = 'id' in params;
  const pageTitle = isEditMode ? '编辑' : '创建';
  const [keepOn, setKeepOn] = useState(false);
  const [detail, setDetail] = useState<any>(null);
  const [refreshMarker, setRefreshMaker] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.inboundOrder);

  const { inBoundInfo } = useSelector((state: RootState) => state?.supplyChain, shallowEqual);

  /**
   * 判断在编辑模式下，某些来源类型是否可控制某些字段不可编辑
   * @param sourceType 来源类型
   * @returns Boolean
   */
  const editDisabledBySourceType = (sourceType: IN_SOURCE_TYPE) => {
    const disabledTypes = [IN_SOURCE_TYPE.receipt, IN_SOURCE_TYPE.saleReturnOrder];

    return isEditMode && disabledTypes.includes(sourceType);
  };

  const editDisabledToSupplier = () => {
    if (isEditMode) {
      return !(
        detail?.bizStatus === StatusEnum.xinjian &&
        detail?.sourceType === IN_SOURCE_TYPE.emptySource
      );
    }
    return !!query.source;
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,
    title: '基本信息',
    items: [
      ...NumberRulesStandardHook({
        label: '入库单编号',
        form,
        edit: isEditMode,
        objectCode: 'InboundOrder',
        fieldCode: 'code',
        refreshMarker,
        rules: [
          { max: 255, message: '不超过255字符' },
          { validator: checkTwoSidesTrim },
          { validator: validateBlInputText('入库单编号') },
        ],
      }),
      {
        label: '业务类型:',
        name: 'bizType',
        validateFirst: true,
        rules: [{ required: true, message: '请选择业务类型' }],
        render: () => (
          <Select
            options={appDict.bound.InBoundType}
            disabled={isEditMode || Boolean(query.source)}
            placeholder="请选择"
          />
        ),
      },
      {
        label: '仓库:',
        name: 'wareHouseId',
        rules: [{ required: true, message: '请选择仓库' }],
        render: () => (
          <SearchSelect
            disabled={
              editDisabledBySourceType(detail?.sourceType) ||
              (detail && detail.bizStatus !== StatusEnum.xinjian)
            }
            labelInValue
            params={{ enableFlag: UsageStatus.enabled }}
            fetchType="warehouse"
            onChange={() => {
              const { items } = form.getFieldsValue(['items']);

              form.setFieldsValue({
                items: items?.map((o: any) => ({ ...o, storageLocationId: null })),
              });
            }}
          />
        ),
      },
      {
        label: '供应商:',
        name: 'supplierId',
        render: () => (
          <SearchSelect
            placeholder="请选择"
            labelInValue
            fetchType="supplier"
            disabled={editDisabledToSupplier()}
            params={{ status: [UsageStatus.enabled] }}
          />
        ),
      },
      {
        label: '来源类型:',
        name: 'sourceType',
        render: () => <Select options={appDict.warehouse.inSourceTypeEnum} disabled />,
      },
      {
        label: '备注:',
        name: 'remark',
        rules: [{ max: 1000, message: '不超过1000个字符' }],
        render: () => <Input.TextArea placeholder="请输入" />,
      },
    ],
  };
  const materialInfo: DataFormLayoutInfoBlock = {
    title: '物料列表',
    items: [
      {
        label: '',
        isFullLine: true,
        render: () => (
          <InBoundMaterial
            source={query.source}
            name="items"
            form={form}
            items={detail?.items}
            editDisabled={editDisabledBySourceType(detail?.sourceType)}
            customFields={customFields}
          />
        ),
      },
    ],
  };

  const getDetail = async () => {
    const { data } = await fetchInboundOrderDetail({ inboundOrderId: id });

    setDetail(data);

    const afterFormatInitialValue = {
      ...data,
      wareHouseId: {
        label: data?.wareHouse?.name,
        value: data?.wareHouse?.id,
      },
      supplierId: {
        label: data?.supplier?.name,
        value: data?.supplier?.id,
      },
      items: data?.items?.map((row: any) => {
        const { material = {}, storageLocation = {}, unit = {}, batchNo = {}, ...rest } = row;

        return {
          ...rest,
          materialId: {
            label: material.baseInfo?.code,
            value: JSON.stringify(material),
            key: JSON.stringify(material),
          },
          storageLocationId: {
            label: storageLocation?.storage?.name,
            value: storageLocation?.storage?.id,
          },
          unitId: {
            label: unit.name,
            value: unit.id,
          },
          batchNo: {
            label: batchNo?.batchNo,
            value: batchNo?.batchNo,
          },
        };
      }),
    };

    const afterFormatCustomFieldsValue = initCustomFieldsInData(afterFormatInitialValue);

    form?.setFieldsValue(afterFormatCustomFieldsValue);
  };

  const onCancel = () => {
    history.goBack();
  };
  const getMaterialInfo = (material: any) => {
    const value = JSON.parse(material?.value ?? '{}');

    return value;
  };

  const formatCustomFieldsInData = ({
    data,
    customFields,
  }: {
    data: any;
    customFields?: ICustomFields;
  }) => {
    if (_.isEmpty(data) || _.isNil(customFields)) return data;

    // 格式化 数据
    const formatFn = (data: any): any => {
      const newCustomFields: ICustomFieldItem[] = [];

      _.forIn(data, (value, key) => {
        const [objectCode, fieldId] = key.split('_');
        const { customFields: objectCustomFields } = getObjectCustomFields({
          customFields,
          objectCode,
        });
        const copyFieldItem = _.cloneDeep(
          _.find(objectCustomFields, ['fieldId', _.toNumber(fieldId)]),
        );

        if (!_.isNil(copyFieldItem)) {
          // 字段格式化 接口方法
          const { formatToApi } = getFormCustomFieldItem(copyFieldItem) ?? {};

          _.set(
            copyFieldItem,
            'fieldValue',
            _.isFunction(formatToApi) ? formatToApi(value) : value,
          );

          newCustomFields.push(
            _.pick(copyFieldItem, ['fieldCode', 'fieldValue']) as ICustomFieldItem,
          );
        }
      });

      return newCustomFields;
    };

    // 递归 数据
    const recursiveFn = (data: any): any => {
      if (_.isArray(data)) {
        return _.map(data, recursiveFn);
      }

      if (_.isObject(data)) {
        _.forIn(data, (value, key) => {
          if (key === 'customFields') {
            _.set(data, 'customFields', formatFn(value));
          } else {
            _.set(data, key, recursiveFn(value));
          }
        });

        return data;
      }

      return data;
    };

    return recursiveFn(data);
  };

  const formatItemsData = (items: any) => {
    const initItems = get(detail, 'items');

    if (isEmpty(items)) return [];

    let lastLineNo = Number(get(initItems, [initItems?.length - 1, 'lineNo'], 0));

    return items?.map((row: any) => {
      return {
        ...row,
        materialId: getMaterialInfo(row.materialId)?.baseInfo?.id,
        storageLocationId: row.storageLocationId?.value,
        unitId: row.unitId?.value,
        batchNo: row.batchNo?.value,
        lineNo: typeof row?.lineNo === 'string' ? row?.lineNo : ++lastLineNo,
      };
    });
  };

  const onFinish = async () => {
    const values = await form.validateFields();
    const _options = {
      ..._.omit(values, ['code_isUseRules']),
      wareHouseId: values.wareHouseId?.value,
      supplierId: values.supplierId?.value,
      items: formatItemsData(values.items),
    } as any;

    const options = formatCustomFieldsInData({ data: _options, customFields });

    setLoading(true);
    (isEditMode
      ? fetchInboundOrderUpdate({ ...options, id, version: detail.version })
      : fetchInboundOrderCreate(options)
    ) // TODO 替换供应链的接口
      .then((res) => {
        if (res && res.code === 200) {
          message.success('操作成功');
          if (keepOn) {
            setRefreshMaker(Math.random());
            form.resetFields();
            return;
          }
          onCancel();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getDetail();
    }

    if (query.source) {
      let sourceType: number | null = null;

      switch (query.source) {
        // 销售退货单新建入库单
        case 'salesReturnOrder':
          sourceType = IN_SOURCE_TYPE.saleReturnOrder;
          break;
        // 采购收货单新建入库单
        case 'delivery':
          sourceType = IN_SOURCE_TYPE.receipt;
          break;
        default:
          break;
      }
      form?.setFieldsValue({
        ...inBoundInfo,
        sourceType,
      });
    } else {
      form?.setFieldsValue({
        sourceType: IN_SOURCE_TYPE.emptySource,
      });
    }
  }, [id, query.source]);

  return (
    <DataFormLayout
      form={form}
      confirmLoading={loading}
      title={`${pageTitle}入库单`}
      info={[
        baseInfo,
        injectCustomFieldInfos({
          customFields,
          type: 'form',
          formConfig: { form },
        }),
        materialInfo,
      ]}
      onCancel={onCancel}
      onFinish={onFinish}
      extra={
        !isEditMode && (
          <Checkbox
            onChange={() => {
              setKeepOn(!keepOn);
            }}
            defaultChecked={keepOn}
          >
            连续新建
          </Checkbox>
        )
      }
    />
  );
}
