import { ColumnProps } from 'antd/es/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { BlTable, TextToolTip } from '@blacklake-web/component';
import _, { take, slice, identity } from 'lodash';
import { Tooltip } from 'antd';

import { fieldLabels } from '../../constants';
import {
  goToMaterialDetail,
  goToProcessDetail,
  goToTranferOrder,
  goToWorkCenterDetail,
  goToWorkOrderDetail,
} from '../../utils';
import { AvatarDisplay } from 'src/components/avatar/show';
import { formatTimeForRender } from 'src/utils/formatters';
import authDict, { hasAuth } from 'src/utils/auth';
import { MaterialEntity } from 'src/entity';
import lookup from 'src/dict';
import { PickOrderType } from 'src/dict/storeRequisition';
import { TagList } from 'src/components';
import { replaceSign } from 'src/utils/constants';

interface CoproductTabProps {
  //   dataSource?: any;
  materials: any;
  type?: PickOrderType;
}

/** 表单字段名 */

const StoreRequisitionDetailTable = ({
  materials,
  type = PickOrderType.product,
}: CoproductTabProps) => {
  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    const baseColumns: ColumnProps<any & FormListFieldData>[] = _.compact([
      {
        title: fieldLabels.lineNumber,
        dataIndex: ['line'],
        width: 120,
      },
      {
        title: fieldLabels.materialCode,
        dataIndex: ['material', 'baseInfo', 'code'],
        width: 130,
        render: (text, field) => {
          return hasAuth(authDict.material_detail) ? (
            <a onClick={() => goToMaterialDetail(field?.material?.baseInfo?.id)}>
              <TextToolTip text={text} width={130} />
            </a>
          ) : (
            text
          );
        },
      },
      {
        title: fieldLabels.materialName,
        dataIndex: ['material', 'baseInfo', 'name'],
        width: 160,
      },
      {
        title: fieldLabels.materialCategory,
        dataIndex: ['material', 'category', 'name'],
        width: 110,
      },
      {
        title: fieldLabels.specification,
        dataIndex: ['material', 'baseInfo', 'specification'],
        width: 110,
      },
      {
        title: fieldLabels.materialProperty,
        dataIndex: ['material', 'attribute'],
        width: 150,
        render: (text: MaterialEntity.MaterialEntity['attribute']) => {
          if (_.isEmpty(text)) return replaceSign;

          return (
            <TagList
              dataSource={_.map(text, ({ id, name, attributeItem }) => ({
                label: `${name}:${attributeItem?.content}`,
                value: id,
              }))}
            />
          );
        },
      },
      {
        title: fieldLabels.unit,
        dataIndex: ['requestPickAmount', 'unitName'],
        width: 100,
      },
      {
        title: fieldLabels.requireCount,
        dataIndex: ['requestPickAmount', 'amountDisplay'],
        width: 120,
      },
      {
        title: fieldLabels.allocateNumber,
        dataIndex: ['transferIssuedAmount', 'amountDisplay'],
        width: 120,
      },
      {
        title: fieldLabels.issuedCount,
        dataIndex: ['pickOrderIssuedAmount', 'amountDisplay'],
        width: 120,
      },
      {
        title: fieldLabels.receivedCount,
        dataIndex: ['receivePickAmount', 'amountDisplay'],
        width: 120,
      },
      {
        title: fieldLabels.dispatchWarehouse,
        dataIndex: ['sourceWarehouseId'],
        render: (sourceWarehouse: { value: { id: number; name: string; code: string } }) => {
          return sourceWarehouse
            ? `${sourceWarehouse?.value?.code}|${sourceWarehouse?.value?.name}`
            : replaceSign;
        },
        width: 300,
      },
      {
        title: fieldLabels.acceptWarehouse,
        dataIndex: ['targetWarehouseId'],
        render: (targetWarehouse: { value: { id: number; name: string; code: string } }) => {
          return targetWarehouse
            ? `${targetWarehouse?.value?.code}|${targetWarehouse?.value?.name}`
            : replaceSign;
        },
        width: 300,
      },
      type === PickOrderType.product && {
        title: fieldLabels.requireTime,
        dataIndex: ['requirementTime'],
        width: 180,
        render: (requirementTime) => formatTimeForRender(requirementTime),
      },
      type === PickOrderType.product && {
        title: fieldLabels.supplier,
        dataIndex: ['supplierList'],
        width: 120,
        render: (supplierList) =>
          supplierList
            ?.map((supplier: { code: string; id: number; name: string }) => supplier.name)
            .join(','),
      },
      {
        title: fieldLabels.batchNumber,
        dataIndex: ['batchNo', 'label'],
        width: 130,
      },
      type === PickOrderType.product && {
        title: fieldLabels.seq,
        dataIndex: ['seq'],
        width: 160,
      },
      type === PickOrderType.product && {
        title: fieldLabels.alternativeMaterial,
        dataIndex: ['alternativeMaterialFlag'],
        width: 160,
        render: (text, field) => lookup('common.yn', text),
      },
      type === PickOrderType.product && {
        title: fieldLabels.processNumber,
        dataIndex: ['inputProcessCode'],
        width: 160,
      },
      type === PickOrderType.product && {
        title: fieldLabels.processName,
        dataIndex: ['inputProcessName'],
        width: 160,
        render: (processName, field) => {
          if (!_.isNil(processName)) {
            return hasAuth(authDict.process_detail) ? (
              <a
                onClick={() => {
                  goToProcessDetail(field?.inputProcessId);
                }}
              >
                <TextToolTip text={processName} width={160} />
              </a>
            ) : (
              processName
            );
          }
          return undefined;
        },
      },
      type === PickOrderType.product && {
        title: fieldLabels.workCenter,
        dataIndex: ['workCenter', 'name'],
        width: 160,
        render: (workCenterName, field) => {
          if (!_.isNil(workCenterName)) {
            return hasAuth(authDict.workcenter_detail) ? (
              <a
                onClick={() => {
                  goToWorkCenterDetail(field?.workCenter?.id);
                }}
              >
                <TextToolTip text={workCenterName} width={160} />
              </a>
            ) : (
              workCenterName
            );
          }
          return undefined;
        },
      },
      type === PickOrderType.product && {
        title: fieldLabels.productionOrderCode,
        dataIndex: ['workOrderCode'],
        width: 160,
        render: (workOrderCode, field) => {
          if (!_.isNil(workOrderCode)) {
            return hasAuth(authDict.productionorder_detail) ? (
              <a
                onClick={() => {
                  goToWorkOrderDetail(field?.workOrderId);
                }}
              >
                <TextToolTip text={workOrderCode} width={160} />
              </a>
            ) : (
              workOrderCode
            );
          }
          return undefined;
        },
      },
      type === PickOrderType.product && {
        title: fieldLabels.productionDepartment,
        dataIndex: ['productionDepartment'],
        width: 140,
        render: (productionDepartment) => {
          return productionDepartment ? (
            <AvatarDisplay
              isUser={false}
              isShowTag={false}
              id={productionDepartment?.id}
              name={productionDepartment?.name}
              maxWidth={120}
            />
          ) : undefined;
        },
      },
      type === PickOrderType.product && {
        title: fieldLabels.mainOutputBatchNumber,
        dataIndex: 'mainOutputBatchNumber',
        width: 160,
      },
      {
        title: fieldLabels.lineRemark,
        dataIndex: ['remark'],
        width: 180,
      },
      {
        title: fieldLabels.relatedAllocateOrder,
        dataIndex: 'transferOrderVO',
        width: 180,
        render: (transferOrders) => {
          if (transferOrders) {
            const displayOrders: { code?: string; id?: number }[] = take(transferOrders, 2);
            const otherOrders: { code?: string; id?: number }[] = slice(transferOrders, 2);

            if (otherOrders && otherOrders.length > 0) {
              return (
                <>
                  {displayOrders.map((transferOrder) => (
                    <span>
                      {hasAuth(authDict.transferorder_detail) ? (
                        <a
                          onClick={() => {
                            goToTranferOrder(transferOrder.id!);
                          }}
                        >
                          {/* TODO 整体做缩略，否则会溢出 */}
                          <TextToolTip text={transferOrder.code} length={20} />
                        </a>
                      ) : (
                        transferOrder.code
                      )}
                    </span>
                  ))}
                  <Tooltip title={otherOrders.map((order) => order.code).join(';')}>...</Tooltip>
                </>
              );
            }
            return displayOrders.map((transferOrder) => (
              <span>
                {hasAuth(authDict.transferorder_detail) ? (
                  <a
                    onClick={() => {
                      goToTranferOrder(transferOrder.id!);
                    }}
                  >
                    {/* TODO 整体做缩略，否则会溢出 */}
                    <TextToolTip text={transferOrder.code} length={20} />
                  </a>
                ) : (
                  transferOrder.code
                )}
              </span>
            ));
          }
          return undefined;
        },
      },
    ]);

    return baseColumns;
  };

  return (
    <div style={{ paddingBottom: 20 }}>
      <BlTable
        scroll={{ x: 'max-content' }}
        dataSource={materials}
        columns={getColumns()}
        rowKey="id"
        sticky
        resizableCol
        useColConfig
        tableConfigKey={'StoreRequisitionDetailTable'}
      />
    </div>
  );
};

export default StoreRequisitionDetailTable;
