import { Form, Input, Select, FormInstance } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { NamePath } from 'antd/lib/form/interface';
import { ColumnProps } from 'antd/lib/table';
import { BlSortFormList, SearchSelect, Tooltip, MaterialSelect, TagList } from 'src/components';
import { UsageStatus } from 'src/dict/common';
import { getMaterialUnitInfo, getMaterialUnits, getMaterialAttrs } from 'src/entity/material';
import _, { isEqual } from 'lodash';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import { TextControl } from 'src/components/text';
import { inBoundItemEnum } from 'src/dict/bound';
import { injectCustomFieldColumns } from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import BatchSearchSelect from '../../batchManagement/components/batchSearchSelect';
import React from 'react';

interface Props {
  form: FormInstance;
  source?: any;
  name: string;
  items: any[];
  editDisabled?: boolean;
  customFields?: any;
}

const width = '100%';

function SampleList(props: Props) {
  const { form, name, source, editDisabled, items, customFields } = props;
  const getMaterialInfo = (namepath: NamePath) => {
    const value = JSON.parse(form.getFieldValue(namepath)?.value ?? '{}');

    return value;
  };
  /**
   * 判断物料行的状态，如果状态是已执行，物料不能编辑
   * @param bizStatus 状态
   * @returns Boolean
   */
  const executingFn = (bizStatus: number) => {
    return typeof bizStatus !== 'undefined' && bizStatus !== inBoundItemEnum.TO_EXECUTE;
  };

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    // 字段项相同属性
    const getOptions = (field: any, fieldName: string) => ({
      fieldKey: [field.fieldKey, fieldName],
      name: [field.name, fieldName],
      validateFirst: true,
      validateTrigger: ['onChange', 'onBlur'],
      style: { marginBottom: '0' },
    });

    return _.compact([
      {
        dataIndex: 'lineNo',
        title: <div>行号</div>,
        width: 80,
        render: (text: string, field: any) => (
          <div key={field.name}>
            {field.name + 1}
            <Form.Item {...getOptions(field, 'lineNo')} initialValue={field.name + 1} hidden>
              <Input />
            </Form.Item>
          </div>
        ),
      },
      {
        dataIndex: 'materialId',
        title: '物料',
        width: 140,
        render: (text: string, field: any) => {
          const bizStatus = items?.[field.key]?.bizStatus;

          return (
            <div key={field.key}>
              <Form.Item
                {...getOptions(field, 'materialId')}
                rules={[{ required: true, message: '请选择物料' }]}
              >
                <MaterialSelect
                  disabled={editDisabled || executingFn(bizStatus)}
                  params={{ enableFlag: UsageStatus.enabled }}
                />
              </Form.Item>
            </div>
          );
        },
      },
      {
        dataIndex: 'materialName',
        title: '物料名称',
        width: 160,
        render: (text: string, field: any) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() =>
                _.get(getMaterialInfo([name, field.name, 'materialId']), 'baseInfo.name') || '-'
              }
            </Form.Item>
          );
        },
      },
      {
        dataIndex: 'materialName',
        title: '物料属性',
        width: 160,
        render: (text: string, field: any) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() => (
                <TagList
                  dataSource={getMaterialAttrs(getMaterialInfo([name, field.name, 'materialId']))}
                />
              )}
            </Form.Item>
          );
        },
      },
      {
        dataIndex: 'materialName',
        title: '物料规格',
        width: 160,
        render: (text: string, field: any) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() =>
                _.get(
                  getMaterialInfo([name, field.name, 'materialId']),
                  'baseInfo.specification',
                ) || '-'
              }
            </Form.Item>
          );
        },
      },
      {
        dataIndex: 'storageLocationId',
        title: '入库位置',
        width: 160,
        render: (text: string, field: any) => {
          return (
            <Form.Item dependencies={[['wareHouseId']]} style={{ marginBottom: 0 }}>
              {() => {
                const wareHouseId = form.getFieldValue('wareHouseId');

                return (
                  <Form.Item
                    {...getOptions(field, 'storageLocationId')}
                    // rules={[{ required: true, message: '请选择入库位置' }]}
                  >
                    <SearchSelect
                      disabled={!wareHouseId?.value}
                      labelInValue
                      params={{
                        storageId: wareHouseId?.value,
                        storageType: 0,
                        enableFlag: UsageStatus.enabled,
                      }}
                      fetchType="storageLocation"
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        dataIndex: 'planAmount',
        title: '应收数量',
        width: 160,
        render: (text: string, field: any) => (
          <Form.Item
            dependencies={[
              [name, field.name, 'unitId'],
              [name, field.name, 'materialId'],
            ]}
            style={{ marginBottom: 0 }}
          >
            {() => {
              const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
                getMaterialInfo([name, field.name, 'materialId']),
                form.getFieldValue([name, field.name, 'unitId'])?.value,
              );
              const amount = items?.[field.key]?.doneAmount;
              const doneAmount = Number(amount) || 0;
              const minAllowEqual = Boolean(doneAmount);

              return (
                <Form.Item
                  {...getOptions(field, 'planAmount')}
                  rules={_.compact([
                    { required: true, message: '请填写' },
                    {
                      validator: numberMinMaxCheck({
                        max: 10000000,
                        min: doneAmount,
                        minAllowEqual,
                      }),
                    },
                    enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
                  ])}
                >
                  <Input disabled={editDisabled} style={{ width }} placeholder="请输入" />
                </Form.Item>
              );
            }}
          </Form.Item>
        ),
      },
      {
        dataIndex: 'unitId',
        title: '单位',
        width: 140,
        render: (text: string, field: any) => {
          const bizStatus = items?.[field.key]?.bizStatus;

          return (
            <Form.Item
              dependencies={[[name, field.name, 'materialId']]}
              style={{ marginBottom: 0 }}
            >
              {() => {
                return (
                  <Form.Item
                    {...getOptions(field, 'unitId')}
                    rules={[{ required: true, message: '请选择单位' }]}
                  >
                    <Select
                      placeholder={'请选择'}
                      disabled={editDisabled || executingFn(bizStatus)}
                      options={getMaterialUnits(getMaterialInfo([name, field.name, 'materialId']))}
                      labelInValue
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        dataIndex: 'batchNo',
        title: '批次号',
        width: 160,
        render: (text: string, field: any) => (
          <Form.Item dependencies={[[name, field.name, 'materialId']]} style={{ marginBottom: 0 }}>
            {() => {
              const material = getMaterialInfo([name, field.name, 'materialId']);

              const disabled = !material?.baseInfo?.id || !material.batchManagementEnable;

              return (
                <Form.Item {...getOptions(field, 'batchNo')}>
                  <BatchSearchSelect
                    labelInValue
                    disabled={disabled}
                    params={{ materialIds: [material?.baseInfo?.id] }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        ),
      },
      {
        dataIndex: 'remark',
        title: '备注',
        width: 160,
        render: (text: string, field: any) => (
          <div key={field.key}>
            <Form.Item {...getOptions(field, 'remark')}>
              <Input style={{ width }} placeholder="请输入" />
            </Form.Item>
          </div>
        ),
      },
      source && {
        dataIndex: 'entityCode',
        title: source === 'delivery' ? '收货单编号' : '销售退货单编号',
        width: 160,
        render: (text: string, field: any) => (
          <div key={field.name}>
            <Form.Item {...getOptions(field, 'entityCode')}>
              <TextControl
                format={(value) => <Tooltip width={100} text={String(value || '-')} />}
              />
            </Form.Item>
          </div>
        ),
      },
      source && {
        dataIndex: 'seqNum',
        title: source === 'delivery' ? '收货单行号' : '销售退货单行号',
        width: 160,
        render: (text: string, field: any) => (
          <div key={field.name}>
            <Form.Item {...getOptions(field, 'seqNum')}>
              <TextControl />
            </Form.Item>
          </div>
        ),
      },
    ]);
  };

  return (
    <BlSortFormList
      isNeedAdd={!editDisabled}
      isNeedDelete={!editDisabled}
      isNeedDrag={false}
      fixedRowFn={(field, index) => executingFn(items?.[field.key]?.bizStatus)}
      buttonText="添加物料行"
      form={form}
      name={name}
      renderColumns={() =>
        injectCustomFieldColumns({
          columns: getColumns(), // 原本的列
          customFields, // 自定义字段信息
          objectCode: OBJECT_OF_CODE.inboundOrderItem, // 从对象code
          type: 'form', // 使用类型
          formConfig: { form, formName: name },
        })
      }
    />
  );
}

export default React.memo(SampleList, isEqual);
