export const BASE_URL = '/systemConfig/noticeTemplate';

export const toDetail = (id: number) => {
  return `${BASE_URL}/${id}/detail`;
};
export const toEdit = (id: number) => {
  return `${BASE_URL}/${id}/edit`;
};
export const toCreate = () => {
  return `${BASE_URL}/create`;
};

export const toList = () => {
  return `${BASE_URL}`;
};
