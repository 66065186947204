import React from 'react';
import { BlTable } from 'src/components';
import { replaceSign } from 'src/utils/constants';
import { ShiftInfoDetailVOS } from '../interface';
import { formatPeriodTime, formatTime } from '../utils';

interface TableProps {
  dataSource: ShiftInfoDetailVOS[];
}

const BaseTable = (props: TableProps) => {
  const { dataSource } = props;

  const dataColumns = [
    {
      title: '序号',
      width: 80,
      dataIndex: 'sequenceNumber',
      render: (sequenceNumber: number) => {
        return sequenceNumber;
      },
    },
    {
      title: '开始时间',
      width: 150,
      dataIndex: 'startTime',
      render: (startTime: number) => {
        return formatPeriodTime(startTime) ?? replaceSign;
      },
    },
    {
      title: '结束时间',
      width: 150,
      dataIndex: 'endTime',
      render: (endTime: number) => {
        return formatPeriodTime(endTime) ?? replaceSign;
      },
    },
    {
      title: '时长',
      width: 150,
      dataIndex: 'duration',
      render: (duration: number) => {
        return formatTime(duration) ?? replaceSign;
      },
    },
  ];

  return (
    <>
      <BlTable
        columns={dataColumns}
        dataSource={dataSource}
        pagination={false}
        rowKey={(record) => {
          const { id } = record;

          return id;
        }}
        style={{ width: '100%' }}
      />
    </>
  );
};

export default BaseTable;
