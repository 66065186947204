import { Checkbox, Form, Input, message, Radio, Select } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import _ from 'lodash';
import { checkTwoSidesTrim, textValidator2 } from 'src/utils/formValidators';
import { YN } from 'src/dict/common';
import { yn } from 'src/dict/common/mappings';
import DepartmentSelect from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { RouteComponentProps } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AlternativeMaterialFormTable from 'src/page/knowledgeManagement/engineerData/alternativePlan/components/formTable/AlternativeMaterialFormTable';
import OriginalMaterialFormTable from 'src/page/knowledgeManagement/engineerData/alternativePlan/components/formTable/OriginalAlternativeMaterialFormTable';
import {
  fetchAltCreate,
  fetchAltGet,
  fetchAltUpdate,
} from 'src/api/ytt/med-domain/alternative-plan';
import SuitedBomFormTable from 'src/page/knowledgeManagement/engineerData/alternativePlan/components/formTable/SuitedBomFormTable';
import {
  DELAY_GET_LIST_MS_GAP,
  handleFormDataBeforeSubmit,
  handleResponseToFormData,
  handleResponseToSuitedBomFormData,
  pauseSomeTime,
} from 'src/page/knowledgeManagement/engineerData/alternativePlan/helper/utils';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { SubstitutionStrategyEnum } from 'src/dict/alternativePlan';
import { fetchBomGet } from 'src/api/ytt/med-domain/bom';
import {
  ALTERNATIVE_MATERIALS,
  ORIGINAL_ALTERNATIVE_MATERIALS,
} from 'src/page/knowledgeManagement/engineerData/alternativePlan/helper/constants';
import { substitutionStrategyMap } from 'src/dict/alternativePlan/mappings';
import { appEnum } from 'src/dict';
import { setAssociatedFormFields } from '../bom/utils';

const { Option } = Select;

interface Props extends RouteComponentProps {}

interface Params {
  id?: number;
}

const CreateOrEditAlternativePlan = (props: Props) => {
  const { history, match } = props;
  const params: Params = match.params;
  const [form] = Form.useForm();
  const [continueToCreate, setContinueToCreate] = useState(false);
  const id = Number(params.id);
  const isEditMode = /edit/.test(match.path) && 'id' in params;
  const isCreateMode = /create/.test(match.path);
  const isCopyMode = /copy/.test(match.path);
  const [hasBomIdWhenInit, setHasBomIdWhenInit] = useState(false);
  /**
   * 创建时表单字段的所属部门默认为当前用户所属部门
   */
  const { userInfo } = useSelector((state: RootState) => state?.user, shallowEqual);
  const fillInOwnedDepartmentId = () => {
    const defaultDepartment: any = _.first(userInfo.departmentVOList);

    if (defaultDepartment) {
      form.setFieldsValue({
        ownedDepartmentId: [
          {
            label: defaultDepartment.name,
            value: defaultDepartment.id,
          },
        ],
      });
    }
  };
  const resetForm = () => {
    form.resetFields();
    form.setFieldsValue({
      alternativeMaterials: undefined,
    });
    fillInOwnedDepartmentId();
  };

  useEffect(() => {
    fillInOwnedDepartmentId();
  }, []);
  const [isSpecificBom, setIsSpecificBom] = useState(false);
  const [substitutionStrategy, setSubstitutionStrategy] = useState(
    SubstitutionStrategyEnum.mixedSubstitute,
  );

  /**
   * 取消按钮、创建按钮的回调
   */
  const onCancel = () => {
    history.goBack();
  };
  const onFinish = async () => {
    try {
      await form.validateFields();
      const formData = form.getFieldsValue(true);
      const submitData = isCopyMode
        ? handleFormDataBeforeSubmit(formData, true)
        : handleFormDataBeforeSubmit(formData);

      if (isCopyMode) {
        delete submitData.id;
      }

      const res = isEditMode ? await fetchAltUpdate(submitData) : await fetchAltCreate(submitData);

      if (res.code === 200) {
        message.success(`${isEditMode ? '编辑' : '新建'}替代方案成功`);
        if (continueToCreate && !isEditMode) {
          resetForm();
          return;
        }
        await pauseSomeTime(DELAY_GET_LIST_MS_GAP);
        onCancel();
      }
    } catch (e) {
      // console.log(e);
    }
  };

  /**
   * 表单-基本信息
   */
  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,
    title: '基本信息',
    items: _.compact([
      {
        label: '替代方案编号:',
        name: 'code',
        validateFirst: true,
        rules: [
          { required: true, message: '替代方案编号不能为空' },
          { max: 255, message: '不超过255个字符' },
          { validator: checkTwoSidesTrim },
          { validator: textValidator2 }, // 校验规则是：必须是字母、数字、特殊字符
        ],
        render: () => <Input placeholder="请输入" />,
      },
      {
        label: '替代方案名称:',
        name: 'name',
        validateFirst: true,
        rules: [
          { required: true, message: '替代方案名称不能为空' },
          { max: 255, message: '不超过255个字符' },
          { validator: checkTwoSidesTrim },
        ],
        render: () => <Input placeholder="请输入" />,
      },
      {
        label: '所属部门:',
        name: 'ownedDepartmentId',
        render: () => <DepartmentSelect placeholder="请选择" isMultiple={false} />,
      },
      {
        label: '替代策略',
        name: 'substitutionStrategy',
        rules: [{ required: true, message: '请选择' }],
        initialValue: SubstitutionStrategyEnum.mixedSubstitute,
        render: () => {
          const onSubstitutionStrategyChange = (substitutionStrategy: SubstitutionStrategyEnum) => {
            const alternativeMaterials = form.getFieldValue(ALTERNATIVE_MATERIALS);
            /** 清空替代料「使用比例」  */
            const copyAlternativeMaterials = setAssociatedFormFields(alternativeMaterials, [
              ['useRatio', 0],
            ]);

            form.setFields([
              {
                name: [ORIGINAL_ALTERNATIVE_MATERIALS, 0, 'useRatio'],
                value: 100,
              },
              {
                name: [ALTERNATIVE_MATERIALS],
                value: copyAlternativeMaterials,
              },
            ]);

            setSubstitutionStrategy(substitutionStrategy);

            if (
              substitutionStrategy ===
              appEnum.AlternativePlan.SubstitutionStrategyEnum.fullSubstitute
            ) {
              const formList = _.cloneDeep(form.getFieldValue(ALTERNATIVE_MATERIALS));

              if (!formList) return;
              formList.forEach((item: { [x: string]: any }) => {
                item.useUpperLimitRatio = null;
              });
              form.setFieldsValue({
                [ALTERNATIVE_MATERIALS]: formList,
              });
            }
          };

          return (
            <Select onChange={onSubstitutionStrategyChange}>
              {substitutionStrategyMap.map(({ label, value }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          );
        },
      },
      {
        label: '指定物料清单',
        name: 'specificBom',
        rules: [{ required: true, message: '请选择' }],
        initialValue: YN.no,
        render: () => (
          <Radio.Group
            onChange={(e) => {
              setIsSpecificBom(!!e.target.value);
            }}
          >
            {yn.map(({ label, value }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        ),
      },
      {
        label: '备注:',
        name: 'remark',
        rules: [{ max: 1000, message: '不超过1000个字符' }],
        render: () => <Input.TextArea maxLength={1000} showCount placeholder="请输入" />,
      },
      isEditMode && {
        label: '编辑原因',
        name: 'editReason',
        rules: [{ max: 1000, message: '不超过1000个字符' }],
        render: () => <Input.TextArea maxLength={1000} showCount placeholder="请输入" />,
      },
    ]),
  };

  /**
   * 表单-适用的物料清单
   */
  const bomInfo: DataFormLayoutInfoBlock = {
    title: '适用的物料清单',
    items: _.compact([
      {
        label: '',
        isFullLine: true,
        render: () => {
          if (isSpecificBom) {
            return (
              <SuitedBomFormTable
                hasBomIdWhenInit={hasBomIdWhenInit}
                form={form}
                name="suitedBom"
              />
            );
          }
          return '';
        },
      },
    ]),
  };

  /**
   * 表单-被替代物料+替代物料
   */
  const materialInfo: DataFormLayoutInfoBlock = {
    title: '物料信息',
    items: _.compact([
      {
        label: '',
        isFullLine: true,
        render: () => (
          <div>
            <p>被替代物料：</p>
            <OriginalMaterialFormTable
              isCreateMode={isCreateMode}
              name={ORIGINAL_ALTERNATIVE_MATERIALS}
              form={form}
            />
          </div>
        ),
      },
      {
        label: '',
        isFullLine: true,
        render: () => (
          <div>
            <p>替代物料：</p>
            <AlternativeMaterialFormTable
              form={form}
              name={ALTERNATIVE_MATERIALS}
              substitutionStrategy={substitutionStrategy}
            />
          </div>
        ),
      },
    ]),
  };

  /**
   * 编辑时自动填充表单
   */
  const autoFillInForm = async (id: number) => {
    const res = await fetchAltGet({ id });

    if (res.code !== 200 || !res.data) return;
    const formData = handleResponseToFormData(res);

    if (!formData) return;
    form.setFieldsValue(formData);
    setHasBomIdWhenInit(!!formData.specificBom);
    setIsSpecificBom(!!formData.specificBom);
    if (!formData.specificBom) return;
    const res2 = await fetchBomGet({ id: _.toNumber(res.data.bomId) });

    if (res2.code !== 200) return;
    const suitedBomFormData = handleResponseToSuitedBomFormData(res2);

    form.setFieldsValue(suitedBomFormData);
  };

  useEffect(() => {
    if (id) {
      autoFillInForm(id).then();
    }
  }, [id]);

  return (
    <DataFormLayout
      form={form}
      title={`${isEditMode ? '编辑' : '创建'}替代方案`}
      info={isSpecificBom ? [baseInfo, bomInfo, materialInfo] : [baseInfo, materialInfo]}
      onCancel={onCancel}
      onFinish={onFinish}
      extra={
        !isEditMode && (
          <Checkbox
            onChange={() => {
              setContinueToCreate(!continueToCreate);
            }}
          >
            连续新建
          </Checkbox>
        )
      }
    />
  );
};

export default CreateOrEditAlternativePlan;
