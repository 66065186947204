import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import { Badge, message as Message, Tag } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { fetchSourceListDetail } from 'src/api/ytt/order-domain/sourceList';
import { BlIcon } from 'src/components';
import { AvatarDisplay } from 'src/components/avatar/show';
import lookup from 'src/dict';
import { CRUD, UsageStatus } from 'src/dict/common';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import SupplierList from './SupplierList';
import authDict from 'src/utils/auth';

type SourceListDetailProps = {
  id: number;
  action: (type: CRUD, id: number) => void;
  refreshMarker: number;
};

const SourceListDetail: React.FC<SourceListDetailProps> = (props) => {
  const { id, action, refreshMarker } = props;
  const [dataSource, setDataSource] = useState({} as any);

  /**
   * 获取货源清单详情
   * @param id 货源清单ID
   */
  const fetchDetailById = async (id: number) => {
    const res = await fetchSourceListDetail({ id });

    const { code, message, data } = res;

    if (code !== 200) {
      Message.error(message);
      return;
    }
    setDataSource(data);
  };

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      { label: '编号', dataIndex: 'code', isFullLine: true },
      {
        label: '状态',
        dataIndex: 'enableStatus',
        isFullLine: true,
        render: (enableStatus: number) => {
          return (
            <Badge
              status={enableStatus === UsageStatus.enabled ? 'success' : 'error'}
              text={lookup('common.usageStatus', enableStatus)}
            />
          );
        },
      },
      {
        label: '物料编号',
        dataIndex: 'remark',
        isFullLine: true,
        render: (text: unknown, record: any) => {
          return _.get(record, 'material.code', replaceSign);
        },
      },
      {
        label: '物料名称',
        dataIndex: 'remark',
        isFullLine: true,
        render: (text: unknown, record: any) => {
          return _.get(record, 'material.name', replaceSign);
        },
      },
      {
        label: '物料属性',
        dataIndex: 'materialAttr',
        isFullLine: true,
        render: (materialAttr: any[]) => {
          return materialAttr?.map((item) => <Tag>{item.name}</Tag>);
        },
      },
      { label: '备注', dataIndex: 'remark', isFullLine: true },
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: any) => <AvatarDisplay {...creator} key={creator?.id} isShowTag isUser />,
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        isFullLine: true,
        render: (createdAt: Date) => (createdAt ? _Time.format(createdAt) : replaceSign),
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: any) => (
          <AvatarDisplay {...operator} key={operator?.id} isShowTag isUser />
        ),
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        isFullLine: true,
        render: (updatedAt: Date) => (updatedAt ? _Time.format(updatedAt) : replaceSign),
      },
      {
        label: '供应商',
        dataIndex: 'suppliers',
        isFullLine: true,
        render: () => <SupplierList id={id} />,
      },
    ],
    column: 2,
  };

  const baseMenu = [
    {
      key: 'enable',
      onClick: () => {
        if (dataSource?.enableStatus === UsageStatus.enabled) {
          action(CRUD.disable, id);
        } else {
          action(CRUD.enable, id);
        }
      },
      icon:
        dataSource?.enableStatus === UsageStatus.enabled ? (
          <BlIcon type="icontingyong" />
        ) : (
          <BlIcon type="iconqiyong" />
        ),
      auth: authDict.sourcelist_enable_disable,
      title: dataSource?.enableStatus === UsageStatus.enabled ? '停用' : '启用',
    },
    {
      key: 'operrecord',
      onClick: () => {
        action(CRUD.operrecord, id);
      },
      auth: authDict.sourcelist_operrecord,
      title: '操作记录',
    },
    {
      key: 'edit',
      onClick: () => {
        action(CRUD.edit, id);
      },
      auth: authDict.sourcelist_edit,
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ];

  useEffect(() => {
    if (id) {
      fetchDetailById(id);
    }
  }, [id, refreshMarker]);

  return (
    <>
      <DetailLayout
        title={'货源清单详情'}
        baseMenu={baseMenu}
        info={[baseInfo]}
        dataSource={dataSource}
      />
    </>
  );
};

export default SourceListDetail;
