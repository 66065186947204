/**
 * 编号控件
 */
import { FC, useContext } from 'react';
import _ from 'lodash';
import { Checkbox, Form, Input } from 'antd';
import type { FormInstance } from 'antd';
import type { StepDetailData, LocalControlItem } from '../../types';
import { ControlEditorContext, INPUT_DEFAULT_PLACEHOLDER } from '../../constants';
import { getNameFactory, titleRules } from './share';
import { SearchSelect } from 'src/components';
import { OBJECT_OF_ID } from 'src/entity/objectPlatform';
import { appEnum } from 'src/dict';

interface Props {
  form: FormInstance<StepDetailData>;
  localKey: string;
}

// 描述信息最大值
const MAX_REMARK_LEN = 1000;
// 提示文字最大长度
const MAX_PROMPT_LEN = 20;

const NumberRulesInputProperties: FC<Props> = ({ form, localKey }) => {
  const { forceUpdate, formProps } = useContext(ControlEditorContext);
  const controlList = form.getFieldValue('controls');

  const dataIndex = controlList.findIndex((item: LocalControlItem) => item._localKey === localKey);

  if (_.isEmpty(controlList)) {
    return null;
  }

  if (dataIndex === -1) {
    return null;
  }
  const getName = getNameFactory(dataIndex);

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={(changeValues, allValues) => {
        if (formProps) {
          const { onValuesChange } = formProps;

          onValuesChange && onValuesChange(changeValues, allValues);
        }

        forceUpdate();
      }}
    >
      <Form.Item label="标题" name={getName('name')} rules={titleRules}>
        <Input max={16} placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>

      <Form.Item
        label={'编号规则'}
        name={getName('numberRule')}
        rules={[{ required: true, message: '请选择编号规则' }]}
      >
        <SearchSelect
          labelInValue
          fetchType={'numberingRules'}
          params={{
            suitObjId: OBJECT_OF_ID.SOPTaskRecord,
            enableFlag: appEnum.Common.UsageStatus.enabled,
          }}
          placeholder={'请选择'}
          renderOption={(data: any) => {
            return {
              label: data?.ruleName,
              value: data?.id,
              key: data?.id,
              objectCode: data?.suitObj?.objectCode,
            };
          }}
          onSelect={(_value: any, option: any) => {
            form.setFields([
              {
                name: getName('relateObject'),
                value: option.objectCode,
              },
            ]);
          }}
        />
      </Form.Item>

      <Form.Item label="校验" style={{ marginBottom: 0 }}>
        <Form.Item name={getName('isRequired')} valuePropName="checked" style={{ marginBottom: 0 }}>
          <Checkbox>必填</Checkbox>
        </Form.Item>
      </Form.Item>
      <Form.Item label="描述信息" name={getName('remark')}>
        <Input.TextArea
          placeholder={INPUT_DEFAULT_PLACEHOLDER}
          maxLength={MAX_REMARK_LEN}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="提示文字"
        name={getName('inputPrompt')}
        rules={[{ max: MAX_PROMPT_LEN, message: `请限制在${MAX_PROMPT_LEN}个字符` }]}
      >
        <Input allowClear placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>
    </Form>
  );
};

export default NumberRulesInputProperties;
