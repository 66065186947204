/**
 * 拆分生产任务
 */
import { useState } from 'react';
import { compact, debounce, flatMap, map } from 'lodash';
import Big from 'big.js';
import { Descriptions, Form, InputNumber, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import BlSortFormList from 'src/components/sortList/BlSortFormList';
import { getMaterialUnitInfo } from 'src/entity/material';
import { fractionLengthCheck } from 'src/utils/formValidators';
import { arrayIsEmpty } from 'src/utils/array';
import { DataFormLayout } from 'src/layout';

import { getInsertedRecordList } from '../utils';

const SplitPlannOrder = (props: any) => {
  const { name, base, form: formInst, onChange } = props;

  const [recordCount, setRecordCount] = useState<number>(0); // 拆分生产任务

  const [form] = Form.useForm<any>();

  const getColumns = (): ColumnProps<FormListFieldData & any>[] => {
    return [
      {
        title: '每单计划生产数',
        dataIndex: 'plannedAmount',
        fixed: 'left',
        width: 120,
        render: (_text, field) => {
          const { materialInfo, unitId } = base;

          const { enablePrecision, precisionFigure } = getMaterialUnitInfo(materialInfo, unitId);

          return (
            <Form.Item
              name={[field.name, 'plannedAmount']}
              fieldKey={[field.fieldKey, 'plannedAmount']}
              style={{ marginBottom: '0' }}
              rules={compact([
                { required: true, message: '计划生产数必填' },
                {
                  validator: (opt, val) => {
                    return new Promise<void>((resolve, reject) => {
                      const base = (val && val.toString()?.split('.')[0]) ?? val;

                      if (val <= 0) reject('计划生产数必须大于0');

                      if (base > 10000000) reject('计划生产数必须小于10000000');
                      return resolve();
                    });
                  },
                },
                enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
              ])}
            >
              <InputNumber
                placeholder="请输入"
                min={1}
                onBlur={() => {
                  const splitTaskList = form.getFieldValue([name]);

                  const sumNumber = splitTaskList.reduce((prev: number, item: any) => {
                    if (item.num && item.plannedAmount) {
                      return (
                        Number(prev) + Number(new Big(item.num).times(new Big(item.plannedAmount)))
                      );
                    }
                    return Number(prev);
                  }, []);

                  sumNumber ? setRecordCount(sumNumber) : setRecordCount(recordCount);
                }}
                stringMode
              />
            </Form.Item>
          );
        },
      },
      {
        title: '个数',
        dataIndex: 'num',
        fixed: 'left',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'num']}
              fieldKey={[field.fieldKey, 'num']}
              style={{ marginBottom: '0' }}
              rules={[{ required: true, message: '个数不能为空' }]}
              initialValue={1}
            >
              <InputNumber
                placeholder="请输入"
                min={1}
                precision={0}
                onBlur={() => {
                  const splitTaskList = form.getFieldValue([name]);

                  const sumNumber = splitTaskList.reduce((prev: number, item: any) => {
                    if (item.num && item.plannedAmount) {
                      return (
                        Number(prev) + Number(new Big(item.num).times(new Big(item.plannedAmount)))
                      );
                    }
                    return Number(prev);
                  }, []);

                  sumNumber ? setRecordCount(sumNumber) : setRecordCount(recordCount);
                }}
                stringMode
              />
            </Form.Item>
          );
        },
      },
    ];
  };

  const baseInfo = {
    items: [
      {
        isFullLine: true,
        render: () => (
          <BlSortFormList
            name={name}
            renderColumns={() => getColumns()}
            isNeedDrag={false}
            maxCount={50}
          />
        ),
      },
    ],
  };

  const handleFinish = async (): Promise<any> => {
    const { detachableFraction, splitPoints } = base;
    const values = await form?.validateFields();

    const { splitOrder: splitTaskList } = values || {};

    if (arrayIsEmpty(splitTaskList)) return false;

    const sumNumber = splitTaskList.reduce((prev: number, item: any) => {
      return Number(prev) + Number(item.num * item.plannedAmount);
    }, []);

    setRecordCount(sumNumber);

    if (sumNumber > detachableFraction) {
      message.error('本次拆分数不可超过可拆分数');
      return;
    }

    const sumLines = splitTaskList.map((item: any) => {
      return getInsertedRecordList(
        {
          ...base,
          plannedAmount: item.plannedAmount,
          isSplit: true,
        },
        item.num,
      );
    });

    if (flatMap(sumLines)?.length > 10) {
      message.error('单次最多支持拆分10个');
      return;
    }

    const valueList = formInst.getFieldValue(['planOrderSplitItemList']);

    const noIncludeRecord = valueList

      .concat(...sumLines)
      .sort((a: any, b: any) => Number(a.order) - Number(b.order));

    formInst.setFields([
      {
        name: 'planOrderSplitItemList',
        value: map(noIncludeRecord, (item) => {
          if (item?.planOrderId === base?.planOrderId && !item?.isSplit) {
            return {
              ...item,
              detachableFraction: detachableFraction && sumNumber && detachableFraction - sumNumber, // 可拆分数
              splitPoints: splitPoints + sumNumber, // 本次拆分数
            };
          }
          return item;
        }),
      },
    ]);
    onChange();
  };

  const renderTopContext = () => {
    const { detachableFraction } = base;

    return (
      <Descriptions style={{ marginTop: 20 }}>
        <Descriptions.Item label="可拆分数">{detachableFraction}</Descriptions.Item>
        <Descriptions.Item label="本次拆分数合计">{recordCount}</Descriptions.Item>
      </Descriptions>
    );
  };

  return (
    <DataFormLayout
      form={form}
      info={[baseInfo]}
      topContext={renderTopContext()}
      title="拆分计划订单"
      onCancel={() => {
        onChange();
      }}
      onFinish={debounce(handleFinish, 1500)}
    />
  );
};

export default SplitPlannOrder;
