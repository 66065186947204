import React, { useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { SearchSelect } from 'src/components';

type PlanUserDeptRoleValue = { type: number; value?: { label: string; value: number }[] };

interface PlanUserDeptRoleProps {
  value?: PlanUserDeptRoleValue;
  onChange?: (value: PlanUserDeptRoleValue) => void;
}

const PlanUserDeptRole = (props: PlanUserDeptRoleProps) => {
  const [selectType, setSelectType] = useState(0);
  const [selectValue, setSelectValue] = useState<PlanUserDeptRoleValue['value']>();

  const { value, onChange } = props;

  const handleChange = (value: PlanUserDeptRoleValue) => {
    onChange?.(value);
  };

  const handleTypeChange = (type: PlanUserDeptRoleValue['type']) => {
    setSelectType(type);
    setSelectValue(undefined);

    handleChange({
      type,
      value: undefined,
    });
  };

  const handleSelectChange = (value: PlanUserDeptRoleValue['value']) => {
    setSelectValue(value);

    handleChange({
      type: selectType,
      value,
    });
  };

  useEffect(() => {
    value?.type && setSelectType(value?.type);
    value?.value && setSelectValue(value?.value);
  }, [value]);

  return (
    <Row gutter={8}>
      <Col span={6}>
        <Select
          value={selectType}
          onChange={handleTypeChange}
          options={[
            { label: '用户', value: 0 },
            { label: '部门', value: 1 },
            { label: '角色', value: 2 },
          ]}
        />
      </Col>
      <Col span={18}>
        {selectType === 0 && (
          <UserOrDepartmentSelectWithDialog
            isMultiple
            isSelectUser
            value={selectValue}
            onChange={handleSelectChange}
          />
        )}
        {selectType === 1 && (
          <UserOrDepartmentSelectWithDialog
            isMultiple
            value={selectValue}
            onChange={handleSelectChange}
          />
        )}
        {selectType === 2 && (
          <SearchSelect
            fetchType="role"
            mode="multiple"
            value={selectValue}
            onChange={handleSelectChange}
          />
        )}
      </Col>
    </Row>
  );
};

export default PlanUserDeptRole;
