/**
 * @component 级联字段选项值匹配器
 */

import { useState, FC, useEffect, useReducer } from 'react';
import _ from 'lodash';
import { Form, FormInstance, Row, Col, Empty } from 'antd';
import { FetchCascadeEditRequest } from 'ytt/metadata-domain/fieldCascading';
import { ListSelect } from '../components/listSelect';
import { LightTransfer } from '../components/lightTransfer';
import { Field } from '../../type';
import { OverflowTooltip } from 'src/components';

interface Props {
  form: FormInstance;
  fields: Field[];
  fieldCode: string;
  refFieldCode: string;
}

type Mapping = Required<FetchCascadeEditRequest>['mapping'];

const getOptions = (f: Field) =>
  (f.choiceValues ?? []).map((c) => ({ label: c.choiceValue!, value: c.choiceCode! }));
const findFieldByCode = (fields: Field[], fieldCode: string) => _.find(fields, { fieldCode }) ?? {};

export const Matcher: FC<Props> = ({ form, fields, fieldCode, refFieldCode }) => {
  const [selectedControlFieldChoiceCode, setSelectedControlFieldChoiceCode] = useState<string>();
  const [, forceUpdate] = useReducer(() => Date.now(), 0);
  const controlField = findFieldByCode(fields, fieldCode);
  const refField = findFieldByCode(fields, refFieldCode);
  const mapping = (form.getFieldsValue(true)?.mapping ?? []) as Mapping;

  useEffect(() => {
    if (controlField) {
      setSelectedControlFieldChoiceCode(_.head(controlField.choiceValues)?.choiceCode!);
      _.delay(forceUpdate, 0);
    }
  }, [fields, fieldCode]);

  if (_.isEmpty(fields)) {
    return null;
  }
  const selectedControlFieldChoiceLabel =
    _.find(controlField.choiceValues, { choiceCode: selectedControlFieldChoiceCode })
      ?.choiceValue ?? '';

  const getSelectIndex = () => {
    const selectIndex = _.findIndex(mapping, {
      choiceCode: selectedControlFieldChoiceCode,
    });

    if (selectIndex !== -1) {
      return selectIndex;
    }

    const newIndex = mapping.length;

    // 如果选中的控制字段，mapping中没有数据的话，重新初始化一下
    if (selectedControlFieldChoiceCode) {
      form.setFields([
        {
          name: ['mapping', newIndex],
          value: { choiceCode: selectedControlFieldChoiceCode, refChoiceCodes: [] },
        },
      ]);
    }

    return newIndex;
  };

  return (
    <Row justify="center">
      <Col>
        <ListSelect
          title="控制字段"
          options={getOptions(controlField)}
          value={selectedControlFieldChoiceCode}
          onChange={(e) => setSelectedControlFieldChoiceCode(e as string)}
          style={{ borderRight: 'none' }}
        />
      </Col>
      <Col>
        <Form.Item noStyle name={['mapping', getSelectIndex(), 'refChoiceCodes']}>
          <LightTransfer
            dataSource={getOptions(refField)}
            titles={['依赖字段', '已选值']}
            extra={
              <span style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                控制字段：
                <OverflowTooltip
                  text={selectedControlFieldChoiceLabel}
                  textStyle={{ display: 'inline-block' }}
                  placement="right"
                />
              </span>
            }
            emptyText={
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'请选择依赖字段值'} />
            }
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
