import React, { useEffect, useState } from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import { fetchApprovalPlanDetail } from 'src/api/ytt/metadata-domain/approvalProcessScheme';
import {
  formatToStingfyOption,
  formatDelayTriggerField,
  formatConditionGroups,
} from 'src/page/organization/triggerRule/utils/formatToFormData';
import CreateAndEditBaseInfo from './components/createAndEditComponent';
import { isNil, map } from 'lodash';
import { ApprovalDesignationObjectEnum } from 'src/dict/processManagement';
import { fetchApprovalPlanNodeListByPlan } from 'src/api/ytt/metadata-domain/approvalNode';
import _Array from 'src/utils/dataTypes/array';

interface createProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const CreateAndEdit = (props: createProps) => {
  const { match } = props;

  const id = Number(match?.params?.id);

  const [dataSource, setDataSource] = useState({} as any);

  const [approvalPlanId, setApprovalPlanId] = useState<number>(0);
  const [referenceId, setReferenceId] = useState<number>(0);

  const [current, setCurrent] = useState<number>(0);

  const prefix = 'approval';

  const handleSetApprovalPlanId = (data: any) => {
    setApprovalPlanId(data?.id);
    setReferenceId(data?.referenceId);
  };

  const handleSetCurrent = (current: number) => {
    setCurrent(current);
  };

  const fetchNodes = async () => {
    try {
      const { data } = await fetchApprovalPlanNodeListByPlan({ id });

      if (data && !_Array.isEmpty(data?.nodeList)) {
        const _nodes = map(data?.nodeList, (node: any, i: number) => {
          const {
            id,
            createdAt,
            creator,
            first,
            last,
            operator,
            sortIndex,
            updatedAt,
            approverList,
            ...rest
          } = node;

          return {
            id,
            type: 'node',
            name: '审批节点名称',
            data: {
              id,
              configured: 1,
              nodeFormType: 'approvingCommonNodes',
              isChangeFieldValue: 0,
              isEdit: 1,
              approverList:
                node?.approverType !== ApprovalDesignationObjectEnum.role
                  ? map(approverList, (e: any) => {
                      return { label: e?.name, value: e?.id };
                    })
                  : approverList,
              ...rest,
            },
            path: [`${i + 1}`],
          };
        });

        const nodes = [
          {
            id: '1',
            type: 'start',
            name: '开始',
            path: ['0'],
          },
        ]
          .concat(_nodes)
          .concat([
            {
              id: '3',
              type: 'end',
              name: '结束',
              path: ['2'],
            },
          ]);

        sessionStorage.setItem(`${prefix}flowData`, JSON.stringify(nodes));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchData = async () => {
    try {
      const relId = approvalPlanId || id;

      const { data } = await fetchApprovalPlanDetail({ id: relId });

      if (data) {
        const {
          triggerRule,
          approvalObjectCode: objectCode,
          approvalObjectName: objectName,
          objectCategory,
          nodeList,
          ...rest
        } = data;

        const { triggerCondition, triggerConditionType, ...restValue } = triggerRule ?? {};

        setDataSource({
          ...rest,
          objectCode: formatToStingfyOption(objectName, {
            objectCode,
            objectName,
            objectCategory,
          }),
          triggerCondition: {
            conditionGroups: triggerCondition?.conditionGroups?.map(formatConditionGroups), // (triggerConditionType.conditionGroups),
            type: isNil(triggerCondition?.type) ? undefined : triggerCondition?.type,
          },
          triggerConditionType,
          bizEventTrigger: formatDelayTriggerField(restValue),
        });

        setApprovalPlanId(data?.id);
        setReferenceId(data?.referenceId);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    current === 0 && (id || approvalPlanId) && fetchData();
  }, [current]);

  useEffect(() => {
    id && fetchNodes();
  }, []);

  useEffect(() => {
    !id &&
      sessionStorage.setItem(
        `${prefix}flowData`,
        JSON.stringify([
          {
            id: '1',
            type: 'start',
            name: '开始',
            path: ['0'],
          },
          {
            id: `${Math.random() * 99}`,
            type: 'node',
            name: '审批节点名称',
            data: {
              configured: 0,
              nodeFormType: 'approvingCommonNodes',
              isChangeFieldValue: 0,
              isEdit: 0,
            },
            path: ['1'],
          },
          {
            id: '3',
            type: 'end',
            name: '结束',
            path: ['2'],
          },
        ]),
      );
  }, []);

  return (
    <CreateAndEditBaseInfo
      edit={Boolean(approvalPlanId || id)}
      initialData={dataSource}
      approvalPlanId={approvalPlanId}
      referenceId={referenceId}
      handleSetApprovalPlanId={handleSetApprovalPlanId}
      current={current}
      handleSetCurrent={handleSetCurrent}
    />
  );
};

export default CreateAndEdit;
