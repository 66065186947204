import { useState } from 'react';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RecordListLayout } from 'layout';
import { fetchPurchaseOrderList } from 'src/api/ytt/order-domain/purchaseOrder';
import { dataColumnsForOrdersList, ordersFilterList } from './columns';
import { BlIcon, LinkTooltip, SingleOperationLogComponent } from 'src/components';
import { PurchaseOrderListItem } from './interface';
import { gcObject } from 'utils';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { PurchaseExecStatus, PurchaseOrderdeliveryMode, PurchaseSource } from 'src/dict/purchase';
import _ from 'lodash';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import {
  toEdit,
  toDetail,
  toCreate,
  toCreateReturnOrder,
  toCreateDeliveryOrder,
  toCreateDeliveryScheduleNote,
} from './navigate';
import {
  handleDistribute,
  handleClosed,
  handleReboot,
  endOrder,
  beforeCreateReturnOrder,
} from './batchOperate';
import authDict from 'src/utils/auth';
import lookup from 'src/dict';
import { CRUD } from 'src/dict/common';
import { formatDateTimeRangeToUnix } from 'src/utils/formatters/dateTime';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import SettingModal from './components/settingModal';

const PurchaseOrderList = () => {
  const history = useHistory();
  // 选中行的key，用以批量操作
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectedRow, setSelectRow] = useState<any[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>();

  const [currentId, setCurrentId] = useState<number>();
  /** 用来打开弹框 */
  const openModal = useOpenExImportModal();

  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [settingModalShow, setSettingModalShow] = useState<boolean>(false);
  const refresh = () => {
    setRefreshMarker(Date.now());
  };
  const dispatch = useDispatch();
  const handleCreateReturnOrder = (ids: number[], onSuccess?: () => void, onFail?: () => void) => {
    const result = beforeCreateReturnOrder(ids, '采购退货单', onSuccess, onFail);

    result.then((res) => {
      if (res) {
        dispatch({
          type: 'supplyChain/formatterPurchaseReturnOrderInfo',
          payload: res,
        });
        history.push(toCreateReturnOrder());
      }
    });
  };
  const handleCreateDeliveryScheduleNote = (
    ids: number[],
    onSuccess?: () => void,
    onFail?: () => void,
  ) => {
    const result = beforeCreateReturnOrder(ids, '交货计划单', onSuccess, onFail);

    result.then((res) => {
      if (res) {
        dispatch({
          type: 'supplyChain/formatterDeliveryScheduleNote',
          payload: res,
        });
        history.push(toCreateDeliveryScheduleNote());
      }
    });
  };
  const left = [
    {
      title: '导出',
      icon: <BlIcon type="icondaochu" />,
      auth: authDict.purchaseorder_export,
      showExport: true,
      onClick: () =>
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.purchaseOrder,
        }),
    },
    {
      title: '新建采购订单',
      auth: authDict.purchaseorder_add,
      showExport: true,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        history.push(toCreate());
      },
      items: [
        {
          title: '导入',
          auth: authDict.purchaseorder_import,
          showExport: true,
          onClick: () =>
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.purchaseOrder,
            }),
        },
        {
          title: '协同设置',
          showExport: true,
          onClick: () => {
            setSettingModalShow(true);
          },
        },
      ],
    },
  ];

  const batchMenu = [
    {
      title: '下发',
      auth: authDict.purchaseorder_dispatch,
      onClick: (onSuccess: any, onFail: any) => {
        handleDistribute(selectedRowKeys, onSuccess, onFail, refresh);
      },
    },
    {
      title: '结束',
      auth: authDict.purchaseorder_finish,
      onClick: (onSuccess: any, onFail: any) => {
        endOrder(
          selectedRowKeys,
          _.map(selectedRow, 'code'),
          'multiple',
          () => {
            onSuccess?.();
            refresh();
          },
          onFail,
        );
      },
    },
    {
      title: '关闭',
      auth: authDict.purchaseorder_close,
      onClick: (onSuccess: any, onFail: any) => {
        Modal.confirm({
          title: '关闭采购订单',
          content: '关闭采购订单后不能再进行任何操作，确认关闭吗？',
          okText: '关闭',
          cancelText: '暂不关闭',
          onOk: async () => {
            await handleClosed(selectedRowKeys, onSuccess, onFail, refresh);
          },
          onCancel: async () => {
            await onFail();
          },
        });
      },
    },
    {
      title: '重启',
      auth: authDict.purchaseorder_restart,
      onClick: (onSuccess: any, onFail: any) => {
        Modal.confirm({
          title: '重启采购订单',
          content: '重启采购订单后，采购订单状态变为执行中，确认重启吗',
          okText: '重启',
          cancelText: '暂不重启',
          onOk: async () => {
            await handleReboot(selectedRowKeys, onSuccess, onFail, refresh);
          },
          onCancel: async () => {
            await onFail();
          },
        });
      },
    },
    {
      title: '新建采购退货单',
      auth: authDict.purchasereturnorder_add,
      onClick: (onSuccess: any, onFail: any) => {
        handleCreateReturnOrder(selectedRowKeys, onSuccess, onFail);
      },
    },
    {
      title: '新建交货计划单',
      auth: authDict.deliveryScheduleNote_add,
      onClick: (onSuccess: any, onFail: any) => {
        handleCreateDeliveryScheduleNote(selectedRowKeys, onSuccess, onFail);
      },
    },
  ];

  const dataColumns = [
    {
      title: '编号',
      dataIndex: 'code',
      width: 150,
      sorter: true,
      render: (code: string, record: PurchaseOrderListItem, index: number, config: any) => {
        return (
          <LinkTooltip
            text={code}
            to={toDetail(record.id as number)}
            width={config.contentWidth}
            auth={authDict.purchaseorder_detail}
          />
        );
      },
    },
    ...dataColumnsForOrdersList,
  ];
  const getOperationList = (record: PurchaseOrderListItem) => {
    return _.compact([
      {
        title: lookup('crud', CRUD.view),
        auth: authDict.purchaseorder_detail,
        onClick: () => {
          history.push(toDetail(record.id!));
        },
      },
      {
        title: lookup('crud', CRUD.edit),
        auth: authDict.purchaseorder_edit,
        disabled:
          record.execStatus === PurchaseExecStatus.finished ||
          record.execStatus === PurchaseExecStatus.closed,
        onClick: () => {
          history.push(toEdit(record.id as number));
        },
      },
      {
        title: lookup('crud', CRUD.issued),
        auth: authDict.purchaseorder_dispatch,
        disabled: record.execStatus !== PurchaseExecStatus.created,
        onClick: () => {
          handleDistribute([record.id!], refresh);
        },
      },
      {
        title: '新建采购退货单',
        auth: authDict.purchasereturnorder_add,
        disabled: record.execStatus !== PurchaseExecStatus.executing,
        onClick: () => {
          handleCreateReturnOrder([record.id!]);
        },
      },
      {
        title: '新建交货计划单',
        auth: authDict.deliveryScheduleNote_add,
        disabled:
          record.execStatus !== PurchaseExecStatus.executing ||
          record.deliveryMode !== PurchaseOrderdeliveryMode.deliverySchedule,
        onClick: () => {
          handleCreateDeliveryScheduleNote([record.id!]);
        },
      },
      {
        title: '新建收货单',
        key: 'createReceivenoteOrder',
        auth: authDict.receivenote_add,
        disabled: !(
          record.execStatus === PurchaseExecStatus.executing &&
          record.source === PurchaseSource.common
        ),
        onClick: () => {
          history.push(toCreateDeliveryOrder(record.id as number));
        },
      },
      {
        title: '结束',
        auth: authDict.purchaseorder_finish,
        disabled: record.execStatus !== PurchaseExecStatus.executing,
        onClick: () => {
          endOrder([record.id!], [record.code!], 'single', refresh);
        },
      },
      {
        title: '关闭',
        auth: authDict.purchaseorder_close,
        disabled: record.execStatus !== PurchaseExecStatus.created,
        onClick: () => {
          Modal.confirm({
            title: '关闭采购订单',
            content: `关闭采购订单后不能再进行任何操作，确认关闭<${record.code}>吗？`,
            okText: '关闭',
            cancelText: '暂不关闭',
            onOk: async () => {
              await handleClosed([record.id!], refresh);
            },
          });
        },
      },
      {
        title: '重启',
        auth: authDict.purchaseorder_restart,
        disabled: record.execStatus !== PurchaseExecStatus.finished,
        onClick: () => {
          Modal.confirm({
            title: '重启采购订单',
            content: `重启采购订单后，采购订单状态变为执行中，确定重启<${record.code}>吗？`,
            okText: '重启',
            cancelText: '暂不重启',
            onOk: async () => {
              await handleReboot([record.id!], refresh);
            },
          });
        },
      },

      // {
      //   title: lookup('crud', CRUD.delete),
      //   onClick: () => {
      //     BLDelConfirm('是否删除当前订单', delForm, () => {
      //       handleFinish(id);
      //     });
      //   },
      // },
      {
        title: '操作记录',
        auth: authDict.purchaseorder_operrecord,
        onClick: () => {
          setCurrentId(record.id);
          setVisibleLog(true);
        },
      },
    ]);
  };
  const formatDataToQuery = (params: any) => {
    const { createdAt, updatedAt, supplier, creator, operator, owner, ...rest } = params || {};
    const options = {
      ...formatDateTimeRangeToUnix('createdAtFrom', 'createdAtTo', createdAt),
      ...formatDateTimeRangeToUnix('updatedAtFrom', 'updatedAtTo', updatedAt),
      creatorIds: _.isEmpty(creator) ? null : _.map(creator, 'value'),
      operatorIds: _.isEmpty(operator) ? null : _.map(operator, 'value'),
      ownerIds: _.isEmpty(owner) ? null : _.map(owner, 'value'),
      supplierIds: supplier?.map((item: any) => item.key),
      ...rest,
    };

    dispatch.excelImport.updateBusinessData({
      businessData: gcObject.filterEmptyProperty(options),
    });

    return gcObject.filterEmptyProperty(options);
  };
  const formatDataToFormDisplay = (data: any) => {
    const { createdAt = [], updatedAt = [], ...resData } = _.cloneDeep(data);

    console.log(300, updatedAt, data);

    return {
      ...resData,
      createdAt: momentData(createdAt),
      updatedAt: momentData(updatedAt),
    };
  };

  return (
    <>
      <RecordListLayout<PurchaseOrderListItem>
        columns={dataColumns}
        filterList={ordersFilterList}
        rowKey="id"
        batchMenu={batchMenu}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectedKeys: any, row) => {
          setSelectedRowKeys(selectedKeys);
          if (row) {
            setSelectRow(row);
          }
        }}
        mainMenu={left}
        getOperationList={getOperationList}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        configcacheKey="purchaseOrderList"
        requestFn={fetchPurchaseOrderList}
        refreshMarker={refreshMarker}
        placeholder="请输入编号、供应商"
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={currentId as number}
          objectCode={OBJECT_OF_CODE.purchaseOrder}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      {settingModalShow && (
        <SettingModal visible={settingModalShow} onClose={() => setSettingModalShow(false)} />
      )}
    </>
  );
};

export default PurchaseOrderList;
