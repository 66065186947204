import React from 'react';
import { LIST_VIEW_MODAL } from './constant';
import { TabList } from 'src/components';
import ElectronicTemplateSubList from './subList';
import ElectronicTemplateMainList from './mainList';

const List = () => {
  const tabList = [
    {
      tab: '按电子单据模板展示',
      key: LIST_VIEW_MODAL.MAIN_LIST,
      component: <ElectronicTemplateMainList />,
    },
    {
      tab: '按模板文件明细展示',
      key: LIST_VIEW_MODAL.SUB_LIST,
      component: <ElectronicTemplateSubList />,
    },
  ];

  return <TabList tabList={tabList} defaultKey={LIST_VIEW_MODAL.MAIN_LIST} />;
};

export default List;
