import React, { useEffect } from 'react';
import { BlIcon, BlTable } from 'src/components';
import { Form, FormInstance, message, TimePicker } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import styles from './shift.module.scss';
import _Time from 'src/utils/dataTypes/time';
import moment from 'moment';
import { gcTime } from 'src/utils';
import {
  checkEndTime,
  checkFirstStartTime,
  checkStartTime,
  formatTime,
  getNeedObject,
  judgeIsNextDay,
} from '../utils';
import { isUndefined } from 'lodash-es';
import { replaceSign } from 'src/utils/constants';
import { ShiftInfoDetailVOS } from '../interface';

interface StoreDetailProps {
  initialData?: ShiftInfoDetailVOS[];
  form: FormInstance;
  getWorkHour: (time: number) => void;
  getTotalHour: (time: number) => void;
}

const format = 'HH:mm';

const oneDay = Number(86400000); // 一天的毫秒数

const FormTable = (props: StoreDetailProps) => {
  const { initialData, form, getWorkHour, getTotalHour } = props;

  const formatDetailDataToForm = (values: any) => {
    const now = new Date().toLocaleDateString();
    const nowDay = new Date(`${now} 00:00:00`).getTime(); // 后端的时间戳永远从0开始 拿到的是一个时间段的时间戳  需要去加上当前日期号的时间戳
    // 今天的0:00-0:01 传给后端的数据是60000 所以时间都需要加上当前日期的00:00的时间戳
    const timeValues = values?.map((node: any) => {
      const newNode = { ...node };

      const { startTime, endTime } = newNode;

      if (startTime > oneDay) {
        newNode.startTag = 1;
        newNode.startTime = moment(nowDay + startTime - oneDay);
      } else {
        newNode.startTag = 0;
        newNode.startTime = moment(nowDay + startTime);
      }
      if (endTime >= oneDay) {
        newNode.endTag = 1;
        newNode.endTime = moment(nowDay + endTime - oneDay);
      } else {
        newNode.endTag = 0;
        newNode.endTime = moment(nowDay + endTime);
      }
      return newNode;
    });

    return timeValues;
  };

  const scrollToBottom = () => {
    const wrapper = document.getElementById('shift');

    if (wrapper) {
      const dom = wrapper.getElementsByClassName('ant-table-body')[0];

      if (dom) {
        dom.scrollTop = dom.scrollHeight;
      }
    }
  };

  useEffect(() => {
    if (initialData) {
      // 编辑先获取有效工时和总工时
      form.setFieldsValue({
        shiftInfos: formatDetailDataToForm(initialData),
      });
    } else {
      form.setFieldsValue({
        shiftInfos: [{}],
      });
    }
  }, [initialData]);

  // 计算有效工时
  const calcWorkHour = (newShiftInfosValues: any) => {
    let workHour = 0;

    newShiftInfosValues.forEach((time: any) => {
      if (!isUndefined(time) && time?.startTime && time?.endTime) {
        workHour += time?.duration;
      }
    });
    getWorkHour(workHour);
  };

  // 计算总工时
  const calcTotalHour = (newShiftInfosValues: any) => {
    const list = getNeedObject(newShiftInfosValues);

    if (list?.firstStartStamp && list?.endTimeStamp) {
      const FirstStartTime = Number(_Time.formatToUnix(list?.firstStartStamp)); // 第一段的开始时间

      const lastEndTime = Number(_Time.formatToUnix(list?.endTimeStamp)); // 最后一段的结束时间

      const endTime = list?.endTimeNode?.endTag === 1 ? lastEndTime + oneDay : lastEndTime;

      const totalTimeGap = endTime - FirstStartTime;

      getTotalHour(totalTimeGap);
    }
  };

  const SetPeriodTime = () => {
    const shiftInfosValues = form.getFieldValue('shiftInfos');

    const newShiftInfosValues = shiftInfosValues?.map((node: any, i: number) => {
      const newNode = { ...node };

      if (!isUndefined(node)) {
        // node存在的情况下
        const { startTime, endTime } = newNode;
        const firstStartTime = shiftInfosValues[0]?.startTime; // 第一段的开始时间

        if (!firstStartTime) {
          return newNode;
        }

        if (startTime) {
          newNode.startTag = judgeIsNextDay(startTime, firstStartTime) ? 1 : 0;
          if (i === 0) {
            newNode.startTag = 0;
          }
        }
        if (endTime) {
          newNode.endTag = judgeIsNextDay(endTime, firstStartTime) ? 1 : 0;
        }

        if (startTime && endTime) {
          // 开始和结束都有值才去计算时间差
          const [periodStartTime, periodEndTime] = gcTime.formatMomentRangeToUnix([
            startTime,
            endTime,
          ]);
          const startTimeStamp = newNode.startTag
            ? Number(periodStartTime) + oneDay
            : Number(periodStartTime);

          const endTimeStamp = newNode.endTag
            ? Number(periodEndTime) + oneDay
            : Number(periodEndTime);

          const timeGap = endTimeStamp - startTimeStamp;

          newNode.duration = timeGap;
        }
      }
      return newNode;
    });

    form.setFieldsValue({ shiftInfos: newShiftInfosValues });
  };

  // 校验开始时间的正确性 ，通过总工时判断
  const startTimeCheck = (index: number) => {
    return (_rule: any, value: any) => {
      return new Promise((resolve, reject) => {
        let isHaveEmpty = false;
        const times = form.getFieldValue('shiftInfos');

        times?.forEach((e: any) => {
          if (!e?.startTime) {
            isHaveEmpty = true;
          }
        });

        if (isHaveEmpty) return resolve(true); // 有开始时间为空 不校验时间合法性 走不能为空的校验

        if (times?.length < 2) {
          // 不校验一条的情况
          calcTotalHour(times);
          calcWorkHour(times);
          return resolve(true);
        }

        const checkRes =
          index === 0
            ? checkFirstStartTime(times, index, value)
            : checkStartTime(times, index, value);

        if (checkRes) {
          calcTotalHour(times);
          calcWorkHour(times);
          return resolve(true);
        }
        return reject('当前开始时间不满足总工时24小时的时间范围');
      });
    };
  };

  // 校验结束时间的正确性
  const endTimeCheck = (index: number) => {
    return (_rule: any, value: any) => {
      return new Promise((resolve, reject) => {
        let isHaveEmpty = false;
        const times = form.getFieldValue('shiftInfos');

        times?.forEach((e: any) => {
          if (!e?.endTime) {
            isHaveEmpty = true;
          }
        });

        if (isHaveEmpty) return resolve(true); // 有结束时间为空 不校验时间合法性 走不能为空的校验

        if (times?.length < 2) {
          // 不校验一条的情况
          calcTotalHour(times);
          calcWorkHour(times);
          return resolve(true);
        }

        // 永远校验的是 ，当前结束时间是否在  当前段的开始和下一段的开始允许范围之内，如果没有下一段的开始时间 默认是次日的第一段开始
        const checkRes = checkEndTime(times, index, value);

        if (checkRes) {
          calcTotalHour(times);
          calcWorkHour(times);
          return resolve(true);
        }
        return reject('当前结束时间不满足总工时24小时的时间范围');
      });
    };
  };

  const isShowStartTag = (index: number) => {
    const current = form.getFieldValue('shiftInfos')[index] ?? {};
    const showTag = current?.startTag === 1;

    if (showTag) {
      return 'block';
    }
    return 'none';
  };

  const isShowEndTag = (index: number) => {
    const current = form.getFieldValue('shiftInfos')[index] ?? {};
    const showTag = current?.endTag === 1;

    if (showTag) {
      return 'block';
    }
    return 'none';
  };

  return (
    <Form.List name="shiftInfos">
      {(fields, { add, remove }) => {
        const getFooter = () => {
          return (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <span
                onClick={() => {
                  if (fields.length >= 5) {
                    message.error('最多支持配置5个时间段');
                    return;
                  }
                  // 如果前面有空值,不允许添加新的一行
                  const times = form.getFieldValue('shiftInfos');

                  let canAdd = true;

                  times.forEach((node: any) => {
                    if (isUndefined(node) || !node?.startTime || !node?.endTime) {
                      canAdd = false;
                    }
                  });
                  if (!canAdd) {
                    message.error('请先选择当前行的开始时间和结束时间');
                  } else {
                    add();
                    setTimeout(() => {
                      scrollToBottom();
                    }, 300);
                  }
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                <span style={{ marginRight: 8 }}>
                  <BlIcon type="iconxinjiantianjia" />
                </span>
                添加时段
              </span>
            </div>
          );
        };
        const getColumns = (): ColumnProps<FormListFieldData>[] => {
          return [
            {
              width: 30,
              fixed: 'left',
              render: (_, record) => {
                return (
                  <div style={{ color: 'red' }}>
                    <BlIcon
                      type="iconlieshanchu"
                      onClick={() => {
                        const times = form.getFieldValue('shiftInfos') ?? {};

                        if (times?.length < 2) {
                          // 提示 至少需要一条
                          message.error('至少需要一条时段');
                          return;
                        }
                        remove(record?.name);
                        // 重新计算工时和有些工时以及次日tag
                        SetPeriodTime();
                        calcWorkHour(form.getFieldValue('shiftInfos'));
                        calcTotalHour(form.getFieldValue('shiftInfos'));
                      }}
                    />
                  </div>
                );
              },
            },
            {
              title: '序号',
              dataIndex: 'sequenceNumber',
              key: 'sequenceNumber',
              width: 20,
              render: (_, field, index) => {
                return index + 1;
              },
            },
            {
              title: '开始时间',
              dataIndex: 'startTime',
              key: 'startTime',
              width: 200,
              render: (_, field, index) => {
                return (
                  <Form.Item shouldUpdate>
                    {() => {
                      return (
                        <div className="timeBox">
                          <div className="time">
                            <Form.Item
                              name={[field?.name, 'startTime']}
                              fieldKey={[field?.key, 'startTime']}
                              rules={[
                                { required: true, message: '开始时间不能为空' },
                                { validator: startTimeCheck(index) },
                              ]}
                            >
                              <TimePicker
                                style={{ width: '100%' }}
                                format={format}
                                onChange={() => {
                                  SetPeriodTime();
                                }}
                                allowClear={false}
                              />
                            </Form.Item>
                          </div>
                          <div className="nextDayTagBox">
                            <div className="nextDayTag" style={{ display: isShowStartTag(index) }}>
                              次日
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: '结束时间',
              dataIndex: 'endTime',
              key: 'endTime',
              width: 200,
              render: (_, field, index) => {
                const endTimeDisabled = isUndefined(
                  form.getFieldValue('shiftInfos')[field?.name]?.startTime,
                );

                return (
                  <Form.Item shouldUpdate>
                    {() => {
                      return (
                        <div className="timeBox">
                          <div className="time">
                            <Form.Item
                              name={[field?.name, 'endTime']}
                              fieldKey={[field?.key, 'endTime']}
                              rules={[
                                { required: true, message: '结束时间不能为空' },
                                { validator: endTimeCheck(index) },
                              ]}
                            >
                              <TimePicker
                                disabled={endTimeDisabled}
                                style={{ width: '100%' }}
                                format={format}
                                allowClear={false}
                                onChange={() => {
                                  SetPeriodTime();
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="nextDayTagBox">
                            <div className="nextDayTag" style={{ display: isShowEndTag(index) }}>
                              次日
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: '时长',
              dataIndex: 'duration',
              key: 'duration',
              width: 150,
              render: (_, field, index) => {
                return (
                  <Form.Item shouldUpdate>
                    {() => {
                      const { startTime, endTime } = form.getFieldValue('shiftInfos')[index] ?? {};

                      const [createStartTime, createEndTime] = gcTime.formatMomentRangeToUnix([
                        startTime,
                        endTime,
                      ]);

                      let perTime;

                      if (startTime && endTime) {
                        if (startTime >= endTime) {
                          // 表面是选择的第二天了 此时需要在结束时间上加一天的毫秒数
                          const createEndTimeGap = createEndTime - createStartTime + oneDay;

                          perTime = formatTime(createEndTimeGap);
                        } else {
                          const timeGap = createEndTime - createStartTime; // 时间差毫秒

                          perTime = formatTime(timeGap);
                        }
                      }

                      return (
                        <Form.Item
                          name={[field?.name, 'duration']}
                          fieldKey={[field?.key, 'duration']}
                        >
                          <div>{perTime ?? replaceSign}</div>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
          ];
        };

        return (
          <BlTable
            columns={getColumns()}
            dataSource={fields}
            footer={getFooter}
            scroll={{ y: 640 }}
            rowKey={(field) => field?.name}
            id="shift"
            style={{ minWidth: 1000 }}
            className={styles?.shiftTable}
            pagination={false}
          />
        );
      }}
    </Form.List>
  );
};

export default FormTable;
