import { useMemo, useState, useRef } from 'react';
import _ from 'lodash';
import { BlIcon, Tooltip } from 'components';
import { RecordListLayout, DataFormLayoutForModal } from 'layout';
import { fieldTypeList } from 'src/utils';
import RoleActions from 'src/page/organization/hooks/useRoleAction';
import TagTooltip from 'src/components/tooltip/tagTooltip';
import { appEnum, appDict } from 'src/dict';
import RoleDisplay from '../hooks/roleDisplay';
import { fetchRoleListForDisplay } from 'src/api/ytt/user-domain/user/role';
import { gcObject } from 'utils';
import { SYSTEM_ADMIN, SCM_VIRTUAL_ROLE } from '../constants';
import authDict, { hasAuth } from 'src/utils/auth';
import { replaceSign } from 'src/utils/constants';
import { useHistory } from 'react-router-dom';
import UserOrDepartmentSelectWithDialog from '../components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import renderUsageStatus from 'src/page/share/renderUsageStatus';
import { handleRoleStatus } from './handleRoleStatus';

interface TableRowType {
  id: number;
  code: string;
  userList: any[];
  enableFlag: number;
}

const RoleManagement = () => {
  const [visible, setVisible] = useState<boolean>(false); // 编辑，创建
  const [visibleRole, setVisibleRole] = useState<boolean>(false); // 权限配置
  const [roleId, setRoleId] = useState<number | null>(null);
  // const [visibleDetail, setVisibleDetail] = useState<boolean>(false); // 查看详情
  // const [roleMsg, setRoleMsg] = useState<TableRowType>(); // 打开详情的角色信息
  // const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const refreshCountRef = useRef(0);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [isCopy, setIsCopy] = useState<boolean>(false);
  // const [visibleLog, setVisibleLog] = useState(false);
  // const [logInstanceId, setLogInstanceId] = useState<number | null>(0);

  const history = useHistory();

  // useEffect(() => {
  //   if (logInstanceId) {
  //     setVisibleLog(true);
  //   }
  // }, [logInstanceId]);

  const refreshTable = () => {
    // 刷新列表页
    refreshCountRef.current++;
    setRefreshMarker(refreshCountRef.current);
  };

  const renderRoleActions = useMemo(() => {
    return visible ? (
      <RoleActions
        roleId={roleId}
        isCopy={isCopy}
        hideCallback={(modifed) => {
          if (modifed) {
            refreshTable();
          }
          setVisible(false);
          setIsCopy(false);
        }}
      />
    ) : null;
  }, [visible, roleId]);

  const fetchData = async (params: any) => {
    const res = await fetchRoleListForDisplay(params);

    return res;
  };

  // const delRoles = async (roleIds: (number | string)[], autoRefresh?: boolean) => {
  //   const { data: response } = await deleteRoles(roleIds.map((item) => Number(item))).role;

  //   if (autoRefresh) {
  //     refreshTable();
  //   }
  //   try {
  //     const { failedIds, failedCount, successCount } = response;

  //     if (failedCount > 0) {
  //       showErrorListMessage({
  //         title: '删除出现失败',
  //         data: failedIds,
  //         columns: [
  //           {
  //             title: '编号',
  //             dataIndex: 'code',
  //             key: 'code',
  //           },
  //           {
  //             title: '失败原因',
  //             dataIndex: 'reason',
  //             key: 'reason',
  //           },
  //         ],
  //         operateName: '删除',
  //         successCount,
  //         failCount: failedCount,
  //         width: 580,
  //       });
  //     } else if (failedCount === 0 && successCount > 0) {
  //       message.success('删除成功');
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const editRole = (id: number) => {
    setVisible(true);
    setRoleId(id);
  };

  const copyRole = (id: number) => {
    setVisible(true);
    setRoleId(id);
    setIsCopy(true);
  };

  const setAuth = (id: number) => {
    setVisibleRole(true);
    setRoleId(id);
  };

  /** 打开详情抽屉函数 */
  // const lookDetail = (record: TableRowType) => {
  //   const { id, code, enableFlag, inUse } = record;

  //   setVisibleDetail(true);
  //   setRoleId(id);
  //   setRoleMsg({ id, code, enableFlag, inUse });
  // };

  // const deleteRole = (id: number) => delRoles([id], true);

  const dataColumns = [
    {
      title: '角色编号',
      dataIndex: 'code',
      type: 'text',
      isFilter: true,
      width: 150,
      sorter: true,
      render: (name: string, record: TableRowType, index: number, config: any) => {
        return (
          <>
            {hasAuth(authDict.role_detail) ? (
              <a
                onClick={() => {
                  history.push(`/organization/roleManagement/detail/${record?.id}`);
                }}
              >
                <Tooltip text={name ?? replaceSign} width={config.contentWidth} />
              </a>
            ) : (
              <Tooltip text={name ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },
    {
      title: '角色名称',
      dataIndex: 'name',
      type: 'text',
      isFilter: true,
      width: 150,
      sorter: true,
    },
    {
      title: '角色描述',
      dataIndex: 'desc',
      type: 'text',
      isFilter: true,
      width: 150,
    },
    {
      title: '使用人',
      dataIndex: 'userList',
      width: 150,
      isFilter: true,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
      render: (userList: any[]) => <TagTooltip list={userList || []} max={2} text="..." />,
    },
    {
      title: '启用状态',
      dataIndex: 'enableFlag',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: { options: appDict.common.usageStatus },
      render: renderUsageStatus,
    },
    {
      title: '许可项',
      width: 150,
      dataIndex: 'roleOwnedLicenses',
      render: (val: any) => {
        if (!val) {
          return '-';
        }
        return (
          <TagTooltip
            type="text"
            text="···"
            list={val.map((item: any) => ({ id: item.code, name: item.name }))}
          />
        );
      },
    },
    {
      title: '备注',
      type: 'text',
      dataIndex: 'remark',
      width: 150,
    },
  ];

  const getOperationList = (record: TableRowType) => {
    const { id, code, enableFlag, userList = [] } = record;
    let list = [
      {
        title: '查看',
        auth: authDict.role_detail,
        onClick: () => history.push(`/organization/roleManagement/detail/${id}`),
      },
    ];

    if (![SYSTEM_ADMIN, SCM_VIRTUAL_ROLE].includes(code)) {
      list = _.compact([
        ...list,
        {
          title: '权限配置',
          auth: authDict.role_config,
          onClick: () => setAuth(id),
        },
        { title: '编辑', auth: authDict.role_edit, onClick: () => editRole(id) },
        { title: '复制', auth: authDict.role_add, onClick: () => copyRole(id) },
        {
          title: enableFlag === appEnum.Common.UsageStatus.disabled ? '启用' : '停用',
          auth: authDict.role_enable_disable,
          onClick: () => handleRoleStatus(id, enableFlag, userList?.length > 0, refreshTable),
        },
      ]);
    }

    return list;
  };

  const config = {
    rowKey: (record: TableRowType) => String(record.id),
    columns: dataColumns.map((column) => {
      const { ...rest } = column;

      return { ...rest };
    }),
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((columns: any) => {
        const filter = {
          label: columns.title,
          name: columns.dataIndex,
          type: columns.type,
          rules: columns.rules,
          renderItem: columns.renderItem,
        } as any;

        if (columns.type === fieldTypeList.select) {
          filter.selectProps = columns.selectProps;
        }

        return filter;
      }),
    formatDataToQuery: (param: any) => {
      const { userList, ...rest } = param;
      const options = {
        ...rest,
        quickQueryParam: param.quickSearch,
        userIds: _.map(userList, 'value'),
      };

      return gcObject.filterEmptyProperty(options);
    },
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        placeholder={'角色编号/角色名称'}
        columns={config.columns}
        filterList={config.filterList}
        rowKey={config.rowKey}
        batchMenu={
          [
            // {
            //   title: '删除',
            //   onClick: () => delRoles(selectedRowKeys.map((item) => Number(item))),
            // },
          ]
        }
        // selectedRowKeys={selectedRowKeys}
        // onSelectedRowKeys={(selectKey) => {
        //   setSelectRowKeys(selectKey);
        // }}
        refreshMarker={refreshMarker}
        mainMenu={[
          {
            title: '新建角色',
            auth: authDict.role_add,
            icon: <BlIcon type="iconxinjiantianjia" />,
            onClick: () => {
              setRoleId(null);
              setVisible(!visible);
            },
            items: [],
          },
        ]}
        formatDataToQuery={config.formatDataToQuery}
        requestFn={(params: any) => fetchData(params)}
        getOperationList={getOperationList}
      />
      {renderRoleActions}
      {visibleRole && (
        <DataFormLayoutForModal
          visible={visibleRole}
          onClose={() => setVisibleRole(false)}
          width={1000}
          content={<RoleDisplay title="权限配置" id={roleId} />}
        />
      )}
      {/* {visibleDetail && (
        <RoleDetail
          id={roleId}
          onEdit={editRole}
          // onDelete={deleteRole}
          onCopy={copyRole}
          onActive={() =>
            roleMsg && handleRoleStatus(roleMsg?.id, roleMsg?.enableFlag, roleMsg?.inUse)
          }
          activeTitle={lookup('common.changeStatusAction', roleMsg?.enableFlag)}
          setAuth={setAuth}
          visible={visibleDetail}
          onClose={() => {
            setVisibleDetail(false);
            setRoleMsg(undefined);
          }}
          onLog={() => {
            if (roleId === logInstanceId) {
              setVisibleLog(true);
            } else {
              setLogInstanceId(roleId);
            }
          }}
        />
      )} */}
      {/* {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={logInstanceId ?? 0}
          objectCode={'Role'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )} */}
    </>
  );
};

export default RoleManagement;
