import { DataFormLayout, DataFormLayoutInfoItem } from '@blacklake-web/layout';
import { FormInstance, Modal, Tabs } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchLayoutDetail } from 'src/api/ytt/layout-domain';
import { fetchCustomFieldGetListByObjectCode } from 'src/api/ytt/metadata-domain/customField';
import { LayoutComponentType, LayoutItemType, PageType } from 'src/dict/customLayout';
import { DataFormLayoutInfoBlock } from 'src/layout/dataForm';
import {
  FieldDTO,
  FormLayoutConfigProps,
  FormLayoutRootConfigProps,
} from 'src/components/customLayout/types';
import {
  formatDataForForm,
  formatLayoutConfigData,
} from 'src/components/customLayout/uitls/dataFormat';
import {
  getInputControlRenderer,
  getRules,
  getSubObjectInputRenderer,
} from 'src/page/customLayout/renderUtils';
import { getFieldsDefaultValueForPreset } from 'src/page/customObject/utils';
import { fetchCustomObjectDetail } from 'src/api/ytt/custom-object-domain';
import {
  fetchStandardBizObjectCustomObjectGetSonObjectAndFieldById,
  FetchStandardBizObjectCustomObjectGetSonObjectAndFieldByIdResponse,
} from 'src/api/ytt/metadata-domain/objectPlatform';
import { FieldCategory, FieldType } from 'src/dict/common';
interface ICalculateForm {
  (layoutConfig: FormLayoutConfigProps): typeof layoutConfig extends FormLayoutRootConfigProps
    ? DataFormLayoutInfoBlock
    : DataFormLayoutInfoItem | React.ReactNode | null;
}

export type SubObjectDTO =
  Required<FetchStandardBizObjectCustomObjectGetSonObjectAndFieldByIdResponse>['data'][number];

export const useCustomLayoutForEdit = (props: {
  /* 用于加载实例数据及布局配置 */
  objectCode: string;
  /* 用于加载实例数据，不传视为创建，传则视为编辑或复制 */
  instanceId?: number;
  layoutId?: number;
  /* 区分复制和编辑，默认false */
  isCopy?: boolean;
  /* antd form实例 */
  form: FormInstance;
  /* 为指定字段传入定制化配置，覆盖默认配置 */
  customInfoItems?: DataFormLayoutInfoItem[];
  /** 不在对象下的字段的渲染 */
  extraInfoItems?: DataFormLayoutInfoItem[];
  /**format 提交数据 */
  formatToApiData?: (data: any) => any;
  /**format form数据 */
  formatFormData?: (data: any) => any;
}) => {
  const {
    objectCode,
    instanceId,
    layoutId,
    customInfoItems,
    extraInfoItems,
    form,
    isCopy,
    formatToApiData,
    formatFormData,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [configLoading, setConfigLoading] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [layoutConfig, setLayoutConfig] = useState<FormLayoutRootConfigProps[]>();
  const [fieldsInfo, setFieldsInfo] = useState<FieldDTO[]>([]);
  const [subObjectInfo, setSubObjectInfo] = useState<SubObjectDTO[]>([]);
  const [layoutData, setlayoutData] = useState<any>({});
  const history = useHistory();

  /**
   * 获取自定义布局中字段默认值
   * @param fieldType 字段类型
   * @param defaultValue 默认值
   * @param choiceValues 选项
   */
  const getDefaultValue = ({
    fieldType,
    defaultValue,
    choiceValues,
  }: {
    fieldType?: number;
    defaultValue?: number | string | (number | string)[];
    choiceValues?: any[];
  }) => {
    if (_.isNil(defaultValue) || _.isNil(choiceValues)) {
      return null;
    }
    const choiceOptions = _.map(choiceValues, (option) => ({
      key: option?.id,
      value: option?.choiceCode,
      label: option?.choiceValue,
    }));

    // TODO 与质检默认值逻辑冲突，将id换成choiceCode，后续待优化
    if (fieldType === FieldType.select) {
      return _.find(choiceOptions, { key: defaultValue })?.value;
    }
    if (fieldType === FieldType.multiSelect) {
      return choiceOptions
        .filter(({ key }) => (defaultValue as (number | string)[]).includes(key))
        .map(({ value }) => value);
    }
    return null;
  };

  /**获取对象下的配置信息，暂定参数为objectCode 可能还会有角色，业务类型 */
  const fetchLayoutConfig = async ({ objectCode }: { objectCode: string }) => {
    setConfigLoading(true);
    const { data } = await fetchLayoutDetail({ id: Number(layoutId) });

    const { data: fieldsData } = await fetchCustomFieldGetListByObjectCode({ objectCode });

    /** 布局缺少对象必填字段 报错提示 */
    if (data?.incomplete) {
      Modal.confirm({
        title: '缺少必填自定义字段，请联系管理员处理。',
        width: 500,
        cancelText: '继续',
        okText: '返回上一页',
        onOk: () => {
          history.goBack();
        },
      });
    }

    setLayoutConfig(formatLayoutConfigData(data?.components));
    setlayoutData(data);
    setFieldsInfo(fieldsData || []);
    if (!isCopy && !instanceId) {
      const layoutFields = data?.components
        ?.filter(({ type }) => type === LayoutItemType.field)
        .map(({ content }) => JSON.parse(content || ''));
      const newFields: any[] = [];

      fieldsData?.forEach(({ fieldCode, fieldType, defaultValue, choiceValues, ...rest }) => {
        const curLayoutField = _.find(layoutFields, { fieldPath: fieldCode });

        if (curLayoutField || fieldCode === '_layout_') {
          newFields.push({
            ...rest,
            fieldCode,
            choiceValues,
            fieldType,
            defaultValue:
              getDefaultValue({
                fieldType,
                defaultValue: curLayoutField?.defaultValue,
                choiceValues,
              }) || defaultValue,
          });
        }
      });

      const formData = formatDataForForm({ fields: newFields, objectCode }, objectCode);

      form.setFieldsValue(formData);
    }
    setConfigLoading(false);
  };

  /**获取业务数据 */
  const fetchData = async ({ instanceId }: { instanceId: number }) => {
    setLoading(true);
    const { data } = await fetchCustomObjectDetail({
      instanceId,
      objectCode,
    });
    const { data: sonObjects } = await fetchStandardBizObjectCustomObjectGetSonObjectAndFieldById({
      objectCode,
    });

    const formData = formatFormData
      ? formatFormData(formatDataForForm(data || {}, objectCode))
      : formatDataForForm(data || {}, objectCode);

    form.setFieldsValue(formData);
    setLoading(false);
    setSubObjectInfo(sonObjects || []);
  };

  /** 提交数据 */
  // TODO 后端还未使用统一接口
  const onFinish = async () => {
    let values = await form.validateFields();

    setConfirmLoading(true);
    if (typeof formatToApiData === 'function') {
      values = formatToApiData(values);
    }
    // TODO 数据更新接口
  };

  useEffect(() => {
    fetchLayoutConfig({ objectCode }).then(() => {
      if (instanceId) {
        fetchData({ instanceId });
      }
    });
  }, []);

  // 递归渲染详情页
  const calculateForm: ICalculateForm = (layoutConf) => {
    // 信息块
    if (layoutConf.type === LayoutItemType.infoBlock) {
      const { title, children, fieldPath } = layoutConf;

      return { title, name: fieldPath?.split('.'), items: (children ?? []).map(calculateForm) };
    }

    // 字段
    if (layoutConf.type === LayoutItemType.field) {
      const { fieldPath, fieldName, required } = layoutConf;
      const fieldInfo = _.find(fieldsInfo, (i) => i.fieldCode === fieldPath) || {};
      const isCustomField = fieldInfo?.fieldCategory === FieldCategory.customFields;
      const customRenderItem = _.find(customInfoItems, (i) => i.name === fieldPath);

      return (
        customRenderItem || {
          ...layoutConf,
          rules: getRules({ ...fieldInfo, isRequired: required }),
          tooltip: fieldInfo?.fieldRemind,
          required: Boolean(required),
          name: isCustomField
            ? ['customFields', `${fieldInfo?.id}`, 'fieldValue']
            : fieldPath?.split('.'),
          label: fieldName,
          render: fieldInfo?.fieldCode ? getInputControlRenderer(fieldInfo, { form }) : '',
        }
      );
    }
    if (layoutConf.type === LayoutItemType.subTable) {
      const { required, fieldPath, objectCode } = layoutConf;
      const customItem = _.find(customInfoItems, (i) => i.name === fieldPath);
      const subObject = subObjectInfo.find((i) => i.objectCode === objectCode);

      return (
        customItem || {
          ...layoutConf,
          required: Boolean(required),
          name: fieldPath?.split('.'),
          label: subObject?.referName,
          isFullLine: true,
          render: getSubObjectInputRenderer(
            subObject || {},
            ['sonObjects', 'subObjectInfo?.code'],
            form,
          ),
        }
      );
    }
    if (layoutConf.type === LayoutItemType.tabpane) {
      const { title, children } = layoutConf;
      const info = formatLayoutConfigData(children ?? []).map(calculateForm);

      return (
        <Tabs.TabPane key={`tabpane-${title}`} tab={title}>
          <DataFormLayout info={info} />
        </Tabs.TabPane>
      );
    }
    return null;
  };

  let containerRender: JSX.Element | null = null;

  if (layoutConfig?.filter((i) => i.type === LayoutItemType.tabs)?.length) {
    containerRender = (
      <>
        {layoutConfig
          ?.filter((i) => i.type === LayoutItemType.tabs)
          .map(({ children }) => (
            <Tabs type="card">{children?.map(calculateForm)}</Tabs>
          ))}
      </>
    );
  }

  const getInfo = () => {
    const configInfo = (layoutConfig ?? []).map(calculateForm);
    if (containerRender) {
      return [];
    }

    if (extraInfoItems && extraInfoItems?.length) {
      configInfo[0] = {
        ...(configInfo[0] || {}),
        items: (configInfo[0]?.items ?? []).concat(extraInfoItems),
      };
      return configInfo;
    }
    return configInfo;
  };

  return {
    loading,
    configLoading,
    confirmLoading: false,
    info: getInfo(),
    containerRender,
    onFinish,
  };
};
