import { FormInstance } from 'antd';
import { useRef } from 'react';
import { CheckItem } from 'src/page/quality/models/dto';
import { isUnQuality } from 'src/page/quality/models/utils/qcTask';
import { InputRender } from '../InputRender';

interface Props {
  checkItem: CheckItem;
  form: FormInstance;
  fieldName: number;
  valueIsUnQuality?: boolean;
}

export const RecordValue = ({ checkItem, form, fieldName, valueIsUnQuality }: Props) => {
  const inputRef = useRef<any>(null);

  return (
    <InputRender
      {...checkItem}
      isUnQuality={checkItem.isUnQuality || valueIsUnQuality}
      fieldName={[fieldName]}
      ref={inputRef}
      onChange={() => {
        const currentResult = form.getFieldValue(['reportValues', fieldName]);

        form.setFields([
          {
            name: ['reportValues', fieldName, 'isUnQuality'],
            value: isUnQuality({
              result: currentResult,
              checkItem,
            }),
          },
        ]);
        inputRef.current?.refresh?.(
          isUnQuality({
            result: currentResult,
            checkItem,
          }),
        );
      }}
    />
  );
};
