import React, { useEffect, useState } from 'react';
import { Form, message as Message } from 'antd';
import { formatStoreValue, throttleFn } from '../utils';
import { match, RouteComponentProps } from 'react-router-dom';
import StoreBaseInfo from '../components/storeCreateAndEditBaseInfo';
import {
  fetchStorageWarehouseCheckEnableStorageLocation,
  fetchStorageWarehouseInfo,
  fetchStorageWarehouseModify,
} from 'src/api/ytt/storage-domain/warehouse';

interface StoreEditProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const StoreEdit = (props: StoreEditProps) => {
  const { match, history } = props;
  const storeId = Number(match?.params?.id);

  const [detailData, setDetailData] = useState([] as any);

  const [modalForm] = Form.useForm();

  const [qcCtrl, setQcCtrl] = useState(false);
  const [checkResult, setCheckResult] = useState(false);

  const setQcCtrlFn = (e: boolean) => {
    setQcCtrl(e);
  };

  const fetchData = async () => {
    try {
      const { data } = await fetchStorageWarehouseInfo({ id: storeId }, { legacy: true });

      const enable = data?.enableStorageLocation ? 0 : 1;

      const { data: Data } = await fetchStorageWarehouseCheckEnableStorageLocation({
        id: storeId,
        enableStorageLocation: enable,
      });

      setCheckResult(Data?.checkResult ?? false);

      if (data) {
        setDetailData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();

      const newValue = formatStoreValue(value);
      const jsonData = { ...newValue, id: storeId };

      const { code, message } = await fetchStorageWarehouseModify(jsonData);

      if (code === 200) {
        Message.success('编辑成功');
        history.goBack();
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [storeId]);

  return (
    <StoreBaseInfo
      title="编辑仓库"
      initialData={detailData}
      form={modalForm}
      onCancel={() => {
        history.goBack();
      }}
      onFinish={throttleFn(handleFinish)}
      edit
      qcCtrl={qcCtrl}
      setQcCtrlFn={setQcCtrlFn}
      checkResult={checkResult}
    />
  );
};

export default StoreEdit;
