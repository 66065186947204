const getbaseNodeInfo = (isEdit: boolean) => ({
  shape: isEdit ? 'custom-node-edit' : 'custom-node',
  width: 140,
  height: 40,
});

const baseEdgeInfo = {
  shape: 'custom-edge',
  router: {
    name: 'orth',
  },
};

const initialData = {
  nodes: [
    {
      id: '1',
      label: '检验任务',
      ...getbaseNodeInfo(false),
    },
    {
      id: '2',
      label: '检验方案',
      ...getbaseNodeInfo(false),
    },
    {
      id: '3',
      label: '物料',
      ...getbaseNodeInfo(false),
    },
    {
      id: '4',
      label: '供应商',
      ...getbaseNodeInfo(false),
    },
    {
      id: '5',
      label: '工序',
      ...getbaseNodeInfo(false),
    },
    {
      id: '6',
      label: '检验项',
      ...getbaseNodeInfo(false),
    },
    {
      id: '7',
      label: '客户',
      ...getbaseNodeInfo(false),
    },
  ],
  edges: [
    {
      source: '1',
      target: '2',
      label: '1:1',
      attrs: {
        label: {
          text: '1:1',
        },
      },
      ...baseEdgeInfo,
    },
    {
      source: '1',
      target: '3',
      label: '1:N',
      ...baseEdgeInfo,
    },
    {
      source: '2',
      target: '5',
      label: '1:N',
      ...baseEdgeInfo,
    },
    {
      source: '2',
      target: '4',
      label: '1:N',
      ...baseEdgeInfo,
    },
    {
      source: '2',
      target: '6',
      label: '1:N',
      ...baseEdgeInfo,
    },
    {
      source: '2',
      target: '7',
      label: '1:N',
      ...baseEdgeInfo,
    },
  ],
};

const getBasicConfig = (isEdit: boolean) => {
  return {
    height: 500,
    width: 800,
    edgeMovable: false,
    edgeLabelMovable: false,
    grid: isEdit,
    interacting: {
      nodeMovable: false,
    },
    selecting: isEdit
      ? {
          enabled: true,
          multiple: false,
          rubberband: true,
          movable: false,
          showNodeSelectionBox: true,
          showEdgeSelectionBox: false,
        }
      : false,
    history: {
      enabled: true,
    },
    scroller: isEdit
      ? {
          enabled: true,
          pannable: false,
          pageVisible: false,
          padding: 0,
        }
      : false,
    mousewheel: {
      enabled: true,
      modifiers: ['ctrl', 'meta'],
    },
  };
};

export { initialData, baseEdgeInfo, getbaseNodeInfo, getBasicConfig };
