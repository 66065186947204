import { Form, Input, FormInstance } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import { BlSortFormList } from 'src/components';
import { numberMinMaxCheck } from 'src/utils/formValidators';
import _ from 'lodash';

interface Props {
  form: FormInstance;
  name: string;
}

// 表头需要加上必填标志，现有组件没有
const requireMark = <span style={{ color: 'red' }}>*</span>;
const width = '100%';
const MinBatch = 'minBatch';
const MaxBatch = 'maxBatch';
const sampleCount = 'sampleCount';

function SampleList(props: Props) {
  const { form, name } = props;
  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    // 字段项相同属性
    const getOptions = (field: any, fieldName: string) => ({
      fieldKey: [field.fieldKey, fieldName],
      name: [field.name, fieldName],
      validateFirst: true,
      validateTrigger: ['onChange', 'onBlur'],
      style: { marginBottom: '0' },
    });

    return [
      {
        dataIndex: MinBatch,
        title: <div>{requireMark} 最小批量</div>,
        render: (text: string, field: any) => (
          <div key={field.key}>
            <Form.Item
              {...getOptions(field, MinBatch)}
              rules={[
                { required: true, message: '请输入最小批量' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    max: 9999999,
                    maxAllowEqual: false,
                    isInteger: true,
                  }),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const prevRowMaxBatch = getFieldValue([name, field.name - 1, MaxBatch]);

                    if (!prevRowMaxBatch || Number(prevRowMaxBatch) + 1 === Number(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('区间必须连续'));
                  },
                }),
              ]}
            >
              <Input style={{ width }} placeholder="请输入" />
            </Form.Item>
          </div>
        ),
      },
      {
        dataIndex: MaxBatch,
        title: <div>{requireMark} 最大批量</div>,
        render: (text: string, field: any) => (
          <div key={field.key}>
            <Form.Item
              {...getOptions(field, MaxBatch)}
              dependencies={[[name, field.name, MinBatch]]}
              rules={[
                { required: true, message: '请输入最大批量' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    max: 999999999999999,
                    isInteger: true,
                  }),
                },
                ({ getFieldValue }) => {
                  return {
                    validator(_, value) {
                      if (Number(getFieldValue([name, field.name, MinBatch])) < Number(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('最大批量必须大于最小批量'));
                    },
                  };
                },
              ]}
            >
              <Input style={{ width }} placeholder="请输入" />
            </Form.Item>
          </div>
        ),
      },
      {
        dataIndex: sampleCount,
        title: <div>{requireMark} 样本量</div>,
        render: (text: string, field: any) => (
          <div key={field.key}>
            <Form.Item
              {...getOptions(field, sampleCount)}
              dependencies={[
                [name, field.name, MinBatch],
                [name, field.name, MaxBatch],
              ]}
              rules={[
                { required: true, message: '请输入样本量' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    max: 9999999,
                    maxAllowEqual: false,
                    isInteger: true,
                  }),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const max = getFieldValue([name, field.name, MaxBatch]);

                    if (!value || Number(max) >= Number(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('样本量必须小于等于最大批量'));
                  },
                }),
              ]}
            >
              <Input style={{ width }} placeholder="请输入" />
            </Form.Item>
          </div>
        ),
      },
      {
        dataIndex: 'action',
        title: '操作',
        render: (text: string, field: any) => (
          <div key={field.key}>
            <a
              onClick={() => {
                form.resetFields([
                  [name, field.name, MinBatch],
                  [name, field.name, MaxBatch],
                  [name, field.name, sampleCount],
                ]);
              }}
            >
              清空
            </a>
          </div>
        ),
      },
    ];
  };

  return (
    <BlSortFormList
      buttonText="添加区间"
      form={form}
      name={name}
      renderColumns={() => getColumns()}
    />
  );
}

export default SampleList;
