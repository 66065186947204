import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { RecordListLayout } from 'src/layout';
import CreateAndEdit from './createAndEdit/createAndEdit';
import Details from './details/details';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { useDispatch } from 'react-redux';
import { Tooltip, BlIcon, TagList, SingleOperationLogComponent } from 'src/components';
import { replaceSign, FIELDS } from 'src/utils/constants';
import { CRUD } from 'src/dict/common';
import { fieldTypeList } from 'src/utils';
import lookup from 'src/dict';
import { fetchResourcesLabelList } from 'src/api/ytt/resource-domain/resourceTag';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import LocalStorage from 'src/utils/localStorage';
import authDict from 'src/utils/auth';

interface TableRowType {
  content?: string;
  index?: number;
  id: number;
}
const ResourceTagList = () => {
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [actionType, setActionType] = useState<string>('');
  const [resId, setResId] = useState<number | undefined>(undefined);
  const [createAndeditVisible, setCreateAndeditVisible] = useState(false);
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const [refreshDetailsMarker, setRefreshDetailsMarker] = useState<number | undefined>(undefined);
  const [detailVisible, setDetailVisible] = useState<boolean>(false);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);

  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  // const [delForm] = Form.useForm();

  const showDetail = (id: number) => {
    setResId(id);
    setDetailVisible(true);
  };

  useEffect(() => {
    const flag: any = LocalStorage.get(FIELDS.RESOURCE_TAG_IS_CREATE);

    if (flag === true) {
      setResId(undefined);
      setActionType(CRUD.create);
      setCreateAndeditVisible(true);
      LocalStorage.remove(FIELDS.RESOURCE_TAG_IS_CREATE);
    }
  }, []);

  const dataColumns = [
    {
      title: '标签项',
      dataIndex: 'name',
      type: fieldTypeList.text,
      isFilter: true,
      sorter: true,
      fixed: 'left',
      width: 200,
      render: (value: string, record: any, index: number, config: any) => (
        <a onClick={() => showDetail(record.groupId)}>
          <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
        </a>
      ),
    },
    {
      title: '业务类型',
      dataIndex: 'businessType',
      type: fieldTypeList.text,
      width: 90,
      render: (value: number) => lookup('resources.resourceTagBusinessType', value),
    },
    {
      title: '标签值',
      dataIndex: 'childs',
      key: 'groupId',
      type: fieldTypeList.text,
      isFilter: true,
      width: 140,
      render: (text: any) => {
        if (_.isEmpty(text)) return replaceSign;

        return (
          <TagList
            dataSource={text.map((label: any, value: number) => {
              return {
                label: label.content,
                value,
              };
            })}
          />
        );
      },
    },
    {
      title: '备注',
      dataIndex: 'remarks',
      type: fieldTypeList.textArea,
      width: 140,
      render: (value: string) => value,
    },
  ];
  const filterList = dataColumns
    .filter((i) => i.isFilter)
    .map((column) => {
      const filter = {
        label: column.title,
        name: column.dataIndex,
        type: column.type,
      } as any;

      return filter;
    });
  // const batchMenu = [
  //   {
  //     title: '删除',
  //     onClick: (onSuccess: any, onFail: any) => {},
  //   },
  // ];
  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      auth: authDict.resourcelabel_export,
      icon: <BlIcon type="icondaochu" />,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.resources_tags,
        });
      },
    },
    {
      title: '新建标签',
      auth: authDict.resourcelabel_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        setResId(undefined);
        setActionType(CRUD.create);
        setCreateAndeditVisible(true);
      },
      items: [
        {
          title: '导入',
          auth: authDict.resourcelabel_import,
          showExport: true,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.resources_tags,
            });
          },
        },
      ],
    },
  ];
  const getOperationList = ({ groupId }: { groupId: number }) => {
    return _.compact([
      {
        title: lookup('crud', CRUD.view),
        auth: authDict.resourcelabel_detail,
        onClick: () => showDetail(groupId),
      },
      {
        title: lookup('crud', CRUD.edit),
        auth: authDict.resourcelabel_edit,
        onClick: () => {
          setResId(groupId);
          setActionType(CRUD.edit);
          setCreateAndeditVisible(true);
        },
      },
      {
        title: '操作记录',
        auth: authDict.resourcelabel_operrecord,
        onClick: () => {
          setResId(groupId);
          setVisibleLog(true);
        },
      },
    ]);
  };
  const formatDataToQuery = (params: any) => {
    const { childs, ...rest } = params;
    const data = {
      content: childs,
      ...rest,
    };

    dispatch.excelImport.updateBusinessData({ businessData: data });
    return data;
  };
  const formatDataToDisplay = (params: any = {}) => {
    const retData = { ...params };

    return retData;
  };
  const getData = async (params: any) => {
    const res: any = await fetchResourcesLabelList(params);
    const arr: any = [];

    res?.data?.list?.forEach((item: any) => {
      const { children, ...rest } = item;

      arr.push({
        childs: children,
        ...rest,
      });
    });

    res.data.list = arr;

    return res;
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        columns={dataColumns}
        filterList={filterList}
        rowKey="groupId"
        // batchMenu={batchMenu}
        mainMenu={mainMenu}
        // expandable={{ childrenColumnName: undefined }}
        getOperationList={getOperationList}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={formatDataToDisplay}
        selectedRowKeys={selectedRowKeys}
        useColConfig={false}
        onSelectedRowKeys={(selectKey: any) => {
          setSelectRowKeys(selectKey.map((key: any) => Number(key)));
        }}
        requestFn={(parmas: any) => getData(parmas)}
        refreshMarker={refreshMarker}
        // placeholder={'请输入标签名称或编号'} // 快速搜索input placeholder
      />
      {createAndeditVisible && (
        <CreateAndEdit
          id={resId}
          type={actionType}
          visible={createAndeditVisible}
          onCancel={() => setCreateAndeditVisible(false)}
          refreshTable={() => {
            setRefreshMarker(new Date().getTime());
          }}
          refreshDetails={() => {
            setRefreshDetailsMarker(new Date().getTime());
          }}
        />
      )}
      {detailVisible && (
        <Details
          id={Number(resId)}
          visible={detailVisible}
          onCancel={() => setDetailVisible(false)}
          showEdit={() => {
            setResId(Number(resId));
            setCreateAndeditVisible(true);
            setActionType(CRUD.edit);
          }}
          showOperationRecord={() => {
            setResId(Number(resId));
            setVisibleLog(true);
          }}
          refreshDetailsMarker={refreshDetailsMarker}
          refreshTable={() => {
            setRefreshMarker(new Date().getTime());
          }}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={Number(resId)}
          objectCode={'ResourceLabel'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(ResourceTagList);
