import authDict from 'src/utils/auth';
import CustomMenu from '.';
import EditCustomMenu from './edit';
import { path } from './constants';

export const customMenuRoute = {
  path: path.list,
  auth: undefined,
  menu: '菜单管理',
  breadcrumb: '菜单管理',
  component: CustomMenu,
  children: [
    {
      path: path.create,
      auth: authDict.customMenu_add,
      breadcrumb: '新建菜单',
      component: EditCustomMenu,
    },
    {
      path: path.edit,
      auth: authDict.customMenu_edit,
      breadcrumb: '编辑菜单',
      component: EditCustomMenu,
    },
    {
      path: path.copy,
      auth: authDict.customMenu_add,
      breadcrumb: '复制菜单',
      component: EditCustomMenu,
    },
  ],
};
