import ProcessRouting from './processRouting';
import Process from './process';
import CreateProcess from './process/create';
import ProcessDetailPage from './process/detailPage';
import EditProcess from './process/edit';
import BomList from './bom/list';
import BomCreateAndEdit from './bom/createAndEdit';
import BomDeatil from './bom/detail';
import ProcessRoutingAction from './processRouting/action';
import ProcessRoutingDetail from './processRouting/detail';
import WorkCenterList from 'src/page/knowledgeManagement/basicData/workCenter/list';
import DetailsBaseInfo from 'src/page/knowledgeManagement/basicData/workCenter/details/detailsBaseInfo';
import WorkCenterBaseInfo from 'src/page/knowledgeManagement/basicData/workCenter/createAndEdit/baseInfo';
import authDict from 'src/utils/auth';
import AlternativePlanList from 'src/page/knowledgeManagement/engineerData/alternativePlan/List';
import AlternativePlanDetail from 'src/page/knowledgeManagement/engineerData/alternativePlan/Detail';
import CreateOrEditAlternativePlan from 'src/page/knowledgeManagement/engineerData/alternativePlan/CreateOrEdit';

export const ENGINEER_PATH = '/productionPlanning/engineering';

const engineerDataRouteConfig = [
  {
    path: ENGINEER_PATH,
    menu: '工程数据',
    breadcrumb: '工程数据',
    redirectPath: `${ENGINEER_PATH}/process`,
    children: [
      {
        path: `${ENGINEER_PATH}/Bom`,
        menu: '物料清单',
        component: BomList,
        logPage: true,
        breadcrumb: '物料清单',
        auth: authDict.bom_view,
        children: [
          {
            path: `${ENGINEER_PATH}/Bom/create`,
            component: BomCreateAndEdit,
            auth: authDict.bom_add,
            breadcrumb: '创建',
          },
          {
            path: `${ENGINEER_PATH}/Bom/edit/:id`,
            component: BomCreateAndEdit,
            auth: authDict.bom_edit,
            breadcrumb: '编辑',
          },
          {
            path: `${ENGINEER_PATH}/Bom/copy/:id`,
            component: BomCreateAndEdit,
            auth: authDict.bom_add,
            breadcrumb: '复制',
          },
          {
            path: `${ENGINEER_PATH}/Bom/detail/:id`,
            component: BomDeatil,
            auth: authDict.bom_detail,
            breadcrumb: '详情',
          },
        ],
      },
      {
        path: `${ENGINEER_PATH}/routing`,
        menu: '工艺路线',
        component: ProcessRouting,
        breadcrumb: '工艺路线',
        logPage: true,
        auth: authDict.routing_view,
        children: [
          {
            path: `${ENGINEER_PATH}/routing/create`,
            component: ProcessRoutingAction,
            auth: authDict.routing_add,
            breadcrumb: '创建工艺路线',
          },
          {
            path: `${ENGINEER_PATH}/routing/:id/edit`,
            component: ProcessRoutingAction,
            auth: authDict.routing_edit,
            breadcrumb: '编辑工艺路线',
          },
          {
            path: `${ENGINEER_PATH}/routing/:id/copy`,
            component: ProcessRoutingAction,
            auth: authDict.routing_add,
            breadcrumb: '复制工艺路线',
          },
          {
            path: `${ENGINEER_PATH}/routing/:id/detail`,
            component: ProcessRoutingDetail,
            auth: authDict.routing_detail,
            breadcrumb: '工艺路线详情',
          },
        ],
      },
      {
        path: `${ENGINEER_PATH}/process`,
        auth: authDict.process_view,
        menu: '工序',
        component: Process,
        breadcrumb: '工序',
        logPage: true,
        children: [
          {
            path: `${ENGINEER_PATH}/process/create`,
            auth: authDict.process_add,
            component: CreateProcess,
            breadcrumb: '新建工序',
          },
          {
            path: `${ENGINEER_PATH}/process/:id/detail`,
            auth: authDict.process_detail,
            component: ProcessDetailPage,
            breadcrumb: '详情',
          },
          {
            path: `${ENGINEER_PATH}/process/:id/edit`,
            auth: authDict.process_edit,
            component: EditProcess,
            breadcrumb: '编辑',
          },
        ],
      },
      {
        path: `${ENGINEER_PATH}/workCenter`,
        component: WorkCenterList,
        menu: '工作中心',
        logPage: true,
        breadcrumb: '工作中心',
        auth: authDict.workcenter_view,
        children: [
          {
            path: `${ENGINEER_PATH}/workCenter/detail/:id`,
            component: DetailsBaseInfo,
            breadcrumb: '详情',
            auth: authDict.workcenter_detail,
          },
          {
            path: `${ENGINEER_PATH}/workCenter/create`,
            component: WorkCenterBaseInfo,
            breadcrumb: '创建',
            auth: authDict.workcenter_add,
          },
          {
            path: `${ENGINEER_PATH}/workCenter/edit/:id`,
            component: WorkCenterBaseInfo,
            breadcrumb: '编辑',
            auth: authDict.workcenter_edit,
          },
          {
            path: `${ENGINEER_PATH}/workCenter/copy/:id`,
            component: WorkCenterBaseInfo,
            breadcrumb: '复制',
            auth: authDict.workcenter_add,
          },
        ],
      },
      {
        path: `${ENGINEER_PATH}/alternativePlan`,
        component: AlternativePlanList,
        menu: '替代方案',
        logPage: true,
        breadcrumb: '替代方案',
        auth: authDict.alternativeplan_view,
        children: [
          {
            path: `${ENGINEER_PATH}/alternativePlan/detail/:id`,
            component: AlternativePlanDetail,
            breadcrumb: '替代方案详情',
            auth: authDict.alternativeplan_detail,
          },
          {
            path: `${ENGINEER_PATH}/alternativePlan/create`,
            component: CreateOrEditAlternativePlan,
            breadcrumb: '新建替代方案',
            auth: authDict.alternativeplan_add,
          },
          {
            path: `${ENGINEER_PATH}/alternativePlan/edit/:id`,
            component: CreateOrEditAlternativePlan,
            breadcrumb: '编辑替代方案',
            auth: authDict.alternativeplan_edit,
          },
          {
            path: `${ENGINEER_PATH}/alternativePlan/copy/:id`,
            component: CreateOrEditAlternativePlan,
            breadcrumb: '新建替代方案',
            auth: authDict.alternativeplan_add,
          },
        ],
      },
    ],
  },
];

export default engineerDataRouteConfig;
