import React, { useEffect } from 'react';
import {
  Button,
  DatePicker,
  Form,
  FormItemProps,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { checkTwoSidesTrim, numberAlphabetSpecialSymbols } from 'src/utils/formValidators';
import { BcAttachmentForm, NumberRulesStandardHook, SearchSelect } from 'components';
import { appDict, appEnum } from 'src/dict';
import UserDeptRoleSelect from 'src/page/organization/components/userDeptRoleSelect';
import ResourceClassificationCascader from 'src/page/resource/components/ResourceClassificationCascader';
import { fetchResourcesList } from 'src/api/ytt/resource-domain/resource';
import { fetchMaintenanceCaseDetail } from 'src/api/ytt/resource-domain/maintenancePlan';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { formatDataToForm as formatCaseDataToForm } from 'src/page/resource/maintenancePlan/actions/baseForm';
import { gcTime } from 'src/utils';
import { getStandardWorkTime, setStandardWorkTime } from 'src/page/resource/maintenancePlan/utils';
//
import { MAX_INPUT_LENGTH, MAX_TEXTAREA_LENGTH, MAX_USER_COUNT } from '../constants';
import {
  ContentProps,
  MaintenanceCreateApiType,
  MaintenanceDetailType,
  IBaseInfoForm,
} from '../index.d';
import { BL_BUSINESS_CODE, BL_BUSINESS_NAME } from '../config';
import { MaintenanceTreeStateParams } from '../../components/maintenanceTree';
import moment from 'moment';

export const formatDataToApi = (data: IBaseInfoForm, type?: string): MaintenanceCreateApiType => {
  const {
    id,
    code = '',
    bizId,
    isUseAutocode = false,
    classificationId,
    standardWorkTimeH,
    standardWorkTimeM,
    standardWorkTimeS,
    planExecutorList,
    currentExecutorList,
    maintenanceCaseId,
    executeIds,
    planStartTime,
    planEndTime,
    ...resData
  } = data;

  return {
    ...resData,
    id: type === appEnum.Common.CRUD.edit ? id : undefined,
    code: isUseAutocode ? '' : code,
    classificationId: !_.isEmpty(classificationId) ? _.last(classificationId) : undefined,
    planExecutorList: _.map(planExecutorList, ({ value, type }) => ({
      bizId: value,
      bizType: type,
    })),
    currentExecutorList: _.map(currentExecutorList, ({ value }) => ({
      bizId: value,
      bizType: 0,
    })),
    bizId: !_.isNil(bizId) ? JSON.parse(bizId.value)?.id : undefined,
    standardWorkTime: setStandardWorkTime(standardWorkTimeH, standardWorkTimeM, standardWorkTimeS),
    executeIds: _.compact(_.map(executeIds, 'value')),
    maintenanceCaseId: _.get(maintenanceCaseId, 'value', 0),
    planStartTime: !_.isNil(planStartTime) ? _.toNumber(gcTime.formatToUnix(planStartTime)) : 0,
    planEndTime: !_.isNil(planEndTime) ? _.toNumber(gcTime.formatToUnix(planEndTime)) : 0,
  };
};

export const formatDataToForm = (data: MaintenanceDetailType): IBaseInfoForm => {
  const {
    name = '',
    businessType = appEnum.Resources.ResourceBusinessType.equipment,
    bizVO,
    bizType = appEnum.Resources.MaintenanceType.maintain,
    classificationList,
    executeType = appEnum.Resources.MaintenanceExecuteType.reportTemplate,
    standardWorkTime,
    planExecutorList,
    currentExecutorList,
    scanCodeFlag = appEnum.Common.YNB.no,
    reportTemplateList,
    sopList,
    maintenanceCase,
    planStartTime,
    planEndTime,
    ...resData
  } = data;

  const {
    H: standardWorkTimeH,
    M: standardWorkTimeM,
    S: standardWorkTimeS,
  } = getStandardWorkTime(standardWorkTime);

  const isSopExecute = executeType === appEnum.Resources.MaintenanceExecuteType.SOP;

  return {
    ...resData,
    name,
    businessType,
    bizType,
    bizId: !_.isNil(bizVO)
      ? {
          label: bizVO.name,
          value: JSON.stringify({
            ..._.pick(bizVO, ['id', 'level', 'hasChildren', 'code']),
            locationName: _.get(bizVO, 'location.name'),
          }),
        }
      : undefined,
    executeType,
    scanCodeFlag,
    classificationId: !_.isEmpty(classificationList)
      ? _.compact(_.map(classificationList, 'id'))
      : undefined,
    planExecutorList: _.map(planExecutorList, ({ name, bizId, bizType }) => ({
      label: name,
      value: bizId,
      type: bizType,
    })),
    currentExecutorList: _.map(currentExecutorList, ({ name, bizId }) => ({
      label: name,
      value: bizId,
    })),
    maintenanceCaseId: !_.isNil(maintenanceCase)
      ? { label: maintenanceCase?.name, value: maintenanceCase?.id }
      : undefined,
    standardWorkTimeH,
    standardWorkTimeM,
    standardWorkTimeS,
    planStartTime: !_.isNil(planStartTime) ? moment(planStartTime) : undefined,
    planEndTime: !_.isNil(planEndTime) ? moment(planEndTime) : undefined,
    executeIds: isSopExecute
      ? _.map(sopList, ({ id, name }) => ({ label: name, value: id }))
      : _.map(reportTemplateList, ({ id, name }) => ({ label: name, value: id })),
  };
};

/**
 * 获取维保方案中 任务需要的信息
 * @param params
 */
export const getMaintenanceCaseForTask = async (maintenanceCaseId?: number) => {
  try {
    if (maintenanceCaseId) {
      const res = await fetchMaintenanceCaseDetail({ id: maintenanceCaseId });

      const data = _.get(res, 'data');

      if (!_.isNil(data)) {
        const {
          executeType,
          executeIds,
          planExecutorList,
          standardWorkTimeH,
          standardWorkTimeM,
          standardWorkTimeS,
          channelType,
          classificationId,
          scanCodeFlag,
          bizId,
          fileIds,
        } = formatCaseDataToForm(data);

        return {
          executeType,
          executeIds,
          planExecutorList,
          standardWorkTimeH,
          standardWorkTimeM,
          standardWorkTimeS,
          channelType,
          classificationId,
          scanCodeFlag,
          bizId,
          fileIds,
        };
      }

      return {};
    }

    return {};
  } catch (error) {
    return {};
  }
};

const ActionContent = (props: ContentProps) => {
  const { form, type, refreshMarker } = props;

  const listCacheData: MaintenanceTreeStateParams = JSON.parse(
    sessionStorage.getItem(BL_BUSINESS_CODE) ?? '{}',
  );

  useEffect(() => {
    initailBiz();
  }, []);

  /** 默认空的维保方案会同步为空的字段对象 */
  const defaultEmptyCaseObj = {
    maintenanceCaseId: undefined,
    executeType: undefined,
    executeIds: undefined,
  };

  /**
   * 创建时，需要根据列表页所选资源初始化维保目标
   */
  const initailBiz = () => {
    // 创建时
    const isCreate = type === appEnum.Common.CRUD.create;

    // 所选为资源类型
    const isEquipment = listCacheData?.target?.type === 'Equipment';

    const equipmentId = listCacheData?.target?.id;
    const equipmentType = listCacheData?.businessType;

    if (isCreate && isEquipment && equipmentId) {
      fetchResourcesList({ idList: [_.toNumber(equipmentId)], type: equipmentType }).then((res) => {
        const bizInfo = _.head(res?.data?.list);

        if (!_.isNil(bizInfo)) {
          const bizId = {
            label: bizInfo.name,
            value: JSON.stringify(
              _.pick(bizInfo, ['id', 'level', 'hasChildren', 'code', 'locationName']),
            ),
          };

          form.setFieldsValue({ bizId });
        }
      });
    }
  };

  const validatorWorkTime = () => {
    const { standardWorkTimeH, standardWorkTimeM, standardWorkTimeS } = form.getFieldsValue();

    // 存在一个或多个0且总和是0的时候
    if (
      (standardWorkTimeM ?? 0) + (standardWorkTimeH ?? 0) + (standardWorkTimeS ?? 0) === 0 &&
      (standardWorkTimeM === 0 || standardWorkTimeH === 0 || standardWorkTimeS === 0)
    ) {
      return Promise.reject('标准工时总时长不能为0');
    }
    return Promise.resolve('');
  };

  /**
   * 自动设置 计划报废时间
   */
  const setPlanEndTime = () => {
    setTimeout(() => {
      const {
        planStartTime,
        standardWorkTimeH,
        standardWorkTimeM,
        standardWorkTimeS,
        planEndTime,
      } = form.getFieldsValue();

      const totalTime = setStandardWorkTime(
        standardWorkTimeH,
        standardWorkTimeM,
        standardWorkTimeS,
      );

      if (_.isNil(planEndTime) && !_.isNil(planStartTime) && totalTime) {
        const realPlanEndTime = moment(planStartTime).add(totalTime, 's');

        form.setFieldsValue({
          planEndTime: realPlanEndTime,
          standardWorkTimeH: standardWorkTimeH ?? 0,
          standardWorkTimeM: standardWorkTimeM ?? 0,
          standardWorkTimeS: standardWorkTimeS ?? 0,
        });
      }
    });
  };

  /**
   * 维保业务对象 change
   * 维保业务对象变动会重置维保方案，进而影响一堆维保方案相关的项
   * @param value
   */
  const handleBizTypeChange = () => {
    form.setFieldsValue({ bizId: undefined, classificationId: undefined, ...defaultEmptyCaseObj });
  };

  /**
   * 维保方案 change
   * @param value
   */
  const handleMaintenanceCaseChange = (value?: { value: number; label: string }) => {
    if (_.isNil(value)) {
      form.setFieldsValue(defaultEmptyCaseObj);
      return;
    }

    const maintenanceCaseId = _.get(value, 'value');

    getMaintenanceCaseForTask(maintenanceCaseId)
      .then((caseData) => {
        // 没有拿到维保方案详细信息时，判定为错误数据，置空维保方案
        if (_.isEmpty(caseData)) {
          form.setFieldsValue(defaultEmptyCaseObj);
        } else {
          form.setFieldsValue(caseData);
        }
      })
      .catch(() => {
        form.setFieldsValue(defaultEmptyCaseObj);
      });
  };

  const getBaseInfo = (): DataFormLayoutInfoBlock => {
    const baseInfo = {
      title: '基本信息',
      column: 2,
      items: [
        {
          label: '',
          name: 'id',
          span: 2,
          hidden: true,
          render: () => <Input />,
        },
        ...NumberRulesStandardHook({
          label: `${BL_BUSINESS_NAME}编号`,
          form,
          edit: type === 'edit',
          objectCode: OBJECT_OF_CODE.MaintenanceTask,
          fieldCode: 'code',
          refreshMarker,
          rules: [
            { max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` },
            numberAlphabetSpecialSymbols,
          ],
        }),
        {
          label: `${BL_BUSINESS_NAME}名称`,
          name: 'name',
          rules: [
            { required: true, message: `请输入${BL_BUSINESS_NAME}名称` },
            { max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` },
            { validator: checkTwoSidesTrim },
          ],
          render: () => <Input placeholder={`请输入${BL_BUSINESS_NAME}名称`} />,
        },
        {
          label: '标识码',
          name: 'identificationCode',
          rules: [{ max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` }],
          render: () => <Input placeholder="请输入标识码" />,
        },
        {
          label: '维保业务对象',
          name: 'bizType',
          initialValue: listCacheData?.businessType,
          rules: [{ required: true, message: '请选择业务类型' }],
          render: () => (
            <Select
              placeholder="请选择业务类型"
              options={appDict.resources.RESORCE_BUSINESS_TYPE_MAP}
              onChange={handleBizTypeChange}
            />
          ),
        },
        {
          label: '业务类型',
          name: 'businessType',
          initialValue: appEnum.Resources.MaintenanceType.maintain,
          rules: [{ required: true, message: '请选择业务类型' }],
          render: () => (
            <Select
              placeholder="请选择业务类型"
              options={appDict.resources.maintenanceType}
              onChange={() => {
                form.setFieldsValue(defaultEmptyCaseObj);
              }}
            />
          ),
        },
        {
          label: '维保方案',
          dependencies: ['bizType', 'businessType'],
          required: true,
          render: () => () => {
            const bizType = form.getFieldValue('bizType') ?? listCacheData?.businessType;
            const businessType = form.getFieldValue('businessType') ?? listCacheData?.businessType;

            return (
              <Form.Item
                name="maintenanceCaseId"
                rules={[{ required: true, message: '请选择维保方案' }]}
                style={{ marginBottom: 0 }}
              >
                <SearchSelect
                  fetchType={'maintenanceCase'}
                  placeholder="请选择维保方案"
                  params={{ status: appEnum.Common.YNB.yes, bizType, businessType }}
                  onChange={handleMaintenanceCaseChange}
                />
              </Form.Item>
            );
          },
        },
        {
          label: '维保目标',
          dependencies: ['bizType'],
          render: () => () => {
            const bizType = form.getFieldValue('bizType');

            return (
              <Form.Item name="bizId" style={{ marginBottom: 0 }}>
                <SearchSelect
                  fetchType="resourceCompleteInfo"
                  params={{
                    type: bizType,
                    statusList: [
                      appEnum.Common.UsageStatus.enabled,
                      appEnum.Common.UsageStatus.disabled,
                    ],
                  }}
                  placeholder="请输入"
                />
              </Form.Item>
            );
          },
        },
        {
          label: '维保目标编号',
          shouldUpdate: true,
          render: () => () => {
            const { value = '{}' } = form.getFieldValue('bizId') ?? {};

            const { code } = JSON.parse(value);

            return <Input placeholder="请选择维保目标" disabled value={code} />;
          },
        },
        {
          label: '维保目标区域',
          shouldUpdate: true,
          render: () => () => {
            const { value = '{}' } = form.getFieldValue('bizId') ?? {};

            const { locationName } = JSON.parse(value);

            return <Input placeholder="请选择维保目标" disabled value={locationName} />;
          },
        },
        {
          label: '维保途径',
          name: 'channelType',
          initialValue: appEnum.Resources.MaintenanceChannel.autonomy,
          render: () => (
            <Select
              placeholder="请选择维保途径"
              options={appDict.resources.maintenanceChannel}
              allowClear
            />
          ),
        },
        {
          label: '维保任务分类',
          dependencies: ['bizType'],
          render: () => () => {
            const bizType = form.getFieldValue('bizType');

            return (
              <Form.Item key={bizType} name="classificationId" style={{ marginBottom: 0 }}>
                <ResourceClassificationCascader
                  // resFetchData={resFetchData}
                  params={{
                    businessType: appEnum.Resources.ResourceBusinessType.maintenance,
                    status: appEnum.Common.UsageStatus.enabled,
                    cascade: true,
                  }}
                  showSearch
                  placeholder={'维保任务分类'}
                  onCreateClick={() => {}}
                />
              </Form.Item>
            );
          },
        },
        // todo 暂时不上
        // {
        //   label: '资源占用',
        //   name: 'feild',
        //   initialValue: appEnum.Common.YNB.no,
        //   tooltip: '开启占用,维保目标在维保任务的计划时间内,不支持排产和生产',
        //   render: () => <Radio.Group options={appDict.common.ynb} />,
        // },
        {
          label: '标准工时',
          render: () => (
            <div>
              <Space>
                <Form.Item
                  name={'standardWorkTimeH'}
                  noStyle
                  rules={[{ validator: validatorWorkTime }]}
                >
                  <InputNumber max={999} min={0} precision={0} />
                </Form.Item>
                <Button type="text" size="small">
                  小时
                </Button>
                <Form.Item name={'standardWorkTimeM'} noStyle>
                  <InputNumber
                    max={59}
                    min={0}
                    precision={0}
                    onChange={() => {
                      form.validateFields(['standardWorkTimeH']);
                    }}
                  />
                </Form.Item>
                <Button type="text" size="small">
                  分钟
                </Button>
                <Form.Item name={'standardWorkTimeS'} noStyle>
                  <InputNumber
                    max={59}
                    min={0}
                    precision={0}
                    onChange={() => {
                      form.validateFields(['standardWorkTimeH']);
                    }}
                  />
                </Form.Item>
                <Button type="text" size="small">
                  秒
                </Button>
              </Space>
            </div>
          ),
        },
        {
          label: '计划开始时间',
          name: 'planStartTime',
          rules: [{ required: true, message: '请选择计划开始时间' }],
          render: () => (
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              showTime
              style={{ width: '100%' }}
              onChange={(value) => {
                if (value) {
                  const planEndTime = form.getFieldValue('planEndTime');

                  if (planEndTime && value && moment(planEndTime).isBefore(value)) {
                    form.setFieldsValue({ planEndTime: undefined });
                    setPlanEndTime();
                  }
                }
              }}
            />
          ),
        },
        {
          label: '计划结束时间',
          dependencies: [
            ['planStartTime'],
            ['standardWorkTimeH'],
            ['standardWorkTimeM'],
            ['standardWorkTimeS'],
          ],
          required: true,
          render: () => () => {
            setPlanEndTime();

            const planStartTime = form.getFieldValue('planStartTime');

            return (
              <Form.Item
                name="planEndTime"
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: '请选择计划结束时间' }]}
              >
                <DatePicker
                  disabledDate={(current) => {
                    return current && moment(current).isBefore(planStartTime);
                  }}
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime
                  style={{ width: '100%' }}
                />
              </Form.Item>
            );
          },
        },
        {
          label: '计划执行人',
          name: 'planExecutorList',
          rules: [
            { required: true, message: '请选择计划执行人' },
            { type: 'array', max: MAX_USER_COUNT, message: '计划执行人最多不超过20个' },
          ],
          render: () => <UserDeptRoleSelect isMultiple />,
        },
        {
          label: '当前执行人',
          name: 'currentExecutorList',
          rules: [
            { required: true, message: '请选择当前执行人' },
            { type: 'array', max: MAX_USER_COUNT, message: '当前执行人最多不超过20个' },
          ],

          render: () => <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
        },
        {
          label: '执行方式',
          name: 'executeType',
          rules: [{ required: true, message: '请选择维保方案' }],
          render: () => (
            <Select
              disabled
              placeholder="请选择维保方案"
              options={appDict.resources.maintenanceExecuteType}
            />
          ),
        },
        {
          noStyle: true,
          shouldUpdate: true,
          render: (formItemConfig: JSX.IntrinsicAttributes & FormItemProps<any>) => () => {
            const executeType = form.getFieldValue('executeType');

            if (executeType === appEnum.Resources.MaintenanceExecuteType.reportTemplate) {
              return (
                <Form.Item
                  {...formItemConfig}
                  label="报告模板"
                  name={'executeIds'}
                  rules={[{ required: true, message: '请检查维保方案' }]}
                >
                  <SearchSelect
                    disabled
                    fetchType="reportTemplate"
                    placeholder="请选择维保方案"
                    mode="multiple"
                    params={{ category: [appEnum.ReportTemplate.TemplateType.maintenanceReport] }}
                  />
                </Form.Item>
              );
            }

            if (executeType === appEnum.Resources.MaintenanceExecuteType.SOP) {
              return (
                <Form.Item
                  {...formItemConfig}
                  label="SOP方案"
                  name={'executeIds'}
                  rules={[{ required: true, message: '请选择SOP方案' }]}
                >
                  <SearchSelect
                    disabled
                    fetchType="SOP"
                    placeholder="请选择SOP方案"
                    mode="multiple"
                    params={{
                      status: appEnum.Common.UsageStatus.enabled,
                      bizType: appEnum.Sop.BizType.maintenance,
                    }}
                  />
                </Form.Item>
              );
            }

            return null;
          },
        },
        {
          label: '扫码确认',
          name: 'scanCodeFlag',
          initialValue: appEnum.Common.YNB.no,
          render: () => <Radio.Group options={appDict.common.ynb} />,
        },
        {
          label: '附件',
          name: 'fileIds',
          isFullLine: true,
          render: () => <BcAttachmentForm form={form} />,
        },
        {
          label: '备注',
          name: 'remark',
          isFullLine: true,
          rules: [{ max: MAX_TEXTAREA_LENGTH, message: `不超过${MAX_TEXTAREA_LENGTH}字符` }],
          render: () => <TextArea placeholder="请输入备注" />,
        },
        {
          label: '状态',
          name: 'status',
          span: 2,
          hidden: true,
          render: () => <Input />,
        },
      ],
    };

    if (type === 'edit') {
      baseInfo.items.push({
        label: '编辑原因',
        name: 'reason',
        isFullLine: true,
        rules: [{ max: MAX_TEXTAREA_LENGTH, message: `不超过${MAX_TEXTAREA_LENGTH}字符` }],
        render: () => <TextArea placeholder="请输入编辑原因" />,
      });
    }

    return baseInfo;
  };

  return <DataFormLayout info={[getBaseInfo()]} form={form} footer={false} />;
};

export default ActionContent;
