import React from 'react';
import type { FC } from 'react';
import { Col, Row } from 'antd';
import { UserDetailTable } from './userDetailTable';
import {
  BIZTYPE_DETAILS_MAP,
  GroupTypesEnum,
  GroupTypesMap,
  GRPUPTYPE_DETAILS_MAP,
} from '../constants';

interface Props {
  initialData: {}[];
}

const ResourceGroupDetails: FC<Props> = (props) => {
  const { initialData } = props;

  /**
   * 可选资源展示组建
   * @param componmentFieldName
   * @param  groupType 资源分组
   * @param bizType 资源分组为用户时所选项
   * @returns Componment
   */
  const optionalResources = (item: { groupType: number; bizType: number }) => {
    const { groupType, bizType } = item;

    if (groupType === GroupTypesEnum.USER) {
      const Component = BIZTYPE_DETAILS_MAP.get(bizType) || UserDetailTable;

      return <Component dataSource={item} />;
    }

    const Component = GRPUPTYPE_DETAILS_MAP.get(groupType) || UserDetailTable;

    return <Component dataSource={item} />;
  };

  return (
    <>
      {initialData?.map((item: any) => {
        const { lineNo, name, groupType, bizType } = item;

        const groupDisplay =
          groupType === GroupTypesEnum.USER && GroupTypesMap.get(bizType)
            ? `${GroupTypesMap.get(groupType)}（${GroupTypesMap.get(bizType)}）`
            : GroupTypesMap.get(groupType);

        return (
          <div
            style={{
              borderStyle: 'dashed',
              borderColor: '#d9d9d9',
              padding: 20,
              marginBottom: 20,
              position: 'sticky',
            }}
          >
            <Row style={{ marginBottom: 10 }}>
              <Col span={8}>行号：{lineNo}</Col>
              <Col span={8}>资源组分类：{groupDisplay}</Col>
              <Col span={8}>资源组名称：{name}</Col>
            </Row>
            <Row>{optionalResources(item)}</Row>
          </div>
        );
      })}
    </>
  );
};

export default ResourceGroupDetails;
