/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../../request';

// makeRequest
function makeRequestRequired<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequestOptional<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData?: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequest<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const optional = makeRequestOptional<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  const required = makeRequestRequired<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  return (
    requestConfig.requestDataOptional ? optional : required
  ) as TRequestConfig['requestDataOptional'] extends true ? typeof optional : typeof required;
}

// Request
export type Request<TReqeustData, TRequestConfig extends RequestConfig, TRequestResult> =
  (TRequestConfig['requestDataOptional'] extends true
    ? (
        requestData?: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult
    : (
        requestData: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult) & {
    requestConfig: TRequestConfig;
  };

const mockUrl_0_15_0_0 = 'http://yapi.blacklake.tech/mock/951' as any;
const devUrl_0_15_0_0 = '' as any;
const prodUrl_0_15_0_0 = '' as any;
const dataKey_0_15_0_0 = undefined as any;

/**
 * 接口 [创建标识码↗](http://yapi.blacklake.tech/project/951/interface/api/86264) 的 **请求类型**
 *
 * @分类 [factory-codes-web-controller↗](http://yapi.blacklake.tech/project/951/interface/api/cat_11962)
 * @标签 `factory-codes-web-controller`
 * @请求头 `POST /code/domain/web/v1/codeEntityLink/create`
 * @更新时间 `2021-12-08 17:25:54`
 */
export interface FetchCodeEntityLinkCreateRequest {
  /**
   * 业务类型
   */
  businessObjectId?: number;
  /**
   * 数量
   */
  count?: number;
  /**
   * 厂内标识码
   */
  factoryCode?: string;
  /**
   * 自定义厂内标识码
   */
  factoryCodeList?: string[];
  /**
   * 编号规则Id
   */
  ruleId?: number;
  /**
   * 生成规则类型 0-默认，1-编号规则，2-自定义
   */
  ruleType?: number;
}

/**
 * 接口 [创建标识码↗](http://yapi.blacklake.tech/project/951/interface/api/86264) 的 **返回类型**
 *
 * @分类 [factory-codes-web-controller↗](http://yapi.blacklake.tech/project/951/interface/api/cat_11962)
 * @标签 `factory-codes-web-controller`
 * @请求头 `POST /code/domain/web/v1/codeEntityLink/create`
 * @更新时间 `2021-12-08 17:25:54`
 */
export interface FetchCodeEntityLinkCreateResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 无重复厂内标识码集合
     */
    noRepeatFactoryCodeList?: string[];
    /**
     * 重复厂内标识码数量
     */
    repeatFactoryCodeAmount?: number;
    /**
     * 重复厂内标识码集合
     */
    repeatFactoryCodeList?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [创建标识码↗](http://yapi.blacklake.tech/project/951/interface/api/86264) 的 **请求配置的类型**
 *
 * @分类 [factory-codes-web-controller↗](http://yapi.blacklake.tech/project/951/interface/api/cat_11962)
 * @标签 `factory-codes-web-controller`
 * @请求头 `POST /code/domain/web/v1/codeEntityLink/create`
 * @更新时间 `2021-12-08 17:25:54`
 */
type FetchCodeEntityLinkCreateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/951',
    '',
    '',
    '/code/domain/web/v1/codeEntityLink/create',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [创建标识码↗](http://yapi.blacklake.tech/project/951/interface/api/86264) 的 **请求配置**
 *
 * @分类 [factory-codes-web-controller↗](http://yapi.blacklake.tech/project/951/interface/api/cat_11962)
 * @标签 `factory-codes-web-controller`
 * @请求头 `POST /code/domain/web/v1/codeEntityLink/create`
 * @更新时间 `2021-12-08 17:25:54`
 */
const fetchCodeEntityLinkCreateRequestConfig: FetchCodeEntityLinkCreateRequestConfig = {
  mockUrl: mockUrl_0_15_0_0,
  devUrl: devUrl_0_15_0_0,
  prodUrl: prodUrl_0_15_0_0,
  path: '/code/domain/web/v1/codeEntityLink/create',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_15_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchCodeEntityLinkCreate',
};

/**
 * 接口 [创建标识码↗](http://yapi.blacklake.tech/project/951/interface/api/86264) 的 **请求函数**
 *
 * @分类 [factory-codes-web-controller↗](http://yapi.blacklake.tech/project/951/interface/api/cat_11962)
 * @标签 `factory-codes-web-controller`
 * @请求头 `POST /code/domain/web/v1/codeEntityLink/create`
 * @更新时间 `2021-12-08 17:25:54`
 */
export const fetchCodeEntityLinkCreate = makeRequest<
  FetchCodeEntityLinkCreateRequest,
  FetchCodeEntityLinkCreateResponse,
  FetchCodeEntityLinkCreateRequestConfig
>(fetchCodeEntityLinkCreateRequestConfig);

/* prettier-ignore-end */
