import React, { useState } from 'react';
import { RecordListLayout } from 'layout';
import { fieldTypeList, getFilterList } from 'src/utils';
import { BlTooltip, SearchSelect } from 'src/components';
import { Badge, Tag } from 'antd';
import _ from 'lodash';
import { replaceSign } from 'src/utils/constants';
import { fetchSourceListList } from 'src/api/ytt/order-domain/sourceList';
import _Time from 'src/utils/dataTypes/time';
import { AvatarDisplay } from 'src/components/avatar/show';
import { lookup } from 'src/dict';
import { CRUD, UsageStatus } from 'src/dict/common';
import { BlSelect } from 'src/components/select';
import { usageStatus } from 'src/dict/common/mappings';
import authDict, { hasAuth } from 'src/utils/auth';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';

type Props = {
  tableConfig: any;
  refreshMarker: number;
  /** 页面操作 */
  action: (type: CRUD, id: number) => void;
  /** 批量启用操作 */
  bulkActivate: (ids: number[], onSuccess?: () => void, onError?: () => void) => void;
  /** 批量停用操作 */
  bulkDeactivate: (ids: number[], onSuccess?: () => void, onError?: () => void) => void;
};

const SourceListView: React.FC<Props> = (props) => {
  const { action, bulkActivate, bulkDeactivate } = props;
  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  /**
   * Columns
   */
  const dataColumns = [
    {
      title: '编号',
      dataIndex: 'code',
      width: 160,
      sorter: true,
      isFilter: true,
      type: fieldTypeList?.text,
      render: (code: string, record: any, index: number, config: any) => {
        const { id } = record;

        return hasAuth(authDict.sourcelist_detail) ? (
          <a
            onClick={() => {
              action(CRUD.view, id);
            }}
          >
            <BlTooltip text={code} width={config.contentWidth} />
          </a>
        ) : (
          <BlTooltip text={code} width={config.contentWidth} />
        );
      },
    },
    {
      title: '状态',
      dataIndex: 'enableStatus',
      width: 160,
      isFilter: true,
      type: fieldTypeList?.select,
      renderItem: (
        <BlSelect selectList={usageStatus} placeholder={'请选择状态'} mode={'multiple'} />
      ),
      render: (enableStatus: number) => {
        return (
          <Badge
            status={enableStatus === UsageStatus.enabled ? 'success' : 'error'}
            text={lookup('common.usageStatus', enableStatus)}
          />
        );
      },
    },
    {
      title: '物料编号',
      dataIndex: 'materialCode',
      width: 200,
      render: (text: unknown, record: any) => {
        return _.get(record, 'material.code', replaceSign);
      },
    },
    {
      title: '物料名称',
      label: '物料',
      dataIndex: 'materialName',
      width: 200,
      isFilter: true,
      type: fieldTypeList?.multiSelect,
      renderItem: (
        <SearchSelect fetchType={'materialCodeName'} mode={'multiple'} placeholder={'请选择物料'} />
      ),
      render: (text: unknown, record: any) => {
        return _.get(record, 'material.name', replaceSign);
      },
    },
    {
      title: '物料属性',
      dataIndex: 'materialAttr',
      width: 200,
      render: (materialAttr: any[]) => {
        return materialAttr?.map((item) => <Tag>{item.name}</Tag>);
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 180,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      render: (createdAt: number) => {
        if (!createdAt) return replaceSign;
        return _Time.format(createdAt);
      },
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 160,
      isFilter: true,
      type: fieldTypeList?.multiSelect,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择创建人" isMultiple isSelectUser />
      ),
      render: (creator: any = {}) => {
        if (!creator) return replaceSign;
        return <AvatarDisplay {...creator} key={creator?.id} isShowTag isUser />;
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 180,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      render: (updatedAt: number) => {
        if (!updatedAt) return replaceSign;
        return _Time.format(updatedAt);
      },
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      width: 160,
      isFilter: true,
      type: fieldTypeList?.multiSelect,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择更新人" isMultiple isSelectUser />
      ),
      render: (operator: any = {}) => {
        if (!operator) return replaceSign;
        return <AvatarDisplay {...operator} key={operator?.id} isShowTag isUser />;
      },
    },
  ];
  /**
   * 列操作 ...
   */
  const getOperationList = (record: any) => {
    const { id, enableStatus } = record;

    return _.compact([
      {
        title: '查看',
        auth: authDict.sourcelist_detail,
        onClick: () => {
          action(CRUD.view, id);
        },
      },
      {
        title: '编辑',
        auth: authDict.sourcelist_edit,
        onClick: () => action(CRUD.edit, id),
      },
      {
        title: enableStatus === UsageStatus.enabled ? '停用' : '启用',
        auth: authDict.sourcelist_enable_disable,
        onClick: () => {
          enableStatus === UsageStatus.enabled ? action(CRUD.disable, id) : action(CRUD.enable, id);
        },
      },
      {
        title: '操作记录',
        auth: authDict.sourcelist_operrecord,
        onClick: () => {
          action(CRUD.operrecord, id);
        },
      },
    ]);
  };
  /**
   * 批量操作
   */
  const batchMenu = [
    {
      title: '批量启用',
      auth: authDict.sourcelist_enable_disable,
      onClick: (onSuccess?: () => void, onError?: () => void) => {
        bulkActivate(selectedRowKeys, onSuccess, onError);
      },
    },
    {
      title: '批量停用',
      auth: authDict.sourcelist_enable_disable,
      onClick: (onSuccess?: () => void, onError?: () => void) => {
        bulkDeactivate(selectedRowKeys, onSuccess, onError);
      },
    },
  ];

  return (
    <>
      <RecordListLayout
        columns={dataColumns}
        filterList={getFilterList(dataColumns)}
        refreshMarker={props.refreshMarker}
        placeholder={'输入编号，物料编号'}
        configcacheKey="sourceList"
        {...props.tableConfig}
        requestFn={fetchSourceListList}
        getOperationList={getOperationList}
        batchMenu={batchMenu}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey: number[]) => {
          setSelectRowKeys(selectKey);
        }}
      />
    </>
  );
};

export default SourceListView;
