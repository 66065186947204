/**
 * @enum 报告模板业务模块枚举值
 */
/** 模板类型 */
export enum TemplateType {
  /** 生产报告 */
  productionReport = 1,
  /** 维保报告 */
  maintenanceReport = 2,
  /** 维修报告 */
  repairReport = 3,
}
