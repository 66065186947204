import { Form } from 'antd';
import { useState } from 'react';
import SearchSelect from 'src/components/searchSelect';
import authDict, { hasAuth } from 'src/utils/auth';
import UploadFileList from '../attachmentList';

const ExistFileItem = () => {
  const [selectFile, setSelectFile] = useState<any>([]);
  const formateFileProps = (item: any) => {
    const value = item.value ? JSON.parse(item.value) : {};

    return {
      ...value,
      uid: value.id,
      name: decodeURIComponent(value?.filename),
      status: 'done',
      type: value.extension,
      url: value.fileUri,
    };
  };

  const renderFileList = (files: any[] = []) => {
    if (!files.length) return null;

    const selectFile = files.map((item: any) => formateFileProps(item));

    return (
      <>
        <div>已选择：{selectFile.length}个</div>
        <div style={{ padding: 10, border: '1px solid rgba(0, 0, 0, .15)', borderRadius: 2 }}>
          <UploadFileList
            files={selectFile}
            preview
            showDownloadIcon={hasAuth(authDict.document_down_file)}
          />
        </div>
      </>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <Form.Item name="existFileIds">
        <SearchSelect
          mode="multiple"
          fetchType="files"
          renderOption={(value) => ({
            label: decodeURIComponent(value?.filename),
            value: JSON.stringify(value),
            key: JSON.stringify(value),
          })}
          searchFieldName="keyword"
          style={{ width: '100%', marginBottom: 20 }}
          onChange={(files: any[]) => {
            setSelectFile(files);
          }}
        />
      </Form.Item>
      {renderFileList(selectFile)}
    </div>
  );
};

export default ExistFileItem;
