// 库存明细中物料的业务状态
// eslint-disable-next-line no-shadow
export enum MaterialBizStatusEnum {
  NO_BIZ,
  IN_QUALITY_CHECK,
}

export const MaterialBizStatusValue = new Map();

MaterialBizStatusValue.set(MaterialBizStatusEnum.NO_BIZ, '无业务');
MaterialBizStatusValue.set(MaterialBizStatusEnum.IN_QUALITY_CHECK, '质检中');

// eslint-disable-next-line no-shadow
export enum MaterialStorageStatusEnum {
  IN_STORAGE = 1,
  ON_WAY,
}

export const MaterialStorageStatusValue = new Map();

MaterialStorageStatusValue.set(MaterialStorageStatusEnum.IN_STORAGE, '仓储中');
MaterialStorageStatusValue.set(MaterialStorageStatusEnum.ON_WAY, '在途中');

// 库存明细中物料质量检测状态
// eslint-disable-next-line no-shadow
export enum MaterialQualityStatusEnum {
  QUALIFIED = 1,
  CONCESSION_QUALIFIED,
  WAITING_FOR_CHECK,
  FAULTY,
  TEMP_CONTROL,
}

export const MaterialQualityStatusValue = new Map();

MaterialQualityStatusValue.set(MaterialQualityStatusEnum.QUALIFIED, '合格');
MaterialQualityStatusValue.set(MaterialQualityStatusEnum.CONCESSION_QUALIFIED, '让步合格');
MaterialQualityStatusValue.set(MaterialQualityStatusEnum.WAITING_FOR_CHECK, '待检');
MaterialQualityStatusValue.set(MaterialQualityStatusEnum.FAULTY, '不合格');
MaterialQualityStatusValue.set(MaterialQualityStatusEnum.TEMP_CONTROL, '暂控');
