import { Radio, Select, Form, FormInstance, Input, Switch, Modal } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { appDict, appEnum } from 'src/dict';
import { BlSortFormList } from 'src/components';
import _ from 'lodash';
import { validateFormListRepeat } from 'src/utils/formValidators';
import { ChoiceProps } from 'src/page/custom_fields/fieldsList/index.d';

interface Props extends ChoiceProps {}
const { Option } = Select;

export interface ChoiceOptionNameItemType {
  choiceValue: string;
  isUsed?: boolean; // 创建的时候没有 isUsed
}

export const singleChoice = (props: Props) => {
  const { name, data, type, form, isPreField, canSetIsRequired } = props;

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    // 字段项相同属性
    const getOptions = (field: any, fieldName: string) => ({
      fieldKey: [field.fieldKey, fieldName],
      name: [field.name, fieldName],
      validateFirst: true,
      style: { marginBottom: '0' },
      validateTrigger: ['onChange', 'onBlur'],
    });

    return _.compact([
      {
        title: '选项信息',
        dataIndex: 'choiceValue',
        render: (text: string, field) => {
          return (
            <div key={field.name}>
              <Form.Item
                {...getOptions(field, 'choiceValue')}
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  { required: true, message: '请输入' },
                  { max: 255, message: '不可超过255个字符' },
                  {
                    validator: validateFormListRepeat(name, 'choiceValue', form, '已存在该选项！'),
                  },
                ]}
                validateFirst
                style={{ marginBottom: 0 }}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </div>
          );
        },
      },
      type === 'edit' && {
        title: '状态',
        dataIndex: 'isUsed',
        width: 150,
        render: (text: string, field) => {
          return (
            _.has(data[field.key], 'key') && (
              <div key={field.name}>
                <Form.Item {...getOptions(field, 'isUsed')} valuePropName="checked" noStyle>
                  <Switch
                    checkedChildren="启用"
                    unCheckedChildren="停用"
                    onChange={(checked) => {
                      if (
                        !checked &&
                        form.getFieldValue('defaultOption') ===
                          form.getFieldValue(['singleOptionList', field.name, 'choiceValue'])
                      ) {
                        form.setFieldsValue({ defaultOption: null });
                      }
                    }}
                  />
                </Form.Item>
              </div>
            )
          );
        },
      },
    ]);
  };

  return [
    {
      label: '必填',
      name: 'isRequired',
      initialValue: appEnum.Common.YN.no,
      rules: [{ required: true, message: '请选择必填' }],
      render: () => (
        <Radio.Group
          disabled={isPreField && form.getFieldValue('isRequired') === appEnum.Common.YN.yes}
          options={appDict.common.yn}
          onChange={(e) => {
            if (!isPreField && e.target.value === appEnum.Common.YN.yes && !canSetIsRequired) {
              Modal.warning({
                title: '此对象暂时无法设置必填的自定义字段',
                okText: '确定',
                onOk: () => {
                  form.setFieldsValue({ isRequired: appEnum.Common.YN.no });
                },
              });
            }
          }}
        />
      ),
    },
    {
      rules: [{ required: true, message: '请选择必填' }],
      render: () => (
        <BlSortFormList
          buttonText="添加选项"
          isNeedBatchAdd
          fixedRowFn={(field: any, index: number) => {
            return form?.getFieldValue([String(name)])[index]?.key;
          }}
          addInit={{ choiceValue: undefined, isUsed: true, isNewAdd: true }}
          batchAddInit={{
            choiceValue: { isFollowInput: true },
            isUsed: { value: true },
            isNewAdd: { value: true },
          }}
          form={form}
          name={name}
          renderColumns={() => getColumns()}
          listRules={[
            {
              validator: async (__, items) => {
                if (!items || _.isEmpty(items.filter((i: any) => !!i))) {
                  return Promise.reject(Error('请至少添加一个选项'));
                } else if (_.uniqBy(items, 'choiceValue').length < items.length) {
                  return Promise.reject(Error('不能有重复的选项'));
                }
                return Promise.resolve(true);
              },
            },
          ]}
        />
      ),
    },
    {
      label: '默认值',
      shouldUpdate: (prev: any, cur: any) => prev[name] !== cur[name],
      render: () => () => {
        const optionList: ChoiceOptionNameItemType[] = form.getFieldsValue()[name];

        return (
          <Form.Item name="defaultOption">
            <Select
              allowClear
              showSearch
              filterOption={(input, options) => {
                // eslint-disable-next-line max-len
                return options
                  ? options?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  : false;
              }}
            >
              {_.map(optionList, (item: ChoiceOptionNameItemType) => {
                if (!item?.choiceValue) return;
                if (!item?.isUsed && type === 'edit') return; // 如果是编辑的情况下,过滤停用的选项
                return (
                  <Option key={item.choiceValue} value={item.choiceValue}>
                    {item.choiceValue}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        );
      },
    },
  ];
};
