import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, message, Modal, Spin } from 'antd';
import { DataFormLayout } from 'src/layout';
import { useBlocker } from 'src/utils';
import { useParams } from 'react-router-dom';
import {
  fetchQcTaskDetailForEdit,
  FetchQcTaskDetailForEditResponse,
  fetchQcTaskStart,
  fetchQcTaskFinishCheck,
  fetchQcTaskSubmitReport,
  fetchQcTaskCancel,
  fetchQcTaskFinish,
} from 'src/api/ytt/quality-domain/qc_task';
import { QcTaskEntity } from 'src/page/quality/models/entities';
import _ from 'lodash';
import styles from 'src/page/quality/qcTask/styles.module.scss';
import { getBaseInfo } from './components/baseInfo';
import { QcTask } from '../../models/dto';
import { fetchCustomFieldInstanceGetByObjectCode } from 'src/api/ytt/custom-object-domain';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { formatCustomFieldsInData, ICustomFields } from 'src/components/customField';
import { getTaskEditUrl } from '../../models/utils/qcTask';
import { CategoryEnum, QcTaskStatusEnum } from 'src/dict/quality';
import {
  EditButton,
  customLayoutChooseButton,
} from 'src/components/customLayout/hooks/customLayoutForButton';
import authDict, { hasAuth } from 'src/utils/auth';

type QcTaskData = FetchQcTaskDetailForEditResponse['data'];

const Execute = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [qcTaskData, setQcTaskData] = useState<QcTaskData>();
  const [refreshMaker, setRefreshMaker] = useState<number>(0);
  const [qcTaskEntity, setqTaskEntity] = useState<QcTaskEntity>();
  const { resetForm, history } = useBlocker(form);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tabList, setTabList] = useState<any[]>([]);
  const tabRef = useRef<any>(null);

  // 刷新报告内容
  const setFormData = ({
    data,
    _qcTaskEntity,
  }: {
    data: QcTaskData;
    _qcTaskEntity: QcTaskEntity;
  }) => {
    const formData = { ...data, qcTotalCount: data?.qcTotal } as any;

    if (!_qcTaskEntity?.isUnStarted() && _qcTaskEntity?.isRecordByCheckItem()) {
      const checkItemReports = _.keyBy(
        _.map(_qcTaskEntity?.getResultByCheckItem()?.checkItems, (checkItem) => ({
          ...checkItem,
          reportValues: checkItem.results,
        })),
        'id',
      );

      formData.reports = checkItemReports;
    }
    if (!_qcTaskEntity?.isUnStarted() && !_qcTaskEntity?.isRecordByCheckItem()) {
      formData.reports = _qcTaskEntity?.getResultByReport?.()?.results;
    }

    form.setFieldsValue(formData);
    resetForm(formData);
  };

  const fetchData = () => {
    Promise.all([
      fetchCustomFieldInstanceGetByObjectCode({
        objectCode: OBJECT_OF_CODE.checkItemRecord,
      }),
      fetchQcTaskDetailForEdit({ id }),
    ]).then(([{ data }, taskRes]) => {
      const qcTask = new QcTaskEntity(
        ({ ...taskRes?.data, checkItemRecordCustomFields: data } as QcTask) || {},
      );

      setQcTaskData(taskRes?.data);
      setqTaskEntity(qcTask);
      setFormData({ data: taskRes?.data, _qcTaskEntity: qcTask });
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [refreshMaker]);

  useEffect(() => {}, []);

  useEffect(() => {
    const showMaterial =
      (qcTaskEntity?.qcConfigEntity().isRecordSample() ||
        qcTaskEntity?.qcConfigEntity().isRecordTotal()) &&
      !qcTaskEntity?.isGeneralQc();

    setTabList(
      _.compact([
        showMaterial && {
          key: 'checkMaterials',
          title: '物料信息',
          isActive: tabRef.current?.getActiveTab?.() === 'checkMaterials',
        },
        {
          key: 'reports',
          title: '检验项记录',
          isActive: tabRef.current?.getActiveTab?.() === 'reports' || !showMaterial,
        },
      ]),
    );
  }, [qcTaskEntity]);

  /**
   * 处理表单校验错误
   * @param error 不传时重置Tab错误数量
   */
  const handleFormError = (error?: unknown) => {
    const errorFields: { name: (string | number)[] }[] = _.get(error, 'errorFields', [
      { name: [] },
    ]);

    const newTabList: any[] = [];

    tabList.map(({ key, title }) => {
      let errorCount = 0;

      if (!_.isEmpty(errorFields)) {
        _.unionBy([...errorFields], (item) => {
          if (item.name.includes(key)) errorCount++;
        });
      }

      newTabList.push({ key, title, errorCount });
    });

    setTabList(newTabList);
  };

  const getSubmitData = () => {
    const values = form.getFieldsValue(true);
    const {
      inspectionResult,
      reports,
      remark,
      checkCount,
      defectCount,
      pendingCount,
      qualifiedConcessionCount,
      qualifiedCount,
      checkMaterials,
    } = values;

    const formatReportValues = (_reportValues: any[], taskCheckCount?: number) => {
      return _.map(_reportValues, (report, index) => {
        const {
          defectList,
          isUnQuality,
          customFields: customFieldsValues,
          valueMin,
          valueMax,
          value,
          attachmentIds,
          seq,
          remark,
          id,
          taskCheckCount: reportCheckCount,
        } = report;

        return formatCustomFieldsInData({
          data: {
            seq: index + 1,
            customFields: customFieldsValues,
            attachmentIds,
            id,
            remark,
            min: valueMin,
            max: valueMax,
            result: _.isArray(value) ? value.join(',') : value,
            singleJudgment: Number(!isUnQuality),
            taskCheckCount: reportCheckCount || taskCheckCount,
            defectList: defectList?.map(
              ({ defectRank, defectReason }: { defectRank: number; defectReason: number }) => ({
                qcDefectRankId: defectRank,
                qcDefectReasonId: defectReason,
              }),
            ),
          },
          customFields: qcTaskEntity?.checkItemRecordCustomFields,
        });
      });
    };

    const formatCheckItemReports = _.map(reports, (reprotValue) => {
      const { reportValues, taskCheckCount, qcConfigCheckItemId } = reprotValue;

      return {
        qcConfigCheckItemId,
        reportValues: formatReportValues(reportValues, taskCheckCount),
      };
    });

    const formatReports = _.map(qcTaskEntity?.qcConfigEntity()?.getCheckItems(), (checkItem) => {
      const { id } = checkItem;

      return {
        qcConfigCheckItemId: id,
        reportValues: formatReportValues(
          _.filter(
            _.flatMap(_.map(reports, (report) => report?.reportValues)),
            (report) => report.qcConfigCheckItemId === id,
          ),
        ),
      };
    });

    const formData = {
      id,
      inspectionResult,
      reports: qcTaskEntity?.isRecordByCheckItem() ? formatCheckItemReports : formatReports,
      remark,
    } as any;

    // 物料信息提交数据
    if (qcTaskEntity?.isMaterialBatchRecordType() && !qcTaskEntity?.isShowRecordResult()) {
      formData.sampleRecords = checkMaterials
        ?.filter(({ category }) => category === CategoryEnum.SAMPLE)
        ?.map((node: any) => {
          return {
            defectCount: node.defectCount,
            pendingCount: node.pendingCount,
            qcStatusAfter: node.qcStatusAfter,
            qcTaskMaterialId: node.qcTaskMaterialId,
            qualifiedConcessionCount: node.qualifiedConcessionCount,
            qualifiedCount: node.qualifiedCount,
          };
        });
    } else {
      formData.sampleRecord = {
        defectCount,
        pendingCount,
        qualifiedConcessionCount,
        qualifiedCount,
      };
    }
    return formData;
  };

  const handleSubmit = async () => {
    try {
      const formData = getSubmitData();

      await form?.validateFields(['checkMaterials']);

      const { code } = await fetchQcTaskSubmitReport({ ...formData });

      if (code === 200) {
        message.success('保存成功');
        setRefreshMaker(Math.random());
      }
    } catch (err) {
      console.log(err);
    }
  };

  const finishTask = async () => {
    await fetchQcTaskFinish({ id });
    message.success('结束任务成功');
    history.goBack();
  };

  const validateSubmitData = async () => {
    if (!form.getFieldValue('checkMaterials')) {
      if (qcTaskEntity?.isRecordSample() && qcTaskEntity?.isMaterialBatchRecordType()) {
        message.error('至少存在一行样本库存，至少存在一行总体库存');
        return Promise.reject({ errorFields: [{ name: ['checkMaterials'] }] });
      } else if (qcTaskEntity?.isRecordSample() && !qcTaskEntity?.isMaterialBatchRecordType()) {
        message.error('至少存在一行样本库存');
        return Promise.reject({ errorFields: [{ name: ['checkMaterials'] }] });
      } else if (!qcTaskEntity?.isRecordSample() && qcTaskEntity?.isMaterialBatchRecordType()) {
        message.error('至少存在一行总体库存');
        return Promise.reject({ errorFields: [{ name: ['checkMaterials'] }] });
      }
      return Promise.resolve();
    }
  };

  const onFinish = async () => {
    try {
      await form.validateFields();
      await validateSubmitData();
      const formData = getSubmitData();
      const { data } = await fetchQcTaskFinishCheck({ ...formData });

      if (data?.code) {
        return Modal.confirm({
          title: `报废性校验将销毁${data?.count}库存，是否继续？`,
          onOk: () => finishTask,
        });
      }
      return finishTask();
    } catch (err) {
      handleFormError(err);
    }
  };

  const footer = (
    <>
      <Button
        onClick={() => {
          Modal.confirm({
            title: '确定取消当前任务吗？',
            onOk: () => {
              fetchQcTaskCancel({ ids: [id] }).then(({ code }) => {
                if (code === 200) {
                  message.success('取消成功');
                  history.goBack();
                }
              });
            },
            cancelText: '取消',
            okText: '确定',
          });
        }}
      >
        取消任务
      </Button>
      {qcTaskEntity?.isUnStarted() ? (
        <Button
          type="primary"
          onClick={() => {
            Modal.confirm({
              title: '确定开始当前任务吗？',
              onOk: () => {
                fetchQcTaskStart({ id }).then(({ code }) => {
                  if (code === 200) {
                    message.success('开始成功');
                    setRefreshMaker(Math.random());
                  } else {
                    message.error('开始失败');
                  }
                });
              },
            });
          }}
        >
          开始任务
        </Button>
      ) : (
        <Button type="primary" onClick={onFinish}>
          结束任务
        </Button>
      )}
    </>
  );

  const toCheckTaskEdit = (
    id: number | string,
    layoutId?: number | string,
    bizType?: number | string,
  ) => {
    history.push(getTaskEditUrl(Number(id), Number(layoutId), Number(bizType)));
  };

  const topRender = useCallback(() => {
    if (!qcTaskEntity) {
      return;
    }

    if (qcTaskEntity?.isUnStarted() && hasAuth(authDict.qualityinspectiontask_edit)) {
      return (
        <div className={styles.topButton}>
          <EditButton
            title="编辑"
            instanceId={Number(id)}
            objectCode={OBJECT_OF_CODE.qcTask}
            type={'edit'}
            buttonType="primary"
            onClick={(layoutId) => {
              toCheckTaskEdit(Number(id), layoutId, qcTaskData?.checkType!);
            }}
          />
        </div>
      );
    } else if (!qcTaskEntity?.isUnStarted()) {
      return (
        <Button className={styles.topButton} type="primary" onClick={handleSubmit}>
          保存
        </Button>
      );
    }
  }, [qcTaskEntity]);

  return (
    <>
      {qcTaskEntity ? (
        <DataFormLayout
          title={'执行任务'}
          form={form}
          info={getBaseInfo({
            qcTaskData,
            tabs: tabList,
            qcTaskEntity,
            form,
            setRefreshMaker,
            tabRef,
          })}
          formLayout={'horizontal'}
          footer={footer}
          loading={isLoading}
          topContext={topRender()}
          formProps={{
            validateTrigger: 'onSubmit',
          }}
        />
      ) : (
        <Spin style={{ width: '100%', height: 500 }} />
      )}
    </>
  );
};

export default Execute;
