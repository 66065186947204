import { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, Space, Button } from 'antd';
import { RecordListLayout } from 'layout';

import { fieldTypeList } from 'src/utils';
import { mapLabeledValueToValue } from 'src/utils/array';
import { lookup } from 'src/dict';
import { SearchSelect } from 'src/components';
import { orderOrigin } from 'src/dict/salesReturnOrder/mappings';
import { fetchSalesOrderListItemDetail } from 'src/api/ytt/order-domain/salesOrder';
import { amountRender } from 'src/page/share/renderAmount';
import { fieldLabels, SalesOrderDetailListItemType, defaultQueryCondition } from './constants';
import Styles from './index.module.scss';

interface SelectSalesOrderDialogProps {
  selectedKeys?: React.Key[];
  visible: boolean;
  defaultQueryConditions?: { [query: string]: any };
  showOriginFilter: boolean;
  onCancel: () => void;
  onFinish: (selectedRowKeys: React.Key[], selectedRows: SalesOrderDetailListItemType[]) => void;
}

export default function SelectSalesOrderDialog({
  selectedKeys = [],
  visible,
  defaultQueryConditions = defaultQueryCondition,
  showOriginFilter,
  onCancel,
  onFinish,
}: SelectSalesOrderDialogProps) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(selectedKeys ?? []);
  const [selectedRows, setSelectedRows] = useState<SalesOrderDetailListItemType[]>([]);
  const [refreshMarker, setRefreshMarker] = useState(0);
  const [queryForm] = Form.useForm();

  const formatDataToQuery = ({ page, size }: { page: number; size: number }) => {
    const { materialIds, customerIds, ...rest } = queryForm.getFieldsValue();

    return {
      ...rest,
      materialIds: mapLabeledValueToValue(materialIds),
      customerIds: mapLabeledValueToValue(customerIds),
      page,
      size,
      ...defaultQueryConditions,
    };
  };

  const columns = [
    {
      title: fieldLabels.code,
      dataIndex: 'orderCode',
      width: 150,
      fixed: 'left',
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: fieldLabels.origin,
      dataIndex: 'origin',
      width: 100,
      render: lookup('salesReturnOrder.orderOrigin'),
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: orderOrigin,
        mode: 'multiple',
      },
    },
    {
      title: fieldLabels.customer,
      dataIndex: ['customer', 'name'],
      width: 120,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: fieldLabels.materialName,
      dataIndex: ['material', 'baseInfo', 'name'],
      width: 120,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: fieldLabels.materialCode,
      dataIndex: ['material', 'baseInfo', 'code'],
      width: 120,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: fieldLabels.amount,
      dataIndex: 'amount',
      width: 120,
      render: amountRender,
    },
    {
      title: fieldLabels.plannedReturnAmount,
      dataIndex: 'plannedReturnAmount',
      width: 140,
      render: amountRender,
    },
    {
      title: fieldLabels.warehousePlannedReceiveAmount,
      dataIndex: 'warehousePlannedReceiveAmount',
      width: 120,
      render: amountRender,
    },
    {
      title: fieldLabels.warehouseReceivedAmount,
      dataIndex: 'warehouseReceivedAmount',
      width: 120,
      render: amountRender,
    },
    {
      title: fieldLabels.customerReturnedAmount,
      dataIndex: 'customerReturnedAmount',
      width: 120,
      render: amountRender,
    },
    {
      title: fieldLabels.detailLineNo,
      dataIndex: 'lineNo',
      width: 140,
    },
    {
      title: fieldLabels.shipmentStatus,
      dataIndex: 'shipmentStatus',
      width: 180,
      render: lookup('sales.shipmentStatus'),
    },
  ];

  useEffect(() => {
    setSelectedRowKeys(selectedKeys);
  }, [selectedKeys]);

  return (
    <Modal
      visible={visible}
      title="按销售订单添加物料"
      width={1200}
      okText="添加"
      cancelText="暂不添加"
      onCancel={onCancel}
      okButtonProps={{ disabled: selectedRowKeys.length === 0 }}
      onOk={() => {
        onFinish(selectedRowKeys, selectedRows);
      }}
    >
      <Form form={queryForm} size="small" colon={false} className={Styles.queryForm}>
        <Space wrap>
          <Form.Item label={fieldLabels.code} name="code">
            <Input />
          </Form.Item>
          {showOriginFilter && (
            <Form.Item label={fieldLabels.origin} name="origin">
              <Select mode="multiple" options={orderOrigin} />
            </Form.Item>
          )}
          <Form.Item label={fieldLabels.customer} name="customerIds">
            <SearchSelect fetchType="customer" mode="multiple" />
          </Form.Item>
          <Form.Item label={fieldLabels.materialCode} name="materialIds">
            <SearchSelect fetchType="material" mode="multiple" labelPath="code" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                setRefreshMarker(Date.now);
              }}
            >
              搜索
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <RecordListLayout
        requestFn={fetchSalesOrderListItemDetail}
        refreshMarker={refreshMarker}
        placeholder="请输入搜索关键字"
        style={{ height: 570 }}
        rowKey="id"
        useFilterWithUrl={false}
        useColConfig={false}
        useQuickFilter={false}
        useIndex={false}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(sKeys, selectedRows) => {
          setSelectedRowKeys(sKeys);
          setSelectedRows(selectedRows!);
        }}
        columns={columns}
        formatDataToQuery={formatDataToQuery}
      />
    </Modal>
  );
}
