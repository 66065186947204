import { createModel } from '@rematch/core';
import { RootModel } from 'src/store/models/index';
import {
  fetchPurchaseWorkbenchAvgDeliveryCycle,
  fetchPurchaseWorkbenchConfig,
  fetchPurchaseWorkbenchDeliverySchedule,
  fetchPurchaseWorkbenchOntimeCompletionRate,
  fetchPurchaseWorkbenchOntimeDeliveryRate,
  fetchPurchaseWorkbenchOverdueRecOrder,
  fetchPurchaseWorkbenchReceiveSchedule,
} from 'src/api/ytt/order-domain/workBench';
import { CHART_NAME, DATE_RANGE } from 'src/dict/purchase';
import { message } from 'antd';

interface KeyValue {
  [key: string]: any;
}

interface StateType extends KeyValue {
  config: { [key: string]: any };
  deliverySchedule: number;
  receiveSchedule: number;
  overdueRecOrderSchedule: number;
  onTimeCompletionRate: any;
  onTimeDeliveryRate: any;
  averageDeliveryCycle: any;
}

const initialState: StateType = {
  config: {
    deliverySchedule: DATE_RANGE.day,
    overdueRecOrderSchedule: DATE_RANGE.day,
    receiveSchedule: DATE_RANGE.day,
    onTimeCompletionRate: {
      suppliers: null,
      materials: null,
      createdAtFrom: null,
      createdAtTo: null,
    },
    onTimeDeliveryRate: {
      suppliers: null,
      materials: null,
      createdAtFrom: null,
      createdAtTo: null,
    },
    averageDeliveryCycle: {
      suppliers: null,
      materials: null,
      createdAtFrom: null,
      createdAtTo: null,
    },
  },
  /** 发货进度 */
  deliverySchedule: 0,
  /** 收货进度 */
  receiveSchedule: 0,
  /** 逾期收货单数 */
  overdueRecOrderSchedule: 0,
  /** 按时完成率 */
  onTimeCompletionRate: [],
  /** 按时交货率 */
  onTimeDeliveryRate: [],
  /** 平均交付周期 */
  averageDeliveryCycle: [],
};

type functionType = () => Promise<any>;

/** 获取图表数据方法 */
const fetchFnMap = new Map<string, functionType>([
  [CHART_NAME.deliverySchedule, fetchPurchaseWorkbenchDeliverySchedule],
  [CHART_NAME.receiveSchedule, fetchPurchaseWorkbenchReceiveSchedule],
  [CHART_NAME.overdueRecOrderSchedule, fetchPurchaseWorkbenchOverdueRecOrder],
  [CHART_NAME.onTimeCompletionRate, fetchPurchaseWorkbenchOntimeCompletionRate],
  [CHART_NAME.onTimeDeliveryRate, fetchPurchaseWorkbenchOntimeDeliveryRate],
  [CHART_NAME.averageDeliveryCycle, fetchPurchaseWorkbenchAvgDeliveryCycle],
]);

export const workbench = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setConfig(state, payload) {
      return { ...state, config: payload };
    },
    setData(state, payload: { [key: string]: any }) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    async fetchConfig() {
      try {
        const { data, code } = await fetchPurchaseWorkbenchConfig();

        if (code === 200) {
          dispatch({
            type: 'workbench/setConfig',
            payload: data,
          });
        }
      } catch (e) {
        // @ts-ignore
      }
    },
    async fetchChartsData(key: CHART_NAME): Promise<any> {
      const fetchFn = fetchFnMap.get(key);

      try {
        const { data, code, message: _message } = await fetchFn?.();

        if (code === 200) {
          dispatch({
            type: 'workbench/setData',
            payload: {
              [key]: data,
            },
          });
        } else {
          message.error(_message);
          dispatch({
            type: 'workbench/setData',
            payload: {
              [key]: null,
            },
          });
        }
      } catch (e) {
        // @ts-ignore
      }
    },
  }),
});
