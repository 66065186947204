import { WarehouseEnumTab } from '../../interface';
import TabList from '../../share/tabList';
import { FC } from 'react';
import InBound from './InBound';
import OutBound from './outBound';

interface WarehouseTabProps {
  traceId?: number;
}

const WarehouseTab: FC<WarehouseTabProps> = ({ traceId }) => {
  const tabList = [
    {
      tab: '入库记录',
      key: WarehouseEnumTab.INBOUND_RECORDS,
      component: <InBound traceId={traceId} />,
    },
    {
      tab: '出库记录',
      key: WarehouseEnumTab.OUTBOUND_RECORDS,
      component: <OutBound traceId={traceId} />,
    },
  ];

  return <TabList tabList={tabList} />;
};

export default WarehouseTab;
