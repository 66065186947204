import { ShipmentNoteForm, ShipmentNoteFormItemList, ShipmentNoteItemsRequest } from './interface';
import { initCustomFieldsInData, formatCustomFieldsInData } from 'src/components/customField';

export const formatItemsValue = (
  values: ShipmentNoteForm,
  isCreating: boolean | undefined,
  itemCustomFields: any,
): ShipmentNoteItemsRequest[] => {
  const { materials: itemsList } = values;

  const _itemsList = itemsList?.map((node: ShipmentNoteFormItemList, index) => {
    const {
      material,
      deliveryDate,
      remark,
      salesOrderId,
      salesOrderItemId,
      plannedIssueAmount,
      id,
      addType,
      cdsnId,
      cdsnItemId,
      cdsnStatus,
      soStatus,
      availableIssueAmount,
      customFields,
    } = node;
    const data: any = {
      amount: Number(plannedIssueAmount?.amountDisplay),
      unitId: plannedIssueAmount?.unitId,
      deliveryDate,
      materialId: material?.baseInfo?.id,
      seqNo: String(index + 1),
      soId: salesOrderId,
      soItemId: salesOrderItemId,
      remark,
      id: addType === 'new' ? undefined : id,
      cdsnId,
      cdsnItemId,
      cdsnStatus,
      soStatus,
      availableIssueAmount: availableIssueAmount?.amount,
      customFields,
    };

    return formatCustomFieldsInData({
      data,
      customFields: itemCustomFields,
    });
  });

  return _itemsList;
};

export const initFormValues = (
  values: ShipmentNoteFormItemList[],
  isCreate: boolean,
): ShipmentNoteFormItemList[] => {
  if (isCreate) {
    return values?.map((item) => {
      // 计划发货数 默认值 = 待发货数
      item.plannedIssueAmount.amountDisplay = item?.availableIssueAmount?.amountDisplay;
      return initCustomFieldsInData(item);
    });
  }
  return initCustomFieldsInData(values);
};
