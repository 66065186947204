/** 查询综合预留orderType
 * 单据类型 1：计划订单 2：生产工单
 */
/**
 * 预留生成方式
 */
export enum ReservedOrderType {
  /** 计划订单 */
  PLAN_ORDER = 1,
  /** 生产工单 */
  PRODUCT_ORDER,
}
