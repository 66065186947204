import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Form, message as Message } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import _ from 'lodash';

import { fetchPickOrderGet } from 'src/api/ytt/med-domain/store-requisition';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { replaceSign } from 'src/utils/constants';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { lookup } from 'src/dict';
import { StatusEnum } from 'src/dict/storeRequisition';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import { FormDataType, formatMaterialLineToDisplay, goToEdit, goToMergeTranfer } from '../utils';
import { fieldLabels, StoreRequisitionDetailType, NOTHING_TO_TRANSFER } from '../constants';
import { closeStoreRequisiton } from '../services';
import { statusBadgeMap } from '../StoreRequisitionList';
import StoreRequisitionDetailTable from '../components/StoreRequisitionTable/detail';

interface StoreRequisitionDetailProps {
  id?: string;
}

export default function StoreRequisitionDetail(
  props: RouteComponentProps<StoreRequisitionDetailProps>,
) {
  const [detailData, setDetailData] = useState<FormDataType>();
  const [loading, setLoading] = useState(false);
  const [visibleLog, setVisibleLog] = useState(false);
  // 从url中获取id参数
  const { match } = props;
  const storeRequisitionId = match.params.id;
  const [detailForm] = Form.useForm();
  const [closeForm] = Form.useForm();
  const dispatch = useDispatch();

  const basicInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: fieldLabels.storeRequisitionCode,
        dataIndex: 'pickOrderCode',
      },
      {
        label: fieldLabels.status,
        dataIndex: 'pickOrderStatus',
        render: (status) => statusBadgeMap.get(status),
      },
      {
        label: fieldLabels.type,
        dataIndex: 'pickOrderType',
        render: lookup('storeRequisiton.PickOrderTypeMap'),
      },
    ],
  };
  const materialInfo: DetailLayoutInfoBlock = {
    title: '物料信息',
    items: [
      {
        dataIndex: 'materials',
        render: (_text) => {
          return <StoreRequisitionDetailTable materials={_text} type={detailData?.pickOrderType} />;
        },
      },
    ],
  };
  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: fieldLabels.creator,
        dataIndex: 'creator',
        render: (_text: any, record: StoreRequisitionDetailType) => {
          if (!record) return '-';
          const { creator } = record;

          return creator ? (
            <AvatarDisplay
              isUser
              isShowTag={false}
              id={creator.id}
              name={creator.name}
              avatarUrl={creator.avatarUrl}
            />
          ) : (
            replaceSign
          );
        },
      },
      {
        label: fieldLabels.createdAt,
        dataIndex: 'createdAt',
        render: formatTimeForRender,
      },
      {
        label: fieldLabels.operator,
        dataIndex: 'operator',
        render: (_text: any, record: StoreRequisitionDetailType) => {
          if (!record) return '-';
          const { operator } = record;

          return operator ? (
            <AvatarDisplay
              isUser
              isShowTag={false}
              id={operator.id}
              name={operator.name}
              avatarUrl={operator.avatarUrl}
            />
          ) : (
            replaceSign
          );
        },
      },

      {
        label: fieldLabels.updatedAt,
        dataIndex: 'updatedAt',
        render: formatTimeForRender,
      },
    ],
  };
  const detailInfo: DetailLayoutInfoBlock[] = [basicInfo, materialInfo, otherInfo];

  const refresh = () => {
    loadDetailData();
  };

  const baseMenu = _.compact([
    detailData?.pickOrderStatus === StatusEnum.new && {
      key: 'close',
      auth: authDict.pickorder_close,
      onClick: () => {
        BLDelConfirm('关闭后将无法对领料申请进行操作，确认关闭吗？', closeForm, async () => {
          setLoading(true);
          await closeStoreRequisiton(Number(storeRequisitionId), closeForm);
          refresh();
        });
      },
      title: '关闭',
    },
    detailData?.pickOrderStatus === StatusEnum.new && {
      key: 'transfer',
      auth: authDict.transferorder_add,
      onClick: () => {
        // 过滤掉申请数-发起调拨数<=0的物料行
        // HHZZ3-33061 允许调拨数计算方法变更：发起调拨数更改为仓库调拨数（扣除那些发起调拨但未完成的数量）
        const materialsToTransfer = detailData.materials
          ?.filter(
            (material) =>
              material.requestPickAmount?.amount! - (material.warehouseIssuedAmount?.amount ?? 0) >
              0,
          )
          ?.map((material) => {
            // HHZZ3-30101 列表与详情页的仓库字段格式不同，跳转前分别处理
            const { sourceWarehouseId, targetWarehouseId, ...rest } = _.omit(material, [
              'targetWarehouseName',
              'sourceWarehouseName',
            ]);

            return {
              ...rest,
              sourceWarehouse: sourceWarehouseId,
              targetWarehouse: targetWarehouseId,
            };
          });

        if (materialsToTransfer?.length === 0) {
          Message.error(NOTHING_TO_TRANSFER);
        } else {
          // 保存数据到redux
          dispatch({
            type: 'transferMaterials/setTansferMaterials',
            payload: materialsToTransfer,
          });
          goToMergeTranfer();
        }
      },
      title: '调拨',
    },
    {
      key: 'record',
      auth: authDict.pickorder_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    detailData?.pickOrderStatus === StatusEnum.new && {
      key: 'edit',
      auth: authDict.pickorder_edit,
      onClick: () => {
        goToEdit(Number(storeRequisitionId));
      },
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ]);

  const loadDetailData = async () => {
    setLoading(true);
    const response = await fetchPickOrderGet({ pickOrderId: Number(storeRequisitionId)! });

    if (response.code === 200 && response.data) {
      setDetailData(formatMaterialLineToDisplay(response.data));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (detailData) detailForm.setFieldsValue({ materials: detailData.materials });
  }, [detailData]);

  useEffect(() => {
    loadDetailData();
  }, []);

  return (
    <>
      <DetailLayout
        title="领料申请详情"
        baseMenu={baseMenu}
        info={detailInfo}
        dataSource={detailData}
        loading={loading}
        userAuth={getAuthFromLocalStorage()}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={Number(storeRequisitionId)}
          objectCode={'PickOrder'} // 对象在对象平台唯一定义的objectCode
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
}
