import { useState, useCallback, useEffect } from 'react';
import { Form, message, Checkbox, Space } from 'antd';
import type { CheckboxProps } from 'antd';
import { debounce } from 'lodash';

import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';
import { BlIcon } from 'src/components';
import * as colors from 'src/styles/color';

import {
  fetchProduceTaskGetAutoConfig,
  fetchProduceTaskUpdateAutoConfig,
  FetchProduceTaskUpdateAutoConfigRequest,
} from 'src/api/ytt/mfg-domain/productionTask';

interface SetProductionWorkOrderProps {
  onCancel: () => void;
}

const SetProductionWorkOrder = (props: SetProductionWorkOrderProps) => {
  const [form] = Form.useForm<any>();
  const { onCancel } = props;
  const [switchCompletion, setSwitchCompletion] = useState<Boolean>();

  const [switchFinish, setonSwitchFinish] = useState<Boolean>();

  useEffect(() => {
    fetchProduceTaskGetAutoConfig()
      .then((res) => {
        const { data } = res;

        const initData = formatDataToInit(data);

        form.setFieldsValue(initData);
      })
      .catch((err) => {
        console.log('err: ', err);
        // history.goBack();
      })
      .finally(() => {
        // setLoading(false);
      });
  }, []);

  const formatDataToInit = (data: any) => {
    const { autoFinishFlag, autoStartFlag } = data;

    if (autoStartFlag) {
      setSwitchCompletion(true);
    }
    if (autoFinishFlag) {
      setonSwitchFinish(true);
    }

    return {
      autoStartFlag: !!autoStartFlag,
      autoFinishFlag: !!autoFinishFlag,
    };
  };

  // 工单自动完工
  const onSwitchCompletion = useCallback<Required<CheckboxProps>['onChange']>((enable) => {
    if (enable.target.checked) {
      setSwitchCompletion(true);
      message.success(
        ' 开启自动开工，系统会在每天 00:00 开工所有满足条件的生产任务。当前预置条件为：任务状态=待执行',
      );
    } else {
      setSwitchCompletion(false);
    }
  }, []);

  // 工单自动关闭
  const onSwitchFinish = useCallback<Required<CheckboxProps>['onChange']>((enable) => {
    if (enable.target.checked) {
      setonSwitchFinish(true);
      message.success(
        '开启自动完工，系统会在每天 00:00 完工所有满足条件的生产任务。当前预置条件为：任务状态=执行中，任务报工数>=任务计划数',
      );
    } else {
      setonSwitchFinish(false);
    }
  }, []);

  const baseInfo: DataFormLayoutInfoBlock = {
    title: '',
    // column: 1,
    items: [
      {
        isFullLine: true,
        colon: false,
        validateFirst: true,
        noStyle: true,
        render: () => (
          <Form.Item
            name={['autoStartFlag']}
            fieldKey={['autoStartFlag']}
            style={{ marginBottom: '0' }}
            valuePropName="checked"
          >
            <Checkbox onChange={onSwitchCompletion}>任务自动开工</Checkbox>
          </Form.Item>
        ),
      },
      {
        colon: false,
        validateFirst: true,
        hidden: !switchCompletion,
        isFullLine: true,
        noStyle: true,
        render: () => (
          <Form.Item
            wrapperCol={{ offset: 1 }}
            valuePropName="checked"
            initialValue={{ checked: false }}
            style={{ marginBottom: 40 }}
          >
            <Space align="start" size={4}>
              <BlIcon type="icontixing-jingshi" style={{ color: colors.warning }} />
              <span style={{ color: colors.warning }}>
                <p style={{ margin: 0 }}>
                  开启自动开工，系统会在每天 00:00 开工所有满足条件的生产任务
                </p>
                <p style={{ margin: 0 }}>当前预置条件为：任务状态 = 待执行</p>
              </span>
            </Space>
          </Form.Item>
        ),
      },
      {
        colon: false,
        validateFirst: true,
        isFullLine: true,
        noStyle: true,
        render: () => (
          <Form.Item
            name={['autoFinishFlag']}
            fieldKey={['autoFinishFlag']}
            style={{ marginBottom: '0' }}
            valuePropName="checked"
          >
            <Checkbox onChange={onSwitchFinish}>任务自动完工</Checkbox>
          </Form.Item>
        ),
      },
      {
        validateFirst: true,
        hidden: !switchFinish,
        colon: false,
        isFullLine: true,
        noStyle: true,
        render: () => (
          <Form.Item
            wrapperCol={{ offset: 1 }}
            valuePropName="checked"
            initialValue={{ checked: true }}
          >
            <Space align="start" size={4}>
              <BlIcon type="icontixing-jingshi" style={{ color: colors.warning }} />
              <span style={{ color: colors.warning }}>
                <p style={{ margin: 0 }}>
                  开启自动完工，系统会在每天 00:00 完工所有满足条件的生产任务
                </p>
                <p style={{ margin: 0 }}>
                  当前预置条件为：任务状态 = 执行中，任务报工数 {'>='} 任务计划数
                </p>
              </span>
            </Space>
          </Form.Item>
        ),
      },
    ],
  };

  const handleFinish = debounce(async () => {
    try {
      const formValue = await form?.validateFields();

      const { autoFinishFlag, autoStartFlag } = formValue;
      const submitVal: FetchProduceTaskUpdateAutoConfigRequest = {
        ...formValue,
        autoStartFlag: Number(autoStartFlag),
        autoFinishFlag: Number(autoFinishFlag),
      };

      await fetchProduceTaskUpdateAutoConfig(submitVal).then(() => {
        message.success('设置成功');
        onCancel();
      });
    } catch (error) {
      console.log('error: ', error);
    }
  }, 1500);

  const bodyStyle = {
    padding: 0,
    display: 'flex',
    marginTop: -24,
    marginBottom: 24,
  };

  return (
    <DataFormLayout
      form={form}
      info={[baseInfo]}
      title={'设置'}
      onCancel={() => {
        onCancel();
      }}
      onFinish={() => {
        handleFinish();
      }}
      bodyStyle={bodyStyle}
    />
  );
};

export default SetProductionWorkOrder;
