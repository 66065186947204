import { useState } from 'react';
import { Form, Input, message as Message } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/es/table';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { BlSortFormList } from '@blacklake-web/component';

import { fetchSalesOrderReturnGoodsReceipt } from 'src/api/ytt/order-domain/salesReturnOrder';
import { materialEntity } from 'src/entity';
import { numberMinMaxCheck, fractionLengthCheck } from 'src/utils/formValidators';
import { amountRender } from 'src/page/share/renderAmount';
import { fieldLabels } from '../constants';
import type { SalesReturnOrderListItemType, SalesReturnDetailListItemType } from '../interface';

// 表单字段名
const receiveGoodsTableName = 'receivingGoods';
/** 默认单位精度 */
const DEFAULT_PRECISION = 10;

export default function ReceiveGoodsDialog({
  returnOrder,
  onFinish,
  onCancel,
}: {
  returnOrder: SalesReturnOrderListItemType;
  onFinish?: () => void;
  onCancel: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const [receiveGoodsForm] = Form.useForm();
  const { getFieldValue } = receiveGoodsForm;

  receiveGoodsForm.setFieldsValue({ [receiveGoodsTableName]: returnOrder.items });

  const getColumns: (
    remove: (index: number | number[]) => void,
  ) => ColumnProps<any & FormListFieldData>[] = () => {
    return [
      {
        title: fieldLabels.lineNo,
        dataIndex: 'num',
        width: 60,
        render: (text, field) => field.name + 1,
      },
      {
        title: fieldLabels.code,
        dataIndex: 'code',
        width: 140,
        render: () => returnOrder.code,
      },
      {
        title: fieldLabels.purchaseReturnOrderCode,
        dataIndex: 'purchaseReturnOrderCode',
        width: 160,
        render: () => returnOrder.purchaseOrderReturnCode,
      },
      {
        title: fieldLabels.materialCode,
        dataIndex: 'materialCode',
        width: 140,
        render: (text, field) =>
          receiveGoodsForm.getFieldValue([
            receiveGoodsTableName,
            field.name,
            'material',
            'baseInfo',
            'code',
          ]),
      },
      {
        title: fieldLabels.materialName,
        dataIndex: 'materialName',
        width: 140,
        render: (text, field) =>
          receiveGoodsForm.getFieldValue([
            receiveGoodsTableName,
            field.name,
            'material',
            'baseInfo',
            'name',
          ]),
      },
      {
        title: fieldLabels.materialProperty,
        dataIndex: 'materialProperty',
        width: 150,
        render: (text, field) =>
          materialEntity
            .getMaterialAttrs(
              receiveGoodsForm.getFieldValue([receiveGoodsTableName, field.name, 'material']),
            )
            .join(';'),
      },
      {
        title: fieldLabels.plannedReturnAmount,
        dataIndex: 'amount',
        width: 130,
        render: (text, field) =>
          amountRender(
            receiveGoodsForm.getFieldValue([receiveGoodsTableName, field.name, 'amount']),
          ),
      },
      {
        title: fieldLabels.inboundAmount,
        dataIndex: 'inboundAmount',
        width: 150,
        render: (_text, field) => {
          const inbountAmount = getFieldValue([receiveGoodsTableName, field.name, 'inboundAmount']);
          const material = getFieldValue([receiveGoodsTableName, field.name, 'material']);
          const { precisionFigure } = materialEntity.getMaterialUnitInfo(
            material,
            inbountAmount.unitId,
          );

          return (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'inboundAmount', 'amount']}
              name={[field.name, 'inboundAmount', 'amount']}
              rules={[
                {
                  required: true,
                  message: `${fieldLabels.inboundAmount}必填`,
                },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: false,
                    fieldName: fieldLabels.inboundAmount,
                  }),
                },
                {
                  // 精度校验从单位中获取
                  validator: fractionLengthCheck(precisionFigure ?? DEFAULT_PRECISION),
                },
              ]}
            >
              <Input placeholder="请输入" />
            </Form.Item>
          );
        },
      },
      {
        title: fieldLabels.unit,
        dataIndex: 'unit',
        width: 60,
        render: (text, field) =>
          receiveGoodsForm.getFieldValue([
            receiveGoodsTableName,
            field.name,
            'inboundAmount',
            'unitName',
          ]),
      },
      {
        title: fieldLabels.salesOrderCode,
        dataIndex: 'salesOrderCode',
        width: 150,
        render: (text, field) =>
          receiveGoodsForm.getFieldValue([receiveGoodsTableName, field.name, 'salesOrderCode']),
      },
      {
        title: fieldLabels.salesOrderItemLineNo,
        dataIndex: 'salesOrderItemLineNo',
        width: 150,
        render: (text, field) =>
          receiveGoodsForm.getFieldValue([
            receiveGoodsTableName,
            field.name,
            'salesOrderItemLineNo',
          ]),
      },
    ];
  };

  const saveReceiveGoods = async () => {
    try {
      await receiveGoodsForm.validateFields();

      const formData: SalesReturnDetailListItemType[] =
        receiveGoodsForm.getFieldValue(receiveGoodsTableName);
      const requestParam = {
        id: returnOrder.id,
        items: formData.map((good) => ({
          id: good.id,
          inboundAmount: Number(good.inboundAmount?.amount),
          salesOrderId: good.salesOrderId!,
          salesOrderItemId: good.salesOrderItemId!,
        })),
      };

      setLoading(true);
      const response = await fetchSalesOrderReturnGoodsReceipt(requestParam);

      setLoading(false);
      if (response.code === 200) {
        Message.success('收货成功');
        onFinish?.();
        onCancel();
      } else {
        Message.error(response.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const baseInfo: DataFormLayoutInfoBlock[] = [
    {
      items: [
        {
          label: '',
          isFullLine: true,
          render: () => (
            <BlSortFormList
              name={receiveGoodsTableName}
              renderColumns={getColumns}
              isNeedDrag={false}
              isNeedAdd={false}
              isNeedDelete={false}
            />
          ),
        },
      ],
    },
  ];

  return (
    <DataFormLayout
      title="收货"
      form={receiveGoodsForm}
      info={baseInfo}
      loading={loading}
      onCancel={onCancel}
      onFinish={saveReceiveGoods}
    />
  );
}
