import React, { useEffect, useState } from 'react';
import { Modal, Tabs } from 'antd';
import _ from 'lodash';
import BaseInfo from './baseInfo';
import RelationshipInfo from './relationInfo';
import { appEnum } from 'src/dict';
import { CheckEntityTypeEnum, CheckTypeEnum, RecordSampleEnum } from 'src/dict/quality';
import { ICustomFields } from 'src/components/customField/interface';
import TriggerRules from './triggerRules';
interface TabContentProps {
  form: any;
  isEdit: boolean;
  customFields: ICustomFields;
  id: number;
}
interface ChangeValues {
  checkType: string | number;
  recordSample: RecordSampleEnum;
  checkEntityType: CheckEntityTypeEnum;
}

const ActionContent = (props: TabContentProps) => {
  const { id, form, isEdit, customFields } = props;
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [checkType, setCheckType] = useState<number>(
    form.getFieldValue('checkType') || appEnum.Quality.CheckTypeEnum.inputFactoryQc,
  );
  const [recordSample, setRecordSample] = useState<RecordSampleEnum>(
    form.getFieldValue('recordSample') || RecordSampleEnum.record,
  );
  const [checkEntityType, setCheckEntityType] = useState<CheckEntityTypeEnum>(
    form.getFieldValue('checkEntityType') || CheckEntityTypeEnum.SameAsBatchCount,
  );
  const [lastCheckEntityType, setLastCheckEntityType] = useState<number>(
    form.getFieldValue('checkEntityType') || CheckEntityTypeEnum.SameAsBatchCount,
  );

  const tabPaneListConfig = _.compact([
    {
      key: 'baseInfo',
      tabTitle: '基础信息',
    },
    checkType !== CheckTypeEnum.generalQc && {
      key: 'relationInfo',
      tabTitle: '关联信息',
    },
    isEdit && {
      key: 'defaultTrigger',
      tabTitle: '预置触发规则/事件',
    },
  ]);

  const handleFormDataChange = (changedValues: ChangeValues) => {
    if (typeof changedValues.checkEntityType !== 'undefined') {
      form.setFieldsValue({
        checkEntityUnitCount: form.getFieldValue('checkEntityUnitCount') || 1,
      });
      setCheckEntityType(Number(changedValues.checkEntityType));
    }
    if (typeof changedValues.checkType !== 'undefined') {
      form.setFieldsValue({
        qcConfigProcessList: [],
        qcConfigMaterialList: [],
        qcConfigResourceList: [],
        qcConfigCustomerList: [],
        qcConfigSupplierList: [],
      });
      setCheckType(Number(changedValues.checkType));
    }
    if (typeof changedValues.recordSample !== 'undefined') {
      setRecordSample(Number(changedValues.recordSample));
    }
    if (typeof changedValues.checkEntityType !== 'undefined') {
      if (
        form
          .getFieldValue('checkItemList')
          ?.filter((checkitemGroup: { list: any[] }) => checkitemGroup.list?.length)?.length &&
        (changedValues.checkEntityType === CheckEntityTypeEnum.useCheckItemConfig ||
          changedValues.checkEntityType === CheckEntityTypeEnum.Custom)
      ) {
        setIsShowModal(true);
      } else {
        setLastCheckEntityType(form.getFieldValue('checkEntityType'));
      }
    }
  };

  return (
    <div style={{ overflow: 'auto', height: '100%' }}>
      <Tabs defaultActiveKey="baseInfo" tabBarStyle={{ marginLeft: 20 }}>
        {tabPaneListConfig.map((tabPaneConfig) => (
          <Tabs.TabPane
            style={{ overflow: 'auto', height: '100%' }}
            tab={tabPaneConfig.tabTitle}
            key={tabPaneConfig.key}
            forceRender
          >
            {tabPaneConfig.key === 'baseInfo' && (
              <BaseInfo
                form={form}
                isEdit={isEdit}
                checkType={checkType}
                recordSample={recordSample}
                checkEntityType={checkEntityType}
                customFields={customFields}
                formProps={{
                  layout: 'horizontal',
                  labelCol: { flex: '140px' },
                  onValuesChange: handleFormDataChange,
                }}
              />
            )}
            {tabPaneConfig.key === 'relationInfo' && (
              <RelationshipInfo
                form={form}
                checkType={checkType}
                formProps={{
                  layout: 'horizontal',
                  labelCol: { flex: '140px' },
                  onValuesChange: handleFormDataChange,
                }}
              />
            )}
            {tabPaneConfig.key === 'defaultTrigger' && <TriggerRules form={form} id={id} />}
          </Tabs.TabPane>
        ))}
      </Tabs>
      <Modal
        title=""
        visible={isShowModal}
        onOk={() => {
          const checkItemList = form.getFieldValue('checkItemList');

          form.setFieldsValue({
            checkItemList: checkItemList?.map((group: { list: any[] }) => {
              group.list = group.list?.map((checkItem) => ({
                ...checkItem,
                totalReportCount: null,
                requireReportCount: null,
              }));
              return group;
            }),
          });
          setIsShowModal(false);
        }}
        onCancel={() => {
          form.setFieldsValue({
            checkEntityType: lastCheckEntityType,
          });
          setIsShowModal(false);
        }}
        okText="确认"
        cancelText="取消"
      >
        <p>切换后所有检验项的【必填份数】与【总份数】将重置，是否继续？ </p>
      </Modal>
    </div>
  );
};

export default ActionContent;
