/**
 * @description 触发规则 列表页
 */

import { useState } from 'react';
import { message } from 'antd';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';

import { fieldTypeList } from 'src/utils';
import lookup, { appEnum, appDict } from 'src/dict';
import { CRUD } from 'src/dict/common';
import authDict from 'src/utils/auth';

import { RecordListLayout, FilterItem } from 'layout';
import { BlIcon, SearchSelect, showErrorListMessage, LinkTooltip } from 'src/components';

import renderUsageStatus from 'src/page/share/renderUsageStatus';

import {
  fetchTriggerRulePageList,
  fetchTriggerRuleEnable,
  fetchTriggerRuleDisable,
} from 'src/api/ytt/trigger-domain/triggerRule';

import { getDetailPageUrl, getCreatePageUrl, getEditPageUrl } from './utils/navigation';
import { isGeneralRule, isBusinessRule, VIEW_MODAL } from './constant';

interface TriggerRuleProps extends RouteComponentProps {
  ruleSource: string;
}

const TriggerRule: React.FC<TriggerRuleProps> = (props) => {
  const { history, ruleSource } = props;

  // 刷新用的时间戳，在refresh方法里用
  const [refreshMarker, setRefreshMarker] = useState<number>(Date.now());
  // 选中行的key，用以批量删除
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  /** 刷新列表函数 */
  const refreshTable = () => setRefreshMarker(Date.now() + Math.random() * 100);
  const columns = [
    {
      title: '规则名称',
      dataIndex: 'name',
      width: 150,
      sorter: true,
      isFilter: true,
      filterType: fieldTypeList.text,
      render: (name: string, record: any, index: number, config: any) => (
        <LinkTooltip
          to={getDetailPageUrl(record.ruleId, ruleSource)}
          text={name}
          width={config.contentWidth}
          auth={authDict.triggerrules_detail}
        />
      ),
    },
    {
      title: '规则编号',
      dataIndex: 'code',
      width: 150,
      sorter: true,
      isFilter: true,
      filterType: fieldTypeList.text,
      render: (code: string, record: any, index: number, config: any) => (
        <LinkTooltip
          to={getDetailPageUrl(record.ruleId, ruleSource)}
          text={code}
          width={config.contentWidth}
          auth={authDict.triggerrules_detail}
        />
      ),
    },
    {
      title: '上游规则',
      dataIndex: 'parentRuleName',
      filterName: 'parentRuleIds',
      width: 150,
      isFilter: true,
      filterType: fieldTypeList.select,
      renderItem: <SearchSelect fetchType="triggerRule" mode="multiple" />,
    },
    {
      title: '业务对象',
      dataIndex: 'bizObjectName',
      filterName: 'objectCodes',
      width: 150,
      isFilter: true,
      filterType: fieldTypeList.select,
      renderItem: <SearchSelect fetchType="bizObjectByCode" mode="multiple" />,
    },
    // {
    //   title: '业务类型',
    //   dataIndex: 'bizType',
    //   filterName: 'objectBizType',
    //   width: 150,
    //   isFilter: true,
    //   filterType: fieldTypeList.select,
    //   selectProps: { options: appDict.triggerRule.objectBizType },
    // },
    {
      title: '规则来源',
      dataIndex: 'sourceType',
      width: 150,
      isFilter: ruleSource === VIEW_MODAL.generalRule,
      filterType: fieldTypeList.select,
      selectProps: { options: _.initial(appDict.triggerRule.ruleSourceType), mode: 'multiple' },
      render: (sourceType: number) => <div>{lookup('triggerRule.ruleSourceType', sourceType)}</div>,
    },
    ...isBusinessRule(ruleSource, [
      {
        title: '来源对象',
        dataIndex: 'bizSourceObjectName',
        width: 150,
        isFilter: true,
        filterType: fieldTypeList.select,
        renderItem: <SearchSelect fetchType="bizObjectByCode" mode="multiple" />,
      },
      {
        title: '来源对象实例',
        dataIndex: 'bizSourceInstanceCode',
        width: 150,
      },
    ]),
    {
      title: '启用状态',
      dataIndex: 'status',
      width: 150,
      isFilter: true,
      filterType: fieldTypeList.select,
      selectProps: { options: appDict.common.usageStatus },
      render: renderUsageStatus,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
    },
  ];

  const filterList: FilterItem[] = columns
    .filter((i) => i.isFilter)
    .map((column) => {
      const filter: any = {
        label: column.title,
        name: column.filterName ?? column.dataIndex,
        type: column.filterType,
        renderItem: column.renderItem,
      };

      if (column.filterType === fieldTypeList.select) {
        filter.selectProps = column.selectProps;
      }
      return filter;
    });

  const showRuleErrorListMessage = (
    successCount: number,
    failCount: number,
    failReasons: any,
    title: string,
  ) =>
    showErrorListMessage({
      failCount,
      successCount,
      title,
      data: _.map(failReasons, (reason) => ({ reason })),
      columns: [{ title: '失败原因', dataIndex: 'reason' }],
    });

  /** @description 批量操作按钮 */
  const batchMenu = [
    //  * batchMenu onClick回调的参数
    //  * onSuccess会刷新列表并清空批量按钮的loading状态
    //  * onFail只清空loading状态
    {
      title: lookup('common.crud', CRUD.enable),
      auth: authDict.triggerrules_enable_disable,
      onClick: (onSuccess?: () => void, onFail?: () => void) => {
        fetchTriggerRuleEnable({ ruleIds: selectedRowKeys as number[] })
          .then((res) => {
            const { successCount = 0, failCount = 0, failReasons = [] } = res?.data ?? {};

            if (failCount) {
              showRuleErrorListMessage(successCount, failCount, failReasons, '启用出现失败');
            } else {
              message.success('启用成功');
            }
            onSuccess?.();
          })
          .catch(() => onFail?.());
      },
    },
    {
      title: lookup('common.crud', CRUD.disable),
      auth: authDict.triggerrules_enable_disable,
      onClick: (onSuccess?: () => void, onFail?: () => void) => {
        fetchTriggerRuleDisable({ ruleIds: selectedRowKeys as number[] })
          .then((res) => {
            const { successCount = 0, failCount = 0, failReasons = [] } = res?.data ?? {};

            if (failCount) {
              showRuleErrorListMessage(successCount, failCount, failReasons, '停用出现失败');
            } else {
              message.success('停用成功');
            }
            onSuccess?.();
          })
          .catch(() => onFail?.());
      },
    },
  ];

  /** 右上角主要按钮 */
  const mainMenu = [
    {
      title: '新建',
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.triggerrules_add,
      onClick: () => history.push(getCreatePageUrl()),
      items: [],
    },
  ];

  /** 获取列表页每一项的操作按钮 */
  const getOperationList = (record: any) => {
    const { ruleId, status } = record;
    const isDisabled = status === appEnum.Common.UsageStatus.disabled;
    const statusTitle = isDisabled ? '启用' : '停用';
    const commonTypeOperates = [
      {
        title: lookup('common.crud', CRUD.edit),
        onClick: () => history.push(getEditPageUrl(ruleId)),
        auth: authDict.triggerrules_edit,
        disabled: status === appEnum.Common.UsageStatus.enabled,
      },
      {
        title: lookup('common.crud', CRUD.copy),
        onClick: () => history.push(getCreatePageUrl(ruleId)),
        auth: authDict.triggerrules_add,
      },
      {
        title: statusTitle,
        auth: authDict.triggerrules_enable_disable,
        onClick: () => {
          let reqFn: any = fetchTriggerRuleDisable;

          if (status === appEnum.Common.UsageStatus.disabled) {
            reqFn = fetchTriggerRuleEnable;
          }

          reqFn({ ruleIds: [ruleId] as number[] }).then((res) => {
            if (res.code === 200) {
              const { failCount, failReasons } = res.data;

              if (failCount > 0) {
                message.error(`${statusTitle}失败`);
                message.error(failReasons[0]);
              } else {
                message.success(`${statusTitle}成功`);
                refreshTable();
              }
            }
          });
        },
      },
    ];

    return [
      {
        title: lookup('common.crud', CRUD.view),
        auth: authDict.triggerrules_detail,
        onClick: () => history.push(getDetailPageUrl(ruleId, ruleSource)),
      },
      ...isGeneralRule(ruleSource, commonTypeOperates),
    ];
  };

  const formatDataToQuery = (params: any) => {
    const { parentRuleIds, objectCodes, sourceType, bizSourceObjectName, ...restParams } = params;
    // 通用规则列表，规则来源于「手动创建」和 「系统预置」
    // 业务规则列表，规则来源于 「业务自动创建」
    const _sourceType =
      ruleSource === VIEW_MODAL.generalRule
        ? [appEnum.TriggerRule.RuleSourceType.manual, appEnum.TriggerRule.RuleSourceType.preset]
        : [appEnum.TriggerRule.RuleSourceType.businessAutomation];
    const _params = {
      ...restParams,
      parentRuleIds: _.isEmpty(parentRuleIds) ? undefined : _.map(parentRuleIds, 'value'),
      objectCodes: _.isEmpty(objectCodes) ? undefined : _.map(objectCodes, 'value'),
      sourceType: _.isEmpty(sourceType) ? _sourceType : sourceType,
      bizSourceObjectCodes: _.isEmpty(bizSourceObjectName)
        ? undefined
        : _.map(bizSourceObjectName, 'value'),
    };

    return _params;
  };

  return (
    <RecordListLayout<any>
      columns={columns}
      refreshMarker={refreshMarker}
      filterList={filterList}
      requestFn={fetchTriggerRulePageList}
      placeholder="请输入规则编号或名称"
      batchMenu={isGeneralRule(ruleSource, batchMenu)}
      mainMenu={isGeneralRule(ruleSource, mainMenu)}
      rowKey="ruleId"
      selectedRowKeys={selectedRowKeys}
      onSelectedRowKeys={(selectedKeys) => setSelectedRowKeys(selectedKeys)}
      // formatDataToDisplay={formatDataToDisplay}
      formatDataToQuery={formatDataToQuery}
      // formatDataToFormDisplay={formatDataToFormDisplay}
      getOperationList={getOperationList}
    />
  );
};

export default TriggerRule;
