import React from 'react';
import { DetailLayout } from 'layout';
//
import { BomResponseInputMaterial } from '../../index.type';
import FeedingControlTable from './feedingControlTable';

interface FeedingControlDetailProps {
  dataSource?: BomResponseInputMaterial;
}

const FeedingControlDetail = (props: FeedingControlDetailProps) => {
  const { dataSource } = props;

  const getBaseInfo = () => {
    const baseInfo = [
      {
        title: '',
        items: [
          {
            label: '所属子项',
            dataIndex: 'seq',
          },
          {
            label: '父项物料编号',
            dataIndex: ['material', 'baseInfo', 'code'],
          },
          {
            label: '父项物料名称',
            dataIndex: ['material', 'baseInfo', 'name'],
          },
          {
            label: '用量：分子',
            dataIndex: 'inputAmountNumerator',
          },
          {
            label: '用量：分母',
            dataIndex: 'inputAmountDenominator',
          },
          {
            label: '单位',
            dataIndex: 'unitName',
          },
          {
            label: '投料工序号',
            isFullLine: true,
            dataIndex: ['inputProcessRouteNodeSimpleVO', 'processNum'],
          },
          {
            label: '',
            dataIndex: 'placehold_3',
            isFullLine: true,
            render: () => <FeedingControlTable dataSource={dataSource?.bomFeedingControls} />,
          },
        ],
      },
    ];

    return baseInfo;
  };

  return <DetailLayout title="投料管控详情" info={getBaseInfo()} dataSource={dataSource} />;
};

export default FeedingControlDetail;
