import React, { useEffect, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';
import { Form, Checkbox, Input, message, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ACTION_CREATE, ACTION_CREATE_CHIRLD, ACTION_EDIT } from '../constant';

import {
  fetchMaterialCategoryCreate,
  fetchMaterialCategoryCreateChild,
  FetchMaterialCategoryListResponse,
  fetchMaterialCategoryUpdate,
} from 'src/api/ytt/material-domain/Web_MaterialCategory';
import { NumberRulesStandardHook } from 'src/components';
import { ObjectCode } from 'src/dict/common';
import { textValidator2, checkTwoSidesTrim } from 'src/utils/formValidators';

interface Props {
  type: number;
  refreshTable?: () => void;
  refreshDetails: () => void;
  onCancel?: () => void;
  id?: number | undefined;
  record?: FetchMaterialCategoryListResponse;
}

const BaseInfo = (props: Props) => {
  const { type, onCancel, refreshTable, refreshDetails, id, record } = props;
  const [form] = Form.useForm();
  const [keepOn, sekeepOn] = useState(type === ACTION_CREATE);
  const [isEidtParent, setIsEditParent] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [refreshMarker, setRefreshMaker] = useState<number>(0);
  const baseInfo: DataFormLayoutInfoBlock = {
    items: [
      {
        label: '上级分类Id',
        name: 'parentId',
        hidden: true,
        render: () => <Input />,
      },
      {
        label: '上级分类',
        name: 'parentName',
        rules: [
          {
            required: type === ACTION_CREATE_CHIRLD || (type === ACTION_EDIT && !isEidtParent),
            message: '上级分类名称必填',
          },
          { max: 255, message: '不超过255字符' },
          { validator: checkTwoSidesTrim },
        ],
        validateFirst: true,
        hidden: type === ACTION_CREATE || isEidtParent,
        render: () => (
          <Input
            placeholder="请输入上级分类名称"
            disabled={type === ACTION_CREATE_CHIRLD || !isEidtParent}
          />
        ),
      },
      {
        label: '分类层级',
        rules: [{ required: type !== ACTION_CREATE, message: '分类层级必填' }],
        name: 'categoryLevel',
        hidden: type === ACTION_CREATE,
        render: () => (
          <Input
            placeholder="请输入分类层级"
            disabled={type === ACTION_CREATE_CHIRLD || type === ACTION_EDIT}
          />
        ),
      },
      ...NumberRulesStandardHook({
        label: '分类编号',
        form,
        edit: type === ACTION_EDIT,
        objectCode: ObjectCode.materialCategory,
        fieldCode: 'code',
        refreshMarker,
        rules: [
          {
            max: 255,
            message: '不可超过255个字符',
          },
          { validator: textValidator2 },
          { validator: checkTwoSidesTrim },
        ],
      }),
      {
        label: '分类名称',
        name: 'name',
        rules: [
          { required: true, message: '分类名称必填' },
          { max: 255, message: '不超过255字符' },
          { validator: checkTwoSidesTrim },
        ],
        render: () => <Input placeholder="请输入分类名称" />,
      },
      {
        label: '编辑原因',
        name: 'reason',
        hidden: type === ACTION_CREATE || type === ACTION_CREATE_CHIRLD,
        rules: [{ max: 1000, message: '不可超过1000字符' }],
        render: () => <TextArea placeholder="请输入编辑原因" />,
      },
    ],
    column: 1,
  };

  const initCreate = () => {};

  const initCreateChirld = (params: any) => {
    setSelectedRow(params);
    form.setFieldsValue({
      categoryLevel: params.level + 1,
      parentName: params.name,
      parentId: params.id,
    });
  };
  const initEdit = async (params: any) => {
    try {
      if (!params) return;
      if (params?.parent) {
        form.setFieldsValue({
          code: params.code,
          name: params.name,
          categoryLevel: params.level,
          parentName: params.parent.name,
          parentId: params.parent.id,
        });
      } else {
        setIsEditParent(true);
        form.setFieldsValue({
          code: params.code,
          name: params.name,
          categoryLevel: params.level,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = async () => {
    try {
      const value = await form?.validateFields();

      switch (type) {
        case ACTION_CREATE: {
          const params: { name: string; code?: string; numberRuleId?: number | string } = {
            name: value.name,
          };

          if (value?.code) {
            params.code = value.code;
          }
          params.numberRuleId = value?.numberRuleId;

          const res: any = await fetchMaterialCategoryCreate(params);

          if (res.code === 200) {
            message.success('创建成功');
          } else {
            message.error(res.message);
          }
          break;
        }

        case ACTION_CREATE_CHIRLD: {
          const params: {
            name: string;
            categoryLevel: number;
            parentId: number;
            code?: string;
            numberRuleId?: number | string;
          } = {
            name: value.name,
            numberRuleId: value.numberRuleId,
            categoryLevel: value.categoryLevel,
            parentId: value.parentId,
          };

          if (value?.code) {
            params.code = value.code;
          }
          params.numberRuleId = value.numberRuleId;

          const res: any = await fetchMaterialCategoryCreateChild(params);

          if (res.code === 200) {
            message.success('创建成功');
          } else {
            message.error(res.message);
          }
          break;
        }

        case ACTION_EDIT: {
          const params: { code: string; name: string; id: number; reason?: string } = {
            code: value.code,
            name: value.name,
            id: id as any,
          };

          if (value?.reason) {
            params.reason = value.reason;
          }
          const res: any = await fetchMaterialCategoryUpdate(params);

          if (res.code === 200) {
            message.success('编辑成功');
          } else {
            message.error(res.message);
          }
          break;
        }

        default:
          break;
      }
      if (keepOn) {
        typeof refreshTable === 'function' && refreshTable();
        form.resetFields();
        setRefreshMaker(Math.random());
        if (type === ACTION_CREATE_CHIRLD) {
          initCreateChirld(selectedRow);
        }
        initCreate();
        return;
      }
      onCancel && onCancel();
      typeof refreshTable === 'function' && refreshTable();
      refreshDetails();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    switch (type) {
      case ACTION_CREATE:
        initCreate();
        break;

      case ACTION_CREATE_CHIRLD:
        initCreateChirld(record);
        break;

      case ACTION_EDIT:
        initEdit(record);
        break;

      default:
        initCreate();
        break;
    }
  }, []);
  const renderTitle = (actionType: number) => {
    let title = '';

    switch (actionType) {
      case ACTION_CREATE:
        title = '新建分类';
        break;

      case ACTION_CREATE_CHIRLD:
        title = '新建子分类';
        break;

      case ACTION_EDIT:
        title = '编辑分类';
        break;

      default:
        title = '新建分类';
        break;
    }

    return title;
  };

  return (
    <DataFormLayout
      form={form}
      info={[baseInfo]}
      title={renderTitle(type)}
      extra={
        (type === ACTION_CREATE || type === ACTION_CREATE_CHIRLD) && (
          <Checkbox
            onChange={() => {
              sekeepOn(!keepOn);
            }}
            defaultChecked={keepOn}
          >
            连续新建
          </Checkbox>
        )
      }
      onCancel={onCancel}
      onFinish={handleFinish}
    />
  );
};

export default BaseInfo;
