import React, { useEffect, useState } from 'react';
import { BlSortFormList } from '@blacklake-web/component';
import { Button, Descriptions, Form, Input, message, Modal } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import { CheckItem } from 'src/page/quality/models/dto/checkItem';
import { FormInstance } from 'antd/es/form/Form';
import { ICustomFields, injectCustomFieldColumns } from 'src/components/customField';
import _, { values } from 'lodash';
import { BcAttachmentDetail, BcAttachmentForm } from 'src/components';
import { TextControl } from 'src/components/text';
import styles from 'src/page/quality/qcTask/styles.module.scss';
import { lookup } from 'src/dict';
import { showDefectModal } from '../defectModal';
import { RecordValue } from './recordValue';
import { useBlocker } from 'src/utils';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import { resolve } from 'path';

interface RecordProps {
  checkItem: CheckItem;
  visible?: boolean;
  form: FormInstance;
  onClose?: () => void;
  canAddReport?: boolean;
  customFields?: ICustomFields;
  isShowQrCode?: boolean;
}

export const RecordEditModal = (props: RecordProps) => {
  const { canAddReport, checkItem, visible, form, customFields, isShowQrCode, onClose } = props;
  const { executeUpload, executeRemark } = checkItem || {};
  const [, setRefreshMarker] = useState<number>(0);
  const commonNamePath = ['reports', `${checkItem?.id}`];
  const [modalForm] = Form.useForm();
  const { resetForm, shouldLeave } = useBlocker(modalForm);
  const [reportCount, setReportCount] = useState<number>(
    Number(checkItem?.totalReportCount || checkItem?.reportCount),
  );
  const [fillReportCount, setFillReportCount] = useState<number>(
    Number(checkItem?.filledReportCount),
  );

  const REPORT_VALUE_KEY = [
    'value',
    'valueMin',
    'valueMax',
    'remark',
    'attachmentIds',
    'customFields',
  ];

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return _.compact([
      {
        title: '序号',
        dataIndex: 'index',
        width: 120,
        render: (i, field: FormListFieldData, index) => index + 1,
      },
      isShowQrCode && {
        title: '标识码',
        dataIndex: 'qrCode',
        width: 120,
        render: (i, field: FormListFieldData) => (
          <Form.Item
            fieldKey={[field.fieldKey, 'qrCode']}
            name={[field.name, 'qrCode']}
            style={{ marginBottom: 0 }}
          >
            <TextControl />
          </Form.Item>
        ),
      },
      {
        title: '实测值',
        width: 300,
        render: (_, field: FormListFieldData) => (
          <RecordValue
            checkItem={checkItem}
            valueIsUnQuality={modalForm.getFieldValue(['reportValues', field.name, 'isUnQuality'])}
            form={modalForm}
            fieldName={field.name}
          />
        ),
      },
      executeRemark && {
        title: '备注',
        width: 200,
        render: (_, field: FormListFieldData) => (
          <Form.Item
            fieldKey={[field.fieldKey, 'remark']}
            name={[field.name, 'remark']}
            style={{ marginBottom: 0 }}
            rules={[{ max: 1000, message: '备注最多可以输入1000个字符' }]}
          >
            <Input placeholder="请输入备注" />
          </Form.Item>
        ),
      },
      (checkItem?.defectRankList?.length || checkItem.defectReasonList?.length) && {
        title: '不良信息',
        dataIndex: 'qcDefectVOS',
        width: 120,
        render: (_, field: FormListFieldData) => (
          <Button
            onClick={() =>
              showDefectModal({
                form: modalForm,
                formNamePath: ['reportValues', String(field.name), 'defectList'],
                onSubmit: () => setRefreshMarker(Date.now()),
                defectRankList: checkItem?.defectRankList,
                defectReasonList: checkItem.defectReasonList,
              })
            }
            type="link"
          >
            {modalForm.getFieldValue(['reportValues', String(field.name), 'defectList'])?.length
              ? '编辑'
              : '添加'}
          </Button>
        ),
      },
      executeUpload && {
        title: '附件',
        dataIndex: 'attachmentIds',
        width: 300,
        render: (_, field: FormListFieldData) => (
          <Form.Item
            fieldKey={[field.fieldKey, 'attachmentIds']}
            name={[field.name, 'attachmentIds']}
            style={{ marginBottom: 0 }}
          >
            <BcAttachmentForm />
          </Form.Item>
        ),
      },
    ]);
  };

  useEffect(() => {
    const reportValues = form.getFieldValue([...commonNamePath, 'reportValues']);

    setFillReportCount(
      _.filter(reportValues, (report) => report?.value || report.valueMin || report?.valueMax)
        ?.length,
    );
    resetForm({ reportValues });
  }, []);

  const copyFirstValueoReport = () => {
    const values = modalForm.getFieldValue(['reportValues']);
    const firstLineData = _.first(values) as any;

    delete firstLineData.id;
    const hasData = _.compact(_.map(REPORT_VALUE_KEY, (key) => firstLineData[key]))?.length;

    if (hasData) {
      modalForm.setFieldsValue({
        reportValues: _.map(values, (value) => ({ ...value, ...firstLineData })),
      });
      setFillReportCount(reportCount);
    } else {
      message.warning('首行没有数据，无法复制');
    }
  };

  return (
    <Modal
      visible={visible}
      width={1200}
      title={`检验项纪录-${checkItem.checkItemName}`}
      onCancel={() => {
        shouldLeave() && onClose?.();
      }}
      onOk={async () => {
        await modalForm.validateFields();
        const reportValues = modalForm.getFieldValue('reportValues');
        const checkItemReports = form.getFieldValue(commonNamePath);

        checkItemReports.reportValues = reportValues;
        checkItemReports.totalReportCount = reportCount;
        checkItemReports.filledReportCount = fillReportCount;
        form.setFields([
          {
            name: commonNamePath,
            value: checkItemReports,
          },
        ]);
        onClose?.();
      }}
      closable
    >
      <Descriptions className={styles.moduleTopInfo}>
        <Descriptions.Item label="检验项名称">{checkItem.checkItemName}</Descriptions.Item>
        <Descriptions.Item label="分组">{checkItem.groupName}</Descriptions.Item>
        <Descriptions.Item label="是否必填">
          {lookup('quality.recordCheckItemType', checkItem.recordCheckItemType)}
        </Descriptions.Item>
        <Descriptions.Item label="标准值单位">{checkItem?.unit?.name ?? '-'}</Descriptions.Item>
        <Descriptions.Item label="检验项编号">{checkItem.checkItemCode}</Descriptions.Item>
        <Descriptions.Item label="标准值">{checkItem.standValue}</Descriptions.Item>
        <Descriptions.Item label="抽检数量">{checkItem.taskCheckCount ?? '-'}</Descriptions.Item>
        <Descriptions.Item label="检验设备">
          {_.map(checkItem.equipments, 'name')?.join(',') || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="其他说明">{checkItem.checkItemRemark || '-'}</Descriptions.Item>
        <Descriptions.Item label="附件">
          {checkItem.attachmentIds ? <BcAttachmentDetail fileIds={checkItem.attachmentIds} /> : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="必填份数">
          {checkItem.requireReportCount ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item label="总份数">
          {(reportCount || checkItem.reportCount) ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item label="已填份数">{fillReportCount ?? '-'}</Descriptions.Item>
      </Descriptions>
      <Button onClick={() => copyFirstValueoReport()}>{'复制首行'}</Button>
      <BlSortFormList
        name={'reportValues'}
        form={modalForm}
        renderColumns={() =>
          injectCustomFieldColumns({
            columns: getColumns(), // 原本的列
            customFields, // 自定义字段信息
            objectCode: 'InspectionItemRecord', // 从对象code
            type: 'form', // 使用类型
          })
        }
        formProps={{
          onValuesChange: () => {
            setReportCount(modalForm.getFieldValue('reportValues')?.length);
            setFillReportCount(
              _.filter(
                modalForm.getFieldValue('reportValues'),
                (report) => report?.value || report.valueMin || report?.valueMax,
              )?.length,
            );
          },
          validateTrigger: 'onChange',
        }}
        beforeDelete={() => {
          return new Promise((resolved, rejected) => {
            Modal.confirm({
              title: '确认删除当前份数？',
              onCancel: () => rejected(),
              onOk: () => resolved(true),
            });
          });
        }}
        buttonText="添加一行"
        isNeedAdd={canAddReport}
        isNeedDelete={canAddReport && reportCount > 1}
        isNeedDrag={false}
        maxCount={100}
      />
    </Modal>
  );
};
