import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Button, Form } from 'antd';
import type { FormInstance } from 'antd';
import { FormListProps } from 'antd/lib/form';
import { remove, cloneDeep, isEmpty, map, filter, get } from 'lodash';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import BlSortFormList from 'src/components/sortList/BlSortFormList';
import { DataFormLayoutForModal } from 'src/layout';
import ResourceSelectModal from 'src/page/knowledgeManagement/components/resourceConfig/selectModal';
import EquipParamsFormList from './processParameters';
import { arrayIsEmpty } from 'src/utils/array';
import { ParmTypeEnum } from 'src/dict/resources';
import { replaceSign } from 'src/utils/constants';

interface Props {
  form: FormInstance;
  filedName: FormListProps['name'];
}

const Resources: FC<Props> = (props) => {
  const { form: formInst, filedName } = props;
  const [showSelectModal, setShowSelectModal] = useState<boolean>(false);
  const [choosedValue, setChoosedValue] = useState<any>();
  const [processParame, setProcessParame] = useState<FormListFieldData>();

  const baseFiledName: any = !arrayIsEmpty(filedName) ? filedName : [filedName];

  useEffect(() => {
    const list = formInst.getFieldValue(filedName);

    setChoosedValue(list);
  }, [props]);

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '资源序号',
        dataIndex: 'lineNos',
        render: (text: string, field) => {
          return (
            <Form.Item
              fieldKey={[field.fieldKey, 'lineNos']}
              name={(field.name, 'lineNos')}
              style={{ marginBottom: '0' }}
            >
              {field.name}
            </Form.Item>
          );
        },
      },
      {
        title: '设备编号',
        dataIndex: 'code',
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'code']}
              fieldKey={[field.fieldKey, 'code']}
              style={{ marginBottom: '0' }}
            >
              {formInst.getFieldValue([...baseFiledName, field.name, 'code']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '设备名称',
        dataIndex: 'name',
        render: (text: string, field) => {
          return (
            <Form.Item
              fieldKey={[field.fieldKey, 'name']}
              name={[field.name, 'name']}
              style={{ width: '100%', marginBottom: '0' }}
            >
              {formInst.getFieldValue([...baseFiledName, field.name, 'name']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '工艺参数',
        render: (text: string, field) => {
          return (
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                setProcessParame(field);
              }}
            >
              设置
            </Button>
          );
        },
      },
    ];
  };

  /**
   * 处理 工艺参数 变动
   * @param value
   */
  const handleResourceParamsChange = (value?: any[]) => {
    if (value) {
      const changeValues = [
        {
          name: [...baseFiledName, processParame?.name, 'resourceParamsList'],
          value,
        },
      ];

      formInst.setFields(changeValues);
    }

    setProcessParame(undefined);
  };

  return (
    <>
      <BlSortFormList
        form={formInst}
        name={filedName as string}
        renderColumns={() => getColumns()}
        isNeedDrag={false}
        handleAdd={() => {
          setShowSelectModal(true);
        }}
        buttonText={'批量添加'}
        onDelete={(index) => {
          const choosedValueCopy = cloneDeep(choosedValue);

          const evens = remove(choosedValueCopy, (i, n) => {
            return index.find((e) => e !== n);
          });

          setChoosedValue(evens);
        }}
      />

      <DataFormLayoutForModal
        visible={!isEmpty(processParame)}
        onClose={() => {
          setProcessParame(undefined);
        }}
        content={
          <EquipParamsFormList
            onChange={handleResourceParamsChange}
            params={
              processParame && get(formInst.getFieldValue(baseFiledName), processParame?.name)
            }
            name={'resourceParamsList'}
          />
        }
      />
      {showSelectModal && (
        <ResourceSelectModal
          isMultiple
          visible={showSelectModal}
          submitData={(val: any, data: any) => {
            setChoosedValue(data);
            formInst.setFields([
              {
                name: filedName,
                value: map(data, ({ resourceParamsList, ...rest }) => {
                  const _resourceParamsList = filter(resourceParamsList, (item) => {
                    if (
                      item.paramsType?.code === ParmTypeEnum.accumulate ||
                      item.paramsType?.code === ParmTypeEnum.actualTime
                    ) {
                      return item;
                    }
                  });

                  return {
                    ...rest,
                    resourceParamsList: map(_resourceParamsList, ({ ...resourceRest }) => ({
                      ...resourceRest,
                      id: resourceRest?.paramsId,
                      param: {
                        value: JSON.stringify({ ...resourceRest, id: resourceRest?.paramsId }),
                        label: resourceRest.paramsName,
                      },
                    })),
                  };
                }),
              },
            ]);
          }}
          closeModal={() => {
            setShowSelectModal(false);
          }}
          defaultSelected={choosedValue}
        />
      )}
    </>
  );
};

export default Resources;
