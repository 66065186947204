// Routing
import { ENGINEER_PATH } from 'src/page/knowledgeManagement/engineerData/navigation';

export const gotoRoutingDetail = (id?: number) => {
  if (!id) return;
  window.reactRouterHistoryInstance.push(`${ENGINEER_PATH}/routing/${id}/detail`);
};

export const gotoRoutingCreate = () => {
  window.reactRouterHistoryInstance.push(`${ENGINEER_PATH}/routing/create`);
};

export const gotoRoutingEdit = (id?: number) => {
  window.reactRouterHistoryInstance.push(`${ENGINEER_PATH}/routing/${id}/edit`);
};

export const gotoRoutingList = () => {
  window.reactRouterHistoryInstance.push(`${ENGINEER_PATH}/routing`);
};
