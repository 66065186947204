import _ from 'lodash';
import { appEnum } from 'src/dict';

const { CustomField } = appEnum;
const formatToOption = (label: string, value: string | number) => {
  return _.isNil(value) ? value : { label, value };
};

export const formatToStingfyOption = (label: string, rest: any) => {
  return { label, value: JSON.stringify(rest) };
};

const formatTimeIntervalTrigger = (data: any) => {
  if (_.isNil(data)) return undefined;
  const { triggerWeekDay, triggerYearMonth, triggerMonthDay, triggerEveryDayTime, ...rest } = data;

  return {
    triggerWeekDay: triggerWeekDay?.split(',').map(Number),
    triggerYearMonth: triggerYearMonth?.split(','),
    triggerMonthDay: triggerMonthDay?.split(','),
    triggerEveryDayTime: triggerEveryDayTime?.split(','),
    ...rest,
  };
};

const formatToOptions = (labels: string, values: string, separator: string) => {
  if (_.isNil(labels) || _.isNil(values)) return undefined;
  const labelList = _.split(labels, separator);
  const valueList = _.split(values, separator).map(Number);

  return _.map(labelList, (label, index) => formatToOption(label, valueList[index]));
};

const formatFieldId = (names: string, ids: string, info: any) => {
  const nameList = _.split(names, ',');
  const idList = _.split(ids, ',').map(Number);
  const fieldIdValue = idList.map((item, index) => ({
    info,
    label: nameList[index],
    value: item,
  }));

  return fieldIdValue;
};

const formatCondition = (data: any, isCopy: boolean) => {
  if (!data) return undefined;
  const {
    conditionId,
    afterDisplayValues,
    afterValues,
    afterValueType,
    beforeDisplayValues,
    beforeValues,
    beforeValueType,
    updateDataCheck,
    fieldId,
    fieldName,
    fieldType,
    choiceValues,
    reference,
    targetType,
    ...rest
  } = data;

  let _afterValues = _.isArray(afterValues) ? afterValues[0] : afterValues;
  let _beforeValues = _.isArray(beforeValues) ? beforeValues[0] : beforeValues;

  switch (fieldType) {
    case CustomField.FieldType.text:
    case CustomField.FieldType.textArea:
    case CustomField.FieldType.number:
    case CustomField.FieldType.integer:
    case CustomField.FieldType.boolean:
      _afterValues = _.isArray(afterValues) ? afterValues[0] : afterValues;
      break;
    case CustomField.FieldType.date:
      _afterValues = _.isNil(_afterValues) ? undefined : Number(_afterValues);
      _beforeValues = _.isNil(_beforeValues) ? undefined : Number(_beforeValues);
      break;
    case CustomField.FieldType.select:
    case CustomField.FieldType.multiSelect:
    case CustomField.FieldType.relation:
    case CustomField.FieldType.relationSub:
    case CustomField.FieldType.subordinate:
      if (afterDisplayValues) {
        _afterValues = formatToOptions(afterDisplayValues, afterValues, ',');
      }
      if (beforeDisplayValues) {
        _beforeValues = formatToOptions(beforeDisplayValues, beforeValues, ',');
      }
      break;
  }

  // 值类型为字段引用时, beforeValues 或 afterValues 要转化为option的格式
  // 对应的组件是 获取字段的多层级选择组件
  if (afterValueType === appEnum.TriggerRule.ValueType.biz && afterDisplayValues) {
    _afterValues = formatToOptions(afterDisplayValues[0], afterValues[0], ',');
  }
  if (beforeValueType === appEnum.TriggerRule.ValueType.biz && beforeDisplayValues) {
    _beforeValues = formatToOptions(beforeDisplayValues[0], beforeValues[0], ',');
  }

  return {
    ...rest,
    conditionId: isCopy ? undefined : conditionId,
    updateDataCheck: Boolean(updateDataCheck),
    fieldId: formatFieldId(fieldName, fieldId, {
      fieldType,
      choiceValues,
      reference,
      targetType,
    }),
    afterValues: _afterValues,
    beforeValues: _beforeValues,
    beforeValueType,
    afterValueType,
  };
};

export const formatConditionGroups = (data: any, isCopy: boolean) => {
  const { condition, innerConditions, type } = data;

  return {
    condition: formatCondition(condition, isCopy),
    innerConditions: innerConditions?.map((item: any) => formatCondition(item, isCopy)),
    type: _.isNil(type) ? undefined : type,
  };
};

export const formatDelayTriggerField = (data: any) => {
  if (_.isNil(data)) return undefined;
  const { delayTriggerFieldId, delayTriggerFieldName, triggerEventType } = data;

  let _delayTriggerField;

  if (!_.isNil(delayTriggerFieldId)) {
    _delayTriggerField = formatToOption(delayTriggerFieldName, delayTriggerFieldId);
  }
  return {
    ...data,
    delayTriggerField: _delayTriggerField,
    triggerEventType: _.isArray(triggerEventType)
      ? triggerEventType.map(({ code, name }: any) => ({ label: name, value: code }))
      : triggerEventType,
  };
};

export const formatToFormData = (data: any, isCopy: boolean) => {
  if (!data) return data;
  const {
    id,
    code,
    parentRuleId,
    parentRuleName,
    objectCode,
    objectName,
    objectCategory,
    bizEventTrigger,
    timeIntervalTrigger,
    triggerCondition,
    ...rest
  } = data;

  return {
    ...rest,
    code: isCopy ? undefined : code,
    id: isCopy ? undefined : id,
    parentRuleId: formatToOption(parentRuleName, parentRuleId),
    objectCode: formatToStingfyOption(objectName, { objectCode, objectName, objectCategory }),
    bizEventTrigger: formatDelayTriggerField(bizEventTrigger),
    timeIntervalTrigger: formatTimeIntervalTrigger(timeIntervalTrigger),
    triggerCondition: {
      conditionGroups: triggerCondition?.conditionGroups?.map((item: any) =>
        formatConditionGroups(item, isCopy),
      ), // (triggerConditionType.conditionGroups),
      type: _.isNil(triggerCondition?.type) ? undefined : triggerCondition?.type,
    },
  };
};
