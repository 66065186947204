import React from 'react';
import _ from 'lodash';
import { Button, DatePicker, Form, Modal, Space } from 'antd';

interface PlanDataModalProps {
  visible: boolean;
  onCancel?: () => void;
  onFinish?: (values: any) => void;
}

const PlanDateModal = ({ onCancel, visible, onFinish }: PlanDataModalProps) => {
  const [form] = Form.useForm();

  const validatorPlanEndDate = (rule: any, value: any) => {
    const planStartDate = form.getFieldValue('planStartDate');

    if (!_.isNil(planStartDate) && !_.isNil(value)) {
      if (value.isBefore(planStartDate)) {
        return Promise.reject('结束时间不能早于开始时间');
      }
    }

    return Promise.resolve();
  };

  const handleCancel = () => {
    if (_.isFunction(onCancel)) onCancel();
  };

  const handleFinish = (values: any) => {
    if (_.isFunction(onFinish)) onFinish(values);
  };

  return (
    <Modal
      title="批量调整计划时间"
      width={600}
      footer={false}
      visible={visible}
      onCancel={handleCancel}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Form.Item label="计划开始时间" name="planStartDate">
          <DatePicker style={{ width: '80%' }} showTime />
        </Form.Item>

        <Form.Item
          label="计划结束时间"
          name="planEndDate"
          rules={[{ validator: validatorPlanEndDate }]}
        >
          <DatePicker style={{ width: '80%' }} showTime />
        </Form.Item>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Space size="middle">
            <Button onClick={handleCancel}>取消</Button>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default PlanDateModal;
