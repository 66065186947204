import React, { useState, useEffect, useCallback } from 'react';
import { Button, FormInstance, Form, Input } from 'antd';
import { fetchEReportTemplateDataPreview } from 'src/api/ytt/e-report-domain/customReport';
import { BlTable, Tooltip } from 'src/components';
import { replaceSign } from 'src/utils/constants';
import { formatFormFilterToGetList, resetFormToFormFilterCondition } from '../../utils';
import { map, filter } from 'lodash';
import { textValidator1 } from 'src/utils/formValidators';
import styles from '../../filter/styles.module.scss';
import _Array from 'src/utils/dataTypes/array';
import lookup, { appEnum } from 'src/dict';

const { Search } = Input;

interface Props {
  fieldData: any[];
  setDataListForm: FormInstance;
  selectedRowKeys: React.Key[];
  handleSelectedRowKeys: (keys: React.Key[]) => void;
  selectedRows: any[];
  handleSetSelectedRows: (data: any[]) => void;
  handleSetPreviewList: (data: any[]) => void;
  onDataFieldTableChange: (params?: any) => void;
  handleSetSearchValue: (value: string) => void;
  operationType: string;
  previewList: any[]; // 预览字段
  onSearchField: () => void;
  isChangeField: boolean;
  handleResetChangeField: (rest: boolean) => void;
}

export const defaultPagination = {
  page: 1,
  size: 10,
};

export interface Pagination {
  page: number;
  size: number;
}

export default function TemplateDataFieldCanEditTable({
  fieldData,
  setDataListForm,
  selectedRowKeys,
  handleSelectedRowKeys,
  selectedRows,
  handleSetSelectedRows,
  handleSetPreviewList,
  onDataFieldTableChange,
  operationType,
  previewList,
  handleSetSearchValue,
  onSearchField,
  isChangeField,
  handleResetChangeField,
}: Props) {
  const [dataSource, setDataSource] = useState([] as any);

  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState<any>(defaultPagination);
  const [prePagination, setPrePagination] = useState<any>(defaultPagination);

  const formatDetailDataToForm = (values: any[]) => {
    const value = values?.map((item: any) => {
      return {
        ...item,
        fieldDisplayNameDefault: item?.fieldDisplayName,
      };
    });

    setDataSource(fieldData);

    return value;
  };

  useEffect(() => {
    // 现在支持快捷搜索 所以预览字段有可能是空数据
    setDataListForm.setFieldsValue({
      templateDataFieldTable: formatDetailDataToForm(fieldData),
    });

    if (
      operationType !== appEnum.Common.CRUD.create &&
      !_Array.isEmpty(selectedRows) &&
      !_Array.isEmpty(fieldData)
    ) {
      getPreviewFieldInfoList();
    }
  }, [fieldData]);

  const isShowTips = useCallback(() => isChangeField, [isChangeField]);

  const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    handleSelectedRowKeys(selectedRowKeys);
    handleSetSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) =>
      onSelectChange(selectedRowKeys, selectedRows),
  };

  const getPreviewFieldInfoList = async () => {
    try {
      // 点击刷新按钮 需要做两件事 1. 根据过滤条件和选择字段获取预览数据结果  2. 更新过滤和预览上被修改后的字段展示

      const fromList = setDataListForm.getFieldsValue();

      const templateDataFieldTable = filter(fromList.templateDataFieldTable, (node: any) =>
        selectedRowKeys.includes(node?.fieldId),
      );

      handleSetSelectedRows(templateDataFieldTable);

      const formatNextFloorCondition = resetFormToFormFilterCondition(fromList);

      setDataListForm.setFieldsValue({ nextFloorCondition: formatNextFloorCondition });

      const filterCondition = formatFormFilterToGetList(formatNextFloorCondition?.[0]); //  最外层的是为了符合数组结构 前端为展示结构加的，后端数据不需要最外层的List,是一个对象

      const temDataSetPreviewFieldInfoList = map(templateDataFieldTable, (node: any) => {
        const { order, ...restNode } = node;

        return {
          ...restNode,
          dataSetId: fromList?.dataSetId?.value,
        };
      });

      const { data } = await fetchEReportTemplateDataPreview({
        dataSetId: fromList?.dataSetId?.value,
        dataSetName: fromList?.dataSetId?.label,
        filterCondition: _Array.isEmpty(filterCondition?.objectFilterConditionList)
          ? undefined
          : filterCondition,
        temDataSetPreviewFieldInfoList,
      });

      if (data?.previewData) {
        handleSetPreviewList(data?.previewData);

        handleResetChangeField(false);
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = selectedRows?.map((field, index) => {
    const { fieldDisplayName } = field;

    return {
      title: <Tooltip text={fieldDisplayName} width={80} />,
      dataIndex: index,
      width: 100,
    };
  });

  const handleQuickPress: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();
    typeof onSearchField === 'function' && onSearchField();
  };

  return (
    <div style={{ display: 'flex' }}>
      <Form.List name="templateDataFieldTable">
        {(fields) => {
          const dataColumns = [
            {
              title: '字段类型',
              dataIndex: 'fieldType',
              width: 100,
              sorter: true,
              render: (fieldType: number) => lookup('customField.fieldType', fieldType),
            },
            {
              title: '字段名',
              dataIndex: 'fieldDisplayName',
              width: 150,
              sorter: true,
              render: (fieldDisplayName: string, field: any) => {
                return (
                  <Form.Item
                    name={[field?.name, 'fieldDisplayName']}
                    fieldKey={[field?.key, 'fieldDisplayName']}
                    initialValue={fieldDisplayName}
                    rules={[
                      { max: 255, message: '不可超过255个字符' },
                      { validator: textValidator1 },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                );
              },
            },
            {
              title: '所属对象',
              width: 100,
              sorter: true,
              dataIndex: 'objectName',
              render: (objectName: string) => objectName ?? replaceSign,
            },
          ];

          const data: any = fields?.map((f: any) => {
            return { ...f, ...dataSource[f?.name] } ?? {};
          });

          return (
            <div
              style={{
                position: 'relative',
                width: '50%',
              }}
            >
              <BlTable
                style={{ width: '100%' }}
                columns={dataColumns}
                dataSource={data}
                pagination={{
                  showSizeChanger: true,
                  showQuickJumper: true,
                  ...pagination,
                }}
                scroll={{ x: true, y: 400 }}
                rowSelection={rowSelection}
                rowKey={(field) => field?.fieldId}
                id="TemplateDataFieldTable"
                className={styles?.templateDataFieldTableName}
                onChange={(pagination: any, filters: any, sorter: any) => {
                  const { current, pageSize } = pagination;

                  setPagination({
                    page: current,
                    size: pageSize,
                  });
                  onDataFieldTableChange(sorter);
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: -40,
                  right: 0,
                }}
              >
                <Search
                  placeholder={'请输入搜索关键字'}
                  onChange={(e) => {
                    handleSetSearchValue(e.target.value);
                  }}
                  onSearch={() => {
                    typeof onSearchField === 'function' && onSearchField();
                  }}
                  onPressEnter={handleQuickPress}
                />
              </div>
            </div>
          );
        }}
      </Form.List>
      <div style={{ width: '50%', position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            height: 35,
            lineHeight: '35px',
            paddingBottom: 8,
            left: 10,
            top: -35,
            color: '#000000D9',
          }}
        >
          模板数据集预览
        </div>
        <div
          style={{
            position: 'absolute',
            top: -40,
            right: 0,
          }}
        >
          {isShowTips() && (
            <span style={{ marginRight: 10 }}>
              {'当前字段设置已修改，点击刷新数据预览数据结果'}
            </span>
          )}
          <Button
            type="primary"
            onClick={() => {
              setLoading(true);
              getPreviewFieldInfoList();
            }}
            loading={loading}
          >
            刷新数据
          </Button>
        </div>
        <BlTable
          style={{ flex: 1, marginLeft: 10 }}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `共 ${previewList?.length} 条`,
            ...prePagination,
          }}
          columns={columns}
          scroll={{ x: 200, y: 400 }}
          dataSource={previewList}
          onChange={(pagination: any) => {
            const { current, pageSize } = pagination;

            setPrePagination({
              page: current,
              size: pageSize,
            });
          }}
        />
      </div>
    </div>
  );
}
