import { Tabs } from 'antd';
import _ from 'lodash';

import styles from './index.module.scss';
import FitRangeList from './FitRangeList';
import { FitRangeDataProps, FitRangeDataItemProps } from '../../constants';
import { lookup } from 'src/dict';
import { FitRangeType } from 'src/dict/workCalendar';
const { TabPane } = Tabs;

interface FitRangePanelProps {
  className: string;
  value?: FitRangeDataProps; // 固定班制下的适用范围的值
  onChange?: (newData: FitRangeDataProps | undefined) => void;
  readOnly?: boolean;
}

// const initValue = {
//   [FitRangeType.users]: [],
//   [FitRangeType.departments]: [],
//   [FitRangeType.equipments]: [],
//   [FitRangeType.workCenters]: [],
// };

export default function FitRangePanel(props: FitRangePanelProps) {
  const { className, value, onChange, readOnly } = props;

  /** 适用范围列表更新回调，获取新的适用范围列表并把它写进新的适用范围数据中 */
  /** @param newValue 新的适用范围列表数据 */
  /** @param type 适用范围类型：1.用户, 2.部门, 3.设备, 4.工作中心 */
  const fitRangeListChanged = (
    newValue: FitRangeDataItemProps[] | undefined,
    type: FitRangeType,
  ) => {
    const newSelectedObj = { [type]: newValue ? [...newValue] : [] };

    if (value) {
      const newValueObj: FitRangeDataProps = { ...value, ...newSelectedObj };

      if (
        Object.keys(newValueObj).every(
          (typeKey) =>
            !newValueObj[Number(typeKey) as FitRangeType] ||
            newValueObj[Number(typeKey) as FitRangeType]!.length === 0,
        )
      ) {
        onChange?.(undefined);
      } else {
        onChange?.(newValueObj);
      }
    } else {
      onChange?.(newSelectedObj);
    }
  };

  /** 取出固定班制工作日历目前支持的所有适用范围类型，用以渲染适用范围tabs
   *  因为users目前不支持，故过滤掉 */
  const fitRangeConfig: FitRangeType[] = Object.keys(FitRangeType)
    .map((key) => FitRangeType[key as keyof typeof FitRangeType])
    .filter((v) => typeof v === 'number' && v !== FitRangeType.users);

  return (
    <>
      <div className={className}>
        <h5 className={styles.fitRangeTitle}>适用范围</h5>
        <Tabs
          defaultActiveKey="1"
          size="small"
          tabBarStyle={{ padding: '0 13px', height: 40, margin: 0 }}
          tabBarGutter={13}
          className={styles.fitRangeTabs}
        >
          {fitRangeConfig.map((configType: FitRangeType) => (
            <TabPane
              className={styles.fitRangeTabPane}
              tab={lookup('workCalendar.fitRangeTypeDisplayMap', configType)}
              key={`fitRangePane-${configType}`}
            >
              <FitRangeList
                listData={value?.[configType]}
                fitRangeType={configType}
                buttonText={`添加${lookup('workCalendar.fitRangeTypeDisplayMap', configType)}`}
                onChange={(newValue) => {
                  fitRangeListChanged(newValue, configType);
                }}
                readOnly={readOnly}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </>
  );
}
