import React from 'react';
import { Modal } from 'antd';
import { BlIcon, BlTable } from 'src/components';
import styles from './qrCodeCreate.module.scss';
import { replaceSign } from 'src/utils/constants';
import { SuccessListType } from './interface';

interface createContentProps {
  visible: Boolean;
  onCancel: () => void;
  onOk: () => void;
  data: SuccessListType[];
  dataSource: any;
}

const CreateCodeSuccessContent = (props: createContentProps) => {
  const { visible, onCancel, data, onOk, dataSource } = props;

  const dataColumns = [
    {
      title: '序号',
      width: 150,
      render: (_: any, record: any, i: number) => {
        return i + 1;
      },
    },
    {
      title: '标识码',
      dataIndex: 'qrCode',
      width: 450,
      render: (qrCode: string) => {
        if (!qrCode) return replaceSign;
        return qrCode || replaceSign;
      },
    },
  ];

  return (
    <>
      <Modal
        visible={Boolean(visible)}
        title="新建结果"
        cancelText={'继续新建'}
        okText={'打印'}
        onCancel={onCancel}
        width={750}
        onOk={onOk}
        className={styles.qrCodeModal}
        destroyOnClose
        keyboard={false}
        maskClosable={false}
      >
        <div className="qrCodeCreateBox">
          <div className="qrCodeCreateTitle">
            <div style={{ color: '#02B980', marginRight: 10 }}>
              <BlIcon type="iconmianxingchenggong" />
            </div>
            <div className="count">本次共新建成功{data?.length || 0}个</div>
            {dataSource?.repeatFactoryCodeAmount > 0 && (
              <div className="count">
                ，新建失败{dataSource?.repeatFactoryCodeAmount || replaceSign}
                个(失败原因：标识码重复)
              </div>
            )}
          </div>
          <div>
            <BlTable
              style={{ width: '100%', marginTop: 20 }}
              pagination={false}
              columns={dataColumns}
              dataSource={data}
              scroll={{ y: 550 }}
              id="qrCodeCreateSuccess"
              rowKey={(record) => record?.qrCode}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateCodeSuccessContent;
