import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { BlTable } from '@blacklake-web/component';
import { Input, Modal, Form, Select, InputNumber, DatePicker, message as Message } from 'antd';
import { ParamsInfoList, TemplateDataSetInfoList, TemplateListNoStatus } from '../../interface';
import { fetchEReportTemplatePreview } from 'src/api/ytt/e-report-domain/customReport';
import Report from './report';
import styles from '../../filter/styles.module.scss';
import {
  APPROVE_ENUM,
  BOOLEAN_ENUM_TYPE,
  FIELD_TYPE_ENUM,
  STATUS_ENUM,
  SYMBOL_MAP,
} from '../../constant';
import { SearchSelect } from 'src/components';
import _Time from 'src/utils/dataTypes/time';
import { inputtNumberIntRules } from 'src/page/custom_fields/fieldsList/components/verificationRules/rules';
import {
  fetchEReportInstanceEReportInstanceCreate,
  fetchEReportInstanceEReportInstanceCreateCheck,
} from 'src/api/ytt/e-report-domain/electronicDocuments';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import authDict, { hasAuth } from 'src/utils/auth';
import { FieldType } from 'src/dict/customField';

const PreviewModal = ({
  visible,
  initData,
  templateId,
  onClose,
  approval,
  operationType,
}: {
  visible: boolean;
  initData: TemplateListNoStatus;
  templateId: number;
  onClose?: () => void;
  approval?: number;
  operationType: string;
}) => {
  const [form] = Form.useForm();
  const [isShowReport, setIsShowReport] = useState<boolean>(false);
  const [params, setParams] = useState<any>();
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const { cptRelatedParam, subTemplateStatus } = initData || {};
  const [instanceRelateParamsInfoList, setInstanceRelateParamsInfoList] = useState<
    ParamsInfoList[]
  >([] as any);

  const renderValueControl = (record: any, index: number, disabledStatus: boolean) => {
    const { fieldType, dataSetId, fieldId, objectNodeId, choiceValues, condition } = record;

    const singleSelectType = condition === 'NOT IN' ? 'multiple' : undefined;

    const selectMode = fieldType === FieldType.multiSelect ? 'multiple' : singleSelectType;

    // 单行文本
    if (fieldType === FieldType.text) {
      return <Input disabled={disabledStatus} />;
    }
    //  单选/多选
    if (fieldType === FieldType.select || fieldType === FieldType.multiSelect) {
      return (
        <Select disabled={disabledStatus} mode={selectMode}>
          {choiceValues?.map((item: any) => {
            return (
              <Select.Option key={item?.id} value={item?.choiceCode}>
                {item?.choiceValue}
              </Select.Option>
            );
          })}
        </Select>
      );
    }
    //  布尔型
    if (fieldType === FieldType.boolean) {
      // 可选项的值由后端接口提供暂无 待后续处理
      return (
        <Select disabled={disabledStatus} mode={singleSelectType}>
          {BOOLEAN_ENUM_TYPE?.map((item: any) => {
            return (
              <Select.Option key={item?.key} value={item?.value}>
                {item?.label}
              </Select.Option>
            );
          })}
        </Select>
      );
    }
    // 数值/整数
    if (fieldType === FieldType.integer || fieldType === FieldType.number) {
      return <InputNumber style={{ width: '100%' }} disabled={disabledStatus} />;
    }

    // 日期
    if (fieldType === FieldType.date) {
      return (
        <DatePicker
          showTime
          format={'YYYY-MM-DD HH:mm:ss'}
          style={{ width: '100%' }}
          disabled={disabledStatus}
        />
      );
    }

    // 关联关系
    if (fieldType === FieldType.relation) {
      return (
        <SearchSelect
          disabled={disabledStatus}
          fetchType="relatedToOneTemplate"
          params={{
            dataSetId,
            fieldId,
            objectNodeId,
          }}
          searchFieldName="fieldValue"
          style={{ width: '100%' }}
        />
      );
    }

    return <Input disabled={disabledStatus} />;
  };
  const columns = [
    {
      title: '模版数据集名称',
      dataIndex: 'dataSetName',
      width: 150,
      render: (_: any, record: TemplateDataSetInfoList, index: number) => {
        const numLine = index + 1;
        const line = '_';

        return numLine < 10
          ? `0${numLine}${line}${record?.dataSetName}`
          : `${numLine}${line}${record?.dataSetName}`;
      },
    },
    {
      title: '关联参数名称',
      dataIndex: 'fieldDisplayName',
      width: 150,
    },
    {
      title: '关联条件',
      width: 150,
      dataIndex: 'condition',
      render: (_: any, { condition }: any) => SYMBOL_MAP.get(condition),
    },
    {
      title: '关联参数值',
      width: 150,
      dataIndex: 'fieldValue',
      render: (_: any, record: any, index: number) => {
        return (
          <div className={styles?.previewParams}>
            <Form.Item shouldUpdate>
              {() => {
                const disabledStatus =
                  record?.condition === 'IS NOT NULL' || record?.condition === 'IS NULL';

                const validatorNumber = (rule: any, value: any) => {
                  if (record?.fieldType !== FieldType.integer) return Promise.resolve('');

                  if (disabledStatus) return Promise.resolve('');

                  if (!inputtNumberIntRules(value)) return Promise.reject('必须是整数');

                  return Promise.resolve('');
                };

                return (
                  <>
                    <Form.Item
                      name={['previewParams', `${index}`, 'value']}
                      validateFirst
                      rules={[
                        { required: !disabledStatus, message: '请选择或输入' },
                        {
                          validator: validatorNumber,
                        },
                      ]}
                    >
                      {renderValueControl(record, index, disabledStatus)}
                    </Form.Item>
                    <Form.Item
                      hidden
                      name={['previewParams', `${index}`, 'id']}
                      initialValue={record?.id}
                    />
                  </>
                );
              }}
            </Form.Item>
          </div>
        );
      },
    },
  ];

  const handleSubmit = async () => {
    const { previewParams } = await form.validateFields();

    const tempRelateParamsForFRList = _.toArray(previewParams).map((e: any, index: number) => {
      const fieldType = cptRelatedParam?.[index]?.fieldType;
      const condition = cptRelatedParam?.[index]?.condition;

      let _value: any;

      if (fieldType === FieldType.date) {
        _value = e?.value ? Number(_Time.formatToUnix(e?.value)) : null;
      } else if (fieldType === FieldType.multiSelect) {
        _value = e?.value?.join(',');
      } else if (condition === 'NOT IN') {
        _value = e?.value?.join(',');
      } else {
        _value = e?.value?.key || e?.value;
      }

      return {
        relateParamsId: e?.id,
        value: _value,
      };
    });

    const { data } = await fetchEReportTemplatePreview({
      templateId,
      tempRelateParamsForFRList,
      subTemplateId: initData.subTemplateId,
    });

    setInstanceRelateParamsInfoList(tempRelateParamsForFRList);

    setIsShowReport(true);
    setParams(data?.params);
    setPreviewUrl(data?.previewUrl);
  };

  useEffect(() => {
    form.setFieldsValue({ previewParams: cptRelatedParam || [] });
  }, []);

  const getOkFn = () => {
    const preview = {
      okText: '确定',
      okFn: () => onClose?.(),
      disabledStatus: 0,
    };

    if (operationType === 'noApprove') {
      // 电子单据模板新建/编辑 对象匹配规则不支持创建电子单据实例和提交审批
      return preview;
    } else if (subTemplateStatus === STATUS_ENUM.ENABLED && approval === APPROVE_ENUM.YES) {
      // 启用中并且可审批
      preview.okText = '提交审批';
      // 提交审批还默认新建电子单据 所以需要两个权限点都是true才行
      const allAuth = hasAuth(authDict.eReportCase_approve) && hasAuth(authDict.eReportCase_add);

      preview.disabledStatus = allAuth ? 0 : 1;

      preview.okFn = addElectronicDocumentsFn;
    } else if (subTemplateStatus === STATUS_ENUM.ENABLED) {
      // 启用中 但是不可审批
      preview.okText = '新建电子单据';
      preview.disabledStatus = hasAuth(authDict.eReportCase_add) ? 0 : 1;

      preview.okFn = addElectronicDocumentsFn;
    }

    return preview;
  };

  const eReportInstanceCreateCheck = async () => {
    try {
      const { data } = await fetchEReportInstanceEReportInstanceCreateCheck({
        instanceRelateParamsInfoList,
        subTemplateId: initData?.subTemplateId,
      });

      if (data) {
        data?.repeat
          ? Modal.confirm({
              title: '电子单据的关联参数组合已存在，请确认是否继续新建？',
              icon: <ExclamationCircleOutlined />,
              okText: '确认',
              cancelText: '取消',
              onOk: createElectronicDocuments,
            })
          : createElectronicDocuments();
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  // 创建电子单据实例  是否提交审批后端自己根据状态去判断是否提交
  const createElectronicDocuments = async () => {
    try {
      const { data } = await fetchEReportInstanceEReportInstanceCreate({
        instanceRelateParamsInfoList,
        subTemplateId: initData?.subTemplateId,
      });

      if (data) {
        // 创建成功
        Message.success(`${getOkFn()?.okText}成功`);
        onClose?.();
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const addElectronicDocumentsFn = () => {
    eReportInstanceCreateCheck();
  };

  return (
    <Modal
      visible={visible}
      width={1000}
      title={isShowReport ? '预览结果' : '预览条件'}
      onCancel={() => onClose?.()}
      onOk={isShowReport ? getOkFn()?.okFn : () => handleSubmit()}
      okButtonProps={{ disabled: isShowReport ? Boolean(getOkFn()?.disabledStatus) : false }}
      okText={isShowReport ? getOkFn()?.okText : '预览'}
      bodyStyle={{ minHeight: 300, maxHeight: 500, padding: 0, overflowY: 'auto' }}
      style={{ maxHeight: 650 }}
      getContainer={false}
      className={styles?.templateModal}
      maskClosable={false}
    >
      {isShowReport ? (
        <Report previewUrl={previewUrl ?? ''} params={params} />
      ) : (
        <Form form={form}>
          <BlTable columns={columns} dataSource={cptRelatedParam} />
        </Form>
      )}
    </Modal>
  );
};

export default PreviewModal;
