const fieldsLabel = {
  code: '设备编号',
  name: '设备名称',
  businessType: '业务类型',
  classification: '设备分类',
  tag: '设备标签',
  level: '设备层级',
  identification: '标识码',
  supplier: '供应商',
  seq: '序列号',
  manager: '负责人',
  department: '负责部门',
  storageArea: '存储区域',
  parent: '父级设备',
  children: '子级设备',
};

export { fieldsLabel };
