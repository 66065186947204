import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { message as Message } from 'antd';
import { RecordListLayout, FilterItem, DataFormLayoutForModal } from 'layout';
import { TextToolTip } from '@blacklake-web/component';
import _ from 'lodash';

import { fetchSalesOrderReturnList } from 'src/api/ytt/order-domain/salesReturnOrder';
import { SingleOperationLogComponent, SearchSelect } from 'src/components';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { renderUser } from 'src/page/share/renderUser';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { fieldTypeList } from 'src/utils';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { intersectionValueOfId, mapLabeledValueToValue } from 'src/utils/array';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { lookup } from 'src/dict';
import { CRUD } from 'src/dict/common';
import { ReturnOrigin, ReturnStatus } from 'src/dict/salesReturnOrder';
import { returnOrigin, returnStatus } from 'src/dict/salesReturnOrder/mappings';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { ReceiveGoodsDialog, renderInboundOrder } from '../components';
import { fieldLabels } from '../constants';
import type { SalesReturnOrderListItemType } from '../interface';
import { useCloseSalesReturnOrderModal, useFinishReturnOrderModal } from '../hooks';
import {
  goToDetail,
  goToCreate,
  goToEdit,
  goToCreateInbound,
  goToReturnApplication,
} from '../navigates';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

export default function OrderList() {
  // 选中行的key，用以批量删除
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // 选中行的数据，带入入库单
  const [selectedRows, setSelectedRows] = useState<SalesReturnOrderListItemType[]>([]);
  // 刷新用的时间戳，在refresh方法里用
  const [refreshMarker, setRefreshMarker] = useState<number>(Date.now());
  const [visibleLog, setVisibleLog] = useState(false);
  // 当前操作行的id，传给操作记录用
  const [currentOrderId, setCurrentOrderId] = useState<number>();
  // 当前操作行
  const [currentOrder, setCurrentOrder] = useState<SalesReturnOrderListItemType>();
  // 收货按钮的弹窗，只有当没有主系统许克项的时候才有
  const [receiveGoodsVisible, setReceiveGoodsVisible] = useState(false);
  // 导入导出;
  const dispatch = useDispatch();
  const openModal = useOpenExImportModal();
  // 操作列逻辑
  const { show: showCloseSalesReturnOrderModal } = useCloseSalesReturnOrderModal();
  const { show: showFinishReturnOrderModal } = useFinishReturnOrderModal();

  const refresh = () => {
    setRefreshMarker(Date.now());
  };
  /**
   * 新建入库单
   */
  const goToInbound = (record: SalesReturnOrderListItemType | SalesReturnOrderListItemType[]) => {
    const payload = _.isArray(record) ? record : [record];

    dispatch({
      type: 'supplyChain/formatSalesReturnOrderToInboundOrder',
      payload,
    });
    goToCreateInbound();
  };
  /**
   * 收货操作
   */
  const receiveGoods = (record: SalesReturnOrderListItemType) => {
    setCurrentOrder(record);
    setReceiveGoodsVisible(true);
  };
  /**
   * 主操作按钮
   */
  const mainMenu = _.compact([
    {
      title: '导出',
      auth: authDict.salesorderreturn_export,
      showExport: true,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.salesReturnOrder,
        });
      },
    },
    {
      title: '查看退货申请单',
      auth: authDict.returnauditnote_view,
      onClick: goToReturnApplication,
    },
    {
      title: '新建销售退货单',
      auth: authDict.salesorderreturn_add,
      onClick: goToCreate,
      items: _.compact([
        {
          title: '导入',
          auth: authDict.salesorderreturn_import,
          showExport: true,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.salesReturnOrder,
            });
          },
        },
      ]),
    },
  ]);
  /**
   * 批量操作按钮
   */
  const batchMenu = [
    {
      title: '合并新建入库单',
      auth: authDict.inboundorder_add,
      // onSuccess, onFail: batchMenu onClick回调的参数，onSuccess会刷新列表并清空批量按钮的loading状态，onFail只清空loading状态
      onClick: (onSuccess?: () => void, onFail?: () => void) => {
        const statusInvalid = selectedRows.some(
          (row) =>
            row.returnStatus === ReturnStatus.finished || row.returnStatus === ReturnStatus.closed,
        );

        const amountInvalid = selectedRows.every((row) =>
          row.items?.every(
            (item) => item.amount?.amount! - item.inboundPlannedAmount?.amount! <= 0,
          ),
        );

        const multiOrigin = _.uniqBy(selectedRows, 'origin').length > 1;

        const baseCustomer = selectedRows?.[0]?.customer?.id;
        const isSameCustomer = selectedRows?.every((value) => value?.customer?.id === baseCustomer);

        if (statusInvalid) {
          Message.error('只有新建/执行中的销售退货单可以新建入库单');
          onFail?.();
        } else if (amountInvalid) {
          Message.error('暂无可入库物料');
          onFail?.();
        } else if (multiOrigin) {
          Message.error('只有相同来源的销售退货单可以合并生成入库单');
          onFail?.();
        } else if (!isSameCustomer) {
          Message.error('客户不一致，请选择客户相同的销售退货单');
          onFail?.();
        } else {
          onSuccess?.();
          goToInbound(selectedRows);
        }
      },
    },
  ];
  /**
   * 列操作 ...
   */
  const getOperationList = (record: SalesReturnOrderListItemType) => {
    const { returnStatus, origin, id } = record;

    return _.compact([
      {
        title: lookup('common.crud', CRUD.view),
        auth: authDict.salesorderreturn_detail,
        onClick: () => {
          goToDetail(id!);
        },
      },
      {
        title: lookup('common.crud', CRUD.edit),
        auth: authDict.salesorderreturn_edit,
        disabled:
          origin === ReturnOrigin.cowork ||
          returnStatus === ReturnStatus.finished ||
          returnStatus === ReturnStatus.closed,
        onClick: () => {
          goToEdit(id!);
        },
      },
      // {
      //   title: mainProductLicensed ? '新建入库单' : '收货',
      //   auth: mainProductLicensed
      //     ? authDict.repo_outbound_inbound_inbound_add_edit
      //     : authDict.sale_return_audit_note_receipt,
      //   disabled: returnStatus === ReturnStatus.finished || returnStatus === ReturnStatus.closed,
      //   onClick: () => {
      //     mainProductLicensed ? goToInbound(record) : receiveGoods(record);
      //   },
      // },
      // 暂时不考虑许可项
      {
        title: '新建入库单',
        auth: authDict.inboundorder_add,
        disabled: returnStatus === ReturnStatus.finished || returnStatus === ReturnStatus.closed,
        onClick: () => {
          const amountInvalid = record.items?.every(
            (item) => item.amount?.amount! - item.inboundPlannedAmount?.amount! <= 0,
          );

          if (amountInvalid) {
            Message.error('暂无可入库物料');
          } else {
            goToInbound(record);
          }
        },
      },
      {
        title: '收货',
        auth: authDict.salesorderreturn_receipt,
        disabled: returnStatus === ReturnStatus.finished || returnStatus === ReturnStatus.closed,
        onClick: () => {
          receiveGoods(record);
        },
      },
      {
        title: '结束',
        auth: authDict.salesorderreturn_finish,
        disabled: returnStatus === ReturnStatus.finished || returnStatus === ReturnStatus.closed,
        onClick: () => {
          showFinishReturnOrderModal(record.code!, id!, refresh);
        },
      },
      {
        title: '关闭',
        auth: authDict.salesorderreturn_close,
        disabled: returnStatus !== ReturnStatus.new || origin === ReturnOrigin.cowork,
        onClick: () => {
          showCloseSalesReturnOrderModal(id!, refresh);
        },
      },
      {
        title: '操作记录',
        auth: authDict.salesorderreturn_operrecord,
        onClick: () => {
          setCurrentOrderId(id);
          setVisibleLog(true);
        },
      },
    ]);
  };
  /**
   * Columns
   */
  const columns = [
    {
      title: fieldLabels.code,
      dataIndex: 'code',
      width: 150,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.text,
      render: (text: string, record: SalesReturnOrderListItemType, index: number, config: any) => {
        return hasAuth(authDict.salesorderreturn_detail) ? (
          <a
            onClick={() => {
              goToDetail(record.id!);
            }}
          >
            <TextToolTip text={text} width={config.contentWidth} />
          </a>
        ) : (
          text
        );
      },
    },
    {
      title: fieldLabels.origin,
      dataIndex: 'origin',
      width: 100,
      render: lookup('salesReturnOrder.returnOrigin'),
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: returnOrigin,
        mode: 'multiple',
      },
    },
    {
      title: fieldLabels.purchaseReturnOrderCode,
      dataIndex: 'purchaseOrderReturnCode',
      width: 180,
      isFilter: true,
      filterName: 'purchaseOrderCode',
      type: fieldTypeList.text,
    },
    {
      title: fieldLabels.salesOrderCode,
      dataIndex: 'salesOrderList',
      width: 160,
      render: (salesOrderList: SalesReturnOrderListItemType['salesOrderList']) =>
        _.map(salesOrderList, 'code').join(';'),
      isFilter: true,
      filterName: 'salesOrderCode',
      type: fieldTypeList.text,
    },
    {
      title: fieldLabels.customerCode,
      dataIndex: ['customer', 'code'],
      width: 120,
      isFilter: true,
      filterName: 'customerCode',
      renderItem: (
        <SearchSelect labelPath="code" fetchType="customer" labelInValue mode="multiple" />
      ),
    },
    {
      title: fieldLabels.customerName,
      dataIndex: ['customer', 'name'],
      width: 120,
      isFilter: true,
      filterName: 'customerName',
      renderItem: <SearchSelect fetchType="customer" labelInValue mode="multiple" />,
    },
    {
      title: fieldLabels.material,
      dataIndex: 'materialList',
      width: 200,
      render: (materialList: SalesReturnOrderListItemType['materialList']) =>
        materialList
          ?.map(
            (material) =>
              `${material.code}${material.name} * ${material.amount?.amount}${material.amount?.unitName}`,
          )
          .join(','),
    },
    {
      title: fieldLabels.relatedInboundOrder,
      dataIndex: 'inboundOrderList',
      width: 120,
      render: renderInboundOrder,
    },
    {
      title: fieldLabels.status,
      dataIndex: 'returnStatus',
      width: 100,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: returnStatus,
        mode: 'multiple',
      },
      render: lookup('salesReturnOrder.returnStatus'),
    },

    {
      title: fieldLabels.creator,
      dataIndex: 'creator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: renderUser,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: fieldLabels.createdAt,
      dataIndex: 'createdAt',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: fieldLabels.operator,
      dataIndex: 'operator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: renderUser,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: fieldLabels.updatedAt,
      dataIndex: 'updatedAt',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
  ];
  /**
   * 列表筛选处理函数
   */

  const filterList: FilterItem[] = columns
    .filter((i) => i.isFilter)
    .map((column) => {
      return _.pick(
        {
          ...column,
          label: column.title,
          name: column.filterName ?? column.dataIndex,
          type: column.type,
        },
        [
          'label',
          'name',
          'type',
          'rules',
          'renderItem',
          'selectProps',
          'inputProps',
          'datePickerProps',
          'dateFormat',
        ],
      ) as FilterItem;
    });
  /**
   * 传给接口数据处理函数
   */
  const formatDataToQuery = (param: any) => {
    const { createdAt, updatedAt, customerCode, customerName, creator, operator, ...rest } = param;
    const requestParam = {
      createdAtFrom: createdAt && createdAt[0] ? Date.parse(createdAt[0]) : undefined,
      createdAtTo: createdAt && createdAt[1] ? Date.parse(createdAt[1]) : undefined,
      updatedAtFrom: updatedAt && updatedAt[0] ? Date.parse(updatedAt[0]) : undefined,
      updatedAtTo: updatedAt && updatedAt[1] ? Date.parse(updatedAt[1]) : undefined,
      customerIds: intersectionValueOfId(customerCode, customerName),
      creatorIds: mapLabeledValueToValue(creator),
      operatorIds: mapLabeledValueToValue(operator),
      ...rest,
    };

    dispatch.excelImport.updateBusinessData({ businessData: requestParam });

    // 接口需要的创建时间和更新时间为number型的timestamp
    return requestParam;
  };
  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (param: any) => {
    return param;
  };
  /**
   * 筛选标签，数据显示
   */
  const formatDataToDisplay = (param: any) => {
    return param;
  };

  return (
    <>
      <RecordListLayout<SalesReturnOrderListItemType>
        refreshMarker={refreshMarker}
        filterList={filterList}
        requestFn={fetchSalesOrderReturnList}
        placeholder="请输入销售退货单编号、客户名称"
        batchMenu={batchMenu}
        mainMenu={mainMenu}
        rowKey="id"
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(
          selectedKeys: React.Key[],
          selectedRows: SalesReturnOrderListItemType[],
        ) => {
          setSelectedRowKeys(selectedKeys);
          setSelectedRows(selectedRows!);
        }}
        columns={columns}
        getOperationList={getOperationList}
        userAuth={getAuthFromLocalStorage()}
        formatDataToDisplay={formatDataToDisplay}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={Number(currentOrderId)}
          objectCode={OBJECT_OF_CODE.SalesOrderReturn} // 对象在对象平台唯一定义的objectCode
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      {receiveGoodsVisible && (
        <DataFormLayoutForModal
          visible={receiveGoodsVisible}
          onClose={() => {
            setReceiveGoodsVisible(false);
          }}
          content={
            <ReceiveGoodsDialog
              returnOrder={currentOrder!}
              onCancel={() => {
                setReceiveGoodsVisible(false);
              }}
            />
          }
        />
      )}
    </>
  );
}
