import React, { useEffect, useState } from 'react';
import { DetailLayout, DetailLayoutForDrawer, DetailLayoutInfoBlock } from 'layout';
import { Space, Popover } from 'antd';
import { BlIcon, QrCode, SingleOperationLogComponent } from 'components';
import { replaceSign } from 'src/utils/constants';
import { fetchStorageAreaInfo } from 'src/api/ytt/storage-domain/area';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { match, RouteComponentProps } from 'react-router-dom';
import { toAreaEdit } from '../navigation';

interface StoreDetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const AreaDetailPage = (props: StoreDetailProps) => {
  const [dataSource, setDataSource] = useState({} as any);
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录

  const { match, history } = props;
  const areaId = Number(match?.params?.id);

  const fetchData = () => {
    fetchStorageAreaInfo({ id: areaId })
      .then((res) => {
        const { data, code } = res;

        if (code === 200) {
          setDataSource(data);
        }
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [areaId]);

  const detailInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '仓库名称',
        dataIndex: 'warehouseName',
        isFullLine: true,
        render: (warehouseName: string) => warehouseName ?? replaceSign,
      },
      {
        label: '仓库编号',
        dataIndex: 'warehouseCode',
        isFullLine: true,
        render: (warehouseCode: string) => warehouseCode ?? replaceSign,
      },
      {
        label: '上级区域名称',
        dataIndex: 'parentName',
        isFullLine: true,
        render: (parentName: string) => parentName ?? replaceSign,
      },
      {
        label: '上级区域编号',
        dataIndex: 'parentCode',
        isFullLine: true,
        render: (parentCode: string) => parentCode ?? replaceSign,
      },
      { label: '区域名称', dataIndex: 'name', isFullLine: true },
      { label: '区域编号', dataIndex: 'code', isFullLine: true },
      {
        label: '标识码',
        dataIndex: 'qrCode',
        isFullLine: true,
        render: (qrCode: string) => {
          return (
            <div>
              <Space style={{ marginRight: '5px' }}>
                {qrCode ?? dataSource?.code ?? replaceSign}
              </Space>
              <Space>
                <Popover
                  placement="rightTop"
                  content={
                    <div>
                      <QrCode value={qrCode ?? dataSource?.code ?? ''} />
                      <div style={{ textAlign: 'center' }}>{qrCode ?? dataSource?.code ?? ''}</div>
                    </div>
                  }
                >
                  <BlIcon type="iconerweima" />
                </Popover>
              </Space>
            </div>
          );
        },
      },
    ],
    column: 2,
  };

  const baseMenu = [
    {
      key: 'record',
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'edit',
      auth: authDict.warehouse_edit,
      onClick: () => {
        history.push(toAreaEdit(areaId));
      },
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ];

  const infoArray = [detailInfo];

  return (
    <>
      <DetailLayout
        userAuth={getAuthFromLocalStorage()}
        title={'区域详情'}
        info={infoArray}
        dataSource={dataSource}
        baseMenu={baseMenu}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={areaId}
          objectCode={'Zone'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default AreaDetailPage;
