export const VIEW_MODAL = {
  generalRule: '1',
  businessRule: '2',
};

export const isGeneralRule = (ruleSource: string, arr: any) => {
  return ruleSource === VIEW_MODAL.generalRule ? arr : [];
};

export const isBusinessRule = (ruleSource: string, arr: any) => {
  return ruleSource === VIEW_MODAL.businessRule ? arr : [];
};
