import { BlTable } from 'src/components';
//
import { DetailType } from '../index.d';

interface TagListProps {
  dataSource: NonNullable<DetailType>['tagsList'];
}

const EquipmentDetailTable = (props: TagListProps) => {
  const { dataSource } = props;

  return (
    <BlTable
      dataSource={dataSource}
      columns={[
        {
          title: '标签项',
          dataIndex: 'name',
        },
        {
          title: '标签值',
          dataIndex: 'content',
        },
      ]}
    />
  );
};

export default EquipmentDetailTable;
