import {
  TriggerEventType,
  TriggerType,
  EffectiveType,
  DelayTriggerField,
  DelayTriggerCondition,
  DelayTriggerTimeUnit,
  BizEventExecType,
  ExecType,
  TriggerWeekDay,
  TriggerYearMonth,
  TriggerConditionType,
  ConditionType,
  ValueType,
  ConnectionType,
  ObjectBizType,
  RuleSourceType,
} from '.';
import { mappingsFactory } from '../utils';

/** 触发方式 */
export const triggerType = mappingsFactory(
  ['按业务动作触发', TriggerType.bizEventTrigger],
  ['定时触发', TriggerType.timeIntervalTrigger],
);

/** -------------- 以下是【按业务动作触发】的相关枚举值 ------------------ */

/** 触发动作 */
export const triggerEventType = mappingsFactory(
  ['新建', TriggerEventType.created],
  ['编辑', TriggerEventType.edit],
  // 等待后续支持
  // ['启用', TriggerEventType.enable],
  // ['停用', TriggerEventType.disable],
  // ['导入', TriggerEventType.import],
  // ['导出', TriggerEventType.export],
);

/** 生效时机 */
export const effectiveType = mappingsFactory(
  ['即时生效', EffectiveType.immediately],
  ['首次触发延时生效', EffectiveType.firstTriggerDelay],
);

/** 延时触发字段 */
export const delayTriggerField = mappingsFactory(
  ['规则触发时', DelayTriggerField.triggering],
  ['创建时间', DelayTriggerField.createdAt],
  ['更新时间', DelayTriggerField.updateAt],
  ['入库时间', DelayTriggerField.warehousingAt],
  ['有效期至', DelayTriggerField.expirationDate],
);

/** 延时条件 */
export const delayTriggerCondition = mappingsFactory(
  ['之前', DelayTriggerCondition.before],
  ['之后', DelayTriggerCondition.after],
);

/** 延时时间单位 */
export const delayTriggerTimeUnit = mappingsFactory(
  ['天', DelayTriggerTimeUnit.date],
  ['小时', DelayTriggerTimeUnit.hour],
  ['分钟', DelayTriggerTimeUnit.minute],
);

/** 按业务动作触发，执行频率 */
export const bizEventExecType = mappingsFactory(
  ['触发一次', BizEventExecType.triggerOnce],
  ['自定义', BizEventExecType.customize],
);

/** ------------------------- 以上是【按业务动作触发】的相关枚举值 ------------------ */

/** -------------------------- 以下是【定时执行】的相关枚举值 ------------------ */

/** 定时执行时，执行频率的选项 */
export const execType = mappingsFactory(
  ['每日', ExecType.everyDay],
  ['每周', ExecType.everWeek],
  ['每月', ExecType.everyMonth],
  ['每年', ExecType.everyYear],
  ['固定周期', ExecType.fixedCycle],
  ['自定义', ExecType.customize],
);

/** 每周的周几触发 */
export const triggerWeekDay = mappingsFactory(
  ['一', TriggerWeekDay.Monday],
  ['二', TriggerWeekDay.Tuesday],
  ['三', TriggerWeekDay.Wednesday],
  ['四', TriggerWeekDay.Thursday],
  ['五', TriggerWeekDay.Friday],
  ['六', TriggerWeekDay.Saturday],
  ['日', TriggerWeekDay.Sunday],
);

/** 每年的几月触发 */
export const triggerYearMonth = mappingsFactory(
  ['一', TriggerYearMonth.January],
  ['二', TriggerYearMonth.February],
  ['三', TriggerYearMonth.March],
  ['四', TriggerYearMonth.April],
  ['五', TriggerYearMonth.May],
  ['六', TriggerYearMonth.June],
  ['七', TriggerYearMonth.July],
  ['八', TriggerYearMonth.August],
  ['九', TriggerYearMonth.September],
  ['十', TriggerYearMonth.October],
  ['十一', TriggerYearMonth.November],
  ['十二', TriggerYearMonth.December],
);

/** --------------------------- 以上是【定时执行】的相关枚举值 ------------------------- */

/** 条件配置方式 */
export const triggerConditionType = mappingsFactory(
  ['业务字段', TriggerConditionType.biz],
  // ['自定义公式', TriggerConditionType.customizeTrigger],
);

/** 触发条件配置，条件类型 */
export const conditionType = mappingsFactory(
  ['等于', ConditionType.EQUAL],
  ['不等于', ConditionType.NOT_EQUAL],
  ['包含', ConditionType.INCLUDE],
  ['不包含', ConditionType.EXCLUDE],
  ['为空', ConditionType.NULL],
  ['不为空', ConditionType.NOT_NULL],
  ['大于', ConditionType.LARGER_THAN],
  ['小于', ConditionType.SMALLER_THAN],
  ['大于等于', ConditionType.LARGER_THAN_AND_EQUAL],
  ['小于等于', ConditionType.SMALLER_THAN_AND_EQUAL],
  ['属于', ConditionType.BELONG_TO],
);

/** 触发条件配置，值类型 */
export const valueType = mappingsFactory(
  ['字段引用', ValueType.biz],
  ['值', ValueType.value],
  // ['公式', ValueType.customizeTrigger],
);

/** 触发条件配置，条件值一件关系关联类型 */
export const connectionType = mappingsFactory(
  ['且', ConnectionType.and],
  ['或', ConnectionType.or],
);

/** 业务类型 */
export const objectBizType = mappingsFactory(['默认业务类型', ObjectBizType.default]);

/** 来源类型 */
export const ruleSourceType = mappingsFactory(
  ['手动创建', RuleSourceType.manual],
  ['系统预置', RuleSourceType.preset],
  ['业务自动创建', RuleSourceType.businessAutomation],
);
