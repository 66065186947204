import { useEffect, useState } from 'react';
import { Form, message as Message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import {
  fetchPurchaseReturnOrderUpdate,
  fetchPurchaseReturnOrderDetail,
  fetchPurchaseReturnOrderDetailMaterial,
} from 'src/api/ytt/order-domain/purchaseReturnOrder';
import CreateAndEditBaseInfo from '../components/createAndEditBaseInfo';
import { formatFetchValue } from '../utils';

interface createProps extends RouteComponentProps {}

const EditSalesOrder = (props: createProps) => {
  const { match, history } = props;
  const orderId = Number(match?.params?.id);
  const [loading, setLoading] = useState(true);
  const [createForm] = Form.useForm();
  const [detailData, setDetailData] = useState<any>();

  const fetchData = async () => {
    try {
      const [{ data }, { data: materialLine }] = await Promise.all([
        fetchPurchaseReturnOrderDetail({ id: Number(orderId) }),
        fetchPurchaseReturnOrderDetailMaterial({ id: Number(orderId) }),
      ]);

      if (data) {
        data.itemList = materialLine;
        setDetailData(data);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [orderId]);

  const handleFinish = async () => {
    setLoading(true);
    try {
      const value = await createForm?.validateFields();
      const newValue = formatFetchValue({ id: orderId, ...value });
      const { code } = await fetchPurchaseReturnOrderUpdate(newValue);

      if (code === 200) {
        setLoading(false);
        Message.success('编辑成功');
        history.goBack();
      }
      setLoading(false);
    } catch (error) {
      console.log('error: ', error);
      setLoading(false);
    }
  };

  return (
    <CreateAndEditBaseInfo
      edit
      title="编辑采购退货单"
      onCancel={() => {
        history.goBack();
      }}
      onFinish={handleFinish}
      form={createForm}
      initialData={detailData}
      loading={loading}
    />
  );
};

export default EditSalesOrder;
