import React, { useState, useEffect } from 'react';
import { BlIcon, BlTable } from '@blacklake-web/component';
import { Badge, Button, Form, message, Modal, Space } from 'antd';
import { replaceSign } from 'src/utils/constants';
import { SubTemplateFileInfoListDetailType } from '../../interface';
import { map, throttle } from 'lodash';
import AddSetTemplateFields from './addSetTemplateFields';
import { STATUS_ENUM } from '../../constant';
import {
  fetchEReportDeleteSubTemplate,
  fetchEReportQuerySubTemplateListNoStatus,
  fetchEReportQueryTemDataSetListNoStatus,
  fetchEReportSubTemplateCreate,
  fetchEReportSubTemplateModify,
  fetchEReportTemplateStatusProcess,
} from 'src/api/ytt/e-report-domain/customReport';
import { appEnum } from 'src/dict';
import _Array from 'src/utils/dataTypes/array';
import PreviewModal from '../preview';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'components';
import styles from '../../filter/styles.module.scss';
import authDict, { hasAuth } from 'src/utils/auth';

interface Props {
  isEdit?: boolean;
  initData?: SubTemplateFileInfoListDetailType[];
  refresh?: () => void;
  approval: number;
}

export default function AddTemplateTabComponent({ isEdit, initData, refresh, approval }: Props) {
  const [visiblePreview, setVisiblePreview] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [operationType, setOperationType] = useState<string>(appEnum.Common.CRUD.create);
  const [initLineData, setInitLineData] = useState({} as any);

  const [initSubDataTable, setInitSubDataTable] = useState([] as any);

  const [addForm] = Form.useForm();

  const templateId = Number(localStorage.getItem('templateId'));

  const hasAuthRes = hasAuth(authDict.ereport_enable_disable);

  useEffect(() => {
    if (templateId && isEdit) {
      refreshSubTemplateList();
    }
  }, [templateId]);

  const checkCanAdd = async () => {
    try {
      if (!templateId) {
        // 没有主模板id，代表一定没有数据集
        return message.error('请前往模板数据集标签页，添加模板数据集');
      }
      const { data } = await fetchEReportQueryTemDataSetListNoStatus({ templateId });

      if (_Array.isEmpty(data?.templateDataSetInfoList)) {
        // 创建模板文件前，必须具有模板数据集
        message.error('请前往模板数据集标签页，添加模板数据集');
      } else {
        setInitLineData({});
        setOperationType(appEnum.Common.CRUD.create);
        setVisible(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const refreshSubTemplateList = async () => {
    try {
      const { data } = await fetchEReportQuerySubTemplateListNoStatus({ templateId });

      if (data) {
        localStorage.removeItem('subTemIds');
        setInitSubDataTable(data?.subTemplateFileInfoList);

        if (!_Array.isEmpty(data?.subTemplateFileInfoList)) {
          const subTemIds = map(data?.subTemplateFileInfoList, 'subTemplateId').join(',');

          localStorage.setItem('subTemIds', subTemIds);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteSubTemplate = async (subTemplateId: number) => {
    try {
      const { code, data } = await fetchEReportDeleteSubTemplate({
        subTemplateId,
        templateId,
      });

      if (code === 200 && data) {
        message.success('删除成功');
        refreshSubTemplateList();
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const renderTitle = (cptRelatedParam: any) => {
    return map(cptRelatedParam, (node: any, index: number) => {
      const numLine = index + 1;
      const line = '_';

      const lineNumber = numLine < 10 ? `0${numLine}${line}` : `${numLine}${line}`;

      return (
        <div key={node?.fieldId}>
          {`${lineNumber}${node?.dataSetName}${line}${node?.fieldDisplayName}`}
        </div>
      );
    });
  };

  const refreshSubTemplateStatus = async (
    ids: number[],
    subTemplateStatus: number,
    onSuccess: any,
  ) => {
    try {
      const { data, code } = await fetchEReportTemplateStatusProcess({
        subTemplateIdList: ids,
        status: subTemplateStatus,
      });

      if (code == 200 && !data?.failNum) {
        message.success(
          `${subTemplateStatus === STATUS_ENUM.ENABLED ? '启用' : '停用'}电子单据模板成功`,
        );

        onSuccess?.();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const overflowStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    verticalAlign: 'middle',
  };

  const dataColumns = [
    {
      title: '电子单据模板',
      dataIndex: 'cptFileName',
      width: 200,
      render: (cptFileName: string, record: any) => {
        return cptFileName ? <a href={record?.cptTemplatePath}>{cptFileName}</a> : replaceSign;
      },
    },
    {
      title: '版本号',
      width: 200,
      dataIndex: 'cptVersion',
    },
    {
      title: '模板状态',
      dataIndex: 'subTemplateStatus',
      width: 200,
      sorter: true,
      render: (subTemplateStatus: number) => {
        const useStatusMap = new Map([
          [STATUS_ENUM.ENABLED, <Badge text="启用中" status="success" />],
          [STATUS_ENUM.DISABLED, <Badge text="停用中" status="error" />],
          [STATUS_ENUM.TEMP, <Badge text="暂存" status="error" />],
        ]);

        return useStatusMap.get(subTemplateStatus);
      },
    },
    {
      title: '关联参数',
      dataIndex: 'cptRelatedParam',
      width: 200,
      sorter: true,
      render: (cptRelatedParam: any[]) => {
        return cptRelatedParam?.length > 1 ? (
          <Tooltip.AntTooltip title={renderTitle(cptRelatedParam)}>
            <span style={{ maxWidth: 200, whiteSpace: 'pre', ...overflowStyle }}>
              {`01_${cptRelatedParam?.[0]?.dataSetName}_${cptRelatedParam?.[0]?.fieldDisplayName}${
                cptRelatedParam?.length > 1 ? '...' : ''
              }`}
            </span>
          </Tooltip.AntTooltip>
        ) : (
          <Tooltip
            text={`01_${cptRelatedParam?.[0]?.dataSetName}_${cptRelatedParam?.[0]?.fieldDisplayName}`}
            width={200}
          />
        );
      },
    },
    {
      title: '操作',
      width: 200,
      dataIndex: 'operation',
      render: (_: any, record: SubTemplateFileInfoListDetailType) => {
        return (
          <>
            <Space size={20}>
              {isEdit && (
                <Button
                  type="link"
                  onClick={() => {
                    Modal.confirm({
                      title: '你确定要删除吗？',
                      icon: <ExclamationCircleOutlined />,
                      okText: '确认',
                      cancelText: '取消',
                      onOk: async () => {
                        record?.subTemplateId && handleDeleteSubTemplate(record?.subTemplateId);
                      },
                    });
                  }}
                >
                  删除
                </Button>
              )}
              {isEdit && (
                <Button
                  type="link"
                  onClick={() => {
                    setVisible(true);
                    setInitLineData(record);
                    setOperationType(appEnum.Common.CRUD.edit);
                  }}
                >
                  编辑
                </Button>
              )}
              <Button
                type="link"
                onClick={() => {
                  setVisiblePreview(true);
                  setInitLineData(record);
                }}
              >
                预览
              </Button>
              {!isEdit && hasAuthRes && (
                <Button
                  type="link"
                  onClick={() => {
                    const subTemplateStatus =
                      record?.subTemplateStatus === STATUS_ENUM.ENABLED
                        ? STATUS_ENUM.DISABLED
                        : STATUS_ENUM.ENABLED;

                    refreshSubTemplateStatus([record?.subTemplateId], subTemplateStatus, refresh);
                  }}
                >
                  {record?.subTemplateStatus === STATUS_ENUM.ENABLED ? '停用' : '启用'}
                </Button>
              )}
            </Space>
          </>
        );
      },
    },
  ];

  const getFooter = () => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <div className="add" onClick={checkCanAdd}>
            <span style={{ marginRight: 8 }}>
              <BlIcon type="iconxinjiantianjia" />
            </span>
            上传模板文件
          </div>
        </div>
      </>
    );
  };

  const onFinish = throttle(async () => {
    const value = await addForm?.validateFields();

    const { cptRelatedParam, fileId, ...rest } = value;

    const newCptRelatedParam = map(cptRelatedParam, (item: any) => {
      const value = JSON.parse(item?.fieldId?.value);

      const { checked, fieldInfo, temDataSetName, ...restValue } = value;

      return {
        ...restValue,
        condition: item?.condition,
      };
    });

    const formatValue = {
      ...rest,
      cptRelatedParam: newCptRelatedParam,
      fileId: fileId?.[0],
      subTemplateId:
        operationType === appEnum.Common.CRUD.edit ? initLineData?.subTemplateId : undefined,
    };

    const fetchFn =
      operationType === appEnum.Common.CRUD.edit
        ? fetchEReportSubTemplateModify
        : fetchEReportSubTemplateCreate;

    const { code, data } = await fetchFn({
      subTemplateFileInfo: formatValue,
      templateId,
    });

    if (code == 200 && data) {
      message.success(`${operationType === appEnum.Common.CRUD.edit ? '编辑' : '添加'}子模板成功`);
      setVisible(false);
      addForm?.resetFields();
      refreshSubTemplateList?.();
    }

    refreshSubTemplateList();
  }, 500);

  return (
    <div>
      <BlTable
        columns={dataColumns}
        dataSource={isEdit ? initSubDataTable : initData}
        footer={isEdit ? getFooter : undefined}
        rowKey={(record: any) => record?.subTemplateId}
        id="templateFieldId"
        className={styles?.templateTable}
        scroll={{ x: 1000 }}
        style={{ width: '100%' }}
      />
      {visible && (
        <AddSetTemplateFields
          visible={visible}
          initLineData={initLineData}
          addForm={addForm}
          operationType={operationType}
          onCancel={() => {
            setVisible(false);
          }}
          onFinish={onFinish}
        />
      )}
      {visiblePreview && initLineData && (
        <PreviewModal
          approval={approval}
          visible={visiblePreview}
          initData={initLineData}
          onClose={() => setVisiblePreview(false)}
          templateId={templateId}
          operationType={isEdit ? 'noApprove' : 'needApprove'}
        />
      )}
    </div>
  );
}
