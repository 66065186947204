import { FetchProgressReportListAdjustRecordResponse } from 'src/api/ytt/mfg-domain/productionTask';
import { TableColumnsType } from 'antd';
import _Time from 'src/utils/dataTypes/time';
import { replaceSign } from 'src/utils/constants';
import TagList from 'src/components/tagList';
import _ from 'lodash';
import { TextToolTip } from '@blacklake-web/component';

interface RecordType {
  key: number | string;
  adjustTime: number;
  adjustUser: string;
  adjustItemName: string;
  beforeValue: string[];
  afterValue: string[];
  lineNo: number;
  adjustReason: string;
}

interface TableDataSource {
  key?: number | string;
  adjustTime?: string;
  adjustUser?: string;
  adjustItemName?: string;
  beforeValue?: string[];
  afterValue?: string[];
  lineNo?: number;
  adjustReason?: string;
}

interface Result {
  tableColumns: TableColumnsType<RecordType>;
  tableDataSource: TableDataSource[] | null;
}

export const formatAdjustmentHistoryToTableData: any = (
  data: FetchProgressReportListAdjustRecordResponse['data'] | undefined,
) => {
  const tableColumns: TableColumnsType<RecordType> = [
    {
      title: '调整时间',
      dataIndex: 'adjustTime',
      width: 200,
    },
    {
      title: '调整用户',
      dataIndex: 'adjustUser',
      width: 200,
    },
    {
      title: '调整原因',
      dataIndex: 'adjustReason',
      width: 200,
    },
    {
      title: '调整字段',
      dataIndex: 'adjustItemName',
      width: 200,
    },
    {
      title: '调整前',
      dataIndex: 'beforeValue',
      width: 500,
      render: (value) => {
        if (_.isEmpty(value)) return replaceSign;
        return (
          <TagList
            maxShow={5}
            maxText={20}
            dataSource={value.map((item: any) => ({ label: item || '-', value: item }))}
          />
        );
      },
    },
    {
      title: '调整后',
      dataIndex: 'afterValue',
      width: 500,
      render: (value) => {
        if (_.isEmpty(value)) return replaceSign;
        return (
          <TagList
            maxShow={5}
            maxText={20}
            dataSource={value.map((item: any) => ({ label: item || '-', value: item }))}
          />
        );
      },
    },
  ];
  const tableDataSource: TableDataSource[] = [];

  if (!data) return { tableColumns, tableDataSource: null };
  const rowSpanList: any[] = [];
  const pageSize = 10;
  const maxSizeNumber = pageSize;
  let restSizeNumber = pageSize;
  const recordLengthList: number[] = [];

  data.forEach((item) => {
    item?.adjustItems?.length && recordLengthList.push(item?.adjustItems?.length);
  });
  const fillRowSpanList = (n: number) => {
    if (n <= restSizeNumber && n > 0) {
      rowSpanList.push(n);
      restSizeNumber -= n;
      for (let i = 0; i < n - 1; i++) {
        rowSpanList.push(0);
      }
      if (restSizeNumber === 0) restSizeNumber = maxSizeNumber;
    } else if (n > restSizeNumber) {
      rowSpanList.push(restSizeNumber);
      for (let i = 0; i < restSizeNumber - 1; i++) {
        rowSpanList.push(0);
      }
      const restN = n - restSizeNumber;

      restSizeNumber = maxSizeNumber;
      fillRowSpanList(restN);
    }
  };

  recordLengthList.forEach(fillRowSpanList);

  let recordCount = 0;

  data.forEach((item, index) => {
    // 设置 columns
    tableColumns[0].render = (value, record) => {
      return {
        children: value,
        props: { rowSpan: rowSpanList[record.lineNo] },
      };
    };
    tableColumns[1].render = (value, record) => {
      return {
        children: value,
        props: { rowSpan: rowSpanList[record.lineNo] },
      };
    };
    tableColumns[2].render = (value, record) => {
      return {
        children: <TextToolTip text={value} width={130} />,
        props: { rowSpan: rowSpanList[record.lineNo] },
      };
    };
    // 设置 dataSource
    item?.adjustItems?.forEach((item2, index2) => {
      tableDataSource.push({
        key: String(index) + '-' + String(index2),
        lineNo: recordCount,
        adjustTime: _Time.format(item.adjustTime),
        adjustUser: item.adjustUser || '-',
        adjustItemName: item2.adjustItemName || '-',
        beforeValue: item2.beforeValue,
        afterValue: item2.afterValue,
        adjustReason: item.adjustReason || '-',
      });
      recordCount++;
    });
  });
  return { tableColumns, tableDataSource };
};

export const formatResourceGroupListToResourceList = (data: any[]) => {
  if (_.isEmpty(data)) return undefined;
  const labelList: any = [];
  const valueList: any = [];

  data.forEach((item, index) => {
    labelList.push({
      dataIndex: ['resourceList', index],
      label: `${item.groupName}`,
      render: (value: any) => {
        if (_.isEmpty(value)) return replaceSign;
        return (
          <TagList
            dataSource={value.map((item: any, index: number) => ({
              label: item ?? replaceSign,
              value: item,
              key: index,
            }))}
          />
        );
      },
    });
    valueList.push(_.map(item.resourceList, 'name'));
  });

  return {
    labelList,
    valueList,
  };
};
