/**
 * 子项物料
 */
import React, { useState } from 'react';
import _ from 'lodash';
import { TextToolTip } from '@blacklake-web/component';
import { Form, FormInstance, Input, InputNumber, Select, Space, Tag, Radio, Button } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/es/table';
import { FieldData } from 'rc-field-form/es/interface.d';
//
import {
  BatchOperateTableHeader,
  BlSortFormList,
  MaterialSelect,
  SearchSelect,
  TagList,
} from 'src/components';
import lookup, { appDict, appEnum } from 'src/dict';
import { DataFormLayoutForModal } from 'src/layout';
import {
  getMaterialAttrs,
  getMaterialFeedUnit,
  getMaterialUnitInfo,
  getMaterialUnits,
  judgeMaterialIsVirtual,
} from 'src/entity/material';
import { MaterialEntity } from 'src/entity/material/index.type';
import { FetchProcessRouteDetailResponse } from 'src/api/ytt/med-domain/process-route';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import { NamePath } from 'antd/lib/form/interface';
import { fetchBomList } from 'src/api/ytt/med-domain/bom';
import { ICustomFields } from 'src/components/customField/interface';
//
import { getLineSeq, formatMaterialToApi, formatMaterialToForm } from '../utils';
import {
  BomFormFeedingControl,
  BomFormInputMaterial,
  BomRequestFeedingControl,
  BomRequestInputMaterial,
  BomRequestInputMaterialLines,
  BomResponseInputMaterial,
} from '../index.type';
import FeedingControlForm, {
  formatBomFeedingControlToApi,
  formatBomFeedingControlToForm,
  getDefaultInputMaterialControl,
  DEFAULT_FEEDINGCONTROL_FROMVALUE,
} from './feedingControlForm';
import { injectCustomFieldColumns } from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

interface SubItemMaterialFormProps {
  type?: string;
  form: FormInstance;
  name: string;
  /**
   * 通过重排来组装列columns,工单
   */
  resetColumns?: ColumnProps<any & FormListFieldData>[];
  /**
   * 是否用于物料清单 0:用于物料清单  1:用于工单  2:计划订单
   */
  useType?: number;
  /**
   * 依赖的外部form字段
   */
  dependenciesFieldPath?: {
    bomMaterial: NamePath; // 父项物料
    processRoute: NamePath; // 工艺路线
    sop: NamePath; // sopId
  };
  /**
   * 自定义字段
   */
  bomCustomFields?: ICustomFields;
}

interface ChangeValue<T> {
  fieldStr?: keyof T;
  value?: any;
  iterationFn?: (item: T, index: number) => T;
}

// 格式化 子项物料 到接口
export const formatBomInputMaterialsToApi = (
  bomInputMaterials?: BomFormInputMaterial[],
  prossessRouteDetail?: FetchProcessRouteDetailResponse['data'],
): BomRequestInputMaterial[] => {
  if (_.isEmpty(bomInputMaterials)) return [];

  const retBomInputMaterials: BomRequestInputMaterial[] = [];

  // 把 平级结构 组装成 树结构 返回给接口
  bomInputMaterials?.forEach(
    ({
      materialId,
      bomFeedingControls,
      inputAmountDenominator,
      inputAmountNumerator,
      lossRate,
      isChildren,
      inputProcessId,
      version,
      splitProcessInput,
      ...resItem
    }) => {
      const formatItem: BomRequestInputMaterial = {
        ...resItem,
        relatedBomId: version?.value ? _.toNumber(version?.value) : undefined,
        inputAmountDenominator: _.toString(inputAmountDenominator),
        inputAmountNumerator: _.toString(inputAmountNumerator),
        lossRate: _.toString(lossRate),
        bomFeedingControls: formatBomFeedingControlToApi(
          bomFeedingControls,
          0,
        ) as BomRequestFeedingControl[],
        materialId: formatMaterialToApi(materialId),
        inputProcessId: inputProcessId?.value
          ? _.toNumber(inputProcessId?.value)
          : _.head(prossessRouteDetail?.processes)?.processId, // 不选默认传工艺路线第一个工序
        splitProcessInput: splitProcessInput ?? appEnum.Common.YN.no,
      };

      if (isChildren) {
        const childFormatItem: BomRequestInputMaterialLines =
          formatItem as BomRequestInputMaterialLines;

        // 子行置为 undefined
        childFormatItem.splitProcessInput = undefined;

        const lastIndex = retBomInputMaterials.length - 1;

        if (retBomInputMaterials[lastIndex]) {
          retBomInputMaterials[lastIndex]?.bomInputMaterialLines?.push(childFormatItem);
        }
      } else {
        _.set(formatItem, 'bomInputMaterialLines', []);

        retBomInputMaterials.push(formatItem);
      }
    },
  );

  return retBomInputMaterials;
};

/**
 * 格式化 子项物料 给form
 * @param value
 * @returns
 */
export const formatBomInputMaterialsToForm = (
  value?: BomResponseInputMaterial[],
  fn?: (item: BomResponseInputMaterial) => { [index: string]: any }, // 给工单预留初始化
): BomFormInputMaterial[] => {
  if (_.isEmpty(value)) return [];

  const retBomInputMaterials: BomFormInputMaterial[] = [];

  const formatItem = (
    item: BomResponseInputMaterial,
    index: number,
    isChildren: boolean = false,
  ): BomFormInputMaterial => {
    const {
      seq = index * 10,
      unitId = 0,
      version = '',
      relatedBomId,
      lossRate = 0,
      pickMode = appEnum.Bom.PickMode.demandPick,
      material = {},
      inputAmountNumerator = 1,
      inputAmountDenominator = 1,
      specificProcessInput = appEnum.Common.YN.no,
      bomInputMaterialLines = [],
      bomFeedingControls,
      inputProcessRouteNodeSimpleVO,
      ...resItem
    } = item;

    return {
      ...resItem,
      seq,
      unitId,
      version: relatedBomId ? { label: version, value: relatedBomId } : undefined,
      lossRate: _.toNumber(lossRate),
      pickMode,
      materialId: formatMaterialToForm(material),
      isChildren,
      specificProcessInput,
      inputProcessId: inputProcessRouteNodeSimpleVO?.id
        ? {
            label: inputProcessRouteNodeSimpleVO?.processNum,
            value: inputProcessRouteNodeSimpleVO?.id,
          }
        : undefined,
      inputAmountNumerator: _.toNumber(inputAmountNumerator),
      inputAmountDenominator: _.toNumber(inputAmountDenominator),
      bomInputMaterials: _.map<BomResponseInputMaterial, BomFormInputMaterial>(
        bomInputMaterialLines,
        (item, index) => formatItem(item, index, true),
      ),
      bomFeedingControls: formatBomFeedingControlToForm(bomFeedingControls),
      ...(fn?.(item) ?? {}),
    };
  };

  _.forEach(value, (item, index) => {
    const { bomInputMaterials, ...formatValue } = formatItem(item, index);

    retBomInputMaterials.push(formatValue);

    // 如果有子行,把子行平铺到子项物料
    if (bomInputMaterials && !_.isEmpty(bomInputMaterials)) {
      retBomInputMaterials.push(...bomInputMaterials);
    }
  });

  return retBomInputMaterials;
};

export const SubItemMaterialForm = (props: SubItemMaterialFormProps) => {
  const {
    form,
    name,
    type = appEnum.Common.CRUD.edit,
    resetColumns = [],
    useType = 1, // 不传 useType，默认用于生产工单
    dependenciesFieldPath = {
      bomMaterial: 'materialId',
      processRoute: 'processRouteId',
      sop: '',
    },
    bomCustomFields,
  } = props;
  const { getFieldValue, setFields } = form;

  const [materialFeedingControl, setMaterialFeedingControl] = useState<
    FormListFieldData | undefined
  >(); // 设置投料管控,存的当前子项物料行的field信息

  /**
   * 获取初始值，
   * @param field
   * @param fieldStr
   * @param defaultValue
   * @returns
   */
  const getDefaultValue = (field: FormListFieldData, fieldStr: string, defaultValue: any) => {
    const hasValue = !_.isNil(form.getFieldValue([name, field.name, fieldStr]));

    if (hasValue) return undefined;

    return defaultValue;
  };

  /**
   * 校验子项物料
   */
  const validatorMaterial = (field: FormListFieldData) => {
    return (rule: any, value: any) => {
      const bomMaterial = getFieldValue(dependenciesFieldPath.bomMaterial); // 父项物料

      const bomMaterialIsVirual = judgeMaterialIsVirtual(
        getMaterialInfo(undefined, undefined, bomMaterial),
      );
      const subItemMaterialIsVirual = judgeMaterialIsVirtual(
        getMaterialInfo(undefined, undefined, value),
      );
      const subItemMaterialChildrens = getRowChildrens(
        field.name,
        _.cloneDeep(form.getFieldValue([name])),
      );

      if (bomMaterialIsVirual && subItemMaterialIsVirual) {
        return Promise.reject('父项物料为虚拟件时，子项物料不能为虚拟件');
      }

      if (subItemMaterialChildrens?.length === 1) {
        return Promise.reject('若拆分工序投料，则必须维护多行投料工序');
      }

      return Promise.resolve('');
    };
  };

  /**
   * 校验用量:分子的数量
   * @param field
   * @returns
   */
  const validatorInputAmountNumerator = (field: FormListFieldData) => {
    return (rule: any, value: any) => {
      const bomFeedingControls = getFieldValue([name, field.name, 'bomFeedingControls']); // 当前行投料管控

      if (!_.isEmpty(bomFeedingControls) && bomFeedingControls.length > 1) {
        let total = 0;

        _.forEach(bomFeedingControls, ({ inputAmountNumerator = 0 }) => {
          total += inputAmountNumerator;
        });

        if (total !== value ?? 1) {
          return Promise.reject('投料管控子行用量:分子之和必须等于所属子项行用量:分子');
        }
      }

      return Promise.resolve('');
    };
  };

  /**
   * 校验单位，存在数量时必填
   */
  const validatorUnit = (fieldName: number) => {
    return (rule: any, value: any) => {
      const amount = getFieldValue([name, fieldName, 'amount']);

      if (_.isNumber(amount) && _.isNil(value)) {
        return Promise.reject('数量填写时，单位不能为空');
      }

      return Promise.resolve('');
    };
  };

  /**
   * 获取行物料其他信息
   */
  const getMaterialInfo = (fieldName?: number, key?: string, value?: any) => {
    let material = {};

    if (value) {
      material = JSON.parse(value?.value ?? '{}');
    } else if (_.isNumber(fieldName)) {
      material = JSON.parse(getFieldValue([name, fieldName, 'materialId'])?.value ?? '{}');
    }

    if (key) {
      return _.get(material, key);
    }

    return material;
  };

  /**
   * 根据物料获取默认领料方式
   * @param material
   */
  const getDefaultPickModeWithMaterial = (material: MaterialEntity) => {
    if (_.isEmpty(material)) return undefined;

    const isVirtualMaterial = judgeMaterialIsVirtual(material);

    return isVirtualMaterial ? appEnum.Bom.PickMode.notPick : appEnum.Bom.PickMode.demandPick;
  };

  /**
   * 获取改行初始化投料管控数据
   */
  const getDefaultFeedingControls = (formList?: BomFormInputMaterial[], fieldName?: number) => {
    const baseFeedingControl = _.cloneDeep(DEFAULT_FEEDINGCONTROL_FROMVALUE);

    if (_.isNil(formList) || _.isNil(fieldName)) {
      return [baseFeedingControl];
    }

    const current = formList[fieldName];

    return [
      {
        ...baseFeedingControl,
        inputAmountDenominator: current.inputAmountDenominator,
        inputAmountNumerator: current.inputAmountNumerator,
        inputMaterialControl: getDefaultInputMaterialControl(current.specificProcessInput),
      },
    ];
  };

  /**
   * 根据物料获取默认版本号
   * @param material
   */
  const getDefaultVersionWithMaterial = async (material: MaterialEntity) => {
    try {
      const materialId = _.get(material, 'baseInfo.id', undefined);

      if (_.isNil(materialId)) return undefined;

      const res = await fetchBomList({ materialIds: [materialId] });

      const defaultVersion = _.head(
        _.filter(_.get(res, 'data.list', []), ['defaultVersion', appEnum.Common.YN.yes]),
      );

      if (_.isEmpty(defaultVersion)) return undefined;

      return { label: defaultVersion.version, value: defaultVersion.id };
    } catch (error) {
      return undefined;
    }
  };

  /**
   * 检查投料工序号是否可填
   */
  const checkInputProcessIdDisabled = (fieldName: number) => {
    const { specificProcessInput, splitProcessInput } = form.getFieldValue([name, fieldName]) ?? {};
    const { value: processRouteId } = form.getFieldValue(dependenciesFieldPath.processRoute) ?? {};

    // disabled情况 1.子项物料有子行物料 2.指定投料工序=否 3.父项物料没有选工艺路线
    const disabled = splitProcessInput || !specificProcessInput || _.isNil(processRouteId);

    return !!disabled;
  };

  /**
   * 在编辑和复制bom时，对子项进行复制操作时，需要清空原有的列id和对应投料管控中的列id,保证新添加复制的子项id为空
   */
  const cleanId = (baseItem: BomFormInputMaterial) => {
    baseItem.id = undefined;

    /** 需要置空 投料管控的所有id */
    baseItem = setRowValueToFeedingControl(baseItem, [{ fieldStr: 'id', value: undefined }]);

    return baseItem;
  };

  /**
   * 设置指定行 字段的值
   * @param baseItem
   * @param fieldName // 行号
   * @param changeValues
   * @returns
   */
  const setItemValues: <T extends Object>(
    baseItem: T,
    fieldName: number,
    changeValues: ChangeValue<T>[],
  ) => T = (baseItem, fieldName, changeValues) => {
    if (!_.isEmpty(changeValues)) {
      _.forEach(changeValues, ({ fieldStr, value, iterationFn }) => {
        /** 如果changeValue传了fieldStr字段，则所有iterationFn或value的修改都在该字段上 */
        if (!_.isNil(fieldStr) && fieldStr) {
          const itemValue = _.isFunction(iterationFn) ? iterationFn(baseItem, fieldName) : value;

          _.set(baseItem, fieldStr, itemValue);
        } else {
          /** 如果changeValue没传fieldStr字段，则所有iterationFn或value的修改都在baseItem整个行对象上 */
          baseItem = _.isFunction(iterationFn) ? iterationFn(baseItem, fieldName) : baseItem;
        }
      });
    }
    return baseItem;
  };

  /**
   * 批量设置行字段的值
   * @param changeValues
   * @param formList 当外部传入时，返回处理后的formList
   */
  const batchSetItemValues = (
    changeValues: ChangeValue<BomFormInputMaterial>[],
    formList?: BomFormInputMaterial[],
  ) => {
    let retFormList: BomFormInputMaterial[] = [];
    const hasFormList = !_.isEmpty(formList);

    if (hasFormList && formList) {
      retFormList = formList;
    } else {
      retFormList = _.cloneDeep(form.getFieldValue(name));
    }

    _.forEach(retFormList, (item, fieldName) => {
      item = setItemValues(item, fieldName, changeValues);
    });

    /** 外部不传formList时，自动form.setFieldsValue */
    if (!hasFormList) {
      form.setFieldsValue({
        [name]: retFormList,
      });
    }

    return retFormList;
  };

  /**
   * 复制一行或整个formList信息(可能是子项物料，也可能是子行物料)
   * @param fieldName
   */
  const copyRows = (fieldName?: number): BomFormInputMaterial[] => {
    const formList = _.cloneDeep(form.getFieldValue(name));

    if (_.isNumber(fieldName)) {
      return [formList[fieldName]];
    }

    return formList;
  };

  // 获取当前子项物料的所有子行
  const getRowChildrens = (fieldName: number, formList: BomFormInputMaterial[]) => {
    const childrens: BomFormInputMaterial[] = [];

    if (_.isEmpty(formList)) return childrens;
    if (formList[fieldName]?.isChildren) return childrens; // 子行物料没有下层了

    for (let i = fieldName + 1; i < formList.length; i++) {
      const element = formList[i];

      if (element.isChildren) {
        childrens.push(element);
      } else {
        break;
      }
    }

    return childrens;
  };

  /**
   * 判断当前子项物料是否有子行
   * @param field
   * @returns
   */
  const juedgeRowHasChildren = (fieldName: number, formList: BomFormInputMaterial[]) => {
    // 当前行是子项物料 && 下一行为子项物料-子行
    if (!formList[fieldName].isChildren && formList[fieldName + 1]?.isChildren) {
      return true;
    }
    return false;
  };

  /**
   * 把子项的子行删空时，重置当前子行的一些字段
   * @param fieldName
   * @param formList
   */
  const resetEmptyRow = (fieldName: number, formList: BomFormInputMaterial[]) => {
    if (!formList[fieldName].isChildren) {
      formList[fieldName].splitProcessInput = appEnum.Common.YN.no; // 重置 拆分工序投料 为否
      formList[fieldName].splitSopControlInput = appEnum.Common.YN.no; // 重置拆分控件投料 为否
      formList[fieldName].bomFeedingControls = getDefaultFeedingControls(formList, fieldName); // 重新初始化 投料管控
    }

    return formList;
  };

  /**
   * 删除指定子项物料的子行
   * @param field
   */
  const deleteRowChildren = (fieldName: number, formList: BomFormInputMaterial[]) => {
    if (juedgeRowHasChildren(fieldName, formList)) {
      // 如果有子行，删除
      const childrens = getRowChildrens(fieldName, formList);

      formList.splice(fieldName + 1, childrens.length);

      formList = resetEmptyRow(fieldName, formList);
    }

    return formList;
  };

  /**
   * 删除一行子项或子行
   * @param fieldName
   */
  const deleteRow = (fieldName: number, formList: BomFormInputMaterial[]) => {
    let retFormList = _.cloneDeep(formList);

    // 如果是子项，需删除其下子行
    if (!retFormList[fieldName].isChildren) {
      retFormList = deleteRowChildren(fieldName, formList);
    } else if (!retFormList[fieldName + 1]?.isChildren && !retFormList[fieldName - 1]?.isChildren) {
      // 如果删除的是父节点唯一存在的子行，需要更改父节点form value
      retFormList = resetEmptyRow(fieldName - 1, formList);
    }

    retFormList.splice(fieldName, 1);

    return retFormList;
  };

  /**
   * 复制 子项物料
   * @param fields
   */
  const copyRow = (fieldName: number, formList: BomFormInputMaterial[]) => {
    const baseItem = cleanId(_.cloneDeep(formList[fieldName]));

    // 复制的是带有子行的子项物料
    if (juedgeRowHasChildren(fieldName, formList)) {
      const childrens = _.map(getRowChildrens(fieldName, formList), cleanId);
      const childrensAmount = childrens.length;

      formList.splice(fieldName + childrensAmount + 1, 0, baseItem, ...childrens);
    } else {
      formList.splice(fieldName + 1, 0, baseItem);
    }

    return formList;
  };

  /**
   * 复制 子项物料-子行
   * @param field
   */
  const copyRowChildren = (fieldName: number, formList: BomFormInputMaterial[]) => {
    const baseItem = cleanId(_.cloneDeep(formList[fieldName]));

    formList.splice(fieldName + 1, 0, baseItem);

    return formList;
  };

  /**
   * 添加子行
   * @param field
   */
  const addChildren = (fieldName: number, formList: BomFormInputMaterial[]) => {
    const childrensAmount = getRowChildrens(fieldName, formList).length;
    let baseItem;

    if (childrensAmount === 0) {
      // 如果没有子行，从子项上复制，且需要修改子项行的值

      baseItem = cleanId(_.cloneDeep(formList[fieldName]));

      // 初始化复制的子行的值
      baseItem.isChildren = true;
      baseItem.seq = getLineSeq(childrensAmount, baseItem.seq);
      baseItem.splitProcessInput = undefined;

      // 修改原来子项物料值
      formList[fieldName].splitProcessInput = appEnum.Common.YN.yes;
      formList[fieldName].inputProcessId = undefined;
      formList[fieldName].bomFeedingControls = undefined;
      formList[fieldName].splitSopControlInput = undefined;
      formList[fieldName].customFields = undefined;
    } else {
      // 如果有子行，从第一个子行上复制内容
      baseItem = cleanId(_.cloneDeep(formList[fieldName + 1]));
      baseItem.seq = getLineSeq(childrensAmount, formList[fieldName].seq);
    }

    // 把新增的子行添加到form中
    formList.splice(fieldName + childrensAmount + 1, 0, baseItem);

    return formList;
  };

  /**
   * 行字段变动同步到其子行上
   * @param fieldName
   * @param changeValues
   * @param formList
   */
  const setRowValueToChildren = (
    fieldName: number,
    formList: BomFormInputMaterial[],
    changeValues: ChangeValue<BomFormInputMaterial>[],
  ) => {
    const baseItem = _.cloneDeep(formList[fieldName]);

    // 当前行不是子行 || 没有子行 时不需要处理
    if (baseItem.isChildren || !juedgeRowHasChildren(fieldName, formList)) {
      return formList;
    }

    const childrensAmount = getRowChildrens(fieldName, formList).length;

    formList = batchSetItemValues(
      [
        {
          iterationFn: (item, index) => {
            /** 找到该行其下的子行 */
            if (index > fieldName && index <= fieldName + childrensAmount) {
              item = setItemValues(item, index, changeValues);
            }
            return item;
          },
        },
      ],
      formList,
    );

    return formList;
  };

  /**
   * 处理 行字段 变动，对本行和子行的影响
   */
  const setRowValueChange = (
    fieldName: number,
    formList: BomFormInputMaterial[],
    changeValues: ChangeValue<BomFormInputMaterial>[],
    needChangeChild = true, // 是否对子行也有同样的影响
  ) => {
    if (!_.isEmpty(changeValues) && changeValues) {
      /** 处理对本行的影响 */
      formList[fieldName] = setItemValues(formList[fieldName], fieldName, changeValues);

      if (needChangeChild) {
        /** 处理对子行的影响 */
        formList = setRowValueToChildren(fieldName, formList, changeValues);
      }
    }

    return formList;
  };

  /**
   * 修改 物料 改变后的值
   * @param value
   * @param fieldName
   * @param formList 会影响到其他行，所以传整个formList
   * @returns
   */
  const changeMaterialValue = async (
    value: any,
    fieldName: number,
    formList: BomFormInputMaterial[],
  ) => {
    const materialInfo = getMaterialInfo(undefined, undefined, value);
    const pickMode = getDefaultPickModeWithMaterial(materialInfo);
    const isVirtualMaterial = judgeMaterialIsVirtual(materialInfo);

    const unit = getMaterialFeedUnit(materialInfo, true);
    const version = await getDefaultVersionWithMaterial(materialInfo);
    const inputQcState =
      materialInfo?.productionInfo?.manufactureQualityStatus ??
      DEFAULT_FEEDINGCONTROL_FROMVALUE.inputQcState;

    const changeValues: ChangeValue<BomFormInputMaterial>[] = [
      { fieldStr: 'materialId', value },
      { fieldStr: 'pickMode', value: pickMode ?? appEnum.Bom.PickMode.demandPick },
      { fieldStr: 'unitId', value: unit?.value },
      { fieldStr: 'version', value: version },
      {
        iterationFn: (item) =>
          setRowValueToFeedingControl(item, [{ fieldStr: 'inputQcState', value: inputQcState }]),
      },
    ];

    if (isVirtualMaterial) {
      /** 虚拟件物料删除其下的子行 */
      formList = deleteRowChildren(fieldName, formList);

      /** 虚拟件物料重置一些其他字段 */
      changeValues.push({
        iterationFn: (item) => changeInputProcessIdValue(undefined, item),
      });
    }

    formList = setRowValueChange(fieldName, formList, changeValues);

    return formList;
  };

  /**
   * 修改 投料工序号 并且改变关联的值
   * @param value
   * @param baseItem 只会影响到修改行，所以传当前行
   */
  const changeInputProcessIdValue = (value: any, baseItem: BomFormInputMaterial) => {
    baseItem.inputProcessId = value;

    /** 需要置空投料控件编号 */
    baseItem = setRowValueToFeedingControl(baseItem, [
      { fieldStr: 'inputSopControlId', value: undefined },
    ]);

    return baseItem;
  };

  /**
   * 修改 指定投料工序 改变后的值
   * @param value
   * @param fieldName
   * @param formList 会影响到其他行，所以传整个formList
   */
  const changeSpecificProcessInputValue = (
    value: number,
    fieldName: number,
    formList: BomFormInputMaterial[],
  ) => {
    const changeList: ChangeValue<BomFormInputMaterial>[] = [
      { fieldStr: 'specificProcessInput', value },
      {
        iterationFn: (item) => changeInputProcessIdValue(undefined, item),
      },
    ];

    // 指定投料工序为 否 时,需要额外的联动
    if (value === appEnum.Common.YN.no) {
      // 删除子行物料
      formList = deleteRowChildren(fieldName, formList);

      if (!_.isEmpty(formList[fieldName].bomFeedingControls)) {
        // 如果有投料管控，把投料管控的 是否必投 改 为非必投
        changeList.push({
          iterationFn: (item) =>
            setRowValueToFeedingControl(item, [
              { fieldStr: 'inputMaterialControl', value: appEnum.Bom.FeedingControl.no },
            ]),
        });
      }
    }

    formList = setRowValueChange(fieldName, formList, changeList);
    return formList;
  };

  /**
   * 行字段值修改同步到指定投料管控上
   * @param changeValues
   * @param baseItem 只会影响到修改行，所以传当前行
   */
  const setRowValueToFeedingControl = (
    baseItem: BomFormInputMaterial,
    changeValues: ChangeValue<BomFormFeedingControl>[],
  ) => {
    if (!_.isEmpty(baseItem.bomFeedingControls) && baseItem.bomFeedingControls) {
      if (baseItem.bomFeedingControls?.length > 1) {
        /** 当投料管控只有一行时，『用量分子』才会同步，多行不同步该字段 */
        changeValues = _.filter(changeValues, (item) => item?.fieldStr !== 'inputAmountNumerator');
      }
      _.forEach(baseItem.bomFeedingControls, (item, index) => {
        item = setItemValues(item, index, changeValues);
      });
    }

    return baseItem;
  };

  /**
   * 处理 投料管控 变动
   * @param value
   */
  const handleFeedingControlChange = (value?: BomFormFeedingControl[]) => {
    if (materialFeedingControl && !_.isNil(value) && !_.isEmpty(value)) {
      const changeValues: FieldData[] = [
        {
          name: [name, materialFeedingControl.name, 'bomFeedingControls'],
          value,
        },
        {
          name: [name, materialFeedingControl.name, 'splitSopControlInput'],
          value: value?.length >= 2 ? appEnum.Common.YN.yes : appEnum.Common.YN.no,
        },
      ];

      // 如果投料管控只有一行，把分子用量同步到当前子项物料的分子用量上
      if (value?.length === 1) {
        changeValues.push({
          name: [name, materialFeedingControl.name, 'inputAmountNumerator'],
          value: _.head(value)?.inputAmountNumerator,
        });
      }

      setFields(changeValues);
    }
    setMaterialFeedingControl(undefined);
  };

  const baseColumnsMap = new Map<string, ColumnProps<FormListFieldData & any>>([
    [
      'seq',
      {
        title: '项次',
        dataIndex: 'seq',
        fixed: 'left',
        width: 160,
        render: (_text, field) => {
          return (
            <Form.Item dependencies={[]} noStyle>
              {() => {
                const formList = form.getFieldValue([name]);

                const { isChildren = false } = formList[field.name];
                const nextIndex =
                  _.filter(formList, ({ isChildren }) => isChildren !== true).length - 1;

                return (
                  <Space size={0}>
                    {isChildren && <Tag color="success">子</Tag>}
                    <Form.Item
                      initialValue={getDefaultValue(field, 'seq', getLineSeq(nextIndex))}
                      name={[field.name, 'seq']}
                      fieldKey={[field.fieldKey, 'seq']}
                      style={{ marginBottom: '0' }}
                      rules={[{ required: true, message: '项次不能为空' }]}
                    >
                      <InputNumber placeholder="请输入" min={1} precision={0} step={5} />
                    </Form.Item>
                  </Space>
                );
              }}
            </Form.Item>
          );
        },
      },
    ],
    [
      'materialId',
      {
        title: <BatchOperateTableHeader titleText="物料编号" required />,
        dataIndex: 'materialId',
        fixed: 'left',
        width: 200,
        render: (text, field) => {
          const baseSelectProps = {
            fetchType: 'materialCompleteInfo',
            style: { width: '100%' },
            placeholder: '请输入',
            labelInValue: true,
            params: {
              enableFlag: appEnum.Common.UsageStatus.enabled,
              bizType: [appEnum.Material.BizType.default, appEnum.Material.BizType.phantom],
            },
            disabled: getFieldValue([name, field.name, 'isChildren']),
            onChange: (value: any) => {
              changeMaterialValue(value, field.name, copyRows()).then((newFormList) => {
                form.setFieldsValue({
                  [name]: newFormList,
                });
              });
              if (useType) {
                form.setFields([
                  {
                    name: [name, field?.name, 'workOrderAlternativePlan'],
                    value: undefined,
                  },
                ]);
              }
            },
          };

          if (useType) {
            baseSelectProps.params = {
              enableFlag: appEnum.Common.UsageStatus.enabled,
              bizType: [appEnum.Material.BizType.default],
            };
          }

          return (
            <div>
              <Form.Item
                style={{ marginBottom: '0' }}
                fieldKey={[field.fieldKey, 'materialId']}
                name={[field.name, 'materialId']}
                rules={[
                  { required: true, message: '物料不能为空' },
                  { validator: validatorMaterial(field) },
                ]}
              >
                <MaterialSelect {...baseSelectProps} />
              </Form.Item>
            </div>
          );
        },
      },
    ],
    [
      'materialName',
      {
        title: '物料名称',
        dataIndex: 'materialName',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() => _.get(getMaterialInfo(field.name, 'baseInfo'), 'name')}
            </Form.Item>
          );
        },
      },
    ],
    [
      'attributes',
      {
        title: '物料属性',
        dataIndex: 'attributes',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() => <TagList dataSource={getMaterialAttrs(getMaterialInfo(field.name))} />}
            </Form.Item>
          );
        },
      },
    ],
    [
      'category',
      {
        title: '物料分类',
        dataIndex: 'category',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() => _.get(getMaterialInfo(field.name, 'category'), 'name')}
            </Form.Item>
          );
        },
      },
    ],
    [
      'specification',
      {
        title: '物料规格',
        dataIndex: 'specification',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() => (
                <TextToolTip
                  text={_.get(getMaterialInfo(field.name, 'baseInfo'), 'specification')}
                  width={130}
                />
              )}
            </Form.Item>
          );
        },
      },
    ],
    [
      'inputAmountNumerator',
      {
        title: (
          <BatchOperateTableHeader
            titleText="用量:分子"
            required
            rules={[
              { required: true, message: '用量:分子不能为空' },
              {
                validator: numberMinMaxCheck({
                  min: 0,
                  minAllowEqual: !!useType, // 非物料清单的用量:分子可为0
                  max: 10000000,
                  fieldName: '用量:分子',
                }),
              },
            ]}
            popoverFieldComponent={<InputNumber style={{ width: '100%' }} min={0} max={10000000} />}
            batchOperation={(value) =>
              batchSetItemValues([
                { fieldStr: 'inputAmountNumerator', value },
                {
                  iterationFn: (item) =>
                    setRowValueToFeedingControl(item, [
                      { fieldStr: 'inputAmountNumerator', value },
                    ]),
                },
              ])
            }
          />
        ),
        dataIndex: 'inputAmountNumerator',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item noStyle dependencies={[[name, field.name, 'unitId']]}>
              {() => {
                const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
                  getMaterialInfo(field.name),
                  form.getFieldValue([name, field.name, 'unitId']),
                );

                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'inputAmountNumerator']}
                    name={[field.name, 'inputAmountNumerator']}
                    initialValue={getDefaultValue(field, 'inputAmountNumerator', 1)}
                    rules={_.compact([
                      { required: true, message: '用量:分子不能为空' },
                      {
                        validator: numberMinMaxCheck({
                          min: 0,
                          minAllowEqual: !!useType, // 非物料清单的用量:分子可为0
                          max: 10000000,
                          fieldName: '用量:分子',
                        }),
                      },
                      // { validator: validatorInputAmountNumerator(field) },
                      enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
                    ])}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder="请输入"
                      min={0}
                      max={10000000}
                      onChange={(value) => {
                        form.setFieldsValue({
                          [name]: setRowValueChange(
                            field.name,
                            copyRows(),
                            [
                              {
                                iterationFn: (item) =>
                                  setRowValueToFeedingControl(item, [
                                    { fieldStr: 'inputAmountNumerator', value },
                                  ]),
                              },
                            ],
                            false,
                          ),
                        });
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ],
    [
      'inputAmountDenominator',
      {
        title: (
          <BatchOperateTableHeader
            titleText="用量:分母"
            required
            rules={[
              { required: true, message: '用量:分母不能为空' },
              {
                validator: numberMinMaxCheck({
                  min: 0,
                  minAllowEqual: false,
                  max: 10000000,
                  fieldName: '用量:分母',
                }),
              },
            ]}
            popoverFieldComponent={<InputNumber style={{ width: '100%' }} min={0} max={10000000} />}
            batchOperation={(value) =>
              batchSetItemValues([
                { fieldStr: 'inputAmountDenominator', value },
                {
                  iterationFn: (item) =>
                    setRowValueToFeedingControl(item, [
                      { fieldStr: 'inputAmountDenominator', value },
                    ]),
                },
              ])
            }
          />
        ),
        dataIndex: 'inputAmountDenominator',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item noStyle dependencies={[[name, field.name, 'unitId']]}>
              {() => {
                const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
                  getMaterialInfo(field.name),
                  form.getFieldValue([name, field.name, 'unitId']),
                );

                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'inputAmountDenominator']}
                    name={[field.name, 'inputAmountDenominator']}
                    initialValue={getDefaultValue(field, 'inputAmountDenominator', 1)}
                    rules={_.compact([
                      { required: true, message: '用量:分母不能为空' },
                      {
                        validator: numberMinMaxCheck({
                          min: 0,
                          minAllowEqual: false,
                          max: 10000000,
                          fieldName: '用量:分母',
                        }),
                      },
                      enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
                    ])}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder="请输入"
                      min={0}
                      max={10000000}
                      disabled={getFieldValue([name, field.name, 'isChildren'])}
                      onChange={(value) => {
                        form.setFieldsValue({
                          [name]: setRowValueChange(field.name, copyRows(), [
                            {
                              iterationFn: (item) =>
                                setRowValueToFeedingControl(item, [
                                  { fieldStr: 'inputAmountDenominator', value },
                                ]),
                            },
                            { fieldStr: 'inputAmountDenominator', value },
                          ]),
                        });
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ],
    [
      'unitId',
      {
        title: '单位',
        dataIndex: 'unitId',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item noStyle dependencies={[[name, field.name, 'materialId']]}>
              {() => {
                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'unitId']}
                    name={[field.name, 'unitId']}
                    rules={[
                      { validator: validatorUnit(field.name) },
                      { required: true, message: '单位不能为空' },
                    ]}
                  >
                    <Select
                      options={getMaterialUnits(getMaterialInfo(field.name))}
                      disabled={getFieldValue([name, field.name, 'isChildren'])}
                      onChange={(value: any) => {
                        form.setFieldsValue({
                          [name]: setRowValueChange(field.name, copyRows(), [
                            { value, fieldStr: 'unitId' },
                          ]),
                        });
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ],
    [
      'lossRate',
      {
        title: (
          <BatchOperateTableHeader
            titleText="损耗率%"
            popoverFieldComponent={
              <InputNumber style={{ width: '100%' }} max={99.9999} min={0} precision={4} />
            }
            batchOperation={(value) => batchSetItemValues([{ value, fieldStr: 'lossRate' }])}
          />
        ),
        dataIndex: 'lossRate',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'lossRate']}
              name={[field.name, 'lossRate']}
              initialValue={getDefaultValue(field, 'lossRate', 0)}
            >
              <InputNumber
                style={{ width: '100%' }}
                max={99.9999}
                min={0}
                precision={4}
                disabled={getFieldValue([name, field.name, 'isChildren'])}
                onChange={(value) => {
                  form.setFieldsValue({
                    [name]: setRowValueChange(field.name, copyRows(), [
                      { value, fieldStr: 'lossRate' },
                    ]),
                  });
                }}
              />
            </Form.Item>
          );
        },
      },
    ],
    [
      'version',
      {
        title: '版本号',
        dataIndex: 'version',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item noStyle dependencies={[[name, field.name, 'materialId']]}>
              {() => {
                const materialInfo = getMaterialInfo(
                  field.name,
                  undefined,
                  getFieldValue([name, field.name, 'materialId']),
                );

                const baseSelectProps: any = {
                  style: { width: '100%' },
                  placeholder: '请选择',
                  fetchType: 'bomVersions',
                  disabled: getFieldValue([name, field.name, 'isChildren']),
                  params: {
                    materialIds: [_.get(materialInfo, ['baseInfo', 'id'])],
                  },
                  onChange: (value: any) => {
                    form.setFieldsValue({
                      [name]: setRowValueChange(field.name, copyRows(), [
                        { value, fieldStr: 'version' },
                      ]),
                    });
                  },
                };

                if (useType) {
                  baseSelectProps.params = {
                    active: appEnum.Common.UsageStatus.enabled,
                    materialIds: [_.get(materialInfo, ['baseInfo', 'id'])],
                  };
                }

                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'version']}
                    name={[field.name, 'version']}
                  >
                    <SearchSelect {...baseSelectProps} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ],
    [
      'pickMode',
      {
        title: (
          <BatchOperateTableHeader
            titleText="领料方式"
            popoverFieldComponent={<Select options={appDict.bom.pickMode} />}
            batchOperation={(value) =>
              batchSetItemValues([
                {
                  fieldStr: 'pickMode',
                  iterationFn: (item, index) => {
                    const materialInfo = getMaterialInfo(index, undefined, item.materialId);
                    const isVirtualMaterial = judgeMaterialIsVirtual(materialInfo);

                    return isVirtualMaterial ? getDefaultPickModeWithMaterial(materialInfo) : value;
                  },
                },
              ])
            }
          />
        ),
        dataIndex: 'pickMode',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item noStyle dependencies={[[name, field.name, 'materialId']]}>
              {() => {
                const materialInfo = getMaterialInfo(
                  field.name,
                  undefined,
                  getFieldValue([name, field.name, 'materialId']),
                );

                const isVirtualMaterial = judgeMaterialIsVirtual(materialInfo);

                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'pickMode']}
                    name={[field.name, 'pickMode']}
                    initialValue={getDefaultValue(
                      field,
                      'pickMode',
                      appEnum.Bom.PickMode.demandPick,
                    )}
                  >
                    <Select
                      style={{ width: '100%' }}
                      placeholder="请输入"
                      options={appDict.bom.pickMode}
                      disabled={
                        isVirtualMaterial || getFieldValue([name, field.name, 'isChildren'])
                      }
                      onChange={(value: any) => {
                        form.setFieldsValue({
                          [name]: setRowValueChange(field.name, copyRows(), [
                            { value, fieldStr: 'pickMode' },
                          ]),
                        });
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ],
    [
      'specificProcessInput',
      {
        title: '指定投料工序',
        dataIndex: 'specificProcessInput',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'specificProcessInput']}
              name={[field.name, 'specificProcessInput']}
              initialValue={getDefaultValue(field, 'specificProcessInput', appEnum.Common.YN.yes)}
            >
              <Radio.Group
                options={appDict.common.yn}
                disabled={getFieldValue([name, field.name, 'isChildren'])}
                onChange={(e) => {
                  form.setFieldsValue({
                    [name]: changeSpecificProcessInputValue(e.target.value, field.name, copyRows()),
                  });
                }}
              />
            </Form.Item>
          );
        },
      },
    ],
    [
      'inputProcessId',
      {
        title: (
          <Form.Item noStyle dependencies={[dependenciesFieldPath.processRoute]}>
            {() => {
              const { value: processRouteId } =
                form.getFieldValue(dependenciesFieldPath.processRoute) ?? {};

              return (
                <BatchOperateTableHeader
                  titleText="投料工序号"
                  popoverFieldComponent={
                    <SearchSelect
                      style={{ width: '100%' }}
                      fetchType="processOfprocessRouting"
                      params={{ id: processRouteId }}
                      labelInValue
                      disabled={!processRouteId}
                    />
                  }
                  batchOperation={(value) => {
                    batchSetItemValues([
                      {
                        iterationFn: (item, index) => {
                          /** 当前投料工序号可编辑且不等于批量设置的值时 */
                          if (
                            !checkInputProcessIdDisabled(index) &&
                            !_.isEqual(value, item.inputProcessId)
                          ) {
                            return changeInputProcessIdValue(value, item);
                          }
                          return item;
                        },
                      },
                    ]);
                  }}
                />
              );
            }}
          </Form.Item>
        ),
        dataIndex: 'inputProcessId',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item
              noStyle
              dependencies={[
                dependenciesFieldPath.processRoute,
                [name, field.name, 'materialId'],
                [name, field.name, 'specificProcessInput'],
                [name, field.name, 'splitProcessInput'],
              ]}
            >
              {() => {
                const { value: processRouteId } =
                  form.getFieldValue(dependenciesFieldPath.processRoute) ?? {};

                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'inputProcessId']}
                    name={[field.name, 'inputProcessId']}
                  >
                    <SearchSelect
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      fetchType="processOfprocessRouting"
                      params={{ id: processRouteId }}
                      labelInValue
                      disabled={checkInputProcessIdDisabled(field.name)}
                      onChange={(value: any) => {
                        form.setFields([
                          {
                            name: [name, field.name],
                            value: changeInputProcessIdValue(value, copyRows(field.name)[0]),
                          },
                        ]);
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ],
    [
      'bomFeedingControls',
      {
        title: '投料管控',
        dataIndex: 'bomFeedingControls',
        width: 120,
        render: (text, field) => {
          return (
            <Form.Item noStyle dependencies={[[name, field.name, 'splitProcessInput']]}>
              {() => {
                // 是否拆工序到子行
                const isSplitProcessInput = getFieldValue([name, field.name, 'splitProcessInput']);

                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'bomFeedingControls']}
                    name={[field.name, 'bomFeedingControls']}
                    initialValue={getDefaultValue(
                      field,
                      'splitProcessInput',
                      getDefaultFeedingControls(),
                    )}
                  >
                    <Button
                      type="link"
                      style={{ padding: 0 }}
                      onClick={() => {
                        setMaterialFeedingControl(field);
                      }}
                      disabled={isSplitProcessInput}
                    >
                      设置
                    </Button>
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ],
    [
      'splitProcessInput',
      {
        title: '拆分工序投料',
        dataIndex: 'splitProcessInput',
        width: 140,
        render: (text, field) => {
          const isChildren = form.getFieldValue([name, field.name, 'isChildren']);

          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'splitProcessInput']}
              name={[field.name, 'splitProcessInput']}
              initialValue={getDefaultValue(field, 'splitProcessInput', appEnum.Common.YN.no)}
            >
              <Input hidden />
              <span>
                {isChildren
                  ? undefined
                  : lookup(
                      'common.yn',
                      form.getFieldValue([name, field.name, 'splitProcessInput']),
                    )}
              </span>
            </Form.Item>
          );
        },
      },
    ],
    [
      'splitSopControlInput',
      {
        title: '拆分控件投料',
        dataIndex: 'splitSopControlInput',
        width: 140,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'splitSopControlInput']}
              name={[field.name, 'splitSopControlInput']}
              initialValue={getDefaultValue(field, 'splitSopControlInput', appEnum.Common.YN.no)}
            >
              <Select
                style={{ padding: 0 }}
                options={appDict.common.yn}
                disabled
                bordered={false}
                showArrow={false}
              />
            </Form.Item>
          );
        },
      },
    ],
    [
      'remark',
      {
        title: '行备注',
        dataIndex: 'remark',
        width: 300,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'remark']}
              name={[field.name, 'remark']}
            >
              <Input.TextArea
                style={{ width: '100%' }}
                maxLength={1000}
                placeholder="请输入"
                autoSize
              />
            </Form.Item>
          );
        },
      },
    ],
    [
      'isChildren',
      {
        title: '',
        dataIndex: 'isChildren',
        width: 1,
        render: (_text, field) => {
          return (
            <Form.Item
              hidden
              initialValue={getDefaultValue(field, 'isChildren', false)}
              name={[field.name, 'isChildren']}
              fieldKey={[field.fieldKey, 'isChildren']}
              style={{ marginBottom: '0' }}
            >
              <Input />
            </Form.Item>
          );
        },
      },
    ],
    [
      'id',
      {
        title: '',
        dataIndex: 'id',
        width: 1,
        render: (text, field) => {
          return (
            <Form.Item
              hidden
              preserve={false}
              name={[field.name, 'id']}
              fieldKey={[field.fieldKey, 'id']}
              style={{ marginBottom: '0' }}
            >
              <Input />
            </Form.Item>
          );
        },
      },
    ],
    [
      'operation',
      {
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width: !useType ? 150 : 80,
        render: (text, field) => {
          return (
            <Form.Item
              dependencies={[
                [name, field.name, 'specificProcessInput'],
                [name, field.name, 'materialId'],
              ]}
              noStyle
            >
              {() => {
                const { specificProcessInput, materialId, isChildren } =
                  form.getFieldValue([name, field.name]) ?? {};
                const materialInfo = getMaterialInfo(field.name, undefined, materialId);

                const isVirtualMaterial = judgeMaterialIsVirtual(materialInfo);

                return (
                  <Space>
                    <Button
                      style={{ padding: 0 }}
                      type="link"
                      onClick={() => {
                        let formList = [];

                        if (isChildren) {
                          formList = copyRowChildren(field.name, copyRows());
                        } else {
                          formList = copyRow(field.name, copyRows());
                        }

                        form.setFieldsValue({
                          [name]: formList,
                        });
                      }}
                    >
                      复制
                    </Button>
                    {!useType && !isChildren && (
                      <Button
                        style={{ padding: 0 }}
                        type="link"
                        disabled={!specificProcessInput || isVirtualMaterial}
                        onClick={() => {
                          form.setFieldsValue({
                            [name]: addChildren(field.name, copyRows()),
                          });
                        }}
                      >
                        添加子行
                      </Button>
                    )}
                  </Space>
                );
              }}
            </Form.Item>
          );
        },
      },
    ],
  ]);

  const getColumns = (): ColumnProps<FormListFieldData>[] => {
    const baseColumns: ColumnProps<any & FormListFieldData>[] = [];
    const mustColumnKey = ['isChildren', 'operation', 'id'];

    if (!_.isEmpty(resetColumns)) {
      // 默认必须有的列

      _.forEach(resetColumns, (column) => {
        if (_.has(column, 'render')) {
          baseColumns.push(column);
        } else {
          const dataIndex = _.get(column, 'dataIndex', '') as string;
          const baseCol = baseColumnsMap.get(dataIndex);

          if (!_.isEmpty(baseCol) && baseCol) {
            baseColumns.push(baseCol);
          }
        }
      });

      _.forEach(mustColumnKey, (key) => {
        const mustCol = baseColumnsMap.get(key);

        if (!_.isEmpty(mustCol) && mustCol) {
          baseColumns.push(mustCol);
        }
      });
    } else {
      baseColumns.push(...Array.from(baseColumnsMap.values()));
    }

    return baseColumns;
  };

  return (
    <>
      <BlSortFormList
        form={form}
        name={name}
        renderColumns={() =>
          injectCustomFieldColumns({
            columns: getColumns(), // 原本的列
            customFields: bomCustomFields, // 自定义字段信息
            objectCode: OBJECT_OF_CODE.SubItemMaterialChild,
            type: 'form', // 使用类型
            hiddenRender: (text, field) => {
              const isChildren = form.getFieldValue([name, field.name, 'isChildren']);

              // 是子项且存在子行的时候，当前子项不展示自定义字段
              if (!isChildren) {
                const hasChildren = juedgeRowHasChildren(field.name, form.getFieldValue([name]));

                return hasChildren;
              }

              return false;
            },
            formConfig: {
              form,
              formName: name,
            },
          })
        }
        isNeedDrag={false}
        maxCount={100}
        onDelete={(index) => {
          if (index.length === 1) {
            const newFormList = deleteRow(index[0], copyRows());

            // 用异步到下次重新给form赋值，以免和formList自带的delete事件冲突，导致form数据错乱,丢失数据
            setTimeout(() => {
              form.setFields([
                {
                  name,
                  value: newFormList,
                },
              ]);
            });
          }
        }}
      />
      <DataFormLayoutForModal
        visible={!_.isEmpty(materialFeedingControl)}
        onClose={() => {
          setMaterialFeedingControl(undefined);
        }}
        content={
          <FeedingControlForm
            type={type}
            useType={useType}
            dependenciesFieldPath={dependenciesFieldPath}
            bomForm={form}
            name="bomFeedingControls"
            params={
              materialFeedingControl && _.get(getFieldValue([name]), materialFeedingControl?.name)
            }
            onChange={handleFeedingControlChange}
          />
        }
      />
    </>
  );
};
