import React, { useEffect, useState } from 'react';
import { match, RouteComponentProps, useHistory } from 'react-router-dom';
import { Space, Popover, Badge, Form } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import { LOCK_STATUS, LOCK_STATUS_DISPLAY_ENUM, QUALITY_ENUM, STATUS_ENUM } from '../constant';
import { BlIcon, QrCode, SingleOperationLogComponent } from 'components';
import { isNull } from 'lodash';
import { replaceSign } from 'src/utils/constants';
import {
  fetchStorageLocationBulkDelete,
  fetchStorageLocationBulkDisable,
  fetchStorageLocationBulkEnable,
  fetchStorageLocationBulkLock,
  fetchStorageLocationBulkUnlock,
  fetchStorageLocationInfo,
} from 'src/api/ytt/storage-domain/position';
import { toPositionEdit, toPositionList } from '../navigation';
import { patchRequest } from '../utils';
import PositionsEdit from '../positions/edit';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';

interface DetailBaseInfoProps {
  positionId: number;
  refreshTable?: () => void;
  type: string;
}

const PositionDetailInfo = (props: DetailBaseInfoProps) => {
  const { positionId, refreshTable, type } = props;
  const [editVisible, setEditVisible] = useState(false); // 显示编辑
  const [dataSource, setDataSource] = useState({} as any);
  const [lockState, setLockState] = useState<number>(0);
  const [storeState, setStoreState] = useState<number>(0);
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录

  const history = useHistory();

  const fetchData = async () => {
    try {
      const { data: detailData } = await fetchStorageLocationInfo(
        { id: positionId },
        { legacy: true },
      );

      if (detailData) {
        setDataSource(detailData);
        setLockState(detailData?.isLock);
        setStoreState(detailData?.enableFlag);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const useStatusMap = new Map([
    [STATUS_ENUM.ENABLED, <Badge text="启用中" status="success" />],
    [STATUS_ENUM.DISABLED, <Badge text="停用" status="error" />],
  ]);

  // const [delForm] = Form.useForm();

  const jsonId = [positionId];

  // const handleFinish = async () => {
  //   try {
  //     const { code, message } = await fetchStorageLocationBulkDelete({ id: jsonId });

  //     if (code === 200) {
  //       Message.success('删除成功');
  //       closeDetail();
  //       return;
  //     }
  //     Message.error(message);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const baseMenu = [
    {
      key: 'enable',
      auth: authDict.storagelocation_enable_disable,
      onClick: async () => {
        const updateApi =
          storeState === STATUS_ENUM?.ENABLED
            ? fetchStorageLocationBulkDisable
            : fetchStorageLocationBulkEnable;
        const updateDisplay = storeState ? '停用成功' : '启用成功';

        patchRequest('single', updateApi, jsonId, updateDisplay, () => {
          fetchData();
          typeof refreshTable === 'function' && refreshTable?.();
        });
      },
      icon:
        storeState === STATUS_ENUM?.ENABLED ? (
          <BlIcon type="icontingyong" />
        ) : (
          <BlIcon type="iconqiyong" />
        ),
      title: storeState ? '停用' : '启用',
    },
    {
      key: 'lock',
      auth: authDict.storagelocation_lock_unlock,
      onClick: async () => {
        const lockApi =
          lockState === LOCK_STATUS_DISPLAY_ENUM?.LOCK
            ? fetchStorageLocationBulkUnlock
            : fetchStorageLocationBulkLock;

        const lockDisplay = lockState === LOCK_STATUS_DISPLAY_ENUM?.LOCK ? '解锁成功' : '锁定成功';

        patchRequest('single', lockApi, jsonId, lockDisplay, () => {
          fetchData();
          typeof refreshTable === 'function' && refreshTable?.();
        });
      },
      title: lockState ? '解锁' : '锁定',
    },
    // {
    //   key: 'delete',
    //   onClick: () => {
    //     BLDelConfirm('是否删除当前仓位', delForm, handleFinish);
    //   },
    //   title: '删除',
    // },
    {
      key: 'record',
      auth: authDict.storagelocation_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'edit',
      auth: authDict.storagelocation_edit,
      onClick: () => {
        setEditVisible(true);
      },
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ];

  const detailInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      { label: '仓位名称', dataIndex: 'name', isFullLine: type === 'drawer' },
      { label: '仓位编号', dataIndex: 'code', isFullLine: type === 'drawer' },
      {
        label: '标识码',
        dataIndex: 'qrCode',
        isFullLine: type === 'drawer',
        render: (qrCode: string) => {
          return (
            <div>
              <Space style={{ marginRight: '5px' }}>
                {qrCode ?? dataSource?.code ?? replaceSign}
              </Space>
              <Space>
                <Popover
                  placement="rightTop"
                  content={
                    <div>
                      <QrCode value={qrCode ?? dataSource?.code ?? ''} />
                      <div style={{ textAlign: 'center' }}>{qrCode ?? dataSource?.code ?? ''}</div>
                    </div>
                  }
                >
                  <BlIcon type="iconerweima" />
                </Popover>
              </Space>
            </div>
          );
        },
      },
      {
        label: '仓位状态',
        dataIndex: 'enableFlag',
        isFullLine: type === 'drawer',
        render: (enableFlag: number) => useStatusMap.get(enableFlag),
      },
      {
        label: '锁定状态',
        dataIndex: 'isLock',
        isFullLine: type === 'drawer',
        render: (isLock: number) => <span>{LOCK_STATUS.get(isLock) || replaceSign}</span>,
      },
      { label: '仓库名称', dataIndex: 'warehouseName', isFullLine: type === 'drawer' },
      { label: '仓库编号', dataIndex: 'warehouseCode', isFullLine: type === 'drawer' },
      { label: '上级区域名称', dataIndex: 'parentName', isFullLine: type === 'drawer' },
      { label: '上级区域编号', dataIndex: 'parentCode', isFullLine: type === 'drawer' },
      {
        label: '质量管理',
        dataIndex: 'qcCtrlList',
        isFullLine: type === 'drawer',
        render: (qcCtrlList: number[], { enableQcCtrl }) => {
          if (enableQcCtrl === STATUS_ENUM.DISABLED) return '无限制';
          if (isNull(qcCtrlList)) return replaceSign;
          return (
            <span>
              {qcCtrlList?.map((node, i) => {
                if (i === qcCtrlList?.length - 1) {
                  return <span key={node}>{QUALITY_ENUM.get(node)}</span> || replaceSign;
                }
                return <span key={node}>{QUALITY_ENUM.get(node)}、</span> || replaceSign;
              })}
            </span>
          );
        },
      },
    ],
    column: 2,
  };

  const infoArray = [detailInfo];

  return (
    <>
      <DetailLayout
        userAuth={getAuthFromLocalStorage()}
        title="仓位详情"
        baseMenu={baseMenu}
        info={infoArray}
        dataSource={dataSource}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={positionId}
          objectCode={'StorageLocation'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      {editVisible && (
        <PositionsEdit
          visible={editVisible}
          positionId={positionId}
          onCancel={() => {
            setEditVisible(false);
          }}
          refreshDetails={fetchData}
          refreshTable={refreshTable}
        />
      )}
    </>
  );
};

export default PositionDetailInfo;
