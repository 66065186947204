/**
 * 工序连接
 */
import { Input, Radio, Form, Select, InputNumber, FormInstance, Tooltip } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { UsageStatus, YN } from 'src/dict/common';
import { yn } from 'src/dict/common/mappings';
import { appDict, appEnum } from 'src/dict';
import { SearchSelect } from 'components';
import { memo } from 'react';
import {
  getMaterialUnits,
  getMaterialProduceUnit,
  getMaterialUnitInfo,
  getMaterialFeedUnit,
} from 'src/entity/material';
import _, { isEmpty, isNumber } from 'lodash';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import { ControlType } from 'src/dict/sop';
import { FeedingType } from 'src/dict/material';
import { InputTypeEnumDisplayMap } from 'src/dict/planned/mappings';

interface Props {
  form: FormInstance;
  name: string;
  isWorkOrder?: Boolean;
  processName: string;
  nextName: string;
  index: number;
  useType: number;
  isPlanOrder?: Boolean;
  isEditMode: Boolean;
}
const Manufactured = 'processManufacturedGoods';
const marginBottom = 0;

const ProcessEdgeForm = memo((props: Props) => {
  const {
    name,
    processName,
    nextName,
    index,
    form,
    isWorkOrder = false,
    useType = 0,
    isPlanOrder = false,
    isEditMode,
  } = props;

  const getMaterialInfo = () => {
    const { value } =
      form.getFieldValue([name, nextName, index, Manufactured, 'materialCode']) || {};

    return value ? JSON.parse(value) : {};
  };
  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    title: '工序接序',
    items: [
      {
        label: '前工序号',
        dependencies: [name, processName, index, 'processNum'],
        render: () => () => {
          const processNum = form.getFieldValue([name, processName, index, 'processNum']);

          return (
            <Form.Item
              style={{ marginBottom }}
              initialValue={processNum}
              name={[name, nextName, index, 'prevProcessNum']}
            >
              <Input disabled />
            </Form.Item>
          );
        },
      },
      {
        label: '前工序ID',
        hidden: true,
        dependencies: [name, processName, index, 'processId'],
        render: () => () => {
          const processId = form.getFieldValue([name, processName, index, 'processId']);

          return (
            <Form.Item
              style={{ marginBottom }}
              initialValue={processId}
              name={[name, nextName, index, 'prevProcessId']}
            >
              <Input disabled />
            </Form.Item>
          );
        },
      },
      {
        label: '前工序名称',
        dependencies: [name, processName, index, 'processName'],
        render: () => () => {
          const process = form.getFieldValue([name, processName, index, 'processName']);

          return (
            <Form.Item
              style={{ marginBottom }}
              initialValue={process}
              name={[name, nextName, index, 'prevProcessName']}
            >
              <Input disabled />
            </Form.Item>
          );
        },
      },
      {
        label: '后工序号',
        dependencies: [name, processName, index + 1, 'processNum'],
        render: () => () => {
          const processNum = form.getFieldValue([name, processName, index + 1, 'processNum']);

          return (
            <Form.Item
              style={{ marginBottom }}
              initialValue={processNum}
              name={[name, nextName, index, 'nextProcessNum']}
            >
              <Input disabled />
            </Form.Item>
          );
        },
      },
      {
        label: '后工序ID',
        hidden: true,
        dependencies: [name, processName, index + 1, 'processId'],
        render: () => () => {
          const processId = form.getFieldValue([name, processName, index + 1, 'processId']);

          return (
            <Form.Item
              style={{ marginBottom }}
              initialValue={processId}
              name={[name, nextName, index, 'nextProcessId']}
            >
              <Input disabled />
            </Form.Item>
          );
        },
      },
      {
        label: '后工序名称',
        dependencies: [name, processName, index + 1, 'processName'],
        render: () => () => {
          const process = form.getFieldValue([name, processName, index + 1, 'processName']);

          return (
            <Form.Item
              style={{ marginBottom }}
              initialValue={process}
              name={[name, nextName, index, 'nextProcessName']}
            >
              <Input disabled />
            </Form.Item>
          );
        },
      },
    ],
  };

  const producingInfo: DataFormLayoutInfoBlock = {
    column: 1,
    title: '在制品',
    items: _.compact([
      {
        label: '物料编号',
        name: [name, nextName, index, Manufactured, 'materialCode'],
        render: () => (
          <SearchSelect
            labelInValue
            params={{
              enableFlag: UsageStatus.enabled,
              bizType: [appEnum.Material.BizType.workInProgress],
            }}
            fetchType="materialCompleteInfo"
            onChange={(item: any) => {
              const valueJSON = item?.value ? JSON.parse(item.value) : {};
              const produceUnit = getMaterialProduceUnit(valueJSON);
              const feedUnit = getMaterialFeedUnit(valueJSON);

              form.setFields([
                {
                  name: [name, nextName, index, Manufactured, 'materialName'],
                  value: valueJSON.baseInfo?.name,
                },
                {
                  name: [name, nextName, index, Manufactured, 'materialId'],
                  value: valueJSON.baseInfo?.id,
                },
                {
                  name: [name, nextName, index, Manufactured, 'feedingQualityStatus'],
                  value: valueJSON?.feedInfo?.qcStatus ?? undefined,
                },
                {
                  name: [name, nextName, index, Manufactured, 'unitId'],
                  value: produceUnit,
                },
                {
                  name: [name, nextName, index, Manufactured, 'material'],
                  value: valueJSON,
                },
                {
                  name: [name, nextName, index, Manufactured, 'feedUnitId'],
                  value: feedUnit,
                },
              ]);
            }}
          />
        ),
      },
      {
        label: '物料ID',
        hidden: true,
        name: [name, nextName, index, Manufactured, 'materialId'],
        render: () => <Input disabled />,
      },
      {
        label: '物料名称',
        name: [name, nextName, index, Manufactured, 'materialName'],
        render: () => <Input disabled />,
      },
      {
        label: isWorkOrder ? '基准数' : '数量',
        initialValue: 1,
        dependencies: [
          [name, nextName, index, Manufactured, 'materialCode'],
          [name, nextName, index, Manufactured, 'unitId'],
        ],
        render: () => () => {
          const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
            getMaterialInfo(),
            form.getFieldValue([name, nextName, index, Manufactured, 'unitId'])?.value,
          );

          const figure = enablePrecision && _.isNumber(precisionFigure) ? precisionFigure : 10;

          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              rules={[
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    isRequired: false,
                    minAllowEqual: false,
                    maxAllowEqual: false,
                    max: 10000000,
                    fieldName: '数量',
                  }),
                },
                { validator: fractionLengthCheck(figure) },
              ]}
              name={[
                name,
                nextName,
                index,
                Manufactured,
                isWorkOrder ? 'standardAmount' : 'amount',
              ]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          );
        },
      },
      {
        label: '自制单位',
        dependencies: [[name, nextName, index, Manufactured, 'materialCode']],
        render: () => () => {
          const material = getMaterialInfo();

          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={[name, nextName, index, Manufactured, 'unitId']}
            >
              {isEmpty(material) ? (
                <SearchSelect
                  labelInValue
                  placeholder={'请选择'}
                  fetchType="unit"
                  params={{ enableFlag: UsageStatus.enabled }}
                />
              ) : (
                <Select labelInValue placeholder={'请选择'} options={getMaterialUnits(material)} />
              )}
            </Form.Item>
          );
        },
      },
      {
        label: '投料单位',
        dependencies: [[name, nextName, index, Manufactured, 'materialCode']],
        render: () => () => {
          const material = getMaterialInfo();

          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={[name, nextName, index, Manufactured, 'feedUnitId']}
            >
              <Select
                labelInValue
                placeholder={'请选择'}
                options={getMaterialUnits(material)}
                disabled={isEmpty(material)}
              />
            </Form.Item>
          );
        },
      },
      isWorkOrder && {
        label: '计划生产数',
        render: () => {
          return (
            <Form.Item
              dependencies={[
                ['bomOutputMaterials', 0, 'plannedAmount'],
                [name, nextName, index, Manufactured, 'standardAmount'],
              ]}
              noStyle
            >
              {() => {
                /** 主产出计划生产数*基准数量 */

                /** 计划生产数 */
                const mainPlannedAmount =
                  form.getFieldValue(['bomOutputMaterials', 0, 'plannedAmount']) || 1;
                /** 在制品基准数 */
                const standardAmount =
                  form.getFieldValue([name, nextName, index, Manufactured, 'standardAmount']) || 1;
                let plannedProduction: any;

                if (isNumber(Number(mainPlannedAmount)) && isNumber(Number(standardAmount))) {
                  plannedProduction = _.multiply(mainPlannedAmount, standardAmount);
                }

                form.setFields([
                  {
                    name: [name, nextName, index, Manufactured, 'plannedProduction'],
                    value: plannedProduction ? _.ceil(plannedProduction, 6) : undefined,
                  },
                ]);

                return (
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name={[name, nextName, index, Manufactured, 'plannedProduction']}
                  >
                    <Input disabled />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        label: '是否报工',
        name: [name, nextName, index, Manufactured, 'reportFlag'],
        initialValue: YN.yes,
        render: () => (
          <Radio.Group
            onChange={(val) => {
              if (!val?.target?.value) {
                form.setFields([
                  {
                    name: [name, nextName, index, Manufactured, 'reportingMethods'],
                    value: undefined,
                  },
                  {
                    name: [name, nextName, index, Manufactured, 'autoWarehousingFlag'],
                    value: null,
                  },
                  {
                    name: [name, nextName, index, Manufactured, 'warehousing'],
                    value: null,
                  },
                ]);
              }
            }}
          >
            {yn.map(({ label, value }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        ),
      },
      {
        label: '报工方式',
        dependencies: [[name, nextName, index, Manufactured, 'reportFlag']],
        render: () => () => {
          const disableFlag = form.getFieldValue([
            name,
            nextName,
            index,
            Manufactured,
            'reportFlag',
          ]);

          return (
            <Form.Item
              // label="投料质量状态"
              style={{ marginBottom: 0 }}
              name={[name, nextName, index, Manufactured, 'reportingMethods']}
              initialValue={[1, 2, 3, 4]}
            >
              <Select
                options={appDict.processRoute.reportingMethods}
                mode="multiple"
                placeholder="请选择"
                disabled={!disableFlag}
              />
            </Form.Item>
          );
        },
      },
      {
        label: '报工控件编号',
        dependencies: [
          [name, nextName, index, Manufactured, 'reportFlag'],
          [name, processName, index, 'sop'],
        ],

        render: () => () => {
          // 前工序的sop
          const sop = form.getFieldValue([name, processName, index, 'sop']);

          const disableFlag = form.getFieldValue([
            name,
            nextName,
            index,
            Manufactured,
            'reportFlag',
          ]);

          // 工单sop快照id
          const sopSnapshotId = form.getFieldValue([name, processName, index, 'sopSnapshotId']);

          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={[name, nextName, index, Manufactured, 'workReportControl']}
            >
              <SearchSelect
                params={{
                  snapshotFlag: Boolean(sopSnapshotId),
                  id: sopSnapshotId ?? sop?.value,
                  controlType: ControlType.report,
                }}
                labelPath="code"
                valuePath={isWorkOrder ? 'id' : 'code'}
                disabled={!sop && !disableFlag}
                fetchType="sopBizControl"
              />
            </Form.Item>
          );
        },
      },
      {
        label: '单次报工数量',
        dependencies: [
          [name, nextName, index, Manufactured, 'materialCode'],
          [name, nextName, index, Manufactured, 'unitId'],
          [name, nextName, index, Manufactured, 'reportFlag'],
        ],
        render: () => () => {
          const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
            getMaterialInfo(),
            form.getFieldValue([name, nextName, index, Manufactured, 'unitId'])?.value,
          );
          const figure = enablePrecision && _.isNumber(precisionFigure) ? precisionFigure : 10;

          const disableFlag = form.getFieldValue([
            name,
            nextName,
            index,
            Manufactured,
            'reportFlag',
          ]);

          return (
            <Form.Item
              name={[name, nextName, index, Manufactured, 'singleWorkReportNum']}
              style={{ marginBottom: 0 }}
              rules={[
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    isRequired: false,
                    minAllowEqual: false,
                    max: 10000000,
                    maxAllowEqual: false,
                    fieldName: '单次报工数量',
                  }),
                },
                { validator: fractionLengthCheck(figure) },
              ]}
            >
              <Input placeholder="请输入单次报工数量" disabled={!disableFlag} />
            </Form.Item>
          );
        },
      },
      {
        label: '是否入库',
        dependencies: [[name, nextName, index, Manufactured, 'reportFlag']],
        render: () => () => {
          const disableFlag = form.getFieldValue([
            name,
            nextName,
            index,
            Manufactured,
            'reportFlag',
          ]);

          return (
            <Form.Item
              name={[name, nextName, index, Manufactured, 'warehousing']}
              style={{ marginBottom: 0 }}
              initialValue={YN.yes}
            >
              <Radio.Group
                disabled={!disableFlag}
                onChange={(e) => {
                  if (!e.target.value) {
                    form.setFields([
                      {
                        name: [name, nextName, index, Manufactured, 'autoWarehousingFlag'],
                        value: null,
                      },
                      {
                        name: [name, nextName, index, Manufactured, 'feedType'],
                        value: null,
                      },
                    ]);
                  } else {
                    form.setFields([
                      {
                        name: [name, nextName, index, Manufactured, 'feedType'],
                        value: 0,
                      },
                    ]);
                  }
                }}
              >
                {yn.map(({ label, value }) => (
                  <Radio key={value} value={value}>
                    {label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          );
        },
      },
      {
        label: '自动入库',
        dependencies: [
          [name, nextName, index, Manufactured, 'reportFlag'],
          [name, nextName, index, Manufactured, 'warehousing'],
        ],
        render: () => () => {
          const disableFlag =
            form.getFieldValue([name, nextName, index, Manufactured, 'warehousing']) &&
            form.getFieldValue([name, nextName, index, Manufactured, 'reportFlag']);

          return (
            <Form.Item
              name={[name, nextName, index, Manufactured, 'autoWarehousingFlag']}
              style={{ marginBottom: 0 }}
              initialValue={YN.yes}
            >
              <Radio.Group disabled={!disableFlag}>
                {yn.map(({ label, value }) => (
                  <Radio key={value} value={value}>
                    {label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          );
        },
      },
      {
        label: '投料控件编号',
        dependencies: [name, processName, index + 1, 'sop'],
        render: () => () => {
          // 后工序的sop
          const sop = form.getFieldValue([name, processName, index + 1, 'sop']);

          // 工单sop快照id
          const sopSnapshotId = form.getFieldValue([name, processName, index + 1, 'sopSnapshotId']);

          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={[name, nextName, index, Manufactured, 'materialFeedControl']}
            >
              <SearchSelect
                params={{
                  snapshotFlag: Boolean(sopSnapshotId),
                  id: sopSnapshotId ?? sop?.value,
                  controlType: ControlType.feed,
                }}
                labelPath="code"
                valuePath={isWorkOrder ? 'id' : 'code'}
                disabled={!sop}
                fetchType="sopBizControl"
              />
            </Form.Item>
          );
        },
      },
      {
        label: '是否必投',
        name: [name, nextName, index, Manufactured, 'feeding'],
        initialValue: YN.yes,
        render: () => (
          <Radio.Group disabled>
            {yn.map(({ label, value }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        ),
      },

      {
        label: '投料上下限类型',
        name: [name, nextName, index, Manufactured, 'limit'],
        initialValue: 1,
        render: () => (
          <Select options={appDict.processRoute.feedingLimit} placeholder="请选择" disabled />
        ),
      },
      {
        label: '投料下限',
        // name: [name, nextName, index, Manufactured, 'lowerLimit'],
        render: () => <Input value={'null'} disabled />,
      },
      {
        label: '投料上限',
        // name: [name, nextName, index, Manufactured, 'upperLimit'],
        render: () => <Input value={'null'} disabled />,
      },
      {
        label: '投料下限比例',
        // name: [name, nextName, index, Manufactured, 'lowerLimitRatio'],
        render: () => <Input value={'null'} disabled />,
      },
      {
        label: '投料上限比例',
        // name: [name, nextName, index, Manufactured, 'upperLimitRatio'],
        render: () => <Input value={'null'} disabled />,
      },
      {
        label: '投料质量状态',
        dependencies: [[name, nextName, index, Manufactured, 'materialCode']],
        render: () => () => {
          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={[name, nextName, index, Manufactured, 'feedingQualityStatus']}
            >
              <Select
                options={appDict.material.feedingQualityStatus}
                mode="multiple"
                placeholder="请选择"
              />
            </Form.Item>
          );
        },
      },
      {
        label: '',
        dependencies: [name, nextName, index, Manufactured, 'warehousing'],
        render: () => () => {
          const warehousing = form.getFieldValue([
            name,
            nextName,
            index,
            Manufactured,
            'warehousing',
          ]);

          return (
            <div>
              <Form.Item
                label={warehousing ? '投料类型' : ''}
                hidden={!warehousing}
                name={[name, nextName, index, Manufactured, 'feedType']}
                initialValue={warehousing ? FeedingType.feeding : null}
                style={{ marginBottom: 0 }}
              >
                <Radio.Group options={appDict.material.feedingTypeMap} />
              </Form.Item>
              {!!warehousing && (
                <>
                  <div style={{ fontSize: '12px' }}>
                    【投料】：支持扫码投料，选择投料时需选择指定的库存明细进行投料；
                  </div>
                  <div style={{ fontSize: '12px' }}>
                    【投料-添加申请】：支持扫码投料，选择投料时需创建「投料申请」由系统按照规则进行自动投料，可支持不指定库存明细；
                  </div>
                </>
              )}
            </div>
          );
        },
      },
    ]),
  };

  return (
    <DataFormLayout
      getAdaptiveContainer={() =>
        document.querySelector('.process-routing-action-form-body') as HTMLDivElement
      }
      form={form}
      title=""
      footer={false}
      info={[baseInfo, producingInfo]}
    />
  );
});

export default ProcessEdgeForm;
