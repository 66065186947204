/** ********* 此文件定义的内容会逐渐迁移到 src/dict/material/index.ts ****** */
/** ********* 不推荐再使用此文件的定义 ********* */

export const MATERIALBIZRANGE_PHANTOM = 0; // 已告知 生产工单 弃用

// 存储有效期 单位
export const STORAGE_YEAR = 0;
export const STORAGE_MONTH = 1;
export const STORAGE_DAY = 2;
export const STORAGE_HOUR = 3;
export const STORAGE_MINUTE = 4;
export const storageValidityUnit = new Map([
  [STORAGE_YEAR, '年'],
  [STORAGE_MONTH, '月'],
  [STORAGE_DAY, '日'],
  [STORAGE_HOUR, '小时'],
  [STORAGE_MINUTE, '分钟'],
]);
export const storageValidityUnitOptionsList = [
  {
    label: storageValidityUnit.get(STORAGE_YEAR),
    value: STORAGE_YEAR,
  },
  {
    label: storageValidityUnit.get(STORAGE_MONTH),
    value: STORAGE_MONTH,
  },
  {
    label: storageValidityUnit.get(STORAGE_DAY),
    value: STORAGE_DAY,
  },
  {
    label: storageValidityUnit.get(STORAGE_HOUR),
    value: STORAGE_HOUR,
  },
  {
    label: storageValidityUnit.get(STORAGE_MINUTE),
    value: STORAGE_MINUTE,
  },
];

export const WAREHOUSINGATTRTYPE_SINGLINE = 0;
export const WAREHOUSINGATTRTYPE_NUM = 1;
export const WAREHOUSINGATTRTYPE_INTEGER = 2;
export const WAREHOUSINGATTRTYPE_DATE = 3;
export const WAREHOUSINGATTRTYPE_SINGLECHOSE = 4;
export const WAREHOUSINGATTRTYPE_MULTICHOICE = 5;
export const warehousingAttrType = new Map([
  [WAREHOUSINGATTRTYPE_SINGLINE, '单行文本'],
  [WAREHOUSINGATTRTYPE_NUM, '数值'],
  [WAREHOUSINGATTRTYPE_INTEGER, '整数'],
  [WAREHOUSINGATTRTYPE_DATE, '日期时间'],
  [WAREHOUSINGATTRTYPE_SINGLECHOSE, '单选'],
  [WAREHOUSINGATTRTYPE_MULTICHOICE, '多选'],
]);
export const warehousingAttrTypeOptionsList = [
  {
    label: warehousingAttrType.get(WAREHOUSINGATTRTYPE_SINGLINE),
    value: WAREHOUSINGATTRTYPE_SINGLINE,
  },
  {
    label: warehousingAttrType.get(WAREHOUSINGATTRTYPE_DATE),
    value: WAREHOUSINGATTRTYPE_DATE,
  },
  {
    label: warehousingAttrType.get(WAREHOUSINGATTRTYPE_NUM),
    value: WAREHOUSINGATTRTYPE_NUM,
  },
  {
    label: warehousingAttrType.get(WAREHOUSINGATTRTYPE_INTEGER),
    value: WAREHOUSINGATTRTYPE_INTEGER,
  },
  {
    label: warehousingAttrType.get(WAREHOUSINGATTRTYPE_SINGLECHOSE),
    value: WAREHOUSINGATTRTYPE_SINGLECHOSE,
  },
  {
    label: warehousingAttrType.get(WAREHOUSINGATTRTYPE_MULTICHOICE),
    value: WAREHOUSINGATTRTYPE_MULTICHOICE,
  },
];

/** ********* 此文件定义的内容会逐渐迁移到 src/dict/material/index.ts ****** */
/** ********* 不推荐再使用此文件的定义 ********* */
