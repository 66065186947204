// 自定义通知模板

// 提醒形式
export enum RemindType {
  // /** 提示音 */
  // prompt = 1,
  // /** 震动 */
  // vibration = 2,
  // /** 语音播报 */
  // voice = 3,
  /** 紧急通知 */
  urgent = 4,
}

//  通知订阅
export enum NoticeType {
  // /** 短信 */
  // sms = 1,
  /** 邮件 */
  mail = 2,
  /** 飞书 */
  feiShu = 3,
}

//  允许拒收
export enum AllowType {
  /** 允许拒收 */
  allow = 1,
  /** 不允许拒收 */
  unAllow = 0,
}
