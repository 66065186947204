import { Radio } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import _ from 'lodash';
import React from 'react';
import { SearchSelect } from 'src/components';
import { ObjectCategory } from 'src/dict/customField';
import { targetType } from 'src/dict/customField/mappings';

interface Props {
  form: FormInstance;
  isEdit?: boolean;
  isNeedType?: boolean;
  objectParams?: any;
}

export const referObjectItems = ({ form, isEdit, isNeedType, objectParams = {} }: Props) => {
  return _.compact([
    {
      label: '关联业务对象',
      name: 'reference',
      rules: [{ required: true, message: '请选择关联业务对象' }],
      render: () => (
        <SearchSelect
          disabled={isEdit}
          placeholder="请选择关联业务对象"
          fetchType={'bizObjectByCode'}
          params={objectParams}
          onChange={(e) => {
            form.setFieldsValue({ fieldName: e?.label });
          }}
        />
      ),
    },
    isNeedType && {
      label: '关联类型',
      name: 'targetType',
      initialValue: 1,
      rules: [{ required: true, message: '请选择关联类型' }],
      render: () => <Radio.Group options={targetType} disabled={isEdit} />,
    },
  ]);
};
