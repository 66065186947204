import React, { useEffect, useState } from 'react';
import { BlMultiCascader } from 'src/components';
import { fetchMaterialListAttribute } from 'src/api/ytt/material-domain/Web_MaterialBaseInfo';

interface Props {
  type: string;
  onChange?: (value: any) => void;
  value?: string[] | number[];
  nameLabel?: string;
  placeholder?: string;
}
const BcBlMultiCascader = (props: Props) => {
  const { type, onChange, value = [], nameLabel, placeholder = '请选择' } = props;
  const [optionsList, setOptionsList] = useState<any>([]);
  const [, setFetching] = useState(false);

  function handelOnChange(value: any, selectedOptions: any) {
    console.log(value, selectedOptions);
    onChange && onChange(value);
  }
  const handleFormatData = (
    list: any[],
    fieldNames: { label: string; value: string; children: string },
  ): any[] => {
    const data = list?.map((item: any) => {
      if (item?.leaf === 0 || item[fieldNames.children]) {
        return {
          label: item[fieldNames.label],
          value: item[fieldNames.value],
          children: handleFormatData(item[fieldNames.children], fieldNames),
        };
      }
      return {
        label: item.name,
        value: item.id,
      };
    });

    return data;
  };
  const handleFetchData = async (searchParams?: string) => {
    let selectOptionsList: any[] = [];

    switch (type) {
      case 'materialProperty':
        {
          let res: any;

          if (searchParams) {
            res = await fetchMaterialListAttribute({
              page: 1,
              size: 1000,
              quickSearch: searchParams,
            });
          } else {
            res = await fetchMaterialListAttribute({ page: 1, size: 1000 });
          }

          selectOptionsList = handleFormatData(res?.data?.list, {
            label: 'name',
            value: 'id',
            children: 'children',
          });
        }
        break;

      default:
        break;
    }
    return selectOptionsList;
  };
  const getList = async (searchParams?: string) => {
    setFetching(true);
    const selectOptionsList: any[] = await handleFetchData(searchParams);

    if (selectOptionsList?.length > 0) {
      setOptionsList(selectOptionsList);
    }
    setFetching(false);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <BlMultiCascader
      placeholder={`${placeholder}${nameLabel || ''}`}
      value={value}
      options={optionsList}
      onChange={handelOnChange}
      onSearch={handleFetchData}
      onOpen={() => {
        getList();
      }}
    />
  );
};

export default BcBlMultiCascader;
