import React, { useState } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { BlIcon } from '@blacklake-web/component';
import CreateCheckItemClassification from './openCreateModal';
import _ from 'lodash';
import { fetchQcCheckItemCategoryPageList } from 'src/api/ytt/quality-domain/checkClass';
import authDict, { hasAuth } from 'src/utils/auth';

const { Option } = Select;

interface Props extends SelectProps<any> {
  type: string;
  params?: any;
  createButtonRelatedProps?: {
    needCreateButton?: boolean; // 是否需要新增的按钮
    title: string;
  };
  placeholder?: string;
  isFilterDefault?: boolean;
}
export const CREATE_BY_CHECK_ITEM = 1; //  检验项新建里的新建检验项分类

export const SearchAndCreate = (props: Props) => {
  const { type, createButtonRelatedProps, placeholder, isFilterDefault } = props;

  const [optionsList, setOptionsList] = useState<LabeledValue[]>([]);
  const [fetching, setFetching] = useState(false);
  const [createVisible, setCreateVisible] = useState(false); // 显示创建

  const getList = async (params?: any) => {
    setFetching(true);
    let selectOptionsList: LabeledValue[] = [];

    switch (type) {
      case 'CheckItemClassificationList':
        {
          // 检验项分类
          const res: any = await fetchQcCheckItemCategoryPageList({
            page: 1,
            size: 50,
            name: params,
          });

          const selectOptionsListTemp = isFilterDefault // 是否过滤默认分类
            ? res?.data?.list?.filter((defect: any) => defect?.id)
            : res?.data?.list;

          selectOptionsList = selectOptionsListTemp?.map((defect: any) => ({
            label: defect.name,
            value: defect.id,
          }));
        }
        break;

      default:
        break;
    }
    setOptionsList(selectOptionsList);
    setFetching(false);
  };

  const filterOption = (input: any, option: any) => {
    if (typeof option.children === 'string') {
      // 第一个option是添加button 所以需要区分
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
    return true;
  };

  const notFoundContent = fetching ? <Spin size="small" /> : '暂无数据';

  const onFocusSelect = () => {
    getList();
  };

  const renderCreateButton = () => {
    const { needCreateButton = false, title } = createButtonRelatedProps || {};

    if (!needCreateButton) {
      return null;
    }

    return (
      <Option value="create" key="add" disabled>
        <a
          onClick={(e) => {
            setCreateVisible(true);
            e.preventDefault();
          }}
        >
          <BlIcon type="iconxinjiantianjia" style={{ marginRight: 2 }} />
          {title}
        </a>
      </Option>
    );
  };

  const onSearch = _.debounce((val) => {
    getList(val);
  }, 500);

  return (
    <>
      <Select
        allowClear
        placeholder={placeholder ?? '请选择'}
        filterOption={filterOption}
        optionFilterProp="children"
        notFoundContent={notFoundContent}
        labelInValue
        onFocus={() => {
          onFocusSelect();
        }}
        showSearch
        onSearch={onSearch}
        {...props}
      >
        {hasAuth(authDict.inspectionitemcategory_add) && renderCreateButton()}
        {optionsList?.map((item) => (
          <Option key={item?.value} value={item?.value}>
            {item?.label}
          </Option>
        ))}
      </Select>
      {createVisible && (
        <CreateCheckItemClassification
          visible={createVisible}
          action={CREATE_BY_CHECK_ITEM}
          closeCreateButton={() => {
            setCreateVisible(false);
          }}
        />
      )}
    </>
  );
};

export default SearchAndCreate;
