/**
 * 左侧面板, 控件库
 */
import { FC, useRef, useContext } from 'react';
import { Card, Space } from 'antd';
import lookup from 'src/dict';
import { useDrag } from 'react-dnd';
import { BlIcon } from 'src/components';
import { BizType, ControlType } from 'src/dict/sop';
import { background } from 'src/components/styles/color';
// eslint-disable-next-line import/no-cycle
import {
  NEW_CONTROL_ID,
  NEW_CONTROL_INDEX,
  coverIconMap,
  basicControls,
  basicControlsForReportTem,
  bizControlsForReportTem,
  bizControls,
  bizControlsOfMaintenance,
  ControlEditorContext,
} from '../constants';
import { DragItemTypes } from '../types';

const businessBizMap = new Map([
  [BizType.production, bizControls],
  [BizType.maintenance, bizControlsOfMaintenance],
  [BizType.repair, bizControlsOfMaintenance],
]);

const CategoryCard: React.FC<{ title: string }> = ({ title, children }) => (
  <Card
    title={title}
    extra="拖拽添加"
    headStyle={{ backgroundColor: background }}
    bordered={false}
    style={{ flex: '1 0 50%', overflow: 'auto' }}
  >
    {children}
  </Card>
);

const insertTextSpacing = (text: string) => {
  if (text.length === 2) {
    return `${text[0]} ${text[1]}`;
  }
  return text;
};

/** 控件封面, 位于左侧栏里, 可拖到中间预览区插入新控件 */
export const ControlCover: FC<{ controlType: ControlType; canDrag?: boolean }> = ({
  controlType,
  canDrag = true,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drag] = useDrag(() => ({
    type: DragItemTypes.cover,
    item: () => ({
      controlType,
      index: NEW_CONTROL_INDEX,
      id: NEW_CONTROL_ID,
    }),
  }));

  drag(ref);

  const name = insertTextSpacing(lookup('sop.controlType', controlType));

  return (
    <div ref={ref} draggable={canDrag}>
      <Card
        hoverable
        bodyStyle={{ padding: '6px 12px' }}
        style={{ cursor: canDrag ? 'grab' : 'not-allowed', userSelect: 'none' }}
      >
        <Space>
          <BlIcon type={coverIconMap.get(controlType) ?? ''} />
          {name}
        </Space>
      </Card>
    </div>
  );
};

const SourceContainer: FC<{
  validate: () => Promise<boolean>;
  hasError: boolean;
  bizType: BizType;
}> = ({ validate, hasError, bizType }) => {
  const {
    sopBasicInfo: { isReportTemplate },
  } = useContext(ControlEditorContext);
  // SOP和报告模板下的控件分开控制：通用控件有不在报告模板显示，业务控件要在报告模板显示
  const getShownBasicControls = () => {
    return isReportTemplate ? basicControlsForReportTem : basicControls;
  };
  const getShownBizControls = () => {
    return isReportTemplate ? bizControlsForReportTem : businessBizMap.get(bizType);
  };

  return (
    <div
      onMouseEnter={validate}
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <CategoryCard title="基础控件">
        <Space wrap size={16}>
          {getShownBasicControls().map((ctrlType) => (
            <ControlCover key={ctrlType} controlType={ctrlType} canDrag={!hasError} />
          ))}
        </Space>
      </CategoryCard>
      {(getShownBizControls() as [])?.length > 0 && (
        <CategoryCard title="业务控件">
          <Space wrap>
            {getShownBizControls()?.map((ctrlType) => (
              <ControlCover key={ctrlType} controlType={ctrlType} canDrag={!hasError} />
            ))}
          </Space>
        </CategoryCard>
      )}
    </div>
  );
};

export default SourceContainer;
