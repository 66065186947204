import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { message as Message } from 'antd';
import moment from 'moment';
import { DetailLayout, DetailLayoutInfoBlock } from 'layout';

import { fetchWorkerCalendarGet } from 'src/api/ytt/calendar-domain';
import { TagTooltip, SingleOperationLogComponent } from 'src/components';
import { formatDateForRender } from 'src/utils/formatters';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { CalendarDataType, calendarTypeDisplayMap, CalendarType } from '../constants';
import FlexCalendar from '../ScheduleShift/ScheduleShiftContent/FlexCalendar';
import SpecialDayTable from '../OperateCalendar/SpecialDayTable';
import FixCalendar from './FixCalendar';
import styles from './index.module.scss';

interface CalendarDetailParams {
  id: string;
}

interface ManagerProps {
  managerId: number;
  managerName: string;
  avatarUrl?: string;
}

// 跳转到编辑页面
const goToEdit = (id: number) => {
  window.reactRouterHistoryInstance.push(`/modeling/calendarManagement/workCalendar/edit${id}`);
};

// 跳转到复制页面
const goToCopy = (id: number) => {
  window.reactRouterHistoryInstance.push(`/modeling/calendarManagement/workCalendar/copy${id}`);
};

export default function CalendarDetail({ match }: RouteComponentProps<CalendarDetailParams>) {
  const calendarId = Number(match?.params?.id);
  const [calendarDetailData, setCalendarDetailData] = useState<CalendarDataType>();
  const [loadingData, setLoadingData] = useState(false);
  const [visibleLog, setVisibleLog] = useState(false);

  const baseMenu = [
    // 一期不做删除
    // {
    //   key: 'delete',
    //   onClick: () => {
    //     // BLDelConfirm('删除后将无法恢复，确认删除？', delForm, handleFinish);
    //   },
    //   icon: <BlIcon type="iconshanchulajitong1" />,
    //   title: '删除',
    // },
    {
      key: 'copy',
      auth: authDict.workcalendar_add,
      onClick: () => {
        goToCopy(calendarId);
      },
      title: '复制',
    },
    {
      key: 'record',
      auth: authDict.workcalendar_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'edit',
      auth: authDict.workcalendar_edit,
      onClick: () => {
        goToEdit(calendarId);
      },
      icon: 'iconbianji',
      title: '编辑',
    },
  ];

  // 排班规则的isFullLine是为了强制后面的日历组件换行，如果不这么写，即使日历的部分是isFullLine的，也会被和排班规则画在一行里
  const shiftRuleInfo = {
    label: '排班规则',
    isFullLine: true,
    dataIndex: ['constantVo', 'shiftRule', 'shiftName'],
    span: 2,
  };

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      { label: '日历编号', dataIndex: 'code', span: 1 },
      {
        label: '日历名称',
        dataIndex: 'name',
        span: 1,
      },
      {
        label: '开始日期',
        dataIndex: 'startTime',
        span: 1,
        render: formatDateForRender,
      },
      {
        label: '结束日期',
        dataIndex: 'endTime',
        span: 1,
        render: formatDateForRender,
      },
      {
        label: '负责人',
        dataIndex: 'managers',
        span: 1,
        render: (renderData: ManagerProps[]) => {
          return (
            <TagTooltip
              list={renderData?.map((manager) => ({
                id: manager.managerId,
                name: manager.managerName,
                key: manager.managerId,
                avatarUrl: manager.avatarUrl,
              }))}
            />
          );
        },
      },
      {
        label: '日历类型',
        dataIndex: 'type',
        span: 1,
        render: (renderData: CalendarType) => calendarTypeDisplayMap[renderData],
      },
      ...(calendarDetailData?.type === CalendarType.Fix ? [shiftRuleInfo] : []),
      {
        span: 2,
        dataIndex: 'calendarPreview',
        isFullLine: true,
        render: () => {
          return (
            <div className={styles.calendarContainer}>
              {calendarDetailData?.type === CalendarType.Flex ? (
                <FlexCalendar
                  scheduleData={calendarDetailData}
                  loadFunction={loadCalendarDetail}
                  calendarLoading={loadingData}
                  readOnly
                />
              ) : (
                <FixCalendar calendarData={calendarDetailData} key="fixCalendarForDetail" />
              )}
            </div>
          );
        },
      },
    ],
    column: 2,
  };

  const specialDayInfo: DetailLayoutInfoBlock = {
    title: '特殊日',
    items: [
      {
        dataIndex: '',
        isFullLine: true,
        render: () => {
          return (
            <SpecialDayTable
              readOnly
              value={calendarDetailData?.constantVo.specialDays?.map((spDay) => ({
                ...spDay,
                // 修复详情页key的bug
                // specialDayIndex是前端用来给特殊日表格当key的字段，编辑/新建时id不一定存在所以必须这么做，查看详情时id一定存在，取id为specialDayIndex
                specialDayIndex: spDay.specialDayId,
              }))}
            />
          );
        },
      },
    ],
  };

  /**
   * 请求指定时间段的工作日历详情
   * 固定班制的日历，在新建和编辑时需要手动点刷新按钮来加载数据，所以加载方法一直写在组件里面
   * 但排班制的日历，在排班之前已经请求了日历的基本信息，所以请求方法在日历组件之外，为了满足日历切换日期的请求需要，要把加载方法作为参数传入日历
   */
  const loadCalendarDetail = async (startTime?: number, endTime?: number) => {
    setLoadingData(true);
    // 如果开始时间为空，则取当前选中日期的月初第一天为开始时间
    const viewStartTime = startTime;
    // 如果结束时间为空，取开始时间的月底最后一天为结束时间
    const viewEndTime =
      endTime ??
      (startTime ? moment(startTime).endOf('month').startOf('day').valueOf() : undefined);

    // 生成请求参数并请求
    const { code, data, message } = await fetchWorkerCalendarGet(
      {
        id: calendarId,
        viewStartTime,
        viewEndTime,
      },
      { legacy: true },
    );

    setLoadingData(false);
    if (code === 200 && data) {
      setCalendarDetailData(data);
    } else {
      Message.error(message);
    }
  };

  useEffect(() => {
    loadCalendarDetail();
  }, []);

  return (
    <div className={styles.detailContainer}>
      <DetailLayout
        title="工作日历详情"
        baseMenu={baseMenu}
        info={
          calendarDetailData?.type === CalendarType.Fix ? [baseInfo, specialDayInfo] : [baseInfo]
        }
        loading={loadingData}
        dataSource={calendarDetailData}
        userAuth={getAuthFromLocalStorage()}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={calendarId}
          objectCode={'WorkCalendar'} // 对象在对象平台唯一定义的objectCode
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </div>
  );
}
