import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Form, message as Message, Alert, message } from 'antd';
import { LinkTooltip, Tooltip } from 'components';
import useBlModal from 'src/components/modal/useBlModal';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { RecordListLayout } from 'layout';
import {
  fetchPurchaseReturnOrderList,
  fetchPurchaseReturnOrderReturnLayer,
  fetchPurchaseReturnOrderListMaterialByIds,
} from 'src/api/ytt/order-domain/purchaseReturnOrder';
import { dataColumnsForOrdersList, ordersFilterList } from './columns';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { PurchaseReturnOrderListItem } from '../interface';
import { goToCreate, goToDetail, goToEdit, OutBoundCreatePath } from '../navigates';
import { gcObject } from 'utils';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { PurchaseExecStatus } from 'src/dict/purchase';
import { endOrder, handleCloseOrder, handleDistribute, handleReturnOrder } from '../batchOperate';
import ReturnTable from '../components/returnTable';
import authDict from 'src/utils/auth';
import { formatDateTimeRangeToUnix } from 'src/utils/formatters/dateTime';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';

const PurchaseOrderList = () => {
  const history = useHistory();
  // 选中行的key，用以批量操作
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectedRows, setSelectRows] = useState<any[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [currentId, setCurrentId] = useState<number>();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);

  const refresh = () => {
    setRefreshMarker(Date.now());
  };
  const [form] = Form.useForm();
  const { show } = useBlModal();
  /** 用来打开弹框 */
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const batchReturn = async (selectRows: any, onSuccess?: () => void, onFail?: () => void) => {
    const filterRows = selectRows.filter(
      (node: any) => node.execStatus === PurchaseExecStatus.executing,
    );

    if (!filterRows.length) {
      Message.error('只有执行中的采购退货单才可以退货');
      onFail?.();
      return;
    }
    const isShowMessage = filterRows.length < selectRows.length;
    const ids = _.map(filterRows, 'id');
    const { data } = await fetchPurchaseReturnOrderReturnLayer({ ids });

    show({
      title: '退货',
      content: (
        <>
          {isShowMessage && (
            <>
              <Alert
                message={'此处仅显示采购退货单状态为"执行中"的数据，其他状态无法执行退货操作'}
                type="warning"
                closable
              />
              <br />
            </>
          )}
          <ReturnTable selectedRows={data} name={'returnInfo'} form={form} />
        </>
      ),
      width: 1200,
      onOk: async () => {
        const value = await form?.validateFields();

        const returnParams = value.returnInfo.map((value: any) => {
          return {
            id: value.id,
            deliverAmount: Number(value.deliverAmount),
          };
        });

        handleReturnOrder(returnParams, onSuccess, onFail);
        // console.log('value', value.returnInfo);
      },
      onCancel: onFail,
      closable: false,
      afterClose: onFail,
    });
  };
  // 新建出库单
  const createOutBound = async (list: PurchaseReturnOrderListItem[], refresh: () => void) => {
    const validateStatus = _.find(list, (row) => row.execStatus !== PurchaseExecStatus.executing);
    const validateOrigin = _.uniqBy(list, 'source')?.length > 1;

    if (validateStatus) {
      message.error('只有执行中的采购退货单可以新建出库单');
      refresh();
      return;
    }
    if (validateOrigin) {
      message.error('只有相同来源的采购退货单可以新建出库单');
      refresh();
      return;
    }
    const { data: materialList } = await fetchPurchaseReturnOrderListMaterialByIds({
      ids: _.compact(_.map(list, 'id')),
    });
    const filterMaterial = _.filter(materialList, (o) => Number(o?.outboundSendAmount?.amount) > 0);

    if (_.isEmpty(filterMaterial)) {
      refresh();
      message.error('暂无可出库物料');
      return;
    }
    dispatch({
      type: 'supplyChain/formatterPurchaseReturnOrderToOutBoundInfo',
      payload: filterMaterial,
    });
    history.push(OutBoundCreatePath());
  };

  const left = _.compact([
    {
      title: '导出',
      auth: authDict.purchasereturnorder_export,
      icon: <BlIcon type="icondaochu" />,
      showExport: true,
      onClick: () =>
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.purchaseReturnOrder,
        }),
    },
    {
      title: '新建采购退货单',
      auth: authDict.purchasereturnorder_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        history.push(goToCreate());
      },
      items: _.compact([
        {
          title: '导入',
          auth: authDict.purchasereturnorder_import,
          showExport: true,
          onClick: () =>
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.purchaseReturnOrder,
            }),
        },
      ]),
    },
  ]);

  const batchMenu = [
    {
      title: '下发',
      auth: authDict.purchasereturnorder_dispatch,
      onClick: (onSuccess: any, onFail: any) => {
        handleDistribute(selectedRowKeys, onSuccess, onFail);
      },
    },
    {
      title: '退货',
      auth: authDict.purchasereturnorder_return,
      onClick: (onSuccess: any, onFail: any) => {
        batchReturn(selectedRows, onSuccess, onFail);
      },
    },
    {
      title: '新建出库单',
      auth: authDict.outboundorder_add,
      onClick: (onSuccess: any, onFail: any) => {
        createOutBound(selectedRows, onFail);
      },
    },
    {
      title: '结束',
      auth: authDict.purchasereturnorder_finish,
      onClick: (onSuccess: any, onFail: any) => {
        endOrder(
          selectedRowKeys,
          _.map(selectedRows, 'code'),
          'multiple',
          () => {
            onSuccess?.();
            refresh();
          },
          onFail,
        );
      },
    },
    {
      title: '关闭',
      auth: authDict.purchasereturnorder_close,
      onClick: (onSuccess: any, onFail: any) => {
        Modal.confirm({
          title: '关闭采购退货单',
          content: '关闭采购退货单后不能再进行任何操作，确认关闭吗？',
          okText: '关闭',
          cancelText: '暂不关闭',
          onOk: async () => {
            await handleCloseOrder(selectedRowKeys, onSuccess, onFail);
          },
          onCancel: async () => {
            await onFail();
          },
        });
      },
    },
  ];

  const getOperationList = (record: PurchaseReturnOrderListItem) => {
    const { id, execStatus } = record;

    return _.compact([
      {
        title: '查看',
        auth: authDict.purchasereturnorder_detail,
        onClick: () => history.push(goToDetail(id!)),
      },
      {
        title: '编辑',
        auth: authDict.purchasereturnorder_edit,
        disabled:
          execStatus === PurchaseExecStatus.undetermined ||
          execStatus === PurchaseExecStatus.finished ||
          execStatus === PurchaseExecStatus.closed,
        onClick: () => history.push(goToEdit(id!)),
      },
      {
        title: '下发',
        auth: authDict.purchasereturnorder_dispatch,
        disabled: execStatus !== PurchaseExecStatus.created,
        onClick: () => handleDistribute([id!], refresh),
      },
      {
        title: '退货',
        auth: authDict.purchasereturnorder_return,
        disabled: execStatus !== PurchaseExecStatus.executing,
        onClick: () => batchReturn([record], refresh),
      },
      {
        title: '新建出库单',
        disabled: execStatus !== PurchaseExecStatus.executing,
        auth: authDict.outboundorder_add,
        onClick: () => createOutBound([record], refresh),
      },
      {
        title: '结束',
        auth: authDict.purchasereturnorder_finish,
        disabled: execStatus !== PurchaseExecStatus.executing,
        onClick: () => {
          endOrder([id!], [record.code!], 'single', refresh, refresh);
        },
      },
      {
        title: '关闭',
        auth: authDict.purchasereturnorder_close,
        disabled: execStatus !== PurchaseExecStatus.created,
        onClick: () =>
          Modal.confirm({
            title: '关闭采购退货单',
            content: `关闭采购退货单后不能再进行任何操作，确认关闭<${record.code}>吗？`,
            okText: '关闭',
            cancelText: '暂不关闭',
            onOk: async () => {
              await handleCloseOrder([id!], refresh);
            },
          }),
      },
      {
        title: '操作记录',
        auth: authDict.purchasereturnorder_operrecord,
        onClick: () => {
          setCurrentId(id);
          setVisibleLog(true);
        },
      },
    ]);
  };

  const dataColumns = [
    {
      title: '编号',
      dataIndex: 'code',
      width: 150,
      sorter: true,
      render: (value: string, record: any, index: number, config: any) => {
        return (
          <LinkTooltip
            text={value}
            to={goToDetail(record?.id)}
            width={config.contentWidth}
            auth={authDict.purchasereturnorder_detail}
          />
        );
      },
    },
    ...dataColumnsForOrdersList,
  ];

  const formatDataToQuery = (params: any) => {
    const { createdAt, updatedAt, creator, operator, ...rest } = params || {};

    const options = {
      ...formatDateTimeRangeToUnix('createdAtFrom', 'createdAtTo', createdAt),
      ...formatDateTimeRangeToUnix('updatedAtFrom', 'updatedAtTo', updatedAt),
      creatorIds: _.isEmpty(creator) ? null : _.map(creator, 'value'),
      operatorIds: _.isEmpty(operator) ? null : _.map(operator, 'value'),
      ...rest,
    };

    dispatch.excelImport.updateBusinessData({
      businessData: gcObject.filterEmptyProperty(options),
    });

    return gcObject.filterEmptyProperty(options);
  };
  const formatDataToFormDisplay = (data: any) => {
    const { createdAt = [], updatedAt = [], ...resData } = _.cloneDeep(data);

    return {
      ...resData,
      createdAt: momentData(createdAt),
      updatedAt: momentData(updatedAt),
    };
  };

  return (
    <>
      <RecordListLayout<PurchaseReturnOrderListItem>
        columns={dataColumns}
        useIndex={false}
        filterList={ordersFilterList}
        rowKey={(record: any) => record?.id}
        batchMenu={batchMenu}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectedKeys: any, selectedRows: any) => {
          setSelectedRowKeys(selectedKeys);
          setSelectRows(selectedRows);
        }}
        mainMenu={left}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        configcacheKey="purchaseReturnOrderList"
        requestFn={fetchPurchaseReturnOrderList}
        refreshMarker={refreshMarker}
        getOperationList={getOperationList}
        placeholder="请输入编号"
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={currentId as number}
          objectCode={OBJECT_OF_CODE.purchaseReturnOrder}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default PurchaseOrderList;
