import React, { useState, useEffect, useContext } from 'react';
import { Space, Badge, Form, Popover } from 'antd';
import { FilterItem, RecordListLayout } from 'layout';
import PositionsDetailDrawer from './detailDrawer';
import PositionsEdit from './edit';
import QcCtrlListModal from '../components/qcCtrlListModal';
import CreatePositions from '../positions/create';
import {
  QUALITY_ENUM,
  QUALITY,
  LOCK_STATUS,
  LOCK_STATUS_DISPLAY,
  FIELD_MAP,
  StoreContext,
  STATE_TYPE,
  STORE_ENUM_TYPE,
  STATUS_ENUM,
  LOCK_ENUM_TYPE,
  LEVEL_TYPE_ENUM,
} from '../constant';
import { replaceSign } from 'src/utils/constants';
import { QrCode, BlIcon, Tooltip, SingleOperationLogComponent, SearchSelect } from 'components';
import { isUndefined, map } from 'lodash';
import { patchRequest } from '../utils';
import { fieldTypeList } from 'utils';
import BLDelConfirm from '../../share/modalConfirm';
import { PositionTableRowType } from '../interface';
import { useHistory } from 'react-router-dom';
import { toPositionDetail } from '../navigation';
import { fetchStorageWarehouseGetEnableQcCtrl } from 'src/api/ytt/storage-domain/warehouse';
import {
  fetchStorageLocationPageList,
  fetchStorageLocationBulkDelete,
  fetchStorageLocationBulkDisable,
  fetchStorageLocationBulkEnable,
  fetchStorageLocationBulkLock,
  fetchStorageLocationBulkUnlock,
} from 'src/api/ytt/storage-domain/position';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import _Array from 'src/utils/dataTypes/array';
import { intersectionValueOfId } from 'src/utils/array';

interface PositionsProps {
  refreshListMarker?: number;
}

const PositionsList = (props: PositionsProps) => {
  const { refreshListMarker } = props;
  const history = useHistory();
  const [visibleDetail, setVisibleDetail] = useState(false); // 是否显示详情
  const [editVisible, setEditVisible] = useState(false); // 显示编辑
  const [createVisible, setCreateVisible] = useState(false); // 显示创建
  const [qcCtrlVisible, setQcCtrlVisible] = useState(false); // 显示质量管理批量设置
  const [positionId, setPositionId] = useState<any>(); // 详情id
  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [storeQcCtrl, setStoreQcCtrl] = useState<number>();
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录

  const { parentAreaSource, storeHouseSource } = useContext(StoreContext);
  const { parentAreaStorageType, parentAreaId, parentAreaLevel } = parentAreaSource;
  const { StoreHouseId } = storeHouseSource;
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const [delForm] = Form.useForm();

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  // const handleDelete = async (onSuccess?: () => void, onFail?: () => void, id?: number) => {
  //   try {
  //     const delId = id ? [id] : selectedRowKeys;

  // const { data } = await fetchStorageLocationBulkDelete({id:delId});

  //     if (_Array.isEmpty(data)) {
  //       // 为空表面删除成功 有数据代表删除失败
  //       typeof onSuccess === 'function' && onSuccess?.();
  //       Message.success('删除成功');
  //       return;
  //     }
  //     // 删除失败
  //     const { errorDesc } = data;

  //     typeof onFail === 'function' && onFail?.();
  //     Message.error(errorDesc);
  //   } catch (error) {
  //     onFail?.();
  //     console.log(error);
  //   }
  // };

  const fetchData = async (params: any) => {
    const myParams =
      parentAreaLevel === LEVEL_TYPE_ENUM?.FACTORY
        ? {}
        : { storageType: parentAreaStorageType, storageId: parentAreaId };

    const newParams = { ...params, ...myParams };

    const res = fetchStorageLocationPageList(newParams, { legacy: true });

    return res;
  };

  const getStoreQcCtrl = async () => {
    const { data, code } = await fetchStorageWarehouseGetEnableQcCtrl({ id: StoreHouseId });

    if (code === 200) {
      setStoreQcCtrl(data?.enableQcCtrl);
    }
  };

  const hasAuthRes = hasAuth(authDict.storagelocation_detail);

  const dataColumns = [
    {
      title: '仓位名称',
      dataIndex: 'name',
      isFilter: true,
      sorter: true,
      width: 150,
      fixed: 'left',
      render: (name: string, record: PositionTableRowType, index: number, config: any) => {
        return (
          <>
            {hasAuthRes ? (
              <a
                onClick={() => {
                  history.push(toPositionDetail(record?.id));
                }}
              >
                <Tooltip text={name ?? replaceSign} width={config.contentWidth} />
              </a>
            ) : (
              <Tooltip text={name ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },
    {
      title: '仓位编号',
      dataIndex: 'code',
      width: 150,
      sorter: true,
      isFilter: true,
      render: (code: string, record: PositionTableRowType, index: number, config: any) => {
        if (!code) return replaceSign;
        return (
          <div>
            <Space style={{ position: 'relative' }}>
              {hasAuthRes ? (
                <a
                  onClick={() => {
                    setPositionId(record?.id);
                    setVisibleDetail(true);
                  }}
                >
                  <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
                </a>
              ) : (
                <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
              )}
            </Space>
            <Space style={{ position: 'absolute', right: '10px' }}>
              <Popover
                placement="rightTop"
                content={
                  <div>
                    <QrCode value={record?.qrCode ?? record?.code ?? ''} />
                    <div style={{ textAlign: 'center' }}>
                      <Tooltip
                        text={record?.qrCode ?? code ?? replaceSign}
                        width={config.contentWidth}
                      />
                    </div>
                  </div>
                }
              >
                <BlIcon type="iconerweima" />
              </Popover>
            </Space>
          </div>
        );
      },
    },
    {
      title: '使用状态',
      dataIndex: 'enableFlag',
      width: 150,
      sorter: true,
      isFilter: true,
      selectProps: {
        options: STORE_ENUM_TYPE.map((node) => {
          return {
            label: node.name,
            value: node.id,
          };
        }),
      },
      render: (enableFlag: number) => {
        const useStatusMap = new Map([
          [STATUS_ENUM.ENABLED, <Badge text="启用中" status="success" />],
          [STATUS_ENUM.DISABLED, <Badge text="停用" status="error" />],
        ]);

        return useStatusMap.get(enableFlag);
      },
    },
    {
      title: '锁定状态',
      dataIndex: 'isLock',
      width: 150,
      sorter: true,
      type: fieldTypeList?.select,
      isFilter: true,
      selectProps: {
        options: LOCK_ENUM_TYPE.map((node) => {
          return {
            label: node.name,
            value: node.id,
          };
        }),
      },
      render: (enableFlag: number) => {
        return LOCK_STATUS_DISPLAY.get(enableFlag);
      },
    },
    {
      title: '仓库名称',
      dataIndex: 'warehouseName',
      width: 150,
      type: 'text',
      render: (warehouseName: string) => warehouseName || replaceSign,
    },
    {
      title: '仓库编号',
      dataIndex: 'warehouseCode',
      type: 'text',
      width: 150,
      render: (warehouseCode: string, record: PositionTableRowType, index: number, config: any) => {
        return (
          <div>
            <Tooltip text={warehouseCode ?? replaceSign} width={config.contentWidth} />
            <Space style={{ position: 'absolute', right: '10px' }}>
              <Popover
                placement="rightTop"
                content={
                  <div>
                    <QrCode value={record?.warehouseQrCode} />
                    <div style={{ textAlign: 'center' }}>
                      <Tooltip
                        text={record?.warehouseQrCode ?? replaceSign}
                        width={config.contentWidth}
                      />
                    </div>
                  </div>
                }
              >
                <BlIcon type="iconerweima" />
              </Popover>
            </Space>
          </div>
        );
      },
    },
    {
      title: '上级区域名称',
      dataIndex: 'parentName',
      type: 'text',
      width: 150,
      render: (parentName: string) => parentName || replaceSign,
    },
    {
      title: '上级区域编号',
      dataIndex: 'parentCode',
      type: 'text',
      width: 150,
      render: (parentCode: string, record: PositionTableRowType, index: number, config: any) => {
        return (
          <div>
            <Tooltip text={parentCode ?? replaceSign} width={config.contentWidth} />
            <Space style={{ position: 'absolute', right: '10px' }}>
              <Popover
                placement="rightTop"
                content={
                  <div>
                    <QrCode value={record?.parentQrCode ?? record?.parentCode ?? ''} />
                    <div style={{ textAlign: 'center' }}>
                      <Tooltip
                        text={record?.parentQrCode ?? record?.parentCode ?? replaceSign}
                        width={config.contentWidth}
                      />
                    </div>
                  </div>
                }
              >
                <BlIcon type="iconerweima" />
              </Popover>
            </Space>
          </div>
        );
      },
    },
    {
      title: '质量管理',
      dataIndex: 'qcCtrlList',
      isFilter: true,
      width: 200,
      render: (qcCtrlList: number[], record: PositionTableRowType) => {
        if (record?.enableQcCtrl === STATUS_ENUM.DISABLED) return '无限制';
        return <span>{qcCtrlList?.map((node: number) => QUALITY_ENUM.get(node)).join('、')}</span>;
      },
    },
  ];

  const createStoreShow =
    parentAreaLevel !== LEVEL_TYPE_ENUM?.FACTORY && !isUndefined(parentAreaLevel);

  const left = [
    {
      title: '导出',
      showExport: true,
      icon: <BlIcon type="icondaochu" />,
      auth: authDict.storagelocation_export,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.storageLocation,
        });
      },
    },
    {
      title: '新建仓位',
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.storagelocation_add,
      onClick: () => setCreateVisible(true),
      items: [
        {
          title: '导入仓位',
          showExport: true,
          auth: authDict.storagelocation_import,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.storageLocation,
            });
          },
        },
        {
          title: '导入仓库区域',
          showExport: true,
          auth: authDict.zone_import,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.storageArea,
            });
          },
        },
      ],
    },
  ];

  const positionLeft = createStoreShow ? left : left.filter((item) => item?.title !== '新建仓位');

  const toolbar = [
    {
      title: '启用',
      icon: <BlIcon type="iconqiyong" />,
      auth: authDict.storagelocation_enable_disable,
      onClick: (onSuccess: any, onFail: any) => {
        patchRequest(
          'patch',
          fetchStorageLocationBulkEnable,
          selectedRowKeys,
          '启用',
          onSuccess,
          onFail,
        );
      },
    },
    {
      title: '停用',
      auth: authDict.storagelocation_enable_disable,
      icon: <BlIcon type="icontingyong" />,
      onClick: (onSuccess: any, onFail: any) => {
        patchRequest(
          'patch',
          fetchStorageLocationBulkDisable,
          selectedRowKeys,
          '停用',
          onSuccess,
          onFail,
        );
      },
    },
    // {
    //   title: '删除',
    //   icon: <BlIcon type="iconshanchulajitong1" />,
    //   onClick: (onSuccess: any, onFail: any) => {
    //     BLDelConfirm(
    //       '是否删除当前仓位',
    //       delForm,
    //       () => {
    //         handleDelete(onSuccess, onFail);
    //       },
    //       () => {
    //         onFail();
    //       },
    //     );
    //   },
    // },
    {
      title: '质量管理',
      onClick: (onSuccess: any, onFail: any) => {
        setQcCtrlVisible(true);
        onFail();
      },
    },
    {
      title: '锁定',
      icon: <BlIcon type="iconmima" />,
      auth: authDict.storagelocation_lock_unlock,
      onClick: (onSuccess: any, onFail: any) => {
        patchRequest(
          'patch',
          fetchStorageLocationBulkLock,
          selectedRowKeys,
          '锁定',
          onSuccess,
          onFail,
        );
      },
    },
    {
      title: '解锁',
      icon: <BlIcon type="icona-mimajiesuo" />,
      auth: authDict.storagelocation_lock_unlock,
      onClick: (onSuccess: any, onFail: any) => {
        patchRequest(
          'patch',
          fetchStorageLocationBulkUnlock,
          selectedRowKeys,
          '解锁',
          onSuccess,
          onFail,
        );
      },
    },
  ];

  const filterToolBar = () => {
    let newToolBar = toolbar;

    if (parentAreaLevel === LEVEL_TYPE_ENUM?.FACTORY || isUndefined(parentAreaLevel)) {
      // 点击的全工厂时 不可操作质量管理
      newToolBar = toolbar.filter((node) => node.title !== '质量管理');
    }
    if (!storeQcCtrl) {
      // 仓库没有开启对应的质量管理时  也不显示
      newToolBar = toolbar.filter((node) => node.title !== '质量管理');
    }
    return newToolBar;
  };

  const formatDataToQuery = (params: any) => {
    const { qcCtrlList, sorter, code, name, enableFlag, isLock, ...rest } = params;

    const relParams = { ...rest };

    if (!isUndefined(enableFlag)) {
      relParams.enableFlag = enableFlag;
    }

    if (!isUndefined(isLock)) {
      relParams.isLock = isLock;
    }

    if (!_Array.isEmpty(code) || !_Array.isEmpty(name)) {
      relParams.idList = intersectionValueOfId(code, name);
    }

    if (!isUndefined(qcCtrlList)) {
      relParams.qcStatus = Number(qcCtrlList);
    }

    if (!_Array.isEmpty(code) || !_Array.isEmpty(name)) {
      relParams.idList = intersectionValueOfId(code, name);
    }

    if (sorter) {
      relParams.sorter = map(params?.sorter, (node: any) => {
        return {
          order: node?.order,
          field: FIELD_MAP.get(node?.field),
        };
      });
    }

    dispatch.excelImport.updateBusinessData({ businessData: relParams });
    return relParams;
  };

  const filterList: FilterItem[] = [
    {
      label: '仓位名称',
      name: 'name',
      type: fieldTypeList?.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'storageLocation'}
          labelInValue
          labelPath="name"
          valuePath="id"
          mode="multiple"
        />
      ),
    },
    {
      label: '仓位编号',
      name: 'code',
      type: fieldTypeList?.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'storageLocation'}
          labelInValue
          labelPath="code"
          valuePath="id"
          mode="multiple"
        />
      ),
    },
    {
      label: '使用状态',
      name: 'enableFlag',
      type: fieldTypeList?.select,
      selectProps: {
        options: STORE_ENUM_TYPE.map((node) => {
          return {
            label: node.name,
            value: node.id,
          };
        }),
      },
    },
    {
      label: '锁定状态',
      name: 'isLock',
      type: fieldTypeList?.select,
      selectProps: {
        options: LOCK_ENUM_TYPE.map((node) => {
          return {
            label: node.name,
            value: node.id,
          };
        }),
      },
    },
    {
      label: '质量管理',
      name: 'qcCtrlList',
      type: fieldTypeList?.select,
      selectProps: {
        options: QUALITY.map((node) => {
          return {
            label: node.name,
            value: node.id,
          };
        }),
      },
    },
  ];

  const getOperationList = (record: PositionTableRowType) => {
    const { enableFlag, isLock } = record;

    return [
      {
        title: '查看',
        auth: authDict.storagelocation_detail,
        onClick: () => {
          setPositionId(record?.id);
          setVisibleDetail(true);
        },
      },
      {
        title: '编辑',
        auth: authDict.storagelocation_edit,
        onClick: () => {
          setPositionId(record?.id);
          setEditVisible(true);
        },
      },
      {
        title: enableFlag ? '停用' : '启用',
        auth: authDict.storagelocation_enable_disable,
        onClick: async () => {
          const storeApi = record?.enableFlag
            ? fetchStorageLocationBulkDisable
            : fetchStorageLocationBulkEnable;
          const storeDisplay = record?.enableFlag ? '停用' : '启用';

          patchRequest('single', storeApi, [record?.id], storeDisplay, refresh);
        },
      },
      {
        title: isLock ? '解锁' : '锁定',
        auth: authDict.storagelocation_lock_unlock,
        onClick: async () => {
          const lockApi = record?.isLock
            ? fetchStorageLocationBulkUnlock
            : fetchStorageLocationBulkLock;
          const lockDisplay = record?.isLock ? '解锁' : '锁定';

          patchRequest('single', lockApi, [record?.id], lockDisplay, refresh);
        },
      },
      {
        title: '操作记录',
        auth: authDict.storagelocation_operrecord,
        onClick: () => {
          setVisibleLog(true);
          setPositionId(record?.id);
        },
      },
    ];
  };

  useEffect(() => {
    setRefreshMarker(refreshListMarker);
    if (StoreHouseId) {
      getStoreQcCtrl();
    }
  }, [refreshListMarker]);

  return (
    <>
      <RecordListLayout<PositionTableRowType>
        rowKey={(record: PositionTableRowType) => String(record.id)}
        filterList={filterList}
        columns={dataColumns}
        mainMenu={positionLeft}
        batchMenu={filterToolBar()}
        userAuth={getAuthFromLocalStorage()}
        placeholder={'输入仓位名称、仓位编号'}
        refreshMarker={refreshMarker}
        configcacheKey="positions"
        selectedRowKeys={selectedRowKeys}
        formatDataToQuery={formatDataToQuery}
        onSelectedRowKeys={(selectKey: any) => {
          setSelectRowKeys(selectKey);
        }}
        requestFn={fetchData}
        getOperationList={getOperationList}
      />
      {createVisible && (
        <CreatePositions
          visible={createVisible}
          onCancel={() => {
            setCreateVisible(false);
          }}
          refreshTable={refresh}
          storeQcCtrl={storeQcCtrl}
        />
      )}

      {editVisible && (
        <PositionsEdit
          visible={editVisible}
          positionId={positionId}
          onCancel={() => {
            setEditVisible(false);
          }}
          refreshTable={refresh}
          storeQcCtrl={storeQcCtrl}
        />
      )}
      {visibleDetail && (
        <PositionsDetailDrawer
          refreshTable={refresh}
          visible={visibleDetail}
          closeDetail={() => {
            setVisibleDetail(false);
          }}
          positionId={positionId}
        />
      )}
      {qcCtrlVisible && (
        <QcCtrlListModal
          visible={qcCtrlVisible}
          onCancel={() => {
            setQcCtrlVisible(false);
          }}
          closeQcCtrl={() => {
            setQcCtrlVisible(false);
            refresh();
          }}
          selectedRowKeys={selectedRowKeys}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={positionId}
          objectCode={'StorageLocation'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default PositionsList;
