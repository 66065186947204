import React from 'react';
import { BlIcon, BlPopover } from 'src/components';
import styles from '../index.module.scss';

interface Props {
  label: string;
  content: string[];
}

const PopoverLabel: React.FC<Props> = (props) => {
  const { label, content } = props;

  const defaultContent = () => (
    <div style={{ padding: 8, backgroundColor: '#fff' }}>
      {content?.map((text) => (
        <div key={text}>{text}</div>
      ))}
    </div>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <span>{label}</span>
      <BlPopover
        title={'帮助提示'}
        overlayStyle={{ width: 300 }}
        color={'#f2f2f2'}
        overlayClassName={styles.helpPopover}
        mouseLeaveDelay={0.3}
        content={defaultContent()}
        placement="rightTop"
      >
        <BlIcon type="iconwentizhiyinbangzhu" className={styles.helpIcon} />
      </BlPopover>
    </div>
  );
};

export default PopoverLabel;
