import { useState } from 'react';
import { RecordListLayout } from 'layout';
import { dataColumnsForApprovalList, approvalFilterList } from './columns';
import { fetchApprovalList } from 'src/api/ytt/metadata-domain/approvalList';
import { formatDateTimeRangeToUnix } from 'src/utils/formatters/dateTime';
import { gcObject } from 'utils';
import authDict, { hasAuth } from 'src/utils/auth';
import { ApprovalListItemProps } from './constant';
import { useHistory } from 'react-router-dom';
import { toDetail } from './navigate';
import _ from 'lodash';
import { BlTooltip, SingleOperationLogComponent } from 'src/components';
import _Array from 'src/utils/dataTypes/array';
import _Url from 'src/utils/url';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';

const PendingList = () => {
  const history = useHistory();
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [id, setId] = useState<number>(0);
  const refresh = () => setRefreshMarker(Date.now());

  const query = _Url.getParams();
  const { tabKey } = query;

  const dataColumns = [
    {
      title: '编号',
      dataIndex: 'code',
      sorter: true,
      width: 150,
      render: (text: string, record: ApprovalListItemProps, index: number, config: any) => {
        return hasAuth(authDict.approvalInstance_detail) ? (
          <a
            onClick={() => {
              history.push(toDetail(record.id!));
            }}
          >
            <BlTooltip text={text} width={config.contentWidth} />
          </a>
        ) : (
          <BlTooltip text={text} width={config.contentWidth} />
        );
      },
    },
    ...dataColumnsForApprovalList(tabKey).filter((e: any) => e?.title !== '创建人'),
  ];

  const getOperationList = (record: ApprovalListItemProps) => {
    return [
      {
        title: '查看',
        auth: authDict.approvalInstance_detail,
        onClick: () => {
          history.push(toDetail(record.id!));
        },
      },
      {
        title: '操作记录',
        auth: authDict.approvalInstance_operrecord,
        onClick: () => {
          setId(record?.id ?? -1);
          setVisibleLog(true);
        },
      },
    ];
  };

  const formatDataToQuery = (params: any) => {
    const { createdAt, updatedAt, approvalCode, ...rest } = params || {};
    const options = {
      ...formatDateTimeRangeToUnix('createdAtBegin', 'createdAtEnd', createdAt),
      ...formatDateTimeRangeToUnix('updatedAtBegin', 'updatedAtEnd', updatedAt),
      approvalCode: _Array.isEmpty(approvalCode) ? null : _.map(approvalCode, 'value'),
      ...rest,
    };

    return gcObject.filterEmptyProperty(options);
  };

  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (data: any) => {
    const { createdAt, updatedAt } = data;

    const retData = { ...data };

    if (createdAt) {
      retData.createdAt = momentData(createdAt);
    }
    if (updatedAt) {
      retData.updatedAt = momentData(updatedAt);
    }

    return retData;
  };

  return (
    <>
      <RecordListLayout<ApprovalListItemProps>
        columns={dataColumns}
        filterList={approvalFilterList(tabKey).filter(
          (e: any) => e?.name !== 'operator' && e?.name !== 'creator',
        )}
        rowKey={'id'}
        useIndex={false}
        configcacheKey={'approvalList'}
        getOperationList={getOperationList}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        requestFn={fetchApprovalList}
        refreshMarker={refreshMarker}
        placeholder={'请输入编号、名称'}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          closeDetail={() => {
            setVisibleLog(false);
          }}
          visible={visibleLog}
          instanceId={id}
          objectCode={'ApprovalInstance'}
        />
      )}
    </>
  );
};

export default PendingList;
