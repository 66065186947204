import _ from 'lodash';
import { compose, pick, filter, map, compact } from 'lodash/fp';
import moment from 'moment';
import { FieldType, TargetType } from 'src/dict/common';
import { getPrefixedSubObjectCode, excludeSubObjFields } from 'src/page/customLayout';
import { FieldDTO, EntityFieldDTO, SubObjectDTO } from 'src/page/customLayout/types';
import {
  choiceToLabelValue,
  getFieldRealValueProp,
  isSelectableField,
} from 'src/page/custom_fields/utils';
import type { FieldValueDTO, SubObjectValueDTO } from './types';

type ListWithFields = { [p: string]: any; fields: FieldValueDTO[] }[];

/** 获取对象实例各个操作的权限点 */
export const getObjectAuthCode = (objectCode: string, operationCode: string) =>
  `${objectCode}.${operationCode}`;

export const getListPath = () => location.pathname.split('/').slice(0, 4).join('/');
export const getDetailPath = () => `${getListPath()}/:instanceId/detail`;
export const getCreatePath = () => `${getListPath()}/create`;
export const getEditPath = () => `${getListPath()}/:instanceId/edit`;
export const getCopyPath = () => `${getListPath()}/:instanceId/copy`;
export const getImportPath = () => `${getListPath()}/import`;

export const getFieldRealValue = (f: FieldValueDTO) => {
  return _.get(f, getFieldRealValueProp(f.fieldType!));
};

const idNameObjCode = ['User', 'Department', 'Role'];
const relationValueMapper = ({ id, mainProperty, objectCode }: any, notInArray = false) => {
  if (idNameObjCode.includes(objectCode)) {
    const item = { id, name: mainProperty, avatarUrl: null };

    return notInArray ? [item] : item;
  }
  return { label: mainProperty, value: id };
};

/**
 * 将字段列表转为 key-value 对象。主属性特别标注。
 * 此处保留引用字段以获得一个展示列。提交表单时去除引用字段。
 */
export const transformFieldsToKV = (fields: FieldValueDTO[]) => {
  const result = _.fromPairs(
    excludeSubObjFields(fields).map((f) => [f.fieldCode, getFieldRealValue(f)]),
  );

  result.$mainProperty = _.find(fields, { isName: 1 })?.fieldValue;
  return result;
};

/** 将从对象列表转为 key-value 对象 */
export const transformSubObjectsToKV = (subObjects: SubObjectValueDTO[]) => {
  return _.groupBy(
    subObjects.map((obj) => ({
      objectCode: getPrefixedSubObjectCode(obj.objectCode!),
      ...transformFieldsToKV(obj.fields!),
    })),
    'objectCode',
  );
};

/** 获取『其他信息』里的字段的 key-value 对象 */
export const getKVOfOtherInfo = pick(['creator', 'createdAt', 'operator', 'updatedAt']);

/** 将返回体里的自定义字段提到外层来。为防止外层字段被同名自定义字段覆盖, 统一加个额外标识 */
export const escalateFieldsToList = (requestFn: (p: any) => Promise<any>) => (params: any) =>
  requestFn(params).then((res) => {
    const originList = (res.data?.list ?? []) as ListWithFields;
    const list = originList.map((obj) => {
      const expandedFields = transformFieldsToKV(obj.fields);
      const markedOuterFields = _.mapKeys(
        _.pick(obj, ['creator', 'createdAt', 'operator', 'updatedAt', 'instanceId']),
        (__, k) => `$${k}`,
      );

      return {
        ...markedOuterFields,
        ...expandedFields,
      };
    });

    return { ...res, data: { ...res.data, list } };
  });

/** 将详情接口返回的自定义字段值转为表单回填格式 */
export const getFieldsValueForReset = (fields: FieldDTO[], entityFields: EntityFieldDTO[]) => {
  return fields.map((f) => {
    if (f.isRefer) {
      return undefined;
    }
    const entityField = _.find(entityFields, { fieldCode: f.fieldCode });

    if (entityField) {
      const fieldValueProp = getFieldRealValueProp(entityField.fieldType!);
      let fieldValue: any = _.get(entityField, fieldValueProp);

      if (!_.isEmpty(fieldValue)) {
        if (isSelectableField(entityField.fieldType!)) {
          fieldValue = fieldValue.map(choiceToLabelValue);
          if (entityField.fieldType === FieldType.select) {
            fieldValue = fieldValue[0];
          }
        } else if (entityField.fieldType === FieldType.relation) {
          fieldValue = _.isArray(fieldValue)
            ? fieldValue.map((item) => relationValueMapper(item))
            : relationValueMapper(fieldValue, true);
        } else if (entityField.fieldType === FieldType.appendix) {
          fieldValue = fieldValue?.map((v: any) => v?.id);
        }
      }
      return {
        ..._.omit(entityField, 'fieldId'),
        id: entityField.fieldId,
        fieldValue,
        [fieldValueProp]: fieldValue, // 兼容从 choiceValues 取值的逻辑
      };
    }
    // 无回填值, 填空值
    return {
      ..._.pick(f, ['fieldCode', 'id', 'fieldType', 'targetType']),
      fieldValue: undefined,
    };
  });
};

/** 将详情接口返回的从对象值转为表单回填格式 */
export const getSubObjectsValueForReset = (
  subObjects: SubObjectDTO[],
  entitySubObjects: SubObjectValueDTO[],
) => {
  return subObjects.map(({ objectCode, fieldList }) => ({
    objectCode,
    instances: compose(
      map(({ fields, instanceId }) => ({
        ...transformFieldsToKV(compact(getFieldsValueForReset(fieldList!, fields))),
        $instanceId: instanceId,
      })),
      filter({ objectCode }),
    )(entitySubObjects),
  }));
};

/**
 * 新建时，将自定义字段默认值转为表单填充格式
 * 注意：为保持表单项顺位正确，引用字段对应的『表单项』以 undefined 占位
 */
export const getFieldsDefaultValueForPreset = (fields: FieldDTO[]) => {
  return excludeSubObjFields(fields).map((f) => {
    const { defaultValue, fieldType, choiceValues, isRefer, datetimeFormat, isCurrentTime } = f;

    if (isRefer) {
      return undefined;
    }
    let fieldValue;

    // 注意：后端将 defaultValue 统一转为了 string 格式，前端拿到要处理下
    if (_.isNil(defaultValue) || defaultValue === '') {
      fieldValue = undefined;
    } else if (fieldType === FieldType.boolean) {
      fieldValue = !!_.toNumber(defaultValue);
    } else if (fieldType === FieldType.integer || fieldType === FieldType.number) {
      fieldValue = _.toNumber(defaultValue);
    } else if (fieldType === FieldType.date) {
      // 当选择HH:mm:ss或HHmmss时，默认为2000/01/01，将来排序会用到
      if (datetimeFormat === 'HH:mm:ss') {
        fieldValue = moment('2000/01/01 ' + defaultValue).valueOf();
      } else if (datetimeFormat === 'HHmmss') {
        fieldValue = moment('2000001 ' + defaultValue).valueOf();
      } else if (datetimeFormat === 'YYYYMMDDHHmmss' || datetimeFormat === 'YYYYMMDDHHmm') {
        fieldValue = moment(defaultValue.slice(0, 8) + ' ' + defaultValue.slice(8)).valueOf();
      } else {
        fieldValue = moment(defaultValue).valueOf();
      }
    } else {
      fieldValue = defaultValue;
    }

    // 时间类型开启自动填写当前时间时，默认初始化当前时间
    if (fieldType === FieldType.date && isCurrentTime) {
      fieldValue = moment().valueOf();
    }

    // 选择类型的默认选中项
    if (isSelectableField(fieldType!)) {
      const defaultChoices = _.filter(choiceValues, { isDefault: 1 });

      if (!_.isEmpty(defaultChoices)) {
        fieldValue = defaultChoices.map(choiceToLabelValue);

        if (fieldType === FieldType.select) {
          fieldValue = fieldValue[0];
        }
      }
    }
    return {
      ..._.pick(f, ['fieldCode', 'id', 'fieldType', 'targetType']),
      fieldValue,
      isNumberRuleActuallyConfig: f.isNumberRuleConfig,
    };
  });
};

export const formatFieldValuesForSubmit = compose(
  map((field: FieldValueDTO & { id?: number }) => {
    let fieldValue: any = field.fieldValue;
    const { fieldType, targetType } = field;

    const isSonObj =
      _.isMatch(fieldValue, { isNumberRuleActuallyConfig: 0 }) ||
      _.isMatch(fieldValue, { isNumberRuleActuallyConfig: 1 });

    const isNumberRuleActuallyConfig = isSonObj ? fieldValue.isNumberRuleActuallyConfig : undefined;

    if (!_.isNil(fieldValue)) {
      // 关联关系抽取value/id值
      if (fieldType === FieldType.relation) {
        if (targetType === TargetType.multiChoice) {
          fieldValue = (fieldValue as any[]).map((v) => v.value ?? v.id);
        } else {
          if (_.isArray(fieldValue)) {
            fieldValue = fieldValue[0];
          }
          fieldValue = fieldValue?.value ?? fieldValue?.id;
        }
      } else if (fieldType === FieldType.select) {
        fieldValue = fieldValue.value;
      } else if (fieldType === FieldType.multiSelect) {
        fieldValue = _.map(fieldValue, 'value');
      } else if (fieldType === FieldType.text && isSonObj) {
        fieldValue = fieldValue.fieldValue;
      }
    }

    if (isSonObj) {
      return {
        ..._.omit(field, ['id', 'targetType']),
        fieldId: field.id,
        fieldValue,
        isNumberRuleActuallyConfig,
      };
    }
    return {
      ..._.omit(field, ['id', 'targetType']),
      fieldId: field.id,
      fieldValue,
    };
  }),
  compact, // 滤掉引用字段
);

// 要么返回一个 namePath 的数组，要么返回 null（入参既非对象又非数组时）
export const getNamePathsFromFieldsValue = (value: any): any[] | null => {
  if (_.isArray(value)) {
    // 如果是数组，把下标加到每一个路径上
    return value
      .map((v, idx) => {
        const path = getNamePathsFromFieldsValue(v);

        return path ? path.map((item) => [idx, ...item]) : [[idx]];
      })
      .flat(1);
  } else if (_.isPlainObject(value)) {
    // 如果是对象，把属性名加到每一个路径上
    return _.entries(value)
      .map(([k, v]) => {
        const path = getNamePathsFromFieldsValue(v);

        return path ? path.map((item) => [k, ...item]) : [[k]];
      })
      .flat(1);
  }
  // 叶结点
  return null;
};
