import _ from 'lodash';
import { RouteProp, MenuData } from './index.d';
import frontendRoutes from './frontend';
import backendRoutes from './backend';

export type { RouteProp, MenuData };

const authedFrontendRoutes = _.cloneDeep(frontendRoutes);
const authedBackendRoutes = _.cloneDeep(backendRoutes);

// 为一、二级菜单自动添加权限
function addAuthForFolders(routes: RouteProp[]) {
  routes.forEach((route) => {
    // 关联页面的菜单项、已添加权限的菜单项忽略
    if (!route || route.component || route.auth || !_.isArray(route.children)) {
      return;
    }
    // 后序遍历, 自底向上添加权限
    addAuthForFolders(route.children);
    const childrenAuth = _.map(route.children, 'auth');

    // 子级不设权限点, 则父级菜单始终展示
    if (childrenAuth.includes(undefined)) {
      return;
    }
    const resultAuth = _.compact(_.flatten(childrenAuth));

    if (!_.isEmpty(resultAuth)) {
      route.auth = resultAuth;
    }
  });
}

addAuthForFolders(authedFrontendRoutes);
addAuthForFolders(authedBackendRoutes);

export { authedFrontendRoutes as frontendRoutes, authedBackendRoutes as backendRoutes };
