import { useEffect, useState } from 'react';
import { Input, Select, Form, message, Checkbox, Modal, Tooltip } from 'antd';
import { DataFormLayoutInfoBlock, DataFormLayout } from 'layout';
import TextArea from 'antd/lib/input/TextArea';
import { terminalList, UserTypes } from '../constants';
import {
  checkFormWithLetterDigitalUnderscore,
  checkTwoSidesTrim,
  telValidator,
} from 'src/utils/formValidators';
// import { CustomFieldEditRender } from 'src/components/customField/edit';
import AvatarUpload from 'src/components/avatar/upload';
import { BcAttachmentForm } from 'components';
import { getRoleList } from 'src/services/organization/rolesApi';
import {
  createUser,
  getOrgUserCount,
  getUserDetailById,
  updateUser,
} from 'src/services/organization/userManage';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { gcArray, gcObject } from 'src/utils';
import { RoleOption } from '../index.d';
import { SYSTEM_ADMIN } from '../../constants';
import { isAdminUser } from '../../share/utils';
import { renderLicenses, roleTagRender } from '../userUtils';
import { RoleAvatar } from 'src/components/avatar/show';
import _, { isEmpty } from 'lodash';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import {
  formatCustomFieldsInData,
  initCustomFieldsInData,
  injectCustomFieldInfos,
  useCustomFieldCombinedData,
} from 'src/components/customField';

interface UserColumnProps {
  id: number;
  name: string;
  avatarUrl: string;
}

const UserCreateAndEdit = () => {
  const [form] = Form.useForm();
  const SELECT_KEYS = ['departmentIds', 'superior', 'ownerId'];
  const [defaultValues, setDefaultValues] = useState<any>({});
  const [keepOn, setkeepOn] = useState<boolean>(false);
  const [, setTerminal] = useState<int | undefined>();
  const [roleList, setRoleList] = useState<RoleOption[]>([]);
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.inboundOrder);
  const [userCountInfo, setUserCountInfo] = useState<any>({});
  const [acountCount, setAcountCount] = useState<string>();
  const getUrlParams = (name: string) => {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i'); // 定义正则表达式
    const r = window.location.search.substr(1).match(reg);

    if (r != null) return unescape(r[2]);
    return null;
  };
  const editId = getUrlParams('id');
  const isEdit = getUrlParams('type') !== 'copy' && !!editId;
  const departmentId = Number(getUrlParams('departmentId'));
  const departmentName = decodeURIComponent(getUrlParams('departmentName')!);

  const getUserCount = () => {
    getOrgUserCount().then((res) => {
      setUserCountInfo(res.data);
    });
  };

  useEffect(() => {
    if (userCountInfo?.appAndWebFreeNum || userCountInfo?.appAndWebUsedNum) {
      setAcountCount(getUserCountByTerminal(form.getFieldValue('terminal')));
    }
  }, [userCountInfo, defaultValues]);

  useEffect(() => {
    const { roles } = getRoleList({ page: 1, size: 500 });

    getUserCount();
    roles.then((res) => {
      const _roleList = res.data?.list?.map((i: any) => {
        return {
          label: i.name,
          value: i.id,
          hidden: i.code === SYSTEM_ADMIN,
          roleOwnedLicenses: i.roleOwnedLicenses,
        };
      });

      setRoleList(_roleList || []);
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: detailData } = await getUserDetailById(Number(getUrlParams('id')));

        if (detailData.superior) {
          detailData.superior = [
            { label: detailData.superior.name, value: detailData.superior.id },
          ];
        } else {
          delete detailData.superior;
        }
        if (detailData.roleList) {
          detailData.roleIds = detailData.roleList.map((role: any) => ({
            value: role.id,
            label: role.name,
          }));
        }
        if (detailData.departmentVOList) {
          detailData.departmentIds = detailData.departmentVOList.map((department: any) => {
            return {
              label: department.name,
              value: department.id,
            };
          });
        }

        const afterFormatCustomFieldsValue = initCustomFieldsInData(detailData);

        form.setFieldsValue(afterFormatCustomFieldsValue);
        setDefaultValues(detailData);
      } catch (err) {
        console.log(err);
      }
    };

    if (getUrlParams('id')) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    // fetchCustomFields();
    if (departmentId && departmentName) {
      form.setFieldsValue({ departmentIds: [{ id: departmentId, name: departmentName }] });
    }
  }, []);

  const getUserCountByTerminal = (type: number) => {
    if (type === UserTypes.APP) {
      return `${userCountInfo.appFreeNum}/${userCountInfo.appFreeNum + userCountInfo.appUsedNum}`;
    }
    if (type === UserTypes.WEB_AND_APP) {
      return `${userCountInfo.appAndWebFreeNum}/${
        userCountInfo.appAndWebFreeNum + userCountInfo.appAndWebUsedNum
      }`;
    }
    if (type === UserTypes.INTERFACE) {
      return `${userCountInfo.interfaceFreeNum}/${
        userCountInfo.interfaceFreeNum + userCountInfo.interfaceUsedNum
      }`;
    }
    return '';
  };

  const handleChangeTerminal = (value: any) => {
    setAcountCount(getUserCountByTerminal(value));
    setTerminal(value);
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '',
        isFullLine: true,
        name: 'avatarUrl',
        render: () => <AvatarUpload form={form} uploadedUrl={defaultValues.avatarUrl} />,
      },
      {
        label: '账号类型',
        name: 'terminal',
        initialValue: UserTypes.WEB_AND_APP,
        rules: [{ required: true, message: '请选择账号类型' }],
        render: () => (
          <div style={{ display: 'flex' }}>
            <Form.Item name="terminal" style={{ marginBottom: 0 }}>
              <Select
                options={
                  defaultValues?.terminal !== UserTypes.OPEN_API
                    ? terminalList
                    : [
                        {
                          label: 'OpenAPI账号',
                          value: UserTypes.OPEN_API,
                        },
                      ]
                }
                disabled={
                  defaultValues?.terminal === UserTypes.OPEN_API ||
                  (isEdit && defaultValues?.terminal === UserTypes.INTERFACE) ||
                  (isEdit && !!isAdminUser(defaultValues.roleList))
                }
                placeholder="请选择账号类型"
                onChange={handleChangeTerminal}
              />
            </Form.Item>
            {acountCount &&
              (defaultValues?.terminal || !isEdit) &&
              defaultValues?.terminal !== UserTypes.OPEN_API && (
                <span style={{ marginLeft: 10 }}>
                  该类型账号可用 <a>{acountCount}</a>个
                </span>
              )}
          </div>
        ),
      },
      {
        label: '用户编号',
        name: 'code',
        rules: [
          { required: true, message: '请输入用户编号' },
          { max: 100, message: '不超过100字符' },
          { validator: checkFormWithLetterDigitalUnderscore },
        ],
        validateFirst: true,
        render: () => <Input placeholder="请输入用户编号" />,
      },
      {
        label: '账号',
        name: 'username',
        rules: [
          { required: true, message: '请输入账号' },
          { max: 100, message: '不超过100字符' },
          { validator: checkFormWithLetterDigitalUnderscore },
        ],
        validateFirst: true,
        render: () => <Input placeholder="请输入用户账号" />,
      },
      {
        label: '用户名称',
        name: 'name',
        rules: [
          { required: true, message: '请输入用户名称' },
          { max: 100, message: '不超过100字符' },
          { validator: checkTwoSidesTrim },
        ],
        validateFirst: true,
        render: () => <Input placeholder="请输入用户名称" />,
      },
      {
        label: '手机号',
        name: 'phone',
        rules: [{ validator: telValidator }],
        render: () => (
          <Input
            disabled={isEdit && !!isAdminUser(defaultValues.roleList)}
            placeholder="请输入手机号"
          />
        ),
      },
      {
        label: '邮箱',
        name: 'email',
        rules: [{ max: 100, message: '不超过100字符' }],
        validateFirst: true,
        render: () => <Input placeholder="请输入邮箱" />,
      },
      {
        label: '直属上级',
        name: 'superior',
        render: () => (
          <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple={false} isSelectUser />
        ),
      },
      {
        label: '部门',
        name: 'departmentIds',
        rules: [{ required: true, message: '请选择部门' }],
        render: () => (
          <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isNewFormat={!editId} />
        ),
      },
      {
        label: '角色',
        name: 'roleIds',
        render: () => (
          <Select
            placeholder="请选择用户角色"
            labelInValue
            disabled={Boolean(
              isAdminUser(defaultValues.roleList) || defaultValues?.terminal === UserTypes.OPEN_API,
            )}
            mode="multiple"
            tagRender={roleTagRender}
            filterOption={(inputValue: string, option: RoleOption | undefined) =>
              String(option?.label)?.indexOf(inputValue) > -1
            }
          >
            {roleList.map((role: any) => (
              <Select.Option
                hidden={role.hidden}
                value={role.value}
                label={role.label}
                key={role.value}
              >
                <Tooltip
                  title={renderLicenses(role.roleOwnedLicenses || [])}
                  placement="topLeft"
                  color={'#fff'}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <RoleAvatar name={role.label} />
                    <span
                      style={{
                        display: 'inline-block',
                        maxWidth: 70,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {role.label}
                    </span>
                  </div>
                </Tooltip>
              </Select.Option>
            ))}
          </Select>
        ),
      },
      {
        label: '附件',
        name: 'attachments',
        isFullLine: true,
        render: () => <BcAttachmentForm form={form} />,
      },
      {
        label: '备注',
        name: 'desc',
        isFullLine: true,
        rules: [{ max: 1000, message: '不超过1000字符' }],
        render: () => <TextArea placeholder="请输入备注" />,
      },
    ],
  };

  if (isEdit) {
    baseInfo.items.push({
      label: '编辑原因',
      name: 'updateReason',
      isFullLine: true,
      rules: [{ max: 1000, message: '不超过1000字符' }],
      render: () => <TextArea placeholder="请输入编辑原因" />,
    });
  }

  const showConfirmModal = (
    data: { username: string; name: string; password: string },
    newPWD: string,
    isEdit?: boolean,
  ) => {
    if (isEdit) {
      message.success('编辑成功');
      history.go(-1);
    } else {
      Modal.info({
        title: '创建用户成功',
        onOk() {
          history.go(-1);
        },
        content: (
          <>
            <p>
              {'账号'}: {data.username}
            </p>
            <p>
              {'姓名'}: {data.name}
            </p>
            <p>
              {'密码'}: {newPWD}
            </p>
          </>
        ),
      });
    }
  };

  const genPWD = (pasLen?: number) => {
    // Math.random() 函数返回一个浮点数,  伪随机数在范围从0到小于1，也就是说，从0（包括0）往上，但是不包括1（排除1）
    // 话虽如此，总觉得还是少了点安全感，访问数组下标时仍然加了个取余的动作
    const MIN_PAS_LEN = 15;
    const MAX_PAS_LEN = 20;

    if (!pasLen) {
      pasLen = Math.floor(MIN_PAS_LEN + Math.random() * (MAX_PAS_LEN - MIN_PAS_LEN));
    }
    const pwdChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const pwdSigns = '_-$%&@+!';
    const signChar = pwdSigns[Math.floor(Math.random() * pwdSigns.length) % pwdSigns.length];
    const signPos = Math.floor(Math.random() * pasLen);
    let password = '';

    for (let i = 0; i < pasLen; i++) {
      const x = Math.floor(Math.random() * pwdChars.length);

      password += i === signPos ? signChar : pwdChars[x % pwdChars.length];
    }

    return password;
  };

  const formatEmptyString = (value: any) => {
    for (const i in value) {
      if (value[i] === '') value[i] = undefined;
    }
  };

  const handleFinish = async () => {
    try {
      const value = await form?.validateFields();

      formatEmptyString(value);
      const department = [...value?.departmentIds];

      let actionFun = null;

      if (editId) {
        value.id = isEdit ? editId : undefined;
      } else {
        value.departmentIds = department.map(({ id }: { id: number }) => ({ value: id }));
      }
      for (const key in value) {
        if (SELECT_KEYS.includes(key) && !gcArray.isEmpty(value[key])) {
          value[key] = value[key].map((i: any) => i.value);
        }
      }
      if (value.superior) {
        value.superior = !gcObject.isEmpty(value.superior) ? value.superior[0] : null;
      }
      if (value.ownerId) {
        value.ownerId = value.ownerId[0];
      }
      // if (value.attachments?.length) {
      //   value.attachments = value.attachments.map(
      //     (attachment: any) => attachment.uri || attachment.url,
      //   );
      // }

      if (value.avatarUrl) {
        value.avatarUrl = value.avatarUrl.uri;
      }

      if (!isEmpty(value.roleIds)) {
        value.roleIds = _.map(value.roleIds, 'value');
      }

      if (value.customFieldItems) {
        value.customFieldItems = Object.keys(value.customFieldItems)
          .filter((item) => value.customFieldItems[item])
          .map((key) => {
            return { id: Number(key), value: value.customFieldItems[key] };
          });
        if (!value.customFieldItems.length) {
          delete value.customFieldItems;
        }
      }

      const newPWD = genPWD();

      const options = formatCustomFieldsInData({ data: value, customFields });

      if (isEdit) {
        actionFun = updateUser(options);
      } else {
        actionFun = createUser(options, newPWD);
      }
      if (actionFun) {
        const res = await actionFun;

        if (res.code === 200) {
          message.success('操作成功');
          if (keepOn) {
            form.resetFields();
          } else {
            showConfirmModal(options, newPWD, isEdit);
          }
        } else {
          message.error(res.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  /** 获取对象自定义字段 */
  // const fetchCustomFields = async () => {
  //   const res = await fetchCustomFieldInstanceGetByObjectCode({
  //     objectCode: OBJECT_OF_CODE.user,
  //   });
  //
  //   setCustomFields((res?.data as any) || {});
  // };

  return (
    <DataFormLayout
      form={form}
      title={isEdit ? '编辑用户' : '新建用户'}
      info={[
        baseInfo,
        injectCustomFieldInfos({
          customFields,
          type: 'form',
          formConfig: { form },
        }),
      ]}
      onFinish={handleFinish}
      onCancel={() => {
        history.go(-1);
      }}
      extra={
        !isEdit ? (
          <Checkbox
            onChange={() => {
              setkeepOn(!keepOn);
            }}
            defaultChecked={false}
          >
            连续新建
          </Checkbox>
        ) : null
      }
    />
  );
};

export default UserCreateAndEdit;
