import { useCallback } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import _ from 'lodash';
import { RecordListLayout } from 'layout';
import { BlIcon } from 'components';
import { fieldTypeList } from 'utils';
import { renderUser } from 'src/page/share/renderUser';
import { fetchReportTemplateList } from 'src/api/ytt/report-domain/reportTemplate';
import { BASIC_PATH } from './constants';
import lookup from 'src/dict';
import { CRUD } from 'src/dict/common';
import { templateType as templateTypeMappings } from 'src/dict/reportTemplate/mappings';
import authDict from 'src/utils/auth';
import { valueOrHolder, formatTimeForRender, formatTimeRangeForQuery } from 'src/utils/formatters';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { ReportTemplateListItem } from './type';

interface Props extends RouteChildrenProps {}

const ReportTemplateList = (props: Props) => {
  const { history } = props;

  const dataColumns = [
    {
      title: '模板名称',
      dataIndex: 'name',
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: '模板类型',
      dataIndex: 'category',
      width: 150,
      render: valueOrHolder(lookup('reportTemplate.templateType')),
      isFilter: true,
      type: fieldTypeList.multiSelect,
      selectProps: { options: templateTypeMappings },
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 150,
      render: renderUser,
      isFilter: true,
      type: fieldTypeList.reference,
      renderItem: <UserOrDepartmentSelectWithDialog isSelectUser isMultiple isNewFormat />,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 180,
      render: formatTimeForRender,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      width: 150,
      render: renderUser,
      isFilter: true,
      type: fieldTypeList.reference,
      renderItem: <UserOrDepartmentSelectWithDialog isSelectUser isMultiple isNewFormat />,
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 180,
      render: formatTimeForRender,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
    },
  ];

  const mainMenu = [
    {
      title: '新建报告模板',
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        history.push(`${BASIC_PATH}/create`);
      },
      auth: authDict.reporttemplate_add,
      items: [],
    },
  ];

  const getOperationList = (record: ReportTemplateListItem) => {
    return [
      {
        title: lookup('crud', CRUD.edit),
        auth: authDict.reporttemplate_edit,
        onClick: () => {
          history.push(`${BASIC_PATH}/${record.id}/edit`);
        },
      },
      {
        title: lookup('crud', CRUD.copy),
        auth: authDict.reporttemplate_add,
        onClick: () => {
          history.push(`${BASIC_PATH}/copy?copyId=${record.id}`);
        },
      },
      // {
      //   title: lookup('crud', CRUD.delete),
      //   auth: authDict.general_report_template_add_edit,
      //   onClick: () => {
      //     BLDelConfirm('确认要删除当前报告模板吗？', delForm, () => handleDelete(record.id));
      //   },
      // },
    ];
  };

  const filterList = dataColumns
    .filter((i) => i.isFilter)
    .map((column) => {
      const filter = {
        label: column.title,
        name: column.dataIndex,
        type: column.type,
        renderItem: column.renderItem,
      } as any;

      if (column.selectProps) {
        filter.selectProps = column.selectProps;
      }

      return filter;
    });

  const formatDataToQuery = useCallback((values: any) => {
    const { quickSearch, name, creator, createdAt, operator, updatedAt, ...rest } = values;
    const submitValue = {
      ...rest,
      quickSearch: quickSearch ?? name,
      creatorId: creator ? _.map(creator, 'id') : undefined,
      createdAt: formatTimeRangeForQuery(createdAt),
      operatorId: operator ? _.map(operator, 'id') : undefined,
      updatedAt: formatTimeRangeForQuery(updatedAt),
    };

    return submitValue;
  }, []);

  return (
    <>
      <RecordListLayout<ReportTemplateListItem>
        columns={dataColumns}
        filterList={filterList}
        rowKey={'id'}
        mainMenu={mainMenu}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={_.identity}
        formatDataToFormDisplay={_.identity}
        configcacheKey={'reportTemplate'}
        placeholder="请输入模板名称"
        requestFn={async (params) => {
          return fetchReportTemplateList(params);
        }}
        getOperationList={getOperationList}
      />
    </>
  );
};

export default ReportTemplateList;
