import { useState } from 'react';
import { message as Message, Modal, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { BlIcon, LinkTooltip, SearchSelect, SingleOperationLogComponent } from 'src/components';
import { RecordListLayout, FilterItem } from 'layout';
import { ShipmentNoteListResponse } from '../interface';
import { OutBoundCreatePath, toCreate, toDetail, toEdit } from '../navigation';
import _ from 'lodash';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import {
  fetchShipmentNoteClose,
  fetchShipmentNoteList,
  FetchShipmentNoteListRequest,
  fetchShipmentNotePlaceGoodsIssue,
} from 'src/api/ytt/order-domain/invoice';
import openFinishInvoiceDialog from '../operate/endOrder';
import { intersectionValueOfId, mapLabeledValueToValue } from 'src/utils/array';
import { fieldTypeList } from 'src/utils';
import { replaceSign } from 'src/utils/constants';
import { inoviceStatus, invoiceOrigin } from 'src/dict/invoice/mappings';
import { renderUser } from 'src/page/share/renderUser';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import lookup from 'src/dict';
import { InvoiceStatus } from 'src/dict/invoice';
import authDict from 'src/utils/auth';
import { CRUD } from 'src/dict/common';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import DeliverGoodsModal from '../operate/delivery/deliverGoodsModal';
import SettingModal from '../components/settingModal';

const InvoiceDocumentsList = () => {
  const history = useHistory();
  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  const [selectedRows, setSelectRows] = useState<any[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [settingModalShow, setSettingModalShow] = useState<boolean>(false); // 协同设置
  // 当前操作行
  const [currentOrder, setCurrentOrder] = useState<ShipmentNoteListResponse>();
  // 发货按钮的弹窗，只有当没有主系统许可项的时候才有
  const [deliverGoodsVisible, setDeliverGoodsVisible] = useState(false);
  /**
   * 导入导出
   */
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  /**
   * 发货操作
   */
  const handleDelivery = (record: ShipmentNoteListResponse) => {
    setCurrentOrder(record);
    setDeliverGoodsVisible(true);
  };
  /**
   * 新建出库单 操作
   */
  const handleCreateOutBound = (list: ShipmentNoteListResponse[]) => {
    const validateStatus = _.find(list, (row) => row.noteStatus !== InvoiceStatus.executing);
    const validateOrigin = _.uniqBy(list, 'origin')?.length > 1;
    const validateCustomer = _.uniqBy(list, (item) => item?.customer?.id)?.length > 1;

    if (validateStatus) {
      message.error('只有执行中的发货单可以新建出库单');
      return;
    }
    if (validateOrigin) {
      message.error('只有相同来源的发货单可以新建出库单');
      return;
    }
    if (validateCustomer) {
      message.error('只有相同客户的发货单可以新建出库单');
      return;
    }

    const origin = _.first(list)?.origin;
    const materialList: any[] = _.flatten(_.map(list, 'items'))?.map((i) => ({ ...i, origin }));
    const filterMaterial = _.filter(materialList, (o) => o?.expectedIssueAmount?.amount > 0);

    if (_.isEmpty(filterMaterial)) return message.error('暂无可出库物料');
    dispatch({
      type: 'supplyChain/formatterOutBoundInfo',
      payload: {
        items: filterMaterial,
        customerId: {
          label: list[0]?.customer?.name,
          value: list[0]?.customer?.id,
        },
      },
    });

    return history.push(OutBoundCreatePath());
  };
  /**
   * 下发操作
   */
  const handleIssuedOrder = async (
    selectRows: ShipmentNoteListResponse[],
    onSuccess?: () => void,
    onFail?: () => void,
  ) => {
    try {
      const propsIds: number[] = _.map(selectRows, 'id');

      const { code } = await fetchShipmentNotePlaceGoodsIssue({ ids: propsIds });

      if (code && code == 200) {
        message.success('下发成功');
        onSuccess?.();
      }
    } catch (error) {
      console.log(error);
      onFail?.();
    }
  };
  /**
   * 关闭
   */
  const handleCloseOrder = async (id: number) => {
    const response = await fetchShipmentNoteClose({ shipmentNoteId: id });

    if (response?.code === 200) {
      Message.success('发货单已关闭');
      refresh();
    } else {
      Message.error(response.message);
    }
  };
  /**
   * 关闭操作
   */
  const handleCloseOrderComfirm = (id: number) => {
    Modal.confirm({
      title: '关闭发货单',
      content: (
        <div>
          确认关闭发货单？
          <br />
          关闭后将无法执行任何操作
        </div>
      ),
      okText: '确认关闭',
      cancelText: '取消',
      onOk: () => handleCloseOrder(id),
    });
  };
  /**
   * 主操作按钮
   */
  const mainMenu = _.compact([
    {
      title: '导出',
      icon: <BlIcon type="icondaochu" />,
      auth: authDict.shipmentsnote_export,
      showExport: true,
      onClick: () =>
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.shipmentNote,
        }),
    },
    {
      title: '新建发货单',
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.shipmentsnote_add,
      onClick: () => {
        history.push(toCreate());
      },
      items: _.compact([
        {
          title: '导入',
          auth: authDict.shipmentsnote_import,
          showExport: true,
          onClick: () =>
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.shipmentNote,
            }),
        },
        {
          title: '协同设置',
          onClick: () => {
            setSettingModalShow(true);
          },
        },
      ]),
    },
  ]);
  /**
   * 批量操作按钮
   */
  const batchMenu = [
    {
      title: '下发',
      auth: authDict.shipmentsnote_dispatch,
      onClick: (onSuccess: any, onFail: any) => {
        handleIssuedOrder(selectedRows, onSuccess, onFail);
      },
    },
    {
      title: '合并新建出库单',
      auth: authDict.outboundorder_add,
      onClick: (onSuccess: any, onFail: any) => {
        onFail();
        handleCreateOutBound(selectedRows);
      },
    },
  ];
  /**
   * 列操作 ...
   */
  const getOperationList = (record: ShipmentNoteListResponse) => {
    return _.compact([
      {
        title: lookup('crud', CRUD.view),
        auth: authDict.shipmentsnote_detail,
        onClick: () => {
          history.push(toDetail(record.id));
        },
      },
      {
        title: lookup('crud', CRUD.edit),
        auth: authDict.shipmentsnote_edit,
        disabled:
          record.noteStatus === InvoiceStatus.finished ||
          record.noteStatus === InvoiceStatus.closed,
        onClick: () => {
          history.push(toEdit(record.id));
        },
      },
      {
        title: lookup('crud', CRUD.issued),
        auth: authDict.shipmentsnote_dispatch,
        disabled: record.noteStatus !== InvoiceStatus.new,
        onClick: () => {
          handleIssuedOrder([record], refresh);
        },
      },
      {
        title: '新建出库单',
        auth: authDict.outboundorder_add,
        disabled: record.noteStatus !== InvoiceStatus.executing,
        onClick: () => {
          handleCreateOutBound([record]);
        },
      },
      {
        title: '发货',
        auth: authDict.shipmentsnote_deliver,
        disabled: record.noteStatus !== InvoiceStatus.executing,
        onClick: () => {
          handleDelivery(record);
        },
      },
      {
        title: '结束',
        auth: authDict.shipmentsnote_finish,
        disabled: record.noteStatus !== InvoiceStatus.executing,
        onClick: () => {
          openFinishInvoiceDialog(record.code!, record.id!, refresh);
        },
      },
      {
        title: '关闭',
        auth: authDict.shipmentsnote_close,
        disabled: record.noteStatus !== InvoiceStatus.new,
        onClick: () => {
          handleCloseOrderComfirm(record.id!);
        },
      },
      // {
      //   title: lookup('crud', CRUD.delete),
      //   onClick: () => {
      //     BLDelConfirm('是否删除当前订单', delForm, () => {
      //       handleFinish(id);
      //     });
      //   },
      // },
      {
        title: '操作记录',
        auth: authDict.shipmentsnote_operrecord,
        onClick: () => {
          setCurrentOrder(record);
          setVisibleLog(true);
        },
      },
    ]);
  };
  /**
   * Columns
   */
  const dataColumns = [
    {
      title: '编号',
      dataIndex: 'code',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.text,
      render: (text: string, record: ShipmentNoteListResponse, index: number, config: any) => {
        return (
          <LinkTooltip
            to={toDetail(record.id)}
            text={text}
            width={config.contentWidth}
            auth={authDict.shipmentsnote_detail}
          />
        );
      },
    },
    {
      title: '来源',
      dataIndex: 'origin',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      render: lookup('invoice.invoiceOrigin'),
      selectProps: {
        options: invoiceOrigin,
        mode: 'multiple',
      },
    },
    {
      title: '协同收货单号',
      dataIndex: 'scmReceiveNoteCode',
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
      render: (scmReceiveNoteCode: string) => scmReceiveNoteCode ?? replaceSign,
    },
    {
      title: '客户编号',
      dataIndex: ['customer', 'code'],
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
      filterName: 'customerCode',
      renderItem: (
        <SearchSelect labelPath="code" fetchType="customer" labelInValue mode="multiple" />
      ),
    },
    {
      title: '客户名称',
      dataIndex: ['customer', 'name'],
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
      filterName: 'customerName',
      renderItem: <SearchSelect fetchType="customer" labelInValue mode="multiple" />,
    },
    {
      title: '状态',
      dataIndex: 'noteStatus',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      render: lookup('invoice.inoviceStatus'),
      selectProps: {
        options: inoviceStatus,
        mode: 'multiple',
      },
    },
    {
      title: '出库单编号',
      dataIndex: 'outboundOrderCode',
      width: 150,
      type: fieldTypeList.text,
      render: (outboundOrderCode: string) => outboundOrderCode ?? replaceSign,
    },
    {
      title: '出库单状态',
      dataIndex: 'outboundOrderStatus',
      width: 150,
      render: lookup('bound.Status'),
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: renderUser,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 200,
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: '编辑人',
      dataIndex: 'operator',
      isFilter: true,
      width: 150,
      type: fieldTypeList.reference,
      render: renderUser,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: '编辑时间',
      dataIndex: 'updatedAt',
      width: 200,
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: '结束人',
      dataIndex: 'finishUser',
      isFilter: true,
      width: 150,
      type: fieldTypeList.reference,
      render: renderUser,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: '结束时间',
      dataIndex: 'finishedAt',
      width: 200,
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
  ];
  /**
   * 传给接口数据处理函数
   */
  const formatDataToQuery = (param: any): FetchShipmentNoteListRequest => {
    const {
      createdAt,
      updatedAt,
      customerCode,
      customerName,
      creator,
      operator,
      finishedAt,
      finishUser,
      ...rest
    } = param;

    // 接口需要的创建时间和更新时间为number型的timestamp
    const dealdParams = {
      createdAtFrom: createdAt && createdAt[0] ? Date.parse(createdAt[0]) : undefined,
      createdAtTo: createdAt && createdAt[1] ? Date.parse(createdAt[1]) : undefined,
      updatedAtFrom: updatedAt && updatedAt[0] ? Date.parse(updatedAt[0]) : undefined,
      updatedAtTo: updatedAt && updatedAt[1] ? Date.parse(updatedAt[1]) : undefined,
      finishedAtFrom: finishedAt && finishedAt[0] ? Date.parse(finishedAt[0]) : undefined,
      finishedAtTo: finishedAt && finishedAt[1] ? Date.parse(finishedAt[1]) : undefined,
      finishUserIds: mapLabeledValueToValue(finishUser),
      customerIds: intersectionValueOfId(customerCode, customerName),
      creatorIds: mapLabeledValueToValue(creator),
      operatorIds: mapLabeledValueToValue(operator),
      ...rest,
    };

    dispatch.excelImport.updateBusinessData({ businessData: dealdParams });
    return dealdParams;
  };
  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (data: any) => {
    const { createdAt, updatedAt, finishedAt, ...rest } = data;

    return {
      createdAt: momentData(createdAt),
      updatedAt: momentData(updatedAt),
      finishedAt: momentData(finishedAt),
      ...rest,
    };
  };
  /**
   * 列表筛选处理函数
   */
  const filterList: FilterItem[] = dataColumns
    .filter((i) => i.isFilter)
    .map((column) => {
      return _.pick(
        {
          ...column,
          label: column.title,
          name: column.filterName ?? column.dataIndex,
          type: column.type,
        },
        [
          'label',
          'name',
          'type',
          'rules',
          'renderItem',
          'selectProps',
          'inputProps',
          'datePickerProps',
          'dateFormat',
        ],
      ) as FilterItem;
    });

  return (
    <>
      <RecordListLayout<ShipmentNoteListResponse>
        columns={dataColumns}
        filterList={filterList}
        rowKey={(record) => record.id}
        batchMenu={batchMenu}
        mainMenu={mainMenu}
        getOperationList={getOperationList}
        placeholder={'请输入发货单编号、销售订单编号'}
        selectedRowKeys={selectedRowKeys}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        configcacheKey="salesOrderDocuments"
        onSelectedRowKeys={(selectKey: any, selectRows?: any[]) => {
          setSelectRowKeys(selectKey);
          setSelectRows(selectRows ?? []);
        }}
        requestFn={fetchShipmentNoteList}
        refreshMarker={refreshMarker}
      />
      {deliverGoodsVisible && (
        <DeliverGoodsModal
          dataSource={currentOrder!}
          visible={deliverGoodsVisible}
          onClose={() => {
            setDeliverGoodsVisible(false);
          }}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={currentOrder?.id!}
          objectCode={OBJECT_OF_CODE.ShipmentsNote}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      {settingModalShow && (
        <SettingModal visible={settingModalShow} onClose={() => setSettingModalShow(false)} />
      )}
    </>
  );
};

export default InvoiceDocumentsList;
