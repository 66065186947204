import { replaceSign } from 'src/utils/constants';
import moment from 'moment';
import _ from 'lodash';
import { Modal } from 'antd';

const renderAmount = (amount: any) => {
  return amount ? amount?.amountDisplay + amount?.unitName : replaceSign;
};

// 处理时间的展示
export const formatTime = (time: number | null | undefined) => {
  if (_.isUndefined(time) || _.isNull(time)) {
    return replaceSign;
  }

  const showTime = moment(time).format('HH:mm:ss');

  return showTime;
};

export const renderTimeUnit = (unit: number) => {
  const obj = ['', '天', '小时', '分钟'];

  return obj[unit];
};

export const formatDetailDataToForm = (values: any, supplier: any) =>
  values?.map((item: any) => {
    return {
      lineNo: String(item?.lineNo),
      materialId: {
        value: JSON.stringify({
          inventoryAmount: item.inventoryAmount,
          purchaseCycle: item.purchaseCycle,
          purchaseInfo: item.material?.purchaseInfo,
          materialId: item.material?.baseInfo?.id,
          unitList: item.material?.unitList,
          baseInfo: item.material?.baseInfo,
        }),
        label: item?.material?.baseInfo?.name,
      },
      deliveryDate: moment(item?.deliveryDate),
      earliestArrivalTime: item?.earliestArrivalTime ? moment(item?.earliestArrivalTime) : '',
      latestArrivalTime: item?.latestArrivalTime ? moment(item?.latestArrivalTime) : '',
      demandAmount: item?.demandAmount?.amount,
      logisticsCycle: item?.logisticsCycle,
      logisticsTimeUnit: item.logisticsTimeUnit,
      id: item?.id,
      supplier,
      purchaseOrderItemId: item.purchaseOrderItemId,
      purchaseOrderCode: item.purchaseOrderCode,
      purchaseOrderItemSeqNum: item.purchaseOrderItemSeqNum,
    };
  });
const handleSwich = (content: string, onOk: () => void, onCancel: () => void) => {
  Modal.confirm({
    title: '提示',
    content,
    onOk,
    onCancel,
    cancelText: '取消',
    okText: '确认',
  });
};

export { renderAmount, handleSwich };
