import { RouteComponentProps } from 'react-router-dom';
import { FilterItem, RecordListLayout } from 'layout';
import { TagList, Tooltip } from 'components';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import _ from 'lodash';
import { gcTime, gcObject, fieldTypeList } from 'utils';
import authDict from 'src/utils/auth';
import { replaceSign } from 'src/utils/constants';
import { TableRowType } from '@blacklake-web/layout/dist/logList/LogListLayout.type';
import _Time from 'src/utils/dataTypes/time';
import { AvatarDisplay } from 'src/components/avatar/show';
import { toDetail, toEdit, toReview } from './navigation';
import { fetchCustomMadeReportQueryCustomMadeReportConfigBackPage } from 'src/api/ytt/e-report-domain/customizedReports';
import { Modify } from './interface';

interface Props extends RouteComponentProps {}

const CustomizedReport = (props: Props) => {
  const { history } = props;

  // 获取定制化报表列表
  const requestFn = async (params: any) => {
    const res = await fetchCustomMadeReportQueryCustomMadeReportConfigBackPage(params);

    return res;
  };

  const dataColumns: any[] = [
    {
      title: '报表名称',
      dataIndex: 'name',
      isFilter: true,
      sorter: true,
      width: 150,
      render: (name: string, record: TableRowType, index: number, config: any) => {
        return (
          <>
            <a
              onClick={() => {
                history.push(toReview(record.id));
              }}
            >
              <Tooltip text={name ?? replaceSign} width={config.contentWidth} />
            </a>
          </>
        );
      },
    },
    {
      title: '查看用户',
      dataIndex: 'allowViewUser',
      type: 'text',
      width: 150,
      render: (text: { name: string; id: number }[]) => {
        if (_.isEmpty(text)) return replaceSign;

        return (
          <TagList
            dataSource={_.map(text, ({ name, id }) => ({
              label: `${name}`,
              value: id,
            }))}
          />
        );
      },
    },
    {
      title: '更新人',
      dataIndex: 'modifier',
      width: 150,
      isFilter: true,
      render: (modifier: Modify) => {
        return (
          <AvatarDisplay
            id={modifier?.id!}
            name={modifier?.name!}
            key={modifier?.id}
            isShowTag={false}
            isUser
          />
        );
      },
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择更新人" isMultiple isSelectUser />
      ),
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 200,
      isFilter: true,
      type: fieldTypeList.date,
      render: (updatedAt: Date) => {
        if (!updatedAt) return replaceSign;
        return _Time.format(updatedAt);
      },
    },
  ];

  const config = {
    rowKey: 'id',
    columns: dataColumns,
    getOperationList: (record: TableRowType) => {
      return [
        {
          title: '查看',
          auth: authDict.Cus_eReport_detail,
          onClick: () => {
            history.push(toDetail(record.id));
          },
        },
        {
          title: '编辑',
          auth: authDict.Cus_eReport_edit,
          onClick: () => {
            history.push(toEdit(record.id));
          },
        },
      ];
    },
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((columns: any): FilterItem => {
        const filter = {
          label: columns.title,
          name: columns.dataIndex,
          ...columns,
        };

        return filter;
      }),

    /**
     * 筛选数据显示处理函数
     */
    formatDataToFormDisplay: (filterData: any) => {
      const { updatedAt, ...rest } = filterData;

      const result = {
        ...rest,
        updatedAt: gcTime.formatRangeTimeToMoment(updatedAt || []),
      };

      return gcObject.filterEmptyProperty(result);
    },

    /**
     * 传给接口数据处理函数
     */
    formatDataToQuery: (param: any) => {
      const { quickSearch, name, updatedAt, modifier, ...rest } = param;
      const [updatedAtStart, updatedAtEnd] = gcTime.formatTimeRangeUnix(updatedAt);

      const options = {
        quickSearch: name || quickSearch,
        updatedAtStart,
        updatedAtEnd,
        operatorId: modifier
          ? modifier.map((item: any) => {
              return item.value;
            })
          : [],
        ...rest,
      };

      const relParams = gcObject.filterEmptyProperty(options);

      return relParams;
    },
  };

  /** 获取对象自定义字段 */
  // const fetchCustomFields = async () => {
  //   const res = await fetchCustomFieldInstanceGetByObjectCode({
  //     objectCode: OBJECT_OF_CODE.inboundOrder,
  //   });

  //   setCustomFields((res?.data as any) || {});
  // };

  // useEffect(() => {
  //   fetchCustomFields();
  // }, []);

  return (
    <>
      <RecordListLayout<TableRowType>
        placeholder={'请输入定制化报表名称'}
        requestFn={requestFn}
        configcacheKey="customizedReport"
        {...config}
      />
    </>
  );
};

export default CustomizedReport;
