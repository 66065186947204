import { request } from 'utils';
import { hashPassword } from 'src/utils/string';

const SERVICE_PREFIX = '/user/domain';
const BASE_URL_DEPARTMENT = `${SERVICE_PREFIX}/web/v1/department`;
const BASE_URL_USER = `${SERVICE_PREFIX}/web/v1/user`;

interface ParamType {
  pageNum: number;
  pageSize: number;
  search?: string;
}

export const getDepartmentByNode = (id?: number) =>
  request.post(`${BASE_URL_DEPARTMENT}/list_child`, { mock: false }, { id });

export const searchByDepartmentOrUser = (name: string) =>
  request.post(`${BASE_URL_DEPARTMENT}/department_or_user_query`, {}, { name });

export const getDepartmentList = () => request.post(`${BASE_URL_DEPARTMENT}/list`, {});

export const getUserList = (params: ParamType, departmentId: number, containChild?: boolean) =>
  request.post(
    `${BASE_URL_USER}/list`,
    { loading: false },
    { ...params, departmentId, containChild },
  );

export const getUserDetail = (userId: number) =>
  request.post(`${BASE_URL_USER}/detail`, { loading: false }, { id: userId });

export const getUserDetailById = (userId: number) =>
  request.post(`${BASE_URL_USER}/getDetailById`, { loading: false }, { id: userId });

export const createUser = (userData: any, originPWD: string) => {
  return hashPassword(originPWD).then((res: any) => {
    userData.password = res.hashHex;
    return request.post(`${BASE_URL_USER}/add`, { loading: false }, userData);
  });
};

export const updateUser = (userData: any) =>
  request.post(`${BASE_URL_USER}/update`, { loading: false }, userData);

export const deleteUser = (id: number) =>
  request.post(`${BASE_URL_USER}/delete`, { loading: false }, { id });

export const resetPassword = (data: any) =>
  request.post(`${BASE_URL_USER}/updatePassword`, { loading: false }, data);

export const bulkUpdateStatus = (data: any) =>
  request.post(`${BASE_URL_USER}/bulkUpdateStatus`, { loading: false }, data);

export const bulkUpdate = (data: any[]) => request.post(`${BASE_URL_USER}/bulkUpdate`, {}, data);

export const getDepartmentDetail = (departmentId: number) =>
  request.post(`${BASE_URL_DEPARTMENT}/detail`, { loading: false }, { id: departmentId });

export const createDepartment = (userData: any) =>
  request.post(`${BASE_URL_DEPARTMENT}/create`, { loading: false }, userData);

export const updateDepartment = (userData: any) =>
  request.post(`${BASE_URL_DEPARTMENT}/update`, { loading: false }, userData);

export const deleteDepartment = (departmentId: number) =>
  request.post(`${BASE_URL_DEPARTMENT}/delete`, { loading: false }, { id: departmentId });

export const getUserLogList = (params: ParamType) =>
  request.post(`${BASE_URL_USER}/logList`, { loading: false, mock: true }, params);

export const getUserByDepartmentId = (departmentId: number) =>
  request.post(`${BASE_URL_DEPARTMENT}/user_list`, { loading: false }, { id: departmentId });

export const getOrgUserCount = () =>
  request.post(`${BASE_URL_USER}/getOrgUserNum`, { loading: false });
