/**
 * @description 用户相关工具方法
 */

import { Tag } from 'antd';
import React, { useState } from 'react';
import { fetchUserGetOrgUserNum } from 'src/api/ytt/user-domain';

interface CustomTagProps {
  label: React.ReactNode;
  value: any;
  disabled: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closable: boolean;
}

export const roleTagRender = (props: CustomTagProps) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
};

interface UserGetOrgUserNumType {
  appFreeNum: number;
  appAndWebFreeNum: number;
  interfaceFreeNum: number;
}

/** 获取租户用户使用数量 */
export const useUserGetOrgUserNum = () => {
  const [orgUserNum, setOrgUserNum] = useState<UserGetOrgUserNumType>({
    appFreeNum: 0,
    appAndWebFreeNum: 0,
    interfaceFreeNum: 0,
  });

  const updateNumber = async () => {
    const res = await fetchUserGetOrgUserNum();

    if (res.code === 200) {
      const { appFreeNum, appAndWebFreeNum, interfaceFreeNum } = res.data as any;

      setOrgUserNum({
        appFreeNum,
        appAndWebFreeNum,
        interfaceFreeNum,
      });
    }
  };

  return { orgUserNum, updateNumber };
};

interface RoleLicense {
  code: string;
  licenseNumLimit: number;
  licenseNumUsed: number;
  name: string;
}

/** 增加角色许可项 */
export const renderLicenses = (roleOwnedLicenses: RoleLicense[]): React.ReactNode => {
  return (
    <ul>
      {roleOwnedLicenses?.map((license) => {
        const { name, code, licenseNumLimit, licenseNumUsed } = license;

        return (
          <li key={code} style={{ color: '#5b5b5b' }}>
            {name} ({licenseNumUsed}/{licenseNumLimit})
          </li>
        );
      })}
    </ul>
  );
};
