import React from 'react';
import { Popover } from 'antd';

import ScheduleShiftPopover from '../ScheduleShiftPopover';
import CalendarTooltip from '../../OperateCalendar/CalendarDisplayPanel/CalendarTooltip';
import { ScheduleTableDateInfoType, ScheduleShiftValueType } from '../../constants';
import styles from './index.module.scss';

interface FlexCalendarTableCellProps {
  dateInfo: ScheduleTableDateInfoType;
  rowKey: number;
  dataIndex: number;
  readOnly?: boolean;
  scheduleShift: (
    selectedValue: ScheduleShiftValueType,
    updatingKey: number,
    dataIndex: number,
    dateInfo: ScheduleTableDateInfoType,
  ) => void;
  changePopoverVisible: (showPopover: boolean, updatingKey: number, dataIndex: number) => void;
}

export default function FlexCalendarTableCell({
  dateInfo,
  rowKey,
  dataIndex,
  readOnly,
  scheduleShift,
  changePopoverVisible,
}: FlexCalendarTableCellProps) {
  // 如果没有dateInfo，该格在工作日历的日期范围外，需要disable
  if (!dateInfo) {
    return <div className={[styles.scheduleCell, styles.disabledCell].join(' ')} />;
  }
  const { shiftDetail, showPopover } = dateInfo;

  // 否则根据shiftDetail判断是否已经有过排班信息
  if (shiftDetail) {
    // 如果有，需要将排班信息画在单元格里，并且将这个信息传递给排班浮层的content，回填里面的数据
    return (
      <Popover
        content={
          readOnly ? (
            <CalendarTooltip dataSource={dateInfo} readOnly />
          ) : (
            <ScheduleShiftPopover
              onChange={(selectedValue) => {
                scheduleShift(selectedValue, rowKey, dataIndex, dateInfo);
              }}
            />
          )
        }
        placement="bottomLeft"
        arrowPointAtCenter
        autoAdjustOverflow
        destroyTooltipOnHide={{ keepParent: false }}
        align={{ offset: [-20, -5] }}
        getPopupContainer={(triggerNode) => triggerNode}
        // 为了做到选则班次/排班规则以后自动关闭浮层，必须手动控制它的visible
        visible={showPopover}
        onVisibleChange={(popoverVisible) => {
          changePopoverVisible(popoverVisible, rowKey, dataIndex);
        }}
        trigger="click"
      >
        <div
          className={styles.scheduleCell}
          style={{ backgroundColor: shiftDetail.color ?? 'white' }}
        >
          <div className={styles.shiftName}>{shiftDetail.name}</div>
        </div>
      </Popover>
    );
  } else if (!readOnly) {
    // 否则返回一个有排班浮层的空格
    return (
      <Popover
        content={
          <ScheduleShiftPopover
            onChange={(selectedValue) => {
              scheduleShift(selectedValue, rowKey, dataIndex, dateInfo);
            }}
          />
        }
        placement="bottomLeft"
        arrowPointAtCenter
        autoAdjustOverflow
        destroyTooltipOnHide={{ keepParent: false }}
        align={{ offset: [-20, -5] }}
        getPopupContainer={(triggerNode) => triggerNode.parentElement || triggerNode}
        visible={showPopover}
        onVisibleChange={(popoverVisible) => {
          changePopoverVisible(popoverVisible, rowKey, dataIndex);
        }}
        trigger="click"
      >
        <div className={styles.scheduleCell} />
      </Popover>
    );
  }
  return <div />;
}
