export const BASE_URL = '/quality/qualityModeling/checkItemList';

export const toCheckItemDetail = (id: number, layoutId: number) => {
  return `${BASE_URL}/${id}/detail?layoutId=${layoutId}`;
};
export const toCheckItemEdit = (id: number, layoutId: number) => {
  return `${BASE_URL}/${id}/edit?layoutId=${layoutId}`;
};
export const toCheckItemCreate = (layoutId: number) => {
  return `${BASE_URL}/create?layoutId=${layoutId}`;
};

export const toCheckItemList = () => {
  return `${BASE_URL}`;
};
