import { FitRangeType } from 'src/dict/workCalendar';
import FitRangePanel from '../OperateCalendar/FitRangePanel';
import CalendarDisplayPanel from '../OperateCalendar/CalendarDisplayPanel';
import { CalendarDataType, FitRangeDataProps } from '../constants';
import styles from '../OperateCalendar/index.module.scss';

interface FixCalendarProps {
  calendarData: CalendarDataType;
}

/**
 * 日历详情-固定班制-日历部分的组件
 * 特殊日不在这里，因为特殊日设计上属于另一个info block
 */
export default function FixCalendar({ calendarData }: FixCalendarProps) {
  const fitRangeDataTemp: FitRangeDataProps = {
    [FitRangeType.users]: [],
    [FitRangeType.departments]: [],
    [FitRangeType.equipments]: [],
    [FitRangeType.workCenters]: [],
  };

  const fitRanges = calendarData?.constantVo.fitRanges;

  // 把从后台取到的工作日历的fitRanges字段map到适合FitRangePanel组件渲染的数据类型
  fitRanges?.forEach((fitRangeItem) => {
    fitRangeDataTemp[fitRangeItem.businessType as FitRangeType]?.push(fitRangeItem);
  });

  return (
    <div className={styles.fitRangeAndCalendar}>
      <FitRangePanel
        className={styles.fitRangePanelForFixCalendar}
        value={fitRangeDataTemp}
        readOnly
      />
      <CalendarDisplayPanel className={styles.calendarDisplayPanel} calendarData={calendarData} />
    </div>
  );
}
