import { map } from 'lodash';
import { FieldType } from 'src/dict/customField';
import _Array from 'src/utils/dataTypes/array';
import _Time from 'src/utils/dataTypes/time';
import styles from './styles.module.scss';

interface PreviewData {
  nextTypeDisplayName: string;
  value: string;
  nextFloorCondition: PreviewData[];
  fieldFilterInfo: {
    condition: string;
    conditionDisplayName: string;
    fieldDisplayName: string;
    fieldId: number;
    fieldName: string;
    objectCode: string;
    objectId: number;
    objectName: string;
    value: string;
    valueDisplay: string;
    fieldType: number;
  };
}
interface Props {
  previewFieldData: PreviewData;
}

const renderValueDisplay = (node: any) => {
  const { valueDisplay, value, fieldType, condition } = node;

  let display = valueDisplay || value;

  if (fieldType === FieldType.date) {
    display = value ? _Time.format(Number(value)) : '';
  }

  if (fieldType === FieldType.multiSelect) {
    display = map(JSON.parse(valueDisplay), 'label').join(',');
  }

  if (condition === 'NOT IN') {
    display = map(JSON.parse(valueDisplay), 'label').join(',');
  }

  if (condition === 'IS NULL' || condition === 'IS NOT NULL') {
    display = '';
  }

  return display;
};

const Container = ({ previewFieldData }: Props) => {
  const renderRight = () => {
    const data = previewFieldData?.nextFloorCondition;

    return data?.map?.((node) => {
      if (node?.fieldFilterInfo && _Array.isEmpty(node?.nextFloorCondition)) {
        return (
          <div>
            {`${node?.fieldFilterInfo?.fieldDisplayName} ${
              node?.fieldFilterInfo?.conditionDisplayName
            } ${renderValueDisplay(node?.fieldFilterInfo)}`}
          </div>
        );
      }
      return <Container key={Math.random()} previewFieldData={node} />;
    });
  };

  return (
    <div className={styles?.filterContent} id="filterContent">
      <div className={styles?.filterType}>
        <div className={styles?.filterLine}> </div>
        {previewFieldData.nextTypeDisplayName}
        <div className={styles?.filterLine}> </div>
      </div>
      <div className={styles?.filterRightContent} style={{ padding: 0 }} id="filterRightContent">
        <div className="nextFloorCondition">{renderRight()}</div>
      </div>
    </div>
  );
};

export default Container;
