import React, { useState } from 'react';
import { FilterItem, RecordListLayout } from 'layout';
import { Badge, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { fetchEReportQueryMainTemplatePage } from 'src/api/ytt/e-report-domain/customReport';
import { BlIcon, SearchSelect, SingleOperationLogComponent, Tooltip } from 'src/components';
import { AvatarDisplay } from 'src/components/avatar/show';
import { CreatorData } from 'src/page/knowledgeManagement/calendarManagement/shift/interface';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { fieldTypeList } from 'src/utils';
import { map } from 'lodash';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import {
  APPROVE_MAP,
  APPROVE_TYPE,
  STATUS_ENUM,
  STATUS_ENUM_MAP,
  TEMPLATE_ENUM,
  TEMPLATE_TYPE_MAP,
} from './constant';
import { TableRowType } from './interface';
import {
  toElectronicTemplateCreate,
  toElectronicTemplateDetail,
  toElectronicTemplateEdit,
} from './navigation';
import _Array from 'src/utils/dataTypes/array';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import _String from 'src/utils/dataTypes/string';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';

export default function ElectronicTemplateMainList() {
  const history = useHistory();
  const [visibleLog, setVisibleLog] = useState(false);
  const [detailId, setDetailId] = useState<any>(); // 详情id

  const hasAuthRes = hasAuth(authDict.ereport_detail);

  const dataColumns = [
    {
      title: '模板编号',
      dataIndex: 'code',
      width: 150,
      sorter: true,
      render: (code: string, record: TableRowType, index: number, config: any) => {
        if (!code) return replaceSign;
        return (
          <>
            {hasAuthRes ? (
              <a
                onClick={() => {
                  history.push(toElectronicTemplateDetail(record?.id));
                }}
              >
                <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
              </a>
            ) : (
              <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },
    {
      title: '模板名称',
      dataIndex: 'name',
      width: 200,
      render: (name: string) => name || replaceSign,
    },
    {
      title: '模板类别',
      dataIndex: 'type',
      width: 150,
      sorter: true,
      render: (type: number) => TEMPLATE_TYPE_MAP.get(type),
    },
    {
      title: '启用状态',
      dataIndex: 'status',
      width: 150,
      sorter: true,
      render: (status: number) => {
        const useStatusMap = new Map([
          [STATUS_ENUM.ENABLED, <Badge text="启用中" status="success" />],
          [STATUS_ENUM.DISABLED, <Badge text="停用中" status="error" />],
        ]);

        return useStatusMap.get(status);
      },
    },
    {
      title: '是否需要审批',
      dataIndex: 'approval',
      width: 150,
      render: (approval: number) => APPROVE_TYPE.get(approval),
    },
    {
      title: '审批方案',
      dataIndex: 'approvalPlanName',
      width: 150,
      render: (approvalPlanName: string) => approvalPlanName ?? replaceSign,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 120,
      render: (creator: CreatorData) => {
        return (
          <AvatarDisplay
            id={creator?.id}
            name={creator?.name}
            avatarUrl={creator?.avatarUrl}
            key={creator?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 200,
      sorter: true,
      render: (createAt: Date) => {
        if (!createAt) return replaceSign;
        return _Time.format(createAt);
      },
    },
    {
      title: '更新人',
      dataIndex: 'modifier',
      width: 150,
      defaultColConfig: {
        display: false,
      },
      render: (modifier: CreatorData) => {
        return (
          <AvatarDisplay
            id={modifier?.id}
            name={modifier?.name}
            avatarUrl={modifier?.avatarUrl}
            key={modifier?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 200,
      sorter: true,
      defaultColConfig: {
        display: false,
      },
      render: (updateAt: Date, record: TableRowType) => {
        if (!updateAt) return _Time.format(record?.createAt) ?? replaceSign;
        return _Time.format(updateAt);
      },
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '模板编号',
      name: 'code',
      type: fieldTypeList.text,
    },
    {
      label: '模板名称',
      name: 'name',
      type: fieldTypeList.text,
    },
    {
      label: '模板类别',
      name: 'type',
      type: fieldTypeList.select,
      selectProps: {
        options: TEMPLATE_ENUM.map((node: any) => {
          return {
            label: node?.label,
            value: node?.key,
          };
        }),
      },
    },
    {
      label: '启用状态',
      name: 'status',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <Select
          mode="multiple"
          placeholder={'请选择'}
          options={map(STATUS_ENUM_MAP, (item: any) => {
            return {
              label: item?.name,
              value: item?.id,
            };
          })}
        />
      ),
    },
    {
      label: '是否需要审批',
      name: 'approval',
      type: fieldTypeList.select,
      renderItem: <Select placeholder={'请选择'} options={APPROVE_MAP} />,
    },
    {
      label: '审批方案',
      name: 'approvalPlanIdList',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect fetchType={'approvePlan'} mode="multiple" params={{ status: [1] }} />
      ),
    },
    {
      label: '创建人',
      name: 'creatorId',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
    {
      label: '创建时间',
      name: 'createdAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '更新人',
      name: 'operatorId',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
    {
      label: '更新时间',
      name: 'updatedAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
  ];

  const mainMenu = [
    {
      title: '新建电子单据模板',
      auth: authDict.ereport_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        history.push(toElectronicTemplateCreate());
      },
      items: [],
    },
  ];

  const formatDataToQuery = (params: any) => {
    const { code, name, operatorId, creatorId, createdAt, updatedAt, approvalPlanIdList, ...rest } =
      params;

    const relParams = {
      ...rest,
    };

    if (!_String.isEmpty(code)) {
      relParams.code = code;
    }

    if (!_String.isEmpty(name)) {
      relParams.name = name;
    }

    if (createdAt) {
      relParams.createdAtStart = Number(_Time.formatToUnix(createdAt[0]));
      relParams.createdAtEnd = Number(_Time.formatToUnix(createdAt[1]));
    }
    if (updatedAt) {
      relParams.updatedAtStart = Number(_Time.formatToUnix(updatedAt[0]));
      relParams.updatedAtEnd = Number(_Time.formatToUnix(updatedAt[1]));
    }

    if (!_Array.isEmpty(creatorId)) {
      relParams.creatorId = map(creatorId, 'value');
    }
    if (!_Array.isEmpty(operatorId)) {
      relParams.operatorId = map(operatorId, 'value');
    }
    if (!_Array.isEmpty(approvalPlanIdList)) {
      relParams.approvalPlanIdList = map(approvalPlanIdList, 'value');
    }

    return relParams;
  };

  const formatDataToFormDisplay = (data: any) => {
    const { createdAt, updatedAt } = data;

    const retData = { ...data };

    if (createdAt) {
      retData.createdAt = momentData(createdAt);
    }
    if (updatedAt) {
      retData.updatedAt = momentData(updatedAt);
    }

    return retData;
  };

  const config = {
    rowKey: (record: TableRowType) => String(record.id),
    columns: dataColumns,
    getOperationList: (record: TableRowType) => {
      return [
        {
          title: '查看',
          auth: authDict.ereport_detail,
          onClick: () => {
            history.push(toElectronicTemplateDetail(record?.id));
          },
        },
        {
          title: '编辑',
          auth: authDict.ereport_edit,
          onClick: () => history.push(toElectronicTemplateEdit(record?.id, 'list')),
        },
        {
          title: '操作记录',
          auth: authDict.ereport_operrecord,
          onClick: () => {
            setDetailId(record?.id);
            setVisibleLog(true);
          },
        },
      ];
    },
    mainMenu,
    filterList,
    formatDataToQuery,
    formatDataToFormDisplay,
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        userAuth={getAuthFromLocalStorage()}
        placeholder="请输入模板编号或模板名称"
        configcacheKey="electronicTemplateMainList" // 储存列配置
        requestFn={fetchEReportQueryMainTemplatePage}
        {...config}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={'eReport'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
}
