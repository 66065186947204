import { createContext } from 'react';

export const CAPTCHA_EXPIRED_FLAG = 'CAPTCHA_EXPIRED_FLAG'; // 验证码失效

// 账号类型
export const ACCOUNT_TYPE = new Map([
  [0, '默认'],
  [1, 'app'],
  [2, 'web+app'],
  [3, 'tv'],
]);

export const BIND_PHONE = 1; // 绑定手机
export const REPLACE_PHONE = 2; // 替换手机
export const RESET_PASSWORD = 3; // 重置密码
export const BIND_EXISTING_PHONE = 4; // 绑定已存在的手机号

export enum MESSAGE_AUTH_TYPE {
  BIND_PHONE = 1, // 绑定手机
  REPLACE_PHONE = 2, // 替换手机
  RESET_PASSWORD = 3, // 重置密码
  BIND_EXISTING_PHONE = 4, // 绑定已存在的手机号
}

export interface UserInfoContextType {
  dataSource: any;
  refresh: () => void;
  refreshMarker: Number;
}

export const UserInfoContext = createContext<UserInfoContextType>({
  dataSource: {} as any,
  refresh: () => {},
  refreshMarker: 0,
});
