import React from 'react';
import { Form, FormInstance } from 'antd';
import { BatchOperateTableHeader, BlSortFormList, SearchSelect, TagList } from 'components';
import { TextToolTip } from '@blacklake-web/component';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { appEnum } from 'src/dict';
import { getMaterialAttrs } from 'src/entity/material';
import _ from 'lodash';
import { NamePath } from 'antd/lib/form/interface';
import { MaterialEntity } from 'src/entity/material/index.type';
import { fetchBomList } from 'src/api/ytt/med-domain/bom';
import MaterialSelect from 'src/components/searchSelect/material';
import { replaceSign } from 'src/utils/constants';

interface Props {
  form: FormInstance;
  name: string;
  hasBomIdWhenInit: boolean;
}

const SuitedBomFormTable = ({ form, name, hasBomIdWhenInit }: Props) => {
  const getMaterialInfo = (namepath: NamePath, subField?: string) => {
    const materialInfo = JSON.parse(form.getFieldValue(namepath)?.value ?? '{}');

    if (subField) {
      return materialInfo[subField];
    }
    return materialInfo;
  };
  const getDefaultVersionWithMaterial = async (material: MaterialEntity) => {
    try {
      const materialId = _.get(material, 'baseInfo.id', undefined);

      if (_.isNil(materialId)) return undefined;

      const res = await fetchBomList({ materialIds: [materialId] });

      const defaultVersion = _.head(
        _.filter(_.get(res, 'data.list', []), {
          defaultVersion: appEnum.Common.YN.yes,
          active: appEnum.Common.UsageStatus.enabled,
        }),
      );

      if (_.isEmpty(defaultVersion)) return undefined;

      return { label: defaultVersion.version, value: defaultVersion.id };
    } catch (error) {
      return undefined;
    }
  };
  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: <BatchOperateTableHeader titleText="父项物料编号" required />,
        dataIndex: 'materialId',
        width: 150,
        render: (text, record, index) => {
          const onMaterialChange = async (materialInfo: any) => {
            const version = await getDefaultVersionWithMaterial(
              JSON.parse(materialInfo?.value || '{}'),
            );
            const formList = _.cloneDeep(form.getFieldValue(name));

            formList[index].version = version && {
              value: version?.value,
              label: version?.label,
            };
            form.setFieldsValue({
              [name]: formList,
            });
          };

          return (
            <div>
              <Form.Item
                style={{ marginBottom: '0' }}
                name={[index, 'materialId']}
                rules={[{ required: true, message: '父项物料不能为空' }]}
              >
                <MaterialSelect
                  onChange={onMaterialChange}
                  params={{
                    enableFlag: appEnum.Common.UsageStatus.enabled,
                    bizType: [appEnum.Material.BizType.default],
                  }}
                />
              </Form.Item>
            </div>
          );
        },
      },
      {
        title: <BatchOperateTableHeader titleText="版本号" required />,
        dataIndex: 'version',
        width: 100,
        render: (text, record, index) => {
          return (
            <Form.Item noStyle dependencies={[[name, index, 'materialId']]}>
              {() => {
                const id = getMaterialInfo([name, index, 'materialId'])?.baseInfo?.id;

                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[record.fieldKey, 'version']}
                    name={[record.name, 'version']}
                    rules={[{ required: true, message: '版本号不能为空' }]}
                  >
                    <SearchSelect
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      fetchType="bomVersions"
                      params={{
                        materialIds: _.compact([id]),
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '物料名称',
        dataIndex: 'materialName',
        width: 200,
        render: (text, record, index) => {
          return (
            <Form.Item dependencies={[[name, index, 'materialId']]} noStyle>
              {() => getMaterialInfo([name, index, 'materialId'], 'baseInfo')?.name ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料属性',
        dataIndex: 'attributes',
        width: 200,
        render: (text, record, index) => {
          return (
            <Form.Item dependencies={[[name, record, 'materialId']]} noStyle>
              {() => (
                <TagList
                  dataSource={getMaterialAttrs(getMaterialInfo([name, index, 'materialId']))}
                />
              )}
            </Form.Item>
          );
        },
      },
      {
        title: '物料分类',
        dataIndex: 'category',
        width: 150,
        render: (text, record, index) => {
          return (
            <Form.Item dependencies={[[name, index, 'materialId']]} noStyle>
              {() => getMaterialInfo([name, index, 'materialId'])?.category?.name ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料规格',
        dataIndex: 'specification',
        width: 150,
        render: (text, record, index) => {
          return (
            <Form.Item dependencies={[[name, index, 'materialId']]} noStyle>
              {() => (
                <TextToolTip
                  text={
                    getMaterialInfo([name, index, 'materialId'])?.baseInfo?.specification ||
                    replaceSign
                  }
                  width={130}
                />
              )}
            </Form.Item>
          );
        },
      },
    ];
  };

  return (
    <BlSortFormList
      name={name}
      renderColumns={() => getColumns()}
      initLineCount={hasBomIdWhenInit ? undefined : 1}
      isNeedDrag={false}
      isNeedDelete={false}
      isNeedAdd={false}
    />
  );
};

export default SuitedBomFormTable;
