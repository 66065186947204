import React, { ReactNode } from 'react';
import { PageHeader } from 'antd';
import styles from './styles.module.scss';

interface Props {
  title: string;
  renderContent: ReactNode;
}

export default function UseLayout({ title, renderContent }: Props) {
  return (
    <div className={styles?.box}>
      <PageHeader title={title} className={styles?.bindHeader} />
      <div className={styles?.content}>{renderContent}</div>
    </div>
  );
}
