const CHINESE_ENGLISH_NUMBER_REG = /^[a-zA-Z0-9\u4e00-\u9fa5]+$/; // 中文，英文和数字
export const CHINESE_ENGLISH_NUMBER_DASH_REG = /^[a-zA-Z0-9\u4e00-\u9fa5\-]+$/; // 中文，英文，数字和短横
const ENGLISH_NUMBER_REG = /^[a-zA-Z0-9]+$/; // 英文和数字
const CHINESE_REG = /[^\u4e00-\u9fa5]/; // 中文
const PASSWORD_REG = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[^]{8,}$/; // 用户密码最低强度(必须包含数字，大小写英文字母,8位长度)
const URL_REG = /(http|https):\/\/([\w.]+\/?)\S*/;
const IP_ADDRESS_REG =
  /^(http|https):\/\/(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/; // ip地址
const QR_CODE_REG = /^[a-zA-Z0-9-\u4e00-\u9fa5]+$/; // 二维码
const TEL_REG = /^(([0+]\d{2,3}-)?(0\d{2,3})-)?(\d{7,8})(-(\d{3,}))?$/;

const regList = {
  CHINESE_ENGLISH_NUMBER_REG,
  ENGLISH_NUMBER_REG,
  CHINESE_REG,
  PASSWORD_REG,
  URL_REG,
  IP_ADDRESS_REG,
  QR_CODE_REG,
  TEL_REG,
};
export const CHINESE_ENGLISH_NUMBER_SPECIAL_REG =
  /^[a-zA-Z0-9\u4e00-\u9fa5`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥¥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、Ω≈ç√˜µ≤≥÷…˚æ“‘«”「」’\s]*$/;
export default regList;
