import { RouteProp } from 'src/route';
import SopOperationLog from 'src/page/sop/scheme/operationLog';
import SOPList from 'src/page/sop/scheme/list';
import SOPDetail from 'src/page/sop/scheme/detail';
import EditSOP from 'src/page/sop/scheme/edit';
import EditSOPStep from 'src/page/sop/scheme/step';
import SOPTaskList from 'src/page/sop/task/list';
import SOPTaskDetail from 'src/page/sop/task/detail';
import SOPTaskExecRecord from 'src/page/sop/task/execRecord';
import SopTaskOperationLog from 'src/page/sop/task/operationLog/list';
import EditSopTask from 'src/page/sop/task/edit';
import authDict, { hasAuth } from 'src/utils/auth';
import { schemePath } from './path';

export const sopRouteConfig: RouteProp[] = [
  {
    path: '/sop',
    menu: 'SOP',
    icon: 'iconyijicaidan-SOP',
    breadcrumb: 'SOP',
    children: [
      {
        path: '/sop/exec',
        menu: 'SOP执行',
        breadcrumb: 'SOP执行',
        children: [
          {
            path: '/sop/exec/task',
            auth: authDict.soptask_view,
            component: SOPTaskList,
            menu: 'SOP任务',
            breadcrumb: 'SOP任务',
            children: [
              {
                path: '/sop/exec/task/:sopTaskId/detail',
                component: SOPTaskDetail,
                auth: authDict.soptask_detail,
                breadcrumb: 'SOP任务详情',
                children: [
                  {
                    path: '/sop/exec/task/:sopTaskId/detail/execRecord',
                    auth: authDict.soptask_exec_record,
                    component: SOPTaskExecRecord,
                    breadcrumb: 'SOP任务执行记录',
                  },
                ],
              },
              {
                path: '/sop/exec/task/create',
                auth: authDict.soptask_add,
                component: EditSopTask,
                breadcrumb: '新建SOP任务',
              },
              {
                path: '/sop/exec/task/:sopTaskId/edit',
                auth: authDict.soptask_edit,
                component: EditSopTask,
                breadcrumb: '编辑SOP任务',
              },
              {
                path: ['/sop/exec/task/:id/detail/operationLog', '/sop/exec/task/:id/operationLog'],
                auth: authDict.soptask_operrecord,
                component: SopTaskOperationLog,
                breadcrumb: 'SOP任务操作日志',
              },
            ],
          },
        ],
      },
      {
        path: '/sop/processEngine',
        menu: 'SOP主数据',
        breadcrumb: 'SOP主数据',
        children: [
          {
            path: schemePath.list,
            auth: authDict.sopscheme_view,
            component: SOPList,
            menu: 'SOP方案',
            breadcrumb: 'SOP方案',
            children: [
              {
                path: schemePath.detail,
                auth: authDict.sopscheme_detail,
                component: SOPDetail,
                breadcrumb: 'SOP方案详情',
              },
              {
                path: schemePath.create,
                auth: authDict.sopscheme_add,
                component: EditSOP,
                breadcrumb: '新建SOP方案',
              },
              {
                path: schemePath.edit,
                auth: authDict.sopscheme_edit,
                component: EditSOP,
                breadcrumb: '编辑SOP方案',
              },
              {
                path: [
                  '/sop/processEngine/scheme/:id/detail/operationLog',
                  '/sop/processEngine/scheme/:id/operationLog',
                ],
                auth: authDict.sopscheme_operrecord, // 操作日志权限?
                component: SopOperationLog,
                breadcrumb: 'SOP方案操作日志',
              },
              {
                path: '/sop/processEngine/scheme/:sopId/edit/step/create',
                auth: authDict.sopscheme_step_add,
                component: EditSOPStep,
                breadcrumb: '新建SOP步骤',
              },
              {
                path: '/sop/processEngine/scheme/:sopId/edit/step/:stepId/edit',
                auth: authDict.sopscheme_step_edit,
                component: EditSOPStep,
                breadcrumb: '编辑SOP步骤',
              },
              {
                path: '/sop/processEngine/scheme/:sopId/edit/step/edit',
                auth: () =>
                  hasAuth(authDict.sopscheme_step_add) && hasAuth(authDict.sopscheme_step_edit),
                component: EditSOPStep,
                breadcrumb: '编辑SOP步骤',
              },
            ],
          },
        ],
      },
    ],
  },
];
