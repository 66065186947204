import styles from './index.module.scss';
import { Button } from 'antd';
import { BlIcon } from 'components';
import React from 'react';

const ChooseFactory = (props: any) => {
  const { orgInfo = [], onSelect = () => {} } = props;

  const factories = React.useMemo(() => {
    return orgInfo.map((info: any) => {
      return (
        <section className={styles.wrap} key={info?.code}>
          <div className={styles.info}>
            <div className={styles.icon}>
              <BlIcon type="icongongchang" style={{ fontSize: 40 }} />
            </div>
            <div>
              <div className={styles.title}>{info.organizationName}</div>
              <div className={styles.desc}>{info.code}</div>
            </div>
          </div>
          <Button type="primary" onClick={() => onSelect(info)}>
            进入
          </Button>
        </section>
      );
    });
  }, []);

  return <div>{factories}</div>;
};

export default ChooseFactory;
