import React, { useState, useEffect } from 'react';
import { DataFormLayoutForModal, DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Form, Select } from 'antd';
import { dealWithPeriodOfTime } from '../../shift/utils';
import { ShiftId, ShiftInfoDetailVOSType } from '../interface';
import { fetchShiftList } from 'src/api/ytt/calendar-domain';

const { Option } = Select;

interface createProps {
  visible: Boolean;
  batchValue: ShiftId;
  onCancel: () => void;
  batchFinish: (value: ShiftId) => void;
}

const BatchSetCycleShiftModal = (props: createProps) => {
  const { visible, onCancel, batchFinish, batchValue } = props;

  const [modalForm] = Form.useForm();

  const [shiftSelectData, setShiftSelectData] = useState([] as any);

  const handleSearch = async (value?: string) => {
    try {
      const { data } = await fetchShiftList({
        shiftWebListBaseCO: { page: 1, size: 50, quickQuery: value ?? '' },
      });

      setShiftSelectData(data?.list);
    } catch (error) {
      console.log(error);
    }
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    items: [
      {
        label: '周期班次',
        name: 'shiftId',
        rules: [{ required: true, message: '班次必选' }],
        render: () => (
          <Select
            labelInValue
            showSearch
            onFocus={() => {
              handleSearch();
            }}
          >
            {shiftSelectData?.map((node: any) => {
              const { shiftInfoDetailVOS, name } = node;

              const formatTime = dealWithPeriodOfTime(shiftInfoDetailVOS)
                ?.map((element: ShiftInfoDetailVOSType) => {
                  return `${element?.startTime} - ${element?.endTime}`;
                })
                .join('；');

              return <Option key={node?.id} value={node?.id}>{`${name}(${formatTime})`}</Option>;
            })}
          </Select>
        ),
      },
    ],
  };

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();

      batchFinish(value?.shiftId);
      typeof onCancel === 'function' && onCancel();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (batchValue) {
      // 代表通过批量设置过了
      modalForm.setFieldsValue({ shiftId: batchValue });
    }
  }, [batchValue]);

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      onClose={onCancel}
      width={800}
      content={
        <DataFormLayout
          form={modalForm}
          title="批量设置"
          info={[baseInfo]}
          onCancel={onCancel}
          onFinish={handleFinish}
        />
      }
    />
  );
};

export default BatchSetCycleShiftModal;
