/**
 * 附件 控件属性
 */
import { CSSProperties, FC, useContext } from 'react';
import _ from 'lodash';
import { Form, Input, Select, Radio, Checkbox, InputNumber, Space } from 'antd';
import type { FormInstance } from 'antd';
import FileUploaderForm from 'src/components/upload/fileUploaderForm';
import { DefaultLogic, BizProperty, ControlType, FileType, UploadMethod } from 'src/dict/sop';
import {
  uploadable as uploadableMappings,
  uploadMethod as uploadMethodMappings,
  fileType as fileTypeMappings,
  fileDefaultLogic as fileDefaultLogicMappings,
  fileBizProperty as fileBizPropertyMappings,
} from 'src/dict/sop/mappings';
import type { StepDetailData, LocalControlItem } from '../../types';
import {
  ControlEditorContext,
  CONTROL_TITLE_LENGTH,
  INPUT_DEFAULT_PLACEHOLDER,
} from '../../constants';
import { getNameFactory, titleRules, splitIntegerIntoBinaryArray, resetUnusedItems } from './share';

interface Props {
  form: FormInstance<StepDetailData>;
  localKey: string;
}

const closeMarginBottom: CSSProperties = {
  marginBottom: 10,
};

const MAX_SHOW_FILE_SIZE = 5;
const fileTypeNameMap = new Map([
  [FileType.document, '文档'],
  [FileType.image, '图片'],
  [FileType.video, '视频'],
  [FileType.audio, '音频'],
  [FileType.compressed, '压缩包'],
]);
const limitMap = new Map([
  [FileType.document, 'document'],
  [FileType.image, 'image'],
  [FileType.video, 'video'],
  [FileType.audio, 'audio'],
  [FileType.compressed, 'compressed'],
]);

const getFileUploaderExtra = (fileTypes: FileType[], maxSize: number) => {
  if (_.isEmpty(fileTypes)) {
    return '无支持上传的文件类型，请检查配置项';
  }
  const allowedTypes = fileTypes.map((type) => fileTypeNameMap.get(type)).join('、');

  return `支持上传${allowedTypes}，单文件不超过${maxSize}M`;
};
const getBinaryArrayValidator = (label: string) => (__: unknown, value: string | undefined) =>
  new Promise((resolve, reject) => {
    if (_.toNumber(value) === 0) {
      reject(`请选择${label}`);
    } else {
      resolve(true);
    }
  });

export const TextInputProperties: FC<Props> = ({ form, localKey }) => {
  const { forceUpdate } = useContext(ControlEditorContext);
  const controlList = form.getFieldValue('controls');

  if (_.isEmpty(controlList)) {
    return null;
  }
  const dataIndex = controlList.findIndex((item: LocalControlItem) => item._localKey === localKey);

  if (dataIndex === -1) {
    return null;
  }
  const getName = getNameFactory(dataIndex);
  const { enableEdit, defaultLogic, enableLimit, fileType, fileSize, uploadMethod } =
    controlList[dataIndex];

  // 当『默认值』选项发生变化时, 联动修改defaultValue的默认值
  const setDefaultValue = (logic: DefaultLogic) => {
    let value: string | number = '';

    if (logic === DefaultLogic.biz) {
      value = BizProperty.processFile;
    }

    form.setFields([
      { name: getName('defaultLogic'), value: logic },
      { name: getName('defaultValue'), value },
    ]);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={() => {
        forceUpdate();
        resetUnusedItems(form, ControlType.file, dataIndex, forceUpdate);
      }}
    >
      <Form.Item label="标题" name={getName('name')} rules={titleRules}>
        <Input max={CONTROL_TITLE_LENGTH} placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>
      <Form.Item label="权限" name={getName('enableEdit')} rules={[{ required: true }]}>
        <Radio.Group options={uploadableMappings} />
      </Form.Item>
      {!enableEdit && (
        <Form.Item
          label="显示内容"
          name={getName('showFileList')}
          rules={[{ required: true, message: '请上传显示内容' }]}
          extra={getFileUploaderExtra(
            [
              FileType.document,
              FileType.image,
              FileType.video,
              FileType.audio,
              FileType.compressed,
            ],
            MAX_SHOW_FILE_SIZE,
          )}
        >
          <FileUploaderForm
            limit={['document', 'image', 'video', 'audio', 'compressed']}
            maxCount={20}
            maxSize={MAX_SHOW_FILE_SIZE}
          />
        </Form.Item>
      )}
      {enableEdit && (
        <>
          <Form.Item
            label="上传方式"
            name={getName('uploadMethod')}
            rules={[{ validator: getBinaryArrayValidator('上传方式') }]}
            getValueProps={(value) => ({ value: splitIntegerIntoBinaryArray(value) })}
            getValueFromEvent={_.sum}
          >
            <Select
              options={uploadMethodMappings}
              mode="multiple"
              allowClear
              onChange={forceUpdate}
            />
          </Form.Item>
          {splitIntegerIntoBinaryArray(uploadMethod).includes(UploadMethod.localFile) && (
            <Form.Item
              label="本地上传文件类型"
              name={getName('fileType')}
              rules={[{ validator: getBinaryArrayValidator('本地上传文件类型') }]}
              getValueProps={(value) => ({ value: splitIntegerIntoBinaryArray(value) })}
              getValueFromEvent={_.sum}
            >
              <Select
                options={fileTypeMappings}
                mode="multiple"
                allowClear
                onChange={forceUpdate}
              />
            </Form.Item>
          )}
          <Form.Item label="上传文件最大体积" style={{ marginBottom: 0 }}>
            <Space>
              <Form.Item
                noStyle
                name={getName('fileSize')}
                rules={[
                  { required: true, message: '请输入上传文件最大体积' },
                  { type: 'number', max: 200, message: '最大值200' },
                ]}
              >
                <InputNumber
                  min={1}
                  style={{ width: '100%' }}
                  precision={0}
                  onChange={forceUpdate}
                />
              </Form.Item>
              <span>MB</span>
            </Space>
          </Form.Item>
          <Form.Item name={getName('enableLimit')} valuePropName="checked">
            <Checkbox>仅允许上传单个文件</Checkbox>
          </Form.Item>
        </>
      )}
      {enableEdit && (
        <Form.Item
          label="默认值"
          name={getName('defaultLogic')}
          rules={[{ required: true, message: '请选择默认值' }]}
          style={
            defaultLogic === DefaultLogic.biz || defaultLogic === DefaultLogic.fixed
              ? closeMarginBottom
              : undefined
          }
        >
          <Select options={fileDefaultLogicMappings} onChange={setDefaultValue} />
        </Form.Item>
      )}
      {enableEdit && defaultLogic === DefaultLogic.fixed && (
        <Form.Item
          name={getName('defaultFileList')}
          rules={[{ required: true, message: '请上传默认文件' }]}
          extra={getFileUploaderExtra(splitIntegerIntoBinaryArray(fileType), fileSize)}
        >
          <FileUploaderForm
            limit={splitIntegerIntoBinaryArray(fileType).map((t) => limitMap.get(t)) as any[]}
            maxCount={enableLimit ? 1 : undefined}
            maxSize={fileSize}
          />
        </Form.Item>
      )}
      {enableEdit && defaultLogic === DefaultLogic.biz && (
        <Form.Item
          name={getName('defaultValue')}
          rules={[{ required: true, message: '请选择默认值' }]}
        >
          <Select options={fileBizPropertyMappings} />
        </Form.Item>
      )}
      {enableEdit && (
        <Form.Item label="校验" style={{ marginBottom: 0 }}>
          <Form.Item name={getName('isRequired')} valuePropName="checked">
            <Checkbox>必填</Checkbox>
          </Form.Item>
        </Form.Item>
      )}
      <Form.Item label="描述信息" name={getName('remark')}>
        <Input.TextArea placeholder={INPUT_DEFAULT_PLACEHOLDER} maxLength={1000} showCount />
      </Form.Item>
      {enableEdit && (
        <Form.Item
          label="提示文字"
          name={getName('inputPrompt')}
          rules={[{ max: 20, message: '字符数最大为20' }]}
        >
          <Input allowClear placeholder={INPUT_DEFAULT_PLACEHOLDER} />
        </Form.Item>
      )}
    </Form>
  );
};

export default TextInputProperties;
