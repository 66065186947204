import _, { isEmpty } from 'lodash';
import {
  BizRange_DEFAULT,
  tabType as tabTypeMapping,
  tabCustom as tabCustomMapping,
  bizRange as bizRangeMapping,
} from 'src/dict/material/mappings';
import { BizRange, BizType, UnitType } from 'src/dict/material';
import { warehousingAttrType } from './constant';
import lookup, { appEnum } from 'src/dict';
import { LabeledValue } from 'antd/lib/select';
import { AttributesFrom, IAutoGeneratedMaterialDetailResponse } from './index.d';
import { MaterialCreateUnitForm } from '../material/createAndEdit/index.d';
import { FetchMaterialCreateUnitRequest } from 'src/api/ytt/material-domain/Web_MaterialBaseInfo';
import { ValueType } from 'src/dict/types';
// 处理 物料 - 批次属性 数据结构
export interface originBatchAttrs {
  displayEnable: number;
  name: number; // 经过 legacy: true转换后
  nullEnable: number;
  serialNo: number;
  type: number;
  id?: number;
}
interface sumbitBatchAttrs {
  displayEnable: number;
  name: string;
  nullEnable: number;
  serialNo: number;
  type: number;
  id?: number;
}

// 处理 物料 - 属性 数据结构
interface originAttrs {
  attributeId: {
    value: number;
    label: string;
    key: number;
    relationId?: number;
  };
  attributeItemId: {
    value: number;
    label: string;
    key: number;
  };
}
interface sumbitAttrs {
  id: number;
  name: string;
  itemId: number;
  itemName: string;
  relationId?: number;
}
interface originTransformUnits {
  id: number;
  convertType: number;
  fromUnitCount: number;
  fromUnitId: number;
  relationId: number;
  toUnitCount: number;
  toUnitId: LabeledValue;
  hasStorage?: boolean;
}
interface sumbitTransformUnits {
  id?: number;
  convertType: number;
  fromUnitCount: number;
  relationId: number;
  toUnitCount: number;
  toUnitId: number;
  index: number;
}
export interface OriginWarehousingManagements {
  displayEnable: boolean;
  nullEnable: boolean;
  name: string;
  type: {
    value: number;
    label: string | undefined;
    key: number;
  };
  options?: {
    content: string;
    id?: number;
  }[];
  ifDefault?: number;
  code?: string;
}

export interface SubmitWarehousingManagements {
  /**
   * 属性编号
   */
  code?: string;
  /**
   * 是否显示，1是，0否
   */
  displayEnable: number;
  /**
   * 属性ID
   */
  id?: number;
  /**
   * 是否系统默认，1是，0否
   */
  ifDefault: number;
  /**
   * 顺序
   */
  index: number;
  /**
   * 属性名称
   */
  name: string;
  /**
   * 是否必填，1是，0否
   */
  nullEnable: number;
  /**
   * 属性值
   */
  options?: {
    /**
     * 属性ID
     */
    id?: number;
    /**
     * 顺序
     */
    index: number;
    /**
     * 名称
     */
    name: string;
  }[];
  type: number;
}

export interface responseWarehousingManagements {
  /**
   * 属性编号
   */
  code: string;
  /**
   * 是否显示，1是，0否
   */
  displayEnable: number;
  /**
   * 属性ID
   */
  id: number;
  /**
   * 是否系统默认，1是，0否
   */
  ifDefault: number;
  /**
   * 顺序
   */
  index: number;
  /**
   * 属性名称
   */
  name: string;
  /**
   * 是否必填，1是，0否
   */
  nullEnable: number;
  /**
   * 属性值
   */
  options?: {
    /**
     * ID
     */
    id: number;
    /**
     * 顺序
     */
    index?: number;
    /**
     * 名称
     */
    name: string;
  }[];
  /**
   * 属性类型，0单行文本、1数值、2整数、3日期时间、4单选、5多选
   */
  type?: number;
}
export interface displayWarehousingManagements {
  displayEnable: boolean;
  nullEnable: boolean;
  name: string;
  type: {
    value: number;
    label: string;
    key: number;
  };
  options?: {
    content: string;
    id?: number;
  }[];
  ifDefault?: number;
  code: string;
}
/* ** 物料基本信息  ** */
// 提交数据 数据结构  属性
export const handleFormatAttrs = (attrsList?: originAttrs[]): sumbitAttrs[] => {
  return (attrsList ?? []).map((item, index) => {
    return {
      id: item?.attributeId?.value,
      name: item?.attributeId?.label,
      itemId: item?.attributeItemId?.value,
      itemName: item?.attributeItemId?.label,
      relationId: item?.attributeId?.relationId,
      index,
    };
  });
};
// 编辑时 显示数据
export const handleFormatBackAttrs = (attrsList: any) => {
  return attrsList?.map((item: any) => {
    return {
      attributeId: {
        value: item.id,
        label: item.name,
        relationId: item.relationId,
      },
      attributeItemId: {
        value: item.itemId,
        label: item.itemName,
      },
    };
  });
};

//  提交数据 图片
export const handleFormatDocuments = (fileList?: any[]) => {
  return fileList?.map((item) => {
    return {
      id: item?.response?.id ? item?.response?.id : item?.id,
      relationId: item?.relationId,
    };
  });
};
// 编辑时 显示数据
export const handleFormatBackDocuments = (
  list: {
    /**
     * 文件ID
     */
    id?: number;
    /**
     * 物料文件关联ID
     */
    relationId?: number;
    /**
     * 文件路径
     */
    url?: string;
  }[],
) => {
  return list?.map(async (item) => {
    return {
      id: item.id,
      url: item?.url,
      relationId: item.relationId,
      uid: item.id,
      status: 'done',
    };
  });
};
// 提交数据 转换单位
export const handleFormatTransformUnits = (
  list?: MaterialCreateUnitForm[],
): FetchMaterialCreateUnitRequest['conversionUnits'] => {
  return _.map(list, (item, index) => {
    return {
      id: item?.id,
      convertType: item?.convertType,
      fromUnitCount: Number(item?.fromUnitCount),
      fromUnitId: Number(item?.fromUnitId?.value),
      relationId: item?.relationId,
      toUnitCount: Number(item?.toUnitCount),
      toUnitId: Number(item?.toUnitId?.value),
      enableFlag: item.enableFlag
        ? appEnum.Common.UsageStatus.enabled
        : appEnum.Common.UsageStatus.disabled,
      serialNo: index,
    };
  });
};
// 编辑时 显示【物料单位】数据
export const handleFormatBackTransformUnits = (
  conversionUnits: IAutoGeneratedMaterialDetailResponse['conversionUnits'],
  mainUnit: IAutoGeneratedMaterialDetailResponse['unit'],
  auxUnits: IAutoGeneratedMaterialDetailResponse['auxUnitList'],
  hasStorage: boolean,
) => {
  const unitList: MaterialCreateUnitForm[] = [];

  if (mainUnit) {
    unitList.push({
      convertType: UnitType.mainUnit,
      fromUnitCount: 1,
      fromUnitId: { label: mainUnit.name, value: _.toNumber(mainUnit.id) },
      id: mainUnit?.id,
      relationId: mainUnit.relationId,
      toUnitCount: 1,
      toUnitId: { label: mainUnit.name, value: _.toNumber(mainUnit.id) },
      enableFlag: appEnum.Common.UsageStatus.enabled,
      hasStorage,
    });
  }

  if (!_.isEmpty(conversionUnits)) {
    _.forEach(conversionUnits, (item) => {
      unitList.push({
        convertType: item.convertType,
        fromUnitCount: item.fromUnitCount,
        fromUnitId: { label: item.fromUnitId, value: _.toNumber(item.fromUnitId) },
        id: item?.id,
        relationId: item.relationId,
        toUnitCount: item.toUnitCount,
        toUnitId: { label: item.toUnitName, value: _.toNumber(item.toUnitId) },
        enableFlag: item.enableFlag ?? appEnum.Common.UsageStatus.disabled,
        hasStorage,
      });
    });
  }

  if (!_.isEmpty(auxUnits)) {
    _.forEach(auxUnits, (item) => {
      unitList.push({
        convertType: item.convertType,
        fromUnitCount: item.fromUnitCount,
        fromUnitId: { label: item.fromUnitId, value: _.toNumber(item.fromUnitId) },
        id: item?.id,
        relationId: item.relationId,
        toUnitCount: item.toUnitCount,
        toUnitId: { label: item.toUnitName, value: _.toNumber(item.toUnitId) },
        enableFlag: item.enableFlag ?? appEnum.Common.UsageStatus.disabled,
        hasStorage,
      });
    });
  }

  return unitList;
};
export const handleFormatBackBatchAttrs = (
  list: originBatchAttrs[] | undefined,
): sumbitBatchAttrs[] => {
  if (!list) {
    return [];
  }
  return list?.map((item) => {
    return {
      ...item,
      name: lookup('material.batchAttrs', item.name),
    };
  });
};
interface CategoryNode {
  id: number;
  name: string;
  children: CategoryNode[];
  [x: string]: any;
}
// 处理 物料分类 显示 [21232,2321321] => 白酒/贵州茅台
export const handleFormatBackCategory = (data: number[], categoryList: CategoryNode[]) => {
  function forEachToGetName(
    data: CategoryNode[],
    id: number | string,
    indexArray: string[],
  ): boolean | string[] {
    const arr = Array.from(indexArray);

    for (let i = 0, len = data.length; i < len; i++) {
      arr.push(data[i].name);
      if (data[i].id === id) {
        return arr;
      }
      const children = data[i].children;

      if (children && children.length) {
        const result = forEachToGetName(children, id, arr);

        if (result) return result;
      }
      arr.pop();
    }
    return false;
  }

  if (data?.length === 0) {
    return '-';
  } else if (data?.length > 0) {
    const categoryNameArr: boolean | string[] = forEachToGetName(
      categoryList,
      data[data.length - 1],
      [],
    );

    if (typeof categoryNameArr === 'boolean') {
      return '-';
    }
    return (categoryNameArr as string[]).join(' / ');
  }

  return '-';
};

// 处理物料类型与物料业务范围
export const handleBizTypeChangeDefaults = (selectedValues: number) => {
  let optionsList;

  if (selectedValues === BizType.workInProgress) {
    optionsList = bizRangeMapping.map((item) => {
      return {
        label: item.label,
        value: item.value,
        disabled: item.value === BizRange.purchase || item.value === BizRange.sales,
      };
    });
    return {
      optionsList,
      bizRange: [
        BizRange.warehousing,
        BizRange.production,
        // 一期没有质量
        BizRange.quality,
        BizRange.manufacture,
      ],
    };
  } else if (selectedValues === BizType.phantom) {
    optionsList = bizRangeMapping.map((item) => {
      return {
        label: item.label,
        value: item.value,
        disabled: true,
      };
    });
    return {
      optionsList,
      bizRange: [],
    };
  }
  // 默认类型，业务范围为全部
  optionsList = bizRangeMapping.map((item) => {
    return {
      label: item.label,
      value: item.value,
      disabled: false,
    };
  });

  return {
    optionsList,
    bizRange: BizRange_DEFAULT,
  };
};
// 处理业务范围与物料tab页面的显示
export const handleBizRangeChangeTabDefaults = (bizRange: number[], customField?: any[]) => {
  const arr = bizRange ? [...bizRange] : [];

  const bizRangeTabMapping = arr.map((item) => {
    const label = lookup('material.bizRange', item);

    return {
      label: `${label}信息`,
      value: item,
    };
  });

  return tabTypeMapping
    .concat(isEmpty(customField) ? [] : tabCustomMapping)
    .concat(bizRangeTabMapping);
};
/* ** 物料基本信息  end ** */

/* ** 物料仓储信息  ** */
// 提交时 处理数据
export const handleFormatLabelValue = (
  data?: Partial<{
    value: string | number;
    label: ValueType;
    key: string | number;
    relationId: number;
  }>,
) => {
  if (!data) {
    return undefined;
  }
  return _.pickBy(
    {
      id: data?.value,
      relationId: data?.relationId,
      name: data?.label,
    },
    _.identity,
  );
};

// 编辑时 显示数据
export const handleFormatBackLabelValue = (
  data:
    | Partial<{
        id: number;
        name: string;
        relationId: number;
      }>
    | undefined,
) => {
  if (data?.id) {
    return _.pickBy(
      {
        value: data?.id,
        label: data?.name,
        relationId: data?.relationId,
      },
      _.identity,
    );
  }
  return undefined;
};

// 提交时，管理设置数据
export const handleFormatWarehousingManagements = (
  params: OriginWarehousingManagements[],
): SubmitWarehousingManagements[] => {
  const data = params?.map((item, index) => {
    const { options } = item;

    return {
      code: item?.code,
      name: item?.name,
      type: item?.type?.value,
      displayEnable: Number(item?.displayEnable),
      nullEnable: Number(item?.nullEnable),
      options: options?.map((el, iindex) => {
        return {
          id: el?.id,
          index: iindex,
          name: el?.content,
        };
      }),
      index,
      ifDefault: item?.ifDefault === undefined ? 0 : 1,
    };
  });

  return data;
};
// 编辑时 显示管理设置数据
export const handleFormatBackWarehousingManagements = (params: any): any => {
  const data = params?.map((item: any) => {
    const { options } = item;

    return {
      code: item?.code,
      name: item?.name,
      type: {
        value: item?.type,
        key: item?.type,
        label: warehousingAttrType.get(item?.type as number),
      },
      displayEnable: Boolean(item?.displayEnable),
      nullEnable: Boolean(item?.nullEnable),
      options: options?.map((el: any) => {
        return {
          id: el?.id,
          index: el?.index,
          content: el.name,
        };
      }),
      index: item?.index,
      ifDefault: item?.ifDefault,
    };
  });

  return data;
};
/* ** 物料仓储信息  end ** */
