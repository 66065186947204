/**
 * @description 电子签名参照映射
 * @author xuzhiyong
 */

import {
  BIZ_TYPE,
  IN_EXECUTE_TYPE,
  OUT_EXECUTE_TYPE,
  SOURCE_TYPE,
  IN_SOURCE_TYPE,
  RECORD_IN_EXECUTE_TYPE,
  RECORD_OUT_EXECUTE_TYPE,
  RECORD_COMMIT_EXECUTE_TYPE,
  RECORD_ADJUST_EXECUTE_TYPE,
  RECORD_PROPERTY_EXECUTE_TYPE,
} from '.';
import { mappingsFactory } from '../utils';

/** 出库类型参照 */
export const outExecuteTypeEnum = mappingsFactory(
  ['普通出库', OUT_EXECUTE_TYPE.outBound],
  ['生产投料', OUT_EXECUTE_TYPE.mfgOut],
  ['出库冲销', OUT_EXECUTE_TYPE.chargeAgainst],
  ['按单出库', OUT_EXECUTE_TYPE.order],
  ['其他', OUT_EXECUTE_TYPE.other],
);

/** 入库类型参照 */
export const inExecuteTypeEnum = mappingsFactory(
  ['按单入库', IN_EXECUTE_TYPE.order],
  ['普通入库', IN_EXECUTE_TYPE.inBound],
  ['生产入库', IN_EXECUTE_TYPE.mfgIn],
  ['冲销入库', IN_EXECUTE_TYPE.chargeAgainst],
  ['跨厂入库', IN_EXECUTE_TYPE.straddle],
);

/** 入库业务类型参照 */
export const bizTypeEnum = mappingsFactory(
  ['采购收货', BIZ_TYPE.purchaseReceiving],
  ['销售退货', BIZ_TYPE.saleRevert],
  ['生产入库', BIZ_TYPE.productionWarehousing],
  ['其他入库', BIZ_TYPE.other],
);

/** 来源类型 */
export const sourceTypeEnum = mappingsFactory(
  ['无源出库', SOURCE_TYPE.emptySource],
  ['协同发货单', SOURCE_TYPE.invoice],
  ['协同采购退货单', SOURCE_TYPE.purchaseReturnOrder],
);

export const inSourceTypeEnum = mappingsFactory(
  ['无源入库', IN_SOURCE_TYPE.emptySource],
  ['协同收货单', IN_SOURCE_TYPE.receipt],
  ['协同销售退货单', IN_SOURCE_TYPE.saleReturnOrder],
);

/** 入库记录业务类型 */
export const recordInExecuteTypeEnum = mappingsFactory(
  ['按单入库', RECORD_IN_EXECUTE_TYPE.order],
  ['普通入库', RECORD_IN_EXECUTE_TYPE.inBound],
  ['生产报工', RECORD_IN_EXECUTE_TYPE.report],
  ['冲销入库', RECORD_IN_EXECUTE_TYPE.chargeAgainst],
  ['跨厂入库', RECORD_IN_EXECUTE_TYPE.straddle],
  ['报工调整', RECORD_IN_EXECUTE_TYPE.reportAdjustment],
);

/** 出库记录业务类型 */
export const recordOutExecuteTypeEnum = mappingsFactory(
  ['普通出库', RECORD_OUT_EXECUTE_TYPE.outBound],
  ['生产投料', RECORD_OUT_EXECUTE_TYPE.feeding],
  ['出库冲销', RECORD_OUT_EXECUTE_TYPE.chargeAgainst],
  ['按单出库', RECORD_OUT_EXECUTE_TYPE.order],
  ['投料撤回', RECORD_OUT_EXECUTE_TYPE.withdraw],
  // ['跨厂出库', RECORD_OUT_EXECUTE_TYPE.crossPlant],
);

/** 调拨记录业务类型 */
export const recordCommitExecuteTypeEnum = mappingsFactory(
  ['按单调拨', RECORD_COMMIT_EXECUTE_TYPE.order],
  ['普通调拨', RECORD_COMMIT_EXECUTE_TYPE.common],
);

/** 库存调整记录业务类型 */
export const recordAdjustExecuteTypeEnum = mappingsFactory(
  ['库存调整', RECORD_ADJUST_EXECUTE_TYPE.adjust],
  ['质检报废', RECORD_ADJUST_EXECUTE_TYPE.scrap],
);

/** 库存调整记录业务类型 */
export const recordPropertyExecuteTypeEnum = mappingsFactory(
  ['属性变更', RECORD_PROPERTY_EXECUTE_TYPE.propertyChange],
  ['标识码绑定', RECORD_PROPERTY_EXECUTE_TYPE.bind],
  ['标识码解绑', RECORD_PROPERTY_EXECUTE_TYPE.unbind],
  ['标识码拆分', RECORD_PROPERTY_EXECUTE_TYPE.split],
  ['质量变更', RECORD_PROPERTY_EXECUTE_TYPE.change],
);
