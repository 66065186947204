import { BlTable } from 'src/components';
//
import { RepairTaskDetailType } from '../index.d';

interface TagListProps {
  dataSource: NonNullable<RepairTaskDetailType>['malfunctionLabelList'];
}

const MalfunctionTagTable = (props: TagListProps) => {
  const { dataSource } = props;

  return (
    <BlTable
      dataSource={dataSource}
      columns={[
        {
          title: '标签项',
          dataIndex: 'name',
        },
        {
          title: '标签值',
          dataIndex: 'labelValue',
        },
      ]}
    />
  );
};

export default MalfunctionTagTable;
