/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../../request';

type UserRequestRestArgs = RequestFunctionRestArgs<typeof request>;

// Request: 目前 React Hooks 功能有用到
export type Request<
  TRequestData,
  TRequestConfig extends RequestConfig,
  TRequestResult,
> = (TRequestConfig['requestDataOptional'] extends true
  ? (requestData?: TRequestData, ...args: RequestFunctionRestArgs<typeof request>) => TRequestResult
  : (
      requestData: TRequestData,
      ...args: RequestFunctionRestArgs<typeof request>
    ) => TRequestResult) & {
  requestConfig: TRequestConfig;
};

const mockUrl_0_0_1_0 = 'http://yapi.blacklake.tech/mock/519' as any;
const devUrl_0_0_1_0 = '' as any;
const prodUrl_0_0_1_0 = '' as any;
const dataKey_0_0_1_0 = undefined as any;

/**
 * 接口 [通过角色ID获取权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72638) 的 **请求类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_privileges_by_role_id`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchPrivilegeListPrivilegesByRoleIdRequest {
  /**
   * 角色ID
   */
  roleId: number;
  /**
   * 权限类型，1:App; 2:Web
   */
  type: number;
}

/**
 * 接口 [通过角色ID获取权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72638) 的 **返回类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_privileges_by_role_id`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchPrivilegeListPrivilegesByRoleIdResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 功能权限编码
     */
    code?: string;
    /**
     * 功能权限ID
     */
    id?: number;
    /**
     * 功能权限名称
     */
    name?: string;
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [通过角色ID获取权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72638) 的 **请求配置的类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_privileges_by_role_id`
 * @更新时间 `2021-12-14 19:24:00`
 */
type FetchPrivilegeListPrivilegesByRoleIdRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/privilege/_list_privileges_by_role_id',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [通过角色ID获取权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72638) 的 **请求配置**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_privileges_by_role_id`
 * @更新时间 `2021-12-14 19:24:00`
 */
const fetchPrivilegeListPrivilegesByRoleIdRequestConfig: FetchPrivilegeListPrivilegesByRoleIdRequestConfig =
  {
    mockUrl: mockUrl_0_0_1_0,
    devUrl: devUrl_0_0_1_0,
    prodUrl: prodUrl_0_0_1_0,
    path: '/user/domain/web/v1/privilege/_list_privileges_by_role_id',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_1_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchPrivilegeListPrivilegesByRoleId',
  };

/**
 * 接口 [通过角色ID获取权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72638) 的 **请求函数**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_privileges_by_role_id`
 * @更新时间 `2021-12-14 19:24:00`
 */
export const fetchPrivilegeListPrivilegesByRoleId = (
  requestData: FetchPrivilegeListPrivilegesByRoleIdRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchPrivilegeListPrivilegesByRoleIdResponse>(
    prepare(fetchPrivilegeListPrivilegesByRoleIdRequestConfig, requestData),
    ...args,
  );
};

fetchPrivilegeListPrivilegesByRoleId.requestConfig =
  fetchPrivilegeListPrivilegesByRoleIdRequestConfig;

/**
 * 接口 [保存角色ID的权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72644) 的 **请求类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_save_privileges_by_role_id`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchPrivilegeSavePrivilegesByRoleIdRequest {
  /**
   * 功能权限ID
   */
  privilegeIdList: number[];
  /**
   * 角色ID
   */
  roleId: number;
}

/**
 * 接口 [保存角色ID的权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72644) 的 **返回类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_save_privileges_by_role_id`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchPrivilegeSavePrivilegesByRoleIdResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 功能权限ID
     */
    privilegeIdList: number[];
    /**
     * 角色ID
     */
    roleId: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [保存角色ID的权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72644) 的 **请求配置的类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_save_privileges_by_role_id`
 * @更新时间 `2021-12-14 19:24:00`
 */
type FetchPrivilegeSavePrivilegesByRoleIdRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/privilege/_save_privileges_by_role_id',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [保存角色ID的权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72644) 的 **请求配置**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_save_privileges_by_role_id`
 * @更新时间 `2021-12-14 19:24:00`
 */
const fetchPrivilegeSavePrivilegesByRoleIdRequestConfig: FetchPrivilegeSavePrivilegesByRoleIdRequestConfig =
  {
    mockUrl: mockUrl_0_0_1_0,
    devUrl: devUrl_0_0_1_0,
    prodUrl: prodUrl_0_0_1_0,
    path: '/user/domain/web/v1/privilege/_save_privileges_by_role_id',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_1_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchPrivilegeSavePrivilegesByRoleId',
  };

/**
 * 接口 [保存角色ID的权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72644) 的 **请求函数**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_save_privileges_by_role_id`
 * @更新时间 `2021-12-14 19:24:00`
 */
export const fetchPrivilegeSavePrivilegesByRoleId = (
  requestData: FetchPrivilegeSavePrivilegesByRoleIdRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchPrivilegeSavePrivilegesByRoleIdResponse>(
    prepare(fetchPrivilegeSavePrivilegesByRoleIdRequestConfig, requestData),
    ...args,
  );
};

fetchPrivilegeSavePrivilegesByRoleId.requestConfig =
  fetchPrivilegeSavePrivilegesByRoleIdRequestConfig;

/**
 * 接口 [tempBuildAdminUser↗](http://yapi.blacklake.tech/project/519/interface/api/72650) 的 **请求类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_temp_build_admin_user`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchPrivilegeTempBuildAdminUserRequest {}

/**
 * 接口 [tempBuildAdminUser↗](http://yapi.blacklake.tech/project/519/interface/api/72650) 的 **返回类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_temp_build_admin_user`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchPrivilegeTempBuildAdminUserResponse {}

/**
 * 接口 [tempBuildAdminUser↗](http://yapi.blacklake.tech/project/519/interface/api/72650) 的 **请求配置的类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_temp_build_admin_user`
 * @更新时间 `2021-12-14 19:24:00`
 */
type FetchPrivilegeTempBuildAdminUserRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/privilege/_temp_build_admin_user',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [tempBuildAdminUser↗](http://yapi.blacklake.tech/project/519/interface/api/72650) 的 **请求配置**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_temp_build_admin_user`
 * @更新时间 `2021-12-14 19:24:00`
 */
const fetchPrivilegeTempBuildAdminUserRequestConfig: FetchPrivilegeTempBuildAdminUserRequestConfig =
  {
    mockUrl: mockUrl_0_0_1_0,
    devUrl: devUrl_0_0_1_0,
    prodUrl: prodUrl_0_0_1_0,
    path: '/user/domain/web/v1/privilege/_temp_build_admin_user',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_1_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: true,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchPrivilegeTempBuildAdminUser',
  };

/**
 * 接口 [tempBuildAdminUser↗](http://yapi.blacklake.tech/project/519/interface/api/72650) 的 **请求函数**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_temp_build_admin_user`
 * @更新时间 `2021-12-14 19:24:00`
 */
export const fetchPrivilegeTempBuildAdminUser = (
  requestData?: FetchPrivilegeTempBuildAdminUserRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchPrivilegeTempBuildAdminUserResponse>(
    prepare(fetchPrivilegeTempBuildAdminUserRequestConfig, requestData),
    ...args,
  );
};

fetchPrivilegeTempBuildAdminUser.requestConfig = fetchPrivilegeTempBuildAdminUserRequestConfig;

/**
 * 接口 [web功能权限树↗](http://yapi.blacklake.tech/project/519/interface/api/72656) 的 **请求类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `GET /user/domain/web/v1/privilege/_tree`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchPrivilegeTreeGetRequest {
  /**
   * 角色Id
   */
  roleId?: string;
}

/**
 * 接口 [web功能权限树↗](http://yapi.blacklake.tech/project/519/interface/api/72656) 的 **返回类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `GET /user/domain/web/v1/privilege/_tree`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchPrivilegeTreeGetResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 功能权限的子级，子级的结构同父级
     */
    children?: unknown[];
    /**
     * 功能权限编码
     */
    code?: string;
    /**
     * 是否支持配置
     */
    enable?: boolean;
    /**
     * 功能权限ID
     */
    id?: number;
    /**
     * 功能权限在树中的层级从1开始，1代表根级
     */
    level?: number;
    /**
     * 功能权限信息
     */
    levelInfo?: string;
    /**
     * 功能权限名称
     */
    name?: string;
    /**
     * 功能权限父级ID
     */
    parentId?: number;
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [web功能权限树↗](http://yapi.blacklake.tech/project/519/interface/api/72656) 的 **请求配置的类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `GET /user/domain/web/v1/privilege/_tree`
 * @更新时间 `2021-12-14 19:24:00`
 */
type FetchPrivilegeTreeGetRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/privilege/_tree',
    undefined,
    string,
    'roleId',
    false
  >
>;

/**
 * 接口 [web功能权限树↗](http://yapi.blacklake.tech/project/519/interface/api/72656) 的 **请求配置**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `GET /user/domain/web/v1/privilege/_tree`
 * @更新时间 `2021-12-14 19:24:00`
 */
const fetchPrivilegeTreeGetRequestConfig: FetchPrivilegeTreeGetRequestConfig = {
  mockUrl: mockUrl_0_0_1_0,
  devUrl: devUrl_0_0_1_0,
  prodUrl: prodUrl_0_0_1_0,
  path: '/user/domain/web/v1/privilege/_tree',
  method: Method.GET,
  requestHeaders: {},
  requestBodyType: RequestBodyType.query,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_1_0,
  paramNames: [],
  queryNames: ['roleId'],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchPrivilegeTreeGet',
};

/**
 * 接口 [web功能权限树↗](http://yapi.blacklake.tech/project/519/interface/api/72656) 的 **请求函数**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `GET /user/domain/web/v1/privilege/_tree`
 * @更新时间 `2021-12-14 19:24:00`
 */
export const fetchPrivilegeTreeGet = (
  requestData: FetchPrivilegeTreeGetRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchPrivilegeTreeGetResponse>(
    prepare(fetchPrivilegeTreeGetRequestConfig, requestData),
    ...args,
  );
};

fetchPrivilegeTreeGet.requestConfig = fetchPrivilegeTreeGetRequestConfig;

/**
 * 接口 [获取可配置电子签名的功能点列表↗](http://yapi.blacklake.tech/project/519/interface/api/86354) 的 **请求类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_enable_signature_function`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchPrivilegeListEnableSignatureFunctionRequest {}

/**
 * 接口 [获取可配置电子签名的功能点列表↗](http://yapi.blacklake.tech/project/519/interface/api/86354) 的 **返回类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_enable_signature_function`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchPrivilegeListEnableSignatureFunctionResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 功能权限编码
     */
    code?: string;
    /**
     * 功能权限ID
     */
    id?: number;
    /**
     * 功能权限名称
     */
    name?: string;
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取可配置电子签名的功能点列表↗](http://yapi.blacklake.tech/project/519/interface/api/86354) 的 **请求配置的类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_enable_signature_function`
 * @更新时间 `2021-12-14 19:24:00`
 */
type FetchPrivilegeListEnableSignatureFunctionRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/privilege/_list_enable_signature_function',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [获取可配置电子签名的功能点列表↗](http://yapi.blacklake.tech/project/519/interface/api/86354) 的 **请求配置**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_enable_signature_function`
 * @更新时间 `2021-12-14 19:24:00`
 */
const fetchPrivilegeListEnableSignatureFunctionRequestConfig: FetchPrivilegeListEnableSignatureFunctionRequestConfig =
  {
    mockUrl: mockUrl_0_0_1_0,
    devUrl: devUrl_0_0_1_0,
    prodUrl: prodUrl_0_0_1_0,
    path: '/user/domain/web/v1/privilege/_list_enable_signature_function',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_1_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: true,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchPrivilegeListEnableSignatureFunction',
  };

/**
 * 接口 [获取可配置电子签名的功能点列表↗](http://yapi.blacklake.tech/project/519/interface/api/86354) 的 **请求函数**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_enable_signature_function`
 * @更新时间 `2021-12-14 19:24:00`
 */
export const fetchPrivilegeListEnableSignatureFunction = (
  requestData?: FetchPrivilegeListEnableSignatureFunctionRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchPrivilegeListEnableSignatureFunctionResponse>(
    prepare(fetchPrivilegeListEnableSignatureFunctionRequestConfig, requestData),
    ...args,
  );
};

fetchPrivilegeListEnableSignatureFunction.requestConfig =
  fetchPrivilegeListEnableSignatureFunctionRequestConfig;

/* prettier-ignore-end */
