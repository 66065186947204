/**
 *  @control 编号控件
 */
import { FC } from 'react';
import { Input } from 'antd';
import { PreviewCompProps } from '../../types';

export const NumberRules: FC<PreviewCompProps> = ({ inputPrompt }) => {
  return <Input style={{ pointerEvents: 'none' }} tabIndex={-1} placeholder={inputPrompt} />;
};
