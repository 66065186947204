/**
 * @description 触发规则 form表单
 */

import { useEffect, useState } from 'react';
import { Input, Select, Form, Radio, Checkbox, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { DataFormLayoutInfoBlock, DataFormLayout } from 'layout';
import { NumberRulesStandardHook, SearchSelect } from 'components';
import _, { some } from 'lodash';

import { ObjectCode, CRUD } from 'src/dict/common';
import { appDict, appEnum } from 'src/dict';
import { validateChineseNotSupported } from 'src/utils/formValidators';

import {
  fetchTriggerRuleEdit,
  fetchTriggerRuleCreate,
} from 'src/api/ytt/trigger-domain/triggerRule';

import TimeIntervalTrigger from './TimeIntervalTrigger';
import BizEventTrigger from './BizEventTrigger';
import RuleConditionEditTable from './RuleConditionEditTable/index';
import { formatDataToRequest } from '../utils/formatDataToRequest';
import { getFieldValueOfForm } from '../utils/tools';

interface TriggerRuleFormProps {
  title: string;
  loading: boolean;
  onFinish: () => void;
  onCancel: () => void;
  initialValues?: any;
  type: 'edit' | 'copy' | 'create';
}

const { TriggerRule } = appEnum;

const TriggerRuleForm: React.FC<TriggerRuleFormProps> = ({
  loading,
  title,
  type,
  initialValues,
  onFinish,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [keepOn, setkeepOn] = useState<boolean>(false);
  const [refreshMarker] = useState<number>(0);

  useEffect(() => {
    if (type !== CRUD.create && initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues]);

  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    column: 1,
    items: [
      ...NumberRulesStandardHook({
        label: '规则编号',
        form,
        edit: type === CRUD.edit,
        objectCode: ObjectCode.triggerRules,
        fieldCode: 'code',
        name: 'code',
        required: true,
        refreshMarker,
        rules: [
          { max: 100, message: '不可超过100个字符' },
          { validator: validateChineseNotSupported() },
        ],
      }),
      {
        label: '规则名称',
        name: 'name',
        rules: [
          { required: true, message: '请输入规则名称' },
          { max: 100, message: '不可超过100个字符' },
        ],
        validateFirst: true,
        render: () => <Input placeholder="请输入规则名称" />,
      },
      {
        label: '上游规则',
        name: 'parentRuleId',
        render: () => (
          <SearchSelect
            fetchType="triggerRule"
            placeholder="请选择上游规则"
            params={{ status: appEnum.Common.UsageStatus.enabled }}
          />
        ),
      },
      {
        label: '备注',
        name: 'remark',
        isFullLine: true,
        rules: [{ max: 1000, message: '不超过1000个字符' }],
        render: () => <TextArea placeholder="请输入备注" />,
      },
    ],
  };

  const ruleInfo: DataFormLayoutInfoBlock = {
    title: '规则信息',
    column: 1,
    items: [
      {
        label: '业务对象',
        name: 'objectCode',
        rules: [{ required: true, message: '请选择业务对象' }],
        isFullLine: true,
        validateFirst: true,
        render: () => (
          <SearchSelect
            fetchType={'bizObjectByAllMsg'}
            placeholder={'请选择业务对象'}
            allowClear={false}
            //  切换对象后，需重置「触发条件」的值
            onSelect={() =>
              form.setFields([
                { name: ['triggerCondition', 'conditionGroups'], value: undefined },
                { name: ['bizEventTrigger', 'triggerEventType'], value: undefined },
              ])
            }
          />
        ),
      },
      // {
      //   label: '业务类型',
      //   name: 'objectBizType',
      //   isFullLine: true,
      //   render: () => (
      //     <Select
      //       placeholder={'请选择业务类型'}
      //       options={appDict.triggerRule.objectBizType}
      //       allowClear
      //     />
      //   ),
      // },
      {
        label: '触发方式',
        name: 'triggerType',
        isFullLine: true,
        initialValue: TriggerRule.TriggerType.bizEventTrigger,
        rules: [{ required: true, message: '请选择触发方式' }],
        render: () => (
          <Select
            placeholder={'请选择触发方式'}
            options={appDict.triggerRule.triggerType}
            // 切换触发方式，影响fieldId的值
            // 定时触发只能选择单级
            onSelect={() => {
              form.setFields([{ name: ['triggerCondition', 'conditionGroups'], value: undefined }]);
            }}
          />
        ),
      },
      {
        isFullLine: true,
        dependencies: ['triggerType', 'objectCode'],
        noStyle: true,
        render: (FormItemProps: any) => () => {
          const triggerType = form.getFieldValue('triggerType');
          const objectCode = getFieldValueOfForm(form, 'objectCode', 'objectCode');

          if (triggerType === TriggerRule.TriggerType.bizEventTrigger) {
            return (
              <BizEventTrigger
                FormItemProps={FormItemProps}
                form={form}
                objectCode={objectCode}
                moduleType="triggerRule"
              />
            );
          }
          return <TimeIntervalTrigger FormItemProps={FormItemProps} />;
        },
      },
    ],
  };

  const triggerCondition: DataFormLayoutInfoBlock = {
    title: '触发条件',
    column: 1,
    items: [
      {
        label: '配置方式',
        name: 'triggerConditionType',
        required: true,
        initialValue: TriggerRule.TriggerConditionType.biz,
        render: () => <Radio.Group options={appDict.triggerRule.triggerConditionType} />,
      },
      {
        label: '',
        isFullLine: true,
        dependencies: [['objectCode'], ['triggerType'], ['bizEventTrigger', 'triggerEventType']],
        render: () => () => {
          const objectCode = getFieldValueOfForm(form, 'objectCode', 'objectCode');
          const objectCategory = getFieldValueOfForm(form, 'objectCode', 'objectCategory');
          /** 触发方式 */
          const triggerType = form.getFieldValue('triggerType');
          /** 触发动作 */
          const triggerEventType = form.getFieldValue('bizEventTrigger')?.triggerEventType;

          const haveEditTriggerEventType = some(triggerEventType, (e: any) => e?.value === 'edit');

          return (
            <RuleConditionEditTable
              form={form}
              namePath={['triggerCondition', 'conditionGroups']}
              triggerType={triggerType}
              code={objectCode}
              objectCategory={objectCategory}
              haveEditTriggerEventType={haveEditTriggerEventType} // 只有当触发动作包含编辑时 才显示编辑数据校验列
            />
          );
        },
      },
    ],
  };

  if (type === CRUD.edit) {
    baseInfo.items.push({
      label: '编辑原因',
      name: 'operateReason',
      isFullLine: true,
      rules: [{ max: 1000, message: '不超过1000字符' }],
      render: () => <TextArea placeholder="请输入编辑原因" />,
    });
  }

  const handleFinish = async () => {
    try {
      const value = await form?.validateFields();

      const reqFn = type === CRUD.edit ? fetchTriggerRuleEdit : fetchTriggerRuleCreate;

      reqFn(formatDataToRequest(value, initialValues?.id)).then((res) => {
        if (res.code === 200) {
          message.success('操作成功');
          if (keepOn) {
            form.resetFields();
            form.setFields([{ name: ['triggerCondition', 'conditionGroups'], value: undefined }]);
          } else {
            onFinish();
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getInfo = () => [baseInfo, ruleInfo, triggerCondition];

  const onFieldsChange = (fields: any) => {
    // 仅当 fields 为单个变动时执行依赖
    // 规避提交时的校验引起值改变
    if (fields.length > 1) return;
    fields.forEach((field: any) => {
      /** 当前变动的字段名 */
      const curfieldName: string = _.last(field.name) as string;
      /** 当前变动的字段所在的父级 字段的路径 */
      const fieldName: (string | number)[] = _.initial(field.name) as (string | number)[];
      const value = form.getFieldValue(fieldName);

      switch (curfieldName) {
        case 'fieldId':
          form.setFields([
            {
              name: fieldName,
              value: {
                ...value,
                beforeValues: undefined,
                beforeConditionType: undefined,
                afterValues: undefined,
                afterConditionType: undefined,
              },
            },
          ]);
          break;
        case 'beforeValueType':
        case 'beforeConditionType':
          if (
            value.beforeConditionType === TriggerRule.ConditionType.NULL ||
            value.beforeConditionType === TriggerRule.ConditionType.NOT_NULL
          ) {
            form.setFields([
              {
                name: fieldName,
                value: { ...value, beforeValues: undefined, beforeValueType: undefined },
              },
            ]);
          } else {
            form.setFields([{ name: fieldName, value: { ...value, beforeValues: undefined } }]);
          }
          break;
        case 'afterValueType':
        case 'afterConditionType':
          if (
            value.afterConditionType === TriggerRule.ConditionType.NULL ||
            value.afterConditionType === TriggerRule.ConditionType.NOT_NULL
          ) {
            form.setFields([
              {
                name: fieldName,
                value: { ...value, afterValues: undefined, afterValueType: undefined },
              },
            ]);
          } else form.setFields([{ name: fieldName, value: { ...value, afterValues: undefined } }]);
          break;
        default:
          break;
      }
    });
  };

  return (
    <DataFormLayout
      form={form}
      title={title}
      loading={loading}
      info={getInfo()}
      onFinish={handleFinish}
      formLayout="horizontal"
      onCancel={onCancel}
      formProps={{ onFieldsChange }}
      extra={
        type === CRUD.create ? (
          <Checkbox onChange={() => setkeepOn(!keepOn)} defaultChecked={false}>
            连续新建
          </Checkbox>
        ) : null
      }
    />
  );
};

export default TriggerRuleForm;
