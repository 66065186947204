/**
 * @formatter 有值则展示值，无值则展示占位符
 */

import { ReactNode } from 'react';
import { isNil, isFunction } from 'lodash';
import { replaceSign } from '../constants';

type renderFn = (param: string | number | null | undefined) => ReactNode;

/**
 * 为空则展示占位符
 * @param {string|number|function} text 值, 也可以传render函数, 返回柯里化的valurOrHolder
 * @param {function} [render] 渲染值的方法
 */
export const valueOrHolder = (text: any, render?: renderFn): any => {
  if (isNil(text) || text === '') {
    return replaceSign;
  }
  // 柯里化
  if (isFunction(text)) {
    return (txt: string | number) => valueOrHolder(txt, text);
  }
  if (isFunction(render)) {
    return render(text);
  }
  return text;
};

export default valueOrHolder;
