import authDict from 'src/utils/auth';
import TraceRelationshipList from './traceManagement/traceRelationship';
import TraceNodeDetail from './traceManagement/traceRelationship/detail';
import TraceNodeChart from './traceManagement/traceRelationship/chart';

const baseUrl = '/trace';
const traceManagementUrl = `${baseUrl}/traceManagement`;
const traceRelationshipUrl = `${traceManagementUrl}/traceRelationship`;

export const toTraceNodeDetail = (id: number) => {
  return `${traceRelationshipUrl}/list/${id}/detail`;
};

export const toTraceNodeChart = (id: number) => {
  return `${traceRelationshipUrl}/list/${id}/chart`;
};

export const TraceRoute = [
  {
    path: baseUrl,
    menu: '追溯',
    breadcrumb: '追溯',
    icon: 'iconyijicaidan-zhuisu',
    redirectPath: baseUrl,
    children: [
      {
        path: traceManagementUrl,
        menu: '追溯管理',
        breadcrumb: '追溯管理',
        children: [
          {
            path: `${traceRelationshipUrl}/list`,
            auth: authDict.trackTrace_view,
            menu: '追溯关系',
            breadcrumb: '追溯关系',
            component: TraceRelationshipList,
            children: [
              {
                path: `${traceRelationshipUrl}/list/:id/detail`,
                breadcrumb: '追溯节点详情',
                component: TraceNodeDetail,
              },
              {
                path: `${traceRelationshipUrl}/list/:id/chart`,
                breadcrumb: '追溯关系图',
                component: TraceNodeChart,
              },
            ],
          },
        ],
      },
    ],
  },
];
