import _ from 'lodash';

const formatToOption = (label: string, value: string | number, dataSource?: any) => {
  return _.isNil(value) ? value : { label, value, dataSource };
};

const formatToStingfyOption = (label: string, rest: any) => {
  return { label, value: JSON.stringify(rest) };
};

const formatTimeIntervalTrigger = (data: any) => {
  if (_.isNil(data)) return undefined;
  const { triggerWeekDay, triggerYearMonth, triggerMonthDay, triggerEveryDayTime, ...rest } = data;

  return {
    triggerWeekDay: triggerWeekDay?.split(',').map(Number),
    triggerYearMonth: triggerYearMonth?.split(','),
    triggerMonthDay: triggerMonthDay?.split(','),
    triggerEveryDayTime: triggerEveryDayTime?.split(','),
    ...rest,
  };
};

const formatDelayTriggerField = (data: any) => {
  if (_.isNil(data)) return undefined;
  const { delayTriggerFieldId, delayTriggerFieldName, triggerEventType } = data;
  let _delayTriggerField;

  if (!_.isNil(delayTriggerFieldId)) {
    _delayTriggerField = formatToOption(delayTriggerFieldName, delayTriggerFieldId);
  }
  return {
    ...data,
    delayTriggerField: _delayTriggerField,
    triggerEventType: triggerEventType.map(({ code, name }: any) => ({ label: name, value: code })),
  };
};

export const formatToDetaiData = (data: any) => {
  const { bizEventTrigger, timeIntervalTrigger, objectName, objectCode, objectCategory, ...rest } =
    data;

  return {
    ...rest,
    objectName,
    objectCode: formatToStingfyOption(objectName, { objectCode, objectName, objectCategory }),
    bizEventTrigger: formatDelayTriggerField(bizEventTrigger),
    timeIntervalTrigger: formatTimeIntervalTrigger(timeIntervalTrigger),
  };
};
