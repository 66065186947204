import { RouteProp } from 'src/route';
import reportTemplateRouteConfig from 'src/page/knowledgeManagement/reportTemplate/navigation';

export const universalRouteConfig: RouteProp[] = [
  {
    path: '/universal',
    menu: '通用',
    icon: 'iconyijicaidan-tongyong',
    breadcrumb: '通用',
    redirectPath: '/universal/reportTemplate',
    children: [...reportTemplateRouteConfig],
  },
];
