import React from 'react';
import { Image } from 'antd';
import { FALLBACK } from '../constants';

interface PhotoViewerProps {
  filePathList: string[];
}

const PhotoViewer: React.FC<PhotoViewerProps> = (props) => {
  return (
    <div>
      <Image.PreviewGroup>
        {props.filePathList.map((path) => (
          <Image
            key={path}
            width={200}
            style={{ paddingLeft: 10, paddingRight: 10 }}
            src={path}
            fallback={FALLBACK}
          />
        ))}
      </Image.PreviewGroup>
    </div>
  );
};

export default PhotoViewer;
