import React from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import ElectronicTemplateDetailBaseInfo from './components/detailBaseInfo';

interface Props extends RouteComponentProps {
  match: match<{ id: string }>;
}

export default function ElectronicTemplateDetail(props: Props) {
  const { match } = props;
  const templateId = Number(match?.params?.id);

  return <ElectronicTemplateDetailBaseInfo templateId={templateId} />;
}
