/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../../request';

type UserRequestRestArgs = RequestFunctionRestArgs<typeof request>;

// Request: 目前 React Hooks 功能有用到
export type Request<
  TRequestData,
  TRequestConfig extends RequestConfig,
  TRequestResult,
> = (TRequestConfig['requestDataOptional'] extends true
  ? (requestData?: TRequestData, ...args: RequestFunctionRestArgs<typeof request>) => TRequestResult
  : (
      requestData: TRequestData,
      ...args: RequestFunctionRestArgs<typeof request>
    ) => TRequestResult) & {
  requestConfig: TRequestConfig;
};

const mockUrl_0_0_3_0 = 'http://yapi.blacklake.tech/mock/671' as any;
const devUrl_0_0_3_0 = '' as any;
const prodUrl_0_0_3_0 = '' as any;
const dataKey_0_0_3_0 = undefined as any;

/**
 * 接口 [创建\/编辑 资源接口↗](http://yapi.blacklake.tech/project/671/interface/api/78614) 的 **请求类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/create_or_update`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesCreateOrUpdateRequest {
  /**
   * 品牌型号
   */
  brandModel?: number;
  /**
   * 业务类型
   */
  businessType: number;
  /**
   * 资源编号
   */
  code: string;
  /**
   * 文件id
   */
  coverFileId: number;
  /**
   * 负责部门集合
   */
  departmentList?: number[];
  /**
   * 启用日期
   */
  enableDate?: string;
  /**
   * 入厂日期
   */
  enterFactoryDate?: string;
  /**
   * 标识码
   */
  entityLinkCode?: string;
  /**
   * 外部编号,如mac地址
   */
  externalNo?: string;
  /**
   * 文件，附件集合
   */
  fileIdList?: number[];
  /**
   * id
   */
  id?: number;
  /**
   * 存储区域ID
   */
  locationId?: number;
  /**
   * 制造商
   */
  manufacturer?: string;
  /**
   * 资源名称
   */
  name: string;
  /**
   * 出厂日期
   */
  outFactoryDate?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 序列号
   */
  serialNo?: string;
  /**
   * 储存单位id
   */
  unitId?: number;
  /**
   * 负责人Id集合
   */
  userIdList?: number[];
}

/**
 * 接口 [创建\/编辑 资源接口↗](http://yapi.blacklake.tech/project/671/interface/api/78614) 的 **返回类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/create_or_update`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesCreateOrUpdateResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 品牌型号
     */
    brandModel: number;
    /**
     * 业务类型
     */
    businessType: number;
    /**
     * 编码
     */
    code?: string;
    /**
     * 标识码
     */
    codeEntityLinkCode?: string;
    /**
     * 图片id
     */
    coverFileId?: number;
    createdAt?: string;
    creatorId?: number;
    deletedAt?: number;
    /**
     * 启用日期
     */
    enableDate?: string;
    /**
     * 入厂时间
     */
    enterFactoryDate?: string;
    /**
     * 外部编号
     */
    externalNo?: string;
    id?: number;
    /**
     * 区域ID
     */
    locationId?: number;
    /**
     * 制造商
     */
    manufacturer?: string;
    /**
     * 资源名称
     */
    name?: string;
    operatorId?: number;
    orgId?: number;
    /**
     * 出厂时间
     */
    outFactoryDate?: string;
    /**
     * 备注
     */
    remark?: string;
    /**
     * 序列号
     */
    serialNo?: string;
    /**
     * 状态
     */
    status?: number;
    /**
     * 单位ID
     */
    unitId?: number;
    updatedAt?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [创建\/编辑 资源接口↗](http://yapi.blacklake.tech/project/671/interface/api/78614) 的 **请求配置的类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/create_or_update`
 * @更新时间 `2022-01-04 20:20:00`
 */
type FetchResourcesCreateOrUpdateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/671',
    '',
    '',
    '/resource/domain/web/v1/resources/create_or_update',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [创建\/编辑 资源接口↗](http://yapi.blacklake.tech/project/671/interface/api/78614) 的 **请求配置**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/create_or_update`
 * @更新时间 `2022-01-04 20:20:00`
 */
const fetchResourcesCreateOrUpdateRequestConfig: FetchResourcesCreateOrUpdateRequestConfig = {
  mockUrl: mockUrl_0_0_3_0,
  devUrl: devUrl_0_0_3_0,
  prodUrl: prodUrl_0_0_3_0,
  path: '/resource/domain/web/v1/resources/create_or_update',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_3_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchResourcesCreateOrUpdate',
};

/**
 * 接口 [创建\/编辑 资源接口↗](http://yapi.blacklake.tech/project/671/interface/api/78614) 的 **请求函数**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/create_or_update`
 * @更新时间 `2022-01-04 20:20:00`
 */
export const fetchResourcesCreateOrUpdate = (
  requestData: FetchResourcesCreateOrUpdateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchResourcesCreateOrUpdateResponse>(
    prepare(fetchResourcesCreateOrUpdateRequestConfig, requestData),
    ...args,
  );
};

fetchResourcesCreateOrUpdate.requestConfig = fetchResourcesCreateOrUpdateRequestConfig;

/**
 * 接口 [删除资源接口↗](http://yapi.blacklake.tech/project/671/interface/api/78620) 的 **请求类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/delete`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesDeleteRequest {
  /**
   * 资源Id
   */
  idList: number[];
  /**
   * 工厂ID
   */
  orgId?: number;
}

/**
 * 接口 [删除资源接口↗](http://yapi.blacklake.tech/project/671/interface/api/78620) 的 **返回类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/delete`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [删除资源接口↗](http://yapi.blacklake.tech/project/671/interface/api/78620) 的 **请求配置的类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/delete`
 * @更新时间 `2022-01-04 20:20:00`
 */
type FetchResourcesDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/671',
    '',
    '',
    '/resource/domain/web/v1/resources/delete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [删除资源接口↗](http://yapi.blacklake.tech/project/671/interface/api/78620) 的 **请求配置**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/delete`
 * @更新时间 `2022-01-04 20:20:00`
 */
const fetchResourcesDeleteRequestConfig: FetchResourcesDeleteRequestConfig = {
  mockUrl: mockUrl_0_0_3_0,
  devUrl: devUrl_0_0_3_0,
  prodUrl: prodUrl_0_0_3_0,
  path: '/resource/domain/web/v1/resources/delete',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_3_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchResourcesDelete',
};

/**
 * 接口 [删除资源接口↗](http://yapi.blacklake.tech/project/671/interface/api/78620) 的 **请求函数**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/delete`
 * @更新时间 `2022-01-04 20:20:00`
 */
export const fetchResourcesDelete = (
  requestData: FetchResourcesDeleteRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchResourcesDeleteResponse>(
    prepare(fetchResourcesDeleteRequestConfig, requestData),
    ...args,
  );
};

fetchResourcesDelete.requestConfig = fetchResourcesDeleteRequestConfig;

/**
 * 接口 [资源详情接口↗](http://yapi.blacklake.tech/project/671/interface/api/78626) 的 **请求类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/detail`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesDetailRequest {
  /**
   * 资源Id
   */
  id: number;
  /**
   * 工厂ID
   */
  orgId?: number;
}

/**
 * 接口 [资源详情接口↗](http://yapi.blacklake.tech/project/671/interface/api/78626) 的 **返回类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/detail`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesDetailResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    brandModel?: number;
    /**
     * 品牌型号
     */
    brandModelDesc?: string;
    businessType?: number;
    /**
     * 业务类型
     */
    businessTypeDesc?: string;
    /**
     * 编号
     */
    code?: string;
    coverFile?: {
      createdAt?: string;
      creator?: {
        /**
         * 描述
         */
        desc?: string;
        /**
         * 用户业务ID
         */
        id?: number;
        /**
         * 用户名
         */
        name?: string;
        /**
         * 工厂ID
         */
        orgId?: number;
        /**
         * 账号
         */
        username?: string;
      };
      extension?: string;
      fileUri?: string;
      filename?: string;
      id?: number;
    };
    /**
     * 资源图片ID
     */
    coverFileId?: number;
    createUser?: {
      active?: string;
      avatarUrl?: string;
      code?: string;
      desc?: string;
      id?: number;
      name?: string;
      orgId?: number;
      username?: string;
    };
    /**
     * 创建时间
     */
    createdAt?: string;
    /**
     * 创建时间Long
     */
    createdTime?: number;
    /**
     * 创建人ID
     */
    creatorId?: number;
    /**
     * 启用日期
     */
    enableDate?: string;
    /**
     * 入场日期
     */
    enterFactoryDate?: string;
    /**
     * 标识码code
     */
    entityLinkCode?: string;
    /**
     * 外部地址，类似于mac地址之类
     */
    externalNo?: string;
    /**
     * 附件ID集合
     */
    fileIdList?: number[];
    /**
     * 附件集合
     */
    fileList?: {
      createdAt?: string;
      creator?: {
        /**
         * 描述
         */
        desc?: string;
        /**
         * 用户业务ID
         */
        id?: number;
        /**
         * 用户名
         */
        name?: string;
        /**
         * 工厂ID
         */
        orgId?: number;
        /**
         * 账号
         */
        username?: string;
      };
      extension?: string;
      fileUri?: string;
      filename?: string;
      id?: number;
    }[];
    /**
     * 主键
     */
    id?: number;
    /**
     * 区域id
     */
    locationId?: number;
    /**
     * 区域级联
     */
    locationList?: {}[];
    /**
     * 地址名称
     */
    locationName?: string;
    /**
     * 制造商
     */
    manufacturer?: string;
    /**
     * 名称
     */
    name?: string;
    operateUser?: {
      active?: string;
      avatarUrl?: string;
      code?: string;
      desc?: string;
      id?: number;
      name?: string;
      orgId?: number;
      username?: string;
    };
    /**
     * 操作人
     */
    operatorId?: number;
    /**
     * 出厂编号
     */
    outFactoryDate?: string;
    /**
     * 备注
     */
    remark?: string;
    /**
     * 资源分类id
     */
    resourceClassificationId?: number;
    /**
     * 负责部门
     */
    responsibleDepartment?: {
      code?: string;
      desc?: string;
      id?: number;
      name?: string;
      orgId?: number;
    }[];
    /**
     * 负责人
     */
    responsibleUser?: {
      active?: string;
      avatarUrl?: string;
      code?: string;
      desc?: string;
      id?: number;
      name?: string;
      orgId?: number;
      username?: string;
    }[];
    /**
     * 序列号
     */
    serialNo?: string;
    status?: number;
    /**
     * 单位id
     */
    unitId?: number;
    /**
     * 单位名称
     */
    unitName?: string;
    /**
     * 更新时间
     */
    updatedAt?: string;
    /**
     * 更新时间
     */
    updatedTime?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [资源详情接口↗](http://yapi.blacklake.tech/project/671/interface/api/78626) 的 **请求配置的类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/detail`
 * @更新时间 `2022-01-04 20:20:00`
 */
type FetchResourcesDetailRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/671',
    '',
    '',
    '/resource/domain/web/v1/resources/detail',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [资源详情接口↗](http://yapi.blacklake.tech/project/671/interface/api/78626) 的 **请求配置**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/detail`
 * @更新时间 `2022-01-04 20:20:00`
 */
const fetchResourcesDetailRequestConfig: FetchResourcesDetailRequestConfig = {
  mockUrl: mockUrl_0_0_3_0,
  devUrl: devUrl_0_0_3_0,
  prodUrl: prodUrl_0_0_3_0,
  path: '/resource/domain/web/v1/resources/detail',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_3_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchResourcesDetail',
};

/**
 * 接口 [资源详情接口↗](http://yapi.blacklake.tech/project/671/interface/api/78626) 的 **请求函数**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/detail`
 * @更新时间 `2022-01-04 20:20:00`
 */
export const fetchResourcesDetail = (
  requestData: FetchResourcesDetailRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchResourcesDetailResponse>(
    prepare(fetchResourcesDetailRequestConfig, requestData),
    ...args,
  );
};

fetchResourcesDetail.requestConfig = fetchResourcesDetailRequestConfig;

/**
 * 接口 [资源列表接口↗](http://yapi.blacklake.tech/project/671/interface/api/78632) 的 **请求类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/list`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesListRequest {
  /**
   * 启用日期-开始
   */
  beginEnableDate: string;
  /**
   * 入厂日期-开始
   */
  beginEnterFactoryDate: string;
  /**
   * 出厂日期-开始
   */
  beginOutFactoryDate: string;
  /**
   * 品牌模式
   */
  brandModel?: number;
  /**
   * 业务范围
   */
  businessIdList: number[];
  /**
   * 业务类型
   */
  businessType?: number;
  /**
   * 资源编号
   */
  code: string;
  /**
   * 部门Id集合
   */
  departmentIdList: number[];
  /**
   * 启用日期-结束
   */
  endEnableDate: string;
  /**
   * 入厂日期-结束
   */
  endEnterFactoryDate: string;
  /**
   * 出厂日期-结束
   */
  endOutFactoryDate: string;
  /**
   * 外部ID类似于mac地址
   */
  externalNo: string;
  /**
   * id集合
   */
  idList: number[];
  /**
   * 区域ID
   */
  locationId: number;
  /**
   * 制造商
   */
  manufacturer: string;
  /**
   * 资源名称
   */
  name: string;
  /**
   * 工厂ID
   */
  orgId?: number;
  /**
   * 页数
   */
  page: number;
  /**
   * 备注
   */
  remark: string;
  /**
   * 资源分类Id
   */
  resourceClassificationId: number;
  /**
   * 序列号
   */
  serialNo: string;
  /**
   * 条数
   */
  size: number;
  /**
   * 状态
   */
  status: number;
  /**
   * 单位Id
   */
  unitId: number;
  /**
   * 用户Id集合
   */
  userIdList: number[];
}

/**
 * 接口 [资源列表接口↗](http://yapi.blacklake.tech/project/671/interface/api/78632) 的 **返回类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/list`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      brandModel?: number;
      /**
       * 品牌型号
       */
      brandModelDesc?: string;
      businessType?: number;
      /**
       * 业务类型
       */
      businessTypeDesc?: string;
      /**
       * 编号
       */
      code?: string;
      coverFile?: {
        createdAt?: string;
        creator?: {
          /**
           * 描述
           */
          desc?: string;
          /**
           * 用户业务ID
           */
          id?: number;
          /**
           * 用户名
           */
          name?: string;
          /**
           * 工厂ID
           */
          orgId?: number;
          /**
           * 账号
           */
          username?: string;
        };
        extension?: string;
        fileUri?: string;
        filename?: string;
        id?: number;
      };
      /**
       * 资源图片ID
       */
      coverFileId?: number;
      createUser?: {
        active?: string;
        avatarUrl?: string;
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
        username?: string;
      };
      /**
       * 创建时间
       */
      createdAt?: string;
      /**
       * 创建时间Long
       */
      createdTime?: number;
      /**
       * 创建人ID
       */
      creatorId?: number;
      /**
       * 启用日期
       */
      enableDate?: string;
      /**
       * 入场日期
       */
      enterFactoryDate?: string;
      /**
       * 标识码code
       */
      entityLinkCode?: string;
      /**
       * 外部地址，类似于mac地址之类
       */
      externalNo?: string;
      /**
       * 附件ID集合
       */
      fileIdList?: number[];
      /**
       * 附件集合
       */
      fileList?: {
        createdAt?: string;
        creator?: {
          /**
           * 描述
           */
          desc?: string;
          /**
           * 用户业务ID
           */
          id?: number;
          /**
           * 用户名
           */
          name?: string;
          /**
           * 工厂ID
           */
          orgId?: number;
          /**
           * 账号
           */
          username?: string;
        };
        extension?: string;
        fileUri?: string;
        filename?: string;
        id?: number;
      }[];
      /**
       * 主键
       */
      id?: number;
      /**
       * 区域id
       */
      locationId?: number;
      /**
       * 区域级联
       */
      locationList?: {}[];
      /**
       * 地址名称
       */
      locationName?: string;
      /**
       * 制造商
       */
      manufacturer?: string;
      /**
       * 名称
       */
      name?: string;
      operateUser?: {
        active?: string;
        avatarUrl?: string;
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
        username?: string;
      };
      /**
       * 操作人
       */
      operatorId?: number;
      /**
       * 出厂编号
       */
      outFactoryDate?: string;
      /**
       * 备注
       */
      remark?: string;
      /**
       * 资源分类id
       */
      resourceClassificationId?: number;
      /**
       * 负责部门
       */
      responsibleDepartment?: {
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
      }[];
      /**
       * 负责人
       */
      responsibleUser?: {
        active?: string;
        avatarUrl?: string;
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
        username?: string;
      }[];
      /**
       * 序列号
       */
      serialNo?: string;
      status?: number;
      /**
       * 单位id
       */
      unitId?: number;
      /**
       * 单位名称
       */
      unitName?: string;
      /**
       * 更新时间
       */
      updatedAt?: string;
      /**
       * 更新时间
       */
      updatedTime?: number;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [资源列表接口↗](http://yapi.blacklake.tech/project/671/interface/api/78632) 的 **请求配置的类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/list`
 * @更新时间 `2022-01-04 20:20:00`
 */
type FetchResourcesListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/671',
    '',
    '',
    '/resource/domain/web/v1/resources/list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [资源列表接口↗](http://yapi.blacklake.tech/project/671/interface/api/78632) 的 **请求配置**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/list`
 * @更新时间 `2022-01-04 20:20:00`
 */
const fetchResourcesListRequestConfig: FetchResourcesListRequestConfig = {
  mockUrl: mockUrl_0_0_3_0,
  devUrl: devUrl_0_0_3_0,
  prodUrl: prodUrl_0_0_3_0,
  path: '/resource/domain/web/v1/resources/list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_3_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchResourcesList',
};

/**
 * 接口 [资源列表接口↗](http://yapi.blacklake.tech/project/671/interface/api/78632) 的 **请求函数**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/list`
 * @更新时间 `2022-01-04 20:20:00`
 */
export const fetchResourcesList = (
  requestData: FetchResourcesListRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchResourcesListResponse>(
    prepare(fetchResourcesListRequestConfig, requestData),
    ...args,
  );
};

fetchResourcesList.requestConfig = fetchResourcesListRequestConfig;

/**
 * 接口 [筛选列表接口，供工作中心可选资源调用↗](http://yapi.blacklake.tech/project/671/interface/api/78638) 的 **请求类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/screen/list`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesScreenListRequest {
  /**
   * 资源编号
   */
  code?: string;
  /**
   * 资源名称
   */
  name?: string;
  orgId?: number;
  /**
   * 页数
   */
  page: number;
  /**
   * 条数
   */
  size: number;
}

/**
 * 接口 [筛选列表接口，供工作中心可选资源调用↗](http://yapi.blacklake.tech/project/671/interface/api/78638) 的 **返回类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/screen/list`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesScreenListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      brandModel?: number;
      /**
       * 品牌型号
       */
      brandModelDesc?: string;
      businessType?: number;
      /**
       * 业务类型
       */
      businessTypeDesc?: string;
      /**
       * 编号
       */
      code?: string;
      coverFile?: {
        createdAt?: string;
        creator?: {
          /**
           * 描述
           */
          desc?: string;
          /**
           * 用户业务ID
           */
          id?: number;
          /**
           * 用户名
           */
          name?: string;
          /**
           * 工厂ID
           */
          orgId?: number;
          /**
           * 账号
           */
          username?: string;
        };
        extension?: string;
        fileUri?: string;
        filename?: string;
        id?: number;
      };
      /**
       * 资源图片ID
       */
      coverFileId?: number;
      createUser?: {
        active?: string;
        avatarUrl?: string;
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
        username?: string;
      };
      /**
       * 创建时间
       */
      createdAt?: string;
      /**
       * 创建时间Long
       */
      createdTime?: number;
      /**
       * 创建人ID
       */
      creatorId?: number;
      /**
       * 启用日期
       */
      enableDate?: string;
      /**
       * 入场日期
       */
      enterFactoryDate?: string;
      /**
       * 标识码code
       */
      entityLinkCode?: string;
      /**
       * 外部地址，类似于mac地址之类
       */
      externalNo?: string;
      /**
       * 附件ID集合
       */
      fileIdList?: number[];
      /**
       * 附件集合
       */
      fileList?: {
        createdAt?: string;
        creator?: {
          /**
           * 描述
           */
          desc?: string;
          /**
           * 用户业务ID
           */
          id?: number;
          /**
           * 用户名
           */
          name?: string;
          /**
           * 工厂ID
           */
          orgId?: number;
          /**
           * 账号
           */
          username?: string;
        };
        extension?: string;
        fileUri?: string;
        filename?: string;
        id?: number;
      }[];
      /**
       * 主键
       */
      id?: number;
      /**
       * 区域id
       */
      locationId?: number;
      /**
       * 区域级联
       */
      locationList?: {}[];
      /**
       * 地址名称
       */
      locationName?: string;
      /**
       * 制造商
       */
      manufacturer?: string;
      /**
       * 名称
       */
      name?: string;
      operateUser?: {
        active?: string;
        avatarUrl?: string;
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
        username?: string;
      };
      /**
       * 操作人
       */
      operatorId?: number;
      /**
       * 出厂编号
       */
      outFactoryDate?: string;
      /**
       * 备注
       */
      remark?: string;
      /**
       * 资源分类id
       */
      resourceClassificationId?: number;
      /**
       * 负责部门
       */
      responsibleDepartment?: {
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
      }[];
      /**
       * 负责人
       */
      responsibleUser?: {
        active?: string;
        avatarUrl?: string;
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
        username?: string;
      }[];
      /**
       * 序列号
       */
      serialNo?: string;
      status?: number;
      /**
       * 单位id
       */
      unitId?: number;
      /**
       * 单位名称
       */
      unitName?: string;
      /**
       * 更新时间
       */
      updatedAt?: string;
      /**
       * 更新时间
       */
      updatedTime?: number;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [筛选列表接口，供工作中心可选资源调用↗](http://yapi.blacklake.tech/project/671/interface/api/78638) 的 **请求配置的类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/screen/list`
 * @更新时间 `2022-01-04 20:20:00`
 */
type FetchResourcesScreenListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/671',
    '',
    '',
    '/resource/domain/web/v1/resources/screen/list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [筛选列表接口，供工作中心可选资源调用↗](http://yapi.blacklake.tech/project/671/interface/api/78638) 的 **请求配置**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/screen/list`
 * @更新时间 `2022-01-04 20:20:00`
 */
const fetchResourcesScreenListRequestConfig: FetchResourcesScreenListRequestConfig = {
  mockUrl: mockUrl_0_0_3_0,
  devUrl: devUrl_0_0_3_0,
  prodUrl: prodUrl_0_0_3_0,
  path: '/resource/domain/web/v1/resources/screen/list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_3_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchResourcesScreenList',
};

/**
 * 接口 [筛选列表接口，供工作中心可选资源调用↗](http://yapi.blacklake.tech/project/671/interface/api/78638) 的 **请求函数**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/screen/list`
 * @更新时间 `2022-01-04 20:20:00`
 */
export const fetchResourcesScreenList = (
  requestData: FetchResourcesScreenListRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchResourcesScreenListResponse>(
    prepare(fetchResourcesScreenListRequestConfig, requestData),
    ...args,
  );
};

fetchResourcesScreenList.requestConfig = fetchResourcesScreenListRequestConfig;

/**
 * 接口 [资源状态变更接口↗](http://yapi.blacklake.tech/project/671/interface/api/78644) 的 **请求类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/status_update`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesStatusUpdateRequest {
  /**
   * 资源Id集合
   */
  idList: number[];
  /**
   * 状态
   */
  status?: number;
}

/**
 * 接口 [资源状态变更接口↗](http://yapi.blacklake.tech/project/671/interface/api/78644) 的 **返回类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/status_update`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesStatusUpdateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [资源状态变更接口↗](http://yapi.blacklake.tech/project/671/interface/api/78644) 的 **请求配置的类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/status_update`
 * @更新时间 `2022-01-04 20:20:00`
 */
type FetchResourcesStatusUpdateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/671',
    '',
    '',
    '/resource/domain/web/v1/resources/status_update',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [资源状态变更接口↗](http://yapi.blacklake.tech/project/671/interface/api/78644) 的 **请求配置**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/status_update`
 * @更新时间 `2022-01-04 20:20:00`
 */
const fetchResourcesStatusUpdateRequestConfig: FetchResourcesStatusUpdateRequestConfig = {
  mockUrl: mockUrl_0_0_3_0,
  devUrl: devUrl_0_0_3_0,
  prodUrl: prodUrl_0_0_3_0,
  path: '/resource/domain/web/v1/resources/status_update',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_3_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchResourcesStatusUpdate',
};

/**
 * 接口 [资源状态变更接口↗](http://yapi.blacklake.tech/project/671/interface/api/78644) 的 **请求函数**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/status_update`
 * @更新时间 `2022-01-04 20:20:00`
 */
export const fetchResourcesStatusUpdate = (
  requestData: FetchResourcesStatusUpdateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchResourcesStatusUpdateResponse>(
    prepare(fetchResourcesStatusUpdateRequestConfig, requestData),
    ...args,
  );
};

fetchResourcesStatusUpdate.requestConfig = fetchResourcesStatusUpdateRequestConfig;

/**
 * 接口 [获取业务类型枚举↗](http://yapi.blacklake.tech/project/671/interface/api/82898) 的 **请求类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/brand/model`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesBrandModelRequest {}

/**
 * 接口 [获取业务类型枚举↗](http://yapi.blacklake.tech/project/671/interface/api/82898) 的 **返回类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/brand/model`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesBrandModelResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      brandModel?: number;
      /**
       * 品牌型号
       */
      brandModelDesc?: string;
      businessType?: number;
      /**
       * 业务类型
       */
      businessTypeDesc?: string;
      /**
       * 编号
       */
      code?: string;
      coverFile?: {
        createdAt?: string;
        creator?: {
          /**
           * 描述
           */
          desc?: string;
          /**
           * 用户业务ID
           */
          id?: number;
          /**
           * 用户名
           */
          name?: string;
          /**
           * 工厂ID
           */
          orgId?: number;
          /**
           * 账号
           */
          username?: string;
        };
        extension?: string;
        fileUri?: string;
        filename?: string;
        id?: number;
      };
      /**
       * 资源图片ID
       */
      coverFileId?: number;
      createUser?: {
        active?: string;
        avatarUrl?: string;
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
        username?: string;
      };
      /**
       * 创建时间
       */
      createdAt?: string;
      /**
       * 创建时间Long
       */
      createdTime?: number;
      /**
       * 创建人ID
       */
      creatorId?: number;
      /**
       * 启用日期
       */
      enableDate?: string;
      /**
       * 入场日期
       */
      enterFactoryDate?: string;
      /**
       * 标识码code
       */
      entityLinkCode?: string;
      /**
       * 外部地址，类似于mac地址之类
       */
      externalNo?: string;
      /**
       * 附件ID集合
       */
      fileIdList?: number[];
      /**
       * 附件集合
       */
      fileList?: {
        createdAt?: string;
        creator?: {
          /**
           * 描述
           */
          desc?: string;
          /**
           * 用户业务ID
           */
          id?: number;
          /**
           * 用户名
           */
          name?: string;
          /**
           * 工厂ID
           */
          orgId?: number;
          /**
           * 账号
           */
          username?: string;
        };
        extension?: string;
        fileUri?: string;
        filename?: string;
        id?: number;
      }[];
      /**
       * 主键
       */
      id?: number;
      /**
       * 区域id
       */
      locationId?: number;
      /**
       * 区域级联
       */
      locationList?: {}[];
      /**
       * 地址名称
       */
      locationName?: string;
      /**
       * 制造商
       */
      manufacturer?: string;
      /**
       * 名称
       */
      name?: string;
      operateUser?: {
        active?: string;
        avatarUrl?: string;
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
        username?: string;
      };
      /**
       * 操作人
       */
      operatorId?: number;
      /**
       * 出厂编号
       */
      outFactoryDate?: string;
      /**
       * 备注
       */
      remark?: string;
      /**
       * 资源分类id
       */
      resourceClassificationId?: number;
      /**
       * 负责部门
       */
      responsibleDepartment?: {
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
      }[];
      /**
       * 负责人
       */
      responsibleUser?: {
        active?: string;
        avatarUrl?: string;
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
        username?: string;
      }[];
      /**
       * 序列号
       */
      serialNo?: string;
      status?: number;
      /**
       * 单位id
       */
      unitId?: number;
      /**
       * 单位名称
       */
      unitName?: string;
      /**
       * 更新时间
       */
      updatedAt?: string;
      /**
       * 更新时间
       */
      updatedTime?: number;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取业务类型枚举↗](http://yapi.blacklake.tech/project/671/interface/api/82898) 的 **请求配置的类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/brand/model`
 * @更新时间 `2022-01-04 20:20:00`
 */
type FetchResourcesBrandModelRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/671',
    '',
    '',
    '/resource/domain/web/v1/resources/brand/model',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [获取业务类型枚举↗](http://yapi.blacklake.tech/project/671/interface/api/82898) 的 **请求配置**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/brand/model`
 * @更新时间 `2022-01-04 20:20:00`
 */
const fetchResourcesBrandModelRequestConfig: FetchResourcesBrandModelRequestConfig = {
  mockUrl: mockUrl_0_0_3_0,
  devUrl: devUrl_0_0_3_0,
  prodUrl: prodUrl_0_0_3_0,
  path: '/resource/domain/web/v1/resources/brand/model',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_3_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchResourcesBrandModel',
};

/**
 * 接口 [获取业务类型枚举↗](http://yapi.blacklake.tech/project/671/interface/api/82898) 的 **请求函数**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/brand/model`
 * @更新时间 `2022-01-04 20:20:00`
 */
export const fetchResourcesBrandModel = (
  requestData?: FetchResourcesBrandModelRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchResourcesBrandModelResponse>(
    prepare(fetchResourcesBrandModelRequestConfig, requestData),
    ...args,
  );
};

fetchResourcesBrandModel.requestConfig = fetchResourcesBrandModelRequestConfig;

/**
 * 接口 [获取业务类型枚举↗](http://yapi.blacklake.tech/project/671/interface/api/82904) 的 **请求类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/business/type`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesBusinessTypeRequest {}

/**
 * 接口 [获取业务类型枚举↗](http://yapi.blacklake.tech/project/671/interface/api/82904) 的 **返回类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/business/type`
 * @更新时间 `2022-01-04 20:20:00`
 */
export interface FetchResourcesBusinessTypeResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      brandModel?: number;
      /**
       * 品牌型号
       */
      brandModelDesc?: string;
      businessType?: number;
      /**
       * 业务类型
       */
      businessTypeDesc?: string;
      /**
       * 编号
       */
      code?: string;
      coverFile?: {
        createdAt?: string;
        creator?: {
          /**
           * 描述
           */
          desc?: string;
          /**
           * 用户业务ID
           */
          id?: number;
          /**
           * 用户名
           */
          name?: string;
          /**
           * 工厂ID
           */
          orgId?: number;
          /**
           * 账号
           */
          username?: string;
        };
        extension?: string;
        fileUri?: string;
        filename?: string;
        id?: number;
      };
      /**
       * 资源图片ID
       */
      coverFileId?: number;
      createUser?: {
        active?: string;
        avatarUrl?: string;
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
        username?: string;
      };
      /**
       * 创建时间
       */
      createdAt?: string;
      /**
       * 创建时间Long
       */
      createdTime?: number;
      /**
       * 创建人ID
       */
      creatorId?: number;
      /**
       * 启用日期
       */
      enableDate?: string;
      /**
       * 入场日期
       */
      enterFactoryDate?: string;
      /**
       * 标识码code
       */
      entityLinkCode?: string;
      /**
       * 外部地址，类似于mac地址之类
       */
      externalNo?: string;
      /**
       * 附件ID集合
       */
      fileIdList?: number[];
      /**
       * 附件集合
       */
      fileList?: {
        createdAt?: string;
        creator?: {
          /**
           * 描述
           */
          desc?: string;
          /**
           * 用户业务ID
           */
          id?: number;
          /**
           * 用户名
           */
          name?: string;
          /**
           * 工厂ID
           */
          orgId?: number;
          /**
           * 账号
           */
          username?: string;
        };
        extension?: string;
        fileUri?: string;
        filename?: string;
        id?: number;
      }[];
      /**
       * 主键
       */
      id?: number;
      /**
       * 区域id
       */
      locationId?: number;
      /**
       * 区域级联
       */
      locationList?: {}[];
      /**
       * 地址名称
       */
      locationName?: string;
      /**
       * 制造商
       */
      manufacturer?: string;
      /**
       * 名称
       */
      name?: string;
      operateUser?: {
        active?: string;
        avatarUrl?: string;
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
        username?: string;
      };
      /**
       * 操作人
       */
      operatorId?: number;
      /**
       * 出厂编号
       */
      outFactoryDate?: string;
      /**
       * 备注
       */
      remark?: string;
      /**
       * 资源分类id
       */
      resourceClassificationId?: number;
      /**
       * 负责部门
       */
      responsibleDepartment?: {
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
      }[];
      /**
       * 负责人
       */
      responsibleUser?: {
        active?: string;
        avatarUrl?: string;
        code?: string;
        desc?: string;
        id?: number;
        name?: string;
        orgId?: number;
        username?: string;
      }[];
      /**
       * 序列号
       */
      serialNo?: string;
      status?: number;
      /**
       * 单位id
       */
      unitId?: number;
      /**
       * 单位名称
       */
      unitName?: string;
      /**
       * 更新时间
       */
      updatedAt?: string;
      /**
       * 更新时间
       */
      updatedTime?: number;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取业务类型枚举↗](http://yapi.blacklake.tech/project/671/interface/api/82904) 的 **请求配置的类型**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/business/type`
 * @更新时间 `2022-01-04 20:20:00`
 */
type FetchResourcesBusinessTypeRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/671',
    '',
    '',
    '/resource/domain/web/v1/resources/business/type',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [获取业务类型枚举↗](http://yapi.blacklake.tech/project/671/interface/api/82904) 的 **请求配置**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/business/type`
 * @更新时间 `2022-01-04 20:20:00`
 */
const fetchResourcesBusinessTypeRequestConfig: FetchResourcesBusinessTypeRequestConfig = {
  mockUrl: mockUrl_0_0_3_0,
  devUrl: devUrl_0_0_3_0,
  prodUrl: prodUrl_0_0_3_0,
  path: '/resource/domain/web/v1/resources/business/type',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_3_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchResourcesBusinessType',
};

/**
 * 接口 [获取业务类型枚举↗](http://yapi.blacklake.tech/project/671/interface/api/82904) 的 **请求函数**
 *
 * @分类 [web端资源定义↗](http://yapi.blacklake.tech/project/671/interface/api/cat_11232)
 * @标签 `web端资源定义`
 * @请求头 `POST /resource/domain/web/v1/resources/business/type`
 * @更新时间 `2022-01-04 20:20:00`
 */
export const fetchResourcesBusinessType = (
  requestData?: FetchResourcesBusinessTypeRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchResourcesBusinessTypeResponse>(
    prepare(fetchResourcesBusinessTypeRequestConfig, requestData),
    ...args,
  );
};

fetchResourcesBusinessType.requestConfig = fetchResourcesBusinessTypeRequestConfig;

/* prettier-ignore-end */
