import React from 'react';
import BatchExportExcel from './BatchExportExcel';
import BatchImportExcel from './BatchImportExcel';
import { useCloseExImportModal } from '../utiles';
import lookup from 'src/dict';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

/** 导入导出弹框 */
const ExImportModal = () => {
  const { visible, optType, businessType } = useSelector((state: RootState) => state.excelImport);
  const closeModal = useCloseExImportModal();
  const params = lookup('objectImport.importParams', businessType);

  if (!visible) {
    return null;
  }
  return (
    <>
      {optType === 'import' ? (
        <BatchImportExcel closeModal={closeModal} importParams={params} />
      ) : (
        <BatchExportExcel closeModal={closeModal} exportParams={{ ...params }} />
      )}
    </>
  );
};

export default ExImportModal;
