import { SearchSelectModalConfig } from '../index.type';
import { FilterFieldType } from '@blacklake-web/layout';
import { fetchStorageWarehousePageList } from 'src/api/ytt/storage-domain/warehouse';
import { BlTooltip } from 'src/components';

export const warehouse: SearchSelectModalConfig<any> = {
  title: '仓库',
  requestFn: fetchStorageWarehousePageList,
  columns: [
    {
      title: '仓库编号',
      dataIndex: 'code',
      width: 200,
      filterConfig: {
        type: FilterFieldType?.text,
      },
    },
    {
      title: '仓库名称',
      dataIndex: 'name',
      width: 200,
      filterConfig: {
        type: FilterFieldType?.text,
      },
    },
  ],
  renderLabel: ({ code, name } = {}) => {
    let label = `${code}|${name}`;
    if (!code && !name) label = '';
    return <BlTooltip text={label} />;
  },
};
