import { BlSortFormList, TagList } from 'src/components';
import { Form, FormInstance, Input, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { renderAmount } from '../utils';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import _Array from 'src/utils/dataTypes/array';
import { replaceSign } from 'src/utils/constants';
import _ from 'lodash';
import { TextControl } from 'src/components/text';
import { getMaterialUnitInfo } from 'src/entity/material';
import { useEffect } from 'react';

interface StoreDetailProps {
  selectedRows?: any[];
  form: FormInstance;
  name: string;
}

const returnTable = (props: StoreDetailProps) => {
  const { selectedRows, form, name } = props;

  form.setFieldsValue({ returnInfo: selectedRows });

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    const baseColumns: ColumnProps<any & FormListFieldData>[] = [
      {
        title: '物料编号',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field.name, 'material', 'baseInfo', 'code']}
              fieldKey={[field?.key, 'materialCode']}
              style={{ marginBottom: 0 }}
            >
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        title: '物料名称',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'material', 'baseInfo', 'name']}
              fieldKey={[field?.key, 'materialName']}
              style={{ marginBottom: 0 }}
            >
              <TextControl />
            </Form.Item>
          );
        },
      },
      {
        title: '物料属性',
        dataIndex: ['material', 'attribute'],
        width: 250,
        render: (value, field) => {
          const attribute = form.getFieldValue(name)[field.name]?.material?.attribute;

          if (_Array.isEmpty(attribute)) {
            return replaceSign;
          }
          return (
            <TagList
              dataSource={_.map(attribute, ({ id, name, attributeItem }) => ({
                label: `${name}:${attributeItem?.content}`,
                value: id,
              }))}
            />
          );
        },
      },
      {
        title: '应退数',
        dataIndex: 'returnAmount',
        width: 150,
        key: 'returnAmount',
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'returnAmount']}
              fieldKey={[field.key, 'returnAmount']}
              validateFirst
              rules={[{ required: true, message: '应退数不能为空' }]}
              style={{ marginBottom: 0 }}
            >
              {renderAmount(form.getFieldValue(name)[field.name].returnAmount)}
            </Form.Item>
          );
        },
      },
      {
        title: '应退时间',
        dataIndex: 'returnTime',
        width: 200,
        key: 'returnTime',
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'returnTime']}
              fieldKey={[field.key, 'returnTime']}
              style={{ marginBottom: 0 }}
            >
              {formatTimeForRender(form.getFieldValue(name)[field.name].returnTime)}
            </Form.Item>
          );
        },
      },
      {
        title: '发出数',
        dataIndex: 'deliverAmount',
        width: 200,
        key: 'deliverAmount',
        render: (_, field) => {
          // 查询出该单位的精度信息
          const material = form.getFieldValue(name)[field.name]?.material;
          const unitId = form.getFieldValue(name)[field.name]?.returnAmount?.unitId;
          const unitInfo = getMaterialUnitInfo(material, unitId);
          const { precisionFigure } = unitInfo;

          return (
            <Form.Item
              name={[field?.name, 'deliverAmount']}
              fieldKey={[field.key, 'deliverAmount']}
              validateFirst
              rules={[
                { required: true, message: '请输入发出数' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: false,
                    message: '请输入大于0的数字',
                  }),
                },
                {
                  // 精度校验从单位中获取
                  validator: fractionLengthCheck(
                    precisionFigure,
                    `请输入${precisionFigure}位精度的数字`,
                  ),
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input
                addonAfter={unitInfo?.name}
                type="number"
                placeholder={'请输入发出数'}
                onChange={(e) => {
                  form.setFieldsValue({
                    deliverAmount: e.currentTarget.value,
                  });
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '采购订单编号',
        dataIndex: 'purchaseOrderCode',
        key: 'purchaseOrderCode',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'purchaseOrderCode']}
              fieldKey={[field?.key, 'purchaseOrderCode']}
              style={{ marginBottom: 0 }}
            >
              {form.getFieldValue(name)[field.name].purchaseOrderCode}
            </Form.Item>
          );
        },
      },
      {
        title: '采购订单行号',
        dataIndex: 'seqNo',
        key: 'seqNo',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'seqNo']}
              fieldKey={[field?.key, 'seqNo']}
              style={{ marginBottom: 0 }}
            >
              {form.getFieldValue(name)[field.name].purchaseOrderSeqNo}
            </Form.Item>
          );
        },
      },
    ];

    return baseColumns;
  };

  return (
    <BlSortFormList
      form={form}
      name={name}
      renderColumns={() => getColumns()}
      isNeedDrag={false}
      isNeedAdd={false}
    />
  );
};

export default returnTable;
