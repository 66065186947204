import React from 'react';
import moment from 'moment';
import { Descriptions } from 'antd';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
//
import { BomResponse } from '../index.type';

interface OtherInfoTabProps {
  dataSource?: BomResponse;
}

const OtherInfoTab = (props: OtherInfoTabProps) => {
  const { dataSource } = props;

  const { operator, updatedAt, creator, createdAt, remark } = dataSource ?? {};

  return (
    <div style={{ padding: 24 }}>
      <Descriptions column={2}>
        <Descriptions.Item label="备注" span={2}>
          {remark}
        </Descriptions.Item>
        <Descriptions.Item label="创建人">
          {creator && <AvatarDisplay {...creator} isShowTag={false} isUser />}
        </Descriptions.Item>
        <Descriptions.Item label="创建时间">
          {moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label="更新人">
          {operator && <AvatarDisplay {...operator} isShowTag={false} isUser />}
        </Descriptions.Item>
        <Descriptions.Item label="更新时间">
          {moment(updatedAt).format('YYYY-MM-DD HH:mm:ss')}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default OtherInfoTab;
