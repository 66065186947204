/**
 * 自定义布局详情页渲染方法
 */
import { Spin, Empty } from 'antd';
import _ from 'lodash';
import { DetailLayout, DetailLayoutInfoItem, DetailLayoutMenuItem } from 'src/layout';
import type { DetailLayoutProps } from 'src/layout';
import { useState } from 'react';
import { useCustomLayoutForDetail } from './hooks/useCustomLayoutForDetail';
import SingleOperationLogComponent from '../singleOperationLog';

interface CustomDetailProps extends DetailLayoutProps {
  objectCode: string;
  instanceIdParamKey?: string | number; // 路由上的实例id参数名，可选（默认为 'instanceId'）
  customInfoItems?: DetailLayoutInfoItem[];
  layoutId: number;
  title: string;
}

export default function CustomDetailLayout(props: CustomDetailProps): JSX.Element {
  const { objectCode, instanceIdParamKey, title, baseMenu, layoutId, customInfoItems } = props;

  const { configLoading, loading, info, dataSource, containerRender } = useCustomLayoutForDetail({
    objectCode,
    instanceId: Number(instanceIdParamKey),
    customInfoItems,
    layoutId,
  });

  if (configLoading) {
    return (
      <div
        style={{
          height: '100%',
          background: '#fff',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Spin spinning={configLoading}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Spin>
      </div>
    );
  }

  return containerRender ? (
    <DetailLayout dataSource={dataSource} baseMenu={baseMenu} info={[]}>
      {containerRender}
    </DetailLayout>
  ) : (
    <DetailLayout
      title={title}
      dataSource={dataSource}
      info={info}
      loading={loading}
      baseMenu={baseMenu}
      topContenxt={containerRender}
    />
  );
}
