import React from 'react';
import _ from 'lodash';
import { Form, FormInstance, Select } from 'antd';
import { BlSortFormList, SearchSelectWithCreate } from 'src/components';
import { FIELDS } from 'src/utils/constants';
import LocalStorage from 'src/utils/localStorage';
//
import {
  EnergyMeterCreateApiType,
  EnergyMeterDetailType,
  IEnergyMeterForm,
  IEnergyTagForm,
} from '../index.d';
import { goToCreateResourceTag } from '../../definitions/navigates';
import { ResourceTagChildType } from '../../definitions/index.d';
import { appEnum } from 'src/dict';
import authDict, { hasAuth } from 'src/utils/auth';

export const formatDataToApi = (data: IEnergyTagForm[]): EnergyMeterCreateApiType['tagsIdList'] => {
  if (_.isEmpty(data)) return [];

  return _.compact(_.map(data, ({ content, tagsLinkId }) => ({ id: tagsLinkId, tagsId: content })));
};

export const formatDataToForm = (
  tagsList: EnergyMeterDetailType['tagsList'],
  tagsTree: EnergyMeterDetailType['tagsTree'],
): IEnergyTagForm[] => {
  if (_.isNil(tagsList) || _.isNil(tagsTree)) return [];

  return _.map(tagsList, (tag) => {
    const tagTreeIndex = _.findIndex(tagsTree, ['name', tag.name]);

    return {
      name: {
        label: tag?.name ?? '',
        value: JSON.stringify(tagsTree[tagTreeIndex]),
      },
      content: tag.id,
      tagsLinkId: tag.tagsLinkId,
    };
  });
};

interface FormListProps {
  form: FormInstance<IEnergyMeterForm>;
  name: string;
}

const FormList = (props: FormListProps) => {
  const { form, name } = props;

  /**
   * 校验标签/参数,不能重复
   */
  const validateDuplicated =
    (listField: string, field: string, message: string) => (rule: any, value: any) => {
      if (_.isNil(value)) return Promise.resolve('');
      const formList = form.getFieldValue(listField);

      if (_.isEmpty(formList)) return Promise.resolve('');

      const { id: curParamsId } = JSON.parse(value?.value ?? '{}');
      const keyList = formList?.map((formItem: any) => {
        const { id } = JSON.parse(formItem[field]?.value ?? '{}');

        return id;
      });

      const firstIndex = _.findIndex(keyList, (key) => key === curParamsId);
      const lastIndex = _.findLastIndex(keyList, (key) => key === curParamsId);

      if (firstIndex !== lastIndex) {
        return Promise.reject(message);
      }

      return Promise.resolve('');
    };

  return (
    <div>
      <BlSortFormList
        form={form}
        name={name}
        isNeedDrag={false}
        maxCount={10}
        renderColumns={() => {
          return [
            {
              title: '标签项',
              render: (text, field) => (
                <Form.Item
                  name={[field.name, 'name']}
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: '标签项不能为空',
                    },
                    {
                      validator: validateDuplicated(name, 'name', '不能选择重复的标签项'),
                    },
                  ]}
                >
                  <SearchSelectWithCreate
                    placeholder="请选择标签项"
                    fetchType="resourceTagTree"
                    createFieldName="标签"
                    params={{ businessType: appEnum.Resources.ResourceBusinessType.energy }}
                    onCreateClick={() => {
                      LocalStorage.set(FIELDS.RESOURCE_TAG_IS_CREATE, true);
                      goToCreateResourceTag();
                    }}
                    onChange={() => {
                      // 变动时，一并清空标签值
                      form.setFields([
                        {
                          name: [name, field.name, 'content'],
                          value: undefined,
                        },
                      ]);
                    }}
                    // TODO: authority needed
                    enableAdd={hasAuth(authDict.resourcelabel_add)}
                  />
                </Form.Item>
              ),
            },
            {
              title: '标签值',
              render: (text, field) => {
                return (
                  <Form.Item dependencies={[[name, field.name, 'name']]} noStyle>
                    {() => {
                      const tagValuesString = form.getFieldValue([name, field.name, 'name']);
                      const tagValues = tagValuesString && JSON.parse(tagValuesString.value);

                      return (
                        <Form.Item
                          name={[field.name, 'content']}
                          style={{ marginBottom: 0 }}
                          rules={[
                            {
                              required: true,
                              message: '标签值不能为空',
                            },
                          ]}
                        >
                          <Select
                            placeholder="请选择标签值"
                            options={tagValues?.children?.map((tagValue: ResourceTagChildType) => ({
                              label: tagValue.content,
                              value: tagValue.id,
                            }))}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
          ];
        }}
      />
    </div>
  );
};

export default FormList;
