import React, { useState, useEffect } from 'react';
import PositionsList from './list';
import { Tooltip, message as Message, Form } from 'antd';
import { useTreeContent } from 'layout';
import CreateArea from '../area/create';
import AreaEdit from '../area/edit';
import AreaDetail from '../area/detail';
import { BlIcon } from 'components';
import { formatTreeData } from '../utils';
import { ParentPropsData, StoreHousePropsData, NodeItem, TreeSourceItem } from '../interface';
import { LEVEL_TYPE_ENUM, STORAGE_TYPE_TYPE_ENUM, StoreContext } from '../constant';
import './index.css';
import BLDelConfirm from '../../share/modalConfirm';
import BlTooltip from 'src/page/numberRules/share/tooltip';
import { fetchStorageWarehouseAllList } from 'src/api/ytt/storage-domain/warehouse';
import { fetchStorageAreaBulkDelete } from 'src/api/ytt/storage-domain/area';
import authDict, { hasAuth } from 'src/utils/auth';

export default function Positions() {
  const [treeSource, setTreeSource] = useState<any>([]);

  const [visibleDetail, setVisibleDetail] = useState(false); // 是否显示区域详情
  const [editVisible, setEditVisible] = useState(false); // 显示区域编辑
  const [createVisible, setCreateVisible] = useState(false); // 显示区域创建
  const [parentAreaData, setParentAreaData] = useState<ParentPropsData>({} as any); // 父级区域对象
  const [storeHouseData, setStoreHouseData] = useState<StoreHousePropsData>({} as any); // 仓库对象

  const [clickId, setClickId] = useState<any>();
  const [refreshListMarker, setRefreshListMarker] = useState<number>(0);
  const [refreshDetailMarker, setRefreshDetailMarker] = useState<number>(0);

  const maxHeight = document.getElementById('positions')?.clientHeight; // treeContent的高度

  const [delForm] = Form.useForm();

  const fetchData = async () => {
    try {
      const { data } = await fetchStorageWarehouseAllList({}, { legacy: true });

      if (data) {
        const treeDataSource = formatTreeData(data);
        const treeData = [{ title: '全工厂', key: 1, level: 0, children: treeDataSource }];

        setTreeSource(treeData);
      }
    } catch (error) {
      console.log('err: ', error);
    }
  };

  const refreshPositionTable = () => {
    setRefreshListMarker(Date.now());
  };

  // const handleFinish = async () => {
  //   try {
  //     // const value = await delForm?.validateFields();
  //     // TODO：后面补充删除的原因
  //     const delId = [clickId];

  //     const { code, message } = await fetchStorageAreaBulkDelete({ ids: delId });

  //     if (code === 200) {
  //       Message.success('删除成功');
  //       areaDetailRefresh();
  //       return;
  //     }
  //     Message.error(message);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const hasAuthDetail = hasAuth(authDict.zone_detail);

  const hasAuthCreate = hasAuth(authDict.zone_add);
  const hasAuthEdit = hasAuth(authDict.zone_edit);

  const iconFn = (title: string, type: string, onClickFn: () => void) => {
    return (
      <div
        style={{ marginLeft: 10 }}
        onClick={() => {
          typeof onClickFn === 'function' && onClickFn();
        }}
      >
        <Tooltip title={title} className="icons">
          <BlIcon type={type} />
        </Tooltip>
      </div>
    );
  };

  const customTitleRender = (data: any) => {
    const nodeTitle = data?.title;

    return (
      <div style={{ display: 'flex' }} className="treeNode">
        <div onClick={refreshPositionTable}>
          <BlTooltip width={100} title={nodeTitle} className="treeNode">
            {nodeTitle}
          </BlTooltip>
        </div>
        <div style={{ marginLeft: 10 }} className="iconNode">
          {data.level !== LEVEL_TYPE_ENUM.FACTORY &&
            data.level !== LEVEL_TYPE_ENUM.LEVEL_FOURTH &&
            hasAuthCreate &&
            iconFn('添加', 'iconxinjiantianjia', () => {
              setCreateVisible(true);
            })}
          {data.storageType === STORAGE_TYPE_TYPE_ENUM.areaStorageType &&
            hasAuthDetail &&
            iconFn('查看', 'iconzhanshichakan', () => {
              setVisibleDetail(true);
            })}
          {data.storageType === STORAGE_TYPE_TYPE_ENUM.areaStorageType &&
            hasAuthEdit &&
            iconFn('编辑', 'iconbianji', () => {
              setEditVisible(true);
            })}
          {/* {data.storageType === STORAGE_TYPE_TYPE_ENUM.areaStorageType &&
            iconFn('删除', 'iconshanchulajitong1', () => {
              BLDelConfirm('是否删除当前区域及区域下所有仓位', delForm, handleFinish);
            })} */}
        </div>
      </div>
    );
  };

  const getHaveKey = (id: number, tree: NodeItem[]): any => {
    let isHave;

    for (let i = 0; i < tree?.length; i++) {
      const node = tree[i];

      if (node?.id === id) {
        isHave = true;
      }
      if (node?.children) {
        if (node?.children.some((item: NodeItem) => item?.id === id)) {
          isHave = true;
        } else if (getHaveKey(id, node?.children)) {
          isHave = getHaveKey(id, node?.children);
        }
      }
    }
    return isHave;
  };

  const getStore = (id: number, tree: TreeSourceItem[]): any => {
    for (let i = 0; i < tree?.length; i++) {
      const node = tree[i];

      const warehouse = node?.children;

      for (let k = 0; k < warehouse?.length; k++) {
        const warehouseItem = warehouse[k];

        if (warehouseItem?.children) {
          const isHave = getHaveKey(id, warehouseItem?.children);
          // 循环遍历children里是否有

          if (isHave) {
            setStoreHouseData({
              StoreHouseId: warehouseItem?.id,
              StoreHouseName: warehouseItem?.name,
              StoreHouseCode: warehouseItem?.code,
            });

            return;
          }
        }
      }
    }
  };

  const onSelect = (node: NodeItem) => {
    if (node?.level === LEVEL_TYPE_ENUM?.FACTORY) {
      // 工厂节点
      setParentAreaData({
        parentAreaLevel: node?.level,
      });
      setStoreHouseData({ StoreHouseId: 0 });
      return;
    }

    const id = node?.id;

    setClickId(id);

    const clickItem = {
      parentAreaId: node?.id,
      parentAreaCode: node?.code,
      parentAreaName: node?.name,
      parentAreaLevel: node?.level,
      parentAreaStorageType: node?.storageType,
    };

    if (clickItem?.parentAreaLevel === LEVEL_TYPE_ENUM?.STORE) {
      // 点击仓库仓库节点 仓库和parent是同一个
      setParentAreaData(clickItem);
      setStoreHouseData({
        StoreHouseId: node?.id,
        StoreHouseName: node?.name,
        StoreHouseCode: node?.code,
      });
      return;
    }
    getStore(clickItem?.parentAreaId, treeSource);
    setParentAreaData(clickItem);
  };

  const icon = { showLeafIcon: false };

  const view = useTreeContent({
    treeData: treeSource,
    showExpandBtn: true,
    detailContent: () => (
      <StoreContext.Provider
        value={{ parentAreaSource: parentAreaData, storeHouseSource: storeHouseData }}
      >
        <PositionsList refreshListMarker={refreshListMarker} />
      </StoreContext.Provider>
    ),
    onSelect,
    checkable: false,
    titleRender: customTitleRender,
    showLine: { icon },
    resizableProp: {
      height: maxHeight,
      maxConstraints: [600, maxHeight],
      minConstraints: [300, maxHeight],
      isChangeHeight: false,
    },
  });

  // 关闭详情弹窗
  const closeDetail = () => {
    setVisibleDetail(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <StoreContext.Provider
      value={{ parentAreaSource: parentAreaData, storeHouseSource: storeHouseData }}
    >
      <div className="positions" style={{ height: '100%' }} id="positions">
        {view}
        {createVisible && (
          <CreateArea
            visible={Boolean(createVisible)}
            onCancel={() => {
              setCreateVisible(false);
              fetchData();
            }}
          />
        )}
        {editVisible && (
          <AreaEdit
            visible={Boolean(editVisible)}
            id={clickId}
            onCancel={() => {
              setEditVisible(false);
            }}
            refreshTreeSource={() => {
              fetchData();
            }}
            refreshDetails={() => {
              setRefreshDetailMarker(Date.now());
            }}
          />
        )}
        {visibleDetail && (
          <AreaDetail
            refreshDetailMarker={refreshDetailMarker}
            visible={visibleDetail}
            closeDetail={closeDetail}
            id={clickId}
            showEdit={() => {
              setEditVisible(true);
            }}
          />
        )}
      </div>
    </StoreContext.Provider>
  );
}
