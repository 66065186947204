/**
 * 工位控件属性
 */
import { CSSProperties, FC, useContext } from 'react';
import _ from 'lodash';
import { Form, Input, Select, Radio, Checkbox } from 'antd';
import type { FormInstance } from 'antd';
import { DefaultLogic, ShowLogic, StationOptionalRange } from 'src/dict/sop';
import {
  editable as editableMappings,
  stationDefaultLogic as stationDefaultLogicMappings,
  stationShowLogic as stationShowLogicMappings,
  stationOptionalRange as stationOptionalRangeMappings,
} from 'src/dict/sop/mappings';
import type { StepDetailData, LocalControlItem } from '../../types';
import {
  ControlEditorContext,
  CONTROL_TITLE_LENGTH,
  INPUT_DEFAULT_PLACEHOLDER,
} from '../../constants';
import SearchSelect from 'src/components/searchSelect';
import { titleRules } from './share';

interface Props {
  form: FormInstance<StepDetailData>;
  localKey: string;
}

const closeMarginBottom: CSSProperties = {
  marginBottom: 10,
};

const getNameFactory = (dataIndex: number) => (name: string) => ['controls', dataIndex, name];

export const StationInputProperties: FC<Props> = ({ form, localKey }) => {
  const { forceUpdate } = useContext(ControlEditorContext);
  const controlList = form.getFieldValue('controls');

  if (_.isEmpty(controlList)) {
    return null;
  }
  const dataIndex = controlList.findIndex((item: LocalControlItem) => item._localKey === localKey);

  if (dataIndex === -1) {
    return null;
  }
  const getName = getNameFactory(dataIndex);
  const { enableEdit, showLogic, defaultLogic, optionalRange, enableCheckbox } =
    controlList[dataIndex];

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={() => {
        forceUpdate();
      }}
    >
      <Form.Item label="标题" name={getName('name')} rules={titleRules}>
        <Input max={CONTROL_TITLE_LENGTH} placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>
      <Form.Item label="权限" name={getName('enableEdit')} rules={[{ required: true }]}>
        <Radio.Group options={editableMappings} />
      </Form.Item>
      {enableEdit && (
        <Form.Item
          label="可选范围"
          name={getName('optionalRange')}
          rules={[{ required: true }]}
          style={optionalRange === StationOptionalRange.appoint ? closeMarginBottom : {}}
        >
          <Select options={stationOptionalRangeMappings} placeholder={'请选择'} />
        </Form.Item>
      )}
      {enableEdit && optionalRange === StationOptionalRange.appoint && (
        <Form.Item
          name={getName('specificValue')}
          rules={[{ required: true, message: '请选择可选范围' }]}
        >
          <SearchSelect labelInValue fetchType={'user'} mode="multiple" placeholder={'添加工位'} />
        </Form.Item>
      )}
      {enableEdit && (
        <Form.Item label="选择方式" style={{ marginBottom: 0 }}>
          <Form.Item name={getName('enableCheckbox')} valuePropName="checked">
            <Checkbox
              onChange={() => {
                form.resetFields([getName('defaultValueList')]);
              }}
            >
              支持多选
            </Checkbox>
          </Form.Item>
        </Form.Item>
      )}
      {!enableEdit && (
        <Form.Item label="显示内容" name={getName('showLogic')} rules={[{ required: true }]}>
          <Select options={stationShowLogicMappings} placeholder={'请选择'} />
        </Form.Item>
      )}
      {!enableEdit && showLogic === ShowLogic.fixedStation && (
        <Form.Item
          name={getName('showValueList')}
          rules={[{ required: true, message: '请添加工位' }]}
        >
          <SearchSelect labelInValue fetchType={'user'} mode="multiple" placeholder={'添加工位'} />
        </Form.Item>
      )}
      {enableEdit && (
        <Form.Item
          label="默认值"
          name={getName('defaultLogic')}
          rules={[{ required: true, message: '请选择默认值' }]}
          style={closeMarginBottom}
        >
          <Select options={stationDefaultLogicMappings} />
        </Form.Item>
      )}
      {enableEdit && defaultLogic === DefaultLogic.fixed && (
        <Form.Item
          name={getName('defaultValueList')}
          rules={[{ required: true, message: '请选择默认值' }]}
          getValueProps={(value) => {
            if (enableCheckbox) {
              return value;
            }
            return { value: value[0] };
          }}
          getValueFromEvent={(value) => {
            if (enableCheckbox) {
              return value;
            }
            return [value];
          }}
        >
          {enableCheckbox ? (
            <SearchSelect
              labelInValue
              fetchType={'user'}
              mode="multiple"
              placeholder={'添加工位'}
            />
          ) : (
            <SearchSelect labelInValue fetchType={'user'} placeholder={'添加工位'} />
          )}
        </Form.Item>
      )}
      <Form.Item label="校验" style={{ marginBottom: 0 }}>
        <Form.Item name={getName('isRequired')} valuePropName="checked" style={{ marginBottom: 0 }}>
          <Checkbox>必填</Checkbox>
        </Form.Item>
      </Form.Item>

      <Form.Item label="描述信息" name={getName('remark')}>
        <Input.TextArea placeholder={INPUT_DEFAULT_PLACEHOLDER} maxLength={1000} />
      </Form.Item>
      <Form.Item
        label="提示文字"
        name={getName('inputPrompt')}
        rules={[{ max: 20, message: '字符数最大为20' }]}
      >
        <Input allowClear placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>
    </Form>
  );
};

export default StationInputProperties;
