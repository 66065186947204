import { Tag, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import './index.scss';

type ValueType = {
  type?: string;
  label: string;
  value: string | number;
};

export type InputTagProps = {
  className?: string;
  style?: any;
  width?: number;
  disabled?: boolean;
  value?: ValueType[];
  onChange?: (value: ValueType[]) => void;
};

const InputTag: React.FC<InputTagProps> = (props) => {
  const { className, style, width, disabled, onChange, value } = props;
  const [tags, setTags] = useState<ValueType[]>(value || []);

  const handleClose = (removedTag: ValueType) => {
    const newTags = tags.filter((tag) => tag.value !== removedTag.value);
    setTags(newTags);
    if (typeof onChange === 'function') {
      onChange(newTags);
    }
  };

  const classes = classNames('tagContent', {
    className,
    disabled,
  });

  useEffect(() => {
    if (value) {
      setTags(value);
    }
  }, [value]);

  return (
    <div className={classes} style={{ ...style, width }}>
      {tags?.map((tag, index) => {
        const { value: _value, label } = tag;
        const isLongTag = label.length > 20;

        const tagElem = (
          <Tag
            className="edit-tag"
            style={{ marginTop: 2, marginBottom: 2 }}
            key={_value}
            closable
            onClose={() => handleClose(tag)}
          >
            {isLongTag ? `${label.slice(0, 20)}...` : label}
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={label} key={_value}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
    </div>
  );
};

export default InputTag;
