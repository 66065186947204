import authDict from 'src/utils/auth';

import PlannedOrderList from './plannedOrder/list';
import PlannedOrderCreateAndEdit from './plannedOrder/createAndEdit';
import PlannedOrderDeatil from './plannedOrder/detail';

import PlannedOrderLog from './plannedOrder/log/list';
import PlannedOrderLogDetail from './plannedOrder/log/detail';

import MaterialSchedulingList from './materialScheduling/list';

import PlanningOperationList from './planningOperation/list';
import PlanningOperationrLog from './planningOperation/log/list';
import PlanningOperationrLogDetail from './planningOperation/log/detail';

export const PRODUCTION_PLANNING_PATH = '/planned/execution';

const materialPlanRouteConfig = [
  {
    path: '/planned',
    menu: '计划',
    breadcrumb: '计划',
    icon: 'iconyijicaidan-shengchan',
    redirectPath: `${PRODUCTION_PLANNING_PATH}/planned`,
    children: [
      {
        path: `${PRODUCTION_PLANNING_PATH}`,
        menu: '物料计划',
        breadcrumb: '物料计划',
        redirectPath: `${PRODUCTION_PLANNING_PATH}/plannedOrder`,
        children: [
          {
            path: `${PRODUCTION_PLANNING_PATH}/plannedOrder`,
            component: PlannedOrderList,
            menu: '计划订单',
            logPage: true,
            breadcrumb: '计划订单',
            auth: authDict.planOrder_view,
            children: [
              {
                path: `${PRODUCTION_PLANNING_PATH}/plannedOrder/create`,
                component: PlannedOrderCreateAndEdit,
                menu: '新建计划订单',
                breadcrumb: '新建计划订单',
                auth: authDict.planOrder_add,
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/plannedOrder/edit/:id`,
                component: PlannedOrderCreateAndEdit,
                menu: '编辑计划订单',
                breadcrumb: '编辑计划订单',
                auth: authDict.planOrder_edit,
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/plannedOrder/copy/:id`,
                component: PlannedOrderCreateAndEdit,
                menu: '复制计划订单',
                breadcrumb: '复制计划订单',
                auth: authDict.planOrder_add,
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/plannedOrder/detail/:id`,
                component: PlannedOrderDeatil,
                menu: '计划订单详情',
                breadcrumb: '计划订单详情',
                auth: authDict.planOrder_detail,
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/plannedOrder/log`,
                component: PlannedOrderLog,
                menu: '投放日志',
                breadcrumb: '投放日志',
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/plannedOrder/log/detail/:id`,
                component: PlannedOrderLogDetail,
                menu: '投放日志详情',
                breadcrumb: '投放日志详情',
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/plannedOrder/materialScheduling`,
                component: MaterialSchedulingList,
                menu: '物料排程设置',
                breadcrumb: '物料排程设置',
              },
            ],
          },
          {
            path: `${PRODUCTION_PLANNING_PATH}/planningOperation`,
            component: PlanningOperationList,
            menu: '计划运算',
            logPage: true,
            breadcrumb: '计划运算',
            auth: authDict.MaterialPlanConfigura_view,
            children: [
              {
                path: `${PRODUCTION_PLANNING_PATH}/planningOperation/log`,
                component: PlanningOperationrLog,
                menu: '计划运算日志',
                breadcrumb: '计划运算日志',
                auth: authDict.MaterialPlanConfigura_operrecord,
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/planningOperation/log/detail/:id`,
                component: PlanningOperationrLogDetail,
                menu: '计划运算日志详情',
                breadcrumb: '计划运算日志详情',
                auth: authDict.MaterialPlanConfigura_operrecord,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default materialPlanRouteConfig;
