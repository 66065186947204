import { useState, useEffect } from 'react';
import { Input, Select, Form, message, Tooltip } from 'antd';
import _ from 'lodash';
import { DataFormLayout, DataFormLayoutForModal } from 'layout';
import { batchTerminalList } from '../constants';
import { ActionModalProps, RoleOption } from '../index.d';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { fetchUserResetPasswordByAdmin, fetchUserGetOrgUserNum } from 'src/api/ytt/user-domain';

import { getRoleList } from 'src/services/organization/rolesApi';
import { hashPassword } from 'src/utils/string';
import { passwordCheck, validateRepeatWith } from 'src/utils/formValidators/index';
import { deepGrey } from 'src/styles/color';
import { roleTagRender, renderLicenses } from '../userUtils';
import { RoleAvatar } from 'src/components/avatar/show';
import { fetchUserBulkUpdate, fetchUserUpdateTerminal } from 'src/api/ytt/user-domain/user';
import { Rule } from 'antd/lib/form';
import { SYSTEM_ADMIN } from '../../constants';

export const UserActionModal = ({
  actionType,
  defaultValues,
  isBulkAction,
  setIsRefreshTable,
  bulkIds,
  visible,
  onClose,
}: ActionModalProps) => {
  const [modalForm] = Form.useForm();
  const [roleList, setRoleList] = useState<RoleOption[]>([]);
  const [freeAccountNums, setFreeAccountNums] =
    useState<{ appFreeNum: number; appAndWebFreeNum: number }>();
  const [terminalType, setTerminalType] = useState<'appFreeNum' | 'appAndWebFreeNum'>();

  const departmentValidator = (rule: Rule, value: any[]) => {
    if (!value) {
      return Promise.reject('请选择部门');
    }
    if (value.length > 10) {
      return Promise.reject('部门最多选择10个');
    }
    return Promise.resolve('');
  };

  const roleValidator = (rule: Rule, value: any[]) => {
    if (!value) {
      return Promise.reject('请选择角色');
    }
    if (value.length > 10) {
      return Promise.reject('角色最多选择10个');
    }
    return Promise.resolve('');
  };

  const actionList = [
    {
      type: 'setDepartment',
      modalTitle: '配置部门',
      column: 1,
      items: [
        {
          label: '部门',
          name: 'departmentIds',
          rules: [{ required: true, validator: departmentValidator }],
          render: () => <UserOrDepartmentSelectWithDialog isMultiple />,
        },
      ],
    },
    {
      type: 'setRole',
      modalTitle: '配置角色',
      column: 1,
      items: [
        {
          label: '角色',
          name: 'roleIds',
          rules: [{ required: true, validator: roleValidator }],
          render: () => (
            <Select
              placeholder="请选择用户角色"
              mode="multiple"
              tagRender={roleTagRender}
              filterOption={(inputValue: string, option: RoleOption | undefined) =>
                String(option?.label)?.indexOf(inputValue) > -1
              }
            >
              {roleList.map((role) => (
                <Select.Option
                  hidden={Boolean(role.code === SYSTEM_ADMIN)}
                  value={role.value}
                  label={role.label}
                  key={role.value}
                >
                  <Tooltip
                    title={renderLicenses(role.roleOwnedLicenses || [])}
                    placement="topLeft"
                    color={'#fff'}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <RoleAvatar name={role.label} />
                      <span
                        style={{
                          display: 'inline-block',
                          maxWidth: 70,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {role.label}
                      </span>
                    </div>
                  </Tooltip>
                </Select.Option>
              ))}
            </Select>
          ),
        },
      ],
    },
    {
      type: 'setTerminal',
      modalTitle: '配置账号类型',
      column: 1,
      items: [
        {
          label: '账号类型',
          name: 'terminal',
          rules: [{ required: true, message: '请选择账号类型' }],
          render: () => (
            <>
              <Select
                options={batchTerminalList}
                onSelect={(value: number) => {
                  setTerminalType(
                    (value === 1 && 'appFreeNum') ||
                      (value === 2 && 'appAndWebFreeNum') ||
                      undefined,
                  );
                  modalForm.setFieldsValue({ terminal: value });
                }}
              />
              {!!terminalType && !!freeAccountNums && (
                <div style={{ color: deepGrey, marginTop: 10 }}>
                  {`该类型账号剩余可用 ${freeAccountNums[terminalType]} 个`}
                </div>
              )}
            </>
          ),
        },
      ],
    },
    {
      modalTitle: '重置密码',
      type: 'resetPassword',
      column: 1,
      items: [
        {
          label: '用户名称',
          name: 'name',
          render: () => defaultValues?.name,
        },
        {
          label: '账号',
          name: 'username',
          render: () => defaultValues?.username,
        },
        {
          label: '新密码',
          name: 'newPassword',
          validateTrigger: 'onBlur',
          rules: [
            {
              validator: passwordCheck('请输入新密码'),
            },
          ],
          render: () => <Input.Password placeholder="请输入新密码" />,
          onBlur: () => {
            if (modalForm.getFieldValue(['oldPassword'])) {
              modalForm.validateFields(['oldPassword']);
            }
          },
        },
        {
          label: '确认新密码',
          name: 'oldPassword',
          validateTrigger: 'onBlur',
          rules: [
            { validator: passwordCheck('请确认新密码') },
            {
              validator: validateRepeatWith(
                { namePath: 'newPassword', label: '新密码', message: '两次密码输入不一致' },
                modalForm,
              ),
            },
          ],
          render: () => <Input.Password placeholder="请确认新密码" />,
        },
      ],
    },
  ];

  const bulkInfo = actionList[_.findIndex(actionList, { type: actionType })];

  useEffect(() => {
    if (actionType === 'setRole') {
      const { roles } = getRoleList({ page: 1, size: 500 });

      roles.then((res) => {
        const _roleList = res.data?.list.map((i: any) => {
          return {
            label: i.name,
            value: i.id,
            code: i.code,
            roleOwnedLicenses: i.roleOwnedLicenses,
          };
        });

        setRoleList(_roleList || []);
      });
    }
    if (actionType === 'setTerminal') {
      fetchUserGetOrgUserNum().then((res: any) => {
        if (res?.code === 200) {
          const { appFreeNum, appAndWebFreeNum } = res?.data;

          setFreeAccountNums({ appFreeNum, appAndWebFreeNum });
        }
      });
    }
  }, []);

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();
      let actionFun = null;

      if (value.departmentIds) {
        value.departmentIds = _.map(value.departmentIds, 'value');
      }
      // 批量修改角色和部门使用新接口
      if (isBulkAction) {
        switch (actionType) {
          case 'setDepartment':
            actionFun = fetchUserBulkUpdate({
              userIdList: bulkIds || [],
              departmentIdList: value.departmentIds || [],
            });
            break;
          case 'setRole':
            actionFun = fetchUserBulkUpdate({
              userIdList: bulkIds || [],
              roleIdList: value.roleIds || [],
            });
            break;
          default:
            actionFun = fetchUserUpdateTerminal(
              {
                idList: bulkIds,
                ...value,
              } || [],
            );
            break;
        }
      }
      if (actionType === 'resetPassword') {
        // const [{ hashHex: newHex }, { hashHex: oldHex }] = await Promise.all([hashPassword(value.newPassword), hashPassword(value.oldPassword)]);
        const { hashHex } = await hashPassword(value.oldPassword);

        actionFun = fetchUserResetPasswordByAdmin({
          checkNewPassword: hashHex,
          newPassword: hashHex,
          username: defaultValues.username,
        });
      }
      if (actionFun) {
        const res = await actionFun;

        if (res.code === 200) {
          message.success('操作成功');
          onClose?.();
          setIsRefreshTable?.(Math.random() * 100);
        } else {
          message.error(res.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderTopContent = (actionType: string) => {
    return actionType === 'resetPassword' ? (
      <div style={{ paddingLeft: 40, paddingTop: 10, color: '#ffa940' }}>
        <div>密码规则：</div>
        <div>
          密码至少8位，必须包含数字、大写字母、小写字母、字符符号中的至少三种，不支持中文、空格
        </div>
      </div>
    ) : null;
  };

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      width={800}
      onClose={() => onClose?.()}
      content={
        <DataFormLayout
          form={modalForm}
          title={bulkInfo.modalTitle}
          topContext={renderTopContent(actionType)}
          onCancel={() => {
            onClose?.();
          }}
          info={[bulkInfo]}
          onFinish={handleFinish}
        />
      }
    />
  );
};
