import { isNull, isUndefined } from 'lodash-es';
import _Array from './array';
import _String from './string';

interface ObjectProps {
  isEmpty: (obj: any) => boolean;
  filterEmptyProperty: (obj: any) => any;
}

/**
 * @description 判断object是否为空
 * @param obj
 * @returns
 */
const isEmpty = (obj: any): boolean => {
  return !Object.keys(obj).length;
};

/**
 * @description 判断是否为空 包含null  undefined ’‘  [] {}
 * @param obj
 * @returns
 */
const allTypeIsEmpty = (obj: any): boolean => {
  return (
    isUndefined(obj) || isNull(obj) || isEmpty(obj) || _Array.isEmpty(obj) || _String.isEmpty(obj)
  );
};

/**
 * @description 过滤object的空属性
 * @param obj
 * @returns
 */
const filterEmptyProperty = (obj: any): any => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === '' || obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
};

/**
 * @description: 用来寻找object类型枚举常量。
 * @param allData  object对象
 * @param fieldname 查找的object名称
 * @returns  {fieldname: value}
 */
const baseFind = (allData: ObjectProps, fieldname: string | number = 'value'): any => {
  return (value: any) => {
    let res: any = {};

    Object.values(allData).forEach((i) => {
      if (i && i[`${fieldname}`] === value) res = i;
    });
    return res;
  };
};

interface FuncProps {
  isEmpty: (o: any) => boolean;
  baseFind: (o: any, value: string | number) => any;
  filterEmptyProperty: (o: any) => any;
  allTypeIsEmpty: (o: any) => any;
}

const _Object = {} as FuncProps;

_Object.isEmpty = isEmpty;
_Object.baseFind = baseFind;
_Object.filterEmptyProperty = filterEmptyProperty;
_Object.allTypeIsEmpty = allTypeIsEmpty;

export default _Object;
