import React from 'react';
import { DataFormLayoutForModal } from 'layout';
import BaseInfo from './baseInfo';

interface Props {
  visible: Boolean;
  refreshTable?: () => void;
  refreshDetails?: () => void;
  onCancel: () => void;
  saveCallback?: (data: any) => void;
  batchNo?: string | undefined;
  materialId?: number | undefined;
  materialinfo?: any;
}

const CreateAndEdit = (props: Props) => {
  const { visible, ...rest } = props;

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      width={800}
      content={<BaseInfo {...rest} />}
      onClose={props.onCancel}
    />
  );
};

export default CreateAndEdit;
