/**
 * 从对象的输入与展示
 */
import _, { toNumber, find, findIndex } from 'lodash';
import { Form } from 'antd';
import { BlSortFormList, BlTable, BatchOperateTableHeader } from 'src/components';
import { FieldType } from 'src/dict/common';
import { getFieldsDefaultValueForPreset } from 'src/page/customObject/utils';
import {
  constructControlFieldForest,
  postorderTraversal,
} from 'src/page/custom_fields/fieldCascading/utils';
import { getDisplayNodeRenderer } from './getDisplayNodeRenderer';
import { getInputControlRenderer } from './getInputControlRenderer';
import { getReferControlRenderer } from './getReferControlRenderer';
import { getFormItemProps } from './getFormItemProps';
import type { FormInstance } from 'antd';
import type { BlSortFormListProps } from '@blacklake-web/component';
import type {
  EntitySubObjectDTO,
  FieldCascadingDTO,
  FieldDTO,
  NamePath,
  SubObjectDTO,
} from '../types';
import { replaceSign } from 'src/utils/constants';
import { FieldPermission } from 'src/utils/auth/fieldPermission';

type ArrayNamePath = Exclude<NamePath, string | number>;

export const excludeSubObjFields = (fields: FieldDTO[]) =>
  fields.filter((f) => f.fieldType !== FieldType.subordinate);

/** 详情页从对象渲染 */
export const getSubObjectDisplayNodeRenderer = (subObjFields: FieldDTO[]) => {
  const columns = excludeSubObjFields(subObjFields).map((f) => ({
    title: f.fieldName,
    dataIndex: f.fieldCode,
    width: 120,
    render: getDisplayNodeRenderer(f, { isInTable: true }),
  }));

  return (dataSource: any[]) => (
    <BlTable columns={columns} dataSource={dataSource} scroll={{ x: 'max-content' }} />
  );
};

interface SubObjectRenderParams {
  subObj: SubObjectDTO;
  /** namePath应该到从对象表格的层级 */
  namePath: ArrayNamePath;
  form: FormInstance;
  /** 主对象的字段所在路径，用于在从对象中引用主对象的字段 */
  namePathOfMainObject: ArrayNamePath;
  /** 从对象实例，用于展示不可编辑的引用字段 */
  subObjectEntities?: EntitySubObjectDTO[];
  isEdit?: boolean;
  fieldCascadings?: FieldCascadingDTO[];
  // 所属对象的字段权限
  fieldsPermission?: FieldPermission[];
}
const getSubObjColumnRenderer =
  ({
    subObj,
    namePath,
    form,
    namePathOfMainObject,
    subObjectEntities,
    isEdit,
    fieldCascadings = [],
    fieldsPermission = [],
  }: SubObjectRenderParams) =>
  () => {
    const fields = excludeSubObjFields(subObj.fieldList ?? []);

    return fields.map((field) => ({
      title: (
        <BatchOperateTableHeader
          titleText={field.fieldName}
          required={!!field.isRequired}
          tooltip={field.fieldRemind}
        />
      ),
      dataIndex: field.fieldCode,
      width: 300,
      render: (__: unknown, formField: any) => {
        if (field.isRefer) {
          const refChainSplit = field.referenceChain?.split('#') ?? [];
          const targetField = find(subObj.fieldList, { id: toNumber(refChainSplit[0]) });

          // 如果引用的是不可编辑字段，在新建时展示空，在编辑时直接取实例值展示
          if (!targetField) {
            return (
              _.nth(subObjectEntities, formField.name)?.fields?.find(
                (f) => f.fieldCode === field.fieldCode,
              )?.fieldValue ?? replaceSign
            );
          }
          // 区分引用的是关联关系字段 还是主从关系字段，需分别处理
          const isReferringMainObj = targetField?.fieldType === FieldType.subordinate;
          let targetNamePath: NamePath;

          // 如果引用的是主从关系字段，则在主对象上查找被选择的字段
          if (isReferringMainObj) {
            const mainObjFields = form.getFieldsValue().fields;
            const realTargetFieldIdx = findIndex(mainObjFields, { id: toNumber(refChainSplit[1]) });

            targetNamePath = [...namePathOfMainObject, realTargetFieldIdx, 'fieldValue'];
          } else {
            targetNamePath = [...namePath, formField.name, targetField?.fieldCode];
          }

          return getReferControlRenderer(field, {
            parentObjectCode: subObj.objectCode!,
            form,
            targetNamePath,
            fields: subObj.fieldList!,
            isReferringMainObj,
          })();
        }
        // 在<Form.List>里，<Form.Item>的name是相对路径，其首位是该项的下标；但在调用 form.getFieldValue时，需使用绝对路径。注意区分这两种情况
        const instancePath = [...namePath, formField.name];
        const fieldAbsolutePath = [...instancePath, field.fieldCode];

        const formItemProps = getFormItemProps({
          field,
          namePath: [formField.name, field.fieldCode],
          fieldCascadings,
          getNamePathByFieldCode: (fieldCode: string) => [...instancePath, fieldCode],
        });
        const inputControlRenderer = getInputControlRenderer(field, {
          inputWidth: 150,
          namePath: fieldAbsolutePath,
          form,
          isEdit,
          isSonObj: true,
          instancePath,
          fieldCascadings,
          getNamePathByFieldCode: (fieldCode: string) => [...instancePath, fieldCode],
          fieldsPermission,
        });

        if (formItemProps.shouldUpdate) {
          return (
            <Form.Item noStyle shouldUpdate={formItemProps.shouldUpdate}>
              {() => (
                <Form.Item
                  style={{ marginBottom: 0 }}
                  {..._.omit(formItemProps, ['shouldUpdate', 'label'])}
                >
                  {inputControlRenderer()}
                </Form.Item>
              )}
            </Form.Item>
          );
        }
        return (
          <Form.Item {..._.omit(formItemProps, 'label')} style={{ marginBottom: 0 }}>
            {inputControlRenderer()}
          </Form.Item>
        );
      },
    }));
  };

/** 新建一个从对象（即添加一行）时，为有默认值的字段填充值 */
export const getSubObjectDefaultValueForPreset = (subObject: SubObjectDTO) => {
  // 滤掉引用字段
  const fieldsWithDefaultValue = _.compact(getFieldsDefaultValueForPreset(subObject.fieldList!));

  return _.fromPairs(
    fieldsWithDefaultValue.map(({ fieldCode, fieldValue }) => [fieldCode, fieldValue]),
  );
};

/** 编辑页从对象输入，渲染一个表格 */
export const getSubObjectInputRenderer = (params: SubObjectRenderParams) => {
  const { subObj, namePath, form, fieldCascadings } = params;
  const handleAdd = () => {
    const addInit = getSubObjectDefaultValueForPreset(subObj);
    const instanceList = form.getFieldValue(namePath) ?? [];

    form.setFields([
      {
        name: namePath,
        value: [...instanceList, addInit],
      },
    ]);
    // 默认值需符合级联规则
    constructControlFieldForest(fieldCascadings ?? []).forEach((tree) => {
      const fieldCodeList = postorderTraversal(tree);

      form.setFields(
        fieldCodeList.map((fieldCode) => ({
          name: [...namePath, instanceList.length, fieldCode],
          value: addInit[fieldCode],
        })),
      );
    });
  };

  return () => {
    const listRules: BlSortFormListProps['listRules'] = [];

    if (subObj.childNecessary) {
      listRules.push({
        validator: async (__, items) => {
          if (!items || _.isEmpty(_.compact(items))) {
            return Promise.reject(Error(`${subObj.referName}不能为空`));
          }
          return Promise.resolve(true);
        },
      });
    }
    return (
      <BlSortFormList
        name={namePath}
        renderColumns={getSubObjColumnRenderer(params)}
        isNeedDrag={false}
        listRules={listRules}
        handleAdd={handleAdd}
      />
    );
  };
};
