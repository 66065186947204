import React, { useEffect, useState } from 'react';
import { Form, FormInstance, Input, message, Radio, Select, Space } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { textValidator1, textValidator2 } from 'src/utils/formValidators';
import { BlSortFormList, NumberRulesStandardHook } from 'src/components';
import { MATCH_METHOD_ENUM, OBJECT_MAP, templateNode } from '../interface';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import styles from '../../templateManagement/filter/styles.module.scss';
import PreviewModal from '../../templateManagement/components/preview';
import { fetchEReportGetRelationParamsBySubTemplateId } from 'src/api/ytt/e-report-domain/customReport';
import { debounce, filter, map } from 'lodash';
import { fetchMatchRuleQueryTemplateForMatchRuleChoose } from 'src/api/ytt/e-report-domain/matchRule';
import _String from 'src/utils/dataTypes/string';
import { fetchDataSetQueryAllObjectForMatchRule } from 'src/api/ytt/e-report-domain/dataSet';

const { TextArea } = Input;

interface baseInfoProps {
  edit: boolean;
  form: FormInstance;
  onCancel: () => void;
  onFinish: () => void;
  dataSource?: any;
}

const CreateAndEditBaseInfo = (props: baseInfoProps) => {
  const { dataSource, edit, form, onCancel, onFinish } = props;

  const [selectTemplateData, setSelectTemplateData] = useState([] as any);

  const [initLineData, setInitLineData] = useState({} as any);
  const [checkData, setCheckData] = useState({} as any);
  const [visiblePreview, setVisiblePreview] = useState<boolean>(false);
  const [objectList, setObjectList] = useState([] as any);

  const fetchAllObject = () => {
    fetchDataSetQueryAllObjectForMatchRule().then((res) => {
      const { data } = res;

      setObjectList(data);
    });
  };

  useEffect(() => {
    fetchAllObject();
    if (edit) {
      // 只有在基本信息页面 才会在新建编辑的时候都可能不断的更新值 重置form
      form.setFieldsValue(dataSource);
    }
  }, [dataSource]);

  const getRelationParamsBySubTemplateId = async (templateId: number) => {
    try {
      const { data } = await fetchEReportGetRelationParamsBySubTemplateId({ templateId });

      setInitLineData({ cptRelatedParam: data, templateId });

      setVisiblePreview(true);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const onSearch = debounce(async (value: string) => {
    try {
      const { data } = await fetchMatchRuleQueryTemplateForMatchRuleChoose({
        objectId:
          form.getFieldValue('objectCode') &&
          (JSON.parse(form.getFieldValue('objectCode')?.value ?? {})?.id ??
            JSON.parse(form.getFieldValue('objectCode')?.value ?? {})?.objectId),
      });

      if (data) {
        if (_String.isEmpty(value)) {
          setSelectTemplateData(data); // 空的直接返回所有数据
        } else {
          // 根据value前端过滤搜索数据
          const filterData = filter(
            data,
            (e: templateNode) =>
              e?.templateCode.indexOf(value) > -1 || e?.templateName.indexOf(value) > -1,
          );

          setSelectTemplateData(filterData);
        }
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }, 200);

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    const baseColumns: ColumnProps<any & FormListFieldData>[] = [
      {
        title: '电子单据模板',
        dataIndex: 'subTemplateId',
        width: 300,
        render: (_text, field) => {
          return (
            <div className={styles?.cptRelatedParam}>
              <Form.Item
                name={[field?.name, 'subTemplateId']}
                fieldKey={[field?.key, 'subTemplateId']}
                rules={[{ required: true, message: '请选择电子单据模板' }]}
                noStyle
              >
                <Select
                  onChange={() => {
                    // 切换选择的字段 更新条件的选择值
                    form.setFields([
                      {
                        name: ['cptRelatedParam', `${field?.key}`, 'condition'],
                        value: '',
                      },
                    ]);
                  }}
                  showSearch
                  onFocus={() => {
                    onSearch('');
                  }}
                  onSearch={(value: string) => {
                    onSearch(value);
                  }}
                  labelInValue
                  placeholder="请选择"
                  options={map(selectTemplateData, (e: templateNode) => {
                    const selectedData =
                      (form.getFieldValue('matchRuleObjectTemplateDTOList') &&
                        map(form.getFieldValue('matchRuleObjectTemplateDTOList'), (e: any) => {
                          if (!e?.subTemplateId) return null;
                          const _e = e?.subTemplateId && JSON.parse(e?.subTemplateId?.value ?? {});

                          return _e?.templateId;
                        })) ??
                      [];

                    return {
                      label: `${e?.templateCode}/${e?.templateName}`,
                      value: JSON.stringify(e),
                      key: e?.templateId,
                      disabled: selectedData?.includes(e.templateId),
                    };
                  })}
                />
              </Form.Item>
            </div>
          );
        },
      },
      {
        title: '操作',
        width: 150,
        dataIndex: 'operation',
        render: (_text, field) => {
          return (
            <>
              <a
                type="link"
                onClick={() => {
                  const lineData =
                    form.getFieldValue('matchRuleObjectTemplateDTOList')?.[field?.name] &&
                    JSON.parse(
                      form.getFieldValue('matchRuleObjectTemplateDTOList')?.[field?.name]
                        ?.subTemplateId?.value,
                    );

                  setCheckData(lineData);

                  getRelationParamsBySubTemplateId(lineData?.templateId);
                }}
              >
                预览
              </a>
            </>
          );
        },
      },
    ];

    return baseColumns;
  };

  const objectOnChange = () => {
    // 业务对象改变 重置匹配的模板
    form.setFieldsValue({ matchRuleObjectTemplateDTOList: [] });
    setSelectTemplateData([]); // 清空匹配的规则数据
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 3,
    title: '基本信息',
    items: [
      ...NumberRulesStandardHook({
        label: '规则编号',
        form,
        disabledState: edit,
        edit,
        objectCode: 'eReportMatch',
        fieldCode: 'code',
        rules: [{ max: 255, message: '不可超过255字符' }, { validator: textValidator2 }],
      }),
      {
        label: '规则名称',
        name: 'name',
        rules: [
          { required: true, message: '规则名称不能为空' },
          { validator: textValidator1 },
          { max: 255, message: '不超过255个字符' },
        ],
        render: () => <Input placeholder="请输入名称" />,
      },
      {
        label: '对象类别',
        name: 'objectCategory',
        initialValue: 1,
        rules: [{ required: true, message: '对象类别不能为空' }],
        render: () => (
          <Select
            allowClear
            options={OBJECT_MAP}
            onChange={() => {
              form.setFieldsValue({ matchRuleObjectTemplateDTOList: [], objectCode: '' });
              setSelectTemplateData([]); // 清空匹配的规则数据
            }}
          />
        ),
      },
      {
        label: '业务对象',
        name: 'objectCode',
        rules: [{ required: true, message: '请选择业务对象' }],
        render: () => {
          return (
            <Select
              placeholder="请输入业务对象"
              showSearch
              labelInValue
              allowClear
              filterOption={(inputValue, option) => {
                return (option?.label ?? '').indexOf(inputValue) > -1;
              }}
              options={objectList
                ?.filter((e: any) => e?.objectCategory === form.getFieldValue('objectCategory'))
                ?.map((e: any) => {
                  return {
                    label: e?.objectName,
                    value: JSON.stringify(e),
                    key: e?.objectId,
                  };
                })}
              onChange={objectOnChange}
            />
          );
        },
      },
      {
        label: '匹配方式',
        name: 'matchMethod',
        rules: [{ required: true, message: '请选择匹配方式' }],
        initialValue: MATCH_METHOD_ENUM.UNITED,
        render: () => (
          <Radio.Group>
            <Radio value={MATCH_METHOD_ENUM.UNITED}>
              <Space>统一匹配</Space>
            </Radio>
          </Radio.Group>
        ),
      },
      {
        label: '备注',
        name: 'remark',
        rules: [{ max: 500, message: '不超过500个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
    ],
  };

  const matchInfo: DataFormLayoutInfoBlock = {
    title: '匹配模板',
    items: [
      {
        label: '',
        isFullLine: true,
        render: () => (
          <BlSortFormList
            style={{ width: '66%' }}
            maxCount={20}
            handleAdd={() => {
              const objectCode = form.getFieldValue('objectCode');

              if (!objectCode) {
                message.error('请先选择业务对象');
              } else {
                const matchRuleObjectTemplateDTOList = form
                  .getFieldValue('matchRuleObjectTemplateDTOList')
                  .concat([{ subTemplateId: undefined }]);

                form.setFieldsValue({
                  matchRuleObjectTemplateDTOList,
                });
              }
            }}
            fixedRowFn={(field) => {
              // 是否可以删除拖拽此行
              const id = form.getFieldValue(['matchRuleObjectTemplateDTOList', field.name, 'id']);

              return !!id;
            }}
            isNeedDrag={false}
            form={form}
            name={'matchRuleObjectTemplateDTOList'}
            renderColumns={() => getColumns()}
            tableProps={{ bordered: false }}
            buttonText="添加电子单据模板"
          />
        ),
      },
    ],
  };

  return (
    <>
      <DataFormLayout
        form={form}
        info={[baseInfo, matchInfo]}
        onCancel={onCancel}
        onFinish={onFinish}
        title={`${edit ? '编辑' : '新建'}对象匹配规则`}
      />
      {visiblePreview && initLineData && (
        <PreviewModal
          visible={visiblePreview}
          initData={initLineData}
          onClose={() => setVisiblePreview(false)}
          templateId={checkData?.templateId}
          operationType={'noApprove'}
        />
      )}
    </>
  );
};

export default CreateAndEditBaseInfo;
