const qs = {
  parse(init?: ConstructorParameters<typeof URLSearchParams>[0]) {
    return Object.fromEntries(new URLSearchParams(init ?? location.search));
  },
  stringify(obj: { [k: string]: any }) {
    return new URLSearchParams(obj).toString();
  },
};

export default qs;
