import React, { useEffect, useRef, useState } from 'react';
import { Empty, Spin } from 'antd';
import { BlIcon } from 'src/components';
import NotificationMessageContent from './messageContent';
import styles from './styles.module.scss';
import _Array from 'src/utils/dataTypes/array';
import {
  fetchMessageBulkUpdateMessageStatus,
  fetchMessageList,
  fetchMessageUpdateAllStatus,
} from 'src/api/ytt/message-domain/messageList';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { getLayout, notifyJump, notifyJumpByLayout, notifyNeedModal } from './notifyJumpDict';
import { GetCustomLayoutForNotify } from './notifyModal';

interface Props {
  type: string;
  handleClose: (visible: boolean) => void;
}

export default function NotificationMessagePopover({ type, handleClose }: Props) {
  const [popoverData, setPopoverData] = useState([] as any);
  const [pageNum, setPageNum] = useState<number>(1); // loading加载之前的页数
  const [maxNumber, setMaxNumber] = useState<number>(1);
  const [updateAll, setUpdateAll] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [visible, setVisible] = useState<boolean>(false);
  const [objectData, setObjectData] = useState({} as any);

  const dispatch = useDispatch();

  const history = useHistory();

  const { totalUnread = 0 } = useSelector((state: RootState) => state?.message, shallowEqual);

  const wrapRef = useRef<any>(null);

  const loadingRef = useRef<boolean>(false);

  loadingRef.current = loading;

  // 获取弹出窗口的数据
  const fetchData = debounce(async () => {
    try {
      setLoading(true);

      if (pageNum > maxNumber) return;

      const { data } = await fetchMessageList({
        page: pageNum,
        size: 5,
      });

      const maxNum = Math.ceil(data?.total / 5);

      setMaxNumber(maxNum);

      if (data) {
        setUpdateAll(Boolean(!data?.totalUnread));
        const temp = data?.list;
        const nowList = pageNum === 1 ? temp : [...popoverData, ...temp];

        setPopoverData(nowList);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, 500);

  const loadMore = (e: any) => {
    const { clientHeight, scrollTop, scrollHeight } = e.target;

    if (scrollTop > scrollHeight - clientHeight - 20) {
      if (loadingRef.current) return;
      setPageNum((pageNum) => pageNum + 1);
    }
  };

  useEffect(() => {
    const Dom = wrapRef.current;

    Dom.addEventListener('scroll', loadMore);
    return () => {
      Dom.removeEventListener('scroll', loadMore);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [pageNum]);

  const updateMessageStatus = async (nodeData: any) => {
    try {
      // 1.判断已读/未读 未读消息需要先转已读   status 0未读/1已读
      // 2. 有详情并跳转对应详情页

      if (nodeData?.status === 0) {
        // 更新未读消息
        const { code } = await fetchMessageBulkUpdateMessageStatus([nodeData?.id]);

        if (code === 200) {
          // 未读变已读  重新获取已读后的数据
          handleClose(false);
          dispatch({ type: 'message/getMessageUnReadTotal' });
        }
      }

      if (JSON.stringify(nodeData?.meta) === '{}') return;

      if (!notifyNeedModal(nodeData?.meta?.objectCode)) return notifyJump(nodeData?.meta); // 代表具有详情实例

      // 接了自定义详情布局的需要先选择布局

      const data = await getLayout(nodeData?.meta);

      // 如果只有一条数据 直接跳转

      if (data?.length === 1) {
        notifyJumpByLayout(nodeData?.meta, data?.[0]?.value);
      } else {
        setObjectData(nodeData?.meta);
        setVisible(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const readAllMessage = async () => {
    try {
      const { code } = await fetchMessageUpdateAllStatus();

      if (code === 200) {
        setUpdateAll(true);

        setPageNum(1);

        fetchData();

        dispatch({ type: 'message/getMessageUnReadTotal' });
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <div
      className={styles?.notificationCardBox}
      style={{
        width: 384,
        maxHeight: 580,
      }}
    >
      <div className={styles?.PopoverHeader}>
        <div className={styles?.PopoverHeaderUnread}>
          <div>未读消息</div>
          <div className={styles?.num}>{totalUnread > 99 ? '99+' : totalUnread}</div>
        </div>
        <div
          className={styles?.PopoverHeaderRead}
          style={{ color: updateAll ? 'rgba(0, 0, 0, 0.65)' : '#02b980' }}
          onClick={() => {
            readAllMessage();
          }}
        >
          全部已读
        </div>
      </div>

      <div className={styles?.notificationCardContentPopoverBox} ref={wrapRef}>
        {_Array.isEmpty(popoverData) ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <div>
            <NotificationMessageContent
              data={popoverData}
              type={type}
              updateMessageStatus={updateMessageStatus}
            />
            {loading && (
              <div className={styles.loading}>
                <Spin />
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className={styles?.more}
        onClick={() => {
          window.open('/notification/notificationMessageUrl');
          handleClose(false);
        }}
      >
        <BlIcon type="iconzhanshichakan" />
        <span style={{ marginLeft: 5 }}>查看全部</span>
      </div>
      {visible && (
        <GetCustomLayoutForNotify
          objectData={objectData}
          visible={visible}
          handleCancel={() => {
            setVisible(false);
          }}
        />
      )}
    </div>
  );
}
