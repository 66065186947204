import React, { useEffect, useState } from 'react';
import {
  fetchCustomerDetail,
  fetchCustomerDetailRegItems,
  fetchCustomerDisable,
  FetchCustomerDisableRequest,
  fetchCustomerEnable,
  FetchCustomerEnableRequest,
} from 'src/api/ytt/order-domain/customer';
import { avatarDisplay } from 'src/components/avatar/show';
import { lookup } from 'src/dict';
import { UsageStatus } from 'src/dict/common';
import { renderUsageStatus } from 'src/page/share/renderUsageStatus';
import { replaceSign } from 'src/utils/constants';
import { message as Message } from 'antd';
import { DetailLayout } from 'layout';
import _ from 'lodash';
import { SingleOperationLogComponent } from 'src/components';
import { formatTimeForRender } from 'src/utils/formatters';
import { Auth } from 'src/dict/auth';
import DetailMaterialsRegistedTable from '../components/detailMaterialsRegistedTable';
import authDict from 'src/utils/auth';
import { CustomerDetailLayoutProps, CustomerDetailProps } from '../interface';

interface Props extends CustomerDetailLayoutProps {}
const DetailsBaseInfo = (props: Props) => {
  const { refresh, showEdit, customerData } = props;

  const [dataSource, setDataSource] = useState<CustomerDetailProps>();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);

  /**
   * fetch 详情 - 基本信息
   */
  const handleFetchBaseInfo = async () => {
    try {
      const { data } = await fetchCustomerDetail({ id: customerData.id });

      return data;
    } catch (error) {
      console.log('error', error);
      return {};
    }
  };
  /**
   * fetch 详情 - 物料行信息
   */
  const handleFetchMaterialInfo = async () => {
    try {
      const { data } = await fetchCustomerDetailRegItems({ id: customerData.id });

      return data;
    } catch (error) {
      console.log('error', error);
      return undefined;
    }
  };
  /**
   * fetch 初始化
   */
  const handleInit = async () => {
    const baseInfo = await handleFetchBaseInfo();
    const materialInfo = await handleFetchMaterialInfo();

    setDataSource({ ...baseInfo, materialInfo });
  };
  /**
   * 启停用 - 客户 - 操作
   */
  const handleEnableOrDisableCustomer = async (
    ids: Array<number>,
    enableFlag: UsageStatus,
    onSuccess?: () => void,
    onFail?: () => void,
  ) => {
    const requestParam: FetchCustomerEnableRequest | FetchCustomerDisableRequest = { ids };
    const requestFn = enableFlag ? fetchCustomerDisable : fetchCustomerEnable;

    await requestFn(requestParam);

    Message.success(enableFlag ? '停用成功' : '启用成功');
    onSuccess?.();
    refresh();
  };
  /**
   * 渲染物料行信息
   */
  const handleRenderMaterialInfo = () => {
    return {
      title: '已注册物料',
      items: [
        {
          render: () => {
            return (
              <DetailMaterialsRegistedTable
                requestFn={async () => {
                  return { data: { list: dataSource?.materialInfo } };
                }}
              />
            );
          },
        },
      ],
    };
  };
  const baseMenu = [
    {
      key: 'statusChange',
      icon: customerData.status === UsageStatus.enabled ? 'icontingyong' : 'iconqiyong',
      onClick: () => {
        if (!customerData) return;
        handleEnableOrDisableCustomer([customerData.id!], customerData.status!);
      },
      title: customerData ? lookup('changeStatusAction', customerData.status) : null,
      auth: authDict.customer_enable_disable,
    },
    {
      key: 'record',
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
      auth: authDict.customer_operrecord,
    },
    {
      key: 'edit',
      onClick: () => {
        showEdit();
      },
      icon: 'iconbianji',
      title: '编辑',
      auth: authDict.customer_edit,
    },
  ];
  const baseInfo = _.compact([
    {
      title: '基本信息',
      items: [
        {
          label: '编号',
          dataIndex: 'code',
          render: (text: string) => text || replaceSign,
        },
        {
          label: '名称',
          dataIndex: 'name',
          render: (text: string) => text || replaceSign,
        },
        {
          label: '状态',
          dataIndex: 'status',
          render: (status: UsageStatus) => {
            return <span>{renderUsageStatus(status) || replaceSign}</span>;
          },
        },
        Auth.supplyChain && {
          label: '注册状态',
          dataIndex: 'regStatus',
          width: 150,
          render: (text: number) => lookup('customer.registrationStatus', text) || replaceSign,
        },
        Auth.supplyChain && {
          label: '注册企业',
          dataIndex: 'regOrg',
          width: 150,
          render: (text: { orgId: number | null; orgCode: string | null; orgName: string }) =>
            text?.orgName || replaceSign,
        },
        {
          label: '客户所有人',
          dataIndex: 'owner',
          render: (user: any) => {
            return user?.id ? avatarDisplay({ ...user, isUser: true }) : replaceSign;
          },
        },
        {
          label: '备注',
          dataIndex: 'remark',
          render: (remark: string) => remark || replaceSign,
        },
      ],
    },
    {
      title: '其他信息',
      items: [
        {
          label: '创建人',
          dataIndex: 'creator',
          render: (user: any) => {
            return user?.id ? avatarDisplay({ ...user, isUser: true }) : replaceSign;
          },
        },
        {
          label: '创建时间',
          dataIndex: 'createdAt',
          isFullLine: true,
          render: formatTimeForRender,
        },
        {
          label: '编辑人',
          dataIndex: 'operator',
          render: (user: any) => {
            return user?.id ? avatarDisplay({ ...user, isUser: true }) : replaceSign;
          },
        },
        {
          label: '编辑时间',
          dataIndex: 'updatedAt',
          isFullLine: true,
          render: formatTimeForRender,
        },
      ],
    },
  ]);
  const infoArray = _.compact([
    ...baseInfo,
    dataSource?.materialInfo && handleRenderMaterialInfo(),
  ]);

  useEffect(() => {
    customerData && handleInit();
  }, []);

  return (
    <>
      <DetailLayout baseMenu={baseMenu} title="客户详情" dataSource={dataSource} info={infoArray} />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={customerData?.id as number}
          objectCode={'Customer'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailsBaseInfo;
