import { FormInstance, Input, Radio } from 'antd';
import { appEnum } from 'src/dict';
import { validateBlInputText1, validatorCheckTwoSidesTrim } from '../verificationRules';
import { yn as ynMappings } from 'src/dict/common/mappings';
import { YN } from 'src/dict/common';
import React from 'react';

const { TextArea } = Input;

interface Props {
  form: FormInstance;
  type?: any;
  fieldCategory?: number;
  fieldType?: number;
}

export const baseItems = (props: Props) => {
  const isPreField = props.fieldCategory === appEnum.CustomField.FieldCategory.preFields;
  const fieldCodeDisabled =
    props.type && props.fieldType !== appEnum.CustomField.FieldType.appendix;

  return [
    {
      label: '字段编号',
      name: 'fieldCode',
      rules: [
        {
          required: true,
          message: '请输入字段编号',
        },
        {
          max: 64,
          message: '不超过64字符',
        },
        { validator: validateBlInputText1() },
      ],
      validateFirst: true,
      render: () => <Input disabled={fieldCodeDisabled} />,
    },
    {
      label: '字段名称',
      hidden: isPreField,
      name: 'fieldName',
      rules: [
        {
          required: true,
          message: '请输入字段名称',
        },
        {
          max: 255,
          message: '不超过255字符',
        },
        { validator: validatorCheckTwoSidesTrim() },
      ],
      validateFirst: true,
      render: () => <Input />,
    },
    {
      label: '是否主属性',
      name: 'isPrime',
      initialValue: YN.no,
      render: () => <Radio.Group options={ynMappings} disabled />,
    },
    {
      label: '字段提示',
      name: 'fieldRemind',
      rules: [
        {
          max: 1000,
          message: '不超过1000字符',
        },
        { validator: validatorCheckTwoSidesTrim() },
      ],
      validateFirst: true,
      render: () => <TextArea />,
    },
  ];
};
