import { match, RouteComponentProps } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import DetailsBaseInfo from './detailsBaseInfo';
import { DetailLayoutMenuItem } from 'src/layout';
import { DeliveryScheduleNoteDetailData } from '../interface';
import {
  fetchDeliveryScheduleNoteDetail,
  fetchDeliveryScheduleNoteItemDetail,
} from 'src/api/ytt/order-domain/deliveryScheduleNote';
import { toEdit } from '../navigate';
import { DeliveryScheduleNoteExecStatus } from 'src/dict/purchase';
import { handleDistribute, handleClosed, endOrder } from '../operate';
import { SingleOperationLogComponent } from 'src/components';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import authDict from 'src/utils/auth';

interface DetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const DetailPage = (props: any) => {
  const { match, history } = props;
  const deliveryScheduleNoteId = Number(match?.params?.id);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<DeliveryScheduleNoteDetailData>({});
  const [execStatus, setExecStatus] = useState<number>(0);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const refreshAll = () => {
    setRefreshMarker(Date.now());
  };
  const fetchData = async () => {
    try {
      const [{ data }, { data: materialLine }] = await Promise.all([
        fetchDeliveryScheduleNoteDetail({ id: deliveryScheduleNoteId }),
        fetchDeliveryScheduleNoteItemDetail({ id: deliveryScheduleNoteId }),
      ]);

      if (data) {
        setDataSource({ ...data, itemList: materialLine });
        setExecStatus(data?.execStatus as number);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    deliveryScheduleNoteId && fetchData();
  }, [deliveryScheduleNoteId, refreshMarker]);

  const baseMenu: DetailLayoutMenuItem[] = [
    {
      key: 'attribute',
      disabled:
        execStatus !== DeliveryScheduleNoteExecStatus.created &&
        execStatus !== DeliveryScheduleNoteExecStatus.rejected,
      onClick: () => {
        handleDistribute([dataSource], [deliveryScheduleNoteId], refreshAll);
      },
      title: '下发',
      auth: authDict.deliveryScheduleNote_dispatch,
    },
    {
      key: 'closed',
      disabled:
        execStatus !== DeliveryScheduleNoteExecStatus.created &&
        execStatus !== DeliveryScheduleNoteExecStatus.rejected,
      onClick: () => {
        Modal.confirm({
          title: '关闭交货计划单',
          content: '关闭交货计划单后不能再进行任何操作，确认关闭吗？',
          okText: '关闭',
          cancelText: '暂不关闭',
          onOk: async () => {
            await handleClosed([dataSource], [deliveryScheduleNoteId], refreshAll);
          },
        });
      },
      title: '关闭',
      auth: authDict.deliveryScheduleNote_close,
    },
    {
      key: 'end',
      disabled:
        execStatus !== DeliveryScheduleNoteExecStatus.undetermined &&
        execStatus !== DeliveryScheduleNoteExecStatus.executing,
      onClick: () => {
        endOrder(
          [dataSource],
          [deliveryScheduleNoteId],
          [dataSource?.code ?? ''],
          'single',
          refreshAll,
        );
      },
      title: '结束',
      auth: authDict.deliveryScheduleNote_finish,
    },
    {
      key: 'record',
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
      auth: authDict.deliveryScheduleNote_operrecord,
    },
    {
      key: 'edit',
      disabled:
        execStatus === DeliveryScheduleNoteExecStatus.finished ||
        execStatus === DeliveryScheduleNoteExecStatus.closed,
      onClick: () => {
        history.push(toEdit(deliveryScheduleNoteId));
      },
      icon: 'iconbianji',
      title: '编辑',
      auth: authDict.deliveryScheduleNote_edit,
    },
  ];

  return (
    <>
      <DetailsBaseInfo baseMenu={baseMenu} dataSource={dataSource} />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={deliveryScheduleNoteId as number}
          objectCode={OBJECT_OF_CODE.deliveryScheduleNote}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailPage;
