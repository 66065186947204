/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../../request';

// makeRequest
function makeRequestRequired<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequestOptional<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData?: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequest<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const optional = makeRequestOptional<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  const required = makeRequestRequired<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  return (
    requestConfig.requestDataOptional ? optional : required
  ) as TRequestConfig['requestDataOptional'] extends true ? typeof optional : typeof required;
}

// Request
export type Request<TReqeustData, TRequestConfig extends RequestConfig, TRequestResult> =
  (TRequestConfig['requestDataOptional'] extends true
    ? (
        requestData?: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult
    : (
        requestData: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult) & {
    requestConfig: TRequestConfig;
  };

const mockUrl_0_21_0_0 = 'http://yapi.blacklake.tech/mock/503' as any;
const devUrl_0_21_0_0 = '' as any;
const prodUrl_0_21_0_0 = '' as any;
const dataKey_0_21_0_0 = undefined as any;

/**
 * 接口 [批量删除↗](http://yapi.blacklake.tech/project/503/interface/api/71876) 的 **请求类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkDelete`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationBulkDeleteRequest {
  /**
   * 仓位id列表
   */
  ids?: number[];
}

/**
 * 接口 [批量删除↗](http://yapi.blacklake.tech/project/503/interface/api/71876) 的 **返回类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkDelete`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationBulkDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 失败数量
     */
    failCount?: number;
    failDetailList?: {
      /**
       * 编码
       */
      code?: string;
      /**
       * 错误描述
       */
      errorDesc?: string;
      /**
       * 操作出现异常的记录id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 成功数量
     */
    successCount?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量删除↗](http://yapi.blacklake.tech/project/503/interface/api/71876) 的 **请求配置的类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkDelete`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageLocationBulkDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_location/bulkDelete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量删除↗](http://yapi.blacklake.tech/project/503/interface/api/71876) 的 **请求配置**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkDelete`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageLocationBulkDeleteRequestConfig: FetchStorageLocationBulkDeleteRequestConfig = {
  mockUrl: mockUrl_0_21_0_0,
  devUrl: devUrl_0_21_0_0,
  prodUrl: prodUrl_0_21_0_0,
  path: '/storage/domain/web/v1/storage_location/bulkDelete',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_21_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageLocationBulkDelete',
};

/**
 * 接口 [批量删除↗](http://yapi.blacklake.tech/project/503/interface/api/71876) 的 **请求函数**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkDelete`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageLocationBulkDelete = makeRequest<
  FetchStorageLocationBulkDeleteRequest,
  FetchStorageLocationBulkDeleteResponse,
  FetchStorageLocationBulkDeleteRequestConfig
>(fetchStorageLocationBulkDeleteRequestConfig);

/**
 * 接口 [批量禁用↗](http://yapi.blacklake.tech/project/503/interface/api/71882) 的 **请求类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkDisable`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationBulkDisableRequest {
  /**
   * 仓位id列表
   */
  ids?: number[];
}

/**
 * 接口 [批量禁用↗](http://yapi.blacklake.tech/project/503/interface/api/71882) 的 **返回类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkDisable`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationBulkDisableResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 失败数量
     */
    failCount?: number;
    failDetailList?: {
      /**
       * 编码
       */
      code?: string;
      /**
       * 错误描述
       */
      errorDesc?: string;
      /**
       * 操作出现异常的记录id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 成功数量
     */
    successCount?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量禁用↗](http://yapi.blacklake.tech/project/503/interface/api/71882) 的 **请求配置的类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkDisable`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageLocationBulkDisableRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_location/bulkDisable',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量禁用↗](http://yapi.blacklake.tech/project/503/interface/api/71882) 的 **请求配置**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkDisable`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageLocationBulkDisableRequestConfig: FetchStorageLocationBulkDisableRequestConfig = {
  mockUrl: mockUrl_0_21_0_0,
  devUrl: devUrl_0_21_0_0,
  prodUrl: prodUrl_0_21_0_0,
  path: '/storage/domain/web/v1/storage_location/bulkDisable',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_21_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageLocationBulkDisable',
};

/**
 * 接口 [批量禁用↗](http://yapi.blacklake.tech/project/503/interface/api/71882) 的 **请求函数**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkDisable`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageLocationBulkDisable = makeRequest<
  FetchStorageLocationBulkDisableRequest,
  FetchStorageLocationBulkDisableResponse,
  FetchStorageLocationBulkDisableRequestConfig
>(fetchStorageLocationBulkDisableRequestConfig);

/**
 * 接口 [批量启用↗](http://yapi.blacklake.tech/project/503/interface/api/71888) 的 **请求类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkEnable`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationBulkEnableRequest {
  /**
   * 仓位id列表
   */
  ids?: number[];
}

/**
 * 接口 [批量启用↗](http://yapi.blacklake.tech/project/503/interface/api/71888) 的 **返回类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkEnable`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationBulkEnableResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 失败数量
     */
    failCount?: number;
    failDetailList?: {
      /**
       * 编码
       */
      code?: string;
      /**
       * 错误描述
       */
      errorDesc?: string;
      /**
       * 操作出现异常的记录id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 成功数量
     */
    successCount?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量启用↗](http://yapi.blacklake.tech/project/503/interface/api/71888) 的 **请求配置的类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkEnable`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageLocationBulkEnableRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_location/bulkEnable',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量启用↗](http://yapi.blacklake.tech/project/503/interface/api/71888) 的 **请求配置**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkEnable`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageLocationBulkEnableRequestConfig: FetchStorageLocationBulkEnableRequestConfig = {
  mockUrl: mockUrl_0_21_0_0,
  devUrl: devUrl_0_21_0_0,
  prodUrl: prodUrl_0_21_0_0,
  path: '/storage/domain/web/v1/storage_location/bulkEnable',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_21_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageLocationBulkEnable',
};

/**
 * 接口 [批量启用↗](http://yapi.blacklake.tech/project/503/interface/api/71888) 的 **请求函数**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkEnable`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageLocationBulkEnable = makeRequest<
  FetchStorageLocationBulkEnableRequest,
  FetchStorageLocationBulkEnableResponse,
  FetchStorageLocationBulkEnableRequestConfig
>(fetchStorageLocationBulkEnableRequestConfig);

/**
 * 接口 [批量锁定↗](http://yapi.blacklake.tech/project/503/interface/api/71894) 的 **请求类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkLock`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationBulkLockRequest {
  /**
   * 仓位id列表
   */
  ids?: number[];
}

/**
 * 接口 [批量锁定↗](http://yapi.blacklake.tech/project/503/interface/api/71894) 的 **返回类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkLock`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationBulkLockResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 失败数量
     */
    failCount?: number;
    failDetailList?: {
      /**
       * 编码
       */
      code?: string;
      /**
       * 错误描述
       */
      errorDesc?: string;
      /**
       * 操作出现异常的记录id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 成功数量
     */
    successCount?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量锁定↗](http://yapi.blacklake.tech/project/503/interface/api/71894) 的 **请求配置的类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkLock`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageLocationBulkLockRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_location/bulkLock',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量锁定↗](http://yapi.blacklake.tech/project/503/interface/api/71894) 的 **请求配置**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkLock`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageLocationBulkLockRequestConfig: FetchStorageLocationBulkLockRequestConfig = {
  mockUrl: mockUrl_0_21_0_0,
  devUrl: devUrl_0_21_0_0,
  prodUrl: prodUrl_0_21_0_0,
  path: '/storage/domain/web/v1/storage_location/bulkLock',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_21_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageLocationBulkLock',
};

/**
 * 接口 [批量锁定↗](http://yapi.blacklake.tech/project/503/interface/api/71894) 的 **请求函数**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkLock`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageLocationBulkLock = makeRequest<
  FetchStorageLocationBulkLockRequest,
  FetchStorageLocationBulkLockResponse,
  FetchStorageLocationBulkLockRequestConfig
>(fetchStorageLocationBulkLockRequestConfig);

/**
 * 接口 [批量修改仓位质量状态↗](http://yapi.blacklake.tech/project/503/interface/api/71900) 的 **请求类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkModifyQcCtrlList`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationBulkModifyQcCtrlListRequest {
  /**
   * 待修改的仓位列表id
   */
  ids: number[];
  /**
   * 预计修改的质量状态列表
   */
  qcCtrlList: number[];
}

/**
 * 接口 [批量修改仓位质量状态↗](http://yapi.blacklake.tech/project/503/interface/api/71900) 的 **返回类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkModifyQcCtrlList`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationBulkModifyQcCtrlListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 失败数量
     */
    failCount?: number;
    failDetailList?: {
      /**
       * 编码
       */
      code?: string;
      /**
       * 错误描述
       */
      errorDesc?: string;
      /**
       * 操作出现异常的记录id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 成功数量
     */
    successCount?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量修改仓位质量状态↗](http://yapi.blacklake.tech/project/503/interface/api/71900) 的 **请求配置的类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkModifyQcCtrlList`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageLocationBulkModifyQcCtrlListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_location/bulkModifyQcCtrlList',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量修改仓位质量状态↗](http://yapi.blacklake.tech/project/503/interface/api/71900) 的 **请求配置**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkModifyQcCtrlList`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageLocationBulkModifyQcCtrlListRequestConfig: FetchStorageLocationBulkModifyQcCtrlListRequestConfig =
  {
    mockUrl: mockUrl_0_21_0_0,
    devUrl: devUrl_0_21_0_0,
    prodUrl: prodUrl_0_21_0_0,
    path: '/storage/domain/web/v1/storage_location/bulkModifyQcCtrlList',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_21_0_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchStorageLocationBulkModifyQcCtrlList',
  };

/**
 * 接口 [批量修改仓位质量状态↗](http://yapi.blacklake.tech/project/503/interface/api/71900) 的 **请求函数**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkModifyQcCtrlList`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageLocationBulkModifyQcCtrlList = makeRequest<
  FetchStorageLocationBulkModifyQcCtrlListRequest,
  FetchStorageLocationBulkModifyQcCtrlListResponse,
  FetchStorageLocationBulkModifyQcCtrlListRequestConfig
>(fetchStorageLocationBulkModifyQcCtrlListRequestConfig);

/**
 * 接口 [批量解锁↗](http://yapi.blacklake.tech/project/503/interface/api/71906) 的 **请求类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkUnlock`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationBulkUnlockRequest {
  /**
   * 仓位id列表
   */
  ids?: number[];
}

/**
 * 接口 [批量解锁↗](http://yapi.blacklake.tech/project/503/interface/api/71906) 的 **返回类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkUnlock`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationBulkUnlockResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 失败数量
     */
    failCount?: number;
    failDetailList?: {
      /**
       * 编码
       */
      code?: string;
      /**
       * 错误描述
       */
      errorDesc?: string;
      /**
       * 操作出现异常的记录id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 成功数量
     */
    successCount?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量解锁↗](http://yapi.blacklake.tech/project/503/interface/api/71906) 的 **请求配置的类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkUnlock`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageLocationBulkUnlockRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_location/bulkUnlock',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量解锁↗](http://yapi.blacklake.tech/project/503/interface/api/71906) 的 **请求配置**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkUnlock`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageLocationBulkUnlockRequestConfig: FetchStorageLocationBulkUnlockRequestConfig = {
  mockUrl: mockUrl_0_21_0_0,
  devUrl: devUrl_0_21_0_0,
  prodUrl: prodUrl_0_21_0_0,
  path: '/storage/domain/web/v1/storage_location/bulkUnlock',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_21_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageLocationBulkUnlock',
};

/**
 * 接口 [批量解锁↗](http://yapi.blacklake.tech/project/503/interface/api/71906) 的 **请求函数**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/bulkUnlock`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageLocationBulkUnlock = makeRequest<
  FetchStorageLocationBulkUnlockRequest,
  FetchStorageLocationBulkUnlockResponse,
  FetchStorageLocationBulkUnlockRequestConfig
>(fetchStorageLocationBulkUnlockRequestConfig);

/**
 * 接口 [创建↗](http://yapi.blacklake.tech/project/503/interface/api/71912) 的 **请求类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/create`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationCreateRequest {
  /**
   * 编码
   */
  code?: string;
  /**
   * 自定义字段
   */
  customFieldItems?: {
    /**
     * 字段id
     */
    id: number;
    /**
     * 字段值
     */
    value: {};
  }[];
  /**
   * 是否开启质量管理 用于校验，必须跟从所属仓库的值
   */
  enableQcCtrl: number;
  /**
   * 名称
   */
  name: string;
  /**
   * 父级节点id
   */
  parentId: number;
  /**
   * 父级节点类型 0仓库 1区域
   */
  parentType: number;
  /**
   * 管控的质量范围，不可超出所属仓库管控范围 1合格 2让步合格 3待检 4不合格 5暂控
   */
  qcCtrlList?: number[];
  /**
   * 标识码
   */
  qrCode?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 所属仓库id
   */
  warehouseId: number;
}

/**
 * 接口 [创建↗](http://yapi.blacklake.tech/project/503/interface/api/71912) 的 **返回类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/create`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationCreateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: number;
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [创建↗](http://yapi.blacklake.tech/project/503/interface/api/71912) 的 **请求配置的类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/create`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageLocationCreateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_location/create',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [创建↗](http://yapi.blacklake.tech/project/503/interface/api/71912) 的 **请求配置**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/create`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageLocationCreateRequestConfig: FetchStorageLocationCreateRequestConfig = {
  mockUrl: mockUrl_0_21_0_0,
  devUrl: devUrl_0_21_0_0,
  prodUrl: prodUrl_0_21_0_0,
  path: '/storage/domain/web/v1/storage_location/create',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_21_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageLocationCreate',
};

/**
 * 接口 [创建↗](http://yapi.blacklake.tech/project/503/interface/api/71912) 的 **请求函数**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/create`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageLocationCreate = makeRequest<
  FetchStorageLocationCreateRequest,
  FetchStorageLocationCreateResponse,
  FetchStorageLocationCreateRequestConfig
>(fetchStorageLocationCreateRequestConfig);

/**
 * 接口 [查询详情↗](http://yapi.blacklake.tech/project/503/interface/api/71918) 的 **请求类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/info`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationInfoRequest {
  /**
   * 仓位id
   */
  id?: number;
}

/**
 * 接口 [查询详情↗](http://yapi.blacklake.tech/project/503/interface/api/71918) 的 **返回类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/info`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationInfoResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 编码
     */
    code: string;
    /**
     * 自定义字段
     */
    customFieldItems?: {
      /**
       * 字段值
       */
      displayValue: {};
      /**
       * 字段code
       */
      fieldCode: string;
      /**
       * 字段id
       */
      fieldId: number;
      /**
       * 字段名称
       */
      fieldName: string;
    }[];
    /**
     * 启用状态1启用0禁用
     */
    enableFlag: number;
    /**
     * 是否启用质量管理
     */
    enableQcCtrl: number;
    /**
     * 仓位id
     */
    id: number;
    /**
     * 是否锁定 1锁定 0未锁定
     */
    isLock: number;
    /**
     * 名称
     */
    name: string;
    /**
     * 父级编码
     */
    parentCode: string;
    /**
     * 父级id
     */
    parentId: number;
    /**
     * 父级名称
     */
    parentName: string;
    /**
     * 父级类型 0仓库 1区域
     */
    parentType: number;
    /**
     * 管控的质量管理状态 1合格 2让步合格 3待检 4不合格 5暂控
     */
    qcCtrlList: number[];
    /**
     * 标识码
     */
    qrCode?: string;
    /**
     * 备注
     */
    remark?: string;
    /**
     * 所属仓库编码
     */
    warehouseCode: string;
    /**
     * 所属仓库id
     */
    warehouseId: number;
    /**
     * 所属仓库名称
     */
    warehouseName: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [查询详情↗](http://yapi.blacklake.tech/project/503/interface/api/71918) 的 **请求配置的类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/info`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageLocationInfoRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_location/info',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [查询详情↗](http://yapi.blacklake.tech/project/503/interface/api/71918) 的 **请求配置**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/info`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageLocationInfoRequestConfig: FetchStorageLocationInfoRequestConfig = {
  mockUrl: mockUrl_0_21_0_0,
  devUrl: devUrl_0_21_0_0,
  prodUrl: prodUrl_0_21_0_0,
  path: '/storage/domain/web/v1/storage_location/info',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_21_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageLocationInfo',
};

/**
 * 接口 [查询详情↗](http://yapi.blacklake.tech/project/503/interface/api/71918) 的 **请求函数**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/info`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageLocationInfo = makeRequest<
  FetchStorageLocationInfoRequest,
  FetchStorageLocationInfoResponse,
  FetchStorageLocationInfoRequestConfig
>(fetchStorageLocationInfoRequestConfig);

/**
 * 接口 [编辑↗](http://yapi.blacklake.tech/project/503/interface/api/71924) 的 **请求类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/modify`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationModifyRequest {
  /**
   * 编码
   */
  code?: string;
  /**
   * 自定义字段
   */
  customFieldItems?: {
    /**
     * 字段id
     */
    id: number;
    /**
     * 字段值
     */
    value: {};
  }[];
  /**
   * 是否开启质量管控 1开启 0关闭
   */
  enableQcCtrl: number;
  /**
   * 待修改的仓位id
   */
  id: number;
  /**
   * 编辑原因
   */
  modifyReason?: string;
  /**
   * 名称
   */
  name: string;
  /**
   * 管控的质量状态
   */
  qcCtrlList?: number[];
  /**
   * 标识码
   */
  qrCode?: string;
  /**
   * 备注
   */
  remark?: string;
}

/**
 * 接口 [编辑↗](http://yapi.blacklake.tech/project/503/interface/api/71924) 的 **返回类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/modify`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationModifyResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [编辑↗](http://yapi.blacklake.tech/project/503/interface/api/71924) 的 **请求配置的类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/modify`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageLocationModifyRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_location/modify',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [编辑↗](http://yapi.blacklake.tech/project/503/interface/api/71924) 的 **请求配置**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/modify`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageLocationModifyRequestConfig: FetchStorageLocationModifyRequestConfig = {
  mockUrl: mockUrl_0_21_0_0,
  devUrl: devUrl_0_21_0_0,
  prodUrl: prodUrl_0_21_0_0,
  path: '/storage/domain/web/v1/storage_location/modify',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_21_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageLocationModify',
};

/**
 * 接口 [编辑↗](http://yapi.blacklake.tech/project/503/interface/api/71924) 的 **请求函数**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/modify`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageLocationModify = makeRequest<
  FetchStorageLocationModifyRequest,
  FetchStorageLocationModifyResponse,
  FetchStorageLocationModifyRequestConfig
>(fetchStorageLocationModifyRequestConfig);

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/503/interface/api/71930) 的 **请求类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/pageList`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationPageListRequest {
  /**
   * 编码
   */
  code?: string;
  /**
   * 状态，1启用 0禁用
   */
  enableFlag?: number;
  /**
   * 是否锁定 1锁定 0未锁定
   */
  isLock?: number;
  /**
   * 名称
   */
  name?: string;
  /**
   * 排序规律：字段名 排序 ,隔开
   */
  orderBy?: string;
  /**
   * 请求页
   */
  page?: number;
  /**
   * 管控的质量状态
   */
  qcStatus?: number;
  /**
   * 快捷搜索
   */
  quickSearch?: string;
  /**
   * 每页大小
   */
  size?: number;
  /**
   * 仓储id 根据storageType确定是仓库id还是区域id
   */
  storageId?: number;
  /**
   * 仓储类型 0仓库 1区域
   */
  storageType?: number;
}

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/503/interface/api/71930) 的 **返回类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/pageList`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationPageListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * 编码
       */
      code: string;
      /**
       * 自定义字段数据
       */
      customFieldItems?: {
        /**
         * 字段值
         */
        displayValue: {};
        /**
         * 字段code
         */
        fieldCode: string;
        /**
         * 字段id
         */
        fieldId: number;
        /**
         * 字段名称
         */
        fieldName: string;
      }[];
      /**
       * 启用状态1启用0禁用
       */
      enableFlag: number;
      /**
       * 是否启用质量管理
       */
      enableQcCtrl: number;
      /**
       * 仓位id
       */
      id: number;
      /**
       * 是否锁定 1锁定 0未锁定
       */
      isLock: number;
      /**
       * 名称
       */
      name: string;
      /**
       * 父级编码
       */
      parentCode: string;
      /**
       * 父级id
       */
      parentId: number;
      /**
       * 父级名称
       */
      parentName: string;
      /**
       * 父级标识码
       */
      parentQrCode?: string;
      /**
       * 父级类型 0仓库 1区域
       */
      parentType: number;
      /**
       * 管控的质量管理状态 1合格 2让步合格 3待检 4不合格 5暂控
       */
      qcCtrlList: number[];
      /**
       * 标识码
       */
      qrCode?: string;
      /**
       * 备注
       */
      remark?: string;
      /**
       * 所属仓库编码
       */
      warehouseCode: string;
      /**
       * 所属仓库id
       */
      warehouseId: number;
      /**
       * 所属仓库名称
       */
      warehouseName: string;
      /**
       * 所属仓库标识码
       */
      warehouseQrCode?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/503/interface/api/71930) 的 **请求配置的类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/pageList`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageLocationPageListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_location/pageList',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/503/interface/api/71930) 的 **请求配置**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/pageList`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageLocationPageListRequestConfig: FetchStorageLocationPageListRequestConfig = {
  mockUrl: mockUrl_0_21_0_0,
  devUrl: devUrl_0_21_0_0,
  prodUrl: prodUrl_0_21_0_0,
  path: '/storage/domain/web/v1/storage_location/pageList',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_21_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageLocationPageList',
};

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/503/interface/api/71930) 的 **请求函数**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/pageList`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageLocationPageList = makeRequest<
  FetchStorageLocationPageListRequest,
  FetchStorageLocationPageListResponse,
  FetchStorageLocationPageListRequestConfig
>(fetchStorageLocationPageListRequestConfig);

/**
 * 接口 [简易分页查询↗](http://yapi.blacklake.tech/project/503/interface/api/71936) 的 **请求类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/simplePageList`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationSimplePageListRequest {
  /**
   * 状态1启用0停用
   */
  enableFlag?: number;
  /**
   * 是否锁定
   */
  isLock?: number;
  /**
   * 排序规律：字段名 排序 ,隔开
   */
  orderBy?: string;
  /**
   * 请求页
   */
  page?: number;
  /**
   * 每页大小
   */
  size?: number;
  /**
   * 仓库id
   */
  warehouseId: number;
}

/**
 * 接口 [简易分页查询↗](http://yapi.blacklake.tech/project/503/interface/api/71936) 的 **返回类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/simplePageList`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationSimplePageListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * 编号
       */
      code?: string;
      /**
       * 状态1启用0停用
       */
      enableFlag?: number;
      /**
       * id
       */
      id?: number;
      /**
       * 是否删除 删除为删除时的时间戳
       */
      isDelete?: number;
      /**
       * 是否锁定
       */
      isLock?: number;
      /**
       * 名称
       */
      name?: string;
      /**
       * 父级id
       */
      parentId?: number;
      /**
       * 父级类型
       */
      parentType?: number;
      /**
       * 质量管控是否跟随上层
       */
      qcFollowParent?: number;
      /**
       * 识别码
       */
      qrCode?: string;
      /**
       * 描述
       */
      remark?: string;
      /**
       * 类型：1区域，2仓位
       */
      type?: number;
      /**
       * 仓库id
       */
      warehouseId?: number;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [简易分页查询↗](http://yapi.blacklake.tech/project/503/interface/api/71936) 的 **请求配置的类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/simplePageList`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageLocationSimplePageListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_location/simplePageList',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [简易分页查询↗](http://yapi.blacklake.tech/project/503/interface/api/71936) 的 **请求配置**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/simplePageList`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageLocationSimplePageListRequestConfig: FetchStorageLocationSimplePageListRequestConfig =
  {
    mockUrl: mockUrl_0_21_0_0,
    devUrl: devUrl_0_21_0_0,
    prodUrl: prodUrl_0_21_0_0,
    path: '/storage/domain/web/v1/storage_location/simplePageList',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_21_0_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchStorageLocationSimplePageList',
  };

/**
 * 接口 [简易分页查询↗](http://yapi.blacklake.tech/project/503/interface/api/71936) 的 **请求函数**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/simplePageList`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageLocationSimplePageList = makeRequest<
  FetchStorageLocationSimplePageListRequest,
  FetchStorageLocationSimplePageListResponse,
  FetchStorageLocationSimplePageListRequestConfig
>(fetchStorageLocationSimplePageListRequestConfig);

/**
 * 接口 [查询子级↗](http://yapi.blacklake.tech/project/503/interface/api/75386) 的 **请求类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/_search_child`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationSearchChildRequest {
  /**
   * id
   */
  id?: number;
  /**
   * 是否查询所有子级：1是，0否
   */
  searchAll?: number;
  /**
   * id类型：0仓库，1区域
   */
  type?: number;
  /**
   * 仓库id
   */
  warehouseId?: number;
}

/**
 * 接口 [查询子级↗](http://yapi.blacklake.tech/project/503/interface/api/75386) 的 **返回类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/_search_child`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageLocationSearchChildResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 子级信息
     */
    children?: unknown[];
    /**
     * 编号
     */
    code?: string;
    /**
     * 状态：1启用，0停用
     */
    enableFlag?: number;
    /**
     * id
     */
    id?: number;
    /**
     * 是否删除：0否，删除为删除时的时间戳
     */
    isDelete?: number;
    /**
     * 名称
     */
    name?: string;
    /**
     * 识别码
     */
    qrCode?: string;
    /**
     * 描述
     */
    remark?: string;
    /**
     * 类型：1区域，2仓位
     */
    type?: number;
    /**
     * 仓库id
     */
    warehouseId?: number;
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [查询子级↗](http://yapi.blacklake.tech/project/503/interface/api/75386) 的 **请求配置的类型**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/_search_child`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageLocationSearchChildRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_location/_search_child',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [查询子级↗](http://yapi.blacklake.tech/project/503/interface/api/75386) 的 **请求配置**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/_search_child`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageLocationSearchChildRequestConfig: FetchStorageLocationSearchChildRequestConfig = {
  mockUrl: mockUrl_0_21_0_0,
  devUrl: devUrl_0_21_0_0,
  prodUrl: prodUrl_0_21_0_0,
  path: '/storage/domain/web/v1/storage_location/_search_child',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_21_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageLocationSearchChild',
};

/**
 * 接口 [查询子级↗](http://yapi.blacklake.tech/project/503/interface/api/75386) 的 **请求函数**
 *
 * @分类 [仓库仓位↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10724)
 * @标签 `仓库仓位`
 * @请求头 `POST /storage/domain/web/v1/storage_location/_search_child`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageLocationSearchChild = makeRequest<
  FetchStorageLocationSearchChildRequest,
  FetchStorageLocationSearchChildResponse,
  FetchStorageLocationSearchChildRequestConfig
>(fetchStorageLocationSearchChildRequestConfig);

/* prettier-ignore-end */
