import React from 'react';
import { BlIcon } from '@blacklake-web/component';
import styles from './styles.module.scss';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import { NOTIFICATION_TYPE } from 'src/dict/notification';
import { Tooltip as AntdTooltip } from 'antd';
import { DebouncedFunc } from 'lodash';
import { FormInstance } from 'antd/es/form/Form';
import _Url from 'src/utils/url';
import moment from 'moment';

interface Props {
  type: string;
  messageForm?: FormInstance;
  initData: any;
  getAggregationListFn?: DebouncedFunc<(params: any) => Promise<void>>;
  updateMessageStatus: (nodeData: any) => void;
}

export default function NotificationCard({
  type,
  initData,
  messageForm,
  getAggregationListFn,
  updateMessageStatus,
}: Props) {
  const urlParams = _Url.getParams().urlParams && JSON.parse(_Url.getParams().urlParams);

  const { pagination } = urlParams ?? {};

  const unread = initData?.status || false; // 0代表未读 1代表已读
  const urgent = initData?.urgent || false; // 0代表普通 1代表紧急

  const chooseType = messageForm?.getFieldValue('showType') ?? NOTIFICATION_TYPE.tile;

  const onOperationFn = () => {
    switch (chooseType) {
      case NOTIFICATION_TYPE.business_object:
        messageForm?.setFieldsValue({ showType: 'categoryDetail', urgent: false, unRead: false });

        getAggregationListFn &&
          getAggregationListFn?.({
            page: 1,
            size: pagination?.currentSize ?? 10,
            moduleId: initData?.moduleId,
          });
        break;
      // case NOTIFICATION_TYPE.business_type:
      //   messageForm?.setFieldsValue({ showType: 'categoryDetail', urgent: false, unRead: false });
      //   getAggregationListFn &&
      //     getAggregationListFn?.({
      //       page: 1,
      //       size: pagination?.currentSize ?? 10,
      //       bizTypeId: initData?.bizTypeId,
      //     });
      //   break;
      default:
        updateMessageStatus(initData);
        break;
    }
  };

  // 通知临时方案，识别http及以后的所有都为链接内容进行处理
  const getHttpString = (str: string) => {
    const strObj = {
      hasHttp: false,
      firstString: str,
      lastString: '',
    };

    if (str.includes('http')) {
      const firstString = str.split('http')?.[0];
      const lastString = str.split('http')?.[1];

      strObj.firstString = firstString;
      strObj.lastString = `http${lastString}`;
      strObj.hasHttp = true;

      return strObj;
    }

    return strObj;
  };

  const contentFormat = getHttpString(initData?.contentAfterPadding ?? initData?.content);

  return (
    <div
      className={styles?.notificationCard}
      style={{
        height: type === 'popover' ? 94 : 108,
        padding: type === 'popover' ? 10 : 16,
        marginBottom: type === 'popover' ? 8 : 16,
        backgroundColor: urgent ? ' #fffbe6' : '#fff',
        borderColor: urgent ? '#FFE58F' : '#d9d9d9',
      }}
      onClick={onOperationFn}
    >
      <div className={styles?.jinJiIcon} style={{ display: urgent ? 'inline-block' : 'none' }}>
        <BlIcon type="iconjinji" className={styles?.jinJi} />
      </div>
      <div className={styles?.tongZhiIcon}>
        <BlIcon type="iconxiaoxitongzhi" className={styles?.icon} />
        {chooseType === NOTIFICATION_TYPE.tile ||
        chooseType === 'categoryDetail' ||
        type === 'popover' ? (
          <div className={styles?.hongDian} style={{ display: !unread ? 'inline-block' : 'none' }}>
            {' '}
          </div>
        ) : (
          <div
            className={styles?.hongDianNum}
            style={{ display: initData?.unreadNum ? 'inline-block' : 'none' }}
          >
            {initData?.unreadNum > 99 ? '99+' : initData?.unreadNum}
          </div>
        )}
      </div>
      <div className={styles?.tongZhiContent}>
        <div className={styles?.titleBox}>
          <div
            className={styles?.title}
            style={{
              color: !unread ? '#595959' : '#8c8c8c',
              maxWidth: type === 'popover' ? 150 : 1000,
            }}
          >
            <AntdTooltip title={initData?.title}>
              <div className={styles?.text}>{initData?.title || replaceSign}</div>
            </AntdTooltip>
          </div>
          {initData?.createdAt && (
            <div className={styles?.time}>
              {_Time.isToday(initData?.createdAt)
                ? `今天 ${moment(initData?.createdAt).format('HH:mm')}`
                : moment(initData?.createdAt).format('YYYY-MM-DD HH:mm')}
            </div>
          )}
        </div>
        <div className={styles?.moduleBox}>
          <span
            style={{
              color: !unread ? '#595959' : '#8c8c8c',
              // borderRight: !unread ? '1px solid #595959' : '1px solid #8c8c8c',
            }}
            className={styles?.moduleSpan}
          >
            {initData?.moduleName ?? replaceSign}
          </span>
          {/* <span style={{ color: !unread ? '#595959' : '#8c8c8c' }} className={styles?.moduleSpan}>
              {initData?.bizTypeName ?? replaceSign}
            </span> */}
        </div>
        <div className={styles?.content}>
          <AntdTooltip title={initData?.contentAfterPadding}>
            <div className={styles?.text}>
              {contentFormat?.hasHttp ? (
                <>
                  <span>{contentFormat?.firstString}</span>
                  <span>
                    <a href={contentFormat?.lastString} target="_blank" rel="noreferrer">
                      {contentFormat?.lastString}
                    </a>
                  </span>
                </>
              ) : (
                <span>{contentFormat?.firstString}</span>
              )}
            </div>
          </AntdTooltip>
        </div>
      </div>
    </div>
  );
}
