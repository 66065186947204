import _ from 'lodash';
import type { SopObj } from '../type';
import type { LabeledValue } from 'src/page/share';

export const sop2Lv = (sopObj: SopObj): LabeledValue => ({
  label: sopObj.name,
  value: sopObj.code,
  key: _.toString(sopObj.id),
});

export const lv2Sop = (lv: LabeledValue | undefined): SopObj | undefined => {
  if (!lv) {
    return lv;
  }
  return {
    name: lv.label,
    code: lv.value,
    id: _.toNumber(lv.key),
  };
};
