import { useContext, useEffect, useRef } from 'react';
import { Popconfirm, Space, FormInstance, Button } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import { formatDateForRender } from 'src/utils/formatters';
import { BlColumnsType, BlTable } from 'src/components';
import OperateSpecialDayModel from './OperateSpecialDayModal';
import { lookup } from 'src/dict';
import { CRUD } from 'src/dict/common';
import { SpecialDayContext, SpecialDayDataRecordProps } from '../../constants';
import styles from './index.module.scss';

interface SpecialDayTableProps {
  value?: SpecialDayDataRecordProps[];
  onChange?: (newValue: SpecialDayDataRecordProps[]) => void;
  calendarForm?: FormInstance;
  readOnly?: boolean;
}

// 特殊日表格的表头需要required符号
const titleRenderer = (titleText: string) => {
  return (
    <Space size={3}>
      <span style={{ color: 'red' }}>*</span>
      {titleText}
    </Space>
  );
};

export default function SpecialDayTable(props: SpecialDayTableProps) {
  const { value, onChange, calendarForm, readOnly } = props;
  // 编辑特殊日的弹窗需要用到班次数据，所有特殊日弹窗都必须从当前组件触发，所以在当前组件中加载班次数据，避免每次弹窗重复加载
  const { specialDayState, showSpecialDayDialog, hideSpecialDayDialog, refreshCalendar } =
    useContext(SpecialDayContext);
  const { showDialog, specialDay } = specialDayState;
  const isUnmounted = useRef(false);

  // 新建特殊日，要清空正在编辑的特殊日数据，以免数据回填
  const addSpecialDay = () => {
    showSpecialDayDialog?.();
  };

  // 注意！特殊日列表因为可能存在大量未保存的新建特殊日，所以不能用specialDayId来索引
  // 暂时与后台讨论加入specialDayIndex字段来存储由前端生成的临时index
  const onCreateOrEditFinished = (newSpecialDay: SpecialDayDataRecordProps) => {
    const { specialDayIndex, startTime } = newSpecialDay;

    if (!value) {
      onChange?.([newSpecialDay]);
    } else {
      const newSpDayArr = [...value];
      const updateIndex = _.findIndex(newSpDayArr, (spDay) => {
        return spDay.specialDayIndex === specialDayIndex;
      });

      // 如果没有找到对应的特殊日，则为新建，否则覆盖原来的特殊日
      if (updateIndex >= 0) {
        newSpDayArr[updateIndex] = newSpecialDay;
        onChange?.(newSpDayArr);
      } else {
        onChange?.([...value, newSpecialDay]);
      }
    }
    // 新建/编辑特殊日后刷新日历预览，显示被新加特殊日开始日期的当月
    refreshCalendar?.(moment(startTime));
    hideSpecialDayDialog?.();
  };

  // 删除特殊日: 根据特殊日在table里的顺序做删除，能使用是因为目前表格不支持排序和分页
  const handleDelete = (tableIndex: number) => {
    if (!value) return;

    // 删除特殊日之后刷新日历，停留在被删除日的当页
    const deletedDay = value[tableIndex];

    refreshCalendar?.(moment(deletedDay.startTime));
    onChange?.([...value?.slice(0, tableIndex), ...value?.slice(tableIndex + 1, value.length)]);
  };

  const operateColumn = {
    title: '操作',
    width: 120,
    fixed: 'right',
    render: (_text: string, record: SpecialDayDataRecordProps, index: number) => {
      return (
        <Space>
          <a
            onClick={() => {
              showSpecialDayDialog?.(record);
            }}
          >
            {lookup('common.crud', CRUD.edit)}
          </a>
          <Popconfirm
            placement="topRight"
            title="你确定要删除吗？"
            onConfirm={() => {
              handleDelete(index);
            }}
          >
            <a>{lookup('common.crud', CRUD.delete)}</a>
          </Popconfirm>
        </Space>
      );
    },
  };

  const specialDayTableColumns: BlColumnsType<SpecialDayDataRecordProps> = [
    {
      title: titleRenderer('名称'),
      dataIndex: 'name',
      width: 200,
    },
    {
      title: titleRenderer('开始日期'),
      dataIndex: 'startTime',
      width: 120,
      render: formatDateForRender,
    },
    {
      title: titleRenderer('结束日期'),
      dataIndex: 'endTime',
      width: 120,
      render: formatDateForRender,
    },
    {
      title: titleRenderer('班次名称'),
      dataIndex: 'shiftName',
      width: 200,
      render: (_text: string, record: SpecialDayDataRecordProps) => {
        return record.shiftName;
      },
    },
    ...(readOnly ? [] : [operateColumn]),
  ];

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  return (
    <>
      <BlTable
        className={styles.specialDayTable}
        columns={specialDayTableColumns}
        rowKey="specialDayIndex" // 新建的特殊日还没有id，必须用其他东西当rowkey，采用index，本列表不能排序不能分页！
        pagination={false}
        size="small"
        dataSource={value}
        scroll={{ y: 320 }}
        footer={() => {
          return readOnly ? null : (
            <Button type="dashed" block onClick={addSpecialDay}>
              添加特殊日
            </Button>
          );
        }}
      />
      {/* 编辑特殊日的弹窗需要用到班次数据，所有特殊日弹窗都必须从当前组件触发，所以在当前组件中加载班次数据，从props传入弹窗，避免每次弹窗重复加载 */}
      {showDialog && calendarForm && !readOnly && (
        <OperateSpecialDayModel
          specialDayData={specialDay}
          specialDayList={value}
          calendarForm={calendarForm}
          onChange={onCreateOrEditFinished}
          onClose={() => {
            hideSpecialDayDialog?.();
          }}
        />
      )}
    </>
  );
}
