import React from 'react';
import { RecordListLayout } from '@blacklake-web/layout';
import { fetchPurchaseWorkbenchPurchaseProgressReport } from 'src/api/ytt/order-domain/workBench';
import _ from 'lodash';
import { replaceSign } from 'src/utils/constants';
import { TagList } from 'src/components';
import { formatTimeForRender } from 'src/utils/formatters';
import lookup from 'src/dict';
import { OVERDUE_RISK, PurchaseExecStatus } from 'src/dict/purchase';
import ProgressList from './components/ProgressList';

interface ProgressDataType {
  id?: number;
  code: string;
  demandTime: number;
  seqNum: number;
  material: any;
  supplier: any;
  overdueRisk: number;
  productStorage: number;
  productionPlanIssued: number;
  receivedProgress: number;
  deliveryProgress: number;
}

const PurchaseProgress: React.FC = () => {
  const dataColumns = [
    {
      title: '采购订单编号',
      dataIndex: 'code',
      width: 200,
    },
    {
      title: '供应商',
      dataIndex: 'supplier',
      width: 200,
      render: (text: any, record: ProgressDataType) => _.get(record, 'supplier.name', replaceSign),
    },
    {
      title: '行号',
      dataIndex: 'seqNum',
      width: 120,
    },
    {
      title: '物料编号',
      dataIndex: 'materialCode',
      width: 200,
      render: (text: any, record: ProgressDataType) =>
        _.get(record, 'material.baseInfo.code', replaceSign),
    },
    {
      title: '物料名称',
      dataIndex: 'materialName',
      width: 200,
      render: (text: any, record: ProgressDataType) =>
        _.get(record, 'material.baseInfo.name', replaceSign),
    },
    {
      title: '物料属性',
      dataIndex: 'materialAttribute',
      type: 'number',
      width: 250,
      render: (text: any, record: ProgressDataType) => {
        const attributes = _.get(record, 'material.attribute', []);

        if (_.isEmpty(attributes)) return replaceSign;

        return (
          <TagList
            dataSource={_.map(attributes, ({ name, attributeItem, id }) => ({
              label: `${name}:${attributeItem?.content}`,
              value: id,
            }))}
          />
        );
      },
    },
    {
      title: '需求日期',
      dataIndex: 'demandTime',
      width: 200,
      render: formatTimeForRender,
    },
    {
      title: '逾期风险',
      dataIndex: 'overdueRisk',
      width: 120,
      render: (text: number) => {
        return (
          <span style={{ color: text === OVERDUE_RISK.low ? '#52c41a' : '#f5222d' }}>
            {lookup('purchase.overdueRisk', text)}
          </span>
        );
      },
    },
    {
      title: '进度',
      dataIndex: 'progress',
      width: 500,
      render: (text: unknown, record: ProgressDataType) => {
        const progress = _.pick(record, [
          'productionPlanIssued',
          'productStorage',
          'deliveryProgress',
          'receivedProgress',
        ]);

        return <ProgressList {...progress} />;
      },
    },
  ];

  const formatDataToQuery = (params: any) => {
    return { ...params, execStatusList: [PurchaseExecStatus.executing] };
  };

  return (
    <>
      <RecordListLayout<ProgressDataType>
        columns={dataColumns}
        placeholder={'输入采购订单编号'}
        useQuickFilter={false}
        formatDataToQuery={formatDataToQuery}
        rowKey="id"
        useIndex={false}
        configcacheKey="purchaseProgress"
        requestFn={fetchPurchaseWorkbenchPurchaseProgressReport}
      />
    </>
  );
};

export default PurchaseProgress;
