export const ACTION_CREATE = 'create';
export const ACTION_EDIT = 'edit';
export const ACTION_COPY = 'copy';

// 工作中心字段转化字段转化
export const FIELD_MAP = new Map([
  ['createTime', 'createdAt'],
  ['updateTime', 'updatedAt'],
]);

export const workCenterStatusMap = new Map([
  [ACTION_CREATE, '新建工作中心'],
  [ACTION_EDIT, '编辑工作中心'],
  [ACTION_COPY, '复制工作中心'],
]);

export const calendarMap = new Map([
  [0, '是'],
  [1, '否'],
]);
interface UserColumnProps {
  id: number;
  name: string;
  avatarUrl: string;
}
interface CreatorData {
  avatarUrl: string;
  id: number;
  name: string;
}
interface UserListItem {
  id: number;
  name?: string;
}

/**
 * 资源组分类枚举
 */
interface TypeItem {
  code: string | number;
  message: string;
}

interface ReplaceItem {
  lineNo: string | number;
  groupType: string | number;
  resourceGroupName: string | number;
  userList: UserListItem[];
}

interface WorkCenterGroupCOListFormField {
  lineNo?: number;
  groupType?: number;
  resourceGroupName?: number | string;
  name?: number | string;
  bizId?: number | string;
  bizType?: number | string;
  userList?: UserListItem[];
  departmentList?: UserListItem[];
  resourceList?: UserListItem[];
}

// 资源组分类
export const GroupTypesEnum = {
  USER: 0, // 用户
  DEPARTMENT: 1, // 部门
  GROUPTYPE: 2, // 资源分类
};

export type {
  UserColumnProps,
  CreatorData,
  UserListItem,
  ReplaceItem,
  WorkCenterGroupCOListFormField,
  TypeItem,
};

// 资源清单不可编辑行号
export const workCenterGroupCOListDisableList = [0, 1, 2];
