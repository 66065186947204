import lookup from 'src/dict';
import { FetchTriggerActionDetailData } from '../constant';

// type Option = {
//   label: string;
//   key: string | number;
//   value: string | number;
//   dataSource?: { fieldType: number };
//   ruleBindObjectCode?: string;
// };
// type detailItem = {
//   fieldId: string;
//   id?: number;
//   seq: number;
//   valueType: number;
//   value: string[];
// };
// interface TriggerActionFormData
//   extends Pick<
//     FetchTriggerActionCreateRequest,
//     'name' | 'id' | 'actionType' | 'code' | 'numberRuleId' | 'remark'
//   > {
//   objectCode: Option;
//   triggerRuleId: string;
//   details: detailItem[];
// }

type FormatDataToRequest = (data: FetchTriggerActionDetailData) => any;
export const formatToDetailData: FormatDataToRequest = (data) => {
  const { actionType, ...restData } = data;
  const _actionTypeText = lookup('triggerAction.actionTypeOptions', actionType);

  return {
    ...restData,
    actionType: _actionTypeText,
    // details: details.map(formatDetails),
  };
};
