/**
 * @description 触发条件配置，值组件
 */
import { Form, Input, Select, SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';

import _ from 'lodash';
import { appDict, appEnum } from 'src/dict';
import SearchSelect, { SearchSelectProps } from 'src/components/searchSelect';
import { numberMinMaxCheck } from 'src/utils/formValidators';

import MyDatePicker from '../../share/FormItemComponents/MyDatePicker';
import RedRemindText from '../../share/RedRemindText';
import withFormItem from '../../share/ConditionEditTableLayout/withFormItem';
import { getValueFromStringfy } from '../../utils/tools';
import FieldIdCascader, { CascaderOption } from '../../share/FieldIdCascader';

const { CustomField, TriggerRule } = appEnum;

interface RuleConditionValueItemProps {
  dependenceValues: any[];
  disabled?: boolean;
  /** 字段引用，值的级联选项 */
  bizOptions: CascaderOption[];
  bizLoading: boolean;
}

const getItemRules = (valueType: number, fieldType: number) => {
  const rule = [{ required: true, message: '值不能为空' }];

  if (valueType === TriggerRule.ValueType.biz) return rule;
  const getRules = (rules: any[] = []) => _.concat(rule, rules);

  switch (fieldType) {
    case CustomField.FieldType.text:
    case CustomField.FieldType.textArea:
      return getRules([{ max: 100, message: '不超过100字符' }]);
    case CustomField.FieldType.number:
    case CustomField.FieldType.integer:
      return getRules([{ validator: numberMinMaxCheck({}) }]);
    case CustomField.FieldType.boolean:
    case CustomField.FieldType.date:
    case CustomField.FieldType.select:
    case CustomField.FieldType.multiSelect:
    case CustomField.FieldType.relation:
    case CustomField.FieldType.relationSub:
    case CustomField.FieldType.subordinate:
      return getRules();
    default:
      return getRules();
  }
};

export const isAllowedEmpty = (conditionType: number) =>
  conditionType === TriggerRule.ConditionType.NOT_NULL ||
  conditionType === TriggerRule.ConditionType.NULL;

const RuleConditionValueItem: React.FC<RuleConditionValueItemProps> = ({
  dependenceValues,
  bizOptions,
  bizLoading,
  ...restProps
}) => {
  const [objectCode, valueType, conditionType, lastFieldInfo] = dependenceValues;
  const { fieldType, targetType, choiceValues, reference } = lastFieldInfo ?? {};

  const placeholder = '请输入值';
  const sPlaceholder = '请选择值';
  const SelectProps: SelectProps<SelectValue> = {
    ...restProps,
    labelInValue: true,
    placeholder: sPlaceholder,
    options: choiceValues?.map(({ choiceCode, choiceValue }: any) => ({
      label: choiceValue,
      value: Number(choiceCode),
    })),
    mode:
      fieldType === CustomField.FieldType.multiSelect ||
      conditionType === appEnum.TriggerRule.ConditionType.BELONG_TO
        ? 'multiple'
        : undefined,
  };
  const SearchSelectProps: SearchSelectProps<SelectValue> = {
    ...restProps,
    fetchType: 'triggerRuleQueryInstance',
    placeholder: sPlaceholder,
    params: { objectCode: reference },
    mode:
      targetType === CustomField.TargetType.multiChoice ||
      conditionType === appEnum.TriggerRule.ConditionType.BELONG_TO
        ? 'multiple'
        : undefined,
  };

  if (isAllowedEmpty(conditionType)) {
    return <Input disabled={isAllowedEmpty(conditionType)} />;
  }
  if (!(typeof valueType === 'number')) {
    return <RedRemindText text="请先选择值类型" />;
  }
  if (valueType === TriggerRule.ValueType.biz) {
    return objectCode ? (
      <FieldIdCascader allowClear {...restProps} options={bizOptions} loading={bizLoading} />
    ) : (
      <RedRemindText text="请先选择业务对象" />
    );
  }

  switch (fieldType) {
    case CustomField.FieldType.text:
    case CustomField.FieldType.textArea:
      return <Input placeholder={placeholder} {...restProps} />;
    case CustomField.FieldType.number:
    case CustomField.FieldType.integer:
      return <Input placeholder={placeholder} {...restProps} />;
    case CustomField.FieldType.date:
      return <MyDatePicker showTime showNow {...restProps} />;
    case CustomField.FieldType.boolean:
      return <Select options={appDict.common.yns} placeholder={sPlaceholder} {...restProps} />;
    case CustomField.FieldType.select:
    case CustomField.FieldType.multiSelect:
      return <Select {...SelectProps} />;
    case CustomField.FieldType.relation:
    case CustomField.FieldType.relationSub:
    case CustomField.FieldType.subordinate:
      return <SearchSelect {...SearchSelectProps} />;
    default:
      return <RedRemindText text="请先选择字段" />;
  }
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};

interface RuleConditionValueProps {
  dependencies?: any[];
  name?: (string | number)[];
  diabled?: boolean;
  dependenceValues?: any[];
  field: any;
  bizOptions: CascaderOption[];
  bizLoading: boolean;
}

const RuleConditionValue: React.FC<RuleConditionValueProps> = ({
  field,
  dependenceValues,
  ...restProps
}) => {
  const [
    updateDataCheck,
    beforeValueType,
    afterValueType,
    beforeConditionType,
    afterConditionType,
    fieldId,
    _objectCode,
  ] = dependenceValues ?? [];
  const objectCode = getValueFromStringfy(_objectCode, 'objectCode');
  const lastField: any = _.last(fieldId);
  const lastFieldInfo: any = lastField?.info;
  const afterRules = getItemRules(afterValueType, lastFieldInfo?.fieldType);
  const beforeRules = getItemRules(beforeValueType, lastFieldInfo?.fieldType);

  return (
    <div style={style}>
      {!updateDataCheck ? null : (
        <Form.Item
          name={_.concat(field?.name, 'beforeValues')}
          rules={isAllowedEmpty(beforeConditionType) ? [] : beforeRules}
        >
          <RuleConditionValueItem
            {...restProps}
            dependenceValues={[objectCode, beforeValueType, beforeConditionType, lastFieldInfo]}
          />
        </Form.Item>
      )}
      <Form.Item
        name={_.concat(field?.name, 'afterValues')}
        rules={isAllowedEmpty(afterConditionType) ? [] : afterRules}
      >
        <RuleConditionValueItem
          {...restProps}
          dependenceValues={[objectCode, afterValueType, afterConditionType, lastFieldInfo]}
        />
      </Form.Item>
    </div>
  );
};

export default withFormItem(RuleConditionValue);
