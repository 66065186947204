import { Descriptions } from 'antd';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import _Time from 'src/utils/dataTypes/time';

//
// import { BomResponse } from '../createAndEdit/index.type';

interface OtherInfoTabProps {
  dataSource?: any;
}

const OtherInfoTab = (props: OtherInfoTabProps) => {
  const { dataSource } = props;

  const { operator, updatedAt, creator, createdAt, remark } = dataSource?.planOrderBase ?? {};

  return (
    <div style={{ paddingBottom: 20 }}>
      <Descriptions column={1}>
        <Descriptions.Item label="备注">{remark}</Descriptions.Item>
        <Descriptions.Item label="创建人">
          {creator?.id && (
            <AvatarDisplay {...creator} avatarUrl={creator?.avatarUrl} isShowTag={false} isUser />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="创建时间">{_Time.format(createdAt)}</Descriptions.Item>
        <Descriptions.Item label="更新人">
          {operator && (
            <AvatarDisplay {...operator} avatarUrl={operator?.avatarUrl} isShowTag={false} isUser />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="更新时间">{_Time.format(updatedAt)}</Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default OtherInfoTab;
