/**
 * 串行链组件
 */
import { useRef, memo, useState, useEffect, forwardRef } from 'react';
import Dragable from 'react-draggable';
import type { FlowChartProps } from '../index.d';
import NodeContainer from '../share/nodeContainer';
import LineContainer from '../share/lineContainer';
import NodeForm from './processForm';
import EdgeForm from './processLinkForm';
import { clickTypeEnum } from '../../constant';
import _ from 'lodash';
import { gcArray } from 'src/utils';
import { message } from 'antd';
import { YN } from 'src/dict/common';
import { FeedingType } from 'src/dict/material';
import { getDefaultProcessManufacturedGoods } from '../../utils';

interface Props extends FlowChartProps {
  isWorkOrder?: number;
  name?: string;
  useType?: number;
  isEditMode: Boolean;
}

const processes = 'processes';
const processConnections = 'processConnections';

const RenderForm = memo((props: any) => {
  return props.type === clickTypeEnum.node ? (
    <NodeForm
      isWorkOrder={props.isWorkOrder}
      edgeLength={props.edgeLength}
      nodeLength={props.nodeLength}
      index={props.currentIndex}
      name={props.name}
      nextName={processes}
      processConName={processConnections}
      form={props.form}
      isEditMode={props.isEditMode}
    />
  ) : (
    <EdgeForm
      isWorkOrder={props.isWorkOrder}
      useType={props.useType}
      index={props.currentEdgeIndex}
      name={props.name}
      nextName={processConnections}
      processName={processes}
      form={props.form}
      isEditMode={props.isEditMode}
    />
  );
});

const FlowChart = forwardRef((props: Props, ref) => {
  const { data = [], edge = [], name = 'process', form, isWorkOrder, useType, isEditMode } = props;

  const getProcessNum = () => {
    const value = form?.getFieldsValue();

    return Number(_.max(_.map(value[name]?.processes, (o) => Number(o.processNum)))) + 10;
  };
  const getInitialNode = (index: number): any => {
    // 根据节点添加顺序
    return {
      processSeq: index + 1,
      processNum: String(getProcessNum()),
    };
  };
  const getProcessConnection = (prevNode: any, nextNode?: any) => {
    return {
      prevProcessNum: prevNode?.processNum,
      prevProcessName: prevNode?.processName,
      prevProcessId: prevNode?.processId,
      nextProcessNum: nextNode?.processNum,
      nextProcessName: nextNode?.processName,
      nextProcessId: nextNode?.processId,
    };
  };
  // 刷新接续关系
  const refreshConnection = (nodes: any[], edges: any[], relateIndex?: number[]) => {
    console.log(nodes);
    const cloneEdges = _.cloneDeep(edges);
    const result: any[] = [];

    nodes.forEach((item: any, key: number) => {
      if (key < nodes.length - 1) {
        const edge = _.find(cloneEdges, ['prevProcessNum', item.processNum]);
        // 节点改变，需要清除投料控件
        const nodeChange = relateIndex?.includes(key + 1) || relateIndex?.includes(key);

        result.push({
          ...edge,
          ...getProcessConnection(nodes[key], nodes[key + 1]),
          processManufacturedGoods: {
            amount: 1,
            warehousing: YN.yes,
            feedType: FeedingType.feeding,
            feeding: YN.yes,
            limit: 1,
            standardAmount: 1,
            ...edge?.processManufacturedGoods,
            ...(nodeChange ? { materialFeedControl: undefined } : {}),
          },
        });
      }
    });
    return result;
  };
  const refreshProcessSeq = (nodes: any[]) => {
    const _nodes = _.cloneDeep(nodes);

    return _nodes.map((item: any, i: number) => {
      return {
        ...item,
        processSeq: i + 1,
      };
    });
  };
  const getInitialEdge = (prevNodeIndex: number, _nodeList: any[]): any => {
    const prevNode = _nodeList[prevNodeIndex];
    const nextNode = _nodeList[prevNodeIndex + 1];

    return {
      ...getProcessConnection(prevNode, nextNode),
      processManufacturedGoods: getDefaultProcessManufacturedGoods(),
    };
  };
  const [nodeList, setNodeList] = useState<any[]>(data);
  const [edgeList, setEdgeList] = useState<any[]>(edge);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentEdgeIndex, setCurrentEdgeIndex] = useState<number>(0);
  const [currentType, setCurrentType] = useState(clickTypeEnum.node);

  const isRelativeCon = (index: number) => {
    return currentEdgeIndex === index || currentEdgeIndex === index - 1;
  };
  const setCurrentInfo = (type: string, index: number) => {
    if (type === 'del') {
      if (currentType === clickTypeEnum.node) {
        if (currentIndex === index) {
          setCurrentIndex(0);
        }
        if (currentIndex > index) setCurrentIndex(currentIndex - 1);
      } else {
        if (isRelativeCon(index)) {
          setCurrentIndex(0);
          setCurrentType(clickTypeEnum.node);
        }
        if (currentEdgeIndex > index) {
          setCurrentEdgeIndex(currentEdgeIndex - 1);
        }
      }
    }
    if (type === 'add') {
      if (currentType === clickTypeEnum.node && currentIndex > index) {
        setCurrentIndex(currentIndex + 1);
      }
      if (currentType === clickTypeEnum.edge && currentEdgeIndex >= index) {
        setCurrentEdgeIndex(currentEdgeIndex + 1);
      }
    }
  };

  // 当前节点之后添加新节点
  const insertNode = async (index: number) => {
    const newIndex = index + 1;
    const value = form?.getFieldsValue();
    const nodes = _.cloneDeep(value[name]?.processes) || [];
    const edges = _.cloneDeep(value[name]?.processConnections) || [];

    nodes.splice(newIndex, 0, getInitialNode(newIndex));
    edges.splice(newIndex, 0, getInitialEdge(index, nodes));

    form?.setFieldsValue({
      [name]: {
        [processes]: refreshProcessSeq(nodes),
        [processConnections]: refreshConnection(nodes, edges, [newIndex]),
        // [processConnections]: refreshPrevNextConnnect(newIndex, nodes, edges),
      },
    });
    setCurrentInfo('add', index);
  };
  // 改变节点位置
  const moveNode = (sourceIndex: number, targetIndex: number) => {
    const value = form?.getFieldsValue();
    const nodes = _.cloneDeep(value[name]?.processes);
    const edges = _.cloneDeep(value[name]?.processConnections);
    const moveNodes = gcArray.move(nodes, sourceIndex, targetIndex);
    // const newEdges = refreshPrevNextConnnect(sourceIndex, moveNodes, edges);

    form?.setFieldsValue({
      [name]: {
        [processes]: refreshProcessSeq(moveNodes),
        [processConnections]: refreshConnection(moveNodes, edges, [sourceIndex, targetIndex]),
        // [processConnections]: refreshPrevNextConnnect(targetIndex, moveNodes, newEdges),
      },
    });
  };
  // 删除节点
  const deleteNode = (index: number) => {
    const value = form?.getFieldsValue();
    const nodes = _.cloneDeep(value[name]?.processes);
    const edges = _.cloneDeep(value[name]?.processConnections);

    if (!nodes || nodes.length <= 1) {
      return;
    }

    const connection = refreshConnection(nodes, edges, [index]);

    if (index === nodes.length - 1) {
      // 最后一个节点
      connection.pop();
    } else {
      connection.splice(index, 1);
    }
    nodes.splice(index, 1);
    form?.setFieldsValue({
      [name]: {
        [processes]: refreshProcessSeq(nodes),
        [processConnections]: refreshConnection(nodes, connection),
        // [processConnections]: refreshPrevNextConnnect(index, nodes, edges),
      },
    });
    setCurrentInfo('del', index);
  };

  const getNodeFields = () => {
    const fields = form?.getFieldsError();
    const list = _.filter(fields, (o) => o.name[1] === processes);

    return _.map(list, 'name');
  };
  const getEdgeFields = () => {
    const fields = form?.getFieldsError();
    const list = _.filter(fields, (o) => o.name[1] === processConnections);

    return _.map(list, 'name');
  };

  const validateFormData = async () => {
    try {
      const fields = currentType === clickTypeEnum.node ? getNodeFields() : getEdgeFields();
      const values = await form?.validateFields(fields);

      if (currentType === clickTypeEnum.node) {
        return values[name][processes];
      }
      return values[name][processConnections];
    } catch (e) {
      return false;
    }
  };

  // 节点点击事件
  const clickNode = async (index: number) => {
    // 获取外部表单数据
    const values = await validateFormData();

    if (values) {
      setCurrentIndex(index);
      setCurrentType(clickTypeEnum.node);
    } else {
      message.error('请填写完整信息');
    }
  };
  // 边线点击
  const clickEdge = async (index: number) => {
    // 获取外部表单数据
    const values = await validateFormData?.();

    if (values) {
      setCurrentEdgeIndex(index);
      setCurrentType(clickTypeEnum.edge);
    } else {
      message.error('请填写完整信息');
    }
  };

  useEffect(() => {
    setNodeList(_.cloneDeep(data));
    setEdgeList(_.cloneDeep(edge));
  }, [data]);

  const nodeRef = useRef(Array(nodeList.length).fill(null)) as any;

  return (
    <div style={{ border: '1px solid #e8e8e8' }}>
      <div style={{ overflowX: 'scroll' }}>
        <div
          style={{
            borderBottom: '1px solid #e8e8e8',
            display: 'flex',
            padding: 36,
            flexWrap: 'nowrap',
          }}
        >
          {nodeList?.map((item: any, key: number) => {
            return (
              <div key={key} style={{ minWidth: 187, display: 'inline-block' }}>
                <Dragable
                  nodeRef={nodeRef[key]}
                  onStart={(n) => {
                    // console.log(n);
                  }}
                  onStop={(__, b) => {
                    const { node, x } = b || {};
                    const nodeWidth = _.get(node, 'clientWidth', 0) + 2;
                    // 元素当前位置对应父级的左边的位置
                    const offsetLeft = _.get(node, 'offsetLeft', 0) + x + nodeWidth - 36;
                    // 目标的index。
                    const targetIndex = Math.floor(offsetLeft / (nodeWidth + 40));

                    if (targetIndex != key) {
                      moveNode(key, targetIndex);
                    }
                  }}
                  axis="x"
                  position={{ x: 0, y: 0 }}
                >
                  <NodeContainer
                    selecting={key === currentIndex && currentType === clickTypeEnum.node}
                    ref={nodeRef}
                    index={key}
                    nodeInfo={item}
                    onDelete={deleteNode}
                    onAdd={insertNode}
                    onClick={clickNode}
                  />
                </Dragable>
                <LineContainer
                  index={key}
                  selecting={key === currentEdgeIndex && currentType === clickTypeEnum.edge}
                  edgeInfo={edgeList[key]}
                  clickFunction={() => clickEdge(key)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <RenderForm
        name={name}
        currentIndex={currentIndex}
        currentEdgeIndex={currentEdgeIndex}
        edgeLength={_.isEmpty(edgeList) ? 0 : edgeList.length}
        nodeLength={nodeList?.length}
        form={form}
        isWorkOrder={isWorkOrder}
        useType={useType}
        type={currentType}
        isEditMode={isEditMode}
      />
    </div>
  );
});

export default memo(FlowChart);
