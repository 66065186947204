/**
 *  @control 资源参数（固定参数及选择参数）
 */
import { FC } from 'react';
import { Input } from 'antd';
import { PreviewCompProps } from '../../types';

export const Resource: FC<PreviewCompProps> = ({ inputPrompt }) => {
  return <Input style={{ pointerEvents: 'none' }} tabIndex={-1} placeholder={inputPrompt} />;
};
