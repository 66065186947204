import React, { useState, useEffect } from 'react';
import { DetailBaseInfo } from './components/detailBaseInfo';
import { match, RouteComponentProps } from 'react-router-dom';
import { toEdit, toList } from './navigation';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import { batchRequest } from './utils';
import { UsageStatus } from 'src/dict/common';
import authDict from 'src/utils/auth';
import {
  fetchApprovalPlanDelete,
  fetchApprovalPlanDetail,
  fetchApprovalPlanDisable,
  fetchApprovalPlanEnable,
} from 'src/api/ytt/metadata-domain/approvalProcessScheme';
import { message as Message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface DetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const ApprovalProcessDetail = (props: DetailProps) => {
  const { match, history } = props;
  const id = Number(match?.params?.id);
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [dataSource, setDataSource] = useState({} as any);

  const fetchData = async () => {
    try {
      const { data } = await fetchApprovalPlanDetail({ id });

      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = async () => {
    try {
      const { message, code } = await fetchApprovalPlanDelete({ id });

      if (code === 200) {
        Message.success('删除成功');

        history.push(toList());

        return;
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  const baseMenu = [
    {
      key: 'enable',
      auth: authDict.approvalScheme_enable_disable,
      onClick: async () => {
        const storeApi = dataSource?.status ? fetchApprovalPlanDisable : fetchApprovalPlanEnable;
        const storeDisplay = dataSource?.status ? '停用' : '启用';

        if (!dataSource?.status && !dataSource?.hasNode) {
          return Message.warning('至少添加一个审批节点');
        }

        batchRequest(storeApi, id, storeDisplay, fetchData);
      },
      icon:
        dataSource?.status === UsageStatus.enabled ? (
          <BlIcon type="icontingyong" />
        ) : (
          <BlIcon type="iconqiyong" />
        ),
      title: dataSource?.status ? '停用' : '启用',
    },
    {
      key: 'delete',
      auth: authDict.approvalScheme_remove,
      onClick: () => {
        Modal.confirm({
          okText: '确认',
          title: '确认要删除当前审批流程方案吗？',
          icon: <ExclamationCircleOutlined />,
          content: '删除后不影响当前正在执行的审批业务；确认要删除吗？',
          onOk() {
            handleFinish();
          },
        });
      },
      icon: <BlIcon type="iconshanchulajitong1" />,
      disabled: dataSource?.status,
      title: '删除',
    },
    {
      key: 'log',
      auth: authDict.approvalScheme_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      icon: <BlIcon type="iconcaozuojilu" />,
      title: '操作记录',
    },
    {
      key: 'edit',
      auth: authDict.approvalScheme_edit,
      disabled: dataSource?.status,
      onClick: () => {
        history.push(toEdit(id));
      },
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ];

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <>
      <DetailBaseInfo detail={dataSource} baseMenu={baseMenu} />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={id}
          objectCode={'ApprovalScheme'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default ApprovalProcessDetail;
