import { RecordListLayout } from 'layout';
import { dataColumnsForDetail } from '../components/columns';
import { ShipmentNoteListItemDetailResponse } from '../interface';
import _ from 'lodash';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import {
  fetchShipmentNoteListItemDetail,
  FetchShipmentNoteListItemDetailRequest,
} from 'src/api/ytt/order-domain/invoice';
import { FilterItem } from '@blacklake-web/layout/dist/logList/LogListLayout.type';
import { intersectionValueOfId, mapLabeledValueToValue } from 'src/utils/array';

const InvoiceDetailList = () => {
  /**
   * Columns
   */
  const dataColumns = [
    ...dataColumnsForDetail,
    {
      title: '',
      dataIndex: 'action',
      width: 40,
      fixed: 'right',
      render: () => {
        return <></>;
      },
    },
  ];
  /**
   * 传给接口数据处理函数
   */
  const formatDataToQuery = (param: any): FetchShipmentNoteListItemDetailRequest => {
    const {
      createdAt,
      updatedAt,
      materialCode,
      materialName,
      creator,
      operator,
      deliveryDate,
      shipmentNoteStatus,
      finishedAt,
      finishUser,
      ...rest
    } = param;

    // 接口需要的创建时间和更新时间为number型的timestamp
    return {
      createdAtFrom: createdAt && createdAt[0] ? Date.parse(createdAt[0]) : undefined,
      createdAtTo: createdAt && createdAt[1] ? Date.parse(createdAt[1]) : undefined,
      updatedAtFrom: updatedAt && updatedAt[0] ? Date.parse(updatedAt[0]) : undefined,
      updatedAtTo: updatedAt && updatedAt[1] ? Date.parse(updatedAt[1]) : undefined,
      finishedAtFrom: finishedAt && finishedAt[0] ? Date.parse(finishedAt[0]) : undefined,
      finishedAtTo: finishedAt && finishedAt[1] ? Date.parse(finishedAt[1]) : undefined,
      finishUserIds: mapLabeledValueToValue(finishUser),
      materialIds: intersectionValueOfId(materialCode, materialName),
      creatorIds: mapLabeledValueToValue(creator),
      operatorIds: mapLabeledValueToValue(operator),
      deliveryDateFrom: deliveryDate && deliveryDate[0] ? Date.parse(deliveryDate[0]) : undefined,
      deliveryDateTo: deliveryDate && deliveryDate[1] ? Date.parse(deliveryDate[1]) : undefined,
      noteStatus: shipmentNoteStatus,
      ...rest,
    };
  };
  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (data: any) => {
    const { deliveryDate, createdAt, updatedAt, finishedAt, ...rest } = data;

    return {
      createdAt: momentData(createdAt),
      updatedAt: momentData(updatedAt),
      deliveryDate: momentData(deliveryDate),
      finishedAt: momentData(finishedAt),
      ...rest,
    };
  };
  /**
   * 列表筛选处理函数
   */
  const filterList: FilterItem[] = dataColumnsForDetail
    .filter((i) => i.isFilter)
    .map((column) => {
      return _.pick(
        {
          ...column,
          label: column.title,
          name: column.filterName ?? column.dataIndex,
          type: column.type,
        },
        [
          'label',
          'name',
          'type',
          'rules',
          'renderItem',
          'selectProps',
          'inputProps',
          'datePickerProps',
          'dateFormat',
        ],
      ) as FilterItem;
    });

  return (
    <>
      <RecordListLayout<ShipmentNoteListItemDetailResponse>
        columns={dataColumns}
        filterList={filterList}
        placeholder={'请输入物料编号、发货单编号'}
        rowKey={(record) => record?.id}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        configcacheKey="salesOrderDetail"
        requestFn={fetchShipmentNoteListItemDetail}
      />
    </>
  );
};

export default InvoiceDetailList;
