import React from 'react';
import { Popover } from 'antd';
import { TextToolTip, BlTable } from '@blacklake-web/component';

import { GetElementType } from 'src/types/apiTypes';
import { replaceSign } from 'src/utils/constants';
import { goToInboundOrder } from '../navigates';
import { SalesReturnOrderAddItemType } from '../interface';

type InboundOrderListType = NonNullable<SalesReturnOrderAddItemType>['inboundOrderList'];
type InboundOrderItemType = GetElementType<InboundOrderListType>;

const TABLE_MAX_HEIGHT = 300;

const columns = [
  {
    title: '入库单编号',
    dataIndex: 'code',
    width: 150,
    render: (code: string, inboundOrderInfo: InboundOrderItemType, index: number, config: any) => (
      <a onClick={() => goToInboundOrder(inboundOrderInfo.id!)}>
        <TextToolTip text={inboundOrderInfo.code} width={config.contentWidth} />
      </a>
    ),
  },
  {
    title: '入库单明细行行号',
    width: 180,
    dataIndex: 'lineNo',
  },
];

export default function renderInboundOrder(inboundList: InboundOrderListType) {
  if (!inboundList?.length) return replaceSign;
  return (
    <Popover
      title="关联入库单信息"
      placement="topRight"
      content={
        <div style={{ width: 320 }}>
          <BlTable
            dataSource={inboundList}
            columns={columns}
            pagination={false}
            scroll={{ y: TABLE_MAX_HEIGHT }}
            rowKey={'id'}
            size="small"
          />
        </div>
      }
    >
      <a>{inboundList?.length}个入库单</a>
    </Popover>
  );
}
