/**
 * @enum 自定义字段枚举值
 */

// 字段类型
export enum FieldType {
  /** 单行文本 */
  text = 1,
  /** 数值 */
  number,
  /** 多行文本 */
  textArea,
  /** 单选 */
  select,
  /** */
  /** 多选 */
  multiSelect,
  /** 布尔值 */
  boolean,
  /** 整数 */
  integer,
  /** 日期时间 */
  date,
  /** 链接 */
  url,
  /** 引用字段 */
  reference,
  /** 关联字段 */
  relation,
  /** relationSub, */
  relationSub,
  /** 主从关系 */
  subordinate = 13,
  /** 附件 */
  appendix,
}

// 字段属性
export enum FieldCategory {
  customFields = 1,
  preFields,
}

// 对象属性
export enum ObjectCategory {
  customObject = 1,
  preObject,
}

export enum TargetType {
  singleChoice,
  multiChoice,
}

export enum RelationDeleteOption {
  allowed,
  notAllowed,
}

export enum ViewRuleEnum {
  bySelf,
  byParent,
}

export enum SubDeleteOption {
  delateAll,
  notAllowed,
  singleChoice = 0,
  multiChoice,
}
