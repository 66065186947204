import React from 'react';
import { Card } from 'antd';
import NotificationCard from './notificationCard';
import styles from './styles.module.scss';
import { DebouncedFunc } from 'lodash';
import { FormInstance } from 'antd/es/form/Form';

interface Props {
  data: any[];
  type: string;
  messageForm?: FormInstance;
  updateMessageStatus: (nodeData: any) => void;
  getAggregationListFn?: DebouncedFunc<(params: any) => Promise<void>>;
}

export default function NotificationMessageContent({
  data,
  type,
  getAggregationListFn,
  updateMessageStatus,
  messageForm,
}: Props) {
  return (
    <div className={styles?.cardBox}>
      {data?.map((node: any) => {
        return (
          <Card
            bordered={false}
            key={node?.inboxId || node?.bizTypeId || node?.moduleId}
            className={styles?.notificationCardBody}
          >
            <NotificationCard
              messageForm={messageForm}
              type={type}
              initData={node}
              getAggregationListFn={getAggregationListFn}
              updateMessageStatus={updateMessageStatus}
            />
          </Card>
        );
      })}
    </div>
  );
}
