import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Empty, Spin, Space, Descriptions } from 'antd';
//
import { valueOrHolder } from 'src/utils/formatters';
import { gcTime } from 'src/utils';
import {
  fetchProduceTaskListPauseRecord,
  FetchProduceTaskListPauseRecordResponse,
} from 'src/api/ytt/mfg-domain/productionTask';

interface PauseRecordProps {
  taskId?: number;
}

enum SuspendStatus {
  /** 暂停中 */
  SUSPENDED = 1,
  /** 暂停结束 */
  SUSPENDED_END,
}

const SuspendStatusMap = new Map([
  [SuspendStatus.SUSPENDED, '暂停中'],
  [SuspendStatus.SUSPENDED_END, '暂停结束'],
]);

type SuspendItem = NonNullable<
  NonNullable<FetchProduceTaskListPauseRecordResponse['data']>['list']
>[0];

const PauseRecord = (props: PauseRecordProps) => {
  const { taskId } = props;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<FetchProduceTaskListPauseRecordResponse['data']>({} as any);

  useEffect(() => {
    if (taskId) {
      setLoading(true);
      fetchProduceTaskListPauseRecord({ taskId, size: 100 })
        .then((res) => {
          const resData = _.get(res, 'data');

          setData(resData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [taskId]);

  const renderInfo = (
    item: SuspendItem,
    config: {
      label: React.ReactNode;
      dataIndex: string;
      render: (value: any) => React.ReactNode;
    },
  ) => {
    const { label, dataIndex, render } = config;

    const value = _.get(item, dataIndex);

    return (
      <Descriptions.Item label={label} labelStyle={{ width: 150 }}>
        {valueOrHolder(value, render)}
      </Descriptions.Item>
    );
  };

  /**
   * 『暂停中』状态的black
   */
  const renderTimeOutingBlack = (item: SuspendItem) => {
    const descs = [
      {
        label: '暂停状态',
        dataIndex: 'pauseStatus',
        render: (pauseStatus: SuspendStatus) => SuspendStatusMap.get(pauseStatus),
      },
      {
        label: '暂停开始时间',
        dataIndex: 'pauseStartTime',
        render: gcTime.formatLine,
      },
      {
        label: '开始人',
        dataIndex: 'pauseStartUser',
        render: (pauseStartUser: any) => pauseStartUser,
      },
      {
        label: '已暂停时长',
        dataIndex: 'duration',
        render: (duration: number | undefined) => `${duration}分钟`,
      },
      {
        label: '暂停原因',
        dataIndex: 'reason',
        render: (reason: string) => reason,
      },
    ];

    return (
      <Descriptions style={{ marginBottom: 20 }} bordered column={1} size="small">
        {_.map(descs, (config) => renderInfo(item, config))}
      </Descriptions>
    );
  };

  /**
   * 『暂停结束』状态的black
   */
  const renderTimeOutedBlack = (item: SuspendItem) => {
    const descs = [
      {
        label: '暂停状态',
        dataIndex: 'pauseStatus',
        render: (pauseStatus: SuspendStatus) => SuspendStatusMap.get(pauseStatus),
      },
      {
        label: '暂停开始时间',
        dataIndex: 'pauseStartTime',
        render: gcTime.formatLine,
      },
      {
        label: '开始人',
        dataIndex: 'pauseStartUser',
        render: (pauseStartUser: any) => pauseStartUser,
      },
      {
        label: '暂停结束时间',
        dataIndex: 'pauseEndTime',
        render: gcTime.formatLine,
      },
      {
        label: '结束人',
        dataIndex: 'pauseEndUser',
        render: (pauseEndUser: any) => pauseEndUser,
      },
      {
        label: '总暂停时长',
        dataIndex: 'duration',
        render: (duration: number | undefined) => `${duration}分钟`,
      },
      {
        label: '暂停原因',
        dataIndex: 'reason',
        render: (reason: string) => reason,
      },
    ];

    return (
      <Descriptions style={{ marginBottom: 20 }} bordered column={1} size="small">
        {_.map(descs, (config) => renderInfo(item, config))}
      </Descriptions>
    );
  };

  return (
    <Spin spinning={loading}>
      {_.isEmpty(data?.list) ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          <Space size={50} style={{ fontSize: 15 }}>
            <span>暂停次数：{data?.pauseTimes ?? 0}次</span>
            <span>暂停时长： {data?.totalPauseDuration ?? 0}分钟</span>
          </Space>
          <div style={{ maxHeight: 500, overflowY: 'auto', marginTop: 16 }}>
            {_.map(data?.list, (item) => {
              if (item.pauseStatus === SuspendStatus.SUSPENDED) {
                return renderTimeOutingBlack(item);
              } else if (item.pauseStatus === SuspendStatus.SUSPENDED_END) {
                return renderTimeOutedBlack(item);
              }

              return null;
            })}
          </div>
        </>
      )}
    </Spin>
  );
};

export default PauseRecord;
