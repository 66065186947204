import { BlSortFormList } from 'src/components';
import { Form, FormInstance, Input } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { checkEmailSupported, integerLengthCheck } from 'src/utils/formValidators';

interface StoreDetailProps {
  selectedRows?: any[];
  form: FormInstance;
}

interface SupplierRegisterPorps {
  code: string;
  name: string;
  supplierOrgId: number;
  email: string;
}

const SupplierRegisterFormTable = (props: StoreDetailProps) => {
  const { selectedRows, form } = props;

  const newSupplierRegister = selectedRows?.map((node: SupplierRegisterPorps) => {
    return {
      code: node.code,
      name: node.name,
      supplierOrgId: node.supplierOrgId,
      email: node.supplierOrgId,
    };
  });

  form.setFieldsValue({ supplierRegister: newSupplierRegister });

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    const baseColumns: ColumnProps<any & FormListFieldData>[] = [
      {
        title: '供应商编号',
        dataIndex: 'supplierCode',

        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field.name, 'code']}
              fieldKey={[field.key, 'code']}
            >
              {form.getFieldValue('supplierRegister')[field.name].code}
            </Form.Item>
          );
        },
      },
      {
        title: '供应商名称',
        dataIndex: 'supplierName',
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field?.name, 'name']}
              fieldKey={[field.key, 'name']}
            >
              {form.getFieldValue('supplierRegister')[field.name].name}
            </Form.Item>
          );
        },
      },
      {
        title: '工厂号',
        dataIndex: 'supplierOrgId',
        width: 220,
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field?.name, 'supplierOrgId']}
              fieldKey={[field.key, 'supplierOrgId']}
              validateFirst
              rules={[
                { required: true, message: '工厂号不能为空' },
                { validator: integerLengthCheck(8, 8) },
              ]}
            >
              <Input
                allowClear
                placeholder={'请输入8位数字的工厂号'}
                onChange={(e) => {
                  form.setFieldsValue({
                    supplierOrgId: e.currentTarget.value,
                  });
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '供应商联系人邮箱',
        dataIndex: 'email',
        width: 200,
        key: 'email',
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field?.name, 'email']}
              fieldKey={[field.key, 'email']}
              validateFirst
              rules={[{ required: true, message: '邮箱不能为空' }, checkEmailSupported]}
            >
              <Input
                allowClear
                placeholder={'请输入'}
                onChange={(e) => {
                  form.setFieldsValue({
                    email: e.currentTarget.value,
                  });
                }}
              />
            </Form.Item>
          );
        },
      },
    ];

    return baseColumns;
  };

  return (
    <BlSortFormList
      form={form}
      name={'supplierRegister'}
      renderColumns={() => getColumns()}
      isNeedDrag={false}
      isNeedAdd={false}
    />
  );
};

export default SupplierRegisterFormTable;
