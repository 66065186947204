import React, { ComponentProps, useEffect, useState } from 'react';
import { Button, DatePicker, Form, Popover, Space, Tag } from 'antd';
import { DataFormLayout, DataFormLayoutForModal } from 'layout';
import BarGroup from './BarGroup';
import MixedLineAndBar from './MixedLineAndBar';
import { useDispatch, useSelector } from 'react-redux';
import { CHART_NAME } from 'src/dict/purchase';
import _ from 'lodash';
import { SearchSelect } from 'src/components';
import moment from 'moment';
import { updateConfig } from '../utils';
import _Time from 'src/utils/dataTypes/time';
import { RootState } from 'src/store';
import { getDetailUrl } from '../navigation';
import { useHistory } from 'react-router-dom';
import '../charts.css';

const { RangePicker } = DatePicker;

type TooltipSelectProps = {
  value?: any;
  onChange?: (value: any) => void;
  tooltipProps: Partial<ComponentProps<typeof Popover>>;
  selectProps: ComponentProps<typeof SearchSelect>;
};

const TooltipSelect: React.FC<TooltipSelectProps> = (props) => {
  const { value, onChange, tooltipProps, selectProps } = props;

  const contentNode = (value: any) => (
    <div style={{ width: 360, maxHeight: 120, overflowX: 'hidden', overflowY: 'auto' }}>
      <Space style={{ width: '100%' }} wrap>
        {value?.map((item: any) => (
          <Tag key={item?.key}>{item?.label}</Tag>
        ))}
      </Space>
    </div>
  );

  return (
    <Popover className={'chars_select'} content={contentNode(value)} {...tooltipProps}>
      <SearchSelect
        {...selectProps}
        value={value}
        onChange={(value) => {
          setTimeout(() => {
            const selectionOverflow = document.getElementsByClassName(
              'ant-select-selection-overflow',
            );

            Array.from(selectionOverflow).forEach((dom) => {
              dom.scrollLeft = 400;
            });
          }, 0);
          onChange?.(value);
        }}
      />
    </Popover>
  );
};

type ChartCardProps = {
  title: string;
  unit: string | string[];
  yAxisTitle: string[];
  dataIndex: string;
  chartType: string;
};

const ChartCard: React.FC<ChartCardProps> = (props) => {
  const { title, unit, yAxisTitle, dataIndex, chartType } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const workbench = useSelector((state: RootState) => state.workbench);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  /**
   * 获取图表数据
   */
  const fetchChartData = () => {
    dispatch({ type: 'workbench/fetchChartsData', payload: dataIndex });
  };

  /**
   * 显示统计图
   * 暂时只两类 1.折柱混合 2.柱图组
   * @param chartType 图类型
   * @param dataIndex 图标识
   * @param fetchFn 数据获取方法
   */
  const renderChart = () => {
    switch (chartType) {
      case 'barGroup':
        return (
          <BarGroup
            chartData={workbench?.[dataIndex]}
            yAxisTitle={yAxisTitle}
            title={title}
            unit={unit}
          />
        );
      case 'lineBar':
        return (
          <MixedLineAndBar
            chartData={workbench?.[dataIndex]}
            yAxisTitle={yAxisTitle}
            title={title}
            unit={unit}
          />
        );
      default:
        return null;
    }
  };

  const materialsValidator = (rule: any, value: any) => {
    if (!value || _.isEmpty(value)) {
      return Promise.reject('请选择物料');
    }
    if (value?.length > 5) {
      return Promise.reject('物料最多支持选择5个');
    }
    return Promise.resolve();
  };

  const suppliersValidator = (rule: any, value: any) => {
    if (!value || _.isEmpty(value)) {
      return Promise.reject('请选择供应商');
    }
    if (value?.length > 50) {
      return Promise.reject('供应商最多支持选择50个');
    }
    return Promise.resolve();
  };

  const getBaseInfo = (key: string) => {
    const baseInfo = {
      title: '',
      items: _.compact([
        {
          label: '供应商',
          name: 'suppliers',
          rules: [{ required: true, validator: suppliersValidator }],
          render: () => (
            <TooltipSelect
              selectProps={{
                maxTagCount: 3,
                placeholder: '最多支持选择50个',
                style: { width: 400 },
                fetchType: 'supplier',
                mode: 'multiple',
              }}
              tooltipProps={{
                placement: 'bottom',
                color: '#fff',
              }}
            />
          ),
        },
        key !== CHART_NAME.onTimeCompletionRate && {
          label: '物料',
          name: 'materials',
          rules: [{ required: true, validator: materialsValidator }],
          render: () => (
            <TooltipSelect
              selectProps={{
                maxTagCount: 3,
                placeholder: '最多支持选择5个',
                style: { width: 400 },
                fetchType: 'material',
                mode: 'multiple',
              }}
              tooltipProps={{
                placement: 'bottom',
                color: '#fff',
              }}
            />
          ),
        },
        {
          label: '订单创建时间',
          name: 'createdAt',
          rules: [{ required: true, message: '请选择订单创建时间' }],
          render: () => <RangePicker style={{ width: 400 }} />,
        },
      ]),
    };

    return baseInfo;
  };

  const onSubmit = () => {
    form.validateFields().then((values: any) => {
      const { suppliers: _suppliers, materials: _materials, createdAt } = values;
      const suppliers = _suppliers?.map((item: any) => item?.key);
      const materials = _materials?.map((item: any) => item?.key);
      const [createdAtFrom, createdAtTo] = createdAt;
      const params = {
        configItem: dataIndex,
        suppliers,
        materials,
        createdAtFrom: Number(_Time.setDayStart(createdAtFrom).format('x')),
        createdAtTo: Number(_Time.setDayEnd(createdAtTo).format('x')),
      };

      updateConfig(params, () => {
        dispatch({ type: 'workbench/fetchConfig' });
        fetchChartData();
        setVisible(false);
      });
    });
  };

  useEffect(() => {
    fetchChartData();
  }, []);

  useEffect(() => {
    const { config } = workbench;
    const curChartConfig = config?.[dataIndex];
    const { suppliers, materials, createdAtFrom, createdAtTo } = curChartConfig || {};

    const formItems = {
      suppliers: suppliers?.map((item: any) => {
        return {
          label: item?.name,
          key: item?.id,
        };
      }),
      materials: materials?.map((item: any) => {
        return {
          label: item?.baseInfo?.name,
          key: item?.baseInfo?.id,
        };
      }),
      createdAt: createdAtFrom && createdAtTo ? [moment(createdAtFrom), moment(createdAtTo)] : null,
    };

    form.setFieldsValue(formItems);
  }, [workbench]);

  return (
    <>
      <div
        style={{
          height: 328,
          width: '100%',
          marginBottom: 24,
          paddingTop: 18,
          border: '1px solid #e6e6e6',
          borderRadius: 4,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: 14,
            paddingRight: 20,
          }}
        >
          <span style={{ color: '#262626', fontSize: 16, fontWeight: 500 }}>{title}</span>
          <Space>
            <Button type={'primary'} ghost size={'small'} onClick={() => setVisible(true)}>
              配置
            </Button>
            <Button
              type={'primary'}
              ghost
              size={'small'}
              onClick={() => {
                const { config } = workbench;
                const curChartConfig = config?.[dataIndex];
                const { suppliers, materials, createdAtFrom, createdAtTo } = curChartConfig || {};

                history.push(
                  getDetailUrl({
                    key: dataIndex,
                    supplier: suppliers?.map((item: any) => {
                      const { code, name, id } = item;

                      return {
                        key: id,
                        value: id,
                        label: `${code}/${name}`,
                      };
                    }),
                    material: materials?.map((item: any) => {
                      const { code, name, id } = item?.baseInfo || {};

                      return {
                        key: id,
                        value: id,
                        label: `${code}/${name}`,
                      };
                    }),
                    createAt: [createdAtFrom, createdAtTo],
                  }),
                );
              }}
            >
              查看明细
            </Button>
          </Space>
        </div>
        <div style={{ width: '100%', height: 284 }}>{renderChart()}</div>
      </div>
      {visible && (
        <DataFormLayoutForModal
          visible={visible}
          width={660}
          content={
            <DataFormLayout
              form={form}
              title={`配置${title}`}
              info={[getBaseInfo(dataIndex)]}
              onCancel={() => setVisible(false)}
              onFinish={onSubmit}
            />
          }
          onClose={() => setVisible(false)}
        />
      )}
    </>
  );
};

export default ChartCard;
