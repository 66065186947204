/*
 * @Author:
 * @Date: 2021-05-12 19:38:08
 * @LastEditors: Vicky Yu
 * @LastEditTime: 2021-05-13 18:49:39
 * @Description:
 * @FilePath: /web-v3/src/custom_fields/src/views/fieldsList/components/fieldFormItems/boolItems.tsx
 */
import { Radio, Form, Button, FormInstance } from 'antd';
import React from 'react';
import { appDict, appEnum } from 'src/dict';

interface Props {
  form: FormInstance;
  isPreField?: boolean;
}
export const boolItems = (props: Props) => {
  const { form, isPreField } = props;

  return [
    {
      label: '必填',
      name: 'isRequired',
      rules: [{ required: true, message: '请选择必填' }],
      initialValue: appEnum.Common.YN.yes,
      render: () => <Radio.Group disabled options={appDict.common.yn} />,
    },
    {
      label: '默认值',
      render: () => (
        // TODO 必须要Form Item直接包裹才是受控组件
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Item
            name="defaultValue"
            initialValue={appEnum.Common.YN.yes}
            style={{ marginBottom: 0 }}
          >
            <Radio.Group disabled={isPreField} options={appDict.common.yn} />
          </Form.Item>
          <Button
            disabled={isPreField}
            size="small"
            onClick={() => {
              form.setFieldsValue({ defaultValue: undefined });
            }}
          >
            清空
          </Button>
        </div>
      ),
    },
  ];
};
