import _ from 'lodash';

/**
 * 表单工具函数——从LabeledValue中抽取value
 * @param  {string[]} props 要抽取value的表单项数组
 * @param  {object} obj 表单值对象
 * @param  {object} obj 表单值对象
 * @returns {object} 抽取value后的新对象
 */
export const extractValue = (props: string[], obj: { [p: string]: any }): any => {
  const extracted = _.fromPairs(props.map((prop) => [prop, _.map(obj[prop], 'value') ?? []]));
  return {
    ...obj,
    ...extracted,
  };
};

/**
 * 表单工具函数——从{id, name}（如用户、部门、角色）中抽取id
 * @param  {string[]} props 要抽取id的表单项数组
 * @param  {object} obj 表单值对象
 * @returns {object} 抽取id后的新对象
 */
export const extractIds = (props: string[], obj: { [p: string]: any }): any => {
  const extracted = _.fromPairs(props.map((prop) => [prop, _.map(obj[prop], 'id') ?? []]));
  return {
    ...obj,
    ...extracted,
  };
};
