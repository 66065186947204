import { FormInstance, Form, Select } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { LabeledValue } from 'antd/lib/select';
import { ColumnProps } from 'antd/es/table';
import _ from 'lodash';

import { BlSortFormList, SearchSelectWithCreate } from 'src/components';
import { ResourceBusinessType } from 'src/dict/resources';
import LocalStorage from 'src/utils/localStorage';
import { FIELDS } from 'src/utils/constants';
import { goToCreateResourceTag } from '../navigates';
import { ResourceTagChildType } from '../index.d';
import authDict, { hasAuth } from 'src/utils/auth';

export default function EquipTagFormList({ form }: { form: FormInstance }) {
  /**
   * 校验设备标签/设备参数,不能重复
   */
  const validateDuplicated =
    (listField: string, field: string, idField: string, message: string) =>
    (rule: any, value: LabeledValue) => {
      if (!value?.key) return Promise.resolve('');
      const valueId = JSON.parse(value.value as string)[idField];
      const formList = form.getFieldValue(listField);

      if (!formList?.length) return Promise.resolve('');
      // 新添加未做任何编辑的行，在formList里的值不是object，是空字符串
      const objList = formList?.map((formItem: any) => {
        return _.isObject(formItem as any) ? JSON.parse(formItem[field]?.value) : undefined;
      });
      const firstIndex = _.findIndex(objList, { [idField]: valueId });
      const lastIndex = _.findLastIndex(objList, { [idField]: valueId });

      if (firstIndex !== lastIndex) {
        return Promise.reject(message);
      }

      return Promise.resolve('');
    };

  const getTagColumns: (
    remove: (index: number | number[]) => void,
  ) => ColumnProps<any & FormListFieldData>[] = () => [
    {
      title: '标签项',
      width: 140,
      render: (text, field) => (
        <Form.Item
          name={[field.name, 'name']}
          style={{ marginBottom: 0 }}
          rules={[
            {
              validator: validateDuplicated('tagsTree', 'name', 'id', '不能选择重复的设备标签'),
            },
            {
              required: true,
              message: '已添加的标签项必填',
            },
          ]}
        >
          <SearchSelectWithCreate
            placeholder="请选择标签项"
            fetchType="resourceTagTree"
            createFieldName="标签"
            params={{ businessType: ResourceBusinessType.equipment }}
            onCreateClick={() => {
              LocalStorage.set(FIELDS.RESOURCE_TAG_IS_CREATE, true);
              goToCreateResourceTag();
            }}
            enableAdd={hasAuth(authDict.resourcelabel_add)}
            onChange={() => {
              form.resetFields([['tagsTree', field.name, 'content']]);
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: '标签值',
      width: 140,
      render: (text, field) => {
        return (
          <Form.Item dependencies={[['tagsTree', field.name, 'name']]} noStyle>
            {() => {
              const tagValuesString = form.getFieldValue(['tagsTree', field.name, 'name']);
              const tagValues = tagValuesString && JSON.parse(tagValuesString.value);

              return (
                <Form.Item
                  name={[field.name, 'content']}
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: '已添加的标签值必填',
                    },
                  ]}
                >
                  <Select
                    placeholder="请选择标签值"
                    showSearch
                    filterOption={(input, option) =>
                      String(option?.label).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={tagValues?.children?.map((tagValue: ResourceTagChildType) => ({
                      label: tagValue.content,
                      value: tagValue.id,
                    }))}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
  ];

  return (
    <BlSortFormList
      name="tagsTree"
      maxCount={10}
      isNeedDrag={false}
      renderColumns={getTagColumns}
      form={form}
    />
  );
}
