import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { ThumbnailImage } from 'src/components';
import { fetchFileListByIds } from 'src/api/ytt/holyfile-domain';

function ImageDetailShow(props: {
  list: any[];
  visible: boolean;
  setVisible: (value: boolean) => void;
}) {
  const { list, visible, setVisible } = props;

  return (
    <Drawer
      title="图片详情"
      placement="right"
      visible={visible}
      width={500}
      onClose={() => setVisible(false)}
    >
      {list.map((image) => (
        <ThumbnailImage documentPath={image.fileUri} documentId={image.id} />
      ))}
    </Drawer>
  );
}

const TableImageShow = (props: { ids: number[] }) => {
  const { ids } = props;
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    fetchFileListByIds({ idList: ids }).then(({ data }) => {
      return setData(data || []);
    });
  }, []);

  return (
    <>
      <a onClick={() => setVisible(true)}>查看详情</a>
      {visible && ImageDetailShow({ list: data, visible, setVisible })}
    </>
  );
};

export { ImageDetailShow, TableImageShow };
