import React, { useState } from 'react';
import type { FC } from 'react';
import { Button } from 'antd';
import { BlTable } from 'src/components';
import type { ColumnProps } from 'antd/lib/table';
import type { FormListFieldData } from 'antd/lib/form/FormList';
import { DataFormLayoutForModal } from 'src/layout';
import { isEmpty } from 'lodash';
import { ProcessParamDetailTable } from './processParamDetailTable';

interface Props {
  dataSource: {}[];
}

export const ResourcesDetailTable: FC<Props> = (props) => {
  const { dataSource } = props;
  const [showParamsModal, setShowParamsModal] = useState<FormListFieldData>();

  const columns: ColumnProps<any & FormListFieldData>[] = [
    {
      title: '序号',
      dataIndex: 'lineNo',
      fixed: 'left',
      width: 120,
      render: (text, _record, index) => index + 1,
    },
    {
      title: '资源编号',
      dataIndex: 'code',
      fixed: 'left',
      width: 120,
    },
    {
      title: '资源名称',
      dataIndex: 'name',
      fixed: 'left',
      width: 120,
    },
    {
      title: '工艺参数',
      dataIndex: 'paramsVOList',
      fixed: 'left',
      width: 120,
      render: (paramsVOList, record) => {
        return (
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              setShowParamsModal(record);
            }}
          >
            查看
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <BlTable
        scroll={{ x: 'max-content', y: 'auto' }}
        dataSource={dataSource?.resourceList}
        columns={columns}
        rowKey="id"
      />
      <DataFormLayoutForModal
        visible={!isEmpty(showParamsModal)}
        onClose={() => {
          setShowParamsModal(undefined);
        }}
        content={<ProcessParamDetailTable dataSource={showParamsModal} />}
      />
    </>
  );
};
