import { useState, useCallback } from 'react';
import { Form } from 'antd';
import { RecordListLayout, FilterItem } from 'layout';
import { TextToolTip } from '@blacklake-web/component';
import _ from 'lodash';

import { fetchReturnAuditNoteList } from 'src/api/ytt/supply-chain-domain/return_audit_note';
import { SingleOperationLogComponent, SearchSelect } from 'src/components';
import { renderUser } from 'src/page/share/renderUser';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { fieldTypeList } from 'src/utils';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { intersectionValueOfId, mapLabeledValueToValue } from 'src/utils/array';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { lookup } from 'src/dict';
import { CRUD } from 'src/dict/common';
import { ApplicationStatus } from 'src/dict/salesReturnOrder';
import { applicationStatus } from 'src/dict/salesReturnOrder/mappings';
import { fieldLabels } from '../constants';
import type { ReturnApplicationListItemType } from '../interface';
import { useOperateReturnApplicationModal } from '../hooks';
import { goToApplicationDetail } from '../navigates';

const formatDataToDisplay = (param: any) => {
  return param;
};
const formatDataToQuery = (param: any) => {
  const { createdAt, updatedAt, customerCode, customerName, creator, operator, ...rest } = param;

  return {
    createdAtFrom: createdAt && createdAt[0] ? Date.parse(createdAt[0]) : undefined,
    createdAtTo: createdAt && createdAt[1] ? Date.parse(createdAt[1]) : undefined,
    updatedAtFrom: updatedAt && updatedAt[0] ? Date.parse(updatedAt[0]) : undefined,
    updatedAtTo: updatedAt && updatedAt[1] ? Date.parse(updatedAt[1]) : undefined,
    customerIds: intersectionValueOfId(customerCode, customerName),
    creatorIds: mapLabeledValueToValue(creator),
    operatorIds: mapLabeledValueToValue(operator),
    ...rest,
  };
};
const formatDataToFormDisplay = (param: any) => {
  return param;
};

export default function ReturnApplicationList() {
  const [refreshMarker, setRefreshMarker] = useState(0);
  const [visibleLog, setVisibleLog] = useState(false);
  // 当前操作行的id，传给操作记录用
  const [currentOrderId, setCurrentOrderId] = useState<number>();
  // 加载中
  const [loading] = useState(false);
  // 拒绝退货申请单的理由form
  const [rejectForm] = Form.useForm();
  const { accept, reject } = useOperateReturnApplicationModal();

  const refresh = useCallback(() => {
    setRefreshMarker(Date.now());
  }, []);
  /**
   * Columns
   */
  const columns = [
    {
      title: fieldLabels.code,
      dataIndex: 'code',
      width: 150,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.text,
      render: (text: string, record: ReturnApplicationListItemType, index: number, config: any) => (
        <a
          onClick={() => {
            goToApplicationDetail(record.id!);
          }}
        >
          <TextToolTip text={text} width={config.contentWidth} />
        </a>
      ),
    },
    {
      title: fieldLabels.customerCode,
      dataIndex: 'customerCode',
      width: 120,
      isFilter: true,
      filterName: 'customerCode',
      renderItem: (
        <SearchSelect labelPath="code" fetchType="customer" labelInValue mode="multiple" />
      ),
    },
    {
      title: fieldLabels.customerName,
      dataIndex: 'customerName',
      width: 120,
      isFilter: true,
      filterName: 'customerName',
      renderItem: <SearchSelect fetchType="customer" labelInValue mode="multiple" />,
    },
    {
      title: fieldLabels.purchaseReturnOrderCode,
      dataIndex: 'customerPurchaseOrderReturnCode',
      width: 180,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: fieldLabels.salesOrderCode,
      dataIndex: 'salesOrderCode',
      width: 160,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: fieldLabels.status,
      dataIndex: 'status',
      width: 100,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: applicationStatus,
        mode: 'multiple',
      },
      render: lookup('salesReturnOrder.applicationStatus'),
    },
    {
      title: fieldLabels.createdAt,
      dataIndex: 'createdAt',
      width: 150,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: fieldLabels.operator,
      dataIndex: 'operator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: renderUser,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: fieldLabels.updatedAt,
      dataIndex: 'updatedAt',
      width: 150,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
  ];
  /**
   * 列操作 ...
   */
  const getOperationList = (record: ReturnApplicationListItemType) => {
    const { status, id } = record;

    return [
      {
        title: lookup('common.crud', CRUD.view),
        auth: authDict.returnauditnote_detail,
        onClick: () => {
          goToApplicationDetail(id!);
        },
      },
      {
        title: '接受',
        auth: authDict.returnauditnote_accept,
        disabled: status === ApplicationStatus.accepted || status === ApplicationStatus.rejected,
        onClick: () => {
          accept(id!, refresh);
        },
      },
      {
        title: '拒绝',
        auth: authDict.returnauditnote_decline,
        disabled: status === ApplicationStatus.accepted || status === ApplicationStatus.rejected,
        onClick: () => {
          reject(id!, record.customerName!, rejectForm, refresh);
        },
      },
      {
        title: '操作记录',
        auth: authDict.returnauditnote_operrecord,
        onClick: () => {
          setCurrentOrderId(record.id);
          setVisibleLog(true);
        },
      },
    ];
  };
  /**
   * 列表筛选处理函数
   */

  const filterList: FilterItem[] = columns
    .filter((i) => i.isFilter)
    .map((column) => {
      return _.pick(
        {
          ...column,
          label: column.title,
          name: column.filterName ?? column.dataIndex,
          type: column.type,
        },
        [
          'label',
          'name',
          'type',
          'rules',
          'renderItem',
          'selectProps',
          'inputProps',
          'datePickerProps',
          'dateFormat',
        ],
      ) as FilterItem;
    });

  return (
    <>
      <RecordListLayout<ReturnApplicationListItemType>
        isLoading={loading}
        refreshMarker={refreshMarker}
        filterList={filterList}
        requestFn={fetchReturnAuditNoteList}
        placeholder="请输入搜索关键字"
        rowKey="id"
        columns={columns}
        getOperationList={getOperationList}
        userAuth={getAuthFromLocalStorage()}
        formatDataToDisplay={formatDataToDisplay}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={Number(currentOrderId)}
          objectCode={'ReturnAuditNote'} // 对象在对象平台唯一定义的objectCode
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
}
