import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Checkbox, List, Input, Breadcrumb, Tag, Modal, Spin, Space, Button, Avatar } from 'antd';
import { searchByDepartmentOrUser } from 'src/services/organization/userManage';
import {
  fetchDepartmentListChild,
  FetchDepartmentListChildResponse,
} from 'src/api/ytt/user-domain';
import { blacklakeGreen, borderGrey, deepGrey, middleGrey } from 'src/styles/color';
import { TextToolTip } from '@blacklake-web/component';
//
import styles from './styles.module.scss';
import { ListItem, userSelectProps } from './index.d';
import { BlIcon } from 'src/components';

enum IListItemType {
  user = 1,
  department,
}

interface IUser {
  avatarUrl?: string;
  email?: string;
  id?: number;
  name?: string;
  orgId?: number;
  phone?: string;
  username?: string;
  code?: string;
}
interface IDepartment {
  code: string;
  desc: string;
  id: number;
  name: string;
  orgId: number;
}

interface IListItem {
  id?: number;
  name?: string;
  avatarUrl?: string;
  type: IListItemType;
  departmentInfo?: {
    allDepartments: IDepartment[]; // type === 'department' 时存在,当前部门下所有的子部门展平
    allUsers: IUser[]; // type === 'department' 时存在,当前部门下的所有人员(包含子部门的)展平
    users: IUser[]; //  type === 'department' 时存在,当前部门下的人员(不包含子部门)展平
    owner: IUser;
  };
  userInfo?: {
    isOwner: boolean; // type === 'user' 时存在,人员是否时当前部门的负责人
  };
  code?: string;
}

const avatarStyle = {
  backgroundColor: blacklakeGreen,
  marginRight: 4,
  marginBottom: 2,
  fontSize: 16,
};

const formatIUserToIListItem = (user: IUser, isOwner = false): IListItem => {
  return {
    id: user.id,
    name: user.name,
    avatarUrl: user.avatarUrl,
    type: IListItemType.user,
    departmentInfo: undefined,
    userInfo: {
      isOwner,
    },
    code: user.code,
  };
};

const formatIDepartmentToIListItem = (department: IDepartment): IListItem => {
  return {
    id: department.id,
    name: department.name,
    avatarUrl: '',
    type: IListItemType.department,
    departmentInfo: {
      allDepartments: [],
      allUsers: [],
      users: [],
      owner: {},
    },
    userInfo: {
      isOwner: false,
    },
    code: department.code,
  };
};

/**
 * 格式化接口数据
 * @param list
 */
const formatApiToList = (
  apiRes: FetchDepartmentListChildResponse,
  currentDepartment?: IListItem,
): IListItem[] => {
  const list: IListItem[] = _.filter(
    _.map(apiRes?.data ?? [], (item) => {
      return {
        id: item?.id ?? 0,
        name: item?.name ?? '',
        avatarUrl: '',
        type: IListItemType.department,
        departmentInfo: {
          allDepartments: (item?.childDepartments as IDepartment[]) ?? [],
          allUsers: item?.allUsers ?? [],
          users: item?.users ?? [],
          owner: item?.owner ?? {},
        },
        userInfo: {
          isOwner: false,
        },
        code: item?.code ?? '',
      };
    }),
    (item) => item.id !== 0,
  );

  if (!_.isNil(currentDepartment?.departmentInfo)) {
    const { departmentInfo } = currentDepartment ?? {};
    const { owner, users } = departmentInfo ?? {};

    // 插入当前部门的负责人
    if (!_.isEmpty(owner) && owner) {
      list.push(formatIUserToIListItem(owner, true));
    }

    // 插入当前部门不属于任何其子部门的人员
    if (!_.isEmpty(users)) {
      _.forEach(users, (item) => {
        if (owner?.id !== item.id) {
          list.push(formatIUserToIListItem(item));
        }
      });
    }
  }

  return list;
};
/**
 *
 * @param props
 * visible 弹窗是否可见，如果使用trigger可不用加
 * onClose 关闭弹窗的回调方法
 * width 弹窗宽度
 * closable 是否可以点击关闭弹窗
 * isMultiple 是否为多选
 * isSelectUser 是否为选择用户，不传则认为是选择部门
 * submitData 选择后的提交选择的回调函数，单选和多选都会返回已选择内容的数组
 * chooesdData 已选择内容
 * userActives 返回的用户状态筛选（如[0,1]返回 启用&禁用 1:启用中 0: 停用）
 * @returns
 */
const UserSelectModal = (props: userSelectProps) => {
  const [loading, setLoading] = useState(false);
  const [selectList, setSelectList] = useState<IListItem[]>([]);
  const [selectedList, setSelectedList] = useState<Map<number, IListItem>>(new Map());
  const [selectedBreadcrumb, setSelectedBreadcrumb] = useState<IListItem[]>([]); // 面包屑选中的部门列表，最后一个就是当前选中的部门

  // [多选][部门]时，需要记录所勾选部门下的所有子部门，用于判断子部门状态
  const selectedDepartmentChildrensRef = useRef<Map<number, IListItem>>(new Map());

  const {
    visible,
    onClose,
    width,
    closable,
    isMultiple,
    isSelectUser,
    submitData: onSubmit,
    choosedData = [],
    defaultChecked,
    userActives,
  } = props;

  useEffect(() => {
    getSelectList(undefined, undefined, initSelectedList(choosedData)).then((listData) => {
      // 部门选择时，可以初始化选中 第一层'全公司'
      if (!_.head(choosedData)?.id && defaultChecked && !isSelectUser) {
        const headDepartment = _.head(listData);

        headDepartment?.id && setSelectedList(new Map([[headDepartment.id, headDepartment]]));
      }
    });
  }, []);

  useEffect(() => {
    const currentDepartment = _.last(selectedBreadcrumb);

    if (currentDepartment) {
      getSelectList(currentDepartment?.id, currentDepartment, selectedList);
    }
  }, [selectedBreadcrumb]);

  /**
   * 拉取部门用户接口
   * @param id 用户或部门id。不传查全公司
   * @param currentDepartment 当前的部门
   * @param curSelectedList 当前选择的列表，需要更新选择列表信息时传
   * @returns
   */
  const getSelectList = async (
    id?: number,
    currentDepartment?: IListItem,
    curSelectedList?: Map<number, IListItem>,
  ) => {
    try {
      let listData: IListItem[] = [];

      setLoading(true);

      const res: FetchDepartmentListChildResponse = await fetchDepartmentListChild({
        id,
        userActives,
      });

      listData = formatApiToList(res, currentDepartment);

      // 更新选择列表信息
      curSelectedList && updateSelectedInfo(listData, curSelectedList);

      setSelectList(listData);
      return listData;
    } catch (error) {
      setSelectList([]);
      return [];
    } finally {
      setLoading(false);
    }
  };

  /**
   * [多选][部门] 记录用户选择当前部门下的所有子部门到 selectedDepartmentChildrensRef
   */
  const setSelectedDepartmentChildrens = (checked: boolean, item: IListItem) => {
    if (!item?.id) return;

    const allDepartments: IDepartment[] = _.get(item, 'departmentInfo.allDepartments', []);

    /** 存在子部门时须记录 */
    if (!_.isEmpty(allDepartments)) {
      _.forEach(allDepartments, (t) => {
        if (checked) {
          selectedDepartmentChildrensRef.current.set(t.id, formatIDepartmentToIListItem(t));
        } else {
          t?.id && selectedDepartmentChildrensRef.current.delete(t.id);
        }
      });
    }
  };

  const initSelectedList = (choosedData: ListItem[]) => {
    const initSelectedList = new Map();

    if (_.isEmpty(choosedData)) return initSelectedList;

    _.forEach(choosedData, (item) => {
      initSelectedList.set(item.id, {
        id: item.id,
        name: item.name,
        avatarUrl: item.avatarUrl,
        type: item?.type,
        code: item?.code,
      });
    });

    return initSelectedList;
  };

  /**
   * 更新已选列表的信息，因为从form中初始化的值缺少一些信息
   */
  const updateSelectedInfo = (items: IListItem[], selectedList: Map<number, IListItem>) => {
    if (_.isEmpty(selectedList)) return;

    const newSelectedList = _.cloneDeep(selectedList);

    _.forEach(items, (item) => {
      if (item.id && newSelectedList.get(item.id)) {
        newSelectedList.set(item.id, item);

        // [多选][部门]时 需要更新记录所选部门的子部门
        if (isMultiple && !isSelectUser) {
          setSelectedDepartmentChildrens(true, item);
        }
      }
    });

    setSelectedList(newSelectedList);
  };

  /**
   * 处理搜索
   */
  const handleSearch = async (value: string) => {
    try {
      if (value) {
        const { data } = await searchByDepartmentOrUser(value);
        const { departments, users } = data;

        const newSelectList = isSelectUser
          ? _.map<IUser, IListItem>(users, (t) => formatIUserToIListItem(t))
          : _.map<IDepartment, IListItem>(departments, (t) => formatIDepartmentToIListItem(t));

        setSelectList(newSelectList);
      } else {
        await getSelectList();
      }

      // 搜索成功 便置空面包屑
      setSelectedBreadcrumb([]);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * 面包屑增加节点
   */
  const addBreadcrumbNode = (node: IListItem) => {
    setSelectedBreadcrumb(selectedBreadcrumb.concat(node));
  };

  /**
   * 处理面包屑点击
   * @param index
   */
  const handleBreadcrumbClick = (index: number) => {
    const newSelect = selectedBreadcrumb.slice(0, index + 1);

    setSelectedBreadcrumb(newSelect);
  };

  /**
   * [单选，多选][用户，部门] 清空选择列表
   */
  const handleResetSelectedList = () => {
    setSelectedList(new Map());

    selectedDepartmentChildrensRef.current = new Map();
  };

  /**
   * [单选][用户，部门] 选择或取消
   */
  const handleSigSelectUserOrDepartment = (checked: boolean, item?: IListItem) => {
    const newSelectedList = new Map();

    if (!item?.id) return;

    if (checked) {
      newSelectedList.set(item?.id, {
        id: item?.id,
        name: item.name,
        avatarUrl: item.avatarUrl,
        code: item.code,
      });
    }

    setSelectedList(newSelectedList);
  };

  /**
   * [多选][用户] 选择或取消
   */
  const handleMulSelectUser = (checked: boolean, item?: IListItem[]) => {
    const newSelectedList = _.cloneDeep(selectedList);

    if (checked) {
      _.forEach(item, ({ id, ...resItem }) => {
        id && newSelectedList.set(id, { id, ...resItem });
      });
    } else {
      _.forEach(item, ({ id }) => {
        id && newSelectedList.delete(id);
      });
    }
    setSelectedList(newSelectedList);
  };

  /**
   * [多选][部门] 选择或取消
   */
  const handleMulSelectDepartment = (checked: boolean, item?: IListItem) => {
    if (!item?.id) return;

    const newSelectedList = _.cloneDeep(selectedList);
    const allDepartments: IDepartment[] = _.get(item, 'departmentInfo.allDepartments', []);

    if (checked) {
      newSelectedList.set(item.id, item);
    } else {
      newSelectedList.delete(item.id);
    }

    // 把当前选择的子部门和已经选的列表进行过滤，1.原来已选择子部门，现在选择其父部门，需要把原来已选择的子部门去除选择状态
    _.forEach(allDepartments, (t) => {
      if (checked && newSelectedList.get(t.id)) {
        newSelectedList.delete(t.id);
      }
    });

    // 记录当前部门的子部门
    setSelectedDepartmentChildrens(checked, item);

    setSelectedList(newSelectedList);
  };

  const renderName = (item: IListItem) => {
    return (item?.name?.length ?? 0) > 20 ? (
      <TextToolTip text={item.name} width={140} />
    ) : (
      item.name
    );
  };

  /**
   * 渲染用户基础信息
   */
  const renderUserInfo = (item: IListItem) => {
    if (!item?.id) return null;

    const { avatarUrl, userInfo } = item ?? {};

    return (
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        {avatarUrl ? (
          <span>
            <Avatar src={avatarUrl} size={24} style={avatarStyle} />
          </span>
        ) : (
          <Avatar icon={<BlIcon type="icongeren" />} size={24} style={avatarStyle} />
        )}
        <span style={{ margin: '0 5px' }}>
          {renderName(item)}
          {userInfo?.isOwner && (
            <Tag color="blue" style={{ margin: '0 3px' }}>
              {'负责人'}
            </Tag>
          )}
        </span>
      </div>
    );
  };

  /**
   * 渲染部门基础信息
   */
  const renderDepartmentInfo = (item: IListItem, amount?: number) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar icon={<BlIcon type="iconzuzhijiagou" />} size={24} style={avatarStyle} />
        <span style={{ margin: '0 5px' }}>
          {renderName(item)}
          {_.isNumber(amount) && <span style={{ color: middleGrey }}>{`(${amount})`}</span>}
        </span>
      </div>
    );
  };

  /**
   * 渲染可选列表的用户item
   */
  const renderSelectUser = (item: IListItem) => {
    const isSelected = !_.isNil(item?.id && selectedList.get(item.id));

    return isMultiple ? (
      <List.Item key={item.id} style={{ cursor: 'pointer' }}>
        <Checkbox
          checked={isSelected}
          onChange={(e) => {
            handleMulSelectUser(e.target.checked, [item]);
          }}
        >
          {renderUserInfo(item)}
        </Checkbox>
      </List.Item>
    ) : (
      <List.Item
        key={item.id}
        style={{ cursor: 'pointer' }}
        className={isSelected ? styles.selectedListItem : ''}
        onClick={() => {
          handleSigSelectUserOrDepartment(!isSelected, item);
        }}
      >
        {renderUserInfo(item)}
      </List.Item>
    );
  };

  /**
   * 选部门的情况下，渲染可选列表部门item
   */
  const renderSelectDepartment = (item: IListItem) => {
    const allDepartments: IUser[] = _.get(item, 'departmentInfo.allDepartments', []);
    const displatAmount = allDepartments.length;
    const isEmpty = displatAmount === 0;
    const selectedChildrenList = selectedDepartmentChildrensRef.current;

    // isSelected 用于判断节点是否由用户主动勾选
    const isSelected = !!(item?.id && selectedList.get(item.id));
    // checkedAll 用于判断节点是否被动全部勾选，1.子节点全选2.父节点选择
    let checkedAll = false;
    // indeterminate 用于判断节点是否被动一部分勾选，1.子节点部分勾选
    let indeterminate = false;

    // 非用户主动勾选时，需要进一步判断是否被动勾选
    if (!isSelected && item?.id) {
      // 是否为父节点
      const isFather = _.find(allDepartments, (t) => t.id && selectedList.get(t.id));

      if (isFather) {
        indeterminate = true;
      } else if (!_.isEmpty(allDepartments)) {
        // 子节点判断是否全选
        checkedAll = _.every(
          allDepartments,
          ({ id }) => !_.isNil(id && selectedChildrenList.get(id)),
        );
      } else {
        checkedAll = !!(item?.id && selectedChildrenList.get(item.id));
      }
    }

    return isMultiple ? (
      <List.Item
        key={item.id}
        style={{ cursor: 'pointer' }}
        extra={!isEmpty && <a onClick={() => addBreadcrumbNode(item)}>下级</a>}
      >
        <Checkbox
          disabled={checkedAll} // 子节点被动全选不能编辑
          checked={isSelected || checkedAll}
          indeterminate={indeterminate}
          onChange={(e) => {
            handleMulSelectDepartment(e.target.checked, item);
          }}
        >
          {renderDepartmentInfo(item, displatAmount !== 0 ? displatAmount : undefined)}
        </Checkbox>
      </List.Item>
    ) : (
      <List.Item
        key={item.id}
        style={{ cursor: 'pointer' }}
        className={isSelected ? styles.selectedListItem : ''}
        extra={
          !isEmpty && (
            <a
              style={{ flexShrink: 0, padding: '2px 0px 2px 15px' }} // 扩大点击区域
              onClick={() => addBreadcrumbNode(item)}
            >
              下级
            </a>
          )
        }
      >
        <div
          style={{ width: '100%' }}
          onClick={() => {
            handleSigSelectUserOrDepartment(!isSelected, item);
          }}
        >
          {renderDepartmentInfo(item, undefined)}
        </div>
      </List.Item>
    );
  };

  /**
   * 选用户的情况下，渲染可选列表部门item
   */
  const renderSelectDepartmentForUser = (item: IListItem) => {
    const allUsers: IUser[] = _.get(item, 'departmentInfo.allUsers', []);
    const displatAmount = allUsers.length;
    const isEmpty = displatAmount === 0;

    const checkedAll = _.every(allUsers, ({ id }) => !_.isNil(id && selectedList.get(id)));
    let indeterminate = false;

    if (!checkedAll) {
      indeterminate = !!_.find(allUsers, ({ id }) => !_.isNil(id && selectedList.get(id)));
    }

    return isMultiple ? (
      <List.Item
        key={item.id}
        style={{ cursor: 'pointer' }}
        extra={!isEmpty && <a onClick={() => addBreadcrumbNode(item)}>下级</a>}
      >
        <Checkbox
          disabled={isEmpty}
          indeterminate={indeterminate}
          checked={isEmpty ? false : checkedAll}
          onChange={(e) => {
            handleMulSelectUser(
              e.target.checked,
              allUsers.map((t) => formatIUserToIListItem(t)),
            );
          }}
        >
          {renderDepartmentInfo(item, displatAmount)}
        </Checkbox>
      </List.Item>
    ) : (
      <List.Item
        key={item.id}
        style={{ cursor: isEmpty ? 'not-allowed' : 'pointer', opacity: isEmpty ? 0.6 : 1 }}
        onClick={() => !isEmpty && addBreadcrumbNode(item)}
        extra={!isEmpty && <a>下级</a>}
      >
        {renderDepartmentInfo(item, displatAmount)}
      </List.Item>
    );
  };

  /**
   * 渲染单个可选用户或部门
   */
  const renderSelectItem = (item: IListItem) => {
    if (isSelectUser) {
      // 选用户时不过滤
      return item.type === IListItemType.user
        ? renderSelectUser(item)
        : renderSelectDepartmentForUser(item);
    } else if (item.type === IListItemType.department) {
      // 选部门时，把用户过滤掉
      return renderSelectDepartment(item);
    }

    return null;
  };

  /**
   * 渲染单个选中用户或部门
   */
  const renderSelectedItem = (item: IListItem) => {
    return (
      <List.Item key={item.id} style={{ cursor: 'pointer' }}>
        {isSelectUser ? renderUserInfo(item) : renderDepartmentInfo(item)}
        <a
          onClick={() => {
            isSelectUser
              ? handleMulSelectUser(false, [item])
              : handleMulSelectDepartment(false, item);
          }}
          style={{ color: deepGrey }}
        >
          <BlIcon type="iconguanbi" />
        </a>
      </List.Item>
    );
  };

  /**
   * 部门用户可选列表
   */
  const renderList = () => {
    return (
      <div className={styles.list}>
        <div className={styles.listItem}>
          <Input.Search
            placeholder={isSelectUser ? '搜索用户' : '搜索部门'}
            onSearch={handleSearch}
            allowClear
          />
        </div>
        <div className={styles.listItem} style={{ padding: '10px 25px 5px' }}>
          <Breadcrumb separator={'>'}>
            <Breadcrumb.Item key={1}>
              {<a onClick={() => handleSearch('')}>{isSelectUser ? '用户' : '部门'}</a>}
            </Breadcrumb.Item>
            {selectedBreadcrumb.map((menu, index: number) => (
              <Breadcrumb.Item key={menu.id}>
                <a onClick={() => handleBreadcrumbClick(index)}>
                  <TextToolTip text={menu.name} width={100} />
                </a>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>
        <div className={styles.listItem} style={{ height: '100%', overflow: 'auto' }}>
          <Spin spinning={loading}>
            <List dataSource={selectList} split={false} renderItem={renderSelectItem} />
          </Spin>
        </div>
      </div>
    );
  };

  /**
   * 部门用户已选列表
   */
  const renderSelectedList = () => {
    const selectedListAry = Array.from(selectedList.values());

    return (
      isMultiple && (
        <div className={styles.list} style={{ borderLeft: `1px solid ${borderGrey}` }}>
          <div
            className={styles.listItem}
            style={{ padding: '5px 25px', display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              已选：{selectedListAry.length} {isSelectUser ? '人' : '个'}
            </div>
            <a onClick={handleResetSelectedList}>清空</a>
          </div>
          <List
            className={styles.listItem}
            style={{ overflow: 'auto' }}
            dataSource={selectedListAry}
            renderItem={renderSelectedItem}
            split={false}
          />
        </div>
      )
    );
  };

  const renderFooter = () => {
    const selectedListAry = Array.from(selectedList.values());

    return (
      <div className={styles.footer}>
        <Space size={10}>
          <Button onClick={onClose}>取消</Button>
          <Button
            type="primary"
            onClick={() => {
              onSubmit(selectedListAry);
              onClose?.();
            }}
          >
            确定
          </Button>
        </Space>
      </div>
    );
  };

  return (
    <Modal
      centered
      visible={visible}
      width={width ?? '45%'}
      bodyStyle={{ padding: 0 }}
      destroyOnClose
      title={isSelectUser ? '选择用户' : '选择部门'}
      keyboard={false}
      maskClosable={false}
      closable={closable}
      footer={renderFooter()}
      onCancel={onClose}
    >
      <div className={styles.selectBody}>
        {renderList()}
        {renderSelectedList()}
      </div>
    </Modal>
  );
};

export default UserSelectModal;
