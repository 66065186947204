export const CAPTCHA_EXPIRED_FLAG = 'CAPTCHA_EXPIRED_FLAG';

export enum SHOW_SMS_CODE_TYPE {
  LOGIN = 'LOGIN',
  RESET_PASSWORD = 'RESET_PASSWORD',
  FIRST_RESET_PASSWORD = 'FIRST_RESET_PASSWORD',
  UNAVAILABLE = 'UNAVAILABLE', // 手机号未绑定，不可使用手机号
}

// 管理员登陆密码错误超过四次时，后台的subCode码
// 仅管理员账号会返回此错误码
export const ACCOUNT_NEED_RETRIEVE = 'USER-DOMAIN/ACCOUNT_NEED_RETRIEVE';
