import _ from 'lodash';

export const hashPassword = (password: string) => {
  return window.argon2.hash({
    pass: password,
    salt: 'tk7zprcUTEJiV3Ti4YFnjzNptsIpSOka',
    hashLen: 128,
    parallelism: 2,
    time: 5,
    mem: 16 * 1024,
    type: window.argon2.ArgonType.Argon2id,
  });
};

/** 处理表单提交数据, 对所有string类型的参数去掉前后空格 */
export function recursiveTrim(param: any): any {
  // 如果是数组，对每一项递归调用
  if (_.isArray(param)) {
    return param.map(recursiveTrim);
  }
  // 如果是对象, 对每个属性递归调用
  if (_.isPlainObject(param)) {
    return _.mapValues(param, recursiveTrim);
  }
  if (_.isString(param)) {
    return _.trim(param);
  }
  return param;
}
