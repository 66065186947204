import { Button, Modal, message as Message } from 'antd';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchExportExcelExport, FetchExportExcelExportResponse } from 'src/api/ytt/import';
import { BUSINESS_TYPE, EXCEL_TYPE } from 'src/dict/objImport';
import { ImportConfigType } from 'src/dict/types';
import { RootState } from 'src/store';
import { qs } from 'utils';

export type BatchExportExcelProps = {
  /** Modal标题 */
  title?: string;
  /** 是否显示 */
  visible?: boolean;
  /** 关闭弹窗 */
  closeModal: () => void;
  /** 导入请求参数 */
  exportParams: ImportConfigType;
};

interface ExportRequestBodyType {
  applicationName: string;
  businessType: string;
  excelType: number;
  businessData: any;
}

/**
 * 批量导入/导入
 * @param title 弹窗标题 （非必填）
 * @param visible 是否显示 （非必填）
 * @param closeModal 关闭弹窗
 * @param exportParams 导出请求参数 （必填）
 */
const BatchExportExcel: React.FC<BatchExportExcelProps> = ({
  title,
  visible,
  closeModal,
  exportParams,
}) => {
  const businessData = useSelector((state: RootState) => state.excelImport.businessData);
  const { applicationName, businessType } = exportParams;
  const history = useHistory();

  /** 导出文件 */
  const exportFile = async () => {
    const params: ExportRequestBodyType = {
      applicationName,
      businessType,
      businessData,
      excelType: EXCEL_TYPE.common,
    };

    if (businessType === BUSINESS_TYPE.workerCalendar) {
      params.excelType = EXCEL_TYPE.special;
    }
    const response: FetchExportExcelExportResponse = await fetchExportExcelExport(params);
    const { code, message } = response;

    if (code === 200) {
      Message.success(`操作成功，可通过日志查看导出情况`);
      closeModal();
    } else {
      Message.error(`导出失败：${message}`);
    }
  };

  /** 查看日志 */
  const linkToLogPage = () => {
    closeModal();
    const path = `${history.location.pathname}/exportLog?${qs.stringify({
      applicationName,
      businessType,
    })}`;
    history.push(path);
  };
  const renderFooter = (): ReactNode => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button type={'link'} onClick={linkToLogPage}>
          导出日志
        </Button>
        <Button type={'primary'} onClick={exportFile}>
          开始导出
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal title={title} visible={visible} centered onCancel={closeModal} footer={null}>
        {renderFooter()}
      </Modal>
    </>
  );
};

BatchExportExcel.defaultProps = {
  title: '批量导出',
  visible: true,
};

export default BatchExportExcel;
