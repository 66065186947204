import { useState } from 'react';
import { Form, Input, FormInstance, Space, Button, Select } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/es/table';
import _ from 'lodash';
import { TextToolTip } from '@blacklake-web/component';

import {
  BlSortFormList,
  BatchOperateTableHeader as TableHeader,
  setFormDeleteList,
  SearchSelectModal,
} from 'src/components';
import MaterialSelectModal from 'src/components/searchSelect/material/Modal';
import { MaterialEntity, materialEntity } from 'src/entity';
import { lookup } from 'src/dict';
import { CRUD, UsageStatus } from 'src/dict/common';
import { BizType, UnitType } from 'src/dict/material';
import {
  withoutSpaceBothSides,
  positiveInt,
  numberMinMaxCheck,
  fractionLengthCheck,
  numberAlphabetSpecialSymbols,
} from 'src/utils/formValidators';
import authDict, { hasAuth } from 'src/utils/auth';
import BatchSearchSelect from 'src/page/warehouseManagement/batchManagement/components/batchSearchSelect';

import { fieldLabels, GeneralTableRowDataType } from '../../constants';
import { goToMaterialDetail } from '../../utils';

interface CommonMaterialTableProps {
  form: FormInstance;
  mode?: 'integrate' | 'common';
  isEdit?: boolean;
}

/** 表单字段名 */
const tableDataFormFieldName = 'materials';
/** 默认单位精度 */
const DEFAULT_PRECISION = 10;
/** 物料行最多行数 */
const MAX_MATERIALS_COUNT = 100;

/**
 * 更新表单数据中，领料申请编码列的rowSpan
 * @param 更新前的表单数据
 * @return 更新后的表单数据
 * */
const updateRowSpan: (prevList: GeneralTableRowDataType[]) => GeneralTableRowDataType[] = (
  prevList,
) => {
  if (prevList.length !== 0) {
    let updateIndex = 0;
    let lastCode = prevList[0].code;
    let rowSpan = 0;

    // 计算rowSpan，当本行领料单号不变，rowSpan+1，否则重置变量，设置rowSpan
    prevList.forEach((line, index) => {
      if (line.code === lastCode) {
        rowSpan += 1;
        line.rowSpan = 0;
      } else {
        prevList[updateIndex].rowSpan = rowSpan;
        updateIndex = index;
        lastCode = line.code;
        rowSpan = 1;
      }
    });

    // 循环结束后设置最后一项rowSpan
    prevList[updateIndex].rowSpan = rowSpan;
  }

  return prevList;
};

export default function GeneralMaterialTable({
  form,
  mode = 'common',
  isEdit = false,
}: CommonMaterialTableProps) {
  const [showMaterialModel, setShowMaterialModel] = useState(false);
  const { getFieldValue } = form;

  /**
   * 复制 领料单物料行
   * @param fields
   */
  const handleCopyMaterialLine = (field: FormListFieldData) => {
    const copyItem: GeneralTableRowDataType = _.cloneDeep(
      form.getFieldValue([tableDataFormFieldName, field.name]),
    );
    const formList: GeneralTableRowDataType[] = _.cloneDeep(
      form.getFieldValue(tableDataFormFieldName),
    );

    copyItem.rowSpan = 0;
    copyItem.pickOrderDetailId = undefined;
    formList.splice(field.name + 1, 0, copyItem);
    form.setFieldsValue({ [tableDataFormFieldName]: updateRowSpan(formList) });
  };

  /** delete方法是BlSortFormList暴露的，在那之后要重新计算更新所有行的rowSpan */
  const updateAfterDelete = () => {
    const formList: GeneralTableRowDataType[] = _.cloneDeep(
      form.getFieldValue(tableDataFormFieldName),
    );

    form.setFieldsValue({ [tableDataFormFieldName]: updateRowSpan(formList) });
  };

  /**
   * 校验行号,不能重复
   */
  const validatorDuplicatedIndex = (rule: any, value: number) => {
    const { field } = rule;
    const name = field.split('.')[1];
    const { code } = form.getFieldValue([tableDataFormFieldName, name]);

    const formList = form
      .getFieldValue(tableDataFormFieldName)
      .filter((materialLine: GeneralTableRowDataType) => materialLine.code === code);

    const firstIndex = _.findIndex(formList, { line: value });
    const lastIndex = _.findLastIndex(formList, { line: value });

    if (firstIndex !== lastIndex) {
      return Promise.reject('不能有重复的行号');
    }

    return Promise.resolve('');
  };

  /**
   * 批量更新表格表单数据
   * @param batchInput 批量设置的值
   * @param path 批量设置路径
   *  */
  const handleBatchSet = (batchInput: string | number, path: Array<string> | string) => {
    const formList: GeneralTableRowDataType[] = _.cloneDeep(
      form.getFieldValue(tableDataFormFieldName),
    );

    formList.forEach((line) => {
      _.set(line, path, batchInput);
    });

    form.setFieldsValue({ [tableDataFormFieldName]: formList });
  };

  const handleFinishAddMaterialLines = (rows: MaterialEntity.MaterialEntity[]) => {
    console.log('rows', rows);
    const originRows = form.getFieldValue(tableDataFormFieldName) || [];
    const currentMaxLineNo =
      originRows.length > 0
        ? (_.max(originRows.map((originRow: GeneralTableRowDataType) => originRow.line)) as number)
        : 0;
    const newRows = rows?.map((row, idx) => ({
      material: row,
      line: currentMaxLineNo + idx + 1,
      requestPickAmount: {
        unitId: row.unit?.id,
        unitName: row.unit?.name,
        unitCode: row.unit?.code,
      },
      unit: {
        label: row.unit?.name,
        value: row.unit?.id,
      },
      sourceWarehouseId: row.inventoryManageAttrDTO?.warehouseId
        ? {
            label: row.inventoryManageAttrDTO?.warehouseName,
            value: {
              id: row.inventoryManageAttrDTO?.warehouseId,
              name: row.inventoryManageAttrDTO?.warehouseName,
              code: row.inventoryManageAttrDTO?.warehouseCode,
            },
          }
        : undefined,
    }));

    if (rows?.length) {
      form.setFieldsValue({ [tableDataFormFieldName]: [...originRows, ...newRows] });
    }
    setShowMaterialModel(false);
  };

  const getColumns: (
    remove: (index: number | number[]) => void,
  ) => ColumnProps<any & FormListFieldData>[] = (remove: (index: number | number[]) => void) => {
    return _.compact([
      mode === 'integrate' && {
        title: <TableHeader titleText={fieldLabels.storeRequisitionCode} required />,
        dataIndex: 'code',
        width: 170,
        fixed: true,
        // onCell: ({ name }) => {
        //   return {
        //     rowSpan: getFieldValue([tableDataFormFieldName, name, 'rowSpan']),
        //   };
        // },
        render: (text, field) => ({
          children: (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'code']}
              name={[field.name, 'code']}
              rules={[
                {
                  required: true,
                  message: `${fieldLabels.storeRequisitionCode}必填`,
                },
                numberAlphabetSpecialSymbols,
                withoutSpaceBothSides,
                { max: 255, message: '不可超过255个字符' },
              ]}
            >
              <Input placeholder="请输入" />
            </Form.Item>
          ),
          props: {
            rowSpan: getFieldValue([tableDataFormFieldName, field.name, 'rowSpan']) as number,
          },
        }),
      },
      {
        title: <TableHeader titleText={fieldLabels.lineNumber} required />,
        dataIndex: 'line',
        width: 100,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'line']}
              name={[field.name, 'line']}
              getValueFromEvent={(event) => {
                const curValue = event.target.value;

                if (isNaN(curValue) || _.endsWith(curValue, '.')) return curValue;
                if (curValue === '') return undefined;
                return Number(curValue);
              }}
              validateTrigger="onSubmit"
              rules={[
                {
                  required: true,
                  message: `${fieldLabels.lineNumber}必填`,
                },
                positiveInt,
                {
                  validator: validatorDuplicatedIndex,
                },
              ]}
            >
              <Input
                placeholder="请输入"
                onChange={() => {
                  const allRows = form.getFieldValue(tableDataFormFieldName);

                  form.validateFields(
                    allRows.map((_row: GeneralTableRowDataType, idx: number) => [
                      tableDataFormFieldName,
                      idx,
                      'line',
                    ]),
                  );
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: fieldLabels.materialCode,
        dataIndex: ['material', 'baseInfo', 'code'],
        width: 130,
        render: (text, field) => {
          return hasAuth(authDict.material_detail) ? (
            <a
              onClick={() =>
                goToMaterialDetail(
                  getFieldValue([tableDataFormFieldName, field.name, 'material', 'baseInfo', 'id']),
                )
              }
            >
              <TextToolTip
                text={getFieldValue([
                  tableDataFormFieldName,
                  field.name,
                  'material',
                  'baseInfo',
                  'code',
                ])}
                width={130}
              />
            </a>
          ) : (
            getFieldValue([tableDataFormFieldName, field.name, 'material', 'baseInfo', 'code'])
          );
        },
      },
      {
        title: fieldLabels.materialName,
        dataIndex: ['material', 'baseInfo', 'name'],
        width: 160,
        render: (text, field) =>
          getFieldValue([tableDataFormFieldName, field.name, 'material', 'baseInfo', 'name']),
      },
      {
        title: fieldLabels.materialCategory,
        dataIndex: ['material', 'category', 'name'],
        width: 110,
        render: (text, field) =>
          getFieldValue([tableDataFormFieldName, field.name, 'material', 'category', 'name']),
      },
      {
        title: fieldLabels.materialProperty,
        dataIndex: ['material', 'attribute'],
        width: 150,
        render: (text, field) => {
          const attributes = materialEntity.getMaterialAttrs(
            getFieldValue([tableDataFormFieldName, field.name, 'material']),
          );

          return attributes.map((attr) => attr.label).join(', ');
        },
      },
      {
        title: fieldLabels.specification,
        dataIndex: ['material', 'baseInfo', 'specification'],
        width: 160,
        render: (text, field) =>
          getFieldValue([
            tableDataFormFieldName,
            field.name,
            'material',
            'baseInfo',
            'specification',
          ]),
      },
      {
        title: (
          <TableHeader
            titleText={fieldLabels.requireCount}
            required
            batchOperation={(batchInput) => {
              handleBatchSet(batchInput, ['requestPickAmount', 'amount']);
            }}
            rules={[
              {
                validator: numberMinMaxCheck({
                  min: 0,
                  max: 10000000,
                  minAllowEqual: true,
                  maxAllowEqual: false,
                  fieldName: fieldLabels.requireCount,
                }),
              },
              {
                // 批量设置的精度只能按默认精度来算
                validator: fractionLengthCheck(DEFAULT_PRECISION),
              },
            ]}
          />
        ),
        dataIndex: ['requestPickAmount', 'amount'],
        width: 120,
        render: (text, field) => {
          const requestPickAmount = getFieldValue([
            tableDataFormFieldName,
            field.name,
            'requestPickAmount',
          ]);
          const material = getFieldValue([tableDataFormFieldName, field.name, 'material']);
          const { precisionFigure } = materialEntity.getMaterialUnitInfo(
            material,
            requestPickAmount?.unitId,
          );

          return (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'requestPickAmount', 'amount']}
              name={[field.name, 'requestPickAmount', 'amount']}
              rules={[
                { required: true, message: `${fieldLabels.requireCount}必填` },
                {
                  // 申请数必须小于10000000，大于0
                  validator: numberMinMaxCheck({
                    min: 0,
                    max: 10000000,
                    minAllowEqual: isEdit,
                    maxAllowEqual: false,
                    fieldName: fieldLabels.requireCount,
                  }),
                },
                {
                  // 精度校验从单位中获取
                  validator: fractionLengthCheck(precisionFigure ?? DEFAULT_PRECISION),
                },
              ]}
            >
              <Input placeholder="请输入" />
            </Form.Item>
          );
        },
      },
      {
        title: fieldLabels.unit,
        width: 120,
        render: (text, field) => {
          const unitList: MaterialEntity.MaterialEntity['unitList'] = getFieldValue([
            tableDataFormFieldName,
            field.name,
            'material',
            'unitList',
          ]);
          const options = unitList
            ?.filter((unit) => unit.unitType !== UnitType.auxUnit)
            .map((unit) => ({ value: unit.id, label: unit.name }));

          return (
            <Form.Item noStyle name={[field.name, 'unit']}>
              <Select options={options} labelInValue />
            </Form.Item>
          );
        },
      },
      {
        title: (
          <TableHeader
            titleText={fieldLabels.dispatchWarehouse}
            batchOperation={(batchInput) => {
              handleBatchSet(batchInput, ['sourceWarehouseId']);
            }}
            popoverFieldComponent={
              <SearchSelectModal
                fetchType="warehouse"
                params={{ enableFlag: UsageStatus.enabled }}
                placeholder={`请选择${fieldLabels.dispatchWarehouse}`}
              />
            }
          />
        ),
        dataIndex: ['sourceWarehouseId'],
        width: 300,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'sourceWarehouseId']}
              name={[field.name, 'sourceWarehouseId']}
            >
              <SearchSelectModal
                fetchType="warehouse"
                params={{ enableFlag: UsageStatus.enabled }}
                placeholder={`请选择${fieldLabels.dispatchWarehouse}`}
              />
            </Form.Item>
          );
        },
      },
      {
        title: (
          <TableHeader
            titleText={fieldLabels.acceptWarehouse}
            required
            batchOperation={(batchInput) => {
              handleBatchSet(batchInput, ['targetWarehouseId']);
            }}
            popoverFieldComponent={
              <SearchSelectModal
                fetchType="warehouse"
                params={{ enableFlag: UsageStatus.enabled }}
                placeholder={`请选择${fieldLabels.acceptWarehouse}`}
              />
            }
          />
        ),
        dataIndex: ['targetWarehouseId'],
        width: 300,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'targetWarehouseId']}
              name={[field.name, 'targetWarehouseId']}
              rules={[{ required: true, message: `${fieldLabels.acceptWarehouse}必填` }]}
            >
              <SearchSelectModal
                fetchType="warehouse"
                params={{ enableFlag: UsageStatus.enabled }}
                placeholder={`请选择${fieldLabels.acceptWarehouse}`}
              />
            </Form.Item>
          );
        },
      },
      {
        title: fieldLabels.batchNumber,
        dataIndex: ['batchNo'],
        width: 130,
        render: (text, field) => {
          const materialinfo = getFieldValue([tableDataFormFieldName, field.name, 'material']);

          return (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'batchNo']}
              name={[field.name, 'batchNo']}
              required
            >
              <BatchSearchSelect
                style={{ width: '100' }}
                materialinfo={materialinfo}
                labelInValue
                rendervalue="all"
                saveCallback={(val) => {
                  form.setFields([
                    {
                      name: [tableDataFormFieldName, field.name, 'batchNo'],
                      value: val,
                    },
                  ]);
                }}
                params={{
                  materialIds: [
                    getFieldValue([
                      tableDataFormFieldName,
                      field.name,
                      'material',
                      'baseInfo',
                      'id',
                    ]),
                  ],
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: fieldLabels.lineRemark,
        dataIndex: ['remark'],
        width: 180,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'remark']}
              name={[field.name, 'remark']}
              rules={[{ max: 1000, message: '不可超过1000个字符' }, withoutSpaceBothSides]}
            >
              <Input placeholder="请输入" maxLength={1000} />
            </Form.Item>
          );
        },
      },
      {
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width: 100,
        render: (text, field) => {
          return (
            <Form.Item style={{ margin: 0 }}>
              <Space>
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={() => {
                    remove(field?.name);
                    mode === 'integrate' && updateAfterDelete();
                  }}
                >
                  {lookup('crud', CRUD.delete)}
                </Button>
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={() => {
                    handleCopyMaterialLine(field);
                  }}
                >
                  {lookup('crud', CRUD.copy)}
                </Button>
              </Space>
            </Form.Item>
          );
        },
      },
    ]);
  };

  return (
    <>
      <BlSortFormList
        name={tableDataFormFieldName}
        renderColumns={getColumns}
        maxCount={MAX_MATERIALS_COUNT}
        listRules={[
          {
            validator: async (_param, names) => {
              if (names?.length > MAX_MATERIALS_COUNT) {
                return Promise.reject(new Error(`最多只能选择${MAX_MATERIALS_COUNT}个物料`));
              }
              return Promise.resolve();
            },
          },
        ]}
        onDelete={(index) => {
          setFormDeleteList({
            form,
            setValueName: ['deleteList', 'deleteLineIds'],
            getValueName: [tableDataFormFieldName, index[0]],
            checkAndFormat: (item) => item?.pickOrderDetailId,
          });
        }}
        handleAdd={() => {
          setShowMaterialModel(true);
        }}
        isNeedDrag={false}
        isNeedDelete={false}
        isNeedAdd={mode === 'common'}
      />
      {showMaterialModel && (
        <MaterialSelectModal
          visible={showMaterialModel}
          title="选择物料"
          selectedValue={[]}
          params={{
            enableFlag: UsageStatus.enabled,
            bizType: [BizType.default, BizType.workInProgress],
          }}
          mode="multiple"
          onCancel={() => setShowMaterialModel(false)}
          onOk={handleFinishAddMaterialLines}
        />
      )}
    </>
  );
}
