import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { BlIcon } from '@blacklake-web/component';
import { Form, Badge, message } from 'antd';
import _ from 'lodash';

import { RecordListLayout } from 'src/layout';
import { fieldTypeList, gcArray, gcTime } from 'src/utils';
import { avatarDisplay } from 'src/components/avatar/show';
import {
  fetchResourcesList,
  FetchResourcesListRequest,
  FetchResourcesListResponse,
  // fetchResourcesDelete,
  fetchResourcesStatusStop,
  fetchResourcesStatusScrap,
  fetchResourcesStatusEnable,
} from 'src/api/ytt/resource-domain/resource';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import TagTooltip from 'src/components/tooltip/tagTooltip';
import { SingleOperationLogComponent, Tooltip, SearchSelect, TagList } from 'src/components';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { lookup } from 'src/dict';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { UsageStatus } from 'src/dict/common';
import { EquipmentLevel, ResourceBusinessType } from 'src/dict/resources';
import { equipmentBusinessType } from 'src/dict/resources/mappings';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { RootState } from 'src/store';
import { renderUser } from 'src/page/share/renderUser';
import renderQrCode from 'src/page/share/renderQrCode';
import { mapLabeledValueToValue } from 'src/utils/array';
import ResourceClassificationCascader from '../components/ResourceClassificationCascader';
import BLDelConfirm from '../../knowledgeManagement/share/modalConfirm';
import type { ResourceListItemType } from './index.d';
import { goToCreate, goToEdit, goToCopy, goToDetail } from './navigates';
import { RESORCE_STATUS_TYPE, RESORCE_STATUS_VALUE, MAX_CHILDREN_COUNT } from './constants';
import UseAgeFilter, { useAgeRender } from './components/UseAgeFilter';
import ResourceTagCascader from './components/ResourceTagCascader';

interface UserColumnProps {
  id: number;
  name: string;
  avatarUrl: string;
}

/**
 * 用后台获取到的新的某一层节点的数据更新掉原先数据里该层的数据
 * 注意：因为后台一次只返回一层，如果原先数据被展开有children数据，则children保持原样
 * @param equipments 原某层数据列表
 * @param childrenList 新返回的某层数据列表
 * @returns 更新后的数据列表
 */
const updateChildren = (
  equipments: ResourceListItemType[],
  childrenList: ResourceListItemType[],
) => {
  return childrenList?.map((childFromApi) => {
    const viewChild = equipments?.find((viewChild) => viewChild.id === childFromApi.id);

    if (viewChild) {
      return {
        ...childFromApi,
        children: viewChild.children ?? childFromApi.children,
      };
    }
    return childFromApi;
  });
};

/**
 * 局部更新树数据
 * @param equipments 原来的森林数据
 * @param childrenList api返回的本次请求的某层数据列表
 * @param parentId 本次api请求的parentId，即本次哪个节点下的所有children，如为最外层，则为空
 * @returns 更新完的森林数据
 */
const getUpdateDataSourceWithChildren = (
  equipments: ResourceListItemType[],
  newlyReturnedList: ResourceListItemType[],
  parentId?: number,
  isUpdatingStatus?: boolean,
): ResourceListItemType[] => {
  // 如果parentId为空，顶层更新，顶层置换成新的数据
  if (_.isNil(parentId)) {
    return isUpdatingStatus ? updateChildren(equipments, newlyReturnedList) : newlyReturnedList;
  }
  // 否则，根据parentId递归找到需要更新的节点，替换其children的数据（但要保留children的children结构）
  return equipments.map((equip) => {
    // 如果当前设备的id=传入的parentId，当前设备就是要更新的设备
    if (equip.id === parentId) {
      const { children } = equip;

      return {
        ...equip,
        children:
          children && isUpdatingStatus
            ? updateChildren(children, newlyReturnedList)
            : newlyReturnedList,
      };
    }
    if (equip.children) {
      return {
        ...equip,
        children: getUpdateDataSourceWithChildren(
          equip.children as ResourceListItemType[],
          newlyReturnedList,
          parentId,
        ),
      };
    }
    return equip;
  });
};

// parentFlag为true，只返回第一级设备，有子设备用hasChildren表示，需要将其转换为children:[]
// parentFlag为false则返回完成树结构,children有自己的值
// 非第一级且不用展开的情况parentFlag为undefined
const buildChildren = (
  backendData: FetchResourcesListResponse['data'],
  parentFlag: boolean | undefined,
): ResourceListItemType[] => {
  // parentFlag为false时，后台会本身就返回完整的树
  const isFiltering = parentFlag === false;

  return isFiltering
    ? (backendData?.list as ResourceListItemType[])
    : backendData?.list.map((equip) => {
        const { hasChildren, children, ...rest } = equip;

        return {
          ...rest,
          children: (children as ResourceListItemType[]) || (hasChildren ? [] : undefined),
        };
      }) || [];
};

/**
 * 批量更新状态
 * @param originData 原始数据
 * @param idList 需要更新的id列表
 * @param newStatus 新的状态
 * @returns
 */
const updateDataSourceStatus = (
  originData: ResourceListItemType[],
  idList: number[],
  newStatus: number,
): ResourceListItemType[] => {
  return originData?.map((equip) => ({
    ...equip,
    status: idList.indexOf(equip.id!) > -1 ? newStatus : equip.status,
    children: equip.children?.length
      ? updateDataSourceStatus(equip.children, idList, newStatus)
      : equip.children,
  }));
};

interface ResourceDefinitionListProps {
  refreshListMarker: number;
}

const ResourceDefinitionList = ({ refreshListMarker }: ResourceDefinitionListProps) => {
  // 按节点展开加载数据，需要受控的dataSource
  const [dataSource, setDataSource] = useState<ResourceListItemType[]>();
  // 当前操作节点的父节点id
  const [curParentId, setCurParentId] = useState<number | undefined>();
  const [detailId, setDetailId] = useState<number>(0);
  const [refreshMarker, setRefreshMarker] = useState<number>(refreshListMarker);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [customFields] = useState<any[]>([]);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [curParentFlag, setCurParentFlag] = useState<boolean>();
  // 是否在更新设备状态（启通用/报废），更新设备状态时，数据更新后要保持原来的展开，其他刷新则不用
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  const paramRef = useRef<any>();
  const resourses = useSelector((state: RootState) => state?.resources, shallowEqual);
  const { areaValue, subLocationFlag, selectNoLocationFlag } = resourses;

  const currArea = areaValue ? areaValue[areaValue.length - 1] : undefined;

  const [delForm] = Form.useForm();
  const refreshTable = () => {
    // 不改动查询参数的刷新，只刷新表格数据
    // 改动refreshMarker的刷新会导致requestFn调用，response会覆盖掉customDataSource
    loadResources(formatDataToQuery(paramRef.current));
  };

  useEffect(() => {
    setRefreshMarker(refreshListMarker);
  }, [refreshListMarker]);

  // 必须放在useEffect里，如果setCurParentId之后手动写refreshTable可能会用修改之前的旧值发请求
  useEffect(() => {
    // 展开节点或者子行列操作需要setCurParentId来做局部刷新数据，请求结束以后会清空curParentId
    // 这里必须加空值判断，否则每次刷新都会刷新两次
    if (!_.isNil(curParentId)) {
      refreshTable();
    }
  }, [curParentId]);

  // const typeFormatter = ({ data }: any) => ({
  //   total: data?.total || 0,
  //   options:
  //     data?.map((item: any) => ({
  //       label: item.message,
  //       value: item.code,
  //       key: item.code,
  //     })) ?? [],
  // });

  // const handleDelete = async (ids: any[], onSuccess?: () => void) => {
  //   // 删除
  //   try {
  //     const res = await fetchResourcesDelete({ idList: ids });

  //     if (res.code !== 200) {
  //       message.error(res?.message);
  //       return;
  //     }
  //     typeof onSuccess === 'function' && onSuccess?.();
  //     message.success('删除成功');
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const onExpand = (expanded: boolean, record: ResourceListItemType) => {
    // 筛选的时候，已经有完整的树数据，展开不需要再请求
    // 若之前已经请求过子级设备，已有children数据，不再重复请求
    if (expanded && curParentFlag !== false && record.children?.length === 0) {
      setCurParentId(record.id);
    }
  };

  const getRowsToExpand = (equipRows: ResourceListItemType[], parentFlag: boolean | undefined) => {
    const expandedKeys: number[] = [];

    // 只有筛选/搜索到时候需要自动展开，parentFlag=true时不用自动展开任何数据
    if (parentFlag) return expandedRowKeys;
    const expandRow = (rows: ResourceListItemType[]) => {
      rows.forEach((equip) => {
        if (equip.children?.length) {
          expandedKeys.push(equip.id!);
          expandRow(equip.children);
        }
      });
    };

    expandRow(equipRows);
    return expandedKeys;
  };

  const mainMenu = [
    // 本期设备接口不能支持导出，暂时隐藏
    // {
    //   title: '导出',
    //   showExport: true,
    //   icon: <BlIcon type="icondaochu" />,
    //   auth: authDict.equipment_export,
    //   onClick: () => {
    //     openModal({
    //       optType: 'export',
    //       businessType: BUSINESS_TYPE.resource,
    //     });
    //   },
    // },
    {
      title: '新建设备',
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.equipment_add,
      onClick: () => {
        goToCreate();
      },
      items: [
        {
          title: '导入',
          showExport: true,
          auth: authDict.equipment_import,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.resource,
            });
          },
        },
      ],
    },
  ];

  const loadResources = async (param: FetchResourcesListRequest) => {
    const response = await fetchResourcesList(param);
    const { parentId, parentFlag } = param;

    if (response && response.code === 200) {
      const dataWithChildrenInit = buildChildren(response.data, parentFlag);

      const updatedDataSource = getUpdateDataSourceWithChildren(
        dataSource!,
        dataWithChildrenInit,
        parentId,
        isUpdatingStatus,
      );

      // 如果是搜索的结果，清空原来的所有数据，直接替换成后台返回的森林数据
      if (parentFlag === false) {
        // 自动展开所有节点
        setExpandedRowKeys(getRowsToExpand(dataWithChildrenInit, parentFlag));
        setDataSource(dataWithChildrenInit);
      } else {
        // 如果不是在更新设备状态时，且不是在搜索筛选状态下，且不在展开子节点
        // 其他原因引发的刷新（搜索空字符串、翻页），清空展开
        if (!isUpdatingStatus && _.isNil(parentId)) {
          setExpandedRowKeys([]);
        }
        setDataSource(updatedDataSource);
      }
      // 如果是更新状态（启停用/报废）引起的刷新，此时更新后的刷新完成，重置是否在更新标记
      if (isUpdatingStatus) {
        setIsUpdatingStatus(false);
      }
    }

    // 加载完毕以后清空curParentId（关闭局部加载模式）
    // 只有在局部更新的时候需要局部加载模式（子设备的改变状态操作或者展开节点）
    if (!_.isNil(curParentId)) setCurParentId(undefined);

    // 树结构hack了ListLayout的dataSource，会导致total出错，返回这个真实的total覆盖它
    return { data: { total: response.data?.total, list: [] } };
  };

  const onChangeStatus = (
    idList: number[],
    status: number,
    onSuccess?: () => void,
    onFail?: () => void,
  ) => {
    const statusOperation = status === RESORCE_STATUS_VALUE.DISABLED ? '启用' : '停用';
    const apiFunction =
      status === RESORCE_STATUS_VALUE.ENABLED
        ? fetchResourcesStatusStop
        : fetchResourcesStatusEnable;
    const newStatus =
      status === RESORCE_STATUS_VALUE.ENABLED
        ? RESORCE_STATUS_VALUE.DISABLED
        : RESORCE_STATUS_VALUE.ENABLED;

    apiFunction({
      idList,
      status: newStatus,
    })
      .then((res) => {
        if (res.code === 200) {
          message.success(`${statusOperation}成功`);
          const newDataSource = updateDataSourceStatus(dataSource!, idList, newStatus);

          setDataSource(newDataSource);
          onSuccess?.();
        } else {
          onFail?.();
        }
      })
      .catch(() => {
        onFail?.();
      });
  };

  const onScrap = (idList: number[], onSuccess?: () => void, onFail?: () => void) => {
    BLDelConfirm(
      '是否确认报废该设备？',
      delForm,
      () => {
        return fetchResourcesStatusScrap({
          idList,
          status: RESORCE_STATUS_VALUE.SCRAP,
        }).then((res) => {
          if (res.code === 200) {
            // 状态变更后刷新树，并且保持原来的展开情况
            const newDataSource = updateDataSourceStatus(
              dataSource!,
              idList,
              RESORCE_STATUS_VALUE.SCRAP,
            );

            setDataSource(newDataSource);
            onSuccess?.();
          }
        });
      },
      onFail,
    );
  };

  const batchMenu = [
    {
      title: '启用',
      auth: authDict.equipment_enable_disable,
      onClick: (onSuccess: () => void, onFail: () => void) =>
        onChangeStatus(selectedRowKeys, RESORCE_STATUS_VALUE.DISABLED, onSuccess, onFail),
    },
    {
      title: '停用',
      auth: authDict.equipment_enable_disable,
      onClick: (onSuccess: () => void, onFail: () => void) =>
        onChangeStatus(selectedRowKeys, RESORCE_STATUS_VALUE.ENABLED, onSuccess, onFail),
    },
    {
      title: '报废',
      auth: authDict.equipment_scrap,
      onClick: (onSuccess: () => void, onFail: () => void) => {
        onScrap(selectedRowKeys, onSuccess, onFail);
      },
    },
    // {
    //   title: '删除',
    //   icon: <BlIcon type="iconshanchulajitong1" />,
    //   onClick: (onSuccess: any, onFail: any) => {
    //     BLDelConfirm(
    //       '是否确认删除选中的设备？',
    //       delForm,
    //       () => {
    //         handleDelete(selectedRowKeys);
    //         onSuccess();
    //       },
    //       onFail,
    //     );
    //   },
    // },
  ];

  const getOperationList = ({
    id,
    status,
    level,
  }: {
    id: number;
    status: number;
    parentId?: number;
    name: string;
    level: EquipmentLevel;
  }) => {
    const statusOperation = status === RESORCE_STATUS_VALUE.DISABLED ? '启用' : '停用';

    return _.compact([
      {
        title: '查看',
        auth: authDict.equipment_view,
        onClick: () => {
          goToDetail(id);
        },
      },
      {
        title: '编辑',
        auth: authDict.equipment_edit,
        onClick: () => {
          goToEdit(id);
        },
      },
      level !== EquipmentLevel.five && {
        title: '新建子设备',
        auth: authDict.equipment_add,
        onClick: () => {
          goToCreate(id);
        },
      },
      {
        title: '复制',
        auth: authDict.equipment_add,
        onClick: () => {
          goToCopy(id);
        },
      },
      status !== RESORCE_STATUS_VALUE.SCRAP && {
        title: statusOperation,
        disabled: status === RESORCE_STATUS_VALUE.SCRAP,
        auth: authDict.equipment_enable_disable,
        onClick: () => {
          onChangeStatus([id], status);
        },
      },
      status !== RESORCE_STATUS_VALUE.SCRAP && {
        title: '报废',
        disabled: status === RESORCE_STATUS_VALUE.SCRAP,
        auth: authDict.equipment_scrap,
        onClick: () => {
          onScrap([id]);
        },
      },
      // {
      //   title: '删除',
      //   onClick: () => {
      //     BLDelConfirm('是否确认删除该设备？', delForm, () => {
      //       return handleDelete([id], refreshTable);
      //     });
      //   },
      // },
      {
        title: '操作记录',
        auth: authDict.equipment_operrecord,
        onClick: () => {
          setDetailId(id);
          setVisibleLog(true);
        },
      },
    ]);
  };

  const dataColumns = useCallback(() => {
    return [
      {
        title: '设备编号',
        dataIndex: 'code',
        type: fieldTypeList.text,
        isFilter: true,
        sorter: true,
        fixed: 'left',
        width: 300,
        render: (value: string, record: any, index: number, config: any) => (
          <a onClick={() => goToDetail(record.id)}>
            <Tooltip text={value ?? '-'} width={config.contentWidth} />
          </a>
        ),
      },
      {
        title: '设备名称',
        dataIndex: 'name',
        sorter: true,
        type: fieldTypeList.text,
        isFilter: true,
        width: 150,
        render: (value: string, record: any, index: number, config: any) => (
          <a onClick={() => goToDetail(record.id)}>
            <Tooltip text={value ?? '-'} width={config.contentWidth} />
          </a>
        ),
      },
      {
        title: '业务类型',
        dataIndex: 'businessType',
        type: fieldTypeList.select,
        isFilter: true,
        width: 150,
        selectProps: { options: equipmentBusinessType },
        render: lookup('resources.equipmentBusinessType'),
      },
      {
        title: '设备分类',
        dataIndex: ['classificationVo', 'className'],
        isFilter: true,
        width: 150,
        render: (text: number, record: ResourceListItemType) => {
          return record?.classificationList?.map((item) => item.name).join('/');
        },
        renderItem: (
          <ResourceClassificationCascader
            showSearch
            placeholder="请选择设备分类"
            enableAddLabel="新建分类"
            params={{ bussinessType: ResourceBusinessType.equipment, cascade: true }}
            onCreateClick={() => {}}
            enableAdd={false}
          />
        ),
        filterName: 'classification',
      },
      {
        title: '设备标签',
        dataIndex: 'tagsList',
        isFilter: true,
        filterName: 'tagsIdList',
        width: 150,
        render: (tags: ResourceListItemType['tagsList']) =>
          tags && (
            <TagList
              dataSource={tags.map((tag) => ({
                value: tag.id,
                label: `${tag.name}:${tag.content}`,
              }))}
            />
          ),
        renderItem: <ResourceTagCascader nameLabel="设备标签" />,
      },
      {
        title: '设备层级',
        dataIndex: 'level',
        width: 150,
        render: (level: ResourceListItemType['level']) =>
          level && lookup('resources.equipmentLevel', level),
      },
      {
        title: '标识码',
        dataIndex: 'entityLinkCode',
        type: fieldTypeList.text,
        isFilter: true,
        width: 150,
        render: renderQrCode,
      },
      {
        title: '供应商',
        dataIndex: 'supplierResDTO',
        type: fieldTypeList.text,
        isFilter: true,
        width: 150,
        render: renderUser,
        renderItem: (
          <SearchSelect fetchType="supplier" placeholder="请输入" labelInValue mode="multiple" />
        ),
        filterName: 'supplier',
      },
      {
        title: '序列号',
        dataIndex: 'serialNo',
        type: fieldTypeList.text,
        width: 150,
        isFilter: true,
        sorter: false,
      },
      {
        title: '负责人',
        dataIndex: 'responsibleUser',
        isFilter: true,
        width: 150,
        renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
        render: (users: any[]) => <TagTooltip list={users || []} max={2} />,
      },
      {
        title: '负责部门',
        dataIndex: 'responsibleDepartment',
        type: fieldTypeList.text,
        isFilter: true,
        width: 150,
        renderItem: (
          <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser={false} />
        ),
        render: (departments: any[]) =>
          departments?.map((department) => avatarDisplay({ ...department })),
      },
      {
        title: '出厂日期',
        dataIndex: 'outFactoryDate',
        type: fieldTypeList.date,
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        isFilter: true,
        sorter: true,
        width: 180,
      },
      {
        title: '入厂日期',
        dataIndex: 'enterFactoryDate',
        type: fieldTypeList.date,
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        isFilter: true,
        sorter: true,
        width: 180,
      },
      {
        title: '启用日期',
        dataIndex: 'enableDate',
        type: fieldTypeList.date,
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        isFilter: true,
        sorter: true,
        width: 180,
      },
      {
        title: '启用状态',
        dataIndex: 'status',
        type: fieldTypeList.select,
        isFilter: true,
        sorter: true,
        width: 150,
        selectProps: {
          options: [
            {
              label: '启用中',
              value: RESORCE_STATUS_VALUE.ENABLED,
            },
            {
              label: '停用中',
              value: RESORCE_STATUS_VALUE.DISABLED,
            },
            {
              label: '已报废',
              value: RESORCE_STATUS_VALUE.SCRAP,
            },
          ],
        },
        render: (status: number) => {
          return (
            <span>
              {status === RESORCE_STATUS_VALUE.ENABLED && <Badge status="success" />}
              {status === RESORCE_STATUS_VALUE.DISABLED && <Badge status="error" />}
              {status === RESORCE_STATUS_VALUE.SCRAP && <Badge status="default" />}
              {RESORCE_STATUS_TYPE.get(status)}
            </span>
          );
        },
      },
      {
        title: '存储单位',
        dataIndex: 'unitName',
        type: fieldTypeList.select,
        isFilter: true,
        width: 150,
        renderItem: (
          <SearchSelect
            fetchType="unit"
            placeholder="请选择单位"
            labelInValue
            params={{ enableFlag: UsageStatus.enabled }}
          />
        ),
      },
      {
        title: '存储区域',
        dataIndex: 'locationList',
        type: fieldTypeList.select,
        width: 150,
        render: (locationList: any[]) => locationList?.map(({ name }) => name).join('/'),
      },
      {
        title: '外部ID',
        dataIndex: 'externalNo',
        type: fieldTypeList.text,
        isFilter: true,
        width: 150,
      },
      {
        title: '品牌型号',
        dataIndex: 'brandModelMessage',
        isFilter: true,
        width: 150,
        renderItem: <SearchSelect fetchType="brandModel" />,
      },
      {
        title: '使用年限',
        dataIndex: 'useAge',
        isFilter: true,
        sorter: true,
        width: 150,
        render: useAgeRender,
        renderItem: <UseAgeFilter />,
      },
      {
        title: '计划报废日期',
        dataIndex: 'planScrapDate',
        type: fieldTypeList.date,
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        isFilter: true,
        sorter: true,
        width: 180,
      },
      {
        title: '关联仓位',
        dataIndex: 'storageName',
        width: 150,
      },
      {
        title: '备注',
        dataIndex: 'remark',
        type: fieldTypeList.textArea,
        isFilter: true,
        width: 150,
      },
    ];
  }, [customFields]);

  const filterList = dataColumns()
    .filter((i) => i.isFilter)
    .map((column: any) => {
      const filter: any = {
        label: column.title,
        name: column.filterName || column.dataIndex,
        type: column.type,
        rules: column.rules,
        renderItem: column.renderItem,
      };

      if (column.type === fieldTypeList.select || column.type === fieldTypeList.multiSelect) {
        filter.selectProps = column.selectProps;
      }
      if (column.type === fieldTypeList.date && column.dateFormat) {
        filter.dateFormat = column.dateFormat;
      }
      if (column.filterRender) {
        filter.renderItem = column.filterRender;
      }
      return filter;
    });

  /**
   * 设备列表接口的参数，除了筛选表单的字段之外，还有特殊处理如下：
   * parentFlag，是否只取最高层级一级的设备数据
   *     在树状列表页初始加载的时候需要传true值
   *     若有筛选条件需要过滤所有层级的设备，则需要传false
   *     若是展开某设备，请求下一级设备，则需要不传
   *     若是请求非树状的平铺列表数据，一定不能传
   * parentId: 父设备id
   *     用于展开某设备的子级设备，其他时候不要传，且不能和parentFlag共存
   *     若是请求非树状的平铺列表数据，一定不能传
   * locationId: 区域id
   *     设备列表仅在第一层请求时与左侧的区域树联动，展开子级设备时，展示全部区域的子设备，不能传这个字段（HHZZ3-18135）
   * selectNoLocationFlag: 无区域设备是否被选中，选中则只展示所有无区域顶级设备
   *     若是请求非树状的平铺列表数据，一定不能传
   * subLocationFlag: 包含子区域是否被勾上，勾上则展示当前区域及所有后代区域的顶级设备
   *     若是请求非树状的平铺列表数据，一定不能传
   * @param data 设备筛选表单的值
   * @returns 列表接口请求参数
   */
  const formatDataToQuery = (data: any) => {
    const sendData = _.cloneDeep(data);
    // 用户输入的真实筛选/搜索条件，如果为空则查询列表接口parentFlag是true, 返回一级数据
    const customerConditions = _.omitBy(sendData, (value, key) => {
      return (
        _.isNil(value) ||
        (_.isArray(value) && value.length === 0) ||
        value === '' ||
        [
          'page',
          'size',
          'sorter',
          'locationId',
          'parentFlag',
          'selectNoLocationFlag',
          'subLocationFlag',
          'parentId',
        ].includes(key)
      );
    });
    const parentFlag = _.isEmpty(customerConditions);

    setCurParentFlag(parentFlag);

    if (!gcArray.isEmpty(sendData.outFactoryDate)) {
      sendData.beginOutFactoryDate = Number(gcTime.formatToUnix(sendData.outFactoryDate[0]));
      sendData.endOutFactoryDate = Number(gcTime.formatToUnix(sendData.outFactoryDate[1]));
      delete sendData.outFactoryDate;
    }
    if (!gcArray.isEmpty(sendData.enterFactoryDate)) {
      sendData.beginEnterFactoryDate = Number(gcTime.formatToUnix(sendData.enterFactoryDate[0]));
      sendData.endEnterFactoryDate = Number(gcTime.formatToUnix(sendData.enterFactoryDate[1]));
      delete sendData.enterFactoryDate;
    }
    if (!gcArray.isEmpty(sendData.enableDate)) {
      sendData.beginEnableDate = Number(gcTime.formatToUnix(sendData.enableDate[0]));
      sendData.endEnableDate = Number(gcTime.formatToUnix(sendData.enableDate[1]));
      delete sendData.enableDate;
    }
    if (!gcArray.isEmpty(sendData.planScrapDate)) {
      sendData.beginPlanScrapDate = Number(gcTime.formatToUnix(sendData.planScrapDate[0]));
      sendData.endPlanScrapDate = Number(gcTime.formatToUnix(sendData.planScrapDate[1]));
      delete sendData.planScrapDate;
    }

    if (!gcArray.isEmpty(sendData.responsibleDepartment)) {
      sendData.departmentIdList = sendData.responsibleDepartment.map(
        (department: { value: number }) => department.value,
      );
      delete sendData.responsibleDepartment;
    }

    if (!gcArray.isEmpty(sendData.responsibleUser)) {
      sendData.userIdList = sendData.responsibleUser.map((user: { value: number }) => user.value);
      delete sendData.responsibleUser;
    }

    if (sendData.unitName) {
      sendData.unitId = sendData.unitName?.value;
      delete sendData.unitName;
    }
    if (sendData.brandModelMessage) {
      sendData.brandModel = sendData.brandModelMessage.value;
    }

    if (sendData.classification) {
      sendData.classificationId = sendData.classification.pop();
      delete sendData.classification;
    }

    if (sendData.supplier) {
      sendData.supplierIdList = mapLabeledValueToValue(sendData.supplier);
    }

    // 搜索时如果有curParentId，代表正在更新设备状态的某一条子设备，此时需要刷新整棵树，所以按else逻辑走
    if (!_.isNil(curParentId) && parentFlag) {
      sendData.parentId = curParentId;
      // 展开子节点的时候，请求不能代入外层的pageSize, 而一定要请求所有的子节点。
      // 子设备限制最多20个，所以请求20个即可
      sendData.size = MAX_CHILDREN_COUNT;
      delete sendData.parentFlag;
    } else {
      // 如果没有parentId，parentFlag才传，如果是展开某一层的情况，parentFlag应该不存在
      sendData.parentFlag = parentFlag;
      // 如果没有parentId，才传locationId HHZZ3-18135
      sendData.locationId = currArea;
      sendData.subLocationFlag = subLocationFlag;
      sendData.selectNoLocationFlag = selectNoLocationFlag;
    }

    paramRef.current = _.omit(sendData, [
      'locationId',
      'parentFlag',
      'selectNoLocationFlag',
      'subLocationFlag',
      'parentId',
    ]);

    dispatch.excelImport.updateBusinessData({ businessData: sendData });
    return sendData;
  };

  const formatDataToDisplay = (data: any) => {
    const displayData = _.cloneDeep(data);

    if (!gcArray.isEmpty(displayData?.enableDate)) {
      displayData.enableDate = `${gcTime.formatLine(
        displayData.enableDate[0],
      )} ~ ${gcTime.formatLine(displayData.enableDate[1])}`;
    }
    if (!gcArray.isEmpty(data?.enterFactoryDate)) {
      displayData.enterFactoryDate = `${gcTime.formatLine(
        displayData.enterFactoryDate[0],
      )} ~ ${gcTime.formatLine(displayData.enterFactoryDate[1])}`;
    }
    if (!gcArray.isEmpty(displayData.outFactoryDate)) {
      displayData.outFactoryDate = `${gcTime.formatLine(
        displayData.outFactoryDate[0],
      )} ~ ${gcTime.formatLine(displayData.outFactoryDate[1])}`;
    }
    if (!gcArray.isEmpty(displayData.planScrapDate)) {
      displayData.planScrapDate = `${gcTime.formatLine(
        displayData.planScrapDate[0],
      )} ~ ${gcTime.formatLine(displayData.planScrapDate[1])}`;
    }
    if (displayData.status) {
      displayData.status = RESORCE_STATUS_TYPE.get(displayData.status);
    }
    return displayData;
  };

  const formatDataToFormDisplay = (data: any) => {
    const { enterFactoryDate, outFactoryDate, enableDate, planScrapDate, ...rest } = data;

    return {
      ...rest,
      enterFactoryDate: gcTime.formatRangeTimeToMoment(enterFactoryDate),
      outFactoryDate: gcTime.formatRangeTimeToMoment(outFactoryDate),
      enableDate: gcTime.formatRangeTimeToMoment(enableDate),
      planScrapDate: gcTime.formatRangeTimeToMoment(planScrapDate),
    };
  };

  return (
    <>
      <RecordListLayout<ResourceListItemType>
        filterList={filterList}
        requestFn={loadResources}
        customDataSource={dataSource}
        useIndex={false}
        mainMenu={mainMenu}
        batchMenu={batchMenu}
        placeholder={'请输入设备名称或编号'} // 快速搜索input placeholder
        formatDataToQuery={formatDataToQuery} // 转换搜索条件进行查询（快速查询字段："quickSearch",当前页："page",分页大小："size"）
        formatDataToDisplay={formatDataToDisplay} // 转换搜索条件进行展示
        formatDataToFormDisplay={formatDataToFormDisplay}
        columns={dataColumns()} // table columns
        refreshMarker={refreshMarker}
        selectedRowKeys={selectedRowKeys}
        rowKey="id"
        configcacheKey={'resourceDefinition'}
        onSelectedRowKeys={(selectKey: React.Key[]) => {
          setSelectedRowKeys(selectKey.map((key) => Number(key)));
        }}
        expandable={{
          onExpand,
          expandedRowKeys,
          // 关闭点击行展开，列操作都属于行点击，如果不关闭，启用停用某条数据就会等同于展开某条数据
          expandRowByClick: false,
          onExpandedRowsChange: (expandedRows: number[]) => {
            setExpandedRowKeys(expandedRows);
          },
        }}
        getOperationList={getOperationList}
        userAuth={getAuthFromLocalStorage()}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={'Equipment'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default ResourceDefinitionList;
