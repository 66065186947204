/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../../request';

// makeRequest
function makeRequestRequired<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequestOptional<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData?: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequest<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const optional = makeRequestOptional<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  const required = makeRequestRequired<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  return (
    requestConfig.requestDataOptional ? optional : required
  ) as TRequestConfig['requestDataOptional'] extends true ? typeof optional : typeof required;
}

// Request
export type Request<TReqeustData, TRequestConfig extends RequestConfig, TRequestResult> =
  (TRequestConfig['requestDataOptional'] extends true
    ? (
        requestData?: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult
    : (
        requestData: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult) & {
    requestConfig: TRequestConfig;
  };

const mockUrl_0_22_0_0 = 'http://yapi.blacklake.tech/mock/503' as any;
const devUrl_0_22_0_0 = '' as any;
const prodUrl_0_22_0_0 = '' as any;
const dataKey_0_22_0_0 = undefined as any;

/**
 * 接口 [bulkDelete↗](http://yapi.blacklake.tech/project/503/interface/api/71852) 的 **请求类型**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/bulkDelete`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageAreaBulkDeleteRequest {
  /**
   * 区域id列表
   */
  ids: number[];
}

/**
 * 接口 [bulkDelete↗](http://yapi.blacklake.tech/project/503/interface/api/71852) 的 **返回类型**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/bulkDelete`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageAreaBulkDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 失败数量
     */
    failCount?: number;
    failDetailList?: {
      /**
       * 编码
       */
      code?: string;
      /**
       * 错误描述
       */
      errorDesc?: string;
      /**
       * 操作出现异常的记录id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 成功数量
     */
    successCount?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [bulkDelete↗](http://yapi.blacklake.tech/project/503/interface/api/71852) 的 **请求配置的类型**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/bulkDelete`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageAreaBulkDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_area/bulkDelete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [bulkDelete↗](http://yapi.blacklake.tech/project/503/interface/api/71852) 的 **请求配置**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/bulkDelete`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageAreaBulkDeleteRequestConfig: FetchStorageAreaBulkDeleteRequestConfig = {
  mockUrl: mockUrl_0_22_0_0,
  devUrl: devUrl_0_22_0_0,
  prodUrl: prodUrl_0_22_0_0,
  path: '/storage/domain/web/v1/storage_area/bulkDelete',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_22_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageAreaBulkDelete',
};

/**
 * 接口 [bulkDelete↗](http://yapi.blacklake.tech/project/503/interface/api/71852) 的 **请求函数**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/bulkDelete`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageAreaBulkDelete = makeRequest<
  FetchStorageAreaBulkDeleteRequest,
  FetchStorageAreaBulkDeleteResponse,
  FetchStorageAreaBulkDeleteRequestConfig
>(fetchStorageAreaBulkDeleteRequestConfig);

/**
 * 接口 [create↗](http://yapi.blacklake.tech/project/503/interface/api/71858) 的 **请求类型**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/create`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageAreaCreateRequest {
  /**
   * 编号
   */
  code?: string;
  /**
   * 名称
   */
  name: string;
  /**
   * 父级区域id，如果没有父区域，直属仓库，则不填
   */
  parentAreaId?: number;
  /**
   * 标识码
   */
  qrCode?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 所属仓库id
   */
  warehouseId: number;
}

/**
 * 接口 [create↗](http://yapi.blacklake.tech/project/503/interface/api/71858) 的 **返回类型**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/create`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageAreaCreateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: number;
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [create↗](http://yapi.blacklake.tech/project/503/interface/api/71858) 的 **请求配置的类型**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/create`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageAreaCreateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_area/create',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [create↗](http://yapi.blacklake.tech/project/503/interface/api/71858) 的 **请求配置**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/create`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageAreaCreateRequestConfig: FetchStorageAreaCreateRequestConfig = {
  mockUrl: mockUrl_0_22_0_0,
  devUrl: devUrl_0_22_0_0,
  prodUrl: prodUrl_0_22_0_0,
  path: '/storage/domain/web/v1/storage_area/create',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_22_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageAreaCreate',
};

/**
 * 接口 [create↗](http://yapi.blacklake.tech/project/503/interface/api/71858) 的 **请求函数**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/create`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageAreaCreate = makeRequest<
  FetchStorageAreaCreateRequest,
  FetchStorageAreaCreateResponse,
  FetchStorageAreaCreateRequestConfig
>(fetchStorageAreaCreateRequestConfig);

/**
 * 接口 [info↗](http://yapi.blacklake.tech/project/503/interface/api/71864) 的 **请求类型**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/info`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageAreaInfoRequest {
  /**
   * 区域id
   */
  id: number;
}

/**
 * 接口 [info↗](http://yapi.blacklake.tech/project/503/interface/api/71864) 的 **返回类型**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/info`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageAreaInfoResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 编码
     */
    code: string;
    /**
     * 区域id
     */
    id: number;
    /**
     * 名称
     */
    name: string;
    /**
     * 父区域编码
     */
    parentCode: string;
    /**
     * 父区域id
     */
    parentId: number;
    /**
     * 父区域名称
     */
    parentName: string;
    /**
     * 标识码
     */
    qrCode?: string;
    /**
     * 所属仓库编码
     */
    warehouseCode: string;
    /**
     * 所属仓库id
     */
    warehouseId: number;
    /**
     * 所属仓库名称
     */
    warehouseName: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [info↗](http://yapi.blacklake.tech/project/503/interface/api/71864) 的 **请求配置的类型**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/info`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageAreaInfoRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_area/info',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [info↗](http://yapi.blacklake.tech/project/503/interface/api/71864) 的 **请求配置**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/info`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageAreaInfoRequestConfig: FetchStorageAreaInfoRequestConfig = {
  mockUrl: mockUrl_0_22_0_0,
  devUrl: devUrl_0_22_0_0,
  prodUrl: prodUrl_0_22_0_0,
  path: '/storage/domain/web/v1/storage_area/info',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_22_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageAreaInfo',
};

/**
 * 接口 [info↗](http://yapi.blacklake.tech/project/503/interface/api/71864) 的 **请求函数**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/info`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageAreaInfo = makeRequest<
  FetchStorageAreaInfoRequest,
  FetchStorageAreaInfoResponse,
  FetchStorageAreaInfoRequestConfig
>(fetchStorageAreaInfoRequestConfig);

/**
 * 接口 [modify↗](http://yapi.blacklake.tech/project/503/interface/api/71870) 的 **请求类型**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/modify`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageAreaModifyRequest {
  /**
   * 编号
   */
  code: string;
  /**
   * 区域id
   */
  id: number;
  /**
   * 名称
   */
  name: string;
  /**
   * 父区域id
   */
  parentAreaId?: number;
  /**
   * 识别码
   */
  qrCode?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 所属仓库id
   */
  warehouseId: number;
}

/**
 * 接口 [modify↗](http://yapi.blacklake.tech/project/503/interface/api/71870) 的 **返回类型**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/modify`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageAreaModifyResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [modify↗](http://yapi.blacklake.tech/project/503/interface/api/71870) 的 **请求配置的类型**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/modify`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageAreaModifyRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_area/modify',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [modify↗](http://yapi.blacklake.tech/project/503/interface/api/71870) 的 **请求配置**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/modify`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageAreaModifyRequestConfig: FetchStorageAreaModifyRequestConfig = {
  mockUrl: mockUrl_0_22_0_0,
  devUrl: devUrl_0_22_0_0,
  prodUrl: prodUrl_0_22_0_0,
  path: '/storage/domain/web/v1/storage_area/modify',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_22_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageAreaModify',
};

/**
 * 接口 [modify↗](http://yapi.blacklake.tech/project/503/interface/api/71870) 的 **请求函数**
 *
 * @分类 [仓库区域↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10720)
 * @标签 `仓库区域`
 * @请求头 `POST /storage/domain/web/v1/storage_area/modify`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageAreaModify = makeRequest<
  FetchStorageAreaModifyRequest,
  FetchStorageAreaModifyResponse,
  FetchStorageAreaModifyRequestConfig
>(fetchStorageAreaModifyRequestConfig);

/* prettier-ignore-end */
