import { Form, InputNumber, message, Radio, Select } from 'antd';
import { debounce, get } from 'lodash';
import { useState, useEffect, FC } from 'react';
import {
  fetchPlanOperationMaterialAdd,
  FetchPlanOperationMaterialAddRequest,
  fetchPlanOperationMaterialUpdate,
} from 'src/api/ytt/plo-domain/plannOrderOperation';

import { MaterialSelect, TagList } from 'src/components';
import lookup, { appEnum } from 'src/dict';
import { DiffRequSources, PlanAttribute } from 'src/dict/planned';
import {
  DiffRequSourcesDisplayMap,
  leadTimeUnitDisplayMap,
  PlanAttributeDisplayMap,
} from 'src/dict/planned/mappings';
import { getMaterialAttrs } from 'src/entity/material';
import { DataFormLayout } from 'src/layout';
import { validateBlInputNumberIntervalInt } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import { validateBlInputNumberInt } from 'src/page/custom_fields/fieldsList/components/verificationRules/verificationRules';
import { arrayIsEmpty } from 'src/utils/array';
import { replaceSign } from 'src/utils/constants';

interface Props {
  params: {
    type: string;
    id?: number;
    data?: any;
  };
  onChange: (value?: any[]) => void;
}

const PlanCalculationMaterial: FC<Props> = (props) => {
  const { onChange, params } = props;

  const [form] = Form.useForm<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (params.type === appEnum.Common.CRUD.edit) {
      const { data } = params;

      form.setFieldsValue(formatData(data));
    } else {
      setLoading(false);
    }
  }, []);

  const baseInfo = [
    {
      column: 3,
      title: '物料信息',
      items: [
        {
          label: '物料编号',
          name: 'materialId',
          rules: [{ required: true, message: '物料编号不能为空' }],
          render: () => (
            <MaterialSelect
              placeholder="请输入"
              labelInValue
              params={{
                enableFlag: appEnum.Common.UsageStatus.enabled,
                bizType: [appEnum.Material.BizType.default],
              }}
              disabled={params.type === appEnum.Common.CRUD.edit}
            />
          ),
        },
        {
          label: '物料名称',
          name: 'materialName',
          width: 120,
          render: () => {
            return (
              <Form.Item dependencies={[['materialId']]} style={{ marginBottom: '0' }}>
                {() => {
                  const material = JSON.parse(form.getFieldValue(['materialId'])?.value ?? '{}');

                  return get(material, ['baseInfo', 'name']);
                }}
              </Form.Item>
            );
          },
        },
        {
          label: '物料属性',
          name: 'attributes',
          width: 120,
          render: () => {
            return (
              <Form.Item dependencies={[['materialId']]} style={{ marginBottom: '0' }}>
                {() => {
                  const material = JSON.parse(form.getFieldValue(['materialId'])?.value ?? '{}');

                  return <TagList dataSource={getMaterialAttrs(material)} />;
                }}
              </Form.Item>
            );
          },
        },
        {
          label: '物料分类',
          name: 'category',
          width: 120,
          render: () => {
            return (
              <Form.Item dependencies={[['materialId']]} style={{ marginBottom: '0' }}>
                {() => {
                  const material = JSON.parse(form.getFieldValue(['materialId'])?.value ?? '{}');

                  return get(material, ['category', 'name']);
                }}
              </Form.Item>
            );
          },
        },
        {
          label: '物料规格',
          name: 'materialName',
          width: 120,
          render: () => {
            return (
              <Form.Item dependencies={[['materialId']]} style={{ marginBottom: '0' }}>
                {() => {
                  const material = JSON.parse(form.getFieldValue(['materialId'])?.value ?? '{}');

                  return get(material, ['baseInfo', 'specification']);
                }}
              </Form.Item>
            );
          },
        },
        {
          label: '业务范围',
          name: 'materialName',
          width: 120,
          render: () => {
            return (
              <Form.Item dependencies={[['materialId']]} style={{ marginBottom: '0' }}>
                {() => {
                  const material = JSON.parse(form.getFieldValue(['materialId'])?.value ?? '{}');

                  if (!arrayIsEmpty(material?.baseInfo?.bizRange)) {
                    const arr = material.baseInfo.bizRange.map((item: number) => {
                      return lookup('material.bizRange', item);
                    });

                    return arr.join('、');
                  }
                  return replaceSign;
                  // return get(material, ['baseInfo', 'specification']);
                }}
              </Form.Item>
            );
          },
        },
      ],
    },
    {
      title: '计划信息',
      items: [
        {
          label: '计划属性',
          name: 'planAttribute',
          rules: [{ required: true, message: '计划属性不能为空' }],
          initialValue: PlanAttribute.Homemade,
          render: () => <Select options={PlanAttributeDisplayMap} disabled />,
        },
        {
          label: '不同需求来源',
          name: 'differentRequirementSources',
          rules: [{ required: true, message: '不同需求来源不能为空' }],
          initialValue: DiffRequSources.NoMerge,
          render: () => (
            <Radio.Group onChange={() => {}} disabled>
              {DiffRequSourcesDisplayMap.map(({ label, value }) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Radio.Group>
          ),
        },
      ],
    },
    {
      title: '提前期',
      items: [
        {
          label: '提前期单位',
          name: 'leadTimeUnit',
          render: () => <Select options={leadTimeUnitDisplayMap} allowClear />,
        },
        {
          label: '固定提前期',
          dependencies: ['leadTimeUnit'],
          render: () => () => {
            const leadTimeUnit = form.getFieldValue('leadTimeUnit');

            return (
              <Form.Item
                name={'leadTime'}
                fieldKey={'leadTime'}
                rules={[{ validator: validateBlInputNumberIntervalInt(0, 200) }]}
              >
                <InputNumber disabled={!leadTimeUnit} placeholder="请输入" />
              </Form.Item>
            );
          },
        },
      ],
    },
    {
      title: '备料偏置时间',
      items: [
        {
          label: '偏置时间单位',
          name: 'offsetTimeUnit',
          render: () => <Select options={leadTimeUnitDisplayMap} allowClear />,
        },
        {
          label: '备料偏置时间',
          dependencies: ['offsetTimeUnit'],
          render: () => () => {
            const offsetTimeUnit = form.getFieldValue('offsetTimeUnit');

            return (
              <Form.Item
                name={'offsetTime'}
                fieldKey={'offsetTime'}
                rules={[{ validator: validateBlInputNumberInt(-200, 200, true, true) }]}
              >
                <InputNumber disabled={!offsetTimeUnit} placeholder="请输入" />
              </Form.Item>
            );
          },
        },
        {
          name: ['id'],
        },
      ],
    },
  ];

  const formatData = (params: any) => {
    const { material, ...rest } = params || {};

    const initValue = {
      ...rest,
      materialId: {
        key: JSON.stringify(material),
        value: JSON.stringify(material),
        label: material?.baseInfo?.code,
      },
    };

    return initValue;
  };

  const formatValueToApi = (values: any): FetchPlanOperationMaterialAddRequest => {
    const { materialId } = values;

    const material = JSON.parse(materialId?.value ?? '{}');

    return {
      ...values,
      materialId: material?.baseInfo?.id,
    };
    // onChange(values);
  };

  const handleFinish = async () => {
    try {
      form
        ?.validateFields()
        .then((values) => {
          const value = formatValueToApi(values);

          if (params.type === appEnum.Common.CRUD.edit) {
            fetchPlanOperationMaterialUpdate(value).then((res) => {
              const { code = 0 } = res ?? {};

              if (code === 200) message.success('编辑成功!');
              onChange();
            });
          } else {
            fetchPlanOperationMaterialAdd(value).then((res) => {
              const { code = 0 } = res ?? {};

              if (code === 200) message.success('创建成功!');
              onChange();
            });
          }
        })
        .catch((error) => {
          const { errorFields } = error;

          form.scrollToField(errorFields?.[0]?.name);
        });
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <>
      <DataFormLayout
        loading={loading}
        form={form}
        info={baseInfo}
        title={params.type === appEnum.Common.CRUD.create ? '添加计划运算物料' : '调整物料计划配置'}
        onCancel={() => {
          onChange();
        }}
        onFinish={debounce(handleFinish, 1500)}
      />
    </>
  );
};

export default PlanCalculationMaterial;
