export const BASE_URL = '/modeling/calendarManagement/shiftList';
export const SCHEDULING_BASE_URL = '/modeling/calendarManagement/schedulingRules';

// 班次

export const toShiftDetail = (id: number) => {
  return `${BASE_URL}/${id}/detail`;
};

export const toShiftEdit = (id: number) => {
  return `${BASE_URL}/${id}/edit`;
};
export const toShiftCreate = () => {
  return `${BASE_URL}/create`;
};

export const toShiftList = () => {
  return `${BASE_URL}`;
};

// 排班规则

export const toSchedulingDetail = (id: number) => {
  return `${SCHEDULING_BASE_URL}/${id}/detail`;
};

export const toSchedulingEdit = (id: number) => {
  return `${SCHEDULING_BASE_URL}/${id}/edit`;
};
export const toSchedulingCreate = () => {
  return `${SCHEDULING_BASE_URL}/create`;
};

export const toSchedulingList = () => {
  return `${SCHEDULING_BASE_URL}`;
};
