/**
 * 字段权限
 */

import _ from 'lodash';
import {
  fetchFieldPermissionGetByObjectCode,
  fetchFieldPermissionGetByObjectCodes,
  FetchFieldPermissionGetByObjectCodeResponse,
  FetchFieldPermissionGetByObjectCodesResponse,
} from 'src/api/ytt/privilege-domain/fieldPermission';
import { appEnum } from 'src/dict';

export type FieldPermission = NonNullable<FetchFieldPermissionGetByObjectCodeResponse['data']>[0];
export type ObjectFieldPermission = NonNullable<
  FetchFieldPermissionGetByObjectCodesResponse['data']
>[0];

/**
 * 获取单个对象字段权限
 * @param objectCode
 * @returns
 */
export async function getObjectFieldsPermission(objectCode: string): Promise<FieldPermission[]> {
  try {
    const res = await fetchFieldPermissionGetByObjectCode(
      { bizObjectCode: objectCode },
      { showErrorMessage: false },
    );
    const data: FieldPermission[] = _.get(res, 'data', []);

    return data;
  } catch (error) {
    return [];
  }
}

/**
 * 获取多个对象字段权限
 * @param objectCodes
 * @returns
 */
export async function getObjectFieldsPermissionList(
  objectCodes: string[],
): Promise<ObjectFieldPermission[]> {
  try {
    const res = await fetchFieldPermissionGetByObjectCodes(
      { bizObjectCodes: objectCodes },
      { showErrorMessage: false },
    );
    const data: ObjectFieldPermission[] = _.get(res, 'data', []);

    return data;
  } catch (error) {
    return [];
  }
}

/**
 * 判断是否用户对该字段是否有『编辑』权限
 * @param objectFieldPermissions
 * @param fieldName 和fieldId二传一
 * @param fieldId 和fieldName二传一
 * @returns boolean  true:有权限或不需要权限 false:无权限
 */
export function judegFieldPermissionEditable(
  objectFieldsPermission: FieldPermission[],
  fieldName?: string,
  fieldId?: number,
): boolean {
  if (_.isEmpty(objectFieldsPermission) || _.isEmpty(fieldName)) return true;

  let fieldPermissionInfo;
  if (fieldId) {
    fieldPermissionInfo = _.find(objectFieldsPermission, ['id', fieldId]);
  } else {
    fieldPermissionInfo = _.find(objectFieldsPermission, ['name', fieldName]);
  }

  // 不存在对应字段权限信息
  if (_.isEmpty(fieldPermissionInfo)) return true;

  // 该字段权限信息为『允许编辑』
  if (fieldPermissionInfo?.editable === appEnum.Common.YN.yes) {
    return true;
  }

  return false;
}

/**
 * 判断是否用户对该字段是否有『查看』权限
 * @param objectFieldPermissions
 * @param fieldName 和fieldId二传一
 * @param fieldId 和fieldName二传一
 * @returns boolean  true:有权限或不需要权限 false:无权限
 */
export function judegFieldPermissionVisible(
  objectFieldsPermission: FieldPermission[],
  fieldName?: string,
  fieldId?: number,
): boolean {
  if (_.isEmpty(objectFieldsPermission) || _.isEmpty(fieldName)) return true;

  let fieldPermissionInfo;
  if (fieldId) {
    fieldPermissionInfo = _.find(objectFieldsPermission, ['id', fieldId]);
  } else {
    fieldPermissionInfo = _.find(objectFieldsPermission, ['name', fieldName]);
  }

  // 不存在对应字段权限信息
  if (_.isEmpty(fieldPermissionInfo)) return true;

  // 该字段权限信息为『允许查看』
  if (fieldPermissionInfo?.visible === appEnum.Common.YN.yes) {
    return true;
  }

  return false;
}
