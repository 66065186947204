import { mappingsFactory } from 'src/dict/utils';

export enum ResourceGroupTypeEnum {
  'user',
  'department',
  'device',
}

export const resourceGroupTypeMap = mappingsFactory(
  ['用户', ResourceGroupTypeEnum.user],
  ['部门', ResourceGroupTypeEnum.department],
  ['设备', ResourceGroupTypeEnum.device],
);

export enum UserResourceGroupBizTypeEnum {
  'user',
  'department',
}
