import { useEffect, useState } from 'react';
import { Form, Input, DatePicker, FormInstance } from 'antd';
import moment, { Moment } from 'moment';
import _ from 'lodash';

import { DataFormLayoutForModal, DataFormLayout } from 'src/layout';
import { numberChineseAlphabetSpecialSymbols } from 'src/utils/formValidators';
import { REST_SHIFT } from 'src/utils/constants';
import { SearchSelect } from 'src/components';
import { SpecialDayDataRecordProps } from '../../../constants';

const { useForm } = Form;

interface SpecialDayModalProps {
  onClose: () => void;
  onChange: (newValue: SpecialDayDataRecordProps) => void;
  // table中新建的时候不用传specialDayData，工作日历新建/编辑，表格编辑有specialDayData
  specialDayData?: SpecialDayDataRecordProps;
  calendarForm: FormInstance; // 工作日历表单的FormInstance，是为了拿到外层表单的开始日期和结束日期用于validation
  specialDayList?: SpecialDayDataRecordProps[]; // 现有特殊日列表，用来做时间validation，
}

interface CalendarDateRangeProps {
  startDate: Moment;
  endDate: Moment;
}

export default function OperateSpecialDayModal({
  onClose,
  onChange,
  specialDayData,
  calendarForm,
  specialDayList,
}: SpecialDayModalProps) {
  const [calendarDateRange, setCalendarDateRange] = useState<CalendarDateRangeProps>(
    {} as CalendarDateRangeProps,
  );

  // 从日历预览里点进来的特殊日有其他数据，但名称是空字符串
  const dialogTitle =
    specialDayData && specialDayData.name.length > 0 ? '编辑特殊日' : '添加特殊日';
  const [specialDayForm] = useForm();

  const submitData = async () => {
    // 表单确认后，从表单提取数据，传给外层
    try {
      await specialDayForm.validateFields();
      const specialDayFormData = specialDayForm.getFieldsValue();
      const { startTime, endTime, shift, name } = specialDayFormData;

      onChange?.({
        shiftName: shift.label,
        shiftId: shift.value,
        startTime: startTime.startOf('day').valueOf(),
        endTime: endTime.startOf('day').valueOf(),
        name,
        specialDayId: specialDayData?.specialDayId,
        // specialDayIndex是由前端生成的临时标记特殊日的id，非正式id
        // 因为特殊日在保存之前就需要编辑，所以需要specialDayIndex
        // 当特殊日曾经保存过，specialDayIndex就是specialDayId，在外层给dataSource绑定时已经map好了
        // 如果没有保存过，在编辑时，specialDayIndex是新建时设置的时间戳，如果是新建，则取时间戳为specialDayIndex赋值
        specialDayIndex: specialDayData?.specialDayIndex ?? Date.now(),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const findSpecialDay = (specialDayIndex?: number) => {
    return _.find(specialDayList, (spDay) => {
      return spDay.specialDayIndex === specialDayIndex;
    });
  };

  const checkTimeOcupied = (current: Moment) => {
    if (!specialDayList) return false;
    return specialDayList?.some((spDay) => {
      const { startTime, endTime } = spDay;
      const startMoment = moment(startTime);
      const endMoment = moment(endTime);

      return current.isBetween(startMoment, endMoment, 'day', '[]');
    });
  };

  // antd的datepicker组件在选择date时，虽然看不见time，但存在time为选择时的时刻
  // 这样会导致同一个日期因为选择的先后顺序而出现大小，直接使用>,<,=来运算时出错
  // 所以用isBefore/isAfter方法指定比较粒度到天来比较
  const disableStartDate = (current: Moment) => {
    const specialDayEndDate = specialDayForm.getFieldValue('endTime');

    if (calendarDateRange.startDate && calendarDateRange.endDate) {
      return (
        current.isBefore(calendarDateRange.startDate, 'day') ||
        current.isAfter(calendarDateRange.endDate, 'day') ||
        checkTimeOcupied(current) ||
        (specialDayEndDate && current.isAfter(specialDayEndDate, 'day'))
      );
    }
    return false;
  };
  const disableEndDate = (current: Moment) => {
    if (calendarDateRange.startDate && calendarDateRange.endDate) {
      const specialDayStartDate = specialDayForm.getFieldValue('startTime');
      const outOfCalendarRange =
        current.isBefore(calendarDateRange.startDate, 'day') ||
        current.isAfter(calendarDateRange.endDate, 'day');

      // 在开始日期未选时先选结束日期，specialDayStartDate为undefined
      // 会出现一种奇怪的现象是，antd把undefined变成来now来计算，早于今天的日期全部失效
      // 所以要判断specialDayStartDate是否存在，若不存在则不考虑它
      return specialDayStartDate
        ? outOfCalendarRange ||
            current.isBefore(specialDayStartDate, 'day') ||
            checkTimeOcupied(current)
        : outOfCalendarRange || checkTimeOcupied(current);
    }
    return false;
  };

  useEffect(() => {
    if (specialDayData) {
      const { startTime, endTime, shiftId, shiftName, specialDayIndex } = specialDayData;
      const startTimeString = startTime ?? findSpecialDay(specialDayIndex)?.startTime;
      const endTimeString = endTime ?? findSpecialDay(specialDayIndex)?.endTime;
      const shift = !_.isNil(shiftId)
        ? {
            value: shiftId,
            label: shiftName,
          }
        : REST_SHIFT;

      specialDayForm.setFieldsValue({
        ...specialDayData,
        startTime: moment(startTimeString),
        endTime: moment(endTimeString),
        shift,
      });
    } else {
      specialDayForm.setFieldsValue({
        shift: REST_SHIFT,
      });
    }
  }, [specialDayData]);

  useEffect(() => {
    // 第一次加载完成时，从工作日历的表单里获取当前的开始日期和结束日期，用以约束特殊日开始日期和结束日期的范围
    setCalendarDateRange({
      startDate: calendarForm.getFieldValue('startDate'),
      endDate: calendarForm.getFieldValue('endDate'),
    });
  }, []);

  const operateSpecialDayFormItems = [
    {
      column: 1,
      items: [
        {
          label: '名称',
          name: 'name',
          rules: [
            { required: true, message: '特殊日名称必填' },
            numberChineseAlphabetSpecialSymbols,
            { max: 10, message: '不可超过10个字符' },
          ],
          render: () => <Input placeholder="请输入" allowClear />,
        },
        {
          label: '开始日期',
          name: 'startTime',
          rules: [{ required: true, message: '开始日期必选' }],
          render: () => (
            <DatePicker allowClear disabledDate={disableStartDate} style={{ width: '100%' }} />
          ),
        },
        {
          label: '结束日期',
          name: 'endTime',
          rules: [{ required: true, message: '结束日期必选' }],
          render: () => (
            <DatePicker allowClear disabledDate={disableEndDate} style={{ width: '100%' }} />
          ),
        },
        {
          label: '班次',
          name: 'shift',
          rules: [{ required: true, message: '班次必选' }],
          render: () => <SearchSelect fetchType="shiftList" />,
        },
      ],
    },
  ];

  return (
    <DataFormLayoutForModal
      visible
      width={648}
      onClose={onClose}
      content={
        <DataFormLayout
          form={specialDayForm}
          info={operateSpecialDayFormItems}
          title={dialogTitle}
          onCancel={onClose}
          onFinish={submitData}
        />
      }
    />
  );
}
