import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  DataFormLayoutForModal,
  DataFormLayout,
  DataFormLayoutInfoBlock,
  DATA_FORM_MODAL_MIN_WIDTH,
} from 'layout';
import { Form, Input, message, Modal, Spin } from 'antd';
import moment from 'moment';
import * as fieldForm from './fieldFormItems/index';
import {
  fetchCustomFieldGetById,
  fetchCustomFieldUpdateCustomField,
  fetchChoiceValueListByFieldId,
} from 'src/api/ytt/metadata-domain/custom_field';
import { GetFetchChoiceValue, handleAssemblyChoiceValueList, handleDealWithData } from './utilsFun';
import _ from 'lodash';
import { appEnum } from 'src/dict';
import { ObjectCategory } from 'src/dict/customField';
import modal from 'antd/lib/modal';
import { FieldCategory, UsageStatus, YN } from 'src/dict/common';

interface editProps {
  visible: Boolean;
  onCancel?: () => void;
  fieldId: number;
  fieldType: number | string;
  objectId: number | string;
  refreshTable: () => void;
  fieldCategory: number;
  objectCategory: number;
  canSetIsRequired: boolean;
}

interface Detail {
  isRefer: number;
  referenceChain: any;
  referFieldName?: string;
  objectName?: string;
  defaultValue: string;
  relatedFieldId?: any;
  isNumberRuleConfig: number;
  isName?: number;
  isPrime?: number;
  isUsed?: number;
}

function EditComponent(props: editProps) {
  const {
    visible,
    onCancel,
    fieldId,
    fieldType,
    fieldCategory,
    objectCategory,
    canSetIsRequired,
    refreshTable,
  } = props;
  const objectId = Number(props.objectId);
  const [modalForm] = Form.useForm();
  const [id, setId] = useState<number>();
  const [choiceValueList, setChoiceValueList] = useState<GetFetchChoiceValue[]>([]);
  const [refreshInfoMaker, setRefreshInfoMaker] = useState<number>();
  const [enableNumberRule, setEnableNumberRule] = useState(true);
  const [detail, setDetail] = useState<Detail>({} as Detail);

  const isPreField = fieldCategory === appEnum.CustomField.FieldCategory.preFields;

  const renderInitChoiceData = (data: GetFetchChoiceValue[], datafieldType: string | number) => {
    let formSelectOptions: any = {};

    if (datafieldType == appEnum.CustomField.FieldType.select) {
      formSelectOptions = handleDealWithData(
        data,
        'singleOptionList',
        appEnum.CustomField.FieldType.select,
      );
      return formSelectOptions?.singleOptionList;
    } else if (datafieldType == appEnum.CustomField.FieldType.multiSelect) {
      formSelectOptions = handleDealWithData(
        data,
        'mutipleOptionList',
        appEnum.CustomField.FieldType.multiSelect,
      );
      return formSelectOptions?.mutipleOptionList;
    }
  };

  const TabChangeCallback = (key: number) => {
    const refresh = () => setRefreshInfoMaker(new Date().getTime());

    if (key === appEnum.CustomField.FieldType.reference || detail.isRefer) {
      return fieldForm.quoteItems({ form: modalForm, data: { objectId } });
    }
    if (key === appEnum.CustomField.FieldType.text) {
      return fieldForm.singleLine({
        form: modalForm,
        fieldCategory,
        isNumberRuleConfig: detail.isNumberRuleConfig,
        isEdit: true,
        refresh,
        objectId,
        enableNumberRule,
        setEnableNumberRule,
        isPreField,
        isPrime: detail?.isPrime === YN.yes,
        isRef: detail.isRefer === YN.yes,
        isCustomObject: objectCategory === ObjectCategory.customObject,
        isUsed: detail.isUsed === UsageStatus.enabled,
      });
    }
    if (key === appEnum.CustomField.FieldType.textArea) {
      return fieldForm.multiLine({
        form: modalForm,
        fieldCategory,
        isPreField,
        isEdit: true,
      });
    }
    if (key === appEnum.CustomField.FieldType.select) {
      return fieldForm.singleChoice({
        form: modalForm,
        type: 'edit',
        tab: 'single',
        name: 'singleOptionList',
        data: renderInitChoiceData(choiceValueList, fieldType),
        isPreField,
        canSetIsRequired,
      });
    }
    if (key === appEnum.CustomField.FieldType.multiSelect) {
      return fieldForm.multiChoice({
        form: modalForm,
        type: 'edit',
        tab: 'multiple',
        name: 'mutipleOptionList',
        data: renderInitChoiceData(choiceValueList, fieldType),
        isPreField,
        canSetIsRequired,
      });
    }
    if (key === appEnum.CustomField.FieldType.boolean) {
      return fieldForm.boolItems({ form: modalForm, isPreField });
    }
    if (key === appEnum.CustomField.FieldType.number) {
      return fieldForm.numItems({ form: modalForm, isPreField, isEdit: true });
    }
    if (key === appEnum.CustomField.FieldType.integer) {
      return fieldForm.intItems({ form: modalForm, isPreField, isEdit: true });
    }
    if (key === appEnum.CustomField.FieldType.date) {
      return fieldForm.dateItems({ form: modalForm, isPreField, isEdit: true });
    }
    if (key === appEnum.CustomField.FieldType.url) {
      return fieldForm.linkItems({ form: modalForm, isPreField, isEdit: true });
    }

    if (key === appEnum.CustomField.FieldType.appendix) {
      return fieldForm.appendixItems();
    }

    if (
      key === appEnum.CustomField.FieldType.relation ||
      key === appEnum.CustomField.FieldType.relationSub
    ) {
      return fieldForm.relationItems({
        form: modalForm,
        isPreField,
        fieldCategory,
        isEdit: true,
      });
    }
    if (key === appEnum.CustomField.FieldType.subordinate) {
      return fieldForm.subordinateItems({
        form: modalForm,
        isEdit: true,
        isPreField,
      });
    }
    return [];
  };

  const referObjectInfo = (key: number) => {
    if (key === appEnum.CustomField.FieldType.relation) {
      return fieldForm.referObjectItems({
        form: modalForm,
        isNeedType: true,
        isEdit: true,
        objectParams:
          objectCategory === ObjectCategory.preObject
            ? { objectCategory: ObjectCategory.customObject }
            : null,
      });
    }
    if (
      key === appEnum.CustomField.FieldType.subordinate &&
      objectCategory === ObjectCategory.customObject
    ) {
      return fieldForm.referObjectItems({
        form: modalForm,
        isEdit: true,
      });
    }
    return [];
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    items: _.compact([
      ...referObjectInfo(Number(fieldType)),
      ...fieldForm.baseItems({
        form: modalForm,
        type: 1,
        fieldCategory,
        fieldType: Number(fieldType),
      }),
      ...TabChangeCallback(Number(fieldType)),
      ...fieldForm.quoteItems({
        form: modalForm,
        data: { objectId, isRefer: detail.isRefer },
        isEdit: true,
      }),
    ]),
  };

  const fetchSelectOptions = async (datafieldType: string | number) => {
    const { data } = await fetchChoiceValueListByFieldId({
      fieldId,
      isUsed: [appEnum.Common.UsageStatus.disabled, appEnum.Common.UsageStatus.enabled],
    });
    let formSelectOptions: any = {};

    if (datafieldType == appEnum.CustomField.FieldType.select) {
      formSelectOptions = handleDealWithData(
        data,
        'singleOptionList',
        appEnum.CustomField.FieldType.select,
      );
    } else if (datafieldType == appEnum.CustomField.FieldType.multiSelect) {
      formSelectOptions = handleDealWithData(
        data,
        'mutipleOptionList',
        appEnum.CustomField.FieldType.multiSelect,
      );
    }
    // 原始数据
    setChoiceValueList(data);
    modalForm?.setFieldsValue({ ...formSelectOptions });

    return formSelectOptions;
  };

  const fetchData = async () => {
    try {
      const { data } = await fetchCustomFieldGetById({
        id: fieldId,
        fieldCategory,
      });

      const formData: any = { ...data };

      if (
        data?.fieldType == appEnum.CustomField.FieldType.select ||
        data?.fieldType == appEnum.CustomField.FieldType.multiSelect
      ) {
        fetchSelectOptions(data?.fieldType);
      }
      let defaultValue: any = data?.defaultValue;

      if (!data?.isNumberRuleConfig) {
        setEnableNumberRule(false);
      }
      // if (data.numberRuleId === -1) {
      //   // 数据库默认为-1
      //   delete data.numberRuleId;
      // }

      if (data?.fieldType === appEnum.CustomField.FieldType.date && defaultValue) {
        if (data.datetimeFormat === 'HH:mm:ss') {
          defaultValue = moment('2000/01/01 ' + defaultValue);
        } else if (data.datetimeFormat === 'HHmmss') {
          defaultValue = moment('2000001 ' + defaultValue);
        } else if (
          data.datetimeFormat === 'YYYYMMDDHHmmss' ||
          data.datetimeFormat === 'YYYYMMDDHHmm'
        ) {
          defaultValue = moment(defaultValue.slice(0, 8) + ' ' + defaultValue.slice(8));
        } else {
          defaultValue = moment(defaultValue);
        }
      }

      if (data?.isRefer && fieldCategory === appEnum.CustomField.FieldCategory.preFields) {
        defaultValue = null;
      }

      if (data?.reference) {
        formData.reference = { value: data.reference, label: data?.referenceDisplay };
      }

      if (data?.numberRuleId) {
        formData.numberRuleId = { value: data?.numberRuleId, label: data?.numberRuleName };
      }

      if (data?.fieldType === appEnum.CustomField.FieldType.boolean) {
        defaultValue = defaultValue && Number(defaultValue);
      }

      modalForm?.setFieldsValue({
        ...formData,
        defaultValue,
        referenceChain: data?.referenceChainDisplay ? data.referenceChainDisplay.split('/') : [],
      });
      setId(data?.id);
      setDetail(data as Detail);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // 预处理字段数据，返回要提交的数据
  const preProcess = (value: any) => {
    if (fieldType === appEnum.CustomField.FieldType.select && !detail.isRefer) {
      if (!value.singleOptionList) {
        message.error('选项信息中选项至少添加一个');
        throw new Error('');
      }
      if (value.singleOptionList.length > 1000) {
        message.error(`选项信息中选项不可超过1000个,已超过${value.singleOptionList.length - 1000}`);
        throw new Error('');
      }
      value.choiceValueList = handleAssemblyChoiceValueList(
        value.singleOptionList,
        [value.defaultOption],
        appEnum.CustomField.FieldType.select,
        fieldId,
      );

      delete value.singleOptionList;
    }
    if (fieldType === appEnum.CustomField.FieldType.multiSelect && !detail.isRefer) {
      if (!value.mutipleOptionList) {
        message.error('选项信息中选项至少添加一个');
        throw new Error('');
      }
      if (value.mutipleOptionList.length > 1000) {
        message.error(`选项信息中选项不可超过1000个,已超过${value.singleOptionList.length - 1000}`);
        throw new Error('');
      }
      value.choiceValueList = handleAssemblyChoiceValueList(
        value.mutipleOptionList,
        [value.defaultOption],
        appEnum.CustomField.FieldType.multiSelect,
        fieldId,
      );

      delete value.mutipleOptionList;
    }

    if ('defaultOption' in value) {
      delete value.defaultOption;
    }

    let { defaultValue } = value;

    if ('datetimeFormat' in value && defaultValue) {
      defaultValue = moment(defaultValue).format(value.datetimeFormat);
    }

    return {
      id,
      fieldType,
      relatedObjectId: objectId,
      fieldCategory,
      ...value,
      isNumberRuleConfig: value.isNumberRuleConfig, // 是否配置编号规则，编辑不更新这个字段
      referenceChain: detail.referenceChain,
      defaultValue,
      isRefer: detail.isRefer,
      reference: value.reference?.value,
      numberRuleId:
        value.numberRuleId?.value && value.isNumberRuleConfig ? value.numberRuleId?.value : -1,
    };
  };

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();

      fetchCustomFieldUpdateCustomField(preProcess(value))
        .then((res) => {
          if (res && res.code === 200 && res.subCode === '403') {
            modal.warning({
              title: '提示',
              content: <>{res.message}</>,
              okText: '确定',
            });
          } else {
            message.success('编辑成功！');
            onCancel && onCancel();
            refreshTable();
            return true;
          }
        })
        .catch(() => {});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      width={DATA_FORM_MODAL_MIN_WIDTH}
      onClose={onCancel}
      content={
        <>
          {detail ? (
            <DataFormLayout
              form={modalForm}
              title="编辑字段"
              info={_.compact([baseInfo])}
              onCancel={onCancel}
              onFinish={handleFinish}
              formProps={{
                onValuesChange: (changedValues) => {
                  if (
                    fieldCategory === FieldCategory.customFields &&
                    _.isNumber(changedValues?.isRequired) &&
                    changedValues?.isRequired === appEnum.Common.YN.yes &&
                    !canSetIsRequired
                  ) {
                    Modal.warning({
                      title: '此对象暂时无法设置必填的自定义字段',
                      okText: '确定',
                      onOk: () => {
                        modalForm.setFieldsValue({ isRequired: appEnum.Common.YN.no });
                      },
                    });
                  }
                },
              }}
              bodyStyle={{ height: document.body.clientHeight - 210 }}
            />
          ) : (
            <Spin />
          )}
        </>
      }
    />
  );
}

export default EditComponent;
