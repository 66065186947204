import { fractionLengthCheck, urlRules } from 'src/utils/formValidators';
import type { Rule, RuleObject } from 'antd/lib/form';
import type { FieldDTO } from '../types';
import { FieldType } from 'src/dict/common';

/** 字段校验规则，用于编辑页 */
export const getRules = (field: FieldDTO) => {
  if (field.isRefer) {
    return undefined;
  }
  const result: Rule[] = [];

  if (field.isRequired && !field.isNumberRuleConfig) {
    result.push({ required: true, message: `${field.fieldName}必填` });
  }
  if (field.maxLength && !field.isNumberRuleConfig) {
    result.push({ type: 'string', max: field.maxLength, message: `最多${field.maxLength}个字符` });
  }
  if (field.maxValue) {
    result.push({ type: 'number', max: field.maxValue, message: `最大值${field.maxValue}` });
  }
  if (field.decimalNumber) {
    result.push({ validator: fractionLengthCheck(field.decimalNumber) });
  }
  if (field.fieldType === FieldType?.url) {
    result.push(urlRules);
  }
  if (field?.extInfo?.fileCount) {
    result.push({
      type: 'array',
      max: field?.extInfo?.fileCount,
      message: `只支持上传${field?.extInfo?.fileCount}个文件`,
    });
  }
  return result;
};
