import { useEffect, useState, useRef } from 'react';
import { Form, Checkbox, Button, Input, Select, message as Message } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { RouteComponentProps } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'src/store/models';
import { validateBlTextAndWhiteSpace } from 'src/page/knowledgeManagement/share/warehouseRules';
import {
  fetchDeliveryScheduleNoteUpdate,
  fetchDeliveryScheduleNoteCreate,
  fetchDeliveryScheduleNoteDetail,
  fetchDeliveryScheduleNoteItemDetail,
} from 'src/api/ytt/order-domain/deliveryScheduleNote';
import { BcAttachmentForm, SearchSelect, NumberRulesStandardHook } from 'src/components';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import _Time from 'src/utils/dataTypes/time';
import { DeliveryScheduleNoteExecStatus, DeliveryScheduleNoteSource } from 'src/dict/purchase';
import { deliveryScheduleNoteSource } from 'src/dict/purchase/mappings';
import { toList } from '../navigate';
import { SupplyRegStatus } from 'src/dict/supplier';
import { UsageStatus } from 'src/dict/common';
import MaterialFormTable from '../components/materialTable';
import _ from 'lodash';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { textValidator2 } from 'src/utils/formValidators';
import moment from 'moment';
import { formatDetailDataToForm, handleSwich } from '../utils';
import { FetchDeliveryScheduleNoteItemDetailResponseData } from '../interface';

const { TextArea } = Input;

interface OperatePurchaseOrderProps {
  id?: string;
}

const CreateAndEditDeliveryScheduleNote = (
  props: RouteComponentProps<OperatePurchaseOrderProps>,
) => {
  const { history, match } = props;

  // 正在保存数据，用来保存期间disable保存按钮
  const [isSaving, setIsSaving] = useState(false);
  // 是否连续新建
  const [continueToCreate, setContinueToCreate] = useState(true);
  const [orderStatus, setOrderStatus] = useState<number>(DeliveryScheduleNoteExecStatus.created);
  const [source, setSource] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const isCreating = history.location.pathname.includes('create');
  const isFromPurchaseOrder = history.location.pathname.includes('purchaseOrder');
  const currentId = Number(match.params.id);
  const formDataBefore = useRef<any>();
  const [initialMaterialLine, setInitialMaterialLine] =
    useState<FetchDeliveryScheduleNoteItemDetailResponseData>();
  const { deliveryScheduleNoteInfo } = useSelector(
    (state: RootState) => state?.supplyChain,
    shallowEqual,
  );

  const [form] = Form.useForm();

  const fetchData = async () => {
    try {
      setLoading(true);
      const [{ data }, { data: materialLine }] = await Promise.all([
        fetchDeliveryScheduleNoteDetail({ id: currentId! }),
        fetchDeliveryScheduleNoteItemDetail({ id: currentId! }),
      ]);

      setLoading(false);
      if (data) {
        const { supplier, ...rest } = data;

        setInitialMaterialLine(materialLine);
        setOrderStatus(data.execStatus as number);
        setSource(data.source as number);
        form.setFieldsValue({
          ...rest,
          supplier: { key: supplier?.id, value: supplier?.id, label: supplier?.name },
          itemList: formatDetailDataToForm(materialLine, supplier),
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    currentId && fetchData();
  }, [currentId]);

  useEffect(() => {
    if (isFromPurchaseOrder && deliveryScheduleNoteInfo?.itemList) {
      const { itemList } = deliveryScheduleNoteInfo;

      const newiItemList = itemList?.map((item: any, index: number) => {
        return {
          lineNo: index + 1,
          materialId: {
            value: JSON.stringify(item?.material),
            key: JSON.stringify(item?.material),
            label: item?.material?.baseInfo?.name,
          },
          deliveryDate: _Time.daysAfter(7),
          earliestArrivalTime: moment('2022-09-09 00:00:00'),
          latestArrivalTime: moment('2022-09-09 23:59:59'),
          purchaseOrderItemId: item.purchaseOrderItemId,
          purchaseOrderCode: item.purchaseOrderCode,
          purchaseOrderItemSeqNum: item.purchaseOrderItemSeqNum,
          supplier: item.supplier,
          demandAmount: item?.waitPlanDeliveryAmount?.amount || 0,
        };
      });

      form.setFieldsValue({
        source: DeliveryScheduleNoteSource.purchaseOrder,
        supplier: {
          value: itemList && itemList[0]?.supplier?.id,
          key: itemList && itemList[0]?.supplier?.id,
          label: itemList && itemList[0]?.supplier?.name,
        },
        itemList: newiItemList,
      });
      setSource(DeliveryScheduleNoteSource.purchaseOrder);
    }
  }, []);

  const handleFinish = _.throttle(async () => {
    try {
      // 表单校验
      await form.validateFields();

      const { supplier, owner, itemList, ...formData } = form.getFieldsValue();
      const formatItemList = itemList.map((value: any, index: number) => {
        const materialObj = JSON.parse(value.materialId.value);

        return {
          deliveryDate: value?.deliveryDate?.valueOf(),
          earliestArrivalTime: value?.earliestArrivalTime?.valueOf(),
          latestArrivalTime: value?.latestArrivalTime?.valueOf(),
          materialId:
            source === DeliveryScheduleNoteSource.purchaseOrder
              ? materialObj?.baseInfo.id
              : materialObj?.materialId,
          lineNo: index + 1,
          logisticsCycle: value?.logisticsCycle ? Number(value?.logisticsCycle) : null,
          logisticsTimeUnit: value?.logisticsTimeUnit,
          purchaseOrderItemId: value?.purchaseOrderItemId,
          demandAmount: value?.demandAmount,
        };
      });
      const requestParam = {
        id: currentId,
        supplierId: supplier.value,
        ownerId: owner.id,
        itemList: formatItemList,
        execStatus: orderStatus,
        ...formData,
      };

      const requestFn = isCreating
        ? fetchDeliveryScheduleNoteCreate
        : fetchDeliveryScheduleNoteUpdate;

      await requestFn(requestParam);
      Message.success('保存成功');
      // 连续新建则清空表单
      if (continueToCreate && isCreating) {
        form.resetFields();
        form.setFieldsValue({
          source: DeliveryScheduleNoteSource.noSource,
          itemList: [
            {
              lineNo: 1,
              deliveryDate: _Time.daysAfter(7),
              earliestArrivalTime: moment('2022-09-09 00:00:00'),
              latestArrivalTime: moment('2022-09-09 23:59:59'),
            },
          ],
        });
        setSource(DeliveryScheduleNoteSource.noSource);
      } else {
        // 否则返回列表
        history.push(toList());
      }
    } finally {
      setIsSaving(false);
    }
  }, 3000);

  const extra = (
    <>
      {isCreating && (
        <Checkbox
          onChange={() => {
            setContinueToCreate(!continueToCreate);
          }}
          defaultChecked={continueToCreate}
        >
          连续新建
        </Checkbox>
      )}
    </>
  );

  // 修改原因字段
  const editReasonFormItems = {
    label: '编辑原因',
    name: 'editReason',
    rules: [
      { max: 255, message: '不可超过255个字符' },
      { validator: validateBlTextAndWhiteSpace() },
    ],
    render: () => <TextArea showCount maxLength={255} placeholder="非必填" allowClear />,
  };

  const sourceChange = (val: number) => {
    function handleOk() {
      setSource(val);
      if (val === DeliveryScheduleNoteSource.noSource) {
        form.setFieldsValue({
          itemList: [
            {
              lineNo: 1,
              deliveryDate: _Time.daysAfter(7),
              earliestArrivalTime: moment('2022-09-09 00:00:00'),
              latestArrivalTime: moment('2022-09-09 23:59:59'),
            },
          ],
        });
      } else {
        form.setFieldsValue({
          itemList: [],
        });
      }
    }
    function handleCancel() {
      form.setFieldsValue(formDataBefore.current);
    }
    const content = '切换来源后将会清空物料信息，是否确认切换？';
    const hasValue = () => {
      // 供应商和物料行 是否有值
      const source = !_.isUndefined(formDataBefore.current?.source);
      const materials = !_.isUndefined(formDataBefore.current?.itemList?.[0]?.materialId);

      return source && materials;
    };

    if (hasValue()) {
      handleSwich(content, handleOk, handleCancel);
    } else {
      handleOk();
    }
  };
  const supplierChange = () => {
    function handleOk() {
      form.setFieldsValue({
        itemList: [],
      });
    }
    function handleCancel() {
      form.setFieldsValue(formDataBefore.current);
    }
    const content = '切换供应商后将会清空物料信息，是否确认切换？';
    const hasValue = () => {
      // 供应商和物料行 是否有值
      const source = !_.isUndefined(formDataBefore.current?.source);
      const supplier = !_.isUndefined(formDataBefore.current?.supplier);
      const materials = !_.isUndefined(formDataBefore.current?.itemList?.[0]?.materialId);

      return source && supplier && materials;
    };

    if (form.getFieldValue('source') === DeliveryScheduleNoteSource.purchaseOrder && hasValue()) {
      handleSwich(content, handleOk, handleCancel);
    }
  };
  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    column: 2,
    items: [
      ...NumberRulesStandardHook({
        label: '编号',
        form,
        edit: !isCreating,
        objectCode: OBJECT_OF_CODE.deliveryScheduleNote,
        fieldCode: 'code',
        disabledState: !isCreating,
        rules: [{ max: 255, message: '不超过255字符' }, { validator: textValidator2 }],
      }),
      {
        label: '来源',
        name: 'source',
        rules: [{ required: true, message: '请选择交货计划单来源' }],
        render: () => (
          <Select
            options={deliveryScheduleNoteSource}
            placeholder="请选择"
            allowClear
            disabled={!isCreating}
            onMouseEnter={() => {
              formDataBefore.current = form.getFieldsValue();
            }}
            onChange={(val) => sourceChange(val as number)}
          />
        ),
      },
      {
        label: '供应商',
        name: 'supplier',
        rules: [{ required: true, message: '请选择供应商' }],
        render: () => (
          <SearchSelect
            fetchType={'supplier'}
            style={{ width: '100%' }}
            labelInValue
            params={{ regStatus: [SupplyRegStatus.registered], status: [UsageStatus.enabled] }}
            disabled={orderStatus !== DeliveryScheduleNoteExecStatus.created}
            onMouseEnter={() => {
              formDataBefore.current = form.getFieldsValue();
            }}
            onChange={supplierChange}
          />
        ),
      },
      {
        label: '交货单所有人',
        name: 'owner',
        rules: [{ required: true, message: '请选择交货计划单所有人' }],
        getValueProps: (value?: any) => {
          if (value) {
            return { value: [_.pick(value, ['id', 'name'])] };
          }
          return {};
        },
        getValueFromEvent: (value) => {
          return value ? value[0] : '';
        },
        render: () => (
          <UserOrDepartmentSelectWithDialog<UserColumnProps>
            isNewFormat
            isMultiple={false}
            isSelectUser
            disabled={orderStatus !== DeliveryScheduleNoteExecStatus.created}
          />
        ),
      },
      {
        label: '附件',
        name: 'attachments',
        render: () => <BcAttachmentForm form={form} />,
      },
      {
        label: '协同附件',
        name: 'coordinationAttachments',
        render: () => (
          <BcAttachmentForm
            disabled={
              orderStatus !== DeliveryScheduleNoteExecStatus.created &&
              orderStatus !== DeliveryScheduleNoteExecStatus.rejected
            }
            form={form}
          />
        ),
      },
      {
        label: '备注',
        name: 'remark',
        rules: [
          { max: 1000, message: '不超过1000个字符' },
          { validator: validateBlTextAndWhiteSpace() },
        ],
        render: () => <TextArea showCount rows={2} maxLength={1000} allowClear />,
      },
      ...(!isCreating ? [editReasonFormItems] : []),
    ],
  };
  const materialInfo: DataFormLayoutInfoBlock = {
    title: '物料信息',
    items: [
      {
        label: '',
        isFullLine: true,
        rules: [{ required: true, message: '请选择物料' }],
        render: () => (
          <MaterialFormTable
            form={form}
            orderStatus={orderStatus}
            name="itemList"
            edit={!isCreating}
            source={source}
            initialMaterialLine={initialMaterialLine}
          />
        ),
      },
    ],
  };
  const footer = (
    <>
      <Button
        onClick={() => {
          history.push(toList());
        }}
      >
        取消
      </Button>
      <Button type="primary" loading={isSaving} onClick={handleFinish}>
        保存
      </Button>
    </>
  );

  return (
    <DataFormLayout
      form={form}
      title={`${isCreating ? '新建' : '编辑'}交货计划单`}
      info={[baseInfo, materialInfo]}
      footer={footer}
      extra={isCreating && extra}
      loading={loading}
    />
  );
};

export default CreateAndEditDeliveryScheduleNote;
