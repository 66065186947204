import { AllowType, NoticeType, RemindType } from '.';
import { mappingsFactory } from '../utils';

export const RemindMap = mappingsFactory(
  // ['提示音', RemindType.prompt],
  // ['震动(仅APP)', RemindType.vibration],
  // ['语音播报', RemindType.voice],
  ['紧急通知', RemindType.urgent],
);

export const NoticeMap = mappingsFactory(['邮件', NoticeType.mail], ['飞书', NoticeType.feiShu]);

export const AllowMap = mappingsFactory(
  ['允许拒收', AllowType.allow],
  ['不允许拒收', AllowType.unAllow],
);
