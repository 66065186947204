import { RecordListLayout } from 'layout';
import React, { useState } from 'react';
import { TextToolTip } from '@blacklake-web/component';
import { Image } from 'antd';
import { fieldTypeList } from 'utils';
import {
  fetchInventoryElementListBatchSummary,
  FetchInventoryElementListBatchSummaryRequest,
} from 'src/api/ytt/inventory-domain';
import { SearchSelect } from 'src/components';
import { mappingMaterialInfoAttrList } from 'src/page/warehouseManagement/utils';
import _ from 'lodash';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import authDict, { hasAuth } from 'src/utils/auth';
import { intersectionValueOfId } from 'src/utils/array';
import MaterialAttributeCascader, {
  formatMaterialAttributeToQuery,
} from 'src/page/knowledgeManagement/materialModeling/material/share/Cascader/BcMultiCascader';
import BcBlCascader from 'src/components/Cascader';
import BatchDetail from 'src/page/warehouseManagement/batchManagement/batchDetail';
//
import { BatchSummaryRecord } from '../../batchManagement/type';
import { valueOrHolder } from 'src/utils/formatters';

interface Params {
  page: number;
  size: number;
  quickSearch?: string;
  selectFlag?: string;
  materialInfo?: string;
  materialCode?: string;
  batchNo?: string;
}

const BatchList = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [batchRecord, setBatchRecord] = useState<BatchSummaryRecord | undefined>(undefined);
  const [recordList, setRecordList] = useState<BatchSummaryRecord[]>();

  const batchNo = batchRecord?.batchNo;
  const materialId = batchRecord?.materialInfo?.materialId;

  const fetchFn = (params: FetchInventoryElementListBatchSummaryRequest) => {
    return fetchInventoryElementListBatchSummary(params).then((res) => {
      const list = res.data?.list;

      setRecordList(list);

      return res;
    });
  };

  const getUnitItem = (type: 'AUX' | 'CONVERSION') => {
    if (_.isEmpty(recordList)) return [];

    if (type === 'AUX') {
      // 当页数据中，最多的辅助单位数量
      const maxAuxItem = _.maxBy(recordList, (o) => o?.bizInfo?.opAmount?.auxAmounts?.length);

      return _.map(maxAuxItem?.bizInfo?.opAmount?.auxAmounts, (item: any, i: number): any => {
        return {
          title: `辅助数量${i + 1}`,
          dataIndex: `auxAmount_${i}`,
          defaultColConfig: {
            display: false,
          },
          width: 120,
          render: (value: any, record: BatchSummaryRecord) => {
            const { amount, unit = {} } = (record.bizInfo?.opAmount?.auxAmounts ?? [])[i] || {};

            return valueOrHolder(amount, () => `${amount} ${unit?.name}`);
          },
        };
      });
    }

    if (type === 'CONVERSION') {
      // 当页数据中，最多的转换单位数量
      const maxConversionItem = _.maxBy(
        recordList,
        (o) => o?.bizInfo?.opAmount?.conversionAmounts?.length,
      );

      return _.map(
        maxConversionItem?.bizInfo?.opAmount?.conversionAmounts,
        (item: any, i: number): any => {
          return {
            title: `转换数量${i + 1}`,
            dataIndex: `conversionAmount_${i}`,
            defaultColConfig: {
              display: false,
            },
            width: 120,
            render: (value: any, record: BatchSummaryRecord) => {
              const { amount, unit = {} } = record.bizInfo?.opAmount?.conversionAmounts?.[i] || {};

              return valueOrHolder(amount, () => `${amount} ${unit?.name}`);
            },
          };
        },
      );
    }

    return [];
  };

  const dataColumns = [
    {
      title: '物料名称',
      dataIndex: 'materialName',
      filterIndex: 'materialNameIds',
      width: 150,
      sorter: true,
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="material"
          labelPath="name"
          searchFieldName="name"
          mode="multiple"
        />
      ),
    },
    {
      title: '物料编码',
      dataIndex: 'materialCode',
      filterIndex: 'materialCodeIds',
      width: 150,
      sorter: true,
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="material"
          labelPath="code"
          searchFieldName="code"
          mode="multiple"
        />
      ),
    },
    {
      title: '物料属性',
      dataIndex: ['materialInfo', 'materialAttrs'],
      width: 150,
      render: mappingMaterialInfoAttrList,
    },
    {
      title: '物料分类',
      dataIndex: ['materialInfo', 'categoryName'],
      filterIndex: 'materialCategoryIds',
      width: 150,
      isFilter: true,
      defaultColConfig: {
        display: false,
      },
      renderItem: (
        <BcBlCascader
          fetchType={'materialCategory'}
          nameLabel={'物料分类'}
          popupPlacement={'bottomRight'}
        />
      ),
    },
    {
      title: '物料规格',
      dataIndex: ['materialInfo', 'specification'],
      filterIndex: 'materialSpecificationIds',
      width: 150,
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="material"
          labelPath="specification"
          searchFieldName="specification"
          mode="multiple"
        />
      ),
    },
    {
      title: '物料图片',
      dataIndex: ['materialInfo', 'documents'],
      width: 150,
      defaultColConfig: {
        display: false,
      },
      render: (val: any) => {
        return _.isEmpty(val) ? (
          '-'
        ) : (
          <>
            {val.map((item: any) => (
              <div style={{ margin: '0 4px', display: 'inline-block' }} key={item.documentId}>
                <Image preview={{}} width={30} height={30} src={item.documentPath} />
              </div>
            ))}
          </>
        );
      },
    },
    {
      title: '备注',
      dataIndex: ['materialInfo', 'remark'],
      width: 150,
      defaultColConfig: {
        display: false,
      },
    },
    {
      title: '批次号',
      dataIndex: 'batchNo',
      width: 150,
      sorter: true,
      type: fieldTypeList.text,
      isFilter: true,
      render: (curTarget: any, record: any, index: number, config: any) => {
        if (curTarget && hasAuth(authDict.batchno_detail)) {
          return (
            <a
              onClick={() => {
                setVisible(true);
                setBatchRecord(record);
              }}
            >
              <TextToolTip text={curTarget} width={config.contentWidth} />
            </a>
          );
        }
        return <div>{curTarget || replaceSign}</div>;
      },
    },
    {
      title: '数量',
      dataIndex: ['bizInfo', 'opAmount', 'amount', 'amount'],
      width: 100,
    },
    {
      title: '仓储单位',
      dataIndex: ['bizInfo', 'opAmount', 'amount', 'unit', 'name'],
      width: 150,
    },
    ...getUnitItem('CONVERSION'),
    ...getUnitItem('AUX'),
    {
      title: '供应商',
      dataIndex: ['bizInfo', 'batchAttr', 'supplierName'],
      width: 150,
    },
    {
      title: '供应商批次',
      dataIndex: ['bizInfo', 'batchAttr', 'supplierBatch'],
      width: 150,
    },
    {
      title: '客户',
      dataIndex: ['bizInfo', 'batchAttr', 'customer'],
      width: 150,
    },
    {
      title: '生产日期',
      dataIndex: ['bizInfo', 'batchAttr', 'productAt'],
      width: 180,
      render: (value: number) => (value ? _Time.formatDate(value) : replaceSign),
    },
    {
      title: '有效期至',
      dataIndex: ['bizInfo', 'batchAttr', 'validTo'],
      width: 180,
      render: (value: number) => (value ? _Time.formatDate(value) : replaceSign),
    },
  ];
  const filterList = dataColumns
    .filter((i) => i?.isFilter)
    .map((columns: any) => ({
      ...columns,
      label: columns?.label ?? columns.title,
      name: columns?.filterIndex ?? columns.dataIndex,
    }));

  const formatDataToQuery = (params: any) => {
    const { materialCodeIds, materialNameIds, materialSpecificationIds, batchNo, ...rest } = params;

    const _params: Params = {
      ...rest,
      materialIds: intersectionValueOfId(
        materialCodeIds,
        materialNameIds,
        materialSpecificationIds,
      ),
      batchNo,
    };

    return _params;
  };

  const formatDataToDisplay = (params: any) => {
    return params;
  };

  return (
    <>
      <RecordListLayout
        rowKey="id"
        configcacheKey="inventoryManagement_batch_list"
        columns={_.compact(dataColumns)}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={formatDataToDisplay}
        filterList={filterList}
        getOperationList={() => []}
        requestFn={fetchFn}
      />
      {visible && (
        <BatchDetail
          batchNo={batchNo as string}
          materialId={materialId as number}
          visible={visible}
          refreshDetailsMarker={undefined}
          onClose={() => setVisible(false)}
        />
      )}
    </>
  );
};

export default BatchList;
