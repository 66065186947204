import { Graph } from '@antv/x6';
Graph.registerEdge(
  'custom-edge',
  {
    inherit: 'edge',
    labels: [
      {
        markup: [
          {
            tagName: 'rect',
            selector: 'body',
          },
          {
            tagName: 'text',
            selector: 'label',
          },
        ],
        attrs: {
          label: {
            fill: '#000',
            fontSize: 14,
            textAnchor: 'middle',
            textVerticalAnchor: 'middle',
            pointerEvents: 'none',
          },
          body: {
            ref: 'label',
            refWidth: '140%',
            refHeight: '140%',
            refX: '-20%',
            refY: '-20%',
            fill: '#fff',
            strokeWidth: 2,
            stroke: '#000',
            rx: 5,
            ry: 5,
          },
        },
        position: {
          distance: 0.7,
          options: {
            absoluteDistance: true,
            reverseDistance: true,
          },
        },
      },
      {
        markup: [
          {
            tagName: 'rect2',
            selector: 'body',
          },
          {
            tagName: 'text',
            selector: 'label',
          },
        ],
        attrs: {
          label: {
            fill: '#000',
            fontSize: 14,
            textAnchor: 'middle',
            textVerticalAnchor: 'middle',
            pointerEvents: 'none',
          },
          body: {
            ref: 'label',
            refWidth: '140%',
            refHeight: '140%',
            refX: '-20%',
            refY: '-20%',
            stroke: '#000',
            fill: '#fff',
            strokeWidth: 2,
            rx: 5,
            ry: 5,
          },
        },
        position: {
          distance: 0.2,
          options: {
            absoluteDistance: true,
            reverseDistance: true,
          },
        },
      },
    ],
  },
  true,
);
