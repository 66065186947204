import { FilterItem, RecordListLayout } from 'layout';
import { BlIcon, LinkTooltip, SearchSelect, TagList } from 'components';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { RouteComponentProps } from 'react-router-dom';
import { PRODUCTION_PLANNING_PATH } from 'src/page/productionPlanning/navigation';
import { BlColumnType } from '@blacklake-web/component/dist/BlTable/BlTable.type';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import { renderUser } from 'src/page/share/renderUser';
import { fieldTypeList, gcObject, gcTime } from 'utils';
import { useDispatch } from 'react-redux';
import {
  fetchMaterialFeedFeedRecordList,
  FetchMaterialFeedFeedRecordListResponse,
} from 'src/api/ytt/mfg-domain/productionTask';
import React from 'react';
import _ from 'lodash';
import lookup, { appDict } from 'src/dict';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import { intersectionValueOfId } from 'src/utils/array';
import authDict from 'src/utils/auth/authDict';

type RecordType = Exclude<
  FetchMaterialFeedFeedRecordListResponse['data'],
  undefined
>['list'][number];

interface Props extends RouteComponentProps {}

const FeedingRecordList = (props: Props) => {
  const { history } = props;
  const openModal = useOpenExImportModal();
  const toFeedingRecordDetail = (id?: number, detailId?: number) => {
    history.push(`${PRODUCTION_PLANNING_PATH}/feedingRecord/detail?id=${id}&detailId=${detailId}`);
  };
  const dispatch = useDispatch();
  const columns: BlColumnType<RecordType>[] = [
    {
      title: '生产任务编号',
      dataIndex: 'taskCode',
      width: 150,
      render: (text: string, record: any, index: number, config: any) => (
        <LinkTooltip
          to={`/productionPlanning/execution/productionTaskList/${record?.taskId}/detail`}
          text={text}
          width={config.contentWidth}
          auth={authDict.producetask_detail}
        />
      ),
    },
    {
      title: '工序编号',
      dataIndex: ['processInfo', 'code'],
      width: 150,
    },
    {
      title: '工序名称',
      dataIndex: ['processInfo', 'name'],
      width: 150,
    },
    {
      title: '物料编号',
      dataIndex: ['materialInfo', 'baseInfo', 'code'],
      width: 150,
    },
    {
      title: '物料名称',
      dataIndex: ['materialInfo', 'baseInfo', 'name'],
      width: 150,
    },
    {
      title: '物料分类',
      dataIndex: ['materialInfo', 'category', 'name'],
      width: 150,
    },
    {
      title: '物料属性',
      dataIndex: ['materialInfo', 'attribute'],
      width: 150,
      render: (value) => {
        if (_.isEmpty(value)) return replaceSign;
        const options = value.map((item: any) => ({
          label: `${item.name}:${item.content}`,
          value: item.id,
          key: item.id,
        }));

        return <TagList dataSource={options} />;
      },
    },
    {
      title: '物料规格',
      dataIndex: ['materialInfo', 'baseInfo', 'specification'],
      width: 150,
    },
    {
      title: '投料数量',
      dataIndex: ['feedTotalAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '已回撤数量',
      dataIndex: ['retractAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '实际投料数量',
      dataIndex: ['feedAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '投料单位',
      dataIndex: ['feedAmount', 'unitName'],
      width: 150,
    },
    {
      title: '辅助单位数量',
      dataIndex: ['feedAuxAmount1', 'amountDisplay'],
      width: 150,
    },
    {
      title: '辅助单位',
      dataIndex: ['feedAuxAmount1', 'unitName'],
      width: 150,
    },
    {
      title: '标识码',
      dataIndex: 'inventoryIdentifier',
      width: 150,
    },
    {
      title: '批次号',
      dataIndex: 'batchNo',
      width: 150,
    },
    {
      title: '质量状态',
      dataIndex: ['qualityStatus'],
      width: 150,
      render: (value: number) => lookup('material.feedingQualityStatus', value) || replaceSign,
    },
    {
      title: '投料仓位',
      dataIndex: ['locationInfo', 'location', 'name'],
      width: 200,
    },
    {
      title: '被替代物料编号',
      dataIndex: ['originalMaterial', 'code'],
      width: 150,
    },
    {
      title: '被替代物料名称',
      dataIndex: ['originalMaterial', 'name'],
      width: 150,
    },
    {
      title: '投料时间',
      dataIndex: 'feedTime',
      width: 200,
      sorter: true,
      render: (time: Date) => {
        if (!time) return replaceSign;
        return _Time.format(time);
      },
    },
    {
      title: '执行人员',
      dataIndex: 'execUser',
      width: 150,
      render: (value) => (value ? renderUser(value) : replaceSign),
    },
    {
      title: '投料人员',
      dataIndex: 'feedUser',
      width: 150,
      render: (value) => (value ? renderUser(value) : replaceSign),
    },
    {
      title: '工单编号',
      dataIndex: 'workOrderCode',
      width: 200,
    },
    {
      title: '主产出物料编号',
      dataIndex: ['workOrderOutputMaterial', 'code'],
      width: 150,
    },
    {
      title: '主产出物料名称',
      dataIndex: ['workOrderOutputMaterial', 'name'],
      width: 150,
    },
    {
      title: '销售订单编号',
      dataIndex: 'salesOrders',
      width: 150,
      render: (value) => {
        if (!value) return replaceSign;
        return (
          <TagList
            dataSource={value.map((item: { code: string; id: number }) => ({
              label: item.code,
              value: item.id,
              key: item.id,
            }))}
          />
        );
      },
    },
    {
      title: '投料记录编号',
      dataIndex: ['feedRecordCode'],
      width: 150,
    },
  ];
  const filterList: FilterItem[] = [
    {
      label: '生产任务编号',
      name: 'quickSearch',
      type: fieldTypeList.text,
    },
    {
      label: '工序编号',
      name: 'processDefIdListCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect fetchType={'process'} labelPath="code" valuePath="id" mode="multiple" />
      ),
    },
    {
      label: '工序名称',
      name: 'processDefIdListName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect fetchType={'process'} labelPath="name" valuePath="id" mode="multiple" />
      ),
    },
    {
      label: '物料编号',
      name: 'materialIdListCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="code"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '物料名称',
      name: 'materialIdListName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="name"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '标识码',
      name: 'inventoryIdentifier',
      type: fieldTypeList.text,
    },
    {
      label: '批次号',
      name: 'batchNo',
      type: fieldTypeList.text,
    },
    {
      label: '质量状态',
      name: 'qualityStatus',
      type: fieldTypeList.multiSelect,
      selectProps: {
        options: appDict.material.feedingQualityStatus,
      },
    },
    {
      label: '投料仓位',
      name: 'opeLocations',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType="storageLocation"
          labelPath="name"
          valuePath="id"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '被替代物料编号',
      name: 'originalMaterialIdListCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="code"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '被替代物料名称',
      name: 'originalMaterialIdListName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="name"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '投料时间',
      name: 'feedTime',
      type: fieldTypeList.date,
    },
    {
      label: '执行人员',
      name: 'execUsers',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
    {
      label: '投料人员',
      name: 'feedUsers',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
    {
      label: '工单编号',
      name: 'workOrderIds',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType={'workOrderFilterList'} mode="multiple" />,
    },
    {
      label: '主产出物料编号',
      name: 'workOrderOutputMaterialIdListCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="code"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '主产出物料名称',
      name: 'workOrderOutputMaterialIdListName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="code"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '销售订单编号',
      name: 'salesOrderIds',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType="salesOrderListSimple" mode="multiple" />,
    },
  ];
  const formatDataToQuery: any = (params: any) => {
    const {
      processDefIdListCode,
      processDefIdListName,
      materialIdListCode,
      materialIdListName,
      opeLocations,
      originalMaterialIdListCode,
      originalMaterialIdListName,
      feedTime,
      execUsers,
      feedUsers,
      workOrderIds,
      workOrderOutputMaterialIdListCode,
      workOrderOutputMaterialIdListName,
      salesOrderIds,
      ...rest
    } = params;
    const [feedTimeFrom, feedTimeTo] = gcTime.formatTimeRangeUnix(feedTime);
    const relParams = {
      ...rest,
      processDefIds: intersectionValueOfId(processDefIdListCode, processDefIdListName),
      materialIds: intersectionValueOfId(materialIdListCode, materialIdListName),
      opeLocations: _.isEmpty(opeLocations) ? null : opeLocations.map((item: any) => item.value),
      originalMaterialIds: intersectionValueOfId(
        originalMaterialIdListCode,
        originalMaterialIdListName,
      ),
      feedTimeFrom: feedTimeFrom && Number(feedTimeFrom),
      feedTimeTo: feedTimeTo && Number(feedTimeTo),
      execUsers: _.isEmpty(execUsers) ? null : execUsers.map((item: any) => item.value),
      feedUsers: _.isEmpty(feedUsers) ? null : feedUsers.map((item: any) => item.value),
      workOrderIds: _.isEmpty(workOrderIds) ? null : workOrderIds.map((item: any) => item.value),
      workOrderOutputMaterialIds: intersectionValueOfId(
        workOrderOutputMaterialIdListCode,
        workOrderOutputMaterialIdListName,
      ),
      salesOrderIds: _.isEmpty(salesOrderIds) ? null : salesOrderIds.map((item: any) => item.value),
    };

    dispatch.excelImport.updateBusinessData({
      businessData: gcObject.filterEmptyProperty(relParams),
    });
    return gcObject.filterEmptyProperty(relParams);
  };
  const formatDataToFormDisplay = (data: any) => {
    const { feedTime, ...rest } = data;

    return {
      ...rest,
      feedTime: feedTime && gcTime.formatRangeTimeToMoment(feedTime),
    };
  };

  return (
    <RecordListLayout
      placeholder="请输入生产任务编号"
      mainMenu={[
        {
          title: '导出',
          showExport: true,
          auth: authDict.materialfeedrecord_export,
          icon: <BlIcon type="icondaochu" />,
          onClick: () => {
            openModal({
              optType: 'export',
              businessType: BUSINESS_TYPE.feedingRecord,
            });
          },
        },
      ]}
      requestFn={(params) =>
        fetchMaterialFeedFeedRecordList({
          ...params,
          sorter: params?.sorter ? params?.sorter : [{ field: 'feedTime', order: 'desc' }], // 后端不好写 前端给的默认排序
        })
      }
      columns={columns}
      getOperationList={(record: RecordType) => [
        {
          title: '查看',
          auth: authDict.materialfeedrecord_detail,
          onClick: () => toFeedingRecordDetail(record.id, record.detailId),
        },
      ]}
      formatDataToQuery={formatDataToQuery}
      formatDataToFormDisplay={formatDataToFormDisplay}
      filterList={filterList}
      configcacheKey="feedingRecordModule"
    />
  );
};

export default FeedingRecordList;
