/**
 * 多个tag集合展示，超出后弹窗展示
 */

import React from 'react';
import { Button, Popover, Tag, Tooltip } from 'antd';
import { TextToolTip } from '@blacklake-web/component';
import _ from 'lodash';
import { replaceSign } from 'src/utils/constants';
import { AvatarDisplay } from '../avatar/show';

interface TagListProps {
  dataSource: {
    [key: string]: any;
  }[];
  /**
   * 唯一值字段的路径
   * @default value
   */
  valuePath?: string;
  /**
   * 展示字段的路径
   * @default label
   */
  labelPath?: string;
  /**
   * 最多展示的tag数量，超出弹窗展示
   * @default 3
   */
  maxShow?: number;
  moreText?: string;
  /**
   * 单个tag最大展示文本长度
   */
  maxText?: number;
  isUserTag?: boolean;
}

function TagList(props: TagListProps) {
  const {
    dataSource,
    valuePath = 'value',
    labelPath = 'label',
    maxShow = 3,
    moreText = '···',
    maxText = 15,
    isUserTag,
  } = props;

  const renderTagList = () => {
    if (_.isEmpty(dataSource)) return replaceSign;

    return _.map(
      _.filter(dataSource, (t, index) => index < maxShow),
      (item) => {
        return isUserTag ? (
          item && (
            <AvatarDisplay key={_.get(item, valuePath)} id={item.id} name={item?.name} {...item} />
          )
        ) : (
          <Tag key={_.get(item, valuePath)}>
            <TextToolTip text={_.get(item, labelPath)} length={maxText} />
          </Tag>
        );
      },
    );
  };

  const renderMore = () => {
    if (dataSource.length > maxShow)
      return (
        <Popover
          content={
            <div style={{ maxWidth: 208, maxHeight: 105, overflowY: 'auto' }}>
              {_.map(
                _.filter(dataSource, (t, index) => index >= maxShow),
                (item) => `${_.get(item, labelPath)}；`,
              )}
            </div>
          }
        >
          {moreText}
        </Popover>
      );

    return null;
  };

  return (
    <div>
      {renderTagList()}
      {renderMore()}
    </div>
  );
}

export default TagList;
