import React, { useState, useEffect } from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import _ from 'lodash';
import { DetailBaseInfo } from './detailBaseInfo';
import { fetchPurchaseAuditDetail } from 'src/api/ytt/supply-chain-domain/purchase_audit';
import { handleAccept, handleReject } from '../list';
import { PurchaseRequisitionStatus } from 'src/dict/sales';
import authDict from 'src/utils/auth';
import { PurchaseOrderCrossCustomFields } from '../../interface';
import { fetchCustomFieldCrossColumns } from '../../utils';

interface DetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const PurchaseRequisitionrDetailPage = (props: DetailProps) => {
  const { match } = props;
  const purchaseId = Number(match?.params?.id);
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [dataSource, setDataSource] = useState({} as any);
  // 跨租户 采购订单的自定义字段
  const [crossCustomFields, setCrossCustomFields] = useState<PurchaseOrderCrossCustomFields>();

  /**
   * 删除操作
   */
  // const handleDelete = async () => {
  //   try {
  //     const value = await delForm?.validateFields();

  //     const { data } = await fetchSalesOrderDelete({ ids: [purchaseId] });

  //     if (data) {
  //       message.success('删除成功');
  //       history.push(toList());
  //       return;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const fetchData = async () => {
    try {
      const { data } = await fetchPurchaseAuditDetail({ id: purchaseId });
      const crossCustomFieldsColumns = await fetchCustomFieldCrossColumns(data?.customerId);

      setDataSource(data);
      setCrossCustomFields(crossCustomFieldsColumns);
    } catch (err) {
      console.log(err);
    }
  };
  /**
   * 详情操作
   */
  const baseMenu = _.compact([
    dataSource?.status === PurchaseRequisitionStatus.pending && {
      key: 'reject',
      auth: authDict.purchaseauditnote_decline,
      onClick: () => {
        handleReject(dataSource, fetchData);
      },
      // icon: <BlIcon type="icontingyong" />,
      title: '拒绝',
    },
    dataSource?.status === PurchaseRequisitionStatus.pending && {
      key: 'accept',
      auth: authDict.purchaseauditnote_accept,
      onClick: () => {
        handleAccept(dataSource, fetchData);
      },
      title: '接受',
    },
    // {
    //   key: 'delete',
    //   onClick: () => {
    //     BLDelConfirm('删除后将无法恢复，确认删除？', delForm, () => {
    //       handleDelete();
    //     });
    //   },
    //   icon: <BlIcon type="iconshanchulajitong1" />,
    //   title: '删除',
    // },
    {
      key: 'log',
      auth: authDict.purchaseauditnote_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      icon: <BlIcon type="iconcaozuojilu" />,
      title: '操作记录',
    },
  ]);

  /**
   * 获取自定义字段可见性配置
   */

  useEffect(() => {
    fetchData();
  }, [purchaseId]);

  return (
    <>
      <DetailBaseInfo
        detail={dataSource}
        baseMenu={baseMenu}
        crossCustomFields={crossCustomFields}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={purchaseId}
          objectCode={'PurchaseAuditNote'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default PurchaseRequisitionrDetailPage;
