export const BASE_URL = '/systemManagement/numberRulesList';

export const toNumberDetail = (id: number) => {
  return `${BASE_URL}/${id}/detail`;
};

export const toNumberRuleList = () => {
  return `${BASE_URL}`;
};

export const toNumberEdit = (id: number) => {
  return `${BASE_URL}/${id}/edit`;
};
export const toNumberCreate = () => {
  return `${BASE_URL}/create`;
};
