import React from 'react';
import { DataFormLayoutForModal } from 'layout';
import InfoForm from './infoForm';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {
  visible: Boolean;
  onCancel: () => void;
  refreshTable?: () => void;
  refreshDetails?: () => void;
  id?: number | undefined;
}

const CreateAndEdit = (props: Props) => {
  const { visible, ...rest } = props;

  return (
    <DataFormLayoutForModal
      // 打开方式：弹窗
      visible={Boolean(visible)}
      width={1200}
      content={<InfoForm {...rest} />}
      onClose={props?.onCancel}
    />
  );
};

export default CreateAndEdit;
