import React, { useState, useEffect, ReactElement } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { BlIcon } from '@blacklake-web/component';
import { Form, message, Space } from 'antd';
import _ from 'lodash';

import { RecordListLayout } from 'src/layout';
import { fieldTypeList, gcArray, gcTime } from 'src/utils';
import {
  fetchInstrumentList,
  // fetchInstrumentDelete,
  fetchInstrumentStatusEnable,
  fetchInstrumentStatusStop,
  fetchInstrumentStatusScrap,
} from 'src/api/ytt/resource-domain/energyMeter';
import { valueOrHolder } from 'src/utils/formatters';

import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import TagTooltip from 'src/components/tooltip/tagTooltip';
import {
  SingleOperationLogComponent,
  SearchSelect,
  TagList,
  LinkTooltip,
  BlColumnsType,
} from 'src/components';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { ObjectCode, UsageStatus } from 'src/dict/common';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { RootState } from 'src/store';
import { appDict, appEnum, lookup } from 'src/dict';
import { renderUser } from 'src/page/share/renderUser';
import renderQrCode from 'src/page/share/renderQrCode';
import { mapLabeledValueToValue } from 'src/utils/array';
import BLDelConfirm from '../../knowledgeManagement/share/modalConfirm';
import type { EnergyMeterListItemType } from './index.d';
import { goToCreate, goToEdit, goToCopy, goToDetail, toDetail } from './navigates';
import UseAgeFilter from '../definitions/components/UseAgeFilter';
import EnergyMeterStatus from './components/EnergyMeterStatus';
import { formatDateTimeRangeToUnix } from 'src/utils/formatters/dateTime';
import ResourceTagCascader from '../definitions/components/ResourceTagCascader';
import ResourceClassificationCascader from '../components/ResourceClassificationCascader';
import { replaceSign } from 'src/utils/constants';

interface EnergyMeterListProps {
  refreshListMarker: number;
}

const EnergyMeterList = ({ refreshListMarker }: EnergyMeterListProps) => {
  const [detailId, setDetailId] = useState<number>(0);
  const [refreshMarker, setRefreshMarker] = useState<number>(refreshListMarker);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  const energyMeterStore = useSelector((state: RootState) => state?.energyMeter, shallowEqual);
  const { areaValue, selectNoLocationFlag, subLocationFlag } = energyMeterStore;

  const currArea = !_.isEmpty(areaValue) ? _.last(areaValue) : undefined;

  const [delForm] = Form.useForm();
  const refreshTable = () => {
    setRefreshMarker(Math.random() * 100);
  };

  useEffect(() => {
    setRefreshMarker(refreshListMarker);
  }, [refreshListMarker]);

  // const handleDelete = async (ids: any[], onSuccess?: () => void) => {
  //   // 删除
  //   try {
  //     const res = await fetchInstrumentDelete({ idList: ids });

  //     if (res.code !== 200) {
  //       message.error(res?.message);
  //       return;
  //     }
  //     typeof onSuccess === 'function' && onSuccess?.();
  //     message.success('删除成功');
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      icon: <BlIcon type="icondaochu" />,
      auth: authDict.energyinstrument_export,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: appEnum.ObjectImport.BUSINESS_TYPE.energyMeter,
        });
      },
    },
    {
      title: '新建能源仪表',
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.energyinstrument_add,
      onClick: () => {
        goToCreate();
      },
      items: [
        {
          title: '导入',
          showExport: true,
          auth: authDict.energyinstrument_import,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: appEnum.ObjectImport.BUSINESS_TYPE.energyMeter,
            });
          },
        },
      ],
    },
  ];

  const getChangeStatus = (stauts: number, id: number) => {
    if (stauts === appEnum.Resources.EnergyMeterStatus.enabled) {
      return fetchInstrumentStatusStop({
        idList: [id],
        status: appEnum.Resources.EnergyMeterStatus.disabeld,
      });
    }
    return fetchInstrumentStatusEnable({
      idList: [id],
      status: appEnum.Resources.EnergyMeterStatus.enabled,
    });
  };

  const batchMenu = [
    {
      title: '启用',
      auth: authDict.energyinstrument_enable_disable,
      onClick: () =>
        fetchInstrumentStatusEnable({
          idList: selectedRowKeys,
          status: appEnum.Resources.EnergyMeterStatus.enabled,
        }).then((res) => {
          if (res.code === 200) {
            message.success('启用成功！');
            refreshTable();
          }
        }),
    },
    {
      title: '停用',
      auth: authDict.energyinstrument_enable_disable,
      onClick: () =>
        fetchInstrumentStatusStop({
          idList: selectedRowKeys,
          status: appEnum.Resources.EnergyMeterStatus.disabeld,
        }).then((res) => {
          if (res.code === 200) {
            message.success('停用成功！');
            refreshTable();
          }
        }),
    },
    {
      title: '报废',
      auth: authDict.energyinstrument_scrap,
      onClick: (onSuccess: any, onFail: any) => {
        BLDelConfirm(
          '是否确认报废该能源仪表？',
          delForm,
          () => {
            return fetchInstrumentStatusScrap({
              idList: selectedRowKeys,
              status: appEnum.Resources.EnergyMeterStatus.scrap,
            }).then((res) => {
              if (res.code === 200) {
                message.success('报废成功！');
                refreshTable();
              }
            });
          },
          onFail(),
        );
      },
    },
    // {
    //   title: '删除',
    //   icon: <BlIcon type="iconshanchulajitong1" />,
    //   onClick: (onSuccess: any, onFail: any) => {
    //     BLDelConfirm(
    //       '是否确认删除选中的能源仪表？',
    //       delForm,
    //       () => {
    //         handleDelete(selectedRowKeys);
    //         onSuccess();
    //       },
    //       onFail,
    //     );
    //   },
    // },
  ];

  const getOperationList = ({ id, status }: { id: number; status: number }) => {
    const statusOperation =
      status === appEnum.Resources.EnergyMeterStatus.disabeld ? '启用' : '停用';

    return _.compact([
      {
        title: '查看',
        auth: authDict.energyinstrument_detail,
        onClick: () => {
          goToDetail(id);
        },
      },
      {
        title: '编辑',
        auth: authDict.energyinstrument_edit,
        onClick: () => {
          goToEdit(id);
        },
      },
      {
        title: '复制',
        auth: authDict.energyinstrument_add,
        onClick: () => {
          goToCopy(id);
        },
      },
      status !== appEnum.Resources.EnergyMeterStatus.scrap && {
        title: statusOperation,
        disabled: status === appEnum.Resources.EnergyMeterStatus.scrap,
        auth: authDict.energyinstrument_enable_disable,
        onClick: () => {
          getChangeStatus(status, id).then((res) => {
            if (res.code === 200) {
              message.success(`${statusOperation}成功`);
              refreshTable();
            }
          });
        },
      },
      status !== appEnum.Resources.EnergyMeterStatus.scrap && {
        title: '报废',
        disabled: status === appEnum.Resources.EnergyMeterStatus.scrap,
        auth: authDict.energyinstrument_scrap,
        onClick: () => {
          BLDelConfirm('是否确认报废该能源仪表？', delForm, () => {
            return fetchInstrumentStatusScrap({
              idList: [id],
              status: appEnum.Resources.EnergyMeterStatus.scrap,
            }).then((res) => {
              if (res.code === 200) {
                refreshTable();
              }
            });
          });
        },
      },
      // {
      //   title: '删除',
      //   onClick: () => {
      //     BLDelConfirm('是否确认删除该能源仪表？', delForm, () => {
      //       return handleDelete([id], refreshTable);
      //     });
      //   },
      // },
      {
        title: '操作记录',
        auth: authDict.energyinstrument_operrecord,
        onClick: () => {
          setDetailId(id);
          setVisibleLog(true);
        },
      },
    ]);
  };

  const dataColumns: (BlColumnsType<EnergyMeterListItemType>[0] & {
    filterName?: string;
    type?: number;
    isFilter?: boolean;
    selectProps?: any;
    renderItem?: ReactElement;
    dateFormat?: any;
  })[] = [
    {
      title: '能源仪表编号',
      dataIndex: 'code',
      type: fieldTypeList.text,
      isFilter: true,
      sorter: true,
      fixed: 'left',
      width: 150,
      render: (value: string, record: EnergyMeterListItemType, index: number, config: any) => {
        return record?.id ? (
          <LinkTooltip
            text={value}
            to={toDetail(record.id)}
            width={config.contentWidth}
            auth={authDict.energyinstrument_detail}
          />
        ) : (
          value
        );
      },
    },
    {
      title: '能源仪表名称',
      dataIndex: 'name',
      sorter: true,
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
      render: (value: string, record: EnergyMeterListItemType, index: number, config: any) => {
        return record?.id ? (
          <LinkTooltip
            text={value}
            to={toDetail(record.id)}
            width={config.contentWidth}
            auth={authDict.energyinstrument_detail}
          />
        ) : (
          value
        );
      },
    },
    {
      title: '业务类型',
      dataIndex: 'businessType',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      selectProps: {
        options: appDict.resources.energyMeterType,
      },
      render: (type: any) => lookup('resources.energyMeterType', type),
    },
    {
      title: '能源仪表分类',
      dataIndex: 'classificationList',
      isFilter: true,
      width: 150,
      render: (text: any) => {
        return valueOrHolder(text, (text: any) => _.map(text, (item) => item?.name).join('/'));
      },
      renderItem: (
        <ResourceClassificationCascader
          params={{
            businessType: appEnum.Resources.ResourceBusinessType.energy,
            status: appEnum.Common.UsageStatus.enabled,
            cascade: true,
          }}
          showSearch
          placeholder={'请选择能源仪表分类'}
          onCreateClick={() => {}}
          enableAdd={false}
        />
      ),
      filterName: 'classification',
    },
    {
      title: '能源仪表标签',
      dataIndex: 'tagsList',
      filterName: 'tagsIdList',
      isFilter: true,
      width: 150,
      render: (tags: any[]) =>
        tags && (
          <TagList
            dataSource={tags.map((tag) => ({
              value: tag.id,
              label: `${tag.name}:${tag.content}`,
            }))}
          />
        ),

      renderItem: (
        <ResourceTagCascader
          nameLabel="能源仪表标签"
          businessType={appEnum.Resources.ResourceBusinessType.energy}
        />
      ),
    },
    {
      title: '标识码',
      dataIndex: 'entityLinkCode',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
      render: (entityLinkCode: string) => <Space>{renderQrCode(entityLinkCode)}</Space>,
    },
    {
      title: '供应商',
      dataIndex: 'supplierResDTO',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
      render: (obj) => renderUser(obj),
      renderItem: <SearchSelect fetchType="supplier" placeholder="请输入" labelInValue />,
      filterName: 'supplier',
    },
    {
      title: '序列号',
      dataIndex: 'serialNo',
      type: fieldTypeList.text,
      width: 150,
      isFilter: true,
      sorter: false,
    },
    {
      title: '负责人',
      dataIndex: 'responsibleUser',
      isFilter: true,
      width: 150,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
      render: (users: any[]) => <TagTooltip list={users || []} max={2} />,
    },
    {
      title: '负责部门',
      dataIndex: 'responsibleDepartment',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser={false} />,
      render: (departments: any[]) => (
        <TagTooltip isUser={false} list={departments || []} max={2} />
      ),
    },
    {
      title: '出厂日期',
      dataIndex: 'outFactoryDate',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      isFilter: true,
      sorter: true,
      width: 180,
    },
    {
      title: '入厂日期',
      dataIndex: 'enterFactoryDate',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      isFilter: true,
      sorter: true,
      width: 180,
    },
    {
      title: '启用日期',
      dataIndex: 'enableDate',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      isFilter: true,
      sorter: true,
      width: 180,
    },
    {
      title: '使用年限',
      dataIndex: 'useAge',
      isFilter: true,
      sorter: true,
      width: 150,
      render: (
        useAge: EnergyMeterListItemType['useAge'],
        { useAgeType }: EnergyMeterListItemType,
      ) => {
        if (_.isNil(useAge) || useAge <= 0) return replaceSign;
        return (
          <span>
            {useAge}
            {lookup('resources.useAgeUnit', useAgeType)}
          </span>
        );
      },
      renderItem: <UseAgeFilter />,
    },
    {
      title: '计划报废日期',
      dataIndex: 'planScrapDate',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      isFilter: true,
      sorter: true,
      width: 180,
    },
    {
      title: '启用状态',
      dataIndex: 'status',
      type: fieldTypeList.select,
      isFilter: true,
      sorter: true,
      width: 150,
      selectProps: {
        options: appDict.resources.energyMeterStatus,
      },
      render: (status: any) => <EnergyMeterStatus status={status} />,
    },
    {
      title: '存储单位',
      dataIndex: 'unitName',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      renderItem: (
        <SearchSelect
          fetchType="unit"
          placeholder="请选择单位"
          labelInValue
          params={{ enableFlag: UsageStatus.enabled }}
        />
      ),
    },
    {
      title: '存储区域',
      dataIndex: 'locationList',
      width: 150,
      render: (locationList: any[]) => locationList?.map(({ name }) => name).join('/'),
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
    },
  ];

  const filterList = dataColumns
    .filter((i) => i.isFilter)
    .map((column: any) => {
      const filter: any = {
        label: column.title,
        name: column.filterName || column.dataIndex,
        type: column.type,
        rules: column.rules,
        renderItem: column.renderItem,
      };

      if (column.type === fieldTypeList.select || column.type === fieldTypeList.multiSelect) {
        filter.selectProps = column.selectProps;
      }
      if (column.type === fieldTypeList.date && column.dateFormat) {
        filter.dateFormat = column.dateFormat;
      }
      if (column.filterRender) {
        filter.renderItem = column.filterRender;
      }
      return filter;
    });

  const formatDataToQuery = (data: any) => {
    const {
      outFactoryDate,
      enterFactoryDate,
      enableDate,
      planScrapDate,
      responsibleDepartment,
      responsibleUser,
      unitName,
      sorter,
      classification,
      supplier,
      ...resData
    } = data;

    const getSorter = (sorter?: { field: string; order: 'asc' | 'desc' }[]) => {
      if (_.isEmpty(sorter)) return undefined;

      const useAgeSorter = _.find(sorter, { field: 'useAge' });

      // 如果存在使用年限的排序，需要增加使用年限单位的反向排序
      if (!_.isNil(useAgeSorter)) {
        const useAgeTypeSorter = {
          field: 'useAgeType',
          order: { asc: 'desc', desc: 'asc' }[useAgeSorter.order] as 'asc' | 'desc',
        };

        sorter?.unshift(useAgeTypeSorter);
      }

      return sorter;
    };

    const sendData = {
      ...resData,
      sorter: getSorter(sorter),
      ...formatDateTimeRangeToUnix('beginOutFactoryDate', 'endOutFactoryDate', outFactoryDate),
      ...formatDateTimeRangeToUnix(
        'beginEnterFactoryDate',
        'endEnterFactoryDate',
        enterFactoryDate,
      ),
      ...formatDateTimeRangeToUnix('beginEnableDate', 'endEnableDate', enableDate),
      ...formatDateTimeRangeToUnix('beginPlanScrapDate', 'endPlanScrapDate', planScrapDate),
      departmentIdList: !_.isEmpty(responsibleDepartment)
        ? mapLabeledValueToValue(responsibleDepartment)
        : undefined,
      userIdList: !_.isEmpty(responsibleUser) ? mapLabeledValueToValue(responsibleUser) : undefined,
      unitId: !_.isNil(unitName) ? unitName?.value : undefined,
      classificationId: !_.isEmpty(classification) ? _.last(classification) : undefined,
      supplyId: _.get(supplier, 'value', undefined),
    };

    // 如果区域被用户操作过,
    // refreshListMarker(0),currArea(undefiend),subLocationFlag(false),selectNoLocationFlag(false)其中会有一个或多个不为默认值
    if (refreshListMarker || currArea || subLocationFlag || selectNoLocationFlag) {
      _.set(sendData, 'selectNoLocationFlag', selectNoLocationFlag);
      _.set(sendData, 'subLocationFlag', subLocationFlag);

      if (!selectNoLocationFlag) {
        _.set(sendData, 'locationId', currArea);
      }
    }

    dispatch.excelImport.updateBusinessData({ businessData: sendData });
    return sendData;
  };

  const formatDataToDisplay = (data: any) => {
    const displayData = _.cloneDeep(data);

    if (!gcArray.isEmpty(displayData?.enableDate)) {
      displayData.enableDate = `${gcTime.formatLine(
        displayData.enableDate[0],
      )} ~ ${gcTime.formatLine(displayData.enableDate[1])}`;
    }
    if (!gcArray.isEmpty(data?.enterFactoryDate)) {
      displayData.enterFactoryDate = `${gcTime.formatLine(
        displayData.enterFactoryDate[0],
      )} ~ ${gcTime.formatLine(displayData.enterFactoryDate[1])}`;
    }
    if (!gcArray.isEmpty(displayData.outFactoryDate)) {
      displayData.outFactoryDate = `${gcTime.formatLine(
        displayData.outFactoryDate[0],
      )} ~ ${gcTime.formatLine(displayData.outFactoryDate[1])}`;
    }
    if (!gcArray.isEmpty(displayData.planScrapDate)) {
      displayData.planScrapDate = `${gcTime.formatLine(
        displayData.planScrapDate[0],
      )} ~ ${gcTime.formatLine(displayData.planScrapDate[1])}`;
    }
    if (displayData.status) {
      displayData.status = lookup('resources.energyMeterStatus', displayData.status);
    }
    return displayData;
  };

  const formatDataToFormDisplay = (data: any) => {
    const { enterFactoryDate, outFactoryDate, enableDate, planScrapDate, ...rest } = data;

    return {
      ...rest,
      enterFactoryDate: gcTime.formatRangeTimeToMoment(enterFactoryDate),
      outFactoryDate: gcTime.formatRangeTimeToMoment(outFactoryDate),
      enableDate: gcTime.formatRangeTimeToMoment(enableDate),
      planScrapDate: gcTime.formatRangeTimeToMoment(planScrapDate),
    };
  };

  return (
    <>
      <RecordListLayout<EnergyMeterListItemType>
        filterList={filterList}
        requestFn={fetchInstrumentList}
        useIndex={false}
        mainMenu={mainMenu}
        batchMenu={batchMenu}
        placeholder={'请输入能源仪表名称或编号'} // 快速搜索input placeholder
        formatDataToQuery={formatDataToQuery} // 转换搜索条件进行查询（快速查询字段："quickSearch",当前页："page",分页大小："size"）
        formatDataToDisplay={formatDataToDisplay} // 转换搜索条件进行展示
        formatDataToFormDisplay={formatDataToFormDisplay}
        columns={dataColumns} // table columns
        refreshMarker={refreshMarker}
        selectedRowKeys={selectedRowKeys}
        rowKey="id"
        configcacheKey={'energyMeter'}
        onSelectedRowKeys={(selectKey: React.Key[]) => {
          setSelectedRowKeys(selectKey.map((key) => Number(key)));
        }}
        getOperationList={getOperationList}
        userAuth={getAuthFromLocalStorage()}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={ObjectCode.EnergyInstrument}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default EnergyMeterList;
