import { useState } from 'react';
import { message as Message, Select } from 'antd';
import { replaceSign } from 'src/utils/constants';
import { DataFormLayoutForModal, FilterItem, RecordListLayout } from 'layout';
import { fieldTypeList, gcTime } from 'src/utils';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import _Time from 'src/utils/dataTypes/time';
import {
  fetchProduceTaskList,
  fetchProduceTaskCancel,
} from 'src/api/ytt/mfg-domain/productionTask';
import { TASK_STATUS_MAP, TASK_STATUS_MAP_ENUM, TASK_TYPE_TYPE } from './constant';
import _, { forEach, isEmpty } from 'lodash';
import { LabelType } from 'src/page/knowledgeManagement/warehouse/interface';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import _Array from 'src/utils/dataTypes/array';
import _String from 'src/utils/dataTypes/string';
import { BlIcon, LinkTooltip, SearchSelect, TagList } from 'src/components';
import { renderDepartmentArray, renderUserArray } from 'src/page/share/renderUser';
import { intersectionValueOfId } from 'src/utils/array';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { WOCloseTypeDisplayMap, WOStatusDisplayMap } from 'src/dict/productionPlanning/mappings';
import { yn } from 'src/dict/common/mappings';
import SetProductionWorkOrder from './setProductionTask';
import AutoTimer from 'src/page/resource/maintenanceTask/components/autoTimer';

interface userProps {
  id: number;
  name: string;
  code: string;
}

export default function ProductionTaskList(props: { history: any }) {
  const { history } = props;
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新页面
  const [ProductionTaskSetting, setProductionTaskSetting] = useState<any>(false);

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  const dataColumns = [
    {
      title: '任务编号',
      dataIndex: 'taskCode',
      width: 150,
      sorter: true,
      render: (text: string, record: any, index: number, config: any) => (
        <LinkTooltip
          to={`/productionPlanning/execution/productionTaskList/${record?.taskId}/detail`}
          text={text}
          width={config.contentWidth}
          auth={authDict.producetask_detail}
        />
      ),
    },
    {
      title: '状态',
      dataIndex: 'taskStatus',
      width: 150,
      render: (taskStatus: number) => TASK_TYPE_TYPE.get(taskStatus) || replaceSign,
    },
    {
      title: '任务物料编号',
      dataIndex: ['taskMaterial', 'baseInfo', 'code'],
      width: 200,
      render: (code: string) => code ?? replaceSign,
    },
    {
      title: '任务物料名称',
      dataIndex: ['taskMaterial', 'baseInfo', 'name'],
      width: 200,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '工序编号',
      dataIndex: 'processCode',
      width: 150,
      render: (processCode: string) => processCode || replaceSign,
    },
    {
      title: '工序名称',
      dataIndex: 'processName',
      width: 150,
      render: (processName: string) => processName || replaceSign,
    },
    {
      title: '工作中心',
      dataIndex: ['workCenterVo', 'name'],
      width: 150,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '执行人',
      dataIndex: 'executors',
      width: 150,
      render: (executors: userProps[]) => renderUserArray(executors),
    },
    {
      title: '生产部门',
      dataIndex: 'departments',
      width: 150,
      render: (departments: userProps[]) => renderDepartmentArray(departments),
    },
    {
      title: '设备',
      dataIndex: 'equipments',
      width: 150,
      render: (equipments: userProps[]) => {
        if (_Array.isEmpty(equipments)) return replaceSign;

        return <TagList dataSource={equipments} labelPath="name" valuePath="id" />;
      },
    },
    {
      title: '计划开始时间',
      dataIndex: 'planStartTime',
      width: 180,
      sorter: true,
      render: (planStartTime: Date) => {
        if (!planStartTime) return replaceSign;
        return _Time.format(planStartTime);
      },
    },
    {
      title: '计划结束时间',
      dataIndex: 'planEndTime',
      width: 180,
      sorter: true,
      render: (planEndTime: Date) => {
        if (!planEndTime) return replaceSign;
        return _Time.format(planEndTime);
      },
    },
    {
      title: '暂停时间',
      dataIndex: 'duration',
      width: 180,
      render: (duration: number) => {
        if (!duration) return replaceSign;
        return `${duration}分钟`;
      },
    },
    {
      title: '工单编号',
      dataIndex: 'workOrderCode',
      width: 150,
      render: (workOrderCode: string) => workOrderCode || replaceSign,
    },
    {
      title: '工单物料编号',
      dataIndex: ['workOrderMaterial', 'baseInfo', 'code'],
      width: 150,
      render: (code: string) => code || replaceSign,
    },
    {
      title: '工单物料名称',
      dataIndex: ['workOrderMaterial', 'baseInfo', 'name'],
      width: 150,
      render: (name: string) => name || replaceSign,
    },
    {
      title: '工单物料规格',
      dataIndex: ['workOrderMaterial', 'baseInfo', 'specification'],
      width: 150,
      render: (name: string) => name || replaceSign,
    },
    {
      title: '工单物料属性',
      dataIndex: ['workOrderMaterial'],
      filterIndex: 'materialAttributeIds',
      width: 150,
      render: (_text: any) => {
        const options: {
          label: string;
          value: string | number;
        }[] = [];

        if (_text && !isEmpty(_text)) {
          const { attribute } = _text;

          forEach(attribute, (item) => {
            if (item?.id) {
              options.push({
                label: `${item.name}:${item.content}`,
                value: item?.id,
              });
            }
          });
        }

        return <TagList dataSource={options} />;
      },
    },
    {
      title: '订单编号',
      dataIndex: 'salesOrderList',
      width: 150,
      render: (salesOrderList: userProps[]) => {
        if (_Array.isEmpty(salesOrderList)) return replaceSign;
        return <TagList dataSource={salesOrderList} labelPath="code" valuePath="id" />;
      },
    },
    {
      title: '任务计划数',
      dataIndex: 'plannedAmount',
      width: 150,
      render: (plannedAmount: any) => {
        if (!plannedAmount) {
          return 0;
        }
        const { unit } = plannedAmount;

        return `${plannedAmount?.amount}${unit?.name}`;
      },
    },
    {
      title: '任务报工数',
      dataIndex: 'alreadyReportedAmount',
      width: 150,
      render: (alreadyReportedAmount: any) => {
        if (!alreadyReportedAmount) {
          return 0;
        }
        const { unit } = alreadyReportedAmount;

        return `${alreadyReportedAmount?.amount}${unit?.name}`;
      },
    },
    {
      title: '任务入库数',
      dataIndex: 'inventoryAmount',
      width: 150,
      render: (inventoryAmount: any) => {
        if (!inventoryAmount) {
          return 0;
        }
        const { unit } = inventoryAmount;

        return `${inventoryAmount?.amount}${unit?.name}`;
      },
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '任务状态',
      name: 'taskStatusList',
      type: fieldTypeList?.multiSelect,
      selectProps: {
        options: TASK_STATUS_MAP_ENUM.map((node: LabelType) => {
          return {
            label: node?.name,
            value: node?.id,
          };
        }),
      },
    },
    {
      label: '工序编号',
      name: 'processDefIdListCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'process'}
          valuePath="id"
          labelPath="code"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '工序名称',
      name: 'processDefIdListName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'process'}
          labelPath="name"
          valuePath="id"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '物料名称',
      name: 'materialIdListName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="name"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '物料编号',
      name: 'materialIdListCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="code"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '工单编号',
      name: 'workOrderIdList',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType={'workOrderFilterList'} labelInValue mode="multiple" />,
    },
    {
      label: '订单编号',
      name: 'salesOrderIdList',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType={'salesOrder'} labelInValue mode="multiple" />,
    },
    {
      label: '工作中心',
      name: 'workCenterIdList',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType={'workCenter'} labelInValue mode="multiple" />,
    },
    {
      label: '设备',
      name: 'equipmentIdList',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType={'resource'} labelInValue mode="multiple" />,
    },
    {
      label: '生产部门',
      name: 'departmentIdList',
      type: fieldTypeList.multiSelect,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple />,
    },
    {
      label: '执行人',
      name: 'executorIdList',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
    {
      label: '计划开始时间',
      name: 'planStartTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '计划结束时间',
      name: 'planEndTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '工单业务状态',
      name: 'businessStatusList',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <Select
          placeholder={'请选择工单业务状态'}
          options={WOStatusDisplayMap}
          allowClear
          mode="multiple"
          onChange={(val) => {
            console.log('val: ', val);
          }}
        />
      ),
    },
    {
      label: '工单关闭类型',
      name: 'closeTypeList',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <Select
          placeholder={'请选择工单关闭类型'}
          options={WOCloseTypeDisplayMap}
          allowClear
          mode="multiple"
        />
      ),
    },
    {
      label: '工单暂停状态',
      name: 'pauseStatusList',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <Select placeholder={'请选择工单暂停状态'} options={yn} allowClear mode="multiple" />
      ),
    },
    {
      label: '工单外部单据编号',
      name: 'externalOrderCode',
      type: fieldTypeList.text,
    },
  ];

  const formatDataToQuery = (params: any) => {
    const {
      processDefIdListCode,
      processDefIdListName,
      materialIdListName,
      materialIdListCode,
      workOrderIdList,
      salesOrderIdList,
      workCenterIdList,
      equipmentIdList,
      planStartTime,
      planEndTime,
      departmentIdList,
      executorIdList,
      taskStatusList,
      taskCode,
      ...rest
    } = params;

    const relParams = {
      ...rest,
    };

    if (planStartTime) {
      relParams.planStartTimeFrom = Number(_Time.formatToUnix(planStartTime[0]));
      relParams.planStartTimeTo = Number(_Time.formatToUnix(planStartTime[1]));
    }
    if (planEndTime) {
      relParams.planEndTimeFrom = Number(_Time.formatToUnix(planEndTime[0]));
      relParams.planEndTimeTo = Number(_Time.formatToUnix(planEndTime[1]));
    }

    if (!_Array.isEmpty(processDefIdListCode) || !_Array.isEmpty(processDefIdListName)) {
      relParams.processDefIdList = intersectionValueOfId(
        processDefIdListCode,
        processDefIdListName,
      );
    }
    if (!_Array.isEmpty(materialIdListName) || !_Array.isEmpty(materialIdListCode)) {
      relParams.materialIdList = intersectionValueOfId(materialIdListCode, materialIdListName);
    }

    if (!_Array.isEmpty(workOrderIdList)) {
      relParams.workOrderIdList = _.map(workOrderIdList, 'value');
    }
    if (!_Array.isEmpty(salesOrderIdList)) {
      relParams.salesOrderIdList = _.map(salesOrderIdList, 'value');
    }
    if (!_Array.isEmpty(workCenterIdList)) {
      relParams.workCenterIdList = _.map(workCenterIdList, 'value');
    }
    if (!_Array.isEmpty(equipmentIdList)) {
      relParams.equipmentIdList = _.map(equipmentIdList, 'value');
    }
    if (!_Array.isEmpty(departmentIdList)) {
      relParams.departmentIdList = _.map(departmentIdList, 'value');
    }
    if (!_Array.isEmpty(executorIdList)) {
      relParams.executorIdList = _.map(executorIdList, 'value');
    }
    if (!_Array.isEmpty(taskStatusList)) {
      relParams.taskStatusList = taskStatusList;
    }
    if (!_String.isEmpty(taskCode)) {
      relParams.taskCode = taskCode;
    }

    return relParams;
  };

  const formatDataToFormDisplay = (data: any) => {
    const { planStartTime, planEndTime, ...rest } = data;

    return {
      ...rest,
      planStartTime: planStartTime && gcTime.formatRangeTimeToMoment(planStartTime),
      planEndTime: planEndTime && gcTime.formatRangeTimeToMoment(planEndTime),
    };
  };

  const formatDataToDisplay = (data: any) => {
    return data;
  };

  const config = {
    rowKey: (record: any) => String(record.taskId),
    columns: dataColumns,
    getOperationList: (record: any) => {
      return [
        {
          title: '查看',
          auth: authDict.producetask_detail,
          onClick: () => {
            history.push(
              `/productionPlanning/execution/productionTaskList/${record?.taskId}/detail`,
            );
          },
        },
        {
          title: '编辑',
          auth: authDict.producetask_edit,
          disabled: !(
            record?.taskStatus === TASK_STATUS_MAP.TO_PERFORM ||
            record?.taskStatus === TASK_STATUS_MAP.EXECUTION ||
            record?.taskStatus === TASK_STATUS_MAP.PAUSE
          ),
          onClick: () => {
            history.push(`/productionPlanning/execution/productionTaskList/${record?.taskId}/edit`);
          },
        },
        {
          title: '取消',
          auth: authDict.producetask_cancel,
          disabled: record?.taskStatus !== TASK_STATUS_MAP.TO_PERFORM,
          onClick: async () => {
            try {
              const { code } = await fetchProduceTaskCancel({
                taskIdList: [record?.taskId],
              });

              if (code === 200) {
                Message.success('取消成功');
                refresh?.();
                return;
              }
            } catch (error) {
              console.log('error: ', error);
            }
          },
        },
      ];
    },
    filterList,
    formatDataToQuery,
    formatDataToFormDisplay,
    formatDataToDisplay,
    mainMenu: [
      {
        title: '设置',
        auth: authDict.producetask_set,
        icon: <BlIcon type="iconshezhi" />,
        onClick: () => {
          setProductionTaskSetting(true);
        },
        items: [],
      },
    ],
  };

  return (
    <>
      <RecordListLayout<any>
        userAuth={getAuthFromLocalStorage()}
        configcacheKey="productionTask" // 储存列配置
        placeholder={'输入任务编号'}
        refreshMarker={refreshMarker}
        requestFn={(params) =>
          fetchProduceTaskList({
            ...params,
            sorter: params?.sorter ? params?.sorter : [{ field: 'taskCode', order: 'desc' }], // 后端不好写 前端给的默认排序
          })
        }
        {...config}
      />
      <DataFormLayoutForModal
        visible={ProductionTaskSetting}
        onClose={() => {
          setProductionTaskSetting(undefined);
        }}
        content={
          <SetProductionWorkOrder
            onCancel={() => {
              setProductionTaskSetting(undefined);
            }}
          />
        }
        width={620}
      />
    </>
  );
}
