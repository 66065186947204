/**
 * @description 电子签名工具函数
 * @author xuzhiyong
 */

import { FilterItem } from 'layout';
import { FormInstance, Modal } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import { FieldData } from 'rc-field-form/lib/interface';
import { YN } from 'src/dict/common';
import { RULE_TYPE } from 'src/dict/eS-M';
import {
  ALL_USER_FLAG_ID,
  CURRENT_USER_FLAG_ID,
} from 'src/page/organization/components/userAndRoleModalSelect/constant';
import LocalStorage from 'src/utils/localStorage';
// eslint-disable-next-line import/no-cycle
import DetailSignConfigList from './components/DetailSignConfigList';
import { SignRangeType, SignConfTableRow, UserType, RoleType } from './type';
import Item from 'antd/lib/list/Item';

/** 格式化列表filterList */
export const getFilterList = (columns: any[]) => {
  return columns
    .filter((i) => i.isFilter)
    .map((column: any): FilterItem => {
      const filter = {
        label: column.title,
        name: column.dataIndex,
        ...column,
      };

      return filter;
    });
};

/**
 * 初始化Form
 * @param form Form实例
 * @param isEdit 是否编辑状态
 * @param data 签名详情
 */
export const initialForm = (form: FormInstance<any>, isEdit: boolean, data?: any) => {
  const {
    name,
    relatedObjectName,
    functionName,
    remark,
    updateReason,
    digitalSignatureConfigVOList: dsList,
  } = data || {};
  const initFormFields = {
    name,
    relatedObjectCode: relatedObjectName
      ? {
          value: relatedObjectName,
          key: relatedObjectName,
          label: relatedObjectName,
        }
      : null,
    functionCode: functionName
      ? {
          value: functionName,
          key: functionName,
          label: functionName,
        }
      : null,
    remark,
    ruleType: RULE_TYPE.parallel,
    updateReason: '',
    configList: [] as SignConfTableRow[],
  };

  if (!isEdit) {
    // 新增
    initFormFields.configList = [
      {
        seq: 1, // 可随意数字
        allUserFlag: YN.yes,
        currentUserFlag: YN.no,
        signRange: [
          {
            type: 'user',
            label: '所有用户',
            value: ALL_USER_FLAG_ID,
          },
        ],
      },
    ];
  } else {
    const configList: SignConfTableRow[] = [];

    dsList.forEach((item: any) => {
      const { allUserFlag, currentUserFlag } = item;
      const signRange: SignRangeType[] = [];

      if (allUserFlag) {
        signRange.push({
          type: 'user',
          label: '所有用户',
          value: ALL_USER_FLAG_ID,
        });
      }
      if (currentUserFlag) {
        signRange.push({
          type: 'user',
          label: '当前登录用户',
          value: CURRENT_USER_FLAG_ID,
        });
      }

      item?.roles?.forEach((role: RoleType) => {
        if (role) {
          signRange.push({
            type: 'role',
            label: role.name,
            value: role.id,
          });
        }
      });

      item?.users?.forEach((user: UserType) => {
        if (user) {
          signRange.push({
            type: 'user',
            label: user.name,
            value: user.id,
          });
        }
      });

      initFormFields.configList.push({
        id: item?.id,
        seq: item?.seq,
        allUserFlag: item?.allUserFlag,
        currentUserFlag: item?.currentUserFlag,
        signRange,
      });
    });

    // 编辑
    initFormFields.updateReason = updateReason;
  }

  form.setFieldsValue(initFormFields);
};

/**
 * 添加签名人范围行
 * @param form Form实例
 */
export const addSignRow = (form: FormInstance<any>) => {
  const configList: SignConfTableRow[] = form.getFieldValue('configList');
  const lastSeq = _.last(configList)?.seq || 0;

  form.setFields([
    {
      name: ['configList', configList.length],
      value: {
        seq: lastSeq + 1,
        allUserFlag: YN.yes,
        currentUserFlag: YN.no,
        signRange: [],
      },
    },
  ]);
};

/**
 * 删除签名人范围行
 * @param form Form实例
 * @param index 索引
 */
export const removeSignRow = (form: FormInstance<any>, index: number) => {
  const configList: SignConfTableRow[] = form.getFieldValue('configList');

  form.setFields([
    {
      name: ['configList'],
      value: configList.filter((e, i) => i !== index),
    },
  ]);
};

/**
 * 更新签名人范围
 * @param form Form实例
 * @param signRange 更新后数据
 * @param index 索引
 */
export const updateSignRange = ({
  form,
  signRange,
  index,
  allUserFlag,
  currentUserFlag,
}: {
  form: FormInstance<any>;
  signRange: SignRangeType[];
  index: number;
  allUserFlag: YN;
  currentUserFlag: YN;
}) => {
  const configList = _.cloneDeep<SignConfTableRow[]>(form.getFieldValue('configList'));

  configList[index].allUserFlag = allUserFlag;
  configList[index].currentUserFlag = currentUserFlag;
  configList[index].signRange = _.unionBy(
    configList[index].signRange
      .filter((item) => !allUserFlag || item.type === 'role')
      .concat(signRange),
    'value',
  );

  form.setFieldsValue({
    configList,
  });
};

/** 格式化Form返回值 */
export const formatParams = (values: any) => {
  const { functionCode, relatedObjectCode } = values;
  const params = { ...values } as any;

  params.digitalSignatureConfigCOList = _.map(values.configList, (conf: any) => ({
    id: conf?.id,
    seq: conf?.seq,
    allUserFlag: conf?.allUserFlag,
    currentUserFlag: conf?.currentUserFlag,
    userIds: conf?.allUserFlag
      ? []
      : conf.signRange
          .filter((user: any) => user.type === 'user' && user.value !== CURRENT_USER_FLAG_ID)
          .map((item: any) => item.value),
    roleIds: conf.signRange
      .filter((role: any) => role.type === 'role')
      .map((item: any) => item.value),
  }));

  delete params.configList;

  params.functionCode = functionCode?.value;
  params.relatedObjectCode = relatedObjectCode?.value;

  return params;
};

/**
 * 处理业务电子签名
 */
export const handleSignature = async ({ resolve, response }: any) => {
  const { functionCode, signatureSessionId } = _.get(response, 'data.data');
  const { config } = response;

  config.headers['X-Signature-Session-Id'] = signatureSessionId;

  Modal.confirm({
    title: '电子签名',
    icon: null,
    content: (
      <DetailSignConfigList
        inBusiness
        functionCode={functionCode}
        signatureSessionId={signatureSessionId}
      />
    ),
    width: 500,
    bodyStyle: { margin: -5 },
    onOk: () => {
      axios(response.config).then((res) => {
        resolve(res);
      });
    },
    onCancel: () => {
      // 处理取消按钮操作 待定  --- xuzhiyong
      // 列表批量操作需要结束promise，暂时放开 --- zhaoxin
      resolve(response);
    },
  });
};

/**
 * 获取电子签名用户名缓存
 * @param functionCode 功能点Code
 */
export const getLocalUsernameOptions = (functionCode: string) => {
  const signAutoString = LocalStorage.get('sign_autocomplete');

  if (signAutoString) {
    const optionList = JSON.parse(signAutoString)?.[functionCode] || [];

    return optionList;
  }
  return [];
};

/**
 * 更新电子签名用户名缓存
 * @param functionCode 功能点Code
 * @param value 值
 */
export const setLocalUsernameOptions = (functionCode: string, value: { value: string }) => {
  const signAutocomplete = JSON.parse(LocalStorage.get('sign_autocomplete') || '{}');

  let options = signAutocomplete[functionCode];

  if (options) {
    options.push(value);
    options = _.uniqBy(options, 'value').slice(-10);
  } else {
    options = [value];
  }
  signAutocomplete[functionCode] = options;

  LocalStorage.set('sign_autocomplete', JSON.stringify(signAutocomplete));
};
