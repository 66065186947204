import Styles from './index.module.scss';

const chartViewHint =
  '选择需要使用动态视图展示的参数，至少一个，最多九个；动态视图仅展示最近24h内更新的10个参数记录';

const legends = [
  { name: '上上限', color: '#FF4D4F' },
  { name: '上限', color: '#FAAD14' },
  { name: '标准值', color: '#02B980' },
  { name: '下限', color: '#FAAD14' },
  { name: '下下限', color: '#FF4D4F' },
];

const renderLegent = ({ name, color }: { name: string; color: string }) => {
  return (
    <div key={`legend-${name}`} className={Styles.legendInfo}>
      <span className={Styles.legendName}>{name}</span>
      <span
        className={Styles.sampleLine}
        style={{
          backgroundImage: `linear-gradient(to left, transparent 0%, transparent 50%, ${color} 50%, ${color} 100%)`,
        }}
      />
    </div>
  );
};

export default function ChartViewHint() {
  return (
    <div className={Styles.hintContainer}>
      <div>{chartViewHint}</div>
      <div className={Styles.legendsContainer}>
        <div>图例：</div>
        {legends.map(renderLegent)}
      </div>
    </div>
  );
}
