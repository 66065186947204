export const BASE_URL = '/quality/qualityModeling/checkItemClassification';

export const toCheckClassDetail = (id: number) => {
  return `${BASE_URL}/${id}/detail`;
};
export const toCheckClassEdit = (id: number) => {
  return `${BASE_URL}/${id}/edit`;
};

export const toCheckClassList = () => {
  return `${BASE_URL}`;
};
