import { FilterItem } from 'src/layout';
import { fieldTypeList } from 'utils';
import { SearchSelect, TagList } from 'src/components';
import {
  deliveryScheduleNoteExecStatus,
  deliveryScheduleNoteSource,
} from 'src/dict/purchase/mappings';
import { PurchaseOrderdeliveryMode } from 'src/dict/purchase';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import _ from 'lodash';
import { replaceSign } from 'src/utils/constants';
import { renderAmount, formatTime, renderTimeUnit } from '../utils';
import { formatTimeForRender, formatDateForRender } from 'src/utils/formatters/dateTime';
import lookup from 'src/dict';
import { DeliveryScheduleNoteListMaterialItem } from '../interface';

const ordersListLeft = [
  {
    title: '供应商编号',
    sorter: true,
    dataIndex: 'supplierCode',
    width: 150,
    render: (value: any, record: DeliveryScheduleNoteListMaterialItem) => {
      return record?.supplier?.code;
    },
  },
  {
    title: '供应商名称',
    dataIndex: ['supplier', 'name'],
    width: 150,
  },
];
const ordersListMiddle = [
  {
    title: '状态',
    dataIndex: 'execStatus',
    width: 150,
    render: lookup('purchase.deliveryScheduleNoteExecStatus'),
  },
  {
    title: '计划单收货状态',
    dataIndex: 'receiveStatus',
    width: 150,
    render: lookup('purchase.deliveryScheduleNoteReceiveStatus'),
  },
];
const ordersListRight = [
  {
    title: '创建人',
    dataIndex: ['creator', 'name'],
    width: 150,
  },
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    sorter: true,
    width: 150,
    render: formatTimeForRender,
  },
  {
    title: '更新人',
    dataIndex: ['operator', 'name'],
    width: 150,
  },
  {
    title: '更新时间',
    dataIndex: 'updatedAt',
    sorter: true,
    width: 150,
    render: formatTimeForRender,
  },
];
const materialsColumns = [
  {
    title: '物料编号',
    dataIndex: ['material', 'baseInfo', 'code'],
    width: 150,
  },
  {
    title: '物料名称',
    dataIndex: ['material', 'baseInfo', 'name'],
    width: 150,
  },
  {
    title: '物料属性',
    dataIndex: ['material', 'attribute'],
    width: 150,
    render: (value: any) => {
      if (_.isEmpty(value)) return replaceSign;
      return (
        <TagList
          dataSource={_.map(value, ({ name, attributeItem }) => ({
            label: `${name}:${attributeItem.content}`,
            value: attributeItem.content,
          }))}
        />
      );
    },
  },
  {
    title: '物料规格',
    dataIndex: ['material', 'baseInfo', 'specification'],
    width: 150,
  },
  {
    title: '供应商发货状态',
    dataIndex: 'deliveryStatus',
    width: 150,
    render: lookup('purchase.deliveryScheduleNoteDeliveryStatus'),
  },
  {
    title: '需求数',
    dataIndex: 'demandAmount',
    sorter: true,
    width: 150,
    render: renderAmount,
  },
  {
    title: '交货日期',
    dataIndex: 'deliveryDate',
    sorter: true,
    width: 150,
    render: formatDateForRender,
  },
  {
    title: '最早到货时间',
    dataIndex: 'earliestArrivalTime',
    sorter: true,
    width: 150,
    render: (time: number) => {
      return time ? formatTime(time) : replaceSign;
    },
  },
  {
    title: '最晚到货时间',
    dataIndex: 'latestArrivalTime',
    sorter: true,
    width: 150,
    render: (time: number) => {
      return time ? formatTime(time) : replaceSign;
    },
  },
  {
    title: '采购订单编号',
    dataIndex: 'purchaseOrderCode',
    width: 150,
  },
  {
    title: '采购订单行号',
    dataIndex: 'purchaseOrderItemSeqNum',
    width: 150,
  },
  {
    title: '计划收货数',
    dataIndex: 'planReceivedAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '实际发货数',
    dataIndex: 'deliverAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '收货接收数',
    dataIndex: 'receivedAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '物流时间',
    dataIndex: 'logisticsCycle',
    width: 150,
    render: (time: number, record: any) => {
      return time ? time + renderTimeUnit(record.logisticsTimeUnit) : replaceSign;
    },
  },
];

export const dataColumnsForOrdersList = [
  ...ordersListLeft,
  ...ordersListMiddle,
  ...ordersListRight,
];
export const dataColumnsForMaterialLineList = [
  {
    title: '行号',
    dataIndex: 'lineNo',
    width: 150,
  },
  ...ordersListLeft,
  ...materialsColumns,
  ...ordersListRight,
];

// 订单视图筛选列
export const ordersFilterList: FilterItem[] = [
  {
    label: '编号',
    name: 'code',
    type: fieldTypeList.text,
  },
  {
    label: '来源',
    name: 'sourceList',
    type: fieldTypeList.multiSelect,
    selectProps: {
      options: deliveryScheduleNoteSource,
      mode: 'multiple',
    },
  },
  {
    label: '供应商',
    name: 'supplier',
    type: fieldTypeList.multiSelect,
    renderItem: <SearchSelect fetchType={'supplierWithCode'} mode={'multiple'} />,
  },
  {
    label: '订单所有人',
    name: 'owner',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <UserOrDepartmentSelectWithDialog placeholder={'请选择订单所有人'} isMultiple isSelectUser />
    ),
  },
  {
    label: '状态',
    name: 'execStatusList',
    type: fieldTypeList.multiSelect,
    selectProps: {
      options: deliveryScheduleNoteExecStatus,
      mode: 'multiple',
    },
  },
  {
    label: '创建人',
    name: 'creator',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <UserOrDepartmentSelectWithDialog placeholder={'请选择订单创建人'} isMultiple isSelectUser />
    ),
  },
  {
    label: '创建时间',
    name: 'createdAt',
    type: fieldTypeList.date,
  },
];
// 物料行视图筛选列
const cloneFilterList = _.cloneDeep(ordersFilterList);

cloneFilterList.splice(1, 1);
cloneFilterList.splice(2, 2);
cloneFilterList.splice(2, 0, {
  label: '物料',
  name: 'material',
  type: fieldTypeList.multiSelect,
  renderItem: (
    <SearchSelect placeholder={'请选择物料'} mode="multiple" fetchType="materialCodeName" />
  ),
});
cloneFilterList.splice(3, 0, {
  label: '采购订单号',
  name: 'purchaseOrderIds',
  type: fieldTypeList.multiSelect,
  renderItem: (
    <SearchSelect
      placeholder={'请选择采购订单号'}
      mode="multiple"
      fetchType="purchaseOrderId"
      labelInValue
      params={{ deliveryModeList: [PurchaseOrderdeliveryMode.deliverySchedule] }}
    />
  ),
});
export const materialsFilterList: FilterItem[] = cloneFilterList;
