/**
 * @description 自定义对象导入常用常量
 * @author Mars
 */

/**
 * 导入帮助提示
 */
export const HELP_TIP_CONTENT_TEXT = [
  '- 单次最多支持导入1w条数据',
  '- 请使用纯文本或数字填写模版',
  '- 数据中的图片、文件暂不支持导入',
];

/**
 * 自定义对象模板导入帮助提示
 */
export const CUSTOM_TEMPLATE_HELP_TIPS = [
  '1、模版第一列是对象的主属性，无论是主对象、从对象还是被关联的对象，导入依据都是对象主属性；',
  '2、如果主属性设置为手动输入，则必须要填写值；如果主属性设置为编号规则生成，新建导入时可以留空，更新导入时也必须要填写值；',
  '3、对象中设置的必填字段必须在模版中保留并填入值。',
];

/**
 * 文件导入限制
 */
export const FILE_EXTENSION_LIMIT_HINT = '文件支持类型：XLSX，文件限制不能超过10M';

/**
 * 导入模板类型
 */
export enum TEMPLATE_TYPE {
  /** 预置 */
  preset = 0,
  /** 自定义 */
  custom,
}

/** 自定义对象应用名 */
export const CUSTOM_APPLICATION_NAME = 'imexport-domain';
