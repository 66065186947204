import React, { useEffect, useState } from 'react';
import { Image } from 'antd';
import { fetchFileDetail, FetchFileDetailResponse } from 'src/api/ytt/holyfile-domain';
import { PictureOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { replaceSign } from 'src/utils/constants';
import { formatterUrl } from 'src/components/upload/share';

interface ImageType {
  /**
   * 文件ID
   */
  documentId: number;
  /**
   * 文件路径
   */
  documentPath?: string;

  itemContainerStyle?: React.CSSProperties;
}
export const BlThumbnailImage: React.FC<ImageType> = (props) => {
  const { documentPath, itemContainerStyle, documentId } = props;

  return (
    <div
      key={documentId}
      style={{
        width: 104,
        height: 104,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        margin: '0 10px 10px 0',
        border: '2px solid rgba(169, 169, 169, 36%)',
        ...itemContainerStyle,
      }}
    >
      {documentPath ? (
        <Image key={documentId} src={documentPath} style={{ width: 88, height: 88 }} />
      ) : (
        <PictureOutlined style={{ fontSize: 40 }} />
      )}
    </div>
  );
};

interface ThumbnailImageProps {
  dataSource: ImageType[];
  itemContainerStyle?: React.CSSProperties;
}

export const ThumbnailImages: React.FC<ThumbnailImageProps> = (props) => {
  const { dataSource, itemContainerStyle } = props;

  if (_.isEmpty(dataSource)) return <span>{replaceSign}</span>;

  return (
    <>
      {_.map(dataSource, (item) => (
        <ThumbnailImage key={item.documentId} itemContainerStyle={itemContainerStyle} {...item} />
      ))}
    </>
  );
};

/**
 * 业务组件 ThumbnailImage
 * 如果没有传url，则根据文件id，去文件服务获取文件的url
 */
export const ThumbnailImage = (props: ImageType) => {
  const defaultUrl = '';
  const [url, setUrl] = useState(defaultUrl);
  const { documentPath, documentId, ...rest } = props;

  const fetchUrl = async (id: number) => {
    try {
      const { data }: FetchFileDetailResponse = await fetchFileDetail({ id });

      setUrl(formatterUrl(data?.fileUri || defaultUrl));
    } catch (error) {
      setUrl(defaultUrl);
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (!props?.documentPath) {
      fetchUrl(props?.documentId);
      return;
    }
    setUrl(formatterUrl(props?.documentPath));
  }, [props.documentId]);
  return <BlThumbnailImage documentId={props.documentId} documentPath={url} {...rest} />;
};
