/*
 * @Author: huangsijia
 * @Date: 2021-11-22 10:54:00
 * @LastEditTime: 2022-02-25 14:08:20
 * @LastEditors: huangsijia
 * @Description: 查看不良等级列表
 * @FilePath: /bf-main-3/src/page/quality/qcDefectRank/list.tsx
 */

import { useState, useRef } from 'react';
import { RecordListLayout } from 'layout';
import { Form, message } from 'antd';
import { replaceSign } from 'src/utils/constants';
import { fieldTypeList } from 'src/utils';
import { BlIcon, LinkTooltip, SingleOperationLogComponent, Tooltip } from 'src/components';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import { CRUD } from 'src/dict/common';

import {
  fetchQcDefectRankPageList,
  fetchQcDefectRankDelete,
  fetchQcDefectRankDetail,
} from 'src/api/ytt/quality-domain/qc_defect_rank';

import { QcDefectRankType, delMsg, DetailDataType } from './interface';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { useHistory } from 'react-router-dom';
import {
  customLayoutCreateButton,
  customLayoutChooseButton,
} from 'src/components/customLayout/hooks/customLayoutForButton';
import { create } from 'lodash';

const unknownMsg = '未知错误';
const delSuccessMsg = '删除成功';
const delFailMsg = '删除失败';
const NONEXISTENT = -1;

const QcDefectRankList = () => {
  const [visibleLogId, setVisibleLogId] = useState<number>(NONEXISTENT); // 显示单条操作记录id
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [delForm] = Form.useForm();
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = async (ids: number[], onSuccess?: () => void) => {
    try {
      const res = await fetchQcDefectRankDelete({ ids });
      const { data } = res;

      if (res.code !== 200) {
        message.error(res?.message || unknownMsg);
        return;
      }
      if (data?.code === 1) {
        // 结果标识符 1成功 2失败 3出现失败(批量删除)
        typeof onSuccess === 'function' && onSuccess?.();
        message.success(data?.result || res?.message || delSuccessMsg);
      }
      if (data?.code === 2) {
        message.error(data?.result || res?.message || delFailMsg);
      }
      // if (data?.code === 3) {
      //   typeof onSuccess === 'function' && onSuccess?.();
      //   message.warn('批量删除出现失败');
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const getOptionList = (record: QcDefectRankType) => {
    return [
      {
        title: '查看',
        auth: authDict.defectlevel_detail,
        customRender: customLayoutChooseButton({
          title: '查看',
          objectCode: 'DefectLevel',
          type: 'detail',
          instanceId: record?.id,
          onClick: (layoutId: number) => {
            history.push(
              `/quality/qualityModeling/qcDefectRank/${record?.id}/detail?layoutId=${layoutId}`,
            );
          },
        }),
      },
      {
        title: '编辑',
        auth: authDict.defectlevel_edit,
        customRender: customLayoutChooseButton({
          title: '编辑',
          objectCode: 'DefectLevel',
          type: 'edit',
          instanceId: record?.id,
          onClick: (layoutId: number) => {
            history.push(
              `/quality/qualityModeling/qcDefectRank/${record?.id}/edit?layoutId=${layoutId}`,
            );
          },
        }),
      },
      {
        title: '操作记录',
        onClick: () => setVisibleLogId(record.id),
        auth: authDict.defectlevel_operrecord,
      },
      // {
      //   title: '删除',
      //   onClick: () => {
      //     BLDelConfirm(delMsg, delForm, () => {
      //       handleDelete([record.id], refreshTable);
      //     });
      //   },
      // },
    ];
  };

  const hasAuthRes = hasAuth(authDict.causeofdefect_detail);

  const getColumns = () => [
    {
      title: '不良等级名称',
      dataIndex: 'name',
      type: fieldTypeList.text,
      width: 200,
      render: (name: string, record: QcDefectRankType, index: number, config: any) => (
        <>
          {hasAuth(authDict.defectlevel_detail) ? (
            customLayoutChooseButton({
              title: <Tooltip text={name ?? replaceSign} width={config.contentWidth} />,
              objectCode: 'DefectLevel',
              type: 'detail',
              instanceId: record?.id,
              onClick: (layoutId: number) => {
                history.push(
                  `/quality/qualityModeling/qcDefectRank/${record?.id}/detail?layoutId=${layoutId}`,
                );
              },
            })
          ) : (
            <Tooltip text={name ?? replaceSign} width={config.contentWidth} />
          )}
        </>
      ),
    },
    {
      title: '备注',
      dataIndex: 'remark',
      type: fieldTypeList.textArea,
      maxWidth: '50%',
      render: (remark: string) => remark ?? replaceSign,
    },
  ];

  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      icon: <BlIcon type="icondaochu" />,
      auth: authDict.defectlevel_export,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.qcDefectRank,
        });
      },
    },
    {
      title: '导入',
      showExport: true,
      auth: authDict.defectlevel_import,
      onClick: () => {
        openModal({
          optType: 'import',
          businessType: BUSINESS_TYPE.qcDefectRank,
        });
      },
    },
    {
      title: '新建不良等级',
      key: 'create',
      auth: authDict.defectlevel_add,
      customRender: customLayoutCreateButton({
        title: '新建不良等级',
        icon: <BlIcon type="iconxinjiantianjia" />,
        objectCode: 'DefectLevel',
        type: 'create',
        onClick: (layoutId) => history.push(`qcDefectRank/create?layoutId=${layoutId}`),
      }),
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const batchMenu = [
    {
      title: '删除',
      icon: <BlIcon type="iconshanchulajitong1" />,
      onClick: (onSuccess: any, onFail: any) => {
        BLDelConfirm(
          delMsg,
          delForm,
          () => {
            handleDelete(selectedRowKeys.map((item) => Number(item)));
            onSuccess();
          },
          onFail,
        );
      },
    },
  ];

  const filterList = [
    {
      label: '不良等级名称',
      name: 'name',
      type: fieldTypeList.text,
    },
    {
      label: '备注',
      name: 'remark',
      type: fieldTypeList.text,
    },
  ];

  const formatDataToQuery = (params: any) => {
    dispatch.excelImport.updateBusinessData({ businessData: params });
    return params;
  };

  return (
    <>
      <RecordListLayout<QcDefectRankType>
        columns={getColumns()}
        useIndex={false}
        mainMenu={mainMenu}
        batchMenu={[]}
        filterList={filterList}
        formatDataToQuery={formatDataToQuery}
        requestFn={fetchQcDefectRankPageList}
        // selectedRowKeys={selectedRowKeys}
        refreshMarker={refreshMarker}
        // onSelectedRowKeys={(selectKey) => {
        //   setSelectRowKeys(selectKey);
        // }}
        getOperationList={getOptionList}
        userAuth={getAuthFromLocalStorage()}
      />
      {visibleLogId > NONEXISTENT && (
        <SingleOperationLogComponent
          visible={visibleLogId > NONEXISTENT}
          instanceId={visibleLogId}
          objectCode={'DefectLevel'}
          closeDetail={() => {
            setVisibleLogId(NONEXISTENT);
          }}
        />
      )}
    </>
  );
};

export default QcDefectRankList;
