import React, { useState } from 'react';
import { BlIcon, BlTable } from 'src/components';
import { Form, FormInstance, message, Input, Button, Popover } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import styles from './customer.module.scss';
import { textValidator1 } from 'src/utils/formValidators';
import { AreaDataType } from '../interface';

const { TextArea } = Input;

interface customerProps {
  form: FormInstance;
}

const DragHandle = SortableHandle(() => <BlIcon type="iconrenyituozhuai" />);

const BlFormList = (props: customerProps) => {
  const { form } = props;

  const [batchForm] = Form.useForm();

  const [batchVisible, setBatchVisible] = useState(false);

  const scrollToBottom = () => {
    const wrapper = document.getElementById('qrCodePrint');

    if (wrapper) {
      const dom = wrapper.getElementsByClassName('ant-table-body')[0];

      if (dom) {
        dom.scrollTop = dom.scrollHeight;
      }
    }
  };

  const onCancel = () => {
    batchForm.resetFields();
    setBatchVisible(false);
  };

  const batchAddFn = (addArr: string[]) => {
    let addList = [];

    addList = addArr.map((node: string) => {
      return { customerCode: node };
    });

    const oldValue = form.getFieldValue('factoryCodeList') ?? [];

    const arr = oldValue?.concat(addList); // 把原来的数据与新加的合并之后 重新赋值

    if (arr.length >= 500) {
      message.error('最多支持添加500行');
      return;
    }

    form.setFieldsValue({ factoryCodeList: arr });

    onCancel();
  };

  // 处理textarea获取的数据
  const processAreaData = (values: AreaDataType) => {
    // 以换行符分割 去除每个选项的前后空格 过滤空字符串
    const addArr = values?.batchOptions
      .split(/[\n]/)
      .map((node: string) => {
        return node.trim();
      })
      .filter((v: string) => v !== '');

    return addArr;
  };

  const onVisibleChange = (visible: boolean) => {
    setBatchVisible(visible);
  };

  const onFinish = async () => {
    const res = await batchForm.validateFields();

    const addArr = await processAreaData(res);

    batchAddFn(addArr);
  };

  const batchContent = () => {
    return (
      <Form style={{ width: '300px' }} form={batchForm}>
        <Form.Item name="batchOptions" noStyle>
          <TextArea
            rows={10}
            style={{ whiteSpace: 'nowrap', overflow: 'scroll' }}
            placeholder="用换行分割多个选项"
          />
        </Form.Item>
        <Form.Item noStyle>
          <div style={{ marginTop: '16px', textAlign: 'right' }}>
            <Button size="small" onClick={onCancel}>
              取消
            </Button>
            <Button size="small" type="primary" style={{ marginLeft: '8px' }} onClick={onFinish}>
              确定
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  return (
    <>
      <Form.List name="factoryCodeList">
        {(fields, { add, remove, move }) => {
          const getFooter = () => {
            return (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div
                  className="addIcon"
                  onClick={() => {
                    if (fields.length >= 500) {
                      message.error('最大支持添加500行');
                      return;
                    }
                    add();
                    setTimeout(() => {
                      scrollToBottom();
                    }, 300);
                  }}
                >
                  <Button size="large" type="dashed">
                    <BlIcon type="iconxinjiantianjia" />
                    添加标识码
                  </Button>
                </div>
                <div className="addIcon">
                  <Popover
                    content={batchContent()}
                    title="批量添加标识码"
                    trigger="click"
                    visible={batchVisible}
                    onVisibleChange={onVisibleChange}
                  >
                    <Button size="large" type="dashed">
                      <BlIcon type="iconpiliang" />
                      批量添加标识码
                    </Button>
                  </Popover>
                </div>
              </div>
            );
          };
          const getColumns = (): ColumnProps<FormListFieldData>[] => {
            return [
              {
                width: 30,
                fixed: 'left',
                render: () => (
                  <div title="拖动">
                    <DragHandle />
                  </div>
                ),
              },
              {
                width: 30,
                fixed: 'left',
                render: (_, record) => {
                  return (
                    <div>
                      <BlIcon
                        style={{ color: 'red' }}
                        type="iconlieshanchu"
                        onClick={() => {
                          const elements = form.getFieldValue('factoryCodeList') ?? {};

                          if (elements?.length < 2) {
                            // 提示 至少需要一条
                            message.error('自定义标识码值至少需要一条');
                            return;
                          }
                          remove(record?.name);
                        }}
                      />
                    </div>
                  );
                },
              },
              {
                title: '标识码值',
                dataIndex: 'customerCode',
                key: 'customerCode',
                width: '100%',
                render: (_, field) => {
                  return (
                    <Form.Item
                      name={[field?.name, 'customerCode']}
                      fieldKey={[field?.key, 'customerCode']}
                      rules={[
                        {
                          required: true,
                          message: '标识码值不能为空',
                        },
                        { validator: textValidator1 },
                      ]}
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <Input style={{ width: '100%' }} placeholder="请输入" />
                    </Form.Item>
                  );
                },
              },
            ];
          };

          // 拖动的元素
          const SortableItem = SortableElement((itemProps: any) => <tr {...itemProps} />);
          const TableContainer = SortableContainer((tableProps: any) => <tbody {...tableProps} />);

          const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
            move(oldIndex, newIndex);
          };

          const DraggableBodyRow = (bodyRowProps: any) => {
            const index = fields.findIndex((x: any) => x.key === bodyRowProps['data-row-key']);

            return <SortableItem index={index} {...bodyRowProps} />;
          };

          const DraggableContainer = (containerProps: any) => (
            <TableContainer
              useDragHandle
              helperClass="row-dragging"
              onSortEnd={onSortEnd}
              {...containerProps}
            />
          );

          return (
            <BlTable
              columns={getColumns()}
              dataSource={fields}
              footer={getFooter}
              scroll={{ y: 650 }}
              rowKey={(field) => field?.key}
              id="qrCodePrint"
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
              className={styles?.customerCode}
            />
          );
        }}
      </Form.List>
    </>
  );
};

export default BlFormList;
