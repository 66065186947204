import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { BlIcon, BlTable } from 'src/components';
import _Array from 'src/utils/dataTypes/array';
import { QcCheckItemListType } from '../interface';
import AddCheckItemAssociatedModal from './addCheckItemAssociated';

interface Props {
  checkSource: QcCheckItemListType[]; // 关联检验项的数据
  setCheckItemList: (lists: QcCheckItemListType[]) => void;
}

export default function CorrelationTestItemTable(props: Props) {
  const { setCheckItemList, checkSource } = props;

  const [selectedRowKeys, setSelectRowKeys] = useState<React.Key[]>([]);
  const [showBatchLine, setShowBatchLine] = useState<boolean>(false);
  const [addVisible, setAddVisible] = useState(false); // 显示新增关联检验项弹框

  const handleDelete = (selectedIds: React.Key[], type: string) => {
    const newCheckSource: QcCheckItemListType[] = checkSource?.filter(
      (node: QcCheckItemListType) => !selectedIds.includes(node?.id),
    );

    if (type === 'batch') {
      setShowBatchLine(false);
    }
    setCheckItemList(newCheckSource);
  };

  const renderBatchLine = () => {
    const selectedNum = selectedRowKeys?.length;

    return (
      <div
        style={{
          marginBottom: 20,
          marginTop: 20,
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
        }}
      >
        <div style={{ marginRight: 20 }}>
          已选：
          <i>{selectedNum}</i>条
        </div>
        <Button
          onClick={() => {
            Modal.confirm({
              title: '你确定要删除吗？',
              icon: <ExclamationCircleOutlined />,
              okText: '确认',
              cancelText: '取消',
              onOk: async () => {
                handleDelete(selectedRowKeys, 'batch');
              },
            });
          }}
        >
          删除
        </Button>
        <div style={{ position: 'absolute', right: 0 }}>
          <Button
            icon={<BlIcon type="iconfanhui" />}
            onClick={() => {
              setSelectRowKeys([]);
              setShowBatchLine(false);
            }}
          >
            返回
          </Button>
        </div>
      </div>
    );
  };

  const renderAddActon = () => {
    return (
      <div style={{ marginBottom: 20, marginTop: 20 }}>
        <Button
          type="dashed"
          icon={<BlIcon type="iconxinjiantianjia" />}
          style={{ color: '#02B980', borderColor: '#02B980' }}
          onClick={() => {
            setAddVisible(true);
          }}
        >
          新增关联检验项
        </Button>
      </div>
    );
  };

  const getColumns = [
    {
      title: '检验项编号',
      dataIndex: 'code',
      width: 350,
      render: (code: string) => code,
    },
    {
      title: '检验项名称',
      dataIndex: 'name',
      width: 350,
      render: (name: string) => name,
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 150,
      render: (_: any, record: QcCheckItemListType) => {
        return (
          <Button
            disabled={showBatchLine}
            style={{ textAlign: 'left', padding: 0 }}
            type={'text'}
            onClick={() => {
              Modal.confirm({
                title: '你确定要删除吗？',
                icon: <ExclamationCircleOutlined />,
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                  handleDelete([record?.id], 'single');
                },
              });
            }}
          >
            删除
          </Button>
        );
      },
    },
  ];

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectRowKeys(selectedRowKeys);
    setShowBatchLine(!_Array.isEmpty(selectedRowKeys));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[]) => onSelectChange(selectedRowKeys),
  };

  const targetKeysSource = checkSource?.map((node: QcCheckItemListType) => node?.id);

  return (
    <>
      <h3>关联检验项</h3>
      {showBatchLine ? renderBatchLine() : renderAddActon()}
      <BlTable
        columns={getColumns}
        dataSource={checkSource}
        rowKey={(record) => record?.id}
        id="checkItemClassTable"
        rowSelection={rowSelection}
      />
      {addVisible && (
        <AddCheckItemAssociatedModal
          visible={addVisible}
          targetKeysSource={targetKeysSource}
          onCancel={() => {
            setAddVisible(false);
          }}
          setCheckItemList={setCheckItemList}
        />
      )}
    </>
  );
}
