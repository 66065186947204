/**
 * @enum 物料业务模块枚举值
 */

import { YN } from '../common';

/** 物料Tab类型 */
export enum TabType {
  /** 仓储信息 */
  warehousing = 1,
  /** 采购信息 */
  purchase,
  /** 销售信息 */
  sales,
  /** 自制信息 */
  production,
  /** 质量信息 */
  quality,
  /** 投料 */
  manufacture,
  /** 基本信息 */
  basic,
  /** 自定义信息 */
  customize,
}
/** 物料单位类型 */
export enum UnitType {
  /** 主单位 */
  mainUnit,
  /** 转换单位 */
  conversionUnit,
  /** 辅助单位 */
  auxUnit,
}
/** 物料类型 */
export enum BizType {
  /** 虚拟件 */
  phantom,
  /** 默认 */
  default,
  /** 在制品 */
  workInProgress,
}
export enum BizRange {
  /** 仓储 */
  warehousing = 1,
  /** 采购 */
  purchase,
  /** 销售 */
  sales,
  /** 生产 */
  production,
  /** 质量 */
  quality,
  /** 投料 */
  manufacture,
}
/** 物料 【批次/库存】属性名称 */
export enum BatchAttrs {
  /** 供应商批次 */
  supplierBatch,
  /** 供应商 */
  supplier,
  /** 客户 */
  customer,
  /** 生产日期 */
  productionDate,
  /** 有效期至 */
  validUntil,
  /** 入厂日期 */
  entryDate,
  /** 货主 */
  consignor,
}

/** 物料 【批次/库存】 属性类型 */
export enum BatchAttrsType {
  /** 单行文本 */
  singleText,
  /** 关联关系 */
  connectionRelation,
  /** 日期时间 */
  dateTime,
}

/** 物料仓储 先进先出属性 */
export enum FirstInFirstOutRules {
  /** 批次号 */
  batchNumber,
  /** 生产日期 */
  productionDate,
  /** 有效期至 */
  validUntil,
}

/** 物料投料 投料质量状态 */
export enum FeedingQualityStatus {
  /** 合格 */
  qualified = 1,
  /** 让步合格 */
  concession,
  /** 待检 */
  pending,
  /** 不合格 */
  unqualified,
}

/** 物料 批次属性 默认值 */
export const BATCHATTRS_DEFAULT = [
  {
    name: '供应商',
    type: BatchAttrsType.connectionRelation,
    isDefault: YN.yes,
    displayEnable: YN.no,
    nullEnable: YN.yes, // “是否可空”,默认为1
    serialNo: 0,
  },
  {
    name: '供应商批次',
    type: BatchAttrsType.singleText,
    isDefault: YN.yes,
    displayEnable: YN.no,
    nullEnable: YN.yes, // “是否可空”,默认为1
    serialNo: 1,
  },
  {
    name: '客户',
    type: BatchAttrsType.connectionRelation,
    isDefault: YN.yes,
    displayEnable: YN.no,
    nullEnable: YN.yes, // “是否可空”,默认为1
    serialNo: 2,
  },
  {
    name: '生产日期',
    type: BatchAttrsType.dateTime,
    isDefault: YN.yes,
    displayEnable: YN.no,
    nullEnable: YN.yes, // “是否可空”,默认为1
    serialNo: 3,
  },
  {
    name: '有效期至',
    type: BatchAttrsType.dateTime,
    isDefault: YN.yes,
    displayEnable: YN.no,
    nullEnable: YN.yes, // “是否可空”,默认为1
    serialNo: 4,
  },
];
/** 物料 库存属性 默认值 */
export const INVENTORYATTRS_DEFAULT = [
  // {
  //   name: '货主',
  //   type: BatchAttrsType.connectionRelation,
  //   isDefault: YN.yes,
  //   displayEnable: YN.no,
  //   nullEnable: YN.yes, // “是否可空”,默认为1
  //   serialNo: 0,
  // },
  {
    name: '供应商',
    type: BatchAttrsType.connectionRelation,
    isDefault: YN.yes,
    displayEnable: YN.no,
    nullEnable: YN.yes, // “是否可空”,默认为1
    serialNo: 1,
  },
  {
    name: '供应商批次',
    type: BatchAttrsType.singleText,
    isDefault: YN.yes,
    displayEnable: YN.no,
    nullEnable: YN.yes, // “是否可空”,默认为1
    serialNo: 2,
  },
  {
    name: '客户',
    type: BatchAttrsType.connectionRelation,
    isDefault: YN.yes,
    displayEnable: YN.no,
    nullEnable: YN.yes, // “是否可空”,默认为1
    serialNo: 3,
  },
  {
    name: '生产日期',
    type: BatchAttrsType.dateTime,
    isDefault: YN.yes,
    displayEnable: YN.no,
    nullEnable: YN.yes, // “是否可空”,默认为1
    serialNo: 4,
  },
  {
    name: '有效期至',
    type: BatchAttrsType.dateTime,
    isDefault: YN.yes,
    displayEnable: YN.no,
    nullEnable: YN.yes, // “是否可空”,默认为1
    serialNo: 5,
  },
  {
    name: '入厂日期',
    type: BatchAttrsType.dateTime,
    isDefault: YN.yes,
    displayEnable: YN.no,
    nullEnable: YN.yes, // “是否可空”,默认为1
    serialNo: 6,
  },
];

/** *************************** 物料分类 ************************** */

/** 物料分类类型 */
export enum CategoryType {
  /** 系统预置 */
  systempreset,
  /** 用户创建 */
  usercreate,
}

/** *************************** 单位定义 ************************** */
/** 转换单位类型 */
export enum UnitsConvertType {
  /** 主单位 */
  main = 0,
  /** 固定单位（转换单位） */
  fixed,
  /** 浮动单位（辅助单位） */
  float,
}

/** 修约规则 */
export enum RoundingRule {
  /** 四舍五入 */
  round,
  /** 奇进偶舍 */
  bankerRounding,
  /** 进位 */
  floor,
  /** 舍位 */
  ceil,
}

/** 投料类型 */

export enum FeedingType {
  feeding,
  feedingAddApplication,
}
