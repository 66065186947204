/*
 * @Author: huangsijia
 * @Date: 2021-11-22 15:40:00
 * @LastEditTime: 2022-02-25 13:45:59
 * @LastEditors: huangsijia
 * @Description: 新建/编辑不良原因弹窗
 * @FilePath: /bf-main-3/src/page/quality/qcDefectReason/components/QcDefectReasonModal.tsx
 */

import React, { useState } from 'react';
import { Form, Checkbox, message as Message, Input } from 'antd';
import {
  fetchQcDefectReasonCreate,
  fetchQcDefectReasonUpdate,
} from 'src/api/ytt/quality-domain/qc_defect_reason';

import { CustomDataFormLayout } from 'src/components/customLayout';
import { useHistory } from 'react-router-dom';
import { gcUrl } from 'src/utils';
import _ from 'lodash';
import { formatCustomFieldValueToSubmit } from 'src/components/customLayout/uitls/dataFormat';
interface createProps {
  match: any;
}

const CREATE = {
  toast: '创建成功',
  service: fetchQcDefectReasonCreate,
  title: '创建不良原因',
};

const EDIT = {
  toast: '编辑成功',
  service: fetchQcDefectReasonUpdate,
  title: '编辑不良原因',
};

const QcDefectReasonEdit = ({ match }: createProps) => {
  const detailId = Number(match?.params?.id) ?? 0;
  const { layoutId } = gcUrl.getParams();
  const [modalForm] = Form.useForm();
  const [continuousCreate, setContinuousCreate] = useState(false);
  const history = useHistory();

  const MODALCONSTS = detailId ? EDIT : CREATE;

  const extra = (
    <Checkbox
      onChange={(e) => {
        setContinuousCreate(e?.target?.checked);
      }}
    >
      连续新建
    </Checkbox>
  );

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();

      value.customFields = formatCustomFieldValueToSubmit(
        modalForm.getFieldValue('customFields'),
        layoutId,
      );
      if (detailId) {
        value.id = detailId;
      }
      const { data } = await MODALCONSTS.service(value);

      if (data) {
        Message.success(MODALCONSTS.toast);
        if (continuousCreate) {
          modalForm.resetFields();
          return;
        }
        history.go(-1);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <CustomDataFormLayout
      objectCode={'CauseOfDefect'}
      form={modalForm}
      onFinish={handleFinish}
      title={MODALCONSTS.title}
      layoutId={layoutId}
      instanceIdParamKey={detailId}
      extraInfoItems={_.compact([
        detailId && {
          label: '编辑原因',
          name: 'operateReason',
          rules: [{ max: 1000, message: '不超过1000个字符' }],
          render: () => <Input.TextArea placeholder="请输入" />,
        },
      ])}
      extra={detailId ? null : extra}
    />
  );
};

export default QcDefectReasonEdit;
