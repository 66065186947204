import React from 'react';
import { Radio, FormInstance, Form, Select } from 'antd';
import { appDict, appEnum } from 'src/dict';
import { deleteOptions } from 'src/dict/customField/mappings';
import { FieldCategory, RelationDeleteOption } from 'src/dict/customField';
import { FieldBaseProps } from 'src/page/custom_fields/fieldsList/index.d';

export const relationItems = ({ form, isPreField, isEdit }: FieldBaseProps) => {
  return [
    {
      label: '级联删除',
      name: 'deleteType',
      initialValue: RelationDeleteOption.allowed,
      render: () => <Radio.Group options={deleteOptions} disabled />,
    },
    {
      label: '访问控制规则',
      name: 'viewRule',
      initialValue: 1,
      render: () => '自身控制',
    },
    {
      label: '可选数据范围',
      name: 'dataRange',
      initialValue: 1,
      render: () => (
        <Radio.Group>
          <Radio value={1}>全部</Radio>
        </Radio.Group>
      ),
    },
    {
      label: '必填',
      name: 'isRequired',
      initialValue: 0,
      rules: [{ required: true, message: '请选择' }],
      render: () => (
        <Radio.Group
          disabled={
            isEdit && isPreField && form.getFieldValue('isRequired') === appEnum.Common.YN.yes
          }
          options={appDict.common.yn}
        />
      ),
    },
    {
      label: '唯一属性',
      name: 'isUnique',
      initialValue: appEnum.Common.YN.yes,
      rules: [{ required: true, message: '请选择' }],
      render: () => <Radio.Group disabled={isEdit} options={appDict.common.yn} />,
    },
  ];
};
