import { Form, Input, Select, FormInstance } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { NamePath } from 'antd/lib/form/interface';
import { ColumnProps } from 'antd/lib/table';
import { BlSortFormList, MaterialSelect, TagList } from 'src/components';
import { UsageStatus } from 'src/dict/common';
import { getMaterialUnitInfo, getMaterialUnits, getMaterialAttrs } from 'src/entity/material';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import _, { isEqual, set } from 'lodash';
import BatchSearchSelect from '../../batchManagement/components/batchSearchSelect';
import { TextControl } from 'src/components/text';
import { outBoundItemEnum } from 'src/dict/bound';
import { injectCustomFieldColumns } from 'src/components/customField';
import React from 'react';

interface Props {
  form: FormInstance;
  source?: any;
  name: string;
  items: any[];
  editDisabled?: boolean;
  customFields?: any;
}

const width = '100%';

function OutBoundMaterial(props: Props) {
  const { form, name, source, editDisabled, items, customFields } = props;
  const getMaterialInfo = (namepath: NamePath) => {
    const value = JSON.parse(form.getFieldValue(namepath)?.value ?? '{}');

    return value;
  };
  /**
   * 判断物料行的状态，如果状态是已执行，物料不能编辑
   * @param bizStatus 状态
   * @returns Boolean
   */
  const executingFn = (bizStatus: number) => {
    return typeof bizStatus !== 'undefined' && bizStatus !== outBoundItemEnum.TO_EXECUTE;
  };

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    // 字段项相同属性
    const getOptions = (field: any, fieldName: string) => ({
      fieldKey: [field.fieldKey, fieldName],
      name: [field.name, fieldName],
      validateFirst: true,
      validateTrigger: ['onChange', 'onBlur'],
      style: { marginBottom: '0' },
    });

    return _.compact([
      {
        dataIndex: 'lineNo',
        title: <div>行号</div>,
        width: 80,
        render: (text: string, field: any) => {
          return (
            <div key={field.name}>
              {field.name + 1}
              <Form.Item {...getOptions(field, 'lineNo')} initialValue={field.name + 1} hidden>
                <Input />
              </Form.Item>
            </div>
          );
        },
      },
      {
        dataIndex: 'materialId',
        title: '物料',
        width: 140,
        render: (text: string, field: any) => {
          const bizStatus = items?.[field.key]?.bizStatus;

          return (
            <div key={field.key}>
              <Form.Item
                {...getOptions(field, 'materialId')}
                rules={[{ required: true, message: '请选择物料' }]}
              >
                <MaterialSelect
                  disabled={editDisabled || executingFn(bizStatus)}
                  params={{ enableFlag: UsageStatus.enabled }}
                  onChange={() => {
                    form?.setFieldsValue(
                      set(form?.getFieldsValue(), [name, field.name, 'batchNo'], undefined),
                    );
                  }}
                />
              </Form.Item>
            </div>
          );
        },
      },
      {
        dataIndex: 'materialName',
        title: '物料名称',
        width: 160,
        render: (text: string, field: any) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() =>
                _.get(getMaterialInfo([name, field.name, 'materialId']), 'baseInfo.name') || '-'
              }
            </Form.Item>
          );
        },
      },
      {
        dataIndex: 'materialName',
        title: '物料属性',
        width: 160,
        render: (text: string, field: any) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() => (
                <TagList
                  dataSource={getMaterialAttrs(getMaterialInfo([name, field.name, 'materialId']))}
                />
              )}
            </Form.Item>
          );
        },
      },
      {
        dataIndex: 'materialName',
        title: '物料规格',
        width: 120,
        render: (text: string, field: any) => {
          return (
            <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
              {() =>
                _.get(
                  getMaterialInfo([name, field.name, 'materialId']),
                  'baseInfo.specification',
                ) || '-'
              }
            </Form.Item>
          );
        },
      },
      {
        dataIndex: 'batchNo',
        title: '批次',
        width: 160,
        render: (text: string, field: any) => (
          <Form.Item
            dependencies={[[name, field.name, 'materialId']]}
            shouldUpdate
            style={{ marginBottom: 0 }}
          >
            {() => {
              const material = getMaterialInfo([name, field.name, 'materialId']);

              return (
                <Form.Item {...getOptions(field, 'batchNo')}>
                  <BatchSearchSelect
                    enableAdd={false}
                    labelInValue
                    disabled={!material?.baseInfo?.id || !material.batchManagementEnable}
                    params={{ materialIds: [material?.baseInfo?.id] }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        ),
      },
      {
        dataIndex: 'planAmount',
        title: '应发数量',
        width: 160,
        render: (text: string, field: any) => (
          <Form.Item
            dependencies={[
              [name, field.name, 'unitId'],
              [name, field.name, 'materialId'],
            ]}
            style={{ marginBottom: 0 }}
          >
            {() => {
              const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
                getMaterialInfo([name, field.name, 'materialId']),
                form.getFieldValue([name, field.name, 'unitId'])?.value,
              );
              const amount = items?.[field.key]?.doneAmount;
              const doneAmount = Number(amount) || 0;
              const minAllowEqual = Boolean(doneAmount);

              return (
                <Form.Item
                  {...getOptions(field, 'planAmount')}
                  rules={_.compact([
                    { required: true, message: '请填写' },
                    {
                      validator: numberMinMaxCheck({
                        max: 10000000,
                        min: doneAmount,
                        minAllowEqual,
                      }),
                    },
                    enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
                  ])}
                >
                  <Input disabled={editDisabled} style={{ width }} placeholder="请输入" />
                </Form.Item>
              );
            }}
          </Form.Item>
        ),
      },
      {
        dataIndex: 'unitId',
        title: '单位',
        width: 160,
        render: (text: string, field: any) => {
          const bizStatus = items?.[field.key]?.bizStatus;

          return (
            <Form.Item
              dependencies={[[name, field.name, 'materialId']]}
              style={{ marginBottom: 0 }}
            >
              {() => {
                return (
                  <Form.Item
                    {...getOptions(field, 'unitId')}
                    rules={[{ required: true, message: '请选择单位' }]}
                  >
                    <Select
                      labelInValue
                      placeholder={'请选择'}
                      disabled={editDisabled || executingFn(bizStatus)}
                      options={getMaterialUnits(getMaterialInfo([name, field.name, 'materialId']))}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        dataIndex: 'remark',
        title: '备注',
        width: 160,
        render: (text: string, field: any) => (
          <div key={field.key}>
            <Form.Item
              {...getOptions(field, 'remark')}
              rules={[{ max: 1000, message: '不超过1000字符' }]}
            >
              <Input style={{ width }} placeholder="请输入" />
            </Form.Item>
          </div>
        ),
      },
      source && {
        dataIndex: 'entityCode',
        title: source === 'invoice' ? '发货单编号' : '采购退货单编号',
        width: 160,
        render: (text: string, field: any) => (
          <div key={field.name}>
            <Form.Item {...getOptions(field, 'entityCode')}>
              <TextControl />
            </Form.Item>
          </div>
        ),
      },
      source && {
        dataIndex: 'seqNum',
        title: source === 'invoice' ? '发货单行号' : '采购退货单行号',
        width: 160,
        render: (text: string, field: any) => (
          <div key={field.name}>
            <Form.Item {...getOptions(field, 'seqNum')}>
              <TextControl />
            </Form.Item>
          </div>
        ),
      },
    ]);
  };

  return (
    <BlSortFormList
      buttonText="添加物料行"
      form={form}
      name={name}
      isNeedAdd={!editDisabled} // 如果是 其他来源 => 新建出库单，则不需要添加按钮
      isNeedDelete={!editDisabled} // 如果是 其他来源 => 新建出库单，则不需要删除按钮
      isNeedDrag={false}
      fixedRowFn={(field, index) => executingFn(items?.[field.key]?.bizStatus)}
      renderColumns={() =>
        injectCustomFieldColumns({
          columns: getColumns(), // 原本的列
          customFields, // 自定义字段信息
          objectCode: 'OutboundOrderItem', // 从对象code
          type: 'form', // 使用类型
          formConfig: { form, formName: name },
        })
      }
    />
  );
}

export default React.memo(OutBoundMaterial, isEqual);
