import React, { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import {
  fetchMaterialCheckInventory,
  fetchMaterialDetail,
} from 'src/api/ytt/material-domain/Web_MaterialBaseInfo';
import { fetchMaterialInventoryDetail } from 'src/api/ytt/material-domain/Web_MaterialInventory';
import { fetchMaterialProductionDetail } from 'src/api/ytt/material-domain/Web_MaterialProduction';
import { fetchMaterialPurchaseDetail } from 'src/api/ytt/material-domain/Web_MaterialPurchase';
import { CRUD, YN } from 'src/dict/common';
import {
  BATCHATTRS_DEFAULT,
  BizRange,
  BizType,
  FeedingQualityStatus,
  FirstInFirstOutRules,
  INVENTORYATTRS_DEFAULT,
} from 'src/dict/material';
import { BizRange_DEFAULT } from 'src/dict/material/mappings';
import { MaterialFormData, MaterialInfo, MaterialSaveRequest } from '..';
import {
  handleFormatAttrs,
  handleFormatBackAttrs,
  handleFormatBackBatchAttrs,
  handleFormatBackLabelValue,
  handleFormatBackTransformUnits,
  handleFormatDocuments,
  handleFormatLabelValue,
  handleFormatTransformUnits,
} from '../utils';
import BaseForm from './baseForm';
import { storageValidityUnit, STORAGE_DAY } from '../constant';
import _, { isEmpty, isNil } from 'lodash';
import {
  fetchMaterialSaveCreate,
  fetchMaterialSaveUpdate,
} from 'src/api/ytt/material-domain/Web_Material';
import { MaterialCreateUnitForm } from './index.d';
import { fetchMaterialFeedDetail } from 'src/api/ytt/material-domain/web_MaterialFeed';
import {
  formatCustomFieldsInData,
  initCustomFieldsInData,
  useCustomFieldCombinedData,
} from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

interface CreateAndEditPorps extends RouteChildrenProps<{ id: string }> {}
const MaterialEditPage = (props: CreateAndEditPorps) => {
  const { history, location, match } = props;
  const [loading, setLoading] = useState(true);
  const [initialValue, setInitialValue] = useState<Partial<MaterialFormData>>();
  const [type, setType] = useState(CRUD.create);
  /** 获取对象自定义字段 */
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.material);

  const handleInitCreate = (): Partial<MaterialFormData> => {
    return {
      // 物料 基本信息
      bizType: BizType.default,
      bizRange: BizRange_DEFAULT,
      batchManagementEnable: YN.no,
      batchAttrs: BATCHATTRS_DEFAULT,
      // 物料 仓储信息
      timeUnit: {
        value: STORAGE_DAY,
        key: STORAGE_DAY,
        label: storageValidityUnit.get(STORAGE_DAY)!,
      }, // 默认为 日
      fifoEnabled: YN.no, // 先进先出，默认不启用
      fifoAttr: FirstInFirstOutRules.batchNumber,
      inventoryAttrs: INVENTORYATTRS_DEFAULT,
      // 物料 采购信息
      sourceControl: YN.no,
      // 物料 生产信息
      manufactureQualityStatus: [FeedingQualityStatus.qualified, FeedingQualityStatus.concession],
    };
  };
  const handleInitEdit = (materialData: MaterialInfo): Partial<MaterialFormData> => {
    const formatData = {
      // 基本信息
      bizRange: _.orderBy(materialData?.bizRange),
      bizType: materialData?.bizType,
      code: materialData?.code,
      name: materialData?.name,
      qrCode: materialData?.qrCode,
      remark: materialData?.remark,
      documents: materialData?.documents,
      batchManagementEnable: materialData?.batchManagementEnable,
      unit: {
        value: materialData?.unit?.id,
        label: materialData?.unit?.name,
      },
      unitRelationId: materialData?.unit?.relationId,
      category: materialData?.category?.categoryIds,
      specification: materialData?.specification,
      categoryRelationId: materialData?.category?.relationId,
      attributes: handleFormatBackAttrs(materialData?.attributes),
      transformUnit: handleFormatBackTransformUnits(
        materialData?.conversionUnits,
        materialData?.unit,
        materialData?.auxUnitList,
        Boolean(materialData?.hasStorage?.flag),
      ),
      batchAttrs: handleFormatBackBatchAttrs(materialData?.batchAttrs as any),
      batchNoRule: handleFormatBackLabelValue(materialData?.batchNoRule),
      // 仓储信息
      convertUnit: handleFormatBackLabelValue(materialData?.convertUnit),
      convertUnitRelationId: materialData?.convertUnit?.relationId,
      warehouse: handleFormatBackLabelValue(materialData?.warehouse),
      location: handleFormatBackLabelValue(materialData?.location),
      time: materialData?.validity?.time,
      timeUnit: {
        value: materialData?.validity?.unit,
        label: storageValidityUnit.get(materialData?.validity?.unit!),
      },
      fifoEnabled: materialData?.fifoEnabled,
      fifoAttr: materialData?.fifoAttr,
      inventoryAttrs: handleFormatBackBatchAttrs(materialData?.inventoryAttrs),
      inventoryQrCodeRule: handleFormatBackLabelValue(materialData?.inventoryQrCodeRule),
      transferBatch: materialData?.transferBatchDisplay,
      transferUnitId: materialData?.transferUnitId,
      // 采购信息
      purchaseAmount: materialData?.purchaseAmount,
      purchaseAmountMin: materialData?.purchaseAmountMin,
      purchaseUnit: handleFormatBackLabelValue(materialData?.purchaseUnit),
      sourceControl: materialData?.sourceControl,
      taxRate: !isNil(materialData?.taxRate) ? Number(materialData?.taxRate) : null,
      // 自制信息
      productionUnit: handleFormatBackLabelValue(materialData?.productionUnit),
      orderBatchRule: materialData?.orderBatchRule,
      // 投料信息
      feedUnitId: handleFormatBackLabelValue(materialData?.feedUnit),
      qcStatus: materialData?.qcStatus || undefined,
      // 自定义字段
      customFields: materialData?.customFields,
    };

    return initCustomFieldsInData(formatData);
  };
  const fetchData = (id: number) => {
    Promise.all([
      fetchMaterialDetail({ id }),
      fetchMaterialInventoryDetail({ materialId: id }),
      fetchMaterialPurchaseDetail({ materialId: id }),
      fetchMaterialProductionDetail({ materialId: id }),
      fetchMaterialCheckInventory({ id }),
      fetchMaterialFeedDetail({ materialId: id }),
    ])
      .then((res) => {
        const [material, inventory, purchase, production, hasStorage, feedInfo] = res;
        const { data: materialDetails } = material;
        const { data: inventoryDetails } = inventory;
        const { data: purchaseDetails } = purchase;
        const { data: productionDetails } = production;
        const { data: hasStorageDetails } = hasStorage;
        const { data: feedDetail } = feedInfo;
        const materialData: MaterialInfo = {
          ...materialDetails!,
          ...inventoryDetails!,
          ...purchaseDetails!,
          ...productionDetails!,
          ...feedDetail!,
          hasStorage: hasStorageDetails!,
        };
        const editInitValue = handleInitEdit(materialData);

        setInitialValue(editInitValue);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const handleFormatData = (_params: MaterialFormData): Partial<MaterialSaveRequest> => {
    const materialId = _.isNil(match!.params.id) ? undefined : Number(match!.params.id);
    const mainUnit = _.head<MaterialCreateUnitForm>(_params?.transformUnit ?? [])?.toUnitId;
    const params = formatCustomFieldsInData({ data: _params, customFields });

    const baseInfo = _.pickBy(
      {
        id: materialId,
        name: params?.name,
        code: params?.code,
        qrCode: params?.qrCode,
        numberRuleId: params?.numberRuleId,
        bizRange: params?.bizRange,
        bizType: params?.bizType,
        specification: params?.specification,
        unit: {
          id: _.head<MaterialCreateUnitForm>(params?.transformUnit ?? [])?.toUnitId?.value,
          relationId: _.head<MaterialCreateUnitForm>(params?.transformUnit ?? [])?.relationId,
        },
        category: {
          categoryIds:
            params?.category && params.category?.length > 0 ? params?.category : undefined,
          relationId:
            params?.category && params.category?.length > 0
              ? params?.categoryRelationId
              : undefined,
        },
        attributes: handleFormatAttrs(params?.attributes),
        documents: handleFormatDocuments(params?.documents),
        batchManagementEnable: Number(params?.batchManagementEnable),
        batchAttrs: params?.batchAttrs,
        batchNoRule: handleFormatLabelValue(params?.batchNoRule),
        remark: params?.remark,
        materialCustomField: isEmpty(params?.customFields)
          ? null
          : {
              customFields: params?.customFields,
            },
        reason: params?.reason,
      },
      (obj) => !_.isNil(obj),
    );
    const inventoryData = _.pickBy(
      {
        materialId,
        convertUnit: handleFormatLabelValue({
          ...params?.convertUnit,
          relationId: params?.convertUnitRelationId,
        }),
        warehouse: handleFormatLabelValue(params?.warehouse),
        location: handleFormatLabelValue(params?.location),
        validity: params?.time ? { time: params?.time, unit: params?.timeUnit?.value } : undefined,
        fifoEnabled: _.isNil(params?.fifoEnabled) ? undefined : Number(params?.fifoEnabled),
        fifoAttr: params?.fifoAttr,
        inventoryAttrs: params?.inventoryAttrs,
        inventoryQrCodeRule: handleFormatLabelValue(params?.inventoryQrCodeRule),
        transferBatch: params?.transferBatch,
        transferUnitId: params?.transferUnitId,
      },
      (obj) => !_.isNil(obj),
    );
    const purchaseData = _.pickBy(
      {
        materialId,
        purchaseAmount: _.isNil(params?.purchaseAmount)
          ? undefined
          : Number(params?.purchaseAmount),
        purchaseAmountMin: _.isNil(params?.purchaseAmountMin)
          ? undefined
          : Number(params?.purchaseAmountMin),
        purchaseUnit: handleFormatLabelValue(params?.purchaseUnit),
        sourceControl: _.isNil(params?.sourceControl) ? undefined : Number(params?.sourceControl),
        taxRate: _.isNil(params?.taxRate) ? undefined : Number(params?.taxRate),
      },
      (obj) => !_.isNil(obj),
    );
    const productionData = _.pickBy(
      {
        materialId,
        productionUnit: handleFormatLabelValue(params?.productionUnit),
        orderBatchRule: params?.orderBatchRule,
      },
      (obj) => !_.isNil(obj),
    );
    const unitList = _.pickBy(
      {
        id: materialId,
        unitId: _.toNumber(mainUnit.value),
        conversionUnits: handleFormatTransformUnits(params?.transformUnit),
      },
      (obj) => !_.isNil(obj),
    );
    const feedData = _.pickBy(
      {
        materialId,
        feedUnitId: params?.feedUnitId?.value,
        qcStatus: params.qcStatus,
      },
      (obj) => !_.isNil(obj),
    );
    const inventoryInfo = _.isEmpty(inventoryData) ? null : inventoryData;
    const purchaseInfo = _.isEmpty(purchaseData) ? null : purchaseData;
    const productionInfo = _.isEmpty(productionData) ? null : productionData;
    const feedInfo = _.isEmpty(feedData) ? null : feedData;

    // TODO 业务范围如果不存在该模块，则传null
    return {
      baseInfo,
      inventoryInfo:
        _.findIndex(params?.bizRange, (o) => o === BizRange.warehousing) === -1
          ? null
          : inventoryInfo,
      purchaseInfo:
        _.findIndex(params?.bizRange, (o) => o === BizRange.purchase) === -1 ? null : purchaseInfo,
      productionInfo:
        _.findIndex(params?.bizRange, (o) => o === BizRange.production) === -1
          ? null
          : productionInfo,
      unitList,
      feedInfo:
        _.findIndex(params?.bizRange, (o) => o === BizRange.manufacture) === -1 ? null : feedInfo,
      operateReason: params?.reason,
    };
  };
  const handleCreate = async (values: MaterialFormData, onSuccess?: (msg: string) => void) => {
    try {
      const params = handleFormatData(values);

      setLoading(true);
      await fetchMaterialSaveCreate(params);
      setLoading(false);
      onSuccess?.('物料新建成功');
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };
  const handleEdit = async (values: MaterialFormData, onSuccess?: (msg: string) => void) => {
    try {
      const params = handleFormatData(values);

      setLoading(true);
      await fetchMaterialSaveUpdate(params);
      setLoading(false);
      onSuccess?.('物料编辑成功');
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // "Init edit "
    if (location.pathname.includes('edit') && match) {
      setType(CRUD.edit);
      fetchData(Number(match.params.id));
      return;
    }
    //  "Init create "
    const createInitValue = handleInitCreate();

    setInitialValue(createInitValue);
    setLoading(false);
  }, []);

  return (
    <BaseForm
      loading={loading}
      type={type}
      history={history}
      initialValue={initialValue}
      onSubmit={(values, onSuccess) => {
        if (type === CRUD.create) handleCreate(values, onSuccess);
        if (type === CRUD.edit) handleEdit(values, onSuccess);
      }}
      customFields={customFields}
    />
  );
};

export default MaterialEditPage;
