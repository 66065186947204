/**
 * 投放计划订单
 */
import { useState } from 'react';
import { RouterProps } from 'react-router-dom';
import { map, debounce, get, toString, isEmpty, compact, cloneDeep, forEach } from 'lodash';
import { Button, Form, Input, message, InputNumber, Select, DatePicker } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import type { NamePath } from 'antd/lib/form/interface';
import { DataFormLayout, DataFormLayoutForModal } from 'src/layout';
import { BatchOperateTableHeader, BlSortFormList, SearchSelect, TagList } from 'src/components';
import { appEnum } from 'src/dict';
import _Array from 'src/utils/dataTypes/array';
import { validatorCheckTwoSidesTrim } from 'src/page/custom_fields/fieldsList/components/verificationRules';

import { fetchPlanOrderLaunch, FetchPlanOrderLaunchRequest } from 'src/api/ytt/plo-domain';
import { getMaterialAttrs } from 'src/entity/material';
import { replaceSign } from 'src/utils/constants';
import SplitProductionTasks from './splitProductionTasks';
import { numberMinMaxCheck, textValidator2 } from 'src/utils/formValidators';
import { arrayIsEmpty } from 'src/utils/array';
import _Time from 'src/utils/dataTypes/time';
import { gcTime } from 'src/utils';
import { fetchMaterialDetail } from 'src/api/ytt/material-domain/Web_MaterialBaseInfo';
import { BatchGenerationTypeDisplayMap } from 'src/dict/productionPlanning/mappings';
import { BatchGenerationTypeEnum } from 'src/dict/productionPlanning';
import { OBJECT_OF_ID } from 'src/entity/objectPlatform';
import BatchSearchSelect from 'src/page/warehouseManagement/batchManagement/components/batchSearchSelect';

// eslint-disable-next-line import/no-cycle
import { handleBatchMessage } from '../list/plannedOrderList';
import { ProductionTaskFormProps } from '../createAndEdit/index.type';
import { LabeledValue } from 'antd/lib/select';

interface FeedingControlFormProps extends RouterProps {
  name: string;
  params: any; // 子项物料行已填写数据
  onCancel: () => void;
  onChange: () => void;
}

const ProductionTaskForm = (props: FeedingControlFormProps) => {
  const { name, params, onCancel, onChange } = props;

  const [form] = Form.useForm<ProductionTaskFormProps>();

  const [splitProductionTasks, setSplitProductionTasks] = useState<FormListFieldData | undefined>(); // 拆分生产任务

  const [base, setBase] = useState<any>(); // 拆分生产任务

  /**
   * 获取行的初始值
   * @param defaultValue
   * @param field
   * @returns
   */
  const getDefaultValue = (field: FormListFieldData, defaultValue: unknown) => {
    if (get(params, [name, field.name])) return undefined;
    return defaultValue;
  };

  // 校验重复
  const validateRepeat = (recordForm: any, filedName: NamePath, validateName: string) => {
    return (_rule: any, value: any) => {
      return new Promise((resolve, reject) => {
        if (!value) {
          return resolve(true);
        }
        const valueList = recordForm.getFieldValue(filedName);

        // 从 valueList 找出重复的项组成 []
        const list = valueList.map((el: any) => String(el?.[validateName]));

        const repeatList = _Array.findDuplicates(list);

        // value 如果存在 在重复项 [] 中，则 reject
        const condition = repeatList.indexOf(value);

        if (condition !== -1) {
          reject('已存在该选项！');
          return;
        }
        return resolve(true);
      });
    };
  };

  const getMaterialInfo = (fieldKey: number, key?: string, value?: any) => {
    let material = {};

    if (value) {
      material = value?.value ?? '{}';
    } else {
      material = form.getFieldValue([name, fieldKey, 'material']) ?? '{}';
    }

    if (key) {
      return get(material, key);
    }

    return material;
  };

  /**
   * 批号控件
   *
   * parameter  batchGenerationType：number  <规则编号 | 批号 | 不生成>
   */
  const renderProductBatchComp = (batchGenerationType: number, lineFieldName: any) => {
    let displayEle = <Select style={{ width: '100' }} disabled />;
    const materialinfo = getMaterialInfo(lineFieldName);

    switch (batchGenerationType) {
      case BatchGenerationTypeEnum.NUMBERINGRULES: {
        displayEle = (
          <SearchSelect
            labelInValue
            fetchType={'numberingRules'}
            placeholder={'请选择'}
            style={{ width: '100' }}
            searchFieldName={'quickQueryParam'}
            params={{
              enableFlag: appEnum.Common.UsageStatus.enabled,
              suitObjId: OBJECT_OF_ID.batchcode,
            }}
          />
        );
        break;
      }
      case BatchGenerationTypeEnum.BATCH: {
        displayEle = (
          <BatchSearchSelect
            style={{ width: '100' }}
            materialinfo={materialinfo}
            labelInValue
            rendervalue="all"
            saveCallback={(val) => {
              form.setFields([
                {
                  name: [name, lineFieldName, 'batch'],
                  value: val,
                },
              ]);
            }}
          />
        );
        break;
      }
      case BatchGenerationTypeEnum.DOESNOTGENERATE: {
        displayEle = <Select disabled style={{ width: '100' }} />;
        break;
      }

      default:
        break;
    }
    return displayEle;
  };

  /**
   * 批量设置
   * @param amount
   */
  const handleBatchSetValue = (field: string, value: any) => {
    const formList = cloneDeep(form.getFieldValue(name));

    forEach(formList, (item) => {
      item[field] = value;
    });

    form.setFieldsValue({
      [name]: formList,
    });
  };

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '计划订单编号',
        dataIndex: 'planOrderCode',
        fixed: 'left',
        width: 200,
        render: (_text, field) => {
          return form.getFieldValue([name, field.name, 'planOrderCode']) ?? replaceSign;
        },
      },
      {
        title: '物料编号',
        dataIndex: 'inputMaterialControl',
        width: 200,
        render: (_text, field) => {
          return (
            <Form.Item style={{ marginBottom: '0' }}>
              {form.getFieldValue([name, field.name, 'material', 'baseInfo', 'code']) ??
                replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料名称',
        dataIndex: 'inputBoundType',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              initialValue={getDefaultValue(field, appEnum.Bom.InputBoundType.percentage)}
            >
              {form.getFieldValue([name, field.name, 'material', 'baseInfo', 'name']) ??
                replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料分类',
        dataIndex: 'category',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item noStyle>
              {form.getFieldValue([name, field.name, 'material', 'category', 'name']) ??
                replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料属性',
        dataIndex: 'attributes',
        width: 150,
        render: (text, field) => {
          const material = form.getFieldValue([name, field.name, 'material']);

          return (
            <Form.Item noStyle>
              <TagList dataSource={getMaterialAttrs(material)} />
            </Form.Item>
          );
        },
      },
      {
        title: '单位',
        dataIndex: 'unitName',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item
              name={[field.name, 'unitName']}
              fieldKey={[field.fieldKey, 'unitName']}
              style={{ marginBottom: '0' }}
              initialValue={form.getFieldValue([
                name,
                field.name,
                'processInfo',
                'processUnit',
                'name',
              ])}
            >
              {form.getFieldValue([name, field.name, 'unitName']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '计划生产数',
        dataIndex: 'plannedAmount',
        width: 150,
        render: (text, field) => {
          const formList = form.getFieldValue([name]);

          const { plannedAmount } = formList[field.name];

          return plannedAmount?.amountDisplay ?? replaceSign;
        },
      },
      {
        title: '已投放数',
        dataIndex: 'launchedAmount',
        width: 150,
        render: (text, field) => {
          const formList = form.getFieldValue([name]);

          const { launchedAmount } = formList[field.name];

          return launchedAmount?.amountDisplay ?? replaceSign;
        },
      },
      {
        title: '可投放数',
        dataIndex: 'canLaunchAmount',
        width: 150,
        render: (text, field) => {
          const formList = form.getFieldValue([name]);

          const { canLaunchAmount } = formList[field.name];

          return canLaunchAmount?.amountDisplay ?? replaceSign;
        },
      },
      {
        title: (
          <BatchOperateTableHeader
            titleText="计划开始时间"
            required
            rules={[{ required: true, message: '计划开始时间不能为空' }]}
            popoverFieldComponent={<DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />}
            batchOperation={(value) => handleBatchSetValue('planStartTime', value)}
          />
        ),
        dataIndex: 'planStartTime',
        width: 220,
        render: (text, field) => {
          return (
            <Form.Item
              name={[field.name, 'planStartTime']}
              fieldKey={[field.fieldKey, 'planStartTime']}
              style={{ marginBottom: '0' }}
              rules={[{ required: true, message: '计划开始时间不能为空' }]}
            >
              <DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />
            </Form.Item>
          );
        },
      },
      {
        title: (
          <BatchOperateTableHeader
            titleText="计划完工时间"
            required
            rules={[{ required: true, message: '计划完工时间不能为空' }]}
            popoverFieldComponent={<DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />}
            batchOperation={(value) => handleBatchSetValue('planFinishTime', value)}
          />
        ),
        dataIndex: 'planFinishTime',
        width: 220,
        render: (text, field) => {
          return (
            <Form.Item
              name={[field.name, 'planFinishTime']}
              fieldKey={[field.fieldKey, 'planFinishTime']}
              style={{ marginBottom: '0' }}
              rules={[
                { required: true, message: '计划完工时间不能为空' },
                {
                  validator: (_: any, value: any) => {
                    const startAt = form.getFieldValue([name, field.name, 'planStartTime']);

                    if (!value || startAt < value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('计划结束时间必须大于计划开始时间'));
                  },
                },
              ]}
            >
              <DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />
            </Form.Item>
          );
        },
      },
      {
        title: '单据编号',
        dataIndex: 'workOrderCode',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item
              name={[field.name, 'workOrderCode']}
              fieldKey={[field.fieldKey, 'workOrderCode']}
              style={{ marginBottom: '0' }}
              rules={[
                { required: true, message: '单据编号不能为空' },
                { max: 255, message: '不超过255字符' },
                { validator: validatorCheckTwoSidesTrim() },
                { validator: validateRepeat(form, name, 'workOrderCode') },
                { validator: textValidator2 },
              ]}
            >
              <Input />
            </Form.Item>
          );
        },
      },
      {
        title: '本次投放数',
        dataIndex: 'launchAmount',
        width: 150,
        render: (text, field) => {
          const formList = form.getFieldValue([name]);

          const { canLaunchAmount } = formList[field.name];

          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'launchAmount']}
              name={[field.name, 'launchAmount']}
              rules={compact([
                { required: true, message: '本次投放数必填' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: true,
                    max: canLaunchAmount?.amount,
                    maxAllowEqual: true,
                    fieldName: '本次投放数',
                  }),
                },
              ])}
            >
              <InputNumber style={{ width: '100%' }} placeholder="请输入" stringMode />
            </Form.Item>
          );
        },
      },
      {
        title: '生成规则',
        dataIndex: 'batchGeneration',
        width: 150,
        render: (_text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              dependencies={[[name, field.name, 'inputMaterialControl']]}
            >
              {() => {
                // 创建需要看物料中是否启用
                const batchManagementEnable = getMaterialInfo(field.name, 'batchManagementEnable');

                return (
                  <Form.Item
                    name={[field.name, 'batchGenerationType']}
                    fieldKey={[field.fieldKey, 'batchGenerationType']}
                    rules={[{ required: true, message: '批号不能为空' }]}
                    style={{ marginBottom: 0 }}
                    initialValue={
                      appEnum.ProductionPlanning.BatchGenerationTypeEnum.DOESNOTGENERATE
                    }
                  >
                    <Select
                      style={{ width: 100 }}
                      options={
                        batchManagementEnable
                          ? BatchGenerationTypeDisplayMap
                          : BatchGenerationTypeDisplayMap.filter((e) => e.value === 3)
                      }
                      allowClear
                      onChange={(val: number) => {
                        form.setFields([{ name: [name, field.name, 'batch'], value: undefined }]);

                        if (
                          val === appEnum.ProductionPlanning.BatchGenerationTypeEnum.NUMBERINGRULES
                        ) {
                          /** 默认带出物料上的编号规则 */
                          const materialId = get(getMaterialInfo(field.name, 'baseInfo'), 'id');

                          fetchMaterialDetail({ id: materialId }).then((res) => {
                            const { data } = res;

                            const batchSelectValue = {
                              key: data?.batchNoRule?.id,
                              value: data?.batchNoRule?.id,
                              label: data?.batchNoRule?.name,
                            };

                            form.setFields([
                              { name: [name, field.name, 'batch'], value: batchSelectValue },
                            ]);
                          });
                        }
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: <BatchOperateTableHeader titleText="批次号" />,
        dataIndex: 'batch',
        width: 200,
        render: (_text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              dependencies={[
                [name, field.name, 'inputMaterialControl'],
                [name, field.name, 'batchGenerationType'],
              ]}
            >
              {() => {
                const batchGenerationType = form.getFieldValue([
                  name,
                  field.name,
                  'batchGenerationType',
                ]);

                return (
                  <Form.Item
                    name={[field.name, 'batch']}
                    fieldKey={[field.fieldKey, 'batch']}
                    noStyle
                    style={{ marginBottom: 0 }}
                  >
                    {renderProductBatchComp(batchGenerationType, field.name)}
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '备注',
        dataIndex: 'remark',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item
              name={[field.name, 'remark']}
              fieldKey={[field.fieldKey, 'remark']}
              style={{ marginBottom: '0' }}
            >
              <Input.TextArea
                style={{ width: '100%' }}
                maxLength={1000}
                placeholder="请输入"
                showCount
                autoSize
              />
            </Form.Item>
          );
        },
      },
      {
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width: 100,
        render: (text, field) => {
          return (
            <Form.Item style={{ marginBottom: '0' }}>
              <Button
                style={{ padding: 0 }}
                type="link"
                onClick={() => {
                  // handleCopyClick(field);
                  setSplitProductionTasks(field);
                  setBase(form.getFieldValue([name, field.name]));
                }}
              >
                拆分
              </Button>
            </Form.Item>
          );
        },
      },
    ];
  };

  const baseInfo = {
    items: [
      {
        isFullLine: true,
        render: () => (
          <BlSortFormList
            name={name}
            renderColumns={() => getColumns()}
            isNeedDrag={false}
            isNeedAdd={false}
          />
        ),
      },
    ],
  };

  /**
   * 格式化数据给接口
   * @param value
   * @returns
   */
  // : WorkOrderRequest
  const formatValueToApi = (value: any): FetchPlanOrderLaunchRequest => {
    const launchRequests = map(
      value?.launchRequests,
      ({
        launchAmount,
        remark,
        planOrderId,
        batchGenerationType,
        batch,
        workOrderCode,
        planFinishTime,
        planStartTime,
      }) => {
        return {
          remark: toString(remark),
          launchAmount: Number(launchAmount),
          planOrderId,
          mainOutputBatchGenerationType: batchGenerationType,
          mainOutputBatchNumber:
            batchGenerationType === BatchGenerationTypeEnum.BATCH && batch?.key
              ? JSON.parse(batch?.key)?.batchNo
              : undefined,
          mainOutputBatchNumberId:
            batchGenerationType === BatchGenerationTypeEnum.BATCH && batch?.key
              ? JSON.parse(batch?.key)?.id
              : undefined,
          mainOutputBatchRuleId:
            batchGenerationType === BatchGenerationTypeEnum.NUMBERINGRULES
              ? Number(batch?.key)
              : undefined,
          workOrderCode,
          planFinishTime: planFinishTime ? Number(gcTime.formatToUnix(planFinishTime)) : undefined,
          planStartTime: planFinishTime ? Number(gcTime.formatToUnix(planStartTime)) : undefined,
        };
      },
    );

    return {
      launchRequests,
    };
  };

  const handleFinish = async () => {
    try {
      const formValue: any = await form.validateFields();

      if (arrayIsEmpty(formValue?.launchRequests)) {
        message.error('投放数量不可为空');
        return;
      }

      const value = await formatValueToApi(formValue);

      // if (typeof onSubmit === 'function') {
      fetchPlanOrderLaunch(value).then((res) => {
        handleBatchMessage('投放', res.data as any);
        onCancel();
        setTimeout(() => {
          onChange();
        }, 1000);
      });

      // }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const formatData = (params: any) => {
    // 时间数据格式化
    return map(
      params,
      (
        {
          canLaunchAmount,
          planFinishTime,
          planStartTime,
          batchGenerationType,
          batchRule,
          batchNumberId,
          batchNumber,
          ...rest
        },
        index,
      ) => {
        let _batch: LabeledValue;

        if (BatchGenerationTypeEnum.NUMBERINGRULES === batchGenerationType) {
          _batch = {
            key: String(batchRule?.id),
            label: batchRule?.name,
            value: String(batchRule?.id),
          };
        }

        if (BatchGenerationTypeEnum.BATCH === batchGenerationType) {
          _batch = {
            key: JSON.stringify({ id: batchNumberId, batchNo: batchNumber }),
            label: batchNumber,
            value: JSON.stringify({ id: batchNumberId, batchNo: batchNumber }),
          };
        }

        return {
          ...rest,
          order: index,
          canLaunchAmount,
          launchAmount: canLaunchAmount?.amount,
          planFinishTime: planFinishTime ? _Time.formatUnixMoment(planFinishTime) : undefined,
          planStartTime: planStartTime ? _Time.formatUnixMoment(planStartTime) : undefined,
          batch: _batch,
          batchGenerationType,
        };
      },
    );
  };

  return (
    <>
      <DataFormLayout
        form={form}
        info={[baseInfo]}
        title="投放计划订单"
        onCancel={() => {
          onCancel();
        }}
        onFinish={debounce(handleFinish, 1500)}
        formProps={{
          preserve: true,
          initialValues: { [name]: formatData(params) },
        }}
      />

      <DataFormLayoutForModal
        visible={!isEmpty(splitProductionTasks)}
        onClose={() => {
          setSplitProductionTasks(undefined);
        }}
        content={
          <SplitProductionTasks
            form={form}
            name="splitTasks"
            field={splitProductionTasks}
            base={base}
            onChange={() => {
              setSplitProductionTasks(undefined);
              form.resetFields(['splitTasks']);
            }}
          />
        }
      />
    </>
  );
};

export default ProductionTaskForm;
