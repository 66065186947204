import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Pagination,
  PaginationProps,
  Select,
  Tooltip,
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { BcAttachmentForm, BlSortFormList, BlTable } from 'src/components';
import { ICustomFields, injectCustomFieldColumns } from 'src/components/customField';
import { TextControl } from 'src/components/text';
import { CheckCountTypeEnum, RecordCheckItemTypeEnum } from 'src/dict/quality';
import { showDefectModal } from '../defectModal';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { RecordValue } from './recordValue';
import { QcTaskEntity } from 'src/page/quality/models/entities';
import _ from 'lodash';
import { fractionLengthCheck, negativeNumberCheck } from 'src/utils/formValidators';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { TextToolTip } from '@blacklake-web/component';
import { DEFAULT_PRECISION } from 'src/page/quality/models/constants';
import lookup from 'src/dict';
import { ColumnProps } from 'antd/lib/table';
import { inspectionResultOptions } from 'src/dict/quality/mappings';
interface CheckItemRecordProps {
  form: FormInstance;
  reportCount: number;
  customFields?: ICustomFields;
  qcTask?: QcTaskEntity;
  currentPage?: number;
  setActiveKey?: (key: string) => void;
}

const CheckItemRecord = forwardRef((props: CheckItemRecordProps, ref) => {
  const FIRST_PAGE = 1;
  const { form, reportCount, customFields, qcTask, currentPage, setActiveKey } = props;
  const [reportPage, setReportPage] = useState<number>(currentPage || FIRST_PAGE);
  const [, setRefreshMarker] = useState<number>(0);
  const [total, setTotal] = useState<number>(reportCount);
  const commonNamePath = ['reports', reportPage - 1, 'reportValues'];

  const renderColumns = (): any[] => [
    {
      title: '分组',
      width: 100,
      fixed: 'left',
      dataIndex: 'groupName',
      render: (_i: any, field: FormListFieldData) => (
        <Form.Item
          fieldKey={[field.fieldKey, 'groupName']}
          name={[field.name, 'groupName']}
          style={{ marginBottom: 0 }}
        >
          <TextControl format={(value) => <TextToolTip text={String(value)} width={100} />} />
        </Form.Item>
      ),
    },
    {
      title: '检验项名称',
      dataIndex: 'checkItemName',
      fixed: 'left',
      width: 120,
      render: (name: string, field: FormListFieldData) => (
        <div style={{ display: 'flex' }}>
          {form.getFieldValue([...commonNamePath, field.name, 'recordCheckItemType']) ===
            RecordCheckItemTypeEnum.yes && <span style={{ color: 'red' }}>*</span>}
          <Form.Item
            fieldKey={[field.fieldKey, 'checkItemName']}
            name={[field.name, 'checkItemName']}
            style={{ marginBottom: 0 }}
          >
            <TextControl />
          </Form.Item>
        </div>
      ),
    },
    {
      title: '检验项编号',
      width: 120,
      fixed: 'left',
      dataIndex: 'checkItemCode',
      render: (_i: any, field: FormListFieldData) => (
        <Form.Item
          fieldKey={[field.fieldKey, 'checkItemCode']}
          name={[field.name, 'checkItemCode']}
          style={{ marginBottom: 0 }}
        >
          <TextControl />
        </Form.Item>
      ),
    },
    !qcTask?.isGeneralQc() && {
      title: '抽检数量',
      width: 120,
      dataIndex: 'taskCheckCount',
      render: (checkCount: any, field: FormListFieldData) => {
        if (
          form.getFieldValue([...commonNamePath, field.name, 'checkCountType']) ===
          CheckCountTypeEnum.useExecuteSet
        ) {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              rules={[
                { validator: negativeNumberCheck() },
                {
                  validator: fractionLengthCheck(
                    qcTask?.qcUnit?.precisionFigure || DEFAULT_PRECISION,
                  ),
                },
              ]}
              fieldKey={[field.fieldKey, 'taskCheckCount']}
              name={[field.name, 'taskCheckCount']}
            >
              <InputNumber />
            </Form.Item>
          );
        }
        return (
          <Form.Item
            fieldKey={[field.fieldKey, 'taskCheckCount']}
            name={[field.name, 'taskCheckCount']}
            style={{ marginBottom: 0 }}
          >
            <TextControl format={(value) => value || '-'} />
          </Form.Item>
        );
      },
    },
    {
      title: '必填份数',
      width: 120,
      dataIndex: 'requireReportCount',
      render: (_i: any, field: FormListFieldData) => (
        <Form.Item
          fieldKey={[field.fieldKey, 'requireReportCount']}
          name={[field.name, 'requireReportCount']}
          style={{ marginBottom: 0 }}
        >
          <TextControl />
        </Form.Item>
      ),
    },
    {
      title: '总份数',
      width: 100,
      dataIndex: 'totalReportCount',
      defaultColConfig: { disabled: true },
      render: (_i: any, field: FormListFieldData) => (
        <Form.Item
          fieldKey={[field.fieldKey, 'totalReportCount']}
          name={[field.name, 'totalReportCount']}
          style={{ marginBottom: 0 }}
        >
          <TextControl />
        </Form.Item>
      ),
    },
    {
      title: '检验设备',
      width: 200,
      dataIndex: 'equipments',
      render: (_i: any, field: FormListFieldData) => (
        <Form.Item
          fieldKey={[field.fieldKey, 'equipments']}
          name={[field.name, 'equipments']}
          style={{ marginBottom: 0 }}
        >
          <TextControl
            format={(equipments: { name: string }[]) => _.map(equipments, 'name').join(',')}
          />
        </Form.Item>
      ),
    },
    {
      title: '其他说明',
      width: 200,
      dataIndex: 'checkItemRemark',
      render: (_i: any, field: FormListFieldData) => (
        <Form.Item
          fieldKey={[field.fieldKey, 'checkItemRemark']}
          name={[field.name, 'checkItemRemark']}
          style={{ marginBottom: 0 }}
        >
          <TextControl />
        </Form.Item>
      ),
    },
    {
      title: '实测值',
      width: 360,
      dataIndex: 'result',
      defaultColConfig: { disabled: true },
      render: (_: any, field: any) => {
        return (
          <RecordValue
            checkItem={form.getFieldValue([...commonNamePath, field.name])}
            form={form}
            fieldName={[field.name]}
            commonNamePath={commonNamePath}
          />
        );
      },
    },
    {
      title: '标准值',
      width: 200,
      dataIndex: 'standValue',
      render: (_i: any, field: FormListFieldData) => (
        <Form.Item
          fieldKey={[field.fieldKey, 'standValue']}
          name={[field.name, 'standValue']}
          style={{ marginBottom: 0 }}
        >
          <TextControl />
        </Form.Item>
      ),
    },
    {
      title: '标准值单位',
      width: 160,
      dataIndex: ['unit', 'name'],
      render: (_i: any, field: FormListFieldData) => (
        <Form.Item
          fieldKey={[field.fieldKey, 'unit', 'name']}
          name={[field.name, 'unit', 'name']}
          style={{ marginBottom: 0 }}
        >
          <TextControl />
        </Form.Item>
      ),
    },
    {
      title: '备注',
      width: 160,
      dataIndex: 'remark',
      defaultColConfig: { disabled: true },
      render: (_: any, field: FormListFieldData) => {
        return form.getFieldValue([...commonNamePath, field.name, 'executeRemark']) ? (
          <Form.Item name={[field.name, 'remark']} style={{ margin: 'auto' }}>
            <Input maxLength={1000} />
          </Form.Item>
        ) : (
          '-'
        );
      },
    },
    {
      title: '不良信息',
      width: 160,
      dataIndex: 'defectInfo',
      defaultColConfig: { disabled: true },
      render: (_: any, field: FormListFieldData, index: number) => (
        <Button
          type="link"
          disabled={
            !form.getFieldValue([...commonNamePath, field.name, 'defectRankList'])?.length &&
            !form.getFieldValue([...commonNamePath, field.name, 'defectReasonList'])?.length
          }
          onClick={() =>
            showDefectModal({
              form,
              formNamePath: [...commonNamePath, field.name, 'defectList'],
              defectRankList: form.getFieldValue([...commonNamePath, field.name, 'defectRankList']),
              defectReasonList: form.getFieldValue([
                ...commonNamePath,
                field.name,
                'defectReasonList',
              ]),
              onSubmit: (data) => {
                form.setFields([{ name: [...commonNamePath, index, 'defectList'], value: data }]);
                setRefreshMarker(Date.now());
              },
            })
          }
        >
          {form.getFieldValue([...commonNamePath, index, 'defectList'])?.length ? '编辑' : '添加'}
        </Button>
      ),
    },
    {
      title: '附件',
      width: 160,
      dataIndex: 'attachmentIds',
      defaultColConfig: { disabled: true },
      render: (_: any, field: FormListFieldData) => {
        return form.getFieldValue([...commonNamePath, field.name, 'executeUpload']) ? (
          <Form.Item name={[field.name, 'attachmentIds']} style={{ margin: 'auto' }}>
            <BcAttachmentForm form={form} />
          </Form.Item>
        ) : (
          '-'
        );
      },
    },
  ];
  const getInventoryColumns = (): ColumnProps<any>[] => {
    const baseColumns: ColumnProps<any>[] = _.compact([
      {
        title: '物料名称',
        dataIndex: 'materialName',
        width: 120,
      },
      {
        title: '物料编码',
        dataIndex: 'materialCode',
        width: 120,
      },
      {
        title: '质量状态',
        dataIndex: 'qcStatus',
        width: 120,
        render: lookup('quality.qcStatusEnum'),
      },
      {
        title: '检验数量',
        dataIndex: 'qcCount',
        width: 120,
      },
      {
        title: '检验单位',
        dataIndex: ['qcUnit'],
        width: 120,
        render: () => qcTask?.qcUnit?.name,
      },
      {
        title: '辅助数量',
        dataIndex: 'auxAmountsCount',
        width: 120,
        render: (_: any, record: any) => record?.inventory?.opAmount?.auxAmounts?.[0]?.amount,
      },
      {
        title: '辅助单位',
        dataIndex: 'auxAmountsUnit',
        width: 120,
        render: (_: any, record: any) => record?.inventory?.opAmount?.auxAmounts?.[0]?.unit?.name,
      },
      {
        title: '储存位置',
        dataIndex: 'location',
        width: 120,
        render: (location: any) => `${location?.storage?.name}/${location?.warehouse?.name}`,
      },
      {
        title: '库存类型',
        dataIndex: 'category',
        width: 120,
        render: lookup('quality.category'),
      },
      qcTask?.isSingleMaterialBatchRecordType() && {
        title: '质量判定',
        dataIndex: 'qcStatusAfter',
        fixed: 'right',
        width: 140,
        render: () => {
          return (
            <Form.Item
              name={['checkMaterials', reportPage - 1, 'qcStatusAfter']}
              fieldKey={[reportCount - 1, 'qcStatusAfter']}
              style={{ margin: '0 auto' }}
            >
              <Select
                style={{ width: 120 }}
                options={inspectionResultOptions}
                disabled={qcTask?.isUnStarted()}
              />
            </Form.Item>
          );
        },
      },
    ]);

    return baseColumns;
  };
  const itemRender: PaginationProps['itemRender'] = (page, type, originalElement) => {
    if (type === 'prev') {
      return (
        <Button type="ghost" disabled={!page}>
          上一份
        </Button>
      );
    }
    if (type === 'next') {
      return (
        <Button type="ghost" disabled={page === reportCount - 1}>
          下一份
        </Button>
      );
    }
    return originalElement;
  };

  const onAddReport = () => {
    const reports = form.getFieldValue(['reports']);

    form.setFieldsValue({ reports: reports.concat(qcTask?.initReportValue(total, true)) });
    setTotal(total + 1);
  };

  const onDeleteReport = (reportPage: number) => {
    const newReports = _.remove(
      form.getFieldValue(['reports']),
      (report, index) => index !== reportPage - 1,
    );

    form.setFieldsValue({ reports: newReports });
    setTotal(newReports.length);
    if (reportPage > newReports.length) {
      setReportPage(newReports.length);
    }
  };

  if (
    !reportCount &&
    (qcTask?.qcConfigEntity()?.isSameAsSampleCount() ||
      qcTask?.qcConfigEntity()?.reportPageSameAsInventory())
  ) {
    return (
      <div style={{ width: 200, margin: 'auto' }}>
        <div>填写份数为0</div>
        <Button
          type="primary"
          style={{ display: 'block' }}
          disabled={qcTask?.isUnStarted()}
          onClick={() => setActiveKey?.('checkMaterials')}
        >
          去设置
        </Button>
      </div>
    );
  }

  return (
    <div>
      {qcTask?.isShowInventeryInReport() && (
        <div style={{ marginBottom: 26 }}>
          <span style={{ display: 'block', marginBottom: 16 }}>当前检验库存明细：</span>
          <BlTable
            dataSource={
              form.getFieldValue(['checkMaterials', reportPage - 1])
                ? [form.getFieldValue(['checkMaterials', reportPage - 1])]
                : []
            }
            columns={getInventoryColumns()}
            scroll={{ x: 'max-content' }}
            rowKey="id"
            tableConfigKey={'report_inventory_detail'}
            resizableCol
            sticky
            useColConfig
            pagination={false}
          />
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 1 }}>
          <Button
            type="ghost"
            disabled={reportPage === FIRST_PAGE || qcTask?.isUnStarted()}
            style={{ marginRight: 16 }}
            onClick={() => {
              form.setFields([
                {
                  name: commonNamePath,
                  value: form.getFieldValue(['reports', String(reportPage - 2), 'reportValues']),
                },
              ]);
              setRefreshMarker(Date.now());
            }}
          >
            从上一份复制
          </Button>
          {qcTask?.qcConfigEntity()?.canAddReport() && (
            <Button
              type="ghost"
              style={{ marginRight: 16 }}
              onClick={() => {
                Modal.confirm({
                  title: '确认删除当前份数吗？',
                  onOk: () => onDeleteReport(reportPage),
                });
              }}
              disabled={total === 1 || qcTask?.isUnStarted()}
            >
              删除本份
            </Button>
          )}
          {qcTask?.qcConfigEntity()?.canAddReport() && (
            <Tooltip title={total === 100 ? '已达最大填写份数，无法继续添加' : ''}>
              <Button
                type="ghost"
                disabled={total === 100 || qcTask?.isUnStarted()}
                onClick={() => onAddReport()}
                title={'不能添加'}
              >
                添加一份
              </Button>
            </Tooltip>
          )}
        </div>
        {!qcTask?.isUnStarted() && (
          <Pagination
            simple
            total={total}
            current={reportPage}
            defaultPageSize={1}
            itemRender={itemRender}
            style={{ marginBottom: 16, lineHeight: '32px' }}
            onChange={(page) => setReportPage(page)}
          />
        )}
      </div>
      <BlSortFormList
        renderColumns={() =>
          injectCustomFieldColumns({
            columns: renderColumns(),
            customFields,
            objectCode: OBJECT_OF_CODE.checkItemRecord,
            type: 'form', // 使用类型
          })
        }
        tableProps={{
          useColConfig: true,
          tableConfigKey: 'checkItemRecord',
          sticky: true,
          resizableCol: true,
        }}
        name={commonNamePath}
        isNeedAdd={false}
        isNeedDelete={false}
        isNeedDrag={false}
      />
    </div>
  );
});

export default CheckItemRecord;
