import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { getFilterFromColumns } from '@blacklake-web/layout';
//
import BatchListSelector, { BatchListSelectorProps } from '../BatchListSelector';
import { configs } from './config';
import { SearchSelectModalConfig } from './index.type';
import _ from 'lodash';

export interface SearchSeletcModalValue {
  value: any;
  label: string;
}

export interface SelectSearchModalProps<RecordType>
  extends Omit<SearchSelectModalConfig<RecordType>, 'requestFn' | 'columns'> {
  fetchType: keyof typeof configs;
  /**
   * 设置模式为多选或单选
   * @default radio
   */
  mode?: 'multiple' | 'radio';
  /**
   * 额外的查询参数
   */
  params?: any;
  /**
   * 用于展示于界面上的组件,
   */
  showComponent?: React.ReactNode;
  /**
   * 主动控制显隐
   */
  visible?: boolean;
  /**
   * 展示的columns和filter
   */
  columns?: BatchListSelectorProps<RecordType>['columns'];
  /**
   * 取消或关闭时处理
   */
  onCancel?: () => void;
  /**
   * 选中数据变化
   */
  onChange?: (value: SearchSeletcModalValue | SearchSeletcModalValue[] | undefined) => void;
  /**
   * 受控选中数据
   */
  value?: SearchSeletcModalValue | SearchSeletcModalValue[];
  /**
   * 行的唯一标识字段
   * @default id
   */
  rowKey?: string;
  /**
   * 是否需要格式化数据
   * @default fasle
   */
  stringify?: boolean;
  /**
   * label的自定义render方法
   * @default 'name'
   */
  renderLabel?: ((selectRows: any) => React.ReactNode) | string;
  /**
   * 选择框placeholder
   * @default ''
   */
  placeholder?: string;

  /**
   * 是否可选
   */
  disabled?: boolean;
}

type SearchSelectModalSelfValue =
  | (SearchSeletcModalValue & { key: string })
  | (SearchSeletcModalValue & { key: string })[]
  | undefined;

function SearchSelectModal<RecordType extends object>(props: SelectSearchModalProps<RecordType>) {
  const [selfVisible, setSelfVisible] = useState(false);

  // 格式化之后的数据，组件内统一使用
  const [selfValue, setSelfValue] = useState<SearchSelectModalSelfValue>();

  const {
    fetchType,
    mode = 'radio',
    params,
    showComponent,
    visible,
    onCancel,
    rowKey = 'id',
    value,
    onChange,
    stringify = false,
    renderLabel,
    placeholder = '请选择',
    disabled,
    ...propsConfig
  } = props;

  const { renderLabel: defaultRenderLabel, ...defaultConfig } = configs[fetchType];
  const filterList = getFilterFromColumns(propsConfig?.columns || defaultConfig?.columns);

  useEffect(() => {
    if (_.isBoolean(visible)) {
      setSelfVisible(visible);
    }
  }, [visible]);

  useEffect(() => {
    let realValue;

    if (_.isNil(value)) {
      setSelfValue(undefined);
      return;
    }

    if (stringify && mode === 'radio' && _.isObject(value)) {
      realValue = {
        ...value,
        value: !_.isNil((value as SearchSeletcModalValue)?.value)
          ? JSON.parse((value as SearchSeletcModalValue)?.value)
          : undefined,
        key: _.get(JSON.parse((value as SearchSeletcModalValue)?.value), [rowKey]),
      };
    }

    if (!stringify && mode === 'radio' && _.isObject(value)) {
      realValue = {
        ...value,
        key: _.get(value, ['value', rowKey]),
      };
    }

    if (stringify && mode === 'multiple' && _.isArray(value)) {
      realValue = _.map(value, (item) => {
        return {
          ...item,
          value: JSON.parse(item.value),
          key: _.get(JSON.parse(item.value), [rowKey]),
        };
      });
    }

    if (!stringify && mode === 'multiple' && _.isArray(value)) {
      realValue = _.map(value, (item) => {
        return {
          ...item,
          key: _.get(item, ['value', rowKey]),
        };
      });
    }

    setSelfValue(realValue as any);
  }, [value]);

  const getSelectKeys = () => {
    if (_.isEmpty(selfValue)) {
      return [];
    }

    if (_.isArray(selfValue)) {
      return _.map(_.map(selfValue, 'value'), rowKey);
    }

    if (_.isObject(selfValue)) {
      const key = _.get(selfValue, ['value', rowKey]);

      if (!_.isNil(key)) return [key];
    }

    return [];
  };

  const getRenderLabel = (selectRow: any) => {
    const realRenderLabel = renderLabel ?? defaultRenderLabel ?? 'name';

    if (_.isFunction(realRenderLabel)) return realRenderLabel(selectRow);

    return _.get(selectRow, realRenderLabel);
  };

  const handleClose = () => {
    if (_.isFunction(onCancel)) onCancel();

    if (_.isNil(visible)) {
      setSelfVisible(false);
    }
  };

  const handleChange = (selectedRowKeys: any[], selectedRows: any[]) => {
    const getRealSelectRows = (oldSelfValue: any) => {
      const _realSelectRows = _.compact(_.uniqBy(_.concat(selectedRows, oldSelfValue), rowKey));

      return _.map(selectedRowKeys, (key) => _.find(_realSelectRows, [rowKey, key]));
    };

    if (mode === 'radio') {
      const realSelectRows = getRealSelectRows(_.get(selfValue, 'value'));

      handleRadioChange(selectedRowKeys, realSelectRows);
    }

    if (mode === 'multiple') {
      const realSelectRows = getRealSelectRows(
        _.map(selfValue as SearchSeletcModalValue[], 'value'),
      );

      handleMultilpeChange(selectedRowKeys, realSelectRows);
    }

    handleClose();
  };

  const handleRadioChange = (selectedRowKeys: any[], selectedRows: any[]) => {
    const selectedRow = _.head(selectedRows);

    if (_.isNil(selectedRow)) {
      onChange?.(undefined);
    } else {
      onChange?.({
        label: getRenderLabel(selectedRow),
        value: stringify ? JSON.stringify(selectedRow) : selectedRow,
      });
    }
  };

  const handleMultilpeChange = (selectedRowKeys: any[], selectedRows: any[]) => {
    onChange?.(
      _.map(selectedRows, (row) => {
        return { label: getRenderLabel(row), value: stringify ? JSON.stringify(row) : row };
      }),
    );
  };

  const renderShowComponent = () => {
    const transformValue = (value: SearchSelectModalSelfValue) => {
      if (_.isArray(value)) {
        return _.map(value, (item) => ({ label: getRenderLabel(item?.value), value: item?.key }));
      }

      return _.isObject(value)
        ? {
            ...value,
            label: getRenderLabel(value?.value),
          }
        : undefined;
    };

    // 传null或者undefined时也可以
    if (_.hasIn(props, 'showComponent')) {
      return <div onClick={() => setSelfVisible(true)}>{showComponent}</div>;
    }

    return (
      <Select
        labelInValue
        allowClear
        placeholder={placeholder}
        onClick={() => {
          if (!disabled) {
            setSelfVisible(true);
          }
        }}
        open={false}
        mode={{ multiple: 'multiple', radio: undefined }[mode] as any}
        options={[]}
        value={transformValue(selfValue)}
        onClear={() => handleChange([], [])}
        onDeselect={(deletedValue: any) => {
          handleChange(
            _.difference(_.map(selfValue as SearchSeletcModalValue[], 'key'), [deletedValue.key]),
            [],
          );
        }}
        disabled={disabled}
      />
    );
  };

  return (
    <>
      {renderShowComponent()}
      <BatchListSelector<RecordType>
        okText="确定"
        cancelText="取消"
        {...defaultConfig}
        {...propsConfig}
        visible={selfVisible}
        rowKey={rowKey}
        filterList={filterList}
        selectType={{ multiple: 'checkbox', radio: 'radio' }[mode] as 'checkbox' | 'radio'}
        onCancel={handleClose}
        defaultQueryConditions={params}
        selectedKeys={getSelectKeys()}
        onFinish={handleChange}
        useContinueSelect
      />
    </>
  );
}

export default SearchSelectModal;
