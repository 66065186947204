import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import TreeContent, {
  MaintenanceTreeFnParams,
  defaultMaintenanceTree,
} from '../components/maintenanceTree';
import { BL_BUSINESS_CODE } from './config';
import ListContent from './list';

const MaintenancePlan = (props: RouteComponentProps) => {
  const session = sessionStorage.getItem(`${BL_BUSINESS_CODE}`);
  const data = session ? JSON.parse(session ?? '{}') : defaultMaintenanceTree;
  const [selectData, setSelectData] = useState<MaintenanceTreeFnParams>(data);

  return (
    <TreeContent
      onChange={(value) => {
        setSelectData(value);
      }}
      cacheKey={`${BL_BUSINESS_CODE}`}
    >
      <ListContent {...selectData} {...props} />
    </TreeContent>
  );
};

export default MaintenancePlan;
