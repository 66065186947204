import { createContext } from 'react';
import { FormatConfigData, SuitObjectData } from './interface';

// 字段转化
export const FIELD_MAP = new Map([
  ['ruleCode', 'code'],
  ['ruleName', 'name'],
  ['suitObj', 'suitObjId'],
  ['enableFlag', 'enableFlag'],
]);

// 编号规则使用状态枚举值
export const NUMBER_RULES_STATUS_ENUM = {
  ENABLED: 1,
  DISABLED: 0,
};

export const NUMBER_RULES_ENUM = [
  { id: NUMBER_RULES_STATUS_ENUM.DISABLED, name: '停用中' },
  { id: NUMBER_RULES_STATUS_ENUM.ENABLED, name: '启用中' },
];

export const NUMBER_RULES_TYPE = new Map([
  [NUMBER_RULES_STATUS_ENUM.ENABLED, '启用中'],
  [NUMBER_RULES_STATUS_ENUM.DISABLED, '停用中'],
]);

export const TABLE_TYPE_ENUM = {
  CONSTANT: 1,
  DATE: 2,
  VARIABLE: 3,
  SERIAL_NUMBER: 4,
};

// 类型枚举值
export const TABLE_TYPE_TYPE = new Map([
  [TABLE_TYPE_ENUM.CONSTANT, '常量'],
  [TABLE_TYPE_ENUM.DATE, '日期'],
  [TABLE_TYPE_ENUM.VARIABLE, '变量'],
  [TABLE_TYPE_ENUM.SERIAL_NUMBER, '流水号'],
]);

export const TABLE_TYPE = [
  { id: TABLE_TYPE_ENUM.CONSTANT, name: '常量' },
  { id: TABLE_TYPE_ENUM.DATE, name: '日期' },
  { id: TABLE_TYPE_ENUM.VARIABLE, name: '变量' },
  { id: TABLE_TYPE_ENUM.SERIAL_NUMBER, name: '流水号' },
];

// 重置周期枚举类型
export const RESET_CYCLE_TYPE_ENUM = {
  BY_NUMBER_BASIS_ONLY: 0,
  DAY_RESET: 1,
  WEEK_RESET: 2,
  MONTH_RESET: 3,
  YEAR_RESET: 4,
};

// 重置周期枚举
export const RESET_CYCLE_TYPE = new Map([
  [RESET_CYCLE_TYPE_ENUM.BY_NUMBER_BASIS_ONLY, '仅按编号依据'],
  [RESET_CYCLE_TYPE_ENUM.DAY_RESET, '每日重置'],
  [RESET_CYCLE_TYPE_ENUM.WEEK_RESET, '每周重置'],
  [RESET_CYCLE_TYPE_ENUM.MONTH_RESET, '每月重置'],
  [RESET_CYCLE_TYPE_ENUM.YEAR_RESET, '每年重置'],
]);

export const REST_PERIOD_ENUM = [
  { id: RESET_CYCLE_TYPE_ENUM.BY_NUMBER_BASIS_ONLY, name: '仅按编号依据' },
  { id: RESET_CYCLE_TYPE_ENUM.DAY_RESET, name: '每日重置' },
  { id: RESET_CYCLE_TYPE_ENUM.WEEK_RESET, name: '每周重置' },
  { id: RESET_CYCLE_TYPE_ENUM.MONTH_RESET, name: '每月重置' },
  { id: RESET_CYCLE_TYPE_ENUM.YEAR_RESET, name: '每年重置' },
];

// 流水编码进制枚举
export const WATER_SYSTEM_TYPE = new Map([
  [1, '十进制'],
  [2, '十六进制'],
  [3, '三十二进制'],
]);

export const WATER_SYSTEM_ENUM = [
  { id: 1, name: '十进制' },
  { id: 2, name: '十六进制' },
  { id: 3, name: '三十二进制' },
];

export interface NumberRulesContextType {
  objectData: SuitObjectData[];
  formatConfigData: FormatConfigData[];
}

export const NumberRulesContext = createContext<NumberRulesContextType>({
  objectData: [],
  formatConfigData: [],
});
