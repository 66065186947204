import { createModel } from '@rematch/core';
import { RootModel } from 'src/store/models/index';
import _ from 'lodash';
import { fetchMessageList } from 'src/api/ytt/message-domain/messageList';
import _Time from 'src/utils/dataTypes/time';
import moment from 'moment';

export const message = createModel<RootModel>()({
  state: {
    totalUnread: 0,
    unReadList: [],
    urgentUnread: 0,
  },
  reducers: {
    setMessageUnReadTotal(state, { totalUnread, unReadList, urgentUnread }) {
      return { ...state, totalUnread, unReadList, urgentUnread };
    },
  },
  effects: (dispatch) => ({
    async getMessageUnReadTotal(): Promise<any> {
      try {
        const nowTime = Number(
          _Time.formatToUnix(moment(new Date()).format('YYYY-MM-DD HH:mm:ss')),
        );

        const past = nowTime - 10 * 1000; // 查询10秒之内是否有新增的未读消息

        const { data } = await fetchMessageList({
          page: 1,
          size: 10,
          from: past,
          to: nowTime,
          readStatus: 0,
        });

        const totalUnreadNumber = _.get(data, 'totalUnread');
        const urgentTotalUnread = _.get(data, 'urgentUnread');

        const unReadList = _.get(data, 'list')?.map((node: any) => {
          return {
            ...node,
            promptStatus: 1,
          };
        });

        localStorage.setItem('unReadListStore', JSON.stringify(unReadList)); // 存储未读数量List便于循环弹消息提示

        dispatch({
          type: 'message/setMessageUnReadTotal',
          payload: {
            totalUnread: totalUnreadNumber || 0,
            unReadList: unReadList || [],
            urgentUnread: urgentTotalUnread || 0,
          },
        });

        return data || {};
      } catch (e: any) {
        console.log('e: ', e);
        if (e?.data?.message !== 200) {
          // 只要不成功 清除定时器
          const intervalId = Number(localStorage.getItem('intervalId'));

          clearInterval(intervalId);
        }
      }
    },
  }),
});
