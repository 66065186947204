import React, { useState } from 'react';
import { DataFormLayout } from 'layout';
import _ from 'lodash';
import LinkSelectModal from '../selectModal';
import RelationshipInfo from './relationshipInfo';
interface RelationTabProps {
  form: any;
  formProps: any;
  checkType: number;
}

const ActionContent = (props: RelationTabProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectType, setSelectType] = useState<string>('qcConfigMaterialList');
  const [, setRefresh] = useState<number>(0);

  const { form, checkType, formProps } = props;

  const showSelectModal = (type: string) => {
    setSelectType(type);
    setModalVisible(true);
  };

  return (
    <>
      <DataFormLayout
        info={[
          RelationshipInfo({
            showSelectModal,
            checkType,
            form,
            setRefresh: () => setRefresh(Math.random()),
          }),
        ]}
        form={form}
        formProps={{
          ...formProps,
          labelCol: {},
          layout: 'vertical',
        }}
        footer={false}
      />
      {modalVisible && (
        <LinkSelectModal
          visible={modalVisible}
          submitData={(type: string, record: any) => {
            form.setFieldsValue({ [type]: record });
          }}
          type={selectType}
          closeModal={() => setModalVisible(false)}
          defaultSelected={form.getFieldValue([selectType])}
        />
      )}
    </>
  );
};

export default ActionContent;
