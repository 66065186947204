/**
 *  @control 创建检验任务
 */
import { FC } from 'react';
import { Button, Space } from 'antd';
import { PreviewCompProps } from '../../types';

export const QcTaskOfSOP: FC<PreviewCompProps> = () => {
  return (
    <div style={{ pointerEvents: 'none' }} tabIndex={-1}>
      <Space size={[16, 8]} wrap>
        <Button type="primary">创建检验任务</Button>
        <Button>检验任务列表</Button>
      </Space>
    </div>
  );
};
