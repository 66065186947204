import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import './index.scss';
import { PictureOutlined } from '@ant-design/icons';
import { fetchFileDetail, FetchFileDetailResponse } from 'src/api/ytt/holyfile-domain';

interface ThumbnailPopoverProps {
  document: {
    id: number;
    url: string;
  };
}
export const ThumbnailPopover = (props: ThumbnailPopoverProps) => {
  const defaultUrl = '';
  const { document } = props;
  const [url, setUrl] = useState(defaultUrl);
  const fetchUrl = async (id: number) => {
    try {
      const { data }: FetchFileDetailResponse = await fetchFileDetail({ id });

      setUrl(data?.fileUri || defaultUrl);
    } catch (error) {
      setUrl(defaultUrl);
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (!document?.url) {
      fetchUrl(document?.id);
      return;
    }
    setUrl(document?.url);
  }, [document.id]);
  return (
    <Popover
      placement="rightTop"
      content={
        url ? (
          <div>
            <img
              src={url}
              width="120px"
              height="120px"
              style={{ objectFit: 'cover' }}
              alt="preview"
            />
          </div>
        ) : null
      }
    >
      <span key={document.id} style={{ marginRight: '12px' }}>
        {url ? (
          <img src={url} alt="thumbnail" width="20px" />
        ) : (
          <PictureOutlined style={{ fontSize: 20 }} />
        )}
      </span>
    </Popover>
  );
};
