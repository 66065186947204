import React, { useEffect, useState } from 'react';
import { match, RouteComponentProps, useHistory } from 'react-router-dom';
import { Space, Popover, Badge, message as Message, Form } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import {
  BIZ_TYPE,
  LOCK_STATUS,
  LOCK_STATUS_DISPLAY_ENUM,
  QUALITY_ENUM,
  STATUS_ENUM,
  STORAGE_LOCATION_TYPE,
} from '../constant';
import { BcMultilineText, BlIcon, QrCode, SingleOperationLogComponent } from 'components';
import { replaceSign } from 'src/utils/constants';
import {
  fetchStorageWarehouseBulkDelete,
  fetchStorageWarehouseBulkDisable,
  fetchStorageWarehouseBulkEnable,
  fetchStorageWarehouseBulkLock,
  fetchStorageWarehouseBulkUnlock,
  fetchStorageWarehouseInfo,
} from 'src/api/ytt/storage-domain/warehouse';
import { toStoreEdit, toStoreList } from '../navigation';
import BLDelConfirm from '../../share/modalConfirm';
import { patchRequest } from '../utils';
import { StoreType } from '../interface';
import _String from 'src/utils/dataTypes/string';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';

interface DetailBaseInfoProps {
  storeId: number;
  refreshTable?: () => void;
  type: string;
}

const StoreDetailInfo = (props: DetailBaseInfoProps) => {
  const { storeId, refreshTable, type } = props;

  const [dataSource, setDataSource] = useState({} as any);

  const fetchData = async () => {
    try {
      const { data: detailData } = await fetchStorageWarehouseInfo(
        { id: storeId },
        { legacy: true },
      );

      if (detailData) {
        setDataSource(detailData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [storeId]);

  const history = useHistory();

  const [checkVisible, setCheckVisible] = useState(false); // 显示确定编辑弹框
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录

  const useStatusMap = new Map([
    [STATUS_ENUM.ENABLED, <Badge text="启用中" status="success" />],
    [STATUS_ENUM.DISABLED, <Badge text="停用" status="error" />],
  ]);

  // const [delForm] = Form.useForm();

  // const handleFinish = async () => {
  //   try {
  //     // const value = await delForm?.validateFields();
  //     // TODO：后面补充删除的原因
  //     const delId = [shiftId];

  //     const { message, data } = await fetchShiftDelete({ ids: delId });

  //     if (data) {
  //       if (data?.failedCount === 0) {
  //         Message.success('删除成功');
  //         history.push(toShiftList());
  //         return;
  //       }
  //       const useCalendarNames = formatDeleteFailsByCalendar(
  //         data?.shiftDeleteDetailVOS?.[0].usingShiftObjs,
  //       );
  //       const useSchedulingRulesNames = formatDeleteFailsBySchedulingRules(
  //         data?.shiftDeleteDetailVOS?.[0].usingShiftObjs,
  //       );

  //       ErrorNotification({
  //         message: '删除失败',
  //         description: (
  //           <>
  //             {!_String.isEmpty(useCalendarNames) ? (
  //               <div>
  //                 有工作日历：<span>{useCalendarNames}</span>
  //                 ，正在使用该班次
  //               </div>
  //             ) : null}
  //             {!_String.isEmpty(useSchedulingRulesNames) ? (
  //               <div>
  //                 有排班规则：<span>{useSchedulingRulesNames}</span>
  //                 ，正在使用该班次
  //               </div>
  //             ) : null}
  //           </>
  //         ),
  //       });

  //       return;
  //     }
  //     Message.error(message);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const baseMenu = [
    {
      key: 'enable',
      auth: authDict.warehouse_enable_disable,
      onClick: async () => {
        const storeApi = dataSource?.enableFlag
          ? fetchStorageWarehouseBulkDisable
          : fetchStorageWarehouseBulkEnable;
        const storeDisplay = dataSource?.enableFlag ? '停用' : '启用';

        patchRequest('single', storeApi, [storeId], storeDisplay, () => {
          fetchData();
          typeof refreshTable === 'function' && refreshTable?.();
        });
      },
      icon:
        dataSource?.enableFlag === STATUS_ENUM?.ENABLED ? (
          <BlIcon type="icontingyong" />
        ) : (
          <BlIcon type="iconqiyong" />
        ),
      title: dataSource?.enableFlag ? '停用' : '启用',
    },
    {
      key: 'lock',
      auth: authDict.warehouse_lock_unlock,
      onClick: async () => {
        const lockApi = dataSource?.isLock
          ? fetchStorageWarehouseBulkUnlock
          : fetchStorageWarehouseBulkLock;
        const lockDisplay = dataSource?.isLock ? '解锁' : '锁定';

        patchRequest('single', lockApi, [storeId], lockDisplay, () => {
          fetchData();
          typeof refreshTable === 'function' && refreshTable?.();
        });
      },
      title: dataSource?.isLock ? '解锁' : '锁定',
    },
    // {
    //   key: 'delete',
    //   onClick: () => {
    //     BLDelConfirm('是否删除当前仓库及仓库下所有仓位', delForm, handleFinish);
    //   },
    //   title: '删除',
    // },
    {
      key: 'record',
      auth: authDict.warehouse_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'edit',
      auth: authDict.warehouse_edit,
      onClick: () => {
        history.push(toStoreEdit(storeId));
      },
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ];

  const detailInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '仓库类型',
        dataIndex: 'bizType',
        isFullLine: type === 'drawer',
        render: (bizType) => BIZ_TYPE.get(bizType) || replaceSign,
      },
      { label: '仓库名称', dataIndex: 'name', isFullLine: type === 'drawer' },
      { label: '仓库编号', dataIndex: 'code', isFullLine: type === 'drawer' },
      {
        label: '标识码',
        dataIndex: 'qrCode',
        isFullLine: type === 'drawer',
        render: (qrCode) => {
          return (
            <div>
              <Space style={{ marginRight: '5px' }}>{qrCode || replaceSign}</Space>
              <Space>
                <Popover
                  placement="rightTop"
                  content={
                    <div>
                      <QrCode value={dataSource?.qrCode} />
                      <div style={{ textAlign: 'center' }}>{dataSource?.qrCode}</div>
                    </div>
                  }
                >
                  <BlIcon type="iconerweima" />
                </Popover>
              </Space>
            </div>
          );
        },
      },
      {
        label: '仓库状态',
        dataIndex: 'enableFlag',
        isFullLine: type === 'drawer',
        render: (enableFlag: number) => useStatusMap.get(enableFlag),
      },
      {
        label: '锁定状态',
        dataIndex: 'isLock',
        isFullLine: type === 'drawer',
        render: (isLock) => <span>{LOCK_STATUS.get(isLock) || replaceSign}</span>,
      },
      {
        label: '仓位管理',
        dataIndex: 'enableStorageLocation',
        isFullLine: type === 'drawer',
        render: (enableStorageLocation: number) => STORAGE_LOCATION_TYPE.get(enableStorageLocation),
      },
      {
        label: '质量管理',
        dataIndex: 'qcCtrlList',
        isFullLine: type === 'drawer',
        render: (qcCtrlList: [], { enableQcCtrl }) => {
          if (enableQcCtrl === STATUS_ENUM.DISABLED) return '无限制';
          return (
            <span>
              {qcCtrlList?.map((node, i) => {
                if (i === qcCtrlList?.length - 1) {
                  return <span key={node}>{QUALITY_ENUM.get(node)}</span> || replaceSign;
                }
                return <span key={node}>{QUALITY_ENUM.get(node)}、</span> || replaceSign;
              })}
            </span>
          );
        },
      },
      {
        label: '备注',
        dataIndex: 'remark',
        isFullLine: type === 'drawer',
        render: (remark: string) => <BcMultilineText text={remark} />,
      },
    ],
    column: 2,
  };

  const infoArray = [detailInfo];

  return (
    <>
      <DetailLayout
        userAuth={getAuthFromLocalStorage()}
        title="仓库详情"
        baseMenu={baseMenu}
        info={infoArray}
        dataSource={dataSource}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={storeId}
          objectCode={'Warehouse'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default StoreDetailInfo;
