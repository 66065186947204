import { useEffect, useState } from 'react';
import { message } from 'antd';
import _ from 'lodash';
import { RouteChildrenProps } from 'react-router';
import { appEnum } from 'src/dict';
import BaseForm from './baseForm';
import {
  fetchPlanOrderCreate,
  fetchPlanOrderUpdate,
  fetchPlanOrderFullDetailGet,
} from 'src/api/ytt/plo-domain';
import { useCustomFieldCombinedData } from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

interface CreateAndEditPorps extends RouteChildrenProps<{ id: string }> {}

const CreateAndEdit = (props: CreateAndEditPorps) => {
  const { history, location, match } = props;

  const [loading, setLoading] = useState(true);
  const [initialValue, setInitialValue] = useState<any>();
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.planOrder);
  // const [dataSource, setDataSource] = useState<any>();

  const [type, setType] = useState(appEnum.Common.CRUD.create);

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      setType(appEnum.Common.CRUD.edit);
      if (match?.params?.id) {
        fetchAndSetData(_.toNumber(match?.params?.id));
      }
    } else if (location.pathname.includes('copy')) {
      setType(appEnum.Common.CRUD.copy);
      if (match?.params?.id) {
        fetchAndSetData(_.toNumber(match?.params?.id));
      }
    } else {
      setType(appEnum.Common.CRUD.create);
      setLoading(false);
    }
  }, []);

  const fetchAndSetData = (id?: number) => {
    if (id) {
      setLoading(true);
      const planOrderFullDetailGet = new Promise((resolve) => {
        fetchPlanOrderFullDetailGet({ planOrderId: id }).then((res) => {
          const { data } = res;

          resolve(data);
        });
      });

      return Promise.all([planOrderFullDetailGet])
        .then((res) => {
          const {
            inputMaterialVOs,
            outputMaterialVOs,
            planOrderBaseVO,
            planOrderSourceVO,
            processPlanVO,
          } = res?.[0];

          const dataSourceList = {
            planOrderBaseCO: planOrderBaseVO,
            bomOutputMaterials: outputMaterialVOs,
            bomInputMaterials: inputMaterialVOs,
            processPlanCO: processPlanVO,
            workOrderSource: planOrderSourceVO,
            customFields: planOrderBaseVO?.customFields,
          };

          setInitialValue(dataSourceList);
          return res;
        })
        .catch((err) => {
          console.log('err: ', err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return Promise.reject();
  };

  const handleCreate = (value: any, cb?: () => void) => {
    fetchPlanOrderCreate(value).then(() => {
      message.success('创建成功!');

      cb?.();
    });
  };

  const handleEdit = (value: any) => {
    setLoading(true);
    fetchPlanOrderUpdate(value)
      .then(() => {
        message.success('编辑成功!');

        // 自定义字段异步更新，需要延迟操作
        setTimeout(() => {
          history.goBack();
          setLoading(false);
        }, 1500);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <BaseForm
      loading={loading}
      type={type}
      history={history}
      initialValue={initialValue}
      onSubmit={(value, cb) => {
        if (type === appEnum.Common.CRUD.create) handleCreate(value, cb);
        if (type === appEnum.Common.CRUD.edit) handleEdit(value);
        if (type === appEnum.Common.CRUD.copy) handleCreate(value, cb);
      }}
      customFields={customFields}
    />
  );
};

export default CreateAndEdit;
