/**
 * @description 触发事件 返回页面url函数
 */

import _ from 'lodash';

export const TriggerAction_PATH = '/systemConfig/triggerAction';

export const getListPageUrl = () => TriggerAction_PATH;

export const getCreatePageUrl = (id?: number) => {
  return _.isNil(id) ? `${TriggerAction_PATH}/create` : `${TriggerAction_PATH}/create?id=${id}`;
};

export const getDetailPageUrl = (actionSource: string, ruleId: number) =>
  `${TriggerAction_PATH}/${ruleId}/detail/${actionSource}`;

export const getEditPageUrl = (ruleId: number) => `${TriggerAction_PATH}/${ruleId}/edit`;
