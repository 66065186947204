import React, { useEffect, useState } from 'react';
import { Form, message as Message } from 'antd';
import { match, RouteComponentProps } from 'react-router-dom';
import { fetchSalesOrderDetail, fetchSalesOrderUpdate } from 'src/api/ytt/order-domain/salesOrder';
import CreateAndEditBaseInfo from './createAndEditBaseInfo';
import { formatFetchValue } from '../utils';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';
import { SalesOrderDetailsResponse } from '../interface';
import { ICustomFields } from 'src/components/customField';

interface createProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const EditSalesOrder = (props: createProps) => {
  const { match, history } = props;
  const salesId = Number(match?.params?.id);
  const [editForm] = Form.useForm();
  const [detailData, setDetailData] = useState<SalesOrderDetailsResponse>();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const { data } = await fetchSalesOrderDetail({ id: salesId });

      if (data) {
        setDetailData(data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [salesId]);

  const handleFinish = async (customFields?: ICustomFields, customItemFields?: ICustomFields) => {
    try {
      const { operateReason, ...rest } = await editForm?.validateFields();

      const newValue = formatFetchValue(rest, customFields, customItemFields);

      setLoading(true);
      const { code } = await fetchSalesOrderUpdate({
        header: { ...newValue.header, id: salesId },
        items: newValue.items,
        operateReason,
      });

      setLoading(false);
      if (code === 200) {
        Message.success('更新成功');
        history.goBack();
      }
    } catch (error) {
      setLoading(false);
      console.log('error: ', error);
    }
  };

  return (
    <CreateAndEditBaseInfo
      edit
      title="编辑销售订单"
      onCancel={() => {
        history.goBack();
      }}
      onFinish={throttleFn(handleFinish)}
      form={editForm}
      initialData={detailData}
      loading={loading}
    />
  );
};

export default EditSalesOrder;
