/**
 * @description 表格参数格式化方法
 * @author xuzhiyong
 */

import { FilterItem } from 'layout';

export const getFilterList = (columns: any[]) => {
  return columns
    .filter((i) => i.isFilter)
    .map((column: any): FilterItem => {
      const filter = {
        label: column.title,
        name: column.dataIndex,
        ...column,
      };

      return filter;
    });
};
