import { useRef, FC, ReactNode } from 'react';
import { List, FormInstance } from 'antd';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import classnames from 'classnames';
import { BlIcon } from 'src/components';
import { NEW_COMPONENT_INDEX } from './share';
import { DragItem, DragItemType, GetInitItemDataFn } from './types';
import type { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import styles from './styles.module.scss';
import { NamePath } from 'antd/lib/form/interface';

type RenderItemFn = (params: {
  /** 表单里该项对应的数据 */
  data: any;
  /** 该项在列表中的下标 */
  index: number;
  /** 删除 */
  remove: FormListOperation['remove'];
}) => ReactNode;

export type DraggableItemProps = FormListFieldData &
  FormListOperation & {
    /** form实例 */
    form: FormInstance;
    /** 列表的namePath */
    listNamePath: NamePath;
    /** 渲染列表项内容 */
    renderItem: RenderItemFn;
    /** 判断每项是否展示。用于筛选的场景，不影响表单内的值 */
    filter?: (item: any) => boolean;
    classname?: string;
    /** 拖入的新项的初始值 */
    getInitItemData?: GetInitItemDataFn;
    /** 添加项之后的回调 */
    onAdd?: (insertIndex: number, listLength: number) => void;
    /** 刷新界面 */
    forceUpdate?: () => void;
  };

/** 可拖拽项 */
const DraggableItem: FC<DraggableItemProps> = ({
  name,
  form,
  listNamePath,
  add,
  move,
  remove,
  filter,
  classname,
  getInitItemData,
  renderItem,
  onAdd,
  forceUpdate,
}) => {
  const itemPath = [listNamePath, name].flat();
  const itemData = form.getFieldValue(itemPath);
  const ref = useRef<HTMLDivElement | null>(null);
  const [{ isOver, isDownward }, drop] = useDrop({
    accept: [DragItemType.listItem, DragItemType.source],
    collect: (monitor: DropTargetMonitor<DragItem>) => {
      const { index: dragIndex } = monitor.getItem() || {};
      const isOver = monitor.isOver();

      if (dragIndex === undefined || dragIndex === name) {
        return {};
      }
      let isDownward = dragIndex < name;

      // 如果是将新控件拖入, 为了让它能插到首位, 需要判断一下hover高度
      if (
        isOver &&
        dragIndex === NEW_COMPONENT_INDEX &&
        name === 0 &&
        ref.current &&
        monitor.getClientOffset()
      ) {
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

        isDownward = hoverClientY > hoverMiddleY;
      }
      return {
        isOver,
        isDownward,
      };
    },
    drop: (item: DragItem) => {
      let insertIndex = name;

      if (item.index === NEW_COMPONENT_INDEX) {
        // 新控件index为负, 默认会插到hover项的前面, 故需特殊处理下
        if (isDownward) {
          insertIndex = name + 1;
        }
        if (!getInitItemData) {
          throw Error('如需支持拖入新的列表项，则getInitItemData必传');
        }
        add(getInitItemData(item, insertIndex));
        forceUpdate && forceUpdate();
        onAdd && onAdd(insertIndex, form.getFieldValue(listNamePath).length);
      } else {
        move(item.index, insertIndex);
      }
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: DragItemType.listItem,
    item: { data: itemData, index: name },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));
  const dropClassName = isDownward ? styles['drop-over-downward'] : styles['drop-over-upward'];
  const opacity = isDragging ? 0 : 1;

  if (filter && !filter(itemData)) {
    return null;
  }
  return (
    <List.Item
      className={classnames(
        styles['draggable-list-item-wrapper'],
        isOver ? dropClassName : '',
        classname,
      )}
    >
      <div ref={ref} className={styles['draggable-list-item']} style={{ opacity }}>
        {/* 拖拽图标 */}
        <BlIcon ref={drag} type="iconrenyituozhuai" />
        {/* 渲染内容 */}
        {renderItem({ data: itemData, index: name, remove })}
      </div>
    </List.Item>
  );
};

export default DraggableItem;
