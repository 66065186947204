import { message, Modal, Table } from 'antd';
import _ from 'lodash';
import {
  fetchShipmentNoteFinish,
  fetchShipmentNoteFinishCheck,
} from 'src/api/ytt/order-domain/invoice';
import { replaceSign } from 'src/utils/constants';
import {
  ShipmentNoteDetailItemsResponseAmount,
  ShipmentNoteFinishCheckResponse,
} from '../../interface';

const getCheckPassedContent = (orderCode: string) => (
  <div>{`结束发货单后将不能再进行任何操作, 确定要结束<${orderCode}>该订单吗？`}</div>
);

const columns = [
  {
    title: '行号',
    dataIndex: 'lineNo',
  },
  {
    title: '物料编号',
    dataIndex: ['material', 'baseInfo', 'code'],
  },
  {
    title: '物料名称',
    dataIndex: ['material', 'baseInfo', 'name'],
  },
  {
    title: '计划发货数',
    dataIndex: ['plannedIssueAmount'],
    render: (text: ShipmentNoteDetailItemsResponseAmount) => {
      return text?.amountDisplay ? `${text.amountDisplay} ${text.unitName}` : replaceSign;
    },
  },
  {
    title: '实际发货数',
    dataIndex: ['warehouseIssueAmount'],
    render: (text: ShipmentNoteDetailItemsResponseAmount) => {
      return text?.amountDisplay ? `${text.amountDisplay} ${text.unitName}` : replaceSign;
    },
  },
];

const TABLE_MAX_HEIGHT = 400;

const getCheckFailedContent = (
  failDetails: NonNullable<ShipmentNoteFinishCheckResponse>['failDetails'],
  orderCode: string,
) => (
  <>
    <div>以下物料明细行还未完成，确认要结束{orderCode}该单据吗？</div>
    <Table
      dataSource={failDetails}
      columns={columns}
      pagination={false}
      scroll={{ y: TABLE_MAX_HEIGHT }}
      rowKey={'materialId'}
    />
  </>
);

// 结束/强制结束
const finishInvoiceService = async (id: number, refresh: () => void, display?: string) => {
  const response = await fetchShipmentNoteFinish({ shipmentNoteId: id });

  if (response?.code === 200) {
    message.success(`发货单${display || '结束'}成功`);
  } else {
    message.error(response.message);
  }

  refresh();
};

// 结束校验
const finishCheckService = async (id: number) => {
  const response = await fetchShipmentNoteFinishCheck({ shipmentNoteId: id });

  if (response.code === 200 && response.data) {
    return response.data;
  }
  message.error(response.message);
  return null;
};

// 结束发货单弹窗
const openFinishInvoiceDialog = async (orderCode: string, orderId: number, refresh: () => void) => {
  const checkResult = await finishCheckService(orderId);

  if (_.isNil(checkResult)) return;
  let content = getCheckPassedContent(orderCode);

  if (checkResult?.failCount! > 0) {
    content = getCheckFailedContent(checkResult?.failDetails, orderCode);
  }

  Modal.confirm({
    title: '结束发货单提示',
    width: 800,
    content,
    okText: checkResult?.failCount! > 0 ? '异常结束' : '结束',
    cancelText: '暂不结束',
    onOk: () =>
      finishInvoiceService(orderId, refresh, checkResult?.failCount! > 0 ? '异常结束' : '结束'),
  });
};

export default openFinishInvoiceDialog;
