import { appEnum } from 'src/dict';
import { RepairTaskSource } from 'src/dict/resources';
import { BL_BUSINESS_URL } from './config';

export const toDetail = (id: number) => {
  return `${BL_BUSINESS_URL}/${id}/detail`;
};

export const goToDetail = (id: number) => {
  window.reactRouterHistoryInstance.push(toDetail(id));
};

export const goToCreate = () => {
  window.reactRouterHistoryInstance.push(`${BL_BUSINESS_URL}/create`);
};

export const goToEdit = (id: number) => {
  window.reactRouterHistoryInstance.push(`${BL_BUSINESS_URL}/${id}/edit`);
};

export const goToCopy = (id: number) => {
  window.reactRouterHistoryInstance.push(`${BL_BUSINESS_URL}/${id}/copy`);
};

export const goToList = () => {
  window.reactRouterHistoryInstance.push(BL_BUSINESS_URL);
};

export const goToCreateResourceTag = () => {
  window.open('/resource/resourceConfig/resourceTag');
};

export const getDetailPath = (sourceType: RepairTaskSource) => {
  const detailMap = {
    [appEnum.Resources.RepairTaskSource.maintenanceTask]: '/resource/implement/maintenance/task',
    [appEnum.Resources.RepairTaskSource.repairTask]: '/resource/implement/repair/task',
    [appEnum.Resources.RepairTaskSource.produceTask]:
      '/productionPlanning/execution/productionTaskList',
    [appEnum.Resources.RepairTaskSource.manual]: null,
  };

  return detailMap[sourceType];
};
