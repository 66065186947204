export const roundRule = new Map([
  [1, '向上圆整'],
  [2, '向下圆整'],
]);

export const STORE_ENUM_TYPE = [
  { id: 0, name: '停用' },
  { id: 1, name: '启用中' },
];

export const BasePath = '/quality/qualityModeling/aql';
