import { Checkbox, Form, FormInstance, Input, Select } from 'antd';
import { BizRangeOptionsList } from '../..';
import AddMaterialProperty from 'src/page/knowledgeManagement/materialModeling/material/addmaterialProperty';
import UploadImg from 'src/page/knowledgeManagement/materialModeling/material/share/uploadImg';
import { BlIcon } from 'src/components';
import { bizType as bizTypeOptionalRangeMappings } from 'src/dict/material/mappings';
import { handleBizTypeChangeDefaults } from '../../utils';
import SearchSelect from 'src/components/searchSelect';
import { checkTwoSidesTrim } from 'src/utils/formValidators';
import AddmaterialBatchManagement from '../../addmaterialBatchManagement';
import { BizType } from 'src/dict/material';
import { CRUD, YN } from 'src/dict/common';
import { OBJECT_OF_ID } from 'src/entity/objectPlatform';
import UnitsFormTable from '../../addTransformUnits/unitsFormTable';
import BcBlCascader from 'src/components/Cascader';
import { ICustomFields } from 'src/components/customField';
import { FieldPermission, judegFieldPermissionEditable } from 'src/utils/auth/fieldPermission';

const { TextArea } = Input;

interface Props {
  form: FormInstance;
  type: string;
  bizRangeOptionsList: BizRangeOptionsList[];
  setBizRangeOptionsList: (params: BizRangeOptionsList[]) => void;
  setUpdateMark?: (param: number) => void;
  setUpdateMBizRangeMark?: (param: number) => void;
  renderMaterialCode: any;
  customFields?: ICustomFields;
  fieldsPermission: FieldPermission[];
}

export const baseInfo = (props: Props) => {
  const {
    form,
    type,
    bizRangeOptionsList,
    setBizRangeOptionsList,
    setUpdateMBizRangeMark,
    setUpdateMark,
    renderMaterialCode,
    customFields,
    fieldsPermission,
  } = props;

  /** 当前用户是否有「物料名称」的字段权限 */
  const hasMaterialNameFieldPermission = judegFieldPermissionEditable(fieldsPermission, '物料名称');

  const handleBizTypeOnChange = (selectedValues: any) => {
    const { optionsList, bizRange } = handleBizTypeChangeDefaults(selectedValues);

    form.setFieldsValue({
      bizRange,
    });
    setBizRangeOptionsList(optionsList);
  };

  return [
    ...renderMaterialCode,
    {
      label: '物料名称',
      name: 'name',
      rules: [
        { required: true, message: '请输入物料名称' },
        { max: 255, message: '不超过255字符' },
        { validator: checkTwoSidesTrim },
      ],
      validateFirst: true,
      render: () => (
        <Input
          disabled={!hasMaterialNameFieldPermission}
          placeholder={
            hasMaterialNameFieldPermission ? '请输入' : '当前用户没有物料名称的编辑权限！'
          }
        />
      ),
    },
    {
      label: '物料单位',
      required: true,
      isFullLine: true,
      render: () => <UnitsFormTable customFields={customFields} form={form} name="transformUnit" />,
    },
    {
      label: '物料类型',
      name: 'bizType',
      rules: [{ required: true, message: '请选择物料类型' }],
      render: () => (
        <Select
          disabled={type === CRUD.edit}
          options={bizTypeOptionalRangeMappings}
          placeholder={'请选择'}
          onChange={handleBizTypeOnChange}
        />
      ),
    },
    {
      dependencies: ['bizType'],
      noStyle: true,
      render: (FormItemProps: any) => () =>
        (
          <Form.Item
            name={'bizRange'}
            label={'业务范围'}
            rules={[
              {
                required: form.getFieldValue('bizType') !== BizType.phantom,
                message: '请选择业务范围',
              },
            ]}
            validateFirst
            {...FormItemProps}
          >
            <Checkbox.Group
              options={bizRangeOptionsList}
              onChange={() => {
                setUpdateMBizRangeMark && setUpdateMBizRangeMark(new Date().getTime());
              }}
            />
          </Form.Item>
        ),
    },
    {
      label: '物料分类',
      name: 'category',
      render: () => <BcBlCascader fetchType={'materialCategory'} nameLabel="物料分类" />,
    },
    {
      label: '物料分类RelationId(占位)',
      name: 'categoryRelationId',
      hidden: true,
      render: () => <Input placeholder="请输入" />,
    },
    {
      label: '标识码',
      name: 'qrCode',
      hidden: type === CRUD.create, // 创建时 无此表单
      render: () => (
        <Input
          placeholder="新建时默认与物料编号一致"
          suffix={<BlIcon type="iconerweima" style={{ fontSize: 20 }} />}
        />
      ),
    },
    {
      label: '物料属性',
      isFullLine: true,
      render: () => <AddMaterialProperty form={form} />,
    },
    {
      label: '物料规格',
      name: 'specification',
      rules: [
        { required: false, message: '请输入物料名称' },
        { max: 255, message: '不超过255字符' },
      ],
      render: () => <Input placeholder="请输入" />,
    },
    {
      label: '物料图片',
      name: 'documents',
      isFullLine: true,
      render: () => <UploadImg form={form} />,
    },
    {
      label: '批次管理',
      name: 'batchManagementEnable',
      valuePropName: 'checked',
      render: () => <Checkbox>启用批次管理</Checkbox>,
    },
    {
      isFullLine: true,
      dependencies: ['batchManagementEnable'],
      noStyle: true,
      render: (FormItemProps: any) => () =>
        (
          <Form.Item
            label={'批次属性'}
            hidden={!form.getFieldValue('batchManagementEnable')}
            {...FormItemProps}
          >
            <AddmaterialBatchManagement customFields={customFields} form={form} />
          </Form.Item>
        ),
    },
    {
      dependencies: ['batchManagementEnable'],
      noStyle: true,
      render: (FormItemProps: any) => () =>
        (
          <Form.Item
            label={'批次号规则'}
            name={'batchNoRule'}
            hidden={!form.getFieldValue('batchManagementEnable')}
            {...FormItemProps}
          >
            <SearchSelect
              labelInValue
              fetchType={'numberingRules'}
              placeholder={'请选择'}
              params={{ suitObjId: OBJECT_OF_ID.batchcode, enableFlag: YN.yes }}
            />
          </Form.Item>
        ),
    },
    {
      label: '备注',
      name: 'remark',
      rules: [{ max: 1000, message: '不超过1000字符' }, { validator: checkTwoSidesTrim }],
      validateFirst: true,
      render: () => <TextArea />,
    },
    {
      label: '编辑原因',
      name: 'reason',
      hidden: type === CRUD.create,
      rules: [{ max: 1000, message: '不可超过1000字符' }],
      render: () => <TextArea placeholder="请输入编辑原因" />,
    },
  ];
};
