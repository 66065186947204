import { Form, message as Message, Modal, Tag } from 'antd';
import { DetailLayoutForDrawer, DetailLayout } from 'layout';

import {
  fetchSupplierDisable,
  FetchSupplierDisableRequest,
  fetchSupplierEnable,
  FetchSupplierEnableRequest,
  fetchSupplierListDeregMaterial,
} from 'src/api/ytt/supply-domain/supplier';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { BlIcon, SingleOperationLogComponent, BlTable, TagList } from 'src/components';
import { lookup } from 'src/dict';
import { UsageStatus, CRUD } from 'src/dict/common';
import { replaceSign } from 'src/utils/constants';
import { SupplierOperateMode, SupplyRegStatus } from 'src/dict/supplier';
import { fieldLabels, SupplierOperateModeTitleMap } from 'src/dict/supplier/mappings';
import type {
  SupplierListItemProps,
  MaterialResItemProps,
  SupplierRegisterPorps,
} from '../interface';
import { renderUsageStatus } from 'src/page/share/renderUsageStatus';
import renderRegStatus from 'src/page/share/renderRegStatus';
import _ from 'lodash';
import { fieldTypeList } from 'src/utils';
import { useEffect, useState } from 'react';
import {
  fetchSupplierRegisterMaterialDeregister,
  fetchSupplierRegisterMaterialListSupplierId,
  fetchSupplierRegisterMaterialRegister,
  FetchSupplierRegisterMaterialRegisterRequest,
  fetchSupplierRegisterDeregister,
  fetchSupplierRegisterIsDeregister,
  FetchSupplierRegisterIsDeregisterRequest,
  fetchSupplierRegisterRegister,
} from 'src/api/ytt/supply-chain-domain/supplier/register_material';
import { formatTimeForRender } from 'src/utils/formatters';
import useBlModal from 'src/components/modal/useBlModal';
import MaterialRegisterFormTable from '../../components/materialRegisterTable';
import SupplierRegisterFormTable from '../../components/supplierRegisterTable';
import RemoveMaterialRegisterFormTable from '../../components/removeMaterialList';
import authDict from 'src/utils/auth';

interface Props {
  supplierData: SupplierListItemProps;
  onClose: () => void;
  refresh: () => void;
  goToEdit: () => void;
}

type UserProps = SupplierListItemProps['creator'];

/** 供应商详情 */
const SupplierDetailBaseInfo = (props: Props) => {
  const { supplierData, refresh, goToEdit, onClose } = props;
  const [dataSource, setDataSource] = useState<any>([]);
  const [visibleLog, setVisibleLog] = useState(false);
  const [form] = Form.useForm();
  const fetchData = async () => {
    try {
      // 供应商详情中的列表
      const { data } = await fetchSupplierRegisterMaterialListSupplierId({
        supplierId: (supplierData.id && supplierData.id) || 0,
      });

      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };

  const { show } = useBlModal();

  useEffect(() => {
    fetchData();
  }, []);

  const enableOrDisableSupplier = async (
    ids: Array<number>,
    enableFlag: UsageStatus,
    onSuccess?: () => void,
    onFail?: () => void,
  ) => {
    console.log('ppp');
    const requestParam: FetchSupplierEnableRequest | FetchSupplierDisableRequest = { ids };
    const requestFn = enableFlag ? fetchSupplierDisable : fetchSupplierEnable;
    const response = await requestFn(requestParam);

    if (response && response.code === 200) {
      Message.success(enableFlag ? '停用成功' : '启用成功');
      onSuccess?.();
      refresh();
    } else {
      Message.error(response.message);
      onFail?.();
    }
  };

  const columns = [
    {
      title: fieldLabels.materialNumber,
      dataIndex: 'materialCode',
      width: 150,
      render: (_text: string, record: MaterialResItemProps) => {
        return record.materialCode ? record.materialCode : replaceSign;
      },
    },
    {
      title: fieldLabels.materialName,
      dataIndex: 'materialName',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: MaterialResItemProps) => {
        return record.materialName ? record.materialName : replaceSign;
      },
    },
    {
      title: fieldLabels.materialProperties,
      dataIndex: 'materialAttribute',
      width: 230,
      render: (text: { name: string; attributeItem: { content: string }; id: number }[]) => {
        if (_.isEmpty(text)) return replaceSign;

        return (
          <TagList
            dataSource={_.map(text, ({ name, attributeItem, id }) => ({
              label: `${name}:${attributeItem.content}`,
              value: id,
            }))}
          />
        );
      },
    },
    {
      title: fieldLabels.number,
      dataIndex: 'materialAmount',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: MaterialResItemProps) => {
        return record.materialAmount && record.materialAmount.amount
          ? record.materialAmount.amount
          : replaceSign;
      },
    },
    {
      title: fieldLabels.unit,
      dataIndex: 'materialUnit',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: MaterialResItemProps) => {
        return record.materialAmount && record.materialAmount.unitName
          ? record.materialAmount.unitName
          : replaceSign;
      },
    },
    {
      title: fieldLabels.registrationStatus,
      dataIndex: 'regStatus',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: MaterialResItemProps) => {
        return lookup('supplyRegStatus.crud', record.regStatus);
      },
    },
    {
      title: fieldLabels.supplierMaterial,
      dataIndex: 'supMaterialCode',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: MaterialResItemProps) => {
        return record.supMaterialCode ? record.supMaterialCode : replaceSign;
      },
    },
    {
      title: fieldLabels.supplierNumber,
      dataIndex: 'supMaterialAmount',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: MaterialResItemProps) => {
        return record.supMaterialAmount && record.supMaterialAmount.amount
          ? record.supMaterialAmount.amount
          : replaceSign;
      },
    },
    {
      title: fieldLabels.supplierUnit,
      dataIndex: 'supMaterialUnit',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: MaterialResItemProps) => {
        return record.supMaterialAmount && record.supMaterialAmount.unitName
          ? record.supMaterialAmount.unitName
          : replaceSign;
      },
    },
  ];

  const supplierDetailInfo = _.compact([
    {
      title: '基本信息',
      items: [
        {
          label: fieldLabels.supplierCode,
          dataIndex: 'code',
        },
        {
          label: fieldLabels.supplierName,
          dataIndex: 'name',
        },
        {
          label: fieldLabels.supplierStatus,
          dataIndex: 'status',
          render: (status: UsageStatus) => <span>{renderUsageStatus(status)}</span>,
        },
        {
          label: fieldLabels.supplierOwner,
          dataIndex: 'owner',
          render: (_text: string, record: SupplierListItemProps) => {
            return record.owner && record.owner.name ? record.owner.name : replaceSign;
          },
        },
        {
          label: fieldLabels.supplierRegStatus,
          dataIndex: 'regStatus',
          render: (status: SupplyRegStatus) => <span>{renderRegStatus(status)}</span>,
        },
        {
          label: fieldLabels.regEnterprise,
          dataIndex: 'regEnterprise',
          render: (_text: string, record: SupplierListItemProps) => {
            return record.regStatus == 0 || record.regStatus == 1 ? (
              replaceSign
            ) : (
              <span>
                {record.supplierOrg?.orgId}
                {record.supplierOrg?.orgName}
              </span>
            );
          },
        },
        {
          label: fieldLabels.remark,
          dataIndex: 'remark',
          render: (remark: string) => {
            return remark || replaceSign;
          },
        },
      ],
    },
    {
      title: '其他信息',
      items: [
        {
          label: fieldLabels.creator,
          dataIndex: 'creator',
          render: (creator: UserProps) => (
            <AvatarDisplay
              id={creator?.id!}
              name={creator?.name!}
              avatarUrl={creator?.avatarUrl}
              key={creator?.id}
              isShowTag={false}
              isUser
            />
          ),
        },
        {
          label: fieldLabels.createdAt,
          dataIndex: 'createdAt',
          dateFormat: 'YYYY-MM-DD HH:mm:ss',
          render: formatTimeForRender,
        },
        {
          label: fieldLabels.operator,
          dataIndex: 'operator',
          render: (operator: UserProps) => (
            <AvatarDisplay
              id={operator?.id!}
              name={operator?.name!}
              avatarUrl={operator?.avatarUrl}
              key={operator?.id}
              isShowTag={false}
              isUser
            />
          ),
        },
        {
          label: fieldLabels.updatedAt,
          dataIndex: 'updatedAt',
          dateFormat: 'YYYY-MM-DD HH:mm:ss',
          render: formatTimeForRender,
        },
      ],
    },
    {
      title: '注册物料',
      items: [
        {
          label: '',
          dataIndex: 'table',
          render: () => {
            return (
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                }}
              >
                <BlTable
                  columns={[...columns]}
                  scroll={{ x: 1200, y: 800 }}
                  dataSource={dataSource}
                  style={{ width: '100%' }}
                />
              </div>
            );
          },
        },
      ],
    },
  ]);

  // 邀请注册供应商

  const handleShowSupplierRegister = () => {
    show({
      title: '邀请注册供应商',
      content: (
        <SupplierRegisterFormTable
          selectedRows={[
            {
              code: supplierData.code,
              name: supplierData.name,
            },
          ]}
          form={form}
        />
      ),
      width: 800,
      onOk: async () => {
        const supplierRegisterProps: SupplierRegisterPorps[] = form
          .getFieldValue('supplierRegister')
          .map((node: SupplierRegisterPorps) => {
            return {
              code: node.code,
              email: node.email,
              supplierOrgCode: node.supplierOrgId,
            };
          });
        const response = await fetchSupplierRegisterRegister(supplierRegisterProps);

        if (response && response.code === 200) {
          Message.success('邀请注册供应商成功');
          refresh();
        } else {
          Message.error(response.message);
        }
      },
      closable: false,
    });
  };

  // 解除注册供应商
  const RemoveSupplierReg = async (
    judegRemoveSupplierList: number[],
    onSuccess?: () => void,
    onFail?: () => void,
  ) => {
    try {
      const res = await fetchSupplierRegisterDeregister(judegRemoveSupplierList);

      if (res && res.code === 200) {
        Message.success('解除注册供应商成功');
      } else {
        Message.error(res.message);
      }
      onFail?.();
      refresh();
    } catch (error) {
      onSuccess?.();
      refresh();
    }
  };

  const judegRemoveSupplierReg = async () => {
    try {
      form.resetFields();

      const judegRemoveSupplierProps: FetchSupplierRegisterIsDeregisterRequest = {
        supplierIds: [supplierData.id!],
      };

      const response = await fetchSupplierRegisterIsDeregister(judegRemoveSupplierProps);

      if (response.data?.failCount == 0) {
        Modal.confirm({
          title: '解除注册供应商',
          content: <div>{'确定解除注册的供应商吗'}</div>,
          visible: true,
          onOk() {
            RemoveSupplierReg([supplierData.id!]);
          },
          okText: '解除',
          cancelText: '暂不解除',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 邀请注册物料
  const handleShowMaterailRegister = () => {
    show({
      title: '邀请注册物料',
      content: <MaterialRegisterFormTable selectedRows={[supplierData]} form={form} />,
      width: 1080,
      onOk: async () => {
        interface register {
          amount: number;
          id?: number | undefined;
          materialId: number;
          supplierId: number;
          unitId: number;
        }

        const registerMaterial: register[] = form
          .getFieldValue('materialRegister')
          .map((node: any) => {
            console.log('node', node);
            return {
              amount: parseInt(node.amount),
              materialId: node.materialsId,
              supplierId: node.suppliersId,
              unitId: node.unitId,
            };
          });
        const registerMaterialListProps: FetchSupplierRegisterMaterialRegisterRequest = {
          registerMaterialList: registerMaterial,
        };

        const response = await fetchSupplierRegisterMaterialRegister(registerMaterialListProps);

        if (response && response.code === 200) {
          Message.success('邀请注册物料成功');
        } else {
          Message.error(response.message);
        }
      },
      closable: false,
    });
  };

  // 解除注册物料

  const RemoveMaterialReg = async () => {
    const removeListProps: number[] = [];

    const removeProps = form.getFieldValue('removeMaterialRegister').map((node: any) => {
      return removeListProps.push(node.itemId);
    });

    const removeResProps = {
      ids: removeListProps,
    };

    const res = await fetchSupplierRegisterMaterialDeregister(removeResProps);

    if (res && res.data?.failCount === 0) {
      Message.success('解除注册物料成功');
    } else {
      Message.error('有采购订单/收货单/采购退货单未结束，解除注册物料失败');
    }
  };

  const judegRemoveMaterialReg = async () => {
    try {
      form.resetFields();

      const { data } = await fetchSupplierListDeregMaterial([supplierData.id!]);

      const removeRegister = data?.map((node: any) => {
        return {
          code: node.code,
          name: node.name,
          materialCode: node.materialCode,
          materialName: node.materialName,
          materialAttr: node.materialAttr,
          materialAmount: node.materialAmount?.amount,
          materialUnitId: node.materialAmount?.unitId,
          materialUnit: node.materialAmount?.unitName,
          regStatus: node.regStatus,
          supMaterialCode: node.supMaterialCode,
          supMaterialName: node.supMaterialName,
          supMaterialAmount: node.supMaterialAmount?.amount,
          supMaterialUnit: node.supMaterialAmount?.unitName,
          materialId: node.materialId,
          supplierId: node.id,
          itemId: node.itemId,
        };
      });

      form.setFieldsValue({ removeMaterialRegister: removeRegister });

      Modal.confirm({
        title: '解除注册物料',
        width: 800,
        content: <RemoveMaterialRegisterFormTable form={form} />,
        visible: true,
        onOk() {
          RemoveMaterialReg();
        },
        onCancel() {},
        okText: '解除',
        cancelText: '暂不解除',
      });
    } catch (error) {
      console.log(error);
    }
  };

  // DetailLayout正在添加统一的loading方法，添加完以后可以用loading属性替代supplierData的判断
  const baseMenu = [
    {
      key: 'statusChange',
      auth: authDict.supplier_enable_disable,
      onClick: () => {
        if (!supplierData) return;
        enableOrDisableSupplier([supplierData.id!], supplierData.status!);
      },
      title: supplierData ? lookup('changeStatusAction', supplierData.status) : null,
    },
    {
      disabled: supplierData.regStatus !== SupplyRegStatus.unregistered,
      auth: authDict.supplier_register_supplier,
      key: 'supplierRegister',
      onClick: () => {
        form.resetFields();
        handleShowSupplierRegister();
      },
      title: '邀请注册供应商',
    },
    {
      disabled: supplierData.regStatus !== SupplyRegStatus.registered,
      auth: authDict.supplier_remove_register_supplier,
      key: 'removeSupplierRegister',
      onClick: () => {
        judegRemoveSupplierReg();
      },
      title: '解除注册供应商',
    },
    {
      disabled: supplierData.regStatus !== SupplyRegStatus.registered,
      auth: authDict.supplier_register_material,
      key: 'MaterailRegister',
      onClick: () => {
        handleShowMaterailRegister();
      },
      title: '邀请注册物料',
    },
    {
      key: 'removeMaterailRegister',
      auth: authDict.supplier_remove_register_material,
      onClick: () => {
        judegRemoveMaterialReg();
      },
      title: '解除注册物料',
    },
    {
      key: 'record',
      auth: authDict.supplier_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'edit',
      onClick: goToEdit,
      icon: <BlIcon type="iconbianji" />,
      title: lookup('common.crud', CRUD.edit),
      auth: authDict.supplier_edit,
    },
  ];

  return supplierData ? (
    <>
      <DetailLayoutForDrawer
        content={
          <>
            <DetailLayout
              title={SupplierOperateModeTitleMap[SupplierOperateMode.view]}
              dataSource={supplierData}
              info={supplierDetailInfo}
              baseMenu={baseMenu}
            />
            {visibleLog && (
              <SingleOperationLogComponent
                visible={visibleLog}
                instanceId={supplierData.id!}
                objectCode={'Supplier'} // 对象在对象平台唯一定义的objectCode
                closeDetail={() => {
                  setVisibleLog(false);
                }}
              />
            )}
          </>
        }
        visible
        width={600}
        onClose={onClose}
      />
    </>
  ) : null;
};

export default SupplierDetailBaseInfo;
