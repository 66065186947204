import authDict from 'src/utils/auth';
import AccountManagement from '../organization/accountManagement';
import TriggerRulesTabList from '../organization/triggerRule/index';
import TriggerRuleDetail from '../organization/triggerRule/detail';
import CreateOrEditTriggerRule from '../organization/triggerRule/createAndEdit';
import TriggerActionTabList from '../organization/triggerAction';
import TriggerActionDetail from '../organization/triggerAction/detail';
import CreateOrEditTriggerAction from '../organization/triggerAction/createOrEdit';
import NoticeTemplateList from '../organization/noticeTemplate';
import CreateNoticeTemplate from '../organization/noticeTemplate/creat';
import NoticeTemplateDetail from '../organization/noticeTemplate/detail';
import EditNoticeTemplate from '../organization/noticeTemplate/edit';
import { customMenuRoute } from 'src/page/organization/customMenu/navigation';

const customFieldRoute = [
  {
    path: '/tenantConfig',
    menu: '租户信息设置',
    icon: 'icongerenshezhi',
    breadcrumb: '租户信息设置',
    redirectPath: '/tenantConfig/accountManagement',
    children: [
      {
        path: '/tenantConfig/accountManagement',
        auth: authDict.tenant_tenant_info_set,
        component: AccountManagement,
        breadcrumb: '账户设置',
      },
    ],
  },
  {
    path: '/interfaceConfig',
    menu: '界面管理',
    icon: 'iconjiemianguanli',
    breadcrumb: '界面管理',
    redirectPath: customMenuRoute.path,
    children: [customMenuRoute],
  },
  {
    path: '/systemConfig',
    menu: '系统设置',
    icon: 'iconshezhi',
    breadcrumb: '系统设置',
    redirectPath: '/systemConfig/triggerRule',
    children: [
      {
        path: '/systemConfig/triggerRule',
        menu: '触发规则',
        breadcrumb: '触发规则',
        auth: authDict.triggerrules_view,
        component: TriggerRulesTabList,
        children: [
          {
            path: '/systemConfig/triggerRule/create',
            auth: authDict.triggerrules_add,
            component: CreateOrEditTriggerRule,
            breadcrumb: '新建触发规则',
          },
          {
            path: '/systemConfig/triggerRule/:id/edit',
            auth: authDict.triggerrules_edit,
            component: CreateOrEditTriggerRule,
            breadcrumb: '编辑触发规则',
          },
          {
            path: '/systemConfig/triggerRule/:id/detail/:ruleSource',
            auth: authDict.triggerrules_detail,
            component: TriggerRuleDetail,
            breadcrumb: '触发规则详情',
          },
        ],
      },
      {
        path: '/systemConfig/triggerAction',
        auth: authDict.triggeraction_view,
        component: TriggerActionTabList,
        breadcrumb: '触发事件',
        menu: '触发事件',
        children: [
          {
            path: '/systemConfig/triggerAction/create',
            auth: authDict.triggeraction_add,
            component: CreateOrEditTriggerAction,
            breadcrumb: '新建触发事件',
          },
          {
            path: '/systemConfig/triggerAction/:id/edit',
            auth: authDict.triggeraction_edit,
            component: CreateOrEditTriggerAction,
            breadcrumb: '编辑触发事件',
          },
          {
            path: '/systemConfig/triggerAction/:id/detail/:actionSource',
            auth: authDict.triggeraction_detail,
            component: TriggerActionDetail,
            breadcrumb: '触发事件详情',
          },
        ],
      },
      {
        path: '/systemConfig/noticeTemplate',
        menu: '通知模板',
        breadcrumb: '通知模板',
        auth: authDict.messagetemplate_view,
        component: NoticeTemplateList,
        children: [
          {
            path: '/systemConfig/noticeTemplate/create',
            auth: authDict.messagetemplate_add,
            component: CreateNoticeTemplate,
            breadcrumb: '新建通知模板',
          },
          {
            path: '/systemConfig/noticeTemplate/:id/edit',
            auth: authDict.messagetemplate_edit,
            component: EditNoticeTemplate,
            breadcrumb: '编辑通知模板',
          },
          {
            path: '/systemConfig/noticeTemplate/:id/detail',
            auth: authDict.messagetemplate_detail,
            component: NoticeTemplateDetail,
            breadcrumb: '通知模板详情',
          },
        ],
      },
    ],
  },
];

export default customFieldRoute;
