/**
 * @enum 资源业务模块枚举值
 */

/** 列表视图 */
export enum EquipmentLevel {
  /** 一级 */
  'one' = 1,
  /** 二级 */
  'two',
  /** 三级 */
  'three',
  /** 四级 */
  'four',
  /** 五级 */
  'five',
}

/**
 * @enum 资源使用年限单位
 */
export enum UseAgeUnit {
  /** 年 */
  'year' = 1,
  /** 月 */
  'month',
  /** 日 */
  'day',
}

// 自动获取api频率单位
export enum AutoFrequencyUnit {
  /** 分 */
  'minute',
  /** 小时 */
  'hour',
}

/** 资源业务类型 */
export enum ResourceBusinessType {
  /** 设备 */
  'equipment',
  /** 能源仪表 */
  'energy',
  /** 参数 */
  'parameter',
  /** 维保 */
  'maintenance',
  /** 故障 */
  'malfunction',
  /** 维修 */
  'repair',
}

/** 设备业务类型 */
export enum EquipmentBusinessType {
  /** 生产设备 */
  product,
  /** 称量设备 */
  weighting,
  /** 其他设备 */
  other,
}

/** 参数类型 */
export enum ParmTypeEnum {
  /** 实时数值 */
  'actualTime' = 0,
  /** 累计数值 */
  'accumulate',
  /** 文本型 */
  'text',
}

/**
 * @enum 能源仪表业务类型
 */
export enum EnergyMeterType {
  /** 电表 */
  'electric' = 0,
  /** 水表 */
  'water',
  /** 气表 */
  'gas',
  /** 其他 */
  'other',
}

/**
 * @enum 能源仪表状态
 */
export enum EnergyMeterStatus {
  /** 启用中 */
  'enabled' = 0,
  /** 停用中 */
  'disabeld',
  /** 报废中 */
  'scrap',
}

/**
 * @enum 参数监控视图类型
 */
export enum ResourceMonitorViewType {
  /** 资源参数 */
  'resource',
  /** 工艺参数 */
  'task',
}

/*
 * @enum 维保业务类型
 */
export enum MaintenanceType {
  /** 保养 */
  'maintain' = 0,
  /** 点检 */
  'spotCheck',
  /** 巡检 */
  'patrolCheck',
  /** 润滑 */
  'lubricate',
  /** 校准 */
  'calibrate',
  /** 清洗 */
  'clean',
}

/**
 * @enum 维保途径
 */
export enum MaintenanceChannel {
  /** 自主 */
  'autonomy' = 0,
  /** 委外 */
  'entrust',
}

/**
 * @enum 维保方案 执行方式
 */
export enum MaintenanceExecuteType {
  /** 报告模板 */
  'reportTemplate' = 0,
  /** sop */
  'SOP',
}

/**
 * @enum 维保任务 来源
 */
export enum MaintenanceTaskSource {
  /** 手动创建 */
  'reportTemplate' = 0,
  /** 触发事件 */
  'event',
  /** 触发规则 */
  'rule',
}

/**
 * @enum 维保任务 状态
 */
export enum MaintenanceTaskStatus {
  /** 未开始 */
  'notStarted' = 0,
  /** 执行中 */
  'executing',
  /** 已结束 */
  'ended',
  /** 暂停中 */
  'suspended',
  /** 已取消 */
  'canceled',
  /** 待执行 */
  'deferred',
}

/**
 * @enum 维修途径
 */
export enum RepairChannel {
  /** 自主 */
  'autonomy' = 0,
  /** 委外 */
  'entrust',
}

/**
 * @enum 维修方案 执行方式
 */
export enum RepairExecuteType {
  /** 报告模板 */
  'reportTemplate' = 0,
  /** sop */
  'SOP',
}

/**
 * @enum 维修任务 来源
 */
export enum RepairTaskSource {
  /** 手动创建 */
  'manual',
  /** 生产任务 */
  'produceTask',
  /** 维保任务 */
  'maintenanceTask',
  /** 维修任务 */
  'repairTask',
}

/**
 * @enum 维修任务 状态
 */
export enum RepairTaskStatus {
  /** 未开始 */
  'notStarted' = 0,
  /** 执行中 */
  'executing',
  /** 已结束 */
  'ended',
  /** 暂停中 */
  'suspended',
  /** 已取消 */
  'canceled',
  /** 待执行 */
  'toExcute',
}

export enum MalfunctionType {
  /** 功能故障 */
  'function' = 0,
  /** 参数故障 */
  'params',
  /** 其他故障 */
  'other',
}

export enum MalfunctionLevel {
  /** 微小故障 */
  'level1' = 0,
  /** 一般故障 */
  'level2',
  /** 较大故障 */
  'level3',
  /** 重大故障 */
  'level4',
  /** 一般事故 */
  'level5',
  /** 较大事故 */
  'level6',
  /** 重大事故 */
  'level7',
  /** 特大事故 */
  'level8',
}

export enum MalfunctionRecordOrign {
  /** 手动创建 */
  'manual' = 0,
  /** 触发事件 */
  'event',
}

export enum MalfunctionRecordOrignObj {
  /** 参数记录 */
  'record' = 0,
  /** 参数监控 */
  'monitor',
}
