/**
 * @enum 发货单业务模块枚举值
 */

/** 发货单来源 */
export enum InvoiceOrigin {
  /** 普通订单 */
  normal = 1,
  /** 协同订单 */
  cowork,
  /** 协同交货计划单 */
  collaborativeDeliverySchedule,
}

/** 发货单状态 */
export enum InvoiceStatus {
  /** 新建 */
  new = 1,
  /** 执行中 */
  executing,
  /** 结束 */
  finished,
  /** 关闭 */
  closed,
}

/** 发货单中添加销售订单来源 */
export enum SailOrderOrigin {
  /** 普通订单 */
  normal,
  /** 协同订单 */
  cowork,
}

/** 发货单列表展示 */
export enum InvoiceViewList {
  /** 单据展示 */
  document = '1',
  /** 明细行展示 */
  detail = '2',
}
