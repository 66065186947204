import { Form, FormInstance, Input, InputNumber, Select, Space } from 'antd';
import { BatchOperateTableHeader, BlSortFormList, TagList } from 'components';
import { TextToolTip } from '@blacklake-web/component';
import _ from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import React from 'react';
import { getMaterialAttrs, getMaterialUnits } from 'src/entity/material';
import { NamePath } from 'antd/lib/form/interface';
import { replaceSign } from 'src/utils/constants';
import MaterialSelect from 'src/components/searchSelect/material';
import {
  checkMaterialCodeRepeat,
  getMaterialCodeList,
} from 'src/page/knowledgeManagement/engineerData/alternativePlan/helper/utils';
import {
  ALTERNATIVE_MATERIALS,
  ORIGINAL_ALTERNATIVE_MATERIALS,
} from 'src/page/knowledgeManagement/engineerData/alternativePlan/helper/constants';
import { appEnum } from 'src/dict';

interface Props {
  name: string;
  form: FormInstance;
  isCreateMode: boolean;
}

const OriginalAlternativeFormTable = ({ name, form, isCreateMode }: Props) => {
  const getMaterialInfo = (namepath: NamePath, subField?: string) => {
    const materialInfo = JSON.parse(form.getFieldValue(namepath)?.value ?? '{}');

    if (subField) {
      return materialInfo[subField];
    }
    return materialInfo;
  };
  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '序号',
        dataIndex: 'lineNo',
        key: 'lineNo',
        width: 100,
        fixed: 'left',
        render: (text, record, index) => {
          return <div>{index + 1}</div>;
        },
      },
      {
        title: <BatchOperateTableHeader titleText="物料编号" required />,
        dataIndex: 'materialId',
        width: 200,
        render: (text, record, index) => {
          const onMaterialChange = () => {
            const materialInfo = getMaterialInfo([name, index, 'materialId']);

            if (!materialInfo || _.isEmpty(materialInfo)) return;
            const alternativeMaterialList = _.cloneDeep(form.getFieldValue(name));

            // 单位信息
            const unitId = materialInfo?.feedInfo?.feedUnitId;
            const unitInfo = materialInfo.unitList.find(
              (item: { id: number }) => item.id === unitId,
            );

            alternativeMaterialList[index].unitId = {
              key: unitId,
              label: unitInfo?.name,
              value: unitId,
            };
            form.setFieldsValue({
              [name]: alternativeMaterialList,
            });
          };

          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              name={[record.name, 'materialId']}
              rules={[
                { required: true, message: '物料不能为空' },
                {
                  validator: checkMaterialCodeRepeat(
                    getMaterialCodeList(
                      [ORIGINAL_ALTERNATIVE_MATERIALS, ALTERNATIVE_MATERIALS],
                      form,
                    ),
                    '物料不能重复，请检查！',
                  ),
                },
              ]}
            >
              <MaterialSelect
                onChange={onMaterialChange}
                params={{
                  enableFlag: appEnum.Common.UsageStatus.enabled,
                  bizType: [appEnum.Material.BizType.default],
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '物料名称',
        dataIndex: 'materialName',
        width: 200,
        render: (text, record, index) => {
          return (
            <Form.Item dependencies={[[name, index, 'materialId']]} noStyle>
              {() => getMaterialInfo([name, index, 'materialId'], 'baseInfo')?.name ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料属性',
        dataIndex: 'materialAttributes',
        width: 200,
        render: (text, record, index) => {
          return (
            <Form.Item dependencies={[name, index, 'materialId']} noStyle>
              {() => (
                <TagList
                  dataSource={getMaterialAttrs(getMaterialInfo([name, record.name, 'materialId']))}
                />
              )}
            </Form.Item>
          );
        },
      },
      {
        title: '物料分类',
        dataIndex: 'materialCategory',
        width: 150,
        render: (text, record, index) => {
          return (
            <Form.Item dependencies={[[name, record.name, 'materialId']]} noStyle>
              {() => getMaterialInfo([name, index, 'materialId'])?.category?.name ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料规格',
        dataIndex: 'specification',
        width: 150,
        render: (text, record, index) => {
          return (
            <Form.Item dependencies={[[name, index, 'materialId']]} noStyle>
              {() => (
                <TextToolTip
                  text={
                    getMaterialInfo([name, index, 'materialId'])?.baseInfo?.specification ||
                    replaceSign
                  }
                  width={130}
                />
              )}
            </Form.Item>
          );
        },
      },
      {
        title: '数量:分子',
        dataIndex: 'amountNumerator',
        width: 150,
        render: (text, record, index) => {
          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={[index, 'amountNumerator']}
              initialValue={1}
              rules={_.compact([
                { required: true, message: '数量:分子不能为空' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    max: 10000000,
                    minAllowEqual: false,
                    fieldName: '数量:分子',
                  }),
                },
              ])}
            >
              <InputNumber
                disabled
                style={{ width: '100%' }}
                placeholder="请输入"
                min={0}
                max={10000000}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '使用比例',
        dataIndex: 'useRatio',
        width: 150,
        render: (text, field) => {
          // const baseName = [name];
          return (
            <Form.Item dependencies={[['substitutionStrategy']]} noStyle>
              {() => {
                // 替代策略
                const substitutionStrategy = form.getFieldValue('substitutionStrategy');

                if (
                  substitutionStrategy ===
                  appEnum.AlternativePlan.SubstitutionStrategyEnum.fullSubstitute
                ) {
                  return (
                    <Form.Item
                      style={{ marginBottom: '0' }}
                      fieldKey={[field.fieldKey, 'useRatio']}
                      name={[field.name, 'useRatio']}
                    >
                      <Select>
                        <Select.Option value={0}>0</Select.Option>
                        <Select.Option value={100}>100</Select.Option>
                      </Select>
                    </Form.Item>
                  );
                }
                return (
                  <Space>
                    <Form.Item
                      style={{ marginBottom: '0' }}
                      fieldKey={[field.fieldKey, 'useRatio']}
                      name={[field.name, 'useRatio']}
                      rules={[
                        {
                          validator: numberMinMaxCheck({
                            min: 0,
                            minAllowEqual: true,
                            max: 100,
                            maxAllowEqual: true,
                            fieldName: '使用比例',
                          }),
                        },
                        { validator: fractionLengthCheck(4) },
                      ]}
                      initialValue={100}
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </Space>
                );
              }}
            </Form.Item>
          );
        },
      },

      {
        title: '数量:分母',
        dataIndex: 'amountDenominator',
        width: 150,
        render: (text, record, index) => {
          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={[index, 'amountDenominator']}
              initialValue={1}
              rules={_.compact([
                { required: true, message: '数量:分母不能为空' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: false,
                    max: 10000000,
                    fieldName: '数量:分母',
                  }),
                },
              ])}
            >
              <InputNumber
                disabled
                style={{ width: '100%' }}
                placeholder="请输入"
                min={0}
                max={10000000}
              />
            </Form.Item>
          );
        },
      },
      {
        title: <BatchOperateTableHeader titleText="单位" required />,
        dataIndex: 'unitId',
        width: 150,
        render: (text: string, record: any, index) => (
          <Form.Item
            style={{ marginBottom: 0 }}
            name={[index, 'unitId']}
            validateFirst
            rules={[{ required: true, message: '请选择单位' }]}
            dependencies={[[name, index, 'materialId']]}
          >
            <Select
              placeholder={'请选择'}
              options={getMaterialUnits(getMaterialInfo([name, index, 'materialId']))}
              labelInValue
            />
          </Form.Item>
        ),
      },
    ];
  };

  return (
    <BlSortFormList
      name={name}
      isNeedAdd={false}
      isNeedDrag={false}
      isNeedDelete={false}
      initLineCount={isCreateMode ? 1 : undefined}
      renderColumns={() => getColumns()}
    />
  );
};

export default OriginalAlternativeFormTable;
