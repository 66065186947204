import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { message } from 'antd';
import { FilterItem, RecordListLayout } from 'layout';
import {
  BlIcon,
  LinkTooltip,
  SearchSelect,
  SingleOperationLogComponent,
  TagList,
  Tooltip,
} from 'components';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import moment from 'moment';
import _ from 'lodash';
import { gcTime, gcObject, fieldTypeList } from 'utils';
import lookup, { appEnum, appDict } from 'src/dict';
import { fetchReceiveNoteListMaterial } from 'src/api/ytt/order-domain/deliveryOrder';
import { DeliveryMaterialTableRowType as TableRowType } from '../index.type';
import { renderUser } from 'src/page/share/renderUser';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { useDispatch } from 'react-redux';
import { detailPath, editPath, inBoundCreatePath, createPath } from '../index';
import { endOrder, dispatchOrder, closeOrder } from '../utils';
import ReceiveModel from '../components/receive';
import { getMaterialAttrs } from 'src/entity/material';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import authDict from 'src/utils/auth';

interface Props extends RouteComponentProps {}

const DeliveryOrder = (props: Props) => {
  const { history } = props;
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const [selectedRow, setSelectRow] = useState<any[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [visible, setVisible] = useState<boolean>(false);
  const [ids, setIds] = useState<number[]>([]);
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState<number>();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);

  const toDetail = (id?: number) => {
    if (!id) return;
    history.push(`${detailPath}?id=${id}`);
  };
  const refreshData = () => {
    setRefreshMarker(Math.random());
  };
  const toEdit = (id?: number) => {
    if (!id) return;
    history.push(`${editPath}?id=${id}`);
  };
  const toCreate = () => {
    history.push(`${createPath}`);
  };
  const filterMaterialFn = (items: any[]) => {
    return _.filter(items, (o) => o?.inboundReceiveAmount?.amount > 0);
  };
  const enableFn = (list: TableRowType[]) => {
    return _.every(list, ['execStatus', appEnum.DeliveryEnum.execStatusEnum.executing]);
  };
  const isSameSupplier = (list: TableRowType[]) => {
    const ids = new Set();

    for (const row of list) {
      ids.add(row?.supplierId);
    }

    return ids.size > 1;
  };
  const createInbound = (list: TableRowType[]) => {
    if (!enableFn(list)) {
      message.error('只有执行中的状态才能新建入库单！');
      return;
    }
    if (isSameSupplier(list)) {
      message.error('供应商不一致，请选择供应商相同的收货单');
      return;
    }
    const _list = _.filter(list, ['execStatus', appEnum.DeliveryEnum.execStatusEnum.executing]);
    const materialList = _list.map((item) => ({
      ...item,
      entityCode: item.code,
      entityId: item.receiveNoteId,
    }));
    const filterMaterial = filterMaterialFn(materialList);

    if (_.isEmpty(filterMaterial)) {
      message.error('暂无可入库物料！');
      return;
    }
    window?.globalStore?.dispatch({
      type: 'supplyChain/formatterInBoundInfo',
      payload: {
        items: filterMaterial,
        supplierId: {
          label: list[0]?.supplierName,
          value: list[0]?.supplierId,
        },
      },
    });
    history.push(`${inBoundCreatePath}?source=delivery`);
  };
  const dataColumns = [
    {
      title: '编号',
      dataIndex: 'code',
      sorter: true,
      isFilter: true,
      width: 150,
      render: (value: string, record: TableRowType, index: number, config: any) => (
        <LinkTooltip
          to={`${detailPath}?id=${record?.receiveNoteId}`}
          text={value}
          width={config.contentWidth}
          auth={authDict.receivenote_detail}
        />
      ),
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '来源',
      dataIndex: 'source',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      render: lookup('DeliveryMap.Source'),
      selectProps: {
        placeholder: '请选择',
        mode: 'multiple',
        options: appDict.DeliveryMap.Source,
      },
    },
    {
      title: '状态',
      dataIndex: 'execStatus',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      render: lookup('DeliveryMap.execStatus'),
      selectProps: {
        placeholder: '请选择',
        mode: 'multiple',
        options: appDict.DeliveryMap.execStatus,
      },
    },
    {
      title: '供应商编号',
      dataIndex: 'supplierCode',
      isFilter: true,
      width: 150,
    },
    {
      title: '供应商名称',
      dataIndex: 'supplierName',
      isFilter: true,
      width: 150,
    },
    {
      title: '协同发货单号',
      dataIndex: 'shipmentNoteCode',
      isFilter: true,
      sorter: true,
      width: 150,
    },
    {
      title: '行号',
      dataIndex: 'seqNum',
      width: 80,
    },
    {
      title: '物料编号',
      filterTitle: '物料',
      dataIndex: 'material',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      render: (text: any, record: TableRowType) => record.material?.baseInfo?.code,
      renderItem: <SearchSelect mode="multiple" fetchType="materialCodeName" />,
    },
    {
      title: '物料名称',
      dataIndex: 'materialName',
      width: 150,
      render: (text: any, record: TableRowType) => record.material?.baseInfo?.name,
    },
    {
      title: '物料属性',
      dataIndex: ['materialAttr'],
      width: 150,
      render: (_text: any, record: any) => (
        <TagList dataSource={getMaterialAttrs(record.material)} />
      ),
    },
    {
      title: '应收数',
      dataIndex: 'planReceiveAmount',
      width: 150,
      render: (text: any, record: TableRowType) =>
        `${record.planReceiveAmount?.amountDisplay} ${record.planReceiveAmount?.unitName}`,
    },
    {
      title: '应收时间',
      dataIndex: 'planReceiveTime',
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      width: 200,
      render: (text: number) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '计划入库数',
      dataIndex: 'planStorageAmount',
      width: 150,
      render: (text: any, record: TableRowType) =>
        `${record.planStorageAmount?.amountDisplay} ${record.planStorageAmount?.unitName}`,
    },
    {
      title: '发出数',
      dataIndex: 'deliverAmount',
      width: 150,
      render: (text: any, record: TableRowType) =>
        `${record.deliverAmount?.amountDisplay} ${record.deliverAmount?.unitName}`,
    },
    {
      title: '接收数',
      dataIndex: 'receiveAmount',
      width: 150,
      render: (text: any, record: TableRowType) =>
        `${record.receiveAmount?.amountDisplay} ${record.receiveAmount?.unitName}`,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 150,
      isFilter: true,
      render: renderUser,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择创建人" isMultiple isSelectUser />
      ),
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      width: 200,
      render: (text: number) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      width: 150,
      isFilter: true,
      render: renderUser,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择创建人" isMultiple isSelectUser />
      ),
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      width: 200,
      render: (text: number) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  const config = {
    rowKey: (record: TableRowType) => String(record.id),
    columns: dataColumns,
    getOperationList: (record: TableRowType) => {
      const { execStatus } = record;
      const enable = execStatus === appEnum.DeliveryEnum.execStatusEnum.executing;
      const isCreated = execStatus === appEnum.DeliveryEnum.execStatusEnum.created;

      return _.compact([
        {
          title: '查看',
          auth: authDict.receivenote_detail,
          onClick: () => toDetail(record.receiveNoteId),
        },
        {
          title: '编辑',
          disabled: !enable && !isCreated,
          auth: authDict.receivenote_edit,
          onClick: () => toEdit(record.receiveNoteId),
        },
        {
          title: '下发',
          disabled: !isCreated,
          auth: authDict.receivenote_dispatch,
          onClick: () => dispatchOrder([record?.receiveNoteId ?? 0], refreshData),
        },
        {
          title: '收货',
          disabled: !enable,
          auth: authDict.receivenote_receipt,
          onClick: () => {
            if (!record.id) {
              return;
            }
            setIds([record.id]);
            setVisible(true);
          },
        },
        {
          title: '关闭',
          disabled: !isCreated,
          auth: authDict.receivenote_close,
          onClick: () => closeOrder([record?.receiveNoteId ?? 0], refreshData),
        },
        {
          title: '结束',
          disabled: !enable,
          auth: authDict.receivenote_finish,
          onClick: () => {
            if (!record.receiveNoteId) return;
            endOrder({
              list: [record],
              success: refreshData,
              type: 'single',
              isMaterial: true,
            });
          },
        },
        {
          title: '新建入库单',
          disabled: !enable,
          auth: authDict.inboundorder_add,
          onClick: () => createInbound([record]),
        },
        {
          title: '操作记录',
          auth: authDict.receivenote_operrecord,
          onClick: () => {
            setCurrentId(record.id);
            setVisibleLog(true);
          },
        },
      ]);
    },
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((columns: any): FilterItem => {
        const filter = {
          label: columns.filterTitle || columns.title,
          name: columns.dataIndex,
          ...columns,
        };

        return filter;
      }),
    formatDataToFormDisplay: (filterData: any) => {
      const { createdAt, updatedAt, planReceiveTime, ...rest } = filterData;

      const result = {
        ...rest,
        createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
        updatedAt: gcTime.formatRangeTimeToMoment(updatedAt || []),
        planReceiveTime: gcTime.formatRangeTimeToMoment(planReceiveTime || []),
      };

      return gcObject.filterEmptyProperty(result);
    },
    formatDataToQuery: (param: any) => {
      const {
        material,
        createdAt,
        source,
        execStatus,
        creator,
        operator,
        updatedAt,
        planReceiveTime,
        ...rest
      } = param;
      const [createdAtFrom, createdAtTo] = gcTime.formatTimeRangeUnix(createdAt);
      const [updatedAtFrom, updatedAtTo] = gcTime.formatTimeRangeUnix(updatedAt);
      const [planReceiveTimeFrom, planReceiveTimeTo] = gcTime.formatTimeRangeUnix(planReceiveTime);

      const options = {
        createdAtFrom,
        createdAtTo,
        planReceiveTimeFrom,
        planReceiveTimeTo,
        updatedAtFrom,
        updatedAtTo,
        materialIds: material?.length ? _.map(material, 'value') : null,
        creatorIds: creator?.length ? _.map(creator, 'value') : null,
        operatorIds: operator?.length ? _.map(operator, 'value') : null,
        sourceList: source,
        execStatusList: execStatus,
        ...rest,
      };

      dispatch.excelImport.updateBusinessData({
        businessData: gcObject.filterEmptyProperty(options),
      });

      return gcObject.filterEmptyProperty(options);
    },
  };

  return (
    <>
      <ReceiveModel
        isMaterial
        ids={ids}
        onSubmit={() => {
          refreshData();
          setVisible(false);
        }}
        onCancel={() => setVisible(false)}
        visible={visible}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={currentId as number}
          objectCode={OBJECT_OF_CODE.ReceiveNoteItem}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      <RecordListLayout<TableRowType>
        placeholder={'请输入编号、物料编号、协同发货单号'}
        useIndex={false}
        batchMenu={[
          {
            title: '下发',
            auth: authDict.receivenote_dispatch,
            onClick: (onSuccess: any, onFail: any) => {
              dispatchOrder(
                selectedRow.map((item) => Number(item.receiveNoteId)),
                refreshData,
                onSuccess,
                onFail,
              );
            },
          },
          {
            title: '收货',
            auth: authDict.receivenote_receipt,
            onClick: (onSucess: any, onFail: any) => {
              onFail();
              if (!enableFn(selectedRow)) {
                message.error('此处仅显示收货单状态为“执行中”的数据，其他状态无法执行收货操作。');
                return;
              }
              setIds(selectedRowKeys.map((item) => Number(item)));
              setVisible(true);
            },
          },
          {
            title: '关闭',
            auth: authDict.receivenote_dispatch,
            onClick: (onSuccess: any, onFail: any) => {
              closeOrder(
                selectedRow.map((item) => Number(item.receiveNoteId)),
                refreshData,
                onSuccess,
                onFail,
              );
            },
          },
          {
            title: '结束',
            auth: authDict.receivenote_finish,
            onClick: (onSuccess: any, onFail: any) => {
              onFail();
              endOrder({
                list: selectedRow,
                success: onSuccess,
                isMaterial: true,
              });
            },
          },
          {
            title: '新建入库单',
            auth: authDict.inboundorder_add,
            onClick: (onSuccess: any, onFail: any) => {
              createInbound(selectedRow);
              onFail();
            },
          },
        ]}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey, row) => {
          setSelectRowKeys(selectKey);
          if (row) {
            setSelectRow(row);
          }
        }}
        refreshMarker={refreshMarker}
        mainMenu={_.compact([
          {
            title: '导出',
            auth: authDict.receivenote_export,
            icon: <BlIcon type="icondaochu" />,
            showExport: true,
            onClick: () =>
              openModal({
                optType: 'export',
                businessType: BUSINESS_TYPE.receiveNote,
              }),
          },
          {
            title: '新建收货单',
            auth: authDict.receivenote_add,
            icon: <BlIcon type="icondaochu" />,
            items: [],
            showExport: true,
            onClick: () => {
              toCreate();
            },
          },
        ])}
        requestFn={fetchReceiveNoteListMaterial}
        configcacheKey="receiveOrderModule"
        {...config}
      />
    </>
  );
};

export default DeliveryOrder;
