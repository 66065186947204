import _ from 'lodash';

const backendEnumProps = ['code', 'message'];
/** 鸭子类型: 当一个对象有且仅有code和message这两个属性时, 被认为是枚举值 */
const isBackendEnum = (value: any) =>
  _.isPlainObject(value) && _.isEqual(_.keys(value), backendEnumProps);

/** 提取枚举值中的code */
export function pickCode<T extends object>(resp: T): PickCode<T> {
  function pickCodeRecursively(data: any): any {
    // 如果是数组，对每一项递归调用
    if (_.isArray(data)) {
      return data.map(pickCodeRecursively);
    }
    // 如果是对象, 对每个属性递归调用
    if (_.isPlainObject(data)) {
      // 提取code
      if (isBackendEnum(data)) {
        return (data as unknown as BackendEnum).code;
      }
      return _.mapValues(data as unknown as object, pickCodeRecursively);
    }
    return data;
  }
  return pickCodeRecursively(resp);
}
