import React, { useCallback, useState } from 'react';
import { Input, Button, Modal, Form, Row, Space, Checkbox, Radio } from 'antd';
import { DataFormLayout } from 'layout';
import { BlIcon } from '@blacklake-web/component';
import { BcAttachmentForm, SearchSelect } from 'components';
import ExecuteItemType from './executeItemType';
import CheckCount from './checkcountType';
import { toCheckItemCreate } from 'src/page/quality/checkItem/navigation';
import ResourceSelect from 'src/page/knowledgeManagement/components/resourceConfig/resourceSelect';
import { checkTwoSidesTrim } from 'src/utils/formValidators';
import { CheckItem } from 'src/page/quality/models/dto';
import {
  CheckEntityTypeEnum,
  CheckItemInputTypeEnum,
  CheckTypeEnum,
  CheckValueTypeEnum,
  RecordCheckItemTypeEnum,
} from 'src/dict/quality';
import _ from 'lodash';
import { appDict } from 'src/dict';
import { RESORCE_STATUS_VALUE } from 'src/page/resource/definitions/constants';
import authDict, { hasAuth } from 'src/utils/auth';
import { LOGIC } from 'src/page/quality/models/constants';
import { ICustomFields } from 'src/components/customField/interface';
import { getMainObjCustomFieldsToEdit } from 'src/components/customField';
import { validateBlInputNumberIntervalInt } from 'src/page/custom_fields/fieldsList/components/verificationRules/verificationRules';
import { customLayoutCreateButton } from 'src/components/customLayout/hooks/customLayoutForButton';
interface SelectModalProps {
  visible: boolean;
  isEdit: boolean;
  groupKey: number;
  checkItemList: CheckItem[];
  submitData: (groupKey: number, data: any[], isEdit: boolean) => void;
  closeModal: () => void;
  form: any;
  checkType?: number;
  checkEntityType?: number;
  customFields: ICustomFields;
  customCount?: number;
}

const AddModal = (props: SelectModalProps) => {
  const {
    submitData,
    closeModal,
    checkType,
    form,
    isEdit,
    visible,
    groupKey,
    checkItemList,
    customFields,
    checkEntityType,
    customCount,
  } = props;
  const isNumber = () => {
    const isNumberType =
      form.getFieldValue('executeItemType') === CheckItemInputTypeEnum.inputFormatPercent ||
      form.getFieldValue('executeItemType') === CheckItemInputTypeEnum.inputFormatNum;
    const inputSingleNumber = form.getFieldValue('checkValueType') !== CheckValueTypeEnum.between;

    return (
      (isNumberType && inputSingleNumber) ||
      form.getFieldValue('executeItemType') === CheckItemInputTypeEnum.inputFormatRecordDefectCount
    );
  };
  const [categoryId, setcategoryId] = useState<number>();
  const [isNumberInput, setIsNumberInput] = useState<boolean>(isNumber());
  const [isMustInput, setIsMustInput] = useState<number>(form.getFieldValue('recordCheckItemType'));
  const validateRepeat = (isEdit: boolean, checkItemList: CheckItem[]) => {
    return (_rule: any, checkItem: { key: number; value: number; label: string }) => {
      return new Promise((resolve, reject) => {
        if (checkItemList?.length && checkItem?.value) {
          const sameDataIndex = checkItemList.findIndex(
            (i) => i.checkItem?.value === checkItem?.value,
          );
          const index = checkItemList.findIndex((i) => i.key === form.getFieldValue('key'));

          if (sameDataIndex !== -1 && (!isEdit || (isEdit && sameDataIndex !== index))) {
            return reject('当前分组下存在相同检验项，不可重复');
          }
        }
        return resolve(true);
      });
    };
  };

  const validateValueMax = () => {
    return (_rule: any, value: number) => {
      return new Promise((resolve, reject) => {
        const maxValue =
          checkEntityType === CheckEntityTypeEnum.Custom
            ? customCount
            : form.getFieldValue('totalReportCount');
        const maxName = checkEntityType === CheckEntityTypeEnum.Custom ? '固定份数' : '总份数';

        if (!_.isNil(value) && !_.isNil(maxValue)) {
          if (Number(value) > Number(maxValue)) {
            return reject(`必须<=${maxName}`);
          }
        }
        return resolve(true);
      });
    };
  };

  const info = useCallback(() => {
    return {
      title: '',
      items: _.compact([
        {
          label: '检验项分类',
          name: 'checkItemCategory',
          rules: [{ required: true, message: '请选择检验项分类' }],
          render: () => (
            <SearchSelect
              onChange={(value: { label: string; value: number }) => {
                setcategoryId(value?.value);
                form.setFieldsValue({ checkItem: null });
              }}
              labelInValue
              fetchType={'checkItemCategory'}
            />
          ),
        },
        {
          label: '',
          isFullLine: true,
          render: () => (
            <Row style={{ display: 'flex' }}>
              <Form.Item
                name="checkItem"
                label="检验项名称/编号"
                validateTrigger={'onSelect'}
                rules={[
                  { required: true, message: '请输入检验项名称/编号' },
                  { validator: validateRepeat(isEdit, checkItemList) },
                ]}
                style={{ width: '72%', marginRight: 8, marginBottom: 0 }}
              >
                <SearchSelect
                  params={{ page: 1, categoryId }}
                  onSelect={(_value: any, option: any) => {
                    if (option) {
                      form.setFieldsValue({ checkItemCategory: option.category });
                    }
                  }}
                  fetchType={'checkItem'}
                />
              </Form.Item>
              {hasAuth(authDict.inspectionitem_add) &&
                customLayoutCreateButton({
                  title: '新建检验项',
                  icon: <BlIcon type="iconxinjiantianjia" />,
                  objectCode: 'InspectionItem',
                  type: 'create',
                  buttonType: 'dashed',
                  onClick: (layoutId) => window.open(toCheckItemCreate(layoutId)),
                })}
            </Row>
          ),
        },
        {
          label: '检验项格式',
          name: 'executeItemType',
          rules: [{ required: true, message: '请选择检验项格式' }],
          isFullLine: true,
          render: () => <ExecuteItemType form={form} />,
        },
        checkType !== CheckTypeEnum.generalQc && {
          label: '抽检类型',
          name: 'checkCountType',
          isFullLine: true,
          rules: [{ required: true, message: '请选择抽检类型' }],
          render: () => <CheckCount form={form} />,
        },
        checkEntityType === CheckEntityTypeEnum.useCheckItemConfig && {
          label: '总份数',
          name: 'totalReportCount',
          isFullLine: true,
          rules: [
            { required: true, message: '请填写总份数' },
            {
              validator: validateBlInputNumberIntervalInt(0, 100, false),
            },
          ],
          render: () => <Input form={form} style={{ width: 100 }} />,
        },
        {
          label: '是否必填',
          name: 'recordCheckItemType',
          rules: [{ required: true, message: '请选择检验项是否必填' }],
          isFullLine: true,
          render: () => (
            <Radio.Group
              options={appDict.quality.recordCheckItemType}
              onChange={(value) => setIsMustInput(value?.target?.value)}
            />
          ),
        },
        isMustInput === RecordCheckItemTypeEnum.yes && {
          label: '必填份数',
          name: 'requireReportCount',
          isFullLine: true,
          dependencies: ['totalReportCount'],
          rules: [
            {
              validator: validateBlInputNumberIntervalInt(0, 100, false),
            },
            {
              validator: validateValueMax(),
            },
          ],
          render: () => <Input form={form} style={{ width: 100 }} />,
        },
        {
          label: '不良原因',
          name: 'defectReasons',
          render: () => <SearchSelect allowClear mode="multiple" fetchType={'defectReason'} />,
        },
        {
          label: '不良等级',
          name: 'defectRanks',
          render: () => (
            <SearchSelect fetchType={'defectRange'} allowClear mode="multiple" labelInValue />
          ),
        },
        {
          label: '检验设备',
          name: 'equipments',
          render: () => (
            <ResourceSelect
              form={form}
              dataKey={'equipments'}
              isMultiple
              queryParam={{ status: RESORCE_STATUS_VALUE.ENABLED }}
              labelInValue
            />
          ),
        },
        {
          label: '备注',
          name: 'remark',
          rules: [{ validator: checkTwoSidesTrim }, { max: 1000, message: '不超过1000字符' }],
          render: () => <Input.TextArea />,
        },
        isNumberInput && {
          label: '附加结论',
          name: 'extraResults',
          render: () => <Checkbox.Group options={appDict.quality.ExtraResult} />,
        },
        {
          label: '文件',
          name: 'attachmentIds',
          render: () => <BcAttachmentForm form={form} />,
        },
        {
          render: () => (
            <Space style={{ marginLeft: 120 }}>
              <Form.Item name="executeRemark" valuePropName="checked">
                <Checkbox>执行时可填写备注</Checkbox>
              </Form.Item>
              <Form.Item name="executeUpload" valuePropName="checked">
                <Checkbox>执行时可上传图片</Checkbox>
              </Form.Item>
              <Form.Item name="key" hidden>
                <Input />
              </Form.Item>
            </Space>
          ),
        },
        ...getMainObjCustomFieldsToEdit({ customFields }, { form }),
      ]),
    };
  }, [categoryId, isNumberInput, isMustInput]);

  const onFinish = async () => {
    try {
      const data = await form.validateFields();

      if (!data.key) {
        data.key = `${checkItemList?.length}-${data.checkItem.value}`;
      }
      submitData(groupKey, data, isEdit);
    } catch (err) {
      console.log(err);
    }
  };

  const onValuesChange = (changedValues: any) => {
    if (
      !_.isNil(changedValues.executeItemType) ||
      !_.isNil(changedValues.checkValueType) ||
      !_.isNil(changedValues.logic)
    ) {
      if (
        ((changedValues.executeItemType === CheckItemInputTypeEnum.inputFormatPercent ||
          changedValues.executeItemType === CheckItemInputTypeEnum.inputFormatNum) &&
          form.getFieldValue('checkValueType') !== CheckValueTypeEnum.between) ||
        changedValues.executeItemType === CheckItemInputTypeEnum.inputFormatRecordDefectCount ||
        changedValues.checkValueType === CheckValueTypeEnum.singleNumber
      ) {
        setIsNumberInput(true);
      } else if (
        !_.isNil(changedValues.logic) &&
        changedValues.logic !== LOGIC.BETWEEN &&
        changedValues.logic !== LOGIC.TOLERANCE
      ) {
        setIsNumberInput(true);
      } else {
        setIsNumberInput(false);
      }
    }
  };

  return (
    <Modal
      visible={visible}
      width={1000}
      title={isEdit ? '编辑检验项' : '添加检验项'}
      onCancel={() => closeModal()}
      footer={null}
      bodyStyle={{ padding: 24, display: 'felx' }}
    >
      <DataFormLayout
        form={form}
        info={[info()]}
        onFinish={onFinish}
        onCancel={() => closeModal()}
        formProps={{ onValuesChange }}
      />
    </Modal>
  );
};

export default AddModal;
