import React, { useState } from 'react';
import { Form, message as Message } from 'antd';
import QrCodeBaseInfo from './components/qrCodebaseInfo';
import { formatRequestValue } from './utils';
import { PRODUCTION_WAY_TYPE } from './constant';
import { gcArray } from 'src/utils';
import CreateCodeSuccessContent from './createSuccessContent';
import PrintModal from './printModal';
import { SuccessListType } from './interface';
import { fetchCodeEntityLinkCreate } from 'src/api/ytt/code-domain/object';

function CreateQrCode() {
  const [createForm] = Form.useForm();
  const [successVisible, setSuccessVisible] = useState(false); // 显示创建成功数据的模态框
  const [successList, setSuccessList] = useState<SuccessListType[]>([]); // 创建成功的数据

  const [printVisible, setPrintVisible] = useState(false); // 显示打印模态框
  const [data, setData] = useState({}); // 创建成功返回的所有数据

  const handleFinish = async () => {
    try {
      const value = await createForm?.validateFields();

      const { ruleType, factoryCodeList } = value;

      if (ruleType === PRODUCTION_WAY_TYPE?.CUSTOMER && gcArray.isEmpty(factoryCodeList)) {
        return Message.error('自定义标识码必填');
      }
      const newValue = formatRequestValue(value);

      const { message, data } = await fetchCodeEntityLinkCreate(newValue);

      if (data?.noRepeatFactoryCodeList) {
        Message.success('创建成功');

        setData(data);
        const formatData = data?.noRepeatFactoryCodeList?.map((node: string) => {
          return { qrCode: node };
        });

        setSuccessList(formatData);
        setSuccessVisible(true);
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div style={{ height: '100%', backgroundColor: '#fff' }}>
        <QrCodeBaseInfo onFinish={handleFinish} form={createForm} />
      </div>
      {successVisible && (
        <CreateCodeSuccessContent
          visible={successVisible}
          onCancel={() => {
            setSuccessVisible(false);
          }}
          data={successList}
          onOk={() => {
            // 弹出打印的弹窗
            setSuccessVisible(false);
            setPrintVisible(true);
          }}
          dataSource={data}
        />
      )}
      {printVisible && (
        <PrintModal
          visible={printVisible}
          onCancel={() => {
            setPrintVisible(false);
          }}
          data={successList}
        />
      )}
    </>
  );
}

export default CreateQrCode;
