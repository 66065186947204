import React from 'react';
import { Tabs } from 'antd';
import { BlTable } from '@blacklake-web/component';
import {
  QcCustomer,
  QcMaterial,
  QcProcess,
  QcResource,
  QcSupplier,
} from 'src/page/quality/models/dto';
import { CheckTypeEnum } from 'src/dict/quality';
import { UsageStatus } from 'src/dict/common';
import { TagList } from 'src/components';
import _ from 'lodash';
import { MaterialEntity } from 'src/entity';

interface Props {
  qcConfigCustomerList: QcCustomer[];
  qcConfigMaterialList: QcMaterial[];
  qcConfigProcessList: QcProcess[];
  qcConfigResourceList: QcResource[];
  qcConfigSupplierList: QcSupplier[];
  checkType?: number;
}
export const RelationshipRender = (props: Props) => {
  const { TabPane } = Tabs;
  const {
    qcConfigCustomerList,
    qcConfigMaterialList,
    qcConfigProcessList,
    qcConfigResourceList,
    qcConfigSupplierList,
    checkType,
  } = props;
  const getMaterialAttrs = (attributes?: any[]): any[] => {
    const options: any[] = [];

    if (!_.isEmpty(attributes)) {
      _.forEach(attributes, (item) => {
        if (item?.id) {
          options.push({
            label: `${item.name}:${item.attributeItem?.content}`,
            value: item?.id,
          });
        }
      });
    }

    return options;
  };

  return (
    <div style={{ marginLeft: 12 }}>
      <Tabs defaultActiveKey="1">
        {(checkType === CheckTypeEnum.produceOriginQc ||
          checkType === CheckTypeEnum.produceQc ||
          checkType === CheckTypeEnum.inspectionQc) && (
          <TabPane tab="关联工序" key="process">
            <BlTable
              columns={[
                { title: '工序编号', dataIndex: 'code' },
                { title: '工序名称', dataIndex: 'name' },
                { title: '工序次序', dataIndex: 'seq', render: (seq: number) => seq ?? '-' },
              ]}
              dataSource={qcConfigProcessList}
              rowKey="id"
            />
          </TabPane>
        )}
        <TabPane tab="关联物料" key="material">
          <BlTable
            columns={[
              {
                title: '物料编号',
                dataIndex: 'code',
                render: (code) => code || '-',
              },
              {
                title: '物料名称',
                dataIndex: 'name',
                render: (name) => name || '-',
              },
              { title: '物料分类', dataIndex: ['category', 'name'] },
              {
                title: '物料属性',
                dataIndex: 'attributes',
                render: (attributes) => <TagList dataSource={getMaterialAttrs(attributes)} />,
              },
              { title: '基本单位', dataIndex: 'unit', render: (unit) => unit?.name },
              {
                title: '检验单位',
                dataIndex: 'qcUnit',
                render: (qcUnit) =>
                  `${qcUnit?.name}${qcUnit.status === UsageStatus.disabled ? '(停用)' : ''}`,
              },
            ]}
            dataSource={qcConfigMaterialList}
            rowKey="id"
          />
        </TabPane>
        {(checkType === CheckTypeEnum.produceOriginQc ||
          checkType === CheckTypeEnum.produceQc ||
          checkType === CheckTypeEnum.inspectionQc) && (
          <TabPane tab="关联设备" key="resource">
            <BlTable
              columns={[
                { title: '设备编号', dataIndex: 'code' },
                { title: '设备名称', dataIndex: 'name' },
              ]}
              dataSource={qcConfigResourceList}
              rowKey="id"
            />
          </TabPane>
        )}
        {checkType === CheckTypeEnum.outputFactoryQc && (
          <TabPane tab="关联客户" key="customer">
            <BlTable
              columns={[
                { title: '客户编号', dataIndex: 'code' },
                { title: '客户名称', dataIndex: 'name' },
              ]}
              dataSource={qcConfigCustomerList}
              rowKey="id"
            />
          </TabPane>
        )}
        {checkType === CheckTypeEnum.inputFactoryQc && (
          <TabPane tab="关联供应商" key="superior">
            <BlTable
              columns={[
                { title: '供应商编号', dataIndex: 'code' },
                { title: '供应商名称', dataIndex: 'name' },
              ]}
              dataSource={qcConfigSupplierList}
              rowKey="id"
            />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};
