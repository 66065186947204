/**
 * 投料、报工 控件属性
 */
import { FC, useContext } from 'react';
import _ from 'lodash';
import { Form, Input } from 'antd';
import type { FormInstance } from 'antd';
import type { StepDetailData, LocalControlItem } from '../../types';
import {
  ControlEditorContext,
  CONTROL_TITLE_LENGTH,
  INPUT_DEFAULT_PLACEHOLDER,
} from '../../constants';
import { getNameFactory, titleRules } from './share';

interface Props {
  form: FormInstance<StepDetailData>;
  localKey: string;
}

const FeedOrReportInputProperties: FC<Props> = ({ form, localKey }) => {
  const { forceUpdate } = useContext(ControlEditorContext);
  const controlList = form.getFieldValue('controls');

  if (_.isEmpty(controlList)) {
    return null;
  }
  const dataIndex = controlList.findIndex((item: LocalControlItem) => item._localKey === localKey);

  if (dataIndex === -1) {
    return null;
  }
  const getName = getNameFactory(dataIndex);

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={() => {
        forceUpdate();
      }}
    >
      <Form.Item label="标题" name={getName('name')} rules={titleRules}>
        <Input max={CONTROL_TITLE_LENGTH} placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>
    </Form>
  );
};

export default FeedOrReportInputProperties;
