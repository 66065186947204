import { useCallback, useEffect, useState } from 'react';
import { Select, Form, Radio, Avatar, message } from 'antd';
import _ from 'lodash';

import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import {
  fetchTriggerRuleEnable,
  fetchTriggerRuleDisable,
  fetchTriggerRuleDetail,
} from 'src/api/ytt/trigger-domain/triggerRule';
import { avatarStyle } from 'src/page/organization/userManagement/constants';
import { BcMultilineText, BlIcon } from 'src/components';

import { gcTime } from 'src/utils';
import { DetailLayoutMenuItem } from 'src/layout/detail/DetailLayout.type';
import lookup, { appDict, appEnum } from 'src/dict';
import { CRUD } from 'src/dict/common';
import { replaceSign } from 'src/utils/constants';
import renderUsageStatus from 'src/page/share/renderUsageStatus';
import authDict from 'src/utils/auth';

import RuleConditionEditTable from './components/RuleConditionEditTable/detail';
import { CustomizeItem, EffectiveType, TimeIntervalTrigger } from './components/DetailDisplayCom';

import { formatToDetaiData } from './utils/formatToDetailData';
import { formatToFormData } from './utils/formatToFormData';
import { getEditPageUrl, getCreatePageUrl } from './utils/navigation';
import { isGeneralRule, isBusinessRule } from './constant';

const { triggerRule } = appDict;
const { TriggerRule } = appEnum;

const Detail = (props: any) => {
  const [statusType, setStatusType] = useState<boolean>();
  const {
    history,
    match: {
      params: { id, ruleSource },
    },
  } = props;

  const [dataSource, setDataSource] = useState({} as any);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      fetchTriggerRuleDetail({ ruleId: Number(id) })
        .then((res) => {
          if (res.code === 200) {
            setDataSource(res.data ?? {});
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [statusType]);

  const getAbleMenuItem = (status: boolean) => {
    return status
      ? {
          key: 'enable',
          title: lookup('common.crud', CRUD.enable),
          auth: authDict.triggerrules_enable_disable,
          onClick: () => {
            fetchTriggerRuleEnable({ ruleIds: [id] }).then((res) => {
              if (res.code === 200) {
                const { failCount, failReasons } = res.data;

                if (failCount > 0) {
                  message.error('启用失败');
                  message.error(failReasons[0]);
                } else {
                  message.success('启用成功');
                  setStatusType(true);
                }
              }
            });
          },
        }
      : {
          key: 'disable',
          title: lookup('common.crud', CRUD.disable),
          auth: authDict.triggerrules_enable_disable,
          onClick: () => {
            fetchTriggerRuleDisable({ ruleIds: [id] }).then((res) => {
              if (res.code === 200) {
                const { failCount, failReasons } = res.data;

                if (failCount > 0) {
                  message.error('停用失败');
                  message.error(failReasons[0]);
                } else {
                  message.success('停用成功');
                  setStatusType(false);
                }
              }
            });
          },
        };
  };

  const baseMenu: DetailLayoutMenuItem[] = isGeneralRule(
    ruleSource,
    _.compact([
      getAbleMenuItem(dataSource?.status === appEnum.Common.UsageStatus.disabled),
      {
        key: 'copy',
        title: lookup('common.crud', CRUD.copy),
        auth: authDict.triggerrules_add,
        onClick: () => history.push(getCreatePageUrl(id)),
      },
      {
        key: 'edit',
        title: lookup('common.crud', CRUD.edit),
        disabled: dataSource?.status === appEnum.Common.UsageStatus.enabled,
        auth: authDict.triggerrules_edit,
        onClick: () => history.push(getEditPageUrl(id)),
      },
    ]),
  );
  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    column: 2,
    items: [
      { label: '规则编号', dataIndex: 'code' },
      { label: '规则名称', dataIndex: 'name' },
      { label: '启用状态', dataIndex: 'status', render: renderUsageStatus },
      { label: '上游规则', dataIndex: 'parentRuleName', render: (text) => text ?? replaceSign },
      {
        label: '规则来源',
        dataIndex: 'sourceType',
        render: (sourceType: number) =>
          lookup('triggerRule.ruleSourceType', sourceType) ?? replaceSign,
      },
      ...isBusinessRule(ruleSource, [
        { label: '来源对象', dataIndex: 'bizSourceObjectName' },
        { label: '来源对象实例', dataIndex: 'bizSourceInstanceCode' },
      ]),
      {
        label: '备注',
        dataIndex: 'remark',
        render: (text: string) => <BcMultilineText text={text} needWordCount />,
        isFullLine: true,
      },
    ],
  };

  const bizEventTrigger = 'bizEventTrigger';
  const bizEventTriggerItems: DetailLayoutInfoBlock['items'] = [
    {
      label: '触发动作',
      dataIndex: [bizEventTrigger, 'triggerEventType'],
      isFullLine: true,
      render: (triggerEventType) => (
        <Select disabled labelInValue value={triggerEventType} mode="multiple" />
      ),
    },
    {
      label: '生效时机',
      dataIndex: bizEventTrigger,
      isFullLine: true,
      render: (bizEventTrigger = {}) => <EffectiveType {...bizEventTrigger} />,
    },
    {
      label: '执行频率',
      dataIndex: bizEventTrigger,
      isFullLine: true,
      render: (bizEventTrigger) => (
        <>
          <Form.Item>
            <Radio.Group
              disabled
              options={triggerRule.bizEventExecType}
              value={bizEventTrigger?.execType}
            />
          </Form.Item>
          {bizEventTrigger?.execType === TriggerRule.BizEventExecType.customize ? (
            <CustomizeItem {...bizEventTrigger} />
          ) : null}
        </>
      ),
    },
  ];

  const TimeIntervalTriggerItems: DetailLayoutInfoBlock['items'] = [
    {
      label: '执行频率',
      dataIndex: 'timeIntervalTrigger',
      isFullLine: true,
      render: (timeIntervalTrigger = {}) => <TimeIntervalTrigger {...timeIntervalTrigger} />,
    },
  ];

  const ruleInfo: DetailLayoutInfoBlock = {
    title: '业务对象',
    column: 1,
    items: [
      { label: '业务对象', dataIndex: 'objectName', isFullLine: true },
      // { label: '业务类型', dataIndex: 'objectBizType', isFullLine: true },
      {
        label: '触发方式',
        dataIndex: 'triggerType',
        isFullLine: true,
        render: (type) => <Select disabled options={triggerRule.triggerType} value={type} />,
      },
      ...(dataSource.triggerType === TriggerRule.TriggerType.bizEventTrigger
        ? bizEventTriggerItems
        : TimeIntervalTriggerItems),
    ],
  };

  const triggerConditioConfigInfo: DetailLayoutInfoBlock = {
    title: '触发条件',
    column: 1,
    items: [
      {
        label: '配置方式',
        dataIndex: 'triggerConditionType',
        isFullLine: true,
        render: (type) => (
          <Radio.Group disabled options={triggerRule.triggerConditionType} value={type} />
        ),
      },
      {
        label: '',
        dataIndex: 'triggerCondition',
        isFullLine: true,
        render: () => {
          return <RuleConditionEditTable disabled data={formatToFormData(dataSource)} />;
        },
      },
    ],
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    column: 2,
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: any) => {
          return !creator ? (
            replaceSign
          ) : (
            <span>
              {creator?.avatarUrl ? (
                <Avatar size={24} src={creator?.avatarUrl} />
              ) : (
                <Avatar icon={<BlIcon type="icongeren" />} size={24} style={avatarStyle} />
              )}
              {creator?.name}
            </span>
          );
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: (time: number) => gcTime.formatLine(time),
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: any) => {
          return !operator ? (
            replaceSign
          ) : (
            <span>
              {operator?.avatarUrl ? (
                <Avatar size={24} src={operator?.avatarUrl} />
              ) : (
                <Avatar icon={<BlIcon type="icongeren" />} size={24} style={avatarStyle} />
              )}
              {operator?.name}
            </span>
          );
        },
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: (time: number) => gcTime.formatLine(time),
      },
    ],
  };

  const getInfo = useCallback(
    () => [baseInfo, ruleInfo, triggerConditioConfigInfo, otherInfo],
    [dataSource],
  );

  return (
    <>
      <DetailLayout
        baseMenu={baseMenu}
        title={'触发规则详情'}
        info={getInfo()}
        dataSource={formatToDetaiData(dataSource)}
        loading={loading}
      />
    </>
  );
};

export default Detail;
