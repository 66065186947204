import { mappingsFactory } from '../utils';
import {
  EquipmentLevel,
  UseAgeUnit,
  ResourceBusinessType,
  ParmTypeEnum,
  EnergyMeterType,
  EnergyMeterStatus,
  EquipmentBusinessType,
  ResourceMonitorViewType,
  MaintenanceType,
  MaintenanceChannel,
  MaintenanceExecuteType,
  MaintenanceTaskSource,
  MaintenanceTaskStatus,
  MalfunctionType,
  MalfunctionLevel,
  MalfunctionRecordOrign,
  MalfunctionRecordOrignObj,
  AutoFrequencyUnit,
  RepairChannel,
  RepairExecuteType,
  RepairTaskSource,
  RepairTaskStatus,
} from '.';

export const equipmentLevel = mappingsFactory(
  ['一级', EquipmentLevel.one],
  ['二级', EquipmentLevel.two],
  ['三级', EquipmentLevel.three],
  ['四级', EquipmentLevel.four],
  ['五级', EquipmentLevel.five],
);

export const useAgeUnit = mappingsFactory(
  ['年', UseAgeUnit.year],
  ['月', UseAgeUnit.month],
  ['天', UseAgeUnit.day],
);

export const useAgeUnitCalc = mappingsFactory(
  ['years', UseAgeUnit.year],
  ['months', UseAgeUnit.month],
  ['days', UseAgeUnit.day],
);

// 自动获取api频率单位
export const autoFrequencyUnit = mappingsFactory(
  ['分钟', AutoFrequencyUnit.minute],
  ['小时', AutoFrequencyUnit.hour],
);

export const resourceBusinessType = mappingsFactory(
  ['设备', ResourceBusinessType.equipment],
  ['能源仪表', ResourceBusinessType.energy],
  ['参数', ResourceBusinessType.parameter],
  ['维保', ResourceBusinessType.maintenance],
);

export const resourceTagBusinessType = mappingsFactory(
  ['设备', ResourceBusinessType.equipment],
  ['能源仪表', ResourceBusinessType.energy],
  ['故障', ResourceBusinessType.malfunction],
);

export const RESORCE_BUSINESS_TYPE_MAP = mappingsFactory(
  ['设备', ResourceBusinessType.equipment],
  ['能源仪表', ResourceBusinessType.energy],
);

export const paramType = mappingsFactory(
  ['实时数值', ParmTypeEnum.actualTime],
  ['累计数值', ParmTypeEnum.accumulate],
  ['文本型', ParmTypeEnum.text],
);

export const energyMeterType = mappingsFactory(
  ['电表', EnergyMeterType.electric],
  ['水表', EnergyMeterType.water],
  ['气表', EnergyMeterType.gas],
  ['其他', EnergyMeterType.other],
);

export const energyMeterStatus = mappingsFactory(
  ['启用中', EnergyMeterStatus.enabled],
  ['停用中', EnergyMeterStatus.disabeld],
  ['已报废', EnergyMeterStatus.scrap],
);

export const equipmentBusinessType = mappingsFactory(
  ['生产设备', EquipmentBusinessType.product],
  ['称量设备', EquipmentBusinessType.weighting],
  ['其他设备', EquipmentBusinessType.other],
);

export const dataCount = mappingsFactory(
  ['100条', 100],
  ['500条', 500],
  ['1000条', 1000],
  ['5000条', 5000],
  ['10000条', 10000],
  // ['20000条', 20000],
  // ['50000条', 50000],
);

export const resourceMonitorViewType = mappingsFactory(
  ['资源', ResourceMonitorViewType.resource],
  ['工艺', ResourceMonitorViewType.task],
);

export const rpStatus = mappingsFactory(
  ['为空', 0],
  ['正常', 1],
  ['高于上限', 2],
  ['高于上上限', 3],
  ['低于下限', 4],
  ['低于下下限', 5],
);

export const ppStatus = mappingsFactory(
  ['为空', 0],
  ['正常', 1],
  ['高于上限', 2],
  ['高于上上限', 3],
  ['低于下限', 4],
  ['低于下下限', 5],
);

export const maintenanceType = mappingsFactory(
  ['保养', MaintenanceType.maintain],
  ['点检', MaintenanceType.spotCheck],
  ['巡检', MaintenanceType.patrolCheck],
  ['润滑', MaintenanceType.lubricate],
  ['校准', MaintenanceType.calibrate],
  ['清洗', MaintenanceType.clean],
);

export const maintenanceChannel = mappingsFactory(
  ['自主', MaintenanceChannel.autonomy],
  ['委外', MaintenanceChannel.entrust],
);

export const maintenanceExecuteType = mappingsFactory(
  ['报告模板', MaintenanceExecuteType.reportTemplate],
  ['SOP方案', MaintenanceExecuteType.SOP],
);

export const maintenanceTaskSource = mappingsFactory(
  ['手动创建', MaintenanceTaskSource.reportTemplate],
  ['触发事件', MaintenanceTaskSource.event],
);

export const maintenanceTaskStatus = mappingsFactory(
  ['未开始', MaintenanceTaskStatus.notStarted],
  ['执行中', MaintenanceTaskStatus.executing],
  ['暂停中', MaintenanceTaskStatus.suspended],
  ['已结束', MaintenanceTaskStatus.ended],
  ['已取消', MaintenanceTaskStatus.canceled],
  ['待执行', MaintenanceTaskStatus.deferred],
);

export const repairChannel = mappingsFactory(
  ['自主', RepairChannel.autonomy],
  ['委外', RepairChannel.entrust],
);

export const repairExecuteType = mappingsFactory(
  ['报告模板', RepairExecuteType.reportTemplate],
  ['SOP方案', RepairExecuteType.SOP],
);

export const repairTaskSource = mappingsFactory(
  ['维保任务', RepairTaskSource.maintenanceTask],
  ['维修任务', RepairTaskSource.repairTask],
  ['生产任务', RepairTaskSource.produceTask],
  ['手动创建', RepairTaskSource.manual],
);

export const repairTaskStatus = mappingsFactory(
  ['未开始', RepairTaskStatus.notStarted],
  ['执行中', RepairTaskStatus.executing],
  ['暂停中', RepairTaskStatus.suspended],
  ['已结束', RepairTaskStatus.ended],
  ['已取消', RepairTaskStatus.canceled],
  ['待执行', RepairTaskStatus.toExcute],
);

export const malfunctionTypes = mappingsFactory(
  ['功能故障', MalfunctionType.function],
  ['参数故障', MalfunctionType.params],
  ['其他故障', MalfunctionType.other],
);

export const malfunctionLevel = mappingsFactory(
  ['微小故障', MalfunctionLevel.level1],
  ['一般故障', MalfunctionLevel.level2],
  ['较大故障', MalfunctionLevel.level3],
  ['重大故障', MalfunctionLevel.level4],
  ['一般事故', MalfunctionLevel.level5],
  ['较大事故', MalfunctionLevel.level6],
  ['重大事故', MalfunctionLevel.level7],
  ['特大事故', MalfunctionLevel.level8],
);

export const malfunctionRecordOrign = mappingsFactory(
  ['手动创建', MalfunctionRecordOrign.manual],
  ['触发事件', MalfunctionRecordOrign.event],
);

export const malfunctionRecordOrignObj = mappingsFactory(
  ['参数记录', MalfunctionRecordOrignObj.record],
  ['参数监控', MalfunctionRecordOrignObj.monitor],
);
