import React, { useState } from 'react';
import { BlTable, BlColumnType } from '@blacklake-web/component';
import { getStandardValue, LOGIC } from 'src/page/quality/models/constants';
import { CheckItem, ResultValue, RecordDetail, CheckMaterial } from 'src/page/quality/models/dto';
import _ from 'lodash';
import {
  CheckCountTypeEnum,
  CheckItemInputTypeEnum,
  CheckValueTypeEnum,
  ExtraResultenum,
} from 'src/dict/quality';
import lookup from 'src/dict';

export const RecordDetailTable = (props: {
  data: RecordDetail[];
  chekItemList: { groupName: string; checkItemAppDetailVOS: CheckItem[] }[];
  reportCount: number;
  sampleMaterials: CheckMaterial[] | null;
  isSameAsBatchCount: boolean;
  setDetailVisible: (value: boolean) => void;
  setRecordDetailData: (value: any) => void;
}) => {
  const {
    data,
    chekItemList,
    reportCount,
    sampleMaterials,
    isSameAsBatchCount,
    setDetailVisible,
    setRecordDetailData,
  } = props;

  const isQuality = (value: any, standarValue: any, executeItemType: CheckItemInputTypeEnum) => {
    const { result, valueMin, valueMax } = value || {};
    const { logic, base, min, max, checkValueType } = standarValue;
    const isRecordDefect = executeItemType === CheckItemInputTypeEnum.inputFormatRecordDefectCount;

    if (result || valueMin || valueMax) {
      if (logic === LOGIC.BETWEEN && checkValueType === CheckValueTypeEnum.between) {
        return (
          <span
            style={{
              color:
                (valueMin && Number(valueMin) < min) || (valueMax && Number(valueMax) > max)
                  ? 'red'
                  : '',
            }}
          >
            <span>
              {valueMin || '-'}~{valueMax || '-'}
            </span>
          </span>
        );
      }
      if (logic === LOGIC.BETWEEN && checkValueType === CheckValueTypeEnum.singleNumber) {
        return (
          <span style={{ color: Number(result) < min || Number(result) > max ? 'red' : '' }}>
            <span>{result}</span>
          </span>
        );
      }
      if (logic === LOGIC.EQ) {
        const isQuality = Number(result) !== base;

        return <span style={{ color: isQuality ? 'red' : '' }}>{result}</span>;
      }
      if (logic === LOGIC.GT) {
        const isQuality = Number(result) <= base;

        return <span style={{ color: isQuality ? 'red' : '' }}>{result}</span>;
      }
      if (logic === LOGIC.GTE) {
        const isQuality = Number(result) < base;

        return <span style={{ color: isQuality ? 'red' : '' }}>{result}</span>;
      }
      if (logic === LOGIC.LT) {
        const isQuality = Number(result) >= base;

        return <span style={{ color: isQuality ? 'red' : '' }}>{result}</span>;
      }
      if (logic === LOGIC.LTE) {
        const isQuality = Number(result) > base;

        return <span style={{ color: isQuality ? 'red' : '' }}>{result}</span>;
      }
      if (logic === LOGIC.TOLERANCE) {
        if (checkValueType === CheckValueTypeEnum.between) {
          const isQuality =
            (valueMin && Number(valueMin) < base + min && Number(valueMin) !== base) ||
            (valueMax && valueMax > base + max && Number(valueMax));

          return (
            <span style={{ color: isQuality ? 'red' : '' }}>
              {`${valueMin ?? '-'} ~${valueMax ?? '-'}`}
            </span>
          );
        }
        const isQuality =
          (Number(result) < base + min || result > base + max) && Number(result) !== base;

        return <span style={{ color: isQuality ? 'red' : '' }}>{result ?? '-'}</span>;
      }

      return (
        <span>
          {isRecordDefect ? '次品: ' : ''}
          <span style={{ color: isRecordDefect && Number(result) > 0 ? 'red' : '' }}>{result}</span>
        </span>
      );
    }
  };

  const getCheckItemDisplay = (checkItem: RecordDetail) => {
    const { taskCheckCount, checkCountType, checkItemName, aqlDetail } = checkItem;

    const isAql =
      checkCountType === CheckCountTypeEnum.customAqlCheck ||
      checkCountType === CheckCountTypeEnum.aqlCheck;

    return (
      <>
        {checkItemName}
        {taskCheckCount ? <p>抽样量： {taskCheckCount}</p> : ''}
        {isAql && (
          <p>
            {' '}
            {checkCountType === CheckCountTypeEnum.customAqlCheck ? '自定义AQL方案' : '检验水平'}：
            {aqlDetail?.qcAqlInspectionLevelName}
          </p>
        )}
        {isAql && <p>接收质量限： {aqlDetail?.acceptLimitName}</p>}
      </>
    );
  };

  const getResultValue = (resultValue: ResultValue, record: RecordDetail) => {
    const { option } = resultValue || {};

    if (option) {
      return (
        <p>
          {option.map((option) => (
            <p style={{ color: record.qualityItems?.includes(option) ? '' : 'red' }}>
              <span>{option}</span>
            </p>
          ))}
        </p>
      );
    }

    return isQuality(resultValue, record, record?.executeItemType);
  };

  const getResultDisplay = (resultValue: ResultValue, record: RecordDetail) => {
    const result = getResultValue(resultValue, record);

    return (
      <a
        onClick={() => {
          setDetailVisible(true);
          setRecordDetailData({ ...record, ...resultValue, standValue: result });
        }}
      >
        {result}
      </a>
    );
  };

  const defaultColumns = [
    { dataIndex: 'groupName', title: '分组', fixed: 'left', width: 100 },
    {
      dataIndex: 'checkItem',
      title: '检验项',
      fixed: 'left',
      width: 200,
      render: (_value: string, record: RecordDetail) => {
        return getCheckItemDisplay(record);
      },
    },
    {
      dataIndex: 'remark',
      title: '备注',
      width: 160,
    },
    {
      dataIndex: 'equipments',
      title: '检验设备',
      width: 160,
      render: (equipments) => equipments?.map((i: { name: string }) => i?.name).join(','),
    },
    {
      dataIndex: 'logic',
      title: '标准值',
      width: 160,
      render: (value: LOGIC, record: RecordDetail) => getStandardValue(value, record),
    },
  ] as BlColumnType<RecordDetail>[];

  const getColumns = () => {
    const countColumns = [];

    for (let i = 0; i <= reportCount - 1; i++) {
      let title = `第${i + 1}份`;

      if (isSameAsBatchCount && sampleMaterials?.[i]?.qrCode) {
        title = sampleMaterials?.[i]?.qrCode || title;
      }
      countColumns.push({
        title,
        dataIndex: `result${i}`,
        width: 150,
        render: getResultDisplay,
      });
    }

    const configExtraResults = _.uniq(_.flatten(_.map(formatCheckItemData(), 'extraResults')));
    const extralResutColumns = configExtraResults?.map((value: ExtraResultenum) => ({
      dataIndex: value === ExtraResultenum.averageSample ? 'average' : 'standardDeviation',
      title: lookup('quality.ExtraResult', value),
      width: 180,
      render: (value: number) => value ?? '-',
    }));

    return defaultColumns.concat(countColumns).concat(extralResutColumns);
  };

  const formatCheckItemData = () => {
    const valueList = _.flatten(
      data.map((value) => {
        const { qcTaskCheckItems, groupName } = value;

        return qcTaskCheckItems?.map((checkIem) => {
          const { min, max, remark, attachmentIds, ...rest } = checkIem;

          return {
            ...rest,
            valueMin: min,
            valueMax: max,
            groupName,
            inputRemark: remark,
            inputAttachmentId: attachmentIds,
          };
        });
      }),
    );

    const checkItemData = _.flatten(
      chekItemList.map((item) => {
        return item.checkItemAppDetailVOS.map((checkItem: CheckItem) => {
          const resultData = {} as any;
          const resultList = _.filter(
            valueList,
            (i) => i?.groupName === item.groupName && i.qcConfigCheckItemId === checkItem.id,
          );

          resultList.forEach((result, index) => {
            resultData[`result${index}`] = result;
          });

          return {
            ...checkItem,
            ...resultData,
            groupName: item.groupName,
            average: resultList[0]?.average,
            standardDeviation: resultList[0]?.standardDeviation,
          };
        });
      }),
    );

    return checkItemData;
  };

  return (
    <>
      <BlTable<RecordDetail>
        columns={getColumns()}
        scroll={{ x: 'max-content' }}
        dataSource={formatCheckItemData()}
      />
    </>
  );
};
