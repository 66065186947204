import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Tabs, Button, Table, Form, Input, Checkbox, Divider, Card } from 'antd';
import { BlTable } from 'src/components';
import '@antv/x6-react-shape';
import '../../components/relationChart/basicLayout/customEdge';
import '../../components/relationChart/basicLayout/customNode';
import {
  fetchDataSetCreateDataSet,
  fetchDataSetQueryDataSetPreviewField,
  fetchDataSetDataSetPreviewProcess,
  fetchDataSetModifyDataSet,
} from 'src/api/ytt/e-report-domain/dataSet';
import RelationChart from './editableChart';
import _ from 'lodash';
import { textValidator1 } from 'src/utils/formValidators';
import lookup from 'src/dict';
import { DataSet } from '..';

type PreviewField = {
  checked?: number;
  fieldId?: number;
  fieldDisplayName?: string;
  fieldInfo?: string;
  fieldName?: string;
  fieldType?: number;
  objectCode?: string;
  objectName?: string;
  objectNodeId?: number;
};
const DataEdit = ({
  isEdit,
  form,
  dataSetData,
  setDataSet,
  isCopy,
  refreshDataSet,
  setPreivewFields,
  isChangeField,
  setIsChangeField,
}: {
  isEdit: boolean;
  dataSetData: DataSet;
  form: any;
  setDataSet: (data: any) => void;
  isCopy: boolean;
  refreshDataSet: (id: number) => Promise<any>;
  setPreivewFields: (data: any[]) => void;
  isChangeField: boolean;
  setIsChangeField: (value: boolean) => void;
}) => {
  const { previewFieldInfos, objectNodeInfoList, objectRelationShipInfoList, previewData, id } =
    dataSetData || {};

  const [selectedRows, setSelectedRows] = useState<PreviewField[]>([]);
  const [previewField, setPreviewField] = useState<PreviewField[]>([]);
  const [activeKey, setAvtiveKey] = useState<string>('objectPreview');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [previewColums, setPreviewColumns] = useState<any[]>([]);
  const [previewTableData, setPreviewTableData] = useState<any>(previewData);

  const getFieldColumns = (_fieldList: any[]) =>
    _fieldList.map((field, index) => {
      const { fieldDisplayName, fieldId } = field;
      let fixed: string | boolean = false;

      if (index === 0) {
        fixed = 'left';
      }

      return {
        title:
          form.getFieldValue(['fieldList', `${fieldId}`, 'fieldDisplayName']) || fieldDisplayName,
        dataIndex: index,
        width: 100,
        fixed,
      };
    });

  const fieldColums = [
    {
      title: '字段类型',
      dataIndex: 'fieldType',
      width: 120,
      sorter: (a: any, b: any, direction: string) => {
        if (direction === 'ascend') {
          return b.fieldType - a.fieldType;
        }
        return a.fieldType - a.fieldType;
      },
      render: lookup('customField.fieldType'),
    },
    {
      title: '字段名',
      dataIndex: 'fieldDisplayName',
      width: 150,
      render: (fieldDisplayName: string, field: any) => {
        return (
          <>
            <Form.Item
              name={['fieldList', `${field.fieldId}${field.objectNodeId}`, 'fieldDisplayName']}
              initialValue={fieldDisplayName}
              rules={[{ max: 255, message: '不可超过255个字符' }, { validator: textValidator1 }]}
              style={{ margin: 0 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              hidden
              name={['fieldList', `${field.fieldId}${field.objectNodeId}`, 'field']}
              initialValue={field}
            />
          </>
        );
      },
    },
    {
      title: '所属对象',
      dataIndex: 'objectName',
      width: 150,
      sorter: (a: any, b: any, direction: string) => {
        if (direction === 'ascend') {
          return b.objectName.localeCompare(a.objectName);
        }
        return a.objectName.localeCompare(b.objectName);
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys: _.map(selectedRows, (field) => `${field.fieldId}${field.objectNodeId}`) || [],
    onSelectAll: (selected: boolean) => {
      const fieldList = previewField.map((field) => ({ ...field, checked: Number(selected) }));

      setSelectedRows(selected ? fieldList : []);
      form.setFieldsValue({
        fieldList: _.keyBy(fieldList, (field) => `${field.fieldId}${field.objectNodeId}`),
      });
    },
    renderCell: (checked: boolean, record: any, index: number) => (
      <Form.Item
        style={{ margin: 0 }}
        name={['fieldList', `${record.fieldId}${record.objectNodeId}`, 'checked']}
        valuePropName="checked"
        initialValue={record.checked}
      >
        <Checkbox
          onChange={() => {
            setSelectedRows(
              _.map(
                _.filter(
                  form.getFieldValue('fieldList'),
                  (field) => field.checked && field?.field?.fieldId,
                ),
                (value) => ({
                  ...value.field,
                  checked: 1,
                  fieldDisplayName: value.fieldDisplayName,
                }),
              ),
            );
          }}
        />
      </Form.Item>
    ),
  };

  const fetchPreviewField = async (id: number, params = {}) => {
    const { data: fieldData } = await fetchDataSetQueryDataSetPreviewField({
      dataSetId: id,
      ...params,
    });

    if (fieldData) {
      setPreviewField(fieldData.sort((a, b) => Number(b?.checked) - Number(a?.checked)));
    }
    return fieldData;
  };

  useEffect(() => {
    if (dataSetData?.id) {
      fetchPreviewField(dataSetData?.id);
    }
    setPreviewColumns(getFieldColumns(previewFieldInfos || []));
    setSelectedRows(previewFieldInfos || []);
  }, []);

  const isShowTips = useCallback(() => isChangeField, [isChangeField]);

  const saveData = async (nodeData: any) => {
    const baseData = await form.validateFields();

    try {
      const { data, code } = id
        ? await fetchDataSetModifyDataSet({
            ...baseData,
            dataSetId: id,
            themeDetail: baseData?.themeDetail || 0,
            ...nodeData,
          })
        : await fetchDataSetCreateDataSet({
            ...baseData,
            ...nodeData,
          });

      refreshDataSet(data?.dataSetId || 0);
      if (code === 200 && data?.dataSetId) {
        setAvtiveKey('preview');
        setDataSet({ ...baseData, ...nodeData, id: data?.dataSetId });
        setPreviewTableData([]);
        const fieldData = await fetchPreviewField(data?.dataSetId);

        if (fieldData) {
          const newFieldsList = _.keyBy(
            _.map(fieldData, (value) => ({
              ...value,
              checked: 1,
              fieldDisplayName: value.fieldDisplayName,
            })),
            (field) => `${field.fieldId}${field.objectNodeId}`,
          );

          setSelectedRows(fieldData || []);
          setPreivewFields(fieldData);
          form.setFieldsValue({ fieldList: newFieldsList });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const refreshData = () => {
    setIsLoading(true);
    const fields = _.filter(
      selectedRows.map((field) => ({
        ...field,
        fieldDisplayName: form.getFieldValue([
          'fieldList',
          `${field.fieldId}${field.objectNodeId}`,
          'fieldDisplayName',
        ]),
      })),
      (field) => field.fieldId,
    );

    setPreivewFields(fields);
    setPreviewColumns(getFieldColumns(fields) || []);
    id &&
      fetchDataSetDataSetPreviewProcess({
        dataSetId: id,
        previewInfos: fields,
      })
        .then((res) => {
          if (res?.code === 200) {
            setPreviewTableData(res.data?.previewData);
          } else {
            setPreviewTableData([]);
          }
        })
        .finally(() => {
          setIsLoading(false);
          setIsChangeField(false);
        });
  };

  return (
    <Tabs
      activeKey={activeKey}
      type="card"
      onTabClick={(key: string) => {
        setAvtiveKey(key);
      }}
    >
      <Tabs.TabPane tab={'数据预览'} key={'preview'}>
        <Row gutter={24}>
          <Col span={8}>
            <Card title="数据集字段：">
              <BlTable
                columns={fieldColums}
                dataSource={previewField}
                rowSelection={rowSelection}
                rowKey={(field) => `${field.fieldId}${field.objectNodeId}`}
                scroll={{ x: 'max-content' }}
                pagination={{
                  showSizeChanger: true,
                  showQuickJumper: true,
                  showTotal: (total) => `共 ${total} 条`,
                }}
              />
            </Card>
          </Col>
          <Col span={16}>
            <Card
              title="数据集预览:"
              extra={
                <>
                  {isShowTips() && (
                    <span style={{ marginRight: 10 }}>
                      {'当前字段设置已修改，点击刷新数据预览数据结果'}
                    </span>
                  )}
                  <Button type="primary" onClick={() => refreshData()}>
                    刷新数据
                  </Button>
                </>
              }
            >
              <BlTable
                columns={previewColums}
                dataSource={previewTableData}
                loading={isLoading}
                scroll={{ x: 'max-content', y: 200 }}
                pagination={false}
                footer={() => '默认显示最新的20行数据，用于参考配置结果'}
              />
            </Card>
          </Col>
        </Row>
      </Tabs.TabPane>
      <Tabs.TabPane tab={'对象视图'} key={'objectPreview'}>
        {(dataSetData || !isEdit) && (
          <RelationChart
            isEdit={isEdit}
            saveData={saveData}
            initialData={{ objectNodeInfoList, objectRelationShipInfoList }}
            form={form}
          />
        )}
      </Tabs.TabPane>
    </Tabs>
  );
};

export default DataEdit;
