import { useEffect, useState } from 'react';
import { toNumber } from 'lodash';
import {
  fetchUserBusinessAuthorizationInformation,
  FetchUserBusinessAuthorizationInformationResponse,
} from 'src/api/ytt/user-domain/user';
import AvailableInfo from './components/availableInfo';
import DashedBox from './components/dashedBox';
import LineProgress from './components/linePorgress';
import SystemUsage from './components/systemUsage';
import styles from './styles.module.scss';

const LicenseInformation = () => {
  const [businessInfo, setBusinessInfo] =
    useState<FetchUserBusinessAuthorizationInformationResponse>();
  const [remainDays, setRemainDays] = useState<string[]>();
  const [effectiveDate, setEffectiveDate] = useState<string>();
  const [webAppRemain, setWebAppRemain] = useState<number | undefined>();
  const [appRemain, setAppRemain] = useState<number | undefined>();
  const [biRemain, setBiRemain] = useState<number | undefined>();
  const [systemSituation, setSystemSituation] = useState<(string | number)[][]>();

  const fetchData = async () => {
    const res = await fetchUserBusinessAuthorizationInformation();

    setBusinessInfo(res);
    const remainDaysStrArr = (res.data?.remainDays + '').split('');
    const sinceAt = res.data?.sinceAt || [];
    const dueAt = res.data?.dueAt || [];
    const newSinceAt = [];
    const newDueAt = [];
    const systemSituation = [];

    for (const elem of sinceAt) {
      if (toNumber(elem) < 10) {
        newSinceAt.push('0' + elem);
      } else {
        newSinceAt.push('' + elem);
      }
    }
    for (const elem of dueAt) {
      if (toNumber(elem) < 10) {
        newDueAt.push('0' + elem);
      } else {
        newDueAt.push('' + elem);
      }
    }

    setEffectiveDate(newSinceAt.join('/') + '-' + newDueAt.join('/'));
    setRemainDays(remainDaysStrArr);
    setWebAppRemain((res.data?.webAppNum || 0) - (res.data?.webAppUsed || 0));
    setAppRemain((res.data?.appNum || 0) - (res.data?.appUsed || 0));
    setBiRemain((res.data?.biNum || 0) - (res.data?.biUsed || 0));
    systemSituation.push([
      res.data?.apiNum || 0,
      (res.data?.apiNum || 0) - (res.data?.apiUsed || 0),
      '接口数量',
      '#69C0FF',
      '#E6F7FF',
    ]);
    systemSituation.push([
      res.data?.dashboardNum || 0,
      (res.data?.dashboardNum || 0) - (res.data?.dashboardUsed || 0),
      '定制看板数量',
      '#87E8DE',
      '#E6FFFB',
    ]);
    systemSituation.push([
      res.data?.batchRecordNum || 0,
      (res.data?.batchRecordNum || 0) - (res.data?.batchRecordUsed || 0),
      '定制批记录数量',
      '#95DE64',
      '#F6FFED',
    ]);
    systemSituation.push([
      res.data?.fileCapacity || 0,
      (res.data?.fileCapacity || 0) - (res.data?.fileCapacityUsed || 0),
      '文件容量',
      '#02B980',
      '#E3F7F1',
    ]);
    systemSituation.push([
      res.data?.customObjectNum || 0,
      (res.data?.customObjectNum || 0) - (res.data?.customObjectUsed || 0),
      '自定义对象数量',
      '#FFC53D',
      '#FFFBE6',
    ]);
    systemSituation.push([
      res.data?.customFieldNum || 0,
      (res.data?.customFieldNum || 0) - (res.data?.customFieldUsed || 0),
      '自定义字段数量',
      '#FA8C16',
      '#FFF7E6',
    ]);
    setSystemSituation(systemSituation || []);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles['overall-container']}>
      <div className={styles['left-part']}>
        <div className={styles['module-container']}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <div className={styles['module-title']}>基本信息</div>
              <div className={styles['basic-info-top']}>
                <div className={styles['base-info-top-item']}>
                  {`工厂号：${businessInfo?.data?.code}`}
                </div>
                <div className={styles['base-info-top-item']}>
                  {`租户显示名称：${businessInfo?.data?.displayName}`}
                </div>
                <div className={styles['base-info-top-item']}>软件版本号：1.0.0</div>
              </div>
            </div>
            <div>
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <div>租户到期剩余</div>
                {remainDays?.map((number) => (
                  <DashedBox number={number} key={number} />
                ))}
                <div style={{ marginLeft: 8 }}>天</div>
              </div>
              <div className={styles['basic-info-effective-date']}>有效日期：{effectiveDate}</div>
            </div>
          </div>
          <div className={styles['available-info-container']}>
            <AvailableInfo
              circleName={'网页+移动端账号使用'}
              authNumber={businessInfo?.data?.webAppNum || 0}
              availableNumber={webAppRemain}
              index={1}
            />
            <AvailableInfo
              circleName={'移动端账号使用'}
              authNumber={businessInfo?.data?.appNum || 0}
              availableNumber={appRemain}
              index={2}
            />
            <AvailableInfo
              circleName={'看板账号使用'}
              authNumber={businessInfo?.data?.biNum || 0}
              availableNumber={biRemain}
              index={3}
            />
          </div>
        </div>
        <div className={styles['module-container']} style={{ marginTop: 16 }}>
          <div className={styles['module-title']} style={{ marginBottom: 28 }}>
            许可项使用情况
          </div>
          <SystemUsage licenseName={'基础许可'} authNumber={10} availableNumber={5} note={'备注'} />
          <SystemUsage licenseName={'SOP许可'} authNumber={10} availableNumber={5} note={'备注'} />
          <SystemUsage licenseName={'称量许可'} authNumber={10} availableNumber={5} note={'备注'} />
        </div>
      </div>
      <div className={styles['right-part']}>
        <div className={styles['module-container']}>
          <div className={styles['module-title']} style={{ marginBottom: 88 }}>
            系统用量使用情况
          </div>
          {systemSituation &&
            systemSituation.map((situation) => {
              return (
                <LineProgress
                  numberName={situation[2] as string}
                  authNumber={situation[0]}
                  availableNumber={situation[1]}
                  strokeColor={situation[3]}
                  trailColor={situation[4]}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default LicenseInformation;
