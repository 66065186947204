import React from 'react';
import { DataFormLayoutForModal } from 'layout';
import BaseInfo from './baseInfo';

interface Props {
  visible: Boolean;
  type: string;
  refreshTable?: () => void;
  refreshDetails: () => void;
  onCancel: () => void;
  id?: number | undefined;
}

const CreateAndEdit = (props: Props) => {
  const { visible, ...rest } = props;

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      width={800}
      content={<BaseInfo {...rest} />}
      onClose={props.onCancel}
    />
  );
};

export default CreateAndEdit;
