import React, { useState, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { message, Space } from 'antd';
import moment, { Moment } from 'moment';
import _ from 'lodash';

import { RecordListLayout } from 'src/layout';
import { fieldTypeList, gcTime } from 'src/utils';
import {
  fetchRepairTaskList,
  fetchRepairTaskCancel,
  fetchRepairTaskUpdatePlanTimeBatch,
  // fetchRepairTaskDelete,
} from 'src/api/ytt/resource-domain/repairTask';

import TagTooltip from 'src/components/tooltip/tagTooltip';
import {
  SingleOperationLogComponent,
  SearchSelect,
  TagList,
  LinkTooltip,
  BlColumnsType,
  BlIcon,
} from 'src/components';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { formatDateTimeRangeToUnix } from 'src/utils/formatters/dateTime';
import { mapLabeledValueToValue } from 'src/utils/array';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { appDict, appEnum, lookup } from 'src/dict';
import { ResourceBusinessType } from 'src/dict/resources';
import renderQrCode from 'src/page/share/renderQrCode';
import { renderUserArray } from 'src/page/share/renderUser';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import ResourceClassificationCascader from '../../components/ResourceClassificationCascader';
import { MaintenanceTreeFnParams } from '../../components/maintenanceTree';
import type { RepairTaskListItemType } from '../index.d';
import { goToCreate, goToEdit, goToDetail, toDetail, getDetailPath } from '../navigates';
import { BL_BUSINESS_CODE, BL_BUSINESS_NAME } from '../config';
import PlanUserDeptRole from '../components/PlanUserDeptRole';
import AutoTimer from '../components/autoTimer';
import { fieldLabels } from '../constants';
import TimeOutRecord from './timeOutRecord';
import ResourceTagCascader from '../../malfunctionRecord/components/ResourceTagCascader';
import AdjustPlanTimeModal, { showAdjustError } from './AdjustPlanTimeModal';
import { replaceSign } from 'src/utils/constants';

interface ListProps extends RouteComponentProps, MaintenanceTreeFnParams {}

const List = (props: ListProps) => {
  const [detailId, setDetailId] = useState<number>(0);
  const [refreshMarker, setRefreshMarker] = useState<number>(0);
  const [resetRefreshMarker, setResetRefreshMarker] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [timeOutRecordId, setTimeOutRecordId] = useState<number>();
  const [adjustTimeModalVisible, setAdjustTimeModalVisible] = useState(false);
  const [adjustTimeModalHandler, setAdjustTimeModalHandler] = useState<
    | {
        // eslint-disable-next-line func-call-spacing
        onSuccess?: () => void;
        onFail?: () => void;
      }
    | undefined
  >(undefined);

  /** 用来打开弹框 */
  const openModal = useOpenExImportModal();

  const dispatch = useDispatch();

  const { target, businessType, subLocationFlag } = props;

  const refreshTable = () => {
    setRefreshMarker(Math.random() * 100);
  };

  useEffect(() => {
    // 侧边栏发生改变时清空列表原先的筛选条件并刷新
    setTimeout(() => {
      setResetRefreshMarker(Date.now());
    });
  }, [target, subLocationFlag, businessType]);

  // const handleDelete = async (ids: any[], onSuccess?: () => void) => {
  //   // 删除
  //   try {
  //     const res = await fetchRepairTaskDelete({ id: _.head(ids) });

  //     if (res.code !== 200) {
  //       message.error(res?.message);
  //       return;
  //     }
  //     typeof onSuccess === 'function' && onSuccess?.();
  //     message.success('删除成功');
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const onAdjustPlanTime = async ({
    planStartTime,
    planEndTime,
  }: {
    planStartTime?: Moment;
    planEndTime?: Moment;
  }) => {
    const response = await fetchRepairTaskUpdatePlanTimeBatch({
      ids: selectedRowKeys,
      planStartTime: planStartTime?.valueOf(),
      planEndTime: planEndTime?.valueOf(),
    });

    if (response && response.data && response.code === 200) {
      const { failNum } = response.data;

      if (failNum === 0) {
        message.success('调整成功');
        adjustTimeModalHandler?.onSuccess?.();
      } else {
        showAdjustError(response.data);
        adjustTimeModalHandler?.onFail?.();
      }
    }
  };

  const mainMenu = [
    {
      title: '导出',
      icon: <BlIcon type="icondaochu" />,
      showExport: true,
      auth: authDict.repairTask_export,
      onClick: () =>
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.repairTask,
        }),
    },
    {
      title: '资源报修',
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.repairTask_add,
      onClick: () => {
        goToCreate();
      },
      items: [],
    },
  ];

  const batchMenu = [
    {
      title: '取消',
      popconfirm: { title: '确认取消?' },
      auth: authDict.repairTask_cancel,
      onClick: () =>
        fetchRepairTaskCancel({
          ids: selectedRowKeys,
        }).then((res) => {
          if (res.code === 200 && res.data) {
            const { successNum, failNum } = res.data;

            if (successNum === 0) {
              message.success('取消失败！');
            } else if (failNum === 0) {
              message.success('取消成功！');
            } else {
              message.success('部分取消成功！');
            }

            refreshTable();
          }
        }),
    },
    {
      title: '调整计划时间',
      auth: authDict.repairTask_edit,
      onClick: (success?: () => void, fail?: () => void) => {
        setAdjustTimeModalHandler({
          onSuccess: () => {
            setAdjustTimeModalVisible(false);
            success?.();
          },
          onFail: () => {
            setAdjustTimeModalVisible(false);
            fail?.();
          },
        });
        setAdjustTimeModalVisible(true);
      },
    },
    // {
    //   title: '删除',
    //   icon: <BlIcon type="iconshanchulajitong1" />,
    //   onClick: (onSuccess: any, onFail: any) => {
    //     BLDelConfirm(
    //       `是否确认删除选中的${BL_BUSINESS_NAME}?`,
    //       delForm,
    //       () => {
    //         handleDelete(selectedRowKeys);
    //         onSuccess();
    //       },
    //       onFail,
    //     );
    //   },
    // },
  ];

  const getOperationList = ({ id, status }: { id: number; status: number }) => {
    const isCanceled = status === appEnum.Resources.RepairTaskStatus.canceled;
    const isEnded = status === appEnum.Resources.RepairTaskStatus.ended;
    const isNotStarted =
      status === appEnum.Resources.RepairTaskStatus.notStarted ||
      status === appEnum.Resources.RepairTaskStatus.toExcute;

    return [
      {
        title: '查看',
        auth: authDict.repairTask_detail,
        onClick: () => {
          goToDetail(id);
        },
      },
      {
        title: '编辑',
        disabled: !isNotStarted,
        auth: authDict.repairTask_edit,
        onClick: () => {
          goToEdit(id);
        },
      },
      {
        title: '取消',
        disabled: isCanceled || isEnded,
        auth: authDict.repairTask_cancel,
        popconfirm: true,
        onClick: () => {
          fetchRepairTaskCancel({
            ids: [id],
          }).then((res) => {
            if (res.code === 200) {
              message.success('取消成功');
              refreshTable();
            }
          });
        },
      },
      {
        title: '暂停记录',
        auth: authDict.repairTask_pause_record,
        onClick: () => {
          setTimeOutRecordId(id);
        },
      },
      // {
      //   title: '删除',
      //   auth:authDict.repairTask_remove,
      //   onClick: () => {
      //     BLDelConfirm(`是否确认删除该${BL_BUSINESS_NAME}？`, delForm, () => {
      //       return handleDelete([id], refreshTable);
      //     });
      //   },
      // },
      {
        title: '操作记录',
        auth: authDict.repairTask_operrecord,
        onClick: () => {
          setDetailId(id);
          setVisibleLog(true);
        },
      },
    ];
  };

  const dataColumns: (BlColumnsType<RepairTaskListItemType>[0] & {
    filterName?: string;
    type?: number;
    isFilter?: boolean;
    selectProps?: any;
    renderItem?: ReactElement;
    dateFormat?: any;
  })[] = _.compact([
    {
      title: fieldLabels.code,
      dataIndex: 'code',
      type: fieldTypeList.text,
      isFilter: true,
      sorter: true,
      fixed: 'left',
      width: 150,
      render: (value: string, record: RepairTaskListItemType, index: number, config: any) => {
        return record?.id ? (
          <LinkTooltip
            text={value}
            to={toDetail(record.id)}
            width={config.contentWidth}
            auth={authDict.repairTask_detail}
          />
        ) : (
          value
        );
      },
    },
    {
      title: fieldLabels.name,
      dataIndex: 'name',
      sorter: true,
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
      render: (value: string, record: RepairTaskListItemType, index: number, config: any) => {
        return record?.id ? (
          <LinkTooltip
            text={value}
            to={toDetail(record.id)}
            width={config.contentWidth}
            auth={authDict.repairTask_detail}
          />
        ) : (
          value
        );
      },
    },
    {
      title: fieldLabels.identificationCode,
      dataIndex: 'identificationCode',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
      render: (identificationCode: string) => <Space>{renderQrCode(identificationCode)}</Space>,
    },
    {
      title: fieldLabels.repairPlan,
      dataIndex: ['repairCase', 'name'],
      width: 150,
      filterName: 'repairCaseId',
      isFilter: true,
      renderItem: (
        <SearchSelect fetchType="repairCase" params={{ type: businessType }} placeholder="请输入" />
      ),
    },
    {
      title: fieldLabels.repairTarget,
      dataIndex: ['bizVO', 'name'],
      width: 150,
      filterName: 'repairTargetId',
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="resource"
          params={{ type: businessType }}
          placeholder="请输入"
          disabled={target?.type === 'Equipment'}
        />
      ),
    },
    {
      title: fieldLabels.repairTargetCode,
      dataIndex: ['bizVO', 'code'],
      width: 150,
      filterName: 'repairTargetCodeId',
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="resource"
          params={{ type: businessType }}
          placeholder="请输入"
          labelPath="code"
        />
      ),
    },
    {
      title: fieldLabels.repairTargetArea,
      dataIndex: ['bizVO', 'location', 'name'],
      width: 150,
    },
    {
      title: fieldLabels.repairRequestor,
      dataIndex: ['reporterList'],
      width: 150,
      isFilter: true,
      filterName: 'reporterIds',
      render: (reporterList) => renderUserArray(reporterList, 2),
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: fieldLabels.malfunctonClassification,
      dataIndex: 'malfunctionClassificationList',
      width: 200,
      isFilter: true,
      filterName: 'malfunctionClassificationId',
      render: (
        malfunctionClassificationList: RepairTaskListItemType['malfunctionClassificationList'],
      ) => {
        return malfunctionClassificationList?.map((item) => item.name).join('/');
      },
      renderItem: (
        <ResourceClassificationCascader
          onChange={() => {}}
          showSearch
          multiple
          params={{ businessType: ResourceBusinessType.malfunction, cascade: true }}
          placeholder={'请选择故障分类'}
          enableAddLabel={'新建分类'}
          onCreateClick={() => {}}
          enableAdd={false}
        />
      ),
    },
    {
      title: fieldLabels.malfunctionTags,
      dataIndex: 'malfunctionLabelList',
      width: 200,
      isFilter: true,
      filterName: 'malfunctionLabelValueIds',
      render: (tags: RepairTaskListItemType['malfunctionLabelList']) =>
        tags && (
          <TagList
            dataSource={tags.map((tag) => ({
              value: tag.id,
              label: `${tag.name}:${tag.labelValue}`,
            }))}
          />
        ),
      renderItem: (
        <ResourceTagCascader nameLabel="故障标签" businessType={ResourceBusinessType.malfunction} />
      ),
    },
    {
      title: fieldLabels.malfunctionLevel,
      dataIndex: 'malfunctionLevel',
      width: 150,
      isFilter: true,
      render: lookup('resources.malfunctionLevel'),
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.resources.malfunctionLevel,
      },
    },
    {
      title: fieldLabels.channelType,
      dataIndex: 'channelType',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      selectProps: {
        options: appDict.resources.repairChannel,
      },
      render: lookup('resources.repairChannel'),
    },
    {
      title: fieldLabels.status,
      dataIndex: 'status',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.resources.repairTaskStatus,
      },
      render: (status: string) => lookup('resources.repairTaskStatus', status),
    },
    {
      title: fieldLabels.classification,
      dataIndex: 'repairClassificationList',
      isFilter: true,
      width: 150,
      render: (text: RepairTaskListItemType['repairClassificationList']) =>
        _.map(text, (item) => item?.name).join('/'),
      filterName: 'classificationId',
      renderItem: (
        <ResourceClassificationCascader
          params={{
            businessType: appEnum.Resources.ResourceBusinessType.repair,
            status: appEnum.Common.UsageStatus.enabled,
            cascade: true,
          }}
          showSearch
          placeholder={'请选择维修任务分类'}
          onCreateClick={() => {}}
          enableAdd={false}
        />
      ),
    },
    {
      title: fieldLabels.scanCodeFlag,
      dataIndex: 'scanCodeFlag',
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.common.ynb,
      },
      width: 150,
      render: (type: any) => lookup('common.ynb', type),
    },
    {
      title: fieldLabels.planStartTime,
      dataIndex: 'planStartTime',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      render: (text: any) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : undefined),
    },
    {
      title: fieldLabels.standardWorkTime,
      dataIndex: 'standardWorkTime',
      width: 200,
      render: (time: number) => <AutoTimer startSec={time} />,
    },
    {
      title: fieldLabels.planEndTime,
      dataIndex: 'planEndTime',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      render: (text: any) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : undefined),
    },
    {
      title: fieldLabels.planExecutorList,
      dataIndex: 'planExecutorList',
      width: 250,
      isFilter: true,
      render: (users: any[]) => <TagTooltip list={users || []} isUser max={2} />,
      renderItem: <PlanUserDeptRole />,
    },
    {
      title: fieldLabels.realStartTime,
      dataIndex: 'realStartTime',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      render: (text: any) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : undefined),
    },
    {
      title: fieldLabels.realEndTime,
      dataIndex: 'realEndTime',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      render: (text: any) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : undefined),
    },
    {
      title: fieldLabels.currentExecutorList,
      dataIndex: 'currentExecutorList',
      width: 250,
      isFilter: true,
      render: (users: any[]) => <TagTooltip list={users || []} isUser max={2} />,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: fieldLabels.executeType,
      dataIndex: 'executeType',
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.resources.repairExecuteType,
      },
      width: 150,
      render: (type: any) => lookup('resources.repairExecuteType', type),
    },
    {
      title: fieldLabels.sopPlan,
      dataIndex: 'sopList',
      isFilter: true,
      width: 150,
      render: (list: any, { executeType }: RepairTaskListItemType) => {
        if (_.isNil(list) || executeType === appEnum.Resources.RepairExecuteType.reportTemplate) {
          return undefined;
        }
        return <TagList dataSource={list ?? []} labelPath="name" valuePath="id" />;
      },

      renderItem: (
        <SearchSelect
          fetchType="SOP"
          placeholder="请选择SOP方案"
          mode="multiple"
          params={{
            status: appEnum.Common.UsageStatus.enabled,
            bizType: appEnum.Sop.BizType.repair,
          }}
        />
      ),
    },
    {
      title: fieldLabels.sopTask,
      dataIndex: 'sopTaskCode',
      width: 150,
      render: (sopTaskCode: string, { sopTaskId, executeType }: RepairTaskListItemType) => {
        if (
          _.isNil(sopTaskId) ||
          executeType === appEnum.Resources.RepairExecuteType.reportTemplate
        ) {
          return undefined;
        }

        return (
          <a
            onClick={() => {
              window.open(`/sop/exec/task/${sopTaskId}/detail`);
            }}
          >
            {sopTaskCode}
          </a>
        );
      },
    },
    {
      title: fieldLabels.reportTemplate,
      dataIndex: 'reportTemplateList',
      isFilter: true,
      width: 150,
      render: (list: any, { executeType }: RepairTaskListItemType) => {
        if (_.isNil(list) || executeType === appEnum.Resources.RepairExecuteType.SOP) {
          return undefined;
        }

        return <TagList dataSource={list ?? []} labelPath="name" valuePath="id" />;
      },
      renderItem: (
        <SearchSelect
          fetchType="reportTemplate"
          placeholder="请选择报告模板"
          mode="multiple"
          params={{ category: [appEnum.ReportTemplate.TemplateType.repairReport] }}
        />
      ),
    },
    {
      title: fieldLabels.createResourceType,
      dataIndex: 'createResourceType',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      selectProps: {
        options: appDict.resources.repairTaskSource,
      },
      render: lookup('resources.repairTaskSource'),
    },
    {
      title: fieldLabels.sourceInstanceCode,
      dataIndex: 'createResourceCode',
      width: 180,
      render: (code: string, { createResourceType, createResourceId }: RepairTaskListItemType) => {
        return !_.isNil(createResourceType) && !_.isNil(code) && !_.isNil(createResourceId) ? (
          <a
            onClick={() => {
              window.open(`${getDetailPath(createResourceType)}/${createResourceId}/detail`);
            }}
          >
            {code}
          </a>
        ) : (
          replaceSign
        );
      },
    },
    {
      title: fieldLabels.malfunctionReason,
      dataIndex: 'malfunctionReason',
      isFilter: true,
      type: fieldTypeList.text,
      width: 150,
    },
    {
      title: fieldLabels.malfunctionDetail,
      dataIndex: 'malfunctionDetail',
      isFilter: true,
      type: fieldTypeList.text,
      width: 150,
    },
    {
      title: fieldLabels.remark,
      dataIndex: 'remark',
      width: 150,
    },
  ]);

  const filterList = dataColumns
    .filter((i) => i.isFilter)
    .map((column: any) => {
      const filter: any = {
        label: column.title,
        name: column.filterName || column.dataIndex,
        type: column.type,
        rules: column.rules,
        renderItem: column.renderItem,
      };

      if (column.type === fieldTypeList.select || column.type === fieldTypeList.multiSelect) {
        filter.selectProps = column.selectProps;
      }
      if (column.type === fieldTypeList.date && column.dateFormat) {
        filter.dateFormat = column.dateFormat;
      }
      return filter;
    });

  const formatDataToQuery = (data: any) => {
    const {
      responsibleDepartment,
      classificationId,
      executeType,
      reportTemplateList,
      sopList,
      repairTargetCodeId,
      planExecutorList,
      currentExecutorList,
      planStartTime,
      planEndTime,
      realStartTime,
      realEndTime,
      repairCaseId,
      repairTargetId,
      triggerEvent,
      triggerRule,
      reporterIds,
      malfunctionClassificationId,
      ...resData
    } = data;

    // 侧边栏筛选数据;
    const repairTreeData = {
      /** 是否包含子区域（侧边栏） */
      hasSubLocation: subLocationFlag,
      /** 维修业务对象 */
      bizType: businessType ?? appEnum.Resources.ResourceBusinessType.equipment,
      /** 维修目标（侧边栏&筛选条件） */
      repairTargetId: target?.type === 'Equipment' ? target?.id : _.get(repairTargetId, 'value'),

      /** 区域ID（侧边栏） */
      locationId: target?.type === 'Location' ? target?.id : undefined,
    };

    const sendData = {
      ...resData,
      ...repairTreeData,
      ...formatDateTimeRangeToUnix('planStartTimeFrom', 'planStartTimeTo', planStartTime),
      ...formatDateTimeRangeToUnix('planEndTimeFrom', 'planEndTimeTo', planEndTime),
      ...formatDateTimeRangeToUnix('realStartTimeFrom', 'realStartTimeTo', realStartTime),
      ...formatDateTimeRangeToUnix('realEndTimeFrom', 'realEndTimeTo', realEndTime),
      repairTargetCodeId: _.get(repairTargetCodeId, 'value'),
      repairCaseId: _.get(repairCaseId, 'value'),
      departmentIdList: !_.isEmpty(responsibleDepartment)
        ? mapLabeledValueToValue(responsibleDepartment)
        : undefined,
      repairClassificationId: !_.isEmpty(classificationId) ? _.last(classificationId) : undefined,
      executeType,
      executeIds:
        !_.isEmpty(sopList) || !_.isEmpty(reportTemplateList)
          ? _.map(_.compact(_.concat(sopList, reportTemplateList)), 'value')
          : undefined,
      planExecutorType: planExecutorList?.type,
      planExecutorList: _.map(planExecutorList?.value, 'value'),
      currentExecutorList: !_.isEmpty(currentExecutorList)
        ? mapLabeledValueToValue(currentExecutorList)
        : undefined,
      triggerEventId: _.get(triggerEvent, 'value'),
      triggerRuleId: _.get(triggerRule, 'value'),
      reporterIds: reporterIds?.map((reporter: any) => reporter.id ?? reporter.value),
      malfunctionClassificationId: !_.isEmpty(malfunctionClassificationId)
        ? _.last(malfunctionClassificationId)
        : undefined,
    };

    dispatch.excelImport.updateBusinessData({ businessData: sendData });
    return sendData;
  };

  const formatDataToDisplay = (data: any) => {
    const { planExecutorList, ...res } = _.cloneDeep(data);

    return { ...res, planExecutorList: _.map(planExecutorList?.value, 'value') };
  };

  const formatDataToFormDisplay = (data: any) => {
    const { planStartTime, planEndTime, realStartTime, realEndTime, ...rest } = data;

    return {
      ...rest,
      planStartTime: gcTime.formatRangeTimeToMoment(planStartTime),
      planEndTime: gcTime.formatRangeTimeToMoment(planEndTime),
      realStartTime: gcTime.formatRangeTimeToMoment(realStartTime),
      realEndTime: gcTime.formatRangeTimeToMoment(realEndTime),
    };
  };

  return (
    <>
      <RecordListLayout<RepairTaskListItemType>
        filterList={filterList}
        requestFn={fetchRepairTaskList}
        useIndex={false}
        mainMenu={mainMenu}
        batchMenu={batchMenu}
        placeholder={`请输入${BL_BUSINESS_NAME}名称或编号`} // 快速搜索input placeholder
        formatDataToQuery={formatDataToQuery} // 转换搜索条件进行查询（快速查询字段："quickSearch",当前页："page",分页大小："size"）
        formatDataToDisplay={formatDataToDisplay} // 转换搜索条件进行展示
        formatDataToFormDisplay={formatDataToFormDisplay}
        columns={dataColumns} // table columns
        refreshMarker={refreshMarker}
        resetRefreshMarker={resetRefreshMarker}
        selectedRowKeys={selectedRowKeys}
        rowKey="id"
        configcacheKey={BL_BUSINESS_CODE}
        onSelectedRowKeys={(selectKey: React.Key[]) => {
          setSelectedRowKeys(selectKey.map((key) => Number(key)));
        }}
        getOperationList={getOperationList}
        userAuth={getAuthFromLocalStorage()}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={OBJECT_OF_CODE.RepairTask}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      <TimeOutRecord recordId={timeOutRecordId} onClose={() => setTimeOutRecordId(undefined)} />
      <AdjustPlanTimeModal
        visible={adjustTimeModalVisible}
        resultHandler={adjustTimeModalHandler}
        onAdjust={onAdjustPlanTime}
      />
    </>
  );
};

export default List;
