import React, { ReactNode, useEffect } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Input } from 'antd';
import { SearchAndCreate } from './searchAndCreate';
import { textValidator1, textValidator2, withoutSpaceBothSides } from 'src/utils/formValidators';
import { BcAttachmentForm, NumberRulesStandardHook } from 'src/components';
import { CustomDataFormLayout } from 'src/components/customLayout';
import { gcUrl } from 'src/utils';
import _ from 'lodash';

interface baseInfoProps {
  onFinish: () => void;
  title: string;
  initialData?: any;
  refreshMarker?: number;
  edit: boolean;
  form: any;
}

const CreateAndEditBaseInfo = (props: baseInfoProps) => {
  const { title, onFinish, initialData, form, edit, refreshMarker } = props;
  const { layoutId } = gcUrl.getParams();

  return (
    <CustomDataFormLayout
      form={form}
      title={title}
      onFinish={onFinish}
      instanceIdParamKey={initialData?.id}
      objectCode={'InspectionItem'}
      layoutId={layoutId}
      extraInfoItems={_.compact([
        initialData?.id && {
          label: '编辑原因',
          name: 'operateReason',
          rules: [{ max: 1000, message: '不超过1000个字符' }],
          render: () => <Input.TextArea placeholder="请输入" />,
        },
      ])}
      formatFormData={(data: any) => ({
        ...data,
      })}
      customInfoItems={[
        ...NumberRulesStandardHook({
          label: '检验项编号',
          form,
          edit,
          objectCode: 'InspectionItem',
          fieldCode: 'code',
          rules: [{ max: 255, message: '不可超过255字符' }, { validator: textValidator2 }],
          refreshMarker,
        }),
        {
          label: '检验项分类',
          name: 'qcCheckItemCategory',
          render: () => (
            <SearchAndCreate
              type="CheckItemClassificationList"
              createButtonRelatedProps={{ title: '新增检验项分类', needCreateButton: true }}
              placeholder="默认分类"
              isFilterDefault
            />
          ),
        },
      ]}
    />
  );
};

export default CreateAndEditBaseInfo;
