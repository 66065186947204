/**
 * 其他信息
 */
import React from 'react';
import moment from 'moment';
import { Form, FormInstance, Input } from 'antd';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
//
import { BomFormOther, BomResponse } from '../index.type';

interface OtherInfoFormProps {
  form: FormInstance;
  name: string;
}

// 格式化 其他信息 给接口
export const formatOtherToApi = (other?: BomFormOther) => {
  return { remark: other?.remark };
};

export const formatOtherToForm = (other?: BomResponse): BomFormOther => {
  return { remark: other?.remark };
};

export const OtherInfoForm = (props: OtherInfoFormProps) => {
  const { form, name } = props;

  const creator = form.getFieldValue(['creator']);
  const operator = form.getFieldValue(['operator']);

  return (
    <div>
      <Form form={form} name={name}>
        <Form.Item label="备注" name={[name, 'remark']}>
          <Input.TextArea maxLength={1000} showCount />
        </Form.Item>
        <Form.Item label="创建人">
          {creator && <AvatarDisplay {...creator} isShowTag={false} isUser />}
        </Form.Item>
        <Form.Item label="创建时间">
          {moment(form.getFieldValue(['createdAt'])).format('YYYY-MM-DD HH:mm:ss')}
        </Form.Item>
        <Form.Item label="更新人">
          {operator && <AvatarDisplay {...operator} isShowTag={false} isUser />}
        </Form.Item>
        <Form.Item label="更新时间">
          {moment(form.getFieldValue(['updatedAt'])).format('YYYY-MM-DD HH:mm:ss')}
        </Form.Item>
      </Form>
    </div>
  );
};
