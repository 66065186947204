import { useState } from 'react';
import {
  BcTextControl,
  BlSortFormList,
  SearchSelect,
  SingleOperationLogComponent,
} from 'components';
import _ from 'lodash';
import { RecordListLayout, FilterItem } from 'layout';
import { Button, Form, message as Message, message, Modal, Select, Input } from 'antd';
import {
  fetchCustomerDelete,
  FetchCustomerDeleteRequest,
  FetchCustomerDeleteResponse,
} from 'src/api/ytt/order-domain/customer';
import { auditStatus as auditStatusMapping } from 'src/dict/customer/mappings';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { showErrorListMessage } from 'src/components/message/errorMessageList';
import { ErrorNotification } from 'src/components/notification';
import { replaceSign } from 'src/utils/constants';
import { avatarDisplay } from 'src/components/avatar/show';
import { fieldTypeList, gcObject, gcTime } from 'src/utils';
import type {
  CustomerListItemPropsUser,
  CustomerMaterialRegAuditListCustomerItemProps,
} from '../../interface';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import {
  fetchCustomerMaterialRegAuditAccept,
  fetchCustomerMaterialRegAuditDecline,
  fetchCustomerMaterialRegAuditListCustomer,
  FetchCustomerMaterialRegAuditListCustomerRequest,
  fetchCustomerMaterialRegAuditListMaterialInfo,
} from 'src/api/ytt/supply-chain-domain/customer_material_reg_audit';
import { FormListFieldData } from 'antd/lib/form/FormList';
import useBlModal from 'src/components/modal/useBlModal';
import { getMaterialUnitInfo, getMaterialUnits } from 'src/entity/material';
import { NamePath } from 'antd/lib/form/interface';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import { handleFormatTransformUnits } from '../../utils';
import { AuditStatus } from 'src/dict/customer';
import lookup from 'src/dict';
import ViewMaterialsRegInfoTable from '../../components/viewMaterialsRegInfoTable';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import authDict from 'src/utils/auth';

const errorColumns = [
  {
    title: '客户名称',
    dataIndex: 'name',
  },
  {
    title: '失败原因',
    dataIndex: 'failReason',
  },
];

const MaterialsRegisterList = () => {
  // 选中行的key，用以批量删除
  const [selectedRowKeys] = useState<React.Key[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新页面
  const [delForm] = Form.useForm();
  const refresh = () => setRefreshMarker(Date.now());
  const [form] = Form.useForm();
  const { show } = useBlModal();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [detailId, setDetailId] = useState<number>(0);

  /**
   *  获取物料信息
   */
  const handleGetMaterialInfo = (namepath: NamePath) => {
    const value = JSON.parse(form.getFieldValue(namepath)?.value ?? '{}');

    return value;
  };
  /**
   *  查看 邀请注册物料 弹窗
   */
  const handleViewMaterials = (record: CustomerMaterialRegAuditListCustomerItemProps) => {
    const config = {
      title: '邀请注册物料信息',
      width: 1200,
      onOk: async () => {},
      okText: '确认',
      onCancel: () => {},
      cancelText: '取消',
      bodyStyle: { paddingTop: 0 },
      content: (
        <div style={{ marginBottom: '30px' }}>
          <ViewMaterialsRegInfoTable
            requestFn={async (params) => {
              const { data } = await fetchCustomerMaterialRegAuditListMaterialInfo({
                ...params,
                materialRegAuditId: record.id!,
              });

              return { data: { list: data } };
            }}
          />
        </div>
        // <RecordListLayout<any>
        //   // style={{ height: 700 }}
        //   // useIndex={false}
        //   filterContaniner={false}
        //   useQuickFilter={false}
        //   useColConfig={false}
        //   useFilterWithUrl={false}
        //   requestFn={async (params) => {
        //     const { data } = await fetchCustomerMaterialRegAuditListMaterialInfo({
        //       ...params,
        //       materialRegAuditId: record.id,
        //     });

        //     return { data: { list: data } };
        //   }}
        //   columns={materialDataColumns}
        //   rowKey={'id'}
        // />
      ),
    };

    show(config);
  };
  /**
   * 接受操作
   */
  const handleAccept = async (record: CustomerMaterialRegAuditListCustomerItemProps) => {
    try {
      const { id } = record;
      const acceptFn = async () => {
        const { code } = await fetchCustomerMaterialRegAuditAccept({
          items: handleFormatTransformUnits(form.getFieldValue('associatedMaterialList')),
          customerMaterialAuditId: id as number,
        });

        if (code == 200) {
          refresh();
          message.success('接受邀请成功');
        } else {
          refresh();
          message.error('接受邀请失败');
        }
      };

      const name = 'associatedMaterialList';
      const getColumns = () => {
        return [
          {
            title: '客户物料编号',
            render: (text: string, field: FormListFieldData) => (
              <div key={field.key}>
                <Form.Item
                  fieldKey={[field.fieldKey, 'cusMaterialCode']}
                  name={[field.name, 'cusMaterialCode']}
                  style={{ marginBottom: '0' }}
                >
                  <BcTextControl />
                </Form.Item>
              </div>
            ),
          },
          {
            title: '客户物料名称',
            render: (text: string, field: FormListFieldData) => (
              <div key={field.key}>
                <Form.Item
                  fieldKey={[field.fieldKey, 'cusMaterialName']}
                  name={[field.name, 'cusMaterialName']}
                  style={{ marginBottom: '0' }}
                >
                  <BcTextControl />
                </Form.Item>
              </div>
            ),
          },
          {
            title: '客户物料数量',
            render: (text: string, field: FormListFieldData) => (
              <div key={field.key}>
                <Form.Item
                  fieldKey={[field.fieldKey, 'cusAmount', 'amountDisplay']}
                  name={[field.name, 'cusAmount', 'amountDisplay']}
                  style={{ marginBottom: '0' }}
                >
                  1
                </Form.Item>
              </div>
            ),
          },
          {
            title: '客户物料单位',
            render: (text: string, field: FormListFieldData) => (
              <div key={field.key}>
                <Form.Item
                  fieldKey={[field.fieldKey, 'cusAmount', 'unitName']}
                  name={[field.name, 'cusAmount', 'unitName']}
                  style={{ marginBottom: '0' }}
                >
                  <BcTextControl />
                </Form.Item>
              </div>
            ),
          },
          {
            title: '自建物料',
            width: 200,
            render: (text: string, field: FormListFieldData) => (
              <Form.Item
                fieldKey={[field.fieldKey, 'materialId']}
                name={[field.name, 'materialId']}
                style={{ marginBottom: '0' }}
                rules={[{ required: true, message: '请选择物料' }]}
              >
                <SearchSelect fetchType={'materialCompleteInfo'} />
              </Form.Item>
            ),
          },
          {
            title: '自建物料名称',
            width: 150,
            render: (text: string, field: FormListFieldData) => (
              <Form.Item
                dependencies={[[name, field.name, 'materialId']]}
                style={{ marginBottom: 0 }}
              >
                {() => {
                  return (
                    <Form.Item
                      fieldKey={[field.fieldKey, 'materialName']}
                      name={[field.name, 'materialName']}
                      style={{ marginBottom: '0' }}
                    >
                      <div>
                        {handleGetMaterialInfo([name, field.name, 'materialId'])?.baseInfo?.name ||
                          replaceSign}
                      </div>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            ),
          },
          {
            title: '数量',
            render: (text: string, field: FormListFieldData) => (
              <Form.Item
                dependencies={[
                  [name, field.name, 'materialUnit'],
                  [name, field.name, 'materialId'],
                ]}
                style={{ marginBottom: 0 }}
              >
                {() => {
                  const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
                    handleGetMaterialInfo([name, field.name, 'materialId']),
                    form.getFieldValue([name, field.name, 'materialUnit'])?.value,
                  );

                  return (
                    <Form.Item
                      fieldKey={[field.fieldKey, 'materialAmount']}
                      name={[field.name, 'materialAmount']}
                      style={{ marginBottom: '0' }}
                      rules={_.compact([
                        { required: true, message: '请输入' },
                        {
                          validator: numberMinMaxCheck({
                            min: 0,
                            minAllowEqual: false,
                          }),
                        },
                        enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
                      ])}
                      validateFirst
                    >
                      <Input placeholder="请输入" type={'number'} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            ),
          },
          {
            title: '单位',
            render: (text: string, field: FormListFieldData) => (
              <Form.Item
                dependencies={[[name, field.name, 'materialId']]}
                style={{ marginBottom: 0 }}
              >
                {() => {
                  return (
                    <Form.Item
                      fieldKey={[field.fieldKey, 'materialUnit']}
                      name={[field.name, 'materialUnit']}
                      style={{ marginBottom: '0' }}
                      rules={[{ required: true, message: '请选择单位' }]}
                    >
                      <Select
                        placeholder={'请选择'}
                        options={getMaterialUnits(
                          handleGetMaterialInfo([name, field.name, 'materialId']),
                        )}
                        labelInValue
                        allowClear
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            ),
          },
        ];
      };
      const content = (
        <>
          <BlSortFormList
            name={name}
            form={form}
            renderColumns={(remove) => getColumns(remove)}
            isNeedDelete={false}
            isNeedDrag={false}
            isNeedAdd={false}
          />
        </>
      );
      const config = {
        title: '关联自建物料',
        content,
        width: 1200,
        onOk: async () => {
          const values = await form.validateFields();

          if (values) {
            acceptFn();
          }
        },
        okText: '确认',
        onCancel: () => {},
        cancelText: '取消',
      };
      /**
       * 开始业务逻辑👇🏻
       */
      const { data } = await fetchCustomerMaterialRegAuditListMaterialInfo({
        materialRegAuditId: Number(record?.id),
      });

      form.setFieldsValue({
        associatedMaterialList: data,
        // Mock 数据
        // associatedMaterialList:[
        //   {
        //     cusMaterialCode: 123,
        //     cusMaterialName: '123',
        //     cusAmount: {
        //       amountDisplay: 1,
        //       unitName: '元',
        //     },
        //   },
        // ],
      });
      show(config);
    } catch (error) {
      console.log('error', error);
    }
  };
  /**
   * 拒绝操作
   */
  const handleReject = (record: CustomerMaterialRegAuditListCustomerItemProps) => {
    try {
      const { id } = record;
      const rejectFn = async () => {
        const { code } = await fetchCustomerMaterialRegAuditDecline({
          customerMaterialAuditId: id as number,
        });

        if (code == 200) {
          refresh();
          message.success('拒绝成功');
        } else {
          refresh();
          message.error('拒绝失败');
        }
      };
      const config = {
        title: '确认要拒绝邀请吗？',
        content: '若确认拒绝，当前用户将不会向您发起采购',
        width: 436,
        onOk: () => {
          rejectFn();
        },
        okText: '确认拒绝',
        onCancel: () => {},
        cancelText: '再想想',
      };

      Modal.confirm(config);
    } catch (error) {
      console.log('error', error);
    }
  };
  /**
   * 删除操作
   */
  const handleDelete = async (
    onSuccess?: () => void,
    onFail?: () => void,
    idToDeleteAlone?: number,
  ) => {
    try {
      // 从确认删除的form里得到删除原因
      const reason = delForm.getFieldValue('delReason');
      const idsInParam = idToDeleteAlone ? [idToDeleteAlone] : (selectedRowKeys as number[]);
      const requestParam: FetchCustomerDeleteRequest = {
        editReason: reason,
        ids: idsInParam,
      };
      // 不明原因，ytt的FetchHandlingEquipmentDeleteResponse中data没有描述，只能强行转换成自定义类型
      const { data }: FetchCustomerDeleteResponse = await fetchCustomerDelete(requestParam);

      if (data) {
        const { success, fail } = data;

        // 全部删除成功，显示删除成功
        if (_.isNil(fail) || _.isEmpty(fail)) {
          Message.success('删除成功');
          onSuccess?.();
        } else if (_.isNil(success) || _.isEmpty(success)) {
          // 全部删除失败，提示删除失败及原因
          onFail?.();
          if (fail.length > 0) {
            ErrorNotification({
              message: '删除失败',
              description: (
                <>
                  {fail.map((failItem) => (
                    <div key={failItem.id}>
                      {`${failItem.name}(${failItem.code})`}: {failItem.detail}
                    </div>
                  ))}
                </>
              ),
            });
          } else {
            Message.error('删除失败！');
          }
        } else if (fail.length > 0) {
          onSuccess?.();
          // 否则用表格显示删除结果及原因统计的table
          showErrorListMessage({
            title: '删除出现错误',
            data: fail,
            columns: errorColumns,
            operateName: '删除',
            successCount: success.length,
            failCount: fail.length,
          });
        }
        // 删除成功以后清空删除表单里已经填的删除原因
        delForm.resetFields();
      } else {
        onFail?.();
        console.log('api返回的response里没有data');
      }
    } catch (error) {
      onFail?.();
      console.log(error);
    }
  };
  /**
   * Columns
   */
  const dataColumns = _.compact([
    {
      title: '编号',
      dataIndex: 'code',
      width: 150,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: '客户编号',
      dataIndex: 'customerCode',
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
      renderItem: <SearchSelect fetchType={'customer'} mode="multiple" />,
    },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 150,
      type: fieldTypeList.text,
    },
    {
      title: '邀请注册物料信息',
      width: 200,
      dataIndex: 'customerMaterialRegAudit',
      type: fieldTypeList.text,
      render: (text: any, record: CustomerMaterialRegAuditListCustomerItemProps) => (
        <Button type="link" onClick={() => handleViewMaterials(record)}>
          查看
        </Button>
      ),
    },
    {
      title: '状态',
      dataIndex: 'auditStatus',
      width: 120,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: auditStatusMapping,
        mode: 'multiple',
      },
      render: (text: number) => lookup('customer.auditStatus', text) || replaceSign,
    },

    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 250,
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: '操作人',
      dataIndex: 'operator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (user: Required<CustomerListItemPropsUser>) => {
        return user?.id ? avatarDisplay({ ...user, isUser: true }) : replaceSign;
      },
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择更新人" isMultiple isSelectUser />
      ),
    },
    {
      title: '操作时间',
      dataIndex: 'updatedAt',
      width: 250,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
  ]);
  /**
   * 列操作 ...
   */
  const getOperationList = (record: CustomerMaterialRegAuditListCustomerItemProps) => {
    const { id } = record;

    return [
      {
        title: '接受',
        disabled:
          record?.auditStatus === AuditStatus.accepted ||
          record?.auditStatus === AuditStatus.rejected,
        onClick: () => handleAccept(record),
        auth: authDict.customermaterialregisteredaudit_accept_reg_material,
      },
      {
        title: '拒绝',
        disabled:
          record?.auditStatus === AuditStatus.accepted ||
          record?.auditStatus === AuditStatus.rejected,
        onClick: () => handleReject(record),
        auth: authDict.customermaterialregisteredaudit_decline_reg_material,
      },

      {
        title: '操作记录',
        onClick: () => {
          setDetailId(id!);
          setVisibleLog(true);
        },
        auth: authDict.customermaterialregisteredaudit_operrecord,
      },
    ];
  };
  /**
   * 列表筛选处理函数
   */
  const filterList: FilterItem[] = dataColumns
    .filter((i) => i.isFilter)
    .map((column) => {
      return _.pick(
        {
          ...column,
          label: column.title === '客户编号' ? '客户' : column.title,
          name: column.dataIndex,
          type: column.type,
        },
        [
          'label',
          'name',
          'type',
          'rules',
          'renderItem',
          'selectProps',
          'inputProps',
          'datePickerProps',
          'dateFormat',
        ],
      ) as FilterItem;
    });
  /**
   * 传给接口数据处理函数
   */
  const formatDataToQuery = (param: any): FetchCustomerMaterialRegAuditListCustomerRequest => {
    const { customerCode, operator, createdAt, updatedAt, ...rest } = param;
    const [createdAtFrom, createdAtTo] = gcTime.formatRangeUnixToInt(createdAt);
    const [updatedAtFrom, updatedAtTo] = gcTime.formatRangeUnixToInt(updatedAt);

    const options = {
      createdAtFrom,
      createdAtTo,
      updatedAtFrom,
      updatedAtTo,
      operatorIds: operator?.length ? _.map(operator, 'value') : null,
      customerIds: customerCode?.length ? _.map(customerCode, 'value') : null,
      ...rest,
    };

    return gcObject.filterEmptyProperty(options);
  };
  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (param: any) => {
    const { createdAt, updatedAt, ...rest } = param;

    const result = {
      ...rest,
      createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
      updatedAt: gcTime.formatRangeTimeToMoment(updatedAt || []),
    };

    return gcObject.filterEmptyProperty(result);
  };

  return (
    <>
      <RecordListLayout<CustomerMaterialRegAuditListCustomerItemProps>
        columns={dataColumns}
        filterList={filterList}
        rowKey={'id'}
        configcacheKey={'materialsRegisterList'}
        placeholder={'请输入编号'}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        requestFn={fetchCustomerMaterialRegAuditListCustomer}
        refreshMarker={refreshMarker}
        useIndex={false}
        getOperationList={getOperationList}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={OBJECT_OF_CODE.customerMaterialRegisteredAudit}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default MaterialsRegisterList;
