import { useReducer, useEffect, useState } from 'react';
import _ from 'lodash';
import { Form, Input, message, Select, TreeSelect } from 'antd';
import type { TreeSelectProps } from 'antd/lib/tree-select';
// import { LabeledValue } from 'antd/lib/select';
import { DataFormLayoutForModal, DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { StepEntityType, PrivilegeType, GroupType, NextStepType } from 'src/dict/sop';
import lookup from 'src/dict';
import {
  privilegeType as privilegeTypeMappings,
  // nextStepType as nextStepTypeMappings,
  groupType as groupTypeMappings,
} from 'src/dict/sop/mappings';
import { fetchSopStepCopyStep } from 'src/api/ytt/sop-domain';
import { fetchCustomFieldDetailByCode } from 'src/api/ytt/metadata-domain/customField';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import RoleSelect from 'src/page/organization/components/roleSelect';
// import LimitableSelect from 'src/components/select/limitableSelect';
import { NumberRulesStandardHook } from 'src/components';
import { textValidator1, textValidator2 } from 'src/utils/formValidators';
import extractIdsForStep from '../../share/extractIdsForStep';
// import {
//   hasParallelAncestor,
//   hasLoopableAncestor,
//   getSiblingsByParentId,
// } from '../../share/stepUtils';
import { StepOverviewList } from '../../types';
import { arrayItemProps } from '../../share/formatProps';

export interface CopyStepModalProps {
  visible: boolean;
  groupTree: TreeSelectProps<any>['treeData'];
  initValues: {
    id: number;
    referenceId: number;
    name: string;
    type: StepEntityType;
    privilegeType?: PrivilegeType;
    groupType?: GroupType;
    authUsers?: any[];
    authDepartments?: any[];
    authRoles?: any[];
  };
  steps: StepOverviewList;
  onOk: () => void;
  onCancel: () => void;
}

// const defaultAncestorGroupProps = {
//   isParallel: false,
//   hasLoop: false,
// };

const CopyStepModal = (props: CopyStepModalProps) => {
  const { visible, initValues, groupTree, onOk, onCancel } = props;
  const [form] = Form.useForm();
  const getValue = form.getFieldValue;
  const [, forceUpdate] = useReducer((i) => i + 1, 0);
  const [copying, setCopying] = useState(false);
  // const [ancestorGroupProps, setAncestorGroupProps] = useState(defaultAncestorGroupProps);
  // const [manualNextStepOptions, setManualNextStepOptions] = useState<LabeledValue[]>([]);
  const isGroup = initValues.type === StepEntityType.stepGroup;
  const typeName = lookup('sop.stepEntityType', initValues.type);
  const [refreshMarker, setRefreshMarker] = useState(0);
  const [numberRuleInput] = NumberRulesStandardHook({
    label: `${typeName}编号`,
    form,
    edit: false,
    objectCode: isGroup ? 'SubStepGroup' : 'SubStep',
    fieldCode: 'code',
    rules: [{ max: 64, message: '不超过64个字符' }, { validator: textValidator2 }],
    refreshMarker,
  });

  // 选择目标步骤组时, 根据步骤组属性做调整
  // const onSelectNewParent = useCallback(
  //   (id) => {
  //     if (id === 0) {
  //       setAncestorGroupProps(defaultAncestorGroupProps);
  //     } else {
  //       setAncestorGroupProps({
  //         isParallel: hasParallelAncestor(steps, id, true),
  //         hasLoop: hasLoopableAncestor(steps, id, true),
  //       });
  //     }
  //     setManualNextStepOptions(
  //       getSiblingsByParentId(steps, id).map(({ name, id }) => ({
  //         label: name,
  //         value: id as number,
  //       })),
  //     );
  //     // 清空下个步骤选项
  //     form.setFieldsValue({ nextStepType: undefined, manualNextStepIds: [] });
  //   },
  //   [steps],
  // );

  // const onChangeNextStepType = (val: NextStepType) => {
  //   // 切换为下个步骤时, 置空人工选择的步骤
  //   if (val === NextStepType.next) {
  //     form.setFieldsValue({ manualNextStepIds: [] });
  //   }
  //   forceUpdate();
  // };

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    items: _.compact([
      numberRuleInput,
      {
        label: `${typeName}名称`,
        name: 'name',
        rules: [
          { required: true },
          { max: 64, message: '不超过64个字符' },
          { validator: textValidator1 },
        ],
        render: () => <Input />,
      },
      !isGroup && {
        label: '执行权限',
        required: true,
        render: () => {
          const privilegeType = getValue('privilegeType');
          let name = '';
          let content = null;
          let useFormatProps = false;

          if (privilegeType === PrivilegeType.users) {
            name = 'authUsers';
            content = (
              <UserOrDepartmentSelectWithDialog isNewFormat labelInValue isMultiple isSelectUser />
            );
          } else if (privilegeType === PrivilegeType.departments) {
            name = 'authDepartments';
            content = (
              <UserOrDepartmentSelectWithDialog isNewFormat labelInValue isMultiple={false} />
            );
          } else if (privilegeType === PrivilegeType.roles) {
            name = 'authRoles';
            content = <RoleSelect />;
            useFormatProps = true;
          }
          return (
            <>
              <Form.Item name="privilegeType" rules={[{ required: true }]} noStyle>
                <Select options={privilegeTypeMappings} onChange={forceUpdate} />
              </Form.Item>
              {content ? (
                <Form.Item
                  name={name}
                  rules={[
                    {
                      required: true,
                      message: `请选择${lookup('sop.privilegeType', privilegeType)}`,
                    },
                  ]}
                  style={{ margin: '10px 0 0' }}
                  {...(useFormatProps ? arrayItemProps : {})}
                >
                  {content}
                </Form.Item>
              ) : null}
            </>
          );
        },
      },
      isGroup && {
        label: '步骤组类型',
        name: 'groupType',
        render: () => <Select options={groupTypeMappings} />,
      },
      {
        label: '复制至',
        name: 'parentId',
        rules: [{ required: true, message: '请选择欲复制至的步骤组' }],
        render: () => <TreeSelect treeData={groupTree} />,
      },
      { name: 'nextStepType', hidden: true, render: () => null },
      { name: 'id', hidden: true, render: () => null },
      { name: 'referenceId', hidden: true, render: () => null },
      { name: 'type', hidden: true, render: () => null },
      // !ancestorGroupProps.isParallel && {
      //   label: '后续步骤',
      //   name: 'nextStepType',
      //   rules: [{ required: true, message: '请选择后续步骤' }],
      //   render: () => (
      //     <Select
      //       options={getValue('parentId') === undefined ? [] : _.takeRight(nextStepTypeMappings, 2)}
      //       onChange={onChangeNextStepType}
      //     />
      //   ),
      // },
      // getValue('nextStepType') === NextStepType.specified && {
      //   label: '可选步骤',
      //   colon: false,
      //   name: 'manualNextStepIds',
      //   rules: [{ required: true, message: '请选择后续步骤' }],
      //   render: () => (
      //     <LimitableSelect
      //       options={manualNextStepOptions}
      //       mode="multiple"
      //       maxCount={7}
      //       allowClear
      //     />
      //   ),
      // },
      // {
      //   label: '结束后执行',
      //   name: 'execAfterFinish',
      //   render: () => <Input.TextArea />,
      // },
    ]),
  };

  const handleFinish = async () => {
    const values = await form.validateFields();

    setCopying(true);
    try {
      const resArr = await Promise.all([
        fetchCustomFieldDetailByCode({ fieldCode: 'code', objectCode: 'SubStep' }),
        fetchCustomFieldDetailByCode({ fieldCode: 'code', objectCode: 'SubStepGroup' }),
        fetchCustomFieldDetailByCode({ fieldCode: 'code', objectCode: 'SOPControl' }),
      ]);
      const [stepNumberRuleId, stepGroupNumberRuleId, controlNumberRuleId] = _.map(
        resArr,
        'data.numberRuleId',
      );

      await fetchSopStepCopyStep({
        ...extractIdsForStep(values),
        stepNumberRuleId,
        stepGroupNumberRuleId,
        controlNumberRuleId,
      });
      message.success('复制成功!');
      onOk();
      onCancel();
    } finally {
      setCopying(false);
    }
  };

  useEffect(() => {
    if (visible) {
      setRefreshMarker(Date.now());
      form.setFieldsValue({
        ...initValues,
        name: `${initValues?.name}-副本`,
        parentId: undefined,
        nextStepType: NextStepType.next,
      });
    }
  }, [initValues, visible]);

  return (
    <DataFormLayoutForModal
      visible={visible}
      onClose={onCancel}
      width={800}
      content={
        <DataFormLayout
          form={form}
          title={`复制${typeName}`}
          info={[baseInfo]}
          onCancel={onCancel}
          onFinish={handleFinish}
          confirmLoading={copying}
        />
      }
    />
  );
};

export default CopyStepModal;
