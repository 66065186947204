import React, { useState } from 'react';

interface Props {
  text: string;
  needWordCount?: boolean;
  defaultLength?: number; // 默认 100个字符
  openText?: string;
  closeText?: string;
}
const MultilineText = (props: Props) => {
  const {
    text,
    defaultLength = 100,
    needWordCount = false,
    openText = '展开全部',
    closeText = '收起全部',
  } = props;
  const [isOpenAll, setIsOpenAll] = useState(false);
  const reduceText = text?.substring(0, defaultLength);
  const textLength = text?.length;

  const renderOpen = () => {
    return (
      <a
        onClick={() => {
          setIsOpenAll(true);
        }}
      >
        {`... ${openText}`}
        {needWordCount && `（共${textLength}个字）`}
      </a>
    );
  };
  const renderPutAway = () => {
    return (
      <a
        onClick={() => {
          setIsOpenAll(false);
        }}
      >
        {`... ${closeText}`}
      </a>
    );
  };

  return (
    <>
      {text === '' || text === undefined || text === null ? (
        <div>-</div>
      ) : (
        <div>
          {isOpenAll ? text : reduceText}
          {textLength >= defaultLength && (isOpenAll ? renderPutAway() : renderOpen())}
        </div>
      )}
    </>
  );
};

export default MultilineText;
