import { useLocation } from 'react-router-dom';
import qs from 'qs';

import ScheduleShiftContent from './ScheduleShiftContent';
import _ from 'lodash';

/**
 * 排班弹窗
 * 排班有3个入口，新建排班制工作日历之后直接点排班按钮，点击编辑工作日历弹窗上的排班按钮，或者直接从列表的操作菜单中选择排班操作
 * 为了统一逻辑，3种都从外层获取calendarParams，拿到id, startTime, endTime用来请求加载排班详情数据
 */
export default function ScheduleShift() {
  const { search } = useLocation();
  let urlParams = qs.parse(search, { ignoreQueryPrefix: true });

  if (_.isEmpty(urlParams)) {
    const _urlParamsFromSession = JSON.parse(
      sessionStorage.getItem('schedule-shift-url-params') || '{}',
    );

    if (!_.isEmpty(_urlParamsFromSession)) {
      urlParams = _urlParamsFromSession;
    }
  } else {
    sessionStorage.setItem('schedule-shift-url-params', JSON.stringify(urlParams));
  }

  const { id, startTime, endTime } = urlParams;

  // 从url取到的日期字符串没有分隔符，重新format成前端参数
  const calendarParams = {
    id: Number(id),
    startTime: Number(startTime),
    endTime: Number(endTime),
  };

  return <ScheduleShiftContent calendarParams={calendarParams} />;
}
