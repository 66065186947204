/**
 *拆分计划订单
 */
import { useState } from 'react';
import { RouterProps } from 'react-router-dom';
import { map, debounce, get, isEmpty, cloneDeep, compact, head, sumBy } from 'lodash';
import { Button, Form, Input, DatePicker, message, InputNumber, Space } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import type { NamePath } from 'antd/lib/form/interface';
import Tag from 'antd/es/tag';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { validatorCheckTwoSidesTrim } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import { getMaterialAttrs, getMaterialUnitInfo } from 'src/entity/material';
import { replaceSign } from 'src/utils/constants';
import { fractionLengthCheck } from 'src/utils/formValidators';
import { arrayIsEmpty } from 'src/utils/array';
import { fetchPlanOrderBatchSplit, FetchPlanOrderBatchSplitRequest } from 'src/api/ytt/plo-domain';
import _Array from 'src/utils/dataTypes/array';
import _Time from 'src/utils/dataTypes/time';
import { getDepartmentDetail } from 'src/services/organization/userManage';
import { DataFormLayout, DataFormLayoutForModal } from 'src/layout';
import { BlSortFormList, TagList } from 'src/components';
import { appEnum } from 'src/dict';
import { gcTime } from 'src/utils';

import { ProductionTaskFormProps } from '../createAndEdit/index.type';
import SplitPlannOrder from './splitPlannOrder';
// eslint-disable-next-line import/no-cycle
import { handleBatchMessage } from '../list/plannedOrderList';

interface FeedingControlFormProps extends RouterProps {
  name: string;
  params: any; // 子项物料行已填写数据
  onCancel: () => void;
  onChange: () => void;
}

const SplitOrderForm = (props: FeedingControlFormProps) => {
  const { name, params, onCancel, onChange } = props;

  const [form] = Form.useForm<ProductionTaskFormProps>();

  const [splitProductionTasks, setSplitProductionTasks] = useState<FormListFieldData | undefined>(); // 拆分生产任务

  const [base, setBase] = useState<any>(); // 拆分生产任务

  /**
   * 获取行的初始值
   * @param defaultValue
   * @param field
   * @returns
   */
  const getDefaultValue = (field: FormListFieldData, defaultValue: unknown) => {
    if (get(params, [name, field.name])) return undefined;
    return defaultValue;
  };

  /**
   * 复制一行
   * @param fields
   */
  const copyRow = (fieldName: number) => {
    const formList = cloneDeep(form.getFieldValue(name));

    const baseItem = cloneDeep(formList[fieldName]);

    formList.splice(fieldName + 1, 0, baseItem);

    return formList;
  };

  // 校验重复
  const validateRepeat = (recordForm: any, filedName: NamePath, validateName: string) => {
    return (_rule: any, value: any) => {
      return new Promise((resolve, reject) => {
        if (!value) {
          return resolve(true);
        }
        const valueList = recordForm.getFieldValue(filedName);

        // 从 valueList 找出重复的项组成 []
        const list = valueList.map((el: any) => String(el?.[validateName]));

        const repeatList = _Array.findDuplicates(list);

        // value 如果存在 在重复项 [] 中，则 reject
        const condition = repeatList.indexOf(value);

        if (condition !== -1) {
          reject('已存在该选项！');
          return;
        }
        return resolve(true);
      });
    };
  };

  const splitFraction = (base: any) => {
    const { planOrderId } = base;
    const formList = cloneDeep(form.getFieldValue(name));
    // const baseItem = formList[field];

    const splitPoints =
      sumBy(
        formList.filter(
          (e: any) => e?.planOrderId === planOrderId && e?.isSplit && e?.plannedAmount,
        ),
        (item: any) => {
          return Number(item?.plannedAmount);
        },
      ) || 0;

    const basePlannedAmount = head(
      formList.filter((e: any) => e?.planOrderId === planOrderId && !e?.isSplit),
    );

    const list = map(formList, (item) => {
      if (item?.planOrderId === planOrderId && !item?.isSplit) {
        return {
          ...item,
          detachableFraction: basePlannedAmount?.canLaunchAmount - splitPoints, // 可拆分数
          splitPoints, // 本次拆分数
        };
      }
      return item;
    });

    form.setFieldsValue({
      [name]: list,
    });
  };

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '计划订单编号',
        dataIndex: 'planOrderCode',
        fixed: 'left',
        width: 200,
        render: (_text, field) => {
          const formList = form.getFieldValue([name]);

          const { isSplit = false, planOrderCode } = formList[field.name];

          if (isSplit) {
            return (
              <Space size={0}>
                {<Tag color="success">拆</Tag>}
                <Form.Item
                  name={[field.name, 'planOrderCode']}
                  fieldKey={[field.fieldKey, 'planOrderCode']}
                  style={{ marginBottom: '0' }}
                  rules={[
                    { required: true, message: '计划订单编号不能为空' },
                    { max: 255, message: '不超过255字符' },
                    { validator: validatorCheckTwoSidesTrim() },
                    { validator: validateRepeat(form, 'planOrderSplitItemList', 'planOrderCode') },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Space>
            );
          }

          return planOrderCode ?? replaceSign;
        },
      },
      {
        title: '物料编号',
        dataIndex: 'inputMaterialControl',
        width: 200,
        render: (_text, field) => {
          return (
            <Form.Item style={{ marginBottom: '0' }}>
              {form.getFieldValue([name, field.name, 'materialInfo', 'baseInfo', 'code']) ??
                replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料名称',
        dataIndex: 'inputBoundType',
        width: 200,
        render: (_text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              initialValue={getDefaultValue(field, appEnum.Bom.InputBoundType.percentage)}
            >
              {form.getFieldValue([name, field.name, 'materialInfo', 'baseInfo', 'name']) ??
                replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料分类',
        dataIndex: 'category',
        width: 150,
        render: (_text, field) => {
          return (
            <Form.Item noStyle>
              {form.getFieldValue([name, field.name, 'materialInfo', 'category', 'name']) ??
                replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料属性',
        dataIndex: 'attributes',
        width: 150,
        render: (_text, field) => {
          const material = form.getFieldValue([name, field.name, 'materialInfo']);

          return (
            <Form.Item noStyle>
              <TagList dataSource={getMaterialAttrs(material)} />
            </Form.Item>
          );
        },
      },
      {
        title: '计划生产数',
        dataIndex: 'plannedAmount',
        width: 150,
        render: (_text, field) => {
          const formList = form.getFieldValue([name]);

          const { isSplit = false, plannedAmount, materialInfo } = formList[field.name];

          const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
            materialInfo,
            form.getFieldValue([name, field.name, 'unitId']),
          );

          if (isSplit) {
            return (
              <Form.Item
                style={{ marginBottom: '0' }}
                fieldKey={[field.fieldKey, 'plannedAmount']}
                name={[field.name, 'plannedAmount']}
                rules={compact([
                  { required: true, message: '计划生产数必填' },
                  {
                    validator: (opt, val) => {
                      return new Promise<void>((resolve, reject) => {
                        const base = (val && val.toString()?.split('.')[0]) ?? val;

                        if (val <= 0) reject('计划生产数必须大于0');

                        if (base > 10000000) reject('计划生产数必须小于10000000');
                        return resolve();
                      });
                    },
                  },
                  enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
                ])}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="请输入"
                  onBlur={() => {
                    const formList = form.getFieldValue(name);

                    const baseItem = cloneDeep(formList[field.name]);

                    splitFraction(baseItem);
                  }}
                />
              </Form.Item>
            );
          }
          return plannedAmount?.amountDisplay ?? replaceSign;
        },
      },
      {
        title: '本次拆分数/可拆分数',
        dataIndex: 'splitPoint',
        width: 200,
        render: (_text, field) => {
          const formList = form.getFieldValue([name]);

          const { isSplit = false, splitPoints, detachableFraction } = formList[field.name];

          if (!isSplit) {
            return (
              <Form.Item
                name={[field.name, 'splitPoint']}
                fieldKey={[field.fieldKey, 'splitPoint']}
                style={{ marginBottom: '0' }}
              >
                {`${splitPoints}/${detachableFraction}` ?? replaceSign}
              </Form.Item>
            );
          }
          return replaceSign;
        },
      },
      {
        title: '单位',
        dataIndex: 'unitName',
        width: 200,
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'unitName']}
              fieldKey={[field.fieldKey, 'unitName']}
              style={{ marginBottom: '0' }}
              initialValue={form.getFieldValue([name, field.name, 'unitName'])}
            >
              {form.getFieldValue([name, field.name, 'unitName']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '计划开始时间',
        dataIndex: 'plannedStartTime',
        width: 220,
        render: (_text, field) => {
          const formList = form.getFieldValue([name]);

          const { isSplit = false, plannedStartTime } = formList[field.name];

          if (isSplit) {
            return (
              <Form.Item
                name={[field.name, 'plannedStartTime']}
                fieldKey={[field.fieldKey, 'plannedStartTime']}
                style={{ marginBottom: '0' }}
              >
                <DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />
              </Form.Item>
            );
          }
          return plannedStartTime ? _Time.format(plannedStartTime) : replaceSign;
        },
      },
      {
        title: '计划结束时间',
        dataIndex: 'plannedEndTime',
        width: 220,
        render: (_text, field) => {
          const formList = form.getFieldValue([name]);

          const { isSplit = false, plannedEndTime } = formList[field.name];

          if (isSplit) {
            return (
              <Form.Item
                name={[field.name, 'plannedEndTime']}
                fieldKey={[field.fieldKey, 'plannedEndTime']}
                style={{ marginBottom: '0' }}
                rules={[
                  {
                    validator: (_: any, value: any) => {
                      const startAt = form.getFieldValue([name, field.name, 'plannedStartTime']);

                      if (!value || startAt < value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('计划结束时间必须大于计划开始时间'));
                    },
                  },
                ]}
              >
                <DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />
              </Form.Item>
            );
          }
          return plannedEndTime ? _Time.format(plannedEndTime) : replaceSign;
        },
      },
      {
        title: '生产部门',
        dataIndex: 'productionDepartment',
        width: 220,
        render: (_text, field) => {
          const formList = form.getFieldValue([name]);

          const { isSplit = false, productionDepartment } = formList[field.name];

          if (isSplit) {
            return (
              <Form.Item
                name={[field.name, 'productionDepartment']}
                fieldKey={[field.fieldKey, 'productionDepartment']}
                style={{ marginBottom: '0' }}
              >
                <UserOrDepartmentSelectWithDialog
                  placeholder={'请选择'}
                  isMultiple={false}
                  onChange={(val: any) => {
                    if (!arrayIsEmpty(val)) {
                      /** 带出部门上的主管 */
                      getDepartmentDetail(val?.[0]?.value).then((res) => {
                        const { data } = res;

                        form.setFields([
                          {
                            name: [name, field.name, 'productionSupervisorId'],
                            value: [{ label: data?.owner?.name, value: data?.owner?.id }],
                          },
                        ]);
                      });
                    }
                  }}
                />
              </Form.Item>
            );
          }
          return head(productionDepartment)?.label ?? replaceSign;
        },
      },
      {
        title: '生产主管',
        dataIndex: 'productionSupervisorId',
        width: 220,
        render: (_text, field) => {
          const formList = form.getFieldValue([name]);

          const { isSplit = false, productionSupervisorId } = formList[field.name];

          if (isSplit) {
            return (
              <Form.Item
                name={[field.name, 'productionSupervisorId']}
                fieldKey={[field.fieldKey, 'productionSupervisorId']}
                style={{ marginBottom: 0, width: 150 }}
              >
                <UserOrDepartmentSelectWithDialog
                  placeholder="请选择"
                  isMultiple={false}
                  isSelectUser
                />
              </Form.Item>
            );
          }
          return head(productionSupervisorId)?.label ?? replaceSign;
        },
      },
      {
        title: '备注',
        dataIndex: 'remark',
        width: 200,
        render: (_text, field) => {
          const formList = form.getFieldValue([name]);

          const { isSplit = false, remark } = formList[field.name];

          if (isSplit) {
            return (
              <Form.Item
                name={[field.name, 'remark']}
                fieldKey={[field.fieldKey, 'remark']}
                style={{ marginBottom: '0' }}
              >
                <Input.TextArea
                  style={{ width: '100%' }}
                  maxLength={1000}
                  placeholder="请输入"
                  showCount
                  autoSize
                />
              </Form.Item>
            );
          }
          return remark ?? replaceSign;
        },
      },
      {
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width: 100,
        render: (_text, field) => {
          const formList = form.getFieldValue([name]);

          const { isSplit = false } = formList[field.name];

          if (!isSplit) {
            return (
              <Form.Item style={{ marginBottom: '0' }}>
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={() => {
                    // handleCopyClick(field);
                    setSplitProductionTasks(field);
                    setBase(form.getFieldValue([name, field.name]));
                  }}
                >
                  拆分
                </Button>
              </Form.Item>
            );
          }
          return (
            <Form.Item style={{ marginBottom: '0' }}>
              <Button
                style={{ padding: 0 }}
                type="link"
                onClick={() => {
                  let formList = [];

                  formList = copyRow(field.name);

                  form.setFieldsValue({
                    [name]: formList,
                  });

                  const baseItem = cloneDeep(formList[field.name]);

                  splitFraction(baseItem);
                }}
              >
                复制
              </Button>
            </Form.Item>
          );
        },
      },
    ];
  };

  const baseInfo = {
    items: [
      {
        isFullLine: true,
        render: () => (
          <BlSortFormList
            name={name}
            renderColumns={() => getColumns()}
            isNeedDrag={false}
            isNeedAdd={false}
            onDelete={(val: number) => {
              const formList = cloneDeep(form.getFieldValue(name));

              const baseItem = cloneDeep(formList[val]);

              let list: any[];

              if (!baseItem?.isSplit) {
                list = formList.filter((e: any) => e?.planOrderId !== baseItem?.planOrderId);
              }
              if (baseItem?.isSplit) {
                list = map(formList, (item) => {
                  if (item?.planOrderId === baseItem?.planOrderId && !item?.isSplit) {
                    return {
                      ...item,
                      detachableFraction: item.detachableFraction + Number(baseItem.plannedAmount),
                      splitPoints: item?.splitPoints - Number(baseItem?.plannedAmount),
                    };
                  }

                  return item;
                });
                list.splice(val, 1);
              }

              setTimeout(() => {
                form.setFieldsValue({
                  [name]: list,
                });
              });
            }}
          />
        ),
      },
    ],
  };

  /**
   * 格式化数据给接口
   * @param value
   * @returns
   */
  // : WorkOrderRequest
  const formatValueToApi = (value: any): FetchPlanOrderBatchSplitRequest => {
    const planOrderSplitItemList = map(params, (item) => {
      const newPlanOrder: [] = value.planOrderSplitItemList.filter(
        (e: any) => e.planOrderId === item?.planOrderId && e?.isSplit,
      );

      const newPlanOrderParams = map(
        newPlanOrder,
        ({
          plannedEndTime,
          planOrderCode,
          plannedStartTime,
          plannedAmount,
          productionDepartment,
          productionSupervisorId,
          remark,
        }) => ({
          planStartTime: plannedStartTime
            ? Number(gcTime.formatToUnix(plannedStartTime))
            : undefined,
          planFinishTime: plannedEndTime ? Number(gcTime.formatToUnix(plannedEndTime)) : undefined,
          planOrderCode,
          plannedAmount,
          productionDepartmentId: head(productionDepartment)?.value,
          productionSupervisorId: head(productionSupervisorId)?.value,
          remark,
        }),
      );

      if (!arrayIsEmpty(newPlanOrderParams)) {
        return {
          planOrderId: item?.planOrderId,
          newPlanOrderItems: newPlanOrderParams,
        };
      }

      return null;
    });

    return {
      planOrderSplitItemList: planOrderSplitItemList.filter((e) => e),
    };
  };

  const handleFinish = async () => {
    try {
      const formValue: any = await form.validateFields();

      if (arrayIsEmpty(formValue?.planOrderSplitItemList)) {
        message.error('拆分数量不可为空');
        return;
      }

      const value = await formatValueToApi(formValue);

      if (!arrayIsEmpty(value?.planOrderSplitItemList)) {
        fetchPlanOrderBatchSplit(value).then((res) => {
          handleBatchMessage('拆分', res.data);
          onCancel();
          onChange();
        });
        return;
      }
      message.error('暂无需要拆分的计划订单');
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const formatData = (params: any) => {
    return map(
      params,
      (
        {
          plannedStartTime,
          plannedEndTime,
          productionDepartment,
          productionSupervisor,
          plannedAmount,
          launchAmount,
          canLaunchAmount,
          ...rest
        },
        index,
      ) => ({
        ...rest,
        order: index,
        plannedStartTime: plannedStartTime ? _Time.formatUnixMoment(plannedStartTime) : undefined,
        plannedEndTime: plannedEndTime ? _Time.formatUnixMoment(plannedEndTime) : undefined,
        productionDepartment: productionDepartment
          ? [{ label: productionDepartment?.name, value: productionDepartment?.id }]
          : [],
        productionSupervisorId: productionSupervisor
          ? [{ label: productionSupervisor?.name, value: productionSupervisor?.id }]
          : [],
        plannedAmount,
        detachableFraction: canLaunchAmount
          ? canLaunchAmount?.amount
          : plannedAmount?.amount - launchAmount?.amount, // 可拆分数
        splitPoints: 0, // 本次拆分数
        canLaunchAmount: canLaunchAmount?.amount,
      }),
    );
  };

  return (
    <>
      <DataFormLayout
        form={form}
        info={[baseInfo]}
        title="拆分计划订单"
        onCancel={() => {
          onCancel();
        }}
        onFinish={debounce(handleFinish, 1500)}
        formProps={{
          preserve: true,
          initialValues: { [name]: formatData(params) },
        }}
      />
      <DataFormLayoutForModal
        visible={!isEmpty(splitProductionTasks)}
        onClose={() => {
          setSplitProductionTasks(undefined);
        }}
        content={
          <SplitPlannOrder
            form={form}
            name="splitOrder"
            field={splitProductionTasks}
            base={base}
            onChange={() => {
              setSplitProductionTasks(undefined);
              form.resetFields(['splitOrder']);
            }}
          />
        }
      />
    </>
  );
};

export default SplitOrderForm;
