import { useState } from 'react';
import { Form, message as Message } from 'antd';
import { RecordListLayout } from 'layout';
import { approvalFilterList, dataColumnsForApprovalList } from './columns';
import {
  fetchApprovalTaskListNeedHandling,
  fetchApprovalTaskApprove,
  fetchApprovalTaskReject,
  fetchApprovalTaskPreCheck,
} from 'src/api/ytt/metadata-domain/approvalTask';
import { formatDateTimeRangeToUnix } from 'src/utils/formatters/dateTime';
import { gcObject } from 'utils';
import { useHistory } from 'react-router-dom';
import authDict from 'src/utils/auth';
import { ApprovalListItemProps } from './constant';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import _, { filter } from 'lodash';
import { BlTooltip, SingleOperationLogComponent } from 'src/components';
import { toTaskDetail } from './navigate';
import _Array from 'src/utils/dataTypes/array';
import _Url from 'src/utils/url';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import { ApprovalTaskStatus } from 'src/dict/approval';

const PendingList = () => {
  const history = useHistory();
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [id, setId] = useState<number>(0);
  const refresh = () => setRefreshMarker(Date.now());
  const [form] = Form.useForm();

  const query = _Url.getParams();
  const { tabKey } = query;

  const handleApproveAgain = async (id: number, type: string) => {
    // 通过审批
    try {
      if (!id) return;

      const { code: Code, message: errorMessage } = await fetchApprovalTaskPreCheck({ id });

      if (Code !== 200) return Message.warning(errorMessage);

      const fetchFn = type === 'pass' ? fetchApprovalTaskApprove : fetchApprovalTaskReject;

      const { code, message } = await fetchFn({
        approvalTaskId: id,
        approveComments: form.getFieldValue('comment'),
      });

      if (code === 200) {
        Message.success(`${type === 'pass' ? '通过审批' : '驳回审批'}`);
        refresh();
        return;
      }

      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  const handleApproveCheck = async (id: number, type: string) => {
    try {
      if (!id) return;

      const { code, message } = await fetchApprovalTaskPreCheck({ id }); // 点击通过/驳回以及再次进去审批的弹框点击确认 需要双重校验是否可以通过/驳回 防止多人同时操作

      if (code !== 200) return Message.warning(message);

      const title = type === 'pass' ? '确认要通过审批吗？' : '确认要驳回审批吗？';

      BLDelConfirm(
        title,
        form,
        () => handleApproveAgain(id, type),
        () => {},
        'comment',
        '请输入审批意见',
      );
    } catch (error) {
      console.log(error);
    }
  };

  const dataColumns = [
    {
      title: '编号',
      dataIndex: 'code',
      sorter: true,
      width: 150,
      render: (code: string, record: ApprovalListItemProps, index: number, config: any) => {
        return (
          <a
            onClick={() => {
              history.push(toTaskDetail(record.id!));
            }}
          >
            <BlTooltip text={code} width={config.contentWidth} />
          </a>
        );
      },
    },
    ...dataColumnsForApprovalList(tabKey).filter(
      (e: any) => e?.title !== '更新人' && e?.title !== '更新时间',
    ),
  ];

  const getOperationList = (record: ApprovalListItemProps) => {
    const operate = [
      {
        title: '通过',
        onClick: () => {
          handleApproveCheck(record.id ?? 0, 'pass');
        },
      },
      {
        title: '驳回',
        onClick: () => {
          handleApproveCheck(record.id ?? 0, 'reject');
        },
      },
      {
        title: '查看',
        onClick: () => {
          history.push(toTaskDetail(record.id!));
        },
      },
      {
        title: '操作记录',
        auth: authDict.approvalTask_operrecord,
        onClick: () => {
          setId(record?.id ?? -1);
          setVisibleLog(true);
        },
      },
    ];

    return record?.status === ApprovalTaskStatus.closed
      ? filter(operate, (e: any) => e?.title !== '通过' && e?.title !== '驳回')
      : operate;
  };

  const formatDataToQuery = (params: any) => {
    const { createdAt, creator, approvalCode, ...rest } = params || {};
    const options = {
      ...formatDateTimeRangeToUnix('createdAtBegin', 'createdAtEnd', createdAt),
      creatorId: _.isEmpty(creator) ? null : _.map(creator, 'value'),
      approvalCode: _Array.isEmpty(approvalCode) ? null : _.map(approvalCode, 'value'),
      ...rest,
    };

    return gcObject.filterEmptyProperty(options);
  };

  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (data: any) => {
    const { createdAt } = data;

    const retData = { ...data };

    if (createdAt) {
      retData.createdAt = momentData(createdAt);
    }

    return retData;
  };

  return (
    <>
      <RecordListLayout<ApprovalListItemProps>
        columns={dataColumns}
        filterList={approvalFilterList(tabKey).filter(
          (e: any) => e?.name !== 'operator' && e?.name !== 'updatedAt',
        )}
        rowKey={'id'}
        useIndex={false}
        configcacheKey={'approvalTaskPending'}
        getOperationList={getOperationList}
        formatDataToQuery={formatDataToQuery}
        requestFn={(params: any) => fetchApprovalTaskListNeedHandling({ status: [0], ...params })} // 列表初始默认只展示待审批的 筛选需要筛选已关闭的 所以在这里做初始化的筛选
        refreshMarker={refreshMarker}
        placeholder={'请输入编号、名称'}
        formatDataToFormDisplay={formatDataToFormDisplay}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          closeDetail={() => {
            setVisibleLog(false);
          }}
          visible={visibleLog}
          instanceId={id}
          objectCode={'ApprovalTask'}
        />
      )}
    </>
  );
};

export default PendingList;
