import React, { useState, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _, { isEmpty, debounce, chunk, flattenDepth, cloneDeep, map, filter } from 'lodash';
import moment from 'moment';
import { Form, message, Select } from 'antd';
import { ColumnProps } from 'antd/es/table';
import {
  BlIcon,
  SearchSelect,
  showErrorListMessage,
  TagList,
  Tooltip,
  SingleOperationLogComponent,
} from 'src/components';
import { RecordListLayout, DataFormLayoutForModal, DetailLayoutForModal } from 'src/layout';
import { fieldTypeList, gcArray, gcTime } from 'src/utils';
import lookup, { appEnum } from 'src/dict';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import _Time from 'src/utils/dataTypes/time';
import { replaceSign } from 'src/utils/constants';
import { arrayIsEmpty, intersectionValueOfId } from 'src/utils/array';
import {
  ProductionOrderTypesEnumDisplayMap,
  BusinessStatusEnumDisplayMap,
  WOCloseTypeDisplayMap,
} from 'src/dict/productionPlanning/mappings';
import { BusinessStatusEnum, SuspendedStatusEnum } from 'src/dict/productionPlanning';
import { SplitRequisitionOrigin } from 'src/dict/storeRequisition';
import BcBlMultiCascader from 'src/page/knowledgeManagement/materialModeling/material/share/Cascader/BcBlMultiCascader';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';

import {
  fetchWorkOrderBaseList,
  FetchWorkOrderDispatchInfoGetResponse,
  fetchWorkOrderDispatchInfoGet,
  fetchWorkOrderBatchFinish,
  fetchWorkOrderBatchClose,
  fetchWorkOrderPause,
  FetchWorkOrderBatchCloseResponse,
  fetchWorkOrderBatchUpdateDraftFlag,
  fetchWorkOrderContinue,
} from 'src/api/ytt/med-domain/work_order';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import BcBlCascader from 'src/components/Cascader';
import { getMaterialAttrs } from 'src/entity/material';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import {
  customLayoutChooseButton,
  customLayoutCreateButton,
} from 'src/components/customLayout/hooks/customLayoutForButton';
import { ObjectCode } from 'src/dict/common';
import showConfirmCloseOrder from 'src/page/productionPlanning/productionOrder/list/showConfirmCloseOrder';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import CpsiveReservationList from 'src/page/planned/reserve/comprehensiveReservation/list';
import { ReservedOrderType } from 'src/page/planned/planningOperation/constants';
import { fetchReservedSynthesizeSuperscriptData } from 'src/page/planned/reserve/utils';
//
import {
  toCreateProductionOrder,
  toEditProductionOrder,
  toDetailProductionOrder,
  toCopyProductionOrder,
  toLogProductionOrder,
} from '../navigation';

import SetProductionWorkOrder from './setProductionWorkOrder';

import { WorkOrderResponseList } from '../createAndEdit/index.type';
import ProductionTaskForm from '../creatProductionTask/ProductionTaskForm';
import { goToCreate } from '../../storeRequisition/utils';

import { FIELD_MAP } from '../constants';

interface userProps {
  id: number;
  name: string;
  code: string;
  avatarUrl: string;
}

/**
 * 处理批量操作提示message
 * @param action
 * @param info
 */
export const handleBatchMessage = (
  action: string,
  info: FetchWorkOrderBatchCloseResponse['data'],
) => {
  const { failAmount = 0, successAmount = 0, failResults = [] } = info ?? {};

  if (failAmount) {
    showErrorListMessage({
      failCount: failAmount,
      successCount: successAmount,
      title: `${action}出现失败`,
      data: failResults,
      columns: [
        {
          title: '工单编号',
          dataIndex: 'workOrderCode',
          width: 100,
          render: (text: string, record: any) => (
            <a onClick={() => window.open(toDetailProductionOrder(record.workOrderId))}>
              <Tooltip text={text} width={150} />
            </a>
          ),
        },
        { title: '失败原因', dataIndex: 'failedReason', width: 200 },
        { title: '', dataIndex: 'workOrderId', width: 1, render: () => null },
      ],
    });
  } else {
    message.success(`${action}成功！`);
  }
};

const ProductionOrderList = (props: { history: any }) => {
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [IssueTask, setIssueTask] = useState<FetchWorkOrderDispatchInfoGetResponse['data']>();
  const [ProductionWorkOrderSetting, setProductionWorkOrderSetting] = useState<any>(false);
  const [operationRecordVisible, setOperationRecordVisible] = useState(false);
  const [datasetId, setDataSetId] = useState<number>(0);
  const [cpsiveReservationList, setCpsiveReservationList] = useState<any>(false);

  const openModal = useOpenExImportModal();
  const [formCloseOrderConFirm] = Form.useForm();
  const dispatch = useDispatch();

  const { history } = props;

  const refreshData = () => {
    setRefreshMarker(Math.random());
  };

  useEffect(() => {
    refreshData();
  }, [history, props]);

  const formatDataToDisplay = (data: any) => {
    const { createdAt, updatedAt, plannedStartTime, plannedEndTime, ...resData } = cloneDeep(data);

    return {
      ...resData,
      createdAt: !gcArray.isEmpty(createdAt) ? createdAt : undefined,
      updatedAt: !gcArray.isEmpty(updatedAt) ? updatedAt : undefined,
      plannedStartTime: !gcArray.isEmpty(plannedStartTime) ? plannedStartTime : undefined,
      plannedEndTime: !gcArray.isEmpty(plannedEndTime) ? plannedEndTime : undefined,
    };
  };

  const formatDataToQuery = (data: any) => {
    const {
      plannedStartTime = [],
      plannedEndTime = [],
      workOrderTypeList,
      workOrderStatusList,
      pauseFlag,
      productionDepartmentIdList,
      productionSupervisorIdList,
      planningDepartmentIdList,
      planningUserIdList,
      sorter,
      materialCode,
      materialName,
      bomIdList,
      processRouteIdList,
      creatorIdList,
      operatorIdList,
      createdAt,
      updatedAt,
      workOrderCode,
      shiftIdList,
      resourceIdList,
      ...resData
    } = cloneDeep(data);

    const relParams = {
      ...resData,
    };

    if (sorter) {
      relParams.sorter = map(sorter, (node: any) => {
        return {
          order: node?.order,
          field: FIELD_MAP.get(node?.field),
        };
      });
    }

    const params = {
      ...resData,
      ...relParams,
      workOrderTypeList,
      workOrderStatusList,
      pauseFlag: !arrayIsEmpty(pauseFlag) ? pauseFlag[0] : undefined,
      plannedEndTimeFrom: !gcArray.isEmpty(plannedEndTime)
        ? moment(plannedEndTime[0]).format('x')
        : undefined,
      plannedEndTimeTo: !gcArray.isEmpty(plannedEndTime)
        ? moment(plannedEndTime[1]).format('x')
        : undefined,
      plannedStartTimeFrom: !gcArray.isEmpty(plannedStartTime)
        ? moment(plannedStartTime[0]).format('x')
        : undefined,
      plannedStartTimeTo: !gcArray.isEmpty(plannedStartTime)
        ? moment(plannedStartTime[1]).format('x')
        : undefined,
      productionDepartmentIdList: !isEmpty(productionDepartmentIdList)
        ? [productionDepartmentIdList[0]?.value]
        : undefined,

      createdAtTo: !gcArray.isEmpty(createdAt) ? moment(createdAt[1]).format('x') : undefined,
      createdAtFrom: !gcArray.isEmpty(createdAt) ? moment(createdAt[0]).format('x') : undefined,

      updatedAtTo: !gcArray.isEmpty(updatedAt) ? moment(updatedAt[1]).format('x') : undefined,
      updatedAtFrom: !gcArray.isEmpty(updatedAt) ? moment(updatedAt[0]).format('x') : undefined,

      productionSupervisorIdList: !isEmpty(productionSupervisorIdList)
        ? [productionSupervisorIdList[0]?.value]
        : undefined,
      planningDepartmentIdList: !isEmpty(planningDepartmentIdList)
        ? [planningDepartmentIdList[0]?.value]
        : undefined,
      planningUserIdList: !isEmpty(planningUserIdList) ? [planningUserIdList[0]?.value] : undefined,
      bomIdList: !arrayIsEmpty(bomIdList) ? map(bomIdList, 'value') : undefined,
      processRouteIdList: !arrayIsEmpty(processRouteIdList)
        ? map(processRouteIdList, 'value')
        : undefined,
      creatorIdList: creatorIdList ? [creatorIdList?.key] : undefined,
      operatorIdList: operatorIdList ? [operatorIdList?.key] : undefined,
      workOrderCode: workOrderCode ?? undefined,
      mainOutputMaterialIdList: intersectionValueOfId(materialCode, materialName),
      shiftIdList: shiftIdList ? _.compact(shiftIdList).map((item: any) => item.value) : undefined,
      resourceIdList: resourceIdList
        ? _.compact(resourceIdList).map((item: any) => JSON.parse(item?.value ?? '{}')?.id)
        : undefined,
    };

    dispatch.excelImport.updateBusinessData({ businessData: params });
    return params;
  };

  const formatDataToFormDisplay = (data: any) => {
    const { plannedStartTime, plannedEndTime, ...rest } = data;

    return {
      ...rest,
      plannedStartTime: plannedStartTime && gcTime.formatRangeTimeToMoment(plannedStartTime),
      plannedEndTime: plannedEndTime && gcTime.formatRangeTimeToMoment(plannedEndTime),
    };
  };

  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      auth: authDict.productionorder_export,
      icon: <BlIcon type="icondaochu" />,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.productionOrder,
        });
      },
    },

    // items: [
    {
      title: '导入',
      showExport: true,
      auth: authDict.productionorder_import,
      onClick: () => {
        openModal({
          optType: 'import',
          businessType: BUSINESS_TYPE.productionOrder,
        });
      },
    },
    {
      title: '设置',
      auth: authDict.productionorder_set_produce_order,
      onClick: () => {
        // SetProductionWorkOrder
        setProductionWorkOrderSetting(true);
      },
    },
    {
      title: '下发日志',
      onClick: () => {
        history.push(toLogProductionOrder());
      },
    },
    // ],
    {
      title: '新建生产工单',
      key: 'create',
      auth: authDict.productionorder_add,
      customRender: customLayoutCreateButton({
        title: '新建生产工单',
        icon: <BlIcon type="iconxinjiantianjia" />,
        objectCode: ObjectCode.POConfig,
        type: 'create',
        onClick: (layoutId) => {
          history.push(toCreateProductionOrder(undefined, layoutId));
        },
      }),
    },
  ];

  const batchMenu = [
    {
      title: '关闭',
      key: 'close',
      auth: authDict.productionorder_close,
      onClick: () => {
        return new Promise((resolve, reject) => {
          showConfirmCloseOrder(formCloseOrderConFirm, () => {
            return fetchWorkOrderBatchClose({
              workOrderCloseType: formCloseOrderConFirm.getFieldValue('workOrderCloseType'),
              operateReason: formCloseOrderConFirm.getFieldValue('operateReason'),
              workOrderIdList: selectedRows.map((e) => e.workOrderId),
            })
              .then((res) => {
                formCloseOrderConFirm.resetFields();
                handleBatchMessage('关闭', res.data);
                resolve(true);
              })
              .catch(() => {
                reject(false);
              });
          });
        });
      },
    },
    {
      title: '完工',
      key: 'finish',
      auth: authDict.productionorder_progress_finish,
      onClick: () => {
        return fetchWorkOrderBatchFinish({
          idList: selectedRows.map((e) => e.workOrderId),
        }).then((res) => {
          handleBatchMessage('完工', res.data);
        });
      },
    },
    {
      title: '下发',
      key: 'delete',
      auth: authDict.productionorder_dispatch,
      onClick: () => {
        // 需要下发的workOrderId集合
        const allWorkOrderIds = selectedRows.map((e) => e.workOrderId).filter(Boolean);
        // 拆分数组
        const chunkIds = chunk(allWorkOrderIds, 10);

        const fetchRequestList = chunkIds.map(async (item) => {
          return await fetchWorkOrderDispatchInfoGet({
            workOrderIds: item,
          }).then((res) => {
            const { data } = res;

            return data;
          });
        });

        Promise.all(fetchRequestList)
          .then((res) => {
            setIssueTask(flattenDepth(res, 1));
          })
          .catch((err) => {
            console.log('err: ', err);
          });
        return Promise.resolve();
      },
    },
    {
      title: '申请领料',
      key: 'delete',
      onClick: (onSuccess: any, onFail: any) => {
        if (selectedRowKeys?.length > 200) {
          message.error('最多支持选择200个');
          onFail?.();
        } else {
          const checkCanStoreRequisitionFilterState = filter(selectedRows, (node: any) => {
            return (
              [
                BusinessStatusEnum.CREATED,
                BusinessStatusEnum.IN_PROGRESS,
                BusinessStatusEnum.SCHEDULED,
              ].includes(node?.workOrderStatus) &&
              [SuspendedStatusEnum.NOTSUSPENDED].includes(node?.pauseFlag) &&
              node?.specifiedMaterial === appEnum.Common.YN.yes
            );
          });

          if (arrayIsEmpty(checkCanStoreRequisitionFilterState)) {
            message.error('暂无可领料数据');
            return onFail?.();
          }

          const ids = checkCanStoreRequisitionFilterState.map((e) => e.workOrderId);

          goToCreate(ids, SplitRequisitionOrigin.productionOrder);
        }
      },
    },
    {
      title: '提交',
      key: 'submit',
      onClick: (_onSuccess: any, onFail: any) => {
        const checkCanUpdateDraftFlag = filter(selectedRows, (node: any) => {
          return [BusinessStatusEnum.DRAFT].includes(node?.workOrderStatus);
        });

        if (arrayIsEmpty(checkCanUpdateDraftFlag)) {
          message.error('暂无可提交数据');
          return onFail?.();
        }

        return fetchWorkOrderBatchUpdateDraftFlag({
          idList: checkCanUpdateDraftFlag.map((e) => e.workOrderId),
        }).then((res) => {
          handleBatchMessage('提交', { ...res.data, successAmount: res.data?.successCount ?? 0 });
        });
      },
    },
  ];

  const columns: (ColumnProps<WorkOrderResponseList> & {
    filterIndex?: string;
    type?: number;
    isFilter?: boolean;
    selectProps?: any;
    renderItem?: ReactElement;
    dateFormat?: any;
  })[] = [
    {
      title: '工单编码',
      dataIndex: 'workOrderCode',
      width: 150,
      isFilter: true,
      sorter: true,
    },
    {
      title: '工单类型',
      dataIndex: 'workOrderType',
      filterIndex: 'workOrderTypeList',
      width: 150,
      render: (text: any) => lookup('productionPlanning.ProductionOrderTypesEnumDisplayMap', text),
      isFilter: true,
      renderItem: (
        <Select
          placeholder={'请选择工单类型'}
          options={ProductionOrderTypesEnumDisplayMap}
          allowClear
          mode="multiple"
        />
      ),
    },
    {
      title: '业务状态',
      dataIndex: 'workOrderStatus',
      filterIndex: 'workOrderStatusList',
      width: 150,
      render: (text: any, record: any) => {
        if (text === BusinessStatusEnum.CLOSED) {
          return `${lookup('productionPlanning.BusinessStatusEnumDisplayMap', text)}(${lookup(
            'productionPlanning.WorkOrderCloseTypeMap',
            record.workOrderCloseType,
          )})`;
        }
        return lookup('productionPlanning.BusinessStatusEnumDisplayMap', text);
      },
      isFilter: true,
      sorter: true,
      renderItem: (
        <Select
          placeholder={'请选择业务状态'}
          options={BusinessStatusEnumDisplayMap}
          allowClear
          mode="multiple"
        />
      ),
    },
    {
      title: '暂停状态',
      dataIndex: 'pauseFlag',
      width: 150,
      render: (text: any) => lookup('productionPlanning.SuspendedStatusEnumDisplayMap', text),
      isFilter: true,
      type: fieldTypeList.boolean,
    },
    // {
    //   title: '领料状态',
    //   dataIndex: ['materialIssueStatus'],
    //   width: 150,
    //   isFilter: true,
    //   type: fieldTypeList.boolean,
    //   renderItem: (
    //     <Select placeholder={'请选择领料状态'} options={ItemTypeEnumDisplayMap} allowClear />
    //   ),
    // },
    {
      title: '计划开始时间',
      dataIndex: 'plannedStartTime',
      width: 180,
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: (text: any) => (text ? _Time.format(text) : replaceSign),
    },
    {
      title: '计划完工时间',
      dataIndex: 'plannedEndTime',
      width: 180,
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: (text: any) => (text ? _Time.format(text) : replaceSign),
    },
    {
      title: '设备',
      dataIndex: ['resource', 'name'],
      filterIndex: 'resourceIdList',
      width: 100,
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="resourceCompleteInfo"
          params={{ parentFlag: true }}
          mode="multiple"
        />
      ),
    },
    {
      title: '班次',
      dataIndex: ['shift', 'name'],
      filterIndex: 'shiftIdList',
      width: 100,
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="shiftListNoRest"
          placeholder="请输入"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      title: '生产部门',
      dataIndex: 'productionDepartment',
      filterIndex: 'productionDepartmentIdList',
      width: 150,
      render: (productionDepartment: userProps) => {
        if (!productionDepartment) return replaceSign;
        return (
          <AvatarDisplay
            id={productionDepartment?.id}
            name={productionDepartment?.name}
            key={productionDepartment?.id}
            isShowTag
            isUser
          />
        );
      },
      isFilter: true,
      renderItem: <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple={false} />,
    },
    {
      title: '生产主管',
      dataIndex: 'productionSupervisor',
      filterIndex: 'productionSupervisorIdList',
      width: 150,
      render: (productionSupervisor: userProps) => {
        if (!productionSupervisor) return replaceSign;
        return (
          <AvatarDisplay
            id={productionSupervisor?.id}
            name={productionSupervisor?.name}
            key={productionSupervisor?.id}
            avatarUrl={productionSupervisor?.avatarUrl}
            isShowTag
            isUser
          />
        );
      },
      isFilter: true,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: '计划部门',
      dataIndex: 'planningDepartment',
      filterIndex: 'planningDepartmentIdList',

      width: 150,
      render: (planningDepartment: userProps) => {
        if (!planningDepartment) return replaceSign;
        return (
          <AvatarDisplay
            id={planningDepartment?.id}
            name={planningDepartment?.name}
            key={planningDepartment?.id}
            isShowTag
            isUser
          />
        );
      },
      isFilter: true,
      renderItem: <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple={false} />,
    },
    {
      title: '计划员',
      dataIndex: 'planningUser',
      filterIndex: 'planningUserIdList',
      width: 150,
      render: (planningUser: userProps) => {
        if (!planningUser) return replaceSign;
        return (
          <AvatarDisplay
            id={planningUser?.id}
            name={planningUser?.name}
            key={planningUser?.id}
            avatarUrl={planningUser?.avatarUrl}
            isShowTag
            isUser
          />
        );
      },
      isFilter: true,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: '物料编号',
      dataIndex: ['materialInfo', 'baseInfo', 'code'],
      filterIndex: 'materialCode',
      width: 150,
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="material"
          labelPath="code"
          valuePath="id"
          placeholder="请输入"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      title: '物料名称',
      dataIndex: ['materialInfo', 'baseInfo', 'name'],
      filterIndex: 'materialName',
      width: 150,
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="material"
          labelPath="name"
          valuePath="id"
          placeholder="请输入"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      title: '物料属性',
      dataIndex: ['materialInfo', 'attribute'],
      filterIndex: 'materialAttributeIds',
      type: fieldTypeList.text,
      renderItem: <BcBlMultiCascader type={'materialProperty'} nameLabel={'物料属性'} />,
      width: 150,
      render: (_text: any, record: any) => (
        <TagList dataSource={getMaterialAttrs(record.materialInfo)} />
      ),
    },
    {
      title: '物料分类',
      dataIndex: ['materialInfo', 'category', 'name'],
      filterIndex: 'materialCategoryIds',
      type: fieldTypeList.text,
      renderItem: (
        <BcBlCascader
          fetchType={'materialCategory'}
          nameLabel={'物料分类'}
          popupPlacement={'bottomRight'}
        />
      ),
      width: 150,
    },
    {
      title: '计划生产数',
      dataIndex: ['plannedAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '预计生产数',
      dataIndex: ['expectedAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '领料齐套数',
      dataIndex: ['pickOrderPreparedAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '总报工数',
      dataIndex: ['totalHoldAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '合格报工数',
      dataIndex: ['qualifiedHoldAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '不合格报工数',
      dataIndex: ['disqualifiedHoldAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '入库数',
      dataIndex: ['warehousedAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '批次号',
      dataIndex: 'mainOutputBatchNumber',
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: '版本号',
      dataIndex: ['bomVersion'],
      width: 150,
      isFilter: true,
      filterIndex: 'bomIdList',
      renderItem: <SearchSelect fetchType={'bomVersions'} labelInValue mode="multiple" />,
    },
    {
      title: '工艺路线编号',
      dataIndex: ['processRoute', 'code'],
      filterIndex: 'processRouteIdList',
      width: 150,
      isFilter: true,
      renderItem: (
        <SearchSelect fetchType={'processRouting'} labelPath="code" labelInValue mode="multiple" />
      ),
    },
    {
      title: '外部单据编号',
      dataIndex: 'externalOrderCode',
      width: 150,
      isFilter: true,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      filterIndex: 'creatorIdList',
      renderItem: <SearchSelect fetchType="user" labelInValue />,
      isFilter: true,
      width: 150,
      render: (text) => {
        const { id, name, avatarUrl } = text || {};

        return (
          id && (
            <AvatarDisplay
              id={id}
              name={name ?? ''}
              key={id}
              avatarUrl={avatarUrl}
              isShowTag={false}
              isUser
            />
          )
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      sorter: true,
      type: fieldTypeList.date,
      isFilter: true,
      width: 180,
      render: (text: any) => _Time.format(text),
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      filterIndex: 'operatorIdList',
      isFilter: true,
      renderItem: <SearchSelect fetchType="user" labelInValue />,
      width: 150,
      render: (text) => {
        const { id, name, avatarUrl } = text || {};

        return (
          id && (
            <AvatarDisplay
              id={id}
              name={name ?? ''}
              key={id}
              avatarUrl={avatarUrl}
              isShowTag={false}
              isUser
            />
          )
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      type: fieldTypeList.date,
      isFilter: true,
      width: 180,
      render: (text: any) => _Time.format(text),
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
  ];

  const getFilterList = () => {
    const FilterColumns = columns
      .filter((i) => i.isFilter)
      .map((column: any) => {
        const filter: any = {
          label: column.title,
          name: column.filterIndex || column.dataIndex,
          type: column.type,
          rules: column.rules,
          renderItem: column.renderItem,
          dateFormat: column.dateFormat,
        };

        if (column.type === fieldTypeList.select || column.type === fieldTypeList.multiSelect) {
          filter.selectProps = column.selectProps;
        }
        return filter;
      });

    FilterColumns.push({
      label: '工单关闭类型',
      name: 'closeTypeList',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <Select
          placeholder={'请选择工单关闭类型'}
          options={WOCloseTypeDisplayMap}
          allowClear
          mode="multiple"
        />
      ),
    });

    return FilterColumns;
  };

  const getOperationList = (
    record: any,
  ): {
    title: string;
    auth?: string;
    onClick: () => void;
    disable?: boolean;
  }[] => {
    const { workOrderId: id, pauseFlag, workOrderStatus, specifiedMaterial } = record;

    // 申请领料条件
    const disabledFlag =
      [
        BusinessStatusEnum.CREATED,
        BusinessStatusEnum.IN_PROGRESS,
        BusinessStatusEnum.SCHEDULED,
      ].includes(workOrderStatus) &&
      [SuspendedStatusEnum.NOTSUSPENDED].includes(pauseFlag) &&
      specifiedMaterial === appEnum.Common.YN.yes;

    // 下发 暂停条件
    const issuedConditions =
      [SuspendedStatusEnum.NOTSUSPENDED].includes(pauseFlag) &&
      [
        BusinessStatusEnum.CREATED,
        BusinessStatusEnum.IN_PROGRESS,
        BusinessStatusEnum.SCHEDULED,
      ].includes(workOrderStatus);

    // 继续条件
    const continueConditions =
      [SuspendedStatusEnum.PAUSED].includes(pauseFlag) &&
      [
        BusinessStatusEnum.CREATED,
        BusinessStatusEnum.IN_PROGRESS,
        BusinessStatusEnum.SCHEDULED,
      ].includes(workOrderStatus);

    const list: any = [
      {
        title: '查看',
        customRender: customLayoutChooseButton({
          title: '查看',
          objectCode: ObjectCode.POConfig,
          type: 'detail',
          instanceId: id,
          onClick: (layoutId: number) => {
            // 组件会返回选择的布局ID, 如果只有一个就是唯一的一个布局
            history.push(toDetailProductionOrder(record.workOrderId, layoutId));
          },
        }),
      },
      {
        title: '编辑',
        auth: authDict.productionorder_edit,
        customRender: customLayoutChooseButton({
          title: '编辑',
          objectCode: ObjectCode.POConfig,
          type: 'edit',
          instanceId: id,
          onClick: (layoutId: number) => {
            history.push(toEditProductionOrder(id, layoutId));
          },
        }),
      },
      {
        title: '复制',
        auth: authDict.productionorder_edit,
        customRender: customLayoutChooseButton({
          title: '复制',
          objectCode: ObjectCode.POConfig,
          type: 'edit',
          instanceId: id,
          onClick: (layoutId: number) => {
            history.push(toCopyProductionOrder(id, layoutId));
          },
        }),
      },
      {
        title: '关闭',
        auth: authDict.productionorder_close,
        disabled: workOrderStatus === BusinessStatusEnum.CLOSED,
        onClick: () => {
          try {
            showConfirmCloseOrder(formCloseOrderConFirm, () => {
              fetchWorkOrderBatchClose({
                workOrderCloseType: formCloseOrderConFirm.getFieldValue('workOrderCloseType'),
                operateReason: formCloseOrderConFirm.getFieldValue('operateReason'),
                workOrderIdList: [id],
              }).then((res) => {
                formCloseOrderConFirm.resetFields();
                handleBatchMessage('关闭', res.data);
              });
            });
          } catch (e) {
            // @ts-ignore
          }
        },
      },
      {
        title: '完工',
        auth: authDict.productionorder_progress_finish,
        onClick: debounce(async () => {
          await fetchWorkOrderBatchFinish({
            idList: [id],
          }).then((res) => {
            handleBatchMessage('完工', res.data);
          });
        }, 1500),
      },
      {
        title: '申请领料',
        disabled: !disabledFlag,
        onClick: debounce(async () => {
          await goToCreate([id], SplitRequisitionOrigin.productionOrder);
        }, 1500),
      },
      {
        title: '操作记录',
        auth: authDict.productionorder_operrecord,
        onClick: () => {
          setDataSetId(record?.workOrderId);
          setOperationRecordVisible(true);
        },
      },
    ];

    if (![BusinessStatusEnum.COMPLETED, BusinessStatusEnum.CLOSED].includes(workOrderStatus)) {
      list.push({
        title: '编辑',
        auth: authDict.productionorder_edit,
        customRender: customLayoutChooseButton({
          title: '编辑',
          objectCode: ObjectCode.POConfig,
          type: 'edit',
          instanceId: id,
          onClick: (layoutId: number) => {
            history.push(toEditProductionOrder(record.workOrderId, layoutId));
          },
        }),
      });
    }

    if (issuedConditions) {
      list.push({
        title: '下发',
        auth: authDict.productionorder_dispatch,
        onClick: debounce(async () => {
          await fetchWorkOrderDispatchInfoGet({
            workOrderIds: [id],
          }).then((res) => {
            const { data } = res;

            setIssueTask(data);
          });
        }, 1500),
      });
    }
    if (issuedConditions) {
      list.push({
        title: '暂停',
        auth: authDict.productionorder_stop,
        onClick: debounce(async () => {
          await fetchWorkOrderPause({
            workOrderId: id,
          }).then((res) => {
            const { code = 0 } = res ?? {};

            if (code === 200) refreshData();

            message.success('暂停成功!');
          });
        }, 1500),
      });
    }

    if (continueConditions) {
      list.push({
        title: '继续',
        auth: authDict.productionorder_continue,
        onClick: debounce(async () => {
          await fetchWorkOrderContinue({
            workOrderId: id,
          }).then((res) => {
            const { code = 0 } = res ?? {};

            if (code === 200) refreshData();
            message.success('继续成功!');
          });
        }, 1500),
      });
    }

    if ([BusinessStatusEnum.DRAFT].includes(workOrderStatus)) {
      list.push({
        title: '提交',
        // auth: authDict.knowledge_eng_data_work_center_add_edit,
        onClick: debounce(async () => {
          await fetchWorkOrderBatchUpdateDraftFlag({
            idList: [id],
          }).then((res) => {
            handleBatchMessage('提交', { ...res.data, successAmount: res.data?.successCount ?? 0 });
          });
        }, 1500),
      });
    }

    if (![BusinessStatusEnum.CLOSED].includes(workOrderStatus)) {
      list.push({
        title: '综合查询',
        auth: authDict.PlanReserved_detail,
        onClick: debounce(async () => {
          fetchReservedSynthesizeSuperscriptData({
            orderId: id,
            orderType: ReservedOrderType.PRODUCT_ORDER,
          }).then((reservedCommonData) => {
            if (!reservedCommonData) {
              message.error('暂无预留');
              return;
            }
            setCpsiveReservationList({
              data: reservedCommonData,
              param: {
                orderId: id,
                orderType: ReservedOrderType.PRODUCT_ORDER,
              },
            });
          });
        }, 1500),
      });
    }

    return list;
  };

  return (
    <>
      <RecordListLayout<WorkOrderResponseList>
        configcacheKey="workOrderList"
        filterList={getFilterList()}
        requestFn={(params) => {
          return fetchWorkOrderBaseList(params, { legacy: true });
        }}
        // requestFn={fetchWorkOrderBaseList}
        mainMenu={mainMenu}
        batchMenu={batchMenu}
        placeholder={'请输入工单编码'} // 快速搜索input placeholder
        formatDataToQuery={formatDataToQuery} // 转换搜索条件进行查询（快速查询字段："quickSearch",当前页："page",分页大小："size"）
        formatDataToFormDisplay={formatDataToFormDisplay}
        formatDataToDisplay={formatDataToDisplay} // 转换搜索条件进行展示
        columns={columns} // table columns
        selectedRowKeys={selectedRowKeys}
        refreshMarker={refreshMarker}
        rowKey="workOrderId"
        onSelectedRowKeys={(selectKey, selectRows) => {
          setSelectedRowKeys(selectKey);
          setSelectedRows(selectRows || []);
        }}
        pagination={{ pageSizeOptions: [10, 20] }}
        userAuth={getAuthFromLocalStorage()}
        getOperationList={getOperationList}
        maxOperationCount={3}
      />
      <DataFormLayoutForModal
        visible={ProductionWorkOrderSetting}
        onClose={() => {
          setProductionWorkOrderSetting(undefined);
        }}
        content={
          <SetProductionWorkOrder
            onCancel={() => {
              setProductionWorkOrderSetting(undefined);
            }}
          />
        }
      />
      <DataFormLayoutForModal
        visible={!isEmpty(IssueTask)}
        onClose={() => {
          setIssueTask(undefined);
        }}
        content={
          <ProductionTaskForm
            name="dispatchRequests"
            params={IssueTask}
            history={history}
            onCancel={() => {
              setIssueTask(undefined);
            }}
          />
        }
      />
      {operationRecordVisible && (
        <SingleOperationLogComponent
          visible={operationRecordVisible}
          instanceId={datasetId}
          objectCode={'ProductionOrder'}
          closeDetail={() => {
            setOperationRecordVisible(false);
          }}
        />
      )}
      <DetailLayoutForModal
        visible={cpsiveReservationList}
        onClose={() => {
          setCpsiveReservationList(false);
        }}
        content={
          <CpsiveReservationList
            parameter={cpsiveReservationList?.param}
            initData={cpsiveReservationList?.data}
          />
        }
      />
    </>
  );
};

export default ProductionOrderList;
