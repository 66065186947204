import React from 'react';
import { BlTable } from '@blacklake-web/component';
import { getStandardValue, LOGIC } from 'src/page/quality/models/constants';
import { CheckCountTypeEnum, CheckItemInputTypeEnum, CheckTypeEnum } from 'src/dict/quality';

import { CheckItem } from 'src/page/quality/models/dto';
import lookup, { appEnum } from 'src/dict';
import _ from 'lodash';
import { BcAttachmentDetail } from 'src/components';
import { injectCustomFieldColumns } from 'src/components/customField';
import { ICustomFields } from 'src/components/customField/interface';

export const CheckItemListTable = (props: {
  data: CheckItem[];
  checkType?: number;
  customFields: ICustomFields;
}) => {
  const { data, checkType, customFields } = props;

  const renderTableArray = (list: string | string[] | number[]) => {
    if (list instanceof Array) {
      return list?.join(',') || '-';
    }
    return list || '-';
  };

  const getColumns = () => {
    return injectCustomFieldColumns({
      columns: _.compact([
        { dataIndex: 'groupName', title: '检验项分组', fixed: 'left', width: 120 },
        { dataIndex: 'checkItemName', title: '检验项名称', fixed: 'left', width: 100 },
        {
          dataIndex: 'checkItemCategoryName',
          title: '检验项分类',
          fixed: 'left',
          width: 100,
        },
        {
          dataIndex: 'executeItemType',
          title: '检验项格式',
          width: 100,
          render: (value: CheckItemInputTypeEnum) => {
            if (
              value === CheckItemInputTypeEnum.inputFormatText ||
              value === CheckItemInputTypeEnum.multipleText
            ) {
              return lookup('quality.TextInputType', value);
            }
            return lookup('quality.CheckItemInputType', value);
          },
        },
        checkType !== CheckTypeEnum.generalQc && {
          dataIndex: 'checkCountType',
          title: '抽检类型',
          width: 100,
          render: lookup('quality.CheckCountType'),
        },
        checkType !== CheckTypeEnum.generalQc && {
          dataIndex: 'checkCount',
          title: '抽检数量',
          width: 100,
          render: (checkCount: number, record: CheckItem) => {
            if (
              record.checkCountType === CheckCountTypeEnum.ratioCheck ||
              record.checkCountType === CheckCountTypeEnum.customCheck
            ) {
              if (typeof checkCount === 'number') {
                return `${checkCount}${
                  record.checkCountType === CheckCountTypeEnum.ratioCheck
                    ? '%'
                    : record?.unit?.name || ''
                }`;
              }
            }
            return '-';
          },
        },
        {
          dataIndex: 'logic',
          title: '标准值',
          width: 100,
          render: (value: LOGIC, record: CheckItem) => getStandardValue(value, record),
        },
        {
          dataIndex: 'scale',
          title: '精度',
          width: 100,
          render: (scale: number, record: CheckItem) => {
            const hasScale =
              record.executeItemType === CheckItemInputTypeEnum.inputFormatNum ||
              record.executeItemType === CheckItemInputTypeEnum.inputFormatPercent;

            return hasScale && scale ? `${scale}位小数` : '-';
          },
        },
        {
          dataIndex: 'radios',
          title: '全部选项',
          width: 100,
          render: (value: string[]) => value.join(','),
        },
        {
          dataIndex: 'qualityItems',
          title: '合格选项',
          width: 100,
          render: (value: string[]) => value.join(','),
        },
        {
          dataIndex: 'qcAqlInspectionLevel',
          title: '检验水平',
          width: 100,
          render: (value: any) => value?.name,
        },
        {
          dataIndex: 'qcAqlCategory',
          title: '国标严格度',
          width: 120,
          render: (value: any) => lookup('quality.aqlTypes', value),
        },
        {
          dataIndex: 'qcAql',
          title: '接收质量限',
          width: 120,
          render: (value: any) => value?.name,
        },
        {
          title: '默认值',
          dataIndex: 'defaultValue',
          width: 100,
          render: (defaultValue: any, record: CheckItem) => {
            const { defaultMin, defaultMax, chekItemInputType } = record;

            if (defaultMin || defaultMax) {
              return `${defaultMin || '-'} ~ ${defaultMax || '-'}`;
            }
            if (chekItemInputType === CheckItemInputTypeEnum.inputFormatMultiple) {
              return renderTableArray(defaultValue);
            }
            return defaultValue;
          },
        },
        {
          title: '是否必填',
          dataIndex: 'recordCheckItemType',
          render: lookup('quality.recordCheckItemType'),
        },
        {
          title: '必填份数',
          dataIndex: 'requireReportCount',
        },
        {
          title: '总份数',
          dataIndex: 'totalReportCount',
        },
        {
          dataIndex: 'defectReasonList',
          title: '不良原因',
          width: 100,
          render: (defectReasonList: { name: string }[]) =>
            defectReasonList?.map((reason: { name: any }) => reason.name).join(','),
        },
        {
          dataIndex: 'defectRankList',
          title: '不良等级',
          width: 100,
          render: (defectRankList: { name: string }[]) =>
            defectRankList?.map(({ name }) => name).join(','),
        },
        {
          title: '检验设备',
          dataIndex: 'equipments',
          render: (equipments: { name: string }[]) => equipments?.map(({ name }) => name).join(','),
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 100,
        },
        {
          title: '附加结论',
          dataIndex: 'extraResults',
          width: 100,
          render: (extraResults: number[] | null) => {
            if (extraResults?.length) {
              return extraResults
                ?.map((result) => lookup('quality.ExtraResult', result))
                .join('，');
            }
            return '-';
          },
        },
        {
          title: '文件',
          dataIndex: 'attachmentIds',
          width: 200,
          render: (attachmentIds: number[]) => {
            if (attachmentIds?.length) {
              return <BcAttachmentDetail fileIds={attachmentIds} />;
            }
            return '-';
          },
        },
        {
          title: '执行时可填写备注',
          dataIndex: 'executeRemark',
          width: 150,
          render: (executeRemark: boolean) => lookup('common.yn', Number(executeRemark)),
        },
        {
          title: '执行时可填写备注',
          dataIndex: 'executeUpload',
          width: 150,
          render: (executeUpload: boolean) => lookup('common.yn', Number(executeUpload)),
        },
      ]), // 原本的列
      customFields, // 自定义字段信息
      objectCode: appEnum.Common.ObjectCode.qcCheckItem, // 从对象code
      type: 'detail', // 使用类型
    });
  };

  return (
    <BlTable<CheckItem> columns={getColumns()} scroll={{ x: 'max-content' }} dataSource={data} />
  );
};
