import LocalStorage from 'src/utils/localStorage';
import { FIELDS } from 'src/utils/constants';
import { isEmpty } from 'lodash';
import authDict from './authDict';

// 获取auth
export const getAuthFromLocalStorage = () => {
  return LocalStorage.get(FIELDS && FIELDS.AUTH);
};

// 判断是否有该权限
export const hasAuth = (authName?: string) => {
  if (!authName) return false;
  const auths = LocalStorage.get(FIELDS.AUTH);

  return !isEmpty(auths) && auths.includes(authName);
};

export const NO_AUTH_MESSAGE = '无权限执行此操作';

export default authDict;
