import { NamePath } from 'antd/es/form/interface';
import _ from 'lodash';
import {
  ICustomFieldItem,
  ICustomFieldObj,
  ICustomFieldsFnFormConfig,
  ICustomFieldsFnParams,
} from './interface';
import { FieldType } from 'src/dict/customField';
import { fetchCustomObjectReferenceFieldByRelateField } from 'src/api/ytt/custom-object-domain';
import { FieldCascadingDTO } from 'src/page/customLayout/types';

export const DEFAULT_DATAINDEX = 'customFields';

/**
 * 获取指定对象的自定义字段信息
 */
export const getObjectCustomFields = (params: ICustomFieldsFnParams): ICustomFieldObj => {
  const { customFields, objectCode } = params;
  let objectCustomFields: ICustomFieldObj = {} as ICustomFieldObj;

  if (_.isEmpty(customFields)) return objectCustomFields;

  // 不传返回主对象自定义字段
  if (_.isNil(objectCode) || customFields?.objectCode === objectCode) {
    objectCustomFields = customFields;
  } else {
    const objIndex = _.findIndex(customFields?.subObjects, ['objectCode', objectCode]);

    if (objIndex !== -1) {
      objectCustomFields = _.get(customFields, ['subObjects', objIndex], []) as ICustomFieldObj;
    }
  }

  return objectCustomFields;
};

/**
 * 处理关联关系改变后，存在引用的字段需要同步
 */
const handleRelationChange = (
  currentField: ICustomFieldItem,
  params: ICustomFieldObj,
  formConfig?: ICustomFieldsFnFormConfig,
  field?: { fieldKey: any; name: any },
  value?: { label: any; value: any },
) => {
  const { customFields, objectCode } = params;

  // 没有传formConfig,不处理
  if (_.isNil(formConfig)) return;

  // 当前关联关系所对应存在的引用字段
  const referenceFields = _.filter(customFields, (item) => {
    return _.split(item.referenceChain, '#')[0] === _.toString(currentField.fieldId);
  });

  // 不存在对应引用字段，不处理
  if (_.isEmpty(referenceFields)) return;

  const { form, formName } = formConfig;

  const getFieldNamePath = (
    objectCode: string,
    fieldId: number,
    formName?: string,
    field?: { fieldKey: any; name: any },
  ) => {
    const namePath = [];
    if (formName) {
      namePath.push(formName);
    }
    if (field) {
      namePath.push(field.name);
    }
    namePath.push(...getCustomFieldNameOrkey({ objectCode, fieldId }));

    return namePath;
  };

  if (value?.value) {
    fetchCustomObjectReferenceFieldByRelateField({
      fieldCode: currentField.fieldCode,
      parentObjectCode: objectCode,
      relateFieldInstanceId: value?.value,
    }).then((res) => {
      const fieldsData = _.get(res, 'data.fields', []);

      _.forEach(referenceFields, ({ fieldId }) => {
        const referenceFieldValue = _.get(_.find(fieldsData, ['fieldId', fieldId]), 'fieldValue');
        const fieldNamePath = getFieldNamePath(objectCode, fieldId, formName, field);

        form.setFields([{ name: fieldNamePath, value: referenceFieldValue }]);
      });
    });
  } else {
    _.forEach(referenceFields, ({ fieldId }) => {
      const fieldNamePath = getFieldNamePath(objectCode, fieldId, formName, field);

      form.setFields([{ name: fieldNamePath, value: null }]);
    });
  }
};

/**
 * 设置字段的form name 或 table key
 * @param param0
 * @returns
 */
export const getCustomFieldNameOrkey = ({
  objectCode = 'main',
  fieldId,
}: {
  objectCode?: string; // 对象code
  fieldId: number; // 字段Id
}) => {
  return ['customFields', `${objectCode}_${fieldId}`];
};

export const handleFieldChange = (
  currentField: ICustomFieldItem,
  params: ICustomFieldObj,
  formConfig?: ICustomFieldsFnFormConfig,
  field?: { fieldKey: any; name: any },
  value?: any,
) => {
  // 关联关系字段onChange时需要控制指定
  if (currentField.fieldType === FieldType.relation) {
    handleRelationChange(currentField, params, formConfig, field, value);
  }
};

/**
 * 获取指定对象自定字段的级联关系
 * @param params
 * @returns
 */
export const getObjectFieldCascadings = (params: ICustomFieldsFnParams) => {
  // 有 subObjectCode 说明是从对象
  const {
    customFields: { fieldCascadingsList, objectCode },
    objectCode: subObjectCode,
  } = params;

  return _.find(fieldCascadingsList, { objectCode: subObjectCode ?? objectCode })?.cascades ?? [];
};

/**
 * 判断是否需要update
 * @param controlFieldNamePath
 * @returns
 */
export const getShoulUpdate =
  (controlFieldNamePath: NamePath) => (prevStore: any, nextStore: any) => {
    return !_.isEqual(
      _.get(prevStore, controlFieldNamePath),
      _.get(nextStore, controlFieldNamePath),
    );
  };

/**
 * FormItem根据级联关系生成shouldUpdate时需要用到此方法
 * @param objectCode
 * @param customFields
 * @param subObjectConfig
 * @returns
 */
export const generateGetCustomFieldNamePathByFieldCode =
  (
    objectCode: string,
    customFields: ICustomFieldItem[],
    subObjectConfig?: { formName: string; fieldName: number },
  ) =>
  (fieldCode: string) => {
    const getId = (f: ICustomFieldItem) => f.fieldId ?? f.id;
    const finalName = getCustomFieldNameOrkey({
      objectCode,
      fieldId: getId(_.find(customFields, { fieldCode })!),
    });

    // 从对象的字段
    if (subObjectConfig) {
      return [subObjectConfig.formName, subObjectConfig.fieldName, ...finalName];
    }
    // 主对象的字段
    return finalName;
  };

/**
 * 获取对象级联字段的默认值数据
 * @param fieldCascadings
 * @param customFields
 */
export const getCascadeFieldsDefaultValues = (
  customFields?: ICustomFieldItem[],
  fieldCascadings?: FieldCascadingDTO[],
) => {
  if (_.isEmpty(customFields) || _.isEmpty(fieldCascadings)) return [];

  const cascadeChain = _.map(fieldCascadings, (cascade) => {
    const controlIndex = _.findIndex(customFields, ['fieldCode', cascade.fieldCode]);
    const refIndex = _.findIndex(customFields, ['fieldCode', cascade.refFieldCode]);

    return {
      form: undefined,
      self: controlIndex,
      data: {},
      to: refIndex,
    };
  });
};
