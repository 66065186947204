import { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Switch, message as Message, Checkbox, Button, Spin } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';

import { RootState } from 'src/store/models';
import {
  FetchTransferOrderBulkCreateRequest,
  fetchTransferOrderBulkCreate,
} from 'src/api/ytt/inventory-domain/transferOrder';
import { fetchCustomFieldDetailByCode } from 'src/api/ytt/metadata-domain/customField';
import { lookup } from 'src/dict';
import { MergePrinciples, TransferOrderBusinessType } from 'src/dict/storeRequisition';
import { materialEntity, MaterialEntity } from 'src/entity';
import LocalStorage from 'src/utils/localStorage';
import { FIELDS, replaceSign } from 'src/utils/constants';
import { showErrorListMessage } from 'src/components/message/errorMessageList';
import { ErrorNotification } from 'src/components/notification';
import TransferTable from '../components/TransferTable';
import {
  NOTHING_TO_TRANSFER,
  StoreRequisitionListItemProps,
  TransferOrderTableRowType,
  transferFieldLabels as fieldLabels,
  TransferOrderCreateItemType,
} from '../constants';
import { goBackToList, goToDetail } from '../utils';

// 传给form的设置
// preserve: 在删除字段时保留字段值
// antd的form会在rerender的时候删除所有字段，如果该字段为false，使用form全程不可以rerender
const formProps = {
  preserve: true,
  labelCol: { span: 5 },
};

const numberRuleParam = {
  objectCode: 'TransferOrder',
  fieldCode: 'code',
};

/** 合单原则选项数组，要求“发出仓库+接收仓库”选项永久选择且不可取消 */
const mergePrinciples = [
  {
    label: lookup('storeRequisiton.MergePrinciplesDisplayMap', MergePrinciples.warehouses),
    value: MergePrinciples.warehouses,
    disabled: true,
  },
  {
    label: lookup('storeRequisiton.MergePrinciplesDisplayMap', MergePrinciples.materials),
    value: MergePrinciples.materials,
  },
  {
    label: lookup('storeRequisiton.MergePrinciplesDisplayMap', MergePrinciples.requireTime),
    value: MergePrinciples.requireTime,
  },
];

const { getMaterialUnits, getMaterialUnitsInfo } = materialEntity;

/**
 * 合单方法
 * @param unmergedData 未合单的数据，所有物料行单独为1个调拨单
 * @param mergePrinciples 合单原则数组，类似于[0, 1]格式
 * @returns 合单完的调拨单数组
 */
const calcMergedOrder = (
  unmergedData: TransferOrderTableRowType[],
  mergePrinciples: MergePrinciples[],
) => {
  // 根据所选合单原则，给未合单数据分组
  const groupedByPrinciples = _.groupBy(unmergedData, (materialLine: TransferOrderTableRowType) => {
    const mergeCredential: number[] = [];

    // 计算合单凭据，将合单原则中所选字段对应的值拼接成一个字符串，由lodash根据这个字符串分组
    mergePrinciples.forEach((principle) => {
      if (principle === MergePrinciples.warehouses) {
        mergeCredential.push(Number(materialLine.sourceWarehouse?.value));
        mergeCredential.push(Number(materialLine.targetWarehouse?.value));
      } else if (principle === MergePrinciples.materials) {
        mergeCredential.push(materialLine.material.baseInfo?.id!);
      } else if (principle === MergePrinciples.requireTime) {
        mergeCredential.push(materialLine.requirementTime);
      }
    });
    // 把合单凭据写进数据里，编辑表单（删除物料行）时需要
    materialLine.mergeCredential = mergeCredential.join('$$');
    return mergeCredential.join('$$');
  });

  let mergedOrders: TransferOrderTableRowType[] = [];

  // 把分组完的二维数组拍成合单结果，同时根据分组信息计算rowSpan和组内的行号
  Object.keys(groupedByPrinciples).forEach((key) => {
    const group = groupedByPrinciples[key];
    const currentRowSpan = group.length;

    group.forEach((item, index) => {
      item.rowSpan = index === 0 ? currentRowSpan : 0;
      item.line = index + 1;
    });
    mergedOrders = mergedOrders.concat(group);
  });

  return mergedOrders;
};

/**
 * 合单数据还原，重新分单成每个物料行单独一个调拨单
 * @param mergedData 合单完的调拨单数据
 * @returns 分单完的调拨单数据（顺序不会还原成一开始的，而是保持合单时的顺序，只是去掉行的合并）
 */
const calcUnmergedOrder = (mergedData: TransferOrderTableRowType[]) => {
  return mergedData.map((materialLine) => ({
    ...materialLine,
    rowSpan: 1,
    line: 1,
  }));
};

/**
 * 把领料申请传递过来的领料单物料行数据，转换成调拨单物料行数据格式
 * @param materialLinesInput 领料单物料行数据
 * @returns 调拨单物料行初始数组
 */
const formatStateDataToForm = (materialLinesInput: StoreRequisitionListItemProps[]) => {
  return materialLinesInput?.map((materialLine: StoreRequisitionListItemProps) => {
    const {
      // sourceWarehouseId,
      // sourceWarehouseName,
      // targetWarehouseId,
      // targetWarehouseName,
      requestPickAmount,
      warehouseIssuedAmount,
      supplierList,
      line,
      batchCode,
      requirementTime,
      receivePickAmount,
      pickOrderIssuedAmount,
      material,
      ...rest
    } = materialLine;

    return {
      material,
      ...rest,
      // HHZZ3-30101 列表与详情页的仓库字段格式不同，跳转前分别处理
      // sourceWarehouse: sourceWarehouseId
      //   ? { label: sourceWarehouseName, value: sourceWarehouseId }
      //   : undefined,
      // targetWarehouse: targetWarehouseId
      //   ? { label: targetWarehouseName, value: targetWarehouseId }
      //   : undefined,
      unitId: requestPickAmount.unitId,
      // 和产品确认，一期暂时取领料单供应商列表的第一个作为调拨单的供应商
      supplier: supplierList?.[0]
        ? { label: supplierList[0].name, value: supplierList[0].id }
        : undefined,
      bizType: TransferOrderBusinessType.storeRequisition,
      line: 1,
      lineRemark: undefined,
      // 默认申请数=领料单申请数-仓库调拨数 HHZZ3-33061 领料调拨：允许调拨数量计算方法变更
      requestAmount: requestPickAmount.amount
        ? requestPickAmount.amount - (warehouseIssuedAmount?.amount ?? 0)
        : undefined,
      batchNo: batchCode ? { label: batchCode.code, value: batchCode.id } : undefined,
      pickOrderLine: line,
      // 经过和产品确认，实际产线上的工人能感知的时间单位是分钟，在合单时，要把同一分钟不同秒的行合在一起
      requirementTime: moment(requirementTime).startOf('minute').valueOf(),
      receiveAmount: receivePickAmount.amount,
      issueAmount: pickOrderIssuedAmount.amount,
      // 初始数组不合单
      rowSpan: 1,
      // 调拨单编号初始化为空
      code: undefined,
      // 备注初始化为空（避免带入领料单的备注）
      remark: undefined,
      unitsOptions: getMaterialUnits(material as MaterialEntity.MaterialEntity),
      unitsInfoList: getMaterialUnitsInfo(material as MaterialEntity.MaterialEntity),
    };
  });
};

/**
 * 把表单数据转换成创建调拨单的接口格式，数组结构转换成嵌套结构
 * @param formData 调拨单表单数据
 * @returns 创建接口参数
 */
const formatDataToCreateParam = (formData: TransferOrderTableRowType[]) => {
  const createParam: FetchTransferOrderBulkCreateRequest['list'] = [];
  let transferOrder: TransferOrderCreateItemType;

  formData.forEach((materialLine, idx) => {
    // rowSpan > 0是每个调拨单的第一个物料行的标记，其后所有同调拨单的物料行rowSpan都是0，直到下一个调拨单
    if (materialLine.rowSpan > 0) {
      if (transferOrder) createParam.push(transferOrder);
      const { bizType, code, remark, sourceWarehouse, targetWarehouse } = materialLine;

      transferOrder = {
        bizType,
        code,
        remark,
        sourceWarehouseId: Number(sourceWarehouse?.value?.id!),
        targetWarehouseId: Number(targetWarehouse?.value?.id!),
        transferOrderItems: [],
      };
    }
    const {
      batchNo,
      pickOrderDetailId,
      requestAmount,
      issueAmount,
      receiveAmount,
      line,
      lineRemark,
      material,
      supplier,
      unitId,
    } = materialLine;

    transferOrder?.transferOrderItems?.push({
      batchNo: batchNo?.label as string,
      entityId: pickOrderDetailId,
      lineNo: line.toString(),
      materialId: material.baseInfo?.id!,
      planAmount: requestAmount,
      issueAmount,
      receiveAmount,
      remark: lineRemark,
      supplierId: Number(supplier?.value),
      unitId,
    });

    // 遍历到最后一个元素，不会发生下次rowSpan>0时把transferOrder push到数组里了，直接push即可
    if (idx === formData.length - 1) {
      createParam.push(transferOrder);
    }
  });

  return createParam;
};

/** 缓存合单原则，存入LocalStorage */
const saveMergeSetting = (mergeSettings: {
  needMergeOrder?: boolean;
  mergePinciple?: MergePrinciples[];
  transferCodeNeedNumberRule?: boolean;
}) => {
  LocalStorage.set(FIELDS.TRANSFER_ORDER_MERGE_SETTING, mergeSettings);
};

// 创建结果表格列配置
const errorColumns = [
  {
    title: '领料申请编号',
    dataIndex: 'pickOrderCode',
    render: (code: string, errorRecord: any) => (
      <a onClick={() => goToDetail(errorRecord.pickOrderId, true)}>{code}</a>
    ),
  },
  {
    title: '领料申请行号',
    dataIndex: 'pickOrderLine',
  },
  {
    title: '调拨单编号',
    dataIndex: 'code',
    render: (text: string) => text ?? replaceSign,
  },
  {
    title: '失败原因',
    dataIndex: 'reason',
  },
];

export default function MergeTransferOrder() {
  const [mergeForm] = Form.useForm();
  // 从redux store里拿出领料申请模块设置上的已选中物料行信息
  const transferMaterialsFromStore = useSelector(
    (state: RootState) => state.transferMaterials,
    shallowEqual,
  );
  // 把领料单物料行格式转换为调拨单物料行格式
  const transferMaterials = formatStateDataToForm(
    // transferMaterialsFromStore的type和数据内容不一致
    transferMaterialsFromStore as unknown as StoreRequisitionListItemProps[],
  );
  // 是否有下一步，有就是配置步骤，没有就是结果确认步骤
  const [hasNextStep, setHasNextStep] = useState<boolean>(true);
  // 调拨单编号是否已启用并配置编号规则（接口请求结果）
  const [numberRuleAllowed, setNumberRuleAllowed] = useState(false);
  // 是否加载中
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const goToStoreRequisitionList = () => {
    goBackToList(history);
  };

  // 如果用户跳过领料申请页面，直接通过url访问领料调拨页面，redux里没有对应的物料行数据，提示请选择物料行并返回领料申请列表页
  if (_.isEmpty(transferMaterials)) {
    Message.error(NOTHING_TO_TRANSFER);
    goToStoreRequisitionList();
  }
  // 从缓存中读取历史合单原则配置，设置在当前表单的initialValues里
  const savedMergeSettings = LocalStorage.get(FIELDS.TRANSFER_ORDER_MERGE_SETTING);
  const { needMergeOrder, mergePrinciple, transferCodeNeedNumberRule } = savedMergeSettings ?? {};
  // 如果缓存中没有配置信息，使用默认值作为初始值，关闭合单和编码规则ß
  const initialValues = {
    transferMaterials,
    needMergeOrder: transferMaterials.length === 1 ? false : needMergeOrder ?? false,
    mergePrinciple: mergePrinciple ?? [MergePrinciples.warehouses],
    transferCodeNeedNumberRule: transferCodeNeedNumberRule ?? false,
  };

  /** 开启编号规则时，清空之前可能已经手动输入的调拨单编号 */
  const clearTransferCode = (checked: boolean) => {
    if (checked) {
      const formMaterials = mergeForm.getFieldValue('transferMaterials');
      const updatedMaterials = formMaterials.map((materialLine: TransferOrderTableRowType) => ({
        ...materialLine,
        code: undefined,
      }));

      mergeForm.setFieldsValue({ transferMaterials: updatedMaterials });
    }
  };

  const mergeFormInfo: DataFormLayoutInfoBlock = {
    items: [
      {
        noStyle: true,
        dependencies: ['transferMaterials'],
        render: (formItemConfig) => () => {
          // 是否只有一条物料行信息
          const noNeedToMergeOrder = mergeForm.getFieldValue('transferMaterials').length === 1;

          // 确认合单结果页不显示“是否合单”开关
          return hasNextStep ? (
            <Form.Item
              {...formItemConfig}
              label={fieldLabels.needMergeOrder}
              name="needMergeOrder"
              valuePropName="checked"
            >
              {/* 当物料行数据只有1条时，“是否合单”默认为否且不支持反选（disabled） */}
              <Switch
                disabled={noNeedToMergeOrder}
                onChange={(needMerge) => {
                  // 开启合单时，不支持启用编号规则
                  if (needMerge) mergeForm.setFieldsValue({ transferCodeNeedNumberRule: false });
                }}
              />
            </Form.Item>
          ) : null;
        },
      },
      {
        noStyle: true,
        dependencies: ['needMergeOrder'],
        render: (formItemConfig) => () => {
          // 设置合单原则并开启合单时显示
          return mergeForm.getFieldValue('needMergeOrder') && hasNextStep ? (
            <Form.Item {...formItemConfig} name="mergePrinciple" label={fieldLabels.mergePrinciple}>
              <Checkbox.Group options={mergePrinciples} />
            </Form.Item>
          ) : null;
        },
      },
      {
        noStyle: true,
        dependencies: ['needMergeOrder'],
        render: (formItemConfig) => () => {
          // 开启合单/系统未配置编号规则时，不显示“是否启用编号规则”开关
          return numberRuleAllowed ? (
            <Form.Item
              {...formItemConfig}
              label={fieldLabels.transferCodeNeedNumberRule}
              name="transferCodeNeedNumberRule"
              valuePropName="checked"
            >
              <Switch onChange={clearTransferCode} />
            </Form.Item>
          ) : null;
        },
      },
      {
        noStyle: true,
        dependencies: ['transferCodeNeedNumberRule', 'transferMaterials'],
        isFullLine: true,
        render: (formItemConfig) => () => {
          return mergeForm.getFieldValue('transferMaterials').length > 0 ? (
            <Form.Item {...formItemConfig} name="transferMaterials">
              <TransferTable
                form={mergeForm}
                useNumberRule={
                  mergeForm.getFieldValue('transferCodeNeedNumberRule') && numberRuleAllowed
                }
                showMergeResult={!hasNextStep}
              />
            </Form.Item>
          ) : (
            <div style={{ padding: '30px 50px' }}>
              暂无调拨物料，请返回生产工单/用料清单/工序计划列表重新选择
            </div>
          );
        },
      },
    ],
  };

  /** 合单结果确认 */
  const showMergeResult = async () => {
    try {
      // 验证表单数据
      await mergeForm.validateFields();
      const { needMergeOrder, mergePrinciple, transferCodeNeedNumberRule, transferMaterials } =
        mergeForm.getFieldsValue();
      const mergeSettings = {
        needMergeOrder,
        mergePrinciple,
        transferCodeNeedNumberRule,
      };

      // 把合单原则设置保存到LocalStorage
      saveMergeSetting(mergeSettings);

      // 如果配置过合单原则，计算合单结果，设置到表单里
      if (!_.isNil(mergePrinciple) && !_.isEmpty(mergePrinciple)) {
        const mergedTransferMaterials = calcMergedOrder(transferMaterials, mergePrinciple);

        mergeForm.setFieldsValue({ transferMaterials: mergedTransferMaterials });
      }

      // 跳转到合单结果确认
      setHasNextStep(false);
    } catch (err) {
      console.log(err);
    }
  };

  /** 返回合单设置页面，同时把数据还原成未合单状态，但要保持表单中的数据修改 */
  const showMergeSetting = () => {
    const formMaterials = mergeForm.getFieldValue('transferMaterials');

    mergeForm.setFieldsValue({ transferMaterials: calcUnmergedOrder(formMaterials) });
    // 返回上一步
    setHasNextStep(true);
  };

  /** 提交调拨单。1保存合单设置，2调用接口批量新建调拨单 */
  const submitTransferOrders = async () => {
    try {
      /** 表单校验 */
      await mergeForm.validateFields();
      const { transferMaterials } = mergeForm.getFieldsValue();

      // 请求批量创建调拨单接口，保存新建的调拨单
      const createParam = formatDataToCreateParam(transferMaterials);

      setLoading(true);
      const { data } = await fetchTransferOrderBulkCreate({ list: createParam });

      setLoading(false);
      if (data) {
        const { failCount, successCount, failDetails } = data;

        // 全部新建成功，显示新建成功
        if (failCount === 0) {
          Message.success('新建成功');
          goBackToList(history);
        } else if (successCount === 0) {
          // 全部新建失败，提示新建失败及原因
          if (failDetails) {
            ErrorNotification({
              message: '新建失败',
              description: (
                <>
                  {failDetails.map((fail) => (
                    <div key={fail.id}>{fail.reason}</div>
                  ))}
                </>
              ),
            });
          } else {
            Message.error('新建失败！');
          }
        } else if (failDetails) {
          const failRecords: {
            code?: string;
            reason: string;
            pickOrderDetailId: number;
            pickOrderId: number;
            pickOrderCode: string;
            pickOrderLine: number;
          }[] = [];

          failDetails.forEach((failDetail) => {
            const { code, reason } = failDetail;

            failDetail.entityIds?.forEach((entityId) => {
              const storeRequisitionItem = _.find(transferMaterialsFromStore, {
                pickOrderDetailId: entityId,
              });

              failRecords.push({
                code,
                reason,
                pickOrderDetailId: entityId,
                pickOrderCode: storeRequisitionItem?.pickOrderCode!,
                pickOrderLine: storeRequisitionItem?.line!,
                pickOrderId: storeRequisitionItem?.pickOrderId!,
              });
            });
          });
          // 否则用表格显示删除结果及原因统计的table
          showErrorListMessage({
            title: '新建出现错误',
            data: failRecords,
            columns: errorColumns,
            operateName: '新建',
            successCount,
            failCount,
            rowKey: 'pickOrderDetailId',
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  /** 获取领料调拨单的编码是否配置过编码规则，如果没有配置过，不显示“是否启用编码规则”的toggle */
  const fetchNumberRule = async () => {
    try {
      setLoading(true);
      const { data } = await fetchCustomFieldDetailByCode(numberRuleParam);

      setLoading(false);

      // 如果编码规则id不为空，表示调拨单编号开启了编码规则并配置过，允许显示是否使用编码规则的开关
      if (data?.numberRuleId) {
        setNumberRuleAllowed(true);
        console.log('number rule enabled');
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // 加载时，请求调拨单编号的编码规则
    fetchNumberRule();
  }, []);

  return (
    <Spin spinning={loading}>
      <DataFormLayout
        form={mergeForm}
        info={[mergeFormInfo]}
        title={hasNextStep ? '领料调拨' : '领料调拨-合单结果确认'}
        infoBlockStyleProps={{ paddingBottom: 0, borderBottom: 'none' }}
        footer={
          <>
            <Button onClick={goToStoreRequisitionList}>取消</Button>
            {hasNextStep ? (
              <Button type="primary" onClick={showMergeResult}>
                下一步
              </Button>
            ) : (
              <>
                <Button onClick={showMergeSetting}>上一步</Button>
                <Button type="primary" onClick={submitTransferOrders}>
                  确认
                </Button>
              </>
            )}
          </>
        }
        formProps={{ ...formProps, initialValues }}
      />
    </Spin>
  );
}
