import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Input, Radio, Row, Tooltip, Form, InputNumber, Popconfirm } from 'antd';
import { BcAttachmentForm, NumberRulesStandardHook } from 'components';
import { validateBlInputNumberIntervalInt } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import { BlIcon } from 'src/components';
import { appDict, appEnum } from 'src/dict';
import { CheckEntityTypeEnum, CheckTypeEnum, TriggerValueEnum } from 'src/dict/quality';
import { CheckEntityType } from 'src/dict/quality/mappings';
import { FormInstance } from 'antd/es/form/Form';
import { AutoTrigger } from './autoTrigger';

const BaseInfo = (props: {
  form: FormInstance;
  checkType: number;
  checkEntityValue: number;
  isEdit: boolean;
}) => {
  const { form, checkType, checkEntityValue, isEdit } = props;
  const [refreshMarker, setRefreshMarker] = useState(0);

  const triggerInitial = {
    triggerEnable: appEnum.Common.YN.no,
    qcConfigTriggerRule: { triggerValue: [TriggerValueEnum.inbound] },
  };
  const getTriggerValue = (checkType: CheckTypeEnum): TriggerValueEnum[] => {
    if (checkType === CheckTypeEnum.inputFactoryQc) {
      return [TriggerValueEnum.inbound];
    }
    if (checkType === CheckTypeEnum.outputFactoryQc) {
      return [TriggerValueEnum.outbound];
    }
    if (checkType === CheckTypeEnum.produceQc) {
      return [TriggerValueEnum.reportProduction];
    }
    return [TriggerValueEnum.inbound];
  };

  const hasAutoTrigger =
    checkType === CheckTypeEnum.inputFactoryQc ||
    checkType === CheckTypeEnum.outputFactoryQc ||
    checkType === CheckTypeEnum.produceQc;

  useEffect(() => {
    setRefreshMarker(Date.now());
  }, []);

  const getCheckEnityRender = useCallback((): ReactNode => {
    let checkEntityTypeList = CheckEntityType;

    if (checkType === CheckTypeEnum.generalQc) {
      checkEntityTypeList = checkEntityTypeList.filter(
        (i) =>
          i.value !== CheckEntityTypeEnum.SameAsSample &&
          i.value !== CheckEntityTypeEnum.SameAsBatchCount,
      );
    }
    const customInputRender = (
      <div style={{ marginRight: 10, display: 'flex' }}>
        <Form.Item
          name="checkEntityUnitCount"
          rules={[
            { required: true, message: '自定义数目必填' },
            { validator: validateBlInputNumberIntervalInt(0, 100, false) },
          ]}
          style={{ display: 'inline', margin: '-5px 10px 0px 0px' }}
        >
          <InputNumber />
        </Form.Item>
        <label>份</label>
      </div>
    );

    return (
      <Radio.Group style={{ display: 'flex' }}>
        {_.map(checkEntityTypeList, ({ label, value }) => {
          return (
            <Row>
              <Radio
                key={value}
                value={Number(value)}
                onChange={(event) => {
                  event.stopPropagation();
                  if (event?.target?.value === CheckEntityTypeEnum?.Custom) {
                    form.resetFields(['checkEntityType']);
                  }
                }}
              >
                {label}
              </Radio>
              {form.getFieldValue('checkEntityType') === CheckEntityTypeEnum.Custom &&
                Number(value) === CheckEntityTypeEnum.Custom &&
                customInputRender}
            </Row>
          );
        })}
      </Radio.Group>
    );
  }, [checkEntityValue, checkType]);

  const baseInfo = {
    title: '基本信息',
    items: _.compact([
      ...NumberRulesStandardHook({
        label: '检验方案编号',
        form,
        name: 'code',
        edit: isEdit,
        objectCode: 'QualityInspectionScheme',
        fieldCode: 'code',
        isFullLine: true,
        style: { width: 600 },
        disabledState: isEdit,
        refreshMarker,
        rules: [{ max: 255, message: '不可超过255个字符' }],
      }),
      {
        label: '检验方案名称',
        rules: [
          { required: true, message: '检验方案名称必填' },
          { max: 255, message: '不可超过255个字符' },
        ],
        name: 'name',
        render: () => <Input />,
      },
      {
        label: '业务类型',
        name: 'checkType',
        rules: [{ required: true }],
        isFullLine: true,
        render: () => (
          <Radio.Group
            disabled={isEdit}
            options={appDict.quality.checkType}
            onChange={(e) => {
              triggerInitial.qcConfigTriggerRule.triggerValue = getTriggerValue(e.target.value);

              form.setFieldsValue(triggerInitial);
              if (
                e.target.value === CheckTypeEnum.generalQc &&
                (form.getFieldValue('checkEntityType') === CheckEntityTypeEnum.SameAsBatchCount ||
                  form.getFieldValue('checkEntityType') === CheckEntityTypeEnum.SameAsSample)
              ) {
                form.setFieldsValue({ checkEntityType: null });
              }
            }}
          />
        ),
      },
      hasAutoTrigger && {
        label: '',
        name: 'qcConfigTriggerRule',
        isFullLine: true,
        render: () => <AutoTrigger form={form} checkType={checkType} />,
      },
      {
        label: '填写份数',
        name: 'checkEntityType',
        rules: [{ required: true }],
        isFullLine: true,
        render: () => getCheckEnityRender(),
      },
      {
        label: (
          <span>
            执行页填写方式
            <Tooltip
              title={
                '按检验单填写：一份检验单上包含所有检验项，按照检验单顺序一份一份填写。 按检验项填写：一页仅显示一个检验项，每一个检验项要填写的次数=检验单份数。'
              }
            >
              <BlIcon type="iconwentizhiyinbangzhu" style={{ color: '#bfbfbf' }} />
            </Tooltip>
          </span>
        ),
        name: 'reportPageType',
        rules: [{ required: true }],
        render: () => <Radio.Group options={appDict.quality.ReportPageType} />,
      },
      {
        label: '备注',
        name: 'remark',
        isFullLine: true,
        rules: [{ max: 1000, message: '不可超过1000字符' }],
        render: () => <Input.TextArea />,
      },
      {
        label: '文件',
        name: 'attachmentIds',
        isFullLine: true,
        render: () => <BcAttachmentForm form={form} />,
      },
    ]),
  };

  return baseInfo;
};

export default BaseInfo;
