import { Badge, Tabs } from 'antd';
import _ from 'lodash';
import RecordByCheckItem from './checkItemRecord/recordByCheckItem';
import MaterialInfo from './materialInfo';
import { QcTaskEntity } from 'src/page/quality/models/entities/qcTask';
import RecordByReport from './checkItemRecord/recordByReport';
import { FormInstance } from 'antd/es/form/Form';
import { FetchQcTaskDetailForEditResponse } from 'src/api/ytt/quality-domain/qc_task';
import { ICustomFields } from 'src/components/customField';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

type Qctask = FetchQcTaskDetailForEditResponse['data'];
interface DetailInfoProps {
  qcTask: QcTaskEntity;
  form: FormInstance;
  qcTaskData: Qctask;
  tabs: { title: string; key: string; isActive: boolean; errorCount: number }[];
}

export const DetailTab = forwardRef((props: DetailInfoProps, ref) => {
  const reportRef = useRef(null);
  const { qcTask, form, qcTaskData, tabs } = props;
  const [activeTabKey, setActiveKey] = useState<string>(
    _.find(tabs, 'isActive')?.key || 'checkMaterials',
  );

  const tabWithComponent = new Map([
    [
      'checkMaterials',
      <MaterialInfo
        form={form}
        taskData={qcTaskData || ({} as Qctask)}
        dataSource={qcTaskData?.checkMaterials || []}
        canEditMaterial={!qcTask?.isUnStarted()}
        qcTask={qcTask}
      />,
    ],
    [
      'reports',
      qcTask?.isRecordByCheckItem?.() ? (
        <RecordByCheckItem
          form={form}
          qcTask={qcTask}
          data={qcTask?.getResultByCheckItem?.()}
          customFields={qcTask?.checkItemRecordCustomFields}
          setActiveKey={setActiveKey}
        />
      ) : (
        <RecordByReport
          form={form}
          reportCount={qcTask?.getReportCount()}
          customFields={qcTask?.checkItemRecordCustomFields}
          qcTask={qcTask}
          setActiveKey={setActiveKey}
          ref={reportRef}
        />
      ),
    ],
  ]);

  useImperativeHandle(ref, () => ({
    getActiveTab: () => activeTabKey,
  }));

  return (
    <Tabs type="card" style={{ width: '100%' }} activeKey={activeTabKey} onChange={setActiveKey}>
      {tabs.map((tab) => {
        return (
          <Tabs.TabPane
            key={tab.key}
            tab={
              <Badge size="small" count={tab.errorCount}>
                {tab.title}
              </Badge>
            }
          >
            {tabWithComponent.get(tab.key)}
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
});
