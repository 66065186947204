import React from 'react';
import _ from 'lodash';
import { DatePicker, Input, InputNumber, Radio, Select, TimePicker } from 'antd';
import { FormInstance, Rule } from 'antd/lib/form';
import { appDict, appEnum } from 'src/dict';
//
import {
  ICustomFieldItem,
  CustomFieldComponentPropsMap,
  ICustomFieldsFnFormConfig,
} from '../interface';
import moment from 'moment';
import { validatorCharacterLen, validatorNumberDigtis } from 'src/utils/formValidators';
import { fetchCustomObjectFuzzySearch } from 'src/api/ytt/custom-object-domain/custom_object';
import { BcAttachmentForm, BlSearchSelect } from 'src/components';
import { gcTime } from 'src/utils';
import { validatorUrl } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import { DataFormLayoutInfoItem } from '@blacklake-web/layout';
import TextNumberRulesStandardHook from './getTextNumberRulesStandardHook';
import { getSelectableControlCascadingEnhancer } from 'src/page/custom_fields/fieldCascading/utils';
import { FieldCascadingDTO, NamePath } from 'src/page/customLayout/types';

const { FieldType, TargetType } = appEnum.CustomField;

interface IGetFieldFormInfo {
  label: string;
  render: (onChange: (value: any) => void, config: { disabled: boolean }) => React.ReactNode;
  rules: Rule[];
  initialValue: any;
  value: any;
  tooltip?: string;
  formatToForm: (value: any) => any; // 格式化数据 form
  formatToApi: (value: any) => any; // 格式化数据 接口
  items?: DataFormLayoutInfoItem[];
}

export interface IEditCustomFieldsItemConfig {
  form: FormInstance<any>;
  /** 字段的formName前缀 */
  fieldFormName: string[];
  /** 当前字段作为依赖字段的级联关系 */
  relatedCascading?: FieldCascadingDTO;
  getNamePathByFieldCode?: (fieldCode: string) => NamePath;
}

const getFieldComponentProp = (fieldType?: number, propsMap?: CustomFieldComponentPropsMap) => {
  if (_.isNil(propsMap) || _.isNil(fieldType)) return {};

  const props = propsMap.get(fieldType);

  if (_.isNil(props)) return {};

  if (!_.isObject(props)) return {};

  return props;
};

export const getFormCustomFieldItem = (
  dataSource: ICustomFieldItem,
  componentsProps?: CustomFieldComponentPropsMap,
  config?: IEditCustomFieldsItemConfig,
): IGetFieldFormInfo => {
  const {
    fieldType,
    fieldName = '',
    isRequired,
    datetimeFormat = '',
    fieldRemind,
    defaultValue,
    choiceValues,
    fieldValue,
    maxLength,
    maxValue,
    decimalNumber,
    targetType,
    reference,
    isRefer,
    extInfo,
    isNumberRuleConfig,
    isCurrentTime,
    fieldCode,
  } = dataSource;
  const { form, fieldFormName, relatedCascading, getNamePathByFieldCode } = config ?? {};

  const componentProp = getFieldComponentProp(fieldType, componentsProps);

  const baseInfo: IGetFieldFormInfo = {
    label: fieldName,
    tooltip: fieldRemind,
    render: (onChange: (value: any) => void, { disabled }) => (
      <Input onChange={onChange} disabled={disabled} />
    ),
    initialValue: defaultValue,
    value: fieldValue,
    rules: [],
    formatToForm: (value: any) => value,
    formatToApi: (value: any) => value,
  };

  /** 获取基础规则 */
  const getBaseRule = () => {
    return isRequired ? { required: Boolean(isRequired), message: `${fieldName}不能为空` } : null;
  };
  const getNumberRule = () => {
    return [
      {
        validator: validatorNumberDigtis(decimalNumber || 8, maxValue),
      },
    ];
  };
  const getTextRule = () => {
    const defaultLength = fieldType === FieldType.text ? 255 : 1000;

    return [{ validator: validatorCharacterLen(maxLength || defaultLength, fieldName) }];
  };

  const getUrlRule = () => ({ validator: validatorUrl() });

  // /** 单行文本 */
  const getText = (): IGetFieldFormInfo => {
    return {
      ...baseInfo,
      initialValue: !isNumberRuleConfig ? baseInfo?.initialValue : '',
      rules: !isNumberRuleConfig ? _.compact([getBaseRule(), ...getTextRule()]) : [],
      render: (onChange, { disabled }) =>
        !isNumberRuleConfig || !form?.getFieldsValue() ? (
          <Input {...componentProp} disabled={disabled || Boolean(isRefer)} onChange={onChange} />
        ) : (
          <TextNumberRulesStandardHook
            fieldValue={fieldValue}
            defaultValue={defaultValue}
            dataSource={dataSource}
            rules={_.compact([getBaseRule(), ...getTextRule()])}
            formName={fieldFormName}
            form={form}
          />
        ),
    };
  };
  /** 多行文本 */
  const getTextArea = (): IGetFieldFormInfo => {
    return {
      ...baseInfo,
      render: (onChange, { disabled }) => (
        <Input.TextArea
          {...componentProp}
          disabled={disabled || Boolean(isRefer)}
          onChange={onChange}
        />
      ),
      rules: _.compact([getBaseRule(), ...getTextRule()]),
    };
  };
  /** 单选 */
  const getSelect = (): IGetFieldFormInfo => {
    const options = _.map(choiceValues, ({ choiceCode, choiceValue }) => ({
      label: choiceValue ?? '',
      value: choiceCode ?? '',
    }));
    const initialValue = _.head(_.filter(choiceValues, 'isDefault'))?.choiceCode;
    const formatToForm = (value: any) => value && String(value);

    const enhanceSelectableControlWithCascading = getSelectableControlCascadingEnhancer({
      relatedCascading,
      fullOptions: options,
      form,
      fieldInfo: dataSource,
      labelInValue: false,
      getNamePathByFieldCode,
    });

    return {
      ...baseInfo,
      initialValue,
      formatToForm,
      render: (onChange, { disabled }) => {
        let ctrl = (
          <Select
            {...componentProp}
            allowClear
            showArrow
            options={options}
            disabled={disabled || Boolean(isRefer)}
            onChange={onChange}
          />
        );
        // 根据级联关系做处理
        return enhanceSelectableControlWithCascading(ctrl);
      },
      rules: _.compact([getBaseRule()]),
    };
  };
  /** 多选 */
  const getMulSelect = (): IGetFieldFormInfo => {
    const options = _.map(choiceValues, ({ choiceCode, choiceValue }) => ({
      label: choiceValue ?? '',
      value: choiceCode ?? '',
    }));
    const initialValue = _.map(_.filter(choiceValues, 'isDefault'), 'choiceCode');
    const formatToForm = (value: any) => value ?? [];
    const enhanceSelectableControlWithCascading = getSelectableControlCascadingEnhancer({
      relatedCascading,
      fullOptions: options,
      form,
      fieldInfo: dataSource,
      labelInValue: false,
      getNamePathByFieldCode,
    });

    return {
      ...baseInfo,
      initialValue,
      formatToForm,
      render: (onChange, { disabled }) => {
        let ctrl = (
          <Select
            {...componentProp}
            mode="multiple"
            allowClear
            showArrow
            options={options}
            disabled={disabled || Boolean(isRefer)}
            onChange={onChange}
          />
        );
        // 根据级联关系做处理
        return enhanceSelectableControlWithCascading(ctrl);
      },
      rules: _.compact([getBaseRule()]),
    };
  };
  /** 布尔值 */
  const getBoolean = (): IGetFieldFormInfo => {
    const formatToApi = (value: any) => Boolean(value);
    // HHZZ3-28426 原先的formatToForm函数Number(value)会把无缺省值的api返回值''format成0，最终渲染成false
    const formatToForm = (value: any) =>
      value?.length > 0 || _.isBoolean(value) ? Number(value) : undefined;
    const { initialValue } = baseInfo;

    return {
      ...baseInfo,
      initialValue: formatToForm(initialValue),
      formatToApi,
      formatToForm,
      render: (onChange, { disabled }) => (
        <Radio.Group
          {...componentProp}
          options={appDict.common.yn}
          disabled={disabled || Boolean(isRefer)}
          onChange={onChange}
        />
      ),
      rules: _.compact([getBaseRule()]),
    };
  };
  /** 数值 */
  const getNumber = (): IGetFieldFormInfo => {
    const formatToApi = (value: any) => {
      return !_.isNil(value) ? Number(value) : null;
    };
    return {
      ...baseInfo,
      initialValue: baseInfo.initialValue ? Number(baseInfo.initialValue) : null,
      formatToApi,
      render: (onChange, { disabled }) => (
        <InputNumber
          style={{ width: '100%' }}
          {...componentProp}
          formatter={(value) => {
            if (value) {
              const [intNumber, decimalNumber] = `${value}`.split('.');

              return `${intNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${
                decimalNumber ? '.' + decimalNumber : ''
              }`;
            }
            return '';
          }}
          disabled={disabled || Boolean(isRefer)}
          onChange={onChange}
        />
      ),
      rules: _.compact([getBaseRule(), ...getNumberRule()]),
    };
  };
  /** 整数 */
  const getInteger = (): IGetFieldFormInfo => {
    const formatToApi = (value: any) => {
      return !_.isNil(value) ? Number(value) : null;
    };
    return {
      ...baseInfo,
      initialValue: baseInfo.initialValue ? Number(baseInfo.initialValue) : null,
      formatToApi,
      render: (onChange, { disabled }) => (
        <InputNumber
          style={{ width: '100%' }}
          {...componentProp}
          precision={0}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          // parser={(value) =>  value?.replace(/\$\s?|(,*)/g, '')}
          disabled={disabled || Boolean(isRefer)}
          onChange={onChange}
        />
      ),
      rules: _.compact([getBaseRule(), ...getNumberRule()]),
    };
  };
  /** 日期时间 */
  const getDate = (): IGetFieldFormInfo => {
    const isShowTime = datetimeFormat.includes('hh') || datetimeFormat.includes('HH');

    const formatToApi = (value: any) => {
      return value ? Number(gcTime.formatToUnix(value)) : null;
    };
    const formatToForm = (value: any) => {
      return value ? moment(value) : null;
    };
    const getInitialValue = () => {
      if (isCurrentTime) {
        return moment(new Date());
      }
      if (defaultValue) {
        // 当选择HH:mm:ss或HHmmss时，默认为2000/01/01，将来排序会用到
        if (datetimeFormat === 'HH:mm:ss') {
          return moment('2000/01/01 ' + defaultValue);
        }
        if (datetimeFormat === 'HHmmss') {
          return moment('2000001 ' + defaultValue);
        }
        if (datetimeFormat === 'YYYYMMDDHHmmss' || datetimeFormat === 'YYYYMMDDHHmm') {
          return moment(defaultValue.slice(0, 8) + ' ' + defaultValue.slice(8));
        }
        return moment(defaultValue);
      }
      return null;
    };

    return {
      ...baseInfo,
      initialValue: getInitialValue(),
      formatToApi,
      formatToForm,
      render: (onChange, { disabled }) => {
        if (datetimeFormat === 'HH:mm:ss' || datetimeFormat === 'HHmmss') {
          return (
            <TimePicker
              {...componentProp}
              style={{ width: '100%' }}
              format={datetimeFormat}
              disabled={disabled || Boolean(isRefer)}
              onChange={onChange}
            />
          );
        }

        return (
          <DatePicker
            {...componentProp}
            style={{ width: '100%' }}
            format={datetimeFormat}
            showTime={isShowTime}
            disabled={disabled || Boolean(isRefer)}
            onChange={onChange}
          />
        );
      },
      rules: _.compact([getBaseRule()]),
    };
  };
  /** URL */
  const getUrl = (): IGetFieldFormInfo => {
    return {
      ...baseInfo,
      render: (onChange, { disabled }) => (
        <Input {...componentProp} onChange={onChange} disabled={disabled || Boolean(isRefer)} />
      ),
      rules: _.compact([getBaseRule(), getUrlRule(), ...getTextRule()]),
    };
  };
  /** 引用关系 */
  const getReference = (): IGetFieldFormInfo => {
    return {
      ...baseInfo,
      render: (onChange) => (
        <Input {...componentProp} disabled placeholder={`引用${fieldName}`} onChange={onChange} />
      ),
      rules: _.compact([getBaseRule()]),
    };
  };
  /** 关联关系 */
  const getRelation = (): IGetFieldFormInfo => {
    const selectFormatter = (res: any) => {
      return {
        options: res.data.map((i: { mainProperty: string; instanceId: number }) => {
          return {
            label: i.mainProperty,
            value: i.instanceId,
          };
        }),
        total: res.data.total,
      };
    };

    const formatToForm = (value: any) => {
      if (targetType === TargetType.multiChoice) {
        return value
          ? value.map((i: { mainProperty: string; id: number }) => ({
              label: i?.mainProperty,
              value: i.id,
            }))
          : [];
      }
      return value ? { label: value?.mainProperty, value: value.id } : null;
    };
    const formatToApi = (value: any) => {
      if (targetType === TargetType.multiChoice) {
        return value?.map((i: { value: string }) => i.value) || [];
      }
      return value?.value;
    };

    return {
      ...baseInfo,
      initialValue: formatToForm(baseInfo.initialValue),
      formatToForm,
      formatToApi,
      render: (onChange, { disabled }) => (
        <BlSearchSelect
          placeholder="请选择"
          {...componentProp}
          fetchFn={(params: any) =>
            fetchCustomObjectFuzzySearch({
              ...params,
              objectCode: reference,
              search: params.searchParams,
            })
          }
          formatter={selectFormatter}
          labelInValue
          mode={targetType === TargetType.multiChoice ? 'multiple' : undefined}
          disabled={disabled || Boolean(isRefer)}
          onChange={onChange}
        />
      ),
      rules: _.compact([getBaseRule()]),
    };
  };

  /** 主从关系 */
  const getSubordinate = (): IGetFieldFormInfo => {
    return {
      ...baseInfo,
      render: () => <Input disabled />,
      rules: _.compact([getBaseRule()]),
    };
  };

  /** 附件 */
  const getAppendix = (): IGetFieldFormInfo => {
    const formatToForm = (value: any) => value || [];
    return {
      ...baseInfo,
      initialValue: formatToForm(baseInfo.initialValue),
      formatToForm,
      formatToApi: (value) => {
        if (typeof value?.[0] === 'object') return value?.map((v: any) => v?.id);
        return value;
      },
      rules: _.compact([
        getBaseRule(),
        {
          type: 'array',
          max: extInfo?.fileCount || 9,
          message: `只支持上传${extInfo?.fileCount || 9}个文件`,
        },
      ]),
      render: (onChange, { disabled }) => (
        <BcAttachmentForm
          {...componentProp}
          disabled={disabled || Boolean(isRefer)}
          onChange={onChange}
          uploadConfig={{
            maxSize: 20,
            totalMaxSize: 200,
            limit: undefined,
          }}
        />
      ),
    };
  };

  const fieldMapping = new Map<number, () => IGetFieldFormInfo>([
    [FieldType.text, getText],
    [FieldType.textArea, getTextArea],
    [FieldType.select, getSelect],
    [FieldType.multiSelect, getMulSelect],
    [FieldType.boolean, getBoolean],
    [FieldType.number, getNumber],
    [FieldType.integer, getInteger],
    [FieldType.date, getDate],
    [FieldType.url, getUrl],
    [FieldType.reference, getReference],
    [FieldType.relation, getRelation],
    [FieldType.subordinate, getSubordinate],
    [FieldType.appendix, getAppendix],
  ]);

  const getFiledFn = fieldMapping.get(fieldType);

  if (!getFiledFn) {
    return {
      ...baseInfo,
      render: () => '【不支持的字段类型】',
    };
  }

  return getFiledFn();
};
