import { Spin } from 'antd';
import React, { useEffect } from 'react';

const ShowFineReport = (props: { previewUrl: string; params: any; canFullScreen?: boolean }) => {
  const { previewUrl, params, canFullScreen = true } = props;

  const url = previewUrl.split('/')[2];

  const domain = `https://${url}`;

  useEffect(() => {}, [previewUrl, params]);

  const loadedIframe = () => {
    const win2 = document.getElementById('show')?.contentWindow;
    const data2 = {
      list: { params },
      url: `${previewUrl}&op=view`, // 私有云不支持帆软的 op=view
    };

    win2.postMessage(data2, domain);
  };

  const renderAllScreen = () => {
    return (
      <div
        style={{ display: 'flex', position: 'absolute', top: 30, right: 30, zIndex: 10, width: 50 }}
      >
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const dashBoard = document.getElementById('dashBoardIframe');

            if (dashBoard) {
              dashBoard.requestFullscreen();
            }
          }}
        >
          全屏
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        width: '100%',
        minHeight: 800,
        maxHeight: 800,
      }}
    >
      {canFullScreen && renderAllScreen()}
      {url && (
        <div
          id={'dashBoardIframe'}
          style={{
            width: '100%',
            minHeight: 800,
            maxHeight: 800,
            margin: '0 auto',
            transition: 'all 0.5s',
            background: '#fff',
          }}
        >
          <iframe
            title="navigation"
            id="show"
            style={{ minHeight: 800, maxHeight: 800, width: '100%' }}
            onLoad={() => loadedIframe()}
            src={`${domain}/webroot/show.html`}
          />
        </div>
      )}
    </div>
  );
};

export default ShowFineReport;
