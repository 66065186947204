import React, { useEffect, useState } from 'react';
import { CascaderOptionType } from 'antd/lib/cascader';
import { BlCascader } from '@blacklake-web/component';
import { STATUS_VALUE } from 'src/page/resource/areaConfig/constants';
import { fetchLocationGetListByParent } from 'src/api/ytt/resource-domain/areaConfig';

interface Props {
  onChange?: (value: any) => void;
  value?: string[] | number[];
  nameLabel?: string;
  placeholder?: string;
  showSearch?: boolean;
}

const AreaCascader = (props: Props) => {
  const { onChange, value, nameLabel, placeholder = '请选择' } = props;
  const [optionsList, setOptionsList] = useState<CascaderOptionType[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const formatArea = (data: any) => {
    if (data instanceof Array) {
      return data;
    }
    return [data];
  };
  const fieldNames = {
    label: 'name',
    value: 'id',
    children: 'children',
  };

  function handelOnChange(value: any) {
    onChange && onChange(value);
  }
  const handleFormatData = (list: any[]): any[] => {
    const data = list?.map((item: any) => {
      if (item?.leaf === 0 || item[fieldNames.children]?.length) {
        return {
          label: item[fieldNames.label],
          value: item[fieldNames.value],
          children: handleFormatData(item[fieldNames.children]),
        };
      }
      return {
        label: item.name,
        value: item.id,
        isLeaf: !item.hasChildren,
        children: undefined,
      };
    });

    return data;
  };

  const getListData = async ({ parentId }: { parentId: number }) => {
    const { data } = await fetchLocationGetListByParent?.({
      parentId,
      enableStatus: STATUS_VALUE.ENABLED,
    });

    return handleFormatData(formatArea(data));
  };

  const getNode = async (options: CascaderOptionType[] | undefined) => {
    const targetOption = options?.[options.length - 1];
    if (targetOption) {
      targetOption.loading = true;

      const list = await getListData({ parentId: Number(targetOption?.value) });

      if (optionsList.length !== 0) {
        targetOption.children = list.length ? list : undefined;
        targetOption.loading = false;
        setOptionsList([...optionsList]);
      } else {
        setOptionsList([...list]);
        targetOption.loading = false;
      }
    }
  };

  const setChooseOptionList = async (chooseList: number[] | string[]) => {
    let childrenList = {} as { id: number; children: any[] };
    const parentList = await getListData({ parentId: 0 });
    const requestList = [parentList];
    for (let i = chooseList.length - 1; i >= 0; i--) {
      const list = await getListData({ parentId: Number(chooseList[i]) });
      let children = list;
      if (childrenList.id) {
        children = list.map((data) => {
          if (data?.value === childrenList.id) {
            data.children = childrenList.children;
          }
          return data;
        });
      }
      childrenList = {
        id: Number(chooseList[i]),
        children,
      };
      requestList.push(list);
    }

    Promise.all([...requestList]).then(() => {
      setOptionsList(
        parentList.map((option) => {
          if (option.value === childrenList.id) {
            option.children = childrenList.children;
          }
          return option;
        }),
      );
      setIsLoadingData(false);
    });
  };

  useEffect(() => {
    if (value?.length) {
      setChooseOptionList(value);
    } else {
      getNode([{ value: 0 }]);
    }
  }, [value]);

  return (
    <BlCascader
      placeholder={`${placeholder}${nameLabel || ''}`}
      value={isLoadingData ? [] : value}
      options={optionsList}
      onChange={handelOnChange}
      popupPlacement={'bottomLeft'}
      changeOnSelect
      loadData={getNode}
    />
  );
};

export default AreaCascader;
