import { BlTable, LinkTooltip } from 'src/components';
import { ColumnProps } from 'antd/es/table';
import { DefaultPaginationType, defaultPagination, InBoundItemType } from '../../interface';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import authDict from 'src/utils/auth';
import { BOUND_PATH } from 'src/page/warehouseManagement/boundOrderManagement/navigation';
import { FC, useEffect, useState } from 'react';
import { fetchTraceTraceContentInventoryListInbounds } from 'src/api/ytt/trace-domain/traceContent';

interface WarehouseTabProps {
  traceId?: number;
}

const InBound: FC<WarehouseTabProps> = ({ traceId }) => {
  const [inPagination, setInPagination] = useState<DefaultPaginationType>(defaultPagination);

  const [inBoundDataSource, setInboundDataSource] = useState({} as any);

  const [loading, setLoading] = useState(true);

  const fetchInBoundData = async (params: any) => {
    try {
      if (traceId) {
        const { data } = await fetchTraceTraceContentInventoryListInbounds({
          traceId,
          page: params?.current ?? 1,
          size: params?.pageSize ?? 10,
        });

        if (data) {
          setInboundDataSource(data);
          setInPagination({
            ...inPagination,
            current: params?.current ?? 1,
            pageSize: params?.pageSize ?? 10,
            total: data?.total ?? 0,
          });
        }
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInBoundData(defaultPagination);
  }, [traceId]);

  const InboundColumns: ColumnProps<InBoundItemType>[] = [
    {
      title: '序号',
      dataIndex: 'seq',
      width: 100,
      render: (_: any, record: InBoundItemType, index: number) => index + 1,
    },
    {
      title: '单据类型',
      dataIndex: 'type',
      width: 200,
      render: () => '入库单',
    },
    {
      title: '单据号',
      dataIndex: ['inboundOrder', 'code'],
      width: 200,
      render: (text: string, record: InBoundItemType) => (
        <LinkTooltip
          to={`${BOUND_PATH}/inBound/${record?.inboundOrder?.id}/detail`}
          text={text}
          width={200}
          auth={authDict.inboundorder_detail}
        />
      ),
    },
    {
      title: '操作时间',
      dataIndex: 'operateTime',
      width: 200,
      render: (operateTime: Date) => {
        if (!operateTime) return replaceSign;
        return _Time.format(operateTime);
      },
    },
  ];

  return (
    <BlTable
      loading={loading}
      scroll={{ y: 580 }}
      dataSource={inBoundDataSource?.list}
      columns={InboundColumns}
      rowKey={(record: InBoundItemType) => record?.inboundRecordId}
      tableConfigKey={'traceNodeInBoundCol'}
      onChange={(pagination: any) => {
        const { current, pageSize } = pagination;

        fetchInBoundData({ current, pageSize });
      }}
      pagination={{
        ...inPagination,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: () => `共 ${inBoundDataSource?.total} 条`,
      }}
    />
  );
};

export default InBound;
