/**
 * @file 业务字典
 */

import { get, isEmpty, isPlainObject, has, isObject } from 'lodash';
import * as Common from './common/index';
import * as common from './common/mappings';
import * as Sop from './sop/index';
import * as sop from './sop/mappings';
import * as Bom from './bom/index';
import * as bom from './bom/mappings';
import * as WorkCalendar from './workCalendar/index';
import * as workCalendar from './workCalendar/mappings';
import * as ProductionPlanning from './productionPlanning/index';
import * as productionPlanning from './productionPlanning/mappings';
import * as Material from './material/index';
import * as material from './material/mappings';
import * as StoreRequisition from './storeRequisition';
import * as storeRequisiton from './storeRequisition/mappings';
import * as ObjectImport from './objImport/index';
import * as objectImport from './objImport/mappings';
import * as ReportTemplate from './reportTemplate/index';
import * as reportTemplate from './reportTemplate/mappings';
import { ExtendedValueType, Mappings } from './types';
import * as eSM from './eS-M/index';
import * as esm from './eS-M/mappings';
import * as Warehouse from './warehouse/index';
import * as warehouse from './warehouse/mappings';
import * as Quality from './quality/index';
import * as quality from './quality/mappings';
import * as ProcessRoute from './processRouting';
import * as processRoute from './processRouting/mappings';
import * as Purchase from './purchase/index';
import * as purchase from './purchase/mappings';
import * as Bound from './bound';
import * as bound from './bound/mappings';
import * as CustomReport from './customReport';
import * as customReport from './customReport/mappings';
import * as Customer from './customer';
import * as customer from './customer/mappings';
import * as Auth from './auth';
import * as auth from './auth/mappings';
import * as Approval from './approval';
import * as approval from './approval/mappings';
import * as SupplyRegStatus from './supplier';
import * as supplyRegStatus from './supplier/mappings';
import * as Sales from './sales';
import * as sales from './sales/mappings';
import * as User from './user';
import * as user from './user/mappings';
import * as DeliveryEnum from './delivery';
import * as DeliveryMap from './delivery/mappings';
import * as Invoice from './invoice';
import * as invoice from './invoice/mappings';
import * as notification from './notification/mappings';
import * as SalesReturnOrder from './salesReturnOrder';
import * as salesReturnOrder from './salesReturnOrder/mappings';
import * as CustomField from './customField';
import * as customField from './customField/mappings';
import * as Resources from './resources';
import * as resources from './resources/mappings';
import * as NoticeMessage from './noticeMessage';
import * as noticeMessage from './noticeMessage/mappings';
import * as TriggerRule from './triggerRule';
import * as triggerRule from './triggerRule/mappings';
import * as TriggerAction from './triggerAction';
import * as triggerAction from './triggerAction/mappings';
import * as CustomLayout from './customLayout';
import * as customLayout from './customLayout/mappings';
import * as AlternativePlan from './alternativePlan';
import * as alternativePlan from './alternativePlan/mappings';
import * as Process from './process';
import * as process from './process/mappings';

import * as Planned from './planned/index';
import * as planned from './planned/mappings';
import * as ProcessManagement from './processManagement';
import * as processManagement from './processManagement/mappings';

import * as CollaborativeDeliverySchedule from './collaborativeDeliverySchedule';
import * as collaborativeDeliverySchedule from './collaborativeDeliverySchedule/mappings';
/** 业务字典全集, 实现命名空间隔离 */
export const appDict = {
  common,
  user,
  sop,
  bom,
  workCalendar,
  productionPlanning,
  material,
  storeRequisiton,
  objectImport,
  reportTemplate,
  esm,
  processRoute,
  purchase,
  bound,
  warehouse,
  quality,
  customReport,
  customer,
  auth,
  approval,
  supplyRegStatus,
  sales,
  DeliveryMap,
  invoice,
  notification,
  salesReturnOrder,
  customField,
  resources,
  noticeMessage,
  triggerRule,
  triggerAction,
  customLayout,
  alternativePlan,
  process,
  planned,
  processManagement,
  collaborativeDeliverySchedule,
};

export const appEnum = {
  Common,
  User,
  Sop,
  Bom,
  WorkCalendar,
  ProductionPlanning,
  Material,
  StoreRequisition,
  ObjectImport,
  ReportTemplate,
  eSM,
  ProcessRoute,
  Purchase,
  Bound,
  Warehouse,
  Quality,
  CustomReport,
  Customer,
  Auth,
  Approval,
  SupplyRegStatus,
  Sales,
  DeliveryEnum,
  Invoice,
  notification,
  SalesReturnOrder,
  CustomField,
  Resources,
  NoticeMessage,
  TriggerRule,
  TriggerAction,
  CustomLayout,
  AlternativePlan,
  Process,
  Planned,
  ProcessManagement,
  CollaborativeDeliverySchedule,
};

type AppDict = typeof appDict;

type QSObject = {
  [Key in keyof AppDict]: `${Key}.${string & keyof AppDict[Key]}`;
};

/** 支持智能提示 */
type commonQuery = keyof typeof common;
type QueryString = commonQuery | QSObject[keyof QSObject];

/**
 * 查字典: 根据value在指定映射表里查找label, 若查不到则返回value
 * @param {string} query 要查询的映射表。格式为『命名空间.映射表』, 如 'common.yn'。使用公共空间时, 空间名省略, 直接写 'yn'
 * @param {string | number} [value] 值。不传则返回一个柯里化的新函数
 * @returns {string | number | function}
 */
export const lookup = (query: QueryString, value?: ExtendedValueType): any => {
  // 自动补齐common命名空间
  const fullQuery = query.includes('.') ? query : `common.${query}`;
  const [dict, mappings] = fullQuery.split('.');
  const realMappings = get(appDict, `${dict}.${mappings}`, []);

  if (isEmpty(realMappings)) {
    throw Error(`未找到业务字典『${query}』，请检查拼写`);
  }
  const getLabel = (val: ExtendedValueType) => {
    // 兼容后端返回的枚举格式
    if (isPlainObject(val) && has(val, 'message')) {
      return (val as BackendEnum).message;
    }
    // 如果传入的是个对象、数组或函数，说明传入有误
    if (isObject(val)) {
      console.log('%c【错误】lookup收到引用类型入参：', 'color: red; font-size: 18px', val);
      throw Error('lookup入参格式错误，请检查。');
    }
    const res = (realMappings as Mappings).find((item) => item.value === val);

    return res?.label ?? val;
  };

  // 支持currying
  if (value === undefined) {
    return getLabel;
  }
  return getLabel(value);
};

export const pickCode = (data: any) => data?.code ?? data;
export const pickMessage = (data: BackendEnum) => data.message;

export default lookup;
