import { match, RouteComponentProps } from 'react-router-dom';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { FileTextOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import {
  fetchPurchaseOrderDetail,
  fetchPurchaseOrderItemDetail,
  fetchPurchaseOrderOutsourceMaterialListDetail,
} from 'src/api/ytt/order-domain/purchaseOrder';
import { fetchCustomFieldInstanceGetByObjectCode } from 'src/api/ytt/custom-object-domain';
import {
  handleDistribute,
  handleClosed,
  handleReboot,
  endOrder,
  beforeCreateReturnOrder,
} from '../batchOperate';
import { SingleOperationLogComponent } from 'src/components';
import DetailsBaseInfo from './detailsBaseInfo';
import { DetailLayoutMenuItem } from 'src/layout';
import { PurchaseExecStatus, PurchaseSource, PurchaseOrderdeliveryMode } from 'src/dict/purchase';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import {
  toEdit,
  toCreateReturnOrder,
  toCreateDeliveryOrder,
  toCreateDeliveryScheduleNote,
} from '../navigate';
import authDict from 'src/utils/auth';
import { PurchaseOrderDataSource } from '../interface';
import _ from 'lodash';
interface DetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const DetailPage = (props: DetailProps) => {
  const { match, history } = props;
  const purchaseId = Number(match?.params?.id);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [dataSource, setDataSource] = useState<PurchaseOrderDataSource>({});
  const [execStatus, setExecStatus] = useState<number>(0);
  const [customFields, setCustomFields] = useState<any>();
  const [itemCustomFields, setItemCustomFields] = useState<any>();
  const [outItemCustomFields, setOutItemCustomFields] = useState<any>();
  const refreshAll = () => {
    setRefreshMarker(Date.now());
  };
  const dispatch = useDispatch();
  const handleCreateReturnOrder = (ids: number[], onSuccess?: () => void, onFail?: () => void) => {
    const result = beforeCreateReturnOrder(ids, '采购退货单', onSuccess, onFail);

    result.then((res) => {
      if (res) {
        dispatch({
          type: 'supplyChain/formatterPurchaseReturnOrderInfo',
          payload: res,
        });
        history.push(toCreateReturnOrder());
      }
    });
  };
  const handleCreateDeliveryScheduleNote = (
    ids: number[],
    onSuccess?: () => void,
    onFail?: () => void,
  ) => {
    const result = beforeCreateReturnOrder(ids, '交货计划单', onSuccess, onFail);

    result.then((res) => {
      if (res) {
        dispatch({
          type: 'supplyChain/formatterDeliveryScheduleNote',
          payload: res,
        });
        history.push(toCreateDeliveryScheduleNote());
      }
    });
  };

  const fetchData = async () => {
    try {
      const [
        { data },
        { data: materialLine },
        { data: outMaterialLine },
        customFieldRes,
        itemCustomFieldRes,
        outItemCustomFieldRes,
      ] = await Promise.all([
        fetchPurchaseOrderDetail({ id: purchaseId }),
        fetchPurchaseOrderItemDetail({ id: purchaseId }),
        fetchPurchaseOrderOutsourceMaterialListDetail({ id: purchaseId }),
        fetchCustomFieldInstanceGetByObjectCode({
          objectCode: OBJECT_OF_CODE.purchaseOrder,
        }),
        fetchCustomFieldInstanceGetByObjectCode({
          objectCode: OBJECT_OF_CODE.purchaseOrderItem,
        }),
        fetchCustomFieldInstanceGetByObjectCode({
          objectCode: OBJECT_OF_CODE.outsourceMaterialList,
        }),
      ]);

      // 设置自定义字段信息
      if (customFieldRes) {
        const { data } = customFieldRes;

        setCustomFields(data);
      }
      if (itemCustomFieldRes) {
        const { data } = itemCustomFieldRes;

        setItemCustomFields(data);
      }
      if (outItemCustomFieldRes) {
        const { data } = outItemCustomFieldRes;

        setOutItemCustomFields(data);
      }
      if (data) {
        setDataSource({ ...data, itemList: materialLine, outItemList: outMaterialLine });
        setExecStatus(data?.execStatus as number);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    purchaseId && fetchData();
  }, [purchaseId, refreshMarker]);

  const baseMenu: DetailLayoutMenuItem[] = _.compact([
    {
      key: 'attribute',
      disabled: execStatus !== PurchaseExecStatus.created,
      auth: authDict.purchaseorder_dispatch,
      onClick: () => {
        handleDistribute([purchaseId], refreshAll);
      },
      title: '下发',
    },
    {
      key: 'createReturnOrder',
      disabled: execStatus !== PurchaseExecStatus.executing,
      auth: authDict.purchasereturnorder_add,
      onClick: () => {
        handleCreateReturnOrder([purchaseId], refreshAll);
      },
      title: '新建采购退货单',
    },
    {
      key: 'createDeliveryScheduleNote',
      title: '新建交货计划单',
      auth: authDict.deliveryScheduleNote_add,
      disabled:
        dataSource.execStatus !== PurchaseExecStatus.executing ||
        dataSource.deliveryMode !== PurchaseOrderdeliveryMode.deliverySchedule,
      onClick: () => {
        handleCreateDeliveryScheduleNote([dataSource.id!]);
      },
    },
    {
      title: '新建收货单',
      key: 'createReceivenoteOrder',
      auth: authDict.receivenote_add,
      disabled: !(
        dataSource.execStatus === PurchaseExecStatus.executing &&
        dataSource.source === PurchaseSource.common
      ),
      onClick: () => {
        history.push(toCreateDeliveryOrder(purchaseId as number));
      },
    },
    {
      key: 'ended',
      disabled: execStatus !== PurchaseExecStatus.executing,
      auth: authDict.purchaseorder_finish,
      onClick: () => {
        endOrder([purchaseId], [dataSource?.code ?? ''], 'single', refreshAll);
      },
      title: '结束',
    },
    {
      key: 'closed',
      disabled: execStatus !== PurchaseExecStatus.created,
      auth: authDict.purchaseorder_close,
      onClick: () => {
        Modal.confirm({
          title: '关闭采购订单',
          content: '关闭采购订单后不能再进行任何操作，确认关闭吗？',
          okText: '关闭',
          cancelText: '暂不关闭',
          onOk: async () => {
            await handleClosed([purchaseId], refreshAll);
          },
        });
      },
      title: '关闭',
    },
    {
      key: 'reboot',
      disabled: execStatus !== PurchaseExecStatus.finished,
      auth: authDict.purchaseorder_restart,
      onClick: () => {
        Modal.confirm({
          title: '重启采购订单',
          content: '重启采购订单后，采购订单状态变为执行中，确定重启吗',
          okText: '重启',
          cancelText: '暂不重启',
          onOk: async () => {
            await handleReboot([purchaseId], refreshAll);
          },
        });
      },
      title: '重启',
    },
    {
      key: 'record',
      auth: authDict.purchaseorder_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      icon: <FileTextOutlined />,
      title: '操作记录',
    },
    {
      key: 'edit',
      onClick: () => {
        history.push(toEdit(purchaseId));
      },
      disabled:
        execStatus === PurchaseExecStatus.finished || execStatus === PurchaseExecStatus.closed,
      auth: authDict.purchaseorder_edit,
      icon: 'iconbianji',
      title: '编辑',
    },
  ]);

  return (
    <>
      {dataSource.id && (
        <DetailsBaseInfo
          baseMenu={baseMenu}
          customFields={customFields}
          itemCustomFields={itemCustomFields}
          outItemCustomFields={outItemCustomFields}
          dataSource={dataSource}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={purchaseId as number}
          objectCode={OBJECT_OF_CODE.purchaseOrder}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailPage;
