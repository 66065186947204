import React, { useEffect, useState } from 'react';
import { Empty, Input, Timeline } from 'antd';
import { DetailLayout, DetailLayoutForDrawer, DetailLayoutInfoBlock } from 'layout';
import { replaceSign } from 'src/utils/constants';
import { fetchLogGetOperationHistory } from 'src/api/ytt/log-domain';
import styles from './styles.module.scss';
import _Time from 'src/utils/dataTypes/time';
import _String from 'src/utils/dataTypes/string';
import { BlIcon, Tooltip } from 'components';
import _ from 'lodash';
import _Array from 'src/utils/dataTypes/array';

interface Props {
  visible: boolean;
  closeDetail: () => void;
  instanceId: number; // 对象id
  objectCode: string; // 对象objectCode 由对象平台定义 唯一值
}
interface FetchResponse {
  editSubObjectNames?: string[]; // 字段变更
  fieldDetailVOS: FieldDetailVOS[];
  instanceCode?: string; // 实例编码
  instanceName: string; // 实例名称
  loggedAt: number; // 日志记录时间
  objectDisplayName: string; // 对象名称
  operationTypeCode: string; // 操作类型code  add  新增 edit 编辑
  operationName: string; // 操作类型名称
  operator: {
    avatarUrl: string; // 用户头像
    id: number; // 用户id
    name: string; // 用户名称
  };
}
interface FieldDetailVOS {
  fieldName?: string; // 字段名称
  fieldType?: number; // 字段类型
  fieldValueFrom?: string; // 字段修改前值，可能为空、null
  fieldValueTo?: string; // 字段修改后值，可能为空、null
}

const SingleOperationLogComponent = (props: Props) => {
  const { visible, closeDetail, instanceId, objectCode } = props;

  const [allData, setAllData] = useState<FetchResponse[]>([]); // 请求返回的所有数据

  const [showData, setShowData] = useState<FetchResponse[]>([]); // 显示数据
  const [loading, setLoading] = useState<boolean>(false); // 显示数据

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await fetchLogGetOperationHistory({
        instanceId,
        objectCode,
      });

      if (data) {
        setAllData(data);
        setShowData(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchData();
    }
  }, [visible, instanceId, objectCode]);

  const onChange = _.debounce((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e?.target;

    const formatData = allData.filter((node: FetchResponse) => {
      const {
        operator,
        operationName,
        instanceName,
        editSubObjectNames,
        fieldDetailVOS,
        objectDisplayName,
        operationTypeCode,
      } = node;
      let filter = false;

      if (operator?.name.indexOf(value) > -1) {
        // 用户名称针对所有
        filter = true;
      }

      if (
        // 只有新增才过滤对象和实例名
        operationTypeCode === 'add' &&
        (objectDisplayName.indexOf(value) > -1 || instanceName.indexOf(value) > -1)
      ) {
        filter = true;
      }

      if (operationTypeCode === 'edit') {
        fieldDetailVOS &&
          fieldDetailVOS.forEach((e: FieldDetailVOS) => {
            if (
              (e?.fieldName && e?.fieldName.indexOf(value) > -1) ||
              (e?.fieldValueFrom && e?.fieldValueFrom.indexOf(value) > -1) ||
              (e?.fieldValueTo && e?.fieldValueTo.indexOf(value) > -1)
            ) {
              filter = true;
            }
          });
        editSubObjectNames &&
          editSubObjectNames.forEach((e: string) => {
            if (e?.indexOf(value) > -1) {
              filter = true;
            }
          });
      }

      if (
        operationTypeCode !== 'add' &&
        operationTypeCode !== 'edit' &&
        operationName.indexOf(value) > -1
      ) {
        filter = true;
      }

      if (filter) return node;

      return null;
    });

    setShowData(formatData);
  }, 200);

  const baseInfo: DetailLayoutInfoBlock = {
    items: [
      {
        label: '',
        dataIndex: 'search',
        isFullLine: true,
        render: () => {
          return (
            <div className={styles?.logSearch}>
              <div className={styles?.search}>
                <Input
                  prefix={<BlIcon type="iconsousuo" />}
                  placeholder="请输入搜索关键字"
                  onChange={onChange}
                  allowClear
                />
              </div>
              <div className={styles?.prompt}>此页面仅展示最新的300条操作记录</div>
            </div>
          );
        },
      },
      {
        label: '',
        dataIndex: 'log',
        isFullLine: true,
        render: () => {
          return _Array.isEmpty(showData) ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <Timeline>
              {showData?.map((node: FetchResponse) => {
                const {
                  operationTypeCode,
                  operator,
                  objectDisplayName,
                  instanceName,
                  loggedAt,
                  editSubObjectNames,
                  fieldDetailVOS,
                  operationName,
                  operateReason,
                } = node;

                const time = _Time.format(loggedAt);

                if (operationTypeCode === 'add') {
                  //  新增
                  return (
                    <Timeline.Item>
                      <div className={styles?.logEdit}>
                        <span className={styles?.logTime}>{time}</span>
                        <div className={styles?.logInfo}>
                          <span className={styles?.logSpan}>{operator?.name}</span>
                          <span className={styles?.logDo}>新建</span>
                          <span className={styles?.logSpan}>{objectDisplayName}</span>
                          <span className={styles?.logSpan}>{instanceName}</span>
                        </div>
                      </div>
                    </Timeline.Item>
                  );
                }
                if (operationTypeCode === 'edit') {
                  //  编辑
                  return (
                    <Timeline.Item>
                      <div className={styles?.logEdit}>
                        <span className={styles?.logTime}>{time}</span>
                        <div className={styles?.logInfo}>
                          {!_Array.isEmpty(editSubObjectNames) ? ( //  editSubObjectNames代表从对象
                            <div>
                              <span className={styles?.logSpan}>{operator?.name}</span>
                              <span className={styles?.logDo}>编辑了</span>
                              <span className={styles?.logSpan}>
                                {editSubObjectNames?.join(',')}
                              </span>
                            </div>
                          ) : null}
                          {fieldDetailVOS?.map((node: FieldDetailVOS) => {
                            return (
                              <>
                                <div>
                                  <span className={styles?.logSpan}>{operator?.name}</span>
                                  <span className={styles?.logDo}>将</span>
                                  <span className={styles?.logSpan}>{node?.fieldName}</span>
                                  <span className={styles?.logDo}>由</span>
                                  <span className={styles?.logSpan}>
                                    <Tooltip
                                      text={
                                        _String.isEmpty(node?.fieldValueFrom ?? '')
                                          ? replaceSign
                                          : node?.fieldValueFrom
                                      }
                                      width={200}
                                    />
                                  </span>
                                  <span className={styles?.logDo}>改为</span>
                                  <span className={styles?.logSpan}>
                                    <Tooltip
                                      text={
                                        _String.isEmpty(node?.fieldValueTo ?? '')
                                          ? replaceSign
                                          : node?.fieldValueTo
                                      }
                                      width={200}
                                    />
                                  </span>
                                </div>
                              </>
                            );
                          })}
                          {!_String.isEmpty(operateReason) && (
                            <div>
                              操作原因：<span className={styles?.logSpan}>{operateReason}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </Timeline.Item>
                  );
                }

                return (
                  <Timeline.Item>
                    <div className={styles?.logEdit}>
                      <span className={styles?.logTime}>{time}</span>
                      <div className={styles?.logInfo}>
                        <span className={styles?.logSpan}>{operator?.name}</span>
                        <span className={styles?.logDo}>对该数据执行了</span>
                        <span className={styles?.logSpan}>{operationName}</span>
                      </div>
                    </div>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          );
        },
      },
    ],
    column: 2,
  };

  return (
    <DetailLayoutForDrawer
      visible={visible}
      onClose={closeDetail}
      width={680}
      content={
        <DetailLayout loading={loading} title="操作记录" info={[baseInfo]} dataSource={showData} />
      }
    />
  );
};

export default SingleOperationLogComponent;
