import React, { useState } from 'react';
import { Popover, Tooltip } from 'antd';
import { Rule } from 'antd/lib/form';

import { requiredSign } from 'src/utils/constants';
import { BlIcon } from 'src/components';
import BatchPopoverContent from './BatchPopoverContent';
import Styles from './index.module.scss';
import color from '../styles/color';
import { QuestionCircleOutlined } from '@ant-design/icons';

interface StoreRequisitionTableHeaderProps {
  titleText: React.ReactNode;
  required?: boolean;
  popoverFieldComponent?: React.ReactNode;
  rules?: Rule[];
  batchOperation?: (batchValue: any) => void;
  tooltip?: string;
}

/**
 * @param required 该列是否必填，缺省为false
 * @param titleText 列名字符串
 * @param batchOperation 批量操作回调函数，参数是批量设置的值（目前只支持单个字段）
 * @param popoverFieldComponent 填写批量值时使用的组件，不填默认为input
 * @param rules 该字段的校验规则，定义同antd
 */
export default function BatchOperateTableHeader({
  titleText,
  required = false,
  popoverFieldComponent,
  rules,
  batchOperation,
  tooltip,
}: StoreRequisitionTableHeaderProps) {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const hidePopover = () => {
    setPopoverVisible(false);
  };

  return (
    <div>
      {required && <span className={Styles.requiredMark}>{requiredSign}</span>}
      <span>
        {titleText}
        {tooltip ? (
          <Tooltip title={tooltip}>
            <QuestionCircleOutlined
              style={{ color: 'rgba(0, 0, 0, 0.45)', cursor: 'help', marginLeft: 4 }}
            />
          </Tooltip>
        ) : null}
      </span>
      {batchOperation && (
        <Popover
          title="批量设置"
          visible={popoverVisible}
          onVisibleChange={setPopoverVisible}
          placement="bottom"
          content={
            <BatchPopoverContent
              fieldName={titleText}
              fieldComponent={popoverFieldComponent}
              rules={rules}
              onFinish={(batchFormValues) => {
                batchOperation(batchFormValues.batchInput);
                hidePopover();
              }}
              onClose={hidePopover}
            />
          }
          trigger="click"
        >
          <span style={{ marginLeft: 5 }}>
            <BlIcon type="iconpiliang" style={{ color: color.primaryColor }} />
          </span>
        </Popover>
      )}
    </div>
  );
}
