import { Form, Input, FormInstance, Button, Select } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/es/table';
import _ from 'lodash';

import {
  BlSortFormList,
  SearchSelect,
  BatchOperateTableHeader as TableHeader,
  SearchSelectModal,
} from 'src/components';
import { lookup } from 'src/dict';
import { CRUD, UsageStatus } from 'src/dict/common';
import { validateBlText1AndWhiteSpace } from 'src/page/knowledgeManagement/share/warehouseRules';
import {
  validateBlInputNumberInt,
  validatorNumberDigtis,
} from 'src/page/custom_fields/fieldsList/components/verificationRules/verificationRules';
import authDict, { hasAuth } from 'src/utils/auth';

import { transferFieldLabels as fieldLabels, TransferOrderTableRowType } from '../../constants';
import { goToMaterialDetail } from '../../utils';

interface TransferTableProps {
  form: FormInstance;
  useNumberRule: boolean;
  showMergeResult: boolean;
}

/** 表单字段名 */
const tableDataFormFieldName = 'transferMaterials';
/** 默认精度 */
const DEFAULT_PRECISION = 10;

/**
 * 更新表单数据中，合并列的rowSpan和行号
 * @param 更新前的表单数据
 * @return 更新后的表单数据
 * */
const updateRowSpanAndLine: (
  prevList: TransferOrderTableRowType[],
) => TransferOrderTableRowType[] = (prevList) => {
  if (prevList.length !== 0) {
    let updateIndex = 0;
    let groupMergeCredential = prevList[0].mergeCredential;
    let rowSpan = 0;

    // 计算rowSpan，当本行领料单号不变，rowSpan+1，否则重置变量，设置rowSpan
    prevList.forEach((line, index) => {
      if (line.mergeCredential === groupMergeCredential) {
        rowSpan += 1;
        line.line = rowSpan;
        line.rowSpan = 0;
      } else {
        prevList[updateIndex].rowSpan = rowSpan;
        updateIndex = index;
        groupMergeCredential = line.mergeCredential;
        line.line = 1;
        rowSpan = 1;
      }
    });

    // 循环结束后设置最后一项rowSpan
    prevList[updateIndex].rowSpan = rowSpan;
  }
  return prevList;
};

export default function TransferTable({
  form,
  useNumberRule,
  showMergeResult,
}: TransferTableProps) {
  const { getFieldValue } = form;

  /** delete方法是BlSortFormList暴露的，在那之后要重新计算更新所有行的rowSpan */
  const updateAfterDelete = () => {
    const formList: TransferOrderTableRowType[] = _.cloneDeep(
      form.getFieldValue(tableDataFormFieldName),
    );

    form.setFieldsValue({ [tableDataFormFieldName]: updateRowSpanAndLine(formList) });
  };

  /**
   * 批量更新表格表单数据
   * @param batchInput 批量设置的值
   * @param path 批量设置路径
   *  */
  const handleBatchSet = (batchInput: string | number, path: Array<string> | string) => {
    const formList: TransferOrderTableRowType[] = _.cloneDeep(
      form.getFieldValue(tableDataFormFieldName),
    );

    formList.forEach((line) => {
      _.set(line, path, batchInput);
    });

    form.setFieldsValue({ [tableDataFormFieldName]: formList });
  };

  const getColumns: (
    remove: (index: number | number[]) => void,
  ) => ColumnProps<any & FormListFieldData>[] = (remove: (index: number | number[]) => void) => {
    return _.compact([
      showMergeResult && {
        title: <TableHeader titleText={fieldLabels.transferOrderCode} />,
        dataIndex: 'code',
        width: 170,
        fixed: true,
        render: (text, field) => ({
          children: (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'code']}
              name={[field.name, 'code']}
              rules={[
                {
                  required: !useNumberRule,
                  message: `${fieldLabels.transferOrderCode}必填`,
                },
                {
                  validator: validateBlText1AndWhiteSpace(),
                },
                { max: 256, message: '不可超过255个字符' },
              ]}
            >
              <Input
                placeholder={useNumberRule ? '保存后按规则生成' : '请输入'}
                disabled={useNumberRule}
              />
            </Form.Item>
          ),
          props: {
            rowSpan: getFieldValue([tableDataFormFieldName, field.name, 'rowSpan']),
          },
        }),
      },
      {
        title: (
          <TableHeader
            titleText={fieldLabels.sourceWarehouse}
            required
            batchOperation={(batchInput) => {
              handleBatchSet(batchInput, ['sourceWarehouse']);
            }}
            popoverFieldComponent={
              <SearchSelectModal
                fetchType="warehouse"
                params={{ enableFlag: UsageStatus.enabled }}
                placeholder={`请选择${fieldLabels.sourceWarehouse}`}
              />
            }
          />
        ),
        dataIndex: ['sourceWarehouse'],
        width: 300,
        render: (text, field) => ({
          children: (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'sourceWarehouse']}
              name={[field.name, 'sourceWarehouse']}
              rules={[{ required: true, message: `${fieldLabels.sourceWarehouse}必填` }]}
            >
              <SearchSelectModal
                fetchType="warehouse"
                params={{ enableFlag: UsageStatus.enabled }}
                placeholder={`请选择${fieldLabels.sourceWarehouse}`}
              />
            </Form.Item>
          ),
          props: {
            rowSpan: getFieldValue([tableDataFormFieldName, field.name, 'rowSpan']),
          },
        }),
      },
      {
        title: (
          <TableHeader
            titleText={fieldLabels.targetWarehouse}
            required
            batchOperation={(batchInput) => {
              handleBatchSet(batchInput, ['targetWarehouse']);
            }}
            popoverFieldComponent={
              <SearchSelectModal
                fetchType="warehouse"
                params={{ enableFlag: UsageStatus.enabled }}
                placeholder={`请选择${fieldLabels.targetWarehouse}`}
              />
            }
          />
        ),
        dataIndex: ['targetWarehouse'],
        width: 300,
        render: (text, field) => ({
          children: (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'targetWarehouse']}
              name={[field.name, 'targetWarehouse']}
              rules={[{ required: true, message: `${fieldLabels.targetWarehouse}必填` }]}
            >
              <SearchSelectModal
                fetchType="warehouse"
                params={{ enableFlag: UsageStatus.enabled }}
                placeholder={`请选择${fieldLabels.targetWarehouse}`}
              />
            </Form.Item>
          ),
          props: { rowSpan: getFieldValue([tableDataFormFieldName, field.name, 'rowSpan']) },
        }),
      },
      {
        title: fieldLabels.businessType,
        dataIndex: ['bizType'],
        width: 120,
        render: (text, field) => ({
          children: lookup(
            'storeRequisiton.TransferOrderBusinessTypeMap',
            getFieldValue([tableDataFormFieldName, field.name, 'bizType']),
          ),
          props: { rowSpan: getFieldValue([tableDataFormFieldName, field.name, 'rowSpan']) },
        }),
      },
      showMergeResult && {
        title: (
          <TableHeader
            titleText={fieldLabels.remark}
            rules={[{ max: 1000, message: '不可超过1000个字符' }]}
            batchOperation={(batchInput) => {
              handleBatchSet(batchInput, ['remark']);
            }}
          />
        ),
        dataIndex: ['remark'],
        width: 180,
        render: (text, field) => ({
          children: (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'remark']}
              name={[field.name, 'remark']}
              rules={[{ max: 1000, message: '不可超过1000个字符' }]}
            >
              <Input placeholder="请输入" maxLength={1000} />
            </Form.Item>
          ),
          props: { rowSpan: getFieldValue([tableDataFormFieldName, field.name, 'rowSpan']) },
        }),
      },
      {
        title: fieldLabels.line,
        dataIndex: ['line'],
        width: 70,
        render: (text, field) => getFieldValue([tableDataFormFieldName, field.name, 'line']),
      },
      {
        title: fieldLabels.material,
        dataIndex: ['material'],
        width: 160,
        render: (text, field) => {
          return hasAuth(authDict.material_detail) ? (
            <a
              onClick={() =>
                goToMaterialDetail(
                  getFieldValue([tableDataFormFieldName, field.name, 'material', 'baseInfo', 'id']),
                )
              }
            >
              {getFieldValue([tableDataFormFieldName, field.name, 'material', 'baseInfo', 'code'])}/
              {getFieldValue([tableDataFormFieldName, field.name, 'material', 'baseInfo', 'name'])}
            </a>
          ) : (
            `${getFieldValue([
              tableDataFormFieldName,
              field.name,
              'material',
              'baseInfo',
              'code',
            ])}/${getFieldValue([
              tableDataFormFieldName,
              field.name,
              'material',
              'baseInfo',
              'name',
            ])}`
          );
        },
      },
      {
        title: (
          <TableHeader
            titleText={fieldLabels.requestAmount}
            required
            batchOperation={(batchInput) => {
              handleBatchSet(batchInput, ['requestAmount']);
            }}
            rules={[
              {
                validator: validateBlInputNumberInt(0, 10000000, true, false),
              },
              {
                // TODO 精度不应hardcode，应从单位读取，目前接口单位信息不包含精度，等待更新
                validator: validatorNumberDigtis(8),
              },
            ]}
          />
        ),
        dataIndex: ['requestPickAmount', 'amount'],
        width: 120,
        render: (text, field) => {
          /** 当前选中的单位id */
          const unitId = getFieldValue([tableDataFormFieldName, field.name, 'unitId']);
          /** 当前行绑定的物料单位信息列表 */
          const unitsInfoList = getFieldValue([
            tableDataFormFieldName,
            field.name,
            'unitsInfoList',
          ]);
          /** 找到单位具体信息，获取精度 */
          const { enablePrecision, precisionFigure } = _.find(unitsInfoList, { id: unitId });

          return (
            <Form.Item
              style={{ margin: 0 }}
              dependencies={[field.fieldKey, 'unitId']}
              fieldKey={[field.fieldKey, 'requestAmount']}
              name={[field.name, 'requestAmount']}
              rules={[
                { required: true, message: `${fieldLabels.requestAmount}必填` },
                {
                  // 申请数必须小于10000000，大于0
                  validator: validateBlInputNumberInt(
                    getFieldValue([tableDataFormFieldName, field.name, 'issuePickAmount']) ?? 0,
                    10000000,
                    true,
                    false,
                    '申请数',
                  ),
                },
                {
                  // 申请数精度为当前选中单位的精度，如果没有，默认为8
                  validator: validatorNumberDigtis(
                    enablePrecision && precisionFigure ? precisionFigure : DEFAULT_PRECISION,
                  ),
                },
              ]}
            >
              <Input placeholder="请输入" />
            </Form.Item>
          );
        },
      },
      {
        title: fieldLabels.unit,
        dataIndex: ['unitId'],
        width: 130,
        render: (text, field) => {
          const unitOptions = getFieldValue([tableDataFormFieldName, field.name, 'unitsOptions']);

          return (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'unitid']}
              name={[field.name, 'unitId']}
              rules={[{ required: true, message: `${fieldLabels.unit}必填` }]}
            >
              <Select options={unitOptions} placeholder="请选择" />
            </Form.Item>
          );
        },
      },
      {
        title: (
          <TableHeader
            titleText={fieldLabels.lineRemark}
            rules={[{ max: 1000, message: '不可超过1000个字符' }]}
            batchOperation={(batchInput) => {
              handleBatchSet(batchInput, ['lineRemark']);
            }}
          />
        ),
        dataIndex: ['lineRemark'],
        width: 180,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'lineRemark']}
              name={[field.name, 'lineRemark']}
              rules={[{ max: 1000, message: '不可超过1000个字符' }]}
            >
              <Input placeholder="请输入" maxLength={1000} />
            </Form.Item>
          );
        },
      },
      // {
      //   title: fieldLabels.requireTime,
      //   dataIndex: ['requirementTime'],
      //   width: 180,
      //   render: (text, field) =>
      //     formatTimeForRender(
      //       getFieldValue([tableDataFormFieldName, field.name, 'requirementTime']),
      //     ),
      // },
      {
        title: fieldLabels.batchNo,
        dataIndex: ['batchNo'],
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'batchNo']}
              name={[field.name, 'batchNo']}
              required
            >
              <SearchSelect
                fetchType="batchNo"
                placeholder="请输入"
                params={{
                  materialIds: [
                    getFieldValue([
                      tableDataFormFieldName,
                      field.name,
                      'material',
                      'baseInfo',
                      'id',
                    ]),
                  ],
                }}
                labelInValue
              />
            </Form.Item>
          );
        },
      },
      {
        title: fieldLabels.supplier,
        dataIndex: ['supplier'],
        width: 120,
        render: (text, field) =>
          getFieldValue([tableDataFormFieldName, field.name, 'supplier', 'label']),
      },
      {
        title: fieldLabels.storeRequisitionCode,
        dataIndex: ['pickOrderCode'],
        width: 130,
        render: (text, field) =>
          getFieldValue([tableDataFormFieldName, field.name, 'pickOrderCode']),
      },
      {
        title: fieldLabels.storeRequisitionLine,
        dataIndex: ['pickOrderLine'],
        width: 120,
        render: (text, field) =>
          getFieldValue([tableDataFormFieldName, field.name, 'pickOrderLine']),
      },
      {
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width: 100,
        render: (text, field) => {
          return (
            <Form.Item style={{ margin: 0 }}>
              <Button
                style={{ padding: 0 }}
                type="link"
                onClick={() => {
                  remove(field?.name);
                  updateAfterDelete();
                }}
              >
                {lookup('crud', CRUD.delete)}
              </Button>
            </Form.Item>
          );
        },
      },
    ]);
  };

  return (
    <BlSortFormList
      name={tableDataFormFieldName}
      renderColumns={getColumns}
      isNeedDrag={false}
      isNeedAdd={false}
      isNeedDelete={false}
    />
  );
}
