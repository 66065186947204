const baseUrl = '/analyse';
const templateUrl = `${baseUrl}/customReport/template/list`;
const matchUrl = `${baseUrl}/customReport/matchRule/list`;
const electronicDocumentsUrl = `${baseUrl}/customReport/electronicDocuments/list`;

// 电子单据模板

export const toElectronicTemplateDetail = (id: number) => {
  return `${templateUrl}/${id}/detail`;
};
export const toElectronicTemplateEdit = (id: number, type: String) => {
  return `${templateUrl}/${id}/edit?type=${type}`;
};
export const toElectronicTemplateCreate = () => {
  return `${templateUrl}/create`;
};

export const toElectronicTemplateList = () => {
  return `${templateUrl}`;
};

// 对象匹配规则

export const toMatchRuleDetail = (id: number) => {
  return `${matchUrl}/${id}/detail`;
};
export const toMatchRuleEdit = (id: number, type: String) => {
  return `${matchUrl}/${id}/edit?type=${type}`;
};
export const toMatchRuleCreate = () => {
  return `${matchUrl}/create`;
};

export const toMatchRuleTemplateList = () => {
  return `${matchUrl}`;
};

// 电子单据详情
export const toElectronicDocumentsDetail = (id: number) => {
  return `${electronicDocumentsUrl}/${id}/detail`;
};
