import { FormInstance } from 'antd';
import { useRef } from 'react';
import { RecordCheckItemTypeEnum } from 'src/dict/quality';
import { CheckItem } from 'src/page/quality/models/dto';
import { isUnQuality } from 'src/page/quality/models/utils/qcTask';
import { InputRender } from '../InputRender';

interface Props {
  checkItem: CheckItem;
  form: FormInstance;
  fieldName: (number | string)[];
  commonNamePath: (number | string)[];
  disabled?: boolean;
}

export const RecordValue = ({ disabled, checkItem, form, fieldName, commonNamePath }: Props) => {
  const inputRef = useRef<any>(null);

  return (
    <InputRender
      {...checkItem}
      fieldName={[...fieldName]}
      ref={inputRef}
      isRequired={
        form.getFieldValue([...commonNamePath, ...fieldName, 'recordCheckItemType']) ===
        RecordCheckItemTypeEnum.yes
      }
      onChange={() => {
        const currentResult = form.getFieldValue([...commonNamePath, ...fieldName]);

        form.setFields([
          {
            name: [...commonNamePath, ...fieldName, 'isUnQuality'],
            value: isUnQuality({
              result: currentResult,
              checkItem,
            }),
          },
        ]);
        inputRef.current?.refresh?.(
          isUnQuality({
            result: currentResult,
            checkItem,
          }),
        );
      }}
    />
  );
};
