/**
 * @mappings SOP模块名-值映射表
 */

import {
  BizType,
  StepEntityType,
  PrivilegeType,
  DigitalType,
  NextStepType,
  GroupType,
  LoopRule,
  LoopExecRestrictRule,
  ControlType,
  ShowLogic,
  DefaultLogic,
  BizProperty,
  NumericType,
  TimeType,
  UserOptionalRange,
  StationOptionalRange,
  UploadMethod,
  FileType,
  SelectorMethodType,
  TaskStatus,
  SopOperationType,
  ParamGetType,
  ObjectEntitySource,
  ResourceBusinessType,
  FieldSource,
  ControlOperationRecord,
  NumericRangeLimitLogic,
} from '.';
import { ObjectCode, YNB } from '../common';
import { mappingsFactory, extendedMappingsFactory, findByValue } from '../utils';

export const bizType = mappingsFactory(
  ['通用业务类型', BizType.general],
  ['生产业务类型', BizType.production],
  ['维保业务类型', BizType.maintenance],
  ['维修业务类型', BizType.repair],
);

export const stepEntityType = mappingsFactory(
  ['步骤', StepEntityType.step],
  ['步骤组', StepEntityType.stepGroup],
);

export const privilegeType = mappingsFactory(
  ['用户', PrivilegeType.users],
  ['部门', PrivilegeType.departments],
  ['角色', PrivilegeType.roles],
  ['任务执行人', PrivilegeType.executors],
);

export const digitalType = mappingsFactory(
  ['用户', DigitalType.users],
  ['角色', DigitalType.roles],
);

export const nextStepType = mappingsFactory(
  ['下个步骤', NextStepType.next],
  ['人工选择', NextStepType.specified],
  // ['条件判断', NextStepType.condition],
  ['自定义函数判断', NextStepType.condition],
);

export const groupType = mappingsFactory(['串行', GroupType.serial], ['并行', GroupType.parallel]);

export const loopRule = mappingsFactory(
  ['循环次数', LoopRule.count],
  ['单次循环时间限制', LoopRule.time],
  ['下个循环执行的标准', LoopRule.condition],
);

export const loopExecRestrictRule = mappingsFactory(
  ['上个循环结束后执行下个循环', LoopExecRestrictRule.inTurn],
  ['到达循环开始时间即可执行', LoopExecRestrictRule.inTime],
);

export const controlType = mappingsFactory(
  ['单行文本', ControlType.single],
  ['多行文本', ControlType.multiLine],
  ['数字', ControlType.numeric],
  ['选择器', ControlType.selector],
  ['时间', ControlType.time],
  ['附件', ControlType.file],
  ['用户', ControlType.user],
  ['工位', ControlType.station],
  ['报工', ControlType.report],
  ['投料', ControlType.feed],
  ['关联数据', ControlType.associatedData],
  ['关联字段', ControlType.associatedField],
  ['资源参数-固定参数', ControlType.resourceWithFixedParams],
  ['资源参数-选择参数', ControlType.resourceWithSelectedParams],
  ['创建检验任务', ControlType.qcTaskOfSOP],
  ['资源报修', ControlType.resourceRepair],
  ['编号', ControlType.numberRules],
);

export const editable = extendedMappingsFactory(['只读', YNB.no], ['可编辑', YNB.yes]);

export const showLogic = mappingsFactory(
  ['业务字段', ShowLogic.biz],
  ['固定值', ShowLogic.fixed],
  ['自定义函数值', ShowLogic.trigger],
  ['填写当时', ShowLogic.fillTime],
  ['指定用户', ShowLogic.fixedUser],
  ['当前工位', ShowLogic.currentStation],
  ['固定工位', ShowLogic.fixedStation],
  ['当前执行用户', ShowLogic.currentUser],
);
export const textShowLogic = [showLogic[1]];
export const numericShowLogic = [showLogic[1], showLogic[2]];
export const timeShowLogic = [showLogic[3], showLogic[1]];
export const userShowLogic = [showLogic[7], showLogic[4]];
export const stationShowLogic = [showLogic[5], showLogic[6]];

export const defaultLogic = mappingsFactory(
  ['无', DefaultLogic.none],
  ['业务字段', DefaultLogic.biz],
  ['固定值', DefaultLogic.fixed],
  ['自定义函数值', DefaultLogic.trigger],
  ['填写当时', DefaultLogic.fillTime],
  ['固定用户', DefaultLogic.fixedUser],
  ['当前工位', DefaultLogic.currentStation],
  ['固定工位', DefaultLogic.fixedStation],
  ['当前执行用户', DefaultLogic.currentUser],
);
const findFromDefaultLogic = findByValue(defaultLogic);

export const textDefaultLogic = [
  findFromDefaultLogic(DefaultLogic.none),
  findFromDefaultLogic(DefaultLogic.fixed),
];
export const numericDefaultLogic = [
  findFromDefaultLogic(DefaultLogic.none),
  findFromDefaultLogic(DefaultLogic.fixed),
  findFromDefaultLogic(DefaultLogic.trigger),
];

export const numericRangeLimitLogic = mappingsFactory(
  ['无', NumericRangeLimitLogic.none],
  ['区间', NumericRangeLimitLogic.range],
);
export const timeDefaultLogic = [
  findFromDefaultLogic(DefaultLogic.none),
  findFromDefaultLogic(DefaultLogic.fillTime),
  findFromDefaultLogic(DefaultLogic.fixed),
];
export const userDefaultLogic = [
  findFromDefaultLogic(DefaultLogic.none),
  ...mappingsFactory(['指定用户', DefaultLogic.fixedUser]),
  ...mappingsFactory(['当前执行用户', DefaultLogic.currentUser]),
];
export const stationDefaultLogic = [
  findFromDefaultLogic(DefaultLogic.none),
  findFromDefaultLogic(DefaultLogic.currentStation),
  ...mappingsFactory(['固定工位', DefaultLogic.fixed]),
];
export const fileDefaultLogic = [
  findFromDefaultLogic(DefaultLogic.none),
  ...mappingsFactory(['固定文件', DefaultLogic.fixed]),
];

export const bizProperty = mappingsFactory(
  ['SOP方案任务编号', BizProperty.sopTaskNo],
  ['所属工序名称', BizProperty.processName],
  ['所属工序备注', BizProperty.processRemark],
  ['所属项目编号', BizProperty.projectNo],
  ['所属项目状态', BizProperty.projectStatus],
  ['所属项目计划产出物料', BizProperty.projectPlanOutputMaterial],
  ['所属项目计划产出物料规格', BizProperty.projectPlanOutputMaterialSpec],
  ['所属项目备注', BizProperty.projectRemark],
  ['所属订单编号', BizProperty.orderNo],
  ['所属订单客户', BizProperty.orderCustomer],
  ['所属订单备注', BizProperty.orderRemark],
  ['SOP方案任务状态', BizProperty.sopTaskStatus],
  ['所属生产批次批次号', BizProperty.productionBatchNo],
);

export const numericBizProperty = mappingsFactory(
  ['所属项目计划产出数量', BizProperty.projectPlanOutputAmount],
  ['所属项目实际产出数量', BizProperty.projectActualOutputAmount],
  ['SOP方案任务实际产出数量', BizProperty.sopTaskActualOutputAmount],
);

export const numericType = mappingsFactory(
  ['数值', NumericType.value],
  ['百分比', NumericType.percentage],
);

export const timeType = mappingsFactory(
  ['年月日', TimeType.ymd],
  ['年月日+时分', TimeType.ymdhm],
  ['年月日+时分秒', TimeType.ymdhms],
  ['月日+时分', TimeType.mdhm],
  ['日+时分', TimeType.dhm],
  ['时分秒', TimeType.hms],
);

export const timeBizProperty = mappingsFactory(
  ['SOP方案任务计划开始时间', BizProperty.sopTaskPlanStartTime],
  ['SOP方案任务计划结束时间', BizProperty.sopTaskPlanEndTime],
  ['SOP方案任务实际开始时间', BizProperty.sopTaskActualStartTime],
  ['SOP方案任务实际结束时间', BizProperty.sopTaskActualEndTime],
  ['项目创建时间', BizProperty.projectCreateTime],
  ['项目计划开始时间', BizProperty.projectPlanStartTime],
  ['项目计划结束时间', BizProperty.projectPlanEndTime],
  ['项目实际开始时间', BizProperty.projectActualStartTime],
  ['订单交货日期', BizProperty.orderDeliveryDate],
);

export const userOptionalRange = mappingsFactory(
  // ['全部用户', UserOptionalRange.all],
  ['当前执行用户', UserOptionalRange.current],
  ['指定用户', UserOptionalRange.appoint],
);

export const stationOptionalRange = mappingsFactory(
  ['全部工位', StationOptionalRange.all],
  ['指定工位', StationOptionalRange.appoint],
  ['当前工位', StationOptionalRange.current],
  ['当前工位所在车间的工位', StationOptionalRange.currentWorkshop],
  ['当前工位所在的产线的工位', StationOptionalRange.currentProductionLine],
);

export const uploadable = extendedMappingsFactory(['只读', YNB.no], ['可上传', YNB.yes]);

export const uploadMethod = mappingsFactory(
  ['本地上传', UploadMethod.localFile],
  ['拍摄上传', UploadMethod.photo],
);

export const fileType = mappingsFactory(
  ['文档', FileType.document],
  ['图片', FileType.image],
  ['音频', FileType.audio],
  ['视频', FileType.video],
  ['压缩包', FileType.compressed],
);

export const fileBizProperty = mappingsFactory(
  ['所属工序附件', BizProperty.processFile],
  ['所属项目附件', BizProperty.projectFile],
  ['所属订单附件', BizProperty.orderFile],
);
export const selectorMethodType = mappingsFactory(
  ['单选', SelectorMethodType.single],
  ['多选', SelectorMethodType.multiple],
);

export const taskStatus = mappingsFactory(
  ['新建', TaskStatus.created],
  ['已删除', TaskStatus.deleted],
  ['已下发', TaskStatus.ready],
  ['已取消', TaskStatus.canceled],
  ['执行中', TaskStatus.executing],
  ['暂停中', TaskStatus.suspended],
  ['已结束', TaskStatus.finished],
);

export const taskPrivilegeType = mappingsFactory(
  ['用户（用户）', PrivilegeType.users],
  ['生产部门（部门）', PrivilegeType.departments],
  ['工程人员（角色）', PrivilegeType.roles],
  ['任务执行人', PrivilegeType.executors],
);

export const executiveStaff = privilegeType.slice(0, 3);

export const sopOperationType = mappingsFactory(
  ['创建SOP方案', SopOperationType.createSop],
  ['启用SOP方案', SopOperationType.enableSop],
  ['停用SOP方案', SopOperationType.disableSop],
  ['编辑SOP方案', SopOperationType.editSop],
  ['删除SOP方案', SopOperationType.deleteSop],
  ['添加步骤', SopOperationType.addStep],
  ['添加步骤组', SopOperationType.addStepGroup],
  ['复制步骤', SopOperationType.copyStep],
  ['复制步骤组', SopOperationType.copyStepGroup],
  ['删除步骤', SopOperationType.deleteStep],
  ['删除步骤组', SopOperationType.deleteStepGroup],
);

export const objectCodeRange = [
  ...mappingsFactory(['供应商', ObjectCode.Supplier]),
  ...mappingsFactory(['仓库', ObjectCode.Warehouse]),
];
export const paramGetType = mappingsFactory(
  ['手动输入', ParamGetType.manual],
  ['API手动获取', ParamGetType.api],
);

// wendy TODO  待sop方案添加 资源业务类型时，再展示
export const objectEntitySource = mappingsFactory(
  ['生产任务', ObjectEntitySource.product],
  ['维保任务', ObjectEntitySource.maintenance],
  ['维修任务', ObjectEntitySource.repairTask],
  ['手动选择', ObjectEntitySource.manual],
);

export const resourceType = mappingsFactory(
  ['设备', ResourceBusinessType.equipment],
  ['能源仪表', ResourceBusinessType.energy],
);

export const fieldSource = [
  ...mappingsFactory(['本表字段', FieldSource.currentSheet]),
  ...mappingsFactory(['他表字段', FieldSource.otherSheet]),
];

export const controlOperationRecord = mappingsFactory(
  ['新建', ControlOperationRecord.created],
  ['编辑', ControlOperationRecord.edited],
);
