import React, { useState, useEffect } from 'react';
import { DataFormLayout } from 'layout';
import { Form, Spin } from 'antd';
import { QcConfigDetailProps, CheckItemGroup } from '../index.d';
import { gcUrl } from 'src/utils';
import {
  fetchQcConfigCreate,
  fetchQcConfigDetail,
  fetchQcConfigUpdate,
} from 'src/api/ytt/quality-domain/qcConfig';
import TabContent from './components/tabContent';
import _ from 'lodash';
import {
  CheckEntityTypeEnum,
  RecordSampleEnum,
  ReportPageTypeEnum,
  CheckItemInputTypeEnum,
  isRecordCountSampleEnum,
} from 'src/dict/quality';
import lookup, { appEnum } from 'src/dict';
import { UsageStatus } from 'src/dict/common';
import {
  formatCustomFieldsInData,
  initCustomFieldsInData,
  useCustomFieldCombinedData,
} from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

const QcConfigCreate = (props: QcConfigDetailProps) => {
  const { history } = props;
  const [createForm] = Form.useForm();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { id, isCopy } = gcUrl.getParams();
  const initialValues = {
    checkType: appEnum.Quality.CheckTypeEnum.inputFactoryQc,
    checkEntityType: CheckEntityTypeEnum.SameAsBatchCount,
    reportPageType: ReportPageTypeEnum.pageByCheckItem,
    recordSample: RecordSampleEnum.record,
  };
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.qcConfig);

  const formatCheckItemSelect = (checkItem: any, checkItemIndex: number) => {
    const {
      checkItemId,
      checkItemName,
      checkItemCategoryId,
      checkItemCategoryName,
      unitVO,
      defectRankList,
      defectReasonList,
      qcAqlInspectionLevel,
      executeItemType,
      textInputType,
      unit,
      equipments,
      checkItemCode,
      defaultValue,
      qcAql,
      qcAqlCategory,
      ...rest
    } = checkItem;

    return {
      checkItem: { label: `${checkItemName}/${checkItemCode}`, value: checkItemId },
      checkItemCategory: { label: checkItemCategoryName, value: checkItemCategoryId },
      unitVO: unitVO ? { label: unitVO.name, value: unitVO.id } : unitVO,
      defectRanks: defectRankList.map((rank: { name: any; id: any }) => {
        return { label: rank.name, value: rank.id };
      }),
      defectReasons: defectReasonList.map((reason: { name: any; id: any }) => {
        return { label: reason.name, value: reason.id };
      }),
      executeItemType:
        executeItemType === CheckItemInputTypeEnum.multipleText
          ? CheckItemInputTypeEnum.inputFormatText
          : executeItemType,
      textInputType: executeItemType,
      unit: unit ? { label: unit.name, value: unit.id } : null,
      equipments: equipments?.map((equipment: { name: string; id: number }) => ({
        label: equipment.name,
        value: equipment.id,
      })),
      qcAql: qcAql ? { value: qcAql.id, label: qcAql.name } : null,
      qcAqlCategory: qcAql
        ? { value: qcAqlCategory, label: lookup('quality.aqlTypes', qcAqlCategory) }
        : null,
      qcAqlInspectionLevel: qcAqlInspectionLevel
        ? { value: qcAqlInspectionLevel?.id, label: qcAqlInspectionLevel?.name }
        : qcAqlInspectionLevel,
      key: `${checkItemIndex}-${checkItemId}`,
      defaultValue:
        executeItemType === CheckItemInputTypeEnum.inputFormatMultiple
          ? defaultValue?.split(',')
          : defaultValue,
      ...rest,
    };
  };

  const formatSubmitData = (data: any) => {
    const value = formatCustomFieldsInData({ data: _.cloneDeep(data), customFields });
    const {
      qcAql,
      qcAqlInspectionLevel,
      checkItemList,
      qcConfigMaterialList,
      qcConfigTriggerRule,
      triggerEnable,
      ...rest
    } = value;

    const qcConfigTriggerRuleList = triggerEnable
      ? qcConfigTriggerRule?.triggerValue?.map((i: number) => ({
          triggerValue: i,
          triggerType: qcConfigTriggerRule.triggerType,
        }))
      : [];
    const qcConfigCheckItemList = _.flatMap(checkItemList, (group: CheckItemGroup) => {
      const { list, name } = group;

      const newList = list?.map((item) => {
        const {
          checkItem,
          checkItemCategory,
          unit,
          checkCount,
          defectRanks,
          defectReasons,
          qcAql,
          qcAqlCategory,
          qcAqlInspectionLevel,
          executeItemType,
          textInputType,
          defaultValue,
          executeRemark,
          executeUpload,
          equipments,
          key,
          id,
          ...rest
        } = item;

        return {
          checkCountType: appEnum.Quality.CheckCountTypeEnum.none,
          checkItemId: checkItem.value,
          checkItemCategoryId: checkItemCategory.value,
          unitId: unit?.value,
          checkCount: Number(checkCount),
          defectRankIds: defectRanks?.map((defectRank: { value: number }) => defectRank.value),
          defectReasonIds: defectReasons?.map(
            (defectReason: { value: number }) => defectReason.value,
          ),
          qcAqlInspectionLevelId: qcAqlInspectionLevel?.value,
          qcAqlId: qcAql?.value,
          qcAqlCategory: qcAqlCategory?.value,
          executeItemType:
            executeItemType === CheckItemInputTypeEnum.inputFormatText
              ? textInputType
              : executeItemType,
          defaultValue: defaultValue instanceof Array ? defaultValue.join(',') : defaultValue,
          ...rest,
          groupName: name,
          executeRemark: Boolean(executeRemark),
          executeUpload: Boolean(executeUpload),
          equipmentIds: equipments?.map(
            (equipment: { label: string; value: number }) => equipment.value,
          ),
          id: !isCopy ? id : null,
        };
      });

      return newList;
    });

    return {
      qcConfigCheckItemList,
      qcAqlId: qcAql?.value,
      qcAqlInspectionLevelId: qcAqlInspectionLevel?.value,
      qcConfigMaterialList: qcConfigMaterialList?.map(
        ({ id, qcUnit }: { id: number; category: number; qcUnit: any }) => {
          return {
            id,
            qcUnitId: qcUnit?.value,
          };
        },
      ),
      qcConfigTriggerRuleList,
      triggerEnable,
      ...rest,
    };
  };

  const getQcConfigData = async () => {
    const { data, fieldPermission } = await fetchQcConfigDetail({ id }, { legacy: true });
    const {
      qcConfigCheckItemList,
      qcConfigMaterialList,
      qcConfigTriggerRuleList,
      recordSummaryCount,
      ...rest
    } = data || {};
    const groupList = _.groupBy(qcConfigCheckItemList || [], 'groupName');

    const qcConfigTriggerRule = {
      triggerValue: _.map(qcConfigTriggerRuleList, 'triggerValue'),
      triggerType: _.flatMap(_.map(qcConfigTriggerRuleList, 'triggerType')),
    };
    const checkItemList = Object.keys(groupList).map((key, index) => {
      return {
        key: index,
        name: key,
        list: groupList[key].map((checkItemData, checkItemIndex) =>
          formatCheckItemSelect(checkItemData, checkItemIndex),
        ),
      };
    });

    const formData = {
      checkItemList,
      qcConfigMaterialList: qcConfigMaterialList?.map((material) => {
        const { qcUnit, unit, ...rest } = material;

        return {
          qcUnit: {
            value: qcUnit?.id,
            label: `${qcUnit?.name}${qcUnit?.status === UsageStatus.disabled ? '(停用)' : ''}`,
          },
          unitName: unit?.name,
          ...rest,
        };
      }),
      recordSummaryCount: recordSummaryCount ?? isRecordCountSampleEnum.yes,
      encoding: fieldPermission?.encoding,
      qcConfigTriggerRule,
      ...rest,
    };

    if (isCopy) {
      delete formData.code;
    }
    return formData;
  };

  useEffect(() => {
    if (id) {
      setIsloading(true);
      getQcConfigData().then((formData) => {
        // 把需要格式化的数据一次传入 initCustomFieldsInData 中，格式化内所有自定义字段，也可分步处理
        const afterFormatCustomFielsValue = initCustomFieldsInData(formData);

        createForm.setFieldsValue({ ...formData, ...afterFormatCustomFielsValue });
        setIsloading(false);
      });
    } else {
      createForm.setFieldsValue({ ...initialValues });
    }
  }, []);

  const handleFinish = async () => {
    const data = await createForm?.validateFields();
    const submitData = formatSubmitData(data);
    let res = null;

    setSubmitting(true);
    try {
      if (isCopy || !id) {
        res = await fetchQcConfigCreate(submitData);
      }
      if (id && !isCopy) {
        submitData.id = id;
        res = await fetchQcConfigUpdate(submitData);
      }
      setSubmitting(false);
      if (res?.code === 200) {
        history.push('/quality/qualityModeling/qcConfig');
      }
    } catch (err) {
      setSubmitting(false);
    }
  };

  const getActionType = (id: number, isCopy: string) => {
    if (isCopy) {
      return '复制';
    }
    if (id) {
      return '编辑';
    }
    return '新建';
  };

  return isLoading ? (
    <div style={{ width: '100%' }}>
      <Spin style={{ width: '100%' }} />
    </div>
  ) : (
    <DataFormLayout
      title={`${getActionType(id, isCopy)}检验方案`}
      form={createForm}
      onFinish={handleFinish}
      onCancel={() => history.go(-1)}
      confirmLoading={submitting}
    >
      <TabContent
        form={createForm}
        isEdit={Boolean(id && !isCopy)}
        customFields={customFields}
        id={id}
      />
    </DataFormLayout>
  );
};

export default QcConfigCreate;
