/**
 * @description 自动转值的时间组件
 */
import { DatePicker, TimePicker } from 'antd';
import moment, { Moment } from 'moment';
import _ from 'lodash';

const disabledTime = (date: moment.Moment) => {
  let hours = moment().hours();
  let minutes = moment().minutes() + 3;
  // const seconds = moment().seconds();

  if (date && date.date() === moment().date()) {
    if (minutes - 59 > 0) {
      minutes -= 59;
      hours += 1;
    }
    return {
      disabledHours: () => _.range(0, hours),
      disabledMinutes: (selectedHour: number) => {
        return selectedHour === hours ? _.range(0, minutes) : [];
      },
      // disabledSeconds: (selectedHour: number, selectedMinute: number) => (selectedHour === hours && selectedMinute === minutes ? _.range(0, seconds) : []),
    };
  }
};

const disabledDate = (current: moment.Moment) => {
  // Can not select days before today
  return current && current < moment().startOf('day');
};

const MyDatePicker = (props: any) => {
  const {
    value: oldValue,
    onChange: oldChange,
    bannedBeforeNow = false,
    showTime = false,
    showNow = false,
  } = props;

  let newProps = {
    ...props,
    onChange: (m: Moment) => {
      oldChange?.(m?.valueOf());
    },
    value: _.isNil(oldValue) ? undefined : moment(oldValue),
    disabledDate: bannedBeforeNow ? disabledDate : undefined,
    disabledTime: bannedBeforeNow && showTime ? disabledTime : undefined,
    showTime: showTime ? { hideDisabledOptions: true } : undefined,
    showNow,
  };

  let Picker: any = DatePicker;

  if (props.picker === 'time') {
    const times = _.split(oldValue, ':').map(Number);

    Picker = TimePicker;
    newProps = {
      ...props,
      onChange: (m: Moment) => {
        oldChange?.(
          `${m?.get('hour').toString().padStart(2, '0')}:${m
            ?.get('minute')
            .toString()
            .padStart(2, '0')}:${m?.get('second').toString().padStart(2, '0')}`,
        );
      },
      value: _.isUndefined(oldValue)
        ? oldValue
        : moment().set('hours', times[0]).set('minutes', times[1]).set('seconds', times[2]),
    };
  }

  return <Picker {...newProps} allowClear={false} />;
};

export default MyDatePicker;
