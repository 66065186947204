import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Tag, Form, message, Modal } from 'antd';
import { DetailLayout } from 'layout';
import { BlIcon, BlTable, SingleOperationLogComponent, TagList } from 'components';
import useBlModal from 'src/components/modal/useBlModal';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { PurchaseExecStatus } from 'src/dict/purchase';
import { renderUser } from 'src/page/share/renderUser';
import { replaceSign } from 'src/utils/constants';
import {
  fetchPurchaseReturnOrderDetail,
  fetchPurchaseReturnOrderReturnLayer,
  fetchPurchaseReturnOrderDetailMaterial,
  FetchPurchaseReturnOrderDetailResponse,
} from 'src/api/ytt/order-domain/purchaseReturnOrder';
import lookup from 'src/dict';
import _ from 'lodash';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { Attribute } from '../interface';
import { renderAmount } from '../utils';
import { goToEdit, OutBoundCreatePath } from '../navigates';
import { endOrder, handleCloseOrder, handleDistribute, handleReturnOrder } from '../batchOperate';
import ReturnTable from '../components/returnTable';
import authDict from 'src/utils/auth';
import _Array from 'src/utils/dataTypes/array';
import { useDispatch } from 'react-redux';

interface DetailPageProps extends RouteComponentProps {}
type DataResource = Exclude<FetchPurchaseReturnOrderDetailResponse['data'], undefined>;

const DetailPage = (props: DetailPageProps) => {
  const { match, history } = props;
  // const { search } = useLocation();
  const orderId = Number(match?.params?.id);

  const [dataSource, setDataSource] = useState<DataResource>({});
  const [itemList, setItemList] = useState<any[]>([]);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const dispatch = useDispatch();
  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  const fetchData = async () => {
    try {
      if (!orderId) {
        return;
      }

      const [{ data }, { data: materialLine }] = await Promise.all([
        fetchPurchaseReturnOrderDetail({ id: Number(orderId) }),
        fetchPurchaseReturnOrderDetailMaterial({ id: Number(orderId) }),
      ]);

      setDataSource(data);
      setItemList(materialLine || []);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [refreshMarker]);

  const [form] = Form.useForm();
  const { show } = useBlModal();
  const batchReturn = async (ids: number[], onSuccess?: () => void, onFail?: () => void) => {
    const { data } = await fetchPurchaseReturnOrderReturnLayer({ ids });

    show({
      title: '退货',
      content: <ReturnTable selectedRows={data} name={'returnInfo'} form={form} />,
      width: 1200,
      onOk: async () => {
        const value = await form?.validateFields();

        const returnParams = value.returnInfo.map((value: any) => {
          return {
            id: value.id,
            deliverAmount: Number(value.deliverAmount),
          };
        });

        handleReturnOrder(returnParams, onSuccess, onFail);
      },
      onCancel: async () => {
        onFail?.();
      },
      closable: false,
      afterClose: async () => {
        onFail?.();
      },
    });
  };
  // 新建出库单
  const createOutBound = (list: any[], refresh: () => void) => {
    const filterMaterial = _.filter(list, (o) => o?.outboundSendAmount?.amount > 0);

    if (_.isEmpty(filterMaterial)) {
      refresh();
      message.error('暂无可出库物料');
      return;
    }
    dispatch({
      type: 'supplyChain/formatterPurchaseReturnOrderToOutBoundInfo',
      payload: filterMaterial,
    });
    history.push(OutBoundCreatePath());
  };

  const baseInfo = [
    {
      title: '基本信息',
      items: [
        { label: '编号', dataIndex: 'code' },
        {
          label: '来源',
          dataIndex: 'source',
          render: lookup('purchase.purchaseReturnSource'),
        },
        {
          label: '状态',
          dataIndex: 'execStatus',
          render: lookup('purchase.purchaseExecStatus'),
        },
        {
          label: '供应商',
          dataIndex: 'supplier',
          render: (supplier: any) => {
            if (supplier) {
              return `${supplier.code} / ${supplier.name}`;
            }
            return replaceSign;
          },
        },
        {
          label: '备注',
          dataIndex: 'remark',
          render: (value: string) => value ?? replaceSign,
        },
        {
          label: '创建人',
          dataIndex: 'creator',
          render: renderUser,
        },
        {
          label: '创建时间',
          dataIndex: 'createdAt',
          render: formatTimeForRender,
        },
        {
          label: '更新人',
          dataIndex: 'operator',
          render: renderUser,
        },
        {
          label: '更新时间',
          dataIndex: 'updatedAt',
          render: formatTimeForRender,
        },
      ],
    },
    {
      title: '物料信息',
      items: [
        {
          label: '',
          dataIndex: 'item',
          render: () => (
            <BlTable
              rowKey={'id'}
              style={{ width: '100%' }}
              scroll={{ x: 1200, y: 800 }}
              pagination={false}
              columns={_.compact([
                {
                  title: '行号',
                  dataIndex: 'seqNo',
                  width: 150,
                },
                {
                  title: '物料编号',
                  dataIndex: ['material', 'baseInfo', 'code'],
                  width: 150,
                  render: (value: string) => value,
                },
                {
                  title: '物料名称',
                  dataIndex: ['material', 'baseInfo', 'name'],
                  width: 150,
                  render: (value: string) => value,
                },
                {
                  title: '物料属性',
                  dataIndex: ['material', 'attribute'],
                  width: 250,
                  render: (attribute: Attribute[]) => {
                    if (_Array.isEmpty(attribute)) {
                      return replaceSign;
                    }
                    return (
                      <TagList
                        dataSource={_.map(attribute, ({ id, name, attributeItem }) => ({
                          label: `${name}:${attributeItem?.content}`,
                          value: id,
                        }))}
                      />
                    );
                  },
                },
                {
                  title: '应退数',
                  dataIndex: 'returnAmount',
                  width: 150,
                  render: renderAmount,
                },
                {
                  title: '应退时间',
                  dataIndex: 'returnTime',
                  width: 150,
                  render: formatTimeForRender,
                },
                {
                  title: '计划出库数',
                  dataIndex: 'planOutboundAmount',
                  width: 150,
                  render: renderAmount,
                },
                {
                  title: '发出数',
                  dataIndex: 'deliverAmount',
                  width: 150,
                  render: renderAmount,
                },
                {
                  title: '接收数',
                  dataIndex: 'receivedAmount',
                  width: 150,
                  render: renderAmount,
                },
                {
                  title: '采购订单编号',
                  dataIndex: 'purchaseOrderCode',
                  width: 250,
                  render: (value: string) => value,
                },
                {
                  title: '采购订单行号',
                  dataIndex: 'purchaseOrderSeqNo',
                  width: 150,
                  render: (value: string) => value,
                },
              ])}
              dataSource={itemList}
            />
          ),
        },
      ],
    },
  ];
  const { execStatus, id } = dataSource;
  const baseMenu = _.compact([
    {
      key: 'distribute',
      onClick: () => {
        if (!id) return;
        handleDistribute([id], refresh);
      },
      title: '下发',
      auth: authDict.purchasereturnorder_dispatch,
      disabled: execStatus !== PurchaseExecStatus.created,
    },
    {
      key: 'return',
      onClick: () => {
        if (!id) return;
        batchReturn([id], refresh, refresh);
      },
      title: '退货',
      auth: authDict.purchasereturnorder_return,
      disabled: execStatus !== PurchaseExecStatus.executing,
    },
    {
      key: 'return',
      onClick: () => {
        createOutBound(itemList, refresh);
      },
      title: '新建出库单',
      auth: authDict.outboundorder_add,
      disabled: execStatus !== PurchaseExecStatus.executing,
    },
    {
      key: 'over',
      onClick: () => {
        if (!id) return;
        endOrder([id], [dataSource?.code!], 'single', refresh, refresh);
      },
      title: '结束',
      auth: authDict.purchasereturnorder_finish,
      disabled: execStatus !== PurchaseExecStatus.executing,
    },
    {
      key: 'close',
      onClick: () => {
        if (!id) return;
        Modal.confirm({
          title: '关闭采购退货单',
          content: '关闭采购退货单后不能再进行任何操作，确认关闭吗？',
          okText: '关闭',
          cancelText: '暂不关闭',
          onOk: async () => {
            await handleCloseOrder([id], refresh, refresh);
          },
          onCancel: refresh,
        });
      },
      title: '关闭',
      auth: authDict.purchasereturnorder_close,
      disabled: execStatus !== PurchaseExecStatus.created,
    },
    {
      key: 'record',
      onClick: () => {
        if (!id) return;
        setVisibleLog(true);
      },
      icon: 'iconcaozuojilu',
      title: '操作记录',
      auth: authDict.purchasereturnorder_operrecord,
    },
    {
      key: 'edit',
      onClick: () => {
        history.push(goToEdit(id!));
      },
      icon: 'iconbianji',
      title: '编辑',
      auth: authDict.purchasereturnorder_edit,
      disabled: ![PurchaseExecStatus.created, PurchaseExecStatus.executing].includes(
        execStatus as number,
      ),
    },
  ]);

  return (
    <>
      <DetailLayout
        title="采购退货单详情"
        info={baseInfo}
        dataSource={dataSource}
        baseMenu={baseMenu}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={orderId as number}
          objectCode={OBJECT_OF_CODE.purchaseReturnOrder}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailPage;
