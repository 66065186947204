import { List, Popconfirm, Button } from 'antd';
import { useState, useEffect } from 'react';
import _ from 'lodash';

import { BlIcon } from 'src/components';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import UserSelectModal from 'src/page/organization/components/userAndDepartmentSelect';
// import ResourceSelectModal from 'src/page/knowledgeManagement/components/resourceConfig/selectModal';
import ResourceSelectModal from 'src/page/resource/definitions/components/BatchEquipmentSelector';
import WorkCenterSelectWithDialog from 'src/page/organization/components/workCenterSelect';
import { ListItem } from 'src/page/organization/components/userAndDepartmentSelect/index.d';
import { FitRangeDataItemProps } from '../../../constants';
import { FitRangeType } from 'src/dict/workCalendar';
import styles from './index.module.scss';

interface FitRangeListProps {
  listData?: FitRangeDataItemProps[]; // 绑定在当前列表上的数据
  buttonText: string; // 适用范围新增按钮上显示的文字
  fitRangeType: FitRangeType; // 适用范围种类：共有用户/部门/资源3种，固定班制只支持部门/资源，排班制只支持用户
  onChange?: (newValue: FitRangeDataItemProps[] | undefined) => void;
  readOnly?: boolean;
}

const fitRangeListIconForTypes = {
  [FitRangeType.departments]: 'iconzuzhijiagou',
  [FitRangeType.equipments]: 'iconziyuan',
  [FitRangeType.workCenters]: undefined,
};

// 选择适用范围的列表，包括适用范围项的新增和删除
export default function FitRangeList({
  listData,
  buttonText,
  fitRangeType = FitRangeType.users,
  onChange,
  readOnly,
}: FitRangeListProps) {
  // 要不要显示新增用户/部门的弹窗
  const [showAddUserAndDepartDialog, setShowAddUserAndDepartDialog] = useState(false);
  // 要不要显示新增资源（设备）的弹窗
  const [showAddResourceDialog, setShowAddResourceDialog] = useState(false);
  // 要不要显示新增工作中心的弹窗
  const [showAddWorkCenterDialog, setShowAddWorkCenterDialog] = useState(false);
  // 是否新增用户，若为否，则新增部门
  const [isAddingUser, setIsAddingUser] = useState(false);
  // 弹窗中被选中数据的列表，应该和listData保持一致，但数据格式不一样
  const [modalChoosedDepartments, setModalChoosedDepartments] = useState<ListItem[]>([]);
  // 弹窗中被选中数据的列表，应该和listData保持一致，但数据格式不一样
  const [modalChoosedWorkCenters, setModalChoosedWorkCenters] = useState<ListItem[]>([]);
  // 选中的设备id数组（原资源）
  const [selectedResourcesIds, setSelectedResourcesIds] = useState<number[]>([]);

  // 在不同适用范围下点击新增按钮的处理函数，设置用户/部门选择弹窗的属性并且显示它
  const onAdd = () => {
    switch (fitRangeType) {
      case FitRangeType.users:
        setIsAddingUser(true);
        setShowAddUserAndDepartDialog(true);
        break;

      case FitRangeType.departments:
        setShowAddUserAndDepartDialog(true);
        break;

      case FitRangeType.equipments:
        setShowAddResourceDialog(true);
        break;

      case FitRangeType.workCenters:
        setShowAddWorkCenterDialog(true);
        break;

      default:
        break;
    }
  };

  const addFitRangeResource = (rowKeys: number[], rowData: any[]) => {
    addFitRangeItem(rowData);
  };

  /**
   * 选中添加项并点击确认后的回调函数
   * 把新的弹窗已选数据map成适用范围的数据格式
   */
  const addFitRangeItem = (newItems: ListItem[]) => {
    const newSelectedItems: FitRangeDataItemProps[] = newItems.map((newItem) => ({
      businessId: newItem.id,
      businessName: newItem.name,
      businessType: fitRangeType,
    }));

    // 把新的适用范围数组（当前适用范围类型）去重并传递除去
    onChange?.(_.uniqBy([...newSelectedItems], 'businessId'));
    setShowAddResourceDialog(false);
  };

  // 处理删除适用范围项的函数
  const handleDelete = (item: FitRangeDataItemProps) => {
    console.log('listData :>> ', listData);
    switch (fitRangeType) {
      case FitRangeType.departments:
        setModalChoosedDepartments(
          modalChoosedDepartments.filter((department) => department.id !== item.businessId),
        );
        break;
      case FitRangeType.equipments:
        setSelectedResourcesIds(
          selectedResourcesIds.filter((resourceId) => resourceId !== item.businessId),
        );
        break;
      case FitRangeType.workCenters:
        setModalChoosedWorkCenters(
          modalChoosedWorkCenters.filter((workCenter) => workCenter.id !== item.businessId),
        );
        break;
      default:
        console.log('never be here');
        break;
    }

    // 把选中的适用范围项过滤掉
    const listAfterDelete = listData?.filter((listItem) => {
      return listItem.businessId !== item.businessId;
    });

    console.log('listAfterDelete :>> ', listAfterDelete);

    onChange?.(listAfterDelete);
  };

  useEffect(() => {
    // 在listData改变的时候，把用于选择用户/部门弹窗数据绑定的数据同步成listData的内容
    if (listData) {
      if (fitRangeType === FitRangeType.equipments) {
        setSelectedResourcesIds(listData.map((listItem) => listItem.businessId as number));
      } else if (fitRangeType === FitRangeType.departments) {
        setModalChoosedDepartments(
          listData.map(
            (listItem) => ({ id: listItem.businessId, name: listItem.businessName } as ListItem),
          ),
        );
      } else if (fitRangeType === FitRangeType.workCenters) {
        setModalChoosedWorkCenters(
          listData.map(
            (listItem) => ({ id: listItem.businessId, name: listItem.businessName } as ListItem),
          ),
        );
      }
    }
  }, [listData]);

  return (
    <>
      <div className={styles.fitRangeList}>
        <List
          className={styles.fitRangeList}
          dataSource={listData}
          split={false}
          renderItem={(item) => (
            <List.Item className={styles.fitRangeItem}>
              {/* {fitRangeType === FitRangeType.users && (
                <AvatarDisplay
                  name={item.businessName}
                  id={item.businessId as number}
                  isShowTag={false}
                  isUser
                /> // 目前固定班制的适用范围不支持用户
              )} */}
              {(fitRangeType === FitRangeType.departments ||
                fitRangeType === FitRangeType.equipments) && (
                <AvatarDisplay
                  name={item.businessName!}
                  id={item.businessId as number}
                  isShowTag={false}
                  displayIcon={<BlIcon type={fitRangeListIconForTypes[fitRangeType]} />}
                />
              )}
              {fitRangeType === FitRangeType.workCenters && <div>{item.businessName}</div>}
              {!readOnly && (
                <Popconfirm
                  placement="topRight"
                  title="你确定要删除吗？"
                  onConfirm={() => {
                    handleDelete(item);
                  }}
                >
                  <BlIcon type="iconshanchulajitong1" />
                </Popconfirm>
              )}
            </List.Item>
          )}
        />
        {!readOnly && (
          <Button
            type="dashed"
            icon={<BlIcon type="iconxinjiantianjia" />}
            style={{ margin: '16px 12px' }}
            onClick={onAdd}
          >
            {buttonText}
          </Button>
        )}
      </div>
      {showAddUserAndDepartDialog && (
        <UserSelectModal
          isMultiple
          visible={showAddUserAndDepartDialog}
          isSelectUser={isAddingUser}
          submitData={addFitRangeItem}
          choosedData={modalChoosedDepartments}
          onClose={() => {
            setIsAddingUser(false);
            setShowAddUserAndDepartDialog(false);
          }}
        />
      )}
      {showAddResourceDialog && (
        <ResourceSelectModal
          visible={showAddResourceDialog}
          submitData={addFitRangeResource}
          closeModal={() => {
            setShowAddResourceDialog(false);
          }}
          defaultSelected={selectedResourcesIds}
        />
      )}
      {showAddWorkCenterDialog && (
        <WorkCenterSelectWithDialog
          visible={showAddWorkCenterDialog}
          onClose={() => {
            setShowAddWorkCenterDialog(false);
          }}
          isMultiple
          submitData={addFitRangeItem}
          choosedData={modalChoosedWorkCenters}
        />
      )}
    </>
  );
}
