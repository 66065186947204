/**
 * @description 触发方式选择 「按业务动作触发」 的组件渲染
 */
import { useState } from 'react';
import { Select, Form, Radio, Space, Input } from 'antd';
import { SearchSelect } from 'src/components';
import _ from 'lodash';

import { appDict, appEnum } from 'src/dict';
import { numberMinMaxCheck, validatorAfterNow } from 'src/utils/formValidators';
import { fetchCustomFieldGetListByObjectCode } from 'src/api/ytt/metadata-domain/customField';

import MyDatePicker from '../share/FormItemComponents/MyDatePicker';

import styles from './styles.module.scss';
import { triggeringActionOptions } from 'src/dict/processManagement/mappings';
import { getFieldValueOfForm } from '../utils/tools';

const {
  effectiveType,
  bizEventExecType,
  delayTriggerField,
  delayTriggerCondition,
  delayTriggerTimeUnit,
} = appDict.triggerRule;
const { EffectiveType, BizEventExecType, DelayTriggerField, DelayTriggerCondition } =
  appEnum.TriggerRule;

/** 将Input框的值格式化为number返回的normalize */
const normalizeToNumberFormInput = (value: string) => {
  if (_.isNil(value) || _.isNaN(Number(value)) || value === '') return undefined;
  return Number(value);
};

/** 获取form路径 */
const defaultParentFieldName = 'bizEventTrigger';

/** 获取form路径 */
const getNamePath = (parentFieldName: string) => (name: string) => [parentFieldName, name];
const getDefaultNamePath = getNamePath(defaultParentFieldName);

// interface CodeProps {
//   objectCode: string;
// }
interface FirstTriggerDelayProps {
  form: any;
}
interface Props {
  objectCode: string;
  moduleType?: string;
  form: any;
}

/** 触发动作 */
const TriggerEventTypeItem = ({ objectCode, moduleType, form }: Props) => {
  if (moduleType === 'triggerRule' && _.isNil(objectCode)) return null;

  if (moduleType === 'approval') {
    return (
      <Form.Item
        label="触发动作"
        name={getDefaultNamePath('triggerEventType')}
        rules={[{ required: true, message: '请选择触发动作' }]}
      >
        <Select placeholder="请选择触发动作" options={triggeringActionOptions} allowClear />
      </Form.Item>
    );
  }

  return (
    <Form.Item
      label="触发动作"
      name={getDefaultNamePath('triggerEventType')}
      rules={[{ required: true, message: '请选择触发动作' }]}
    >
      <SearchSelect
        onDeselect={(data: any) => {
          // 只要移除了edit就重置触发条件
          if (data?.value === 'edit') {
            form.setFields([{ name: ['triggerCondition', 'conditionGroups'], value: undefined }]);
          }
        }}
        fetchType="bizOperation"
        placeholder="请选择触发动作"
        params={{ objectCode, page: undefined, size: undefined }}
        mode="multiple"
        allowClear={false}
      />
    </Form.Item>
  );
};

/** 生效时机 */
const EffectiveTypeItem = () => (
  <Form.Item label="生效时机" className={styles.innerFormItemGlobal}>
    <Form.Item name={getDefaultNamePath('effectiveType')} initialValue={EffectiveType.immediately}>
      <Radio.Group options={effectiveType} />
    </Form.Item>
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) => {
        const path = 'bizEventTrigger.effectiveType';

        return _.get(prevValues, path) !== _.get(currentValues, path);
      }}
    >
      {(form) => {
        const effectiveType = form.getFieldValue(getDefaultNamePath('effectiveType'));

        if (effectiveType === EffectiveType.firstTriggerDelay) {
          return <FirstTriggerDelay form={form} />;
        }
        return null;
      }}
    </Form.Item>
  </Form.Item>
);

/** 执行频率 */
const ExecTimeItem = () => (
  <Form.Item label="执行频率" required className={styles.innerFormItem}>
    <Form.Item
      name={getDefaultNamePath('execType')}
      initialValue={BizEventExecType.triggerOnce}
      className={styles.innerFormItem}
    >
      <Radio.Group options={bizEventExecType} />
    </Form.Item>
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) => {
        const path = 'bizEventTrigger.execType';

        return _.get(prevValues, path) !== _.get(currentValues, path);
      }}
    >
      {(form) => {
        const execType = form.getFieldValue(getDefaultNamePath('execType'));

        if (execType === BizEventExecType.customize) {
          return <CustomizeItem />;
        }
        return null;
      }}
    </Form.Item>
  </Form.Item>
);

/** 生效时机, 选「首次触发延时生效」时的配置组件 */
export const FirstTriggerDelay = ({ form }: FirstTriggerDelayProps) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any[]>();

  return (
    <Space style={{ marginTop: '20px' }}>
      <Form.Item
        name={getDefaultNamePath('delayTriggerField')}
        preserve={false}
        initialValue={_.head(delayTriggerField)}
        rules={[{ required: true, message: '请选择延时触发字段' }]}
      >
        <Select
          labelInValue
          placeholder={'延时触发字段'}
          options={_.concat(_.head(delayTriggerField), options ?? [])}
          loading={loading}
          onFocus={() => {
            const _objectCode = getFieldValueOfForm(form, 'objectCode', 'objectCode');

            if (!_objectCode) return;
            setLoading(true);
            fetchCustomFieldGetListByObjectCode({ objectCode: _objectCode }).then((res) => {
              setOptions(
                _.map(
                  _.filter(
                    res.data,
                    ({ fieldType }) => fieldType === appEnum.CustomField.FieldType.date,
                  ),
                  ({ fieldName, id }) => ({ value: id, label: fieldName }),
                ),
              );
              setLoading(false);
            });
          }}
        />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {(form) => {
          const delayTriggerFieldOption = form.getFieldValue(
            getDefaultNamePath('delayTriggerField'),
          );
          let options = delayTriggerCondition;

          // 当「延时触发字段」选择了 「规则触发时」
          // 且
          // 当 「业务字段」的 字段类型不为时间日期时
          // 延时条件 只能选择「之后」
          if (delayTriggerFieldOption?.value === DelayTriggerField.triggering) {
            options = _.filter(delayTriggerCondition, ['value', DelayTriggerCondition.after]);
          }

          return (
            <Form.Item
              name={getDefaultNamePath('delayTriggerCondition')}
              preserve={false}
              initialValue={DelayTriggerCondition.after}
              rules={[{ required: true, message: '请选择延时条件' }]}
            >
              <Select placeholder={'延时条件'} options={options} />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item
        name={getDefaultNamePath('delayTriggerTime')}
        preserve={false}
        normalize={normalizeToNumberFormInput}
        rules={[
          {
            validator: numberMinMaxCheck({
              min: 0,
              max: 1000,
              isInteger: true,
              fieldName: '延时时间',
              minAllowEqual: false,
              maxAllowEqual: false,
            }),
          },
        ]}
      >
        <Input placeholder={'延时时间'} />
      </Form.Item>
      <Form.Item
        name={getDefaultNamePath('delayTriggerTimeUnit')}
        preserve={false}
        rules={[{ required: true, message: '请选择延时时间单位' }]}
      >
        <Select placeholder={'时间单位'} options={delayTriggerTimeUnit} />
      </Form.Item>
    </Space>
  );
};

/** 执行频率，选「自定义选项」时的配置组件 */
export const CustomizeItem = ({ parentPath }: { parentPath?: string }) => {
  let getPath = getDefaultNamePath;

  if (parentPath) {
    getPath = getNamePath(parentPath);
  }
  return (
    <>
      <Form.Item
        label="触发"
        required
        className={styles.innerFormItemGlobal}
        style={{ marginTop: '24px' }}
      >
        <Space>
          <div>首次触发之后，每</div>
          <Form.Item
            name={getPath('execTime')}
            preserve={false}
            style={{ width: '120px' }}
            normalize={normalizeToNumberFormInput}
            rules={[
              {
                validator: numberMinMaxCheck({
                  min: 0,
                  max: 1000,
                  isInteger: true,
                  fieldName: '触发执行间隔',
                  minAllowEqual: false,
                  maxAllowEqual: false,
                }),
              },
            ]}
          >
            <Input placeholder={'触发执行间隔'} />
          </Form.Item>
          <Form.Item
            name={getPath('execTimeUnit')}
            preserve={false}
            style={{ width: '100px' }}
            rules={[{ required: true, message: '请选择触发执行间隔单位' }]}
          >
            <Select placeholder={'间隔单位'} options={delayTriggerTimeUnit} />
          </Form.Item>
          <div>触发一次</div>
        </Space>
      </Form.Item>
      <Form.Item
        label="结束触发"
        required
        className={styles.innerFormItemGlobal}
        style={{ marginBottom: 0 }}
      >
        <Space>
          <div>累计触发</div>
          <Form.Item
            name={getPath('finishCount')}
            style={{ width: '120px' }}
            normalize={normalizeToNumberFormInput}
            preserve={false}
            rules={[
              {
                validator: numberMinMaxCheck({
                  min: 0,
                  max: 100,
                  isInteger: true,
                  fieldName: '累计触发次数',
                  minAllowEqual: false,
                  maxAllowEqual: false,
                }),
              },
            ]}
          >
            <Input placeholder={'累计触发次数'} />
          </Form.Item>
          <div>次</div>
        </Space>
        <div className={styles.or}>或</div>
        <Space>
          <div>时间到达</div>
          <Form.Item
            name={getPath('finishTime')}
            rules={[
              { required: true, message: '请选择触发时间' },
              { validator: validatorAfterNow() },
            ]}
            preserve={false}
          >
            <MyDatePicker showTime width={160} format="YYYY-MM-DD HH:mm:ss" bannedBeforeNow />
          </Form.Item>
        </Space>
        <div className={styles.or}>或</div>
        <span>数据变更，导致数据不满足触发条件</span>
      </Form.Item>
    </>
  );
};

const BizEventTrigger = ({ objectCode, moduleType = 'triggerRule', form }: any) => {
  return (
    <>
      <TriggerEventTypeItem objectCode={objectCode} moduleType={moduleType} form={form} />
      <EffectiveTypeItem />
      <ExecTimeItem />
    </>
  );
};

export default BizEventTrigger;
