import React, { useEffect, useState } from 'react';
import { DetailLayout } from 'layout';
import { getMaterialAttrs } from 'src/entity/material';
import { BlTable, TagList } from 'src/components';
import lookup from 'src/dict';
import { fetchSchedulingSchedulingMaterialGet } from 'src/api/ytt/wos-domain';
import { replaceSign } from 'src/utils/constants';
import { arrayIsEmpty } from 'src/utils/array';

//

interface FeedingControlDetailProps {
  params: any;
}

const MaterialSchedulingDetail = (props: FeedingControlDetailProps) => {
  const { params } = props;
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState();

  useEffect(() => {
    const { id } = params;

    fetchSchedulingSchedulingMaterialGet({ id: Number(id) })
      .then((res) => {
        setDataSource(res?.data);
      })
      .catch((err) => {
        console.log('err: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props]);

  const renderOptionalEquipment = () => {
    const columns = [
      {
        title: '资源序号',
        dataIndex: 'lineNo',
        width: 120,
        render: (text: any, _record, index: number) => index + 1,
      },
      {
        title: '设备编号',
        dataIndex: 'resourceCode',
        width: 120,
      },
      {
        title: '设备名称',
        dataIndex: 'resourceName',
        width: 120,
      },
      {
        title: '标准产能-数量',
        dataIndex: 'standardCapacityAmount',
        width: 120,
        render: (_text, record) => {
          const { standardCapacity } = record;

          return standardCapacity?.amountDisplay ?? replaceSign;
        },
      },
      {
        title: '标准产能-单位',
        dataIndex: 'standardCapacityUnit',
        width: 120,
        render: (_text, record) => {
          const { standardCapacity } = record;

          return standardCapacity?.unitName ?? replaceSign;
        },
      },
      {
        title: '标准产能-时间',
        dataIndex: 'standardCapacityTime',
        width: 120,
      },
      {
        title: '标准产能-时间单位',
        dataIndex: 'standardCapacityTimeUnit',
        width: 120,
        render: (text: number) => {
          return lookup('planned.sCTimeUnitDisplayMap', text);
        },
      },
      {
        title: '标准产能-优先级',
        dataIndex: 'priority',
        width: 120,
      },
    ];

    return (
      <BlTable
        scroll={{ x: 'max-content', y: 'auto' }}
        dataSource={dataSource?.usableResources}
        columns={columns}
        rowKey="id"
      />
    );
  };

  const getBaseInfo = () => {
    const baseInfo = [
      {
        items: [
          {
            label: '物料编码',
            dataIndex: ['material', 'baseInfo', 'code'],
            width: 120,
          },
          {
            label: '物料名称',
            dataIndex: ['material', 'baseInfo', 'name'],
            width: 120,
          },
          {
            label: '物料属性',
            dataIndex: ['material'],
            width: 150,
            render: (_text: any) => <TagList dataSource={getMaterialAttrs(_text)} />,
          },
          {
            label: '物料分类',
            dataIndex: ['material', 'category', 'name'],
            width: 150,
          },
          {
            label: '物料规格',
            dataIndex: ['material', 'baseInfo', 'specification'],
            width: 150,
          },
          {
            label: '业务类型',
            dataIndex: ['material', 'baseInfo', 'bizRange'],
            width: 150,
            render: (text: number[]) => {
              if (!arrayIsEmpty(text)) {
                const arr = text.map((item: number) => {
                  return lookup('material.bizRange', item);
                });

                return arr.join('、');
              }
              return replaceSign;
            },
          },
          {
            label: '排程优先级',
            dataIndex: 'priority',
            width: 150,
          },
        ],
      },
      {
        title: '可选设备',
        items: [
          {
            render: () => renderOptionalEquipment(),
          },
        ],
      },
    ];

    return baseInfo;
  };

  return (
    <DetailLayout
      title="查看排程物料"
      info={getBaseInfo()}
      dataSource={dataSource}
      loading={loading}
    />
  );
};

export default MaterialSchedulingDetail;
