import { Modal } from 'antd';
import _ from 'lodash';
import { logout } from 'src/utils/login';
import { FIELDS, LoginRoutePathname } from 'src/utils/constants';
import LocalStorage from 'src/utils/localStorage';

let flag = true;
export const showLogoutModal = _.debounce((props: { message: string; title?: string }) => {
  const { message, title, ...rest } = props || {};
  const { pathname } = document.location;

  if (!LoginRoutePathname.includes(pathname) && flag) {
    flag = false;
    Modal.destroyAll();
    Modal.confirm({
      title: title || '登出提示',
      content: message,
      okText: '返回登出',
      cancelText: null,
      okCancel: false,
      onOk: () => {
        logout();
        flag = true;
      },
      ...rest,
    });
  }
}, 1500);

export const checkLoginStatus = async (): Promise<boolean> => {
  const token = LocalStorage.get(FIELDS.TOKEN_NAME);
  const auth = LocalStorage.get(FIELDS.AUTH);
  const { pathname } = document.location;

  // 正常登录和灰度登录流程不需要重新检测登录状态
  if (LoginRoutePathname.includes(pathname)) return true;
  if (!token) {
    // 登录信息丢失
    // logout();
    showLogoutModal({ message: '您的账户登录信息失效', title: '登录失效' });
    return false;
  } else if (token === 'expired' && auth) {
    // 过期
    showLogoutModal({ message: '您的账户登录时长已超过3天，请重新登录。', title: '登录过期' });
    return false;
  }
  return true;
};
