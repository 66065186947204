import { lookup } from 'src/dict';
import { AllowType, NoticeType, RemindType } from 'src/dict/noticeMessage';

export const VIEW_MODAL = {
  triggerRules: '1',
  unTriggerRules: '2',
};

// 提醒形式
export const remindTypeOptions = [
  // {
  //   label: lookup('noticeMessage.RemindMap', RemindType.prompt),
  //   value: RemindType.prompt,
  // },
  // {
  //   label: lookup('noticeMessage.RemindMap', RemindType.vibration),
  //   value: RemindType.vibration,
  // },
  // {
  //   label: lookup('noticeMessage.RemindMap', RemindType.voice),
  //   value: RemindType.voice,
  // },
  {
    label: lookup('noticeMessage.RemindMap', RemindType.urgent),
    value: RemindType.urgent,
  },
];

export const REMIND_TYPE = new Map([
  // [RemindType.prompt, lookup('noticeMessage.RemindMap', RemindType.prompt)],
  // [RemindType.vibration, lookup('noticeMessage.RemindMap', RemindType.vibration)],
  // [RemindType.voice, lookup('noticeMessage.RemindMap', RemindType.voice)],
  [RemindType.urgent, lookup('noticeMessage.RemindMap', RemindType.urgent)],
]);

//  通知订阅

export const noticeOptions = [
  // {
  //   label: lookup('noticeMessage.NoticeMap', NoticeType.sms),
  //   value: NoticeType.sms,
  // },
  {
    label: lookup('noticeMessage.NoticeMap', NoticeType.mail),
    value: NoticeType.mail,
  },
  {
    label: lookup('noticeMessage.NoticeMap', NoticeType.feiShu),
    value: NoticeType.feiShu,
  },
];

export const NOTICE_TYPE = new Map([
  // [NoticeType.sms, lookup('noticeMessage.NoticeMap', NoticeType.sms)],
  [NoticeType.mail, lookup('noticeMessage.NoticeMap', NoticeType.mail)],
  [NoticeType.feiShu, lookup('noticeMessage.NoticeMap', NoticeType.feiShu)],
]);

//  通知订阅

export const allowOptions = [
  {
    label: lookup('noticeMessage.AllowMap', AllowType.allow),
    value: AllowType.allow,
  },
  {
    label: lookup('noticeMessage.AllowMap', AllowType.unAllow),
    value: AllowType.unAllow,
  },
];

export const ALLOW_REJECTION_TYPE = new Map([
  [AllowType.allow, lookup('noticeMessage.AllowMap', AllowType.allow)],
  [AllowType.unAllow, lookup('noticeMessage.AllowMap', AllowType.unAllow)],
]);

// 提醒形式
export const PresetRemindTypeOptions = [
  {
    label: '紧急通知',
    value: 1,
  },
];

export const Preset_REMIND_TYPE = new Map([[1, '紧急通知']]);

//  通知订阅

export const PresetNoticeOptions = [
  {
    label: '邮件',
    value: 1,
  },
];

export const Preset_NOTICE_TYPE = new Map([[1, '邮件']]);

//  通知订阅

export const PresetAllowOptions = [
  {
    label: '不允许拒收',
    value: 0,
  },
  {
    label: '允许拒收',
    value: 1,
  },
];

export const Preset_ALLOW_REJECTION_TYPE = new Map([
  ['不允许拒收', 0],
  ['允许拒收', 1],
]);
