/**
 * @description 映射表
 */

import { APPLICATION_NAME, BUSINESS_TYPE, EXPORT_STATUS, IMPORT_STATUS, TEMPLATE_ID } from '.';
import { ImportConfigType } from '../types';
import { mappingsFactory } from '../utils';

/** 导入导出参数参照 */
export const importParams = mappingsFactory<ImportConfigType, string>(
  /** ====================== materialDomain ====================== */
  [
    {
      businessType: BUSINESS_TYPE.unit,
      applicationName: APPLICATION_NAME.materialDomain,
      templateId: TEMPLATE_ID.unit,
    },
    // 单位
    BUSINESS_TYPE.unit,
  ],
  [
    {
      businessType: BUSINESS_TYPE.batchNo,
      applicationName: APPLICATION_NAME.materialDomain,
      templateId: TEMPLATE_ID.batchNo,
    },
    // 批次号
    BUSINESS_TYPE.batchNo,
  ],
  [
    {
      businessType: BUSINESS_TYPE.material,
      applicationName: APPLICATION_NAME.materialDomain,
      templateId: TEMPLATE_ID.material,
    },
    // 物料
    BUSINESS_TYPE.material,
  ],
  [
    {
      businessType: BUSINESS_TYPE.materialType,
      applicationName: APPLICATION_NAME.materialDomain,
      templateId: TEMPLATE_ID.materialType,
    },
    // 物料分类
    BUSINESS_TYPE.materialType,
  ],
  [
    {
      businessType: BUSINESS_TYPE.materialAttribute,
      applicationName: APPLICATION_NAME.materialDomain,
      templateId: TEMPLATE_ID.materialAttribute,
    },
    // 物料属性
    BUSINESS_TYPE.materialAttribute,
  ],
  /** ====================== userDomain ====================== */
  [
    {
      businessType: BUSINESS_TYPE.user,
      applicationName: APPLICATION_NAME.userDomain,
      templateId: TEMPLATE_ID.user,
    },
    // 用户
    BUSINESS_TYPE.user,
  ],
  [
    {
      businessType: BUSINESS_TYPE.dept,
      applicationName: APPLICATION_NAME.userDomain,
      templateId: TEMPLATE_ID.dept,
    },
    // 部门
    BUSINESS_TYPE.dept,
  ],
  /** ====================== orderDomain ====================== */
  [
    {
      businessType: BUSINESS_TYPE.customer,
      applicationName: APPLICATION_NAME.orderDomain,
      templateId: TEMPLATE_ID.customer,
    },
    // 客户
    BUSINESS_TYPE.customer,
  ],
  [
    {
      businessType: BUSINESS_TYPE.supplier,
      applicationName: APPLICATION_NAME.orderDomain,
      templateId: TEMPLATE_ID.supplier,
    },
    // 供应商
    BUSINESS_TYPE.supplier,
  ],
  [
    {
      businessType: BUSINESS_TYPE.supplierSCM,
      applicationName: APPLICATION_NAME.orderDomain,
      templateId: TEMPLATE_ID.supplierSCM,
    },
    // 供应链 - 供应商
    BUSINESS_TYPE.supplierSCM,
  ],
  [
    {
      businessType: BUSINESS_TYPE.salesOrder,
      applicationName: APPLICATION_NAME.orderDomain,
      templateId: TEMPLATE_ID.salesOrder,
    },
    // 销售订单
    BUSINESS_TYPE.salesOrder,
  ],
  [
    {
      businessType: BUSINESS_TYPE.purchaseOrder,
      applicationName: APPLICATION_NAME.orderDomain,
      templateId: TEMPLATE_ID.purchaseOrder,
    },
    // 采购订单
    BUSINESS_TYPE.purchaseOrder,
  ],
  [
    {
      businessType: BUSINESS_TYPE.purchaseReturnOrder,
      applicationName: APPLICATION_NAME.orderDomain,
      templateId: TEMPLATE_ID.purchaseReturnOrder,
    },
    // 采购退货订单
    BUSINESS_TYPE.purchaseReturnOrder,
  ],
  [
    {
      businessType: BUSINESS_TYPE.deliveryScheduleNote,
      applicationName: APPLICATION_NAME.orderDomain,
      templateId: TEMPLATE_ID.deliveryScheduleNote,
    },
    // 采购退货订单
    BUSINESS_TYPE.deliveryScheduleNote,
  ],
  [
    {
      businessType: BUSINESS_TYPE.sourceList,
      applicationName: APPLICATION_NAME.orderDomain,
      templateId: TEMPLATE_ID.sourceList,
    },
    // 货源清单
    BUSINESS_TYPE.sourceList,
  ],
  [
    {
      businessType: BUSINESS_TYPE.shipmentNote,
      applicationName: APPLICATION_NAME.orderDomain,
      templateId: TEMPLATE_ID.shipmentNote,
    },
    // 货源清单
    BUSINESS_TYPE.shipmentNote,
  ],
  [
    {
      businessType: BUSINESS_TYPE.salesReturnOrder,
      applicationName: APPLICATION_NAME.orderDomain,
      templateId: TEMPLATE_ID.salesReturnOrder,
    },
    // 销售退货单
    BUSINESS_TYPE.salesReturnOrder,
  ],
  /** ====================== inventoryDomain ====================== */
  [
    {
      businessType: BUSINESS_TYPE.materialInventory,
      applicationName: APPLICATION_NAME.inventoryDomain,
      templateId: TEMPLATE_ID.materialInventory,
    },
    // 库存明细
    BUSINESS_TYPE.materialInventory,
  ],
  [
    {
      businessType: BUSINESS_TYPE.inboundOrder,
      applicationName: APPLICATION_NAME.inventoryDomain,
      templateId: TEMPLATE_ID.inboundOrder,
    },
    // 入库单
    BUSINESS_TYPE.inboundOrder,
  ],
  [
    {
      businessType: BUSINESS_TYPE.inboundOrderRecord,
      applicationName: APPLICATION_NAME.inventoryDomain,
      templateId: TEMPLATE_ID.inboundOrderRecord,
    },
    // 入库记录
    BUSINESS_TYPE.inboundOrderRecord,
  ],
  [
    {
      businessType: BUSINESS_TYPE.outboundOrder,
      applicationName: APPLICATION_NAME.inventoryDomain,
      templateId: TEMPLATE_ID.outboundOrder,
    },
    // 出库单
    BUSINESS_TYPE.outboundOrder,
  ],
  [
    {
      businessType: BUSINESS_TYPE.outboundOrderRecord,
      applicationName: APPLICATION_NAME.inventoryDomain,
      templateId: TEMPLATE_ID.outboundOrderRecord,
    },
    // 出库记录
    BUSINESS_TYPE.outboundOrderRecord,
  ],
  [
    {
      businessType: BUSINESS_TYPE.transferOrder,
      applicationName: APPLICATION_NAME.inventoryDomain,
      templateId: TEMPLATE_ID.transferOrder,
    },
    // 调拨单
    BUSINESS_TYPE.transferOrder,
  ],
  [
    {
      businessType: BUSINESS_TYPE.transferOrderRecord,
      applicationName: APPLICATION_NAME.inventoryDomain,
      templateId: TEMPLATE_ID.transferOrderRecord,
    },
    // 调拨记录
    BUSINESS_TYPE.transferOrderRecord,
  ],
  /** ====================== storageDomain ====================== */
  [
    {
      businessType: BUSINESS_TYPE.storageWarehouse,
      applicationName: APPLICATION_NAME.storageDomain,
      templateId: TEMPLATE_ID.storageWarehouse,
    },
    // 仓库
    BUSINESS_TYPE.storageWarehouse,
  ],
  [
    {
      businessType: BUSINESS_TYPE.storageArea,
      applicationName: APPLICATION_NAME.storageDomain,
      templateId: TEMPLATE_ID.storageArea,
    },
    // 区域
    BUSINESS_TYPE.storageArea,
  ],
  [
    {
      businessType: BUSINESS_TYPE.storageLocation,
      applicationName: APPLICATION_NAME.storageDomain,
      templateId: TEMPLATE_ID.storageLocation,
    },
    // 仓位
    BUSINESS_TYPE.storageLocation,
  ],
  /** ====================== qualityDomain ====================== */
  [
    {
      businessType: BUSINESS_TYPE.qcCheckItem,
      applicationName: APPLICATION_NAME.qualityDomain,
      templateId: TEMPLATE_ID.qcCheckItem,
    },
    // 质检项
    BUSINESS_TYPE.qcCheckItem,
  ],
  [
    {
      businessType: BUSINESS_TYPE.qcCheckItemCategory,
      applicationName: APPLICATION_NAME.qualityDomain,
      templateId: TEMPLATE_ID.qcCheckItemCategory,
    },
    // 质检项分类
    BUSINESS_TYPE.qcCheckItemCategory,
  ],
  [
    {
      businessType: BUSINESS_TYPE.qcDefectRank,
      applicationName: APPLICATION_NAME.qualityDomain,
      templateId: TEMPLATE_ID.qcDefectRank,
    },
    // 不良等级
    BUSINESS_TYPE.qcDefectRank,
  ],
  [
    {
      businessType: BUSINESS_TYPE.qcDefectReason,
      applicationName: APPLICATION_NAME.qualityDomain,
      templateId: TEMPLATE_ID.qcDefectReason,
    },
    // 不良原因
    BUSINESS_TYPE.qcDefectReason,
  ],
  [
    {
      businessType: BUSINESS_TYPE.qcConfig,
      applicationName: APPLICATION_NAME.qualityDomain,
      templateId: TEMPLATE_ID.qcConfig,
    },
    // 检验方案
    BUSINESS_TYPE.qcConfig,
  ],
  /** ====================== medDomain ====================== */
  [
    {
      businessType: BUSINESS_TYPE.pickOrder,
      applicationName: APPLICATION_NAME.medDomain,
      templateId: TEMPLATE_ID.pickOrder,
    },
    // 工序
    BUSINESS_TYPE.pickOrder,
  ],
  [
    {
      businessType: BUSINESS_TYPE.process,
      applicationName: APPLICATION_NAME.medDomain,
      templateId: TEMPLATE_ID.process,
    },
    // 工序
    BUSINESS_TYPE.process,
  ],
  [
    {
      businessType: BUSINESS_TYPE.processRoute,
      applicationName: APPLICATION_NAME.medDomain,
      templateId: TEMPLATE_ID.processRoute,
    },
    // 工艺路线
    BUSINESS_TYPE.processRoute,
  ],
  [
    {
      businessType: BUSINESS_TYPE.bom,
      applicationName: APPLICATION_NAME.medDomain,
      templateId: TEMPLATE_ID.bom,
    },
    // bom
    BUSINESS_TYPE.bom,
  ],
  [
    {
      businessType: BUSINESS_TYPE.productionOrder,
      applicationName: APPLICATION_NAME.medDomain,
      templateId: TEMPLATE_ID.productionOrder,
    },
    // 生产工单
    BUSINESS_TYPE.productionOrder,
  ],
  /** ====================== resourceDomain ====================== */
  [
    {
      businessType: BUSINESS_TYPE.resource,
      applicationName: APPLICATION_NAME.resourceDomain,
      templateId: TEMPLATE_ID.resource,
    },
    // 设备
    BUSINESS_TYPE.resource,
  ],
  [
    {
      businessType: BUSINESS_TYPE.energyMeter,
      applicationName: APPLICATION_NAME.resourceDomain,
      templateId: TEMPLATE_ID.energyMeter,
    },
    // 能源仪表主数据
    BUSINESS_TYPE.energyMeter,
  ],
  [
    {
      businessType: BUSINESS_TYPE.resources_tags,
      applicationName: APPLICATION_NAME.resourceDomain,
      templateId: TEMPLATE_ID.resources_tags,
    },
    // 标签
    BUSINESS_TYPE.resources_tags,
  ],
  [
    {
      businessType: BUSINESS_TYPE.params_def,
      applicationName: APPLICATION_NAME.resourceDomain,
      templateId: TEMPLATE_ID.params_def,
    },
    // 参数定义
    BUSINESS_TYPE.params_def,
  ],
  [
    {
      businessType: BUSINESS_TYPE.location,
      applicationName: APPLICATION_NAME.resourceDomain,
      templateId: TEMPLATE_ID.location,
    },
    // 区域
    BUSINESS_TYPE.location,
  ],
  [
    {
      businessType: BUSINESS_TYPE.ResourceType,
      applicationName: APPLICATION_NAME.resourceDomain,
      templateId: TEMPLATE_ID.ResourceType,
    },
    // 资源分类
    BUSINESS_TYPE.ResourceType,
  ],
  [
    {
      businessType: BUSINESS_TYPE.equipmentParamRecord,
      applicationName: APPLICATION_NAME.resourceDomain,
      templateId: TEMPLATE_ID.equipmentParamRecord,
    },
    // 设备参数记录
    BUSINESS_TYPE.equipmentParamRecord,
  ],
  [
    {
      businessType: BUSINESS_TYPE.energyInstrumentParamRecord,
      applicationName: APPLICATION_NAME.resourceDomain,
      templateId: TEMPLATE_ID.energyInstrumentParamRecord,
    },
    // 能源仪表参数记录
    BUSINESS_TYPE.energyInstrumentParamRecord,
  ],
  [
    {
      businessType: BUSINESS_TYPE.paramMonitor,
      applicationName: APPLICATION_NAME.resourceDomain,
      templateId: '',
    },
    // 参数监控
    BUSINESS_TYPE.paramMonitor,
  ],
  [
    {
      businessType: BUSINESS_TYPE.maintenanceCase,
      applicationName: APPLICATION_NAME.resourceDomain,
      templateId: TEMPLATE_ID.maintenanceCase,
    },
    // 维保方案
    BUSINESS_TYPE.maintenanceCase,
  ],
  [
    {
      businessType: BUSINESS_TYPE.maintenanceTask,
      applicationName: APPLICATION_NAME.resourceDomain,
      templateId: '',
    },
    // 维保任务
    BUSINESS_TYPE.maintenanceTask,
  ],
  [
    {
      businessType: BUSINESS_TYPE.RepairCase,
      applicationName: APPLICATION_NAME.resourceDomain,
      templateId: TEMPLATE_ID.RepairCase,
    },
    // 维修方案
    BUSINESS_TYPE.RepairCase,
  ],
  [
    {
      businessType: BUSINESS_TYPE.repairTask,
      applicationName: APPLICATION_NAME.resourceDomain,
      templateId: TEMPLATE_ID.repairTask,
    },
    // 维修任务
    BUSINESS_TYPE.repairTask,
  ],
  [
    {
      businessType: BUSINESS_TYPE.malfunctionRecord,
      applicationName: APPLICATION_NAME.resourceDomain,
    },
    // 故障记录
    BUSINESS_TYPE.malfunctionRecord,
  ],
  /** ====================== workCenterDomain ====================== */
  [
    {
      businessType: BUSINESS_TYPE.workCenter,
      applicationName: APPLICATION_NAME.workCenterDomain,
      templateId: TEMPLATE_ID.workCenter,
    },
    // 工作中心
    BUSINESS_TYPE.workCenter,
  ],
  [
    {
      businessType: BUSINESS_TYPE.receiveNote,
      applicationName: APPLICATION_NAME.orderDomain,
      templateId: TEMPLATE_ID.receiveNote,
    },
    // 物料
    BUSINESS_TYPE.receiveNote,
  ],
  /** ====================== workerCalendarDomain ====================== */
  [
    {
      businessType: BUSINESS_TYPE.workerCalendar,
      applicationName: APPLICATION_NAME.workerCalendarDomain,
      templateId: TEMPLATE_ID.workerCalendar,
    },
    // 工作日历
    BUSINESS_TYPE.workerCalendar,
  ],
  [
    {
      businessType: BUSINESS_TYPE.alternativePlan,
      applicationName: APPLICATION_NAME.medDomain,
      templateId: TEMPLATE_ID.alternativePlan,
    },
    // 替代方案
    BUSINESS_TYPE.alternativePlan,
  ],
  /** ====================== ploDomain ====================== */
  [
    {
      businessType: BUSINESS_TYPE.planOrder,
      applicationName: APPLICATION_NAME.planOrderDomain,
      templateId: TEMPLATE_ID.planOrder,
    },
    // 计划订单
    BUSINESS_TYPE.planOrder,
  ],
  [
    {
      businessType: BUSINESS_TYPE.planningOperationr,
      applicationName: APPLICATION_NAME.planOrderDomain,
    },
    // 计划运算
    BUSINESS_TYPE.planningOperationr,
  ],
  [
    {
      businessType: BUSINESS_TYPE.progressReport,
      applicationName: APPLICATION_NAME.workTaskDomain,
    },
    // 报工记录
    BUSINESS_TYPE.progressReport,
  ],
  [
    {
      businessType: BUSINESS_TYPE.feedingRecord,
      applicationName: APPLICATION_NAME.workTaskDomain,
    },
    // 投料记录
    BUSINESS_TYPE.feedingRecord,
  ],
  [
    {
      businessType: BUSINESS_TYPE.feedingRetractRecord,
      applicationName: APPLICATION_NAME.workTaskDomain,
    },
    // 投料回撤记录
    BUSINESS_TYPE.feedingRetractRecord,
  ],
);

/** 导入状态参照 */
export const importStatus = mappingsFactory(
  ['导入中', IMPORT_STATUS.pending],
  ['导入成功', IMPORT_STATUS.success],
  ['部分导入成功', IMPORT_STATUS.partialSuccess],
  ['导入失败', IMPORT_STATUS.fail],
);

/** 导出状态参照 */
export const exportStatus = mappingsFactory(
  ['导出中', EXPORT_STATUS.pending],
  ['导出成功', EXPORT_STATUS.success],
  ['导出失败', EXPORT_STATUS.fail],
);
