import { message as Message } from 'antd';
import { ErrorNotification, showErrorListMessage } from 'src/components';
import { replaceSign } from 'src/utils/constants';

const errorColumns = [
  {
    title: '数据集名称',
    dataIndex: 'name',
    width: 200,
    render: (name: string) => name || replaceSign,
  },
  {
    title: '数据集编号',
    dataIndex: 'code',
    width: 200,
    render: (code: string) => code || replaceSign,
  },
  {
    title: '失败原因',
    dataIndex: 'reason',
    width: 300,
    render: (reason: string) => reason,
  },
];

// 列表批量操作请求通用方法
export const batchRequest = (
  type: string,
  patchApi: any,
  params: any,
  display: string,
  onSuccess: any,
  onFail?: any,
) => {
  patchApi(params)
    .then((res: any) => {
      const {
        data: { failNum, successNum, failDetail },
      } = res;

      if (failNum === 0) {
        Message.success(`${display}成功`);
        onSuccess?.();
        return;
      }
      if (successNum === 0) {
        onFail?.();
        // 全部删除失败
      } else if (failNum) {
        onSuccess?.();
        // 有失败的删除 需要展示失败信息
      }

      if (type === 'single') {
        Message.error(failDetail[0]?.reason);
      } else {
        showErrorListMessage({
          title: `${display}出现失败`,
          data: failDetail,
          columns: errorColumns,
          operateName: display,
          successCount: successNum,
          failCount: failNum,
          width: 800,
        });
      }
    })
    .catch((err: any) => {
      console.log('err: ', err);
      onFail();
    });
};

export const delRequest = (delApi: any, selectId: number, onSuccess: any, onFail?: any) => {
  delApi({ id: selectId })
    .then((res: any) => {
      const { data, message } = res;

      if (data) {
        Message.success('删除');
        onSuccess?.();
        return;
      }

      Message.error(message);
    })
    .catch((err: any) => {
      console.log('err: ', err);
      onFail?.();
    });
};
