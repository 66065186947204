import { useState, useEffect } from 'react';
import MaterialSelectModal from './Modal';
import { Input, Select, SelectProps } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { MaterialEntity } from 'src/entity/material/index.type';
import { SearchSelectOption } from '../configs';
interface MaterialSelectProps extends SelectProps<any> {
  mode?: 'multiple';
  params?: any;
  value?: LabeledValue | LabeledValue[]; // 作用：解决【BlSortFormList里面新增一行会清空上一行的控件内容】的问题，使用时不必传值
  onChange?: (res: SearchSelectOption | SearchSelectOption[]) => void;
}
const MaterialSelect = (props: MaterialSelectProps) => {
  const { mode, value, onChange, params, ...rest } = props;
  const [materialShowFlag, setMaterialShowFlag] = useState<boolean>(false);
  const [parentNode, setParentNode] = useState<any>();
  const [selectedValue, setSelectedValue] = useState<LabeledValue[] | LabeledValue>(value || []);

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    }
  }, [value]);
  const confirmCallback = (rows: MaterialEntity[]) => {
    setMaterialShowFlag(false);
    const results = rows.map((row) => {
      return {
        label: row?.baseInfo?.code || '',
        key: JSON.stringify(row),
        value: JSON.stringify(row),
      };
    });
    if (mode) {
      const valueArr = results.map((res) => res.label);
      setSelectedValue(results);
      if (typeof onChange === 'function') {
        onChange(results);
      }
    } else {
      setSelectedValue(results[0]);
      if (typeof onChange === 'function') {
        onChange(results[0]);
      }
    }
  };
  const show = (e: any) => {
    // const p = e.target?.closest('table');
    // setParentNode(p);
    setMaterialShowFlag(true);
  };
  return (
    <>
      <Select
        open={false}
        mode={mode}
        value={selectedValue}
        labelInValue
        optionLabelProp="label"
        onClick={(e) => (rest.disabled ? '' : show(e))}
        {...rest}
      />
      {materialShowFlag && (
        <MaterialSelectModal
          title="物料选择"
          mode={mode}
          parentNode={parentNode}
          params={params}
          selectedValue={selectedValue}
          visible={materialShowFlag}
          onCancel={() => setMaterialShowFlag(false)}
          onOk={confirmCallback}
        />
      )}
    </>
  );
};

export default MaterialSelect;
