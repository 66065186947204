import React from 'react';
import { useHistory } from 'react-router-dom';
import { RecordListLayout } from 'layout';
import { SearchSelect } from 'src/components';
import { getAuthFromLocalStorage } from 'src/utils/auth';
import { replaceSign } from 'src/utils/constants';
import { FilterItem } from 'src/layout/recordList';
import { fieldTypeList } from 'src/utils';
import { fetchTraceTraceNodeInventoryListNodes } from 'src/api/ytt/trace-domain/traceNode';
import _Time from 'src/utils/dataTypes/time';
import _Array from 'src/utils/dataTypes/array';
import { intersectionValueOfId } from 'src/utils/array';
import _String from 'src/utils/dataTypes/string';
import { ColType } from './interface';
import { toTraceNodeChart, toTraceNodeDetail } from '../..';
import { message } from 'antd';

export default function TraceRelationshipList() {
  const history = useHistory();

  const fetchFn = async (params: any) => {
    if (!_Array.isEmpty(params.materialIdList) && !params?.batchNo && !params.qrCode) {
      message.error('批次号、标识码请至少输入其中一个条件再进行筛选');
      return Promise.reject('批次号、标识码请至少输入其中一个条件再进行筛选');
    }

    const res = await fetchTraceTraceNodeInventoryListNodes(params);

    return res;
  };

  const listCol = [
    {
      title: '批次号',
      dataIndex: 'batchNo',
      width: 150,
      sorter: true,
      render: (batchNo: string) => batchNo ?? replaceSign,
    },
    {
      title: '标识码',
      dataIndex: 'qrCode',
      width: 150,
      sorter: true,
      render: (qrCode: string) => qrCode ?? replaceSign,
    },
    {
      title: '物料编号',
      dataIndex: ['material', 'code'],
      width: 150,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '物料名称',
      dataIndex: ['material', 'name'],
      width: 150,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '生成时间',
      dataIndex: 'createdAt',
      width: 180,
      sorter: true,
      render: (createdAt: Date) => {
        if (!createdAt) return replaceSign;
        return _Time.format(createdAt);
      },
    },
  ];

  const getOperationList = (record: ColType) => {
    return [
      {
        title: '详情',
        onClick: () => {
          history.push(toTraceNodeDetail(record?.traceId));
        },
      },
      {
        title: '关系图',
        onClick: () => {
          history.push(toTraceNodeChart(record?.traceId));
        },
      },
    ];
  };

  const filterList: FilterItem[] = [
    {
      label: '批次号',
      name: 'batchNo',
      type: fieldTypeList.text,
    },
    {
      label: '标识码',
      name: 'qrCode',
      type: fieldTypeList.text,
    },
    {
      label: '物料编号',
      name: 'materialCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          labelPath="code"
          valuePath="id"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '物料名称',
      name: 'materialName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          labelPath="name"
          valuePath="id"
          labelInValue
          mode="multiple"
        />
      ),
    },
  ];

  const formatDataToQuery = (params: any) => {
    const { quickSearch, batchNo, qrCode, materialName, materialCode, ...rest } = params;

    const relParams = { ...rest };

    if (!_String.isEmpty(batchNo)) {
      relParams.batchNo = batchNo;
    }
    if (!_String.isEmpty(qrCode)) {
      relParams.qrCode = qrCode;
    }

    if (!_String.isEmpty(quickSearch)) {
      relParams.quickSearch = quickSearch;
    }

    if (!_Array.isEmpty(materialName) || !_Array.isEmpty(materialCode)) {
      relParams.materialIdList = intersectionValueOfId(materialName, materialCode);
    }

    return relParams;
  };

  return (
    <RecordListLayout<ColType>
      userAuth={getAuthFromLocalStorage()}
      columns={listCol}
      rowKey={(record: ColType) => record?.traceId}
      placeholder={'请输入批次号、标识码'}
      configcacheKey="traceList"
      formatDataToQuery={formatDataToQuery}
      filterList={filterList}
      requestFn={(params) => fetchFn(params)}
      getOperationList={getOperationList}
    />
  );
}
