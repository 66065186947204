/**
 * 选择角色
 */

import { FC, useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import type { CustomTagProps } from 'rc-select/lib/interface/generator';
import SearchSelect from 'src/components/searchSelect';
import { RoleDisplay } from 'src/components/avatar/show';
import type { LabelInValueSelectorProps } from '../userAndDepartmentSelect/index.d';
import type { SelectProps } from 'antd';

interface SimpleRole {
  id: number;
  name: string;
}

interface RoleSelectProps
  extends Pick<SelectProps<any>, 'allowClear' | 'disabled' | 'placeholder' | 'bordered'> {
  value?: SimpleRole | SimpleRole[];
  onChange?: (value: SimpleRole | SimpleRole[]) => void;
  /** 多选 */
  isMultiple?: boolean;
  params?: any;
}

/** IdName to LabeledValue */
const in2lb = (v: SimpleRole) => ({
  label: v?.name,
  value: v?.id,
});
/** LabeledValue to idName */
const lb2in = (v: LabelInValueSelectorProps): SimpleRole => ({
  id: _.toNumber(v?.value),
  name: v?.label,
});

const tagRender = ({ value, label, closable, onClose }: CustomTagProps) => {
  return (
    <RoleDisplay
      id={value as number}
      name={label as string}
      tagProps={{
        closable,
        onClose,
        style: { height: 24, display: 'flex', alignItems: 'center' },
      }}
      isShowTag
    />
  );
};

/**
 * 用于表单中选择角色的受控组件
 */
const RoleSelect: FC<RoleSelectProps> = (props) => {
  const { value, onChange, isMultiple = false, ...rest } = props;
  const [selected, setSelected] = useState<
    LabelInValueSelectorProps | LabelInValueSelectorProps[]
  >();

  const ssProps = useMemo(() => {
    const obj: any = { ...rest };

    if (isMultiple) {
      obj.mode = 'multiple';
    }
    return obj;
  }, [isMultiple]);

  const onSelectChange = (nextVal: LabelInValueSelectorProps | LabelInValueSelectorProps[]) => {
    setSelected(nextVal);
    if (nextVal === undefined) {
      onChange?.(nextVal);
    } else {
      onChange?.(
        isMultiple
          ? (nextVal as LabelInValueSelectorProps[]).map(lb2in)
          : lb2in(nextVal as LabelInValueSelectorProps),
      );
    }
  };

  // 初始化时进行数据回填
  useEffect(() => {
    if (!value || _.isEmpty(value)) {
      return;
    }
    setSelected(isMultiple ? (value as SimpleRole[]).map(in2lb) : in2lb(value as SimpleRole));
  }, [value]);

  return (
    <SearchSelect
      fetchType="role"
      tagRender={tagRender}
      labelInValue
      value={selected}
      onChange={onSelectChange}
      {...ssProps}
    />
  );
};

export default RoleSelect;
