import { createModel } from '@rematch/core';
import { RootModel } from 'src/store/models/index';

interface EnergyMeterStateProps {
  areaValue: number[];
  subLocationFlag: boolean;
  selectNoLocationFlag: boolean;
}

export const energyMeter = createModel<RootModel>()({
  state: {
    areaValue: [] as number[],
    subLocationFlag: false,
    selectNoLocationFlag: false,
  },
  reducers: {
    setAreaValue(state: EnergyMeterStateProps, areaValue: number[]) {
      return {
        ...state,
        areaValue,
        selectNoLocationFlag: false,
      };
    },
    setSubLocationFlag(state: EnergyMeterStateProps, subLocationFlag: boolean) {
      return {
        ...state,
        subLocationFlag,
      };
    },
    selectNoLocationFlag(state: EnergyMeterStateProps) {
      return {
        ...state,
        selectNoLocationFlag: true,
      };
    },
    reset() {
      return {
        areaValue: [],
        subLocationFlag: false,
        selectNoLocationFlag: false,
      };
    },
  },
});
