import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { DataFormLayout } from 'layout';
import { Checkbox, Form, Input, message as Message, Select } from 'antd';
import SignatureConfigList from './components/SignatureConfigList';
import { ruleTypeEnum } from 'src/dict/eS-M/mappings';
import {
  fetchDigitalSignatureAdd,
  fetchDigitalSignatureDetail,
  fetchDigitalSignatureUpdate,
} from 'src/api/ytt/user-domain/eSignature';
import { formatParams, initialForm, updateSignRange } from './utils';
import PopoverLabel from './components/PopoverLabel';
import { YN } from 'src/dict/common';
import { SearchSelect } from 'src/components';
import { LabeledValue } from 'antd/lib/select';
import { match, RouteComponentProps } from 'react-router-dom';

const { TextArea } = Input;

interface SignatureActionProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const SignatureAction: React.FC<SignatureActionProps> = (props) => {
  const [form] = Form.useForm();
  const { match, history } = props;
  const id = Number(match?.params?.id);
  const isEdit = Boolean(id);
  const [continueAdd, setContinueAdd] = useState(false);
  const [objectCode, setObjectCode] = useState('');

  /**
   * 获取编辑数据
   * @id 电子签名ID
   */
  const fetchData = async (id?: number) => {
    let data = {} as any;

    if (id) {
      const res = await fetchDigitalSignatureDetail({ id });

      data = res?.data;
    }

    initialForm(form, isEdit, data);
  };

  /**
   * 获取提交方法
   * @isEdit 是否编辑
   */
  const submitFunction = async (isEdit: boolean, params: any) => {
    let res = null;

    if (isEdit) {
      delete params.relatedObjectCode;
      delete params.functionCode;
      params.id = id;

      res = await fetchDigitalSignatureUpdate(params);
    } else {
      res = await fetchDigitalSignatureAdd(params);
    }
    return res;
  };

  const onSubmit = () => {
    form.validateFields().then(async (values) => {
      const params = formatParams(values);

      if (isEdit) {
        params.id = id;
      }

      const res = await submitFunction(isEdit, params);

      const { code, message } = res;

      if (code !== 200) {
        Message.error(message);
        return;
      }
      Message.success(message);
      // refetch();
      if (continueAdd) {
        form.resetFields();
        fetchData();
      } else {
        history.goBack();
      }
    });
  };

  const getBaseInfo = (isEdit: boolean) => {
    const baseInfo = {
      title: '基本信息',
      items: [
        {
          label: '电子签名名称',
          name: 'name',
          rules: [{ required: true, message: '电子签名名称必填' }],
          render: () => <Input maxLength={20} style={{ width: 260 }} placeholder={'请输入名称'} />,
        },
        {
          label: '所属对象',
          name: 'relatedObjectCode',
          rules: [{ required: !isEdit, message: '所属对象必填' }],
          render: () => (
            <SearchSelect
              style={{ width: 260 }}
              disabled={isEdit}
              fetchType={'bizObject4Signature'}
              placeholder={'请选择所属对象'}
              onChange={(obj: LabeledValue) => setObjectCode(obj?.value as string)}
            />
          ),
        },
        {
          label: '功能点',
          name: 'functionCode',
          rules: [{ required: !isEdit, message: '功能点必填' }],
          render: () => {
            return (
              <SearchSelect
                style={{ width: 260 }}
                disabled={isEdit || !objectCode}
                params={{ objectCode }}
                fetchType={'signatureFunction'}
                placeholder={'请选择功能点'}
              />
            );
          },
        },
        {
          label: '备注',
          name: 'remark',
          render: () => <TextArea placeholder={'请输入备注'} />,
        },
      ],
    };

    if (isEdit) {
      baseInfo.items.push({
        label: '编辑原因',
        name: 'updateReason',
        render: () => <TextArea placeholder={'请输入编辑原因'} />,
      });
    }

    return baseInfo;
  };

  /**
   * 添加签名人范围
   * @param chooseList 已选中数据
   * @param signType 添加类型  用户/角色
   * @param optIndex 当前操作数据索引
   */
  const addSignRange = (chooseList: any[], signType: string, optIndex: number) => {
    const allUserFlag = chooseList[0]?.allUserFlag ? YN.yes : YN.no;
    const currentUserFlag = chooseList[0]?.currentUserFlag ? YN.yes : YN.no;

    const signRange = chooseList.map((item) => ({
      type: signType,
      label: item.name,
      value: item.id,
    }));

    updateSignRange({ form, signRange, index: optIndex, allUserFlag, currentUserFlag });
  };

  const getSignInfo = () => {
    return {
      title: '签名人配置',
      items: [
        {
          label: (
            <PopoverLabel
              label={'签名规则'}
              content={['多人签名时，需要按顺序签名请选择串行，多人无序签名请选择并行']}
            />
          ),
          name: 'ruleType',
          rules: [{ required: true, message: '签名规则必填' }],
          render: () => (
            <Select
              style={{ width: 260 }}
              disabled
              placeholder={'请选择签名规则'}
              options={ruleTypeEnum}
            />
          ),
        },
        {
          label: '',
          isFullLine: true,
          name: 'configList',
          render: () => (
            <SignatureConfigList form={form} name={'configList'} addSignRange={addSignRange} />
          ),
        },
      ],
    };
  };

  useEffect(() => {
    fetchData(id);
  }, []);

  return (
    <DataFormLayout
      form={form}
      title={isEdit ? '编辑电子签名' : '新增电子签名'}
      extra={
        !isEdit && (
          <Checkbox onChange={(e) => setContinueAdd(e.target.checked)} defaultChecked={continueAdd}>
            连续新建
          </Checkbox>
        )
      }
      info={[getBaseInfo(isEdit), getSignInfo()]}
      onCancel={() => {
        history.goBack();
      }}
      onFinish={onSubmit}
    />
  );
};

export default SignatureAction;
