import React, { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import { Avatar, Popover, Tag } from 'antd';
import { fetchDepartmentUserList, fetchRoleGet } from 'src/api/ytt/user-domain';
import { blacklakeGreen } from 'src/styles/color';
import { BlIcon, Tooltip } from '..';

export const avatarStyle = {
  backgroundColor: blacklakeGreen,
  marginRight: 4,
  marginBottom: 2,
  lineHeight: '14px',
};

interface AvatarDisplayProps {
  id: number | string;
  avatarUrl?: string;
  name: string;
  isUser?: boolean;
  isShowTag?: boolean;
  displayIcon?: React.ReactNode;
  /* 部门是否hover展示用户 */
  isShowUsers?: boolean;
  maxWidth?: number;
  tagMaxWidth?: number;
}

const UserListPopoverContent: FC<{ type: 'dept' | 'role'; id: number }> = ({ type, id }) => {
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    const fn = type === 'role' ? fetchRoleGet : fetchDepartmentUserList;

    fn({ id }).then((res) => {
      setUsers(res.data?.users ?? []);
    });
  }, [type, id]);

  return (
    <div style={{ width: 208, height: 105, overflowY: 'auto' }}>
      {users.map((item) => item.name).join('；')}
    </div>
  );
};

const _getAvatar = (
  avatarUrl: string | undefined,
  isUser?: boolean,
  displayIcon?: React.ReactNode,
) => {
  displayIcon =
    displayIcon ?? (isUser ? <BlIcon type="icongeren" /> : <BlIcon type="iconzuzhijiagou" />);
  return avatarUrl ? (
    <Avatar size={16} src={avatarUrl} style={avatarStyle} />
  ) : (
    <Avatar icon={displayIcon} size={16} style={avatarStyle} />
  );
};

const avatarDisplay = (props: AvatarDisplayProps) => {
  const {
    avatarUrl,
    name,
    id,
    isUser,
    isShowTag,
    displayIcon,
    isShowUsers,
    maxWidth,
    tagMaxWidth = 150,
  } = props;

  const maxWidthStyle = {
    maxWidth,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    borderStyle: 'none',
  };

  let comp = isShowTag ? (
    <Tag key={id} style={{ ...maxWidthStyle }} title={name}>
      {_getAvatar(avatarUrl, isUser, displayIcon)}
      <Tooltip text={name} width={tagMaxWidth} />
    </Tag>
  ) : (
    <span key={id} style={{ whiteSpace: 'nowrap', ...maxWidthStyle }} title={name}>
      {_getAvatar(avatarUrl, isUser, displayIcon)}
      <Tooltip text={name} width={tagMaxWidth} />
    </span>
  );

  if (!isUser && isShowUsers) {
    comp = (
      <Popover content={<UserListPopoverContent type="dept" id={_.toNumber(id)} />}>{comp}</Popover>
    );
  }

  return comp;
};

const avatarPictuer = (pictureUrl: string, defaultIcon?: React.ReactNode) => {
  return (
    <div
      style={{
        marginLeft: 20,
        display: 'flex',
        alignItems: 'flex-end',
        width: 240,
        justifyContent: 'space-between',
      }}
    >
      {pictureUrl ? (
        <Avatar size={86} src={pictureUrl} />
      ) : (
        <Avatar size={86} icon={defaultIcon || <BlIcon type="icongeren" />} />
      )}
      大图
      {pictureUrl ? (
        <Avatar size={32} src={pictureUrl} />
      ) : (
        <Avatar size={32} icon={defaultIcon || <BlIcon type="icongeren" />} />
      )}
      缩略图
    </div>
  );
};

interface RoleDisplayProps extends Pick<AvatarDisplayProps, 'id' | 'name' | 'isShowTag'> {
  tagProps?: Object;
  isShowUsers?: boolean;
  tagMaxWidth?: number;
}

export const RoleAvatar: FC<{ name: string }> = ({ name }) => (
  <Avatar size={18} style={{ marginRight: 8, backgroundColor: blacklakeGreen }}>
    {name.charAt(0)}
  </Avatar>
);

const RoleDisplay: FC<RoleDisplayProps> = (props) => {
  const { id, name, isShowTag, tagProps = {}, isShowUsers = false, tagMaxWidth = 150 } = props;

  let comp = isShowTag ? (
    <Tag key={id} {...tagProps} style={{ borderStyle: 'none' }}>
      <Popover content={name}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <RoleAvatar name={name} />
          <span
            style={{
              display: 'inline-block',
              maxWidth: tagMaxWidth,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {name}
          </span>
        </div>
      </Popover>
    </Tag>
  ) : (
    <span key={id} style={{ whiteSpace: 'nowrap' }}>
      <RoleAvatar name={name} />
      {name}
    </span>
  );

  if (isShowUsers) {
    comp = (
      <Popover content={<UserListPopoverContent type="role" id={_.toNumber(id)} />}>{comp}</Popover>
    );
  }
  return comp;
};

const AvatarDisplay = avatarDisplay;

export { avatarDisplay, avatarPictuer, AvatarDisplay, RoleDisplay };
