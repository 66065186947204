/**
 * SearchSelect 配置
 */
import { ReactNode, ReactText } from 'react';
import _, { filter } from 'lodash';
import { appDict } from 'src/dict';
//
import { BlSearchSelectFormatterData } from '@blacklake-web/component';
import {
  fetchUserList,
  fetchDepartmentList,
  fetchRoleList,
  fetchDepartmentListUserUnderDepartment,
  FetchDepartmentListUserUnderDepartmentResponse,
} from 'src/api/ytt/user-domain';
import {
  fetchMaterialList,
  fetchMaterialListToApi,
} from 'src/api/ytt/material-domain/Web_MaterialBaseInfo';
import { fetchResourcesScreenList } from 'src/api/ytt/resource-domain/workCenter/index';
import type { FetchDepartmentListResponse } from 'src/api/ytt/user-domain';
import {
  fetchSopList,
  fetchSopControlListBizControl,
  FetchSopControlListBizControlResponse,
  fetchSopListForFilter,
} from 'src/api/ytt/sop-domain';
import { fetchProcess } from 'src/api/ytt/med-domain/process';
import { ControlType } from 'src/dict/sop';
import { fetchBomList } from 'src/api/ytt/med-domain/bom';
import { fetchFileListBySearch } from 'src/api/ytt/holyfile-domain';
import { fetchUnitList } from 'src/api/ytt/material-domain/web-unit';
import {
  fetchProcessRoute,
  fetchProcessRouteDetail,
  FetchProcessRouteDetailResponse,
  fetchProcessRouteProcessNode,
} from 'src/api/ytt/med-domain/process-route';
import {
  fetchWorkOrderBaseList,
  FetchWorkOrderBaseListResponse,
  fetchWorkOrderFilterList,
} from 'src/api/ytt/med-domain/work_order';
import {
  fetchStandardBizObjectListPage,
  fetchStandardBizObjectObjectList,
} from 'src/api/ytt/metadata-domain';

import { fetchStorageWarehousePageList } from 'src/api/ytt/storage-domain/warehouse';
import {
  fetchMaterialInventoryListUnit,
  FetchMaterialInventoryListUnitResponse,
} from 'src/api/ytt/material-domain/Web_MaterialInventory';
import { fetchNumberRuleList } from 'src/api/ytt/custom-domain';
import { fetchWorkCenterList } from 'src/api/ytt/work-center-domain';
import { fetchCustomerList } from 'src/api/ytt/order-domain/customer';
import { fetchBatchNoList } from 'src/api/ytt/material-domain/Batch_No';
import { fetchSupplierList, FetchSupplierListResponse } from 'src/api/ytt/supply-domain/supplier';
import { fetchStorageLocationPageList } from 'src/api/ytt/storage-domain/position';
import {
  fetchSalesOrderList,
  fetchSalesOrderListSimple,
} from 'src/api/ytt/order-domain/salesOrder';
import { fetchProxyApiMaterialList } from 'src/api/ytt/order-domain/common';
import {
  fetchResourcesBusinessType,
  FetchResourcesBusinessTypeRequest,
  fetchResourcesBrandModel,
  FetchResourcesBrandModelResponse,
  FetchResourcesScreenListResponse,
  fetchResourcesList,
} from 'src/api/ytt/resource-domain/resource';
import {
  fetchMalfunctionSourceObjectList,
  FetchMalfunctionSourceObjectListResponse,
} from 'src/api/ytt/resource-domain/malfunctionRecord';
import { fetchResourcesLabelGetLabelTree } from 'src/api/ytt/resource-domain/resourceTag';
import { fetchResourceTypeList } from 'src/api/ytt/resource-domain/resourceCategory';
import { fetchParamsDefList } from 'src/api/ytt/resource-domain/parmDefine';
import { fetchResourceSchedulerList } from 'src/api/ytt/resource-domain/collectionRules';
import {
  fetchRepairCaseList,
  fetchRepairCaseTaskCaseList,
} from 'src/api/ytt/resource-domain/repairPlan';

import {
  fetchShiftList,
  FetchShiftListResponse,
  fetchShiftRuleList,
} from 'src/api/ytt/calendar-domain';
import { fetchReportTemplateList } from 'src/api/ytt/report-domain/reportTemplate';

import { REST_SHIFT } from 'src/utils/constants';
import { dealWithPeriodOfTime } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import {
  TableRowType,
  ShiftInfoDetailVOS,
} from 'src/page/knowledgeManagement/calendarManagement/shift/interface';
import { fetchQcCheckItemCategoryPageList } from 'src/api/ytt/quality-domain/checkClass';
import {
  fetchQcCheckItemPageList,
  FetchQcCheckItemPageListResponse,
} from 'src/api/ytt/quality-domain/checkItem';
import _Array from 'src/utils/dataTypes/array';
import CheckItem from 'src/page/quality/checkItem/list';
import { fetchQcDefectRankPageList } from 'src/api/ytt/quality-domain/qc_defect_rank';
import { fetchQcDefectReasonPageList } from 'src/api/ytt/quality-domain/qc_defect_reason';
import {
  fetchDataSetQueryDataSetPage,
  FetchDataSetQueryDataSetPageResponse,
  fetchDataSetQueryRelateParamValue,
  FetchDataSetQueryRelateParamValueResponse,
} from 'src/api/ytt/e-report-domain/dataSet';
import {
  fetchEReportQueryTemDataSetListNoStatus,
  FetchEReportQueryTemDataSetListNoStatusResponse,
} from 'src/api/ytt/e-report-domain/customReport';
import { fetchQcConfigPageList } from 'src/api/ytt/quality-domain/qcConfig';
import { fetchBizList, FetchBizListResponse } from 'src/api/ytt/message-domain/type';
import { fetchModuleList, FetchModuleListResponse } from 'src/api/ytt/message-domain/module';
import {
  fetchStandardBizObjectTreeByCode,
  FetchStandardBizObjectTreeByCodeResponse,
} from 'src/api/ytt/metadata-domain/standardBizObject';
import {
  fetchTriggerRulePageList,
  fetchTriggerRuleQueryInstance,
  FetchTriggerRuleQueryInstanceResponse,
  FetchTriggerRulePageListResponse,
  fetchTriggerRuleQueryBizOperation,
  FetchTriggerRuleQueryBizOperationResponse,
  fetchTriggerActionPageList,
  fetchTriggerActionSupportObjectPageList,
} from 'src/api/ytt/trigger-domain';
import {
  fetchPrivilegeListEnableSignatureFunction,
  FetchPrivilegeListEnableSignatureFunctionResponse,
} from 'src/api/ytt/user-domain/privilege';
import { fetchLogBizOperationList, FetchLogBizOperationListResponse } from 'src/api/ytt/log-domain';
import { valueOrHolder } from 'src/utils/formatters';

import {
  fetchCustomObjectFuzzySearch,
  FetchCustomObjectFuzzySearchResponse,
} from 'src/api/ytt/custom-object-domain';
import { fetchMaterialCategoryList } from 'src/api/ytt/material-domain/Web_MaterialCategory';
import {
  fetchApprovalPlanCanApproveObjects,
  FetchApprovalPlanCanApproveObjectsResponse,
  fetchApprovalPlanList,
} from 'src/api/ytt/metadata-domain/approvalProcessScheme';
import { fetchMaintenanceCaseList } from 'src/api/ytt/resource-domain/maintenancePlan';
import {
  fetchMatchRuleQueryTemplateForMatchRuleChoose,
  FetchMatchRuleQueryTemplateForMatchRuleChooseResponse,
} from 'src/api/ytt/e-report-domain/matchRule';
import { fetchCustomerDeliveryScheduleNoteList } from 'src/api/ytt/supply-chain-domain/customer_delivery_schedule_note';
import {
  fetchCustomMadeReportQueryCustomMadeReportConfigFront,
  FetchCustomMadeReportQueryCustomMadeReportConfigFrontResponse,
} from 'src/api/ytt/e-report-domain/customizedReports';
import { fetchLayoutList } from 'src/api/ytt/layout-domain';
import {
  fetchStandardBizObjectCustomObjectGetSonObjectAndFieldById,
  FetchStandardBizObjectCustomObjectGetSonObjectAndFieldByIdResponse,
} from 'src/api/ytt/metadata-domain/custom_object';
import { fetchStandardBizObjectListPageForDigitalSignature } from 'src/api/ytt/metadata-domain/objectPlatform';
import { fetchCurrencyList, FetchCurrencyListResponse } from 'src/api/ytt/order-domain/currency';
import { fetchPurchaseOrderList } from 'src/api/ytt/order-domain/purchaseOrder';
import { fetchQcConfigListForTaskCreate } from 'src/api/ytt/quality-domain/app_qc_config';

interface responseData {
  data?: {
    total?: number;
    list?: any[];
  };
}

export interface SearchSelectOption {
  label: ReactNode;
  value: ReactText;
  key: ReactText;
  // [index: string]: any;
}

export type RenderOption = (item: any) => SearchSelectOption;

interface SearchSelectConfigs {
  [index: string]: {
    fetchFn: (params: any) => Promise<any>;
    formatter: (
      res: any,
      labelPath?: string | string[],
      valuePath?: string,
      renderOption?: RenderOption,
    ) => BlSearchSelectFormatterData;
    /**默认重置搜索字段名 */
    defaultSearchFieldName?: string;
    /**默认option.label 取值字段路径 */
    defaultLabelPath?: string;
    /**默认option.value 取值字段路径 */
    defaultValuePath?: string;
    /**自定义处理option */
    defaultRenderOption?: RenderOption;
  };
}

// 统一处理数据
const dataFormat = (
  res: responseData,
  labelPath: string | string[] = 'name',
  valuePath = 'id',
  renderOption?: RenderOption,
) => {
  const total = _.get(res, 'data.total', 0);
  const list = _.get(res, 'data.list', []);

  return {
    total: total || 0,
    options: _.map(list, (item: any) => {
      if (_.isFunction(renderOption)) return renderOption(item);
      let label = _.get(item, labelPath);

      if (_.isArray(labelPath)) {
        label = labelPath.map((path) => _.get(item, path)).join('/');
      }

      return {
        label: valueOrHolder(label),
        value: _.get(item, valuePath),
        key: _.get(item, valuePath),
      };
    }),
  };
};

export const configs: SearchSelectConfigs = {
  /** 用户 */
  user: {
    fetchFn: fetchUserList,
    formatter: dataFormat,
    defaultSearchFieldName: 'search',
  },
  /** 部门 */
  department: {
    fetchFn: fetchDepartmentList,
    formatter: (res: FetchDepartmentListResponse, ...arg) =>
      dataFormat(
        {
          data: {
            total: res?.data?.length ?? 0,
            list: res?.data,
          },
        },
        ...arg,
      ),
  },
  /** 角色 */
  role: {
    defaultSearchFieldName: 'quickQueryParam',
    fetchFn: fetchRoleList,
    formatter: dataFormat,
  },
  /** 单位定义列表 */
  unit: {
    fetchFn: fetchUnitList,
    formatter: dataFormat,
  },
  /** 物料属性列表 */
  /** 物料分类列表 */
  /** 物料 */
  material: {
    fetchFn: fetchMaterialList,
    formatter: dataFormat,
  },
  /** 物料分类列表 */
  materialCategory: {
    fetchFn: fetchMaterialCategoryList,
    formatter: dataFormat,
  },
  /** 物料单位 （主单位、转换单位、辅助单位）*/
  materialUnit: {
    fetchFn: fetchMaterialInventoryListUnit,
    formatter: (res: FetchMaterialInventoryListUnitResponse, ...arg) =>
      dataFormat(
        {
          data: {
            total: res?.data?.length ?? 0,
            list: res?.data,
          },
        },
        ...arg,
      ),
  },
  /** 物料，编号/名称 */
  materialCodeName: {
    fetchFn: fetchMaterialList,
    defaultRenderOption: (item) => ({
      label: `${item.code} / ${item.name}`,
      value: item.id,
      key: item.id,
    }),
    formatter: dataFormat,
  },
  /** 物料（输出所有信息，联动带出物料编号，物料属性的需求） */
  materialCompleteInfo: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchMaterialListToApi,
    defaultRenderOption: (item) => ({
      label: item.baseInfo.code,
      value: JSON.stringify(item),
      key: item.baseInfo.id,
    }),
    formatter: dataFormat,
  },
  /** 采购订单下的物料选择，联动带出采购周期，可用库存的需求 */
  materialForPurchaseOrder: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchProxyApiMaterialList,
    defaultRenderOption: (item) => ({
      label: item.materialName,
      value: JSON.stringify(item),
      key: item.materialId,
    }),
    formatter: dataFormat,
  },
  /** 工作中心列表 */
  workCenter: {
    fetchFn: fetchWorkCenterList,
    formatter: dataFormat,
  },
  /** sop方案列表 */
  SOP: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchSopList,
    defaultRenderOption: (item) => ({
      label: `${item.code}/${item.name}`,
      value: item.sopId,
      key: item.sopId,
    }),
    formatter: dataFormat,
  },
  /** sop方案列表-筛选 */
  SOPFilter: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchSopListForFilter,
    defaultRenderOption: (item) => ({
      label: `${item.code}/${item.name}`,
      value: item.sopId,
      key: item.sopId,
    }),
    formatter: dataFormat,
  },
  /** SOP数字控件：单位选项 */
  SOPUnitOptions: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchUnitList,
    formatter: dataFormat,
    defaultRenderOption: (item) => {
      return {
        label: item.name,
        value: item.id,
        key: item.id,
      };
    },
  },
  /** 设备（资源）列表 */
  resource: {
    fetchFn: fetchResourcesScreenList,
    defaultSearchFieldName: 'quickSearch',
    // formatter: dataFormat,
    formatter: (res: FetchResourcesScreenListResponse, ...arg) =>
      dataFormat(
        {
          data: {
            total: res?.data?.data?.total ?? 0,
            list: res?.data?.data?.list,
          },
        },
        ...arg,
      ),
  },
  malfunctionSourceObjectList: {
    fetchFn: fetchMalfunctionSourceObjectList,
    defaultSearchFieldName: 'quickSearch',
    // formatter: dataFormat,
    formatter: (res: FetchMalfunctionSourceObjectListResponse, ...arg) => {
      console.log(res, arg);

      return dataFormat(
        {
          data: {
            total: res?.data?.total ?? 0,
            list: res?.data?.list,
          },
        },
        ['paramName', 'recordTime'],
        'id',
      );
    },
  },

  /** 设备（资源）列表 （带其他信息的） */
  resourceCompleteInfo: {
    fetchFn: fetchResourcesList,
    formatter: dataFormat,
    defaultRenderOption: (item) => {
      const newItem = _.pick(item, ['id', 'level', 'hasChildren', 'code', 'locationName']);

      return {
        label: item.name,
        value: JSON.stringify(newItem),
        key: item.id,
      };
    },
  },
  /** 资源标签树列表 */
  resourceTagTree: {
    fetchFn: fetchResourcesLabelGetLabelTree,
    formatter: dataFormat,
    defaultRenderOption: (item) => {
      return {
        label: item.name,
        value: JSON.stringify(item),
        key: item.id,
      };
    },
  },
  /** 资源标签树列表 */
  resourceTagTreeFormat: {
    fetchFn: fetchResourcesLabelGetLabelTree,
    formatter: dataFormat,
    defaultRenderOption: (item) => {
      const { id, name: label, children } = item;
      const obj: any = {
        id,
        label,
        children: children.map((data: any) => {
          const { id, content, groupId: labelId } = data;

          return { id, content, labelId };
        }),
      };

      return {
        label,
        value: JSON.stringify(obj),
        key: JSON.stringify(obj),
      };
    },
  },
  /** 资源分类列表 */
  resourceClassification: {
    fetchFn: fetchResourceTypeList,
    defaultLabelPath: 'className',
    formatter: dataFormat,
  },
  /** 参数定义列表 */
  resourceParams: {
    fetchFn: fetchParamsDefList,
    formatter: dataFormat,
    defaultRenderOption: (item) => ({
      label: item.name,
      value: JSON.stringify(item),
      key: item.id,
    }),
  },
  /** 资源参数定义单位选项 */
  resourceUnitOptions: {
    fetchFn: () => {
      const params: any = { enableFlag: 1 };
      return fetchUnitList({ ...params });
    },
    formatter: dataFormat,
    defaultRenderOption: (item) => {
      return {
        label: item.name,
        value: item.id,
        key: item.id,
      };
    },
  },
  /** 资源数采规则 */
  resourceDataCollectionRules: {
    fetchFn: fetchResourceSchedulerList,
    formatter: dataFormat,
  },
  /** 维修方案 */
  repairCase: {
    fetchFn: fetchRepairCaseList,
    formatter: dataFormat,
  },
  repairCaseForTask: {
    fetchFn: fetchRepairCaseTaskCaseList,
    formatter: dataFormat,
  },
  /** 工序列表 */
  process: {
    fetchFn: fetchProcess,
    formatter: dataFormat,
  },
  /** 工序编号加名称列表 */
  processCodeAndName: {
    fetchFn: fetchProcess,
    defaultRenderOption: (item) => ({
      label: `${item.code}/${item.name}`,
      value: item.id,
      key: item.id,
    }),
    formatter: dataFormat,
  },
  /** 工序列表，需要联动，带出所有工序信息 */
  processCompleteInfo: {
    fetchFn: fetchProcess,
    defaultRenderOption: (item) => ({
      label: item.code,
      value: JSON.stringify(item),
      key: item.id,
    }),
    formatter: dataFormat,
  },
  /** 获取标准对象  返回id*/
  bizObject: {
    defaultSearchFieldName: 'search',
    fetchFn: fetchStandardBizObjectListPage,
    defaultLabelPath: 'objectName',
    formatter: dataFormat,
  },
  /** 获取标准对象 提供所有对象*/
  bizObjectByCode: {
    defaultSearchFieldName: 'search',
    fetchFn: fetchStandardBizObjectListPage,
    defaultLabelPath: 'objectName',
    defaultValuePath: 'objectCode',
    formatter: dataFormat,
  },
  /** 获取标准对象 电子签名 */
  bizObject4Signature: {
    defaultSearchFieldName: 'search',
    fetchFn: fetchStandardBizObjectListPageForDigitalSignature,
    defaultLabelPath: 'objectName',
    defaultValuePath: 'objectCode',
    formatter: dataFormat,
  },
  /** 获取标准对象 提供对象返回的所有信息*/
  bizObjectByAllMsg: {
    defaultSearchFieldName: 'search',
    fetchFn: fetchStandardBizObjectListPage,
    defaultRenderOption: (item) => ({
      label: item.objectName,
      value: JSON.stringify(item),
      key: item.id,
    }),
    formatter: dataFormat,
  },
  /** 获取 触发事件 支持更新或者创建的对象*/
  supportObject: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchTriggerActionSupportObjectPageList,
    defaultRenderOption: (item) => ({
      label: item.objectName,
      value: JSON.stringify(item),
      key: item.id,
    }),
    formatter: dataFormat,
  },
  /** 对象的编号规则  查询的suitObjId从 src/entity/objectPlatform 中拿 */
  numberingRules: {
    fetchFn: fetchNumberRuleList,
    defaultLabelPath: 'ruleName',
    formatter: dataFormat,
  },
  /** 物料清单 版本 */
  bomVersions: {
    fetchFn: fetchBomList,
    defaultSearchFieldName: 'version',
    defaultLabelPath: 'version',
    formatter: dataFormat,
  },
  /** 文件 */
  files: {
    fetchFn: fetchFileListBySearch,
    defaultRenderOption: (item) => ({
      label: item.filename,
      value: JSON.stringify(item),
      key: item.id,
    }),
    formatter: dataFormat,
  },
  /** 客户 */
  customer: {
    fetchFn: fetchCustomerList,
    formatter: dataFormat,
    defaultSearchFieldName: 'name',
  },
  /** 客户编号 */
  customerCode: {
    fetchFn: fetchCustomerList,
    formatter: dataFormat,
    defaultLabelPath: 'code',
    defaultValuePath: 'id',
  },
  /** 客户编号加名称列表 */
  customerCodeName: {
    fetchFn: fetchCustomerList,
    defaultRenderOption: (item) => ({
      label: `${item.code}/${item.name}`,
      value: item.id,
      key: item.id,
    }),
    formatter: dataFormat,
  },
  /** 工艺路线列表 */
  processRouting: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchProcessRoute,
    formatter: dataFormat,
  },
  /** 工艺路线中配置的工序列表 */
  processOfprocessRouting: {
    fetchFn: fetchProcessRouteDetail,
    defaultLabelPath: 'processNum',
    defaultValuePath: 'id',
    formatter: (res: FetchProcessRouteDetailResponse, ...arg) =>
      dataFormat(
        {
          data: {
            total: res?.data?.processes?.length ?? 0,
            list: res?.data?.processes,
          },
        },
        ...arg,
      ),
  },
  /** 仓库 */
  warehouse: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchStorageWarehousePageList,
    formatter: dataFormat,
  },
  /** 仓库 带出信息 */
  warehouseAllInfo: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchStorageWarehousePageList,
    defaultRenderOption: (item) => ({
      label: item.name,
      value: JSON.stringify(item),
      key: item.id,
    }),
    formatter: dataFormat,
  },
  /** 批次号 */
  batchNo: {
    fetchFn: fetchBatchNoList,
    defaultLabelPath: 'batchNo',
    defaultValuePath: 'id',
    formatter: dataFormat,
  },
  /** 供应商 */
  supplier: {
    fetchFn: fetchSupplierList,
    formatter: dataFormat,
  },
  /** 供应商 */
  supplierWithCode: {
    fetchFn: fetchSupplierList,
    defaultRenderOption: (item) => ({
      label: `${item.code}/${item.name}`,
      value: item.id,
      key: item.id,
    }),
    formatter: dataFormat,
  },
  /** 对已注册的供应商进行物料注册 */
  supplierInfo: {
    fetchFn: fetchSupplierList,
    defaultRenderOption: (item) => ({
      label: item.code,
      value: JSON.stringify(item),
      key: item.id,
    }),
    formatter: dataFormat,
  },
  /** 生产工单 */
  workOrder: {
    fetchFn: fetchWorkOrderBaseList,
    defaultLabelPath: 'base.code',
    defaultValuePath: 'base.id',
    formatter: (res: FetchWorkOrderBaseListResponse, ...arg) =>
      dataFormat(
        {
          data: {
            total: res?.data?.length ?? 0,
            list: res?.data,
          },
        },
        ...arg,
      ),
  },
  /** sop方案下的业务控件  */
  sopBizControl: {
    fetchFn: async (params) => {
      try {
        const sopRes = await fetchSopControlListBizControl(params);

        if (_.has(params, 'controlType')) {
          sopRes.data = _.filter(sopRes?.data, ['controlType', params.controlType]);
        }

        return sopRes;
      } catch (error) {
        return Promise.reject([]);
      }
    },
    defaultLabelPath: 'code',
    formatter: (res: FetchSopControlListBizControlResponse, ...arg) =>
      dataFormat(
        {
          data: {
            total: res?.data?.length ?? 0,
            list: res?.data,
          },
        },
        ...arg,
      ),
  },
  /** sop方案下的业务控件-报工 待删除 */
  workReportControl: {
    fetchFn: fetchSopControlListBizControl,
    formatter: (res: FetchSopControlListBizControlResponse) => ({
      total: res?.data?.length || 0,
      options:
        _.filter(res?.data, ['controlType', ControlType.report])?.map((item: any) => ({
          label: item.code,
          value: item.id,
          key: item.id,
        })) ?? [],
    }),
  },
  /** sop方案下的业务控件-投料 待删除 */
  materialFeedControl: {
    fetchFn: fetchSopControlListBizControl,
    formatter: (res: FetchSopControlListBizControlResponse) => ({
      total: res?.data?.length || 0,
      options:
        _.filter(res?.data, ['controlType', ControlType.feed])?.map((item: any) => ({
          label: item.code,
          value: item.id,
          key: item.id,
        })) ?? [],
    }),
  },
  /** 仓位 */
  storageLocation: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchStorageLocationPageList,
    formatter: dataFormat,
  },
  /** 销售订单列表 带出所有信息*/
  salesOrderList: {
    fetchFn: fetchSalesOrderList,
    defaultRenderOption: (item) => ({
      label: item.header?.code,
      value: JSON.stringify(item),
      key: item.header?.id,
    }),
    formatter: dataFormat,
  },
  /** 销售订单简单信息列表 带出所有信息*/
  salesOrderListSimple: {
    fetchFn: fetchSalesOrderListSimple,
    defaultRenderOption: (item) => ({
      label: item.header?.code,
      value: item.header?.id,
      key: item.header?.id,
    }),
    formatter: dataFormat,
  },
  /** 销售订单简单信息列表 物料行*/
  salesOrderListSimpleLine: {
    fetchFn: fetchSalesOrderListSimple,
    formatter: ({ data }: any) => ({
      total: data?.total || 0,
      options:
        data?.list?.[0]?.items?.map((item: any) => ({
          label: item.lineNo,
          value: JSON.stringify({
            materialId: item?.material?.id,
            deliveryDate: item?.deliveryDate,
          }),
          key: JSON.stringify({
            materialId: item?.material?.id,
            deliveryDate: item?.deliveryDate,
          }),
        })) ?? [],
    }),
  },

  /** 销售订单列表 */
  salesOrder: {
    fetchFn: fetchSalesOrderList,
    defaultRenderOption: (item) => ({
      label: item.header?.code,
      value: item.header?.id,
      key: item.header?.id,
    }),
    formatter: dataFormat,
  },
  /**根据工艺路线id和工序号查询sop下的控件信息 */
  sopControlOfProcessRouting: {
    fetchFn: async (params) => {
      try {
        const ProcessRouteRes = await fetchProcessRouteProcessNode(params);
        const sopId = ProcessRouteRes.data?.sop?.id;
        const sopRes = await fetchSopControlListBizControl({ id: sopId });

        if (_.has(params, 'controlType')) {
          sopRes.data = _.filter(sopRes?.data, ['controlType', params.controlType]);
        }

        return sopRes;
      } catch (error) {
        return Promise.reject([]);
      }
    },
    defaultLabelPath: 'code',
    formatter: (res: FetchSopControlListBizControlResponse, ...arg) =>
      dataFormat(
        {
          data: {
            total: res?.data?.length ?? 0,
            list: res?.data,
          },
        },
        ...arg,
      ),
  },
  /** 设备定义的业务类型 */
  businessType: {
    fetchFn: (params: FetchResourcesBusinessTypeRequest | undefined) =>
      fetchResourcesBusinessType(params, { legacy: false }),
    formatter: ({ data }: any) => ({
      total: data?.total || 0,
      options:
        data?.map((item: any) => ({
          label: item.message,
          value: item.code,
          key: item.code,
        })) ?? [],
    }),
  },
  /** 设备定义的品牌类型 */
  brandModel: {
    fetchFn: fetchResourcesBrandModel,
    formatter: ({ data }: FetchResourcesBrandModelResponse) => ({
      // 品牌类型现在种类很少，远小于1页，后台接口数据类型没有total字段，可以用data.length来当total
      total: data?.length || 0,
      options:
        data?.map((model) => ({
          label: model.name!,
          value: model.id!,
          key: model.id!,
        })) || [],
    }),
  },
  /** 批次列表 直接使用下面👇🏻 BatchSearchSelect组件 */
  /** src/page/warehouseManagement/batchManagement/components/batchSearchSelect.tsx */
  batchList: {
    fetchFn: fetchBatchNoList,
    formatter: dataFormat,
  },
  /** 班次列表 */
  shiftList: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchShiftList,
    formatter: (res: responseData) => {
      const { total, options } = dataFormat(res);
      return {
        total,
        // 休息班次不会在班次列表里，需要前端hard code
        options: [...options, REST_SHIFT],
      };
    },
  },
  shiftListNoRest: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchShiftList,
    formatter: dataFormat,
  },
  /** 排班规则列表 */
  shiftRuleList: {
    fetchFn: fetchShiftRuleList,
    formatter: dataFormat,
  },
  /** 可绑定标识码的关联对象 仅用于新建标识码的关联对象 */
  objectByQrCode: {
    defaultSearchFieldName: 'search',
    fetchFn: fetchStandardBizObjectObjectList,
    formatter: ({ data }: any) => ({
      total: data?.total || 0,
      options: _.map(
        _.filter(data, (node: any) => node?.useIdentificationCode),
        (item: any) => {
          return {
            label: item.objectName,
            value: item.objectCode,
            key: item.objectCode,
          };
        },
      ),
    }),
  },

  /** 班次列表 label展示当前班次的详细时间段 */
  shiftRuleListByDetailCol: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchShiftList,
    formatter: ({ data }: FetchShiftListResponse) => {
      return {
        total: data?.total || 0,
        options: [
          REST_SHIFT,
          ..._.map(data?.list, (item: TableRowType) => {
            const { shiftInfoDetailVOS, name } = item;

            const formatTime = dealWithPeriodOfTime(shiftInfoDetailVOS)
              .map((element: ShiftInfoDetailVOS) => {
                return `${element?.startTime} -  ${element?.endTime}`;
              })
              .join('；');

            return {
              label: `${name}${!_Array.isEmpty(shiftInfoDetailVOS) ? `(${formatTime})` : ''}`,
              value: item?.id,
              key: item?.id,
            };
          }),
        ],
      };
    },
  },
  /** 报告模版列表 */
  reportTemplate: {
    fetchFn: fetchReportTemplateList,
    formatter: dataFormat,
  },
  /** 检验项分类 */
  checkItemCategory: {
    fetchFn: fetchQcCheckItemCategoryPageList,
    formatter: dataFormat,
  },

  /** 检验项名称 */
  checkItem: {
    fetchFn: fetchQcCheckItemPageList,
    formatter: ({ data }: FetchQcCheckItemPageListResponse) => ({
      total: data?.total || 0,
      options: _.map(data?.list, (i) => ({
        label: `${i.name} ${i.code ? `/${i.code}` : ''}`,
        value: i.id,
        category: { value: i.categoryId, label: i.categoryName },
        key: i.id,
      })),
    }),
  },

  /** 不良原因 */
  defectReason: {
    fetchFn: fetchQcDefectReasonPageList,
    formatter: dataFormat,
  },
  /** 不良等级 */
  defectRange: {
    fetchFn: fetchQcDefectRankPageList,
    formatter: dataFormat,
  },
  /** 数据集 */
  dataTemplate: {
    fetchFn: fetchDataSetQueryDataSetPage,
    formatter: ({ data }: FetchDataSetQueryDataSetPageResponse) => ({
      total: data?.total || 0,
      options:
        data?.list?.map((item: any) => ({
          label: `${item.code}/${item.dataSetName}`,
          value: item.id,
          key: item.id,
        })) ?? [],
    }),
  },

  /** 电子单据主模板里的数据集 */
  dataForTemDataSetListNoStatus: {
    fetchFn: fetchEReportQueryTemDataSetListNoStatus,
    defaultRenderOption: (item) => ({
      label:
        item?.serialNumber < 10
          ? `0${item?.serialNumber}_${item?.dataSetName}`
          : `${item?.serialNumber}_${item?.dataSetName}`,
      value: item.templateDataSetId,
      key: item.templateDataSetId,
    }),
    formatter: (res: FetchEReportQueryTemDataSetListNoStatusResponse, ...arg) =>
      dataFormat(
        {
          data: {
            total: res?.data?.templateDataSetInfoList?.length ?? 0,
            list: res?.data?.templateDataSetInfoList,
          },
        },
        ...arg,
      ),
  },
  /** 根据部门筛选 用户 */
  departmentListUserUnderDepartment: {
    fetchFn: fetchDepartmentListUserUnderDepartment,
    formatter: (res: FetchDepartmentListUserUnderDepartmentResponse, ...arg) =>
      dataFormat(
        {
          data: {
            total: res?.data?.length ?? 0,
            list: res?.data?.userList,
          },
        },
        ...arg,
      ),
  },

  /** 生产工单筛选 */
  workOrderFilterList: {
    fetchFn: fetchWorkOrderFilterList,
    defaultLabelPath: 'workOrderCode',
    defaultValuePath: 'workOrderId',
    formatter: dataFormat,
  },

  /** 检验方案 */
  qcConfigList: {
    fetchFn: fetchQcConfigPageList,
    formatter: dataFormat,
  },
  /** app创建质检任务匹配对应质检方案 */
  qcAppConfigList: {
    fetchFn: fetchQcConfigListForTaskCreate,
    formatter: dataFormat,
  },
  /** 模板数据集 当选择的关联关系时 可选的字段 */
  relatedToOneTemplate: {
    fetchFn: fetchDataSetQueryRelateParamValue,
    formatter: ({ data }: FetchDataSetQueryRelateParamValueResponse) => ({
      total: data?.previewData?.length || 0,
      options:
        data?.previewData?.map((item: any) => ({
          label: item?.[1],
          value: item?.[0],
          key: item?.[0],
        })) ?? [],
    }),
  },
  /** 通知模块 查询模块的接口 */
  moduleNotification: {
    fetchFn: fetchModuleList,
    formatter: ({ data }: FetchModuleListResponse) => ({
      total: data?.total || 0,
      options:
        data?.list?.map((item: any) => ({
          label: item.moduleName,
          value: item.id,
          key: item.id,
        })) ?? [],
    }),
  },
  /** 新建通知模板 根据所选的对象code  获取用户部门角色相关字段 */
  noticeFiled: {
    fetchFn: fetchStandardBizObjectTreeByCode,
    formatter: ({ data }: FetchStandardBizObjectTreeByCodeResponse) => ({
      total: data?.fields?.length || 0,
      options: filter(
        data?.fields,
        (node: any) =>
          node?.reference === 'User' ||
          node?.reference === 'Department' ||
          node?.reference === 'Role',
      ).map((e: any) => {
        return {
          label: e?.fieldName,
          value: JSON.stringify({ ...e, type: 4, objectCode: data?.objectCode }),
          key: e?.id,
        };
      }),
    }),
  },
  /** 触发规则 */
  triggerRules: {
    fetchFn: fetchTriggerRulePageList,
    formatter: ({ data }: FetchTriggerRulePageListResponse) => ({
      total: data?.total || 0,
      options:
        data?.list?.map((item: any) => ({
          label: item?.name,
          value: item?.ruleId,
          key: item?.ruleId,
        })) ?? [],
    }),
  },
  /** 查询功能点 */
  signatureFunction: {
    fetchFn: fetchPrivilegeListEnableSignatureFunction,
    formatter: ({ data }: FetchPrivilegeListEnableSignatureFunctionResponse) => ({
      total: data?.length || 0,
      options:
        data?.map((item: any) => ({
          label: item.name,
          value: item.code,
          key: item.code,
        })) ?? [],
    }),
  },

  /** 检验方案 */
  customObject: {
    fetchFn: fetchStandardBizObjectTreeByCode,
    defaultLabelPath: 'fieldName',
    defaultValuePath: 'id',
    formatter: (res: FetchStandardBizObjectTreeByCodeResponse, ...arg) =>
      dataFormat(
        {
          data: {
            total: 0, // || res?.data? ?? 0,
            list: res?.data?.fields,
          },
        },
        ...arg,
      ),
  },

  /** 触发规则, 返回接口所有item */
  triggerRuleByItem: {
    fetchFn: fetchTriggerRulePageList,
    defaultLabelPath: 'name',
    defaultValuePath: 'ruleId',
    defaultRenderOption: (item) => ({
      label: _.get(item, 'name'),
      value: JSON.stringify(item),
      key: _.get(item, 'ruleId'),
    }),
    formatter: dataFormat,
  },

  /** 触发规则，value返回id */
  triggerRule: {
    fetchFn: fetchTriggerRulePageList,
    defaultLabelPath: 'name',
    defaultValuePath: 'ruleId',
    formatter: dataFormat,
  },

  /** 实例对象 */
  triggerRuleQueryInstance: {
    fetchFn: fetchTriggerRuleQueryInstance,
    defaultLabelPath: 'label',
    defaultValuePath: 'value',
    formatter: (res: FetchTriggerRuleQueryInstanceResponse, ...arg) =>
      dataFormat(
        {
          data: {
            total: res.data?.datas.length || 0, // || res?.data? ?? 0,
            list: res?.data?.datas?.map((item) => ({ value: item[0], label: item[1] })),
          },
        },
        ...arg,
      ),
  },
  /** 查询操作元数据 支持获取操作code 进行操作日志的筛选 */
  operationCode: {
    fetchFn: fetchLogBizOperationList,
    formatter: ({ data }: FetchLogBizOperationListResponse) => ({
      total: data?.total || 0,
      options:
        data?.list?.map((item: any) => ({
          label: item.operationName,
          value: item.operationCode,
          key: item.operationCode,
        })) ?? [],
    }),
  },

  /** 触发事件 */
  triggerEvent: {
    fetchFn: fetchTriggerActionPageList,
    formatter: dataFormat,
  },

  /** 自定义对象 */
  customizedObject: {
    fetchFn: fetchCustomObjectFuzzySearch,
    defaultSearchFieldName: 'search',
    formatter: (res: FetchCustomObjectFuzzySearchResponse) => {
      const list = res.data ?? [];

      return {
        total: list.length,
        options: list.map(({ mainProperty, instanceId }) => ({
          label: mainProperty!,
          value: instanceId!,
          key: instanceId!,
        })),
      };
    },
  },

  /** 操作元数据 */
  bizOperation: {
    fetchFn: fetchTriggerRuleQueryBizOperation,
    defaultSearchFieldName: 'quickSearch',
    defaultLabelPath: 'name',
    defaultValuePath: 'code',
    formatter: (res: FetchTriggerRuleQueryBizOperationResponse, ...arg) =>
      dataFormat(
        {
          data: {
            total: res?.data?.length ?? 0,
            list: res?.data,
          },
        },
        ...arg,
      ),
  },
  /** 审批方案特定对象 */
  approvalObject: {
    fetchFn: fetchApprovalPlanCanApproveObjects,
    formatter: (res: FetchApprovalPlanCanApproveObjectsResponse) => {
      const list = res.data ?? [];

      return {
        total: list.length,
        options: list.map((e: any) => ({
          label: e?.objectName,
          value: JSON.stringify(e),
          key: e?.objectCode,
        })),
      };
    },
  },
  /** 维保方案 */
  maintenanceCase: {
    fetchFn: fetchMaintenanceCaseList,
    formatter: dataFormat,
  },
  /** 查询模版提供给匹配规则选择 */
  templateForMatchRuleChoose: {
    fetchFn: fetchMatchRuleQueryTemplateForMatchRuleChoose,
    formatter: (res: FetchMatchRuleQueryTemplateForMatchRuleChooseResponse) => {
      const list = res?.data ?? [];

      return {
        total: list?.length ?? 0,
        options: list.map((e: any) => ({
          label: `${e?.templateCode}/${e?.templateName}`,
          value: JSON.stringify(e),
          key: e?.subTemplateId,
        })),
      };
    },
  },

  /** 自定义布局 */
  customizedLayout: {
    fetchFn: fetchLayoutList,
    formatter: dataFormat,
  },
  /** 从对象 */
  sonObject: {
    fetchFn: fetchStandardBizObjectCustomObjectGetSonObjectAndFieldById,
    formatter: (res: FetchStandardBizObjectCustomObjectGetSonObjectAndFieldByIdResponse) => {
      const list = res.data ?? [];

      return {
        total: list.length,
        options: list.map((item) => ({
          label: item.referName,
          value: item.referName!,
        })),
      };
    },
  },
  /** 协同交货计划单 */
  collaborativeDeliverySchedule: {
    fetchFn: fetchCustomerDeliveryScheduleNoteList,
    formatter: dataFormat,
  },
  // 报表预览选择
  reportReview: {
    fetchFn: fetchCustomMadeReportQueryCustomMadeReportConfigFront,
    formatter: (res: FetchCustomMadeReportQueryCustomMadeReportConfigFrontResponse) => {
      const list = res.data ?? [];

      return {
        total: list.length,
        options: list.map((item) => ({
          label: item.name,
          value: item.id!,
          key: item.id,
        })),
      };
    },
  },
  /** 班次 班次的所有信息 */
  shiftListInfo: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchShiftList,
    defaultRenderOption: (item) => ({
      label: item.name,
      value: JSON.stringify(item),
      key: item.id,
    }),
    formatter: dataFormat,
  },
  /** 币种主数据 */
  currency: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchCurrencyList,
    defaultRenderOption: (item) => ({
      label: item.name,
      value: JSON.stringify(item),
      key: item.id,
    }),
    formatter: (res: FetchCurrencyListResponse, ...arg) =>
      dataFormat(
        {
          data: {
            total: res?.data?.length ?? 0,
            list: res?.data,
          },
        },
        ...arg,
      ),
  },
  /** 采购订单号 */
  purchaseOrderId: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchPurchaseOrderList,
    defaultRenderOption: (item) => ({
      label: item.code,
      value: item.id,
      key: item.id,
    }),
    formatter: dataFormat,
  },
  /** 审批方案 */
  approvePlan: {
    defaultSearchFieldName: 'quickSearch',
    fetchFn: fetchApprovalPlanList,
    formatter: dataFormat,
  },
};
