import React, { useState } from 'react';
import { Form, Checkbox, message as Message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import CreateAndEditBaseInfo from './components/createAndEditBaseInfo';
import { toList } from './navigation';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';
import { formatCrateAndEditValue } from './utils';
import { FieldObjType } from './interface';
import { fetchMessageTemplateCreate } from 'src/api/ytt/trigger-domain';

interface createProps extends RouteComponentProps {}

const CreateNoticeTemplate = (props: createProps) => {
  const { history } = props;

  const [createForm] = Form.useForm();
  const [continuousCreate, setContinuousCreate] = useState(false);
  const [inputSelectedOptions, setInputSelectedOptions] = useState<FieldObjType[]>([]);
  const [textSelectedOptions, setTextSelectedOptions] = useState<FieldObjType[]>([]);

  const extra = (
    <Checkbox
      onChange={(e) => {
        setContinuousCreate(e?.target?.checked);
      }}
    >
      连续新建
    </Checkbox>
  );

  // 切换对象需要重置字段选择数据
  const handleResetSelectOptions = () => {
    setInputSelectedOptions([]);

    setTextSelectedOptions([]);
  };

  const handleSetSelectOptions = (type: string, options?: FieldObjType[]) => {
    if (type === 'input') {
      setInputSelectedOptions(inputSelectedOptions?.concat(options ?? []));
    } else {
      setTextSelectedOptions(textSelectedOptions?.concat(options ?? []));
    }
  };

  const handleFinish = async () => {
    try {
      const value = await createForm?.validateFields();

      const newValue = formatCrateAndEditValue(value, inputSelectedOptions, textSelectedOptions);

      const { data } = await fetchMessageTemplateCreate({
        ...newValue,
      });

      if (data) {
        Message.success('创建成功');
        if (continuousCreate) {
          createForm.resetFields();
          return;
        }
        history.push(toList());
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <CreateAndEditBaseInfo
      edit={false}
      title="新建通知模板"
      onCancel={() => {
        history.push(toList());
      }}
      onFinish={throttleFn(handleFinish)}
      form={createForm}
      extra={extra}
      handleSetSelectOptions={handleSetSelectOptions}
      inputSelectedOptions={inputSelectedOptions}
      textSelectedOptions={textSelectedOptions}
      handleResetSelectOptions={handleResetSelectOptions}
    />
  );
};

export default CreateNoticeTemplate;
