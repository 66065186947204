import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import _ from 'lodash';
import { customEvents } from 'src/utils/events';
import { formatTooltipHtml } from '../utils';

type MixedLineAndBarProps = {
  title: string;
  unit: string | string[];
  chartData: any[];
  yAxisTitle: string[];
};

type SourceType = {
  xAxisData: string[];
  barData: number[];
  lineData: number[];
};

const MixedLineAndBar: React.FC<MixedLineAndBarProps> = (props) => {
  const { yAxisTitle, chartData, title, unit } = props;
  const chartRef = useRef<HTMLDivElement>(null);
  const chart = useRef<echarts.ECharts>();
  const colors = ['#02b980', '#2f69ff'];

  useEffect(() => {
    chart.current = echarts.init(chartRef.current as HTMLDivElement);
    setTimeout(() => {
      chart?.current?.resize();
    }, 0);

    customEvents.on('resize', () => {
      chart?.current?.resize();
    });
  }, []);

  /** 格式化toolTip */
  const barGroupToolTipFormat = _.debounce(
    (params: any[]) => {
      let resultHtml = '';

      params?.forEach(({ seriesType, color, value }) => {
        resultHtml += `
        <div style="font-size: 12px; color: #262626;">
          <span style="display: inline-block; margin-right: 4px; width: 10px; height: 10px; background-color: ${color};"></span>
          <span>
            ${seriesType === 'bar' ? '采购订单数' : '按时完成率'}: 
            ${value}${seriesType === 'bar' ? unit[0] : unit[1]}
          </span>
        </div>
      `;
      });

      return formatTooltipHtml(resultHtml, params[0]?.axisValueLabel);
    },
    300,
    { leading: true, maxWait: 1000 },
  );

  /** 刷新数据 */
  const setOption = (source: SourceType) => {
    const option = {
      grid: {
        left: 50,
        right: 50,
        top: 45,
        bottom: 45,
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        padding: 0,
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        position: (point: number[], params: any, dom: HTMLElement, rect: any, size: any) => {
          const [x, y] = point;
          const {
            contentSize: [width, height],
          } = size;

          return [x - width - 20, y - height / 2];
        },
        appendToBody: true,
        formatter: barGroupToolTipFormat,
      },
      color: colors,
      xAxis: {
        type: 'category',
        data: source.xAxisData,
        axisLabel: {
          show: true,
          interval: 0,
          formatter: (value: string) => {
            return value.length > 8 ? value.slice(0, 7) + '...' : value;
          },
          width: 48,
          color: 'rgba(0, 0, 0, 0.65)',
          overflow: 'breakAll',
        },
      },
      yAxis: [
        {
          name: yAxisTitle[0],
          nameTextStyle: {
            align: 'center',
            padding: [0, 0, 0, 10],
            color: 'rgba(0, 0, 0, 0.25)',
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
            },
          },
          axisLabel: {
            color: 'rgba(0, 0, 0, 0.25)',
          },
        },
        {
          name: yAxisTitle[1],
          splitLine: {
            show: false,
          },
          nameTextStyle: {
            align: 'right',
            padding: [0, -30, 0, 0],
            color: 'rgba(0, 0, 0, 0.25)',
          },
          axisLabel: {
            color: 'rgba(0, 0, 0, 0.25)',
          },
        },
      ],
      dataZoom: [
        {
          type: 'inside',
          show: true,
          xAxisIndex: [0],
          start: 0,
          end: 99,
        },
      ],
      series: [
        {
          type: 'bar',
          barMaxWidth: 12,
          data: source.barData,
        },
        {
          type: 'line',
          yAxisIndex: 1,
          data: source.lineData,
          symbol: 'circle',
          symbolSize: 4,
          itemStyle: {
            color: '#2f69ff',
            borderColor: 'rgba(47, 105, 255, 0.38)',
            borderWidth: 6,
          },
        },
      ],
    };

    chart?.current?.setOption(option, true);
  };

  useEffect(() => {
    if (_.isEmpty(chartData)) {
      return;
    }
    const xAxisData: string[] = [];
    const barData: number[] = [];
    const lineData: number[] = [];

    chartData.forEach((data) => {
      xAxisData.push(data?.supplier?.name);
      barData.push(data?.purchaseOrderNum);
      lineData.push(data?.rate);
    });
    setOption({
      xAxisData,
      barData,
      lineData,
    });
  }, [chartData]);

  return <div ref={chartRef} style={{ height: 280, width: '100%' }} />;
};

export default MixedLineAndBar;
