import { FieldType } from 'src/dict/customField';
import { FilterCondition } from './interface';

export const VIEW_MODAL = {
  TEMPLATE: '1', // 模板
  DATA: '2', // 数据集
};

export const LIST_VIEW_MODAL = {
  MAIN_LIST: '1',
  SUB_LIST: '2',
};

export const SYMBOL_ENUM = [
  { id: 1, key: '=', name: '等于' },
  { id: 2, key: '!=', name: '不等于' },
  { id: 3, key: 'IN', name: '属于' },
  { id: 4, key: 'NOT IN', name: '不包含' },
  { id: 5, key: 'IS NULL', name: '为空' },
  { id: 6, key: 'IS NOT NULL', name: '不为空' },
  { id: 7, key: '>', name: '大于' },
  { id: 8, key: '<', name: '小于' },
  { id: 9, key: '>=', name: '大于等于' },
  { id: 10, key: '<=', name: '小于等于' },
  { id: 11, key: 'like', name: '包含' },
];

export const SYMBOL_MAP = new Map([
  ['=', '等于'],
  ['!=', '不等于'],
  ['IN', '属于'],
  ['NOT IN', '不包含'],
  ['IS NULL', '为空'],
  ['IS NOT NULL', '不为空'],
  ['>', '大于'],
  ['<', '小于'],
  ['>=', '大于等于'],
  ['<=', '小于等于'],
  ['like', '包含'],
]);

// 模板类别
export const FIELD_TYPE_ONE_TO_ONE_SYMBOL_MAP = new Map([
  [FieldType.text, [1, 2, 5, 6, 11]],
  [FieldType.number, [1, 2, 5, 6, 7, 8, 9, 10]],
  [FieldType.textArea, [1, 2, 3, 5, 6]],
  [FieldType.select, [1, 4, 5, 6]],
  [FieldType.multiSelect, [3, 4, 5, 6]],
  [FieldType.boolean, [1, 4, 5, 6]],
  [FieldType.integer, [1, 2, 5, 6, 7, 8, 9, 10]],
  [FieldType.date, [1, 2, 5, 6, 7, 8, 9, 10]],
  [FieldType.url, []],
  [FieldType.reference, []],
  [FieldType.relation, [1]],
  [FieldType.relationSub, []],
  [FieldType.subordinate, [1, 2, 9, 10]],
  [FieldType.appendix, []],
]);

export const TEMPLATE_ENUM = [
  { key: 1, label: '生产主题' },
  { key: 2, label: '质量主题' },
  { key: 3, label: '仓储主题' },
  { key: 4, label: '资源主题' },
  { key: 5, label: '销售主题' },
  { key: 6, label: '采购主题' },
  { key: 7, label: '业务建模' },
  { key: 8, label: '工作日历' },
];

export const TEMPLATE_TYPE_ENUM = {
  TEMP: 0,
  PRODUCT: 1, // 生产主题
  QUALITY: 2, // 质量主题
  WAREHOUSING: 3, // 仓储主题
  RESOURCE: 4, // 资源主题
  SALES: 5, // 销售主题
  PURCHASE: 6, // 采购主题
  BUSINESS: 7, // 业务建模
  WORK_CALENDAR: 8, // 工作日历
};

export const TEMPLATE_TYPE_MAP = new Map([
  [0, ''],
  [1, '生产主题'],
  [2, '质量主题'],
  [3, '仓储主题'],
  [4, '资源主题'],
  [5, '销售主题'],
  [6, '采购主题'],
  [7, '业务建模'],
  [8, '工作日历'],
]);

export const BOOLEAN_ENUM_TYPE = [
  { label: '是', value: 'true', key: 'true' },
  { label: '否', value: 'false', key: 'false' },
];

export const filterConditionSingle = {
  fieldFilterInfo: {
    object: {
      key: '',
      label: '',
    },
    symbol: {
      key: '',
      label: '',
    },
    val: '',
  },
  nextFloorCondition: [],
  nextType: 'and',
};

export const filterInitDataByCreate: FilterCondition = {
  fieldFilterInfo: undefined,
  nextFloorCondition: [filterConditionSingle],
  nextType: 'and',
};

export const STATUS_TYPE = new Map([
  [0, '停用中'],
  [1, '停用中'],
  [2, '启用中'],
]);

export const STATUS_TYPE_ENUM = [
  { id: 0, name: '停用中' },
  { id: 1, name: '停用中' },
  { id: 2, name: '启用中' },
];

export const STATUS_ENUM_MAP = [
  { id: 1, name: '停用中' },
  { id: 2, name: '启用中' },
];

export const STATUS_ENUM = {
  TEMP: 0,
  DISABLED: 1,
  ENABLED: 2,
};

export const APPROVE_ENUM = {
  NO: 0,
  YES: 1,
};

export const APPROVE_TYPE = new Map([
  [APPROVE_ENUM.YES, '是'],
  [APPROVE_ENUM.NO, '否'],
]);

export const APPROVE_MAP = [
  { value: APPROVE_ENUM.YES, label: '是' },
  { value: APPROVE_ENUM.NO, label: '否' },
];
