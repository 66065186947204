import { useEffect, useState } from 'react';
import { BlTable } from 'src/components';
import lookup from 'src/dict';
import { mappingMaterialInfoAttrList } from 'src/page/warehouseManagement/utils';
import { replaceSign } from 'src/utils/constants';
import { CustomerDetailMaterialProps } from '../interface';

interface TableProps {
  requestFn: () => Promise<any>;
}

const DetailMaterialsRegistedTable = (props: TableProps) => {
  const { requestFn } = props;
  const [dataSource, setdataSource] = useState<Required<CustomerDetailMaterialProps>[]>([]);
  const [loading, setloading] = useState(true);

  /**
   * fetch 接口
   */
  const fetch = async () => {
    const { data } = await requestFn();

    setdataSource(data?.list);
    setloading(false);
  };
  /**
   * Columns
   */
  const dataColumns = [
    {
      title: '物料编号',
      dataIndex: 'materialCode',
      width: 150,
      isFilter: true,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '物料名称',
      dataIndex: 'materialName',
      width: 150,
      isFilter: true,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '物料属性',
      dataIndex: 'attribute',
      width: 150,
      render: mappingMaterialInfoAttrList,
    },
    {
      title: '数量',
      dataIndex: ['amount', 'amountDisplay'],
      width: 150,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '单位',
      dataIndex: ['amount', 'unitName'],
      width: 150,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '客户物料编号',
      dataIndex: 'customerMaterialCode',
      width: 150,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '客户物料名称',
      dataIndex: 'customerMaterialName',
      width: 150,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '客户物料数量',
      dataIndex: ['customerAmount', 'amountDisplay'],
      width: 150,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '客户物料单位',
      dataIndex: ['customerAmount', 'unitName'],
      width: 150,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
  ];

  /**
   * 初始化
   */
  useEffect(() => {
    if (typeof requestFn === 'function') {
      fetch();
    }
  }, []);

  return (
    <>
      <BlTable
        columns={dataColumns}
        dataSource={dataSource}
        pagination={false}
        rowKey={(record) => record?.seqNo}
        style={{ width: '100%' }}
        scroll={{ y: 600 }}
        loading={loading}
      />
    </>
  );
};

export default DetailMaterialsRegistedTable;
