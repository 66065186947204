import { useState, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, debounce, cloneDeep, map } from 'lodash';
import { Form, message, Select } from 'antd';
import { ColumnProps } from 'antd/es/table';
import BcBlMultiCascader from 'src/page/knowledgeManagement/materialModeling/material/share/Cascader/BcBlMultiCascader';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import {
  BlIcon,
  SearchSelect,
  showErrorListMessage,
  TagList,
  Tooltip,
  SingleOperationLogComponent,
} from 'src/components';
import { RecordListLayout, DataFormLayoutForModal, DetailLayoutForModal } from 'src/layout';
import { fieldTypeList, gcArray, gcTime } from 'src/utils';
import lookup, { appDict } from 'src/dict';
import {
  LaunchTypeEnumDisplayMap,
  RequirementTypeEnumDisplayMap,
  StatusEnumDisplayMap,
} from 'src/dict/planned/mappings';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import _Time from 'src/utils/dataTypes/time';
import { replaceSign } from 'src/utils/constants';
import { arrayIsEmpty, intersectionValueOfId } from 'src/utils/array';
import { StatusEnum } from 'src/dict/planned';
import {
  fetchPlanOrderList,
  fetchPlanOrderLaunchInfoGet,
  FetchPlanOrderLaunchInfoGetResponse,
  fetchPlanOrderBatchConfirm,
  fetchPlanOrderBatchClose,
  FetchPlanOrderBatchCloseResponse,
  fetchPlanOrderScheduling,
} from 'src/api/ytt/plo-domain';
import BcBlCascader from 'src/components/Cascader';
import { getMaterialAttrs } from 'src/entity/material';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { BUSINESS_TYPE } from 'src/dict/objImport';

import {
  toCreatePlannedOrder,
  toEditPlannedOrder,
  toDetailPlannedOrder,
  toLogPlannedOrder,
  toMaterialScheduling,
} from '../navigation';

import { PlanOrderResponseList } from '../createAndEdit/index.type';
// eslint-disable-next-line import/no-cycle
import ProductionTaskForm from '../creatProductionTask/ProductionTaskForm';
import SetPlannedOrder from './setPlannedOrder';
// eslint-disable-next-line import/no-cycle
import SplitOrderForm from '../splitOrderForm';
import { FIELD_MAP } from '../constants';
import showConfirmCloseOrder from './showConfirmCloseOrder';
import CpsiveReservationList from '../../reserve/comprehensiveReservation/list';
import { ReservedOrderType } from '../../planningOperation/constants';
import scheduling from '../../materialScheduling/scheduling';
import SchSetModal from '../../materialScheduling/schedulingSettingModal';
import { fetchReservedSynthesizeSuperscriptData } from '../../reserve/utils';

interface userProps {
  id: number;
  name: string;
  code: string;
  avatarUrl: string;
}

/**
 * 处理批量操作提示message
 * @param action
 * @param info
 */
export const handleBatchMessage = (
  action: string,
  info: FetchPlanOrderBatchCloseResponse['data'],
) => {
  const { failAmount = 0, successAmount = 0, failResults = [] } = info ?? {};

  if (failAmount) {
    showErrorListMessage({
      failCount: failAmount,
      successCount: successAmount,
      title: `${action}出现失败`,
      data: failResults,
      columns: [
        {
          title: '计划订单编号',
          dataIndex: 'planOrderCode',
          width: 100,
          render: (text: string, record: any) => (
            <a onClick={() => window.open(toDetailPlannedOrder(record.planOrderId))}>
              <Tooltip text={text} width={150} />
            </a>
          ),
        },
        { title: '失败原因', dataIndex: 'failedReason', width: 200 },
        { title: '', dataIndex: 'planOrderId', width: 1, render: () => null },
      ],
    });
  } else {
    message.success(`${action}成功！`);
  }
};

const PlannedOrderList = (props: { history: any }) => {
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [IssueTask, setIssueTask] = useState<FetchPlanOrderLaunchInfoGetResponse['data']>();
  const [PlannedOrderSetting, setPlannedOrderSetting] = useState<any>(false);
  const [operationRecordVisible, setOperationRecordVisible] = useState(false);
  const [datasetId, setDataSetId] = useState<number>(0);
  const [splitOrder, setSplitOrder] = useState<any[]>();
  const [cpsiveReservationList, setCpsiveReservationList] = useState<any>(false);
  const [schedulingSetting, setSchedulingSetting] = useState(false); // 排程方案设置

  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const { history } = props;

  const [formCloseOrderConFirm] = Form.useForm();

  const [formSchedulingConFirm] = Form.useForm();

  const refreshData = () => {
    setRefreshMarker(Math.random());
  };

  useEffect(() => {
    refreshData();
  }, [history, props]);

  const formatDataToDisplay = (data: any) => {
    const { createdAt, updatedAt, plannedStartTime, plannedEndTime, requirementTime, ...resData } =
      cloneDeep(data);

    return {
      ...resData,
      createdAt: !gcArray.isEmpty(createdAt) ? createdAt : undefined,
      updatedAt: !gcArray.isEmpty(updatedAt) ? updatedAt : undefined,
      plannedStartTime: !gcArray.isEmpty(plannedStartTime) ? plannedStartTime : undefined,
      plannedEndTime: !gcArray.isEmpty(plannedEndTime) ? plannedEndTime : undefined,
      requirementTime: !gcArray.isEmpty(requirementTime) ? requirementTime : undefined,
    };
  };

  const formatDataToFormDisplay = (data: any) => {
    const { createdAt, updatedAt, plannedStartTime, plannedEndTime, requirementTime, ...rest } =
      data;

    return {
      ...rest,
      createdAt: gcTime.formatRangeTimeToMoment(createdAt),
      updatedAt: gcTime.formatRangeTimeToMoment(updatedAt),
      plannedStartTime: gcTime.formatRangeTimeToMoment(plannedStartTime),
      plannedEndTime: gcTime.formatRangeTimeToMoment(plannedEndTime),
      requirementTime: gcTime.formatRangeTimeToMoment(requirementTime),
    };
  };

  const formatDataToQuery = (data: any) => {
    const {
      plannedStartTime = [],
      plannedEndTime = [],
      productionDepartmentIdList,
      productionSupervisorIdList,
      planningDepartmentIdList,
      planningUserIdList,
      sorter,
      materialCode,
      materialName,
      bomIdList,
      processRouteIdList,
      creatorIdList,
      operatorIdList,
      createdAt,
      updatedAt,
      workOrderCode,
      planOrderRequirementType,
      planOrderLaunchType,
      planOrderStatus,
      shiftVO,
      requirementTime,
      ...resData
    } = cloneDeep(data);

    const relParams = {
      ...resData,
    };

    if (sorter) {
      relParams.sorter = map(sorter, (node: any) => {
        return {
          order: node?.order,
          field: FIELD_MAP.get(node?.field),
        };
      });
    }

    const [plannedEndTimeFrom, plannedEndTimeTo] = gcTime.formatTimeRangeUnix(plannedEndTime);
    const [plannedStartTimeFrom, plannedStartTimeTo] = gcTime.formatTimeRangeUnix(plannedStartTime);
    const [createdAtFrom, createdAtTo] = gcTime.formatTimeRangeUnix(createdAt);
    const [updatedAtFrom, updatedAtTo] = gcTime.formatTimeRangeUnix(updatedAt);
    const [requirementTimeFrom, requirementTimeTo] = gcTime.formatTimeRangeUnix(requirementTime);

    const params = {
      ...resData,
      ...relParams,
      planOrderRequirementTypeList: planOrderRequirementType,
      planOrderLaunchTypeList: planOrderLaunchType,
      planOrderStatusList: planOrderStatus,
      shiftIdList: shiftVO ? map(shiftVO, 'key') : undefined,
      productionDepartmentIdList: !isEmpty(productionDepartmentIdList)
        ? [productionDepartmentIdList[0]?.value]
        : undefined,
      plannedEndTimeFrom,
      plannedEndTimeTo,
      plannedStartTimeFrom,
      plannedStartTimeTo,
      createdAtFrom,
      createdAtTo,
      updatedAtFrom,
      updatedAtTo,
      requirementTimeFrom,
      requirementTimeTo,
      productionSupervisorIdList: !isEmpty(productionSupervisorIdList)
        ? [productionSupervisorIdList[0]?.value]
        : undefined,
      planningDepartmentIdList: !isEmpty(planningDepartmentIdList)
        ? [planningDepartmentIdList[0]?.value]
        : undefined,
      planningUserIdList: !isEmpty(planningUserIdList) ? [planningUserIdList[0]?.value] : undefined,
      bomIdList: !arrayIsEmpty(bomIdList) ? map(bomIdList, 'value') : undefined,
      processRouteIdList: !arrayIsEmpty(processRouteIdList)
        ? map(processRouteIdList, 'value')
        : undefined,
      creatorIdList: creatorIdList ? [creatorIdList?.key] : undefined,
      operatorIdList: operatorIdList ? [operatorIdList?.key] : undefined,
      workOrderCode: workOrderCode ?? undefined,
      mainOutputMaterialIdList: intersectionValueOfId(materialCode, materialName),
    };

    dispatch.excelImport.updateBusinessData({ businessData: params });
    return params;
  };

  const mainMenu = [
    {
      title: '排程',
      auth: authDict.TScForMNL,
      onClick: () => {
        return new Promise((resolve, reject) => {
          scheduling(formSchedulingConFirm, () => {
            const deadlineTime = formSchedulingConFirm.getFieldValue('deadlineTime');

            return fetchPlanOrderScheduling({
              deadlineTime: Number(gcTime.formatToUnix(deadlineTime)),
            })
              .then((res) => {
                formSchedulingConFirm.resetFields();
                handleBatchMessage('排程', res.data as any);
                refreshData();
                resolve(true);
              })
              .catch(() => {
                reject(false);
              });
          });
          resolve(true);
        });
      },
      items: [
        {
          title: '物料排程设置',
          auth: authDict.TScForMNL,
          onClick: () => {
            history.push(toMaterialScheduling());
          },
        },
        {
          title: '排程方案设置',
          auth: authDict.TScForMNL,
          onClick: () => {
            setSchedulingSetting(true);
          },
        },
      ],
    },
    {
      title: '新建计划订单',
      auth: authDict.planOrder_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        history.push(toCreatePlannedOrder());
      },
      items: [
        {
          title: '导入',
          showExport: true,
          auth: authDict.planOrder_import,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.planOrder,
            });
          },
        },
        {
          title: '设置',
          auth: authDict.planOrder_set,
          onClick: () => {
            setPlannedOrderSetting(true);
          },
        },
        {
          title: '投放日志',
          auth: authDict.planOrder_launch,
          onClick: () => {
            history.push(toLogPlannedOrder());
          },
        },
      ],
    },
  ];

  const batchMenu = [
    {
      title: '关闭',
      key: 'close',
      auth: authDict.planOrder_close,
      onClick: () => {
        return new Promise((resolve, reject) => {
          showConfirmCloseOrder(formCloseOrderConFirm, () => {
            return fetchPlanOrderBatchClose({
              operateReason: formCloseOrderConFirm.getFieldValue('operateReason'),
              planOrderIdList: selectedRows.map((e) => e.planOrderId),
            })
              .then((res) => {
                formCloseOrderConFirm.resetFields();
                handleBatchMessage('关闭', res.data);
                refreshData();
                resolve(true);
              })
              .catch(() => {
                reject(false);
              });
          });
          resolve(true);
        });
      },
    },
    {
      title: '确认',
      key: 'confirm',
      auth: authDict.planOrder_confirm,
      onClick: () => {
        return fetchPlanOrderBatchConfirm({
          planOrderIdList: selectedRows.map((e) => e.planOrderId),
        }).then((res) => {
          handleBatchMessage('确认', res.data);
        });
      },
    },
    {
      title: '投放',
      key: 'launch',
      auth: authDict.planOrder_launch,
      onClick: () => {
        return fetchPlanOrderLaunchInfoGet({
          planOrderIdList: selectedRows.map((e) => e.planOrderId),
        }).then((res) => {
          const { data } = res;

          setIssueTask(data);
        });
      },
    },
    {
      title: '拆分',
      key: 'split',
      auth: authDict.planOrder_split,
      onClick: () => {
        const _selectedRows = selectedRows.filter(
          (e) => ![StatusEnum.CLOSED].includes(e.planOrderStatus) && e?.canLaunchAmount?.amount,
        );

        setSplitOrder(_selectedRows);

        return Promise.resolve();
      },
    },
  ];

  const columns: (ColumnProps<PlanOrderResponseList> & {
    filterIndex?: string;
    type?: number;
    isFilter?: boolean;
    selectProps?: any;
    renderItem?: ReactElement;
    dateFormat?: any;
  })[] = [
    {
      title: '计划订单编号',
      dataIndex: 'planOrderCode',
      width: 150,
      isFilter: true,
      sorter: true,
    },
    {
      title: '需求类型',
      dataIndex: 'planOrderRequirementType',
      filterIndex: 'planOrderRequirementType',
      width: 150,
      render: (text: any) => lookup('planned.RequirementTypeEnumDisplayMap', text),
      isFilter: true,
      renderItem: (
        <Select
          placeholder={'请选择需求类型'}
          options={RequirementTypeEnumDisplayMap}
          allowClear
          mode="multiple"
        />
      ),
    },
    {
      title: '投放类型',
      dataIndex: 'planOrderLaunchType',
      filterIndex: 'planOrderLaunchType',
      width: 150,
      render: (text: any) => lookup('planned.LaunchTypeEnumDisplayMap', text),
      isFilter: true,
      renderItem: (
        <Select
          placeholder={'请选择投放类型'}
          options={LaunchTypeEnumDisplayMap}
          allowClear
          mode="multiple"
        />
      ),
    },
    {
      title: '状态',
      dataIndex: 'planOrderStatus',
      filterIndex: 'planOrderStatus',
      width: 150,
      render: (text: any) => lookup('planned.StatusEnumDisplayMap', text),
      isFilter: true,
      renderItem: (
        <Select
          placeholder={'请选择状态'}
          options={StatusEnumDisplayMap}
          allowClear
          mode="multiple"
        />
      ),
    },
    {
      title: '需求时间',
      dataIndex: 'requirementTime',
      width: 180,
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: (text: any) => (text ? _Time.format(text) : replaceSign),
    },
    {
      title: '计划部门',
      dataIndex: 'planningDepartment',
      filterIndex: 'planningDepartmentIdList',
      width: 150,
      render: (planningDepartment: userProps) => {
        if (!planningDepartment) return replaceSign;
        return (
          <AvatarDisplay
            id={planningDepartment?.id}
            name={planningDepartment?.name}
            key={planningDepartment?.id}
            isShowTag
            isUser
          />
        );
      },
      isFilter: true,
      renderItem: <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple={false} />,
    },
    {
      title: '计划员',
      dataIndex: 'planningUser',
      filterIndex: 'planningUserIdList',
      width: 150,
      render: (planningUser: userProps) => {
        if (!planningUser) return replaceSign;
        return (
          <AvatarDisplay
            id={planningUser?.id}
            name={planningUser?.name}
            key={planningUser?.id}
            avatarUrl={planningUser?.avatarUrl}
            isShowTag
            isUser
          />
        );
      },
      isFilter: true,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: '计划开始时间',
      dataIndex: 'plannedStartTime',
      width: 180,
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: (text: any) => (text ? _Time.format(text) : replaceSign),
    },
    {
      title: '计划完工时间',
      dataIndex: 'plannedEndTime',
      width: 180,
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: (text: any) => (text ? _Time.format(text) : replaceSign),
    },
    {
      title: '生产部门',
      dataIndex: 'productionDepartment',
      filterIndex: 'productionDepartmentIdList',
      width: 150,
      render: (productionDepartment: userProps) => {
        if (!productionDepartment) return replaceSign;
        return (
          <AvatarDisplay
            id={productionDepartment?.id}
            name={productionDepartment?.name}
            key={productionDepartment?.id}
            isShowTag
            isUser
          />
        );
      },
      isFilter: true,
      renderItem: <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple={false} />,
    },
    {
      title: '生产主管',
      dataIndex: 'productionSupervisor',
      filterIndex: 'productionSupervisorIdList',
      width: 150,
      render: (productionSupervisor: userProps) => {
        if (!productionSupervisor) return replaceSign;
        return (
          <AvatarDisplay
            id={productionSupervisor?.id}
            name={productionSupervisor?.name}
            key={productionSupervisor?.id}
            avatarUrl={productionSupervisor?.avatarUrl}
            isShowTag
            isUser
          />
        );
      },
      isFilter: true,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: '班次',
      dataIndex: ['shiftVO', 'name'],
      filterIndex: 'shiftVO',
      width: 150,
      isFilter: true,
      renderItem: <SearchSelect fetchType="shiftList" mode="multiple" />,
    },
    {
      title: '物料编号',
      dataIndex: ['materialInfo', 'baseInfo', 'code'],
      filterIndex: 'materialCode',
      width: 150,
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="material"
          labelPath="code"
          valuePath="id"
          placeholder="请输入"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      title: '物料名称',
      dataIndex: ['materialInfo', 'baseInfo', 'name'],
      filterIndex: 'materialName',
      width: 150,
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="material"
          labelPath="name"
          valuePath="id"
          placeholder="请输入"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      title: '物料属性',
      dataIndex: ['materialInfo', 'attribute'],
      filterIndex: 'materialAttributeIds',
      type: fieldTypeList.text,
      renderItem: <BcBlMultiCascader type={'materialProperty'} nameLabel={'物料属性'} />,
      width: 150,
      render: (_text: any, record: any) => (
        <TagList dataSource={getMaterialAttrs(record.materialInfo)} />
      ),
    },
    {
      title: '物料分类',
      dataIndex: ['materialInfo', 'category', 'name'],
      filterIndex: 'materialCategoryIds',
      type: fieldTypeList.text,
      renderItem: (
        <BcBlCascader
          fetchType={'materialCategory'}
          nameLabel={'物料分类'}
          popupPlacement={'bottomRight'}
        />
      ),
      width: 150,
    },
    {
      title: '计划生产数',
      dataIndex: ['plannedAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '预计生产数',
      dataIndex: ['expectedAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '已投放数',
      dataIndex: ['launchAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '可投放数',
      dataIndex: ['canLaunchAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '版本号',
      dataIndex: ['bomVersion'],
      width: 150,
      filterIndex: 'bomIdList',
    },
    {
      title: '生成方式',
      dataIndex: ['generationMethod'],
      width: 150,
      filterIndex: 'generationMethod',
      isFilter: true,
      render: (generationMethod) => lookup('planned.GenerationMethodDisplayMap', generationMethod),
      renderItem: <Select options={appDict.planned.GenerationMethodDisplayMap} />,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
      render: (remark: string) => remark ?? replaceSign,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      filterIndex: 'creatorIdList',
      renderItem: <SearchSelect fetchType="user" labelInValue />,
      isFilter: true,
      width: 150,
      render: (text) => {
        const { id, name, avatarUrl } = text || {};

        return (
          id && (
            <AvatarDisplay
              id={id}
              name={name ?? ''}
              key={id}
              avatarUrl={avatarUrl}
              isShowTag={false}
              isUser
            />
          )
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      sorter: true,
      type: fieldTypeList.date,
      isFilter: true,
      width: 180,
      render: (text: any) => _Time.format(text),
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      filterIndex: 'operatorIdList',
      isFilter: true,
      renderItem: <SearchSelect fetchType="user" labelInValue />,
      width: 150,
      render: (text) => {
        const { id, name, avatarUrl } = text || {};

        return (
          id && (
            <AvatarDisplay
              id={id}
              name={name ?? ''}
              key={id}
              avatarUrl={avatarUrl}
              isShowTag={false}
              isUser
            />
          )
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      type: fieldTypeList.date,
      isFilter: true,
      width: 180,
      sorter: true,
      render: (text: any) => _Time.format(text),
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
  ];

  const getFilterList = () => {
    return columns
      .filter((i) => i.isFilter)
      .map((column: any) => {
        const filter: any = {
          label: column.title,
          name: column.filterIndex || column.dataIndex,
          type: column.type,
          rules: column.rules,
          renderItem: column.renderItem,
          dateFormat: column.dateFormat,
        };

        if (column.type === fieldTypeList.select || column.type === fieldTypeList.multiSelect) {
          filter.selectProps = column.selectProps;
        }
        return filter;
      });
  };

  const getOperationList = (
    record: any,
  ): {
    title: string;
    auth?: string;
    onClick: () => void;
    disable?: boolean;
  }[] => {
    const { planOrderId: id, planOrderStatus } = record;

    // 编辑条件
    const editFlag = [StatusEnum.PLAN, StatusEnum.PLANCONFIRMATION].includes(planOrderStatus);

    // 计划确认计划订单条件
    const planConfirmation = [StatusEnum.PLAN].includes(planOrderStatus);

    // 投放条件
    const launched = [StatusEnum.PLANCONFIRMATION, StatusEnum.LAUNCHED].includes(planOrderStatus);

    // 拆分条件
    const split = ![StatusEnum.CLOSED].includes(planOrderStatus);

    // 关闭条件
    const closed = [StatusEnum.PLAN, StatusEnum.PLANCONFIRMATION, StatusEnum.LAUNCHED].includes(
      planOrderStatus,
    );

    const list = [
      {
        title: '查看',
        auth: authDict.planOrder_detail,
        onClick: () => {
          history.push(toDetailPlannedOrder(record.planOrderId));
        },
      },
      {
        title: '操作记录',
        auth: authDict.planOrder_operrecord,
        onClick: () => {
          setDataSetId(record?.planOrderId);
          setOperationRecordVisible(true);
        },
      },
    ];

    if (split) {
      list.push({
        title: '拆分',
        auth: authDict.planOrder_split,
        onClick: () => {
          // 拆分条件
          setSplitOrder([record]);
        },
      });
    }

    if (editFlag) {
      list.push({
        title: '编辑',
        auth: authDict.planOrder_edit,
        onClick: () => {
          history.push(toEditPlannedOrder(record.planOrderId));
        },
      });
    }

    if (launched) {
      list.push({
        title: '投放',
        auth: authDict.planOrder_launch,
        onClick: debounce(async () => {
          await fetchPlanOrderLaunchInfoGet({
            planOrderIdList: [id],
          }).then((res) => {
            const { data } = res;

            setIssueTask(data);
          });
        }, 1500),
      });
    }

    if (planConfirmation) {
      list.push({
        title: '确认',
        auth: authDict.planOrder_confirm,
        onClick: debounce(async () => {
          await fetchPlanOrderBatchConfirm({
            planOrderIdList: [id],
          }).then((res) => {
            const { code = 0 } = res ?? {};

            if (code === 200) refreshData();
            message.success('确认成功!');
          });
        }, 1500),
      });
    }

    if (closed) {
      list.push({
        title: '关闭',
        auth: authDict.planOrder_close,
        onClick: debounce(() => {
          return new Promise((resolve, reject) => {
            showConfirmCloseOrder(formCloseOrderConFirm, () => {
              return fetchPlanOrderBatchClose({
                operateReason: formCloseOrderConFirm.getFieldValue('operateReason'),
                planOrderIdList: [id],
              })
                .then((res) => {
                  formCloseOrderConFirm.resetFields();
                  handleBatchMessage('关闭', res.data);
                  refreshData();
                  resolve(true);
                })
                .catch(() => {
                  reject(false);
                });
            });
          });
        }, 1500),
      });
    }

    if (![StatusEnum.CLOSED].includes(planOrderStatus)) {
      list.push({
        title: '综合查询',
        auth: authDict.PlanReserved_detail,
        onClick: debounce(async () => {
          fetchReservedSynthesizeSuperscriptData({
            orderId: id,
            orderType: ReservedOrderType.PLAN_ORDER,
          }).then((reservedCommonData) => {
            if (!reservedCommonData) {
              message.error('暂无预留');
              return;
            }
            setCpsiveReservationList({
              data: reservedCommonData,
              param: {
                orderId: id,
                orderType: ReservedOrderType.PLAN_ORDER,
              },
            });
          });
        }, 1500),
      });
    }

    return list;
  };

  return (
    <>
      <RecordListLayout<PlanOrderResponseList>
        configcacheKey="planOrderList"
        filterList={getFilterList()}
        requestFn={fetchPlanOrderList}
        // requestFn={fetchWorkOrderBaseList}
        mainMenu={mainMenu}
        batchMenu={batchMenu}
        placeholder={'请输入计划订单编码'} // 快速搜索input placeholder
        formatDataToQuery={formatDataToQuery} // 转换搜索条件进行查询（快速查询字段："quickSearch",当前页："page",分页大小："size"）
        formatDataToDisplay={formatDataToDisplay} // 转换搜索条件进行展示
        columns={columns} // table columns
        selectedRowKeys={selectedRowKeys}
        refreshMarker={refreshMarker}
        rowKey="planOrderId"
        onSelectedRowKeys={(selectKey, selectRows) => {
          setSelectedRowKeys(selectKey);
          setSelectedRows(selectRows || []);
        }}
        pagination={{ pageSizeOptions: ['10', '20'] }}
        userAuth={getAuthFromLocalStorage()}
        getOperationList={getOperationList}
        formatDataToFormDisplay={formatDataToFormDisplay}
      />
      {/* 设置 */}
      <DataFormLayoutForModal
        visible={PlannedOrderSetting}
        onClose={() => {
          setPlannedOrderSetting(undefined);
        }}
        content={
          <SetPlannedOrder
            onCancel={() => {
              setPlannedOrderSetting(undefined);
            }}
          />
        }
      />
      {/* 投放 */}
      <DataFormLayoutForModal
        visible={!isEmpty(IssueTask)}
        onClose={() => {
          setIssueTask(undefined);
        }}
        content={
          <ProductionTaskForm
            name="launchRequests"
            params={IssueTask}
            history={history}
            onCancel={() => {
              setIssueTask(undefined);
            }}
            onChange={refreshData}
          />
        }
      />
      {/* 操作记录 */}
      {operationRecordVisible && (
        <SingleOperationLogComponent
          visible={operationRecordVisible}
          instanceId={datasetId}
          objectCode={'PlanOrder'}
          closeDetail={() => {
            setOperationRecordVisible(false);
          }}
        />
      )}

      {/* 拆分 */}
      <DataFormLayoutForModal
        visible={!isEmpty(splitOrder)}
        onClose={() => {
          setSplitOrder(undefined);
        }}
        content={
          <SplitOrderForm
            name="planOrderSplitItemList"
            params={splitOrder}
            history={history}
            onCancel={() => {
              setSplitOrder(undefined);
            }}
            onChange={refreshData}
          />
        }
      />
      <DetailLayoutForModal
        visible={cpsiveReservationList}
        onClose={() => {
          setCpsiveReservationList(false);
        }}
        content={
          <CpsiveReservationList
            parameter={cpsiveReservationList?.param}
            initData={cpsiveReservationList?.data}
          />
        }
      />
      {/* 排程方案设置 */}
      <DataFormLayoutForModal
        visible={schedulingSetting}
        onClose={() => {
          setSchedulingSetting(false);
        }}
        content={
          <SchSetModal
            name="scheduleSetting"
            params={splitOrder}
            history={history}
            onCancel={() => {
              setSchedulingSetting(false);
            }}
            onChange={refreshData}
          />
        }
      />
    </>
  );
};

export default PlannedOrderList;
