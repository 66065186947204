import { History } from 'history';
import { SplitRequisitionOrigin } from 'src/dict/storeRequisition';
import { StoreRequisitionDetailType } from './constants';

export const PRODUCTION_PLANNING_PATH = '/productionPlanning/execution';

/** 跳转到新建页面 */
export const goToCreate = (ids: number[], origin: SplitRequisitionOrigin) => {
  window.reactRouterHistoryInstance.push(
    `${PRODUCTION_PLANNING_PATH}/productManagement/storeRequisition/batchCreate?ids=${ids}&originType=${origin}`,
  );
};

export const goToCommonCreate = () => {
  window.reactRouterHistoryInstance.push(
    `${PRODUCTION_PLANNING_PATH}/productManagement/storeRequisition/create`,
  );
};

/** 跳转到编辑页面 */
export const goToEdit = (storeRequisitionId: number) => {
  window.reactRouterHistoryInstance.push(
    `${PRODUCTION_PLANNING_PATH}/productManagement/storeRequisition/edit/${storeRequisitionId}`,
  );
};

/** 跳转到详情页面 */
export const goToDetail = (storeRequisitionId: number, openInNewTab?: boolean) => {
  const url = `${PRODUCTION_PLANNING_PATH}/productManagement/storeRequisition/detail/${storeRequisitionId}`;

  if (openInNewTab) window.open(url);
  else window.reactRouterHistoryInstance.push(url);
};

/** 跳转到物料详情页面 */
export const goToMaterialDetail = (id: number) => {
  window.open(`/modeling/materialModeling/materialList/${id}/details`);
};

/** 跳转到工序详情页面 */
export const goToProcessDetail = (id: number) => {
  window.open(`/productionPlanning/engineering/process/${id}/detail`);
};

/** 跳转到工作中心详情页面 */
export const goToWorkCenterDetail = (id: number) => {
  window.open(`/productionPlanning/engineering/workCenter/detail/${id}`);
};

/** 跳转到生产工单详情页面 */
export const goToWorkOrderDetail = (id: number) => {
  window.open(`/productionPlanning/execution/productionOrder/detail/${id}`);
};

/** 跳转到调拨单详情页面 */
export const goToTranferOrder = (id: number) => {
  window.open(`/warehouseManagement/boundOrderManagement/transferOrder/${id}/detail`);
};

/** 跳转到调拨单详情页面 */
export const goToMergeTranfer = () => {
  window.reactRouterHistoryInstance.push(
    '/productionPlanning/execution/productManagement/storeRequisition/mergeTransferOrder',
  );
};

/** 跳转返回列表页 */
export const goBackToList = (history?: History) => {
  history
    ? history.push('/productionPlanning/execution/productManagement/storeRequisition')
    : window.reactRouterHistoryInstance.push(
        '/productionPlanning/execution/productManagement/storeRequisition',
      );
};

export const goToIntegratePick = () => {
  window.reactRouterHistoryInstance.push(
    `${PRODUCTION_PLANNING_PATH}/productManagement/storeRequisition/integratePick`,
  );
};

/**
 * 格式转换：详情接口返回值转换为前端表格需要的格式
 * @param detailResponseData 详情接口返回值携带的数据
 */
export const formatMaterialLineToDisplay = (detailResponseData: StoreRequisitionDetailType) => {
  const { materials, pickOrderCode, pickOrderId, ...rest } = detailResponseData;

  return {
    ...rest,
    pickOrderCode: pickOrderCode!,
    code: pickOrderCode!,
    pickOrderId: pickOrderId!,
    reason: '',
    materials: materials?.map((materialLine) => {
      const {
        sourceWarehouse,
        sourceWarehouseId,
        sourceWarehouseName,
        targetWarehouse,
        targetWarehouseId,
        targetWarehouseName,
        batchCode,
        requestPickAmount,
      } = materialLine;

      return {
        ...materialLine,
        requestPickAmount,
        code: pickOrderCode,
        sourceWarehouseId: sourceWarehouseId
          ? {
              value: {
                id: sourceWarehouseId,
                name: sourceWarehouseName,
                code: sourceWarehouse?.code,
              },
              label: sourceWarehouseName,
            }
          : undefined,
        targetWarehouseId: targetWarehouseId
          ? {
              value: {
                id: targetWarehouseId,
                name: targetWarehouseName,
                code: targetWarehouse?.code,
              },
              label: targetWarehouseName,
            }
          : undefined,
        batchNo: batchCode ? { value: batchCode.id, label: batchCode.code } : undefined,
        unit: { label: requestPickAmount.unitName, value: requestPickAmount.unitId },
      };
    }),
  };
};

export type FormDataType = ReturnType<typeof formatMaterialLineToDisplay> & {
  reason: string;
  deleteList?: { [key: string]: number[] };
};
