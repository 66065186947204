/**
 * 自定义校验方法
 */

import { FormInstance } from 'antd';
import { RuleObject } from 'antd/lib/form';
import _ from 'lodash';
import _Array from '../dataTypes/array';
import { checkChineseNotSupported, naturalNumber, numberCheck } from './regularValidator';

/**
 * 文本输入统一校验规则。以这篇文档为准:
 * https://blacklake.feishu.cn/wiki/wikcnXCluzuSQoknmkZ6Esd82ox#
 */
const textRules = [
  {
    reg: /^[a-zA-Z0-9\u4e00-\u9fa5`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、Ω≈ç√˜µ≤≥÷…˚æ“‘«”「」’℃\s]*$/,
    message: '必须是字母、数字、中文、特殊字符',
  },
  {
    reg: /^[a-zA-Z0-9`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、Ω≈ç√˜µ≤≥÷…˚æ“‘«”「」’\s]*$/,
    message: '必须是字母、数字、特殊字符',
  },
  {
    reg: /^[a-zA-Z\d_]*$/,
    message: '必须是字母、数字、下划线',
  },
];
const textValidatorFactory =
  (rule: typeof textRules[number]) => (__: unknown, value: string | undefined) => {
    return new Promise((resolve, reject) => {
      if (!value || rule.reg.test(value)) {
        resolve(true);
      } else {
        reject(rule.message);
      }
    });
  };
const textValidators = _.fromPairs(
  textRules.map((item, idx) => [`textValidator${idx + 1}`, textValidatorFactory(item)]),
);

const { textValidator1, textValidator2, textValidator3 } = textValidators;

/**
 * 表单仅支持字母数字下划线
 * @param rule
 * @param value
 */
const checkFormWithLetterDigitalUnderscore = (rule: any, value: string) => {
  const reg = /^[a-zA-Z\d_]*$/g;

  if (!reg.test(value)) {
    return Promise.reject(new Error('仅支持字母，数字和下划线'));
  }
  return Promise.resolve();
};

/**
 * 前后不能出现空格
 * @param rule
 * @param value
 * @returns
 */
const checkTwoSidesTrim = (rule: any, value: string) => {
  if (_.startsWith(value, ' ') || _.endsWith(value, ' ')) {
    return Promise.reject(new Error('不支持前后出现空格'));
  }
  return Promise.resolve();
};

/**
 * 字符中间最多出现三个空格
 * @param rule
 * @param value
 * @returns
 */
const whiteSpaceInMiddleValidator = (rule: any, value: string) => {
  const reg = /^\S+\s+\s+\s+\s+\S+$/;

  if (reg.test(value)) {
    return Promise.reject(new Error('不支持中间出现三个以上的空格'));
  }
  return Promise.resolve();
};

/**
 * 手机号验证
 * @param type
 * @returns
 */
const telValidator = (rule: any, value: string) => {
  const phoneReg = /^\+?[0-9\-]{11,100}$/;
  // const phoneReg = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
  // const phoneReg = /^1\d{10}$/;

  if (!phoneReg.test(value) && value) {
    return Promise.reject(new Error('请输入正确格式的手机号'));
  }
  return Promise.resolve();
};

// BlSortFormList校验套餐
// 校验选项重复
const validateFormListRepeat = (
  fieldName: string | (string | number)[],
  targetItem: string,
  form: FormInstance,
  message?: string,
) => {
  return (_rule: RuleObject, value: number) => {
    return new Promise((resolve, reject) => {
      if (!value) {
        return resolve(true);
      }
      const valueList: any = form.getFieldValue(fieldName);
      const list: number[] = valueList.map((el: any) => el && el[targetItem]); // 从 valueList 找出 目标项 组成 []
      const repeatList: number[] = _Array.findDuplicates(list);

      // value 如果存在 在UserOptionalRange项 [] 中，则 reject
      if (repeatList.indexOf(value) !== -1) {
        return reject(message || '已存在该选项！');
      }

      return resolve(true);
    });
  };
};

// 校验 不可与其他表单项重复
const validateNotRepeatWith = (
  name: { field: string; fieldName: string; translateField?: string }[],
  formm: FormInstance,
) => {
  return (_rule: RuleObject, value: { label: string; value: number; key: number }) => {
    return new Promise((resolve, reject) => {
      if (!value?.value) {
        return resolve(true);
      }
      name?.map((item) => {
        const formValues = formm.getFieldValue(item.field);

        // 检查是否与 formList里的每一项的某一列 重复
        if (Array.isArray(formValues)) {
          formValues.map((el) => {
            if (item.translateField && el[item.translateField] === value?.value) {
              return reject(`不可与${item.fieldName}重复`);
            }
            if (item.translateField && el[item.translateField].value === value?.value) {
              return reject(`不可与${item.fieldName}重复`);
            }
          });
        }
        if (formValues?.value === value?.value) {
          return reject(`不可与${item.fieldName}重复`);
        }
      });

      return resolve(true);
    });
  };
};

/**
 * @description: 必须是字母、数字和符号（除中文）
 * @param {*}
 * @return {*}
 */
const validateBlInputText = (name?: string) => {
  const reg = /[\u4e00-\u9fa5]+/;

  return (_rule: RuleObject, value: string, _callback: (error?: string) => void) => {
    return new Promise<void>((resolve, reject) => {
      if (!value) return resolve();
      if (reg.test(value)) reject(`${name || ''}必须是字母、数字和符号`);

      return resolve();
    });
  };
};

/**
 * @description: 小数点位数校验
 */
const fractionLengthCheck = (maxLength: any, message?: string) => {
  return (_rule: RuleObject, value: string, _callback: (error?: string) => void) => {
    const fraction = value && value.toString().split('.')[1];

    return new Promise<void>((resolve, reject) => {
      if (_.isNumber(maxLength) && fraction && fraction.length > maxLength) {
        return reject(message ? message : `小数点后最多保留${maxLength}位数字`);
      }

      return resolve();
    });
  };
};

/**
 * @description: 整数位数校验
 */
const integerLengthCheck = (minLen: number, maxLen: number, message?: string) => {
  return (_rule: RuleObject, value: string) => {
    const integer = value.toString().split('.')[0];

    return new Promise<void>((resolve, reject) => {
      if (integer.length > maxLen) {
        reject(message || `整数位数最多${maxLen}位`);
      } else if (integer.length < minLen) {
        reject(message || `整数位数最少${minLen}位`);
      } else {
        resolve();
      }
    });
  };
};

/**
 * @description: 同时整数和小数的位数校验，包含负数
 */
const numberLengthCheck = (
  lengthBeforePoint: number,
  lengthAfterPoint: number,
  errorMessage?: string,
) => {
  return (_rule: RuleObject, value: string) => {
    const regString = `^-?\\d{1,${lengthBeforePoint}}(\\.\\d{1,${lengthAfterPoint}})?$`;
    const reg = new RegExp(regString); // /^-?\d{1,9}(\.\d{1,6})?$/;

    return new Promise<void>((resolve, reject) => {
      if (!value) resolve();
      if (!reg.test(value)) {
        reject(
          errorMessage ||
            `请输入整数位不超过${lengthBeforePoint}位且小数位不超过${lengthAfterPoint}位的数字`,
        );
      }
      resolve();
    });
  };
};

/**
 * @description: 判断数值
 * @param {number} max: 最大值
 * @param {*} maxAllowEqual 最大值是否允许等于，默认true
 * @param {*} promptByInterval 错误提示是否按照区间提示，默认false
 * @param {*} isRequired 是否必填，默认true
 */
const numberMinMaxCheck = (config: {
  max?: number;
  maxAllowEqual?: boolean;
  min?: number;
  minAllowEqual?: boolean;
  isInteger?: boolean;
  fieldName?: string;
  promptByInterval?: boolean;
  isRequired?: boolean;
  message?: string;
}) => {
  return (_rule: RuleObject, value: any) => {
    const {
      max = Infinity,
      maxAllowEqual = true,
      min = -Infinity,
      minAllowEqual = true,
      isInteger = false,
      fieldName = '数字',
      promptByInterval = false,
      isRequired = true,
      message = '',
    } = config;
    const reg = /^(-?\d+)(\.\d+)?$/;
    const intReg = new RegExp('^-?\\d*$');

    return new Promise<void>((resolve, reject) => {
      if (_.isNil(value) || value === '') return resolve();

      if (isRequired && !reg.test(value)) {
        return reject(new Error(message ? message : '请输入数字'));
      }
      if (isInteger && !intReg.test(value)) {
        return reject(new Error('请输入整数'));
      }
      /**
       *  按照区间提示
       */
      if (promptByInterval) {
        if (!isRequired && (value === '' || value === _.isNil(value))) {
          return resolve();
        }
        if (maxAllowEqual && minAllowEqual) {
          if (value > max || value < min)
            reject(message || `${fieldName}必须大于等于${min}，小于等于${max}`);
          return resolve();
        }
        if (!maxAllowEqual && !minAllowEqual) {
          if (value >= max || value <= min)
            reject(message || `${fieldName}必须大于${min}，小于${max}`);
          return resolve();
        }
        if (maxAllowEqual) {
          if (value <= min || value > max)
            reject(message || `${fieldName}必须大于${min}，小于等于${max}`);
          return resolve();
        }
        if (minAllowEqual) {
          if (value < min || value >= max)
            reject(message || `${fieldName}必须大于等于${min}，小于${max}`);
          return resolve();
        }
        return resolve();
      }
      /**
       *  按照最大或最小提示
       */
      if (!isRequired && (value === '' || value === _.isNil(value))) {
        return resolve();
      }
      if (maxAllowEqual && value > max) reject(message || `${fieldName}必须小于等于${max}`);
      else if (!maxAllowEqual && value >= max) reject(message || `${fieldName}必须小于${max}`);
      else if (minAllowEqual && value < min) reject(message || `${fieldName}必须大于等于${min}`);
      else if (!minAllowEqual && value <= min)
        reject(message ? message : `${fieldName}必须大于${min}`);

      return resolve();
    });
  };
};

/**
 * 前后不能出现 /
 * @param rule
 * @param value
 * @returns
 */
const checkTwoSidesBackslash = (rule: any, value: string) => {
  if (_.startsWith(value, '/') || _.endsWith(value, '/')) {
    return Promise.reject(new Error('首尾不能为/'));
  }
  return Promise.resolve();
};

/**
 * @description: 密码规则
 * @param rule
 * @param value
 */
const passwordCheck = (emptyMessage?: string) => (rule: any, value: string) => {
  if (!value) {
    return Promise.reject(new Error(emptyMessage || '请输入密码'));
  }
  // if (/[\u4e00-\u9fa5\s]/.test(value)) return Promise.reject(new Error('密码不支持中文或空格'));

  return /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、Ω≈ç√˜µ≤≥÷…˚æ“‘«”「」’]+$)(?![a-z0-9]+$)(?![a-z`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、Ω≈ç√˜µ≤≥÷…˚æ“‘«”「」’]+$)(?![0-9`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、Ω≈ç√˜µ≤≥÷…˚æ“‘«”「」’]+$)[a-zA-Z0-9`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、Ω≈ç√˜µ≤≥÷…˚æ“‘«”「」’]{8,}$/.test(
    value,
  )
    ? Promise.resolve()
    : Promise.reject(new Error('密码不合规'));
};

// 校验 必须和其他表单项的值一致
const validateRepeatWith = (
  field: { namePath: string | Array<string | number>; label: string; message?: string },
  form: FormInstance,
) => {
  return (_rule: RuleObject, value: any) => {
    const formValue = form.getFieldValue(field.namePath);
    return formValue === value
      ? Promise.resolve()
      : Promise.reject(new Error(field?.message || `必须和${field.label}输入一致`));
  };
};

/**
 * @description: 小数位数不能超过最大值
 * @param  {number} value
 * @param {number} maxDigtis
 */
const maxDigtisRules = (value: number, maxDigtis: number): boolean => {
  const decimalPart = value && value.toString().split('.')[1];

  if (decimalPart && decimalPart.length > maxDigtis) {
    return false;
  }
  return true;
};

/**
 * @description: 数值不能超过上限，数值小数位数不能超过上限
 * @param {number} maxDigtis: 最大位数
 * @param {number} max: 最大值
 */
const validatorNumberDigtis = (maxDigtis: number, max = Infinity, name: string = '') => {
  return (_rule: RuleObject, value: any) => {
    return new Promise<void>((resolve, reject) => {
      if (!value) return resolve();
      if (value > max) reject(`${name}数值不能超过${max}`);
      if (!maxDigtisRules(value, maxDigtis)) reject(`${name}精度不能超过${maxDigtis}位`);
      return resolve();
    });
  };
};

/**
 * @description: 自然数校验
 * @param {*}
 * @return {*}
 */
const naturalNumberCheck = () => {
  return (_rule: RuleObject, value: string, _callback: (error?: string) => void) => {
    const { pattern: reg, message } = naturalNumber;

    return new Promise<void>((resolve, reject) => {
      if (value && !reg.test(value)) {
        return reject(new Error(message));
      }
      return resolve();
    });
  };
};

/**
 * 不支持中文
 */
const validateChineseNotSupported = () => {
  return (_rule: RuleObject, value: string) => {
    const { pattern: reg, message } = checkChineseNotSupported;
    if (reg.test(value)) {
      return Promise.reject(new Error(message));
    }
    return Promise.resolve();
  };
};
/**
 * @description: 判断整数倍
 * @param {number} baseNum: 基础数
 * @param {number} compareNum: 比较数
 * @param {string} message: 相应的提示信息，非必填
 */
const intMultipleCheck = (config: { baseNum: number; compareNum: number; message?: string }) => {
  const { baseNum, compareNum, message = '' } = config;
  return (_rule: RuleObject, value: string) => {
    if (baseNum && compareNum % baseNum !== 0) {
      return Promise.reject(new Error(message || `请输入${baseNum}整数倍的值`));
    }
    return Promise.resolve();
  };
};

/**
 *  @description: 字符长度不超过max
 *  @param {number} max: 最大长度数
 * @return {*}
 */
const validatorCharacterLen = (max: number, name: string = '') => {
  return (_rule: RuleObject, value: any) => {
    if (value && value.length > max) {
      return Promise.reject(`${name}不超过${max}个字符`);
    }
    return Promise.resolve();
  };
};

/**
 *  @description: 时间不可早于当前时间
 * @return {*}
 */
const validatorAfterNow = () => {
  return (_rule: RuleObject, value: any) => {
    if (value < Date.now().valueOf()) {
      return Promise.reject('选择的时间不可早于当前时间');
    }
    return Promise.resolve();
  };
};

/** 不能以数字开头 */
export const noDigitStart = (__: unknown, value: string) => {
  if (/^\d+/.test(value)) {
    return Promise.reject(new Error('不能以数字开头'));
  }
  return Promise.resolve();
};

/**
 * @description: 非负数校验
 */
export const negativeNumberCheck = () => {
  return (_rule: RuleObject, value: string) => {
    const regString = /^\d{1,}(\.\d{1,})?$/;
    const reg = new RegExp(regString);

    return new Promise<void>((resolve, reject) => {
      if (value && !reg.test(value)) {
        reject('请输入大于或等于0的数');
      }
      resolve();
    });
  };
};

export {
  textValidator1,
  textValidator2,
  textValidator3,
  checkFormWithLetterDigitalUnderscore,
  checkTwoSidesTrim,
  whiteSpaceInMiddleValidator,
  telValidator,
  validateFormListRepeat,
  validateNotRepeatWith,
  validateBlInputText,
  fractionLengthCheck,
  integerLengthCheck,
  numberMinMaxCheck,
  checkTwoSidesBackslash,
  passwordCheck,
  validateRepeatWith,
  maxDigtisRules,
  validatorNumberDigtis,
  naturalNumberCheck,
  validateChineseNotSupported,
  intMultipleCheck,
  validatorCharacterLen,
  numberLengthCheck,
  validatorAfterNow,
};
