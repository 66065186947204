import { createContext } from 'react';
import { Moment } from 'moment';
import {
  FetchWorkerCalendarGetResponse,
  FetchShiftListResponse,
  FetchShiftRuleListResponse,
  FetchWorkerCalendarPreViewResponse,
  FetchWorkerCalendarListResponse,
} from 'src/api/ytt/calendar-domain';
import { FitRangeType } from 'src/dict/workCalendar';
import { ListItemType } from 'src/types/apiTypes';

// 用户信息类型
interface UserColumnProps {
  id: number;
  name: string;
  avatarUrl: string;
}
// 工作日历列表项类型
type WorkCalendarListItemProps = ListItemType<FetchWorkerCalendarListResponse>;
interface WorkCalendarParamItemProps {
  id: number;
  startTime?: number;
  endTime?: number;
}

// 工作日历类型
enum CalendarType {
  Fix = 1, // 固定班制
  Flex = 2, // 排班制
}

// 工作日历名称
const calendarTypeDisplayMap = {
  [CalendarType.Fix]: '固定班制',
  [CalendarType.Flex]: '排班制',
};

type OperateModeHideType = 'calendar.operateDialog.hide';
type OperateModeCreateType = 'calendar.operateDialog.create';
type OperateModeEditType = 'calendar.operateDialog.edit';
type OperateModeCopyType = 'calendar.operateDialog.copy';
type OperateModeType =
  | OperateModeHideType
  | OperateModeCreateType
  | OperateModeEditType
  | OperateModeCopyType;

interface OperateDialogModeTypes {
  HIDE: OperateModeHideType;
  CREATE: OperateModeCreateType;
  EDIT: OperateModeEditType;
  COPY: OperateModeCopyType;
}

// 工作日历新建/编辑/复制窗口显示模式
const OPERATE_DIALOG_MODE: OperateDialogModeTypes = {
  HIDE: 'calendar.operateDialog.hide',
  CREATE: 'calendar.operateDialog.create',
  EDIT: 'calendar.operateDialog.edit',
  COPY: 'calendar.operateDialog.copy',
};

// 工作日历日期/时间格式化字符串
const DISPLAY_DATE_FORMAT = 'YYYY-MM-DD';
const DISPLAY_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

type CalendarDataType = FetchWorkerCalendarGetResponse['data'];

// 适用范围项的数据格式
interface FitRangeDataItemProps {
  businessId?: number; // 适用范围id
  businessName?: string; // 适用范围名称
  businessType: FitRangeType; // 适用范围种类
}

// 适用范围组件的数据格式，按种类分别组织，每个种类是一个符合FitRangeDataItemProps的数据的数组
// interface FitRangeDataProps {
//   [FitRangeType.users]: FitRangeDataItemProps[];
//   [FitRangeType.departments]: FitRangeDataItemProps[];
//   [FitRangeType.resources]: FitRangeDataItemProps[];
// }

type FitRangeDataProps = Partial<Record<FitRangeType, FitRangeDataItemProps[]>>;

// 从数组中获取单个元素的类型
type GetElementType<T extends any[]> = T extends (infer U)[] ? U : never;

// 特殊日数据格式
// 从日历预览进行创建时，填入开始日期和结束日期，班次信息为空
// 从日历预览进行编辑时，填入班次信息，开始日期和结束日期为空（日历只能取到当天，不知道时间）
export interface SpecialDayDataRecordProps {
  name: string;
  startTime?: number;
  endTime?: number;
  shiftName?: string;
  shiftId?: number;
  specialDayId?: number;
  specialDayIndex?: number;
}

// 工作日历下的useReducer
enum SPECIALDAY_REDUCER_TYPE {
  'ShowDialog',
  'HideDialog',
  'RefreshCalendar',
}
interface SpecialDayStateProps {
  showDialog: boolean;
  specialDay: SpecialDayDataRecordProps | undefined;
  refreshCalendar: number;
  displayMoment?: Moment;
}

type SpecialDayReducerType = (
  prevState: SpecialDayStateProps,
  action:
    | { type: SPECIALDAY_REDUCER_TYPE.ShowDialog; payload?: SpecialDayDataRecordProps }
    | { type: SPECIALDAY_REDUCER_TYPE.RefreshCalendar; payload?: Moment }
    | { type: SPECIALDAY_REDUCER_TYPE.HideDialog },
) => SpecialDayStateProps;

const initSpecialDayState: SpecialDayStateProps = {
  showDialog: false,
  specialDay: undefined,
  refreshCalendar: 0,
};

// 为特殊日的跨组件通信而使用的context
export interface SpecialDayContextType {
  specialDayState: SpecialDayStateProps;
  showSpecialDayDialog?: (specialDay?: SpecialDayDataRecordProps) => void;
  hideSpecialDayDialog?: () => void;
  refreshCalendar?: (displayMoment?: Moment) => void;
}

export const SpecialDayContext = createContext<SpecialDayContextType>({
  specialDayState: initSpecialDayState,
});

type ShiftsListProps = NonNullable<FetchShiftListResponse['data']>['list'];
type ShiftRulesListProps = NonNullable<FetchShiftRuleListResponse['data']>['list'];
type ShiftItemType = GetElementType<ShiftsListProps>;
type ShiftRuleItemType = GetElementType<ShiftRulesListProps>;
type ScheduleShiftValueType = (ShiftItemType | ShiftRuleItemType) & {
  isShift: boolean;
};
interface ShiftContextType {
  shifts: ShiftsListProps;
  shiftRules: ShiftRulesListProps;
}
export const ShiftContext = createContext<ShiftContextType>({
  shifts: [],
  shiftRules: [],
});

type CalendarDayDataProps = GetElementType<NonNullable<FetchWorkerCalendarPreViewResponse['data']>>;

// const checkNever = (paramToBeChecked: never) => {
//   // Nothing to do
// };

/**
 * 从response中取出排班信息的类型
 * 加入showPopover表示是否显示排班弹窗
 */
type ScheduleTableDateInfoType = CalendarDayDataProps & {
  showPopover: boolean;
};

export {
  CalendarType,
  calendarTypeDisplayMap,
  OPERATE_DIALOG_MODE,
  DISPLAY_DATE_FORMAT,
  DISPLAY_TIME_FORMAT,
  initSpecialDayState,
  SPECIALDAY_REDUCER_TYPE,
  // checkNever,
};

export type {
  OperateModeType,
  CalendarDataType,
  FitRangeDataProps,
  FitRangeDataItemProps,
  GetElementType,
  SpecialDayReducerType,
  SpecialDayStateProps,
  ShiftsListProps,
  ShiftRulesListProps,
  UserColumnProps,
  WorkCalendarListItemProps,
  WorkCalendarParamItemProps,
  ShiftItemType,
  ScheduleShiftValueType,
  CalendarDayDataProps,
  ScheduleTableDateInfoType,
};
