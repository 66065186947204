import React, { useEffect, useState } from 'react';
import { Checkbox, Form, FormInstance, Input, FormItemProps } from 'antd';
import { fetchCustomFieldDetailByCode } from 'src/api/ytt/metadata-domain/customField';
import styles from './styles.module.scss';
import { Rule } from 'antd/lib/form';
import { NamePath } from 'antd/es/form/interface';

interface Props extends FormItemProps {
  form: FormInstance;
  edit: boolean; // 是否是编辑状态
  objectCode: string; // 对象code
  fieldCode: string; // 对象下的字段
  disabledState?: boolean; // 编辑状态是否可编辑,默认可编辑状态
  rules: Rule[];
  namePath?: NamePath; // 针对多层路径下的嵌套字段的code编号 为[]就是第一层
  checkboxLayout?: 'horizontal' | 'vertical'; // checkbox 方向
  isFullLine?: boolean;
  span?: number;
  refreshMarker?: number; // 刷新
  onChange?: (e: any) => void; // code输入框的onChange
}

const defaultNamePath: (string | number)[] = [];

export default function NumberRulesStandardHook({
  label,
  form,
  edit,
  fieldCode,
  objectCode,
  rules,
  namePath = defaultNamePath,
  disabledState = false,
  checkboxLayout = 'horizontal',
  isFullLine,
  span,
  onChange,
  refreshMarker = 0,
  ...restProps
}: Props) {
  const [numberCodeId, setNumberCodeId] = useState<number>(0); // 未启用/未引用都是单文本. 唯一判断条件，是否有编号规则id
  const [isNumberRuleConfig, setIsNumberRuleConfig] = useState<number>(0); // 是否配置编号规则
  const [checkState, setCheckState] = useState<boolean>(false); // 是否勾选复选框
  const _namePath =
    typeof namePath === 'string' || typeof namePath === 'number' ? [namePath] : namePath;

  const ruleIdPath = [..._namePath, 'numberRuleId'];
  const fieldCodePath = [..._namePath, fieldCode];

  const fetchData = async () => {
    try {
      const { data, code } = await fetchCustomFieldDetailByCode({ fieldCode, objectCode });

      if (code === 200) {
        if (data?.isNumberRuleConfig && data?.numberRuleId) {
          form?.setFields([
            { name: ruleIdPath, value: data?.numberRuleId },
            { name: fieldCodePath, value: '' },
          ]);
          setNumberCodeId(data?.numberRuleId);
          setIsNumberRuleConfig(data?.isNumberRuleConfig ?? 0);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // FIXME：limeirong 如果namePath传['XXX'] 每次触发onchange事件都会走↓↓↓进行初始化 导致checkBox的onChange失败
  useEffect(() => {
    if (!edit) {
      const code = form.getFieldValue(fieldCodePath);

      setCheckState(!code);
      // 有code, 置空ruleId
      if (code) {
        form.setFields([{ name: ruleIdPath, value: '' }]);
      } else if (!form.getFieldValue(ruleIdPath)) {
        // 无code且无ruleId, 拉取ruleId
        fetchData();
      }
    }
  }, [edit, namePath, fieldCode]);

  useEffect(() => {
    if (!edit && refreshMarker) {
      // 清空fieldCodePath字段  重新获取是否有编号规则id，赋值
      form.setFields([
        { name: fieldCodePath, value: '' },
        { name: ruleIdPath, value: '' },
      ]);
      setCheckState(true);
      fetchData();
    }
  }, [edit, refreshMarker]);

  return [
    {
      label,
      isFullLine,
      span,
      name: fieldCode,
      required: numberCodeId > 0 ? Boolean(!checkState) : true,
      render: () => {
        if (!edit && isNumberRuleConfig && numberCodeId > 0) {
          // 创建状态下 配置且引用编号规则
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: checkboxLayout === 'horizontal' ? 'row' : 'column',
              }}
            >
              <Form.Item name={ruleIdPath} hidden>
                <Input />
              </Form.Item>
              <Form.Item
                className={styles?.numberRulItem}
                style={{ flex: 1 }}
                validateFirst
                name={fieldCodePath}
                rules={[
                  {
                    required: !checkState,
                    message: `${label}不能为空`,
                  },
                  ...rules,
                ]}
                {...restProps}
              >
                <Input
                  placeholder={checkState ? '保存后按规则生成' : '请输入'}
                  disabled={checkState}
                  onChange={onChange}
                />
              </Form.Item>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: checkboxLayout === 'vertical' ? 'flex-start' : 'flex-end',
                  marginTop: checkboxLayout === 'vertical' ? 22 : 0,
                  width: 100,
                }}
              >
                <Checkbox
                  checked={checkState}
                  onChange={(e: any) => {
                    setCheckState(e?.target?.checked);
                    if (e?.target?.checked) {
                      // 选中状态
                      form?.setFields([
                        { name: ruleIdPath, value: numberCodeId },
                        { name: fieldCodePath, value: '' },
                      ]);
                    } else {
                      form?.setFields([{ name: ruleIdPath, value: '' }]);
                    }
                  }}
                >
                  编号规则
                </Checkbox>
              </div>
            </div>
          );
        }
        return (
          <Form.Item
            className={styles?.numberRulItem}
            name={[..._namePath, fieldCode]}
            validateFirst
            rules={[
              {
                required: true,
                message: `${label}不能为空`,
              },
              ...rules,
            ]}
            {...restProps}
          >
            <Input placeholder="请输入" disabled={edit && disabledState} />
          </Form.Item>
        );
      },
    },
  ];
}
