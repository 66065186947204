import { FormInstance } from 'antd';
import AddmaterialUnitManagement from '../../addmaterialUnitManagement';
import SearchSelect from 'src/components/searchSelect';
import { OBJECT_OF_ID } from 'src/entity/objectPlatform';
import { YN } from 'src/dict/common';
import { ICustomFields } from 'src/components/customField';

interface Props {
  form: FormInstance;
  customFields?: ICustomFields;
}

export const warehousingManagementInfo = (props: Props) => {
  const { form, customFields } = props;

  return [
    {
      label: '库存属性',
      isFullLine: true,
      render: () => <AddmaterialUnitManagement customFields={customFields} form={form} />,
    },
    {
      label: '库存标识码规则',
      name: 'inventoryQrCodeRule',
      render: () => (
        <SearchSelect
          labelInValue
          fetchType={'numberingRules'}
          placeholder={'请选择'}
          params={{ suitObjId: OBJECT_OF_ID.inventoryElement, enableFlag: YN.yes }}
        />
      ),
    },
  ];
};
