import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { DetailLayoutForDrawer, RecordListLayout } from 'layout';
import { Badge, message as Message } from 'antd';
import {
  fetchDigitalSignatureBulkActivate,
  FetchDigitalSignatureBulkActivateResponse,
  fetchDigitalSignatureBulkDeactivate,
  FetchDigitalSignatureBulkDeactivateResponse,
  fetchDigitalSignaturePageList,
} from 'src/api/ytt/user-domain/eSignature';
import { fieldTypeList } from 'src/utils';
import ESignatureDetail from './detail';
import { ESMTableRowType } from './type';
import { activeStatusStrEnum } from 'src/dict/eS-M/mappings';
import { BlSelect } from 'src/components/select';
import { ACTIVE_STATUS_STR } from 'src/dict/eS-M';
import lookup from 'src/dict';
import { getFilterList } from './utils';
import { BlIcon, LinkTooltip, SearchSelect, Tooltip } from 'src/components';
import authDict from 'src/utils/auth';
import { LabeledValue } from 'antd/lib/select';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { replaceSign } from 'src/utils/constants';

const ESignature: React.FC = () => {
  const [objectCode, setObjectCode] = useState('');
  const [refreshMarker, setRefreshMarker] = useState(new Date().getTime());
  const [detailVisible, setDetailVisible] = useState(false);
  const [signatureVisible, setSignatureVisible] = useState(false);
  const [currentId, setCurrentId] = useState<number>();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const location = useLocation();
  const history = useHistory();

  /** 刷新列表 */
  const refreshList = () => {
    setRefreshMarker(new Date().getTime());
  };

  /**
   * 批量启用
   * @ids 业务id List
   */
  const bulkActivate = async (ids: number[]) => {
    const res: FetchDigitalSignatureBulkActivateResponse = await fetchDigitalSignatureBulkActivate({
      ids,
    });

    const { code, message } = res;

    if (code !== 200) {
      Message.error(message);
      return;
    }
    Message.success(message);
    refreshList();
  };

  /**
   * 批量停用
   * @ids 业务id List
   */
  const bulkDeactivate = async (ids: number[]) => {
    const res: FetchDigitalSignatureBulkDeactivateResponse =
      await fetchDigitalSignatureBulkDeactivate({
        ids,
      });

    const { code, message } = res;

    if (code !== 200) {
      Message.error(message);
      return;
    }
    Message.success(message);
    refreshList();
  };

  /**
   * 编辑
   * @params id 电子签名ID
   */
  const editESignature = (id: number) => {
    setCurrentId(id);
    setIsEdit(true);
    setSignatureVisible(true);
  };

  const dataColumns = [
    {
      title: '电子签名名称',
      dataIndex: 'name',
      width: 200,
      isFilter: true,
      sorter: true,
      type: fieldTypeList.text,
      render: (text: string, record: ESMTableRowType, index: number, config: any) => (
        <LinkTooltip
          auth={authDict.electronicsignature_detail}
          text={text ?? replaceSign}
          to={`/systemManagement/eS-M/${record?.id}/detail`}
          width={config.contentWidth}
        />
      ),
    },
    {
      title: '所属对象',
      dataIndex: 'relatedObjectName',
      width: 200,
      isFilter: true,
      type: fieldTypeList.select,
      renderItem: (
        <SearchSelect
          fetchType={'bizObject4Signature'}
          placeholder={'请选择所属对象'}
          onChange={(obj: LabeledValue) => setObjectCode(obj?.value as string)}
        />
      ),
    },
    {
      title: '功能点',
      dataIndex: 'functionName',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      renderItem: (
        <SearchSelect
          disabled={!objectCode}
          params={{ objectCode }}
          fetchType={'signatureFunction'}
          placeholder={'请选择功能点'}
        />
      ),
    },
    {
      title: '启用状态',
      dataIndex: 'activeStatus',
      width: 120,
      isFilter: true,
      type: fieldTypeList.select,
      renderItem: <BlSelect placeholder={'请选择启用状态'} selectList={activeStatusStrEnum} />,
      render: (activeStatus: number) => {
        return (
          <Badge
            status={activeStatus === ACTIVE_STATUS_STR.enabled ? 'success' : 'error'}
            text={lookup('esm.activeStatusStrEnum', activeStatus)}
          />
        );
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 200,
      isFilter: true,
      type: fieldTypeList.text,
    },
  ];

  const getOperationList = (record: ESMTableRowType) => {
    const { id, activeStatus } = record;

    return _.compact([
      {
        title: '查看',
        auth: authDict.electronicsignature_detail,
        onClick: () => {
          // setCurrentId(id);
          // setDetailVisible(true);
          history.push(`/systemManagement/eS-M/${id}/detail`);
        },
      },
      activeStatus !== ACTIVE_STATUS_STR.deleted && {
        title: '编辑',
        auth: authDict.electronicsignature_edit,
        onClick: () => {
          history.push(`/systemManagement/eS-M/edit/${id}`);
        },
      },
      {
        title: activeStatus === ACTIVE_STATUS_STR.enabled ? '停用' : '启用',
        auth: authDict.electronicsignature_enable_disable,
        onClick: () => {
          activeStatus === ACTIVE_STATUS_STR.enabled ? bulkDeactivate([id]) : bulkActivate([id]);
        },
      },
    ]);
  };

  const formatDataToQuery = (params: any) => {
    const { quickSearch, name, functionName, activeStatus, relatedObjectName, ...rest } = params;
    const resParams = {
      name: name || quickSearch,
      functionCode: functionName?.value,
      relatedObjectCode: relatedObjectName?.value,
      activeStatus: activeStatus?.value,
      ...rest,
    };

    return resParams;
  };

  useEffect(() => {
    try {
      const filterData = qs.parse(location?.search?.slice(1))?.filterData;

      if (filterData) {
        const objectCode = JSON.parse(filterData as string)?.relatedObjectName?.value;

        setObjectCode(objectCode || '');
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <RecordListLayout<ESMTableRowType>
        columns={dataColumns}
        placeholder={'输入电子签名名称'}
        refreshMarker={refreshMarker}
        filterList={getFilterList(dataColumns)}
        rowKey="id"
        formatDataToQuery={formatDataToQuery}
        configcacheKey="eSignature"
        batchMenu={[
          {
            title: '批量启用',
            auth: authDict.electronicsignature_enable_disable,
            onClick: () => {
              return bulkActivate(selectedRowKeys);
            },
          },
          {
            title: '批量停用',
            auth: authDict.electronicsignature_enable_disable,
            onClick: () => {
              return bulkDeactivate(selectedRowKeys);
            },
          },
        ]}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey: React.Key[]) => {
          setSelectedRowKeys(selectKey as number[]);
        }}
        mainMenu={[
          {
            title: '新建电子签名',
            icon: <BlIcon type="iconxinjiantianjia" />,
            auth: authDict.electronicsignature_add,
            onClick: () => {
              history.push('/systemManagement/eS-M/create');
            },
            items: [],
          },
        ]}
        requestFn={fetchDigitalSignaturePageList}
        getOperationList={getOperationList}
      />
      {/* 新增/编辑 */}
      {/* {signatureVisible && (
        <SignatureAction
          visible={signatureVisible}
          refetch={refreshList}
          onClose={() => setSignatureVisible(false)}
          isEdit={isEdit}
          id={currentId}
        />
      )} */}
      {/* 查看详情 */}
      {detailVisible && currentId && (
        <DetailLayoutForDrawer
          visible={detailVisible}
          width={680}
          content={
            <ESignatureDetail
              id={currentId}
              editESignature={editESignature}
              onActive={bulkActivate}
              onDeActive={bulkDeactivate}
            />
          }
          onClose={() => setDetailVisible(false)}
        />
      )}
    </>
  );
};

export default ESignature;
