import { useState, useRef, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FilterItem, RecordListLayout } from 'layout';
import { BlIcon, LinkTooltip, SearchSelect, SingleOperationLogComponent } from 'components';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import moment from 'moment';
import _ from 'lodash';
import { gcTime, gcObject, fieldTypeList } from 'utils';
import { BOUND_PATH as basePath } from '../index';
import lookup, { appEnum, appDict } from 'src/dict';
import { patchRequest, getIdVersionList } from '../utils';
import {
  fetchInboundOrderList,
  fetchInboundOrderIssue,
  fetchInboundOrderClose,
} from 'src/api/ytt/inventory-domain/boundOrder';
import { InboundTableRowType as TableRowType } from '../type';
import { renderUser } from 'src/page/share/renderUser';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { useDispatch } from 'react-redux';
import authDict from 'src/utils/auth';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { injectCustomFieldColumns, useCustomFieldCombinedData } from 'src/components/customField';

interface Props extends RouteComponentProps {}

const { StatusEnum } = appEnum.Bound;
const InBoundOrder = (props: Props) => {
  const { history } = props;
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const [selectedRow, setSelectRow] = useState<any>([]);
  const refreshCountRef = useRef(0);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [detailId, setDetailId] = useState<number>(0);
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.inboundOrder);
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const refreshTable = () => {
    // 刷新列表页
    refreshCountRef.current++;
    setRefreshMarker(refreshCountRef.current);
  };

  const requestFn = async (params: any) => {
    const res = await fetchInboundOrderList(params);

    return res;
  };

  const toDetail = (id?: number) => {
    if (!id) return;
    history.push(`${basePath}/inBound/${id}/detail`);
  };
  const toCreate = () => {
    history.push(`${basePath}/inBound/create`);
  };
  const toEdit = (id?: number) => {
    history.push(`${basePath}/inBound/${id}/edit`);
  };
  const dataColumns: any[] = [
    {
      title: '入库单编号',
      dataIndex: 'code',
      sorter: true,
      isFilter: true,
      width: 150,
      render: (text: string, record: any, index: number, config: any) => (
        <LinkTooltip
          to={`${basePath}/inBound/${record?.id}/detail`}
          text={text}
          width={config.contentWidth}
          auth={authDict.inboundorder_detail}
        />
      ),
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '状态',
      dataIndex: 'bizStatus',
      type: fieldTypeList.select,
      isFilter: true,
      sorter: true,
      width: 150,
      render: lookup('bound.Status'),
      selectProps: {
        placeholder: '请选择',
        mode: 'multiple',
        options: appDict.bound.Status,
      },
    },
    {
      title: '仓库',
      dataIndex: 'wareHouse',
      isFilter: true,
      width: 150,
      render: (text: { name: string }) => text?.name,
      inputProps: {
        placeholder: '请输入关键字',
      },
      renderItem: <SearchSelect labelInValue fetchType="warehouse" />,
    },
    {
      title: '业务类型',
      dataIndex: 'bizType',
      width: 150,
      type: fieldTypeList.select,
      isFilter: true,
      render: lookup('bound.InBoundType'),
      selectProps: {
        placeholder: '请选择',
        mode: 'multiple',
        options: appDict.bound.InBoundType,
      },
    },
    {
      title: '供应商',
      dataIndex: 'supplier',
      width: 150,
      isFilter: true,
      sorter: true,
      render: (item: { name: string }) => item?.name,
      renderItem: <SearchSelect mode="multiple" labelInValue fetchType="supplier" />,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 150,
      isFilter: true,
      render: renderUser,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择创建人" isMultiple isSelectUser />
      ),
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      width: 180,
      render: (text: number) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  const config = {
    rowKey: 'id',
    // columns: dataColumns,
    columns: injectCustomFieldColumns({
      columns: dataColumns, // 原本的列
      customFields, // 自定义字段信息
      objectCode: OBJECT_OF_CODE.inboundOrder, // 从对象code
      type: 'detail', // 使用类型
      inTable: true,
      columnProps: {
        to: (record: any) => `${basePath}/inBound/${record?.id}/detail`,
        auth: authDict.inboundorder_detail,
        width: 150,
      },
    }),
    // 操作列
    getOperationList: (record: TableRowType) => {
      const { bizStatus } = record;

      return [
        {
          title: '查看',
          auth: authDict.inboundorder_detail,
          onClick: () => toDetail(record.id),
        },
        {
          title: '编辑',
          auth: authDict.inboundorder_edit,
          disabled: bizStatus === StatusEnum.yiguanbi,
          onClick: () => toEdit(record.id),
        },
        {
          title: '下发',
          auth: authDict.inboundorder_dispatch,
          disabled: bizStatus !== StatusEnum.xinjian,
          onClick: () => {
            if (!record?.id) {
              return;
            }
            patchRequest(fetchInboundOrderIssue, getIdVersionList([record]), '下发', refreshTable);
          },
        },
        {
          title: '关闭',
          auth: authDict.inboundorder_close,
          disabled: bizStatus === StatusEnum.yiguanbi,
          onClick: () => {
            if (!record?.id) {
              return;
            }
            patchRequest(fetchInboundOrderClose, getIdVersionList([record]), '关闭', refreshTable);
          },
        },
        {
          title: '操作记录',
          auth: authDict.inboundorder_operrecord,
          onClick: () => {
            if (!record.id) return;
            setDetailId(record.id);
            setVisibleLog(true);
          },
        },
      ];
    },
    // 批量操作
    batchMenu: [
      {
        title: '下发',
        auth: authDict.inboundorder_dispatch,
        onClick: (onSuccess: any, onFail: any) => {
          return patchRequest(
            fetchInboundOrderIssue,
            getIdVersionList(selectedRow),
            '下发',
            onSuccess,
            onFail,
          );
        },
      },
      {
        title: '关闭',
        auth: authDict.inboundorder_close,
        onClick: (onSuccess: any, onFail: any) => {
          return patchRequest(
            fetchInboundOrderClose,
            getIdVersionList(selectedRow),
            '关闭',
            onSuccess,
            onFail,
          );
        },
      },
    ],
    // 右上角操作
    mainMenu: [
      {
        title: '导出',
        icon: <BlIcon type="icondaochu" />,
        showExport: true,
        auth: authDict.inboundorder_export,
        onClick: () =>
          openModal({
            optType: 'export',
            businessType: BUSINESS_TYPE.inboundOrder,
          }),
      },
      {
        title: '新建入库单',
        icon: <BlIcon type="iconxinjiantianjia" />,
        auth: authDict.inboundorder_add,
        showExport: true,
        onClick: toCreate,
        items: [
          {
            title: '导入',
            showExport: true,
            auth: authDict.inboundorder_import,
            onClick: () =>
              openModal({
                optType: 'import',
                businessType: BUSINESS_TYPE.inboundOrder,
              }),
          },
        ],
      },
    ],
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((columns: any): FilterItem => {
        const filter = {
          label: columns.title,
          name: columns.dataIndex,
          ...columns,
        };

        return filter;
      }),
    formatDataToFormDisplay: (filterData: any) => {
      const { createdAt, ...rest } = filterData;

      const result = {
        ...rest,
        createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
      };

      return gcObject.filterEmptyProperty(result);
    },
    formatDataToQuery: (param: any) => {
      const {
        quickSearch,
        createdAt,
        code,
        creator,
        wareHouse,
        updatedAt,
        departmentId,
        supplier,
        ...rest
      } = param;
      const [createdAtFrom, createdAtTill] = gcTime.formatTimeRangeUnix(createdAt);

      const options = {
        departmentId: departmentId?.[0]?.value,
        quickSearch: code || quickSearch,
        createdAtFrom,
        createdAtTill,
        warehouseId: wareHouse?.value,
        supplierIds: _.map(supplier, 'value'),
        creator: creator?.length ? _.map(creator, 'value') : null,
        ...rest,
      };

      const relParams = gcObject.filterEmptyProperty(options);

      dispatch.excelImport.updateBusinessData({ businessData: relParams });
      return relParams;
    },
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        placeholder={'请输入编号关键字'}
        useIndex={false}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey, row) => {
          setSelectRowKeys(selectKey);
          setSelectRow(row);
        }}
        refreshMarker={refreshMarker}
        requestFn={requestFn}
        configcacheKey="inBoundModule"
        {...config}
      />
      {/* {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={'InboundOrder'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )} */}
    </>
  );
};

export default InBoundOrder;
