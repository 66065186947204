import { BlTable } from '@blacklake-web/component';
import { BomResponseInputMaterial } from 'src/page/knowledgeManagement/engineerData/bom/index.type';
import React from 'react';
import { TagList } from 'components';

interface Props {
  bomDataSource: any;
}

// 父项物料编号、版本号、物料名称、物料属性、物料分类、物料规格
const SuitedBomDetailTable = (props: Props) => {
  const { bomDataSource } = props;

  const getColumns = () => {
    return [
      {
        title: '父项物料编号',
        dataIndex: 'code',
        width: 150,
      },
      {
        title: '版本号',
        dataIndex: 'version',
        width: 100,
      },
      {
        title: '物料名称',
        dataIndex: 'name',
        width: 150,
      },
      {
        title: '物料属性',
        dataIndex: 'attributes',
        width: 150,
        render: (text: any, record: any) => <TagList dataSource={text} />,
      },
      {
        title: '物料分类',
        dataIndex: 'category',
        width: 150,
      },
      {
        title: '物料规格',
        dataIndex: 'specification',
        width: 150,
      },
    ];
  };

  return (
    <BlTable<BomResponseInputMaterial>
      dataSource={bomDataSource}
      columns={getColumns()}
      rowKey="code"
      style={{ paddingLeft: 24 }}
    />
  );
};

export default SuitedBomDetailTable;
