/**
 * 正则校验方法
 */

import _ from 'lodash';

type RegularType = {
  pattern: RegExp;
  message: string;
};

/**
 * 使用正则表达式校验字段
 * @param regKeys 需要符合的正则表达式key或key的数组，key在reg.ts中配置
 * @param name 标准错误信息的主语
 * @param customizedMessage 自定义错误信息
 */
export const patterRulesWithMessage = (
  regs: RegularType | RegularType[],
  name: string,
  customizedMessage?: string,
) => {
  const formatMessage = (reg: RegularType) => {
    return {
      ...regs,
      message: customizedMessage ?? `${name}${reg.message}`,
    };
  };

  if (_.isObject(regs) && !Array.isArray(regs)) {
    return formatMessage(regs);
  }
  return regs.map((reg) => formatMessage);
};

/** 数字、字母、特殊字符（包含空格） */
export const numberAlphabetSpecialSymbols = {
  pattern:
    /^[a-zA-Z0-9`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥¥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、Ω≈ç√˜µ≤≥÷…˚æ“‘«”「」℃’\s]*$/,
  message: '必须是字母、数字、特殊字符',
};

/** 中文、数字、字母、特殊字符（包含空格） */
export const numberChineseAlphabetSpecialSymbols = {
  pattern:
    /^[a-zA-Z0-9\u4e00-\u9fa5`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥¥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、Ω≈ç√˜µ≤≥÷…˚æ“‘«”「」℃’\s]*$/,
  message: '必须是字母、数字、中文、特殊字符',
};

/** 字母、数字、下划线 */
export const numberAlphabetUnderline = {
  pattern: /^[a-zA-Z\d_]*$/,
  message: '必须是字母、数字、下划线',
};

/** 前后没有空格 */
export const withoutSpaceBothSides = { pattern: /^[\S].*[\S]$|(^\S?$)/, message: '前后不支持空格' };

/** 数字、英文加号、英文减号 */
export const numberPlusMinus = {
  pattern: /^[\d-+]*$/,
  message: '仅支持数字，英文连字符和英文加号',
};

/** 字符中间最多出现三个空 */
export const atMostThreeSpaces = {
  pattern: /^\S+\s{0,3}\S+$/,
  message: '字符中间最多出现三个空格',
};

/** 电话号码/手机号 */
export const telOrPhone = {
  pattern: /^(([0+]\d{2,3}-)?(0\d{2,3})-)?(\d{7,8})(-(\d{3,}))?$|(^1\d{10}$)/,
  message: '请输入正确格式的电话号码/手机号',
};

/** 正整数 */
export const positiveInt = {
  pattern: /^[1-9]\d*$/,
  message: '请输入正整数',
};

/** 数字 */
export const numberCheck = {
  pattern: /^(-?\d+)(\.\d+)?$/,
  message: '请输入数字',
};

/** 自然数 */
export const naturalNumber = {
  pattern: /^[0-9]\d*$/,
  message: '请输入自然数',
};

/** 密码:必须包含大写字母、小写字母、数字、字符(_-$%&@+!)不低于三种的组合，长度大于8 */
export const password = {
  pattern:
    /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z_\-$%&@+!]+$)(?![a-z0-9]+$)(?![a-z_\-$%&@+!]+$)(?![0-9_\-$%&@+!]+$)[a-zA-Z0-9_\-$%&@+!]{8,}$/,
  message: '必须包含大写字母、小写字母、数字、字符(_-$%&@+!)不低于三种的组合，长度大于8',
};

/** 数字型字符串 */
export const numberStringCheck = {
  pattern: /^[0-9]\d*$/,
  message: '请输入数字',
};

/** 仅支持中文、英文、数字、三种特殊符号/-_ */
export const checkFormSpecialCheck = {
  pattern: /^[a-zA-Z0-9\u4e00-\u9fa5-/_]*$/,
  message: '仅支持中文、英文、数字、三种特殊符号/-_',
};

/** 不支持中文 */
export const checkChineseNotSupported = {
  pattern: /[\u4e00-\u9fa5]/,
  message: '不能为中文',
};

/** 邮箱校验 */
export const checkEmailSupported = {
  pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/,
  message: '邮箱格式不正确',
};

/** 必须是网址 */
export const urlRules = {
  pattern: /(http|https|ftp|ftps):\/\/([\w.]+\/?)\S*/,
  message: '网址不正格式确',
};
