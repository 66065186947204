/**
 * 数字 控件属性
 */
import { CSSProperties, FC, useContext, useMemo } from 'react';
import _ from 'lodash';
import { Form, Input, InputNumber, Select, Radio, Checkbox } from 'antd';
import type { FormInstance } from 'antd';
import {
  DefaultLogic,
  ShowLogic,
  BizProperty,
  ControlType,
  NumericType,
  NumericRangeLimitLogic,
} from 'src/dict/sop';
import {
  controlType as controlTypeMappings,
  editable as editableMappings,
  numericShowLogic as numericShowLogicMappings,
  textShowLogic as textShowLogicMappings,
  numericDefaultLogic as numericDefaultLogicMappings,
  textDefaultLogic as textDefaultLogicMappings,
  bizProperty as bizPropertyMappings,
  numericBizProperty as numericBizPropertyMappings,
  numericType as numericTypeMappings,
  numericRangeLimitLogic as numericRangeLimitLogicMappings,
} from 'src/dict/sop/mappings';
import type { StepDetailData, LocalControlItem } from '../../types';
import {
  ControlEditorContext,
  CONTROL_TITLE_LENGTH,
  INPUT_DEFAULT_PLACEHOLDER,
} from '../../constants';
import { getNameFactory, titleRules, resetUnusedItems } from './share';
import { textValidator1, fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import { FormulaEditorModal, SearchSelect } from 'src/components';

import { FetchUnitListResponse } from 'src/api/ytt/material-domain/web-unit';

type UnitListItem = Exclude<
  Exclude<PickCode<FetchUnitListResponse>['data'], undefined>['list'],
  undefined
>[number];
interface Props {
  form: FormInstance<StepDetailData>;
  localKey: string;
}

const closeMarginBottom: CSSProperties = {
  marginBottom: 10,
};

const getDemoNumber = (
  numericType: NumericType,
  enableSeparator: boolean,
  enableDecimal: boolean,
  decimalLength: number,
) => {
  if (numericType === NumericType.percentage) {
    const num: number = 88.36;

    return `${enableDecimal ? num.toFixed(decimalLength) : Math.round(num)}%`;
  }
  const num: number = 88888.8888888;

  if (enableSeparator) {
    const decimalLen = enableDecimal ? decimalLength : 0;

    return num.toLocaleString('en', {
      maximumFractionDigits: decimalLen,
      minimumFractionDigits: decimalLen,
    });
  }
  return enableDecimal ? num.toFixed(decimalLength) : Math.round(num);
};

const MAX_LENGTH_BEFORE_POINT = 9;

export const NumericInputProperties: FC<Props> = ({ form, localKey }) => {
  const {
    forceUpdate,
    sopBasicInfo: { isReportTemplate },
  } = useContext(ControlEditorContext);

  const revalidate = () => {
    forceUpdate();
    _.delay(() => form.validateFields(), 400);
  };
  const controlList = form.getFieldValue('controls');

  const numericDefaultLogicList = useMemo(() => {
    const mappings = numericDefaultLogicMappings.slice();

    if (isReportTemplate) {
      return mappings.filter((item) => item.value !== DefaultLogic.trigger);
    }

    return mappings;
  }, [isReportTemplate]);

  const numericShowLogicList = useMemo(() => {
    const mappings = numericShowLogicMappings.slice();

    return isReportTemplate
      ? mappings.filter((item) => item.value !== ShowLogic.trigger)
      : mappings;
  }, [isReportTemplate]);

  if (_.isEmpty(controlList)) {
    return null;
  }
  const dataIndex = controlList.findIndex((item: LocalControlItem) => item._localKey === localKey);

  if (dataIndex === -1) {
    return null;
  }
  const getName = getNameFactory(dataIndex);
  const {
    controlType,
    enableEdit,
    showLogic,
    defaultLogic,
    scanInput,
    limitMin,
    limitMax,
    enableSeparator,
    enableDecimal,
    numericType,
    decimalLength,
    enableLimit,
    numericLimitType,
    lowLimit,
    lowestLimit,
    highLimit,
    highestLimit,
  } = controlList[dataIndex];
  const isNumeric = controlType === ControlType.numeric;
  const demoNumber = getDemoNumber(numericType, enableSeparator, enableDecimal, decimalLength);
  let ValueInputComp: any = Input;
  const showValueRules: any[] = [{ required: true, message: '请输入默认值' }];
  const defaultValueRules: any[] = showValueRules.slice(0);

  const selectFormatter = (res: FetchUnitListResponse) => {
    return {
      options: res?.data?.list?.map((item: UnitListItem) => {
        return {
          label: item.name,
          value: item.id,
          key: item.id,
        };
      }),
      total: res?.data?.total,
    };
  };

  if (isNumeric) {
    showValueRules.push({ max: 24, message: '不超过24个字符' });

    // 默认值校验数值范围区间
    if (numericLimitType === NumericRangeLimitLogic.range) {
      const lowArr = ['lowLimit', 'lowestLimit'];
      const highArr = ['highLimit', 'highestLimit'];
      const rangeObj = {
        lowLimit,
        highLimit,
        lowestLimit,
        highestLimit,
      };
      const validRangeKeys = _.keys(
        _.pickBy(rangeObj, (item) => !_.isNil(item) && _.isNumber(_.toNumber(item))),
      );

      const lowRangeLength = _.intersection(lowArr, validRangeKeys)?.length;
      const highRangeLength = _.intersection(highArr, validRangeKeys)?.length;

      const isLow = lowRangeLength && !highRangeLength;
      const isHigh = !lowRangeLength && highRangeLength;
      const isLowHigh = lowRangeLength && highRangeLength;

      if (isLow) {
        defaultValueRules.push({
          validator: numberMinMaxCheck({
            minAllowEqual: true,
            maxAllowEqual: true,
            min: _.min([lowestLimit, lowLimit]),
            message: '不符合数值范围限制',
          }),
        });
      }
      if (isHigh) {
        defaultValueRules.push({
          validator: numberMinMaxCheck({
            minAllowEqual: true,
            maxAllowEqual: true,
            max: _.max([highestLimit, highLimit]),
            message: '不符合数值范围限制',
          }),
        });
      }
      if (isLowHigh) {
        defaultValueRules.push({
          validator: numberMinMaxCheck({
            promptByInterval: true,
            minAllowEqual: true,
            maxAllowEqual: true,
            max: _.max([highestLimit, highLimit]),
            min: _.min([lowestLimit, lowLimit]),
            message: '不符合数值范围限制',
          }),
        });
      }
    }
    // 默认值、显示值校验小数位数
    if (enableDecimal && _.isNumber(decimalLength)) {
      const validator = { validator: fractionLengthCheck(decimalLength) };

      defaultValueRules.push(validator);
      showValueRules.push(validator);
    }
  } else {
    showValueRules.push({ max: 200, message: '不超过200个字符' });
    showValueRules.push({ validator: textValidator1 });
    // 默认值检查是否有限制字数校验
    if (enableLimit && _.isNumber(limitMax) && _.isNumber(limitMin)) {
      defaultValueRules.push({ max: limitMax, message: `不超过${limitMax}个字符` });
      defaultValueRules.push({ min: limitMin, message: `不少于${limitMin}个字符` });
    }
    if (controlType === ControlType.multiLine) {
      ValueInputComp = Input.TextArea;
    }
  }

  // 当『显示内容』发生变化时, 联动修改showValue的默认值
  const setShowValue = (logic: ShowLogic) => {
    let value: string | number = '';

    if (logic === ShowLogic.biz) {
      value = isNumeric ? BizProperty.projectPlanOutputAmount : BizProperty.sopTaskNo;
    }
    if (isNumeric && logic === ShowLogic.trigger) {
      form.setFields([{ name: getName('showFormulaId'), value: undefined }]);
    }
    form.setFields([
      { name: getName('showLogic'), value: logic },
      { name: getName('showValue'), value },
    ]);
  };
  // 当『默认值』选项发生变化时, 联动修改defaultValue的默认值
  const setDefaultValue = (logic: DefaultLogic) => {
    let value: string | number = '';

    if (logic === DefaultLogic.biz) {
      value = isNumeric ? BizProperty.projectPlanOutputAmount : BizProperty.sopTaskNo;
    }
    if (isNumeric && logic === DefaultLogic.trigger) {
      form.setFields([{ name: getName('defaultFormulaId'), value: undefined }]);
    }
    form.setFields([
      { name: getName('defaultLogic'), value: logic },
      { name: getName('defaultValue'), value },
    ]);
  };

  const numericLimitCommonValidator = (
    _rule: any,
    value: string,
    _callback: (error?: string) => void,
  ) => {
    if (value) {
      const absValue = Math.abs(_.toNumber(value));
      const arr = !absValue ? [] : _.toString(absValue).split('.');
      const integer = arr[0];
      const fraction = arr[1];

      if (integer.length > MAX_LENGTH_BEFORE_POINT) {
        _callback(`整数不超过${MAX_LENGTH_BEFORE_POINT}位`);
      }
      if (enableDecimal && _.isNumber(decimalLength) && fraction?.length > decimalLength) {
        _callback(`小数点后最多保留${decimalLength}位数字`);
      }
    }

    _callback();
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={() => {
        forceUpdate();
        resetUnusedItems(form, controlType, dataIndex, forceUpdate);
      }}
      className="control-editor-form"
    >
      <Form.Item label="标题" name={getName('name')} rules={titleRules}>
        <Input max={CONTROL_TITLE_LENGTH} placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>
      {!isNumeric && (
        <Form.Item label="类型" name={getName('controlType')} rules={[{ required: true }]}>
          <Select options={_.take(controlTypeMappings, 2)} />
        </Form.Item>
      )}
      <Form.Item label="权限" name={getName('enableEdit')} rules={[{ required: true }]}>
        <Radio.Group options={editableMappings} />
      </Form.Item>
      {!enableEdit && (
        <Form.Item
          label="显示内容"
          name={getName('showLogic')}
          rules={[{ required: true }]}
          style={closeMarginBottom}
        >
          <Select
            options={isNumeric ? numericShowLogicList : textShowLogicMappings}
            onSelect={setShowValue}
          />
        </Form.Item>
      )}
      {!enableEdit && showLogic === ShowLogic.fixed && (
        <Form.Item name={getName('showValue')} rules={showValueRules}>
          <ValueInputComp
            type={isNumeric ? 'number' : 'text'}
            placeholder={INPUT_DEFAULT_PLACEHOLDER}
          />
        </Form.Item>
      )}
      {!enableEdit && showLogic === ShowLogic.biz && (
        <Form.Item
          name={getName('showValue')}
          rules={[{ required: true, message: '请选择显示内容' }]}
        >
          <Select options={isNumeric ? numericBizPropertyMappings : bizPropertyMappings} />
        </Form.Item>
      )}
      {/* {!enableEdit && showLogic === ShowLogic.trigger && (
           <Form.Item
             name={getName('showValue')}
             rules={[
               { required: true, message: '请输入trigger公式' },
               { max: 1000, message: '最多输入1000个字符' },
             ]}
           >
             <Input.TextArea maxLength={1000} />
           </Form.Item>
         )} */}

      {!isReportTemplate && !enableEdit && showLogic === ShowLogic.trigger && (
        <Form.Item
          name={getName('showFormulaId')}
          rules={[{ required: true, message: '请输入公式' }]}
        >
          <FormulaEditorModal objectCode={'SOPTask'} />
        </Form.Item>
      )}
      {enableEdit && (
        <Form.Item
          label="默认值"
          name={getName('defaultLogic')}
          rules={[{ required: true, message: '请选择默认值' }]}
          style={defaultLogic === DefaultLogic.none ? undefined : closeMarginBottom}
        >
          <Select
            options={isNumeric ? numericDefaultLogicList : textDefaultLogicMappings}
            onSelect={setDefaultValue}
          />
        </Form.Item>
      )}
      {enableEdit && defaultLogic === DefaultLogic.fixed && (
        <Form.Item name={getName('defaultValue')} rules={defaultValueRules}>
          <ValueInputComp
            type={isNumeric ? 'number' : 'text'}
            placeholder={INPUT_DEFAULT_PLACEHOLDER}
          />
        </Form.Item>
      )}
      {enableEdit && defaultLogic === DefaultLogic.biz && (
        <Form.Item
          name={getName('defaultValue')}
          rules={[{ required: true, message: '请选择默认值' }]}
        >
          <Select options={isNumeric ? numericBizPropertyMappings : bizPropertyMappings} />
        </Form.Item>
      )}
      {/* {enableEdit && defaultLogic === DefaultLogic.trigger && (
           <Form.Item
             name={getName('defaultFormulaId')}
             // rules={[
             //   { required: true, message: '请输入trigger公式' },
             //   { max: 1000, message: '最多输入1000个字符' },
             // ]}
           >
             <Input.TextArea maxLength={1000} />
           </Form.Item>
         )} */}

      {!isReportTemplate && enableEdit && defaultLogic === DefaultLogic.trigger && (
        <Form.Item
          name={getName('defaultFormulaId')}
          rules={[{ required: true, message: '请输入公式' }]}
        >
          <FormulaEditorModal objectCode={'SOPTask'} />
        </Form.Item>
      )}
      {isNumeric && (
        <Form.Item label="格式">
          <Form.Item
            name={getName('numericType')}
            rules={[{ required: true, message: '请选择格式' }]}
            style={closeMarginBottom}
          >
            <Select options={numericTypeMappings} />
          </Form.Item>
          <div
            className="text-format-previewer"
            style={numericType === NumericType.percentage ? closeMarginBottom : undefined}
          >
            {demoNumber}
          </div>
          {numericType === NumericType.value && (
            <Form.Item
              name={getName('enableSeparator')}
              valuePropName="checked"
              style={{ marginBottom: 0 }}
            >
              <Checkbox>显示千分符</Checkbox>
            </Form.Item>
          )}
          <div style={{ height: 32, display: 'flex', alignItems: 'center' }}>
            <Form.Item noStyle name={getName('enableDecimal')} valuePropName="checked">
              <Checkbox onChange={revalidate}>保留小数位数</Checkbox>
            </Form.Item>
            {enableDecimal && (
              <Form.Item
                noStyle
                name={getName('decimalLength')}
                rules={[{ required: true, message: '请输入保留小数位数' }]}
              >
                <InputNumber min={1} max={9} precision={0} onChange={revalidate} />
              </Form.Item>
            )}
          </div>
        </Form.Item>
      )}
      {isNumeric && (
        <Form.Item label="单位" name={getName('unitId')}>
          <SearchSelect
            fetchType="SOPUnitOptions"
            formatter={selectFormatter}
            placeholder="请选择"
          />
        </Form.Item>
      )}
      {enableEdit && !isNumeric && (
        <Form.Item label="扫码">
          <Form.Item
            name={getName('scanInput')}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox>扫码输入</Checkbox>
          </Form.Item>
          {scanInput && (
            <Form.Item
              name={getName('scanResultCanEdit')}
              valuePropName="checked"
              style={{ marginBottom: 0 }}
            >
              <Checkbox>可修改扫码结果</Checkbox>
            </Form.Item>
          )}
        </Form.Item>
      )}

      {enableEdit && isNumeric && (
        <Form.Item
          label="数值范围限制"
          name={getName('numericLimitType')}
          rules={[{ required: true, message: '请选择' }]}
        >
          <Select options={numericRangeLimitLogicMappings} />
        </Form.Item>
      )}
      {enableEdit && numericLimitType === NumericRangeLimitLogic.range && (
        <Form.Item label="下限~上限" style={{ marginBottom: 0 }}>
          <Form.Item
            name={getName('lowLimit')}
            rules={[{ validator: numericLimitCommonValidator }]}
            style={{ display: 'inline-block' }}
          >
            <InputNumber onChange={revalidate} />
          </Form.Item>
          <span style={{ margin: '0 8px', lineHeight: '32px' }}>~</span>
          <Form.Item
            name={getName('highLimit')}
            dependencies={[getName('lowLimit')]}
            rules={[
              { validator: numericLimitCommonValidator },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const lowLimit = getFieldValue(getName('lowLimit'));

                  if (value !== null && lowLimit !== null && value <= lowLimit) {
                    return Promise.reject(new Error('请输入大于下限的数值'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
            style={{ display: 'inline-block' }}
          >
            <InputNumber onChange={revalidate} />
          </Form.Item>
        </Form.Item>
      )}
      {enableEdit && numericLimitType === NumericRangeLimitLogic.range && (
        <Form.Item label="下下限~上上限" style={{ marginBottom: 0 }}>
          <Form.Item
            name={getName('lowestLimit')}
            dependencies={[getName('lowLimit')]}
            rules={[
              { validator: numericLimitCommonValidator },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const lowLimit = getFieldValue(getName('lowLimit'));
                  const highLimit = getFieldValue(getName('highLimit'));

                  if (value !== null && lowLimit !== null && value >= lowLimit) {
                    return Promise.reject(new Error('请输入小于下限的数值'));
                  }
                  if (value !== null && highLimit !== null && value >= highLimit) {
                    return Promise.reject(new Error('请输入小于上限的数值'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
            style={{ display: 'inline-block' }}
          >
            <InputNumber onChange={revalidate} />
          </Form.Item>
          <span style={{ margin: '0 8px', lineHeight: '32px' }}>~</span>
          <Form.Item
            name={getName('highestLimit')}
            dependencies={[getName('lowestLimit'), getName('highLimit')]}
            rules={[
              { validator: numericLimitCommonValidator },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const lowestLimit = getFieldValue(getName('lowestLimit'));
                  const lowLimit = getFieldValue(getName('lowLimit'));
                  const highLimit = getFieldValue(getName('highLimit'));

                  if (value !== null && highLimit !== null && value <= highLimit) {
                    return Promise.reject(new Error('请输入大于上限的数值'));
                  }
                  if (value !== null && lowLimit !== null && value <= lowLimit) {
                    return Promise.reject(new Error('请输入大于下限的数值'));
                  }
                  if (value !== null && lowestLimit !== null && value <= lowestLimit) {
                    return Promise.reject(new Error('请输入大于下下限的数值'));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
            style={{ display: 'inline-block' }}
          >
            <InputNumber onChange={revalidate} />
          </Form.Item>
        </Form.Item>
      )}

      {enableEdit && (
        <Form.Item label="校验" style={{ marginBottom: 0 }}>
          <Form.Item
            name={getName('isRequired')}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox>必填</Checkbox>
          </Form.Item>
        </Form.Item>
      )}

      <Form.Item label="描述信息" name={getName('remark')}>
        <Input.TextArea placeholder={INPUT_DEFAULT_PLACEHOLDER} maxLength={1000} showCount />
      </Form.Item>
      {enableEdit && (
        <Form.Item
          label="提示文字"
          name={getName('inputPrompt')}
          rules={[{ max: 20, message: '字符数最大为20' }]}
        >
          <Input allowClear placeholder={INPUT_DEFAULT_PLACEHOLDER} />
        </Form.Item>
      )}
    </Form>
  );
};

export default NumericInputProperties;
