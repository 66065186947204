/**
 * @description 多级条件配置表单组件
 */
import { Button, FormInstance, Form, Row } from 'antd';
import _ from 'lodash';
import { BlIcon } from '@blacklake-web/component';
import Options from './Options';
import ConditionItem from './ConditionItem';
import { NamePath, ConditionEditTableLayoutProps } from '../constants';
// eslint-disable-next-line import/no-cycle
import ConditionEditTableLayout from '../index';
import styles from '../styles.module.scss';

/** 初始路径 */
let path: NamePath;

interface ConditionEditTableProps extends ConditionEditTableLayoutProps {
  onRefresh: () => void;
  totalLevel: number;
}

interface AddItemButtonProps {
  onAdd: () => void;
  form: FormInstance;
  // 默认十条
  maxSeq?: number;
  disabled?: boolean;
}

const AddItemButton: React.FC<AddItemButtonProps> = ({ onAdd, disabled, form, maxSeq = 10 }) => {
  const conditionGroups = form.getFieldValue(path);
  // 序号从1开始计算
  const totalConditionsFlat = _.flatMap(conditionGroups, (item) => item?.innerConditions);

  return !disabled ? (
    <Button
      onClick={onAdd}
      icon={<BlIcon type="iconxinjiantianjia" />}
      type="dashed"
      className={styles.addItemButton}
      disabled={totalConditionsFlat.length > maxSeq - 1}
    >
      {`添加筛选条件（最多支持${maxSeq}条配置条件）`}
    </Button>
  ) : null;
};

const Seq = ({ namePath, level, name }: any) => {
  return (
    <Form.Item shouldUpdate noStyle>
      {(form) => {
        const conditionGroups = form.getFieldValue(['triggerCondition', 'conditionGroups']);
        let end: number = 0;
        let seq = 1;
        const width = `${100 - 56 * (level - 1)}px`;

        if (_.isNil(conditionGroups)) return null;
        if (level === 2) {
          end = namePath[0];
          seq += name;
        } else if (level === 1) {
          end = name;
        }
        for (let i = 0; i < end; i++) {
          const { innerConditions, type } = conditionGroups[i] || {};

          if (_.isNil(innerConditions) || _.isNil(type)) seq += 1;
          else {
            seq += innerConditions.length;
          }
        }
        return (
          <Form.Item style={{ width, paddingLeft: level === 1 ? '12px' : 0 }}>{seq}</Form.Item>
        );
      }}
    </Form.Item>
  );
};

const ConditionEditTable: React.FC<ConditionEditTableProps> = ({
  namePath,
  parentPath,
  form,
  refRomove,
  onRefresh,
  level = 1,
  totalLevel,
  info,
  disabled,
}) => {
  const { columns, rowProps, colProps } = info;

  if (level === 1) path = namePath;
  return (
    <Form.List name={namePath}>
      {(fields, { add, remove }) => {
        return (
          <div className={styles.formList}>
            {fields.map(({ name, key, ...restField }) => {
              const fieldPath = [...namePath, name];
              const data = form.getFieldValue(fieldPath);

              if (!_.isUndefined(data?.type)) {
                return (
                  <Form.Item
                    {...restField}
                    name={[name, 'innerConditions']}
                    className={styles.innerTableLayout}
                    key={key}
                  >
                    <ConditionEditTableLayout
                      form={form}
                      namePath={[name, 'innerConditions']}
                      parentPath={[...parentPath, name, 'innerConditions']}
                      info={info}
                      refRomove={() => remove(name)}
                      level={2}
                      totalLevel={totalLevel}
                      disabled={disabled}
                    />
                  </Form.Item>
                );
              }
              const conditionItemNamePath: NamePath = [name];

              if (totalLevel - level > 0) {
                conditionItemNamePath.push('condition');
              }

              return (
                <div style={{ display: 'flex' }} className={styles.conditionItem}>
                  <Seq level={level} namePath={namePath} name={name} />
                  <Row className={styles.formListItemRow} wrap={false} key={key} {...rowProps}>
                    <ConditionItem
                      namePath={conditionItemNamePath}
                      parentPath={[...parentPath]}
                      level={level}
                      columns={columns}
                      rowProps={rowProps}
                      colProps={colProps}
                      key={key}
                    />
                  </Row>
                  {disabled ? null : (
                    <Options
                      disabled={disabled}
                      form={form}
                      namePath={fieldPath}
                      onRemove={() => {
                        /**
                         * refRomove 若没有传值，说明此行条件值处于最顶部的ConditionTableLayout
                         * 有值时，说明此行条件值处于内部的ConditionTableLayout
                         */
                        if (refRomove) {
                          const data = form.getFieldValue([...path, ...namePath]);

                          // 当内层的条件值组合行数等于2时
                          // 此时删除最后一条条件值会将整个ConditionTableLayout删掉
                          if (data?.length === 2) refRomove();
                        }
                        remove(name);
                      }}
                      refresh={onRefresh}
                      conversion={totalLevel - level > 0}
                      copy={false}
                    />
                  )}
                </div>
              );
            })}
            <AddItemButton onAdd={() => add()} disabled={disabled} form={form} />
          </div>
        );
      }}
    </Form.List>
  );
};

export default ConditionEditTable;
