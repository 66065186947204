/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../request';

type UserRequestRestArgs = RequestFunctionRestArgs<typeof request>;

// Request: 目前 React Hooks 功能有用到
export type Request<
  TRequestData,
  TRequestConfig extends RequestConfig,
  TRequestResult,
> = (TRequestConfig['requestDataOptional'] extends true
  ? (requestData?: TRequestData, ...args: RequestFunctionRestArgs<typeof request>) => TRequestResult
  : (
      requestData: TRequestData,
      ...args: RequestFunctionRestArgs<typeof request>
    ) => TRequestResult) & {
  requestConfig: TRequestConfig;
};

const mockUrl_0_0_0_1 = 'http://yapi.blacklake.tech/mock/519' as any;
const devUrl_0_0_0_1 = '' as any;
const prodUrl_0_0_0_1 = '' as any;
const dataKey_0_0_0_1 = undefined as any;

/**
 * 接口 [通过角色ID获取权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72638) 的 **请求类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_privileges_by_role_id`
 * @更新时间 `2022-01-24 10:44:12`
 */
export interface FetchPrivilegeListPrivilegesByRoleIdRequest {
  /**
   * 角色ID
   */
  roleId: number;
  /**
   * 权限类型
   */
  type: number;
}

/**
 * 接口 [通过角色ID获取权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72638) 的 **返回类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_privileges_by_role_id`
 * @更新时间 `2022-01-24 10:44:12`
 */
export interface FetchPrivilegeListPrivilegesByRoleIdResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 功能权限编码
     */
    code?: string;
    /**
     * 功能权限ID
     */
    id?: number;
    /**
     * 功能权限名称
     */
    name?: string;
  }[];
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [通过角色ID获取权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72638) 的 **请求配置的类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_privileges_by_role_id`
 * @更新时间 `2022-01-24 10:44:12`
 */
type FetchPrivilegeListPrivilegesByRoleIdRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/privilege/_list_privileges_by_role_id',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [通过角色ID获取权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72638) 的 **请求配置**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_privileges_by_role_id`
 * @更新时间 `2022-01-24 10:44:12`
 */
const fetchPrivilegeListPrivilegesByRoleIdRequestConfig: FetchPrivilegeListPrivilegesByRoleIdRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_1,
    devUrl: devUrl_0_0_0_1,
    prodUrl: prodUrl_0_0_0_1,
    path: '/user/domain/web/v1/privilege/_list_privileges_by_role_id',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_1,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchPrivilegeListPrivilegesByRoleId',
  };

/**
 * 接口 [通过角色ID获取权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72638) 的 **请求函数**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_privileges_by_role_id`
 * @更新时间 `2022-01-24 10:44:12`
 */
export const fetchPrivilegeListPrivilegesByRoleId = (
  requestData: FetchPrivilegeListPrivilegesByRoleIdRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchPrivilegeListPrivilegesByRoleIdResponse>(
    prepare(fetchPrivilegeListPrivilegesByRoleIdRequestConfig, requestData),
    ...args,
  );
};

fetchPrivilegeListPrivilegesByRoleId.requestConfig =
  fetchPrivilegeListPrivilegesByRoleIdRequestConfig;

/**
 * 接口 [保存角色ID的权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72644) 的 **请求类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_save_privileges_by_role_id`
 * @更新时间 `2022-01-24 10:44:12`
 */
export interface FetchPrivilegeSavePrivilegesByRoleIdRequest {
  /**
   * 功能权限ID
   */
  privilegeIdList: number[];
  /**
   * 角色ID
   */
  roleId: number;
}

/**
 * 接口 [保存角色ID的权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72644) 的 **返回类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_save_privileges_by_role_id`
 * @更新时间 `2022-01-24 10:44:12`
 */
export interface FetchPrivilegeSavePrivilegesByRoleIdResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 功能权限ID
     */
    privilegeIdList: number[];
    /**
     * 角色ID
     */
    roleId: number;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [保存角色ID的权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72644) 的 **请求配置的类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_save_privileges_by_role_id`
 * @更新时间 `2022-01-24 10:44:12`
 */
type FetchPrivilegeSavePrivilegesByRoleIdRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/privilege/_save_privileges_by_role_id',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [保存角色ID的权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72644) 的 **请求配置**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_save_privileges_by_role_id`
 * @更新时间 `2022-01-24 10:44:12`
 */
const fetchPrivilegeSavePrivilegesByRoleIdRequestConfig: FetchPrivilegeSavePrivilegesByRoleIdRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_1,
    devUrl: devUrl_0_0_0_1,
    prodUrl: prodUrl_0_0_0_1,
    path: '/user/domain/web/v1/privilege/_save_privileges_by_role_id',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_1,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchPrivilegeSavePrivilegesByRoleId',
  };

/**
 * 接口 [保存角色ID的权限列表（web、app角色共用）↗](http://yapi.blacklake.tech/project/519/interface/api/72644) 的 **请求函数**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_save_privileges_by_role_id`
 * @更新时间 `2022-01-24 10:44:12`
 */
export const fetchPrivilegeSavePrivilegesByRoleId = (
  requestData: FetchPrivilegeSavePrivilegesByRoleIdRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchPrivilegeSavePrivilegesByRoleIdResponse>(
    prepare(fetchPrivilegeSavePrivilegesByRoleIdRequestConfig, requestData),
    ...args,
  );
};

fetchPrivilegeSavePrivilegesByRoleId.requestConfig =
  fetchPrivilegeSavePrivilegesByRoleIdRequestConfig;

/**
 * 接口 [tempBuildAdminUser↗](http://yapi.blacklake.tech/project/519/interface/api/72650) 的 **请求类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_temp_build_admin_user`
 * @更新时间 `2022-01-24 10:44:12`
 */
export interface FetchPrivilegeTempBuildAdminUserRequest {}

/**
 * 接口 [tempBuildAdminUser↗](http://yapi.blacklake.tech/project/519/interface/api/72650) 的 **返回类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_temp_build_admin_user`
 * @更新时间 `2022-01-24 10:44:12`
 */
export interface FetchPrivilegeTempBuildAdminUserResponse {}

/**
 * 接口 [tempBuildAdminUser↗](http://yapi.blacklake.tech/project/519/interface/api/72650) 的 **请求配置的类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_temp_build_admin_user`
 * @更新时间 `2022-01-24 10:44:12`
 */
type FetchPrivilegeTempBuildAdminUserRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/privilege/_temp_build_admin_user',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [tempBuildAdminUser↗](http://yapi.blacklake.tech/project/519/interface/api/72650) 的 **请求配置**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_temp_build_admin_user`
 * @更新时间 `2022-01-24 10:44:12`
 */
const fetchPrivilegeTempBuildAdminUserRequestConfig: FetchPrivilegeTempBuildAdminUserRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_1,
    devUrl: devUrl_0_0_0_1,
    prodUrl: prodUrl_0_0_0_1,
    path: '/user/domain/web/v1/privilege/_temp_build_admin_user',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_1,
    paramNames: [],
    queryNames: [],
    requestDataOptional: true,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchPrivilegeTempBuildAdminUser',
  };

/**
 * 接口 [tempBuildAdminUser↗](http://yapi.blacklake.tech/project/519/interface/api/72650) 的 **请求函数**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_temp_build_admin_user`
 * @更新时间 `2022-01-24 10:44:12`
 */
export const fetchPrivilegeTempBuildAdminUser = (
  requestData?: FetchPrivilegeTempBuildAdminUserRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchPrivilegeTempBuildAdminUserResponse>(
    prepare(fetchPrivilegeTempBuildAdminUserRequestConfig, requestData),
    ...args,
  );
};

fetchPrivilegeTempBuildAdminUser.requestConfig = fetchPrivilegeTempBuildAdminUserRequestConfig;

/**
 * 接口 [web功能权限树↗](http://yapi.blacklake.tech/project/519/interface/api/72656) 的 **请求类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_tree`
 * @更新时间 `2022-01-24 10:44:12`
 */
export interface FetchPrivilegeTreeRequest {
  /**
   * 角色Id
   */
  roleId?: string;
}

/**
 * 接口 [web功能权限树↗](http://yapi.blacklake.tech/project/519/interface/api/72656) 的 **返回类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_tree`
 * @更新时间 `2022-01-24 10:44:12`
 */
export interface FetchPrivilegeTreeResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 功能权限的子级，子级的结构同父级
     */
    children?: unknown[];
    /**
     * 功能权限编码
     */
    code?: string;
    /**
     * 是否支持配置
     */
    enable?: boolean;
    /**
     * 功能权限ID
     */
    id?: number;
    /**
     * 功能权限在树中的层级从1开始，1代表根级
     */
    level?: number;
    /**
     * 功能权限信息
     */
    levelInfo?: string;
    /**
     * 功能权限名称
     */
    name?: string;
    /**
     * 功能权限父级ID
     */
    parentId?: number;
  }[];
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [web功能权限树↗](http://yapi.blacklake.tech/project/519/interface/api/72656) 的 **请求配置的类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_tree`
 * @更新时间 `2022-01-24 10:44:12`
 */
type FetchPrivilegeTreeRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/privilege/_tree',
    undefined,
    string,
    'roleId',
    false
  >
>;

/**
 * 接口 [web功能权限树↗](http://yapi.blacklake.tech/project/519/interface/api/72656) 的 **请求配置**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_tree`
 * @更新时间 `2022-01-24 10:44:12`
 */
const fetchPrivilegeTreeRequestConfig: FetchPrivilegeTreeRequestConfig = {
  mockUrl: mockUrl_0_0_0_1,
  devUrl: devUrl_0_0_0_1,
  prodUrl: prodUrl_0_0_0_1,
  path: '/user/domain/web/v1/privilege/_tree',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_1,
  paramNames: [],
  queryNames: ['roleId'],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchPrivilegeTree',
};

/**
 * 接口 [web功能权限树↗](http://yapi.blacklake.tech/project/519/interface/api/72656) 的 **请求函数**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_tree`
 * @更新时间 `2022-01-24 10:44:12`
 */
export const fetchPrivilegeTree = (
  requestData: FetchPrivilegeTreeRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchPrivilegeTreeResponse>(
    prepare(fetchPrivilegeTreeRequestConfig, requestData),
    ...args,
  );
};

fetchPrivilegeTree.requestConfig = fetchPrivilegeTreeRequestConfig;

/**
 * 接口 [获取可配置电子签名的功能点列表↗](http://yapi.blacklake.tech/project/519/interface/api/86354) 的 **请求类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_enable_signature_function`
 * @更新时间 `2022-01-24 10:44:12`
 */
export interface FetchPrivilegeListEnableSignatureFunctionRequest {}

/**
 * 接口 [获取可配置电子签名的功能点列表↗](http://yapi.blacklake.tech/project/519/interface/api/86354) 的 **返回类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_enable_signature_function`
 * @更新时间 `2022-01-24 10:44:12`
 */
export interface FetchPrivilegeListEnableSignatureFunctionResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 功能权限编码
     */
    code?: string;
    /**
     * 功能权限ID
     */
    id?: number;
    /**
     * 功能权限名称
     */
    name?: string;
  }[];
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取可配置电子签名的功能点列表↗](http://yapi.blacklake.tech/project/519/interface/api/86354) 的 **请求配置的类型**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_enable_signature_function`
 * @更新时间 `2022-01-24 10:44:12`
 */
type FetchPrivilegeListEnableSignatureFunctionRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/privilege/_list_enable_signature_function',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [获取可配置电子签名的功能点列表↗](http://yapi.blacklake.tech/project/519/interface/api/86354) 的 **请求配置**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_enable_signature_function`
 * @更新时间 `2022-01-24 10:44:12`
 */
const fetchPrivilegeListEnableSignatureFunctionRequestConfig: FetchPrivilegeListEnableSignatureFunctionRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_1,
    devUrl: devUrl_0_0_0_1,
    prodUrl: prodUrl_0_0_0_1,
    path: '/user/domain/web/v1/privilege/_list_enable_signature_function',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_1,
    paramNames: [],
    queryNames: [],
    requestDataOptional: true,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchPrivilegeListEnableSignatureFunction',
  };

/**
 * 接口 [获取可配置电子签名的功能点列表↗](http://yapi.blacklake.tech/project/519/interface/api/86354) 的 **请求函数**
 *
 * @分类 [web-privilege↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10792)
 * @标签 `web-privilege`
 * @请求头 `POST /user/domain/web/v1/privilege/_list_enable_signature_function`
 * @更新时间 `2022-01-24 10:44:12`
 */
export const fetchPrivilegeListEnableSignatureFunction = (
  requestData?: FetchPrivilegeListEnableSignatureFunctionRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchPrivilegeListEnableSignatureFunctionResponse>(
    prepare(fetchPrivilegeListEnableSignatureFunctionRequestConfig, requestData),
    ...args,
  );
};

fetchPrivilegeListEnableSignatureFunction.requestConfig =
  fetchPrivilegeListEnableSignatureFunctionRequestConfig;

const mockUrl_0_0_0_2 = 'http://yapi.blacklake.tech/mock/519' as any;
const devUrl_0_0_0_2 = '' as any;
const prodUrl_0_0_0_2 = '' as any;
const dataKey_0_0_0_2 = undefined as any;

/**
 * 接口 [标准业务对象列表获取↗](http://yapi.blacklake.tech/project/519/interface/api/72728) 的 **请求类型**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10794)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /user/domain/web/v1/standard_biz_object/_list_page`
 * @更新时间 `2021-10-15 16:15:49`
 */
export interface FetchStandardBizObjectListPageRequest {
  /**
   * 排序字段
   */
  orderBy?: string;
  /**
   * 升降序
   */
  orderDirection?: string;
  /**
   * 分页参数
   */
  page: number;
  /**
   * 搜索条件
   */
  search?: string;
  /**
   * 分页参数
   */
  size: number;
}

/**
 * 接口 [标准业务对象列表获取↗](http://yapi.blacklake.tech/project/519/interface/api/72728) 的 **返回类型**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10794)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /user/domain/web/v1/standard_biz_object/_list_page`
 * @更新时间 `2021-10-15 16:15:49`
 */
export interface FetchStandardBizObjectListPageResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [标准业务对象列表获取↗](http://yapi.blacklake.tech/project/519/interface/api/72728) 的 **请求配置的类型**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10794)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /user/domain/web/v1/standard_biz_object/_list_page`
 * @更新时间 `2021-10-15 16:15:49`
 */
type FetchStandardBizObjectListPageRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/standard_biz_object/_list_page',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [标准业务对象列表获取↗](http://yapi.blacklake.tech/project/519/interface/api/72728) 的 **请求配置**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10794)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /user/domain/web/v1/standard_biz_object/_list_page`
 * @更新时间 `2021-10-15 16:15:49`
 */
const fetchStandardBizObjectListPageRequestConfig: FetchStandardBizObjectListPageRequestConfig = {
  mockUrl: mockUrl_0_0_0_2,
  devUrl: devUrl_0_0_0_2,
  prodUrl: prodUrl_0_0_0_2,
  path: '/user/domain/web/v1/standard_biz_object/_list_page',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_2,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStandardBizObjectListPage',
};

/**
 * 接口 [标准业务对象列表获取↗](http://yapi.blacklake.tech/project/519/interface/api/72728) 的 **请求函数**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10794)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /user/domain/web/v1/standard_biz_object/_list_page`
 * @更新时间 `2021-10-15 16:15:49`
 */
export const fetchStandardBizObjectListPage = (
  requestData: FetchStandardBizObjectListPageRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchStandardBizObjectListPageResponse>(
    prepare(fetchStandardBizObjectListPageRequestConfig, requestData),
    ...args,
  );
};

fetchStandardBizObjectListPage.requestConfig = fetchStandardBizObjectListPageRequestConfig;

/**
 * 接口 [标准业务对象列表无分页获取↗](http://yapi.blacklake.tech/project/519/interface/api/72734) 的 **请求类型**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10794)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /user/domain/web/v1/standard_biz_object/_object_list`
 * @更新时间 `2021-10-15 16:15:49`
 */
export interface FetchStandardBizObjectObjectListRequest {}

/**
 * 接口 [标准业务对象列表无分页获取↗](http://yapi.blacklake.tech/project/519/interface/api/72734) 的 **返回类型**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10794)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /user/domain/web/v1/standard_biz_object/_object_list`
 * @更新时间 `2021-10-15 16:15:49`
 */
export interface FetchStandardBizObjectObjectListResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [标准业务对象列表无分页获取↗](http://yapi.blacklake.tech/project/519/interface/api/72734) 的 **请求配置的类型**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10794)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /user/domain/web/v1/standard_biz_object/_object_list`
 * @更新时间 `2021-10-15 16:15:49`
 */
type FetchStandardBizObjectObjectListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/standard_biz_object/_object_list',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [标准业务对象列表无分页获取↗](http://yapi.blacklake.tech/project/519/interface/api/72734) 的 **请求配置**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10794)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /user/domain/web/v1/standard_biz_object/_object_list`
 * @更新时间 `2021-10-15 16:15:49`
 */
const fetchStandardBizObjectObjectListRequestConfig: FetchStandardBizObjectObjectListRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_2,
    devUrl: devUrl_0_0_0_2,
    prodUrl: prodUrl_0_0_0_2,
    path: '/user/domain/web/v1/standard_biz_object/_object_list',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_2,
    paramNames: [],
    queryNames: [],
    requestDataOptional: true,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchStandardBizObjectObjectList',
  };

/**
 * 接口 [标准业务对象列表无分页获取↗](http://yapi.blacklake.tech/project/519/interface/api/72734) 的 **请求函数**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10794)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /user/domain/web/v1/standard_biz_object/_object_list`
 * @更新时间 `2021-10-15 16:15:49`
 */
export const fetchStandardBizObjectObjectList = (
  requestData?: FetchStandardBizObjectObjectListRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchStandardBizObjectObjectListResponse>(
    prepare(fetchStandardBizObjectObjectListRequestConfig, requestData),
    ...args,
  );
};

fetchStandardBizObjectObjectList.requestConfig = fetchStandardBizObjectObjectListRequestConfig;

const mockUrl_0_0_0_3 = 'http://yapi.blacklake.tech/mock/519' as any;
const devUrl_0_0_0_3 = '' as any;
const prodUrl_0_0_0_3 = '' as any;
const dataKey_0_0_0_3 = undefined as any;

/**
 * 接口 [指定角色的所有字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72590) 的 **请求类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getByRole`
 * @更新时间 `2022-01-18 15:22:01`
 */
export interface FetchFieldPermissionGetByRoleRequest {
  /**
   * 业务对象code
   */
  bizObjectCode: string;
  /**
   * 角色id
   */
  roleId: number;
}

/**
 * 接口 [指定角色的所有字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72590) 的 **返回类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getByRole`
 * @更新时间 `2022-01-18 15:22:01`
 */
export interface FetchFieldPermissionGetByRoleResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [指定角色的所有字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72590) 的 **请求配置的类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getByRole`
 * @更新时间 `2022-01-18 15:22:01`
 */
type FetchFieldPermissionGetByRoleRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/field_permission/_getByRole',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [指定角色的所有字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72590) 的 **请求配置**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getByRole`
 * @更新时间 `2022-01-18 15:22:01`
 */
const fetchFieldPermissionGetByRoleRequestConfig: FetchFieldPermissionGetByRoleRequestConfig = {
  mockUrl: mockUrl_0_0_0_3,
  devUrl: devUrl_0_0_0_3,
  prodUrl: prodUrl_0_0_0_3,
  path: '/user/domain/web/v1/field_permission/_getByRole',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_3,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchFieldPermissionGetByRole',
};

/**
 * 接口 [指定角色的所有字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72590) 的 **请求函数**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getByRole`
 * @更新时间 `2022-01-18 15:22:01`
 */
export const fetchFieldPermissionGetByRole = (
  requestData: FetchFieldPermissionGetByRoleRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchFieldPermissionGetByRoleResponse>(
    prepare(fetchFieldPermissionGetByRoleRequestConfig, requestData),
    ...args,
  );
};

fetchFieldPermissionGetByRole.requestConfig = fetchFieldPermissionGetByRoleRequestConfig;

/**
 * 接口 [指定角色的所有字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72596) 的 **请求类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getByRoleWithPagination`
 * @更新时间 `2022-01-18 15:22:01`
 */
export interface FetchFieldPermissionGetByRoleWithPaginationRequest {
  /**
   * 业务对象code
   */
  bizObjectCode: string;
  /**
   * 分页参数
   */
  page: number;
  /**
   * 角色id
   */
  roleId: number;
  /**
   * 分页参数
   */
  size: number;
}

/**
 * 接口 [指定角色的所有字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72596) 的 **返回类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getByRoleWithPagination`
 * @更新时间 `2022-01-18 15:22:01`
 */
export interface FetchFieldPermissionGetByRoleWithPaginationResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [指定角色的所有字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72596) 的 **请求配置的类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getByRoleWithPagination`
 * @更新时间 `2022-01-18 15:22:01`
 */
type FetchFieldPermissionGetByRoleWithPaginationRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/field_permission/_getByRoleWithPagination',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [指定角色的所有字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72596) 的 **请求配置**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getByRoleWithPagination`
 * @更新时间 `2022-01-18 15:22:01`
 */
const fetchFieldPermissionGetByRoleWithPaginationRequestConfig: FetchFieldPermissionGetByRoleWithPaginationRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_3,
    devUrl: devUrl_0_0_0_3,
    prodUrl: prodUrl_0_0_0_3,
    path: '/user/domain/web/v1/field_permission/_getByRoleWithPagination',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_3,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchFieldPermissionGetByRoleWithPagination',
  };

/**
 * 接口 [指定角色的所有字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72596) 的 **请求函数**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getByRoleWithPagination`
 * @更新时间 `2022-01-18 15:22:01`
 */
export const fetchFieldPermissionGetByRoleWithPagination = (
  requestData: FetchFieldPermissionGetByRoleWithPaginationRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchFieldPermissionGetByRoleWithPaginationResponse>(
    prepare(fetchFieldPermissionGetByRoleWithPaginationRequestConfig, requestData),
    ...args,
  );
};

fetchFieldPermissionGetByRoleWithPagination.requestConfig =
  fetchFieldPermissionGetByRoleWithPaginationRequestConfig;

/**
 * 接口 [默认字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72602) 的 **请求类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getDefault`
 * @更新时间 `2022-01-18 15:22:02`
 */
export interface FetchFieldPermissionGetDefaultRequest {
  /**
   * 业务对象code
   */
  bizObjectCode: string;
}

/**
 * 接口 [默认字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72602) 的 **返回类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getDefault`
 * @更新时间 `2022-01-18 15:22:02`
 */
export interface FetchFieldPermissionGetDefaultResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [默认字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72602) 的 **请求配置的类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getDefault`
 * @更新时间 `2022-01-18 15:22:02`
 */
type FetchFieldPermissionGetDefaultRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/field_permission/_getDefault',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [默认字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72602) 的 **请求配置**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getDefault`
 * @更新时间 `2022-01-18 15:22:02`
 */
const fetchFieldPermissionGetDefaultRequestConfig: FetchFieldPermissionGetDefaultRequestConfig = {
  mockUrl: mockUrl_0_0_0_3,
  devUrl: devUrl_0_0_0_3,
  prodUrl: prodUrl_0_0_0_3,
  path: '/user/domain/web/v1/field_permission/_getDefault',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_3,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchFieldPermissionGetDefault',
};

/**
 * 接口 [默认字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72602) 的 **请求函数**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getDefault`
 * @更新时间 `2022-01-18 15:22:02`
 */
export const fetchFieldPermissionGetDefault = (
  requestData: FetchFieldPermissionGetDefaultRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchFieldPermissionGetDefaultResponse>(
    prepare(fetchFieldPermissionGetDefaultRequestConfig, requestData),
    ...args,
  );
};

fetchFieldPermissionGetDefault.requestConfig = fetchFieldPermissionGetDefaultRequestConfig;

/**
 * 接口 [默认字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72608) 的 **请求类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getDefaultWithPagination`
 * @更新时间 `2022-01-18 15:22:02`
 */
export interface FetchFieldPermissionGetDefaultWithPaginationRequest {
  /**
   * 业务对象code
   */
  bizObjectCode: string;
  /**
   * 分页参数
   */
  page: number;
  /**
   * 分页参数
   */
  size: number;
}

/**
 * 接口 [默认字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72608) 的 **返回类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getDefaultWithPagination`
 * @更新时间 `2022-01-18 15:22:02`
 */
export interface FetchFieldPermissionGetDefaultWithPaginationResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [默认字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72608) 的 **请求配置的类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getDefaultWithPagination`
 * @更新时间 `2022-01-18 15:22:02`
 */
type FetchFieldPermissionGetDefaultWithPaginationRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/field_permission/_getDefaultWithPagination',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [默认字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72608) 的 **请求配置**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getDefaultWithPagination`
 * @更新时间 `2022-01-18 15:22:02`
 */
const fetchFieldPermissionGetDefaultWithPaginationRequestConfig: FetchFieldPermissionGetDefaultWithPaginationRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_3,
    devUrl: devUrl_0_0_0_3,
    prodUrl: prodUrl_0_0_0_3,
    path: '/user/domain/web/v1/field_permission/_getDefaultWithPagination',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_3,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchFieldPermissionGetDefaultWithPagination',
  };

/**
 * 接口 [默认字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72608) 的 **请求函数**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_getDefaultWithPagination`
 * @更新时间 `2022-01-18 15:22:02`
 */
export const fetchFieldPermissionGetDefaultWithPagination = (
  requestData: FetchFieldPermissionGetDefaultWithPaginationRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchFieldPermissionGetDefaultWithPaginationResponse>(
    prepare(fetchFieldPermissionGetDefaultWithPaginationRequestConfig, requestData),
    ...args,
  );
};

fetchFieldPermissionGetDefaultWithPagination.requestConfig =
  fetchFieldPermissionGetDefaultWithPaginationRequestConfig;

/**
 * 接口 [标准业务对象列表无分页获取↗](http://yapi.blacklake.tech/project/519/interface/api/72614) 的 **请求类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_objectList`
 * @更新时间 `2022-01-18 15:22:02`
 */
export interface FetchFieldPermissionObjectListRequest {}

/**
 * 接口 [标准业务对象列表无分页获取↗](http://yapi.blacklake.tech/project/519/interface/api/72614) 的 **返回类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_objectList`
 * @更新时间 `2022-01-18 15:22:02`
 */
export interface FetchFieldPermissionObjectListResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [标准业务对象列表无分页获取↗](http://yapi.blacklake.tech/project/519/interface/api/72614) 的 **请求配置的类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_objectList`
 * @更新时间 `2022-01-18 15:22:02`
 */
type FetchFieldPermissionObjectListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/field_permission/_objectList',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [标准业务对象列表无分页获取↗](http://yapi.blacklake.tech/project/519/interface/api/72614) 的 **请求配置**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_objectList`
 * @更新时间 `2022-01-18 15:22:02`
 */
const fetchFieldPermissionObjectListRequestConfig: FetchFieldPermissionObjectListRequestConfig = {
  mockUrl: mockUrl_0_0_0_3,
  devUrl: devUrl_0_0_0_3,
  prodUrl: prodUrl_0_0_0_3,
  path: '/user/domain/web/v1/field_permission/_objectList',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_3,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchFieldPermissionObjectList',
};

/**
 * 接口 [标准业务对象列表无分页获取↗](http://yapi.blacklake.tech/project/519/interface/api/72614) 的 **请求函数**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_objectList`
 * @更新时间 `2022-01-18 15:22:02`
 */
export const fetchFieldPermissionObjectList = (
  requestData?: FetchFieldPermissionObjectListRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchFieldPermissionObjectListResponse>(
    prepare(fetchFieldPermissionObjectListRequestConfig, requestData),
    ...args,
  );
};

fetchFieldPermissionObjectList.requestConfig = fetchFieldPermissionObjectListRequestConfig;

/**
 * 接口 [创建更新角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72620) 的 **请求类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_update`
 * @更新时间 `2022-01-18 15:22:02`
 */
export interface FetchFieldPermissionUpdateRequest {
  /**
   * 业务对象
   */
  bizObjects: {
    /**
     * 业务对象code
     */
    code: string;
    /**
     * 业务对象下字段
     */
    fields: {
      /**
       * 字段可编辑
       */
      editable: number;
      /**
       * 字段id
       */
      id: number;
      /**
       * 字段权限值
       */
      permission?: number;
      /**
       * 字段可见
       */
      visible: number;
    }[];
  }[];
  /**
   * 角色id
   */
  roleId: number;
}

/**
 * 接口 [创建更新角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72620) 的 **返回类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_update`
 * @更新时间 `2022-01-18 15:22:02`
 */
export interface FetchFieldPermissionUpdateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [创建更新角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72620) 的 **请求配置的类型**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_update`
 * @更新时间 `2022-01-18 15:22:02`
 */
type FetchFieldPermissionUpdateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/field_permission/_update',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [创建更新角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72620) 的 **请求配置**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_update`
 * @更新时间 `2022-01-18 15:22:02`
 */
const fetchFieldPermissionUpdateRequestConfig: FetchFieldPermissionUpdateRequestConfig = {
  mockUrl: mockUrl_0_0_0_3,
  devUrl: devUrl_0_0_0_3,
  prodUrl: prodUrl_0_0_0_3,
  path: '/user/domain/web/v1/field_permission/_update',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_3,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchFieldPermissionUpdate',
};

/**
 * 接口 [创建更新角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/72620) 的 **请求函数**
 *
 * @分类 [web端角色字段权限↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10796)
 * @标签 `web端角色字段权限`
 * @请求头 `POST /user/domain/web/v1/field_permission/_update`
 * @更新时间 `2022-01-18 15:22:02`
 */
export const fetchFieldPermissionUpdate = (
  requestData: FetchFieldPermissionUpdateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchFieldPermissionUpdateResponse>(
    prepare(fetchFieldPermissionUpdateRequestConfig, requestData),
    ...args,
  );
};

fetchFieldPermissionUpdate.requestConfig = fetchFieldPermissionUpdateRequestConfig;

const mockUrl_0_0_0_4 = 'http://yapi.blacklake.tech/mock/519' as any;
const devUrl_0_0_0_4 = '' as any;
const prodUrl_0_0_0_4 = '' as any;
const dataKey_0_0_0_4 = undefined as any;

/**
 * 接口 [登录接口↗](http://yapi.blacklake.tech/project/519/interface/api/72626) 的 **请求类型**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/login`
 * @更新时间 `2022-01-24 10:44:10`
 */
export interface FetchLoginRequest {
  /**
   * 工厂号
   */
  code?: string;
  /**
   * 验证码是否正确
   */
  flag?: boolean;
  /**
   * 是否保持登陆
   */
  keepLogin?: boolean;
  /**
   * 密码
   */
  password?: string;
  /**
   * 电话号码
   */
  phone?: string;
  /**
   * 图形验证码
   */
  pictureCode?: string;
  /**
   * 短信验证码
   */
  smsCode?: string;
  terminal?: string;
  /**
   * 登陆方式(1为工厂号、账号密码，2为短信登陆)
   */
  type: number;
  /**
   * 账号
   */
  username?: string;
  /**
   * 图形验证码uuid
   */
  uuid?: string;
}

/**
 * 接口 [登录接口↗](http://yapi.blacklake.tech/project/519/interface/api/72626) 的 **返回类型**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/login`
 * @更新时间 `2022-01-24 10:44:10`
 */
export interface FetchLoginResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    captchaVO?: {
      /**
       * 图形验证码字节流
       */
      image?: string;
      /**
       * 存储在redis的校验码的key值
       */
      uuid?: string;
    };
    /**
     * 工厂信息
     */
    orgInfo?: {
      /**
       * 工厂code
       */
      code?: string;
      /**
       * 工厂ID
       */
      orgId?: number;
      /**
       * 工厂名称
       */
      organizationName?: string;
    }[];
    /**
     * 用户token
     */
    token?: string;
    /**
     * UUID
     */
    uuid?: string;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [登录接口↗](http://yapi.blacklake.tech/project/519/interface/api/72626) 的 **请求配置的类型**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/login`
 * @更新时间 `2022-01-24 10:44:10`
 */
type FetchLoginRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/login',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [登录接口↗](http://yapi.blacklake.tech/project/519/interface/api/72626) 的 **请求配置**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/login`
 * @更新时间 `2022-01-24 10:44:10`
 */
const fetchLoginRequestConfig: FetchLoginRequestConfig = {
  mockUrl: mockUrl_0_0_0_4,
  devUrl: devUrl_0_0_0_4,
  prodUrl: prodUrl_0_0_0_4,
  path: '/user/domain/web/v1/login',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_4,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchLogin',
};

/**
 * 接口 [登录接口↗](http://yapi.blacklake.tech/project/519/interface/api/72626) 的 **请求函数**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/login`
 * @更新时间 `2022-01-24 10:44:10`
 */
export const fetchLogin = (requestData: FetchLoginRequest, ...args: UserRequestRestArgs) => {
  return request<FetchLoginResponse>(prepare(fetchLoginRequestConfig, requestData), ...args);
};

fetchLogin.requestConfig = fetchLoginRequestConfig;

/**
 * 接口 [登出接口↗](http://yapi.blacklake.tech/project/519/interface/api/72632) 的 **请求类型**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/logout`
 * @更新时间 `2022-01-24 10:44:11`
 */
export interface FetchLogoutRequest {
  /**
   * token
   */
  token: string;
  /**
   * 用户ID
   */
  userId: number;
}

/**
 * 接口 [登出接口↗](http://yapi.blacklake.tech/project/519/interface/api/72632) 的 **返回类型**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/logout`
 * @更新时间 `2022-01-24 10:44:11`
 */
export type FetchLogoutResponse = any;

/**
 * 接口 [登出接口↗](http://yapi.blacklake.tech/project/519/interface/api/72632) 的 **请求配置的类型**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/logout`
 * @更新时间 `2022-01-24 10:44:11`
 */
type FetchLogoutRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/logout',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [登出接口↗](http://yapi.blacklake.tech/project/519/interface/api/72632) 的 **请求配置**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/logout`
 * @更新时间 `2022-01-24 10:44:11`
 */
const fetchLogoutRequestConfig: FetchLogoutRequestConfig = {
  mockUrl: mockUrl_0_0_0_4,
  devUrl: devUrl_0_0_0_4,
  prodUrl: prodUrl_0_0_0_4,
  path: '/user/domain/web/v1/logout',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.raw,
  dataKey: dataKey_0_0_0_4,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchLogout',
};

/**
 * 接口 [登出接口↗](http://yapi.blacklake.tech/project/519/interface/api/72632) 的 **请求函数**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/logout`
 * @更新时间 `2022-01-24 10:44:11`
 */
export const fetchLogout = (requestData: FetchLogoutRequest, ...args: UserRequestRestArgs) => {
  return request<FetchLogoutResponse>(prepare(fetchLogoutRequestConfig, requestData), ...args);
};

fetchLogout.requestConfig = fetchLogoutRequestConfig;

/**
 * 接口 [发送图形验证码↗](http://yapi.blacklake.tech/project/519/interface/api/72710) 的 **请求类型**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/send_captcha`
 * @更新时间 `2022-01-24 10:44:14`
 */
export interface FetchSendCaptchaRequest {
  /**
   * 短信验证码
   */
  code?: string;
  /**
   * 电话号码
   */
  phone?: string;
  /**
   * 是否需要强制发送短信验证码
   */
  sendMessage?: boolean;
}

/**
 * 接口 [发送图形验证码↗](http://yapi.blacklake.tech/project/519/interface/api/72710) 的 **返回类型**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/send_captcha`
 * @更新时间 `2022-01-24 10:44:14`
 */
export interface FetchSendCaptchaResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 图形验证码字节流
     */
    image?: string;
    /**
     * 存储在redis的校验码的key值
     */
    uuid?: string;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [发送图形验证码↗](http://yapi.blacklake.tech/project/519/interface/api/72710) 的 **请求配置的类型**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/send_captcha`
 * @更新时间 `2022-01-24 10:44:14`
 */
type FetchSendCaptchaRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/send_captcha',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [发送图形验证码↗](http://yapi.blacklake.tech/project/519/interface/api/72710) 的 **请求配置**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/send_captcha`
 * @更新时间 `2022-01-24 10:44:14`
 */
const fetchSendCaptchaRequestConfig: FetchSendCaptchaRequestConfig = {
  mockUrl: mockUrl_0_0_0_4,
  devUrl: devUrl_0_0_0_4,
  prodUrl: prodUrl_0_0_0_4,
  path: '/user/domain/web/v1/send_captcha',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_4,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchSendCaptcha',
};

/**
 * 接口 [发送图形验证码↗](http://yapi.blacklake.tech/project/519/interface/api/72710) 的 **请求函数**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/send_captcha`
 * @更新时间 `2022-01-24 10:44:14`
 */
export const fetchSendCaptcha = (
  requestData: FetchSendCaptchaRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchSendCaptchaResponse>(
    prepare(fetchSendCaptchaRequestConfig, requestData),
    ...args,
  );
};

fetchSendCaptcha.requestConfig = fetchSendCaptchaRequestConfig;

/**
 * 接口 [校验图形验证码，验证通过返回true并发送短信验证码，失败返回false↗](http://yapi.blacklake.tech/project/519/interface/api/72824) 的 **请求类型**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/verify_captcha`
 * @更新时间 `2022-01-24 10:44:19`
 */
export interface FetchVerifyCaptchaRequest {
  /**
   * 验证码
   */
  captchaCode: string;
  /**
   * 手机号
   */
  phone: string;
  /**
   * uuid
   */
  uuid: string;
}

/**
 * 接口 [校验图形验证码，验证通过返回true并发送短信验证码，失败返回false↗](http://yapi.blacklake.tech/project/519/interface/api/72824) 的 **返回类型**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/verify_captcha`
 * @更新时间 `2022-01-24 10:44:19`
 */
export interface FetchVerifyCaptchaResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 请求结果
     */
    flag?: boolean;
    /**
     * 短信验证码
     */
    sendCode?: string;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [校验图形验证码，验证通过返回true并发送短信验证码，失败返回false↗](http://yapi.blacklake.tech/project/519/interface/api/72824) 的 **请求配置的类型**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/verify_captcha`
 * @更新时间 `2022-01-24 10:44:19`
 */
type FetchVerifyCaptchaRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/verify_captcha',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [校验图形验证码，验证通过返回true并发送短信验证码，失败返回false↗](http://yapi.blacklake.tech/project/519/interface/api/72824) 的 **请求配置**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/verify_captcha`
 * @更新时间 `2022-01-24 10:44:19`
 */
const fetchVerifyCaptchaRequestConfig: FetchVerifyCaptchaRequestConfig = {
  mockUrl: mockUrl_0_0_0_4,
  devUrl: devUrl_0_0_0_4,
  prodUrl: prodUrl_0_0_0_4,
  path: '/user/domain/web/v1/verify_captcha',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_4,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchVerifyCaptcha',
};

/**
 * 接口 [校验图形验证码，验证通过返回true并发送短信验证码，失败返回false↗](http://yapi.blacklake.tech/project/519/interface/api/72824) 的 **请求函数**
 *
 * @分类 [单点登陆↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10798)
 * @标签 `单点登陆`
 * @请求头 `POST /user/domain/web/v1/verify_captcha`
 * @更新时间 `2022-01-24 10:44:19`
 */
export const fetchVerifyCaptcha = (
  requestData: FetchVerifyCaptchaRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchVerifyCaptchaResponse>(
    prepare(fetchVerifyCaptchaRequestConfig, requestData),
    ...args,
  );
};

fetchVerifyCaptcha.requestConfig = fetchVerifyCaptchaRequestConfig;

const mockUrl_0_0_0_5 = 'http://yapi.blacklake.tech/mock/519' as any;
const devUrl_0_0_0_5 = '' as any;
const prodUrl_0_0_0_5 = '' as any;
const dataKey_0_0_0_5 = undefined as any;

/**
 * 接口 [选项值批量新增↗](http://yapi.blacklake.tech/project/519/interface/api/72434) 的 **请求类型**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_bulk_create`
 * @更新时间 `2021-10-15 16:15:48`
 */
export type FetchChoiceValueBulkCreateRequest = {
  /**
   * 选项值
   */
  choiceValue: string;
  /**
   * 是否默认
   */
  isDefault: number;
  /**
   * 是否启用
   */
  isUsed: number;
  /**
   * 关联字段id
   */
  relatedFieldId: number;
  /**
   * 顺序值
   */
  sequence: number;
}[];

/**
 * 接口 [选项值批量新增↗](http://yapi.blacklake.tech/project/519/interface/api/72434) 的 **返回类型**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_bulk_create`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchChoiceValueBulkCreateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [选项值批量新增↗](http://yapi.blacklake.tech/project/519/interface/api/72434) 的 **请求配置的类型**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_bulk_create`
 * @更新时间 `2021-10-15 16:15:48`
 */
type FetchChoiceValueBulkCreateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/choice_value/_bulk_create',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [选项值批量新增↗](http://yapi.blacklake.tech/project/519/interface/api/72434) 的 **请求配置**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_bulk_create`
 * @更新时间 `2021-10-15 16:15:48`
 */
const fetchChoiceValueBulkCreateRequestConfig: FetchChoiceValueBulkCreateRequestConfig = {
  mockUrl: mockUrl_0_0_0_5,
  devUrl: devUrl_0_0_0_5,
  prodUrl: prodUrl_0_0_0_5,
  path: '/user/domain/web/v1/choice_value/_bulk_create',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_5,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchChoiceValueBulkCreate',
};

/**
 * 接口 [选项值批量新增↗](http://yapi.blacklake.tech/project/519/interface/api/72434) 的 **请求函数**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_bulk_create`
 * @更新时间 `2021-10-15 16:15:48`
 */
export const fetchChoiceValueBulkCreate = (
  requestData: FetchChoiceValueBulkCreateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchChoiceValueBulkCreateResponse>(
    prepare(fetchChoiceValueBulkCreateRequestConfig, requestData),
    ...args,
  );
};

fetchChoiceValueBulkCreate.requestConfig = fetchChoiceValueBulkCreateRequestConfig;

/**
 * 接口 [选项值新增↗](http://yapi.blacklake.tech/project/519/interface/api/72440) 的 **请求类型**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_create_choice_value`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchChoiceValueCreateChoiceValueRequest {
  /**
   * 选项值
   */
  choiceValue: string;
  /**
   * 是否默认
   */
  isDefault: number;
  /**
   * 是否启用
   */
  isUsed: number;
  /**
   * 关联字段id
   */
  relatedFieldId: number;
  /**
   * 顺序值
   */
  sequence: number;
}

/**
 * 接口 [选项值新增↗](http://yapi.blacklake.tech/project/519/interface/api/72440) 的 **返回类型**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_create_choice_value`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchChoiceValueCreateChoiceValueResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [选项值新增↗](http://yapi.blacklake.tech/project/519/interface/api/72440) 的 **请求配置的类型**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_create_choice_value`
 * @更新时间 `2021-10-15 16:15:48`
 */
type FetchChoiceValueCreateChoiceValueRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/choice_value/_create_choice_value',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [选项值新增↗](http://yapi.blacklake.tech/project/519/interface/api/72440) 的 **请求配置**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_create_choice_value`
 * @更新时间 `2021-10-15 16:15:48`
 */
const fetchChoiceValueCreateChoiceValueRequestConfig: FetchChoiceValueCreateChoiceValueRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_5,
    devUrl: devUrl_0_0_0_5,
    prodUrl: prodUrl_0_0_0_5,
    path: '/user/domain/web/v1/choice_value/_create_choice_value',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_5,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchChoiceValueCreateChoiceValue',
  };

/**
 * 接口 [选项值新增↗](http://yapi.blacklake.tech/project/519/interface/api/72440) 的 **请求函数**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_create_choice_value`
 * @更新时间 `2021-10-15 16:15:48`
 */
export const fetchChoiceValueCreateChoiceValue = (
  requestData: FetchChoiceValueCreateChoiceValueRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchChoiceValueCreateChoiceValueResponse>(
    prepare(fetchChoiceValueCreateChoiceValueRequestConfig, requestData),
    ...args,
  );
};

fetchChoiceValueCreateChoiceValue.requestConfig = fetchChoiceValueCreateChoiceValueRequestConfig;

/**
 * 接口 [选项值删除↗](http://yapi.blacklake.tech/project/519/interface/api/72446) 的 **请求类型**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_delete_choice_value`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchChoiceValueDeleteChoiceValueRequest {
  /**
   * 选项值id
   */
  id: number;
}

/**
 * 接口 [选项值删除↗](http://yapi.blacklake.tech/project/519/interface/api/72446) 的 **返回类型**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_delete_choice_value`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchChoiceValueDeleteChoiceValueResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [选项值删除↗](http://yapi.blacklake.tech/project/519/interface/api/72446) 的 **请求配置的类型**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_delete_choice_value`
 * @更新时间 `2021-10-15 16:15:48`
 */
type FetchChoiceValueDeleteChoiceValueRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/choice_value/_delete_choice_value',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [选项值删除↗](http://yapi.blacklake.tech/project/519/interface/api/72446) 的 **请求配置**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_delete_choice_value`
 * @更新时间 `2021-10-15 16:15:48`
 */
const fetchChoiceValueDeleteChoiceValueRequestConfig: FetchChoiceValueDeleteChoiceValueRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_5,
    devUrl: devUrl_0_0_0_5,
    prodUrl: prodUrl_0_0_0_5,
    path: '/user/domain/web/v1/choice_value/_delete_choice_value',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_5,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchChoiceValueDeleteChoiceValue',
  };

/**
 * 接口 [选项值删除↗](http://yapi.blacklake.tech/project/519/interface/api/72446) 的 **请求函数**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_delete_choice_value`
 * @更新时间 `2021-10-15 16:15:48`
 */
export const fetchChoiceValueDeleteChoiceValue = (
  requestData: FetchChoiceValueDeleteChoiceValueRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchChoiceValueDeleteChoiceValueResponse>(
    prepare(fetchChoiceValueDeleteChoiceValueRequestConfig, requestData),
    ...args,
  );
};

fetchChoiceValueDeleteChoiceValue.requestConfig = fetchChoiceValueDeleteChoiceValueRequestConfig;

/**
 * 接口 [根据字段id获取选项值列表↗](http://yapi.blacklake.tech/project/519/interface/api/72452) 的 **请求类型**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_list_by_field_id`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchChoiceValueListByFieldIdRequest {
  /**
   * 选项值
   */
  choiceValue?: string;
  /**
   * 创建时间
   */
  createdAt?: string;
  /**
   * 创建人id
   */
  creatorId?: number;
  /**
   * 字段id
   */
  fieldId: number;
  /**
   * 业务id
   */
  id?: number;
  /**
   * 是否默认
   */
  isDefault?: number;
  /**
   * 软删除标记
   */
  isDeleted?: number;
  /**
   * 是否需要停用选项 0 不需要停用字段 1 需要停用字段
   */
  isUsed: number;
  /**
   * 操作人id
   */
  operatorId?: number;
  /**
   * 工厂id
   */
  orgId?: number;
  /**
   * 相关字段id
   */
  relatedFieldId?: number;
  /**
   * 顺序值
   */
  sequence?: number;
  /**
   * 修改时间
   */
  updatedAt?: string;
}

/**
 * 接口 [根据字段id获取选项值列表↗](http://yapi.blacklake.tech/project/519/interface/api/72452) 的 **返回类型**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_list_by_field_id`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchChoiceValueListByFieldIdResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [根据字段id获取选项值列表↗](http://yapi.blacklake.tech/project/519/interface/api/72452) 的 **请求配置的类型**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_list_by_field_id`
 * @更新时间 `2021-10-15 16:15:48`
 */
type FetchChoiceValueListByFieldIdRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/choice_value/_list_by_field_id',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [根据字段id获取选项值列表↗](http://yapi.blacklake.tech/project/519/interface/api/72452) 的 **请求配置**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_list_by_field_id`
 * @更新时间 `2021-10-15 16:15:48`
 */
const fetchChoiceValueListByFieldIdRequestConfig: FetchChoiceValueListByFieldIdRequestConfig = {
  mockUrl: mockUrl_0_0_0_5,
  devUrl: devUrl_0_0_0_5,
  prodUrl: prodUrl_0_0_0_5,
  path: '/user/domain/web/v1/choice_value/_list_by_field_id',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_5,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchChoiceValueListByFieldId',
};

/**
 * 接口 [根据字段id获取选项值列表↗](http://yapi.blacklake.tech/project/519/interface/api/72452) 的 **请求函数**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_list_by_field_id`
 * @更新时间 `2021-10-15 16:15:48`
 */
export const fetchChoiceValueListByFieldId = (
  requestData: FetchChoiceValueListByFieldIdRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchChoiceValueListByFieldIdResponse>(
    prepare(fetchChoiceValueListByFieldIdRequestConfig, requestData),
    ...args,
  );
};

fetchChoiceValueListByFieldId.requestConfig = fetchChoiceValueListByFieldIdRequestConfig;

/**
 * 接口 [选项值修改↗](http://yapi.blacklake.tech/project/519/interface/api/72458) 的 **请求类型**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_update_choice_value`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchChoiceValueUpdateChoiceValueRequest {
  /**
   * 选项值
   */
  choiceValue: string;
  /**
   * 选项值id
   */
  id: number;
  /**
   * 是否默认
   */
  isDefault: number;
  /**
   * 是否启用
   */
  isUsed: number;
  /**
   * 字段id
   */
  relatedFieldId: number;
  /**
   * 顺序值
   */
  sequence: number;
}

/**
 * 接口 [选项值修改↗](http://yapi.blacklake.tech/project/519/interface/api/72458) 的 **返回类型**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_update_choice_value`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchChoiceValueUpdateChoiceValueResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [选项值修改↗](http://yapi.blacklake.tech/project/519/interface/api/72458) 的 **请求配置的类型**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_update_choice_value`
 * @更新时间 `2021-10-15 16:15:48`
 */
type FetchChoiceValueUpdateChoiceValueRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/choice_value/_update_choice_value',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [选项值修改↗](http://yapi.blacklake.tech/project/519/interface/api/72458) 的 **请求配置**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_update_choice_value`
 * @更新时间 `2021-10-15 16:15:48`
 */
const fetchChoiceValueUpdateChoiceValueRequestConfig: FetchChoiceValueUpdateChoiceValueRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_5,
    devUrl: devUrl_0_0_0_5,
    prodUrl: prodUrl_0_0_0_5,
    path: '/user/domain/web/v1/choice_value/_update_choice_value',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_5,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchChoiceValueUpdateChoiceValue',
  };

/**
 * 接口 [选项值修改↗](http://yapi.blacklake.tech/project/519/interface/api/72458) 的 **请求函数**
 *
 * @分类 [web端选项值接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10800)
 * @标签 `web端选项值接口`
 * @请求头 `POST /user/domain/web/v1/choice_value/_update_choice_value`
 * @更新时间 `2021-10-15 16:15:48`
 */
export const fetchChoiceValueUpdateChoiceValue = (
  requestData: FetchChoiceValueUpdateChoiceValueRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchChoiceValueUpdateChoiceValueResponse>(
    prepare(fetchChoiceValueUpdateChoiceValueRequestConfig, requestData),
    ...args,
  );
};

fetchChoiceValueUpdateChoiceValue.requestConfig = fetchChoiceValueUpdateChoiceValueRequestConfig;

const mockUrl_0_0_0_6 = 'http://yapi.blacklake.tech/mock/519' as any;
const devUrl_0_0_0_6 = '' as any;
const prodUrl_0_0_0_6 = '' as any;
const dataKey_0_0_0_6 = undefined as any;

/**
 * 接口 [获取短信验证码↗](http://yapi.blacklake.tech/project/519/interface/api/72716) 的 **请求类型**
 *
 * @分类 [短信验证↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10802)
 * @标签 `短信验证`
 * @请求头 `POST /user/domain/web/v1/sms/getCode`
 * @更新时间 `2022-01-24 10:44:15`
 */
export interface FetchSmsGetCodeRequest {
  /**
   * 短信验证码
   */
  code?: string;
  /**
   * 电话号码
   */
  phone?: string;
  /**
   * 是否需要强制发送短信验证码
   */
  sendMessage?: boolean;
}

/**
 * 接口 [获取短信验证码↗](http://yapi.blacklake.tech/project/519/interface/api/72716) 的 **返回类型**
 *
 * @分类 [短信验证↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10802)
 * @标签 `短信验证`
 * @请求头 `POST /user/domain/web/v1/sms/getCode`
 * @更新时间 `2022-01-24 10:44:15`
 */
export interface FetchSmsGetCodeResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 请求结果
     */
    flag?: boolean;
    /**
     * 短信验证码
     */
    sendCode?: string;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取短信验证码↗](http://yapi.blacklake.tech/project/519/interface/api/72716) 的 **请求配置的类型**
 *
 * @分类 [短信验证↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10802)
 * @标签 `短信验证`
 * @请求头 `POST /user/domain/web/v1/sms/getCode`
 * @更新时间 `2022-01-24 10:44:15`
 */
type FetchSmsGetCodeRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/sms/getCode',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [获取短信验证码↗](http://yapi.blacklake.tech/project/519/interface/api/72716) 的 **请求配置**
 *
 * @分类 [短信验证↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10802)
 * @标签 `短信验证`
 * @请求头 `POST /user/domain/web/v1/sms/getCode`
 * @更新时间 `2022-01-24 10:44:15`
 */
const fetchSmsGetCodeRequestConfig: FetchSmsGetCodeRequestConfig = {
  mockUrl: mockUrl_0_0_0_6,
  devUrl: devUrl_0_0_0_6,
  prodUrl: prodUrl_0_0_0_6,
  path: '/user/domain/web/v1/sms/getCode',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_6,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchSmsGetCode',
};

/**
 * 接口 [获取短信验证码↗](http://yapi.blacklake.tech/project/519/interface/api/72716) 的 **请求函数**
 *
 * @分类 [短信验证↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10802)
 * @标签 `短信验证`
 * @请求头 `POST /user/domain/web/v1/sms/getCode`
 * @更新时间 `2022-01-24 10:44:15`
 */
export const fetchSmsGetCode = (
  requestData: FetchSmsGetCodeRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchSmsGetCodeResponse>(
    prepare(fetchSmsGetCodeRequestConfig, requestData),
    ...args,
  );
};

fetchSmsGetCode.requestConfig = fetchSmsGetCodeRequestConfig;

/**
 * 接口 [验证短信验证码↗](http://yapi.blacklake.tech/project/519/interface/api/72722) 的 **请求类型**
 *
 * @分类 [短信验证↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10802)
 * @标签 `短信验证`
 * @请求头 `POST /user/domain/web/v1/sms/verifySmsCode`
 * @更新时间 `2022-01-24 10:44:15`
 */
export interface FetchSmsVerifySmsCodeRequest {
  /**
   * 短信验证码
   */
  code?: string;
  /**
   * 电话号码
   */
  phone?: string;
  /**
   * 是否需要强制发送短信验证码
   */
  sendMessage?: boolean;
}

/**
 * 接口 [验证短信验证码↗](http://yapi.blacklake.tech/project/519/interface/api/72722) 的 **返回类型**
 *
 * @分类 [短信验证↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10802)
 * @标签 `短信验证`
 * @请求头 `POST /user/domain/web/v1/sms/verifySmsCode`
 * @更新时间 `2022-01-24 10:44:15`
 */
export interface FetchSmsVerifySmsCodeResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: string;
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [验证短信验证码↗](http://yapi.blacklake.tech/project/519/interface/api/72722) 的 **请求配置的类型**
 *
 * @分类 [短信验证↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10802)
 * @标签 `短信验证`
 * @请求头 `POST /user/domain/web/v1/sms/verifySmsCode`
 * @更新时间 `2022-01-24 10:44:15`
 */
type FetchSmsVerifySmsCodeRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/sms/verifySmsCode',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [验证短信验证码↗](http://yapi.blacklake.tech/project/519/interface/api/72722) 的 **请求配置**
 *
 * @分类 [短信验证↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10802)
 * @标签 `短信验证`
 * @请求头 `POST /user/domain/web/v1/sms/verifySmsCode`
 * @更新时间 `2022-01-24 10:44:15`
 */
const fetchSmsVerifySmsCodeRequestConfig: FetchSmsVerifySmsCodeRequestConfig = {
  mockUrl: mockUrl_0_0_0_6,
  devUrl: devUrl_0_0_0_6,
  prodUrl: prodUrl_0_0_0_6,
  path: '/user/domain/web/v1/sms/verifySmsCode',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_6,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchSmsVerifySmsCode',
};

/**
 * 接口 [验证短信验证码↗](http://yapi.blacklake.tech/project/519/interface/api/72722) 的 **请求函数**
 *
 * @分类 [短信验证↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10802)
 * @标签 `短信验证`
 * @请求头 `POST /user/domain/web/v1/sms/verifySmsCode`
 * @更新时间 `2022-01-24 10:44:15`
 */
export const fetchSmsVerifySmsCode = (
  requestData: FetchSmsVerifySmsCodeRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchSmsVerifySmsCodeResponse>(
    prepare(fetchSmsVerifySmsCodeRequestConfig, requestData),
    ...args,
  );
};

fetchSmsVerifySmsCode.requestConfig = fetchSmsVerifySmsCodeRequestConfig;

/**
 * 接口 [重置密码的短信验证服务↗](http://yapi.blacklake.tech/project/519/interface/api/87776) 的 **请求类型**
 *
 * @分类 [短信验证↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10802)
 * @标签 `短信验证`
 * @请求头 `POST /user/domain/web/v1/sms/_verify_message`
 * @更新时间 `2022-01-24 10:44:14`
 */
export interface FetchSmsVerifyMessageRequest {
  /**
   * 短信验证码
   */
  code?: string;
  /**
   * 电话号码
   */
  phone?: string;
  /**
   * 是否需要强制发送短信验证码
   */
  sendMessage?: boolean;
}

/**
 * 接口 [重置密码的短信验证服务↗](http://yapi.blacklake.tech/project/519/interface/api/87776) 的 **返回类型**
 *
 * @分类 [短信验证↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10802)
 * @标签 `短信验证`
 * @请求头 `POST /user/domain/web/v1/sms/_verify_message`
 * @更新时间 `2022-01-24 10:44:14`
 */
export interface FetchSmsVerifyMessageResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: string;
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [重置密码的短信验证服务↗](http://yapi.blacklake.tech/project/519/interface/api/87776) 的 **请求配置的类型**
 *
 * @分类 [短信验证↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10802)
 * @标签 `短信验证`
 * @请求头 `POST /user/domain/web/v1/sms/_verify_message`
 * @更新时间 `2022-01-24 10:44:14`
 */
type FetchSmsVerifyMessageRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/sms/_verify_message',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [重置密码的短信验证服务↗](http://yapi.blacklake.tech/project/519/interface/api/87776) 的 **请求配置**
 *
 * @分类 [短信验证↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10802)
 * @标签 `短信验证`
 * @请求头 `POST /user/domain/web/v1/sms/_verify_message`
 * @更新时间 `2022-01-24 10:44:14`
 */
const fetchSmsVerifyMessageRequestConfig: FetchSmsVerifyMessageRequestConfig = {
  mockUrl: mockUrl_0_0_0_6,
  devUrl: devUrl_0_0_0_6,
  prodUrl: prodUrl_0_0_0_6,
  path: '/user/domain/web/v1/sms/_verify_message',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_6,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchSmsVerifyMessage',
};

/**
 * 接口 [重置密码的短信验证服务↗](http://yapi.blacklake.tech/project/519/interface/api/87776) 的 **请求函数**
 *
 * @分类 [短信验证↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10802)
 * @标签 `短信验证`
 * @请求头 `POST /user/domain/web/v1/sms/_verify_message`
 * @更新时间 `2022-01-24 10:44:14`
 */
export const fetchSmsVerifyMessage = (
  requestData: FetchSmsVerifyMessageRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchSmsVerifyMessageResponse>(
    prepare(fetchSmsVerifyMessageRequestConfig, requestData),
    ...args,
  );
};

fetchSmsVerifyMessage.requestConfig = fetchSmsVerifyMessageRequestConfig;

const mockUrl_0_0_0_7 = 'http://yapi.blacklake.tech/mock/519' as any;
const devUrl_0_0_0_7 = '' as any;
const prodUrl_0_0_0_7 = '' as any;
const dataKey_0_0_0_7 = undefined as any;

/**
 * 接口 [更新数据权限↗](http://yapi.blacklake.tech/project/519/interface/api/72524) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/data/privilege/_upsert`
 * @更新时间 `2022-01-24 10:44:06`
 */
export interface FetchDataPrivilegeUpsertRequest {
  items?: {
    /**
     * 对象id
     */
    bizObjectId?: number;
    /**
     * 字段id
     */
    fieldId?: number;
    /**
     * 逻辑操作符
     */
    logicOperation?: string;
    /**
     * 操作符
     */
    operation?: string;
    /**
     * 操作符的值
     */
    value?: string;
  }[];
  roleId?: number;
}

/**
 * 接口 [更新数据权限↗](http://yapi.blacklake.tech/project/519/interface/api/72524) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/data/privilege/_upsert`
 * @更新时间 `2022-01-24 10:44:06`
 */
export interface FetchDataPrivilegeUpsertResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [更新数据权限↗](http://yapi.blacklake.tech/project/519/interface/api/72524) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/data/privilege/_upsert`
 * @更新时间 `2022-01-24 10:44:06`
 */
type FetchDataPrivilegeUpsertRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/data/privilege/_upsert',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [更新数据权限↗](http://yapi.blacklake.tech/project/519/interface/api/72524) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/data/privilege/_upsert`
 * @更新时间 `2022-01-24 10:44:06`
 */
const fetchDataPrivilegeUpsertRequestConfig: FetchDataPrivilegeUpsertRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/data/privilege/_upsert',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDataPrivilegeUpsert',
};

/**
 * 接口 [更新数据权限↗](http://yapi.blacklake.tech/project/519/interface/api/72524) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/data/privilege/_upsert`
 * @更新时间 `2022-01-24 10:44:06`
 */
export const fetchDataPrivilegeUpsert = (
  requestData: FetchDataPrivilegeUpsertRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDataPrivilegeUpsertResponse>(
    prepare(fetchDataPrivilegeUpsertRequestConfig, requestData),
    ...args,
  );
};

fetchDataPrivilegeUpsert.requestConfig = fetchDataPrivilegeUpsertRequestConfig;

/**
 * 接口 [根据业务对象id获取数据权限↗](http://yapi.blacklake.tech/project/519/interface/api/72530) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/data/privilege/listByBizObjectId`
 * @更新时间 `2022-01-24 10:44:07`
 */
export interface FetchDataPrivilegeListByBizObjectIdRequest {
  bizObjectId?: number;
  roleId?: number;
}

/**
 * 接口 [根据业务对象id获取数据权限↗](http://yapi.blacklake.tech/project/519/interface/api/72530) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/data/privilege/listByBizObjectId`
 * @更新时间 `2022-01-24 10:44:07`
 */
export interface FetchDataPrivilegeListByBizObjectIdResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 对象编号
     */
    bizObjectCode?: string;
    /**
     * 对象id
     */
    bizObjectId?: number;
    /**
     * 对象名称
     */
    bizObjectName?: string;
    /**
     * 对象的预设字段
     */
    fields?: {
      /**
       * 对象字段编号
       */
      fieldCode?: string;
      /**
       * 对象字段id
       */
      fieldId?: number;
      /**
       * 对象字段名称
       */
      fieldName?: string;
      /**
       * 字段对应的筛选类型
       */
      filterType?: string;
    }[];
    /**
     * 对象数据权限
     */
    list?: {
      /**
       * 对象字段id
       */
      fieldId?: number;
      /**
       * 逻辑操作符
       */
      logicOperation?: string;
      /**
       * 操作符
       */
      operation?: string;
      /**
       * 操作值
       */
      value?: string;
    }[];
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [根据业务对象id获取数据权限↗](http://yapi.blacklake.tech/project/519/interface/api/72530) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/data/privilege/listByBizObjectId`
 * @更新时间 `2022-01-24 10:44:07`
 */
type FetchDataPrivilegeListByBizObjectIdRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/data/privilege/listByBizObjectId',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [根据业务对象id获取数据权限↗](http://yapi.blacklake.tech/project/519/interface/api/72530) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/data/privilege/listByBizObjectId`
 * @更新时间 `2022-01-24 10:44:07`
 */
const fetchDataPrivilegeListByBizObjectIdRequestConfig: FetchDataPrivilegeListByBizObjectIdRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_7,
    devUrl: devUrl_0_0_0_7,
    prodUrl: prodUrl_0_0_0_7,
    path: '/user/domain/web/v1/data/privilege/listByBizObjectId',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_7,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchDataPrivilegeListByBizObjectId',
  };

/**
 * 接口 [根据业务对象id获取数据权限↗](http://yapi.blacklake.tech/project/519/interface/api/72530) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/data/privilege/listByBizObjectId`
 * @更新时间 `2022-01-24 10:44:07`
 */
export const fetchDataPrivilegeListByBizObjectId = (
  requestData: FetchDataPrivilegeListByBizObjectIdRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDataPrivilegeListByBizObjectIdResponse>(
    prepare(fetchDataPrivilegeListByBizObjectIdRequestConfig, requestData),
    ...args,
  );
};

fetchDataPrivilegeListByBizObjectId.requestConfig =
  fetchDataPrivilegeListByBizObjectIdRequestConfig;

/**
 * 接口 [获取对象列表↗](http://yapi.blacklake.tech/project/519/interface/api/72536) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/data/privilege/listObjectCodes`
 * @更新时间 `2022-01-24 10:44:07`
 */
export interface FetchDataPrivilegeListObjectCodesRequest {
  roleId?: number;
}

/**
 * 接口 [获取对象列表↗](http://yapi.blacklake.tech/project/519/interface/api/72536) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/data/privilege/listObjectCodes`
 * @更新时间 `2022-01-24 10:44:07`
 */
export interface FetchDataPrivilegeListObjectCodesResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 业务对象编号
     */
    bizObjectCode?: string;
    /**
     * 业务对象id
     */
    bizObjectId?: number;
    /**
     * 业务对象名称
     */
    bizObjectName?: string;
    /**
     * 数据权限
     */
    dataPrivilegeFields?: {
      /**
       * 对象字段id
       */
      fieldId?: number;
      /**
       * 逻辑操作符
       */
      logicOperation?: string;
      /**
       * 操作符
       */
      operation?: string;
      /**
       * 操作值
       */
      value?: string;
    }[];
  }[];
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取对象列表↗](http://yapi.blacklake.tech/project/519/interface/api/72536) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/data/privilege/listObjectCodes`
 * @更新时间 `2022-01-24 10:44:07`
 */
type FetchDataPrivilegeListObjectCodesRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/data/privilege/listObjectCodes',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [获取对象列表↗](http://yapi.blacklake.tech/project/519/interface/api/72536) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/data/privilege/listObjectCodes`
 * @更新时间 `2022-01-24 10:44:07`
 */
const fetchDataPrivilegeListObjectCodesRequestConfig: FetchDataPrivilegeListObjectCodesRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_7,
    devUrl: devUrl_0_0_0_7,
    prodUrl: prodUrl_0_0_0_7,
    path: '/user/domain/web/v1/data/privilege/listObjectCodes',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_7,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchDataPrivilegeListObjectCodes',
  };

/**
 * 接口 [获取对象列表↗](http://yapi.blacklake.tech/project/519/interface/api/72536) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/data/privilege/listObjectCodes`
 * @更新时间 `2022-01-24 10:44:07`
 */
export const fetchDataPrivilegeListObjectCodes = (
  requestData: FetchDataPrivilegeListObjectCodesRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDataPrivilegeListObjectCodesResponse>(
    prepare(fetchDataPrivilegeListObjectCodesRequestConfig, requestData),
    ...args,
  );
};

fetchDataPrivilegeListObjectCodes.requestConfig = fetchDataPrivilegeListObjectCodesRequestConfig;

/**
 * 接口 [创建部门↗](http://yapi.blacklake.tech/project/519/interface/api/72542) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/create`
 * @更新时间 `2022-01-24 10:44:07`
 */
export interface FetchDepartmentCreateRequest {
  /**
   * 部门编号
   */
  code: string;
  /**
   * 备注
   */
  desc?: string;
  /**
   * 部门名称
   */
  name: string;
  /**
   * 部门负责人
   */
  ownerId?: number;
  /**
   * 父部门id
   */
  parentId: number;
}

/**
 * 接口 [创建部门↗](http://yapi.blacklake.tech/project/519/interface/api/72542) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/create`
 * @更新时间 `2022-01-24 10:44:07`
 */
export interface FetchDepartmentCreateResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 编号
     */
    code?: string;
    /**
     * 创建时间
     */
    createdAt?: string;
    creator?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    /**
     * 备注
     */
    desc?: string;
    /**
     * id
     */
    id?: number;
    /**
     * 名称
     */
    name?: string;
    operator?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    /**
     * 工厂id
     */
    orgId?: number;
    owner?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    /**
     * 父部门
     */
    parentDepartment?: {
      [k: string]: unknown;
    };
    /**
     * 更新时间
     */
    updatedAt?: string;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [创建部门↗](http://yapi.blacklake.tech/project/519/interface/api/72542) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/create`
 * @更新时间 `2022-01-24 10:44:07`
 */
type FetchDepartmentCreateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/department/create',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [创建部门↗](http://yapi.blacklake.tech/project/519/interface/api/72542) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/create`
 * @更新时间 `2022-01-24 10:44:07`
 */
const fetchDepartmentCreateRequestConfig: FetchDepartmentCreateRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/department/create',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDepartmentCreate',
};

/**
 * 接口 [创建部门↗](http://yapi.blacklake.tech/project/519/interface/api/72542) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/create`
 * @更新时间 `2022-01-24 10:44:07`
 */
export const fetchDepartmentCreate = (
  requestData: FetchDepartmentCreateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDepartmentCreateResponse>(
    prepare(fetchDepartmentCreateRequestConfig, requestData),
    ...args,
  );
};

fetchDepartmentCreate.requestConfig = fetchDepartmentCreateRequestConfig;

/**
 * 接口 [删除部门↗](http://yapi.blacklake.tech/project/519/interface/api/72548) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/delete`
 * @更新时间 `2022-01-24 10:44:07`
 */
export interface FetchDepartmentDeleteRequest {
  /**
   * 部门id
   */
  id: number;
}

/**
 * 接口 [删除部门↗](http://yapi.blacklake.tech/project/519/interface/api/72548) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/delete`
 * @更新时间 `2022-01-24 10:44:07`
 */
export interface FetchDepartmentDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [删除部门↗](http://yapi.blacklake.tech/project/519/interface/api/72548) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/delete`
 * @更新时间 `2022-01-24 10:44:07`
 */
type FetchDepartmentDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/department/delete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [删除部门↗](http://yapi.blacklake.tech/project/519/interface/api/72548) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/delete`
 * @更新时间 `2022-01-24 10:44:07`
 */
const fetchDepartmentDeleteRequestConfig: FetchDepartmentDeleteRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/department/delete',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDepartmentDelete',
};

/**
 * 接口 [删除部门↗](http://yapi.blacklake.tech/project/519/interface/api/72548) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/delete`
 * @更新时间 `2022-01-24 10:44:07`
 */
export const fetchDepartmentDelete = (
  requestData: FetchDepartmentDeleteRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDepartmentDeleteResponse>(
    prepare(fetchDepartmentDeleteRequestConfig, requestData),
    ...args,
  );
};

fetchDepartmentDelete.requestConfig = fetchDepartmentDeleteRequestConfig;

/**
 * 接口 [部门或用户 名称模糊查询↗](http://yapi.blacklake.tech/project/519/interface/api/72554) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/department_or_user_query`
 * @更新时间 `2022-01-24 10:44:07`
 */
export interface FetchDepartmentDepartmentOrUserQueryRequest {
  /**
   * 部门或用户名称
   */
  name: string;
}

/**
 * 接口 [部门或用户 名称模糊查询↗](http://yapi.blacklake.tech/project/519/interface/api/72554) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/department_or_user_query`
 * @更新时间 `2022-01-24 10:44:07`
 */
export interface FetchDepartmentDepartmentOrUserQueryResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 部门列表
     */
    departments?: {
      /**
       * 编号
       */
      code?: string;
      /**
       * 备注
       */
      desc?: string;
      /**
       * id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
      /**
       * 工厂id
       */
      orgId?: number;
    }[];
    /**
     * 用户列表
     */
    users?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    }[];
  }[];
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [部门或用户 名称模糊查询↗](http://yapi.blacklake.tech/project/519/interface/api/72554) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/department_or_user_query`
 * @更新时间 `2022-01-24 10:44:07`
 */
type FetchDepartmentDepartmentOrUserQueryRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/department/department_or_user_query',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [部门或用户 名称模糊查询↗](http://yapi.blacklake.tech/project/519/interface/api/72554) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/department_or_user_query`
 * @更新时间 `2022-01-24 10:44:07`
 */
const fetchDepartmentDepartmentOrUserQueryRequestConfig: FetchDepartmentDepartmentOrUserQueryRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_7,
    devUrl: devUrl_0_0_0_7,
    prodUrl: prodUrl_0_0_0_7,
    path: '/user/domain/web/v1/department/department_or_user_query',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_7,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchDepartmentDepartmentOrUserQuery',
  };

/**
 * 接口 [部门或用户 名称模糊查询↗](http://yapi.blacklake.tech/project/519/interface/api/72554) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/department_or_user_query`
 * @更新时间 `2022-01-24 10:44:07`
 */
export const fetchDepartmentDepartmentOrUserQuery = (
  requestData: FetchDepartmentDepartmentOrUserQueryRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDepartmentDepartmentOrUserQueryResponse>(
    prepare(fetchDepartmentDepartmentOrUserQueryRequestConfig, requestData),
    ...args,
  );
};

fetchDepartmentDepartmentOrUserQuery.requestConfig =
  fetchDepartmentDepartmentOrUserQueryRequestConfig;

/**
 * 接口 [部门详情查询↗](http://yapi.blacklake.tech/project/519/interface/api/72560) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/detail`
 * @更新时间 `2022-01-24 10:44:08`
 */
export interface FetchDepartmentDetailRequest {
  /**
   * 部门id
   */
  id: number;
}

/**
 * 接口 [部门详情查询↗](http://yapi.blacklake.tech/project/519/interface/api/72560) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/detail`
 * @更新时间 `2022-01-24 10:44:08`
 */
export interface FetchDepartmentDetailResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 编号
     */
    code?: string;
    /**
     * 创建时间
     */
    createdAt?: string;
    creator?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    /**
     * 备注
     */
    desc?: string;
    /**
     * id
     */
    id?: number;
    /**
     * 名称
     */
    name?: string;
    operator?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    /**
     * 工厂id
     */
    orgId?: number;
    owner?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    parentDepartment?: {
      /**
       * 编号
       */
      code?: string;
      /**
       * 备注
       */
      desc?: string;
      /**
       * id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
      /**
       * 工厂id
       */
      orgId?: number;
    };
    /**
     * 更新时间
     */
    updatedAt?: string;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [部门详情查询↗](http://yapi.blacklake.tech/project/519/interface/api/72560) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/detail`
 * @更新时间 `2022-01-24 10:44:08`
 */
type FetchDepartmentDetailRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/department/detail',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [部门详情查询↗](http://yapi.blacklake.tech/project/519/interface/api/72560) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/detail`
 * @更新时间 `2022-01-24 10:44:08`
 */
const fetchDepartmentDetailRequestConfig: FetchDepartmentDetailRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/department/detail',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDepartmentDetail',
};

/**
 * 接口 [部门详情查询↗](http://yapi.blacklake.tech/project/519/interface/api/72560) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/detail`
 * @更新时间 `2022-01-24 10:44:08`
 */
export const fetchDepartmentDetail = (
  requestData: FetchDepartmentDetailRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDepartmentDetailResponse>(
    prepare(fetchDepartmentDetailRequestConfig, requestData),
    ...args,
  );
};

fetchDepartmentDetail.requestConfig = fetchDepartmentDetailRequestConfig;

/**
 * 接口 [部门列表↗](http://yapi.blacklake.tech/project/519/interface/api/72566) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/list`
 * @更新时间 `2022-01-24 10:44:08`
 */
export interface FetchDepartmentListRequest {}

/**
 * 接口 [部门列表↗](http://yapi.blacklake.tech/project/519/interface/api/72566) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/list`
 * @更新时间 `2022-01-24 10:44:08`
 */
export interface FetchDepartmentListResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 部门下全部用户列表（包含子部门用户）
     */
    allUsers?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    }[];
    /**
     * 子部门列表
     */
    childDepartments?: unknown[];
    /**
     * 编号
     */
    code?: string;
    /**
     * 是否有下级部门
     */
    hasChild?: boolean;
    /**
     * id
     */
    id?: number;
    /**
     * 名称
     */
    name?: string;
    /**
     * 工厂id
     */
    orgId?: number;
    owner?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    /**
     * 部门用户列表（不包含子部门用户）
     */
    users?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    }[];
  }[];
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [部门列表↗](http://yapi.blacklake.tech/project/519/interface/api/72566) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/list`
 * @更新时间 `2022-01-24 10:44:08`
 */
type FetchDepartmentListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/department/list',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [部门列表↗](http://yapi.blacklake.tech/project/519/interface/api/72566) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/list`
 * @更新时间 `2022-01-24 10:44:08`
 */
const fetchDepartmentListRequestConfig: FetchDepartmentListRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/department/list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDepartmentList',
};

/**
 * 接口 [部门列表↗](http://yapi.blacklake.tech/project/519/interface/api/72566) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/list`
 * @更新时间 `2022-01-24 10:44:08`
 */
export const fetchDepartmentList = (
  requestData?: FetchDepartmentListRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDepartmentListResponse>(
    prepare(fetchDepartmentListRequestConfig, requestData),
    ...args,
  );
};

fetchDepartmentList.requestConfig = fetchDepartmentListRequestConfig;

/**
 * 接口 [直接子部门列表，id为空时返回【全公司】↗](http://yapi.blacklake.tech/project/519/interface/api/72572) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/list_child`
 * @更新时间 `2022-01-24 10:44:08`
 */
export interface FetchDepartmentListChildRequest {
  /**
   * 部门id，id为空时查询【全公司】
   */
  id?: number;
  /**
   * 返回的用户状态筛选（1:启用中）
   */
  userActive?: number;
  /**
   * 返回的用户多状态筛选（默认[]）
   * userActives 用 userActives生效；如果没有就去查userAcitve;如果userActive也没有，默认返回启用中的数据
   */
  userActives?: number[];
}

/**
 * 接口 [直接子部门列表，id为空时返回【全公司】↗](http://yapi.blacklake.tech/project/519/interface/api/72572) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/list_child`
 * @更新时间 `2022-01-24 10:44:08`
 */
export interface FetchDepartmentListChildResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 部门下全部用户列表（包含子部门用户）
     */
    allUsers?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    }[];
    /**
     * 子部门列表
     */
    childDepartments?: unknown[];
    /**
     * 编号
     */
    code?: string;
    /**
     * 是否有下级部门
     */
    hasChild?: boolean;
    /**
     * id
     */
    id?: number;
    /**
     * 名称
     */
    name?: string;
    /**
     * 工厂id
     */
    orgId?: number;
    owner?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    /**
     * 部门用户列表（不包含子部门用户）
     */
    users?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    }[];
  }[];
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [直接子部门列表，id为空时返回【全公司】↗](http://yapi.blacklake.tech/project/519/interface/api/72572) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/list_child`
 * @更新时间 `2022-01-24 10:44:08`
 */
type FetchDepartmentListChildRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/department/list_child',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [直接子部门列表，id为空时返回【全公司】↗](http://yapi.blacklake.tech/project/519/interface/api/72572) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/list_child`
 * @更新时间 `2022-01-24 10:44:08`
 */
const fetchDepartmentListChildRequestConfig: FetchDepartmentListChildRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/department/list_child',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDepartmentListChild',
};

/**
 * 接口 [直接子部门列表，id为空时返回【全公司】↗](http://yapi.blacklake.tech/project/519/interface/api/72572) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/list_child`
 * @更新时间 `2022-01-24 10:44:08`
 */
export const fetchDepartmentListChild = (
  requestData: FetchDepartmentListChildRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDepartmentListChildResponse>(
    prepare(fetchDepartmentListChildRequestConfig, requestData),
    ...args,
  );
};

fetchDepartmentListChild.requestConfig = fetchDepartmentListChildRequestConfig;

/**
 * 接口 [修改部门↗](http://yapi.blacklake.tech/project/519/interface/api/72578) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/update`
 * @更新时间 `2022-01-24 10:44:08`
 */
export interface FetchDepartmentUpdateRequest {
  /**
   * 部门编号
   */
  code: string;
  /**
   * 备注
   */
  desc?: string;
  /**
   * 编辑原因
   */
  editReason?: string;
  /**
   * 部门id
   */
  id: number;
  /**
   * 部门名称
   */
  name: string;
  /**
   * 部门负责人
   */
  ownerId?: number;
  /**
   * 父部门id
   */
  parentId: number;
}

/**
 * 接口 [修改部门↗](http://yapi.blacklake.tech/project/519/interface/api/72578) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/update`
 * @更新时间 `2022-01-24 10:44:08`
 */
export interface FetchDepartmentUpdateResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 编号
     */
    code?: string;
    /**
     * 创建时间
     */
    createdAt?: string;
    /**
     * 备注
     */
    desc?: string;
    /**
     * id
     */
    id?: number;
    /**
     * 名称
     */
    name?: string;
    /**
     * 工厂id
     */
    orgId?: number;
    owner?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    /**
     * 父部门
     */
    parentDepartment?: {
      [k: string]: unknown;
    };
    /**
     * 更新时间
     */
    updatedAt?: string;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [修改部门↗](http://yapi.blacklake.tech/project/519/interface/api/72578) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/update`
 * @更新时间 `2022-01-24 10:44:08`
 */
type FetchDepartmentUpdateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/department/update',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [修改部门↗](http://yapi.blacklake.tech/project/519/interface/api/72578) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/update`
 * @更新时间 `2022-01-24 10:44:08`
 */
const fetchDepartmentUpdateRequestConfig: FetchDepartmentUpdateRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/department/update',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDepartmentUpdate',
};

/**
 * 接口 [修改部门↗](http://yapi.blacklake.tech/project/519/interface/api/72578) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/update`
 * @更新时间 `2022-01-24 10:44:08`
 */
export const fetchDepartmentUpdate = (
  requestData: FetchDepartmentUpdateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDepartmentUpdateResponse>(
    prepare(fetchDepartmentUpdateRequestConfig, requestData),
    ...args,
  );
};

fetchDepartmentUpdate.requestConfig = fetchDepartmentUpdateRequestConfig;

/**
 * 接口 [查询部门下用户列表(不分页)↗](http://yapi.blacklake.tech/project/519/interface/api/72584) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/user_list`
 * @更新时间 `2022-01-24 10:44:08`
 */
export interface FetchDepartmentUserListRequest {
  /**
   * 部门id
   */
  id: number;
}

/**
 * 接口 [查询部门下用户列表(不分页)↗](http://yapi.blacklake.tech/project/519/interface/api/72584) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/user_list`
 * @更新时间 `2022-01-24 10:44:08`
 */
export interface FetchDepartmentUserListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 编号
     */
    code?: string;
    /**
     * id
     */
    id?: number;
    /**
     * 名称
     */
    name?: string;
    /**
     * 工厂id
     */
    orgId?: number;
    owner?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    /**
     * 部门下用户列表
     */
    users?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    }[];
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [查询部门下用户列表(不分页)↗](http://yapi.blacklake.tech/project/519/interface/api/72584) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/user_list`
 * @更新时间 `2022-01-24 10:44:08`
 */
type FetchDepartmentUserListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/department/user_list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [查询部门下用户列表(不分页)↗](http://yapi.blacklake.tech/project/519/interface/api/72584) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/user_list`
 * @更新时间 `2022-01-24 10:44:08`
 */
const fetchDepartmentUserListRequestConfig: FetchDepartmentUserListRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/department/user_list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDepartmentUserList',
};

/**
 * 接口 [查询部门下用户列表(不分页)↗](http://yapi.blacklake.tech/project/519/interface/api/72584) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/user_list`
 * @更新时间 `2022-01-24 10:44:08`
 */
export const fetchDepartmentUserList = (
  requestData: FetchDepartmentUserListRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDepartmentUserListResponse>(
    prepare(fetchDepartmentUserListRequestConfig, requestData),
    ...args,
  );
};

fetchDepartmentUserList.requestConfig = fetchDepartmentUserListRequestConfig;

/**
 * 接口 [新增用户↗](http://yapi.blacklake.tech/project/519/interface/api/72740) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/add`
 * @更新时间 `2022-01-24 10:44:16`
 */
export interface FetchUserAddRequest {
  /**
   * 用户附件
   */
  attachments?: number[];
  /**
   * 用户头像
   */
  avatarUrl?: string;
  /**
   * 用户编号
   */
  code: string;
  customFieldItems?: {
    id?: number;
    value?: {};
  }[];
  /**
   * 部门ID集合
   */
  departmentIds: number[];
  /**
   * 备注
   */
  desc?: string;
  /**
   * 用户邮箱
   */
  email?: string;
  /**
   * 用户名称
   */
  name: string;
  /**
   * 新用户随机密码
   */
  password: string;
  /**
   * 手机号码
   */
  phone?: string;
  /**
   * 角色ID集合
   */
  roleIds?: number[];
  /**
   * 直属上级
   */
  superior?: number;
  /**
   * 账号类型 1:app, 2:web+app, 3:tv
   */
  terminal: string;
  /**
   * 账号
   */
  username: string;
}

/**
 * 接口 [新增用户↗](http://yapi.blacklake.tech/project/519/interface/api/72740) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/add`
 * @更新时间 `2022-01-24 10:44:16`
 */
export interface FetchUserAddResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [新增用户↗](http://yapi.blacklake.tech/project/519/interface/api/72740) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/add`
 * @更新时间 `2022-01-24 10:44:16`
 */
type FetchUserAddRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/add',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [新增用户↗](http://yapi.blacklake.tech/project/519/interface/api/72740) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/add`
 * @更新时间 `2022-01-24 10:44:16`
 */
const fetchUserAddRequestConfig: FetchUserAddRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/add',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserAdd',
};

/**
 * 接口 [新增用户↗](http://yapi.blacklake.tech/project/519/interface/api/72740) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/add`
 * @更新时间 `2022-01-24 10:44:16`
 */
export const fetchUserAdd = (requestData: FetchUserAddRequest, ...args: UserRequestRestArgs) => {
  return request<FetchUserAddResponse>(prepare(fetchUserAddRequestConfig, requestData), ...args);
};

fetchUserAdd.requestConfig = fetchUserAddRequestConfig;

/**
 * 接口 [批量新增用户↗](http://yapi.blacklake.tech/project/519/interface/api/72746) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/bulkAdd`
 * @更新时间 `2022-01-24 10:44:17`
 */
export type FetchUserBulkAddRequest = {
  /**
   * 用户附件
   */
  attachments?: number[];
  /**
   * 用户头像
   */
  avatarUrl?: string;
  /**
   * 用户编号
   */
  code: string;
  customFieldItems?: {
    id?: number;
    value?: {};
  }[];
  /**
   * 部门ID集合
   */
  departmentIds: number[];
  /**
   * 备注
   */
  desc?: string;
  /**
   * 用户邮箱
   */
  email?: string;
  /**
   * 用户名称
   */
  name: string;
  /**
   * 新用户随机密码
   */
  password: string;
  /**
   * 手机号码
   */
  phone?: string;
  /**
   * 角色ID集合
   */
  roleIds?: number[];
  /**
   * 直属上级
   */
  superior?: number;
  /**
   * 账号类型 1:app, 2:web+app, 3:tv
   */
  terminal: string;
  /**
   * 账号
   */
  username: string;
}[];

/**
 * 接口 [批量新增用户↗](http://yapi.blacklake.tech/project/519/interface/api/72746) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/bulkAdd`
 * @更新时间 `2022-01-24 10:44:17`
 */
export interface FetchUserBulkAddResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量新增用户↗](http://yapi.blacklake.tech/project/519/interface/api/72746) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/bulkAdd`
 * @更新时间 `2022-01-24 10:44:17`
 */
type FetchUserBulkAddRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/bulkAdd',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量新增用户↗](http://yapi.blacklake.tech/project/519/interface/api/72746) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/bulkAdd`
 * @更新时间 `2022-01-24 10:44:17`
 */
const fetchUserBulkAddRequestConfig: FetchUserBulkAddRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/bulkAdd',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserBulkAdd',
};

/**
 * 接口 [批量新增用户↗](http://yapi.blacklake.tech/project/519/interface/api/72746) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/bulkAdd`
 * @更新时间 `2022-01-24 10:44:17`
 */
export const fetchUserBulkAdd = (
  requestData: FetchUserBulkAddRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserBulkAddResponse>(
    prepare(fetchUserBulkAddRequestConfig, requestData),
    ...args,
  );
};

fetchUserBulkAdd.requestConfig = fetchUserBulkAddRequestConfig;

/**
 * 接口 [批量更新用户↗](http://yapi.blacklake.tech/project/519/interface/api/72752) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/bulkUpdate`
 * @更新时间 `2022-01-24 10:44:17`
 */
export type FetchUserBulkUpdateRequest = {
  /**
   * 附件
   */
  attachments?: number[];
  /**
   * 头像
   */
  avatarUrl?: string;
  /**
   * 用户编号
   */
  code: string;
  customFieldItems?: {
    id?: number;
    value?: {};
  }[];
  /**
   * 部门ID集合
   */
  departmentIds: number[];
  /**
   * 备注
   */
  desc?: string;
  /**
   * 邮箱
   */
  email?: string;
  /**
   * 目标用户ID
   */
  id: number;
  /**
   * 用户名称
   */
  name: string;
  /**
   * 电话号码
   */
  phone?: string;
  /**
   * 编辑原因
   */
  reason?: string;
  /**
   * 角色ID集合
   */
  roleIds?: number[];
  /**
   * 直属上级
   */
  superior?: number;
  /**
   * 账号类型 0：默认, 1：app, 2:web+app, 3:tv
   */
  terminal: string;
  /**
   * 账号
   */
  username: string;
}[];

/**
 * 接口 [批量更新用户↗](http://yapi.blacklake.tech/project/519/interface/api/72752) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/bulkUpdate`
 * @更新时间 `2022-01-24 10:44:17`
 */
export interface FetchUserBulkUpdateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量更新用户↗](http://yapi.blacklake.tech/project/519/interface/api/72752) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/bulkUpdate`
 * @更新时间 `2022-01-24 10:44:17`
 */
type FetchUserBulkUpdateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/bulkUpdate',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量更新用户↗](http://yapi.blacklake.tech/project/519/interface/api/72752) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/bulkUpdate`
 * @更新时间 `2022-01-24 10:44:17`
 */
const fetchUserBulkUpdateRequestConfig: FetchUserBulkUpdateRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/bulkUpdate',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserBulkUpdate',
};

/**
 * 接口 [批量更新用户↗](http://yapi.blacklake.tech/project/519/interface/api/72752) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/bulkUpdate`
 * @更新时间 `2022-01-24 10:44:17`
 */
export const fetchUserBulkUpdate = (
  requestData: FetchUserBulkUpdateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserBulkUpdateResponse>(
    prepare(fetchUserBulkUpdateRequestConfig, requestData),
    ...args,
  );
};

fetchUserBulkUpdate.requestConfig = fetchUserBulkUpdateRequestConfig;

/**
 * 接口 [批量更新状态↗](http://yapi.blacklake.tech/project/519/interface/api/72758) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/bulkUpdateStatus`
 * @更新时间 `2022-01-24 10:44:17`
 */
export interface FetchUserBulkUpdateStatusRequest {
  /**
   * 需要更新的业务ID集合
   */
  idList: number[];
  /**
   * 需要更新的状态key(1、账户状态；2、锁定状态；3、删除）
   */
  statusKey: string;
  /**
   * 需要更新的状态value（1对应的value值是1为启用，0为未启用，2为已激活， 3为未激活；2对应的value值是0为未锁定，1为已锁定；3对应的value值是1为删除）
   */
  statusValue: string;
}

/**
 * 接口 [批量更新状态↗](http://yapi.blacklake.tech/project/519/interface/api/72758) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/bulkUpdateStatus`
 * @更新时间 `2022-01-24 10:44:17`
 */
export interface FetchUserBulkUpdateStatusResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    updateFailureVOList?: {
      code?: string;
      failureReason?: string;
      name?: string;
    }[];
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量更新状态↗](http://yapi.blacklake.tech/project/519/interface/api/72758) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/bulkUpdateStatus`
 * @更新时间 `2022-01-24 10:44:17`
 */
type FetchUserBulkUpdateStatusRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/bulkUpdateStatus',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量更新状态↗](http://yapi.blacklake.tech/project/519/interface/api/72758) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/bulkUpdateStatus`
 * @更新时间 `2022-01-24 10:44:17`
 */
const fetchUserBulkUpdateStatusRequestConfig: FetchUserBulkUpdateStatusRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/bulkUpdateStatus',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserBulkUpdateStatus',
};

/**
 * 接口 [批量更新状态↗](http://yapi.blacklake.tech/project/519/interface/api/72758) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/bulkUpdateStatus`
 * @更新时间 `2022-01-24 10:44:17`
 */
export const fetchUserBulkUpdateStatus = (
  requestData: FetchUserBulkUpdateStatusRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserBulkUpdateStatusResponse>(
    prepare(fetchUserBulkUpdateStatusRequestConfig, requestData),
    ...args,
  );
};

fetchUserBulkUpdateStatus.requestConfig = fetchUserBulkUpdateStatusRequestConfig;

/**
 * 接口 [删除用户↗](http://yapi.blacklake.tech/project/519/interface/api/72764) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/delete`
 * @更新时间 `2022-01-24 10:44:17`
 */
export interface FetchUserDeleteRequest {
  id?: number;
}

/**
 * 接口 [删除用户↗](http://yapi.blacklake.tech/project/519/interface/api/72764) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/delete`
 * @更新时间 `2022-01-24 10:44:17`
 */
export interface FetchUserDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [删除用户↗](http://yapi.blacklake.tech/project/519/interface/api/72764) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/delete`
 * @更新时间 `2022-01-24 10:44:17`
 */
type FetchUserDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/delete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [删除用户↗](http://yapi.blacklake.tech/project/519/interface/api/72764) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/delete`
 * @更新时间 `2022-01-24 10:44:17`
 */
const fetchUserDeleteRequestConfig: FetchUserDeleteRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/delete',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserDelete',
};

/**
 * 接口 [删除用户↗](http://yapi.blacklake.tech/project/519/interface/api/72764) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/delete`
 * @更新时间 `2022-01-24 10:44:17`
 */
export const fetchUserDelete = (
  requestData: FetchUserDeleteRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserDeleteResponse>(
    prepare(fetchUserDeleteRequestConfig, requestData),
    ...args,
  );
};

fetchUserDelete.requestConfig = fetchUserDeleteRequestConfig;

/**
 * 接口 [获取用户详情↗](http://yapi.blacklake.tech/project/519/interface/api/72770) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/detail`
 * @更新时间 `2022-01-24 10:44:17`
 */
export interface FetchUserDetailRequest {}

/**
 * 接口 [获取用户详情↗](http://yapi.blacklake.tech/project/519/interface/api/72770) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/detail`
 * @更新时间 `2022-01-24 10:44:17`
 */
export interface FetchUserDetailResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 账号状态
     */
    active?: string;
    /**
     * 附件
     */
    attachments?: number[];
    /**
     * 头像地址
     */
    avatarUrl?: string;
    /**
     * 用户编码
     */
    code?: string;
    createdAt?: string;
    creator?: unknown;
    /**
     * 自定义字段
     */
    customFieldItemList?: {
      bizObjectId?: number;
      canRead?: boolean;
      displayValue?: {};
      fieldCode?: string;
      fieldId?: number;
      fieldName?: string;
      relatedBizId?: number;
    }[];
    /**
     * 部门
     */
    departmentVOList?: {
      /**
       * 编号
       */
      code?: string;
      /**
       * 创建时间
       */
      createdAt?: string;
      creator?: {
        avatarUrl?: string;
        email?: string;
        id?: number;
        name?: string;
        orgId?: number;
        phone?: string;
        username?: string;
      };
      /**
       * 备注
       */
      desc?: string;
      /**
       * id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
      operator?: {
        avatarUrl?: string;
        email?: string;
        id?: number;
        name?: string;
        orgId?: number;
        phone?: string;
        username?: string;
      };
      /**
       * 工厂id
       */
      orgId?: number;
      owner?: {
        avatarUrl?: string;
        email?: string;
        id?: number;
        name?: string;
        orgId?: number;
        phone?: string;
        username?: string;
      };
      parentDepartment?: {
        /**
         * 编号
         */
        code?: string;
        /**
         * 备注
         */
        desc?: string;
        /**
         * id
         */
        id?: number;
        /**
         * 名称
         */
        name?: string;
        /**
         * 工厂id
         */
        orgId?: number;
      };
      /**
       * 更新时间
       */
      updatedAt?: string;
    }[];
    desc?: string;
    /**
     * 邮箱
     */
    email?: string;
    /**
     * 首次登陆状态（0为首次，1为非首次）
     */
    firstLoginStatus?: number;
    /**
     * 用户id
     */
    id?: number;
    /**
     * 锁定状态
     */
    lockStatus?: string;
    /**
     * 用户名
     */
    name?: string;
    /**
     * 用户名拼音
     */
    namePinYin?: string;
    operator?: unknown;
    /**
     * 工厂ID
     */
    orgId?: number;
    /**
     * 电话号码
     */
    phone?: string;
    /**
     * 角色
     */
    roleList?: {
      /**
       * 角色编号
       */
      code?: string;
      /**
       * 角色描述
       */
      desc?: string;
      /**
       * 角色业务ID
       */
      id?: number;
      /**
       * 当前角色是否被用户使用,仅用于角色列表页
       */
      inUse?: boolean;
      /**
       * 角色名称
       */
      name?: string;
      /**
       * 工厂ID
       */
      orgId?: number;
      /**
       * 许可项列表
       */
      permissionChoices?: {
        /**
         * 描述
         */
        desc?: string;
        /**
         * 名称
         */
        name?: string;
      }[];
      /**
       * 角色备注
       */
      remark?: string;
    }[];
    /**
     * 直属上级
     */
    superior?: {
      [k: string]: unknown;
    };
    /**
     * 账号类型
     */
    terminal?: string;
    updateAt?: string;
    /**
     * 账号
     */
    username?: string;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取用户详情↗](http://yapi.blacklake.tech/project/519/interface/api/72770) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/detail`
 * @更新时间 `2022-01-24 10:44:17`
 */
type FetchUserDetailRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/detail',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [获取用户详情↗](http://yapi.blacklake.tech/project/519/interface/api/72770) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/detail`
 * @更新时间 `2022-01-24 10:44:17`
 */
const fetchUserDetailRequestConfig: FetchUserDetailRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/detail',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserDetail',
};

/**
 * 接口 [获取用户详情↗](http://yapi.blacklake.tech/project/519/interface/api/72770) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/detail`
 * @更新时间 `2022-01-24 10:44:17`
 */
export const fetchUserDetail = (
  requestData?: FetchUserDetailRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserDetailResponse>(
    prepare(fetchUserDetailRequestConfig, requestData),
    ...args,
  );
};

fetchUserDetail.requestConfig = fetchUserDetailRequestConfig;

/**
 * 接口 [根据用户id获取用户详情↗](http://yapi.blacklake.tech/project/519/interface/api/72776) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/getDetailById`
 * @更新时间 `2022-01-24 10:44:17`
 */
export interface FetchUserGetDetailByIdRequest {
  id?: number;
  phone?: string;
}

/**
 * 接口 [根据用户id获取用户详情↗](http://yapi.blacklake.tech/project/519/interface/api/72776) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/getDetailById`
 * @更新时间 `2022-01-24 10:44:17`
 */
export interface FetchUserGetDetailByIdResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 账号状态
     */
    active?: string;
    /**
     * 附件
     */
    attachments?: number[];
    /**
     * 头像地址
     */
    avatarUrl?: string;
    /**
     * 用户编码
     */
    code?: string;
    createdAt?: string;
    creator?: unknown;
    /**
     * 自定义字段
     */
    customFieldItemList?: {
      bizObjectId?: number;
      canRead?: boolean;
      displayValue?: {};
      fieldCode?: string;
      fieldId?: number;
      fieldName?: string;
      relatedBizId?: number;
    }[];
    /**
     * 部门
     */
    departmentVOList?: {
      /**
       * 编号
       */
      code?: string;
      /**
       * 创建时间
       */
      createdAt?: string;
      creator?: {
        avatarUrl?: string;
        email?: string;
        id?: number;
        name?: string;
        orgId?: number;
        phone?: string;
        username?: string;
      };
      /**
       * 备注
       */
      desc?: string;
      /**
       * id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
      operator?: {
        avatarUrl?: string;
        email?: string;
        id?: number;
        name?: string;
        orgId?: number;
        phone?: string;
        username?: string;
      };
      /**
       * 工厂id
       */
      orgId?: number;
      owner?: {
        avatarUrl?: string;
        email?: string;
        id?: number;
        name?: string;
        orgId?: number;
        phone?: string;
        username?: string;
      };
      parentDepartment?: {
        /**
         * 编号
         */
        code?: string;
        /**
         * 备注
         */
        desc?: string;
        /**
         * id
         */
        id?: number;
        /**
         * 名称
         */
        name?: string;
        /**
         * 工厂id
         */
        orgId?: number;
      };
      /**
       * 更新时间
       */
      updatedAt?: string;
    }[];
    desc?: string;
    /**
     * 邮箱
     */
    email?: string;
    /**
     * 首次登陆状态（0为首次，1为非首次）
     */
    firstLoginStatus?: number;
    /**
     * 用户id
     */
    id?: number;
    /**
     * 锁定状态
     */
    lockStatus?: string;
    /**
     * 用户名
     */
    name?: string;
    /**
     * 用户名拼音
     */
    namePinYin?: string;
    operator?: unknown;
    /**
     * 工厂ID
     */
    orgId?: number;
    /**
     * 电话号码
     */
    phone?: string;
    /**
     * 角色
     */
    roleList?: {
      /**
       * 角色编号
       */
      code?: string;
      /**
       * 角色描述
       */
      desc?: string;
      /**
       * 角色业务ID
       */
      id?: number;
      /**
       * 当前角色是否被用户使用,仅用于角色列表页
       */
      inUse?: boolean;
      /**
       * 角色名称
       */
      name?: string;
      /**
       * 工厂ID
       */
      orgId?: number;
      /**
       * 许可项列表
       */
      permissionChoices?: {
        /**
         * 描述
         */
        desc?: string;
        /**
         * 名称
         */
        name?: string;
      }[];
      /**
       * 角色备注
       */
      remark?: string;
    }[];
    /**
     * 直属上级
     */
    superior?: {
      [k: string]: unknown;
    };
    /**
     * 账号类型
     */
    terminal?: string;
    updateAt?: string;
    /**
     * 账号
     */
    username?: string;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [根据用户id获取用户详情↗](http://yapi.blacklake.tech/project/519/interface/api/72776) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/getDetailById`
 * @更新时间 `2022-01-24 10:44:17`
 */
type FetchUserGetDetailByIdRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/getDetailById',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [根据用户id获取用户详情↗](http://yapi.blacklake.tech/project/519/interface/api/72776) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/getDetailById`
 * @更新时间 `2022-01-24 10:44:17`
 */
const fetchUserGetDetailByIdRequestConfig: FetchUserGetDetailByIdRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/getDetailById',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserGetDetailById',
};

/**
 * 接口 [根据用户id获取用户详情↗](http://yapi.blacklake.tech/project/519/interface/api/72776) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/getDetailById`
 * @更新时间 `2022-01-24 10:44:17`
 */
export const fetchUserGetDetailById = (
  requestData: FetchUserGetDetailByIdRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserGetDetailByIdResponse>(
    prepare(fetchUserGetDetailByIdRequestConfig, requestData),
    ...args,
  );
};

fetchUserGetDetailById.requestConfig = fetchUserGetDetailByIdRequestConfig;

/**
 * 接口 [获取租户用户使用数量↗](http://yapi.blacklake.tech/project/519/interface/api/72782) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/getOrgUserNum`
 * @更新时间 `2022-01-24 10:44:18`
 */
export interface FetchUserGetOrgUserNumRequest {}

/**
 * 接口 [获取租户用户使用数量↗](http://yapi.blacklake.tech/project/519/interface/api/72782) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/getOrgUserNum`
 * @更新时间 `2022-01-24 10:44:18`
 */
export interface FetchUserGetOrgUserNumResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取租户用户使用数量↗](http://yapi.blacklake.tech/project/519/interface/api/72782) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/getOrgUserNum`
 * @更新时间 `2022-01-24 10:44:18`
 */
type FetchUserGetOrgUserNumRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/getOrgUserNum',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [获取租户用户使用数量↗](http://yapi.blacklake.tech/project/519/interface/api/72782) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/getOrgUserNum`
 * @更新时间 `2022-01-24 10:44:18`
 */
const fetchUserGetOrgUserNumRequestConfig: FetchUserGetOrgUserNumRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/getOrgUserNum',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserGetOrgUserNum',
};

/**
 * 接口 [获取租户用户使用数量↗](http://yapi.blacklake.tech/project/519/interface/api/72782) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/getOrgUserNum`
 * @更新时间 `2022-01-24 10:44:18`
 */
export const fetchUserGetOrgUserNum = (
  requestData?: FetchUserGetOrgUserNumRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserGetOrgUserNumResponse>(
    prepare(fetchUserGetOrgUserNumRequestConfig, requestData),
    ...args,
  );
};

fetchUserGetOrgUserNum.requestConfig = fetchUserGetOrgUserNumRequestConfig;

/**
 * 接口 [获取用户工厂列表信息↗](http://yapi.blacklake.tech/project/519/interface/api/72788) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/getUserOrgList`
 * @更新时间 `2022-01-24 10:44:18`
 */
export interface FetchUserGetUserOrgListRequest {
  /**
   * phone
   */
  phone: string;
}

/**
 * 接口 [获取用户工厂列表信息↗](http://yapi.blacklake.tech/project/519/interface/api/72788) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/getUserOrgList`
 * @更新时间 `2022-01-24 10:44:18`
 */
export interface FetchUserGetUserOrgListResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 工厂code
     */
    code?: string;
    /**
     * 工厂ID
     */
    orgId?: number;
    /**
     * 工厂名称
     */
    organizationName?: string;
  }[];
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取用户工厂列表信息↗](http://yapi.blacklake.tech/project/519/interface/api/72788) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/getUserOrgList`
 * @更新时间 `2022-01-24 10:44:18`
 */
type FetchUserGetUserOrgListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/getUserOrgList',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [获取用户工厂列表信息↗](http://yapi.blacklake.tech/project/519/interface/api/72788) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/getUserOrgList`
 * @更新时间 `2022-01-24 10:44:18`
 */
const fetchUserGetUserOrgListRequestConfig: FetchUserGetUserOrgListRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/getUserOrgList',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserGetUserOrgList',
};

/**
 * 接口 [获取用户工厂列表信息↗](http://yapi.blacklake.tech/project/519/interface/api/72788) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/getUserOrgList`
 * @更新时间 `2022-01-24 10:44:18`
 */
export const fetchUserGetUserOrgList = (
  requestData: FetchUserGetUserOrgListRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserGetUserOrgListResponse>(
    prepare(fetchUserGetUserOrgListRequestConfig, requestData),
    ...args,
  );
};

fetchUserGetUserOrgList.requestConfig = fetchUserGetUserOrgListRequestConfig;

/**
 * 接口 [用户列表↗](http://yapi.blacklake.tech/project/519/interface/api/72794) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/list`
 * @更新时间 `2022-01-24 10:44:18`
 */
export interface FetchUserListRequest {
  /**
   * 账号类型
   */
  active?: string;
  /**
   * 用户编号
   */
  code?: string;
  /**
   * 部门是否包含子部门
   */
  containChild?: boolean;
  /**
   * 部门ID
   */
  departmentId?: number;
  /**
   * 用户邮箱
   */
  email?: string;
  /**
   * 锁定状态
   */
  lockStatus?: string;
  /**
   * 用户名称
   */
  name?: string;
  /**
   * 请求页
   */
  page?: number;
  /**
   * 用户手机号
   */
  phone?: string;
  /**
   * 角色ID集合
   */
  roleIdList?: number[];
  /**
   * 名称或编号的模糊搜索
   */
  search?: string;
  /**
   * 筛选标识 1 全选 0 不全选 默认为 0
   */
  selectFlag?: number;
  /**
   * 每页大小
   */
  size?: number;
  /**
   * 排序 列表顺序表示排序顺序
   */
  sorter?: {
    /**
     * 排序字段
     */
    field?: string;
    /**
     * 排序规律 默认 asc，asc 升序 desc 降序
     */
    order?: string;
  }[];
  /**
   * 直属上级ID集合
   */
  superiorIdList?: number[];
  /**
   * 终端类型
   */
  terminal?: string;
  /**
   * 用户账号
   */
  username?: string;
}

/**
 * 接口 [用户列表↗](http://yapi.blacklake.tech/project/519/interface/api/72794) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/list`
 * @更新时间 `2022-01-24 10:44:18`
 */
export interface FetchUserListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {}[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [用户列表↗](http://yapi.blacklake.tech/project/519/interface/api/72794) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/list`
 * @更新时间 `2022-01-24 10:44:18`
 */
type FetchUserListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [用户列表↗](http://yapi.blacklake.tech/project/519/interface/api/72794) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/list`
 * @更新时间 `2022-01-24 10:44:18`
 */
const fetchUserListRequestConfig: FetchUserListRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserList',
};

/**
 * 接口 [用户列表↗](http://yapi.blacklake.tech/project/519/interface/api/72794) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/list`
 * @更新时间 `2022-01-24 10:44:18`
 */
export const fetchUserList = (requestData: FetchUserListRequest, ...args: UserRequestRestArgs) => {
  return request<FetchUserListResponse>(prepare(fetchUserListRequestConfig, requestData), ...args);
};

fetchUserList.requestConfig = fetchUserListRequestConfig;

/**
 * 接口 [getUserAndPrivilege↗](http://yapi.blacklake.tech/project/519/interface/api/72800) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/privilege_info`
 * @更新时间 `2022-01-24 10:44:18`
 */
export interface FetchUserPrivilegeInfoRequest {}

/**
 * 接口 [getUserAndPrivilege↗](http://yapi.blacklake.tech/project/519/interface/api/72800) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/privilege_info`
 * @更新时间 `2022-01-24 10:44:18`
 */
export interface FetchUserPrivilegeInfoResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 头像url
     */
    avatarUrl?: string;
    /**
     * 编号
     */
    code?: string;
    /**
     * 用户邮箱
     */
    email?: string;
    /**
     * 姓名
     */
    name?: string;
    /**
     * 工厂id
     */
    orgId?: number;
    /**
     * 手机号
     */
    phone?: string;
    /**
     * 功能权限列表
     */
    privilegeListVOSet?: {
      /**
       * 功能权限编码
       */
      code?: string;
      /**
       * 功能权限ID
       */
      id?: number;
      /**
       * 功能权限名称
       */
      name?: string;
    }[];
    /**
     * 用户名
     */
    username?: string;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [getUserAndPrivilege↗](http://yapi.blacklake.tech/project/519/interface/api/72800) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/privilege_info`
 * @更新时间 `2022-01-24 10:44:18`
 */
type FetchUserPrivilegeInfoRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/privilege_info',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [getUserAndPrivilege↗](http://yapi.blacklake.tech/project/519/interface/api/72800) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/privilege_info`
 * @更新时间 `2022-01-24 10:44:18`
 */
const fetchUserPrivilegeInfoRequestConfig: FetchUserPrivilegeInfoRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/privilege_info',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserPrivilegeInfo',
};

/**
 * 接口 [getUserAndPrivilege↗](http://yapi.blacklake.tech/project/519/interface/api/72800) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/privilege_info`
 * @更新时间 `2022-01-24 10:44:18`
 */
export const fetchUserPrivilegeInfo = (
  requestData?: FetchUserPrivilegeInfoRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserPrivilegeInfoResponse>(
    prepare(fetchUserPrivilegeInfoRequestConfig, requestData),
    ...args,
  );
};

fetchUserPrivilegeInfo.requestConfig = fetchUserPrivilegeInfoRequestConfig;

/**
 * 接口 [重置密码↗](http://yapi.blacklake.tech/project/519/interface/api/72806) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/resetPassword`
 * @更新时间 `2022-01-24 10:44:18`
 */
export interface FetchUserResetPasswordRequest {
  /**
   * 工厂CODE
   */
  code: string;
  /**
   * 首次登陆状态
   */
  firstLoginStatus?: number;
  /**
   * 确认新密码
   */
  makeSureNewPassword: string;
  /**
   * 新密码
   */
  newPassword: string;
  /**
   * 电话
   */
  phone: string;
  /**
   * 账号
   */
  username: string;
  /**
   * UUID
   */
  uuid: string;
}

/**
 * 接口 [重置密码↗](http://yapi.blacklake.tech/project/519/interface/api/72806) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/resetPassword`
 * @更新时间 `2022-01-24 10:44:18`
 */
export interface FetchUserResetPasswordResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [重置密码↗](http://yapi.blacklake.tech/project/519/interface/api/72806) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/resetPassword`
 * @更新时间 `2022-01-24 10:44:18`
 */
type FetchUserResetPasswordRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/resetPassword',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [重置密码↗](http://yapi.blacklake.tech/project/519/interface/api/72806) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/resetPassword`
 * @更新时间 `2022-01-24 10:44:18`
 */
const fetchUserResetPasswordRequestConfig: FetchUserResetPasswordRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/resetPassword',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserResetPassword',
};

/**
 * 接口 [重置密码↗](http://yapi.blacklake.tech/project/519/interface/api/72806) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/resetPassword`
 * @更新时间 `2022-01-24 10:44:18`
 */
export const fetchUserResetPassword = (
  requestData: FetchUserResetPasswordRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserResetPasswordResponse>(
    prepare(fetchUserResetPasswordRequestConfig, requestData),
    ...args,
  );
};

fetchUserResetPassword.requestConfig = fetchUserResetPasswordRequestConfig;

/**
 * 接口 [更新用户↗](http://yapi.blacklake.tech/project/519/interface/api/72812) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/update`
 * @更新时间 `2022-01-24 10:44:18`
 */
export interface FetchUserUpdateRequest {
  /**
   * 附件
   */
  attachments?: number[];
  /**
   * 头像
   */
  avatarUrl?: string;
  /**
   * 用户编号
   */
  code: string;
  customFieldItems?: {
    id?: number;
    value?: {};
  }[];
  /**
   * 部门ID集合
   */
  departmentIds: number[];
  /**
   * 备注
   */
  desc?: string;
  /**
   * 邮箱
   */
  email?: string;
  /**
   * 目标用户ID
   */
  id: number;
  /**
   * 用户名称
   */
  name: string;
  /**
   * 电话号码
   */
  phone?: string;
  /**
   * 编辑原因
   */
  reason?: string;
  /**
   * 角色ID集合
   */
  roleIds?: number[];
  /**
   * 直属上级
   */
  superior?: number;
  /**
   * 账号类型 0：默认, 1：app, 2:web+app, 3:tv
   */
  terminal: string;
  /**
   * 账号
   */
  username: string;
}

/**
 * 接口 [更新用户↗](http://yapi.blacklake.tech/project/519/interface/api/72812) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/update`
 * @更新时间 `2022-01-24 10:44:18`
 */
export interface FetchUserUpdateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [更新用户↗](http://yapi.blacklake.tech/project/519/interface/api/72812) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/update`
 * @更新时间 `2022-01-24 10:44:18`
 */
type FetchUserUpdateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/update',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [更新用户↗](http://yapi.blacklake.tech/project/519/interface/api/72812) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/update`
 * @更新时间 `2022-01-24 10:44:18`
 */
const fetchUserUpdateRequestConfig: FetchUserUpdateRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/update',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserUpdate',
};

/**
 * 接口 [更新用户↗](http://yapi.blacklake.tech/project/519/interface/api/72812) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/update`
 * @更新时间 `2022-01-24 10:44:18`
 */
export const fetchUserUpdate = (
  requestData: FetchUserUpdateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserUpdateResponse>(
    prepare(fetchUserUpdateRequestConfig, requestData),
    ...args,
  );
};

fetchUserUpdate.requestConfig = fetchUserUpdateRequestConfig;

/**
 * 接口 [更新密码↗](http://yapi.blacklake.tech/project/519/interface/api/72818) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/updatePassword`
 * @更新时间 `2022-01-24 10:44:18`
 */
export interface FetchUserUpdatePasswordRequest {
  /**
   * 用户新密码
   */
  newPassword: string;
  /**
   * 用户旧密码
   */
  oldPassword: string;
}

/**
 * 接口 [更新密码↗](http://yapi.blacklake.tech/project/519/interface/api/72818) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/updatePassword`
 * @更新时间 `2022-01-24 10:44:18`
 */
export interface FetchUserUpdatePasswordResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [更新密码↗](http://yapi.blacklake.tech/project/519/interface/api/72818) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/updatePassword`
 * @更新时间 `2022-01-24 10:44:18`
 */
type FetchUserUpdatePasswordRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/updatePassword',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [更新密码↗](http://yapi.blacklake.tech/project/519/interface/api/72818) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/updatePassword`
 * @更新时间 `2022-01-24 10:44:18`
 */
const fetchUserUpdatePasswordRequestConfig: FetchUserUpdatePasswordRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/updatePassword',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserUpdatePassword',
};

/**
 * 接口 [更新密码↗](http://yapi.blacklake.tech/project/519/interface/api/72818) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/updatePassword`
 * @更新时间 `2022-01-24 10:44:18`
 */
export const fetchUserUpdatePassword = (
  requestData: FetchUserUpdatePasswordRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserUpdatePasswordResponse>(
    prepare(fetchUserUpdatePasswordRequestConfig, requestData),
    ...args,
  );
};

fetchUserUpdatePassword.requestConfig = fetchUserUpdatePasswordRequestConfig;

/**
 * 接口 [根据角色查询角色下的用户↗](http://yapi.blacklake.tech/project/519/interface/api/74570) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_getUsersByRoleId`
 * @更新时间 `2022-01-24 10:44:15`
 */
export interface FetchUserGetUsersByRoleIdRequest {
  /**
   * 用户名称
   */
  name?: string;
  /**
   * 角色ID
   */
  roleId: number;
}

/**
 * 接口 [根据角色查询角色下的用户↗](http://yapi.blacklake.tech/project/519/interface/api/74570) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_getUsersByRoleId`
 * @更新时间 `2022-01-24 10:44:15`
 */
export interface FetchUserGetUsersByRoleIdResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {}[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [根据角色查询角色下的用户↗](http://yapi.blacklake.tech/project/519/interface/api/74570) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_getUsersByRoleId`
 * @更新时间 `2022-01-24 10:44:15`
 */
type FetchUserGetUsersByRoleIdRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/_getUsersByRoleId',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [根据角色查询角色下的用户↗](http://yapi.blacklake.tech/project/519/interface/api/74570) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_getUsersByRoleId`
 * @更新时间 `2022-01-24 10:44:15`
 */
const fetchUserGetUsersByRoleIdRequestConfig: FetchUserGetUsersByRoleIdRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/_getUsersByRoleId',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserGetUsersByRoleId',
};

/**
 * 接口 [根据角色查询角色下的用户↗](http://yapi.blacklake.tech/project/519/interface/api/74570) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_getUsersByRoleId`
 * @更新时间 `2022-01-24 10:44:15`
 */
export const fetchUserGetUsersByRoleId = (
  requestData: FetchUserGetUsersByRoleIdRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserGetUsersByRoleIdResponse>(
    prepare(fetchUserGetUsersByRoleIdRequestConfig, requestData),
    ...args,
  );
};

fetchUserGetUsersByRoleId.requestConfig = fetchUserGetUsersByRoleIdRequestConfig;

/**
 * 接口 [管理员账号重置账号密码↗](http://yapi.blacklake.tech/project/519/interface/api/86546) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_reset_password_by_admin`
 * @更新时间 `2022-01-24 10:44:16`
 */
export interface FetchUserResetPasswordByAdminRequest {
  /**
   * 确认账号新密码
   */
  checkNewPassword: string;
  /**
   * 账号新密码
   */
  newPassword: string;
  /**
   * 被重置密码的账号
   */
  username: string;
}

/**
 * 接口 [管理员账号重置账号密码↗](http://yapi.blacklake.tech/project/519/interface/api/86546) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_reset_password_by_admin`
 * @更新时间 `2022-01-24 10:44:16`
 */
export interface FetchUserResetPasswordByAdminResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [管理员账号重置账号密码↗](http://yapi.blacklake.tech/project/519/interface/api/86546) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_reset_password_by_admin`
 * @更新时间 `2022-01-24 10:44:16`
 */
type FetchUserResetPasswordByAdminRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/_reset_password_by_admin',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [管理员账号重置账号密码↗](http://yapi.blacklake.tech/project/519/interface/api/86546) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_reset_password_by_admin`
 * @更新时间 `2022-01-24 10:44:16`
 */
const fetchUserResetPasswordByAdminRequestConfig: FetchUserResetPasswordByAdminRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/_reset_password_by_admin',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserResetPasswordByAdmin',
};

/**
 * 接口 [管理员账号重置账号密码↗](http://yapi.blacklake.tech/project/519/interface/api/86546) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_reset_password_by_admin`
 * @更新时间 `2022-01-24 10:44:16`
 */
export const fetchUserResetPasswordByAdmin = (
  requestData: FetchUserResetPasswordByAdminRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserResetPasswordByAdminResponse>(
    prepare(fetchUserResetPasswordByAdminRequestConfig, requestData),
    ...args,
  );
};

fetchUserResetPasswordByAdmin.requestConfig = fetchUserResetPasswordByAdminRequestConfig;

/**
 * 接口 [更新手机号↗](http://yapi.blacklake.tech/project/519/interface/api/86852) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_update_phone`
 * @更新时间 `2022-01-24 10:44:16`
 */
export interface FetchUserUpdatePhoneRequest {
  /**
   * 短信
   */
  message: string;
}

/**
 * 接口 [更新手机号↗](http://yapi.blacklake.tech/project/519/interface/api/86852) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_update_phone`
 * @更新时间 `2022-01-24 10:44:16`
 */
export interface FetchUserUpdatePhoneResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [更新手机号↗](http://yapi.blacklake.tech/project/519/interface/api/86852) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_update_phone`
 * @更新时间 `2022-01-24 10:44:16`
 */
type FetchUserUpdatePhoneRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/_update_phone',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [更新手机号↗](http://yapi.blacklake.tech/project/519/interface/api/86852) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_update_phone`
 * @更新时间 `2022-01-24 10:44:16`
 */
const fetchUserUpdatePhoneRequestConfig: FetchUserUpdatePhoneRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/_update_phone',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserUpdatePhone',
};

/**
 * 接口 [更新手机号↗](http://yapi.blacklake.tech/project/519/interface/api/86852) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_update_phone`
 * @更新时间 `2022-01-24 10:44:16`
 */
export const fetchUserUpdatePhone = (
  requestData: FetchUserUpdatePhoneRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserUpdatePhoneResponse>(
    prepare(fetchUserUpdatePhoneRequestConfig, requestData),
    ...args,
  );
};

fetchUserUpdatePhone.requestConfig = fetchUserUpdatePhoneRequestConfig;

/**
 * 接口 [校验用户手机号↗](http://yapi.blacklake.tech/project/519/interface/api/86858) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_verity_phone`
 * @更新时间 `2022-01-24 10:44:16`
 */
export interface FetchUserVerityPhoneRequest {
  /**
   * 用户新手机号
   */
  newPhone: string;
  /**
   * 用户旧手机号
   */
  oldPhone?: string;
}

/**
 * 接口 [校验用户手机号↗](http://yapi.blacklake.tech/project/519/interface/api/86858) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_verity_phone`
 * @更新时间 `2022-01-24 10:44:16`
 */
export interface FetchUserVerityPhoneResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 请求结果
     */
    flag?: boolean;
    /**
     * 短信验证码
     */
    sendCode?: string;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [校验用户手机号↗](http://yapi.blacklake.tech/project/519/interface/api/86858) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_verity_phone`
 * @更新时间 `2022-01-24 10:44:16`
 */
type FetchUserVerityPhoneRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/_verity_phone',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [校验用户手机号↗](http://yapi.blacklake.tech/project/519/interface/api/86858) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_verity_phone`
 * @更新时间 `2022-01-24 10:44:16`
 */
const fetchUserVerityPhoneRequestConfig: FetchUserVerityPhoneRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/_verity_phone',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserVerityPhone',
};

/**
 * 接口 [校验用户手机号↗](http://yapi.blacklake.tech/project/519/interface/api/86858) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_verity_phone`
 * @更新时间 `2022-01-24 10:44:16`
 */
export const fetchUserVerityPhone = (
  requestData: FetchUserVerityPhoneRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserVerityPhoneResponse>(
    prepare(fetchUserVerityPhoneRequestConfig, requestData),
    ...args,
  );
};

fetchUserVerityPhone.requestConfig = fetchUserVerityPhoneRequestConfig;

/**
 * 接口 [切换工厂↗](http://yapi.blacklake.tech/project/519/interface/api/86864) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_switch_org`
 * @更新时间 `2022-01-24 10:44:16`
 */
export interface FetchUserSwitchOrgRequest {
  /**
   * 切换工厂code
   */
  orgCode: string;
  /**
   * 用户手机号
   */
  phone: string;
}

/**
 * 接口 [切换工厂↗](http://yapi.blacklake.tech/project/519/interface/api/86864) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_switch_org`
 * @更新时间 `2022-01-24 10:44:16`
 */
export interface FetchUserSwitchOrgResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: string;
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [切换工厂↗](http://yapi.blacklake.tech/project/519/interface/api/86864) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_switch_org`
 * @更新时间 `2022-01-24 10:44:16`
 */
type FetchUserSwitchOrgRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/_switch_org',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [切换工厂↗](http://yapi.blacklake.tech/project/519/interface/api/86864) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_switch_org`
 * @更新时间 `2022-01-24 10:44:16`
 */
const fetchUserSwitchOrgRequestConfig: FetchUserSwitchOrgRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/_switch_org',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserSwitchOrg',
};

/**
 * 接口 [切换工厂↗](http://yapi.blacklake.tech/project/519/interface/api/86864) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_switch_org`
 * @更新时间 `2022-01-24 10:44:16`
 */
export const fetchUserSwitchOrg = (
  requestData: FetchUserSwitchOrgRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserSwitchOrgResponse>(
    prepare(fetchUserSwitchOrgRequestConfig, requestData),
    ...args,
  );
};

fetchUserSwitchOrg.requestConfig = fetchUserSwitchOrgRequestConfig;

/**
 * 接口 [重置密码的短信验证服务↗](http://yapi.blacklake.tech/project/519/interface/api/87158) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_verify_message`
 * @更新时间 `2021-12-29 16:24:02`
 */
export interface FetchUserVerifyMessageRequest {
  /**
   * 短信验证码
   */
  code?: string;
  /**
   * 电话号码
   */
  phone?: string;
  /**
   * 是否需要强制发送短信验证码
   */
  sendMessage?: boolean;
}

/**
 * 接口 [重置密码的短信验证服务↗](http://yapi.blacklake.tech/project/519/interface/api/87158) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_verify_message`
 * @更新时间 `2021-12-29 16:24:02`
 */
export interface FetchUserVerifyMessageResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: string;
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [重置密码的短信验证服务↗](http://yapi.blacklake.tech/project/519/interface/api/87158) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_verify_message`
 * @更新时间 `2021-12-29 16:24:02`
 */
type FetchUserVerifyMessageRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/_verify_message',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [重置密码的短信验证服务↗](http://yapi.blacklake.tech/project/519/interface/api/87158) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_verify_message`
 * @更新时间 `2021-12-29 16:24:02`
 */
const fetchUserVerifyMessageRequestConfig: FetchUserVerifyMessageRequestConfig = {
  mockUrl: mockUrl_0_0_0_7,
  devUrl: devUrl_0_0_0_7,
  prodUrl: prodUrl_0_0_0_7,
  path: '/user/domain/web/v1/user/_verify_message',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_7,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchUserVerifyMessage',
};

/**
 * 接口 [重置密码的短信验证服务↗](http://yapi.blacklake.tech/project/519/interface/api/87158) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_verify_message`
 * @更新时间 `2021-12-29 16:24:02`
 */
export const fetchUserVerifyMessage = (
  requestData: FetchUserVerifyMessageRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserVerifyMessageResponse>(
    prepare(fetchUserVerifyMessageRequestConfig, requestData),
    ...args,
  );
};

fetchUserVerifyMessage.requestConfig = fetchUserVerifyMessageRequestConfig;

/**
 * 接口 [商业授权信息获取↗](http://yapi.blacklake.tech/project/519/interface/api/87506) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_business_authorization_information`
 * @更新时间 `2022-01-24 10:44:15`
 */
export interface FetchUserBusinessAuthorizationInformationRequest {}

/**
 * 接口 [商业授权信息获取↗](http://yapi.blacklake.tech/project/519/interface/api/87506) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_business_authorization_information`
 * @更新时间 `2022-01-24 10:44:15`
 */
export interface FetchUserBusinessAuthorizationInformationResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 接口授权总数
     */
    apiNum?: number;
    /**
     * 接口已用数量
     */
    apiUsed?: number;
    /**
     * 移动端账号总数
     */
    appNum?: number;
    /**
     * 移动端账号已用数量
     */
    appUsed?: number;
    /**
     * 定制批记录授权总数
     */
    batchRecordNum?: number;
    /**
     * 定制批记录使用数
     */
    batchRecordUsed?: number;
    biNum?: number;
    biUsed?: number;
    /**
     * 自定义字段总数
     */
    customFieldNum?: number;
    /**
     * 自定义字段使用数
     */
    customFieldUsed?: number;
    /**
     * 自定义对象总数
     */
    customObjectNum?: number;
    /**
     * 自定义对象使用数
     */
    customObjectUsed?: number;
    /**
     * 定制看板授权总数
     */
    dashboardNum?: number;
    /**
     * 定制看板已用数量
     */
    dashboardUsed?: number;
    /**
     * 租户到期时间
     */
    dueAt?: string;
    /**
     * 文件总容量
     */
    fileCapacity?: number;
    /**
     * 文件容量单位
     */
    fileCapacityUnit?: string;
    /**
     * 文件容量使用数量
     */
    fileCapacityUsed?: number;
    /**
     * 剩余时间
     */
    remainDays?: number;
    /**
     * 租户起算时间
     */
    sinceAt?: string;
    /**
     * 网页+移动端账号总数
     */
    webAppNum?: number;
    /**
     * 网页+移动端账号已用数量
     */
    webAppUsed?: number;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [商业授权信息获取↗](http://yapi.blacklake.tech/project/519/interface/api/87506) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_business_authorization_information`
 * @更新时间 `2022-01-24 10:44:15`
 */
type FetchUserBusinessAuthorizationInformationRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/user/_business_authorization_information',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [商业授权信息获取↗](http://yapi.blacklake.tech/project/519/interface/api/87506) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_business_authorization_information`
 * @更新时间 `2022-01-24 10:44:15`
 */
const fetchUserBusinessAuthorizationInformationRequestConfig: FetchUserBusinessAuthorizationInformationRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_7,
    devUrl: devUrl_0_0_0_7,
    prodUrl: prodUrl_0_0_0_7,
    path: '/user/domain/web/v1/user/_business_authorization_information',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_7,
    paramNames: [],
    queryNames: [],
    requestDataOptional: true,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchUserBusinessAuthorizationInformation',
  };

/**
 * 接口 [商业授权信息获取↗](http://yapi.blacklake.tech/project/519/interface/api/87506) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/user/_business_authorization_information`
 * @更新时间 `2022-01-24 10:44:15`
 */
export const fetchUserBusinessAuthorizationInformation = (
  requestData?: FetchUserBusinessAuthorizationInformationRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchUserBusinessAuthorizationInformationResponse>(
    prepare(fetchUserBusinessAuthorizationInformationRequestConfig, requestData),
    ...args,
  );
};

fetchUserBusinessAuthorizationInformation.requestConfig =
  fetchUserBusinessAuthorizationInformationRequestConfig;

/**
 * 接口 [快速搜索部门下用户↗](http://yapi.blacklake.tech/project/519/interface/api/92437) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/_list_user_under_department`
 * @更新时间 `2022-01-24 10:44:07`
 */
export interface FetchDepartmentListUserUnderDepartmentRequest {
  /**
   * 部门ID集合
   */
  departmentIds: number[];
  /**
   * 用户名称或编号的模糊搜索
   */
  search?: string;
}

/**
 * 接口 [快速搜索部门下用户↗](http://yapi.blacklake.tech/project/519/interface/api/92437) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/_list_user_under_department`
 * @更新时间 `2022-01-24 10:44:07`
 */
export interface FetchDepartmentListUserUnderDepartmentResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 部门ID
     */
    departmentId?: number;
    /**
     * 部门下满足筛选条件的用户信息
     */
    userList?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    }[];
  }[];
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [快速搜索部门下用户↗](http://yapi.blacklake.tech/project/519/interface/api/92437) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/_list_user_under_department`
 * @更新时间 `2022-01-24 10:44:07`
 */
type FetchDepartmentListUserUnderDepartmentRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/department/_list_user_under_department',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [快速搜索部门下用户↗](http://yapi.blacklake.tech/project/519/interface/api/92437) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/_list_user_under_department`
 * @更新时间 `2022-01-24 10:44:07`
 */
const fetchDepartmentListUserUnderDepartmentRequestConfig: FetchDepartmentListUserUnderDepartmentRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_7,
    devUrl: devUrl_0_0_0_7,
    prodUrl: prodUrl_0_0_0_7,
    path: '/user/domain/web/v1/department/_list_user_under_department',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_7,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchDepartmentListUserUnderDepartment',
  };

/**
 * 接口 [快速搜索部门下用户↗](http://yapi.blacklake.tech/project/519/interface/api/92437) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10804)
 * @标签 `web`
 * @请求头 `POST /user/domain/web/v1/department/_list_user_under_department`
 * @更新时间 `2022-01-24 10:44:07`
 */
export const fetchDepartmentListUserUnderDepartment = (
  requestData: FetchDepartmentListUserUnderDepartmentRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDepartmentListUserUnderDepartmentResponse>(
    prepare(fetchDepartmentListUserUnderDepartmentRequestConfig, requestData),
    ...args,
  );
};

fetchDepartmentListUserUnderDepartment.requestConfig =
  fetchDepartmentListUserUnderDepartmentRequestConfig;

const mockUrl_0_0_0_8 = 'http://yapi.blacklake.tech/mock/519' as any;
const devUrl_0_0_0_8 = '' as any;
const prodUrl_0_0_0_8 = '' as any;
const dataKey_0_0_0_8 = undefined as any;

/**
 * 接口 [test_access_user_active↗](http://yapi.blacklake.tech/project/519/interface/api/72662) 的 **请求类型**
 *
 * @分类 [access-privilege-test-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10806)
 * @标签 `access-privilege-test-controller`
 * @请求头 `POST /user/domain/web/v1/privilege/test_access_user_active`
 * @更新时间 `2022-01-24 10:44:13`
 */
export interface FetchPrivilegeTestAccessUserActiveRequest {}

/**
 * 接口 [test_access_user_active↗](http://yapi.blacklake.tech/project/519/interface/api/72662) 的 **返回类型**
 *
 * @分类 [access-privilege-test-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10806)
 * @标签 `access-privilege-test-controller`
 * @请求头 `POST /user/domain/web/v1/privilege/test_access_user_active`
 * @更新时间 `2022-01-24 10:44:13`
 */
export interface FetchPrivilegeTestAccessUserActiveResponse {}

/**
 * 接口 [test_access_user_active↗](http://yapi.blacklake.tech/project/519/interface/api/72662) 的 **请求配置的类型**
 *
 * @分类 [access-privilege-test-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10806)
 * @标签 `access-privilege-test-controller`
 * @请求头 `POST /user/domain/web/v1/privilege/test_access_user_active`
 * @更新时间 `2022-01-24 10:44:13`
 */
type FetchPrivilegeTestAccessUserActiveRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/privilege/test_access_user_active',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [test_access_user_active↗](http://yapi.blacklake.tech/project/519/interface/api/72662) 的 **请求配置**
 *
 * @分类 [access-privilege-test-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10806)
 * @标签 `access-privilege-test-controller`
 * @请求头 `POST /user/domain/web/v1/privilege/test_access_user_active`
 * @更新时间 `2022-01-24 10:44:13`
 */
const fetchPrivilegeTestAccessUserActiveRequestConfig: FetchPrivilegeTestAccessUserActiveRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_8,
    devUrl: devUrl_0_0_0_8,
    prodUrl: prodUrl_0_0_0_8,
    path: '/user/domain/web/v1/privilege/test_access_user_active',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_8,
    paramNames: [],
    queryNames: [],
    requestDataOptional: true,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchPrivilegeTestAccessUserActive',
  };

/**
 * 接口 [test_access_user_active↗](http://yapi.blacklake.tech/project/519/interface/api/72662) 的 **请求函数**
 *
 * @分类 [access-privilege-test-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10806)
 * @标签 `access-privilege-test-controller`
 * @请求头 `POST /user/domain/web/v1/privilege/test_access_user_active`
 * @更新时间 `2022-01-24 10:44:13`
 */
export const fetchPrivilegeTestAccessUserActive = (
  requestData?: FetchPrivilegeTestAccessUserActiveRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchPrivilegeTestAccessUserActiveResponse>(
    prepare(fetchPrivilegeTestAccessUserActiveRequestConfig, requestData),
    ...args,
  );
};

fetchPrivilegeTestAccessUserActive.requestConfig = fetchPrivilegeTestAccessUserActiveRequestConfig;

/**
 * 接口 [test_access_user_add↗](http://yapi.blacklake.tech/project/519/interface/api/72668) 的 **请求类型**
 *
 * @分类 [access-privilege-test-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10806)
 * @标签 `access-privilege-test-controller`
 * @请求头 `POST /user/domain/web/v1/privilege/test_access_user_add`
 * @更新时间 `2022-01-24 10:44:13`
 */
export interface FetchPrivilegeTestAccessUserAddRequest {}

/**
 * 接口 [test_access_user_add↗](http://yapi.blacklake.tech/project/519/interface/api/72668) 的 **返回类型**
 *
 * @分类 [access-privilege-test-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10806)
 * @标签 `access-privilege-test-controller`
 * @请求头 `POST /user/domain/web/v1/privilege/test_access_user_add`
 * @更新时间 `2022-01-24 10:44:13`
 */
export interface FetchPrivilegeTestAccessUserAddResponse {}

/**
 * 接口 [test_access_user_add↗](http://yapi.blacklake.tech/project/519/interface/api/72668) 的 **请求配置的类型**
 *
 * @分类 [access-privilege-test-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10806)
 * @标签 `access-privilege-test-controller`
 * @请求头 `POST /user/domain/web/v1/privilege/test_access_user_add`
 * @更新时间 `2022-01-24 10:44:13`
 */
type FetchPrivilegeTestAccessUserAddRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/privilege/test_access_user_add',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [test_access_user_add↗](http://yapi.blacklake.tech/project/519/interface/api/72668) 的 **请求配置**
 *
 * @分类 [access-privilege-test-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10806)
 * @标签 `access-privilege-test-controller`
 * @请求头 `POST /user/domain/web/v1/privilege/test_access_user_add`
 * @更新时间 `2022-01-24 10:44:13`
 */
const fetchPrivilegeTestAccessUserAddRequestConfig: FetchPrivilegeTestAccessUserAddRequestConfig = {
  mockUrl: mockUrl_0_0_0_8,
  devUrl: devUrl_0_0_0_8,
  prodUrl: prodUrl_0_0_0_8,
  path: '/user/domain/web/v1/privilege/test_access_user_add',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_8,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchPrivilegeTestAccessUserAdd',
};

/**
 * 接口 [test_access_user_add↗](http://yapi.blacklake.tech/project/519/interface/api/72668) 的 **请求函数**
 *
 * @分类 [access-privilege-test-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10806)
 * @标签 `access-privilege-test-controller`
 * @请求头 `POST /user/domain/web/v1/privilege/test_access_user_add`
 * @更新时间 `2022-01-24 10:44:13`
 */
export const fetchPrivilegeTestAccessUserAdd = (
  requestData?: FetchPrivilegeTestAccessUserAddRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchPrivilegeTestAccessUserAddResponse>(
    prepare(fetchPrivilegeTestAccessUserAddRequestConfig, requestData),
    ...args,
  );
};

fetchPrivilegeTestAccessUserAdd.requestConfig = fetchPrivilegeTestAccessUserAddRequestConfig;

const mockUrl_0_0_0_9 = 'http://yapi.blacklake.tech/mock/519' as any;
const devUrl_0_0_0_9 = '' as any;
const prodUrl_0_0_0_9 = '' as any;
const dataKey_0_0_0_9 = undefined as any;

/**
 * 接口 [创建角色↗](http://yapi.blacklake.tech/project/519/interface/api/72674) 的 **请求类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_create`
 * @更新时间 `2022-01-24 10:44:13`
 */
export interface FetchRoleCreateRequest {
  CODE_PT?: {
    cursor?: number;
  };
  DESC_NAME_AND_REMARK_PT?: {
    cursor?: number;
  };
  code?: string;
  desc?: string;
  name?: string;
  permissionChoices?: string[];
  privilegeIds?: number[];
  remark?: string;
}

/**
 * 接口 [创建角色↗](http://yapi.blacklake.tech/project/519/interface/api/72674) 的 **返回类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_create`
 * @更新时间 `2022-01-24 10:44:13`
 */
export interface FetchRoleCreateResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    code?: string;
    desc?: string;
    id?: number;
    name?: string;
    orgId?: number;
    remark?: string;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [创建角色↗](http://yapi.blacklake.tech/project/519/interface/api/72674) 的 **请求配置的类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_create`
 * @更新时间 `2022-01-24 10:44:13`
 */
type FetchRoleCreateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/role/_create',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [创建角色↗](http://yapi.blacklake.tech/project/519/interface/api/72674) 的 **请求配置**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_create`
 * @更新时间 `2022-01-24 10:44:13`
 */
const fetchRoleCreateRequestConfig: FetchRoleCreateRequestConfig = {
  mockUrl: mockUrl_0_0_0_9,
  devUrl: devUrl_0_0_0_9,
  prodUrl: prodUrl_0_0_0_9,
  path: '/user/domain/web/v1/role/_create',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_9,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchRoleCreate',
};

/**
 * 接口 [创建角色↗](http://yapi.blacklake.tech/project/519/interface/api/72674) 的 **请求函数**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_create`
 * @更新时间 `2022-01-24 10:44:13`
 */
export const fetchRoleCreate = (
  requestData: FetchRoleCreateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchRoleCreateResponse>(
    prepare(fetchRoleCreateRequestConfig, requestData),
    ...args,
  );
};

fetchRoleCreate.requestConfig = fetchRoleCreateRequestConfig;

/**
 * 接口 [根据id删除角色↗](http://yapi.blacklake.tech/project/519/interface/api/72680) 的 **请求类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_delete`
 * @更新时间 `2022-01-24 10:44:13`
 */
export interface FetchRoleDeleteRequest {
  /**
   * 待删除角色ID集合
   */
  roleIds?: number[];
}

/**
 * 接口 [根据id删除角色↗](http://yapi.blacklake.tech/project/519/interface/api/72680) 的 **返回类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_delete`
 * @更新时间 `2022-01-24 10:44:13`
 */
export interface FetchRoleDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 失败数量
     */
    failedCount?: number;
    failedIds?: {
      code?: string;
      id?: number;
      reason?: string;
    }[];
    /**
     * 信息
     */
    message?: string;
    /**
     * 成功数量
     */
    successCount?: number;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [根据id删除角色↗](http://yapi.blacklake.tech/project/519/interface/api/72680) 的 **请求配置的类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_delete`
 * @更新时间 `2022-01-24 10:44:13`
 */
type FetchRoleDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/role/_delete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [根据id删除角色↗](http://yapi.blacklake.tech/project/519/interface/api/72680) 的 **请求配置**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_delete`
 * @更新时间 `2022-01-24 10:44:13`
 */
const fetchRoleDeleteRequestConfig: FetchRoleDeleteRequestConfig = {
  mockUrl: mockUrl_0_0_0_9,
  devUrl: devUrl_0_0_0_9,
  prodUrl: prodUrl_0_0_0_9,
  path: '/user/domain/web/v1/role/_delete',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_9,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchRoleDelete',
};

/**
 * 接口 [根据id删除角色↗](http://yapi.blacklake.tech/project/519/interface/api/72680) 的 **请求函数**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_delete`
 * @更新时间 `2022-01-24 10:44:13`
 */
export const fetchRoleDelete = (
  requestData: FetchRoleDeleteRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchRoleDeleteResponse>(
    prepare(fetchRoleDeleteRequestConfig, requestData),
    ...args,
  );
};

fetchRoleDelete.requestConfig = fetchRoleDeleteRequestConfig;

/**
 * 接口 [角色详情↗](http://yapi.blacklake.tech/project/519/interface/api/72686) 的 **请求类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_get`
 * @更新时间 `2022-01-24 10:44:13`
 */
export interface FetchRoleGetRequest {
  id?: number;
}

/**
 * 接口 [角色详情↗](http://yapi.blacklake.tech/project/519/interface/api/72686) 的 **返回类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_get`
 * @更新时间 `2022-01-24 10:44:13`
 */
export interface FetchRoleGetResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 角色编号
     */
    code?: string;
    /**
     * 创建时间
     */
    createdAt?: string;
    creator?: {
      /**
       * 用户头像url
       */
      avatarUrl?: string;
      /**
       * 用户ID
       */
      id?: number;
      /**
       * 用户姓名
       */
      name?: string;
    };
    /**
     * 角色描述
     */
    desc?: string;
    /**
     * 角色业务ID
     */
    id?: number;
    /**
     * 角色名称
     */
    name?: string;
    operator?: {
      /**
       * 用户头像url
       */
      avatarUrl?: string;
      /**
       * 用户ID
       */
      id?: number;
      /**
       * 用户姓名
       */
      name?: string;
    };
    /**
     * 工厂ID
     */
    orgId?: number;
    /**
     * 许可项列表
     */
    permissionChoices?: {
      /**
       * 描述
       */
      desc?: string;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 角色备注
     */
    remark?: string;
    /**
     * 更新时间
     */
    updatedAt?: string;
    /**
     * 正在使用当前角色的用户集合
     */
    users?: {
      /**
       * 用户头像url
       */
      avatarUrl?: string;
      /**
       * 用户ID
       */
      id?: number;
      /**
       * 用户姓名
       */
      name?: string;
    }[];
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [角色详情↗](http://yapi.blacklake.tech/project/519/interface/api/72686) 的 **请求配置的类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_get`
 * @更新时间 `2022-01-24 10:44:13`
 */
type FetchRoleGetRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/role/_get',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [角色详情↗](http://yapi.blacklake.tech/project/519/interface/api/72686) 的 **请求配置**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_get`
 * @更新时间 `2022-01-24 10:44:13`
 */
const fetchRoleGetRequestConfig: FetchRoleGetRequestConfig = {
  mockUrl: mockUrl_0_0_0_9,
  devUrl: devUrl_0_0_0_9,
  prodUrl: prodUrl_0_0_0_9,
  path: '/user/domain/web/v1/role/_get',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_9,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchRoleGet',
};

/**
 * 接口 [角色详情↗](http://yapi.blacklake.tech/project/519/interface/api/72686) 的 **请求函数**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_get`
 * @更新时间 `2022-01-24 10:44:13`
 */
export const fetchRoleGet = (requestData: FetchRoleGetRequest, ...args: UserRequestRestArgs) => {
  return request<FetchRoleGetResponse>(prepare(fetchRoleGetRequestConfig, requestData), ...args);
};

fetchRoleGet.requestConfig = fetchRoleGetRequestConfig;

/**
 * 接口 [角色列表↗](http://yapi.blacklake.tech/project/519/interface/api/72692) 的 **请求类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_list`
 * @更新时间 `2022-01-24 10:44:14`
 */
export interface FetchRoleListRequest {
  /**
   * 角色编号
   */
  code?: string;
  /**
   * 角色描述
   */
  desc?: string;
  /**
   * 角色名称
   */
  name?: string;
  /**
   * 排序所依据的字段名:如根据名称排序 不关心顺序倒叙 传入name
   */
  orderBy?: string;
  /**
   * 分页page
   */
  page: number;
  /**
   * 快速搜索框内容
   */
  quickQueryParam?: string;
  /**
   * 角色备注
   */
  remark?: string;
  /**
   * 分页size
   */
  size: number;
  /**
   * 排序方式 顺序ASC 倒叙DESC 不关心根据什么字段排序 如根据名称顺序排列传人ASC
   */
  sort?: string;
}

/**
 * 接口 [角色列表↗](http://yapi.blacklake.tech/project/519/interface/api/72692) 的 **返回类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_list`
 * @更新时间 `2022-01-24 10:44:14`
 */
export interface FetchRoleListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * 角色编号
       */
      code?: string;
      /**
       * 角色描述
       */
      desc?: string;
      /**
       * 角色业务ID
       */
      id?: number;
      /**
       * 当前角色是否被用户使用,仅用于角色列表页
       */
      inUse?: boolean;
      /**
       * 角色名称
       */
      name?: string;
      /**
       * 工厂ID
       */
      orgId?: number;
      /**
       * 许可项列表
       */
      permissionChoices?: {
        /**
         * 描述
         */
        desc?: string;
        /**
         * 名称
         */
        name?: string;
      }[];
      /**
       * 角色备注
       */
      remark?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [角色列表↗](http://yapi.blacklake.tech/project/519/interface/api/72692) 的 **请求配置的类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_list`
 * @更新时间 `2022-01-24 10:44:14`
 */
type FetchRoleListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/role/_list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [角色列表↗](http://yapi.blacklake.tech/project/519/interface/api/72692) 的 **请求配置**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_list`
 * @更新时间 `2022-01-24 10:44:14`
 */
const fetchRoleListRequestConfig: FetchRoleListRequestConfig = {
  mockUrl: mockUrl_0_0_0_9,
  devUrl: devUrl_0_0_0_9,
  prodUrl: prodUrl_0_0_0_9,
  path: '/user/domain/web/v1/role/_list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_9,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchRoleList',
};

/**
 * 接口 [角色列表↗](http://yapi.blacklake.tech/project/519/interface/api/72692) 的 **请求函数**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_list`
 * @更新时间 `2022-01-24 10:44:14`
 */
export const fetchRoleList = (requestData: FetchRoleListRequest, ...args: UserRequestRestArgs) => {
  return request<FetchRoleListResponse>(prepare(fetchRoleListRequestConfig, requestData), ...args);
};

fetchRoleList.requestConfig = fetchRoleListRequestConfig;

/**
 * 接口 [更新角色↗](http://yapi.blacklake.tech/project/519/interface/api/72698) 的 **请求类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_update`
 * @更新时间 `2022-01-24 10:44:14`
 */
export interface FetchRoleUpdateRequest {
  CODE_PT?: {
    cursor?: number;
  };
  DESC_NAME_AND_REMARK_PT?: {
    cursor?: number;
  };
  code?: string;
  desc?: string;
  id?: number;
  name?: string;
  operatorId?: number;
  permissionChoices?: string[];
  privilegeIds?: number[];
  remark?: string;
}

/**
 * 接口 [更新角色↗](http://yapi.blacklake.tech/project/519/interface/api/72698) 的 **返回类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_update`
 * @更新时间 `2022-01-24 10:44:14`
 */
export interface FetchRoleUpdateResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    code?: string;
    desc?: string;
    id?: number;
    name?: string;
    orgId?: number;
    remark?: string;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [更新角色↗](http://yapi.blacklake.tech/project/519/interface/api/72698) 的 **请求配置的类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_update`
 * @更新时间 `2022-01-24 10:44:14`
 */
type FetchRoleUpdateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/role/_update',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [更新角色↗](http://yapi.blacklake.tech/project/519/interface/api/72698) 的 **请求配置**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_update`
 * @更新时间 `2022-01-24 10:44:14`
 */
const fetchRoleUpdateRequestConfig: FetchRoleUpdateRequestConfig = {
  mockUrl: mockUrl_0_0_0_9,
  devUrl: devUrl_0_0_0_9,
  prodUrl: prodUrl_0_0_0_9,
  path: '/user/domain/web/v1/role/_update',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_9,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchRoleUpdate',
};

/**
 * 接口 [更新角色↗](http://yapi.blacklake.tech/project/519/interface/api/72698) 的 **请求函数**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/_update`
 * @更新时间 `2022-01-24 10:44:14`
 */
export const fetchRoleUpdate = (
  requestData: FetchRoleUpdateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchRoleUpdateResponse>(
    prepare(fetchRoleUpdateRequestConfig, requestData),
    ...args,
  );
};

fetchRoleUpdate.requestConfig = fetchRoleUpdateRequestConfig;

/**
 * 接口 [列表↗](http://yapi.blacklake.tech/project/519/interface/api/72704) 的 **请求类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/permission_choice/_list`
 * @更新时间 `2022-01-24 10:44:14`
 */
export interface FetchRolePermissionChoiceListRequest {}

/**
 * 接口 [列表↗](http://yapi.blacklake.tech/project/519/interface/api/72704) 的 **返回类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/permission_choice/_list`
 * @更新时间 `2022-01-24 10:44:14`
 */
export interface FetchRolePermissionChoiceListResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 描述
     */
    desc?: string;
    /**
     * 名称
     */
    name?: string;
  }[];
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [列表↗](http://yapi.blacklake.tech/project/519/interface/api/72704) 的 **请求配置的类型**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/permission_choice/_list`
 * @更新时间 `2022-01-24 10:44:14`
 */
type FetchRolePermissionChoiceListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/role/permission_choice/_list',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [列表↗](http://yapi.blacklake.tech/project/519/interface/api/72704) 的 **请求配置**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/permission_choice/_list`
 * @更新时间 `2022-01-24 10:44:14`
 */
const fetchRolePermissionChoiceListRequestConfig: FetchRolePermissionChoiceListRequestConfig = {
  mockUrl: mockUrl_0_0_0_9,
  devUrl: devUrl_0_0_0_9,
  prodUrl: prodUrl_0_0_0_9,
  path: '/user/domain/web/v1/role/permission_choice/_list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_9,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchRolePermissionChoiceList',
};

/**
 * 接口 [列表↗](http://yapi.blacklake.tech/project/519/interface/api/72704) 的 **请求函数**
 *
 * @分类 [role-controller↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10808)
 * @标签 `role-controller`
 * @请求头 `POST /user/domain/web/v1/role/permission_choice/_list`
 * @更新时间 `2022-01-24 10:44:14`
 */
export const fetchRolePermissionChoiceList = (
  requestData?: FetchRolePermissionChoiceListRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchRolePermissionChoiceListResponse>(
    prepare(fetchRolePermissionChoiceListRequestConfig, requestData),
    ...args,
  );
};

fetchRolePermissionChoiceList.requestConfig = fetchRolePermissionChoiceListRequestConfig;

const mockUrl_0_0_0_10 = 'http://yapi.blacklake.tech/mock/519' as any;
const devUrl_0_0_0_10 = '' as any;
const prodUrl_0_0_0_10 = '' as any;
const dataKey_0_0_0_10 = undefined as any;

/**
 * 接口 [自定义字段新增↗](http://yapi.blacklake.tech/project/519/interface/api/72464) 的 **请求类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_create_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldCreateCustomFieldRequest {
  /**
   * 选项值列表
   */
  choiceValueList?: {
    /**
     * 选项值
     */
    choiceValue: string;
    /**
     * 是否默认
     */
    isDefault: number;
    /**
     * 是否启用
     */
    isUsed: number;
    /**
     * 关联字段id
     */
    relatedFieldId: number;
    /**
     * 顺序值
     */
    sequence: number;
  }[];
  /**
   * 日期格式
   */
  datetimeFormat?: string;
  /**
   * 小数位数
   */
  decimalNumber?: number;
  /**
   * 默认值
   */
  defaultValue?: string;
  /**
   * 字段类别 1 自定义字段 2 预设字段
   */
  fieldCategory: number;
  /**
   * 字段编号
   */
  fieldCode: string;
  /**
   * 字段名称
   */
  fieldName: string;
  /**
   * 字段提示
   */
  fieldRemind?: string;
  /**
   * 字段类型
   */
  fieldType: number;
  /**
   * 是否启用编号规则
   */
  isNumberRuleConfig?: number;
  /**
   * 是否必填
   */
  isRequired?: number;
  /**
   * 是否唯一
   */
  isUnique?: number;
  /**
   * 是否启用
   */
  isUsed?: number;
  /**
   * 最大长度
   */
  maxLength?: number;
  /**
   * 最大值
   */
  maxValue?: number;
  /**
   * 编号规则id
   */
  numberRuleId?: number;
  /**
   * 关联关系字段id
   */
  relatedFieldId?: number;
  /**
   * 相关对象id
   */
  relatedObjectId: number;
}

/**
 * 接口 [自定义字段新增↗](http://yapi.blacklake.tech/project/519/interface/api/72464) 的 **返回类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_create_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldCreateCustomFieldResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 创建时间
     */
    createdAt?: string;
    /**
     * 创建人id
     */
    creatorId?: number;
    /**
     * 日期格式
     */
    datetimeFormat?: string;
    /**
     * 小数位数
     */
    decimalNumber?: number;
    /**
     * 默认值
     */
    defaultValue?: string;
    /**
     * 字段类别
     */
    fieldCategory?: number;
    /**
     * 字段编号
     */
    fieldCode?: string;
    /**
     * 字段名称
     */
    fieldName?: string;
    /**
     * 字段权限
     */
    fieldPermission?: number;
    /**
     * 字段提示
     */
    fieldRemind?: string;
    /**
     * 字段类型
     */
    fieldType?: number;
    /**
     * 业务id
     */
    id?: number;
    /**
     * 是否启用编号规则
     */
    isNumberRuleConfig?: number;
    /**
     * 是否必填
     */
    isRequired?: number;
    /**
     * 是否唯一
     */
    isUnique?: number;
    /**
     * 是否启用
     */
    isUsed?: number;
    /**
     * 最大长度
     */
    maxLength?: number;
    /**
     * 最大值
     */
    maxValue?: number;
    /**
     * 编号规则id
     */
    numberRuleId?: number;
    /**
     * 操作人id
     */
    operatorId?: number;
    /**
     * 工厂id
     */
    orgId?: number;
    /**
     * 相关对象id
     */
    relatedObjectId?: number;
    /**
     * 更新时间
     */
    updatedAt?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [自定义字段新增↗](http://yapi.blacklake.tech/project/519/interface/api/72464) 的 **请求配置的类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_create_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
type FetchCustomFieldCreateCustomFieldRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/custom_field/_create_custom_field',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [自定义字段新增↗](http://yapi.blacklake.tech/project/519/interface/api/72464) 的 **请求配置**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_create_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
const fetchCustomFieldCreateCustomFieldRequestConfig: FetchCustomFieldCreateCustomFieldRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_10,
    devUrl: devUrl_0_0_0_10,
    prodUrl: prodUrl_0_0_0_10,
    path: '/user/domain/web/v1/custom_field/_create_custom_field',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_10,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchCustomFieldCreateCustomField',
  };

/**
 * 接口 [自定义字段新增↗](http://yapi.blacklake.tech/project/519/interface/api/72464) 的 **请求函数**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_create_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
export const fetchCustomFieldCreateCustomField = (
  requestData: FetchCustomFieldCreateCustomFieldRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchCustomFieldCreateCustomFieldResponse>(
    prepare(fetchCustomFieldCreateCustomFieldRequestConfig, requestData),
    ...args,
  );
};

fetchCustomFieldCreateCustomField.requestConfig = fetchCustomFieldCreateCustomFieldRequestConfig;

/**
 * 接口 [获取关联关系字段对应的对象下的所有符合条件的字段↗](http://yapi.blacklake.tech/project/519/interface/api/72470) 的 **请求类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_custom_field_related_one_field_list`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldCustomFieldRelatedOneFieldListRequest {
  /**
   * 自定义字段id
   */
  id: number;
}

/**
 * 接口 [获取关联关系字段对应的对象下的所有符合条件的字段↗](http://yapi.blacklake.tech/project/519/interface/api/72470) 的 **返回类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_custom_field_related_one_field_list`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldCustomFieldRelatedOneFieldListResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 创建时间
     */
    createdAt?: string;
    /**
     * 创建人id
     */
    creatorId?: number;
    /**
     * 操作人名称
     */
    creatorName?: string;
    /**
     * 日期格式
     */
    datetimeFormat?: string;
    /**
     * 小数位数
     */
    decimalNumber?: number;
    /**
     * 默认值
     */
    defaultValue?: string;
    /**
     * 字段类别
     */
    fieldCategory?: number;
    /**
     * 字段编号
     */
    fieldCode?: string;
    /**
     * 字段名称
     */
    fieldName?: string;
    /**
     * 字段权限
     */
    fieldPermission?: number;
    /**
     * 字段提示
     */
    fieldRemind?: string;
    /**
     * 字段类型
     */
    fieldType?: number;
    /**
     * 业务id
     */
    id?: number;
    /**
     * 是否启用编号规则
     */
    isNumberRuleConfig?: number;
    /**
     * 是否被引用
     */
    isReferred?: number;
    /**
     * 是否必填
     */
    isRequired?: number;
    /**
     * 是否唯一
     */
    isUnique?: number;
    /**
     * 是否启用
     */
    isUsed?: number;
    /**
     * 最大长度
     */
    maxLength?: number;
    /**
     * 最大值
     */
    maxValue?: number;
    /**
     * 编号规则id
     */
    numberRuleId?: number;
    /**
     * 操作人id
     */
    operatorId?: number;
    /**
     * 更新人名称
     */
    operatorName?: string;
    /**
     * 工厂id
     */
    orgId?: number;
    /**
     * 相关对象id
     */
    relatedObjectId?: number;
    /**
     * 更新时间
     */
    updatedAt?: string;
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取关联关系字段对应的对象下的所有符合条件的字段↗](http://yapi.blacklake.tech/project/519/interface/api/72470) 的 **请求配置的类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_custom_field_related_one_field_list`
 * @更新时间 `2021-10-15 16:15:48`
 */
type FetchCustomFieldCustomFieldRelatedOneFieldListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/custom_field/_custom_field_related_one_field_list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [获取关联关系字段对应的对象下的所有符合条件的字段↗](http://yapi.blacklake.tech/project/519/interface/api/72470) 的 **请求配置**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_custom_field_related_one_field_list`
 * @更新时间 `2021-10-15 16:15:48`
 */
const fetchCustomFieldCustomFieldRelatedOneFieldListRequestConfig: FetchCustomFieldCustomFieldRelatedOneFieldListRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_10,
    devUrl: devUrl_0_0_0_10,
    prodUrl: prodUrl_0_0_0_10,
    path: '/user/domain/web/v1/custom_field/_custom_field_related_one_field_list',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_10,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchCustomFieldCustomFieldRelatedOneFieldList',
  };

/**
 * 接口 [获取关联关系字段对应的对象下的所有符合条件的字段↗](http://yapi.blacklake.tech/project/519/interface/api/72470) 的 **请求函数**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_custom_field_related_one_field_list`
 * @更新时间 `2021-10-15 16:15:48`
 */
export const fetchCustomFieldCustomFieldRelatedOneFieldList = (
  requestData: FetchCustomFieldCustomFieldRelatedOneFieldListRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchCustomFieldCustomFieldRelatedOneFieldListResponse>(
    prepare(fetchCustomFieldCustomFieldRelatedOneFieldListRequestConfig, requestData),
    ...args,
  );
};

fetchCustomFieldCustomFieldRelatedOneFieldList.requestConfig =
  fetchCustomFieldCustomFieldRelatedOneFieldListRequestConfig;

/**
 * 接口 [获取对象下为关联关系的字段集合↗](http://yapi.blacklake.tech/project/519/interface/api/72476) 的 **请求类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_custom_field_related_one_list`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldCustomFieldRelatedOneListRequest {
  /**
   * 字段编号
   */
  fieldCode?: string;
  /**
   * 字段名称
   */
  fieldName?: string;
  /**
   * 业务id
   */
  id?: number;
  /**
   * 对象id
   */
  objectId: number;
}

/**
 * 接口 [获取对象下为关联关系的字段集合↗](http://yapi.blacklake.tech/project/519/interface/api/72476) 的 **返回类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_custom_field_related_one_list`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldCustomFieldRelatedOneListResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 字段编号
     */
    fieldCode?: string;
    /**
     * 字段名称
     */
    fieldName?: string;
    /**
     * 业务id
     */
    id?: number;
    /**
     * 对象id
     */
    objectId: number;
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取对象下为关联关系的字段集合↗](http://yapi.blacklake.tech/project/519/interface/api/72476) 的 **请求配置的类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_custom_field_related_one_list`
 * @更新时间 `2021-10-15 16:15:48`
 */
type FetchCustomFieldCustomFieldRelatedOneListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/custom_field/_custom_field_related_one_list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [获取对象下为关联关系的字段集合↗](http://yapi.blacklake.tech/project/519/interface/api/72476) 的 **请求配置**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_custom_field_related_one_list`
 * @更新时间 `2021-10-15 16:15:48`
 */
const fetchCustomFieldCustomFieldRelatedOneListRequestConfig: FetchCustomFieldCustomFieldRelatedOneListRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_10,
    devUrl: devUrl_0_0_0_10,
    prodUrl: prodUrl_0_0_0_10,
    path: '/user/domain/web/v1/custom_field/_custom_field_related_one_list',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_10,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchCustomFieldCustomFieldRelatedOneList',
  };

/**
 * 接口 [获取对象下为关联关系的字段集合↗](http://yapi.blacklake.tech/project/519/interface/api/72476) 的 **请求函数**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_custom_field_related_one_list`
 * @更新时间 `2021-10-15 16:15:48`
 */
export const fetchCustomFieldCustomFieldRelatedOneList = (
  requestData: FetchCustomFieldCustomFieldRelatedOneListRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchCustomFieldCustomFieldRelatedOneListResponse>(
    prepare(fetchCustomFieldCustomFieldRelatedOneListRequestConfig, requestData),
    ...args,
  );
};

fetchCustomFieldCustomFieldRelatedOneList.requestConfig =
  fetchCustomFieldCustomFieldRelatedOneListRequestConfig;

/**
 * 接口 [自定义字段类型枚举列表↗](http://yapi.blacklake.tech/project/519/interface/api/72482) 的 **请求类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_custom_field_type_list`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldCustomFieldTypeListRequest {}

/**
 * 接口 [自定义字段类型枚举列表↗](http://yapi.blacklake.tech/project/519/interface/api/72482) 的 **返回类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_custom_field_type_list`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldCustomFieldTypeListResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 字段名称
     */
    fieldName?: string;
    /**
     * 字段类型
     */
    fieldType?: number;
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [自定义字段类型枚举列表↗](http://yapi.blacklake.tech/project/519/interface/api/72482) 的 **请求配置的类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_custom_field_type_list`
 * @更新时间 `2021-10-15 16:15:48`
 */
type FetchCustomFieldCustomFieldTypeListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/custom_field/_custom_field_type_list',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [自定义字段类型枚举列表↗](http://yapi.blacklake.tech/project/519/interface/api/72482) 的 **请求配置**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_custom_field_type_list`
 * @更新时间 `2021-10-15 16:15:48`
 */
const fetchCustomFieldCustomFieldTypeListRequestConfig: FetchCustomFieldCustomFieldTypeListRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_10,
    devUrl: devUrl_0_0_0_10,
    prodUrl: prodUrl_0_0_0_10,
    path: '/user/domain/web/v1/custom_field/_custom_field_type_list',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_10,
    paramNames: [],
    queryNames: [],
    requestDataOptional: true,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchCustomFieldCustomFieldTypeList',
  };

/**
 * 接口 [自定义字段类型枚举列表↗](http://yapi.blacklake.tech/project/519/interface/api/72482) 的 **请求函数**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_custom_field_type_list`
 * @更新时间 `2021-10-15 16:15:48`
 */
export const fetchCustomFieldCustomFieldTypeList = (
  requestData?: FetchCustomFieldCustomFieldTypeListRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchCustomFieldCustomFieldTypeListResponse>(
    prepare(fetchCustomFieldCustomFieldTypeListRequestConfig, requestData),
    ...args,
  );
};

fetchCustomFieldCustomFieldTypeList.requestConfig =
  fetchCustomFieldCustomFieldTypeListRequestConfig;

/**
 * 接口 [自定义字段停用↗](http://yapi.blacklake.tech/project/519/interface/api/72488) 的 **请求类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_disable_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
export type FetchCustomFieldDisableCustomFieldRequest = {
  /**
   * 失败原因
   */
  detail?: string;
  /**
   * 字段编号
   */
  fieldCode?: string;
  /**
   * 字段名称
   */
  fieldName?: string;
  /**
   * 字段id
   */
  id?: number;
}[];

/**
 * 接口 [自定义字段停用↗](http://yapi.blacklake.tech/project/519/interface/api/72488) 的 **返回类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_disable_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldDisableCustomFieldResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [自定义字段停用↗](http://yapi.blacklake.tech/project/519/interface/api/72488) 的 **请求配置的类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_disable_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
type FetchCustomFieldDisableCustomFieldRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/custom_field/_disable_custom_field',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [自定义字段停用↗](http://yapi.blacklake.tech/project/519/interface/api/72488) 的 **请求配置**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_disable_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
const fetchCustomFieldDisableCustomFieldRequestConfig: FetchCustomFieldDisableCustomFieldRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_10,
    devUrl: devUrl_0_0_0_10,
    prodUrl: prodUrl_0_0_0_10,
    path: '/user/domain/web/v1/custom_field/_disable_custom_field',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_10,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchCustomFieldDisableCustomField',
  };

/**
 * 接口 [自定义字段停用↗](http://yapi.blacklake.tech/project/519/interface/api/72488) 的 **请求函数**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_disable_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
export const fetchCustomFieldDisableCustomField = (
  requestData: FetchCustomFieldDisableCustomFieldRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchCustomFieldDisableCustomFieldResponse>(
    prepare(fetchCustomFieldDisableCustomFieldRequestConfig, requestData),
    ...args,
  );
};

fetchCustomFieldDisableCustomField.requestConfig = fetchCustomFieldDisableCustomFieldRequestConfig;

/**
 * 接口 [自定义字段启用↗](http://yapi.blacklake.tech/project/519/interface/api/72494) 的 **请求类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_enable_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
export type FetchCustomFieldEnableCustomFieldRequest = {
  /**
   * 自定义字段id
   */
  id: number;
}[];

/**
 * 接口 [自定义字段启用↗](http://yapi.blacklake.tech/project/519/interface/api/72494) 的 **返回类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_enable_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldEnableCustomFieldResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [自定义字段启用↗](http://yapi.blacklake.tech/project/519/interface/api/72494) 的 **请求配置的类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_enable_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
type FetchCustomFieldEnableCustomFieldRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/custom_field/_enable_custom_field',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [自定义字段启用↗](http://yapi.blacklake.tech/project/519/interface/api/72494) 的 **请求配置**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_enable_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
const fetchCustomFieldEnableCustomFieldRequestConfig: FetchCustomFieldEnableCustomFieldRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_10,
    devUrl: devUrl_0_0_0_10,
    prodUrl: prodUrl_0_0_0_10,
    path: '/user/domain/web/v1/custom_field/_enable_custom_field',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_10,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchCustomFieldEnableCustomField',
  };

/**
 * 接口 [自定义字段启用↗](http://yapi.blacklake.tech/project/519/interface/api/72494) 的 **请求函数**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_enable_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
export const fetchCustomFieldEnableCustomField = (
  requestData: FetchCustomFieldEnableCustomFieldRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchCustomFieldEnableCustomFieldResponse>(
    prepare(fetchCustomFieldEnableCustomFieldRequestConfig, requestData),
    ...args,
  );
};

fetchCustomFieldEnableCustomField.requestConfig = fetchCustomFieldEnableCustomFieldRequestConfig;

/**
 * 接口 [获取自定义字段详情↗](http://yapi.blacklake.tech/project/519/interface/api/72500) 的 **请求类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_get_by_id`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldGetByIdRequest {
  /**
   * 创建时间
   */
  createdAt?: string;
  /**
   * 创建人id
   */
  creatorId?: number;
  /**
   * 操作人名称
   */
  creatorName?: string;
  /**
   * 日期格式
   */
  datetimeFormat?: string;
  /**
   * 小数位数
   */
  decimalNumber?: number;
  /**
   * 默认值
   */
  defaultValue?: string;
  /**
   * 字段类别
   */
  fieldCategory: number;
  /**
   * 字段编号
   */
  fieldCode?: string;
  /**
   * 字段名称
   */
  fieldName?: string;
  /**
   * 字段权限
   */
  fieldPermission?: number;
  /**
   * 字段提示
   */
  fieldRemind?: string;
  /**
   * 字段类型
   */
  fieldType?: number;
  /**
   * 自定义字段id
   */
  id: number;
  /**
   * 是否启用编号规则
   */
  isNumberRuleConfig?: number;
  /**
   * 是否被引用
   */
  isReferred?: number;
  /**
   * 是否必填
   */
  isRequired?: number;
  /**
   * 是否唯一
   */
  isUnique?: number;
  /**
   * 是否启用
   */
  isUsed?: number;
  /**
   * 最大长度
   */
  maxLength?: number;
  /**
   * 最大值
   */
  maxValue?: number;
  /**
   * 编号规则id
   */
  numberRuleId?: number;
  /**
   * 编号规则名称
   */
  numberRuleName?: string;
  /**
   * 引用对象名称
   */
  objectName?: string;
  /**
   * 操作人id
   */
  operatorId?: number;
  /**
   * 更新人名称
   */
  operatorName?: string;
  /**
   * 工厂id
   */
  orgId?: number;
  /**
   * 引用字段名称
   */
  referFieldName?: string;
  /**
   * 关联关系字段id
   */
  relatedFieldId?: number;
  /**
   * 相关对象id
   */
  relatedObjectId?: number;
  /**
   * 更新时间
   */
  updatedAt?: string;
}

/**
 * 接口 [获取自定义字段详情↗](http://yapi.blacklake.tech/project/519/interface/api/72500) 的 **返回类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_get_by_id`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldGetByIdResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 创建时间
     */
    createdAt?: string;
    /**
     * 创建人id
     */
    creatorId?: number;
    /**
     * 操作人名称
     */
    creatorName?: string;
    /**
     * 日期格式
     */
    datetimeFormat?: string;
    /**
     * 小数位数
     */
    decimalNumber?: number;
    /**
     * 默认值
     */
    defaultValue?: string;
    /**
     * 字段类别
     */
    fieldCategory: number;
    /**
     * 字段编号
     */
    fieldCode?: string;
    /**
     * 字段名称
     */
    fieldName?: string;
    /**
     * 字段权限
     */
    fieldPermission?: number;
    /**
     * 字段提示
     */
    fieldRemind?: string;
    /**
     * 字段类型
     */
    fieldType?: number;
    /**
     * 自定义字段id
     */
    id: number;
    /**
     * 是否启用编号规则
     */
    isNumberRuleConfig?: number;
    /**
     * 是否被引用
     */
    isReferred?: number;
    /**
     * 是否必填
     */
    isRequired?: number;
    /**
     * 是否唯一
     */
    isUnique?: number;
    /**
     * 是否启用
     */
    isUsed?: number;
    /**
     * 最大长度
     */
    maxLength?: number;
    /**
     * 最大值
     */
    maxValue?: number;
    /**
     * 编号规则id
     */
    numberRuleId?: number;
    /**
     * 编号规则名称
     */
    numberRuleName?: string;
    /**
     * 引用对象名称
     */
    objectName?: string;
    /**
     * 操作人id
     */
    operatorId?: number;
    /**
     * 更新人名称
     */
    operatorName?: string;
    /**
     * 工厂id
     */
    orgId?: number;
    /**
     * 引用字段名称
     */
    referFieldName?: string;
    /**
     * 关联关系字段id
     */
    relatedFieldId?: number;
    /**
     * 相关对象id
     */
    relatedObjectId?: number;
    /**
     * 更新时间
     */
    updatedAt?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取自定义字段详情↗](http://yapi.blacklake.tech/project/519/interface/api/72500) 的 **请求配置的类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_get_by_id`
 * @更新时间 `2021-10-15 16:15:48`
 */
type FetchCustomFieldGetByIdRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/custom_field/_get_by_id',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [获取自定义字段详情↗](http://yapi.blacklake.tech/project/519/interface/api/72500) 的 **请求配置**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_get_by_id`
 * @更新时间 `2021-10-15 16:15:48`
 */
const fetchCustomFieldGetByIdRequestConfig: FetchCustomFieldGetByIdRequestConfig = {
  mockUrl: mockUrl_0_0_0_10,
  devUrl: devUrl_0_0_0_10,
  prodUrl: prodUrl_0_0_0_10,
  path: '/user/domain/web/v1/custom_field/_get_by_id',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_10,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchCustomFieldGetById',
};

/**
 * 接口 [获取自定义字段详情↗](http://yapi.blacklake.tech/project/519/interface/api/72500) 的 **请求函数**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_get_by_id`
 * @更新时间 `2021-10-15 16:15:48`
 */
export const fetchCustomFieldGetById = (
  requestData: FetchCustomFieldGetByIdRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchCustomFieldGetByIdResponse>(
    prepare(fetchCustomFieldGetByIdRequestConfig, requestData),
    ...args,
  );
};

fetchCustomFieldGetById.requestConfig = fetchCustomFieldGetByIdRequestConfig;

/**
 * 接口 [根据业务对象code获取所有使用中的自定义字段↗](http://yapi.blacklake.tech/project/519/interface/api/72506) 的 **请求类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_get_list_by_object_code`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldGetListByObjectCodeRequest {
  objectCode?: string;
}

/**
 * 接口 [根据业务对象code获取所有使用中的自定义字段↗](http://yapi.blacklake.tech/project/519/interface/api/72506) 的 **返回类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_get_list_by_object_code`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldGetListByObjectCodeResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 创建时间
     */
    createdAt?: string;
    /**
     * 创建人id
     */
    creatorId?: number;
    /**
     * 操作人名称
     */
    creatorName?: string;
    /**
     * 日期格式
     */
    datetimeFormat?: string;
    /**
     * 小数位数
     */
    decimalNumber?: number;
    /**
     * 默认值
     */
    defaultValue?: string;
    /**
     * 字段类别
     */
    fieldCategory?: number;
    /**
     * 字段编号
     */
    fieldCode?: string;
    /**
     * 字段名称
     */
    fieldName?: string;
    /**
     * 字段权限
     */
    fieldPermission?: number;
    /**
     * 字段提示
     */
    fieldRemind?: string;
    /**
     * 字段类型
     */
    fieldType?: number;
    /**
     * 业务id
     */
    id?: number;
    /**
     * 是否启用编号规则
     */
    isNumberRuleConfig?: number;
    /**
     * 是否被引用
     */
    isReferred?: number;
    /**
     * 是否必填
     */
    isRequired?: number;
    /**
     * 是否唯一
     */
    isUnique?: number;
    /**
     * 是否启用
     */
    isUsed?: number;
    /**
     * 最大长度
     */
    maxLength?: number;
    /**
     * 最大值
     */
    maxValue?: number;
    /**
     * 编号规则id
     */
    numberRuleId?: number;
    /**
     * 操作人id
     */
    operatorId?: number;
    /**
     * 更新人名称
     */
    operatorName?: string;
    /**
     * 工厂id
     */
    orgId?: number;
    /**
     * 相关对象id
     */
    relatedObjectId?: number;
    /**
     * 更新时间
     */
    updatedAt?: string;
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [根据业务对象code获取所有使用中的自定义字段↗](http://yapi.blacklake.tech/project/519/interface/api/72506) 的 **请求配置的类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_get_list_by_object_code`
 * @更新时间 `2021-10-15 16:15:48`
 */
type FetchCustomFieldGetListByObjectCodeRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/custom_field/_get_list_by_object_code',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [根据业务对象code获取所有使用中的自定义字段↗](http://yapi.blacklake.tech/project/519/interface/api/72506) 的 **请求配置**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_get_list_by_object_code`
 * @更新时间 `2021-10-15 16:15:48`
 */
const fetchCustomFieldGetListByObjectCodeRequestConfig: FetchCustomFieldGetListByObjectCodeRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_10,
    devUrl: devUrl_0_0_0_10,
    prodUrl: prodUrl_0_0_0_10,
    path: '/user/domain/web/v1/custom_field/_get_list_by_object_code',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_10,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchCustomFieldGetListByObjectCode',
  };

/**
 * 接口 [根据业务对象code获取所有使用中的自定义字段↗](http://yapi.blacklake.tech/project/519/interface/api/72506) 的 **请求函数**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_get_list_by_object_code`
 * @更新时间 `2021-10-15 16:15:48`
 */
export const fetchCustomFieldGetListByObjectCode = (
  requestData: FetchCustomFieldGetListByObjectCodeRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchCustomFieldGetListByObjectCodeResponse>(
    prepare(fetchCustomFieldGetListByObjectCodeRequestConfig, requestData),
    ...args,
  );
};

fetchCustomFieldGetListByObjectCode.requestConfig =
  fetchCustomFieldGetListByObjectCodeRequestConfig;

/**
 * 接口 [根据业务对象id获取所有字段↗](http://yapi.blacklake.tech/project/519/interface/api/72512) 的 **请求类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_get_list_by_object_id`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldGetListByObjectIdRequest {
  /**
   * 字段创建结束时间
   */
  createEndTime?: number;
  /**
   * 字段创建开始时间
   */
  createStartTime?: number;
  /**
   * 字段类别
   */
  fieldCategory?: number;
  /**
   * 字段编号
   */
  fieldCode?: string;
  /**
   * 字段名称
   */
  fieldName?: string;
  /**
   * 字段类型
   */
  fieldType?: number;
  /**
   * 是否启用
   */
  isUsed?: number[];
  /**
   * 对象id
   */
  objectId: number;
  /**
   * 排序字段
   */
  orderBy?: string;
  /**
   * 升降序
   */
  orderDirection?: string;
  /**
   * 分页参数-页数
   */
  page: number;
  /**
   * 搜索条件
   */
  search?: string;
  /**
   * 分页参数-每页个数
   */
  size: number;
  /**
   * 字段更新结束时间
   */
  updateEndTime?: number;
  /**
   * 字段更新开始时间
   */
  updateStartTime?: number;
}

/**
 * 接口 [根据业务对象id获取所有字段↗](http://yapi.blacklake.tech/project/519/interface/api/72512) 的 **返回类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_get_list_by_object_id`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldGetListByObjectIdResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * 创建时间
       */
      createdAt?: string;
      /**
       * 创建人id
       */
      creatorId?: number;
      /**
       * 操作人名称
       */
      creatorName?: string;
      /**
       * 日期格式
       */
      datetimeFormat?: string;
      /**
       * 小数位数
       */
      decimalNumber?: number;
      /**
       * 默认值
       */
      defaultValue?: string;
      /**
       * 字段类别
       */
      fieldCategory?: number;
      /**
       * 字段编号
       */
      fieldCode?: string;
      /**
       * 字段名称
       */
      fieldName?: string;
      /**
       * 字段权限
       */
      fieldPermission?: number;
      /**
       * 字段提示
       */
      fieldRemind?: string;
      /**
       * 字段类型
       */
      fieldType?: number;
      /**
       * 业务id
       */
      id?: number;
      /**
       * 是否启用编号规则
       */
      isNumberRuleConfig?: number;
      /**
       * 是否被引用
       */
      isReferred?: number;
      /**
       * 是否必填
       */
      isRequired?: number;
      /**
       * 是否唯一
       */
      isUnique?: number;
      /**
       * 是否启用
       */
      isUsed?: number;
      /**
       * 最大长度
       */
      maxLength?: number;
      /**
       * 最大值
       */
      maxValue?: number;
      /**
       * 编号规则id
       */
      numberRuleId?: number;
      /**
       * 操作人id
       */
      operatorId?: number;
      /**
       * 更新人名称
       */
      operatorName?: string;
      /**
       * 工厂id
       */
      orgId?: number;
      /**
       * 相关对象id
       */
      relatedObjectId?: number;
      /**
       * 更新时间
       */
      updatedAt?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [根据业务对象id获取所有字段↗](http://yapi.blacklake.tech/project/519/interface/api/72512) 的 **请求配置的类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_get_list_by_object_id`
 * @更新时间 `2021-10-15 16:15:48`
 */
type FetchCustomFieldGetListByObjectIdRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/custom_field/_get_list_by_object_id',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [根据业务对象id获取所有字段↗](http://yapi.blacklake.tech/project/519/interface/api/72512) 的 **请求配置**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_get_list_by_object_id`
 * @更新时间 `2021-10-15 16:15:48`
 */
const fetchCustomFieldGetListByObjectIdRequestConfig: FetchCustomFieldGetListByObjectIdRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_10,
    devUrl: devUrl_0_0_0_10,
    prodUrl: prodUrl_0_0_0_10,
    path: '/user/domain/web/v1/custom_field/_get_list_by_object_id',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_10,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchCustomFieldGetListByObjectId',
  };

/**
 * 接口 [根据业务对象id获取所有字段↗](http://yapi.blacklake.tech/project/519/interface/api/72512) 的 **请求函数**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_get_list_by_object_id`
 * @更新时间 `2021-10-15 16:15:48`
 */
export const fetchCustomFieldGetListByObjectId = (
  requestData: FetchCustomFieldGetListByObjectIdRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchCustomFieldGetListByObjectIdResponse>(
    prepare(fetchCustomFieldGetListByObjectIdRequestConfig, requestData),
    ...args,
  );
};

fetchCustomFieldGetListByObjectId.requestConfig = fetchCustomFieldGetListByObjectIdRequestConfig;

/**
 * 接口 [自定义字段修改↗](http://yapi.blacklake.tech/project/519/interface/api/72518) 的 **请求类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_update_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldUpdateCustomFieldRequest {
  /**
   * 选项值
   */
  choiceValueList?: {
    /**
     * 选项值
     */
    choiceValue: string;
    /**
     * 选项值id
     */
    id: number;
    /**
     * 是否默认
     */
    isDefault: number;
    /**
     * 是否启用
     */
    isUsed: number;
    /**
     * 字段id
     */
    relatedFieldId: number;
    /**
     * 顺序值
     */
    sequence: number;
  }[];
  /**
   * 日期格式
   */
  datetimeFormat?: string;
  /**
   * 小数位数
   */
  decimalNumber?: number;
  /**
   * 默认值
   */
  defaultValue?: string;
  /**
   * 字段类别 1 自定义字段 2 预设字段
   */
  fieldCategory: number;
  /**
   * 字段编号
   */
  fieldCode: string;
  /**
   * 字段名称
   */
  fieldName: string;
  /**
   * 字段提示
   */
  fieldRemind?: string;
  /**
   * 字段类型
   */
  fieldType: number;
  /**
   * 字段id
   */
  id: number;
  /**
   * 是否启用编号规则
   */
  isNumberRuleConfig?: number;
  /**
   * 是否必填
   */
  isRequired?: number;
  /**
   * 是否唯一
   */
  isUnique?: number;
  /**
   * 是否启用
   */
  isUsed?: number;
  /**
   * 最大长度
   */
  maxLength?: number;
  /**
   * 最大值
   */
  maxValue?: number;
  /**
   * 编号规则id
   */
  numberRuleId?: number;
  /**
   * 关联关系字段id
   */
  relatedFieldId?: number;
  /**
   * 相关对象id
   */
  relatedObjectId: number;
}

/**
 * 接口 [自定义字段修改↗](http://yapi.blacklake.tech/project/519/interface/api/72518) 的 **返回类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_update_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
export interface FetchCustomFieldUpdateCustomFieldResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 创建时间
     */
    createdAt?: string;
    /**
     * 创建人id
     */
    creatorId?: number;
    /**
     * 日期格式
     */
    datetimeFormat?: string;
    /**
     * 小数位数
     */
    decimalNumber?: number;
    /**
     * 默认值
     */
    defaultValue?: string;
    /**
     * 字段类别
     */
    fieldCategory?: number;
    /**
     * 字段编号
     */
    fieldCode?: string;
    /**
     * 字段名称
     */
    fieldName?: string;
    /**
     * 字段权限
     */
    fieldPermission?: number;
    /**
     * 字段提示
     */
    fieldRemind?: string;
    /**
     * 字段类型
     */
    fieldType?: number;
    /**
     * 业务id
     */
    id?: number;
    /**
     * 是否启用编号规则
     */
    isNumberRuleConfig?: number;
    /**
     * 是否必填
     */
    isRequired?: number;
    /**
     * 是否唯一
     */
    isUnique?: number;
    /**
     * 是否启用
     */
    isUsed?: number;
    /**
     * 最大长度
     */
    maxLength?: number;
    /**
     * 最大值
     */
    maxValue?: number;
    /**
     * 编号规则id
     */
    numberRuleId?: number;
    /**
     * 操作人id
     */
    operatorId?: number;
    /**
     * 工厂id
     */
    orgId?: number;
    /**
     * 相关对象id
     */
    relatedObjectId?: number;
    /**
     * 更新时间
     */
    updatedAt?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [自定义字段修改↗](http://yapi.blacklake.tech/project/519/interface/api/72518) 的 **请求配置的类型**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_update_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
type FetchCustomFieldUpdateCustomFieldRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/custom_field/_update_custom_field',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [自定义字段修改↗](http://yapi.blacklake.tech/project/519/interface/api/72518) 的 **请求配置**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_update_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
const fetchCustomFieldUpdateCustomFieldRequestConfig: FetchCustomFieldUpdateCustomFieldRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_10,
    devUrl: devUrl_0_0_0_10,
    prodUrl: prodUrl_0_0_0_10,
    path: '/user/domain/web/v1/custom_field/_update_custom_field',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_10,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchCustomFieldUpdateCustomField',
  };

/**
 * 接口 [自定义字段修改↗](http://yapi.blacklake.tech/project/519/interface/api/72518) 的 **请求函数**
 *
 * @分类 [web端自定义字段接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_10810)
 * @标签 `web端自定义字段接口`
 * @请求头 `POST /user/domain/web/v1/custom_field/_update_custom_field`
 * @更新时间 `2021-10-15 16:15:48`
 */
export const fetchCustomFieldUpdateCustomField = (
  requestData: FetchCustomFieldUpdateCustomFieldRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchCustomFieldUpdateCustomFieldResponse>(
    prepare(fetchCustomFieldUpdateCustomFieldRequestConfig, requestData),
    ...args,
  );
};

fetchCustomFieldUpdateCustomField.requestConfig = fetchCustomFieldUpdateCustomFieldRequestConfig;

const mockUrl_0_0_0_11 = 'http://yapi.blacklake.tech/mock/519' as any;
const devUrl_0_0_0_11 = '' as any;
const prodUrl_0_0_0_11 = '' as any;
const dataKey_0_0_0_11 = undefined as any;

/**
 * 接口 [新增电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86312) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_add`
 * @更新时间 `2022-01-24 10:44:09`
 */
export interface FetchDigitalSignatureAddRequest {
  /**
   * 签名人配置列表
   */
  digitalSignatureConfigCOList: {
    /**
     * 是否选择所有用户 0-false 1-true
     */
    allUserFlag: number;
    /**
     * 是否选择当前登录人 0-false 1-true
     */
    currentUserFlag: number;
    /**
     * 业务id，id存在时为编辑
     */
    id?: number;
    /**
     * 角色ids
     */
    roleIds?: number[];
    /**
     * 角色列表
     */
    roles?: {
      /**
       * 角色编号
       */
      code?: string;
      /**
       * 角色描述
       */
      desc?: string;
      /**
       * 业务id
       */
      id?: number;
      /**
       * 角色名称
       */
      name?: string;
    }[];
    /**
     * 序号
     */
    seq: number;
    /**
     * 用户ids
     */
    userIds?: number[];
    /**
     * 用户列表
     */
    users?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    }[];
  }[];
  /**
   * 功能点code
   */
  functionCode: string;
  /**
   * 电子签名名称
   */
  name: string;
  /**
   * 关联对象code
   */
  relatedObjectCode: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 签名规则 0并行，1串行
   */
  ruleType: number;
}

/**
 * 接口 [新增电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86312) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_add`
 * @更新时间 `2022-01-24 10:44:09`
 */
export interface FetchDigitalSignatureAddResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [新增电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86312) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_add`
 * @更新时间 `2022-01-24 10:44:09`
 */
type FetchDigitalSignatureAddRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_add',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [新增电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86312) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_add`
 * @更新时间 `2022-01-24 10:44:09`
 */
const fetchDigitalSignatureAddRequestConfig: FetchDigitalSignatureAddRequestConfig = {
  mockUrl: mockUrl_0_0_0_11,
  devUrl: devUrl_0_0_0_11,
  prodUrl: prodUrl_0_0_0_11,
  path: '/user/domain/web/v1/digital_signature/_add',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_11,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDigitalSignatureAdd',
};

/**
 * 接口 [新增电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86312) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_add`
 * @更新时间 `2022-01-24 10:44:09`
 */
export const fetchDigitalSignatureAdd = (
  requestData: FetchDigitalSignatureAddRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureAddResponse>(
    prepare(fetchDigitalSignatureAddRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureAdd.requestConfig = fetchDigitalSignatureAddRequestConfig;

/**
 * 接口 [批量启用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86318) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_activate`
 * @更新时间 `2022-01-24 10:44:09`
 */
export interface FetchDigitalSignatureBulkActivateRequest {
  /**
   * 业务id List
   */
  ids: number[];
}

/**
 * 接口 [批量启用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86318) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_activate`
 * @更新时间 `2022-01-24 10:44:09`
 */
export interface FetchDigitalSignatureBulkActivateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量启用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86318) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_activate`
 * @更新时间 `2022-01-24 10:44:09`
 */
type FetchDigitalSignatureBulkActivateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_bulk_activate',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量启用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86318) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_activate`
 * @更新时间 `2022-01-24 10:44:09`
 */
const fetchDigitalSignatureBulkActivateRequestConfig: FetchDigitalSignatureBulkActivateRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_11,
    devUrl: devUrl_0_0_0_11,
    prodUrl: prodUrl_0_0_0_11,
    path: '/user/domain/web/v1/digital_signature/_bulk_activate',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_11,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchDigitalSignatureBulkActivate',
  };

/**
 * 接口 [批量启用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86318) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_activate`
 * @更新时间 `2022-01-24 10:44:09`
 */
export const fetchDigitalSignatureBulkActivate = (
  requestData: FetchDigitalSignatureBulkActivateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureBulkActivateResponse>(
    prepare(fetchDigitalSignatureBulkActivateRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureBulkActivate.requestConfig = fetchDigitalSignatureBulkActivateRequestConfig;

/**
 * 接口 [批量停用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86324) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_deactivate`
 * @更新时间 `2022-01-24 10:44:09`
 */
export interface FetchDigitalSignatureBulkDeactivateRequest {
  /**
   * 业务id List
   */
  ids: number[];
}

/**
 * 接口 [批量停用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86324) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_deactivate`
 * @更新时间 `2022-01-24 10:44:09`
 */
export interface FetchDigitalSignatureBulkDeactivateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量停用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86324) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_deactivate`
 * @更新时间 `2022-01-24 10:44:09`
 */
type FetchDigitalSignatureBulkDeactivateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_bulk_deactivate',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量停用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86324) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_deactivate`
 * @更新时间 `2022-01-24 10:44:09`
 */
const fetchDigitalSignatureBulkDeactivateRequestConfig: FetchDigitalSignatureBulkDeactivateRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_11,
    devUrl: devUrl_0_0_0_11,
    prodUrl: prodUrl_0_0_0_11,
    path: '/user/domain/web/v1/digital_signature/_bulk_deactivate',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_11,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchDigitalSignatureBulkDeactivate',
  };

/**
 * 接口 [批量停用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86324) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_deactivate`
 * @更新时间 `2022-01-24 10:44:09`
 */
export const fetchDigitalSignatureBulkDeactivate = (
  requestData: FetchDigitalSignatureBulkDeactivateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureBulkDeactivateResponse>(
    prepare(fetchDigitalSignatureBulkDeactivateRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureBulkDeactivate.requestConfig =
  fetchDigitalSignatureBulkDeactivateRequestConfig;

/**
 * 接口 [批量删除电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86330) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_delete`
 * @更新时间 `2022-01-24 10:44:09`
 */
export interface FetchDigitalSignatureBulkDeleteRequest {
  /**
   * 业务id List
   */
  ids: number[];
}

/**
 * 接口 [批量删除电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86330) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_delete`
 * @更新时间 `2022-01-24 10:44:09`
 */
export interface FetchDigitalSignatureBulkDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量删除电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86330) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_delete`
 * @更新时间 `2022-01-24 10:44:09`
 */
type FetchDigitalSignatureBulkDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_bulk_delete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量删除电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86330) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_delete`
 * @更新时间 `2022-01-24 10:44:09`
 */
const fetchDigitalSignatureBulkDeleteRequestConfig: FetchDigitalSignatureBulkDeleteRequestConfig = {
  mockUrl: mockUrl_0_0_0_11,
  devUrl: devUrl_0_0_0_11,
  prodUrl: prodUrl_0_0_0_11,
  path: '/user/domain/web/v1/digital_signature/_bulk_delete',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_11,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDigitalSignatureBulkDelete',
};

/**
 * 接口 [批量删除电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86330) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_delete`
 * @更新时间 `2022-01-24 10:44:09`
 */
export const fetchDigitalSignatureBulkDelete = (
  requestData: FetchDigitalSignatureBulkDeleteRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureBulkDeleteResponse>(
    prepare(fetchDigitalSignatureBulkDeleteRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureBulkDelete.requestConfig = fetchDigitalSignatureBulkDeleteRequestConfig;

/**
 * 接口 [查看电子签名详情↗](http://yapi.blacklake.tech/project/519/interface/api/86336) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_detail`
 * @更新时间 `2022-01-24 10:44:10`
 */
export interface FetchDigitalSignatureDetailRequest {
  /**
   * 业务id
   */
  id: number;
}

/**
 * 接口 [查看电子签名详情↗](http://yapi.blacklake.tech/project/519/interface/api/86336) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_detail`
 * @更新时间 `2022-01-24 10:44:10`
 */
export interface FetchDigitalSignatureDetailResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    activeStatus?: number;
    /**
     * 创建时间
     */
    createdAt?: string;
    creator?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    /**
     * 签名人配置列表
     */
    digitalSignatureConfigVOList?: {
      /**
       * 是否选择所有用户 0-false 1-true
       */
      allUserFlag: number;
      /**
       * 是否选择当前登录人 0-false 1-true
       */
      currentUserFlag: number;
      /**
       * 业务id，id存在时为编辑
       */
      id?: number;
      /**
       * 角色ids
       */
      roleIds?: number[];
      /**
       * 角色列表
       */
      roles?: {
        /**
         * 角色编号
         */
        code?: string;
        /**
         * 角色描述
         */
        desc?: string;
        /**
         * 业务id
         */
        id?: number;
        /**
         * 角色名称
         */
        name?: string;
      }[];
      /**
       * 序号
       */
      seq: number;
      /**
       * 用户ids
       */
      userIds?: number[];
      /**
       * 用户列表
       */
      users?: {
        avatarUrl?: string;
        email?: string;
        id?: number;
        name?: string;
        orgId?: number;
        phone?: string;
        username?: string;
      }[];
    }[];
    /**
     * 功能点名称
     */
    functionName?: string;
    /**
     * 业务id
     */
    id?: number;
    /**
     * 电子签名名称(支持模糊搜索)
     */
    name?: string;
    operator?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    /**
     * 关联对象名称
     */
    relatedObjectName?: string;
    /**
     * 备注
     */
    remark?: string;
    /**
     * 签名规则 0并行，1串行
     */
    ruleType?: number;
    /**
     * 更新时间
     */
    updatedAt?: string;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [查看电子签名详情↗](http://yapi.blacklake.tech/project/519/interface/api/86336) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_detail`
 * @更新时间 `2022-01-24 10:44:10`
 */
type FetchDigitalSignatureDetailRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_detail',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [查看电子签名详情↗](http://yapi.blacklake.tech/project/519/interface/api/86336) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_detail`
 * @更新时间 `2022-01-24 10:44:10`
 */
const fetchDigitalSignatureDetailRequestConfig: FetchDigitalSignatureDetailRequestConfig = {
  mockUrl: mockUrl_0_0_0_11,
  devUrl: devUrl_0_0_0_11,
  prodUrl: prodUrl_0_0_0_11,
  path: '/user/domain/web/v1/digital_signature/_detail',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_11,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDigitalSignatureDetail',
};

/**
 * 接口 [查看电子签名详情↗](http://yapi.blacklake.tech/project/519/interface/api/86336) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_detail`
 * @更新时间 `2022-01-24 10:44:10`
 */
export const fetchDigitalSignatureDetail = (
  requestData: FetchDigitalSignatureDetailRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureDetailResponse>(
    prepare(fetchDigitalSignatureDetailRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureDetail.requestConfig = fetchDigitalSignatureDetailRequestConfig;

/**
 * 接口 [查看电子签名列表↗](http://yapi.blacklake.tech/project/519/interface/api/86342) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_page_list`
 * @更新时间 `2022-01-24 10:44:10`
 */
export interface FetchDigitalSignaturePageListRequest {
  /**
   * 启用状态，1-启用中（默认） 2-停用中 0-已删除
   */
  activeStatus?: number;
  /**
   * 功能点code
   */
  functionCode?: string;
  /**
   * 电子签名名称(支持模糊搜索)
   */
  name?: string;
  /**
   * 请求页
   */
  page?: number;
  /**
   * 关联对象code
   */
  relatedObjectCode?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 筛选标识 1 全选 0 不全选 默认为 0
   */
  selectFlag?: number;
  /**
   * 每页大小
   */
  size?: number;
  /**
   * 排序 列表顺序表示排序顺序
   */
  sorter?: {
    /**
     * 排序字段
     */
    field?: string;
    /**
     * 排序规律 默认 asc，asc 升序 desc 降序
     */
    order?: string;
  }[];
}

/**
 * 接口 [查看电子签名列表↗](http://yapi.blacklake.tech/project/519/interface/api/86342) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_page_list`
 * @更新时间 `2022-01-24 10:44:10`
 */
export interface FetchDigitalSignaturePageListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      activeStatus?: number;
      /**
       * 功能点名称
       */
      functionName?: string;
      /**
       * 业务id
       */
      id?: number;
      /**
       * 电子签名名称(支持模糊搜索)
       */
      name?: string;
      /**
       * 关联对象名称
       */
      relatedObjectName?: string;
      /**
       * 备注
       */
      remark?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [查看电子签名列表↗](http://yapi.blacklake.tech/project/519/interface/api/86342) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_page_list`
 * @更新时间 `2022-01-24 10:44:10`
 */
type FetchDigitalSignaturePageListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_page_list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [查看电子签名列表↗](http://yapi.blacklake.tech/project/519/interface/api/86342) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_page_list`
 * @更新时间 `2022-01-24 10:44:10`
 */
const fetchDigitalSignaturePageListRequestConfig: FetchDigitalSignaturePageListRequestConfig = {
  mockUrl: mockUrl_0_0_0_11,
  devUrl: devUrl_0_0_0_11,
  prodUrl: prodUrl_0_0_0_11,
  path: '/user/domain/web/v1/digital_signature/_page_list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_11,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDigitalSignaturePageList',
};

/**
 * 接口 [查看电子签名列表↗](http://yapi.blacklake.tech/project/519/interface/api/86342) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_page_list`
 * @更新时间 `2022-01-24 10:44:10`
 */
export const fetchDigitalSignaturePageList = (
  requestData: FetchDigitalSignaturePageListRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignaturePageListResponse>(
    prepare(fetchDigitalSignaturePageListRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignaturePageList.requestConfig = fetchDigitalSignaturePageListRequestConfig;

/**
 * 接口 [编辑电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86348) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_update`
 * @更新时间 `2022-01-24 10:44:10`
 */
export interface FetchDigitalSignatureUpdateRequest {
  /**
   * 签名人配置列表
   */
  digitalSignatureConfigCOList: {
    /**
     * 是否选择所有用户 0-false 1-true
     */
    allUserFlag: number;
    /**
     * 是否选择当前登录人 0-false 1-true
     */
    currentUserFlag: number;
    /**
     * 业务id，id存在时为编辑
     */
    id?: number;
    /**
     * 角色ids
     */
    roleIds?: number[];
    /**
     * 角色列表
     */
    roles?: {
      /**
       * 角色编号
       */
      code?: string;
      /**
       * 角色描述
       */
      desc?: string;
      /**
       * 业务id
       */
      id?: number;
      /**
       * 角色名称
       */
      name?: string;
    }[];
    /**
     * 序号
     */
    seq: number;
    /**
     * 用户ids
     */
    userIds?: number[];
    /**
     * 用户列表
     */
    users?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    }[];
  }[];
  /**
   * 业务id
   */
  id: number;
  /**
   * 电子签名名称
   */
  name: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 编辑原因
   */
  updateReason?: string;
}

/**
 * 接口 [编辑电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86348) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_update`
 * @更新时间 `2022-01-24 10:44:10`
 */
export interface FetchDigitalSignatureUpdateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [编辑电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86348) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_update`
 * @更新时间 `2022-01-24 10:44:10`
 */
type FetchDigitalSignatureUpdateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_update',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [编辑电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86348) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_update`
 * @更新时间 `2022-01-24 10:44:10`
 */
const fetchDigitalSignatureUpdateRequestConfig: FetchDigitalSignatureUpdateRequestConfig = {
  mockUrl: mockUrl_0_0_0_11,
  devUrl: devUrl_0_0_0_11,
  prodUrl: prodUrl_0_0_0_11,
  path: '/user/domain/web/v1/digital_signature/_update',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_11,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDigitalSignatureUpdate',
};

/**
 * 接口 [编辑电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86348) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_update`
 * @更新时间 `2022-01-24 10:44:10`
 */
export const fetchDigitalSignatureUpdate = (
  requestData: FetchDigitalSignatureUpdateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureUpdateResponse>(
    prepare(fetchDigitalSignatureUpdateRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureUpdate.requestConfig = fetchDigitalSignatureUpdateRequestConfig;

/**
 * 接口 [查看电子签名配置人列表（用于开始签名）↗](http://yapi.blacklake.tech/project/519/interface/api/86624) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_config_list`
 * @更新时间 `2022-01-24 10:44:09`
 */
export interface FetchDigitalSignatureConfigListRequest {
  /**
   * 功能点 code
   */
  functionCode: string;
  /**
   * 签名会话 id（由电子签名服务生成）
   */
  signatureSessionId: number;
}

/**
 * 接口 [查看电子签名配置人列表（用于开始签名）↗](http://yapi.blacklake.tech/project/519/interface/api/86624) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_config_list`
 * @更新时间 `2022-01-24 10:44:09`
 */
export interface FetchDigitalSignatureConfigListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 电子签名配置人列表（包含签名情况）
     */
    digitalSignatureSignConfigVOList?: {
      /**
       * 是否选择所有用户 0-false 1-true
       */
      allUserFlag?: number;
      currentUser?: {
        avatarUrl?: string;
        email?: string;
        id?: number;
        name?: string;
        orgId?: number;
        phone?: string;
        username?: string;
      };
      /**
       * 是否选择当前登录人 0-false 1-true
       */
      currentUserFlag?: number;
      /**
       * 角色列表
       */
      roles?: {
        /**
         * 角色编号
         */
        code?: string;
        /**
         * 角色描述
         */
        desc?: string;
        /**
         * 业务id
         */
        id?: number;
        /**
         * 角色名称
         */
        name?: string;
      }[];
      /**
       * 序号
       */
      seq: number;
      signer?: {
        avatarUrl?: string;
        email?: string;
        id?: number;
        name?: string;
        orgId?: number;
        phone?: string;
        username?: string;
      };
      /**
       * 用户列表
       */
      users?: {
        avatarUrl?: string;
        email?: string;
        id?: number;
        name?: string;
        orgId?: number;
        phone?: string;
        username?: string;
      }[];
      /**
       * 校验状态 true-通过 false-不通过
       */
      verifyStatus?: boolean;
    }[];
    /**
     * 功能点Code
     */
    functionCode?: string;
    /**
     * 签名规则 0并行，1串行
     */
    ruleType?: number;
    /**
     * 签名会话 id（由电子签名服务生成）
     */
    signatureSessionId?: number;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [查看电子签名配置人列表（用于开始签名）↗](http://yapi.blacklake.tech/project/519/interface/api/86624) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_config_list`
 * @更新时间 `2022-01-24 10:44:09`
 */
type FetchDigitalSignatureConfigListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_config_list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [查看电子签名配置人列表（用于开始签名）↗](http://yapi.blacklake.tech/project/519/interface/api/86624) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_config_list`
 * @更新时间 `2022-01-24 10:44:09`
 */
const fetchDigitalSignatureConfigListRequestConfig: FetchDigitalSignatureConfigListRequestConfig = {
  mockUrl: mockUrl_0_0_0_11,
  devUrl: devUrl_0_0_0_11,
  prodUrl: prodUrl_0_0_0_11,
  path: '/user/domain/web/v1/digital_signature/_config_list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_11,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDigitalSignatureConfigList',
};

/**
 * 接口 [查看电子签名配置人列表（用于开始签名）↗](http://yapi.blacklake.tech/project/519/interface/api/86624) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_config_list`
 * @更新时间 `2022-01-24 10:44:09`
 */
export const fetchDigitalSignatureConfigList = (
  requestData: FetchDigitalSignatureConfigListRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureConfigListResponse>(
    prepare(fetchDigitalSignatureConfigListRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureConfigList.requestConfig = fetchDigitalSignatureConfigListRequestConfig;

/**
 * 接口 [签名↗](http://yapi.blacklake.tech/project/519/interface/api/86630) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_sign`
 * @更新时间 `2022-01-24 10:44:10`
 */
export interface FetchDigitalSignatureSignRequest {
  /**
   * 功能点 code
   */
  functionCode: string;
  /**
   * 密码
   */
  password: string;
  /**
   * 序号
   */
  seq: number;
  /**
   * 签名会话 id（由电子签名服务生成）
   */
  signatureSessionId: number;
  /**
   * 账号
   */
  username: string;
}

/**
 * 接口 [签名↗](http://yapi.blacklake.tech/project/519/interface/api/86630) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_sign`
 * @更新时间 `2022-01-24 10:44:10`
 */
export interface FetchDigitalSignatureSignResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 序号
     */
    seq?: number;
    /**
     * 签名会话 id（由电子签名服务生成）
     */
    signatureSessionId?: number;
    signer?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    /**
     * 校验状态 true-通过 false-不通过
     */
    verifyStatus?: boolean;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [签名↗](http://yapi.blacklake.tech/project/519/interface/api/86630) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_sign`
 * @更新时间 `2022-01-24 10:44:10`
 */
type FetchDigitalSignatureSignRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_sign',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [签名↗](http://yapi.blacklake.tech/project/519/interface/api/86630) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_sign`
 * @更新时间 `2022-01-24 10:44:10`
 */
const fetchDigitalSignatureSignRequestConfig: FetchDigitalSignatureSignRequestConfig = {
  mockUrl: mockUrl_0_0_0_11,
  devUrl: devUrl_0_0_0_11,
  prodUrl: prodUrl_0_0_0_11,
  path: '/user/domain/web/v1/digital_signature/_sign',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_11,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDigitalSignatureSign',
};

/**
 * 接口 [签名↗](http://yapi.blacklake.tech/project/519/interface/api/86630) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_sign`
 * @更新时间 `2022-01-24 10:44:10`
 */
export const fetchDigitalSignatureSign = (
  requestData: FetchDigitalSignatureSignRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureSignResponse>(
    prepare(fetchDigitalSignatureSignRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureSign.requestConfig = fetchDigitalSignatureSignRequestConfig;

const mockUrl_0_0_0_14 = 'http://yapi.blacklake.tech/mock/519' as any;
const devUrl_0_0_0_14 = '' as any;
const prodUrl_0_0_0_14 = '' as any;
const dataKey_0_0_0_14 = undefined as any;

/**
 * 接口 [获取租户信息↗](http://yapi.blacklake.tech/project/519/interface/api/86966) 的 **请求类型**
 *
 * @分类 [租户信息相关接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_12014)
 * @标签 `租户信息相关接口`
 * @请求头 `POST /user/domain/web/v1/organization/_get_display_info`
 * @更新时间 `2022-01-24 10:44:11`
 */
export interface FetchOrganizationGetDisplayInfoRequest {}

/**
 * 接口 [获取租户信息↗](http://yapi.blacklake.tech/project/519/interface/api/86966) 的 **返回类型**
 *
 * @分类 [租户信息相关接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_12014)
 * @标签 `租户信息相关接口`
 * @请求头 `POST /user/domain/web/v1/organization/_get_display_info`
 * @更新时间 `2022-01-24 10:44:11`
 */
export interface FetchOrganizationGetDisplayInfoResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 租户显示名称
     */
    displayName?: string;
    /**
     * 显示logoId
     */
    logoId?: number;
  };
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取租户信息↗](http://yapi.blacklake.tech/project/519/interface/api/86966) 的 **请求配置的类型**
 *
 * @分类 [租户信息相关接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_12014)
 * @标签 `租户信息相关接口`
 * @请求头 `POST /user/domain/web/v1/organization/_get_display_info`
 * @更新时间 `2022-01-24 10:44:11`
 */
type FetchOrganizationGetDisplayInfoRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/organization/_get_display_info',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [获取租户信息↗](http://yapi.blacklake.tech/project/519/interface/api/86966) 的 **请求配置**
 *
 * @分类 [租户信息相关接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_12014)
 * @标签 `租户信息相关接口`
 * @请求头 `POST /user/domain/web/v1/organization/_get_display_info`
 * @更新时间 `2022-01-24 10:44:11`
 */
const fetchOrganizationGetDisplayInfoRequestConfig: FetchOrganizationGetDisplayInfoRequestConfig = {
  mockUrl: mockUrl_0_0_0_14,
  devUrl: devUrl_0_0_0_14,
  prodUrl: prodUrl_0_0_0_14,
  path: '/user/domain/web/v1/organization/_get_display_info',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_14,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchOrganizationGetDisplayInfo',
};

/**
 * 接口 [获取租户信息↗](http://yapi.blacklake.tech/project/519/interface/api/86966) 的 **请求函数**
 *
 * @分类 [租户信息相关接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_12014)
 * @标签 `租户信息相关接口`
 * @请求头 `POST /user/domain/web/v1/organization/_get_display_info`
 * @更新时间 `2022-01-24 10:44:11`
 */
export const fetchOrganizationGetDisplayInfo = (
  requestData?: FetchOrganizationGetDisplayInfoRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchOrganizationGetDisplayInfoResponse>(
    prepare(fetchOrganizationGetDisplayInfoRequestConfig, requestData),
    ...args,
  );
};

fetchOrganizationGetDisplayInfo.requestConfig = fetchOrganizationGetDisplayInfoRequestConfig;

/**
 * 接口 [编辑租户显示信息↗](http://yapi.blacklake.tech/project/519/interface/api/87302) 的 **请求类型**
 *
 * @分类 [租户信息相关接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_12014)
 * @标签 `租户信息相关接口`
 * @请求头 `POST /user/domain/web/v1/organization/_update_display_info`
 * @更新时间 `2022-01-24 10:44:11`
 */
export interface FetchOrganizationUpdateDisplayInfoRequest {
  /**
   * 租户显示名称
   */
  displayName?: string;
  /**
   * 租户logo-Id
   */
  logoId?: number;
}

/**
 * 接口 [编辑租户显示信息↗](http://yapi.blacklake.tech/project/519/interface/api/87302) 的 **返回类型**
 *
 * @分类 [租户信息相关接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_12014)
 * @标签 `租户信息相关接口`
 * @请求头 `POST /user/domain/web/v1/organization/_update_display_info`
 * @更新时间 `2022-01-24 10:44:11`
 */
export interface FetchOrganizationUpdateDisplayInfoResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  fieldPermission?: {
    encoding?: string;
    noAccess?: string[];
    readonly?: string[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [编辑租户显示信息↗](http://yapi.blacklake.tech/project/519/interface/api/87302) 的 **请求配置的类型**
 *
 * @分类 [租户信息相关接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_12014)
 * @标签 `租户信息相关接口`
 * @请求头 `POST /user/domain/web/v1/organization/_update_display_info`
 * @更新时间 `2022-01-24 10:44:11`
 */
type FetchOrganizationUpdateDisplayInfoRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/organization/_update_display_info',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [编辑租户显示信息↗](http://yapi.blacklake.tech/project/519/interface/api/87302) 的 **请求配置**
 *
 * @分类 [租户信息相关接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_12014)
 * @标签 `租户信息相关接口`
 * @请求头 `POST /user/domain/web/v1/organization/_update_display_info`
 * @更新时间 `2022-01-24 10:44:11`
 */
const fetchOrganizationUpdateDisplayInfoRequestConfig: FetchOrganizationUpdateDisplayInfoRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_14,
    devUrl: devUrl_0_0_0_14,
    prodUrl: prodUrl_0_0_0_14,
    path: '/user/domain/web/v1/organization/_update_display_info',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_14,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchOrganizationUpdateDisplayInfo',
  };

/**
 * 接口 [编辑租户显示信息↗](http://yapi.blacklake.tech/project/519/interface/api/87302) 的 **请求函数**
 *
 * @分类 [租户信息相关接口↗](http://yapi.blacklake.tech/project/519/interface/api/cat_12014)
 * @标签 `租户信息相关接口`
 * @请求头 `POST /user/domain/web/v1/organization/_update_display_info`
 * @更新时间 `2022-01-24 10:44:11`
 */
export const fetchOrganizationUpdateDisplayInfo = (
  requestData: FetchOrganizationUpdateDisplayInfoRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchOrganizationUpdateDisplayInfoResponse>(
    prepare(fetchOrganizationUpdateDisplayInfoRequestConfig, requestData),
    ...args,
  );
};

fetchOrganizationUpdateDisplayInfo.requestConfig = fetchOrganizationUpdateDisplayInfoRequestConfig;

/* prettier-ignore-end */
