import _ from 'lodash';
import type { FormInstance } from 'antd';
import { CHINESE_ENGLISH_NUMBER_SPECIAL_REG } from 'src/utils/regExp';
import type { ControlType } from 'src/dict/sop';
import { controlDefaultValueMap, CONTROL_TITLE_LENGTH } from '../../constants';

export const getNameFactory = (dataIndex: number) => (name: string) => {
  const basePath = ['controls', dataIndex];

  return basePath.concat(_.compact(name.split('.')));
};

export const titleRules = [
  { required: true, message: '请输入标题' },
  { max: CONTROL_TITLE_LENGTH, message: `字符数最大为${CONTROL_TITLE_LENGTH}` },
  { pattern: CHINESE_ENGLISH_NUMBER_SPECIAL_REG, message: '支持输入字母、数字、中文和特殊字符' },
];

/**
 * 将整数转为二进制的数组
 * 例: 15 => [8, 4, 2, 1]
 */
export const splitIntegerIntoBinaryArray = (value: number) => {
  let op = value;
  let pos = 0;
  const result = [];

  while (op > 0) {
    if (op % 2 === 1) {
      result.push(Math.pow(2, pos));
    }
    op = Math.floor(op / 2);
    ++pos;
  }
  return result;
};

/** 可能被重置、而又会影响预览区的属性 */
const optionalPropsUsedByPreview = ['isRequired', 'inputPrompt'];

/** 将未使用的表单项重置为默认值 */
export const resetUnusedItems = (
  form: FormInstance,
  controlType: ControlType,
  controlIndex: number,
  forceUpdate: () => void,
) => {
  // 推迟到forceUpdate完成后再执行
  _.delay(() => {
    const defaultValue = controlDefaultValueMap.get(controlType)!;
    const currentControl = _.head(form.getFieldsValue()?.controls?.filter((i: any) => !!i));

    if (!currentControl) {
      return;
    }
    const controlList = form.getFieldValue('controls');
    const currentFields = _.keys(currentControl);
    const publicNotResetFields = ['stepId', 'referenceId'];
    const toBeReset = _.omit(defaultValue, [...currentFields, ...publicNotResetFields]);

    form.setFields([
      {
        name: ['controls', controlIndex],
        value: _.assign({}, controlList[controlIndex], toBeReset),
      },
    ]);
    // 如果重置了影响预览区的属性, 再刷新一次
    if (_.keys(toBeReset).some((prop) => optionalPropsUsedByPreview.includes(prop))) {
      // 推迟到set表单完成后再执行
      _.delay(forceUpdate, 100);
    }
  }, 100);
};
