/**
 * 废弃  已迁移到@blacklake/components
 */

/*
 * @Author: your name
 * @Date: 2021-05-07 09:24:30
 * @LastEditTime: 2021-05-18 14:10:44
 * @LastEditors: Vicky Yu
 * @Description: In User Settings Edit
 * @FilePath: /new-layout/src/components/sortList/BlSortList.tsx
 */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Col, Row, Table } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined, MinusCircleOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import { BlSortListProps, BlSortListValue } from './BlSortList.type';
import { primaryColor } from 'src/components/styles/color';

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = SortableElement((props: any) => <tr {...props} />);
const BlSortableContainer = SortableContainer((props: any) => <tbody {...props} />);

const BlSortList = <T extends BlSortListValue>(props: BlSortListProps<T>, ref: any) => {
  // eslint-disable-next-line max-len
  const {
    columns,
    initialValue = [],
    isDrag = true,
    listAction = [],
    onSortFinish,
    onDeleteFinish,
    onDelete,
    tableStyle,
  } = props;
  const [dataSource, setDataSource] = useState(initialValue);
  const [listActionHover, setListActionHover] = useState(false);
  const handleMinus = (key: string) => {
    onDelete && onDelete();
    const _dataSource = dataSource.filter((item) => key !== item.key);

    onDeleteFinish && onDeleteFinish(key, _dataSource);
    setDataSource(_dataSource);
  };
  const renderColumns = () => {
    const columnsList = [
      {
        title: '',
        dataIndex: 'sort',
        width: 80,
        className: 'drag-visible',
        render: (text: string, record: BlSortListValue) => {
          const { enableDelete, key } = record;

          return (
            <div style={{ display: 'flex' }}>
              {isDrag && <DragHandle />}
              {enableDelete === 'enable' && (
                <MinusCircleOutlined
                  onClick={() => {
                    handleMinus(key);
                  }}
                  style={{
                    cursor: 'pointer',
                    color: 'red',
                    marginLeft: '12px',
                  }}
                />
              )}
              {enableDelete === 'disable' && (
                <MinusCircleOutlined
                  style={{
                    color: 'rgb(153, 153, 153)',
                    marginLeft: '12px',
                  }}
                />
              )}
            </div>
          );
        },
      },
      ...columns,
    ];

    return columnsList;
  };

  // 拖拽相关
  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      const data = [].concat(dataSource as any);
      const newData = arrayMove(data, oldIndex, newIndex).filter((el) => !!el);
      // console.log('Sorted items: ', newData);

      onSortFinish && onSortFinish(newData);
      setDataSource(newData);
    }
  };

  const DraggableContainer = (props: any) => (
    <BlSortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ ...restProps }: any) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => x.index === restProps['data-row-key']);

    return <SortableItem index={index} {...restProps} />;
  };
  // 拖拽 end

  const handleSetDataSource = (data: T[]) => {
    setDataSource(data);
  };

  useImperativeHandle(ref, () => ({
    resetTable: (data: any) => setDataSource(data),
    getTableData: () => dataSource,
  }));

  return (
    <>
      <Table
        scroll={{
          y: 500,
        }}
        pagination={false}
        {...tableStyle}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        columns={renderColumns()}
        dataSource={dataSource}
      />
      {listAction.length > 0 && (
        <Row
          gutter={16}
          style={{
            marginLeft: 0,
            marginRight: 0,
            marginTop: '16px',
            padding: ' 2px 16px',
            border: listActionHover ? `1px dashed ${primaryColor}` : '1px dashed #ddd',
            transition: 'border .3s',
          }}
          onMouseEnter={() => {
            setListActionHover(true);
          }}
          onMouseLeave={() => {
            setListActionHover(false);
          }}
        >
          {listAction.map((item) => {
            return (
              <Col
                key={item.key}
                className="gutter-row"
                style={{ textAlign: 'center' }}
                span={24 / listAction.length}
              >
                {item.render(dataSource, handleSetDataSource)}
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};

export default forwardRef(BlSortList) as any;
