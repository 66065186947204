import * as constants from 'src/utils/constants';
import LocalStorage from 'src/utils/localStorage';
import qs from 'qs';
import { fetchLogin, FetchLoginRequest } from 'src/api/ytt/user-domain/user/login';
import _ from 'lodash';
import { blacklakeDomain, FIELDS } from 'src/utils/constants';
import { hashPassword } from 'src/utils/string';
import { CAPTCHA_EXPIRED_FLAG, SHOW_SMS_CODE_TYPE } from 'src/page/login/constants';
import volcano from 'src/utils/volcano';
import Cookies from 'js-cookie';

enum LOGIN_TYPE {
  common,
}

type LoginInterface = FetchLoginRequest & {
  remember?: boolean;
  type: 1 | 2;
  uuid?: string;
};

const setCookie = (cname: string, cvalue: string, expires: number, domain: string) => {
  Cookies.set(cname, cvalue, { expires, domain });
};

export const afterLoginHandler = async (res: any, remember = true) => {
  const token = _.get(res, 'data.token');

  if (token) {
    LocalStorage.set(FIELDS.TOKEN_NAME, token);

    setCookie('gc_x_token', token, 3, blacklakeDomain);

    await window?.globalStore?.dispatch({
      type: 'user/getUserInfo',
      payload: {
        token,
      },
    });

    volcano.webLogin();
  }

  LocalStorage.remove(`${CAPTCHA_EXPIRED_FLAG}${SHOW_SMS_CODE_TYPE.LOGIN}`);
  LocalStorage.remove(`${CAPTCHA_EXPIRED_FLAG}${SHOW_SMS_CODE_TYPE.RESET_PASSWORD}`);

  return res?.data;
};

const commonLogin = async (values: LoginInterface) => {
  const {
    pictureCode,
    pictureUuid,
    phone,
    smsCode,
    remember,
    code,
    password,
    username,
    uuid,
    type,
  } = values;
  let _password;

  if (password) {
    const { hashHex } = await hashPassword(password);
    _password = hashHex;
  }

  const query = {
    uuid,
    phone,
    smsCode,
    pictureCode,
    type,
    username,
    code,
    password: _password,
    keepLogin: remember,
    pictureUuid,
  };

  return new Promise((resolve, reject) => {
    fetchLogin(query)
      .then((res) => resolve(afterLoginHandler(res, remember)))
      .catch(reject);
  });
};

export const login = async (values: LoginInterface, loginType = LOGIN_TYPE.common) => {
  // eslint-disable-next-line default-case
  switch (loginType) {
    case LOGIN_TYPE.common:
      return await commonLogin(values);
  }
};

export const logout = () => {
  LocalStorage.remove(constants.FIELDS.TOKEN_NAME); // 删除token 信息
  LocalStorage.remove(constants.FIELDS.AUTH); // 删除用户信息
  LocalStorage.remove(constants.FIELDS.USER);
  volcano.webLogout();

  setTimeout(() => {
    const { href, pathname } = window.location;

    if (pathname !== '/login') {
      const loginPath = `/login${qs.stringify({ pre_path: href }, { addQueryPrefix: true })}`;

      window.reactRouterHistoryInstance && window.reactRouterHistoryInstance.push(loginPath);
    }
  }, 500);
};

// 密码至少8位，必须包含数字、大写字母、小写字母、字符符号中的至少3种，不支持中文、空格
export const validatorCheckPassword = () => {
  return (rule: any, value: string): any => {
    if (value.trim() === '') return Promise.resolve();
    // 不支持中文， 空格
    if (/[\u4e00-\u9fa5\s]+/g.test(value)) {
      return Promise.reject(new Error('密码格式错误'));
    }

    let count = 0;

    if (value.length < 8) {
      return Promise.reject(new Error('密码至少8位'));
    }

    // 必须包含数字
    if (/\d/g.test(value)) {
      count += 1;
    }

    // 大写字母
    if (/[a-z]+/g.test(value)) {
      count += 1;
    }

    // 小写字母
    if (/[A-Z]+/g.test(value)) {
      count += 1;
    }

    // 字符符合
    const reg = new RegExp(
      '[`~!@#$%^&*()_\\-+=<>?:"{}|,./;·！￥…（）—《》？：“”\\[\\]【】、；‘\'，。Ω≈ç√˜µ≤≥÷˚æ«「」’]',
      'g',
    );

    if (reg.test(value)) {
      count += 1;
    }

    if (count < 3) {
      return Promise.reject(new Error('密码格式错误'));
    }

    return Promise.resolve();
  };
};
