/**
 * 关联数据控件属性
 */
import { FC, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { Form, Input, Select, Checkbox } from 'antd';
import type { FormInstance } from 'antd';

import { ControlType } from 'src/dict/sop';
import { objectCodeRange as objectCodeMapping } from 'src/dict/sop/mappings';
import type {
  StepDetailData,
  LocalControlItem,
  CustomFieldGetListByObjectCode,
  LabelInValueSelectorProps,
} from '../../types';
import {
  ControlEditorContext,
  CONTROL_TITLE_LENGTH,
  INPUT_DEFAULT_PLACEHOLDER,
} from '../../constants';
import { getNameFactory, resetUnusedItems, titleRules } from './share';
// import { getFieldListObjectCode } from 'src/services/customFields/fieldList';
import { fetchCustomFieldGetListByObjectCode } from 'src/api/ytt/metadata-domain/customField';
import { FieldType } from 'src/dict/common';
import { associadateData2Lv, lv2AssociadateData, sf2lb } from '../../share/controlFormat';
import { FieldCategory } from 'src/dict/customField';

interface Props {
  form: FormInstance<StepDetailData>;
  localKey: string;
}

// 描述信息最大值
const MAX_REMARK_LEN = 1000;

export const AssociatedDataInputProperties: FC<Props> = ({ form, localKey }) => {
  const fieldTypeOfassociation = [
    FieldType.text,
    FieldType.textArea,
    FieldType.number,
    FieldType.select,
    FieldType.multiSelect,
    FieldType.boolean,
    FieldType.integer,
    FieldType.date,
  ];

  const { forceUpdate } = useContext(ControlEditorContext);
  const controlList = form.getFieldValue('controls');
  const dataIndex = controlList.findIndex((item: LocalControlItem) => item._localKey === localKey);
  const getName = getNameFactory(dataIndex);
  const [objectList, setObjectList] = useState<LabelInValueSelectorProps[]>([]);

  const { showField } = controlList[dataIndex];

  const setFieldValueofCondition = (
    conditions: string[],
    formItemName: string,
    defaultValue: any,
  ) => {
    let isTouched = false;

    for (const value of conditions) {
      if (form.isFieldTouched(getName(value))) {
        isTouched = true;
        break;
      }
    }
    const fieldValue = !isTouched ? form.getFieldValue(getName(formItemName)) : defaultValue;

    form.setFields([
      {
        name: getName(formItemName),
        value: fieldValue,
      },
    ]);
  };

  const relateObjectValue = form.getFieldValue(getName('relateObject'));

  useEffect(() => {
    if (relateObjectValue) {
      // getFieldListObjectCode(relateObjectValue).then((res) => {
      fetchCustomFieldGetListByObjectCode({ objectCode: relateObjectValue }).then((res) => {
        const resData: CustomFieldGetListByObjectCode[] = res?.data ?? [];

        const filterData = resData
          ?.filter((item) => {
            return (
              item?.fieldCategory === FieldCategory.preFields &&
              item?.fieldType &&
              fieldTypeOfassociation.includes(item?.fieldType)
            );
          })
          .map(sf2lb);

        setObjectList(filterData as LabelInValueSelectorProps[]);
        const filterMainField = resData.find((item) => item?.isName === 1);

        form.setFields([
          {
            name: getName('mainField'),
            value: {
              fieldCode: filterMainField?.fieldCode,
              fieldName: filterMainField?.fieldName,
            },
          },
        ]);
      });
    } else {
      setObjectList([]);
    }
    setFieldValueofCondition(['relateObject'], 'showField', []);
  }, [relateObjectValue, localKey]);

  // 展示字段列表变化时，校验当前选中的showField 是否有效
  if (_.isEmpty(controlList)) {
    return null;
  }

  if (dataIndex === -1) {
    return null;
  }

  return (
    <Form
      layout="vertical"
      form={form}
      // onValuesChange={() => {
      //   forceUpdate();
      //   resetUnusedItems(form, ControlType.associatedData, dataIndex, forceUpdate);
      // }}
      onValuesChange={() => {
        forceUpdate();
        resetUnusedItems(form, ControlType.associatedData, dataIndex, forceUpdate);
      }}
    >
      <Form.Item label="标题" name={getName('name')} rules={titleRules}>
        <Input max={CONTROL_TITLE_LENGTH} placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>

      <Form.Item
        label="关联数据目标"
        name={getName('relateObject')}
        rules={[{ required: true, message: '请选择关联数据目标' }]}
      >
        <Select options={objectCodeMapping} placeholder={'请选择'} />
      </Form.Item>
      <Form.Item
        label="展示字段"
        name={getName('showField')}
        getValueProps={associadateData2Lv}
        getValueFromEvent={lv2AssociadateData}
      >
        <Select<any>
          value={associadateData2Lv(showField)}
          options={objectList}
          labelInValue
          allowClear
          mode="multiple"
          showSearch
          filterOption={(input, option) =>
            _.toString(option?.label)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          placeholder={'请选择'}
        />
      </Form.Item>
      <Form.Item label="" name={getName('mainField')} style={{ display: 'none' }} />

      <Form.Item name={getName('enableCheckbox')} valuePropName="checked">
        <Checkbox>是否支持多选</Checkbox>
      </Form.Item>
      <Form.Item label="校验" style={{ marginBottom: 0 }}>
        <Form.Item name={getName('isRequired')} valuePropName="checked">
          <Checkbox>必填</Checkbox>
        </Form.Item>
      </Form.Item>
      <Form.Item label="描述信息" name={getName('remark')}>
        <Input.TextArea
          placeholder={INPUT_DEFAULT_PLACEHOLDER}
          maxLength={MAX_REMARK_LEN}
          showCount
        />
      </Form.Item>
    </Form>
  );
};

export default AssociatedDataInputProperties;
