import { useEffect, useState } from 'react';
import { Modal, Form, Input, message } from 'antd';
import _, { isEmpty, isNumber } from 'lodash';
import { BlSortFormList, showErrorListMessage, TagList, Tooltip } from 'src/components';
import {
  fetchReceiveNoteListReceiveLayer,
  fetchReceiveNoteReceiveGoods,
} from 'src/api/ytt/order-domain/deliveryOrder';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import moment from 'moment';
import { getMaterialAttrs } from 'src/entity/material';
import { replaceSign } from 'src/utils/constants';

interface Props {
  visible: boolean;
  isMaterial?: boolean;
  ids?: number[];
  onCancel?: () => void;
  onSubmit?: () => void;
}

const Receive = (props: Props) => {
  const { visible, isMaterial = false, ids, onCancel, onSubmit } = props;
  const [list, setList] = useState<any[]>([]);
  const [form] = Form.useForm();

  const getReceiveList = async () => {
    if (isEmpty(ids)) return;
    const res = await fetchReceiveNoteListReceiveLayer(isMaterial ? { itemIds: ids } : { ids });

    if (res?.code === 200) {
      setList(res.data || []);
      form.setFieldsValue({
        items:
          res.data?.map((item) => ({
            itemId: item.id,
            receiveAmount: item?.receiveAmount?.amount,
          })) || [],
      });
    }
  };

  const onFinish = async () => {
    const values = await form.validateFields();

    if (isEmpty(values.items)) {
      return;
    }
    const res = await fetchReceiveNoteReceiveGoods(values.items);

    if (res?.code === 200) {
      const { failCount = 0, successCount = 0, failDetails = [] } = res?.data ?? {};

      if (failCount) {
        showErrorListMessage({
          failCount,
          successCount,
          title: '提示',
          data: _.map(failDetails, (item) => ({ ...item })),
          columns: [
            { title: '行号', dataIndex: 'seqNum', width: 80 },
            { title: '编号', dataIndex: 'code', width: 100 },
            { title: '物料编号', dataIndex: 'materialCode', width: 100 },
            { title: '物料名称', dataIndex: 'materialName', width: 100 },
            { title: '', dataIndex: 'key', width: 1, render: () => null },
          ],
        });
      } else {
        message.success('收货成功！');
      }
      onSubmit?.();
    }
  };

  useEffect(() => {
    if (visible) {
      getReceiveList();
    }
  }, [visible]);

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        dataIndex: 'receiveNoteCode',
        title: '收货单编号',
        width: 150,
        render: (text: string, field) => (
          <div key={field.name}>{list[field.name]?.receiveNoteCode}</div>
        ),
      },
      {
        dataIndex: 'shipmentNoteCode',
        title: '协同发货单号',
        width: 150,
        render: (text: string, field) => (
          <div key={field.name}>{list[field.name]?.shipmentNoteCode}</div>
        ),
      },
      {
        dataIndex: 'seqNum',
        title: '行号',
        width: 80,
        render: (text: string, field) => <div key={field.name}>{list[field.name]?.seqNum}</div>,
      },
      {
        dataIndex: 'materialCode',
        title: '物料编号',
        width: 110,
        render: (text: string, field) => (
          <div key={field.name}>{list[field.name]?.material?.baseInfo?.code}</div>
        ),
      },
      {
        dataIndex: 'materialCode',
        title: '物料名称',
        width: 110,
        render: (text: string, field) => (
          <div key={field.name}>{list[field.name]?.material?.baseInfo?.name}</div>
        ),
      },
      {
        title: '物料属性',
        width: 150,
        dataIndex: 'materialAttr',
        render: (text: string, field) => (
          <TagList dataSource={getMaterialAttrs(list[field.name]?.material)} />
        ),
      },
      {
        title: '应收数',
        dataIndex: 'planReceiveAmount',
        width: 150,
        render: (text: string, field) => {
          const { planReceiveAmount } = list[field.name] || {};

          return (
            <div key={field.name} style={{ display: 'flex' }}>
              <span style={{ marginRight: 10 }}>{planReceiveAmount?.amountDisplay}</span>
              <Tooltip text={planReceiveAmount?.unitName ?? '-'} width={80} />
            </div>
          );
        },
      },
      {
        dataIndex: 'planReceiveTime',
        title: '应收时间',
        width: 180,
        render: (text: string, field) => {
          const { planReceiveTime } = list[field.name];

          if (!isNumber(planReceiveTime)) {
            return '-';
          }
          return (
            <div key={field.name}>{moment(planReceiveTime).format('YYYY/MM/DD HH:mm:ss')}</div>
          );
        },
      },
      {
        dataIndex: 'receiveAmount',
        title: '接收数',
        width: 220,
        render: (text: string, field: any) => {
          const unit = list[field.name]?.unit;
          const enablePrecision = unit?.enablePrecision;
          const precisionFigure = unit?.precisionFigure || 10;

          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Item
                style={{ marginBottom: 0, marginRight: 10 }}
                validateFirst
                validateTrigger={['onChange', 'onBlur']}
                name={[field.name, 'receiveAmount']}
                rules={_.compact([
                  { required: true, message: '请填写' },
                  {
                    validator: numberMinMaxCheck({
                      min: 0,
                      minAllowEqual: false,
                    }),
                  },
                  enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
                ])}
              >
                <Input style={{ width: 80 }} placeholder="请输入" />
              </Form.Item>
              <Tooltip text={unit?.name ?? '-'} width={80} />
            </div>
          );
        },
      },
      {
        title: '采购订单编号',
        dataIndex: 'purchaseOrderCode',
        width: 150,
        render: (text: string, field) => (
          <div key={field.name}>{list[field.name]?.purchaseOrderCode ?? replaceSign}</div>
        ),
      },
      {
        title: '采购订单行号',
        width: 150,
        dataIndex: 'purchaseOrderItemSeqNum',
        render: (text: string, field) => (
          <div key={field.name}>{list[field.name]?.purchaseOrderItemSeqNum ?? replaceSign}</div>
        ),
      },
      {
        title: '交货计划单编号',
        dataIndex: 'deliveryScheduleNoteCode',
        width: 150,
        render: (text: string, field) => (
          <div key={field.name}>{list[field.name]?.deliveryScheduleNoteCode ?? replaceSign}</div>
        ),
      },
      {
        title: '交货计划单行号',
        width: 150,
        dataIndex: 'deliveryScheduleNoteLineNo',
        render: (text: string, field) => (
          <div key={field.name}>{list[field.name]?.deliveryScheduleNoteLineNo ?? replaceSign}</div>
        ),
      },
    ];
  };

  return (
    <Modal
      destroyOnClose
      keyboard={false}
      maskClosable={false}
      width={1300}
      title="收货"
      visible={visible}
      onCancel={() => onCancel?.()}
      onOk={onFinish}
    >
      <BlSortFormList
        isNeedAdd={false}
        isNeedDrag={false}
        isNeedDelete={false}
        form={form}
        name={'items'}
        renderColumns={() => getColumns()}
      />
    </Modal>
  );
};

export default Receive;
