import { Tag } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import _ from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { BlIcon } from 'src/components';
import authDict from 'src/utils/auth';
import { replaceSign } from 'src/utils/constants';
import { DetailType } from '../interface';
import { toEdit, toReview } from '../navigation';

interface Props extends RouteComponentProps {
  detail: DetailType;
}

const BaseInfo = (props: Props) => {
  const { detail, history } = props;

  const baseMenu = _.compact([
    {
      key: 'edit',
      auth: authDict.Cus_eReport_edit,
      onClick: () => {
        history.push(toEdit(detail.id!));
      },
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ]);
  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '报表名称',
        dataIndex: 'name',
        isFullLine: true,
        render: (name: string) => {
          return (
            <a
              onClick={() => {
                history.push(toReview(detail.id!));
              }}
            >
              {name}
            </a>
          );
        },
      },
      {
        label: '查看用户',
        isFullLine: true,
        dataIndex: 'allowViewUser',
        render: (val?: any[]) => {
          return val?.map((item: any) => <Tag key={item.id}>{item.name}</Tag>) || replaceSign;
        },
      },
      {
        label: '备注',
        dataIndex: 'remark',
        isFullLine: true,
      },
    ],
    column: 2,
  };

  return (
    <DetailLayout
      title="定制化报表配置详情"
      info={[baseInfo]}
      dataSource={detail}
      baseMenu={baseMenu}
    />
  );
};

export default withRouter(BaseInfo);
