import { Form, message as Message } from 'antd';
import { match, RouteComponentProps } from 'react-router-dom';
import DetailsBaseInfo from './baseInfo';
import { useEffect, useState } from 'react';
import { fetchApprovalDetail } from 'src/api/ytt/metadata-domain/approvalList';
import {
  fetchApprovalTaskDetail,
  fetchApprovalTaskApprove,
  fetchApprovalTaskReject,
  fetchApprovalTaskPreCheck,
} from 'src/api/ytt/metadata-domain/approvalTask';
import { ApprovalDetailProps } from '../constant';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import authDict from 'src/utils/auth';
import { SingleOperationLogComponent } from 'src/components';
import { filter } from 'lodash';

interface DetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

export default function CustomerDetail(props: DetailProps) {
  const { match, history } = props;
  const approvalId = Number(match?.params?.id);
  const [dataSource, setDataSource] = useState<ApprovalDetailProps>();
  const [canOperate, setCanOperate] = useState<boolean>(false);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [form] = Form.useForm();
  const refresh = () => {
    setRefreshMarker(Date.now());
  };
  const isApprovalTask = history.location.pathname.includes('approval_task');

  const handleApproveAgain = async (id: number, type: string) => {
    // 通过审批
    try {
      if (!id) return;

      const { code: Code, message: errorMessage } = await fetchApprovalTaskPreCheck({ id });

      if (Code !== 200) return Message.warning(errorMessage);

      const fetchFn = type === 'pass' ? fetchApprovalTaskApprove : fetchApprovalTaskReject;

      const { code, message } = await fetchFn({
        approvalTaskId: id,
        approveComments: form.getFieldValue('comment'),
      });

      if (code === 200) {
        Message.success(`${type === 'pass' ? '通过审批' : '驳回审批'}`);
        refresh();
        return;
      }

      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  const handleApproveCheck = async (id: number, type: string) => {
    try {
      if (!id) return;

      const { code, message } = await fetchApprovalTaskPreCheck({ id }); // 点击通过/驳回以及再次进去审批的弹框点击确认 需要双重校验是否可以通过/驳回 防止多人同时操作

      if (code !== 200) return Message.warning(message);

      const title = type === 'pass' ? '确认要通过审批吗？' : '确认要驳回审批吗？';

      BLDelConfirm(
        title,
        form,
        () => handleApproveAgain(id, type),
        () => {},
        'comment',
        '请输入审批意见',
      );
    } catch (error) {
      console.log(error);
    }
  };

  const baseMenu = [
    {
      key: 'reject',
      onClick: () => {
        handleApproveCheck(approvalId, 'reject');
      },
      title: '驳回',
    },
    {
      key: 'log',
      auth: isApprovalTask
        ? authDict.approvalTask_operrecord
        : authDict.approvalInstance_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'approve',
      onClick: () => {
        handleApproveCheck(approvalId, 'pass');
      },
      title: '通过',
    },
  ];

  const relBaseMenu = canOperate ? baseMenu : filter(baseMenu, (e: any) => e?.key === 'log');

  const handleFetchBaseInfo = async () => {
    try {
      const fetchDetail = isApprovalTask ? fetchApprovalTaskDetail : fetchApprovalDetail;
      const { data } = await fetchDetail({ id: approvalId });
      const { canAudit } = data;

      setDataSource(data);
      setCanOperate(canAudit);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    approvalId && handleFetchBaseInfo();
  }, [approvalId, refreshMarker]);

  return (
    <>
      <DetailsBaseInfo
        isApprovalTask={isApprovalTask}
        title={isApprovalTask ? '审批任务详情' : '审批单详情'}
        dataSource={dataSource!}
        baseMenu={relBaseMenu}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          closeDetail={() => {
            setVisibleLog(false);
          }}
          visible={visibleLog}
          instanceId={approvalId}
          objectCode={isApprovalTask ? 'ApprovalTask' : 'ApprovalInstance'}
        />
      )}
    </>
  );
}
