import { qs } from 'src/utils';
// import { ObjectCategory } from 'src/dict/common';
import type { StandardObjectData } from './type';

export const toFieldDetailList = ({
  id,
  objectName,
  objectCategory,
  objectCode,
  layoutTypes,
  canSetIsRequired,
}: StandardObjectData) => {
  // 自定义对象本期不做；标准对象按白名单支持
  const query = {
    objectCategory,
    objectName,
    objectCode,
    layoutTypes: (layoutTypes ?? []).join(','),
    canSetIsRequired,
  };

  return `/systemManagement/bizObject/${id}/settings?${qs.stringify(query)}`;
};

/** 从子页面回到对象设置页，并保留url参数 */
export const backToSettings = (id: number | string) =>
  `/systemManagement/bizObject/${id}/settings${location.search}`;
