/**
 * @description 电子签名参照映射
 * @author xuzhiyong
 */

import { ACTIVE_STATUS_STR, RULE_TYPE } from '.';
import { mappingsFactory } from '../utils';

/** 电子签名启用状态参照 */
export const activeStatusStrEnum = mappingsFactory(
  ['删除', ACTIVE_STATUS_STR.deleted],
  ['启用中', ACTIVE_STATUS_STR.enabled],
  ['停用中', ACTIVE_STATUS_STR.disabled],
);

/** 电子签名签名规则参照 */
export const ruleTypeEnum = mappingsFactory(
  ['并行', RULE_TYPE.parallel],
  ['串行', RULE_TYPE.serial],
);
