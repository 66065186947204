import { FieldType } from 'src/dict/common';

export const PRE_OBJECT = {
  Equipment: 'Equipment',
  ResourceClassification: 'ResourceClassification',
  Location: 'Location',
  Warehouse: 'Warehouse',
  StorageLocation: 'StorageLocation',
  User: 'User',
  Unit: 'Unit',
  Zone: 'Zone',
};

// 以下常量来自《字段类型规范》
// https://blacklake.feishu.cn/wiki/wikcnFL24eChhVHQUdtIv8Bz0zf?table=tbl1XxAAujxMUrJi&view=vewfyhZLs6

/** 支持过滤的字段 */
export const FILTABLE_FIELD_TYPES = [
  FieldType.text,
  FieldType.select,
  FieldType.multiSelect,
  FieldType.boolean,
  FieldType.integer,
  FieldType.number,
  FieldType.date,
  FieldType.reference,
  FieldType.relation,
];

/** 支持排序的字段 */
export const SORTABLE_FIELD_TYPES = [
  FieldType.text,
  FieldType.select,
  FieldType.boolean,
  FieldType.integer,
  FieldType.number,
  FieldType.date,
];

// === 《字段类型规范》常量结束 ===

const objSettingsPathPrefix = '/systemManagement/bizObject/:objectId/settings';

export const pathPattens = {
  createLayout: `${objSettingsPathPrefix}/createLayout`,
  editLayout: `${objSettingsPathPrefix}/editLayout/:layoutId`,
  copyLayout: `${objSettingsPathPrefix}/copyLayout/:layoutId`,
  viewLayout: `${objSettingsPathPrefix}/viewLayout/:layoutId`,
  assignLayout: `${objSettingsPathPrefix}/assignLayout`,
  createBizType: `${objSettingsPathPrefix}/createBizType`,
  editBizType: `${objSettingsPathPrefix}/editBizType/:bizTypeId`,
  fieldCascadingList: `${objSettingsPathPrefix}/fieldCascading`,
};
