/**
 * @description 触发事件，触发条件配置编辑表格组件
 */
import { useState } from 'react';
import { Form, Button, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';

import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import _ from 'lodash';

import { BlTable, BlIcon } from 'components';
import { appDict } from 'src/dict';
import { ActionValueTypeEnum, ActionTypeEnum } from 'src/dict/triggerAction';
import _Array from 'src/utils/dataTypes/array';

import { useStandardBizToCascaderOption } from '../../triggerRule/share/FieldIdCascader';

import ValueComponent from './ValueComponent';
import FieldSelect from './FieldSelect';
import { withFormItem } from './withFormItem';

import { DetailItem, BaseOption, CascaderOption } from '../constant';
import styles from './styles.module.scss';

const { valueTypeOptions } = appDict.triggerAction;

type NamePath = string | number;

interface ObjectConfigEditTableProps {
  namePath: NamePath;
  /** 当前业务对象，即触发事件绑定的业务对象 */
  objectCode: string;
  /** 触发规则绑定的业务对象code */
  ruleBindObjectCode?: string;
  onChange?: (data: any) => void;
  value?: DetailItem[];
  disabled?: boolean;
  form: FormInstance;
  fieldOptions?: BaseOption[];
  loading?: boolean;
}

interface FormListFieldItem extends DetailItem {
  key: FormListFieldData['key'];
  disabled: boolean;
  field: FormListFieldData;
}

// 校验重复
const validateRepeat = (recordForm: FormInstance, filedName: NamePath, validateName: string) => {
  return (_rule: any, value: any) => {
    return new Promise((resolve, reject) => {
      if (!value) {
        return resolve(true);
      }
      if (typeof value?.value !== 'string') {
        return resolve(true);
      }
      const valueList = recordForm.getFieldValue(filedName);

      // 从 valueList 找出重复的项组成 []
      const list = valueList.map((item: any) => {
        const value = _.get(item, validateName);

        if (typeof value !== 'string') {
          return null;
        }
        return JSON.parse(value)?.id;
      });

      const repeatList = _Array.findDuplicates(list);

      // value 如果存在 在重复项 [] 中，则 reject
      const condition = repeatList.indexOf(JSON.parse(value.value)?.id);

      if (condition !== -1) {
        reject('已存在该选项！');
        return;
      }
      return resolve(true);
    });
  };
};

/** 获取table的columns */
const getColumns: (
  remove: (index: number) => void,
  form: FormInstance,
  valueTypeOptionList: BaseOption[],
  disabled?: boolean,
  fieldOptions?: BaseOption[],
  /** 值类型为「字段引用」时，给Cascader组件用的options */
  bizValueOptionsInfo?: { options: CascaderOption[]; loading: boolean },
  /** 值类型为「引用触发规则」时，给Cascader组件用的options */
  triggerOptionsInfo?: { options: CascaderOption[]; loading: boolean },
) => ColumnProps<FormListFieldItem>[] = (
  remove,
  form,
  valueTypeOptionList,
  disabled,
  fieldOptions = [],
  bizValueOptionsInfo,
  triggerOptionsInfo,
) => {
  const dataCol: ColumnProps<FormListFieldItem>[] = [
    {
      title: '序号',
      dataIndex: 'seq',
      key: 'seq',
      width: 40,
      render: (_, { field }, index) => (
        <Form.Item name={[field.name, 'seq']} noStyle>
          {index + 1}
        </Form.Item>
      ),
    },
    {
      title: '字段',
      dataIndex: 'fieldId',
      width: 200,
      render: (_, { field, disabled }, index) => {
        return (
          <FieldSelect
            name="fieldId"
            field={field}
            dependenceParamsPath={['objectCode.value', `details.${index}.defaultField`]}
            disabled={disabled}
            fieldIdOptions={fieldOptions}
            rules={[
              { validator: validateRepeat(form, 'details', 'fieldId.value') },
              { required: true, message: '字段不可为空' },
            ]}
          />
        );
      },
    },
    {
      title: '值类型',
      dataIndex: 'valueType',
      width: 200,
      render: (_, { field, disabled }) => {
        const ValueType = withFormItem(Select);

        return (
          <ValueType
            name="valueType"
            field={field}
            options={valueTypeOptionList}
            initialValue={ActionValueTypeEnum.value}
            disabled={disabled}
          />
        );
      },
    },
    {
      title: '值',
      dataIndex: 'value',
      width: 200,
      render: (_, { field, disabled }, index) => {
        return (
          <Form.Item
            shouldUpdate={(
              { details, triggerRuleId },
              { details: _details, triggerRuleId: _triggerRuleId },
            ) => {
              return (
                details[index]?.valueType !== _details[index]?.valueType ||
                triggerRuleId?.value !== _triggerRuleId?.value ||
                details[index]?.fieldId?.value !== _details[index]?.fieldId?.value
              );
            }}
            noStyle
          >
            {(form) => {
              const triggerRule = form.getFieldValue(['triggerRuleId', 'value']);
              const actionType = form.getFieldValue('actionType');
              const valueType = form.getFieldValue(['details', index, 'valueType']);
              const fieldInfo = form.getFieldValue(['details', index, 'fieldId', 'value']);

              return (
                <ValueComponent
                  key={field.key}
                  namePath={[index, 'value']}
                  valueType={valueType}
                  triggerRule={triggerRule ? JSON.parse(triggerRule) : triggerRule}
                  fieldInfo={fieldInfo ? JSON.parse(fieldInfo) : fieldInfo}
                  actionType={actionType}
                  disabled={disabled}
                  bizInfo={bizValueOptionsInfo}
                  triggerInfo={triggerOptionsInfo}
                />
              );
            }}
          </Form.Item>
        );
      },
    },
  ];
  const operatColItem: ColumnProps<FormListFieldItem> = {
    title: '操作',
    dataIndex: 'option',
    width: 80,
    render: (_, { disabled, defaultField }, index) => (
      <Form.Item>
        <Button
          onClick={() => remove(index)}
          disabled={disabled || !!defaultField}
          icon={<BlIcon type="iconlieshanchu" />}
          type="text"
          style={{ color: defaultField || disabled ? '' : 'red' }}
        />
      </Form.Item>
    ),
  };

  return disabled ? dataCol : [...dataCol, operatColItem];
};

const ObjectConfigEditTable: React.FC<ObjectConfigEditTableProps> = ({
  namePath,
  disabled,
  form,
  fieldOptions = [],
  objectCode,
  ruleBindObjectCode,
  loading,
}) => {
  const [bizLoading, setBizLoading] = useState<boolean>(false);
  const [triggerLoading, setTriggerLoading] = useState<boolean>(false);

  /** 事件类型为「新建」时，返回true */
  const isCreateActionType = () => form.getFieldValue('actionType') === ActionTypeEnum.createRecord;

  // 事件类型为新建时，值类型才有「字段引用」的选项
  /** 值类型为「字段引用」时，给Cascader组件用的options */
  const valueOptionsFromBiz = useStandardBizToCascaderOption(
    isCreateActionType() ? objectCode : undefined,
    4, // depth
    { setLoading: setBizLoading },
  );

  /** 值类型为「引用触发规则」时，给Cascader组件用的options */
  const valueOptionsFromTrigger = useStandardBizToCascaderOption(ruleBindObjectCode, 4, {
    setLoading: setTriggerLoading,
  });

  /** 根据事件类型，获取值类型的选项 */
  const getValueTypeOptions = () => {
    /** 更新事件类型的值选项仅支持选择 ['值', '引用触发规则'] */
    const updateLabels = ['值', '引用触发规则.业务对象', '引用触发规则.业务字段'];

    if (isCreateActionType()) {
      return valueTypeOptions;
    }
    return _.filter(valueTypeOptions, ({ label }) => updateLabels.includes(label));
  };

  return (
    <Form.List name={namePath}>
      {(fields, { add, remove }) => {
        const dataColumns = getColumns(
          remove,
          form,
          getValueTypeOptions(),
          disabled,
          fieldOptions,
          { options: valueOptionsFromBiz, loading: bizLoading },
          { options: valueOptionsFromTrigger, loading: triggerLoading },
        );
        const details = form?.getFieldValue('details');
        const data = fields?.map((field) => {
          return { field, ...details?.[field?.name], disabled, key: field.key } ?? {};
        });

        return (
          <BlTable
            className={styles.editTable}
            columns={dataColumns}
            dataSource={data}
            loading={loading}
            id="details"
            key="key"
            pagination={false}
            rowKey={(field) => field?.key}
            footer={
              disabled
                ? undefined
                : () => {
                    return (
                      // <AddItemButton onAdd={() => add({ valueType: ActionValueTypeEnum.value })} />
                      <Button
                        onClick={() => add({ valueType: ActionValueTypeEnum.value })}
                        icon={<BlIcon type="iconxinjiantianjia" />}
                        type="dashed"
                        className={styles.addItemButton}
                      >
                        添加一行
                      </Button>
                    );
                  }
            }
          />
        );
      }}
    </Form.List>
  );
};

export default ObjectConfigEditTable;
