import React, { useEffect } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { FormInstance, Input } from 'antd';
import CorrelationTestItemTable from './correlationTestItemTable';
import { textValidator1 } from 'src/utils/formValidators';
import { CheckClassDetailType, QcCheckItemListType } from '../interface';

const { TextArea } = Input;

interface EditInfoProps {
  onCancel: () => void;
  onFinish: () => void;
  title: string;
  initialData: CheckClassDetailType;
  form: FormInstance;
  checkSource: QcCheckItemListType[];
  setCheckItemList: (lists: QcCheckItemListType[]) => void;
}

const EditBaseInfo = (props: EditInfoProps) => {
  const { onCancel, title, onFinish, initialData, form, checkSource, setCheckItemList } = props;

  useEffect(() => {
    if (initialData) {
      form?.setFieldsValue({
        name: initialData?.name,
        id: initialData?.id,
      });
    }
  }, [initialData]);

  const baseInfo: DataFormLayoutInfoBlock = {
    items: [
      {
        label: '检验项分类名称',
        name: 'name',
        rules: [
          { required: true, message: '检验项分类名称不能为空' },
          { max: 255, message: '不超过255个字符' },
          { validator: textValidator1 },
        ],
        render: () => <Input />,
      },
      {
        label: ' ',
        isFullLine: true,
        render: () => (
          <CorrelationTestItemTable setCheckItemList={setCheckItemList} checkSource={checkSource} />
        ),
      },
      {
        label: '编辑原因',
        name: 'operateReason',
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
    ],
  };

  return (
    <DataFormLayout
      form={form}
      title={title}
      info={[baseInfo]}
      onCancel={onCancel}
      onFinish={onFinish}
    />
  );
};

export default EditBaseInfo;
