import { FormInstance } from 'antd/es/form/Form';
import { NamePath } from 'antd/lib/form/interface';
import _ from 'lodash';
import { appDict, appEnum } from 'src/dict';
const { conditionType } = appDict.triggerRule;
const { CustomField } = appEnum;

/** 不同值选项对应的枚举值 */
export enum FieldType {
  /** 单行文本 or 多行文本 */
  text = 1,
  /** 数值 or 整数 or日期时间 */
  numOrDate,
  /** 单选 or 布尔 or 关联关系对一 */
  singleSelect,
  /** 多选 or 关联关系对多 */
  multiSelect,
}

/** 根据字段类型返回对应的值选项的枚举值 */
const getFieldTypeOption = (fieldType: number, targetType: number) => {
  switch (fieldType) {
    case CustomField.FieldType.text:
    case CustomField.FieldType.textArea:
      return FieldType.text;
    case CustomField.FieldType.number:
    case CustomField.FieldType.date:
    case CustomField.FieldType.integer:
      return FieldType.numOrDate;
    case CustomField.FieldType.select:
    case CustomField.FieldType.boolean:
      return FieldType.singleSelect;
    case CustomField.FieldType.multiSelect:
      return FieldType.multiSelect;
    case CustomField.FieldType.relation:
    case CustomField.FieldType.relationSub:
    case CustomField.FieldType.subordinate:
      return targetType === appEnum.CustomField.TargetType.singleChoice
        ? FieldType.singleSelect
        : FieldType.multiSelect;
  }
};

/** 根据label过滤符合要求的conditionType的选项 */
const getOption = (labelArr: string[]) =>
  _.filter(conditionType, ({ label }) => labelArr.includes(label));

/** 返回符合要求的条件选项 */
export const getConditionTypeOption = (fieldType: number, targetType: number) => {
  switch (getFieldTypeOption(fieldType, targetType)) {
    case FieldType.text:
      return getOption(['等于', '不等于', '包含', '为空', '不为空']);
    case FieldType.numOrDate:
      return getOption([
        '等于',
        '不等于',
        '为空',
        '不为空',
        '大于',
        '小于',
        '大于等于',
        '小于等于',
      ]);
    case FieldType.singleSelect:
      return getOption(['等于', '不等于', '为空', '不为空', '属于']);
    case FieldType.multiSelect:
      return getOption(['包含', '不包含', '为空', '不为空']);
    default:
      return conditionType;
  }
};

export enum ValuesRenderType {
  /** 单行文本 or 多行文本 */
  input = 1,
  /** 数值 or 整数 or日期时间 */
  inputNumber,
  /** 包含 */
  date,
  other,
}

export const getValuesRender = (fieldType: number) => {
  switch (fieldType) {
    case CustomField.FieldType.text:
    case CustomField.FieldType.textArea:
      return ValuesRenderType.input;
    case CustomField.FieldType.number:
    case CustomField.FieldType.integer:
      return ValuesRenderType.inputNumber;
    case CustomField.FieldType.date:
      return ValuesRenderType.date;
    default:
      return ValuesRenderType.other;
  }
};

// 根据form获取到select组件中，暴露出来的labelInValue的值
// value 的 值为一个stringify的值
// 取出 stingify 值 当中的某个值
export const getFieldValueOfForm = (
  form: FormInstance<any>,
  fieldName: string | NamePath,
  targetFieldPath: string,
) => {
  const fieldVal = form.getFieldValue(_.isString(fieldName) ? fieldName.split('.') : fieldName);

  if (_.isNil(fieldVal)) return undefined;

  const fieldValueStr = typeof fieldVal === 'string' ? fieldVal : _.get(fieldVal, 'value');

  if (_.isNil(fieldValueStr)) return undefined;
  const fieldValue = JSON.parse(fieldValueStr);

  return _.isArray(fieldValue)
    ? _.map(fieldValue, targetFieldPath)
    : _.get(fieldValue, targetFieldPath);
};

// 取出{ label, value } 中的value 中的 某个值
// value 是一个stringify的对象
export const getValueFromStringfy = (fieldValue: string, targetFieldPath: string) => {
  const value = _.get(fieldValue, 'value');

  if (_.isNil(value)) return undefined;
  return _.get(JSON.parse(value), targetFieldPath);
};
