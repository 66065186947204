/**
 * 从对象接入基础方法
 */

import { DetailLayoutInfoItem } from '@blacklake-web/layout';
import { Form } from 'antd';
import _ from 'lodash';
import { replaceSign } from 'src/utils/constants';
import BatchOperateTableHeader from '../batchOperateTableHeader';
import {
  DEFAULT_DATAINDEX,
  generateGetCustomFieldNamePathByFieldCode,
  getCustomFieldNameOrkey,
  getObjectCustomFields,
  getObjectFieldCascadings,
  getShoulUpdate,
  handleFieldChange,
} from './base';
import { getDetailCustomFieldItem, IDetailCustomFieldItemConfig } from './detailModel';
import { getFormCustomFieldItem } from './formModel';
import {
  CustomFieldComponentPropsMap,
  ICustomFieldsFnConfig,
  ICustomFieldsFnParams,
} from './interface';

export interface ISubObjCustomFieldsToEditConfig extends ICustomFieldsFnConfig {
  /** 可以配置是否隐藏渲染组件 */
  hiddenRender?: (text: any, record: { fieldKey: any; name: any }, index: number) => boolean;
}

export interface ISubObjCustomFieldsToDetailConfig extends IDetailCustomFieldItemConfig {
  dataIndex?: string | string[];
}

/**
 * 获取从对象 自定义字段 在『表单』的info,返回列表直接用在 Table 的columns配置中,
 * @param customFields
 * @returns
 */
export const getSubObjCustomFieldsToEdit = (
  params: ICustomFieldsFnParams,
  config: ISubObjCustomFieldsToEditConfig,
  componentsProps?: CustomFieldComponentPropsMap,
): any[] => {
  const { customFields, objectCode } = getObjectCustomFields(params);
  const { disabled = false, hiddenRender, ...formConfig } = config ?? {};
  const fieldCascadings = getObjectFieldCascadings(params);

  if (_.isEmpty(customFields) || _.isNil(objectCode)) return [];

  const infos = _.map(customFields, (dataSource, fieldIndex) => {
    const relatedCascading = fieldCascadings.find(
      (cas) => cas.refFieldCode === dataSource.fieldCode,
    );

    return {
      title: (
        <BatchOperateTableHeader
          required={!!dataSource.isRequired}
          titleText={dataSource.fieldName}
          tooltip={dataSource.fieldRemind}
        />
      ),
      dataIndex: `customField_${dataSource.fieldId}`,
      width: 170,
      render: (text: any, field: { fieldKey: any; name: number }, index: number) => {
        // 是否需要隐藏自定义字段
        let isHidden = false;

        if (_.isFunction(hiddenRender)) {
          isHidden = hiddenRender(text, field, index);
        }

        if (isHidden) return replaceSign;

        const item = getFormCustomFieldItem(dataSource, componentsProps, {
          form: config?.form,
          fieldFormName: [formConfig.formName ?? '', _.toString(field.name)],
          relatedCascading,
          getNamePathByFieldCode: generateGetCustomFieldNamePathByFieldCode(
            objectCode,
            customFields,
            { formName: formConfig.formName!, fieldName: field.name },
          ),
        });
        const shouldUpdate = relatedCascading
          ? getShoulUpdate([
              formConfig.formName!,
              field.name,
              ...getCustomFieldNameOrkey({
                objectCode,
                fieldId: _.find(customFields, { fieldCode: relatedCascading.fieldCode! })?.fieldId!,
              }),
            ])
          : undefined;

        const formItemProps = {
          style: { marginBottom: 0 },
          name: [
            field.name,
            ...getCustomFieldNameOrkey({
              objectCode,
              fieldId: dataSource.fieldId,
            }),
          ],
          ..._.pick(item, ['rules', 'initialValue']),
        };

        const itemRenderer = () => {
          return item.render(
            (value: any) => {
              handleFieldChange(
                dataSource,
                { customFields, objectCode },
                formConfig,
                undefined,
                value,
              );
            },
            { disabled },
          );
        };

        // 级联或其他需要更新的情况下
        if (shouldUpdate) {
          return (
            <Form.Item noStyle shouldUpdate={shouldUpdate}>
              {() => <Form.Item {...formItemProps}>{itemRenderer()}</Form.Item>}
            </Form.Item>
          );
        }
        return <Form.Item {...formItemProps}>{itemRenderer()}</Form.Item>;
      },
    };
  });

  return _.compact(infos);
};

/**
 * 获取从对象 自定义字段 在『详情』的info,返回列表直接用在 table 的columns配置中
 * @param customFieldObj
 * @param dataIndex 支持自定义的key
 * @returns
 */
export const getSubObjCustomFieldsToDetail = (
  params: ICustomFieldsFnParams,
  config: ISubObjCustomFieldsToDetailConfig,
  componentsProps?: CustomFieldComponentPropsMap,
): DetailLayoutInfoItem[] => {
  const { customFields, objectCode } = getObjectCustomFields(params);

  if (_.isEmpty(customFields) || _.isNil(objectCode)) return [];

  const infos = _.map(customFields, (dataSource, index) => {
    const { dataIndex = DEFAULT_DATAINDEX, ...itemConfig } = config;

    const item = getDetailCustomFieldItem(dataSource, itemConfig, componentsProps);
    if (!item) return null;

    return {
      ...item,
      title: dataSource.fieldName,
      width: 150,
      dataIndex: `customFields_${dataSource.fieldId}`,
      render: (__: any, record: any) => {
        const index_ = _.findIndex(_.get(record, dataIndex), ['fieldId', dataSource.fieldId]);

        const path =
          typeof dataIndex === 'string'
            ? [dataIndex, `${index_}`, 'fieldValue']
            : [...dataIndex, `${index_}`, 'fieldValue'];
        const value = _.get(record, path);

        return item.format(value, record);
      },
    };
  });
  return _.compact(infos);
};
