import { ArrowRightOutlined } from '@ant-design/icons';
import React from 'react';
import ProgressItem from './PurchaseProgress';

interface ListProps {
  /** 生产计划已下达进度 */
  productionPlanIssued: number;
  /** 成品已入库进度 */
  productStorage: number;
  /** 已发货进度 */
  deliveryProgress: number;
  /** 已收货进度 */
  receivedProgress: number;
}

const ProgressList: React.FC<ListProps> = (props) => {
  const { productionPlanIssued, productStorage, deliveryProgress, receivedProgress } = props;

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <ProgressItem value={productionPlanIssued} label={'生产计划已下达'} />
      <ArrowRightOutlined style={{ marginBottom: 20 }} />
      <ProgressItem value={productStorage} label={'成品已入库'} />
      <ArrowRightOutlined style={{ marginBottom: 20 }} />
      <ProgressItem value={deliveryProgress} label={'已发货'} />
      <ArrowRightOutlined style={{ marginBottom: 20 }} />
      <ProgressItem value={receivedProgress} label={'已收货'} />
    </div>
  );
};

export default ProgressList;
