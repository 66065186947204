/**
 * @mappings 销售订单 -值映射表
 */

import { OrderStatus, PlannedIssueStatus } from '.';
import { mappingsFactory } from '../utils';

/**
 * 协同交货计划单状态
 */
export const orderStatus = mappingsFactory(
  // ['新建', OrderStatus.create],
  ['待确认', OrderStatus.pending],
  ['执行中', OrderStatus.inprogress],
  ['已退回', OrderStatus.returned],
  ['已结束', OrderStatus.finished],
  ['已关闭', OrderStatus.closed],
);

/**
 * 计划发货状态
 */
export const plannedIssueStatus = mappingsFactory(
  ['待计划', PlannedIssueStatus.toPlan],
  ['部分计划', PlannedIssueStatus.partPlan],
  ['已计划', PlannedIssueStatus.planned],
);
