/**
 * @description: 必须是字母、中文、数字、支持所有特殊字符(特殊符号包含中间的空格)
 * @param {*} value
 * @return {*}
 */
export const textRules = (value: any): boolean => {
  const reg =
    /^[a-zA-Z0-9\u4e00-\u9fa5`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！≮≯≠～¥≡@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、Ω≈ç√˜µ≤≥÷…˚æ“‘«”「」’\s]+$/;

  return reg.test(value);
};

/**
 * @description: 必须是字母、数字、支持所有特殊字符(特殊符号包含中间的空格)
 * @param {*} value
 * @return {*}
 */
export const textRules1 = (value: any): boolean => {
  const reg =
    /^[a-zA-Z0-9`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、Ω≈ç√˜µ≤≥÷…˚æ“‘«”「」’\s]+$/;

  return reg.test(value);
};

// 前后不支持空格
export const whiteSpaceValidator = (value: any): boolean => {
  // HHZZ3-2959: 原来的/^[\S].*[\S]$/必须匹配2个以上字符
  const reg = /^[\S].*[\S]$|(^\S?$)/;

  return reg.test(value);
};

// 正整数
export const positiveIntegerValidator = (value: any): boolean => {
  const reg = /^[1-9]\d*$/;

  return reg.test(value);
};
