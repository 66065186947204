import { BlTable } from '@blacklake-web/component';
import { TagList } from 'components';
import { getMaterialAttrs } from 'src/entity/material';
import React from 'react';

interface Props {
  originalAlternativeMaterials: any;
}

const OriginalAlternativeMaterialDetailTable = (props: Props) => {
  const getColumns = () => {
    return [
      {
        title: '序号',
        dataIndex: 'lineNo',
        width: 100,
        fixed: 'left',
      },
      {
        title: '物料编号',
        dataIndex: ['material', 'baseInfo', 'code'],
        width: 200,
        fixed: 'left',
      },
      {
        title: '物料名称',
        dataIndex: ['material', 'baseInfo', 'name'],
        width: 200,
      },
      {
        title: '物料属性',
        dataIndex: ['material'],
        width: 200,
        render: (text: any) => <TagList dataSource={getMaterialAttrs(text)} />,
      },
      {
        title: '物料分类',
        dataIndex: ['material', 'category', 'name'],
        width: 150,
      },
      {
        title: '物料规格',
        dataIndex: ['material', 'baseInfo', 'specification'],
        width: 150,
      },
      {
        title: '数量:分子',
        dataIndex: 'amountNumerator',
        key: 'amountNumerator',
        width: 150,
      },
      {
        title: '数量:分母',
        dataIndex: 'amountDenominator',
        key: 'amountDenominator',
        width: 150,
      },
      {
        title: '使用比例',
        dataIndex: 'useRatio',
        width: 120,
      },
      {
        title: '单位',
        dataIndex: 'unitName',
        width: 150,
      },
    ];
  };

  return (
    <BlTable
      dataSource={props.originalAlternativeMaterials}
      columns={getColumns()}
      rowKey="id"
      scroll={{ x: 'max-content' }}
    />
  );
};

export default OriginalAlternativeMaterialDetailTable;
