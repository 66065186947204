import React from 'react';
import styles from '../import.module.scss';

type Props = {
  title: string;
  style?: { [key: string]: any };
};

const CardContainer: React.FC<Props> = (props) => {
  const { title, children, style } = props;

  return (
    <div className={styles.cardContainer} style={style}>
      <div className={styles.cardHeader}>
        <span className={styles.cardHeaderText}>{title}</span>
      </div>
      <div style={{ padding: 24 }}>{children}</div>
    </div>
  );
};

export default CardContainer;
