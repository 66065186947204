import React, { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import _ from 'lodash';
import { DetailLayoutInfoBlock, DetailLayout, DetailLayoutInfoItem } from 'layout';
import { message } from 'antd';
import {
  fetchMaintenanceTaskDetail,
  fetchMaintenanceTaskCancel,
} from 'src/api/ytt/resource-domain/maintenanceTask';
import { AvatarDisplay } from 'src/components/avatar/show';
import TagTooltip from 'src/components/tooltip/tagTooltip';
import { BcAttachmentDetail, BlIcon, SingleOperationLogComponent } from 'components';
import { gcTime } from 'src/utils';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { valueOrHolder } from 'src/utils/formatters';
import lookup, { appEnum } from 'src/dict';
import { replaceSign } from 'src/utils/constants';
import renderQrCode from 'src/page/share/renderQrCode';
//
import { MaintenanceDetailType } from '../index.d';
import { goToEdit } from '../navigates';
import { BL_BUSINESS_NAME } from '../config';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { ValueType } from 'src/dict/types';
import AutoTimer from '../components/autoTimer';
import TimeOutRecord from '../list/timeOutRecord';

interface DetailContentProps extends RouteChildrenProps<{ id: string }> {}

const Detail = (props: DetailContentProps) => {
  const { match, history } = props;
  const [detailData, setDetailData] = useState<MaintenanceDetailType>();
  const [loading, setLoading] = useState(false);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [timeOutLog, setTimeOutLog] = useState<boolean>(false);

  const { status } = detailData ?? {};

  const isCanceled = status === appEnum.Resources.MaintenanceTaskStatus.canceled;
  const isEnded = status === appEnum.Resources.MaintenanceTaskStatus.ended;
  const isNotStarted = status === appEnum.Resources.MaintenanceTaskStatus.notStarted;
  const isExecuting = status === appEnum.Resources.MaintenanceTaskStatus.executing;
  const isSuspended = status === appEnum.Resources.MaintenanceTaskStatus.suspended;
  const isDeferred = status === appEnum.Resources.MaintenanceTaskStatus.deferred;

  const fetchAndSetData = (id?: string) => {
    if (id) {
      setLoading(true);
      return fetchMaintenanceTaskDetail({ id: _.toNumber(id) }).then((res) => {
        const { data } = res;

        data && setDetailData(data);

        return res;
      });
    }

    return Promise.reject();
  };

  useEffect(() => {
    fetchAndSetData(match?.params?.id)
      .catch(() => {
        setTimeout(() => {
          history.goBack();
        }, 500);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const baseMenu = () => {
    const { id, status } = detailData ?? {};

    if (_.isNil(status) || _.isNil(id)) return [];

    const buttonList = [
      {
        title: '取消',
        key: 'cancel',
        auth: authDict.maintenanceTask_cancel,
        disabled: isCanceled || isEnded,
        onClick: () => {
          fetchMaintenanceTaskCancel({ maintenanceTaskIds: [id] }).then((res) => {
            if (res.code === 200) {
              message.success('取消成功!');
              fetchAndSetData(match?.params?.id).finally(() => {
                setLoading(false);
              });
            }
          });
        },
      },
      {
        title: '暂停记录',
        key: 'pauseRecord',
        auth: authDict.maintenanceTask_pause_record,
        onClick: () => {
          setTimeOutLog(true);
        },
      },
      {
        title: '操作记录',
        key: 'operRecord',
        auth: authDict.maintenanceTask_operrecord,
        onClick: () => {
          setVisibleLog(true);
        },
      },
      {
        title: '编辑',
        key: 'edit',
        auth: authDict.maintenanceTask_edit,
        type: 'primary',
        icon: <BlIcon type="iconbianji" />,
        disabled: !isNotStarted && !isDeferred,
        onClick: () => {
          goToEdit(id);
        },
      },
    ];

    return _.compact(buttonList);
  };

  const baseInfo = (): DetailLayoutInfoBlock => {
    const baseItems: DetailLayoutInfoItem[] = _.compact([
      { label: `${BL_BUSINESS_NAME}编号`, dataIndex: 'code' },
      { label: `${BL_BUSINESS_NAME}名称`, dataIndex: 'name' },
      {
        label: '标识码',
        dataIndex: 'identificationCode',
        render: (identificationCode: any) =>
          valueOrHolder(identificationCode, (code: any) => renderQrCode(code) ?? ''),
      },
      {
        label: '维保业务对象',
        dataIndex: 'bizType',
        render: (type: boolean | ValueType | undefined) =>
          valueOrHolder(lookup('resources.RESORCE_BUSINESS_TYPE_MAP', type)),
      },
      {
        label: '维保方案',
        dataIndex: ['maintenanceCase', 'name'],
      },
      {
        label: '维保目标',
        dataIndex: ['bizVO', 'name'],
      },
      {
        label: '维保目标编号',
        dataIndex: ['bizVO', 'code'],
      },
      {
        label: '维保目标区域',
        dataIndex: ['bizVO', 'location', 'name'],
      },
      {
        label: '业务类型',
        dataIndex: 'businessType',
        render: (type: boolean | ValueType | undefined) =>
          valueOrHolder(lookup('resources.maintenanceType', type)),
      },
      {
        label: '维保途径',
        dataIndex: 'channelType',
        render: (type: boolean | ValueType | undefined) =>
          valueOrHolder(lookup('resources.maintenanceChannel', type)),
      },
      {
        label: '任务状态',
        dataIndex: 'status',
        render: (status: boolean | ValueType | undefined) =>
          valueOrHolder(lookup('resources.maintenanceTaskStatus', status)),
      },
      {
        label: '维保任务分类',
        dataIndex: 'classificationList',
        render: (list: any) => valueOrHolder(_.map(list, 'name').join('/')),
      },
      // todo 暂时不上
      // {
      //   label: '资源占用',
      //   dataIndex: 'field',
      //   render: (type) => valueOrHolder(lookup('common.yn', type)),
      // },
      {
        label: '扫码确认',
        dataIndex: 'scanCodeFlag',
        render: (type: boolean | ValueType | undefined) =>
          valueOrHolder(lookup('common.ynb', type)),
      },
      {
        label: '标准工时',
        dataIndex: 'standardWorkTime',
        render: (time: number | undefined) => <AutoTimer startSec={time} />,
      },
      {
        label: '计划开始时间',
        dataIndex: 'planStartTime',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: '计划结束时间',
        dataIndex: 'planEndTime',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: '计划执行人',
        dataIndex: 'planExecutorList',
        render: (users: any[]) => <TagTooltip list={users || []} isUser max={2} />,
      },
      {
        label: '实际开始时间',
        dataIndex: 'realStartTime',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: '实际结束时间',
        dataIndex: 'realEndTime',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: '当前执行人',
        dataIndex: 'currentExecutorList',
        render: (users: any[]) => <TagTooltip list={users || []} isUser max={2} />,
      },
      {
        label: '已执行时长',
        dataIndex: 'executedDuration',
        render: (time: number | undefined) => {
          return isExecuting || isSuspended ? (
            <AutoTimer
              startSec={time}
              auto={detailData?.status === appEnum.Resources.MaintenanceTaskStatus.executing}
            />
          ) : (
            replaceSign
          );
        },
      },
      {
        label: '总执行时长',
        dataIndex: 'sumDuration',
        render: (time: number | undefined) => {
          return isEnded ? (
            <AutoTimer
              startSec={time}
              auto={detailData?.status === appEnum.Resources.MaintenanceTaskStatus.executing}
            />
          ) : (
            replaceSign
          );
        },
      },

      {
        label: '执行方式',
        dataIndex: 'executeType',
        render: (type: boolean | ValueType | undefined) =>
          valueOrHolder(lookup('resources.maintenanceExecuteType', type)),
      },
      {
        label: 'SOP方案',
        dataIndex: 'sopList',
        render: (list: any) => {
          if (
            _.isNil(list) ||
            detailData?.executeType === appEnum.Resources.MaintenanceExecuteType.reportTemplate
          ) {
            return replaceSign;
          }
          return _.map(list, 'name');
        },
      },
      {
        label: 'SOP任务',
        dataIndex: 'sopTaskName',
        render: (sopTaskName: any) => {
          if (
            _.isNil(detailData?.sopTaskId) ||
            detailData?.executeType === appEnum.Resources.MaintenanceExecuteType.reportTemplate
          ) {
            return replaceSign;
          }

          return (
            <a
              onClick={() => {
                window.open(`/sop/exec/task/${detailData?.sopTaskId}/detail`);
              }}
            >
              {sopTaskName ?? 'SOP任务-维保任务'}
            </a>
          );
        },
      },
      {
        label: '维保报告模板',
        dataIndex: 'reportTemplateList',
        render: (list: any) => {
          if (
            _.isNil(list) ||
            detailData?.executeType === appEnum.Resources.MaintenanceExecuteType.SOP
          ) {
            return replaceSign;
          }
          return _.map(list, 'name').join('，');
        },
      },
      {
        label: '任务来源',
        dataIndex: 'createResourceType',
        render: (type: boolean | ValueType | undefined) =>
          valueOrHolder(lookup('resources.maintenanceTaskSource', type)),
      },
      {
        label: '触发事件',
        dataIndex: ['triggerEvent', 'name'],
      },
      {
        label: '触发规则',
        dataIndex: ['triggerRule', 'name'],
      },
      {
        label: '附件',
        dataIndex: 'fileIds',
        render: (fileIds: number[]) => <BcAttachmentDetail fileIds={fileIds} />,
        span: 2,
      },
      {
        label: '备注',
        dataIndex: 'remark',
        isFullLine: true,
      },
    ]);

    return {
      title: '基本信息',
      items: baseItems,
      column: 2,
    };
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: any) =>
          valueOrHolder(creator, (creator: any) => (
            <AvatarDisplay {...creator} isShowTag={false} isUser />
          )),
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: any) =>
          valueOrHolder(operator, (operator: any) => (
            <AvatarDisplay {...operator} isShowTag={false} isUser />
          )),
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
    ],
    column: 2,
  };

  return (
    <>
      <DetailLayout
        loading={loading}
        baseMenu={baseMenu()}
        title={`${BL_BUSINESS_NAME}详情`}
        info={[baseInfo(), otherInfo]}
        dataSource={detailData}
        userAuth={getAuthFromLocalStorage()}
      >
        {visibleLog && (
          <SingleOperationLogComponent
            visible={visibleLog}
            instanceId={detailData?.id ?? 0}
            objectCode={OBJECT_OF_CODE.MaintenanceTask}
            closeDetail={() => {
              setVisibleLog(false);
            }}
          />
        )}
      </DetailLayout>
      {timeOutLog && (
        <TimeOutRecord recordId={detailData?.id} onClose={() => setTimeOutLog(false)} />
      )}
    </>
  );
};

export default Detail;
