import { PropsWithChildren, useEffect, useState } from 'react';
import { Button } from 'antd';
import _, { isEmpty } from 'lodash';
import { DataFormLayoutForModal } from 'layout';
import { UploadFilesProps } from './index.d';
import AttachmentBindModel from './components/attachmentBindModel';
import { fetchFileListByIds, fetchFileBindBiz } from 'src/api/ytt/holyfile-domain';
import type { FetchFileListByIdsResponse } from 'src/api/ytt/holyfile-domain';
import UploadFileList from './components/attachmentList';
import { BlIcon } from '@blacklake-web/component';
/**
 * @description 添加文件FormItem
 * @param props
 * buttonText 按钮文字，默认添加文件
 * label 文案
 * name 表单文件字段名
 * @returns
 */

type fileResponse = Exclude<FetchFileListByIdsResponse['data'], undefined>[number];

const getValueId = (value: any[]) => {
  if (typeof value[0] === 'object' && 'id' in value[0]) {
    return value?.map((v) => v?.id);
  }
  return value;
};

const UploadFiles = (props: PropsWithChildren<UploadFilesProps>) => {
  const {
    value,
    onChange,
    biz,
    buttonText,
    label,
    name,
    disabled,
    uploadConfig = {},
    ...rest
  } = props;
  const [visible, setVisible] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const fileFormatter = (file: fileResponse) => {
    return {
      uid: file.id,
      name: decodeURIComponent(file?.filename ?? ''),
      url: file.fileUri,
      status: 'done',
      ...file,
    };
  };
  const getFilesByIds = async (idList: number[] = []) => {
    const { data } = await fetchFileListByIds({ idList });

    setFileList(data?.map((item: fileResponse) => fileFormatter(item)) || []);
  };

  const bindFilesBiz = async () => {
    console.log(biz);
    if (!biz) {
      return;
    }
    const res = await fetchFileBindBiz({
      fileIds: fileList.map((item: any) => item.id),
      bizCode: biz?.bizCode,
      bizId: biz?.bizId,
      bizTypeName: biz?.bizTypeName,
    });

    return res;
  };

  useEffect(() => {
    if (value && !isEmpty(value)) {
      getFilesByIds(getValueId(value));
    } else {
      // HHZZ3-34373 表单值清空以后，清空fileList
      setFileList([]);
    }
  }, [value]);
  return (
    <div>
      <Button
        disabled={disabled}
        onClick={() => setVisible(true)}
        icon={<BlIcon type="iconshangchuan1" />}
      >
        {buttonText || '添加文件'}
      </Button>
      <UploadFileList
        files={fileList}
        preview
        showRemoveIcon={!disabled}
        onChange={(files: any) => {
          const ids = _.map(files, 'id');

          onChange?.(ids);
        }}
        {...rest}
      />
      <DataFormLayoutForModal
        visible={visible}
        onClose={() => setVisible(false)}
        width={700}
        content={
          <AttachmentBindModel
            getFormData={(data: any) => {
              // 添加文件后，输出保存的文件和部门
              const union = _.uniq(_.map(fileList, 'id').concat(data.fileIds));

              onChange?.(union);
              bindFilesBiz();
            }}
            onCancel={() => setVisible(false)}
            uploadConfig={uploadConfig}
          />
        }
      />
    </div>
  );
};

export default UploadFiles;
