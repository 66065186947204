import { Form, Input, FormInstance, DatePicker, Select, Button, TimePicker, message } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table';
import { BlSortFormList, SearchSelect } from 'src/components';
import { DeliveryScheduleNoteExecStatus, DeliveryScheduleNoteSource } from 'src/dict/purchase';
import { logisticsTimeUnit } from 'src/dict/purchase/mappings';
import _Time from 'src/utils/dataTypes/time';
import _, { compact, max, map } from 'lodash';
import { replaceSign } from 'src/utils/constants';
import { useEffect, useState } from 'react';
import { numberMinMaxCheck, intMultipleCheck, fractionLengthCheck } from 'src/utils/formValidators';
import { materialEntity } from 'src/entity';
import { PlusOutlined } from '@ant-design/icons';
import BatchAddMaterials from './batchAddMaterials';
import EditableText from 'src/components/editableText';
import {
  FetchDeliveryScheduleNoteItemDetailResponseData,
  FetchDeliveryScheduleNoteItemDetailResponseDataElement,
  FetchPurchaseOrderListMaterialResponseData,
  FetchPurchaseOrderListMaterialResponseDataElement,
} from '../interface';

interface Props {
  orderStatus: number;
  name: string;
  edit: boolean;
  form: FormInstance;
  source: number;
  initialMaterialLine?: FetchDeliveryScheduleNoteItemDetailResponseData;
}
/** 默认单位精度 */
const DEFAULT_PRECISION = 10;

export default function MaterialTable({
  name,
  form,
  edit,
  orderStatus,
  source,
  initialMaterialLine,
}: Props) {
  const [batchAddVisible, setBatchAddVisible] = useState(false);
  const editDisabled =
    orderStatus !== DeliveryScheduleNoteExecStatus.created &&
    orderStatus !== DeliveryScheduleNoteExecStatus.rejected;
  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return _.compact([
      {
        title: '行号',
        dataIndex: 'lineNo',
        key: 'lineNo',
        width: 150,
        render: (_, field) => {
          const materials = form.getFieldValue('itemList');
          // 编辑行号时，新增行号为当前行号值最大加1
          const list = materials.map((el: any) => Number(el?.lineNo || 0));
          const num = Number(max(list)) + 1;

          return (
            <Form.Item
              name={[field?.name, 'lineNo']}
              fieldKey={[field?.key, 'lineNo']}
              initialValue={String(num)}
              style={{ marginBottom: 0 }}
            >
              {/* 删除物料行时,不会自动补序。在最后提交数据时，再对seqNum重新排序 */}
              {field?.name + 1}
            </Form.Item>
          );
        },
      },
      {
        title: '物料',
        dataIndex: 'materialId',
        key: 'materialId',
        width: 250,
        render: (_, field) => {
          return (
            <Form.Item dependencies={['supplier']} noStyle>
              {() => {
                const supplier = form.getFieldValue('supplier');

                return (
                  <Form.Item
                    name={[field?.name, 'materialId']}
                    fieldKey={[field?.key, 'materialId']}
                    rules={[{ required: true, message: '请选择物料' }]}
                    style={{ marginBottom: 0 }}
                  >
                    <SearchSelect
                      disabled={editDisabled || source === DeliveryScheduleNoteSource.purchaseOrder}
                      placeholder="请选择物料"
                      fetchType={'materialForPurchaseOrder'}
                      labelInValue
                      params={{ supplierId: supplier?.value }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '交货日期',
        dataIndex: 'deliveryDate',
        key: 'deliveryDate',
        width: 250,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'deliveryDate']}
              fieldKey={[field?.key, 'deliveryDate']}
              rules={[{ required: true, message: '请输入交货日期' }]}
              shouldUpdate
              initialValue={_Time.daysAfter(7)}
              style={{ marginBottom: 0 }}
            >
              <DatePicker format={'YYYY-MM-DD'} disabled={editDisabled} />
            </Form.Item>
          );
        },
      },
      {
        title: '最早到货时间',
        dataIndex: 'earliestArrivalTime',
        key: 'earliestArrivalTime',
        width: 250,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'earliestArrivalTime']}
              fieldKey={[field?.key, 'earliestArrivalTime']}
              shouldUpdate
              initialValue={moment('2022-09-09 00:00:00')}
              style={{ marginBottom: 0 }}
            >
              <TimePicker
                placeholder={editDisabled ? '' : '请选择时间'}
                format={'HH:mm:ss'}
                disabled={editDisabled}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '最晚到货时间',
        dataIndex: 'latestArrivalTime',
        key: 'latestArrivalTime',
        width: 250,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'latestArrivalTime']}
              fieldKey={[field?.key, 'latestArrivalTime']}
              shouldUpdate
              initialValue={moment('2022-09-09 23:59:59')}
              style={{ marginBottom: 0 }}
            >
              <TimePicker
                placeholder={editDisabled ? '' : '请选择时间'}
                format={'HH:mm:ss'}
                disabled={editDisabled}
              />
            </Form.Item>
          );
        },
      },
      source === DeliveryScheduleNoteSource.purchaseOrder && {
        title: '采购订单编号',
        width: 250,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'purchaseOrderCode']}
              fieldKey={[field?.key, 'purchaseOrderCode']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      source === DeliveryScheduleNoteSource.purchaseOrder && {
        title: '采购订单行号',
        width: 250,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'purchaseOrderItemSeqNum']}
              fieldKey={[field?.key, 'purchaseOrderItemSeqNum']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '需求数',
        dataIndex: 'demandAmount',
        key: 'demandAmount',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item shouldUpdate noStyle>
              {() => {
                const { materialId } = form.getFieldValue('itemList')[field.name] ?? {};
                const materialObj = materialId && JSON.parse(materialId?.value);
                const unitName = materialObj?.inventoryAmount?.unitName || materialObj?.unit?.name;
                const unitId = materialObj?.inventoryAmount?.unitId || materialObj?.unit?.id;
                const { enablePrecision, precisionFigure } = materialEntity.getMaterialUnitInfo(
                  materialObj,
                  unitId,
                );

                const purchaseAmountMin = materialObj?.purchaseInfo?.purchaseAmountMin;
                const purchaseAmount = materialObj?.purchaseInfo?.purchaseAmount ?? 0;
                const demandAmount = form.getFieldValue([name, field.name, 'demandAmount']);

                return (
                  <Form.Item
                    name={[field?.name, 'demandAmount']}
                    fieldKey={[field?.key, 'demandAmount']}
                    rules={compact([
                      { required: true, message: '请输入需求数' },
                      enablePrecision && {
                        validator: fractionLengthCheck(
                          precisionFigure ?? DEFAULT_PRECISION,
                          `请输入${precisionFigure ?? DEFAULT_PRECISION}位精度的数字`,
                        ),
                      },
                      {
                        validator: numberMinMaxCheck({
                          min: purchaseAmountMin,
                          fieldName: '需求数',
                          message: purchaseAmountMin
                            ? `请输入大于等于最小批量${purchaseAmountMin}的值`
                            : '请输入大于0的数字',
                        }),
                      },
                      {
                        validator: intMultipleCheck({
                          baseNum: purchaseAmount,
                          compareNum: demandAmount,
                          message: `请输入采购批量${purchaseAmount}整数倍的值`,
                        }),
                      },
                      {
                        validator: numberMinMaxCheck({
                          max: 100000000,
                          fieldName: '需求数',
                          message: '请输入小于100,000,000的值',
                        }),
                      },
                    ])}
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      addonAfter={unitName}
                      type="number"
                      placeholder="请输入需求数"
                      disabled={editDisabled}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '可用库存',
        dataIndex: 'inventoryAmount',
        key: 'inventoryAmount',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item shouldUpdate noStyle>
              {() => {
                const { materialId } = form.getFieldValue('itemList')[field.name] ?? {};
                const amount =
                  materialId && JSON.parse(materialId?.value)?.inventoryAmount?.amountDisplay;
                const unit =
                  (materialId && JSON.parse(materialId?.value)?.unit?.name) ||
                  (materialId && JSON.parse(materialId?.value)?.inventoryAmount.unitName);

                return (
                  <Form.Item
                    name={[field?.name, 'inventoryAmount']}
                    fieldKey={[field?.key, 'inventoryAmount']}
                    style={{ marginBottom: 0 }}
                  >
                    <span>{amount ? amount + unit : replaceSign}</span>
                    {/* 可用库存纯展示 由物料编号带出 */}
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '物流时间',
        dataIndex: 'logisticsCycle',
        key: 'logisticsCycle',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'logisticsCycle']}
              fieldKey={[field?.key, 'logisticsCycle']}
              style={{ marginBottom: 0 }}
              rules={compact([
                {
                  validator: numberMinMaxCheck({
                    min: 1,
                    fieldName: '物流时间',
                    message: '请输入大于0的整数',
                  }),
                },
                {
                  validator: numberMinMaxCheck({
                    max: 999,
                    fieldName: '物流时间',
                    message: '时间需小于1000天',
                  }),
                },
              ])}
            >
              <Input
                type="number"
                placeholder={editDisabled ? '' : '请输入物流时间'}
                disabled={editDisabled}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '物流时间单位',
        dataIndex: 'logisticsTimeUnit',
        key: 'logisticsTimeUnit',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              shouldUpdate
              noStyle
              name={[field?.name, 'logisticsTimeUnit']}
              fieldKey={[field?.key, 'logisticsTimeUnit']}
              style={{ marginBottom: 0 }}
            >
              <Select allowClear options={logisticsTimeUnit} disabled={editDisabled} />
            </Form.Item>
          );
        },
      },
    ]);
  };
  const renderAddActon = () => {
    return (
      <div style={{ marginBottom: 20, marginTop: 10, display: 'inline' }}>
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          style={{ color: '#02B980', borderColor: '#02B980' }}
          onClick={() => {
            form
              .validateFields()
              .then(() => {
                setBatchAddVisible(true);
              })
              .catch((e) => {
                let errMsg = '';

                if (
                  e.errorFields.some((fields: any) => {
                    return fields.name.some((n: string, i: number) => {
                      if (
                        n == 'supplier' ||
                        n == 'source' ||
                        (n == 'itemList' && fields.errors[i] != '请至少添加一行物料')
                      ) {
                        errMsg = fields.errors[i];
                        return true;
                      }
                      return false;
                    });
                  })
                ) {
                  message.error(errMsg || e.errorFields[0].errors[0]);
                } else {
                  setBatchAddVisible(true);
                }
              });
          }}
        >
          按采购订单添加
        </Button>
      </div>
    );
  };
  const handleMaterialFinish = (
    rows?: FetchPurchaseOrderListMaterialResponseData,
    selectedKeys?: number[],
  ) => {
    const materials = form.getFieldValue(name) || [];

    if (!rows || rows.length == 0) {
      if (!materials || materials.length == 0) {
        message.error('请至少添加一行物料');
        return;
      }
      rows = [];
    }
    const addMaterial = rows?.map(
      (node: FetchPurchaseOrderListMaterialResponseDataElement, index: number) => {
        // 此处id为采购订单物料行id,
        const {
          code,
          seqNum,
          id: purchaseOrderItemId,
          purchaseOrderId,
          demandAmount,
          supplier,
          material,
          planDeliveryAmount,
          waitPlanDeliveryAmount,
        } = node;

        // 需求数的默认值计算：采购订单的待计划交货数 - 当前没有id的物料行的需求数之和 + 删掉的带id的物料行需求数之和
        const materialsBeforeHasSave = materials.filter(
          (m: any) => m.purchaseOrderItemId === purchaseOrderItemId && m.id,
        );
        const materialsBeforeNotSave = materials.filter(
          (m: any) => m.purchaseOrderItemId === purchaseOrderItemId && !m.id,
        );
        const materialsBefore =
          initialMaterialLine?.filter(
            (m: FetchDeliveryScheduleNoteItemDetailResponseDataElement) =>
              m.purchaseOrderItemId === purchaseOrderItemId,
          ) || [];
        const amountBefore = materialsBefore?.reduce(
          (sum: number, e: any) => sum + Number(e?.demandAmount?.amount || 0),
          0,
        );
        const demandAmoutBeforeHasSave = materialsBeforeHasSave?.reduce(
          (sum: number, e: any) => sum + Number(e?.demandAmount || 0),
          0,
        );
        const demandAmoutBeforeNotSave = materialsBeforeNotSave?.reduce(
          (sum: number, e: any) => sum + Number(e?.demandAmount || 0),
          0,
        );
        const amountDeleted = amountBefore - demandAmoutBeforeHasSave;

        return {
          seqNo: materials?.length ? materials?.length + index + 1 : index + 1,
          material,
          returnAmount: 1,
          unitName: demandAmount?.unitName,
          unitId: demandAmount?.unitId,
          purchaseOrderCode: code,
          purchaseOrderId,
          supplier,
          purchaseOrderItemSeqNum: seqNum,
          purchaseOrderItemId,
          demandAmount:
            (waitPlanDeliveryAmount?.amount || 0) - demandAmoutBeforeNotSave + amountDeleted,
          planDeliveryAmount,
          waitPlanDeliveryAmount,
          materialId: {
            key: JSON.stringify(material),
            label: material?.baseInfo?.name,
            value: JSON.stringify(material),
          },
        };
      },
    );
    const itemList = _.cloneDeep(materials).concat(addMaterial);
    const baseSupplier = itemList[0]?.supplier;
    const isFalse = _.some(itemList, (node) => node?.supplier?.id !== baseSupplier?.id);

    if (isFalse) {
      message.error('供应商不一致，请重新选择');
      return;
    }
    form.setFieldsValue({
      itemList,
      supplier: {
        key: baseSupplier?.id,
        value: baseSupplier?.id,
        lebel: baseSupplier?.name,
      },
    });
    setBatchAddVisible(false);
  };

  return (
    <>
      <div style={{ marginBottom: 24, fontSize: 14, color: 'rgba(0, 0, 0, 0.85)' }}>
        物料：{' '}
        {!editDisabled && source === DeliveryScheduleNoteSource.purchaseOrder && renderAddActon()}
      </div>
      <BlSortFormList
        buttonText="添加物料行"
        form={form}
        name={name}
        isNeedDrag={false}
        isNeedAdd={!editDisabled && source === DeliveryScheduleNoteSource.noSource}
        isNeedDelete={!editDisabled}
        renderColumns={() => getColumns()}
        listRules={[
          {
            validator: async (__, items) => {
              if (!items || _.isEmpty(items.filter((i: any) => !!i))) {
                return Promise.reject(Error('请至少添加一行物料'));
              }
              return Promise.resolve(true);
            },
          },
        ]}
      />
      {batchAddVisible && (
        <BatchAddMaterials
          addForm={form}
          visible={batchAddVisible}
          handleFinish={handleMaterialFinish}
          onCancel={() => {
            setBatchAddVisible(false);
          }}
        />
      )}
    </>
  );
}
