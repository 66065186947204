/**
 * @page 自定义菜单新建/编辑/复制
 */
import { useEffect, useState } from 'react';
import { matchPath } from 'react-router';
import { useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { compact, toNumber, toString, pick, isEmpty } from 'lodash';
import classnames from 'classnames';
import {
  Form,
  Input,
  Button,
  Transfer,
  TransferProps,
  message,
  Checkbox,
  List,
  Empty,
  Modal,
} from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';
import { checkTwoSidesTrim, textValidator3 } from 'src/utils/formValidators';
import { useBlocker } from 'src/utils';
import {
  fetchStandardBizObjectCustomObjectSimpleListWithoutPage,
  FetchStandardBizObjectCustomObjectSimpleListWithoutPageResponse,
} from 'ytt/metadata-domain/objectPlatform';
import {
  fetchStandardBizObjectMenuGet,
  fetchStandardBizObjectMenuCreate,
  fetchStandardBizObjectMenuUpdate,
} from 'ytt/metadata-domain/customMenu';
import DraggableList from 'src/components/draggableList';
import OverflowTooltip from 'src/components/overflowTooltip';
import { getCopiedCode, getCopiedName } from 'src/utils/formatters';
import { path } from '../constants';
import styles from '../styles.module.scss';

type PathParams = {
  /** 菜单id */
  id?: string;
};

type LoadingComp = null | 'page' | 'save' | 'saveBack';
type SimpleObject =
  Required<FetchStandardBizObjectCustomObjectSimpleListWithoutPageResponse>['data'][number];

const initialValues = {
  menuCode: '',
  menuName: '',
  menuDesc: '',
  objectIdList: [],
};

const transferProps: Partial<TransferProps<SimpleObject>> = {
  titles: ['待分配对象', '已分配对象'],
  operations: ['加入右侧', '加入左侧'],
  showSearch: true,
  filterOption: (inputValue, option) => option.objectName!.includes(inputValue),
  rowKey: (i) => toString(i.id),
  listStyle: {
    flex: '0 1 625px',
    minWidth: 400,
  },
  operationStyle: {
    margin: '0 16px',
    gap: 8,
  },
};

const EditCustomMenu = () => {
  const [loadingComp, setLoadingComp] = useState<LoadingComp>(null);
  const [objectList, setObjectList] = useState<SimpleObject[]>([]);
  const { id } = useParams<PathParams>();
  const [form] = Form.useForm();
  const { resetForm, history } = useBlocker(form);

  const isEdit = !!matchPath(location.pathname, path.edit);
  const isCopy = !!matchPath(location.pathname, path.copy);

  const info: DataFormLayoutInfoBlock[] = [
    {
      title: '基本信息',
      items: compact([
        isEdit && { name: 'id', hidden: true },
        {
          label: '菜单名称',
          name: 'menuName',
          rules: [
            { required: true, message: '菜单名称必填' },
            { type: 'string', max: 24, message: '不超过24个字符' },
            { validator: checkTwoSidesTrim },
          ],
          render: () => <Input placeholder="请输入" />,
        },
        {
          label: '菜单编号',
          name: 'menuCode',
          rules: [
            { required: true, message: '菜单编号必填' },
            { type: 'string', max: 64, message: '不超过64个字符' },
            { validator: textValidator3 },
            { validator: checkTwoSidesTrim },
          ],
          render: () => <Input placeholder="请输入" disabled={isEdit} />,
        },
        {
          label: '菜单说明',
          name: 'menuDesc',
          rules: [
            { type: 'string', max: 256, message: '不超过256个字符' },
            { validator: checkTwoSidesTrim },
          ],
          render: () => <Input.TextArea placeholder="请输入" />,
        },
      ]),
    },
    {
      title: '菜单内容配置',
      items: [
        {
          label: '',
          name: 'objectIdList',
          valuePropName: 'targetKeys',
          isFullLine: true,
          rules: [
            {
              validator(__, value) {
                if (!value || isEmpty(value)) {
                  Modal.error({ content: '保存失败，请分配对象给菜单！' });
                  return Promise.reject(Error('菜单内容不能为空'));
                }
                return Promise.resolve();
              },
              validateTrigger: 'onSubmit',
            },
          ],
          render: () => (
            <Transfer {...transferProps} dataSource={objectList}>
              {({ direction, filteredItems, selectedKeys, onItemSelect }) => {
                if (isEmpty(filteredItems)) {
                  return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
                }
                if (direction === 'left') {
                  return (
                    <List className={styles['object-list']}>
                      {filteredItems.map((i) => (
                        <List.Item
                          className={classnames(
                            styles['object-list-item'],
                            styles['object-list-left-item'],
                          )}
                        >
                          <Checkbox
                            className={styles['object-check-box']}
                            checked={selectedKeys.includes(toString(i.id))}
                            onChange={(e) => {
                              onItemSelect(toString(i.id), e.target.checked);
                            }}
                          >
                            <OverflowTooltip text={i.objectName!} />
                          </Checkbox>
                        </List.Item>
                      ))}
                    </List>
                  );
                }
                const ids = filteredItems.map((i) => toString(i.id));

                return (
                  <DndProvider backend={HTML5Backend}>
                    <DraggableList
                      name={'objectIdList'}
                      form={form}
                      className={styles['object-list']}
                      itemClassName={classnames(
                        styles['object-list-item'],
                        styles['object-list-right-item'],
                      )}
                      filter={(id) => ids.includes(id)}
                      renderItem={({ data }) => {
                        const obj = filteredItems.find((i) => toString(i.id) === data);

                        if (!obj) {
                          return null;
                        }
                        return (
                          <Checkbox
                            className={classnames(
                              styles['object-check-box'],
                              styles['object-list-right-item-checkbox'],
                            )}
                            checked={selectedKeys.includes(data)}
                            onChange={(e) => onItemSelect(data, e.target.checked)}
                          >
                            <OverflowTooltip text={obj.objectName!} />
                          </Checkbox>
                        );
                      }}
                    />
                  </DndProvider>
                );
              }}
            </Transfer>
          ),
        },
      ],
    },
  ];

  const goBack = () => history.push(path.list);
  const getSaveFn = (btn: LoadingComp) => () => {
    form
      .validateFields()
      .then((values) => {
        const submitValues = {
          ...values,
          objectIdList: (values.objectIdList as string[]).map(toNumber),
        };

        setLoadingComp(btn);
        (isEdit
          ? fetchStandardBizObjectMenuUpdate(submitValues)
          : fetchStandardBizObjectMenuCreate(submitValues)
        )
          .then(() => {
            message.success('保存完成');
            resetForm(values);
            setLoadingComp(null);
            if (!isEdit || btn === 'saveBack') {
              goBack();
            }
          })
          .catch(() => {
            setLoadingComp(null);
          });
      })
      .catch(({ errorFields }) => {
        console.error(errorFields);
        form.scrollToField(errorFields[0].name, { behavior: 'smooth' });
      });
  };

  const footer = (
    <div style={{ display: 'flex', justifyContent: 'center', gap: 16 }}>
      <Button type="default" onClick={goBack}>
        取消
      </Button>
      {isEdit && (
        <Button
          type="default"
          onClick={getSaveFn('saveBack')}
          loading={loadingComp === 'saveBack'}
          disabled={loadingComp === 'save'}
        >
          保存并返回
        </Button>
      )}
      <Button
        type="primary"
        onClick={getSaveFn('save')}
        loading={loadingComp === 'save'}
        disabled={loadingComp === 'saveBack'}
      >
        保存
      </Button>
    </div>
  );

  useEffect(() => {
    setLoadingComp('page');
    Promise.all([
      fetchStandardBizObjectCustomObjectSimpleListWithoutPage(),
      isEdit || isCopy ? fetchStandardBizObjectMenuGet({ id: toNumber(id) }) : null,
    ])
      .then((resList) => {
        setObjectList(resList[0].data ?? []);
        if (isEdit || isCopy) {
          let data = resList[1]?.data!;
          const fields = ['menuCode', 'menuName', 'menuDesc'];

          if (isEdit) {
            fields.push('id');
          }
          if (isCopy) {
            data = {
              ...data,
              menuCode: getCopiedCode(data.menuCode),
              menuName: getCopiedName(data.menuName),
            };
          }
          resetForm({
            ...pick(data, fields),
            objectIdList: data.allocatedObjectIdList!.map(toString),
          });
        } else {
          resetForm(initialValues);
        }
      })
      .finally(() => setLoadingComp(null));
  }, []);

  return (
    <DataFormLayout loading={loadingComp === 'page'} form={form} info={info} footer={footer} />
  );
};

export default EditCustomMenu;
