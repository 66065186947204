/**
 * 在列表容器组件上使用该hook，以便在列表尺寸因 自适应 而发生变化时，重新渲染列表项的Tooltip
 * 不适用于主动修改容器组件宽度。这种情况请使用 useWidthObserver
 */
import React, { useEffect, useReducer } from 'react';
import { throttle } from 'lodash';

interface Props {
  /** 指向被观察DOM元素的ref，与selector二选一 */
  ref?: React.MutableRefObject<HTMLDivElement | null>;
  /** 可以拿到被观察DOM元素的selector，与ref二选一 */
  selector?: string;
  wait?: number;
}

export const useResizeObserver = ({ ref, selector, wait = 1000 }: Props) => {
  const [resizeFlag, notifyResize] = useReducer(() => Date.now(), 0);

  useEffect(() => {
    let target;

    if (ref) {
      target = ref.current;
    } else if (selector) {
      target = document.querySelector(selector);
    }
    if (!target) {
      throw Error('未找到观察目标DOM元素，请检查……');
    }
    const throttledHandler = throttle(() => notifyResize(), wait, { trailing: true });
    const observer = new ResizeObserver(throttledHandler);

    observer.observe(target);
    return () => observer.disconnect();
  }, [ref, selector]);

  return [resizeFlag];
};
