import { BlIcon } from '@blacklake-web/component';
import { Form, Checkbox, Radio, FormInstance, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { appDict, appEnum } from 'src/dict';
import { CheckTypeEnum, TriggerValueEnum } from 'src/dict/quality';
import { inputFactoryAutoTrigger, outputFactoryAutoTrigger } from 'src/dict/quality/mappings';

interface AutoTriggerProps {
  checkType: CheckTypeEnum;
  form: FormInstance;
}

export const AutoTrigger = (props: AutoTriggerProps) => {
  const { form, checkType } = props;

  const getTriggerValue = (checkType: CheckTypeEnum): TriggerValueEnum[] => {
    if (checkType === CheckTypeEnum.inputFactoryQc) {
      return [TriggerValueEnum.inbound];
    }
    if (checkType === CheckTypeEnum.outputFactoryQc) {
      return [TriggerValueEnum.outbound];
    }
    if (checkType === CheckTypeEnum.produceQc) {
      return [TriggerValueEnum.reportProduction];
    }
    return [TriggerValueEnum.inbound];
  };

  return (
    <div style={{ background: '#f5f5f5', padding: '14px 14px 2px 14px', marginLeft: 140 }}>
      <Form.Item
        name="triggerEnable"
        label="自动触发"
        rules={[{ required: true }]}
        initialValue={appEnum.Common.YN.no}
      >
        <Radio.Group
          options={appDict.common.yn}
          onChange={(e) => {
            form.setFieldsValue({
              qcConfigTriggerRule: { triggerValue: getTriggerValue(checkType), triggerType: [] },
            });
          }}
        />
      </Form.Item>
      {checkType === CheckTypeEnum.inputFactoryQc &&
        (form.getFieldValue('triggerEnable') ? (
          <>
            <Form.Item
              name={['qcConfigTriggerRule', 'triggerValue']}
              rules={[{ required: true }]}
              label="触发时机"
              initialValue={[TriggerValueEnum.inbound]}
            >
              <Checkbox.Group disabled>
                <Checkbox value={TriggerValueEnum.inbound}>
                  {'入库单下发即触发'}
                  <Tooltip title={'入库单下发即触发：仅适用于按单入库'}>
                    <BlIcon type="iconwentizhiyinbangzhu" style={{ color: '#bfbfbf' }} />
                  </Tooltip>
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              name={['qcConfigTriggerRule', 'triggerType']}
              rules={[{ required: true }]}
              label="触发业务类型"
            >
              <Checkbox.Group options={inputFactoryAutoTrigger} />
            </Form.Item>
          </>
        ) : null)}
      {checkType === CheckTypeEnum.outputFactoryQc &&
        (form.getFieldValue('triggerEnable') ? (
          <>
            <Form.Item
              name={['qcConfigTriggerRule', 'triggerValue']}
              rules={[{ required: true }]}
              label="触发时机"
              initialValue={[TriggerValueEnum.outbound]}
            >
              <Checkbox.Group disabled>
                <Checkbox value={TriggerValueEnum.outbound}>
                  {'出库单下发即触发'}
                  <Tooltip title={'出库单下发即触发：仅适用于按单出库'}>
                    <BlIcon type="iconwentizhiyinbangzhu" style={{ color: '#bfbfbf' }} />
                  </Tooltip>
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              name={['qcConfigTriggerRule', 'triggerType']}
              rules={[{ required: true }]}
              label="触发业务类型"
            >
              <Checkbox.Group options={outputFactoryAutoTrigger} />
            </Form.Item>
          </>
        ) : null)}
      {checkType === CheckTypeEnum.produceQc &&
        (form.getFieldValue('triggerEnable') ? (
          <>
            <Form.Item
              name={['qcConfigTriggerRule', 'triggerValue']}
              rules={[{ required: true }]}
              label="触发时机"
              initialValue={[TriggerValueEnum.reportProduction]}
            >
              <Checkbox.Group disabled>
                <Checkbox value={TriggerValueEnum.reportProduction}>{'报工即触发'}</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </>
        ) : null)}
    </div>
  );
};
