/**
 * 可拖拽的步骤/步骤组行
 */

import { FC, useRef, MutableRefObject, CSSProperties } from 'react';
import { useDrag, useDrop, DropTargetMonitor, ConnectDragSource } from 'react-dnd';
import classnames from 'classnames';
import { message } from 'antd';
import { BlIcon } from 'src/components';

interface DraggableRowProps {
  index: number; // 行号
  parentId: number; // 父级步骤组id, 根为0
  className: string;
  ['data-row-key']: number;
  moveRow: (dragIndex: number, hoverIndex: number, dragKey: number, hoverKey: number) => void;
}
interface TableRowWithHandleProps {
  previewRef: MutableRefObject<HTMLTableRowElement | null>;
  dragRef: ConnectDragSource;
  isDragging: boolean;
  style?: CSSProperties;
  className?: string;
}
interface DragItem {
  id: number;
  index: number;
  parentId: number;
}

const TableRowWithHandle: FC<TableRowWithHandleProps> = (props) => {
  const { previewRef, dragRef, isDragging, children, style = {}, ...rest } = props;

  return (
    <tr ref={previewRef} style={{ ...style, opacity: isDragging ? 0 : 1 }} {...rest}>
      <td className="ant-table-cell">
        <BlIcon ref={dragRef} type="iconrenyituozhuai" />
      </td>
      {children}
    </tr>
  );
};

const DraggableRow: FC<DraggableRowProps> = ({ index, parentId, moveRow, ...restProps }) => {
  const ref = useRef<HTMLTableRowElement | null>(null);
  const [{ isOver, isDownward }, drop] = useDrop({
    accept: 'row',
    collect: (monitor: DropTargetMonitor<DragItem>) => {
      const { index: dragIndex, parentId: hoverParentId } = monitor.getItem() || {};

      if (dragIndex === undefined || dragIndex === index) {
        return {
          isOver: false,
        };
      }
      return {
        isOver: monitor.isOver() && parentId === hoverParentId,
        isDownward: dragIndex < index,
      };
    },
    drop: (item: DragItem) => {
      const dragId = item.id;
      const dropTargetId = restProps['data-row-key'];

      if (dragId === dropTargetId) {
        return;
      }
      if (item.parentId !== parentId) {
        message.warning('仅允许同级之间拖拽');
        return;
      }
      moveRow(item.index, index, dragId, dropTargetId);
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'row',
    item: { id: restProps['data-row-key'], index, parentId },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  let dropClassName = '';

  preview(drop(ref));
  if (isOver) {
    dropClassName = isDownward ? 'drop-over-downward' : 'drop-over-upward';
  }
  return (
    <TableRowWithHandle
      previewRef={ref}
      dragRef={drag}
      isDragging={isDragging}
      {...restProps}
      className={classnames(restProps.className, dropClassName)}
    />
  );
};

export default DraggableRow;
