import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { DatePicker, Form, FormInstance, Input, InputNumber, message, Select } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';
import { BlIcon, SearchSelect } from 'src/components';
import { filter, isNull, isUndefined } from 'lodash';
import { FetchDataSetQueryCheckedDataSetPreviewFieldResponse } from 'src/api/ytt/e-report-domain/dataSet';
import { BOOLEAN_ENUM_TYPE, FIELD_TYPE_ONE_TO_ONE_SYMBOL_MAP, SYMBOL_ENUM } from '../constant';
import { addDisable } from '../utils';
import { inputtNumberIntRules } from 'src/page/custom_fields/fieldsList/components/verificationRules/rules';
import { FieldType } from 'src/dict/customField';

interface Props {
  dataSource: FetchDataSetQueryCheckedDataSetPreviewFieldResponse['data'];
  modifyData: (type: string, namePath: any[]) => void;
  form: FormInstance;
  namePath: any[];
  level?: number; // 转化层级 比如2 代表至多可转化两级
  renderCustomerFormItem?: (namePath: any, previewFieldData: any) => void;
  conditionGroupFieldName: string;
  maxCount: number;
  needDelete?: Boolean; // 是否需要删除
  needCopy?: Boolean; // 是否需要复制
  needConversion?: Boolean; // 是否需要转化
}

export const getSymbolSelectList = (data: number[]) => {
  const list = filter(SYMBOL_ENUM, (node: any) => data.includes(node?.id));

  return list;
};

export default function FilterItem(props: Props) {
  const {
    dataSource,
    form,
    namePath,
    modifyData,
    level = 2,
    renderCustomerFormItem,
    conditionGroupFieldName,
    maxCount,
    needDelete = true,
    needCopy = true,
    needConversion = true,
  } = props;

  const [hiddenConversion, setHiddenConversion] = useState<boolean>(false);

  // 隐藏转化按钮
  const hiddenConversionFn = () => {
    let number = 0;

    namePath.forEach((node: any) => {
      if (typeof node === 'string' && node === 'nextFloorCondition') {
        number += 1;
      }

      return number;
    });

    setHiddenConversion(number > level);
  };

  useEffect(() => {
    if (level) {
      hiddenConversionFn();
    }
  }, [level, namePath]);

  const renderFormItem = (namePath: any, previewFieldData: any) => {
    const renderValueControl = (selectField: any) => {
      const selectFieldType = (selectField && selectField?.fieldType) ?? 1;

      const symbol =
        form.getFieldValue([...namePath, 'symbol']) &&
        form.getFieldValue([...namePath, 'symbol'])?.key;

      const disabledStatus = symbol === 'IS NULL' || symbol === 'IS NOT NULL';

      const singleSelectType = symbol === 'NOT IN' ? 'multiple' : undefined;

      const selectMode = selectFieldType === FieldType.multiSelect ? 'multiple' : singleSelectType;

      // 单行文本
      if (selectFieldType === FieldType.text) {
        return <Input disabled={disabledStatus} />;
      }
      //  单选/多选
      if (selectFieldType === FieldType.select || selectFieldType === FieldType.multiSelect) {
        // 可选项的值由后端接口提供暂无 待后续处理
        return (
          <Select disabled={disabledStatus} labelInValue mode={selectMode}>
            {selectField?.choiceValues?.map((item: any) => {
              return (
                <Select.Option key={item?.id} value={item?.choiceCode}>
                  {item?.choiceValue}
                </Select.Option>
              );
            })}
          </Select>
        );
      }
      //  布尔型
      if (selectFieldType === FieldType.boolean) {
        // 可选项的值由后端接口提供暂无 待后续处理
        return (
          <Select disabled={disabledStatus} labelInValue mode={singleSelectType}>
            {BOOLEAN_ENUM_TYPE?.map((item: any) => {
              return (
                <Select.Option key={item?.key} value={item?.value}>
                  {item?.label}
                </Select.Option>
              );
            })}
          </Select>
        );
      }
      // 数值/整数
      if (selectFieldType === FieldType.integer || selectFieldType === FieldType.number) {
        return <InputNumber style={{ width: '100%' }} disabled={disabledStatus} />;
      }

      // 日期
      if (selectFieldType === FieldType.date) {
        return (
          <DatePicker
            showTime
            format={'YYYY-MM-DD HH:mm:ss'}
            style={{ width: '100%' }}
            disabled={disabledStatus}
          />
        );
      }

      // 关联关系
      if (selectFieldType === FieldType.relation) {
        return (
          <SearchSelect
            disabled={disabledStatus}
            fetchType="relatedToOneTemplate"
            params={{
              dataSetId: form?.getFieldValue('dataSetId')?.value,
              fieldId: selectField?.fieldId,
              objectNodeId: selectField?.objectNodeId,
            }}
            searchFieldName="fieldValue"
            style={{ width: '100%' }}
          />
        );
      }

      return <Input disabled={disabledStatus} />;
    };

    const validatorObjTemp = (rule: any, value: any) => {
      const symbol =
        form.getFieldValue([...namePath, 'symbol']) &&
        form.getFieldValue([...namePath, 'symbol'])?.key;

      const disabledStatus = symbol === 'IS NOT NULL' || symbol === 'IS NULL';

      if (disabledStatus) return Promise.resolve('');

      if (
        Object.prototype.toString.call(value) === '[object object]' &&
        !value._isAMomentObject &&
        !value?.key
      ) {
        return Promise.reject('请选择或输入');
      }
      if (isNull(value) || isUndefined(value)) {
        return Promise.reject('请选择或输入');
      }

      return Promise.resolve('');
    };

    const validatorNumber = (rule: any, value: any) => {
      const data = form.getFieldValue(namePath);

      const fieldData = (data?.object?.value && JSON.parse(data?.object?.value)) ?? {};

      if (fieldData?.fieldType !== FieldType.integer) return Promise.resolve('');

      if (data?.symbol?.value === 'IS NULL' || data?.symbol?.value === 'IS NOT NULL') {
        return Promise.resolve('');
      }

      if (!inputtNumberIntRules(value)) return Promise.reject('必须是整数');

      return Promise.resolve('');
    };

    const onChange = () => {
      // 字段改变的时候需要重置符合和值

      const oldValue = form.getFieldValue(namePath);

      const resetValue = { ...oldValue, value: undefined, symbol: undefined };

      form?.setFields([{ name: namePath, value: resetValue }]);
    };

    const onSymbolChange = () => {
      // 当选择为空或者不为空时 需要置空value的值

      const oldValue = form.getFieldValue(namePath);

      const resetValue = { ...oldValue, value: undefined };

      form?.setFields([{ name: namePath, value: resetValue }]);
    };

    return (
      <>
        <Form.Item
          name={[...namePath, 'object']}
          className={styles?.filterSelect}
          rules={[{ validator: validatorObjTemp }]}
        >
          <Select labelInValue onChange={onChange}>
            {previewFieldData?.map((item: any) => {
              return (
                <Select.Option
                  value={JSON.stringify(item)}
                  key={`${item?.objectCode}.${item?.fieldName}`}
                >
                  {`${item?.objectName}.${item?.fieldDisplayName}`}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item dependencies={[[...namePath, 'object']]} className={styles?.filterSelect}>
          {() => {
            const data = form.getFieldValue(namePath);

            const fieldType = data?.object?.value && JSON.parse(data?.object?.value)?.fieldType;

            const symbolSelectList = getSymbolSelectList(
              FIELD_TYPE_ONE_TO_ONE_SYMBOL_MAP.get(fieldType) ?? [],
            );

            return (
              <Form.Item name={[...namePath, 'symbol']} rules={[{ validator: validatorObjTemp }]}>
                <Select labelInValue onChange={onSymbolChange}>
                  {symbolSelectList?.map((item: any) => {
                    return (
                      <Select.Option key={item?.key} value={item?.key}>
                        {item?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item dependencies={[[...namePath, 'object']]} className={styles?.filterSelect}>
          {() => {
            const data = form.getFieldValue(namePath);

            const fieldData = (data?.object?.value && JSON.parse(data?.object?.value)) ?? {};

            return (
              <Form.Item
                name={[...namePath, 'value']}
                validateFirst
                rules={[
                  { validator: validatorObjTemp },
                  {
                    validator: validatorNumber,
                  },
                ]}
              >
                {renderValueControl(fieldData)}
              </Form.Item>
            );
          }}
        </Form.Item>
      </>
    );
  };

  return (
    <div className={styles?.filterCondition}>
      {renderCustomerFormItem
        ? renderCustomerFormItem([...namePath], dataSource)
        : renderFormItem([...namePath], dataSource)}
      <div className={styles?.operation}>
        {needDelete && (
          <span
            className={styles?.operationItem}
            onClick={() => {
              modifyData('delete', namePath);
            }}
          >
            <BlIcon type="iconguanbi" />
          </span>
        )}
        {needCopy && (
          <span
            className={styles?.operationItem}
            onClick={() => {
              const res = addDisable(
                form?.getFieldValue(conditionGroupFieldName)?.[0].conditionGroupFieldName,
              );

              if (res) {
                message.error(`最多添加${maxCount}个筛选项`);
              } else {
                modifyData('copy', namePath);
              }
            }}
          >
            <BlIcon type="iconmianxingfuzhi" />
          </span>
        )}

        {needConversion && !hiddenConversion && (
          <span
            className={styles?.operationItem}
            onClick={() => {
              modifyData('conversion', namePath);
            }}
          >
            <RetweetOutlined />
          </span>
        )}
      </div>
    </div>
  );
}
