import { useEffect, useState, memo } from 'react';

import ParamCard from './ParamCard';
import Styles from './index.module.scss';

interface MonitorDashboardProps {
  paramIds: Array<number>;
  refreshMarker: number;
}

const calcGridType = (selectedIds: Array<number>) => {
  if (selectedIds.length <= 1) {
    return Styles.chartsContainer11;
  } else if (selectedIds.length <= 2) {
    return Styles.chartsContainer21;
  } else if (selectedIds.length <= 4) {
    return Styles.chartsContainer22;
  } else if (selectedIds.length <= 6) {
    return Styles.chartsContainer32;
  }
  return Styles.chartsContainer33;
};

function MonitorDashboard({ paramIds, refreshMarker }: MonitorDashboardProps) {
  const [displayParamIds, setDisplayParamIds] = useState(paramIds);
  const [containerClassName, setContainerClassName] = useState<string>();
  const updateMonitorLayout = (paramIds: Array<number>) => {
    setDisplayParamIds(paramIds);
    setContainerClassName(calcGridType(paramIds));
  };

  console.log('displayParamIds', displayParamIds);

  const strechChart = (id: number) => {
    displayParamIds.length > 1 ? updateMonitorLayout([id]) : updateMonitorLayout(paramIds);
  };

  useEffect(() => {
    updateMonitorLayout(paramIds);
  }, [paramIds]);

  return (
    <div className={containerClassName}>
      {displayParamIds?.map((paramId) => (
        <div key={`paramMonitorChart-${paramId}`} className={Styles.monitorChart}>
          <ParamCard
            paramId={paramId}
            key={`paramChart-${paramId}`}
            refreshMarker={refreshMarker}
            strechChart={strechChart}
            onlyChild={paramIds.length === 1}
            fullScreen={displayParamIds.length === 1}
          />
        </div>
      ))}
    </div>
  );
}

/**
 * 在全屏/退出全屏的时候，MonitorDashboard组件会被unmount，所以加memo来缓存之前的组件，否则就会丢失之前的放大/缩小某个图表的设置
 */
export default memo(MonitorDashboard, (prevProps, nextProps) => {
  return (
    prevProps.paramIds.length === nextProps.paramIds.length &&
    prevProps.refreshMarker === nextProps.refreshMarker
  );
});
