import React from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import DetailInfo from './components/detailBaseInfo';

interface DetailPageProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const CheckItemDetailPage = (props: DetailPageProps) => {
  const { match } = props;
  const checkId = Number(match?.params?.id);

  return <DetailInfo checkId={checkId} type="page" />;
};

export default CheckItemDetailPage;
