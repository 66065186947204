import React, { useState, useEffect } from 'react';
import { message, Spin } from 'antd';
import { DetailLayout } from 'layout';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { RelationshipRender } from './component/relationShip';
import { CheckItemListTable } from './component/checkItemList';
import {
  fetchQcConfigDetail,
  fetchQcConfigSnapshotDetail,
  FetchQcConfigDetailResponse,
  fetchQcConfigDisable,
  fetchQcConfigEnable,
} from 'src/api/ytt/quality-domain/qcConfig';
import { gcTime, gcUrl } from 'src/utils';
import { materialProcessType } from 'src/dict/quality/mappings';
import { QcConfig } from 'src/page/quality/models/dto/qcConfig';
import { CheckItem } from '../../models/dto';
import { SingleOperationLogComponent, BlIcon, BcAttachmentDetail } from 'src/components';
import { getQcConfigEditUrl, getQcConfigCopyUrl } from '../navigator';
import lookup, { appEnum } from 'src/dict';
import { ObjectCode, UsageStatus } from 'src/dict/common';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import {
  CheckEntityTypeEnum,
  CheckTypeEnum,
  MaterialProcessEnum,
  RecordSampleEnum,
} from 'src/dict/quality';
import _ from 'lodash';
import { useCustomFieldCombinedData, injectCustomFieldInfos } from 'src/components/customField';
import TriggerRules from './component/triggerRules';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

type DataType = FetchQcConfigDetailResponse['data'];

const QcConfigDetail = ({ history }: { history: any }) => {
  const { id } = gcUrl.getParams();
  const [data, setData] = useState<DataType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const toQcConfigCopy = (id: number) => history.push(getQcConfigCopyUrl(id));
  const toQcConfigEdit = (id: number) => history.push(getQcConfigEditUrl(id));
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.qcConfig);
  const baseInfo = {
    title: '基本信息',
    items: [
      {
        label: '检验方案编号',
        dataIndex: 'code',
      },
      {
        label: '检验方案名称',
        dataIndex: 'name',
      },
      {
        label: '业务类型',
        dataIndex: 'checkType',
        render: lookup('quality.checkType'),
      },
      // {
      //   label: '自动触发方式',
      //   dataIndex: 'qcConfigTriggerRuleList',
      // },
      {
        label: '填写份数',
        dataIndex: 'checkEntityType',
        render: (value: number, record: QcConfig) =>
          `${lookup('quality.CheckEntityType', value)} ${
            value === CheckEntityTypeEnum.Custom ? `${record.checkEntityUnitCount}份` : ''
          }`,
      },
      {
        label: '执行页填写方式',
        dataIndex: 'reportPageType',
        render: lookup('quality.ReportPageType'),
      },
      {
        label: '备注',
        dataIndex: 'remark',
        isFullLine: true,
      },
      {
        label: '文件',
        dataIndex: 'attachmentIds',
        isFullLine: true,
        render: (value: number[]) => {
          if (!value) {
            return '-';
          }
          return <BcAttachmentDetail fileIds={value} />;
        },
      },
    ],
  };

  const checkItemListInfo = {
    title: '检验项明细列表',
    items: [
      {
        label: '',
        dataIndex: 'qcConfigCheckItemList',
        isFullLine: true,
        render: (checkItem: CheckItem[]) => (
          <CheckItemListTable
            data={checkItem}
            checkType={data?.checkType?.code}
            customFields={
              _.find(
                customFields?.subObjects,
                ({ objectCode }) => objectCode === appEnum.Common.ObjectCode.qcCheckItem,
              ) || {}
            }
          />
        ),
      },
    ],
  };

  const resultInfo = {
    title: '检验结论',
    items: _.compact([
      {
        label: '结论判定选项',
        dataIndex: 'inspectionResultOptions',
        render: (value: number[]) =>
          value?.map((i) => lookup('quality.inspectionResultOptions', i)).join(','),
      },
      data?.checkType !== CheckTypeEnum.generalQc && {
        label: '总体处理方式',
        dataIndex: 'materialBatchRecordType',
        render: (materialBatchRecordType: number) =>
          materialProcessType.find((i) => i.value === materialBatchRecordType)?.label,
      },
      data?.checkType !== CheckTypeEnum.generalQc && {
        label: '样本是否需要记录',
        dataIndex: 'recordSample',
        render: lookup('quality.RecordSample'),
      },
      data?.recordSample === RecordSampleEnum.record &&
        data?.checkType !== CheckTypeEnum.generalQc && {
          label: '样本处理方式',
          dataIndex: 'sampleProcessMethod',
          render: lookup('quality.SampleProcess'),
        },
      data?.recordSample === RecordSampleEnum.record &&
        data?.checkType !== CheckTypeEnum.generalQc && {
          label: '样本记录填写格式',
          dataIndex: 'reportFormatType',
          render: lookup('quality.ReportFormatType') || '-',
        },
      data?.checkType !== CheckTypeEnum.generalQc &&
        data?.recordSample === RecordSampleEnum.notRecord &&
        (data?.checkEntityType === CheckEntityTypeEnum.useExecuteAdd ||
          data?.checkEntityType === CheckEntityTypeEnum.Custom ||
          data?.checkEntityType === CheckEntityTypeEnum.useCheckItemConfig) &&
        data?.materialBatchRecordType === MaterialProcessEnum.MATERIAL_NO_RECORD && {
          label: '总量/抽样量是否需填写',
          dataIndex: 'recordSummaryCount',
          render: lookup('quality.recordSummaryCount'),
        },
    ]),
  };

  const relationshipInfo = {
    title: '关联关系',
    items: [
      {
        label: '',
        dataIndex: 'relationship',
        render: (_value: any, record: QcConfig) => (
          <RelationshipRender
            qcConfigCustomerList={record?.qcConfigCustomerList || []}
            qcConfigMaterialList={record?.qcConfigMaterialList || []}
            qcConfigProcessList={record?.qcConfigProcessList || []}
            qcConfigResourceList={record?.qcConfigResourceList || []}
            qcConfigSupplierList={record?.qcConfigSupplierList || []}
            checkType={record?.checkType}
          />
        ),
      },
    ],
  };

  const tiggerRulesInfo = {
    title: '预置触发规则/事件',
    items: [
      {
        label: '',
        dataIndex: 'relationship',
        render: (_value: any, record: QcConfig) => <TriggerRules id={id} />,
      },
    ],
  };

  const otherInfo = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (value: any) => {
          if (!value) return '-';
          return (
            <AvatarDisplay
              id={value.id}
              isShowTag={false}
              name={value.name}
              avatarUrl={value.avatarUrl}
            />
          );
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: (createdAt: number) => gcTime.formatLine(createdAt),
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (value: any) => {
          if (!value) return '-';
          return (
            <AvatarDisplay
              id={value.id}
              isShowTag={false}
              name={value.name}
              avatarUrl={value.avatarUrl}
            />
          );
        },
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: (updatedAt: number) => gcTime.formatLine(updatedAt),
      },
    ],
  };

  const fetchData = async () => {
    setIsLoading(true);
    const { id, isSnapshot } = gcUrl.getParams();
    let res;

    if (isSnapshot) {
      res = await fetchQcConfigSnapshotDetail({ id }, { legacy: true });
    } else {
      res = await fetchQcConfigDetail({ id }, { legacy: true });
    }

    setData(res?.data);
    setIsLoading(false);
  };

  const baseMenu = [
    {
      key: 'copy',
      onClick: () => toQcConfigCopy(Number(data?.id)),
      icon: <BlIcon type="iconfuzhi" />,
      auth: authDict.qualityinspectionscheme_add,
      title: '复制',
    },
    {
      key: 'look',
      onClick: () => setVisibleLog(true),
      auth: authDict.qualityinspectionscheme_operrecord,
      title: '操作记录',
    },
    {
      key: 'active',
      icon: <BlIcon type="iconqiyong" />,
      auth: authDict.qualityinspectionscheme_enable_disable,
      title: lookup('changeStatusAction', Number(data?.state)),
      onClick: async () => {
        const isEnable = data?.state === UsageStatus.enabled;
        const displayText = isEnable ? '停用成功' : '启用成功';

        const res =
          data?.state === UsageStatus.enabled
            ? await fetchQcConfigDisable({ id: Number(data.id) })
            : await fetchQcConfigEnable({ id: Number(data?.id) });

        if (res.code === 200) {
          message.success(displayText);
          fetchData();
        } else {
          message.success(res.message);
        }
      },
    },
    {
      key: 'edit',
      onClick: () => toQcConfigEdit(Number(data?.id)),
      auth: authDict.qualityinspectionscheme_edit,
      icon: <BlIcon type="iconfuzhi" />,
      disabled: data?.state === UsageStatus.enabled,
      title: '编辑',
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const getInfo = () => {
    if (data?.checkType === CheckTypeEnum.generalQc) {
      return [
        baseInfo,
        injectCustomFieldInfos({ customFields, type: 'detail' }),
        checkItemListInfo,
        resultInfo,
        otherInfo,
      ];
    }
    return [
      baseInfo,
      injectCustomFieldInfos({ customFields, type: 'detail' }),
      checkItemListInfo,
      resultInfo,
      relationshipInfo,
      tiggerRulesInfo,
      otherInfo,
    ];
  };

  return isLoading ? (
    <div style={{ width: '100%' }}>
      <Spin style={{ width: '100%' }} />
    </div>
  ) : (
    <>
      <DetailLayout
        title={'检验方案详情'}
        info={getInfo()}
        dataSource={data}
        userAuth={getAuthFromLocalStorage()}
        baseMenu={gcUrl.getParams().isSnapshot ? [] : baseMenu}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={gcUrl.getParams().id}
          objectCode={ObjectCode.qcConfig}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default QcConfigDetail;
