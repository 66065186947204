import React from 'react';

import { fetchResourcesList } from 'src/api/ytt/resource-domain/resource';
import {
  BatchListSelector,
  BatchListSelectorProps,
  TagTooltip,
  TagList,
  BlColumnsType,
} from 'src/components';
import { avatarDisplay } from 'src/components/avatar/show';
import { fieldTypeList } from 'src/utils';
import { lookup } from 'src/dict';
import { ResourceBusinessType } from 'src/dict/resources';
import { equipmentBusinessType } from 'src/dict/resources/mappings';
import renderQrCode from 'src/page/share/renderQrCode';
import { renderUser } from 'src/page/share/renderUser';
import ResourceClassificationCascader from '../../../components/ResourceClassificationCascader';
import ResourceTagCascader from '../../components/ResourceTagCascader';
import { ResourceListItemType } from '../../index.d';
import { fieldsLabel } from './constants';
import Styles from './index.module.scss';
import { RESORCE_STATUS_VALUE } from '../../constants';

// import Styles from './index.module.scss';

const columns = [
  {
    title: fieldsLabel.code,
    dataIndex: 'code',
    type: fieldTypeList.text,
    isFilter: true,
    sorter: true,
    fixed: 'left',
    width: 200,
  },
  {
    title: fieldsLabel.name,
    dataIndex: 'name',
    sorter: true,
    type: fieldTypeList.text,
    isFilter: true,
    width: 150,
  },
  {
    title: fieldsLabel.businessType,
    dataIndex: 'businessType',
    width: 150,
    selectProps: { options: equipmentBusinessType },
    render: lookup('resources.equipmentBusinessType'),
  },
  {
    title: fieldsLabel.classification,
    dataIndex: ['classificationVo', 'name'],
    isFilter: true,
    width: 150,
    renderItem: (
      <ResourceClassificationCascader
        showSearch
        placeholder={'请选择设备分类'}
        onCreateClick={() => {}}
        enableAdd={false}
        params={{ businessType: ResourceBusinessType.equipment, cascade: true }}
      />
    ),
    filterName: 'classificationId',
  },
  {
    title: fieldsLabel.tag,
    dataIndex: 'tagsList',
    isFilter: true,
    width: 150,
    render: (tags: ResourceListItemType['tagsList']) =>
      tags && (
        <TagList
          dataSource={tags.map((tag) => ({
            value: tag.id,
            label: `${tag.name}:${tag.content}`,
          }))}
        />
      ),
    renderItem: <ResourceTagCascader nameLabel="设备标签" />,
  },
  {
    title: fieldsLabel.level,
    dataIndex: 'level',
    width: 150,
    render: (level: ResourceListItemType['level']) =>
      level && lookup('resources.equipmentLevel', level),
  },
  {
    title: fieldsLabel.identification,
    dataIndex: 'entityLinkCode',
    width: 150,
    render: renderQrCode,
  },
  {
    title: fieldsLabel.supplier,
    dataIndex: 'supplierResDTO',
    width: 150,
    render: renderUser,
  },
  {
    title: fieldsLabel.seq,
    dataIndex: 'serialNo',
    width: 150,
    sorter: false,
  },
  {
    title: fieldsLabel.manager,
    dataIndex: 'responsibleUser',
    width: 150,
    render: (users: any[]) => <TagTooltip list={users || []} max={2} />,
  },
  {
    title: fieldsLabel.department,
    dataIndex: 'responsibleDepartment',
    width: 150,
    render: (departments: any[]) =>
      departments?.map((department) => avatarDisplay({ ...department })),
  },
  {
    title: fieldsLabel.storageArea,
    dataIndex: 'locationList',
    width: 150,
    render: (locationList: any[]) => locationList?.map(({ name }) => name).join('/'),
  },
  {
    title: fieldsLabel.parent,
    dataIndex: 'parentName',
    width: 150,
  },
  {
    title: fieldsLabel.children,
    dataIndex: 'children',
    width: 150,
    render: (children: ResourceListItemType[]) => children?.map((child) => child.name).join(','),
  },
];

const filterList = columns
  .filter((i) => i.isFilter)
  .map((column: any) => {
    const filter: any = {
      label: column.title,
      name: column.filterName || column.dataIndex,
      type: column.type,
      rules: column.rules,
      renderItem: column.renderItem,
    };

    if (column.type === fieldTypeList.select || column.type === fieldTypeList.multiSelect) {
      filter.selectProps = column.selectProps;
    }
    if (column.type === fieldTypeList.date && column.dateFormat) {
      filter.dateFormat = column.dateFormat;
    }
    if (column.filterRender) {
      filter.renderItem = column.filterRender;
    }
    return filter;
  });

const formatDataToQuery = ({ classificationId, tagsList, ...restParams }: any) => {
  return {
    ...restParams,
    classificationId: classificationId?.[classificationId.length - 1],
    tagsIdList: tagsList,
  };
};

const title = (
  <div>
    <span>批量添加设备</span>
    <span className={Styles.titleHint}>
      不支持添加重复设备，勾选已经添加的设备，保存时会自动删除
    </span>
  </div>
);

export function BatchEquipmentSelector(
  props: Omit<
    BatchListSelectorProps<ResourceListItemType>,
    'columns' | 'filterList' | 'defaultFilterValue' | 'requestFn'
  >,
) {
  return (
    <BatchListSelector<ResourceListItemType>
      {...props}
      requestFn={fetchResourcesList}
      columns={columns as BlColumnsType<ResourceListItemType>}
      filterList={filterList}
      title={title}
      defaultQueryConditions={{
        status: RESORCE_STATUS_VALUE.ENABLED,
      }}
      formatDataToQuery={formatDataToQuery}
    />
  );
}

interface BatchEquipmentSelectorCompatibleProps {
  visible: boolean;
  submitData: (data: number[], rows: any[]) => void;
  closeModal: () => void;
  defaultSelected?: number[];
}

export default function BatchEquipmentSelectorCompatible(
  props: BatchEquipmentSelectorCompatibleProps,
) {
  const { visible, submitData, closeModal, defaultSelected } = props;

  return (
    <BatchEquipmentSelector
      visible={visible}
      onCancel={closeModal}
      onFinish={submitData as (data: React.Key[], rows: any[]) => void}
      selectedKeys={defaultSelected}
    />
  );
}
