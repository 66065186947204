/**
 * 资源清单
 */
import { useState } from 'react';
import { RouterProps } from 'react-router-dom';
import { map, debounce, get, toString, isNil, isEmpty, forEach, cloneDeep } from 'lodash';
import { Button, Form, Input, DatePicker, message, InputNumber, Select, Space } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import type { NamePath } from 'antd/lib/form/interface';
import { DataFormLayout, DataFormLayoutForModal } from 'src/layout';
import { BatchOperateTableHeader, BlSortFormList, SearchSelect, TagList } from 'src/components';
import { appEnum } from 'src/dict';
import _Time from 'src/utils/dataTypes/time';
import { gcTime } from 'src/utils';
import _Array from 'src/utils/dataTypes/array';
import { validatorCheckTwoSidesTrim } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import {
  fetchWorkOrderDispatch,
  FetchWorkOrderDispatchRequest,
} from 'src/api/ytt/med-domain/work_order';
import { validateBlInputText3 } from 'src/page/custom_fields/fieldsList/components/verificationRules/verificationRules';
import { getMaterialAttrs } from 'src/entity/material';
import { replaceSign } from 'src/utils/constants';
import SplitProductionTasks from './splitProductionTasks';
import { numberMinMaxCheck } from 'src/utils/formValidators';
import { arrayIsEmpty } from 'src/utils/array';
import AttrFormTable, {
  formatDataToInitEdit,
  formatDataForSubmit,
} from 'src/page/knowledgeManagement/basicData/workCenter/shared/attrFormTable';
import { ProductionTaskFormProps } from '../createAndEdit/index.type';
import {
  GroupTypesEnum,
  UserListItem,
} from 'src/page/knowledgeManagement/basicData/workCenter/constants';

const { Option } = Select;

interface FeedingControlFormProps extends RouterProps {
  name: string;
  params: any; // 子项物料行已填写数据
  onCancel: () => void;
}

const ProductionTaskForm = (props: FeedingControlFormProps) => {
  const { name, params, onCancel } = props;

  const [form] = Form.useForm<ProductionTaskFormProps>();

  const [otherResources, setOtherResources] = useState<FormListFieldData | undefined>(); // 设置投料管控,存的当前子项物料行的field信息

  const [splitProductionTasks, setSplitProductionTasks] = useState<FormListFieldData | undefined>(); // 拆分生产任务

  const [base, setBase] = useState<any>(); // 拆分生产任务

  /**
   * 获取行的初始值
   * @param defaultValue
   * @param field
   * @returns
   */
  const getDefaultValue = (field: FormListFieldData, defaultValue: unknown) => {
    if (get(params, [name, field.name])) return undefined;
    return defaultValue;
  };

  // 校验重复
  const validateRepeat = (recordForm: any, filedName: NamePath, validateName: string) => {
    return (_rule: any, value: any) => {
      return new Promise((resolve, reject) => {
        if (!value) {
          return resolve(true);
        }
        const valueList = recordForm.getFieldValue(filedName);

        // 从 valueList 找出重复的项组成 []
        const list = valueList.map((el: any) => String(el?.[validateName]));

        const repeatList = _Array.findDuplicates(list);

        // value 如果存在 在重复项 [] 中，则 reject
        const condition = repeatList.indexOf(value);

        if (condition !== -1) {
          reject('已存在该选项！');
          return;
        }
        return resolve(true);
      });
    };
  };

  /**
   * 批量设置
   * @param amount
   */
  const handleBatchSetValue = (field: string, value: any) => {
    const formList = cloneDeep(form.getFieldValue(name));

    forEach(formList, (item) => {
      item[field] = value;
    });

    form.setFieldsValue({
      [name]: formList,
    });
  };

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    const { specificProcessInput } = params;
    const specificProcessInputIsNo = specificProcessInput === appEnum.Common.YN.no; // 指定工序投料 = 否

    return [
      {
        title: '生产任务编号',
        dataIndex: 'produceTaskCode',
        fixed: 'left',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'produceTaskCode']}
              fieldKey={[field.fieldKey, 'produceTaskCode']}
              style={{ marginBottom: '0' }}
              rules={[
                { required: true, message: '生产任务编号不能为空' },
                { max: 255, message: '不超过255字符' },
                { validator: validatorCheckTwoSidesTrim() },
                { validator: validateRepeat(form, 'dispatchRequests', 'produceTaskCode') },
                { validator: validateBlInputText3() },
              ]}
            >
              <Input />
            </Form.Item>
          );
        },
      },
      {
        title: '任务状态',
        dataIndex: 'inputAmountNumerator',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'inputAmountNumerator']}
              name={[field.name, 'inputAmountNumerator']}
              initialValue={getDefaultValue(field, 1)}
            >
              新建
            </Form.Item>
          );
        },
      },
      {
        title: '标识码',
        dataIndex: 'taskIdentifier',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'taskIdentifier']}
              name={[field.name, 'taskIdentifier']}
              rules={[
                { max: 255, message: '不超过255字符' },
                { validator: validatorCheckTwoSidesTrim() },
                { validator: validateBlInputText3() },
              ]}
            >
              <Input />
            </Form.Item>
          );
        },
      },
      // {
      //   title: '物料名称',
      //   dataIndex: 'materialName',
      //   width: 150,
      //   render: (text, field) => {
      //     return (
      //       <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
      //         {() => _.get(getMaterialInfo(field.name, 'baseInfo'), 'name')}
      //       </Form.Item>
      //     );
      //   },
      // },
      {
        title: '生产工单编号',
        dataIndex: 'code',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item noStyle>
              {form.getFieldValue([name, field.name, 'workOrderCode']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      // {
      //   title: '生产工单id',
      //   dataIndex: 'workOrderId',
      //   width: 200,
      //   render: (text, field) => {
      //     return (
      //       <Form.Item
      //         fieldKey={[field.fieldKey, 'workOrderId']}
      //         name={[field.name, 'workOrderId']}
      //         noStyle
      //       >
      //         {form.getFieldValue([name, field.name, 'workOrderId'])}
      //       </Form.Item>
      //     );
      //   },
      // },
      {
        title: '物料编号',
        dataIndex: 'inputMaterialControl',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              initialValue={getDefaultValue(
                field,
                specificProcessInputIsNo
                  ? appEnum.Bom.FeedingControl.no
                  : appEnum.Bom.FeedingControl.yes,
              )}
            >
              {form.getFieldValue([name, field.name, 'material', 'code']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料名称',
        dataIndex: 'inputBoundType',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              initialValue={getDefaultValue(field, appEnum.Bom.InputBoundType.percentage)}
            >
              {form.getFieldValue([name, field.name, 'material', 'name']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料分类',
        dataIndex: 'category',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item noStyle>
              {form.getFieldValue([name, field.name, 'material', 'category', 'name']) ??
                replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料属性',
        dataIndex: 'attributes',
        width: 150,
        render: (text, field) => {
          const material = form.getFieldValue([name, field.name, 'material']);

          return (
            <Form.Item noStyle>
              <TagList dataSource={getMaterialAttrs(material)} />
            </Form.Item>
          );
        },
      },
      {
        title: '工单计划生产数',
        dataIndex: 'OrderAmount',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item style={{ marginBottom: '0' }} initialValue={getDefaultValue(field, 0)}>
              {form.getFieldValue([name, field.name, 'plannedAmount']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '批号',
        dataIndex: 'batchNumber',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item style={{ marginBottom: '0' }} initialValue={getDefaultValue(field, 0)}>
              {form.getFieldValue([name, field.name, 'batchNumber']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '工序号',
        dataIndex: 'processNum',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item style={{ marginBottom: '0' }}>
              {form.getFieldValue([name, field.name, 'processInfo', 'processNum']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '工序编号',
        dataIndex: 'processCode',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item style={{ marginBottom: '0' }} initialValue={getDefaultValue(field, 0)}>
              {form.getFieldValue([name, field.name, 'processInfo', 'processCode']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '工序名称',
        dataIndex: 'id',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item style={{ marginBottom: '0' }} initialValue={getDefaultValue(field, 0)}>
              {form.getFieldValue([name, field.name, 'processInfo', 'processName']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '工作中心名称',
        dataIndex: 'workCenter',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item style={{ marginBottom: '0' }} initialValue={getDefaultValue(field, 0)}>
              {form.getFieldValue([name, field.name, 'processInfo', 'workCenter', 'name']) ??
                replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '工序说明',
        dataIndex: 'description',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item style={{ marginBottom: '0' }} initialValue={getDefaultValue(field, 0)}>
              {form.getFieldValue([name, field.name, 'processInfo', 'description']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '生产计划数',
        dataIndex: 'productionPlannedAmount',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item style={{ marginBottom: '0' }} initialValue={getDefaultValue(field, 0)}>
              {form.getFieldValue([
                name,
                field.name,
                'processInfo',
                'plannedAmount',
                'amountDisplay',
              ]) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '已下发数',
        dataIndex: 'dispatchedAmount',
        width: 200,
        render: (text, field) => {
          return (
            form.getFieldValue([
              name,
              field.name,
              'processInfo',
              'dispatchedAmount',
              'amountDisplay',
            ]) ?? replaceSign
          );
        },
      },
      {
        title: '可下发数',
        dataIndex: 'dispatchedRemainAmount',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item style={{ marginBottom: '0' }} initialValue={getDefaultValue(field, 0)}>
              {form.getFieldValue([
                name,
                field.name,
                'processInfo',
                'dispatchedRemainAmount',
                'amountDisplay',
              ]) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: (
          <BatchOperateTableHeader
            titleText="任务计划生产数"
            required
            rules={[
              { required: true, message: '任务计划生产数不能为空' },
              {
                validator: numberMinMaxCheck({
                  min: 0,
                  minAllowEqual: false,
                  fieldName: '任务计划生产数',
                }),
              },
            ]}
            popoverFieldComponent={<InputNumber style={{ width: '100%' }} min={0} max={10000000} />}
            batchOperation={(value) => handleBatchSetValue('plannedAmount', value)}
          />
        ),
        dataIndex: 'plannedAmount',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item
              dependencies={[
                [field.name, 'processInfo', 'dispatchedRemainAmount', 'amountDisplay'],
              ]}
            >
              {() => {
                return (
                  <Form.Item
                    name={[field.name, 'plannedAmount']}
                    fieldKey={[field.fieldKey, 'plannedAmount']}
                    style={{ marginBottom: '0' }}
                    rules={[
                      { required: true, message: '任务计划生产数不能为空' },
                      {
                        validator: numberMinMaxCheck({
                          min: 0,
                          minAllowEqual: false,
                          fieldName: '任务计划生产数',
                        }),
                      },
                    ]}
                  >
                    <InputNumber style={{ width: '100%' }} placeholder="请输入" min={0} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '工序单位',
        dataIndex: 'id',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item
              name={[field.name, 'id']}
              fieldKey={[field.fieldKey, 'id']}
              style={{ marginBottom: '0' }}
              initialValue={form.getFieldValue([
                name,
                field.name,
                'processInfo',
                'processUnit',
                'name',
              ])}
            >
              {form.getFieldValue([name, field.name, 'processInfo', 'processUnit', 'name']) ??
                replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: (
          <BatchOperateTableHeader
            titleText="计划开始时间"
            required
            rules={[{ required: true, message: '计划开始时间不能为空' }]}
            popoverFieldComponent={<DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />}
            batchOperation={(value) => handleBatchSetValue('plannedStartTime', value)}
          />
        ),
        dataIndex: 'plannedStartTime',
        width: 220,
        render: (text, field) => {
          return (
            <Form.Item
              name={[field.name, 'plannedStartTime']}
              fieldKey={[field.fieldKey, 'plannedStartTime']}
              style={{ marginBottom: '0' }}
              initialValue={_Time.formatUnixMoment(
                form.getFieldValue([name, field.name, 'processInfo', 'plannedStartTime']),
              )}
              rules={[{ required: true, message: '计划开始时间不能为空' }]}
            >
              <DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />
            </Form.Item>
          );
        },
      },
      {
        title: (
          <BatchOperateTableHeader
            titleText="计划结束时间"
            required
            rules={[{ required: true, message: '计划结束时间不能为空' }]}
            popoverFieldComponent={<DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />}
            batchOperation={(value) => handleBatchSetValue('plannedFinishTime', value)}
          />
        ),
        dataIndex: 'plannedFinishTime',
        width: 220,
        render: (text, field) => {
          return (
            <Form.Item
              name={[field.name, 'plannedFinishTime']}
              fieldKey={[field.fieldKey, 'plannedFinishTime']}
              style={{ marginBottom: '0' }}
              initialValue={_Time.formatUnixMoment(
                form.getFieldValue([name, field.name, 'processInfo', 'plannedEndTime']),
              )}
              rules={[
                { required: true, message: '计划结束时间不能为空' },
                {
                  validator: (_: any, value: any) => {
                    const startAt = form.getFieldValue([name, field.name, 'plannedStartTime']);

                    if (!value || startAt < value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('计划结束时间必须大于计划开始时间'));
                  },
                },
              ]}
            >
              <DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />
            </Form.Item>
          );
        },
      },
      {
        title: '生产部门',
        dataIndex: 'department',
        width: 220,
        render: (text, field) => {
          const val = form.getFieldValue([name, field.name]);

          return (
            <Form.Item
              name={[field.name, 'department', 'bizIdList']}
              fieldKey={[field.fieldKey, 'department', 'bizIdList']}
              style={{ marginBottom: '0' }}
            >
              <Select allowClear showArrow placeholder={'请选择'} mode="multiple" labelInValue>
                {val?.department?.departmentList
                  ?.filter(Boolean)
                  ?.map(({ id, name }: UserListItem) => (
                    <Option key={id} value={id} label={name}>
                      {name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          );
        },
      },
      {
        title: '执行人',
        dataIndex: 'user',
        width: 300,
        render: (text, field) => {
          const val = form.getFieldValue([name, field.name]);

          return (
            <Space>
              {val?.user?.bizType === GroupTypesEnum.USER ? (
                <Input disabled value={'指定用户'} style={{ width: 100 }} />
              ) : (
                <Input disabled value={'指定部门'} style={{ width: 100 }} />
              )}
              <Form.Item
                name={[field.name, 'user', 'bizIdList']}
                fieldKey={[field.fieldKey, 'user', 'bizIdList']}
                style={{ marginBottom: 0, width: 150 }}
                initialValue={_Time.formatUnixMoment(
                  form.getFieldValue([name, field.name, 'processInfo', 'plannedEndTime']),
                )}
              >
                {val?.user?.bizType === GroupTypesEnum.USER ? (
                  <Select allowClear showArrow placeholder={'请选择'} mode="multiple" labelInValue>
                    {val?.user?.userList?.filter(Boolean)?.map(({ id, name }: UserListItem) => (
                      <Option key={id} value={id} label={name}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <SearchSelect
                    fetchType={'departmentListUserUnderDepartment'}
                    mode="multiple"
                    labelInValue
                    placeholder={'请选择部门下用户'}
                    searchFieldName="search"
                    params={{
                      departmentIds: val?.user?.departmentListFilter,
                    }}
                  />
                )}
              </Form.Item>
            </Space>
          );
        },
      },
      {
        title: '设备',
        dataIndex: 'device',
        width: 220,
        render: (text, field) => {
          const val = form.getFieldValue([name, field.name]);

          return (
            <Form.Item
              name={[field.name, 'device', 'bizIdList']}
              fieldKey={[field.fieldKey, 'device', 'bizIdList']}
              style={{ marginBottom: '0' }}
            >
              <Select allowClear showArrow placeholder={'请选择'} mode="multiple" labelInValue>
                {val?.device?.resourceList?.filter(Boolean)?.map(({ id, name }: UserListItem) => (
                  <Option key={id} value={id} label={name}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          );
        },
      },

      {
        title: '其他资源',
        dataIndex: 'workCenterGroupCOList',
        width: 200,
        render: (text, field) => {
          const val = form.getFieldValue([name, field.name, 'processInfo', 'resourceGroupList']);

          if (arrayIsEmpty(val)) {
            return replaceSign;
          }

          form.setFields([
            {
              name: [name, field?.name, 'resourceGroupList'],
              value: formatDataToInitEdit({ workCenterGroupCOList: val || [] })
                ?.workCenterGroupCOList,
            },
          ]);
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'workCenterGroupCOList']}
              name={[field.name, 'workCenterGroupCOList']}
            >
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => {
                  setOtherResources(field);
                }}
              >
                设置
              </Button>
            </Form.Item>
            // </Form.Item>
          );
        },
      },
      {
        title: 'sop方案',
        dataIndex: 'sopId',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item
              name={[field.name, 'sopId']}
              fieldKey={[field.fieldKey, 'sopId']}
              style={{ marginBottom: '0' }}
              initialValue={form.getFieldValue([name, field.name, 'processInfo', 'sop', 'name'])}
            >
              <Input disabled />
            </Form.Item>
          );
        },
      },
      {
        title: (
          <BatchOperateTableHeader
            titleText="备注"
            popoverFieldComponent={
              <Input.TextArea
                style={{ width: '100%' }}
                maxLength={1000}
                placeholder="请输入"
                showCount
                autoSize
              />
            }
            batchOperation={(value) => handleBatchSetValue('remark', value)}
          />
        ),
        dataIndex: 'remark',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item
              name={[field.name, 'remark']}
              fieldKey={[field.fieldKey, 'remark']}
              style={{ marginBottom: '0' }}
            >
              <Input.TextArea
                style={{ width: '100%' }}
                maxLength={1000}
                placeholder="请输入"
                showCount
                autoSize
              />
            </Form.Item>
          );
        },
      },
      {
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width: 100,
        render: (text, field) => {
          return (
            <Form.Item style={{ marginBottom: '0' }}>
              <Button
                style={{ padding: 0 }}
                type="link"
                onClick={() => {
                  // handleCopyClick(field);
                  setSplitProductionTasks(field);
                  setBase(form.getFieldValue([name, field.name]));
                }}
              >
                拆分
              </Button>
            </Form.Item>
          );
        },
      },
    ];
  };

  const baseInfo = {
    items: [
      {
        isFullLine: true,
        render: () => (
          <BlSortFormList
            name={name}
            renderColumns={() => getColumns()}
            isNeedDrag={false}
            isNeedAdd={false}
          />
        ),
      },
    ],
  };

  /**
   * 格式化数据给接口
   * @param value
   * @returns
   */
  // : WorkOrderRequest
  const formatValueToApi = (value: any): FetchWorkOrderDispatchRequest => {
    const dispatchRequests = map(
      value?.dispatchRequests,
      ({
        plannedAmount,
        plannedFinishTime,
        plannedStartTime,
        produceTaskCode,
        remark,
        resourceGroupList,
        sopId,
        taskIdentifier,
        processInfo,
        workOrderId,
        department,
        user,
        device,
      }) => {
        return {
          plannedAmount: Number(plannedAmount),
          plannedFinishTime: Number(gcTime.formatToUnix(plannedFinishTime)),
          plannedStartTime: Number(gcTime.formatToUnix(plannedStartTime)),
          processNum: toString(processInfo?.processNum),
          produceTaskCode: toString(produceTaskCode),
          remark: toString(remark),
          resourceGroupList: formatDataForSubmit({
            workCenterGroupCOList: !arrayIsEmpty(resourceGroupList)
              ? [department, user, device, ...resourceGroupList]
              : [department, user, device],
          }).workCenterGroupCOList,
          sopId: Number(sopId),
          taskIdentifier: taskIdentifier ? toString(taskIdentifier) : null,
          workOrderId: Number(workOrderId),
        };
      },
    );

    return {
      dispatchRequests,
    };
  };

  const handleFinish = async () => {
    try {
      const formValue: any = await form.validateFields();

      if (arrayIsEmpty(formValue?.dispatchRequests)) {
        message.error('下发数量不可为空');
        return;
      }

      const value = await formatValueToApi(formValue);

      // if (typeof onSubmit === 'function') {
      fetchWorkOrderDispatch(value).then((res) => {
        if (res.code === 200) message.success(`下发${res.message}`);
        onCancel();
      });

      // }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  /**
   * 处理 资源组 变动
   * @param value
   */
  const handleOtherResourcesChange = (value?: any) => {
    if (otherResources && !isNil(value) && !isEmpty(value)) {
      const resourceGroupList = map(
        value[otherResources.name]?.resourceGroupList,

        ({ bizIdList: _bizIdList, departmentList, ...rest }) => {
          return {
            ...rest,
            bizIdList: map(_bizIdList, ({ key }) => {
              return Number(key);
            }),
            departmentList: arrayIsEmpty(departmentList)
              ? map(_bizIdList, ({ key, label }) => {
                  return { id: Number(key), name: label, value: Number(key) };
                })
              : departmentList,
          };
        },
      );

      form.setFields([
        {
          name: [name, otherResources.name, 'resourceGroupList'],
          value: resourceGroupList,
        },
        {
          name: [name, otherResources.name, 'processInfo', 'resourceGroupList'],
          value: resourceGroupList,
        },
      ]);
    }
    setOtherResources(undefined);
  };

  const formatResourceGroupList = (processInfo: any) => {
    const _resourceGroupList = processInfo?.resourceGroupList?.slice(0, 3);

    const formatResourceGroupList = formatDataToInitEdit({
      workCenterGroupCOList: _resourceGroupList || [],
    })?.workCenterGroupCOList;

    return {
      processInfo: {
        ...processInfo,
        resourceGroupList: processInfo?.resourceGroupList?.slice(3),
      },
      plannedAmount: processInfo?.dispatchedRemainAmount?.amountDisplay,
      department: formatResourceGroupList.find((e) => e.groupType === GroupTypesEnum.DEPARTMENT),
      user: formatResourceGroupList.find((e) => e.groupType === GroupTypesEnum.USER),
      device: formatResourceGroupList.find((e) => e.groupType === GroupTypesEnum.GROUPTYPE),
    };
  };

  const formatData = (params: any) => {
    return map(params, ({ defaultTaskCode, processInfo, ...rest }, index) => ({
      ...rest,
      order: index,
      produceTaskCode: defaultTaskCode,
      ...formatResourceGroupList(processInfo),
    }));
  };

  return (
    <>
      <DataFormLayout
        form={form}
        info={[baseInfo]}
        title="新建生产任务"
        onCancel={() => {
          onCancel();
        }}
        onFinish={debounce(handleFinish, 1500)}
        formProps={{
          preserve: true,
          initialValues: { [name]: formatData(params) },
        }}
      />
      <DataFormLayoutForModal
        visible={!isEmpty(otherResources)}
        onClose={() => {
          setOtherResources(undefined);
        }}
        content={
          <DataFormLayout
            form={form}
            title="分配资源"
            info={[
              {
                items: [
                  {
                    isFullLine: true,
                    render: () => (
                      <AttrFormTable
                        form={form}
                        filedName={[name, String(otherResources?.name), 'resourceGroupList']}
                        onlySelectBiz
                        initialData={false}
                        limit
                      />
                    ),
                  },
                ],
              },
            ]}
            onFinish={() => {
              handleOtherResourcesChange(form.getFieldValue(name));
            }}
            onCancel={() => {
              setOtherResources(undefined);
            }}
          />
        }
      />
      <DataFormLayoutForModal
        visible={!isEmpty(splitProductionTasks)}
        onClose={() => {
          setSplitProductionTasks(undefined);
        }}
        content={
          <SplitProductionTasks
            form={form}
            name="splitTasks"
            field={splitProductionTasks}
            base={base}
            onChange={() => {
              setSplitProductionTasks(undefined);
            }}
          />
        }
      />
    </>
  );
};

export default ProductionTaskForm;
