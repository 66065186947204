import React, { useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { FormInstance, InputNumber, Radio } from 'antd';
import { PRODUCTION_WAY_TYPE } from '../constant';
import CustomQrCodeTable from './customCodeTable';
import { validatePositiveInteger } from 'src/page/knowledgeManagement/share/warehouseRules';
import { SearchSelect } from 'src/components';
import { OBJECT_OF_ID } from 'src/entity/objectPlatform';

interface baseInfoProps {
  onFinish: () => void;
  form: FormInstance;
}

const QrCodeBaseInfo = (props: baseInfoProps) => {
  const { onFinish, form } = props;

  const [ruleTypeValue, setRuleType] = useState<number>(0);

  const numberRuleHidden = ruleTypeValue !== PRODUCTION_WAY_TYPE.NUMBER_RULE;
  const customerHidden = ruleTypeValue !== PRODUCTION_WAY_TYPE.CUSTOMER;

  const ruleTypeChange = (value: number) => {
    setRuleType(value);
  };

  const maxNumber = () => {
    return (_rule: any, value: number) => {
      return new Promise((resolve, reject) => {
        if (value > 500) {
          return reject('最大值为500');
        }
        if (value === 0) {
          return reject('必须为正整数');
        }

        return resolve(true);
      });
    };
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    items: [
      {
        label: '生产方式',
        name: 'ruleType',
        rules: [{ required: true, message: '生产方式必填' }],
        initialValue: PRODUCTION_WAY_TYPE?.SYSTEM_DEFAULT,
        render: () => (
          <Radio.Group onChange={(e) => ruleTypeChange(e?.target?.value)}>
            <Radio value={PRODUCTION_WAY_TYPE.SYSTEM_DEFAULT}>系统默认</Radio>
            <Radio value={PRODUCTION_WAY_TYPE.NUMBER_RULE}>编号规则</Radio>
            <Radio value={PRODUCTION_WAY_TYPE.CUSTOMER}>自定义</Radio>
          </Radio.Group>
        ),
      },
      {
        label: '标识码数量',
        name: 'count',
        hidden: !customerHidden,
        rules: [
          { required: customerHidden, message: '标识码数量必填' },
          { validator: maxNumber() },
          { validator: validatePositiveInteger() },
        ],
        render: () => <InputNumber style={{ width: '100%' }} />,
      },
      {
        label: '关联对象',
        name: 'businessObjectCode',
        render: () => <SearchSelect fetchType={'objectByQrCode'} labelInValue />,
      },
      {
        label: '编号规则',
        name: 'ruleId',
        hidden: numberRuleHidden,
        rules: [{ required: !numberRuleHidden, message: '编号规则必填' }],
        render: () => {
          // 调用编号规则的接口 获取存在的编号规则
          return (
            <SearchSelect
              labelInValue
              searchFieldName={'name'}
              fetchType={'numberingRules'}
              placeholder={'请选择'}
              params={{ suitObjId: OBJECT_OF_ID.codeEntityLink, enableFlag: 1 }}
            />
          );
        },
      },
      {
        label: '自定义标识码',
        hidden: customerHidden,
        required: !customerHidden,
        render: () => <CustomQrCodeTable {...props} />,
      },
    ],
  };

  return (
    <DataFormLayout
      title="新建标识"
      form={form}
      info={[baseInfo]}
      onFinish={onFinish}
      formProps={{ labelCol: { flex: '120px' } }}
      onCancel={() => {
        form?.resetFields();
      }}
      cancelText={'重置'}
      okText={'确定'}
      formLayout={'horizontal'}
    />
  );
};

export default QrCodeBaseInfo;
