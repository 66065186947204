import React from 'react';
import { RouterProps } from 'react-router-dom';

import _ from 'lodash';
import moment from 'moment';
import { BlIcon, BlTable, TagList } from 'src/components';
import { AvatarDisplay } from 'src/components/avatar/show';
import lookup, { appEnum } from 'src/dict';
import { DetailLayout } from 'src/layout';
import { valueOrHolder } from 'src/utils/formatters';
import { getMaterialAttrs } from 'src/entity/material';
import { ENGINEER_PATH } from 'src/page/knowledgeManagement/engineerData/navigation';
//
import { BomResponseInputMaterial } from '../index.type';
import { Button } from 'antd';

interface AlternativeDetailProps extends RouterProps {
  dataSource?: BomResponseInputMaterial;
}

const AlternativeDetail = (props: AlternativeDetailProps) => {
  const { dataSource, history } = props;

  const { alternativePlanDetailVO } = dataSource ?? {};

  const getBaseInfo = () => {
    const baseInfo = [
      {
        title: '基础信息',
        items: [
          {
            label: '替代方案编号',
            dataIndex: 'code',
          },
          {
            label: '替代方案名称',
            dataIndex: 'name',
          },
          {
            label: '所属部门',
            dataIndex: 'ownedDepartmentName',
          },
          {
            label: '替代策略',
            dataIndex: 'substitutionStrategy',
            render: (text: any) => lookup('alternativePlan.substitutionStrategyMap', text),
          },
          {
            label: '指定物料清单',
            dataIndex: 'specificBom',
            render: (text: any) => lookup('yn', text),
          },
          {
            label: '备注',
            dataIndex: 'remark',
          },
        ],
      },
      alternativePlanDetailVO?.specificBom === appEnum.Common.YN.yes && {
        title: '适用物料清单',
        items: [
          {
            label: '',
            dataIndex: 'bomBasicVO',
            render: (dataSource: any) => (
              <BlTable
                dataSource={!_.isEmpty(dataSource) ? [dataSource] : []}
                columns={[
                  {
                    title: '父项物料编号',
                    dataIndex: ['material', 'baseInfo', 'code'],
                  },
                  {
                    title: '版本号',
                    dataIndex: 'version',
                  },
                  {
                    title: '物料名称',
                    dataIndex: ['material', 'baseInfo', 'name'],
                  },
                  {
                    title: '物料属性',
                    dataIndex: ['material', 'attribute'],
                    render: (_text: any, record: any) => (
                      <TagList dataSource={getMaterialAttrs(record?.material)} />
                    ),
                  },
                  {
                    title: '物料分类',
                    dataIndex: ['material', 'category', 'name'],
                  },
                  {
                    title: '物料规格',
                    dataIndex: ['material', 'baseInfo', 'specification'],
                  },
                ]}
              />
            ),
          },
        ],
      },
      {
        title: '被替代物料',
        items: [
          {
            label: '',
            dataIndex: 'originalAlternativeMaterials',
            render: (dataSource: any) => (
              <BlTable
                dataSource={dataSource}
                scroll={{ x: 'max-content' }}
                columns={[
                  {
                    title: '序号',
                    dataIndex: 'lineNo',
                    width: 80,
                    render: (text, record, index) => index + 1,
                  },
                  {
                    title: '物料名称',
                    dataIndex: ['material', 'baseInfo', 'name'],
                  },
                  {
                    title: '物料编号',
                    dataIndex: ['material', 'baseInfo', 'code'],
                  },
                  {
                    title: '物料属性',
                    dataIndex: ['material', 'attribute'],
                    render: (_text: any, record: any) => (
                      <TagList dataSource={getMaterialAttrs(record?.material)} />
                    ),
                  },
                  {
                    title: '物料分类',
                    dataIndex: ['material', 'category', 'name'],
                  },
                  {
                    title: '物料规格',
                    dataIndex: ['material', 'baseInfo', 'specification'],
                  },
                  {
                    title: '使用比例',
                    dataIndex: 'useRatio',
                    width: 120,
                  },
                  {
                    title: '数量:分子',
                    dataIndex: 'amountNumerator',
                    width: 150,
                  },
                  {
                    title: '数量:分母',
                    dataIndex: 'amountDenominator',
                    width: 150,
                  },
                  {
                    title: '单位',
                    dataIndex: 'unitName',
                    width: 150,
                  },
                ]}
              />
            ),
          },
        ],
      },
      {
        title: '替代物料',
        items: [
          {
            label: '',
            dataIndex: 'alternativeMaterials',
            render: (dataSource: any) => (
              <BlTable
                dataSource={dataSource}
                scroll={{ x: 'max-content' }}
                columns={[
                  {
                    title: '序号',
                    dataIndex: 'lineNo',
                    width: 80,
                    render: (text, record, index) => index + 1,
                  },
                  {
                    title: '优先级',
                    dataIndex: 'priority',
                    width: 80,
                  },
                  {
                    title: '物料名称',
                    dataIndex: ['material', 'baseInfo', 'name'],
                    width: 200,
                  },
                  {
                    title: '物料编号',
                    dataIndex: ['material', 'baseInfo', 'code'],
                  },
                  {
                    title: '物料属性',
                    dataIndex: ['material', 'attribute'],
                    width: 200,
                    render: (_text: any, record: any) => (
                      <TagList dataSource={getMaterialAttrs(record?.material)} />
                    ),
                  },
                  {
                    title: '物料分类',
                    dataIndex: ['material', 'category', 'name'],
                    width: 200,
                  },
                  {
                    title: '物料规格',
                    dataIndex: ['material', 'baseInfo', 'specification'],
                    width: 200,
                  },
                  {
                    title: '使用比例',
                    dataIndex: 'useRatio',
                    width: 120,
                  },
                  {
                    title: '数量:分子',
                    dataIndex: 'amountNumerator',
                    width: 150,
                  },
                  {
                    title: '数量:分母',
                    dataIndex: 'amountDenominator',
                    width: 150,
                  },
                  {
                    title: '单位',
                    dataIndex: 'unitName',
                    width: 150,
                  },
                  {
                    title: '领料方式',
                    dataIndex: 'pickMode',
                    width: 150,
                    render: (text: any) => lookup('bom.pickMode', text),
                  },
                  {
                    title: '投料质量状态',
                    dataIndex: 'feedingQualityStatus',
                    width: 150,
                    render: (text: any) => {
                      return !_.isEmpty(text)
                        ? text
                            ?.map((item: any) => lookup('material.feedingQualityStatus', item))
                            .join(',')
                        : null;
                    },
                  },
                  {
                    title: '使用上线比例(%)',
                    dataIndex: 'useUpperLimitRatio',
                    width: 200,
                  },
                  {
                    title: '备注',
                    dataIndex: 'remark',
                    width: 200,
                  },
                ]}
              />
            ),
          },
        ],
      },
      {
        title: '其他信息',
        items: [
          {
            label: '创建人',
            dataIndex: ['creator'],
            render: (creator: any) =>
              valueOrHolder(creator, (creator: any) => (
                <AvatarDisplay {...creator} isShowTag={false} isUser />
              )),
          },
          {
            label: '创建时间',
            dataIndex: ['createdAt'],
            render: (text: any) =>
              valueOrHolder(text, (text) => moment(text).format('YYYY-MM-DD HH:mm:ss')),
          },
          {
            label: '更新人',
            dataIndex: ['operator'],
            render: (operator: any) =>
              valueOrHolder(operator, (operator: any) => (
                <AvatarDisplay {...operator} isShowTag={false} isUser />
              )),
          },
          {
            label: '更新时间',
            dataIndex: ['updatedAt'],
            render: (text: any) =>
              valueOrHolder(text, (text) => moment(text).format('YYYY-MM-DD HH:mm:ss')),
          },
        ],
      },
    ];

    return _.compact(baseInfo);
  };

  return (
    <div>
      <DetailLayout
        title="替代料详情"
        dataSource={alternativePlanDetailVO}
        info={getBaseInfo()}
        baseMenu={[
          {
            key: appEnum.Common.CRUD.edit,
            icon: <BlIcon type="iconbianji" />,
            title: lookup('crud', appEnum.Common.CRUD.edit),
            buttonRender: (
              <Button
                type="primary"
                onClick={() => {
                  history.push(
                    `${ENGINEER_PATH}/alternativePlan/edit/${alternativePlanDetailVO?.id}`,
                  );
                }}
                style={{ marginRight: 30 }}
              >
                编辑
              </Button>
            ),
          },
        ]}
      />
    </div>
  );
};

export default AlternativeDetail;
