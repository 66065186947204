/**
 * @enum 公共枚举值
 */

/** 是否(Yes/No) */
export enum YN {
  /** 否 */
  no,
  /** 是 */
  yes,
}

/** 是否, 布尔值 */
export const YNB = {
  /** 否 */
  no: false,
  /** 是 */
  yes: true,
};

/** 是否, 字符串 */
export const YNS = {
  /** 否 */
  no: 'false',
  /** 是 */
  yes: 'true',
};

/** 成败(Success/Failure) */
export enum SF {
  /** 失败 */
  failure,
  /** 成功 */
  success,
  /** 部分成功 */
  partSuccess,
}

/** 增查改删(Create, Retrieve, Update, Delete) */
export enum CRUD {
  /** 新建 */
  create = 'create',
  /** 编辑 */
  edit = 'edit',
  /** 删除 */
  delete = 'delete',
  /** 查看 */
  view = 'view',
  /** 复制 */
  copy = 'copy',
  /** 下发 */
  issued = 'issued',
  /** 启用 */
  enable = 'enable',
  /** 停用 */
  disable = 'disable',
  /** 操作记录 */
  operrecord = 'operrecord',
}

/** 开关、停启(on/off) */
export enum Turn {
  /** 关闭/停用 */
  off,
  /** 开启/启用 */
  on,
}

/** 时间单位 */
export enum Time {
  /** 秒 */
  second = 1,
  /** 分钟 */
  minute,
  /** 小时 */
  hour,
  /** 日 */
  day,
  /** 月 */
  month,
  /** 年 */
  year,
}

/** 使用状态 */
export enum UsageStatus {
  /** 停用中 */
  disabled,
  /** 启用中 */
  enabled,
}

/**
 * 视图模式
 */
export enum ViewModal {
  /**
   * 按单据
   */
  documents = '1',
  /**
   * 按明细行
   */
  detail = '2',
}

/** 对象管理 已在 src/entity/objectPlatform/index.ts 目录下管理，以下请尽快迁移 */
export enum ObjectCode {
  /** 「物料」 对象编号 */
  material = 'Material',
  /** 「物料分类」 对象编号 */
  materialCategory = 'MaterialCategory',
  /** 「物料单位」 对象编号 */
  materialUnit = 'Unit',
  /** 「批号」 对象编号 */
  batchNo = 'BatchNo',
  /** 「供应商」 对象编号 */
  Supplier = 'Supplier',
  Equipment = 'Equipment',
  Location = 'Location',
  Warehouse = 'Warehouse',
  StorageLocation = 'StorageLocation',
  User = 'User',
  Unit = 'Unit',
  Zone = 'Zone',
  qcConfig = 'QualityInspectionScheme',
  qcCheckItem = 'SpecificInspectionItem',
  /** 触发规则 */
  triggerRules = 'TriggerRules',
  /** 触发事件 */
  triggerAction = 'TriggerAction',
  qcTask = 'QualityInspectionTask',
  qcCheckItemRecord = 'InspectionItemRecord',
  /** 资源分类 */
  ResourceType = 'ResourceType',
  /** 能源仪表 */
  EnergyInstrument = 'EnergyInstrument',
  /** 生产工单 */
  POConfig = 'ProductionOrder',
  /** 计划订单 */
  PlanOrderConfig = 'PlanOrder',
  /** 数采规则 */
  DataCollectionRule = 'DataAcquisitionRule',
}

/** 对象管理 已在 src/entity/objectPlatform/index.ts 目录下管理，以上请尽快迁移 END */

/** 字段类型 */
export enum FieldType {
  /** 单行文本 */
  text = 1,
  /** 数值 */
  number,
  /** 多行文本 */
  textArea,
  /** 单选 */
  select,
  /** 多选 */
  multiSelect,
  /** 布尔值 */
  boolean,
  /** 整数 */
  integer,
  /** 日期时间 */
  date,
  /** 链接 */
  url,
  /** 引用字段 */
  reference,
  /** 关联关系字段 */
  relation,
  /** 主从关系字段。因后端变动, 原来的枚举值12弃用 */
  subordinate = 13,
  /** 附件类型 */
  appendix,
}

/** 字段分类 */
export enum FieldCategory {
  /** 自定义字段 */
  customFields = 1,
  /** 预置字段 */
  preFields,
}

/** 对象分类 */
export enum ObjectCategory {
  /** 自定义对象 */
  customObject = 1,
  /** 标准对象 */
  preObject,
}

/** 关联关系类型 */
export enum TargetType {
  /** 对一 */
  singleChoice = 0,
  /** 对多 */
  multiChoice,
}
