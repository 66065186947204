import { message as Message } from 'antd';
import { ErrorNotification, showErrorListMessage } from 'src/components';
import { replaceSign } from 'src/utils/constants';
import _Array from 'src/utils/dataTypes/array';
import _Object from 'src/utils/dataTypes/object';
import { throttle } from 'lodash';

export const formatStoreValue = (value: any) => {
  const formatEnableQcCtrl = value?.enableQcCtrl ? 1 : 0;
  const formatEnableStorageLocation = value?.enableStorageLocation ? 1 : 0;
  const formatQcCtrlList = formatEnableQcCtrl ? value?.qcCtrlList : [];

  if (value?.customFieldItems && !_Object.isEmpty(value?.customFieldItems)) {
    value.customFieldItems = Object.keys(value?.customFieldItems)
      .filter((item) => value?.customFieldItems[item])
      .map((key) => {
        const relValue = Array.isArray(value.customFieldItems[key])
          ? value.customFieldItems[key].join(',')
          : value.customFieldItems[key].toString();

        return { id: Number(key), value: relValue };
      });
  }

  const formatValue = {
    ...value,
    enableQcCtrl: formatEnableQcCtrl,
    enableStorageLocation: formatEnableStorageLocation,
    qcCtrlList: formatQcCtrlList,
  };

  return formatValue;
};

export const formatTreeData = (data: any) => {
  const newData = data?.map((item: any) => {
    if (!_Array.isEmpty(item?.sonStorage)) {
      formatTreeData(item?.sonStorage);
    }
    const newItem = {
      ...item,
      title: item?.name,
      key: item?.id,
      children: formatTreeData(item?.sonStorage),
    };

    return newItem;
  });

  return newData;
};

// 详情请求操作方法
export const batchOperationRequest = (
  jsonId: (string | number)[],
  display: string,
  requestApi: any,
  fetchData: any,
) => {
  requestApi(jsonId).then((res: any) => {
    const { code, message } = res;

    if (code === 200) {
      Message.success(display);
      fetchData();
      return;
    }
    Message.error(message);
  });
};

const errorColumns = [
  {
    title: '名称',
    dataIndex: 'name',
    width: 200,
    render: (name: string) => name || replaceSign,
  },
  {
    title: '编号',
    dataIndex: 'code',
    width: 200,
    render: (code: string) => code || replaceSign,
  },
  {
    title: '失败原因',
    dataIndex: 'errorDesc',
    width: 300,
    render: (errorDesc: string) => errorDesc,
  },
];

// 列表批量操作请求通用方法
export const patchRequest = (
  type: string,
  patchApi: any,
  selectedIds: number[],
  display: string,
  onSuccess: any,
  onFail?: any,
) => {
  patchApi({ ids: selectedIds })
    .then((res: any) => {
      const {
        data: { failCount, successCount, failDetailList },
      } = res;

      if (failCount === 0) {
        Message.success(`${display}成功`);
        onSuccess?.();
        return;
      }
      if (successCount === 0) {
        onFail?.();
        // 全部删除失败
      } else if (failCount) {
        onSuccess?.();
        // 有失败的删除 需要展示失败信息
      }

      if (type === 'single') {
        Message.error(failDetailList[0]?.errorDesc);
      } else {
        showErrorListMessage({
          title: `${display}出现失败`,
          data: failDetailList,
          columns: errorColumns,
          operateName: display,
          successCount,
          failCount,
          width: 800,
        });
      }
    })
    .catch((err: any) => {
      console.log('err: ', err);
      onFail();
    });
};

export const throttleFn = (fn: any) => {
  return throttle(fn, 2000, {
    leading: true,
    trailing: false,
  });
};
