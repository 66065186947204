import { useState, useEffect } from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import { DetailLayout } from 'layout';
import { BlTable, BlIcon, SingleOperationLogComponent, TagList } from 'components';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import moment from 'moment';
import { replaceSign } from 'src/utils/constants';
import {
  fetchTransferOrderDetail,
  fetchTransferOrderIssue,
  fetchTransferOrderClose,
} from 'src/api/ytt/inventory-domain/transferOrder';
import lookup from 'src/dict';
import _ from 'lodash';
import { BOUND_PATH as basePath } from '../index';
import { StatusEnum, TransferTypeEnum } from 'src/dict/bound';
import { patchTransferRequest } from '../utils';
import authDict from 'src/utils/auth';
import {
  injectCustomFieldColumns,
  injectCustomFieldInfos,
  useCustomFieldCombinedData,
} from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

interface DetailPageProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const DetailPage = (props: DetailPageProps) => {
  const { match, history } = props;
  const id = Number(match?.params?.id);

  const [dataSource, setDataSource] = useState({} as any);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.transferOrder);

  const fetchData = async () => {
    try {
      if (!id) {
        return;
      }
      const { data } = await fetchTransferOrderDetail({ id });

      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const baseInfo = [
    {
      title: '基本信息',
      items: [
        { label: '调拨单编号', dataIndex: 'code' },
        {
          label: '业务类型',
          dataIndex: 'bizType',
          render: lookup('bound.TransferType'),
        },
        {
          label: '状态',
          dataIndex: 'bizStatus',
          render: lookup('bound.Status'),
        },
        {
          label: '发出仓库',
          dataIndex: 'sourceWarehouse',
          render: (value: any) => value?.name ?? replaceSign,
        },
        {
          label: '接收仓库',
          dataIndex: 'targetWarehouse',
          render: (value: any) => value?.name ?? replaceSign,
        },
        {
          label: '备注',
          dataIndex: 'remark',
          render: (value: string) => value ?? replaceSign,
        },
      ],
    },
    injectCustomFieldInfos({ customFields, type: 'detail' }),
    {
      title: '物料列表',
      items: [
        {
          label: '',
          dataIndex: 'transferOrderItems',
          render: () => (
            <BlTable
              style={{ width: '100%' }}
              rowKey={'lineNo'}
              // scroll={{ x: dataSource.bizType === TransferTypeEnum.picking ? 1500 : 1200 }}
              scroll={{
                x: 'max-content',
              }}
              pagination={false}
              columns={injectCustomFieldColumns({
                columns: _.compact([
                  {
                    title: '行号',
                    dataIndex: 'lineNo',
                    width: 70,
                  },
                  {
                    title: '物料编号',
                    dataIndex: 'materialInfo',
                    width: 200,
                    render: (value: any) => value?.baseInfo?.code,
                  },
                  {
                    title: '物料名称',
                    dataIndex: 'materialInfo',
                    render: (value: any) => value?.baseInfo?.name,
                  },
                  {
                    title: '物料属性',
                    dataIndex: 'materialInfo',
                    render: (value: any) => {
                      if (_.isEmpty(value.attribute)) return replaceSign;
                      return (
                        <TagList
                          dataSource={_.map(value.attribute, ({ name, attributeItem }) => ({
                            label: `${name}:${attributeItem.content}`,
                            value: attributeItem.content,
                          }))}
                        />
                      );
                    },
                  },
                  {
                    title: '物料规格',
                    dataIndex: 'materialInfo',
                    render: (value: any) => value?.baseInfo?.specification,
                  },
                  dataSource.bizType === TransferTypeEnum.picking && {
                    title: '领料申请编号',
                    width: 150,
                    dataIndex: ['entity', 'code'],
                    render: (value: string) => value,
                  },
                  dataSource.bizType === TransferTypeEnum.picking && {
                    title: '领料申请行号',
                    width: 150,
                    dataIndex: ['entity', 'lineNo'],
                    render: (value: number) => value,
                  },
                  {
                    title: '状态',
                    dataIndex: 'bizStatus',
                    width: 100,
                    render: lookup('bound.Status'),
                  },
                  {
                    title: '申请数量',
                    width: 100,
                    dataIndex: 'planAmount',
                    render: (value: number, record: any) => `${value} ${record?.unit?.name}`,
                  },
                  {
                    title: '已发数量',
                    width: 100,
                    dataIndex: 'issueAmount',
                    render: (value: number, record: any) => {
                      return Number(value) > 0 ? (
                        <a
                          href={`/warehouseManagement/executiveManagement/commitRecord?itemId=${record?.id}`}
                          target={'_blank'}
                          rel="noreferrer"
                        >
                          {`${value} ${record?.unit?.name}`}
                        </a>
                      ) : (
                        value
                      );
                    },
                  },
                  {
                    title: '已收数量',
                    width: 100,
                    dataIndex: 'receiveAmount',
                    render: (value: number, record: any) => {
                      return (
                        <a
                          href={`/warehouseManagement/executiveManagement/commitRecord?itemId=${record?.id}`}
                          target={'_blank'}
                          rel="noreferrer"
                        >
                          {`${value} ${record?.unit?.name}`}
                        </a>
                      );
                    },
                  },
                  {
                    title: '指定批号',
                    width: 150,
                    dataIndex: 'materialBatchInfo',
                    render: (value: any) => value?.batchNo,
                  },
                  {
                    title: '指定供应商',
                    dataIndex: 'supplier',
                    render: (value: any) => value?.name,
                    width: 150,
                  },
                  {
                    title: '备注',
                    width: 100,
                    dataIndex: 'remark',
                  },
                ]), // 原本的列
                customFields, // 自定义字段信息
                objectCode: OBJECT_OF_CODE.transferOrderItem, // 从对象code
                type: 'detail', // 使用类型
              })}
              dataSource={dataSource.transferOrderItems}
            />
          ),
        },
      ],
    },
    {
      title: '其他信息',
      items: [
        {
          label: '创建人',
          dataIndex: 'creator',
          render: (creator: any) => {
            return creator ? <AvatarDisplay {...creator} isShowTag={false} isUser /> : replaceSign;
          },
        },
        {
          label: '创建时间',
          dataIndex: 'createdAt',
          isFullLine: true,
          render: (createdAt: number) =>
            moment(createdAt).format('YYYY-MM-DD HH:mm:ss') ?? replaceSign,
        },

        {
          label: '更新人',
          dataIndex: 'operator',
          render: (operator: any) => {
            return operator ? (
              <AvatarDisplay {...operator} isShowTag={false} isUser />
            ) : (
              replaceSign
            );
          },
        },
        {
          label: '更新时间',
          dataIndex: 'updatedAt',
          render: (updatedAt: number) =>
            moment(updatedAt).format('YYYY-MM-DD HH:mm:ss') ?? replaceSign,
        },
      ],
    },
  ];
  const { bizStatus } = dataSource;
  const baseMenu = _.compact([
    {
      key: 'operate',
      auth: authDict.transferorder_operrecord,
      title: '操作记录',
      onClick: () => {
        setVisibleLog(true);
      },
    },
    bizStatus === StatusEnum.xinjian && {
      key: 'xiafa',
      auth: authDict.transferorder_dispatch,
      onClick: () => {
        if (!dataSource.id) return;
        patchTransferRequest(fetchTransferOrderIssue, { ids: [dataSource?.id] }, '下发', fetchData);
      },
      icon: <BlIcon type="iconbianji" />,
      title: '下发',
    },
    bizStatus !== StatusEnum.yiguanbi && {
      key: 'close',
      auth: authDict.transferorder_close,
      onClick: () => {
        if (!dataSource.id) return;
        patchTransferRequest(fetchTransferOrderClose, { ids: [dataSource?.id] }, '关闭', fetchData);
      },
      icon: <BlIcon type="iconbianji" />,
      title: '关闭',
    },
    bizStatus !== StatusEnum.yiguanbi && {
      key: 'edit',
      auth: authDict.transferorder_edit,
      onClick: () => history.push(`${basePath}/transferOrder/${id}/edit`),
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ]);

  return (
    <>
      <DetailLayout
        title="调拨单详情"
        info={baseInfo}
        dataSource={dataSource}
        baseMenu={baseMenu}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={Number(id)}
          objectCode={'TransferOrder'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailPage;
