export const FIELD_MAP = new Map([
  ['name', 'name'],
  ['periodNum', 'period_num'],
  ['type', 'type'],
  ['updatedAt', 'updated_at'],
  ['createdAt', 'created_at'],
]);

export const CYCLE_TYPE_ENUM = new Map([
  [1, '按天'],
  [2, '按周'],
  [3, '按月'],
]);

export const CYCLE_TYPE_DETAIL_ENUM = new Map([
  [1, '天'],
  [2, '周'],
  [3, '个月'],
]);

export const CYCLE_NUMBER_TYPE = {
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
};

export const WEED_TYPE_ENUM = new Map([
  [0, '日'],
  [1, '一'],
  [2, '二'],
  [3, '三'],
  [4, '四'],
  [5, '五'],
  [6, '六'],
]);
