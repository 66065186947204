import RoleManagement from 'src/page/organization/roleManagement';
import UserRoleManagement from 'src/page/organization/userManagement';
import UserCreateAndEdit from 'src/page/organization/userManagement/user/createAndEdit';
import UserDetail from 'src/page/organization/userManagement/user/userDetail';
import DepartmentDetail from 'src/page/organization/userManagement/department/departmentDetail';
import OperationLogList from '../logManagement/operationLog';
import OperationLogDetailPage from '../logManagement/operationLog/detail/detailPage';
import LicenseInformation from './licenseInformation';
import authDict from 'src/utils/auth';
import RoleDetailPage from './hooks/roleDisplayPage';
import CustomizedReport from './customizedReport';
import CustomizedReportDetail from './customizedReport/detail/detailPage';
import CustomizedReportEdit from './customizedReport/edit/edit';
import CustomizedReportReview from './customizedReport/detail/review';

const organizationRoutes = [
  ...(window.isProdEnv
    ? []
    : [
        {
          path: '/AuthorizInformation',
          menu: '许可管理',
          icon: 'iconquanxianpeizhi',
          breadcrumb: '许可管理',
          redirectPath: '/AuthorizInformation/licenseInformation',
          children: [
            {
              path: '/AuthorizInformation/licenseInformation',
              menu: '商务授权信息',
              auth: authDict.tenant_view,
              component: LicenseInformation,
              breadcrumb: '商务授权信息',
            },
          ],
        },
      ]),
  {
    path: '/organization',
    menu: '组织与权限',
    icon: 'iconzuzhijiagou',
    breadcrumb: '组织与权限',
    redirectPath: '/organization/userManagement',
    children: [
      {
        path: '/organization/userManagement',
        component: UserRoleManagement,
        auth: [authDict.user_view, authDict.department_view],
        menu: '部门与用户',
        logPage: true,
        breadcrumb: '部门与用户',
        children: [
          {
            path: '/organization/userManagement/createUser',
            component: UserCreateAndEdit,
            menu: '',
            auth: authDict.user_add,
            breadcrumb: '创建用户',
          },
          {
            path: '/organization/userManagement/editUser',
            component: UserCreateAndEdit,
            menu: '',
            auth: authDict.user_edit,
            breadcrumb: '编辑用户',
          },
          {
            path: '/organization/userManagement/copyUser',
            component: UserCreateAndEdit,
            menu: '',
            auth: authDict.user_add,
            breadcrumb: '复制用户',
          },
          {
            path: '/organization/userManagement/detail',
            component: UserDetail,
            menu: '',
            auth: authDict.user_detail,
            breadcrumb: '用户详情',
          },
          {
            path: '/organization/departmentManagement/detail',
            component: DepartmentDetail,
            menu: '',
            auth: authDict.department_detail,
            breadcrumb: '部门详情',
          },
        ],
      },
      {
        path: '/organization/roleManagement',
        component: RoleManagement,
        auth: authDict.role_view,
        menu: '角色',
        breadcrumb: '角色',
        children: [
          {
            path: '/organization/roleManagement/detail/:id',
            component: RoleDetailPage,
            auth: authDict.role_detail,
            breadcrumb: '角色详情',
          },
        ],
      },
    ],
  },
  {
    path: '/reportPermission',
    menu: '报表权限管理',
    icon: 'iconyijicaidan-fenxi',
    breadcrumb: '报表权限管理',
    redirectPath: '/reportPermission/customizedManagement',
    children: [
      {
        path: '/reportPermission/customizedManagement',
        auth: authDict.Cus_eReport_view,
        component: CustomizedReport,
        menu: '定制化报表',
        breadcrumb: '定制化报表',
      },
      {
        path: '/reportPermission/customizedManagement/:id/detail',
        auth: authDict.Cus_eReport_detail,
        component: CustomizedReportDetail,
        breadcrumb: '定制化报表详情',
      },
      {
        path: '/reportPermission/customizedManagement/:id/edit',
        auth: authDict.Cus_eReport_edit,
        component: CustomizedReportEdit,
        menu: '',
        breadcrumb: '定制化报表编辑',
      },
      {
        path: '/reportPermission/customizedManagement/:id/review',
        component: CustomizedReportReview,
        menu: '',
        breadcrumb: '定制化报表预览',
      },
    ],
  },
  {
    path: '/logManagement',
    menu: '系统日志',
    icon: 'iconcaozuorizhi',
    breadcrumb: '系统日志',
    redirectPath: '/logManagement/operationLogList',
    children: [
      {
        path: '/logManagement/operationLogList',
        auth: authDict.operationlog_view,
        component: OperationLogList,
        menu: '系统操作日志',
        breadcrumb: '系统操作日志',
        children: [
          {
            path: '/logManagement/operationLogList/:id/detail',
            auth: authDict.operationlog_detail,
            component: OperationLogDetailPage,
            menu: '',
            breadcrumb: '操作日志详情',
          },
        ],
      },
    ],
  },
];

export default organizationRoutes;
