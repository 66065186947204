import { FetchTriggerActionDetailResponse } from 'src/api/ytt/trigger-domain/triggerAction';
import { appEnum } from 'src/dict';
import { ActionValueTypeEnum } from 'src/dict/triggerAction';
import _ from 'lodash';
import { formatConditionGroups } from 'src/page/organization/triggerRule/utils/formatToFormData';
import { ReactNode } from 'react';

const { CustomField } = appEnum;

// type Option = {
//   label: string;
//   key: string | number;
//   value: string | number;
// };
// type detailItem = {
//   fieldId: string;
//   id?: number;
//   seq: number;
//   valueType: number;
//   value: string[];
// };

// interface TriggerActionFormData
//   extends Pick<
//     FetchTriggerActionCreateRequest,
//     'name' | 'id' | 'actionType' | 'code' | 'numberRuleId' | 'remark'
//   > {
//   objectCode: Option;
//   triggerRuleId: string;
//   details: detailItem[];
// }

type FetchTriggerActionDetailData = FetchTriggerActionDetailResponse['data'];

export const formatToOption = (
  label: string | undefined | ReactNode,
  value: string | number | object | undefined,
  // info?: any,
) => {
  return { label, value: typeof value === 'object' ? JSON.stringify(value) : value };
};

const formatToOptions = (
  labels: string[] | ReactNode[] | undefined,
  values: (string | number)[],
) => {
  if (_.isNil(labels) || _.isNil(values)) return undefined;
  return _.map(labels, (label, index) => formatToOption(label, values[index]));
};

const formatDetails = (detail: FetchTriggerActionDetailData['detail'], isCopy: boolean) => {
  const {
    id,
    choiceValues,
    fieldType,
    fieldId,
    fieldName,
    valueType,
    value,
    displayValues,
    reference,
    targetType,
    defaultField,
    required,
    ...rest
  } = detail;
  const _fieldName = required ? (
    <span>
      <span style={{ color: 'red' }}>* </span>
      {fieldName}
    </span>
  ) : (
    fieldName
  );
  const _fieldId = formatToOption(_fieldName, {
    id: Number(fieldId),
    fieldType,
    choiceValues,
    reference,
    targetType,
    defaultField,
    isRequired: required,
  });
  const obj: any = {
    ...rest,
    id: isCopy ? undefined : id,
    defaultField,
    fieldId: _fieldId,
    valueType,
  };

  if (_.isNil(value) || _.isNil(value?.[0])) obj.value = undefined;
  else if ([ActionValueTypeEnum.triggerRuleBiz, ActionValueTypeEnum.biz].includes(valueType)) {
    obj.value = formatToOptions(displayValues[0]?.split(','), value[0]?.split(',').map(Number));
  } else if (ActionValueTypeEnum.triggerRuleObj === valueType) {
    obj.value = formatToOptions(displayValues[0]?.split(','), value[0]?.split(','));
  } else if (valueType === ActionValueTypeEnum.formula) {
    obj.value = { formulaId: value[0], formulaText: displayValues[0] };
  } else {
    switch (fieldType) {
      case CustomField.FieldType.text:
      case CustomField.FieldType.textArea:
      case CustomField.FieldType.number:
      case CustomField.FieldType.integer:
      case CustomField.FieldType.boolean:
      case CustomField.FieldType.url:
        obj.value = value[0];
        break;
      case CustomField.FieldType.date:
        obj.value = Number(value[0]);
        break;
      case CustomField.FieldType.select:
      case CustomField.FieldType.multiSelect:
      case CustomField.FieldType.relation:
      case CustomField.FieldType.relationSub:
      case CustomField.FieldType.subordinate:
        obj.value = formatToOptions(displayValues, value);
        break;
    }
  }
  return obj;
};

type FormatDataToRequest = (data: FetchTriggerActionDetailData, isCopy?: boolean) => any;
export const formatToFormData: FormatDataToRequest = (data, isCopy = false) => {
  const {
    code,
    ruleId,
    ruleName,
    ruleBindObjectCode,
    objectName,
    objectCode,
    details,
    instanceFilterCondition: triggerCondition,
    objectFieldPath,
    objectFieldPathName,
    ...restData
  } = data || {};
  const triggerRuleId = formatToOption(ruleName, {
    value: ruleId,
    key: ruleId,
    label: ruleName,
    ruleBindObjectCode,
  });
  const _objectFieldPath = _.isNil(objectFieldPath)
    ? []
    : formatToOptions(objectFieldPathName?.split('.'), objectFieldPath.split('.'));

  return {
    ...restData,
    code: isCopy ? undefined : code,
    ruleId: isCopy ? undefined : ruleId,
    triggerRuleId,
    objectCode: formatToOption(objectName, { objectCode, objectName }),
    objectFieldPath: _objectFieldPath,
    details: details?.map((item: any) => formatDetails(item, isCopy)),
    triggerCondition: triggerCondition && {
      conditionGroups: triggerCondition?.conditionGroups?.map((item: any) =>
        formatConditionGroups(item, isCopy),
      ),
      type: _.isNil(triggerCondition?.type) ? undefined : triggerCondition?.type,
    },
  };
};
