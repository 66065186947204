import React, { useEffect, useState } from 'react';
import { BlTable } from '@blacklake-web/component';
import { Modal } from 'antd';
import { TemplateDataSetInfoList } from '../../interface';
import ConditionRender from '../../filter/detailContainer';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import { fetchEReportTemplateDataPreview } from 'src/api/ytt/e-report-domain/customReport';
import { Tooltip } from 'src/components';
import styles from '../../filter/styles.module.scss';
import { formatDetailCondition } from '../../utils';

const TemplateDataSetDetail = ({
  visible,
  initData,
  onClose,
}: {
  visible: boolean;
  initData: TemplateDataSetInfoList;
  onClose?: () => void;
}) => {
  const { temDataSetPreviewFieldInfoList } = initData;
  const [previewData, setPreviewData] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({
    page: 1,
    size: 10,
  });

  useEffect(() => {
    fetchEReportTemplateDataPreview({ ...initData }).then((res) => {
      setPreviewData(res.data?.previewData);
    });
  }, []);

  const getColumns = () => {
    return (
      temDataSetPreviewFieldInfoList?.map(({ fieldDisplayName }, index) => ({
        title: <Tooltip text={fieldDisplayName} width={80} />,
        dataIndex: index,
        width: 120,
      })) || []
    );
  };

  const info: DetailLayoutInfoBlock = {
    items: [
      {
        label: '模版数据集来源',
        dataIndex: 'dataSetName',
        isFullLine: true,
      },
      {
        label: '模版数据集过滤条件',
        dataIndex: 'filterCondition',
        isFullLine: true,
        render: (filterCondition: any) => {
          if (!filterCondition) return '无';
          return (
            <ConditionRender
              previewFieldData={formatDetailCondition(initData?.filterCondition)?.[0]}
            />
          );
        },
      },
      {
        label: '模版数据集预览',
        dataIndex: 'temDataSetPreviewFieldInfoList',
        isFullLine: true,
        render: () => {
          return (
            <BlTable
              style={{ maxWidth: 1000, minWidth: 500 }}
              pagination={{
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: () => `共 ${previewData?.length} 条`,
                ...pagination,
              }}
              scroll={{ x: 700, y: 400 }}
              columns={getColumns()}
              dataSource={previewData}
              onChange={(pagination: any) => {
                const { current, pageSize } = pagination;

                setPagination({
                  page: current,
                  size: pageSize,
                });
              }}
            />
          );
        },
      },
    ],
  };

  return (
    <Modal
      visible={visible}
      width={1200}
      onCancel={() => onClose?.()}
      onOk={() => onClose?.()}
      className={styles?.templateModal}
    >
      <DetailLayout info={[info]} dataSource={initData} title={'模版数据集详情'} />
    </Modal>
  );
};

export default TemplateDataSetDetail;
