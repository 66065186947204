/**
 * @description 触发方式选择 「定时触发」 的组件渲染
 */

import { Select, Form, Radio, Checkbox, Space, Button, DatePicker } from 'antd';
import _ from 'lodash';
import lookup, { appDict, appEnum } from 'src/dict';
import { validatorAfterNow } from 'src/utils/formValidators';

import { BlIcon } from '@blacklake-web/component';
import { CustomizeItem } from './BizEventTrigger';
import MyDatePicker from '../share/FormItemComponents/MyDatePicker';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';

const { triggerWeekDay, triggerYearMonth, execType } = appDict.triggerRule;
const { ExecType } = appEnum.TriggerRule;

/** 获取form路径 */
const parentFieldName = 'timeIntervalTrigger';

/** 获取form路径 */
const getNamePath = (name: string) => [parentFieldName, name];

/** 每日的几点触发 */
const TriggerEveryDayTime = () => {
  return (
    <Form.Item
      label={lookup('triggerRule.execType', ExecType.everyDay)}
      required
      preserve={false}
      style={{ marginBottom: 0 }}
    >
      <Form.List name={getNamePath('triggerEveryDayTime')}>
        {(fields, { add, remove }) => {
          const isMaxLength = (value: number) => value === 24;

          return (
            <>
              {fields.map((field) => (
                <div key={field.key}>
                  <Space size="small">
                    <Form.Item {...field} rules={[{ message: '请选择每日时间', required: true }]}>
                      <MyDatePicker picker="time" format="HH:mm:ss" allowClear={false} />
                    </Form.Item>
                    <Button
                      style={{
                        marginBottom: '24px',
                        marginLeft: '-6px',
                        color: fields.length === 1 ? '' : 'red',
                      }}
                      type="link"
                      icon={<BlIcon type="iconlieshanchu" />}
                      onClick={() => remove(field.name)}
                      disabled={fields.length === 1}
                    />
                  </Space>
                </div>
              ))}
              <Button
                style={{ padding: 0, height: '22px' }}
                type="link"
                icon={<BlIcon type="icontianjia" />}
                disabled={isMaxLength(fields.length)}
                onClick={() => add()}
              >
                添加
              </Button>
            </>
          );
        }}
      </Form.List>
    </Form.Item>
  );
};

/** 每周的周几触发 */
const TriggerWeekDay = () => (
  <Form.Item
    name={getNamePath('triggerWeekDay')}
    preserve={false}
    label={lookup('triggerRule.execType', ExecType.everWeek)}
    rules={[{ required: true, message: '请至少勾选一个' }]}
  >
    <Checkbox.Group options={triggerWeekDay} />
  </Form.Item>
);

/** 每月的几号触发 */
const TriggerMonthDay = () => {
  /** 每个月日期从1到31的枚举 */
  const triggerMonthDayOptions = Array.from({ length: 31 }, (_, index) => String(index + 1)).map(
    (item) => ({
      label: item,
      value: item.padStart(2, '0'),
    }),
  );
  const tagRender = (props: any) => <span>{props.label},</span>;

  return (
    <Form.Item
      name={getNamePath('triggerMonthDay')}
      preserve={false}
      label="每月几号"
      rules={[{ required: true, message: '请至少选择一个日期' }]}
    >
      <Select
        placeholder="请选择每月几号触发"
        options={triggerMonthDayOptions}
        mode="multiple"
        tagRender={tagRender}
        showSearch
        style={{ width: '360px' }}
      />
    </Form.Item>
  );
};

/** 每年的几月触发 */
const TriggerYearMonth = () => {
  const triggerYearMonthOptions = triggerYearMonth.map(({ label, value }) => ({
    label,
    value: String(value).padStart(2, '0'),
  }));

  return (
    <Form.Item
      name={getNamePath('triggerYearMonth')}
      preserve={false}
      label={lookup('triggerRule.execType', ExecType.everyMonth)}
      rules={[{ required: true, message: '请至少勾选一个每年的月份' }]}
    >
      <Checkbox.Group options={triggerYearMonthOptions} />
    </Form.Item>
  );
};

/** 定时执行时，执行频率的选择 */
const TriggerExecType = () => (
  <Form.Item name={getNamePath('execType')} label="" required initialValue={ExecType.everyDay}>
    <Radio.Group options={execType} />
  </Form.Item>
);

/** 定时执行时，执行频率的选择 */
const TriggerDays = () => {
  const AddDays = (props: any) => {
    const { value, onChange, ...restProps } = props;
    let _value = value;
    const maxLength = 99;
    const vUnixs = _.map(_.split(value, ','), (item) => moment(item, true).startOf('day').unix());

    return (
      <>
        <TextArea
          placeholder="请通过下方按钮添加日期"
          value={value}
          {...restProps}
          onChange={(e) => {
            const valueList = _.split(value, ',');

            if (valueList.length === 0) return;
            const preTail = _.last(valueList);
            const curTail = _.last(_.split(e.target.value, ','));

            if (!preTail || !curTail) onChange('');
            else if (preTail.length > curTail.length) {
              onChange(_.initial(valueList).join(','));
            }
          }}
        />
        <DatePicker
          bordered={false}
          placeholder=""
          style={{ width: '100px' }}
          allowClear={false}
          inputReadOnly
          disabled={_.split(value, ',').length > maxLength}
          disabledDate={(current) => {
            const cUnix = current.startOf('day').unix();
            const isSelected = () => vUnixs.includes(cUnix);

            return current && (current < moment().startOf('day') || isSelected());
          }}
          onChange={($, dataString) => {
            if (_.isNil(value) || value === '') _value = dataString;
            else _value = value + ',' + dataString;
            onChange(_value);
          }}
          suffixIcon={
            <Button
              type="link"
              // icon={<BlIcon type="icontianjia" />}
              style={{ marginLeft: '-30px' }}
              disabled={_.split(value, ',').length > maxLength}
            >
              * 添加日期
            </Button>
          }
        />
      </>
    );
  };

  return (
    <Form.Item name={getNamePath('triggerDays')} preserve={false} label="日期" required>
      <AddDays />
    </Form.Item>
  );
};

/** 定时执行时, 执行频率的配置项组件 */
const TimeIntervalTrigger = ({ FormItemProps }: any) => {
  /** 执行频率的选择不同,渲染不同的组件 */
  const getExecTypeItems = (execTypeValue: number) => {
    switch (execTypeValue) {
      case ExecType.everyDay:
        return <TriggerEveryDayTime />;
      case ExecType.everWeek:
        return (
          <>
            <TriggerWeekDay />
            <TriggerEveryDayTime />
          </>
        );
      case ExecType.everyMonth:
        return (
          <>
            <TriggerMonthDay />
            <TriggerEveryDayTime />
          </>
        );
      case ExecType.everyYear:
        return (
          <>
            <TriggerYearMonth />
            <TriggerMonthDay />
            <TriggerEveryDayTime />
          </>
        );
      case ExecType.fixedCycle:
        return (
          <>
            <Form.Item
              label="首次触发时间"
              name={getNamePath('startTime')}
              preserve={false}
              rules={[
                { required: true, message: '请选择首次触发时间' },
                { validator: validatorAfterNow() },
              ]}
            >
              <MyDatePicker
                showTime
                bannedBeforeNow
                key="triggerEveryDayTime"
                format="YYYY-MM-DD HH:mm:ss"
              />
            </Form.Item>
            <CustomizeItem parentPath={parentFieldName} />
          </>
        );
      case ExecType.customize:
        return (
          <>
            <TriggerDays />
            <TriggerEveryDayTime />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Form.Item label="执行频率" required {...FormItemProps}>
      <TriggerExecType />
      <Form.Item noStyle shouldUpdate>
        {(form) => getExecTypeItems(form.getFieldValue(getNamePath('execType')))}
      </Form.Item>
    </Form.Item>
  );
};

export default TimeIntervalTrigger;
