import { Radio, FormInstance, Input } from 'antd';
import { validatorCheckTwoSidesTrim } from '../verificationRules/index';
import { appDict, appEnum } from 'src/dict';
import { RelationDeleteOption, ViewRuleEnum } from 'src/dict/customField';
import { subDeleteRule, viewRule } from 'src/dict/customField/mappings';
import { FieldBaseProps } from 'src/page/custom_fields/fieldsList/index.d';

interface Props extends FieldBaseProps {
  objectName?: string;
}

export const subordinateItems = ({
  form,
  isEdit,
  objectName,
  isPreField,
  canSetIsRequired,
}: Props) => {
  return [
    {
      label: '相关列表编号',
      name: 'referCode',
      rules: [
        { required: true, message: '请输入相关列表编号' },
        { max: 255, message: '不超过255字符' },
        { validator: validatorCheckTwoSidesTrim() },
      ],
      render: () => <Input disabled={isEdit} />,
    },
    {
      label: '相关列表名称',
      name: 'referName',
      tooltip: '请注意，字段长度超过16个字符后前台会省略，App端不显示，Web端鼠标悬浮显示全称。',
      initialValue: objectName,
      rules: [
        { required: true, message: '请输入相关列表名称' },
        { max: 255, message: '不超过255字符' },
        { validator: validatorCheckTwoSidesTrim() },
      ],
      render: () => <Input />,
    },
    {
      label: '级联删除',
      name: 'deleteType',
      initialValue: RelationDeleteOption.allowed,
      render: () => <Radio.Group options={subDeleteRule} disabled />,
    },
    {
      label: '访问控制规则',
      name: 'viewRule',
      initialValue: ViewRuleEnum.byParent,
      render: () => <Radio.Group options={viewRule} disabled />,
    },
    {
      label: '从对象必填',
      name: 'childNecessary',
      initialValue: appEnum.Common.YN.no,
      rules: [{ required: true, message: '请选择' }],
      render: () => (
        <Radio.Group
          disabled={
            !canSetIsRequired ||
            (isEdit && isPreField && form.getFieldValue('isRequired') === appEnum.Common.YN.yes)
          }
          options={appDict.common.yn}
        />
      ),
    },
  ];
};
