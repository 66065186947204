/**
 * 自定义
 */
import { fetchStandardBizObjectMenuGetMenuHierarchy } from 'src/api/ytt/metadata-domain/customMenu';
import { fetchStandardBizObjectCustomObjectSimpleListWithoutPage } from 'src/api/ytt/metadata-domain/objectPlatform';
import CustomObjectList from './list';
import EditCustomObject from './edit';
import CustomObjectDetail from './detail';
import authDict, { hasAuth } from 'src/utils/auth';
import type { RouteProp } from 'src/route';
import type { CustomObjectData } from 'src/page/custom_fields/type';
import _, { isEmpty } from 'lodash';
import Import from 'src/page/import/import';
import { ExportLog, ImportLog } from 'src/components/excelBatchOption';

const FIRST_LEVEL_PATH = '/customObject';

export const customObjectRouteConfig: RouteProp[] = [
  {
    path: FIRST_LEVEL_PATH,
    menu: '自定义',
    icon: 'iconyijicaidan-zidingyi',
    breadcrumb: '自定义',
    initCollapsed: true,
    children: [],
    loadChildren: () =>
      Promise.all([
        fetchStandardBizObjectMenuGetMenuHierarchy(),
        fetchStandardBizObjectCustomObjectSimpleListWithoutPage(),
      ]).then((resList) => {
        const menuData = (resList[0].data ?? []).map((m) => ({
          ...m,
          allocatedObjectIdList: (m.allocatedObjectList ?? []).map((o) => o.id!),
        }));
        const objectList = resList[1].data ?? [];
        const all = objectList.map((o) => o.id!);

        // 无任何自定义对象的权限
        if (isEmpty(objectList)) {
          return [];
        }
        if (isEmpty(menuData)) {
          menuData.push({
            menuCode: '__customObject',
            menuName: '自定义对象',
            allocatedObjectIdList: all,
          });
        } else {
          const assigned = menuData.flatMap((m) => m.allocatedObjectIdList);
          const unassigned = _.difference(all, assigned);

          if (!isEmpty(unassigned)) {
            menuData.push({
              menuCode: '__other',
              menuName: '其他对象',
              allocatedObjectIdList: unassigned,
            });
          }
        }

        return menuData.map(({ menuCode, menuName, allocatedObjectIdList }) => {
          const submenuPath = `${FIRST_LEVEL_PATH}/${menuCode}`;

          return {
            path: submenuPath,
            menu: `${menuName}`,
            breadcrumb: `${menuName}`,
            children: _.compact(
              allocatedObjectIdList!.map((id) => {
                const item = _.find(objectList, { id });

                if (!item) {
                  console.error(`对象ID ${id} 未找到对应的对象实体`);
                  return null;
                }
                const { objectName, objectCode } = item;
                // 注：objectCode 不能通过路径参数获取, 因为那样做的话面包屑将无法区分不同的自定义对象的路径
                const shortName = _.truncate(objectName, { length: 9 });
                const editComp = () => <EditCustomObject {...{ objectCode: objectCode! }} />;
                const listPath = `${submenuPath}/${objectCode}`;

                return {
                  breadcrumb: shortName,
                  path: listPath,
                  menu: objectName,
                  component: () => <CustomObjectList {...(item as CustomObjectData)} />,
                  children: [
                    {
                      path: `${listPath}/:instanceId/detail`,
                      component: () => (
                        <CustomObjectDetail
                          {...{ objectCode: objectCode!, objectName: objectName! }}
                        />
                      ),
                      breadcrumb: `${shortName}详情`,
                    },
                    {
                      path: `${listPath}/create`,
                      component: editComp,
                      breadcrumb: `新建${shortName}`,
                    },
                    {
                      path: `${listPath}/:instanceId/edit`,
                      component: editComp,
                      breadcrumb: `编辑${shortName}`,
                    },
                    {
                      path: `${listPath}/:instanceId/copy`,
                      component: editComp,
                      breadcrumb: `复制${shortName}`,
                    },
                    {
                      path: `${listPath}/import`,
                      component: Import,
                      breadcrumb: '导入',
                    },
                    {
                      path: `${listPath}/importLog`,
                      component: ImportLog,
                      breadcrumb: '导入日志',
                    },
                    {
                      path: `${listPath}/exportLog`,
                      component: ExportLog,
                      breadcrumb: '导出日志',
                    },
                  ],
                };
              }),
            ),
          };
        });
      }),
  },
];
