/**
 * @description 多级条件配置表单组件
 */

import { useState } from 'react';
import { Form } from 'antd';

import { ConditionEditTableLayoutProps } from './constants';
import ConditionEditTable from './ConditionEditTable/ConditionEditTable';
import ConditionListTitle from './ConditionListTitle';
import ConditionConnectType from './ConditionConnectType';

import styles from './styles.module.scss';

/** 多级条件配置表单组件 */
const ConditionEditTableLayout: React.FC<ConditionEditTableLayoutProps> = (props) => {
  const {
    form,
    namePath = [],
    level = 1,
    refRomove,
    info,
    totalLevel = 1,
    parentPath,
    ...restProps
  } = props;
  const { columns } = info;
  /** 一组条件的连接关系值的 【路径】 */
  const typeNamePath = [...namePath.slice(0, namePath?.length - 1), 'type'];
  // 更新组件标记
  const [, setRefreshMarker] = useState(+Date.now());

  /** 条件组的渲染函数 */
  const renderRight = () => {
    return (
      <>
        <ConditionEditTable
          namePath={namePath}
          parentPath={parentPath ?? namePath}
          form={form}
          refRomove={refRomove}
          onRefresh={() => setRefreshMarker(+Date.now())}
          level={level}
          totalLevel={totalLevel}
          info={{
            ...info,
            columns: columns.filter(({ hide }) => !hide),
          }}
          {...restProps}
        />
      </>
    );
  };

  // const scrollToBottom = () => {
  //   const wrapper = document.getElementById('containerBox');

  //   if (wrapper) {
  //     wrapper.scrollTop = wrapper.scrollHeight;
  //   }
  // };

  return (
    <Form.Item name={namePath}>
      <ConditionListTitle info={info} isShow={!refRomove} disabled={restProps.disabled} />
      <div className={styles?.filterContent} id="filterContent">
        <ConditionConnectType name={typeNamePath} disabled={restProps.disabled} />
        <div className={styles?.filterRightContent} id="filterRightContent">
          <div className="nextFloorCondition">{renderRight()}</div>
        </div>
      </div>
    </Form.Item>
  );
};

export default ConditionEditTableLayout;
