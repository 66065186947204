import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Checkbox, message, Tabs, Modal } from 'antd';
import { storageValidityUnit, STORAGE_DAY } from '../constant';
import * as businessFieldForm from './components';
import { BizRangeOptionsList } from '..';
import { DataFormLayout } from 'src/layout';
import {
  fetchMaterialInventoryCreate,
  fetchMaterialInventoryDetail,
} from 'src/api/ytt/material-domain/Web_MaterialInventory';
import {
  fetchMaterialCheckInventory,
  fetchMaterialCreate,
  fetchMaterialCreateUnit,
  fetchMaterialUpdate,
  fetchMaterialDetail,
  FetchMaterialCreateRequest,
} from 'src/api/ytt/material-domain/Web_MaterialBaseInfo';
import {
  handleBizRangeChangeTabDefaults,
  handleBizTypeChangeDefaults,
  handleFormatAttrs,
  handleFormatBackAttrs,
  handleFormatBackBatchAttrs,
  handleFormatBackDocuments,
  handleFormatBackLabelValue,
  handleFormatBackTransformUnits,
  handleFormatDocuments,
  handleFormatLabelValue,
  handleFormatTransformUnits,
} from '../utils';
import { BlIcon, NumberRulesStandardHook } from 'src/components';
import {
  BATCHATTRS_DEFAULT,
  INVENTORYATTRS_DEFAULT,
  BizType,
  FeedingQualityStatus,
  FirstInFirstOutRules,
  TabType,
} from 'src/dict/material';
import { bizRange as bizRangeMapping, BizRange_DEFAULT } from 'src/dict/material/mappings';
import { CRUD, ObjectCode, YN } from 'src/dict/common';
import {
  fetchMaterialPurchaseDetail,
  fetchMaterialPurchaseSave,
} from 'src/api/ytt/material-domain/Web_MaterialPurchase';
import {
  fetchMaterialProductionDetail,
  fetchMaterialProductionSave,
} from 'src/api/ytt/material-domain/Web_MaterialProduction';
import { LabeledValue } from 'antd/lib/select';
import { checkTwoSidesTrim, textValidator2 } from 'src/utils/formValidators';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { MaterialCreateUnitForm } from './index.d';

interface Props extends RouteComponentProps {
  refreshTable?: () => void;
  refreshDetails?: () => void;
  onCancel?: () => void;
  id?: number | undefined; // id存在则说明是编辑操作
}
export interface AllTabParams {
  bizRang?: number[];
  basicUnit?: LabeledValue | undefined;
}

const InfoForm = (props: Props) => {
  const { onCancel, refreshTable, refreshDetails, id, history } = props;
  const type = _.isUndefined(id) ? CRUD.create : CRUD.edit;
  const [form] = Form.useForm();
  const [keepOn, setKeepOn] = useState(type === CRUD.create);
  const [materialId, setMaterialId] = useState(id); // 新建之后存储的id
  const [activeKey, setActiveKey] = useState<string>(String(TabType.basic));
  // 由于子组件无法管理状态，该状态放在这个父组件管理
  const [addTransformUnitsVisible, setAddTransformUnitsVisible] = useState<
    LabeledValue | undefined
  >(undefined);
  const [bizRangeOptionsList, setBizRangeOptionsList] =
    useState<BizRangeOptionsList[]>(bizRangeMapping);
  const [allTabParams, setAllTabParams] = useState<AllTabParams>();
  const [updateMark, setUpdateMark] = useState(0);
  // 由于子组件无法管理状态，该状态放在这个父组件管理 ;
  const prevKeyRef = useRef<string>();

  // 自定义 HOOK 需放在最顶层
  const renderMaterialCode = NumberRulesStandardHook({
    label: '物料编号',
    form,
    edit: type === CRUD.edit,
    objectCode: ObjectCode.material,
    fieldCode: 'code',
    rules: [
      {
        max: 255,
        message: '不可超过255个字符',
      },
      { validator: textValidator2 },
      { validator: checkTwoSidesTrim },
    ],
  });
  // 自定义 HOOK 需放在最顶层 END
  const initCreate = (lastValues?: any) => {
    /**
     * 连续创建时，会用到 lastValues 的值
     */
    switch (Number(activeKey)) {
      case TabType.basic: {
        // 物料 基本信息
        form.setFields([
          { name: 'code', value: '' },
          { name: 'numberRuleId', value: lastValues?.numberRuleId },
          { name: 'bizType', value: BizType.default },
          {
            name: 'bizRange',
            value: BizRange_DEFAULT,
          },
          { name: 'batchManagementEnable', value: YN.no },
          {
            name: 'batchAttrs',
            value: BATCHATTRS_DEFAULT,
          },
        ]);

        break;
      }

      case TabType.warehousing: {
        // 物料 仓储信息
        form.setFields([
          {
            name: 'unit',
            value: { value: STORAGE_DAY, label: storageValidityUnit.get(STORAGE_DAY) },
          }, // 默认为 日
          {
            name: 'fifoEnabled',
            value: YN.no,
          }, // 先进先出，默认不启用
          {
            name: 'fifoAttr',
            value: FirstInFirstOutRules.batchNumber,
          }, // 先进先出，默认不启用
          {
            name: 'inventoryAttrs',
            value: INVENTORYATTRS_DEFAULT,
          },
        ]);
        break;
      }

      case TabType.purchase:
        // 物料 采购信息
        form.setFields([
          {
            name: 'sourceControl',
            value: YN.no,
          },
        ]);
        break;

      case TabType.production:
        // 物料 采购信息
        form.setFields([
          {
            name: 'manufactureQualityStatus',
            value: [FeedingQualityStatus.qualified, FeedingQualityStatus.concession],
          },
        ]);
        break;

      default:
        break;
    }
  };
  const initEdit = async (id: number) => {
    try {
      if (!id) return;

      switch (Number(activeKey)) {
        case TabType.basic: {
          // 物料 基本信息
          const { data } = await fetchMaterialDetail({ id }, { legacy: true });

          const hasStorage: any = await fetchMaterialCheckInventory({ id });
          // 根据 bizType 设置 bizRange disabled 初始值
          const handleBizTypeOnChange = (selectedValues: any) => {
            const { optionsList } = handleBizTypeChangeDefaults(selectedValues);

            setBizRangeOptionsList(optionsList);
          };

          handleBizTypeOnChange(data?.bizType);
          form.setFields([
            { name: 'bizRange', value: _.orderBy(data?.bizRange) },
            { name: 'bizType', value: data?.bizType },
            { name: 'code', value: data?.code },
            { name: 'name', value: data?.name },
            { name: 'qrCode', value: data?.qrCode },
            { name: 'remark', value: data?.remark },
            {
              name: 'unit',
              value: {
                value: data?.unit?.id,
                label: data?.unit?.name,
                relationId: data?.unit?.relationId,
              },
            },
            { name: 'unitRelationId', value: data?.unit?.relationId },
            { name: 'specification', value: data?.specification },
            { name: 'category', value: data?.category?.categoryIds },
            { name: 'categoryRelationId', value: data?.category?.relationId },
            // { name: 'documents', value: handleFormatBackDocuments(data?.documents) },
            { name: 'documents', value: data?.documents },
            { name: 'attributes', value: handleFormatBackAttrs(data?.attributes) },
            {
              name: 'transformUnit',
              value: handleFormatBackTransformUnits(
                data?.conversionUnits,
                data?.unit,
                data?.auxUnitList,
                hasStorage?.data?.flag,
              ),
            },
            { name: 'batchManagementEnable', value: data?.batchManagementEnable },
            { name: 'batchAttrs', value: handleFormatBackBatchAttrs(data?.batchAttrs as any) },
            { name: 'batchNoRule', value: handleFormatBackLabelValue(data?.batchNoRule) },
          ]);
          setUpdateMark(+new Date());
          break;
        }
        case TabType.warehousing: {
          // 物料 仓储信息
          const { data } = await fetchMaterialInventoryDetail({ materialId: id }, { legacy: true });

          form.setFields([
            { name: 'relationId', value: data?.relationId },
            { name: 'convertUnit', value: handleFormatBackLabelValue(data?.convertUnit) },
            { name: 'convertUnitRelationId', value: data?.convertUnit?.relationId },
            { name: 'warehouse', value: handleFormatBackLabelValue(data?.warehouse) },
            { name: 'location', value: handleFormatBackLabelValue(data?.location) },
            {
              name: 'unit',
              value: {
                value: data?.validity?.unit,
                label: storageValidityUnit.get(data?.validity?.unit as number),
              },
            },
            { name: 'time', value: data?.validity?.time },
            { name: 'fifoEnabled', value: data?.fifoEnabled },
            { name: 'fifoAttr', value: data?.fifoAttr },
            {
              name: 'inventoryAttrs',
              value: handleFormatBackBatchAttrs(data?.inventoryAttrs as any),
            },
            {
              name: 'inventoryQrCodeRule',
              value: handleFormatBackLabelValue(data?.inventoryQrCodeRule),
            },
          ]);
          // 数据准备好了之后，需要重新触发一下render，warehousingStorageInfo表单内的disable效果才能触发
          setUpdateMark(+new Date());
          break;
        }
        case TabType.purchase: {
          // 物料 采购信息
          const { data } = await fetchMaterialPurchaseDetail({ materialId: id }, { legacy: true });

          form.setFields([
            { name: 'purchaseAmount', value: data?.purchaseAmount },
            { name: 'purchaseAmountMin', value: data?.purchaseAmountMin },
            { name: 'purchaseUnit', value: handleFormatBackLabelValue(data?.purchaseUnit) }, // TODO
            { name: 'sourceControl', value: data?.sourceControl },
          ]);
          break;
        }
        case TabType.production: {
          // 物料 生产信息
          const { data } = await fetchMaterialProductionDetail(
            { materialId: id },
            { legacy: true },
          );

          form.setFields([
            { name: 'manufactureQualityStatus', value: data?.manufactureQualityStatus },
            { name: 'productionUnit', value: handleFormatBackLabelValue(data?.productionUnit) }, // TODO
          ]);
          break;
        }

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };
  /**
   * 选项卡内容填充
   */
  const renderTabChangeContent = (key: string) => {
    if (key !== prevKeyRef.current) {
      // 获取上一次的 activeKey 如果未变化导致的页面render：可能是做了添加单位、勾选了业务范围等，此时不应init初始值
      // activeKey 变化了，则说明在进行 tab 切换操作
      if (type === CRUD.create) {
        if (materialId) {
          initEdit(materialId as number);
        } else {
          initCreate();
        }
      } else {
        initEdit(id as number);
      }
    }
    // const { data } = await fetchMaterialInventoryDetail({ materialId: id as number });
    switch (Number(key)) {
      // 基本信息
      case TabType.basic:
        return [
          {
            items: businessFieldForm.baseInfo({
              form,
              materialId: id,
              type,
              bizRangeOptionsList,
              setBizRangeOptionsList,
              addTransformUnitsVisible,
              setAddTransformUnitsVisible,
              setUpdateMark,
              renderMaterialCode,
            }),
            column: 2,
          },
        ];

      // 仓储信息
      case TabType.warehousing:
        return [
          {
            title: '基本设置',
            items: businessFieldForm.warehousingBasicInfo({
              form,
              materialId: materialId as number,
            }),
            column: 2,
          },
          {
            title: '存储设置',
            items: businessFieldForm.warehousingStorageInfo({
              form,
              updateMark,
              setUpdateMark,
            }),
            column: 2,
          },
          {
            title: '管理设置',
            items: businessFieldForm.warehousingManagementInfo({
              form,
              updateMark,
              setUpdateMark,
            }),
            column: 2,
          },
        ];
      case TabType.purchase:
        return [
          {
            items: businessFieldForm.purchaseBasicInfo({
              form,
              materialId: materialId as number,
            }),
            column: 2,
          },
        ];
      case TabType.production:
        return [
          {
            items: businessFieldForm.productionBasicInfo({
              form,
              materialId: materialId as number,
            }),
            column: 2,
          },
        ];

      default:
        return [
          {
            items: [],
          },
        ];
    }
  };

  useEffect(() => {
    prevKeyRef.current = activeKey;
  });

  const baseInfo: any = renderTabChangeContent(activeKey);
  const setBasicUnitDefaultFn = (clickKey: string, basicUnit: LabeledValue | undefined) => {
    switch (Number(clickKey)) {
      case TabType.purchase: {
        form.setFields([{ name: 'purchaseUnit', value: basicUnit }]);

        break;
      }
      case TabType.production: {
        form.setFields([
          { name: 'productionUnit', value: basicUnit }, // TODO
        ]);
        break;
      }
      default:
        break;
    }
  };
  /**
   * 切换选项卡，保存并切换事件
   */
  const handleOk = async (clickKey: string, allTabParams: AllTabParams | undefined) => {
    try {
      let getFormUnit = allTabParams?.basicUnit;

      if (Number(activeKey) === TabType.basic) {
        getFormUnit = form.getFieldValue('unit');
        // 存一下 basicUnit
        setAllTabParams({ ...allTabParams, basicUnit: getFormUnit });
      }
      // 保存当前tab页面数据
      await saveData(form.getFieldsValue());
      typeof refreshTable === 'function' && refreshTable();
      refreshDetails?.();
      // 切换tab
      setActiveKey(clickKey);
      // 设置其他tab页面的单位为主单位
      setBasicUnitDefaultFn(clickKey, getFormUnit);
    } catch (err) {
      console.log(err);
    }
  };
  /**
   * 切换选项
   */
  const renderTabContext = useCallback(() => {
    const getFormBizRange = form.getFieldValue('bizRange');
    const tabTypeMapping = handleBizRangeChangeTabDefaults(
      getFormBizRange || allTabParams?.bizRang,
    );

    return (
      <Tabs
        onTabClick={async (clickKey) => {
          const isFromBasicToOtherTab = Number(activeKey) === TabType.basic;

          // 切换tab 存一下bizRange
          isFromBasicToOtherTab && setAllTabParams({ ...allTabParams, bizRang: getFormBizRange });
          // 用户操作过表单
          if (form.isFieldsTouched()) {
            Modal.confirm({
              icon: <BlIcon type="iconmianxingtixing-jingshi" />,
              title: '当前页面编辑信息尚未保存，确认切换?',
              okText: '保存并切换',
              cancelText: '切换',
              onOk() {
                if (isFromBasicToOtherTab) {
                  handleOk(clickKey, { ...allTabParams, bizRang: getFormBizRange });
                  return;
                }
                handleOk(clickKey, allTabParams);
              },
              onCancel() {
                // 强制切换tab页
                setActiveKey(clickKey);
                setBizRangeOptionsList(bizRangeMapping);
              },
            });
          } else {
            // 用户未操作过表单，则直接切换tab页面
            form.resetFields();
            setActiveKey(clickKey);
          }
          // 设置其他tab页面的单位为主单位
          !keepOn && setBasicUnitDefaultFn(clickKey, allTabParams?.basicUnit);
        }}
        activeKey={activeKey}
      >
        {_.remove(
          [...tabTypeMapping],
          (n) =>
            n?.value === TabType.basic ||
            n?.value === TabType.production ||
            n?.value === TabType.purchase ||
            n?.value === TabType.warehousing,
        ).map((item) => (
          <Tabs.TabPane tab={item.label} key={String(item.value)} />
        ))}
      </Tabs>
    );
  }, [activeKey]);

  /**
   * 创建或编辑 物料单位
   * @param materialId 物料ID
   * @param formValue form value
   */
  const createAndEditTransFormUnit = async (materialId: number | undefined, formValue: any) => {
    const { transformUnit } = formValue;
    const mainUnit = _.head<MaterialCreateUnitForm>(transformUnit ?? [])?.toUnitId;

    if (activeKey === String(TabType.basic) && materialId && mainUnit?.value) {
      const params = {
        id: materialId as number,
        unitId: _.toNumber(mainUnit.value),
        conversionUnits: handleFormatTransformUnits(transformUnit),
      };

      await fetchMaterialCreateUnit(params);
    }
  };

  const handleFormatCreateData = (parmas: any) => {
    let data;

    switch (Number(activeKey)) {
      case TabType.basic: {
        // 物料 基本信息
        data = {
          name: parmas?.name,
          code: parmas?.code,
          numberRuleId: parmas?.numberRuleId,
          bizRange: parmas?.bizRange,
          bizType: parmas?.bizType,
          unit: {
            id: _.head<MaterialCreateUnitForm>(parmas?.transformUnit ?? [])?.toUnitId?.value,
            relationId: _.head<MaterialCreateUnitForm>(parmas?.transformUnit ?? [])?.relationId,
          },
          categoryIds: parmas?.category,
          attributes: handleFormatAttrs(parmas?.attributes),
          documents: handleFormatDocuments(parmas?.documents),
          batchManagementEnable: Number(parmas?.batchManagementEnable),
          batchAttrs: parmas?.batchAttrs,
          batchNoRule: handleFormatLabelValue(parmas?.batchNoRule),
          remark: parmas?.remark,
        };
        break;
      }

      case TabType.warehousing: {
        // 物料 仓储信息
        data = {
          materialId,
          convertUnit: handleFormatLabelValue(parmas?.convertUnit),
          warehouse: handleFormatLabelValue(parmas?.warehouse),
          location: handleFormatLabelValue(parmas?.location),
          validity: { time: parmas?.time, unit: parmas?.unit?.value },
          fifoEnabled: Number(parmas?.fifoEnabled),
          fifoAttr: parmas?.fifoAttr,
          inventoryAttrs: parmas?.inventoryAttrs,
          inventoryQrCodeRule: handleFormatLabelValue(parmas?.inventoryQrCodeRule),
        };
        break;
      }

      case TabType.purchase: {
        // 物料 采购信息
        data = {
          materialId,
          purchaseAmount: Number(parmas?.purchaseAmount),
          purchaseAmountMin: Number(parmas?.purchaseAmountMin),
          purchaseUnit: handleFormatLabelValue(parmas?.purchaseUnit),
          sourceControl: Number(parmas?.sourceControl),
        };
        break;
      }

      case TabType.production: {
        // 物料 生产信息
        data = {
          materialId,
          manufactureQualityStatus: parmas?.manufactureQualityStatus,
          productionUnit: handleFormatLabelValue(parmas?.productionUnit),
        };
        break;
      }

      default:
        break;
    }

    return data;
  };
  const createFn = async (parmas: any, formValue: any) => {
    try {
      switch (Number(activeKey)) {
        case TabType.basic: {
          // 物料 基本信息
          const {
            message: mes,
            code,
            data,
          } = await fetchMaterialCreate(parmas as FetchMaterialCreateRequest);

          if (code === 200) {
            message.success('物料创建成功');
            // 创建单位
            await createAndEditTransFormUnit(data?.id, formValue);
            setMaterialId(data?.id);
          } else {
            throw mes;
          }
          break;
        }

        case TabType.warehousing: {
          // 物料 仓储信息
          const { message: mes, code } = await fetchMaterialInventoryCreate(parmas);

          if (code === 200) {
            message.success('物料仓储信息创建成功');
          } else {
            throw mes;
          }
          break;
        }
        case TabType.purchase: {
          // 物料 采购信息
          const { message: mes, code } = await fetchMaterialPurchaseSave(parmas);

          if (code === 200) {
            message.success('物料采购信息创建成功');
          } else {
            throw mes;
          }
          break;
        }
        case TabType.production: {
          // 物料 生产信息
          const { message: mes, code } = await fetchMaterialProductionSave(parmas);

          if (code === 200) {
            message.success('物料生产信息创建成功');
          } else {
            throw mes;
          }
          break;
        }

        default:
          break;
      }
    } catch (err) {
      // 内层错误向外抛出,外层捕获后则不关闭弹窗
      console.log(err);
      throw err;
    }
  };

  const handleFormatEditData = (parmas: any) => {
    let data;

    switch (Number(activeKey)) {
      case TabType.basic: {
        // 物料 基本信息
        data = {
          id,
          name: parmas?.name,
          code: parmas?.code,
          qrCode: parmas?.qrCode,
          bizRange: parmas?.bizRange,
          unit: {
            id: _.head<MaterialCreateUnitForm>(parmas?.transformUnit ?? [])?.toUnitId?.value,
            relationId: _.head<MaterialCreateUnitForm>(parmas?.transformUnit ?? [])?.relationId,
          },
          category: {
            categoryIds: parmas?.category?.length > 0 ? parmas?.category : undefined,
            relationId: parmas?.category?.length > 0 ? parmas?.categoryRelationId : undefined,
          },
          attributes: handleFormatAttrs(parmas?.attributes),
          documents: handleFormatDocuments(parmas?.documents),
          batchManagementEnable: Number(parmas?.batchManagementEnable),
          batchAttrs: parmas?.batchAttrs,
          batchNoRule: handleFormatLabelValue(parmas?.batchNoRule),
          // customFields: parmas?.customFields,
          remark: parmas?.remark,
          reason: parmas?.reason,
        };
        break;
      }

      case TabType.warehousing: {
        // 物料 仓储信息
        data = {
          materialId,
          relationId: parmas?.relationId,
          convertUnit: handleFormatLabelValue({
            ...parmas?.convertUnit,
            relationId: parmas?.convertUnitRelationId,
          }),
          warehouse: handleFormatLabelValue(parmas?.warehouse),
          location: handleFormatLabelValue(parmas?.location),
          validity: { time: parmas?.time, unit: parmas?.unit?.value },
          fifoEnabled: Number(parmas?.fifoEnabled),
          fifoAttr: parmas?.fifoAttr,
          inventoryAttrs: parmas?.inventoryAttrs,
          inventoryQrCodeRule: handleFormatLabelValue(parmas?.inventoryQrCodeRule),
        };
        break;
      }
      case TabType.purchase: {
        // 物料 采购信息
        data = {
          materialId,
          purchaseAmount: Number(parmas?.purchaseAmount),
          purchaseAmountMin: Number(parmas?.purchaseAmountMin),
          purchaseUnit: handleFormatLabelValue(parmas?.purchaseUnit),
          sourceControl: Number(parmas?.sourceControl),
        };
        break;
      }

      case TabType.production: {
        // 物料 生产信息
        data = {
          materialId,
          manufactureQualityStatus: parmas?.manufactureQualityStatus,
          productionUnit: handleFormatLabelValue(parmas?.productionUnit),
        };
        break;
      }

      default:
        break;
    }

    console.log('格式化', data);

    return data;
  };
  const editFn = async (parmas: any, formValue: any) => {
    try {
      switch (Number(activeKey)) {
        case TabType.basic: {
          // 物料 基本信息
          await createAndEditTransFormUnit(id, formValue);
          const { message: mes, code } = await fetchMaterialUpdate(parmas);

          if (code === 200) {
            message.success('物料编辑成功');
          } else {
            console.log('mes', mes);
            throw mes;
          }
          break;
        }

        case TabType.warehousing: {
          // 物料 仓储信息
          const { message: mes, code } = await fetchMaterialInventoryCreate(parmas);

          if (code === 200) {
            message.success('物料仓储信息编辑成功');
          } else {
            throw mes;
          }
          break;
        }
        case TabType.purchase: {
          // 物料 采购信息
          const { message: mes, code } = await fetchMaterialPurchaseSave(parmas);

          if (code === 200) {
            message.success('物料采购信息编辑成功');
          } else {
            throw mes;
          }
          break;
        }
        case TabType.production: {
          // 物料 生产信息
          const { message: mes, code } = await fetchMaterialProductionSave(parmas);

          if (code === 200) {
            message.success('物料生产信息编辑成功');
          } else {
            throw mes;
          }
          break;
        }

        default:
          break;
      }
    } catch (err) {
      // 内层错误向外抛出,外层捕获后则不关闭弹窗
      console.log(err);
      throw err;
    }
  };

  async function saveData(value: any) {
    try {
      switch (type) {
        case CRUD.create: {
          await createFn(handleFormatCreateData(value), value);

          break;
        }

        case CRUD.edit: {
          await editFn(handleFormatEditData(value), value);

          break;
        }

        default:
          break;
      }
    } catch (err) {
      // 内层错误向外抛出,外层捕获后则不关闭弹窗
      console.log(err);
      throw err;
    }
  }
  const handleFinish = async () => {
    const value = await form?.validateFields();

    try {
      await saveData(value);
      // 创建编辑成功后，根据是否连续创建 => 关闭弹窗
      // if (keepOn) {
      //   typeof refreshTable === 'function' && refreshTable();
      //   const values = form.getFieldsValue();

      //   form.resetFields();
      //   initCreate(values);
      //   setMaterialId(undefined);
      //   setUpdateMark(new Date().getTime());
      //   return;
      // }
      // if (typeof onCancel === 'function') {
      //   onCancel();
      //   typeof refreshTable === 'function' && refreshTable();
      //   return;
      // }
      // history.goBack();
      // refreshDetails?.();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DataFormLayout
      form={form}
      info={baseInfo}
      title={type === CRUD.create ? '新建物料' : '编辑物料'}
      topContext={renderTabContext()}
      extra={
        type === CRUD.create && (
          <Checkbox
            onChange={() => {
              setKeepOn(!keepOn);
            }}
            defaultChecked={keepOn}
          >
            连续新建
          </Checkbox>
        )
      }
      onCancel={() => {
        if (typeof onCancel === 'function') {
          onCancel();
          return;
        }
        history.goBack();
      }}
      onFinish={handleFinish}
    />
  );
};

export default InfoForm;
