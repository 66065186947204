import { useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Space, Dropdown, Popconfirm, Form, Menu, message } from 'antd';
import { RecordListLayout } from 'layout';
import { BlIcon } from '@blacklake-web/component';
import { LinkTooltip, showErrorListMessage, SingleOperationLogComponent } from 'src/components';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import _ from 'lodash';
import moment from 'moment';
import { gcObject, gcTime } from 'utils';
import { Turn } from 'src/dict/common';
import { lookup } from 'src/dict';
import { fetchCustomAqlList, fetchCustomAqlDelete } from 'src/api/ytt/quality-domain/aql';
import { turnAQL } from './utils';
import { STORE_ENUM_TYPE, BasePath } from './constant';
import renderUsageStatus from 'src/page/share/renderUsageStatus';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';

interface TableRowType {
  id: number;
  state: number;
}

interface Props extends RouteComponentProps {}

const AqlManage = (props: Props) => {
  const { history } = props;
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const refreshCountRef = useRef(0);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [delForm] = Form.useForm();
  const [stateForm] = Form.useForm();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [detailId, setDetailId] = useState<number>(0);

  const refreshTable = () => {
    // 刷新列表页
    refreshCountRef.current++;
    setRefreshMarker(refreshCountRef.current);
  };

  const toAqlDetail = (id: number) => {
    // 详情页面
    history.push(`${BasePath}/detail/${id}`);
  };
  const toAqlCreate = () => {
    // 创建页面
    history.push(`${BasePath}/create`);
  };
  const toAqlEdit = (id: number) => {
    // 编辑页面
    history.push(`${BasePath}/edit/${id}`);
  };
  const toAqlCopy = (id: number) => {
    // 复制页面
    history.push(`${BasePath}/copy/${id}`);
  };
  const toAqlNational = () => {
    // 国标页面
    history.push(`${BasePath}/national`);
  };
  const bulkDelete = async (ids: number[]) => {
    const value = await delForm?.validateFields();
    // 批量删除
    const res = await fetchCustomAqlDelete({ ids, reason: value.delReason });

    return res;
  };
  const patchRequest = (
    patchApi: any,
    params: any,
    display: string,
    onSuccess?: any,
    onFail?: any,
  ) => {
    return patchApi(params)
      .then((res: any) => {
        const { code, message: mes } = res;

        if (code === 200) {
          onSuccess && onSuccess();
          const { failedCount = 0, successCount = 0, failedList = [] } = res?.data ?? {};

          if (failedCount) {
            showErrorListMessage({
              failCount: failedCount,
              successCount,
              title: `${display}出现失败`,
              data: _.map(failedList, (item) => ({ ...item, key: item.id })),
              columns: [
                { title: '名称', dataIndex: 'name', width: 200 },
                { title: '失败原因', dataIndex: 'reason', width: 200 },
                { title: '', dataIndex: 'key', width: 1, render: () => null },
              ],
            });
          } else {
            message.success(`${display}成功！`);
          }
          return;
        }
        message.error(mes);
        return undefined;
      })
      .catch((err: any) => {
        console.log('err: ', err);
        onFail && onFail();
      });
  };

  const dataColumns = [
    {
      title: '方案名称',
      dataIndex: 'name',
      type: 'text',
      width: 200,
      isFilter: true,
      inputProps: {
        placeholder: '请输入关键字',
      },
      render: (code: string, record: TableRowType, index: number, config: any) => (
        <LinkTooltip
          to={`${BasePath}/detail/${record.id}`}
          text={code}
          width={config.contentWidth}
          auth={authDict.customizedaql_detail}
        />
      ),
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      isFilter: true,
      inputProps: {
        placeholder: '请输入关键字',
      },
      width: 150,
      render: (value: any) => {
        if (!value) return '-';
        return (
          <AvatarDisplay
            id={value.id}
            isShowTag={false}
            name={value.name}
            avatarUrl={value.avatarUrl}
            isUser
          />
        );
      },
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择创建人" isMultiple isSelectUser />
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      type: 8,
      isFilter: true,
      sorter: true,
      width: 180,
      render: (text: number) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 180,
      type: 8,
      isFilter: true,
      sorter: true,
      render: (text: number) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '状态',
      width: 150,
      isFilter: true,
      type: 4,
      selectProps: {
        placeholder: '请选择',
        options: STORE_ENUM_TYPE.map((node: any) => {
          return {
            label: node.name,
            value: node.id,
          };
        }),
      },
      dataIndex: 'state',
      render: renderUsageStatus,
    },
  ];

  const getOperationList = (record: TableRowType) => {
    const stateOperateText = record.state === Turn.on ? '停用' : '启用';

    return [
      {
        title: '查看',
        onClick: () => toAqlDetail(record.id),
        auth: authDict.customizedaql_detail,
      },
      {
        title: stateOperateText,
        onClick: () =>
          BLDelConfirm(`确认${stateOperateText}?`, stateForm, () =>
            turnAQL(record.id, record.state, refreshTable),
          ),
        auth: authDict.customizedaql_enable_disable,
      },
      {
        title: '编辑',
        onClick: () => toAqlEdit(record.id),
        disabled: record.state === Turn.on,
        auth: authDict.customizedaql_edit,
      },
      {
        title: '复制',
        onClick: () => toAqlCopy(record.id),
        auth: authDict.customizedaql_add,
      },
      {
        title: '操作记录',
        auth: authDict.customizedaql_operrecord,
        onClick: () => {
          if (!record.id) return;
          setDetailId(record.id);
          setVisibleLog(true);
        },
      },
    ];
  };
  const config = {
    rowKey: (record: TableRowType) => String(record.id),
    columns: dataColumns,
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((columns: any) => {
        const filter = {
          label: columns.title,
          name: columns.dataIndex,
          type: columns.type,
          ...columns,
        } as any;

        return filter;
      }),
    getOperationList,
    formatDataToFormDisplay: (filterData: any) => {
      const { createdAt, updatedAt, ...rest } = filterData;

      const result = {
        ...rest,
        createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
        updatedAt: gcTime.formatRangeTimeToMoment(updatedAt || []),
      };

      return gcObject.filterEmptyProperty(result);
    },
    formatDataToQuery: (param: any) => {
      const { quickSearch, createdAt, creator, updatedAt, ...rest } = param;
      const [createdAtFrom, createdAtTo] = gcTime.formatTimeRangeUnix(createdAt);
      const [updatedAtFrom, updatedAtTo] = gcTime.formatTimeRangeUnix(updatedAt);

      const options = {
        name: quickSearch,
        createdAtFrom,
        createdAtTo,
        updatedAtFrom,
        updatedAtTo,
        creator: creator ? _.map(creator, 'value') : null,
        ...rest,
      };

      return gcObject.filterEmptyProperty(options);
    },
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        placeholder={'输入方案名称'}
        useIndex={false}
        userAuth={getAuthFromLocalStorage()}
        // batchMenu={[
        //   {
        //     title: '删除',
        //     icon: <BlIcon type="iconshanchulajitong1" />,
        //     onClick: (onSuccess: any, onFail: any) => {
        //       BLDelConfirm(
        //         '是否确认删除选中的方案？',
        //         delForm,
        //         () => {
        //           patchRequest(bulkDelete, selectedRowKeys, '删除', onSuccess);
        //           onSuccess();
        //         },
        //         onFail,
        //       );
        //     },
        //   },
        // ]}
        // selectedRowKeys={selectedRowKeys}
        // onSelectedRowKeys={(selectKey) => {
        //   setSelectRowKeys(selectKey);
        // }}
        refreshMarker={refreshMarker}
        mainMenu={[
          {
            title: '新建自定义AQL',
            icon: <BlIcon type="iconxinjiantianjia" />,
            onClick: toAqlCreate,
            auth: authDict.customizedaql_add,
            items: [
              {
                title: '查看国标AQL',
                icon: <BlIcon type="iconyanjing" />,
                onClick: toAqlNational,
                auth: authDict.customizedaql_standard,
              },
            ],
          },
        ]}
        requestFn={fetchCustomAqlList}
        configcacheKey="qualityAqlModule"
        {...config}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={'CustomizedAQL'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default AqlManage;
