import React, { useEffect, useState } from 'react';
import { BcTextControl, BlSortFormList } from 'src/components';
import { Form, FormInstance, Switch } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
// import { ColumnProps } from 'antd/lib/table';
// import { WAREHOUSINGATTRTYPE_MULTICHOICE, WAREHOUSINGATTRTYPE_SINGLECHOSE } from '../constant';
// import { SearchSelect } from '../share/searchSelect';
// import AddAttrItems from '../share/addAttrItems';
// import AddOptionAttrItem from '../addOptionAttrItem';
import lookup from 'src/dict';
import { ICustomFields, injectCustomFieldColumns } from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

interface Props {
  // initData?: [];
  form: FormInstance;
  customFields?: ICustomFields;
}

const formName = 'inventoryAttrs';
const AddmaterialUnitManagement = (props: Props) => {
  const { form, customFields } = props;
  const [, setDataSource] = useState<any>([]);
  // const [update, setUpdate] = useState(false);
  // const [addOptionAttrItemVisiable, setAddOptionAttrItemVisiable] = useState(false);

  const formatDataToForm = (values: any) => {
    const elementValue = values.map((item: any) => {
      return item;
    });

    setDataSource(elementValue);

    return elementValue;
  };

  // 初始化
  useEffect(() => {
    const initData = form.getFieldValue('inventoryAttrs');

    if (initData) {
      formatDataToForm(initData ?? []);
    }
  }, []);

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '库存属性名称',
        render: (text: string, field: any) => (
          <div key={field.key}>
            <Form.Item
              fieldKey={[field.fieldKey, 'name']}
              name={[field.name, 'name']}
              validateTrigger={['onChange', 'onBlur']}
              style={{ marginBottom: '0' }}
              rules={[{ required: true, message: '单元属性名称必填' }]}
            >
              <BcTextControl />
              {/* <Input placeholder="请输入" /> */}
            </Form.Item>
          </div>
        ),
      },
      {
        title: '属性类型',
        render: (text: string, field: any) => (
          <div key={field.key}>
            <Form.Item
              fieldKey={[field.fieldKey, 'type']}
              name={[field.name, 'type']}
              validateTrigger={['onChange', 'onBlur']}
              style={{ marginBottom: '0' }}
              rules={[{ required: true, message: '属性类型必填' }]}
            >
              <BcTextControl format={(value) => lookup('material.batchAttrsType', value)} />

              {/* <SearchSelect
                type={'warehousingAttrTypeOptionsList'}
                placeholder="请选择"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option as any).children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                labelInValue
                onChange={() => {
                  setUpdate(!update);
                }}
              /> */}
            </Form.Item>
          </div>
        ),
      },
      // {
      //   title: '属性值',
      //   render: (text: string, field: any) => {
      //     const type = form.getFieldValue(['inventoryAttrs', field.name, 'type'])?.value;
      //     const options = form.getFieldValue(['inventoryAttrs', field.name, 'options']);

      //     if (
      //       type === WAREHOUSINGATTRTYPE_SINGLECHOSE ||
      //       type === WAREHOUSINGATTRTYPE_MULTICHOICE
      //     ) {
      //       return (
      //         <>
      //           <Form.Item
      //             fieldKey={[field.fieldKey, 'options']}
      //             name={[field.name, 'options']}
      //             style={{ marginBottom: '0' }}
      //           >
      //             <AddAttrItems
      //               data={options?.map((item: { content: string }) => item.content)}
      //               onAddClick={() => {
      //                 setAddOptionAttrItemVisiable(true);
      //               }}
      //             />
      //           </Form.Item>
      //           <AddOptionAttrItem
      //             fieldName={['inventoryAttrs', field.name, 'options']}
      //             fieldIndex={field.name}
      //             visible={addOptionAttrItemVisiable}
      //             onCancel={() => setAddOptionAttrItemVisiable(false)}
      //             materialForm={form}
      //           />
      //         </>
      //       );
      //     }
      //     return <div key={field.key}>-</div>;
      //   },
      // },
      {
        title: '是否显示',
        width: 120,
        render: (text: string, field: any) => {
          return (
            <div key={field.key}>
              <Form.Item
                fieldKey={[field.fieldKey, 'displayEnable']}
                name={[field.name, 'displayEnable']}
                valuePropName="checked"
                style={{ marginBottom: '0' }}
                getValueFromEvent={(value) => {
                  return Number(value);
                }}
              >
                <Switch />
              </Form.Item>
            </div>
          );
        },
      },
      {
        title: '是否必填',
        width: 120,
        render: (text: string, field: any) => {
          return (
            <div key={field.key}>
              <Form.Item
                fieldKey={[field.fieldKey, 'nullEnable']}
                name={[field.name, 'nullEnable']}
                valuePropName="checked"
                style={{ marginBottom: '0' }}
                getValueProps={(value) => {
                  return { checked: !value };
                }}
                getValueFromEvent={(value) => {
                  return Number(!value);
                }}
              >
                <Switch />
              </Form.Item>
            </div>
          );
        },
      },
    ];
  };

  return (
    <BlSortFormList
      name={formName}
      form={form}
      renderColumns={() =>
        injectCustomFieldColumns({
          columns: getColumns(), // 原本的列
          customFields, // 自定义字段信息
          objectCode: OBJECT_OF_CODE.InventoryAttributeConfig, // 从对象code
          type: 'form', // 使用类型
          formConfig: { form, formName },
        })
      }
      // isNeedBatchAdd
      isNeedAdd={false}
      isNeedDrag={false}
      isNeedDelete={false}
      batchAddInit={{ name: { isFollowInput: true } }}
    />
  );
};

export default AddmaterialUnitManagement;
