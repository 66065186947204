import styles from '../style.module.scss';
import { memo } from 'react';

interface Props {
  edgeInfo: any;
  index: number;
  selecting?: boolean;
  clickFunction?: (info: any) => void;
}

const LineContainer = memo((props: Props) => {
  const { selecting, index, edgeInfo, clickFunction } = props;

  if (!edgeInfo) {
    return null;
  }
  const edgeStyle = [styles.line];

  if (selecting) {
    edgeStyle.push(styles.editingLine);
  }

  return (
    <div
      className={edgeStyle.join(' ')}
      onClick={() => {
        clickFunction?.(index);
      }}
    />
  );
});

export default LineContainer;
