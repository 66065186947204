import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { BlIcon } from '@blacklake-web/component';
import { gcArray } from 'utils';
//
import styles from '../styles.module.scss';

const getSelectedKey = (pathname: string) => {
  const result = /(^\/.+?\/.+?)\//.exec(pathname);

  if (!result || result.length < 2) {
    return pathname;
  }
  return result[1];
};

const { SubMenu, Item } = Menu;

const BackEndMenu = (props: any) => {
  const { menuData } = props;
  const { location } = window;
  const SubMenuPath = location.pathname.split('/');

  SubMenuPath.pop();

  const renderMenuItem = (itemsArray: any[], isFirstLevel = false) => {
    return itemsArray.map((item: any) => {
      if (!item) return null;
      const { path, icon, name, children, isCategory } = item;

      if (isCategory) {
        return (
          <div className={styles['backendMenu-module']} key={name}>
            {name}
          </div>
        );
      }

      const option: any = isFirstLevel
        ? { className: styles['backend-first-level-menu-item'] }
        : {};

      if (icon) {
        option.icon = <BlIcon type={icon} />;
      }

      if (!path || !name) return null;
      const isLeaf = !gcArray.isEmpty(children.filter((node: any) => node.name));

      if (isLeaf) {
        return (
          <SubMenu {...option} key={path} title={name}>
            {renderMenuItem(children)}
          </SubMenu>
        );
      }
      return (
        <Item {...option} key={path}>
          <Link to={path}>{name}</Link>
        </Item>
      );
    });
  };

  return (
    <div>
      <Menu
        defaultOpenKeys={[SubMenuPath.join('/')]}
        selectedKeys={[getSelectedKey(location.pathname)]}
        mode="inline"
        className={styles['backend-menu']}
      >
        {renderMenuItem(menuData, true)}
      </Menu>
    </div>
  );
};

export default BackEndMenu;
