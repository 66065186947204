import { createContext } from 'react';
import { noop, sum } from 'lodash';
import {
  ControlType,
  BizType,
  StepEntityType,
  PrivilegeType,
  NextStepType,
  LoopExecRestrictRule,
  DefaultLogic,
  ShowLogic,
  NumericType,
  TimeType,
  UploadMethod,
  FileType,
  SelectorMethodType,
  GroupType,
  UserOptionalRange,
  ParamGetType,
  ObjectEntitySource,
  FieldSource,
  NumericRangeLimitLogic,
} from 'src/dict/sop';
import lookup from 'src/dict';
import { Time, ObjectCode } from 'src/dict/common';
import * as Previews from './control/previews';
import type { FetchSopStepAddRequest } from 'src/api/ytt/sop-domain';
import type { ControlItem } from './types';

export const NEW_STEP_ID = 'new';
export const NEW_STEP_NUMBER_ID = -999;
export const NEW_CONTROL_ID = -10086;
export const NEW_CONTROL_INDEX = -10086;
export const CONTROL_TITLE_LENGTH = 36;

// wendy TODO 涉及到功能校验，ts校验先关闭
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onValuesChange = (changeValues: any, allValues: any) => {};

export const ControlEditorContext = createContext({
  forceUpdate: noop,
  formProps: {
    onValuesChange,
  },
  bizType: BizType.general,
  sopBasicInfo: {
    isReportTemplate: false,
    stepId: '',
  },
});

export const INPUT_DEFAULT_PLACEHOLDER = '请输入';

export const coverIconMap = new Map<ControlType, string>([
  [ControlType.single, 'icondanhangwenben'],
  [ControlType.multiLine, 'iconduohangwenben'],
  [ControlType.numeric, 'iconshuzi'],
  [ControlType.user, 'icongeren'],
  [ControlType.selector, 'iconxuanzeqi'],
  [ControlType.time, 'iconrili'],
  [ControlType.file, 'iconfujian'],
  [ControlType.station, 'icongongwei'],
  [ControlType.report, 'iconbaogong'],
  [ControlType.feed, 'icontouliao'],
  [ControlType.associatedData, 'iconguanlianshuju'],
  [ControlType.associatedField, 'iconguanlianziduan'],
  [ControlType.resourceWithFixedParams, 'icongudingcanshu'],
  [ControlType.resourceWithSelectedParams, 'iconshoudongxuanzecanshu'],
  [ControlType.qcTaskOfSOP, 'iconchuangjianjianyanrenwu'],
  [ControlType.resourceRepair, 'iconziyuanweixiu'],
  [ControlType.numberRules, 'iconbianhao'],
]);

export const componentMap = new Map([
  [ControlType.single, Previews.Single],
  [ControlType.multiLine, Previews.MultiLine],
  [ControlType.numeric, Previews.Numeric],
  [ControlType.time, Previews.Time],
  [ControlType.user, Previews.User],
  [ControlType.station, Previews.Station],
  [ControlType.file, Previews.File],
  [ControlType.selector, Previews.Selector],
  [ControlType.feed, Previews.Single],
  [ControlType.report, Previews.Single],
  [ControlType.associatedData, Previews.AssociatedData],
  [ControlType.associatedField, Previews.AssociatedField],
  [ControlType.resourceWithFixedParams, Previews.Resource],
  [ControlType.resourceWithSelectedParams, Previews.Resource],
  [ControlType.qcTaskOfSOP, Previews.QcTaskOfSOP],
  [ControlType.resourceRepair, Previews.ResourceRepair],
  [ControlType.numberRules, Previews.NumberRules],
]);

/** 新步骤默认值 */
export const stepDefaultValue: FetchSopStepAddRequest = {
  code: '',
  id: 0,
  referenceId: 0,
  type: StepEntityType.step,
  name: '',
  authUsers: [],
  authDepartments: [],
  authRoles: [],
  loopCount: 2,
  privilegeType: PrivilegeType.executors,
  singleLoopIntervalTime: 1,
  loopIntervalUnit: Time.second,
  singleLoopTimeRestrict: false,
  loopExecRestrictRule: LoopExecRestrictRule.inTurn,
  enableDigitalSignature: false,
  digitalType: PrivilegeType.users,
  digitalUserIds: [],
  digitalRoleIds: [],
  enableSelfLoop: false,
  first: false,
  last: false,
  nextStepType: NextStepType.next,
  groupType: GroupType.serial,
  controls: [],
  manualNextStepIds: [],
  formulaId: undefined,
};

// 单行、多行控件共享的默认值
const textInputDefaultValue = {
  bizType: BizType.general,
  code: undefined,
  decimalLength: 1,
  defaultLogic: DefaultLogic.none,
  defaultValue: undefined,
  enableCheckbox: undefined,
  enableDecimal: false,
  enableEdit: true,
  enableLimit: false,
  enableSeparator: false,
  inputPrompt: undefined,
  isRequired: false,
  limitMax: undefined,
  limitMin: undefined,
  numericType: NumericType.value,
  referenceId: 0,
  remark: undefined,
  scanInput: false,
  scanResultCanEdit: false,
  showLogic: ShowLogic.fixed,
  showTrigger: undefined,
  showValue: undefined,
  sortIndex: undefined,
  stepId: 0,
};

// 数字控件默认值
const numericInputDefaultValue = {
  bizType: BizType.general,
  code: undefined,
  decimalLength: 1,
  defaultLogic: DefaultLogic.none,
  defaultValue: undefined,
  enableCheckbox: undefined,
  enableDecimal: false,
  enableEdit: true,
  // enableLimit: false,
  enableSeparator: false,
  inputPrompt: undefined,
  isRequired: false,
  // limitMax: undefined,
  // limitMin: undefined,
  numericType: NumericType.value,
  referenceId: 0,
  remark: undefined,
  scanInput: false,
  scanResultCanEdit: false,
  showLogic: ShowLogic.fixed,
  showTrigger: undefined,
  showValue: undefined,
  sortIndex: undefined,
  stepId: 0,
};

/** 新控件默认值 */
export const controlDefaultValueMap = new Map<ControlType, ControlItem>([
  [
    ControlType.single,
    {
      ...textInputDefaultValue,
      controlType: ControlType.single,
      name: lookup('sop.controlType', ControlType.single),
    },
  ],
  [
    ControlType.multiLine,
    {
      ...textInputDefaultValue,
      controlType: ControlType.multiLine,
      name: lookup('sop.controlType', ControlType.multiLine),
    },
  ],
  [
    ControlType.numeric,
    {
      ...numericInputDefaultValue,
      controlType: ControlType.numeric,
      name: lookup('sop.controlType', ControlType.numeric),
      defaultFormulaId: undefined,
      showFormulaId: undefined,
      unitId: undefined,
      unitName: undefined,
      numericLimitType: NumericRangeLimitLogic.none,
      lowLimit: undefined,
      highLimit: undefined,
      lowestLimit: undefined,
      highestLimit: undefined,
    },
  ],
  [
    ControlType.time,
    {
      bizType: BizType.general,
      code: '',
      controlType: ControlType.time,
      name: lookup('sop.controlType', ControlType.time),
      sortIndex: 0,
      stepId: 0,
      referenceId: 0,
      isRequired: false,
      defaultLogic: 0,
      defaultValue: undefined,
      enableEdit: true,
      inputPrompt: undefined,
      remark: undefined,
      showLogic: ShowLogic.fillTime,
      showValue: undefined,
      timeType: TimeType.ymd,
    },
  ],
  [
    ControlType.user,
    {
      bizType: BizType.general,
      code: '',
      controlType: ControlType.user,
      name: lookup('sop.controlType', ControlType.user),
      sortIndex: 0,
      stepId: 0,
      referenceId: 0,
      optionalRange: UserOptionalRange.current, // 可选范围：指定用户、当前用户
      optionalUser: [],
      enableCheckbox: false, // 是否多选
      defaultLogic: 0, // 默认值：指定用户、固定用户 默认：无
      defaultUserOption: [], // 默认值列表
      isRequired: false, // 校验必填
      enableEdit: false, // 权限：可编辑
      inputPrompt: undefined, // 输入placeholder
      remark: undefined, // 描述信息
      showLogic: ShowLogic.currentUser, // 显示逻辑：全部用户、指定用户、固定用户
      showValueList: [],
    },
  ],
  [
    ControlType.station,
    {
      bizType: BizType.general,
      code: '',
      controlType: ControlType.station,
      name: lookup('sop.controlType', ControlType.station),
      sortIndex: 0,
      stepId: 0,
      isRequired: false, // 校验必填
      enableEdit: true, // 权限：可编辑
      decimalLength: undefined,
      defaultLogic: 0,
      defaultOption: undefined, // 默认选项
      defaultTrigger: undefined,
      defaultValueList: undefined, // 默认值
      enableCheckbox: false, // 是否多选
      enableDecimal: undefined,
      enableLimit: false,
      enableSeparator: undefined,
      fileSize: undefined,
      fileType: undefined,
      inputPrompt: undefined, // 输入placeholder
      limitMax: undefined,
      limitMin: undefined,
      numericType: undefined,
      optionValue: undefined, // 选项值
      optionalRange: 3, // 可选范围
      referenceId: 0,
      remark: '', // 描述信息
      scanInput: undefined,
      scanResultCanEdit: false,
      selectMethod: undefined, // 选择方式
      showLogic: ShowLogic.currentStation, // 显示逻辑
      showTrigger: undefined,
      showValueList: undefined, // 显示的值
      specificValue: undefined,
      timeType: undefined,
      uploadMethod: undefined,
    },
  ],
  [
    ControlType.file,
    {
      bizType: BizType.general,
      code: '',
      controlType: ControlType.file,
      name: lookup('sop.controlType', ControlType.file),
      sortIndex: 0,
      stepId: 0,
      isRequired: false,
      defaultLogic: 0,
      defaultFileList: [],
      defaultValue: undefined,
      enableDecimal: false,
      enableEdit: true,
      enableLimit: true,
      fileSize: 20,
      fileType: sum([
        FileType.document,
        FileType.image,
        FileType.audio,
        FileType.video,
        FileType.compressed,
      ]),
      inputPrompt: undefined,
      referenceId: 0,
      remark: '',
      showFileList: [],
      uploadMethod: sum([UploadMethod.localFile, UploadMethod.photo]),
    },
  ],
  [
    ControlType.selector,
    {
      bizType: BizType.general,
      code: '',
      controlType: ControlType.selector,
      name: lookup('sop.controlType', ControlType.selector),
      sortIndex: 0,
      stepId: 0,
      isRequired: false, // 校验必填
      enableEdit: true, // 权限：可编辑
      defaultOption: undefined, // 默认选项
      defaultValue: undefined, // 默认值
      inputPrompt: undefined, // 输入placeholder
      optionValue: ['选项一', '选项二', '选项三'].map((label) => ({ label })), // 选项值
      referenceId: 0,
      remark: '', // 描述信息
      selectMethod: SelectorMethodType.single, // 选择方式
    },
  ],
  [
    ControlType.report,
    {
      bizType: BizType.production,
      code: '',
      controlType: ControlType.report,
      name: lookup('sop.controlType', ControlType.report),
      sortIndex: 0,
      stepId: 0,
      referenceId: 0,
    },
  ],
  [
    ControlType.feed,
    {
      bizType: BizType.production,
      code: '',
      controlType: ControlType.feed,
      name: lookup('sop.controlType', ControlType.feed),
      sortIndex: 0,
      stepId: 0,
      referenceId: 0,
    },
  ],
  [
    ControlType.associatedData,
    {
      bizType: BizType.general,
      code: '',
      controlType: ControlType.associatedData,
      name: lookup('sop.controlType', ControlType.associatedData),
      sortIndex: 0,
      stepId: 0,
      referenceId: 0,
      relateObject: ObjectCode.Supplier,
      showField: [],
      mainField: undefined,
      enableCheckbox: false, // 是否多选
      isRequired: false, // 校验必填
      remark: undefined, // 描述信息
    },
  ],
  [
    ControlType.associatedField,
    {
      bizType: BizType.general,
      code: '',
      controlType: ControlType.associatedField,
      name: lookup('sop.controlType', ControlType.associatedField),
      sortIndex: 0,
      stepId: 0,
      referenceId: 0,
      fieldSource: FieldSource.currentSheet,
      localControl: undefined,
      relateObject: ObjectCode.Supplier,
      referenceField: undefined,
      mainField: undefined,
      enableCheckbox: false, // 是否多选
      isRequired: false, // 校验必填
      remark: undefined, // 描述信息
    },
  ],
  [
    ControlType.resourceWithFixedParams,
    {
      bizField: {
        paramGetType: ParamGetType.manual,
        resourceType: 0,
        resourceIds: undefined,
        paramIds: undefined,
        maxCount: 20,
        required: false, // 校验必填
      },
      bizType: BizType.production,
      code: '',
      controlType: ControlType.resourceWithFixedParams,
      name: lookup('sop.controlType', ControlType.resourceWithFixedParams),
      sortIndex: 0,
      stepId: 0,
      referenceId: 0,
      // isRequired: false, // 校验必填
      remark: undefined, // 描述信息
      inputPrompt: undefined, // 输入placeholder
    },
  ],
  [
    ControlType.resourceWithSelectedParams,
    {
      bizField: {
        paramGetType: ParamGetType.manual,
        resourceType: 0,
        resourceSource: ObjectEntitySource.manual,
        resourceIds: undefined,
        maxCount: 20,
        required: false, // 校验必填
      },
      bizType: BizType.production,
      code: '',
      controlType: ControlType.resourceWithSelectedParams,
      name: lookup('sop.controlType', ControlType.resourceWithSelectedParams),
      sortIndex: 0,
      stepId: 0,
      referenceId: 0,
      // isRequired: false, // 校验必填
      remark: undefined, // 描述信息
      inputPrompt: undefined, // 输入placeholder
    },
  ],

  [
    ControlType.qcTaskOfSOP,
    {
      bizType: BizType.production,
      code: '',
      controlType: ControlType.qcTaskOfSOP,
      name: lookup('sop.controlType', ControlType.qcTaskOfSOP),
      sortIndex: 0,
      stepId: 0,
      referenceId: 0,
      bizField: {
        autoProcess: 1,
        autoMaterial: 1,
        autoBatchNumber: 1,
      },
    },
  ],
  [
    ControlType.resourceRepair,
    {
      bizType: BizType.production,
      code: '',
      controlType: ControlType.resourceRepair,
      name: lookup('sop.controlType', ControlType.resourceRepair),
      sortIndex: 0,
      stepId: 0,
      referenceId: 0,
    },
  ],
  [
    ControlType.numberRules,
    {
      bizType: BizType.general,
      code: '',
      controlType: ControlType.numberRules,
      name: lookup('sop.controlType', ControlType.numberRules),
      sortIndex: 0,
      stepId: 0,
      referenceId: 0,
      relateObject: undefined,
      numberRule: undefined,
      isRequired: false, // 校验必填
      remark: undefined, // 描述信息
      inputPrompt: undefined, // 输入placeholder
    },
  ],
  [
    ControlType.resourceRepair,
    {
      bizType: BizType.production,
      code: '',
      controlType: ControlType.resourceRepair,
      name: lookup('sop.controlType', ControlType.resourceRepair),
      sortIndex: 0,
      stepId: 0,
      referenceId: 0,
    },
  ],
]);
/**
 * SOP和报告模板下的控件分开控制：通用控件有不在报告模板显示，业务控件要在报告模板显示
 */
/** 基础控件-SOP */
export const basicControls = [
  ControlType.single,
  ControlType.multiLine,
  ControlType.numeric,
  ControlType.user,
  ControlType.selector,
  ControlType.time,
  ControlType.file,
  // ControlType.station,
  ControlType.associatedData,
  ControlType.associatedField,
  ControlType.numberRules,
];
/** 基础控件-报告模板 */
export const basicControlsForReportTem = [
  ControlType.single,
  ControlType.multiLine,
  ControlType.numeric,
  ControlType.user,
  ControlType.selector,
  ControlType.time,
  ControlType.file,
  // ControlType.station,
  ControlType.associatedData,
  ControlType.associatedField,
];

/** 业务控件：维保、维修 */
export const bizControlsOfMaintenance = [
  ControlType.resourceWithFixedParams,
  ControlType.resourceWithSelectedParams,
  ControlType.resourceRepair,
];
/** 业务控件：生产 */
export const bizControlsOfProduce = [ControlType.report, ControlType.feed, ControlType.qcTaskOfSOP];

/** 业务控件 */
export const bizControls = [...bizControlsOfProduce, ...bizControlsOfMaintenance];

// 报告模板-业务控件
export const bizControlsForReportTem = [];
