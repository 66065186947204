import React from 'react';
import NotificationMessage from './notification/notification';
import NotificationMessagePopover from './notification/notificationPopover';
import TodoEvent from './todo';
import AbnormalEvent from './abnormalEvent';
import { Tabs } from 'antd';
import styles from './notification/styles.module.scss';

const { TabPane } = Tabs;

interface Props {
  type: string;
  handleClose: (visible: boolean) => void;
}

enum TabKey {
  notificationMessage = '1',
  todoEvent = '2',
  abnormalEvent = '3',
}

const NotificationTabList = ({ type = 'page', handleClose }: Props) => {
  const tabList = [
    {
      tab: '通知消息',
      key: TabKey.notificationMessage,
      component:
        type === 'popover' ? (
          <NotificationMessagePopover type={type} handleClose={handleClose} />
        ) : (
          <NotificationMessage type={type} />
        ),
    },
    // {
    //   tab: '待办事项',
    //   key: TabKey.todoEvent,
    //   component: <TodoEvent />,
    // },
    // {
    //   tab: '异常事件',
    //   key: TabKey.abnormalEvent,
    //   component: <AbnormalEvent />,
    // },
  ];

  return (
    <div className={type === 'popover' ? styles.tabsBoxPopover : styles.tabsBox}>
      <Tabs className={styles.tabs} destroyInactiveTabPane>
        {tabList.map(({ component, ...resItem }) => (
          <TabPane {...resItem} style={{ height: '100%' }}>
            {component}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default NotificationTabList;
