import { useEffect, useState } from 'react';
import { BlSortFormList } from 'src/components';
import { FormInstance } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';

import SearchSelectInput from 'src/page/knowledgeManagement/materialModeling/material/share/searchSelectInput';
import { ICustomFields } from 'src/components/customField';

interface StoreDetailProps {
  // initData?: [];
  form: FormInstance;
  customFields?: ICustomFields;
  // baseUnitId?: number | undefined;
}

const AddMaterialProperty = (props: StoreDetailProps) => {
  const { form, customFields } = props;
  const [, setDataSource] = useState<any>([]);

  const formatDataToForm = (values: any) => {
    const elementValue = values.map((item: any) => {
      return item;
    });

    setDataSource(elementValue);

    return elementValue;
  };

  // 初始化
  useEffect(() => {
    const initData = form.getFieldValue('attributes');

    if (initData) {
      formatDataToForm(initData ?? []);
    }
  }, []);

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '属性项',
        render: (text: string, field: any) => (
          <SearchSelectInput
            type={'materialAttributeList'}
            form={form}
            field={field}
            fieldName={'attributeId'}
            formatDataToForm={formatDataToForm}
            onChange={() => {
              form.resetFields([['attributes', field.name, 'attributeItemId']]);
            }}
          />
        ),
      },
      {
        title: '属性值',
        render: (text: string, field: any) => {
          return (
            <SearchSelectInput
              type={'getMaterialAttributeItemList'}
              form={form}
              field={field}
              fieldName={'attributeItemId'}
              formatDataToForm={formatDataToForm}
            />
          );
        },
      },
    ];
  };

  return (
    <BlSortFormList
      style={{ width: '95%' }}
      name={'attributes'}
      form={form}
      isNeedDrag={false}
      renderColumns={() => getColumns()}
      // renderColumns={() =>
      //   injectCustomFieldColumns({
      //     columns: getColumns(), // 原本的列
      //     customFields, // 自定义字段信息
      //     objectCode: OBJECT_OF_CODE.MaterialConversionUnit, // 从对象code
      //     type: 'form', // 使用类型
      //     formConfig: { form, formName: 'attributes' },
      //   })
      // }
    />
  );
};

export default AddMaterialProperty;
