import React, { useCallback, useEffect, useState } from 'react';
// import { Form } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import { replaceSign } from 'src/utils/constants';
import {
  fetchMalfunctionDetail,
  FetchMalfunctionDetailResponse,
} from 'src/api/ytt/resource-domain/malfunctionRecord';
import _ from 'lodash';
import lookup from 'src/dict';
import { BlIcon, BlTable, SingleOperationLogComponent, BcAttachmentDetail } from 'src/components';
import authDict from 'src/utils/auth';
import { goToEdit } from '../navigates';
import _Time from 'src/utils/dataTypes/time';
import { BL_OBJECT_CODE } from '../config';

interface Props {
  id: number;
}

const DetailsBaseInfo = (props: Props) => {
  const { id: detailId } = props;
  const [dataSource, setDataSource] = useState<FetchMalfunctionDetailResponse>();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);

  const init = async () => {
    try {
      const res: any = await fetchMalfunctionDetail({ id: detailId });
      const { data } = res;

      // console.log();
      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };

  // const handleFinish = async (id: number | number[]) => {
  //   try {
  //     const value = await delForm?.validateFields();
  //     const ids = typeof id === 'number' ? [id] : id;
  //     const { code, message: mes } = await fetchMaterialAttributeDelete({
  //       ids,
  //       reason: value.delReason,
  //     });

  //     if (code === 200) {
  //       message.success('删除成功');
  //       // 关闭details
  //       typeof onCancel === 'function' && onCancel();
  //       // 刷新 list
  //       typeof refreshTable === 'function' && refreshTable();
  //       return;
  //     }
  //     message.error(mes);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const initBaseMenu = useCallback(
    () =>
      _.compact([
        // {
        //   key: 'delete',
        //   onClick: () => {
        //     confirm('是否删除当前单位', delForm, () => {
        //       handleFinish(detailId);
        //     });
        //   },
        //   // icon: <<BlIcon type="iconshanchulajitong1" /> />,
        //   title: '删除',
        // },
        {
          key: 'record',
          auth: authDict.resourceMalfunctionRecord_operrecord,
          onClick: () => {
            setVisibleLog(true);
          },
          // icon: <FileTextOutlined />,
          title: '操作记录',
        },
        {
          key: 'edit',
          auth: authDict.resourceMalfunctionRecord_edit,
          onClick: () => {
            goToEdit(detailId);
          },
          icon: <BlIcon type="iconbianji" />,
          title: '编辑',
        },
      ]),
    [detailId],
  );

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '故障编号',
        dataIndex: 'code',
        span: 1,
        // isFullLine: true,
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '故障标题',
        dataIndex: 'name',
        span: 1,
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '业务对象',
        dataIndex: 'resourceType',
        span: 1,
        render: (value: number) =>
          lookup('resources.RESORCE_BUSINESS_TYPE_MAP', value) || replaceSign,
      },
      {
        label: '故障资源',
        dataIndex: ['resource', 'name'],
        span: 1,
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '资源编号',
        dataIndex: ['resource', 'code'],
        span: 1,
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '故障类型',
        dataIndex: 'type',
        span: 1,
        render: (value: number) => lookup('resources.malfunctionTypes', value) || replaceSign,
      },
      {
        label: '故障分类',
        dataIndex: ['classification', 'fullName'],
        span: 1,
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '故障等级',
        dataIndex: 'level',
        span: 1,
        render: (value: number) => lookup('resources.malfunctionLevel', value) || replaceSign,
      },
      {
        label: '记录来源',
        dataIndex: 'recordSource',
        span: 1,
        render: (value: number) => lookup('resources.malfunctionRecordOrign', value) || replaceSign,
      },
      {
        label: '来源对象',
        dataIndex: 'sourceObject',
        span: 1,
        render: (value: number) =>
          lookup('resources.malfunctionRecordOrignObj', value) || replaceSign,
      },
      {
        label: '来源对象实例',
        dataIndex: 'sourceObjectVO',
        span: 1,
        render: (obj: any) => (obj ? `${obj.paramName} ${obj.recordTime}` : replaceSign),
      },
      {
        label: '故障记录时间',
        dataIndex: 'recordTime',
        span: 1,
        render: (recordTime: Date) => {
          if (!recordTime) return replaceSign;
          return _Time.format(recordTime);
        },
      },
      {
        label: '故障原因',
        dataIndex: 'description',
        span: 1,
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '故障上报人',
        dataIndex: ['reportor', 'name'],
        span: 1,
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '故障明细',
        dataIndex: 'detail',
        span: 1,
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '备注',
        dataIndex: 'remark',
        isFullLine: true,
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '附件',
        dataIndex: 'fileIds',
        render: (fileIds: number[]) => {
          return fileIds ? <BcAttachmentDetail fileIds={fileIds} /> : replaceSign;
        },
        isFullLine: true,
      },
    ],
    column: 2,
  };

  const malfunctionLabel: DetailLayoutInfoBlock = {
    title: '故障标签',
    items: [
      {
        label: '',
        dataIndex: 'tagsLinks',
        isFullLine: true,
        render: (list: any) => {
          // return list;
          return (
            <BlTable
              dataSource={list}
              pagination={false}
              rowKey={(row: any) => row.id}
              columns={[
                {
                  title: '标签项',
                  dataIndex: 'label',
                },
                {
                  title: '标签值',
                  dataIndex: 'labelValue',
                },
              ]}
            />
          );
        },
      },
    ],
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        span: 1,
        dataIndex: ['creator', 'name'],
        render: (name: string) => name || replaceSign,
      },
      {
        label: '创建时间',
        span: 1,
        dataIndex: 'createdAt',
        render: (createdAt: Date) => {
          if (!createdAt) return replaceSign;
          return _Time.format(createdAt);
        },
      },
      {
        label: '更新人',
        span: 1,
        dataIndex: ['operator', 'name'],
        render: (name: string) => name || replaceSign,
      },
      {
        label: '更新时间',
        span: 1,
        dataIndex: 'updatedAt',
        render: (updatedAt: Date) => {
          if (!updatedAt) return replaceSign;
          return _Time.format(updatedAt);
        },
      },
    ],
    column: 2,
  };
  const infoArray = [baseInfo, malfunctionLabel, otherInfo];

  useEffect(() => {
    if (detailId) {
      init();
    }
  }, [detailId]);
  return (
    <>
      <DetailLayout
        title={'标签详情'}
        baseMenu={initBaseMenu()}
        info={infoArray}
        dataSource={dataSource}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId as number}
          objectCode={BL_OBJECT_CODE}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailsBaseInfo;
