import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Button, DatePicker, Space } from 'antd';
import { RecordListLayout } from 'src/layout';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { formateDateRangeForQuery2 } from 'src/utils/formatters/dateTime';
import {
  fetchDispatchRequestLogList,
  FetchDispatchRequestLogListResponse,
} from 'src/api/ytt/med-domain/work_order';
import { AvatarDisplay } from 'src/components/avatar/show';
import { ListItemType } from 'src/types';
//
import { toLogDetailProductionOrder } from '../navigation';
import { getResult, getStatus } from './utils';

type RecordType = ListItemType<FetchDispatchRequestLogListResponse>;

const FILTER_KEY = 'productionOrderLogFilter';

const List = () => {
  const [filterDate, setFilterDate] = useState<any>(
    _.map(JSON.parse(sessionStorage.getItem(FILTER_KEY) ?? '[]'), (item) => moment(item)),
  );
  const [resetRefreshMarker, setResetRefreshMarker] = useState(0);

  const columns: ColumnProps<RecordType>[] = [
    {
      title: '操作时间',
      dataIndex: 'updatedAt',
      width: 200,
      render: (text) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''),
    },
    {
      title: '操作用户',
      dataIndex: 'operator',
      width: 100,
      render: (info) => <AvatarDisplay {...info} isUser />,
    },
    {
      title: '操作结果',
      dataIndex: 'operatorId',
      width: 200,
      render: (text, record) => getStatus(record ?? {}),
    },
    {
      title: '操作详情',
      dataIndex: 'successCount',
      width: 200,
      render: (text, record) => getResult(record ?? {}),
    },
    {
      title: '详情',
      dataIndex: 'id',
      width: 100,
      fixed: 'right',
      render: (operatorId) => <Link to={toLogDetailProductionOrder(operatorId)}>查看</Link>,
    },
  ];

  const formatDataToQuery = (params: any) => {
    return { ...params, ...formateDateRangeForQuery2('updatedAtFrom', 'updatedAtTo', filterDate) };
  };

  return (
    <div
      style={{ height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}
    >
      <Space style={{ padding: 16 }} size={16}>
        <span>操作时间:</span>
        <DatePicker.RangePicker
          style={{ width: 300 }}
          value={filterDate}
          onChange={(value) => {
            setFilterDate(value);
          }}
        />
        <Button
          type="primary"
          onClick={() => {
            setResetRefreshMarker(Math.random());
            sessionStorage.setItem(FILTER_KEY, JSON.stringify(filterDate));
          }}
        >
          查询
        </Button>
      </Space>
      <RecordListLayout<RecordType>
        useQuickFilter={false}
        useColConfig={false}
        formatDataToQuery={formatDataToQuery}
        resetRefreshMarker={resetRefreshMarker}
        requestFn={fetchDispatchRequestLogList}
        columns={columns}
      />
    </div>
  );
};

export default List;
