import { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FilterItem } from 'layout';
import moment from 'moment';
import { isEmpty, cloneDeep, omit, map } from 'lodash';
import { fieldTypeList, gcArray, gcTime } from 'utils';
import { BlIcon, SingleOperationLogComponent, Tooltip } from 'components';
import { RecordListLayout } from 'src/layout';
import _Time from 'src/utils/dataTypes/time';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { fetchWorkCenterList } from 'src/api/ytt/work-center-domain/index';
import { SearchSelect } from 'src/components';
import { replaceSign } from 'src/utils/constants';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { UserListItem, UserColumnProps, FIELD_MAP } from '../constants';
import {
  toDetailWorkCenter,
  toCreateWorkCenter,
  toEditWorkCenter,
  toCopyWorkCenter,
} from '../navigation';
import { ColumnProps } from 'antd/lib/table';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';

interface TableRowType {
  content?: string;
  index?: number;
  id: number;
}

const MaterialPropertyList = (props: { history: any }) => {
  const [operationRecordVisible, setOperationRecordVisible] = useState(false);
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const [datasetId, setDataSetId] = useState<number>(0);
  // const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const { history } = props;
  // const handleFinish = async (id?: number | any[]) => {
  //   try {
  //     let idList;

  //     if (id) {
  //       // 操作列的删除
  //       idList = typeof id === 'number' ? [id] : id;
  //     } else {
  //       // 按钮的删除
  //       idList = selectedRowKeys;
  //     }
  //     const { code, message: mes } = await fetchWorkCenterDelete({
  //       idList,
  //       // reason: value.delReason,
  //     });

  //     if (code === 200) {
  //       message.success('删除成功');
  //       // 刷新list
  //       setRefreshMarker(new Date().getTime());
  //       return;
  //     }
  //     message.error(mes);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const dataColumns: (ColumnProps<any> & {
    filterIndex?: string;
    type?: number;
    isFilter?: boolean;
    renderItem?: ReactElement;
    dateFormat?: any;
  })[] = [
    {
      title: '工作中心编号',
      dataIndex: 'code',
      sorter: true,
      width: 150,
      fixed: 'left',
      type: fieldTypeList.text,
      render: (text: string, record: any, index: number, config: any) => {
        return <Tooltip text={text ?? replaceSign} width={config.contentWidth} />;
      },
    },
    {
      title: '工作中心名称',
      dataIndex: 'name',
      sorter: true,
      width: 150,
      type: fieldTypeList.text,
      render: (text: string, record: any, index: number, config: any) => {
        return <Tooltip text={text ?? replaceSign} width={config.contentWidth} />;
      },
    },
    {
      title: '所属部门',
      dataIndex: 'departmentListResponseDTOList',
      type: fieldTypeList.select,
      width: 150,
      render: (text: UserListItem[]) => text?.map(({ name }) => name)?.join(',') ?? '-',
    },

    // 一期不做
    // {
    //   title: '日历标识',
    //   dataIndex: 'calendar',
    //   isFilter: true,
    //   width: 150,
    //   type: fieldTypeList.boolean,
    //   render: (text: number) => {
    //     return <Tooltip text={calendarMap?.get(text) ?? replaceSign} width={140} />;
    //   },
    // },
    {
      title: '投料仓位',
      dataIndex: 'inputStorageList',
      width: 150,
      type: fieldTypeList.select,
      render: (text: UserListItem[]) => text?.map(({ name }) => name)?.join(',') ?? '-',
    },
    {
      title: '产出仓位',
      dataIndex: 'outputStorageList',
      width: 150,
      type: fieldTypeList.select,
      render: (text: UserListItem[]) => text?.map(({ name }) => name)?.join(',') ?? '-',
    },
    {
      title: '备注',
      dataIndex: 'remark',
      type: fieldTypeList.textArea,
      width: 150,
      render: (text: string, record: any, index: number, config: any) => {
        return <Tooltip text={text ?? replaceSign} width={config.contentWidth} />;
      },
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      filterIndex: 'creatorId',
      renderItem: <SearchSelect fetchType="user" labelInValue />,
      width: 150,
      render: (text: any) => {
        const { id, name, avatarUrl } = text || {};

        return (
          id && (
            <AvatarDisplay
              id={id}
              name={name ?? ''}
              key={id}
              isShowTag={false}
              avatarUrl={avatarUrl}
              isUser
            />
          )
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      width: 180,
      sorter: true,
      render: (text: any) => _Time.format(text),
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      filterIndex: 'operatorId',
      renderItem: <SearchSelect fetchType="user" labelInValue />,
      width: 150,
      render: (text: any) => {
        const { id, name, avatarUrl } = text || {};

        return (
          id && (
            <AvatarDisplay
              id={id}
              name={name ?? ''}
              key={id}
              isShowTag={false}
              avatarUrl={avatarUrl}
              isUser
            />
          )
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      type: fieldTypeList.date,
      width: 180,
      sorter: true,
      render: (text: any) => _Time.format(text),
    },
  ];
  const config = {
    left: [
      {
        title: '导出',
        showExport: true,
        icon: <BlIcon type="icondaochu" />,
        auth: authDict.workcenter_export,
        onClick: () => {
          openModal({
            optType: 'export',
            businessType: BUSINESS_TYPE.workCenter,
          });
        },
      },
      {
        title: '新建工作中心',
        auth: authDict.workcenter_add,
        icon: <BlIcon type="iconxinjiantianjia" />,
        onClick: () => {
          history.push(toCreateWorkCenter());
        },
        items: [
          {
            title: '导入',
            showExport: true,
            auth: authDict.workcenter_import,
            onClick: () => {
              openModal({
                optType: 'import',
                businessType: BUSINESS_TYPE.workCenter,
              });
            },
          },
        ],
      },
    ],
    toolbar: [],
    columns: dataColumns.map((column) => {
      return { ...omit(column, ['type']) };
    }),
    rowKey: 'id',
  };

  const getOperationList = (record: any) => {
    return [
      {
        title: '查看',
        auth: authDict.workcenter_detail,
        onClick: () => {
          history.push(toDetailWorkCenter(record.id));
        },
      },
      {
        title: '编辑',
        auth: authDict.workcenter_edit,
        onClick: () => {
          history.push(toEditWorkCenter(record.id));
        },
      },
      {
        title: '复制',
        auth: authDict.workcenter_add,
        onClick: () => {
          history.push(toCopyWorkCenter(record.id));
        },
      },
      {
        title: '操作记录',
        auth: authDict.workcenter_operrecord,
        onClick: () => {
          setDataSetId(record?.id);
          setOperationRecordVisible(true);
        },
      },
    ];
  };

  const filterList: FilterItem[] = [
    {
      label: '工作中心编号',
      name: 'code',
      type: fieldTypeList.text,
    },
    {
      label: '工作中心名称',
      name: 'name',
      type: fieldTypeList.text,
    },
    {
      label: '所属部门',
      name: 'departmentIdList',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple={false} />,
    },
    {
      label: '创建人',
      name: 'creatorId',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
    {
      label: '创建时间',
      name: 'createdAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '更新人',
      name: 'operatorId',
      type: fieldTypeList.text,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple={false} isSelectUser />
      ),
    },
    {
      label: '更新时间',
      name: 'updatedAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
  ];

  const formatDataToQuery = (data: any) => {
    const {
      createdAt = [],
      updatedAt = [],
      departmentIdList = [],
      operatorId = {},
      creatorId = {},
      sorter,
      ...resData
    } = cloneDeep(data);

    const params = {
      ...resData,
      createBeginTime: !gcArray.isEmpty(createdAt) ? moment(createdAt[0]).format('x') : undefined,
      createEndTime: !gcArray.isEmpty(createdAt) ? moment(createdAt[1]).format('x') : undefined,
      updateBeginTime: !gcArray.isEmpty(updatedAt) ? moment(updatedAt[0]).format('x') : undefined,
      updateEndTime: !gcArray.isEmpty(updatedAt) ? moment(updatedAt[1]).format('x') : undefined,
      creatorId: creatorId[0]?.value,
      operatorId: operatorId[0]?.value,
      departmentIdList: !isEmpty(departmentIdList) ? [departmentIdList[0]?.value] : undefined,
    };

    if (sorter) {
      params.sorter = map(sorter, (node: any) => {
        return {
          order: node?.order,
          field: FIELD_MAP.get(node?.field) ?? node?.field,
        };
      });
    }

    dispatch.excelImport.updateBusinessData({ businessData: params });
    return params;
  };

  const formatDataToFormDisplay = (data: any) => {
    const { createdAt, updatedAt, ...rest } = data;

    return {
      ...rest,
      createdAt: createdAt && gcTime.formatRangeTimeToMoment(createdAt),
      updatedAt: updatedAt && gcTime.formatRangeTimeToMoment(updatedAt),
    };
  };

  const formatDataToDisplay = (params: any = {}) => {
    const retData = { ...params };

    return retData;
  };

  const requestFn = async (parmas: any) => {
    const res: any = await fetchWorkCenterList(parmas);

    return res;
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        filterList={filterList}
        columns={config.columns}
        rowKey={config.rowKey}
        batchMenu={config.toolbar}
        mainMenu={config.left}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        formatDataToDisplay={formatDataToDisplay}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey) => {
          setSelectRowKeys(selectKey);
        }}
        requestFn={requestFn}
        getOperationList={getOperationList}
        // refreshMarker={refreshMarker}
        userAuth={getAuthFromLocalStorage()}
      />
      {operationRecordVisible && (
        <SingleOperationLogComponent
          visible={operationRecordVisible}
          instanceId={datasetId}
          objectCode={'WorkCenter'}
          closeDetail={() => {
            setOperationRecordVisible(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(MaterialPropertyList);
