/**
 * @component SOP自定义函数详情展示控件
 */

import { Button, Modal } from 'antd';
import { FC, useState } from 'react';
import { fetchFormulaDetail } from 'src/api/ytt/formula-domain/calculationFormula';
import { FormulaInfo } from '../../types';

type ModalInfo = {
  visible: boolean;
  [x: string]: any;
};

const FormulaDetailModal: FC<FormulaInfo> = ({ linkText, label, formulaId }) => {
  const [modalInfo, setModalInfo] = useState<ModalInfo>({ visible: false });

  const renderModalOfNumber = () => {
    return (
      <span>
        {label && <span>{label} -</span>}
        <a
          type="link"
          onClick={async () => {
            try {
              const res = await fetchFormulaDetail({ id: formulaId });

              if (res?.code === 200) {
                setModalInfo({
                  visible: true,
                  func: res?.data?.text,
                });
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {linkText}
        </a>
      </span>
    );
  };

  return (
    <>
      {renderModalOfNumber()}

      <Modal
        visible={modalInfo?.visible}
        destroyOnClose
        title="自定义函数值表达式"
        centered
        onCancel={() => {
          setModalInfo({ ...modalInfo, ...{ visible: false } });
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => setModalInfo({ ...modalInfo, ...{ visible: false } })}
          >
            关闭
          </Button>,
        ]}
      >
        <span>{modalInfo?.func}</span>
      </Modal>
    </>
  );
};

export default FormulaDetailModal;
