import React from 'react';
import { VIEW_MODAL } from './constant';
import { TabList } from 'src/components';
import TriggerRulesList from './triggerRulesList';
import UnTriggerRulesList from './UnTriggerRulesList';

const NoticeTemplateList = () => {
  const tabList = [
    {
      tab: '触发规则类',
      key: VIEW_MODAL.triggerRules,
      component: <TriggerRulesList />,
    },
    {
      tab: '非触发规则类',
      key: VIEW_MODAL.unTriggerRules,
      component: <UnTriggerRulesList />,
    },
  ];

  return <TabList tabList={tabList} defaultKey={VIEW_MODAL.triggerRules} />;
};

export default NoticeTemplateList;
