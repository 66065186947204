const color = {
  // 相邻色
  navBlack: '#00140E',
  deepGreen: '#002F21',
  oliveGreen: '#036004',
  blacklakeGreen: '#02B980',
  ganttBlacklakeGreen: '#3DD27E',
  onionGreen: '#18EBC3',
  paleGreen: '#DDF7F2',
  greyGreen: '#c0dcd3',
  lightGreen: '#E8F7F2',
  // 灰色
  grey: '#fafafa',
  darkGrey: '#1F273D',
  deepGrey: '#A9A9A9',
  middleGrey: '#8E98AE',
  sliverGrey: '#B8BFCF',
  lightGrey: '#D3D8E3',
  oldLightGrey: '#FBFBFB',
  secondaryGrey: '#9B9B9B',
  borderGrey: '#EAECF1',
  greyWhite: '#F8F9FB',
  disabledGrey: 'rgba(0,0,0,0.25)',
  formItemGrey: 'rgba(0,0,0,0.65)',
  // 辅助色
  errorRed: '#F4335B',
  alertYellow: '#ECAF2B',
  errorOrange: '#FB8E5F',
  lightRed: '#FDEEEA',
  darkBlue: '#071B2B',
  blueGrey: '#243546',
  white: '#FFF',
  black: '#000',
  blueViolet: '#4A90E2',
  // button
  buttonHoverTitle: '#B8BFCF',
  buttonHoverBorder: '#D3D8E3',
  // new rule
  primary: '#02B980',
  primaryHalf: 'rgba(2, 185, 128, 0.5)',
  processing: 'rgba(24, 145, 255, 1)',
  processingHalf: 'rgba(24, 145, 255, 0.5)',
  warning: 'rgba(250, 173, 20, 1)',
  warningHalf: 'rgba(250, 173, 20, 0.5)',
  error: '#FF3B30',
  lightError: 'rgba(245, 34, 45, 0.5)',
  important: '#000',
  title: '#000',
  content: '#5A5A5A',
  fontSub: '#9B9B9B',
  border: '#e8e8e8',
  placeholder: '#9B9B9B',
  purple: '#AD84FF',
  // 头像色值
  orange: '#FAAD14',
  khaki: '#E4BF53',
  red: '#FF5950',
  greenBrown: '#93B54D',
  grassGreen: '#3DD27E',
  darkGreen: '#00AD77',
  skyBlue: '#28C2E4',
  blueGreen: '#00B9BE',
  blue: '#1890FF',
  blackPurple: '#7482E5',
  black60: 'rgba(0,0,0, 0.6)',
  black40: 'rgba(0,0,0, 0.4)',
  black20: 'rgba(0,0,0, 0.2)',
  gray: '#979797',
};

export default color;
export const {
  navBlack,
  deepGreen,
  oliveGreen,
  blacklakeGreen,
  ganttBlacklakeGreen,
  onionGreen,
  paleGreen,
  greyGreen,
  lightGreen,
  grey,
  darkGrey,
  deepGrey,
  middleGrey,
  sliverGrey,
  lightGrey,
  oldLightGrey,
  secondaryGrey,
  borderGrey,
  greyWhite,
  disabledGrey,
  formItemGrey,
  errorRed,
  alertYellow,
  errorOrange,
  lightRed,
  darkBlue,
  blueGrey,
  white,
  black,
  blueViolet,
  buttonHoverTitle,
  buttonHoverBorder,
  primary,
  primaryHalf,
  processing,
  processingHalf,
  warning,
  warningHalf,
  error,
  lightError,
  important,
  title,
  content,
  fontSub,
  border,
  placeholder,
  purple,
  orange,
  khaki,
  red,
  greenBrown,
  grassGreen,
  darkGreen,
  skyBlue,
  blueGreen,
  blue,
  blackPurple,
  black60,
  black40,
  black20,
  gray,
} = color;
