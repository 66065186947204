import DataSetList from './dataSetManagement';
import DataSetDetail from './dataSetManagement/detail';
import DataCreatAndEdit from './dataSetManagement/createAndEdit';
import ElectronicTemplateDetail from './templateManagement/detail';
import EditElectronicTemplate from './templateManagement/edit';
import CreateElectronicTemplate from './templateManagement/create';
import List from './templateManagement';
import authDict from 'src/utils/auth';
import ObjectMatchRuleList from './objectMatchingRule/list';
import MatchRuleDetail from './objectMatchingRule/detail';
import MatchRuleCreateAndEdit from './objectMatchingRule/createAndEdit';
import CustomizedReportReview from './customizedReport/review';
import FineBIView from './fineBI/index';
import ElectronicDocumentsList from './electronicDocuments/list';
import ElectronicDocumentsDetail from './electronicDocuments/detail';

const baseUrl = '/analyse';
const datasetUrl = `${baseUrl}/dataset`;
const datasetManageUrl = `${datasetUrl}/manage`;
const customReportUrl = `${baseUrl}/customReport`;
const templateUrl = `${baseUrl}/customReport/template/list`;
const matchUrl = `${baseUrl}/customReport/matchRule/list`;
const customizedReportUrl = `${baseUrl}/customizedReport`;
const fineBIUrl = `${baseUrl}/fineBI`;
const electronicDocumentsUrl = `${baseUrl}/customReport/electronicDocuments/list`;

export const getDetailUrl = (id: number | string) => `${datasetManageUrl}/detail?id=${id}`;
export const getEditUrl = (id: number | string) => `${datasetManageUrl}/edit?id=${id}`;
export const getCopyUrl = (id: number | string) => `${datasetManageUrl}/copy?id=${id}&isCopy=true`;

export const AnalyseRoute = [
  {
    path: baseUrl,
    menu: '分析',
    breadcrumb: '分析',
    icon: 'iconyijicaidan-fenxi',
    redirectPath: datasetManageUrl,
    children: [
      {
        path: datasetUrl,
        menu: '数据集',
        children: [
          {
            path: `${datasetManageUrl}`,
            menu: '数据集',
            breadcrumb: '数据集',
            auth: authDict.dataset_view,
            component: DataSetList,
            children: [
              {
                path: `${datasetManageUrl}/detail`,
                breadcrumb: '数据集详情',
                auth: authDict.dataset_detail,
                component: DataSetDetail,
              },
              {
                path: `${datasetManageUrl}/create`,
                breadcrumb: '数据集创建',
                auth: authDict.dataset_add,
                component: DataCreatAndEdit,
              },
              {
                path: `${datasetManageUrl}/edit`,
                breadcrumb: '数据集编辑',
                auth: authDict.dataset_edit,
                component: DataCreatAndEdit,
              },
              {
                path: `${datasetManageUrl}/copy`,
                breadcrumb: '数据集复制',
                auth: authDict.dataset_add,
                component: DataCreatAndEdit,
              },
            ],
          },
        ],
      },
      {
        path: customReportUrl,
        menu: '电子单据模板',
        children: [
          {
            path: `${templateUrl}`,
            auth: authDict.ereport_view,
            menu: '电子单据模板',
            breadcrumb: '电子单据模板',
            component: List,
          },
          {
            path: `${templateUrl}/:id/detail`,
            auth: authDict.ereport_detail,
            component: ElectronicTemplateDetail,
            breadcrumb: '电子单据模板详情',
          },
          {
            path: `${templateUrl}/:id/edit`,
            auth: authDict.ereport_edit,
            component: EditElectronicTemplate,
            breadcrumb: '编辑电子单据模板',
          },
          {
            path: `${templateUrl}/create`,
            auth: authDict.ereport_add,
            component: CreateElectronicTemplate,
            breadcrumb: '新建电子单据模板',
          },
          {
            path: `${matchUrl}`,
            auth: authDict.eReportMatch_view,
            menu: '对象匹配规则',
            breadcrumb: '对象匹配规则',
            component: ObjectMatchRuleList,
          },
          {
            path: `${matchUrl}/:id/detail`,
            auth: authDict.eReportMatch_detail,
            component: MatchRuleDetail,
            breadcrumb: '对象匹配规则详情',
          },
          {
            path: `${matchUrl}/:id/edit`,
            auth: authDict.eReportMatch_edit,
            component: MatchRuleCreateAndEdit,
            breadcrumb: '编辑对象匹配规则',
          },
          {
            path: `${matchUrl}/create`,
            auth: authDict.eReportMatch_add,
            component: MatchRuleCreateAndEdit,
            breadcrumb: '新建对象匹配规则',
          },
          {
            path: `${electronicDocumentsUrl}`,
            auth: authDict.eReportCase_view,
            menu: '电子单据查询',
            breadcrumb: '电子单据查询',
            component: ElectronicDocumentsList,
          },
          {
            path: `${electronicDocumentsUrl}/:id/detail`,
            auth: authDict.eReportCase_detail,
            component: ElectronicDocumentsDetail,
            breadcrumb: '电子单据详情',
          },
        ],
      },
      {
        path: customizedReportUrl,
        menu: '定制化报表',
        children: [
          {
            path: `${customizedReportUrl}/view`,
            auth: authDict.Cus_eReport_view_custom_report,
            menu: '定制化报表',
            breadcrumb: '定制化报表',
            component: CustomizedReportReview,
          },
        ],
      },
      {
        path: fineBIUrl,
        menu: '智能分析平台',
        children: [
          {
            path: `${fineBIUrl}/view`,
            menu: '智能分析平台',
            breadcrumb: '智能分析平台',
            auth: authDict.IntelligentAnalysisPlatform_view,
            component: FineBIView,
          },
        ],
      },
    ],
  },
];
