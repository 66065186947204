import { Button, Modal, Popover, message as Message } from 'antd';
import React, { ReactNode, useState } from 'react';
import { BcUpload, BlIcon } from '../..';
import styles from '../index.module.scss';
import { FileTextOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import { fetchImportExcelImport, FetchImportExcelImportResponse } from 'src/api/ytt/import';
import { useHistory } from 'react-router-dom';
import { ImportConfigType } from 'src/dict/types';
import { BUSINESS_TYPE, EXCEL_TYPE } from 'src/dict/objImport';
import lookup from 'src/dict';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import _ from 'lodash';
import { downloadImportTemplate } from 'src/services/file';
import { TEMPLATE_TYPE } from 'src/page/import/constaint';
import { qs } from 'utils';

export interface BatchImportExcelProps {
  /** Modal标题 */
  title?: string;
  /** 是否显示 */
  visible?: boolean;
  /** 提示文件格式限制类型的文字 */
  fileExtensionLimitHint?: string;
  /** 帮助提示tab内容 */
  helpTipContentText?: string[];
  /** 关闭弹窗 */
  closeModal: () => void;
  /** 导入请求参数 */
  importParams: ImportConfigType;
}

interface ImportRequestBodyType {
  applicationName: string;
  businessType: string;
  fileId: number;
  excelType: number;
  templateFileUrl: string;
  businessData?: any;
}

/**
 * 批量导入/导入
 * @param title 弹窗标题 （非必填）
 * @param visible 是否显示 （非必填）
 * @param fileExtensionLimitHint 提示文件格式限制类型的文字 （非必填）
 * @param helpTipContentText 帮助提示tab内容 （非必填）
 * @param closeModal 关闭弹窗
 * @param importParams 导入请求参数 （必填）
 */
const BatchImportExcel: React.FC<BatchImportExcelProps> = ({
  title,
  visible,
  fileExtensionLimitHint,
  helpTipContentText,
  importParams,
  closeModal,
}) => {
  const businessData = useSelector((state: RootState) => state.excelImport.businessData);
  const [uploadedFileId, setUploadedFileId] = useState<number | null>(null);
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(null);
  const { applicationName, businessType, templateId } = importParams;
  const history = useHistory();

  /**
   * tip帮助标签内容
   */
  const helpTipContent = () => (
    <div style={{ padding: 8, backgroundColor: '#fff' }}>
      {helpTipContentText?.map((text) => (
        <div key={text}>{text}</div>
      ))}
    </div>
  );

  /**
   * 开始导入
   */
  const importFile = async () => {
    if (uploadedFileId) {
      const params: ImportRequestBodyType = {
        applicationName,
        businessType,
        businessData: {},
        fileId: uploadedFileId,
        excelType: EXCEL_TYPE.common,
        templateFileUrl: lookup('objectImport.importParams', businessType).templateId,
      };

      if (businessType === BUSINESS_TYPE.workerCalendar) {
        params.excelType = EXCEL_TYPE.special;
        params.businessData = {
          fileUrl: uploadedFileUrl,
          workerCalendarId: businessData?.workerCalendarId,
        };
      }
      const response: FetchImportExcelImportResponse = await fetchImportExcelImport(params);
      const { code, message } = response;

      if (code === 200) {
        Message.success('操作成功，可通过日志查看导入情况');
        closeModal();
      } else {
        Message.error(`导入失败：${message}`);
      }
    }
  };

  /** 文件完成上传时，取得上传文件的uri */
  const fileUploaded = (files: UploadFile[]) => {
    // 文件删除时也会触发onUploaded方法，如果文件全部删除则必须disable导入按钮
    if (files.length === 0) {
      setUploadedFileId(null);
      setUploadedFileUrl(null);
    } else {
      const { status, response } = files[0];

      if (status === 'done' && response) {
        setUploadedFileId(response.id);
        setUploadedFileUrl(response.uri);
      }
    }
  };

  /** 查看日志 */
  const linkToLogPage = () => {
    closeModal();
    const path = `${history.location.pathname}/importLog?${qs.stringify({
      applicationName,
      businessType,
    })}`;
    history.push(path);
  };

  /** 下载模板 */
  const handleDownloadTemplate = () => {
    downloadImportTemplate({ applicationName, businessType, templateType: TEMPLATE_TYPE.preset })
      .then((res) => {})
      .catch(() => {
        console.log('%c [ error ]-143', 'font-size:px; color:#bf2c9f;', '获取模板文件失败');
      });
  };

  const renderFooter = (): ReactNode => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <div>
          {businessType !== BUSINESS_TYPE.workerCalendar && (
            <Button icon={<FileTextOutlined />} onClick={handleDownloadTemplate}>
              下载模板
            </Button>
          )}
          <Button type={'link'} onClick={linkToLogPage}>
            导入日志
          </Button>
        </div>
        <Button type={'primary'} onClick={_.debounce(importFile, 300)} disabled={!uploadedFileId}>
          开始导入
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal
        title={title}
        visible={visible}
        centered
        onOk={importFile}
        onCancel={closeModal}
        okText="开始导入"
        footer={renderFooter()}
      >
        <div className={styles.toolBar}>
          <span>{`请按模板要求填写后上传`}</span>
          <div>
            <span>帮助</span>
            <Popover
              title={'帮助提示'}
              overlayStyle={{ width: 300 }}
              color={'#f2f2f2'}
              overlayClassName={styles.helpPopover}
              mouseLeaveDelay={0.3}
              content={helpTipContent}
              placement="rightTop"
            >
              <BlIcon className={styles.helpIcon} type="iconwentizhiyinbangzhu" />
            </Popover>
          </div>
        </div>
        <BcUpload
          maxSize={10}
          multiple={false}
          maxCount={1}
          limit="xlsx"
          fileExtensionLimitHint={fileExtensionLimitHint}
          onUploaded={fileUploaded}
        />
      </Modal>
    </>
  );
};

BatchImportExcel.defaultProps = {
  title: '批量导入',
  visible: true,
  helpTipContentText: [
    '- 单次最多支持导入1w条数据',
    '- 请使用纯文本或数字填写模板',
    '- 数据中的图片、文件暂不支持导入',
  ],
  fileExtensionLimitHint: '文件支持类型：XLSX，文件限制不能超过10M',
};

export default BatchImportExcel;
