// 字段转化
export const FIELD_MAP = new Map([
  ['taskCode', 'task_code'],
  ['planStartTime', 'plan_start_time'],
  ['planEndTime', 'plan_end_time'],
]);

// 生产任务枚举值
export const TASK_TYPE_TYPE = new Map([
  [1, '待执行'],
  [2, '执行中'],
  [3, '暂停中'],
  [4, '已完工'],
  [5, '已取消'],
]);

export const TASK_STATUS_MAP = {
  TO_PERFORM: 1,
  EXECUTION: 2,
  PAUSE: 3,
  FINISHED: 4,
  CANCEL: 5,
};

export const TASK_STATUS_MAP_ENUM = [
  { id: 1, name: '待执行' },
  { id: 2, name: '执行中' },
  { id: 3, name: '暂停中' },
  { id: 4, name: '已完工' },
  { id: 5, name: '已取消' },
];
