import _ from 'lodash';

import { FIELDS } from '../constants';
import LocalStorage from '../localStorage';

const networkError = {
  signNeeded: 'DIGITAL_SIGNATURE_NEEDED',
  signIncomplete: 'USER-DOMAIN/DIGITAL_SIGNATURE_INCOMPLETE',
};

export const signNeeded = (error: any) => {
  const code = _.get(error, 'response.data.code', null);

  return code === networkError.signNeeded;
};

export const getOrgId = () => {
  let orgId = '';
  const user = LocalStorage.get(FIELDS.USER);

  orgId = _.get(user, 'orgId', '');
  return orgId;
};

export const needSignature = (res: Response) => {
  const subCode = _.get(res, 'data.subCode');

  return subCode === networkError.signIncomplete;
};

// export const sign = () => {
//   return new Promise((resolve, reject) => {
//     openModal({
//       title: '电子签名',
//       footer: null,
//       children: <ESignForm onConfirm={value => onSubmit(value, resolve)} onCancel={() => onCancel(reject)} />,
//       width: 500,
//       onCancel: () => onCancel(reject),
//     });
//   });
// };
