import { compose, get, identity, join, map } from 'lodash/fp';
import { TargetType, FieldType, ObjectCategory } from 'src/dict/common';
import {
  renderUser,
  renderUserArray,
  renderDept,
  renderDepartmentArray,
  renderRoleArray,
  renderMultiChoiceRefrence,
  singleRefrence,
} from 'src/page/share/renderUser';
import { getDateFormatter, valueOrHolder } from 'src/utils/formatters';
import { renderTextPreLine, renderMultiSelect, renderBool, renderUrl } from './common';
import type { FieldDTO } from '../types';
import { BcAttachmentDetail } from 'src/components';

interface Options {
  /** 是否在表格中 */
  isInTable?: boolean;
}

/**
 * 字段展示效果的渲染方法，用于列表、详情页。不包括从对象
 */
export const getDisplayNodeRenderer = (
  field: FieldDTO,
  options: Options = { isInTable: false },
) => {
  const { targetType, fieldType, relatedObjectCategory, reference, datetimeFormat } = field;
  const { isInTable } = options;
  const isMultipleRelation = targetType === TargetType.multiChoice;

  switch (fieldType) {
    case FieldType.textArea:
      return isInTable ? valueOrHolder : renderTextPreLine;
    case FieldType.date:
      return getDateFormatter(datetimeFormat!);
    case FieldType.select:
    case FieldType.multiSelect:
      return renderMultiSelect;
    case FieldType.boolean:
      return renderBool;
    case FieldType.url:
      return renderUrl;
    case FieldType.relation:
      // 自定义对象, 取主属性值
      if (relatedObjectCategory === ObjectCategory.customObject) {
        return valueOrHolder(
          isMultipleRelation ? compose(join('，'), map('mainProperty')) : get('mainProperty'),
        );
      }
      switch (reference) {
        case 'User':
          return isMultipleRelation ? renderUserArray : renderUser;
        case 'Department':
          return isMultipleRelation ? renderDepartmentArray : renderDept;
        case 'Role':
          // TODO:
          return isMultipleRelation ? renderRoleArray : identity;
        case 'Document':
          return (value: any[]) => {
            if (!value) return '-';
            if (isInTable) {
              return value?.map((v) => v?.mainProperty).join('、');
            }
            return <BcAttachmentDetail fileIds={value?.map((v) => v?.id)} />;
          };
      }
      return isMultipleRelation ? renderMultiChoiceRefrence : singleRefrence;
    // TODO: 其他标准关联对象如何展示？
    case FieldType.appendix:
      return (value: any[]) => {
        if (!value) return '-';
        if (isInTable) {
          return value?.map((v) => decodeURIComponent(v?.mainProperty || '')).join('、');
        }
        return <BcAttachmentDetail fileIds={value?.map((v) => v?.id)} />;
      };
    default:
      return valueOrHolder;
  }
};
