import React from 'react';

type Props = {
  title: string;
};

const Title = (props: Props) => {
  const { title } = props;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: 56,
        backgroundColor: '#fafafa',
        paddingLeft: 24,
        marginBottom: 24,
      }}
    >
      <div style={{ height: 16, width: 2, backgroundColor: '#02b980', marginRight: 8 }} />
      <span style={{ fontSize: 16, fontWeight: 500, color: '#262626' }}>{title}</span>
    </div>
  );
};

export default Title;
