import { useRef, useEffect, useState } from 'react';
import { Form, Input, message, Select, Checkbox, Radio } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { checkTwoSidesTrim, textValidator2 } from 'src/utils/formValidators';
import { Turn, YN, UsageStatus } from 'src/dict/common';
import { usageStatus, yn } from 'src/dict/common/mappings';
import { MaterialSelect, SearchSelect } from 'components';
import { ProcessRoutingForm } from './components';
import DepartmentSelect from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import {
  fetchProcessRouteAdd,
  fetchProcessRouteDetail,
  FetchProcessRouteDetailResponse,
  fetchProcessRouteUpdate,
} from 'src/api/ytt/med-domain/process-route';
import {
  getMaterialUnits,
  getMaterialAttrs,
  getMaterialMainUnit,
  getMaterialProduceUnit,
} from 'src/entity/material';
import { appEnum } from 'src/dict';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import {
  formatterProcess,
  formatterProcessApi,
  formatterProcessConnApi,
  formatterProcessConnections,
  getDefaultProcessManufacturedGoods,
  getPageMode,
} from './utils';
import { gotoRoutingList } from './navigation';
import { PageModeEnum } from './constant';
import { ENGINEER_PATH } from '../navigation';

interface Props extends RouteComponentProps {}
interface Params {
  id?: number;
}

export default function ProcessRoutingAction(props: Props) {
  const { userInfo } = useSelector((state: RootState) => state?.user, shallowEqual);
  const [form] = Form.useForm();
  const { history, match } = props;
  const params: Params = match.params;
  const id = Number(params.id);
  const pageInfo = getPageMode(history.location.pathname);
  const isEditMode = pageInfo.mode === PageModeEnum.edit;
  const isCopyMode = pageInfo.mode === PageModeEnum.copy;
  const pageTitle = pageInfo.title;
  const [keepOn, setKeepOn] = useState(false);
  const processRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const getMaterialInfo = () => {
    const value = form.getFieldValue('materialId')?.value;

    return value ? JSON.parse(value) : {};
  };
  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,
    title: '基本信息',
    items: _.compact([
      {
        label: '工艺路线编码:',
        name: 'code',
        validateFirst: true,
        rules: [
          { required: true, message: '工艺路线编码不能为空' },
          { max: 255, message: '不超过255个字符' },
          { validator: checkTwoSidesTrim },
          { validator: textValidator2 },
        ],
        render: () => <Input placeholder="请输入" />,
      },
      {
        label: '工艺路线名称:',
        name: 'name',
        validateFirst: true,
        rules: [
          { required: true, message: '工艺路线名称不能为空' },
          { max: 255, message: '不超过255个字符' },
          { validator: checkTwoSidesTrim },
        ],
        render: () => <Input placeholder="请输入" />,
      },
      {
        label: '状态:',
        name: 'status',
        initialValue: Turn.off,
        render: () => (
          <Radio.Group disabled>
            {usageStatus.map(({ label, value }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        ),
      },
      {
        label: '所属部门:',
        name: 'departmentId',
        render: () => <DepartmentSelect placeholder="请选择" isMultiple={false} />,
      },
      {
        label: '物料编号:',
        name: 'materialId',
        rules: [{ required: true, message: '请选择' }],
        render: () => (
          <MaterialSelect
            onChange={(item: any) => {
              const valueJSON = item?.value ? JSON.parse(item.value) : {};
              const produceUnit = getMaterialProduceUnit(valueJSON);
              const attrs = getMaterialAttrs(valueJSON);

              form.setFields([
                {
                  name: 'materialName',
                  value: valueJSON.baseInfo?.name,
                },
                {
                  name: 'materialStyle',
                  value: attrs,
                },
                {
                  name: 'materialCateg',
                  value: valueJSON.category?.name || '',
                },
                {
                  name: 'materialSpecification',
                  value: valueJSON.baseInfo?.specification || '',
                },
                {
                  name: 'unitId',
                  value: produceUnit,
                },
              ]);
            }}
            params={{
              bizType: [appEnum.Material.BizType.default],
              bizRange: [appEnum.Material.BizRange.production],
              enableFlag: UsageStatus.enabled,
            }}
          />
          // <SearchSelect
          //   labelInValue

          //   fetchType="materialCompleteInfo"
          // />
        ),
      },
      {
        label: '物料名称:',
        name: 'materialName',
        render: () => <Input placeholder="-" disabled />,
      },
      {
        label: '物料属性:',
        name: 'materialStyle',
        render: () => (
          <Select
            mode="multiple"
            labelInValue
            disabled
            placeholder="-"
            options={getMaterialAttrs(getMaterialInfo())}
          />
        ),
      },
      {
        label: '物料分类:',
        name: 'materialCateg',
        render: () => <Input placeholder="-" disabled />,
      },
      {
        label: '物料规格:',
        name: 'materialSpecification',
        render: () => <Input placeholder="-" disabled />,
      },
      {
        label: '单位:',
        dependencies: ['materialId'],
        rules: [{ required: true, message: '请选择' }],
        render: () => () => {
          return (
            <Form.Item
              rules={[{ required: true, message: '请选择' }]}
              style={{ marginBottom: 0 }}
              name="unitId"
            >
              <Select
                labelInValue
                placeholder={'请选择'}
                options={getMaterialUnits(getMaterialInfo())}
              />
            </Form.Item>
          );
        },
      },
      {
        label: '启用SOP:',
        name: 'enableSop',
        rules: [{ required: true, message: '请选择' }],
        initialValue: YN.no,
        render: () => (
          <Radio.Group
            onChange={(e) => {
              // 启用sop后，不能填报告模版，需要删除所有工序下的报告模版值
              const list = form.getFieldValue(['process', 'processes']);

              if (e.target.value) {
                form.setFieldsValue({
                  process: {
                    processes: list.map((item: any) => ({ ..._.omit(item, 'reportIds') })),
                  },
                });
              }
            }}
          >
            {yn.map(({ label, value }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        ),
      },
      {
        label: '备注:',
        name: 'remark',
        rules: [{ max: 1000, message: '不超过1000个字符' }],
        render: () => <Input.TextArea maxLength={1000} showCount placeholder="请输入" />,
      },
      isEditMode && {
        label: '编辑原因',
        name: 'reason',
        rules: [{ max: 1000, message: '不超过1000个字符' }],
        render: () => <Input.TextArea maxLength={1000} showCount placeholder="请输入" />,
      },
    ]),
  };
  const processInfo: DataFormLayoutInfoBlock = {
    title: '工序信息',
    items: [
      {
        label: '',
        isFullLine: true,
        name: 'process',
        ...(!isEditMode
          ? {
              initialValue: {
                processes: [{ processSeq: 1, processNum: '10' }],
              },
            }
          : {}),
        render: () => {
          return (
            <ProcessRoutingForm
              ref={processRef}
              name="process"
              form={form}
              isEditMode={isEditMode}
            />
          );
        },
      },
    ],
  };

  const getProcess = (data: FetchProcessRouteDetailResponse['data']) => {
    const { processConnections = [], processes = [] } = data || {};

    if (isCopyMode) {
      // 复制需要将在制品设置成默认值
      return {
        processes: formatterProcess(processes?.map((item) => _.omit(item, ['id']))),
        processConnections: processConnections?.map((item) => ({
          ..._.omit(item, ['id']),
          processManufacturedGoods: getDefaultProcessManufacturedGoods(),
        })),
      };
    }
    return {
      processes: formatterProcess(processes),
      processConnections: formatterProcessConnections(processConnections, true),
    };
  };

  const getDetail = async () => {
    setLoading(true);
    fetchProcessRouteDetail({ id: Number(id) })
      .then((res) => {
        const { data } = res;

        if (data) {
          const { department } = data;
          const options = {
            ..._.omit(data, ['unit', 'departmentId', 'processes', 'processConnections']),
            ...(department
              ? { departmentId: [{ label: department.name, value: department.id }] }
              : {}),
            materialId: {
              label: data?.material?.baseInfo?.code,
              value: JSON.stringify(data.material),
            },
            materialName: data?.material?.baseInfo?.name,
            materialCateg: data?.material?.category?.name,
            materialStyle: getMaterialAttrs(data?.material),
            materialSpecification: data?.material?.baseInfo?.specification,
            unitId: {
              label: data.unit?.name,
              value: data.unit?.id,
            },
            status: isCopyMode ? Turn.off : data.status, // 复制的状态需要默认停用
            process: getProcess(data),
          };

          form?.setFieldsValue(options);
        }
      })
      .finally(() => setLoading(false));
  };

  const onCancel = () => {
    if (isEditMode) {
      history.goBack();
    } else {
      gotoRoutingList();
    }
  };
  const onFinish = async () => {
    const values = await form.validateFields();
    const { process } = values;

    if (process?.processes?.length > 200) {
      message.error('一个工艺路线最多支持200个工序！');
      return;
    }
    if (_.find(process?.processes, (o) => !o.processId)) {
      message.error('请填写完整信息！');
      return;
    }
    const { departmentId, materialId } = values;

    const params = _.omit(values, [
      'node',
      'edge',
      'process',
      'materialCateg',
      'materialName',
      'materialStyle',
      'departmentId',
      'materialSpecification',
    ]) as any;

    if (departmentId instanceof Array && departmentId.length) {
      params.departmentId = _.head(departmentId).value;
    }
    if (typeof materialId?.value === 'string') {
      params.materialId = JSON.parse(materialId.value).baseInfo?.id;
    }

    const options = {
      ...params,
      unitId: params.unitId?.value,
      processes: formatterProcessApi(process?.processes),
      processConnections: formatterProcessConnApi(process?.processConnections, true),
    };

    const res = await (isEditMode
      ? fetchProcessRouteUpdate({ id, ...options })
      : fetchProcessRouteAdd(options));

    if (res && res.code === 200) {
      message.success('操作成功');
      if (keepOn) {
        form.resetFields();
        return;
      }
      if (isCopyMode) {
        history.push(`${ENGINEER_PATH}/routing`);
      } else {
        onCancel();
      }
    }
  };
  const initCreate = () => {
    // 创建时给默认用户所在部门
    const defaultDepartment: any = _.first(userInfo.departmentVOList);

    if (defaultDepartment) {
      form.setFieldsValue({
        departmentId: [
          {
            label: defaultDepartment.name,
            value: defaultDepartment.id,
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (id) {
      getDetail();
      return;
    }
    initCreate();
  }, [id]);

  return (
    <DataFormLayout
      loading={loading}
      form={form}
      title={`${pageTitle}工艺路线`}
      info={[baseInfo, processInfo]}
      extra={
        !isEditMode && (
          <Checkbox
            onChange={() => {
              setKeepOn(!keepOn);
            }}
            defaultChecked={keepOn}
          >
            连续新建
          </Checkbox>
        )
      }
      onCancel={onCancel}
      onFinish={onFinish}
      bodyClassName="process-routing-action-form-body"
    />
  );
}
