import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import { replaceSign } from 'src/utils/constants';
import {
  fetchResourcesLabelGet,
  FetchResourcesLabelGetResponse,
} from 'src/api/ytt/resource-domain/resourceTag';
import _ from 'lodash';
import lookup from 'src/dict';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import authDict from 'src/utils/auth';
import _Time from 'src/utils/dataTypes/time';

interface Props {
  id: number;
  showEdit: () => void;
  // showOperationRecord: () => void;
  refreshDetailsMarker: number | undefined;
  // refreshTable?: () => void;
  // onCancel?: () => void;
}

const DetailsBaseInfo = (props: Props) => {
  const {
    id: detailId,
    showEdit,
    // showOperationRecord,
    refreshDetailsMarker,
    // refreshTable,
    // onCancel,
  } = props;
  const [dataSource, setDataSource] = useState<FetchResourcesLabelGetResponse>();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);

  const init = async () => {
    try {
      const res: any = await fetchResourcesLabelGet({ groupId: detailId });
      const { data } = res;

      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };

  // const handleFinish = async (id: number | number[]) => {
  //   try {
  //     const value = await delForm?.validateFields();
  //     const ids = typeof id === 'number' ? [id] : id;
  //     const { code, message: mes } = await fetchMaterialAttributeDelete({
  //       ids,
  //       reason: value.delReason,
  //     });

  //     if (code === 200) {
  //       message.success('删除成功');
  //       // 关闭details
  //       typeof onCancel === 'function' && onCancel();
  //       // 刷新 list
  //       typeof refreshTable === 'function' && refreshTable();
  //       return;
  //     }
  //     message.error(mes);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const initBaseMenu = useCallback(
    () =>
      _.compact([
        // {
        //   key: 'delete',
        //   onClick: () => {
        //     confirm('是否删除当前单位', delForm, () => {
        //       handleFinish(detailId);
        //     });
        //   },
        //   // icon: <<BlIcon type="iconshanchulajitong1" /> />,
        //   title: '删除',
        // },
        {
          key: 'record',
          auth: authDict.resourcelabel_operrecord,
          onClick: () => {
            setVisibleLog(true);
          },
          // icon: <FileTextOutlined />,
          title: '操作记录',
        },
        {
          key: 'edit',
          auth: authDict.resourcelabel_edit,
          onClick: () => {
            showEdit();
          },
          icon: <BlIcon type="iconbianji" />,
          title: '编辑',
        },
      ]),
    [detailId],
  );

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '标签项',
        dataIndex: 'name',
        isFullLine: true,
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '业务类型',
        dataIndex: 'businessType',
        isFullLine: true,
        render: (value: number) => lookup('resources.resourceTagBusinessType', value),
      },
      {
        label: '标签值列表',
        dataIndex: 'children',
        isFullLine: true,
        render: (list: any) => {
          // return list;
          return (
            <Table
              style={{ width: '100%' }}
              pagination={false}
              key={'id'}
              columns={[
                {
                  title: '标签值',
                  key: 'content',
                  dataIndex: 'content',
                },
              ]}
              dataSource={list}
            />
          );
        },
      },
      {
        label: '备注',
        dataIndex: 'remarks',
        isFullLine: true,
        render: (text: string) => text ?? replaceSign,
      },
    ],
    column: 1,
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: ['creator', 'name'],
        render: (name: string) => name || replaceSign,
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: (createdAt: Date) => {
          if (!createdAt) return replaceSign;
          return _Time.format(createdAt);
        },
      },
      {
        label: '更新人',
        dataIndex: ['operator', 'name'],
        render: (name: string) => name || replaceSign,
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: (updatedAt: Date) => {
          if (!updatedAt) return replaceSign;
          return _Time.format(updatedAt);
        },
      },
    ],
    column: 2,
  };
  const infoArray = [baseInfo, otherInfo];

  useEffect(() => {
    if (detailId) {
      init();
    }
  }, [refreshDetailsMarker]);
  return (
    <>
      <DetailLayout
        title={'标签详情'}
        baseMenu={initBaseMenu()}
        info={infoArray}
        dataSource={dataSource}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId as number}
          objectCode={'ResourceLabel'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailsBaseInfo;
