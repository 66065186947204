import { Space } from 'antd';
import { BlTable } from 'src/components';
import { CreatorData } from 'src/page/knowledgeManagement/calendarManagement/shift/interface';
import { replaceSign } from 'src/utils/constants';
import styles from './style.module.scss';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import _Time from 'src/utils/dataTypes/time';
import { OPERATION_TERMINAL_TYPE, toDetail } from '../constant';
import { TableRowType } from '../interface';
import _Array from 'src/utils/dataTypes/array';

interface SubObjectInfoProps {
  data: TableRowType[];
}

export default function SubObjectInfo({ data }: SubObjectInfoProps) {
  const subObjectListColumns = [
    {
      title: '从对象名称',
      dataIndex: 'objectDisplayName',
      width: 200,
      render: (objectDisplayName: string) => objectDisplayName ?? replaceSign,
    },
    {
      title: '从对象实例ID',
      dataIndex: 'instanceId',
      width: 200,
      render: (instanceId: number) => instanceId ?? replaceSign,
    },
    {
      title: '操作人',
      dataIndex: 'operator',
      width: 200,
      render: (operator: CreatorData) => {
        return (
          <AvatarDisplay
            id={operator?.id}
            name={operator?.name}
            avatarUrl={operator?.avatarUrl}
            key={operator?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '操作时间',
      dataIndex: 'loggedAt',
      width: 200,
      render: (loggedAt: Date) => {
        if (!loggedAt) return replaceSign;
        return _Time.format(loggedAt);
      },
    },
    {
      title: '操作类型',
      dataIndex: 'operationName',
      width: 200,
    },
    {
      title: '操作终端',
      dataIndex: 'sourceType',
      width: 200,
      render: (sourceType: number) => OPERATION_TERMINAL_TYPE.get(sourceType) ?? replaceSign,
    },
    {
      title: '操作原因',
      dataIndex: 'operationReason',
      width: 200,
      render: (operationReason: string) => operationReason ?? replaceSign,
    },
    {
      title: '电子签名',
      dataIndex: 'signList',
      width: 200,
      render: (signList: CreatorData[]) => {
        if (_Array.isEmpty(signList)) return replaceSign;
        return (
          <Space>
            {signList?.map((manager: CreatorData) => (
              <AvatarDisplay
                id={manager.id}
                name={manager.name}
                avatarUrl={manager.avatarUrl}
                key={manager.id}
                isShowTag={false}
                isUser
              />
            ))}
          </Space>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 150,
      fixed: 'right',
      render: (_: any, record: TableRowType) => {
        return (
          <>
            <Space>
              <a
                onClick={() => {
                  console.log('record?.logId', record?.logId);
                  window.open(toDetail(record?.logId));
                }}
              >
                查看
              </a>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <div style={{ paddingLeft: 24, paddingRight: 24 }}>
      {data?.map((node: any) => {
        return (
          <div className={styles?.subObject}>
            <h5>{`从对象-${node?.[0]?.objectDisplayName}日志`}</h5>
            <BlTable
              columns={subObjectListColumns}
              dataSource={node}
              pagination={false}
              rowKey={(record) => record?.logId}
              scroll={{ x: 600, y: 560 }}
            />
          </div>
        );
      })}
    </div>
  );
}
