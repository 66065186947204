import React, { useEffect, useState } from 'react';
import { Avatar, Tag, message } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import { getDepartmentDetail } from 'src/services/organization/userManage';
import { fetchDepartmentActiveDepartment } from 'src/api/ytt/user-domain/user';
import { avatarStyle } from '../constants';
import { DepartmentActionModal } from './createAndEdit';
import { BlIcon, SingleOperationLogComponent } from 'components';
import authDict from 'src/utils/auth';
import lookup from 'src/dict';

const DepartmentDetail = () => {
  const [dataSource, setDataSource] = useState<any>({});
  const [showDepartmentModal, setShowDepartmentModal] = useState<boolean>(false);
  const [detailId, setDetailId] = useState<number>(0);
  const [departmentValues, setDepartmentValues] = useState<any>({});
  const [actionType, setActionType] = useState<string>('');
  const [visibleLog, setVisibleLog] = useState(false);
  const [refreshMaker, setRefreshMaker] = useState<number>(Math.random() * 100 + Date.now());

  const getUrlParams = (name: string) => {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i'); // 定义正则表达式
    const r = window.location.search.substr(1).match(reg);

    if (r != null) return unescape(r[2]);
    return null;
  };
  const id = Number(getUrlParams('id'));
  const fetchData = async () => {
    try {
      const { data: detailData } = await getDepartmentDetail(id);

      setDataSource(detailData);
    } catch (err) {
      console.log(err);
    }
  };

  const baseMenu = [
    {
      key: 'edit',
      title: '编辑',
      onClick: () => {
        setShowDepartmentModal(true);
        setActionType('edit');
        setDetailId(dataSource.id);
        setDepartmentValues({
          parentId: dataSource.key,
          parentDepartment: {
            id: dataSource.key,
            name: dataSource.title,
          },
        });
      },
      icon: <BlIcon type="iconbianji" />,
      auth: authDict.department_edit,
    },
    ...(dataSource.supportActive
      ? [
          {
            key: 'able',
            title: lookup('user.departmentStatus', Number(!dataSource.active.code)),
            auth: authDict.department_enable_disable,
            onClick: () => {
              fetchDepartmentActiveDepartment({
                id: dataSource.id,
                active: Number(!dataSource.active.code),
              }).then((res) => {
                if (res.code === 200) {
                  setRefreshMaker(Math.random() * 100 + Date.now());
                  message.success(
                    `${lookup('user.departmentStatus', Number(!dataSource.active.code))}成功`,
                  );
                }
              });
            },
          },
        ]
      : []),
    {
      key: 'operate',
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    // {
    //   key: 'delete',
    //   onClick: () => {
    //     deleteDepartment(id).then((res) => {
    //       if (res.code === 200) {
    //         message.success('删除成功');
    //       } else {
    //         message.error(res.message);
    //       }
    //     });
    //   },
    //   title: '删除',
    // },
  ];

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      { label: '部门编号', dataIndex: 'code' },
      { label: '部门名称', dataIndex: 'name' },
      {
        label: '上级部门',
        dataIndex: 'parentDepartment',
        render: (parentDepartment: any) => {
          if (!parentDepartment) {
            return '-';
          }
          return (
            <Tag>
              <Avatar icon={<BlIcon type="iconzuzhijiagou" />} size={16} style={avatarStyle} />{' '}
              {parentDepartment?.name}
            </Tag>
          );
        },
      },
      {
        label: '负责人',
        dataIndex: 'owner',
        render: (owner: any) => {
          if (!owner) {
            return '-';
          }
          return (
            <Tag>
              {owner?.avatarUrl ? (
                <Avatar size={18} src={owner.avatarUrl} />
              ) : (
                <Avatar icon={<BlIcon type="icongeren" />} size={16} style={avatarStyle} />
              )}{' '}
              {owner?.name}
            </Tag>
          );
        },
      },
      {
        label: '备注',
        dataIndex: 'desc',
        render: (text: string) => text || '-',
      },
    ],
    column: 2,
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: any) => {
          if (!creator) {
            return '-';
          }
          return (
            <Tag>
              {creator?.avatarUrl ? (
                <Avatar size={18} src={creator.avatarUrl} />
              ) : (
                <Avatar icon={<BlIcon type="icongeren" />} size={16} style={avatarStyle} />
              )}
              {creator?.name}
            </Tag>
          );
        },
      },
      { label: '创建时间', dataIndex: 'createdAt' },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: any) => {
          if (!operator) {
            return '-';
          }
          return (
            <Tag>
              {operator.avatarUrl ? (
                <Avatar size={16} src={operator.avatarUrl} />
              ) : (
                <Avatar icon={<BlIcon type="icongeren" />} size={18} style={avatarStyle} />
              )}
              {operator?.name}
            </Tag>
          );
        },
      },
      { label: '更新时间', dataIndex: 'updatedAt', render: (text: string) => text ?? '-' },
    ],
    column: 2,
  };

  useEffect(() => {
    fetchData();
  }, [refreshMaker]);

  return (
    <DetailLayout
      baseMenu={baseMenu}
      title={'部门详情'}
      info={[baseInfo, otherInfo]}
      dataSource={dataSource}
    >
      {showDepartmentModal && (
        <DepartmentActionModal
          visible={showDepartmentModal}
          id={detailId}
          onClose={() => {
            setShowDepartmentModal(false);
            setActionType('');
            setDetailId(0);
            fetchData();
          }}
          actionType={actionType}
          defaultValues={departmentValues}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={id}
          objectCode={'Department'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </DetailLayout>
  );
};

export default DepartmentDetail;
