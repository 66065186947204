import React, { useEffect } from 'react';
import { DataFormLayout } from 'layout';
import { FormInstance, Input, Select } from 'antd';
import { DetailType, traceabilityOptions } from '../interface';
import { textValidator1, textValidator2, withoutSpaceBothSides } from 'src/utils/formValidators';
import SearchSelect from 'src/components/searchSelect';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import { BcAttachmentForm } from 'src/components';
import { TraceabilityType } from 'src/dict/process';

const { TextArea } = Input;

interface baseInfoProps {
  onCancel: () => void;
  onFinish: () => void;
  title: string;
  initialData?: DetailType;
  form: FormInstance;
  extra?: any;
  edit: boolean;
}

const CreateAndEditBaseInfo = (props: baseInfoProps) => {
  const { onCancel, title, onFinish, initialData, form, extra, edit } = props;

  useEffect(() => {
    if (initialData) {
      form?.setFieldsValue({
        name: initialData?.name,
        code: initialData?.code,
        workCenterId: {
          key: initialData?.workCenterId,
          label: initialData?.workCenterName,
        },
        department:
          initialData?.department || initialData?.department?.active
            ? [
                {
                  key: initialData?.department?.id,
                  label: initialData?.department?.name,
                },
              ]
            : [],
        description: initialData?.description,
        fileIds: initialData?.files,
        traceRelationship: initialData?.configurationItems?.traceRelationship,
      });
    }
  }, [initialData]);

  const baseInfo = [
    {
      title: '基本信息',
      items: [
        {
          label: '工序编号',
          name: 'code',
          rules: [
            { required: true, message: '工序编号必填' },
            { max: 255, message: '不可超过255个字符' },
            { validator: textValidator2 },
          ],
          render: () => <Input placeholder="请输入" allowClear />,
        },
        {
          label: '工序名称',
          name: 'name',
          rules: [
            { required: true, message: '工序名称必填' },
            { max: 255, message: '不可超过255个字符' },
            { validator: textValidator1 },
            withoutSpaceBothSides,
          ],
          render: () => <Input placeholder="请输入" allowClear />,
        },
        {
          label: '所属部门',
          name: 'department',
          render: () => <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple={false} />,
        },
      ],
    },
    {
      title: '默认信息',
      items: [
        {
          label: '工作中心',
          name: 'workCenterId',
          rules: [{ required: true, message: '工作中心必选' }],
          render: () => (
            <SearchSelect fetchType="workCenter" style={{ width: '100%' }} labelInValue />
          ),
        },
        {
          label: '工序说明',
          name: 'description',
          rules: [{ max: 1000, message: '不可超过1000个字符' }, { validator: textValidator1 }],
          render: () => <TextArea placeholder="请输入" />,
        },
        {
          label: '文件',
          name: 'fileIds',
          render: () => <BcAttachmentForm form={form} />,
        },
        {
          label: '编辑原因',
          hidden: !edit,
          name: 'operateReason',
          rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
          render: () => <TextArea placeholder="非必填" />,
        },
      ],
    },
    {
      title: '业务配置',
      items: [
        {
          label: '用料追溯关系',
          name: 'traceRelationship',
          initialValue: TraceabilityType.SingleAutomatic,
          rules: [{ required: true, message: '用料追溯关系必选' }],
          render: () => (
            <Select>
              {traceabilityOptions?.map((item: any) => {
                return (
                  <Select.Option value={item?.value} key={item?.value}>
                    {item?.label}
                  </Select.Option>
                );
              })}
            </Select>
          ),
        },
      ],
    },
  ];

  return (
    <DataFormLayout
      formLayout="vertical"
      form={form}
      title={title}
      info={baseInfo}
      extra={extra || ''}
      onCancel={onCancel}
      onFinish={onFinish}
    />
  );
};

export default CreateAndEditBaseInfo;
