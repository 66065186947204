import React from 'react';
import { DetailLayout, DetailLayoutMenuItem } from 'layout';
import { Space } from 'antd';
import { replaceSign } from 'src/utils/constants';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { CreatorData } from 'src/page/knowledgeManagement/calendarManagement/shift/interface';
import { detailBaseInfo, renderDetailPurchaseInfo, orderDetailColumn } from '../components/columns';
import { BlTable } from 'src/components';
import _ from 'lodash';
import _Time from 'src/utils/dataTypes/time';
import { getAuthFromLocalStorage } from 'src/utils/auth';

interface DetailBaseInfoProps {
  detail: CustomerDeliveryScheduleNoteDetailProps;
  loading: boolean;
  baseMenu?: DetailLayoutMenuItem[];
}

export const DetailBaseInfo = ({ detail, baseMenu, loading }: DetailBaseInfoProps) => {
  /**
   *  明细行 Column
   */
  const columnDetail = [...orderDetailColumn];

  const baseInfo = _.compact([
    {
      title: '基本信息',
      items: detailBaseInfo,
    },
    {
      title: '协同信息',
      items: renderDetailPurchaseInfo(detail),
    },
    {
      title: '交货计划明细',
      items: [
        {
          label: '',
          dataIndex: 'table',
          render: () => {
            return (
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                }}
              >
                <BlTable
                  columns={columnDetail}
                  scroll={{ x: 1200, y: 800 }}
                  dataSource={detail?.items}
                  style={{ width: '100%' }}
                  rowKey={(record) => record?.id!}
                />
              </div>
            );
          },
        },
      ],
    },
    {
      title: '其他信息',
      items: [
        {
          label: '创建人',
          dataIndex: 'creator',
          render: (creator: CreatorData) => {
            return (
              <Space>
                <AvatarDisplay
                  id={creator?.id}
                  name={creator?.name}
                  avatarUrl={creator?.avatarUrl}
                  key={creator?.id}
                  isShowTag={false}
                  isUser
                />
              </Space>
            );
          },
        },
        {
          label: '创建时间',
          dataIndex: 'createdAt',
          render: (createdAt: Date) => {
            if (!createdAt) return replaceSign;
            return _Time.format(createdAt);
          },
        },
        {
          label: '',
          dataIndex: '',
          render: () => '',
        },
        {
          label: '更新人',
          dataIndex: 'operator',
          render: (operator: CreatorData) => {
            return (
              <Space>
                <AvatarDisplay
                  id={operator?.id}
                  name={operator?.name}
                  avatarUrl={operator?.avatarUrl}
                  key={operator?.id}
                  isShowTag={false}
                  isUser
                />
              </Space>
            );
          },
        },
        {
          label: '更新时间',
          dataIndex: 'updatedAt',
          render: (updatedAt: Date) => {
            if (!updatedAt) return replaceSign;
            return _Time.format(updatedAt);
          },
        },
      ],
    },
  ]);

  return (
    <DetailLayout
      userAuth={getAuthFromLocalStorage()}
      title="详情"
      info={baseInfo}
      dataSource={detail}
      baseMenu={baseMenu}
      loading={loading}
    />
  );
};
