import React, { useCallback, useState } from 'react';
import { DatePicker, Form, Input, Select, Tabs } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';
import moment from 'moment';
import { middleGrey } from 'src/styles/color';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import AvatarUpload from 'src/components/avatar/upload';
import { checkTwoSidesTrim, numberAlphabetSpecialSymbols } from 'src/utils/formValidators';
import AreaCascader from 'src/components/Cascader/areaCascader';
import { BcAttachmentForm, BlIcon, NumberRulesStandardHook, SearchSelect } from 'components';
import { fetchFileListByIds } from 'src/api/ytt/holyfile-domain';
import { appDict, appEnum } from 'src/dict';
import { ObjectCode } from 'src/dict/common';
import { gcTime } from 'src/utils';
import LocalStorage from 'src/utils/localStorage';
import { FIELDS } from 'src/utils/constants';
import ResourceClassificationCascader from 'src/page/resource/components/ResourceClassificationCascader';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { injectCustomFieldInfos } from 'src/components/customField';
//
import { MAX_INPUT_LENGTH, MAX_TEXTAREA_LENGTH } from '../constants';
import {
  ContentProps,
  EnergyMeterCreateApiType,
  EnergyMeterDetailType,
  IEnergyBaseInfoForm,
} from '../index.d';
import UseAgeFilter from '../../definitions/components/UseAgeFilter';
import EnergyTagForm from './energyTag';
import EnergyParameterForm from './energyParameter';
import CensusEquimentForm from './censusEquiment';

const MAX_USER_COUNT = 10; // 最大负责人数量
const MAX_DEPARTMENT_COUNT = 5; // 最大负责部门数量

export const formatDataToApi = (
  data: IEnergyBaseInfoForm,
  type?: string,
): Omit<
  EnergyMeterCreateApiType,
  'resourceParamsList,energyInstrumentResourceLinkList,tagsIdList'
> => {
  const {
    id,
    code = '',
    isUseAutocode = false,
    outFactoryDate,
    enterFactoryDate,
    enableDate,
    planScrapDate,
    supplierId,
    userIdList,
    departmentIdList,
    unitId,
    locationId,
    coverFileId,
    classificationId,
    ...resData
  } = data;

  return {
    ...resData,
    id: type === appEnum.Common.CRUD.edit ? id : undefined,
    code: isUseAutocode ? '' : code,
    classificationId: !_.isEmpty(classificationId) ? _.last(classificationId) : undefined,
    outFactoryDate: !_.isNil(outFactoryDate) ? gcTime.formatLine(outFactoryDate) : undefined,
    enterFactoryDate: !_.isNil(enterFactoryDate) ? gcTime.formatLine(enterFactoryDate) : undefined,
    enableDate: !_.isNil(enableDate) ? gcTime.formatLine(enableDate) : undefined,
    planScrapDate: !_.isNil(planScrapDate) ? gcTime.formatLine(planScrapDate) : undefined,
    supplierId: _.get(supplierId, 'value', undefined),
    userIdList: !_.isEmpty(userIdList) ? _.map(userIdList, 'value') : undefined,
    departmentIdList: !_.isEmpty(departmentIdList) ? _.map(departmentIdList, 'value') : undefined,
    unitId: _.get(unitId, 'value', undefined),
    locationId: !_.isEmpty(locationId) ? _.last(locationId) : undefined,
    coverFileId: _.get(coverFileId, 'id', undefined),
  };
};

export const formatDataToForm = (data: EnergyMeterDetailType): IEnergyBaseInfoForm => {
  const {
    name = '',
    businessType = 0,
    coverFileId,
    outFactoryDate,
    enterFactoryDate,
    enableDate,
    planScrapDate,
    supplierResDTO,
    unitId,
    unitName = '',
    locationList,
    responsibleUser,
    responsibleDepartment,
    classificationList,
    ...resData
  } = data;

  return {
    ...resData,
    name,
    businessType,
    numberRuleId: 0,
    classificationId: !_.isEmpty(classificationList)
      ? _.compact(_.map(classificationList, 'id'))
      : undefined,
    coverFileId: !_.isNil(coverFileId) ? { id: coverFileId } : undefined,
    outFactoryDate: !_.isNil(outFactoryDate) ? moment(outFactoryDate) : undefined,
    enterFactoryDate: !_.isNil(enterFactoryDate) ? moment(enterFactoryDate) : undefined,
    enableDate: !_.isNil(enableDate) ? moment(enableDate) : undefined,
    planScrapDate: !_.isNil(planScrapDate) ? moment(planScrapDate) : undefined,
    supplierId: !_.isNil(supplierResDTO)
      ? { label: supplierResDTO?.name ?? '', value: supplierResDTO?.id ?? 0 }
      : undefined,
    userIdList: !_.isEmpty(responsibleUser)
      ? _.map(responsibleUser, ({ id = 0, name = '' }) => ({ label: name, value: id }))
      : undefined,
    departmentIdList: !_.isEmpty(responsibleDepartment)
      ? _.map(responsibleDepartment, ({ id = 0, name = '' }) => ({ label: name, value: id }))
      : undefined,
    unitId: !_.isNil(unitId) ? { label: unitName, value: unitId } : undefined,
    locationId: !_.isEmpty(locationList) ? _.map(locationList, 'id') : undefined,
  };
};

const ActionContent = (props: ContentProps) => {
  const { form, type, customFields } = props;
  const [coverFileUrl, setCoverFileUrl] = useState<string | undefined>('');

  // 批量添加参数的弹窗是否显示
  const { areaValue } = useSelector((state: RootState) => state.energyMeter, shallowEqual) ?? {};

  /**
   * 自动设置 计划报废时间
   */
  const setPlanScrapDate = () => {
    setTimeout(() => {
      const { enableDate, useAge, useAgeType, planScrapDate } = form.getFieldsValue();

      const dateUnitMap = new Map<number, 'day' | 'month' | 'year'>([
        [appEnum.Resources.UseAgeUnit.day, 'day'],
        [appEnum.Resources.UseAgeUnit.month, 'month'],
        [appEnum.Resources.UseAgeUnit.year, 'year'],
      ]);

      if (
        _.isNil(planScrapDate) &&
        !_.isNil(enableDate) &&
        !_.isNil(useAge) &&
        !_.isNil(useAgeType)
      ) {
        const planScrapDate = moment(enableDate).add(useAge, dateUnitMap.get(useAgeType));

        form.setFieldsValue({ planScrapDate });
      }
    });
  };

  const getBaseInfo = useCallback((): DataFormLayoutInfoBlock => {
    if (form.getFieldValue('coverFileId')) {
      fetchFileListByIds({ idList: [form.getFieldValue('coverFileId')?.id] }).then(({ data }) => {
        setCoverFileUrl(data?.[0]?.fileUri);
      });
    }

    const baseInfo = {
      title: '基本信息',
      column: 2,
      items: [
        {
          label: '',
          name: 'coverFileId',
          span: 2,
          render: () => (
            <div style={{ width: '90%' }}>
              <AvatarUpload
                form={form}
                formKey={'coverFileId'}
                uploadedUrl={coverFileUrl}
                icon={<BlIcon type="iconshebei" />}
              />
            </div>
          ),
        },
        {
          label: '',
          name: 'id',
          span: 2,
          hidden: true,
          render: () => <Input />,
        },
        ...NumberRulesStandardHook({
          label: '能源仪表编号',
          form,
          edit: type === 'edit',
          objectCode: ObjectCode.EnergyInstrument,
          fieldCode: 'code',
          rules: [
            { max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` },
            numberAlphabetSpecialSymbols,
          ],
        }),
        {
          label: '能源仪表名称',
          name: 'name',
          rules: [
            { required: true, message: '请输入能源仪表名称' },
            { max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` },
            { validator: checkTwoSidesTrim },
          ],
          render: () => <Input placeholder="请输入能源仪表名称" />,
        },
        {
          label: '业务类型',
          name: 'businessType',
          initialValue: appEnum.Resources.EnergyMeterType.electric,
          rules: [{ required: true, message: '请选择业务类型' }],
          render: () => (
            <Select placeholder="请选择业务类型" options={appDict.resources.energyMeterType} />
          ),
        },
        {
          label: '能源仪表分类',
          name: 'classificationId',
          render: () => (
            <ResourceClassificationCascader
              // resFetchData={resFetchData}
              params={{
                businessType: appEnum.Resources.ResourceBusinessType.energy,
                status: appEnum.Common.UsageStatus.enabled,
                cascade: true,
              }}
              showSearch
              placeholder={'请选择能源仪表分类'}
              enableAddLabel={'新建分类'}
              onCreateClick={() => {
                LocalStorage.set(FIELDS.RESOURCE_TYPE_IS_CREATE, true);
                window.open('/resource/resourceConfig/category');
              }}
              enableAdd
            />
          ),
        },
        {
          label: '标识码',
          name: 'entityLinkCode',
          rules: [{ max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` }],
          render: () => <Input placeholder="请输入标识码" />,
        },
        {
          label: '供应商',
          name: 'supplierId',
          render: () => (
            <SearchSelect
              fetchType="supplier"
              placeholder="请输入"
              labelInValue
              params={{ status: [appEnum.Common.UsageStatus.enabled] }}
            />
          ),
        },
        {
          label: '序列号',
          name: 'serialNo',
          rules: [{ max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` }],
          render: () => <Input placeholder="请输入序列号" />,
        },
        {
          label: '负责人',
          name: 'userIdList',
          rules: [
            () => ({
              validator(val: any, value: string | any[]) {
                if (_.isEmpty(value) || value?.length <= MAX_USER_COUNT) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(`负责人数量不超过${MAX_USER_COUNT}个`));
              },
            }),
          ],
          render: () => (
            <UserOrDepartmentSelectWithDialog placeholder="请选择负责人" isMultiple isSelectUser />
          ),
        },
        {
          label: '负责部门',
          name: 'departmentIdList',
          rules: [
            () => ({
              validator(val: any, value: string | any[]) {
                if (_.isEmpty(value) || value?.length <= MAX_DEPARTMENT_COUNT) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(`负责部门数量不超过${MAX_DEPARTMENT_COUNT}个`));
              },
            }),
          ],
          render: () => (
            <UserOrDepartmentSelectWithDialog placeholder="请选择负责部门" isMultiple />
          ),
        },
        {
          label: '出厂日期',
          name: 'outFactoryDate',
          render: () => (
            <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime style={{ width: '100%' }} />
          ),
        },
        {
          label: '入厂日期',
          name: 'enterFactoryDate',
          render: () => (
            <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime style={{ width: '100%' }} />
          ),
        },
        {
          label: '启用日期',
          name: 'enableDate',
          render: () => (
            <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime style={{ width: '100%' }} />
          ),
        },
        {
          label: '使用年限',
          render: () => (
            <div style={{ width: '100%' }}>
              <UseAgeFilter defaultUnit={appEnum.Resources.UseAgeUnit.year} />
            </div>
          ),
        },
        {
          label: '计划报废日期',
          dependencies: [['enableDate'], ['useAge'], ['useAgeType']],
          render: () => () => {
            setPlanScrapDate();

            return (
              <Form.Item name="planScrapDate" style={{ marginBottom: 0 }}>
                <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime style={{ width: '100%' }} />
              </Form.Item>
            );
          },
        },
        {
          label: '存储单位',
          name: 'unitId',
          render: () => (
            <SearchSelect
              fetchType="unit"
              placeholder="请选择单位"
              labelInValue
              params={{ enableFlag: appEnum.Common.UsageStatus.enabled }}
            />
          ),
        },
        {
          label: '存储区域',
          name: 'locationId',
          initialValue: areaValue,
          render: () => <AreaCascader placeholder="请输入选择存储区域" />,
        },
        {
          label: '附件',
          name: 'fileIdList',
          isFullLine: true,
          render: () => <BcAttachmentForm form={form} />,
        },
        {
          label: '备注',
          name: 'remark',
          isFullLine: true,
          rules: [{ max: MAX_TEXTAREA_LENGTH, message: `不超过${MAX_TEXTAREA_LENGTH}字符` }],
          render: () => <TextArea placeholder="请输入备注" />,
        },
      ],
    };

    if (type === 'edit') {
      baseInfo.items.push({
        label: '编辑原因',
        name: 'reason',
        isFullLine: true,
        rules: [{ max: MAX_TEXTAREA_LENGTH, message: `不超过${MAX_TEXTAREA_LENGTH}字符` }],
        render: () => <TextArea placeholder="请输入编辑原因" />,
      });
    }

    return baseInfo;
  }, [coverFileUrl]);

  const getEnergyTagForm = () => {
    return {
      title: '能源仪表标签',
      items: [
        {
          isFullLine: true,
          render: () => <EnergyTagForm name="tagsIdList" form={form} />,
        },
      ],
    };
  };

  const getCensusEquimentForm = () => {
    return {
      title: (
        <span>
          <span>统计设备</span>
          <span style={{ fontSize: 14, color: middleGrey, marginLeft: 5 }}>
            共用该能源仪表的能耗统计的所有设备
          </span>
        </span>
      ),
      items: [
        {
          isFullLine: true,
          render: () => <CensusEquimentForm name="energyInstrumentResourceLinkList" form={form} />,
        },
      ],
    };
  };
  const getEnergyParameterForm = () => {
    return {
      title: '能源仪表参数',
      items: [
        {
          isFullLine: true,
          render: () => <EnergyParameterForm name="resourceParamsList" form={form} />,
        },
      ],
    };
  };

  const tabPaneListConfig = [
    {
      key: 'baseInfo',
      tabTitle: '能源仪表台账',
      data: _.compact([
        getBaseInfo(),
        customFields &&
          injectCustomFieldInfos({
            customFields,
            type: 'form',
            formConfig: { form }, // form相关配置
          }),
        getEnergyTagForm(),
        getCensusEquimentForm(),
        getEnergyParameterForm(),
      ]),
    },
  ];

  return (
    <div style={{ overflowY: 'auto', margin: 24 }}>
      <Tabs defaultActiveKey="baseInfo" type="card">
        {tabPaneListConfig.map((tabPaneConfig) => (
          <Tabs.TabPane tab={tabPaneConfig.tabTitle} key={tabPaneConfig.key} forceRender>
            <DataFormLayout info={tabPaneConfig.data} form={form} footer={false} />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default ActionContent;
