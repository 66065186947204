import React, { useState } from 'react';
import { Form } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { FilterItem, RecordListLayout } from 'layout';
import { fieldTypeList } from 'src/utils';
import { CheckItemType } from './interface';
import { replaceSign } from 'src/utils/constants';
import { toCheckItemCreate, toCheckItemDetail, toCheckItemEdit } from './navigation';
import { BlIcon, SingleOperationLogComponent, Tooltip } from 'src/components';
import CheckItemDetail from './detail';
import { SearchAndCreate } from './components/searchAndCreate';
import _String from 'src/utils/dataTypes/string';
import {
  fetchQcCheckItemCheckBeforeDelete,
  fetchQcCheckItemDelete,
  fetchQcCheckItemPageList,
} from 'src/api/ytt/quality-domain/checkItem';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import {
  customLayoutChooseButton,
  customLayoutCreateButton,
} from 'src/components/customLayout/hooks/customLayoutForButton';

interface Props extends RouteComponentProps {}

const CheckItem = (props: Props) => {
  const { history } = props;
  // const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [visibleDetail, setVisibleDetail] = useState(false);
  const [checkId, setCheckId] = useState<any>();
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const [delForm] = Form.useForm();

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  // const handleDelete = async (ids: number[], onSuccess?: () => void, onFail?: () => void) => {
  //   try {
  //     const { code } = await fetchQcCheckItemDelete({ ids });

  //     if (code === 200) {
  //       onSuccess?.();
  //       Message.success('删除成功');
  //       return;
  //     }
  //     onFail?.();
  //   } catch (error) {
  //     onFail?.();
  //     console.log(error);
  //   }
  // };

  // // 删除前的check
  // const editCheckFn = async (ids: number[], onSuccess?: () => void, onFail?: () => void) => {
  //   try {
  //     const { data } = await fetchQcCheckItemCheckBeforeDelete({ ids });

  //     if (data) {
  //       // 被使用中，弹框提醒
  //       BLDelConfirm(
  //         `目前存在${data}个质检方案引用了此检验项，删除后对应质检方案中检验项会一并删除，要继续删除?`,
  //         delForm,
  //         () => {
  //           handleDelete(ids, onSuccess, onFail);
  //         },
  //       );
  //       return;
  //     }

  //     BLDelConfirm(
  //       '检验项目删除后将无法恢复,要继续删除吗',
  //       delForm,
  //       () => {
  //         handleDelete(ids, onSuccess, onFail);
  //       },
  //       () => {
  //         onFail?.();
  //       },
  //     );
  //   } catch (error) {
  //     console.log('error: ', error);
  //   }
  // };

  const hasAuthRes = hasAuth(authDict.inspectionitem_detail);

  const dataColumns = [
    {
      title: '检验项编号',
      dataIndex: 'code',
      width: 200,
      render: (code: string, record: CheckItemType, index: number, config: any) => {
        return (
          <>
            {hasAuthRes ? (
              customLayoutChooseButton({
                title: <Tooltip text={code ?? replaceSign} width={config.contentWidth} />,
                objectCode: 'InspectionItem',
                type: 'detail',
                onClick: (layoutId) =>
                  history.push(toCheckItemDetail(Number(record?.id), layoutId)),
                instanceId: record?.id,
              })
            ) : (
              <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },
    {
      title: '检验项名称',
      dataIndex: 'name',
      width: 200,
      render: (name: string, record: CheckItemType, index: number, config: any) => {
        return (
          <>
            {hasAuthRes ? (
              customLayoutChooseButton({
                title: <Tooltip text={name ?? replaceSign} width={config.contentWidth} />,
                objectCode: 'InspectionItem',
                type: 'detail',
                onClick: (layoutId) =>
                  history.push(toCheckItemDetail(Number(record?.id), layoutId)),
                instanceId: record?.id,
              })
            ) : (
              <Tooltip text={name ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },
    {
      title: '检验项分类',
      dataIndex: 'categoryName',
      width: 200,
      render: (categoryName: string) => categoryName,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 200,
      render: (remark: string) => remark ?? replaceSign,
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '检验项名称',
      name: 'checkItemName',
      type: fieldTypeList?.text,
    },
    {
      label: '检验项编号',
      name: 'checkItemCode',
      type: fieldTypeList?.text,
    },
    {
      label: '检验项分类',
      name: 'categoryId',
      type: fieldTypeList?.text,
      renderItem: <SearchAndCreate type="CheckItemClassificationList" isFilterDefault={false} />,
    },
  ];

  const left = [
    {
      title: '导出',
      showExport: true,
      icon: <BlIcon type="icondaochu" />,
      auth: authDict.inspectionitem_export,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.qcCheckItem,
        });
      },
    },
    {
      title: '导入',
      showExport: true,
      auth: authDict.inspectionitem_import,
      onClick: () => {
        openModal({
          optType: 'import',
          businessType: BUSINESS_TYPE.qcCheckItem,
        });
      },
    },
    {
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.inspectionitem_add,
      customRender: customLayoutCreateButton({
        title: '新建检验项',
        icon: <BlIcon type="iconxinjiantianjia" />,
        objectCode: 'InspectionItem',
        type: 'create',
        onClick: (layoutId) => history.push(toCheckItemCreate(layoutId)),
      }),
    },
  ];

  // const toolbar = [
  //   {
  //     title: '删除',
  //     onClick: (onSuccess: any, onFail: any) => {
  //       editCheckFn(selectedRowKeys, onSuccess, onFail);
  //     },
  //   },
  // ];

  const formatDataToQuery = (params: any) => {
    const { categoryId, quickSearch, checkItemName, checkItemCode, ...rest } = params;

    const relParams = {
      ...rest,
      categoryId: categoryId?.value,
    };

    if (!_String.isEmpty(quickSearch)) {
      relParams.quickSearch = quickSearch;
    }
    if (!_String.isEmpty(checkItemName)) {
      relParams.checkItemName = checkItemName;
    }
    if (!_String.isEmpty(checkItemCode)) {
      relParams.checkItemCode = checkItemCode;
    }

    dispatch.excelImport.updateBusinessData({ businessData: relParams });
    return relParams;
  };

  const config = {
    rowKey: (record: CheckItemType) => String(record.id),
    columns: dataColumns,
    getOperationList: (record: CheckItemType) => {
      return [
        {
          auth: authDict.inspectionitem_detail,
          customRender: customLayoutChooseButton({
            title: '查看',
            objectCode: 'InspectionItem',
            type: 'detail',
            onClick: (layoutId) => history.push(toCheckItemDetail(Number(record?.id), layoutId)),
            instanceId: record?.id,
          }),
        },
        {
          title: '编辑',
          auth: authDict.inspectionitem_edit,
          customRender: customLayoutChooseButton({
            title: '编辑',
            objectCode: 'InspectionItem',
            type: 'edit',
            onClick: (layoutId) => history.push(toCheckItemEdit(Number(record?.id), layoutId)),
            instanceId: record?.id,
          }),
        },
        {
          title: '操作记录',
          auth: authDict.inspectionitem_operrecord,
          onClick: () => {
            setCheckId(record?.id);
            setVisibleLog(true);
          },
        },
      ];
    },
    mainMenu: left,
    filterList,
    formatDataToQuery,
  };

  return (
    <>
      <RecordListLayout<CheckItemType>
        userAuth={getAuthFromLocalStorage()}
        placeholder={'输入检验项编号、检验项名称'}
        configcacheKey="checkItem"
        // onSelectedRowKeys={(selectKey: any) => {
        //   setSelectRowKeys(selectKey);
        // }}
        requestFn={fetchQcCheckItemPageList}
        refreshMarker={refreshMarker}
        {...config}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={checkId}
          objectCode={'InspectionItem'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default CheckItem;
