import styles from '../../styles.module.scss';

interface Props {
  number: number | string;
}
const DashedBox = (props: Props) => {
  const { number } = props;

  return <div className={styles['dashed-box']}>{number}</div>;
};

export default DashedBox;
