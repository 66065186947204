import { useEffect, useState } from 'react';
import { BlIcon } from 'components';
import useTreeContent from 'src/layout/treeContent';
import TreeBottom from './treeBottom';
import { message, Tooltip } from 'antd';
import './index.scss';
import authDict, { hasAuth } from 'src/utils/auth';
import lookup, { appEnum } from 'src/dict';
import { fetchDepartmentList, fetchDepartmentActiveDepartment } from 'src/api/ytt/user-domain/user';

const TreeWebContent = (props: any) => {
  const [treeData, setTreeData] = useState<any>([]);
  const {
    setActionType,
    detailContent,
    setDetailId,
    setShowActionModal,
    setIsShowChildrenUser,
    setDepartmentValues,
    isRefreshTree,
    setIsRefreshTree,
    history,
  } = props;

  const formateDepartmentTreeData = (data: any) => {
    return data.map((item: any) => {
      return {
        key: item.id,
        title: item.name,
        checkable: false,
        isLeaf: !item.childDepartments.length,
        active: item.active,
        supportActive: item.supportActive,
        children: formateDepartmentTreeData(item.childDepartments),
      };
    });
  };

  const refreshDepartmentList = () => {
    fetchDepartmentList({}).then((res) => {
      setTreeData(formateDepartmentTreeData(res.data ?? []));
    });
  };

  const showDepartmentDetail = (departmentId: number) => {
    history.push(`/organization/departmentManagement/detail?id=${departmentId}`);
  };

  useEffect(() => {
    refreshDepartmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshTree]);

  // const handleDelete = (id: number) => {
  //   fetchDepartmentDelete({ id }).then((res) => {
  //     if (res.code === 200) {
  //       refreshDepartmentList();
  //     } else {
  //       message.error(res.message);
  //     }
  //   });
  // };

  const iconFn = (title: string, type: string, onClickFn: (e: any) => void) => {
    return (
      <div
        style={{ marginLeft: 8 }}
        onClick={(e: any) => {
          typeof onClickFn === 'function' && onClickFn(e);
        }}
      >
        <Tooltip title={title} className="icons">
          <BlIcon type={type} />
        </Tooltip>
      </div>
    );
  };

  const handleAdd = (node: any) => (e: any) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    setActionType('create');
    setShowActionModal(true);
    setDepartmentValues({
      parentId: node.key,
      parentDepartment: {
        id: node.key,
        name: node.title,
      },
    });
  };

  const handleView = (node: any) => (e: any) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    showDepartmentDetail(node.key);
  };

  const handleEdit = (node: any) => (e: any) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    setActionType('edit');
    setShowActionModal(true);
    setDetailId(node.key);
  };

  const handleActiveDepartment = (node: any) => (e: any) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    // appEnum.User.DEPARTMENT_STATUS_ENUM.disable
    // 启停状态，0为停用，1为启用
    fetchDepartmentActiveDepartment({ id: node.key, active: Number(!node.active) }).then((res) => {
      if (res.code === 200) {
        message.success(`${lookup('user.departmentStatus', Number(!node.active))}成功`);
        setIsRefreshTree?.(Math.random() * 100);
      }
    });
  };

  return useTreeContent({
    treeData,
    detailContent,
    showLine: { showLeafIcon: false },
    bottomRender: <TreeBottom onChangeCheck={(value: boolean) => setIsShowChildrenUser(value)} />,
    height: window.innerHeight - 80,
    resizableProp: { height: window.innerHeight - 80 },
    showExpandBtn: true,
    virtual: false,
    titleRender: (node: any) => {
      return (
        <>
          <span style={{ minWidth: 200, overflow: 'auto' }} className="treeNode">
            {node.title}
            <div className="iconNode">
              {hasAuth(authDict.department_add) &&
                node.active === appEnum.Common.UsageStatus.enabled &&
                iconFn('添加', 'iconxinjiantianjia', handleAdd(node))}
              {node.title !== '全公司' &&
                hasAuth(authDict.department_detail) &&
                iconFn('详情', 'iconxiangqing', handleView(node))}
              {hasAuth(authDict.department_edit) && iconFn('编辑', 'iconbianji', handleEdit(node))}
              {hasAuth(authDict.department_enable_disable) &&
                node.title !== '全公司' &&
                node.supportActive &&
                iconFn(
                  lookup('user.departmentStatus', Number(!node.active)),
                  node.active === appEnum.User.DEPARTMENT_STATUS_ENUM.enable
                    ? 'icontingyong'
                    : 'iconqiyong',
                  handleActiveDepartment(node),
                )}
            </div>
            {/* <Popconfirm
              title="是否确认删除"
              onConfirm={() => handleDelete(node.key)}
              okText="确认"
              cancelText="取消"
            >
              <Tooltip title={'删除'}>
                <a
                  style={treeButtonStyle}
                  onClick={(e: any) => {
                    if (e.stopPropagation) {
                      e.stopPropagation();
                    }
                  }}
                >
                  <<BlIcon type="iconshanchulajitong1" /> />
                </a>
              </Tooltip>
            </Popconfirm> */}
          </span>
        </>
      );
    },
  });
};

export { TreeWebContent };
