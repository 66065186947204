import React from 'react';
import SalesOrderDetailList from './list/detailList';
import SalesOrderDocumentsList from './list/documentList';
import { TabList } from 'src/components';
import lookup, { appEnum } from 'src/dict';

const List = () => {
  const tabList = [
    {
      tab: lookup('common.viewModal', appEnum.Common.ViewModal.documents),
      key: appEnum.Common.ViewModal.documents,
      component: <SalesOrderDocumentsList />,
    },
    {
      tab: lookup('common.viewModal', appEnum.Common.ViewModal.detail),
      key: appEnum.Common.ViewModal.detail,
      component: <SalesOrderDetailList />,
    },
  ];

  return <TabList tabList={tabList} defaultKey={appEnum.Common.ViewModal.documents} />;
};

export default List;
