import React, { useState, useEffect } from 'react';
import { DetailBaseInfo } from './components/detailBaseInfo';
import { match, RouteComponentProps } from 'react-router-dom';
import { toEdit } from './navigation';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import {
  fetchMessageTemplateDetail,
  fetchMessageTemplateDisable,
  fetchMessageTemplateEnable,
} from 'src/api/ytt/trigger-domain';
import { batchRequest } from './utils';
import { UsageStatus } from 'src/dict/common';
import authDict from 'src/utils/auth';

interface DetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const NoticeTemplateDetail = (props: DetailProps) => {
  const { match, history } = props;
  const templateNoticeId = Number(match?.params?.id);
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [dataSource, setDataSource] = useState({} as any);

  const fetchData = async () => {
    try {
      const { data } = await fetchMessageTemplateDetail({ id: templateNoticeId });

      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };

  const baseMenu = [
    {
      key: 'enable',
      auth: authDict.messagetemplate_enable_disable,
      onClick: async () => {
        const storeApi = dataSource?.status
          ? fetchMessageTemplateDisable
          : fetchMessageTemplateEnable;
        const storeDisplay = dataSource?.status ? '停用' : '启用';

        batchRequest('single', storeApi, [templateNoticeId], storeDisplay, fetchData);
      },
      icon:
        dataSource?.status === UsageStatus.enabled ? (
          <BlIcon type="icontingyong" />
        ) : (
          <BlIcon type="iconqiyong" />
        ),
      title: dataSource?.status ? '停用' : '启用',
    },
    {
      key: 'log',
      onClick: () => {
        setVisibleLog(true);
      },
      icon: <BlIcon type="iconcaozuojilu" />,
      title: '操作记录',
    },
    {
      key: 'edit',
      auth: authDict.messagetemplate_edit,
      disabled: dataSource?.status,
      onClick: () => {
        history.push(toEdit(templateNoticeId));
      },
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ];

  useEffect(() => {
    fetchData();
  }, [templateNoticeId]);

  return (
    <>
      <DetailBaseInfo detail={dataSource} baseMenu={baseMenu} />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={templateNoticeId}
          objectCode={'MessageTemplate'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default NoticeTemplateDetail;
