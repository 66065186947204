import React, { useState, useEffect } from 'react';
import { BcAttachmentDetail, BlIcon, SingleOperationLogComponent } from 'src/components';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import { replaceSign } from 'src/utils/constants';
import _Array from 'src/utils/dataTypes/array';
import _Time from 'src/utils/dataTypes/time';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { toProcessEdit } from '../navigation';
import { useHistory } from 'react-router';
import { fetchProcessDetail } from 'src/api/ytt/med-domain/process';
import { Creator } from 'src/page/sales/salesManagement/salesOrder/interface';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import lookup from 'src/dict';
import { ConfigurationItems } from '../interface';

interface DetailBaseInfoProps {
  processId: number;
  type: string;
}

export default function DetailBaseInfo({ processId, type }: DetailBaseInfoProps) {
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const history = useHistory();

  const [dataSource, setDataSource] = useState({} as any);

  const fetchData = async () => {
    try {
      const { data } = await fetchProcessDetail({ id: processId });

      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [processId]);

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '工序编号',
        dataIndex: 'code',
        isFullLine: type === 'drawer',
        render: (code: string) => code ?? replaceSign,
      },
      {
        label: '工序名称',
        dataIndex: 'name',
        isFullLine: type === 'drawer',
        render: (code: string) => code ?? replaceSign,
      },
      {
        label: '所属部门',
        dataIndex: ['department', 'name'],
        isFullLine: type === 'drawer',
        render: (departmentName: string) => departmentName ?? replaceSign,
      },
    ],
    column: 2,
  };

  const defaultInfo: DetailLayoutInfoBlock = {
    title: '默认信息',
    items: [
      {
        label: '工作中心',
        dataIndex: 'workCenterName',
        isFullLine: type === 'drawer',
        render: (workCenterName: string) => workCenterName ?? replaceSign,
      },
      {
        label: '工序说明',
        dataIndex: 'description',
        isFullLine: type === 'drawer',
        render: (description: string) => description ?? replaceSign,
      },
      {
        label: '文件',
        dataIndex: 'files',
        isFullLine: type === 'drawer',
        render: (files: number[]) => {
          if (_Array.isEmpty(files)) return replaceSign;

          return <BcAttachmentDetail fileIds={files} />;
        },
      },
    ],
    column: 1,
  };

  const traceRelationshipInfo: DetailLayoutInfoBlock = {
    title: '业务配置',
    items: [
      {
        label: '用料追溯关系',
        dataIndex: 'configurationItems',
        isFullLine: type === 'drawer',
        render: (configurationItems: ConfigurationItems) =>
          lookup('process.traceabilityType', configurationItems?.traceRelationship) ?? replaceSign,
      },
    ],
    column: 1,
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: Creator) => {
          return (
            <AvatarDisplay
              id={creator?.id}
              name={creator?.name}
              avatarUrl={creator?.avatarUrl}
              isShowTag={false}
              isUser
            />
          );
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        isFullLine: type === 'drawer',
        render: (createdAt: Date) => _Time.format(createdAt) ?? replaceSign,
      },

      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: Creator) => {
          return (
            <AvatarDisplay
              id={operator?.id}
              name={operator?.name}
              avatarUrl={operator?.avatarUrl}
              isShowTag={false}
              isUser
            />
          );
        },
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        isFullLine: type === 'drawer',
        render: (updatedAt: Date, record: any) =>
          _Time.format(updatedAt) ?? _Time.format(record?.createdAt) ?? replaceSign,
      },
    ],
    column: 2,
  };
  const baseMenu = [
    {
      key: 'record',
      auth: authDict.process_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'edit',
      auth: authDict.process_edit,
      onClick: () => {
        history.push(toProcessEdit(processId));
      },
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ];

  return (
    <>
      <DetailLayout
        userAuth={getAuthFromLocalStorage()}
        title="工序详情"
        baseMenu={baseMenu}
        info={[baseInfo, defaultInfo, traceRelationshipInfo, otherInfo]}
        dataSource={dataSource}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={processId}
          objectCode={'Process'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
}
