/**
 * @enum 计划订单业务模块枚举值
 */

/** 需求类型类型字段map */
export enum RequirementTypeEnum {
  /** 独立需求 */
  INDEPENDENTDEMAND = 1,
  /** 相关需求 */
  RELEVANTREQUIREMENTS,
}

/** 投放类型字段map
 * 一期仅支持生产工单
 */

export enum LaunchTypeEnum {
  /** 生产工单 */
  PWO = 1,
  //   /** 采购申请 */
  //   PURCHASINGAPPLICATION,
  //   /** 委外订单 */
  //   OUTSOURCINGORDERS,
}

/** 计划订单状态字段map */
export enum StatusEnum {
  /** 计划 */
  PLAN = 1,
  /** 计划确认 */
  PLANCONFIRMATION,
  /** 已投放 */
  LAUNCHED,
  /** 已关闭 */
  CLOSED,
}

/** 投料状态map */
export enum InputTypeEnum {
  /** 投料 */
  INPUT = 1,
  /** 投料添加申请 */
  INPUTADDREQUEST,
}

/**
 * 计划订单关闭类型
 */
export enum PlanOrderCloseTypeEnum {
  normalShutdown = 1,
  abnormalShutdown,
}

/**
 * 生成方式
 */
export enum GenerationMethod {
  /** 手工录入 */
  manualentry = 1,
  /** 计划运算 */
  programOperation,
  /** 拆分 */
  split,
}

/**
 * 来源单据类型
 */
export enum SourceType {
  /** 计划订单 */
  plannedOrder = 1,
  // /** 销售订单 */
  // saleOrder,
}

/**
 * 操作类型
 */
export enum OperateType {
  /** 投放 */
  launch = 1,
  /** 排程 */
  schedule,
}

/**
 * 数量拆分规则
 */
export enum quantitySplit {
  /** 按产线拆分 */
  productionLine = 1,
  /** 按班次拆分 */
  shift,
  /** 按维保任务拆分 */
  maintenance,
}

/**
 * 物料排程可选设备时间
 */
export enum standardCapacityTimeUnit {
  /** 按产线拆分 */
  hour = 1,
}
// ======================================================计划运算============================================================

/**
 * 计划运算范围
 */
export enum OperationRange {
  /** 独立需求计划订单 */
  IndependentDemandPlanOrder = 1,
}

/**
 * 需求优先级
 */
export enum RequirementPriority {
  /** 按需求时间升序 */
  TimeAscendingOrder = 1,
}
/**
 * 预留释放设置
 */
export enum ReserveLiberate {
  /** 不释放预留 */
  DoNotReleaseReservation = 1,
}

/**
 * 计划属性
 */
export enum PlanAttribute {
  /** 自制 */
  Homemade = 1,
}
/**
 * 不同需求来源
 */
export enum DiffRequSources {
  /** 不合并 */
  NoMerge,
  /** 合并 */
  Merge,
}

/**
 * 不同需求来源
 */
export enum leadTimeUnitMap {
  /** 小时 */
  Hour = 1,
  /** 日 */
  Day,
}

/**
 * 需求设置 需求设置&供应设置单据名称
 */
export enum requirementSourceTypeName {
  /** 计划订单 */
  planOrder = 1,
  /** 生产工单 */
  productionOrder,
}

/**
 * 需求设置 需求设置&供应设置单据类型
 */
export enum requirementSourceType {
  /** 生产工单 */
  planOrder = 1,
  /** 普通 */
  ordinary,
}

/**
 * 预留类型
 */
export enum reservedType {
  /** 强预留 */
  strongReserve = 1,
  /** 弱预留 */
  weakReserved,
}

/**
 * 预留生成方式
 */
export enum ReservedGenerationMethodEnum {
  /** 计划运算 */
  PLAN_OPERATION = 1,
  /** 手工录入 */
  MANUAL,
}

/**
 * 预留状态
 */
export enum reservedStatus {
  /** 预留中 */
  reserveing = 1,
  /** 已释放 */
  released,
}
