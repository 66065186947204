import { Form, message as Message } from 'antd';
import React, { useEffect, useState } from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import CreateAndEditBaseInfo from './components/createAndEditComponent';
import {
  fetchMatchRuleMatchRuleCreate,
  fetchMatchRuleMatchRuleModify,
  fetchMatchRuleQueryMatchRuleDetail,
} from 'src/api/ytt/e-report-domain/matchRule';
import { map } from 'lodash';
import { formatToStingfyOption } from 'src/page/organization/triggerRule/utils/formatToFormData';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';
import { toMatchRuleDetail, toMatchRuleTemplateList } from '../templateManagement/navigation';

interface createProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const MatchRuleCreateAndEdit = (props: createProps) => {
  const { match, history } = props;

  const id = Number(match?.params?.id);

  const {
    location: { search },
  } = history;

  const backType = search.slice(6);

  const onCancel = () => {
    if (backType === 'detail') {
      history.push(toMatchRuleDetail(id));
    } else {
      history.push(toMatchRuleTemplateList());
    }
  };

  const [dataSource, setDataSource] = useState({} as any);

  const [form] = Form.useForm();

  const fetchData = async () => {
    try {
      const { data } = await fetchMatchRuleQueryMatchRuleDetail({ matchRuleId: id });

      if (data) {
        const { objectCode = '', objectName = '', objectId = '', ...rest } = data;

        setDataSource({
          ...rest,
          objectCode: formatToStingfyOption(objectName, {
            objectId,
            objectName,
            objectCode,
          }),
          matchRuleObjectTemplateDTOList: map(
            data?.subMatchRuleDTOList?.[0]?.matchRuleObjectTemplateDTOList,
            (e: any) => {
              return {
                subTemplateId: {
                  label: `${e?.templateCode}/${e?.templateName}`,
                  value: JSON.stringify(e),
                  key: e?.subTemplateId,
                },
              };
            },
          ),
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    id && fetchData();
  }, [id]);

  const handleFinish = async () => {
    try {
      const value = await form?.validateFields();

      const { matchRuleObjectTemplateDTOList, objectCode, ...rest } = value;

      const object = JSON.parse(objectCode?.value);

      const fetchFn = id ? fetchMatchRuleMatchRuleModify : fetchMatchRuleMatchRuleCreate;

      const { data, message } = await fetchFn({
        ...rest,
        objectCode: object?.objectCode,
        objectId: object?.id ?? object?.objectId,
        objectName: object?.objectName,
        subMatchRuleDTOList: [
          {
            matchRuleObjectTemplateDTOList: map(matchRuleObjectTemplateDTOList, (e: any) =>
              JSON.parse(e?.subTemplateId?.value),
            ),
          },
        ],
        id,
      });

      if (data) {
        Message.success(`${id ? '更新成功' : '新建成功'}`);
        onCancel();
        return;
      }

      Message.error(message);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <CreateAndEditBaseInfo
      dataSource={dataSource}
      edit={Boolean(id)}
      form={form}
      onFinish={throttleFn(handleFinish)}
      onCancel={onCancel}
    />
  );
};

export default MatchRuleCreateAndEdit;
