import { useState } from 'react';
import { RecordListLayout, FilterItem } from 'layout';
import { TextToolTip } from '@blacklake-web/component';
import _ from 'lodash';

import { fetchResourceSchedulerList } from 'src/api/ytt/resource-domain/collectionRules';
import { SingleOperationLogComponent } from 'src/components';
import { fieldTypeList } from 'src/utils';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { appEnum, lookup } from 'src/dict';
import { CRUD } from 'src/dict/common';
import { fieldLabels } from '../constants';
import { goToDetail, goToCreate, goToEdit } from '../navigates';
import type { CollectionRulesListItemType } from '../interface';
import AutoFrequency, { autoFrequencyRender } from '../components/AutoFrequency';

export default function CollectionRulesList() {
  // 刷新用的时间戳，在refresh方法里用
  const [refreshMarker] = useState<number>(Date.now());
  const [visibleLog, setVisibleLog] = useState(false);
  // 当前操作行的id，传给操作记录用
  const [currentOrderId, setCurrentOrderId] = useState<number>();

  /**
   * 主操作按钮
   */
  const mainMenu = _.compact([
    {
      title: '新建数采规则',
      auth: authDict.collectionrule_add,
      onClick: goToCreate,
      items: [],
    },
  ]);

  /**
   * 列操作 ...
   */
  const getOperationList = (record: CollectionRulesListItemType) => {
    const { id } = record;

    return _.compact([
      {
        title: lookup('common.crud', CRUD.view),
        auth: authDict.collectionrule_detail,
        onClick: () => {
          goToDetail(id!);
        },
      },
      {
        title: lookup('common.crud', CRUD.edit),
        auth: authDict.collectionrule_edit,
        onClick: () => {
          goToEdit(id!);
        },
      },
      {
        title: '操作记录',
        auth: authDict.collectionrule_operrecord,
        onClick: () => {
          setCurrentOrderId(id);
          setVisibleLog(true);
        },
      },
    ]);
  };
  /**
   * Columns
   */
  const columns = [
    {
      title: fieldLabels.code,
      dataIndex: 'code',
      width: 150,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.text,
      render: (text: string, record: CollectionRulesListItemType, index: number, config: any) => {
        return hasAuth(authDict.collectionrule_detail) ? (
          <a
            onClick={() => {
              goToDetail(record.id!);
            }}
          >
            <TextToolTip text={text} width={config.contentWidth} />
          </a>
        ) : (
          text
        );
      },
    },
    {
      title: fieldLabels.name,
      dataIndex: 'name',
      width: 160,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: fieldLabels.autoBeginTime,
      dataIndex: 'autoBeginTime',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: fieldLabels.autoEndTime,
      dataIndex: 'autoEndTime',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: fieldLabels.autoFrequency,
      dataIndex: 'autoFrequency',
      width: 200,
      isFilter: true,
      render: autoFrequencyRender,
      renderItem: <AutoFrequency />,
    },
  ];
  /**
   * 列表筛选处理函数
   */

  const filterList: FilterItem[] = columns
    .filter((i) => i.isFilter)
    .map((column) => {
      return _.pick(
        {
          ...column,
          label: column.title,
          name: column.dataIndex,
          type: column.type,
        },
        [
          'label',
          'name',
          'type',
          'rules',
          'renderItem',
          'selectProps',
          'inputProps',
          'datePickerProps',
          'dateFormat',
        ],
      ) as FilterItem;
    });
  /**
   * 传给接口数据处理函数
   */
  const formatDataToQuery = (param: any) => {
    const { autoBeginTime, autoEndTime, autoFrequency, autoFrequencyUnitType, ...rest } = param;
    const hasAutoFrequency = !_.isNil(autoFrequencyUnitType) && !_.isNil(autoFrequencyUnitType);
    const requestParam = {
      autoFrequency: hasAutoFrequency ? Number(autoFrequency) : undefined,
      autoFrequencyUnitType: hasAutoFrequency ? autoFrequencyUnitType : undefined,
      autoBeginTimeFrom:
        autoBeginTime && autoBeginTime[0] ? Date.parse(autoBeginTime[0]) : undefined,
      autoBeginTimeTo: autoBeginTime && autoBeginTime[1] ? Date.parse(autoBeginTime[1]) : undefined,
      autoEndTimeFrom: autoEndTime && autoEndTime[0] ? Date.parse(autoEndTime[0]) : undefined,
      autoEndTimeTo: autoEndTime && autoEndTime[1] ? Date.parse(autoEndTime[1]) : undefined,
      ...rest,
    };

    // 接口需要的创建时间和更新时间为number型的timestamp
    return requestParam;
  };
  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (param: any) => {
    return param;
  };
  /**
   * 筛选标签，数据显示
   */
  const formatDataToDisplay = (param: any) => {
    return param;
  };

  return (
    <>
      <RecordListLayout<CollectionRulesListItemType>
        refreshMarker={refreshMarker}
        filterList={filterList}
        requestFn={fetchResourceSchedulerList}
        placeholder="请输入数采规则名称或编号"
        mainMenu={mainMenu}
        rowKey="id"
        columns={columns}
        getOperationList={getOperationList}
        userAuth={getAuthFromLocalStorage()}
        formatDataToDisplay={formatDataToDisplay}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={Number(currentOrderId)}
          objectCode={appEnum.Common.ObjectCode.DataCollectionRule} // 对象在对象平台唯一定义的objectCode
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
}
