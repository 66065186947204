import {
  fetchReservedCommonGet,
  FetchReservedCommonGetRequest,
  fetchReservedSynthesizeSuperscriptGet,
  FetchReservedSynthesizeSuperscriptGetRequest,
} from 'src/api/ytt/plo-domain/reserved';

/**
 * 查询预留
 */
export const fetchReservedCommonData = (parameter: FetchReservedCommonGetRequest) => {
  return fetchReservedCommonGet(parameter)
    .then((res) => {
      if (!res.data) {
        return;
      }
      return res.data;
    })
    .catch((err) => {
      console.log('err: ', err);
    });
};

/**
 * 综合查询
 */
export const fetchReservedSynthesizeSuperscriptData = (
  parameter: FetchReservedSynthesizeSuperscriptGetRequest,
) => {
  return fetchReservedSynthesizeSuperscriptGet(parameter)
    .then((res) => {
      if (!res.data?.subscript) {
        return;
      }
      return res.data;
    })
    .catch((err) => {
      console.log('err: ', err);
    });
};
