import { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import { map, debounce, isEmpty } from 'lodash';
import { message, Tabs, Form } from 'antd';
import { DetailLayout, DataFormLayoutForModal, DetailLayoutForModal } from 'src/layout';
import { SingleOperationLogComponent } from 'src/components';
import lookup, { appEnum } from 'src/dict';
import { AvatarDisplay } from 'src/components/avatar/show';
import { injectCustomFieldInfos, useCustomFieldCombinedData } from 'src/components/customField';
import { FetchWorkOrderDispatchInfoGetResponse } from 'src/api/ytt/med-domain/work_order';
import _Time from 'src/utils/dataTypes/time';
import { DetailLayoutMenuItem } from 'src/layout/detail/DetailLayout.type';
import CoproductTab from './coproductDetail';
import SubItemMaterialTab from './subItemMaterialDetail';
import OtherInfoTab from './otherInfoDetail';
import { ProductionOrderTab, ProductionOrderTabMap } from '../constants';
import { toEditPlannedOrder } from '../navigation';
import ProductionTaskForm from '../creatProductionTask/ProductionTaskForm';
import DetailPage from './processRoutingDetail';
import SourceDetail from './sourceDetail';
// import { goToCreate } from '../../storeRequisition/utils';
import { handleBatchMessage } from '../list/plannedOrderList';
import { replaceSign } from 'src/utils/constants';
import authDict from 'src/utils/auth';
import { StatusEnum } from 'src/dict/planned';
import {
  fetchPlanOrderBatchConfirm,
  fetchPlanOrderLaunchInfoGet,
  fetchPlanOrderFullDetailGet,
  fetchPlanOrderBatchClose,
} from 'src/api/ytt/plo-domain';
import showConfirmCloseOrder from '../list/showConfirmCloseOrder';
import SplitOrderForm from '../splitOrderForm';
import CpsiveReservationList from '../../reserve/comprehensiveReservation/list';
import { ReservedOrderType } from '../../planningOperation/constants';
import { fetchReservedSynthesizeSuperscriptData } from '../../reserve/utils';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

interface DetailProps extends RouteChildrenProps<{ id: string }> {}

const Detail = (props: DetailProps) => {
  const { match, history } = props;

  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState<any>();
  const [IssueTask, setIssueTask] = useState<FetchWorkOrderDispatchInfoGetResponse['data']>();
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.feedingRecord);
  const [operationRecordVisible, setOperationRecordVisible] = useState(false);
  const [datasetId, setDataSetId] = useState<number>(0);
  const [splitOrder, setSplitOrder] = useState<any[]>();
  const [cpsiveReservationList, setCpsiveReservationList] = useState<any>(false);

  const [formCloseOrderConFirm] = Form.useForm();

  const fetchAndSetData = (id?: number) => {
    if (id) {
      setLoading(true);
      const planOrderFullDetailGet = new Promise((resolve) => {
        fetchPlanOrderFullDetailGet({ planOrderId: id }).then((res) => {
          const { data } = res;

          resolve(data);
        });
      });

      return Promise.all([planOrderFullDetailGet])
        .then((res) => {
          const {
            inputMaterialVOs,
            outputMaterialVOs,
            planOrderBaseVO,
            planOrderSourceVO,
            processPlanVO,
          } = res?.[0] as any;

          const dataSourceList = {
            planOrderBase: planOrderBaseVO,
            bomOutputMaterials: outputMaterialVOs,
            bomInputMaterials: inputMaterialVOs,
            processPlanCO: processPlanVO,
            workOrderSource: planOrderSourceVO,
            customFields: planOrderBaseVO?.customFields,
          };

          setDataSource(dataSourceList);
          return res;
        })
        .catch((err) => {
          console.log('err: ', err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return Promise.reject();
  };

  useEffect(() => {
    fetchAndSetData(Number(match?.params?.id))
      .catch(() => {
        history.goBack();
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  /**
   * 刷新页面
   */
  const refreshPage = () => {
    fetchAndSetData(Number(match?.params?.id)).finally(() => {
      setLoading(false);
    });
  };

  const getBaseMenu = (): DetailLayoutMenuItem[] => {
    const {
      status,
      id,
      code,
      planStartTime,
      planFinishTime,
      remark,
      productionDepartment,
      productionSupervisor,
    } = dataSource?.planOrderBase || {};

    // 编辑条件
    const editFlag = [StatusEnum.PLAN, StatusEnum.PLANCONFIRMATION].includes(status);

    // 计划确认计划订单条件
    const planConfirmation = [StatusEnum.PLAN].includes(status);

    // 投放条件
    const launched = [StatusEnum.PLANCONFIRMATION, StatusEnum.LAUNCHED].includes(status);

    // 拆分条件
    const split = ![StatusEnum.CLOSED].includes(status);

    // 关闭条件
    const closed = [StatusEnum.PLAN, StatusEnum.PLANCONFIRMATION, StatusEnum.LAUNCHED].includes(
      status,
    );

    const menuList = [
      {
        title: '操作记录',
        auth: authDict.planOrder_operrecord,
        key: 'operation',
        onClick: () => {
          setDataSetId(id);
          setOperationRecordVisible(true);
        },
      },
    ];

    if (launched) {
      menuList.push({
        title: '投放',
        key: 'launched',
        auth: authDict.planOrder_launch,
        onClick: debounce(async () => {
          await fetchPlanOrderLaunchInfoGet({
            planOrderIdList: [id],
          }).then((res) => {
            const { data } = res;

            setIssueTask(data as any);
          });
        }, 1500),
      });
    }

    if (planConfirmation) {
      menuList.push({
        title: '确认',
        key: 'planConfirmation',
        auth: authDict.planOrder_confirm,
        onClick: debounce(async () => {
          await fetchPlanOrderBatchConfirm({
            planOrderIdList: [id],
          }).then((res) => {
            const { code = 0 } = res ?? {};

            refreshPage();
            if (code === 200) message.success('确认成功!');
          });
        }, 1500),
      });
    }

    if (closed) {
      menuList.push({
        title: '关闭',
        auth: authDict.planOrder_close,
        key: 'close',
        onClick: debounce(() => {
          return new Promise((resolve, reject) => {
            showConfirmCloseOrder(formCloseOrderConFirm, () => {
              return fetchPlanOrderBatchClose({
                operateReason: formCloseOrderConFirm.getFieldValue('operateReason'),
                planOrderIdList: [id],
              })
                .then((res) => {
                  formCloseOrderConFirm.resetFields();
                  handleBatchMessage('关闭', res.data);
                  refreshPage();
                  resolve(true);
                })
                .catch(() => {
                  reject(false);
                });
            });
          });
        }, 1500),
      });
    }

    if (split) {
      menuList.push({
        title: '拆分',
        key: 'split',
        auth: authDict.planOrder_split,
        onClick: () => {
          setSplitOrder([
            {
              planOrderCode: code,
              materialInfo: dataSource?.bomOutputMaterials?.[0]?.material,
              plannedAmount: dataSource?.bomOutputMaterials?.[0]?.plannedAmount,
              unitName: dataSource?.bomOutputMaterials?.[0]?.unitName,
              plannedStartTime: planStartTime,
              plannedEndTime: planFinishTime,
              remark,
              productionDepartment,
              productionSupervisor,
              canLaunchAmount: dataSource?.bomOutputMaterials?.[0]?.canLaunchAmount,
              planOrderId: id,
            },
          ]);
        },
      });
    }

    if (![StatusEnum.CLOSED].includes(status)) {
      menuList.push({
        key: 'reservation',
        title: '综合查询',
        auth: authDict.PlanReserved_detail,
        onClick: debounce(async () => {
          fetchReservedSynthesizeSuperscriptData({
            orderId: id,
            orderType: ReservedOrderType.PLAN_ORDER,
          }).then((reservedCommonData) => {
            if (!reservedCommonData) {
              message.error('暂无预留');
              return;
            }
            setCpsiveReservationList({
              data: reservedCommonData,
              param: {
                orderId: id,
                orderType: ReservedOrderType.PLAN_ORDER,
              },
            });
          });
        }, 1500),
      });
    }

    if (editFlag) {
      menuList.push({
        key: appEnum.Common.CRUD.edit,
        auth: authDict.planOrder_edit,
        title: lookup('crud', appEnum.Common.CRUD.edit),
        onClick: () => {
          match?.params?.id && history.push(toEditPlannedOrder(Number(match?.params?.id)));
        },
      });
    }

    return menuList;
  };

  const renderTabs = () => {
    const tabWithComponent = new Map([
      [ProductionOrderTab.SUB_ITEM_MATERIAL, <SubItemMaterialTab dataSource={dataSource} />],
      [ProductionOrderTab.COPRODUCT, <CoproductTab dataSource={dataSource} />],
      [ProductionOrderTab.OTHER, <OtherInfoTab dataSource={dataSource} />],
      [ProductionOrderTab.PROCESS_PLAN, <DetailPage dataSource={dataSource?.processPlanCO} />],
      [ProductionOrderTab.SOURCE, <SourceDetail dataSource={dataSource} />],
    ]);

    return (
      <Tabs type="card">
        {map(Array.from(ProductionOrderTabMap.keys()), (key) => {
          const title = ProductionOrderTabMap.get(key)?.title;

          return (
            <Tabs.TabPane key={key} tab={title} style={{ height: '100%' }}>
              {tabWithComponent.get(key)}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    );
  };

  const baseInfo = [
    {
      title: '基本信息',
      items: [
        {
          label: '计划订单编号',
          dataIndex: ['planOrderBase', 'code'],
        },
        {
          label: '需求类型',
          dataIndex: ['planOrderBase', 'requirementType'],
          render: (text: any) => lookup('planned.RequirementTypeEnumDisplayMap', text),
        },
        {
          label: '投放类型',
          dataIndex: ['planOrderBase', 'launchType'],
          render: (text: any) => lookup('planned.LaunchTypeEnumDisplayMap', text),
        },
        {
          label: '状态',
          dataIndex: ['planOrderBase', 'status'],
          render: (text: any) => lookup('planned.StatusEnumDisplayMap', text),
        },
        {
          label: '需求时间',
          dataIndex: ['planOrderBase', 'requirementTime'],
          render: (text: any) => (text ? _Time.format(text) : replaceSign),
        },
        {
          label: '计划部门',
          dataIndex: ['planOrderBase', 'planningDepartment', 'name'],
        },
        {
          label: '计划员',
          dataIndex: ['planOrderBase', 'planningUser'],
          render: (text: any) => {
            return text?.id && <AvatarDisplay {...(text ?? {})} isShowTag={false} isUser />;
          },
        },

        {
          label: '计划开始时间',
          dataIndex: ['planOrderBase', 'planStartTime'],
          render: (text: any) => (text ? _Time.format(text) : replaceSign),
        },
        {
          label: '计划结束时间',
          dataIndex: ['planOrderBase', 'planFinishTime'],
          render: (text: any) => (text ? _Time.format(text) : replaceSign),
        },
        {
          label: '生产部门',
          dataIndex: ['planOrderBase', 'productionDepartment', 'name'],
        },
        {
          label: '生产主管',
          dataIndex: ['planOrderBase', 'productionSupervisor'],
          render: (text: any) => {
            return text?.id && <AvatarDisplay {...(text ?? {})} isShowTag={false} isUser />;
          },
        },
        {
          label: '班次',
          dataIndex: ['planOrderBase', 'shiftVO', 'name'],
        },
      ],
    },
    injectCustomFieldInfos({ customFields, type: 'detail' }),
    {
      title: '详细信息',
      items: [
        {
          dataIndex: 'xxx',
          render: () => renderTabs(),
        },
      ],
    },
  ];

  return (
    <>
      <DetailLayout
        title="计划订单详情"
        info={baseInfo}
        dataSource={dataSource}
        baseMenu={getBaseMenu()}
        loading={loading}
      />
      <DataFormLayoutForModal
        visible={!isEmpty(IssueTask)}
        onClose={() => {
          setIssueTask(undefined);
        }}
        content={
          <ProductionTaskForm
            name="launchRequests"
            params={IssueTask}
            history={history}
            onCancel={() => {
              setIssueTask(undefined);
            }}
            onChange={() => {
              setIssueTask(undefined);
            }}
          />
        }
      />
      {operationRecordVisible && (
        <SingleOperationLogComponent
          visible={operationRecordVisible}
          instanceId={datasetId}
          objectCode={'PlanOrder'}
          closeDetail={() => {
            setOperationRecordVisible(false);
          }}
        />
      )}
      {/* 拆分 */}
      <DataFormLayoutForModal
        visible={!isEmpty(splitOrder)}
        onClose={() => {
          setSplitOrder(undefined);
        }}
        content={
          <SplitOrderForm
            name="planOrderSplitItemList"
            params={splitOrder}
            history={history}
            onCancel={() => {
              setSplitOrder(undefined);
            }}
            onChange={refreshPage}
          />
        }
      />

      <DetailLayoutForModal
        visible={cpsiveReservationList}
        onClose={() => {
          setCpsiveReservationList(false);
        }}
        content={
          <CpsiveReservationList
            parameter={cpsiveReservationList?.param}
            initData={cpsiveReservationList?.data}
          />
        }
      />
    </>
  );
};

export default Detail;
