/**
 * 在列表容器组件上使用该hook，以便在 主动修改 列表尺寸时，重新渲染列表项的Tooltip
 */
import React, { useEffect, useReducer } from 'react';
import { throttle } from 'lodash';

interface Props {
  /** 指向被观察DOM元素的ref，与selector二选一 */
  ref?: React.MutableRefObject<HTMLDivElement | null>;
  /** 可以拿到被观察DOM元素的selector，与ref二选一 */
  selector?: string;
  wait?: number;
}

export const useWidthObserver = ({ ref, selector, wait = 500 }: Props) => {
  const [resizeFlag, notifyResize] = useReducer(() => Date.now(), 0);

  useEffect(() => {
    let target;

    if (ref) {
      target = ref.current;
    } else if (selector) {
      target = document.querySelector(selector);
    }
    if (!target) {
      throw Error('未找到观察目标DOM元素，请检查……');
    }
    const throttledHandler = throttle(() => notifyResize(), wait, { trailing: true });
    const observer = new MutationObserver(throttledHandler);

    observer.observe(target, { attributes: true, attributeFilter: ['style'] });
    return () => observer.disconnect();
  }, [ref, selector]);

  return [resizeFlag];
};
