import { useRef, FC } from 'react';
import { Space, List, Form } from 'antd';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import classnames from 'classnames';
import { BlIcon } from 'src/components';
import { ControlType } from 'src/dict/sop';
import { componentMap, NEW_CONTROL_INDEX } from '../../constants';
import { LocalControlItem, DragItemTypes, DragItem, PreviewCompProps } from '../../types';
import '../../style.scss';

interface Props {
  id: string;
  index: number;
  selected: boolean;
  data: LocalControlItem;
  moveControl: (dragIndex: number, hoverIndex: number, controlType: ControlType) => void;
  selectControl: (localKey: string) => void;
  removeControl: (localKey: string) => void;
  copyControl: (localKey: string) => void;
}

/** 可拖拽列表项，在中间预览区展示 */
const DraggableItem: FC<Props> = ({
  id,
  index,
  selected,
  data,
  moveControl,
  selectControl,
  removeControl,
  copyControl,
}) => {
  const { name, controlType, _localKey, isRequired, remark, inputPrompt, selectMethod } = data;
  const ref = useRef<HTMLDivElement | null>(null);
  const [{ isOver, isDownward }, drop] = useDrop({
    accept: [DragItemTypes.component, DragItemTypes.cover],
    collect: (monitor: DropTargetMonitor<DragItem>) => {
      const { index: dragIndex } = monitor.getItem() || {};
      const isOver = monitor.isOver();

      if (dragIndex === undefined || dragIndex === index) {
        return {};
      }
      let isDownward = dragIndex < index;

      // 如果是将新控件拖入, 为了让它能插到首位, 需要判断一下hover高度
      if (
        isOver &&
        dragIndex === NEW_CONTROL_INDEX &&
        index === 0 &&
        ref.current &&
        monitor.getClientOffset()
      ) {
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

        isDownward = hoverClientY > hoverMiddleY;
      }
      return {
        isOver,
        isDownward,
      };
    },
    drop: (item: DragItem) => {
      let insertIndex = index;

      // 新控件index为负, 默认会插到hover项的前面, 故需特殊处理下
      if (item.index === NEW_CONTROL_INDEX && isDownward) {
        insertIndex = index + 1;
      }
      moveControl(item.index, insertIndex, item.controlType);
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: DragItemTypes.component,
    item: { id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));
  const dropClassName = isDownward ? 'drop-over-downward' : 'drop-over-upward';
  const opacity = isDragging ? 0 : 1;
  const CompBody = componentMap.get(controlType as ControlType) as FC<PreviewCompProps>;
  const displayName = name === '' ? '未命名控件' : name;

  return CompBody ? (
    <List.Item
      className={classnames(
        'control-list-item',
        selected ? 'control-list-item-selected' : '',
        isOver ? dropClassName : '',
      )}
      onClick={() => selectControl(_localKey)}
    >
      <div ref={ref} className="control-component" style={{ opacity }}>
        <BlIcon ref={drag} type="iconrenyituozhuai" />
        <div className="ant-form ant-form-horizontal control-comp-wrapper">
          <Form.Item
            label={displayName}
            required={isRequired}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            extra={remark}
          >
            <CompBody inputPrompt={inputPrompt} selectMethod={selectMethod} />
          </Form.Item>
        </div>
        <Space style={{ margin: '0 8px' }} className="control-action">
          <BlIcon
            type="iconmianxingfuzhi"
            className="hover-icon"
            title="复制"
            onClick={(e) => {
              e.stopPropagation();
              copyControl(_localKey);
            }}
          />
          <BlIcon
            type="iconshanchu-guanbi"
            className="hover-icon"
            title="删除"
            onClick={(e) => {
              e.stopPropagation();
              removeControl(_localKey);
            }}
          />
        </Space>
      </div>
    </List.Item>
  ) : null;
};

export default DraggableItem;
