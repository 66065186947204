const schemeListPath = '/sop/processEngine/scheme';

export const schemePath = {
  list: schemeListPath,
  create: `${schemeListPath}/:bizType/create`,
  detail: `${schemeListPath}/:id/detail`,
  edit: `${schemeListPath}/:id/edit`,
};

const taskListPath = '/sop/exec/task';

export const taskPath = {
  list: taskListPath,
};
