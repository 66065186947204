import React, { useEffect, useState } from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import { fetchShiftGet } from 'src/api/ytt/calendar-domain';
import DetailInfo from './components/detailBaseInfo';

interface Props extends RouteComponentProps {
  match: match<{ id: string }>;
}

const ShiftDetailPage = (props: Props) => {
  const { match } = props;
  const shiftId = Number(match?.params?.id);

  return <DetailInfo shiftId={shiftId} type="page" />;
};

export default ShiftDetailPage;
