import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  BlIcon,
  LinkTooltip,
  QrCode,
  SingleOperationLogComponent,
  TagList,
  ThumbnailPopover,
  Tooltip,
} from 'components';
import { Space, Dropdown, Menu, Tag, Popover, Form, message } from 'antd';
import { RecordListLayout } from 'src/layout';
import { fieldTypeList } from 'src/utils';
import {
  fetchMaterialDelete,
  fetchMaterialList,
  fetchMaterialStart,
  fetchMaterialStop,
} from 'src/api/ytt/material-domain/Web_MaterialBaseInfo';
import { patchRequest } from '../units/utils';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import BcBlMultiCascader from './share/Cascader/BcBlMultiCascader';
import { replaceSign } from 'src/utils/constants';
import renderUsageStatus from 'src/page/share/renderUsageStatus';
import { usageStatus } from 'src/dict/common/mappings';
import lookup from 'src/dict';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import _ from 'lodash';
import BcBlCascader from 'src/components/Cascader';
import { formatTimeForRender } from 'src/utils/formatters';
import authDict from 'src/utils/auth';
import { CRUD } from 'src/dict/common';

interface TableRowType {
  id: number;
  code: string;
  name: string;
  qrCode: string;
  remark: string;
  enableFlag: number;
  categoryName: string;
  unitName: string;
  attributes: any[];
  documents: any[];
}
interface Props extends RouteComponentProps {}
const MaterialList = (props: Props) => {
  const { history } = props;
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [delForm] = Form.useForm();
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [materialId, setMaterialId] = useState<number>(0);

  const handleFinish = async (id?: number | any[]) => {
    try {
      const value = await delForm?.validateFields();
      let ids;

      if (id) {
        // 操作列的删除
        ids = typeof id === 'number' ? [id] : id;
      } else {
        // 按钮的删除
        ids = selectedRowKeys;
      }
      const { code, message: mes } = await fetchMaterialDelete({
        ids,
        reason: value.delReason,
      });

      if (code === 200) {
        message.success('删除成功');
        // 刷新list
        setRefreshMarker(new Date().getTime());
        return;
      }
      message.error(mes);
    } catch (error) {
      console.log(error);
    }
  };
  const dataColumns = [
    {
      title: '物料编号',
      dataIndex: 'code',
      type: 'text',
      isFilter: true,
      sorter: true,
      width: 150,
      render: (text: string, record: TableRowType, index: number, config: any) => {
        return (
          <LinkTooltip
            to={`/modeling/materialModeling/materialList/${record.id}/details`}
            text={text}
            width={config.contentWidth}
            auth={authDict.material_detail}
          />
        );
      },
    },
    {
      title: '物料名称',
      dataIndex: 'name',
      type: 'text',
      isFilter: true,
      sorter: true,
      width: 150,
      render: (text: string, record: TableRowType, index: number, config: any) => {
        return (
          <LinkTooltip
            to={`/modeling/materialModeling/materialList/${record.id}/details`}
            text={text}
            width={config.contentWidth}
            auth={authDict.material_detail}
          />
        );
      },
    },
    {
      title: '物料属性',
      dataIndex: 'attributes',
      type: 'number',
      isFilter: true,
      width: 250,
      renderItem: <BcBlMultiCascader type={'materialProperty'} nameLabel={'物料属性'} />,
      render: (
        text: { attributeName: string; attributeItemName: string; attributeId: number }[],
      ) => {
        if (_.isEmpty(text)) return replaceSign;

        return (
          <TagList
            dataSource={_.map(text, ({ attributeName, attributeItemName, attributeId }) => ({
              label: `${attributeName}:${attributeItemName}`,
              value: attributeId,
            }))}
          />
        );
      },
    },
    {
      title: '物料规格',
      dataIndex: 'specification',
      isFilter: true,
      width: 150,
    },
    {
      title: '物料分类',
      dataIndex: 'category',
      type: 1,
      isFilter: true,
      // sorter: true,
      width: 150,
      renderItem: (
        <BcBlCascader
          fetchType={'materialCategory'}
          nameLabel={'物料分类'}
          popupPlacement={'bottomRight'}
        />
      ),
      render: (text: { categoryName: string; relationId: number; categoryIds: number[] }) =>
        text?.categoryName || '-',
    },
    {
      title: '主单位',
      dataIndex: 'unitName',
      type: 'text',
      width: 150,
    },
    {
      title: '物料状态',
      dataIndex: 'enableFlag',
      type: fieldTypeList.select,
      isFilter: true,
      sorter: true,
      width: 150,
      selectProps: {
        options: usageStatus,
      },
      render: renderUsageStatus,
    },
    {
      title: '标识码',
      dataIndex: 'qrCode',
      width: 150,
      render: (text: string) => {
        return text ? (
          <Popover
            placement="rightTop"
            content={
              <div>
                <QrCode value={text} />
                <div style={{ textAlign: 'center' }}>{text}</div>
              </div>
            }
          >
            <BlIcon type="iconerweima" style={{ fontSize: 20 }} />
          </Popover>
        ) : (
          replaceSign
        );
      },
    },
    {
      title: '物料图片', // length = 3 的数组
      dataIndex: 'documents',
      width: 150,
      render: (text: { id: number; url: string; relationId: number }[]) => {
        return text?.map((item) => {
          return <ThumbnailPopover document={item} />;
        });
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 180,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
    },
  ];
  const config = {
    mainMenu: [
      {
        title: '导出',
        showExport: true,
        auth: authDict.material_export,
        icon: <BlIcon type="icondaochu" />,
        onClick: () => {
          openModal({
            optType: 'export',
            businessType: BUSINESS_TYPE.material,
          });
        },
      },
      {
        title: '新建物料',
        auth: authDict.material_add,
        icon: <BlIcon type="iconxinjiantianjia" />,
        onClick: () => {
          history.push('/modeling/materialModeling/materialList/create');
        },
        items: [
          {
            title: '导入',
            showExport: true,
            auth: authDict.material_import,
            onClick: () => {
              openModal({
                optType: 'import',
                businessType: BUSINESS_TYPE.material,
              });
            },
          },
        ],
      },
    ],
    batchMenu: [
      {
        title: '启用',
        auth: authDict.material_enable_disable,
        onClick: (onSuccess: any, onFail: any) => {
          patchRequest(fetchMaterialStart, selectedRowKeys, '启用成功', onSuccess, onFail);
        },
      },
      {
        title: '停用',
        auth: authDict.material_enable_disable,
        onClick: (onSuccess: any, onFail: any) => {
          patchRequest(fetchMaterialStop, selectedRowKeys, '停用成功', onSuccess, onFail);
        },
      },
      // {
      //   title: '删除',
      //   onClick: (onSuccess: any, onFail: any) => {
      //     onFail();
      //     BLDelConfirm('是否删除当前物料', delForm, () => {
      //       handleFinish();
      //     });
      //   },
      // },
    ],
    getOperationList: (record: any) => {
      const { id } = record;

      return [
        {
          title: lookup('crud', CRUD.view),
          auth: authDict.material_detail,
          onClick: () => {
            history.push(`/modeling/materialModeling/materialList/${id}/details`);
          },
        },
        {
          title: lookup('crud', CRUD.edit),
          auth: authDict.material_edit,
          onClick: () => {
            history.push(`/modeling/materialModeling/materialList/${id}/edit`);
          },
        },
        {
          title: lookup('common.changeStatusAction', record?.enableFlag),
          auth: authDict.material_enable_disable,
          onClick: () => {
            const api = record?.enableFlag ? fetchMaterialStop : fetchMaterialStart;

            patchRequest(
              api,
              [id],
              `${lookup('common.changeStatusAction', record?.enableFlag)}成功`,
              () => {
                setRefreshMarker(new Date().getTime());
              },
            );
          },
        },
        // {
        //   title: lookup('crud', CRUD.delete),
        //   onClick: () => {
        //     BLDelConfirm('是否删除当前物料', delForm, () => {
        //       handleFinish(id);
        //     });
        //   },
        // },
        {
          title: '操作记录',
          auth: authDict.material_operrecord,
          onClick: () => {
            setMaterialId(id);
            setVisibleLog(true);
          },
        },
      ];
    },
    columns: dataColumns.map((column) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isFilter, type, ...rest } = column;

      return { ...rest };
    }),
    rowKey: 'id',
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((column) => {
        // 如果是嵌套结构的dataIndex，需要不能直接将column复制给name，需要分开处理
        const filter = {
          label: column.title,
          name: column.dataIndex,
          type: column.type,
        } as any;

        if (column.type === fieldTypeList.select) {
          filter.selectProps = column.selectProps;
        }
        if (column.renderItem) {
          filter.renderItem = column.renderItem;
        }
        // console.log('filter', filter);
        return filter;
      }),
  };
  const formatDataToQuery = (params: any) => {
    // requestFn 查询
    if (params?.attributes) {
      const attributeIds = params.attributes;

      delete params.attributes;
      params = {
        ...params,
        attributeIds,
      };
    }
    if (params?.category) {
      const categoryIds = params.category;

      delete params.category;
      params = {
        ...params,
        categoryIds,
      };
    }

    dispatch.excelImport.updateBusinessData({ businessData: params });
    return params;
  };
  const formatDataToDisplay = (params: any = {}) => {
    // table上的筛选展示，回显处理
    const retData = { ...params };

    retData.enableFlag = lookup('common.changeStatusAction', retData?.enableFlag);

    return retData;
  };
  const formatDataToFormDisplay = (params: any) => {
    // 筛选抽屉，回显处理
    const retData = { ...params };

    if (retData?.enableFlag) {
      retData.enableFlag = Number(retData.enableFlag);
    }

    return retData;
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        columns={config.columns}
        filterList={config.filterList}
        rowKey={config.rowKey}
        batchMenu={config.batchMenu}
        mainMenu={config.mainMenu}
        getOperationList={config.getOperationList}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={formatDataToDisplay}
        formatDataToFormDisplay={formatDataToFormDisplay}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey) => {
          setSelectRowKeys(selectKey);
        }}
        requestFn={fetchMaterialList}
        refreshMarker={refreshMarker}
        configcacheKey={'material'}
        useIndex={false}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={materialId as number}
          objectCode={OBJECT_OF_CODE.material}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(MaterialList);
