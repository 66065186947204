import { Form, Select } from 'antd';
import { DataFormLayout, DataFormLayoutForModal, DataFormLayoutInfoBlock } from 'src/layout';
import { fetchLayoutDetailByObjectCode } from 'src/api/ytt/layout-domain';
import { useEffect, useState } from 'react';
import { PageType } from 'src/dict/customLayout';
import { notifyJumpByLayout } from './notifyJumpDict';

interface Props {
  objectData: any;
  visible: boolean;
  handleCancel: () => void;
}

export const GetCustomLayoutForNotify = ({ objectData, visible, handleCancel }: Props) => {
  const [modalForm] = Form.useForm();

  const [layoutItems, setLayoutItems] = useState([] as any);

  const fetchData = async () => {
    try {
      const { data } = await fetchLayoutDetailByObjectCode({
        objectCode: objectData?.objectCode,
        instanceId: objectData?.id,
        type: PageType.detail,
      });

      setLayoutItems((data || [])?.map(({ id, name }) => ({ key: id, label: name, value: id })));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [objectData]);

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    items: [
      {
        label: '请选择页面布局',
        name: 'layoutId',
        rules: [{ required: true, message: '请选择页面布局' }],
        render: () => <Select options={layoutItems} />,
      },
    ],
  };

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();

      notifyJumpByLayout(objectData, value?.layoutId);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      onClose={handleCancel}
      width={800}
      content={
        <DataFormLayout
          form={modalForm}
          info={[baseInfo]}
          onCancel={handleCancel}
          onFinish={handleFinish}
        />
      }
    />
  );
};
