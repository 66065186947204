import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { Button } from 'antd';
import { SearchSelect } from 'src/components';
import { fetchCustomMadeReportQueryCustomMadeReportConfigDetailFront } from 'src/api/ytt/e-report-domain/customizedReports';
import _ from 'lodash';
import LocalStorage from 'src/utils/localStorage';
import { FIELDS } from 'src/utils/constants';

const CustomizedReportReview = () => {
  const [reportUrl, setReportUrl] = useState<any>();
  const [iframeTop, setIframeTop] = useState<number>(50);

  const getReportUrl = async (allowViewUserId: number) => {
    const reportDetail = await fetchCustomMadeReportQueryCustomMadeReportConfigDetailFront({
      id: allowViewUserId,
    });

    setReportUrl(reportDetail.data?.reportUrl);
  };

  // 全屏状态下监听不了Esc,可以监听resize,且加个防抖
  window.onresize = _.debounce(() => {
    if (document.fullscreenElement) {
      setIframeTop(0);
    } else {
      setIframeTop(50);
    }
  }, 200);

  return (
    <>
      <div className={styles.overallContainer}>
        <div
          className={styles.fullScreenButton}
          onClick={() => {
            const dashBoard = document.getElementById('fullScreen');

            if (dashBoard) dashBoard.requestFullscreen();
          }}
        >
          <Button style={{ width: 100, height: 40, fontSize: 20, borderRadius: 8 }} type="primary">
            全屏
          </Button>
        </div>
        <div>
          <div className={styles.searchSelect}>
            <SearchSelect
              style={{ width: 200 }}
              fetchType="reportReview"
              placeholder="报表目录"
              onChange={(e: any) => {
                if (e) getReportUrl(e.value);
              }}
              labelInValue
              getPopupContainer={(e: any) => e.parentNode}
            />
          </div>
          {reportUrl ? (
            <div
              className={styles.reportIframeDiv}
              style={{ paddingTop: iframeTop }}
              id={'fullScreen'}
            >
              <iframe className={styles.report} src={`${reportUrl}`} />
            </div>
          ) : (
            <div className={styles.whiteBlock}>
              <div className={styles.choseSize}>请选择定制化报表</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomizedReportReview;
