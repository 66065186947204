import { useState, useEffect } from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import { BlIcon, SingleOperationLogComponent, TagList } from 'src/components';
import { DetailLayout } from 'layout';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { ProcessRoutingDetail } from './components';
import moment from 'moment';
import { replaceSign } from 'src/utils/constants';
import lookup, { appEnum } from 'src/dict';
import _Time from 'src/utils/dataTypes/time';
import { fetchProcessRouteDetail } from 'src/api/ytt/med-domain/process-route';
import { updateStatus } from './utils';
import _ from 'lodash';
import renderUsageStatus from 'src/page/share/renderUsageStatus';
import authDict from 'src/utils/auth';
import { getMaterialAttrs } from 'src/entity/material';
import { gotoRoutingEdit } from './navigation';
import { ENGINEER_PATH } from '../navigation';

interface DetailPageProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const { UsageStatus } = appEnum.Common;
const DetailPage = (props: DetailPageProps) => {
  const { match, history } = props;
  const id = Number(match?.params?.id);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState({} as any);

  const fetchData = async () => {
    try {
      if (!id) {
        return;
      }
      const { data } = await fetchProcessRouteDetail({ id });

      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const baseInfo = [
    {
      title: '基本信息',
      items: [
        { label: '工艺路线编号', dataIndex: 'code' },
        { label: '工艺路线名称', dataIndex: 'name' },
        {
          label: '状态',
          dataIndex: 'status',
          render: renderUsageStatus,
        },
        {
          label: '所属部门',
          dataIndex: 'department',
          render: (value: any) => value?.name ?? replaceSign,
        },
        {
          label: '物料编号',
          dataIndex: 'materialCode',
          render: () => dataSource?.material?.baseInfo?.code ?? replaceSign,
        },
        {
          label: '物料名称',
          dataIndex: 'materialName',
          render: () => dataSource?.material?.baseInfo?.name ?? replaceSign,
        },
        {
          label: '物料属性',
          dataIndex: ['material', 'attribute'],
          render: (_text: any, record: any) => (
            <TagList dataSource={getMaterialAttrs(record?.material)} />
          ),
        },
        {
          label: '物料分类',
          dataIndex: 'materialCategoryName',
          render: () => {
            return dataSource?.material?.category?.name ?? replaceSign;
          },
        },
        {
          label: '物料规格',
          dataIndex: 'specification',
          render: () => dataSource?.material?.baseInfo?.specification ?? replaceSign,
        },
        { label: '单位', dataIndex: 'unit', render: (value: any) => value?.name ?? replaceSign },
        {
          label: '启用SOP',
          dataIndex: 'enableSop',
          render: lookup('processRoute.yn'),
        },
        {
          label: '备注',
          dataIndex: 'remark',
          render: (value: any) => value ?? replaceSign,
        },
      ],
    },
    {
      title: '工序列表',
      items: [
        {
          label: '',
          dataIndex: 'process',
          render: () => (
            <ProcessRoutingDetail
              processes={dataSource.processes}
              processConnections={dataSource.processConnections}
            />
          ),
        },
      ],
    },
    {
      title: '其他信息',
      items: [
        {
          label: '创建人',
          dataIndex: 'creator',
          render: (creator: any) => {
            return creator ? <AvatarDisplay {...creator} isShowTag={false} isUser /> : replaceSign;
          },
        },
        {
          label: '创建时间',
          dataIndex: 'createdAt',
          isFullLine: true,
          render: (createdAt: number) => {
            if (createdAt) {
              return moment(createdAt).format('YYYY-MM-DD HH:mm:ss');
            }
            return replaceSign;
          },
        },

        {
          label: '更新人',
          dataIndex: 'operator',
          render: (operator: any) => {
            return operator ? (
              <AvatarDisplay {...operator} isShowTag={false} isUser />
            ) : (
              replaceSign
            );
          },
        },
        {
          label: '更新时间',
          dataIndex: 'updatedAt',
          render: (updatedAt: number) => {
            if (updatedAt) {
              return moment(updatedAt).format('YYYY-MM-DD HH:mm:ss');
            }
            return replaceSign;
          },
        },
      ],
    },
  ];

  const baseMenu = _.compact([
    {
      key: 'operate',
      title: '操作记录',
      auth: authDict.routing_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
    },
    {
      key: 'active',
      icon: <BlIcon type="iconqiyong" />,
      title: lookup('changeStatusAction', dataSource?.status),
      auth: authDict.routing_enable_disable,
      onClick: () => {
        const isEnable = dataSource?.status === UsageStatus.enabled;
        const actionStatus = isEnable ? UsageStatus.disabled : UsageStatus.enabled;
        const displayText = isEnable ? '停用成功。关联的物料清单也全部停用成功' : '启用成功';

        updateStatus(id, actionStatus, displayText, fetchData);
      },
    },
    {
      key: 'copy',
      auth: authDict.routing_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => history.push(`${ENGINEER_PATH}/routing/${id}/copy`),
      title: '复制',
    },
    dataSource?.status === UsageStatus.disabled && {
      key: 'edit',
      auth: authDict.routing_edit,
      onClick: () => gotoRoutingEdit(id),
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ]);

  return (
    <>
      <DetailLayout
        title="工艺路线详情"
        info={baseInfo}
        dataSource={dataSource}
        baseMenu={baseMenu}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={id}
          objectCode={'Routing'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailPage;
