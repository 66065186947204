/**
 * @enum 销售订单 业务模块枚举值
 */

/**
 * 供应链权限
 */
export enum Auth {
  supplyChain = 1,
}
/**
 * 订单状态
 */
export enum OrderStatus {
  /**
   * 新建
   */
  create = 1,
  /**
   * 结束
   */
  finished,
}
/**
 * 订单来源
 */
export enum OriginType {
  /**
   * 普通
   */
  ordinary,
  /**
   * 协同
   */
  collaborate,
}
/**
 * 生产状态
 */
export enum ProductionStatus {
  /**
   * 待计划
   */
  toPlan = 1,
  /**
   * 部分计划
   */
  partPlan,
  /**
   * 已计划
   */
  planned,
  /**
   * 已完工
   */
  completed,
}
/**
 * 工单状态
 */
export enum AssociatedWorkOrder {
  /**
   * 新建
   */
  created = 1,
  /**
   * 已下发
   */
  issued,
  /**
   * 执行中
   */
  perform,
  /**
   * 已完工
   */
  completion,
  /**
   * 已关闭
   */
  closed,
}
/**
 * 发货状态
 */
export enum ShipmentStatus {
  /**
   * 待发货
   */
  toBeDelivered = 1,
  /**
   * 部分发货
   */
  partiallyShipped,
  /**
   * 全部发货
   */
  allShipped,
}
/**
 * 退货状态
 */

/**
 * 协同采购申请单 状态
 */
export enum PurchaseRequisitionStatus {
  /**
   * 待处理
   */
  pending = 0,
  /**
   * 已接受
   */
  accepted,
  /**
   * 已拒绝
   */
  rejected,
}
/**
 * 发货单状态
 */
export enum InvoiceStatus {
  /**
   * 新建
   */
  create = 1,
  /**
   * 执行中
   */
  inprogress,
  /**
   * 结束
   */
  finish,
  /**
   * 关闭
   */
  close,
}
/**
 * 物料业务范围
 */ export enum MaterialBizRange {
  /** 虚拟件 */
  phantom = 0,
  /** 仓储 */
  warehousing,
  /** 采购 */
  purchase,
  /** 销售 */
  sales,
  /** 生产 */
  production,
  /** 质量 */
  quality,
}
