/**
 * 字段级联
 */

import { useEffect, useReducer, useState } from 'react';
import { message } from 'antd';
import _ from 'lodash';
import { RecordListLayout } from 'src/layout';
import {
  fetchCascadeListPage,
  fetchCascadeDisable,
  fetchCascadeEnable,
  fetchCascadeDelete,
  FetchCascadeListPageResponse,
} from 'ytt/metadata-domain/fieldCascading';
import { fetchCustomFieldGetListByObjectCode } from 'ytt/metadata-domain/custom_field';
import renderUsageStatus from 'src/page/share/renderUsageStatus';
import EditModal from './editModal';
import { UsageStatus } from 'src/dict/common';
import { qs } from 'src/utils';
import { valueOrHolder } from 'src/utils/formatters';
import lookup from 'src/dict';
import { Field } from '../type';
import type { RecordListLayoutProps, OperationListItem } from '@blacklake-web/layout';

type ListItem = Required<FetchCascadeListPageResponse>['data']['list'][number];

const placeholder = '请输入控制字段或依赖字段';
const initEditModalState = {
  visible: false,
  initData: null,
};

const FieldCascading = () => {
  const [fields, setFields] = useState<Field[]>([]);
  const [editModalState, setEditModalState] = useState<any>(initEditModalState);
  const [refreshMarker, refresh] = useReducer(() => Date.now(), 0);
  const { objectCode } = qs.parse();

  useEffect(() => {
    fetchCustomFieldGetListByObjectCode({ objectCode }).then((res) => setFields(res.data!));
  }, [objectCode]);

  const renderFieldName = (fieldCode: string) =>
    valueOrHolder(_.find(fields, { fieldCode })?.fieldName);
  const columns = [
    {
      title: '控制字段',
      dataIndex: 'fieldCode',
      width: 280,
      render: renderFieldName,
    },
    {
      title: '依赖字段',
      dataIndex: 'refFieldCode',
      width: 280,
      render: renderFieldName,
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 150,
      render: renderUsageStatus,
    },
    {
      title: '依赖字段只有唯一值时默认选中',
      dataIndex: 'refUnique',
      width: 250,
      render: lookup('yn'),
    },
  ];

  const mainMenu = [
    {
      title: '新建级联关系',
      icon: 'iconxinjiantianjia',
      onClick: () => setEditModalState({ ...initEditModalState, visible: true }),
      items: [],
    },
  ];

  const getOperationList = (record: ListItem): OperationListItem[] => {
    const { id, status, fieldCode, refFieldCode } = record;
    const opName = lookup('changeStatusAction', status);

    return [
      {
        title: '编辑',
        onClick: () => {
          const controlField = _.find(fields, { fieldCode });
          const refField = _.find(fields, { fieldCode: refFieldCode });

          if (_.isNil(controlField)) {
            message.error(`控制字段未找到，请检查字段编号："${fieldCode}"的字段是否存在或启用！`);
            return;
          }
          if (_.isNil(refField)) {
            message.error(
              `依赖字段未找到，请检查字段编号："${refFieldCode}"的字段是否存在或启用！`,
            );
            return;
          }

          setEditModalState({
            visible: true,
            initData: { ...record, objectCode },
          });
        },
      },
      {
        title: opName,
        onClick: () => {
          const fn = status === UsageStatus.enabled ? fetchCascadeDisable : fetchCascadeEnable;

          fn({ id }).then(() => {
            message.success(`${opName}成功！`);
            refresh();
          });
        },
      },
      {
        title: '删除',
        reasonconfirm: { message: '该级联关系删除后不能恢复，确认删除？' },
        onClick: (reason: string) => {
          // @ts-ignore
          fetchCascadeDelete({ id, reason }).then(() => {
            message.success('删除完成。');
            refresh();
          });
        },
      },
    ];
  };

  const recordListProps: RecordListLayoutProps<any> = {
    columns,
    getOperationList,
    mainMenu,
    maxOperationCount: 3,
    placeholder,
    requestFn: fetchCascadeListPage,
    formatDataToQuery: (data) => ({ ...data, objectCode }),
    refreshMarker,
    useQuickFilter: false,
    configcacheKey: 'fieldCascadingList',
  };

  return (
    <>
      <RecordListLayout {...recordListProps} />
      <EditModal
        visible={editModalState.visible}
        hide={() => setEditModalState(initEditModalState)}
        initData={editModalState.initData}
        refreshList={refresh}
      />
    </>
  );
};

export default FieldCascading;
