import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Checkbox, Spin } from 'antd';
import { EventDataNode } from 'antd/lib/tree';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { RootState } from 'src/store';
import { useTreeContent } from 'src/layout';
import { BlTooltip } from 'src/components';
import {
  fetchLocationGetAllList,
  FetchLocationGetAllListResponse,
} from 'src/api/ytt/resource-domain/areaConfig';
import { ComplementTree } from 'src/types/apiTypes';
import { AreaListItemType } from './index.d';
import ResourceDefinitionList from './list';
import Styles from './index.module.scss';
import { appEnum } from 'src/dict';

type AreaListItemTypeWithKey = ComplementTree<AreaListItemType & { key: number }>;

const formatTreeData = (
  responseData: FetchLocationGetAllListResponse['data'],
): AreaListItemTypeWithKey[] => {
  return (
    responseData?.map((responseNode) => {
      const { id, children, name, ...rest } = responseNode;

      return {
        key: id!,
        id,
        name,
        title: name,
        children: formatTreeData(children as FetchLocationGetAllListResponse['data']),
        ...rest,
      };
    }) ?? []
  );
};

const icon = { showLeafIcon: false };

export default function ResourceDefinition() {
  // 区域树数据
  const [treeSource, setTreeSource] = useState<AreaListItemTypeWithKey[]>();
  // 选择区域后刷新列表
  const [refreshListMarker, setRefreshListMarker] = useState(0);
  // 是否包含子区域
  // const [subLocationFlag, setSubLocationFlag] = useState(false);
  // 选中行的key
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>();
  // 区域加载中（组织设备列表提前加载）
  const [loading, setLoading] = useState(false);
  // 过滤后的树
  const [filteredTree, setFilteredTree] = useState<AreaListItemTypeWithKey[]>();
  const dispatch = useDispatch();
  const maxHeight = document.getElementById('resources')?.clientHeight; // treeContent的高度

  const resourses = useSelector((state: RootState) => state?.resources, shallowEqual);
  const { selectNoLocationFlag, subLocationFlag, areaValue } = resourses;

  const customTitleRender = (data: AreaListItemTypeWithKey) => {
    const nodeTitle = data?.name;

    return (
      <div style={{ display: 'flex' }} className="treeNode">
        <div>
          <BlTooltip width={100} text={nodeTitle} className="treeNode">
            {nodeTitle}
          </BlTooltip>
        </div>
      </div>
    );
  };

  const onSelect = (selectedData: EventDataNode) => {
    const indexRoute = selectedData.pos.split('-');

    indexRoute.shift();
    let currList: AreaListItemTypeWithKey[] = filteredTree || treeSource || [];
    const idRouteArr: number[] = [];

    indexRoute.forEach((idx: string) => {
      const currArea = currList?.[Number(idx)];

      idRouteArr.push(Number(currArea?.id));
      currList = currArea?.children as AreaListItemTypeWithKey[];
    });

    dispatch({ type: 'resources/setAreaValue', payload: idRouteArr });
    setSelectedKeys([selectedData.key]);
    setRefreshListMarker(Date.now());
  };

  const changeSubLocationFlag = (e: CheckboxChangeEvent) => {
    dispatch({ type: 'resources/setSubLocationFlag', payload: e?.target?.checked });
    setRefreshListMarker(Date.now());
  };

  const areaFooter = (
    <div className={Styles.areaFooter}>
      <Checkbox
        checked={subLocationFlag}
        disabled={selectNoLocationFlag}
        onChange={changeSubLocationFlag}
      >
        包含子区域
      </Checkbox>
      <a
        onClick={() => {
          setSelectedKeys([]);
          dispatch({ type: 'resources/selectNoLocationFlag' });
          setRefreshListMarker(Date.now());
        }}
        className={selectNoLocationFlag ? Styles.noLocationSelected : undefined}
      >
        查看无区域设备
      </a>
    </div>
  );

  const loadArea = async () => {
    try {
      setLoading(true);
      const response = await fetchLocationGetAllList({
        parentId: 0,
        enableStatus: appEnum.Common.UsageStatus.enabled,
      });

      if (response && response.code === 200) {
        const treeDataSource = formatTreeData(response.data);

        setSelectedKeys(areaValue);
        setTreeSource(treeDataSource);
        setFilteredTree(treeDataSource);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadArea();
  }, []);

  const treeView = useTreeContent({
    treeData: treeSource,
    showExpandBtn: true,
    detailContent: () => (
      <div className={Styles.treeDetailContent}>
        <Spin spinning={loading}>
          {treeSource && <ResourceDefinitionList refreshListMarker={refreshListMarker} />}
        </Spin>
      </div>
    ),
    onSelect,
    onSearch: (data: AreaListItemTypeWithKey[]) => setFilteredTree(data),
    checkable: false,
    titleRender: customTitleRender,
    bottomRender: areaFooter,
    showLine: { icon },
    resizableProp: {
      height: maxHeight,
      maxConstraints: [600, maxHeight],
      minConstraints: [300, maxHeight],
      isChangeHeight: false,
    },
    selectedKeys,
    searchPlaceholder: '请输入区域名称',
  });

  return (
    <div style={{ height: '100%' }} id="resources">
      {treeView}
    </div>
  );
}
