import lookup from 'src/dict';
import { BcAttachmentDetail, BlTable, TagList } from 'src/components';
import { renderUser } from 'src/page/share/renderUser';
import { DetailLayout, DetailLayoutInfoBlock, DetailLayoutMenuItem } from 'src/layout';
import { formatTimeForRender, formatDateForRender } from 'src/utils/formatters/dateTime';
import { valueOrHolder } from 'src/utils/formatters';
import _, { isEmpty, compact } from 'lodash';
import { renderAmount, formatTime, renderTimeUnit } from '../utils';
import { replaceSign } from 'src/utils/constants';
import _Array from 'src/utils/dataTypes/array';
import { DeliveryScheduleNoteExecStatus, DeliveryScheduleNoteSource } from 'src/dict/purchase';
interface Props {
  dataSource: any;
  baseMenu?: DetailLayoutMenuItem[];
}

const DetailsBaseInfo = (props: Props) => {
  const { dataSource, baseMenu } = props;

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '编号',
        dataIndex: 'code',
      },
      {
        label: '来源',
        dataIndex: 'source',
        render: lookup('purchase.deliveryScheduleNoteSource'),
      },
      {
        label: '供应商',
        dataIndex: ['supplier', 'name'],
        render: (text: string) => (typeof text === 'string' ? text : replaceSign),
      },
      {
        label: '订单所有人',
        dataIndex: 'owner',
        render: renderUser,
      },
      {
        label: '状态',
        dataIndex: 'execStatus',
        render: lookup('purchase.deliveryScheduleNoteExecStatus'),
      },
      {
        label: '计划单收货状态',
        dataIndex: 'receiveStatus',
        render: lookup('purchase.deliveryScheduleNoteReceiveStatus'),
      },
      {
        label: '备注',
        dataIndex: 'remark',
        render: valueOrHolder,
      },
      {
        label: '附件',
        dataIndex: 'attachments',
        render: (files: number[]) => {
          if (isEmpty(files)) return replaceSign;

          return <BcAttachmentDetail fileIds={files} />;
        },
      },
      {
        label: '协同附件',
        dataIndex: 'coordinationAttachments',
        render: (files: number[]) => {
          if (isEmpty(files)) return replaceSign;

          return <BcAttachmentDetail fileIds={files} />;
        },
      },
    ],
  };

  if (dataSource.execStatus == DeliveryScheduleNoteExecStatus.rejected) {
    baseInfo.items.push({
      label: '退回原因',
      dataIndex: 'returnReason',
      render: valueOrHolder,
    });
  }
  const materialInfo: DetailLayoutInfoBlock = {
    title: '物料信息',
    items: [
      {
        label: '',
        dataIndex: 'material',
        render: () => {
          const columns = compact([
            {
              title: '行号',
              dataIndex: 'lineNo',
              width: 150,
            },
            {
              title: '物料编号',
              dataIndex: ['material', 'baseInfo', 'code'],
              width: 200,
            },
            {
              title: '物料名称',
              dataIndex: ['material', 'baseInfo', 'name'],
              width: 200,
            },
            {
              title: '物料属性',
              dataIndex: ['material', 'attribute'],
              width: 250,

              render: (attribute: any) => {
                if (_Array.isEmpty(attribute)) {
                  return replaceSign;
                }
                return (
                  <TagList
                    dataSource={_.map(attribute, ({ id, name, attributeItem }) => ({
                      label: `${name}:${attributeItem?.content}`,
                      value: id,
                    }))}
                  />
                );
              },
            },
            {
              title: '物料规格',
              dataIndex: ['material', 'baseInfo', 'specification'],
              width: 200,
            },
            {
              title: '需求数',
              dataIndex: 'demandAmount',
              width: 150,
              render: renderAmount,
            },
            {
              title: '交货日期',
              dataIndex: 'deliveryDate',
              width: 200,
              render: formatDateForRender,
            },
            {
              title: '最早到货时间',
              dataIndex: 'earliestArrivalTime',
              width: 200,
              render: (time: number) => {
                return time ? formatTime(time) : replaceSign;
              },
            },
            {
              title: '最晚到货时间',
              dataIndex: 'latestArrivalTime',
              width: 200,
              render: (time: number) => {
                return time ? formatTime(time) : replaceSign;
              },
            },
            dataSource.source === DeliveryScheduleNoteSource.purchaseOrder && {
              title: '采购订单编号',
              dataIndex: 'purchaseOrderCode',
              width: 200,
            },
            dataSource.source === DeliveryScheduleNoteSource.purchaseOrder && {
              title: '采购订单行号',
              dataIndex: 'purchaseOrderItemSeqNum',
              width: 200,
            },
            {
              title: '物流时间',
              dataIndex: 'logisticsCycle',
              width: 150,
              render: (time: number, record: any) => {
                return time ? time + renderTimeUnit(record.logisticsTimeUnit) : replaceSign;
              },
            },
            {
              title: '计划收货数',
              dataIndex: 'planReceivedAmount',
              width: 150,
              render: renderAmount,
            },
            {
              title: '实际发货数',
              dataIndex: 'deliverAmount',
              width: 150,
              render: renderAmount,
            },
            {
              title: '收货接收数',
              dataIndex: 'receivedAmount',
              width: 150,
              render: renderAmount,
            },
          ]);

          return (
            <BlTable
              style={{ marginLeft: 24 }}
              scroll={{ y: 630 }}
              rowKey={(record) => record?.currentId}
              columns={columns}
              dataSource={dataSource.itemList}
              pagination={false}
            />
          );
        },
      },
    ],
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    column: 2,
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: renderUser,
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: formatTimeForRender,
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: renderUser,
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: formatTimeForRender,
      },
    ],
  };

  const infoArray = [baseInfo, materialInfo, otherInfo];

  return (
    <DetailLayout
      title={'交货计划单详情'}
      baseMenu={baseMenu}
      info={infoArray}
      dataSource={dataSource}
    />
  );
};

export default DetailsBaseInfo;
