import { useState, useEffect, FC } from 'react';
import { Form, Input, InputNumber, Select, Space } from 'antd';
import {
  getMaterialAttrs,
  getMaterialFeedUnit,
  getMaterialUnitInfo,
  getMaterialUnits,
} from 'src/entity/material';
import { DataFormLayout } from 'src/layout';
import _, { get, isEmpty, isNil, isNumber } from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { BlSortFormList, MaterialSelect, SearchSelect, TagList } from 'src/components';
import { pickModeMap, SubstitutionStrategyMap } from 'src/dict/productionPlanning/mappings';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import { appDict, appEnum } from 'src/dict';
import { pickModeEnum, SubstitutionStrategyEnum } from 'src/dict/productionPlanning';
import { validateBlInputNumberIntervalInt } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import { replaceSign } from 'src/utils/constants';
import { fetchWorkOrderCalculate } from 'src/api/ytt/med-domain-web';
import {
  formatMaterialToApi,
  setAssociatedFormFields,
} from 'src/page/knowledgeManagement/engineerData/bom/utils';

interface Props {
  params: any;
  name: string;
  onChange: (value?: any[]) => void;
}

const AlternativeMaterialsForm: FC<Props> = (props) => {
  const [form] = Form.useForm<any>();
  const { params, onChange, name } = props;

  const [material, setMaterial] = useState(); // 被替代物料信息

  useEffect(() => {
    const { materialId, unitId, workOrderAlternativePlan } = params;

    if (materialId) {
      setMaterial(JSON.parse(materialId?.value ?? '{}'));

      form.setFields([
        {
          name: ['originalAlternativeMaterials', 0, 'unitId'],
          value: workOrderAlternativePlan?.originalAlternativeMaterials?.[0]?.unitId ?? unitId,
        },
        {
          name: ['originalAlternativeMaterials', 0, 'materialId'],
          value: materialId,
        },
        {
          name: ['originalAlternativeMaterials', 0, 'useRatio'],
          value: workOrderAlternativePlan?.originalAlternativeMaterials?.[0]?.useRatio,
        },
      ]);
    }
  }, [params]);

  /**
   * 校验行号,不能重复
   */
  const validatorIndex = (rule: any, value: any) => {
    const listValue = form.getFieldValue('alternativeMaterials');

    const firstIndex = _.findIndex(listValue, { priority: value });
    const lastIndex = _.findLastIndex(listValue, { priority: value });

    if (firstIndex != lastIndex) {
      return Promise.reject('不能有重复的优先级');
    }

    return Promise.resolve('');
  };

  /**
   * 校验替代物料,不能重复 不能等于被替代物料
   */
  const validatorMaterials = (rule: any, value: any) => {
    const listValue = form.getFieldValue('alternativeMaterials');

    const firstIndex = _.findIndex(listValue, { materialId: value });
    const lastIndex = _.findLastIndex(listValue, { materialId: value });

    if (
      listValue.find(
        (e: any) =>
          JSON.parse(e?.materialId?.value ?? '{}')?.baseInfo?.id == material?.baseInfo?.id,
      )
    ) {
      return Promise.reject('替代料不可与被替代料相同');
    }

    if (firstIndex != lastIndex) {
      return Promise.reject('不能有重复的物料');
    }

    return Promise.resolve('');
  };

  /**
   * 获取行物料其他信息
   */
  const getMaterialInfo = (name: string, fieldName?: number, key?: string, value?: any) => {
    let material = {};

    if (value) {
      material = JSON.parse(value?.value ?? '{}');
    } else if (_.isNumber(fieldName)) {
      material = JSON.parse(form.getFieldValue([name, fieldName, 'materialId'])?.value ?? '{}');
    }

    if (key) {
      return _.get(material, key);
    }

    return material;
  };

  const getColumns = (): ColumnProps<FormListFieldData & any>[] => {
    return _.compact([
      {
        title: '',
        dataIndex: 'id',
        width: 1,
        render: (text, field) => {
          return (
            <Form.Item
              hidden
              name={[field.name, 'id']}
              fieldKey={[field.fieldKey, 'id']}
              style={{ marginBottom: '0' }}
            >
              <Input />
            </Form.Item>
          );
        },
      },
      {
        title: '',
        dataIndex: 'materialId',
        width: 1,
        render: (text, field) => {
          const baseSelectProps = {
            fetchType: 'materialCompleteInfo',
            style: { width: '100%' },
            placeholder: '请输入',
            labelInValue: true,
          };

          return (
            <Form.Item
              hidden
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'materialId']}
              name={[field.name, 'materialId']}
            >
              <SearchSelect {...baseSelectProps} />
            </Form.Item>
          );
        },
      },

      {
        title: '序号',
        dataIndex: 'lineNo',
        width: 120,
        render: (_text, field, index) => {
          return (
            <Form.Item
              name={[field.name, 'lineNo']}
              fieldKey={[field.fieldKey, 'lineNo']}
              style={{ marginBottom: '0' }}
            >
              {index + 1}
            </Form.Item>
          );
        },
      },
      {
        title: '使用比例',
        dataIndex: 'useRatio',
        width: 150,
        render: (_text, field) => {
          // const baseName = [name];
          return (
            <Form.Item dependencies={[['substitutionStrategy']]} noStyle>
              {() => {
                // 替代策略
                const substitutionStrategy = form.getFieldValue('substitutionStrategy');

                if (substitutionStrategy === SubstitutionStrategyEnum.COMPLETE) {
                  return (
                    <Form.Item
                      style={{ marginBottom: '0' }}
                      fieldKey={[field.fieldKey, 'useRatio']}
                      name={[field.name, 'useRatio']}
                    >
                      <Select>
                        <Select.Option value={0}>0</Select.Option>
                        <Select.Option value={100}>100</Select.Option>
                      </Select>
                    </Form.Item>
                  );
                }
                return (
                  <Space>
                    <Form.Item
                      style={{ marginBottom: '0' }}
                      fieldKey={[field.fieldKey, 'useRatio']}
                      name={[field.name, 'useRatio']}
                      rules={[
                        {
                          validator: numberMinMaxCheck({
                            min: 0,
                            minAllowEqual: true,
                            max: 100,
                            maxAllowEqual: true,
                            fieldName: '使用比例',
                          }),
                        },
                        { validator: fractionLengthCheck(4) },
                      ]}
                      initialValue={100}
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </Space>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '物料编码',
        dataIndex: 'materialCode',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'materialCode']}
              fieldKey={[field.fieldKey, 'materialCode']}
              style={{ marginBottom: '0' }}
            >
              {material?.baseInfo?.code}
            </Form.Item>
          );
        },
      },
      {
        title: '物料名称',
        dataIndex: 'materialName',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'materialName']}
              fieldKey={[field.fieldKey, 'materialName']}
              style={{ marginBottom: '0' }}
            >
              {material?.baseInfo?.name}
            </Form.Item>
          );
        },
      },
      {
        title: '物料属性',
        dataIndex: 'attributes',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'attributes']}
              fieldKey={[field.fieldKey, 'attributes']}
              style={{ marginBottom: '0' }}
            >
              {<TagList dataSource={getMaterialAttrs(material)} />}
            </Form.Item>
          );
        },
      },
      {
        title: '物料分类',
        dataIndex: 'category',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'category']}
              fieldKey={[field.fieldKey, 'category']}
              style={{ marginBottom: '0' }}
            >
              {material?.category?.name}
            </Form.Item>
          );
        },
      },
      {
        title: '物料规格',
        dataIndex: 'specification',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'specification']}
              fieldKey={[field.fieldKey, 'specification']}
              style={{ marginBottom: '0' }}
            >
              {material?.baseInfo?.specification}
            </Form.Item>
          );
        },
      },
      {
        title: '数量:分子',
        dataIndex: 'amountNumerator',
        width: 150,
        render: (text, field) => {
          // const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
          //   JSON.parse(params?.materialId?.value ?? '{}'),
          //   params?.unitId,
          // );

          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'amountNumerator']}
              name={[field.name, 'amountNumerator']}
              initialValue={1}
              rules={_.compact([
                { required: true, message: '数量:分子不能为空' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    // minAllowEqual: !useForBom, // 物料清单 不能为0，订单可以为0
                    max: 10000000,
                    fieldName: '数量:分子',
                  }),
                },
                // { validator: validatorInputAmountNumerator },
                // enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
              ])}
            >
              <InputNumber style={{ width: '100%' }} placeholder="请输入" disabled />
            </Form.Item>
          );
        },
      },
      {
        title: '数量:分母',
        dataIndex: 'amountDenominator',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'amountDenominator']}
              name={[field.name, 'amountDenominator']}
              initialValue={1}
              rules={[
                { required: true, message: '数量:分母不能为空' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: false,
                    max: 10000000,
                    fieldName: '数量:分母',
                  }),
                },
              ]}
            >
              <InputNumber
                disabled
                style={{ width: '100%' }}
                placeholder="请输入"
                min={0}
                max={10000000}
              />
            </Form.Item>
          );
        },
      },

      {
        title: '单位',
        dataIndex: 'unitId',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item noStyle dependencies={[[name, field.name, 'materialId']]}>
              {() => {
                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'unitId']}
                    name={[field.name, 'unitId']}
                    rules={[
                      // { validator: validatorUnit(field.name) },
                      { required: true, message: '单位不能为空' },
                    ]}
                  >
                    <Select
                      options={getMaterialUnits(material)}
                      // disabled={getFieldValue([name, field.name, 'isChildren'])}
                      onChange={() => {
                        // form.setFieldsValue({
                        //   [name]: setRowValueChange(field.name, copyRows(), [
                        //     { value, fieldStr: 'unitId' },
                        //   ]),
                        // });
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ]);
  };

  const getDefaultValue = (field: FormListFieldData, fieldStr: string, defaultValue: any) => {
    const hasValue = !isNil(form.getFieldValue([name, field.name, fieldStr]));

    if (hasValue) return undefined;

    return defaultValue;
  };

  const getColumnsAlternative = (): ColumnProps<FormListFieldData & any>[] => {
    const alternativeName = 'alternativeMaterials';

    return _.compact([
      {
        title: '',
        dataIndex: 'id',
        width: 1,
        render: (_text, field) => {
          return (
            <Form.Item
              hidden
              name={[field.name, 'id']}
              fieldKey={[field.fieldKey, 'id']}
              style={{ marginBottom: '0' }}
            >
              <Input />
            </Form.Item>
          );
        },
      },
      {
        title: '序号',
        dataIndex: 'lineNo',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'lineNo']}
              fieldKey={[field.fieldKey, 'lineNo']}
              style={{ marginBottom: '0' }}
              initialValue={field?.fieldKey + 1}
            >
              <InputNumber placeholder="请输入" min={1} precision={0} step={5} disabled />
            </Form.Item>
          );
        },
      },
      {
        title: '使用比例',
        dataIndex: 'useRatio',
        width: 150,
        render: (text, field) => {
          // const baseName = [name];
          return (
            <Form.Item dependencies={[['substitutionStrategy']]} noStyle>
              {() => {
                // 替代策略
                const substitutionStrategy = form.getFieldValue('substitutionStrategy');

                if (substitutionStrategy === SubstitutionStrategyEnum.COMPLETE) {
                  return (
                    <Form.Item
                      style={{ marginBottom: '0' }}
                      fieldKey={[field.fieldKey, 'useRatio']}
                      name={[field.name, 'useRatio']}
                    >
                      <Select>
                        <Select.Option value={0}>0</Select.Option>
                        <Select.Option value={100}>100</Select.Option>
                      </Select>
                    </Form.Item>
                  );
                }
                return (
                  <Space>
                    <Form.Item
                      style={{ marginBottom: '0' }}
                      fieldKey={[field.fieldKey, 'useRatio']}
                      name={[field.name, 'useRatio']}
                      rules={[
                        {
                          validator: numberMinMaxCheck({
                            min: 0,
                            minAllowEqual: true,
                            max: 100,
                            maxAllowEqual: true,
                            fieldName: '使用比例',
                          }),
                        },
                        { validator: fractionLengthCheck(4) },
                      ]}
                      initialValue={0}
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </Space>
                );
              }}
            </Form.Item>
          );
        },
      },
      // 标准用量需求数 temp
      {
        title: '',
        dataIndex: 'standardAndRequirementAmount',
        width: 1,
        render: (_text, field) => {
          return (
            <Form.Item
              // name={[field.name, 'biaozhun']}
              // fieldKey={[field.fieldKey, 'biaozhun']}
              dependencies={[
                ['originalAlternativeMaterials', 0, 'useRatio'],
                ['originalAlternativeMaterials', 0, 'unitId'],
                [alternativeName, field.name, 'materialId'],
                [alternativeName, field.name, 'unitId'],
                [alternativeName, field.name, 'amountDenominator'],
                [alternativeName, field.name, 'amountNumerator'],
                [alternativeName, field.name, 'lineNo'],
                [alternativeName, field.name, 'useRatio'],
              ]}
              style={{ marginBottom: '0' }}
            >
              {() => {
                const recordLine = get(form.getFieldValue(alternativeName), field?.name);
                const originalMaterialsLine = get(
                  form.getFieldValue('originalAlternativeMaterials'),
                  0,
                );

                const { materialId, unitId, amountDenominator, amountNumerator, lineNo, useRatio } =
                  recordLine;

                const alternativeMateriaFilter = {
                  lineNo,
                  amountDenominator,
                  amountNumerator,
                  alternativeMaterialUnitId: unitId,
                  alternativeMaterialId: formatMaterialToApi(materialId),
                  useRatio,
                };

                const calculateDataFilter = _.omitBy(
                  {
                    ...params?.calculateData,
                    originalAlternativeMaterialUnitId: originalMaterialsLine?.unitId,
                    ...alternativeMateriaFilter,
                  },
                  isNumber,
                );

                if (!isEmpty(calculateDataFilter)) {
                  return replaceSign;
                }

                const request = {
                  ...params?.calculateData,
                  originalAlternativeMaterialUnitId: originalMaterialsLine?.unitId,
                  alternativeMaterialCalculatedAmounts: [alternativeMateriaFilter],
                };

                fetchWorkOrderCalculate(request).then((res) => {
                  if (!res.code) {
                    return replaceSign;
                  }
                  form.setFields([
                    {
                      name: [alternativeName, field.name, 'requirementAmount'],
                      value: res?.data?.[0]?.requirementAmount?.amountDisplay,
                    },
                    {
                      name: [alternativeName, field.name, 'standardUseAmount'],
                      value: res?.data?.[0]?.standardUseAmount?.amountDisplay,
                    },
                  ]);

                  return (
                    <Form.Item
                      name={[field.name, 'standardAndRequirementAmount']}
                      fieldKey={[field.fieldKey, 'standardAndRequirementAmount']}
                      style={{ marginBottom: '0' }}
                      hidden
                    >
                      <Input disabled />
                    </Form.Item>
                  );
                });
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '标准用量',
        dataIndex: 'standardUseAmount',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'standardUseAmount']}
              fieldKey={[field.fieldKey, 'standardUseAmount']}
              style={{ marginBottom: '0' }}
            >
              <Input disabled />
            </Form.Item>
          );
        },
      },
      {
        title: '需求数',
        dataIndex: 'requirementAmount',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'requirementAmount']}
              fieldKey={[field.fieldKey, 'requirementAmount']}
              style={{ marginBottom: '0' }}
            >
              <Input disabled />
            </Form.Item>
          );
        },
      },
      {
        title: '优先级',
        dataIndex: 'priority',
        width: 120,
        render: (_text, field, index) => {
          return (
            <Form.Item
              name={[field.name, 'priority']}
              fieldKey={[field.fieldKey, 'priority']}
              style={{ marginBottom: '0' }}
              rules={[
                { validator: validateBlInputNumberIntervalInt(1, 50) },
                { validator: validatorIndex },
                { required: true, message: '优先级必填' },
              ]}
              initialValue={getDefaultValue(field, 'priority', index + 1)}
            >
              <InputNumber placeholder="请输入" min={1} precision={0} step={5} />
            </Form.Item>
          );
        },
      },
      {
        title: '物料编码',
        dataIndex: 'materialId',
        width: 200,
        render: (_text, field) => {
          const baseSelectProps = {
            fetchType: 'materialCompleteInfo',
            style: { width: '100%' },
            placeholder: '请输入',
            labelInValue: true,
            params: {
              enableFlag: appEnum.Common.UsageStatus.enabled,
              bizType: [appEnum.Material.BizType.default, appEnum.Material.BizType.phantom],
            },
          };

          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'materialId']}
              name={[field.name, 'materialId']}
              rules={[
                { required: true, message: '物料不能为空' },
                { validator: validatorMaterials },
              ]}
            >
              {/* <SearchSelect {...baseSelectProps} /> */}
              <MaterialSelect
                {...baseSelectProps}
                onChange={(value: any) => {
                  const materialInfo = getMaterialInfo(
                    alternativeName,
                    field.name,
                    undefined,
                    value,
                  );
                  const unit = getMaterialFeedUnit(materialInfo, true);

                  form.setFields([
                    {
                      name: [alternativeName, field.name, 'unitId'],
                      value: unit?.value,
                    },
                    {
                      name: [alternativeName, field.name, 'feedingQualityStatus'],
                      value: materialInfo?.feedInfo?.qcStatus ?? undefined,
                    },
                  ]);
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '物料名称',
        dataIndex: 'materialName',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item dependencies={[[alternativeName, field.name, 'materialId']]} noStyle>
              {() => get(getMaterialInfo(alternativeName, field.name, 'baseInfo'), 'name')}
            </Form.Item>
          );
        },
      },
      {
        title: '物料属性',
        dataIndex: 'attributes',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item dependencies={[[alternativeName, field.name, 'materialId']]} noStyle>
              {() => (
                <TagList
                  dataSource={getMaterialAttrs(getMaterialInfo(alternativeName, field.name))}
                />
              )}
            </Form.Item>
          );
        },
      },
      {
        title: '物料分类',
        dataIndex: 'category',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item dependencies={[[alternativeName, field.name, 'materialId']]} noStyle>
              {() => _.get(getMaterialInfo(alternativeName, field.name, 'category'), 'name')}
            </Form.Item>
          );
        },
      },
      {
        title: '物料规格',
        dataIndex: 'materialName',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item dependencies={[[alternativeName, field.name, 'materialId']]} noStyle>
              {() => get(getMaterialInfo(alternativeName, field.name, 'baseInfo'), 'specification')}
            </Form.Item>
          );
        },
      },
      {
        title: '数量:分子',
        dataIndex: 'amountNumerator',
        width: 150,
        render: (text, field) => {
          const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
            getMaterialInfo(alternativeName, field.name),
            form.getFieldValue([alternativeName, field.name, 'unitId']),
          );

          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'amountNumerator']}
              name={[field.name, 'amountNumerator']}
              initialValue={1}
              rules={_.compact([
                { required: true, message: '数量:分子不能为空' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    // minAllowEqual: !useForBom, // 物料清单 不能为0，订单可以为0
                    max: 10000000,
                    fieldName: '数量:分子',
                  }),
                },
                enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
              ])}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder="请输入"
                min={0}
                max={10000000}
                onChange={() => {
                  // const ary = form.getFieldValue(name);
                  // form.validateFields(
                  //   ary.map((t: any, index: any) => [
                  //     'bomFeedingControls',
                  //     index,
                  //     'inputAmountNumerator',
                  //   ]),
                  // );
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '数量:分母',
        dataIndex: 'amountDenominator',
        width: 150,
        render: (_text, field) => {
          // const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
          //   getMaterialInfo(alternativeName, field.name),
          //   form.getFieldValue([alternativeName, field.name, 'unitId']),
          // );

          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'amountDenominator']}
              name={[field.name, 'amountDenominator']}
              initialValue={1}
              rules={[
                { required: true, message: '数量:分母不能为空' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: false,
                    max: 10000000,
                    fieldName: '数量:分母',
                  }),
                },
                // enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
              ]}
            >
              <InputNumber style={{ width: '100%' }} placeholder="请输入" min={0} max={10000000} />
            </Form.Item>
          );
        },
      },
      {
        title: '单位',
        dataIndex: 'unitId',
        width: 150,
        render: (_text, field) => {
          return (
            <Form.Item noStyle dependencies={[[alternativeName, field.name, 'materialId']]}>
              {() => {
                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'unitId']}
                    name={[field.name, 'unitId']}
                    rules={[
                      // { validator: validatorUnit(field.name) },
                      { required: true, message: '单位不能为空' },
                    ]}
                  >
                    <Select
                      options={getMaterialUnits(getMaterialInfo(alternativeName, field.name))}
                      onChange={() => {
                        // form.setFieldsValue({
                        //   [name]: setRowValueChange(field.name, copyRows(), [
                        //     { value, fieldStr: 'unitId' },
                        //   ]),
                        // });
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '领料方式',
        dataIndex: 'pickMode',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'pickMode']}
              fieldKey={[field.fieldKey, 'pickMode']}
              style={{ marginBottom: '0' }}
              rules={[{ required: true, message: '领料方式不能为空' }]}
              initialValue={pickModeEnum.ONDEMAND}
            >
              <Select options={pickModeMap} allowClear />
            </Form.Item>
          );
        },
      },
      {
        title: '投料质量状态',
        dataIndex: 'feedingQualityStatus',
        width: 200,
        render: (_text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'feedingQualityStatus']}
              name={[field.name, 'feedingQualityStatus']}
            >
              <Select options={appDict.material.feedingQualityStatus} mode="multiple" />
            </Form.Item>
          );
        },
      },
      {
        title: '使用比例上限',
        dataIndex: 'useUpperLimitRatio',
        width: 150,
        render: (_text, field) => {
          return (
            <Form.Item dependencies={[['substitutionStrategy']]} noStyle>
              {() => {
                // 替代策略
                const substitutionStrategy = form.getFieldValue('substitutionStrategy');

                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'useUpperLimitRatio']}
                    name={[field.name, 'useUpperLimitRatio']}
                    rules={_.compact([
                      {
                        validator: numberMinMaxCheck({
                          min: 0,
                          minAllowEqual: true,
                          max: 100,
                          maxAllowEqual: true,
                          fieldName: '使用比例',
                        }),
                      },
                      { validator: fractionLengthCheck(4) },
                    ])}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      disabled={substitutionStrategy === SubstitutionStrategyEnum.COMPLETE}
                      min={0}
                      max={100}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '行备注',
        dataIndex: 'remark',
        width: 300,
        render: (_text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'remark']}
              name={[field.name, 'remark']}
            >
              <Input.TextArea
                style={{ width: '100%' }}
                maxLength={1000}
                placeholder="请输入"
                showCount
                autoSize
              />
            </Form.Item>
          );
        },
      },
    ]);
  };

  const baseInfo = [
    {
      title: '基本信息',
      isFullLine: true,
      items: [
        {
          label: '替代策略',
          name: ['substitutionStrategy'],
          rules: [{ required: true, message: '替代策略必填' }],
          initialValue: SubstitutionStrategyEnum.HYBRID,
          render: () => {
            return (
              <Select
                options={SubstitutionStrategyMap}
                allowClear
                onChange={() => {
                  const alternativeMaterials = form.getFieldValue('alternativeMaterials');
                  /** 清空替代料「使用比例」 「使用比例上限」 */
                  const copyAlternativeMaterials = setAssociatedFormFields(alternativeMaterials, [
                    ['useRatio', 0],
                    ['useUpperLimitRatio', undefined],
                  ]);

                  form.setFields([
                    {
                      name: ['originalAlternativeMaterials', 0, 'useRatio'],
                      value: 100,
                    },
                    {
                      name: ['alternativeMaterials'],
                      value: copyAlternativeMaterials,
                    },
                  ]);
                }}
              />
            );
          },
        },
      ],
    },
    {
      isFullLine: true,
      title: '被替代物料',
      items: [
        {
          isFullLine: true,
          render: () => (
            <BlSortFormList
              name={'originalAlternativeMaterials'}
              renderColumns={() => getColumns()}
              isNeedDrag={false}
              isNeedAdd={false}
              maxCount={10}
              initLineCount={1}
              isNeedDelete={false}
            />
          ),
        },
      ],
    },
    {
      isFullLine: true,
      title: '替代物料',
      items: [
        {
          isFullLine: true,

          render: () => (
            <BlSortFormList
              name={'alternativeMaterials'}
              renderColumns={() => getColumnsAlternative()}
              isNeedDrag={false}
              maxCount={50}
            />
          ),
        },
      ],
    },
  ];

  return (
    <DataFormLayout
      form={form}
      info={baseInfo}
      title="设置替代方案"
      onCancel={() => {
        onChange();
      }}
      onFinish={() => {
        form
          ?.validateFields()
          .then((values) => {
            onChange(values);
          })
          .catch((error) => {
            const { errorFields } = error;

            form.scrollToField(errorFields?.[0]?.name);
          });
      }}
      formProps={{
        // preserve: true,
        initialValues: params?.workOrderAlternativePlan,
      }}
    />
  );
};

export default AlternativeMaterialsForm;
