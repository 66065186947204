import React, { useEffect, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Input, FormInstance } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import FormTable from './createAndEditTable';
import { DetailData, FormatConfigData, SourceDataType, SuitObjectData } from '../interface';
import { BlIcon, Tooltip, SearchSelect } from 'src/components';
import { formatData, formatDemo, formatEditElementFields } from '../utils';
import './number.module.scss';
import { TABLE_TYPE_ENUM } from '../constant';
import { textValidator1, textValidator2, withoutSpaceBothSides } from 'src/utils/formValidators';
import { replaceSign } from 'src/utils/constants';
import { fetchNumberRuleGetSourceByObjCode } from 'src/api/ytt/custom-domain';

interface baseInfoProps {
  onCancel: () => void;
  onFinish: () => void;
  handleSetDemo: (value: string) => void;
  demo: string;
  setSourceDataFn: (value: any) => void;
  title: string;
  initialData?: DetailData;
  form: FormInstance;
  edit: boolean;
  extra?: any;
  suitObjectData: SuitObjectData[];
  formatConfigData: FormatConfigData[];
}

const BaseInfo = (props: baseInfoProps) => {
  const {
    onCancel,
    title,
    onFinish,
    initialData,
    form,
    edit,
    extra,
    setSourceDataFn,
    suitObjectData,
    formatConfigData,
    handleSetDemo,
    demo,
  } = props;

  const [sourceData, setSourceData] = useState<SourceDataType>({
    dateData: [{ label: '当前日期', value: '0' }],
    variableData: [],
  });

  const getSourceData = async () => {
    try {
      const suitObjIdValue = edit
        ? initialData?.suitObj?.id
        : form.getFieldsValue()?.suitObjId?.value;

      const nodeItem =
        suitObjectData &&
        suitObjectData?.find((node: SuitObjectData) => node?.id === suitObjIdValue);

      if (nodeItem) {
        const { data, code } = await fetchNumberRuleGetSourceByObjCode({
          code: nodeItem?.objectCode,
        });

        if (code === 200) {
          const formatDataSource = formatData(data ?? {});

          setSourceData(formatDataSource); // 元素来源

          setSourceDataFn(formatDataSource);
        }
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getDemo = () => {
    const element = form.getFieldValue('elementList') ?? [];

    const newElementList = formatEditElementFields(element, sourceData?.variableData); // 获取编号示例的时候 需要再次单独处理变量时的元素来源 需要显示成label

    const sample = formatDemo(newElementList, formatConfigData);

    handleSetDemo(sample);
  };

  const suitObjectOnchange = async () => {
    try {
      getSourceData();
      // 当适用对象发生改变时  需要重置元素来源
      const elementList = form.getFieldValue('elementList') ?? [];
      const newElementList = elementList.map((node: any) => {
        const newNode = { ...node };

        if (
          node?.elementType === TABLE_TYPE_ENUM.DATE ||
          node?.elementType === TABLE_TYPE_ENUM.VARIABLE
        ) {
          // 重置日期或者变量的元素来源
          newNode.elementFields = null;
        }
        return newNode;
      });

      form.setFieldsValue({ elementList: newElementList });
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,
    items: [
      {
        label: '规则编号',
        name: 'ruleCode',
        rules: [
          { required: true, message: '规则编号必填' },
          { validator: textValidator2 },
          { max: 255, message: '不可超过255个字符' },
        ],
        render: () => <Input placeholder="请输入" allowClear />,
      },
      {
        label: '规则名称',
        name: 'ruleName',
        rules: [
          { required: true, message: '规则名称必填' },
          { validator: textValidator1 },
          withoutSpaceBothSides,
          { max: 255, message: '不可超过255个字符' },
        ],
        render: () => <Input placeholder="请输入" allowClear />,
      },
      {
        label: '适用对象',
        name: 'suitObjId',
        rules: [{ required: true, message: '适用对象必选' }],
        render: () => (
          <SearchSelect
            fetchType={'bizObject'}
            onChange={suitObjectOnchange}
            labelInValue
            disabled={edit}
            allowClear
          />
        ),
      },
      {
        label: '',
        render: () => {
          return (
            <div
              style={{
                height: 70,
                width: '100%',
                backgroundColor: '#FAFAFA',
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 15,
              }}
            >
              <div style={{ color: '#595959' }}>
                <span>编号示例 </span>
                <span onClick={getDemo} style={{ cursor: 'pointer' }}>
                  <BlIcon type="iconshuaxin" />
                </span>
              </div>
              <div style={{ fontWeight: 700 }}>
                <Tooltip text={demo ?? replaceSign} width={'100%'} />
              </div>
            </div>
          );
        },
      },
      {
        label: '',
        isFullLine: true,
        render: () => {
          return (
            <FormTable
              initialData={initialData?.elementList}
              form={form}
              sourceData={sourceData}
              formatConfigData={formatConfigData}
            />
          );
        },
      },
      {
        label: '规则描述',
        name: 'ruleRemark',
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
      {
        label: '编辑原因',
        name: 'operateReason',
        hidden: !edit,
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
    ],
  };

  useEffect(() => {
    if (edit && initialData) {
      getSourceData();
      form.setFieldsValue({
        ruleCode: initialData?.ruleCode,
        ruleName: initialData?.ruleName,
        ruleRemark: initialData?.ruleRemark,
        suitObjId: {
          value: initialData?.suitObj?.id,
          label: initialData?.suitObj?.objectName,
        },
      });
    }
  }, [initialData]);

  return (
    <DataFormLayout
      formLayout="vertical"
      form={form}
      title={title}
      info={[baseInfo]}
      extra={extra || ''}
      onCancel={onCancel}
      onFinish={onFinish}
    />
  );
};

export default BaseInfo;
