import React, { ReactNode, useCallback, useState } from 'react';
import _ from 'lodash';
import { Radio, Checkbox, Form } from 'antd';
import { appDict, appEnum } from 'src/dict';
import {
  CheckEntityTypeEnum,
  CheckTypeEnum,
  isRecordCountSampleEnum,
  MaterialProcessEnum,
  RecordSampleEnum,
  ReportFormatTypeEnum,
  SampleProcessEnum,
} from 'src/dict/quality';
import { recordSummaryCount, materialProcessType } from 'src/dict/quality/mappings';
import { truncateSync } from 'fs';

const ResultInfo = (props: { form: any; recordSample: number }) => {
  const { recordSample, form } = props;
  const [reportFormatType, setReportFormatType] = useState<SampleProcessEnum | undefined>(
    form.getFieldValue('sampleProcessMethod'),
  );
  const [isRecordCount, setIsRecordCount] = useState<MaterialProcessEnum | undefined>(
    form.getFieldValue('materialBatchRecordType'),
  );

  /**
   * 渲染Label
   * @param label 标签名
   * @param required 是否必填
   */
  const renderLabel = (label: string, required?: boolean) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: 130,
          textAlign: 'right',
        }}
      >
        {required && <span style={{ marginRight: 8, color: '#ff4d4f' }}>*</span>}
        <span>{label}</span>
      </div>
    );
  };

  const infoContentStyle = {
    marginBottom: 16,
  };

  const getRecordSampleContent = useCallback((): ReactNode => {
    return recordSample === RecordSampleEnum.record ? (
      <div style={{ background: '#f5f5f5', padding: 14, marginLeft: 140 }}>
        <Form.Item
          label={renderLabel('样本处理方式', true)}
          name="sampleProcessMethod"
          required={false}
          rules={[{ required: true, message: '请选择样本处理方式' }]}
          style={infoContentStyle}
        >
          <Radio.Group
            options={appDict.quality.SampleProcess}
            onChange={(e: any) => {
              setReportFormatType(e.target?.value);
              if (e.target?.value === SampleProcessEnum.scrap) {
                form.setFieldsValue({ reportFormatType: ReportFormatTypeEnum.materialUnit });
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label={renderLabel('样本记录填写格式', true)}
          name="reportFormatType"
          required={false}
          rules={[{ required: true, message: '样本记录填写格式' }]}
          style={infoContentStyle}
        >
          <Radio.Group
            options={appDict.quality.ReportFormatType}
            disabled={reportFormatType === SampleProcessEnum.scrap}
          />
        </Form.Item>
      </div>
    ) : null;
  }, [recordSample, reportFormatType]);

  const info = {
    title: '检验结论',
    items: _.compact([
      {
        label: '结论判定选项',
        name: 'inspectionResultOptions',
        rules: [{ required: true, message: '最少选择一个' }],
        isFullLine: true,
        render: () => <Checkbox.Group options={appDict.quality.inspectionResultOptions} />,
      },
      form.getFieldValue('checkType') !== CheckTypeEnum.generalQc && {
        label: '总体处理方式',
        rules: [{ required: true, message: '总体处理方式必填' }],
        name: 'materialBatchRecordType',
        isFullLine: true,
        render: () => (
          <Radio.Group
            options={materialProcessType}
            onChange={(e) => setIsRecordCount(e.target.value)}
          />
        ),
      },
      form.getFieldValue('checkType') !== CheckTypeEnum.generalQc && {
        label: '样本是否需要记录',
        name: 'recordSample',
        rules: [{ required: true }],
        isFullLine: true,
        render: () => <Radio.Group options={appDict.quality.RecordSample} />,
      },
      form.getFieldValue('checkType') !== CheckTypeEnum.generalQc && {
        label: '',
        name: 'recordSampleType',
        isFullLine: true,
        hidden: recordSample === RecordSampleEnum.notRecord,
        shouldUpdate: (prevValues: { recordSample: any }, curValues: { recordSample: any }) =>
          prevValues.recordSample !== curValues.recordSample,
        render: () => getRecordSampleContent(),
      },
      form.getFieldValue('checkType') !== CheckTypeEnum.generalQc &&
        recordSample === RecordSampleEnum.notRecord &&
        (form.getFieldValue('checkEntityType') === CheckEntityTypeEnum.useExecuteAdd ||
          form.getFieldValue('checkEntityType') === CheckEntityTypeEnum.Custom ||
          form.getFieldValue('checkEntityType') === CheckEntityTypeEnum.useCheckItemConfig) &&
        isRecordCount === MaterialProcessEnum.MATERIAL_NO_RECORD && {
          label: '总量/抽样量是否需填写',
          name: 'recordSummaryCount',
          rules: [{ required: true }],
          isFullLine: true,
          initialValue: isRecordCountSampleEnum.yes,
          render: () => <Radio.Group options={recordSummaryCount} />,
        },
    ]),
  };

  return info;
};

export default ResultInfo;
