import { useCallback, useMemo, useState, useRef } from 'react';
import _ from 'lodash';
import { Layout } from 'antd';
import { Route, Switch, withRouter, Redirect, useHistory } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import LocalStorage from 'src/utils/localStorage';
import { RootState } from 'src/store';
import { RouteProp, MenuData, frontendRoutes, backendRoutes } from 'src/route';
import AppSider from 'src/page/app/appSider';
import BlBreadcrumbs from './components/breadcrumbs';
import { FIELDS } from 'src/utils/constants';
import NotificationLayout from '../notificationManagement/notificationMessage/notification/notificationLayout';
import ExImportModal from 'src/components/excelBatchOption/modal';
import { NoAccess } from 'src/layout';
import { ContextType } from './constant';
import { getFirstLeaf } from './share';
import './index.scss';

const { Header, Content } = Layout;

/**
 * 判断当前路由所属环境（前台/后台），解决前后台页面相互跳转的问题
 * 如：在后台点『通知消息』-『查看全部』
 */
const getCurrentRouteContext = (
  path: string,
  frontendRoutes: RouteProp[],
  backendRoutes: RouteProp[],
): ContextType | '' => {
  // 只需比较一级菜单的路径
  const firstLevelPath = _.nth(path.split('/'), 1);

  if (!firstLevelPath) {
    return '';
  }
  const matchRoute = (routes: RouteProp[]): boolean => {
    for (const _route of routes) {
      if (_route.isCategory) {
        continue;
      }
      if (firstLevelPath === _.nth(_route.path.split('/'), 1)) {
        return true;
      }
    }
    return false;
  };

  // 前台菜单
  if (matchRoute(frontendRoutes)) {
    return ContextType.FRONTEND;
  }
  // 后台菜单
  if (matchRoute(backendRoutes)) {
    return ContextType.BACKEND;
  }
  return '';
};

function GlobalLayout() {
  const contextInfo = useSelector((state: RootState) => state?.contextInfo, shallowEqual);
  const privilegeInfo = useSelector((state: RootState) => state?.user?.privilegeInfo, shallowEqual);
  const history = useHistory();
  const dispatch = useDispatch();
  const contextType = useRef<ContextType | null>(null);
  // 渲染菜单的数据
  const [menuList, setMenuList] = useState<MenuData[]>([]);
  // 渲染主页面的数据
  const [routerContent, setRouterContent] = useState<any[]>([]);
  // 自定义菜单，异步获取后保存在这里
  const [customMenuData, setCustomMenuData] = useState<{ [p: string]: RouteProp[] }>({});
  // 自定义菜单加载完成后重新渲染的标识
  const refreshFlag = useRef<boolean>(false);

  // 只要执行 history.push，这个组件就会触发rerender，但没必要每次去计算路由
  // 需要重新计算路由的情况：
  //   1. 自定义菜单加载完成
  //   2. contextType 发生变化

  // 使用自定义菜单
  const fullFrontendRoutes = useMemo(() => {
    frontendRoutes.forEach((rc) => {
      if (_.isFunction(rc.loadChildren)) {
        const customMenu = customMenuData[rc.path];

        // 如果有自定义菜单，就直接替换children
        if (Array.isArray(customMenu)) {
          rc.children = customMenu;
        }
        // 如果自定义菜单尚未加载，则从后台拿
        else {
          rc.loadChildren().then((menu) => {
            refreshFlag.current = true;
            setCustomMenuData({ ...customMenuData, [rc.path]: menu });
          });
        }
      }
    });

    // 过滤掉空菜单
    return frontendRoutes.filter((rc) => !rc.menu || !_.isEmpty(rc.children));
  }, [customMenuData]);

  const hasAuthFn = useCallback(
    (auth: string | string[] | undefined | Function): Boolean => {
      if (auth === undefined) {
        return true;
      }
      if (typeof auth === 'string') {
        return privilegeInfo?.includes(auth);
      }
      if (Array.isArray(auth)) {
        return _.difference(privilegeInfo, auth)?.length !== privilegeInfo.length;
      }
      if (_.isFunction(auth)) {
        return auth();
      }
      return true;
    },
    [privilegeInfo],
  );

  // 前两级菜单/面包屑需要设置重定向路径到有权限的页面
  const addRedirectPath = useCallback(
    (routes: RouteProp[], depth = 1) => {
      if (depth > 2) {
        return;
      }
      routes.forEach((route) => {
        const { auth, children } = route;

        if (!hasAuthFn(auth)) {
          return;
        }
        const authedChildren =
          (children as RouteProp[])?.filter((item) => hasAuthFn(item.auth)) ?? [];
        const routeWithAuthedChildren = { ...route, children: authedChildren };

        if (!_.isEmpty(authedChildren)) {
          route.redirectPath = getFirstLeaf(routeWithAuthedChildren, depth, hasAuthFn).path;
        }
        addRedirectPath(authedChildren, depth + 1);
      });
    },
    [privilegeInfo],
  );

  const getContentByRoute = useCallback(
    (routes: RouteProp[]) => {
      const newRouteArr: any[] = [];

      routes.forEach((_route) => {
        const { auth, component, path, redirectPath, children } = _route;
        let childrenRouteArr: any[] = [];
        let componentRender: JSX.Element;

        if (hasAuthFn(auth)) {
          componentRender = component ? (
            <Route path={path} component={component} exact key={path} />
          ) : (
            <Route path={path} render={() => <Redirect to={redirectPath!} />} key={path} exact />
          );
        } else {
          componentRender = <Route path={path} component={NoAccess} exact key={path} />;
        }

        if (children) {
          childrenRouteArr = getContentByRoute(children as RouteProp[]);
        }
        newRouteArr.push(componentRender);
        childrenRouteArr.length && newRouteArr.push(childrenRouteArr);
      });
      return newRouteArr;
    },
    [privilegeInfo],
  );

  const getMenuList = useCallback(
    (routes: RouteProp[]): MenuData[] => {
      return _.compact(
        routes.map((_route) => {
          const { menu, breadcrumb, auth, path, children, icon, isCategory, initCollapsed } =
            _route;
          const hasAuth = auth ? hasAuthFn(auth) : true;
          const menuInfo = {
            path,
            name: menu,
            isCategory,
            breadcrumbName: breadcrumb,
            icon,
            children: children ? getMenuList(children) : [],
            initCollapsed,
          };

          return hasAuth ? menuInfo : null;
        }),
      );
    },
    [privilegeInfo],
  );

  // 判断路由对应的环境。优先根据 pathname 判断，判断不了再取Redux
  const currentContextType =
    getCurrentRouteContext(history.location.pathname, fullFrontendRoutes, backendRoutes) ||
    contextInfo.contextType;

  if (refreshFlag.current || currentContextType !== contextType.current) {
    contextType.current = currentContextType;
    refreshFlag.current = false;
    // 根据环境选择要加载的路由
    let currentRouteConfig: RouteProp[];

    if (currentContextType === ContextType.FRONTEND) {
      currentRouteConfig = fullFrontendRoutes;
      addRedirectPath(currentRouteConfig);
    } else {
      currentRouteConfig = backendRoutes;
    }
    setMenuList(getMenuList(currentRouteConfig));
    setRouterContent(_.flatMapDeep(getContentByRoute(currentRouteConfig)));
  }

  const onSwitchContext = (type: string) => {
    LocalStorage.set(FIELDS.CONTEXT_TYPE, type);
    history.push('/');
    dispatch({ type: 'contextInfo/setContextType', payload: type });
    // 自定义菜单重新加载
    setCustomMenuData({});
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <AppSider menuData={menuList} type={currentContextType} onSwitchContext={onSwitchContext} />
      <Layout>
        <Header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: 24,
              background: '#ffffff',
              height: '100%',
            }}
          >
            {BlBreadcrumbs(window.location, menuList)}
            <NotificationLayout />
          </div>
        </Header>
        <Content>
          <Switch>{routerContent}</Switch>
        </Content>
      </Layout>
      <ExImportModal />
    </Layout>
  );
}

export default withRouter(GlobalLayout);
