import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Form, message as Message } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import { BlTable } from '@blacklake-web/component';
import _ from 'lodash';

import { fetchReturnAuditNoteDetail } from 'src/api/ytt/supply-chain-domain/return_audit_note';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { materialEntity, MaterialEntity } from 'src/entity';
import { lookup } from 'src/dict';
import { ApplicationStatus } from 'src/dict/salesReturnOrder';
import { SingleOperationLogComponent, TagList } from 'src/components';
import { renderUser } from 'src/page/share/renderUser';
import { amountRender } from 'src/page/share/renderAmount';
import { goToList } from '../navigates';
import { useOperateReturnApplicationModal } from '../hooks';
import { fieldLabels } from '../constants';
import { ReturnApplicationDetailType } from '../interface';

interface SalesReturnOrderDetailProps {
  id: string;
}

export default function ReturnApplicationDetail({
  match,
}: RouteComponentProps<SalesReturnOrderDetailProps>) {
  const orderId = Number(match.params.id);

  const [orderDetail, setOrderDetail] = useState<ReturnApplicationDetailType>();
  const [loading, setLoading] = useState(false);
  const [visibleLog, setVisibleLog] = useState(false);
  const [rejectForm] = Form.useForm();
  const { accept, reject } = useOperateReturnApplicationModal();

  if (!orderId) goToList();
  /**
   * 协同采购退货单 - Columns
   */
  const returnInfoColumns = [
    {
      title: fieldLabels.lineNo,
      dataIndex: 'lineNo',
      width: 80,
    },
    {
      title: fieldLabels.customerMaterialCode,
      dataIndex: 'customerMaterialCode',
      width: 140,
    },
    {
      title: fieldLabels.customerMaterialName,
      dataIndex: 'customerMaterialName',
      width: 140,
    },
    {
      title: fieldLabels.customerReturnAmount,
      dataIndex: 'customerReturnAmount',
      render: amountRender,
      width: 120,
    },
    {
      title: fieldLabels.ourMaterialCode,
      dataIndex: 'materialCode',
      width: 140,
    },
    {
      title: fieldLabels.ourMaterialName,
      dataIndex: 'materialName',
      width: 140,
    },
    {
      title: fieldLabels.ourMaterialAttr,
      dataIndex: 'material',
      render: (material: MaterialEntity.MaterialEntity) => (
        <TagList dataSource={materialEntity.getMaterialAttrs(material)} />
      ),
      width: 180,
    },
    {
      title: fieldLabels.convertedReturnAmount,
      dataIndex: 'convertedReturnAmount',
      render: amountRender,
      width: 160,
    },
  ];
  /**
   * 基本信息
   */
  const basicInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: fieldLabels.code,
        dataIndex: 'code',
      },
      {
        label: fieldLabels.customerCode,
        dataIndex: 'customerCode',
      },
      {
        label: fieldLabels.customerName,
        dataIndex: 'customerName',
      },
      {
        label: fieldLabels.salesOrderCode,
        dataIndex: 'salesOrderCode',
      },
      {
        label: fieldLabels.status,
        dataIndex: 'status',
        render: lookup('salesReturnOrder.applicationStatus'),
      },
      {
        label: fieldLabels.rejectReason,
        dataIndex: 'reason',
      },
      {
        label: fieldLabels.createdAt,
        dataIndex: 'createdAt',
        render: formatTimeForRender,
      },
      {
        label: fieldLabels.operator,
        dataIndex: 'operator',
        render: renderUser,
      },
      {
        label: fieldLabels.updatedAt,
        dataIndex: 'updatedAt',
        render: formatTimeForRender,
      },
    ],
  };
  /**
   * 协同采购退货单信息
   */
  const scmInfo: DetailLayoutInfoBlock = {
    title: '协同采购退货单信息',
    items: [
      {
        label: fieldLabels.purchaseReturnOrderCode,
        dataIndex: 'customerPurchaseOrderReturnCode',
      },
      {
        label: fieldLabels.remark,
        dataIndex: ['poReturnInfo', 'remark'],
      },
    ],
  };
  /**
   * 退货信息
   */
  const returnInfo: DetailLayoutInfoBlock = {
    title: '退货信息',
    items: [
      {
        dataIndex: 'items',
        render: (items) => (
          <BlTable
            pagination={false}
            scroll={{ x: 'max-content', y: 300 }}
            dataSource={items}
            rowKey="id"
            columns={returnInfoColumns}
          />
        ),
      },
    ],
  };
  /**
   * 详情 - 信息块
   */
  const detailInfo: DetailLayoutInfoBlock[] = [basicInfo, scmInfo, returnInfo];
  /**
   * 详情 - 操作
   */
  const baseMenu = _.isNil(orderDetail)
    ? []
    : _.compact([
        orderDetail?.status === ApplicationStatus.waiting && {
          title: '接受',
          auth: authDict.returnauditnote_accept,
          key: 'accept',
          onClick: () => {
            accept(orderDetail.id!, fetchReturnApplicationDetail);
          },
        },
        orderDetail?.status === ApplicationStatus.waiting && {
          title: '拒绝',
          auth: authDict.returnauditnote_decline,
          key: 'reject',
          onClick: () => {
            reject(
              orderDetail.id!,
              orderDetail.customerName!,
              rejectForm,
              fetchReturnApplicationDetail,
            );
          },
        },
        {
          key: 'record',
          auth: authDict.returnauditnote_operrecord,
          onClick: () => {
            setVisibleLog(true);
          },
          title: '操作记录',
        },
      ]);

  const fetchReturnApplicationDetail = async () => {
    setLoading(true);
    const response = await fetchReturnAuditNoteDetail({ id: orderId });

    if (response.code === 200 && response.data) {
      setOrderDetail(response.data);
    } else {
      Message.error(response.message);
    }
    setLoading(false);
  };

  /**
   * 初始化
   */
  useEffect(() => {
    fetchReturnApplicationDetail();
  }, []);

  return (
    <>
      <DetailLayout
        title="退货申请单详情"
        dataSource={orderDetail}
        loading={loading}
        baseMenu={baseMenu}
        info={detailInfo}
        userAuth={getAuthFromLocalStorage()}
      />
      {visibleLog && orderDetail && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={Number(orderDetail?.id)}
          objectCode={'ReturnAuditNote'} // 对象在对象平台唯一定义的objectCode
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
}
