/**
 * @mappings 出入库，调拨单-值映射表
 */

import {
  OutBoundTypeEnum,
  InBoundTypeEnum,
  TransferTypeEnum,
  StatusEnum,
  qualityStatusEnum,
  storageStatusEnum,
  inventoryBizStatusEnum,
  InventoryChangeActionEnum,
  inBoundEntityTypeEnum,
  outBoundEntityTypeEnum,
} from '.';
import { mappingsFactory } from '../utils';

export const OutBoundType = mappingsFactory(
  ['其他出库', OutBoundTypeEnum.other],
  ['销售出库', OutBoundTypeEnum.sales],
  ['退厂出库', OutBoundTypeEnum.purchase],
);

export const InBoundType = mappingsFactory(
  ['其他入库', InBoundTypeEnum.other],
  ['采购入库', InBoundTypeEnum.purchase],
  ['退货入库', InBoundTypeEnum.sales],
);

export const TransferType = mappingsFactory(
  ['仓储调拨', TransferTypeEnum.storage],
  ['领料调拨', TransferTypeEnum.picking],
);

export const Status = mappingsFactory(
  ['新建', StatusEnum.xinjian],
  ['已下发', StatusEnum.xiafa],
  ['执行中', StatusEnum.zhixing],
  ['已完成', StatusEnum.yiwancheng],
  ['已关闭', StatusEnum.yiguanbi],
);

export const qualityStatus = mappingsFactory(
  ['合格', qualityStatusEnum.qualified],
  ['让步合格', qualityStatusEnum.concession],
  ['待检', qualityStatusEnum.toBeInspected],
  ['不合格', qualityStatusEnum.unqualified],
);

export const storageStatus = mappingsFactory(
  ['存储中', storageStatusEnum.inStorage],
  ['在途中', storageStatusEnum.onWay],
  ['已出厂', storageStatusEnum.delivered],
);
export const inventoryBizStatus = mappingsFactory(
  ['无业务', inventoryBizStatusEnum.noBiz],
  ['质检中', inventoryBizStatusEnum.inQualityCheck],
);
export const inventoryChangeAction = mappingsFactory(
  ['入库', InventoryChangeActionEnum.in],
  ['出库', InventoryChangeActionEnum.out],
  ['转移入库', InventoryChangeActionEnum.receive],
  ['转移出库', InventoryChangeActionEnum.issue],
  ['数量调整', InventoryChangeActionEnum.amountAdjust],
  ['属性调整', InventoryChangeActionEnum.attrAdjust],
  ['导入', InventoryChangeActionEnum.import],
);

export const inBoundEntityType = mappingsFactory(
  ['采购收货单', inBoundEntityTypeEnum.receive],
  ['销售退货单', inBoundEntityTypeEnum.return],
);

export const outBoundEntityType = mappingsFactory(
  ['协同采购退货单', outBoundEntityTypeEnum.return],
  ['销售发货单', outBoundEntityTypeEnum.invoiceOrdinary],
  ['协同销售发货单', outBoundEntityTypeEnum.invoiceCollaborate],
);
