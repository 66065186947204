import React from 'react';
import { DetailLayout, DetailLayoutMenuItem } from 'layout';
import {
  renderCollaboratePurchaseColumn,
  purchaseBaseColumn,
  purchaseDetailColumn,
} from '../../components/columns';
import { BlTable } from 'src/components';
import { PurchaseAuditDetail, PurchaseOrderCrossCustomFields } from '../../interface';
import { injectCustomFieldColumns, injectCustomFieldInfos } from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { FieldType } from 'src/dict/customField';

interface DetailBaseInfoProps {
  detail: PurchaseAuditDetail;
  baseMenu?: DetailLayoutMenuItem[];
  crossCustomFields?: PurchaseOrderCrossCustomFields;
}

export const DetailBaseInfo = ({ detail, baseMenu, crossCustomFields }: DetailBaseInfoProps) => {
  /**
   * 跨租户的采购订单的自定义字段
   */
  const crossDocumentCustomFields = injectCustomFieldInfos({
    customFields: crossCustomFields?.document || [],
    dataIndex: ['purchaseOrder', 'scmPurchaseOrderCustomFields'],
    type: 'detail',
    componentsProps: new Map([
      [
        FieldType.appendix, // 附件类型的组件的参数(跨租户)
        {
          type: 'collaborative',
          collaborativeParams: { customerId: crossCustomFields?.customerId },
        },
      ],
    ]),
  });
  /**
   * 跨租户的采购订单物料行的自定义字段
   */
  const crossMaterialCustomFields = injectCustomFieldColumns({
    columns: purchaseDetailColumn, // 原本的列
    customFields: crossCustomFields?.material || [], // 自定义字段信息
    dataIndex: 'scmPurchaseOrderItemCustomFields', // 自定义字段
    objectCode: OBJECT_OF_CODE.purchaseOrderItem, // 采购订单物料行的code（是跨租户配置的自定义字段）
    type: 'detail', // 使用类型
    componentsProps: new Map([
      [
        FieldType.appendix, // 附件类型的组件的参数(跨租户)
        {
          type: 'collaborative',
          collaborativeParams: { customerId: crossCustomFields?.customerId },
        },
      ],
    ]),
  });

  /**
   * 详情 columns
   */
  const baseInfo = [
    {
      title: '申请单基本信息',
      items: purchaseBaseColumn,
    },
    {
      title: '协同采购信息',
      items: [
        ...renderCollaboratePurchaseColumn(detail?.customerId!),
        // 注入主对象自定义字段列
        ...crossDocumentCustomFields?.items,
      ],
    },

    {
      title: '采购明细',
      items: [
        {
          label: '',
          dataIndex: 'table',
          render: () => {
            return (
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                }}
              >
                <BlTable
                  // 注入从对象自定义字段列
                  columns={crossMaterialCustomFields}
                  scroll={{ x: 1200, y: 800 }}
                  dataSource={detail?.items}
                  style={{ width: '100%' }}
                  rowKey={(record) => record?.id as number}
                />
              </div>
            );
          },
        },
      ],
    },
  ];

  return <DetailLayout title="详情" info={baseInfo} dataSource={detail} baseMenu={baseMenu} />;
};
