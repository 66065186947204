/**
 * @component 平铺选项的选择器
 * @desc 暂时只支持单选
 */
import { CSSProperties, FC, ReactNode, useState } from 'react';
import { Input, List } from 'antd';
import classNames from 'classnames';
import { BlIcon, OverflowTooltip } from 'src/components';
import './styles.scss';

export interface ListSelectProps {
  title: ReactNode;
  options: { label: string; value: string }[];
  style?: CSSProperties;
  value?: any;
  onChange?: (...args: any) => void;
}

export const ListSelect: FC<ListSelectProps> = ({
  title,
  options,
  style = {},
  value,
  onChange,
}) => {
  const [searchWord, setSearchWord] = useState<string>('');
  const filteredOptions = options?.filter((item) => item.label.includes(searchWord));

  return (
    <div className="bl-list-select" style={style}>
      <div className="bl-list-select-title">{title}</div>
      <div className="bl-list-select-search-wrapper">
        <Input
          placeholder="请输入搜索关键字"
          onChange={(e) => setSearchWord(e.target.value)}
          suffix={<BlIcon type="iconsousuo" style={{ color: 'rgba(0,0,0,0.45)' }} />}
          allowClear
        />
      </div>
      <List
        className="bl-list-select-list"
        split={false}
        dataSource={filteredOptions}
        renderItem={(item) => (
          <List.Item
            className={classNames(
              'bl-list-select-list-item',
              item.value === value ? 'bl-list-select-list-item-active' : '',
            )}
            onClick={() => onChange?.(item.value)}
          >
            <OverflowTooltip
              text={item.label}
              placement="right"
              textStyle={{ display: 'inline-block' }}
            />
          </List.Item>
        )}
      />
    </div>
  );
};
