import { useEffect, useState } from 'react';
import { Switch, Form, Select } from 'antd';
import _ from 'lodash';

import { appDict } from 'src/dict';
import ConditionEditTableLayout from '../../share/ConditionEditTableLayout';
import RuleConditionValue from './RuleConditionValue';
import FieldIdCascader from '../../share/FieldIdCascader';

const MySwitch = (props: any) => {
  const oldChange = props.onChange;
  const newProps = {
    ...props,
    checked: Boolean(props.value),
    onChange: (e: any) => {
      oldChange(Number(e));
    },
  };

  return (
    <div style={{ display: 'flex', height: '100%', justifyContent: 'space-around' }}>
      <Form.Item>
        <Switch {...newProps} />
      </Form.Item>
      <div style={style}>
        {!!props.value && <Form.Item>编辑前</Form.Item>}
        <Form.Item>编辑后</Form.Item>
      </div>
    </div>
  );
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};

const RuleConditionEditTable = (props: any) => {
  const { disabled, data, useParent } = props;
  const [form] = Form.useForm();
  const [haveEditTriggerEventType, setHaveEditTriggerEventType] = useState<boolean>();

  useEffect(() => {
    form.setFieldsValue(data);
    setHaveEditTriggerEventType(
      _.some(data?.bizEventTrigger?.triggerEventType, (e: any) => e?.value === 'edit'),
    );
  }, [data]);

  const dataInfo = {
    colProps: { span: 4 },
    rowProps: { gutter: 24 },
    columns: [
      {
        title: '条件id',
        name: 'conditionId',
        noStyle: true,
        hide: true,
      },
      {
        title: '字段',
        name: 'fieldId',
        colProps: { span: haveEditTriggerEventType ? 4 : 6 },
        renderFormItem: () => {
          return <FieldIdCascader disabled />;
        },
      },
      {
        title: '编辑数据校验',
        name: 'updateDataCheck',
        hide: !haveEditTriggerEventType,
        renderFormItem: () => {
          return <MySwitch disabled />;
        },
      },
      {
        title: '条件',
        colProps: { span: haveEditTriggerEventType ? 4 : 6 },
        render: (parentPath: (string | number)[], namePath: (string | number)[]) => {
          const updateDataCheck = _.get(
            data,
            [...parentPath, ...namePath, 'updateDataCheck'].join('.'),
          );

          return (
            <div style={style}>
              {!updateDataCheck ? null : (
                <Form.Item name={[...namePath, 'beforeConditionType']}>
                  <Select disabled options={appDict.triggerRule.conditionType} />
                </Form.Item>
              )}
              <Form.Item name={[...namePath, 'afterConditionType']}>
                <Select disabled options={appDict.triggerRule.conditionType} />
              </Form.Item>
            </div>
          );
        },
      },
      {
        title: '值类型',
        colProps: { span: 6 },
        render: (parentPath: (string | number)[], namePath: (string | number)[]) => {
          const updateDataCheck = _.get(
            data,
            [...parentPath, ...namePath, 'updateDataCheck'].join('.'),
          );

          return (
            <div style={style}>
              {!updateDataCheck ? null : (
                <Form.Item name={[...namePath, 'beforeValueType']}>
                  <Select disabled options={appDict.triggerRule.valueType} />
                </Form.Item>
              )}
              <Form.Item name={[...namePath, 'afterValueType']}>
                <Select disabled options={appDict.triggerRule.valueType} />
              </Form.Item>
            </div>
          );
        },
      },
      {
        title: '值',
        colProps: { span: 6 },
        render: (parentPath: (string | number)[], namePath: (string | number)[]) => {
          const basePath = [...parentPath, ...namePath].join('.');
          const dependencies = [
            `${basePath}.updateDataCheck`,
            `${basePath}.beforeValueType`,
            `${basePath}.afterValueType`,
            `${basePath}.beforeConditionType`,
            `${basePath}.afterConditionType`,
            `${basePath}.fieldId`,
            'objectCode',
          ];

          return (
            <RuleConditionValue
              field={{ name: namePath, parentPath, namePath }}
              dependencies={dependencies}
              disabled={disabled}
              needFormItem={false}
            />
          );
        },
      },
    ],
  };

  return (
    <Form form={form}>
      <div style={{ marginLeft: useParent === 'triggerAction' ? '' : '82px' }}>
        <ConditionEditTableLayout
          {...props}
          info={dataInfo}
          namePath={['triggerCondition', 'conditionGroups']}
          totalLevel={2}
          form={form}
        />
      </div>
    </Form>
  );
};

export default RuleConditionEditTable;
