/**
 * @description 电子签名参照映射
 * @author xuzhiyong
 */

import {
  QC_STATUS,
  ResultOptionEnum,
  CheckTypeEnum,
  CheckEntityTypeEnum,
  ReportPageTypeEnum,
  CheckItemInputTypeEnum,
  CheckValueTypeEnum,
  CheckCountTypeEnum,
  ReportFormatTypeEnum,
  RecordSampleEnum,
  ExtraResultenum,
  SampleProcessEnum,
  TextInputEnum,
  RecordCheckItemTypeEnum,
  AqlTypeEnum,
  inputFactoryAutoTriggerEnum,
  outputFactoryAutoTriggerEnum,
  MaterialProcessEnum,
  SingleJudgmentEnum,
  AssignStatusEnum,
  QcTaskStatusEnum,
  isRecordCountSampleEnum,
  PerformTaskTab,
  CategoryEnum,
  CreateTypeEnum,
} from '.';
import { mappingsFactory } from '../utils';

export const inspectionResultOptions = mappingsFactory(
  ['合格', ResultOptionEnum.qualified],
  ['让步合格', ResultOptionEnum.concessionQualified],
  ['不合格', ResultOptionEnum.unqualified],
  ['待检', ResultOptionEnum.pending],
);

/** 质量状态参照 */
export const qcStatusEnum = mappingsFactory(
  ['合格', QC_STATUS.QUALIFIED],
  ['让步合格', QC_STATUS.CONCESSION_QUALIFIED],
  ['待检', QC_STATUS.WAITING_FOR_CHECK],
  ['不合格', QC_STATUS.FAULTY],
  ['暂控', QC_STATUS.TEMP_CONTROL],
);

export const checkType = mappingsFactory(
  ['入厂检', CheckTypeEnum.inputFactoryQc],
  ['出厂检', CheckTypeEnum.outputFactoryQc],
  ['首检', CheckTypeEnum.produceOriginQc],
  ['生产检', CheckTypeEnum.produceQc],
  ['巡检 ', CheckTypeEnum.inspectionQc],
  ['通用检', CheckTypeEnum.generalQc],
);

export const CheckEntityType = mappingsFactory(
  ['和抽样的库存明细个数一致', CheckEntityTypeEnum.SameAsBatchCount],
  ['和抽样量一致', CheckEntityTypeEnum.SameAsSample],
  ['固定份数', CheckEntityTypeEnum.Custom],
  ['执行时自行添加', CheckEntityTypeEnum.useExecuteAdd],
  ['按检验项分别配置', CheckEntityTypeEnum.useCheckItemConfig],
);

export const ReportPageType = mappingsFactory(
  ['按检验单填写', ReportPageTypeEnum.pageByReport],
  ['按检验项填写', ReportPageTypeEnum.pageByCheckItem],
);

export const CheckItemInputType = mappingsFactory(
  ['数值', CheckItemInputTypeEnum.inputFormatNum],
  ['文本', CheckItemInputTypeEnum.inputFormatText],
  ['百分比', CheckItemInputTypeEnum.inputFormatPercent],
  ['单选项', CheckItemInputTypeEnum.inputFormatSingle],
  ['多选项', CheckItemInputTypeEnum.inputFormatMultiple],
  ['记录次品数', CheckItemInputTypeEnum.inputFormatRecordDefectCount],
);

export const checkValueType = mappingsFactory(
  ['单一数值', CheckValueTypeEnum.singleNumber],
  ['数值区间', CheckValueTypeEnum.between],
);

export const CheckCountType = mappingsFactory(
  ['无', CheckCountTypeEnum.none],
  ['比例抽检', CheckCountTypeEnum.ratioCheck],
  ['固定抽检', CheckCountTypeEnum.customCheck],
  ['国标AQL', CheckCountTypeEnum.aqlCheck],
  ['自定义AQL', CheckCountTypeEnum.customAqlCheck],
  ['执行时自行设置', CheckCountTypeEnum.useExecuteSet],
);

export const ReportFormatType = mappingsFactory(
  ['每个库存明细分别记录', ReportFormatTypeEnum.materialUnit],
  ['根据抽样总量记录', ReportFormatTypeEnum.SampleCount],
);

export const RecordSample = mappingsFactory(
  ['是', RecordSampleEnum.record],
  ['否', RecordSampleEnum.notRecord],
);

export const SampleProcess = mappingsFactory(
  ['放回', SampleProcessEnum.restore],
  ['报废', SampleProcessEnum.scrap],
);

export const ExtraResult = mappingsFactory(
  ['样本平均值', ExtraResultenum.averageSample],
  ['样本标准偏差', ExtraResultenum.standardDeviationSample],
);

export const TextInputType = mappingsFactory(
  ['单行文本', TextInputEnum.singleText],
  ['多行文本', TextInputEnum.multipleText],
);

export const recordCheckItemType = mappingsFactory(
  ['是', RecordCheckItemTypeEnum.yes],
  ['否', RecordCheckItemTypeEnum.no],
);

export const aqlTypes = mappingsFactory(
  ['正常', AqlTypeEnum.normal],
  ['加严', AqlTypeEnum.tightPlus],
  ['放宽', AqlTypeEnum.widenPlus],
);

export const inputFactoryAutoTrigger = mappingsFactory(
  ['采购入库', inputFactoryAutoTriggerEnum.purchaseInbound],
  ['退货入库', inputFactoryAutoTriggerEnum.returnInbound],
  ['其他入库', inputFactoryAutoTriggerEnum.otherInbound],
);

export const outputFactoryAutoTrigger = mappingsFactory(
  ['销售出库', outputFactoryAutoTriggerEnum.salesOutbound],
  ['退厂出库', outputFactoryAutoTriggerEnum.backFactoryOutbound],
  ['其他出库', outputFactoryAutoTriggerEnum.otherOutbound],
);

export const materialProcessType = mappingsFactory(
  ['不记录', MaterialProcessEnum.MATERIAL_NO_RECORD],
  ['仅记录不更新质量状态', MaterialProcessEnum.MATERIAL_ONLY_RECORD],
  ['记录并更新质量状态', MaterialProcessEnum.MATERIAL_RECORD_UPDATE_STATUS],
);
export const singleJudgment = mappingsFactory(
  ['合格', SingleJudgmentEnum.qualified],
  ['不合格', SingleJudgmentEnum.unqualified],
);

export const assignStatus = mappingsFactory(
  ['已领取', AssignStatusEnum.assigned],
  ['未领取', AssignStatusEnum.unAssign],
);

export const qcTaskStatus = mappingsFactory(
  ['未开始', QcTaskStatusEnum.unstarted],
  ['执行中', QcTaskStatusEnum.executing],
  ['已结束', QcTaskStatusEnum.finished],
  ['已取消', QcTaskStatusEnum.cancled],
);

export const recordSummaryCount = mappingsFactory(
  ['是', isRecordCountSampleEnum.yes],
  ['否', isRecordCountSampleEnum.no],
);

export const category = mappingsFactory(
  ['总体', CategoryEnum.OVERALL],
  ['样本', CategoryEnum.SAMPLE],
);

export const createType = mappingsFactory(
  ['手动创建', CreateTypeEnum.handle],
  ['自动创建', CreateTypeEnum.auto],
  ['接口创建', CreateTypeEnum.api],
);
