import _, { result } from 'lodash';
import { ICustomFields, initCustomFieldsInData } from 'src/components/customField';
import { FieldType } from 'src/dict/common';
import {
  CheckEntityTypeEnum,
  CheckItemInputTypeEnum,
  CheckTypeEnum,
  QcTaskStatusEnum,
  ReportFormatTypeEnum,
} from 'src/dict/quality';
import { CheckItem } from '../dto';
import { CheckMaterial } from '../dto/checkMaterial';
import { checkMaterials } from '../dto/checkMaterials';
import { QcConfig } from '../dto/qcConfig';
import { QcTask, RecordDetail } from '../dto/qcTask';
import { formatValue } from '../utils/qcTask';
import { QcConfigEntity } from './qcConfig';
export class QcTaskEntity {
  checkCount: number;
  sampleMaterials?: CheckMaterial[];
  checkItems?: RecordDetail[];
  qcConfig: QcConfig;
  checkType?: CheckTypeEnum;
  status?: QcTaskStatusEnum;
  id?: number;
  qcTaskDetailCheckItems?: RecordDetail[];
  checkCountInfo?: { count?: number };
  checkMaterials?: checkMaterials[];
  qcUnit?: { name: string; precisionFigure: number };
  checkItemRecordCustomFields: ICustomFields;

  constructor(params: QcTask) {
    this.checkCount = params.checkCount || 0;
    this.qcConfig = params.qcConfig;
    this.checkItems = params.checkItems;
    this.sampleMaterials = params.sampleMaterials;
    this.checkMaterials = params.checkMaterials;
    this.checkType = params.checkType;
    this.status = params.status;
    this.qcUnit = params.qcUnit;
    this.id = params.id;
    this.checkItemRecordCustomFields = params.checkItemRecordCustomFields;
  }

  qcConfigEntity = () => new QcConfigEntity(this.qcConfig);
  /** 获取报告份数 */
  getReportCount = () => {
    const { checkCount, sampleMaterials, checkItems, qcConfig } = this || {};
    const { checkEntityUnitCount } = qcConfig || {};

    if (!qcConfig) {
      return 0;
    }

    if (qcConfig.checkEntityType === CheckEntityTypeEnum.SameAsBatchCount) {
      if (!this.isRecordSample() && !this.qcConfigEntity().isRecordTotal()) {
        return 1;
      }
      return sampleMaterials?.length || 0;
    }
    if (qcConfig.checkEntityType === CheckEntityTypeEnum.SameAsSample) {
      if (!this.isRecordSample() && !this.qcConfigEntity().isRecordTotal()) {
        return 1;
      }
      if (checkCount > 100) {
        return 1;
      }
      return Math.ceil(checkCount || 0);
    }
    if (qcConfig.checkEntityType === CheckEntityTypeEnum.Custom) {
      return Number(checkEntityUnitCount);
    }

    if (
      qcConfig.checkEntityType === CheckEntityTypeEnum.useExecuteAdd ||
      qcConfig.checkEntityType === CheckEntityTypeEnum.useCheckItemConfig
    ) {
      const list = _.map(checkItems, ({ qcTaskCheckItems }) => {
        return _.maxBy(qcTaskCheckItems, 'seq')?.seq || 0;
      });

      return _.max(list) || 1;
    }

    return Math.ceil(checkCount && checkCount <= 100 ? checkCount : 1);
  };

  /** 抽样量是否和和抽样的库存明细个数一致 */
  isSameAsBatchCount = () =>
    this?.qcConfig.checkEntityType === CheckEntityTypeEnum.SameAsBatchCount;

  isUnStarted = () => this.status === QcTaskStatusEnum.unstarted;
  isGeneralQc = () => this.checkType === CheckTypeEnum.generalQc;
  isProductQc = () => this.checkType === CheckTypeEnum.produceQc;
  isInputFactoryQc = () => this.checkType === CheckTypeEnum.inputFactoryQc;
  isOutputFactoryQc = () => this.checkType === CheckTypeEnum.outputFactoryQc;
  isProduceOriginQc = () => this.checkType === CheckTypeEnum.produceOriginQc;
  isInspectionQc = () => this.checkType === CheckTypeEnum.inspectionQc;

  /** 执行页面 - 是否展示总量 */
  isShowTotalCount = () => {
    const qcConfigEntity = this.qcConfigEntity();

    if (this.isGeneralQc()) {
      return false;
    }
    if (
      !qcConfigEntity.isRecordSample() &&
      !qcConfigEntity.isRecordTotal() &&
      qcConfigEntity.canSetIsRecordSummaryCount() &&
      !qcConfigEntity.isRecordSummaryCount?.()
    ) {
      return false;
    }
    return true;
  };

  /** 执行页面 - 是否展示总抽样量 */
  isShowCheckCount = () => this.isShowTotalCount();

  /** 执行页面 - 总量是否可以编辑 */
  canEditTotalCount = () => {
    const qcConfigEntity = this.qcConfigEntity();

    if (
      !qcConfigEntity.isRecordTotal() &&
      !qcConfigEntity.isRecordSample() &&
      (!qcConfigEntity.canSetIsRecordSummaryCount() ||
        (qcConfigEntity.canSetIsRecordSummaryCount() && qcConfigEntity.isRecordSummaryCount?.()))
    ) {
      return true;
    }
    return false;
  };

  /** 执行页面 - 总抽样量是否可以编辑 */
  canEditCheckCount = () => this.canEditTotalCount();

  isRecordByCheckItem = () => this.qcConfigEntity().isRecordByCheckItem();

  /** 物料信息页总体处理方式是否为记录不更新质量状态或者记录更新质量状态 */
  isMaterialBatchRecordType = () => this.qcConfigEntity().isMaterialBatchRecordType();

  /** 物料信息页总体处理方式是否为记录更新质量状态 */
  isSingleMaterialBatchRecordType = () => this.qcConfigEntity().isSingleMaterialBatchRecordType();

  /** 物料信息页-样本记录填写格式是否为每个库存明细分别填写 */
  isReportFormatType = () => this.qcConfigEntity().isReportFormatType();
  /** 按检验单填写中的报告是否展示库存信息 */
  isShowInventeryInReport = () =>
    this.qcConfigEntity().isRecordSample() &&
    this.isReportFormatType() &&
    this.qcConfigEntity()?.reportPageSameAsInventory();

  /** 基本信息是否展示检验结论 */
  isShowRecordResult = () =>
    this.qcConfigEntity().isRecordSample() && !this.qcConfigEntity().isReportFormatType();
  /** 物料信息页-样本是否需要记录 */
  isRecordSample = () => this.qcConfigEntity().isRecordSample();

  /** 已填检验项记录数据处理 */
  formateCheckItemRecord = (recordData: any, checkItem: CheckItem) => {
    const reportValue = recordData
      ? {
          executeItemType: checkItem.executeItemType,
          ...recordData,
          valueMin: recordData.min,
          valueMax: recordData.max,
        }
      : { ...checkItem };

    if (!recordData) {
      return {
        ...formatValue(reportValue, checkItem),
      };
    }

    const { qcDefectVOS, ...rest } = recordData;
    const defectList = _.map(qcDefectVOS, (defectInfo) => ({
      defectReason: defectInfo?.qcDefectReasonId,
      defectRank: defectInfo?.qcDefectRankId,
    }));

    return initCustomFieldsInData({
      defectList,
      ...formatValue(reportValue, checkItem),
      ...rest,
    });
  };

  /** 已填报告汇总 */
  resultList = () => {
    const resultList = _.flatMap(this.checkItems, (group) =>
      _.map(group.qcTaskCheckItems, (checkItem) => {
        const checkItemConfig = this.qcConfigEntity()
          .getCheckItems()
          .find(
            (_config: { qcConfigCheckItemId: number }) =>
              _config.qcConfigCheckItemId === checkItem.qcConfigCheckItemId,
          );

        return {
          ...checkItemConfig,
          ...checkItem,
          groupName: group.groupName,
        };
      }),
    );

    return resultList;
  };

  /** 按检验项填写，每份报告的格式化 */
  initCheckItemReports = (checkItem: CheckItem, reportCount: number) => {
    const initialResult = [];
    const { id, attachmentIds, remark, ...rest } = checkItem;

    const checkItemResultList = _.groupBy(this.resultList(), 'qcConfigCheckItemId')?.[Number(id)];

    for (let i = 0; i < (checkItemResultList?.length || this.getReportCount()); i++) {
      const historyResult = checkItemResultList?.[i];

      initialResult.push({
        ...rest,
        seq: i + 1,
        qrCode: this.sampleMaterials?.[i]?.qrCode,
        qcConfigCheckItemId: id,
        totalReportCount: checkItem?.totalReportCount || reportCount,
        ...this.formateCheckItemRecord(historyResult, rest),
      });
    }

    return {
      ...checkItem,
      taskCheckCount: _.groupBy(this.resultList(), 'qcConfigCheckItemId')?.[Number(id)]?.[0]
        ?.taskCheckCount,
      checkItemRemark: remark,
      qcConfigCheckItemId: checkItem?.id,
      reportCount: this.getReportCount(),
      results: initialResult,
    };
  };
  /** 按检验项填写，报告数据处理 */
  getResultByCheckItem = () => {
    const checkItems = this.qcConfigEntity().getCheckItems();
    const customFiledsIntinalValue = initCustomFieldsInData({
      customFields: _.map(this.checkItemRecordCustomFields?.customFields, (customField) => {
        const { fieldType, defaultValue, choiceValues } = customField;

        let fieldValue = defaultValue as any;

        if (fieldType === FieldType.select) {
          fieldValue = _.head(_.filter(choiceValues, 'isDefault'))?.choiceCode;
        }
        if (fieldType === FieldType.multiSelect) {
          fieldValue = _.map(_.filter(choiceValues, 'isDefault'), 'choiceCode');
        }
        return {
          ...customField,
          fieldValue,
        };
      }),
    });

    return {
      checkItems: _.map(checkItems, (checkItem) =>
        this.initCheckItemReports(
          { ...checkItem, ...customFiledsIntinalValue },
          this.getReportCount(),
        ),
      ),
    };
  };

  /** 按检验单填写，每份报告的格式化 */
  initReportValue = (reportIndex: number, isCopy?: boolean) => {
    const checkItems = this.qcConfigEntity()?.getCheckItems?.();

    return {
      reportValues: _.map(checkItems, (checkItem, index) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, attachmentIds, remark, ...rest } = checkItem;
        const historyResult = _.groupBy(this.resultList(), 'qcConfigCheckItemId')?.[checkItem.id]?.[
          reportIndex
        ];

        return {
          ...rest,
          checkItemRemark: remark,
          qcConfigCheckItemId: id,
          qrCode: this.sampleMaterials?.[reportIndex]?.qrCode,
          ...this.formateCheckItemRecord(historyResult, rest),
          seq: reportIndex + 1,
          id: isCopy ? null : this.formateCheckItemRecord(historyResult, checkItem).id,
        };
      }),
    };
  };

  /** 按检验单填写报告格式化 */
  getResultByReport = () => {
    const checkItems = this.qcConfigEntity().getCheckItems();
    const initialResult = [];

    for (let i = 0; i < this.getReportCount(); i++) {
      initialResult.push(this.initReportValue(i));
    }

    return {
      checkItems,
      results: initialResult,
    };
  };

  canAddReport = () => this.qcConfig.checkEntityType === CheckEntityTypeEnum.useExecuteAdd;
}
