import React, { useEffect, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';
import { Form, Checkbox, Input, message, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ResourceBusinessType, ParmTypeEnum } from 'src/dict/resources';
import { RESORCE_BUSINESS_TYPE_MAP, paramType } from 'src/dict/resources/mappings';
import _ from 'lodash';
import {
  fetchParamsDefCreateOrUpdate,
  fetchParamsDefDetail,
  fetchParamsDefUpdate,
} from 'src/api/ytt/resource-domain/parmDefine';
import { CRUD } from 'src/dict/common';
import { NumberRulesStandardHook } from 'src/components';
import LocalStorage from 'src/utils/localStorage';
import { FIELDS } from 'src/utils/constants';
import BlCascader from '../../components/ResourceClassificationCascader';
import {
  checkTwoSidesBackslash,
  checkTwoSidesTrim,
  textValidator2,
} from 'src/utils/formValidators';
import { SearchSelectWithCreate } from 'components';

interface Props {
  type: string;
  refreshTable?: () => void;
  onCancel?: () => void;
  refreshMarker?: number;
  id?: number | undefined;
}

const BaseInfo = (props: Props) => {
  const { type, onCancel, refreshTable, refreshMarker, id } = props;
  const [, setBussinessType] = useState<number>(0);
  const [parmType, setParmType] = useState<number>(0);
  const [form] = Form.useForm();
  const [keepOn, sekeepOn] = useState(type === CRUD.create);
  // const [initialData, setInitialData] = useState<ComplementTree<ListItemType<FetchParamsDefDetailResponse>>['data']>();
  const [statusCode, setStatus] = useState<number>(1);

  const baseInfo: DataFormLayoutInfoBlock = {
    items: [
      ...NumberRulesStandardHook({
        label: '参数编号',
        form,
        edit: type === CRUD.edit,
        objectCode: 'ResourceParameter',
        fieldCode: 'code',
        rules: [
          { max: 255, message: '不可超过255字符' },
          { validator: textValidator2 },
          { validator: checkTwoSidesBackslash },
          // { validator: checkTwoSidesTrim },
        ],
        refreshMarker,
        // disabledState: false,
      }),
      {
        label: '参数名称',
        name: 'name',
        rules: [
          { required: true, message: '参数名称必填' },
          { max: 255, message: '不可超过255字符' },
          { validator: checkTwoSidesTrim },
        ],
        validateFirst: true,
        render: () => <Input placeholder="请输入参数名称" />,
      },
      {
        label: '业务类型',
        name: 'businessType',
        initialValue: { value: ResourceBusinessType.equipment },
        rules: [{ required: true, message: '请选择业务类型' }],
        render: () => (
          <Select
            placeholder="请选择业务类型"
            labelInValue
            options={RESORCE_BUSINESS_TYPE_MAP}
            onChange={(type: any) => {
              setBussinessType(Number(type.value));
            }}
            disabled={type === CRUD.edit}
          />
        ),
      },
      {
        label: '参数类型',
        name: 'paramsType',
        initialValue: { value: ParmTypeEnum.actualTime },
        rules: [{ required: true, message: '请选择参数类型' }],
        render: () => (
          <Select
            placeholder="请选择参数类型"
            labelInValue
            options={paramType}
            onChange={(type: any) => {
              setParmType(Number(type.value));
              Number(type.value) === 2 && form?.resetFields(['unitId']);
            }}
            disabled={type === CRUD.edit}
          />
        ),
      },
      {
        label: '参数分类',
        name: 'classificationId',
        render: () => (
          <BlCascader
            onChange={() => {}}
            // resFetchData={resFetchData}
            showSearch
            placeholder={'请选择参数分类'}
            enableAddLabel={'新建分类'}
            onCreateClick={() => {
              LocalStorage.set(FIELDS.RESOURCE_TYPE_IS_CREATE, true);
              window.open('/resource/resourceConfig/category');
            }}
            enableAdd
          />
        ),
      },
      {
        label: '参数单位',
        name: 'unitId',
        render: () => (
          <SearchSelectWithCreate
            placeholder="请选择参数单位"
            fetchType="unit"
            createFieldName="单位"
            params={{ enableFlag: 1 }}
            onCreateClick={() => {
              // LocalStorage.set(FIELDS.RESOURCE_TAG_IS_CREATE, true);
              // goToCreateResourceTag();
            }}
            // TODO: authority needed
            labelInValue
            disabled={parmType === 2}
            enableAdd={false}
          />
        ),
      },
      {
        label: '备注',
        name: 'remark',
        rules: [{ max: 1000, message: '不可超过1000字符' }],
        render: () => <TextArea placeholder="请输入备注" />,
      },
      {
        label: '编辑原因',
        name: 'editReason',
        hidden: type !== CRUD.edit,
        rules: [{ max: 1000, message: '不可超过1000字符' }],
        render: () => <TextArea placeholder="请输入编辑原因" />,
      },
    ],
    column: 1,
  };
  const initCreate = () => {
    form.setFieldsValue({
      code_isUseRules: true,
    });
  };
  const initEdit = async (resId: number | undefined) => {
    try {
      if (!resId) return;
      const res: any = await fetchParamsDefDetail({ id: resId });
      const { data } = res;
      const {
        code,
        businessType,
        classificationId,
        classificationList,
        name,
        paramsType,
        unitName,
        unitId,
        status,
        remark,
      } = data;
      const val: any = {
        businessType: { value: businessType },
        paramsType: { value: paramsType },
        remark,
      };

      if (type === CRUD.edit) {
        val.code = code;
        val.name = name;
        setStatus(status);
      }
      if (unitId) {
        val.unitId = {
          key: unitId,
          label: unitName, // 未返回
          value: unitId,
        };
      }
      if (classificationId) {
        val.classificationId = classificationList.map(
          (item: { name: string; id: number }) => item.id,
        );
      }

      setParmType(paramsType);
      form.setFieldsValue(val);
      // setInitialData(val);
    } catch (err) {
      console.log(err);
    }
  };
  const initCopy = async (resId: number | undefined) => {
    initEdit(resId);
    initCreate();
  };
  const fetchCreate = async (req: any) => {
    const { code } = await fetchParamsDefCreateOrUpdate(req);

    if (code === 200) {
      message.success('创建成功');
    }
  };
  const fetchEdit = async (req: any) => {
    const { code } = await fetchParamsDefUpdate(req);

    if (code === 200) {
      message.success('编辑成功');
    }
  };
  const handleFinish = async () => {
    try {
      const data = await form?.validateFields();
      const { code, businessType, name, classificationId, paramsType, unitId, ...rest } = data;

      const value = {
        code: code.replace(/\s+/g, ' '),
        name: name.replace(/\s+/g, ' '),
        businessType: businessType.value,
        classificationId: _.isEmpty(classificationId) ? null : classificationId.concat().pop(),
        paramsType: paramsType?.value,
        unitId: unitId?.value,
        status: statusCode ?? 1,
        id: null,
        ...rest,
      };

      if (type === CRUD.edit) {
        value.id = id;
      }

      console.log(data, value);
      switch (type) {
        case CRUD.create:
          await fetchCreate(value);
          break;

        case CRUD.edit:
          await fetchEdit(value);
          break;

        case CRUD.copy:
          await fetchCreate(value);
          break;

        default:
          break;
      }

      typeof refreshTable === 'function' && refreshTable();

      if (keepOn) {
        form.resetFields();
        initCreate();
        return;
      }

      onCancel && onCancel();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    switch (type) {
      case CRUD.create:
        initCreate();
        break;

      case CRUD.copy:
        initCopy(id);
        break;

      case CRUD.edit:
        initEdit(id);
        break;

      default:
        initCreate();
        break;
    }
  }, [refreshMarker]);

  return (
    <DataFormLayout
      form={form}
      info={[baseInfo]}
      title={type !== CRUD.edit ? '新建参数' : '编辑参数'}
      extra={
        type === CRUD.create && (
          <Checkbox
            onChange={() => {
              sekeepOn(!keepOn);
            }}
            defaultChecked={keepOn}
          >
            连续新建
          </Checkbox>
        )
      }
      onCancel={onCancel}
      onFinish={handleFinish}
    />
  );
};

export default BaseInfo;
