import { useState } from 'react';
import { message as Message, FormInstance, Modal } from 'antd';

import {
  fetchReturnAuditNoteAccept,
  fetchReturnAuditNoteDecline,
} from 'src/api/ytt/supply-chain-domain/return_audit_note';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';

export const useOperateReturnApplicationModal = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const accept = (id: number, refresh?: () => void) => {
    Modal.confirm({
      title: '提示',
      content: '确认接受客户发起的退货申请吗？确认后将生成销售退货单且不支持删除；',
      okText: '确认接受',
      cancelText: '再想想',
      onOk: async () => {
        try {
          setButtonLoading(true);
          const response = await fetchReturnAuditNoteAccept({ id });

          if (response?.code === 200) {
            Message.success('接受成功');
            refresh?.();
          } else {
            Message.error(response.message);
          }
        } finally {
          setButtonLoading(false);
          Modal.destroyAll();
        }
      },
      okButtonProps: {
        loading: buttonLoading,
      },
      cancelButtonProps: {
        loading: buttonLoading,
      },
    });
  };

  const reject = (
    id: number,
    cutomerName: string,
    rejectForm: FormInstance,
    refresh?: () => void,
  ) => {
    BLDelConfirm(
      '确认拒绝销售退货单？',
      rejectForm,
      async () => {
        try {
          const reason = rejectForm.getFieldValue('reason');
          const response = await fetchReturnAuditNoteDecline({ id, reason });

          if (response?.code === 200) {
            Message.success('拒绝成功');
            rejectForm.resetFields();
            refresh?.();
          } else {
            Message.error(response.message);
          }
        } catch (err) {
          console.log(err);
        }
      },
      undefined,
      'reason',
      '拒绝原因',
      `确认拒绝来自${cutomerName}的销售退货单吗？拒绝后将不能执行任何操作`,
      '确认拒绝',
      '再想想',
      300,
    );
  };

  return { accept, reject };
};
