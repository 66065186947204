import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import { Badge, message as Message } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  fetchDigitalSignatureBulkActivate,
  FetchDigitalSignatureBulkActivateResponse,
  fetchDigitalSignatureBulkDeactivate,
  FetchDigitalSignatureBulkDeactivateResponse,
  fetchDigitalSignatureDetail,
  FetchDigitalSignatureDetailResponse,
} from 'src/api/ytt/user-domain/eSignature';
import { BlIcon } from 'src/components';
import UserTooltip from 'src/components/tooltip/userTooltip';
import lookup from 'src/dict';
import { ACTIVE_STATUS_STR } from 'src/dict/eS-M';
import DetailSignConfigList from './components/DetailSignConfigList';
import { UserType } from './type';
import { match, RouteComponentProps } from 'react-router-dom';
import SignatureAction from './action';
import authDict from 'src/utils/auth';

interface ESignatureDetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const ESignatureDetailPage: React.FC<ESignatureDetailProps> = (props) => {
  const { match, history } = props;
  const id = Number(match?.params?.id);
  const [dataSource, setDataSource] = useState({} as any);
  const [signatureVisible, setSignatureVisible] = useState(false);

  /**
   * 获取电子签名详情
   * @param id 电子签名ID
   */
  const fetchDetailById = async () => {
    const res: FetchDigitalSignatureDetailResponse = await fetchDigitalSignatureDetail({ id });

    const { code, message, data } = res;

    if (code !== 200) {
      Message.error(message);
      return;
    }
    setDataSource(data);
  };

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      { label: '电子签名名称', dataIndex: 'name', isFullLine: true },
      { label: '所属对象', dataIndex: 'relatedObjectName', isFullLine: true },
      { label: '功能点', dataIndex: 'functionName', isFullLine: true },
      {
        label: '启用状态',
        dataIndex: 'activeStatus',
        isFullLine: true,
        render: (activeStatus: number) => {
          return (
            <Badge
              status={activeStatus === ACTIVE_STATUS_STR.enabled ? 'success' : 'error'}
              text={lookup('esm.activeStatusStrEnum', activeStatus)}
            />
          );
        },
      },
      { label: '备注', dataIndex: 'remark', isFullLine: true },
    ],
    column: 2,
  };

  const signatureConfig: DetailLayoutInfoBlock = {
    title: '签名人配置',
    items: [
      {
        label: '签名规则',
        dataIndex: 'ruleType',
        isFullLine: true,
        render: (ruleType: number) => <div>{lookup('esm.ruleTypeEnum', ruleType)}</div>,
      },
      {
        label: '',
        dataIndex: 'digitalSignatureConfigVOList',
        isFullLine: true,
        render: (list: any[]) => {
          return <DetailSignConfigList signConfigList={list} />;
        },
      },
    ],
    column: 1,
  };

  const others: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        isFullLine: true,
        render: (creator: UserType) => (creator ? <UserTooltip {...creator} /> : null),
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        isFullLine: true,
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        isFullLine: true,
        render: (operator: UserType) => (operator ? <UserTooltip {...operator} /> : null),
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        isFullLine: true,
      },
    ],
    column: 2,
  };

  /**
   * 批量停用
   * @ids 业务id List
   */
  const bulkDeactivate = async (ids: number[]) => {
    const res: FetchDigitalSignatureBulkDeactivateResponse =
      await fetchDigitalSignatureBulkDeactivate({
        ids,
      });

    const { code, message } = res;

    if (code !== 200) {
      Message.error(message);
      return;
    }
    Message.success(message);
    fetchDetailById();
  };

  /**
   * 批量启用
   * @ids 业务id List
   */
  const bulkActivate = async (ids: number[]) => {
    const res: FetchDigitalSignatureBulkActivateResponse = await fetchDigitalSignatureBulkActivate({
      ids,
    });

    const { code, message } = res;

    if (code !== 200) {
      Message.error(message);
      return;
    }
    Message.success(message);
    fetchDetailById();
  };

  const baseMenu = [
    {
      key: 'enable',
      auth: authDict.electronicsignature_enable_disable,
      onClick: () => {
        if (dataSource.activeStatus === ACTIVE_STATUS_STR.enabled) {
          bulkDeactivate([id]);
        } else {
          bulkActivate([id]);
        }
        fetchDetailById();
      },
      icon:
        dataSource.activeStatus === ACTIVE_STATUS_STR.enabled ? (
          <BlIcon type="icontingyong" />
        ) : (
          <BlIcon type="iconqiyong" />
        ),
      title: dataSource.activeStatus === ACTIVE_STATUS_STR.enabled ? '停用' : '启用',
    },
    {
      key: 'edit',
      onClick: () => {
        history.push(`/systemManagement/eS-M/edit/${id}`);
      },
      icon: <BlIcon type="iconbianji" />,
      auth: authDict.electronicsignature_edit,
      title: '编辑',
    },
  ];

  useEffect(() => {
    if (id) {
      fetchDetailById();
    }
  }, [id]);

  return (
    <>
      <DetailLayout
        title={'电子签名详情'}
        baseMenu={baseMenu}
        info={[baseInfo, signatureConfig, others]}
        dataSource={dataSource}
      />
    </>
  );
};

export default ESignatureDetailPage;
