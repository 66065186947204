import { useContext } from 'react';
import { Tabs, Space, Tooltip } from 'antd';
import moment from 'moment';

import { BlIcon } from 'src/components';
import { REST_SHIFT } from 'src/utils/constants';
import { ScheduleShiftValueType, ShiftItemType, ShiftContext } from '../../constants';
import styles from './index.module.scss';

const { TabPane } = Tabs;

interface ScheduleShiftPopoverProps {
  onChange: (selectedValue: ScheduleShiftValueType) => void;
}

const byRuleTitle = (
  <Space size={3}>
    <span>按规则排班</span>
    <Tooltip title="从该天开始，按所选排班规则排班，直至当前月份最后一天。使用该方式排班时必须已填写结束日期。">
      <BlIcon type="icontixing-jingshi" />
    </Tooltip>
  </Space>
);

/**
 * 格式化指定时间段
 * @param startTime 开始时间
 * @param endTime 结束时间
 * @returns 格式化完的时间段字符串
 */
const formatPeriod = (startTime?: number, endTime?: number) => {
  const startOfToday = moment().startOf('day');
  const startMoment = startOfToday.clone().add(startTime, 'ms');
  const endMoment = startOfToday.clone().add(endTime, 'ms');
  const nextDay = startMoment.date() !== endMoment.date() ? '次日' : '';

  return `${startMoment.format('HH:mm')}-${nextDay}${endMoment.format('HH:mm')}`;
};

/**
 * 格式化班次信息的显示label
 * @param shift 班次object
 */
const getShiftLabel = (shift: ShiftItemType) => {
  const { name, shiftInfoDetailVOS } = shift;
  const shiftInfoString = shiftInfoDetailVOS
    ?.map((shiftInfo) => formatPeriod(shiftInfo.startTime, shiftInfo.endTime))
    .join('; ');

  return shiftInfoString && shiftInfoString.length > 0 ? `${name} (${shiftInfoString})` : name;
};

const restDay: ShiftItemType = {
  id: REST_SHIFT.value,
  name: REST_SHIFT.label,
  color: '#F5F5F5',
};

/** 排班浮层内容：按天排班/按排班规则排班 */
export default function ScheduleShiftPopover({ onChange }: ScheduleShiftPopoverProps) {
  const { shifts, shiftRules } = useContext(ShiftContext);

  return (
    <Tabs defaultActiveKey="1" className={styles.scheduleTabs}>
      <TabPane tab="按天排班" key="1">
        {[...shifts, restDay].map((shift) => (
          <div
            tabIndex={1}
            className={styles.shiftListItem}
            style={{ backgroundColor: shift.color }}
            title={getShiftLabel(shift)}
            key={shift.id}
            onClick={() => {
              onChange({ ...shift, isShift: true });
            }}
          >
            <div className={styles.shiftLabel}>{getShiftLabel(shift)}</div>
          </div>
        ))}
      </TabPane>
      <TabPane tab={byRuleTitle} key="2">
        {shiftRules.map((shiftRule) => (
          <div
            tabIndex={1}
            className={styles.shiftListItem}
            title={shiftRule.name}
            key={shiftRule.id}
            onClick={() => {
              onChange({ ...shiftRule, isShift: false });
            }}
          >
            <div className={styles.shiftLabel}>{shiftRule.name}</div>
          </div>
        ))}
      </TabPane>
    </Tabs>
  );
}
