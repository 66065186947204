import React, { useEffect, useState } from 'react';
import { message as Message } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import { replaceSign } from 'src/utils/constants';
import {
  fetchParamsDefDetail,
  FetchParamsDefDetailResponse,
  fetchParamsDefEnable,
  fetchParamsDefStop,
} from 'src/api/ytt/resource-domain/parmDefine';
import renderUsageStatus from 'src/page/share/renderUsageStatus';
import lookup from 'src/dict';
import { BlIcon } from 'src/components';
import authDict from 'src/utils/auth';
import _Time from 'src/utils/dataTypes/time';

interface Props {
  id: number;
  showEdit: () => void;
  showOperationRecord: () => void;
  refreshDetailsMarker: number | undefined;
  refreshTable?: () => void;
}

const DetailsBaseInfo = (props: Props) => {
  const { id: detailId, showEdit, showOperationRecord, refreshDetailsMarker, refreshTable } = props;
  const [dataSource, setDataSource] = useState<FetchParamsDefDetailResponse>();
  const [statusCode, setStatus] = useState<number>(0);
  const init = async () => {
    try {
      const res: any = await fetchParamsDefDetail({ id: detailId });
      const { data } = res;
      const { status } = data;

      setStatus(status);
      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };
  // const handleFinish = async (id: number | number[]) => {
  //   try {
  //     const value = await delForm?.validateFields();
  //     const ids = typeof id === 'number' ? [id] : id;
  //     const { code, message: mes } = await fetchMaterialAttributeDelete({
  //       ids,
  //       reason: value.delReason,
  //     });

  //     if (code === 200) {
  //       message.success('删除成功');
  //       // 关闭details
  //       typeof onCancel === 'function' && onCancel();
  //       // 刷新 list
  //       typeof refreshTable === 'function' && refreshTable();
  //       return;
  //     }
  //     message.error(mes);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const initBaseMenu = [
    // {
    //   key: 'delete',
    //   onClick: () => {
    //     confirm('是否删除当前单位', delForm, () => {
    //       handleFinish(detailId);
    //     });
    //   },
    //   // icon: <<BlIcon type="iconshanchulajitong1" /> />,
    //   title: '删除',
    // },
    {
      key: 'operate',
      auth: authDict.resourceparameter_operrecord,
      title: '操作记录',
      onClick: () => {
        showOperationRecord();
      },
    },
    {
      title: lookup('changeStatusAction', statusCode), // 启停用
      key: 'qitingyong',
      icon: <BlIcon type="iconqiyong" />,
      auth: authDict.resourceparameter_enable_disable,
      onClick: () => {
        (async () => {
          const response = await (!statusCode
            ? fetchParamsDefEnable({ ids: [detailId] })
            : fetchParamsDefStop({ ids: [detailId] }));

          if (response?.code === 200) {
            Message.success(`${lookup('changeStatusAction', statusCode)}成功`);
            init();
            refreshTable && refreshTable();
          } else {
            Message.error(response.message);
          }
        })();
      },
    },
    {
      key: 'edit',
      auth: authDict.resourceparameter_edit,
      onClick: () => {
        showEdit();
      },
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ];

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '参数编号',
        dataIndex: 'code',
        // isFullLine: true,
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '参数名称',
        dataIndex: 'name',
        // isFullLine: true,
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '业务类型',
        dataIndex: 'businessType',
        // isFullLine: true,
        render: (value: number) => lookup('resources.RESORCE_BUSINESS_TYPE_MAP', value),
      },
      {
        label: '参数类型',
        dataIndex: 'paramsType',
        render: (value: number) => lookup('resources.paramType', value),
      },
      {
        label: '参数分类',
        dataIndex: 'classificationId',
        render: (text: number, record: any) => {
          if (text) {
            return record?.classificationList
              ?.map((item: { name: string; id: number }) => item.name)
              .join('/');
          }
          return replaceSign;
        },
      },
      {
        label: '参数单位',
        dataIndex: 'unitName',
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '启用状态',
        dataIndex: 'status',
        isFullLine: true,
        render: (text: number) => renderUsageStatus(text),
      },
      {
        label: '备注',
        dataIndex: 'remark',
        isFullLine: true,
        render: (value: string) => value,
      },
    ],
    column: 2,
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: ['creator', 'name'],
        render: (name: string) => name || replaceSign,
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: (createdAt: number[]) => {
          if (!createdAt) return replaceSign;
          createdAt[1] -= 1;
          return _Time.format(createdAt);
        },
      },
      {
        label: '更新人',
        dataIndex: ['operator', 'name'],
        render: (name: string) => name || replaceSign,
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: (updatedAt: number[]) => {
          if (!updatedAt) return replaceSign;
          updatedAt[1] -= 1;
          return _Time.format(updatedAt);
        },
      },
    ],
    column: 2,
  };
  const infoArray = [baseInfo, otherInfo];

  useEffect(() => {
    if (detailId) {
      init();
    }
  }, [refreshDetailsMarker]);
  return (
    <>
      <DetailLayout
        title={'参数详情'}
        baseMenu={initBaseMenu}
        info={infoArray}
        dataSource={dataSource}
      />
    </>
  );
};

export default DetailsBaseInfo;
