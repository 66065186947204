import { DetailLayout } from '@blacklake-web/layout';
import { DetailLayoutInfoBlock } from '@blacklake-web/layout/dist/detail/DetailLayout.type';
import React, { useEffect, useState } from 'react';
import { replaceSign } from 'src/utils/constants';
import _ from 'lodash';
import _Array from 'src/utils/dataTypes/array';
import { renderDepartmentArray, renderUserArray } from 'src/page/share/renderUser';
import TagList from '../../../components/tagList';
import _Time from 'src/utils/dataTypes/time';
import AdjustmentHistoryTable from 'src/page/productionPlanning/progressReport/components/AdjustmentHistoryTable';
import { RouteChildrenProps } from 'react-router';
import {
  fetchProgressReportDetail,
  fetchProgressReportListAdjustRecord,
} from 'src/api/ytt/mfg-domain/productionTask';
import lookup from 'src/dict';
import { formatResourceGroupListToResourceList } from 'src/page/productionPlanning/progressReport/helper/utils';
import { Popover, Space } from 'antd';
import { BlIcon, QrCode } from 'components';

interface Props extends RouteChildrenProps<{ id: string }> {}

const ProgressReportDetail = (props: Props) => {
  const { match } = props;
  const id = Number(match?.params?.id);
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState<any>({});
  const [baseInfo, setBaseInfo] = useState<DetailLayoutInfoBlock>({
    title: '基本信息',
    items: [
      {
        label: '生产任务编号',
        dataIndex: 'taskCode',
      },
      {
        label: '工序名称',
        dataIndex: 'processName',
      },
      {
        label: '工序编号',
        dataIndex: 'processCode',
      },
      {
        label: '报工物料名称',
        dataIndex: ['materialInfo', 'baseInfo', 'name'],
      },
      {
        label: '报工物料编号',
        dataIndex: ['materialInfo', 'baseInfo', 'code'],
      },
      {
        label: '批次号',
        dataIndex: 'batchNo',
        render: (value: string) => {
          if (value == undefined || value === '') return replaceSign;
          return value;
        },
      },
      {
        label: '标识码',
        dataIndex: 'qrCode',
        render: (qrCode: string) => {
          if (qrCode == undefined) return replaceSign;
          return (
            <div>
              <Space style={{ marginRight: '5px' }}>
                {qrCode ?? dataSource?.code ?? replaceSign}
              </Space>
              <Space>
                <Popover
                  placement="rightTop"
                  content={
                    <div>
                      <QrCode value={qrCode ?? dataSource?.code ?? ''} />
                      <div style={{ textAlign: 'center' }}>{qrCode ?? dataSource?.code ?? ''}</div>
                    </div>
                  }
                >
                  <BlIcon type="iconerweima" />
                </Popover>
              </Space>
            </div>
          );
        },
      },
      {
        label: '生产人员',
        dataIndex: ['producers'],
        render: (value) => {
          if (_Array.isEmpty(value)) return replaceSign;
          return renderUserArray(value);
        },
      },
      {
        label: '质量状态',
        dataIndex: ['qcStatus'],
        render: (value) => {
          return lookup('productionPlanning.QualityStatusOptions', value);
        },
      },
      {
        label: '报工数量1',
        dataIndex: 'reportBaseAmount',
        render: (value) => {
          if (!value) return replaceSign;
          return value?.amount + ' ' + value?.unit?.name;
        },
      },
      {
        label: '报工数量2',
        dataIndex: 'reportAuxBaseAmount1',
        render: (value) => {
          if (!value) return replaceSign;
          return value?.amount + ' ' + value?.unit?.name;
        },
      },
      {
        label: '报工时间',
        dataIndex: 'reportTime',
        render: (reportTime: Date) => {
          if (!reportTime) return replaceSign;
          return _Time.format(reportTime);
        },
      },
      {
        label: '备注',
        dataIndex: 'remark',
        render: (value: string) => {
          if (!value) return replaceSign;
          return value;
        },
      },
      {
        label: '部门',
        dataIndex: 'produceDepartments',
        render: (departments) => {
          if (_Array.isEmpty(departments)) return replaceSign;
          return renderDepartmentArray(departments);
        },
      },
      {
        label: '设备',
        dataIndex: 'equipments',
        render: (equipments) => {
          if (_Array.isEmpty(equipments)) return replaceSign;
          return <TagList dataSource={equipments} labelPath="name" valuePath="id" />;
        },
      },
      {
        label: '执行人',
        dataIndex: 'executors',
        render: (executors) => {
          if (_Array.isEmpty(executors)) return replaceSign;
          return renderUserArray(executors);
        },
      },
      {
        label: '工单编号',
        dataIndex: 'workOrderCode',
      },
      {
        label: '工单物料名称',
        dataIndex: 'mainMaterialName',
      },
      {
        label: '工单物料编号',
        dataIndex: 'mainMaterialCode',
      },
      {
        label: '订单编号',
        dataIndex: 'salesOrderCode',
        render: (value) => {
          if (!value) return replaceSign;
          if (_Array.isEmpty(value)) return replaceSign;
          return (
            <TagList dataSource={value.map((item: string) => ({ label: item, value: item }))} />
          );
        },
      },
      {
        label: '报工记录编号',
        dataIndex: 'reportRecordCode',
      },
    ],
    column: 3,
  });
  const adjustmentHistoryInfo: DetailLayoutInfoBlock = {
    title: '调整记录',
    items: [
      {
        dataIndex: 'adjustmentHistory',
        isFullLine: true,
        render: (value) => <AdjustmentHistoryTable dataSource={value} />,
      },
    ],
  };

  const getDetailDataAndSet = async (id: number) => {
    if (!id) return;
    const promise1 = fetchProgressReportDetail({ reportRecordId: id }).then((res) => {
      if (!res.data) return;
      return res.data;
    });
    const promise2 = fetchProgressReportListAdjustRecord({ reportRecordId: id }).then((res) => {
      if (!res.data) return;
      return res.data;
    });

    Promise.allSettled([promise1, promise2])
      .then((resList: any) => {
        let result: any = {};
        const data1 = resList[0]?.value;
        const data2 = resList[1]?.value;
        const resourceGroupList = data1.resourceGroupList;

        if (resourceGroupList) {
          // @ts-ignore
          const { labelList, valueList } = formatResourceGroupListToResourceList(resourceGroupList);

          setBaseInfo({
            ...baseInfo,
            items: baseInfo.items
              .slice(0, 16)
              .concat(...labelList)
              .concat(baseInfo.items.slice(16)),
          });
          result.resourceList = valueList;
        }

        if (!_.isEmpty(data1)) result = { ...result, ...data1 };
        if (!_.isEmpty(data2)) result.adjustmentHistory = data2;
        setDataSource(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDetailDataAndSet(id).then();
  }, [id]);

  return (
    <DetailLayout
      loading={loading}
      title="报工记录详情"
      dataSource={dataSource}
      info={[baseInfo, adjustmentHistoryInfo]}
    />
  );
};

export default ProgressReportDetail;
