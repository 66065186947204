import React, { useEffect, useState } from 'react';
import { Form, message as Message } from 'antd';
import { match, RouteComponentProps } from 'react-router-dom';
import CreateAndEditBaseInfo from './components/createAndEditBaseInfo';
import { fetchProcessDetail, fetchProcessUpdate } from 'src/api/ytt/med-domain/process';
import _Array from 'src/utils/dataTypes/array';
import { throttleFn } from '../../warehouse/utils';

interface EditProcessProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

export default function EditProcess(props: EditProcessProps) {
  const { match, history } = props;
  const processId = Number(match?.params?.id);

  const [detailData, setDetailData] = useState<any>({} as any);

  const [editForm] = Form.useForm();

  const fetchData = async () => {
    try {
      const { data } = await fetchProcessDetail({ id: processId });

      setDetailData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = async () => {
    try {
      const value = await editForm?.validateFields();

      const { department, workCenterId, traceRelationship, ...rest } = value;

      if (!_Array.isEmpty(department)) {
        if (department?.[0]?.key) {
          rest.departmentId = department?.[0]?.key;
        } else {
          rest.departmentId = department?.[0]?.value;
        }
      } else {
        rest.departmentId = null;
      }

      const { data, message } = await fetchProcessUpdate({
        ...rest,
        workCenterId: workCenterId?.value ?? workCenterId?.key,
        configurationItems: { traceRelationship },
        id: processId,
      });

      if (data) {
        Message.success('更新成功');
        history.goBack();
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [processId]);

  return (
    <CreateAndEditBaseInfo
      edit
      onFinish={throttleFn(handleFinish)}
      onCancel={() => {
        history.goBack();
      }}
      title="编辑工序"
      form={editForm}
      initialData={detailData}
    />
  );
}
