/**
 * @mappings 销售订单 -值映射表
 */

import {
  AssociatedWorkOrder,
  InvoiceStatus,
  MaterialBizRange,
  OrderStatus,
  OriginType,
  ProductionStatus,
  PurchaseRequisitionStatus,
  ShipmentStatus,
} from '.';
import { mappingsFactory } from '../utils';

/**
 * 订单状态
 */
export const orderStatus = mappingsFactory(
  ['新建', OrderStatus.create],
  ['结束', OrderStatus.finished],
);
/**
 * 订单来源
 */
export const originType = mappingsFactory(
  ['普通订单', OriginType.ordinary],
  ['协同订单', OriginType.collaborate],
);
/**
 * 生产状态
 */
export const productionStatus = mappingsFactory(
  ['待计划', ProductionStatus.toPlan],
  ['部分计划', ProductionStatus.partPlan],
  ['已计划', ProductionStatus.planned],
  ['已完工', ProductionStatus.completed],
);
/**
 * 工单状态
 */
export const associatedWorkOrder = mappingsFactory(
  ['新建', AssociatedWorkOrder.created],
  ['已下发', AssociatedWorkOrder.issued],
  ['执行中', AssociatedWorkOrder.perform],
  ['已完工', AssociatedWorkOrder.completion],
  ['已关闭', AssociatedWorkOrder.closed],
);

/**
 * 发货状态
 */
export const shipmentStatus = mappingsFactory(
  ['待发货', ShipmentStatus.toBeDelivered],
  ['部分发货', ShipmentStatus.partiallyShipped],
  ['全部发货', ShipmentStatus.allShipped],
);

/**
 * 协同采购申请单 状态
 */
export const purchaseRequisitionStatus = mappingsFactory(
  ['待处理', PurchaseRequisitionStatus.pending],
  ['已接受', PurchaseRequisitionStatus.accepted],
  ['已拒绝', PurchaseRequisitionStatus.rejected],
);
/**
 * 发货单状态
 */
export const invoiceStatus = mappingsFactory(
  ['新建', InvoiceStatus.create],
  ['执行中', InvoiceStatus.inprogress],
  ['结束', InvoiceStatus.finish],
  ['关闭', InvoiceStatus.close],
);

/**
 * 物料业务范围
 */
export const materialBizRange = mappingsFactory(
  ['虚拟件', MaterialBizRange.phantom],
  ['仓储', MaterialBizRange.warehousing],
  ['采购', MaterialBizRange.purchase],
  ['销售', MaterialBizRange.sales],
  ['生产', MaterialBizRange.production],
  ['质量', MaterialBizRange.quality],
);
