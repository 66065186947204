import { map, cloneDeep, findIndex, isArray } from 'lodash';
import moment from 'moment';
import { FieldType } from 'src/dict/customField';
import _Array from 'src/utils/dataTypes/array';
import _Time from 'src/utils/dataTypes/time';

const formatFieldFilterInfo = (data: any) => {
  const object = data?.object?.value && JSON.parse(data?.object?.value);

  const { choiceValues, ...rest } = object;

  const formatObjectValue = (data: any) => {
    if (data?.label) {
      return data?.key;
    }
    return data;
  };

  const formatTime = (data: any) => {
    // 最特殊的就是value是日期需要转化成时间戳

    let _value: any;

    if (rest?.fieldType === FieldType.date) {
      _value = Number(_Time.formatToUnix(data));
    } else {
      _value = isArray(data) ? map(data, 'value').join(',') : formatObjectValue(data);
    }

    return _value;
  };

  const newField = {
    ...rest,
    condition: data?.symbol?.value,
    conditionDisplayName: data?.symbol?.label,
    value: formatTime(data?.value),
    valueDisplay: isArray(data?.value) ? JSON.stringify(data?.value) : data?.value?.label ?? '',
  };

  return newField;
};

export const formatFormFilterToGetList = (data: any) => {
  const { fieldFilterInfo, nextFloorCondition, nextType, ...rest } = data;

  const relData = { ...rest };

  const objectFilterConditionList = map(nextFloorCondition, (node: any) => {
    const newNode = cloneDeep(node);

    if (node?.fieldFilterInfo) {
      // 代表是条件行

      newNode.fieldFilterInfo = formatFieldFilterInfo(node?.fieldFilterInfo);

      return { ...newNode, nextTypeDisplayName: '' };
    }
    const newNextFloorCondition = map(node?.nextFloorCondition, (e: any) => {
      const newFieldFilterInfo = formatFieldFilterInfo(e?.fieldFilterInfo);

      return newFieldFilterInfo;
    });

    newNode.nextFloorCondition = newNextFloorCondition;
    newNode.nextTypeDisplayName = node?.nextType === 'or' ? '或' : '且';

    return { ...newNode, fieldFilterInfo: {} };
  });

  return {
    ...relData,
    objectFilterConditionList,
    type: nextType,
    typeDisplayName: nextType === 'or' ? '或' : '且',
  };
};

const formatFilterInfo = (data: any, previewFieldInfoList: any[]) => {
  const replaceList =
    previewFieldInfoList?.[
      findIndex(previewFieldInfoList, (e: any) => e.fieldId === data?.fieldId)
    ];

  if (replaceList) {
    data.fieldDisplayName = replaceList?.fieldDisplayName;
  }

  const {
    condition,
    conditionDisplayName,
    fieldDisplayName,
    fieldName,
    objectCode,
    objectName,
    value,
    valueDisplay,
    ...rest
  } = data;

  const formatTime = (value: any) => {
    // 最特殊的就是value是日期需要转化成时间戳

    let _value: any;

    if (rest?.fieldType === FieldType.date) {
      _value = moment(Number(value));
    } else if (rest?.fieldType === FieldType.multiSelect) {
      _value = JSON.parse(valueDisplay);
    } else if (condition === 'NOT IN') {
      _value = JSON.parse(valueDisplay);
    } else {
      _value = valueDisplay
        ? {
            value,
            label: valueDisplay ?? value,
            key: value,
          }
        : value;
    }

    return _value;
  };

  const newFieldFilterInfo = {
    object: {
      key: `${objectCode}.${fieldName}`,
      value: JSON.stringify(data),
      label: `${objectName}.${fieldDisplayName}`,
    },
    symbol: {
      value: condition,
      label: conditionDisplayName,
      key: condition,
    },
    value: value ? formatTime(value) : undefined,
    valueDisplay: valueDisplay ?? undefined,
    ...rest,
  };

  return newFieldFilterInfo;
};

export const formatDetailToForm = (data: any, previewFieldInfoList: any[]) => {
  if (!data) {
    return null;
  }
  const { objectFilterConditionList, type, typeDisplayName, ...restData } = data;

  const formatObjectFilterConditionList = map(objectFilterConditionList, (node: any) => {
    const newNode = cloneDeep(node);

    if (!_Array.isEmpty(newNode?.nextFloorCondition)) {
      // 代表过滤组

      const { nextFloorCondition, ...restNode } = newNode;

      const newNextFloorCondition = map(nextFloorCondition, (node: any) => {
        return {
          fieldFilterInfo: formatFilterInfo(node, previewFieldInfoList),
          nextFloorCondition: [],
          nextType: '',
        };
      });

      return {
        ...restNode,
        nextFloorCondition: newNextFloorCondition,
        fieldFilterInfo: undefined, // 必须给undefined
      };
    }

    const { fieldFilterInfo, ...restNode } = newNode;

    const newFieldFilterInfo = formatFilterInfo(fieldFilterInfo, previewFieldInfoList);

    return {
      ...restNode,
      fieldFilterInfo: newFieldFilterInfo,
    };
  });

  const res = [
    {
      ...restData,
      nextFloorCondition: formatObjectFilterConditionList,
      nextType: type,
      nextTypeDisplayName: typeDisplayName,
    },
  ];

  return res;
};

export const formatDetailCondition = (data: any) => {
  if (!data) {
    return null;
  }
  const { objectFilterConditionList, type, typeDisplayName, ...restData } = data;

  const formatObjectFilterConditionList = map(objectFilterConditionList, (node: any) => {
    const newNode = cloneDeep(node);

    if (!_Array.isEmpty(newNode?.nextFloorCondition)) {
      // 代表过滤组

      const { nextFloorCondition, ...restNode } = newNode;

      const newNextFloorCondition = map(nextFloorCondition, (node: any) => {
        return {
          fieldFilterInfo: node,
          nextFloorCondition: [],
          nextType: '',
        };
      });

      return {
        ...restNode,
        nextFloorCondition: newNextFloorCondition,
        fieldFilterInfo: undefined, // 必须给undefined
      };
    }

    return newNode;
  });

  const res = [
    {
      ...restData,
      nextFloorCondition: formatObjectFilterConditionList,
      nextType: type,
      nextTypeDisplayName: typeDisplayName,
    },
  ];

  return res;
};

export const addDisable = (data: any) => {
  const dataFormat = cloneDeep(data);
  const newData = map(dataFormat, (node: any) => {
    const { fieldFilterInfo, nextFloorCondition } = node;

    if (fieldFilterInfo) {
      // 代表是当前行
      node.number = 1;
    } else {
      // 代表是条件组 number就是nextFloorCondition的长度
      node.number = nextFloorCondition?.length;
    }

    return node;
  });

  let num = 0;

  newData.forEach((e: any) => {
    num += e?.number;
  });

  const disable = num >= 20;

  return disable;
};

const formatFormFilterInfoForm = (data: any, previewFieldInfoList: any[]) => {
  const field = JSON.parse(data?.object?.value);

  const replaceList =
    previewFieldInfoList?.[
      findIndex(previewFieldInfoList, (e: any) => e.fieldId === field?.fieldId)
    ];

  const { object, ...rest } = data;

  const newFieldFilterInfo = {
    ...rest,
    object: {
      ...object,
      label: replaceList ? `${field?.objectName}.${replaceList?.fieldDisplayName}` : object?.label,
      value: replaceList ? JSON.stringify(replaceList) : object?.value,
    },
  };

  return newFieldFilterInfo;
};

export const resetFormToFormFilterCondition = (data: any) => {
  const { nextFloorCondition, templateDataFieldTable } = data;

  const formatNextFloorCondition = map(nextFloorCondition[0]?.nextFloorCondition, (node: any) => {
    const newNode = cloneDeep(node);

    if (!_Array.isEmpty(newNode?.nextFloorCondition)) {
      // 代表过滤组

      const { nextFloorCondition, ...restNode } = newNode;

      const newNextFloorCondition = map(nextFloorCondition, (node: any) => {
        return {
          fieldFilterInfo: formatFormFilterInfoForm(node?.fieldFilterInfo, templateDataFieldTable),
          nextFloorCondition: [],
          nextType: '',
        };
      });

      return {
        ...restNode,
        nextFloorCondition: newNextFloorCondition,
        fieldFilterInfo: undefined, // 必须给undefined
      };
    }

    const { fieldFilterInfo, ...restNode } = newNode;

    const newFieldFilterInfo = formatFormFilterInfoForm(fieldFilterInfo, templateDataFieldTable);

    return {
      ...restNode,
      fieldFilterInfo: newFieldFilterInfo,
    };
  });

  const newNextFloorCondition = [
    { ...nextFloorCondition[0], nextFloorCondition: formatNextFloorCondition },
  ];

  return newNextFloorCondition;
};
