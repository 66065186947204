/**
 * @description 触发条件，值类型选项组件
 */

import { Select, Form } from 'antd';
import _ from 'lodash';

import { appDict, appEnum } from 'src/dict';

import withFormItem from '../../share/ConditionEditTableLayout/withFormItem';

const { TriggerRule } = appEnum;
const { valueType } = appDict.triggerRule;

interface RuleValueTypeProps {
  dependenceValues: any[];
  disabled?: boolean;
  name: (string | number)[];
  field: any;
}

interface RuleValueTypeItemProps {
  dependenceValues: any[];
  disabled?: boolean;
}

const isAllowedEmpty = (conditionType: number) =>
  conditionType === TriggerRule.ConditionType.NOT_NULL ||
  conditionType === TriggerRule.ConditionType.NULL;

const RuleValueTypeItem: React.FC<RuleValueTypeItemProps> = ({
  dependenceValues,
  ...restProps
}) => {
  const [triggerType, conditionType] = dependenceValues;

  if (isAllowedEmpty(conditionType)) return <Select disabled={isAllowedEmpty(conditionType)} />;
  const options =
    triggerType === appEnum.TriggerRule.TriggerType.bizEventTrigger
      ? valueType
      : _.filter(valueType, ['value', TriggerRule.ValueType.value]);

  return <Select placeholder="请选择值类型" options={options} {...restProps} />;
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};

const RuleValueType: React.FC<RuleValueTypeProps> = ({ field, dependenceValues, ...restProps }) => {
  const [updateDataCheck, beforeConditionType, afterConditionType, ...restValue] =
    dependenceValues ?? [];
  const rules = [{ required: true, message: '值类型不能为空' }];

  return (
    <div style={style}>
      {!updateDataCheck ? null : (
        <Form.Item
          name={_.concat(field?.name, 'beforeValueType')}
          rules={isAllowedEmpty(beforeConditionType) ? [] : rules}
        >
          <RuleValueTypeItem
            {...restProps}
            dependenceValues={[...restValue, beforeConditionType]}
          />
        </Form.Item>
      )}
      <Form.Item
        name={_.concat(field?.name, 'afterValueType')}
        rules={isAllowedEmpty(afterConditionType) ? [] : rules}
      >
        <RuleValueTypeItem {...restProps} dependenceValues={[...restValue, afterConditionType]} />
      </Form.Item>
    </div>
  );
};

export default withFormItem(RuleValueType);
