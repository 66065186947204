import React, { useRef, useState } from 'react';
import { BlSortFormList, BlTooltip } from 'src/components';
import { Input, Select, Form, FormInstance, InputNumber, Switch, message } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import _Array from 'src/utils/dataTypes/array';
import { Conversions } from '..';
import { RuleObject } from 'antd/lib/form';
import { FetchMaterialCreateUnitRequest } from 'src/api/ytt/material-domain/Web_MaterialBaseInfo';
import { LabeledValue } from 'antd/lib/select';
import SearchSelect from 'src/components/searchSelect';
import { UsageStatus } from 'src/dict/common';
import { UnitType } from 'src/dict/material';
import lookup, { appDict, appEnum } from 'src/dict';
import _ from 'lodash';
import { valueOrHolder } from 'src/utils/formatters';
import { numberMinMaxCheck, positiveInt, validatorNumberDigtis } from 'src/utils/formValidators';
import { MaterialCreateUnitForm } from '../createAndEdit/index.d';
import { center } from '@antv/x6/lib/registry/node-anchor/bbox';
import { ICustomFields, injectCustomFieldColumns } from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

interface Props {
  form: FormInstance;
  name: string;
  customFields?: ICustomFields;
}

const UnitsFormTable = (props: Props) => {
  const { form, name, customFields } = props;
  const [, setDataSource] = useState<Partial<FetchMaterialCreateUnitRequest>[]>([]);

  const formatDetailDataToForm = (values: Conversions[]) => {
    const elementValue = values.map((item) => {
      return {
        convertType: item?.convertType,
        toUnitCount: item?.toUnitCount,
        toUnitId: item?.toUnitId,
        fromUnitCount: item?.fromUnitCount,
        fromUnitId: item?.fromUnitId,
        id: item?.id,
        hasStorage: item?.hasStorage,
        relationId: item?.relationId,
      };
    });

    setDataSource(elementValue);

    return elementValue;
  };

  const lastSavedUnitsRef = useRef<MaterialCreateUnitForm[]>([]);

  /**
   * 获取初始值，
   * @param field
   * @param fieldStr
   * @param defaultValue
   * @returns
   */
  const getDefaultValue = (field: FormListFieldData, fieldStr: string, defaultValue: any) => {
    const hasValue = !_.isNil(form.getFieldValue([name, field.name, fieldStr]));

    if (hasValue) return undefined;

    return defaultValue;
  };

  // 转换单位 校验重复
  const validateRepeat = (filedStr: any, formm: FormInstance) => {
    return (_rule: RuleObject, value: number | LabeledValue) => {
      return new Promise((resolve, reject) => {
        if (!value) {
          return resolve(true);
        }
        // ①
        const valueList: FetchMaterialCreateUnitRequest[] = formm.getFieldValue(name);

        // ②从 valueList 找出 目标项 组成 []
        const list: number[] =
          valueList?.map((el: any) => {
            // 如果是 labelInValue 类型
            if (el && typeof el[filedStr] === 'object') {
              return el[filedStr].value;
            }
            return el && el[filedStr];
          }) ?? [];

        const repeatList: number[] = _Array.findDuplicates(list);

        // ③value 如果存在 在重复项 [] 中，则 reject
        if (repeatList.indexOf(Number(value)) !== -1) {
          return reject('已存在该选项！');
        }
        // 如果是 labelInValue 类型
        if (typeof value === 'object' && repeatList.indexOf(Number(value.value)) !== -1) {
          return reject('已存在该选项！');
        }

        return resolve(true);
      });
    };
  };

  /**
   * 获取单位行基础信息
   * @param field
   */
  const getUnitRowBaseInfo = (field: FormListFieldData) => {
    return {
      /** 是否存在库存 */
      hasStorage: form.getFieldValue([name, field.name, 'hasStorage']),
      /** 是否是辅助单位 */
      isAuxUnit: form.getFieldValue([name, field.name, 'convertType']) === UnitType.auxUnit,
      /** 是否是主单位 */
      isMainUnit: form.getFieldValue([name, field.name, 'convertType']) === UnitType.mainUnit,
      /** 是否是上次已保存的单位 */
      isSaved: !_.isNil(form.getFieldValue([name, field.name, 'id'])),
    };
  };

  /**
   * 从已保存的启用中的转换单位选择主单位
   * @param value
   */
  const handleMainUnitChangeFromSavedList = (value: LabeledValue) => {
    const formList = _.cloneDeep(form.getFieldValue(name));

    const changeIndex = _.findIndex(_.map(lastSavedUnitsRef.current, 'toUnitId'), [
      'value',
      value.value,
    ]);

    if (changeIndex >= 0) {
      formList[changeIndex].toUnitId = _.head(lastSavedUnitsRef.current)?.toUnitId;
      formList[0].toUnitId = lastSavedUnitsRef.current[changeIndex].toUnitId;

      _.forEach(formList, (item) => {
        item.fromUnitId = value;
      });

      form.setFields([{ name, value: formList }]);
      value && message.warn('变更主单位后，请检查对应转换率是否调整！');
    }
  };

  /**
   * 主单位修改后同步值
   * @param value
   */
  const handleMainUnitChange = (value?: LabeledValue) => {
    const formList = _.cloneDeep(form.getFieldValue(name));

    _.forEach(formList, (item) => {
      item.fromUnitId = value;
    });

    form.setFields([
      { name, value: formList },
      { name: 'transferUnitId', value: value?.key },
    ]);
    value && message.warn('变更主单位后，请检查对应转换率是否调整！');
  };

  /**
   * 单位类型修改  1.辅助单位时重置转换率
   * @param value
   * @param field
   */
  const handleUnitTypeChange = (value: any, field: FormListFieldData) => {
    const formList = _.cloneDeep(form.getFieldValue(name));

    if (value === UnitType.auxUnit) {
      // 除当前操作的行外，是否存在启用中的辅助单位
      const hasEnabledAuxUnit = !_.isEmpty(
        _.filter(formList, ({ enableFlag, convertType }, index: number) => {
          return enableFlag && convertType === UnitType.auxUnit && field.name !== index;
        }),
      );

      formList[field.name].enableFlag = hasEnabledAuxUnit
        ? appEnum.Common.UsageStatus.disabled
        : appEnum.Common.UsageStatus.enabled;
      formList[field.name].toUnitCount = undefined;
      formList[field.name].fromUnitCount = undefined;
    }

    form.setFields([{ name, value: formList }]);
  };

  /**
   * 状态修改  1.辅助单位只能有一个为启用状态
   * @param value
   * @param field
   */
  const handleStatusChange = (value: any, field: FormListFieldData) => {
    const formList = _.cloneDeep(form.getFieldValue(name));

    if (formList[field.name].convertType === UnitType.auxUnit && value) {
      // 检查是否存在已启用的辅助单位，保证辅助单位只能有一个

      if (
        _.filter(
          formList,
          ({ convertType, enableFlag }, index: number) =>
            index !== field.name && convertType === UnitType.auxUnit && enableFlag,
        ).length >= 1
      ) {
        formList[field.name].enableFlag = appEnum.Common.UsageStatus.disabled;
        form.setFields([{ name, value: formList }]);
        message.warn('仅能存在一个启用中的辅助单位！');
      }
    }
  };

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '单位类型',
        render: (text: number, field, index: number) => {
          const { isSaved } = getUnitRowBaseInfo(field);

          return (
            <div key={field.key}>
              <Form.Item
                fieldKey={[field.fieldKey, 'convertType']}
                name={[field.name, 'convertType']}
                validateTrigger={['onChange', 'onBlur']}
                initialValue={getDefaultValue(
                  field,
                  'convertType',
                  index === 0 ? UnitType.mainUnit : UnitType.conversionUnit,
                )}
                style={{ marginBottom: '0' }}
              >
                {index === 0 ? (
                  valueOrHolder(
                    lookup(
                      'material.unitType',
                      form.getFieldValue([name, field.name, 'convertType']),
                    ),
                  )
                ) : (
                  <Select
                    disabled={isSaved}
                    options={_.filter(
                      appDict.material.unitType,
                      ({ value }) => value !== UnitType.mainUnit,
                    )}
                    onChange={(value) => handleUnitTypeChange(value, field)}
                  />
                )}
              </Form.Item>
            </div>
          );
        },
      },
      {
        title: 'X',
        render: (text: number, field) => {
          const { hasStorage, isMainUnit, isAuxUnit } = getUnitRowBaseInfo(field);

          return (
            <Form.Item
              dependencies={[[name, field.name, 'convertType']]}
              style={{ marginBottom: '0' }}
            >
              {() => (
                <Form.Item
                  fieldKey={[field.fieldKey, 'toUnitCount']}
                  name={[field.name, 'toUnitCount']}
                  // validateTrigger={['onChange', 'onBlur']}
                  initialValue={getDefaultValue(field, 'toUnitCount', isMainUnit ? 1 : undefined)}
                  style={{ marginBottom: '0' }}
                  rules={_.compact([
                    {
                      required: !isAuxUnit,
                      message: '必填',
                    },
                    { max: 1000000000, type: 'number', message: '最大不能超过1000000000' },
                    !isAuxUnit && positiveInt,
                  ])}
                >
                  {isMainUnit ? (
                    valueOrHolder(form.getFieldValue([name, field.name, 'toUnitCount']))
                  ) : (
                    <InputNumber
                      style={{ width: '100%' }}
                      disabled={isAuxUnit || hasStorage}
                      placeholder={isAuxUnit ? '' : '请输入'}
                    />
                  )}
                </Form.Item>
              )}
            </Form.Item>
          );
        },
      },
      {
        title: '单位',
        render: (text: number, field) => {
          const { isMainUnit, isSaved } = getUnitRowBaseInfo(field);

          // 上次已保存的单位
          const savedUnits = _.filter(form.getFieldValue(name), ({ id }) => id);

          // 上次已保存的，启用且不是辅助单位的列表
          const savedConvertUnits = _.filter(
            savedUnits,
            ({ enableFlag, convertType }) => enableFlag && convertType !== UnitType.auxUnit,
          );

          lastSavedUnitsRef.current = savedUnits;

          return (
            <div key={field.key}>
              <Form.Item
                fieldKey={[field.fieldKey, 'toUnitId']}
                name={[field.name, 'toUnitId']}
                validateTrigger={['onChange', 'onBlur']}
                style={{ marginBottom: '0' }}
                rules={_.compact([
                  { required: true, message: '单位必选' },
                  { validator: validateRepeat('toUnitId', form) },
                ])}
                validateFirst
              >
                {/* 如果存在已保存的单位列表，则主单位只能从 已保存的单位列表中选择 */}
                {isMainUnit && !_.isEmpty(savedConvertUnits) ? (
                  <Select
                    labelInValue
                    placeholder={'请选择'}
                    options={_.map(savedConvertUnits, 'toUnitId')}
                    onChange={handleMainUnitChangeFromSavedList}
                    showSearch
                    filterOption={(input, option) =>
                      option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                ) : (
                  <SearchSelect
                    labelInValue
                    disabled={isSaved}
                    fetchType={'unit'}
                    placeholder={'请选择'}
                    params={{ enableFlag: UsageStatus.enabled }}
                    onChange={(value: LabeledValue) => isMainUnit && handleMainUnitChange(value)}
                  />
                )}
              </Form.Item>
            </div>
          );
        },
      },
      {
        align: 'center',
        width: 60,
        render: (text: string, field: FormListFieldData) => {
          return <div key={field.key}>=</div>;
        },
      },
      {
        title: 'Y',
        render: (text: number, field) => {
          const { hasStorage, isMainUnit, isAuxUnit } = getUnitRowBaseInfo(field);

          return (
            <Form.Item dependencies={[[name, field.name, 'convertType']]} noStyle>
              {() => (
                <Form.Item
                  fieldKey={[field.fieldKey, 'fromUnitCount']}
                  name={[field.name, 'fromUnitCount']}
                  validateTrigger={['onChange', 'onBlur']}
                  initialValue={getDefaultValue(field, 'fromUnitCount', isMainUnit ? 1 : undefined)}
                  style={{ marginBottom: '0' }}
                  rules={_.compact([
                    {
                      required: !isAuxUnit,
                      message: '必填',
                    },
                    { max: 1000000000, type: 'number', message: '最大不能超过1000000000' },
                    !isAuxUnit && positiveInt,
                  ])}
                >
                  {isMainUnit ? (
                    valueOrHolder(form.getFieldValue([name, field.name, 'toUnitCount']))
                  ) : (
                    <InputNumber
                      style={{ width: '100%' }}
                      disabled={isAuxUnit || hasStorage}
                      placeholder={isAuxUnit ? '' : '请输入'}
                    />
                  )}
                </Form.Item>
              )}
            </Form.Item>
          );
        },
      },
      {
        title: '主单位',
        render: (text: string, field) => (
          <Form.Item noStyle dependencies={[[name, 0, 'toUnitId']]}>
            {() => {
              const mainUnit = form.getFieldValue([name, 0, 'toUnitId']);

              return (
                <Form.Item
                  fieldKey={[field.fieldKey, 'fromUnitId']}
                  name={[field.name, 'fromUnitId']}
                  validateTrigger={['onChange', 'onBlur']}
                  style={{ marginBottom: '0' }}
                  initialValue={getDefaultValue(field, 'fromUnitId', mainUnit)}
                >
                  <BlTooltip text={valueOrHolder(mainUnit?.label)} />
                </Form.Item>
              );
            }}
          </Form.Item>
        ),
      },
      {
        title: '是否启用',
        width: 150,
        render: (text: string, field) => {
          const { isMainUnit } = getUnitRowBaseInfo(field);

          return (
            <Form.Item
              fieldKey={[field.fieldKey, 'enableFlag']}
              name={[field.name, 'enableFlag']}
              validateTrigger={['onChange', 'onBlur']}
              valuePropName="checked"
              initialValue={getDefaultValue(
                field,
                'enableFlag',
                appEnum.Common.UsageStatus.enabled,
              )}
              style={{ marginBottom: '0' }}
            >
              <Switch
                disabled={isMainUnit}
                onChange={(value) => handleStatusChange(value, field)}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '',
        width: 1,
        render: (text: string, field) => {
          return (
            <Form.Item fieldKey={[field.fieldKey, 'id']} name={[field.name, 'id']} hidden>
              <Input />
            </Form.Item>
          );
        },
      },
      {
        title: '',
        width: 1,
        render: (text: string, field) => {
          return (
            <Form.Item
              fieldKey={[field.fieldKey, 'relationId']}
              name={[field.name, 'relationId']}
              hidden
            >
              <Input />
            </Form.Item>
          );
        },
      },
    ] as ColumnProps<any & FormListFieldData>[];
  };

  return (
    <BlSortFormList
      style={{ width: '95%' }}
      name={name}
      form={form}
      renderColumns={() =>
        injectCustomFieldColumns({
          columns: getColumns(), // 原本的列
          customFields, // 自定义字段信息
          objectCode: OBJECT_OF_CODE.MaterialConversionUnit, // 从对象code
          type: 'form', // 使用类型
          formConfig: { form, formName: name },
        })
      }
      // isNeedDelete={false}
      isNeedDrag={false}
      initLineCount={1}
      fixedRowFn={(field) => {
        // 1.【已保存的数据】不能被删除  2.【主单位】不能被删除
        const { isSaved, isMainUnit } = getUnitRowBaseInfo(field);

        return isSaved || isMainUnit;
      }}
    />
  );
};

export default UnitsFormTable;
