import _ from 'lodash';
import { message } from 'antd';
import { fetchWorkCenterExternalDetail } from 'src/api/ytt/work-center-domain';
import { fetchProcessRouteUpdateStatus } from 'src/api/ytt/med-domain/process-route';
import { formatDataToInitEdit } from 'src/page/knowledgeManagement/basicData/workCenter/shared/attrFormTable';
import { showErrorListMessage } from 'src/components';
import { wcgCOlistToApi, wcgCOlistToForm } from '../../basicData/workCenter/resourceGroup/util';
import { PageModeEnum } from './constant';
import { YN } from 'src/dict/common';
import { FeedingType } from 'src/dict/material';

// 获取资源清单信息
export const getWorkCenterGroupfieldValue = async (id?: number) => {
  if (!id) {
    return null;
  }
  const res: any = await fetchWorkCenterExternalDetail({ id });
  const workCenterGroupCOList: { [key: string]: number }[] = res?.data?.workCenterGroupCOList || [];

  const formatworkCenterGroupCOList = wcgCOlistToForm(workCenterGroupCOList);

  return formatworkCenterGroupCOList;
};

// 列表批量操作请求方法
const patchRequest = (
  patchApi: any,
  params: any,
  display: string,
  displayMessage?: string,
  onSuccess?: any,
  onFail?: any,
) => {
  return patchApi(params)
    .then((res: any) => {
      const { code, message: mes } = res;

      if (code === 200) {
        onSuccess && onSuccess();
        const { failCount = 0, successCount = 0, failDetails = [] } = res?.data ?? {};

        if (failCount) {
          showErrorListMessage({
            failCount,
            successCount,
            title: `${display}出现失败`,
            data: _.map(failDetails, (item) => ({ ...item, key: item.id })),
            columns: [
              { title: '工艺路线编号', dataIndex: 'code', width: 200 },
              { title: '工艺路线名称', dataIndex: 'name', width: 200 },
              { title: '失败原因', dataIndex: 'reason', width: 200 },
              { title: '', dataIndex: 'key', width: 1, render: () => null },
            ],
          });
        } else {
          message.success(displayMessage);
        }
        return;
      }
      message.error(mes);
      return undefined;
    })
    .catch((err: any) => {
      console.log('err: ', err);
      onFail && onFail();
    });
};

export const updateStatus = async (id: number, status: number, display: string, cb: () => void) => {
  const res = await fetchProcessRouteUpdateStatus({
    status,
    idList: [id],
  });

  if (res.code === 200) {
    const { failCount, failDetails } = res.data || {};

    if (!failCount) {
      message.success(display);
      cb?.();
    } else {
      message.error(_.first(failDetails)?.reason);
    }
  }
};

// 处理工序节点数据(填充表单)
export const formatterProcess = (data?: any) => {
  if (!data) {
    return [];
  }

  return data.map((item: any) => {
    const formatworkCenterGroupCOList = wcgCOlistToForm(item?.workCenterGroupCOList);

    return {
      ...item,
      ...(item.processCode
        ? {
            processCode: {
              label: item.processCode,
              value: JSON.stringify({
                id: item.processId,
                name: item.processName,
                code: item.processCode,
              }),
              key: item.processCode,
            },
          }
        : {}),
      ...(item?.sop
        ? {
            sop: {
              label: item?.sop?.name,
              value: item?.sop?.id,
            },
          }
        : {}),
      reportIds: _.map(item?.reportTemplates, (o) => ({
        label: o?.name,
        value: o?.id,
      })),
      workCenterGroupCOList: formatworkCenterGroupCOList,
      workCenterId: {
        label: item.workCenterName,
        value: item?.workCenterId,
      },
    };
  });
};
// 处理工序接续关系数据(填充表单)
export const formatterProcessConnections = (data?: any, isRouting?: Boolean) => {
  if (!data) {
    return [];
  }
  return data.map((item: any) => {
    const { processManufacturedGoods = {} } = item;
    const { material, unit = {}, feedUnit = {} } = processManufacturedGoods;

    return {
      ...item,
      processManufacturedGoods: {
        ...processManufacturedGoods,
        ...(material
          ? {
              materialName: material?.baseInfo?.name,
              materialCode: {
                label: material?.baseInfo?.code,
                value: JSON.stringify(material),
              },
            }
          : {}),
        unitId: {
          label: unit?.name,
          value: unit?.id,
        },
        feedUnitId: {
          label: feedUnit?.name,
          value: feedUnit?.id,
        },
        materialFeedControl: {
          label: processManufacturedGoods.materialFeedControlCode,
          value: isRouting
            ? processManufacturedGoods.materialFeedControlCode
            : processManufacturedGoods.materialFeedControlId,
        },
        workReportControl: {
          label: processManufacturedGoods.workReportControlCode,
          value: isRouting
            ? processManufacturedGoods.workReportControlCode
            : processManufacturedGoods.workReportControlId,
        },
      },
    };
  });
};

// 处理工序节点数据(传给服务器)
export const formatterProcessApi = (processes: any[]) => {
  return processes?.map((item: any) => ({
    ...item,
    processCode: JSON.parse(item?.processCode?.value ?? '{}')?.code,
    sop: item?.sop?.value,
    reportIds: _.map(item?.reportIds, 'value'),
    workCenterId: item?.workCenterId?.value,
    workCenterGroupCOList: wcgCOlistToApi(item?.workCenterGroupCOList),
  }));
};

// 处理工序接续数据(传给服务器)
export const formatterProcessConnApi = (processConnections: any[], isRouting?: Boolean) => {
  return processConnections?.map((item: any) => {
    return {
      ...item,
      processManufacturedGoods: {
        ..._.omit(item.processManufacturedGoods, ['materialCode']),
        unitId: item.processManufacturedGoods?.unitId?.value,
        feedUnitId: item.processManufacturedGoods?.feedUnitId?.value,
        [isRouting ? 'materialFeedControlCode' : 'materialFeedControlId']:
          item.processManufacturedGoods?.materialFeedControl?.value,
        [isRouting ? 'workReportControlCode' : 'workReportControlId']:
          item.processManufacturedGoods?.workReportControl?.value,
      },
    };
  });
};

export const getDefaultProcessManufacturedGoods = () => {
  return {
    amount: 1,
    warehousing: YN.yes,
    feedType: FeedingType.feeding,
    feeding: YN.yes,
    limit: 1,
    standardAmount: 1,
    reportFlag: YN.yes,
    reportingMethods: [1, 2, 3, 4],
  };
};

export const getPageMode = (pathname: string) => {
  const lastPath = pathname.split('/').pop();

  if (lastPath === 'create') {
    return {
      title: '创建',
      mode: PageModeEnum.create,
    };
  } else if (lastPath === 'edit') {
    return {
      title: '编辑',
      mode: PageModeEnum.edit,
    };
  } else if (lastPath === 'copy') {
    return {
      title: '复制',
      mode: PageModeEnum.copy,
    };
  } else {
    return {};
  }
};

export { formatDataToInitEdit, patchRequest };
