import { createModel } from '@rematch/core';
import { RootModel } from 'src/store/models/index';
import { StoreRequisitionListItemProps } from 'src/page/productionPlanning/storeRequisition/constants';

export const transferMaterials = createModel<RootModel>()({
  state: [] as StoreRequisitionListItemProps[],
  reducers: {
    setTansferMaterials(
      state: StoreRequisitionListItemProps[],
      transferMaterials: StoreRequisitionListItemProps[],
    ) {
      return transferMaterials;
    },
  },
});
