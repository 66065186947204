export const MAX_INPUT_LENGTH = 255;
export const MAX_TEXTAREA_LENGTH = 1000;

/**
 * 维保方案 谁可转派
 */
export const PlanAssignFrom = {
  /** 计划执行人 */
  planExecutor: 1,
  /** 当前执行人 */
  currentExecutor: 2,
};

export const PlanAssignFromMap = new Map([
  [PlanAssignFrom.currentExecutor, { label: '当前执行人', value: PlanAssignFrom.currentExecutor }],
  [PlanAssignFrom.planExecutor, { label: '计划执行人', value: PlanAssignFrom.planExecutor }],
]);

/**
 * 维保方案 可转派给
 */
export const PlanAssignTo = {
  /** 计划执行人 */
  planExecutor: 1,
  /** 当前登录用户所属下级用户 */
  subordinate: 2,
  /** 当前登录用户所属部门用户 */
  sameDeptUser: 4,
};

export const PlanAssignToMap = new Map([
  [PlanAssignTo.planExecutor, { label: '计划执行人', value: PlanAssignTo.planExecutor }],
  [
    PlanAssignTo.subordinate,
    { label: '当前登录用户所属下级用户', value: PlanAssignTo.subordinate },
  ],
  [
    PlanAssignTo.sameDeptUser,
    { label: '当前登录用户所属部门用户', value: PlanAssignTo.sameDeptUser },
  ],
]);
