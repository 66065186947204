import React from 'react';
import { Select, Radio, DatePicker, Form, TimePicker, Checkbox } from 'antd';
import { appDict, appEnum } from 'src/dict';
import { FieldBaseProps } from 'src/page/custom_fields/fieldsList/index.d';

const message = '必填项';
const { Option } = Select;
const OPTIONS = [
  'YYYY-MM-DD',
  'YYYY/MM/DD',
  'YYYYMMDD',
  'YYYY-MM-DD HH:mm:ss',
  'YYYY/MM/DD HH:mm:ss',
  'YYYYMMDDHHmmss',
  'YYYY-MM-DD HH:mm',
  'YYYY/MM/DD HH:mm',
  'YYYYMMDDHHmm',
  'HH:mm:ss',
  'HHmmss',
];
const FORMAT = 'YYYY/MM/DD HH:mm:ss';

export const dateItems = ({ isPreField, form, isEdit }: FieldBaseProps) => {
  return [
    {
      label: '日期格式',
      name: 'datetimeFormat',
      initialValue: FORMAT,
      rules: [{ required: true, message }],
      render: () => (
        <Select disabled={isEdit}>
          {OPTIONS.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      label: '必填',
      name: 'isRequired',
      rules: [{ required: true, message }],
      initialValue: appEnum.Common.YN.no,
      render: () => (
        <Radio.Group
          disabled={isPreField && form.getFieldValue('isRequired') === appEnum.Common.YN.yes}
          options={appDict.common.yn}
        />
      ),
    },
    {
      label: '默认值',
      dependencies: [['datetimeFormat'], ['isCurrentTime']],
      render: () => {
        return () => {
          const isHMS =
            form.getFieldValue('datetimeFormat') === 'HH:mm:ss' ||
            form.getFieldValue('datetimeFormat') === 'HHmmss';

          const isCurrentTime = form.getFieldValue('isCurrentTime');

          if (isCurrentTime) {
            form.setFieldsValue({ defaultValue: undefined });
          }

          return (
            <>
              {!isHMS && (
                <Form.Item style={{ marginBottom: 0 }} name="defaultValue">
                  <DatePicker
                    disabled={isPreField || isCurrentTime}
                    showTime
                    style={{ width: 300 }}
                    format={form.getFieldValue('datetimeFormat')}
                    placeholder="请选择"
                  />
                </Form.Item>
              )}
              {isHMS && (
                <Form.Item style={{ marginBottom: 0 }} name="defaultValue">
                  <TimePicker
                    disabled={isPreField || isCurrentTime}
                    style={{ width: 300 }}
                    format={form.getFieldValue('datetimeFormat')}
                    placeholder="请选择"
                  />
                </Form.Item>
              )}
              <Form.Item
                valuePropName="checked"
                name="isCurrentTime"
                getValueFromEvent={(e: any) => {
                  return e.target.checked ? appEnum.Common.YN.yes : appEnum.Common.YN.no;
                }}
              >
                <Checkbox>自动填写操作时间</Checkbox>
              </Form.Item>
            </>
          );
        };
      },
    },
  ];
};
