import React, { useState } from 'react';
import { Form, Checkbox, message as Message } from 'antd';
import { formatStoreValue, throttleFn } from '../utils';
import { QUALITY } from '../constant';
import { RouteComponentProps } from 'react-router-dom';
import { toStoreList } from '../navigation';
import StoreBaseInfo from '../components/storeCreateAndEditBaseInfo';
import { fetchStorageWarehouseCreate } from 'src/api/ytt/storage-domain/warehouse';

interface createProps extends RouteComponentProps {}

const CreateStoreHouse = (props: createProps) => {
  const { history } = props;
  const [modalForm] = Form.useForm();

  const [continuousCreate, setContinuousCreate] = useState(false);
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新页面
  const [qcCtrl, setQcCtrl] = useState(false);

  const setQcCtrlFn = (e: boolean) => {
    setQcCtrl(e);
  };

  const extra = (
    <Checkbox
      onChange={(e) => {
        setContinuousCreate(e?.target?.checked);
      }}
    >
      连续新建
    </Checkbox>
  );

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();

      const newValue = formatStoreValue(value);

      const { code, message } = await fetchStorageWarehouseCreate(newValue);

      if (code === 200) {
        Message.success('创建成功');
        if (continuousCreate) {
          setQcCtrl(false);
          modalForm.resetFields();
          modalForm?.setFieldsValue({
            qcCtrlList: QUALITY.map((node) => node?.id),
            code_isUseRules: true,
          });
          setRefreshMarker(Date.now());
          return;
        }
        history.push(toStoreList());
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StoreBaseInfo
      form={modalForm}
      title="新建仓库"
      onCancel={() => {
        history.push(toStoreList());
      }}
      onFinish={throttleFn(handleFinish)}
      extra={extra}
      edit={false}
      qcCtrl={qcCtrl}
      setQcCtrlFn={setQcCtrlFn}
      checkResult
      refreshMarker={refreshMarker}
    />
  );
};

export default CreateStoreHouse;
