import organizationRoute from 'src/page/organization';
import customFieldsRoute from 'src/page/custom_fields';
import toolsRoute from 'src/page/custom_fields/tools';
import { RouteProp } from './index.d';
import { recursionRouteAddExImport } from 'src/utils/formatters/route';

const backendRoutes: Array<RouteProp> = [
  {
    path: '*manage',
    menu: '管理',
    isCategory: true,
    breadcrumb: '管理',
  },
  ...organizationRoute,
  {
    path: '*tools',
    menu: '工具',
    isCategory: true,
    breadcrumb: '工具',
  },
  ...toolsRoute,
  {
    path: '*setting',
    menu: '设置',
    isCategory: true,
    breadcrumb: '设置',
  },
  ...customFieldsRoute,
];

recursionRouteAddExImport(backendRoutes);

export default backendRoutes;
