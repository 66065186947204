import { useEffect, useState } from 'react';
import _ from 'lodash';

import lookup from 'src/dict';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { AssociateFieldRecordType, StepRecord } from '../../type';
import { BlTable } from 'src/components';
import { renderUser } from 'src/page/share/renderUser';
import { User } from 'src/page/share';
import ControlValue from '../../components/controlValue';
import { fetchSopExecTaskControlValueEditRecords } from 'src/api/ytt/sop-domain';

import styles from '../../styles.module.scss';
import { formatTimeForRender } from 'src/utils/formatters';

interface TableProps {
  controlRecordId: number;
}
interface PaginationProp {
  page: number | undefined;
  size: number | undefined;
  [x: string]: any;
}

const defaultPagination = {
  page: 1,
  size: 10,
};

const OperationRecordTable = (props: TableProps) => {
  const { controlRecordId } = props;

  const [dataSource, setdataSource] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationProp>(defaultPagination);

  /**
   * fetch 接口
   */
  const handleFetch = async (param) => {
    setLoading(true);
    try {
      const { data } = await fetchSopExecTaskControlValueEditRecords({
        controlRecordId,
        ...param,
      });

      setdataSource(data?.list);
      setPagination({
        ...pagination,
        ...{
          total: data?.total,
        },
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  /**
   * columns 格式
   */
  const dataColumns = [
    {
      title: '编辑人',
      dataIndex: 'editor',
      width: 150,
      render: renderUser,
    },
    {
      title: '编辑时间',
      dataIndex: 'editTime',
      width: 150,
      sorter: true,
      render: formatTimeForRender,
    },
    {
      title: '操作类型',
      dataIndex: 'operateType',
      width: 150,
      render: (operateType: number) => {
        return lookup('sop.controlOperationRecord', operateType);
      },
    },
    {
      title: '修改前',
      dataIndex: 'prevValue',
      width: 150,
      render: (
        prevValue: string | number[] | User[] | AssociateFieldRecordType,
        record: StepRecord,
      ) => {
        const controlType = _.get(record, 'controlType');

        return (
          <div className={styles.controlValueWrap}>
            <ControlValue controlType={controlType} controlValue={prevValue} />
          </div>
        );
      },
    },
    {
      title: '修改后',
      dataIndex: 'editedValue',
      width: 150,
      render: (
        prevValue: string | number[] | User[] | AssociateFieldRecordType,
        record: StepRecord,
      ) => {
        const controlType = _.get(record, 'controlType');

        return (
          <div className={styles.controlValueWrap}>
            <ControlValue controlType={controlType} controlValue={prevValue} />
          </div>
        );
      },
    },
  ];

  /**
   * 初始化
   */
  useEffect(() => {
    handleFetch({
      ...defaultPagination,
    });
  }, []);

  return (
    <>
      <BlTable
        bordered
        useIndex
        columns={dataColumns}
        dataSource={dataSource}
        pagination={{
          total: pagination.total,
          showTotal: (total) => `共 ${total} 条`,
          showSizeChanger: true,
        }}
        rowKey={(record) => record?.id}
        style={{ width: '100%' }}
        scroll={{ x: true, y: 400 }}
        loading={loading}
        onChange={(
          pagination: TablePaginationConfig,
          filters: Record<string, FilterValue>,
          sorter: SorterResult<any>,
        ) => {
          const { current, pageSize } = pagination;

          setPagination({
            ...pagination,
            ...{
              page: current,
              size: pageSize,
            },
          });

          const param = {
            page: current,
            size: pageSize,
          };

          if (sorter?.field) {
            _.set(param, 'sorter', [
              { field: sorter?.field, order: sorter?.order === 'ascend' ? 'asc' : 'desc' },
            ]);
          }
          handleFetch(param);
        }}
      />
    </>
  );
};

export default OperationRecordTable;
