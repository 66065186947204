import { createModel } from '@rematch/core';
import _ from 'lodash';
import { RootModel } from 'src/store/models/index';
import { KNOWN_EMPTY_LIST_PARAM } from '@blacklake-web/layout';

export interface ExcelImportStateType {
  visible: boolean;
  optType: string;
  businessData: any;
  businessType: string;
}

const initialState: ExcelImportStateType = {
  visible: false,
  optType: '',
  businessData: {},
  businessType: '',
};

export const excelImport = createModel<RootModel>()({
  state: initialState,
  reducers: {
    /** 打开导入导出弹窗 */
    openExImportModal(state, { optType, businessType }) {
      return {
        ...state,
        visible: true,
        optType,
        businessType,
      };
    },
    /** 关闭导入导出弹窗 */
    closeExImportModal(state) {
      return {
        ...state,
        visible: false,
        optType: '',
        businessType: '',
      };
    },
    /** 更新导出条件 */
    updateBusinessData(state, { businessData }) {
      const _businessData = _.cloneDeep(businessData);

      _.forIn(_businessData, (value: any, key: string) => {
        if (value === KNOWN_EMPTY_LIST_PARAM) {
          _businessData[key] = [-1];
        }
      });

      delete _businessData.page;
      delete _businessData.size;
      return {
        ...state,
        businessData: _businessData,
      };
    },
  },
});
