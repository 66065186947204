import { BlIcon, TextToolTip } from '@blacklake-web/component';
import { Button } from 'antd';
import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  fetchExportExcelTaskCustomObjectExportProgress,
  fetchExportExcelTaskExportProgress,
} from 'src/api/ytt/import';
import { AvatarDisplay } from 'src/components/avatar/show';
import lookup from 'src/dict';
import { RecordListLayout } from 'src/layout';
import { downloadFileByID } from '../utiles';
import { qs } from 'utils';

type TableRowType = {
  fileName: string;
  fileId?: number;
  avatarUrl?: string;
  userName: string;
  userId?: number;
  updatedAt: number;
  status: number;
  resultMsg: string;
};

const ExportLog: React.FC = () => {
  const { search } = useLocation();
  const { applicationName, businessType, isCustomObject } = qs.parse(search.slice(1));

  const dataColumns = [
    {
      title: '文件名',
      dataIndex: 'fileName',
      width: 200,
      render: (text: string, record: TableRowType) => {
        const { fileId, status } = record;
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TextToolTip text={text} width={180} />
            {status === 1 && (
              <Button
                icon={<BlIcon type="iconxiazai" />}
                onClick={() => downloadFileByID(fileId)}
              />
            )}
          </div>
        );
      },
    },
    {
      title: '操作人',
      dataIndex: 'userName',
      width: 120,
      render: (userName: any, record: TableRowType) => {
        const { avatarUrl } = record;

        return (
          <AvatarDisplay
            name={userName}
            avatarUrl={avatarUrl}
            id={record?.userId || ''}
            key={record?.userId}
            isShowTag
            isUser
          />
        );
      },
    },
    {
      title: '操作时间',
      dataIndex: 'updatedAt',
      width: 160,
      render: (text: number) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''),
    },
    {
      title: '操作结果',
      dataIndex: 'status',
      width: 120,
      render: (text: number) => lookup('objectImport.exportStatus', text),
    },
    {
      title: '操作详情',
      dataIndex: 'resultMsg',
      width: 200,
    },
  ];

  /**
   * 处理查询条件
   * @param params 原查询条件 默认的字段有 quickSearch(快速查询内容), page(当前页), size(分页大小), sorter(排序)
   */
  const formatDataToQuery = (params: any) => {
    delete params.sorter;
    delete params.quickSearch;
    params.applicationName = applicationName;
    params.businessType = businessType;
    params.sorter = [
      {
        field: 'updatedAt',
        order: 'desc',
      },
    ];

    return params;
  };

  return (
    <RecordListLayout<TableRowType>
      columns={dataColumns}
      useQuickFilter={false}
      formatDataToQuery={formatDataToQuery}
      requestFn={
        isCustomObject
          ? fetchExportExcelTaskCustomObjectExportProgress
          : fetchExportExcelTaskExportProgress
      }
      style={{ paddingTop: 24 }}
    />
  );
};

export default ExportLog;
