import { DataFormLayout, DataFormLayoutForModal } from 'layout';
import { AutoComplete, Form, Input, message } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  fetchDigitalSignatureSign,
  FetchDigitalSignatureSignRequest,
} from 'src/api/ytt/user-domain/eSignature';
import { YN } from 'src/dict/common';
import LocalStorage from 'src/utils/localStorage';
import { hashPassword } from 'src/utils/string';
// eslint-disable-next-line import/no-cycle
import { getLocalUsernameOptions, setLocalUsernameOptions } from '../utils';

type SignatureModalProps = {
  visible: boolean;
  close: (isSuccess?: boolean) => void;
  currentRow: any;
  functionCode: string;
  signatureSessionId: number;
};

const SignatureModal: React.FC<SignatureModalProps> = (props) => {
  const { visible, close, currentRow, functionCode, signatureSessionId } = props;
  const [form] = Form.useForm();
  const [hasOnlySignUser, setHasOnlySignUser] = useState(false);
  const [usernameOptions, setUsernameOptions] = useState<{ value: string }[]>([]);

  const signSubmit = () => {
    form.validateFields().then(async (values) => {
      const { account: username, password } = values;
      let _password = '';

      if (password) {
        const { hashHex } = await hashPassword(password);

        _password = hashHex;
      }

      setLocalUsernameOptions(functionCode, { value: username });

      const params: FetchDigitalSignatureSignRequest = {
        username,
        password: _password,
        functionCode,
        signatureSessionId,
        seq: currentRow?.seq || 1,
      };

      const res = await fetchDigitalSignatureSign(params);

      if (res.code === 200) {
        message.success(res.message);
        close(true);
      }
    });
  };

  const signInfo = {
    title: '',
    items: [
      {
        label: '账号',
        name: 'account',
        rules: [{ required: true, message: '账号必填' }],
        render: () => (
          <AutoComplete
            disabled={hasOnlySignUser}
            options={usernameOptions}
            style={{ width: 260 }}
            placeholder={'请输入账号'}
          />
        ),
      },
      {
        label: '密码',
        name: 'password',
        rules: [{ required: true, message: '密码必填' }],
        render: () => (
          <Input.Password
            style={{ width: 260 }}
            placeholder={'请输入密码'}
            autoComplete="new-password"
          />
        ),
      },
    ],
  };

  /** 设置表单初始值 */
  const setFormInitialValue = () => {
    const { roles, users = [], currentUserFlag } = currentRow;
    let username = '';

    if (!_.isEmpty(roles) || users.length > 1) {
      return;
    }

    if (currentUserFlag === YN.yes && _.isEmpty(users)) {
      username = LocalStorage.getUserInfo()?.username;
    }
    if (currentUserFlag === YN.no && users.length === 1) {
      username = users[0].username;
    }

    if (username) {
      form.setFields([
        {
          name: 'account',
          value: username,
        },
      ]);

      setHasOnlySignUser(true);
    }
  };

  useEffect(() => {
    setUsernameOptions(getLocalUsernameOptions(functionCode));
    setFormInitialValue();
  }, []);

  return (
    <DataFormLayoutForModal
      visible={visible}
      width={500}
      content={
        <DataFormLayout
          form={form}
          title={'电子签名'}
          info={[signInfo]}
          onCancel={close}
          onFinish={signSubmit}
        />
      }
      onClose={close}
    />
  );
};

export default SignatureModal;
