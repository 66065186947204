const fieldTypeList = {
  text: 1, // 单行文本
  number: 2, // 数值
  textArea: 3, // 多行文本
  select: 4, // 单选
  multiSelect: 5, // 多选
  boolean: 6, // 布尔值
  integer: 7, // 整数
  date: 8, // 日期时间
  url: 9, // 链接
  reference: 10, // 引用字段
  relation: 11, // 关联字段
};

const fieldTypeString = {
  [fieldTypeList.text]: '单行文本',
  [fieldTypeList.number]: '数值',
  [fieldTypeList.textArea]: '多行文本',
  [fieldTypeList.select]: '单选',
  [fieldTypeList.multiSelect]: '多选',
  [fieldTypeList.boolean]: '布尔值',
  [fieldTypeList.integer]: '整数',
  [fieldTypeList.date]: '日期时间',
  [fieldTypeList.url]: '链接',
  [fieldTypeList.reference]: '引用字段',
};

const fieldCategory = {
  customFields: 1,
  preFields: 2,
};
const fieldCategoryString = {
  [fieldCategory.customFields]: '自定义字段',
  [fieldCategory.preFields]: '预设字段',
};

const fieldTypeSortList = [
  { name: '单行文本', type: fieldTypeList.text },
  { name: '多行文本', type: fieldTypeList.textArea },
  { name: '单选', type: fieldTypeList.select },
  { name: '多选', type: fieldTypeList.multiSelect },
  { name: '布尔值', type: fieldTypeList.boolean },
  { name: '数值', type: fieldTypeList.number },
  { name: '整数', type: fieldTypeList.integer },
  { name: '日期时间', type: fieldTypeList.date },
  { name: '链接', type: fieldTypeList.url },
  { name: '引用字段', type: fieldTypeList.reference },
  { name: '关联字段', type: fieldTypeList.reference },
];

const booleanValueList = [
  { displayName: '是', value: 1 },
  { displayName: '否', type: 0 },
];

const statusList = [
  {
    displayName: '启用',
    value: 1,
  },
  {
    displayName: '停用',
    value: 0,
  },
];

const statusValue = {
  enble: 1,
  disable: 0,
};

const statusToString = {
  [statusValue.enble]: '启用',
  [statusValue.disable]: '停用',
};

const getFieldTypeByName = (name: string): number => {
  interface Item {
    [key: string]: any;
  }
  return (<Item>fieldTypeList)[name];
};

export {
  fieldTypeList,
  fieldTypeString,
  fieldCategory,
  fieldCategoryString,
  fieldTypeSortList,
  booleanValueList,
  statusList,
  statusValue,
  statusToString,
  getFieldTypeByName,
};
