import React, { useState } from 'react';
import { Form, message as Message, Checkbox } from 'antd';
import BaseInfo from './component/createAndEditInfo';
import { formatCreateAndEditValue, formatElementList } from './utils';
import { SourceDataType } from './interface';
import { isUndefined } from 'lodash-es';
import { RouteComponentProps } from 'react-router-dom';
import { toNumberRuleList } from './navigation';
import { fetchNumberRuleCreate } from 'src/api/ytt/custom-domain';
import { throttleFn } from '../knowledgeManagement/warehouse/utils';

interface createProps extends RouteComponentProps {
  location: any;
}

const CreateNumberRules = (props: createProps) => {
  const {
    location: {
      state: { suitObjectData, formatConfigData },
    },
    history,
  } = props;

  const [createForm] = Form.useForm();

  const [demo, setDemo] = useState('');

  const [continuousCreate, setContinuousCreate] = useState(false); // 连续新建

  const [sourceData, setSourceData] = useState<SourceDataType>({} as any);

  const setSourceDataFn = (value: any) => {
    setSourceData(value);
  };

  const handleSetDemo = (value: string) => {
    setDemo(value);
  };

  const handleFinish = async () => {
    try {
      const value = await createForm?.validateFields();

      const { elementList, suitObjId, ...rest } = value;

      if (isUndefined(elementList)) {
        // 元素表没有值 提示
        Message.info('请添加规则元素');
        return;
      }

      const newElementList = formatElementList(elementList, sourceData);

      const _value = { ...rest, elementList: newElementList, suitObjId: suitObjId?.value };

      const newValue = formatCreateAndEditValue(_value, formatConfigData);

      const { data, message } = await fetchNumberRuleCreate(newValue);

      if (data) {
        Message.success('创建成功');
        if (continuousCreate) {
          createForm.resetFields();
          setDemo('');
          return;
        }
        history.push(toNumberRuleList());
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  const extra = (
    <Checkbox
      onChange={(e) => {
        setContinuousCreate(e?.target?.checked ?? false);
      }}
    >
      连续新建
    </Checkbox>
  );

  return (
    <BaseInfo
      onFinish={throttleFn(handleFinish)}
      onCancel={() => {
        history.push(toNumberRuleList());
      }}
      title={'新建编号规则'}
      form={createForm}
      edit={false}
      extra={extra}
      setSourceDataFn={setSourceDataFn}
      handleSetDemo={(value: string) => {
        handleSetDemo(value);
      }}
      formatConfigData={formatConfigData}
      suitObjectData={suitObjectData}
      demo={demo}
    />
  );
};

export default CreateNumberRules;
