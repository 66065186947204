import { useState, useEffect } from 'react';
import { useLocation, RouteComponentProps } from 'react-router-dom';
import { DetailLayout } from 'layout';
import { ThumbnailImage } from 'components';
import {
  fetchInventoryElementDetail,
  FetchInventoryElementDetailResponse,
} from 'src/api/ytt/inventory-domain';
import lookup from 'src/dict';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import { replaceSign } from 'src/utils/constants';

interface DetailPageProps extends RouteComponentProps {}
type DataSourceType = Exclude<FetchInventoryElementDetailResponse['data'], undefined>;

const DetailPage = (props: DetailPageProps) => {
  const { search } = useLocation();
  const { id } = qs.parse(search, { ignoreQueryPrefix: true });

  const [dataSource, setDataSource] = useState<DataSourceType>({});

  const fetchData = async () => {
    try {
      if (!id) {
        return;
      }
      const { data } = await fetchInventoryElementDetail({ id: Number(id) });

      setDataSource(data || {});
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);
  const getConverAmounts = () => {
    return (
      dataSource.opAmount?.conversionAmounts?.map((item: any, i: number): any => {
        return {
          label: `转换数量${i + 1}`,
          dataIndex: `converAmount${String(i)}`,
          render: () => {
            const { amount, unit = {} } = item || {};

            if (typeof amount === 'undefined') {
              return '-';
            }
            return `${amount} ${unit?.name}`;
          },
        };
      }) || []
    );
  };

  const getAuxAmounts = () => {
    return (
      dataSource.opAmount?.auxAmounts?.map((item: any, i: number): any => {
        return {
          label: '辅助数量',
          dataIndex: `auxAmounts${String(i)}`,
          render: () => {
            const { amount, unit = {} } = item || {};

            if (typeof amount === 'undefined') {
              return '-';
            }
            return `${amount} ${unit?.name}`;
          },
        };
      }) || []
    );
  };

  const baseInfo = [
    {
      title: '基本信息',
      items: _.concat(
        [
          {
            label: '物料名称',
            dataIndex: 'materialName',
            render: () => dataSource.material?.name || replaceSign,
          },
          {
            label: '物料编号',
            dataIndex: 'materialCode',
            render: () => dataSource.material?.code || replaceSign,
          },
          {
            label: '物料分类',
            dataIndex: 'categoryName',
            render: () => dataSource.material?.categoryName || replaceSign,
          },
          {
            label: '物料属性',
            dataIndex: 'materialAttr',
            render: () => {
              const attribute = dataSource.material?.attrList;

              return attribute && attribute.length > 0
                ? attribute.map((item) => {
                    const { name, attributeItem } = item;

                    return (
                      <div
                        style={{
                          display: 'inline-block',
                          maxWidth: '100%',
                          padding: '0 7px',
                          marginRight: 8,
                          backgroundColor: '#fafafa',
                          border: '1px solid #d9d9d9',
                          borderRadius: 2,
                        }}
                      >
                        {name}: {attributeItem?.content}
                      </div>
                    );
                  })
                : '-';
            },
          },
          {
            label: '物料规格',
            dataIndex: 'specification',
            render: () => dataSource.material?.specification || replaceSign,
          },
          {
            label: '物料图片',
            dataIndex: 'documents',
            render: () => {
              const { documents = [] } = dataSource.material || {};

              return documents?.length > 0 ? (
                <div style={{ display: 'flex' }}>
                  {documents?.map((item) => {
                    if (!item?.documentId) {
                      return '-';
                    }
                    return (
                      <ThumbnailImage
                        documentId={item?.documentId}
                        key={item?.documentId}
                        documentPath={item?.documentPath}
                      />
                    );
                  })}
                </div>
              ) : (
                '-'
              );
            },
          },
          {
            label: '仓库',
            dataIndex: 'warehouse',
            render: () => dataSource.location?.warehouse?.name || replaceSign,
          },
          {
            label: '仓位',
            dataIndex: 'storageLocationId',
            render: () => dataSource.location?.storage?.name || replaceSign,
          },
          {
            label: '数量',
            dataIndex: 'amount',
            render: () => {
              const { amount, unit = {} } = dataSource.opAmount?.amount || {};

              if (typeof amount === 'undefined') {
                return '-';
              }
              return `${amount} ${unit?.name}`;
            },
          },
        ],
        getAuxAmounts(),
        getConverAmounts(),
        [
          {
            label: '备注',
            dataIndex: 'remark',
            render: () => dataSource.material?.remark || replaceSign,
          },
          {
            label: '位置状态',
            dataIndex: 'storageStatus',
            render: lookup('bound.storageStatus'),
          },
          {
            label: '质量状态',
            dataIndex: 'qcStatus',
            render: lookup('bound.qualityStatus'),
          },
          {
            label: '业务状态',
            dataIndex: 'bizStatus',
            render: lookup('bound.inventoryBizStatus'),
          },
          {
            label: '批次号',
            dataIndex: 'batchNo',
            render: () => dataSource.bizKey?.batchNo || replaceSign,
          },
          {
            label: '标识码',
            dataIndex: 'qrCode',
            render: (value: string) => value || replaceSign,
          },
          {
            label: '供应商',
            dataIndex: 'supplier',
            render: () => dataSource.bizKey?.supplier?.name || replaceSign,
          },
          {
            label: '供应商批次',
            dataIndex: 'supplierBatchNo',
            render: () => dataSource.bizKey?.supplierBatchNo || replaceSign,
          },
          {
            label: '客户',
            dataIndex: 'customer',
            render: () => dataSource.bizKey?.customer?.name || replaceSign,
          },
          {
            label: '入厂日期',
            dataIndex: 'inboundTime',
            render: () => {
              if (!dataSource.bizKey?.inboundTime) {
                return '-';
              }
              return moment(dataSource.bizKey?.inboundTime).format('YYYY/MM/DD HH:mm:ss');
            },
          },
          {
            label: '生产日期',
            dataIndex: 'outTime',
            render: () => {
              if (!dataSource.bizKey?.productTime) {
                return '-';
              }
              return moment(dataSource.bizKey?.productTime).format('YYYY/MM/DD HH:mm:ss');
            },
          },
          {
            label: '有效期至',
            dataIndex: 'validityPeriod',
            render: () => {
              if (!dataSource.bizKey?.validityPeriod) {
                return '-';
              }
              return moment(dataSource.bizKey?.validityPeriod).format('YYYY/MM/DD HH:mm:ss');
            },
          },
        ],
      ),
    },
  ];

  return (
    <DetailLayout title="库存明细详情" info={baseInfo} dataSource={dataSource} baseMenu={[]} />
  );
};

export default DetailPage;
