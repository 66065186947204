/**
 * @enum 领料申请业务模块枚举值
 */

/** 领料申请分单来源分类 */
export enum SplitRequisitionOrigin {
  /** 生产工单 */
  productionOrder = 0,
  /** 用料清单 */
  bom = 1,
  /** 工序计划 */
  processPlanning = 2,
}

/** 领料申请状态字段 */
export enum StatusEnum {
  /** 新建 */
  new = 0,
  /** 已关闭 */
  closed = 1,
}

/** 调拨单合单原则 */
export enum MergePrinciples {
  /** 发出仓库+接收仓库 */
  warehouses = 0,
  /** 物料 */
  materials = 1,
  /** 需求时间 */
  requireTime = 2,
}

/** 调拨单业务类型 */
export enum TransferOrderBusinessType {
  /** 仓储调拨 */
  inventory,
  /** 领料调拨 */
  storeRequisition,
}

export enum PickOrderType {
  /** 生产领料 */
  product = 1,
  /** 普通领料 */
  common,
}

export enum CalcRange {
  /** “计划确认”状态的计划订单 */
  planOrder = 1,
  /** “新建、已下发、执行中”且“未暂停”的生产工单 */
  produceOrder,
}

export enum ProvidedRange {
  /** 领料申请待收料数量 */
  toReceive = 1,
  /** 车间仓库库存数量 */
  workshopStorage,
}
