import { DatePicker, Form, FormInstance, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';
import moment from 'moment';

import {
  FetchResourceSchedulerCreateRequest,
  FetchResourceSchedulerUpdateRequest,
} from 'src/api/ytt/resource-domain/collectionRules';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { checkTwoSidesTrim, numberAlphabetSpecialSymbols } from 'src/utils/formValidators';
import { AutoFrequencyUnit } from 'src/dict/resources';
//
import { MAX_INPUT_LENGTH, MAX_TEXTAREA_LENGTH, fieldLabels } from '../constants';
import { CollectionRuleDetailType, CollectionRuleFormType } from '../interface';
import AutoFrequency from '../components/AutoFrequency';

interface ActionContentProps {
  form: FormInstance;
  type: 'create' | 'edit';
}

type FormatDataToApiFunction =
  | ((data: CollectionRuleFormType) => FetchResourceSchedulerCreateRequest)
  | ((data: CollectionRuleFormType) => FetchResourceSchedulerUpdateRequest);

export const formatDataToApi: FormatDataToApiFunction = (data: CollectionRuleFormType) => {
  const { autoBeginTime, autoEndTime, autoFrequency, ...rest } = _.omit(data, 'frequency');

  return {
    ...rest,
    autoFrequency: Number(autoFrequency),
    autoBeginTime: autoBeginTime.valueOf(),
    autoEndTime: autoEndTime.valueOf(),
  };
};

export const formatDataToForm = (data: CollectionRuleDetailType): CollectionRuleFormType => {
  const { autoBeginTime, autoEndTime, name, autoFrequency, autoFrequencyUnitType, ...rest } = data;

  return {
    ...rest,
    autoBeginTime: moment(autoBeginTime),
    autoEndTime: moment(autoEndTime),
    name: name!,
    autoFrequency: autoFrequency!,
    autoFrequencyUnitType: autoFrequencyUnitType!,
  };
};

const ActionContent = (props: ActionContentProps) => {
  const { form, type } = props;

  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    column: 2,
    items: _.compact([
      {
        label: '',
        name: 'id',
        hidden: true,
        render: () => <Input />,
      },
      {
        label: fieldLabels.code,
        name: 'code',
        rules: [
          { required: true, message: `请输入${fieldLabels.code}` },
          { max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` },
          numberAlphabetSpecialSymbols,
        ],
        render: () => <Input placeholder={`请输入${fieldLabels.code}`} />,
      },
      {
        label: fieldLabels.name,
        name: 'name',
        rules: [
          { required: true, message: `请输入${fieldLabels.name}` },
          { max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` },
          { validator: checkTwoSidesTrim },
        ],
        render: () => <Input placeholder={`请输入${fieldLabels.name}`} />,
      },
      {
        noStyle: true,
        dependencies: ['autoEndTime'],
        render: (formItemStyles) => () => {
          const autoEndTime = form.getFieldValue('autoEndTime');

          return (
            <Form.Item
              {...formItemStyles}
              label={fieldLabels.autoBeginTime}
              name="autoBeginTime"
              rules={[{ required: true, message: `请输入${fieldLabels.autoBeginTime}` }]}
            >
              <DatePicker
                format="YYYY-MM-DD HH:mm:ss"
                showTime
                style={{ width: '100%' }}
                disabledDate={(date) => autoEndTime && date.isAfter(autoEndTime)}
              />
            </Form.Item>
          );
        },
      },
      {
        noStyle: true,
        dependencies: ['autoBeginTime'],
        render: (formItemStyles) => () => {
          const autoBeginTime = form.getFieldValue('autoBeginTime');

          return (
            <Form.Item
              // 被dependencies包起来的FormItem会失去外层的样式，通过这样的透传来解决
              {...formItemStyles}
              name="autoEndTime"
              label={fieldLabels.autoEndTime}
              rules={[{ required: true, message: `请输入${fieldLabels.autoEndTime}` }]}
            >
              <DatePicker
                format="YYYY-MM-DD HH:mm:ss"
                showTime
                style={{ width: '100%' }}
                disabledDate={(date) => autoBeginTime && date.isSameOrBefore(autoBeginTime)}
              />
            </Form.Item>
          );
        },
      },

      {
        label: fieldLabels.autoFrequency,
        name: 'autoFrequency',
        rules: [{ required: true, message: `请输入${fieldLabels.autoFrequency}` }],
        render: () => (
          <div style={{ width: '100%' }}>
            <AutoFrequency defaultUnit={AutoFrequencyUnit.minute} defaultNumber={5} form={form} />
          </div>
        ),
      },
      type === 'edit' && {
        label: '编辑原因',
        name: 'operateReason',
        isFullLine: true,
        rules: [{ max: MAX_TEXTAREA_LENGTH, message: `不超过${MAX_TEXTAREA_LENGTH}字符` }],
        render: () => <TextArea placeholder="请输入编辑原因" />,
      },
    ]),
  };

  return (
    <div style={{ overflowY: 'auto', height: '100%', margin: 24 }}>
      <DataFormLayout info={[baseInfo]} form={form} footer={false} />
    </div>
  );
};

export default ActionContent;
