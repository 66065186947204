/**
 * @description 流程管理审批流
 * @author chenyurou
 */

import {
  ApprovalDesignationMethodEnum,
  ApprovalDesignationObjectEnum,
  AssociationTriggeringRuleEnum,
  TriggeringActionEnum,
  typeEnum,
} from '.';
import { mappingsFactory } from '../utils';

export const approvalDesignationObjectOptions = mappingsFactory(
  ['指定用户', ApprovalDesignationObjectEnum.user],
  ['部门', ApprovalDesignationObjectEnum.department],
  ['角色', ApprovalDesignationObjectEnum.role],
);

export const approvalDesignationMethodOptions = mappingsFactory(
  ['或签（一名审批人通过或驳回即可）', ApprovalDesignationMethodEnum.orSing],
  ['会签（需所有审批人通过，一名审批人否决即可驳回）', ApprovalDesignationMethodEnum.andSing],
);

export const approvalDesignationOptions = mappingsFactory(
  ['或签', ApprovalDesignationMethodEnum.orSing],
  ['会签', ApprovalDesignationMethodEnum.andSing],
);

export const associationTriggeringRuleEnum = mappingsFactory(
  ['关联已有触发规则', AssociationTriggeringRuleEnum.exist],
  ['自定义', AssociationTriggeringRuleEnum.customer],
);

export const triggeringActionOptions = mappingsFactory(['新建', TriggeringActionEnum.add]);

export const typeOptions = mappingsFactory(
  ['自定义类型', typeEnum.customer],
  ['预置类型', typeEnum.preset],
);
