import React, { useEffect, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';
import { Form, Checkbox, Input, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import AttrFormTable from './attrFormTable';
import {
  fetchMaterialAttributeCreate,
  fetchMaterialAttributeDetail,
  fetchMaterialAttributeUpdate,
} from 'src/api/ytt/material-domain/Web_MaterialAttribute';
import { CRUD } from 'src/dict/common';
import { checkTwoSidesTrim } from 'src/utils/formValidators';

interface Props {
  type: string;
  refreshTable?: () => void;
  refreshDetails: () => void;
  onCancel?: () => void;
  id?: number | undefined;
}

const BaseInfo = (props: Props) => {
  const { type, onCancel, refreshTable, refreshDetails, id } = props;
  const [form] = Form.useForm();
  const [keepOn, sekeepOn] = useState(type === CRUD.create);
  const [initialData, setInitialData] = useState<any>([]);
  const baseInfo: DataFormLayoutInfoBlock = {
    items: [
      {
        label: '属性项',
        name: 'name',
        rules: [
          { required: true, message: '属性项必填' },
          { max: 255, message: '不超过255字符' },
          { validator: checkTwoSidesTrim },
        ],
        validateFirst: true,
        render: () => <Input placeholder="请输入属性项" />,
      },
      {
        label: '属性值列表',
        render: () => <AttrFormTable initialData={initialData?.attributeItemsList} form={form} />,
      },
      {
        label: '编辑原因',
        name: 'reason',
        hidden: type === CRUD.create,
        rules: [{ max: 1000, message: '不可超过1000字符' }],
        render: () => <TextArea placeholder="请输入编辑原因" />,
      },
    ],
    column: 1,
  };

  const initCreate = () => {
    form.setFieldsValue({
      code_isUseRules: true,
    });
  };
  const initEdit = async (attrId: number | undefined) => {
    try {
      if (!attrId) return;
      const res: any = await fetchMaterialAttributeDetail({ id: attrId });

      form.setFieldsValue(res.data);
      setInitialData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = async () => {
    try {
      const value = await form?.validateFields();

      value?.attributeItemsList?.map((item: any, index: number) => {
        item.index = index;
        return item;
      });

      switch (type) {
        case CRUD.create: {
          await fetchMaterialAttributeCreate(value);
          message.success('创建成功');
          break;
        }

        case CRUD.edit:
          await fetchMaterialAttributeUpdate({ ...value, id });
          message.success('编辑成功');
          break;

        default:
          break;
      }
      if (keepOn) {
        typeof refreshTable === 'function' && refreshTable();
        form.resetFields();
        initCreate();
        return;
      }
      onCancel && onCancel();
      typeof refreshTable === 'function' && refreshTable();
      refreshDetails();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    switch (type) {
      case CRUD.create:
        initCreate();
        break;

      case CRUD.edit:
        initEdit(id);
        break;

      default:
        initCreate();
        break;
    }
  }, []);

  return (
    <DataFormLayout
      form={form}
      info={[baseInfo]}
      title={type === CRUD.create ? '新建属性' : '编辑属性'}
      extra={
        type === CRUD.create && (
          <Checkbox
            onChange={() => {
              sekeepOn(!keepOn);
            }}
            defaultChecked={keepOn}
          >
            连续新建
          </Checkbox>
        )
      }
      onCancel={onCancel}
      onFinish={handleFinish}
    />
  );
};

export default BaseInfo;
