import React, { useState, useEffect } from 'react';
import { DetailBaseInfo } from './detailBaseInfo';
import { match, RouteComponentProps } from 'react-router-dom';
import {
  fetchSalesOrderDetail,
  fetchSalesOrderFinish,
  fetchSalesOrderFinishCheck,
} from 'src/api/ytt/order-domain/salesOrder';
import { toCreateInvoice_URL, toEdit, toInvoiceList_URL } from '../navigation';
import { SingleOperationLogComponent } from 'src/components';
import _ from 'lodash';
import { Modal, message as Message } from 'antd';
import _Array from 'src/utils/dataTypes/array';
import {
  SalesOrderFinishCheckFailDetails,
  PurchaseOrderCrossCustomFields,
  SalesOrderListProps,
} from '../interface';
import { Auth, OriginType, ShipmentStatus } from 'src/dict/sales';
import authDict from 'src/utils/auth';
import { appEnum } from 'src/dict';
import { fetchCustomFieldCrossColumns } from '../utils';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { PurchaseOrderdeliveryMode } from 'src/dict/purchase';
import { useDispatch } from 'react-redux';
import { useCustomFieldCombinedData } from 'src/components/customField';

interface DetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const SalesOrderDetailPage = (props: DetailProps) => {
  const { match, history } = props;
  const salesId = Number(match?.params?.id);
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [checkVisible, setCheckVisible] = useState(false);
  const [dataSource, setDataSource] = useState({} as any);
  const [loading, setLoading] = useState(true);
  // 销售订单 自定义字段
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.salesOrder);
  const customItemFields = useCustomFieldCombinedData(OBJECT_OF_CODE.salesOrderItem);
  // 跨租户 采购订单的自定义字段
  const [crossCustomFields, setCrossCustomFields] = useState<PurchaseOrderCrossCustomFields>();

  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const { data } = await fetchSalesOrderDetail({ id: salesId }, { legacy: true });
      const crossCustomFieldsColumns = await fetchCustomFieldCrossColumns(
        data?.header?.customer?.id,
      );

      setLoading(false);
      setDataSource(data);
      setCrossCustomFields(crossCustomFieldsColumns);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // const handleDelete = async () => {
  //   try {
  //     const value = await delForm?.validateFields();

  //     const { data } = await fetchSalesOrderDelete({ ids: [salesId] });

  //     if (data) {
  //       message.success('删除成功');
  //       history.push(toList());
  //       return;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  /**
   * 结束操作
   */
  const handleEndOrder = async (overMessage: string) => {
    try {
      const { message, data } = await fetchSalesOrderFinish({ ids: [salesId] });

      if (data) {
        if (_Array.isEmpty(data?.fail)) {
          Message.success(overMessage);
          fetchData();
          return;
        }
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * 结束操作 - 确认
   */
  const handleEndOrderConfirm = async (
    ids: number[],
    type: 'single' | 'multiple',
    onSuccess?: () => void,
    onFail?: () => void,
  ) => {
    let modalConfig = {
      content: <></>,
      message: '',
    };
    const { failCount = 0, successCount = 0, failDetails } = (await checkFn()) || {};
    const orderCodeList = _.map(failDetails, 'code') || [];

    async function checkFn() {
      const { data } = await fetchSalesOrderFinishCheck({ ids });

      return data;
    }
    function renderContent(
      data: (string | undefined)[] | { lineNo: string; code: string; materialCode: string }[],
      type: 'success' | 'multipleAbnormal' | 'singleAbnormal',
    ) {
      const modalConfig = {
        content: <></>,
        message: '',
      };

      switch (type) {
        case 'singleAbnormal':
          {
            const line = (data as SalesOrderFinishCheckFailDetails[])?.map((item) => {
              return `${item?.lineNo}行的<${item?.materialCode}>`;
            });
            const code = (data as SalesOrderFinishCheckFailDetails[])?.[0]?.code;

            modalConfig.content = (
              <div>{`${line?.join('，')}还未完成，确定结束<${code}>订单吗?`}</div>
            );
            modalConfig.message = '销售订单异常结束';
          }

          break;

        case 'multipleAbnormal':
          modalConfig.content = (
            <>
              <div>以下订单内存在未完成的明细行，确定要结束销售订单嘛?</div>
              <div style={{ color: '#02B980' }}>{data?.join(',')}</div>
            </>
          );
          modalConfig.message = '部分销售订单异常结束';
          break;
        default:
          modalConfig.content = (
            <>
              <div>结束销售订单后，将不再进行任何操作，确定结束以下订单吗?</div>
              <div style={{ color: '#02B980' }}>{data?.join('，')}</div>
            </>
          );
          modalConfig.message = '销售订单结束成功';
          break;
      }
      return modalConfig;
    }

    switch (type) {
      case 'multiple':
        if (failCount === 0 && successCount === ids?.length) {
          modalConfig = renderContent(orderCodeList, 'success');
        } else {
          // 有异常状态的订单（发货状态=待发货/部分发货，生产状态=待计划/部分计划/已计划 ）
          modalConfig = renderContent(orderCodeList, 'multipleAbnormal');
        }
        break;

      default:
        if (failCount === 0 && successCount === ids?.length) {
          modalConfig = renderContent(orderCodeList, 'success');
        } else {
          modalConfig = renderContent(failDetails, 'singleAbnormal');
        }
        break;
    }

    Modal.confirm({
      title: '结束销售订单',
      content: <div>{modalConfig.content}</div>,
      visible: checkVisible,
      onOk() {
        handleEndOrder(modalConfig.message);
      },
      onCancel() {
        onFail?.();
        setCheckVisible(false);
      },
      okText: '结束',
      cancelText: '暂不结束',
    });
  };
  /**
   * 新建发货单
   */
  const handleCreateInvoice = (
    data: SalesOrderListProps[],
    type?: 'single' | 'multiple',
    onSuccess?: () => void,
    onFail?: () => void,
  ) => {
    const jumpToCreateInvoice = (data: SalesOrderListProps[]) => {
      dispatch({
        type: 'supplyChain/formatterInvoiceInfo',
        payload: { invoiceType: data[0]?.header?.origin, data },
      });
      history.push(toCreateInvoice_URL());
    };
    const handleNext = () => {
      if (_.findIndex(data, (o) => o.header?.shipmentStatus === ShipmentStatus.allShipped) > -1) {
        Message.error('只有待发货/部分发货的销售订单可以新建发货单');
        onFail?.();
      } else if (
        _.findIndex(
          data,
          (o) => o.header?.deliveryMode === PurchaseOrderdeliveryMode.deliverySchedule,
        ) > -1
      ) {
        Message.error('交货方式为「按交货计划」不支持新建发货单，请重新选择');
        onFail?.();
      } else {
        jumpToCreateInvoice(data);
      }
    };
    const config = {
      title: '提示',
      content: '物料已全部计划发货，发货计划不得超过需求数量，可以到发货单内执行其他操作',
      width: 436,
      onOk: () => {
        history.push(toInvoiceList_URL());
      },
      okText: '查看发货单',
      onCancel: () => {},
      cancelText: '关闭',
    };

    switch (type) {
      case 'multiple': {
        // 确认来源是否一致
        const headerList = _.map(data, 'header');
        const originList = _.map(headerList, 'origin');

        if (_.uniq(originList).length === 1) {
          handleNext();
        } else {
          Message.error('只有相同来源的销售订单可以新建发货单');
          onFail?.();
        }

        break;
      }

      default: {
        const shipmentStatus = data[0]?.header?.shipmentStatus;

        if (shipmentStatus === ShipmentStatus.allShipped) {
          // 弹窗提示
          Modal.confirm(config);
        } else {
          // 发货状态=待发货/部分发货时，带物料编号、物料名称等信息新页面打开新建发货单页面；
          jumpToCreateInvoice(data);
        }

        break;
      }
    }
  };
  /**
   * 详情操作
   */
  const baseMenu = [
    {
      title: '新建发货单',
      key: 'shipmentsnote_add',
      auth: authDict.shipmentsnote_add,
      //  销售订单状态=全部发货时，不支持新建发货单;当交货方式 = 「按交货计划」时，不支持根据销售订单创建发货单
      disabled:
        dataSource?.header?.shipmentStatus === ShipmentStatus.allShipped ||
        dataSource?.header?.deliveryMode === PurchaseOrderdeliveryMode.deliverySchedule,
      onClick: () => handleCreateInvoice([dataSource], 'single'),
    },
    {
      key: 'over',
      auth: authDict.salesorder_finish,
      onClick: () => {
        handleEndOrderConfirm([dataSource?.header?.id], 'single');
      },
      disabled: dataSource?.header?.status !== appEnum.Sales.OrderStatus.create,
      title: '结束',
    },
    // {
    //   key: 'delete',
    //   onClick: () => {
    //     BLDelConfirm('删除后将无法恢复，确认删除？', delForm, () => {
    //       handleDelete();
    //     });
    //   },
    //   icon: <BlIcon type="iconshanchulajitong1" />,
    //   title: '删除',
    // },
    {
      key: 'log',
      auth: authDict.salesorder_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'edit',
      auth: authDict.salesorder_edit,
      onClick: () => {
        history.push(toEdit(salesId));
      },
      title: '编辑',
      disabled:
        Auth.supplyChain && origin !== null
          ? (dataSource?.header?.origin === OriginType.ordinary &&
              dataSource?.header?.status !== appEnum.Sales.OrderStatus.create) ||
            dataSource?.header?.origin === OriginType.collaborate
          : dataSource?.header?.status !== appEnum.Sales.OrderStatus.create, //  订单来源=普通订单时，订单状态=新建时支持编辑，其他状态暂不支持编辑；  订单来源=协同订单时，任何状态都不支持编辑
    },
  ];

  useEffect(() => {
    fetchData();
  }, [salesId]);

  return (
    <>
      <DetailBaseInfo
        detail={dataSource}
        baseMenu={baseMenu}
        loading={loading}
        crossCustomFields={crossCustomFields}
        customFields={{ document: customFields, material: customItemFields }}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={salesId}
          objectCode={'SalesOrder'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default SalesOrderDetailPage;
