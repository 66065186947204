export const FIELDS = {
  WELCOME_AT: 'welcome_at',
  USER_NAME: 'username',
  TOKEN_NAME: 'token',
  TOKEN_PASSWORD: 'token_password',
  AUTH: 'auth',
  USER_INFO: 'userInfo',
  LOGIN_INFO: 'loginInfo',
  USER: 'USER',
  CONTEXT_TYPE: 'context_type',
  REQUEST_CONFIG: 'request_config',
  TRANSFER_ORDER_MERGE_SETTING: 'transfer_order_merge_setting',
  MESSAGE_UN_READ_TOTAL: 'message_un_read_total',
  MESSAGE_UN_READ_LIST: 'message_un_read_list',
  MESSAGE_UN_READ_URGENT: 'message_un_read_urgent',
  RESOURCE_TAG_IS_CREATE: 'resource_tag_is_create',
  RESOURCE_TYPE_IS_CREATE: 'resource_type_is_create',
  INTEGRATE_PICK_CONFIG: 'storeRequisition_integrate_pick_config',
};

// 小数点保留的位数
export const MaxDigits = 6;

// 杠, 一个替换标识。用来表示不显示
export const replaceSign = '-';
// 表示必填/必选对标识符
export const requiredSign = '*';
export const LOCATION_INFO = 'location_info';

export const CommonLoginRoute = '/login';

// 登录页路由pathname
export const LoginRoutePathname = [CommonLoginRoute];

// 休息班次
export const REST_SHIFT = {
  value: 0,
  label: '休息',
};

export const LOGOUT_CODE = {
  // 这里注释到情况都被归纳到 MESSAGE 里面

  // CONFIG_CHANGED: 'USER-DOMAIN/CONFIG_CHANGED', // 工厂配置变动，请重新登录
  // FACE_RECOGNITION_INFORMATION_CHANGED: 'USER-DOMAIN/FACE_RECOGNITION_INFORMATION_CHANGED', //您的人脸信息已被管理员修改，请重新登录
  // NFC_CHANGED: 'USER-DOMAIN/NFC_CHANGED', // 您的NFC卡信息已被管理员修改，请重新登录
  // PRIVILEGE_CHANGED: 'USER-DOMAIN/PRIVILEGE_CHANGED', // 您的账号权限已被修改，请重新登录
  // PHONE_CHANGED: 'USER-DOMAIN/PHONE_CHANGED', // 您的登陆手机号已被管理员修改，请重新登录
  // PASSWORD_CHANGED: 'USER-DOMAIN/PASSWORD_CHANGED',  // 您的密码已被管理员修改，请重新登录
  // TERMINAL_CHANGED: 'USER-DOMAIN/TERMINAL_CHANGED', // 您的账号类型已被修改，请重新登陆
  // SYSTEM_RENEW: 'USER-DOMAIN/SYSTEM_RENEW', // 系统更新，请重新登录
  // USER_LOGIN_EXPIRED: 'USER-DOMAIN/USER_LOGIN_EXPIRED', // 用户登陆过期

  SSO_TOKEN_FAI: 'USER-DOMAIN/SSO_TOKEN_FAIL', // 自动登录已过期，请重新登录
  SSO_OTHER_DEVICES_LOGIN: 'USER-DOMAIN/SSO_OTHER_DEVICES_LOGIN', // 您的账号在别处登录
  MESSAGE: 'USER-DOMAIN/MESSAGE', // 其他情况
};

export const blacklakeDomain = `.${location.host
  .split('.')
  .slice(location.host.split('.').length - 2, 3)
  .join('.')}`;
