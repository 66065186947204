/**
 * @description 货源清单枚举值
 * @author xuzhiyong
 */

/** 货源清单列表Tab */
export enum SOURCE_VIEW_TAB {
  /** 货源清单视图 */
  source = '1',
  /** 供应商行视图 */
  supplier = '2',
}
