import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form, Input, message as Message, Checkbox } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import _ from 'lodash';

import {
  fetchPickOrderGet,
  fetchPickOrderUpdate,
  fetchPickOrderBatchCreate,
  FetchPickOrderBatchCreateRequest,
} from 'src/api/ytt/med-domain/store-requisition';
import { lookup } from 'src/dict';
import { CRUD } from 'src/dict/common';
import { PickOrderType } from 'src/dict/storeRequisition';
import { ErrorNotification, NumberRulesStandardHook } from 'src/components';
import { numberAlphabetSpecialSymbols, withoutSpaceBothSides } from 'src/utils/formValidators';
import StoreRequisitionTable from '../components/StoreRequisitionTable';
import { fieldLabels } from '../constants';
import { FormDataType, formatMaterialLineToDisplay, goBackToList } from '../utils';
import GeneralMaterialTable from '../components/StoreRequisitionTable/GeneralMaterialTable';
import { LabeledValue } from 'antd/lib/select';

interface SingleStoreRequisitionProps {
  id?: string;
}

// 传给form的设置
// preserve: 在删除字段时保留字段值
// antd的form会在rerender的时候删除所有字段，如果该字段为false，使用form全程不可以rerender
const formProps = {
  preserve: true,
};

const parseBatchNo = (batchNo: LabeledValue | undefined) => {
  if (_.isString(batchNo?.value)) {
    return {
      id: JSON.parse(String(batchNo?.value))?.id as number,
      code: JSON.parse(String(batchNo?.value))?.batchNo as string,
    };
  } else if (_.isNumber(batchNo?.value)) {
    return { id: batchNo!.value, code: batchNo!.label as string };
  }
  return undefined;
};

const formatEditRequestParam = (detailFormData: FormDataType) => {
  const { deleteList, materials, code, ...restData } = _.omit(detailFormData, [
    'creator',
    'createdAt',
    'operator',
    'updatedAt',
  ]);

  return {
    ...restData,
    updateMaterials: materials?.map((materialLine) => {
      const {
        sourceWarehouseId,
        targetWarehouseId,
        batchNo,
        requestPickAmount,
        material,
        unit,
        line,
        ...rest
      } = _.omit(materialLine, [
        'sourceWarehouseName',
        'targetWarehouseName',
        'inputProcessCode',
        'inputProcessName',
        'inputProcessId',
        'code',
        'pickOrderIssuedAmount',
        'productionDepartment',
        'requirementTime',
        'supplierList',
        'transferOrder',
        'workCenter',
        'workOrderCode',
      ]);

      return {
        ...rest,
        line: line!,
        sourceWarehouseId: sourceWarehouseId?.value?.id,
        targetWarehouseId: targetWarehouseId?.value?.id!,
        batchCode: parseBatchNo(batchNo),
        requestPickAmount: String(requestPickAmount?.amount!),
        unitId: unit?.value || requestPickAmount?.unitId!,
        materialId: material?.baseInfo?.id!,
      };
    }),
    deleteLineIds: deleteList?.deleteLineIds,
    pickOrderCode: code,
  };
};

/**
 * 表单需要的列表数据转换为新建接口入参格式
 */
const formatCreateRequestParam = (formData: FormDataType) => {
  const createParam: FetchPickOrderBatchCreateRequest['createPickOrderCOs'] = [];
  const { materials, code, ...rest } = formData;

  createParam.push({
    ...rest,
    pickOrderCode: code!,
    pickOrderType: PickOrderType.common,
    materials: materials!.map((materialLine) => {
      const {
        material,
        remark,
        requestPickAmount,
        sourceWarehouseId,
        targetWarehouseId,
        unit,
        batchNo,
        line,
      } = materialLine;

      return {
        line,
        remark,
        materialId: material.baseInfo?.id!,
        requestPickAmount: String(requestPickAmount.amount!),
        unitId: unit.value!,
        sourceWarehouseId: sourceWarehouseId?.value?.id!,
        targetWarehouseId: targetWarehouseId?.value?.id!,
        batchCode: parseBatchNo(batchNo),
      };
    }),
  });

  return createParam;
};

export default function EditStoreRequisition(
  props: RouteComponentProps<SingleStoreRequisitionProps>,
) {
  const { match, history } = props;
  const isCreate = history.location.pathname.split('/').pop()!.includes('create');
  const [editForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [keepOn, setKeepOn] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<FormDataType>();

  const storeRequisitionId = match.params.id;

  const dialogTitle =
    (isCreate ? lookup('common.crud', CRUD.create) : lookup('common.crud', CRUD.edit)) + '领料申请';
  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    items: [
      ...NumberRulesStandardHook({
        label: fieldLabels.storeRequisitionCode,
        form: editForm,
        edit: !isCreate,
        objectCode: 'PickOrder',
        fieldCode: 'code',
        rules: [
          numberAlphabetSpecialSymbols,
          withoutSpaceBothSides,
          { max: 255, message: '不可超过255个字符' },
        ],
      }),
      {
        label: fieldLabels.type,
        name: 'pickOrderType',
        render: () =>
          lookup(
            'storeRequisiton.PickOrderTypeMap',
            editForm.getFieldValue('pickOrderType') ?? PickOrderType.common,
          ),
      },
      {
        name: 'pickOrderId',
        hidden: true,
        render: () => <Input />,
      },
      {
        name: 'deleteList',
        hidden: true,
        initialValue: { deleteLineIds: [] },
        render: () => <Input />,
      },
    ],
  };

  const materialInfo: DataFormLayoutInfoBlock = {
    title: '物料信息',
    items: [
      {
        name: 'materials',
        isFullLine: true,
        rules: [{ required: true, message: '物料行必填' }],
        render: () => {
          const orderType = editForm.getFieldValue('pickOrderType');

          // 单个新建场景只发生在普通领料时，只有普通领料用普通物料表哥
          return isCreate || orderType === PickOrderType.common ? (
            <GeneralMaterialTable form={editForm} isEdit={!isCreate} />
          ) : (
            <StoreRequisitionTable form={editForm} mode={CRUD.edit} />
          );
        },
      },
    ],
  };

  const otherInfo = {
    title: '其他信息',
    items: [
      {
        label: '编辑原因',
        name: 'reason',
        isFullLine: true,
        rules: [withoutSpaceBothSides],
        render: () => <Input.TextArea maxLength={1000} showCount />,
      },
    ],
  };

  const formInfo: DataFormLayoutInfoBlock[] = isCreate
    ? [baseInfo, materialInfo]
    : [baseInfo, materialInfo, otherInfo];

  const createGeneralStoreRequisition = async () => {
    try {
      await editForm.validateFields();
      const storeRequisition = editForm.getFieldsValue();

      const createParam = formatCreateRequestParam(storeRequisition);

      setLoading(true);
      const response = await fetchPickOrderBatchCreate({ createPickOrderCOs: createParam });
      const { code, data } = response;

      setLoading(false);

      if (code === 200 && data) {
        const { failCount, successCount, failDetails } = data;

        // 全部新建成功，显示新建成功
        if (failCount === 0) {
          Message.success('新建成功');

          if (isCreate && keepOn) {
            editForm.resetFields();
          } else {
            goBackToList(history);
          }
        } else if (successCount === 0) {
          // 全部新建失败，提示新建失败及原因
          if (failDetails) {
            ErrorNotification({
              message: '新建失败',
              description: (
                <>
                  {failDetails.map((fail) => (
                    <div key={fail.pickOrderCode}>{fail.reason}</div>
                  ))}
                </>
              ),
            });
          } else {
            Message.error('新建失败！');
          }
        }
      } else {
        Message.error(response.message);
      }
    } catch (e) {
      setLoading(false);
      // console.log('e :>> ', e);
    }
  };

  const updateStoreRequisition = async () => {
    try {
      await editForm.validateFields();
      const storeRequisition = editForm.getFieldsValue();

      const editParam = formatEditRequestParam(storeRequisition);

      setLoading(true);
      const response = await fetchPickOrderUpdate(editParam);

      setLoading(false);

      if (response.code === 200) {
        Message.success('更新成功');

        goBackToList(history);
      } else {
        Message.error(response.message);
      }
    } catch (e) {
      setLoading(false);
      // console.log('e :>> ', e);
    }
  };

  const loadDetailData = async () => {
    setLoading(true);
    const response = await fetchPickOrderGet({ pickOrderId: Number(storeRequisitionId)! });

    if (response.code === 200 && response.data) {
      setInitialData(formatMaterialLineToDisplay(response.data));

      console.log(
        'formatMaterialLineToDisplay(response.data)',
        formatMaterialLineToDisplay(response.data),
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isCreate && storeRequisitionId) loadDetailData();
  }, []);

  useEffect(() => {
    editForm.resetFields();
  }, [initialData]);

  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      <DataFormLayout
        form={editForm}
        info={formInfo}
        title={dialogTitle}
        loading={loading}
        extra={
          isCreate && (
            <Checkbox
              onChange={() => {
                setKeepOn(!keepOn);
              }}
              defaultChecked={keepOn}
            >
              连续新建
            </Checkbox>
          )
        }
        onCancel={() => goBackToList(history)}
        onFinish={() => {
          isCreate ? createGeneralStoreRequisition() : updateStoreRequisition();
        }}
        infoBlockStyleProps={{ paddingBottom: 0, borderBottom: 'none' }}
        formProps={{ ...formProps, initialValues: initialData }}
      />
    </div>
  );
}
