import { BlTable, LinkTooltip, TagList, TagTooltip } from 'src/components';
import { ColumnProps } from 'antd/es/table';
import {
  defaultPagination,
  DefaultPaginationType,
  ProductionTabEnum,
  ReportsItemType,
  ReportsType,
} from '../interface';
import TabList from '../share/tabList';
import _Array from 'src/utils/dataTypes/array';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import { toDetailProductionOrder } from 'src/page/productionPlanning/productionOrder/navigation';
import authDict from 'src/utils/auth';
import { FC, useEffect, useState } from 'react';
import { fetchTraceTraceContentInventoryListReports } from 'src/api/ytt/trace-domain/traceContent';

interface ProductionTabProps {
  traceId?: number;
}

interface userProps {
  id: number;
  name: string;
  code: string;
}

const ProductionTab: FC<ProductionTabProps> = ({ traceId }) => {
  const [pagination, setPagination] = useState<DefaultPaginationType>(defaultPagination);

  const [loading, setLoading] = useState(true);

  const [dataSource, setDataSource] = useState<ReportsType>();

  const fetchProductionData = async (params: any) => {
    try {
      if (traceId) {
        setLoading(true);
        const { data } = await fetchTraceTraceContentInventoryListReports({
          traceId,
          page: params?.current ?? 1,
          size: params?.pageSize ?? 10,
        });

        if (data) {
          setDataSource(data);
          setPagination({
            ...pagination,
            current: params?.current ?? 1,
            pageSize: params?.pageSize ?? 10,
            total: data?.total ?? 0,
          });
        }
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductionData(defaultPagination);
  }, [traceId]);

  const baseColumns: ColumnProps<ReportsItemType>[] = [
    {
      title: '序号',
      dataIndex: 'seq',
      width: 100,
      render: (_: any, record: any, index: number) => index + 1,
    },
    {
      title: '工单编码',
      dataIndex: ['workOrder', 'code'],
      width: 200,
      render: (text: string, record: ReportsItemType) => {
        return (
          <LinkTooltip
            to={toDetailProductionOrder(record?.workOrder?.id)}
            text={text}
            width={200}
            auth={authDict.productionorder_detail}
          />
        );
      },
    },
    {
      title: '工序',
      dataIndex: ['process', 'name'],
      width: 200,
    },
    {
      title: '生产人员',
      dataIndex: 'producers',
      width: 200,
      render: (producers: userProps[]) => {
        return <TagTooltip list={producers} isUser max={3} />;
      },
    },
    {
      title: '设备',
      dataIndex: 'equipments',
      width: 200,
      render: (equipments: userProps[]) => {
        if (_Array.isEmpty(equipments)) return replaceSign;
        return <TagList dataSource={equipments} labelPath="name" valuePath="id" />;
      },
    },
    {
      title: '报工人',
      dataIndex: ['reportUser', 'name'],
      width: 200,
    },
    {
      title: '报工操作时间',
      dataIndex: 'operateTime',
      render: (operateTime: Date) => {
        if (!operateTime) return replaceSign;
        return _Time.format(operateTime);
      },
      width: 200,
    },
  ];

  const ReportRecordsComponent = () => {
    return (
      <BlTable
        loading={loading}
        scroll={{ y: 580 }}
        dataSource={dataSource?.list}
        columns={baseColumns}
        rowKey={(record: ReportsItemType) => record?.reportRecordId}
        tableConfigKey={'traceNodeProductionCol'}
        onChange={(pagination: any) => {
          const { current, pageSize } = pagination;

          fetchProductionData({ current, pageSize });
        }}
        pagination={{
          ...pagination,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: () => `共 ${pagination?.total} 条`,
        }}
      />
    );
  };

  const tabList = [
    {
      tab: '报工记录',
      key: ProductionTabEnum.REPORT_RECORDS,
      component: <ReportRecordsComponent />,
    },
  ];

  return <TabList tabList={tabList} />;
};

export default ProductionTab;
