import React, { useState, useRef, CSSProperties } from 'react';
import { Button, Table, TableProps as AntTableProps } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BlTable } from 'src/components';

const type = 'DraggableBodyRow';

export interface TableProps extends AntTableProps<any> {
  index: number;
  dataSource: any[];
  columns: any[];
  className?: string;
  style?: CSSProperties;
  handleDelete?: (rows: any[]) => void;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: TableProps) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor: any) => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

export const DragSortingTable: React.FC<TableProps> = (props) => {
  const { dataSource, columns, moveRow, handleDelete, ...restProps } = props;
  const [selectRows, setSelectRows] = useState<any[]>([]);

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };
  const rowSelection = {
    columnWidth: 40,
    onChange: (selectedRowKeys: React.Key[], selectRows: any[]) => {
      setSelectRows(selectRows);
    },
  };

  return (
    <DndProvider backend={HTML5Backend}>
      {selectRows.length && handleDelete ? (
        <div>
          <span style={{ marginRight: 16 }}>已选: {selectRows.length}条</span>
          <Button
            onClick={() => {
              handleDelete(selectRows);
              setSelectRows([]);
            }}
          >
            删除
          </Button>
        </div>
      ) : null}
      <BlTable
        columns={columns}
        dataSource={dataSource}
        components={components}
        scroll={{ x: 'max-content' }}
        rowKey={(record) => record.checkItem.value}
        rowSelection={rowSelection}
        selectedRowKeys={selectRows.map((i) => i.id)}
        onRow={(record: any, index: number) => ({
          index,
          moveRow,
        })}
        {...restProps}
      />
    </DndProvider>
  );
};
