/**
 * 自定义业务类型列表
 */

import { useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toNumber } from 'lodash';
import { message } from 'antd';
import { RecordListLayout } from 'src/layout';
import lookup from 'src/dict';
import { UsageStatus, ObjectCategory } from 'src/dict/common';
import authDict from 'src/utils/auth';
import { qs } from 'src/utils';
import renderUsageStatus from 'src/page/share/renderUsageStatus';
import {
  fetchBizTypeListPage,
  fetchBizTypeEnable,
  fetchBizTypeDisable,
  fetchBizTypeDelete,
} from 'ytt/metadata-domain/bizType';
import SortModal from './modals/sort';
import MatchModal from './modals/match';
import type { OperationListItem } from '@blacklake-web/layout';
import type { BizListItem } from './types';

const placeholder = '请输入业务类型名称';
const columns = [
  {
    title: '业务类型名称',
    dataIndex: 'name',
    width: 300,
  },
  {
    title: '备注',
    dataIndex: 'remark',
    width: 300,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 150,
    render: renderUsageStatus,
  },
  {
    title: '是否预置',
    dataIndex: 'isPreset',
    width: 120,
    render: lookup('yn'),
  },
];

const BizTypeList = () => {
  const history = useHistory();
  const [refreshMarker, refresh] = useReducer(() => Date.now(), 0);
  const [sortModalVisible, setSortModalVisible] = useState(false);
  const [matchModalVisible, setMatchModalVisible] = useState(false);

  const { objectCode, objectCategory: objectCategoryStr } = qs.parse();
  const objectCategory = toNumber(objectCategoryStr);
  const isCustomObject = objectCategory === ObjectCategory.customObject;

  const sortMenuItem = {
    title: '业务类型排序',
    icon: 'icona-xianxingpaixu',
    auth: authDict.businessType_edit,
    onClick: () => setSortModalVisible(true),
  };
  const mainMenu = isCustomObject
    ? [
        {
          title: '匹配业务类型关系',
          icon: 'icona-xianxingpipei',
          auth: authDict.businessType_edit,
          onClick: () => setMatchModalVisible(true),
        },
        sortMenuItem,
        {
          title: '新建业务类型',
          icon: 'iconxinjiantianjia',
          auth: authDict.businessType_add,
          onClick: () => {
            history.push(`${location.pathname}/createBizType${location.search}`);
          },
          items: [],
        },
      ]
    : [sortMenuItem];

  const getOperationList = (record: BizListItem): OperationListItem[] => {
    const { id, status, isPreset } = record;
    const changeStatusAction = lookup('common.changeStatusAction', status);

    return isCustomObject
      ? [
          {
            title: '编辑',
            auth: authDict.businessType_edit,
            onClick: () => history.push(`${location.pathname}/editBizType/${id}${location.search}`),
          },
          {
            title: changeStatusAction,
            auth: authDict.businessType_enable_disable,
            disabled: !!isPreset,
            onClick: () => {
              (status === UsageStatus.enabled
                ? fetchBizTypeDisable({ id })
                : fetchBizTypeEnable({ id })
              ).then(() => {
                message.success(`${changeStatusAction}成功！`);
                refresh();
              });
            },
          },
          {
            title: '删除',
            auth: authDict.businessType_remove,
            disabled: !!isPreset || status === UsageStatus.enabled,
            reasonconfirm: { message: '删除后将无法恢复，确认删除？' },
            onClick: (reason: string) =>
              // @ts-ignore
              fetchBizTypeDelete({ id, reason }).then(() => {
                message.success('删除完成。');
                refresh();
              }),
          },
        ]
      : [];
  };

  return (
    <>
      <RecordListLayout
        columns={columns}
        getOperationList={getOperationList}
        mainMenu={mainMenu}
        placeholder={placeholder}
        formatDataToQuery={(params) => ({
          ...params,
          status: [UsageStatus.disabled, UsageStatus.enabled],
          objectCode,
          name: params.quickSearch || undefined,
        })}
        requestFn={fetchBizTypeListPage}
        refreshMarker={refreshMarker}
        maxOperationCount={3}
        configcacheKey="custom-biz-type"
        rowKey="id"
      />
      <SortModal
        visible={sortModalVisible}
        hide={() => setSortModalVisible(false)}
        refreshList={refresh}
      />
      <MatchModal visible={matchModalVisible} hide={() => setMatchModalVisible(false)} />
    </>
  );
};

export default BizTypeList;
export * from './edit';
