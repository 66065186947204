import { useState, ReactElement, useEffect } from 'react';
import { Button, DatePicker, Form, message } from 'antd';
import moment from 'moment';
import { ColumnProps } from 'antd/es/table';
import type { RangePickerProps } from 'antd/lib/date-picker';
import { BlIcon, LinkTooltip, showErrorListMessage, TagList } from 'src/components';
import { RecordListLayout, DetailLayoutForModal } from 'src/layout';
import { fieldTypeList, gcTime } from 'src/utils';
import lookup, { appEnum } from 'src/dict';
import { FetchPlanOrderBatchCloseResponse } from 'src/api/ytt/plo-domain';
import BcBlCascader from 'src/components/Cascader';
import { getMaterialAttrs } from 'src/entity/material';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import {
  fetchPlanOperationCompute,
  fetchPlanOperationLastCalDate,
  fetchPlanOperationMaterialDeleted,
  fetchPlanOperationMaterialList,
  fetchPlanOperationSchemeGet,
  FetchPlanOperationSchemeGetResponse,
} from 'src/api/ytt/plo-domain/plannOrderOperation';
import BcBlMultiCascader from 'src/page/knowledgeManagement/materialModeling/material/share/Cascader/BcBlMultiCascader';
import _Time from 'src/utils/dataTypes/time';
import { replaceSign } from 'src/utils/constants';
import { arrayIsEmpty } from 'src/utils/array';

import { toLogPlanningOperation } from '../navigation';
import PlanCalculationSchemeDetail from '../planCalculationScheme/detail';
import PlanCalculationMaterial from '../planCalculationMaterial/index';

const MAX_DAY = 120;

/**
 * 处理批量操作提示message
 * @param action
 * @param info
 */
export const handleBatchMessage = (
  action: string,
  info: FetchPlanOrderBatchCloseResponse['data'],
) => {
  const { failAmount = 0, successAmount = 0, failResults = [] } = info ?? {};

  if (failAmount) {
    showErrorListMessage({
      failCount: failAmount,
      successCount: successAmount,
      title: `${action}出现失败`,
      data: failResults,
      columns: [
        // {
        //   title: '计划订单编号',
        //   dataIndex: 'planOrderCode',
        //   width: 100,
        //   render: (text: string, record: any) => (
        //     <a onClick={() => window.open(toDetailPlannedOrder(record.planOrderId))}>
        //       <Tooltip text={text} width={150} />
        //     </a>
        //   ),
        // },
        { title: '失败原因', dataIndex: 'failedReason', width: 200 },
        { title: '', dataIndex: 'planOrderId', width: 1, render: () => null },
      ],
    });
  } else {
    message.success(`${action}成功！`);
  }
};

const PlanOperationList = (props: { history: any }) => {
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [planOperationSchemeDisplay, setPlanOperationSchemeDisplay] = useState<any>(false);
  const [planCalculationMaterialDisplay, setPlanCalculationMaterialDisplay] = useState<any>(false);
  const [lastCalculationTime, setlastCalculationTime] = useState<any>();
  const [planOperationScheme, setPlanOperationScheme] =
    useState<FetchPlanOperationSchemeGetResponse['data']>(); // 计划运算方案详情

  const openModal = useOpenExImportModal();

  const { history } = props;

  const [planOperationForm] = Form.useForm();

  const refreshData = () => {
    setRefreshMarker(Math.random());
  };

  const getLastCalDate = () => {
    fetchPlanOperationLastCalDate()
      .then((res) => {
        setlastCalculationTime(res.data);
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  };

  const getPlanOperationScheme = () => {
    fetchPlanOperationSchemeGet()
      .then((res) => {
        const { data } = res;

        const POEndTime = moment(
          moment().add(data?.expectationDate, 'days').format('YYYY-MM-DD 23:59:59'),
        );

        planOperationForm.setFields([
          {
            name: 'deadlineTime',
            value: POEndTime,
          },
        ]);
        setPlanOperationScheme(data);
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  };

  useEffect(() => {
    refreshData();
    getLastCalDate();
    getPlanOperationScheme();
  }, [history, props]);

  const mainMenu = [
    {
      title: '添加物料',
      auth: authDict.MaterialPlanConfigura_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        setPlanCalculationMaterialDisplay({
          type: appEnum.Common.CRUD.create,
          id: undefined,
        });
      },
      items: [
        {
          title: '导入',
          showExport: true,
          auth: authDict.MaterialPlanConfigura_import,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.planningOperationr,
            });
          },
        },
      ],
    },
  ];

  // PlanOrderResponseList
  const columns: (ColumnProps<any> & {
    filterIndex?: string;
    type?: number;
    isFilter?: boolean;
    selectProps?: any;
    renderItem?: ReactElement;
    dateFormat?: any;
  })[] = [
    {
      title: '物料编号',
      dataIndex: ['material', 'baseInfo', 'code'],
      width: 150,
    },
    {
      title: '物料名称',
      dataIndex: ['material', 'baseInfo', 'name'],
      width: 150,
    },
    {
      title: '物料规格',
      dataIndex: ['material', 'baseInfo', 'specification'],
      width: 150,
    },
    {
      title: '物料属性',
      dataIndex: ['material', 'attribute'],
      type: fieldTypeList.text,
      renderItem: <BcBlMultiCascader type={'materialProperty'} nameLabel={'物料属性'} />,
      width: 150,
      render: (_text: any, record: any) => (
        <TagList dataSource={getMaterialAttrs(record.material)} />
      ),
    },
    {
      title: '物料分类',
      dataIndex: ['material', 'category', 'name'],
      type: fieldTypeList.text,
      renderItem: (
        <BcBlCascader
          fetchType={'materialCategory'}
          nameLabel={'物料分类'}
          popupPlacement={'bottomRight'}
        />
      ),
      width: 150,
    },
    {
      title: '业务范围',
      dataIndex: ['material', 'baseInfo', 'bizRange'],
      width: 160,
      render: (text: number[]) => {
        if (!arrayIsEmpty(text)) {
          const arr = text.map((item: number) => {
            return lookup('material.bizRange', item);
          });

          return arr.join('、');
        }
        return replaceSign;
      },
    },
    {
      title: '计划属性',
      dataIndex: 'planAttribute',
      width: 130,
      render: (text: any) => lookup('planned.PlanAttributeDisplayMap', text),
    },
    {
      title: '不同需求来源',
      dataIndex: 'differentRequirementSources',
      width: 150,
      render: (text: any) => lookup('planned.DiffRequSourcesDisplayMap', text),
    },
    {
      title: '提前期单位',
      dataIndex: 'leadTimeUnit',
      width: 150,
      render: (text: any) => lookup('planned.leadTimeUnitDisplayMap', text),
    },
    {
      title: '固定提前期',
      dataIndex: 'leadTime',
      width: 150,
    },
    {
      title: '偏置时间单位',
      dataIndex: 'offsetTimeUnit',
      width: 150,
      render: (text: any) => lookup('planned.leadTimeUnitDisplayMap', text),
    },
    {
      title: '备料偏置时间',
      dataIndex: 'offsetTime',
      width: 150,
    },
  ];

  const getOperationList = (
    record: any,
  ): {
    title: string;
    auth?: string;
    onClick: () => void;
    disable?: boolean;
  }[] => {
    const { id } = record;

    const list = [
      {
        title: '调整配置',
        auth: authDict.MaterialPlanConfigura_edit,
        onClick: () => {
          setPlanCalculationMaterialDisplay({
            type: appEnum.Common.CRUD.edit,
            id,
            data: record,
          });
        },
      },
      {
        title: '移除',
        auth: authDict.MaterialPlanConfigura_remove,
        onClick: () => {
          fetchPlanOperationMaterialDeleted({ id }).then((res) => {
            const { code = 0 } = res ?? {};

            refreshData();

            if (code === 200) message.success('移除成功!');
          });
        },
      },
      // {
      //   title: '操作记录',
      //   auth: authDict.planOrder_operrecord,
      //   onClick: () => {
      //     setDataSetId(record?.planOrderId);
      //     setOperationRecordVisible(true);
      //   },
      // },
    ];

    return list;
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current > moment().add(MAX_DAY, 'days');
  };

  return (
    <div
      style={{ height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}
    >
      <Form style={{ padding: 16 }} form={planOperationForm} layout="inline">
        {/* 详情 + 编辑 */}
        <Form.Item name="plan" label="计划运算方案">
          <a
            onClick={() => {
              setPlanOperationSchemeDisplay(true);
            }}
          >
            {planOperationScheme?.code}
          </a>
          {/* planCalculationScheme */}
        </Form.Item>

        {/* 运行调用接口 */}
        <Form.Item name="deadlineTime" label="计划运算结束时间">
          <DatePicker
            // disabledDate={disabledDate}
            format={'YYYY-MM-DD HH:mm:ss'}
            placeholder={'请输入'}
            showTime
            disabledDate={disabledDate}
          />
        </Form.Item>

        {hasAuth(authDict.MaterialPlanConfigura_planning_operation) && (
          <Button
            style={{ marginLeft: 10, marginRight: 10 }}
            type="primary"
            onClick={() => {
              const deadlineTime = planOperationForm.getFieldValue('deadlineTime');

              const params = {
                deadlineTime: deadlineTime ? Number(gcTime.formatToUnix(deadlineTime)) : 0,
                planOperationSchemeId: planOperationScheme?.id ?? 0,
              };

              fetchPlanOperationCompute(params)
                .then(() => {
                  getLastCalDate();
                  refreshData();
                })
                .catch((err) => {
                  console.log('err: ', err);
                });
            }}
          >
            运行
          </Button>
        )}

        <Form.Item name="lastTime" label="上次运行时间">
          <span>{lastCalculationTime ? _Time.format(lastCalculationTime) : replaceSign}</span>
        </Form.Item>
        {/* log */}

        {hasAuth(authDict.MaterialPlanConfigura_operrecord) && (
          <Form.Item name="log">
            <a onClick={() => history.push(toLogPlanningOperation())}>计划运算日志</a>
          </Form.Item>
        )}
      </Form>
      <RecordListLayout<any>
        configcacheKey="planOperationList"
        // filterList={getFilterList()}
        filterList={undefined}
        requestFn={fetchPlanOperationMaterialList}
        mainMenu={mainMenu}
        batchMenu={undefined}
        useQuickFilter={false}
        // placeholder={'请输入计划订单编码'} // 快速搜索input placeholder
        // formatDataToQuery={formatDataToQuery} // 转换搜索条件进行查询（快速查询字段："quickSearch",当前页："page",分页大小："size"）
        // formatDataToDisplay={formatDataToDisplay} // 转换搜索条件进行展示
        columns={columns} // table columns
        // selectedRowKeys={selectedRowKeys}
        refreshMarker={refreshMarker}
        rowKey="planOrderId"
        // onSelectedRowKeys={(selectKey, selectRows) => {
        //   setSelectedRowKeys(selectKey);
        //   setSelectedRows(selectRows || []);
        // }}
        pagination={{ pageSizeOptions: ['10', '20'] }}
        userAuth={getAuthFromLocalStorage()}
        getOperationList={getOperationList}
      />

      {/* 操作记录 */}
      {/* {operationRecordVisible && (
        <SingleOperationLogComponent
          visible={operationRecordVisible}
          instanceId={datasetId}
          objectCode={'PlanOrder'}
          closeDetail={() => {
            setOperationRecordVisible(false);
          }}
        />
      )} */}

      <DetailLayoutForModal
        visible={planOperationSchemeDisplay}
        onClose={() => {
          setPlanOperationSchemeDisplay(undefined);
        }}
        content={
          <PlanCalculationSchemeDetail
            dataSource={planOperationScheme}
            onChange={() => {
              // 再次请求数据
              getPlanOperationScheme();
              setPlanOperationSchemeDisplay(undefined);
              refreshData();
            }}
          />
        }
      />
      <DetailLayoutForModal
        visible={planCalculationMaterialDisplay}
        onClose={() => {
          setPlanCalculationMaterialDisplay(undefined);
        }}
        content={
          <PlanCalculationMaterial
            onChange={() => {
              setPlanCalculationMaterialDisplay(undefined);
              refreshData();
            }}
            params={planCalculationMaterialDisplay}
          />
        }
      />
    </div>
  );
};

export default PlanOperationList;
