import React, { useState, useEffect } from 'react';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import { replaceSign } from 'src/utils/constants';
import _Array from 'src/utils/dataTypes/array';
import { message as Message, Popover, Space } from 'antd';
import _Time from 'src/utils/dataTypes/time';
import {
  fetchProduceTaskCancel,
  fetchProduceTaskDetail,
  fetchProduceTaskListPauseRecord,
} from 'src/api/ytt/mfg-domain/productionTask';
import { TASK_STATUS_MAP, TASK_TYPE_TYPE } from './constant';
import _, { forEach, isEmpty, map } from 'lodash';
import { match, RouteComponentProps } from 'react-router-dom';
import TagList from 'src/components/tagList';
import { renderDepartmentArray, renderUserArray } from 'src/page/share/renderUser';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { BlIcon, QrCode } from 'src/components';
import MaterialTable from './components/MaterialTable';
import PauseRecord from './components/PauseRecord';

interface DetailBaseInfoProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

interface userProps {
  id: number;
  name: string;
  code: string;
}

export default function ProductionDetail(props: DetailBaseInfoProps) {
  const { match, history } = props;
  const taskId = Number(match?.params?.id);
  const [dataSource, setDataSource] = useState({} as any);
  const userAuth = getAuthFromLocalStorage();
  const hasEditPermission = userAuth.indexOf(authDict.producetask_edit) >= 0;
  const orderStatusCouldEdit =
    dataSource?.taskStatus === TASK_STATUS_MAP.TO_PERFORM ||
    dataSource?.taskStatus === TASK_STATUS_MAP.EXECUTION ||
    dataSource?.taskStatus === TASK_STATUS_MAP.PAUSE;

  const fetchData = async () => {
    try {
      const { data } = await fetchProduceTaskDetail({ taskId });

      fetchProduceTaskListPauseRecord({ taskId });

      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [taskId]);

  const baseInfo: DetailLayoutInfoBlock[] = [
    {
      title: '基本信息',
      items: [
        {
          label: '任务编号',
          dataIndex: 'taskCode',
          render: (taskCode: string) => taskCode || replaceSign,
        },
        {
          label: '状态',
          dataIndex: 'taskStatus',
          render: (taskStatus: number) => TASK_TYPE_TYPE.get(taskStatus) || replaceSign,
        },
        {
          label: '任务物料编号',
          dataIndex: ['taskOutputMaterialList', 0, 'baseInfo', 'code'],
        },
        {
          label: '任务物料名称',
          dataIndex: ['taskOutputMaterialList', 0, 'baseInfo', 'name'],
        },
        {
          label: '任务物料规格',
          dataIndex: ['taskOutputMaterialList', 0, 'baseInfo'],
          width: 150,
          render: (name: string) => name?.specification || replaceSign,
        },
        {
          label: '任务物料属性',
          dataIndex: ['taskOutputMaterialList', 0],
          width: 150,
          render: (_text: any) => {
            const options: {
              label: string;
              value: string | number;
            }[] = [];

            if (_text && !isEmpty(_text)) {
              const { attribute } = _text;

              forEach(attribute, (item) => {
                if (item?.id) {
                  options.push({
                    label: `${item.name}:${item.content}`,
                    value: item?.id,
                  });
                }
              });
            }

            return <TagList dataSource={options} />;
          },
        },
        {
          label: '工序编号',
          dataIndex: 'relatedProcessCode',
          render: (relatedProcessCode: string) => relatedProcessCode || replaceSign,
        },
        {
          label: '工序名称',
          dataIndex: 'relatedProcessName',
          render: (relatedProcessName: string) => relatedProcessName || replaceSign,
        },
        {
          label: '标识码',
          dataIndex: 'taskIdentifyCode',
          render: (text: string) => {
            return text ? (
              <Space>
                {text}
                <Popover
                  placement="rightTop"
                  content={
                    <div>
                      <QrCode value={text} />
                      <div style={{ textAlign: 'center' }}>{text}</div>
                    </div>
                  }
                >
                  <BlIcon type="iconerweima" style={{ fontSize: 20 }} />
                </Popover>
              </Space>
            ) : (
              replaceSign
            );
          },
        },
        {
          label: '工作中心',
          dataIndex: ['workCenterVO', 'name'],
          render: (name: string) => name || replaceSign,
        },
        {
          label: '执行人',
          dataIndex: 'executors',
          render: (executors: userProps[]) => {
            if (_Array.isEmpty(executors)) return replaceSign;
            return renderUserArray(executors);
          },
        },
        {
          label: '生产部门',
          dataIndex: 'departments',
          render: (departments: userProps[]) => {
            if (_Array.isEmpty(departments)) return replaceSign;
            return renderDepartmentArray(departments);
          },
        },
        {
          label: '设备',
          dataIndex: 'equipments',
          render: (equipments: userProps[]) => {
            if (_Array.isEmpty(equipments)) return replaceSign;
            return <TagList dataSource={equipments} labelPath="name" valuePath="id" />;
          },
        },
        {
          label: '计划开始时间',
          dataIndex: 'planStartTime',
          render: (planStartTime: Date) => {
            if (!planStartTime) return replaceSign;
            return _Time.format(planStartTime);
          },
        },
        {
          label: '计划结束时间',
          dataIndex: 'planEndTime',
          render: (planEndTime: Date) => {
            if (!planEndTime) return replaceSign;
            return _Time.format(planEndTime);
          },
        },
        {
          label: '工单编号',
          dataIndex: 'relatedWorkOrderCode',
          render: (relatedWorkOrderCode: string) => relatedWorkOrderCode || replaceSign,
        },
        {
          label: '工单物料编号',
          dataIndex: 'workOrderOutputMaterialList',
          render: (workOrderOutputMaterialList: any) =>
            map(workOrderOutputMaterialList, (item) => {
              return item?.baseInfo?.code;
            }).join(',') || replaceSign,
        },
        {
          label: '工单物料名称',
          dataIndex: 'workOrderOutputMaterialList',
          render: (workOrderOutputMaterialList: any) =>
            map(workOrderOutputMaterialList, (item) => {
              return item?.baseInfo?.name;
            }).join(',') || replaceSign,
        },
        {
          label: '订单编号',
          dataIndex: 'salesOrderList',
          render: (salesOrderList: userProps[]) => {
            if (_Array.isEmpty(salesOrderList)) return replaceSign;
            return <TagList dataSource={salesOrderList} labelPath="code" valuePath="id" />;
          },
        },
        {
          label: '备注',
          dataIndex: 'remark',
          render: (taskCode: string) => taskCode || replaceSign,
        },
      ],
      column: 2,
    },
    {
      title: '物料',
      items: [
        {
          dataIndex: 'taskReportableMaterialList',
          render: (taskReportableMaterialList: any) => {
            return (
              <MaterialTable
                taskReportableMaterialList={taskReportableMaterialList}
                refreshData={fetchData}
                taskId={taskId}
                hasEditPermission={hasEditPermission && orderStatusCouldEdit}
              />
            );
          },
        },
      ],
    },
    {
      title: '暂停记录',
      items: [
        {
          dataIndex: 'taskReportableMaterialList',
          render: (taskReportableMaterialList: any) => {
            return <PauseRecord taskId={taskId} />;
          },
        },
      ],
    },
  ];

  const baseMenu = _.compact([
    dataSource?.taskStatus === TASK_STATUS_MAP.TO_PERFORM
      ? {
          key: 'cancel',
          auth: authDict.producetask_cancel,
          onClick: async () => {
            try {
              const { code } = await fetchProduceTaskCancel({
                taskIdList: [dataSource?.taskId],
              });

              if (code === 200) {
                Message.success('取消成功');
                fetchData?.();
                return;
              }
            } catch (error) {
              console.log('error: ', error);
            }
          },
          title: '取消',
        }
      : undefined,
    orderStatusCouldEdit
      ? {
          key: 'edit',
          auth: authDict.producetask_edit,
          onClick: () => {
            history.push(`/productionPlanning/execution/productionTaskList/${taskId}/edit`);
          },
          title: '编辑',
        }
      : undefined,
  ]);

  return (
    <DetailLayout
      title="生产任务详情"
      info={baseInfo}
      dataSource={dataSource}
      baseMenu={baseMenu}
    />
  );
}
