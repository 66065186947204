import { useState } from 'react';
import { Form, Input, InputNumber } from 'antd';
import { findIndex, findLastIndex, isNil, find, debounce } from 'lodash';

import { LabeledValue } from 'antd/lib/select';
import { ColumnProps } from 'antd/es/table';
import { FormListFieldData } from 'antd/lib/form/FormList';

import { DataFormLayout } from 'src/layout';
import { BlSortFormList, SearchSelect } from 'src/components';
import { lookup } from 'src/dict';
import { UsageStatus } from 'src/dict/common';
import { ResourceBusinessType, ParmTypeEnum } from 'src/dict/resources';
import { numberLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import BatchParamSelector from 'src/page/resource/definitions/components/BatchParamSelector';

const MAX_PARAMS_COUNT = 100;

export default function EquipParamsFormList({
  onChange,
  params,
  name,
}: {
  onChange: (value?: any[]) => void;
  params: any;
  name: string;
}) {
  const [paramSelectorVisible, setParamSelectorVisible] = useState(false);

  const baseFiledName: any = [name];

  const [form] = Form.useForm();

  const formatResourceParamToFormList = (resourceParamFromListApi: any) => {
    const { id, code, name, paramsType, unitName } = resourceParamFromListApi;
    const formattedParamFields = {
      paramsId: id,
      paramsName: name,
      paramsCode: code,
      paramsType,
      unitName,
      id,
    };

    return {
      ...formattedParamFields,
      param: {
        label: formattedParamFields.paramsName,
        // 把formlist联动/比对需要用到的字段打包进json
        value: JSON.stringify(formattedParamFields),
        key: formattedParamFields.paramsId,
      },
      // ...rest,
    };
  };

  const handleSelectParamFinish = (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    const originParams = form.getFieldValue(baseFiledName) || [];

    // 去重，只有原来不存在的新行才可以被加入
    const newRows = selectedRows
      .filter((row) => isNil(find(originParams, { paramsId: row.id, id: row.id })))
      .map(formatResourceParamToFormList);
    const fullRows = [...(originParams ?? []), ...newRows];

    form.setFields([
      {
        name: baseFiledName,
        value: fullRows,
      },
    ]);

    setParamSelectorVisible(false);
  };
  /**
   * 校验设备标签/设备参数,不能重复
   */
  const validateDuplicated =
    (listField: string, field: string, idField: string, message: string) =>
    (rule: any, value: LabeledValue) => {
      if (!value?.key) return Promise.resolve('');
      const valueId = JSON.parse(value.value as string)[idField];
      const formList = form.getFieldValue(listField);

      if (!formList?.length) return Promise.resolve('');
      const objList = formList?.map((formItem: any) => JSON.parse(formItem[field]?.value));
      const firstIndex = findIndex(objList, { [idField]: valueId });
      const lastIndex = findLastIndex(objList, { [idField]: valueId });

      if (firstIndex !== lastIndex) {
        return Promise.reject(message);
      }

      return Promise.resolve('');
    };

  const getParamColumns: (
    remove: (index: number | number[]) => void,
  ) => ColumnProps<any & FormListFieldData>[] = () => [
    {
      title: '设备参数',
      width: 180,
      render: (text, field) => (
        <Form.Item
          name={[field.name, 'param']}
          style={{ marginBottom: 0 }}
          rules={[
            {
              validator: validateDuplicated(
                baseFiledName,
                'param',
                'paramsId',
                '不能选择重复的设备参数',
              ),
            },
            {
              required: true,
              message: '已添加的参数必填',
            },
          ]}
        >
          <SearchSelect
            placeholder="请选择设备参数"
            fetchType="resourceParams"
            params={{
              businessType: ResourceBusinessType.equipment,
              status: UsageStatus.enabled,
              paramsTypeList: [ParmTypeEnum.actualTime, ParmTypeEnum.accumulate],
            }}
            onChange={(selectedLabeledValue: LabeledValue) => {
              const paramString = selectedLabeledValue?.value;
              const paramValueObj = paramString && {
                ...JSON.parse(paramString as string),
                param: selectedLabeledValue,
              };
              const params: any[] = form.getFieldValue(baseFiledName);

              form.setFieldsValue({
                baseFiledName: params?.map((param, index: number) => {
                  return index === field.name ? paramValueObj : param;
                }),
              });
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: '参数类型',
      width: 140,
      render: (text, field) => {
        return (
          <Form.Item dependencies={[[...baseFiledName, field.name, 'param']]} noStyle>
            {() => {
              const paramString = form.getFieldValue([...baseFiledName, field.name, 'param']);
              const paramValue = paramString && JSON.parse(paramString.value);

              return (
                <Input disabled value={lookup('resources.paramType', paramValue?.paramsType)} />
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '参数单位',
      width: 140,
      render: (text, field) => {
        return (
          <Form.Item dependencies={[[...baseFiledName, field.name, 'param']]} noStyle>
            {() => {
              const paramString = form.getFieldValue([...baseFiledName, field.name, 'param']);
              const paramValue = paramString && JSON.parse(paramString.value);

              return <Input disabled value={paramValue?.unitName} />;
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '参数标准值',
      width: 120,
      render: (text, field) => {
        return (
          <Form.Item dependencies={[[...baseFiledName, field.name, 'param']]} noStyle>
            {() => {
              const paramString = form.getFieldValue([...baseFiledName, field.name, 'param']);
              const paramValue = paramString && JSON.parse(paramString.value);
              const disabled = paramValue?.paramsType === ParmTypeEnum.text;

              return (
                <Form.Item
                  //   getValueFromEvent={getNumberFromInput}
                  name={[field.name, 'paramsStandard']}
                  style={{ marginBottom: 0 }}
                  rules={[{ validator: numberLengthCheck(9, 6) }]}
                >
                  <InputNumber disabled={disabled} />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '参数上限',
      width: 120,
      render: (text, field) => {
        return (
          <Form.Item
            dependencies={[
              [...baseFiledName, field.name, 'param'],
              [...baseFiledName, field.name, 'paramsStandard'],
            ]}
            noStyle
          >
            {() => {
              const paramString = form.getFieldValue([...baseFiledName, field.name, 'param']);
              const paramValue = paramString && JSON.parse(paramString.value);
              const disabled = paramValue?.paramsType === ParmTypeEnum.text;
              const standard = form.getFieldValue([...baseFiledName, field.name, 'paramsStandard']);

              return (
                <Form.Item
                  name={[field.name, 'paramsUpperLimit']}
                  style={{ marginBottom: 0 }}
                  rules={[
                    { validator: numberLengthCheck(9, 6) },
                    {
                      validator: numberMinMaxCheck({
                        min: standard,
                        minAllowEqual: false,
                        message: '参数上限必须大于标准值',
                      }),
                    },
                  ]}
                >
                  <InputNumber disabled={disabled} />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '参数下限',
      width: 120,
      render: (text, field) => {
        return (
          <Form.Item
            dependencies={[
              [...baseFiledName, field.name, 'param'],
              [...baseFiledName, field.name, 'paramsStandard'],
            ]}
            noStyle
          >
            {() => {
              const paramString = form.getFieldValue([...baseFiledName, field.name, 'param']);
              const paramValue = paramString && JSON.parse(paramString.value);
              const disabled = paramValue?.paramsType === ParmTypeEnum.text;
              const standard = form.getFieldValue([...baseFiledName, field.name, 'paramsStandard']);

              return (
                <Form.Item
                  name={[field.name, 'paramsLowerLimit']}
                  style={{ marginBottom: 0 }}
                  rules={[
                    { validator: numberLengthCheck(9, 6) },
                    {
                      validator: numberMinMaxCheck({
                        max: standard,
                        maxAllowEqual: false,
                        message: '参数下限必须小于标准值',
                      }),
                    },
                  ]}
                >
                  <InputNumber disabled={disabled} />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '参数上上限',
      width: 120,
      render: (text, field) => {
        return (
          <Form.Item
            dependencies={[
              [...baseFiledName, field.name, 'param'],
              [...baseFiledName, field.name, 'paramsUpperLimit'],
            ]}
            noStyle
          >
            {() => {
              const paramString = form.getFieldValue([...baseFiledName, field.name, 'param']);
              const paramValue = paramString && JSON.parse(paramString.value);
              const disabled = paramValue?.paramsType === ParmTypeEnum.text;
              const upperLimit = form.getFieldValue([
                ...baseFiledName,
                field.name,
                'paramsUpperLimit',
              ]);

              return (
                <Form.Item
                  name={[field.name, 'paramsTopLimit']}
                  style={{ marginBottom: 0 }}
                  rules={[
                    { validator: numberLengthCheck(9, 6) },
                    {
                      validator: numberMinMaxCheck({
                        min: upperLimit,
                        minAllowEqual: false,
                        message: '参数上上限必须大于参数上限',
                      }),
                    },
                  ]}
                >
                  <InputNumber disabled={disabled} />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '参数下下限',
      width: 120,
      render: (text, field) => {
        return (
          <Form.Item
            dependencies={[
              [...baseFiledName, field.name, 'param'],
              [...baseFiledName, field.name, 'paramsLowerLimit'],
            ]}
            noStyle
          >
            {() => {
              const paramString = form.getFieldValue([...baseFiledName, field.name, 'param']);
              const paramValue = paramString && JSON.parse(paramString.value);
              const disabled = paramValue?.paramsType === ParmTypeEnum.text;
              const lowerLimit = form.getFieldValue([
                ...baseFiledName,
                field.name,
                'paramsLowerLimit',
              ]);

              return (
                <Form.Item
                  name={[field.name, 'paramsFloorLimit']}
                  style={{ marginBottom: 0 }}
                  rules={[
                    { validator: numberLengthCheck(9, 6) },
                    {
                      validator: numberMinMaxCheck({
                        max: lowerLimit,
                        maxAllowEqual: false,
                        message: '参数下下限必须小于参数下限',
                      }),
                    },
                  ]}
                >
                  <InputNumber disabled={disabled} />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
  ];

  const baseInfo = {
    items: [
      {
        isFullLine: true,
        render: () => (
          <BlSortFormList
            name={baseFiledName}
            maxCount={MAX_PARAMS_COUNT}
            listRules={[
              {
                validator: async (_param, names) => {
                  if (names?.length > MAX_PARAMS_COUNT) {
                    return Promise.reject(new Error(`最多只能选择${MAX_PARAMS_COUNT}个参数`));
                  }
                  return Promise.resolve();
                },
              },
            ]}
            isNeedDrag={false}
            renderColumns={getParamColumns}
            extraButton={[
              {
                title: '批量添加',
                onClick: () => {
                  setParamSelectorVisible(true);
                },
              },
            ]}
            form={form}
          />
        ),
      },
    ],
  };

  return (
    <>
      <DataFormLayout
        form={form}
        info={[baseInfo]}
        infoBlockStyleProps={{ marginTop: -24 }}
        title="设置工艺参数"
        onCancel={() => {
          onChange();
        }}
        onFinish={debounce(() => {
          form
            .validateFields()
            .then((values) => {
              onChange(values?.resourceParamsList);
            })
            .catch((error) => {
              console.log('error: ', error);
            });
        }, 1500)}
        formProps={{
          preserve: true,
          initialValues: { [name]: params?.[name] },
        }}
      />

      <BatchParamSelector
        visible={paramSelectorVisible}
        onCancel={() => {
          setParamSelectorVisible(false);
        }}
        onFinish={handleSelectParamFinish}
        maxCount={100}
        originCount={form?.getFieldValue(baseFiledName)?.length}
        defaultQueryConditions={{
          businessType: ResourceBusinessType.equipment,
          status: UsageStatus.enabled,
          paramsTypeList: [ParmTypeEnum.actualTime, ParmTypeEnum.accumulate],
        }}
      />
    </>
  );
}
