import { useEffect, useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { fetchOrganizationGetDisplayInfo } from 'src/api/ytt/user-domain';
import { TextToolTip } from '@blacklake-web/component';
import {
  fetchOrganizationUpdateDisplayInfo,
  FetchOrganizationUpdateDisplayInfoRequest,
} from 'src/api/ytt/user-domain/organization';
import { useDispatch } from 'react-redux';
import { fetchFileDetail } from 'src/api/ytt/holyfile-domain';
import { BcUpload, BlIcon } from 'src/components';
import styles from './styles.module.scss';

const LOGO_MAX_SIZE = 2;

const AccountManagement = () => {
  const dispatch = useDispatch();
  const [modalForm] = Form.useForm();
  const [logoUrl, setLogoUrl] = useState<string | undefined>('');
  const [displayName, setDisplayName] = useState<string | undefined>('黑湖智造');
  const [logoId, setLogoId] = useState<number | undefined>(undefined);
  const fetchInfoData = async () => {
    try {
      const { data } = await fetchOrganizationGetDisplayInfo();

      setDisplayName(data?.displayName);
      setLogoId(data?.logoId);
      modalForm.setFieldsValue({ name: data?.displayName, logo: data?.logoId });
      if (data?.logoId) {
        const res = await fetchFileDetail({ id: data?.logoId });

        setLogoUrl(res.data?.fileUri);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onCancel = () => {
    fetchInfoData();
    setLogoUrl('');
  };

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();

      const data: FetchOrganizationUpdateDisplayInfoRequest = {
        displayName: value.name ? value.name : displayName,
        logoId: value.logo,
      };

      await fetchOrganizationUpdateDisplayInfo({
        displayName: data.displayName,
        logoId: data.logoId,
      });
      message.success('修改成功');

      dispatch({
        type: 'logoDisplay/fetchInfoData',
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchInfoData();
  }, []);

  const baseInfo: DataFormLayoutInfoBlock = {
    title: '租户信息',
    align: 'left',
    column: 1,
    items: [
      {
        label: '显示名称',
        name: 'name',
        rules: [
          { required: true, message: '名称必填' },
          { max: 20, message: '不可超过20个字符' },
        ],
        render: () => (
          <Input
            placeholder="请输入名称"
            allowClear
            defaultValue={displayName}
            key={logoId}
            onBlur={(e) => {
              setDisplayName(e.target.value);
              modalForm.setFieldsValue({ name: e.target.value });
            }}
            onChange={(e) => {
              setDisplayName(e.target.value);
              modalForm.setFieldsValue({ name: e.target.value });
            }}
          />
        ),
      },
      {
        label: '显示Logo',
        name: 'logo',
        extra: (
          <div style={{ marginTop: 16 }}>
            注：1.建议Logo上传文件为PNG格式的透明背景图片；
            <br />
            <span style={{ marginLeft: '2em' }}>2.文件大小不超过{LOGO_MAX_SIZE}M。</span>
          </div>
        ),
        render: () => (
          <div style={{ display: 'flex' }}>
            <div className={styles['logo-upload-bg']}>
              {logoUrl ? (
                <div className={styles['logo-container']}>
                  <img src={logoUrl} className={styles['logo-main']} />
                  <BlIcon
                    type="iconshanchu-guanbi"
                    className={styles['logo-delet-btn']}
                    style={{ color: '#8C8C8C' }}
                    onClick={() => {
                      setLogoUrl('');
                      modalForm.setFieldsValue({ logo: '' });
                      modalForm.validateFields(['logo']);
                    }}
                  />
                </div>
              ) : (
                <BcUpload
                  draggable={false}
                  limit={'image'}
                  maxCount={1}
                  maxSize={LOGO_MAX_SIZE}
                  onUploaded={(data: any) => {
                    setLogoUrl(data[0]?.response?.uri);
                    modalForm.setFieldsValue({ logo: data[0]?.response?.id });
                    modalForm.validateFields(['logo']);
                  }}
                >
                  <Button
                    type="dashed"
                    style={{
                      color: '#02B980',
                      borderColor: '#02B980',
                    }}
                    icon={<BlIcon type="iconxinjiantianjia" />}
                  >
                    上传Logo
                  </Button>
                </BcUpload>
              )}
            </div>
            <div style={{ display: 'flex', marginLeft: 56 }}>
              <span style={{ width: 98 }}>页头预览效果：</span>
              <div className={styles['menu-head-preview']}>
                {logoUrl ? (
                  <>
                    <img src={logoUrl} className={styles['menu-head-preview-logo']} />
                    <TextToolTip text={displayName} width={76} />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        ),
      },
    ],
  };

  return (
    <DataFormLayout
      form={modalForm}
      title="欢迎您！"
      onFinish={handleFinish}
      onCancel={onCancel}
      info={[baseInfo]}
    />
  );
};

export default AccountManagement;
