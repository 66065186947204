import { BlSortFormList, TagList } from 'src/components';
import { Form, Input, FormInstance, DatePicker, Button, message, Tag } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import EditableText from 'src/components/editableText';
import _, { compact, max, map } from 'lodash';
import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import BatchAddMaterials from './batchAddMaterials';
import moment from 'moment';
import _Array from 'src/utils/dataTypes/array';
import { replaceSign } from 'src/utils/constants';
import { Attribute } from '../interface';
import { appEnum } from 'src/dict';
import { numberMinMaxCheck, intMultipleCheck, fractionLengthCheck } from 'src/utils/formValidators';
import { materialEntity } from 'src/entity';

interface props {
  form: FormInstance;
  name: string;
  disabled?: boolean;
  source?: number;
}
/** 默认单位精度 */
const DEFAULT_PRECISION = 10;
const MaterialTable = (props: props) => {
  const { form, name, disabled, source } = props;
  const { SourceEnum } = appEnum.DeliveryEnum;
  const [batchAddVisible, setBatchAddVisible] = useState(false);
  const renderAddActon = () => {
    return (
      <div style={{ marginBottom: 20, marginTop: 10, display: 'inline' }}>
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          style={{ color: '#02B980', borderColor: '#02B980' }}
          onClick={() => {
            setBatchAddVisible(true);
          }}
        >
          按采购订单添加
        </Button>
      </div>
    );
  };
  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '行号',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'seqNo']}
              fieldKey={[field?.key, 'seqNo']}
              style={{ marginBottom: 0 }}
            >
              {field?.name + 1}
            </Form.Item>
          );
        },
      },
      {
        title: '物料编号',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'material', 'baseInfo', 'code']}
              fieldKey={[field?.key, 'materialCode']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '物料名称',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'material', 'baseInfo', 'name']}
              fieldKey={[field?.key, 'materialName']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '物料规格',
        width: 200,
        render: (_, field) => {
          const specification =
            form.getFieldValue(name)[field.name]?.material?.baseInfo?.specification;

          if (_Array.isEmpty(specification)) {
            return replaceSign;
          }
          return (
            <Form.Item
              name={[field?.name, 'material', 'baseInfo', 'specification']}
              fieldKey={[field?.key, 'materialSpecification']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '物料属性',
        width: 200,
        render: (_, field) => {
          const attributes = form.getFieldValue(name)[field.name]?.material?.attribute;

          if (_Array.isEmpty(attributes)) {
            return replaceSign;
          }
          return (
            <TagList
              dataSource={attributes.map((attr: Attribute) => {
                return {
                  label: `${attr.name}:${attr?.attributeItem?.content}`,
                  value: attr.id,
                };
              })}
            />
          );
        },
      },
      {
        title: '应收数',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item shouldUpdate noStyle>
              {() => {
                const unitName = form.getFieldValue(name)[field.name]?.unitName;
                const materialObj = form.getFieldValue(name)[field.name]?.material;
                const { enablePrecision, precisionFigure } = materialObj?.unit || {};

                return (
                  <Form.Item
                    name={[field?.name, 'planReceiveAmount']}
                    fieldKey={[field?.key, 'planReceiveAmount']}
                    style={{ marginBottom: 0 }}
                    rules={compact([
                      { required: true, message: '请输入应收数' },
                      enablePrecision && {
                        validator: fractionLengthCheck(
                          precisionFigure ?? DEFAULT_PRECISION,
                          `请输入${precisionFigure ?? DEFAULT_PRECISION}位精度的数字`,
                        ),
                      },
                      {
                        validator: numberMinMaxCheck({
                          min: 0,
                          minAllowEqual: false,
                          fieldName: '应收数',
                          message: '请输入大于0的值',
                        }),
                      },
                    ])}
                  >
                    <Input
                      addonAfter={unitName}
                      disabled={disabled}
                      type="number"
                      placeholder="请输入应收数"
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '应收时间',
        width: 250,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'planReceiveTime']}
              fieldKey={[field?.key, 'planReceiveTime']}
              rules={[{ required: true, message: '请选择应收时间' }]}
              shouldUpdate
              style={{ marginBottom: 0 }}
            >
              <DatePicker showTime disabled={disabled} format={'YYYY-MM-DD HH:mm:ss'} />
            </Form.Item>
          );
        },
      },
      {
        title:
          source == SourceEnum.ordinary || source == SourceEnum.coordination
            ? '采购订单编号'
            : '交货计划单编号',
        width: 250,
        render: (_, field) => {
          const isFromPurchaseOrder =
            source == SourceEnum.coordination || source == SourceEnum.ordinary;

          return (
            <Form.Item
              name={[
                field?.name,
                isFromPurchaseOrder ? 'purchaseOrderCode' : 'deliveryScheduleNoteCode',
              ]}
              fieldKey={[
                field?.key,
                isFromPurchaseOrder ? 'purchaseOrderCode' : 'deliveryScheduleNoteCode',
              ]}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title:
          source == SourceEnum.ordinary || source == SourceEnum.coordination
            ? '采购订单行号'
            : '交货计划单行号',
        width: 250,
        render: (_, field) => {
          const isFromPurchaseOrder =
            source == SourceEnum.coordination || source == SourceEnum.ordinary;

          return (
            <Form.Item
              name={[
                field?.name,
                isFromPurchaseOrder ? 'purchaseOrderItemSeqNum' : 'deliveryScheduleNoteLineNo',
              ]}
              fieldKey={[
                field?.key,
                isFromPurchaseOrder ? 'purchaseOrderItemSeqNum' : 'deliveryScheduleNoteLineNo',
              ]}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
    ];
  };
  const handleMaterialFinish = (rows?: any[], selectedKeys?: number[]) => {
    const materials = form.getFieldValue(name) || [];

    if (!rows || rows.length == 0) {
      if (!materials || materials.length == 0) {
        message.error('请至少添加一行物料');
        return;
      }
      rows = [];
    }
    // 过滤掉重复purchaseOrderItemId的物料行
    const filterRows = materials?.length
      ? rows?.filter((node) => !map(materials, 'purchaseOrderItemId').includes(node.id))
      : rows;
    const addMaterial = filterRows?.map((node, index: number) => {
      // 此处id为采购订单物料行id,
      const {
        code,
        seqNum,
        id: purchaseOrderItemId,
        purchaseOrderId,
        demandAmount,
        supplier,
        material,
      } = node;

      return {
        seqNo: materials?.length ? materials?.length + index + 1 : index + 1,
        material,
        returnAmount: 1,
        unitName: demandAmount?.unitName,
        unitId: demandAmount?.unitId,
        purchaseOrderCode: code,
        purchaseOrderId,
        supplier,
        purchaseOrderItemSeqNum: seqNum,
        purchaseOrderItemId,
        planReceiveTime: moment(node.demandTime),
        planReceiveAmount:
          Number(node?.demandAmount?.amount || 0) -
          Number(node?.planReceivedAmount?.amount || 0) +
          Number(node?.planReturnAmount?.amount || 0),
      };
    });
    const newMaterials = _.cloneDeep(materials).concat(addMaterial);
    // 根据用户选择selectedKeys的做删减 (反选的情况)
    const itemList = newMaterials.filter((item: any) => {
      const { purchaseOrderItemId } = item;
      const index = _.findIndex(selectedKeys, (o) => o == purchaseOrderItemId);

      if (index !== -1) {
        return item;
      }
    });

    const baseSupplier = itemList[0]?.supplier;
    const isFalse = _.some(itemList, (node) => node?.supplier?.id !== baseSupplier?.id);

    const basePurchaseOrderId = itemList?.[0]?.purchaseOrderId;
    const isSameOrder = itemList.every((row: any) => row.purchaseOrderId == basePurchaseOrderId);

    if (isFalse) {
      message.error('收货单所选供应商必须一致');
      return;
    }
    if (!isSameOrder) {
      message.error('请选择属于同一采购订单的物料行');
      return;
    }
    form.setFieldsValue({
      supplier: `${baseSupplier.code} / ${baseSupplier.name}`,
    });

    form.setFieldsValue({ itemList });

    setBatchAddVisible(false);
  };
  const getSelectedKeys = () => {
    const items = form.getFieldValue(name) || [];
    const selectedKeys = items?.map((item: any) => item?.purchaseOrderItemId);

    return selectedKeys;
  };

  return (
    <div>
      <div style={{ marginBottom: 24, fontSize: 14, color: 'rgba(0, 0, 0, 0.85)' }}>
        物料： {!disabled && renderAddActon()}
      </div>
      <BlSortFormList
        buttonText="添加物料行"
        form={form}
        name={name}
        isNeedDrag={false}
        isNeedAdd={false}
        isNeedDelete={!disabled} // 状态为执行中时，不允许增删物料行
        renderColumns={() => getColumns()}
        listRules={[
          {
            validator: async (__, items) => {
              if (!items || _.isEmpty(items.filter((i: any) => !!i))) {
                form.setFieldsValue({ supplier: replaceSign });
                return Promise.reject(Error('请至少添加一行物料'));
              }
              return Promise.resolve(true);
            },
          },
        ]}
      />
      {batchAddVisible && (
        <BatchAddMaterials
          addForm={form}
          visible={batchAddVisible}
          handleFinish={handleMaterialFinish}
          selectedKeys={getSelectedKeys()}
          onCancel={() => {
            setBatchAddVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default MaterialTable;
