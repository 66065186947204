/**
 * @mappings 布局模块名-值映射表
 */

import { mappingsFactory } from '../utils';
import { LayoutComponentType, LayoutItemType, PageType, Terminal } from '.';

export const layoutItemType = mappingsFactory(
  ['容器', LayoutItemType.container],
  ['标签页', LayoutItemType.tabs],
  ['信息块', LayoutItemType.infoBlock],
  ['字段', LayoutItemType.field],
);

export const pageType = mappingsFactory(
  ['详情页', PageType.detail],
  ['新建/编辑页', PageType.edit],
  ['列表页', PageType.list],
);

export const terminal = mappingsFactory(['网页端', Terminal.web], ['移动端', Terminal.app]);

export const layoutComponentType = mappingsFactory(
  ['标题栏', LayoutComponentType.titlebar],
  ['标签页', LayoutComponentType.sonObjectTab],
  ['主从列表', LayoutComponentType.sonObjectTable],
  ['字段', LayoutComponentType.field],
);
