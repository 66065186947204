import { extractIds } from 'src/utils/formatters';
import { extractValue } from 'src/utils/formatters/extractValue';

const extractIdsForStep = (value: any) =>
  extractIds(
    ['authUsers', 'authDepartments', 'authRoles', 'digitalUserIds', 'digitalRoleIds'],
    value,
  );

export const extractIdsForStepofUser = (value: any) =>
  extractIds(['showValueList', 'optionalUser', 'defaultUserOption'], value);

export const extractIdsForStepofResource = (params: string[], value: any) =>
  extractValue(params, value);

export default extractIdsForStep;
