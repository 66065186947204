// 支持通知跳转的 对象-跳转结果的map映射关系

import { fetchLayoutDetailByObjectCode } from 'src/api/ytt/layout-domain';
import { ObjectCategory } from 'src/dict/common';
import { PageType } from 'src/dict/customLayout';
import { PRODUCTION_PLANNING_PATH } from 'src/page/planned/navigation';
import { PRODUCTION_PLANNING_PATH as BATH } from 'src/page/productionPlanning/navigation';

interface MetaProps {
  id: string; // 跳转实例id
  objectCategory: string; // 对应的对象类型 1代表自定义对象 2代表预置对象
  objectCode: string; // 跳转对象code
  status?: string;
}

export const getLayout = async (objectData: any) => {
  try {
    const res: any = await fetchLayoutDetailByObjectCode({
      objectCode: objectData?.objectCode,
      instanceId: objectData?.id,
      type: PageType.detail,
    });

    return (res?.data || [])?.map((node: any) => ({
      key: node?.id,
      label: node?.name,
      value: node?.id,
    }));
  } catch (error) {
    console.log('error: ', error);
  }
};

// 需要选择详情布局的
export const notifyNeedModal = (objectCode: string) => {
  const needModal = ['QualityInspectionTask'];

  return needModal.includes(objectCode);
};

export const notifyJump = (meta: MetaProps) => {
  if (Number(meta?.objectCategory) === ObjectCategory.customObject) {
    // 自定义对象跳转
    window.open(`/customObject/__customObject/${meta?.objectCode}/${meta?.id}/detail`);
  } else {
    // 预置对象
    switch (meta?.objectCode) {
      case 'SOPTask': // SOP任务
        window.open(`/sop/exec/task/${meta?.id}/detail`);
        break;
      case 'ProductionOrder': // 生产工单跳转下发日志详情
        window.open(`${BATH}/productionOrder/log/detail/${meta?.id}`);
        break;
      case 'PlanOrder': // 计划订单跳转投放日志详情
        window.open(`${PRODUCTION_PLANNING_PATH}/plannedOrder/log/detail/${meta?.id}`);
        break;
      default:
        break;
    }
  }
};

export const notifyJumpByLayout = (meta: MetaProps, layoutId: number) => {
  switch (meta?.objectCode) {
    case 'QualityInspectionTask': // 检验任务
      window.open(`/quality/qualityExecution/qcTask/detail?id=${meta?.id}&layoutId=${layoutId}`);
      break;
    default:
      break;
  }
};
