/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Author: huangsijia
 * @Date: 2021-11-22 10:54:00
 * @LastEditTime: 2022-02-25 14:09:15
 * @LastEditors: huangsijia
 * @Description: 查看不良原因列表
 * @FilePath: /bf-main-3/src/page/quality/qcDefectReason/list.tsx
 */

import { useState, useRef } from 'react';
import { RecordListLayout } from 'layout';
import { Form, message } from 'antd';
import { replaceSign } from 'src/utils/constants';
import { fieldTypeList } from 'src/utils';
import { BlIcon, SingleOperationLogComponent, Tooltip } from 'src/components';

import {
  fetchQcDefectReasonPageList,
  fetchQcDefectReasonDelete,
} from 'src/api/ytt/quality-domain/qc_defect_reason';

import { QcDefectReasonType } from './interface';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { useHistory } from 'react-router-dom';
import {
  customLayoutCreateButton,
  customLayoutChooseButton,
} from 'src/components/customLayout/hooks/customLayoutForButton';

const unknownMsg = '未知错误';
const delSuccessMsg = '删除成功';
const delFailMsg = '删除失败';
const NONEXISTENT = -1;

const QcDefectReasonList = () => {
  const [visibleLogId, setVisibleLogId] = useState<number>(NONEXISTENT); // 显示单条操作记录id
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const refreshCountRef = useRef(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [delForm] = Form.useForm();
  const history = useHistory();
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const handleDelete = async (ids: number[], onSuccess?: () => void) => {
    try {
      const res = await fetchQcDefectReasonDelete({ ids });
      const { data } = res;

      if (res.code !== 200) {
        message.error(res?.message || unknownMsg);
        return;
      }
      if (data?.code === 1) {
        // 结果标识符 1成功 2失败 3出现失败(批量删除)
        typeof onSuccess === 'function' && onSuccess?.();
        message.success(data?.result || res?.message || delSuccessMsg);
      }
      if (data?.code === 2) {
        message.error(data?.result || res?.message || delFailMsg);
      }
      // if (data?.code === 3) {
      //   typeof onSuccess === 'function' && onSuccess?.();
      //   message.warn('批量删除出现失败');
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const refreshTable = () => {
    // 刷新列表页
    refreshCountRef.current++;
    setRefreshMarker(refreshCountRef.current);
  };

  const getOptionList = (record: QcDefectReasonType) => {
    return [
      {
        title: '查看',
        auth: authDict.causeofdefect_detail,
        customRender: customLayoutChooseButton({
          title: '查看',
          objectCode: 'CauseOfDefect',
          type: 'detail',
          instanceId: record?.id,
          onClick: (layoutId: number) => {
            history.push(`qcDefectReason/${record?.id}/detail?layoutId=${layoutId}`);
          },
        }),
      },
      {
        title: '编辑',
        customRender: customLayoutChooseButton({
          title: '编辑',
          objectCode: 'CauseOfDefect',
          type: 'edit',
          onClick: (layoutId: number) => {
            history.push(`qcDefectReason/${record?.id}/edit?layoutId=${layoutId}`);
          },
        }),
        auth: authDict.causeofdefect_edit,
      },
      {
        title: '操作记录',
        key: 'log',
        onClick: () => setVisibleLogId(record.id),
        auth: authDict.causeofdefect_operrecord,
      },
      // {
      //   title: '删除',
      //   onClick: () => {
      //     BLDelConfirm(delMsg, delForm, () => {
      //       handleDelete([record.id], refreshTable);
      //     });
      //   },
      // },
    ];
  };

  const getColumns = () => [
    {
      title: '不良原因名称',
      dataIndex: 'name',
      type: fieldTypeList.text,
      width: 200,
      render: (text: string, record: QcDefectReasonType, index: number, config: any) => (
        <>
          {hasAuth(authDict.causeofdefect_detail) ? (
            customLayoutChooseButton({
              title: <Tooltip text={text ?? replaceSign} width={config.contentWidth} />,
              objectCode: 'CauseOfDefect',
              type: 'detail',
              onClick: (layoutId: number) => {
                history.push(`qcDefectReason/${record?.id}/detail?layoutId=${layoutId}`);
              },
            })
          ) : (
            <Tooltip text={text ?? replaceSign} width={config.contentWidth} />
          )}
        </>
      ),
    },
    {
      title: '备注',
      dataIndex: 'remark',
      type: fieldTypeList.textArea,
      width: 200,
      render: (remark: string) => remark ?? replaceSign,
    },
  ];

  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      icon: <BlIcon type="icondaochu" />,
      auth: authDict.causeofdefect_export,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.qcDefectReason,
        });
      },
    },
    {
      title: '导入',
      showExport: true,
      auth: authDict.causeofdefect_import,
      onClick: () => {
        openModal({
          optType: 'import',
          businessType: BUSINESS_TYPE.qcDefectReason,
        });
      },
    },
    {
      auth: authDict.causeofdefect_add,
      customRender: customLayoutCreateButton({
        title: '新建不良原因',
        icon: <BlIcon type="iconxinjiantianjia" />,
        objectCode: 'CauseOfDefect',
        type: 'create',
        onClick: (layoutId) => history.push(`qcDefectReason/create?layoutId=${layoutId}`),
      }),
    },
  ];

  // const batchMenu = [
  //   {
  //     title: '删除',
  //     icon: <BlIcon type="iconshanchulajitong1" />,
  //     onClick: (onSuccess: any, onFail: any) => {
  //       BLDelConfirm(
  //         delMsg,
  //         delForm,
  //         () => {
  //           handleDelete(selectedRowKeys.map((item) => Number(item)));
  //           onSuccess();
  //         },
  //         onFail,
  //       );
  //     },
  //   },
  // ];

  const filterList = [
    {
      label: '不良原因名称',
      name: 'name',
      type: fieldTypeList.text,
    },
    {
      label: '备注',
      name: 'remark',
      type: fieldTypeList.text,
    },
  ];

  const formatDataToQuery = (params: any) => {
    dispatch.excelImport.updateBusinessData({ businessData: params });
    return params;
  };

  return (
    <>
      <RecordListLayout<QcDefectReasonType>
        columns={getColumns()}
        useIndex={false}
        mainMenu={mainMenu}
        // batchMenu={batchMenu}
        filterList={filterList}
        formatDataToQuery={formatDataToQuery}
        requestFn={fetchQcDefectReasonPageList}
        // selectedRowKeys={selectedRowKeys}
        refreshMarker={refreshMarker}
        // onSelectedRowKeys={(selectKey) => {
        //   setSelectRowKeys(selectKey);
        // }}
        getOperationList={getOptionList}
        userAuth={getAuthFromLocalStorage()}
      />
      {visibleLogId > NONEXISTENT && (
        <SingleOperationLogComponent
          visible={visibleLogId > NONEXISTENT}
          instanceId={visibleLogId}
          objectCode={'CauseOfDefect'}
          closeDetail={() => {
            setVisibleLogId(NONEXISTENT);
          }}
        />
      )}
    </>
  );
};

export default QcDefectReasonList;
