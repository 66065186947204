import { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import useTreeContent from 'src/layout/treeContent';
import DataTableForm from './fieldAuth';
import {
  fetchFieldPermissionObjectList,
  fetchFieldPermissionGetByRole,
  fetchFieldPermissionGetDefault,
  fetchFieldPermissionUpdate,
} from 'src/api/ytt/privilege-domain/fieldPermission';
import _ from 'lodash';
import styles from '../role.module.scss';

interface Item {
  objectName?: string;
  objectCode?: string;
}

interface Props {
  roleId?: any;
  disabled?: boolean;
  getDataSource?: (prop: any) => void;
}

const FieldPrivilegeTree = () => {
  const [tree, setTree] = useState<Item[]>([]);

  useEffect(() => {
    fetchFieldPermissionObjectList().then((res: any) => {
      if (res.data) {
        setTree(_.get(res, 'data', []));
      }
    });
  }, []);
  const domTree = tree.map((item: Item) => ({
    key: item.objectCode,
    title: item.objectName,
  }));

  return {
    domTree,
  };
};

const UseFieldSelectContent = (props: Props) => {
  const { roleId, disabled, getDataSource } = props;
  const { domTree }: any = FieldPrivilegeTree();
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState(null);
  const [list, setList] = useState<any>({});

  const mergeAuth = (arr: any, source: any) => {
    if (!arr) return [];
    return arr?.map((item: any) => {
      const matchItem = _.find(source, ['id', item.id]);

      return _.merge(item, matchItem || {});
    });
  };

  const getDataByField = async (id: string) => {
    // 获取当前对象下的所有字段权限
    const { data: fieldAuthByObject } = await fetchFieldPermissionGetDefault({ bizObjectCode: id });
    // 获取当前角色的字段权限
    const { data: fieldAuthByRole } = await fetchFieldPermissionGetByRole({
      bizObjectCode: id,
      roleId,
    });

    // 对象权限数据+角色权限数据
    const requestAuth = mergeAuth(fieldAuthByObject, fieldAuthByRole);

    // 服务端权限数据+用户操作后的数据
    setDataSource(mergeAuth(requestAuth, list[id]));
  };

  const submit = async () => {
    const bizObjects: any = [];

    _.forIn(list, (fields, code) => {
      if (fields instanceof Array && fields.length > 0) {
        bizObjects.push({
          code,
          fields,
        });
      }
    });

    const res = await fetchFieldPermissionUpdate({
      roleId,
      bizObjects,
    });

    if (res && res.code === 200) {
      setDisableSave(true);
      message.success('编辑成功');
      setList({});
    }
  };

  const treeWebContent = useTreeContent({
    treeData: domTree,
    style: { height: 436, overflowY: 'scroll' },
    onSelect: (node: any) => {
      getDataByField(node.key);
    },
    isListTree: true,
    checkable: false,
    detailContent: (treeNode) => (
      <>
        <div className={styles.titleContent}>
          <span className={styles.authTitle}>设置该角色在当前对象下字段可见和编辑权限</span>
          {disabled ? null : (
            <Button disabled={disableSave} type="primary" onClick={submit}>
              保存
            </Button>
          )}
        </div>
        {dataSource && (
          <DataTableForm
            treeNode={treeNode}
            disabled={disabled}
            getChangeItems={(changeItems) => {
              setList(changeItems);
            }}
            dataSource={dataSource || []}
          />
        )}
      </>
    ),
  });

  useEffect(() => {
    if (Object.keys(list).length) {
      setDisableSave(false);
    }
  }, [list]);

  useEffect(() => {
    if (getDataSource) {
      getDataSource({ disableSave });
    }
  }, [disableSave, getDataSource]);

  return <>{treeWebContent}</>;
};

export default UseFieldSelectContent;
