import { useState } from 'react';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { RecordListLayout } from 'layout';
import { TextToolTip, BlColumnsType } from '@blacklake-web/component';

import { formatDateForRender, formatTimeForRender } from 'src/utils/formatters';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { TagTooltip, SingleOperationLogComponent, BlIcon } from 'src/components';
import { FormatDataToQueryData, FilterItem } from 'src/layout';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { LabelInValueSelectorProps } from 'src/page/organization/components/userAndDepartmentSelect/index.d';
import {
  fetchWorkerCalendarList,
  FetchWorkerCalendarListRequest,
  // fetchWorkerCalendarBulkDelete,
} from 'src/api/ytt/calendar-domain';
import { fieldTypeList } from 'src/utils';
import { lookup } from 'src/dict';
import { CRUD } from 'src/dict/common';
// import BLDelConfirm from '../../../share/modalConfirm';
// import { showErrorListMessage } from 'src/components/message/errorMessageList';
// import { ErrorNotification } from 'src/components/notification';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import {
  CalendarType,
  calendarTypeDisplayMap,
  DISPLAY_DATE_FORMAT,
  DISPLAY_TIME_FORMAT,
  UserColumnProps,
  WorkCalendarListItemProps,
} from '../constants';

const { RangePicker } = DatePicker;

// type IAutoGeneratedResponse = IRealResponse['data'];
interface CalendarTableParamProps extends FormatDataToQueryData {
  code?: string;
  endTime?: moment.Moment[];
  name?: string;
  startTime?: moment.Moment[];
  type?: CalendarType[];
  creatorUser?: LabelInValueSelectorProps[];
  operatorUser?: LabelInValueSelectorProps[];
  createdAt?: moment.Moment[];
  updatedAt?: moment.Moment[];
  sorter?: any;
  managers?: LabelInValueSelectorProps[]; // select返回的值是{value: 123, label: 'abc'}格式的
}

interface CalendarApiParamType {
  code?: string;
  endTimeStart?: number;
  endTimeEnd?: number;
  name?: string;
  startTimeStart?: number;
  startTimeEnd?: number;
  type?: CalendarType;
  managerIds?: number[];
  creatorId?: number;
  operatorId?: number;
  page?: number;
  size?: number;
  quickSearch?: string;
  orderBy?: string;
  createdAtStart?: number;
  createdAtEnd?: number;
  updatedAtStart?: number;
  updatedAtEnd?: number;
}

const getTimeStamp = (
  inputTime: Moment | string | undefined,
  startOfDay?: boolean,
): number | undefined => {
  if (inputTime === undefined) return inputTime;
  // deep linking的时候，inputTime不是从form里拿的值，是从url里拿的string
  const inputMoment = typeof inputTime === 'string' ? moment(inputTime) : inputTime;

  return startOfDay ? inputMoment.startOf('day').valueOf() : inputMoment.valueOf();
};

// param是由antd的table发出，经由RecordListLayout封装过一次的参数，描述的是表格的变化(分页，排序，筛选，搜索信息)
// 将这些信息转换成后台分页查询列表数据的参数格式，即requestFn的传入参数应有的格式
const formatDataToQuery = (param: CalendarTableParamProps): CalendarApiParamType => {
  const {
    startTime,
    endTime,
    createdAt,
    updatedAt,
    type,
    managers,
    creatorUser,
    operatorUser,
    ...rest
  } = param;

  return {
    startTimeStart: getTimeStamp(startTime?.[0], true),
    startTimeEnd: getTimeStamp(startTime?.[1], true),
    endTimeStart: getTimeStamp(endTime?.[0], true),
    endTimeEnd: getTimeStamp(endTime?.[1], true),
    createdAtStart: getTimeStamp(createdAt?.[0]),
    createdAtEnd: getTimeStamp(createdAt?.[1]),
    updatedAtStart: getTimeStamp(updatedAt?.[0]),
    updatedAtEnd: getTimeStamp(updatedAt?.[1]),
    type: type && type.length === 1 ? type[0] : undefined, // 一共只有2种日历类型，全选等于不选
    managerIds: managers?.map((manager) => manager.value as number),
    creatorId: creatorUser?.[0].value as number,
    operatorId: operatorUser?.[0].value as number,
    ...rest,
  };
};

const turnTimeArrToMomentArr = (timeArr: Moment[] | string[] | undefined): Moment[] | undefined =>
  timeArr?.map((t) => (typeof t === 'string' ? moment(t) : t));

const formatRangeToString = (
  timeRange: moment.Moment[] | string[] | undefined,
  formatString: string,
): string | undefined => {
  if (!timeRange || _.isEmpty(timeRange)) return undefined;
  const timeRangeMoment = turnTimeArrToMomentArr(timeRange!);

  return `${timeRangeMoment![0]?.format(formatString)} - ${timeRangeMoment![1]?.format(
    formatString,
  )}`;
};

// 把param转换成{key: value}[], 显示在RecordListInfo的tag里
const formatDataToDisplay = (param: CalendarTableParamProps) => {
  const {
    startTime,
    endTime,
    createdAt,
    updatedAt,
    type,
    managers,
    creatorUser,
    operatorUser,
    ...rest
  } = param;

  return {
    ...rest,
    startTime: formatRangeToString(startTime, DISPLAY_DATE_FORMAT),
    endTime: formatRangeToString(endTime, DISPLAY_DATE_FORMAT),
    createdAt: formatRangeToString(createdAt, DISPLAY_TIME_FORMAT),
    updatedAt: formatRangeToString(updatedAt, DISPLAY_TIME_FORMAT),
    type: type ? calendarTypeDisplayMap[type[0]] : undefined,
    managers: managers?.map((manager) => manager.label).join(', '),
    creatorUser: creatorUser?.[0].label,
    operatorUser: operatorUser?.[0].label,
  };
};

const formatDataToFormDisplay = (param: CalendarTableParamProps) => {
  const { startTime, endTime, createdAt, updatedAt, ...rest } = param;

  return {
    ...rest,
    startTime: turnTimeArrToMomentArr(startTime),
    endTime: turnTimeArrToMomentArr(endTime),
    createdAt: turnTimeArrToMomentArr(createdAt),
    updatedAt: turnTimeArrToMomentArr(updatedAt),
  };
};

const filterList: FilterItem[] = [
  {
    label: '日历编号',
    name: 'code',
    type: fieldTypeList.text,
  },
  {
    label: '日历名称',
    name: 'name',
    type: fieldTypeList.text,
  },
  {
    label: '开始日期',
    name: 'startTime',
    type: fieldTypeList.date,
    renderItem: (
      <RangePicker
        placeholder={['请选择', '请选择']}
        separator={<BlIcon type="iconzhixiangyou" />}
        suffixIcon={<BlIcon type="iconshijian" />}
        style={{ width: '100%' }}
      />
    ),
  },
  {
    label: '结束日期',
    name: 'endTime',
    type: fieldTypeList.date,
    renderItem: (
      <RangePicker
        placeholder={['请选择', '请选择']}
        separator={<BlIcon type="iconzhixiangyou" />}
        suffixIcon={<BlIcon type="iconshijian" />}
        style={{ width: '100%' }}
      />
    ),
  },
  {
    label: '负责人',
    name: 'managers',
    type: fieldTypeList.text,
    renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
  },
  {
    label: '日历类型',
    name: 'type',
    type: fieldTypeList.select,
    selectProps: {
      mode: 'multiple',
      allowClear: true,
      options: [
        {
          label: '固定班制',
          value: 1,
        },
        {
          label: '排班制',
          value: 2,
        },
      ],
    },
  },
  {
    label: '创建人',
    name: 'creatorUser',
    type: fieldTypeList.text,
    renderItem: (
      <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple={false} isSelectUser />
    ),
  },
  {
    label: '创建时间',
    name: 'createdAt',
    type: fieldTypeList.date,
    renderItem: (
      <RangePicker
        showTime
        placeholder={['开始时间', '结束时间']}
        separator={<BlIcon type="iconzhixiangyou" />}
        suffixIcon={<BlIcon type="iconshijian" />}
      />
    ),
  },
  {
    label: '更新人',
    name: 'operatorUser',
    type: fieldTypeList.text,
    renderItem: (
      <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple={false} isSelectUser />
    ),
  },
  {
    label: '更新时间',
    name: 'updatedAt',
    type: fieldTypeList.date,
    renderItem: (
      <RangePicker
        showTime
        placeholder={['开始时间', '结束时间']}
        separator={<BlIcon type="iconzhixiangyou" />}
        suffixIcon={<BlIcon type="iconshijian" />}
      />
    ),
  },
];

// 跳转到创建页面
const goToCreate = () => {
  window.reactRouterHistoryInstance.push('/modeling/calendarManagement/workCalendar/create');
};

// 跳转到编辑页面
const goToEdit = (id: number) => {
  window.reactRouterHistoryInstance.push(`/modeling/calendarManagement/workCalendar/edit${id}`);
};

// 跳转到复制页面
const goToCopy = (id: number) => {
  window.reactRouterHistoryInstance.push(`/modeling/calendarManagement/workCalendar/copy${id}`);
};

// 跳转到排班页面，要把日期放在url里当参数传递，格式化一下去掉/
const goToSchedule = (id: number, startTime: number, endTime: number) => {
  window.reactRouterHistoryInstance.push(
    `/modeling/calendarManagement/workCalendar/schedule?id=${id}&startTime=${startTime}&endTime=${endTime}`,
  );
};

// 跳转到详情页面
const goToDetail = (id: number) => {
  window.reactRouterHistoryInstance.push(`/modeling/calendarManagement/workCalendar/detail${id}`);
};

// TODO: talk with backend to get calendar name here instead of id
// const errorColumns = [
//   {
//     title: '工作日历名',
//     dataIndex: 'id',
//     render: (id: string) => (
//       <a
//         onClick={() => {
//           goToDetail(Number(id));
//         }}
//       >
//         {id}
//       </a>
//     ),
//   },
//   {
//     title: '失败原因',
//     dataIndex: 'failReason',
//   },
// ];

export default function WorkCalendarList() {
  // const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]); // 选中行的key，用以批量删除
  const [refreshMarker, setRefreshMarker] = useState<number>(Date.now()); // 刷新用的时间戳，在refresh方法里用
  const [visibleLog, setVisibleLog] = useState(false);
  const [currentCalendarId, setCurrentCalendarId] = useState<number>();
  // const [operateDialogMode, setOperateDialogMode] = useState<OperateModeType>(
  //   OPERATE_DIALOG_MODE.HIDE,
  // ); // 当前工作日历弹窗的模式，HIDE为隐藏，即页面展示列表。另有CREATE/EDIT/COPY三种模式，分别对应弹出新建/编辑/复制工作日历的弹窗
  // 当前正在操作的日历的id，在点击编辑/复制时会把当前行的id设置上，如果是新建，则为undefined
  // const [operatingCalendar, setOperatingCalendar] = useState<WorkCalendarParamItemProps>();
  // // 是否显示排班窗口
  // const [showScheduleDialog, setShowScheduleDialog] = useState(false);

  // const [delForm] = Form.useForm(); // 用在确认删除的弹框中，填删除原因的form上

  const mainMenu = [
    {
      title: '新建工作日历',
      auth: authDict.workcalendar_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: goToCreate,
      items: [],
    },
  ];

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  // 批量删除工作日历，在确认删除弹窗点了确认后的回掉
  // onSuccess, onFail: batchMenu onClick回调的参数，onSuccess会刷新列表并清空批量按钮的loading状态，onFail只清空loading状态
  // idToDeleteAlone: 单选一行在操作菜单里删除工作日历时传入的对应行的工作日历id
  // 批量删除时，idToDeleteAlone为undefined，删除参数是selectedRowKeys
  // const handleDelete = async (
  //   onSuccess?: () => void,
  //   onFail?: () => void,
  //   idToDeleteAlone?: number,
  // ) => {
  //   try {
  //     // 从确认删除的form里得到删除原因
  //     const delReason = delForm.getFieldValue('delReason');
  //     const idsInParam = idToDeleteAlone ? [idToDeleteAlone] : (selectedRowKeys as number[]);
  //     const { data } = await fetchWorkerCalendarBulkDelete({
  //       deleteReason: delReason,
  //       ids: idsInParam,
  //     });

  //     if (data) {
  //       const { failCount, successCount, failDetails } = data;

  //       // 全部删除成功，显示删除成功
  //       if (failCount === 0) {
  //         Message.success('删除成功');
  //         onSuccess?.();
  //       } else if (successCount === 0) {
  //         // 全部删除失败，提示删除失败及原因
  //         onFail?.();
  //         if (failDetails) {
  //           ErrorNotification({
  //             message: '删除失败',
  //             description: (
  //               <>
  //                 {failDetails.map((fail) => (
  //                   <div key={fail.id}>{fail.failReason}</div>
  //                 ))}
  //               </>
  //             ),
  //           });
  //         } else {
  //           Message.error('删除失败！');
  //         }
  //       } else if (failDetails) {
  //         onSuccess?.();
  //         // 否则用表格显示删除结果及原因统计的table
  //         showErrorListMessage({
  //           title: '删除出现错误',
  //           data: failDetails,
  //           columns: errorColumns,
  //           operateName: '删除',
  //           successCount,
  //           failCount,
  //         });
  //       }
  //       // 删除成功以后清空删除表单里已经填的删除原因
  //       delForm.resetFields();
  //     } else {
  //       onFail?.();
  //       console.log('api返回的response里没有data');
  //     }
  //   } catch (error) {
  //     onFail?.();
  //     console.log(error);
  //   }
  // };

  // const batchMenu = [
  //   {
  //     title: lookup('common.crud', CRUD.delete),
  //     // onSuccess, onFail: batchMenu onClick回调的参数，onSuccess会刷新列表并清空批量按钮的loading状态，onFail只清空loading状态
  //     onClick: (onSuccess?: () => void, onFail?: () => void) => {
  //       BLDelConfirm('你确定要删除吗？', delForm, () => {
  //         handleDelete(onSuccess, onFail);
  //       });
  //     },
  //   },
  // ];

  const columns: BlColumnsType<WorkCalendarListItemProps> = [
    {
      title: '日历编号',
      dataIndex: 'code',
      width: 140,
      sorter: true,
      render: (text: string, record: WorkCalendarListItemProps, index: number, config: any) => {
        return hasAuth(authDict.workcalendar_detail) ? (
          <a
            onClick={() => {
              window.reactRouterHistoryInstance.push(
                `/modeling/calendarManagement/workCalendar/detail${record.id}`,
              );
            }}
          >
            <TextToolTip text={text} width={config.contentWidth} />
          </a>
        ) : (
          text
        );
      },
    },
    {
      title: '日历名称',
      dataIndex: 'name',
      width: 150,
      sorter: true,
      render: (text: string, record: WorkCalendarListItemProps, index: number, config: any) => {
        return hasAuth(authDict.workcalendar_detail) ? (
          <a
            onClick={() => {
              window.reactRouterHistoryInstance.push(
                `/modeling/calendarManagement/workCalendar/detail${record.id}`,
              );
            }}
          >
            <TextToolTip text={text} width={config.contentWidth} />
          </a>
        ) : (
          text
        );
      },
    },
    {
      title: '开始日期',
      dataIndex: 'startTime',
      width: 130,
      sorter: true,
      render: (_text: string, record: WorkCalendarListItemProps) => {
        return formatDateForRender(record.startTime);
      },
    },
    {
      title: '结束日期',
      dataIndex: 'endTime',
      width: 130,
      sorter: true,
      render: (_text: string, record: WorkCalendarListItemProps) => {
        return formatDateForRender(record.endTime);
      },
    },
    {
      title: '负责人',
      dataIndex: 'managers',
      width: 150,
      render: (_text: string, record: WorkCalendarListItemProps) => (
        <TagTooltip list={record.managers} isUser />
      ),
    },
    {
      title: '日历类型',
      dataIndex: 'type',
      width: 110,
      render: (_text: string, record: WorkCalendarListItemProps) => {
        return calendarTypeDisplayMap[record.type as CalendarType];
      },
    },
    {
      title: '创建人',
      dataIndex: ['creatorUser', 'name'],
      width: 120,
      render: (_text: string, record: WorkCalendarListItemProps) => {
        const { creatorUser } = record;

        return (
          <AvatarDisplay
            id={creatorUser.id}
            name={creatorUser.name}
            avatarUrl={creatorUser.avatarUrl}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 180,
      sorter: true,
      render: (_text: string, record: WorkCalendarListItemProps) => {
        return formatTimeForRender(record.createdAt);
      },
    },
    {
      title: '更新人',
      dataIndex: ['operatorUser', 'name'],
      width: 120,
      render: (_text: string, record: WorkCalendarListItemProps) => {
        const { operatorUser } = record;

        return (
          <AvatarDisplay
            id={operatorUser.id}
            name={operatorUser.name}
            avatarUrl={operatorUser.avatarUrl}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 180,
      sorter: true,
      render: (_text: string, record: WorkCalendarListItemProps) => {
        return formatTimeForRender(record.updatedAt);
      },
    },
  ];

  const getOperationList = (record: WorkCalendarListItemProps) => {
    const { id, type } = record;

    return _.compact([
      {
        title: lookup('common.crud', CRUD.view),
        auth: authDict.workcalendar_detail,
        onClick: () => {
          goToDetail(id!);
        },
      },
      {
        title: lookup('common.crud', CRUD.edit),
        auth: authDict.workcalendar_edit,
        onClick: () => {
          goToEdit(id!);
        },
      },
      {
        title: lookup('common.crud', CRUD.copy),
        auth: authDict.workcalendar_add,
        onClick: () => {
          goToCopy(id!);
        },
      },
      type === CalendarType.Flex &&
        (hasAuth(authDict.workcalendar_add) || hasAuth(authDict.workcalendar_edit)) && {
          title: '排班',
          onClick: () => {
            goToSchedule(record.id, record.startTime, record.endTime);
          },
        },
      {
        title: '操作记录',
        auth: authDict.workcalendar_operrecord,
        onClick: () => {
          setCurrentCalendarId(id);
          setVisibleLog(true);
        },
      },
    ]);
  };

  return (
    <>
      <RecordListLayout<WorkCalendarListItemProps>
        refreshMarker={refreshMarker}
        filterList={filterList}
        requestFn={(params: FetchWorkerCalendarListRequest) =>
          fetchWorkerCalendarList(params, { legacy: true })
        }
        placeholder="请输入搜索关键字"
        // batchMenu={batchMenu}
        mainMenu={mainMenu}
        rowKey="id"
        // selectedRowKeys={selectedRowKeys}
        // onSelectedRowKeys={(selectedKeys) => {
        //   setSelectedRowKeys(selectedKeys);
        // }}
        columns={columns}
        getOperationList={getOperationList}
        userAuth={getAuthFromLocalStorage()}
        formatDataToDisplay={formatDataToDisplay}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={currentCalendarId!}
          objectCode={'WorkCalendar'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
}
