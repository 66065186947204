import React from 'react';
import { RecordListLayout } from 'layout';
import { UnTriggerFilterList, unTriggerListCol } from './columns';
import { fetchMessageTemplateList } from 'src/api/ytt/message-domain/messageTemplate';
import _String from 'src/utils/dataTypes/string';
import { unTriggerColType } from './interface';

export default function TriggerRulesList() {
  const formatDataToQuery = (params: any) => {
    const { moduleId, name, title, content, quickSearch, ...rest } = params;

    const relParams = { ...rest };

    if (!_String.isEmpty(name) || !_String.isEmpty(quickSearch)) {
      relParams.name = name || quickSearch;
    }

    if (!_String.isEmpty(content)) {
      relParams.content = content;
    }

    if (!_String.isEmpty(title)) {
      relParams.title = title;
    }

    if (moduleId) {
      relParams.moduleId = moduleId?.value;
    }

    return {
      ...relParams,
    };
  };

  return (
    <RecordListLayout<unTriggerColType>
      formatDataToQuery={formatDataToQuery}
      columns={unTriggerListCol}
      rowKey={(record: unTriggerColType) => record?.templateId}
      placeholder={'输入通知模板名称'}
      configcacheKey="unTriggerRulesList"
      filterList={UnTriggerFilterList}
      requestFn={(params: any) => fetchMessageTemplateList({ type: 0, ...params })}
    />
  );
}
