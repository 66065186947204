import { FC, useEffect, useState } from 'react';
import { Form, Input, InputNumber, message } from 'antd';
import { compact, get } from 'lodash';
import { DataFormLayout } from 'src/layout';
import {
  fetchReservedDetailGet,
  FetchReservedDetailGetResponse,
  fetchReservedUpdate,
} from 'src/api/ytt/plo-domain/reserved';
import { TagList } from 'src/components';
import { getMaterialAttrs } from 'src/entity/material';
import _Time from 'src/utils/dataTypes/time';
import { replaceSign } from 'src/utils/constants';
import lookup from 'src/dict';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';

interface Props {
  params: any;
  // name: string;
  onChange: (value?: any[]) => void;
}

const ReservationBaseForm: FC<Props> = (props) => {
  const { onChange, params } = props;
  const [reservationForm] = Form.useForm<any>();

  const [dataSource, setDataSource] = useState<FetchReservedDetailGetResponse['data']>();

  useEffect(() => {
    fetchReservedDetailGet({ id: params?.id }).then((res) => {
      setDataSource(res.data);
      reservationForm.setFieldsValue(res.data);
    });
  }, [props]);

  const getBaseInfo = () => {
    const { enablePrecision, precisionFigure } = dataSource?.supplyDetailInfo?.reservedUnit || {};

    return [
      {
        title: '基本信息',
        items: [
          {
            label: '预留编号',
            name: ['baseInfo', 'code'],
            rules: [{ required: true, message: '预留编号必填' }],
            render: () => {
              return get(dataSource, ['baseInfo', 'code']);
            },
          },
          {
            label: '状态',
            name: ['baseInfo', 'status'],
            render: () =>
              lookup('planned.reservedStatusMap', get(dataSource, ['baseInfo', 'status'])),
          },
          {
            label: '预留类型',
            render: () => {
              return (
                <Input
                  disabled
                  value={lookup(
                    'planned.reservedTypeMap',
                    get(dataSource, ['baseInfo', 'reservedType']),
                  )}
                />
              );
            },
          },
          {
            label: '物料编号',
            name: ['baseInfo', 'material', 'baseInfo', 'code'],
            render: () => {
              return get(dataSource, ['baseInfo', 'material', 'baseInfo', 'code']);
            },
          },
          {
            label: '物料名称',
            name: ['baseInfo', 'material', 'baseInfo', 'name'],
            render: () => {
              return get(dataSource, ['baseInfo', 'material', 'baseInfo', 'name']);
            },
          },
          {
            label: '物料分类',
            name: ['baseInfo', 'material', 'category', 'name'],
            render: () => {
              return get(dataSource, ['baseInfo', 'material', 'category', 'name']);
            },
          },
          {
            label: '物料规格',
            name: ['baseInfo', 'material', 'baseInfo', 'specification'],
            render: () => {
              return get(dataSource, ['baseInfo', 'material', 'baseInfo', 'specification']);
            },
          },
          {
            label: '物料属性',
            name: ['baseInfo', 'material'],
            render: () => (
              <TagList
                dataSource={getMaterialAttrs(get(dataSource, ['baseInfo', 'material']) as any)}
              />
            ),
          },
        ],
      },
      {
        title: '需求信息',
        items: [
          {
            label: '需求单据类型',
            name: ['demandDetailInfo', 'orderType'],
            rules: [{ required: true, message: '需求单据类型必填' }],
            render: () =>
              lookup(
                'planned.reqSourceTypeNameMap',
                get(dataSource, ['demandDetailInfo', 'orderType']),
              ),
          },
          {
            label: '需求单据编号',
            name: ['demandDetailInfo', 'code'],
            render: () => {
              return get(dataSource, ['demandDetailInfo', 'code']);
            },
          },
          {
            label: '需求单据分录',
            name: ['demandDetailInfo', 'seq'],
            render: () => {
              return get(dataSource, ['demandDetailInfo', 'seq']);
            },
          },
          {
            label: '需求数量',
            name: ['demandDetailInfo', 'amount'],
            render: () => {
              return get(dataSource, ['demandDetailInfo', 'amount', 'amountDisplay']);
            },
          },
          {
            label: '单位',
            name: ['demandDetailInfo', 'unitName'],
            render: () => {
              return get(dataSource, ['demandDetailInfo', 'unitName']);
            },
          },
          {
            label: '需求时间',
            name: ['demandDetailInfo', 'requirementTime'],
            render: () => {
              const text = get(dataSource, ['demandDetailInfo', 'requirementTime']);

              return text ? _Time.format(text) : replaceSign;
            },
          },
        ],
      },
      {
        title: '供应信息',
        items: [
          {
            label: '供应单据类型',
            name: ['supplyDetailInfo', 'orderType'],
            rules: [{ required: true, message: '供应单据类型必填' }],
            render: () =>
              lookup(
                'planned.reqSourceTypeNameMap',
                get(dataSource, ['supplyDetailInfo', 'orderType']),
              ),
          },
          {
            label: '供应单据编号',
            name: ['supplyDetailInfo', 'code'],
            render: () => {
              return get(dataSource, ['supplyDetailInfo', 'code']);
            },
          },
          {
            label: '供应单据分录',
            name: ['supplyDetailInfo', 'seq'],
            render: () => {
              return get(dataSource, ['supplyDetailInfo', 'seq']);
            },
          },
          {
            label: '总供应数量',
            name: ['supplyDetailInfo', 'supplyAmount'],
            render: () => {
              return get(dataSource, ['supplyDetailInfo', 'supplyAmount', 'amountDisplay']);
            },
          },
          {
            label: '单位',
            name: ['supplyDetailInfo', 'unitName'],
            render: () => {
              return get(dataSource, ['supplyDetailInfo', 'unitName']);
            },
          },
          {
            label: '供应时间',
            name: ['supplyDetailInfo', 'supplyTime'],
            render: () => {
              const text = get(dataSource, ['supplyDetailInfo', 'supplyTime']);

              return text ? _Time.format(text) : replaceSign;
            },
          },
          {
            label: '预留数量',
            name: ['supplyDetailInfo', 'reservedAmount', 'amount'],
            rules: compact([
              { required: true, message: '预留数量必填' },
              {
                validator: numberMinMaxCheck({
                  min: 0,
                  max: get(dataSource, ['supplyDetailInfo', 'supplyAmount', 'amount']),
                  minAllowEqual: false,
                  maxAllowEqual: true,
                  fieldName: '预留数量',
                }),
              },
              enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
            ]),
            render: () => {
              return <InputNumber stringMode />;
            },
          },
          {
            label: '预留单位',
            name: ['supplyDetailInfo', 'reservedUnit', 'name'],
            render: () => {
              return <Input disabled />;
            },
          },
        ],
      },
    ];
  };

  return (
    <DataFormLayout
      form={reservationForm}
      info={getBaseInfo()}
      title="编辑预留"
      onCancel={() => {
        onChange();
      }}
      onFinish={() => {
        reservationForm
          ?.validateFields()
          .then((values) => {
            const amount = values?.supplyDetailInfo?.reservedAmount?.amount;
            const id = dataSource?.baseInfo.id;

            fetchReservedUpdate({
              amount,
              id: Number(id),
            }).then(() => {
              message.success('编辑成功');
              onChange();
            });
          })
          .catch((error) => {
            const { errorFields } = error;

            reservationForm.scrollToField(errorFields?.[0]?.name);
          });
      }}
    />
  );
};

export default ReservationBaseForm;
