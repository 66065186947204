import { useState, useEffect } from 'react';
import { useLocation, RouteComponentProps } from 'react-router-dom';
import { Form, Input, message } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { SearchSelect } from 'src/components';
import {
  fetchReceiveNoteDetail,
  FetchReceiveNoteDetailResponse,
  fetchReceiveNoteItemDetail,
  fetchReceiveNoteUpdate,
} from 'src/api/ytt/order-domain/deliveryOrder';
import lookup, { appEnum } from 'src/dict';
import _ from 'lodash';
import qs from 'qs';
import MaterialTable from './components/materialTable';
import moment from 'moment';
import { formatFetchValue } from './utils';
import { UsageStatus } from 'src/dict/common';
import EditableText from 'src/components/editableText';

interface DetailPageProps extends RouteComponentProps {}
type DataResource = Exclude<FetchReceiveNoteDetailResponse['data'], undefined>;
const { SourceEnum, execStatusEnum } = appEnum.DeliveryEnum;
const EditPage = (props: DetailPageProps) => {
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  const [form] = Form.useForm();
  const { history } = props;

  const [dataSource, setDataSource] = useState<DataResource>({});

  const fetchData = async () => {
    try {
      if (!query.id) {
        return;
      }
      const { data = {} } = await fetchReceiveNoteDetail({ id: Number(query.id) });
      const res = await fetchReceiveNoteItemDetail({ id: Number(query.id) });
      const supplier = `${data?.supplierCode} / ${data?.supplierName}`;

      setDataSource({ ...data });
      const itemList = res.data?.map((node, index: number) => {
        const { seqNum, purchaseOrderItemId, material, id } = node;

        return {
          id,
          material,
          returnAmount: 1,
          unitName: material?.unit?.name,
          unitId: material?.unit?.id,
          purchaseOrderCode: node?.purchaseOrderCode,
          purchaseOrderId: node?.purchaseOrderId,
          purchaseOrderItemSeqNum: node?.purchaseOrderItemSeqNum,
          deliveryScheduleNoteCode: node?.deliveryScheduleNoteCode,
          deliveryScheduleNoteLineNo: node?.deliveryScheduleNoteLineNo,
          supplier: { id: data?.supplierId, name: data?.supplierName, code: data?.supplierCode },
          seqNum,
          purchaseOrderItemId,
          planReceiveTime: moment(node.planReceiveTime),
          planReceiveAmount: node?.planReceiveAmount?.amount,
        };
      });

      form.setFieldsValue({
        remark: data.remark,
        supplier,
        itemList,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,
    title: '基本信息',
    items: [
      { label: '编号', render: () => dataSource?.code },
      {
        label: '来源',
        render: () => lookup('DeliveryMap.Source')(dataSource?.source),
      },
      {
        label: '供应商',
        name: 'supplier',
        render: () => <EditableText canEdit={false} />,
      },
      {
        label: '备注:',
        name: 'remark',
        rules: [{ max: 1000, message: '不超过1000个字符' }],
        render: () => <Input.TextArea placeholder="请输入" />,
      },
      {
        label: '编辑原因:',
        name: 'operateReason',
        rules: [{ max: 1000, message: '不超过1000个字符' }],
        render: () => <Input.TextArea placeholder="请输入" />,
      },
    ],
  };
  const material: DataFormLayoutInfoBlock = {
    title: '物料信息',
    items: [
      {
        label: '',
        name: 'itemList',
        isFullLine: true,
        required: true,
        render: () => (
          <MaterialTable
            form={form}
            name="itemList"
            source={dataSource.source}
            disabled={dataSource.execStatus !== execStatusEnum.created}
          />
        ),
      },
    ],
  };

  const onCancel = () => {
    history.goBack();
  };

  const onFinish = async () => {
    form
      .validateFields()
      .then((values) => {
        const newValues = formatFetchValue(values);

        fetchReceiveNoteUpdate({
          ...newValues,
          id: Number(query.id),
          source: dataSource.source,
        })
          .then((res) => {
            if (res && res.code === 200) {
              message.success('编辑成功');
              onCancel();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        if (err?.errorFields?.length) {
          message.error(err?.errorFields[0].errors[0]);
        }
      });
  };

  return (
    <DataFormLayout
      onCancel={onCancel}
      onFinish={onFinish}
      form={form}
      title="编辑收货单"
      info={[baseInfo, material]}
    />
  );
};

export default EditPage;
