/*
 * @Author:
 * @Date: 2021-05-14 09:09:54
 * @LastEditors: Vicky Yu
 * @LastEditTime: 2021-05-14 10:40:29
 * @Description:
 * @FilePath: /web-v3/src/custom_fields/src/views/fieldsList/components/verificationRules/rules.ts
 */

import { RuleObject } from 'antd/lib/form';

/**
 * @description: 必须是字母、数字、下划线
 * @param {*} value
 * @return {*}
 */
export const inputText1Rules = (value: any): boolean => {
  const reg = /^[a-zA-Z0-9_]+$/;

  return reg.test(value);
};

/**
 * @description: 必须是字母、中文、支持所有特殊字符
 * @param {*} value
 * @return {*}
 */
export const inputText2Rules = (value: any): boolean => {
  const reg =
    /^[a-zA-Z\u4e00-\u9fa5`~!@#$%^&*()_\-+=<>?:" {}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、Ω≈ç√˜µ≤≥÷…˚æ“‘«”「」’]+$/;

  return reg.test(value);
};
/**
 * @description: 必须是字母、数字、支持所有特殊字符
 * @param {*} value
 * @return {*}
 */
export const inputText3Rules = (value: any): boolean => {
  const reg =
    /^[a-zA-Z0-9`~!@#$%^&*()_\-+=<>?:" {}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、Ω≈ç√˜µ≤≥÷…˚æ“‘«”「」’]+$/;

  return reg.test(value);
};

/**
 * @description: 必须是整数
 * @param {*} value
 * @return {*}
 */
export const inputtNumberIntRules = (value: any): boolean => {
  const reg = new RegExp('^-?\\d*$');

  return reg.test(value);
};

/**
 * @description: 必须当前数值比原数值大
 * @param {number | undefined} preNumber
 * @return {*}
 */
export const inputCompareNumber = (preNumber: number | undefined, name: string = '') => {
  return (_rule: RuleObject, value: string | number) => {
    return new Promise<void>((resolve, reject) => {
      if (preNumber && value < preNumber) reject(`${name}当前数值必须必原数值大`);
      resolve();
    });
  };
};

/**
 * @description: 必须是在区间内
 * @param {*} value
 * @return {*}
 */
export const inputNumberIntervalRules = (
  value: any,
  min: number,
  max: number,
  minAllowEqual = true,
  maxAllowEqual = true,
  name: string,
): { res: boolean; mes?: string } => {
  if (maxAllowEqual && minAllowEqual) {
    if (value < min || value > max) {
      return { res: false, mes: `${name}必须是在 [ ${min}~${max} ] 之间` };
    }
  } else {
    if (maxAllowEqual) {
      if (value <= min || value > max) {
        return { res: false, mes: `${name}必须是在 ( ${min}~${max} ] 之间` };
      }

      return { res: true };
    }
    if (minAllowEqual) {
      if (value < min || value >= max) {
        return { res: false, mes: `${name}必须是在 [ ${min}~${max} ) 之间` };
      }

      return { res: true };
    }
  }

  return { res: true };
};

/**
 * @description: 必须是网址
 * @param {*} value
 * @return {*}
 */
export const urlRules = (value: any): boolean => {
  const reg = /(http|https|ftp|ftps):\/\/([\w.]+\/?)\S*/;

  return reg.test(value);
};

/**
 * @description: 必须是数值
 * @param {*} value
 */
export const numberRules = (value: any): boolean => {
  const reg = /^(-?\d+)(\.\d+)?$/;

  return reg.test(value);
};

/**
 * @description: 小数位数不能超过最大值
 * @param  {number} value
 * @param {number} maxDigtis
 */
export const maxDigtisRules = (value: number, maxDigtis: number): boolean => {
  const decimalPart = value && value.toString().split('.')[1];

  if (decimalPart && decimalPart.length > maxDigtis) {
    return false;
  }
  return true;
};
