import { FormInstance, Form, Select, Input } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { LabeledValue } from 'antd/lib/select';
import { ColumnProps } from 'antd/es/table';
import _ from 'lodash';
import { BlSortFormList, SearchSelectWithCreate } from 'src/components';
import { ResourceBusinessType } from 'src/dict/resources';
import { FetchResourcesLabelGetLabelTreeResponse } from 'src/api/ytt/resource-domain/resourceTag';
import { ListItemType, GetElementType } from 'src/types';

type ResourceTagListItemType = ListItemType<FetchResourcesLabelGetLabelTreeResponse>;
type ResourceTagChildType = GetElementType<ResourceTagListItemType['children']>;

interface ResourceTagFormListProps {
  form: FormInstance;
  formFieldName?: string;
  tagLabelFieldName?: string;
  tagValueFieldName?: string;
}

export default function ResourceTagFormList({
  form,
  formFieldName = 'malfunctionTagsLink',
  tagLabelFieldName = 'tagParentId',
  tagValueFieldName = 'tagsId',
}: ResourceTagFormListProps) {
  /**
   * 校验设备标签/设备参数,不能重复
   */
  const validateDuplicated = (listField: string, field: string, message: string) => {
    return (rule: any, value: LabeledValue) => {
      if (!value) return Promise.resolve('');
      const valueId = value;
      const formList = form.getFieldValue(listField);

      if (!formList?.length) return Promise.resolve('');
      // 新添加未做任何编辑的行，在formList里的值不是object，是空字符串
      const objList = formList?.map((formItem: any) => {
        return _.isObject(formItem as any) ? formItem : undefined;
      });

      const firstIndex = _.findIndex(objList, { [field]: valueId });
      const lastIndex = _.findLastIndex(objList, { [field]: valueId });

      if (firstIndex !== lastIndex) {
        return Promise.reject(message);
      }

      return Promise.resolve('');
    };
  };

  const getTagColumns: (
    remove: (index: number | number[]) => void,
  ) => ColumnProps<any & FormListFieldData>[] = () => [
    {
      title: '标签项',
      width: 140,
      render: (text, field) => (
        <>
          <Form.Item fieldKey={[field.fieldKey, 'id']} name={[field.name, 'id']} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name={[field.name, tagLabelFieldName]}
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: '已添加的标签项必填',
              },
              {
                validator: validateDuplicated(
                  formFieldName,
                  tagLabelFieldName,
                  '不能选择重复的设备标签',
                ),
              },
            ]}
          >
            <SearchSelectWithCreate
              placeholder="请选择标签项"
              fetchType="resourceTagTreeFormat"
              createFieldName="标签"
              params={{ businessType: ResourceBusinessType.malfunction }}
              onCreateClick={() => {}}
              enableAdd={false}
              onChange={() => {
                form.resetFields([[formFieldName, field.name, tagValueFieldName]]);
                form.validateFields();
              }}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: '标签值',
      width: 140,
      render: (text, field) => {
        return (
          <Form.Item dependencies={[[formFieldName, field.name, tagLabelFieldName]]} noStyle>
            {() => {
              const tagValuesString = form.getFieldValue([
                formFieldName,
                field.name,
                tagLabelFieldName,
              ]);
              const tagValues = tagValuesString && JSON.parse(tagValuesString.value);

              return (
                <Form.Item
                  name={[field.name, tagValueFieldName]}
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: '已添加的标签值必填',
                    },
                  ]}
                >
                  <Select
                    placeholder="请选择标签值"
                    showSearch
                    filterOption={(input, option) =>
                      String(option?.label).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={tagValues?.children?.map((tagValue: ResourceTagChildType) => ({
                      label: tagValue.content,
                      value: tagValue.id,
                    }))}
                    onChange={() => {
                      form.validateFields();
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
  ];

  return (
    <BlSortFormList
      name={formFieldName}
      maxCount={10}
      isNeedDrag={false}
      renderColumns={getTagColumns}
      form={form}
    />
  );
}
