import { RecordListLayout } from 'layout';
import React, { useMemo, useState } from 'react';
import { fieldTypeList } from 'utils';
import { fetchBatchNoList } from 'src/api/ytt/material-domain/Batch_No';
import { SearchSelect } from 'src/components';
import { mappingMaterialInfoAttrList } from 'src/page/warehouseManagement/utils';
import _ from 'lodash';
import BatchDetail from 'src/page/warehouseManagement/batchManagement/batchDetail';
import { BlIcon } from 'components';
import CreateAndEdit from '../createAndEdit';
import { replaceSign } from 'src/utils/constants';
import { FetchBatchNumberItem } from '../type';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import _Time from 'src/utils/dataTypes/time';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import authDict, { hasAuth } from 'src/utils/auth';
import { intersectionValueOfId } from 'src/utils/array';
import BcBlCascader from 'src/components/Cascader';
import MaterialAttributeCascader, {
  formatMaterialAttributeToQuery,
} from 'src/page/knowledgeManagement/materialModeling/material/share/Cascader/BcMultiCascader';
import { TextToolTip } from '@blacklake-web/component';
import { toEditUrl } from '../navigation';

interface Params {
  page: number;
  size: number;
  quickSearch?: string;
  selectFlag?: string;
  materialInfo?: string;
  materialCode?: string;
  batchNo?: string;
}

/**
 * 排序对应字段值
 */
const sortKeyMap = {
  batchNo: 'batchNo',
  supplierBatch: 'supplierBatchNo',
  produceAt: 'productTime',
  validTo: 'validityPeriod',
  createAt: 'createAt',
};

const BatchList = () => {
  const history = useHistory();
  const [visible, setVisible] = useState<boolean>(false);
  const [createAndeditVisible, setCreateAndeditVisible] = useState(false);
  const [batchRecord, setBatchRecord] = useState<FetchBatchNumberItem | undefined>(undefined);
  const [, setRefreshMarker] = useState<number | undefined>(undefined);
  const [refreshDetailsMarker, setRefreshDetailsMarker] = useState<number | undefined>(undefined);
  const { search } = useLocation();
  const query: { createBatch?: boolean } = qs.parse(search, { ignoreQueryPrefix: true });

  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const view = useMemo(() => {
    if (query?.createBatch) {
      setCreateAndeditVisible(true);
    }
    const dataColumns = [
      {
        title: '批次号',
        dataIndex: 'batchNo',
        width: 150,
        sorter: true,
        fixed: 'left',
        isFilter: true,
        render: (curTarget: any, record: any, index: number, config: any) => {
          if (curTarget && hasAuth(authDict.batchno_detail)) {
            return (
              <a
                onClick={() => {
                  setVisible(true);
                  setBatchRecord(record);
                }}
              >
                <TextToolTip text={curTarget} width={config.contentWidth} />
              </a>
            );
          }
          return <div>{curTarget || replaceSign}</div>;
        },
      },
      {
        title: '物料名称',
        dataIndex: ['materialInfo', 'baseInfo', 'name'],
        filterIndex: 'materialNameIds',
        width: 150,
        isFilter: true,
        renderItem: (
          <SearchSelect
            fetchType="material"
            labelPath="name"
            searchFieldName="name"
            mode="multiple"
          />
        ),
      },
      {
        title: '物料编码',
        dataIndex: ['materialInfo', 'baseInfo', 'code'],
        filterIndex: 'materialCodeIds',
        width: 150,
        isFilter: true,
        renderItem: (
          <SearchSelect
            fetchType="material"
            labelPath="code"
            searchFieldName="code"
            mode="multiple"
          />
        ),
      },
      {
        title: '物料属性',
        dataIndex: ['materialInfo', 'attribute'],
        filterIndex: 'materialAttributeIds',
        width: 150,
        isFilter: true,
        render: mappingMaterialInfoAttrList,
        renderItem: <MaterialAttributeCascader />,
      },
      {
        title: '物料分类',
        dataIndex: ['materialInfo', 'category', 'name'],
        filterIndex: 'materialCategoryIds',
        width: 150,
        isFilter: true,
        renderItem: (
          <BcBlCascader
            fetchType={'materialCategory'}
            nameLabel={'物料分类'}
            popupPlacement={'bottomRight'}
          />
        ),
      },
      {
        title: '物料规格',
        dataIndex: ['materialInfo', 'baseInfo', 'specification'],
        filterIndex: 'materialSpecificationIds',
        isFilter: true,
        width: 150,
        renderItem: (
          <SearchSelect
            fetchType="material"
            labelPath="specification"
            searchFieldName="specification"
            mode="multiple"
          />
        ),
      },
      {
        title: '供应商',
        dataIndex: ['supplier', 'name'],
        filterIndex: 'supplierIds',
        width: 150,
        isFilter: true,
        renderItem: <SearchSelect fetchType={'supplier'} mode="multiple" />,
      },
      {
        title: '供应商批次',
        dataIndex: 'supplierBatch',
        sorter: true,
        width: 150,
        isFilter: true,
      },
      {
        title: '客户',
        dataIndex: ['customer', 'name'],
        filterIndex: 'customerIds',
        width: 150,
        isFilter: true,
        renderItem: <SearchSelect fetchType={'customer'} mode="multiple" />,
      },
      {
        title: '生产日期',
        dataIndex: 'produceAt',
        sorter: true,
        width: 180,
        render: (value: number) => (value ? _Time.formatDate(value) : replaceSign),
      },
      {
        title: '有效期至',
        dataIndex: 'validTo',
        sorter: true,
        width: 180,
        render: (value: number) => (value ? _Time.formatDate(value) : replaceSign),
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        sorter: true,
        width: 180,
        render: (value: number) => (value ? _Time.formatDate(value) : replaceSign),
      },
    ];
    const getOperationList = (record: any) => {
      const { materialId, batchNo } = record;

      return [
        {
          title: '查看',
          key: 'view',
          onClick: () => {
            setVisible(true);
            setBatchRecord(record);
          },
          auth: authDict.batchno_detail,
        },
        {
          title: '编辑',
          key: 'edit',
          onClick: () => {
            history.push({
              pathname: toEditUrl(),
              state: {
                materialId,
                batchNo,
              },
            });
          },
          auth: authDict.batchno_edit,
        },
      ];
    };
    const filterList = dataColumns
      .filter((i) => i?.isFilter)
      .map((columns: any) => ({
        ...columns,
        label: columns.label ?? columns.title,
        name: columns?.filterIndex ?? columns.dataIndex,
      }));
    const leftMenu = [
      {
        title: '导出',
        showExport: true,
        auth: authDict.batchno_export,
        icon: <BlIcon type="icondaochu" />,
        onClick: () =>
          openModal({
            optType: 'export',
            businessType: BUSINESS_TYPE.batchNo,
          }),
      },
      {
        title: '新建批次',
        auth: authDict.batchno_add,
        icon: <BlIcon type="iconxinjiantianjia" />,
        onClick: () => {
          setBatchRecord(undefined);
          setCreateAndeditVisible(true);
        },
        items: [
          {
            title: '导入',
            showExport: true,
            auth: authDict.batchno_import,
            onClick: () =>
              openModal({
                optType: 'import',
                businessType: BUSINESS_TYPE.batchNo,
              }),
          },
        ],
      },
    ];
    const batchNo = batchRecord?.batchNo;
    const materialId = batchRecord?.materialInfo?.baseInfo?.id;

    const formatDataToQuery = (params: any) => {
      const {
        materialCodeIds,
        materialNameIds,
        materialAttributeIds,
        materialSpecificationIds,
        supplierIds,
        customerIds,
        sorter,
        ...rest
      } = params;

      const _params: Params = {
        ...rest,
        sorter: !_.isEmpty(sorter)
          ? _.map(sorter, ({ field, order }) => ({ field: _.get(sortKeyMap, field, field), order }))
          : undefined,
        materialIds: intersectionValueOfId(
          materialCodeIds,
          materialNameIds,
          materialSpecificationIds,
        ),
        materialAttributeIds: _.map(
          formatMaterialAttributeToQuery(materialAttributeIds),
          'valueId',
        ),
        supplierIds: !_.isEmpty(supplierIds) ? _.map(supplierIds, 'value') : undefined,
        customerIds: !_.isEmpty(customerIds) ? _.map(customerIds, 'value') : undefined,
      };

      dispatch.excelImport.updateBusinessData({ businessData: _params });
      return _params;
    };

    const formatDataToDisplay = (params: any) => {
      return params;
    };

    return (
      <>
        <RecordListLayout
          rowKey="id"
          configcacheKey="batchNoManagement_batch_list"
          columns={dataColumns.map((i) => ({ ...i }))}
          formatDataToQuery={formatDataToQuery}
          formatDataToDisplay={formatDataToDisplay}
          filterList={filterList}
          requestFn={fetchBatchNoList}
          mainMenu={leftMenu}
          getOperationList={getOperationList}
        />
        {visible && (
          <BatchDetail
            batchNo={batchNo as string}
            materialId={materialId as number}
            visible={visible}
            refreshDetailsMarker={refreshDetailsMarker}
            onClose={() => setVisible(false)}
          />
        )}
        {createAndeditVisible && (
          <CreateAndEdit
            batchNo={batchNo as string}
            materialId={materialId as number}
            visible={createAndeditVisible}
            onCancel={() => setCreateAndeditVisible(false)}
            refreshTable={() => {
              setRefreshMarker(new Date().getTime());
            }}
            refreshDetails={() => {
              setRefreshDetailsMarker(new Date().getTime());
            }}
          />
        )}
      </>
    );
  }, [visible, createAndeditVisible]);

  return view;
};

export default BatchList;
