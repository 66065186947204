import React, { useState } from 'react';
import { Select, Space } from 'antd';
import moment, { Moment } from 'moment';

import { BlIcon } from 'src/components';
import styles from './index.module.scss';

interface CalendarDateSwitcherProps {
  value: Moment;
  onChange: (newVal: Moment) => void;
  startDate?: Moment;
  endDate?: Moment;
  switcherClassName?: string;
  iconClassName?: string;
  confirmChange?: () => Promise<boolean>;
}

export default function CalendarDateSwitcher(props: CalendarDateSwitcherProps) {
  const { value, onChange, switcherClassName, iconClassName, startDate, endDate, confirmChange } =
    props;
  const [selectedYear, setSelectedYear] = useState(value.year());

  const monthOptions = [];

  const current = value.clone();
  const localeData = value.localeData();
  const months = [];

  // 根据选中的年份，计算出可选的月份及其在下拉框中的Option
  for (let i = 0; i < 12; i++) {
    current.month(i);
    months.push(localeData.monthsShort(current));
    if (moment().year(selectedYear).month(i).isBetween(startDate, endDate, 'month', '[]')) {
      monthOptions.push(
        // <Select.Option value={months[i]} className="month-item" key={`${i}`}>
        //   {months[i]}
        // </Select.Option>,
        {
          label: months[i],
          value: i,
        },
      );
    }
  }

  const month = value.month();
  const year = value.year();
  const startYear = startDate?.year();
  const endYear = endDate?.year();
  const options = [];

  for (let i = startYear ?? year - 10; i <= (endYear ?? year + 9); i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}年
      </Select.Option>,
    );
  }

  const switchToLastMonth = () => {
    if (value.isSame(startDate, 'month')) return;
    const newValue = value.clone();

    newValue.month(value.month() - 1);
    onChange(newValue);
  };
  const switchToNextMonth = () => {
    if (value.isSame(endDate, 'month')) return;
    const newValue = value.clone();

    newValue.month(value.month() + 1);
    onChange(newValue);
  };

  const switchYear = (newYear: string) => {
    const target = value.clone().year(Number.parseInt(newYear, 10));

    setSelectedYear(Number.parseInt(newYear, 10));
    // 如果切换年份以后，拼接原来选中的月份超出日历范围，则重新设置月份
    if (startDate && endDate) {
      if (target.isBefore(startDate)) {
        onChange(startDate);
        return;
      } else if (target.isAfter(endDate)) {
        onChange(endDate);
        return;
      }
    }
    onChange(target);
  };

  const confirmBeforeChange = (
    callBackAfterConfirm: (...params: any[]) => void,
    ...rest: any[]
  ) => {
    if (confirmChange) {
      confirmChange().then((result) => {
        if (result) callBackAfterConfirm(...rest);
      });
    } else {
      callBackAfterConfirm(...rest);
    }
  };

  return (
    <div className={switcherClassName}>
      <Space>
        <Select
          size="middle"
          dropdownMatchSelectWidth={false}
          className="my-year-select"
          onChange={(newValue) => {
            confirmBeforeChange(switchYear, newValue);
          }}
          value={`${year}年`}
        >
          {options}
        </Select>
        <BlIcon
          type="iconxiangzuo"
          className={[iconClassName, value.isSame(startDate, 'month') && styles.disabled].join(' ')}
          onClick={() => {
            confirmBeforeChange(switchToLastMonth);
          }}
          size={16}
        />
        <Select
          size="middle"
          dropdownMatchSelectWidth={false}
          value={{ label: months[month], value: month }}
          labelInValue
          options={monthOptions}
          onChange={(selectedMonth) => {
            confirmBeforeChange((selectedMonth) => {
              const newValue = value.clone();

              newValue.month(selectedMonth.value);
              onChange(newValue);
            }, selectedMonth);
          }}
        />

        <BlIcon
          type="iconxiangyou"
          className={[iconClassName, value.isSame(endDate, 'month') && styles.disabled].join(' ')}
          onClick={() => {
            confirmBeforeChange(switchToNextMonth);
          }}
          size={16}
        />
      </Space>
    </div>
  );
}
