import React from 'react';
import { DataFormLayoutForModal } from 'layout';
import BaseInfo from './baseInfo';
import { FetchMaterialCategoryListResponse } from 'src/api/ytt/material-domain/Web_MaterialCategory';

interface Props {
  visible: Boolean;
  type: number;
  refreshTable?: () => void;
  refreshDetails: () => void;
  onCancel: () => void;
  id?: number | undefined;
  record?: FetchMaterialCategoryListResponse;
}

const CreateAndEdit = (props: Props) => {
  const { visible, ...rest } = props;

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      width={800}
      content={<BaseInfo {...rest} />}
      onClose={props.onCancel}
    />
  );
};

export default CreateAndEdit;
