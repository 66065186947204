import React, { useEffect, useState } from 'react';
import { Form, message as Message } from 'antd';
import BaseInfo from './component/createAndEditInfo';
import { SourceDataType } from './interface';
import {
  formatCreateAndEditValue,
  formatDemo,
  formatElementList,
  formatDetailElementFields,
} from './utils';
import { match, RouteComponentProps } from 'react-router-dom';
import { fetchNumberRuleDetail, fetchNumberRuleUpdate } from 'src/api/ytt/custom-domain';
import { throttleFn } from '../knowledgeManagement/warehouse/utils';

interface editProps extends RouteComponentProps {
  match: match<{ id: string }>;
  location: any;
}

const EditNumberRules = (props: editProps) => {
  const { match, history, location } = props;
  const numberRuleId = Number(match?.params?.id);
  const {
    state: { formatConfigData, suitObjectData },
  } = location;

  const [detailData, setDetailData] = useState({} as any);

  const [editForm] = Form.useForm();

  const [demo, setDemo] = useState('');

  const [sourceData, setSourceData] = useState<SourceDataType>({} as any);

  const fetchData = async () => {
    try {
      const { data } = await fetchNumberRuleDetail({ id: numberRuleId }, { legacy: true });

      if (data) {
        const newElementList = formatDetailElementFields(data?.elementList, 'edit');

        const newDetailElementList = formatDetailElementFields(data?.elementList, 'detail');

        const demoStr = formatDemo(newDetailElementList, formatConfigData);

        setDemo(demoStr);

        setDetailData({ ...data, elementList: newElementList });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [numberRuleId]);

  const setSourceDataFn = (value: any) => {
    setSourceData(value);
  };

  const handleSetDemo = (value: string) => {
    setDemo(value);
  };

  const handleFinish = async () => {
    try {
      const value = await editForm?.validateFields();

      const { elementList, suitObjId, ...rest } = value;

      const _elementList = formatElementList(elementList, sourceData);

      const _value = { ...rest, elementList: _elementList };

      const newValue = formatCreateAndEditValue(_value, formatConfigData);

      const lastValue = { ...newValue, id: numberRuleId, suitObjId: suitObjId?.value };

      const { code, message } = await fetchNumberRuleUpdate(lastValue);

      if (code === 200) {
        Message.success('更新成功');
        history.goBack();
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <BaseInfo
      onFinish={throttleFn(handleFinish)}
      onCancel={() => {
        history.goBack();
      }}
      title="编辑编号规则"
      form={editForm}
      edit
      initialData={detailData}
      setSourceDataFn={setSourceDataFn}
      formatConfigData={formatConfigData}
      suitObjectData={suitObjectData}
      handleSetDemo={(value: string) => {
        handleSetDemo(value);
      }}
      demo={demo}
    />
  );
};

export default EditNumberRules;
