import { useState } from 'react';
import { Form, FormInstance, Select, Modal, message } from 'antd';
import { DataFormLayoutForModal, DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Button, BlIcon } from '@blacklake-web/component';
import {
  fetchQcAqlInspectionLevelList,
  fetchQcAqlAcceptLimitList,
  fetchQcAqlQueryList,
} from 'src/api/ytt/quality-domain/qc_aql';
interface Props {
  aqlForm: FormInstance;
  sampleData?: any;
  style?: any;
  setSampleData?: any;
}

interface copyResponseProps {
  aqlAcceptLimitVO?: any;
  sampleList?: any;
}

const { Option } = Select;
const aqlTypes = [
  { id: 1, name: '正常' },
  { id: 2, name: '加严' },
  { id: 3, name: '放宽' },
];

function CopyAql(props: Props) {
  const { aqlForm, setSampleData, ...rest } = props;
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const [copyVisible, setcopyVisible] = useState<boolean>(false);
  const [inspectionLevelList, setInspectionLevelList] = useState<any[]>([]);
  const [aqlList, setAqlList] = useState<any[]>([]);
  const [copyResponse, setCopyResponse] = useState<copyResponseProps | undefined>();

  const getInspectionLevelList = async () => {
    const { data } = await fetchQcAqlInspectionLevelList();

    setInspectionLevelList(data?.list || []);
  };
  const getAqlList = async () => {
    const { data } = await fetchQcAqlAcceptLimitList();
    const result: any[] = [];

    if (data) {
      aqlTypes.forEach((t: any) => {
        result.push(
          ...data.list?.map((item: any) => {
            return {
              label: `${t.name}-${item.value}`,
              value: JSON.stringify({
                qcAqlCategory: t.id,
                qcAqlId: item.id,
              }),
            };
          }),
        );
      });
    }
    setAqlList(result || []);
  };
  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,
    items: [
      {
        label: '检验水平',
        name: 'qcAqlInspectionLevelId',
        rules: [{ required: true, message: '检验水平不能为空' }],
        render: () => (
          <Select placeholder="请选择" onFocus={getInspectionLevelList}>
            {inspectionLevelList.map((item: any) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        label: '接收质量限',
        name: 'ids',
        rules: [{ required: true, message: '接收质量限不能为空' }],
        render: () => (
          <Select onFocus={getAqlList} placeholder="请选择">
            {aqlList.map((item: any) => (
              <Option key={item.label} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        ),
      },
    ],
  };
  const onCancel = () => setVisible(false);
  const importAQL = (data?: any) => {
    // 复制国标的样本量和接收质量限表数据到AQL表单
    const { aqlAcceptLimitVO = [], sampleList = [] } = data || copyResponse || {};
    const { aqlAcceptLimitDetailVOList = [] } = aqlAcceptLimitVO || {};
    const formatSampleList = sampleList.map((item: any, key: number) => {
      if (key === sampleList.length - 1 && item.maxBatch === null) {
        return { ...item, maxBatch: '999999999999999' };
      }
      return item;
    });

    const formatAcceptLimit = aqlAcceptLimitDetailVOList.map((item: any, key: number) => {
      if (key === aqlAcceptLimitDetailVOList.length - 1 && item.maxBatch === null) {
        return { ...item, maxBatch: '999999999999999' };
      }
      return item;
    });

    setSampleData(formatSampleList);
    aqlForm?.setFieldsValue({
      sampleList: formatSampleList,
      acceptLimitList: [
        {
          name: '',
          limit: formatAcceptLimit,
        },
      ],
    });
  };
  const onFinish = async () => {
    const values = await form.validateFields();
    const ids = JSON.parse(values.ids);

    const { data } = await fetchQcAqlQueryList({
      qcAqlInspectionLevelId: values.qcAqlInspectionLevelId,
      qcAqlId: ids.qcAqlId,
      qcAqlCategory: ids.qcAqlCategory,
    });
    const { sampleList } = await aqlForm.getFieldsValue();

    setCopyResponse(data);
    if (sampleList && sampleList.length) {
      setcopyVisible(true);
    } else {
      message.success('复制成功');
      importAQL(data);
      onCancel();
    }
  };

  return (
    <>
      <Button icon={<BlIcon type="iconfuzhi" />} onClick={() => setVisible(true)} {...rest}>
        复制国标AQL
      </Button>
      <DataFormLayoutForModal
        width={600}
        visible={visible}
        onClose={onCancel}
        content={
          <DataFormLayout
            onFinish={onFinish}
            onCancel={onCancel}
            form={form}
            title="复制国标AQL"
            info={[baseInfo]}
          />
        }
      />
      <Modal
        title=""
        zIndex={99999999}
        visible={copyVisible}
        onOk={() => {
          importAQL();
          onCancel();
          setcopyVisible(false);
        }}
        onCancel={() => setcopyVisible(false)}
        okText="确认"
        cancelText="取消"
      >
        <p>当前存在已配置的自定义信息</p>
        <p>复制将会覆盖已配置信息，你还要继续吗？</p>
      </Modal>
    </>
  );
}

export default CopyAql;
