import { DetailLayout, DetailLayoutInfoItem } from '@blacklake-web/layout';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { fetchLayoutDetailByObjectCode } from 'src/api/ytt/layout-domain';
import { LayoutItemType, PageType } from 'src/dict/customLayout';
import { DetailLayoutInfoBlock } from 'src/layout/detail';
import { FormLayoutConfigProps, FormLayoutRootConfigProps } from '../types';
import { formatDetailDisplayData, formatLayoutConfigData } from '../uitls/dataFormat';
import { FieldDTO } from 'src/components/customLayout/types';
import {
  getDisplayNodeRenderer,
  getSubObjectDisplayNodeRenderer,
} from 'src/page/customLayout/renderUtils';
import { Tabs } from 'antd';
import {
  fetchCustomObjectDetail,
  FetchCustomObjectDetailResponse,
} from 'src/api/ytt/custom-object-domain';
import { fetchStandardBizObjectCustomObjectGetSonObjectAndFieldById } from 'src/api/ytt/metadata-domain/objectPlatform';
import { FieldType, TargetType } from 'src/dict/common';
import { FORMAT_RULE_LINE } from 'src/utils/dataTypes/time';
import { fetchCustomFieldGetListByObjectCode } from 'src/api/ytt/metadata-domain/customField';

interface ICalculateForm {
  (layoutConfig: FormLayoutConfigProps): typeof layoutConfig extends FormLayoutRootConfigProps
    ? DetailLayoutInfoBlock
    : DetailLayoutInfoItem | null | React.ReactNode;
}

export const useCustomLayoutForDetail = (props: {
  /* 用于加载实例数据及布局配置 */
  objectCode: string;
  /* 用于加载实例数据 */
  instanceId: number;
  layoutId: number;
  /* 为指定字段传入定制化配置，覆盖默认配置 */
  customInfoItems?: DetailLayoutInfoItem[];
}) => {
  const { objectCode, instanceId, layoutId, customInfoItems } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [configLoading, setConfigLoading] = useState<boolean>();
  const [layoutConfig, setLayoutConfig] = useState<FormLayoutRootConfigProps[]>();
  const [dataSource, setDataSource] = useState<any>({});
  const [fieldsInfo, setFieldsInfo] = useState<FieldDTO[]>([]);
  const [subObjectList, setSubObjectList] = useState<any[]>([]);

  /**获取对象下的配置信息，暂定参数为objectCode */
  const fetchLayoutConfig = async ({ objectCode }: { objectCode: string }) => {
    const { data } = await fetchLayoutDetailByObjectCode({
      objectCode,
      type: PageType.detail,
      instanceId,
    });
    const layoutData = data?.find((i) => i.id === layoutId);
    setConfigLoading(false);

    setLayoutConfig(formatLayoutConfigData(layoutData?.components));
  };

  const formatObjecttFieldsInfo = (data: FetchCustomObjectDetailResponse['data']) => {
    const operateInfo = [
      {
        type: LayoutItemType.field,
        fieldCode: 'creator',
        fieldName: '创建人',
        fieldType: FieldType.relation,
        reference: 'User',
        targetType: TargetType.singleChoice,
      },
      {
        type: LayoutItemType.field,
        fieldCode: 'createdAt',
        fieldName: '创建时间',
        fieldType: FieldType.date,
        datetimeFormat: FORMAT_RULE_LINE,
      },
      {
        type: LayoutItemType.field,
        fieldCode: 'operator',
        fieldName: '更新人',
        fieldType: FieldType.relation,
        reference: 'User',
        targetType: TargetType.singleChoice,
      },
      {
        type: LayoutItemType.field,
        fieldCode: 'updatedAt',
        fieldName: '更新时间',
        fieldType: FieldType.date,
        datetimeFormat: FORMAT_RULE_LINE,
      },
    ];
    return (data?.fields || []).concat(operateInfo);
  };

  /**获取业务数据 */
  const fetcData = async ({ instanceId }: { instanceId: number }) => {
    setConfigLoading(true);
    const { data } = await fetchCustomObjectDetail({
      instanceId: _.toNumber(instanceId),
      objectCode,
    });
    const { data: fieldsData } = await fetchCustomFieldGetListByObjectCode({ objectCode });
    const { data: sonObjects } = await fetchStandardBizObjectCustomObjectGetSonObjectAndFieldById({
      objectCode,
    });
    if (data?.fields) {
      data.fields = data?.fields.map((field) => ({
        ...fieldsData?.find(({ id }) => id === field.fieldId),
        ...field,
      }));
    }
    setFieldsInfo(formatObjecttFieldsInfo(data));
    setSubObjectList(sonObjects || []);
    setDataSource(data);
    fetchLayoutConfig({ objectCode });
  };

  useEffect(() => {
    fetcData({ instanceId });
  }, []);

  // 递归渲染详情页
  const calculateForm: ICalculateForm = (layoutConf) => {
    // 信息块
    if (layoutConf.type === LayoutItemType.infoBlock) {
      const { title, children } = layoutConf;

      return {
        title,
        items: (children ?? []).map(calculateForm),
      };
    }

    // 字段
    if (layoutConf.type === LayoutItemType.field) {
      const { fieldPath, fieldName } = layoutConf;
      const fieldInfo = _.find(fieldsInfo, (i) => i.fieldCode === layoutConf.fieldPath) || {};
      const customItem = _.find(customInfoItems, (i) => i.dataIndex === fieldPath);

      return (
        customItem || {
          dataIndex: fieldPath,
          label: fieldName,
          tooltip: fieldInfo?.fieldRemind,
          render: getDisplayNodeRenderer(fieldInfo),
          fieldCategory: layoutConf?.fieldCategory,
        }
      );
    }
    if (layoutConf.type === LayoutItemType.subTable) {
      const { objectCode } = layoutConf;
      const customItem = _.find(customInfoItems, (i) => i.dataIndex === objectCode);
      const subObject = subObjectList.find((i) => i.objectCode === objectCode);

      if (customItem) {
        customItem.label = subObject?.objectName;
      }

      return (
        customItem || {
          dataIndex: objectCode,
          label: subObject?.objectName,
          isFullLine: true,
          render: getSubObjectDisplayNodeRenderer(subObject?.fieldList || []),
        }
      );
    }
    if (layoutConf.type === LayoutItemType.tabpane) {
      const { title, children, objectCode: sonObjectCode } = layoutConf;
      const subObject = subObjectList.find((i) => i.objectCode === sonObjectCode);
      const info = (children ?? []).map(calculateForm);

      return (
        <Tabs.TabPane key={`tabpane-${sonObjectCode}`} tab={title || subObject?.objectName}>
          <DetailLayout
            info={info}
            dataSource={formatDetailDisplayData(dataSource, sonObjectCode || objectCode)}
          />
        </Tabs.TabPane>
      );
    }
    return null;
  };

  let containerRender = null;

  if (layoutConfig?.filter((i) => i.type === LayoutItemType.tabs)?.length) {
    containerRender = (
      <>
        {layoutConfig
          ?.filter((i) => i.type === LayoutItemType.tabs)
          .map(({ children }) => (
            <Tabs type="card">{(children ?? []).map(calculateForm)}</Tabs>
          ))}
      </>
    );
  }

  return {
    configLoading,
    loading,
    info: (layoutConfig ?? []).map(calculateForm),
    containerRender,
    dataSource: formatDetailDisplayData(dataSource, objectCode),
  };
};
