import {
  fetchReceiveNoteBatchEndedEnsure,
  fetchReceiveNoteBatchEnded,
  fetchReceiveNoteBatchDistribute,
  fetchReceiveNoteBatchClosed,
  fetchReceiveNoteListMaterialByIds,
} from 'src/api/ytt/order-domain/deliveryOrder';
import { message, Modal } from 'antd';
import { showErrorListMessage } from 'components';
import _, { isEmpty } from 'lodash';
import { replaceSign } from 'src/utils/constants';
import { procStatusEnum, execStatusEnum } from 'src/dict/delivery';
import moment from 'moment';
import { DeliveryTableRowType as TableRowType } from './index.type';
import { inBoundCreatePath } from './index';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { fetchCustomFieldVisibleGetCustomFieldCross } from 'src/api/ytt/supply-chain-domain/custom_fields';
interface EndOrder {
  list: any[];
  success?: () => void;
  cancel?: () => void;
  isMaterial?: boolean;
  type?: 'single' | 'multiple';
}
interface Amount {
  amount: number;
  amountDisplay: string;
  unitCode: string;
  unitId: number;
  unitName: string;
}

export const renderAmount = (amount: Amount) => {
  return amount ? amount?.amountDisplay + amount?.unitName : replaceSign;
};

const showError = (res: any, option: any) => {
  if (res.code === 200) {
    const { failCount = 0, successCount = 0, failDetails } = res?.data ?? {};

    if (failCount) {
      if (option.type === 'multiple') {
        showErrorListMessage({
          failCount,
          successCount,
          title: '提示',
          width: 800,
          data: _.map(failDetails, (item) => ({ ...item, key: item.seqNum })),
          desc: option.desc,
          columns: [
            { title: '编号', dataIndex: 'code', render: (value: any) => value || replaceSign },
            {
              title: '供应商编号',
              dataIndex: 'supplierCode',
              render: (value: any, record: any) => record?.supplier?.code || replaceSign,
            },
            {
              title: '供应商名称',
              dataIndex: 'supplierName',
              render: (value: any, record: any) => record?.supplier?.name || replaceSign,
            },
            {
              title: option.reasonTitle || '失败原因',
              dataIndex: 'errorReason',
              width: 200,
            },
            { title: '', dataIndex: 'key', width: 1, render: () => null },
          ],
          ...option,
        });
      } else {
        const first = _.first(failDetails) as any;

        message.error(first?.errorReason);
      }
    } else {
      message.success(`${option.display || '结束'}成功！`);
    }
  }
};

const endOrderconfirm = (ids: any[], type: any, cb?: () => void, display?: string) => {
  fetchReceiveNoteBatchEnded({ ids })
    .then((response) => {
      showError(response, {
        method: 'error',
        okText: '知道了',
        type,
        display,
      });
    })
    .finally(() => cb?.());
};

export const endOrder = ({
  isMaterial = false,
  list = [],
  success,
  cancel,
  type = 'multiple',
}: EndOrder) => {
  const ids = isMaterial ? _.map(list, 'receiveNoteId') : _.map(list, 'id');
  const codes = _.map(list, 'code');

  fetchReceiveNoteBatchEndedEnsure({
    ids,
  }).then((res) => {
    if (res.code === 200) {
      const { failCount = 0, failDetails } = res?.data ?? {};
      const unusual = _.find(failDetails, ['procStatus', procStatusEnum.unusual]);
      const display = unusual ? '异常结束' : '结束';

      if (failCount === 0) {
        Modal.confirm({
          title: '以下收货单结束后不能再进行任何操作，确定结束吗？',
          content: codes?.join(', '),
          onOk: () => endOrderconfirm(ids, type, success, display),
        });
      } else {
        if (type === 'multiple') {
          showError(res, {
            method: 'confirm',
            okText: '结束',
            type,
            cancelText: '暂不结束',
            desc: '以下收货单结束后不能再进行任何操作，确定结束吗?',
            reasonTitle: '中断原因',
            onCancel: () => cancel?.(),
            onOk: () => endOrderconfirm(ids, type, success, display),
          });
          return;
        }
        const first = _.first(failDetails) || {};

        Modal.confirm({
          title: '结束收货单',
          content: `${first.errorReason}，确定结束收货单${first.code}吗？`,
          onCancel: () => cancel?.(),
          onOk: () => endOrderconfirm(ids, type, success, display),
          cancelText: '暂不结束',
          okText: '结束',
        });
      }
    }
  });
};

const getErrorColumns = (isException?: boolean) => [
  {
    title: '编号',
    dataIndex: 'code',
    width: 120,
    render: (code: string) => code || replaceSign,
  },
  {
    title: '采购订单号',
    dataIndex: 'purchaseOrderCode',
    width: 150,
    render: (code: string) => code || replaceSign,
  },
  {
    title: '供应商名称',
    dataIndex: ['supplier', 'name'],
    width: 120,
  },
  {
    title: '供应商编号',
    dataIndex: ['supplier', 'code'],
    width: 120,
  },
  {
    title: isException ? '中断原因' : '失败原因',
    dataIndex: 'errorReason',
    width: 300,
    render: (detail: string) => detail || replaceSign,
  },
];

export const dispatchOrder = async (
  ids: number[],
  refresh: () => void,
  onSuccess?: () => void,
  onFail?: () => void,
) => {
  try {
    const { data } = await fetchReceiveNoteBatchDistribute({ ids });

    if (data) {
      const { successCount, failCount, failDetails } = data;

      if (failCount === 0) {
        message.success('下发成功');
        onSuccess?.();
        refresh?.();
        return;
      }
      if (ids.length === 1) {
        message.error(`下发失败，${failDetails?.[0].errorReason}`);
        onFail?.();
        return;
      }
      if (successCount === 0) {
        onFail?.();
        // 全部失败
      } else if (failCount! > 0) {
        onSuccess?.();
        // 部分失败 需要展示失败信息
      }
      showErrorListMessage({
        title: '下发出现失败',
        data: (failDetails as Object[]).map((item, index) => {
          return { ...item, num: index + 1 };
        }),
        columns: getErrorColumns(),
        operateName: '下发',
        successCount,
        failCount,
        width: 900,
      });

      return;
    }
  } catch (error) {
    onFail?.();
    console.log(error);
  }
};

export const closeOrder = (
  ids: number[],
  refresh: () => void,
  onSuccess?: () => void,
  onFail?: () => void,
) => {
  Modal.confirm({
    title: '关闭收货单后不能再进行任何操作，是否确认关闭？',
    onOk: () => closeConfirm(ids, refresh, onSuccess, onFail),
    onCancel: () => onFail?.(),
  });
};
export const enableFn = (list: TableRowType[]) =>
  _.every(list, ['execStatus', execStatusEnum.executing]);
const filterMaterialFn = (items: any[]) => {
  return _.filter(items, (o) => o?.inboundReceiveAmount?.amount > 0);
};

export const createInbound = async (history: any, list: TableRowType[]) => {
  if (!enableFn(list)) {
    message.error('只有执行中的状态才能新建入库单！');
    return;
  }
  const baseSupplier = list?.[0]?.supplierId;
  const isSameSupplier = list?.every((value) => value?.supplierId === baseSupplier);

  if (!isSameSupplier) {
    message.error('供应商不一致，请选择供应商相同的收货单');
    return;
  }
  const { data: materialList } = await fetchReceiveNoteListMaterialByIds({
    ids: _.compact(_.map(list, 'id')),
  });

  materialList?.map((material: any, i: number) => {
    material.entityCode = _.find(list, ['id', material.receiveNoteId])?.code;
    material.entityId = material.receiveNoteId;
  });
  const filterMaterial = filterMaterialFn(materialList || []);

  if (isEmpty(filterMaterial)) {
    message.error('暂无可入库物料！');
    return;
  }
  window?.globalStore?.dispatch({
    type: 'supplyChain/formatterInBoundInfo',
    payload: {
      items: filterMaterial,
      supplierId: {
        label: list[0]?.supplierName,
        value: list[0]?.supplierId,
      },
    },
  });
  history.push(`${inBoundCreatePath}?source=delivery`);
};

const closeConfirm = async (
  ids: number[],
  refresh: () => void,
  onSuccess?: () => void,
  onFail?: () => void,
) => {
  try {
    const { data } = await fetchReceiveNoteBatchClosed({ ids });

    if (data) {
      const { successCount, failCount, failDetails } = data;

      if (failCount === 0) {
        message.success('关闭成功');
        onSuccess?.();
        refresh?.();
        return;
      }
      if (ids.length === 1) {
        message.error(`关闭失败，${failDetails?.[0].errorReason}`);
        onFail?.();
        return;
      }
      if (successCount === 0) {
        onFail?.();
        // 全部失败
      } else if (failCount! > 0) {
        onSuccess?.();
        // 部分失败 需要展示失败信息
      }
      showErrorListMessage({
        title: '关闭出现失败',
        data: (failDetails as Object[]).map((item, index) => {
          return { ...item, num: index + 1 };
        }),
        columns: getErrorColumns(),
        operateName: '关闭',
        successCount,
        failCount,
        width: 800,
      });
      return;
    }
    return;
  } catch (error) {
    onFail?.();
    console.log(error);
  }
};

export const formatFetchValue = (values: any) => {
  //  此处的supplier为供应商编号/名称的纯展示字段，需要丢掉
  const { itemList, supplier, ...rest } = values;

  const supplierId = itemList[0].supplier?.id;
  const _itemList = itemList?.map((node: any, index: number) => {
    const { material, id, purchaseOrderItemId, planReceiveAmount, planReceiveTime, unitId } = node;

    const _planReceiveTime = moment(planReceiveTime).valueOf();

    return {
      seqNum: String(index + 1),
      materialId: material?.baseInfo?.id,
      purchaseOrderItemId,
      planReceiveAmount: Number(planReceiveAmount),
      planReceiveTime: _planReceiveTime,
      id,
      unitId,
    };
  });

  return {
    itemList: _itemList,
    supplierId,
    ...rest,
  };
};

/**
 * 获取，跨租户的可见的自定义字段 列
 */
export const fetchCustomFieldCrossColumns = async (supplierId: number | undefined) => {
  try {
    if (_.isUndefined(supplierId)) return;

    const [{ data: crossCustomFieldsData }, { data: crossCustomFieldsItemData }] =
      await Promise.all([
        await fetchCustomFieldVisibleGetCustomFieldCross({
          supplierId,
          objectCodes: [OBJECT_OF_CODE.ShipmentsNote],
        }),
        await fetchCustomFieldVisibleGetCustomFieldCross({
          supplierId,
          objectCodes: [OBJECT_OF_CODE.ShipmentsNoteItem],
        }),
      ]);

    return {
      document: {
        objectCode: OBJECT_OF_CODE.ShipmentsNote,
        customFields: crossCustomFieldsData?.map((item) => {
          return { ...item, fieldId: item.id };
        }),
        sonObjects: [],
      },
      material: {
        objectCode: OBJECT_OF_CODE.ShipmentsNoteItem,
        customFields: crossCustomFieldsItemData?.map((item) => {
          return { ...item, fieldId: item.id };
        }),
        sonObjects: [],
      },
      supplierId,
    };
  } catch (error) {
    console.log('error', error);
  }
};
