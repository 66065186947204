import React from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import DetailInfo from './components/detailBaseInfo';

interface SchedulingDetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const SchedulingDetailPage = (props: SchedulingDetailProps) => {
  const { match } = props;
  const schedulingId = Number(match?.params?.id);

  return <DetailInfo schedulingId={schedulingId} type="page" />;
};

export default SchedulingDetailPage;
