import { message, Modal } from 'antd';
import _ from 'lodash';
import { showErrorListMessage } from 'src/components';
import lookup from 'src/dict';

// 出入库管理列表批量操作请求通用方法
export const patchRequest = (
  patchApi: any,
  params: any,
  display: string,
  onSuccess?: any,
  onFail?: any,
  columns?: any[],
) => {
  Modal.confirm({
    title: `确定${display}？`,
    content: '',
    // icon: null,
    onCancel: onFail,
    onOk: () => {
      patchApi(params)
        .then((res: any) => {
          const { code, message: mes } = res;

          if (code === 200) {
            onSuccess && onSuccess();
            const { failCount = 0, successCount = 0, failDetails = [] } = res?.data ?? {};

            if (failCount) {
              showErrorListMessage({
                failCount,
                successCount,
                title: `${display}出现失败`,
                data: _.map(failDetails, (item) => ({ ...item, key: item.id })),
                columns: columns ?? [
                  { title: '编号', dataIndex: 'code', width: 200 },
                  { title: '业务类型', dataIndex: 'bizType', width: 200 },
                  { title: '失败原因', dataIndex: 'reason', width: 200 },
                  { title: '', dataIndex: 'key', width: 1, render: () => null },
                ],
              });
            } else {
              message.success(`${display}成功！`);
            }
            return;
          }
          message.error(mes);
          return undefined;
        })
        .catch((err: any) => {
          console.log('err: ', err);
          onFail && onFail();
        });
    },
  });
};
// 调拨单批量操作
export const patchTransferRequest = (
  patchApi: any,
  params: any,
  display: string,
  onSuccess?: any,
  onFail?: any,
) => {
  return patchRequest(patchApi, params, display, onSuccess, onFail, [
    { title: '编号', dataIndex: 'code', width: 200 },
    {
      title: '业务类型',
      dataIndex: 'bizType',
      render: lookup('bound.TransferType'),
      width: 200,
    },
    { title: '失败原因', dataIndex: 'reason', width: 200 },
    { title: '', dataIndex: 'key', width: 1, render: () => null },
  ]);
};

export const getIdVersionList = (list: any[]) => ({
  inboundOrderIdVersionList: list.map((item: any) => ({ id: item.id, version: item.version })),
});
