/**
 * @modal 新建/编辑字段级联关系
 */

import { FC, useEffect, useReducer, useState } from 'react';
import _ from 'lodash';
import { Form, Modal, Steps, Select, Button, Spin, message, Checkbox } from 'antd';
import { useBlocker, qs } from 'src/utils';
import { fetchCustomFieldGetListByObjectCode } from 'ytt/metadata-domain/custom_field';
import {
  fetchCascadeAdd,
  fetchCascadeEdit,
  fetchCascadeTree,
  FetchCascadeAddRequest,
  FetchCascadeEditRequest,
} from 'ytt/metadata-domain/fieldCascading';
import { Matcher } from './matcher';
import { Field } from '../../type';
import { isSelectableField } from '../../utils';
import { FieldCategory, FieldType, UsageStatus, YN } from 'src/dict/common';

interface Props {
  visible: boolean;
  hide: () => void;
  refreshList: () => void;
  initData?: FetchCascadeEditRequest;
}

const { Step } = Steps;

const getFieldOptions = (fields: Field[]) =>
  fields.map((f) => ({
    label: f.fieldName,
    value: f.fieldCode!,
  }));

const EditModal: FC<Props> = ({ visible, hide, refreshList, initData }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [, forceUpdate] = useReducer(() => Date.now(), 0);
  // 已有的级联关系里的依赖字段
  const [existingRefFieldCodes, setExistingRefFieldCodes] = useState<string[]>([]);
  const [fields, setFields] = useState<Field[]>([]);
  const [loadingComp, setLoadingComp] = useState<'data' | 'submit' | null>(null);
  const [form] = Form.useForm<FetchCascadeAddRequest>();
  const { objectCode } = qs.parse();
  const initValues: FetchCascadeAddRequest = {
    objectCode,
    fieldCode: '',
    refFieldCode: '',
    refUnique: YN.no,
    mapping: [],
    status: UsageStatus.enabled,
  };
  const { resetForm, shouldLeave } = useBlocker(form, { initValues });
  const isEdit = !!initData;
  const isCreate = !isEdit;

  const onStepChange = (step: number) => {
    if (step === 1 && isCreate) {
      form.validateFields().then(({ fieldCode }) => {
        const controlFieldChoiceCodes = _.map(
          _.find(fields, { fieldCode })?.choiceValues,
          'choiceCode',
        ) as string[];

        form.setFieldsValue({
          mapping: controlFieldChoiceCodes.map((cfcc) => ({
            choiceCode: cfcc,
            refChoiceCodes: [],
          })),
        });
        setCurrentStep(step);
      });
    } else {
      setCurrentStep(step);
    }
  };

  const handleCancel = () => {
    if (shouldLeave()) {
      resetForm(initValues);
      hide();
    }
  };

  const handleFinish = () => {
    const values = form.getFieldsValue(true);

    setLoadingComp('submit');
    (isEdit ? fetchCascadeEdit(values) : fetchCascadeAdd(values))
      .then(() => {
        message.success('保存完成。');
        resetForm(values);
        refreshList();
        handleCancel();
      })
      .catch(() => setLoadingComp(null));
  };

  const footer = (
    <div style={{ display: 'flex', justifyContent: 'center', gap: 16 }}>
      <Button type="default" onClick={handleCancel}>
        取消
      </Button>
      {currentStep === 0 && (
        <Button type="primary" onClick={() => onStepChange(1)}>
          下一步
        </Button>
      )}
      {currentStep === 1 && (
        <>
          {isCreate && <Button onClick={() => onStepChange(0)}>上一步</Button>}
          <Button type="primary" onClick={handleFinish} loading={loadingComp === 'submit'}>
            确定
          </Button>
        </>
      )}
    </div>
  );

  useEffect(() => {
    if (visible) {
      setCurrentStep(isCreate ? 0 : 1);
      setLoadingComp('data');
      Promise.all([
        fetchCustomFieldGetListByObjectCode({ objectCode }),
        fetchCascadeTree({ objectCode }),
      ])
        .then((resList) => {
          // 只支持配置自定义字段
          setFields(
            (resList[0].data ?? []).filter(
              (f) =>
                f.fieldCategory === FieldCategory.customFields &&
                isSelectableField(f.fieldType!) &&
                !f.isRefer,
            ),
          );
          setExistingRefFieldCodes(_.map(resList[1].data, 'refFieldCode') as string[]);
        })
        .finally(() => setLoadingComp(null));
      if (initData) {
        resetForm(initData);
      }
    }
  }, [visible, initData]);

  const formFieldCode = form.getFieldValue('fieldCode');
  const formRefFieldCode = form.getFieldValue('refFieldCode');
  const controlFieldOptions = getFieldOptions(
    fields.filter((f) => {
      return f.fieldType === FieldType.select && f.fieldCode !== formRefFieldCode;
    }),
  );
  const depFieldOptions = getFieldOptions(
    fields.filter((f) => {
      return (
        f.fieldCode !== formFieldCode &&
        // 别的级联关系里的依赖字段不支持再设为依赖字段
        !existingRefFieldCodes.includes(f.fieldCode!)
      );
    }),
  );

  return (
    <Modal
      className="bl-modal"
      visible={visible}
      title={`${isEdit ? '编辑' : '新建'}字段级联关系`}
      width={800}
      okButtonProps={{ loading: loadingComp === 'submit' }}
      onCancel={handleCancel}
      footer={footer}
      maskClosable={false}
      destroyOnClose
    >
      {isCreate && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Steps
            onChange={onStepChange}
            current={currentStep}
            style={{ width: 500, marginBottom: 24 }}
          >
            <Step
              title={
                <span style={{ fontWeight: currentStep === 0 ? 500 : 400 }}>选择级联字段</span>
              }
            />
            <Step
              title={
                <span style={{ fontWeight: currentStep === 1 ? 500 : 400 }}>
                  配置选项值映射关系
                </span>
              }
            />
          </Steps>
        </div>
      )}
      <Spin spinning={loadingComp === 'data'}>
        <Form form={form}>
          {isCreate && currentStep === 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: 421,
              }}
            >
              <Form.Item style={{ width: 500 }}>
                <span style={{ fontWeight: 500 }}>请选择控制字段和依赖字段</span>
              </Form.Item>
              <Form.Item
                style={{ width: 500 }}
                name="fieldCode"
                label="控制字段"
                rules={[{ required: true, message: '控制字段必选' }]}
              >
                <Select options={controlFieldOptions} disabled={isEdit} onChange={forceUpdate} />
              </Form.Item>
              <Form.Item
                style={{ width: 500 }}
                name="refFieldCode"
                label="依赖字段"
                rules={[{ required: true, message: '依赖字段必选' }]}
              >
                <Select options={depFieldOptions} disabled={isEdit} onChange={forceUpdate} />
              </Form.Item>
              <Form.Item
                style={{ width: 500 }}
                name="refUnique"
                valuePropName="checked"
                getValueFromEvent={(e) => +e.target.checked}
                extra="说明：勾选后，如果依赖字段对应选择只有一个，自动默认选中，无需手工选择"
              >
                <Checkbox disabled={isEdit}>依赖字段只有唯一值时默认选中</Checkbox>
              </Form.Item>
            </div>
          )}
          {currentStep === 1 && (
            <Spin spinning={loadingComp === 'data'}>
              <Matcher
                form={form}
                fields={fields}
                fieldCode={formFieldCode}
                refFieldCode={formRefFieldCode}
              />
            </Spin>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditModal;
