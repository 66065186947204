import React, { useEffect, useState } from 'react';
import { DetailLayoutForDrawer } from 'layout';
import { fetchShiftGet } from 'src/api/ytt/calendar-domain';
import DetailInfo from './components/detailBaseInfo';

interface Props {
  visible: boolean;
  closeDetail: () => void;
  shiftId: number;
}

const ShiftDetail = (props: Props) => {
  const { visible, closeDetail, shiftId } = props;

  return (
    <DetailLayoutForDrawer
      visible={visible}
      onClose={closeDetail}
      width={680}
      content={<DetailInfo shiftId={shiftId} type="drawer" />}
    />
  );
};

export default ShiftDetail;
