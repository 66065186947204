import React, { useState } from 'react';
import { Form, FormInstance, Input, Space } from 'antd';
import { BlSortFormList } from '@blacklake-web/component';
import { ColumnProps } from 'antd/lib/table';
import InputTag from 'src/components/input/inputTag';
import PopoverLabel from './PopoverLabel';
import UserAndRoleModalSelect from 'src/page/organization/components/userAndRoleModalSelect';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ALL_USER_FLAG_ID } from 'src/page/organization/components/userAndRoleModalSelect/constant';
import _ from 'lodash';
import { SignConfTableRow } from '../type';
import { YN } from 'src/dict/common';
import { BlIcon } from 'src/components';

type SignatureConfigListProps = {
  form: FormInstance<any>;
  name: string;
  addSignRange: (chooseList: any[], signType: string, optIndex: number) => void;
};

const SignatureConfigList: React.FC<SignatureConfigListProps> = (props) => {
  const { form, name, addSignRange } = props;
  const [curIndex, setCurIndex] = useState<number>();
  const [signType, setSignType] = useState('user');
  const [addSignRangeVisible, setAddSignRangeVisible] = useState(false);

  /** 添加用户/角色 */
  const addConfig = (signType: string, optIndex: number) => {
    setCurIndex(optIndex);
    setSignType(signType);
    setAddSignRangeVisible(true);
  };

  const columns: ColumnProps<any & FormListFieldData>[] = [
    {
      title: '',
      dataIndex: 'id',
      width: 0,
      render: (text, field) => {
        return (
          <Form.Item
            hidden
            name={[field.name, 'id']}
            fieldKey={[field.fieldKey, 'id']}
            style={{ marginBottom: '0' }}
          >
            <Input />
          </Form.Item>
        );
      },
    },
    {
      title: '',
      dataIndex: 'seq',
      width: 0,
      render: (text, field) => {
        return (
          <Form.Item
            hidden
            name={[field.name, 'seq']}
            fieldKey={[field.fieldKey, 'seq']}
            style={{ marginBottom: '0' }}
          >
            <Input />
          </Form.Item>
        );
      },
    },
    {
      title: '序号',
      dataIndex: 'index',
      width: 60,
      render: (text, field, index) => index + 1,
    },
    {
      title: (
        <PopoverLabel
          label={'签名人范围'}
          content={[
            '配置每个签名的可签名用户范围，支持按用户添加或按角色添加',
            '“当前登录用户”表示当前登录系统进行操作的用户允许签名',
            '选择某个角色时，具体该角色的所有用户均允许签名',
          ]}
        />
      ),
      dataIndex: 'signRange',
      render: (text, field) => {
        return (
          <>
            <Form.Item
              name={[field.name, 'signRange']}
              style={{ marginBottom: 0 }}
              rules={[{ required: true, message: '签名人范围必填' }]}
            >
              <InputTag />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      render: (text, field, index) => {
        return (
          <Space align="end">
            <a onClick={() => addConfig('user', index)}>
              {/* <BlIcon type="iconxinjiantianjia" /> */}
              添加用户
            </a>
            <a onClick={() => addConfig('role', index)}>
              {/* <BlIcon type="iconxinjiantianjia" /> */}
              添加角色
            </a>
          </Space>
        );
      },
    },
  ];

  /** 渲染添加签名操作按钮 */
  const renderOptButton = () => (
    <>
      添加签名
      <span style={{ fontSize: 12 }}>（最多支持添加10行）</span>
    </>
  );

  return (
    <>
      <BlSortFormList
        form={form}
        name={name}
        handleAdd={() => {
          const rows = _.cloneDeep<SignConfTableRow[]>(form.getFieldValue(name));
          const lastSeq = _.findLast(rows)?.seq || 1;

          rows.push({
            allUserFlag: YN.yes,
            currentUserFlag: YN.no,
            seq: lastSeq + 1,
            signRange: [
              {
                type: 'user',
                label: '所有用户',
                value: ALL_USER_FLAG_ID,
              },
            ],
          });

          form.setFieldsValue({ configList: rows });
        }}
        buttonText={renderOptButton()}
        renderColumns={() => columns}
        isNeedDrag={false}
        maxCount={10}
      />
      {/* 添加用户/角色 */}
      {addSignRangeVisible && (
        <UserAndRoleModalSelect
          selectUser={signType === 'user'}
          visible={addSignRangeVisible}
          showAllUserFlag={signType === 'user'}
          showCurrentUserFlag={signType === 'user' && curIndex === 0}
          submitData={(chooseList: any[]) => addSignRange(chooseList, signType, curIndex || 0)}
          onClose={() => setAddSignRangeVisible(false)}
        />
      )}
    </>
  );
};

export default SignatureConfigList;
