import { useEffect, useRef, useCallback } from 'react';
import * as echarts from 'echarts';
import moment from 'moment';
import { useResizeDetector } from 'react-resize-detector';

import { lookup } from 'src/dict';
import { valueOrHolder } from 'src/utils/formatters';
import { customEvents } from 'src/utils/events';
import { MonitorDataItemType, MonitorParamInfoType } from '../../constants';

interface ParamChartProps {
  data: MonitorDataItemType[];
  paramInfo: MonitorParamInfoType;
}

const dimensions = ['time', 'upsLimit', 'upLimit', 'standardVal', 'downLimit', 'downsLimit', 'val'];

const labelFields: { [key in keyof MonitorDataItemType]: string } = {
  val: '参数值',
  standardVal: '参数标准值',
  upsLimit: '参数上上限',
  upLimit: '参数上限',
  downLimit: '参数下限',
  downsLimit: '参数下下限',
  recordTime: '记录时间',
  baseLineType: '',
  time: '',
};

export default function ParamChart({ data, paramInfo }: ParamChartProps) {
  const { paramUnit, paramName } = paramInfo;
  const chart = useRef<echarts.ECharts>();

  // 警告红，警告黄，黑湖绿，警告黄，警告红，标准蓝
  const colors = ['#FF4D4F', '#FAAD14', '#52C41A', '#FAAD14', '#FF4D4F', '#02B980'];

  const onResize = useCallback(() => {
    chart?.current?.resize();
  }, []);
  const { ref: resizeRef } = useResizeDetector({ onResize });

  useEffect(() => {
    chart.current = echarts.init(resizeRef.current as HTMLDivElement);

    // 专门为退出全屏模式增加的事件监听，退出全屏获取正确的高度有延时，所以要setTimeout
    customEvents.on('resize', () => setTimeout(onResize, 0));
  }, []);

  /** 格式化toolTip */
  const toolTipFormat = ({ data }: { data: MonitorDataItemType }) => {
    let resultHtml = '<div style="padding: 10px;">';

    Object.keys(labelFields).forEach((key) => {
      if (key !== 'baseLineType' && key !== 'time') {
        const fieldValue = data[key as keyof MonitorDataItemType];

        resultHtml +=
          '<div style="font-size: 12px; color: #262626;background-color: white;">' +
          (key !== 'val' && key !== 'recordTime'
            ? `<span>${lookup('resources.resourceMonitorViewType', data.baseLineType)}</span>`
            : '') +
          `<span>${labelFields[key as keyof MonitorDataItemType]}: </span>` +
          (key !== 'recordTime'
            ? `<span>${valueOrHolder(fieldValue)} ${fieldValue ? paramUnit ?? '' : ''}</span>`
            : ` <span>${moment(data[key as keyof MonitorDataItemType]).format(
                'YYYY-MM-DD HH:mm:ss',
              )}</span>`);
      }
    });

    resultHtml += '</div>';
    return resultHtml;
  };

  const serialLineConfig = {
    type: 'line',
    symbol: 'circle',
    // symbolSize: 1,
    lineStyle: { type: [10, 10], width: 1, opacity: 0.8 },
  };

  /** 图表配置 */
  const option = {
    title: {
      show: true,
      text: `${paramName}`,
      left: '50%',
      textAlign: 'center',
      textStyle: {
        fontSize: 14,
      },
    },
    grid: {
      left: 15,
      right: 10,
      top: 50,
      bottom: 30,
    },
    tooltip: {
      show: true,
      trigger: 'item',
      padding: 0,
      borderColor: 'white',
      backgroundColor: 'white',
      formatter: toolTipFormat,
      confine: true,
    },
    color: colors,
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: '#E6E6E6',
        },
      },
      axisLabel: {
        show: true,
        interval: 0,
        rotate: 30,
        formatter: (value: number) => {
          return moment(Math.floor(value)).format('HH:mm');
        },
        width: 48,
        color: 'rgba(0, 0, 0, 0.65)',
        overflow: 'breakAll',
      },
    },
    yAxis: {
      name: `单位：${valueOrHolder(paramUnit)}`,
      nameTextStyle: {
        align: 'left',
        padding: [0, 0, 0, 10],
        color: 'rgba(0, 0, 0, 0.65)',
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#E6E6E6',
        },
      },
      splitLine: {
        lineStyle: {
          width: 0.5,
          color: '#E6E6E6',
        },
      },
      axisLabel: {
        inside: true,
        color: 'rgba(0, 0, 0, 0.65)',
      },
    },
    dataset: {
      dimensions,
      source: data,
    },
    series: [
      serialLineConfig,
      serialLineConfig,
      serialLineConfig,
      serialLineConfig,
      serialLineConfig,
      { type: 'line', lineStyle: { width: 3 }, symbol: 'emptyCircle', symbolSize: 8 },
    ],
    backgroundColor: '#FAFAFA',
  };

  useEffect(() => {
    chart?.current?.setOption(option, true);
  }, [data]);

  return (
    <div
      ref={resizeRef}
      style={{ height: '100%', width: '100%', overflow: 'hidden', marginBottom: 5 }}
    />
  );
}
