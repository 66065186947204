/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../../request';

// makeRequest
function makeRequestRequired<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequestOptional<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData?: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequest<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const optional = makeRequestOptional<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  const required = makeRequestRequired<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  return (
    requestConfig.requestDataOptional ? optional : required
  ) as TRequestConfig['requestDataOptional'] extends true ? typeof optional : typeof required;
}

// Request
export type Request<TReqeustData, TRequestConfig extends RequestConfig, TRequestResult> =
  (TRequestConfig['requestDataOptional'] extends true
    ? (
        requestData?: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult
    : (
        requestData: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult) & {
    requestConfig: TRequestConfig;
  };

const mockUrl_0_7_6_0 = 'http://yapi.blacklake.tech/mock/735' as any;
const devUrl_0_7_6_0 = '' as any;
const prodUrl_0_7_6_0 = '' as any;
const dataKey_0_7_6_0 = undefined as any;

/**
 * 接口 [删除检验项前校验↗](http://yapi.blacklake.tech/project/735/interface/api/79826) 的 **请求类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/check_before_delete`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemCheckBeforeDeleteRequest {
  ids?: number[];
}

/**
 * 接口 [删除检验项前校验↗](http://yapi.blacklake.tech/project/735/interface/api/79826) 的 **返回类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/check_before_delete`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemCheckBeforeDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: number;
  /**
   * 返回信息
   */
  message: string;
  requestId?: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [删除检验项前校验↗](http://yapi.blacklake.tech/project/735/interface/api/79826) 的 **请求配置的类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/check_before_delete`
 * @更新时间 `2021-12-08 11:00:02`
 */
type FetchQcCheckItemCheckBeforeDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_check_item/check_before_delete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [删除检验项前校验↗](http://yapi.blacklake.tech/project/735/interface/api/79826) 的 **请求配置**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/check_before_delete`
 * @更新时间 `2021-12-08 11:00:02`
 */
const fetchQcCheckItemCheckBeforeDeleteRequestConfig: FetchQcCheckItemCheckBeforeDeleteRequestConfig =
  {
    mockUrl: mockUrl_0_7_6_0,
    devUrl: devUrl_0_7_6_0,
    prodUrl: prodUrl_0_7_6_0,
    path: '/quality/domain/web/v1/qc_check_item/check_before_delete',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_7_6_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchQcCheckItemCheckBeforeDelete',
  };

/**
 * 接口 [删除检验项前校验↗](http://yapi.blacklake.tech/project/735/interface/api/79826) 的 **请求函数**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/check_before_delete`
 * @更新时间 `2021-12-08 11:00:02`
 */
export const fetchQcCheckItemCheckBeforeDelete = makeRequest<
  FetchQcCheckItemCheckBeforeDeleteRequest,
  FetchQcCheckItemCheckBeforeDeleteResponse,
  FetchQcCheckItemCheckBeforeDeleteRequestConfig
>(fetchQcCheckItemCheckBeforeDeleteRequestConfig);

/**
 * 接口 [新建检验项↗](http://yapi.blacklake.tech/project/735/interface/api/79832) 的 **请求类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/create`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemCreateRequest {
  /**
   * 附件文件id列表，以,分割
   */
  attachmentIds?: number[];
  /**
   * 校验项分类id
   */
  categoryId?: number;
  /**
   * 编码
   */
  code?: string;
  /**
   * 名称
   */
  name: string;
  /**
   * 备注
   */
  remark?: string;
}

/**
 * 接口 [新建检验项↗](http://yapi.blacklake.tech/project/735/interface/api/79832) 的 **返回类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/create`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemCreateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: number;
  /**
   * 返回信息
   */
  message: string;
  requestId?: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [新建检验项↗](http://yapi.blacklake.tech/project/735/interface/api/79832) 的 **请求配置的类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/create`
 * @更新时间 `2021-12-08 11:00:02`
 */
type FetchQcCheckItemCreateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_check_item/create',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [新建检验项↗](http://yapi.blacklake.tech/project/735/interface/api/79832) 的 **请求配置**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/create`
 * @更新时间 `2021-12-08 11:00:02`
 */
const fetchQcCheckItemCreateRequestConfig: FetchQcCheckItemCreateRequestConfig = {
  mockUrl: mockUrl_0_7_6_0,
  devUrl: devUrl_0_7_6_0,
  prodUrl: prodUrl_0_7_6_0,
  path: '/quality/domain/web/v1/qc_check_item/create',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_6_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcCheckItemCreate',
};

/**
 * 接口 [新建检验项↗](http://yapi.blacklake.tech/project/735/interface/api/79832) 的 **请求函数**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/create`
 * @更新时间 `2021-12-08 11:00:02`
 */
export const fetchQcCheckItemCreate = makeRequest<
  FetchQcCheckItemCreateRequest,
  FetchQcCheckItemCreateResponse,
  FetchQcCheckItemCreateRequestConfig
>(fetchQcCheckItemCreateRequestConfig);

/**
 * 接口 [删除检验项↗](http://yapi.blacklake.tech/project/735/interface/api/79838) 的 **请求类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/delete`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemDeleteRequest {
  ids?: number[];
}

/**
 * 接口 [删除检验项↗](http://yapi.blacklake.tech/project/735/interface/api/79838) 的 **返回类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/delete`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  requestId?: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [删除检验项↗](http://yapi.blacklake.tech/project/735/interface/api/79838) 的 **请求配置的类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/delete`
 * @更新时间 `2021-12-08 11:00:02`
 */
type FetchQcCheckItemDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_check_item/delete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [删除检验项↗](http://yapi.blacklake.tech/project/735/interface/api/79838) 的 **请求配置**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/delete`
 * @更新时间 `2021-12-08 11:00:02`
 */
const fetchQcCheckItemDeleteRequestConfig: FetchQcCheckItemDeleteRequestConfig = {
  mockUrl: mockUrl_0_7_6_0,
  devUrl: devUrl_0_7_6_0,
  prodUrl: prodUrl_0_7_6_0,
  path: '/quality/domain/web/v1/qc_check_item/delete',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_6_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcCheckItemDelete',
};

/**
 * 接口 [删除检验项↗](http://yapi.blacklake.tech/project/735/interface/api/79838) 的 **请求函数**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/delete`
 * @更新时间 `2021-12-08 11:00:02`
 */
export const fetchQcCheckItemDelete = makeRequest<
  FetchQcCheckItemDeleteRequest,
  FetchQcCheckItemDeleteResponse,
  FetchQcCheckItemDeleteRequestConfig
>(fetchQcCheckItemDeleteRequestConfig);

/**
 * 接口 [查询检验项详情↗](http://yapi.blacklake.tech/project/735/interface/api/79844) 的 **请求类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/detail`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemDetailRequest {
  /**
   * 附件
   */
  attachmentIds?: number[];
  /**
   * 检验项分类id
   */
  categoryId?: number;
  /**
   * 检验项分类名称
   */
  categoryName?: string;
  /**
   * 编号
   */
  code?: string;
  /**
   * 创建人
   */
  creator?: {};
  /**
   * id
   */
  id?: number;
  /**
   * 名称
   */
  name?: string;
  operator?: {
    /**
     * web-User信息VO-头像
     */
    avatarUrl?: string;
    /**
     * web-User信息VO-编号
     */
    code?: string;
    /**
     * web-User信息VO-描述
     */
    desc?: string;
    /**
     * web-User信息VO-id
     */
    id?: number;
    /**
     * web-User信息VO-name
     */
    name?: string;
    /**
     * web-User信息VO-username
     */
    username?: string;
  };
  /**
   * 备注
   */
  remark?: string;
}

/**
 * 接口 [查询检验项详情↗](http://yapi.blacklake.tech/project/735/interface/api/79844) 的 **返回类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/detail`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemDetailResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 附件
     */
    attachmentIds?: number[];
    /**
     * 检验项分类id
     */
    categoryId?: number;
    /**
     * 检验项分类名称
     */
    categoryName?: string;
    /**
     * 编号
     */
    code?: string;
    /**
     * 创建人
     */
    creator?: {};
    /**
     * id
     */
    id?: number;
    /**
     * 名称
     */
    name?: string;
    operator?: {
      /**
       * web-User信息VO-头像
       */
      avatarUrl?: string;
      /**
       * web-User信息VO-编号
       */
      code?: string;
      /**
       * web-User信息VO-描述
       */
      desc?: string;
      /**
       * web-User信息VO-id
       */
      id?: number;
      /**
       * web-User信息VO-name
       */
      name?: string;
      /**
       * web-User信息VO-username
       */
      username?: string;
    };
    /**
     * 备注
     */
    remark?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  requestId?: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [查询检验项详情↗](http://yapi.blacklake.tech/project/735/interface/api/79844) 的 **请求配置的类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/detail`
 * @更新时间 `2021-12-08 11:00:02`
 */
type FetchQcCheckItemDetailRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_check_item/detail',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [查询检验项详情↗](http://yapi.blacklake.tech/project/735/interface/api/79844) 的 **请求配置**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/detail`
 * @更新时间 `2021-12-08 11:00:02`
 */
const fetchQcCheckItemDetailRequestConfig: FetchQcCheckItemDetailRequestConfig = {
  mockUrl: mockUrl_0_7_6_0,
  devUrl: devUrl_0_7_6_0,
  prodUrl: prodUrl_0_7_6_0,
  path: '/quality/domain/web/v1/qc_check_item/detail',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_6_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcCheckItemDetail',
};

/**
 * 接口 [查询检验项详情↗](http://yapi.blacklake.tech/project/735/interface/api/79844) 的 **请求函数**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/detail`
 * @更新时间 `2021-12-08 11:00:02`
 */
export const fetchQcCheckItemDetail = makeRequest<
  FetchQcCheckItemDetailRequest,
  FetchQcCheckItemDetailResponse,
  FetchQcCheckItemDetailRequestConfig
>(fetchQcCheckItemDetailRequestConfig);

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79850) 的 **请求类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/pageList`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemPageListRequest {
  /**
   * 所属检验项分类id
   */
  categoryId?: number;
  /**
   * 检验项搜索名称
   */
  checkItemName?: string;
  /**
   * 请求页
   */
  page?: number;
  /**
   * 快速搜索条件
   */
  quickSearch?: string;
  /**
   * 每页大小
   */
  size?: number;
  /**
   * 排序 列表顺序表示排序顺序
   */
  sorter?: {
    /**
     * 排序字段
     */
    field?: string;
    /**
     * 排序规律 默认 asc，asc 升序 desc 降序
     */
    order?: string;
  }[];
}

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79850) 的 **返回类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/pageList`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemPageListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * 分类id
       */
      categoryId?: number;
      /**
       * 分类名称
       */
      categoryName?: string;
      /**
       * 编号
       */
      code?: string;
      /**
       * id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
      /**
       * 备注
       */
      remark?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  requestId?: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79850) 的 **请求配置的类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/pageList`
 * @更新时间 `2021-12-08 11:00:02`
 */
type FetchQcCheckItemPageListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_check_item/pageList',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79850) 的 **请求配置**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/pageList`
 * @更新时间 `2021-12-08 11:00:02`
 */
const fetchQcCheckItemPageListRequestConfig: FetchQcCheckItemPageListRequestConfig = {
  mockUrl: mockUrl_0_7_6_0,
  devUrl: devUrl_0_7_6_0,
  prodUrl: prodUrl_0_7_6_0,
  path: '/quality/domain/web/v1/qc_check_item/pageList',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_6_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcCheckItemPageList',
};

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79850) 的 **请求函数**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/pageList`
 * @更新时间 `2021-12-08 11:00:02`
 */
export const fetchQcCheckItemPageList = makeRequest<
  FetchQcCheckItemPageListRequest,
  FetchQcCheckItemPageListResponse,
  FetchQcCheckItemPageListRequestConfig
>(fetchQcCheckItemPageListRequestConfig);

/**
 * 接口 [编辑检验项↗](http://yapi.blacklake.tech/project/735/interface/api/79856) 的 **请求类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/update`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemUpdateRequest {
  /**
   * 附件文件id列表，以,分割
   */
  attachmentIds?: string;
  /**
   * 校验项分类id
   */
  categoryId?: number;
  /**
   * 编码
   */
  code?: string;
  /**
   * 业务id
   */
  id: number;
  /**
   * 名称
   */
  name: string;
  /**
   * 备注
   */
  remark?: string;
}

/**
 * 接口 [编辑检验项↗](http://yapi.blacklake.tech/project/735/interface/api/79856) 的 **返回类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/update`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemUpdateResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 附件
     */
    attachmentIds?: number[];
    /**
     * 检验项分类id
     */
    categoryId?: number;
    /**
     * 检验项分类名称
     */
    categoryName?: string;
    /**
     * 编号
     */
    code?: string;
    /**
     * 创建人
     */
    creator?: {};
    /**
     * id
     */
    id?: number;
    /**
     * 名称
     */
    name?: string;
    operator?: {
      /**
       * web-User信息VO-头像
       */
      avatarUrl?: string;
      /**
       * web-User信息VO-编号
       */
      code?: string;
      /**
       * web-User信息VO-描述
       */
      desc?: string;
      /**
       * web-User信息VO-id
       */
      id?: number;
      /**
       * web-User信息VO-name
       */
      name?: string;
      /**
       * web-User信息VO-username
       */
      username?: string;
    };
    /**
     * 备注
     */
    remark?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  requestId?: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [编辑检验项↗](http://yapi.blacklake.tech/project/735/interface/api/79856) 的 **请求配置的类型**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/update`
 * @更新时间 `2021-12-08 11:00:02`
 */
type FetchQcCheckItemUpdateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_check_item/update',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [编辑检验项↗](http://yapi.blacklake.tech/project/735/interface/api/79856) 的 **请求配置**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/update`
 * @更新时间 `2021-12-08 11:00:02`
 */
const fetchQcCheckItemUpdateRequestConfig: FetchQcCheckItemUpdateRequestConfig = {
  mockUrl: mockUrl_0_7_6_0,
  devUrl: devUrl_0_7_6_0,
  prodUrl: prodUrl_0_7_6_0,
  path: '/quality/domain/web/v1/qc_check_item/update',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_6_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcCheckItemUpdate',
};

/**
 * 接口 [编辑检验项↗](http://yapi.blacklake.tech/project/735/interface/api/79856) 的 **请求函数**
 *
 * @分类 [web-检验项↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11342)
 * @标签 `web-检验项`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item/update`
 * @更新时间 `2021-12-08 11:00:02`
 */
export const fetchQcCheckItemUpdate = makeRequest<
  FetchQcCheckItemUpdateRequest,
  FetchQcCheckItemUpdateResponse,
  FetchQcCheckItemUpdateRequestConfig
>(fetchQcCheckItemUpdateRequestConfig);

/* prettier-ignore-end */
