import fs from 'file-saver';
// import * as xlsxStyle from 'gc-xlsx';
import _ from 'lodash';
import XLSX from 'xlsx';

const { saveAs } = fs;

interface ColStyle {
  colsWidth: { wpx: number }[];
}

interface xlsxData {
  style: ColStyle | undefined;
  data: any[];
}
/**
 * @description 导出excel文件
 * @param {*} data       导出数据
 * @param {*} filename   导出的文件名
 * @param {*} sheetNames 表格sheet 的名称，如果不导出多个sheet表格可以为空
 * @param {*} type       文件类型
 */
export const exportXlsxFile = (data: any, filename = 'download', sheetNames: string[], type: string = 'xlsx') => {
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();

  if (Array.isArray(sheetNames)) {
    sheetNames.forEach((sheetName, index) => {
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(data[index]), sheetNames[index] || `sheet${index}`);
    });
  } else {
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
  }
  const wbout = XLSX.write(wb, { type: 'array', bookType: type, bookSST: true });

  saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${filename}.${type}`);
};

/**
 * @description 将字符串导出为CSV文件，可以将字符串生成CSV文件并下载到本地
 * @param csvString 字符串
 * @param fileName  导出文件名
 * @returns
 */
export const exportCsvFileByString = (csvString: string | null, fileName: string) => {
  if (!csvString) return;

  const blob = new Blob([`\uFEFF${csvString}`], { type: 'text/csv;charset=gb2312;' });
  const a = document.createElement('a');

  a.download = `${fileName || '导出文件'}.csv`;
  a.href = URL.createObjectURL(blob);
  a.click();
};

/**
 * @description 字符数组转换为无字符数字数组，为将数据转换为生成Blob做准备
 * @param s
 * @returns
 */
const s2ab = (s: any): any => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);

  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

/**
 * @description colsWidth 分两种参数形式，值为number时所有列宽一样，值为数组时列宽每列分别定义
 * @param param0
 * @returns
 */
const getWs = ({ data, style = {} as ColStyle }: xlsxData) => {
  const ws = {} as any;
  let maxColumnLength = 0;
  const maxRowLength = data.length;
  const getLastFieldName = (lastColumnIndex: number) => {
    if (lastColumnIndex <= 26) {
      return String.fromCharCode(65 + (lastColumnIndex - 1));
    }
    return `${String.fromCharCode(65 + Math.floor(lastColumnIndex / 26) - (lastColumnIndex % 26 !== 0 ? 1 : 2))}${
      lastColumnIndex % 26 !== 0 ? String.fromCharCode(65 + (lastColumnIndex % 26) - 1) : 'Z'
    }`;
  };

  data.forEach((n, i) => {
    if (n.length > maxColumnLength) {
      maxColumnLength = n.length;
    }
    n.forEach((m: any, index: number) => {
      const cell = `${getLastFieldName(index + 1)}${i + 1}`;

      ws[cell] = {
        t: 's',
        v: `${m || typeof m === 'number' ? m : ''}`,
        s: {
          alignment: {
            wrapText: true,
          },
        },
      };
    });
  });
  ws['!ref'] = `A1:${getLastFieldName(maxColumnLength)}${maxRowLength}`;
  if (style.colsWidth) {
    if (typeof style.colsWidth === 'number') {
      const _colsWidth = _.fill(Array(maxColumnLength), { wpx: style.colsWidth });

      style.colsWidth = _colsWidth;
    }
    ws['!cols'] = style.colsWidth;
  }
  return ws;
};

/**
 * @description 导出自定义样式的xlsx文件
 * @param data
 * @param filename
 * @param sheetNames 多个sheet的时候，需要输入对应的 sheetNames，是数组
 * @param type   导出文件类型，默认为 xlsx
 * @param style  xlsx文件的样式
 */
// export const exportXlsxStyleFile = (
//   data: any[],
//   filename = 'download',
//   sheetNames?: string[],
//   type = 'xlsx',
//   style?: ColStyle,
// ) => {
//   const wb = XLSX.utils.book_new();

//   if (Array.isArray(sheetNames)) {
//     // multiple sheet
//     sheetNames.forEach((sheetName, index) => {
//       const ws = getWs({ data: data[index], style });

//       XLSX.utils.book_append_sheet(wb, ws, sheetNames[index] || `sheet${index}`);
//     });
//   } else {
//     const ws = getWs({ data, style });

//     XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
//   }
//   const wbout = xlsxStyle.write(wb, { type: 'binary', bookSST: true, bookType: type });

//   saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${filename}.${type}`);
// };

/**
 * @description 根据请求返回blob数据导出xlsx文件
 * 请求config配置 responseType: 'blob',
 * @param {*} blobData blob数据，不需要处理
 * @param {*} filename 文件名
 */
export const exportBlobToXlsxFile = (blobData: BlobPart, filename: any = 'doenload') => {
  const blob = new Blob([blobData], { type: 'application/octet-stream' });

  saveAs(blob, `${filename}.xlsx`);
};

/**
 * @description 将以base64的图片url数据转换为Blob
 * @param urlData
 */
export function base64ToBlob(urlData: string) {
  const arr = urlData.split(',');
  const end = arr[0].match(/:(.*?);/);
  const mimeType = end ? end[1] : '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mimeType });
}

/**
 * @description 将blob转文件
 * @param {*} theBlob
 * @param {*} fileName
 */
export function blobToFile(theBlob: any, fileName: string) {
  const files = new window.File([theBlob], fileName, { type: theBlob.type });

  return files;
}

/**
 * @description 将base64转文件
 * @param {*} urlData
 * @param {*} fileName
 */
export function base64ToFile(urlData: string, fileName = 'img') {
  const blob = base64ToBlob(urlData);

  return blobToFile(blob, fileName);
}

export default 'dummy';
