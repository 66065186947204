import { QcMaterial } from 'src/page/quality/models/dto';
import { STATUS_ENUM } from 'src/page/knowledgeManagement/warehouse/constant';
import { TagList } from 'src/components';
import _ from 'lodash';

export const titleMap = new Map([
  ['qcConfigProcessList', '工序'],
  ['qcConfigMaterialList', '物料'],
  ['qcConfigResourceList', '设备'],
  ['qcConfigCustomerList', '客户'],
  ['qcConfigSupplierList', '供应商'],
]);
export const columnsMap = new Map([
  [
    'qcConfigProcessList',
    [
      {
        title: '工序编号',
        dataIndex: 'code',
        width: 150,
      },
      {
        title: '工序名称',
        dataIndex: 'name',
        width: 150,
      },
    ],
  ],
  [
    'qcConfigMaterialList',
    [
      {
        title: '物料编号',
        dataIndex: 'code',
        width: 150,
      },
      {
        title: '物料名称',
        dataIndex: 'name',
        width: 150,
        render: (name: string, record: QcMaterial) =>
          `${name}${record.enableFlag === STATUS_ENUM.DISABLED ? '(已停用)' : ''}`,
      },
      {
        title: '物料分类',
        dataIndex: ['category', 'categoryName'],
        width: 150,
      },
      {
        title: '物料属性',
        dataIndex: 'attributes',
        width: 150,
        render: (attributes: any[]) =>
          `${
            attributes
              ?.map((attribute) => `${attribute.attributeName}: ${attribute.attributeItemName}`)
              .join(',') || '-'
          }`,
      },
    ],
  ],
  [
    'qcConfigResourceList',
    [
      {
        title: '设备编号',
        dataIndex: 'code',
        width: 150,
      },
      {
        title: '设备名称',
        dataIndex: 'name',
        width: 150,
      },
    ],
  ],
  [
    'qcConfigCustomerList',
    [
      {
        title: '客户编号',
        dataIndex: 'code',
        width: 150,
      },
      {
        title: '客户名称',
        dataIndex: 'name',
        width: 150,
      },
    ],
  ],
  [
    'qcConfigSupplierList',
    [
      {
        title: '供应商编号',
        dataIndex: 'code',
        width: 150,
      },
      {
        title: '供应商名称',
        dataIndex: 'name',
        width: 150,
      },
    ],
  ],
]);

export const filterOptions = new Map([
  [
    'qcConfigProcessList',
    [
      { label: '工序编号', value: 'code', width: 150 },
      { label: '工序名称', value: 'name', width: 150 },
    ],
  ],
  [
    'qcConfigMaterialList',
    [
      { label: '物料编号', value: 'code', width: 150 },
      { label: '物料名称', value: 'name', width: 150 },
      { label: '物料分类', value: 'categoryIds', width: 150 },
    ],
  ],
  [
    'qcConfigResourceList',
    [
      { label: '设备编号', value: 'code', width: 150 },
      { label: '设备名称', value: 'name', width: 150 },
    ],
  ],
  [
    'qcConfigCustomerList',
    [
      { label: '客户编号', value: 'code', width: 150 },
      { label: '客户名称', value: 'name', width: 150 },
    ],
  ],
  [
    'qcConfigSupplierList',
    [
      { label: '供应商编号', value: 'code', width: 150 },
      { label: '供应商名称', value: 'name', width: 150 },
    ],
  ],
]);
