import { useState } from 'react';
import { BlIcon, BlTooltip, SingleOperationLogComponent } from 'components';
import _ from 'lodash';
import { RecordListLayout, FilterItem } from 'layout';
import { Button, Form, message as Message } from 'antd';
import {
  fetchCustomerList,
  fetchCustomerDelete,
  FetchCustomerDeleteRequest,
  FetchCustomerDeleteResponse,
  fetchCustomerDisable,
  FetchCustomerDisableRequest,
  fetchCustomerEnable,
  FetchCustomerEnableRequest,
} from 'src/api/ytt/order-domain/customer';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { appDict, lookup } from 'src/dict';
import { CRUD, UsageStatus } from 'src/dict/common';
import { showErrorListMessage } from 'src/components/message/errorMessageList';
import { ErrorNotification } from 'src/components/notification';
import { renderUsageStatus } from 'src/page/share/renderUsageStatus';
import { valueOrHolder } from 'src/utils/formatters';
import { replaceSign } from 'src/utils/constants';
import { avatarDisplay } from 'src/components/avatar/show';
import { fieldTypeList, gcTime, gcObject } from 'src/utils';
import type { CustomerListItemProps, CustomerListItemPropsUser } from '../interface';
import CreateAndEdit from '../createAndEdit';
import CustomerDetail from '../detail';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import { Auth } from 'src/dict/auth';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import authDict, { hasAuth } from 'src/utils/auth';

const errorColumns = [
  {
    title: '客户名称',
    dataIndex: 'name',
  },
  {
    title: '失败原因',
    dataIndex: 'failReason',
  },
];

const CustomerList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新页面
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<Required<CustomerListItemProps>>();
  const [mode, setMode] = useState<CRUD>();
  const [delForm] = Form.useForm();
  /**
   * 导入导出
   */
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const refresh = () => setRefreshMarker(Date.now());
  const handleRefreshDetailStatus = () => {
    refresh();
    if (currentData) {
      setCurrentData({
        ...currentData,
        status: Number(!currentData.status) as UsageStatus,
      });
    }
  };
  /**
   * 删除操作
   */
  const handleDelete = async (
    onSuccess?: () => void,
    onFail?: () => void,
    idToDeleteAlone?: number,
  ) => {
    try {
      // 从确认删除的form里得到删除原因
      const reason = delForm.getFieldValue('delReason');
      const idsInParam = idToDeleteAlone ? [idToDeleteAlone] : (selectedRowKeys as number[]);
      const requestParam: FetchCustomerDeleteRequest = {
        editReason: reason,
        ids: idsInParam,
      };
      // 不明原因，ytt的FetchHandlingEquipmentDeleteResponse中data没有描述，只能强行转换成自定义类型
      const { data }: FetchCustomerDeleteResponse = await fetchCustomerDelete(requestParam);

      if (data) {
        const { success, fail } = data;

        // 全部删除成功，显示删除成功
        if (_.isNil(fail) || _.isEmpty(fail)) {
          Message.success('删除成功');
          onSuccess?.();
        } else if (_.isNil(success) || _.isEmpty(success)) {
          // 全部删除失败，提示删除失败及原因
          onFail?.();
          if (fail.length > 0) {
            ErrorNotification({
              message: '删除失败',
              description: (
                <>
                  {fail.map((failItem) => (
                    <div key={failItem.id}>
                      {`${failItem.name}(${failItem.code})`}: {failItem.detail}
                    </div>
                  ))}
                </>
              ),
            });
          } else {
            Message.error('删除失败！');
          }
        } else if (fail.length > 0) {
          onSuccess?.();
          // 否则用表格显示删除结果及原因统计的table
          showErrorListMessage({
            title: '删除出现错误',
            data: fail,
            columns: errorColumns,
            operateName: '删除',
            successCount: success.length,
            failCount: fail.length,
          });
        }
        // 删除成功以后清空删除表单里已经填的删除原因
        delForm.resetFields();
      } else {
        onFail?.();
        console.log('api返回的response里没有data');
      }
    } catch (error) {
      onFail?.();
      console.log(error);
    }
  };
  /**
   * 启停用操作
   */
  const handleEnableOrDisableCustomer = async (
    ids: Array<number>,
    enableFlag: UsageStatus,
    onSuccess?: () => void,
    onFail?: () => void,
  ) => {
    try {
      const requestParam: FetchCustomerEnableRequest | FetchCustomerDisableRequest = { ids };
      const requestFn = enableFlag ? fetchCustomerDisable : fetchCustomerEnable;

      await requestFn(requestParam);

      Message.success(enableFlag ? '停用成功' : '启用成功');
      onSuccess?.();
      refresh();
    } catch (error) {
      onFail?.();
      refresh();
    }
  };
  /**
   * Columns
   */
  const dataColumns = _.compact([
    {
      title: '客户编号',
      dataIndex: 'code',
      width: 150,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.text,
      render: (text: string, record: CustomerListItemProps, index: number, config: any) => {
        return hasAuth(authDict.customer_detail) ? (
          <a
            onClick={() => {
              setCurrentData(record);
              setMode(CRUD.view);
            }}
          >
            <BlTooltip text={text} width={config.contentWidth} />
          </a>
        ) : (
          <BlTooltip text={text} width={config.contentWidth} />
        );
      },
    },
    {
      title: '客户名称',
      dataIndex: 'name',
      width: 150,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.text,
      render: (text: string, record: CustomerListItemProps, index: number, config: any) => {
        return hasAuth(authDict.customer_detail) ? (
          <a
            onClick={() => {
              setCurrentData(record);
              setMode(CRUD.view);
            }}
          >
            <BlTooltip text={text} width={config.contentWidth} />
          </a>
        ) : (
          <BlTooltip text={text} width={config.contentWidth} />
        );
      },
    },
    {
      title: '客户所有人',
      dataIndex: 'owner',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (user: Required<CustomerListItemPropsUser>) => {
        return user.id ? avatarDisplay({ ...user, isUser: true }) : replaceSign;
      },
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择客户所有人" isMultiple isSelectUser />
      ),
    },
    Auth.supplyChain && {
      title: '注册状态',
      dataIndex: 'regStatus',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.customer.registrationStatus,
        mode: 'multiple',
      },
      render: (text: number) => lookup('customer.registrationStatus', text) || replaceSign,
    },
    Auth.supplyChain && {
      title: '注册企业',
      dataIndex: 'regOrg',
      width: 150,
      type: fieldTypeList.text,
      render: (text: { orgId: number | null; orgCode: string | null; orgName: string }) =>
        text?.orgName || replaceSign,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (user: Required<CustomerListItemPropsUser>) => {
        return user?.id ? avatarDisplay({ ...user, isUser: true }) : replaceSign;
      },
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择创建人" isMultiple isSelectUser />
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 180,
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: '变更人',
      dataIndex: 'operator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (user: Required<CustomerListItemPropsUser>) => {
        return user?.id ? avatarDisplay({ ...user, isUser: true }) : replaceSign;
      },
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择更新人" isMultiple isSelectUser />
      ),
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 180,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    // {
    //   title: '自定义字段',
    //   dataIndex: 'customFieldItemList',
    //   width: 150,
    //   isFilter: true,
    //   type: fieldCategory.customFields,
    //   render: valueOrHolder,
    // },
    {
      title: '状态',
      dataIndex: 'status',
      width: 120,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.common.usageStatus,
        mode: 'multiple',
      },
      render: renderUsageStatus,
    },
  ]);
  /**
   * 主操作按钮
   */
  const mainMenu = _.compact([
    {
      title: '查看注册邀请列表',
      icon: <BlIcon type="iconcaozuojilu" />,
      onClick: () => {
        window.open('/modeling/customerModeling/customerRegisterList');
      },
      auth: authDict.customerregisteredaudit_reg_list,
    },
    {
      title: '查看物料注册邀请列表',
      icon: <BlIcon type="iconcaozuojilu" />,
      onClick: () => {
        window.open('/modeling/customerModeling/materialsRegisterList');
      },
      auth: authDict.customermaterialregisteredaudit_reg_list_material,
    },
    {
      title: '导出',
      showExport: true,
      auth: authDict.customer_export,
      icon: <BlIcon type="icondaochu" />,
      onClick: () =>
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.customer,
        }),
    },
    {
      title: '新建客户',
      icon: <BlIcon type="iconxinjiantianjia" />,
      showExport: true,
      auth: authDict.customer_add,
      onClick: () => {
        setMode(CRUD.create);
      },
      items: [
        {
          title: '导入',
          auth: authDict.customer_import,
          showExport: true,
          onClick: () =>
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.customer,
            }),
        },
      ],
    },
  ]);
  /**
   * 批量操作按钮
   */
  const batchMenu = [
    {
      title: lookup('changeStatusAction', UsageStatus.disabled),
      key: CRUD.disable,
      auth: authDict.customer_enable_disable,
      onClick: (onSuccess?: () => void, onFail?: () => void) => {
        handleEnableOrDisableCustomer(
          selectedRowKeys.map((rowKey) => Number(rowKey)),
          UsageStatus.disabled,
          onSuccess,
          onFail,
        );
      },
    },
    {
      title: lookup('changeStatusAction', UsageStatus.enabled),
      key: CRUD.enable,
      // onSuccess, onFail: batchMenu onClick回调的参数，onSuccess会刷新列表并清空批量按钮的loading状态，onFail只清空loading状态
      auth: authDict.customer_enable_disable,
      onClick: (onSuccess?: () => void, onFail?: () => void) => {
        handleEnableOrDisableCustomer(
          selectedRowKeys.map((rowKey) => Number(rowKey)),
          UsageStatus.enabled,
          onSuccess,
          onFail,
        );
      },
    },
    // {
    //   title: lookup('common.crud', CRUD.delete),
    //   // onSuccess, onFail: batchMenu onClick回调的参数，onSuccess会刷新列表并清空批量按钮的loading状态，onFail只清空loading状态
    //   onClick: (onSuccess?: () => void, onFail?: () => void) => {
    //     BLDelConfirm('你确定要删除吗？', delForm, () => {
    //       handleDelete(onSuccess, onFail);
    //     });
    //   },
    // },
  ];
  /**
   * 列操作 ...
   */
  const getOperationList = (record: CustomerListItemProps) => {
    return [
      {
        title: '查看',
        auth: authDict.customer_detail,
        onClick: () => {
          setCurrentData(record);
          setMode(CRUD.view);
        },
      },
      {
        title: '编辑',
        auth: authDict.customer_edit,
        onClick: () => {
          setCurrentData(record);
          setMode(CRUD.edit);
        },
      },
      {
        title: lookup('changeStatusAction', record.status),
        auth: authDict.customer_enable_disable,
        onClick: () => handleEnableOrDisableCustomer([record.id!], record.status!),
      },
      // {
      //   title: '删除',
      //   onClick: () => {
      //     BLDelConfirm('你确定要删除吗？', delForm, () => {
      //       handleDelete(refresh, undefined, record.id);
      //     });
      //   },
      // },
      {
        title: '操作记录',
        auth: authDict.customer_operrecord,
        onClick: () => {
          setCurrentData(record);
          setVisibleLog(true);
        },
      },
    ];
  };
  /**
   * 列表筛选处理函数
   */
  const filterList: FilterItem[] = dataColumns
    .filter((i) => i.isFilter)
    .map((column) => {
      return _.pick(
        {
          ...column,
          label: column.title,
          name: column.dataIndex,
          type: column.type,
        },
        [
          'label',
          'name',
          'type',
          'rules',
          'renderItem',
          'selectProps',
          'inputProps',
          'datePickerProps',
          'dateFormat',
        ],
      ) as FilterItem;
    });
  /**
   * 传给接口数据处理函数
   */
  const formatDataToQuery = (param: any) => {
    const { creator, operator, owner, createdAt, updatedAt, ...rest } = param;
    const [createdAtFrom, createdAtTo] = gcTime.formatRangeUnixToInt(createdAt);
    const [updatedAtFrom, updatedAtTo] = gcTime.formatRangeUnixToInt(updatedAt);

    const options = {
      createdAtFrom,
      createdAtTo,
      updatedAtFrom,
      updatedAtTo,
      creator: creator?.length ? _.map(creator, 'value') : null,
      operator: operator?.length ? _.map(operator, 'value') : null,
      owner: owner?.length ? _.map(owner, 'value') : null,
      ...rest,
    };

    dispatch.excelImport.updateBusinessData({ businessData: options });

    return gcObject.filterEmptyProperty(options);
  };
  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (param: any) => {
    const { createdAt, updatedAt, ...rest } = param;

    const result = {
      ...rest,
      createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
      updatedAt: gcTime.formatRangeTimeToMoment(updatedAt || []),
    };

    return gcObject.filterEmptyProperty(result);
  };

  return (
    <>
      <RecordListLayout<CustomerListItemProps>
        columns={dataColumns}
        filterList={filterList}
        rowKey={'id'}
        configcacheKey={'customer'}
        placeholder={'请输入客户编号或者名称'}
        mainMenu={mainMenu}
        batchMenu={batchMenu}
        getOperationList={getOperationList}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectedKeys) => {
          setSelectedRowKeys(selectedKeys);
        }}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        requestFn={fetchCustomerList}
        refreshMarker={refreshMarker}
      />
      {mode === CRUD.create || mode === CRUD.edit ? (
        <CreateAndEdit
          operateMode={mode}
          customerData={currentData}
          editShowMode={(currentData) => {
            setCurrentData(currentData);
            setMode(CRUD.view);
          }}
          onClose={() => {
            setCurrentData(undefined);
            setMode(undefined);
          }}
          refresh={refresh}
        />
      ) : null}
      {mode === CRUD.view && currentData ? (
        <CustomerDetail
          customerData={currentData}
          onClose={() => {
            setCurrentData(undefined);
            setMode(undefined);
          }}
          showEdit={() => {
            setMode(CRUD.edit);
          }}
          refresh={handleRefreshDetailStatus}
        />
      ) : null}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={currentData?.id as number}
          objectCode={OBJECT_OF_CODE.customer}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default CustomerList;
