import React, { useState } from 'react';
import { Col, List, Modal, Radio, Row } from 'antd';
import { RelationObject, RelationFieldInfo } from '../index.d';
import lookup from 'src/dict';
import _ from 'lodash';
import { Cell } from '@antv/x6';

const ChooseModal = ({
  showSelectModel,
  objectInfo,
  saveChooseField,
  closeModal,
}: {
  showSelectModel: boolean;
  objectInfo: { relationObject: RelationObject | null; currenObject: Cell | null };
  closeModal: () => void;
  saveChooseField: (field: RelationFieldInfo, refField: RelationFieldInfo[]) => void;
}) => {
  const [relationInfo, setRelationInfo] = useState<any>();
  const { relationObject, currenObject } = objectInfo;
  const { objectName, shipRefFieldInfoList, targetType } = relationObject || {};
  const chooseFields = _.map(
    currenObject?.getData().nodeRefFieldInfo,
    (field) => field.preRefFieldId || field.refFieldId,
  );

  return (
    <Modal
      visible={showSelectModel}
      title="选择关联字段"
      onCancel={closeModal}
      onOk={() => saveChooseField(relationInfo, shipRefFieldInfoList || [])}
    >
      <Row>
        <Col span={12}>
          <p>当前对象: {currenObject?.getData().displayName}</p>
        </Col>
        <Col span={12}>
          <p>关联对象: {objectName}</p>
        </Col>
        <Col span={12}>
          <p>关联关系: {lookup('customReport.relationShip', targetType)}</p>
        </Col>
        <Col span={12}>
          <p>关联方式: {'左关联'}</p>
        </Col>
        <Col span={12}>
          <p>关联字段: </p>
          <Radio.Group buttonStyle="solid">
            {shipRefFieldInfoList?.map((_relationInfo, index) => (
              <Radio.Button
                value={_relationInfo}
                onClick={() => {
                  setRelationInfo(_relationInfo);
                }}
                disabled={chooseFields?.includes(
                  _relationInfo.preRefFieldId || _relationInfo.refFieldId,
                )}
                style={{ width: '100%' }}
                key={_relationInfo.preRefFieldId}
              >
                <span>
                  {index + 1}.{' '}
                  {`${_relationInfo?.preObjectName}.${_relationInfo?.preRefFieldDisplayName}`}
                </span>
              </Radio.Button>
            ))}
          </Radio.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default ChooseModal;
