import React, { useState } from 'react';
import { Space, Dropdown, Menu, Form, message as Message, Modal, InputNumber } from 'antd';
import { FilterItem, RecordListLayout } from 'layout';
import { useHistory } from 'react-router-dom';
import {
  BlIcon,
  BlTooltip,
  ErrorNotification,
  showErrorListMessage,
  SingleOperationLogComponent,
  Tooltip,
} from 'components';
import { replaceSign } from 'src/utils/constants';
import { fieldTypeList } from 'src/utils';
import { isUndefined, map } from 'lodash';
import {
  CreatorType,
  ShiftRuleListVOSType,
  TableRowType,
  WorkerCalendarInDeleteShiftVOS,
} from './interface';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import _Time from 'src/utils/dataTypes/time';
import { CYCLE_TYPE_ENUM, FIELD_MAP } from './constant';
import { formatUseNames, momentData } from '../shift/utils';
import _String from 'src/utils/dataTypes/string';
import BLDelConfirm from '../../share/modalConfirm';
import { toSchedulingCreate, toSchedulingDetail, toSchedulingEdit } from '../shift/navigation';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import SchedulingDetail from './detail';
import {
  fetchShiftRuleCheck,
  fetchShiftRuleDelete,
  fetchShiftRuleList,
} from 'src/api/ytt/calendar-domain';
import _Array from 'src/utils/dataTypes/array';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';

export default function SchedulingRulesList() {
  const history = useHistory();
  const [detailVisible, setDetailVisible] = useState(false);
  const [schedulingId, setSchedulingId] = useState<any>();
  const [checkVisible, setCheckVisible] = useState(false);
  // const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录

  const [delForm] = Form.useForm();

  // 刷新页面
  const refresh = () => {
    const date = Date.now();

    setRefreshMarker(date);
  };

  const errorColumns = [
    {
      title: '排班规则名称',
      dataIndex: 'name',
      width: 200,
      render: (name: string) => name || replaceSign,
    },
    {
      title: '失败原因',
      dataIndex: 'workerCalendarInDeleteShiftVOS',
      width: 700,
      render: (workerCalendarInDeleteShiftVOS: WorkerCalendarInDeleteShiftVOS[]) => {
        const useCalendarNames = workerCalendarInDeleteShiftVOS
          ?.map((node: WorkerCalendarInDeleteShiftVOS) => node?.name)
          .join('，');

        return (
          <>
            {!_String.isEmpty(useCalendarNames) ? (
              <div>
                有工作日历：<span>{useCalendarNames}</span>
                ，正在使用该排班规则
              </div>
            ) : null}
          </>
        );
      },
    },
  ];

  // const handleDelete = async (
  //   type: string,
  //   delId: number[],
  //   onSuccess: () => void,
  //   onFail?: () => void,
  // ) => {
  //   try {
  //     // const value = await delForm?.validateFields();
  //     // TODO：后面补充删除的原因

  //     const { message, data } = await fetchShiftRuleDelete({ ids: delId });

  //     if (data) {
  //       if (!data?.failedCount) {
  //         Message.success('删除成功');
  //         onSuccess?.();
  //         return;
  //       }
  //       if (data?.successCount === 0) {
  //         onFail?.();
  //         // 全部删除失败
  //       } else if (data?.failedCount) {
  //         onSuccess?.();
  //         // 有失败的删除 需要展示失败信息
  //       }
  //       const useCalendarNames =
  //         data?.shiftRuleDeleteDetailVOS?.[0]?.workerCalendarInDeleteShiftVOS
  //           ?.map((node: any) => node?.name)
  //           .join('，') ?? '';

  //       if (type === 'single') {
  //         ErrorNotification({
  //           message: '删除失败',
  //           description: (
  //             <>
  //               {!_String.isEmpty(useCalendarNames) ? (
  //                 <div>
  //                   有工作日历：<span>{useCalendarNames}</span>
  //                   ，正在使用该班次
  //                 </div>
  //               ) : null}
  //             </>
  //           ),
  //         });
  //       } else {
  //         showErrorListMessage({
  //           title: '删除出现失败',
  //           data: data?.shiftRuleDeleteDetailVOS ?? [],
  //           columns: errorColumns,
  //           operateName: '删除',
  //           successCount: data?.successCount,
  //           failCount: data?.failedCount,
  //           width: 1200,
  //         });
  //       }

  //       return;
  //     }
  //     Message.error(message);
  //   } catch (error) {
  //     onFail?.();
  //     console.log(error);
  //   }
  // };

  const getShiftName = (data: ShiftRuleListVOSType[]) => {
    const formatName = data
      ?.map((node: ShiftRuleListVOSType) => node?.shiftInShiftRuleInfoListVO?.name)
      ?.join('—');

    return formatName;
  };

  // 编辑前的check
  const editCheckFn = async (id: number) => {
    try {
      const { data } = await fetchShiftRuleCheck({ id });

      if (data) {
        const { inUse, workerCalendarVOS } = data;

        if (inUse) {
          // 被使用中，弹框提醒
          Modal.confirm({
            title: '确定要编辑这条排班规则吗?',
            content: (
              <div>
                有
                {_Array.isEmpty(workerCalendarVOS)
                  ? ''
                  : map(workerCalendarVOS, (node: any) => {
                      return (
                        <span
                          style={{ color: '#02B980', cursor: 'pointer' }}
                          onClick={() => {
                            window.reactRouterHistoryInstance.push(
                              `/modeling/calendarManagement/workCalendar/detail${node?.id}`,
                            );
                          }}
                        >
                          {node?.name}
                        </span>
                      );
                    })}
                等日历， 正在使用该排班规则，排班规则修改后，将影响到这些工作日历，你确定要修改吗？
              </div>
            ),
            visible: checkVisible,
            onOk() {
              history.push(toSchedulingEdit(id));
            },
            onCancel() {
              setCheckVisible(false);
            },
            okText: '确定',
            cancelText: '取消',
          });
          return;
        }
      }

      // 未被引用
      history.push(toSchedulingEdit(id));
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const hasAuthRes = hasAuth(authDict.shiftrule_detail);

  const dataColumns = [
    {
      title: '规则名称',
      dataIndex: 'name',
      width: 150,
      sorter: true,
      render: (name: string, record: TableRowType, index: number, config: any) => {
        if (!name) return replaceSign;
        return (
          <>
            {hasAuthRes ? (
              <a
                onClick={() => {
                  history.push(toSchedulingDetail(record?.id));
                }}
              >
                <Tooltip text={name ?? replaceSign} width={config.contentWidth} />
              </a>
            ) : (
              <Tooltip text={name ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },
    {
      title: '周期类型',
      dataIndex: 'type',
      width: 150,
      sorter: true,
      render: (type: number) => CYCLE_TYPE_ENUM.get(type) || replaceSign,
    },
    {
      title: '重复周期',
      dataIndex: 'periodNum',
      width: 150,
      sorter: true,
      render: (periodNum: number) => periodNum || replaceSign,
    },
    {
      title: '周期班次',
      dataIndex: 'shiftRuleInfoListVOS',
      width: 150,
      render: (shiftRuleInfoListVOS: ShiftRuleListVOSType[]) =>
        getShiftName(shiftRuleInfoListVOS) || replaceSign,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
      render: (remark: string) => remark || replaceSign,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 150,
      render: (creator: CreatorType) => {
        return (
          <AvatarDisplay
            id={creator?.id}
            name={creator?.name}
            avatarUrl={creator?.avatarUrl}
            key={creator?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 180,
      sorter: true,
      render: (createdAt: Date) => {
        if (!createdAt) return replaceSign;
        return _Time.format(createdAt);
      },
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      width: 150,
      render: (operator: CreatorType) => {
        return (
          <AvatarDisplay
            id={operator?.id}
            name={operator?.name}
            avatarUrl={operator?.avatarUrl}
            key={operator?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 180,
      sorter: true,
      render: (updatedAt: Date, record: TableRowType) => {
        if (!updatedAt) return _Time.format(record?.createdAt) ?? replaceSign; // 没有更新,默认为创建时间
        return _Time.format(updatedAt);
      },
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '规则名称',
      name: 'name',
      type: fieldTypeList.text,
    },
    {
      label: '周期类型',
      name: 'type',
      type: fieldTypeList.select,
      selectProps: {
        allowClear: true,
        options: [
          {
            label: '按天',
            value: 1,
          },
          {
            label: '按周',
            value: 2,
          },
          {
            label: '按月',
            value: 3,
          },
        ],
      },
    },
    {
      label: '重复周期',
      name: 'periodNum',
      type: fieldTypeList.integer,
      renderItem: (
        <InputNumber min={1} max={31} style={{ width: '100%' }} placeholder="请输入重复周期" />
      ),
    },
    {
      label: '创建人',
      name: 'creatorId',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<CreatorType> isMultiple={false} isSelectUser />,
    },
    {
      label: '创建时间',
      name: 'createdAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '更新人',
      name: 'operatorId',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<CreatorType> isMultiple={false} isSelectUser />,
    },
    {
      label: '更新时间',
      name: 'updatedAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
  ];

  const left = [
    {
      title: '新建排班规则',
      auth: authDict.shiftrule_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        history.push(toSchedulingCreate());
      },
      items: [],
    },
  ];

  // const toolbar = [
  //   {
  //     title: '删除',
  //     icon: <BlIcon type="iconshanchulajitong1" />,
  //     onClick: (onSuccess: any, onFail: any) => {
  //       BLDelConfirm(
  //         '删除后将无法恢复，确认删除？',
  //         delForm,
  //         () => {
  //           handleDelete('batch', selectedRowKeys, onSuccess, onFail);
  //         },
  //         () => {
  //           onFail();
  //         },
  //       );
  //     },
  //   },
  // ];

  const formatDataToQuery = (params: any) => {
    const { updatedAt, createdAt, sorter, creatorId, operatorId, quickSearch, ...rest } = params;

    const realParams = {
      ...rest,
    };

    if (createdAt) {
      realParams.createdAtFrom = Number(_Time.formatToUnix(createdAt[0]));
      realParams.createdAtTill = Number(_Time.formatToUnix(createdAt[1]));
    }
    if (updatedAt) {
      realParams.updatedAtFrom = Number(_Time.formatToUnix(updatedAt[0]));
      realParams.updatedAtTill = Number(_Time.formatToUnix(updatedAt[1]));
    }

    if (!_String.isEmpty(quickSearch)) {
      realParams.quickSearch = quickSearch;
    }

    if (!_Array.isEmpty(creatorId)) {
      realParams.creatorId = creatorId?.[0]?.value;
    }
    if (!_Array.isEmpty(operatorId)) {
      realParams.operatorId = operatorId?.[0]?.value;
    }

    if (sorter) {
      realParams.sorter = map(sorter, (node: any) => {
        return {
          order: node?.order,
          field: FIELD_MAP.get(node?.field),
        };
      });
    }

    return realParams;
  };

  const formatDataToDisplay = (data: any) => {
    const { type, updatedAt, createdAt, creatorId, operatorId } = data;

    const relData = { ...data };

    if (type) {
      relData.type = CYCLE_TYPE_ENUM.get(type);
    }

    if (createdAt) {
      const createdAtFrom = _Time.format(createdAt[0], 'YYYY-MM-DD HH:mm:ss');
      const createdAtTill = _Time.format(createdAt[1], 'YYYY-MM-DD HH:mm:ss');

      relData.createdAt = `${createdAtFrom} — ${createdAtTill}`;
    }
    if (updatedAt) {
      const updatedAtFrom = _Time.format(updatedAt[0], 'YYYY-MM-DD HH:mm:ss');
      const updatedAtTill = _Time.format(updatedAt[1], 'YYYY-MM-DD HH:mm:ss');

      relData.updatedAt = `${updatedAtFrom} — ${updatedAtTill}`;
    }

    if (creatorId) {
      relData.creatorId = creatorId[0]?.label;
    }

    if (operatorId) {
      relData.operatorId = operatorId[0]?.label;
    }

    return relData;
  };

  const formatDataToFormDisplay = (data: any) => {
    const { updatedAt, createdAt } = data;

    const relData = { ...data };

    if (updatedAt) {
      relData.updatedAt = momentData(updatedAt);
    }
    if (createdAt) {
      relData.createdAt = momentData(createdAt);
    }

    return relData;
  };

  const config = {
    rowKey: (record: TableRowType) => String(record.id),
    columns: dataColumns,
    getOperationList: (record: TableRowType) => {
      return [
        {
          title: '查看',
          auth: authDict.shiftrule_detail,
          onClick: () => {
            setSchedulingId(record?.id);
            setDetailVisible(true);
          },
        },
        {
          title: '编辑',
          auth: authDict.shiftrule_edit,
          onClick: () => editCheckFn(record?.id),
        },
        {
          title: '操作记录',
          auth: authDict.shiftrule_operrecord,
          onClick: () => {
            setVisibleLog(true);
            setSchedulingId(record?.id);
          },
        },
      ];
    },
    mainMenu: left,
    filterList,
    formatDataToQuery,
    formatDataToDisplay,
    formatDataToFormDisplay,
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        userAuth={getAuthFromLocalStorage()}
        placeholder={'输入规则名称'}
        // selectedRowKeys={selectedRowKeys}
        configcacheKey="schedulingCol" // 储存列配置
        // onSelectedRowKeys={(selectKey: any) => {
        //   setSelectRowKeys(selectKey);
        // }}
        requestFn={(params) => fetchShiftRuleList(params)}
        refreshMarker={refreshMarker}
        {...config}
      />
      {detailVisible && (
        <SchedulingDetail
          visible={detailVisible}
          closeDetail={() => {
            setDetailVisible(false);
          }}
          schedulingId={schedulingId}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={schedulingId}
          objectCode={'ShiftRule'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
}
