/**
 * @enum 销售退货单业务模块枚举值
 */

/** 列表视图 */
export enum ListViewType {
  /** 通用业务类型 */
  order,
  /** 生产业务类型 */
  detail,
}

/** 退货来源 */
export enum ReturnOrigin {
  /** 普通退货 */
  normal = 1,
  /** 协同退货 */
  cowork,
}

/** 订单来源 */
export enum SalesOrderOrigin {
  /** 普通订单 */
  normal,
  /** 协同订单 */
  cowork,
}

/** 销售订单发货状态 */
export enum SalesOrderShipmentStatus {
  /** 待发货 */
  ready = 1,
  /** 部分发货 */
  partial,
  /** 全部发货 */
  all,
}

/** 退货状态 */
export enum ReturnStatus {
  /** 新建 */
  new = 1,
  /** 执行中 */
  executing,
  /** 已结束 */
  finished,
  /** 已关闭 */
  closed,
}

/** 退货申请状态 */
export enum ApplicationStatus {
  /** 待处理 */
  waiting = 0,
  /** 已接受 */
  accepted,
  /** 已拒绝 */
  rejected,
}
