import { Collapse } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { OverflowTooltip } from 'src/components';
import { gcArray } from 'utils';
import styles from './styles.module.scss';

interface BlSubMenuProps {
  itemArray: any[];
  path: string;
  title?: React.ReactNode;
  initCollapsed?: boolean;
}

const BlSubMenu = (props: BlSubMenuProps) => {
  const [activeKeys, setActiveKeys] = useState<string[] | string>([]);
  const { itemArray, path, title, initCollapsed } = props;

  useEffect(() => {
    if (!initCollapsed) {
      setActiveKeys(_.map(itemArray, 'path'));
    }
  }, [initCollapsed, itemArray]);

  const renderMenuItem = (item: any) => {
    // 选中态包括自身页面和子页面。加一个斜杠，将子页面与相同前缀的同级路径区分开来
    const isSelected = path === item.path || path.includes(`${item.path}/`);

    return (
      <Link
        key={item.path}
        className={`${styles.menuItem} ${isSelected ? styles.menuItemSelected : ''}`}
        to={item.path}
        draggable={false}
      >
        <OverflowTooltip text={item.name} placement="right" />
      </Link>
    );
  };

  return (
    <div className={styles.submenu}>
      <h2 className={styles.title}>{title}</h2>
      <div className="menu_level_2" id="submenu" style={{ borderRight: 'none' }}>
        <Collapse
          ghost
          className={styles.content}
          activeKey={activeKeys}
          onChange={(keys) => setActiveKeys(keys)}
        >
          {itemArray.map((item: any) => {
            if (!item?.name) return;

            if (gcArray.isEmpty(item.children)) {
              return (
                <Collapse.Panel
                  key={item.path}
                  header={<OverflowTooltip text={item.name} placement="right" />}
                  showArrow={false}
                  collapsible={'header'}
                >
                  {renderMenuItem(item)}
                </Collapse.Panel>
              );
            }
            return (
              <Collapse.Panel
                className={styles['menu-collapse-item']}
                key={item.path}
                header={<OverflowTooltip text={item.name} placement="right" />}
              >
                {item.children?.map((node: any) => {
                  if (!node?.name) return;
                  return renderMenuItem(node);
                })}
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </div>
    </div>
  );
};

export default BlSubMenu;
