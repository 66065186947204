import { BlSortFormList, TagList } from 'src/components';
import { Form, FormInstance, Input, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import _Time from 'src/utils/dataTypes/time';
import _ from 'lodash';
import { lookup } from 'src/dict';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { replaceSign } from 'src/utils/constants';

interface StoreDetailProps {
  form: FormInstance;
}

const RemoveMaterialRegisterFormTable = (props: StoreDetailProps) => {
  const { form } = props;

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    const baseColumns: ColumnProps<any & FormListFieldData>[] = [
      {
        title: '序号',
        fixed: 'left',
        dataIndex: 'lineNo',
        key: 'lineNo',
        width: 60,
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field?.name, 'lineNo']}
              fieldKey={[field?.key, 'lineNo']}
            >
              {field?.name + 1}
            </Form.Item>
          );
        },
      },
      {
        title: '供应商编号',
        fixed: 'left',
        width: 120,
        dataIndex: 'supplierCode',

        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field.name, 'code']}
              fieldKey={[field.key, 'code']}
            >
              {form.getFieldValue('removeMaterialRegister')[field.name].code ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '供应商名称',
        fixed: 'left',
        width: 120,
        dataIndex: 'supplierName',
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field.name, 'supplierName']}
              fieldKey={[field.key, 'supplierName']}
            >
              {form.getFieldValue('removeMaterialRegister')[field.name].name ?? replaceSign}
            </Form.Item>
          );
        },
      },

      {
        title: '物料编号',
        width: 150,
        dataIndex: 'materialCode',
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field.name, 'materialCode']}
              fieldKey={[field.key, 'materialCode']}
            >
              {form.getFieldValue('removeMaterialRegister')[field.name].materialCode ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料名称',
        width: 150,
        dataIndex: 'materialName',
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field.name, 'materialName']}
              fieldKey={[field.key, 'materialName']}
            >
              {form.getFieldValue('removeMaterialRegister')[field.name].materialName ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料属性',
        dataIndex: 'materialAttr',
        width: 230,
        render: (_, field) => {
          if (!form.getFieldValue('removeMaterialRegister')[field.name].materialAttr) {
            return replaceSign;
          }

          const dataProps = form
            .getFieldValue('removeMaterialRegister')
            [field.name].materialAttr.map(
              (item: { name: string; attributeItem: { content: string }; id: number }) => {
                return { label: `${item.name}:${item.attributeItem.content}`, value: item.id };
              },
            );

          return <TagList dataSource={dataProps} />;
        },
      },
      {
        title: '数量',
        width: 150,
        dataIndex: 'materialAmount',
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field.name, 'materialAmount']}
              fieldKey={[field.key, 'materialAmount']}
            >
              {form.getFieldValue('removeMaterialRegister')[field.name].materialAmount ??
                replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '单位',
        width: 150,
        dataIndex: 'materialUnit',
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field.name, 'materialUnit']}
              fieldKey={[field.key, 'materialUnit']}
            >
              {form.getFieldValue('removeMaterialRegister')[field.name].materialUnit ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '注册状态',
        width: 150,
        dataIndex: 'regStatus',
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field.name, 'regStatus']}
              fieldKey={[field.key, 'regStatus']}
            >
              {lookup(
                'supplyRegStatus.crud',
                form.getFieldValue('removeMaterialRegister')[field.name].regStatus ?? replaceSign,
              )}
            </Form.Item>
          );
        },
      },
      {
        title: '供应商物料',
        width: 150,
        dataIndex: 'supMaterialCode',
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field.name, 'supMaterialCode']}
              fieldKey={[field.key, 'supMaterialCode']}
            >
              {form.getFieldValue('removeMaterialRegister')[field.name].supMaterialCode ??
                replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '供应商数量',
        width: 150,
        dataIndex: 'supMaterialAmount',
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field.name, 'supMaterialAmount']}
              fieldKey={[field.key, 'supMaterialAmount']}
            >
              {form.getFieldValue('removeMaterialRegister')[field.name].supMaterialAmount ??
                replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '供应商单位',
        width: 150,
        dataIndex: 'supMaterialUnit',
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field.name, 'supMaterialUnit']}
              fieldKey={[field.key, 'supMaterialUnit']}
            >
              {form.getFieldValue('removeMaterialRegister')[field.name].supMaterialUnit ??
                replaceSign}
            </Form.Item>
          );
        },
      },
    ];

    return baseColumns;
  };

  return (
    <BlSortFormList
      form={form}
      name={'removeMaterialRegister'}
      renderColumns={() => getColumns()}
      isNeedDrag={false}
      isNeedAdd={false}
    />
  );
};

export default RemoveMaterialRegisterFormTable;
