import React, { ReactNode, useEffect, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Form, Input, Radio, Space, Checkbox, Select, FormInstance, Tooltip, Popover } from 'antd';
import { BIZ_TYPE__BACK_ENUM, QUALITY, STATUS_ENUM } from '../constant';
import { StoreType } from '../interface';
import { QrCode, BlIcon, NumberRulesStandardHook } from 'components';
import _Array from 'src/utils/dataTypes/array';
import { textValidator1, textValidator2 } from 'src/utils/formValidators';

const RadioGroup = Radio.Group;

const { Option } = Select;

const { TextArea } = Input;

interface baseInfoProps {
  onCancel: () => void;
  onFinish: () => void;
  title: string;
  initialData?: StoreType;
  form: FormInstance;
  extra?: ReactNode;
  edit: boolean;
  qcCtrl: boolean;
  setQcCtrlFn: (e: boolean) => void;
  checkResult: boolean;
  refreshMarker?: number;
}

const StoreBaseInfo = (props: baseInfoProps) => {
  const {
    onCancel,
    title,
    onFinish,
    initialData,
    form,
    extra,
    edit,
    qcCtrl,
    setQcCtrlFn,
    checkResult,
    refreshMarker,
  } = props;

  const [isSetQcCtrl, setIsSetQcCtrl] = useState(0);

  const options = QUALITY.map(({ name, id }) => <Option value={id}>{name}</Option>);

  const clickLength = () => {
    if (isSetQcCtrl === 0) {
      return false;
    } else if (isSetQcCtrl === 1) {
      return true;
    }
    return !qcCtrl;
  };

  const qcHidden = initialData?.enableQcCtrl ? clickLength() : !qcCtrl;

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 3,
    items: [
      {
        label: '仓库类型',
        name: 'bizType',
        initialValue: BIZ_TYPE__BACK_ENUM?.ORDINARY,
        rules: [{ required: true, message: '仓库类型不能为空' }],
        render: () => (
          <RadioGroup>
            <Radio value={BIZ_TYPE__BACK_ENUM?.ORDINARY}>
              <Space>普通仓库</Space>
            </Radio>
            <Radio value={BIZ_TYPE__BACK_ENUM?.WORKSHOP}>
              <Space>车间仓库</Space>
            </Radio>
          </RadioGroup>
        ),
      },
      {
        label: '仓库名称',
        name: 'name',
        rules: [
          { required: true, message: '仓库名称不能为空' },
          { validator: textValidator1 },
          { max: 255, message: '不超过255个字符' },
        ],
        render: () => <Input />,
      },
      ...NumberRulesStandardHook({
        label: '仓库编号',
        form,
        edit,
        objectCode: 'Warehouse',
        fieldCode: 'code',
        refreshMarker,
        rules: [{ max: 255, message: '不超过255字符' }, { validator: textValidator2 }],
      }),
      {
        label: '标识码',
        name: 'qrCode',
        rules: [{ validator: textValidator1 }, { max: 256, message: '不超过256个字符' }],
        hidden: !edit,
        render: () => (
          <Input
            suffix={
              <Popover
                placement="rightTop"
                content={
                  <div>
                    <QrCode value={initialData?.qrCode ?? initialData?.code ?? ''} />
                    <div style={{ textAlign: 'center' }}>
                      {initialData?.qrCode ?? initialData?.code}
                    </div>
                  </div>
                }
              >
                <BlIcon type="iconerweima" />
              </Popover>
            }
          />
        ),
      },
      {
        label: (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 4 }}>仓位管理</span>
            <Tooltip title="启用仓位管理后,物料仅存放在仓位节点">
              <BlIcon type="icontixing-jingshi" />
            </Tooltip>
          </span>
        ),
        render: () => (
          <Form.Item
            name="enableStorageLocation"
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox disabled={!checkResult}>启用仓位管理</Checkbox>
          </Form.Item>
        ),
      },
      {
        label: '质量管理',
        render: () => (
          <Form.Item name="enableQcCtrl" valuePropName="checked" style={{ marginBottom: 0 }}>
            <Checkbox
              value={STATUS_ENUM?.ENABLED}
              onChange={(e) => {
                setQcCtrlFn(e?.target?.checked);
                setIsSetQcCtrl(isSetQcCtrl + 1);
              }}
            >
              启用质量管理
            </Checkbox>
          </Form.Item>
        ),
      },
      {
        label: '质量范围',
        name: 'qcCtrlList',
        rules: [{ required: edit ? !qcHidden : qcCtrl, message: '质量范围不可为空' }],
        hidden: edit ? qcHidden : !qcCtrl,
        render: () => <Select mode="multiple">{options}</Select>,
      },
      {
        label: '备注',
        name: 'remark',
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
      {
        label: '编辑原因',
        name: 'operateReason',
        hidden: !edit,
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
    ],
  };

  const info = [baseInfo];

  useEffect(() => {
    if (initialData) {
      form?.setFieldsValue({
        id: initialData?.id,
        bizType: initialData?.bizType,
        code: initialData?.code,
        name: initialData?.name,
        enableStorageLocation: initialData?.enableStorageLocation,
        enableQcCtrl: initialData?.enableQcCtrl,
        qcCtrlList: _Array.isEmpty(initialData?.qcCtrlList)
          ? QUALITY.map((node) => node?.id)
          : initialData?.qcCtrlList,
        qrCode: initialData?.qrCode ?? initialData?.code,
        remark: initialData?.remark,
      });
    } else {
      form?.setFieldsValue({
        qcCtrlList: QUALITY.map((node) => node?.id),
        code_isUseRules: true,
      });
    }
  }, [initialData]);

  return (
    <DataFormLayout
      form={form}
      title={title}
      info={info}
      onCancel={onCancel}
      onFinish={onFinish}
      extra={extra}
    />
  );
};

export default StoreBaseInfo;
