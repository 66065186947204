import React from 'react';
import { Modal, Input, Form, FormInstance } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';

const { TextArea } = Input;

function BLDelConfirm(
  title: string,
  form: FormInstance,
  handleFinish: () => void | Promise<void>,
  onCancel?: () => void | Promise<void>,
  itemName?: string,
  placeholder?: string,
  content?: React.ReactNode,
  okText?: string,
  cancelText?: string,
  reasonLengthLimit?: number,
) {
  Modal.confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content: (
      <div className={styles.blDelConfirmContainer}>
        {content}
        <Form form={form} className={styles.blDelConfirm}>
          <Form.Item
            name={itemName ?? 'delReason'}
            rules={[
              {
                max: reasonLengthLimit ?? 1000,
                message: `最多输入${reasonLengthLimit ?? 1000}个字符`,
              },
            ]}
          >
            <TextArea placeholder={placeholder ?? '请输入原因'} />
          </Form.Item>
        </Form>
      </div>
    ),
    okText: okText || '确认',
    cancelText: cancelText || '取消',
    onOk: async () => {
      await handleFinish();
      form.resetFields();
    },
    onCancel: async () => {
      onCancel && (await onCancel());
      form.resetFields();
    },
  });
}

export default BLDelConfirm;
