import { Form, FormInstance, Modal, Popconfirm, Select } from 'antd';
import { BlSortFormList } from '@blacklake-web/component';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import _ from 'lodash';

interface ModalProps {
  form: FormInstance;
  formNamePath: (string | number)[];
  defectRankList?: { id?: number; name?: string }[];
  defectReasonList?: { id?: number; name?: string }[];
  onSubmit?: (data: any) => void;
}

export const showDefectModal = (props: ModalProps) => {
  const { onSubmit, form, formNamePath = ['defectList'], defectRankList, defectReasonList } = props;
  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '不良原因',
        dataIndex: 'defectReason',
        render: (i, field: FormListFieldData) => (
          <Form.Item
            fieldKey={[field.fieldKey, 'defectReason']}
            name={[field.name, 'defectReason']}
            style={{ marginBottom: 0 }}
          >
            <Select
              placeholder="不良原因"
              style={{ width: 174 }}
              options={defectReasonList?.map((defectReason) => ({
                label: String(defectReason.name),
                value: Number(defectReason.id),
              }))}
              onChange={() => form.validateFields(['defectList'])}
            />
          </Form.Item>
        ),
      },
      {
        title: '不良等级',
        dataIndex: 'defectRank',
        render: (_, field: FormListFieldData) => (
          <Form.Item
            fieldKey={[field.fieldKey, 'defectRank']}
            name={[field.name, 'defectRank']}
            style={{ marginBottom: 0 }}
          >
            <Select
              placeholder="不良等级"
              style={{ width: 174 }}
              options={defectRankList?.map((defectRank) => ({
                label: String(defectRank.name),
                value: Number(defectRank.id),
              }))}
              onChange={() => form.validateFields(['defectList'])}
            />
          </Form.Item>
        ),
      },
    ];
  };

  form.setFieldsValue({ defectList: form.getFieldValue(formNamePath) || [] });

  Modal.confirm({
    title: '不良信息',
    icon: null,
    width: 550,
    content: (
      <BlSortFormList
        name={'defectList'}
        form={form}
        renderColumns={() => getColumns()}
        formProps={{
          validateTrigger: 'onSubmit',
        }}
        listRules={[
          {
            validator: async (__, items) => {
              if (items && !_.isEmpty(items.filter((i: any) => !i))) {
                return Promise.reject(Error('不可存在空行'));
              }
              return Promise.resolve(true);
            },
          },
          {
            validator: async (__, items) => {
              const uniqArrary = _.uniqWith(items, _.isEqual);

              if (uniqArrary?.length < items?.length) {
                return Promise.reject(Error('每行的不良信息不可重复'));
              }
              return Promise.resolve(true);
            },
          },
        ]}
      />
    ),
    cancelText: '取消',
    onOk: async () => {
      const { defectList } = await form.validateFields(['defectList']);

      form.setFields([{ name: formNamePath, value: defectList }]);
      onSubmit?.(defectList);
    },
  });
};
