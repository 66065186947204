import React from 'react';
import { RecordListLayout } from 'layout';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { fetchStandardBizObjectListPage } from 'src/api/ytt/metadata-domain/custom_object';
import lookup from 'src/dict';
import authDict, { hasAuth } from 'src/utils/auth';
import { ObjectCategory } from 'src/dict/common';
import { toFieldDetailList } from '../navigation';
import { fieldTypeList } from 'utils';
import { LinkTooltip, TabList } from 'src/components';
import CustomObjectList from './customObject';
import type { StandardObjectData } from '../type';
interface TableRowType {
  objectCode: string;
  objectName: string;
  objectDesc: string;
  objectFieldConfig: number;
  objectCodeConfig: number;
  id: number;
}

interface Params {
  quickSearch?: string;
  fieldCode?: string;
  fieldName?: string;
  search?: string;
}

function StandardObjectList() {
  const history = useHistory();
  const formatDataToQuery = (params: Params) => {
    const p: any = _.omit(params, ['quickSearch']);

    p.objectCategory = ObjectCategory.preObject;
    p.search = params.quickSearch;
    return p;
  };

  const config = {
    left: [],
    toolbar: [],
    columns: [
      {
        title: '对象编号',
        dataIndex: 'objectCode',
        width: 150,
        sorter: true,
        render: (text: string, record: TableRowType, index: number, config: any) => (
          <LinkTooltip to={toFieldDetailList(record)} text={text} width={config.contentWidth} />
        ),
      },
      {
        title: '对象名称',
        dataIndex: 'objectName',
        width: 150,
        sorter: true,
        render: (text: string, record: TableRowType, index: number, config: any) => (
          <LinkTooltip to={toFieldDetailList(record)} text={text} width={config.contentWidth} />
        ),
      },
      {
        title: '对象描述',
        dataIndex: 'objectDesc',
        width: 150,
      },
    ],
    rowKey: (record: StandardObjectData) => record.objectCode,
    filterList: [
      {
        label: '对象编号',
        dataIndex: 'fieldCode',
        type: fieldTypeList.text,
        name: 'fieldCode',
      },
      {
        label: '对象名称',
        dataIndex: 'fieldName',
        type: fieldTypeList.text,
        name: 'fieldName',
      },
    ],
    getOperationList: (record: any) => {
      return [
        {
          title: '查看',
          onClick: () => history.push(toFieldDetailList(record)),
          auth: authDict.object_detail,
        },
      ];
    },
  };

  return (
    <>
      <RecordListLayout<StandardObjectData>
        placeholder={'请输入对象名称或编号'}
        columns={config.columns}
        filterList={config.filterList}
        rowKey={config.rowKey}
        batchMenu={config.toolbar}
        mainMenu={config.left}
        getOperationList={config.getOperationList}
        formatDataToQuery={formatDataToQuery}
        requestFn={fetchStandardBizObjectListPage}
      />
    </>
  );
}

const ListWithTabs = () => {
  const tabList = _.compact([
    {
      tab: lookup('objectCategory', ObjectCategory.preObject),
      key: 'standard',
      component: <StandardObjectList />,
    },
    hasAuth(authDict.object_view_custom_objects) && {
      tab: lookup('objectCategory', ObjectCategory.customObject),
      key: 'custom',
      component: <CustomObjectList />,
    },
  ]);

  return <TabList tabList={tabList} defaultKey={'standard'} />;
};

export default ListWithTabs;
