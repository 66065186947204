import { message, Select, Spin } from 'antd';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  fetchMatchRuleMatchRuleTemplatePreviewForBizBatchMergePdf,
  fetchMatchRuleQueryMatchTemplate,
  FetchMatchRuleQueryMatchTemplateResponse,
} from 'src/api/ytt/e-report-domain/matchRule';
import { useReactToPrint } from 'react-to-print';

type TemplateItem = Exclude<FetchMatchRuleQueryMatchTemplateResponse['data'], undefined>[number];

export const PrintContent = React.forwardRef(
  (props: { selectedRowKeys: (string | number)[] }, ref) => {
    const { selectedRowKeys } = props;
    const [data, setData] = useState<any>();
    const [pdfUrl, setPdfUrl] = useState<string>();
    const [isloading, setIsloading] = useState<boolean>(false);
    const printRef = useRef(null);
    const [templateList, setTemplateList] = useState<TemplateItem[]>([]);
    const loadPdf = async (mappingId: number) => {
      const templateInfo = templateList.find((i) => i.id === mappingId);

      if (!templateInfo) {
        message.error('请选择模版');
        return;
      }
      try {
        const {
          data: { previewUrl },
        } = await fetchMatchRuleMatchRuleTemplatePreviewForBizBatchMergePdf({
          objectCode: 'InventoryElement',
          instanceIdList: selectedRowKeys.map(String),
          mappingId: Number(templateInfo?.id),
          templateId: Number(templateInfo?.templateId),
        });

        setPdfUrl(previewUrl);
        fetch(previewUrl)
          .then((res) => res.blob()) // 解析res值为blob
          .then((response) => {
            const reader = new FileReader();

            reader.readAsDataURL(new Blob([response], { type: 'application/pdf' }));
            reader.addEventListener('loadend', () => {
              setData(reader?.result);
              setIsloading(false);
            });
          });
      } catch (err) {
        message.error('下载内容加载出错了');
        setIsloading(false);
      }
    };

    useEffect(() => {
      fetchMatchRuleQueryMatchTemplate({
        objectCode: 'InventoryElement',
      }).then(({ data }) => {
        return setTemplateList(data);
      });
    }, []);

    const handlePrint = useReactToPrint({
      content: () => printRef.current,
    });

    useImperativeHandle(ref, () => ({
      handlePrint,
    }));

    return (
      <div>
        <p>请选择打印模版：</p>
        <Select
          options={templateList.map(({ id, templateName }) => ({
            value: Number(id),
            label: templateName,
          }))}
          style={{ width: 200 }}
          onChange={async (value) => {
            setIsloading(true);
            loadPdf(Number(value));
          }}
          allowClear
        />
        {
          <div style={{ width: '100%', textAlign: 'center', paddingTop: 16 }}>
            {isloading ? (
              <Spin> 打印内容加载中。。。 </Spin>
            ) : (
              pdfUrl && (
                <object data={data} type="application/pdf" width="100%" height="400px" id="pdf">
                  浏览器不支持预览PDF
                </object>
              )
            )}
          </div>
        }
      </div>
    );
  },
);
