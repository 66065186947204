import {
  RequirementTypeEnum,
  LaunchTypeEnum,
  StatusEnum,
  InputTypeEnum,
  SourceType,
  GenerationMethod,
  OperateType,
  quantitySplit,
  standardCapacityTimeUnit,
  OperationRange,
  RequirementPriority,
  ReserveLiberate,
  PlanAttribute,
  DiffRequSources,
  leadTimeUnitMap,
  requirementSourceTypeName,
  requirementSourceType,
  reservedType,
  ReservedGenerationMethodEnum,
  reservedStatus,
} from '.';
import { mappingsFactory } from '../utils';

/** 计划订单需求类型map */
export const RequirementTypeEnumDisplayMap = mappingsFactory(
  ['独立需求', RequirementTypeEnum.INDEPENDENTDEMAND],
  ['相关需求', RequirementTypeEnum.RELEVANTREQUIREMENTS],
);

/** 投放类型map */
export const LaunchTypeEnumDisplayMap = mappingsFactory(['生产工单', LaunchTypeEnum.PWO]);

/** 计划订单状态map */
export const StatusEnumDisplayMap = mappingsFactory(
  ['计划', StatusEnum.PLAN],
  ['计划确认', StatusEnum.PLANCONFIRMATION],
  ['已投放', StatusEnum.LAUNCHED],
  ['已关闭', StatusEnum.CLOSED],
);

/** 投料类型map */
export const InputTypeEnumDisplayMap = mappingsFactory(
  ['投料', InputTypeEnum.INPUT],
  ['投料-添加申请', InputTypeEnum.INPUTADDREQUEST],
);

/** 生成方式map */
export const GenerationMethodDisplayMap = mappingsFactory(
  ['手工录入', GenerationMethod.manualentry],
  ['计划运算', GenerationMethod.programOperation],
  ['拆分', GenerationMethod.split],
);

/** 来源单据类型map */
export const SourceTypeDisplayMap = mappingsFactory(['计划订单', SourceType.plannedOrder]);

/** 操作类型map */
export const OperateTypeDisplayMap = mappingsFactory(
  ['投放', OperateType.launch],
  ['排程', OperateType.schedule],
);
/** 计划运算范围map */
export const OperationRangeDisplayMap = mappingsFactory([
  '独立需求计划订单',
  OperationRange.IndependentDemandPlanOrder,
]);

/** 需求优先级map */
export const RequirementPriorityDisplayMap = mappingsFactory([
  '按需求时间升序',
  RequirementPriority.TimeAscendingOrder,
]);

/** 预留释放设置map */
export const ReserveLiberateDisplayMap = mappingsFactory([
  '不释放预留',
  ReserveLiberate.DoNotReleaseReservation,
]);

/** 计划属性map */
export const PlanAttributeDisplayMap = mappingsFactory(['自制', PlanAttribute.Homemade]);

/** 不同需求来源map */
export const DiffRequSourcesDisplayMap = mappingsFactory(
  ['合并', DiffRequSources.Merge],
  ['不合并', DiffRequSources.NoMerge],
);

/** 提前期单位 */
export const leadTimeUnitDisplayMap = mappingsFactory(
  ['小时', leadTimeUnitMap.Hour],
  ['日', leadTimeUnitMap.Day],
);

/** 需求设置&供应设置单据名称 */
export const reqSourceTypeNameMap = mappingsFactory(
  ['计划订单', requirementSourceTypeName.planOrder],
  ['生产工单', requirementSourceTypeName.productionOrder],
);

/** 需求设置&供应设置单据类型 */
export const reqSourceTypeTypeMap = mappingsFactory(
  ['生产工单', requirementSourceType.planOrder],
  ['普通', requirementSourceType.ordinary],
);

/** 需求设置&供应设置单据类型 */
export const reservedTypeMap = mappingsFactory(
  ['强预留', reservedType.strongReserve],
  ['弱预留', reservedType.weakReserved],
);

/** 需求设置&供应设置单据类型 */
export const reservedStatusMap = mappingsFactory(
  ['预留中', reservedStatus.reserveing],
  ['已释放', reservedStatus.released],
);

/** 预留生成方式map */
export const ReservedGenerationMethodDisplayMap = mappingsFactory(
  ['计划运算', ReservedGenerationMethodEnum.PLAN_OPERATION],
  ['手工录入', ReservedGenerationMethodEnum.MANUAL],
);
export const quantitySplitDisplayMap = mappingsFactory(
  ['按产线拆分', quantitySplit.productionLine],
  ['按班次拆分', quantitySplit.shift],
  ['按维保任务拆分', quantitySplit.maintenance],
);

// standardCapacityTimeUnit
export const sCTimeUnitDisplayMap = mappingsFactory(['小时', standardCapacityTimeUnit.hour]);
