import { TimeType } from 'src/dict/sop';

const getTimeFormatString = (timeType: TimeType) => {
  switch (timeType) {
    case TimeType.ymd:
      return 'YYYY/M/DD';
    case TimeType.ymdhms:
      return 'YYYY/M/DD HH:mm:ss';
    case TimeType.hms:
      return 'HH:mm:ss';
    case TimeType.ymdhm:
      return 'YYYY/M/DD HH:mm';
    case TimeType.mdhm:
      return 'M/DD HH:mm';
    case TimeType.dhm:
      return 'DD HH:mm';
  }
};

export default getTimeFormatString;
