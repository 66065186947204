import { mappingsFactory } from '../utils';
import { InvoiceOrigin, InvoiceStatus, SailOrderOrigin } from '.';

export const invoiceOrigin = mappingsFactory(
  ['普通订单', InvoiceOrigin.normal],
  ['协同订单', InvoiceOrigin.cowork],
  ['协同交货计划单', InvoiceOrigin.collaborativeDeliverySchedule],
);

export const inoviceStatus = mappingsFactory(
  ['新建', InvoiceStatus.new],
  ['执行中', InvoiceStatus.executing],
  ['已结束', InvoiceStatus.finished],
  ['已关闭', InvoiceStatus.closed],
);

export const sailOrderOrigin = mappingsFactory(
  ['普通订单', SailOrderOrigin.normal],
  ['协同订单', SailOrderOrigin.cowork],
);
