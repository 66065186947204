/**
 * @mappings 工艺路线-值映射表
 */

import {
  PlanTypeEnum,
  ProduceTypeEnum,
  reportingMethodEnum,
  FeedingQualityEnum,
  feedingLimitEnum,
  YN,
  reportingMethodsEnum,
} from '.';
import { mappingsFactory } from '../utils';

export const yn = mappingsFactory(['是', YN.yes], ['否', YN.no]);

export const PlanType = mappingsFactory(
  ['强管控', PlanTypeEnum.strong],
  ['弱管控', PlanTypeEnum.week],
  ['不管控', PlanTypeEnum.no],
);

export const ProduceType = mappingsFactory(
  ['管控', ProduceTypeEnum.yes],
  ['不管控', ProduceTypeEnum.no],
);

export const reportingMethod = mappingsFactory(
  ['扫码报工', reportingMethodEnum.scan],
  ['记录报工', reportingMethodEnum.note],
);

export const FeedingQuality = mappingsFactory(
  ['合格', FeedingQualityEnum.qualified],
  ['不合格', FeedingQualityEnum.unqualified],
  ['让步合格', FeedingQualityEnum.concession],
  ['待检', FeedingQualityEnum.toBeInspected],
);

export const feedingLimit = mappingsFactory(
  ['不管控', feedingLimitEnum.no],
  ['比例', feedingLimitEnum.scale],
  ['固定值', feedingLimitEnum.fixed],
);

// 新增在制品中报工方式
export const reportingMethods = mappingsFactory(
  ['扫码报工-合格', reportingMethodsEnum.qualifiedScan],
  ['扫码报工-不合格', reportingMethodsEnum.unqualifiedScan],
  ['记录报工-合格', reportingMethodsEnum.qualifiedNote],
  ['记录报工-不合格', reportingMethodsEnum.unqualifiedNote],
);
