import React, { useEffect, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';
import { Form, Checkbox, Input, message, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import ResFormTable from './resFormTable';
import { ResourceBusinessType } from 'src/dict/resources';
import { resourceTagBusinessType } from 'src/dict/resources/mappings';
import _ from 'lodash';
import {
  fetchResourcesLabelInsert,
  fetchResourcesLabelModify,
  fetchResourcesLabelGet,
} from 'src/api/ytt/resource-domain/resourceTag';
import { CRUD } from 'src/dict/common';
import { checkTwoSidesTrim } from 'src/utils/formValidators';

interface Props {
  type: string;
  refreshTable?: () => void;
  refreshDetails: () => void;
  onCancel?: () => void;
  id?: number | undefined;
}

const BaseInfo = (props: Props) => {
  const { type, onCancel, refreshTable, refreshDetails, id } = props;
  const [, setBussinessType] = useState<number>(0);
  const [form] = Form.useForm();
  const [keepOn, sekeepOn] = useState(type === CRUD.create);
  const [initialData, setInitialData] = useState<any>([]);
  const baseInfo: DataFormLayoutInfoBlock = {
    items: [
      {
        label: '业务类型',
        name: 'businessType',
        initialValue: { value: ResourceBusinessType.equipment },
        rules: [{ required: true, message: '请选择业务类型' }],
        render: () => (
          <Select
            placeholder="请选择业务类型"
            labelInValue
            options={resourceTagBusinessType}
            onChange={(type: any) => {
              setBussinessType(Number(type.value));
            }}
            disabled={type === CRUD.edit}
          />
        ),
      },
      {
        label: '标签项',
        name: 'name',
        rules: [
          { required: true, message: '标签项必填' },
          { max: 255, message: '不超过255字符' },
          { validator: checkTwoSidesTrim },
        ],
        validateFirst: true,
        render: () => <Input placeholder="请输入标签项" />,
      },
      {
        label: '标签值列表',
        render: () => <ResFormTable initialData={initialData?.resourceItemsList} form={form} />,
      },
      {
        label: '备注',
        name: 'remarks',
        rules: [{ max: 1000, message: '不可超过1000字符' }],
        render: () => <TextArea placeholder="请输入备注" />,
      },
      {
        label: '编辑原因',
        name: 'editReason',
        hidden: type === CRUD.create,
        rules: [{ max: 1000, message: '不可超过1000字符' }],
        render: () => <TextArea placeholder="请输入编辑原因" />,
      },
    ],
    column: 1,
  };

  const initCreate = () => {
    form.setFieldsValue({
      code_isUseRules: true,
    });
  };
  const initEdit = async (resId: any) => {
    try {
      if (!resId) return;
      const res: any = await fetchResourcesLabelGet({ groupId: resId });
      const { data } = res;
      const { children, ...rest } = data;
      const arr: any = children?.map((item: any) => {
        return {
          content: item.content,
          id: item.id,
        };
      });
      const { businessType, name, remarks } = rest;
      const val = {
        businessType: { value: businessType },
        content: arr,
        name,
        remarks,
      };

      form.setFieldsValue(val);

      setInitialData(val);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = async () => {
    try {
      const { businessType, content, editReason, name, remarks } = await form?.validateFields();

      const value: any = {
        businessType: businessType.value,
        operateReason: editReason,
        groupId: id,
        contents: content?.map((item: any, index: number) => {
          return {
            ...item,
            index,
          };
        }),
        name,
        remarks,
      };

      if (_.isEmpty(content)) {
        message.error('标签值必填');

        return;
      }

      switch (type) {
        case CRUD.create:
          await fetchResourcesLabelInsert(value);
          message.success('创建成功');
          break;

        case CRUD.edit:
          await fetchResourcesLabelModify(value);
          message.success('编辑成功');
          break;

        default:
          break;
      }
      if (keepOn) {
        typeof refreshTable === 'function' && refreshTable();
        form.resetFields();
        initCreate();
        return;
      }
      onCancel && onCancel();
      typeof refreshTable === 'function' && refreshTable();
      refreshDetails();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    switch (type) {
      case CRUD.create:
        initCreate();
        break;

      case CRUD.edit:
        initEdit(id);
        break;

      default:
        initCreate();
        break;
    }
  }, []);

  return (
    <DataFormLayout
      form={form}
      info={[baseInfo]}
      title={type === CRUD.create ? '新建标签' : '编辑标签'}
      extra={
        type === CRUD.create && (
          <Checkbox
            onChange={() => {
              sekeepOn(!keepOn);
            }}
            defaultChecked={keepOn}
          >
            连续新建
          </Checkbox>
        )
      }
      onCancel={onCancel}
      onFinish={handleFinish}
    />
  );
};

export default BaseInfo;
