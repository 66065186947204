/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../../request';

// makeRequest
function makeRequestRequired<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequestOptional<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData?: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequest<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const optional = makeRequestOptional<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  const required = makeRequestRequired<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  return (
    requestConfig.requestDataOptional ? optional : required
  ) as TRequestConfig['requestDataOptional'] extends true ? typeof optional : typeof required;
}

// Request
export type Request<TReqeustData, TRequestConfig extends RequestConfig, TRequestResult> =
  (TRequestConfig['requestDataOptional'] extends true
    ? (
        requestData?: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult
    : (
        requestData: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult) & {
    requestConfig: TRequestConfig;
  };

const mockUrl_0_7_7_0 = 'http://yapi.blacklake.tech/mock/735' as any;
const devUrl_0_7_7_0 = '' as any;
const prodUrl_0_7_7_0 = '' as any;
const dataKey_0_7_7_0 = undefined as any;

/**
 * 接口 [新建检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/79862) 的 **请求类型**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/create`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemCategoryCreateRequest {
  /**
   * 名称
   */
  name: string;
}

/**
 * 接口 [新建检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/79862) 的 **返回类型**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/create`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemCategoryCreateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: number;
  /**
   * 返回信息
   */
  message: string;
  requestId?: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [新建检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/79862) 的 **请求配置的类型**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/create`
 * @更新时间 `2021-12-08 11:00:02`
 */
type FetchQcCheckItemCategoryCreateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_check_item_category/create',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [新建检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/79862) 的 **请求配置**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/create`
 * @更新时间 `2021-12-08 11:00:02`
 */
const fetchQcCheckItemCategoryCreateRequestConfig: FetchQcCheckItemCategoryCreateRequestConfig = {
  mockUrl: mockUrl_0_7_7_0,
  devUrl: devUrl_0_7_7_0,
  prodUrl: prodUrl_0_7_7_0,
  path: '/quality/domain/web/v1/qc_check_item_category/create',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_7_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcCheckItemCategoryCreate',
};

/**
 * 接口 [新建检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/79862) 的 **请求函数**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/create`
 * @更新时间 `2021-12-08 11:00:02`
 */
export const fetchQcCheckItemCategoryCreate = makeRequest<
  FetchQcCheckItemCategoryCreateRequest,
  FetchQcCheckItemCategoryCreateResponse,
  FetchQcCheckItemCategoryCreateRequestConfig
>(fetchQcCheckItemCategoryCreateRequestConfig);

/**
 * 接口 [删除检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/79868) 的 **请求类型**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/delete`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemCategoryDeleteRequest {
  /**
   * id
   */
  ids?: number[];
}

/**
 * 接口 [删除检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/79868) 的 **返回类型**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/delete`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemCategoryDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  requestId?: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [删除检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/79868) 的 **请求配置的类型**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/delete`
 * @更新时间 `2021-12-08 11:00:02`
 */
type FetchQcCheckItemCategoryDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_check_item_category/delete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [删除检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/79868) 的 **请求配置**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/delete`
 * @更新时间 `2021-12-08 11:00:02`
 */
const fetchQcCheckItemCategoryDeleteRequestConfig: FetchQcCheckItemCategoryDeleteRequestConfig = {
  mockUrl: mockUrl_0_7_7_0,
  devUrl: devUrl_0_7_7_0,
  prodUrl: prodUrl_0_7_7_0,
  path: '/quality/domain/web/v1/qc_check_item_category/delete',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_7_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcCheckItemCategoryDelete',
};

/**
 * 接口 [删除检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/79868) 的 **请求函数**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/delete`
 * @更新时间 `2021-12-08 11:00:02`
 */
export const fetchQcCheckItemCategoryDelete = makeRequest<
  FetchQcCheckItemCategoryDeleteRequest,
  FetchQcCheckItemCategoryDeleteResponse,
  FetchQcCheckItemCategoryDeleteRequestConfig
>(fetchQcCheckItemCategoryDeleteRequestConfig);

/**
 * 接口 [检验项分类详情↗](http://yapi.blacklake.tech/project/735/interface/api/79874) 的 **请求类型**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/detail`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemCategoryDetailRequest {
  /**
   * id
   */
  id?: number;
}

/**
 * 接口 [检验项分类详情↗](http://yapi.blacklake.tech/project/735/interface/api/79874) 的 **返回类型**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/detail`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemCategoryDetailResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    creator?: {
      /**
       * web-User信息VO-头像
       */
      avatarUrl?: string;
      /**
       * web-User信息VO-编号
       */
      code?: string;
      /**
       * web-User信息VO-描述
       */
      desc?: string;
      /**
       * web-User信息VO-id
       */
      id?: number;
      /**
       * web-User信息VO-name
       */
      name?: string;
      /**
       * web-User信息VO-username
       */
      username?: string;
    };
    /**
     * id
     */
    id?: number;
    /**
     * 名称
     */
    name?: string;
    operator?: {
      /**
       * web-User信息VO-头像
       */
      avatarUrl?: string;
      /**
       * web-User信息VO-编号
       */
      code?: string;
      /**
       * web-User信息VO-描述
       */
      desc?: string;
      /**
       * web-User信息VO-id
       */
      id?: number;
      /**
       * web-User信息VO-name
       */
      name?: string;
      /**
       * web-User信息VO-username
       */
      username?: string;
    };
    /**
     * 质检项列表
     */
    qcCheckItemList?: {
      /**
       * 附件
       */
      attachmentIds?: number[];
      /**
       * 检验项分类id
       */
      categoryId?: number;
      /**
       * 检验项分类名称
       */
      categoryName?: string;
      /**
       * 编号
       */
      code?: string;
      /**
       * 创建人
       */
      creator?: {};
      /**
       * id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
      operator?: {
        /**
         * web-User信息VO-头像
         */
        avatarUrl?: string;
        /**
         * web-User信息VO-编号
         */
        code?: string;
        /**
         * web-User信息VO-描述
         */
        desc?: string;
        /**
         * web-User信息VO-id
         */
        id?: number;
        /**
         * web-User信息VO-name
         */
        name?: string;
        /**
         * web-User信息VO-username
         */
        username?: string;
      };
      /**
       * 备注
       */
      remark?: string;
    }[];
  };
  /**
   * 返回信息
   */
  message: string;
  requestId?: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [检验项分类详情↗](http://yapi.blacklake.tech/project/735/interface/api/79874) 的 **请求配置的类型**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/detail`
 * @更新时间 `2021-12-08 11:00:02`
 */
type FetchQcCheckItemCategoryDetailRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_check_item_category/detail',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [检验项分类详情↗](http://yapi.blacklake.tech/project/735/interface/api/79874) 的 **请求配置**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/detail`
 * @更新时间 `2021-12-08 11:00:02`
 */
const fetchQcCheckItemCategoryDetailRequestConfig: FetchQcCheckItemCategoryDetailRequestConfig = {
  mockUrl: mockUrl_0_7_7_0,
  devUrl: devUrl_0_7_7_0,
  prodUrl: prodUrl_0_7_7_0,
  path: '/quality/domain/web/v1/qc_check_item_category/detail',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_7_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcCheckItemCategoryDetail',
};

/**
 * 接口 [检验项分类详情↗](http://yapi.blacklake.tech/project/735/interface/api/79874) 的 **请求函数**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/detail`
 * @更新时间 `2021-12-08 11:00:02`
 */
export const fetchQcCheckItemCategoryDetail = makeRequest<
  FetchQcCheckItemCategoryDetailRequest,
  FetchQcCheckItemCategoryDetailResponse,
  FetchQcCheckItemCategoryDetailRequestConfig
>(fetchQcCheckItemCategoryDetailRequestConfig);

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79880) 的 **请求类型**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/pageList`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemCategoryPageListRequest {
  /**
   * 搜索参数 - 搜索范围：检验项分类名称、关联检验项名称
   */
  name?: string;
  /**
   * 请求页
   */
  page?: number;
  /**
   * 每页大小
   */
  size?: number;
  /**
   * 排序 列表顺序表示排序顺序
   */
  sorter?: {
    /**
     * 排序字段
     */
    field?: string;
    /**
     * 排序规律 默认 asc，asc 升序 desc 降序
     */
    order?: string;
  }[];
}

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79880) 的 **返回类型**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/pageList`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemCategoryPageListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
      /**
       * 质检项列表
       */
      qcCheckItemList?: {
        /**
         * 附件
         */
        attachmentIds?: number[];
        /**
         * 检验项分类id
         */
        categoryId?: number;
        /**
         * 检验项分类名称
         */
        categoryName?: string;
        /**
         * 编号
         */
        code?: string;
        /**
         * 创建人
         */
        creator?: {};
        /**
         * id
         */
        id?: number;
        /**
         * 名称
         */
        name?: string;
        operator?: {
          /**
           * web-User信息VO-头像
           */
          avatarUrl?: string;
          /**
           * web-User信息VO-编号
           */
          code?: string;
          /**
           * web-User信息VO-描述
           */
          desc?: string;
          /**
           * web-User信息VO-id
           */
          id?: number;
          /**
           * web-User信息VO-name
           */
          name?: string;
          /**
           * web-User信息VO-username
           */
          username?: string;
        };
        /**
         * 备注
         */
        remark?: string;
      }[];
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  requestId?: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79880) 的 **请求配置的类型**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/pageList`
 * @更新时间 `2021-12-08 11:00:02`
 */
type FetchQcCheckItemCategoryPageListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_check_item_category/pageList',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79880) 的 **请求配置**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/pageList`
 * @更新时间 `2021-12-08 11:00:02`
 */
const fetchQcCheckItemCategoryPageListRequestConfig: FetchQcCheckItemCategoryPageListRequestConfig =
  {
    mockUrl: mockUrl_0_7_7_0,
    devUrl: devUrl_0_7_7_0,
    prodUrl: prodUrl_0_7_7_0,
    path: '/quality/domain/web/v1/qc_check_item_category/pageList',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_7_7_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchQcCheckItemCategoryPageList',
  };

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79880) 的 **请求函数**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/pageList`
 * @更新时间 `2021-12-08 11:00:02`
 */
export const fetchQcCheckItemCategoryPageList = makeRequest<
  FetchQcCheckItemCategoryPageListRequest,
  FetchQcCheckItemCategoryPageListResponse,
  FetchQcCheckItemCategoryPageListRequestConfig
>(fetchQcCheckItemCategoryPageListRequestConfig);

/**
 * 接口 [编辑检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/79886) 的 **请求类型**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/update`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemCategoryUpdateRequest {
  /**
   * id
   */
  id?: number;
  /**
   * 名称
   */
  name?: string;
  /**
   * 质检项id列表
   */
  qcCheckItemIdList?: number[];
}

/**
 * 接口 [编辑检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/79886) 的 **返回类型**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/update`
 * @更新时间 `2021-12-08 11:00:02`
 */
export interface FetchQcCheckItemCategoryUpdateResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    creator?: {
      /**
       * web-User信息VO-头像
       */
      avatarUrl?: string;
      /**
       * web-User信息VO-编号
       */
      code?: string;
      /**
       * web-User信息VO-描述
       */
      desc?: string;
      /**
       * web-User信息VO-id
       */
      id?: number;
      /**
       * web-User信息VO-name
       */
      name?: string;
      /**
       * web-User信息VO-username
       */
      username?: string;
    };
    /**
     * id
     */
    id?: number;
    /**
     * 名称
     */
    name?: string;
    operator?: {
      /**
       * web-User信息VO-头像
       */
      avatarUrl?: string;
      /**
       * web-User信息VO-编号
       */
      code?: string;
      /**
       * web-User信息VO-描述
       */
      desc?: string;
      /**
       * web-User信息VO-id
       */
      id?: number;
      /**
       * web-User信息VO-name
       */
      name?: string;
      /**
       * web-User信息VO-username
       */
      username?: string;
    };
    /**
     * 质检项列表
     */
    qcCheckItemList?: {
      /**
       * 附件
       */
      attachmentIds?: number[];
      /**
       * 检验项分类id
       */
      categoryId?: number;
      /**
       * 检验项分类名称
       */
      categoryName?: string;
      /**
       * 编号
       */
      code?: string;
      /**
       * 创建人
       */
      creator?: {};
      /**
       * id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
      operator?: {
        /**
         * web-User信息VO-头像
         */
        avatarUrl?: string;
        /**
         * web-User信息VO-编号
         */
        code?: string;
        /**
         * web-User信息VO-描述
         */
        desc?: string;
        /**
         * web-User信息VO-id
         */
        id?: number;
        /**
         * web-User信息VO-name
         */
        name?: string;
        /**
         * web-User信息VO-username
         */
        username?: string;
      };
      /**
       * 备注
       */
      remark?: string;
    }[];
  };
  /**
   * 返回信息
   */
  message: string;
  requestId?: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [编辑检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/79886) 的 **请求配置的类型**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/update`
 * @更新时间 `2021-12-08 11:00:02`
 */
type FetchQcCheckItemCategoryUpdateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_check_item_category/update',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [编辑检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/79886) 的 **请求配置**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/update`
 * @更新时间 `2021-12-08 11:00:02`
 */
const fetchQcCheckItemCategoryUpdateRequestConfig: FetchQcCheckItemCategoryUpdateRequestConfig = {
  mockUrl: mockUrl_0_7_7_0,
  devUrl: devUrl_0_7_7_0,
  prodUrl: prodUrl_0_7_7_0,
  path: '/quality/domain/web/v1/qc_check_item_category/update',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_7_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcCheckItemCategoryUpdate',
};

/**
 * 接口 [编辑检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/79886) 的 **请求函数**
 *
 * @分类 [web-检验项分类↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11346)
 * @标签 `web-检验项分类`
 * @请求头 `POST /quality/domain/web/v1/qc_check_item_category/update`
 * @更新时间 `2021-12-08 11:00:02`
 */
export const fetchQcCheckItemCategoryUpdate = makeRequest<
  FetchQcCheckItemCategoryUpdateRequest,
  FetchQcCheckItemCategoryUpdateResponse,
  FetchQcCheckItemCategoryUpdateRequestConfig
>(fetchQcCheckItemCategoryUpdateRequestConfig);

/* prettier-ignore-end */
