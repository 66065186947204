import React from 'react';
import { BcAttachmentDetail, SearchSelect, TagList } from 'src/components';
import { replaceSign } from 'src/utils/constants';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import _Time from 'src/utils/dataTypes/time';
import { fieldTypeList } from 'src/utils';
import { FilterItem } from 'src/layout';
import { Creator, CustomerDeliveryScheduleNoteDetailProps } from '../interface';
import _ from 'lodash';
import _String from 'src/utils/dataTypes/string';
import lookup, { appDict, appEnum } from 'src/dict';
import {
  formatTime,
  renderTimeUnit,
} from 'src/page/purchase/purchaseManagement/deliveryScheduleNote/utils';
import { formatDateForRender } from 'src/utils/formatters/dateTime';

// 订单单据展示列
export const dataColumnsForDocuments = _.compact([
  {
    title: '来源',
    dataIndex: 'source',
    width: 100,
    render: lookup('purchase.deliveryScheduleNoteSource'),
  },
  {
    title: '客户编号',
    dataIndex: 'customerCode',
    width: 150,
    render: (text: number) => text ?? replaceSign,
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    width: 150,
    render: (text: string) => text ?? replaceSign,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 150,
    render: (status: number) =>
      lookup('collaborativeDeliverySchedule.orderStatus', status) ?? replaceSign,
  },
  {
    title: '计划发货状态',
    dataIndex: 'plannedIssueStatus',
    width: 150,
    render: (text: number) =>
      lookup('collaborativeDeliverySchedule.plannedIssueStatus', text) ?? replaceSign,
  },
  {
    title: '客户备注',
    dataIndex: 'customerRemark',
    width: 150,
    render: (text: string) => text ?? replaceSign,
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    width: 150,
    render: (creator: Creator) => {
      return (
        <AvatarDisplay
          id={creator?.id}
          name={creator?.name}
          avatarUrl={creator?.avatarUrl}
          key={creator?.id}
          isShowTag={false}
          isUser
        />
      );
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    width: 200,
    sorter: true,
    render: (createdAt: Date) => {
      if (!createdAt) return replaceSign;
      return _Time.format(createdAt);
    },
  },
  {
    title: '更新人',
    dataIndex: 'operator',
    width: 150,
    render: (operator: Creator) => {
      return (
        <AvatarDisplay
          id={operator?.id}
          name={operator?.name}
          avatarUrl={operator?.avatarUrl}
          key={operator?.id}
          isShowTag={false}
          isUser
        />
      );
    },
  },
  {
    title: '更新时间',
    dataIndex: 'updatedAt',
    width: 200,
    sorter: true,
    render: (updatedAt: Date) => {
      if (!updatedAt) return replaceSign;
      return _Time.format(updatedAt);
    },
  },
]);

// 订单明细展示列
export const dataColumnsForDetail = _.compact([
  {
    title: '行号',
    dataIndex: 'lineNo',
    width: 100,
    render: (lineNo: number) => lineNo ?? replaceSign,
  },
  {
    title: '销售订单编号',
    dataIndex: 'saleOrderCode',
    width: 200,
    render: (salesOrderCode: string) => salesOrderCode ?? replaceSign,
  },
  {
    title: '销售订单明细行号',
    dataIndex: 'saleOrderLineNo',
    width: 200,
    render: (salesOrderCode: string) => salesOrderCode ?? replaceSign,
  },
  {
    title: '客户编号',
    dataIndex: 'customerCode',
    width: 150,
    render: (text: string) => text ?? replaceSign,
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    width: 150,
    render: (text: string) => text ?? replaceSign,
  },
  {
    title: '客户物料编号',
    dataIndex: 'customerMaterialCode',
    width: 200,
    render: (code: string) => code ?? replaceSign,
  },
  {
    title: '客户物料名称',
    dataIndex: 'customerMaterialName',
    width: 200,
    render: (name: string) => name ?? replaceSign,
  },
  {
    title: '客户交货数量',
    dataIndex: ['customerAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '客户交货单位',
    dataIndex: ['customerAmount', 'unitName'],
    width: 150,
    render: (unitName: string) => unitName ?? replaceSign,
  },
  {
    title: '自建物料编号',
    dataIndex: ['material', 'baseInfo', 'code'],
    width: 200,
    render: (code: string) => code ?? replaceSign,
  },
  {
    title: '自建物料名称',
    dataIndex: ['material', 'baseInfo', 'name'],
    width: 200,
    render: (name: string) => name ?? replaceSign,
  },
  {
    title: '自建物料属性',
    dataIndex: ['material', 'attribute'],
    width: 200,
    render: (
      text: { name: string; attributeItem: { content: string; id: number }; id: number }[],
    ) => {
      if (_.isEmpty(text)) return replaceSign;

      return (
        <TagList
          dataSource={_.map(text, ({ name, attributeItem, id }) => ({
            label: `${name}:${attributeItem.content}`,
            value: id,
          }))}
        />
      );
    },
  },
  {
    title: '自建物料规格',
    dataIndex: ['material', 'baseInfo', 'specification'],
    width: 200,
    render: (name: string) => name ?? replaceSign,
  },
  {
    title: '交货数量',
    dataIndex: ['amount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '交货单位',
    dataIndex: ['amount', 'unitName'],
    width: 150,
    render: (unitName: string) => unitName ?? replaceSign,
  },
  {
    title: '计划发货状态',
    dataIndex: 'plannedIssueStatus',
    width: 150,
    render: (plannedIssueStatus: number) =>
      lookup('collaborativeDeliverySchedule.plannedIssueStatus', plannedIssueStatus) ?? replaceSign,
  },
  {
    title: '交货日期',
    dataIndex: 'deliveryTime',
    width: 200,
    sorter: true,
    render: formatDateForRender,
  },
  {
    title: '最早到货时间',
    dataIndex: 'earliestArrivalTime',
    width: 200,
    sorter: true,
    render: (deliveryDate: number) => {
      return formatTime(deliveryDate) ?? replaceSign;
    },
  },
  {
    title: '最晚到货时间',
    dataIndex: 'latestArrivalTime',
    width: 200,
    sorter: true,
    render: (deliveryDate: number) => {
      return formatTime(deliveryDate) ?? replaceSign;
    },
  },
  {
    title: '计划发货数',
    dataIndex: ['plannedIssueAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '仓库实发数',
    dataIndex: ['outboundAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '客户接收数',
    dataIndex: ['customerReceivedAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '物流时间',
    dataIndex: 'shipmentTime',
    width: 200,
    render: (shipmentTime: number) => shipmentTime ?? replaceSign,
  },
  {
    title: '物流时间单位',
    dataIndex: 'shipmentTimeUnit',
    width: 150,
    render: renderTimeUnit,
  },
  {
    title: '最早发货时间',
    dataIndex: 'earliestDeliveryTime',
    width: 200,
    render: (deliveryDate: number) => {
      return formatTime(deliveryDate) ?? replaceSign;
    },
  },
  {
    title: '最晚发货时间',
    dataIndex: 'latestDeliveryTime',
    width: 200,
    render: (deliveryDate: number) => {
      return formatTime(deliveryDate) ?? replaceSign;
    },
  },
]);

// 订单单据筛选列
export const documentsFilterList: FilterItem[] = _.compact([
  {
    label: '协同交货计划单编号',
    name: 'code',
    type: fieldTypeList.text,
  },
  {
    label: '来源',
    name: 'source',
    type: fieldTypeList?.multiSelect,
    selectProps: {
      options: appDict.purchase.deliveryScheduleNoteSource,
    },
  },
  {
    label: '客户',
    name: 'customerIds',
    type: fieldTypeList.multiSelect,
    renderItem: <SearchSelect fetchType={'customerCodeName'} labelInValue mode="multiple" />,
  },
  {
    label: '状态',
    name: 'status',
    type: fieldTypeList?.multiSelect,
    selectProps: {
      options: appDict.collaborativeDeliverySchedule.orderStatus,
    },
  },
  {
    label: '计划发货状态',
    name: 'plannedIssueStatus',
    type: fieldTypeList?.multiSelect,
    selectProps: {
      options: appDict.collaborativeDeliverySchedule.plannedIssueStatus,
      mode: 'multiple',
    },
  },
  {
    label: '创建时间',
    name: 'createdAt',
    type: fieldTypeList.date,
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
  },
]);
// 明细筛选列
export const detailFilterList: FilterItem[] = _.compact([
  {
    label: '协同交货计划单编号',
    name: 'code',
    type: fieldTypeList.text,
  },
  {
    label: '行号',
    name: 'lineNo',
    type: fieldTypeList.text,
  },
  {
    label: '销售订单编号',
    name: 'saleOrderCode',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <SearchSelect
        fetchType={'salesOrderListSimple'}
        labelInValue
        mode="multiple"
        params={{ deliveryMode: [appEnum.Purchase.PurchaseOrderdeliveryMode.deliverySchedule] }}
      />
    ),
  },
  {
    label: '客户',
    name: 'customerIds',
    type: fieldTypeList.multiSelect,
    renderItem: <SearchSelect fetchType={'customerCodeName'} labelInValue mode="multiple" />,
  },
  {
    label: '自建物料',
    name: 'materialIds',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <SearchSelect
        fetchType={'material'}
        labelInValue
        labelPath="code"
        valuePath="id"
        mode="multiple"
        params={{ enableFlag: 1 }} // 选择启用中的 mode="multiple"
      />
    ),
  },
  {
    label: '计划发货状态',
    name: 'plannedIssueStatus',
    type: fieldTypeList?.multiSelect,
    selectProps: {
      options: appDict.collaborativeDeliverySchedule.plannedIssueStatus,
      mode: 'multiple',
    },
  },
]);

// 详情基本信息
export const detailBaseInfo = [
  { label: '协同交货计划编号', dataIndex: 'code', width: 200 },
  {
    label: '来源',
    dataIndex: 'source',
    width: 100,
    render: lookup('purchase.deliveryScheduleNoteSource'),
  },
  {
    label: '客户编号',
    dataIndex: 'customerCode',
    width: 150,
    render: (text: number) => text ?? replaceSign,
  },
  {
    label: '客户名称',
    dataIndex: 'customerName',
    width: 150,
    render: (text: number) => text ?? replaceSign,
  },
  {
    label: '当前状态',
    dataIndex: 'status',
    width: 150,
    render: (status: number) =>
      lookup('collaborativeDeliverySchedule.orderStatus', status) ?? replaceSign,
  },
  {
    label: '计划发货状态',
    dataIndex: 'plannedIssueStatus',
    width: 150,
    render: (plannedIssueStatus: number) =>
      lookup('collaborativeDeliverySchedule.plannedIssueStatus', plannedIssueStatus) ?? replaceSign,
  },
];
// 详情-协同信息
export const renderDetailPurchaseInfo = ({
  customerId,
  status,
}: CustomerDeliveryScheduleNoteDetailProps) => {
  return _.compact([
    {
      label: '客户备注',
      dataIndex: 'customerRemark',
      render: (remark: string) => (_String.isEmpty(remark) ? replaceSign : remark),
    },
    status === appEnum.CollaborativeDeliverySchedule.OrderStatus.returned && {
      label: '退回原因',
      dataIndex: 'returnReason',
      render: (text: string) => (_String.isEmpty(text) ? replaceSign : text),
    },
    {
      label: '协同附件',
      dataIndex: 'coordinationAttachments',
      render: (files: number[]) => {
        if (_.isEmpty(files)) return replaceSign;

        return (
          <BcAttachmentDetail
            fileIds={files}
            type="collaborative"
            collaborativeParams={{ customerId: customerId! }}
          />
        );
      },
    },
  ]);
};
// 详情-交货计划明细行
export const orderDetailColumn = [
  {
    title: '行号',
    dataIndex: 'lineNo',
    width: 150,
    render: (lineNo: string) => lineNo ?? replaceSign,
  },
  {
    title: '销售订单编号',
    dataIndex: 'saleOrderCode',
    width: 200,
    render: (salesOrderCode: string) => salesOrderCode ?? replaceSign,
  },
  {
    title: '销售订单明细行号',
    dataIndex: 'saleOrderLineNo',
    width: 200,
    render: (salesOrderCode: string) => salesOrderCode ?? replaceSign,
  },
  {
    title: '客户物料编号',
    dataIndex: 'customerMaterialCode',
    width: 200,
    render: (code: string) => code ?? replaceSign,
  },
  {
    title: '客户物料名称',
    dataIndex: 'customerMaterialName',
    width: 200,
    render: (name: string) => name ?? replaceSign,
  },
  {
    title: '客户交货数量',
    dataIndex: ['customerAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '客户交货单位',
    dataIndex: ['customerAmount', 'unitName'],
    width: 150,
    render: (unitName: string) => unitName ?? replaceSign,
  },
  {
    title: '自建物料编号',
    dataIndex: ['material', 'baseInfo', 'code'],
    width: 200,
    render: (code: string) => code ?? replaceSign,
  },
  {
    title: '自建物料名称',
    dataIndex: ['material', 'baseInfo', 'name'],
    width: 200,
    render: (name: string) => name ?? replaceSign,
  },
  {
    title: '自建物料属性',
    dataIndex: ['material', 'attribute'],
    width: 200,
    render: (
      text: { name: string; attributeItem: { content: string; id: number }; id: number }[],
    ) => {
      if (_.isEmpty(text)) return replaceSign;

      return (
        <TagList
          dataSource={_.map(text, ({ name, attributeItem, id }) => ({
            label: `${name}:${attributeItem.content}`,
            value: id,
          }))}
        />
      );
    },
  },
  {
    title: '自建物料规格',
    dataIndex: ['material', 'baseInfo', 'specification'],
    width: 200,
    render: (name: string) => name ?? replaceSign,
  },
  {
    title: '计划发货状态',
    dataIndex: 'plannedIssueStatus',
    width: 150,
    render: (plannedIssueStatus: number) =>
      lookup('collaborativeDeliverySchedule.plannedIssueStatus', plannedIssueStatus) ?? replaceSign,
  },
  {
    title: '交货数量',
    dataIndex: ['amount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '交货单位',
    dataIndex: ['amount', 'unitName'],
    width: 150,
    render: (unitName: string) => unitName ?? replaceSign,
  },
  {
    title: '交货日期',
    dataIndex: 'deliveryTime',
    width: 200,
    sorter: true,
    render: formatDateForRender,
  },
  {
    title: '最早到货时间',
    dataIndex: 'earliestArrivalTime',
    width: 200,
    sorter: true,
    render: (deliveryDate: number) => {
      return formatTime(deliveryDate) ?? replaceSign;
    },
  },
  {
    title: '最晚到货时间',
    dataIndex: 'latestArrivalTime',
    width: 200,
    sorter: true,
    render: (deliveryDate: number) => {
      return formatTime(deliveryDate) ?? replaceSign;
    },
  },
  {
    title: '计划发货数',
    dataIndex: ['plannedIssueAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '仓库实发数',
    dataIndex: ['outboundAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '客户接收数',
    dataIndex: ['customerReceivedAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '物流时间',
    dataIndex: 'shipmentTime',
    width: 200,
    render: (shipmentTime: number) => shipmentTime ?? replaceSign,
  },
  {
    title: '物流时间单位',
    dataIndex: 'shipmentTimeUnit',
    width: 150,
    render: renderTimeUnit,
  },
  {
    title: '最早发货时间',
    dataIndex: 'earliestDeliveryTime',
    width: 200,
    render: (deliveryDate: number) => {
      return formatTime(deliveryDate) ?? replaceSign;
    },
  },
  {
    title: '最晚发货时间',
    dataIndex: 'latestDeliveryTime',
    width: 200,
    render: (deliveryDate: number) => {
      return formatTime(deliveryDate) ?? replaceSign;
    },
  },
];
