import React, { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import { BlIcon } from 'src/components';
import styles from './styles.module.scss';
import { RootState } from 'src/store';
import {
  fetchMessageBulkUpdateMessageStatus,
  fetchMessageList,
} from 'src/api/ytt/message-domain/messageList';
import { replaceSign } from 'src/utils/constants';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import NotificationMessagePopover from './notificationMessagePopover';
import { map } from 'lodash';
import { getLayout, notifyJump, notifyJumpByLayout, notifyNeedModal } from './notifyJumpDict';
import { GetCustomLayoutForNotify } from './notifyModal';

const errorStyle = {
  backgroundColor: '#ffefee',
  border: '1px solid #FFA39E',
  with: 580,
  height: 196,
};

export default function NotificationLayout() {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState<boolean>(false);

  const [objectData, setObjectData] = useState({} as any);

  const { urgentUnread = 0 } = useSelector((state: RootState) => state?.message, shallowEqual);

  const unReadListStore = localStorage.getItem('unReadListStore');
  const unReadListForEach = unReadListStore && JSON.parse(unReadListStore);

  const getUnreadMessageFn = () => {
    dispatch({ type: 'message/getMessageUnReadTotal' });
  };

  useEffect(() => {
    const duration = process.env.REACT_APP_MOCK ? 100000000 : 10000;

    dispatch({ type: 'message/getMessageUnReadTotal' });
    const intervalId = window.setInterval(getUnreadMessageFn, duration);

    localStorage.setItem('intervalId', JSON.stringify(intervalId));

    return () => clearInterval(intervalId);
  }, []);

  // 紧急通知
  const openUrgentNotification = (e: any) => {
    if (e?.promptStatus === 0) return;

    const key = e?.inboxId.toString();

    notification.open({
      duration: null,
      type: 'error',
      message: '紧急通知',
      description: (
        <div className={styles?.message}>
          <div className={styles?.title}>
            <div className={styles?.text}>{e?.title}</div>
          </div>
          <div className={styles?.module}>
            <span className={styles?.moduleSpan}>{e?.moduleName || replaceSign}</span>
            {/* <span className={styles?.moduleSpan}>{e?.bizTypeName || replaceSign}</span> */}
          </div>
          <div className={styles?.content}>{e?.contentAfterPadding}</div>
          <div className={styles?.footer}>
            <Button
              danger
              style={{ marginRight: 16 }}
              onClick={async () => {
                try {
                  const { code } = await fetchMessageBulkUpdateMessageStatus([e?.id]);

                  if (code === 200) {
                    notification.close(key);

                    dispatch({ type: 'message/getMessageUnReadTotal' });

                    if (JSON.stringify(e?.meta) === '{}') {
                      window.open('/notification/notificationMessageUrl'); // 不具有实例详情跳转消息列表
                      return;
                    }

                    if (!notifyNeedModal(e?.meta?.objectCode)) return notifyJump(e?.meta); // 代表具有详情实例

                    // 接了自定义详情布局的需要先选择布局

                    const data = await getLayout(e?.meta);

                    // 如果只有一条数据 直接跳转

                    if (data?.length === 1) {
                      notifyJumpByLayout(e?.meta, data?.[0]?.value);
                    } else {
                      setObjectData(e?.meta);
                      setVisible(true);
                    }
                  }
                } catch (error) {
                  console.log('error: ', error);
                }
              }}
            >
              立即处理
            </Button>
            <Button
              type="text"
              onClick={() => {
                // 稍后查看 即不处理关闭消息通知 挂在紧急通知icon上
                notification.close(key);
              }}
            >
              <span style={{ borderBottom: '1px solid #585453' }}>稍后查看</span>
            </Button>
          </div>
        </div>
      ),
      key,
      top: 90,
      style: errorStyle,
      className: styles?.urgentNotice,
    });
  };

  // 普通消息
  const openNotification = (e: any) => {
    if (e?.promptStatus === 0) return;
    const key = e?.inboxId.toString();

    notification.open({
      top: 90,
      icon: <BlIcon type="iconxiaoxitongzhi" />,
      message: e?.title,
      description: (
        <div
          className={styles?.overflowText}
          style={{ cursor: 'pointer' }}
          onClick={async () => {
            try {
              const { code } = await fetchMessageBulkUpdateMessageStatus([e?.id]);

              // TODO:CYR 暂无详情示例 跳转到列表  立即处理直接转为已读即可

              if (code === 200) {
                notification.close(key);
                dispatch({ type: 'message/getMessageUnReadTotal' });

                if (JSON.stringify(e?.meta) === '{}') {
                  window.open('/notification/notificationMessageUrl'); // 不具有实例详情跳转消息列表
                  return;
                }

                if (!notifyNeedModal(e?.meta?.objectCode)) return notifyJump(e?.meta); // 代表具有详情实例

                // 接了自定义详情布局的需要先选择布局

                const data = await getLayout(e?.meta);

                // 如果只有一条数据 直接跳转

                if (data?.length === 1) {
                  notifyJumpByLayout(e?.meta, data?.[0]?.value);
                } else {
                  setObjectData(e?.meta);
                  setVisible(true);
                }
              }
            } catch (error) {
              console.log('error: ', error);
            }
          }}
        >
          {e?.contentAfterPadding}
        </div>
      ),
      key,
    });
  };

  const getUrgentMessage = async () => {
    // 每次点击获取最新的一条紧急未读通知
    const { data } = await fetchMessageList({
      page: 1,
      size: 1,
      readStatus: 0,
      urgent: 1,
    });

    openUrgentNotification(data?.list?.[0]);
  };

  const urgentDisplay = Boolean(urgentUnread);

  unReadListForEach?.forEach((e: any) => {
    if (e?.urgent) {
      // 紧急
      openUrgentNotification(e);
    } else {
      openNotification(e);
    }
  });

  const NnReadList = map(unReadListForEach, (node: any) => {
    return {
      ...node,
      promptStatus: 0,
    };
  });

  localStorage.setItem('unReadListStore', JSON.stringify(NnReadList)); //  保证消息只会弹一次

  return (
    <div className={styles?.help}>
      <div
        className={styles?.urgentNotice}
        onClick={() => {
          getUrgentMessage();
        }}
        style={{ display: urgentDisplay ? 'inline' : 'none' }}
      >
        <BlIcon type="iconjinjitongzhiqizhi" className={styles?.flag} />
        <div className={styles?.notice}>
          <BlIcon type="iconjinji" />
          <span>{urgentUnread > 99 ? '99+' : urgentUnread}</span>
        </div>
      </div>
      <div className={styles?.message}>
        <NotificationMessagePopover />
      </div>
      <div className={styles?.helpCenter}>帮助中心</div>
      {visible && (
        <GetCustomLayoutForNotify
          objectData={objectData}
          visible={visible}
          handleCancel={() => {
            setVisible(false);
          }}
        />
      )}
    </div>
  );
}
