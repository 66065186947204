export const getStatus = ({ successCount = 0, failedCount = 0, disPatchCount = 0 }) => {
  if (disPatchCount > 0) return '下发中';
  if (failedCount === 0 && successCount > 0) {
    return '成功';
  } else if (failedCount >= 0 && successCount === 0) {
    return '失败';
  }
  return '部分成功';
};

export const getResult = ({ successCount = 0, failedCount = 0, disPatchCount = 0 }) => {
  let result = `成功数：${successCount}，失败数：${failedCount}`;

  if (disPatchCount > 0) result += `，下发中：${disPatchCount}`;

  return result;
};
