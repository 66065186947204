import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { message } from 'antd';
import { BlIcon } from '@blacklake-web/component';
import _ from 'lodash';
import { RecordListLayout } from 'src/layout';
import { fieldTypeList } from 'src/utils';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import {
  SingleOperationLogComponent,
  Tooltip,
  TagTooltip,
  TagList,
  SearchSelect,
} from 'src/components';
import { replaceSign } from 'src/utils/constants';
import { appDict, appEnum, lookup } from 'src/dict';
import { valueOrHolder } from 'src/utils/formatters';
import {
  fetchRepairCaseList,
  fetchRepairCaseStatusUpdate,
  FetchRepairCaseListResponse,
} from 'src/api/ytt/resource-domain/repairPlan';
import MaintenanceStatus from '../components/MaintenanceStatus';
import PlanUserDeptRole from '../components/PlanUserDeptRole';
import ResourceTagCascader from '../components/ResourceTagCascader';
import ResourceClassificationCascader from '../../components/ResourceClassificationCascader';
import { MaintenanceTreeFnParams } from '../../components/maintenanceTree';
import { PlanAssignFromMap, PlanAssignToMap } from '../constants';
import { getStandardWorkTime } from '../utils';
import { goToCreate, goToEdit, goToCopy, goToDetail } from '../navigates';
import { ResourceBusinessType } from 'src/dict/resources';
import { malfunctionLevel, repairChannel } from 'src/dict/resources/mappings';
import { BL_BUSINESS_CODE, BL_OBJECT_CODE, BL_BUSINESS_NAME } from '../config';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { ListItemType } from 'src/types';

interface ListProps extends RouteComponentProps, MaintenanceTreeFnParams {}
export type ResourceListItemType = ListItemType<FetchRepairCaseListResponse>;

const List = (props: ListProps) => {
  const [detailId, setDetailId] = useState<number>(0);
  const [refreshMarker, setRefreshMarker] = useState<number>(0);
  const [resetRefreshMarker, setResetRefreshMarker] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  const { target, businessType, subLocationFlag } = props;

  useEffect(() => {
    setTimeout(() => {
      setResetRefreshMarker(Date.now());
    });
  }, [target, businessType, subLocationFlag]);

  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      auth: authDict.repairCase_export,
      icon: <BlIcon type="icondaochu" />,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.RepairCase,
        });
      },
    },
    {
      title: `新建${BL_BUSINESS_NAME}`,
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.repairCase_add,
      onClick: () => {
        goToCreate();
      },
      items: [
        {
          title: '导入',
          auth: authDict.repairCase_import,
          showExport: true,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.RepairCase,
            });
          },
        },
      ],
    },
  ];
  const batchMenu = [
    {
      title: '启用',
      auth: authDict.repairCase_enable_disable,
      onClick: () =>
        fetchRepairCaseStatusUpdate({
          idList: selectedRowKeys,
          status: appEnum.Common.UsageStatus.enabled,
        }).then((res) => {
          if (res.code === 200) {
            message.success('启用成功！');
            setRefreshMarker(Date.now());
          }
        }),
    },
    {
      title: '停用',
      auth: authDict.repairCase_enable_disable,
      onClick: () =>
        fetchRepairCaseStatusUpdate({
          idList: selectedRowKeys,
          status: appEnum.Common.UsageStatus.disabled,
        }).then((res) => {
          if (res.code === 200) {
            message.success('停用成功！');
            setRefreshMarker(Date.now());
          }
        }),
    },
    // {
    //   title: '删除',
    //   icon: <BlIcon type="iconshanchulajitong1" />,
    //   onClick: (onSuccess: any, onFail: any) => {
    //     BLDelConfirm(
    //       `是否确认删除选中的${BL_BUSINESS_NAME}?`,
    //       delForm,
    //       () => {
    //         handleDelete(selectedRowKeys);
    //         onSuccess();
    //       },
    //       onFail,
    //     );
    //   },
    // },
  ];

  const getChangeStatus = (stauts: number, id: number) => {
    if (stauts === appEnum.Common.UsageStatus.enabled) {
      return fetchRepairCaseStatusUpdate({
        idList: [id],
        status: appEnum.Common.UsageStatus.disabled,
      });
    }
    return fetchRepairCaseStatusUpdate({
      idList: [id],
      status: appEnum.Common.UsageStatus.enabled,
    });
  };
  const getOperationList = ({ id, status }: { id: number; status: number }) => {
    return _.compact([
      {
        title: '查看',
        auth: authDict.repairCase_detail,
        onClick: () => {
          goToDetail(id);
        },
      },
      {
        title: '编辑',
        auth: authDict.repairCase_edit,
        onClick: () => {
          goToEdit(id);
        },
      },
      {
        title: '复制',
        auth: authDict.repairCase_add,
        onClick: () => {
          goToCopy(id);
        },
      },
      {
        title: lookup('common.changeStatusAction', status),
        auth: authDict.repairCase_enable_disable,
        onClick: () => {
          getChangeStatus(status, id).then((res) => {
            if (res.code === 200) {
              message.success(`${lookup('common.changeStatusAction', status)}成功`);
              setRefreshMarker(Date.now());
            }
          });
        },
      },
      // {
      //   title: '删除',
      //   auth:authDict.maintenanceCase_remove,
      //   onClick: () => {
      //     BLDelConfirm(`是否确认删除该${BL_BUSINESS_NAME}？`, delForm, () => {
      //       return handleDelete([id], refreshTable);
      //     });
      //   },
      // },
      {
        title: '操作记录',
        auth: authDict.repairCase_operrecord,
        onClick: () => {
          setDetailId(id);
          setVisibleLog(true);
        },
      },
    ]);
  };
  const dataColumns = [
    {
      title: `${BL_BUSINESS_NAME}编号`,
      dataIndex: 'code',
      sorter: true,
      fixed: 'left',
      isFilter: true,
      type: fieldTypeList.text,
      filterName: 'code',
      width: 150,
      render: (value: string, record: any, index: number, config: any) => {
        return hasAuth(authDict.repairCase_detail) ? (
          <a onClick={() => goToDetail(record.id)}>
            <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
          </a>
        ) : (
          <Tooltip text={value ?? replaceSign} />
        );
      },
    },
    {
      title: `${BL_BUSINESS_NAME}名称`,
      dataIndex: 'name',
      filterName: 'name',
      sorter: true,
      fixed: 'left',
      isFilter: true,
      type: fieldTypeList.text,
      width: 150,
      render: (value: string, record: any, index: number, config: any) => {
        return hasAuth(authDict.repairCase_detail) ? (
          <a onClick={() => goToDetail(record.id)}>
            <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
          </a>
        ) : (
          <Tooltip text={value ?? replaceSign} />
        );
      },
    },
    {
      title: '维修目标',
      dataIndex: ['biz', 'name'],
      isFilter: true,
      filterName: 'repairTargetId',
      width: 150,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
      renderItem: (
        <SearchSelect
          fetchType="resource"
          params={{ type: businessType }}
          placeholder="请输入维修目标"
          disabled={target?.type === 'Equipment'}
        />
      ),
    },
    {
      title: '维修目标编号',
      dataIndex: ['biz', 'code'],
      isFilter: true,
      filterName: 'repairTargetCodeId',
      width: 150,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
      renderItem: (
        <SearchSelect
          fetchType="resource"
          params={{ type: businessType }}
          placeholder="请输入维修目标编号"
          disabled={target?.type === 'Equipment'}
          labelPath="code"
        />
      ),
    },
    {
      title: '故障分类',
      dataIndex: 'malfunctionTraceList',
      filterName: 'malfunctionClassificationId',
      isFilter: true,
      width: 150,
      render: (data: any, record: any, index: number, config: any) => {
        const value = data.map((item: any) => item.name).join('/');

        return <Tooltip text={value ?? replaceSign} width={config.contentWidth} />;
      },
      renderItem: (
        <ResourceClassificationCascader
          onChange={() => {}}
          showSearch
          multiple
          params={{ businessType: ResourceBusinessType.malfunction, cascade: true }}
          placeholder={'请选择故障分类'}
          enableAddLabel={'新建分类'}
          onCreateClick={() => {}}
          enableAdd={false}
        />
      ),
    },
    {
      title: '故障标签',
      dataIndex: 'tagsList',
      isFilter: true,
      width: 150,
      render: (tags: ResourceListItemType['tagsList']) => {
        return (
          tags && (
            <TagList
              dataSource={tags.map((tag: any) => ({
                value: tag.id,
                key: tag.id,
                label: `${tag?.name}:${tag?.labelValue}`,
              }))}
            />
          )
        );
      },
      renderItem: (
        <ResourceTagCascader nameLabel="故障标签" businessType={ResourceBusinessType.malfunction} />
      ),
    },
    {
      title: '故障等级',
      dataIndex: 'malfunctionLevel',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      render: (value: number, record: any, index: number, config: any) => (
        <Tooltip
          text={lookup('resources.malfunctionLevel', value) ?? replaceSign}
          width={config.contentWidth}
        />
      ),
      selectProps: {
        options: malfunctionLevel,
      },
    },
    {
      title: '维修途径',
      dataIndex: 'channelType',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip
          text={lookup('resources.repairChannel', value) ?? replaceSign}
          width={config.contentWidth}
        />
      ),
      selectProps: {
        options: repairChannel,
      },
    },
    {
      title: '维修任务分类',
      dataIndex: 'resourceTypeTraceList', // fullName
      isFilter: true,
      filterName: 'classificationId',
      width: 150,
      render: (data: any, record: any, index: number, config: any) => {
        const value = data.map((item: any) => item.name).join('/');

        return <Tooltip text={value ?? replaceSign} width={config.contentWidth} />;
      },
      renderItem: (
        <ResourceClassificationCascader
          onChange={() => {}}
          showSearch
          multiple
          params={{ businessType: ResourceBusinessType.repair, cascade: true }}
          placeholder={'请选择故障分类'}
          enableAddLabel={'新建分类'}
          onCreateClick={() => {}}
          enableAdd={false}
        />
      ),
    },
    {
      title: '扫码确认',
      dataIndex: 'scanCodeFlag',
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.common.ynb,
      },
      width: 150,
      render: (type: any) => lookup('common.ynb', type),
    },
    {
      title: '标准工时',
      dataIndex: 'standardWorkTime',
      width: 150,
      render: (time?: number) => {
        return valueOrHolder(time, () => {
          const { H, M, S } = getStandardWorkTime(time);

          return `${H}小时${M}分钟${S}秒`;
        });
      },
    },
    {
      title: '计划执行人',
      dataIndex: 'planExecutorList',
      width: 150,
      isFilter: true,
      render: (users: any[]) => <TagTooltip list={users || []} isUser max={2} />,
      renderItem: <PlanUserDeptRole />,
    },
    {
      title: '允许转派',
      dataIndex: 'assignFlag',
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.common.ynb,
      },
      width: 150,
      render: (type: any) => lookup('common.ynb', type),
    },
    {
      title: '谁可转派',
      dataIndex: 'assignFrom',
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: [...PlanAssignFromMap.values()],
        mode: 'multiple',
      },
      width: 150,
      render: (assignFrom: number) =>
        _.map(
          _.filter([...PlanAssignFromMap.keys()], (key) => (assignFrom & key) === key),
          (key) => PlanAssignFromMap.get(key)?.label,
        ).join('，'),
    },
    {
      title: '可转派给',
      dataIndex: 'assignTo',
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: [...PlanAssignToMap.values()],
        mode: 'multiple',
      },
      width: 150,
      render: (assignTo: number) =>
        _.map(
          _.filter([...PlanAssignToMap.keys()], (key) => (assignTo & key) === key),
          (key) => PlanAssignToMap.get(key)?.label,
        ).join('，'),
    },
    {
      title: '执行方式',
      dataIndex: 'executeType',
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.resources.maintenanceExecuteType,
      },
      width: 150,
      render: (type: any) => lookup('resources.maintenanceExecuteType', type),
    },
    {
      title: 'SOP方案',
      dataIndex: 'sopList',
      isFilter: true,
      width: 150,
      render: (list: any) => <TagList dataSource={list ?? []} labelPath="name" />,
      renderItem: (
        <SearchSelect
          fetchType="SOP"
          placeholder="请选择SOP方案"
          mode="multiple"
          params={{
            status: appEnum.Common.UsageStatus.enabled,
            bizType: appEnum.Sop.BizType.repair,
          }}
        />
      ),
    },
    {
      title: '报告模板',
      dataIndex: 'reportTemplateList',
      isFilter: true,
      width: 150,
      render: (list: any) => <TagList dataSource={list ?? []} labelPath="name" />,
      renderItem: (
        <SearchSelect
          fetchType="reportTemplate"
          placeholder="请选择报告模板"
          mode="multiple"
          params={{ category: [appEnum.ReportTemplate.TemplateType.repairReport] }}
        />
      ),
    },
    {
      title: '启用状态',
      dataIndex: 'status',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      selectProps: {
        options: appDict.common.usageStatus,
      },
      render: (status: any) => <MaintenanceStatus status={status} />,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
  ];

  const filterList = dataColumns
    .filter((i) => i.isFilter)
    .map((column: any) => {
      const filter: any = {
        label: column.title,
        name: column.filterName || column.dataIndex,
        type: column.type,
        rules: column.rules,
        renderItem: column.renderItem,
      };

      if (column.type === fieldTypeList.select || column.type === fieldTypeList.multiSelect) {
        filter.selectProps = column.selectProps;
      }
      if (column.type === fieldTypeList.date && column.dateFormat) {
        filter.dateFormat = column.dateFormat;
      }
      return filter;
    });

  const formatDataToQuery = (params: any) => {
    const {
      classificationId,
      tagsList: tagsIdList,
      repairTargetCodeId,
      repairTargetId,
      malfunctionClassificationId,
      planExecutorList,
      assignFrom,
      assignTo,
      executeType,
      reportTemplateList,
      sopList,
      ...rest
    } = params;
    const queryParams = {
      bizType: businessType,
      repairTargetId: target?.type === 'Equipment' ? target?.id : _.get(repairTargetId, 'value'),
      repairTargetCodeId:
        target?.type === 'Equipment' ? target?.id : _.get(repairTargetCodeId, 'value'),
      /** 区域ID（侧边栏） */
      locationId: target?.type === 'Location' ? target?.id : undefined,
      hasSubLocation: subLocationFlag,
      classificationId: _.isEmpty(classificationId) ? null : classificationId.concat().pop(),
      malfunctionClassificationId: _.isEmpty(malfunctionClassificationId)
        ? null
        : malfunctionClassificationId.concat().pop(),
      tagsIdList,
      planExecutorIdList: _.map(planExecutorList?.value, 'value'),
      assignFrom: !_.isEmpty(assignFrom)
        ? _.reduce(assignFrom, (sum, n) => _.sum([sum, n]), 0)
        : undefined,
      assignTo: !_.isEmpty(assignTo)
        ? _.reduce(assignTo, (sum, n) => _.sum([sum, n]), 0)
        : undefined,
      executeType,
      executeIds:
        !_.isEmpty(sopList) || !_.isEmpty(reportTemplateList)
          ? _.map(_.compact(_.concat(sopList, reportTemplateList)), 'value')
          : undefined,
      ...rest,
    };

    dispatch.excelImport.updateBusinessData({
      businessData: { ...queryParams },
    });

    return {
      ...queryParams,
    };
  };
  const formatDataToFormDisplay = (data: any) => {
    const { ...rest } = data;

    return {
      ...rest,
    };
  };

  return (
    <>
      <RecordListLayout
        filterList={filterList}
        requestFn={fetchRepairCaseList}
        useIndex={false}
        mainMenu={mainMenu}
        batchMenu={batchMenu}
        placeholder={'请输入维修方案标题或编号'}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        columns={dataColumns}
        refreshMarker={refreshMarker}
        resetRefreshMarker={resetRefreshMarker}
        selectedRowKeys={selectedRowKeys}
        rowKey="id"
        configcacheKey={BL_BUSINESS_CODE}
        onSelectedRowKeys={(selectKey: React.Key[]) => {
          setSelectedRowKeys(selectKey.map((key) => Number(key)));
        }}
        getOperationList={getOperationList}
        userAuth={getAuthFromLocalStorage()}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={BL_OBJECT_CODE}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default List;
