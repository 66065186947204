/**
 * 关联字段控件属性
 */
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import _ from 'lodash';
import { Form, Input, Select, Checkbox, Tooltip, Cascader } from 'antd';
import type { FormInstance } from 'antd';

import { ControlType, FieldSource, StepEntityType } from 'src/dict/sop';
import {
  objectCodeRange as objectCodeMapping,
  fieldSource as fieldSourceMapping,
} from 'src/dict/sop/mappings';
import type {
  StepDetailData,
  LocalControlItem,
  CustomFieldGetListByObjectCode,
  LabelInValueSelectorProps,
  CascaderTreeNode,
} from '../../types';
import {
  basicControls,
  ControlEditorContext,
  CONTROL_TITLE_LENGTH,
  INPUT_DEFAULT_PLACEHOLDER,
} from '../../constants';
import { getNameFactory, resetUnusedItems, titleRules } from './share';
import { FieldType } from 'src/dict/common';
import { associadateData2Lv, lv2AssociadateData, sf2lb } from '../../share/controlFormat';
import { FieldCategory } from 'src/dict/customField';
import lookup from 'src/dict';
import '../../style.scss';

import { fetchCustomFieldGetListByObjectCode } from 'src/api/ytt/metadata-domain/customField';
import { fetchSopDetail } from 'src/api/ytt/sop-domain';
import { customField2Lv } from '../../share/controlFieldFormat';
import { getParentById } from '../../share/stepUtils';

interface Props {
  form: FormInstance<StepDetailData>;
  localKey: string;
}
interface RouteParams {
  sopId: string;
}

// 描述信息最大值
const MAX_REMARK_LEN = 1000;

const fieldTypeOfassociation = [
  FieldType.text,
  FieldType.textArea,
  FieldType.number,
  FieldType.select,
  FieldType.multiSelect,
  FieldType.boolean,
  FieldType.integer,
  FieldType.date,
];

export const AssociatedFieldInputProperties: FC<Props> = ({ form, localKey }) => {
  const {
    forceUpdate,
    formProps,
    sopBasicInfo: { isReportTemplate, stepId },
  } = useContext(ControlEditorContext);
  const { sopId } = useParams<RouteParams>();

  const controlList = form.getFieldValue('controls');
  const dataIndex = controlList.findIndex((item: LocalControlItem) => item._localKey === localKey);
  const getName = getNameFactory(dataIndex);
  const { fieldSource, referenceField } = controlList[dataIndex];

  const [objectList, setObjectList] = useState<LabelInValueSelectorProps[]>([]);
  const [optionsList, setOptionsList] = useState<LabelInValueSelectorProps[]>([]);
  const [cascaderTooltip, setCascaderTooltip] = useState(false);

  const setFieldValueofCondition = (
    conditions: string[],
    formItemName: string,
    defaultValue: any,
  ) => {
    let isTouched = false;

    for (const value of conditions) {
      if (form.isFieldTouched(getName(value))) {
        isTouched = true;
        break;
      }
    }
    const fieldValue = !isTouched ? form.getFieldValue(getName(formItemName)) : defaultValue;

    form.setFields([
      {
        name: getName(formItemName),
        value: fieldValue,
      },
    ]);
  };

  const otherSheetFieldTip = isReportTemplate
    ? ''
    : { help: '提示：所有字段都记录在SOP任务记录中。' };

  const handleFormatData = (
    list: any[],
    fieldNames: {
      label: string;
      value: string | string[];
      children: string;
      getDataSource?: any;
      parentOfCurStep: any;
    },
    newList: CascaderTreeNode[] = [],
  ): any[] => {
    for (const item of list) {
      const needControls = _.get(item, 'type') === StepEntityType.step;
      const isControl = !_.isNil(_.get(item, 'controlType'));

      // 不能关联到本步骤内的控件：去除1、当前的 2.步骤
      if (needControls && stepId === item?.id) continue;

      let children = [];

      if (needControls) {
        children = _.get(item, 'controls');
      } else {
        children = _.get(item, fieldNames.children);
        if (item?.id === fieldNames?.parentOfCurStep?.id) {
          children = _.get(item, fieldNames.children).filter((item: any) => item.id !== stepId);
        }
      }

      const controlType = isControl ? _.get(item, 'controlType') : undefined;
      const controlTypeName = controlType ? `（${lookup('sop.controlType', controlType)}）` : '';

      // 1.步骤组下有子步骤组、子步骤，2.步骤下有控件
      if (children?.length) {
        const newNode = {
          label: _.get(item, 'name'),
          value: _.get(item, 'id'),
          referenceId: _.get(item, 'referenceId'),
          children: [],
          isLeaf: !needControls,
          controlType,
          disabled: false,
        };

        handleFormatData(children, fieldNames, newNode.children);

        newList.push(newNode);
      } else {
        // 1.空步骤组 空步骤  2.最后一层的控件
        // isContorl
        const newNodeOfControl = {
          label: `${_.get(item, 'name')}${controlTypeName}`,
          value: _.get(item, 'id'),
          referenceId: _.get(item, 'referenceId'),
          isLeaf: true,
          children: undefined,
          controlType,
          disabled: !isControl,
        };

        newList.push(newNodeOfControl);
      }
    }

    return newList;
  };

  const fetchDetails = async () => {
    try {
      const { data } = await fetchSopDetail(
        { sopId: _.toNumber(sopId), withControl: true },
        { legacy: true },
      );
      // const steps = (data?.steps ?? []).filter((item) => item.id !== _.toNumber(stepId));
      const steps = data?.steps ?? [];
      // 精简steps中的数据，并统一成 label,value,children的格式

      const parentOfCurStep = getParentById(steps, _.toNumber(stepId));

      setOptionsList(
        handleFormatData(steps, {
          label: 'name',
          value: 'id',
          children: 'children',
          parentOfCurStep,
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const relateObjectValue = form.getFieldValue(getName('relateObject'));

  useEffect(() => {
    if (isReportTemplate) {
      // controlList:筛选出 1.落库的controlList中  2.属于basicControls 3.本身之外的
      const filterControlList = controlList.filter(
        (item: any) =>
          !_.isNil(item.id) &&
          basicControls.includes(item.controlType) &&
          item._localKey !== localKey,
      );
      const formatControlList = customField2Lv(filterControlList, {
        keyName: 'id',
        labelName: 'name',
      });

      setOptionsList(formatControlList as LabelInValueSelectorProps[]);
      // wendy TODO 不同模块下默认值不同
      form.setFields([
        {
          name: getName('fieldSource'),
          value: FieldSource.otherSheet,
        },
      ]);
    } else {
      fetchDetails();
    }
  }, [localKey]);

  useEffect(() => {
    if (fieldSource === FieldSource.otherSheet && relateObjectValue) {
      fetchCustomFieldGetListByObjectCode({
        objectCode: relateObjectValue,
        fieldCategory: FieldCategory.preFields,
      }).then((res) => {
        const resData: CustomFieldGetListByObjectCode[] = res?.data ?? [];

        const filterData = resData
          ?.filter((item) => {
            return item?.fieldType && fieldTypeOfassociation.includes(item?.fieldType);
          })
          .map(sf2lb);

        setObjectList(filterData as LabelInValueSelectorProps[]);

        const filterMainField = resData.find((item) => item?.isName === 1);

        form.setFields([
          {
            name: getName('mainField'),
            value: {
              fieldCode: filterMainField?.fieldCode,
              fieldName: filterMainField?.fieldName,
            },
          },
        ]);
      });
    } else {
      setObjectList([]);
    }
    setFieldValueofCondition(['relateObject'], 'referenceField', []);
  }, [fieldSource, relateObjectValue, localKey]);

  const fieldSourceList = useMemo(() => {
    return isReportTemplate
      ? fieldSourceMapping.filter((item) => item.value !== FieldSource.currentSheet)
      : fieldSourceMapping;
  }, [isReportTemplate]);

  if (_.isEmpty(controlList)) {
    return null;
  }

  if (dataIndex === -1) {
    return null;
  }

  const displayRender = (label: any) => {
    const len = label?.length;
    const childText = label?.[len - 1];

    // 低版本下，Cascader showSearch=true时，tooltip不显示
    return childText ? (
      <Tooltip visible={cascaderTooltip} title={() => childText}>
        {childText}
      </Tooltip>
    ) : (
      childText
    );
  };

  const handleCascaderMouse = _.debounce(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, visible: boolean) => {
      // e?.relatedTarget,
      setCascaderTooltip(visible);
    },
    300,
  );

  const renderControlsofCurField = () => {
    if (isReportTemplate) {
      return (
        <Form.Item
          label="关联本表控件名称"
          name={getName('localControl')}
          rules={[{ required: true, message: '请选择关联本表控件名称' }]}
        >
          <Select
            options={optionsList}
            allowClear
            showSearch
            filterOption={(input, option) =>
              _.toString(option?.label)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="请选择"
          />
        </Form.Item>
      );
    }

    return (
      <Form.Item
        label="关联本表控件名称"
        name={getName('localControl')}
        rules={[{ required: true, message: '请选择关联本表控件名称' }]}
      >
        <Cascader
          options={optionsList}
          allowClear
          showSearch={{
            filter: (inputValue: string, path: any[]) => {
              return path.some(
                (option) =>
                  (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
              );
            },
          }}
          displayRender={displayRender}
          onMouseEnter={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            handleCascaderMouse(e, true)
          }
          onMouseLeave={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            handleCascaderMouse(e, false);
          }}
          className="control-cascader"
        />
      </Form.Item>
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={(changeValues, allValues) => {
        if (formProps) {
          const { onValuesChange } = formProps;

          onValuesChange && onValuesChange(changeValues, allValues);
        }

        forceUpdate();
        resetUnusedItems(form, ControlType.associatedField, dataIndex, forceUpdate);
      }}
    >
      <Form.Item label="标题" name={getName('name')} rules={titleRules}>
        <Input max={CONTROL_TITLE_LENGTH} placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>

      <Form.Item
        label="字段来源"
        name={getName('fieldSource')}
        rules={[{ required: true, message: '请选择字段来源' }]}
        help={
          fieldSource === FieldSource.otherSheet ? (
            <>
              提示：字段来源于某个业务对象的预置字段；
              <br />
              例如：供应商的名称字段。
            </>
          ) : (
            '提示：字段来源于关联当前方案的SOP任务的执行记录。'
          )
        }
      >
        <Select options={fieldSourceList} placeholder={'请选择'} />
      </Form.Item>

      {/* wendy TODO showSearch */}
      {fieldSource === FieldSource.currentSheet && renderControlsofCurField()}

      {fieldSource === FieldSource.otherSheet && (
        <>
          <Form.Item
            label="他表目标"
            name={getName('relateObject')}
            rules={[{ required: true, message: '请选择他表目标' }]}
          >
            <Select options={objectCodeMapping} placeholder={'请选择'} />
          </Form.Item>
          {/* icontixing-jingshi */}
          <Form.Item
            label="关联他表字段"
            name={getName('referenceField')}
            getValueProps={associadateData2Lv}
            getValueFromEvent={lv2AssociadateData}
            rules={[{ required: true, message: '请选择关联他表字段' }]}
            {...otherSheetFieldTip}
          >
            {/* wendy TODO  */}
            <Select<any>
              value={associadateData2Lv(referenceField)}
              options={objectList}
              labelInValue
              allowClear
              mode="multiple"
              showSearch
              filterOption={(input, option) =>
                _.toString(option?.label)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={'请选择'}
            />
          </Form.Item>
          <Form.Item label="" name={getName('mainField')} style={{ display: 'none' }} />
          <Form.Item name={getName('enableCheckbox')} valuePropName="checked">
            <Checkbox>是否支持多选</Checkbox>
          </Form.Item>
        </>
      )}

      {/* 字段来源：本表时，隐藏这个必填校验 */}
      {fieldSource !== FieldSource.currentSheet && (
        <Form.Item label="校验" style={{ marginBottom: 0 }}>
          <Form.Item name={getName('isRequired')} valuePropName="checked">
            <Checkbox>必填</Checkbox>
          </Form.Item>
        </Form.Item>
      )}

      <Form.Item label="描述信息" name={getName('remark')}>
        <Input.TextArea
          placeholder={INPUT_DEFAULT_PLACEHOLDER}
          maxLength={MAX_REMARK_LEN}
          showCount
        />
      </Form.Item>
    </Form>
  );
};

export default AssociatedFieldInputProperties;
