import React, { useState } from 'react';
import { Button, Form, FormInstance, Popover } from 'antd';
import { BlIcon, SearchSelect } from 'src/components';
import { cloneDeep } from 'lodash';
import { formatSelectToMessageReceivers } from '../utils';
import { ReceiverType } from '../interface';

interface Props {
  form: FormInstance;
  roleForm: FormInstance;
}

export default function AddRole({ form, roleForm }: Props) {
  const [roleVisible, setRoleVisible] = useState<boolean>(false);

  const batchContent = () => {
    return (
      <Form style={{ width: '300px' }} form={roleForm}>
        <Form.Item name="roleSelect">
          <SearchSelect
            renderOption={(data: any) => {
              const newData = cloneDeep(data);

              const _data = { ...newData, type: 3 };
              const _value = JSON.stringify(_data);

              return { label: data?.name, value: _value, key: data?.id };
            }}
            style={{ width: '65%' }}
            mode="multiple"
            fetchType={'role'}
            labelInValue
          />
        </Form.Item>
        <Form.Item noStyle>
          <div style={{ marginTop: '16px', textAlign: 'right' }}>
            <Button
              size="small"
              onClick={() => {
                setRoleVisible(false);
              }}
            >
              取消
            </Button>
            <Button
              size="small"
              style={{ marginLeft: '8px' }}
              onClick={async () => {
                try {
                  const { roleSelect } = await roleForm?.validateFields();

                  const messageReceivers = form.getFieldValue('messageReceivers') ?? [];

                  const newMessageReceivers = formatSelectToMessageReceivers(
                    messageReceivers,
                    roleSelect,
                    ReceiverType.role,
                  );

                  form.setFieldsValue({ messageReceivers: newMessageReceivers });

                  setRoleVisible(false);
                } catch (error) {
                  console.log('error: ', error);
                }
              }}
            >
              确定
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  const onVisibleChange = (visible: boolean) => {
    setRoleVisible(visible);
  };

  return (
    <div>
      <Popover
        content={batchContent()}
        title="添加角色"
        trigger="click"
        visible={roleVisible}
        onVisibleChange={onVisibleChange}
      >
        <Button type="dashed" icon={<BlIcon type="iconxinjiantianjia" />}>
          添加角色
        </Button>
      </Popover>
    </div>
  );
}
