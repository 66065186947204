import { useRef, FC, useContext, useCallback } from 'react';
import { List, Form, Input, Checkbox, Tooltip, Select } from 'antd';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import classnames from 'classnames';
import _ from 'lodash';
import { BlIcon, Tag } from 'src/components';
import { borderColorBase, errorColor } from 'src/components/styles/color';
import lookup from 'src/dict';
import { LayoutComponentType, PageType } from 'src/dict/customLayout';
import { checkTwoSidesTrim } from 'src/utils/formValidators';
import {
  EditorContext,
  getComponentInitialValue,
  getInitContent,
  isSystemField,
  isSonObjectComp,
  NEW_COMPONENT_INDEX,
} from '../../share';
import { DragItem, DragItemType, LayoutParsedComponent } from '../../../../type';
import type { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import styles from '../../styles.module.scss';
import { FieldType } from 'src/dict/common';

type Props = FormListFieldData &
  FormListOperation & {
    overflowTextLength: number;
  };

const iconDeletableStyle = {
  marginLeft: 8,
  color: errorColor,
  cursor: 'pointer',
};
const iconUndeletableStyle = {
  marginLeft: 8,
  color: borderColorBase,
  cursor: 'not-allowed',
};

// 将checkbox的布尔值转为01
const checkboxFormItemProps = {
  getValueProps(val: number) {
    return { value: val, checked: !!val };
  },
  getValueFromEvent(e: CheckboxChangeEvent) {
    return +e.target.checked;
  },
};

const getComponentList = () => document.querySelector<HTMLElement>(`.${styles['component-list']}`)!;

// 添加元素到底部之后，视口自动滚动到底部
const scrollToBottom = () => {
  const container = document.querySelector<HTMLDivElement>(
    `.${styles['layout-editor-page-info']}`,
  )!;

  container.scrollTo(0, container.scrollHeight);
};

const isEditableComp = (type: LayoutComponentType) =>
  type === LayoutComponentType.field || type === LayoutComponentType.sonObjectTable;

/** 可拖拽项，在页面信息展示 */
const DraggableItem: FC<Props> = ({ name, add, move, remove, overflowTextLength }) => {
  const { fieldList, sonObjectList, pageType, form, forceUpdate, disabled } =
    useContext(EditorContext);
  const componentPath = ['components', name];
  const contentPath = [...componentPath, 'content'];
  const {
    id,
    type,
    content: { required: _fieldRequired, fieldPath, objectCode: sonObjectCode },
  } = form?.getFieldValue(componentPath);
  const ref = useRef<HTMLDivElement | null>(null);
  const [{ isOver, isDownward }, drop] = useDrop({
    accept: [DragItemType.component, DragItemType.cover],
    collect: (monitor: DropTargetMonitor<DragItem>) => {
      const { index: dragIndex } = monitor.getItem() || {};
      const isOver = monitor.isOver();

      if (dragIndex === undefined || dragIndex === name) {
        return {};
      }
      let isDownward = dragIndex < name;

      // 如果是将新控件拖入, 为了让它能插到首位, 需要判断一下hover高度
      if (
        isOver &&
        dragIndex === NEW_COMPONENT_INDEX &&
        name === 0 &&
        ref.current &&
        monitor.getClientOffset()
      ) {
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

        isDownward = hoverClientY > hoverMiddleY;
      }
      return {
        isOver,
        isDownward,
      };
    },
    drop: (item: DragItem) => {
      let insertIndex = name;

      if (item.index === NEW_COMPONENT_INDEX) {
        // 新控件index为负, 默认会插到hover项的前面, 故需特殊处理下
        if (isDownward) {
          insertIndex = name + 1;
        }
        add(
          getComponentInitialValue(item.type, pageType, { fieldCode: item.key, fieldList }),
          insertIndex,
        );
        if (insertIndex === form?.getFieldValue('components').length - 1) {
          scrollToBottom();
        }
        forceUpdate();
      } else {
        move(item.index, insertIndex);
      }
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: DragItemType.component,
    item: { type, key: fieldPath ?? id, index: name },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));
  const dropClassName = isDownward ? styles['drop-over-downward'] : styles['drop-over-upward'];
  const opacity = isDragging ? 0 : 1;
  const isField = type === LayoutComponentType.field;
  const isSonObject = isSonObjectComp(type);
  const isSonObjectTable = type === LayoutComponentType.sonObjectTable;
  const fieldData = isField ? _.find(fieldList, { fieldCode: fieldPath }) ?? {} : {};
  const choiceOptions = _.map(fieldData.choiceValues, ({ id, choiceValue }) => ({
    label: choiceValue,
    value: id,
    key: id,
  }));

  const fieldName = fieldData?.fieldName ?? '';
  const sonObjectData =
    isSonObject && sonObjectCode ? _.find(sonObjectList, { objectCode: sonObjectCode }) ?? {} : {};

  // 【标题栏、标签页】都可以删
  // 【字段】详情页: 主属性和创建更新信息不能删；新建/编辑页: 必填字段都不能删
  // 【主从列表】详情页: 都可以删；新建/编辑页: 必填的不能删
  const deletable =
    type === LayoutComponentType.titlebar ||
    type === LayoutComponentType.sonObjectTab ||
    (isField &&
      (pageType === PageType.detail
        ? !fieldData?.isName && !isSystemField(fieldData!)
        : !fieldData?.isRequired)) ||
    (isSonObjectTable && (pageType === PageType.detail || !sonObjectData?.childNecessary));

  const renderTitle = () => {
    if (isField) {
      return (
        <div className={styles['component-list-item-title']}>
          <div className={styles['component-list-item-title-text']}>{fieldName}</div>
          {fieldName.length > overflowTextLength && (
            <Tooltip title={fieldName} getPopupContainer={getComponentList}>
              <div className={styles['component-list-item-title-tooltip-body']} />
            </Tooltip>
          )}
        </div>
      );
    } else if (type === LayoutComponentType.titlebar) {
      return (
        <Form.Item
          className={styles['component-list-item-title-input']}
          name={[name, 'content', 'title']}
          rules={[
            { required: true, message: '标题名称不能为空' },
            { type: 'string', max: 16, message: '不超过16个字符' },
            { validator: checkTwoSidesTrim },
          ]}
        >
          <Input
            style={{ width: '100%', maxWidth: 440 }}
            placeholder="请输入标题名称"
            disabled={disabled}
          />
        </Form.Item>
      );
    }
    return (
      <div className={styles['component-list-item-title']}>
        {lookup('customLayout.layoutComponentType', type)}
      </div>
    );
  };
  // 获取已经选过的从对象，避免重复选择
  const getSonObjectOptions = useCallback(() => {
    const selected = _.compact(
      (form!.getFieldValue('components') as LayoutParsedComponent[])
        .filter((item) => isSonObjectComp(item.type))
        .map((item) => item.content.objectCode),
    );

    return sonObjectList.map((item) => ({
      label: item.objectName!,
      value: item.objectCode!,
      disabled: selected.includes(item.objectCode!),
    }));
  }, [sonObjectList, form]);

  const onSonObjectChange = useCallback(
    (value?: any) => {
      const currentValue = form?.getFieldValue(componentPath);
      const newContent = getInitContent(
        currentValue.type,
        pageType,
        value
          ? {
              sonObjectCode: value,
              sonObjectList,
            }
          : undefined,
      );

      form?.setFields([{ name: contentPath, value: newContent }]);
      forceUpdate();
    },
    [form],
  );

  return (
    <List.Item
      className={classnames(styles['component-list-item-wrapper'], isOver ? dropClassName : '')}
    >
      <div ref={ref} className={styles['component-list-item']} style={{ opacity }}>
        {/* 如果是编辑已有控件，需保留id */}
        {id && <Form.Item hidden name={[name, 'id']} />}
        {!disabled && (
          <>
            {/* 拖拽 */}
            <BlIcon ref={drag} type="iconrenyituozhuai" />
            {/* 删除 */}
            <BlIcon
              type="iconlieshanchu"
              style={deletable ? iconDeletableStyle : iconUndeletableStyle}
              onClick={(e: MouseEvent) => {
                e.stopPropagation();
                if (deletable) {
                  remove(name);
                  forceUpdate();
                }
              }}
            />
          </>
        )}
        {/* 标题 */}
        {renderTitle()}
        {/* 字段类型标签 */}
        {isField && (
          <div className={styles['component-list-item-info-area-long']}>
            <Tag>{lookup('customField.fieldType', fieldData?.fieldType)}</Tag>
          </div>
        )}
        {pageType === PageType.edit && isField && (
          <div className={styles['component-list-item-info-area']}>
            {[FieldType.select, FieldType.multiSelect].includes(fieldData?.fieldType as number) && (
              <Form.Item name={[name, 'content', 'defaultValue']} style={{ width: '100%' }}>
                <Select
                  style={{ width: '100%' }}
                  options={choiceOptions as any}
                  allowClear
                  disabled={disabled}
                  mode={fieldData?.fieldType === FieldType.multiSelect ? 'multiple' : undefined}
                  placeholder={'请选择默认值'}
                />
              </Form.Item>
            )}
          </div>
        )}
        {!isField && (
          <div className={styles['component-list-item-info-area-long']}>
            {isSonObjectComp(type) && (
              <Form.Item
                name={[name, 'content', 'objectCode']}
                rules={[{ required: true, message: '从对象不能为空' }]}
                style={{ width: '100%' }}
              >
                <Select
                  style={{ width: '100%' }}
                  options={getSonObjectOptions()}
                  onSelect={onSonObjectChange}
                  onClear={onSonObjectChange}
                  disabled={disabled || !deletable}
                />
              </Form.Item>
            )}
          </div>
        )}
        <div className={styles['component-list-item-flexible-gap']} />
        <div className={styles['component-list-item-info-area']}>
          {/* 字段属性编辑 */}
          {pageType === PageType.edit && isEditableComp(type) && (
            <>
              <Form.Item name={[name, 'content', 'required']} {...checkboxFormItemProps}>
                <Checkbox
                  disabled={
                    disabled ||
                    (isField && !!_fieldRequired) ||
                    (isSonObjectTable && (!sonObjectCode || !!sonObjectData?.childNecessary))
                  }
                >
                  必填
                </Checkbox>
              </Form.Item>
              <Form.Item dependencies={[[...contentPath, 'required']]}>
                {({ getFieldValue, setFields }) => {
                  const required = getFieldValue([...contentPath, 'required']);

                  if (required) {
                    setFields([{ name: [...contentPath, 'frequent'], value: 1 }]);
                  }
                  return (
                    <Form.Item name={[name, 'content', 'frequent']} {...checkboxFormItemProps}>
                      <Checkbox
                        disabled={disabled || required || (isSonObjectTable && !sonObjectCode)}
                      >
                        常用
                      </Checkbox>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </>
          )}
        </div>
      </div>
    </List.Item>
  );
};

export default DraggableItem;
