import React, { useState } from 'react';
import { Form, message as Message, Checkbox } from 'antd';
import CreateAndEditBaseInfo from './components/createAndEditBaseInfo';
import { RouteComponentProps } from 'react-router-dom';
import { fetchProcessAdd } from 'src/api/ytt/med-domain/process';
import { toProcessList } from './navigation';
import _Array from 'src/utils/dataTypes/array';
import { throttleFn } from '../../warehouse/utils';

interface createProps extends RouteComponentProps {}

export default function CreateProcess({ history }: createProps) {
  const [createForm] = Form.useForm();
  const [continuousCreate, setContinuousCreate] = useState(false); // 连续新建

  const handleFinish = async () => {
    try {
      const value = await createForm?.validateFields();

      const { department, workCenterId, traceRelationship, ...rest } = value;

      const { data, message } = await fetchProcessAdd({
        ...rest,
        departmentId: _Array.isEmpty(department) ? null : department?.[0]?.value,
        workCenterId: workCenterId?.value ?? workCenterId?.key,
        configurationItems: { traceRelationship },
      });

      if (data) {
        Message.success('创建成功');
        if (continuousCreate) {
          createForm.resetFields();
          return;
        }
        history.push(toProcessList());
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  const extra = (
    <Checkbox
      onChange={(e) => {
        setContinuousCreate(e?.target?.checked ?? false);
      }}
    >
      连续新建
    </Checkbox>
  );

  return (
    <CreateAndEditBaseInfo
      edit={false}
      onFinish={throttleFn(handleFinish)}
      onCancel={() => {
        history.push(toProcessList());
      }}
      title="新建工序"
      form={createForm}
      extra={extra}
    />
  );
}
