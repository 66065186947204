import React, { useState, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { message, Space } from 'antd';
import _ from 'lodash';

import { RecordListLayout } from 'src/layout';
import { fieldTypeList, gcTime } from 'src/utils';
import {
  fetchMaintenanceTaskList,
  fetchMaintenanceTaskCancel,
  // fetchMaintenanceTaskDelete,
  fetchMaintenanceTaskBatchUpdatePlanTime,
} from 'src/api/ytt/resource-domain/maintenanceTask';

import TagTooltip from 'src/components/tooltip/tagTooltip';
import {
  SingleOperationLogComponent,
  SearchSelect,
  TagList,
  LinkTooltip,
  BlColumnsType,
  showErrorListMessage,
} from 'src/components';
import authDict from 'src/utils/auth';
import { appDict, appEnum, lookup } from 'src/dict';
import { mapLabeledValueToValue } from 'src/utils/array';
import type { MaintenanceListItemType } from '../index.d';
import { goToCreate, goToEdit, goToDetail, toDetail } from '../navigates';
import MaintenanceStatus from '../components/MaintenanceStatus';
import ResourceClassificationCascader from '../../components/ResourceClassificationCascader';
import { MaintenanceTreeFnParams } from '../../components/maintenanceTree';
import { BL_BUSINESS_CODE, BL_BUSINESS_NAME } from '../config';
import PlanUserDeptRole from '../components/PlanUserDeptRole';
import TimeOutRecord from './timeOutRecord';
import renderQrCode from 'src/page/share/renderQrCode';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { formatDateTimeRangeToUnix } from 'src/utils/formatters/dateTime';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import moment from 'moment';
import AutoTimer from '../components/autoTimer';
import PlanDateModal from './planDateModal';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';

interface ListProps extends RouteComponentProps, MaintenanceTreeFnParams {}

const List = (props: ListProps) => {
  const [detailId, setDetailId] = useState<number>(0);
  const [refreshMarker, setRefreshMarker] = useState<number>(0);
  const [resetRefreshMarker, setResetRefreshMarker] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [visiblePlanDate, setVisiblePlanDate] = useState<boolean>(false);
  const [timeOutRecordId, setTimeOutRecordId] = useState<number>();

  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const { target, businessType, subLocationFlag } = props;

  const refreshTable = () => {
    setRefreshMarker(Math.random() * 100);
  };

  useEffect(() => {
    setTimeout(() => {
      setResetRefreshMarker(Date.now());
    });
  }, [target, subLocationFlag, businessType]);

  // const handleDelete = async (ids: any[], onSuccess?: () => void) => {
  //   // 删除
  //   try {
  //     const res = await fetchMaintenanceTaskDelete({ id: _.head(ids) });

  //     if (res.code !== 200) {
  //       message.error(res?.message);
  //       return;
  //     }
  //     typeof onSuccess === 'function' && onSuccess?.();
  //     message.success('删除成功');
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  /**
   * 调整计划时间
   */
  const updatePlanDate = (ids: React.Key[], values: any) => {
    const { planStartDate, planEndDate } = values;

    // 没填计划开始时间和计划结束时间不处理
    if ((_.isNil(planStartDate) && _.isNil(planEndDate)) || _.isEmpty(ids)) {
      setVisiblePlanDate(false);
      return;
    }

    const planStartDateUnix = planStartDate ? moment(planStartDate).format('x') : undefined;
    const planEndDateUnix = planEndDate ? moment(planEndDate).format('x') : undefined;

    return fetchMaintenanceTaskBatchUpdatePlanTime(
      _.map(ids, (id) => {
        return {
          id: _.toNumber(id),
          planStartTime: _.toNumber(planStartDateUnix),
          planEndTime: _.toNumber(planEndDateUnix),
        };
      }),
    )
      .then((res) => {
        const { failCount = 0, successCount = 0, failList = [] } = res?.data ?? {};

        if (failCount) {
          showErrorListMessage({
            failCount,
            successCount,
            title: '调整出现失败',
            data: failList,
            columns: [
              { title: '任务编号', dataIndex: 'code' },
              { title: '任务名称', dataIndex: 'name' },
              { title: '失败原因', dataIndex: 'errMsg', width: 200 },
              { title: '', dataIndex: 'id', width: 1, render: () => null },
            ],
          });
        } else {
          message.success('调整成功!');
        }

        refreshTable();
      })
      .finally(() => {
        setVisiblePlanDate(false);
      });
  };

  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      auth: authDict.maintenanceTask_export,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: appEnum.ObjectImport.BUSINESS_TYPE.maintenanceTask,
        });
      },
    },
    {
      title: `新建${BL_BUSINESS_NAME}`,
      auth: authDict.maintenanceTask_add,
      onClick: () => {
        goToCreate();
      },
      items: [],
    },
  ];

  const batchMenu = [
    {
      title: '取消',
      popconfirm: true,
      auth: authDict.maintenanceTask_cancel,
      onClick: () =>
        fetchMaintenanceTaskCancel({
          maintenanceTaskIds: selectedRowKeys,
        }).then((res) => {
          if (res.code === 200) {
            message.success('取消成功！');
            refreshTable();
          }
        }),
    },
    {
      title: '调整计划时间',
      auth: authDict.maintenanceTask_edit,
      onClick: () => {
        setVisiblePlanDate(true);

        return Promise.reject();
      },
    },
    // {
    //   title: '删除',
    //   icon: <BlIcon type="iconshanchulajitong1" />,
    //   onClick: (onSuccess: any, onFail: any) => {
    //     BLDelConfirm(
    //       `是否确认删除选中的${BL_BUSINESS_NAME}?`,
    //       delForm,
    //       () => {
    //         handleDelete(selectedRowKeys);
    //         onSuccess();
    //       },
    //       onFail,
    //     );
    //   },
    // },
  ];

  const getOperationList = ({ id, status }: { id: number; status: number }) => {
    const isCanceled = status === appEnum.Resources.MaintenanceTaskStatus.canceled;
    const isEnded = status === appEnum.Resources.MaintenanceTaskStatus.ended;
    const isNotStarted = status === appEnum.Resources.MaintenanceTaskStatus.notStarted;
    const isDeferred = status === appEnum.Resources.MaintenanceTaskStatus.deferred;

    return [
      {
        title: '查看',
        auth: authDict.maintenanceTask_detail,
        onClick: () => {
          goToDetail(id);
        },
      },
      {
        title: '编辑',
        disabled: !isNotStarted && !isDeferred,
        auth: authDict.maintenanceTask_edit,
        onClick: () => {
          goToEdit(id);
        },
      },
      {
        title: '取消',
        disabled: isCanceled || isEnded,
        auth: authDict.maintenanceTask_cancel,
        popconfirm: true,
        onClick: () => {
          fetchMaintenanceTaskCancel({
            maintenanceTaskIds: [id],
          }).then((res) => {
            if (res.code === 200) {
              message.success('取消成功');
              refreshTable();
            }
          });
        },
      },
      {
        title: '暂停记录',
        auth: authDict.maintenanceTask_pause_record,
        onClick: () => {
          setTimeOutRecordId(id);
        },
      },
      // {
      //   title: '删除',
      //   auth:authDict.maintenanceTask_remove,
      //   onClick: () => {
      //     BLDelConfirm(`是否确认删除该${BL_BUSINESS_NAME}？`, delForm, () => {
      //       return handleDelete([id], refreshTable);
      //     });
      //   },
      // },
      {
        title: '操作记录',
        auth: authDict.maintenanceTask_operrecord,
        onClick: () => {
          setDetailId(id);
          setVisibleLog(true);
        },
      },
    ];
  };

  const dataColumns: (BlColumnsType<MaintenanceListItemType>[0] & {
    filterName?: string;
    type?: number;
    isFilter?: boolean;
    selectProps?: any;
    renderItem?: ReactElement;
    dateFormat?: any;
  })[] = _.compact([
    {
      title: `${BL_BUSINESS_NAME}编号`,
      dataIndex: 'code',
      type: fieldTypeList.text,
      isFilter: true,
      sorter: true,
      fixed: 'left',
      width: 150,
      render: (value: string, record: MaintenanceListItemType, index: number, config: any) => {
        return record?.id ? (
          <LinkTooltip
            text={value}
            to={toDetail(record.id)}
            width={config.contentWidth}
            auth={authDict.maintenanceTask_detail}
          />
        ) : (
          value
        );
      },
    },
    {
      title: `${BL_BUSINESS_NAME}名称`,
      dataIndex: 'name',
      sorter: true,
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
      render: (value: string, record: MaintenanceListItemType, index: number, config: any) => {
        return record?.id ? (
          <LinkTooltip
            text={value}
            to={toDetail(record.id)}
            width={config.contentWidth}
            auth={authDict.maintenanceTask_detail}
          />
        ) : (
          value
        );
      },
    },
    {
      title: '标识码',
      dataIndex: 'identificationCode',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
      render: (identificationCode: string) => <Space>{renderQrCode(identificationCode)}</Space>,
    },
    {
      title: '任务状态',
      dataIndex: 'status',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.resources.maintenanceTaskStatus,
      },
      render: (status: string) => lookup('resources.maintenanceTaskStatus', status),
    },
    {
      title: '维保方案',
      dataIndex: ['maintenanceCase', 'name'],
      width: 150,
      filterName: 'maintenanceCaseId',
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="maintenanceCase"
          params={{ type: businessType }}
          placeholder="请输入"
        />
      ),
    },
    {
      title: '维保目标',
      dataIndex: ['bizVO', 'name'],
      width: 150,
      filterName: 'maintenanceTargetId',
      isFilter: true,
      renderItem: (
        <SearchSelect fetchType="resource" params={{ type: businessType }} placeholder="请输入" />
      ),
    },
    {
      title: '维保目标编号',
      dataIndex: ['bizVO', 'code'],
      width: 150,
      filterName: 'maintenanceTargetCodeId',
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="resource"
          params={{ type: businessType }}
          placeholder="请输入"
          labelPath="code"
        />
      ),
    },
    {
      title: '业务类型',
      dataIndex: 'businessType',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      selectProps: {
        options: appDict.resources.maintenanceType,
      },
      render: (type: any) => lookup('resources.maintenanceType', type),
    },
    {
      title: '维保途径',
      dataIndex: 'channelType',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      selectProps: {
        options: appDict.resources.maintenanceChannel,
      },
      render: (type: any) => lookup('resources.maintenanceChannel', type),
    },
    {
      title: '维保任务分类',
      dataIndex: 'classificationList',
      isFilter: true,
      width: 150,
      render: (text: any) => _.map(text, (item) => item?.name).join('/'),
      filterName: 'classificationId',
      renderItem: (
        <ResourceClassificationCascader
          params={{
            businessType: appEnum.Resources.ResourceBusinessType.maintenance,
            status: appEnum.Common.UsageStatus.enabled,
            cascade: true,
          }}
          showSearch
          placeholder={'请选择维保任务分类'}
          onCreateClick={() => {}}
          enableAdd={false}
        />
      ),
    },
    // todo 暂时不上
    // {
    //   title: '资源占用',
    //   dataIndex: 'field5',
    //   isFilter: true,
    //   type: fieldTypeList.select,
    //   selectProps: {
    //     options: appDict.common.yn,
    //   },
    //   width: 150,
    //   render: (type: any) => lookup('common.ynb', type),
    // },
    {
      title: '扫码确认',
      dataIndex: 'scanCodeFlag',
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.common.ynb,
      },
      width: 150,
      render: (type: any) => lookup('common.ynb', type),
    },
    {
      title: '计划开始时间',
      dataIndex: 'planStartTime',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      render: (text: any) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : undefined),
    },
    {
      title: '标准工时',
      dataIndex: 'standardWorkTime',
      width: 200,
      render: (time: number) => <AutoTimer startSec={time} />,
    },
    {
      title: '计划结束时间',
      dataIndex: 'planEndTime',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      render: (text: any) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : undefined),
    },
    {
      title: '计划执行人',
      dataIndex: 'planExecutorList',
      width: 250,
      isFilter: true,
      render: (users: any[]) => <TagTooltip list={users || []} isUser max={2} />,
      renderItem: <PlanUserDeptRole />,
    },
    {
      title: '实际开始时间',
      dataIndex: 'realStartTime',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      render: (text: any) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : undefined),
    },
    {
      title: '实际结束时间',
      dataIndex: 'realEndTime',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      render: (text: any) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : undefined),
    },
    {
      title: '当前执行人',
      dataIndex: 'currentExecutorList',
      width: 250,
      isFilter: true,
      render: (users: any[]) => <TagTooltip list={users || []} isUser max={2} />,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    // {
    //   title: '已执行时长',
    //   dataIndex: 'executedDuration',
    //   width: 200,
    //   render: (time: number | undefined, record: any) => (
    //     <AutoTimer
    //       startSec={time}
    //       auto={_.get(record, 'status') === appEnum.Resources.MaintenanceTaskStatus.executing}
    //     />
    //   ),
    // },
    // {
    //   title: '总执行时长',
    //   dataIndex: 'sumDuration',
    //   width: 200,
    //   render: (time: number | undefined, record: any) => (
    //     <AutoTimer
    //       startSec={time}
    //       auto={_.get(record, 'status') === appEnum.Resources.MaintenanceTaskStatus.executing}
    //     />
    //   ),
    // },
    {
      title: '执行方式',
      dataIndex: 'executeType',
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.resources.maintenanceExecuteType,
      },
      width: 150,
      render: (type: any) => lookup('resources.maintenanceExecuteType', type),
    },
    {
      title: 'SOP方案',
      dataIndex: 'sopList',
      isFilter: true,
      width: 150,
      render: (list: any, { executeType }) => {
        if (
          _.isNil(list) ||
          executeType === appEnum.Resources.MaintenanceExecuteType.reportTemplate
        ) {
          return undefined;
        }
        return <TagList dataSource={list ?? []} labelPath="name" valuePath="id" />;
      },

      renderItem: (
        <SearchSelect
          fetchType="SOP"
          placeholder="请选择SOP方案"
          mode="multiple"
          params={{
            status: appEnum.Common.UsageStatus.enabled,
            bizType: appEnum.Sop.BizType.maintenance,
          }}
        />
      ),
    },
    {
      title: 'SOP任务',
      dataIndex: 'sopTaskName',
      width: 150,
      render: (sopTaskName: any, { sopTaskId, executeType }) => {
        if (
          _.isNil(sopTaskId) ||
          executeType === appEnum.Resources.MaintenanceExecuteType.reportTemplate
        ) {
          return undefined;
        }

        return (
          <a
            onClick={() => {
              window.open(`/sop/exec/task/${sopTaskId}/detail`);
            }}
          >
            {sopTaskName}
          </a>
        );
      },
    },
    {
      title: '维保报告模板',
      dataIndex: 'reportTemplateList',
      isFilter: true,
      width: 150,
      render: (list: any, { executeType }) => {
        if (_.isNil(list) || executeType === appEnum.Resources.MaintenanceExecuteType.SOP) {
          return undefined;
        }

        return <TagList dataSource={list ?? []} labelPath="name" valuePath="id" />;
      },
      renderItem: (
        <SearchSelect
          fetchType="reportTemplate"
          placeholder="请选择报告模板"
          mode="multiple"
          params={{ category: [appEnum.ReportTemplate.TemplateType.maintenanceReport] }}
        />
      ),
    },
    {
      title: '任务来源',
      dataIndex: 'createResourceType',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      selectProps: {
        options: appDict.resources.maintenanceTaskSource,
      },
      render: (status: any) => lookup('resources.maintenanceTaskSource', status),
    },
    {
      title: '触发事件',
      dataIndex: ['triggerEvent', 'name'],
      width: 150,
      isFilter: true,
      filterName: 'triggerEvent',
      renderItem: <SearchSelect fetchType={'triggerEvent'} />,
      render: (status: any) => <MaintenanceStatus status={status} />,
    },
    {
      title: '触发规则',
      dataIndex: ['triggerRule', 'name'],
      width: 150,
      isFilter: true,
      filterName: 'triggerRule',
      renderItem: <SearchSelect fetchType={'triggerRule'} />,
      render: (status: any) => <MaintenanceStatus status={status} />,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
    },
  ]);

  const filterList = dataColumns
    .filter((i) => i.isFilter)
    .map((column: any) => {
      const filter: any = {
        label: column.title,
        name: column.filterName || column.dataIndex,
        type: column.type,
        rules: column.rules,
        renderItem: column.renderItem,
      };

      if (column.type === fieldTypeList.select || column.type === fieldTypeList.multiSelect) {
        filter.selectProps = column.selectProps;
      }
      if (column.type === fieldTypeList.date && column.dateFormat) {
        filter.dateFormat = column.dateFormat;
      }
      return filter;
    });

  const formatDataToQuery = (data: any) => {
    const {
      responsibleDepartment,
      classificationId,
      executeType,
      reportTemplateList,
      sopList,
      maintenanceTargetCodeId,
      planExecutorList,
      currentExecutorList,
      planStartTime,
      planEndTime,
      realStartTime,
      realEndTime,
      maintenanceCaseId,
      maintenanceTargetId,
      triggerEvent,
      triggerRule,
      ...resData
    } = data;

    // 侧边栏筛选数据;
    const maintenanceTreeData = {
      /** 是否包含子区域（侧边栏） */
      hasSubLocation: subLocationFlag,
      /** 维保业务对象 */
      bizType: businessType,
      /** 维保目标（侧边栏&筛选条件） */
      maintenanceTargetIdList: _.compact([
        target?.type === 'Equipment' ? target?.id : undefined,
        _.get(maintenanceTargetId, 'value'),
      ]),
      /** 区域ID（侧边栏） */
      locationId: target?.type === 'Location' ? target?.id : undefined,
    };

    const sendData = {
      ...resData,
      ...maintenanceTreeData,
      ...formatDateTimeRangeToUnix('planStartTimeFrom', 'planStartTimeTo', planStartTime),
      ...formatDateTimeRangeToUnix('planEndTimeFrom', 'planEndTimeTo', planEndTime),
      ...formatDateTimeRangeToUnix('realStartTimeFrom', 'realStartTimeTo', realStartTime),
      ...formatDateTimeRangeToUnix('realEndTimeFrom', 'realEndTimeTo', realEndTime),
      maintenanceTargetCodeId: _.get(maintenanceTargetCodeId, 'value'),
      maintenanceCaseId: _.get(maintenanceCaseId, 'value'),
      departmentIdList: !_.isEmpty(responsibleDepartment)
        ? mapLabeledValueToValue(responsibleDepartment)
        : undefined,
      classificationId: !_.isEmpty(classificationId) ? _.last(classificationId) : undefined,
      executeType,
      executeIds:
        !_.isEmpty(sopList) || !_.isEmpty(reportTemplateList)
          ? _.map(_.compact(_.concat(sopList, reportTemplateList)), 'value')
          : undefined,
      planExecutorType: planExecutorList?.type,
      planExecutorList: _.map(planExecutorList?.value, 'value'),
      currentExecutorList: !_.isEmpty(currentExecutorList)
        ? mapLabeledValueToValue(currentExecutorList)
        : undefined,
      triggerEventId: _.get(triggerEvent, 'value'),
      triggerRuleId: _.get(triggerRule, 'value'),
    };

    dispatch.excelImport.updateBusinessData({ businessData: sendData });
    return sendData;
  };

  const formatDataToDisplay = (data: any) => {
    const { planExecutorList, ...res } = _.cloneDeep(data);

    return { ...res, planExecutorList: _.map(planExecutorList?.value, 'value') };
  };

  const formatDataToFormDisplay = (data: any) => {
    const { planStartTime, planEndTime, realStartTime, realEndTime, ...rest } = data;

    return {
      ...rest,
      planStartTime: gcTime.formatRangeTimeToMoment(planStartTime),
      planEndTime: gcTime.formatRangeTimeToMoment(planEndTime),
      realStartTime: gcTime.formatRangeTimeToMoment(realStartTime),
      realEndTime: gcTime.formatRangeTimeToMoment(realEndTime),
    };
  };

  return (
    <>
      <RecordListLayout<MaintenanceListItemType>
        filterList={filterList}
        requestFn={fetchMaintenanceTaskList}
        useIndex={false}
        mainMenu={mainMenu}
        batchMenu={batchMenu}
        placeholder={`请输入${BL_BUSINESS_NAME}名称或编号`} // 快速搜索input placeholder
        formatDataToQuery={formatDataToQuery} // 转换搜索条件进行查询（快速查询字段："quickSearch",当前页："page",分页大小："size"）
        formatDataToDisplay={formatDataToDisplay} // 转换搜索条件进行展示
        formatDataToFormDisplay={formatDataToFormDisplay}
        columns={dataColumns} // table columns
        refreshMarker={refreshMarker}
        resetRefreshMarker={resetRefreshMarker}
        selectedRowKeys={selectedRowKeys}
        rowKey="id"
        configcacheKey={BL_BUSINESS_CODE}
        onSelectedRowKeys={(selectKey: React.Key[]) => {
          setSelectedRowKeys(selectKey.map((key) => Number(key)));
        }}
        getOperationList={getOperationList}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={OBJECT_OF_CODE.MaintenanceTask}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      {visiblePlanDate && (
        <PlanDateModal
          visible={visiblePlanDate}
          onCancel={() => setVisiblePlanDate(false)}
          onFinish={(values) => {
            updatePlanDate(selectedRowKeys, values);
          }}
        />
      )}
      <TimeOutRecord recordId={timeOutRecordId} onClose={() => setTimeOutRecordId(undefined)} />
    </>
  );
};

export default List;
