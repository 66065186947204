import { message } from 'antd';
import _ from 'lodash';
import { fetchFileListByIds } from 'src/api/ytt/holyfile-domain';
import { getFileDetail } from 'src/services/file';

// 文件size展示formatter
export const bytesFormatter = (bytes: any) => {
  if (typeof bytes !== 'number') return '-';
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (bytes / Math.pow(k, i)).toPrecision(4) + ' ' + sizes[i];
};

export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

/**
 * 根据文件ID下载或预览
 * @fileIds 文件ID List
 */
export const fetchFile = async (fileIds: number[]) => {
  if (_.isEmpty(fileIds)) {
    message.warning('暂未找到附件');
    return;
  }

  const res = await fetchFileListByIds({ idList: fileIds });
  const { data } = res as {
    code: number;
    data: {
      fileUri: string;
    }[];
  };

  return data?.map((file) => file.fileUri) || [];
};
