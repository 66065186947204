import React, { useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import _ from 'lodash';
import { Select, Input, Checkbox } from 'antd';
import { useTreeContent } from 'src/layout';
import { BlIcon } from '@blacklake-web/component';
import { BlTooltip } from 'src/components';
import { RESORCE_BUSINESS_TYPE_MAP } from 'src/dict/resources/mappings';
import {
  fetchSidebarTree,
  fetchSidebarList,
  FetchSidebarTreeResponse,
  FetchSidebarListResponse,
} from 'src/api/ytt/resource-domain/maintenanceArea';
import { ComplementTree, ListItemType } from 'src/types/apiTypes';
import styles from './index.module.scss';
import lookup, { appEnum } from 'src/dict';

type treeType = ComplementTree<ListItemType<FetchSidebarTreeResponse>>;
type listType = ComplementTree<ListItemType<FetchSidebarListResponse>>;
type targetType = {
  id?: React.Key;
  type?: 'Location' | 'Equipment';
  selectedKeys: React.Key[];
};

export interface MaintenanceTreeFnParams {
  /** 业务对象 */
  businessType?: number;
  /** 选择区域或资源(设备或能源仪表) */
  target?: targetType;
  /** 包含子区域标识 */
  subLocationFlag?: boolean;
}
export interface MaintenanceTreeStateParams {
  businessType: number;
  searchValue?: string;
  target: targetType;
  subLocationFlag: boolean;
  withLocation: boolean;
}

interface Props {
  onChange: (value: MaintenanceTreeFnParams) => void;
  cacheKey?: string;
  children?: ReactNode;
  businessTypeLabel?: string;
  hasSubLocationFlag?: boolean;
  onlySelectEquipment?: boolean;
}

export const defaultMaintenanceTree: MaintenanceTreeStateParams = {
  businessType: appEnum.Resources.ResourceBusinessType.equipment,
  searchValue: '',
  target: { id: undefined, selectedKeys: [] },
  subLocationFlag: false,
  withLocation: true,
};
const BL_MAINTENANCE_TREE = 'BlMaintenanceTree';

const MaintenanceTree = (props: Props) => {
  const {
    onChange,
    cacheKey,
    children,
    businessTypeLabel = '维保业务对象',
    hasSubLocationFlag = true,
    onlySelectEquipment = false,
  } = props;

  const [maintenanceTreeStateParams, setMaintenanceTreeStateParams] =
    useState<MaintenanceTreeStateParams>(defaultMaintenanceTree);

  const [refreshMarker, setRefreshMarker] = useState(0);
  const [treeSource, setTreeSource] = useState([]);
  const [iconBusinessType, setIconBusinessType] = useState(0);

  const maxHeight = document.getElementById(BL_MAINTENANCE_TREE)?.clientHeight;

  const formatTreeData = (responseData: treeType[] | undefined, level = 0): any => {
    return (
      responseData?.map((responseNode) => {
        const { id, children, ...rest } = responseNode;

        // 唯一标识增加层级信息，处理同一设备在不同层级造成tree组件错误
        return {
          key: id,
          id,
          title: null,
          children: formatTreeData(children, level + 1),
          ...rest,
        };
      }) ?? []
    );
  };

  const formatListData = (responseData: listType[] | undefined, level = 0): any => {
    return (
      responseData?.map((responseNode) => {
        const { id, children, name, ...rest } = responseNode;

        let displayName = name;

        if (responseNode?.locationName) {
          displayName = `${name}(${responseNode?.locationName})`;
        }
        // 唯一标识增加层级信息，处理同一设备在不同层级造成tree组件错误
        return {
          key: id,
          id,
          children: formatListData(children, level + 1),
          name: displayName,
          title: null,
          type: 'Equipment',
          ...rest,
        };
      }) ?? []
    );
  };

  /**
   * 重置之前的状态,并判断是否需要刷新
   */
  const resetFilter = (params: MaintenanceTreeStateParams) => {
    const nextParams = _.cloneDeep(params);

    nextParams.searchValue = undefined;
    // nextParams.target = defaultMaintenanceTree.target;
    nextParams.subLocationFlag = false;

    return nextParams;
  };

  const handleChange = (params: MaintenanceTreeStateParams) => {
    const { businessType, target, subLocationFlag } = params;

    onChange({
      businessType,
      target: { ...target, id: target.id },
      subLocationFlag,
    });

    /** 缓存上次选择数据 */
    cacheKey && sessionStorage.setItem(cacheKey, JSON.stringify(params));
  };

  /**
   * 维保类型选择 change
   * @param type
   */
  const handleBusinessChange = (type: number) => {
    const nextParams = _.cloneDeep(maintenanceTreeStateParams);

    nextParams.businessType = type;
    nextParams.target = { id: undefined, selectedKeys: [] };

    setMaintenanceTreeStateParams(resetFilter(nextParams));

    setRefreshMarker(Date.now());

    handleChange(nextParams);
  };

  /**
   * 搜索内容 change
   * @param e
   */
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e?.target;

    const nextParams = _.cloneDeep(maintenanceTreeStateParams);

    nextParams.searchValue = value;

    setMaintenanceTreeStateParams(nextParams);
  };

  /**
   * 区域或资源 select
   */
  const handleTargetSelect = (selectedData: any) => {
    const { type = 'Equipment', id } = selectedData as targetType;

    if (onlySelectEquipment && type !== 'Equipment') return;

    const getTreeSelectkeys = () => {
      const indexRoute = _.map(_.split(selectedData.pos, '-'), _.toNumber);

      indexRoute.shift();
      const idRouteArr: number[] = [];
      let currList = treeSource || [];

      _.forEach(indexRoute, (idx) => {
        const currArea = currList[idx] as any;

        idRouteArr.push(currArea?.id);
        currList = currArea?.children;
      });

      return idRouteArr;
    };

    const nextParams = _.cloneDeep(maintenanceTreeStateParams);

    nextParams.target = { id, type, selectedKeys: getTreeSelectkeys() };

    /** 如果是设备的话,并且当前是选中包含子区域时，需要取消包含子区域 */
    if (type === 'Equipment' && maintenanceTreeStateParams.subLocationFlag === true) {
      nextParams.subLocationFlag = false;
    }

    setMaintenanceTreeStateParams(nextParams);

    handleChange(nextParams);
  };

  /**
   * 包含子区域 change
   */
  const handleSubLocationFlagChange = (e: any) => {
    const nextParams = _.cloneDeep(maintenanceTreeStateParams);

    nextParams.subLocationFlag = e.target.checked;

    setMaintenanceTreeStateParams(nextParams);
    handleChange(nextParams);
  };

  /**
   * 查看有无区域状态 change
   */
  const handleWithLocationChange = () => {
    const nextParams = _.cloneDeep(maintenanceTreeStateParams);

    nextParams.withLocation = !maintenanceTreeStateParams.withLocation;
    setMaintenanceTreeStateParams(resetFilter(nextParams));

    setRefreshMarker(Date.now());
  };

  const onSearch = () => {
    setTreeSource([]);
    setRefreshMarker(Date.now());
  };

  const getTopRender = () => {
    const { businessType, searchValue } = maintenanceTreeStateParams;

    return (
      <>
        <div className={styles.areaHeader}>
          <span style={{ flexShrink: 0 }}>{`${businessTypeLabel}：`}</span>
          <Select
            placeholder={`请选择${businessTypeLabel}`}
            value={businessType}
            style={{ width: '100%' }}
            options={RESORCE_BUSINESS_TYPE_MAP}
            onChange={handleBusinessChange}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <Input.Search
            placeholder={`请输入${lookup('resources.RESORCE_BUSINESS_TYPE_MAP', businessType)}名称`}
            value={searchValue}
            onSearch={onSearch}
            onChange={handleSearchChange}
            allowClear
          />
        </div>
      </>
    );
  };

  const getBottomRender = () => {
    const { businessType, target, subLocationFlag, withLocation } = maintenanceTreeStateParams;
    const isEmptyTarget = _.isEmpty(target);
    /** 是否选中的是资源类型 */
    const isEquipment = target?.type === 'Equipment';

    return (
      <div className={styles.areaFooter}>
        {hasSubLocationFlag ? (
          <Checkbox
            checked={subLocationFlag}
            disabled={!withLocation || isEquipment || isEmptyTarget}
            onChange={handleSubLocationFlagChange}
          >
            包含子区域
          </Checkbox>
        ) : null}
        <a
          onClick={handleWithLocationChange}
          className={withLocation ? styles.noLocationSelected : undefined}
        >
          查看{withLocation ? '无区域' : '区域内'}
          {lookup('resources.RESORCE_BUSINESS_TYPE_MAP', businessType)}
        </a>
      </div>
    );
  };

  const titleRender = (data: any) => {
    const { name: nodeTitle, type } = data;
    const iconList: string[] = ['iconshebei-mianxing', 'iconnengyuanyibiao'];
    const iconType: string = type === 'Location' ? 'iconquyu1' : iconList[iconBusinessType];

    return (
      <div style={{ display: 'flex' }} className="treeNode">
        <div>
          <BlIcon type={iconType} />
          <BlTooltip width={100} text={nodeTitle} className="treeNode" />
        </div>
      </div>
    );
  };

  const loadArea = async () => {
    const { businessType, searchValue, withLocation } = maintenanceTreeStateParams;

    let fetchFn: any = fetchSidebarTree;
    let fetchParams: any = { businessType, locationEnabled: true };
    let formatFn = formatTreeData;

    if (!_.isEmpty(searchValue)) {
      fetchFn = fetchSidebarList;
      formatFn = formatListData;
      fetchParams = {
        businessType,
        withLocation,
        quickSearch: searchValue,
      };
    } else if (withLocation === false) {
      fetchFn = fetchSidebarList;
      formatFn = formatListData;
      fetchParams = {
        businessType,
        withLocation,
        withResourceTree: true,
      };
    }

    try {
      const response: any = await fetchFn(fetchParams);

      if (response && response.code === 200) {
        const { data } = response;
        const treeDataSource = formatFn(data.list);

        // 如果是查看无区域资源时，主要加一层最外层数据
        if (withLocation === false && _.isEmpty(searchValue)) {
          // 设置默认展开
          setMaintenanceTreeStateParams((oldState) => {
            return {
              ...oldState,
              target: { id: undefined, selectedKeys: ['000000', '11111111111111'] },
            };
          });

          setTimeout(() => {
            setTreeSource([
              {
                id: '000000',
                key: '000000',
                name: '无区域资源',
                disabled: true,
                children: treeDataSource,
              },
            ] as never[]);
          });
        } else {
          setTreeSource(treeDataSource);
        }
        setIconBusinessType(businessType);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (refreshMarker) {
      loadArea();
    }
  }, [refreshMarker]);

  useEffect(() => {
    const lastParams = cacheKey ? JSON.parse(sessionStorage.getItem(cacheKey) ?? '{}') : undefined;

    if (!_.isEmpty(lastParams)) {
      setMaintenanceTreeStateParams(lastParams);

      handleChange(lastParams);
    } else {
      // 把初始值写进缓存
      cacheKey && sessionStorage.setItem(cacheKey, JSON.stringify(maintenanceTreeStateParams));
    }

    setRefreshMarker(Date.now());
  }, []);

  const treeView = useTreeContent({
    treeData: treeSource,
    showExpandBtn: true,
    updateTreeData: true,
    detailContent: () => <div className={styles.treeDetailContent}>{children}</div>,
    onSelect: handleTargetSelect,
    overflowAble: true,
    onlyOpenArea: true,
    isSearch: false,
    checkable: false,
    withLocation: maintenanceTreeStateParams.withLocation,
    titleRender,
    topRender: getTopRender(),
    bottomRender: getBottomRender(),
    showLine: { icon: { showLeafIcon: false } },
    resizableProp: {
      height: maxHeight,
      maxConstraints: [600, maxHeight],
      minConstraints: [300, maxHeight],
      isChangeHeight: false,
    },
    selectedKeys: maintenanceTreeStateParams.target.selectedKeys,
  });

  return (
    <div style={{ height: '100%' }} id={BL_MAINTENANCE_TREE}>
      {treeView}
    </div>
  );
};

export default MaintenanceTree;
