import { Badge } from 'antd';
import { map } from 'lodash';
import { SearchSelect, TagList, Tooltip } from 'src/components';
import { UsageStatus } from 'src/dict/common';
import { usageStatus } from 'src/dict/common/mappings';
import { FilterItem } from 'src/layout';
import { UserColumnProps } from 'src/page/knowledgeManagement/basicData/workCenter/constants';
import { fieldTypeList } from 'src/utils';
import authDict, { hasAuth } from 'src/utils/auth';
import { replaceSign } from 'src/utils/constants';
import _Array from 'src/utils/dataTypes/array';
import UserOrDepartmentSelectWithDialog from '../components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import {
  allowOptions,
  ALLOW_REJECTION_TYPE,
  noticeOptions,
  NOTICE_TYPE,
  PresetAllowOptions,
  PresetNoticeOptions,
  PresetRemindTypeOptions,
  remindTypeOptions,
  REMIND_TYPE,
} from './constant';
import { ReceiverObjType, triggerColType, TriggerObjType } from './interface';
import { toDetail } from './navigation';

const hasAuthRes = hasAuth(authDict.messagetemplate_detail);

export const triggerListCol = (history: any) => {
  return [
    {
      title: '业务对象',
      dataIndex: 'bizObjectName',
      width: 150,
      render: (bizObjectName: string) => bizObjectName ?? replaceSign,
    },
    // {
    //   title: '业务类型',
    //   dataIndex: 'bizType',
    //   width: 150,
    //   render: (bizType: string) => bizType ?? replaceSign,
    // },
    {
      title: '通知模板编号',
      dataIndex: 'code',
      sorter: true,
      width: 150,
      render: (code: string, { id }: triggerColType, index: number, config: any) => (
        <>
          {hasAuthRes ? (
            <a
              onClick={() => {
                history.push(toDetail(id));
              }}
            >
              <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
            </a>
          ) : (
            <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
          )}
        </>
      ),
    },
    {
      title: '通知模板名称',
      dataIndex: 'name',
      sorter: true,
      width: 150,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '启用状态',
      dataIndex: 'status',
      width: 150,
      render: (status: number) => {
        const useStatusMap = new Map([
          [UsageStatus.enabled, <Badge text="启用中" status="success" />],
          [UsageStatus.disabled, <Badge text="停用中" status="error" />],
        ]);

        return useStatusMap.get(status);
      },
    },
    {
      title: '通知标题',
      dataIndex: 'messageTitle',
      width: 150,
      render: (messageTitle: string) => messageTitle ?? replaceSign,
    },
    {
      title: '通知内容',
      dataIndex: 'messageContent',
      width: 150,
      render: (messageContent: string) => messageContent ?? replaceSign,
    },
    {
      title: '接收人',
      dataIndex: 'messageReceivers',
      width: 150,
      render: (messageReceivers: ReceiverObjType[]) => {
        if (_Array.isEmpty(messageReceivers)) return replaceSign;

        const _messageReceivers = map(messageReceivers, (e: ReceiverObjType) => {
          return {
            value: e?.receiverId,
            label: e?.receiverName,
          };
        });

        return <TagList dataSource={_messageReceivers} maxShow={3} />;
      },
    },
    {
      title: '提醒形式',
      dataIndex: 'remindMethod',
      width: 150,
      render: (remindMethod: number[]) => {
        if (_Array.isEmpty(remindMethod)) return replaceSign;
        return <span>{remindMethod?.map((node: number) => REMIND_TYPE.get(node)).join('、')}</span>;
      },
    },
    {
      title: '通知订阅',
      dataIndex: 'messageSubscription',
      width: 150,
      render: (messageSubscription: number[]) => {
        if (_Array.isEmpty(messageSubscription)) return replaceSign;
        return (
          <span>
            {messageSubscription?.map((node: number) => NOTICE_TYPE.get(node)).join('、')}
          </span>
        );
      },
    },

    {
      title: '拒收设置',
      dataIndex: 'allowRejection',
      width: 150,
      render: (allowRejection: number) => ALLOW_REJECTION_TYPE.get(allowRejection),
    },
    {
      title: '触发规则',
      dataIndex: 'rules',
      width: 150,
      render: (rules: TriggerObjType[]) => {
        if (_Array.isEmpty(rules)) return replaceSign;

        const _rules = map(rules, (e: TriggerObjType) => {
          return {
            value: e?.triggerRuleId,
            label: e?.triggerRuleName ?? replaceSign,
          };
        });

        return <TagList dataSource={_rules} maxShow={3} />;
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
      render: (remark: string) => remark ?? replaceSign,
    },
  ];
};

export const filterList: FilterItem[] = [
  {
    label: '业务对象',
    name: 'objectCodes',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <SearchSelect fetchType={'bizObjectByCode'} labelInValue allowClear mode="multiple" />
    ),
  },
  // {
  //   label: '业务类型',
  //   name: 'type',
  //   type: fieldTypeList.text,
  // },
  {
    label: '通知模板编号',
    name: 'code',
    type: fieldTypeList.text,
  },
  {
    label: '通知模板名称',
    name: 'name',
    type: fieldTypeList.text,
  },
  {
    label: '启用状态',
    name: 'status',
    type: fieldTypeList?.select,
    selectProps: {
      options: usageStatus,
    },
  },
  {
    label: '通知标题',
    name: 'messageTitle',
    type: fieldTypeList.text,
  },
  {
    label: '通知内容',
    name: 'messageContent',
    type: fieldTypeList.text,
  },
  {
    label: '接收用户',
    name: 'userIds',
    type: fieldTypeList.multiSelect,
    renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
  },
  {
    label: '接收部门',
    name: 'departmentIds',
    type: fieldTypeList.multiSelect,
    renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple />,
  },
  {
    label: '接收角色',
    name: 'roleIds',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <SearchSelect style={{ width: '100' }} mode="multiple" fetchType={'role'} labelInValue />
    ),
  },
  {
    label: '提醒形式',
    name: 'remindMethod',
    type: fieldTypeList.multiSelect,
    selectProps: {
      options: remindTypeOptions,
    },
  },
  {
    label: '通知订阅',
    name: 'messageSubscription',
    type: fieldTypeList.multiSelect,
    selectProps: {
      options: noticeOptions,
    },
  },
  {
    label: '拒收设置',
    name: 'allowRejection',
    type: fieldTypeList.select,
    selectProps: {
      options: allowOptions,
    },
  },
  {
    label: '触发规则',
    name: 'triggerRuleIds',
    type: fieldTypeList.multiSelect,
    renderItem: <SearchSelect fetchType={'triggerRule'} labelInValue allowClear mode="multiple" />,
  },
  {
    label: '备注',
    name: 'remark',
    type: fieldTypeList.text,
  },
];

// 非触发规则列表
export const unTriggerListCol = [
  {
    title: '业务对象',
    dataIndex: 'moduleName',
    width: 150,
    render: (moduleName: string) => moduleName ?? replaceSign,
  },
  {
    title: '通知模板名称',
    dataIndex: 'name',
    width: 150,
    render: (name: string) => name ?? replaceSign,
  },
  {
    title: '启用状态',
    dataIndex: 'active',
    width: 150,
    render: (active: number) => {
      const useStatusMap = new Map([
        [UsageStatus.enabled, <Badge text="启用中" status="success" />],
        [UsageStatus.disabled, <Badge text="停用中" status="error" />],
      ]);

      return useStatusMap.get(active);
    },
  },
  {
    title: '通知标题',
    dataIndex: 'title',
    width: 150,
    render: (title: string) => title ?? replaceSign,
  },
  {
    title: '通知内容',
    dataIndex: 'content',
    width: 150,
    render: (content: string) => content ?? replaceSign,
  },
  {
    title: '接收人描述',
    dataIndex: 'receiverDesc',
    width: 150,
    render: (receiverDesc: string) => receiverDesc ?? replaceSign,
  },
  {
    title: '提醒形式',
    dataIndex: 'urgent',
    width: 150,
    render: (urgent: number) => {
      return urgent ? '紧急通知' : replaceSign;
    },
  },
  {
    title: '通知订阅',
    dataIndex: 'sendToEmailStatus',
    width: 150,
    render: (sendToEmailStatus: number) => {
      return sendToEmailStatus ? '邮件' : replaceSign;
    },
  },
  {
    title: '拒收设置',
    dataIndex: 'reject',
    width: 150,
    render: (reject: number) => {
      return reject ? '允许拒收' : '不允许拒收';
    },
  },
];

export const UnTriggerFilterList: FilterItem[] = [
  {
    label: '业务对象',
    name: 'moduleId',
    type: fieldTypeList.select,
    renderItem: <SearchSelect fetchType={'moduleNotification'} labelInValue />,
  },
  {
    label: '通知模板名称',
    name: 'name',
    type: fieldTypeList.text,
  },
  {
    label: '启用状态',
    name: 'active',
    type: fieldTypeList?.select,
    selectProps: {
      options: usageStatus,
    },
  },
  {
    label: '通知标题',
    name: 'title',
    type: fieldTypeList.text,
  },
  {
    label: '通知内容',
    name: 'content',
    type: fieldTypeList.text,
  },
  {
    label: '提醒形式',
    name: 'urgent',
    type: fieldTypeList.select,
    selectProps: {
      options: PresetRemindTypeOptions,
    },
  },
  {
    label: '通知订阅',
    name: 'sendToEmailStatus',
    type: fieldTypeList.select,
    selectProps: {
      options: PresetNoticeOptions,
    },
  },
  {
    label: '拒收设置',
    name: 'reject',
    type: fieldTypeList.select,
    selectProps: {
      options: PresetAllowOptions,
    },
  },
];
