/**
 * 支持批量操作（全选、反选）的下拉框
 */

import { FC, ReactNode } from 'react';
import { Select, SelectProps, Button } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { IdName, name2label, label2name } from 'src/utils/formatters';

export interface BatchSelectProps
  extends Omit<SelectProps<any[]>, 'options' | 'mode' | 'labelInValue' | 'dropdownRender'> {
  options: NonNullable<SelectProps<any[]>['options']> | IdName[];
  disabledOptionValues?: LabeledValue['value'][];
  useIdName?: boolean;
  renderOption?: (label: LabeledValue['label'], value?: LabeledValue['value']) => ReactNode;
}

const { Option } = Select;

export const BatchSelect: FC<BatchSelectProps> = ({
  disabledOptionValues,
  useIdName = false,
  options,
  renderOption,
  value,
  onChange,
  ...rest
}) => {
  const innerValue = useIdName ? value?.map(name2label) : value;
  const innerOnChange = useIdName
    ? (val: any[], opts: Parameters<NonNullable<typeof onChange>>[1]) =>
        onChange!(val ? val.map(label2name) : val, opts)
    : onChange;
  const innerOptions = useIdName
    ? (options as IdName[]).map(name2label)
    : (options as LabeledValue[]);
  const hasSelectedAll = innerValue && innerValue.length === options.length;

  return (
    <Select
      mode="multiple"
      labelInValue
      allowClear
      value={innerValue}
      onChange={innerOnChange}
      dropdownRender={(menu) => (
        <>
          <div>
            <Button
              type="link"
              onClick={() => {
                if (innerOnChange) {
                  hasSelectedAll
                    ? innerOnChange([], innerOptions)
                    : innerOnChange(innerOptions, innerOptions);
                }
              }}
            >
              {hasSelectedAll ? '全不选' : '全选'}
            </Button>
          </div>
          {menu}
          <div style={{ height: 4 }} />
        </>
      )}
      {...rest}
    >
      {innerOptions?.map((opt) => (
        <Option
          key={opt.value}
          value={opt.value}
          label={opt.label}
          disabled={disabledOptionValues?.includes(opt.value)}
        >
          {renderOption ? renderOption(opt.label, opt.value) : opt.label}
        </Option>
      ))}
    </Select>
  );
};
