import envConfig from 'src/configs/envConfig';
import BrowserLogger from 'alife-logger';

class ArmsEntity {
  private bl: typeof BrowserLogger.ConfigContext;

  private coverEnv() {
    switch (envConfig.currentApiEnv) {
      case 'feature':
        return;

      case 'dev':
        return;

      case 'test':
        return 'daily';

      case 'beta':
        return 'pre';

      // TODO 添加生产环境
    }
    return envConfig.currentApiEnv;
  }

  init() {
    const env = this.coverEnv();

    if (!env) {
      console.log('自动上报错误系统已关闭');
      return;
    }

    this.bl = BrowserLogger.singleton({
      pid: 'cknbo7ifwg@7fd7be61f9ae1c3',
      appType: 'web',
      imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
      sendResource: true,
      enableLinkTrace: true,
      behavior: true,
      // TODO 接入用户信息
      uid: JSON.stringify('userInfo'),
      tag: envConfig.commitHash,
      release: envConfig.releaseVersion,
      environment: env,
      useFmp: false,
      enableSPA: true,
      ignore: {
        ignoreUrls: [],
        // TODO 需要把消息通知给关掉
        ignoreApis: [],
        ignoreErrors: [],
        ignoreResErrors: [],
      },
    });
  }

  updateUserInfo(userInfo: any) {
    // TODO 更新用户信息
    if (this.bl) {
      this.bl.setConfig({
        uid: '',
        setUsername() {
          return 'username_xxx';
        },
      });
    }
  }
}

const arms = new ArmsEntity();

export default arms;
