import React, { useState, useRef } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { BlIcon, showErrorListMessage, Tooltip } from 'components';
import { message, Badge, Radio } from 'antd';
import EditComponent from './components/edit';
import { FormatDataToQueryData, RecordListLayout } from 'src/layout';
import FieldDetail from '../fieldDetail';
import {
  fetchCustomFieldGetListByObjectId,
  fetchCustomFieldEnableCustomField,
  fetchCustomFieldDisableCustomField,
} from 'src/api/ytt/metadata-domain/custom_field';
import {
  fieldTypeString,
  fieldCategoryString,
  gcTime,
  gcArray,
  gcString,
  gcUrl,
  gcObject,
} from 'utils';
import CreateComponent from './components/create';
import { FieldListProps, FailPopProps, FilterItem, Params, TableRowType } from './index.d';
import './index.scss';
import _Time from 'src/utils/dataTypes/time';
import { replaceSign } from 'src/utils/constants';
import lookup, { appDict, appEnum } from 'src/dict';
import authDict, { hasAuth } from 'src/utils/auth';
import { FieldType, YN } from 'src/dict/common';
import { AvatarDisplay } from 'src/components/avatar/show';
import { pathPattens } from '../constants';

export interface CreatorData {
  avatarUrl: string;
  id: number;
  name: string;
}

function FieldList({ objectId }: FieldListProps) {
  const [detailVisible, setDetailVisible] = useState(false);
  const [createVisible, setCreateVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [fieldId, setFieldId] = useState<number>(0);
  const [fieldCategory, setFieldCategory] = useState<number>(1);
  const [selectedRowKeys, setSelectRowKeys] = useState<React.Key[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [fieldType, setFieldType] = useState(0);
  const refreshCountRef = useRef(0);
  const { objectCategory, objectName, canSetIsRequired } = gcUrl.getParams();
  const history = useHistory();

  const fetchData = async (params?: Params) => {
    const res = await fetchCustomFieldGetListByObjectId({ objectId, ...params });

    return res;
  };

  const refreshTable = () => {
    refreshCountRef.current++;
    setRefreshMarker(refreshCountRef.current);
  };

  const onChangeStatusFail = (data: FailPopProps) => {
    const { successCount, failCount, errorList, type } = data;

    showErrorListMessage({
      title: `${type}失败`,
      data: errorList,
      columns: [
        {
          title: '字段编号',
          dataIndex: 'fieldCode',
          key: 'fieldCode',
        },
        {
          title: '字段编号',
          dataIndex: 'fieldName',
          key: 'fieldName',
        },
        {
          title: '失败原因',
          dataIndex: 'detail',
          key: 'detail',
        },
      ],
      operateName: type,
      successCount,
      failCount,
      width: 580,
    });
  };

  const changeFieldStatus = async (type: string, ids: number[]) => {
    const data = ids.map((i: number) => {
      return {
        id: i,
      };
    });

    try {
      const res = await (type === 'enable'
        ? fetchCustomFieldEnableCustomField(data)
        : fetchCustomFieldDisableCustomField(data));
      const {
        data: { fail, success },
      } = res;

      if (!gcArray.isEmpty(fail) && fail.length) {
        onChangeStatusFail({
          successCount: success.length,
          failCount: fail.length,
          errorList: fail,
          type: `${type === 'enable' ? '启用' : '停用'} `,
        });
      } else {
        message.success(`${type === 'enable' ? '启用' : '停用'}成功`);
        refreshTable();
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const showDetail = (record: TableRowType) => {
    setFieldId(record.id);
    setFieldType(record.fieldType);
    setFieldCategory(record.fieldCategory);
    setDetailVisible(true);
  };

  const hasAuthRes = hasAuth(authDict.field_detail);
  const dataColumns = [
    {
      title: '字段编号',
      dataIndex: 'fieldCode',
      type: FieldType.text,
      isFilter: true,
      width: 150,
      sorter: true,
      fixed: 'left',
      render: (code: string, record: TableRowType, index: number, config: any) => (
        <>
          {hasAuthRes ? (
            <a onClick={() => showDetail(record)}>
              <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
            </a>
          ) : (
            <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
          )}
        </>
      ),
    },
    {
      title: '字段名称',
      dataIndex: 'fieldName',
      type: FieldType.text,
      isFilter: true,
      sorter: true,
      width: 150,
    },
    {
      title: '是否主属性',
      dataIndex: 'isPrime',
      width: 120,
      render: (isPrime: number) => <Radio checked={isPrime === YN.yes} disabled />,
    },
    {
      title: '状态',
      dataIndex: 'isUsed',
      type: FieldType.multiSelect,
      selectProps: { options: appDict.common.usageStatus },
      isFilter: true,
      sorter: true,
      width: 150,
      render: (status: number) => (
        <span>
          <Badge status={status === appEnum.Common.UsageStatus.enabled ? 'success' : 'default'} />
          {lookup('common.usageStatus', status)}
        </span>
      ),
    },
    {
      title: '字段类别',
      dataIndex: 'fieldCategory',
      type: FieldType.select,
      isFilter: true,
      sorter: true,
      width: 150,
      selectProps: {
        options: Object.keys(fieldCategoryString).map((category: string) => {
          return {
            label: fieldCategoryString[Number(category)],
            value: category,
          };
        }),
      },
      render: (type: number) => fieldCategoryString[type],
    },
    {
      title: '字段类型',
      dataIndex: 'fieldType',
      type: FieldType.select,
      isFilter: true,
      sorter: true,
      width: 150,
      render: (fieldType: number, record: TableRowType) => {
        return record.isRefer ? '引用字段' : lookup('customField.fieldType', fieldType);
      },
      selectProps: {
        options: appDict.customField.fieldType,
      },
    },
    {
      title: '字段提示',
      dataIndex: 'fieldRemind',
      isFilter: false,
      width: 150,
      render: (fieldRemind: string) => (gcString.isEmpty(fieldRemind) ? '-' : fieldRemind),
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      isFilter: false,
      width: 150,
      render: (creator: CreatorData) => {
        if (!creator?.id) {
          return '-';
        }
        return (
          <AvatarDisplay
            id={creator?.id}
            name={creator?.name}
            avatarUrl={creator?.avatarUrl}
            key={creator?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      type: FieldType.date,
      isFilter: true,
      sorter: true,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      width: 180,
      render: (createdAt: Date) => {
        if (!createdAt) return replaceSign;
        return _Time.format(createdAt);
      },
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      isFilter: false,
      width: 150,
      render: (operator: CreatorData) => {
        if (!operator?.id) {
          return '-';
        }
        return (
          <AvatarDisplay
            id={operator?.id}
            name={operator?.name}
            avatarUrl={operator?.avatarUrl}
            key={operator?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      type: FieldType.date,
      isFilter: true,
      sorter: true,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      width: 180,
      render: (updatedAt: Date) => {
        if (!updatedAt) return replaceSign;
        return _Time.format(updatedAt);
      },
    },
  ];

  const leftButtons = [
    {
      title: '字段级联关系',
      onClick: () => {
        history.push(
          `${generatePath(pathPattens.fieldCascadingList, { objectId })}${location.search}`,
        );
      },
    },
    {
      title: '新建字段',
      icon: 'iconxinjiantianjia',
      auth: authDict.field_add,
      onClick: () => setCreateVisible(true),
      items: [],
    },
  ];

  const config = {
    left: leftButtons,
    toolbar: [
      {
        title: '启用',
        auth: authDict.field_enable_disable,
        onClick: () =>
          changeFieldStatus(
            'enable',
            selectedRowKeys.map((key) => Number(key)),
          ),
      },
      {
        title: '停用',
        auth: authDict.field_enable_disable,
        onClick: () =>
          changeFieldStatus(
            'disable',
            selectedRowKeys.map((key) => Number(key)),
          ),
      },
    ],
    columns: dataColumns.map((column) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isFilter, type, dateFormat, ...rest } = column;

      return { ...rest };
    }),
    rowKey: (record: TableRowType) => record.id,
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((column: any) => {
        const filter: FilterItem = {
          label: column.title,
          name: column.dataIndex,
          type: column.type,
          rules: column.rules,
        };

        if (column.type === FieldType.select || column.type === FieldType.multiSelect) {
          filter.selectProps = column.selectProps;
        }
        if (column.type === FieldType.date && column.dateFormat) {
          filter.dateFormat = column.dateFormat;
        }
        return filter;
      }),
    getOperationList: (record: TableRowType) => {
      return [
        {
          key: 'detail',
          title: '查看',
          onClick: () => showDetail(record),
          auth: authDict.field_detail,
        },
        {
          key: 'edit',
          title: '编辑',
          auth: authDict.field_edit,
          onClick: () => {
            setFieldId(record.id);
            setFieldCategory(record.fieldCategory);
            setFieldType(record.fieldType);
            setEditVisible(true);
          },
        },
        {
          key: 'status',
          title: lookup('common.changeStatusAction', record.isUsed),
          auth: authDict.field_enable_disable,
          onClick: () =>
            changeFieldStatus(
              record.isUsed === appEnum.Common.UsageStatus.enabled ? 'disable' : 'enable',
              [record.id],
            ),
        },
      ];
    },
  };

  const onCloseDetail = () => {
    setDetailVisible(false);
  };

  const formatDataToQuery = (params: FormatDataToQueryData) => {
    const queryParams = { ...params };

    if (gcArray.isEmpty(params.isUsed)) {
      delete queryParams.isUsed;
    }
    if (params.createdAt) {
      const [createStartTime, createEndTime] = gcTime.formatMomentRangeToUnix(params.createdAt);

      queryParams.createStartTime = createStartTime / 1000;
      queryParams.createEndTime = createEndTime / 1000;
      delete queryParams?.createdAt;
    }
    if (params.updatedAt) {
      const [updateStartTime, updateEndTime] = gcTime.formatMomentRangeToUnix(params.updatedAt);

      queryParams.updateStartTime = updateStartTime / 1000;
      queryParams.updateEndTime = updateEndTime / 1000;
      delete queryParams.updatedAt;
    }
    if (params?.quickSearch) {
      queryParams.search = params.quickSearch;
      delete queryParams.quickSearch;
    }

    return gcObject.filterEmptyProperty(queryParams);
  };

  const formatDataToDisplay = (data: any) => {
    const retData = { ...data };

    if (retData?.fieldCategory) {
      retData.fieldCategory = fieldCategoryString[Number(retData?.fieldCategory)];
    } else {
      retData.fieldCategory = undefined;
    }
    if (!gcArray.isEmpty(retData?.createdAt)) {
      retData.createdAt = `${gcTime.formatLine(retData.createdAt[0])} ~ ${gcTime.formatLine(
        retData.createdAt[1],
      )}`;
    }
    if (!gcArray.isEmpty(retData?.updatedAt)) {
      retData.updatedAt = `${gcTime.formatLine(retData.updatedAt[0])} ~ ${gcTime.formatLine(
        retData.updatedAt[1],
      )}`;
    }
    if (retData?.fieldType) {
      retData.fieldType = fieldTypeString[retData.fieldType];
    }

    return retData;
  };

  return (
    <div className="fieldList">
      <RecordListLayout<TableRowType>
        columns={config.columns}
        filterList={config.filterList}
        rowKey={config.rowKey}
        batchMenu={config.toolbar}
        mainMenu={config.left}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={(data = {}) => formatDataToDisplay(data)}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey: React.SetStateAction<React.Key[]>) => {
          setSelectRowKeys(selectKey);
        }}
        requestFn={(parmas: Params) =>
          fetchData({ isUsed: [appEnum.Common.YN.yes, appEnum.Common.YN.no], ...parmas })
        }
        refreshMarker={refreshMarker}
        placeholder={'请输入字段编号或字段名称'}
        getOperationList={config.getOperationList}
      />
      <CreateComponent
        objectId={objectId}
        objectCategory={objectCategory}
        objectName={objectName}
        visible={createVisible}
        onCancel={() => setCreateVisible(false)}
        refreshTable={refreshTable}
        canSetIsRequired={canSetIsRequired}
      />
      {editVisible && (
        <EditComponent
          objectId={objectId}
          fieldCategory={fieldCategory}
          objectCategory={objectCategory}
          visible={editVisible}
          fieldId={fieldId}
          fieldType={fieldType}
          onCancel={() => setEditVisible(false)}
          refreshTable={refreshTable}
          canSetIsRequired={canSetIsRequired}
        />
      )}
      {detailVisible && (
        <FieldDetail
          visible={detailVisible}
          onCloseDetail={onCloseDetail}
          fieldId={fieldId}
          fieldCategory={fieldCategory}
          refreshTable={() => refreshTable()}
          showEdit={() => {
            setEditVisible(true);
            onCloseDetail();
          }}
        />
      )}
    </div>
  );
}

export default FieldList;
