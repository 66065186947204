import React from 'react';

import { TabList } from 'src/components';
import { lookup } from 'src/dict';
import { ListViewType } from 'src/dict/salesReturnOrder';
import OrderList from './OrderList';
import DetailList from './DetailList';

const orderViewKey = `salesReturnOrderList-${ListViewType.order}`;
const detailViewKey = `salesReturnOrderList-${ListViewType.detail}`;

export default function SalesReturnOrderList() {
  const tabList = [
    {
      tab: lookup('salesReturnOrder.listViewType', ListViewType.order),
      key: orderViewKey,
      component: <OrderList />,
    },
    {
      tab: lookup('salesReturnOrder.listViewType', ListViewType.detail),
      key: detailViewKey,
      component: <DetailList />,
    },
  ];

  return <TabList tabList={tabList} defaultKey={orderViewKey} />;
}
