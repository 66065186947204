/**
 * 投料管控
 */
import React, { useRef, useState } from 'react';
import _ from 'lodash';
import {
  Button,
  Descriptions,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
  Space,
  Tooltip,
} from 'antd';
import { DataFormLayout } from 'src/layout';
import { BatchOperateTableHeader, BlSortFormList, SearchSelect } from 'src/components';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { appDict, appEnum } from 'src/dict';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import { NamePath } from 'antd/lib/form/interface';
import { getMaterialUnitInfo, getMaterialUnits, judgeMaterialIsVirtual } from 'src/entity/material';
//
import {
  BomFormFeedingControl,
  BomFormInputMaterial,
  BomRequestFeedingControl,
  BomResponseFeedingControl,
} from '../index.type';
import { WorkOrderRequestFeedingControl } from 'src/page/productionPlanning/productionOrder/createAndEdit/index.type';
import { fetchProcessRouteProcessNode } from 'src/api/ytt/med-domain/process-route';
import { BusinessStatusEnum } from 'src/dict/productionPlanning';
import { InputTypeEnumDisplayMap } from 'src/dict/planned/mappings';
import { InputTypeEnum } from 'src/dict/planned';

interface FeedingControlFormProps {
  type: string;
  /** 外部的大form */
  bomForm: FormInstance;
  name: keyof BomFormInputMaterial;
  params: BomFormInputMaterial; // 子项物料行已填写数据
  onChange: (value?: BomFormFeedingControl[]) => void;
  /**
   * 是否用于物料清单 0:用于物料清单  1:用于工单  2:计划订单
   */
  useType?: number;
  /**
   * 依赖的外部form字段
   */
  dependenciesFieldPath: {
    bomMaterial: NamePath; // 父项物料
    processRoute: NamePath; // 工艺路线
    sop: NamePath; // sopId
  };
}

interface FeedingControlForm {
  [index: string]: BomFormFeedingControl[];
}

// 启用sop 许可项 暂时默认开启，等后续对接工厂许可项
const ORIGIN_SOP_STATUS = appEnum.Common.UsageStatus.enabled;

// 格式化 投料管控 给接口
export const formatBomFeedingControlToApi = (
  feedControls?: BomFormFeedingControl[],
  useType?: number,
): BomRequestFeedingControl[] | WorkOrderRequestFeedingControl[] => {
  if (_.isEmpty(feedControls)) return [];

  return _.map(feedControls, (item) => {
    const {
      inputAmountDenominator,
      inputAmountNumerator,
      inputLowerLimit,
      inputLowerLimitRatio,
      inputUpperLimit,
      inputUpperLimitRatio,
      inputQcState = [
        appEnum.Material.FeedingQualityStatus.qualified,
        appEnum.Material.FeedingQualityStatus.concession,
      ],
      inputSopControlId,
      ...resItem
    } = item;

    const baseParams: BomRequestFeedingControl = {
      ...resItem,
      inputQcState,
      inputAmountDenominator: _.toString(inputAmountDenominator),
      inputAmountNumerator: _.toString(inputAmountNumerator),
      inputLowerLimit: _.isNil(inputLowerLimit) ? undefined : _.toString(inputLowerLimit),
      inputLowerLimitRatio: _.isNil(inputLowerLimitRatio)
        ? undefined
        : _.toString(inputLowerLimitRatio),
      inputUpperLimit: _.isNil(inputUpperLimit) ? undefined : _.toString(inputUpperLimit),
      inputUpperLimitRatio: _.isNil(inputUpperLimitRatio)
        ? undefined
        : _.toString(inputUpperLimitRatio),
    };

    // 用于物料清单
    if (!useType) {
      const retParams: BomRequestFeedingControl = {
        ...baseParams,
        inputSopControlCode: inputSopControlId?.value
          ? _.toString(inputSopControlId?.value)
          : undefined,
      };

      return retParams;
    }

    const retParams: WorkOrderRequestFeedingControl = {
      ...baseParams,
      inputSopControlId: inputSopControlId?.value
        ? _.toNumber(inputSopControlId?.value)
        : undefined,
    };

    return retParams;
  });
};

/**
 * 格式化 投料管控 给form
 * @param feedControls
 * @returns
 */
export const formatBomFeedingControlToForm = (
  feedControls?: BomResponseFeedingControl[],
  fn?: (item: BomResponseFeedingControl) => { [index: string]: any }, // 给工单预留初始化
): BomFormFeedingControl[] => {
  if (_.isEmpty(feedControls)) return [];

  return _.map(feedControls, (item, index) => {
    const {
      lineSeq = index * 10,
      inputAmountNumerator = 1,
      inputAmountDenominator = 1,
      inputBoundType = appEnum.Bom.InputBoundType.notControl,
      inputMaterialControl = appEnum.Common.YN.yes,
      feedType = appEnum.Material.FeedingType.feeding,
      inputQcState = [],
      inputSopControlDTO,
      ...resItem
    } = item;

    return {
      ...resItem,
      lineSeq,
      inputQcState,
      inputBoundType,
      inputMaterialControl,
      feedType,
      inputAmountNumerator: _.toNumber(inputAmountNumerator),
      inputAmountDenominator: _.toNumber(inputAmountDenominator),
      inputSopControlId: inputSopControlDTO?.id
        ? {
            label: inputSopControlDTO?.code,
            value: inputSopControlDTO?.code,
          }
        : undefined,
      ...(fn?.(item) ?? {}),
    };
  });
};

// 默认初始值
export const DEFAULT_FEEDINGCONTROL_FROMVALUE = {
  id: undefined,
  inputAmountDenominator: 1,
  inputAmountNumerator: 1,
  inputBoundType: appEnum.Bom.InputBoundType.notControl,
  inputLowerLimit: undefined,
  inputLowerLimitRatio: undefined,
  inputMaterialControl: appEnum.Bom.FeedingControl.no,
  feedType: appEnum.Material.FeedingType.feeding,
  inputQcState: [
    appEnum.Material.FeedingQualityStatus.qualified,
    appEnum.Material.FeedingQualityStatus.concession,
  ],
  inputSopControlId: undefined,
  inputUpperLimit: undefined,
  inputUpperLimitRatio: undefined,
  lineSeq: 10,
  inputType: InputTypeEnum.INPUT,
};

/**
 * 获取投料管控 是否必填的 默认值
 */
export const getDefaultInputMaterialControl = (specificProcessInput?: number) => {
  const specificProcessInputIsNo = specificProcessInput === appEnum.Common.YN.no; // 指定工序投料 = 否

  return specificProcessInputIsNo ? appEnum.Bom.FeedingControl.no : appEnum.Bom.FeedingControl.yes;
};

const FeedingControlForm = (props: FeedingControlFormProps) => {
  const { name, params, onChange, bomForm, dependenciesFieldPath, useType, type } = props;

  const [loading, setLoading] = useState(false);
  const isAllowCopyRow = useRef(false); // 是否允许复制行，第一次校验成功后不需要再次校验

  const [form] = Form.useForm<FeedingControlForm>();

  /**
   * 校验行号,不能重复
   */
  const validatorIndex = (rule: any, value: any) => {
    const listValue = form.getFieldValue(name);

    const firstIndex = _.findIndex(listValue, { lineSeq: value });
    const lastIndex = _.findLastIndex(listValue, { lineSeq: value });

    if (firstIndex != lastIndex) {
      return Promise.reject('不能有重复的行号');
    }

    return Promise.resolve('');
  };

  /**
   * 校验用量分子
   */
  const validatorInputAmountNumerator = () => {
    const listValue = form.getFieldValue(name);

    if (!_.isEmpty(listValue) && listValue?.length > 1) {
      let total = 0;

      _.forEach(listValue, ({ inputAmountNumerator = 0 }) => {
        total += inputAmountNumerator;
      });

      if (total !== params?.inputAmountNumerator ?? 0) {
        return Promise.reject('子行用量之和必须等于所属子项行用量');
      }
    }

    return Promise.resolve('');
  };

  /**
   * 获取行的初始值
   * @param defaultValue
   * @param field
   * @returns
   */
  const getDefaultValue = (field: FormListFieldData, defaultValue: unknown) => {
    if (_.get(params, [name, field.name])) return undefined;

    return defaultValue;
  };

  /**
   * 复制某一行
   * @param field 被复制的field
   */
  const copyOneLine = (field: FormListFieldData) => {
    const copyValue = _.cloneDeep(form.getFieldValue([name, field.name]));
    const formValue = form.getFieldValue([name]);

    copyValue.lineSeq = (formValue.length + 1) * 10;
    copyValue.id = undefined;

    const newFormValue = _.concat(formValue, [copyValue]);

    form.setFieldsValue({
      [name]: newFormValue,
    });
  };

  /**
   * 判断投料上下限或投料上下限比例 是否禁止输入状态
   * @param field
   * @param inputBoundType 当前输入框属于的投料上下限类型
   * @param isUpper 当前输入框是否是上限
   * @returns
   */
  const judgeLimitDisabled = (
    field: FormListFieldData,
    inputBoundType: number,
    isUpper?: boolean,
  ) => {
    const baseName = [name, field.name];

    const dependencies = [
      [...baseName, 'inputBoundType'],
      [...baseName, 'inputMaterialControl'],
    ];

    const { inputMaterialControl, inputBoundType: curInputBoundType } = _.get(
      form.getFieldsValue(dependencies),
      [name, field.name],
      {},
    );
    const { specificProcessInput } = params;

    // 指定工序投料 = 否
    const specificProcessInputIsNo = specificProcessInput === appEnum.Common.YN.no;

    // 是否必投 = 非必投
    const inputMaterialControlIsNo =
      !isUpper && inputMaterialControl === appEnum.Bom.FeedingControl.no;

    // 投料上下限类型 != 当前输入框属于的投料上下限类型
    const inputBoundTypeIsNot = curInputBoundType !== inputBoundType;

    const disabled = specificProcessInputIsNo || inputMaterialControlIsNo || inputBoundTypeIsNot;

    return disabled;
  };

  /**
   * 处理复制逻辑
   * @param field
   */
  const handleCopyClick = async (field: FormListFieldData) => {
    const { specificProcessInput, inputProcessId } = params;
    const processRouteId = bomForm.getFieldValue(dependenciesFieldPath.processRoute);
    const materialInfo = JSON.parse(params?.materialId?.value ?? '{}');

    console.log('%c [ inputProcessId ]-312', 'font-size:px; color:#bf2c9f;', inputProcessId);
    const isVirtualMaterial = judgeMaterialIsVirtual(materialInfo); // 是否虚拟件

    // 如果已经被允许复制了，直接继续复制
    if (isAllowCopyRow.current) return copyOneLine(field);

    // sop 许可项 = 启用
    if (ORIGIN_SOP_STATUS === appEnum.Common.UsageStatus.enabled) {
      // 指定投料工序 = 否
      if (specificProcessInput === appEnum.Common.YN.no) {
        return message.warning('不指定投料工序的物料不支持拆分控件投料!');
      }

      // 指定投料工序号 = undefined
      if (!inputProcessId) {
        return message.warning('没有选择指定投料工序!');
      }

      try {
        setLoading(true);
        let sopId;

        // 物料清单为工艺路线查询 工单是当前物料行绑定的工序
        if (!useType) {
          const ProcessRouteRes = await fetchProcessRouteProcessNode({
            processRouteId: processRouteId?.value,
            processNodeNum: inputProcessId?.label as string,
          });

          sopId = ProcessRouteRes.data?.sop?.id;
        } else {
          sopId = _.split(_.toString(params?.inputProcessId?.value), '-')?.[1];
        }

        if (!sopId) {
          // 所指定工序 SOP 方案为 null
          return message.warning('所指定的投料工序没有配置SOP方案,不支持拆分控件投料!');
        }
      } catch (error) {
        return;
      } finally {
        setLoading(false);
      }
    }

    if (isVirtualMaterial) {
      return message.warning('虚拟件物料不支持拆分控件投料!');
    }

    isAllowCopyRow.current = true;
    return copyOneLine(field);
  };

  /**
   * 批量设置表单值
   */
  const handleBatchSetValue = (field: string, value: any) => {
    const formList = _.cloneDeep(form.getFieldValue(name));

    _.forEach(formList, (item) => {
      item[field] = value;
    });

    form.setFieldsValue({
      [name]: formList,
    });
  };

  /**
   * 重置投料限制，
   */
  const handleResetInputLimit = (field: FormListFieldData) => {
    const formList = _.cloneDeep(form.getFieldValue(name));

    const inputLimitFields = [
      {
        fieldStr: 'inputUpperLimit',
        inputBoundType: appEnum.Bom.InputBoundType.constant,
        isUpper: true,
      },
      {
        fieldStr: 'inputLowerLimit',
        inputBoundType: appEnum.Bom.InputBoundType.constant,
        isUpper: false,
      },
      {
        fieldStr: 'inputUpperLimitRatio',
        inputBoundType: appEnum.Bom.InputBoundType.percentage,
        isUpper: true,
      },
      {
        fieldStr: 'inputLowerLimitRatio',
        inputBoundType: appEnum.Bom.InputBoundType.percentage,
        isUpper: false,
      },
    ];

    _.forEach(inputLimitFields, ({ fieldStr, inputBoundType, isUpper }) => {
      const disabled = judgeLimitDisabled(field, inputBoundType, isUpper);

      if (disabled) {
        formList[field.name][fieldStr] = undefined;
      }
    });

    form.setFieldsValue({
      [name]: formList,
    });
  };

  const getColumns = (): ColumnProps<FormListFieldData & any>[] => {
    const { specificProcessInput } = params;
    const specificProcessInputIsNo = specificProcessInput === appEnum.Common.YN.no; // 指定工序投料 = 否

    return _.compact([
      {
        title: '行号',
        dataIndex: 'lineSeq',
        fixed: 'left',
        width: 120,
        render: (_text, field, index) => {
          return (
            <Form.Item
              initialValue={getDefaultValue(field, (index + 1) * 10)}
              name={[field.name, 'lineSeq']}
              fieldKey={[field.fieldKey, 'lineSeq']}
              style={{ marginBottom: '0' }}
              rules={[{ required: true, message: '行号不能为空' }, { validator: validatorIndex }]}
            >
              <InputNumber placeholder="请输入" min={1} precision={0} step={5} />
            </Form.Item>
          );
        },
      },
      {
        title: (
          <BatchOperateTableHeader
            titleText="用量:分子"
            rules={[
              { required: true, message: '用量:分子不能为空' },
              {
                validator: numberMinMaxCheck({
                  min: 0,
                  minAllowEqual: !!useType, // 物料清单 不能为0，非bom可以为0
                  max: 10000000,
                  fieldName: '用量:分子',
                }),
              },
            ]}
            popoverFieldComponent={
              <InputNumber style={{ width: '100%' }} placeholder="请输入" min={0} max={10000000} />
            }
            batchOperation={(value) => handleBatchSetValue('inputAmountNumerator', value)}
          />
        ),
        dataIndex: 'inputAmountNumerator',
        width: 150,
        render: (text, field) => {
          const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
            JSON.parse(params?.materialId?.value ?? '{}'),
            params?.unitId,
          );

          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'inputAmountNumerator']}
              name={[field.name, 'inputAmountNumerator']}
              initialValue={getDefaultValue(field, 1)}
              rules={_.compact([
                { required: true, message: '用量:分子不能为空' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: !!useType, // 物料清单 不能为0，非物料清单可以为0
                    max: 10000000,
                    fieldName: '用量:分子',
                  }),
                },
                { validator: validatorInputAmountNumerator },
                enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
              ])}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder="请输入"
                min={0}
                max={10000000}
                onChange={() => {
                  const ary = form.getFieldValue(name);

                  form.validateFields(
                    ary.map((t: any, index: any) => [
                      'bomFeedingControls',
                      index,
                      'inputAmountNumerator',
                    ]),
                  );
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '用量:分母',
        dataIndex: 'inputAmountDenominator',
        width: 150,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'inputAmountDenominator']}
              name={[field.name, 'inputAmountDenominator']}
              initialValue={getDefaultValue(field, params?.inputAmountDenominator ?? 1)}
              rules={[
                { required: true, message: '用量:分母不能为空' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: false,
                    max: 10000000,
                    fieldName: '用量:分母',
                  }),
                },
              ]}
            >
              <InputNumber
                disabled
                style={{ width: '100%' }}
                placeholder="请输入"
                min={0}
                max={10000000}
              />
            </Form.Item>
          );
        },
      },

      {
        title: '投料控件编号',
        dataIndex: 'inputSopControlId',
        width: 200,
        render: (text, field) => {
          const searchSelectProps = {
            disabled: false,
            params: {},
            fetchType: '',
            placeholder: '请选择',
            valuePath: 'code',
          };

          if (!useType) {
            // 用于物料清单
            const { inputProcessId } = params;
            const processRouteId = bomForm.getFieldValue(dependenciesFieldPath.processRoute);

            searchSelectProps.fetchType = 'sopControlOfProcessRouting';
            searchSelectProps.params = {
              processRouteId: processRouteId?.value,
              processNodeNum: inputProcessId?.label,
              controlType: appEnum.Sop.ControlType.feed,
            };
            searchSelectProps.disabled = !processRouteId?.value || !inputProcessId?.value;
          } else {
            // 工单的时候，inputProcessId字段上的value 存储的是对应工序上选择的sop；
            const { inputProcessId: sopId } = params;

            searchSelectProps.fetchType = 'sopBizControl';

            /** 页面初始值以及工序暂存值 */
            // FIXME:lmr 路径写死的问题
            const processesList = bomForm.getFieldValue(['processPlanCO', 'processes']) ?? [];
            const snapshotFlag = processesList?.find((e) => e.processNum === sopId?.label);

            searchSelectProps.params = {
              id: Number(_.split(_.toString(sopId?.value), '-')?.[1]) ?? undefined,
              controlType: appEnum.Sop.ControlType.feed,
              snapshotFlag: Boolean(snapshotFlag?.sopSnapshotId),
            };
            searchSelectProps.disabled = !sopId?.value;
            searchSelectProps.valuePath = 'id';
          }

          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'inputSopControlId']}
              name={[field.name, 'inputSopControlId']}
            >
              <SearchSelect {...searchSelectProps} />
            </Form.Item>
          );
        },
      },
      {
        title: '是否必投',
        dataIndex: 'inputMaterialControl',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'inputMaterialControl']}
              name={[field.name, 'inputMaterialControl']}
              initialValue={getDefaultValue(
                field,
                specificProcessInputIsNo
                  ? appEnum.Bom.FeedingControl.no
                  : appEnum.Bom.FeedingControl.yes,
              )}
            >
              <Radio.Group
                options={appDict.bom.feedingControl}
                disabled={specificProcessInputIsNo}
                onChange={() => {
                  handleResetInputLimit(field);
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '投料上下限类型',
        dataIndex: 'inputBoundType',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'inputBoundType']}
              name={[field.name, 'inputBoundType']}
              initialValue={getDefaultValue(field, appEnum.Bom.InputBoundType.notControl)}
            >
              <Select
                options={appDict.bom.inputBoundType}
                onChange={() => {
                  handleResetInputLimit(field);
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '投料上限',
        dataIndex: 'inputUpperLimit',
        width: 150,
        render: (text, field) => {
          const baseName = [name, field.name];

          const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
            JSON.parse(params?.materialId?.value ?? '{}'),
            params?.unitId,
          );

          return (
            <Form.Item
              dependencies={[
                [...baseName, 'inputMaterialControl'],
                [...baseName, 'inputBoundType'],
              ]}
              noStyle
            >
              {() => {
                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'inputUpperLimit']}
                    name={[field.name, 'inputUpperLimit']}
                    rules={_.compact([
                      enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
                    ])}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      disabled={judgeLimitDisabled(
                        field,
                        appEnum.Bom.InputBoundType.constant,
                        true,
                      )}
                      min={0}
                      max={10000000}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '投料下限',
        dataIndex: 'inputLowerLimit',
        width: 150,
        render: (text, field) => {
          const baseName = [name, field.name];

          const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
            JSON.parse(params?.materialId?.value ?? '{}'),
            params?.unitId,
          );

          const disabeld = judgeLimitDisabled(field, appEnum.Bom.InputBoundType.constant);

          return (
            <Form.Item
              dependencies={[
                [...baseName, 'inputMaterialControl'],
                [...baseName, 'inputBoundType'],
              ]}
              noStyle
            >
              {() => {
                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'inputLowerLimit']}
                    name={[field.name, 'inputLowerLimit']}
                    rules={_.compact([
                      !disabeld && { required: true, message: '投料下限不能为空' },
                      enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
                    ])}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      disabled={disabeld}
                      min={0}
                      max={10000000}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '投料上限比例',
        dataIndex: 'inputUpperLimitRatio',
        width: 150,
        render: (text, field) => {
          const baseName = [name, field.name];

          return (
            <Form.Item
              dependencies={[
                [...baseName, 'inputMaterialControl'],
                [...baseName, 'inputBoundType'],
              ]}
              noStyle
            >
              {() => {
                return (
                  <Space>
                    <Form.Item
                      style={{ marginBottom: '0' }}
                      fieldKey={[field.fieldKey, 'inputUpperLimitRatio']}
                      name={[field.name, 'inputUpperLimitRatio']}
                      rules={[{ validator: fractionLengthCheck(4) }]}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        max={100}
                        min={0}
                        disabled={judgeLimitDisabled(
                          field,
                          appEnum.Bom.InputBoundType.percentage,
                          true,
                        )}
                      />
                    </Form.Item>
                    <span> % </span>
                  </Space>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '投料下限比例',
        dataIndex: 'inputLowerLimitRatio',
        width: 150,
        render: (text, field) => {
          const baseName = [name, field.name];

          const disabeld = judgeLimitDisabled(field, appEnum.Bom.InputBoundType.percentage);

          return (
            <Form.Item
              dependencies={[
                [...baseName, 'inputMaterialControl'],
                [...baseName, 'inputBoundType'],
              ]}
              noStyle
            >
              {() => {
                return (
                  <Space>
                    <Form.Item
                      style={{ marginBottom: '0' }}
                      fieldKey={[field.fieldKey, 'inputLowerLimitRatio']}
                      name={[field.name, 'inputLowerLimitRatio']}
                      rules={_.compact([
                        !disabeld && { required: true, message: '投料下限比例不能为空' },
                        { validator: fractionLengthCheck(4) },
                      ])}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        max={100}
                        min={0}
                        disabled={disabeld}
                      />
                    </Form.Item>
                    <span> % </span>
                  </Space>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '投料质量状态',
        dataIndex: 'inputQcState',
        width: 200,
        render: (text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'inputQcState']}
              name={[field.name, 'inputQcState']}
              rules={[{ required: true, message: '投料质量状态不能为空' }]}
            >
              <Select options={appDict.material.feedingQualityStatus} mode="multiple" />
            </Form.Item>
          );
        },
      },
      {
        title: '投料类型',
        dataIndex: 'feedType',
        width: 300,
        render: (text, field) => {
          // 生产工单中，如果是非新建态、非草稿态，则不可编辑
          const disableFlag =
            useType === 1 &&
            ![BusinessStatusEnum.CREATED, BusinessStatusEnum.DRAFT].includes(
              bomForm.getFieldValue(['workOrderBaseCO', 'status']),
            );

          return (
            <div>
              <Form.Item
                style={{ marginBottom: '0' }}
                fieldKey={[field.fieldKey, 'feedType']}
                name={[field.name, 'feedType']}
                rules={[{ required: true, message: '投料类型不能为空' }]}
                initialValue={getDefaultValue(field, appEnum.Material.FeedingType.feeding)}
              >
                <Radio.Group disabled={disableFlag} options={appDict.material.feedingTypeMap} />
              </Form.Item>
              <Tooltip title="支持扫码投料，选择投料时需选择指定的库存明细进行投料；">
                <div style={{ fontSize: '12px' }}>【投料】：</div>
              </Tooltip>
              <Tooltip title="支持扫码投料，选择投料时需创建「投料申请」由系统按照规则进行自动投料，可支持不指定库存明细；">
                <div style={{ fontSize: '12px' }}>【投料-添加申请】：</div>
              </Tooltip>
            </div>
          );
        },
      },

      type === appEnum.Common.CRUD.edit && {
        title: '',
        render: (text, field) => {
          return (
            <Form.Item
              hidden
              name={[field.name, 'id']}
              fieldKey={[field.fieldKey, 'id']}
              style={{ marginBottom: '0' }}
            >
              <Input />
            </Form.Item>
          );
        },
      },
      ORIGIN_SOP_STATUS === appEnum.Common.UsageStatus.enabled && {
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width: 100,
        render: (text, field) => {
          return (
            <Form.Item style={{ marginBottom: '0' }}>
              <Button
                loading={loading}
                style={{ padding: 0 }}
                type="link"
                onClick={() => {
                  handleCopyClick(field);
                }}
              >
                复制行
              </Button>
            </Form.Item>
          );
        },
      },
    ]);
  };

  const baseInfo = {
    items: [
      {
        isFullLine: true,
        render: () => (
          <BlSortFormList
            name={name}
            renderColumns={() => getColumns()}
            isNeedDrag={false}
            isNeedAdd={false}
            maxCount={10}
            fixedRowFn={(filed, index) => index === 0}
          />
        ),
      },
    ],
  };

  const renderTopContext = () => {
    const materialInfo = JSON.parse(params?.materialId?.value ?? '{}');
    const materialUnits = getMaterialUnits(materialInfo);

    return (
      <Descriptions style={{ marginTop: 20 }}>
        <Descriptions.Item label="所属项次">{params?.seq}</Descriptions.Item>
        <Descriptions.Item label="物料编号">
          {_.get(materialInfo, 'baseInfo.code')}
        </Descriptions.Item>
        <Descriptions.Item label="物料名称">
          {_.get(materialInfo, 'baseInfo.name')}
        </Descriptions.Item>
        <Descriptions.Item label="用量:分子">{params?.inputAmountNumerator}</Descriptions.Item>
        <Descriptions.Item label="用量:分母">{params?.inputAmountDenominator}</Descriptions.Item>
        <Descriptions.Item label="单位">
          {_.find(materialUnits, ['value', params?.unitId])?.label}
        </Descriptions.Item>
        <Descriptions.Item label="投料工序号">{params?.inputProcessId?.label}</Descriptions.Item>
        <Descriptions.Item label=""> </Descriptions.Item>
        <Descriptions.Item label=""> </Descriptions.Item>
        <Descriptions.Item label="子行设置"> </Descriptions.Item>
      </Descriptions>
    );
  };

  return (
    <DataFormLayout
      form={form}
      info={[baseInfo]}
      infoBlockStyleProps={{ marginTop: -24 }}
      topContext={renderTopContext()}
      title="设置投料管控"
      onCancel={() => {
        onChange();
      }}
      onFinish={() => {
        form.validateFields().then((values) => {
          onChange(values[name]);
        });
      }}
      formProps={{
        preserve: true,
        initialValues: { [name]: params?.[name] },
      }}
    />
  );
};

export default FeedingControlForm;
