import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';
import {
  Form,
  Radio,
  FormItemProps,
  Checkbox,
  Input,
  message,
  Select,
  Space,
  InputNumber,
  Button,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ResourceBusinessType } from 'src/dict/resources';
import {
  RESORCE_BUSINESS_TYPE_MAP,
  malfunctionLevel,
  repairChannel,
} from 'src/dict/resources/mappings';
import {
  fetchRepairCaseCreate,
  fetchRepairCaseDetail,
  FetchRepairCaseDetailResponse,
  fetchRepairCaseUpdate,
} from 'src/api/ytt/resource-domain/repairPlan';
import { PlanAssignFromMap, PlanAssignToMap } from '../constants';
import UserDeptRoleSelect from 'src/page/organization/components/userDeptRoleSelect';
import lookup, { appDict, appEnum } from 'src/dict';
import { NumberRulesStandardHook, SearchSelect, BcAttachmentForm } from 'src/components';
import { CRUD } from 'src/dict/common';
import { fetchResourcesList } from 'src/api/ytt/resource-domain/resource';
import ResourceClassificationCascader from '../../components/ResourceClassificationCascader';
import {
  checkTwoSidesBackslash,
  checkTwoSidesTrim,
  textValidator2,
} from 'src/utils/formValidators';
import { getStandardWorkTime, setStandardWorkTime } from '../utils';
import { BL_BUSINESS_CODE, BL_BUSINESS_NAME } from '../config';
import TagFormList from '../../components/resourceTagFormList/TagFormList';

interface ActionProps {
  id: string;
}

export const formatDataToForm = (
  data: FetchRepairCaseDetailResponse['data'] = {},
  pageType: string = CRUD.edit,
) => {
  const {
    id,
    code,
    name,
    assignFlag = appEnum.Common.YNB.yes,
    assignFrom = 0,
    assignTo = 0,
    fileIds,
    biz,
    bizType,
    executeType = appEnum.Resources.MaintenanceExecuteType.reportTemplate,
    standardWorkTime,
    planExecutorList,
    scanCodeFlag = appEnum.Common.YNB.no,
    reportTemplateList,
    sopList,
    remark,
    resourceTypeTraceList,
    malfunctionTraceList,
    malfunctionLevel,
    tagsList,
    channelType,
  } = data;
  const {
    H: standardWorkTimeH,
    M: standardWorkTimeM,
    S: standardWorkTimeS,
  } = getStandardWorkTime(standardWorkTime);

  const isSopExecute = executeType === appEnum.Resources.MaintenanceExecuteType.SOP;

  const val: any = {
    name,
    assignFlag,
    assignFrom: _.filter([...PlanAssignFromMap.keys()], (key) => (assignFrom & key) === key),
    assignTo: _.filter([...PlanAssignToMap.keys()], (key) => (assignTo & key) === key),
    fileIds: !_.isNil(fileIds) ? _.map(fileIds, _.toNumber) : undefined,
    bizType,
    bizId: !_.isNil(biz)
      ? {
          label: biz.name,
          value: JSON.stringify(
            _.pick(biz, ['id', 'level', 'hasChildren', 'code', 'locationName']),
          ),
        }
      : undefined,
    executeType,
    standardWorkTimeH,
    standardWorkTimeM,
    standardWorkTimeS,
    planExecutorList: _.map(planExecutorList, ({ name, bizId, bizType }) => ({
      label: name,
      value: bizId,
      type: bizType,
    })),
    channelType,
    scanCodeFlag,
    malfunctionLevel,
    executeIds: isSopExecute
      ? _.map(sopList, ({ id, code, name }) => ({ label: `${code}/${name}`, value: id }))
      : _.map(reportTemplateList, ({ id, name }) => ({ label: name, value: id })),
    remark,
  };

  if (pageType === CRUD.edit) {
    val.id = id;
    val.code = code;
  }

  if (resourceTypeTraceList) {
    const parentIds = resourceTypeTraceList.map((item: any) => item.id);

    val.classificationId = parentIds;
  }
  if (malfunctionTraceList) {
    const parentIds = malfunctionTraceList.map((item: any) => item.id);

    val.malfunctionClassificationId = parentIds;
  }
  if (tagsList) {
    val.malfunctionTagsLink = tagsList.map((tag: any) => {
      const { id, labelValueId, tagsLinkId, name, children } = tag;
      const labelValues = children?.map((item: any) => {
        const { id, content, groupId: labelId } = item;

        return { id, content, labelId };
      });

      return {
        tagParentId: {
          label: name,
          value: JSON.stringify({
            id,
            label: name,
            children: labelValues,
          }),
          key: JSON.stringify({
            id,
            label: name,
            children: labelValues,
          }),
        },
        tagsId: labelValueId,
        id: pageType === CRUD.edit ? tagsLinkId : null,
      };
    });
  }

  return val;
};

const Action = (props: RouteComponentProps<ActionProps>) => {
  const { match, history } = props;
  const listCacheData = JSON.parse(sessionStorage.getItem(BL_BUSINESS_CODE) ?? '{}');

  const id = Number(match.params.id);
  const pageType = history.location.pathname.split('/').pop();

  const [form] = Form.useForm();
  // 刷新
  const [refreshMarker, setRefreshMarker] = useState<number>(0);
  const [keepOn, setKeepOn] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initailBiz();
  }, []);

  const initailBiz = () => {
    // 创建时
    const isCreate = pageType === appEnum.Common.CRUD.create;

    // 所选为资源类型
    const isEquipment = listCacheData?.target?.type === 'Equipment';

    const equipmentId = _.head(_.split((listCacheData?.target?.id as string) ?? '', '_'));
    const equipmentType = listCacheData?.businessType;

    if (isCreate && isEquipment && equipmentId) {
      fetchResourcesList({ idList: [_.toNumber(equipmentId)], type: equipmentType }).then((res) => {
        const bizInfo = _.head(res?.data?.list);

        if (!_.isNil(bizInfo)) {
          const bizId = {
            label: bizInfo.name,
            value: JSON.stringify(
              _.pick(bizInfo, ['id', 'level', 'hasChildren', 'code', 'locationName']),
            ),
          };

          form.setFieldsValue({ bizId });
        }
      });
    }
  };

  const fetchCreate = async (req: any) => {
    const { code } = await fetchRepairCaseCreate(req);

    if (code === 200) {
      message.success('创建成功');
    }
  };
  const fetchEdit = async (req: any) => {
    const { code } = await fetchRepairCaseUpdate(req);

    if (code === 200) {
      message.success('编辑成功');
    }
  };
  const initEdit = async (resId: number | undefined) => {
    try {
      if (!resId) return;
      setLoading(true);
      const res: any = await fetchRepairCaseDetail({ id: resId });
      const { data } = res;
      const val = formatDataToForm(data, pageType);

      form.setFieldsValue(val);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const initCopy = async (resId: number | undefined) => {
    try {
      if (!resId) return;
      setLoading(true);

      const res: any = await fetchRepairCaseDetail({ id: resId });
      const { data } = res;
      const val = formatDataToForm(data, pageType);

      form.setFieldsValue(val);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFinish = async () => {
    setLoading(true);
    try {
      const data = await form?.validateFields();
      const {
        id,
        code,
        name,
        assignFrom,
        assignTo,
        bizId,
        classificationId,
        executeIds,
        fileIds,
        malfunctionClassificationId,
        malfunctionLevel,
        malfunctionTagsLink: tagList = [],
        planExecutorList,
        standardWorkTimeH,
        standardWorkTimeM,
        standardWorkTimeS,
        ...rest
      } = data;
      const malfunctionTagsLink = tagList.map((item: any) => {
        const { id, tagsId } = item;

        return {
          id,
          tagsId,
        };
      });
      const value = {
        id: pageType === appEnum.Common.CRUD.edit ? id : undefined,
        code: code.replace(/\s+/g, ' '),
        name: name && name.replace(/\s+/g, ' '),
        assignFrom: _.sum(assignFrom),
        assignTo: _.sum(assignTo),
        bizId: !_.isNil(bizId) ? JSON.parse(bizId.value)?.id : undefined,
        classificationId: !_.isEmpty(classificationId) ? _.last(classificationId) : undefined,
        executeIds: _.map(executeIds, 'value'),
        fileIds: !_.isNil(fileIds) ? _.map(fileIds, _.toString) : undefined,
        malfunctionClassificationId: !_.isEmpty(malfunctionClassificationId)
          ? _.last(malfunctionClassificationId)
          : undefined,
        malfunctionLevel,
        malfunctionTagsLink,
        planExecutorList: _.map(planExecutorList, ({ value, type }) => ({
          bizId: value,
          bizType: type,
        })),
        standardWorkTime: setStandardWorkTime(
          standardWorkTimeH,
          standardWorkTimeM,
          standardWorkTimeS,
        ),
        ...rest,
      };

      switch (pageType) {
        case CRUD.create:
          await fetchCreate(value);
          break;

        case CRUD.edit:
          await fetchEdit(value);
          break;

        case CRUD.copy:
          await fetchCreate(value);
          break;

        default:
          break;
      }
      setRefreshMarker(new Date().getTime());

      if (keepOn) {
        form.resetFields();
        initCreate();
        return;
      }

      history.goBack();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const validatorWorkTime = () => {
    const { standardWorkTimeH, standardWorkTimeM, standardWorkTimeS } = form.getFieldsValue();

    // 存在一个或多个0且总和是0的时候
    if (
      (standardWorkTimeM ?? 0) + (standardWorkTimeH ?? 0) + (standardWorkTimeS ?? 0) === 0 &&
      (standardWorkTimeM === 0 || standardWorkTimeH === 0 || standardWorkTimeS === 0)
    ) {
      return Promise.reject('标准工时总时长不能为0');
    }

    return Promise.resolve('');
  };
  const initCreate = () => {
    form.setFieldsValue({
      code_isUseRules: true,
    });
  };
  const handleBizTypeChange = () => {
    form.setFieldsValue({ bizId: undefined });
  };
  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '',
        name: 'id',
        hidden: true,
        render: () => <Input />,
      },
      ...NumberRulesStandardHook({
        label: '维修方案编号',
        form,
        edit: pageType === CRUD.edit,
        objectCode: 'RepairCase',
        fieldCode: 'code',
        rules: [
          { max: 255, message: '不可超过255字符' },
          { validator: textValidator2 },
          { validator: checkTwoSidesBackslash },
          { validator: checkTwoSidesTrim },
        ],
        refreshMarker,
        // disabledState: false,
      }),
      {
        label: '维修方案名称',
        name: 'name',
        rules: [
          { required: true, message: '请输入维修方案名称' },
          { max: 255, message: '不可超过255字符' },
        ],
        render: () => <Input placeholder="请输入维修方案名称" />,
      },
      {
        label: '维修业务对象',
        name: 'bizType',
        initialValue: listCacheData?.businessType ?? ResourceBusinessType.equipment,
        rules: [{ required: true, message: '请选择维修业务对象' }],
        render: () => (
          <Select
            placeholder="请选择维修业务对象"
            options={RESORCE_BUSINESS_TYPE_MAP}
            onChange={handleBizTypeChange}
          />
        ),
      },
      {
        label: '维修目标',
        dependencies: ['bizType'],
        render: () => () => {
          const bussinessType = form.getFieldValue('bizType');

          return (
            <Form.Item
              name="bizId"
              style={{ marginBottom: 0 }}
              // rules={[{ required: true, message: '请选择故障资源' }]}
            >
              <SearchSelect
                fetchType="resourceCompleteInfo"
                params={{
                  type: bussinessType,
                  statusList: [
                    appEnum.Common.UsageStatus.enabled,
                    appEnum.Common.UsageStatus.disabled,
                  ],
                }}
                placeholder="请输入维修目标"
              />
            </Form.Item>
          );
        },
      },
      {
        label: '维修目标编号',
        shouldUpdate: true,
        render: () => () => {
          const { value = '{}' } = form.getFieldValue('bizId') ?? {};
          const { code } = JSON.parse(value);

          return <Input placeholder="" disabled value={code} />;
        },
      },
      {
        label: '维修目标区域',
        shouldUpdate: true,
        render: () => () => {
          const { value = '{}' } = form.getFieldValue('bizId') ?? {};
          const { locationName } = JSON.parse(value);

          return <Input placeholder="" disabled value={locationName} />;
        },
      },
      {
        label: '维修途径',
        name: 'channelType',
        initialValue: appEnum.Resources.MaintenanceChannel.autonomy,
        render: () => <Select placeholder="请选择维修途径" options={repairChannel} allowClear />,
      },
      {
        label: '维修任务分类',
        name: 'classificationId',
        render: () => (
          <ResourceClassificationCascader
            onChange={() => {}}
            showSearch
            multiple
            params={{ businessType: ResourceBusinessType.repair, cascade: true }}
            placeholder={'请选择维修任务分类'}
            enableAddLabel={'新建分类'}
            onCreateClick={() => {}}
            enableAdd={false}
          />
        ),
      },
      {
        label: '扫码确认',
        name: 'scanCodeFlag',
        initialValue: appEnum.Common.YNB.no,
        render: () => <Radio.Group options={appDict.common.ynb} />,
      },
      {
        label: '标准工时',
        render: () => (
          <div>
            <Space>
              <Form.Item
                name={'standardWorkTimeH'}
                noStyle
                rules={[{ validator: validatorWorkTime }]}
              >
                <InputNumber max={999} min={0} precision={0} />
              </Form.Item>
              <Button type="text" size="small">
                小时
              </Button>
              <Form.Item name={'standardWorkTimeM'} noStyle>
                <InputNumber
                  max={59}
                  min={0}
                  precision={0}
                  onChange={() => {
                    form.validateFields(['standardWorkTimeH']);
                  }}
                />
              </Form.Item>
              <Button type="text" size="small">
                分钟
              </Button>
              <Form.Item name={'standardWorkTimeS'} noStyle>
                <InputNumber
                  max={59}
                  min={0}
                  precision={0}
                  onChange={() => {
                    form.validateFields(['standardWorkTimeH']);
                  }}
                />
              </Form.Item>
              <Button type="text" size="small">
                秒
              </Button>
            </Space>
          </div>
        ),
      },
      {
        label: '计划执行人',
        name: 'planExecutorList',
        rules: [{ type: 'array', max: 20, message: '计划执行人最多不超过20个' }],
        render: () => <UserDeptRoleSelect isMultiple />,
      },
      {
        label: '允许转派',
        name: 'assignFlag',
        initialValue: appEnum.Common.YNB.yes,
        render: () => <Radio.Group options={appDict.common.ynb} />,
      },
      {
        noStyle: true,
        dependencies: ['assignFlag'],
        render: (formItemConfig: JSX.IntrinsicAttributes & FormItemProps<any>) => () => {
          const assignFlag = form.getFieldValue('assignFlag');

          if (!assignFlag) return null;

          return (
            <Form.Item
              {...formItemConfig}
              label="谁可转派"
              name="assignFrom"
              initialValue={[...PlanAssignFromMap.keys()]}
              rules={[{ required: true, message: '请选择谁可转派' }]}
            >
              <Checkbox.Group options={[...PlanAssignFromMap.values()]} />
            </Form.Item>
          );
        },
      },
      {
        noStyle: true,
        dependencies: ['assignFlag'],
        render: (formItemConfig: JSX.IntrinsicAttributes & FormItemProps<any>) => () => {
          const assignFlag = form.getFieldValue('assignFlag');

          if (!assignFlag) return null;

          return (
            <Form.Item
              {...formItemConfig}
              label="可转派给"
              name="assignTo"
              initialValue={[...PlanAssignToMap.keys()]}
              rules={[{ required: true, message: '请选择可转派给' }]}
            >
              <Checkbox.Group options={[...PlanAssignToMap.values()]} />
            </Form.Item>
          );
        },
      },
      {
        label: '执行方式',
        name: 'executeType',
        initialValue: appEnum.Resources.MaintenanceExecuteType.reportTemplate,
        rules: [{ required: true, message: '请选择执行方式' }],
        render: () => (
          <Select
            placeholder="请选择执行方式"
            options={appDict.resources.maintenanceExecuteType}
            onChange={() => {
              // 执行方式变动重置 executeIds
              form.setFieldsValue({ executeIds: undefined });
            }}
          />
        ),
      },
      {
        noStyle: true,
        dependencies: ['executeType'],
        render: (formItemConfig: JSX.IntrinsicAttributes & FormItemProps<any>) => () => {
          const executeType = form.getFieldValue('executeType');

          if (executeType === appEnum.Resources.MaintenanceExecuteType.reportTemplate) {
            return (
              <Form.Item
                {...formItemConfig}
                label="报告模板"
                name={'executeIds'}
                rules={[
                  { required: true, message: '请选择报告模板' },
                  { type: 'array', max: 20, message: '报告模板最多不超过20个' },
                ]}
              >
                <SearchSelect
                  fetchType="reportTemplate"
                  placeholder="请选择报告模板"
                  mode="multiple"
                  params={{ category: [appEnum.ReportTemplate.TemplateType.repairReport] }}
                />
              </Form.Item>
            );
          }

          if (executeType === appEnum.Resources.MaintenanceExecuteType.SOP) {
            return (
              <Form.Item
                {...formItemConfig}
                label="SOP方案"
                name={'executeIds'}
                rules={[
                  { required: true, message: '请选择SOP方案' },
                  { type: 'array', max: 1, message: 'SOP方案最多不超过1个' },
                ]}
              >
                <SearchSelect
                  fetchType="SOP"
                  placeholder="请选择SOP方案"
                  mode="multiple"
                  params={{
                    status: appEnum.Common.UsageStatus.enabled,
                    bizType: appEnum.Sop.BizType.repair,
                  }}
                />
              </Form.Item>
            );
          }

          return null;
        },
      },
      {
        label: '附件',
        name: 'fileIds',
        render: () => <BcAttachmentForm form={form} />,
      },
      {
        label: '备注',
        name: 'remark',
        rules: [{ max: 1000, message: '不可超过1000字符' }],
        render: () => <TextArea placeholder="请输入备注" />,
      },
      {
        label: '编辑原因',
        name: 'operateReason',
        hidden: pageType !== CRUD.edit,
        rules: [{ max: 1000, message: '不可超过1000字符' }],
        render: () => <TextArea placeholder="请输入编辑原因" />,
      },
    ],
  };

  const faultInfo = {
    title: '故障信息',
    items: [
      {
        label: '故障分类',
        name: 'malfunctionClassificationId',
        render: () => (
          <ResourceClassificationCascader
            onChange={() => {}}
            showSearch
            multiple
            params={{ businessType: ResourceBusinessType.malfunction, cascade: true }}
            placeholder={'请选择故障分类'}
            enableAddLabel={'新建分类'}
            onCreateClick={() => {}}
            enableAdd={false}
          />
        ),
      },
      {
        label: '故障等级',
        name: 'malfunctionLevel',
        render: () => <Select placeholder="请选择故障等级" options={malfunctionLevel} allowClear />,
      },
    ],
  };

  const tagInfo = {
    title: '故障标签',
    items: [
      {
        isFullLine: true,
        render: () => <TagFormList form={form} />,
      },
    ],
  };

  useEffect(() => {
    switch (pageType) {
      case CRUD.create:
        initCreate();
        break;

      case CRUD.edit:
        initEdit(id);
        break;

      case CRUD.copy:
        initCopy(id);
        break;

      default:
        break;
    }
  }, [refreshMarker]);

  return (
    <DataFormLayout
      loading={loading}
      form={form}
      title={`${lookup('crud', pageType)}${BL_BUSINESS_NAME}`}
      extra={
        pageType === appEnum.Common.CRUD.create && (
          <Checkbox
            onChange={() => {
              setKeepOn(!keepOn);
            }}
            defaultChecked={keepOn}
          >
            连续新建
          </Checkbox>
        )
      }
      info={[baseInfo, faultInfo, tagInfo]}
      onFinish={handleFinish}
      onCancel={() => {
        history.goBack();
      }}
    />
  );
};

export default Action;
