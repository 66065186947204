/**
 * @description 提醒文案组件
 */

interface RedRemindTextProps {
  text: string;
}

const RedRemindText: React.FC<RedRemindTextProps> = ({ text }) => (
  <div style={{ color: 'red' }}>{text}</div>
);

export default RedRemindText;
