import React from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import DetailBaseInfo from './components/detailBaseInfo';

interface DetailPageProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const CheckItemClassificationDetailPage = (props: DetailPageProps) => {
  const { match } = props;
  const checkClassId = Number(match?.params?.id);

  return <DetailBaseInfo checkClassId={checkClassId} />;
};

export default CheckItemClassificationDetailPage;
