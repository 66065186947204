import React from 'react';
import { Select, SelectProps } from 'antd';

type OptionType = {
  label: string;
  value: any;
  key: any;
};

const SearchSelectNoFetch: React.FC<SelectProps<any>> = (props) => {
  const { options, ...rest } = props;

  return (
    <Select
      options={options as OptionType[]}
      showSearch
      filterOption={(input, option) => (option?.label as string).includes(input)}
      {...rest}
    />
  );
};

export default SearchSelectNoFetch;
