import { message } from 'antd';
import { fetchFileUpload } from 'src/api/ytt/holyfile-domain';
import { FileData } from 'yapi-to-typescript';

/**
 * 为了自动补全路径以及添加身份认证信息，需要用util中封装的instance对象来完成rest call
 * 因此不能使用action属性，必须使用customRequest属性
 * onSuccess方法的调用是为了结束文件上传的loading状态，但它必须要有一个XMLHttpRequest类型的参数，我们不需要它
 * 因此暂时将传入参数的option的类型设置为any，该方法不在其他地方调用，影响不大
 */
export const customUploadFile = async (option: any) => {
  const file = option.file as File;
  // rest api要求发送到后台的数据必须是formData

  try {
    const response = await fetchFileUpload({ file: new FileData(file) });

    if (response.code === 200) {
      const fileData = response.data;

      option.onSuccess?.(fileData);
    } else {
      message.error(response.message || '上传失败');
      option.onError?.(null);
    }
  } catch (error) {
    console.log(error);
    message.error((error as Error)?.message || '上传失败');
    option.onError?.(null);
  }
};
/**
 * +号会被浏览器处理为空，所以需要转换为%2b
 */
export const formatterUrl = (fileUrl?: string) => {
  if (!fileUrl) return '';
  return encodeURI(fileUrl).replace('+', '%2B');
};

/**
 * 自定义下载文件
 */
const saveAs = (blob: Blob, filename: string) => {
  if ('msSaveOrOpenBlob' in window.navigator) {
    navigator?.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    var body = document.querySelector('body');

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    link.style.display = 'none';
    body?.appendChild(link);

    link.click();
    body?.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  }
};
export const getBlob = (url: string, cb: any) => {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (xhr.status === 200) {
      cb(xhr.response);
    }
  };
  xhr.send();
};
export const courseDownload = (url: string, filename: string) => {
  getBlob(url, (blob: Blob) => {
    saveAs(blob, filename);
  });
};
