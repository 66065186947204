import { FilterItem, RecordListLayout } from 'layout';
import { BlIcon, SearchSelect, TagList } from 'components';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BlColumnType } from '@blacklake-web/component/dist/BlTable/BlTable.type';
import { fieldTypeList, gcObject, gcTime } from 'utils';
import {
  fetchMaterialFeedRetractRecordList,
  FetchMaterialFeedRetractRecordListResponse,
} from 'src/api/ytt/mfg-domain/productionTask';
import _ from 'lodash';
import { replaceSign } from 'src/utils/constants';
import React, { useState } from 'react';
import _Time from 'src/utils/dataTypes/time';
import { renderUser } from 'src/page/share/renderUser';
import lookup, { appDict } from 'src/dict';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import { intersectionValueOfId } from 'src/utils/array';
import { useDispatch } from 'react-redux';
import authDict from 'src/utils/auth/authDict';
import { fetchFile } from 'src/utils/files';
import { Modal } from 'antd';
import FileViewer from 'src/components/fileViewer';

type RecordType = Exclude<
  FetchMaterialFeedRetractRecordListResponse['data'],
  undefined
>['list'][number];

const FeedingRetractRecordList = () => {
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  const [fileViewVisible, setFileViewVisible] = useState(false);
  const [filePathList, setFilePathList] = useState<string[]>([]);
  const columns: BlColumnType<RecordType>[] = [
    {
      title: '生产任务编号',
      dataIndex: 'taskCode',
      width: 150,
    },
    {
      title: '工序编号',
      dataIndex: ['processInfo', 'code'],
      width: 150,
    },
    {
      title: '工序名称',
      dataIndex: ['processInfo', 'name'],
      width: 150,
    },
    {
      title: '物料编号',
      dataIndex: ['materialInfo', 'baseInfo', 'code'],
      width: 150,
    },
    {
      title: '物料名称',
      dataIndex: ['materialInfo', 'baseInfo', 'name'],
      width: 150,
    },
    {
      title: '物料分类',
      dataIndex: ['materialInfo', 'category', 'name'],
      width: 150,
    },
    {
      title: '物料属性',
      dataIndex: ['materialInfo', 'attribute'],
      width: 150,
      render: (value) => {
        if (_.isEmpty(value)) return replaceSign;
        const options = value.map((item: any) => ({
          label: `${item.name}:${item.content}`,
          value: item.id,
          key: item.id,
        }));

        return <TagList dataSource={options} />;
      },
    },
    {
      title: '物料规格',
      dataIndex: ['materialInfo', 'baseInfo', 'specification'],
      width: 150,
    },
    {
      title: '回撤数量',
      dataIndex: ['retractAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '单位',
      dataIndex: ['retractAmount', 'unitName'],
      width: 150,
    },
    {
      title: '辅助单位数量',
      dataIndex: ['retractAuxAmount1', 'amountDisplay'],
      width: 150,
    },
    {
      title: '辅助单位',
      dataIndex: ['retractAuxAmount1', 'unitName'],
      width: 150,
    },
    {
      title: '批次号',
      dataIndex: 'batchNo',
      width: 150,
    },
    {
      title: '回撤标识码',
      dataIndex: 'inventoryIdentifier',
      width: 150,
    },
    {
      title: '回撤质量状态',
      dataIndex: ['qualityStatus'],
      width: 150,
      render: (value: number) => lookup('material.feedingQualityStatus', value) || replaceSign,
    },
    {
      title: '回撤仓位',
      dataIndex: ['locationInfo', 'location', 'name'],
      width: 200,
    },
    {
      title: '被替代物料编号',
      dataIndex: ['originalMaterial', 'code'],
      width: 150,
    },
    {
      title: '被替代物料名称',
      dataIndex: ['originalMaterial', 'name'],
      width: 150,
    },
    {
      title: '回撤时间',
      dataIndex: 'retractTime',
      width: 200,
      sorter: true,
      render: (time: Date) => {
        if (!time) return replaceSign;
        return _Time.format(time);
      },
    },
    {
      title: '回撤人员',
      dataIndex: 'retractUser',
      width: 150,
      render: (value) => (value ? renderUser(value) : replaceSign),
    },
    {
      title: '回撤原因',
      dataIndex: 'retractReason',
      width: 150,
    },
    {
      title: '工单编号',
      dataIndex: 'workOrderCode',
      width: 200,
    },
    {
      title: '主产出物料编码',
      dataIndex: ['workOrderOutputMaterial', 'code'],
      width: 150,
    },
    {
      title: '主产出物料名称',
      dataIndex: ['workOrderOutputMaterial', 'name'],
      width: 150,
    },
    {
      title: '销售订单编号',
      dataIndex: 'salesOrders',
      width: 150,
      render: (value) => {
        if (!value) return replaceSign;
        return (
          <TagList
            dataSource={value.map((item: { code: string; id: number }) => ({
              label: item.code,
              value: item.id,
              key: item.id,
            }))}
          />
        );
      },
    },
    {
      title: '附件',
      dataIndex: 'appendices',
      width: 150,
      render: (value) => {
        if (value && value.length >= 1) {
          return (
            <a
              onClick={async () => {
                const filePathList = await fetchFile(value);

                if (filePathList) {
                  setFilePathList(filePathList);
                  setFileViewVisible(true);
                }
              }}
            >
              查看附件
            </a>
          );
        }
        return replaceSign;
      },
    },
  ];
  const filterList: FilterItem[] = [
    {
      label: '生产任务编号',
      name: 'quickSearch',
      type: fieldTypeList.text,
    },
    {
      label: '工序编号',
      name: 'processDefIdListCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect fetchType={'process'} labelPath="code" valuePath="id" mode="multiple" />
      ),
    },
    {
      label: '工序名称',
      name: 'processDefIdListName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect fetchType={'process'} labelPath="name" valuePath="id" mode="multiple" />
      ),
    },
    {
      label: '物料编号',
      name: 'materialIdListCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="code"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '物料名称',
      name: 'materialIdListName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="name"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '批次号',
      name: 'batchNo',
      type: fieldTypeList.text,
    },
    {
      label: '回撤标识码',
      name: 'inventoryIdentifier',
      type: fieldTypeList.text,
    },
    {
      label: '回撤质量状态',
      name: 'qualityStatus',
      type: fieldTypeList.multiSelect,
      selectProps: {
        options: appDict.material.feedingQualityStatus,
      },
    },
    {
      label: '回撤仓位',
      name: 'opeLocations',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType="storageLocation"
          labelPath="name"
          valuePath="id"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '被替代物料编号',
      name: 'originalMaterialIdListCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="code"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '被替代物料名称',
      name: 'originalMaterialIdListName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="name"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '回撤时间',
      name: 'retractTime',
      type: fieldTypeList.date,
    },
    {
      label: '回撤人员',
      name: 'retractUsers',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
    {
      label: '工单编号',
      name: 'workOrderIds',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType={'workOrderFilterList'} mode="multiple" />,
    },
    {
      label: '主产出物料编号',
      name: 'workOrderOutputMaterialIdListCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="code"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '主产出物料名称',
      name: 'workOrderOutputMaterialIdListName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="code"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '销售订单编号',
      name: 'salesOrderIds',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType="salesOrderListSimple" mode="multiple" />,
    },
  ];
  const formatDataToQuery: any = (params: any) => {
    const {
      processDefIdListCode,
      processDefIdListName,
      materialIdListCode,
      materialIdListName,
      opeLocations,
      originalMaterialIdListCode,
      originalMaterialIdListName,
      retractTime,
      retractUsers,
      workOrderIds,
      workOrderOutputMaterialIdListCode,
      workOrderOutputMaterialIdListName,
      salesOrderIds,
      ...rest
    } = params;
    const [retractTimeFrom, retractTimeTo] = gcTime.formatTimeRangeUnix(retractTime);
    const relParams = {
      ...rest,
      processDefIds: intersectionValueOfId(processDefIdListCode, processDefIdListName),
      materialIds: intersectionValueOfId(materialIdListCode, materialIdListName),
      opeLocations: _.isEmpty(opeLocations) ? null : opeLocations.map((item: any) => item.value),
      originalMaterialIds: intersectionValueOfId(
        originalMaterialIdListCode,
        originalMaterialIdListName,
      ),
      retractTimeFrom: retractTimeFrom && Number(retractTimeFrom),
      retractTimeTo: retractTimeTo && Number(retractTimeTo),
      retractUsers: _.isEmpty(retractUsers) ? null : retractUsers.map((item: any) => item.value),
      workOrderIds: _.isEmpty(workOrderIds) ? null : workOrderIds.map((item: any) => item.value),
      workOrderOutputMaterialIds: intersectionValueOfId(
        workOrderOutputMaterialIdListCode,
        workOrderOutputMaterialIdListName,
      ),
      salesOrderIds: _.isEmpty(salesOrderIds) ? null : salesOrderIds.map((item: any) => item.value),
    };

    dispatch.excelImport.updateBusinessData({
      businessData: gcObject.filterEmptyProperty(relParams),
    });
    return gcObject.filterEmptyProperty(relParams);
  };
  const formatDataToFormDisplay = (data: any) => {
    const { retractTime, ...rest } = data;

    return {
      ...rest,
      retractTime: retractTime && gcTime.formatRangeTimeToMoment(retractTime),
    };
  };

  return (
    <>
      <RecordListLayout
        placeholder="请输入生产任务编号"
        mainMenu={[
          {
            title: '导出',
            showExport: true,
            auth: authDict.materialretractrecord_export,
            icon: <BlIcon type="icondaochu" />,
            onClick: () => {
              openModal({
                optType: 'export',
                businessType: BUSINESS_TYPE.feedingRetractRecord,
              });
            },
          },
        ]}
        requestFn={(params) =>
          fetchMaterialFeedRetractRecordList({
            ...params,
            sorter: params?.sorter ? params?.sorter : [{ field: 'retractTime', order: 'desc' }], // 后端不好写 前端给的默认排序
          })
        }
        columns={columns}
        filterList={filterList}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        configcacheKey="feedingRetractRecordModule"
      />
      {fileViewVisible && (
        <Modal
          title={'查看附件'}
          width={900}
          visible={fileViewVisible}
          footer={null}
          onCancel={() => setFileViewVisible(false)}
        >
          <FileViewer fileType={'png'} filePathList={filePathList} />
        </Modal>
      )}
    </>
  );
};

export default FeedingRetractRecordList;
