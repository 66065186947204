import React, { useState, useEffect } from 'react';
import { DetailLayoutForDrawer } from 'layout';
import { fetchSalesOrderDetail } from 'src/api/ytt/order-domain/salesOrder';
import { DetailBaseInfo } from './detailBaseInfo';

interface DetailProps {
  visible: boolean;
  closeDetail: () => void;
  salesOrderId: number;
}

const SalesOrderDetail = (props: DetailProps) => {
  const { visible, closeDetail, salesOrderId } = props;

  const [dataSource, setDataSource] = useState({} as any);

  const fetchData = async () => {
    try {
      const { data } = await fetchSalesOrderDetail({ id: salesOrderId });

      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [salesOrderId]);

  return (
    <DetailLayoutForDrawer
      visible={visible}
      onClose={closeDetail}
      width={1200}
      content={<DetailBaseInfo detail={dataSource} />}
    />
  );
};

export default SalesOrderDetail;
