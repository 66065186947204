import { Table } from 'antd';
import { RecordListLayout } from 'layout';
import { useEffect, useState } from 'react';
import { fetchCurrencyList } from 'src/api/ytt/order-domain/currency';
export default function CurrencyList() {
  const [dataSource, setDataSource] = useState<any>();
  const columns = [
    {
      title: '货币代码',
      dataIndex: 'code',
      width: 120,
    },
    {
      title: '货币符号',
      dataIndex: 'symbol',
      width: 120,
    },
    {
      title: '货币名称',
      dataIndex: 'name',
      width: 120,
    },
  ];

  useEffect(() => {
    fetchCurrencyList({}).then((res) => {
      console.log(res);
      setDataSource(res?.data);
    });
  }, []);

  return (
    <div>
      <Table dataSource={dataSource} columns={columns} rowKey="code" pagination={false} />
    </div>
  );
}
