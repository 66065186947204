import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { getFileDetail } from 'src/services/file';

/** 关闭导入导出弹窗 */
export const useCloseExImportModal = () => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch.excelImport.closeExImportModal();
  };

  return closeModal;
};

interface ModalProps {
  optType: string;
  businessType: string;
}

/** 打开导入导出弹窗 */
export const useOpenExImportModal = () => {
  const dispatch = useDispatch();

  const openModal = (modalProps: ModalProps) => {
    dispatch.excelImport.openExImportModal(modalProps);
  };

  return openModal;
};

/**
 * 下载文件
 * @文件ID id
 */
export const downloadFileByID = async (id: number | string | null | undefined) => {
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    const a = document.createElement('a');
    a.href = id;
    a.click();
    return;
  }

  const res = await getFileDetail({ id });
  const { code, data, message: msg } = res;
  if (code !== 200) {
    message.error(msg);
    return;
  }
  const { fileUri, filename } = data;
  const a = document.createElement('a');
  a.href = fileUri;
  a.download = filename;
  a.click();
};
