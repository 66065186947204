export interface ColType {
  /**
   * 批次号
   */
  batchNo: string;
  /**
   * 节点生成时间
   */
  createdAt: number;
  material: {
    /**
     * 物料编码
     */
    code: string;
    /**
     * 物料id
     */
    id: number;
    /**
     * 物料名称
     */
    name: string;
  };
  /**
   * 标识码
   */
  qrCode: string;
  /**
   * 该节点traceId
   */
  traceId: number;
}

// 追溯节点详情tab
export const TraceNodeTab = {
  PARENT: '1', // 父级节点
  SUB: '2', // 子级节点
  PRODUCTION: '3', // 生产信息
  WAREHOUSE: '4', // 仓储信息
  QUALITY: '5', // 质量信息
};

// 生产信息tab下的信息tab
export const ProductionTabEnum = {
  REPORT_RECORDS: '6', // 报工
};

// 仓储tab下的信息tab
export const WarehouseEnumTab = {
  INBOUND_RECORDS: '7', // 入库记录
  OUTBOUND_RECORDS: '8', // 出库记录
};

// 质量信息tab下的信息tab
export const QualityEnumTab = {
  INSPECTION_TASKS: '9', // 检验任务
};

export interface TraceNodeDetailType {
  baseSource: DetailBaseInfoType;
  parentSource: ParentAndSubNodeType;
  subSource: ParentAndSubNodeType;
  reportSource: ReportsType;
  warehouse: WarehouseDetailType;
}

export interface ParentAndSubNodeType {
  total: number;
  page: number;
  list: ParentAndSubNodeItemType[];
}

export interface DetailBaseInfoType {
  /**
   * 生产日期
   */
  productionTime: number;
  supplier: {
    /**
     * id
     */
    id: number;
    /**
     * 供应商名称
     */
    name: string;
    /**
     * 供应商批次
     */
    supplierBatchNo: string;
  };
  traceNode: {
    /**
     * 批次号
     */
    batchNo: string;
    /**
     * 节点生成时间
     */
    createdAt: number;
    material: {
      /**
       * 物料编码
       */
      code: string;
      /**
       * 物料id
       */
      id: number;
      /**
       * 物料名称
       */
      name: string;
    };
    /**
     * 标识码
     */
    qrCode: string;
    /**
     * 该节点traceId
     */
    traceId: number;
  };
  workOrder: {
    /**
     * 工单编码
     */
    code: string;
    /**
     * 工单id
     */
    id: number;
    /**
     * 工单名称
     */
    name: string;
  };
}

export interface ParentAndSubNodeItemType {
  /**
   * 追溯关系建立时间
   */
  createdAt: number;
  node: {
    /**
     * 批次号
     */
    batchNo: string;
    /**
     * 节点生成时间
     */
    createdAt: number;
    material: {
      /**
       * 物料编码
       */
      code: string;
      /**
       * 物料id
       */
      id: number;
      /**
       * 物料名称
       */
      name: string;
    };
    /**
     * 标识码
     */
    qrCode: string;
    /**
     * 该节点traceId
     */
    traceId: number;
  };
  operationType: number;
}

export interface ReportsType {
  total: number;
  page: number;
  list: ReportsItemType[];
}

export interface ReportsItemType {
  /**
   * 设备
   */
  equipments: {
    /**
     * 设备编码
     */
    code: string;
    /**
     * 设备id
     */
    id: number;
    /**
     * 设备名称
     */
    name: string;
  }[];
  /**
   * 报工操作时间
   */
  operateTime: number;
  process: {
    /**
     * 工序编码
     */
    code: string;
    /**
     * 工序id
     */
    id: number;
    /**
     * 工序名称
     */
    name: string;
  };
  /**
   * 生产人员
   */
  producers: {
    /**
     * 用户编码
     */
    code: string;
    /**
     * 用户id
     */
    id: number;
    /**
     * 用户名字
     */
    name: string;
  }[];
  /**
   * 报工记录Id
   */
  reportRecordId: number;
  reportUser: {
    /**
     * 用户编码
     */
    code: string;
    /**
     * 用户id
     */
    id: number;
    /**
     * 用户名字
     */
    name: string;
  };
  workOrder: {
    /**
     * 工单编码
     */
    code: string;
    /**
     * 工单id
     */
    id: number;
    /**
     * 工单名称
     */
    name: string;
  };
}

export interface WarehouseDetailType {
  inBoundSource: InBoundType;
  outBoundSource: OutBoundType;
}

export interface InBoundType {
  total: number;
  page: number;
  list: InBoundItemType[];
}

export interface OutBoundType {
  total: number;
  page: number;
  list: OutBoundItemType[];
}

export interface InBoundItemType {
  inboundOrder: {
    /**
     * 入库单编码
     */
    code: string;
    /**
     * 入库单id
     */
    id: number;
    /**
     * 入库单名称
     */
    name: string;
  };
  /**
   * 入库记录Id
   */
  inboundRecordId: number;
  /**
   * 操作时间
   */
  operateTime: number;
  operator: {
    /**
     * 用户编码
     */
    code: string;
    /**
     * 用户id
     */
    id: number;
    /**
     * 用户名字
     */
    name: string;
  };
}

export interface OutBoundItemType {
  outboundOrder: {
    /**
     * 出库单编码
     */
    code: string;
    /**
     * 出库单id
     */
    id: number;
    /**
     * 入库单名称
     */
    name: string;
  };
  /**
   * 出库记录Id
   */
  outboundRecordId: number;
  /**
   * 操作时间
   */
  operateTime: number;
  operator: {
    /**
     * 用户编码
     */
    code: string;
    /**
     * 用户id
     */
    id: number;
    /**
     * 用户名字
     */
    name: string;
  };
}

export interface DefaultPaginationType {
  current: number;
  pageSize: number;
  total: number;
}

export const defaultPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};
