import React from 'react';
import { Badge } from 'antd';
import lookup, { appEnum } from 'src/dict';
import _ from 'lodash';
import { replaceSign } from 'src/utils/constants';

const MaintenanceStatus = ({ status }: { status?: number }) => {
  if (_.isNil(status)) return <span>{replaceSign}</span>;

  return (
    <span>
      {status === appEnum.Common.UsageStatus.enabled && <Badge status="success" />}
      {status === appEnum.Common.UsageStatus.disabled && <Badge status="default" />}
      {lookup('common.usageStatus', status)}
    </span>
  );
};

export default MaintenanceStatus;
