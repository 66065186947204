import React, { useState } from 'react';
import { Button, Form, FormInstance, Popover } from 'antd';
import { BlIcon, SearchSelect } from 'src/components';
import { formatSelectToMessageReceivers } from '../utils';
import { ReceiverType } from '../interface';

interface Props {
  form: FormInstance;
  fieldForm: FormInstance;
}

export default function AddField({ form, fieldForm }: Props) {
  const [visible, setVisible] = useState<boolean>(false);

  const batchContent = () => {
    return (
      <Form style={{ width: '300px' }} form={fieldForm}>
        <Form.Item name="fieldSelect">
          <SearchSelect
            style={{ width: '65%' }}
            mode="multiple"
            params={{
              code:
                form.getFieldsValue()?.objectCode?.value &&
                JSON.parse(form.getFieldsValue()?.objectCode?.value)?.objectCode,
            }}
            fetchType={'noticeFiled'}
            labelInValue
          />
        </Form.Item>
        <Form.Item noStyle>
          <div style={{ marginTop: '16px', textAlign: 'right' }}>
            <Button
              size="small"
              onClick={() => {
                setVisible(false);
              }}
            >
              取消
            </Button>
            <Button
              size="small"
              style={{ marginLeft: '8px' }}
              onClick={async () => {
                try {
                  const { fieldSelect } = await fieldForm?.validateFields();

                  const messageReceivers = form.getFieldValue('messageReceivers') ?? [];

                  const newMessageReceivers = formatSelectToMessageReceivers(
                    messageReceivers,
                    fieldSelect,
                    ReceiverType.field,
                  );

                  form.setFieldsValue({ messageReceivers: newMessageReceivers });

                  setVisible(false);
                } catch (error) {
                  console.log('error: ', error);
                }
              }}
            >
              确定
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  const onVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  return (
    <div>
      <Popover
        content={batchContent()}
        title="添加业务字段"
        trigger="click"
        visible={visible}
        onVisibleChange={onVisibleChange}
      >
        <Button type="dashed" icon={<BlIcon type="iconxinjiantianjia" />}>
          添加业务字段
        </Button>
      </Popover>
    </div>
  );
}
