import { DetailLayout, DetailLayoutInfoBlock } from '@blacklake-web/layout';
import { useEffect, useState } from 'react';
import RetractRecordTable from 'src/page/productionPlanning/feedingRecord/components/RetractRecordTable';
import { injectCustomFieldInfos, useCustomFieldCombinedData } from 'src/components/customField';
import { RouteChildrenProps } from 'react-router';
import { fetchMaterialFeedFeedRecordDetail } from 'src/api/ytt/mfg-domain/productionTask';
import _ from 'lodash';
import { replaceSign } from 'src/utils/constants';
import { TagList } from 'components';
import lookup from 'src/dict';
import _Time from 'src/utils/dataTypes/time';
import { renderUser } from 'src/page/share/renderUser';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

interface Props extends RouteChildrenProps<{ id: string }> {}

const FeedingRecordDetail = (props: Props) => {
  const [dataSource, setDataSource] = useState<any>();
  const { search } = useLocation();
  const urlParams = qs.parse(search, { ignoreQueryPrefix: true });
  const id = Number(urlParams?.id);
  const detailId = Number(urlParams?.detailId);
  const [loading, setLoading] = useState(true);
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.feedingRecord);

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '生产任务编号',
        dataIndex: 'taskCode',
      },
      {
        label: '工序编号',
        dataIndex: ['processInfo', 'code'],
      },
      {
        label: '工序名称',
        dataIndex: ['processInfo', 'name'],
      },
      {
        label: '物料编号',
        dataIndex: ['materialInfo', 'baseInfo', 'code'],
      },
      {
        label: '物料名称',
        dataIndex: ['materialInfo', 'baseInfo', 'name'],
      },
      {
        label: '物料分类',
        dataIndex: ['materialInfo', 'category', 'name'],
      },
      {
        label: '物料属性',
        dataIndex: ['materialInfo', 'attribute'],
        render: (value) => {
          if (_.isEmpty(value)) return replaceSign;
          const options = value.map((item: any) => ({
            label: `${item.name}:${item.content}`,
            value: item.id,
            key: item.id,
          }));

          return <TagList dataSource={options} />;
        },
      },
      {
        label: '物料规格',
        dataIndex: ['materialInfo', 'baseInfo', 'specification'],
      },
      {
        label: '投料数量',
        dataIndex: ['feedTotalAmount', 'amountDisplay'],
      },
      {
        label: '已回撤数量',
        dataIndex: ['retractAmount', 'amountDisplay'],
      },
      {
        label: '实际投料数量',
        dataIndex: ['feedAmount', 'amountDisplay'],
      },
      {
        label: '投料单位',
        dataIndex: ['feedAmount', 'unitName'],
      },
      {
        label: '辅助单位数量',
        dataIndex: ['feedAuxAmount1', 'amountDisplay'],
      },
      {
        label: '辅助单位',
        dataIndex: ['feedAuxAmount1', 'unitName'],
      },
      {
        label: '标识码',
        dataIndex: 'inventoryIdentifier',
      },
      {
        label: '批次号',
        dataIndex: 'batchNo',
      },
      {
        label: '质量状态',
        dataIndex: 'qualityStatus',
        render: (value: number) => lookup('material.feedingQualityStatus', value) || replaceSign,
      },
      {
        label: '投料仓位',
        dataIndex: ['locationInfo', 'location', 'name'],
      },
      {
        label: '被替代物料编号',
        dataIndex: ['originalMaterial', 'code'],
      },
      {
        label: '被替代物料名称',
        dataIndex: ['originalMaterial', 'name'],
      },
      {
        label: '投料时间',
        dataIndex: 'feedTime',
        render: (time: Date) => {
          if (!time) return replaceSign;
          return _Time.format(time);
        },
      },
      {
        label: '执行人员',
        dataIndex: 'execUser',
        render: (value) => (value ? renderUser(value) : replaceSign),
      },
      {
        label: '投料人员',
        dataIndex: 'feedUser',
        render: (value) => (value ? renderUser(value) : replaceSign),
      },
      {
        label: '工单编号',
        dataIndex: 'workOrderCode',
      },
      {
        label: '主产出物料编号',
        dataIndex: ['workOrderOutputMaterial', 'code'],
      },
      {
        label: '主产出物料名称',
        dataIndex: ['workOrderOutputMaterial', 'name'],
      },
      {
        label: '销售订单编号',
        dataIndex: 'salesOrders',
        render: (value) => {
          if (!value) return replaceSign;
          return (
            <TagList
              dataSource={value.map((item: { code: string; id: number }) => ({
                label: item.code,
                value: item.id,
                key: item.id,
              }))}
            />
          );
        },
      },
      {
        label: '投料记录编号',
        dataIndex: ['feedRecordCode'],
      },
    ],
  };
  const retractRecordInfo: DetailLayoutInfoBlock = {
    title: '回撤记录',
    items: [
      {
        label: '',
        dataIndex: 'retractRecords',
        render: (value) => {
          return <RetractRecordTable retractRecordDataSource={value} />;
        },
      },
    ],
  };
  const getDataAndSet = (id: number, detailId: number) => {
    if (!id) return;
    fetchMaterialFeedFeedRecordDetail({
      recordId: id,
      recordDetailId: detailId,
    })
      .then((res) => {
        if (res) {
          setDataSource(res?.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!id || !detailId) return;
    getDataAndSet(id, detailId);
  }, [id, detailId]);
  return (
    <DetailLayout
      title="投料记录详情"
      dataSource={dataSource}
      loading={loading}
      info={[
        baseInfo,
        injectCustomFieldInfos({
          customFields,
          type: 'detail',
        }) as DetailLayoutInfoBlock,
        retractRecordInfo,
      ]}
    />
  );
};

export default FeedingRecordDetail;
