import React, { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import _ from 'lodash';
import { DetailLayoutInfoBlock, DetailLayout, DetailLayoutInfoItem } from 'layout';
import { message } from 'antd';
import {
  fetchMaintenanceCaseDetail,
  fetchMaintenanceCaseStatusUpdate,
} from 'src/api/ytt/resource-domain/maintenancePlan';
import { AvatarDisplay } from 'src/components/avatar/show';
import TagTooltip from 'src/components/tooltip/tagTooltip';
import { BcAttachmentDetail, BlIcon, SingleOperationLogComponent } from 'components';
import { gcTime } from 'src/utils';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { valueOrHolder } from 'src/utils/formatters';
import lookup, { appEnum } from 'src/dict';
//
import { MaintenanceDetailType } from '../index.d';
import { goToCopy, goToEdit } from '../navigates';
import MaintenanceStatus from '../components/MaintenanceStatus';
import { BL_BUSINESS_NAME } from '../config';
import { getStandardWorkTime } from '../utils';
import { PlanAssignFromMap, PlanAssignToMap } from '../constants';
import { replaceSign } from 'src/utils/constants';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

interface DetailContentProps extends RouteChildrenProps<{ id: string }> {}

const Detail = (props: DetailContentProps) => {
  const { match, history } = props;
  const [detailData, setDetailData] = useState<MaintenanceDetailType>();
  const [loading, setLoading] = useState(false);
  const [visibleLog, setVisibleLog] = useState(false);

  const fetchAndSetData = (id?: string) => {
    if (id) {
      setLoading(true);
      return fetchMaintenanceCaseDetail({ id: _.toNumber(id) }).then((res) => {
        const { data } = res;

        data && setDetailData(data);

        return res;
      });
    }

    return Promise.reject();
  };

  useEffect(() => {
    fetchAndSetData(match?.params?.id)
      .catch(() => {
        setTimeout(() => {
          history.goBack();
        }, 500);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getChangeStatus = (stauts: number, idList: number[]) => {
    if (stauts === appEnum.Resources.EnergyMeterStatus.enabled) {
      return fetchMaintenanceCaseStatusUpdate({
        idList,
        status: appEnum.Resources.EnergyMeterStatus.disabeld,
      });
    }
    return fetchMaintenanceCaseStatusUpdate({
      idList,
      status: appEnum.Resources.EnergyMeterStatus.enabled,
    });
  };

  const baseMenu = () => {
    const { id, status } = detailData ?? {};

    if (_.isNil(status) || _.isNil(id)) return [];

    const statusOperation = lookup('common.changeStatusAction', status);

    const buttonList = [
      {
        title: statusOperation,
        key: 'changeStatus',
        icon: <BlIcon type="iconqiyong" />,
        auth: authDict.maintenanceCase_enable_disable,
        onClick: () => {
          getChangeStatus(status, [id]).then((res) => {
            if (res.code === 200) {
              message.success(`${statusOperation}成功!`);
              fetchAndSetData(match?.params?.id).finally(() => {
                setLoading(false);
              });
            }
          });
        },
      },
      {
        title: '复制',
        key: 'copy',
        auth: authDict.maintenanceCase_add,
        icon: <BlIcon type="iconfuzhi" />,
        onClick: () => {
          goToCopy(id);
        },
      },
      // {
      //   key: 'delete',
      //   onClick: () => {
      //     fetchMaintenanceCaseDelete({ idList: [id] }).then((res) => {
      //       if (res.code === 200) {
      //         message.success('删除成功');
      //       }
      //     });
      //   },
      //   title: '删除',
      //   disabled: true,
      // },
      {
        title: '操作记录',
        key: 'look',
        auth: authDict.maintenanceCase_operrecord,
        onClick: () => {
          setVisibleLog(true);
        },
      },
      {
        title: '编辑',
        key: 'edit',
        auth: authDict.maintenanceCase_edit,
        type: 'primary',
        icon: <BlIcon type="iconbianji" />,
        onClick: () => {
          goToEdit(id);
        },
      },
    ];

    return _.compact(buttonList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const baseInfo = (): DetailLayoutInfoBlock => {
    const baseItems: DetailLayoutInfoItem[] = [
      { label: `${BL_BUSINESS_NAME}编号`, dataIndex: 'code' },
      { label: `${BL_BUSINESS_NAME}名称`, dataIndex: 'name' },
      {
        label: '维保业务对象',
        dataIndex: 'bizType',
        render: (type) => valueOrHolder(lookup('resources.RESORCE_BUSINESS_TYPE_MAP', type)),
      },
      {
        label: '维保目标',
        dataIndex: ['biz', 'name'],
      },
      {
        label: '维保目标编号',
        dataIndex: ['biz', 'code'],
      },
      {
        label: '维保目标区域',
        dataIndex: ['biz', 'locationName'],
      },
      {
        label: '业务类型',
        dataIndex: 'businessType',
        render: (type) => valueOrHolder(lookup('resources.maintenanceType', type)),
      },
      {
        label: '维保途径',
        dataIndex: 'channelType',
        render: (type) => valueOrHolder(lookup('resources.maintenanceChannel', type)),
      },
      { label: '维保任务分类', dataIndex: ['resourceType', 'name'] },
      {
        label: '标准工时',
        dataIndex: 'standardWorkTime',
        render: (time) => {
          return valueOrHolder(time, () => {
            const { H, M, S } = getStandardWorkTime(time);

            return `${H}小时${M}分钟${S}秒`;
          });
        },
      },
      {
        label: '计划执行人',
        dataIndex: 'planExecutorList',
        render: (users: any[]) => <TagTooltip list={users || []} isUser max={2} />,
      },
      // todo 暂时不上
      // {
      //   label: '资源占用',
      //   dataIndex: 'field',
      //   render: (type) => valueOrHolder(lookup('common.yn', type)),
      // },
      {
        label: '扫码确认',
        dataIndex: 'scanCodeFlag',
        render: (type) => valueOrHolder(lookup('common.ynb', type)),
      },
      {
        label: '允许转派',
        dataIndex: 'assignFlag',
        render: (type) => valueOrHolder(lookup('common.ynb', type)),
      },
      {
        label: '谁可转派',
        dataIndex: 'assignFrom',
        render: (assignFrom) =>
          valueOrHolder(assignFrom || undefined, () =>
            _.map(
              _.filter([...PlanAssignFromMap.keys()], (key) => (assignFrom & key) === key),
              (key) => PlanAssignFromMap.get(key)?.label,
            ).join('，'),
          ),
      },
      {
        label: '可转派给',
        dataIndex: 'assignTo',
        render: (assignTo) =>
          valueOrHolder(assignTo || undefined, () =>
            _.map(
              _.filter([...PlanAssignToMap.keys()], (key) => (assignTo & key) === key),
              (key) => PlanAssignToMap.get(key)?.label,
            ).join('，'),
          ),
      },
      {
        label: '执行方式',
        dataIndex: 'executeType',
        render: (type) => lookup('resources.maintenanceExecuteType', type),
      },
      {
        label: 'SOP方案',
        dataIndex: 'sopList',
        render: (list) => {
          if (_.isNil(list)) return replaceSign;
          return _.map(list, 'name');
        },
      },
      {
        label: '报告模板',
        dataIndex: 'reportTemplateList',
        render: (list) => {
          if (_.isNil(list)) return replaceSign;
          return _.map(list, 'name').join('，');
        },
      },
      {
        label: '启用状态',
        dataIndex: 'status',
        render: (status) => <MaintenanceStatus status={status} />,
      },
      {
        label: '附件',
        dataIndex: 'fileIds',
        render: (fileIds: number[]) => <BcAttachmentDetail fileIds={fileIds} />,
        span: 2,
      },
      {
        label: '备注',
        dataIndex: 'remark',
        isFullLine: true,
      },
    ];

    return {
      title: '基本信息',
      items: baseItems,
      column: 2,
    };
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: any) =>
          valueOrHolder(creator, (creator: any) => (
            <AvatarDisplay {...creator} isShowTag={false} isUser />
          )),
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: any) =>
          valueOrHolder(operator, (operator: any) => (
            <AvatarDisplay {...operator} isShowTag={false} isUser />
          )),
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
    ],
    column: 2,
  };

  return (
    <DetailLayout
      loading={loading}
      baseMenu={baseMenu()}
      title={`${BL_BUSINESS_NAME}详情`}
      info={[baseInfo(), otherInfo]}
      dataSource={detailData}
      userAuth={getAuthFromLocalStorage()}
    >
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailData?.id ?? 0}
          objectCode={OBJECT_OF_CODE.MaintenanceCase}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </DetailLayout>
  );
};

export default Detail;
