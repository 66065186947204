import { DetailLayout } from 'layout';
import { formatTimeForRender } from 'src/utils/formatters';
import _ from 'lodash';
import ApprovalNodeSteps from '../components/approvalNodeSteps';
import { DetailLayoutMenuItem } from 'src/layout/detail/DetailLayout.type';
import { renderApprovalStatus, renderApprovalTaskStatus } from '../utils';
import { replaceSign } from 'src/utils/constants';
import authDict, { hasAuth } from 'src/utils/auth';
import { message } from 'antd';
import { Tooltip } from 'src/components';
import { toElectronicDocumentsDetail } from 'src/page/analyse/templateManagement/navigation';

interface Props {
  dataSource: any;
  title: string;
  baseMenu: DetailLayoutMenuItem[];
  isApprovalTask: boolean;
}

const DetailsBaseInfo = (props: Props) => {
  const { dataSource, baseMenu, title, isApprovalTask } = props;

  const getApproveObjectInfo = (approvalObjectCode: string) => {
    let contentItem = (
      <a
        onClick={() => {
          if (hasAuth(authDict.soptask_detail)) {
            window.open(`/sop/exec/task/${dataSource?.approvalObjectInstanceId}/detail`);
          } else {
            message.warning('您无查看权限');
          }
        }}
      >
        <Tooltip text={dataSource?.approvalObjectInstanceCode ?? replaceSign} width={150} />
      </a>
    );

    switch (approvalObjectCode) {
      case 'eReportCase':
        contentItem = (
          <a
            onClick={() => {
              if (hasAuth(authDict.eReportCase_detail)) {
                window.open(toElectronicDocumentsDetail(dataSource?.approvalObjectInstanceId));
              } else {
                message.warning('您无查看权限');
              }
            }}
          >
            <Tooltip text={dataSource?.approvalObjectInstanceCode ?? replaceSign} width={150} />
          </a>
        );

        break;
    }

    return contentItem;
  };

  const baseInfo = _.compact([
    {
      column: 2,
      title: '基本信息',
      items: [
        {
          label: isApprovalTask ? '审批任务编号' : '审批单编号',
          dataIndex: 'code',
        },
        {
          label: '名称',
          dataIndex: 'name',
        },
        {
          label: '状态',
          dataIndex: 'status',
          render: isApprovalTask ? renderApprovalTaskStatus : renderApprovalStatus,
        },
        {
          label: '申请人',
          dataIndex: ['applyNode', 'applicantName'],
        },
        {
          label: '创建人',
          dataIndex: ['creator', 'name'],
        },
        {
          label: '创建时间',
          dataIndex: 'createdAt',
          render: formatTimeForRender,
        },
        {
          label: '更新人',
          dataIndex: ['operator', 'name'],
        },
        {
          label: '更新时间',
          dataIndex: 'updatedAt',
          render: formatTimeForRender,
        },
      ],
    },
    {
      column: 2,
      title: '审批内容',
      items: [
        {
          label: '审批对象',
          dataIndex: 'approvalObjectName',
        },
        {
          label: '审批内容',
          dataIndex: 'approvalObjectInstanceCode',
          render: () => getApproveObjectInfo(dataSource?.approvalObjectCode),
        },
      ],
    },
  ]);
  const handleRenderApprovalInfo = () => {
    return {
      title: '审批流程',
      column: 2,
      items: [
        {
          dataIndex: 'nodeList',
          isFullLine: true,
          render: (nodeList: [], data: any) => {
            return (
              <ApprovalNodeSteps
                applyNode={data?.applyNode}
                nodeList={nodeList}
                isApprovalTask={isApprovalTask}
              />
            );
          },
        },
      ],
    };
  };
  const infoArray = _.compact([...baseInfo, handleRenderApprovalInfo()]);

  return (
    <DetailLayout baseMenu={baseMenu} title={title} dataSource={dataSource} info={infoArray} />
  );
};

export default DetailsBaseInfo;
