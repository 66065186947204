import _ from 'lodash';
import { fetchCustomFieldVisibleGetCustomFieldCross } from 'src/api/ytt/supply-chain-domain/custom_fields';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { formatCustomFieldsInData, ICustomFields } from 'src/components/customField';
import _Time from 'src/utils/dataTypes/time';

export const formatFetchValue = (
  values: any,
  customFields?: ICustomFields,
  customItemFields?: ICustomFields,
) => {
  const {
    materials,
    ownerIds,
    customerId,
    attachments,
    code,
    remark,
    numberRuleId,
    customFields: documentCustomFieldsData,
    ...rest
  } = values;

  const newValues = { ...rest };

  const _materials = materials?.map((node: any, index: number) => {
    const { deliveryDate, materialId, id, amount, lineNo, ...rest } = node;

    const _materialId = materialId && JSON.parse(materialId?.value)?.baseInfo?.id;

    const _deliveryDate = _Time.formatToUnix(deliveryDate);

    const newNode = {
      ...rest,
      materialId: _materialId,
      deliveryDate: _deliveryDate,
      lineNo: lineNo ?? index + 1,
      id,
      amount: Number(amount),
    };

    return formatCustomFieldsInData({
      data: newNode,
      customFields: customItemFields,
    });
  });

  newValues.header = formatCustomFieldsInData({
    data: {
      attachments,
      code,
      remark,
      ownerIds: ownerIds?.map((owner: any) => owner.value ?? owner.key),
      customerId: customerId?.value ?? customerId?.key,
      codeRuleId: numberRuleId,
      customFields: documentCustomFieldsData,
    },
    customFields,
  });

  newValues.items = _materials;

  return newValues;
};

/**
 * 获取，跨租户的可见的自定义字段 列
 */
export const fetchCustomFieldCrossColumns = async (customerId: number | undefined) => {
  try {
    if (_.isUndefined(customerId)) return;

    const [{ data: crossCustomFieldsData }, { data: crossCustomFieldsItemData }] =
      await Promise.all([
        await fetchCustomFieldVisibleGetCustomFieldCross({
          customerId,
          objectCodes: [OBJECT_OF_CODE.purchaseOrder],
        }),
        await fetchCustomFieldVisibleGetCustomFieldCross({
          customerId,
          objectCodes: [OBJECT_OF_CODE.purchaseOrderItem],
        }),
      ]);

    return {
      document: {
        objectCode: OBJECT_OF_CODE.purchaseOrder, // 采购订单的code（是跨租户配置的自定义字段）
        customFields: crossCustomFieldsData?.map((item) => {
          return { ...item, fieldId: item.id }; // 补充这个字段
        }),
        sonObjects: [],
        subObjects: [],
      },
      material: {
        objectCode: OBJECT_OF_CODE.purchaseOrderItem, // 采购订单物料行的code（是跨租户配置的自定义字段）
        customFields: crossCustomFieldsItemData?.map((item) => {
          return { ...item, fieldId: item.id };
        }),
        sonObjects: [],
        subObjects: [],
      },
      customerId,
    };
  } catch (error) {
    console.log('error', error);
  }
};
