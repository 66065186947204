import React from 'react';
import TagQrCode from './TagQrCode';
import _Array from 'src/utils/dataTypes/array';
import { SuccessListType } from '../interface';

interface Props {
  data: SuccessListType[];
  singlePageNum: number;
}

class ComponentToPrint extends React.PureComponent<Props> {
  render() {
    const { data, singlePageNum } = this.props;

    return (
      <div>
        {!_Array.isEmpty(data) &&
          data.map((i: SuccessListType) => {
            return (
              <div
                style={{
                  pageBreakAfter: singlePageNum ? 'auto' : 'always',
                  display: singlePageNum ? 'inline-block' : 'block',
                }}
                key={i?.qrCode}
              >
                <TagQrCode value={i?.qrCode} />
              </div>
            );
          })}
      </div>
    );
  }
}

export default ComponentToPrint;
