/**
 * @description 对象属性值配置, 包装FormItem的HOC
 */
import { ReactNode } from 'react';
import { Form } from 'antd';
import _ from 'lodash';

export const getShouldUpdateFn = (namePathList: string[]) => (pre: any, cur: any) => {
  let update = false;

  for (let i = 0; i < namePathList?.length; i++) {
    const preValue = _.get(pre, namePathList[i]);
    const curValue = _.get(cur, namePathList[i]);

    if (!_.isEqual(preValue, curValue)) {
      update = true;
      break;
    }
  }
  return update;
};

type selectData = (data: any) => any;
const defaultSelectData: selectData = () => ({});

const withFormItemUpdate =
  (Component: ReactNode, selectData = defaultSelectData) =>
  ({ name, field, formItemPorps, rules, dependencies, needFormItem = true, ...restProps }: any) => {
    return (
      <Form.Item noStyle shouldUpdate={getShouldUpdateFn(dependencies)}>
        {(form) => {
          const dependenceValues = dependencies?.map((item: string) =>
            form.getFieldValue(item?.split('.')),
          );
          const params = form.getFieldsValue();

          return needFormItem ? (
            <Form.Item
              {...field}
              {...formItemPorps}
              rules={rules ?? formItemPorps?.rules}
              name={_.concat(field?.name, name)}
            >
              <Component
                {...restProps}
                {...selectData(params)}
                dependenceValues={dependenceValues ?? []}
                field={field}
                form={form}
              />
            </Form.Item>
          ) : (
            <Component
              {...restProps}
              {...selectData(params)}
              dependenceValues={dependenceValues ?? []}
              field={field}
              form={form}
            />
          );
        }}
      </Form.Item>
    );
  };

export default withFormItemUpdate;
