import { useEffect, useState } from 'react';
import { Form, message as Message } from 'antd';
import _ from 'lodash';

import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';

import {
  fetchPickOrderGetCreateGeneralPickOrderList,
  fetchPickOrderGeneralBatchCreate,
  FetchPickOrderGeneralBatchCreateRequest,
} from 'src/api/ytt/med-domain/store-requisition';
import { lookup } from 'src/dict';
import { CRUD } from 'src/dict/common';
import { PickOrderType } from 'src/dict/storeRequisition';
import { showErrorListMessage } from 'src/components/message/errorMessageList';
import { ErrorNotification } from 'src/components/notification';
import LocalStorage from 'src/utils/localStorage';
import { FIELDS } from 'src/utils/constants';
import { goBackToList } from '../utils';
import {
  GeneralPickOrderDataType,
  GeneralPickOrderLineType,
  GeneralTableRowDataType,
} from '../constants';
import CommonMaterialTable from '../components/StoreRequisitionTable/GeneralMaterialTable';

/**
 * 后台树形结构数据展开为表单需要的列表数据
 * 同时加上渲染需要的字段rowSpan，并转换sourceWarehouseId和targetWarehouseId(组件需要它们是LabledValue)
 */
const formatLinesData = (apiData: GeneralPickOrderDataType[]) => {
  const formattedLineData: GeneralTableRowDataType[] = [];

  apiData?.forEach((order) => {
    if (order.pickOrderLines) {
      order.pickOrderLines.forEach((orderLine: GeneralPickOrderLineType, idx: number) => {
        const {
          lineNo,
          sourceWarehouseId,
          sourceWarehouseName,
          sourceWarehouseCode,
          requestPickAmount,
          ...rest
        } = orderLine;

        formattedLineData.push({
          ...rest,
          code: order.code,
          line: lineNo,
          rowSpan: idx === 0 ? order.pickOrderLines.length : 0,
          sourceWarehouseId: !_.isNil(sourceWarehouseId)
            ? {
                label: sourceWarehouseName!,
                value: {
                  id: sourceWarehouseId,
                  name: sourceWarehouseName,
                  code: sourceWarehouseCode,
                },
              }
            : undefined,
          requestPickAmount,
          unit: { label: requestPickAmount.unitName, value: requestPickAmount.unitId! },
        });
      });
    }
  });
  return formattedLineData;
};

/**
 * 表单需要的列表数据转换为新建接口入参格式
 */
const formatRequestParam = (formData: GeneralTableRowDataType[]) => {
  const createParam: FetchPickOrderGeneralBatchCreateRequest['createPickOrderCOs'] = [];

  let currentCode: string;
  let remainCount = 0;
  let currentOrderIndex: number;

  formData.forEach((materialLine: GeneralTableRowDataType) => {
    const currentRequisitionIndex = _.findIndex(createParam, {
      pickOrderCode: materialLine.code,
    });
    const {
      code,
      sourceWarehouseId,
      targetWarehouseId,
      batchNo,
      rowSpan,
      requestPickAmount,
      material,
      unit,
      line,
      ...rest
    } = _.omit(materialLine, [
      'inputProcessCode',
      'inputProcessName',
      'inputProcessId',
      'productionDepartment',
      'sourceWarehouseName',
      'supplierList',
      'targetWarehouseName',
      'workCenter',
      'workOrderCode',
    ]);
    const materialObject = {
      ...rest,
      line: line!,
      sourceWarehouseId: sourceWarehouseId ? (sourceWarehouseId.value?.id as number) : undefined,
      targetWarehouseId: targetWarehouseId?.value?.id as number,
      batchCode: batchNo?.key
        ? {
            id: JSON.parse(batchNo?.key)?.id as number,
            code: JSON.parse(batchNo?.key)?.batchNo as string,
          }
        : undefined,
      requestPickAmount: String(requestPickAmount?.amount),
      unitId: unit?.value as number,
      materialId: material?.baseInfo?.id!,
    };

    if (currentRequisitionIndex < 0 && remainCount === 0) {
      remainCount = rowSpan!;
      currentCode = code!;
      createParam.push({
        pickOrderCode: currentCode!,
        pickOrderType: PickOrderType.common,
        materials: [materialObject],
      });
      currentOrderIndex = createParam.length - 1;
    } else {
      createParam[currentOrderIndex].materials.push(materialObject);
    }

    remainCount--;
  });

  return createParam;
};

// 传给form的设置
// preserve: 在删除字段时保留字段值
// antd的form会在rerender的时候删除所有字段，如果该字段为false，使用form全程不可以rerender
const formProps = {
  preserve: true,
};

// 表单字段名
const tableDataFormFieldName = 'materials';

// 创建结果表格列配置
const errorColumns = [
  {
    title: '领料申请编号',
    dataIndex: 'pickOrderCode',
  },
  {
    title: '失败原因',
    dataIndex: 'reason',
  },
];

/**
 * 新建领料申请
 * 领料申请是比较特殊的新建模式，只支持批量新建，即选中一些生产工单/用料清单/工序计划，由后台进行分单然后一起填写属性
 * 但在编辑/查看详情时，是单个编辑/查看的，因此领料申请的新建和编辑无法共用组件
 * */
export default function IntegratePick() {
  const [storeRequisitionListLoading, setStoreRequisitionListLoading] = useState(false);
  const [createForm] = Form.useForm();

  const dialogTitle = `${lookup('crud', CRUD.create)}齐套领料申请`;

  const tableInfo: DataFormLayoutInfoBlock[] = [
    {
      items: [
        {
          label: '',
          isFullLine: true,
          render: () => <CommonMaterialTable form={createForm} mode="integrate" />,
        },
      ],
    },
  ];

  /** 批量创建领料单并提示结果 */
  const createStoreRequisition = async () => {
    try {
      await createForm.validateFields();
      const { materials } = createForm.getFieldsValue();
      const createParam = formatRequestParam(materials);

      setStoreRequisitionListLoading(true);
      const { data } = await fetchPickOrderGeneralBatchCreate({ createPickOrderCOs: createParam });

      setStoreRequisitionListLoading(false);
      if (data) {
        const { failCount, successCount, failDetails } = data;

        // 全部新建成功，显示新建成功m
        if (failCount === 0) {
          Message.success('新建成功');
        } else if (successCount === 0) {
          // 全部新建失败，提示新建失败及原因
          if (failDetails) {
            ErrorNotification({
              message: '新建失败',
              description: (
                <>
                  {failDetails.map((fail) => (
                    <div key={fail.pickOrderCode}>{fail.reason}</div>
                  ))}
                </>
              ),
            });
          } else {
            Message.error('新建失败！');
          }
        } else if (failDetails) {
          // 否则用表格显示删除结果及原因统计的table
          showErrorListMessage({
            title: '新建出现错误',
            data: failDetails,
            columns: errorColumns,
            operateName: '新建',
            successCount,
            failCount,
          });
        }
      }
      goBackToList();
    } catch (e) {
      setStoreRequisitionListLoading(false);
      // console.log('e :>> ', e);
    }
  };

  useEffect(() => {
    const loadIntegrateOrders = async () => {
      try {
        const savedIntegratePickConfig = LocalStorage.get(FIELDS.INTEGRATE_PICK_CONFIG);
        const { pickingCalculateRange, suppliedCalculateRange, deadline } =
          savedIntegratePickConfig ?? {};

        setStoreRequisitionListLoading(true);
        const integrateOrders = await fetchPickOrderGetCreateGeneralPickOrderList({
          pickingCalculateRange,
          suppliedCalculateRange,
          deadline: new Date(deadline).valueOf(),
        });

        setStoreRequisitionListLoading(false);
        const { code, data } = integrateOrders;

        if (code === 200 && data) {
          const formattedDataSource = formatLinesData(data);

          createForm.setFieldsValue({ [tableDataFormFieldName]: formattedDataSource });
        }
      } finally {
        setStoreRequisitionListLoading(false);
      }
    };

    loadIntegrateOrders();
  }, []);

  return (
    <DataFormLayout
      form={createForm}
      info={tableInfo}
      title={dialogTitle}
      infoBlockStyleProps={{ padding: 0, margin: 0, borderBottom: 'none' }}
      onCancel={goBackToList}
      onFinish={createStoreRequisition}
      loading={storeRequisitionListLoading}
      formProps={formProps}
    />
  );
}
