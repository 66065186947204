import { findKey } from 'lodash';
import devEnvConfig from './env.json';

// @ts-ignore
const EnvConf: { RELEASE_INFO: any; BUILD_AT: string; COMMITHASH: string } = process.env.CONF;

const isBuildEnv = process.env.NODE_ENV === 'production';
const DefaultAPI = '/api';

const getCurrentEnv = (isBuildEnv: boolean) => {
  // 生产构建时环境,默认忽略 envSwitch 设置
  if (isBuildEnv) return 'prod';

  // 本地构建环境优先级： devEnvConfig 设置 > 'feature'
  return findKey(devEnvConfig) ?? 'feature';
};

export default {
  API: DefaultAPI,
  authPower: false,
  currentApiEnv: getCurrentEnv(isBuildEnv),
  releaseVersion: EnvConf.RELEASE_INFO,
  buildAt: EnvConf.BUILD_AT,
  commitHash: EnvConf.COMMITHASH,
};
