import { useState } from 'react';
import { SearchSelect } from 'src/components';
import { CustomDataFormLayout } from 'src/components/customLayout';
import { gcUrl } from 'src/utils';
import _ from 'lodash';
import lookup from 'src/dict';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { Form, Input } from 'antd';

interface baseInfoProps {
  onFinish: () => void;
  title: string;
  initialData?: any;
  refreshMarker?: number;
  edit: boolean;
  form: any;
  bizType?: number;
}

const CreateAndEditBaseInfo = (props: baseInfoProps) => {
  const { title, onFinish, initialData, form, edit, refreshMarker, bizType } = props;
  const { layoutId } = gcUrl.getParams();
  const [materialId, setMaterialId] = useState<number>();
  const [processId, setProcessId] = useState<number>();
  const [customerId, setCustomerId] = useState<number>();
  const [supplierId, setSupplierId] = useState<number>();
  const [resourceId, setResourceId] = useState<number>();

  const customInfoItems = [
    {
      label: '业务类型',
      name: 'checkType',
      render: () => {
        return (
          <Form.Item initialValue={'checkTypeValue'}>
            <Input disabled value={lookup('quality.checkType', bizType)} />
          </Form.Item>
        );
      },
    },
    {
      label: '检验方案',
      name: 'qcConfig',
      rules: [{ required: true, message: '检验方案必填' }],
      render: () => (
        <SearchSelect
          placeholder="请选择检验方案"
          fetchType={'qcAppConfigList'}
          disabled={edit}
          params={{ checkType: bizType, materialId, processId, customerId, resourceId, supplierId }}
        />
      ),
    },
    {
      label: '物料',
      name: 'material',
      rules: [{ required: true, message: '物料必填' }],
      render: () => (
        <SearchSelect
          onChange={(e: any) => {
            setMaterialId(e?.value);
            form.setFieldsValue({ qcConfig: '' });
            form.setFieldsValue({ batchNoId: '' });
          }}
          disabled={edit}
          placeholder="请选择物料"
          fetchType={'materialCodeName'}
        />
      ),
    },
    {
      label: '关联工序',
      name: 'process',
      rules: [{ required: true, message: '工序必填' }],
      render: () => (
        <SearchSelect
          disabled={edit}
          onChange={(e: any) => {
            setProcessId(e?.value);
            form.setFieldsValue({ qcConfig: '' });
          }}
          placeholder="请选择工序"
          fetchType={'process'}
        />
      ),
    },
    // 非必填
    {
      label: '关联客户',
      name: 'customer',
      render: () => (
        <SearchSelect
          onChange={(e: any) => {
            setCustomerId(e?.value);
            form.setFieldsValue({ qcConfig: '' });
          }}
          disabled={edit}
          placeholder="请选择关联客户"
          fetchType={'customer'}
        />
      ),
    },
    {
      label: '关联供应商',
      name: 'supplier',
      render: () => (
        <SearchSelect
          disabled={edit}
          onChange={(e: any) => {
            setSupplierId(e?.value);
            form.setFieldsValue({ qcConfig: '' });
          }}
          placeholder="请选择供应商"
          fetchType={'supplier'}
        />
      ),
    },
    {
      label: '关联设备',
      name: 'equipment',
      render: () => (
        <SearchSelect
          disabled={edit}
          onChange={(e: any) => {
            setResourceId(e?.value);
            form.setFieldsValue({ qcConfig: '' });
          }}
          placeholder="请选择设备"
          fetchType={'resource'}
        />
      ),
    },
    {
      label: '批次号',
      name: 'batchNoId',
      render: () => (
        <SearchSelect
          disabled={edit}
          placeholder="请选择批次号"
          fetchType={'batchNo'}
          params={materialId ? { materialIds: [materialId] } : {}}
        />
      ),
    },
    {
      label: '可领取人',
      name: 'candidates',
      render: () => (
        <UserOrDepartmentSelectWithDialog
          disabled={edit}
          placeholder="请选择可领取人"
          isMultiple
          isSelectUser
        />
      ),
    },
    {
      label: '可领取部门',
      name: 'candidateDeps',
      render: () => (
        <UserOrDepartmentSelectWithDialog
          disabled={edit}
          placeholder="请选择可领取部门"
          isMultiple
        />
      ),
    },
  ];

  return (
    <CustomDataFormLayout
      form={form}
      title={title}
      onFinish={onFinish}
      instanceIdParamKey={initialData?.id}
      objectCode={'QualityInspectionTask'}
      layoutId={layoutId}
      customInfoItems={customInfoItems}
    />
  );
};

export default CreateAndEditBaseInfo;
