import { useEffect, useState } from 'react';
import { Form, message as Message } from 'antd';
import { match, RouteComponentProps } from 'react-router-dom';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';
import EditBaseInfo from './editBaseInfo';
import {
  fetchCustomMadeReportCustomMadeReportModify,
  fetchCustomMadeReportQueryCustomMadeReportConfigDetailBack,
} from 'src/api/ytt/e-report-domain/customizedReports';

interface StoreEditProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const CustomizedReportEdit = (props: StoreEditProps) => {
  const { match, history } = props;
  const customizedReportId = Number(match?.params?.id);

  const [detailData, setDetailData] = useState({} as any);

  const [modalForm] = Form.useForm();

  const fetchData = async () => {
    try {
      const { data } = await fetchCustomMadeReportQueryCustomMadeReportConfigDetailBack({
        id: customizedReportId,
      });

      setDetailData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();

      const allowViewUserId = value?.allowViewUser?.map((item: any) => {
        return item.value;
      });

      const { code } = await fetchCustomMadeReportCustomMadeReportModify({
        allowViewUserId,
        id: customizedReportId,
        remark: value.remark,
      });

      if (code === 200) {
        Message.success('更新成功');

        history.goBack();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [customizedReportId]);

  return (
    <EditBaseInfo
      edit
      title="编辑报表"
      onCancel={() => {
        history.goBack();
      }}
      onFinish={throttleFn(handleFinish)}
      form={modalForm}
      initialData={detailData}
    />
  );
};

export default CustomizedReportEdit;
