export const goToDetail = (id: number) => {
  window.reactRouterHistoryInstance.push(`/resource/params/collectionRules/${id}/detail`);
};

export const goToCreate = () => {
  window.reactRouterHistoryInstance.push('/resource/params/collectionRules/create');
};

export const goToEdit = (id: number) => {
  window.reactRouterHistoryInstance.push(`/resource/params/collectionRules/${id}/edit`);
};

export const goToList = () => {
  window.reactRouterHistoryInstance.push('/resource/params/collectionRules');
};
