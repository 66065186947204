import { Modal, Form, Checkbox, Tooltip, message as Message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import {
  fetchCustomFieldVisibleGetByObjectCodes,
  fetchCustomFieldVisibleUpsert,
} from 'src/api/ytt/supply-chain-domain/custom_fields/index';

interface Props {
  /** 是否显示 */
  visible: boolean;
  onClose: () => void;
}
interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}
const SettingModal = (props: Props) => {
  const { visible, onClose } = props;
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [orderOptions, setOrderOptions] = useState<Option[]>();
  const [itemOptions, setItemOptions] = useState<Option[]>();
  const [form] = Form.useForm();
  const [orderSelectedOptions, setOrderSelectedOptions] = useState<CheckboxValueType[]>();
  const [itemSelectedOptions, setItemSelectedOptions] = useState<CheckboxValueType[]>();

  useEffect(() => {
    setModalShow(visible);
    if (visible) {
      fetchData();
    }
  }, [visible]);
  const fetchData = () => {
    fetchCustomFieldVisibleGetByObjectCodes({
      objectCodes: [OBJECT_OF_CODE.purchaseOrder, OBJECT_OF_CODE.purchaseOrderItem],
    }).then((res) => {
      const { data } = res;

      const orderCustomFields = data?.filter(
        (a: any) => a.customField.relatedObjectCode == OBJECT_OF_CODE.purchaseOrder,
      );
      const itemCustomFields = data?.filter(
        (a: any) => a.customField.relatedObjectCode == OBJECT_OF_CODE.purchaseOrderItem,
      );
      const orderOptions = orderCustomFields?.map((field: any) => {
        return {
          label: field.customField.fieldName,
          value: field.customField.id,
        };
      });
      const itemOptions = itemCustomFields?.map((field: any) => {
        return {
          label: field.customField.fieldName,
          value: field.customField.id,
        };
      });
      const orderSelected = orderCustomFields
        ?.filter((a: any) => a.visibleFlag == 1)
        .map((field: any) => field.customField.id);
      const itemSelected = itemCustomFields
        ?.filter((a: any) => a.visibleFlag == 1)
        .map((field: any) => field.customField.id);

      console.log(orderSelected);
      setOrderSelectedOptions(orderSelected);
      setItemSelectedOptions(itemSelected);
      setOrderOptions(orderOptions);
      setItemOptions(itemOptions);
      console.log(orderSelectedOptions, orderOptions);
    });
  };
  const onCancel = () => {
    onClose();
  };
  const confirmCallbock = () => {
    const orderParams =
      orderOptions?.map((option) => {
        return {
          fieldId: Number(option.value),
          objectCode: OBJECT_OF_CODE.purchaseOrder,
          visibleFlag: orderSelectedOptions?.includes(Number(option.value)) ? 1 : 0,
        };
      }) || [];
    const itemParams =
      itemOptions?.map((option) => {
        return {
          fieldId: Number(option.value),
          objectCode: OBJECT_OF_CODE.purchaseOrderItem,
          visibleFlag: itemSelectedOptions?.includes(Number(option.value)) ? 1 : 0,
        };
      }) || [];

    fetchCustomFieldVisibleUpsert([...orderParams, ...itemParams]).then(() => {
      Message.success('保存成功');
    });
    onClose();
  };
  const onOrderChange = (checkedValues: CheckboxValueType[]) => {
    setOrderSelectedOptions(checkedValues);
  };
  const onItemChange = (checkedValues: CheckboxValueType[]) => {
    setItemSelectedOptions(checkedValues);
  };

  const SearchForm = () => {
    return (
      <Form name="customFieldsSetting" form={form} onFinishFailed={() => {}} autoComplete="off">
        <Form.Item
          label="采购订单"
          initialValue={orderSelectedOptions}
          name={OBJECT_OF_CODE.purchaseOrder}
        >
          {orderOptions && orderOptions.length > 0 ? (
            <Checkbox.Group options={orderOptions} onChange={onOrderChange} />
          ) : (
            <div style={{ color: '#999' }}>未配置自定义字段</div>
          )}
        </Form.Item>
        <Form.Item
          label="采购订单行"
          initialValue={itemSelectedOptions}
          name={OBJECT_OF_CODE.purchaseOrderItem}
        >
          {itemOptions && itemOptions.length > 0 ? (
            <Checkbox.Group options={itemOptions} onChange={onItemChange} />
          ) : (
            <div style={{ color: '#999' }}>未配置自定义字段</div>
          )}
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      title="采购订单协同设置"
      visible={modalShow}
      centered
      onCancel={onCancel}
      onOk={confirmCallbock}
    >
      <div style={{ marginBottom: 16, fontWeight: 'bold' }}>
        供应商自定义字段可见性设置&nbsp;
        <Tooltip
          placement="topLeft"
          title="选中的自定义字段支持在供应商“协同采购申请单”、“销售订单”的协同信息中展示及查看"
          arrowPointAtCenter
        >
          <QuestionCircleOutlined />
        </Tooltip>
        ：
      </div>
      <SearchForm />
    </Modal>
  );
};

export default SettingModal;
