import React, { useEffect, useState } from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import styles from './style.module.scss';
import { Button } from 'antd';
import { fetchCustomMadeReportQueryCustomMadeReportConfigDetailBack } from 'src/api/ytt/e-report-domain/customizedReports';

interface Props extends RouteComponentProps {
  match: match<{ id: string }>;
}

const CustomizedReportReview = (props: Props) => {
  const { match } = props;
  const allowViewUserId = Number(match?.params?.id);

  const [reportUrl, setReportUrl] = useState<any>();

  const fetchData = async () => {
    try {
      const { data } = await fetchCustomMadeReportQueryCustomMadeReportConfigDetailBack({
        id: allowViewUserId,
      });

      if (data) setReportUrl(data.reportUrl);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [allowViewUserId]);

  return (
    <>
      <div className={styles.overallContainer}>
        <div
          className={styles.fullScreenButton}
          onClick={() => {
            const dashBoard = document.getElementById('fullScreen');

            if (dashBoard) dashBoard.requestFullscreen();
          }}
        >
          <Button style={{ width: 100, height: 40, fontSize: 20, borderRadius: 8 }} type="primary">
            全屏
          </Button>
        </div>
        {reportUrl && (
          <div id={'fullScreen'} className={styles.reportIframeDiv}>
            <iframe className={styles.report} src={`${reportUrl}`} />
          </div>
        )}
      </div>
    </>
  );
};

export default CustomizedReportReview;
