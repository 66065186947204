import { Checkbox, Button, Divider } from 'antd';
import { BlIcon } from 'src/components';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { useDispatch } from 'react-redux';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import authDict, { hasAuth } from 'src/utils/auth';

interface TreeBottomProps {
  onChangeCheck: (value: boolean) => void;
}

const TreeBottom = (props: TreeBottomProps) => {
  const { onChangeCheck } = props;
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  return (
    <div>
      <Checkbox onChange={(e) => onChangeCheck(e?.target?.checked)}>包含子部门</Checkbox>
      <Divider style={{ margin: 10 }} />
      <div style={{ marginBottom: 10, marginLeft: -10, marginRight: -10 }}>
        {hasAuth(authDict.department_import) && (
          <Button
            type="text"
            icon={<BlIcon type="icondaoru" />}
            onClick={() => {
              openModal({
                optType: 'import',
                businessType: BUSINESS_TYPE.dept,
              });
            }}
          >
            导入
          </Button>
        )}
        <Divider type="vertical" style={{ margin: 0 }} />
        {hasAuth(authDict.department_export) && (
          <Button
            type="text"
            icon={<BlIcon type="icondaochu" />}
            onClick={() => {
              dispatch.excelImport.updateBusinessData({ businessData: {} });
              openModal({
                optType: 'export',
                businessType: BUSINESS_TYPE.dept,
              });
            }}
          >
            导出
          </Button>
        )}
        <Divider type="vertical" style={{ margin: 0 }} />
        {/* <Button type="text" icon={<SolutionOutlined />}>
          操作日志
        </Button> */}
      </div>
    </div>
  );
};

export default TreeBottom;
