import { replaceSign } from 'src/utils/constants';
import { FetchPurchaseOrderDetailResponseData } from './interface';
import { isUndefined, compact } from 'lodash';
import moment from 'moment';

const renderAmount = (amount: any) => {
  return amount ? amount?.amountDisplay + amount?.unitName : replaceSign;
};

const renderCurrency = (currency: any) => {
  return currency ? currency?.name : replaceSign;
};

/**
 * 新建采购订单 - initEditData
 */
const initEditData = (data: FetchPurchaseOrderDetailResponseData) => {
  const { supplier, currency } = data;

  return {
    ...data,
    supplier: {
      ...supplier,
      label: supplier?.name,
      value: supplier?.id,
    },
    currency: currency
      ? {
          ...currency,
          label: currency?.name,
          value: JSON.stringify(currency),
        }
      : null,
  };
};

const renderTotalPrice = (price: string, accuracy: number) => {
  if (isUndefined(price)) {
    return '-';
  }
  const val = String(Number(price).toFixed(accuracy)).split('.');

  const num = compact([Number(val[0]).toLocaleString(), val[1]]).join('.');

  return num;
};

const formatDetailItemDataToForm = (values: any) =>
  values?.map((item: any) => {
    return {
      seqNum: String(item?.seqNum),
      materialId: {
        value: JSON.stringify({
          inventoryAmount: item.inventoryAmount,
          purchaseCycle: item.purchaseCycle,
          purchaseInfo: item.material?.purchaseInfo,
          materialId: item.material?.baseInfo?.id,
          unitList: item.material?.unitList,
        }),
        label: item?.material?.baseInfo?.name,
      },
      demandTime: moment(item?.demandTime),
      demandAmount: item?.demandAmount?.amount,
      id: item?.id,
      customFields: item?.customFields,
      unitPrice: item?.unitPrice?.price,
      taxRate: item?.taxRate,
      totalPrice: item?.totalPrice?.price,
      bomVersion: {
        label: item?.bomVersion,
        value: item?.bomVersion,
        key: item?.bomVersion,
      },
    };
  });

const formatDetailOutItemDataToForm = (values: any) =>
  values?.map((item: any) => {
    const {
      id,
      purchaseOrderItemId,
      purchaseOrderItemLineNo,
      seq,
      material,
      demandAmount,
      inputAmountNumerator,
      inputAmountDenominator,
      standardDosage,
      lossRate,
      customFields,
    } = item;

    return {
      id,
      purchaseOrderItemId,
      purchaseOrderItemLineNo,
      seq,
      material,
      demandAmount,
      inputAmountNumerator,
      inputAmountDenominator,
      standardDosage,
      unit: material?.unit,
      lossRate,
      customFields,
    };
  });

export {
  renderAmount,
  initEditData,
  renderCurrency,
  renderTotalPrice,
  formatDetailItemDataToForm,
  formatDetailOutItemDataToForm,
};
