import { createModel } from '@rematch/core';
import { RootModel } from 'src/store/models/index';
import LocalStorage from 'src/utils/localStorage';
import * as constants from 'src/utils/constants';
import { fetchUserDetail, fetchUserPrivilegeInfo } from 'src/api/ytt/user-domain';
import _ from 'lodash';
import sentry from 'src/utils/sentry';
import qs from 'qs';
import envConfig from 'src/configs/envConfig';
import authDict from 'src/utils/auth';
import volcano from 'src/utils/volcano';
import Cookies from 'js-cookie';
import { blacklakeDomain } from 'src/utils/constants';

export const user = createModel<RootModel>()({
  state: {
    userInfo: LocalStorage.get(constants.FIELDS.USER) || {},
    privilegeInfo: LocalStorage.get(constants.FIELDS.AUTH) || [],
    token: LocalStorage.get(constants.FIELDS.TOKEN_NAME),
    /** 用来判断当前是否需要强制退出的标识状态 */
    needLogout: false,
  },
  reducers: {
    setUser(state, { userInfo, token, privilegeInfo }) {
      return { ...state, userInfo, token, privilegeInfo, needLogout: false };
    },
    needLogout(state) {
      return { ...state, needLogout: true };
    },
    setUserInfo(state, userInfo) {
      return { ...state, userInfo };
    },
    clearUser() {
      const { href, pathname } = window.location;

      LocalStorage.remove(constants.FIELDS.TOKEN_NAME); // 删除token 信息
      LocalStorage.remove(constants.FIELDS.AUTH); // 删除用户信息
      LocalStorage.remove(constants.FIELDS.USER);
      Cookies.remove('gc_x_token', { domain: blacklakeDomain });

      if (pathname !== '/login') {
        const loginPath = `/login${qs.stringify({ pre_path: href }, { addQueryPrefix: true })}`;

        window.reactRouterHistoryInstance && window.reactRouterHistoryInstance.push(loginPath);
      }

      return { userInfo: {}, privilegeInfo: [], token: [], needLogout: false };
    },
  },
  effects: (dispatch) => ({
    async getUserInfo(payload): Promise<any> {
      try {
        const [detailInfo, res] = await Promise.all(
          _.compact([fetchUserDetail({}), !envConfig.authPower && fetchUserPrivilegeInfo()]),
        );
        const userInfo: any = _.get(detailInfo, 'data');
        let privilegeInfo = [];

        if (envConfig.authPower) {
          privilegeInfo = _.values(authDict);
        } else {
          const privilegeListVOSet = _.get(res, 'data.privilegeListVOSet', []) || [];
          const orgLicenseList = _.get(res, 'data.orgLicenseList', []) || [];

          privilegeInfo = _.map(privilegeListVOSet.concat(orgLicenseList), (i) => i.code);
        }

        LocalStorage.set(constants.FIELDS.AUTH, privilegeInfo);

        if (userInfo) {
          LocalStorage.set(constants.FIELDS.USER, userInfo);
          /** 火山登录态设置UUID */
          volcano.setUUID(userInfo?.id, userInfo?.orgId);
          volcano.setConfig(userInfo);
          sentry.setUser(userInfo as any);
        }

        dispatch({
          type: 'user/setUser',
          payload: { userInfo: userInfo || {}, privilegeInfo, token: payload?.token },
        });
        return userInfo || {};
      } catch (e) {
        // @ts-ignore
        console.log(e);
      }
    },
  }),
});
