/*
 * @Author: huangsijia
 * @Date:  2021-11-24 15:20:09
 * @LastEditTime: 2022-02-25 14:09:34
 * @LastEditors: huangsijia
 * @Description: 不良等级抽屉详情
 * @FilePath: /bf-main-3/src/page/quality/qcDefectRank/components/QcDefectRankDrawer.tsx
 */

import { DetailLayoutInfoBlock } from 'layout';
import { Space } from 'antd';
import _Time from 'src/utils/dataTypes/time';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import { replaceSign } from 'src/utils/constants';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { DetailDataType } from '../interface';
import authDict from 'src/utils/auth';
import { match, RouteComponentProps, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchQcDefectRankDetail } from 'src/api/ytt/quality-domain/qc_defect_rank';
import { CustomDetailLayout } from 'src/components/customLayout';
import { gcUrl } from 'src/utils';
import { customLayoutChooseButton } from 'src/components/customLayout/hooks/customLayoutForButton';

type CreatorData = Required<DetailDataType>['operator'];
interface DetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const QcDefectRankPage = (props: DetailProps) => {
  const { match } = props;
  const detailId = Number(match?.params?.id) ?? 0;
  const { layoutId } = gcUrl.getParams();
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [modalRecord, setModalRecord] = useState<DetailDataType>({} as any);
  const history = useHistory();

  const fetchData = async () => {
    try {
      const { data } = await fetchQcDefectRankDetail({ id: detailId });

      setModalRecord(data as DetailDataType);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [detailId]);

  const baseMenu = [
    // {
    //   key: 'delete',
    //   title: '删除',
    //   icon: <BlIcon type="iconshanchulajitong1" />,
    //   onClick: () => {
    //     BLDelConfirm(
    //       delMsg,
    //       delForm,
    //       () => {
    //         typeof onDelete === 'function' && onDelete();
    //         // typeof onSuccess === 'function' && onSuccess();
    //       },
    //       // onFail,
    //     );
    //   },
    // },
    {
      key: 'look',
      title: '操作记录',
      icon: 'iconcaozuojilu',
      auth: authDict.defectlevel_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
    },
    {
      key: 'edit',
      title: '编辑',
      icon: <BlIcon type="iconbianji" />,
      auth: authDict.defectlevel_edit,
      buttonRender: customLayoutChooseButton({
        title: '编辑',
        objectCode: 'DefectLevel',
        type: 'edit',
        instanceId: detailId,
        buttonType: 'primary',
        icon: <BlIcon type="iconbianji" />,
        onClick: (layoutId: number) => {
          history.push(
            `/quality/qualityModeling/qcDefectRank/${detailId}/edit?layoutId=${layoutId}`,
          );
        },
      }),
    },
  ];

  return (
    <>
      <CustomDetailLayout
        objectCode={'DefectLevel'}
        dataSource={modalRecord}
        baseMenu={baseMenu}
        title="不良等级详情"
        layoutId={layoutId}
        instanceIdParamKey={detailId}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={'DefectLevel'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default QcDefectRankPage;
