import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import { Badge, message as Message } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  fetchDigitalSignatureDetail,
  FetchDigitalSignatureDetailResponse,
} from 'src/api/ytt/user-domain/eSignature';
import { BlIcon } from 'src/components';
import UserTooltip from 'src/components/tooltip/userTooltip';
import lookup from 'src/dict';
import { ACTIVE_STATUS_STR } from 'src/dict/eS-M';
import DetailSignConfigList from './components/DetailSignConfigList';
import { UserType } from './type';

type ESignatureDetailProps = {
  id: number;
  editESignature: (id: number) => void;
  onActive: (ids: number[]) => void;
  onDeActive: (ids: number[]) => void;
};

const ESignatureDetail: React.FC<ESignatureDetailProps> = (props) => {
  const { id, editESignature, onActive, onDeActive } = props;
  const [dataSource, setDataSource] = useState({} as any);

  /**
   * 获取电子签名详情
   * @param id 电子签名ID
   */
  const fetchDetailById = async (id: number) => {
    const res: FetchDigitalSignatureDetailResponse = await fetchDigitalSignatureDetail({ id });

    const { code, message, data } = res;

    if (code !== 200) {
      Message.error(message);
      return;
    }
    setDataSource(data);
  };

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      { label: '电子签名名称', dataIndex: 'name', isFullLine: true },
      { label: '所属对象', dataIndex: 'relatedObjectName', isFullLine: true },
      { label: '功能点', dataIndex: 'functionName', isFullLine: true },
      {
        label: '启用状态',
        dataIndex: 'activeStatus',
        isFullLine: true,
        render: (activeStatus: number) => {
          return (
            <Badge
              status={activeStatus === ACTIVE_STATUS_STR.enabled ? 'success' : 'error'}
              text={lookup('esm.activeStatusStrEnum', activeStatus)}
            />
          );
        },
      },
      { label: '备注', dataIndex: 'remark', isFullLine: true },
    ],
    column: 2,
  };

  const signatureConfig: DetailLayoutInfoBlock = {
    title: '签名人配置',
    items: [
      {
        label: '签名规则',
        dataIndex: 'ruleType',
        isFullLine: true,
        render: (ruleType: number) => <div>{lookup('esm.ruleTypeEnum', ruleType)}</div>,
      },
      {
        label: '',
        dataIndex: 'digitalSignatureConfigVOList',
        isFullLine: true,
        render: (list: any[]) => {
          return <DetailSignConfigList signConfigList={list} />;
        },
      },
    ],
    column: 1,
  };

  const others: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        isFullLine: true,
        render: (creator: UserType) => (creator ? <UserTooltip {...creator} /> : null),
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        isFullLine: true,
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        isFullLine: true,
        render: (operator: UserType) => (operator ? <UserTooltip {...operator} /> : null),
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        isFullLine: true,
      },
    ],
    column: 2,
  };

  const baseMenu = [
    {
      key: 'enable',
      onClick: () => {
        if (dataSource.activeStatus === ACTIVE_STATUS_STR.enabled) {
          onDeActive([id]);
        } else {
          onActive([id]);
        }
        fetchDetailById(id);
      },
      icon:
        dataSource.activeStatus === ACTIVE_STATUS_STR.enabled ? (
          <BlIcon type="icontingyong" />
        ) : (
          <BlIcon type="iconqiyong" />
        ),
      title: dataSource.activeStatus === ACTIVE_STATUS_STR.enabled ? '停用' : '启用',
    },
    {
      key: 'edit',
      onClick: () => {
        editESignature(id);
      },
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ];

  useEffect(() => {
    if (id) {
      fetchDetailById(id);
    }
  }, [id]);

  return (
    <>
      <DetailLayout
        title={'电子签名详情'}
        baseMenu={baseMenu}
        info={[baseInfo, signatureConfig, others]}
        dataSource={dataSource}
      />
    </>
  );
};

export default ESignatureDetail;
