import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Modal, Tabs } from 'antd';
import {
  UseFieldSelectContent,
  FunctionAuthority,
  DataPrivilege,
} from 'src/page/organization/components';
import _ from 'lodash';
import { BlIcon } from 'components';
import styles from '../role.module.scss';

enum TabType {
  func = '1',
  data = '3',
  field = '4',
}

type usePrivilegeContentType = (props: {
  roleId?: any;
  disabled?: boolean;
  getAllTabStatus?: (status: any) => void;
}) => React.ReactNode;
const usePrivilegeContent: usePrivilegeContentType = (props) => {
  const { roleId, disabled, getAllTabStatus } = props;
  const ref = useRef<any>({});
  const [activeKey, setActiveKey] = useState<string>(TabType.func);

  const showDeleteConfirm = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      const disableSave = _.get(ref.current, `${activeKey}.disableSave`);

      if (disableSave) {
        resolve();
        return;
      }
      Modal.confirm({
        icon: <BlIcon type="iconmianxingtixing-jingshi" />,
        title: '当前页面编辑信息尚未保存，确认关闭?',
        okText: '去保存',
        cancelText: '切换',
        onOk() {
          reject();
        },
        onCancel() {
          resolve();
        },
      });
    });
  }, [activeKey]);

  useEffect(() => {
    if (_.isFunction(getAllTabStatus)) {
      getAllTabStatus(ref.current);
    }
  }, [getAllTabStatus]);

  return useMemo(() => {
    return (
      <div className={styles.tabPanel}>
        <Tabs
          type="card"
          activeKey={activeKey}
          style={{ width: '100%' }}
          onChange={(key: string) => showDeleteConfirm().then(() => setActiveKey(key))}
        >
          <Tabs.TabPane tab="功能权限" key={TabType.func}>
            {
              <FunctionAuthority
                roleId={roleId}
                disabled={disabled}
                title="设置该角色在当前页面及其子页面下的WEB功能按钮权限"
                getDataSource={({ disableSave }) => {
                  ref.current[TabType.func] = { disableSave };
                }}
              />
            }
          </Tabs.TabPane>
          <Tabs.TabPane tab="字段权限" key={TabType.field}>
            <UseFieldSelectContent
              disabled={disabled}
              roleId={roleId}
              getDataSource={({ disableSave }) => {
                ref.current[TabType.field] = { disableSave };
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="数据权限" key={TabType.data}>
            {roleId && (
              <DataPrivilege
                disabled={disabled}
                title="设置该角色在当前对象下的数据可见范围"
                roleId={roleId}
                getDataSource={({ disableSave }) => {
                  ref.current[TabType.data] = { disableSave };
                }}
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }, [activeKey, disabled, roleId, showDeleteConfirm]);
};

export default usePrivilegeContent;
