import { Space } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import { CreatorData } from 'src/page/knowledgeManagement/calendarManagement/shift/interface';
import { replaceSign } from 'src/utils/constants';
import _Array from 'src/utils/dataTypes/array';
import _Time from 'src/utils/dataTypes/time';
import { OPERATION_TERMINAL_TYPE } from '../constant';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { BlTable } from 'src/components';
import SubObjectInfo from './SubObjectInfo';
import _String from 'src/utils/dataTypes/string';
import { DetailFields, DetailType, TableRowType } from '../interface';

interface DetailBaseInfoProps {
  detail: DetailType;
}

export const BaseInfo = ({ detail }: DetailBaseInfoProps) => {
  const operationColumns = [
    {
      title: '编辑字段',
      dataIndex: 'fieldName',
      width: 300,
      render: (fieldName: string) => fieldName ?? replaceSign,
    },
    {
      title: '修改前数据',
      dataIndex: 'fieldValueFrom',
      width: 500,
      render: (fieldValueFrom: string) => fieldValueFrom ?? replaceSign,
    },
    {
      title: '修改后数据',
      dataIndex: 'fieldValueTo',
      width: 500,
      render: (fieldValueTo: string) => fieldValueTo ?? replaceSign,
    },
  ];

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '对象名称',
        dataIndex: 'objectDisplayName',
        render: (objectDisplayName: string) => {
          if (_String.isEmpty(objectDisplayName)) return replaceSign;
          return objectDisplayName;
        },
      },
      {
        label: '对象实例编号',
        dataIndex: 'instanceCode',
        render: (instanceCode: string) => {
          if (_String.isEmpty(instanceCode)) return replaceSign;
          return instanceCode;
        },
      },
      {
        label: '对象实例名称',
        dataIndex: 'instanceName',
        render: (instanceName: string) => {
          if (_String.isEmpty(instanceName)) return replaceSign;
          return instanceName;
        },
      },
      {
        label: '操作时间',
        dataIndex: 'loggedAt',
        render: (loggedAt: Date) => {
          if (!loggedAt) return replaceSign;
          return _Time.format(loggedAt);
        },
      },
      {
        label: '操作类型',
        dataIndex: 'operationName',
      },
      {
        label: '操作终端',
        dataIndex: 'sourceType',
        render: (sourceType: number) => OPERATION_TERMINAL_TYPE.get(sourceType) || replaceSign,
      },
      {
        label: '操作原因',
        dataIndex: 'operationReason',
        render: (operationReason: string) => {
          if (_String.isEmpty(operationReason)) return replaceSign;
          return operationReason;
        },
      },
      {
        label: '电子签名',
        dataIndex: 'signList',
        render: (signList: CreatorData[]) => {
          if (_Array.isEmpty(signList)) return replaceSign;
          return (
            <Space>
              {signList?.map((manager: CreatorData) => (
                <AvatarDisplay
                  id={manager.id}
                  name={manager.name}
                  avatarUrl={manager.avatarUrl}
                  key={manager.id}
                  isShowTag={false}
                  isUser
                />
              ))}
            </Space>
          );
        },
      },
    ],
    column: 2,
  };

  const operationInfo: DetailLayoutInfoBlock = {
    title: '操作详情',
    items: [
      {
        label: '',
        dataIndex: 'detailFields',
        render: (detailFields: DetailFields[]) => (
          <BlTable
            columns={operationColumns}
            dataSource={detailFields}
            pagination={false}
            scroll={{ y: 560 }}
            style={{ marginLeft: 24, marginRight: 24 }}
          />
        ),
      },
    ],
    column: 2,
  };

  const subObjectInfo: DetailLayoutInfoBlock = {
    title: '从对象日志',
    items: [
      {
        label: '',
        dataIndex: 'subObjectList',
        render: (subObjectList: TableRowType[]) => <SubObjectInfo data={subObjectList} />,
      },
    ],
    column: 2,
  };

  return (
    <DetailLayout
      title="操作日志详情"
      info={[baseInfo, operationInfo, subObjectInfo]}
      dataSource={detail}
    />
  );
};
