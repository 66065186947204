import { Form, Input, InputNumber, Select, Space } from 'antd';
import _ from 'lodash';
import { CheckItemInputTypeEnum, CheckValueTypeEnum } from 'src/dict/quality';
import { percentCheck } from 'src/page/custom_fields/fieldsList/components/verificationRules/verificationRules';
import {
  DEFAULT_PRECISION,
  getDefaultScale,
  LOGIC,
  NUMBER_SCALE,
} from 'src/page/quality/models/constants';
import { numberCheck, numberMinMaxCheck, validatorNumberDigtis } from 'src/utils/formValidators';
import styles from 'src/page/quality/qcTask/styles.module.scss';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

interface Props {
  executeItemType?: number;
  scale?: number;
  radios?: string[];
  fieldName: (number | string)[];
  checkValueType?: number;
  isUnQuality?: boolean;
  isRequired?: boolean;
  onChange?: () => void;
}

export const InputRender = forwardRef((props: Props, ref) => {
  const { checkValueType, executeItemType, scale, radios, fieldName, isRequired, onChange } = props;
  const [valueMin, setValueMin] = useState<number>();
  const [valueMax, setValueMax] = useState<number>();
  const [isUnQuality, setIsUnQuality] = useState<boolean>(Boolean(props?.isUnQuality));
  const singleInputWidth = 227;
  const betweenInputWidth = 100;

  useEffect(() => {
    setIsUnQuality(Boolean(props?.isUnQuality));
  }, [props?.isUnQuality]);
  const baseRule = _.compact([
    isRequired && {
      required: true,
      message: '当前检验项实测值必填',
    },
  ]);

  const getBaseNumberRules = () => {
    return _.compact([
      ...baseRule,
      {
        validator: validatorNumberDigtis(scale || getDefaultScale(Number(executeItemType))),
      },
      executeItemType === CheckItemInputTypeEnum.inputFormatRecordDefectCount && {
        validator: numberMinMaxCheck({ min: 0 }),
      },
    ]);
  };

  const getNumberInput = () => {
    if (checkValueType === CheckValueTypeEnum.between) {
      return (
        <Space>
          <Form.Item
            label=""
            name={[...fieldName, 'valueMin']}
            className={styles.formulaInput}
            rules={[
              ...getBaseNumberRules(),
              { validator: numberMinMaxCheck({ max: valueMax, message: '最小值必须小于最大值' }) },
            ]}
            style={{ margin: 'auto' }}
            dependencies={[...fieldName, 'valueMax']}
          >
            <InputNumber
              placeholder="最小值"
              style={{ width: betweenInputWidth, color: isUnQuality ? 'red' : '' }}
              onChange={(value) => {
                setValueMin(Number(value));
                onChange?.();
              }}
            />
          </Form.Item>
          {executeItemType === CheckItemInputTypeEnum.inputFormatPercent && (
            <span style={{ lineHeight: '32px' }}>%</span>
          )}
          <span>~</span>
          <Form.Item
            label=""
            name={[...fieldName, 'valueMax']}
            className={styles.formulaInput}
            style={{ margin: 'auto' }}
            rules={[
              ...getBaseNumberRules(),
              { validator: numberMinMaxCheck({ min: valueMin, message: '最大值必须大于最小值' }) },
            ]}
          >
            <InputNumber
              placeholder="最大值"
              style={{ width: betweenInputWidth, color: isUnQuality ? 'red' : '' }}
              onChange={(value) => {
                setValueMax(Number(value));
                onChange?.();
              }}
            />
          </Form.Item>
          {executeItemType === CheckItemInputTypeEnum.inputFormatPercent && (
            <span style={{ lineHeight: '32px' }}>%</span>
          )}
        </Space>
      );
    }

    return (
      <Space>
        <Form.Item
          name={[...fieldName, 'value']}
          rules={[...getBaseNumberRules()]}
          style={{ margin: 'auto' }}
        >
          <InputNumber
            placeholder="实测值"
            onChange={() => onChange?.()}
            style={{ width: singleInputWidth, color: isUnQuality ? 'red' : '' }}
          />
        </Form.Item>
        {executeItemType === CheckItemInputTypeEnum.inputFormatPercent && (
          <span style={{ lineHeight: '32px' }}>%</span>
        )}
      </Space>
    );
  };

  useImperativeHandle(ref, () => ({
    refresh: (isUnQuality: boolean) => {
      setIsUnQuality(isUnQuality);
    },
  }));

  switch (executeItemType) {
    case CheckItemInputTypeEnum.inputFormatText:
      return (
        <Form.Item
          name={[...fieldName, 'value']}
          rules={[...baseRule, { max: 255, message: '文本长度不能超过255' }]}
          style={{ margin: 'auto' }}
        >
          <Input
            onChange={() => onChange?.()}
            maxLength={255}
            style={{ width: singleInputWidth, color: isUnQuality ? 'red' : '' }}
          />
        </Form.Item>
      );
    case CheckItemInputTypeEnum.multipleText:
      return (
        <Form.Item
          name={[...fieldName, 'value']}
          rules={[...baseRule, { max: 1000, message: '文本长度不能超过1000' }]}
          style={{ margin: 'auto' }}
        >
          <Input.TextArea
            onChange={() => onChange?.()}
            maxLength={1000}
            style={{ width: singleInputWidth, color: isUnQuality ? 'red' : '' }}
          />
        </Form.Item>
      );
    case CheckItemInputTypeEnum.inputFormatNum:
    case CheckItemInputTypeEnum.inputFormatPercent:
      return getNumberInput();
    case CheckItemInputTypeEnum.inputFormatRecordDefectCount:
      return (
        <Form.Item
          name={[...fieldName, 'value']}
          rules={_.compact([...getBaseNumberRules()])}
          style={{ margin: 'auto' }}
        >
          <InputNumber
            onChange={() => onChange?.()}
            min={0}
            style={{ width: singleInputWidth, color: isUnQuality ? 'red' : '' }}
          />
        </Form.Item>
      );
    case CheckItemInputTypeEnum.inputFormatMultiple:
      return (
        <Form.Item name={[...fieldName, 'value']} style={{ margin: 'auto' }} rules={baseRule}>
          <Select
            mode="multiple"
            options={radios?.map((i) => ({ label: i, value: i }))}
            onChange={() => onChange?.()}
            allowClear
            style={{ width: singleInputWidth, color: isUnQuality ? 'red' : '' }}
          />
        </Form.Item>
      );
    case CheckItemInputTypeEnum.inputFormatSingle:
      return (
        <Form.Item
          name={[...fieldName, 'value']}
          style={{ margin: 'auto', color: isUnQuality ? 'red' : '' }}
          rules={baseRule}
        >
          <Select
            options={radios?.map((i) => ({ label: i, value: i }))}
            onChange={() => onChange?.()}
            style={{ width: singleInputWidth, color: isUnQuality ? 'red' : '' }}
            showSearch
            allowClear
          />
        </Form.Item>
      );
    default:
      return (
        <Form.Item name={[...fieldName, 'value']} style={{ margin: 'auto' }} rules={baseRule}>
          <Input style={{ width: singleInputWidth, color: isUnQuality ? 'red' : '' }} />
        </Form.Item>
      );
  }
});
