import { useEffect } from 'react';
import { DataFormLayout, DataFormLayoutForModal, DataFormLayoutInfoBlock } from 'layout';

import { STATUS_TYPE } from '../../constant';
import { SubTemplateFileInfoListDetailType } from '../../interface';
import { FormInstance, Input, Button, Form } from 'antd';
import { BcUpload, BlIcon } from 'src/components';
import CptRelatedParam from './cptRelatedParam';
import { map, filter } from 'lodash';
import lookup, { appEnum } from 'src/dict';
import { checkFormSpecialCheck } from 'src/utils/formValidators';
import { fetchEReportQueryTemDataSetListNoStatus } from 'src/api/ytt/e-report-domain/customReport';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onFinish: () => void;
  addForm: FormInstance;
  initLineData?: SubTemplateFileInfoListDetailType;
  operationType: string;
}

export default function AddSetTemplateFields({
  visible,
  onFinish,
  onCancel,
  addForm,
  initLineData,
  operationType,
}: Props) {
  const templateId = Number(localStorage.getItem('templateId'));

  const getDataNameSerialNumber = (temDataSetId: number, templateDataSetInfoList: any[]) => {
    // 由于后端不感知 序号。所以需要在回显关联参数的数据集的序号时，根据templateDataSetId去遍历找到该数据集对应的serialNumber
    const currentData = filter(
      templateDataSetInfoList,
      (e: any) => e?.templateDataSetId === temDataSetId,
    )?.[0];

    return currentData?.serialNumber < 10
      ? `0${currentData?.serialNumber}_${currentData?.dataSetName}`
      : `${currentData?.serialNumber}_${currentData?.dataSetName}`;
  };

  const formatCptParam = (cptRelatedParam: any[], templateDataSetInfoList: any[]) => {
    const newCptRelatedParam = map(cptRelatedParam, (node: any) => {
      return {
        dataSetId: {
          key: node?.temDataSetId,
          label: getDataNameSerialNumber(node?.temDataSetId, templateDataSetInfoList),
          value: node?.temDataSetId,
        },
        fieldId: {
          label: node?.fieldDisplayName,
          key: node?.fieldName,
          value: JSON.stringify(node),
        },
        condition: node?.condition,
      };
    });

    return newCptRelatedParam;
  };

  const initFormData = async () => {
    try {
      const { data } = await fetchEReportQueryTemDataSetListNoStatus({ templateId }); // 编辑的时候需要获取数据集列表的数据 去匹配serialNumber

      if (data && initLineData) {
        addForm.setFieldsValue({
          fileId: [initLineData?.fileId],
          cptVersion: initLineData?.cptVersion,
          cptRelatedParam: formatCptParam(
            initLineData?.cptRelatedParam ?? [],
            data?.templateDataSetInfoList ?? [],
          ),
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (operationType === appEnum.Common.CRUD.create) {
      // 新增需要清空form,子模板文件所有数据
      addForm.resetFields();
    } else {
      initFormData(); // 编辑初始化回显
    }
  }, [initLineData, operationType]);

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,
    items: [
      {
        label: '模板文件',
        name: 'fileId',
        isFullLine: true,
        rules: [{ required: true, message: '模板文件必上传' }],
        render: () => (
          <BcUpload
            totalMaxSize={3}
            accept={'.cpt'}
            autoDelErrorFile
            draggable={false}
            maxCount={1}
          >
            <Button icon={<BlIcon type="iconshangchuan1" />}>上传cpt模版文件</Button>
          </BcUpload>
        ),
      },
      {
        label: '版本号',
        name: 'cptVersion',
        isFullLine: true,
        rules: [
          { required: true, message: '版本号必填' },
          { max: 20, message: '不超过20个字符' },
          checkFormSpecialCheck,
        ],
        validateFirst: true,
        render: () => <Input style={{ width: '33%' }} placeholder="请输入" />,
      },
      {
        label: '模板状态',
        isFullLine: true,
        render: () => {
          return operationType === appEnum.Common.CRUD.create
            ? '停用中'
            : STATUS_TYPE.get(initLineData?.subTemplateStatus || 0);
        },
      },
      {
        label: '关联参数',
        isFullLine: true,
        required: true,
        render: () => (
          <div style={{ width: '70%' }}>
            <Form.Item
              rules={[{ required: true, message: '请至少添加一条关联参数' }]}
              name="cptRelatedParam"
            >
              <CptRelatedParam form={addForm} />
            </Form.Item>
          </div>
        ),
      },
    ],
  };

  return (
    <>
      <DataFormLayoutForModal
        visible={Boolean(visible)}
        onClose={onCancel}
        width={1100}
        content={
          <DataFormLayout
            title={`${lookup('common.crud', operationType)}模板文件`}
            info={[baseInfo]}
            form={addForm}
            formLayout={'horizontal'}
            onFinish={throttleFn(onFinish)}
            onCancel={onCancel}
          />
        }
      />
    </>
  );
}
