import { CheckItemInputTypeEnum, CheckValueTypeEnum } from 'src/dict/quality';
import { LOGIC } from '../constants';
import { CheckItem } from '../dto';

export const isUnQuality = ({ result, checkItem }: { result: any; checkItem: any }) => {
  const { value, option, valueMin, valueMax } = result;
  const { logic, base, min, max, checkValueType, qualityItems, executeItemType } = checkItem;
  const isRecordDefect = executeItemType === CheckItemInputTypeEnum.inputFormatRecordDefectCount;

  if (
    executeItemType === CheckItemInputTypeEnum.inputFormatNum ||
    executeItemType === CheckItemInputTypeEnum.inputFormatPercent
  ) {
    switch (logic) {
      case LOGIC.BETWEEN: {
        if (checkValueType === CheckValueTypeEnum.between) {
          return Number(valueMin) < min || Number(valueMax) > max;
        }
        return Number(value) < min || Number(value) > max;
      }
      case LOGIC.EQ: {
        return Number(value) !== base;
      }
      case LOGIC.GT: {
        return Number(value) <= base;
      }
      case LOGIC.GTE: {
        return Number(value) < base;
      }
      case LOGIC.LT: {
        return Number(value) >= base;
      }
      case LOGIC.LTE: {
        return Number(value) > base;
      }
      case LOGIC.TOLERANCE: {
        if (checkValueType === CheckValueTypeEnum.between) {
          return (
            (valueMin && Number(valueMin) < base + min && Number(valueMin) !== base) ||
            (valueMax && valueMax > base + max && Number(valueMax))
          );
        }
        return (Number(value) < base + min || value > base + max) && Number(value) !== base;
      }
      default:
        return false;
    }
  }
  if (executeItemType === CheckItemInputTypeEnum.inputFormatSingle) {
    return !qualityItems?.includes(value);
  }
  if (executeItemType === CheckItemInputTypeEnum.inputFormatMultiple) {
    return value?.filter((i: string) => !qualityItems?.includes(i))?.length;
  }
  return isRecordDefect && Number(value) > 0;
};

export const formatValue = (
  checkItemRecord: {
    executeItemType: CheckItemInputTypeEnum;
    defalutValue: any;
    result: any;
    valueMin?: number;
    valueMax?: number;
    defaultValue?: any;
    singleJudgment?: number;
    option: string[];
    defaultMin?: number;
    defaultMax?: number;
    id?: number;
    taskCheckCount?: number;
  },
  checkItem: CheckItem,
) => {
  let value = checkItemRecord?.result ?? checkItemRecord?.defaultValue;

  if (checkItemRecord?.executeItemType === CheckItemInputTypeEnum.inputFormatMultiple) {
    value = checkItemRecord?.defaultValue?.split(',') || checkItemRecord?.option || [];
  }

  const resultValue = {
    value,
    valueMin: checkItemRecord?.valueMin ?? checkItemRecord?.defaultMin,
    valueMax: checkItemRecord?.valueMax ?? checkItemRecord?.defaultMax,
  } as any;

  if (checkItemRecord?.id) {
    resultValue.id = checkItemRecord.id;
  }

  return {
    ...resultValue,
    taskCheckCount: checkItemRecord.taskCheckCount,
    isUnQuality:
      typeof checkItemRecord?.singleJudgment === 'number'
        ? !checkItemRecord?.singleJudgment
        : isUnQuality({
            result: resultValue,
            checkItem,
          }),
  };
};

export const getTaskEditUrl = (id: number, layoutId: number, bizType: number) =>
  `/quality/qualityExecution/qcTask/${id}/edit?layoutId=${layoutId}&bizType=${bizType}`;
