import PendingList from './pendingList';
import HandledList from './handledList';
import InitiateList from './initiateList';
import { VIEW_MODAL } from './constant';
import { TabList } from 'src/components';

const List = () => {
  const tabList = [
    {
      tab: '待我处理',
      key: VIEW_MODAL.PENDING,
      component: <PendingList />,
    },
    {
      tab: '我已处理',
      key: VIEW_MODAL.HANDLED,
      component: <HandledList />,
    },
    {
      tab: '我发起的',
      key: VIEW_MODAL.INITIATE,
      component: <InitiateList />,
    },
  ];

  return <TabList tabList={tabList} defaultKey={VIEW_MODAL.PENDING} />;
};

export default List;
