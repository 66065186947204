import { Form, FormInstance, Input, InputNumber, Select, Space } from 'antd';
import { BatchOperateTableHeader, BlSortFormList, TagList } from 'components';
import { TextToolTip } from '@blacklake-web/component';
import _, { max } from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import {
  fractionLengthCheck,
  numberMinMaxCheck,
  validateFormListRepeat,
} from 'src/utils/formValidators';
import React from 'react';
import { getMaterialAttrs, getMaterialUnitInfo, getMaterialUnits } from 'src/entity/material';
import { appDict, appEnum } from 'src/dict';
import { NamePath } from 'antd/lib/form/interface';
import { replaceSign } from 'src/utils/constants';
import { SubstitutionStrategyEnum } from 'src/dict/alternativePlan';
import MaterialSelect from 'src/components/searchSelect/material';
import {
  checkMaterialCodeRepeat,
  getMaterialCodeList,
} from 'src/page/knowledgeManagement/engineerData/alternativePlan/helper/utils';
import {
  ALTERNATIVE_MATERIALS,
  ORIGINAL_ALTERNATIVE_MATERIALS,
} from 'src/page/knowledgeManagement/engineerData/alternativePlan/helper/constants';
import { FormAlternativeMaterialItem } from 'src/page/knowledgeManagement/engineerData/alternativePlan/types/create.type';

interface Props {
  name: string;
  form: FormInstance;
  substitutionStrategy: SubstitutionStrategyEnum;
}

const AlternativeMaterialFormTable = ({ name, form, substitutionStrategy }: Props) => {
  const getMaterialInfo = (namepath: NamePath, subField?: string) => {
    const materialInfo = JSON.parse(form.getFieldValue(namepath)?.value ?? '{}');

    if (subField) {
      return materialInfo[subField];
    }
    return materialInfo;
  };
  const batchSetFieldValue = (fieldName: string, fieldValue: any) => {
    const formList = _.cloneDeep(form.getFieldValue(name));

    formList.forEach((item: { [x: string]: any }) => {
      item[fieldName] = fieldValue;
    });
    form.setFieldsValue({
      [name]: formList,
    });
  };
  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '序号',
        dataIndex: 'lineNo',
        width: 100,
        fixed: 'left',
        render: (text, record, index) => {
          return <div>{index + 1}</div>;
        },
      },
      {
        title: '优先级',
        dataIndex: 'priority',
        width: 150,
        render: (text, record) => {
          const materials: FormAlternativeMaterialItem[] | undefined =
            form.getFieldValue(ALTERNATIVE_MATERIALS);
          const currentMaxNum = _.max(materials?.map((item) => Number(item.priority ?? 0)));
          const num = currentMaxNum ? currentMaxNum + 1 : 1;

          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={[record?.name, 'priority']}
              initialValue={num}
              validateFirst
              rules={[
                { required: true, message: '优先级不能为空' },
                {
                  validator: numberMinMaxCheck({
                    isInteger: true,
                    min: 1,
                    max: 50,
                    fieldName: '优先级',
                  }),
                },
                { validator: validateFormListRepeat(name, 'priority', form, '优先级有重复') },
              ]}
            >
              <InputNumber placeholder="请输入" />
            </Form.Item>
          );
        },
      },
      {
        title: <BatchOperateTableHeader titleText="物料编号" required />,
        dataIndex: 'materialId',
        width: 200,
        render: (text, record, index) => {
          const onMaterialChange = () => {
            const materialInfo = getMaterialInfo([name, index, 'materialId']);

            if (!materialInfo || _.isEmpty(materialInfo)) return;
            const alternativeMaterialList = _.cloneDeep(form.getFieldValue(name));

            // 单位信息
            const unitId = materialInfo?.feedInfo?.feedUnitId;
            const unitInfo = materialInfo.unitList.find(
              (item: { id: number }) => item.id === unitId,
            );

            alternativeMaterialList[index].unitId = {
              key: unitId,
              label: unitInfo?.name,
              value: unitId,
            };
            // 投料质量状态
            alternativeMaterialList[index].feedingQualityStatus =
              materialInfo?.feedInfo?.qcStatus ?? [];
            form.setFieldsValue({
              [name]: alternativeMaterialList,
            });
          };

          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              name={[index, 'materialId']}
              validateFirst
              rules={[
                { required: true, message: '物料不能为空' },
                {
                  validator: checkMaterialCodeRepeat(
                    getMaterialCodeList(
                      [ORIGINAL_ALTERNATIVE_MATERIALS, ALTERNATIVE_MATERIALS],
                      form,
                    ),
                    '物料不能重复，请检查！',
                  ),
                },
              ]}
            >
              <MaterialSelect
                onChange={onMaterialChange}
                params={{
                  enableFlag: appEnum.Common.UsageStatus.enabled,
                  bizType: [appEnum.Material.BizType.default],
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '物料名称',
        dataIndex: 'materialName',
        width: 200,
        render: (text, record, index) => {
          return (
            <Form.Item dependencies={[name, index, 'materialId']} noStyle>
              {() => getMaterialInfo([name, index, 'materialId'], 'baseInfo')?.name ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料属性',
        dataIndex: 'materialAttributes',
        width: 200,
        render: (text, record, index) => {
          return (
            <Form.Item dependencies={[name, index, 'materialId']} noStyle>
              {() => (
                <TagList
                  dataSource={getMaterialAttrs(getMaterialInfo([name, index, 'materialId']))}
                />
              )}
            </Form.Item>
          );
        },
      },
      {
        title: '物料分类',
        dataIndex: 'materialCategory',
        width: 150,
        render: (text, record, index) => {
          return (
            <Form.Item dependencies={[name, index, 'materialId']} noStyle>
              {() => getMaterialInfo([name, index, 'materialId'])?.category?.name ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '物料规格',
        dataIndex: 'specification',
        width: 150,
        render: (text, record, index) => {
          return (
            <Form.Item dependencies={[[name, index, 'materialId']]} noStyle>
              {() => (
                <TextToolTip
                  text={
                    getMaterialInfo([name, index, 'materialId'])?.baseInfo?.specification ||
                    replaceSign
                  }
                  width={130}
                />
              )}
            </Form.Item>
          );
        },
      },
      {
        title: '使用比例',
        dataIndex: 'useRatio',
        width: 150,
        render: (text, field, index) => {
          // const baseName = [name];
          return (
            <Form.Item
              dependencies={[['substitutionStrategy'], [name, index, 'useUpperLimitRatio']]}
              noStyle
            >
              {() => {
                // 替代策略
                const substitutionStrategy = form.getFieldValue('substitutionStrategy');
                const useUpperLimitRatio = form.getFieldValue([name, index, 'useUpperLimitRatio']);

                if (
                  substitutionStrategy ===
                  appEnum.AlternativePlan.SubstitutionStrategyEnum.fullSubstitute
                ) {
                  return (
                    <Form.Item
                      style={{ marginBottom: '0' }}
                      fieldKey={[field.fieldKey, 'useRatio']}
                      name={[field.name, 'useRatio']}
                    >
                      <Select>
                        <Select.Option value={0}>0</Select.Option>
                        <Select.Option value={100}>100</Select.Option>
                      </Select>
                    </Form.Item>
                  );
                }
                return (
                  <Space>
                    <Form.Item
                      style={{ marginBottom: '0' }}
                      fieldKey={[field.fieldKey, 'useRatio']}
                      name={[field.name, 'useRatio']}
                      rules={[
                        {
                          validator: numberMinMaxCheck({
                            min: 0,
                            minAllowEqual: true,
                            // 如果使用比例上限(%)未填写为空，则不设置使用比例上限
                            max: _.isNil(useUpperLimitRatio) ? undefined : useUpperLimitRatio,
                            maxAllowEqual: true,
                            fieldName: '使用比例',
                          }),
                        },
                        { validator: fractionLengthCheck(4) },
                      ]}
                      initialValue={0}
                    >
                      <InputNumber style={{ width: '100%' }} max={100} />
                    </Form.Item>
                  </Space>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: (
          <BatchOperateTableHeader
            required
            titleText="数量:分子"
            rules={[
              { required: true, message: '数量:分子不能为空' },
              {
                validator: numberMinMaxCheck({
                  min: 0,
                  minAllowEqual: false,
                  max: 10000000,
                  fieldName: '数量:分子',
                }),
              },
            ]}
            popoverFieldComponent={<InputNumber style={{ width: '100%' }} min={0} max={10000000} />}
            batchOperation={(value) => {
              batchSetFieldValue('amountNumerator', value);
            }}
          />
        ),
        dataIndex: 'amountNumerator',
        width: 150,
        render: (text, record, index) => {
          const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
            getMaterialInfo([name, index, 'materialId']),
            form.getFieldValue([name, index, 'unitId'])?.value,
          );

          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={[index, 'amountNumerator']}
              initialValue={1}
              dependencies={[[name, index, 'unitId']]}
              rules={_.compact([
                { required: true, message: '数量:分子不能为空' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: false,
                    max: 10000000,
                    fieldName: '数量:分子',
                  }),
                },
                !!enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
              ])}
            >
              <InputNumber style={{ width: '100%' }} placeholder="请输入" min={0} max={10000000} />
            </Form.Item>
          );
        },
      },
      {
        title: (
          <BatchOperateTableHeader
            required
            titleText="数量:分母"
            rules={[
              { required: true, message: '数量:分母不能为空' },
              {
                validator: numberMinMaxCheck({
                  min: 0,
                  minAllowEqual: false,
                  max: 10000000,
                  fieldName: '数量:分母',
                }),
              },
            ]}
            popoverFieldComponent={<InputNumber style={{ width: '100%' }} min={0} max={10000000} />}
            batchOperation={(value) => {
              batchSetFieldValue('amountDenominator', value);
            }}
          />
        ),
        dataIndex: 'amountDenominator',
        width: 150,
        render: (text, record, index) => {
          const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
            getMaterialInfo([name, index, 'materialId']),
            form.getFieldValue([name, index, 'unitId'])?.value,
          );

          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={[index, 'amountDenominator']}
              initialValue={1}
              dependencies={[[name, index, 'unitId']]}
              rules={_.compact([
                { required: true, message: '数量:分母不能为空' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: false,
                    max: 10000000,
                    fieldName: '数量:分母',
                  }),
                },
                !!enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
              ])}
            >
              <InputNumber style={{ width: '100%' }} placeholder="请输入" min={0} max={10000000} />
            </Form.Item>
          );
        },
      },
      {
        title: <BatchOperateTableHeader titleText="单位" required />,
        dataIndex: 'unitId',
        width: 150,
        render: (text: string, record: any, index) => (
          <Form.Item
            style={{ marginBottom: 0 }}
            name={[index, 'unitId']}
            validateFirst
            rules={[{ required: true, message: '请选择单位' }]}
            dependencies={[[name, index, 'materialId']]}
          >
            <Select
              placeholder={'请选择'}
              options={getMaterialUnits(getMaterialInfo([name, index, 'materialId']))}
              labelInValue
            />
          </Form.Item>
        ),
      },
      {
        title: <BatchOperateTableHeader titleText="领料方式" required />,
        dataIndex: 'pickMode',
        width: 200,
        render: (text, record, index) => {
          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name={[index, 'pickMode']}
              initialValue={appEnum.Bom.PickMode.demandPick}
              rules={[{ required: true, message: '必须选择领料方式' }]}
            >
              <Select options={appDict.bom.pickMode} />
            </Form.Item>
          );
        },
      },
      {
        title: '投料质量状态',
        dataIndex: 'feedingQualityStatus',
        width: 200,
        render: (text, record, index) => {
          return (
            <Form.Item style={{ marginBottom: '0' }} name={[index, 'feedingQualityStatus']}>
              <Select options={appDict.material.feedingQualityStatus} mode="multiple" />
            </Form.Item>
          );
        },
      },
      {
        title: (
          <BatchOperateTableHeader
            titleText="使用比例上限(%)"
            rules={[
              {
                validator: numberMinMaxCheck({
                  min: 0,
                  minAllowEqual: false,
                  max: 100,
                  fieldName: '使用比例上限(%)',
                }),
              },
              { validator: fractionLengthCheck(4) },
            ]}
            popoverFieldComponent={
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                max={100}
                disabled={substitutionStrategy === SubstitutionStrategyEnum.fullSubstitute}
              />
            }
            batchOperation={(value) => {
              batchSetFieldValue('useUpperLimitRatio', value);
            }}
          />
        ),
        dataIndex: 'useUpperLimitRatio',
        width: 200,
        render: (text, record, index) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              name={
                substitutionStrategy === SubstitutionStrategyEnum.fullSubstitute
                  ? undefined
                  : [index, 'useUpperLimitRatio']
              }
              initialValue={null}
              rules={[
                {
                  validator: fractionLengthCheck(4),
                },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: false,
                    max: 100,
                    fieldName: '使用比例上限',
                  }),
                },
              ]}
            >
              {substitutionStrategy === SubstitutionStrategyEnum.fullSubstitute ? (
                replaceSign
              ) : (
                <InputNumber style={{ width: '100%' }} max={100} min={0} />
              )}
            </Form.Item>
          );
        },
      },
      {
        title: '行备注',
        dataIndex: 'remark',
        width: 300,
        render: (text, record, index) => {
          return (
            <Form.Item style={{ marginBottom: '10px', marginTop: '10px' }} name={[index, 'remark']}>
              <Input.TextArea
                style={{ width: '100%' }}
                maxLength={1000}
                placeholder="请输入"
                showCount
                autoSize
              />
            </Form.Item>
          );
        },
      },
    ];
  };

  return (
    <BlSortFormList
      buttonText="添加物料行"
      name={name}
      maxCount={50}
      isNeedDrag={false}
      renderColumns={() => getColumns()}
      listRules={[
        {
          validator: async (__, items) => {
            if (!items || _.isEmpty(items)) {
              return Promise.reject(Error('请至少添加一行替代物料'));
            }
            return Promise.resolve(true);
          },
        },
      ]}
    />
  );
};

export default AlternativeMaterialFormTable;
