import _ from 'lodash';

import * as constants from '../constants';

export const getUserInfo = (): any =>
  _.get(JSON.parse(String(localStorage.getItem(constants.FIELDS.USER_INFO))), 'data');

export const LOGIN_INFO_EXPIRATION = 3 * 24 * 60 * 60;

// limitType的枚举类型定义
// eslint-disable-next-line no-shadow
export enum LIMIT_TYPE_ENUM {
  USER = 0, // 不同用户对相同的key会存储/读取不同的值
  ORG = 1, // 不同工厂对相同的key会存储/读取不同的值
}

export default class LocalStorage {
  static getUserInfo = (): any =>
    _.get(JSON.parse(String(localStorage.getItem(constants.FIELDS.USER))), 'data');

  static get(key: string): any {
    const origin = localStorage.getItem(key);
    const { phone, orgId, org, id: userId } = this.getUserInfo() || {};
    const code = _.get(org, 'code');

    if (origin) {
      const value = JSON.parse(origin);

      if (key === 'auth' || key === 'CONFIG') {
        // auth和CONFIG永不过期
        return value.data;
      }
      const now = new Date().getTime();
      const storageTime = _.get(value, 'meta.time');
      const limitType = _.get(value, 'meta.limitType');
      const { userLimit, phone: storagePhone } = _.get(value, 'meta.user', {});
      const { orgLimit, code: storageCode } = _.get(value, 'meta.orgCode', {});
      const exp = value?.meta?.exp;

      // 是否符合读取限制条件
      const isTimeIncompatible = exp && exp !== 0 && now - storageTime > exp;
      const isUserIncompatible = userLimit && storagePhone !== phone;
      const isOrgIncompatible = orgLimit && storageCode !== code;

      if (isTimeIncompatible || isUserIncompatible || isOrgIncompatible) {
        if (key === 'token') {
          return 'expired';
        }
        localStorage.removeItem(key);
        return undefined;
      }
      if (limitType === LIMIT_TYPE_ENUM.USER) {
        return _.get(value, `data[orgId-${orgId}-userId-${userId}]`);
      }
      if (limitType === LIMIT_TYPE_ENUM.ORG) {
        return _.get(value, `data[orgId-${orgId}]`);
      }

      return value.data;
    }
    return undefined;
  }

  /*
   limit为localStorage的存储类型限制，类型为枚举值，具体描述参照定义。
  */

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static set(
    key: string,
    data: any,
    limitOption?: {
      expiration?: number;
      limitType?: LIMIT_TYPE_ENUM;
      userLimit?: boolean;
      orgLimit?: boolean;
    },
  ): void {
    const { expiration = 0, limitType, userLimit = false, orgLimit = false } = limitOption || {};
    const { id: userId, orgId, org, phone } = this.getUserInfo() || {};
    let _data = data;

    if (limitType === LIMIT_TYPE_ENUM.USER) {
      if (!userId) {
        console.error('设置用户限制类型的LocalStorage必须有用户信息');
        return;
      }
      _data = {
        ...this.getOriginValue(key),
        [`orgId-${orgId}-userId-${userId}`]: data,
      };
    } else if (limitType === LIMIT_TYPE_ENUM.ORG) {
      if (!orgId) {
        console.error('设置工厂限制类型的LocalStorage必须有工厂信息');
        return;
      }
      _data = { ...this.getOriginValue(key), [`orgId-${orgId}`]: data };
    }
    const value = {
      meta: {
        exp: typeof expiration === 'number' && expiration > 0 ? expiration * 1000 : 0,
        time: new Date().getTime(),
        limitType,
        user: {
          id: userId,
          phone,
          userLimit,
        },
        org: {
          id: orgId,
          code: org && org.code,
        },
        // 遗留代码 不确定是否有依赖该字段 所以不删除
        orgCode: {
          code: org && org.code,
          orgLimit,
        },
      },
      data: _data,
    };

    localStorage.setItem(key, JSON.stringify(value));
  }

  static remove(key: string): void {
    localStorage.removeItem(key);
  }

  static clear(): void {
    localStorage.clear();
  }

  static getOriginValue(key: string): any | undefined {
    const origin = localStorage.getItem(key);

    if (origin) {
      const value = JSON.parse(origin);

      return value.data;
    }
    return undefined;
  }
}

// 从localStorage中获取用户信息
export const getUserFromLocalStorage = (): any => {
  return LocalStorage.get(constants.FIELDS.USER);
};
