/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../../request';

// makeRequest
function makeRequestRequired<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequestOptional<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData?: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequest<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const optional = makeRequestOptional<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  const required = makeRequestRequired<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  return (
    requestConfig.requestDataOptional ? optional : required
  ) as TRequestConfig['requestDataOptional'] extends true ? typeof optional : typeof required;
}

// Request
export type Request<TReqeustData, TRequestConfig extends RequestConfig, TRequestResult> =
  (TRequestConfig['requestDataOptional'] extends true
    ? (
        requestData?: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult
    : (
        requestData: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult) & {
    requestConfig: TRequestConfig;
  };

const mockUrl_0_7_2_0 = 'http://yapi.blacklake.tech/mock/735' as any;
const devUrl_0_7_2_0 = '' as any;
const prodUrl_0_7_2_0 = '' as any;
const dataKey_0_7_2_0 = undefined as any;

/**
 * 接口 [接受质量限列表↗](http://yapi.blacklake.tech/project/735/interface/api/79808) 的 **请求类型**
 *
 * @分类 [web-AQL↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11348)
 * @标签 `web-AQL`
 * @请求头 `POST /quality/domain/web/v1/qc_aql/accept_limit_list`
 * @更新时间 `2021-11-17 15:00:02`
 */
export interface FetchQcAqlAcceptLimitListRequest {}

/**
 * 接口 [接受质量限列表↗](http://yapi.blacklake.tech/project/735/interface/api/79808) 的 **返回类型**
 *
 * @分类 [web-AQL↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11348)
 * @标签 `web-AQL`
 * @请求头 `POST /quality/domain/web/v1/qc_aql/accept_limit_list`
 * @更新时间 `2021-11-17 15:00:02`
 */
export interface FetchQcAqlAcceptLimitListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * id
       */
      id?: number;
      /**
       * 名称
       */
      value?: number;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [接受质量限列表↗](http://yapi.blacklake.tech/project/735/interface/api/79808) 的 **请求配置的类型**
 *
 * @分类 [web-AQL↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11348)
 * @标签 `web-AQL`
 * @请求头 `POST /quality/domain/web/v1/qc_aql/accept_limit_list`
 * @更新时间 `2021-11-17 15:00:02`
 */
type FetchQcAqlAcceptLimitListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_aql/accept_limit_list',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [接受质量限列表↗](http://yapi.blacklake.tech/project/735/interface/api/79808) 的 **请求配置**
 *
 * @分类 [web-AQL↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11348)
 * @标签 `web-AQL`
 * @请求头 `POST /quality/domain/web/v1/qc_aql/accept_limit_list`
 * @更新时间 `2021-11-17 15:00:02`
 */
const fetchQcAqlAcceptLimitListRequestConfig: FetchQcAqlAcceptLimitListRequestConfig = {
  mockUrl: mockUrl_0_7_2_0,
  devUrl: devUrl_0_7_2_0,
  prodUrl: prodUrl_0_7_2_0,
  path: '/quality/domain/web/v1/qc_aql/accept_limit_list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_2_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcAqlAcceptLimitList',
};

/**
 * 接口 [接受质量限列表↗](http://yapi.blacklake.tech/project/735/interface/api/79808) 的 **请求函数**
 *
 * @分类 [web-AQL↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11348)
 * @标签 `web-AQL`
 * @请求头 `POST /quality/domain/web/v1/qc_aql/accept_limit_list`
 * @更新时间 `2021-11-17 15:00:02`
 */
export const fetchQcAqlAcceptLimitList = makeRequest<
  FetchQcAqlAcceptLimitListRequest,
  FetchQcAqlAcceptLimitListResponse,
  FetchQcAqlAcceptLimitListRequestConfig
>(fetchQcAqlAcceptLimitListRequestConfig);

/**
 * 接口 [检验水平列表↗](http://yapi.blacklake.tech/project/735/interface/api/79814) 的 **请求类型**
 *
 * @分类 [web-AQL↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11348)
 * @标签 `web-AQL`
 * @请求头 `POST /quality/domain/web/v1/qc_aql/inspection_level_list`
 * @更新时间 `2021-11-17 15:00:02`
 */
export interface FetchQcAqlInspectionLevelListRequest {}

/**
 * 接口 [检验水平列表↗](http://yapi.blacklake.tech/project/735/interface/api/79814) 的 **返回类型**
 *
 * @分类 [web-AQL↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11348)
 * @标签 `web-AQL`
 * @请求头 `POST /quality/domain/web/v1/qc_aql/inspection_level_list`
 * @更新时间 `2021-11-17 15:00:02`
 */
export interface FetchQcAqlInspectionLevelListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [检验水平列表↗](http://yapi.blacklake.tech/project/735/interface/api/79814) 的 **请求配置的类型**
 *
 * @分类 [web-AQL↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11348)
 * @标签 `web-AQL`
 * @请求头 `POST /quality/domain/web/v1/qc_aql/inspection_level_list`
 * @更新时间 `2021-11-17 15:00:02`
 */
type FetchQcAqlInspectionLevelListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_aql/inspection_level_list',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [检验水平列表↗](http://yapi.blacklake.tech/project/735/interface/api/79814) 的 **请求配置**
 *
 * @分类 [web-AQL↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11348)
 * @标签 `web-AQL`
 * @请求头 `POST /quality/domain/web/v1/qc_aql/inspection_level_list`
 * @更新时间 `2021-11-17 15:00:02`
 */
const fetchQcAqlInspectionLevelListRequestConfig: FetchQcAqlInspectionLevelListRequestConfig = {
  mockUrl: mockUrl_0_7_2_0,
  devUrl: devUrl_0_7_2_0,
  prodUrl: prodUrl_0_7_2_0,
  path: '/quality/domain/web/v1/qc_aql/inspection_level_list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_2_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcAqlInspectionLevelList',
};

/**
 * 接口 [检验水平列表↗](http://yapi.blacklake.tech/project/735/interface/api/79814) 的 **请求函数**
 *
 * @分类 [web-AQL↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11348)
 * @标签 `web-AQL`
 * @请求头 `POST /quality/domain/web/v1/qc_aql/inspection_level_list`
 * @更新时间 `2021-11-17 15:00:02`
 */
export const fetchQcAqlInspectionLevelList = makeRequest<
  FetchQcAqlInspectionLevelListRequest,
  FetchQcAqlInspectionLevelListResponse,
  FetchQcAqlInspectionLevelListRequestConfig
>(fetchQcAqlInspectionLevelListRequestConfig);

/**
 * 接口 [根据 检验水平、接受质量限 查询国标aql↗](http://yapi.blacklake.tech/project/735/interface/api/79820) 的 **请求类型**
 *
 * @分类 [web-AQL↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11348)
 * @标签 `web-AQL`
 * @请求头 `POST /quality/domain/web/v1/qc_aql/query_list`
 * @更新时间 `2021-11-17 15:00:03`
 */
export interface FetchQcAqlQueryListRequest {
  /**
   * AQL检验严格度类型: 1正常检验, 2加严检验, 3放宽检验
   */
  qcAqlCategory?: number;
  /**
   * 接受质量限id
   */
  qcAqlId?: number;
  /**
   * 检验水平id
   */
  qcAqlInspectionLevelId?: number;
}

/**
 * 接口 [根据 检验水平、接受质量限 查询国标aql↗](http://yapi.blacklake.tech/project/735/interface/api/79820) 的 **返回类型**
 *
 * @分类 [web-AQL↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11348)
 * @标签 `web-AQL`
 * @请求头 `POST /quality/domain/web/v1/qc_aql/query_list`
 * @更新时间 `2021-11-17 15:00:03`
 */
export interface FetchQcAqlQueryListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    aqlAcceptLimitVO?: {
      /**
       * 国标-接受质量限明细列表
       */
      aqlAcceptLimitDetailVOList?: {
        /**
         * 明细-接收数
         */
        acceptCount?: number;
        /**
         * 明细-最大批量
         */
        maxBatch?: number;
        /**
         * 明细-最小批量
         */
        minBatch?: number;
        /**
         * 明细-拒收数
         */
        rejectCount?: number;
        /**
         * 明细-样本量
         */
        sampleCount?: number;
      }[];
    };
    /**
     * 国标-样本量列表
     */
    sampleList?: {
      /**
       * 国标-最大批量
       */
      maxBatch?: number;
      /**
       * 国标-最小批量
       */
      minBatch?: number;
      /**
       * 国标-样本量
       */
      sampleCount?: number;
    }[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [根据 检验水平、接受质量限 查询国标aql↗](http://yapi.blacklake.tech/project/735/interface/api/79820) 的 **请求配置的类型**
 *
 * @分类 [web-AQL↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11348)
 * @标签 `web-AQL`
 * @请求头 `POST /quality/domain/web/v1/qc_aql/query_list`
 * @更新时间 `2021-11-17 15:00:03`
 */
type FetchQcAqlQueryListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_aql/query_list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [根据 检验水平、接受质量限 查询国标aql↗](http://yapi.blacklake.tech/project/735/interface/api/79820) 的 **请求配置**
 *
 * @分类 [web-AQL↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11348)
 * @标签 `web-AQL`
 * @请求头 `POST /quality/domain/web/v1/qc_aql/query_list`
 * @更新时间 `2021-11-17 15:00:03`
 */
const fetchQcAqlQueryListRequestConfig: FetchQcAqlQueryListRequestConfig = {
  mockUrl: mockUrl_0_7_2_0,
  devUrl: devUrl_0_7_2_0,
  prodUrl: prodUrl_0_7_2_0,
  path: '/quality/domain/web/v1/qc_aql/query_list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_2_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcAqlQueryList',
};

/**
 * 接口 [根据 检验水平、接受质量限 查询国标aql↗](http://yapi.blacklake.tech/project/735/interface/api/79820) 的 **请求函数**
 *
 * @分类 [web-AQL↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11348)
 * @标签 `web-AQL`
 * @请求头 `POST /quality/domain/web/v1/qc_aql/query_list`
 * @更新时间 `2021-11-17 15:00:03`
 */
export const fetchQcAqlQueryList = makeRequest<
  FetchQcAqlQueryListRequest,
  FetchQcAqlQueryListResponse,
  FetchQcAqlQueryListRequestConfig
>(fetchQcAqlQueryListRequestConfig);

/* prettier-ignore-end */
