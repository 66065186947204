import React, { ReactNode, useEffect, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { FormInstance, Input, Radio, Select, Tabs } from 'antd';
import { textValidator2, textValidator1 } from 'src/utils/formValidators';
import {
  APPROVE_ENUM,
  APPROVE_MAP,
  TEMPLATE_ENUM,
  TEMPLATE_TYPE_MAP,
  VIEW_MODAL,
} from '../constant';
import AddTemplateTabComponent from './template/addTemplateTabComponent';
import AddDataTabContent from './data/addDataTabContent';
import { NumberRulesStandardHook, SearchSelect } from 'src/components';

const { TabPane } = Tabs;
const { TextArea } = Input;

interface baseInfoProps {
  onCancel: () => void;
  onFinish: (operationType: string) => void;
  title: string;
  initialData?: any;
  form: FormInstance;
  extra?: ReactNode;
  addDataVisible: boolean;
  edit: boolean;
  handelSetAddDataVisible: (visible: boolean) => void;
}

export default function ElectronicTemplateCreateAndEditBastInfo(props: baseInfoProps) {
  const {
    onCancel,
    title,
    onFinish,
    initialData,
    form,
    extra,
    addDataVisible,
    handelSetAddDataVisible,
    edit,
  } = props;

  const [hiddenApprove, setHiddenApprove] = useState<number>(0);

  const renderTabs = () => {
    const tabList = [
      {
        tab: '模板文件',
        key: VIEW_MODAL.TEMPLATE,
        component: <AddTemplateTabComponent isEdit approval={form.getFieldValue('approval')} />,
      },
      {
        tab: '模板数据集',
        key: VIEW_MODAL.DATA,
        component: (
          <AddDataTabContent
            onFinish={onFinish}
            addDataVisible={addDataVisible}
            handelSetAddDataVisible={handelSetAddDataVisible}
            edit={edit}
          />
        ),
      },
    ];

    return (
      <Tabs destroyInactiveTabPane>
        {tabList.map(({ component, key, ...resItem }) => (
          <TabPane key={key} {...resItem}>
            {component}
          </TabPane>
        ))}
      </Tabs>
    );
  };

  const addInfo: DataFormLayoutInfoBlock = {
    items: [
      {
        label: '',
        isFullLine: true,
        render: () => renderTabs(),
      },
    ],
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    items: [
      ...NumberRulesStandardHook({
        label: '模板编号',
        form,
        edit,
        disabledState: edit,
        objectCode: 'eReport',
        fieldCode: 'code',
        rules: [{ max: 255, message: '不可超过255个字符' }, { validator: textValidator2 }],
      }),
      {
        label: '模板名称',
        name: 'name',
        rules: [
          { required: true, message: '模板名称必填' },
          { max: 255, message: '不可超过255个字符' },
          { validator: textValidator1 },
        ],
        render: () => <Input placeholder="请输入" allowClear />,
      },
      {
        label: '模板类型',
        name: 'type',
        render: () => (
          <Select labelInValue>
            {TEMPLATE_ENUM?.map((item: any) => {
              return (
                <Select.Option value={item?.value} key={item?.key}>
                  {item?.label}
                </Select.Option>
              );
            })}
          </Select>
        ),
      },
      {
        label: '是否需要审批',
        name: 'approval',
        initialValue: APPROVE_ENUM?.NO,
        rules: [{ required: true, message: '请选择是否需要审批' }],
        render: () => (
          <Radio.Group
            options={APPROVE_MAP}
            onChange={(e: any) => {
              setHiddenApprove(e?.target?.value);
            }}
          />
        ),
      },
      {
        label: '审批方案',
        name: 'approvePlan',
        hidden: hiddenApprove === APPROVE_ENUM.NO,
        rules: [{ required: hiddenApprove === APPROVE_ENUM.YES, message: '请选择' }],
        render: () => <SearchSelect fetchType={'approvePlan'} params={{ status: [1] }} />, // 获取电子单据对象启用中的额审批方案
      },
      {
        label: '模板说明',
        name: 'remark',
        rules: [{ max: 50, message: '不超过50个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="请输入模板说明，最多50字" />,
      },
      {
        label: '编辑原因',
        name: 'operateReason',
        hidden: !edit,
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
    ],
  };

  useEffect(() => {
    if (initialData) {
      form?.setFieldsValue({
        name: initialData?.name,
        code: initialData?.code,
        type: {
          key: initialData?.type,
          label: TEMPLATE_TYPE_MAP.get(initialData?.type),
        },
        remark: initialData?.remark,
        approval: initialData?.approval,
        approvePlan: initialData?.approval
          ? {
              key: initialData?.approvalPlanId,
              value: initialData?.approvalPlanId,
              label: initialData?.approvalPlanName,
            }
          : undefined,
      });

      setHiddenApprove(initialData?.approval);
    }
  }, [initialData]);

  return (
    <DataFormLayout
      extra={extra}
      form={form}
      title={title}
      info={[baseInfo, addInfo]}
      onCancel={onCancel}
      onFinish={() => {
        onFinish('create');
      }}
    />
  );
}
