import {
  Button,
  Form,
  message,
  InputNumber,
  Popover,
  Space,
  Spin,
  FormInstance,
  Modal,
} from 'antd';
import { BlIcon } from '@blacklake-web/component';
import React, { useCallback, useState } from 'react';
import { Rule } from 'antd/lib/form';
import styles from './NumberEditPopover.module.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface InputControlProps {
  rules?: Rule[];
  onClose: () => void;
  onFinish: (formValue: { numberInput: number }) => void;
  title: string;
  initialNumber?: number;
  form: FormInstance;
  name: string;
}

/**
 * 二次提交确认，传递点击确认时的回调函数，该回掉函数返回值为 Promise
 * @param message
 * @param handleOk
 */
const continueSubmitConfirm = (message: string, handleOk: () => Promise<void>) => {
  Modal.confirm({
    title: message,
    icon: <ExclamationCircleOutlined />,
    content: '是否继续提交？',
    okText: '继续提交',
    cancelText: '取消',
    zIndex: 1001,
    onOk() {
      return handleOk()
        .then(() => Promise.resolve())
        .catch(() => Promise.resolve());
    },
    onCancel() {
      return Promise.resolve();
    },
  });
};

const InputControl = ({
  rules,
  onClose,
  onFinish,
  title,
  initialNumber,
  form,
  name,
}: InputControlProps) => {
  return (
    <Form onFinish={onFinish} layout="vertical" form={form}>
      <Form.Item
        initialValue={initialNumber || 0}
        label={title}
        name={name}
        style={{ width: 280 }}
        rules={rules}
      >
        {<InputNumber style={{ width: '100%' }} placeholder="请输入" />}
      </Form.Item>
      <div style={{ textAlign: 'right' }}>
        <Space>
          <Form.Item noStyle>
            <Button onClick={onClose}>取消</Button>
          </Form.Item>
          <Form.Item noStyle>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};

interface Props {
  rules?: Rule[];
  title: string;
  initialNumber: number;
  submitUpdate: (n: number) => Promise<{ number: number; message: string } | 'success' | 'fail'>;
  submitUpdateSkipRule: (n: number) => Promise<void>;
}

const NumberEditPopover = ({
  rules,
  title,
  initialNumber,
  submitUpdate,
  submitUpdateSkipRule,
}: Props) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [form] = Form.useForm();

  const closePopover = useCallback(() => {
    setPopoverVisible(false);
  }, []);
  const [loading, setLoading] = useState(false);

  return (
    <Popover
      visible={popoverVisible}
      placement="bottom"
      trigger="click"
      zIndex={1000}
      content={
        <Spin spinning={loading}>
          <InputControl
            rules={rules}
            title={title}
            initialNumber={initialNumber}
            form={form}
            name="numberInput"
            onFinish={async (formValue) => {
              setLoading(true);

              try {
                const res = await submitUpdate(formValue?.numberInput);

                if (res === 'success') {
                  message.success('修改完成');
                  setLoading(false);
                  closePopover();
                } else if (res === 'fail') {
                  setLoading(false);
                  closePopover();
                } else if (typeof res === 'object') {
                  setLoading(false);
                  continueSubmitConfirm(res.message, () => {
                    return submitUpdateSkipRule(res.number).finally(() => {
                      closePopover();
                    });
                  });
                }
                // eslint-disable-next-line no-empty
              } catch (e) {
                setLoading(false);
                closePopover();
              }
            }}
            onClose={() => {
              closePopover();
            }}
          />
        </Spin>
      }
    >
      <BlIcon
        type="iconbianji"
        className={styles.editIconHover}
        onClick={() => {
          if (!popoverVisible) {
            form.setFieldsValue({ numberInput: initialNumber });
            setPopoverVisible(true);
          }
        }}
      />
    </Popover>
  );
};

export default NumberEditPopover;
