import React from 'react';
import { FormInstance, Input, Select, Form } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { UnitType } from 'src/dict/material';
import { CRUD, YN } from 'src/dict/common';
import _ from 'lodash';
import { ConvertUnit } from '../..';

interface Props {
  form: FormInstance;
  type: string;
}

export const warehousingBasicInfo = (props: Props) => {
  const { form, type } = props;

  return [
    {
      shouldUpdate: (prev, next) => {
        return prev.transformUnit !== next.transformUnit;
      },
      noStyle: true,
      render: (FormItemProps: any) => () => {
        const transformUnit = _.filter(
          form.getFieldValue('transformUnit'),
          ({ enableFlag }) => enableFlag,
        );
        const convertUnit = form.getFieldValue('convertUnit');
        const unitList = transformUnit
          .map((item: ConvertUnit) => {
            if (item.convertType !== UnitType.auxUnit && item.enableFlag) {
              return {
                label: item.toUnitId?.label,
                value: item.toUnitId?.value,
              };
            }
          })
          .filter(Boolean);

        // 确认此次修改的单位，是否还在formList
        if (!_.find(transformUnit, ({ toUnitId }) => toUnitId?.value === convertUnit?.value)) {
          // 否，convertUnit = 主单位
          // form.setFields([{ name: 'convertUnit', value: transformUnit[0].fromUnitId }]);

          // 与单位列表联动，转换单位不存在formList则，则清空转换单位convertUnit
          form.setFields([{ name: 'convertUnit', value: undefined }]);
        }

        return (
          <Form.Item
            name={'convertUnit'}
            label={'转换单位'}
            {...FormItemProps}
            style={{ width: '100%' }}
          >
            <Select labelInValue placeholder={'请选择'} options={unitList} />
          </Form.Item>
        );
      },
    },
    {
      label: '转换单位RelationId',
      name: 'convertUnitRelationId',
      hidden: true,
      render: () => <Input />,
    },
  ];
};
