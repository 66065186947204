import React, { useState } from 'react';
import { Space, Dropdown, Menu, Form, message as Message, TimePicker, Modal } from 'antd';
import { FilterItem, RecordListLayout } from 'layout';
import { BlIcon, SingleOperationLogComponent, Tooltip } from 'components';
import { replaceSign } from 'src/utils/constants';
import { fieldTypeList, gcTime } from 'src/utils';
import { map } from 'lodash';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import ColorBox from './components/colorBox';
import { CreatorData, ShiftInfoDetailVOS, TableRowType } from './interface';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import {
  dealWithPeriodOfTime,
  formatTime,
  formatDeleteFailsByCalendar,
  formatDeleteFailsBySchedulingRules,
  getNowTimeStamp,
  momentData,
  formatUseNames,
  oneDay,
} from './utils';
import _Time from 'src/utils/dataTypes/time';
import _String from 'src/utils/dataTypes/string';
import { FIELD_MAP } from './constant';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { useHistory } from 'react-router-dom';
import { toShiftCreate, toShiftDetail, toShiftEdit } from './navigation';
import ShiftDetail from './detail';
import { fetchShiftCheck, fetchShiftDelete, fetchShiftList } from 'src/api/ytt/calendar-domain';
import _Array from 'src/utils/dataTypes/array';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';

interface UserColumnProps {
  id: number;
  name: string;
  avatarUrl: string;
}

export default function ShiftList() {
  const history = useHistory();
  const [checkVisible, setCheckVisible] = useState(false); // 显示确定编辑弹框
  const [visibleDetail, setVisibleDetail] = useState(false); // 显示详情
  // const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]); // 选中的keys
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新list页面
  const [shiftId, setShiftId] = useState<any>(); // 详情id
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录

  const [delForm] = Form.useForm();

  // 刷新页面
  const refresh = () => {
    const date = Date.now();

    setRefreshMarker(date);
  };

  const errorColumns = [
    {
      title: '班次名称',
      dataIndex: 'name',
      width: 200,
      render: (name: string) => name || replaceSign,
    },
    {
      title: '失败原因',
      dataIndex: 'usingShiftObjs',
      width: 700,
      render: (usingShiftObjs: any) => {
        const useCalendarNames = formatDeleteFailsByCalendar(usingShiftObjs);
        const useSchedulingRulesNames = formatDeleteFailsBySchedulingRules(usingShiftObjs);

        return (
          <>
            {!_String.isEmpty(useCalendarNames) ? (
              <div>
                有工作日历：<span>{useCalendarNames}</span>
                ，正在使用该班次
              </div>
            ) : null}
            {!_String.isEmpty(useSchedulingRulesNames) ? (
              <div>
                有排班规则：
                <span>{useSchedulingRulesNames}</span>
                ，正在使用该班次
              </div>
            ) : null}
          </>
        );
      },
    },
  ];

  // const handleDelete = async (
  //   type: string,
  //   delId: number[],
  //   onSuccess: () => void,
  //   onFail?: () => void,
  // ) => {
  //   try {
  //     // const value = await delForm?.validateFields();
  //     // TODO：后面补充删除的原因
  //     const { message, data } = await fetchShiftDelete({ ids: delId });

  //     if (data) {
  //       if (data?.failedCount === 0) {
  //         Message.success('删除成功');
  //         onSuccess?.();
  //         return;
  //       }
  //       if (data?.successCount === 0) {
  //         onFail?.();
  //         // 全部删除失败
  //       } else if (data?.failedCount) {
  //         onSuccess?.();
  //         // 有失败的删除 需要展示失败信息
  //       }

  //       const useCalendarNames = formatDeleteFailsByCalendar(
  //         data?.shiftDeleteDetailVOS?.[0].usingShiftObjs,
  //       );
  //       const useSchedulingRulesNames = formatDeleteFailsBySchedulingRules(
  //         data?.shiftDeleteDetailVOS?.[0].usingShiftObjs,
  //       );

  //       if (type === 'single') {
  //         ErrorNotification({
  //           message: '删除失败',
  //           description: (
  //             <>
  //               {!_String.isEmpty(useCalendarNames) ? (
  //                 <div>
  //                   有工作日历：<span>{useCalendarNames}</span>
  //                   ，正在使用该班次
  //                 </div>
  //               ) : null}
  //               {!_String.isEmpty(useSchedulingRulesNames) ? (
  //                 <div>
  //                   有排班规则：<span>{useSchedulingRulesNames}</span>
  //                   ，正在使用该班次
  //                 </div>
  //               ) : null}
  //             </>
  //           ),
  //         });
  //       } else {
  //         showErrorListMessage({
  //           title: '删除出现失败',
  //           data: data?.shiftDeleteDetailVOS,
  //           columns: errorColumns,
  //           operateName: '删除',
  //           successCount: data?.successCount,
  //           failCount: data?.failedCount,
  //           width: 1200,
  //         });
  //       }

  //       return;
  //     }
  //     Message.error(message);
  //   } catch (error) {
  //     onFail?.();
  //     console.log(error);
  //   }
  // };

  // 编辑前的check
  const editCheckFn = async (id: number) => {
    try {
      const { data } = await fetchShiftCheck({ id });

      if (data) {
        const { inUse, workerCalendarVOS, shiftRuleUpdateVOS } = data;

        if (inUse) {
          // 被使用中，弹框提醒
          const shiftName = formatUseNames(shiftRuleUpdateVOS);

          Modal.confirm({
            title: '确定要编辑这条班次吗?',
            content: (
              <div>
                有
                {!_Array.isEmpty(workerCalendarVOS) && (
                  <span>
                    {map(workerCalendarVOS, (node: any) => {
                      return (
                        <span
                          style={{ color: '#02B980', cursor: 'pointer' }}
                          onClick={() => {
                            window.reactRouterHistoryInstance.push(
                              `/modeling/calendarManagement/workCalendar/detail${node?.id}`,
                            );
                          }}
                        >
                          {node?.name}
                        </span>
                      );
                    })}
                    等日历，
                  </span>
                )}
                {_String.isEmpty(shiftName) ? (
                  ''
                ) : (
                  <span>
                    <span style={{ color: '#02B980' }}>{shiftName}</span>等排班规则
                  </span>
                )}
                正在使用该班次，班次修改后，将影响到这些工作日历及班次，你确定要修改吗？
              </div>
            ),
            visible: checkVisible,
            onOk() {
              history.push(toShiftEdit(id));
            },
            onCancel() {
              setCheckVisible(false);
            },
            okText: '确定',
            cancelText: '取消',
          });
          return;
        }
      }

      // 未被引用
      history.push(toShiftEdit(id));
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const hasAuthRes = hasAuth(authDict.shift_detail);

  const dataColumns = [
    {
      title: '班次名称',
      dataIndex: 'name',
      width: 150,
      sorter: true,
      render: (ruleCode: string, record: TableRowType, index: number, config: any) => {
        if (!ruleCode) return replaceSign;
        return (
          <>
            {hasAuthRes ? (
              <a
                onClick={() => {
                  history.push(toShiftDetail(record?.id));
                }}
              >
                <Tooltip text={ruleCode ?? replaceSign} width={config.contentWidth} />
              </a>
            ) : (
              <Tooltip text={ruleCode ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },
    {
      title: '班次颜色',
      dataIndex: 'color',
      width: 110,
      render: (color: string) => {
        if (!color) return replaceSign;
        return <ColorBox color={color} />;
      },
    },
    {
      title: '班次时段',
      dataIndex: 'shiftInfoDetailVOS',
      width: 200,
      render: (shiftInfoDetailVOS: ShiftInfoDetailVOS) => {
        if (!shiftInfoDetailVOS) return replaceSign;

        const shiftInfoDetail = dealWithPeriodOfTime(shiftInfoDetailVOS)?.map(
          (element: ShiftInfoDetailVOS) => {
            return `${element?.startTime} - ${element?.endTime}`;
          },
        );

        return shiftInfoDetail?.map((node: string) => {
          return <div key={node}>{node}</div>;
        });
      },
    },
    {
      title: '有效工时',
      dataIndex: 'effectiveDuration',
      width: 150,
      sorter: true,
      render: (effectiveDuration: number) => formatTime(effectiveDuration) ?? replaceSign,
    },
    {
      title: '总工时',
      dataIndex: 'sumDuration',
      width: 150,
      sorter: true,
      render: (sumDuration: number) => formatTime(sumDuration) ?? replaceSign,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
      render: (remark: string) => remark || replaceSign,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 120,
      render: (creator: CreatorData) => {
        return (
          <AvatarDisplay
            id={creator?.id}
            name={creator?.name}
            avatarUrl={creator?.avatarUrl}
            key={creator?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 180,
      sorter: true,
      render: (createdAt: Date) => {
        if (!createdAt) return replaceSign;
        return _Time.format(createdAt);
      },
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      width: 150,
      render: (operator: CreatorData) => {
        return (
          <AvatarDisplay
            id={operator?.id}
            name={operator?.name}
            avatarUrl={operator?.avatarUrl}
            key={operator?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 180,
      sorter: true,
      render: (updatedAt: Date, record: any) => {
        if (!updatedAt) return _Time.format(record?.createdAt) ?? replaceSign; // 没有更新,默认为创建时间
        return _Time.format(updatedAt);
      },
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '班次名称',
      name: 'name',
      type: fieldTypeList.text,
    },
    {
      label: '有效工时',
      name: 'effectiveDuration',
      type: fieldTypeList.date,
      renderItem: (
        <TimePicker.RangePicker
          format="HH:mm"
          placeholder={['请输入', '请输入']}
          style={{ width: '100%' }}
          order
        />
      ),
    },
    {
      label: '总工时',
      name: 'sumDuration',
      type: fieldTypeList.date,
      renderItem: (
        <TimePicker.RangePicker
          format="HH:mm"
          placeholder={['请输入', '请输入']}
          style={{ width: '100%' }}
          order
        />
      ),
    },
    {
      label: '创建人',
      name: 'creatorId',
      type: fieldTypeList.text,
      renderItem: (
        <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple={false} isSelectUser />
      ),
    },
    {
      label: '创建时间',
      name: 'createdAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '更新人',
      name: 'operatorId',
      type: fieldTypeList.text,
      renderItem: (
        <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple={false} isSelectUser />
      ),
    },
    {
      label: '更新时间',
      name: 'updatedAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
  ];

  const mainMenu = [
    {
      title: '新建班次',
      auth: authDict.shift_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        history.push(toShiftCreate());
      },
      items: [],
    },
  ];

  // const toolbar = [
  //   {
  //     title: '删除',
  //     icon: <BlIcon type="iconshanchulajitong1" />,
  //     onClick: (onSuccess: any, onFail: any) => {
  //       BLDelConfirm(
  //         '删除后将无法恢复，确认删除？',
  //         delForm,
  //         () => {
  //           handleDelete('batch', selectedRowKeys, onSuccess, onFail);
  //         },
  //         () => {
  //           onFail();
  //         },
  //       );
  //     },
  //   },
  // ];

  const formatDataToQuery = (params: any) => {
    const {
      sumDuration,
      effectiveDuration,
      updatedAt,
      createdAt,
      sorter,
      creatorId,
      operatorId,
      quickSearch,
      ...rest
    } = params;

    const realParams = {
      ...rest,
    };

    const nowTimeStamp = getNowTimeStamp();

    if (effectiveDuration) {
      const [startTime, endTime] = gcTime.formatMomentRangeToUnix(effectiveDuration);

      realParams.minEffectiveDuration = startTime - nowTimeStamp; // 后端的时间戳永远从0开始 给的是一个时间段的时间戳 但TimePicker计算的是具体日期的时间点的时间戳 所以需要减法处理
      realParams.maxEffectiveDuration =
        endTime - nowTimeStamp === 0 ? endTime - nowTimeStamp + oneDay : endTime - nowTimeStamp;
    }
    if (sumDuration) {
      const [startTime, endTime] = gcTime.formatMomentRangeToUnix(sumDuration);

      realParams.minSumDuration = startTime - nowTimeStamp;
      realParams.maxSumDuration =
        endTime - nowTimeStamp === 0 ? endTime - nowTimeStamp + oneDay : endTime - nowTimeStamp;
    }

    if (createdAt) {
      realParams.createdAtFrom = Number(_Time.formatToUnix(createdAt[0]));
      realParams.createdAtTill = Number(_Time.formatToUnix(createdAt[1]));
    }
    if (updatedAt) {
      realParams.updatedAtFrom = Number(_Time.formatToUnix(updatedAt[0]));
      realParams.updatedAtTill = Number(_Time.formatToUnix(updatedAt[1]));
    }

    if (!_Array.isEmpty(creatorId)) {
      realParams.creatorId = creatorId?.[0]?.value;
    }
    if (!_Array.isEmpty(operatorId)) {
      realParams.operatorId = operatorId?.[0]?.value;
    }

    if (!_String.isEmpty(quickSearch)) {
      realParams.quickSearch = quickSearch;
    }

    if (sorter) {
      realParams.sorter = map(sorter, (node: any) => {
        return {
          order: node?.order,
          field: FIELD_MAP.get(node?.field),
        };
      });
    }

    return realParams;
  };

  const formatDataToDisplay = (data: any) => {
    const { sumDuration, effectiveDuration, updatedAt, createdAt, creatorId, operatorId } = data;

    const relData = { ...data };

    if (sumDuration) {
      const [startTime, endTime] = gcTime.formatMomentRangeToUnix(sumDuration);

      const startTimeDisplay = formatTime(startTime - getNowTimeStamp());

      const endFormat = endTime - getNowTimeStamp() === 0 ? oneDay : endTime - getNowTimeStamp(); // 结尾时间的00:00 代表的是24:00

      const endTimeDisplay = formatTime(endFormat);

      relData.sumDuration = `${startTimeDisplay} -- ${endTimeDisplay}`;
    }

    if (effectiveDuration) {
      const [startTime, endTime] = gcTime.formatMomentRangeToUnix(effectiveDuration);

      const startTimeDisplay = formatTime(startTime - getNowTimeStamp());

      const endFormat = endTime - getNowTimeStamp() === 0 ? oneDay : endTime - getNowTimeStamp();

      const endTimeDisplay = formatTime(endFormat);

      relData.effectiveDuration = `${startTimeDisplay} -- ${endTimeDisplay}`;
    }

    if (createdAt) {
      const createdAtFrom = _Time.format(createdAt[0], 'YYYY-MM-DD HH:mm:ss');
      const createdAtTill = _Time.format(createdAt[1], 'YYYY-MM-DD HH:mm:ss');

      relData.createdAt = `${createdAtFrom} — ${createdAtTill}`;
    }
    if (updatedAt) {
      const updatedAtFrom = _Time.format(updatedAt[0], 'YYYY-MM-DD HH:mm:ss');
      const updatedAtTill = _Time.format(updatedAt[1], 'YYYY-MM-DD HH:mm:ss');

      relData.updatedAt = `${updatedAtFrom} — ${updatedAtTill}`;
    }

    if (creatorId) {
      relData.creatorId = creatorId[0]?.label;
    }

    if (operatorId) {
      relData.operatorId = operatorId[0]?.label;
    }

    return relData;
  };

  const formatDataToFormDisplay = (data: any) => {
    const { effectiveDuration, sumDuration, updatedAt, createdAt } = data;

    const retData = { ...data };

    if (effectiveDuration) {
      retData.effectiveDuration = momentData(effectiveDuration);
    }
    if (sumDuration) {
      retData.sumDuration = momentData(sumDuration);
    }
    if (updatedAt) {
      retData.updatedAt = momentData(updatedAt);
    }
    if (createdAt) {
      retData.createdAt = momentData(createdAt);
    }

    return retData;
  };

  const config = {
    rowKey: (record: TableRowType) => String(record.id),
    columns: dataColumns,
    getOperationList: (record: TableRowType) => {
      return [
        {
          title: '查看',
          auth: authDict.shift_detail,
          onClick: () => {
            setShiftId(record?.id);
            setVisibleDetail(true);
          },
        },
        {
          title: '编辑',
          auth: authDict.shift_edit,
          onClick: () => editCheckFn(record?.id),
        },
        {
          title: '操作记录',
          auth: authDict.shift_operrecord,
          onClick: () => {
            setShiftId(record?.id);
            setVisibleLog(true);
          },
        },
      ];
    },
    mainMenu,
    filterList,
    formatDataToQuery,
    formatDataToDisplay,
    formatDataToFormDisplay,
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        userAuth={getAuthFromLocalStorage()}
        placeholder={'输入班次名称'}
        // selectedRowKeys={selectedRowKeys}
        configcacheKey="shiftCol" // 储存列配置
        // onSelectedRowKeys={(selectKey: any) => {
        //   setSelectRowKeys(selectKey);
        // }}
        requestFn={fetchShiftList}
        refreshMarker={refreshMarker}
        {...config}
      />
      {visibleDetail && (
        <ShiftDetail
          visible={visibleDetail}
          closeDetail={() => {
            setVisibleDetail(false);
          }}
          shiftId={shiftId}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={shiftId}
          objectCode={'Shift'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
}
