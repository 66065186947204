import NotificationSetting from './notificationMessage/notification/notificationSet';
import NotificationMessage from './notificationMessage/notificationTabList';

const baseUrl = '/notification';
const notificationMessageUrl = `${baseUrl}/notificationMessageUrl`;

export const NotificationRoute = [
  {
    path: baseUrl,
    menu: '通知',
    breadcrumb: '通知中心',
    icon: 'iconyijicaidan-tongzhi',
    redirectPath: notificationMessageUrl,
    children: [
      {
        path: notificationMessageUrl,
        menu: '通知消息',
        breadcrumb: '通知消息',
        component: NotificationMessage,
      },
      {
        path: `${notificationMessageUrl}/set`,
        breadcrumb: '通知设置',
        component: NotificationSetting,
      },
    ],
  },
];
