/**
 * @description 路由跳转
 */

import { CHART_NAME, DATE_RANGE } from 'src/dict/purchase';
import { qs } from 'url-parse';
import time from 'src/utils/dataTypes/time';
import moment from 'moment';
import { execStatusEnum } from 'src/dict/delivery';

const getDateRangeByKey = (dataRange: number) => {
  if (dataRange === DATE_RANGE.day) {
    return time.formatRangeUnixToInt([moment().startOf('day'), moment().endOf('day')]);
  }
  if (dataRange === DATE_RANGE.week) {
    return time.formatRangeUnixToInt([moment().startOf('week'), moment().endOf('week')]);
  }
  if (dataRange === DATE_RANGE.month) {
    return time.formatRangeUnixToInt([moment().startOf('month'), moment().endOf('month')]);
  }
  if (dataRange === -1) {
    return time.formatRangeUnixToInt([
      moment('1971-01-01'),
      moment().subtract(1, 'days').endOf('day'),
    ]);
  }
  return [];
};

interface UrlParams {
  key: string;
  dataRange?: number;
  createAt?: number[];
  supplier?: any[];
  material?: any[];
}

/** 获取跳转路由 */
export const getDetailUrl = ({
  key,
  dataRange = DATE_RANGE.day,
  createAt,
  supplier,
  material,
}: UrlParams) => {
  const query: any = {
    tabKey: 2,
  };

  switch (key) {
    case CHART_NAME.deliverySchedule:
    case CHART_NAME.receiveSchedule:
      query.filterData = JSON.stringify({
        planReceiveTime: getDateRangeByKey(dataRange).map((time: number) =>
          moment(time).format('YYYY-MM-DD HH:mm:ss'),
        ),
        execStatus: [execStatusEnum.executing, execStatusEnum.finished],
      });
      return `/purchase/purchaseManagement/deliveryOrder?${qs.stringify(query)}`;
    case CHART_NAME.overdueRecOrderSchedule:
      query.filterData = JSON.stringify({
        planReceiveTime: getDateRangeByKey(-1).map((time: number) =>
          moment(time).format('YYYY-MM-DD HH:mm:ss'),
        ),
        execStatus: [execStatusEnum.executing],
      });
      return `/purchase/purchaseManagement/deliveryOrder?${qs.stringify(query)}`;
    case CHART_NAME.onTimeCompletionRate:
      query.filterData = JSON.stringify({
        supplier,
        createdAt: createAt?.map((time: number) => moment(time).format('YYYY-MM-DD HH:mm:ss')),
      });
      query.tabKey = 1;
      return `/purchase/purchaseManagement/purchaseOrder?${qs.stringify(query)}`;
    case CHART_NAME.onTimeDeliveryRate:
    case CHART_NAME.averageDeliveryCycle:
      query.filterData = JSON.stringify({
        supplier,
        material,
        createdAt: createAt?.map((time: number) => moment(time).format('YYYY-MM-DD HH:mm:ss')),
      });
      return `/purchase/purchaseManagement/purchaseOrder?${qs.stringify(query)}`;
    default:
      return '';
  }
};
