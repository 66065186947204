import { message, message as Message, Modal } from 'antd';
import { showErrorListMessage } from 'components';
import _ from 'lodash';
import {
  fetchPurchaseReturnOrderBatchDistribute,
  fetchPurchaseReturnOrderBatchClose,
  fetchPurchaseReturnOrderBatchEnded,
  fetchPurchaseReturnOrderBatchEndedEnsure,
  fetchPurchaseReturnOrderBatchReturnOrder,
  FetchPurchaseReturnOrderBatchEndedResponse,
} from 'src/api/ytt/order-domain/purchaseReturnOrder';
import { EndResultType } from 'src/dict/purchase';
import { replaceSign } from 'src/utils/constants';
import { showEndListMessage } from '../purchaseOrder/components/endMessageList';

interface ReturnOrder {
  deliverAmount?: number;
  id?: number;
}

const errorColumns = [
  {
    title: '采购退货单编号',
    dataIndex: 'code',
    width: 100,
  },
  {
    title: '供应商名称',
    dataIndex: ['supplier', 'name'],
    width: 100,
  },
  {
    title: '供应商编号',
    dataIndex: ['supplier', 'code'],
    width: 100,
  },
  {
    title: '失败原因',
    dataIndex: 'errorReason',
    width: 100,
  },
];

const returnErrorColumns = [
  {
    title: '采购退货单编号',
    dataIndex: 'code',
    width: 100,
  },
  {
    title: '供应商名称',
    dataIndex: ['supplier', 'name'],
    width: 100,
  },
  {
    title: '供应商编号',
    dataIndex: ['supplier', 'code'],
    width: 100,
  },
  {
    title: '物料名称',
    dataIndex: ['material', 'name'],
    width: 100,
  },
  {
    title: '物料编号',
    dataIndex: ['material', 'code'],
    width: 100,
  },
  {
    title: '失败原因',
    dataIndex: 'errorReason',
    width: 100,
  },
];

const handleDistribute = async (ids: number[], onSuccess?: () => void, onFail?: () => void) => {
  try {
    const { data } = await fetchPurchaseReturnOrderBatchDistribute({ ids });

    if (data) {
      const { successCount, failCount, failDetails } = data;

      if (failCount === 0) {
        Message.success('下发成功');
        onSuccess?.();
        return;
      }
      onFail?.();

      if (ids.length === 1) {
        Message.error(`下发失败，${failDetails && failDetails[0].errorReason}`);
        return;
      }

      showErrorListMessage({
        title: '下发出现失败',
        data: failDetails ?? [],
        columns: errorColumns,
        operateName: '下发',
        successCount,
        failCount,
        width: 800,
      });

      return;
    }
  } catch (error) {
    onFail?.();
    console.log(error);
  }
};

const handleCloseOrder = async (ids: number[], onSuccess?: () => void, onFail?: () => void) => {
  try {
    const { data } = await fetchPurchaseReturnOrderBatchClose({ ids });

    if (data) {
      const { successCount, failCount, failDetails } = data;

      if (failCount === 0) {
        Message.success('关闭成功');
        onSuccess?.();
        return;
      }
      onFail?.();
      if (ids.length === 1) {
        Message.error(`关闭失败，${failDetails && failDetails[0]?.errorReason}`);
        return;
      }

      showErrorListMessage({
        title: '关闭出现失败',
        data: failDetails ?? [],
        columns: errorColumns,
        operateName: '关闭',
        successCount,
        failCount,
        width: 800,
        onOk: onSuccess,
        onCancel: onFail,
      });

      return;
    }

    return;
  } catch (error) {
    onFail?.();
    console.log(error);
  }
};

const handleReturnOrder = async (
  returnOrder: ReturnOrder[],
  onSuccess?: () => void,
  onFail?: () => void,
) => {
  try {
    const { data } = await fetchPurchaseReturnOrderBatchReturnOrder(returnOrder);

    if (data) {
      const { successCount, failCount, failDetails } = data;

      if (failCount === 0) {
        Message.success('退货成功');
        onSuccess?.();
        return;
      }
      onFail?.();
      if (returnOrder.length === 1) {
        Message.error(`退货失败，${failDetails && failDetails[0].errorReason}`);
        return;
      }

      showErrorListMessage({
        title: '退货出现失败',
        data: failDetails ?? [],
        columns: returnErrorColumns,
        operateName: '退货',
        successCount,
        failCount,
        width: 800,
        onOk: onSuccess,
        onCancel: onFail,
      });

      return;
    }

    return;
  } catch (error) {
    onFail?.();
    console.log(error);
  }
};

/**
 * 结束操作
 */
const getErrorColumns = (isException?: boolean) => [
  {
    title: '序号',
    dataIndex: 'num',
    width: 150,
    render: (code: number) => code || replaceSign,
  },
  {
    title: '编号',
    dataIndex: 'code',
    width: 150,
    render: (code: string) => code || replaceSign,
  },
  {
    title: '供应商名称',
    dataIndex: ['supplier', 'name'],
    width: 150,
  },
  {
    title: isException ? '中断原因' : '失败原因',
    dataIndex: 'errorReason',
    width: 300,
    render: (detail: string) => detail || replaceSign,
  },
];

const showError = (
  res: FetchPurchaseReturnOrderBatchEndedResponse,
  option: any,
  callback: () => void,
) => {
  const { failCount = 0, successCount = 0, failDetails } = res?.data ?? {};

  if (failCount) {
    if (option.type === 'multiple') {
      showErrorListMessage({
        failCount,
        successCount,
        title: '结束出现失败',
        width: 800,
        data: failDetails?.map((item, index) => {
          return { ...item, num: index + 1 };
        }),
        desc: `采购退货单结束完成，结束成功：${successCount}个，结束失败：${failCount}个，具体失败原因如下：`,
        columns: getErrorColumns(),
        ...option,
      });
    } else {
      const first = _.first(failDetails) as any;

      message.error(first?.errorReason);
      callback();
    }
  } else {
    message.success(`${option.display}！`);
    callback();
  }
};

const endOrderconfirm = async (
  ids: number[],
  type: 'multiple' | 'single',
  display: string,
  onSuccess: () => void,
  onFail: () => void,
) => {
  try {
    const response = await fetchPurchaseReturnOrderBatchEnded({ ids });

    if (response && response.code === 200 && response.data) {
      showError(
        response,
        {
          method: 'error',
          okText: '知道了',
          type,
          display,
          onOk: onSuccess,
          onCancel: onSuccess,
        },
        onSuccess,
      );
    }
  } catch (error) {
    onFail?.();
    console.log(error);
  }
};

const endOrder = async (
  ids: number[],
  codes: string[],
  type: 'multiple' | 'single',
  onSuccess: () => void,
  onFail: () => void,
  setLoading?: (loading: boolean) => void,
) => {
  try {
    setLoading?.(true);
    const response = await fetchPurchaseReturnOrderBatchEndedEnsure({ ids });

    if (response && response.code === 200 && response.data) {
      const { successCount, failCount, failDetails } = response.data;
      const hasException = failDetails?.some(
        (failDetail) => failDetail.procStatus === EndResultType.exception,
      );
      const display = hasException ? '异常结束成功' : '结束成功';

      if (failCount === 0) {
        // 校验完全通过
        Modal.confirm({
          title: '结束采购退货单',
          content: `结束采购退货单后不能再进行任何操作，确定结束${codes?.join(', ')}吗？`,
          onOk: () => endOrderconfirm(ids, type, display, onSuccess, onFail),
          onCancel: onFail,
          cancelText: '暂不结束',
          okText: '结束',
        });
      } else {
        // 校验有不通过的数据
        if (type === 'multiple') {
          showEndListMessage({
            title: '提示',
            message: '以下采购退货单结束后不能再进行任何操作，确认结束吗？',
            data: (failDetails as Object[]).map((item, index) => {
              return { ...item, num: index + 1 };
            }),
            columns: getErrorColumns(true),
            onCancel: onFail,
            onOk: () => endOrderconfirm(ids, type, display, onSuccess, onFail),
            successCount,
            failCount,
            cancelText: '暂不结束',
            okText: '结束',
          });
          return;
        }
        // single
        const first = _.first(failDetails) || {};

        Modal.confirm({
          title: '结束采购退货单',
          content: `${first.errorReason}，确定结束采购退货单${first.code}吗？`,
          onCancel: () => onFail?.(),
          onOk: () => endOrderconfirm(ids, type, display, onSuccess, onFail),
          cancelText: '暂不结束',
          okText: '结束',
        });
      }
    }
  } catch (error) {
    onFail?.();
    console.log(error);
  } finally {
    setLoading?.(false);
  }
};

/**
 * 结束操作 END
 */

export { handleDistribute, handleCloseOrder, handleReturnOrder, endOrder };
