import { RecordListLayout } from 'layout';
import { BlSelectedRowKeys } from '@blacklake-web/layout/dist/recordList/recordListLayout.type';
import { Modal } from 'antd';
import React, { useState } from 'react';
import { fetchRoleList, fetchUserList } from 'src/api/ytt/user-domain';
import { UsageStatus } from 'src/dict/common';
import { ALL_USER_FLAG_ID, CURRENT_USER_FLAG_ID } from './constant';
import styles from './styles.module.scss';

export type UserAndRoleModalSelectProps = {
  selectUser: boolean;
  visible?: boolean;
  onClose: () => void;
  width?: string | number;
  closable?: boolean;
  showAllUserFlag?: boolean; // 显示选择所有用户
  showCurrentUserFlag?: boolean; // 显示选择当前登录人
  submitData: (data: any[]) => void;
};

const UserAndRoleModalSelect: React.FC<UserAndRoleModalSelectProps> = (props) => {
  const {
    visible,
    onClose,
    width,
    selectUser,
    closable,
    submitData,
    showAllUserFlag,
    showCurrentUserFlag,
  } = props;
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const columns = [
    {
      title: '编号',
      dataIndex: 'code',
    },
    {
      title: '名称',
      dataIndex: 'name',
    },
  ];

  const formatDataToQuery = (params: any) => {
    const queryParams = { ...params };

    if (params?.quickSearch) {
      queryParams.name = params.quickSearch;
      delete queryParams.quickSearch;
    }
    queryParams.active = UsageStatus.enabled;

    return queryParams;
  };

  const renderMenuList = () => {
    const menuList: any[] = [];

    if (showAllUserFlag) {
      menuList.push({
        title: '所有用户',
        onClick: () => {
          submitData([
            {
              name: '所有用户',
              id: ALL_USER_FLAG_ID,
              allUserFlag: true,
            },
          ]);
          onClose();
        },
      });
    }

    if (showCurrentUserFlag) {
      menuList.push({
        title: '当前登录用户',
        onClick: () => {
          submitData([
            {
              name: '当前登录用户',
              id: CURRENT_USER_FLAG_ID,
              currentUserFlag: true,
            },
          ]);
          onClose();
        },
      });
    }
    return menuList;
  };

  const fetchData = (params: any) => {
    return selectUser ? fetchUserList(params) : fetchRoleList(params);
  };

  return (
    <>
      <Modal
        centered
        visible={visible}
        onCancel={onClose}
        width={width || 800}
        destroyOnClose
        title={selectUser ? '选择用户' : '选择角色'}
        keyboard={false}
        maskClosable={false}
        closable={closable}
        className={styles.addModal}
        onOk={() => {
          submitData(selectedRows);
          onClose();
        }}
      >
        <RecordListLayout<any>
          columns={columns}
          placeholder={'输入名称，回车快速查询'}
          rowKey="id"
          formatDataToQuery={formatDataToQuery}
          configcacheKey="userRole"
          selectedRowKeys={selectedRowKeys}
          onSelectedRowKeys={(selectedRowKeys: BlSelectedRowKeys, selectRows?: any[]) => {
            setSelectedRowKeys(selectedRowKeys as number[]);
            setSelectedRows(selectRows || []);
          }}
          mainMenu={renderMenuList()}
          requestFn={(params) => fetchData(params)}
        />
      </Modal>
    </>
  );
};

export default UserAndRoleModalSelect;
