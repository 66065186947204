import { useCallback, useEffect, useState } from 'react';
import { DetailLayoutForDrawer, DetailLayoutInfoBlock, DetailLayout } from 'layout';
import { STATUS_VALUE, STATUS_TYPE, STATUS_OPERATE } from './constants';
import { Badge, message } from 'antd';
import {
  fetchLocationGetById,
  fetchLocationDeactivate,
  fetchLocationActivate,
} from 'src/api/ytt/resource-domain/areaConfig';
import { avatarDisplay } from 'src/components/avatar/show';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import authDict from 'src/utils/auth';
import Action from './action';
interface DetailContentProps {
  // id: number;
  // refreshTable: () => void;
  // onCloseDetail: () => void;
  // showEdit?: () => void;
  // showLog?: () => void;
  history?: any;
}
interface AreaDetaulProps extends DetailContentProps {
  visible: boolean;
}

const DetailContent = (props: DetailContentProps) => {
  const { history } = props;
  const [detailData, setDetailData] = useState({} as any);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [actionVisible, setActionVisible] = useState<boolean>(false);

  const searchId = history?.location?.search?.split('id=')[1];

  const fetchData = async () => {
    try {
      const res = await fetchLocationGetById({ id: searchId });

      setDetailData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // if (history?.location?.search) {
    //   searchId && fetchData(searchId);
    // } else {
    fetchData();
    // }
  }, [searchId]);

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      { label: '区域编号', dataIndex: 'code' },
      { label: '区域名称', dataIndex: 'name', render: (text: string) => text ?? '-' },
      { label: '区域层级', dataIndex: 'level', render: (text: string) => text ?? '-' },
      { label: '上级区域', dataIndex: 'parentName', render: (text: string) => text ?? '-' },
      {
        label: '负责人',
        dataIndex: 'manager',
        render: (manager: any) => (manager?.id ? avatarDisplay({ ...manager, isUser: true }) : '-'),
      },
      {
        label: '状态',
        dataIndex: 'status',
        render: (status: number) => (
          <span>
            <Badge status={status === STATUS_VALUE.ENABLED ? 'success' : 'default'} />
            {STATUS_TYPE.get(status)}
          </span>
        ),
      },
      {
        label: '备注',
        dataIndex: 'remark',
        render: (desc: string) => desc || '-',
      },
    ],
    column: 2,
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: any) => {
          if (!creator) {
            return '-';
          }
          return avatarDisplay({ ...creator, isUser: true });
        },
      },
      { label: '创建时间', dataIndex: 'createdAt' },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: any) => {
          if (!operator) {
            return '-';
          }
          return avatarDisplay({ ...operator, isUser: true });
        },
      },
      { label: '更新时间', dataIndex: 'updatedAt' },
    ],
    column: 2,
  };

  const baseMenu = useCallback(() => {
    return [
      {
        key: 'status',
        onClick: async () => {
          let res = null;

          if (detailData.status === STATUS_VALUE.ENABLED) {
            res = await fetchLocationDeactivate({ ...detailData });
          } else if (detailData.status === STATUS_VALUE.DISABLED) {
            res = await fetchLocationActivate({ ...detailData });
          }
          if (res?.code === 200) {
            message.success(detailData.status === STATUS_VALUE.ENABLED ? '停用成功' : '启用成功');
            // onCloseDetail?.();
            // refreshTable?.();
            fetchData();
          }
        },
        auth: authDict.location_enable_disable,
        title: STATUS_OPERATE.get(detailData.status) || '',
      },
      {
        key: 'look',
        onClick: () => setVisibleLog(true),
        auth: authDict.location_operrecord,
        title: '操作记录',
      },
      {
        key: 'edit',
        onClick: () => setActionVisible(true),
        icon: <BlIcon type="iconbianji" />,
        auth: authDict.location_edit,
        title: '编辑',
      },
    ];
  }, [detailData]);

  return (
    <>
      <DetailLayout
        baseMenu={baseMenu()}
        title={'区域详情'}
        info={[baseInfo, otherInfo]}
        dataSource={detailData}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={searchId}
          objectCode={'Location'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      {actionVisible && (
        <Action
          visible={actionVisible}
          id={searchId}
          type={'edit'}
          onCancel={() => setActionVisible(false)}
          refreshTable={fetchData}
        />
      )}
    </>
  );
};

const AreaDetailDrawer = (props: AreaDetaulProps) => {
  const { visible, onCloseDetail } = props;

  return (
    <DetailLayoutForDrawer
      visible={visible}
      onClose={onCloseDetail}
      width={680}
      content={<DetailContent {...props} />}
    />
  );
};

export { AreaDetailDrawer, DetailContent };
