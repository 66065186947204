import React from 'react';
import { Tooltip as AntTooltip } from 'antd';
import { AbstractTooltipProps } from 'antd/lib/tooltip';

export interface BlTooltipProps {
  text?: string; // 要显示的文字
  width?: number | string; // 显示文字的最大宽度
  length?: number; // text最大显示长度,超出长度时显示省略
}

const overflowStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  verticalAlign: 'middle',
};

const Tooltip = (props: BlTooltipProps & AbstractTooltipProps) => {
  const { text, width, length = 15, ...restProps } = props;

  if (typeof text !== 'string') {
    return <AntTooltip title={''} {...restProps} />;
  }

  return (
    <AntTooltip {...restProps} title={text}>
      <span style={{ maxWidth: width, whiteSpace: 'pre', ...overflowStyle }}>{text}</span>
    </AntTooltip>
  );
};

Tooltip.AntTooltip = AntTooltip;

export default Tooltip;

export { default as TagTooltip } from './tagTooltip';
export { default as UserTooltip } from './userTooltip';
export { default as LinkTooltip } from './linkTooltip';
