/**
 * @component 标签
 * @doc https://app.mockplus.cn/app/-Fcypa5O95H-/design
 */
import { FC } from 'react';
import { Tag } from 'antd';
import styles from './styles.module.scss';

const BlTag: FC<{
  type?: 'normal' | 'normal-deletable' | 'avatar' | 'avatar-deletable' | 'add';
}> = ({ type = 'normal', children }) => {
  return <Tag className={styles.normal}>{children}</Tag>;
};

export default BlTag;
