/**
 * 列表底部区域，可拖拽源到此添加新的列表项
 */
import { FormInstance } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { FC } from 'react';
import { useDrop } from 'react-dnd';
import { DragItemType, DragItem, GetInitItemDataFn } from './types';

interface Props {
  form: FormInstance;
  listNamePath: NamePath;
  getInitItemData: GetInitItemDataFn;
  forceUpdate?: () => void;
}

const Empty: FC<Props> = ({ form, forceUpdate, listNamePath, getInitItemData }) => {
  const [{ isOver }, drop] = useDrop({
    accept: [DragItemType.source],
    collect(monitor) {
      return { isOver: monitor.isOver() };
    },
    drop(item: DragItem) {
      const list = form.getFieldValue(listNamePath);

      form.setFields([
        {
          name: listNamePath,
          value: [...list, getInitItemData(item, list.length)],
        },
      ]);
      forceUpdate && forceUpdate();
    },
  });

  return (
    <div
      ref={drop}
      style={{
        flex: '1 1',
        background: isOver ? 'rgba(0,0,0,.05)' : '#fafafa',
      }}
    />
  );
};

export default Empty;
