import { useEffect, useState } from 'react';
import { Cascader, Divider } from 'antd';
import { BlIcon } from '@blacklake-web/component';
import { CascaderValueType } from 'antd/lib/cascader';
import {
  fetchResourceTypeList,
  FetchResourceTypeListResponse,
} from 'src/api/ytt/resource-domain/resourceCategory';
import { ResourceBusinessType } from 'src/dict/resources';
import { ListItemType } from 'src/types';
import Styles from './index.module.scss';
// import './index.module.scss';
import authDict, { hasAuth } from 'src/utils/auth';

type rCLType = ListItemType<FetchResourceTypeListResponse>;

interface Props {
  onChange?: (value: CascaderValueType) => void;
  value?: string[] | number[];
  placeholder?: string;
  enableAdd?: boolean;
  showSearch?: boolean;
  enableAddLabel?: string;
  defaultOptionsList?: CascaderDataItemType[];
  formatData?: (list: rCLType[]) => CascaderDataItemType[];
  onCreateClick: () => void;
  resFetchData?: () => Promise<CascaderDataItemType[]>;
  params?: any;
  multiple?: boolean;
}

type CascaderDataItemType = {
  label: string;
  value: string;
  children?: CascaderDataItemType[];
  parent?: CascaderDataItemType;
};

const ResourceClassificationCascader = (props: Props) => {
  const {
    onChange,
    onCreateClick,
    value = [],
    enableAdd,
    enableAddLabel,
    placeholder = '请选择',
    formatData,
    defaultOptionsList = [],
    params = { businessType: ResourceBusinessType.parameter, cascade: true },
    resFetchData,
    ...rest
  } = props;
  const [optionsList, setOptionsList] = useState<CascaderDataItemType[]>(defaultOptionsList);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const handelOnChange = (value: CascaderValueType) => {
    onChange && onChange(value);
  };

  const handleFormatData = (list: rCLType[]): CascaderDataItemType[] => {
    const data: any = list?.map((item: rCLType) => {
      if (item.children) {
        return {
          label: item.name,
          value: item.id,
          children: handleFormatData(item.children as rCLType[]),
        };
      }
      return {
        label: item.name,
        value: item.id,
      };
    });

    return data || [];
  };
  const handleFetchData = async () => {
    let selectOptionsList: CascaderDataItemType[] = [];

    const res = await fetchResourceTypeList({
      ...params,
    });

    selectOptionsList = (formatData || handleFormatData)(res?.data?.list!);

    return selectOptionsList;
  };

  const getNode = async () => {
    try {
      setIsLoadingData(true);

      const list: CascaderDataItemType[] = await (resFetchData || handleFetchData)();

      if (optionsList.length !== 0) {
        setOptionsList([...optionsList]);
      } else {
        setOptionsList([...list]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    getNode();
  }, []);

  return (
    <Cascader
      placeholder={placeholder}
      value={isLoadingData ? [] : value}
      options={optionsList}
      onChange={handelOnChange}
      dropdownRender={(menus) => (
        <div className={Styles.resourceClassificationDropdownRender}>
          {/* <div className={'resourceClassificationDropdownRender'}> */}
          {menus}
          {hasAuth(authDict.resourcetype_add) && enableAdd && (
            <>
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: '6px 12px' }}>
                <a
                  style={{
                    width: '100%',
                    flex: 'none',
                    display: 'block',
                    cursor: 'pointer',
                  }}
                  onClick={onCreateClick}
                >
                  <BlIcon type="icontianjia" /> {enableAddLabel}
                </a>
              </div>
            </>
          )}
        </div>
      )}
      {...rest}
    />
  );
};

export default ResourceClassificationCascader;
