/* eslint-disable indent */
import { useEffect, useState, useReducer } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { message as Message, Form, Input, Radio, DatePicker, Checkbox, Button } from 'antd';
import moment, { Moment } from 'moment';
import _ from 'lodash';

import { DataFormLayout } from 'src/layout';
import UserOrDepartmentSelectWithDialog, {
  LabelInValueSelectorProps,
} from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { SearchSelect } from 'src/components';
import {
  numberAlphabetSpecialSymbols,
  numberChineseAlphabetSpecialSymbols,
  withoutSpaceBothSides,
} from 'src/utils/formValidators';
import FitRangePanel from './FitRangePanel';
import CalendarDisplayPanel from './CalendarDisplayPanel';
import SpecialDayTable from './SpecialDayTable';
import { lookup } from 'src/dict';
import { CRUD } from 'src/dict/common';
import { FitRangeType } from 'src/dict/workCalendar';
import { RootState } from 'src/store';
import type {
  OperateModeType,
  SpecialDayDataRecordProps,
  FitRangeDataProps,
  SpecialDayReducerType,
} from '../constants';
import {
  OPERATE_DIALOG_MODE,
  DISPLAY_DATE_FORMAT,
  CalendarType,
  calendarTypeDisplayMap,
  initSpecialDayState,
  SPECIALDAY_REDUCER_TYPE,
  SpecialDayContext,
  FitRangeDataItemProps,
  // checkNever,
} from '../constants';
import {
  FetchWorkerCalendarCreateRequest,
  FetchWorkerCalendarCreateResponse,
  fetchWorkerCalendarCreate,
  FetchWorkerCalendarUpdateRequest,
  FetchWorkerCalendarUpdateResponse,
  fetchWorkerCalendarUpdate,
  fetchWorkerCalendarGet,
  FetchWorkerCalendarGetResponse,
} from 'src/api/ytt/calendar-domain';
import styles from './index.module.scss';

interface OperateCalendarProps {
  id?: string;
}

type WorkCalendarDataProps = FetchWorkerCalendarGetResponse['data'];

const { useForm } = Form;

const specialDayReducer: SpecialDayReducerType = (state, action) => {
  switch (action.type) {
    case SPECIALDAY_REDUCER_TYPE.ShowDialog:
      return {
        ...state,
        specialDay: action.payload,
        showDialog: true,
      };

    case SPECIALDAY_REDUCER_TYPE.HideDialog:
      return { ...state, showDialog: false };

    case SPECIALDAY_REDUCER_TYPE.RefreshCalendar:
      return {
        ...state,
        refreshCalendar: Date.now(),
        displayMoment: action.payload,
      };

    default: {
      // 为eslint的warning先注释掉
      // checkNever(action);

      return state;
    }
  }
};

// 重构以后，离开新建/编辑页面的方法变成了路径跳转，而非关闭弹窗
const goBackToList = () => {
  window.reactRouterHistoryInstance.push('/modeling/calendarManagement/workCalendar');
};

// 重构以后，排班弹窗变成跳转排班页面
const goToSchedule = (id: number, startTime: number, endTime: number) => {
  window.reactRouterHistoryInstance.push(
    `/modeling/calendarManagement/workCalendar/schedule?id=${id}&startTime=${startTime}&endTime=${endTime}`,
  );
};

// 传给form的设置
// preserve: 在删除字段时保留字段值
// antd的form会在rerender的时候删除所有字段，如果该字段为false，使用form全程不可以rerender
const formProps = {
  preserve: true,
};

export default function OperateCalendar(props: RouteComponentProps<OperateCalendarProps>) {
  const { match, history } = props;
  const calendarId = match.params.id;
  const lastPath = history.location.pathname.split('/').pop();
  let mode: OperateModeType;

  if (lastPath?.startsWith('create')) {
    mode = OPERATE_DIALOG_MODE.CREATE;
  } else if (lastPath?.startsWith('edit')) {
    mode = OPERATE_DIALOG_MODE.EDIT;
  } else if (lastPath?.startsWith('copy')) {
    mode = OPERATE_DIALOG_MODE.COPY;
  } else {
    // 其实永远也不会有hide了
    mode = OPERATE_DIALOG_MODE.HIDE;
  }

  const [calendarType, setCalendarType] = useState<number>(CalendarType.Fix); // 当前日历类型，固定班制或者排班制
  // 排班规则下拉框选项数据
  const [continueToCreate, setContinueToCreate] = useState(mode === OPERATE_DIALOG_MODE.CREATE);
  const [calendarFormLoading, setCalendarFormLoading] = useState(false);
  const [clearCalendarFlag, setClearCalendarFlag] = useState(0);
  const [specialDayState, dispatch] = useReducer<SpecialDayReducerType>(
    specialDayReducer,
    initSpecialDayState,
  );

  const [operateForm] = useForm(); // 用以传给DataFormLayout的form instance
  const currentUser = useSelector((state: RootState) => state.user.userInfo);
  let dialogTitle = '';
  const suffixTitle = '工作日历';

  // 根据当前的操作类型指定对话框的标题
  switch (mode) {
    case OPERATE_DIALOG_MODE.CREATE:
      dialogTitle = `${lookup('crud', CRUD.create)}${suffixTitle}`;
      break;

    case OPERATE_DIALOG_MODE.EDIT:
      dialogTitle = `${lookup('crud', CRUD.edit)}${suffixTitle}`;
      break;

    case OPERATE_DIALOG_MODE.COPY:
      dialogTitle = `${lookup('crud', CRUD.copy)}${suffixTitle}`;
      break;

    case OPERATE_DIALOG_MODE.HIDE:
      break;

    default:
      // 为eslint的warnig先注释掉
      // checkNever(mode);
      break;
  }

  // 显示特殊日弹框，payload表示编辑特殊日的表单数据，没有则为新建
  // 由于日历预览的tooltip可以发起弹框，跨组件通信加入useReducer
  const showSpecialDayDialog = (specialDay?: SpecialDayDataRecordProps) => {
    dispatch?.({ type: SPECIALDAY_REDUCER_TYPE.ShowDialog, payload: specialDay });
  };

  // 关闭特殊日弹框
  // 由于日历预览的tooltip可以发起弹框，跨组件通信加入useReducer
  const hideSpecialDayDialog = () => {
    dispatch?.({ type: SPECIALDAY_REDUCER_TYPE.HideDialog });
  };

  // 刷新工作日历预览，payload是刷新之后显示的日历页
  // 特殊日更新之后需要刷新日历，跨组件通信加入useReducer
  const refreshCalendar = (displayMoment?: Moment) => {
    dispatch?.({ type: SPECIALDAY_REDUCER_TYPE.RefreshCalendar, payload: displayMoment });
  };

  /** 校验结束日期：不能早于开始日期，不能晚于开始日期后一年 */
  const disableEndDate = (current: Moment) => {
    const currentStartDate: Moment = operateForm.getFieldValue('startDate');

    if (currentStartDate) {
      const maxDate = currentStartDate.clone().add(1, 'year'); // moment add直接操作原来的对象，所以必须clone出来再add

      return current.isBefore(currentStartDate, 'day') || current.isSameOrAfter(maxDate, 'day');
    }
    return false;
  };

  /** 校验开始日期，不能晚于结束日期 */
  const disableStartDate = (current: Moment) => {
    const currentEndDate: Moment = operateForm.getFieldValue('endDate');

    if (currentEndDate) {
      return current.isAfter(currentEndDate, 'day');
    }
    return false;
  };

  const clearCalendar = () => {
    setClearCalendarFlag(Date.now());
  };

  // 排班规则下拉框，只有固定班制才有
  const shiftRuleFormItem = {
    label: '排班规则',
    name: 'shiftRule',
    rules: [{ required: true, message: '排班规则必选' }],
    render: () => <SearchSelect fetchType="shiftRuleList" />,
  };

  // 排班制适用范围：选择用户
  const fitRangeForFlexFormItems = {
    column: 2,
    title: '适用范围',
    items: [
      {
        label: '用户',
        name: 'fitRanges',
        rules: [{ required: true, message: '适用范围必填' }],
        render: () => (
          <UserOrDepartmentSelectWithDialog placeholder="请选择" isMultiple isSelectUser />
        ),
      },
    ],
  };

  // 固定班制适用范围和日历
  const fitRangeAndCalendarForFix = {
    span: 2,
    isFullLine: true,
    render: () => {
      return (
        <div className={styles.fitRangeAndCalendar}>
          <Form.Item
            style={{ height: '100%' }}
            name="fitRanges"
            rules={[{ required: true, message: '适用范围必选' }]}
          >
            <FitRangePanel className={styles.fitRangePanelForFixCalendar} />
          </Form.Item>
          <CalendarDisplayPanel
            className={styles.calendarDisplayPanel}
            form={operateForm}
            clearFlag={clearCalendarFlag}
          />
        </div>
      );
    },
  };

  // 固定班制的特殊日表格
  const specialDayForFixFormItems = {
    column: 1,
    title: '特殊日',
    items: [
      {
        name: 'specialDays',
        isFullLine: true,
        render: () => <SpecialDayTable calendarForm={operateForm} />,
      },
    ],
  };

  // 编辑原因：只在编辑模式下可用
  const editReasonFormItems = {
    column: 1,
    title: '编辑原因',
    items: [
      {
        name: 'updateReason',
        isFullLine: true,
        rules: [{ max: 1000, message: '不可超过1000个字符' }],
        render: () => (
          <Input.TextArea
            rows={1}
            maxLength={1000}
            style={{ marginBottom: '40px' }}
            showCount
            placeholder="请输入"
          />
        ),
      },
    ],
  };

  // DataFormLayout内嵌form的渲染内容，数组里的每一项是InfoBlock
  const operateCalendarFormItems = [
    {
      column: 2,
      title: '基本信息',
      items: [
        {
          label: '日历编号',
          name: 'code',
          rules: [
            { required: true, message: '日历编号必填' },
            numberAlphabetSpecialSymbols,
            withoutSpaceBothSides,
            { max: 256, message: '不可超过255个字符' },
          ],
          render: () => <Input placeholder="请输入" allowClear />,
        },
        {
          label: '日历名称',
          name: 'name',
          rules: [
            { required: true, message: '日历名称必填' },
            numberChineseAlphabetSpecialSymbols,
            withoutSpaceBothSides,
            { max: 256, message: '不可超过255个字符' },
          ],
          render: () => <Input placeholder="请输入" allowClear />,
        },
        {
          label: '开始日期',
          name: 'startDate',
          rules: [{ required: true, message: '开始日期必选' }],
          render: () => (
            <DatePicker allowClear disabledDate={disableStartDate} style={{ width: '100%' }} />
          ),
        },
        {
          label: '结束日期',
          name: 'endDate',
          rules: [{ required: true, message: '结束日期必选' }],
          render: () => (
            <DatePicker allowClear disabledDate={disableEndDate} style={{ width: '100%' }} />
          ),
        },
        {
          label: '负责人',
          name: 'managers',
          rules: [{ required: true, message: '负责人必选' }],
          render: () => (
            <UserOrDepartmentSelectWithDialog placeholder="请选择" isMultiple isSelectUser />
          ),
        },
        {
          label: '日历类型',
          name: 'type',
          rules: [{ required: true, message: '日历类型必选' }],
          render: () => (
            <div>
              <Radio.Group
                value={calendarType}
                disabled={mode === OPERATE_DIALOG_MODE.EDIT}
                onChange={(e) => {
                  setCalendarType(e.target.value);
                  // 两种班制下的日历适用范围数据格式都不一样，一旦切换日历类型，必须清空适用范围
                  operateForm.setFieldsValue({ fitRanges: undefined });
                }}
              >
                <Radio value={CalendarType.Fix}>{calendarTypeDisplayMap[CalendarType.Fix]}</Radio>
                <Radio value={CalendarType.Flex}>{calendarTypeDisplayMap[CalendarType.Flex]}</Radio>
              </Radio.Group>
              {calendarType === CalendarType.Fix ? (
                <div className={styles.calendarTypeHintFix}>适用范围内工作日历一致</div>
              ) : (
                <div className={styles.calendarTypeHintFlex}>适用范围内每个用户工作日不一致</div>
              )}
            </div>
          ),
        },
        ...(calendarType === CalendarType.Fix
          ? [shiftRuleFormItem, fitRangeAndCalendarForFix]
          : []),
      ],
    },
    ...(calendarType === CalendarType.Fix
      ? [specialDayForFixFormItems]
      : [fitRangeForFlexFormItems]),
    ...(mode === OPERATE_DIALOG_MODE.EDIT ? [editReasonFormItems] : []),
  ];

  const initCreateForm = () => {
    // 新建工作日历，没有calendarId
    // 清空表单是为了连续新建模式
    operateForm.resetFields();
    // 新建工作日历时，只有日历类型是有默认值的，默认值为固定班制
    // 负责人默认为当前用户
    operateForm.setFieldsValue({
      type: CalendarType.Fix,
      managers: [{ label: currentUser.name, value: currentUser.id }],
    });
    // 清空日历预览
    clearCalendar();
  };

  // 根据load工作日历的返回值初始化edit/copy时的表格
  // 固定班制copy范围包含特殊日
  const initEditAndCopyForm = (data: WorkCalendarDataProps) => {
    if (data) {
      const { startTime, endTime, managers, type, constantVo, shiftVos } = data;
      const startDate = moment(startTime); // 为了给form里的antd的datepicker识别而做的格式转化
      const endDate = moment(endTime); // 为了给form里的antd的datepicker识别而做的格式转化
      const fitRangeDataTemp: FitRangeDataProps = {
        [FitRangeType.users]: [],
        [FitRangeType.departments]: [],
        [FitRangeType.equipments]: [],
        [FitRangeType.workCenters]: [],
      };

      setCalendarType(type);
      // 当工作日历类型为固定班制，constVo才有值
      if (constantVo) {
        const { fitRanges, shiftRule, specialDays } = constantVo;

        // 把从后台取到的工作日历的fitRanges字段map到适合FitRangePanel组件渲染的数据类型
        fitRanges?.forEach((fitRangeItem) => {
          fitRangeDataTemp[fitRangeItem.businessType as FitRangeType]?.push(fitRangeItem);
        });

        // 初始化回填固定班制排班规则的值
        const shiftRuleForFixCalendar = {
          label: shiftRule.shiftName,
          value: shiftRule.shiftRuleId,
        };

        const specialDaysWithIndex = specialDays?.map((spDay) => ({
          ...spDay,
          specialDayIndex: spDay.specialDayId,
        }));

        // 把其他字段填到form中，其中负责人需要map成select识别的格式
        operateForm.setFieldsValue({
          ...data,
          startDate,
          endDate,
          type,
          // 前端用specialDayIndex字段当作特殊日的key，后台返回的数据没有这个字段，取id给它赋值
          specialDays: specialDaysWithIndex,
          managers: managers.map((manager) => ({
            value: manager.managerId,
            label: manager.managerName,
          })),
          fitRanges: fitRangeDataTemp,
          shiftRule: shiftRuleForFixCalendar,
        });
      } else if (shiftVos) {
        operateForm.setFieldsValue({
          ...data,
          startDate,
          endDate,
          type,
          managers: managers.map((manager) => ({
            value: manager.managerId,
            label: manager.managerName,
          })),
          fitRanges: shiftVos.map((shiftVo) => ({
            label: shiftVo.userVO?.name,
            value: shiftVo.userVO?.id,
          })),
        });
      }
    }
  };

  const loadCalendarDetail = async (calendarId: number) => {
    setCalendarFormLoading(true);
    const response = await fetchWorkerCalendarGet({ id: calendarId }, { legacy: true });

    // 后台：当业务逻辑出错， 返回response的code可能不是200.
    // 比如请求一个已经删除的工作日历
    // 在这种情况下，错误信息保存在response.message里.
    if (response.code === 200) {
      // 编辑或复制工作日历时，把传进来的calendarData回填到本弹窗的表单上
      initEditAndCopyForm(response.data);
    } else {
      Message.error(response.message);
    }
    setCalendarFormLoading(false);
  };

  /** 编辑工作日表单填写完成，保存按钮点击之后，从表单中获取填写数据转换成api中要求的格式
   * @return 被格式化完的数据，符合后台格式的calendar数据
   */
  const formatFormDataToSave = async () => {
    try {
      await operateForm.validateFields();
      const formDirty = operateForm.isFieldsTouched([], true);

      if (!formDirty) {
        goBackToList();
        return;
      }

      const calendarFormObj = operateForm.getFieldsValue();
      const {
        startDate,
        endDate,
        managers,
        shiftRule,
        specialDays,
        fitRanges,
        code,
        name,
        updateReason,
      } = calendarFormObj;
      const calendarObjToSave = {
        code,
        name,
        updateReason,
        startTime: startDate.startOf('day').valueOf(),
        endTime: endDate.startOf('day').valueOf(),
        shiftRuleId: shiftRule?.value,
        type: calendarType,
        managerIds: managers.map((manager: LabelInValueSelectorProps) => manager.value),
        specialDayConfigs: specialDays,
        fitRangeList:
          calendarType === CalendarType.Fix
            ? [
                ..._.compact(fitRanges[FitRangeType.users]),
                ..._.compact(fitRanges[FitRangeType.departments]),
                ..._.compact(fitRanges[FitRangeType.equipments]),
                ..._.compact(fitRanges[FitRangeType.workCenters]),
              ].map((fitRange) => ({
                businessId: (fitRange as FitRangeDataItemProps).businessId,
                businessType: (fitRange as FitRangeDataItemProps).businessType,
              }))
            : fitRanges.map((fitRange: { value: number; label: string }) => ({
                businessId: fitRange.value,
                businessType: FitRangeType.users,
              })),
      };

      return calendarObjToSave;
    } catch (error) {
      console.log('validate calendar form error: ', error);
      return null;
    }
  };

  // 发送请求保存工作日历数据，分别处理新建复制和编辑
  // 编辑模式需要把工作日历的id作为参数传给后台，否则不用
  const saveCalendar = async (
    calendarToSave: FetchWorkerCalendarCreateRequest | FetchWorkerCalendarUpdateRequest,
  ) => {
    setCalendarFormLoading(true);
    let response: FetchWorkerCalendarCreateResponse | FetchWorkerCalendarUpdateResponse;

    try {
      if (mode === OPERATE_DIALOG_MODE.EDIT && calendarId) {
        response = await fetchWorkerCalendarUpdate({ ...calendarToSave, id: Number(calendarId) });
        if (response && response.code === 200) {
          return {
            startTime: calendarToSave.startTime,
            endTime: calendarToSave.endTime,
            id: Number(calendarId),
          };
        }
        Message.error(response.message);
        return undefined;
      }
      response = await fetchWorkerCalendarCreate(calendarToSave);
      if (response && response.code === 200) {
        const { data } = response as FetchWorkerCalendarCreateResponse;

        return {
          startTime: calendarToSave.startTime,
          endTime: calendarToSave.endTime,
          id: data,
        };
      }
      Message.error(response.message);
      return undefined;
    } catch (error) {
      return undefined;
    } finally {
      setCalendarFormLoading(false);
    }
  };

  // 创建/编辑工作日表单填写完成，保存按钮点击之后，从表单中获取填写数据转换成api中要求的格式, 请求api保存, 并处理保存结果
  const handleCreateOrEditFinish = async () => {
    const calendarObjToSave = await formatFormDataToSave();

    if (calendarObjToSave) {
      const savedCalendarId = await saveCalendar(calendarObjToSave);

      if (savedCalendarId) {
        // 如果当前状态为连续创建，清空表单，设置初始值
        // 只有固定班制的工作日历可以连续新建
        // 否则关闭当前弹框
        if (continueToCreate && calendarType === CalendarType.Fix) {
          initCreateForm();
        } else {
          goBackToList(); // 当前弹窗会销毁，之后不能有任何操作state
        }
      }
    }
  };

  const handleSaveAndSchedule = async () => {
    const calendarObjToSave = await formatFormDataToSave();

    if (calendarObjToSave) {
      const calendarParams = await saveCalendar(calendarObjToSave);

      if (calendarParams && calendarParams.id) {
        // onSchedule(calendarParams);
        goToSchedule(calendarParams.id, calendarParams.startTime, calendarParams.endTime);
      }
    }
  };

  useEffect(() => {
    if (
      (mode === OPERATE_DIALOG_MODE.EDIT || mode === OPERATE_DIALOG_MODE.COPY) &&
      calendarId !== undefined
    ) {
      // 如果是编辑/复制工作日历, 并且calendarId从列表页被传来进来, 用calendarId从后台把该工作日历的详情信息请求回来
      loadCalendarDetail(Number(calendarId));
    } else {
      // 否则为新建状态，初始化表单
      initCreateForm();
    }
  }, []);

  return (
    <SpecialDayContext.Provider
      value={{ specialDayState, showSpecialDayDialog, hideSpecialDayDialog, refreshCalendar }}
    >
      <DataFormLayout
        form={operateForm}
        info={operateCalendarFormItems}
        title={dialogTitle}
        infoBlockStyleProps={{ paddingBottom: 0, borderBottom: 'none' }}
        loading={calendarFormLoading}
        onCancel={goBackToList}
        onFinish={handleCreateOrEditFinish}
        extra={
          <>
            {mode === OPERATE_DIALOG_MODE.CREATE && calendarType === CalendarType.Fix && (
              <Checkbox
                onChange={() => {
                  setContinueToCreate(!continueToCreate);
                }}
                defaultChecked={continueToCreate}
              >
                连续新建
              </Checkbox>
            )}
            {calendarType === CalendarType.Flex && (
              <Button
                type="primary"
                loading={calendarFormLoading}
                onClick={async () => {
                  setContinueToCreate(false);
                  handleSaveAndSchedule();
                }}
              >
                保存并排班
              </Button>
            )}
          </>
        }
        formProps={formProps}
      />
    </SpecialDayContext.Provider>
  );
}
