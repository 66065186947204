import { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import { map, debounce, toNumber, isEmpty, compact } from 'lodash';
import { message, Tabs, Popover, Space, Form, Dropdown, Menu } from 'antd';
import { DetailLayout, DataFormLayoutForModal, DetailLayoutForModal } from 'src/layout';
import { BcAttachmentDetail, BlIcon, QrCode } from 'src/components';
import lookup, { appEnum } from 'src/dict';
import { BusinessStatusEnum, SuspendedStatusEnum } from 'src/dict/productionPlanning';
import { SplitRequisitionOrigin } from 'src/dict/storeRequisition';
import { AvatarDisplay } from 'src/components/avatar/show';
import {
  fetchWorkOrderBaseGet,
  fetchWorkOrderOutputGet,
  fetchWorkOrderInputMaterialGet,
  fetchWorkOrderProcessPlanGet,
  fetchWorkOrderDispatchInfoGet,
  FetchWorkOrderDispatchInfoGetResponse,
  fetchWorkOrderSourceGet,
  fetchWorkOrderPause,
  fetchWorkOrderBatchClose,
  fetchWorkOrderBatchFinish,
  fetchWorkOrderContinue,
} from 'src/api/ytt/med-domain/work_order';
import _Time from 'src/utils/dataTypes/time';
import { replaceSign } from 'src/utils/constants';
import authDict, { hasAuth } from 'src/utils/auth';
import showConfirmCloseOrder from 'src/page/productionPlanning/productionOrder/list/showConfirmCloseOrder';
import { fetchWorkOrderBatchUpdateDraftFlag } from 'src/api/ytt/med-domain-web';
import CpsiveReservationList from 'src/page/planned/reserve/comprehensiveReservation/list';
import { ReservedOrderType } from 'src/page/planned/planningOperation/constants';
import { useCustomLayoutForDetail } from 'src/components/customLayout/hooks/useCustomLayoutForDetail';
import { gcUrl } from 'src/utils';
import { FieldCategory, ObjectCode } from 'src/dict/common';

import CoproductTab from './coproductDetail';
import SubItemMaterialTab from './subItemMaterialDetail';
import OtherInfoTab from './otherInfoDetail';
import { ProductionOrderTab, ProductionOrderTabMap } from '../constants';
import { toCopyProductionOrder, toEditProductionOrder } from '../navigation';
import ProductionTaskForm from '../creatProductionTask/ProductionTaskForm';
import DetailPage from './processRoutingDetail';
import SourceDetail from './sourceDetail';
import { goToCreate } from '../../storeRequisition/utils';
import { handleBatchMessage } from '../list/productionOrderList';
import { fetchReservedSynthesizeSuperscriptData } from 'src/page/planned/reserve/utils';
import { customLayoutChooseButton } from 'src/components/customLayout/hooks/customLayoutForButton';

interface DetailProps extends RouteChildrenProps<{ id: string }> {}

const Detail = (props: DetailProps) => {
  const { match, history } = props;

  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState<any>();
  const [IssueTask, setIssueTask] = useState<FetchWorkOrderDispatchInfoGetResponse['data']>();
  const [formCloseOrderConFirm] = Form.useForm();
  const [customFields, setCustomFields] = useState<any>({});
  const [cpsiveReservationList, setCpsiveReservationList] = useState<any>(false);

  /** 自定义字段自定义布局 */

  const { layoutId } = gcUrl.getParams();
  const { info, dataSource: customDataSource } = useCustomLayoutForDetail({
    objectCode: ObjectCode.POConfig,
    layoutId,
    instanceId: Number(match?.params?.id),
  });

  const fetchAndSetData = (id?: string) => {
    if (id) {
      setLoading(true);
      const workOrderBaseGet = new Promise((resolve) => {
        fetchWorkOrderBaseGet({ workOrderId: toNumber(id) }).then((res) => {
          const { data } = res;

          resolve(data);
        });
      });

      const workOrderOutputGet = new Promise((resolve) => {
        fetchWorkOrderOutputGet({ workOrderId: toNumber(id) }).then((res) => {
          const { data } = res;

          resolve(data);
        });
      });
      const workOrderInputMaterialGet = new Promise((resolve) => {
        fetchWorkOrderInputMaterialGet({ workOrderId: toNumber(id) }).then((res) => {
          const { data } = res;

          resolve(data);
        });
      });
      const workOrderProcessPlanGet = new Promise((resolve) => {
        fetchWorkOrderProcessPlanGet({ workOrderId: toNumber(id) }).then((res) => {
          const { data } = res;

          if (data?.processConnections) {
            data.processConnections = map(
              data?.processConnections,
              ({ processManufacturedGoods, ...rest }) => ({
                ...rest,
                processManufacturedGoods: {
                  ...processManufacturedGoods,
                  amount: processManufacturedGoods?.dispatchedAmount?.amountDisplay,
                },
              }),
            );
          }
          resolve(data);
        });
      });

      const WorkOrderSourceGet = new Promise((resolve) => {
        fetchWorkOrderSourceGet({ workOrderId: toNumber(id) }).then((res) => {
          const { data } = res;

          resolve(data);
        });
      });

      return Promise.all([
        workOrderBaseGet,
        workOrderOutputGet,
        workOrderInputMaterialGet,
        workOrderProcessPlanGet,
        WorkOrderSourceGet,
      ])
        .then((res) => {
          const dataSourceList = {
            workOrderBaseCO: res[0],
            bomOutputMaterials: res[1],
            bomInputMaterials: res[2],
            processPlanCO: res[3],
            workOrderSource: res[4],
          };

          // setIsVirtualMaterial(judgeMaterialIsVirtual(dataSourceList?.workOrderBaseCO?.material));
          setDataSource(dataSourceList);
          return res;
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    }

    return Promise.reject();
  };

  useEffect(() => {
    fetchAndSetData(match?.params?.id)
      .catch(() => {
        history.goBack();
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  /**
   * 刷新页面
   */
  const refreshPage = () => {
    fetchAndSetData(match?.params?.id).finally(() => {
      setLoading(false);
    });
  };

  const renderTabs = () => {
    const tabWithComponent = new Map([
      [ProductionOrderTab.SUB_ITEM_MATERIAL, <SubItemMaterialTab dataSource={dataSource} />],
      [ProductionOrderTab.COPRODUCT, <CoproductTab dataSource={dataSource} />],
      [ProductionOrderTab.OTHER, <OtherInfoTab dataSource={dataSource} />],
      [ProductionOrderTab.PROCESS_PLAN, <DetailPage dataSource={dataSource?.processPlanCO} />],
      [ProductionOrderTab.SOURCE, <SourceDetail dataSource={dataSource} />],
    ]);

    return (
      <Tabs type="card">
        {map(Array.from(ProductionOrderTabMap.keys()), (key) => {
          const title = ProductionOrderTabMap.get(key)?.title;

          return (
            <Tabs.TabPane key={key} tab={title} style={{ height: '100%' }}>
              {tabWithComponent.get(key)}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    );
  };

  const formatCustomField = () => {
    return info.map((item: any) => {
      return {
        ...item,
        items: item?.items?.filter((e: any) => e?.fieldCategory === FieldCategory.customFields),
      };
    });
  };

  const baseInfo = [
    {
      title: '基本信息',
      items: [
        {
          label: '工单编号',
          dataIndex: ['workOrderBaseCO', 'code'],
        },
        {
          label: '工单类型',
          dataIndex: ['workOrderBaseCO', 'workOrderType'],
          render: (text: any) =>
            lookup('productionPlanning.ProductionOrderTypesEnumDisplayMap', text),
        },
        {
          label: '业务状态',
          dataIndex: ['workOrderBaseCO', 'status'],
          render: (text: any, record: any) => {
            if (text === BusinessStatusEnum.CLOSED) {
              return `${lookup('productionPlanning.BusinessStatusEnumDisplayMap', text)}(${lookup(
                'productionPlanning.WorkOrderCloseTypeMap',
                record.workOrderBaseCO.workOrderCloseType,
              )})`;
            }
            return lookup('productionPlanning.BusinessStatusEnumDisplayMap', text);
          },
        },
        {
          label: '暂停状态',
          dataIndex: ['workOrderBaseCO', 'pauseFlag'],
          render: (text: any) => lookup('productionPlanning.SuspendedStatusEnumDisplayMap', text),
        },
        {
          label: '标识码',
          dataIndex: ['workOrderBaseCO', 'identifier'],
          render: (text: string) => {
            return text ? (
              <Space>
                {text}
                <Popover
                  placement="rightTop"
                  content={
                    <div>
                      <QrCode value={text} />
                      <div style={{ textAlign: 'center' }}>{text}</div>
                    </div>
                  }
                >
                  <BlIcon type="iconerweima" style={{ fontSize: 20 }} />
                </Popover>
              </Space>
            ) : (
              replaceSign
            );
          },
        },
        {
          label: '指定用料',
          dataIndex: ['workOrderBaseCO', 'specifiedMaterial'],
          render: (text: any) => lookup('common.yn', text),
        },
        {
          label: '计划开始时间',
          dataIndex: ['workOrderBaseCO', 'plannedStartTime'],
          render: (text: any) => (text ? _Time.format(text) : replaceSign),
        },
        {
          label: '计划结束时间',
          dataIndex: ['workOrderBaseCO', 'plannedFinishTime'],
          render: (text: any) => (text ? _Time.format(text) : replaceSign),
        },
        {
          label: '设备',
          dataIndex: ['workOrderBaseCO', 'resource', 'name'],
        },
        {
          label: '班次',
          dataIndex: ['workOrderBaseCO', 'shift', 'name'],
        },
        {
          label: '生产部门',
          dataIndex: ['workOrderBaseCO', 'productionDepartment', 'name'],
        },
        {
          label: '生产主管',
          dataIndex: ['workOrderBaseCO', 'productionSupervisor'],
          render: (text: any) => {
            return text?.id && <AvatarDisplay {...(text ?? {})} isShowTag={false} isUser />;
          },
        },
        {
          label: '计划部门',
          dataIndex: ['workOrderBaseCO', 'planningDepartment', 'name'],
        },
        {
          label: '计划员',
          dataIndex: ['workOrderBaseCO', 'planningUser'],
          render: (text: any) => {
            return text?.id && <AvatarDisplay {...(text ?? {})} isShowTag={false} isUser />;
          },
        },
        {
          label: '文件',
          dataIndex: ['workOrderBaseCO', 'files'],
          render: (value: number[]) => <BcAttachmentDetail fileIds={value} />,
        },
      ],
    },
    ...formatCustomField(),
    {
      title: '详细信息',
      items: [
        {
          dataIndex: 'xxx',
          render: () => renderTabs(),
        },
      ],
    },
  ];

  const getBaseMenu: any = compact([
    {
      title: lookup('crud', appEnum.Common.CRUD.copy),
      auth: authDict.productionorder_edit,
      buttonRender: customLayoutChooseButton({
        title: lookup('crud', appEnum.Common.CRUD.copy),
        objectCode: ObjectCode.POConfig,
        type: 'edit',
        buttonType: 'primary',
        instanceId: Number(match?.params?.id),
        onClick: (layoutId: number) => {
          match?.params?.id &&
            history.push(toCopyProductionOrder(Number(match?.params?.id), layoutId));
        },
      }),
    },
    {
      title: lookup('crud', appEnum.Common.CRUD.edit),
      auth: authDict.productionorder_edit,
      hidden: [BusinessStatusEnum.CLOSED, BusinessStatusEnum.COMPLETED].includes(
        dataSource?.workOrderBaseCO?.status,
      ),
      buttonRender: customLayoutChooseButton({
        title: lookup('crud', appEnum.Common.CRUD.edit),
        objectCode: ObjectCode.POConfig,
        type: 'edit',
        buttonType: 'primary',
        icon: <BlIcon type="iconbianji" />,
        instanceId: Number(match?.params?.id),
        onClick: (layoutId: number) => {
          match?.params?.id &&
            history.push(toEditProductionOrder(Number(match?.params?.id), layoutId));
        },
      }),
    },
  ]).filter((e) => !e.hidden);

  const menu = () => {
    const { pauseFlag, specifiedMaterial, status } = dataSource?.workOrderBaseCO || {};

    const disabledFlag =
      [
        BusinessStatusEnum.CREATED,
        BusinessStatusEnum.IN_PROGRESS,
        BusinessStatusEnum.SCHEDULED,
      ].includes(status) &&
      [SuspendedStatusEnum.NOTSUSPENDED].includes(pauseFlag) &&
      specifiedMaterial === appEnum.Common.YN.yes;

    // 下发条件
    const issuedConditions =
      [SuspendedStatusEnum.NOTSUSPENDED].includes(pauseFlag) &&
      [
        BusinessStatusEnum.CREATED,
        BusinessStatusEnum.IN_PROGRESS,
        BusinessStatusEnum.SCHEDULED,
      ].includes(status);

    return (
      <Menu>
        {hasAuth(authDict.productionorder_close) && (
          <Menu.Item
            disabled={status === BusinessStatusEnum.CLOSED}
            onClick={() => {
              setLoading(true);
              showConfirmCloseOrder(formCloseOrderConFirm, () => {
                fetchWorkOrderBatchClose({
                  workOrderCloseType: formCloseOrderConFirm.getFieldValue('workOrderCloseType'),
                  operateReason: formCloseOrderConFirm.getFieldValue('operateReason'),
                  workOrderIdList: [Number(match?.params?.id)],
                })
                  .then((res) => {
                    formCloseOrderConFirm.resetFields();
                    if (!res.data?.failAmount) refreshPage();
                    handleBatchMessage('关闭', res.data);
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              });
            }}
          >
            关闭
          </Menu.Item>
        )}
        {hasAuth(authDict.productionorder_progress_finish) && (
          <Menu.Item
            onClick={() => {
              setLoading(true);
              fetchWorkOrderBatchFinish({
                idList: [Number(match?.params?.id)],
              })
                .then((res) => {
                  if (!res.data?.failAmount) refreshPage();
                  handleBatchMessage('完工', res.data);
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          >
            完工
          </Menu.Item>
        )}
        {hasAuth(authDict.productionorder_stop) && (
          <Menu.Item
            onClick={() => {
              setLoading(true);
              fetchWorkOrderPause({
                workOrderId: Number(match?.params?.id),
              })
                .then(() => {
                  refreshPage();
                  message.success('暂停成功');
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          >
            暂停
          </Menu.Item>
        )}
        {hasAuth(authDict.productionorder_continue) && (
          <Menu.Item
            onClick={() => {
              setLoading(true);
              fetchWorkOrderContinue({
                workOrderId: Number(match?.params?.id),
              })
                .then(() => {
                  refreshPage();
                  message.success('继续成功');
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          >
            继续
          </Menu.Item>
        )}
        {disabledFlag && (
          <Menu.Item
            onClick={() => {
              const workOrderIds = [Number(match?.params?.id)];

              goToCreate(workOrderIds, SplitRequisitionOrigin.productionOrder);
            }}
          >
            申请领料
          </Menu.Item>
        )}
        {issuedConditions && hasAuth(authDict.productionorder_dispatch) && (
          <Menu.Item
            onClick={() => {
              fetchWorkOrderDispatchInfoGet({ workOrderIds: [Number(match?.params?.id)] }).then(
                (res) => {
                  const { data } = res;

                  setIssueTask(data);
                },
              );
            }}
          >
            {lookup('crud', appEnum.Common.CRUD.issued)}
          </Menu.Item>
        )}
        {[BusinessStatusEnum.DRAFT].includes(status) && (
          <Menu.Item
            onClick={debounce(async () => {
              await fetchWorkOrderBatchUpdateDraftFlag({
                idList: [match?.params?.id],
              }).then((res) => {
                refreshPage();
                handleBatchMessage('提交', {
                  ...res.data,
                  successAmount: res.data?.successCount ?? 0,
                });
              });
            }, 1500)}
          >
            提交
          </Menu.Item>
        )}

        {![BusinessStatusEnum.CLOSED].includes(status) && hasAuth(authDict.PlanReserved_detail) && (
          <Menu.Item
            onClick={debounce(async () => {
              fetchReservedSynthesizeSuperscriptData({
                orderId: Number(match?.params?.id),
                orderType: ReservedOrderType.PRODUCT_ORDER,
              }).then((reservedCommonData) => {
                if (!reservedCommonData) {
                  message.error('暂无预留');
                  return;
                }
                setCpsiveReservationList({
                  data: reservedCommonData,
                  param: {
                    orderId: match?.params?.id,
                    orderType: ReservedOrderType.PRODUCT_ORDER,
                  },
                });
              });
            }, 1500)}
          >
            综合查询
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const getExtraMenu = () => {
    return (
      <Dropdown.Button overlay={menu()} type={'primary'}>
        操作
      </Dropdown.Button>
    );
  };

  return (
    <>
      <DetailLayout
        title="生产工单详情"
        info={baseInfo}
        dataSource={{ ...dataSource, ...customDataSource }}
        baseMenu={getBaseMenu}
        loading={loading}
        extra={getExtraMenu()}
      />
      <DataFormLayoutForModal
        visible={!isEmpty(IssueTask)}
        onClose={() => {
          setIssueTask(undefined);
        }}
        content={
          <ProductionTaskForm
            name="dispatchRequests"
            params={IssueTask}
            history={history}
            onCancel={() => {
              setIssueTask(undefined);
            }}
          />
        }
      />
      <DetailLayoutForModal
        visible={cpsiveReservationList}
        onClose={() => {
          setCpsiveReservationList(false);
        }}
        content={
          <CpsiveReservationList
            parameter={cpsiveReservationList?.param}
            initData={cpsiveReservationList?.data}
          />
        }
      />
    </>
  );
};

export default Detail;
