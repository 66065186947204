/**
 * @file 在表单中使用的文件上传组件
 *
 * 注：一般情况下应使用通用文件模块 BcUpload、BcAttachment，以关联到业务对象
 * PRD: https://blacklake.feishu.cn/wiki/wikcnKU1uyQ4QTuHEfnJmcY4nTd#M1F17q
 *
 * 只有在无法使用通用文件模块的情况下，使用该组件
 * 与 FileUploader 的区别: 此组件与后端交互都只传文件id
 */
import { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import { Button } from 'antd';
import { BlUpload, BlUploadProps } from '@blacklake-web/component';
import { BlIcon } from 'src/components';
import { fetchFileListByIds } from 'src/api/ytt/holyfile-domain';
import { customUploadFile } from './share';
import type { UploadFileStatus } from 'antd/lib/upload/interface';
import type { FetchFileListByIdsResponse } from 'src/api/ytt/holyfile-domain';

interface Props extends Omit<BlUploadProps, 'onChange'> {
  title?: string;
  value?: number[];
  onChange?: (fileIds: number[]) => void;
}
type fileResponse = Required<Required<FetchFileListByIdsResponse>['data'][number]>;
type FileListItem = {
  status: UploadFileStatus;
  uid: string;
  name: string;
  url: string;
} & fileResponse;

const fileFormatter = (file: fileResponse): FileListItem => ({
  uid: _.toString(file.id),
  name: file.filename,
  url: file.fileUri,
  status: 'done',
  ..._.omit(file, 'status'),
});
const isFileRejected = (file: any) => file.status === 'done' && file.response === null;
const showUploadList = {
  showDownloadIcon: true,
  showRemoveIcon: true,
};

const FileUploaderForm: FC<Props> = (props) => {
  const { title, value, onChange } = props;
  const [fileList, setFileList] = useState<FileListItem[]>([]);

  const getFilesByIds = async (idList: number[]) => {
    const { data } = await fetchFileListByIds({ idList });

    setFileList((data as fileResponse[])?.map(fileFormatter) || []);
  };

  const onFileUploaderChange = (val: any[]) => {
    if (_.isEmpty(val)) {
      return;
    }
    // 存在被后端拦截的文件类型
    if (val.some(isFileRejected)) {
      setFileList(val.filter((file) => !isFileRejected(file)));
      return;
    }
    const nextVal = val.map((file) => {
      if (file.originFileObj && file.status === 'done' && file.response) {
        return {
          ...file,
          id: file.response.id,
          url: file.response.uri,
        };
      }
      return file;
    });
    setFileList(nextVal);
    // 当所有文件都上传完成后，将最新的文件id列表加入表单
    if (nextVal.every((item) => item.status === 'done')) {
      onChange?.(_.map(nextVal, 'id'));
    }
  };

  const onRemoveFile = (file: any) => {
    const nextFileList = fileList.filter(({ id }) => id !== file.id);
    setFileList(nextFileList);
    onChange?.(_.map(nextFileList, 'id'));
  };

  // 数据回填时根据id请求文件内容
  useEffect(() => {
    if (_.isArray(value) && !_.isEmpty(value) && _.isEmpty(fileList)) {
      getFilesByIds(value);
    }
  }, [value]);

  const UploadButton = <Button icon={<BlIcon type="iconfujian" />}>{title ?? '上传附件'}</Button>;
  return (
    <BlUpload
      customRequest={customUploadFile}
      draggable={false}
      fileList={fileList}
      showUploadList={showUploadList}
      canPreview
      onRemove={onRemoveFile}
      onChange={onFileUploaderChange}
      overCountNode={UploadButton}
      {..._.omit(props, 'value', 'onChange')}
    >
      {UploadButton}
    </BlUpload>
  );
};

export default FileUploaderForm;
