/**
 * @function 『审批中/已通过/已驳回』列表项渲染方法
 */

import { Badge } from 'antd';
import lookup, { pickCode } from 'src/dict';
import { ApprovalStatus, ApprovalTaskStatus } from 'src/dict/approval';

const approvalStatusMap = new Map([
  [
    ApprovalStatus.pending,
    <Badge text={lookup('approval.approvalStatus', ApprovalStatus.pending)} status="processing" />,
  ],
  [
    ApprovalStatus.approved,
    <Badge text={lookup('approval.approvalStatus', ApprovalStatus.approved)} status="success" />,
  ],
  [
    ApprovalStatus.rejected,
    <Badge text={lookup('approval.approvalStatus', ApprovalStatus.rejected)} status="error" />,
  ],
]);

const approvalTaskStatusMap = new Map([
  [
    ApprovalTaskStatus.pending,
    <Badge
      text={lookup('approval.approvalTaskStatus', ApprovalTaskStatus.pending)}
      status="default"
    />,
  ],
  [
    ApprovalTaskStatus.approved,
    <Badge
      text={lookup('approval.approvalTaskStatus', ApprovalTaskStatus.approved)}
      status="success"
    />,
  ],
  [
    ApprovalTaskStatus.rejected,
    <Badge
      text={lookup('approval.approvalTaskStatus', ApprovalTaskStatus.rejected)}
      status="error"
    />,
  ],
  [
    ApprovalTaskStatus.closed,
    <Badge
      text={lookup('approval.approvalTaskStatus', ApprovalTaskStatus.closed)}
      status="default"
    />,
  ],
]);

export const renderApprovalStatus = (enableFlag: ApprovalStatus) =>
  approvalStatusMap.get(pickCode(enableFlag));

export const renderApprovalTaskStatus = (enableFlag: ApprovalStatus) =>
  approvalTaskStatusMap.get(pickCode(enableFlag));
