import React, { useState } from 'react';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { RecordListLayout } from 'layout';
import { dataColumnsForDocuments, documentsFilterList } from '../components/columns';
import { SingleOperationLogComponent, Tooltip } from 'src/components';
import { CustomerDeliveryScheduleNoteListProps } from '../interface';
import { toCreateInvoice_URL, toDetail, toInvoiceList_URL } from '../navigation';
import { replaceSign } from 'src/utils/constants';
import _ from 'lodash';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import { useDispatch } from 'react-redux';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { handleAccept, handleCreateInvoice, handleReject } from '../batchOperate';
import { InvoiceOrigin } from 'src/dict/invoice';
import { fetchCustomerDeliveryScheduleNoteList } from 'src/api/ytt/supply-chain-domain/customer_delivery_schedule_note';
import { appEnum } from 'src/dict';
import TextArea from 'antd/lib/input/TextArea';
import { disabledGrey } from 'src/styles/color';

const SalesOrderDocumentsList = () => {
  const history = useHistory();
  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  const [selectedRows, setSelectRows] = useState<CustomerDeliveryScheduleNoteListProps[]>([]);

  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [salesOrderId, setSalesOrderId] = useState<number>(0);
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [returnReasonForm] = Form.useForm();
  /**
   * 导入导出
   */
  const dispatch = useDispatch();

  const refresh = () => {
    setRefreshMarker(Date.now());
  };
  /**
   * 跳转到新建发货单页面
   */
  const handleJumpToCreateInvoice = (data: CustomerDeliveryScheduleNoteListProps[]) => {
    // 只有执行中的单据支持新建发货单
    const res = _.remove(
      data,
      (n) => n.status === appEnum.CollaborativeDeliverySchedule.OrderStatus.inprogress,
    );

    dispatch({
      type: 'supplyChain/formatterCdsnInvoiceInfo',
      payload: { invoiceType: InvoiceOrigin.collaborativeDeliverySchedule, data: res },
    });
    history.push(toCreateInvoice_URL());
  };
  /**
   * 跳转到发货单列表页面
   */
  const handleJumpToInvoiceList = () => {
    history.push(toInvoiceList_URL());
  };
  /**
   * 退回原因
   */
  const formContent = (
    <div style={{ marginTop: '30px' }}>
      <Form form={returnReasonForm}>
        <Form.Item
          style={{ marginBottom: 0 }}
          // label={'退回原因'}
          name={'reason'}
          rules={[
            {
              max: 1000,
              message: '最多输入1000个字符',
            },
          ]}
        >
          <TextArea placeholder={'请输入退回原因'} rows={4} showCount maxLength={1000} />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0, color: disabledGrey }}>
          确认退回后将给客户发送退回通知
        </Form.Item>
      </Form>
    </div>
  );

  /**
   * 批量操作按钮
   */
  const toolBar = _.compact([
    {
      title: '新建发货单',
      auth: authDict.shipmentsnote_add,
      onClick: (onSuccess: any, onFail: any) => {
        handleCreateInvoice(
          selectedRows,
          () => {
            onSuccess();
            handleJumpToCreateInvoice(selectedRows);
          },
          onFail,
          handleJumpToInvoiceList,
          'multiple',
        );
      },
    },
  ]);
  /**
   * 列操作 ...
   */
  const getOperationList = (record: CustomerDeliveryScheduleNoteListProps) => {
    return [
      {
        title: '查看',
        auth: authDict.CustomerDeliveryScheduleNote_detail,
        onClick: () => {
          history.push(toDetail(record?.id as number));
        },
      },
      {
        title: '操作记录',
        auth: authDict.CustomerDeliveryScheduleNote_operrecord,
        onClick: () => {
          setSalesOrderId(record?.id!);
          setVisibleLog(true);
        },
      },
      {
        title: '确认',
        auth: authDict.CustomerDeliveryScheduleNote_confirm,
        disabled: record?.status !== appEnum.CollaborativeDeliverySchedule.OrderStatus.pending,
        onClick: () => handleAccept([record?.id!], refresh),
      },
      {
        title: '退回',
        auth: authDict.CustomerDeliveryScheduleNote_return,
        disabled: record?.status !== appEnum.CollaborativeDeliverySchedule.OrderStatus.pending,
        onClick: () => handleReject(record, returnReasonForm, formContent, refresh),
      },
      {
        title: '新建发货单',
        auth: authDict.shipmentsnote_add,
        disabled: record?.status !== appEnum.CollaborativeDeliverySchedule.OrderStatus.inprogress,
        onClick: () =>
          handleCreateInvoice(
            [record],
            () => {
              handleJumpToCreateInvoice([record]);
            },
            () => {},
            handleJumpToInvoiceList,
            'single',
          ),
      },
    ];
  };
  /**
   * Columns
   */
  const dataColumns = [
    {
      title: '协同交货计划单编号',
      dataIndex: 'code',
      width: 200,
      sorter: true,
      render: (
        code: string,
        record: CustomerDeliveryScheduleNoteListProps,
        index: number,
        config: any,
      ) => {
        return (
          <>
            {hasAuth(authDict.CustomerDeliveryScheduleNote_detail) ? (
              <a
                onClick={() => {
                  history.push(toDetail(record?.id as number));
                }}
              >
                <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
              </a>
            ) : (
              <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },
    ...dataColumnsForDocuments,
  ];
  /**
   * 传给接口数据处理函数
   */
  const formatDataToQuery = (params: any) => {
    const { createdAt, customerIds, ...rest } = params;

    const relParams = {
      createdAtFrom: createdAt && createdAt[0] ? Date.parse(createdAt[0]) : undefined,
      createdAtTo: createdAt && createdAt[1] ? Date.parse(createdAt[1]) : undefined,
      customerIds: customerIds?.length ? _.map(customerIds, 'value') : null,
      ...rest,
    };

    dispatch.excelImport.updateBusinessData({ businessData: relParams });
    return relParams;
  };
  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (data: any) => {
    const { createdAt } = data;

    const retData = {
      createdAt: createdAt && momentData(createdAt),
      ...data,
    };

    return retData;
  };
  /**
   * RecordListLayout 配置
   */
  const config = {
    rowKey: (record: CustomerDeliveryScheduleNoteListProps) => String(record?.id),
    columns: dataColumns,
    getOperationList,
    batchMenu: toolBar,
    filterList: documentsFilterList, // 筛选列
    formatDataToQuery,
    formatDataToFormDisplay,
  };

  return (
    <>
      <RecordListLayout<CustomerDeliveryScheduleNoteListProps>
        userAuth={getAuthFromLocalStorage()}
        placeholder={'请输入协同交货计划单编号'}
        selectedRowKeys={selectedRowKeys}
        configcacheKey="salesOrderDocuments"
        onSelectedRowKeys={(selectKey: any, selectRows?: any[]) => {
          setSelectRowKeys(selectKey);
          setSelectRows(selectRows ?? []);
        }}
        requestFn={fetchCustomerDeliveryScheduleNoteList}
        refreshMarker={refreshMarker}
        {...config}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={salesOrderId}
          objectCode={OBJECT_OF_CODE.CustomerDeliveryScheduleNote}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default SalesOrderDocumentsList;
