import React, { useState, useEffect } from 'react';
import { DataFormLayout } from 'layout';
import { BlTable } from 'src/components';

import lookup, { appDict, appEnum } from 'src/dict';
import {
  FetchPlanOperationSchemeGetResponse,
  fetchPlanOperationSchemeUpdate,
} from 'src/api/ytt/plo-domain/plannOrderOperation';
import { Form, Input, InputNumber, message, Radio, Select } from 'antd';
import { validateBlInputNumberIntervalInt } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import {
  OperationRangeDisplayMap,
  RequirementPriorityDisplayMap,
  ReserveLiberateDisplayMap,
} from 'src/dict/planned/mappings';
import { OperationRange, RequirementPriority, ReserveLiberate } from 'src/dict/planned';
import { debounce, findIndex, findLastIndex, map } from 'lodash';
import { numberMinMaxCheck } from 'src/utils/formValidators';
import { arrayIsEmpty } from 'src/utils/array';

//

interface FeedingControlDetailProps {
  dataSource: FetchPlanOperationSchemeGetResponse['data'];
  onChange: () => void;
}

const PlanCalculationSchemeEdit = (props: FeedingControlDetailProps) => {
  const { dataSource, onChange } = props;

  const [form] = Form.useForm<any>();

  const [supplySelectedRowKeys, setSupplySelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowKeys, setSelectRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    const { requirementSourceType, supplySourceType } = dataSource || {};
    const supplySourceTypeSelect = supplySourceType?.filter((e) => e.status);
    const requirementSourceTypeSelect = requirementSourceType?.filter((e) => e.status);

    setSupplySelectedRowKeys(map(supplySourceTypeSelect, 'id'));
    setSelectRowKeys(map(requirementSourceTypeSelect, 'id'));

    form.setFields([
      { name: 'supplySourceType', value: dataSource?.supplySourceType },
      { name: 'requirementSourceType', value: dataSource?.requirementSourceType },
      { name: 'id', value: dataSource?.id },
    ]);
  }, [dataSource]);

  const renderRequirementSourceType = () => {
    const columns = [
      {
        title: '单据名称',
        dataIndex: ['orderType'],
        render: (text: any) => lookup('planned.reqSourceTypeNameMap', text),
      },
      {
        title: '单据类型',
        dataIndex: ['orderType'],
        render: (text: any) => lookup('planned.reqSourceTypeTypeMap', text),
      },
    ];

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
      setSelectRowKeys(selectedRowKeys);
      // setShowBatchLine(!_Array.isEmpty(selectedRowKeys));
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys: React.Key[]) => onSelectChange(selectedRowKeys),
    };

    return (
      <BlTable
        scroll={{ x: 'max-content', y: 'auto' }}
        dataSource={dataSource?.requirementSourceType}
        columns={columns}
        pagination={false}
        rowSelection={rowSelection}
        rowKey={(field) => field?.id}
      />
    );
  };

  /**
   * 校验行号,不能重复
   */
  const validatorIndex = (rule: any, value: any) => {
    const listValue = form.getFieldValue('supplySourceType');

    const firstIndex = findIndex(listValue, { priority: value });
    const lastIndex = findLastIndex(listValue, { priority: value });

    if (firstIndex != lastIndex) {
      return Promise.reject('不能有重复的优先级');
    }

    return Promise.resolve('');
  };

  const renderSupplySourceType = () => {
    const columns = [
      {
        title: '单据名称',
        dataIndex: ['orderType'],
        render: (text: any) => lookup('planned.reqSourceTypeNameMap', text),
      },
      {
        title: '单据类型',
        dataIndex: ['orderType'],
        render: (text: any) => lookup('planned.reqSourceTypeTypeMap', text),
      },
      {
        title: '优先级',
        dataIndex: ['priority'],
        render: (text: number, record: any, index: number) => {
          const { id } = record;

          return (
            <Form.Item
              name={['supplySourceType', index, 'priority']}
              rules={[
                { validator: validateBlInputNumberIntervalInt(0, 1000, false, true) },
                {
                  required: Boolean(supplySelectedRowKeys.find((e) => e === id)),
                  message: '优先级必填',
                },
                { validator: validatorIndex },
              ]}
              initialValue={text}
            >
              <InputNumber placeholder="请输入" min={0} />
            </Form.Item>
          );
        },
      },
    ];

    const onSelectChanges = (selectedRowKeys: React.Key[]) => {
      setSupplySelectedRowKeys(selectedRowKeys);
    };

    const rowSelections = {
      selectedRowKeys: supplySelectedRowKeys,
      onChange: (selectedRowKeys: React.Key[]) => onSelectChanges(selectedRowKeys),
    };

    return (
      <BlTable
        scroll={{ x: 'max-content', y: 'auto' }}
        dataSource={dataSource?.supplySourceType}
        columns={columns}
        rowSelection={rowSelections}
        rowKey={(field) => field?.id}
        pagination={false}
      />
    );
  };

  const getBaseInfo = () => {
    const baseInfo = [
      {
        title: '基本信息',
        items: [
          {
            label: '计划方案编号',
            name: ['code'],
            rules: [{ required: true, message: '计划方案编号必填' }],
            initialValue: dataSource?.code,
            render: () => {
              return <Input disabled />;
            },
          },
          {
            label: '计划展望期',
            name: 'expectationDate',
            rules: [
              { required: true, message: '计划展望期必填' },
              {
                validator: numberMinMaxCheck({
                  min: 0,
                  max: 120,
                  minAllowEqual: false,
                  maxAllowEqual: false,
                  fieldName: '计划展望期',
                  isInteger: true,
                }),
              },
            ],
            initialValue: dataSource?.expectationDate,
            render: () => {
              return <InputNumber placeholder="请输入" />;
            },
          },
          {
            label: '计划运算范围',
            name: 'operationRange',
            initialValue: [OperationRange.IndependentDemandPlanOrder],
            render: () => {
              return (
                <Select options={OperationRangeDisplayMap} allowClear disabled mode="multiple" />
              );
            },
          },
          {
            label: '需求优先级',
            name: 'requirementPriority',
            initialValue: RequirementPriority.TimeAscendingOrder,
            render: () => {
              return <Select options={RequirementPriorityDisplayMap} allowClear disabled />;
            },
          },
          {
            label: '预留释放设置',
            name: 'reserveLiberate',
            initialValue: ReserveLiberate.DoNotReleaseReservation,
            render: () => {
              return <Select options={ReserveLiberateDisplayMap} allowClear disabled />;
            },
          },
        ],
      },
      {
        title: '需求设置',
        items: [
          {
            isFullLine: true,
            name: 'requirementSourceType',
            render: () => renderRequirementSourceType(),
          },
        ],
      },
      {
        title: '供应设置',
        items: [
          {
            isFullLine: true,
            name: 'supplySourceType',
            render: () => renderSupplySourceType(),
          },
          {
            label: '是否考虑非主产出',
            name: 'notMainOutput',
            initialValue: appEnum.Common.YN.no,
            render: () => {
              return <Radio.Group disabled options={appDict.common.yn} />;
            },
          },
          {
            label: '是否考虑替代料',
            name: 'replace',
            initialValue: appEnum.Common.YN.no,
            render: () => {
              return <Radio.Group disabled options={appDict.common.yn} />;
            },
          },
          {
            name: 'id',
          },
        ],
      },
    ];

    return baseInfo;
  };

  const formatValueToApi = (values: any) => {
    const { requirementSourceType, supplySourceType, ...rest } = values || {};

    return {
      ...rest,
      requirementSourceType: map(requirementSourceType, ({ id, ...requirementSourceTypeRest }) => ({
        ...requirementSourceTypeRest,
        id,
        status: Number(Boolean(selectedRowKeys.find((e) => e === id))),
      })),
      supplySourceType: map(supplySourceType, ({ id, ...supplySourceTypeRest }) => ({
        ...supplySourceTypeRest,
        id,
        status: Number(Boolean(supplySelectedRowKeys.find((e) => e === id))),
      })),
    };
  };

  const handleFinish = async () => {
    if (arrayIsEmpty(supplySelectedRowKeys)) {
      message.error('供应来源单据必选');
      return;
    }
    try {
      form
        ?.validateFields()
        .then((values) => {
          const value = formatValueToApi(values);

          fetchPlanOperationSchemeUpdate(value).then((res) => {
            const { code = 0 } = res ?? {};

            if (code === 200) message.success('编辑成功!');
            onChange();
          });
        })
        .catch((error) => {
          const { errorFields } = error;

          form.scrollToField(errorFields?.[0]?.name);
        });
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <>
      <DataFormLayout
        title="编辑计划运算方案"
        info={getBaseInfo()}
        form={form}
        onCancel={() => {
          onChange();
        }}
        onFinish={debounce(handleFinish, 1500)}
      />
    </>
  );
};

export default PlanCalculationSchemeEdit;
