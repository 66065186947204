import { useEffect, useState } from 'react';
import { DataFormLayoutInfoBlock } from 'layout';
import { Form, Checkbox, message as Message, Input } from 'antd';
import {
  fetchQcDefectRankCreate,
  fetchQcDefectRankUpdate,
  fetchQcDefectRankDetail,
} from 'src/api/ytt/quality-domain/qc_defect_rank';

import { DetailDataType } from '../interface';
import { useHistory } from 'react-router-dom';
import { CustomDataFormLayout } from 'src/components/customLayout';
import { gcUrl } from 'src/utils';
import _ from 'lodash';
import { formatCustomFieldsInData } from 'src/components/customField';
import { formatCustomFieldValueToSubmit } from 'src/components/customLayout/uitls/dataFormat';

interface createProps {
  match: any;
}

const QcDefectLevelEdit = ({ match }: createProps) => {
  const detailId = Number(match?.params?.id) ?? 0;
  const [modalForm] = Form.useForm();
  const [modalRecord, setModalRecord] = useState<DetailDataType>();
  const { layoutId } = gcUrl.getParams();
  const history = useHistory();

  const fetchData = async () => {
    try {
      const { data } = await fetchQcDefectRankDetail({ id: detailId });

      modalForm.setFieldsValue(data);
      setModalRecord(data);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  useEffect(() => {
    if (detailId) {
      fetchData();
    }
  }, [detailId]);

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();
      const updateFn = detailId ? fetchQcDefectRankUpdate : fetchQcDefectRankCreate;

      value.customFields = formatCustomFieldValueToSubmit(
        modalForm.getFieldValue('customFields'),
        layoutId,
      );
      const { data } = await updateFn({
        ...modalRecord,
        ...value,
      });

      if (data) {
        Message.success(`${detailId ? '编辑' : '创建'}成功`);
        history.go(-1);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <CustomDataFormLayout
      objectCode={'DefectLevel'}
      form={modalForm}
      onFinish={handleFinish}
      title={`${detailId ? '编辑' : '创建'}不良等级`}
      layoutId={layoutId}
      instanceIdParamKey={detailId}
      extraInfoItems={_.compact([
        detailId && {
          label: '编辑原因',
          name: 'operateReason',
          rules: [{ max: 1000, message: '不超过1000个字符' }],
          render: () => <Input.TextArea placeholder="请输入" />,
        },
      ])}
    />
  );
};

export default QcDefectLevelEdit;
