import _ from 'lodash';
import { Divider } from 'antd';
import {
  BlSearchSelect,
  BlSearchSelectProps,
  BlSearchSelectParams,
  BlIcon,
} from '@blacklake-web/component';
import { SelectValue } from 'antd/lib/select';
import { configs, RenderOption } from './configs';

export interface SearchSelectProps<VT>
  extends Omit<BlSearchSelectProps<VT>, 'fetchFn' | 'formatter'> {
  fetchType: keyof typeof configs;
  /**
   * 用户搜索的查询字段名重新赋值
   */
  searchFieldName?: string;
  /**
   * 搜索框展示的label值的字段路径
   */
  labelPath?: string | string[];
  /**
   * 搜索框展示的label值的字段路径
   */
  valuePath?: string;
  /**
   * 自定义处理option
   */
  renderOption?: RenderOption;
}

const SearchSelect = <VT extends SelectValue>(props: SearchSelectProps<VT>) => {
  const { fetchType, searchFieldName, labelPath, valuePath, renderOption, ...rest } = props;
  const {
    fetchFn,
    formatter,
    defaultSearchFieldName = 'quickSearch',
    defaultLabelPath,
    defaultValuePath,
    defaultRenderOption,
  } = configs[fetchType];

  /**
   * 对fetchFn进一步封装处理
   */
  const handleFetchFn = (params: BlSearchSelectParams) => {
    const { searchParams, ...rest } = params;

    const retParams = { ...rest };

    if (searchParams) {
      const fieldName = searchFieldName ?? defaultSearchFieldName;
      _.set(retParams, fieldName, searchParams);
    }

    return fetchFn(retParams);
  };

  /**
   * 对formatter进一步封装处理
   */
  const handleFormatter = (res: any) => {
    const retParams = formatter(
      res,
      labelPath ?? defaultLabelPath,
      valuePath ?? defaultValuePath,
      renderOption ?? defaultRenderOption,
    );

    return retParams;
  };

  return (
    <BlSearchSelect
      fetchFn={handleFetchFn}
      formatter={handleFormatter}
      placeholder="请选择"
      labelInValue
      {...rest}
    />
  );
};

const SearchSelectWithCreate = <VT extends SelectValue>(
  props: SearchSelectProps<VT> & {
    createFieldName: string;
    onCreateClick: () => void;
    enableAdd: boolean;
  },
) => {
  const { createFieldName, onCreateClick, enableAdd, ...restProps } = props;

  return (
    <SearchSelect
      {...restProps}
      dropdownRender={(menu) => {
        return (
          <div>
            {enableAdd && (
              <>
                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: '6px 12px' }}>
                  <a
                    style={{
                      width: '100%',
                      flex: 'none',
                      display: 'block',
                      cursor: 'pointer',
                    }}
                    onClick={onCreateClick}
                  >
                    <BlIcon type="icontianjia" /> 新建{`${createFieldName}`}
                  </a>
                </div>
                <Divider style={{ margin: '4px 0' }} />
              </>
            )}
            {menu}
          </div>
        );
      }}
    />
  );
};

export { SearchSelectWithCreate };
export default SearchSelect;
