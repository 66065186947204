/**
 * @description 电子签名枚举类
 * @author xuzhiyong
 */

/** 启用状态 */
export enum ACTIVE_STATUS_STR {
  /** 已删除 */
  deleted = 0,
  /** 启用中 */
  enabled,
  /** 停用中 */
  disabled,
}

/** 签名规则 */
export enum RULE_TYPE {
  /** 并行 */
  parallel = 0,
  /** 串行 */
  serial,
}
