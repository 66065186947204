import _, { find, first } from 'lodash';
import { LayoutComponentType, LayoutItemType } from 'src/dict/customLayout';
import { FetchLayoutDetailByObjectCodeResponse } from 'src/api/ytt/layout-domain';
import { FormLayoutConfigProps, FormLayoutRootConfigProps } from '../types';
import { FetchCustomObjectDetailResponse } from 'src/api/ytt/custom-object-domain';
import moment from 'moment';
import { FieldCategory, FieldType, TargetType } from 'src/dict/common';
import { choiceToLabelValue } from 'src/page/custom_fields/utils';
import { gcTime } from 'src/utils';

export type EntityFieldDTO = NonNullable<
  Required<FetchLayoutDetailByObjectCodeResponse>['data']['components']
>[number];

type DetailData = Required<FetchCustomObjectDetailResponse>['data'];
export type FieldValueDTO = Required<DetailData>['fields'][number] | EntityFieldDTO;

export const formatLayoutConfigData = (components?: EntityFieldDTO[]) => {
  const containerData: FormLayoutRootConfigProps[] = [];
  const notTitleComponentsList: FormLayoutRootConfigProps = {
    type: LayoutItemType.infoBlock,
    title: '',
    children: [],
  };
  const subTableComponentsList: FormLayoutRootConfigProps = {
    type: LayoutItemType.infoBlock,
    title: '',
    children: [],
  };

  if (_.find(components, (i) => i.type === LayoutComponentType.sonObjectTab)) {
    return formatTabContainerData(components);
  }

  components?.map(({ type, content }) => {
    const contentParams = content ? JSON.parse(content) : {};
    if (type === LayoutComponentType.titlebar) {
      containerData.push({
        type: LayoutItemType.infoBlock,
        title: contentParams?.title || '',
        children: [],
      });
    }
    if (type === LayoutComponentType.field) {
      const fieldData: FormLayoutConfigProps = {
        type: LayoutItemType.field,
        ...contentParams,
      };
      if (_.last(containerData)) {
        _.last(containerData)?.children?.push(fieldData);
      } else {
        notTitleComponentsList.children?.push(fieldData);
      }
    }
    if (type === LayoutComponentType.sonObjectTable) {
      const fieldData: FormLayoutConfigProps = {
        type: LayoutItemType.subTable,
        ...contentParams,
      };
      if (_.last(containerData)) {
        _.last(containerData)?.children?.push(fieldData);
      } else {
        subTableComponentsList.children?.push(fieldData);
      }
    }
  });

  return containerData
    .concat(notTitleComponentsList.children?.length ? notTitleComponentsList : [])
    .concat(subTableComponentsList.children?.length ? subTableComponentsList : []);
};

export const formatTabContainerData = (components?: EntityFieldDTO[]) => {
  let containerData: FormLayoutRootConfigProps[] = [];

  const baseTabData = {
    type: LayoutItemType.tabpane,
    title: '基本信息',
    children: formatLayoutConfigData(
      components?.filter((i) => i.type !== LayoutComponentType.sonObjectTab),
    ),
  };
  const subObjectTabData = components
    ?.filter((i) => i.type === LayoutItemType.tabpane)
    .map(({ type, content }) => {
      const contentParams = content ? JSON.parse(content) : {};

      return {
        type: LayoutItemType.tabpane,
        title: contentParams?.referName,
        objectCode: contentParams?.objectCode,
        children: [
          {
            type: LayoutItemType.infoBlock,
            children: [
              {
                type: LayoutItemType.subTable,
                ...contentParams,
              },
            ],
          },
        ],
      };
    });
  containerData = [
    {
      type: LayoutItemType.tabs,
      children: [baseTabData].concat(subObjectTabData || []),
    },
  ];

  return containerData;
};

export const formatDetailDisplayData = (detailData: DetailData, objectCode?: string) => {
  const { createdAt, creator, operator, updatedAt } = detailData;
  let data: any = {
    createdAt,
    creator,
    operator,
    updatedAt,
  };

  if (objectCode && detailData.objectCode === objectCode) {
    (detailData?.fields || [])?.forEach((field) => {
      data[String(field?.fieldCode)] = formatFieldForDisplay(field);
    });
  } else if (detailData?.sonObjects) {
    const sonObjectdata =
      _.filter(detailData?.sonObjects, (object) => object.objectCode === objectCode) || [];

    data[String(objectCode)] = sonObjectdata?.map((data) =>
      formatDetailDisplayData(data as DetailData, objectCode),
    );
  }
  return data;
};

export const formatDataForForm = (detailData: DetailData, objectCode?: string) => {
  let data: any = { customFields: {} };

  if (objectCode && detailData.objectCode === objectCode) {
    (detailData?.fields || [])?.forEach((field) => {
      if (field?.fieldCategory === FieldCategory.customFields) {
        data['customFields'][`${field?.id || field.fieldId}`] = {
          ...field,
          fieldValue: formatFieldFormData(field),
        };
      } else {
        data[String(field?.fieldCode)] = formatFieldFormData(field);
      }
    });
  } else if (detailData?.sonObjects) {
    const sonObjectdata =
      _.filter(detailData?.sonObjects, (object) => object.objectCode === objectCode) || [];

    data[String(objectCode)] = sonObjectdata?.map((_data) =>
      formatDataForForm(_data as DetailData, objectCode),
    );
  }
  return data;
};

export const formatFieldFormData = (field: FieldValueDTO) => {
  const { fieldType, choiceValues, defaultValue, isRefer, targetType } = field;
  let fieldValue = field.fieldValue || defaultValue;
  // 单选多选默认选项
  const defaultChoices = _.filter(choiceValues, { isDefault: 1 }).map(choiceToLabelValue);

  switch (fieldType) {
    case FieldType.boolean:
      return !!_.toNumber(fieldValue);
    case FieldType.number:
    case FieldType.integer:
      return _.toNumber(fieldValue);
    case FieldType.date:
      return fieldValue ? moment(fieldValue) : null;
    case FieldType.relation: {
      if (targetType === TargetType.multiChoice) {
        return fieldValue
          ? fieldValue.map((i: { mainProperty: string; id: number; objectCode?: string }) => {
              if (i.objectCode === 'Document') {
                return i.id;
              }

              return {
                label: i?.mainProperty,
                value: i.id,
              };
            })
          : [];
      }
      return fieldValue ? { label: fieldValue?.mainProperty, value: fieldValue.id } : null;
    }
    case FieldType.multiSelect:
      return fieldValue
        ? fieldValue?.map((i: string) => choiceToLabelValue(find(choiceValues, ['choiceCode', i])))
        : defaultChoices;
    case FieldType.select:
      const fieldValueInfo = find(choiceValues, ['choiceCode', fieldValue]);

      return fieldValue ? choiceToLabelValue(fieldValueInfo || {}) : first(defaultChoices);
    case FieldType.appendix:
      return fieldValue || [];
    default:
      return fieldValue;
  }
};

export const formatFieldForDisplay = (field: FieldValueDTO) => {
  const { fieldType, choiceValues, fieldValue } = field;

  if (fieldType === FieldType.multiSelect || fieldType === FieldType.select) {
    return choiceValues;
  }
  return fieldValue;
};

export const formatCustomFieldValueToSubmit = (customFields: any, layoutId?: number) => {
  return _.compact(
    _.map(customFields, (field, key) => {
      const { fieldType, targetType, fieldCode, id, fieldId } = field;
      let fieldValue = field?.fieldValue;

      switch (fieldType) {
        case FieldType.date:
          fieldValue = fieldValue ? Number(gcTime.formatToUnix(fieldValue)) : null;
          break;
        case FieldType.boolean:
          if (!_.isNil(fieldValue) && fieldValue !== '') {
            fieldValue = Boolean(fieldValue);
          }
          break;
        case FieldType.multiSelect:
          fieldValue = fieldValue?.length
            ? _.map(fieldValue, (val) => {
                return String(val?.value);
              })
            : null;
          break;
        case FieldType.select:
          fieldValue = fieldValue?.value ? String(fieldValue?.value) : null;
          break;
        case FieldType.relation:
          if (targetType === TargetType.multiChoice) {
            fieldValue = fieldValue?.map((i: { value: string }) => i.value) || [];
          } else {
            fieldValue = fieldValue?.value;
          }
          break;
        case FieldType.appendix:
          if (typeof fieldValue[0] === 'object') {
            fieldValue = fieldValue?.map((v: any) => v?.id);
          }
          break;
        case FieldType.reference:
          fieldValue = null;
          break;
      }
      if (fieldCode === '_layout_') {
        fieldValue = layoutId;
      }
      return {
        fieldValue,
        fieldId: id || fieldId,
      };
    }),
  );
};
