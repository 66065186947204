import React, { useEffect, useState } from 'react';
import { DataFormLayout, DataFormLayoutForModal } from 'layout';
import { Checkbox, Form, Input, message as Message } from 'antd';
import {
  fetchSourceListCreate,
  fetchSourceListDetail,
  fetchSourceListDetailSupplier,
  fetchSourceListUpdate,
} from 'src/api/ytt/order-domain/sourceList';
import { SearchSelect, NumberRulesStandardHook } from 'src/components';
import SupplierEditTable from './components/SupplierEditTable';
import { UsageStatus } from 'src/dict/common';
import { BizRange } from 'src/dict/material';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { numberAlphabetSpecialSymbols, withoutSpaceBothSides } from 'src/utils/formValidators';

const { TextArea } = Input;

type CreateAndEditProps = {
  visible: boolean;
  isEdit: boolean;
  id?: number;
  refetch: () => void;
  onClose: () => void;
};

const CreateAndEdit: React.FC<CreateAndEditProps> = (props) => {
  const [form] = Form.useForm();
  const { visible, isEdit, id, onClose, refetch } = props;
  const [continueAdd, setContinueAdd] = useState(false);
  const [refreshMarker, setRefreshMarker] = useState<number>();

  /**
   * 初始化表单
   * @param sourceData 货源清单信息
   * @param supplierList 供应商列表
   */
  const initialForm = (sourceData: any = {}, supplierList: any[] = []) => {
    const { code, material, remark } = sourceData;

    form.setFieldsValue({
      code,
      remark,
      material: {
        id: material.id,
        label: `${material?.code}/${material?.name}`,
        value: material?.id,
      },
      itemList: supplierList?.map((supplier) => {
        return {
          id: supplier?.id,
          supplier: {
            id: supplier.supplierId,
            label: supplier?.supplierName,
            value: supplier?.supplierId,
          },
          purchaseCycle: supplier?.purchaseCycle,
        };
      }),
    });
  };

  /**
   * 格式化新增/编辑入参
   * @param values form字段值
   */
  const formatParams = (values: any) => {
    const { itemList, material, ...rest } = values;
    const params = {
      materialId: material?.value,
      itemList: itemList.map((item: any) => {
        return {
          id: item?.id,
          purchaseCycle: item?.purchaseCycle,
          supplierId: item?.supplier?.value,
        };
      }),
      ...rest,
    };

    return params;
  };

  /**
   * 获取编辑数据
   * @id 货源清单ID
   */
  const fetchData = async (id?: number) => {
    if (id) {
      const { data: sourceData } = await fetchSourceListDetail({ id });
      const { data: supplierList } = await fetchSourceListDetailSupplier({ id });

      // 初始化表单
      initialForm(sourceData, supplierList);
    }
  };

  /**
   * 获取提交方法
   * @isEdit 是否编辑
   */
  const submitFunction = async (isEdit: boolean, params: any) => {
    let res = null;

    if (isEdit) {
      params.id = id;

      res = await fetchSourceListUpdate(params);
    } else {
      res = await fetchSourceListCreate(params);
    }
    return res;
  };

  /** 提交表单 */
  const onSubmit = () => {
    form.validateFields().then(async (values) => {
      const params = formatParams(values);

      const res = await submitFunction(isEdit, params);

      const { code, message } = res;

      if (code !== 200) {
        Message.error(message);
        return;
      }
      Message.success(message);
      refetch();
      if (continueAdd) {
        form.resetFields();
        setRefreshMarker(Date.now());
        fetchData();
      } else {
        onClose();
      }
    });
  };

  const getBaseInfo = (isEdit: boolean) => {
    const baseInfo = {
      title: '',
      items: [
        ...NumberRulesStandardHook({
          label: '编号',
          name: 'code',
          form,
          edit: isEdit,
          objectCode: OBJECT_OF_CODE.sourceList,
          fieldCode: 'code',
          rules: [
            numberAlphabetSpecialSymbols,
            withoutSpaceBothSides,
            { max: 255, message: '不可超过255个字符' },
          ],
          refreshMarker,
          disabledState: isEdit,
        }),
        {
          label: '物料',
          name: 'material',
          rules: [{ required: true, message: '物料必填' }],
          render: () => (
            <SearchSelect
              fetchType={'materialCodeName'}
              params={{ bizRange: [BizRange.purchase], enableFlag: UsageStatus.enabled }}
            />
          ),
        },
        {
          label: '供应商',
          name: 'itemList',
          rules: [{ required: true, message: '供应商必填' }],
          render: () => <SupplierEditTable form={form} name={'itemList'} />,
        },
        {
          label: '备注',
          name: 'remark',
          render: () => <TextArea placeholder={'请输入备注'} maxLength={1000} showCount />,
        },
      ],
    };

    if (isEdit) {
      baseInfo.items.push({
        label: '编辑原因',
        name: 'updateReason',
        render: () => <TextArea placeholder={'请输入编辑原因'} maxLength={255} showCount />,
      });
    }

    return baseInfo;
  };

  useEffect(() => {
    fetchData(id);
  }, []);

  return (
    <>
      <DataFormLayoutForModal
        visible={visible}
        width={800}
        content={
          <DataFormLayout
            form={form}
            title={isEdit ? '编辑货源清单' : '新建货源清单'}
            extra={
              !isEdit && (
                <Checkbox
                  onChange={(e) => setContinueAdd(e.target.checked)}
                  defaultChecked={continueAdd}
                >
                  连续新建
                </Checkbox>
              )
            }
            info={[getBaseInfo(isEdit)]}
            onCancel={onClose}
            onFinish={onSubmit}
          />
        }
        onClose={onClose}
      />
    </>
  );
};

export default CreateAndEdit;
