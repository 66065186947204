import React, { useEffect, useState } from 'react';
import { Form, message as Message, Checkbox } from 'antd';
import BaseInfo from './components/createAndEditBaseInfo';
import { changeValues, formatTime } from './utils';
import { RouteComponentProps } from 'react-router-dom';
import { toShiftList } from './navigation';
import { fetchShiftCreate, fetchShiftGet } from 'src/api/ytt/calendar-domain';
import { throttleFn } from '../../warehouse/utils';

interface createProps extends RouteComponentProps {
  location: any;
}

const CreatShift = (props: createProps) => {
  const { location, history } = props;

  const { state } = location;

  const [createForm] = Form.useForm();

  const [continuousCreate, setContinuousCreate] = useState(false); // 连续新建

  const [stateId, setStateId] = useState(0);

  const [workHourDisplay, setWorkHour] = useState('');

  const [totalHourDisplay, setTotalHour] = useState('');

  // 获取有效工时
  const getWorkHour = (time: number) => {
    const workDisplay = formatTime(time ?? 0);

    createForm.setFieldsValue({ effectiveDuration: time });

    setWorkHour(workDisplay);
  };

  // 获取总工时

  const getTotalHour = (time: number) => {
    const workDisplay = formatTime(time ?? 0);

    createForm.setFieldsValue({ sumDuration: time });

    setTotalHour(workDisplay);
  };

  const [detailData, setDetailData] = useState({} as any);

  const handleFinish = async () => {
    try {
      const value = await createForm?.validateFields();

      const newShiftInfos = changeValues(value?.shiftInfos);

      const newValue = { ...value, shiftInfos: newShiftInfos };

      const { data, message } = await fetchShiftCreate(newValue);

      if (data) {
        Message.success('创建成功');
        if (continuousCreate) {
          createForm.resetFields();
          setWorkHour(''); // 置空有效工时，总工时
          setTotalHour('');
          setStateId(0);
          createForm.setFieldsValue({
            shiftInfos: [{}],
          });
          return;
        }
        history.push(toShiftList());
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  const extra = (
    <Checkbox
      onChange={(e) => {
        setContinuousCreate(e?.target?.checked ?? false);
      }}
    >
      连续新建
    </Checkbox>
  );

  const fetchData = async () => {
    try {
      const { data } = await fetchShiftGet(state);

      if (data) {
        setDetailData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (state) {
      setStateId(state);
      fetchData();
    }
  }, [state]);

  return (
    <BaseInfo
      onFinish={throttleFn(handleFinish)}
      onCancel={() => {
        history.push(toShiftList());
      }}
      title={stateId ? '复制班次' : '新建班次'}
      form={createForm}
      extra={extra}
      edit={false}
      initialData={detailData}
      getWorkHour={getWorkHour}
      getTotalHour={getTotalHour}
      workHourDisplay={workHourDisplay}
      totalHourDisplay={totalHourDisplay}
    />
  );
};

export default CreatShift;
