import _ from 'lodash';
import { PRODUCTION_WAY_TYPE } from './constant';
import { QrCodeData } from './interface';

export const formatRequestValue = (value: QrCodeData) => {
  const { ruleType, factoryCodeList, businessObjectCode, ruleId, ...rest } = value;

  const newValue = { ...rest };

  if (ruleType === PRODUCTION_WAY_TYPE.CUSTOMER) {
    // 如果规则类型选择的自定义 需要清空ruleId 格式化转化给后端的数据格式
    const formatFactoryCodeList = _.filter(_.map(factoryCodeList, 'customerCode'), (e) => e).filter(
      (e) => e,
    );

    return {
      ...newValue,
      ruleType,
      factoryCodeList: formatFactoryCodeList,
      businessObjectCode: businessObjectCode?.value,
    };
  }

  if (ruleType === PRODUCTION_WAY_TYPE.NUMBER_RULE) {
    // 如果规则类型选择的编号规则 需要清空自定义数据
    return {
      ...newValue,
      ruleId: ruleId?.value,
      ruleType,
      businessObjectCode: businessObjectCode?.value,
    };
  }

  if (ruleType === PRODUCTION_WAY_TYPE.SYSTEM_DEFAULT) {
    // 如果规则类型选择的系统默认 需要清空自定义数据 ruleId
    return {
      ...newValue,
      ruleType,
      businessObjectCode: businessObjectCode?.value,
    };
  }

  return newValue;
};
