import { processCompleteInfo } from './process';
import { storageLocation } from './location';
import { warehouse } from './warehouse';

export const configs = {
  /** 工序 */
  processCompleteInfo,
  storageLocation,
  warehouse,
};
