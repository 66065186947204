import React, { useEffect, useState } from 'react';
import { Tree, Input } from 'antd';
import { BlIcon } from 'components';
import { BlTreeProps, NodeData } from './BlTree.d';
import BlListTree from './BlListInTree';
import _ from 'lodash';
import styles from './styles.module.scss';

export declare type Key = string | number;

const BlTreeComponent: React.FC<BlTreeProps> = (props: BlTreeProps) => {
  const {
    isSearch,
    treeData = [],
    isListTree, // 单层树结构
    topRender,
    bottomRender,
    titleRender,
    showExpandBtn,
    updateTreeData = false,
    searchPlaceholder,
    selectedKeys,
    overflowAble = false,
    onlyOpenArea = false,
    onSelect,
    onSearch,
    withLocation,
    ...restProps
  } = props;
  const [currentKeys, setExpandedKeys] = useState<Key[]>([]); // 展开指定的树节点
  const [tree, setTree] = useState<NodeData[]>(treeData); // 显示的树
  const [flatTree, setFlatTree] = useState<NodeData[]>(treeData); // 所以节点数据
  const [isAnyExpanded, setIsAnyExpanded] = useState<boolean>(false); // 是否有展开数据
  const [query, setQuery] = useState(''); // 搜索关键词

  useEffect(() => {
    console.log(selectedKeys, currentKeys, _.dropRight(selectedKeys));
    if (updateTreeData) {
      setTree(treeData);
    } else {
      if (treeData.length === 0) return;
      setTree(treeData);
    }

    if (isListTree) return;
    let flatTreeList: NodeData[] = [];
    flatTreeData(treeData, flatTreeList);
    setFlatTree(flatTreeList);

    if (query?.length) searchQuery(query);
    if (withLocation !== undefined) {
      setExpandedKeys(_.dropRight(selectedKeys) ?? []);
      setIsAnyExpanded(false);
      return;
    }

    // 如果更新treeData时，有选中的key且当前展开节点为空，自动展开选中节点
    else if (!_.isEmpty(selectedKeys) && _.isEmpty(currentKeys)) {
      setExpandedKeys(_.dropRight(selectedKeys));
    }
  }, [treeData]);

  /**
   * @description: 递归树，将树的节点依次摘下来，放数组里
   * @param {*}
   * @return {*}
   */
  const flatTreeData = (
    _tree: NodeData[] = [],
    allDataList: NodeData[] = [],
    parentKey?: number | string | undefined,
  ) => {
    _tree?.map((node) => {
      const { isLeaf, children, key, title } = node;
      if (withLocation && onlyOpenArea) {
        if ((node as any).type === 'Location') {
          allDataList.push({ isLeaf, key, title, ...(parentKey && { parentKey }) });
        }
      } else {
        allDataList.push({ isLeaf, key, title, ...(parentKey && { parentKey }) });
      }
      if (!isLeaf) flatTreeData(children!, allDataList, key);
    });
  };

  // 展开/收起节点时触发
  const onExpand = (expandedKeys: Key[]) => {
    setExpandedKeys(expandedKeys.indexOf(flatTree[0]?.key) > -1 ? expandedKeys : []);
    setIsAnyExpanded(expandedKeys.indexOf(flatTree[0]?.key) > -1);
  };

  const searchQuery = (value: string) => {
    if (isListTree) {
      setTree(treeData.filter(({ title }) => _.toString(title).indexOf(value) > -1));
    } else {
      // 展开项key值集合
      const nowExpandedKeys: number[] = [];
      // 筛选后的树
      const filteredTree = filterTreeData(treeData, value, nowExpandedKeys);

      if (_.isFunction(onSearch)) onSearch(filteredTree);
      setTree(filteredTree.length > 0 ? filteredTree : treeData);
      onExpand(nowExpandedKeys);
    }
  };

  // input的change事件
  const onChange = _.debounce((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e?.target;

    setQuery(value);
    if (!value) {
      setTree(treeData);
      // restore search result
      if (_.isFunction(onSearch)) onSearch?.(treeData);
      onExpand([]);
      return;
    }

    if (treeData.length === 0) return;
    searchQuery(value);
  }, 200);

  /**
   * @description: 多叉树递归筛选逻辑，匹配符合条件的子节点
   * @param {NodeData} tree
   * @param {string} value
   * @param {number} expandedKeys
   * @return {*}
   */
  const filterTreeData = (
    tree: NodeData[] = [],
    value: string,
    expandedKeys: number[] = [],
  ): NodeData[] => {
    // 记录符合条件节点集合
    const matchedTree: NodeData[] = [];
    // 遍历当前节点
    tree?.forEach((item) => {
      const node = { ...item };
      const { title, key } = node;
      // 获取当前节点符合条件的子孙节点集合
      const newChildren = filterTreeData(node?.children, value, expandedKeys);

      if (newChildren.length > 0) {
        // 子孙节点中有符合条件的节点时，展开当前节点
        expandedKeys.push(Number(key));
        // 展开的子节点中只展示符合条件的子节点
        node.children = newChildren;
        matchedTree.push(node);
      } else if (_.toString(title).indexOf(value) > -1) {
        // 若子孙节点中没有符合条件的节点，但当前子节点匹配，则加入匹配集合，保留不符合条件的子孙节点但不展开
        matchedTree.push(node);
      }
    });
    return matchedTree;
  };

  // 展开按钮
  const getExpandBtn = () => (
    <a
      className={styles.expandBtn}
      onClick={() => onExpand(isAnyExpanded ? [] : _.map(flatTree, 'key'))}
    >
      {isAnyExpanded ? '收起 ' : '展开 '}
      {isAnyExpanded ? <BlIcon type="iconshouqi" /> : <BlIcon type="iconzhankai" />}
    </a>
  );

  const getTreeSelectkeys = () => {
    if (_.isEmpty(selectedKeys)) return undefined;

    const lastKey = _.last(selectedKeys);

    if (lastKey) {
      return [lastKey];
    }
    return undefined;
  };

  const TreeComponent = isListTree ? BlListTree : Tree;

  return (
    <div className={overflowAble ? styles.treeOverflow : styles.tree}>
      {topRender && <div className={styles.topRender}>{topRender}</div>}
      {isSearch ? (
        <div className={styles.treeSearch}>
          <Input
            prefix={<BlIcon type="iconsousuo" />}
            placeholder={searchPlaceholder ?? '请输入搜索关键字'}
            onChange={onChange}
            allowClear
          />
        </div>
      ) : null}
      <div className={styles.treeComponent}>
        <TreeComponent
          treeData={tree}
          onExpand={onExpand}
          expandedKeys={currentKeys}
          // style={{ backgroundColor: '#fafafa', height: '100%' }}
          titleRender={titleRender}
          selectedKeys={getTreeSelectkeys()}
          onSelect={(selectedKeys, e) => {
            if (_.isFunction(onSelect)) {
              onSelect(selectedKeys, { ...e, currentTree: tree });
            }
          }}
          {...restProps}
        />
        {((withLocation && onlyOpenArea) || !onlyOpenArea) &&
          !isListTree &&
          showExpandBtn &&
          flatTree.length > 0 &&
          getExpandBtn()}
      </div>
      {bottomRender && <div className={styles.bottomRender}>{bottomRender}</div>}
    </div>
  );
};

BlTreeComponent.defaultProps = {
  isListTree: false,
  showExpandBtn: false,
};

export default BlTreeComponent;
