import React, { useEffect, useState } from 'react';
import { Input, Space, Button, Modal, Select, Row } from 'antd';
import { fetchResourcesList } from 'src/api/ytt/resource-domain/resource';
import { fetchMaterialList } from 'src/api/ytt/material-domain/Web_MaterialBaseInfo';
import { fetchSupplierList } from 'src/api/ytt/supply-domain/supplier';
import { fetchProcess } from 'src/api/ytt/med-domain/process';
import { fetchCustomerList } from 'src/api/ytt/order-domain/customer';
import _ from 'lodash';
import { titleMap, columnsMap, filterOptions } from './constants';
import { BlTable } from '@blacklake-web/component';
import BcBlCascader from 'src/components/Cascader';
import { RESORCE_STATUS_VALUE } from 'src/page/resource/definitions/constants';
import { appEnum } from 'src/dict';
import ResizeObserver from 'rc-resize-observer';
interface SelectModalProps {
  visible: boolean;
  submitData: (type: string, rows: any[]) => void;
  type: string;
  closeModal: () => void;
  defaultSelected?: any[];
}

const DETAUL_PAGE = {
  page: 1,
  size: 10,
};

const LinkSelectModal = (props: SelectModalProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[] | undefined>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [data, setData] = useState<{ list: any[]; page?: number; size?: number; total?: number }>({
    list: [],
  });
  const [materialCategory, setMaterialCategory] = useState<number[]>([]);
  const [disabledRowKeys, setDisabledRowKeys] = useState<number[] | undefined>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const { submitData, visible, closeModal, defaultSelected, type } = props;
  const defaultSelectValue = {
    label: `${titleMap.get(type)}编号`,
    value: 'code',
  };

  const [searchField, setSearchField] =
    useState<{ label: string; value: string }>(defaultSelectValue);

  useEffect(() => {
    setDisabledRowKeys(_.map(defaultSelected, 'id') || []);
    setSelectedRowKeys(_.map(defaultSelected, 'id'));
    setSelectedRows(defaultSelected || []);
  }, [defaultSelected]);

  const getFetchDataFn = (_type: string, params: any) => {
    if (!params[searchField.value]) {
      params[searchField.value] = searchValue;
    }
    switch (_type) {
      case 'qcConfigResourceList': {
        params.status = RESORCE_STATUS_VALUE.ENABLED;
        return fetchResourcesList(params);
      }
      case 'qcConfigMaterialList': {
        params.enableFlag = appEnum.Common.UsageStatus.enabled;
        if (materialCategory?.length) {
          params.categoryIds = materialCategory;
        }
        return fetchMaterialList(params);
      }
      case 'qcConfigProcessList':
        return fetchProcess(params);
      case 'qcConfigSupplierList': {
        params.status = [appEnum.Common.UsageStatus.enabled];
        return fetchSupplierList(params);
      }
      case 'qcConfigCustomerList': {
        params.status = [appEnum.Common.UsageStatus.enabled];
        return fetchCustomerList(params);
      }
      default: {
        params.status = RESORCE_STATUS_VALUE.ENABLED;
        return fetchResourcesList(params);
      }
    }
  };

  const fetchTableData = (params: any) => {
    getFetchDataFn(type, params).then((res) => {
      let list = res?.data?.list;

      if (type === 'qcConfigMaterialList') {
        list = list?.map((item: { unit: { id: any; name: any } }) => ({
          qcUnit: {
            value: item?.unit?.id,
            label: item?.unit?.name,
          },
          ...item,
        }));
      }

      setData({ ...res?.data, list, size: params.size });
    });
  };

  const renderSelectInput = () => {
    return (
      <Row style={{ marginBottom: 10 }}>
        <Select
          options={filterOptions.get(type)}
          style={{ width: '20%', height: 32 }}
          labelInValue
          defaultValue={searchField}
          onChange={(value: { label: string; value: string }) => {
            setSearchField(value);
          }}
        />
        {searchField.value === 'categoryIds' ? (
          <Row>
            <div style={{ width: 400 }}>
              <BcBlCascader
                fetchType={'materialCategory'}
                onChange={(value) => {
                  setMaterialCategory(value);
                }}
              />
            </div>
            <Button
              type="primary"
              onClick={() => {
                fetchTableData({
                  categoryIds: materialCategory,
                  ...DETAUL_PAGE,
                });
              }}
            >
              查询
            </Button>
          </Row>
        ) : (
          <Input
            style={{ width: '80%', height: 32, lineHeight: 32 }}
            placeholder={`请输入${searchField.label}`}
            onPressEnter={(e) => {
              fetchTableData({ ...DETAUL_PAGE, [searchField.value]: e.target?.value });
              setSearchValue(e.target?.value);
            }}
          />
        )}
      </Row>
    );
  };

  const formatSelectValue = (rows: any[], value: any) => {
    const selectValue: any = value ? { ...value } : {};

    if (type === 'qcConfigMaterialList') {
      selectValue.qcUnit = _.find(rows, (row) => row?.id === value?.id)?.qcUnit || {
        value: value?.unit?.id,
        label: value?.unit?.name,
      };
    }
    if (type === 'qcConfigProcessList') {
      selectValue.seq = _.find(rows, (row) => row?.id === value?.id)?.seq || 0;
    }
    return selectValue;
  };

  const rowSelection = {
    selectedRowKeys,
    onSelectAll: (selected: any, _selectedRows: any) => {
      if (selected) {
        setSelectedRowKeys(
          _.uniqBy(selectedRows.concat(_.compact(_selectedRows)), 'id')
            ?.filter(({ id }) => id)
            .map(({ id }: { id: number }) => Number(id)),
        );
        setSelectedRows(
          _.uniqBy(selectedRows.concat(_.compact(_selectedRows)), 'id')?.filter(({ id }) => id),
        );
      }
    },
    onSelect: (record: any, selected: any, _selectedRows: any, nativeEvent: any) => {
      let oldSelectedRows = selectedRows;
      const newSelectRows = _selectedRows?.map((value: any) =>
        formatSelectValue(_selectedRows, value),
      );

      if (!selected) {
        oldSelectedRows = selectedRows.filter((row) => row?.id !== record?.id);
      }

      setSelectedRowKeys(
        _.uniqBy(oldSelectedRows.concat(newSelectRows), 'id')
          ?.filter(({ id }) => id)
          .map(({ id }: { id: number }) => Number(id)),
      );
      setSelectedRows(
        _.uniqBy(oldSelectedRows.concat(newSelectRows), 'id')?.filter(({ id }) => id),
      );
    },
    getCheckboxProps: (record: any) => ({
      disabled: disabledRowKeys?.includes(record.id), // Column configuration not to be checked
    }),
  };

  useEffect(() => {
    fetchTableData(DETAUL_PAGE);
  }, []);

  return (
    <Modal
      visible={visible}
      width={1000}
      title={`添加${titleMap.get(type)}`}
      onCancel={() => closeModal()}
      footer={null}
      bodyStyle={{ padding: 24, display: 'felx' }}
    >
      {renderSelectInput()}
      <BlTable
        columns={columnsMap.get(type) || []} // table columns
        rowSelection={rowSelection}
        style={{ minHeight: 300, paddingBottom: 50 }}
        rowKey="id"
        dataSource={data.list}
        pagination={{
          showSizeChanger: true,
          current: data?.page,
          pageSize: data?.size || 10,
          total: data?.total,
          showTotal: (total) => `共 ${total} 条`,
        }}
        resizableCol
        sticky
        onChange={(params) => fetchTableData({ page: params.current, size: params.pageSize })}
      />
      <Space
        align={'center'}
        size={'large'}
        style={{
          padding: 10,
          justifyContent: 'center',
          borderTop: '1px solid #b1b1b12e',
          width: '100%',
          position: 'absolute',
          bottom: 0,
        }}
      >
        <Button onClick={() => closeModal()}>取消</Button>
        <Button
          type="primary"
          onClick={() => {
            console.log(selectedRows);
            submitData(type, selectedRows);
            closeModal();
          }}
        >
          确认
        </Button>
      </Space>
    </Modal>
  );
};

export default LinkSelectModal;
