import { useEffect, useState } from 'react';
import { Checkbox, Form, Input, message, Select } from 'antd';
import { DataFormLayout, DataFormLayoutForModal, DataFormLayoutInfoBlock } from 'layout';
import TextArea from 'antd/lib/input/TextArea';
import {
  checkFormWithLetterDigitalUnderscore,
  whiteSpaceInMiddleValidator,
} from 'src/utils/formValidators';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { ActionModalProps } from '../index.d';
import { gcArray, gcObject } from 'src/utils';
import {
  createDepartment,
  getDepartmentDetail,
  updateDepartment,
} from 'src/services/organization/userManage';

const ROOTNAME = '全公司';

export const DepartmentActionModal = ({
  actionType,
  defaultValues,
  visible,
  onClose,
  setIsRefreshTree,
  id,
}: ActionModalProps) => {
  const [keepOn, setkeepOn] = useState<boolean>(false);
  const [parentDepartment, setParentDepartment] = useState<any>();
  const [isRootNode, setIsRootNode] = useState<boolean>(false);
  const [modalForm] = Form.useForm();
  const SELECT_KEYS = ['departmentIds', 'superior', 'ownerId'];
  let actionTitle = '新建部门';

  if (actionType === 'edit') {
    actionTitle = '编辑部门';
  }

  useEffect(() => {
    if (defaultValues) {
      modalForm.setFieldsValue(defaultValues);
      if (defaultValues.parentDepartment?.id) {
        setParentDepartment(defaultValues.parentDepartment);
      }
    }
  }, [defaultValues]);

  useEffect(() => {
    const fetchData = async (_id: number) => {
      try {
        const { data: detailData } = await getDepartmentDetail(_id);

        if (detailData.name === ROOTNAME) setIsRootNode(true);
        if (detailData.owner) {
          detailData.ownerId = [{ value: detailData.owner.id, label: detailData.owner.name }];
        }
        if (detailData.owner) {
          detailData.ownerId = [{ value: detailData.owner.id, label: detailData.owner.name }];
        }
        if (detailData.parentDepartment) {
          setParentDepartment(detailData.parentDepartment);
          detailData.parentId = detailData.parentDepartment.id;
        }
        modalForm.setFieldsValue(detailData);
      } catch (err) {
        console.log(err);
      }
    };

    if (id) {
      fetchData(id);
    }
  }, []);

  const baseInfo: DataFormLayoutInfoBlock = {
    title: '',
    column: 1,
    items: [
      {
        label: '部门编号',
        name: 'code',
        rules: [
          { required: true, message: '请输入部门编号' },
          { max: 100, message: '不超过100字符' },
          { validator: checkFormWithLetterDigitalUnderscore },
        ],
        render: () => <Input disabled={isRootNode} />,
      },
      {
        label: '部门名称',
        name: 'name',
        rules: [
          { required: true, message: '请输入部门名称' },
          { max: 100, message: '不超过100字符' },
          { validator: whiteSpaceInMiddleValidator },
        ],
        render: () => <Input disabled={isRootNode} />,
      },
      {
        label: '上级部门',
        name: 'parentId',
        // rules: [{ required: true, message: '请选上级部门' }],
        render: () => (
          <Select
            disabled
            options={[
              {
                label: parentDepartment?.name,
                value: parentDepartment?.id,
              },
            ]}
          />
        ),
      },
      {
        label: '负责人',
        name: 'ownerId',
        render: () => <UserOrDepartmentSelectWithDialog isMultiple={false} isSelectUser />,
      },
      {
        label: '备注',
        name: 'desc',
        rules: [
          { max: 1000, message: '不超过1000字符' },
          { validator: whiteSpaceInMiddleValidator },
        ],
        render: () => <TextArea placeholder="请输入备注" />,
      },
    ],
  };

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();
      let actionFun = null;

      if (actionType === 'edit') {
        value.id = id;
      }
      for (const key in value) {
        if (SELECT_KEYS.includes(key) && !gcArray.isEmpty(value[key])) {
          value[key] = value[key].map((i: any) => i.value);
        }
      }
      if (value.superior && !gcObject.isEmpty(value.superior)) {
        value.superior = { id: value.superior[0] };
      }
      if (value.ownerId) {
        value.ownerId = value.ownerId[0];
      }

      if (value.avatarUrl) {
        value.avatarUrl = value.avatarUrl.uri;
      }

      if (actionType === 'create') {
        actionFun = createDepartment(value);
      }
      if (actionType === 'edit') {
        actionFun = updateDepartment(value);
      }
      if (actionFun) {
        const res = await actionFun;

        if (res.code === 200) {
          message.success('操作成功');
          setIsRefreshTree?.(Math.random() * 100);
          if (keepOn) {
            modalForm.resetFields();
            if (defaultValues) {
              modalForm.setFieldsValue(defaultValues);
              if (defaultValues.parentDepartment?.id) {
                setParentDepartment(defaultValues.parentDepartment);
              }
            }
          } else {
            onClose?.();
          }
        } else {
          message.error(res.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      width={800}
      onClose={() => {
        onClose?.();
      }}
      content={
        <DataFormLayout
          form={modalForm}
          title={actionTitle}
          extra={
            actionType === 'create' ? (
              <Checkbox
                onChange={() => {
                  setkeepOn(!keepOn);
                }}
                defaultChecked={false}
              >
                连续新建
              </Checkbox>
            ) : null
          }
          onCancel={() => {
            onClose?.();
          }}
          info={[baseInfo]}
          onFinish={handleFinish}
        />
      }
    />
  );
};
