import React, { useState, useEffect } from 'react';
import { Button, Avatar } from 'antd';
import { BcUpload, BlIcon } from '..';
import styles from './styles.module.scss';

interface uploadProps {
  formKey?: string;
  form?: any;
  uploadedUrl?: string;
  icon?: React.ReactNode;
}
const AvatarUpload = (props: uploadProps) => {
  const { form, uploadedUrl, icon, formKey = 'avatarUrl' } = props;
  const [preViewUrl, setPreViewUrl] = useState<string>('');

  useEffect(() => {
    if (uploadedUrl) {
      setPreViewUrl(uploadedUrl);
    }
  }, [uploadedUrl]);

  const uploadButton = (
    <Button icon={<BlIcon type="iconshangchuan1" />}>{preViewUrl ? '修改头像' : '上传头像'}</Button>
  );

  return (
    <div
      className={styles.avatarWrapper}
      style={{
        marginLeft: 20,
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      {preViewUrl ? (
        <Avatar size={86} src={preViewUrl} />
      ) : (
        <Avatar size={86} icon={icon || <BlIcon type="icongeren" />} />
      )}
      大图
      {preViewUrl ? (
        <Avatar size={32} src={preViewUrl} />
      ) : (
        <Avatar size={32} icon={icon || <BlIcon type="icongeren" />} />
      )}
      缩略图
      <BcUpload
        className={styles.attachUpload}
        draggable={false}
        showUploadList={false}
        limit={'image'}
        maxCount={1}
        maxSize={10}
        autoDelErrorFile={false}
        onUploaded={(data: any) => {
          if (data[0]?.response?.uri) {
            setPreViewUrl(data[0]?.response?.uri);
            form?.setFieldsValue({ [formKey]: data[0]?.response });
          }
        }}
        overCountNode={uploadButton}
      >
        {uploadButton}
      </BcUpload>
    </div>
  );
};

export default AvatarUpload;
