import React, { ReactNode, useEffect } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import {
  checkTwoSidesBackslash,
  textValidator1,
  textValidator2,
  withoutSpaceBothSides,
} from 'src/utils/formValidators';
import { FormInstance, Input } from 'antd';
import { BcAttachmentForm, NumberRulesStandardHook, SearchSelect } from 'src/components';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import MaterialFormTable from '../components/materialTable';
import _ from 'lodash';
import { SalesOrderDetailsResponse } from '../interface';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import {
  ICustomFields,
  initCustomFieldsInData,
  injectCustomFieldInfos,
  useCustomFieldCombinedData,
} from 'src/components/customField';
import { appEnum } from 'src/dict';

const { TextArea } = Input;

interface baseInfoProps {
  onCancel: () => void;
  onFinish: (customFields: ICustomFields, customItemFields: ICustomFields) => void;
  title: string;
  loading: boolean;
  initialData?: SalesOrderDetailsResponse;
  extra?: ReactNode;
  edit: boolean;
  form: FormInstance;
  refreshMarker?: number;
}

const CreateAndEditBaseInfo = (props: baseInfoProps) => {
  const { onCancel, title, onFinish, initialData, form, extra, edit, refreshMarker, loading } =
    props;
  // 自定义字段
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.salesOrder);
  const customItemFields = useCustomFieldCombinedData(OBJECT_OF_CODE.salesOrderItem);

  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    column: 3,
    items: [
      ...NumberRulesStandardHook({
        label: '订单编号',
        form,
        edit,
        objectCode: 'SalesOrder',
        fieldCode: 'code',
        rules: [
          { max: 64, message: '不可超过64字符' },
          { validator: textValidator2 },
          { validator: checkTwoSidesBackslash },
          withoutSpaceBothSides,
        ],
        refreshMarker,
      }),
      {
        label: '客户名称',
        name: 'customerId',
        rules: [{ required: true, message: '请选择客户' }],
        render: () => (
          <SearchSelect fetchType={'customer'} style={{ width: '100%' }} labelInValue />
        ),
      },
      {
        label: '订单所有人',
        name: 'ownerIds',
        rules: [{ required: true, message: '请选择订单所有人' }],
        render: () => (
          <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple={false} isSelectUser />
        ),
      },
      {
        label: '订单备注',
        name: 'remark',
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="请输入" showCount maxLength={1000} rows={6} />,
      },
      {
        label: '附件',
        name: 'attachments',
        render: () => <BcAttachmentForm form={form} />,
      },
    ],
  };

  const materialInfo: DataFormLayoutInfoBlock = {
    title: '物料信息',
    column: 3,
    items: [
      {
        isFullLine: true,
        render: () => (
          <MaterialFormTable
            form={form}
            initialData={initialData?.items}
            edit={edit}
            customItemFields={customItemFields}
            orderStatus={initialData?.header?.status}
          />
        ),
      },
    ],
  };

  const otherInfo: DataFormLayoutInfoBlock = {
    column: 3,
    items: [
      {
        label: '编辑原因',
        name: 'operateReason',
        hidden: !edit,
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
    ],
  };

  useEffect(() => {
    if (initialData) {
      const afterFormatInitialValue = {
        id: initialData?.header?.id,
        code: initialData?.header?.code,
        customerId: {
          key: initialData?.header?.customer?.id,
          label: initialData?.header?.customer?.name,
        },
        ownerIds: _.map(initialData?.header?.owners, (node: any) => {
          return {
            key: node.id,
            label: node?.name,
          };
        }),
        remark: initialData?.header?.remark,
        attachments: initialData?.header?.attachments,
        customFields: initialData?.header?.customFields,
      };
      const afterFormatCustomFielsValue = initCustomFieldsInData(afterFormatInitialValue);

      form?.setFieldsValue(afterFormatCustomFielsValue);
    }
  }, [initialData]);

  return (
    <DataFormLayout
      form={form}
      title={title}
      loading={loading}
      info={[
        baseInfo,
        // 插入主对象自定义字段
        injectCustomFieldInfos({
          customFields,
          type: 'form',
          formConfig: { form }, // form相关配置
          disabled: edit && initialData?.header.status !== appEnum.Sales.OrderStatus.create,
        }),
        materialInfo,
        otherInfo,
      ]}
      onCancel={onCancel}
      onFinish={() => onFinish(customFields, customItemFields)}
      extra={extra}
    />
  );
};

export default CreateAndEditBaseInfo;
