import { SearchSelectModalConfig } from '../index.type';
import { fetchProcess } from 'src/api/ytt/med-domain/process';
import { FilterFieldType } from '@blacklake-web/layout';
import { AvatarDisplay } from 'src/components/avatar/show';

export const processCompleteInfo: SearchSelectModalConfig<any> = {
  title: '选择工序',
  requestFn: fetchProcess,
  columns: [
    {
      title: '工序编号',
      dataIndex: 'code',
      fixed: 'left',
      width: 200,
      filterConfig: {
        name: 'code',
        type: FilterFieldType.text,
      },
    },
    {
      title: '工序名称',
      dataIndex: 'name',
      width: 150,
      filterConfig: {
        name: 'name',
        type: FilterFieldType.text,
      },
    },
    {
      title: '负责部门',
      dataIndex: 'departmentName',
      width: 150,
    },
    {
      title: '工作中心',
      dataIndex: 'workCenterName',
      width: 150,
    },
    {
      title: '创建人',
      dataIndex: ['creator'],
      width: 150,
      render: (creator) => {
        return (
          <AvatarDisplay
            id={creator?.id}
            name={creator?.name}
            avatarUrl={creator?.avatarUrl}
            isShowTag={false}
            isUser
          />
        );
      },
    },
  ],
  renderLabel: 'code',
};
