import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import TreeContent, { MaintenanceTreeFnParams } from '../components/maintenanceTree';
import List from './list';

const ResourceRecord = () => {
  // 设备/能源仪表 ID
  const [definId, setDefinId] = useState<number | undefined>();
  const [businessType, setBussinessType] = useState<number>(0);

  return (
    <TreeContent
      onChange={(value: MaintenanceTreeFnParams) => {
        console.log(value);
        const { businessType, target } = value;
        const { id } = target as any;

        setBussinessType(businessType ?? 0);
        setDefinId(id);
      }}
      onlySelectEquipment
      cacheKey={'resourceRecord'}
      hasSubLocationFlag={false}
      businessTypeLabel={'业务对象'}
    >
      <List definId={definId} businessType={businessType} />
    </TreeContent>
  );
};

export default withRouter(ResourceRecord);
