import React, { useState, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { BlIcon } from '@blacklake-web/component';
import { message } from 'antd';
import _ from 'lodash';

import { RecordListLayout } from 'src/layout';
import { fieldTypeList } from 'src/utils';
import {
  fetchMaintenanceCaseList,
  // fetchMaintenanceCaseDelete,
  fetchMaintenanceCaseStatusUpdate,
} from 'src/api/ytt/resource-domain/maintenancePlan';

import TagTooltip from 'src/components/tooltip/tagTooltip';
import {
  SingleOperationLogComponent,
  SearchSelect,
  TagList,
  LinkTooltip,
  BlColumnsType,
} from 'src/components';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { appDict, appEnum, lookup } from 'src/dict';
import { mapLabeledValueToValue } from 'src/utils/array';
import type { MaintenanceListItemType } from '../index.d';
import { goToCreate, goToEdit, goToCopy, goToDetail, toDetail } from '../navigates';
import MaintenanceStatus from '../components/MaintenanceStatus';
import ResourceClassificationCascader from '../../components/ResourceClassificationCascader';
import { MaintenanceTreeFnParams } from '../../components/maintenanceTree';
import { BL_BUSINESS_CODE, BL_BUSINESS_NAME } from '../config';
import { getStandardWorkTime } from '../utils';
import { PlanAssignFromMap, PlanAssignToMap } from '../constants';
import PlanUserDeptRole from '../components/PlanUserDeptRole';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { valueOrHolder } from 'src/utils/formatters';

interface ListProps extends RouteComponentProps, MaintenanceTreeFnParams {}

const List = (props: ListProps) => {
  const [detailId, setDetailId] = useState<number>(0);
  const [refreshMarker, setRefreshMarker] = useState<number>(0);
  const [resetRefreshMarker, setResetRefreshMarker] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const { target, businessType, subLocationFlag } = props;

  const refreshTable = () => {
    setRefreshMarker(Math.random() * 100);
  };

  useEffect(() => {
    setTimeout(() => {
      setResetRefreshMarker(Date.now());
    });
  }, [target, subLocationFlag, businessType]);

  // const handleDelete = async (ids: any[], onSuccess?: () => void) => {
  //   // 删除
  //   try {
  //     const res = await fetchMaintenanceCaseDelete({ idList: ids, orgId: 1 });

  //     if (res.code !== 200) {
  //       message.error(res?.message);
  //       return;
  //     }
  //     typeof onSuccess === 'function' && onSuccess?.();
  //     message.success('删除成功');
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      icon: <BlIcon type="icondaochu" />,
      auth: authDict.maintenanceCase_export,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: appEnum.ObjectImport.BUSINESS_TYPE.maintenanceCase,
        });
      },
    },
    {
      title: `新建${BL_BUSINESS_NAME}`,
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.maintenanceCase_add,
      onClick: () => {
        goToCreate();
      },
      items: [
        {
          title: '导入',
          auth: authDict.maintenanceCase_import,
          showExport: true,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: appEnum.ObjectImport.BUSINESS_TYPE.maintenanceCase,
            });
          },
        },
      ],
    },
  ];

  const getChangeStatus = (stauts: number, id: number) => {
    if (stauts === appEnum.Common.UsageStatus.enabled) {
      return fetchMaintenanceCaseStatusUpdate({
        idList: [id],
        status: appEnum.Common.UsageStatus.disabled,
      });
    }
    return fetchMaintenanceCaseStatusUpdate({
      idList: [id],
      status: appEnum.Common.UsageStatus.enabled,
    });
  };

  const batchMenu = [
    {
      title: '启用',
      auth: authDict.maintenanceCase_enable_disable,
      onClick: () =>
        fetchMaintenanceCaseStatusUpdate({
          idList: selectedRowKeys,
          status: appEnum.Common.UsageStatus.enabled,
        }).then((res) => {
          if (res.code === 200) {
            message.success('启用成功！');
            refreshTable();
          }
        }),
    },
    {
      title: '停用',
      auth: authDict.maintenanceCase_enable_disable,
      onClick: () =>
        fetchMaintenanceCaseStatusUpdate({
          idList: selectedRowKeys,
          status: appEnum.Common.UsageStatus.disabled,
        }).then((res) => {
          if (res.code === 200) {
            message.success('停用成功！');
            refreshTable();
          }
        }),
    },
    // {
    //   title: '删除',
    //   icon: <BlIcon type="iconshanchulajitong1" />,
    //   onClick: (onSuccess: any, onFail: any) => {
    //     BLDelConfirm(
    //       `是否确认删除选中的${BL_BUSINESS_NAME}?`,
    //       delForm,
    //       () => {
    //         handleDelete(selectedRowKeys);
    //         onSuccess();
    //       },
    //       onFail,
    //     );
    //   },
    // },
  ];

  const getOperationList = ({ id, status }: { id: number; status: number }) => {
    return _.compact([
      {
        title: '查看',
        auth: authDict.maintenanceCase_detail,
        onClick: () => {
          goToDetail(id);
        },
      },
      {
        title: '编辑',
        auth: authDict.maintenanceCase_edit,
        onClick: () => {
          goToEdit(id);
        },
      },
      {
        title: '复制',
        auth: authDict.maintenanceCase_add,
        onClick: () => {
          goToCopy(id);
        },
      },
      {
        title: lookup('common.changeStatusAction', status),
        auth: authDict.maintenanceCase_enable_disable,
        onClick: () => {
          getChangeStatus(status, id).then((res) => {
            if (res.code === 200) {
              message.success(`${lookup('common.changeStatusAction', status)}成功`);
              refreshTable();
            }
          });
        },
      },
      // {
      //   title: '删除',
      //   auth:authDict.maintenanceCase_remove,
      //   onClick: () => {
      //     BLDelConfirm(`是否确认删除该${BL_BUSINESS_NAME}？`, delForm, () => {
      //       return handleDelete([id], refreshTable);
      //     });
      //   },
      // },
      {
        title: '操作记录',
        auth: authDict.maintenanceCase_operrecord,
        onClick: () => {
          setDetailId(id);
          setVisibleLog(true);
        },
      },
    ]);
  };

  const dataColumns: (BlColumnsType<MaintenanceListItemType>[0] & {
    filterName?: string;
    type?: number;
    isFilter?: boolean;
    selectProps?: any;
    renderItem?: ReactElement;
    dateFormat?: any;
  })[] = _.compact([
    {
      title: `${BL_BUSINESS_NAME}编号`,
      dataIndex: 'code',
      type: fieldTypeList.text,
      isFilter: true,
      sorter: true,
      fixed: 'left',
      width: 150,
      render: (value: string, record: MaintenanceListItemType, index: number, config: any) => {
        return record?.id ? (
          <LinkTooltip
            text={value}
            to={toDetail(record.id)}
            width={config.contentWidth}
            auth={authDict.maintenanceCase_detail}
          />
        ) : (
          value
        );
      },
    },
    {
      title: `${BL_BUSINESS_NAME}名称`,
      dataIndex: 'name',
      sorter: true,
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
      render: (value: string, record: MaintenanceListItemType, index: number, config: any) => {
        return record?.id ? (
          <LinkTooltip
            text={value}
            to={toDetail(record.id)}
            width={config.contentWidth}
            auth={authDict.maintenanceCase_detail}
          />
        ) : (
          value
        );
      },
    },
    {
      title: '维保目标',
      dataIndex: ['biz', 'name'],
      width: 150,
      filterName: 'maintenanceTargetId',
      isFilter: true,
      renderItem: (
        <SearchSelect fetchType="resource" params={{ type: businessType }} placeholder="请输入" />
      ),
    },
    {
      title: '维保目标编号',
      dataIndex: ['biz', 'code'],
      width: 150,
      filterName: 'maintenanceTargetCodeId',
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="resource"
          params={{ type: businessType }}
          placeholder="请输入"
          labelPath="code"
        />
      ),
    },
    {
      title: '业务类型',
      dataIndex: 'businessType',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      selectProps: {
        options: appDict.resources.maintenanceType,
      },
      render: (type: any) => lookup('resources.maintenanceType', type),
    },
    {
      title: '维保途径',
      dataIndex: 'channelType',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      selectProps: {
        options: appDict.resources.maintenanceChannel,
      },
      render: (type: any) => lookup('resources.maintenanceChannel', type),
    },
    {
      title: '维保任务分类',
      dataIndex: ['resourceType', 'name'],
      isFilter: true,
      width: 150,
      // render: (text: any) => {
      //   return valueOrHolder(text, (text: any) => _.map(text, (item) => item?.name).join('/'));
      // },
      filterName: 'classificationId',
      renderItem: (
        <ResourceClassificationCascader
          params={{
            businessType: appEnum.Resources.ResourceBusinessType.maintenance,
            status: appEnum.Common.UsageStatus.enabled,
            cascade: true,
          }}
          showSearch
          placeholder={'请选择维保任务分类'}
          onCreateClick={() => {}}
          enableAdd={false}
        />
      ),
    },
    // todo 暂时不上
    // {
    //   title: '资源占用',
    //   dataIndex: 'field5',
    //   isFilter: true,
    //   type: fieldTypeList.select,
    //   selectProps: {
    //     options: appDict.common.yn,
    //   },
    //   width: 150,
    //   render: (type: any) => lookup('common.ynb', type),
    // },
    {
      title: '扫码确认',
      dataIndex: 'scanCodeFlag',
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.common.ynb,
      },
      width: 150,
      render: (type: any) => lookup('common.ynb', type),
    },
    {
      title: '标准工时',
      dataIndex: 'standardWorkTime',
      width: 150,
      render: (time?: number) => {
        return valueOrHolder(time, () => {
          const { H, M, S } = getStandardWorkTime(time);

          return `${H}小时${M}分钟${S}秒`;
        });
      },
    },
    {
      title: '计划执行人',
      dataIndex: 'planExecutorList',
      width: 250,
      isFilter: true,
      render: (users: any[]) => <TagTooltip list={users || []} isUser max={2} />,
      renderItem: <PlanUserDeptRole />,
    },
    {
      title: '允许转派',
      dataIndex: 'assignFlag',
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.common.ynb,
      },
      width: 150,
      render: (type: any) => lookup('common.ynb', type),
    },
    {
      title: '谁可转派',
      dataIndex: 'assignFrom',
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: [...PlanAssignFromMap.values()],
        mode: 'multiple',
      },
      width: 150,
      render: (assignFrom: number) =>
        _.map(
          _.filter([...PlanAssignFromMap.keys()], (key) => (assignFrom & key) === key),
          (key) => PlanAssignFromMap.get(key)?.label,
        ).join('，'),
    },
    {
      title: '可转派给',
      dataIndex: 'assignTo',
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: [...PlanAssignToMap.values()],
        mode: 'multiple',
      },
      width: 150,
      render: (assignTo: number) =>
        _.map(
          _.filter([...PlanAssignToMap.keys()], (key) => (assignTo & key) === key),
          (key) => PlanAssignToMap.get(key)?.label,
        ).join('，'),
    },
    {
      title: '执行方式',
      dataIndex: 'executeType',
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.resources.maintenanceExecuteType,
      },
      width: 150,
      render: (type: any) => lookup('resources.maintenanceExecuteType', type),
    },
    {
      title: '报告模板',
      dataIndex: 'reportTemplateList',
      isFilter: true,
      width: 150,
      render: (list: any) => <TagList dataSource={list ?? []} labelPath="name" />,
      renderItem: (
        <SearchSelect
          fetchType="reportTemplate"
          placeholder="请选择报告模板"
          mode="multiple"
          params={{ category: [appEnum.ReportTemplate.TemplateType.maintenanceReport] }}
        />
      ),
    },
    {
      title: 'SOP方案',
      dataIndex: 'sopList',
      isFilter: true,
      width: 150,
      render: (list: any) => <TagList dataSource={list ?? []} labelPath="name" />,
      renderItem: (
        <SearchSelect
          fetchType="SOP"
          placeholder="请选择SOP方案"
          mode="multiple"
          params={{
            status: appEnum.Common.UsageStatus.enabled,
            bizType: appEnum.Sop.BizType.maintenance,
          }}
        />
      ),
    },
    {
      title: '启用状态',
      dataIndex: 'status',
      type: fieldTypeList.select,
      isFilter: true,
      width: 150,
      selectProps: {
        options: appDict.common.usageStatus,
      },
      render: (status: any) => <MaintenanceStatus status={status} />,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
    },
  ]);

  const filterList = dataColumns
    .filter((i) => i.isFilter)
    .map((column: any) => {
      const filter: any = {
        label: column.title,
        name: column.filterName || column.dataIndex,
        type: column.type,
        rules: column.rules,
        renderItem: column.renderItem,
      };

      if (column.type === fieldTypeList.select || column.type === fieldTypeList.multiSelect) {
        filter.selectProps = column.selectProps;
      }
      if (column.type === fieldTypeList.date && column.dateFormat) {
        filter.dateFormat = column.dateFormat;
      }
      return filter;
    });

  const formatDataToQuery = (data: any) => {
    const {
      responsibleDepartment,
      classificationId,
      executeType,
      reportTemplateList,
      sopList,
      maintenanceTargetCodeId,
      planExecutorList,
      assignFrom,
      assignTo,
      maintenanceTargetId,
      ...resData
    } = data;

    // 侧边栏筛选数据
    const maintenanceTreeData = {
      /** 是否包含子区域（侧边栏） */
      hasSubLocation: subLocationFlag,
      /** 维保业务对象 */
      bizType: businessType,
      /** 维保目标（侧边栏&筛选条件） */
      maintenanceTargetId:
        target?.type === 'Equipment' ? target?.id : _.get(maintenanceTargetId, 'value'),
      /** 区域ID（侧边栏） */
      locationId: target?.type === 'Location' ? target?.id : undefined,
    };

    const sendData = {
      ...resData,
      ...maintenanceTreeData,
      maintenanceTargetCodeId: _.get(maintenanceTargetCodeId, 'value'),
      departmentIdList: !_.isEmpty(responsibleDepartment)
        ? mapLabeledValueToValue(responsibleDepartment)
        : undefined,
      classificationId: !_.isEmpty(classificationId) ? _.last(classificationId) : undefined,
      executeType,
      executeIds:
        !_.isEmpty(sopList) || !_.isEmpty(reportTemplateList)
          ? _.map(_.compact(_.concat(sopList, reportTemplateList)), 'value')
          : undefined,
      planExecutorIdList: _.map(planExecutorList?.value, 'value'),
      assignFrom: !_.isEmpty(assignFrom)
        ? _.reduce(assignFrom, (sum, n) => _.sum([sum, n]), 0)
        : undefined,
      assignTo: !_.isEmpty(assignTo)
        ? _.reduce(assignTo, (sum, n) => _.sum([sum, n]), 0)
        : undefined,
    };

    dispatch.excelImport.updateBusinessData({ businessData: sendData });
    return sendData;
  };

  const formatDataToDisplay = (data: any) => {
    const { planExecutorList, ...res } = _.cloneDeep(data);

    return { ...res, planExecutorList: _.map(planExecutorList?.value, 'value') };
  };

  const formatDataToFormDisplay = (data: any) => {
    const { ...rest } = data;

    return {
      ...rest,
    };
  };

  return (
    <>
      <RecordListLayout<MaintenanceListItemType>
        filterList={filterList}
        requestFn={fetchMaintenanceCaseList}
        useIndex={false}
        mainMenu={mainMenu}
        batchMenu={batchMenu}
        placeholder={`请输入${BL_BUSINESS_NAME}名称或编号`} // 快速搜索input placeholder
        formatDataToQuery={formatDataToQuery} // 转换搜索条件进行查询（快速查询字段："quickSearch",当前页："page",分页大小："size"）
        formatDataToDisplay={formatDataToDisplay} // 转换搜索条件进行展示
        formatDataToFormDisplay={formatDataToFormDisplay}
        columns={dataColumns} // table columns
        refreshMarker={refreshMarker}
        resetRefreshMarker={resetRefreshMarker}
        selectedRowKeys={selectedRowKeys}
        rowKey="id"
        configcacheKey={BL_BUSINESS_CODE}
        onSelectedRowKeys={(selectKey: React.Key[]) => {
          setSelectedRowKeys(selectKey.map((key) => Number(key)));
        }}
        getOperationList={getOperationList}
        userAuth={getAuthFromLocalStorage()}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={OBJECT_OF_CODE.MaintenanceCase}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default List;
