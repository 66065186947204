import { useEffect, useState } from 'react';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { DataFormLayout } from 'layout';
import { Checkbox, Form, message } from 'antd';

import {
  fetchResourceSchedulerDetail,
  fetchResourceSchedulerCreate,
  fetchResourceSchedulerUpdate,
} from 'src/api/ytt/resource-domain/collectionRules';
import lookup, { appEnum } from 'src/dict';
import { CollectionRuleFormType } from '../interface';
import ActionContent, { formatDataToApi, formatDataToForm } from './BaseForm';
import { goToDetail } from '../navigates';

interface ActionProps {
  id: string;
}

const CollectionRuleAction = (props: RouteComponentProps<ActionProps>) => {
  const { match, history } = props;
  const id = Number(match.params.id);
  const type = history.location.pathname.split('/').pop() as 'create' | 'edit';

  const [collectionRuleForm] = Form.useForm<CollectionRuleFormType>();
  const [keepOn, setKeepOn] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await fetchResourceSchedulerDetail({ id });

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return {};
    } finally {
      setLoading(false);
    }
  };

  const fetchApi = (data: any) => {
    let fetchFn;

    if (_.isNil(data)) return Promise.reject();

    if (type === appEnum.Common.CRUD.create) fetchFn = fetchResourceSchedulerCreate;
    if (type === appEnum.Common.CRUD.edit) fetchFn = fetchResourceSchedulerUpdate;

    return fetchFn?.(data) ?? Promise.resolve({ code: 500, data: {} });
  };

  useEffect(() => {
    if (type === appEnum.Common.CRUD.edit && id) {
      fetchData().then((data) => {
        const initialFormValue = formatDataToForm(data ?? {});

        collectionRuleForm.setFieldsValue(initialFormValue);
      });
    }
  }, [id]);

  const handleFinish = async () => {
    setLoading(true);
    try {
      const value = await collectionRuleForm?.validateFields();

      const submitData = formatDataToApi(value);

      const { code, data } = await fetchApi(submitData);

      if (code === 200) {
        message.success(`${lookup('common.crud', type)}成功！`);

        if (keepOn) {
          collectionRuleForm.resetFields();
        } else {
          const id = _.get(data, 'id');

          if (id) {
            goToDetail(id);
          } else {
            history.goBack();
          }
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DataFormLayout
        loading={loading}
        form={collectionRuleForm}
        title={`${lookup('crud', type)}数采规则`}
        extra={
          type === appEnum.Common.CRUD.create && (
            <Checkbox
              onChange={() => {
                setKeepOn(!keepOn);
              }}
              defaultChecked={keepOn}
            >
              连续新建
            </Checkbox>
          )
        }
        info={[]}
        onFinish={handleFinish}
        onCancel={() => {
          history.goBack();
        }}
      >
        <ActionContent form={collectionRuleForm} type={type!} />
      </DataFormLayout>
    </>
  );
};

export default CollectionRuleAction;
