import { useState, useEffect } from 'react';
import { DataFormLayoutForModal, DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Form, Input, message } from 'antd';
import { fetchSopCopy } from 'src/api/ytt/sop-domain';
import { BizType } from 'src/dict/sop';
import lookup from 'src/dict';
import { textValidator1, textValidator2 } from 'src/utils/formValidators';
import { NumberRulesStandardHook } from 'src/components';
import _ from 'lodash';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  fromSopName: string;
  bizType: BizType;
  sopId: number;
  referenceId: number;
}

const formProps = {
  labelCol: { span: 5 },
};

const CopySopModal = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [refreshMarker, setRefreshMarker] = useState(0);
  const { visible, onCancel, onSuccess, fromSopName, bizType, sopId, referenceId } = props;
  const [form] = Form.useForm();
  const [numberRuleInput] = NumberRulesStandardHook({
    label: '复制后的编号',
    form,
    edit: false,
    disabledState: true,
    objectCode: 'SOPScheme',
    fieldCode: 'code',
    rules: [{ max: 120, message: '不超过120个字符' }, { validator: textValidator2 }],
    refreshMarker,
  });

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    items: [
      numberRuleInput,
      {
        label: '复制后的名称',
        name: 'name',
        rules: [
          { required: true },
          { validator: textValidator1 },
          { max: 200, message: '不超过200个字符' },
        ],
        render: () => <Input />,
      },
      {
        label: '复制后的SOP方案版本号',
        name: 'version',
        rules: [
          { required: true },
          { validator: textValidator1 },
          { max: 200, message: '不超过200个字符' },
        ],
        render: () => <Input />,
      },
      {
        label: '业务类型',
        name: 'bizType',
        render: () => lookup('sop.bizType', bizType),
      },
      { name: 'sopId', hidden: true, render: () => null },
      { name: 'referenceId', hidden: true, render: () => null },
    ].map((item) => _.assign(item, { isFullLine: true })),
  };

  const handleFinish = async () => {
    try {
      const value = await form.validateFields();

      setLoading(true);
      await fetchSopCopy(value);
      message.success('创建成功');
      onSuccess();
      onCancel();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // 每次重新打开弹窗时, 重置表单初始值
    if (visible) {
      setRefreshMarker(Date.now());
      form.setFieldsValue({
        name: `${fromSopName}-副本`,
        version: '',
        bizType,
        sopId,
        referenceId,
      });
    }
  }, [visible, fromSopName, sopId, referenceId]);

  return (
    <DataFormLayoutForModal
      visible={visible}
      onClose={onCancel}
      width={800}
      content={
        <DataFormLayout
          form={form}
          formProps={formProps}
          title="复制SOP方案"
          info={[baseInfo]}
          onCancel={onCancel}
          onFinish={handleFinish}
          confirmLoading={loading}
        />
      }
    />
  );
};

export default CopySopModal;
