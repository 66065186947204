import React, { ReactNode, useEffect, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Input, FormInstance, Radio, InputNumber, Form } from 'antd';
import FormTable from './createAndEditTable';
import { DetailSourceType, ShiftId } from '../interface';
import { CYCLE_NUMBER_TYPE } from '../constant';
import { setCycleShift, setInitialCycleShift } from '../utils';
import { textValidator1, withoutSpaceBothSides } from 'src/utils/formValidators';

const { TextArea } = Input;

interface baseInfoProps {
  onCancel: () => void;
  onFinish: () => void;
  title: string;
  initialData?: DetailSourceType;
  form: FormInstance;
  extra?: ReactNode;
  edit: boolean;
  batched: boolean;
  batchValue: ShiftId;
  handleSetBatched: (batch: boolean) => void;
  handleSetBatchValue: (batch: ShiftId) => void;
}

const CreateAndEditBaseInfo = (props: baseInfoProps) => {
  const {
    onCancel,
    title,
    onFinish,
    initialData,
    form,
    extra,
    edit,
    batched,
    batchValue,
    handleSetBatched,
    handleSetBatchValue,
  } = props;

  const { setFieldsValue, getFieldValue, getFieldsValue } = form;

  const [max, setMax] = useState(31);

  const typeChange = (value: number) => {
    let maxNumber = 1;

    if (value === CYCLE_NUMBER_TYPE.DAY) {
      maxNumber = 31;
    } else if (value === CYCLE_NUMBER_TYPE.WEEK) {
      maxNumber = 4;
    } else {
      maxNumber = 1;
    }
    setFieldsValue({ periodNum: 1 });
    setMax(maxNumber);
    const shiftRuleInfoCreateCOS = setInitialCycleShift(value);
    // 生成方式的改变不保留周期班次选好的班次

    setFieldsValue({ shiftRuleInfoCreateCOS });
  };

  const periodNumOnChange = (num: number) => {
    const { type } = getFieldsValue();
    // 重复周期的改变 需要保留数据 从后往前删
    const shiftRuleInfoCreateCOS = setCycleShift(type, num, form);

    setFieldsValue({ shiftRuleInfoCreateCOS });
  };

  console.log(getFieldValue('type') === CYCLE_NUMBER_TYPE.MONTH);

  const baseInfo: DataFormLayoutInfoBlock = {
    items: [
      {
        label: '规则名称',
        name: 'name',
        rules: [
          { required: true, message: '规则名称必填' },
          { max: 100, message: '不可超过100个字符' },
          { validator: textValidator1 },
          withoutSpaceBothSides,
        ],
        render: () => <Input placeholder="请输入" allowClear />,
      },
      {
        label: '周期类型',
        name: 'type',
        rules: [{ required: true, message: '周期类型必选' }],
        render: () => (
          <Radio.Group onChange={(e) => typeChange(e?.target?.value)}>
            <Radio value={CYCLE_NUMBER_TYPE.DAY}>按天</Radio>
            <Radio value={CYCLE_NUMBER_TYPE.WEEK}>按周</Radio>
            <Radio value={CYCLE_NUMBER_TYPE.MONTH}>按月</Radio>
          </Radio.Group>
        ),
      },
      {
        label: '重复周期',
        name: 'periodNum',
        rules: [{ required: true, message: '重复周期必选' }],
        render: () => (
          <InputNumber
            style={{ width: '100%' }}
            max={max}
            min={1}
            keyboard
            disabled={getFieldValue('type') === CYCLE_NUMBER_TYPE.MONTH}
            onChange={(e: number) => {
              periodNumOnChange(e);
            }}
          />
        ),
      },
      {
        label: '周期班次',
        isFullLine: true,
        required: true,
        render: () => (
          <Form.Item
            name="shiftRuleInfoCreateCOS"
            rules={[{ required: true, message: '周期班次必选' }]}
          >
            <FormTable
              initialData={initialData?.shiftRuleInfoVOS}
              form={form}
              batched={batched}
              batchValue={batchValue}
              handleSetBatched={handleSetBatched}
              handleSetBatchValue={handleSetBatchValue}
            />
          </Form.Item>
        ),
      },
      {
        label: '备注',
        name: 'remark',
        rules: [
          { max: 1000, message: '不超过1000个字符' },
          { validator: textValidator1 },
          withoutSpaceBothSides,
        ],
        render: () => <TextArea placeholder="请输入" />,
      },
      {
        label: '编辑原因',
        name: 'operateReason',
        hidden: !edit,
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
    ],
    column: 2,
  };

  useEffect(() => {
    if (initialData) {
      form?.setFieldsValue({
        name: initialData?.name,
        type: initialData?.type,
        periodNum: initialData?.periodNum,
        remark: initialData?.remark,
      });
    }
  }, [initialData]);

  return (
    <DataFormLayout
      form={form}
      title={title}
      info={[baseInfo]}
      extra={extra || ''}
      onCancel={onCancel}
      onFinish={onFinish}
    />
  );
};

export default CreateAndEditBaseInfo;
