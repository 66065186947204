/**
 * @mappings 映射表
 */

import { SourceEnum, execStatusEnum } from '.';
import { mappingsFactory } from '../utils';

export const Source = mappingsFactory(
  ['普通采购', SourceEnum.ordinary],
  ['协同采购', SourceEnum.coordination],
  ['交货计划单', SourceEnum.deliverySchedule],
);

export const execStatus = mappingsFactory(
  ['新建', execStatusEnum.created],
  ['执行中', execStatusEnum.executing],
  ['已结束', execStatusEnum.finished],
  ['已关闭', execStatusEnum.closed],
);
