export const goToDetail = (id: number) => {
  window.reactRouterHistoryInstance.push(`/sales/salesManagement/salesReturnOrder/${id}/detail`);
};

export const goToCreate = () => {
  window.reactRouterHistoryInstance.push('/sales/salesManagement/salesReturnOrder/create');
};

export const goToEdit = (id: number) => {
  window.reactRouterHistoryInstance.push(`/sales/salesManagement/salesReturnOrder/${id}/edit`);
};

export const goToList = () => {
  window.reactRouterHistoryInstance.push('/sales/salesManagement/salesReturnOrder');
};

export const goToSalesOrder = (id: number) => {
  window.open(`/sales/salesManagement/salesOrder/${id}/detail`);
};

export const goToCreateInbound = () => {
  window.reactRouterHistoryInstance.push(
    '/sales/salesManagement/salesReturnOrder/inbound?source=salesReturnOrder',
  );
};

export const goToInboundOrder = (id: number) => {
  window.open(`/warehouseManagement/boundOrderManagement/inBound/${id}/detail`);
};

export const goToReturnApplication = () => {
  window.reactRouterHistoryInstance.push(
    '/sales/salesManagement/salesReturnOrder/returnApplication',
  );
};

export const goToApplicationDetail = (id: number) => {
  window.reactRouterHistoryInstance.push(
    `/sales/salesManagement/salesReturnOrder/returnApplication/${id}/detail`,
  );
};
