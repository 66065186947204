import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Tag } from 'antd';

import { BlMultiCascader } from 'src/components';
import { ResourceBusinessType } from 'src/dict/resources';
import { fetchResourcesLabelGetLabelTree } from 'src/api/ytt/resource-domain/resourceTag';

interface Props {
  onChange?: (value: string[] | number[]) => void;
  value?: string[] | number[];
  placeholder?: string;
  nameLabel?: string;
  businessType?: ResourceBusinessType;
}

type CascaderDataItemType = {
  label: string;
  value: string;
  children?: CascaderDataItemType[];
  parent?: CascaderDataItemType;
};

type InputType = {
  content?: string;
  name?: string;
  id?: number;
  groupId?: number;
  children?: InputType[];
};

const handleFormatData = (list: InputType[]): CascaderDataItemType[] => {
  const data = list?.map((item: InputType) => {
    if (item.children) {
      return {
        label: item.content ?? item.name,
        // fetchResourcesLabelGetLabelTree接口返回的格式中，标签项的id就是第一个标签值的id，是重复的
        // 如果直接用id来当标签项的value，那么第一个标签值也会跟着标签项一起不可选
        value: item.groupId!,
        children: handleFormatData(item.children),
      };
    }
    return {
      label: item.content ?? item.name,
      value: item.id!,
    };
  });

  return data || [];
};

const ResourceTagCascader = (props: Props) => {
  const { onChange, value = [], placeholder = '请选择', nameLabel, businessType } = props;
  const [optionsList, setOptionsList] = useState<CascaderDataItemType[]>([]);
  const [, setFetching] = useState(false);

  const handelOnChange = (value: string[] | number[]) => {
    onChange && onChange(value);
  };

  const handleFetchData = async (searchParams?: string) => {
    let selectOptionsList: CascaderDataItemType[] = [];

    const res = await fetchResourcesLabelGetLabelTree({
      page: 1,
      size: 1000,
      businessType: businessType ?? ResourceBusinessType.equipment,
      quickSearch: searchParams,
    });

    selectOptionsList = handleFormatData(res?.data?.list! as InputType[]);

    return selectOptionsList;
  };
  const getList = async (searchParams?: string) => {
    setFetching(true);
    const selectOptionsList: CascaderDataItemType[] = await handleFetchData(searchParams);

    if (selectOptionsList?.length > 0) {
      setOptionsList(selectOptionsList);
    }
    setFetching(false);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <BlMultiCascader
      placeholder={`${placeholder}${nameLabel || ''}`}
      value={value}
      options={optionsList}
      onChange={handelOnChange}
      uncheckableItemValues={_.map(optionsList, 'value')}
      renderValue={(value: any, selectedItems: any) => (
        <span>
          {selectedItems.map((item: CascaderDataItemType) => {
            return (
              <Tag key={item.value}>
                {item.parent?.label}: {item.label}
              </Tag>
            );
          })}
        </span>
      )}
      onSearch={handleFetchData}
    />
  );
};

export default ResourceTagCascader;
