/**
 * 其他信息
 */
import { Form, FormInstance, Input, Select } from 'antd';
import moment from 'moment';
import { appDict, appEnum } from 'src/dict';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { BomFormOther } from './index.type';

interface OtherInfoFormProps {
  form: FormInstance;
  name: string;
}

// 格式化 其他信息 给接口
export const formatOtherToApi = (other?: BomFormOther) => {
  return { remark: other?.remark };
};

export const formatOtherToForm = (other?: any): BomFormOther => {
  return { remark: other?.remark };
};

export const OtherInfoForm = (props: OtherInfoFormProps) => {
  const { form, name } = props;

  const operator = form.getFieldValue(['workOrderBaseCO', 'operator']);
  const creator = form.getFieldValue(['workOrderBaseCO', 'creator']);

  return (
    <div>
      <Form form={form} name={name} labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
        <Form.Item
          name={[name, 'externalOrderCode']}
          rules={[{ max: 255, message: '不可超过255字符' }]}
          label="外部单据编号"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="领料申请单据"
          // name={[name, 'remark122']}
        >
          <Select
            value={appEnum.ProductionPlanning.AcquisitionApplicationEnum.PRODUCTIONWORKORDER}
            options={appDict.productionPlanning.AcquisitionApplicationEnumDisplayMap}
            disabled
          />
        </Form.Item>
        <Form.Item label="备注" name={[name, 'remark']}>
          <Input.TextArea maxLength={1000} showCount />
        </Form.Item>
        <Form.Item label="创建人">
          {operator?.id && (
            <AvatarDisplay
              id={creator?.id}
              name={creator?.name}
              key={creator?.id}
              avatarUrl={creator?.avatarUrl}
              isShowTag={false}
              isUser
            />
          )}
        </Form.Item>
        <Form.Item label="创建时间">
          {moment(form.getFieldValue(['workOrderBaseCO', 'createdAt'])).format(
            'YYYY-MM-DD HH:mm:ss',
          )}
        </Form.Item>
        <Form.Item label="更新人">
          {operator?.id && (
            <AvatarDisplay
              id={operator?.id}
              name={operator?.name}
              key={operator?.id}
              avatarUrl={operator?.avatarUrl}
              isShowTag={false}
              isUser
            />
          )}
        </Form.Item>
        <Form.Item label="更新时间">
          {moment(form.getFieldValue(['workOrderBaseCO', 'updatedAt'])).format(
            'YYYY-MM-DD HH:mm:ss',
          )}
        </Form.Item>
      </Form>
    </div>
  );
};
