import { FC } from 'react';
import { Typography, Space, Tooltip } from 'antd';
import { BlIcon } from 'src/components';
import lookup from 'src/dict';
import { ControlType } from 'src/dict/sop';
import { coverIconMap } from '../../constants';

const { Title } = Typography;

export interface TooltipContentProps {
  title: string;
  icon?: React.ReactNode;
}

const PropAreaTitle: FC<{ controlType: ControlType; tooltipContent: TooltipContentProps }> = ({
  controlType,
  tooltipContent,
}) => {
  return (
    <Title level={4}>
      <Space>
        <BlIcon type={coverIconMap.get(controlType) ?? ''} />
        {lookup('sop.controlType', controlType)}
        {tooltipContent.title && (
          <Tooltip title={tooltipContent.title}>
            <BlIcon type={tooltipContent.icon ?? 'iconwentizhiyinbangzhu'} />
          </Tooltip>
        )}
      </Space>
    </Title>
  );
};

export default PropAreaTitle;
