import { FormInstance, Input, Radio, Form, Select, InputNumber } from 'antd';
import { yn as ynMapping } from 'src/dict/common/mappings';
import { numberMinMaxCheck } from 'src/utils/formValidators';
import { UnitType } from 'src/dict/material';
import { ConvertUnit } from '../..';

interface Props {
  form: FormInstance;
}

const config = {
  max: 1000000,
  min: 0,
  isInteger: true,
  minAllowEqual: false,
  maxAllowEqual: false,
  promptByInterval: true,
  isRequired: false,
};

const taxRateConfig = {
  max: 100,
  min: 0,
  isInteger: true,
  minAllowEqual: true,
  maxAllowEqual: true,
  // promptByInterval: true,
  isRequired: false,
};

const InputNumberWithAddonAfter = (props: any) => {
  const { addonAfter, ...other } = props;

  return (
    <>
      <InputNumber {...other} />
      <span style={{ marginLeft: 8, display: 'inline-block' }}>{addonAfter}</span>
    </>
  );
};

export const purchaseBasicInfo = (props: Props) => {
  const { form } = props;

  return [
    {
      shouldUpdate: (prev, next) => {
        return prev.transformUnit !== next.transformUnit;
      },
      noStyle: true,
      render: (FormItemProps: any) => () => {
        const transformUnit = _.filter(
          form.getFieldValue('transformUnit'),
          ({ enableFlag }) => enableFlag,
        );
        const purchaseUnit = form.getFieldValue('purchaseUnit');
        const unitList = transformUnit
          .map((item: ConvertUnit) => {
            if (item.convertType !== UnitType.auxUnit && item.enableFlag) {
              return {
                label: item.toUnitId?.label,
                value: item.toUnitId?.value,
              };
            }
          })
          .filter(Boolean);

        // 确认此次修改的单位，是否还在formList
        if (!_.find(transformUnit, ({ toUnitId }) => toUnitId?.value === purchaseUnit?.value)) {
          // 否，purchaseUnit = 主单位
          form.setFields([{ name: 'purchaseUnit', value: transformUnit[0].fromUnitId }]);
        }

        return (
          <Form.Item
            name={'purchaseUnit'}
            label={'采购单位'}
            rules={[{ required: true, message: '请选择采购单位' }]}
            {...FormItemProps}
          >
            <Select labelInValue placeholder={'请选择'} options={unitList} />
          </Form.Item>
        );
      },
    },

    {
      label: '采购批量',
      name: 'purchaseAmount',
      rules: [{ validator: numberMinMaxCheck(config) }],
      validateFirst: true,
      render: () => <Input placeholder="请输入" type="number" />,
    },
    {
      label: '最小采购量',
      name: 'purchaseAmountMin',
      rules: [{ validator: numberMinMaxCheck(config) }],
      validateFirst: true,
      render: () => <Input placeholder="请输入" type="number" />,
    },
    {
      label: '货源控制',
      name: 'sourceControl',
      rules: [{ required: true, message: '请选择货源控制' }],
      render: () => <Radio.Group options={ynMapping} />,
    },
    {
      label: '税率',
      name: 'taxRate',
      rules: [{ validator: numberMinMaxCheck(taxRateConfig) }],
      render: () => <InputNumberWithAddonAfter addonAfter="%" />,
    },
  ];
};
