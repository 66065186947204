import BigJs from 'big.js';
import moment from 'moment';
import { replaceSign } from '../constants';

import gcArray from './array';

export const DEFAULT_FORMAT_RULE = 'YYYY-MM-DD HH:mm:ss';
export const FORMAT_RULE_LINE = 'YYYY-MM-DD HH:mm:ss';
export const FORMAT_DAY_UNDER_LINE = 'YYYY_MM_DD';
export const FORMAT_DAY = 'YYYY/MM/DD';
export const FORMAT_HOUR = 'HH:mm:ss';
export const FORMAT_DATE_HOUR = 'MM/DD HH:mm';

const now = (): moment.Moment => moment().utcOffset(8);

/**
 * 获取当前整点时间
 * @returns
 */
const nowWholeHour = (): moment.Moment => moment().minutes(0).seconds(0).utcOffset(8);
/**
 * @description 格式化日期时间为对应时间戳
 * @param value
 * @returns
 */
const formatUnixMoment = (value: number): moment.Moment => moment.unix(value / 1000);

/**
 * @description 格式化日期时间为对应时间戳
 * @param value
 * @returns
 */
const formatToUnix = (value: moment.MomentInput): string =>
  moment(value).set({ millisecond: 0 }).format('x');
/**
 * @description 格式化日期数组 为对应的两个时间戳
 * @param value
 * @returns
 */
const formatRangeUnix = (value: string | any[]): string[] => {
  if (!value || value.length === 0) {
    return [];
  }
  return [
    formatToUnix(
      moment(value[0]).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }),
    ),
    formatToUnix(
      moment(value[1]).set({
        hour: 23,
        minute: 59,
        second: 59,
      }),
    ),
  ];
};

/**
 * @description 格式化日期时间数组 为对应的两个时间戳
 * @param value
 */
const formatTimeRangeUnix = (value: string | any[]): string[] => {
  if (!value || value.length === 0) {
    return [];
  }
  return [formatToUnix(moment(value[0])), formatToUnix(moment(value[1]))];
};

/**
 * @description 格式化日期数组 为对应的两个时间戳(number)
 * @param value
 * @returns
 */
const formatRangeUnixToInt = (value: string | any[]): number[] => {
  return formatRangeUnix(value).map((timestamp: string) => Number(timestamp));
};
/**
 * 将不是moment的time range转换为moment time组成的time range
 * @param value [date, date]
 * @returns [moment, moment]
 */
const formatRangeTimeToMoment = (value: string | any[]): any[] => {
  if (!Array.isArray(value) || value.length !== 2) return [];
  return [value[0] ? moment(value[0]) : undefined, value[1] ? moment(value[1]) : undefined];
};

/**
 * 📢 将 moment range 转换为 unix range，一般用于处理提交给后端的参数 format
 * ❗ extraFormat 和 moments 需要一一对应使用
 * @param {Array} moments moment数组
 * @param {Array} extraFormat 额外还需要format的function数组 ex：[setDayStart, setDayEnd]
 */
export const formatMomentRangeToUnix = (
  moments: moment.Moment[],
  extraFormat?: any,
): Array<any> | any => {
  if (gcArray.isEmpty(moments)) return [];
  return moments.map((momentArg, i) => {
    if (!momentArg) return undefined;
    const fn = extraFormat ? extraFormat?.[i] : null;

    if (typeof fn === 'function') {
      return formatToUnix(fn(momentArg));
    }
    return formatToUnix(momentArg);
  });
};

/**
 * @description 给日期增加时刻为00:00:00 的具体时间
 * @param value
 * @returns
 */
const setDayStart = (value: moment.MomentInput): moment.Moment =>
  moment(value).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

/**
 * @description 给日期增加时刻为23:59:59 的具体时间
 * @param value
 * @returns
 */
const setDayEnd = (value: moment.MomentInput): moment.Moment =>
  moment(value).set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 0,
  });
/**
 * @description 设置时间的秒为0
 * @param value
 * @returns
 */
const setNoSecond = (value: moment.MomentInput): moment.Moment => moment(value).seconds(0);
/**
 * @description 设置时间为整点
 * @param value
 * @returns
 */
const setOnlyHour = (value: moment.MomentInput): moment.Moment =>
  moment(value).minutes(0).seconds(0);

/**
 * @description 格式化日期为 MM/DD
 */
const formatDateNoYear = (day: Date): string => moment(new Date(day)).format('MM/DD');
/**
 * @description 获取当前时间并且格式化为 YYYY_MM_DD
 * @param date  日期默认为当前时间
 * @returns
 */
const formatTodayUnderline = (date: Date = new Date()): string =>
  moment(date).format(FORMAT_DAY_UNDER_LINE);
/** 格式化时间的为 HH:mm:ss */
const formatTime = (time: string): string => moment(time).format(FORMAT_HOUR);

/**
 * @description 格式化显示时间
 * @param date  日期
 * @param format 日期显示格式，不传为默认格式
 * @returns
 */
const format = (date: moment.MomentInput | moment.Moment, formatStr?: string): string => {
  let day = date;

  if (typeof date === 'string') {
    day = new Date(date);
  }
  return moment(day).format(formatStr || DEFAULT_FORMAT_RULE);
};

/**
 * @description 格式化为，YYYY-MM-DD HH:mm:ss
 * @param date
 * @returns
 */
const formatLine = (date: moment.MomentInput): string => {
  let day = date;

  if (typeof date === 'string') {
    day = new Date(date);
  }
  return moment(day).format(FORMAT_RULE_LINE);
};
/**
 * @description     格式化为  YYYY/MM/DD
 * @param dataTime  日期，如果不传默认为当前时间
 * @returns
 */
const formatDate = (day?: number | string): string => {
  if (day) {
    if (typeof day === 'string' && !Number.isNaN(Date.parse(day))) {
      // Date.parse() 判断不合法的字符串会返回NaN
      return moment(new Date(day)).format(FORMAT_DAY);
    }

    if (typeof day === 'number') {
      return moment(new Date(day)).format(FORMAT_DAY);
    }

    return replaceSign;
  }
  return moment(new Date()).format(FORMAT_DAY);
};

/**
 * @description 格式化为 'MM/DD HH:mm'
 * @param dataTime
 * @returns
 */
const formatDateHourNoYear = (dataTime: Date): string =>
  moment(new Date(dataTime)).format(FORMAT_DATE_HOUR);

/**
 * @description 获取下一天的日期
 * @param day   日期，如果不传默认为当前时间
 * @returns
 */
const nextDay = (day: Date = new Date()): moment.Moment => {
  const newDate = new Date(day);

  newDate.setDate(newDate.getDate() + 1);
  return moment(newDate);
};

/**
 * @description 获取下一个小时的日期
 * @param day   日期，如果不传默认为当前时间
 * @returns
 */
const nextHour = (day = new Date()): moment.Moment => {
  const newDate = new Date(day);

  newDate.setHours(newDate.getHours() + 1);
  newDate.setMinutes(0);
  return moment(newDate);
};

/**
 * @description 获取几天前的日期
 * @param days  天数
 * @param date  日期，如果不传默认为当前时间
 * @returns
 */
const daysAgo = (days: number, date?: Date): moment.Moment => {
  const theDay = date || new Date();

  return moment(new Date(theDay.getTime() - days * 24 * 60 * 60 * 1000));
};

const daysAfter = (days: number, date?: Date): moment.Moment => {
  const theDay = date || new Date();

  return moment(new Date(theDay.getTime() + days * 24 * 60 * 60 * 1000));
};

/**
 * @description 获取几个小时以前的日期
 * @param day   日期，如果不传默认为当前时间
 * @param hours 小时数
 * @returns
 */
const hoursAgo = (hours: number, date?: Date): moment.Moment => {
  const theDay = date || new Date();

  return moment(new Date(theDay.getTime() - hours * 60 * 60 * 1000));
};

/**
 * @description 获取几个小时以后的日期
 * @param day   日期，如果不传默认为当前时间
 * @param hours 小时数
 * @returns
 */
const addHours = (day: Date = new Date(), hours: number): moment.Moment => {
  return moment(new Date(day).getTime() + hours * 60 * 60 * 1000);
};

/**
 * @description 相对时间, 目标日期距离现在过去了多久
 * @example     14 小时前
 * @param day   日期
 * @returns
 */
const dayStart = (day: moment.MomentInput): moment.Moment => {
  return moment(day).startOf('day');
};

/**
 * @description 相对时间, 现在距离目前日期
 * @example     10 小时内
 * @param day   日期
 * @returns
 */
const dayEnd = (day: moment.MomentInput): moment.Moment => {
  return moment(day).endOf('day');
};

/**
 * @description 计算时间差
 * @param time1
 * @param time2
 * @param format 时间差的显示格式'hours'| 'years'
 * @returns
 */
const diff = (
  time1: moment.MomentInput,
  time2: moment.MomentInput,
  formatStr: moment.unitOfTime.Diff,
): number => moment(time1).diff(moment(time2), formatStr);

/**
 * @description 检查一个 moment 是否在另一个 moment 之前
 * @param time
 * @returns
 */
const beforeNow = (time: moment.MomentInput): boolean => moment(time).isBefore(now());

/**
 * @description 检查一个 moment 是否在另一个 moment 之后，
 * @param time
 * @returns
 */
const fromNow = (time: moment.MomentInput): string => moment(time).fromNow();
/**
 * @description 比较两个日期中比较小的那个
 * @param time1
 * @param time2
 * @returns     较小的日期
 */
const minDate = (time1: moment.MomentInput, time2: moment.MomentInput): moment.MomentInput => {
  if (moment(time1).diff(moment(time2)) < 0) {
    return time1;
  } else {
    return time2;
  }
};
/**
 * @description 比较两个日期中比较大的那个
 * @param time1
 * @param time2
 * @returns     较大的日期
 */
const maxDate = (time1: moment.MomentInput, time2: moment.MomentInput): moment.MomentInput => {
  if (moment(time1).diff(moment(time2)) > 0) {
    return time1;
  } else {
    return time2;
  }
};
/**
 * @description  日期是否为今天
 * @param time
 * @returns
 */
const isToday = (time: moment.MomentInput): boolean => moment(time).isSame(moment(), 'day');
/**
 * @description  日期是否为昨天
 * @param time
 * @returns
 */
const isYesterday = (time: moment.MomentInput): boolean =>
  moment(time).isSame(moment().subtract(1, 'days'), 'day');
/**
 * @description  格式化日期为 `今天 HH:mm:ss`
 * @param time
 * @returns
 */
const showTodayTime = (time: moment.MomentInput): string =>
  moment(time).calendar(null, { sameDay: `${['今天']} HH:mm:ss` });
/**
 * @description  格式化日期为 `昨天 HH:mm:ss`
 * @param time
 * @returns
 */
const showYesterdayTime = (time: moment.MomentInput): string =>
  moment(time).calendar(null, { lastDay: `${['昨天']} HH:mm:ss` });

const genIntervals = () => [
  {
    name: 'today',
    display: '今天',
    from: format(moment().startOf('day')),
    till: format(moment().endOf('day')),
  },
  {
    name: 'yesterday',
    display: '昨天',
    from: format(daysAgo(1).startOf('day')),
    till: format(daysAgo(1).endOf('day')),
  },
  {
    name: 'thisWeek',
    display: '本周',
    from: format(moment().startOf('week')),
    till: format(moment().endOf('week')),
  },
  {
    name: 'lastWeek',
    display: '上周',
    from: format(daysAgo(7).startOf('week')),
    till: format(daysAgo(7).endOf('week')),
  },
  {
    name: 'tomorrow',
    display: '明天',
    from: format(daysAgo(-1).startOf('day')),
    till: format(daysAgo(-1).endOf('day')),
  },
  {
    name: 'nextWeek',
    display: '下周',
    from: format(daysAgo(-7).startOf('week')),
    till: format(daysAgo(-7).endOf('week')),
  },
  {
    name: 'nextMonth',
    display: '下月',
    from: format(daysAgo(-30).startOf('month')),
    till: format(daysAgo(-30).endOf('month')),
  },
];

/**
 * genInterval 根据时间描述获取具体日期
 * @param timeTag 'nextMonth' | nextWeek' | 'tomorrow' | 'lastWeek' | 'thisWeek' | 'yesterday' | 'today'
 * @returns 对应时间段及其描述
 */
const genInterval = (timeTag: string): any => {
  const interval = genIntervals().find((item) => item.name === timeTag || item.display === timeTag);

  return interval;
};

const BASE_SECOND_MILLISECONDS = 1000; // 1s = 1000ms
const BASE_MINUTE_MILLISECONDS = 60 * BASE_SECOND_MILLISECONDS; // 1min = 60 * 1000ms
const BASE_HOUR_MILLISECONDS = 60 * BASE_MINUTE_MILLISECONDS; // 1h = 3600 * 1000ms
const BASE_DAY_MILLISECONDS = 24 * BASE_HOUR_MILLISECONDS; // 1day = 86400 * 1000ms
/**
 * @description 格式化时间长间隔
 * @param length 两个时间戳之和差
 * @param format 对应的时间单位 'day' | 'minute' | 'hour'
 * @returns
 */
const genMilliseconds = (length: number, formatStr: any): number => {
  switch (formatStr) {
    case 'day':
      return length * BASE_DAY_MILLISECONDS;

    case 'minute':
      return length * BASE_MINUTE_MILLISECONDS;

    case 'hour':
      return length * BASE_HOUR_MILLISECONDS;

    case 'second':
      return length * BASE_MINUTE_MILLISECONDS;

    default:
      return length * BASE_DAY_MILLISECONDS;
  }
};

/**
 * @description 将毫秒数转换成对应单位 单位有 ms/m/h/d
 * @param param0 { time: 毫秒数，fromUnit：需转换时间的单位，targetUnit：转换的单位，raw: 是否用对象的格式返回结构 }
 * @returns
 */
// TODO: 目前仅支持fromUnit是ms
const convertTimeAndUnit = ({ time, fromUnit = 'ms', targetUnit, raw = false }: ConvertTime) => {
  if (time === undefined || !targetUnit) {
    console.error('时间转换必须传入时间和目标单位');
  }
  if (fromUnit !== 'ms') {
    console.error('暂不支持其他单位的转换');
  }
  let res;
  let _time = time;

  if (fromUnit === 'ms' && targetUnit === 'm') {
    _time = new BigJs(time).div(60 * 1000).valueOf();
    res = raw ? { time: _time, unit: targetUnit } : `${_time}分钟`;
  } else if (fromUnit === 'ms' && targetUnit === 'h') {
    _time = new BigJs(time).div(60 * 60 * 1000).valueOf();
    res = raw ? { time: _time, unit: targetUnit } : `${_time}小时`;
  } else if (fromUnit === 'ms' && targetUnit === 'd') {
    _time = new BigJs(time).div(24 * 60 * 60 * 1000).valueOf();
    res = raw ? { time: _time, unit: targetUnit } : `${_time}天`;
  }
  return res;
};

interface ConvertTime {
  time: string | number;
  fromUnit: string;
  targetUnit: string;
  raw: boolean;
}
interface TimeUnit {
  time: string;
  unit: string;
}
interface FuncProps {
  nowWholeHour: () => moment.Moment;
  formatUnixMoment: (value: number) => moment.Moment;
  formatToUnix: (value: string) => string;
  formatMomentRangeToUnix: (moments: moment.Moment[], extraFormat?: any) => any[];
  setDayStart: (vale: string) => moment.Moment;
  setDayEnd: (vale: string) => moment.Moment;
  setNoSecond: (value: moment.MomentInput) => moment.Moment;
  setOnlyHour: (value: moment.MomentInput) => moment.Moment;
  formatDate: (day?: string | number) => string;
  formatDateNoYear: (day: Date) => string;
  formatTodayUnderline: () => string;
  formatTime: (time: string) => string;
  format: (date: moment.MomentInput, formatStr?: string) => string;
  formatLine: (date: moment.MomentInput, formatStr?: string) => string;
  formatDateHourNoYear: (dataTime: Date, formatStr: string) => string;
  nextDay: (day: Date) => moment.Moment;
  nextHour: (day: Date) => moment.Moment;
  daysAgo: (days: number, date?: Date) => moment.Moment;
  daysAfter: (days: number, date?: Date) => moment.Moment;
  hoursAgo: (hours: number, date?: Date) => moment.Moment;
  addHours: (day: Date, hours: number) => moment.Moment;
  dayStart: (day: moment.MomentInput) => moment.Moment;
  dayEnd: (day: moment.MomentInput) => moment.Moment;
  diff: (time1: moment.MomentInput, time2: moment.MomentInput, formatStr: any) => number;
  beforeNow: (time: moment.MomentInput) => boolean;
  fromNow: (time: moment.MomentInput) => string;
  minDate: (time1: moment.MomentInput, time2: moment.MomentInput) => moment.MomentInput;
  maxDate: (time1: moment.MomentInput, time2: moment.MomentInput) => moment.MomentInput;
  isToday: (time: moment.MomentInput) => boolean;
  isYesterday: (time: moment.MomentInput) => boolean;
  showTodayTime: (time: moment.MomentInput) => string;
  showYesterdayTime: (time: moment.MomentInput) => string;
  genInterval: (timeTag: string) => any;
  genMilliseconds: (length: number, formatStr: any) => number;
  convertTimeAndUnit: (x: ConvertTime) => TimeUnit | string | undefined;
  formatRangeUnix: (value: string | any[]) => string[];
  formatTimeRangeUnix: (value: string | any[]) => string[];
  formatRangeUnixToInt: (value: string | any[]) => number[];
  formatRangeTimeToMoment: (value: string | any[]) => any[];
}

const _Time = {} as FuncProps;

_Time.nowWholeHour = nowWholeHour;
_Time.formatUnixMoment = formatUnixMoment;
_Time.formatToUnix = formatToUnix;
_Time.formatMomentRangeToUnix = formatMomentRangeToUnix;
_Time.setDayStart = setDayStart;
_Time.setDayEnd = setDayEnd;
_Time.addHours = addHours;
_Time.beforeNow = beforeNow;
_Time.dayEnd = dayEnd;
_Time.dayStart = dayStart;
_Time.daysAgo = daysAgo;
_Time.daysAfter = daysAfter;
_Time.diff = diff;
_Time.format = format;
_Time.formatDate = formatDate;
_Time.formatDateHourNoYear = formatDateHourNoYear;
_Time.formatDateNoYear = formatDateNoYear;
_Time.formatLine = formatLine;
_Time.formatTime = formatTime;
_Time.formatToUnix = formatToUnix;
_Time.formatTodayUnderline = formatTodayUnderline;
_Time.formatUnixMoment = formatUnixMoment;
_Time.fromNow = fromNow;
_Time.genInterval = genInterval;
_Time.genMilliseconds = genMilliseconds;
_Time.hoursAgo = hoursAgo;
_Time.isToday = isToday;
_Time.isYesterday = isYesterday;
_Time.maxDate = maxDate;
_Time.minDate = minDate;
_Time.nextDay = nextDay;
_Time.nextHour = nextHour;
_Time.nowWholeHour = nowWholeHour;
_Time.setDayEnd = setDayEnd;
_Time.setDayStart = setDayStart;
_Time.setNoSecond = setNoSecond;
_Time.setOnlyHour = setOnlyHour;
_Time.showTodayTime = showTodayTime;
_Time.showYesterdayTime = showYesterdayTime;
_Time.convertTimeAndUnit = convertTimeAndUnit;
_Time.formatRangeUnix = formatRangeUnix;
_Time.formatTimeRangeUnix = formatTimeRangeUnix;
_Time.formatRangeUnixToInt = formatRangeUnixToInt;
_Time.formatRangeTimeToMoment = formatRangeTimeToMoment;

export default _Time;
