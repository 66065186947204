import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Space, Dropdown, Menu, Tag, Form, message } from 'antd';
import { RecordListLayout } from 'src/layout';
import CreateAndEdit from './createAndEdit/createAndEdit';
import Details from './details/details';
import {
  fetchMaterialAttributeDelete,
  fetchMaterialAttributeList,
} from 'src/api/ytt/material-domain/Web_MaterialAttribute';
import { BlIcon, SingleOperationLogComponent, TagList, Tooltip } from 'components';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import { replaceSign } from 'src/utils/constants';
import { CRUD } from 'src/dict/common';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import _, { divide } from 'lodash';
import authDict, { hasAuth } from 'src/utils/auth';
import lookup from 'src/dict';

interface TableRowType {
  content?: string;
  index?: number;
  id: number;
}
const MaterialPropertyList = () => {
  const [detailVisible, setDetailVisible] = useState(false);
  const [operationRecordVisible, setOperationRecordVisible] = useState(false);
  const [createAndeditVisible, setCreateAndeditVisible] = useState(false);
  const [actionType, setActionType] = useState(CRUD.create);
  const [arrtId, setArrtId] = useState<number | undefined>(undefined);
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [refreshDetailsMarker, setRefreshDetailsMarker] = useState<number | undefined>(undefined);
  const [delForm] = Form.useForm();
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);

  const handleFinish = async (id?: number | any[]) => {
    try {
      const value = await delForm?.validateFields();
      let ids;

      if (id) {
        // 操作列的删除
        ids = typeof id === 'number' ? [id] : id;
      } else {
        // 按钮的删除
        ids = selectedRowKeys;
      }
      const { code, message: mes } = await fetchMaterialAttributeDelete({
        ids,
        reason: value.delReason,
      });

      if (code === 200) {
        message.success('删除成功');
        // 刷新list
        setRefreshMarker(new Date().getTime());
        return;
      }
      message.error(mes);
    } catch (error) {
      console.log(error);
    }
  };
  const dataColumns = [
    {
      title: '属性项',
      dataIndex: 'name',
      type: 'text',
      isFilter: true,
      sorter: true,
      fixed: 'left',
      render: (text: string, record: TableRowType, index: number, config: any) => {
        return hasAuth(authDict.materialattributeitem_detail) ? (
          <a
            onClick={() => {
              setArrtId(record.id);
              setDetailVisible(true);
              // window.open(`/knowledgeManagement/materialModeling/unitsList/details/${record.id}`);
            }}
          >
            <Tooltip text={text ?? replaceSign} width={config.contentWidth} />
          </a>
        ) : (
          <Tooltip text={text ?? replaceSign} width={config.contentWidth} />
        );
      },
    },
    {
      title: '属性值',
      dataIndex: 'attributeItemsList',
      type: 'text',
      isFilter: true,
      render: (text: { content: string; id: number }[]) => {
        if (_.isEmpty(text)) return replaceSign;

        return (
          <TagList
            dataSource={_.map(text, ({ content, id }) => ({
              label: `${content}`,
              value: id,
            }))}
          />
        );
      },
    },
  ];
  const config = {
    mainMenu: [
      {
        title: '导出',
        showExport: true,
        auth: authDict.materialattributeitem_export,
        icon: <BlIcon type="icondaochu" />,
        onClick: () => {
          openModal({
            optType: 'export',
            businessType: BUSINESS_TYPE.materialAttribute,
          });
        },
      },
      {
        title: '新建属性',
        auth: authDict.materialattributeitem_add,
        icon: <BlIcon type="iconxinjiantianjia" />,
        onClick: () => {
          setArrtId(undefined);
          setActionType(CRUD.create);
          setCreateAndeditVisible(true);
        },
        items: [
          {
            title: '导入',
            auth: authDict.materialattributeitem_import,
            showExport: true,
            onClick: () => {
              openModal({
                optType: 'import',
                businessType: BUSINESS_TYPE.materialAttribute,
              });
            },
          },
        ],
      },
    ],
    batchMenu: [
      {
        title: '删除',
        onClick: (onSuccess: any, onFail: any) => {
          onFail();
          BLDelConfirm('是否删除当前属性项及属性项下所有属性值', delForm, () => {
            handleFinish();
          });
        },
      },
    ],
    getOperationList: (record: any) => {
      const { id } = record;

      return [
        {
          title: lookup('crud', CRUD.view),
          auth: authDict.materialattributeitem_detail,
          onClick: () => {
            setArrtId(id);
            setDetailVisible(true);
          },
        },
        {
          title: lookup('crud', CRUD.edit),
          auth: authDict.materialattributeitem_edit,
          onClick: () => {
            setArrtId(id);
            setCreateAndeditVisible(true);
            setActionType(CRUD.edit);
          },
        },
        // {
        //   title: lookup('crud', CRUD.delete),
        //   onClick: () => {
        //     BLDelConfirm('是否删除当前属性项', delForm, () => {
        //       handleFinish(id);
        //     });
        //   },
        // },
        {
          title: '操作记录',
          auth: authDict.materialattributeitem_operrecord,
          onClick: () => {
            setArrtId(id);
            setVisibleLog(true);
          },
        },
      ];
    },
    columns: dataColumns.map((column) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isFilter, type, ...rest } = column;

      return { ...rest };
    }),
    rowKey: 'id',
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((column) => {
        const filter = {
          label: column.title,
          name: column.dataIndex,
          type: column.type,
        } as any;

        return filter;
      }),
  };
  const formatDataToQuery = (params: any) => {
    if (params?.attributeItemsList) {
      params.content = params.attributeItemsList;
      delete params.attributeItemsList;
    }

    dispatch.excelImport.updateBusinessData({ businessData: params });
    return params;
  };
  const formatDataToDisplay = (params: any = {}) => {
    const retData = { ...params };

    return retData;
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        columns={config.columns}
        filterList={config.filterList}
        rowKey={config.rowKey}
        // batchMenu={config.batchMenu}
        mainMenu={config.mainMenu}
        getOperationList={config.getOperationList}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={formatDataToDisplay}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey) => {
          setSelectRowKeys(selectKey);
        }}
        requestFn={fetchMaterialAttributeList}
        refreshMarker={refreshMarker}
        configcacheKey={'materialProperty'}
      />
      {createAndeditVisible && (
        <CreateAndEdit
          id={arrtId}
          type={actionType}
          visible={createAndeditVisible}
          onCancel={() => setCreateAndeditVisible(false)}
          refreshTable={() => {
            setRefreshMarker(new Date().getTime());
          }}
          refreshDetails={() => {
            setRefreshDetailsMarker(new Date().getTime());
          }}
        />
      )}
      {detailVisible && (
        <Details
          id={Number(arrtId)}
          visible={detailVisible}
          onCancel={() => setDetailVisible(false)}
          showEdit={() => {
            setArrtId(Number(arrtId));
            setCreateAndeditVisible(true);
            setActionType(CRUD.edit);
          }}
          showOperationRecord={() => {
            setArrtId(Number(arrtId));
            setVisibleLog(true);
          }}
          refreshDetailsMarker={refreshDetailsMarker}
          refreshTable={() => {
            setRefreshMarker(new Date().getTime());
          }}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={arrtId as number}
          objectCode={OBJECT_OF_CODE.materialAttributeItem}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(MaterialPropertyList);
