import { FormInstance, message as Message } from 'antd';
import {
  FetchPickOrderCloseRequest,
  fetchPickOrderClose,
} from 'src/api/ytt/med-domain/store-requisition';

export const closeStoreRequisiton = async (id: number, reasonForm: FormInstance) => {
  // 从确认删除的form里得到删除原因
  const reason = reasonForm.getFieldValue('delReason');
  const requestParam: FetchPickOrderCloseRequest = {
    pickOrderId: id,
    closeReason: reason,
  };

  const response = await fetchPickOrderClose(requestParam);

  if (response.code === 200) {
    Message.success('关闭成功');
  } else {
    Message.error(response.message);
  }
};
