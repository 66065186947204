import React, { useState } from 'react';
import { fieldTypeList } from 'src/utils';
import { replaceSign } from 'src/utils/constants';
import { FilterItem, RecordListLayout } from 'src/layout';
import {
  fetchConfigBatchClose,
  fetchConfigBatchOpen,
  fetchConfigList,
} from 'src/api/ytt/message-domain/messageSet';
import { SearchSelect } from 'src/components';
import _String from 'src/utils/dataTypes/string';
import { message as Message } from 'antd';
import { map } from 'lodash';

const STATUS_ENUM = [
  { key: 0, label: '已接收' },
  { key: 1, label: '已拒收' },
];

export const activeMap = new Map([
  [0, '已接收'],
  [1, '已拒收'],
]);

interface TableRowType {
  reject: boolean;
  template: TemplateType;
}

interface TemplateType {
  bizTypeName: string;
  moduleName: string;
  title: string;
  content: string;
  templateId: number;
  moduleId: number;
}

interface batchObjType {
  templateId: number;
  moduleId: number;
}

export default function NotificationSetting() {
  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  const [selectedRows, setSelectRows] = useState<batchObjType[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新页面

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  const batchRequest = (
    batchApi: any,
    selectRows: batchObjType[],
    display: string,
    onSuccess: any,
    onFail?: any,
  ) => {
    batchApi(selectRows)
      .then((res: any) => {
        const { data, message } = res;

        if (data) {
          Message.success(`${display}成功`);
          onSuccess?.();
          return;
        }

        Message.error(message);
      })
      .catch((err: any) => {
        console.log('err: ', err);
        onFail?.();
      });
  };

  const dataColumns = [
    {
      title: '业务对象',
      dataIndex: ['template', 'moduleName'],
      width: 150,
      render: (moduleName: string) => moduleName ?? replaceSign,
    },
    // {
    //   title: '业务类型',
    //   dataIndex: ['template', 'bizTypeName'],
    //   width: 150,
    //   render: (bizTypeName: string) => bizTypeName ?? replaceSign,
    // },
    {
      title: '通知标题',
      dataIndex: ['template', 'title'],
      width: 150,
      render: (title: string) => title ?? replaceSign,
    },
    {
      title: '通知内容',
      dataIndex: ['template', 'content'],
      width: 150,
      render: (content: string) => content ?? replaceSign,
    },
    {
      title: '接收状态',
      dataIndex: 'reject',
      width: 150,
      render: (reject: boolean) => (reject ? '已拒收' : '已接收'),
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '业务对象',
      name: 'moduleId',
      type: fieldTypeList.select,
      renderItem: <SearchSelect fetchType={'moduleNotification'} labelInValue />,
    },
    // {
    //   label: '业务类型',
    //   name: 'bizTypeId',
    //   type: fieldTypeList.select,
    //   renderItem: <SearchSelect fetchType={'typeNotification'} labelInValue />,
    // },
    {
      label: '业务标题',
      name: 'title',
      type: fieldTypeList.text,
    },
    {
      label: '通知内容',
      name: 'content',
      type: fieldTypeList.text,
    },
    {
      label: '接收状态',
      name: 'reject',
      type: fieldTypeList.select,
      selectProps: {
        options: STATUS_ENUM.map((node: any) => {
          return {
            label: node?.label,
            value: node?.key,
          };
        }),
      },
    },
  ];

  const formatDataToQuery = (params: any) => {
    const { content, moduleId, bizTypeId, title, quickSearch, ...rest } = params;

    const relParams = {
      ...rest,
    };

    if (!_String.isEmpty(content)) {
      relParams.content = content;
    }

    if (!_String.isEmpty(title) || !_String.isEmpty(quickSearch)) {
      relParams.title = title || quickSearch;
    }

    if (moduleId) {
      relParams.moduleId = moduleId?.value;
    }

    if (bizTypeId) {
      relParams.bizTypeId = bizTypeId?.value;
    }

    return relParams;
  };

  const formatDataToFormDisplay = (data: any) => {
    return data;
  };

  const toolbar = [
    {
      title: '接收',
      onClick: (onSuccess: any, onFail: any) => {
        batchRequest(fetchConfigBatchOpen, selectedRows, '接收', onSuccess, onFail);
      },
    },
    {
      title: '拒收',
      onClick: (onSuccess: any, onFail: any) => {
        batchRequest(fetchConfigBatchClose, selectedRows, '拒收', onSuccess, onFail);
      },
    },
  ];

  const config = {
    rowKey: (record: TableRowType) => {
      return record?.template?.templateId;
    },
    columns: dataColumns,
    getOperationList: ({ reject, template }: TableRowType) => {
      return [
        {
          title: reject ? '接收' : '拒收',
          onClick: () => {
            const storeApi = reject ? fetchConfigBatchOpen : fetchConfigBatchClose;

            const storeDisplay = reject ? '接收' : '拒收';

            batchRequest(
              storeApi,
              [{ templateId: template?.templateId, moduleId: template?.moduleId }],
              storeDisplay,
              refresh,
            );
          },
        },
      ];
    },
    filterList,
    formatDataToQuery,
    formatDataToFormDisplay,
    batchMenu: toolbar,
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        placeholder="请输入通知标题"
        refreshMarker={refreshMarker}
        configcacheKey="notificationSetting" // 储存列配置
        requestFn={fetchConfigList}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey: any, selectRowKeys?: any[]) => {
          const formatSelectRow = map(selectRowKeys, (node: any) => {
            const { template } = node;

            return {
              templateId: template?.templateId,
              moduleId: template?.moduleId,
            };
          });

          setSelectRows(formatSelectRow);

          setSelectRowKeys(selectKey);
        }}
        {...config}
      />
    </>
  );
}
