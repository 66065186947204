/**
 * @description 多级条件配置表单组件表头
 */

import { Row, Col } from 'antd';

import { ConditionItemInfo } from './constants';
import styles from './styles.module.scss';

interface ConditionListTitleProps {
  isShow: boolean;
  info: ConditionItemInfo;
  disabled?: boolean;
}

const ConditionListTitle: React.FC<ConditionListTitleProps> = ({ isShow, info, disabled }) => {
  const { rowProps, colProps, columns } = info;

  return isShow ? (
    <div className={styles?.titleContent}>
      <div style={{ width: '160px' }} className={styles.titleItem}>
        序号
      </div>
      <Row className={styles.titleRow} wrap={false} {...rowProps}>
        {columns
          .filter(({ hideTitle, hide }) => !hideTitle && !hide)
          .map(({ title, colProps: _colProps }, index) => (
            <Col
              key={`${title}_${index}`}
              className={styles.titleItem}
              {...(_colProps ?? colProps)}
            >
              {title}
            </Col>
          ))}
      </Row>
      {disabled ? null : (
        <div className={styles.titleItem}>
          <div style={{ width: '80px' }}>操作</div>
        </div>
      )}
    </div>
  ) : null;
};

export default ConditionListTitle;
