import { useCallback, useEffect, useState } from 'react';
import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import { getUserDetailById } from 'src/services/organization/userManage';
import { Badge, message } from 'antd';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import _, { isEmpty } from 'lodash';
import { BcAttachmentDetail, showErrorListMessage, BcMultilineText } from 'src/components';
import {
  userStatusList,
  terminalList,
  INUSE_OFF,
  INUSE_ON,
  ACTIVATE_NOTYET,
  LOCK_STATUS_TRUE,
  UserTypes,
} from '../constants';
import { gcArray } from 'src/utils';
// import customFieldDisplayValue from 'src/components/customField/show';
import { avatarDisplay, avatarPictuer } from 'src/components/avatar/show';
import TagTooltip from 'src/components/tooltip/tagTooltip';
import { formatTimeForRender } from 'src/utils/formatters';
import { DetailLayoutMenuItem } from 'src/layout/detail/DetailLayout.type';
import { replaceSign } from 'src/utils/constants';
import { isAdminUser } from '../../share/utils';
import { SingleOperationLogComponent } from 'components';
import { UserActionModal } from './userActionModal';
import ShowBulkConfirmModal from './BulkComfirmModal';
import { USER_STATUS_ENUM } from 'src/dict/user';
import authDict from 'src/utils/auth';
import {
  fetchUserActivateUser,
  fetchUserAwakenUser,
  fetchUserUnlockUser,
} from 'src/api/ytt/user-domain/user';
import { injectCustomFieldInfos } from 'src/components/customField';
import { fetchCustomFieldInstanceGetByObjectCode } from 'src/api/ytt/custom-object-domain';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

const errorColumns = [
  {
    title: '用户编号',
    dataIndex: 'code',
  },
  {
    title: '用户名称',
    dataIndex: 'name',
  },
  {
    title: '失败原因',
    dataIndex: 'desc',
  },
];

const UserDetailContent = (props: { history: any }) => {
  const { history } = props;
  const [dataSource, setDataSource] = useState<any>({});
  const [visibleLog, setVisibleLog] = useState(false);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [noticeVisible, setNoticeVisible] = useState(false);
  const [statusType, setStatusType] = useState<number>(USER_STATUS_ENUM.disable);
  const [customFields, setCustomFields] = useState<any>();

  const getUrlParams = (name: string) => {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i'); // 定义正则表达式
    const r = window.location.search.substr(1).match(reg);

    if (r != null) return unescape(r[2]);
    return null;
  };
  const id = Number(getUrlParams('id'));
  const fetchData = async () => {
    try {
      const { data: detailData } = await getUserDetailById(id);

      setDataSource(detailData);
    } catch (err) {
      console.log(err);
    }
  };

  /** 获取对象自定义字段 */
  const fetchCustomFields = async () => {
    const res = await fetchCustomFieldInstanceGetByObjectCode({
      objectCode: OBJECT_OF_CODE.user,
    });

    setCustomFields((res?.data as any) || {});
  };

  useEffect(() => {
    fetchCustomFields();
    fetchData();
  }, []);

  const baseMenu: DetailLayoutMenuItem[] =
    dataSource?.terminal === UserTypes.SCM_VIRTUAL
      ? []
      : _.compact([
          {
            key: 'reset',
            auth: authDict.user_reset_password,
            title: '重置密码',
            onClick: () => setShowUserModal(true),
          },
          {
            key: 'active',
            title: '激活',
            auth: authDict.user_active,
            // 仅当active为1，对应未激活状态的时候展示
            active: ACTIVATE_NOTYET,
            onClick: async () => {
              await fetchUserActivateUser({ idList: [id] });
              message.success('激活成功');
              fetchData();
            },
          },
          !isAdminUser(dataSource.roleList) && {
            key: 'disable',
            title: '停用',
            auth: authDict.user_enable_disable,
            // 仅当active为1，对应启用状态的时候展示
            active: INUSE_ON,
            onClick: () => {
              setStatusType(USER_STATUS_ENUM.disable);
              setNoticeVisible(true);
            },
          },
          {
            key: 'enble',
            title: '启用',
            auth: authDict.user_enable_disable,
            // 仅当active为0，对应停用状态的时候展示
            active: INUSE_OFF,
            onClick: async () => {
              await fetchUserAwakenUser({ idList: [id], active: USER_STATUS_ENUM.enable });
              message.success('启用成功');
              fetchData();
            },
          },
          {
            key: 'unlock',
            title: '解锁',
            auth: authDict.user_unlock,
            // 仅当lockStatus为1，对应已锁定状态的时候展示
            lockStatus: LOCK_STATUS_TRUE,
            onClick: async () => {
              const res = await fetchUserUnlockUser({ idList: [id] });
              const { code, data } = res;
              const { updateFailureVOList } = data || {};

              if (code !== 200) {
                message.success('解锁失败');
                return;
              }
              if (updateFailureVOList && updateFailureVOList?.length > 0) {
                showErrorListMessage({
                  title: '解锁出现失败',
                  data: updateFailureVOList,
                  columns: errorColumns,
                  operateName: '解锁',
                  successCount: 1,
                  failCount: 1,
                  width: 600,
                });
              } else {
                fetchData();
                message.success('解锁成功');
              }
            },
          },
          !isAdminUser(dataSource.roleList) &&
            dataSource?.terminal !== UserTypes.OPEN_API && {
              key: 'copy',
              auth: authDict.user_add,
              title: '复制',
              onClick: () => {
                history.push(`/organization/userManagement/copyUser?id=${id}&type=copy`);
              },
            },
          {
            key: 'operate',
            auth: authDict.user_operrecord,
            onClick: () => {
              setVisibleLog(true);
            },
            title: '操作记录',
          },
          {
            key: 'edit',
            auth: authDict.user_edit,
            title: '编辑',
            onClick: () => {
              history.push(`/organization/userManagement/editUser?id=${id}`);
            },
          },
        ]).filter((item: DetailLayoutMenuItem) => {
          const filterKeys = ['active', 'lockStatus'];

          for (const key of filterKeys) {
            if (Object.hasOwnProperty.call(item, key) && item?.[key] !== dataSource?.[key]) {
              return false;
            }
          }

          return true;
        });

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '',
        dataIndex: 'avatarUrl',
        render: (avatarUrl: string) => (
          <div style={{ width: '70%' }}>{avatarPictuer(avatarUrl)}</div>
        ),
        isFullLine: true,
      },
      {
        label: '账号类型',
        dataIndex: 'terminal',
        render: (terminal) => {
          return terminalList
            .concat([
              {
                label: 'OpenAPI账号',
                value: UserTypes.OPEN_API,
              },
            ])
            .find((item) => item.value === terminal)?.label;
        },
      },
      { label: '用户编号', dataIndex: 'code' },
      { label: '账号', dataIndex: 'username' },
      {
        label: '用户名称',
        dataIndex: 'name',
        render: (name: string) => name || replaceSign,
      },
      {
        label: '手机号',
        dataIndex: 'phone',
        render: (phone: string) => phone || replaceSign,
      },
      {
        label: '邮箱',
        dataIndex: 'email',
        render: (email: string) => email || replaceSign,
      },
      {
        label: '直属上级',
        dataIndex: 'superior',
        render: (superior: any) => {
          if (!superior) {
            return '-';
          }
          return avatarDisplay({
            ...superior,
            isShowTag: false,
            isUser: true,
          });
        },
      },
      {
        label: '部门',
        dataIndex: 'departmentVOList',
        render: (departments: any[]) => {
          if (gcArray.isEmpty(departments)) {
            return '-';
          }
          return <TagTooltip list={departments} isUser={false} max={3} />;
        },
      },
      {
        label: '角色',
        dataIndex: 'roleList',
        render: (roles: any[]) => {
          if (gcArray.isEmpty(roles)) {
            return '-';
          }
          return TagTooltip({ list: roles, text: '\n更多', max: 3, type: 'role' });
        },
      },
      {
        label: '附件',
        dataIndex: 'attachments',
        render: (files: number[]) => {
          if (isEmpty(files)) return replaceSign;

          return <BcAttachmentDetail fileIds={files} />;
        },
      },
      {
        label: '备注',
        dataIndex: 'desc',
        render: (text: string) => <BcMultilineText text={text} needWordCount />,
      },
    ],
    column: 2,
  };

  const statusInfo: DetailLayoutInfoBlock = {
    title: '状态信息',
    items: [
      {
        label: '账号状态',
        dataIndex: 'active',
        render: (_status: number) => {
          let color: PresetStatusColorType = 'success';

          if (_status === 0) {
            color = 'error';
          }
          if (_status === 2) {
            color = 'processing';
          }
          if (_status === 3) {
            color = 'default';
          }
          const index = _.findIndex(userStatusList, { value: _status });

          return (
            <span>
              <Badge status={color} /> {userStatusList[index]?.displayName || '-'}
            </span>
          );
        },
      },
      {
        label: '锁定状态',
        dataIndex: 'lockStatus',
        render: (status: boolean) => (
          <span>
            <Badge status={status ? 'default' : 'success'} /> {status ? '已锁定' : '未锁定'}
          </span>
        ),
      },
    ],
    column: 2,
  };

  // const customInfo = {
  //   title: '自定义字段',
  //   items: (dataSource.customFieldItemList || []).map((data: any) => {
  //     return {
  //       label: data.fieldName,
  //       dataIndex: data.fieldCode,
  //       render: () => customFieldDisplayValue(data.fieldType, data.displayValue),
  //     };
  //   }),
  //   column: 2,
  // };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: any) => {
          if (!creator) {
            return '-';
          }
          return avatarDisplay({ ...creator, isUser: true, isShowTag: true });
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: (createdAt) => formatTimeForRender(createdAt) || replaceSign,
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: any) => {
          if (!operator) {
            return '-';
          }
          return avatarDisplay({ ...operator, isUser: true, isShowTag: true });
        },
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: (updatedAt) => {
          return formatTimeForRender(updatedAt) || replaceSign;
        },
      },
    ],
    column: 2,
  };

  const getInfo = useCallback(() => {
    const infoArray = [
      baseInfo,
      injectCustomFieldInfos({ customFields, type: 'detail' }),
      statusInfo,
      otherInfo,
    ];

    // if (dataSource.customFieldItemList?.length) {
    //   infoArray.push(customInfo);
    // }
    return infoArray;
  }, [dataSource]);

  return (
    <>
      <DetailLayout
        baseMenu={baseMenu}
        title={'用户详情'}
        info={getInfo()}
        dataSource={dataSource}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={id}
          objectCode={'User'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      {showUserModal && (
        <UserActionModal
          visible={showUserModal}
          onClose={() => setShowUserModal(false)}
          actionType="resetPassword"
          defaultValues={dataSource}
        />
      )}
      {noticeVisible && (
        <ShowBulkConfirmModal
          visible={noticeVisible}
          close={() => setNoticeVisible(false)}
          idList={[dataSource.id]}
          type={statusType}
          refreshList={fetchData}
        />
      )}
    </>
  );
};

export default UserDetailContent;
