import { Tabs } from 'antd';
import { memo, ReactNode, useState } from 'react';

interface TabListItem {
  tab: string;
  key: string;
  component: ReactNode;
}

interface Props {
  tabList: TabListItem[];
}

const TabList = ({ tabList }: Props) => {
  const [activeKey, setActiveKey] = useState<string>();

  const onChange = (activeKey: string) => {
    setActiveKey(activeKey);
  };

  return (
    <>
      <Tabs destroyInactiveTabPane type="card" activeKey={activeKey} onChange={onChange}>
        {tabList?.map(({ component, ...resItem }) => (
          <Tabs.TabPane {...resItem} style={{ height: '100%' }}>
            {component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default memo(TabList);
