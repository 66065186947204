import { useState, useCallback, useEffect } from 'react';
import { Form, Checkbox, Switch, message } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';

import { debounce } from 'lodash';
import {
  fetchPlanOrderConfigGet,
  fetchPlanOrderConfigSave,
  FetchPlanOrderConfigSaveRequest,
} from 'src/api/ytt/plo-domain';

interface SetPlannedOrderProps {
  onCancel: () => void;
}

const SetPlannedOrder = (props: SetPlannedOrderProps) => {
  const [form] = Form.useForm<any>();
  const { onCancel } = props;

  const [switchFinish, setonSwitchFinish] = useState<Boolean>();

  useEffect(() => {
    fetchPlanOrderConfigGet()
      .then((res) => {
        const { data } = res;

        const initData = formatDataToInit(data);

        form.setFieldsValue(initData);
      })
      .catch((err) => {
        console.log('err: ', err);
        // history.goBack();
      })
      .finally(() => {
        // setLoading(false);
      });
  }, []);

  const formatDataToInit = (data: any) => {
    const { closeConfig } = data;

    /** 初始化关闭状态 */
    const _closeConfig = {
      ...closeConfig,
      autoClose: !!closeConfig?.autoClose,
      launched: !!closeConfig?.launched,
      amountLaunchedGtPlaned: !!closeConfig?.amountLaunchedGtPlaned,
    };

    if (closeConfig?.autoClose) {
      setonSwitchFinish(true);
    }

    return {
      closeConfig: { ..._closeConfig },
    };
  };

  // 计划订单自动关闭
  const onSwitchFinish = useCallback((enable: boolean) => {
    if (enable) {
      setonSwitchFinish(true);
      message.success(
        '开启计划订单自动关闭后，系统自动会在每日00:30点关闭所有符合关闭条件的计划订单',
      );
    } else {
      setonSwitchFinish(false);
    }
  }, []);

  const baseInfo: DataFormLayoutInfoBlock = {
    title: '',
    column: 2,
    items: [
      {
        label: '计划订单自动关闭',
        validateFirst: true,
        labelCol: { span: 5 },
        render: () => (
          <Form.Item
            name={['closeConfig', 'autoClose']}
            fieldKey={['closeConfig', 'autoClose']}
            style={{ marginBottom: '0' }}
            valuePropName="checked"
          >
            <Switch onChange={onSwitchFinish} />
          </Form.Item>
        ),
      },
      {
        validateFirst: true,
        hidden: !switchFinish,
        label: '设置自动关闭条件',
        labelCol: { span: 5 },
        render: () => (
          <Form.Item
            name={['closeConfig', 'launched']}
            fieldKey={['closeConfig', 'launched']}
            style={{ marginBottom: '0' }}
            valuePropName="checked"
            initialValue={{ checked: true }}
          >
            <Checkbox disabled>计划订单状态为“已投放”</Checkbox>
          </Form.Item>
        ),
      },
      {
        label: ' ',
        colon: false,
        validateFirst: true,
        hidden: !switchFinish,
        labelCol: { span: 5 },
        render: () => (
          <Form.Item
            name={['closeConfig', 'amountLaunchedGtPlaned']}
            fieldKey={['closeConfig', 'amountLaunchedGtPlaned']}
            style={{ marginBottom: '0' }}
            valuePropName="checked"
            initialValue={{ checked: true }}
          >
            <Checkbox disabled>计划订单主产出为“已投放数”大于等于“生产数”</Checkbox>
          </Form.Item>
        ),
      },
    ],
  };

  const handleFinish = debounce(async () => {
    try {
      const formValue = await form?.validateFields();

      const { closeConfig } = formValue;
      const submitVal: FetchPlanOrderConfigSaveRequest = {
        ...formValue,
        closeConfig: {
          ...closeConfig,
          launched: Number(closeConfig.launched),
          autoClose: Number(closeConfig.autoClose),
          amountLaunchedGtPlaned: Number(closeConfig.amountLaunchedGtPlaned),
        },
      };

      await fetchPlanOrderConfigSave(submitVal).then(() => {
        message.success('设置成功');
        onCancel();
      });
    } catch (error) {
      console.log('error: ', error);
    }
  }, 1500);

  return (
    <DataFormLayout
      form={form}
      info={[baseInfo]}
      title={'设置'}
      onCancel={() => {
        onCancel();
      }}
      onFinish={() => {
        handleFinish();
      }}
    />
  );
};

export default SetPlannedOrder;
