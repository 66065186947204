import _ from 'lodash';

/** 单选select与array表单项格式的适配 */
export const arrayItemProps = {
  getValueProps: (val: any) => {
    if (val === undefined) {
      return val;
    }
    if (_.isEmpty(val)) {
      return { value: undefined };
    }
    return { value: val[0] };
  },
  getValueFromEvent: (val: any) => {
    if (val === undefined) {
      return val;
    }
    return [val];
  },
};
