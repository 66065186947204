/**
 * @description 公式编辑器组件
 */

import { ReactNode, useState } from 'react';
import { Button, Modal, Input, Popconfirm, message } from 'antd';
import { BlIcon } from '..';

import {
  fetchFormulaCreate,
  fetchFormulaDetail,
} from 'src/api/ytt/formula-domain/calculationFormula';
import _ from 'lodash';
interface FormulaEditorModalProps {
  /** 默认内容为公式id */
  value?: any;
  onChange?: any;
  objectCode: string;
  /** 是否将text也包装进value, 返回 { id: number, text: string } */
  textInValue?: boolean;
  /** 只展示公式文本 */
  onlyShowText?: boolean;
  // disabled?: boolean;
}

const FormulaEditorModal: React.FC<FormulaEditorModalProps> = ({
  value,
  onChange,
  objectCode,
  textInValue = false,
  // 详情页展示需要
  onlyShowText = false,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  // 文本输入框绑定的值
  const [formulaText, setFormulaText] = useState<string>('');
  // 文本框初始值
  const [initalText, setInitalText] = useState<string>('');

  const showModal = () => {
    setIsModalVisible(true);
    if (textInValue && value?.formulaText) {
      setFormulaText(value?.formulaText);
      setInitalText(value?.formulaText);
      return;
    }
    if (_.isNaN(Number(value)) && _.isNaN(Number(value?.formulaId))) return;
    fetchFormulaDetail({ id: textInValue ? Number(value?.formulaId) : Number(value) }).then(
      (res) => {
        if (res.code === 200) {
          setFormulaText(res.data?.text ?? '');
          setInitalText(res.data?.text ?? '');
        }
      },
    );
  };

  const handleOk = () => {
    setLoading(true);
    fetchFormulaCreate({ objectCode, formulaText: formulaText.replaceAll(/[\n\s\r]+/g, '') })
      .then((res) => {
        if (res.code === 200) {
          onChange?.(
            textInValue ? { formulaId: res.data?.formulaId, formulaText } : res.data?.formulaId,
          );
          message.success('保存成功');
          setIsModalVisible(false);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFormulaText('');
  };

  /** 公式被编辑后，展示Popconfirm */
  const withPopconfirm = (text: ReactNode) => {
    const title = '你修改的内容还未保存，确定取消？';
    if (_.isEqual(initalText, formulaText)) {
      return <div onClick={() => handleCancel()}>{text}</div>;
    }
    return (
      <Popconfirm title={title} onConfirm={() => handleCancel()}>
        {text}
      </Popconfirm>
    );
  };

  if (onlyShowText) {
    return <span>{textInValue ? value?.formulaText : value}</span>;
  }

  return (
    <>
      <Button onClick={showModal} type="dashed">
        <BlIcon type="iconxinjiantianjia" /> 编辑函数
      </Button>
      <Modal
        title="公式编辑器"
        visible={isModalVisible}
        onOk={handleOk}
        maskClosable={false}
        cancelText={withPopconfirm('取消')}
        closeIcon={withPopconfirm(<BlIcon type="iconguanbi" />)}
        okButtonProps={{ loading, disabled: _.isEmpty(formulaText) }}
      >
        <Input.TextArea
          maxLength={1000}
          showCount
          autoSize
          placeholder="公式显示编辑区域"
          onChange={(e) => setFormulaText(e.target.value)}
          value={formulaText}
        />
      </Modal>
    </>
  );
};

export default FormulaEditorModal;
