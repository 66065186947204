/**
 * 中间品
 */
import React from 'react';
import { Form, FormInstance, Input } from 'antd';

interface IntermediateMaterialFormProps {
  form: FormInstance;
  name: string;
}

const IntermediateMaterialForm = (props: IntermediateMaterialFormProps) => {
  const { form, name } = props;

  return (
    <div>
      <Form form={form} name={name}>
        <Form.Item label="中间品" name={[name, 'intermediateMaterial']}>
          <Input.TextArea maxLength={1000} showCount />
        </Form.Item>
      </Form>
    </div>
  );
};

export default IntermediateMaterialForm;
