import _ from 'lodash';
import NodeContainer from '../share/nodeContainer';
import LineContainer from '../share/lineContainer';
import ProcessDetail from './processDetail';
import ProcessLinkDetail from './processLinkDetail';
import { clickTypeEnum } from '../../constant';
import { useEffect, useState } from 'react';

interface Props {
  processes: any;
  processConnections: any;
  isWorkOrder?: Boolean;
  useType?: number; // 确定该组件用于哪个模块下，0-bom(不传即可)、1-生产工单(暂不用这个确定，暂用isWorkOrder)、2-计划订单(传 2)
}

const ProcessRoutingForm = (props: Props) => {
  const { processes, processConnections, isWorkOrder, useType } = props;
  const [clickType, setClickType] = useState<any>(null);
  const [current, setCurrent] = useState<number>(0);

  const RenderProcess = () => {
    if (clickType === null) {
      return null;
    }
    return clickType === clickTypeEnum.node ? (
      <ProcessDetail isWorkOrder={isWorkOrder} dataSource={processes[current]} />
    ) : (
      <ProcessLinkDetail
        isWorkOrder={isWorkOrder}
        useType={useType}
        dataSource={processConnections[current]}
      />
    );
  };

  useEffect(() => {
    if (processes && processes.length) {
      setCurrent(0);
      setClickType(clickTypeEnum.node);
    }
  }, [processes]);

  return (
    <div style={{ border: '1px solid #e8e8e8' }}>
      <div style={{ overflowX: 'scroll' }}>
        <div
          style={{
            borderBottom: '1px solid #e8e8e8',
            display: 'flex',
            padding: 36,
            flexWrap: 'nowrap',
          }}
        >
          {processes?.map((item: any, key: number) => {
            return (
              <div key={key} style={{ minWidth: 187, display: 'inline-block' }}>
                <NodeContainer
                  index={key}
                  selecting={current === key && clickType === clickTypeEnum.node}
                  nodeInfo={item}
                  onClick={(data: any) => {
                    setClickType(clickTypeEnum.node);
                    setCurrent(key);
                  }}
                />
                {processConnections[key] ? (
                  <LineContainer
                    selecting={current === key && clickType === clickTypeEnum.edge}
                    edgeInfo={processConnections[key]}
                    index={key}
                    clickFunction={(data: any) => {
                      setClickType(clickTypeEnum.edge);
                      setCurrent(key);
                    }}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
      {RenderProcess()}
    </div>
  );
};

export default ProcessRoutingForm;
