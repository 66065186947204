import React, { useEffect, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from '../../../../../layout';
import { Form, Checkbox, Input, Radio, message, Row, InputNumber } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  fetchUnitCreate,
  fetchUnitUpdate,
  fetchUnitDetail,
} from 'src/api/ytt/material-domain/web-unit';
import { validateBlInputNumberIntervalInt } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import { inputCompareNumber } from 'src/page/custom_fields/fieldsList/components/verificationRules/rules';
import { CRUD, ObjectCode } from 'src/dict/common';
import { roundingRule } from 'src/dict/material/mappings';
import { NumberRulesStandardHook } from 'src/components';
import { textValidator2, checkTwoSidesTrim } from 'src/utils/formValidators';
import { appEnum } from 'src/dict';
import { getDefaultPrecisionFigure } from '../utils';

interface Props {
  type: string;
  refreshTable?: () => void;
  refreshDetails: () => void;
  onCancel?: () => void;
  id?: number | undefined;
}

const UnitsBaseInfo = (props: Props) => {
  const { type, onCancel, refreshTable, refreshDetails, id } = props;
  const [form] = Form.useForm();
  const [keepOn, sekeepOn] = useState(type === CRUD.create);
  const [defaultFigure, setDefaultFigure] = useState<number>();
  const [refreshMarker, setRefreshMaker] = useState<number>(0);
  const baseInfo: DataFormLayoutInfoBlock = {
    items: [
      ...NumberRulesStandardHook({
        label: '单位编号',
        form,
        edit: type === CRUD.edit,
        objectCode: ObjectCode.materialUnit,
        fieldCode: 'code',
        rules: [
          {
            max: 255,
            message: '不可超过255个字符',
          },
          { validator: textValidator2 },
          { validator: checkTwoSidesTrim },
        ],
        refreshMarker,
      }),
      {
        label: '单位名称',
        name: 'name',
        rules: [
          { required: true, message: '单位名称必填' },
          { max: 255, message: '不超过255字符' },
          { validator: checkTwoSidesTrim },
        ],
        validateFirst: true,
        render: () => <Input placeholder="请输入单位名称" />,
      },
      {
        label: '舍入规则',
        name: 'roundingRole',
        initialValue: 0,
        rules: [{ required: true, message: '舍入规则必选' }],
        validateFirst: true,
        render: () => (
          <Radio.Group>
            {roundingRule.map((item) => {
              return <Radio value={item.value}>{item.label}</Radio>;
            })}
          </Radio.Group>
        ),
      },
      {
        label: '精度管理',
        required: true,
        shouldUpdate: (prevValues: any, curValues: any) => {
          return prevValues.enablePrecision !== curValues.enablePrecision;
        },
        render: () => {
          return () => {
            return (
              <Row>
                <Form.Item
                  name="enablePrecision"
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                  hidden
                >
                  <Checkbox>启用精度管理</Checkbox>
                </Form.Item>
                {Boolean(form.getFieldValue('enablePrecision')) && (
                  <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Item
                      name="precisionFigure"
                      style={{ marginBottom: 0 }}
                      rules={[
                        { validator: validateBlInputNumberIntervalInt(0, 10) },
                        {
                          validator: inputCompareNumber(defaultFigure),
                        },
                      ]}
                      validateFirst
                    >
                      <InputNumber
                        defaultValue={defaultFigure}
                        value={defaultFigure}
                        style={{ width: 200 }}
                        placeholder="只可输入0-10之间的整数"
                        min={0}
                      />
                    </Form.Item>
                    <span
                      style={{
                        marginLeft: '8px',
                      }}
                    >
                      位
                    </span>
                  </Row>
                )}
              </Row>
            );
          };
        },
      },
      {
        label: '单位备注',
        name: 'remark',
        rules: [{ max: 1000, message: '不可超过1000字符' }],
        render: () => <TextArea placeholder="请输入单位备注" />,
      },
      {
        label: '编辑原因',
        name: 'updateReason',
        hidden: type === CRUD.create,
        rules: [{ max: 1000, message: '不可超过1000字符' }],
        render: () => <TextArea placeholder="请输入编辑原因" />,
      },
    ],
    column: 1,
  };

  const formProps = {
    initialValues: {
      enablePrecision: true,
      precisionFigure: 6,
    },
  };
  const initCreate = () => {};
  const initEdit = async (unitId: number | undefined) => {
    try {
      if (!unitId) return;
      const res: any = await fetchUnitDetail({ id: unitId });

      form.setFieldsValue({
        ...(res?.data ?? {}),
        precisionFigure: getDefaultPrecisionFigure(res.data.precisionFigure),
        enablePrecision: appEnum.Common.UsageStatus.enabled, // 取消精度管理启停用状态，默认全部启用
      });
      setDefaultFigure(getDefaultPrecisionFigure(res.data.precisionFigure));
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = async () => {
    try {
      const value = await form?.validateFields();

      switch (type) {
        case CRUD.create: {
          value.enablePrecision = Number(value.enablePrecision);
          if (value?.precisionFigure) {
            value.precisionFigure = Number(value.precisionFigure);
          }
          delete value.updateReason;

          await fetchUnitCreate(value);
          message.success('创建成功');
          break;
        }

        case CRUD.edit:
          value.enablePrecision = Number(value.enablePrecision);
          await fetchUnitUpdate({ ...value, id });
          message.success('编辑成功');
          break;

        default:
          break;
      }
      if (keepOn) {
        typeof refreshTable === 'function' && refreshTable();
        form.resetFields();
        setRefreshMaker(Math.random());
        return;
      }
      onCancel && onCancel();
      typeof refreshTable === 'function' && refreshTable();
      refreshDetails();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    switch (type) {
      case CRUD.create:
        initCreate();
        break;

      case CRUD.edit:
        initEdit(id);
        break;

      default:
        initCreate();
        break;
    }
  }, []);

  return (
    <DataFormLayout
      form={form}
      formProps={formProps}
      info={[baseInfo]}
      title={type === CRUD.create ? '新建单位' : '编辑单位'}
      extra={
        type === CRUD.create && (
          <Checkbox
            onChange={() => {
              sekeepOn(!keepOn);
            }}
            defaultChecked={keepOn}
          >
            连续新建
          </Checkbox>
        )
      }
      onCancel={onCancel}
      onFinish={handleFinish}
    />
  );
};

export default UnitsBaseInfo;
