import { Select, Input, Space, Form } from 'antd';
import _ from 'lodash';

import { lookup } from 'src/dict';
import { UseAgeUnit } from 'src/dict/resources';
import { useAgeUnit } from 'src/dict/resources/mappings';
import { numberMinMaxCheck } from 'src/utils/formValidators';
import { ResourceListItemType } from '../../index.d';
import Styles from './index.module.scss';

interface UseAgeFilterProps {
  defaultUnit?: UseAgeUnit;
}

export const useAgeRender = (
  useAge: ResourceListItemType['useAge'],
  { useAgeType }: ResourceListItemType,
) => {
  return !_.isNil(useAge) && !_.isNil(useAgeType) ? (
    <span>
      {useAge}
      {lookup('resources.useAgeUnit', useAgeType)}
    </span>
  ) : undefined;
};

export default function UseAgeFilter({ defaultUnit }: UseAgeFilterProps) {
  return (
    <Space className={Styles.useAgeFilterContainer}>
      <Form.Item
        name="useAge"
        className={Styles.useAgeInput}
        getValueFromEvent={(event) => {
          const curValue = event.target.value;

          if (isNaN(curValue) || _.endsWith(curValue, '.')) return curValue;
          if (curValue === '') return undefined;
          return Number(curValue);
        }}
        rules={[
          {
            validator: numberMinMaxCheck({
              min: 1,
              max: 99,
              isInteger: true,
              fieldName: '年限',
            }),
          },
        ]}
      >
        <Input allowClear placeholder="请输入时长" />
      </Form.Item>
      <Form.Item
        name="useAgeType"
        className={Styles.useAgeTypeSelect}
        initialValue={defaultUnit}
        getValueProps={(unitValue) => {
          return { value: unitValue ?? defaultUnit };
        }}
      >
        <Select placeholder="请选择单位" options={useAgeUnit} />
      </Form.Item>
    </Space>
  );
}
