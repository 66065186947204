import React, { useEffect, useState } from 'react';
import { Form, Space, message as Message, Modal } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import {
  BcMultilineText,
  BlIcon,
  ErrorNotification,
  SingleOperationLogComponent,
} from 'components';
import { replaceSign } from 'src/utils/constants';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import ColorBox from './colorBox';
import { DetailSource } from '../interface';
import BaseTable from './detailBaseTable';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import {
  formatDeleteFailsByCalendar,
  formatDeleteFailsBySchedulingRules,
  formatTime,
  formatUseNames,
} from '../utils';
import _Time from 'src/utils/dataTypes/time';
import _String from 'src/utils/dataTypes/string';
import { toShiftCreate, toShiftEdit, toShiftList } from '../navigation';
import { fetchShiftCheck, fetchShiftDelete, fetchShiftGet } from 'src/api/ytt/calendar-domain';
import { useHistory } from 'react-router';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
interface DetailBaseInfoProps {
  shiftId: number;
  type: string;
}

const DetailInfo = (props: DetailBaseInfoProps) => {
  const { shiftId, type } = props;
  const history = useHistory();

  const [checkVisible, setCheckVisible] = useState(false); // 显示确定编辑弹框
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录

  const [dataSource, setDetail] = useState({} as any);

  const fetchData = async () => {
    try {
      const { data } = await fetchShiftGet({ id: shiftId });

      if (data) {
        setDetail(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const [delForm] = Form.useForm();

  // const handleFinish = async () => {
  //   try {
  //     // const value = await delForm?.validateFields();
  //     // TODO：后面补充删除的原因
  //     const delId = [shiftId];

  //     const { message, data } = await fetchShiftDelete({ ids: delId });

  //     if (data) {
  //       if (data?.failedCount === 0) {
  //         Message.success('删除成功');
  //         history.push(toShiftList());
  //         return;
  //       }
  //       const useCalendarNames = formatDeleteFailsByCalendar(
  //         data?.shiftDeleteDetailVOS?.[0].usingShiftObjs,
  //       );
  //       const useSchedulingRulesNames = formatDeleteFailsBySchedulingRules(
  //         data?.shiftDeleteDetailVOS?.[0].usingShiftObjs,
  //       );

  //       ErrorNotification({
  //         message: '删除失败',
  //         description: (
  //           <>
  //             {!_String.isEmpty(useCalendarNames) ? (
  //               <div>
  //                 有工作日历：<span>{useCalendarNames}</span>
  //                 ，正在使用该班次
  //               </div>
  //             ) : null}
  //             {!_String.isEmpty(useSchedulingRulesNames) ? (
  //               <div>
  //                 有排班规则：<span>{useSchedulingRulesNames}</span>
  //                 ，正在使用该班次
  //               </div>
  //             ) : null}
  //           </>
  //         ),
  //       });

  //       return;
  //     }
  //     Message.error(message);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const baseMenu = [
    // {
    //   key: 'delete',
    //   onClick: () => {
    //     BLDelConfirm('删除后将无法恢复，确认删除？', delForm, handleFinish);
    //   },
    //   icon: <BlIcon type="iconshanchulajitong1" />,
    //   title: '删除',
    // },
    {
      key: 'copy',
      icon: <BlIcon type="iconfuzhi" />,
      onClick: () => {
        history.push({
          pathname: toShiftCreate(),
          state: shiftId,
        });
      },
      title: '复制',
    },
    {
      key: 'record',
      auth: authDict.shift_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'edit',
      auth: authDict.shift_edit,
      onClick: async () => {
        const { data } = await fetchShiftCheck({ id: shiftId });

        if (data?.inUse) {
          // 被使用中，弹框提醒
          const shiftName = formatUseNames(data?.shiftRuleUpdateVOS);

          const calendarName = formatUseNames(data?.workerCalendarVOS);

          Modal.confirm({
            title: '确定要编辑这条班次吗?',
            content: (
              <div>
                有
                {_String.isEmpty(calendarName) ? (
                  ''
                ) : (
                  <span>
                    <span style={{ color: '#02B980' }}>{calendarName}</span>等日历
                  </span>
                )}
                {_String.isEmpty(shiftName) ? (
                  ''
                ) : (
                  <span>
                    <span style={{ color: '#02B980' }}>{shiftName}</span>等排班规则
                  </span>
                )}
                正在使用该班次，班次修改后，将影响到这些工作日历及班次，你确定要修改吗？
              </div>
            ),
            visible: checkVisible,
            onOk() {
              history.push(toShiftEdit(shiftId));
            },
            onCancel() {
              setCheckVisible(false);
            },
            okText: '确定',
            cancelText: '取消',
          });
        } else {
          history.push(toShiftEdit(shiftId));
        }
      },
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ];

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      { label: '班次名称', dataIndex: 'name' },
      {
        label: '班次颜色',
        dataIndex: 'color',
        render: (color: string) => <ColorBox color={color} /> ?? '-',
      },
    ],
    column: 2,
  };

  const timeInfo: DetailLayoutInfoBlock = {
    title: '时段信息',
    items: [
      {
        label: '',
        dataIndex: 'effectiveDuration',
        isFullLine: true,
        render: () => {
          return (
            <div
              style={{
                display: 'flex',
                width: '100%',
                paddingLeft: 24,
                paddingRight: 24,
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  height: 70,
                  backgroundColor: '#FAFAFA',
                  padding: 14,
                  flex: 1,
                }}
              >
                <div>有效工时</div>
                <div style={{ fontWeight: 700 }}>
                  {formatTime(dataSource?.effectiveDuration) ?? '00 小时 00分钟'}
                </div>
              </div>
              <div
                style={{
                  height: 70,
                  backgroundColor: '#FAFAFA',
                  padding: 14,
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <div>总工时</div>
                <div style={{ fontWeight: 700 }}>
                  {formatTime(dataSource.sumDuration) ?? '00 小时 00分钟'}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        label: '',
        dataIndex: 'shiftInfoDetailTable',
        isFullLine: true,
        render: () => {
          return (
            <div style={{ padding: 24 }}>
              <BaseTable dataSource={dataSource?.shiftInfoDetailVOS} />
            </div>
          );
        },
      },
      {
        label: '备注',
        dataIndex: 'remark',
        isFullLine: true,
        render: (text: string) => <BcMultilineText text={text} />,
      },
    ],
    column: 2,
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: any) => {
          return (
            <Space>
              <AvatarDisplay
                id={creator?.id}
                name={creator?.name}
                avatarUrl={creator?.avatarUrl}
                key={creator?.id}
                isShowTag={false}
                isUser
              />
            </Space>
          );
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        isFullLine: true,
        render: (createdAt: Date) => _Time.format(createdAt) ?? replaceSign,
      },

      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: any) => {
          return (
            <Space>
              <AvatarDisplay
                id={operator?.id}
                name={operator?.name}
                avatarUrl={operator?.avatarUrl}
                key={operator?.id}
                isShowTag={false}
                isUser
              />
            </Space>
          );
        },
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        isFullLine: true,
        render: (updatedAt: Date, record: any) =>
          _Time.format(updatedAt) ?? _Time.format(record?.createdAt) ?? replaceSign,
      },
    ],
    column: 2,
  };

  return (
    <>
      <DetailLayout
        userAuth={getAuthFromLocalStorage()}
        title="班次详情"
        baseMenu={baseMenu}
        info={[baseInfo, timeInfo, otherInfo]}
        dataSource={dataSource}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={shiftId}
          objectCode={'Shift'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailInfo;
