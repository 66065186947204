import React from 'react';
//
import { TabList } from 'src/components';
//
import DeliveryOrderList from './deliveryOrderList';
import DeliveryMaterialList from './deliveryMaterialList';

enum TabKey {
  DeliveryOrder = '1',
  DeliveryMaterial = '2',
}

const List = (props: { history: any }) => {
  const { history } = props;

  const tabList = [
    {
      tab: '收货单视图',
      key: TabKey.DeliveryOrder,
      component: <DeliveryOrderList history={history} />,
    },
    {
      tab: '物料行视图',
      key: TabKey.DeliveryMaterial,
      component: <DeliveryMaterialList history={history} />,
    },
  ];

  return <TabList tabList={tabList} defaultKey={TabKey.DeliveryOrder} />;
};

export default List;
