/**
 * 角色详情抽屉栏
 * 【权限配置】【编辑】【复制】【操作记录】【删除】Button
 */
import RoleDisplay from './roleDisplay';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import authDict from 'src/utils/auth';
import { match, RouteComponentProps } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { DataFormLayoutForModal } from 'src/layout/dataForm';
import RoleActions from './useRoleAction';

interface RoleDetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const RoleDetailPage = (props: RoleDetailProps) => {
  const { match } = props;
  const roleId = Number(match?.params?.id);

  const copyRole = () => {
    setVisible(true);
    setIsCopy(true);
  };

  const [visible, setVisible] = useState<boolean>(false); // 编辑，创建
  const [visibleRole, setVisibleRole] = useState<boolean>(false); // 权限配置
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const [visibleLog, setVisibleLog] = useState(false);
  const [refreshMarker, setRefreshMaker] = useState<number>(0);

  const renderRoleActions = useMemo(() => {
    return visible ? (
      <RoleActions
        roleId={roleId}
        isCopy={isCopy}
        hideCallback={() => {
          setVisible(false);
          setIsCopy(false);
          setRefreshMaker(Math.random());
        }}
      />
    ) : null;
  }, [visible, roleId]);

  const baseMenu = [
    {
      key: 'auth',
      auth: authDict.role_config,
      onClick: () => {
        setVisibleRole(true);
      },
      title: '权限配置',
    },
    {
      key: 'look',
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'copy',
      auth: authDict.role_add,
      onClick: () => {
        copyRole();
      },
      title: '复制',
    },
    {
      key: 'edit',
      auth: authDict.role_edit,
      onClick: () => {
        setVisible(true);
      },
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ];

  return (
    <>
      <RoleDisplay
        showOtherInfo
        title="角色详情"
        disabled
        baseMenu={baseMenu}
        id={roleId}
        refreshMarker={refreshMarker}
        onRefresh={() => setRefreshMaker(Math.random())}
      />
      {renderRoleActions}
      {visibleRole && (
        <DataFormLayoutForModal
          visible={visibleRole}
          onClose={() => setVisibleRole(false)}
          width={1000}
          content={<RoleDisplay title="权限配置" id={roleId} />}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={roleId}
          objectCode={'Role'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default RoleDetailPage;
