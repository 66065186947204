import { BlTable } from 'components';
import React from 'react';
import NumberEditPopover from './NumberEditPopover';
import { numberMinMaxCheck } from 'src/utils/formValidators';
import { fetchProduceTaskUpdateOutput } from 'ytt/mfg-domain/productionTask';
import { message } from 'antd';

interface Props {
  taskReportableMaterialList: any;
  refreshData: () => void;
  taskId: number;
  hasEditPermission: boolean;
}

const MaterialTable = ({
  taskReportableMaterialList,
  refreshData,
  taskId,
  hasEditPermission,
}: Props) => {
  return (
    <BlTable
      scroll={{ x: 'max-content', y: 'auto' }}
      dataSource={taskReportableMaterialList}
      columns={[
        {
          title: '物料编号',
          dataIndex: 'code',
          width: 100,
          render: (code) => {
            return code;
          },
        },
        {
          title: '物料名称',
          dataIndex: 'name',
          width: 100,
          render: (name) => {
            return name;
          },
        },
        {
          title: '任务计划数',
          dataIndex: 'plannedAmount',
          width: 100,
          render: (plannedAmount, record) => {
            const { unit } = plannedAmount;

            return (
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                {`${plannedAmount?.amount}${unit?.name}`}
                {hasEditPermission && record?.editable ? (
                  <NumberEditPopover
                    title="修改任务计划数:"
                    initialNumber={plannedAmount?.amount}
                    submitUpdate={async (number) => {
                      try {
                        await fetchProduceTaskUpdateOutput(
                          {
                            taskId,
                            plannedAmount: number,
                            progressReportKey: record.progressReportKey,
                          },
                          { showErrorMessage: false },
                        );
                        refreshData();
                        return 'success';
                      } catch (e: any) {
                        if (e?.data?.needCheck === 1) {
                          return {
                            number,
                            message: e?.data?.message,
                          };
                        }
                        message.error(e?.data?.message);
                        return 'fail';
                      }
                    }}
                    submitUpdateSkipRule={async (number) => {
                      try {
                        await fetchProduceTaskUpdateOutput({
                          taskId,
                          plannedAmount: number,
                          progressReportKey: record.progressReportKey,
                          // @ts-ignore
                          skipCheck: true,
                        });
                        refreshData();
                        // eslint-disable-next-line no-empty
                      } catch (e) {}
                    }}
                    rules={[
                      { required: true, message: '任务计划数不能为空' },
                      {
                        validator: numberMinMaxCheck({
                          min: record.alreadyReportedAmount.amount,
                          max: 10000000,
                          fieldName: '任务计划数',
                        }),
                      },
                    ]}
                  />
                ) : (
                  ''
                )}
              </div>
            );
          },
        },
        {
          title: '任务报工数',
          dataIndex: 'alreadyReportedAmount',
          width: 100,
          render: (alreadyReportedAmount) => {
            if (!alreadyReportedAmount) {
              return 0;
            }
            const { unit } = alreadyReportedAmount;

            return `${alreadyReportedAmount?.amount}${unit?.name}`;
          },
        },
        {
          title: '合格报工数',
          dataIndex: 'alreadyQualifiedReportedAmount',
          width: 100,
          render: (alreadyQualifiedReportedAmount) => {
            if (!alreadyQualifiedReportedAmount) {
              return 0;
            }
            const { unit } = alreadyQualifiedReportedAmount;

            return `${alreadyQualifiedReportedAmount?.amount}${unit?.name}`;
          },
        },
        {
          title: '不合格报工数',
          dataIndex: 'alreadyUnqualifiedReportedAmount',
          width: 100,
          render: (alreadyUnqualifiedReportedAmount) => {
            if (!alreadyUnqualifiedReportedAmount) {
              return 0;
            }
            const { unit } = alreadyUnqualifiedReportedAmount;

            return `${alreadyUnqualifiedReportedAmount?.amount}${unit?.name}`;
          },
        },
        {
          title: '任务入库数',
          dataIndex: 'inventoryAmount',
          width: 100,
          render: (inventoryAmount) => {
            if (!inventoryAmount) {
              return 0;
            }
            const { unit } = inventoryAmount;

            return `${inventoryAmount?.amount}${unit?.name}`;
          },
        },
      ]}
      rowKey="id"
    />
  );
};

export default MaterialTable;
