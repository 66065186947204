import { useEffect, useState } from 'react';
import type { RouteChildrenProps } from 'react-router';
import { Form, Checkbox, Input, message, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { map, omit } from 'lodash';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';
import { appEnum } from 'src/dict';
import { BcAttachmentForm, SearchSelect } from 'src/components';
import { validatorCheckTwoSidesTrim } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import {
  fetchWorkCenterDetail,
  fetchWorkCenterUpdate,
  fetchWorkCenterCreate,
} from 'src/api/ytt/work-center-domain/index';
import { ACTION_CREATE, ACTION_EDIT, ACTION_COPY, workCenterStatusMap } from '../constants';
import { DEFAULTDATA } from '../shared/attrFormTable';
import { STORAGE_TYPE_TYPE_ENUM } from 'src/page/knowledgeManagement/warehouse/constant';
import ResourceGroup from '../resourceGroup';
import { wcgCOlistToApi, wcgCOlistToForm } from '../resourceGroup/util';
import { textValidator1 } from 'src/utils/formValidators';

interface Props extends RouteChildrenProps<{ id: string }> {}

const WorkCenterBaseInfo = (props: Props) => {
  const { match, history } = props;
  const [form] = Form.useForm();
  const [type, setType] = useState(appEnum.Common.CRUD.create);
  const [keepOn, setKeepOn] = useState(type === ACTION_CREATE);
  const [id] = useState(match?.params?.id);
  const [receiveWarehouse, setReceiveWarehouse] = useState();

  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    column: 2,
    items: [
      {
        label: '工作中心编号',
        name: 'code',
        rules: [
          { required: true, message: '工作中心编号必填' },
          { max: 255, message: '不超过255字符' },
          { validator: validatorCheckTwoSidesTrim() },
        ],
        validateFirst: true,
        render: () => <Input placeholder="请输入工作中心编号" />,
      },
      {
        label: '工作中心名称',
        name: 'name',
        rules: [
          { required: true, message: '工作中心名称必填' },
          { max: 255, message: '不超过255字符' },
          { validator: validatorCheckTwoSidesTrim() },
        ],
        validateFirst: true,
        render: () => <Input placeholder="请输入工作中心名称" />,
      },
      {
        label: '所属部门',
        name: 'departmentIdList',
        validateFirst: true,
        render: () => (
          <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple={false} />
        ),
      },
      {
        label: '默认收料仓库',
        name: 'defaultReceiveWarehouseId',
        validateFirst: true,
        render: () => (
          <SearchSelect
            fetchType={'warehouse'}
            labelInValue
            allowClear
            onChange={(val) => {
              setReceiveWarehouse(val?.key);
              form.setFields([
                {
                  name: ['inputStorageList'],
                  value: undefined,
                },
              ]);
            }}
          />
        ),
      },

      {
        label: '投料仓位',
        validateFirst: true,
        dependencies: ['defaultReceiveWarehouseId'],
        render: () => () => {
          const defaultReceiveWarehouseId = receiveWarehouse;

          return (
            <Form.Item style={{ marginBottom: 0 }} name="inputStorageList">
              {defaultReceiveWarehouseId ? (
                <SearchSelect
                  fetchType={'storageLocation'}
                  labelInValue
                  mode="multiple"
                  params={{
                    storageId: defaultReceiveWarehouseId,
                    storageType: STORAGE_TYPE_TYPE_ENUM.storageType,
                  }}
                  allowClear
                />
              ) : (
                <Select labelInValue />
              )}
            </Form.Item>
          );
        },
      },
      {
        label: '产出仓位',
        name: 'outputStorageList',
        validateFirst: true,
        render: () => (
          <SearchSelect fetchType={'storageLocation'} labelInValue mode="multiple" allowClear />
        ),
      },
      // 一期不做
      // {
      //   label: '日历标识',
      //   name: 'calendar',
      //   validateFirst: true,
      //   render: () => (
      //     <Radio.Group>
      //       <Radio value={0}>是</Radio>
      //       <Radio value={1}>否</Radio>
      //     </Radio.Group>
      //   ),
      // },
      {
        label: '文件',
        name: 'fileIds',
        render: () => <BcAttachmentForm form={form} />,
      },
      {
        label: '备注',
        name: 'remark',
        // hidden: type === ACTION_CREATE,
        rules: [{ max: 1000, message: '不可超过1000字符' }],
        render: () => <TextArea placeholder="请输入编辑原因" />,
      },
      {
        label: '编辑原因',
        name: 'operateReason',
        hidden: !location.pathname.includes('edit'),
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
    ],
  };

  const workCenterGroupCOList: DataFormLayoutInfoBlock = {
    title: '资源清单',
    column: 2,
    items: [
      {
        isFullLine: true,
        label: '',
        render: () => (
          // <AttrFormTable initialData={false} form={form} filedName={['workCenterGroupCOList']} />
          <ResourceGroup form={form} filedName={['workCenterGroupCOList']} />
        ),
      },
    ],
  };

  const initCreate = () => {
    form.setFieldsValue({
      code_isUseRules: true,
      workCenterGroupCOList: DEFAULTDATA,
    });
  };

  const initEdit = async (attrId?: number) => {
    try {
      if (!attrId) return;
      const res: any = await fetchWorkCenterDetail({ id: attrId });

      const {
        departmentListResponseDTOList: _departmentIdList,
        inputStorageList: _inputStorageList,
        outputStorageList: _outputStorageList,
        defaultReceiveWarehouse,
        workCenterGroupCOList,
      } = res.data;
      const departmentIdList = _departmentIdList?.map(({ id, name }: any) => {
        return { value: id, label: name };
      });
      const inputStorageList = _inputStorageList?.map(({ id, name }: any) => {
        return { key: id, label: name };
      });
      const outputStorageList = _outputStorageList?.map(({ id, name }: any) => {
        return { key: id, label: name };
      });
      const defaultReceiveWarehouseId = {
        key: defaultReceiveWarehouse?.id,
        label: defaultReceiveWarehouse?.name,
      };

      setReceiveWarehouse(defaultReceiveWarehouse?.id);

      const wcgCOlist = wcgCOlistToForm(workCenterGroupCOList);

      form.setFieldsValue({
        ...res.data,
        departmentIdList,
        defaultReceiveWarehouseId,
        inputStorageList,
        outputStorageList,
        workCenterGroupCOList: wcgCOlist,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const initCopy = async (attrId: number | undefined) => {
    try {
      if (!attrId) return;
      const res: any = await fetchWorkCenterDetail({ id: attrId });

      const {
        departmentListResponseDTOList: _departmentIdList,
        inputStorageList: _inputStorageList,
        outputStorageList: _outputStorageList,
        defaultReceiveWarehouse,
        workCenterGroupCOList,
      } = omit(res.data, ['id']);
      const departmentIdList = _departmentIdList?.map(({ id, name }: any) => {
        return { value: id, label: name };
      });
      const inputStorageList = _inputStorageList?.map(({ id, name }: any) => {
        return { key: id, label: name };
      });
      const outputStorageList = _outputStorageList?.map(({ id, name }: any) => {
        return { key: id, label: name };
      });
      const defaultReceiveWarehouseId = {
        key: defaultReceiveWarehouse?.id,
        label: defaultReceiveWarehouse?.name,
      };

      setReceiveWarehouse(defaultReceiveWarehouse?.id);

      const wcgCOlist = wcgCOlistToForm(workCenterGroupCOList);

      form.setFieldsValue({
        ...res.data,
        departmentIdList,
        defaultReceiveWarehouseId,
        inputStorageList,
        outputStorageList,
        workCenterGroupCOList: map(wcgCOlist, (workCenterGroupItem) => {
          return {
            ...omit(workCenterGroupItem, ['id']),
          };
        }),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = async () => {
    try {
      const _value = await form?.validateFields();

      const {
        departmentIdList: _departmentIdList,
        inputStorageList: _inputStorageList,
        outputStorageList: _outputStorageList,
        defaultReceiveWarehouseId,
        workCenterGroupCOList,
      } = _value;

      const wcgList = wcgCOlistToApi(workCenterGroupCOList);

      const departmentIdList = _departmentIdList?.map(({ value }: any) => {
        return value;
      });
      const inputStorageList = _inputStorageList?.map(({ key }: any) => {
        return key;
      });
      const outputStorageList = _outputStorageList?.map(({ key }: any) => {
        return key;
      });

      const value = {
        ..._value,
        departmentIdList,
        inputStorageList,
        outputStorageList,
        workCenterGroupCOList: wcgList,
        defaultReceiveWarehouseId: defaultReceiveWarehouseId?.key,
      };

      switch (type) {
        case ACTION_CREATE: {
          await fetchWorkCenterCreate(value);
          message.success('创建成功');
          break;
        }

        case ACTION_EDIT:
          await fetchWorkCenterUpdate({ ...value, id: Number(id) });
          message.success('编辑成功');
          break;

        case ACTION_COPY:
          await fetchWorkCenterCreate(value);
          message.success('创建成功');
          break;

        default:
          break;
      }
      if (type === ACTION_CREATE && keepOn) {
        form.resetFields();
        initCreate();
      } else {
        history.goBack();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      setType(appEnum.Common.CRUD.edit);
      initEdit(Number(id));
    } else if (location.pathname.includes('copy')) {
      setType(appEnum.Common.CRUD.copy);
      initCopy(Number(id));
    } else if (location.pathname.includes('creat')) {
      initCreate();
      setType(appEnum.Common.CRUD.create);
    } else {
      initCreate();
    }
  }, []);

  return (
    <DataFormLayout
      form={form}
      info={[baseInfo, workCenterGroupCOList]}
      title={workCenterStatusMap.get(type)}
      extra={
        type === ACTION_CREATE && (
          <Checkbox
            onChange={() => {
              setKeepOn(!keepOn);
            }}
            defaultChecked={keepOn}
          >
            连续新建
          </Checkbox>
        )
      }
      onCancel={() => {
        history.goBack();
      }}
      onFinish={handleFinish}
      formProps={{
        id: 'attrTable',
      }}
    />
  );
};

export default WorkCenterBaseInfo;
