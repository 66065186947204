import { message } from 'antd';
// 列表批量操作请求通用方法
export const patchRequest = (
  patchApi: any,
  selectedIds: any,
  display: string,
  onSuccess?: any,
  onFail?: any,
) => {
  patchApi({ ids: selectedIds })
    .then((res: any) => {
      const { code, message: mes } = res;

      if (code === 200) {
        onSuccess && onSuccess();
        message.success(display);
        return;
      }
      message.error(mes);
      return undefined;
    })
    .catch((err: any) => {
      console.log('err: ', err);
      onFail && onFail();
    });
};

// 取消精度管理启停用状态，原来没有精度的默认初始化为0
export const getDefaultPrecisionFigure = (enablePrecision: number) => {
  if (enablePrecision < 0) return 0;

  return enablePrecision;
};
