import React, { useState } from 'react';
import { FilterItem, RecordListLayout } from 'layout';
import { LinkTooltip, SearchSelect, TagTooltip } from 'components';
import { replaceSign } from 'src/utils/constants';
import { RouteComponentProps } from 'react-router-dom';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import _Time from 'src/utils/dataTypes/time';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { fieldTypeList } from 'src/utils';
import { toElectronicDocumentsDetail } from '../templateManagement/navigation';
import { CreatorData } from 'src/page/knowledgeManagement/calendarManagement/shift/interface';
import {
  APPROVE_ENUM,
  APPROVE_MAP,
  APPROVE_TYPE,
  SYMBOL_MAP,
  TEMPLATE_ENUM,
  TEMPLATE_TYPE_MAP,
} from '../templateManagement/constant';
import lookup, { appDict } from 'src/dict';
import { fetchEReportInstanceQueryEReportInstancePage } from 'src/api/ytt/e-report-domain/electronicDocuments';
import { cptRelatedParamType, TableRowType } from './interface';
import _String from 'src/utils/dataTypes/string';
import _Array from 'src/utils/dataTypes/array';
import { map } from 'lodash';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import { ApprovalStatus } from 'src/dict/approval';
import { renderElectronicValueDisplay, submitApprove } from './utils';
import { PrintByReportComponent } from 'src/components/print';
import { Select } from 'antd';

interface Props extends RouteComponentProps {}

const ElectronicDocumentsList = (props: Props) => {
  const { history } = props;

  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新页面

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  const dataColumns = [
    {
      title: '模板编号',
      dataIndex: 'templateCode',
      width: 200,
      sorter: true,
      isFilter: true,
      render: (templateCode: string) => templateCode ?? replaceSign,
    },
    {
      title: '模板名称',
      dataIndex: 'name',
      width: 200,
      isFilter: true,
      render: (name: string, record: TableRowType, index: number, config: any) => (
        <LinkTooltip
          to={toElectronicDocumentsDetail(record?.id)}
          text={name}
          width={config.contentWidth}
          auth={authDict.eReportCase_detail}
        />
      ),
    },
    {
      title: '模板类别',
      dataIndex: 'type',
      width: 150,
      isFilter: true,
      render: (type: number) => TEMPLATE_TYPE_MAP.get(type),
    },
    {
      title: 'cpt模板',
      dataIndex: 'cptFileName',
      width: 200,
      isFilter: true,
      render: (cptFileName: string) => cptFileName ?? replaceSign,
    },
    {
      title: 'cpt模板版本号',
      dataIndex: 'cptVersion',
      width: 150,
      defaultColConfig: {
        display: false,
      },
      render: (cptVersion: string) => cptVersion ?? replaceSign,
    },
    {
      title: 'cpt模板关联参数',
      dataIndex: 'cptRelatedParam',
      width: 300,
      render: (cptRelatedParam: cptRelatedParamType[]) => {
        return (
          <TagTooltip
            type="pureText"
            list={cptRelatedParam?.map((node: cptRelatedParamType) => {
              return {
                id: node.relateParamsId,
                name: `${node?.fieldDisplayName}：${SYMBOL_MAP.get(
                  node?.condition,
                )}${renderElectronicValueDisplay(node)}`,
                key: node.relateParamsId,
              };
            })}
          />
        );
      },
    },
    {
      title: '是否需要审批',
      dataIndex: 'approval',
      width: 150,
      render: (approval: number) => APPROVE_TYPE.get(approval) ?? replaceSign,
    },
    {
      title: '审批单编号',
      dataIndex: 'approvalFormCode',
      width: 200,
      isFilter: true,
      render: (approvalFormCode: string) => approvalFormCode ?? replaceSign,
    },
    {
      title: '审批状态',
      dataIndex: 'approvalStatus',
      width: 150,
      render: (approvalStatus: number, { approval }: TableRowType) => {
        return approval === APPROVE_ENUM.YES
          ? lookup('approval.approvalStatus', approvalStatus)
          : replaceSign;
      },
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 150,
      render: (creator: CreatorData) => {
        return (
          <AvatarDisplay
            id={creator?.id}
            name={creator?.name}
            avatarUrl={creator?.avatarUrl}
            key={creator?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 200,
      isFilter: true,
      sorter: true,
      render: (createdAt: Date) => {
        if (!createdAt) return replaceSign;
        return _Time.format(createdAt);
      },
    },
    {
      title: '更新人',
      dataIndex: 'modifier',
      width: 150,
      isFilter: true,
      defaultColConfig: {
        display: false,
      },
      render: (modifier: CreatorData) => {
        return (
          <AvatarDisplay
            id={modifier?.id}
            name={modifier?.name}
            avatarUrl={modifier?.avatarUrl}
            key={modifier?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 200,
      sorter: true,
      isFilter: true,
      defaultColConfig: {
        display: false,
      },
      render: (updatedAt: Date, record: TableRowType) => {
        if (!updatedAt) return _Time.format(record?.createdAt) ?? replaceSign;
        return _Time.format(updatedAt);
      },
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '模板编号',
      name: 'templateCode',
      type: fieldTypeList?.text,
    },
    {
      label: '模板名称',
      name: 'templateName',
      type: fieldTypeList?.text,
    },
    {
      label: '模板类别',
      name: 'type',
      type: fieldTypeList.select,
      selectProps: {
        options: TEMPLATE_ENUM.map((node: any) => {
          return {
            label: node?.label,
            value: node?.key,
            key: node?.key,
          };
        }),
      },
    },
    {
      label: '是否需要审批',
      name: 'approval',
      type: fieldTypeList.select,
      renderItem: <Select placeholder={'请选择'} options={APPROVE_MAP} />,
    },
    {
      label: '审批单编号',
      name: 'approvalFormCode',
      type: fieldTypeList?.text,
    },
    {
      label: '审批单状态',
      name: 'approvalStatusList',
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.approval.approvalStatus,
        mode: 'multiple',
      },
    },
    {
      label: '创建人',
      name: 'creatorId',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect labelInValue fetchType={'user'} mode="multiple" />,
    },
    {
      label: '创建时间',
      name: 'createdAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '更新人',
      name: 'operatorId',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect labelInValue fetchType={'user'} mode="multiple" />,
    },
    {
      label: '更新时间',
      name: 'updatedAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
  ];

  const formatDataToQuery = (params: any) => {
    const {
      templateCode,
      templateName,
      approvalFormCode,
      operatorId,
      creatorId,
      createdAt,
      updatedAt,
      approvalStatusList,
      ...rest
    } = params;

    const relParams = {
      ...rest,
    };

    if (!_String.isEmpty(templateCode)) {
      relParams.templateCode = templateCode;
    }

    if (!_String.isEmpty(templateName)) {
      relParams.templateName = templateName;
    }

    if (!_String.isEmpty(approvalFormCode)) {
      relParams.approvalFormCode = approvalFormCode;
    }

    if (!_Array.isEmpty(approvalStatusList)) {
      relParams.approvalStatusList = approvalStatusList;
      relParams.approval = 1;
    }

    if (createdAt) {
      relParams.createdAtStart = Number(_Time.formatToUnix(createdAt[0]));
      relParams.createdAtEnd = Number(_Time.formatToUnix(createdAt[1]));
    }
    if (updatedAt) {
      relParams.updatedAtStart = Number(_Time.formatToUnix(updatedAt[0]));
      relParams.updatedAtEnd = Number(_Time.formatToUnix(updatedAt[1]));
    }

    if (!_Array.isEmpty(creatorId)) {
      relParams.creatorId = map(creatorId, 'value');
    }
    if (!_Array.isEmpty(operatorId)) {
      relParams.operatorId = map(operatorId, 'value');
    }

    return relParams;
  };

  const formatDataToFormDisplay = (data: any) => {
    const { createdAt, updatedAt } = data;

    const retData = { ...data };

    if (createdAt) {
      retData.createdAt = momentData(createdAt);
    }
    if (updatedAt) {
      retData.updatedAt = momentData(updatedAt);
    }

    return retData;
  };

  const getOperationList = (record: TableRowType) => {
    return [
      {
        title: '查看',
        auth: authDict.eReportCase_detail,
        onClick: () => {
          history.push(toElectronicDocumentsDetail(record?.id));
        },
      },
      {
        title: '提交审批',
        auth: authDict.eReportCase_approve,
        disabled:
          record?.approval === APPROVE_ENUM.NO ||
          record?.approvalStatus !== ApprovalStatus.rejected,
        onClick: () => {
          submitApprove(record?.id, () => {
            refresh();
          });
        },
      },
      {
        title: '打印',
        auth: authDict.eReportCase_print,
        customRender: <PrintByReportComponent instanceId={record.id} />,
      },
    ];
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        refreshMarker={refreshMarker}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        filterList={filterList}
        getOperationList={getOperationList}
        userAuth={getAuthFromLocalStorage()}
        placeholder={'请输入模板编号或名称'}
        configcacheKey="electronicDocumentsCol"
        requestFn={fetchEReportInstanceQueryEReportInstancePage}
        columns={dataColumns}
        maxOperationCount={3}
      />
    </>
  );
};

export default ElectronicDocumentsList;
