/**
 * 在表格中渲染步骤的列
 */
import { Tag, Tooltip } from 'antd';
import lookup from 'src/dict';
import { DigitalType, GroupType, NextStepType, PrivilegeType, StepEntityType } from 'src/dict/sop';
import { replaceSign } from 'src/utils/constants';
import { getParentById, getById } from './stepUtils';
import { renderNameArray } from '../../task/share/renderLabel';
import type { StepOverviewList, StepOverviewData } from '../types';
import FormulaDetailModal from '../components/formulaDetailModal';

/** 渲染步骤名称 */
export const getRenderStepName =
  (steps: StepOverviewList, isRenderTree?: boolean) => (__: unknown, record: StepOverviewData) => {
    const parent = getParentById(steps, record.id);
    const tags = [];

    if (parent === 'root' || parent?.groupType !== GroupType.parallel) {
      if (record.first) {
        tags.push(
          <Tag key="start" color="#D9F7BE" style={{ color: '#52C41A', border: 'none' }}>
            始
          </Tag>,
        );
      }
      if (record.last) {
        tags.push(
          <Tag key="end" color="#FFF1B8" style={{ color: '#FAAD14', border: 'none' }}>
            终
          </Tag>,
        );
      }
    }
    if (record.type === StepEntityType.stepGroup) {
      tags.push(
        <Tag color="#F0F0F0" key="grouptype" style={{ color: '#595959', border: 'none' }}>
          {lookup('sop.groupType', record.groupType).charAt(0)}
        </Tag>,
      );
    }

    if (isRenderTree) {
      return (
        <>
          <span className="treeNodeTitletext"> {record.name}</span>
          <span style={{ marginRight: 8 }} />
          {tags}
        </>
      );
    }

    const colContent = (
      <span>
        <span>{record.name}</span>
        <span style={{ marginRight: 8 }} />
        {tags}
      </span>
    );

    return {
      children: (
        //  <TextToolTip text={record.name} width={60} />
        <Tooltip title={colContent}>{colContent}</Tooltip>
      ),
      props: {
        className: 'step-list-step-name',
      },
    };
  };

/** 渲染『循环』列 */
export const renderSelfLoop = (__: unknown, record: StepOverviewData) => {
  const descGroup = [];
  const timeRestrictGroup = [];

  if (record.enableSelfLoop) {
    descGroup.push(`循环状态为「开启」，循环${record.loopCount}次`);
    timeRestrictGroup.push(`「${lookup('turn', +record.singleLoopTimeRestrict)}」单次循环时间限制`);
    if (record.singleLoopTimeRestrict) {
      timeRestrictGroup.push(
        `${record.singleLoopIntervalTime}${lookup('time', record.loopIntervalUnit)}循环一次`,
      );
      timeRestrictGroup.push(lookup('sop.loopExecRestrictRule', record.loopExecRestrictRule));
    }
    descGroup.push(timeRestrictGroup.join('，'));
  } else {
    descGroup.push('循环「关闭」');
  }
  return descGroup.join('；');
};

/** 渲染『电子签名』列 */
export const renderDigitalSign = (__: unknown, record: StepOverviewData) => {
  // 串行步骤组无电子签名
  if (record.type === StepEntityType.stepGroup && record.groupType === GroupType.serial) {
    return replaceSign;
  }
  if (!record.enableDigitalSignature) {
    return '关';
  }
  const prefix = `开-${lookup('sop.digitalType', record.digitalType)}-`;
  let auth;

  if (record.digitalType === DigitalType.users) {
    auth = renderNameArray(record.digitalUserIds as any[]);
  } else if (record.digitalType === DigitalType.roles) {
    auth = renderNameArray(record.digitalRoleIds as any[]);
  }
  return `${prefix}${auth}`;
};

/** 渲染『后续步骤』列 */
export const getRenderNextStep =
  (steps: StepOverviewList) => (__: unknown, record: StepOverviewData) => {
    // 终止步骤无后续步骤
    if (record.last) {
      return replaceSign;
    }
    const parent = getParentById(steps, record.id);

    // 并行步骤组下无后续步骤
    if (parent !== 'root' && parent?.groupType === GroupType.parallel) {
      return replaceSign;
    }

    const nextStepType = lookup('sop.nextStepType', record.nextStepType);

    if (record.nextStepType === NextStepType.specified) {
      const manualNextSteps = record.manualNextStepIds.map((id) => {
        const step = getById(steps, id);

        return step === 'root' ? '' : step?.name;
      });

      return `${nextStepType}-${manualNextSteps.join('、')}`;
    }
    if (record.nextStepType === NextStepType.condition) {
      return (
        <FormulaDetailModal linkText="查看" label="自定义函数判断" formulaId={record?.formulaId} />
      );
    }
    return nextStepType;
  };

/** 渲染『执行权限』列 */
export const renderExecAuth = (__: unknown, record: StepOverviewData) => {
  // 步骤组无执行权限
  if (record.type === StepEntityType.stepGroup) {
    return replaceSign;
  }
  const authType = lookup('sop.privilegeType', record.privilegeType);

  if (record.privilegeType === PrivilegeType.executors) {
    return authType;
  }
  return `${authType}-${renderNameArray([
    ...record.authUsers,
    ...record.authRoles,
    ...record.authDepartments,
  ])}`;
};
