import { Descriptions } from 'antd';
import _Time from 'src/utils/dataTypes/time';
import { BlTable } from '@blacklake-web/component';
import lookup from 'src/dict';

//
// import { BomResponse } from '../createAndEdit/index.type';

interface sourceInfoTabProps {
  dataSource?: any;
}

const SourceDetail = (props: sourceInfoTabProps) => {
  const { dataSource } = props;

  const { generationMethod, sourceType } = dataSource?.workOrderSource ?? {};

  const renderSaleTable = () => {
    return (
      <BlTable
        dataSource={dataSource?.workOrderSource?.sourceSalesOrderList}
        columns={[
          {
            title: '销售订单编号',
            dataIndex: ['salesOrderValue', 'code'],
          },
          {
            title: '销售订单行号',
            dataIndex: 'salesOrderLine',
          },
          {
            title: '交货日期',
            dataIndex: 'deliveryTime',
            render: (text: any) => _Time.format(text),
          },
        ]}
        rowKey="id"
      />
    );
  };

  const renderSourceTable = () => {
    return (
      <BlTable
        dataSource={dataSource?.workOrderSource?.sourceWorkOrderList}
        columns={[
          {
            title: '单据编号',
            dataIndex: ['workOrderValue', 'code'],
          },
          {
            title: '单据行号',
            dataIndex: 'workOrderLine',
          },
        ]}
        rowKey="id"
      />
    );
  };

  return (
    <div style={{ padding: 24 }}>
      <Descriptions column={1}>
        <Descriptions.Item label="生成方式">
          {lookup('productionPlanning.GenerationEnumDisplayMap', generationMethod)}
        </Descriptions.Item>
        <Descriptions.Item label="来源单据类型">
          {lookup('productionPlanning.SourceTypeDisplayMap', sourceType)}
        </Descriptions.Item>
        <Descriptions.Item label="来源单据">{renderSourceTable()}</Descriptions.Item>
        <Descriptions.Item label="销售订单">{renderSaleTable()}</Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default SourceDetail;
