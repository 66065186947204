import { Modal } from 'antd';
import { BlTable } from '@blacklake-web/component';
import _ from 'lodash';

import { amountRender } from 'src/page/share/renderAmount';
import { fieldLabels } from '../constants';
import { CheckFinishResultType } from '../interface';
import { finishSalesReturnOrderService, finishCheckService } from '../services';

const getCheckPassedContent = (orderCode: string) => (
  <div>{`结束销售退货单后将不能再进行任何操作, 确定要结束<${orderCode}>该订单吗？`}</div>
);

const columns = [
  {
    title: fieldLabels.lineNo,
    dataIndex: 'lineNo',
    width: 80,
  },
  {
    title: fieldLabels.materialCode,
    dataIndex: 'materialCode',
    width: 160,
  },
  {
    title: fieldLabels.materialName,
    dataIndex: 'materialName',
    width: 200,
  },
  {
    title: fieldLabels.plannedReturnAmount,
    dataIndex: 'amount',
    render: amountRender,
    width: 118,
  },
  {
    title: fieldLabels.inboundAmount,
    dataIndex: 'inboundAmount',
    render: amountRender,
    width: 118,
  },
];

const TABLE_MAX_HEIGHT = 400;

const getCheckFailedContent = (
  failDetails: NonNullable<CheckFinishResultType>['failDetails'],
  orderCode: string,
) => (
  <>
    <div>以下物料明细行还未完成，确认要结束{orderCode}该单据吗？</div>
    <BlTable
      dataSource={failDetails}
      columns={columns}
      pagination={false}
      scroll={{ y: TABLE_MAX_HEIGHT }}
      rowKey={'materialId'}
    />
  </>
);

export const useFinishReturnOrderModal = () => {
  const show = async (orderCode: string, orderId: number, refresh?: () => void) => {
    const checkResult = await finishCheckService(orderId);

    if (_.isNil(checkResult)) return;
    let content = getCheckPassedContent(orderCode);

    if (checkResult?.failCount! > 0) {
      content = getCheckFailedContent(checkResult?.failDetails, orderCode);
    }
    Modal.confirm({
      title: '结束销售退货单提示',
      width: 800,
      content,
      okText: checkResult?.failCount! > 0 ? '强制结束' : '继续结束',
      cancelText: '暂不结束',
      onOk: async () => {
        await finishSalesReturnOrderService(orderId);
        refresh?.();
      },
    });
  };

  return { show };
};
