import { ReactNode } from 'react';
import { Popover, Tag } from 'antd';
import { AvatarDisplay, RoleDisplay } from '../avatar/show';
import UserTooltip, { UserProps } from './userTooltip';
import styles from './index.module.scss';
import _ from 'lodash';
import { replaceSign } from 'src/utils/constants';

interface ListItem extends UserProps {
  name: string;
  id: number;
}
export type TagTooltipType = 'user' | 'deparement' | 'role' | 'text' | 'pureText';
interface Props {
  list?: ListItem[]; // 数据
  text?: ReactNode; // 显示文案，默认“更多“
  max?: number; // 最大显示个数，默认“3“
  splitBy?: string; // tooltip展示数据分隔符号，默认“；”
  isUser?: boolean;
  isShowDetail?: boolean;
  type?: TagTooltipType;
  isShowAll?: boolean; // 不折叠，展示全部内容
}

const TagTooltip = (props: Props) => {
  const {
    list = [],
    max = 3,
    text = '更多',
    splitBy = '；',
    isUser,
    isShowDetail,
    type,
    isShowAll,
  } = props;
  if (!list || _.isEmpty(list)) {
    return <>{replaceSign}</>;
  }

  const renderTooltip = () => {
    return (
      <div style={{ maxWidth: 208, maxHeight: 105, overflowY: 'auto' }}>
        {list
          .map((item) => item.name)
          .slice(max, list.length)
          .join(splitBy)}
      </div>
    );
  };

  return (
    <div className={isShowAll ? styles.tagTooltip : styles.tagNoWrap}>
      {(isShowAll ? list : list.slice(0, max)).map((item: ListItem) => {
        if (isShowDetail) {
          return <UserTooltip {...item} key={item.id} />;
        }
        if (type === 'text') {
          return <Tag key={item.id}>{item.name}</Tag>;
        }
        if (type === 'pureText') {
          return <div key={item?.id}>{item.name}</div>;
        }
        if (type === 'role') {
          return <RoleDisplay {...item} isShowTag isShowUsers={isShowAll} key={item.id} />;
        }
        return (
          <AvatarDisplay
            key={item.id}
            isShowTag
            isUser={isUser || type === 'user'}
            isShowUsers={isShowAll}
            {...item}
          />
        );
      })}
      {!isShowAll && list.length > max && (
        <Popover content={renderTooltip()}>
          <a>{text}</a>
        </Popover>
      )}
    </div>
  );
};

export default TagTooltip;
