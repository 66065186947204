/**
 * @des 触发事件模块options值
 * [{ label, value }]
 */

import { ActionTypeEnum, ActionValueTypeEnum, ActionSourceType, InstanceSourceTypeEnum } from '.';
import { mappingsFactory } from '../utils';

/** 业务类型 */
export const actionTypeOptions = mappingsFactory(
  ['新建记录', ActionTypeEnum.createRecord],
  ['更新记录', ActionTypeEnum.updateRecord],
);

/** 触发事件字段信息配置，值类型 */
export const valueTypeOptions = mappingsFactory(
  ['值', ActionValueTypeEnum.value],
  ['公式', ActionValueTypeEnum.formula],
  ['字段引用', ActionValueTypeEnum.biz],
  ['引用触发规则.业务字段', ActionValueTypeEnum.triggerRuleBiz],
  ['引用触发规则.业务对象', ActionValueTypeEnum.triggerRuleObj],
);

/** 来源类型 */
export const actionSourceType = mappingsFactory(
  ['手动创建', ActionSourceType.manual],
  ['系统预置', ActionSourceType.preset],
  ['业务自动创建', ActionSourceType.businessAutomation],
);

export const instanceSourceTypeOptions = mappingsFactory(
  ['触发规则业务对象', InstanceSourceTypeEnum.triggerRuleBizObject],
  ['触发规则业务对象关联对象', InstanceSourceTypeEnum.triggerRuleBizObjectAssociatedObject],
  ['手动选择业务对象', InstanceSourceTypeEnum.manualSelectBizObject],
);
