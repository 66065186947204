import { FormInstance, message, Modal } from 'antd';
import {
  fetchCustomerDeliveryScheduleNoteAutoCreateMaterialRegister,
  fetchCustomerDeliveryScheduleNoteConfirm,
  fetchCustomerDeliveryScheduleNoteConfirmCheck,
  fetchCustomerDeliveryScheduleNoteReturn,
} from 'src/api/ytt/supply-chain-domain/customer_delivery_schedule_note';
import {
  CustomerDeliveryScheduleNoteListItems,
  CustomerDeliveryScheduleNoteListProps,
} from './interface';
import { toCustomerMaterialRegist_URL } from './navigation';
import _ from 'lodash';
import { PlannedIssueStatus, OrderStatus } from 'src/dict/collaborativeDeliverySchedule';
import authDict, { hasAuth } from 'src/utils/auth';
/**
 * 接受操作 (批量、列操作)
 */
const handleAccept = async (ids: number[], callback: () => void) => {
  try {
    const acceptFn = async () => {
      const { code } = await fetchCustomerDeliveryScheduleNoteConfirm({
        ids,
      });

      if (code == 200) {
        message.success('确认计划成功');
        callback();
      } else {
        message.error('确认失败');
      }
    };
    const checkFn = async () => {
      const { data } = await fetchCustomerDeliveryScheduleNoteConfirmCheck({
        ids,
      });

      return data;
    };
    /**
     * 注册物料
     */
    const registMaterialsFn = async () => {
      const { data } = await fetchCustomerDeliveryScheduleNoteAutoCreateMaterialRegister({
        ids,
      });

      return data;
    };

    const checkConfig = {
      title: '提示',
      content: '是否确认客户发起的交货计划？确认后将生成协同交货计划且不支持删除协同交货计划',
      width: 436,
      onOk: () => {
        checkFn().then((data) => {
          // 判断是否需要创建物料注册邀请
          if (data === false) {
            // 需要
            Modal.error(materialRegistConfig);
          } else {
            acceptFn();
          }
        });
      },
      okText: '确认计划',
      onCancel: () => {},
      cancelText: '再想想',
      icon: null,
      closable: true,
    };
    const materialRegistConfig = {
      title: '提示',
      content:
        '当前交货计划有部分客户物料未注册或正在注册中，已对未注册物料发起注册，请在「客户-查看物料注册邀请」中审核注册物料',
      width: 436,
      onOk: () => {
        registMaterialsFn().then(() => {
          /**
           * 跳转到
           */
          window.open(toCustomerMaterialRegist_URL());
          callback();
        });
      },
      okText: '审核物料注册邀请',
      okButtonProps: {
        style: {
          display: !hasAuth(authDict.customermaterialregisteredaudit_reg_list_material) && 'none',
        },
      },
      cancelButtonProps: {
        style: {
          display: 'none',
        },
      },
      icon: null,
      closable: true,
    };

    Modal.confirm(checkConfig);
  } catch (error) {
    console.log('error', error);
  }
};
/**
 * 退回操作 (列操作)
 */
const handleReject = (
  record: CustomerDeliveryScheduleNoteListProps,
  form: FormInstance,
  formContent: JSX.Element,
  callback: () => void,
) => {
  try {
    const { id } = record;
    const rejectFn = async () => {
      const { reason } = form.getFieldsValue();
      const { code } = await fetchCustomerDeliveryScheduleNoteReturn({
        id: id as number,
        reason,
      });

      if (code == 200) {
        message.success('确认退回成功');
      } else {
        message.error('退回失败');
      }
      callback();
    };
    const config = {
      title: '请填写退回原因',
      content: formContent,
      width: 600,
      onOk: async () => {
        return rejectFn();
      },
      okText: '确认退回',
      onCancel: () => {},
      cancelText: '再想想',
      icon: null,
      closable: true,
    };

    Modal.confirm(config);
  } catch (error) {
    console.log('error', error);
  }
};
/**
 * 新建发货单
 */
const handleCreateInvoice = (
  data:
    | CustomerDeliveryScheduleNoteListProps[]
    | {
        items: CustomerDeliveryScheduleNoteListItems[];
        plannedIssueStatus: { code?: number | undefined; message?: string | undefined } | undefined;
      }[],
  onSuccess: () => void,
  onFail?: () => void,
  onJump?: () => void,
  type?: 'single' | 'multiple',
) => {
  const handleNext = () => {
    if (
      _.findIndex(
        data,
        (o) =>
          o?.plannedIssueStatus === PlannedIssueStatus.planned ||
          o?.status !== OrderStatus.inprogress,
      ) > -1
    ) {
      message.error('只有状态为执行中，发货状态为待计划/部分计划的协同交货计划单可以新建发货单');
      onFail?.();
    } else {
      onSuccess();
    }
  };
  const config = {
    title: '提示',
    content: '物料已全部计划发货，发货数量不得超过需求数量，可以到发货单内执行其他操作',
    width: 436,
    onOk: () => {
      onJump?.();
    },
    okText: '查看发货单',
    onCancel: () => {},
    cancelText: '关闭',
  };

  switch (type) {
    case 'multiple': {
      handleNext();
      break;
    }

    default: {
      // 默认 single
      const plannedIssueStatus = data[0]?.plannedIssueStatus;

      if (plannedIssueStatus === PlannedIssueStatus.planned) {
        // 弹窗提示
        Modal.confirm(config);
      } else {
        // 发货状态=待发货/部分发货时，带物料编号、物料名称等信息新页面打开新建发货单页面；
        onSuccess();
      }

      break;
    }
  }
};

export { handleAccept, handleReject, handleCreateInvoice };
