import React, { useState } from 'react';
import { Form, FormInstance, Input, message, Switch } from 'antd';
import { BatchOperateTableHeader, BlSortFormList, SearchSelect } from 'src/components';
import { FormListFieldData } from 'antd/lib/form/FormList';
import _ from 'lodash';
import { RESORCE_STATUS_VALUE } from 'src/page/resource/definitions/constants';
import { BatchEquipmentSelector } from 'src/page/resource/definitions/components/BatchEquipmentSelector';
import { valueOrHolder } from 'src/utils/formatters';
//
import {
  EnergyMeterCreateApiType,
  EnergyMeterDetailType,
  ICensusEquimentForm,
  IEnergyMeterForm,
} from '../../index.d';
import { gitUniqueList } from '../../utils';
import DetailTableModal from './detailModal';

const MAX_COUNT = 20;

export const formatDataToApi = (
  data: ICensusEquimentForm[],
): EnergyMeterCreateApiType['energyInstrumentResourceLinkList'] => {
  if (_.isEmpty(data)) return [];

  return _.compact(
    _.map(data, (item) => {
      const { hasChildren = false, linkResourceId, id } = item;

      const { id: resourceId, level } = JSON.parse(linkResourceId?.value ?? '{}');

      return {
        hasChildren,
        level,
        linkResourceId: resourceId,
        id,
      };
    }),
  );
};

export const formatDataToForm = (
  data: EnergyMeterDetailType['energyInstrumentResourceLinkList'],
): ICensusEquimentForm[] => {
  if (_.isEmpty(data)) return [];

  return (data ?? []).map(({ hasChildren, id, linkResourceId, name = '', level }) => {
    return {
      linkResourceId: { label: name, value: JSON.stringify({ id: linkResourceId, name, level }) },
      hasChildren,
      id,
    };
  });
};

interface FormListProps {
  form: FormInstance<IEnergyMeterForm>;
  name: string;
}

const FormList = (props: FormListProps) => {
  const { form, name } = props;

  const [showAddResourceDialog, setShowAddResourceDialog] = useState(false);
  const [showEquimentModalId, setShowEquimentModalId] = useState<number>();
  const [loading] = useState(false);

  const getDefaultValue = (field: FormListFieldData, fieldStr: string, defaultValue?: any) => {
    const hasValue = !_.isNil(form.getFieldValue([name, field.name, fieldStr]));

    if (hasValue) return undefined;

    return defaultValue;
  };

  /**
   * 校验标签/参数/设备,不能重复
   */
  const validateDuplicated =
    (listField: string, field: string, message: string) => (rule: any, value: any) => {
      if (_.isNil(value)) return Promise.resolve('');
      const formList = form.getFieldValue(listField);

      if (_.isEmpty(formList)) return Promise.resolve('');

      const { id: curParamsId } = JSON.parse(value?.value ?? '{}');
      const keyList = formList?.map((formItem: any) => {
        const { id } = JSON.parse(formItem[field]?.value ?? '{}');

        return id;
      });

      const firstIndex = _.findIndex(keyList, (key) => key === curParamsId);
      const lastIndex = _.findLastIndex(keyList, (key) => key === curParamsId);

      if (firstIndex !== lastIndex) {
        return Promise.reject(message);
      }

      return Promise.resolve('');
    };

  const lookEquipmentDetail = async (field: FormListFieldData) => {
    const selectEquipment = form.getFieldValue([name, field.name, 'linkResourceId']) ?? {};

    if (_.isEmpty(selectEquipment)) return message.warning('请选择设备！');

    const { id } = JSON.parse(selectEquipment?.value ?? '{}');

    setShowEquimentModalId(id);
  };

  /** 批量添加设备 */
  const batchAddEquipments = (selectKeys: any[], selectRows: any[]) => {
    if (_.isEmpty(selectKeys)) return;

    const curSelectData: ICensusEquimentForm[] = _.filter(
      form.getFieldValue(name),
      ({ linkResourceId }) => !_.isNil(linkResourceId),
    );

    const formatAddData = (data: any[]): ICensusEquimentForm[] => {
      const addData = _.map(data, (item) => {
        const newItem = _.pick(item, ['id', 'level', 'hasChildren']);

        return {
          linkResourceId: {
            label: item.name,
            value: JSON.stringify(newItem),
          },
          hasChildren: false,
        };
      });

      return addData;
    };

    const newData = {
      [name]: gitUniqueList<ICensusEquimentForm>({
        curList: curSelectData,
        addList: formatAddData(selectRows),
        maxCount: MAX_COUNT,
        getUniqueKey: ({ linkResourceId = {} }) => {
          const { value = '{}' } = linkResourceId;

          return JSON.parse(value)?.id;
        },
      }),
    };

    form.setFieldsValue(newData);

    setShowAddResourceDialog(false);
  };

  return (
    <div>
      <BlSortFormList
        form={form}
        name={name}
        isNeedDrag={false}
        tableProps={{ loading }}
        maxCount={MAX_COUNT}
        extraButton={[
          {
            title: '批量添加',
            onClick: () => {
              setShowAddResourceDialog(true);
            },
          },
        ]}
        renderColumns={() => {
          return [
            {
              title: '设备',
              render: (text, field) => (
                <Form.Item noStyle dependencies={[[name, field.name, 'hasChildren']]}>
                  {() => {
                    const hasChildren = form.getFieldValue([name, field.name, 'hasChildren']);

                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Item
                          style={{ marginBottom: '0', width: '100%' }}
                          fieldKey={[field.fieldKey, 'linkResourceId']}
                          name={[field.name, 'linkResourceId']}
                          initialValue={getDefaultValue(field, 'linkResourceId')}
                          rules={[
                            {
                              required: true,
                              message: '设备不能为空',
                            },
                            {
                              validator: validateDuplicated(
                                name,
                                'linkResourceId',
                                '不能选择重复的设备',
                              ),
                            },
                          ]}
                        >
                          <SearchSelect
                            fetchType="resourceCompleteInfo"
                            placeholder="请选择设备"
                            labelInValue
                            params={{
                              status: RESORCE_STATUS_VALUE.ENABLED,
                            }}
                          />
                        </Form.Item>
                        {hasChildren ? (
                          <a
                            style={{ width: 60, marginLeft: 10 }}
                            onClick={() => lookEquipmentDetail(field)}
                          >
                            子设备
                          </a>
                        ) : null}
                      </div>
                    );
                  }}
                </Form.Item>
              ),
            },
            {
              title: '设备层级',
              width: 200,
              render: (text, field) => {
                return (
                  <Form.Item noStyle dependencies={[[name, field.name, 'linkResourceId']]}>
                    {() => {
                      const linkResourceId =
                        form.getFieldValue([name, field.name, 'linkResourceId']) ?? {};

                      const level = JSON.parse(linkResourceId?.value ?? '{}')?.level;

                      return (
                        <Form.Item style={{ marginBottom: '0' }}>
                          <span>{valueOrHolder(level ? `${level}级` : undefined)}</span>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: (
                <BatchOperateTableHeader
                  titleText="包含子设备"
                  tooltip="开启后,能耗统计将包含该设备的子设备"
                />
              ),
              render: (text, field) => {
                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'hasChildren']}
                    name={[field.name, 'hasChildren']}
                    initialValue={getDefaultValue(field, 'hasChildren', false)}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                );
              },
            },
            {
              label: '',
              name: 'id',
              width: 1,
              render: (text, field) => {
                return (
                  <Form.Item
                    style={{ marginBottom: '0' }}
                    fieldKey={[field.fieldKey, 'id']}
                    name={[field.name, 'id']}
                  >
                    <Input />
                  </Form.Item>
                );
              },
            },
          ];
        }}
      />
      {showAddResourceDialog && (
        <BatchEquipmentSelector
          visible={showAddResourceDialog}
          onFinish={batchAddEquipments}
          onCancel={() => {
            setShowAddResourceDialog(false);
          }}
          maxCount={MAX_COUNT}
        />
      )}
      {!_.isNil(showEquimentModalId) && (
        <DetailTableModal
          id={showEquimentModalId}
          visible={!_.isNil(showEquimentModalId)}
          onCancel={() => {
            setShowEquimentModalId(undefined);
          }}
        />
      )}
    </div>
  );
};

export default FormList;
