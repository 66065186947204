import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, DatePicker, Space } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { RecordListLayout } from 'src/layout';
import lookup from 'src/dict';
import { ListItemType } from 'src/types';
import { formateDateRangeForQuery2 } from 'src/utils/formatters/dateTime';
import {
  fetchPlanOrderLaunchLogList,
  FetchPlanOrderLaunchLogListResponse,
} from 'src/api/ytt/plo-domain';
import { AvatarDisplay } from 'src/components/avatar/show';
//
import { toLogDetailPlannedOrder } from '../navigation';
import { getResult, StatusMap, StatusEnum } from './utils';

type RecordType = ListItemType<FetchPlanOrderLaunchLogListResponse>;

const FILTER_KEY = 'planOrderLogFilter';

const List = () => {
  const [filterDate, setFilterDate] = useState<any>([
    moment(moment().month(moment().month()).startOf('month').valueOf()),
    moment(moment().month(moment().month()).endOf('month').valueOf()),
  ]);
  const [resetRefreshMarker, setResetRefreshMarker] = useState(0);

  useEffect(() => {
    const startDate = moment().month(moment().month()).startOf('month').valueOf();

    const endDate = moment().month(moment().month()).endOf('month').valueOf();

    formatDataToQuery([moment(startDate), moment(endDate)]);

    setFilterDate([moment(startDate), moment(endDate)]);
  }, []);

  const columns: ColumnProps<RecordType>[] = [
    {
      title: '操作时间',
      dataIndex: 'createdAt',
      width: 200,
      render: (text) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''),
    },
    {
      title: '操作用户',
      dataIndex: 'creator',
      width: 100,
      render: (info) => <AvatarDisplay {...info} isUser />,
    },
    {
      title: '操作类型',
      dataIndex: 'operateType',
      width: 100,
      render: (text: any) => lookup('planned.OperateTypeDisplayMap', text),
    },
    {
      title: '操作结果',
      dataIndex: 'status',
      width: 200,
      render: (text) => StatusMap.get(text),
    },
    {
      title: '操作详情',
      dataIndex: 'successCount',
      width: 200,
      render: (_text, record) => getResult(record ?? {}),
    },
    {
      title: '详情',
      dataIndex: 'requestId',
      width: 100,
      fixed: 'right',
      render: (requestId, record) => {
        if ([StatusEnum.ING].includes(record?.status)) {
          return <span>查看</span>;
        }
        return <Link to={toLogDetailPlannedOrder(requestId)}>查看</Link>;
      },
    },
  ];

  const formatDataToQuery = (params: any) => {
    return { ...params, ...formateDateRangeForQuery2('createdAtFrom', 'createdAtTo', filterDate) };
  };

  return (
    <div
      style={{ height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}
    >
      <Space style={{ padding: 16 }} size={16}>
        <span>操作时间:</span>
        <DatePicker.RangePicker
          style={{ width: 300 }}
          value={filterDate}
          onChange={(value) => {
            setFilterDate(value);
          }}
        />
        <Button
          type="primary"
          onClick={() => {
            setResetRefreshMarker(Math.random());
            sessionStorage.setItem(FILTER_KEY, JSON.stringify(filterDate));
          }}
        >
          查询
        </Button>
      </Space>
      <RecordListLayout<RecordType>
        useQuickFilter={false}
        useColConfig={false}
        formatDataToQuery={formatDataToQuery}
        resetRefreshMarker={resetRefreshMarker}
        requestFn={fetchPlanOrderLaunchLogList}
        columns={columns}
      />
    </div>
  );
};

export default List;
