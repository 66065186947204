import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styles from 'src/page/organization/components/role.module.scss';
import { Button, Checkbox } from 'antd';
import {
  fetchPrivilegeListPrivilegesByRoleId,
  fetchPrivilegeSavePrivilegesByRoleId,
} from 'src/api/ytt/user-domain';
import { fetchBizOperationTree } from 'src/api/ytt/metadata-domain/biz_operation';
import usePrivilegeTree from './usePrivilegeTree';
import _ from 'lodash';
import { BlIcon } from 'components';

type FunctionAuthorityType = (props: {
  roleId: number;
  title?: string;
  disabled?: boolean;
  getDataSource?: (prop: any) => void;
}) => ReactElement;

const FunctionAuthorityContext: any = React.createContext(null);

/**
 * https://blacklake.feishu.cn/docs/doccnuclWl23IeGmWYxJelGxdoe
 *
 * 根据prd 以及 后端字段设置，组成一个map, checkbox 选择后校验是否有其他 checkbox 需要联动
 */
const map = new Map();

// 勾选【编辑/新建】权限，自动勾选【查看|菜单】
map.set(/(add|edit)$/, /(detail|view)$/);
// 勾选【设置/查看操作记录/详情/导入/导出】权限，自动勾选【菜单】
map.set(/(set|operrecord|detail|import|export)$/, /(view)$/);
map.set(/(config)$/, /(detail|view)$/);
// 单独给业务对象模块设置其他联动
map.set(/view_custom_objects/, /(view)$/);
map.set(/(add|edit)$/, /view_custom_objects/);
// 勾选【拆分/关闭/投放/计划确认】权限，自动勾选【查看|菜单】
map.set(/(split|close|launch|planned_confirm)$/, /(detail|view)$/);
// 勾选【预留.释放】权限，自动勾选【预留.查询】
map.set(/(PlanReserved.release)$/, /(detail)$/);
// 勾选【物料计划配置.计划/物料计划配置.移除】权限，自动勾选【物料计划配置.菜单】
map.set(/(MaterialPlanConfigura.planning_operation|MaterialPlanConfigura.remove)$/, /(view)$/);

const CheckList: React.FC<{ code: string; dataSource?: any[] }> = (props) => {
  const { code, dataSource } = props;
  const [options, setOptions] = useState<any[]>([]);
  const {
    selectedList,
    changeSelectedList,
    disabled: disabledProps = false,
  } = useContext(FunctionAuthorityContext);

  /**
   * 获取 checkbox 是否需要关注其他 checkbox， 并返回需要关注的列表
   * 返回依赖列表id
   */
  const getCheckboxDeps = useCallback((item: any, items: any): string[] => {
    const ret: any[] = [];

    map.forEach((target, key) => {
      if (target.test(`${code}.${item.code}`)) {
        items.forEach((_item: any) => {
          key.test(`${code}.${_item.code}`) && ret.push(_item.id);
        });
      }
    });
    return ret;
  }, []);

  /*
   * 当 checkbox 变化后，需要找到其他变化的列表放到 selectList 内
   */
  const findChangedList = useCallback(
    (item: any, items: any[] = []) => {
      const ret: any[] = [];

      map.forEach((target, key) => {
        if (key.test(`${code}.${item.code}`)) {
          options.forEach(
            (_item: any) =>
              target.test(`${code}.${_item.code}`) && ret.push(_.find(items, { code: _item.code })),
          );
        }
      });
      return ret;
    },
    [options],
  );

  const onChange = useCallback(
    (e, data) => {
      const { checked } = e.target;
      const list = checked ? findChangedList(data, dataSource) : [];

      changeSelectedList([..._.filter(dataSource, (i) => i.id === data.id), ...list], checked);
    },
    [changeSelectedList, dataSource, findChangedList],
  );

  useEffect(() => {
    const _options = _.map(dataSource, (item) => {
      const deps = getCheckboxDeps(item, dataSource);

      let disabled = false;

      if (!item?.enable || disabledProps) {
        disabled = true;
      } else {
        disabled = deps.some((id) => selectedList.has(id));
      }

      return {
        disabled,
        label: item.title,
        key: item.id,
        id: item.id,
        code: item.code,
        checked: selectedList.has(item.id),
      };
    });

    setOptions(_options as any[]);
  }, [dataSource, disabledProps, getCheckboxDeps, selectedList]);

  return (
    <div style={{ background: '#F5F5F5', marginLeft: 40, marginTop: 6 }}>
      {options.map((i) => (
        <Checkbox
          key={i.key}
          onChange={(e) => onChange(e, i)}
          style={{ padding: 8, width: '180px', marginLeft: 0 }}
          {...i}
        >
          {i.label}
        </Checkbox>
      ))}
    </div>
  );
};

const fineLeafItem = (dataSource: any, returnMap = false) => {
  const map = new Map();
  const dfs = (data: any) => {
    if (_.isEmpty(data.children)) {
      return (
        data?.items?.filter((i: any) => {
          if (i.enable) {
            map.set(i.id, i);
            return true;
          }
          return false;
        }) || []
      );
    }

    let ret: any = [];

    _.forEach(data.children, (i) => {
      ret = [...ret, ...dfs(i)];
    });

    return ret;
  };

  const result = dfs(dataSource);

  if (returnMap) {
    return map;
  }
  return result;
};

const Items: React.FC<{ dataSource: any; isFistLevel?: boolean }> = (props) => {
  const { dataSource, isFistLevel } = props;
  const [checked, setChecked] = useState(false);
  const [toggleOpen, setToggleOpen] = useState(false);
  const {
    changeSelectedList,
    selectedList,
    disabled: disabledProps = false,
  } = useContext(FunctionAuthorityContext);
  const [disabled] = useState(dataSource.enable === false ? true : disabledProps);
  const [childrenMap, setChildrenMap] = useState(new Map());
  const [indeterminate, setIndeterminate] = useState(false);

  useEffect(() => {
    const result = fineLeafItem(dataSource, true);

    setChildrenMap(result);
  }, [dataSource]);

  const onChange = useCallback(
    (e) => {
      setChecked((prev) => !prev);

      changeSelectedList(Array.from(childrenMap.values()), e.target.checked);
    },
    [changeSelectedList, childrenMap],
  );

  useEffect(() => {
    let hasAll = false; // 是否所有 children 都被选中
    let hasSome = false; // 是否有 child 被选中

    if (childrenMap.size > 0 && selectedList.size > 0) {
      hasAll = true;
      hasSome = false;

      childrenMap.forEach((i) => {
        if (selectedList.has(i.id)) {
          hasSome = true;
        } else {
          hasAll = false;
        }
      });

      if (hasAll) {
        hasSome = false;
        setChecked(hasAll);
        setIndeterminate(hasSome);
      } else if (hasSome) {
        setIndeterminate(hasSome);
      } else {
        setChecked(hasAll);
        setIndeterminate(hasSome);
      }
    } else {
      setChecked(hasAll);
      setIndeterminate(hasSome);
    }
  }, [childrenMap, selectedList]);

  const onToggleOpen = useCallback(() => {
    setToggleOpen((prev) => !prev);
  }, []);

  return (
    <div style={{ paddingLeft: isFistLevel ? 0 : 26, paddingTop: 4 }}>
      <div>
        <BlIcon
          onClick={onToggleOpen}
          type={toggleOpen ? 'iconxiang-xia' : 'iconxiang-you'}
          style={{ cursor: 'pointer', paddingRight: '4px', fontSize: '12px' }}
        />
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          disabled={disabled}
          onChange={onChange}
        />
        <span onClick={onToggleOpen} style={{ cursor: 'pointer', paddingLeft: 10 }}>
          {dataSource?.title}
        </span>
      </div>
      {toggleOpen
        ? (dataSource.children || []).map((item: any) => <Items dataSource={item} key={item.key} />)
        : null}
      {toggleOpen && dataSource?.items ? (
        <CheckList code={dataSource?.code} dataSource={dataSource?.items} />
      ) : null}
    </div>
  );
};

const Content = (props: any) => {
  const { title, roleId, getDataSource } = props;
  const { handleAllItems, changeSelectedList, disabled, selectedList } =
    useContext(FunctionAuthorityContext);
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [curServeDataSource, setCurServerDataSource] = useState<any>([]);
  const { domTree, selectItems }: any = usePrivilegeTree(fetchBizOperationTree, disabled, roleId);

  useEffect(() => {
    if (roleId) {
      fetchPrivilegeListPrivilegesByRoleId({ type: 1, roleId }).then((res) => {
        const result = _.get(res, 'data', []);

        setCurServerDataSource(result.map((i: any) => i.id));
        changeSelectedList(result, true);
      });
    }
  }, [changeSelectedList, roleId]);

  useEffect(() => {
    handleAllItems(selectItems);
  }, [handleAllItems, selectItems]);

  useEffect(() => {
    if (getDataSource) {
      getDataSource({ disableSave });
    }
  }, [disableSave, getDataSource]);

  useEffect(() => {
    if (curServeDataSource.length !== selectedList.size) {
      setDisableSave(false);
    } else {
      let hasTotal = true;

      for (const el of selectedList.values()) {
        if (curServeDataSource.indexOf(el.id) === -1) {
          hasTotal = false;
          break;
        }
      }

      setDisableSave(hasTotal);
    }
  }, [curServeDataSource, selectedList]);

  const handleSubmit = useCallback(() => {
    // if (selectedList.size === 0) {
    //   message.error('必须选择一个权限功能点');
    //   return;
    // }

    const _selectedList = Array.from(selectedList.values()).map((i: any) => i.id);

    fetchPrivilegeSavePrivilegesByRoleId({
      roleId,
      privilegeIdList: _selectedList,
    }).then(() => {
      setCurServerDataSource(_selectedList);
    });
  }, [roleId, selectedList]);

  return (
    <div className={styles.panelContent}>
      <div className={styles.titleContent}>
        <span className={styles.authTitle}>{title}</span>
        {disabled ? null : (
          <Button disabled={disableSave} type="primary" onClick={handleSubmit}>
            保存
          </Button>
        )}
      </div>
      <div style={{ overflowY: 'scroll', height: 400, paddingRight: 10 }}>
        {(domTree || []).map((item: any) => (
          <Items dataSource={item} key={item.key} isFistLevel />
        ))}
      </div>
    </div>
  );
};

const FunctionAuthority: FunctionAuthorityType = (props) => {
  const { disabled = false } = props;
  const [selectedList, setSelectedList] = useState<any>(new Map());
  const [, setAllItems] = useState([]);

  const changeSelectedList = useCallback((dataSource, isAdd) => {
    setSelectedList((prev: any) => {
      const map = _.cloneDeep(prev);

      dataSource.forEach((i: any) => {
        if (isAdd) {
          map.set(i.id, i);
        } else {
          map.delete(i.id);
        }
      });

      return map;
    });
  }, []);

  const handleAllItems = useCallback((_allItems) => {
    setAllItems(_allItems);
  }, []);

  return useMemo(
    () => (
      <FunctionAuthorityContext.Provider
        value={{ selectedList, changeSelectedList, handleAllItems, disabled }}
      >
        <Content {...props} />
      </FunctionAuthorityContext.Provider>
    ),
    [changeSelectedList, disabled, handleAllItems, props, selectedList],
  );
};

export default React.memo(FunctionAuthority);
