import JSEncrypt from 'jsencrypt';

/**
 * string 判断是否为空字符
 * @param value
 * @returns
 */
const isEmpty = (value: string) => {
  if (value === '' || value === null || value === undefined) {
    return true;
  }
  return false;
};
/**
 * 字符串超过长度后加省略号
 * @param {*} toString 目标字符串
 * @param {*} length 需要省略的长度
 */
const stringEllipsis = (toString: any, length: number) => {
  const string = String(toString);

  if (!string || string.length < length) {
    return string;
  }
  return `${string.substring(0, length - 3)}...`;
};

/**
 * 格式化文件名为对象 { name: '', type: '.jgp}
 * @param {*} fileName 文件名
 */
const parseFileName = (fileName: string): ParseFileName => {
  const index = Number(fileName && fileName.lastIndexOf('.'));

  if (!fileName || index === -1) {
    return {
      name: undefined,
      type: undefined,
    };
  }
  return {
    name: fileName.substring(0, index),
    type: fileName.substring(index + 1, fileName.length),
  };
};

/**
 * 密码解密
 * @param {*} password 密码
 */
const hashPassword = (password: string) => {
  return window.argon2.hash({
    pass: password,
    salt: 'tk7zprcUTEJiV3Ti4YFnjzNptsIpSOka',
    hashLen: 128,
    parallelism: 2,
    time: 5,
    mem: 16 * 1024,
    type: window.argon2.ArgonType.Argon2id,
  });
};

/** login模块专用  */
// 参考 https://yq.aliyun.com/articles/663288
/**
 * @description 密码解密
 * @param publicKey
 * @param password
 * @returns
 */
const rsaPassword = (publicKey: string, password: string) => {
  const encrypt = new JSEncrypt({});

  encrypt.setPublicKey(publicKey);
  return encrypt.encrypt(password);
};

/**
 * @description 密码校验
 * @param password 密码 string
 */
const testPassword = (password = '') => {
  const symbol = '~!@#$%^&*()_+`-={}|:"<>?[]\\;\',./';
  const PASSWORD_REG = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[^]{3,}$/;
  const CHINESE_SPACE_REG = /[\s\u4e00-\u9fa5]/; // 中文和空格
  const ENGLISH_NUMBER_REG = /[a-zA-Z0-9]/; // 英文和数字
  const ret = {
    result: true,
    msg: '',
  };

  const _password = password ?? '';
  const passwordAry = _password.split('');

  if (_password.length < 8) {
    ret.result = false;
    ret.msg = '密码至少8位';
    return ret;
  }
  if (!PASSWORD_REG.test(_password)) {
    ret.result = false;
    ret.msg = '密码必须包含数字、大写、小写字母';
    return ret;
  }
  if (CHINESE_SPACE_REG.test(_password)) {
    ret.result = false;
    ret.msg = '密码不支持中文、空格、特殊字符';
    return ret;
  }

  for (let index = 0; index < passwordAry.length; index++) {
    const item = passwordAry[index];

    if (!ENGLISH_NUMBER_REG.test(item) && symbol.indexOf(item) === -1) {
      ret.result = false;
      ret.msg = '密码不支持中文、空格、特殊字符';
      break;
    }
  }

  return ret;
};

/**
 * @description 判断字符串是否为json
 * @param str
 * @returns
 */
const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * @description 替换字符串指定位置和数量
 * @param {*} str 目标字符串
 * @param {*} index 起始位置
 * @param {*} length 替换数量
 * @param {*} replacement 替换字符
 */
const replaceStr = (str: string, index = 0, length = 0, replacement: any) => {
  if (!str || typeof str !== 'string' || str.length <= index + length) {
    return str ?? '';
  }

  const strHead = str.substring(0, index);
  const strBody = Array(length).fill(replacement).join('');
  const strTail = str.substring(index + length);

  return `${strHead}${strBody}${strTail}`;
};

interface ParseFileName {
  name: string | undefined;
  type: string | undefined;
}
interface TestPassword {
  result: boolean;
  msg: string;
}
interface FuncProps {
  stringEllipsis: (s: string, length: number) => string;
  parseFileName: (s: string) => ParseFileName;
  hashPassword: (s: string) => string;
  rsaPassword: (publicKey: string, password: string) => string | false;
  testPassword: (password: string) => TestPassword;
  isJson: (s: string) => boolean;
  replaceStr: (str: string, index: number, length: number, replacement: string) => string;
  isEmpty: (str: string) => boolean;
}

const _String = {} as FuncProps;

_String.stringEllipsis = stringEllipsis;
_String.parseFileName = parseFileName;
_String.hashPassword = hashPassword;
_String.rsaPassword = rsaPassword;
_String.testPassword = testPassword;
_String.isJson = isJson;
_String.replaceStr = replaceStr;
_String.isEmpty = isEmpty;

export default _String;
