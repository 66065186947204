import { useEffect, useRef, useState } from 'react';
import { Form, Checkbox, Button, Input, Select, message as Message } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { RouteComponentProps } from 'react-router-dom';
import {
  validateBlTextAndWhiteSpace,
  validateBlText1AndWhiteSpace,
} from 'src/page/knowledgeManagement/share/warehouseRules';
import {
  fetchPurchaseOrderUpdate,
  fetchPurchaseOrderCreate,
  fetchPurchaseOrderDetail,
  fetchPurchaseOrderItemDetail,
  fetchPurchaseOrderOutsourceMaterialListDetail,
} from 'src/api/ytt/order-domain/purchaseOrder';
import { fetchCustomFieldInstanceGetByObjectCode } from 'src/api/ytt/custom-object-domain';
import { BcAttachmentForm, SearchSelect, NumberRulesStandardHook } from 'src/components';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import _Time from 'src/utils/dataTypes/time';
import {
  PurchaseExecStatus,
  PurchaseOrderdeliveryMode,
  PurchaseOrderMaterialCarryMode,
  PurchaseSource,
} from 'src/dict/purchase';
import {
  purchaseSource,
  purchaseOrderdeliveryMode,
  purchaseOrderMaterialCarryMode,
} from 'src/dict/purchase/mappings';
import { toList } from './navigate';
import { SupplyRegStatus } from 'src/dict/supplier';
import { UsageStatus } from 'src/dict/common';
import MaterialFormTable from './components/materialTable';
import MaterialTab from './components/materialTab';
import _ from 'lodash';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import {
  injectCustomFieldInfos,
  formatCustomFieldsInData,
  initCustomFieldsInData,
  useCustomFieldCombinedData,
} from 'src/components/customField';
import { appEnum } from 'src/dict';
import { initEditData, formatDetailItemDataToForm, formatDetailOutItemDataToForm } from './utils';
import { handleSwich } from './batchOperate';
import {
  FetchPurchaseOrderItemDetailResponseData,
  FetchPurchaseOrderOutsourceMaterialListDetailResponseData,
} from './interface';

const { TextArea } = Input;

interface OperatePurchaseOrderProps {
  id?: string;
}

const CreateAndEditPurchaseOrder = (props: RouteComponentProps<OperatePurchaseOrderProps>) => {
  const { history, match } = props;

  // 正在保存数据，用来保存期间disable保存按钮
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  // 是否连续新建
  const [continueToCreate, setContinueToCreate] = useState(true);
  const [orderStatus, setOrderStatus] = useState<number>(PurchaseExecStatus.created);
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.purchaseOrder);
  const itemCustomFields = useCustomFieldCombinedData(OBJECT_OF_CODE.purchaseOrderItem);
  const outItemCustomFields = useCustomFieldCombinedData(OBJECT_OF_CODE.outsourceMaterialList);
  const [carryMode, setCarryMode] = useState<PurchaseOrderMaterialCarryMode>();
  const isCreating = history.location.pathname.includes('create');
  const [refreshMarker, setRefreshMarker] = useState<number>();

  const currentId = Number(match.params.id);

  const formDataBefore = useRef<any>();

  const [form] = Form.useForm();
  const setFormItem = (
    materialLine:
      | FetchPurchaseOrderItemDetailResponseData
      | FetchPurchaseOrderOutsourceMaterialListDetailResponseData
      | undefined,
    name: string,
  ) => {
    if (materialLine) {
      const afterFormatInitialValue =
        name == 'itemList'
          ? formatDetailItemDataToForm(materialLine)
          : formatDetailOutItemDataToForm(materialLine);
      const afterFormatCustomFieldsValue = initCustomFieldsInData(afterFormatInitialValue);

      form.setFieldsValue({
        [name]: afterFormatCustomFieldsValue,
      });
    } else {
      form.setFieldsValue({
        [name]:
          name == 'itemList'
            ? [
                {
                  seqNum: 1,
                  demandTime: _Time.daysAfter(7),
                },
              ]
            : [],
      });
    }
  };
  const fetchData = async () => {
    try {
      form.setFieldsValue({ source: null });
      setLoading(true);
      const [{ data }, { data: materialLine }, { data: outMaterialLine }] = await Promise.all([
        fetchPurchaseOrderDetail({ id: currentId! }),
        fetchPurchaseOrderItemDetail({ id: currentId! }),
        fetchPurchaseOrderOutsourceMaterialListDetail({ id: currentId! }),
      ]);

      setLoading(false);
      if (data) {
        if (customFields) {
          const afterFormatCustomFielsValue = initCustomFieldsInData(initEditData(data));

          form.setFieldsValue(afterFormatCustomFielsValue);
        } else {
          form.setFieldsValue(initEditData(data));
        }
        setFormItem(materialLine, 'itemList');
        setFormItem(outMaterialLine, 'outItemList');
        setOrderStatus(data.execStatus as number);
        setCarryMode(data.materialCarryMode as number);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    currentId && fetchData(); // 编辑
    if (!currentId) {
      form.setFieldsValue({
        itemList: [
          {
            seqNum: 1,
            demandTime: _Time.daysAfter(7),
          },
        ],
      });
    }
  }, [currentId]);

  const handleFinish = async () => {
    try {
      // 表单校验
      await form.validateFields();

      const { supplier, currency, owner, itemList, outItemList, ...formData } =
        form.getFieldsValue();

      const formatItemList = itemList.map((value: any, index: number) => {
        value.demandTime = value?.demandTime.valueOf();
        value.materialId = JSON.parse(value.materialId.value).materialId;
        value.seqNum = index + 1; // 处理一下行号
        value.unitPrice = value.unitPrice ? Number(value.unitPrice) : undefined;
        value.taxRate = value.taxRate ? Number(value.taxRate) : undefined;
        value.totalPrice = value.totalPrice ? Number(value.totalPrice) : undefined;
        value.bomVersion = value.bomVersion?.label;
        return formatCustomFieldsInData({
          data: value,
          customFields: itemCustomFields,
        });
      });
      const formatOutItemList = outItemList?.map((value: any, index: number) => {
        const fortmatValue = {
          demandAmount: value.demandAmount.amount,
          inputAmountDenominator: value.inputAmountDenominator,
          inputAmountNumerator: value.inputAmountNumerator,
          lineNo: value.purchaseOrderItemLineNo,
          purchaseOrderItemId: value.purchaseOrderItemId,
          materialId: value.material.baseInfo.id,
          lossRate: value.lossRate,
          seq: value.seq,
          standardDosage: value.standardDosage.amount,
          unitId: value.unit.id,
          customFields: value.customFields,
        };

        return formatCustomFieldsInData({
          data: fortmatValue,
          customFields: outItemCustomFields,
        });
      });
      const value = formatCustomFieldsInData({
        data: formData,
        customFields,
      });

      const requestParam = {
        id: currentId,
        supplierId: supplier?.value,
        currencyId: JSON.parse(currency?.value || '{}')?.id,
        ownerId: owner.id,
        itemList: formatItemList,
        outsourceMaterialLists: formatOutItemList,
        ...value,
      };

      const requestFn = isCreating ? fetchPurchaseOrderCreate : fetchPurchaseOrderUpdate;

      await requestFn(requestParam);
      Message.success('保存成功');
      // 连续新建则清空表单
      if (continueToCreate && isCreating) {
        form.resetFields();
        setRefreshMarker(Date.now());
        form.setFieldsValue({
          itemList: [
            {
              seqNum: 1,
              demandTime: _Time.daysAfter(7),
            },
          ],
        });
        setCarryMode(PurchaseOrderMaterialCarryMode.notCarry);
      } else {
        // 否则返回列表
        history.push(toList());
      }
    } finally {
      setIsSaving(false);
    }
  };

  const extra = (
    <>
      {isCreating && (
        <Checkbox
          onChange={() => {
            setContinueToCreate(!continueToCreate);
          }}
          defaultChecked={continueToCreate}
        >
          连续新建
        </Checkbox>
      )}
    </>
  );

  // 修改原因字段
  const editReasonFormItems = {
    label: '编辑原因',
    name: 'editReason',
    rules: [
      { max: 255, message: '不可超过255个字符' },
      { validator: validateBlTextAndWhiteSpace() },
    ],
    render: () => <TextArea showCount maxLength={255} placeholder="非必填" allowClear />,
  };
  const changeCurrency = (value: any) => {
    if (!value) {
      clearCurrency();
      return;
    }
    function handleOk() {
      // 进行切换,清空单价金额字段
      formDataBefore.current?.itemList?.map((a: any) => {
        a.unitPrice = null;
        a.totalPrice = null;
      });
      if (formDataBefore.current) {
        formDataBefore.current.currency = value;
      }
      form.setFieldsValue(formDataBefore.current);
    }
    function handleCancel() {
      // 不切换，保留原值
      form.setFieldsValue(formDataBefore.current);
    }
    const content = '切换币种后将清空当前订单的所有单价、金额字段，是否确认删除？';

    if (formDataBefore.current.currency) {
      handleSwich(content, handleOk, handleCancel);
    }
  };
  const clearCurrency = () => {
    function handleOk() {
      // 进行切换,清空单价金额字段
      formDataBefore.current?.itemList?.map((a: any) => {
        a.unitPrice = null;
        a.totalPrice = null;
      });
      if (formDataBefore.current) {
        formDataBefore.current.currency = null;
      }
      form.setFieldsValue(formDataBefore.current);
    }
    function handleCancel() {
      // 不切换，保留原值
      form.setFieldsValue(formDataBefore.current);
    }
    const content = '清空币种后将清空当前订单的所有单价、金额字段，是否确认删除？';

    handleSwich(content, handleOk, handleCancel);
  };
  const carryModeChange = (val: number) => {
    setCarryMode(val);

    if (val == PurchaseOrderMaterialCarryMode.notCarry) {
      const formCurrent = form.getFieldsValue();

      formCurrent.itemList.map((item: any) => {
        item.bomVersion = null;
      });
      formCurrent.outItemList = [];
      form.setFieldsValue(formCurrent);
    }
  };
  const materialInfo: DataFormLayoutInfoBlock = {
    title: '物料信息',
    items: [
      {
        label: '',
        isFullLine: true,
        render: () => {
          return carryMode == PurchaseOrderMaterialCarryMode.carry ? (
            <MaterialTab
              form={form}
              itemCustomFields={itemCustomFields}
              outItemCustomFields={outItemCustomFields}
              orderStatus={orderStatus}
              edit={!isCreating}
              carryMode={carryMode}
            />
          ) : (
            <MaterialFormTable
              form={form}
              customFields={itemCustomFields}
              orderStatus={orderStatus}
              name="itemList"
              edit={!isCreating}
              carryMode={carryMode}
            />
          );
        },
      },
    ],
  };
  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    column: 3,
    items: [
      ...NumberRulesStandardHook({
        label: '编号',
        name: 'code',
        form,
        edit: !isCreating,
        objectCode: OBJECT_OF_CODE.purchaseOrder,
        fieldCode: 'code',
        rules: [
          { validator: validateBlText1AndWhiteSpace() },
          { max: 255, message: '不可超过255个字符' },
        ],
        refreshMarker,
        disabledState: !isCreating,
      }),
      {
        label: '来源',
        name: 'source',
        rules: [{ required: true, message: '请选择来源' }],
        render: () => (
          <Select
            options={purchaseSource}
            placeholder="请选择"
            disabled={orderStatus !== PurchaseExecStatus.created}
            onFocus={() => {
              // 获取form表单值
              formDataBefore.current = form.getFieldsValue();
            }}
            onChange={(value) => {
              function handleOk() {
                // 进行切换,清空供应商和物料行
                form.setFieldsValue({
                  supplier: undefined,
                  itemList: [
                    {
                      seqNum: 1,
                      demandTime: _Time.daysAfter(7),
                    },
                  ],
                  deliveryMode: PurchaseOrderdeliveryMode.purchaseOrder,
                });
                if (value === PurchaseSource.common) {
                  form.setFieldsValue({ deliveryMode: PurchaseOrderdeliveryMode.purchaseOrder });
                }
              }
              function handleCancel() {
                // 不切换，保留原值
                form.setFieldsValue(formDataBefore.current);
              }
              const content = '切换来源后将清空当前订单的供应商及物料信息，是否确认清空？';
              const hasValue = () => {
                // 供应商和物料行 是否有值
                const source = !_.isUndefined(formDataBefore.current?.source);
                const supplier = !_.isUndefined(formDataBefore.current?.supplier);
                const materials = !_.isUndefined(formDataBefore.current?.itemList?.[0]?.materialId);

                return (source && supplier) || (source && materials);
              };

              if (formDataBefore.current?.source !== value && hasValue()) {
                handleSwich(content, handleOk, handleCancel);
              } else if (value === PurchaseSource.common) {
                form.setFieldsValue({ deliveryMode: PurchaseOrderdeliveryMode.purchaseOrder });
              } else {
                form.setFieldsValue({ deliveryMode: null });
              }
            }}
          />
        ),
      },
      {
        dependencies: ['source'],
        noStyle: true,
        render: (FormItemProps: any) => () => {
          const source = form.getFieldValue('source');
          const haveSource = typeof source === 'number' || source === null; // 编辑时
          const params =
            source === appEnum.Purchase.PurchaseSource.collaborate
              ? { regStatus: [SupplyRegStatus.registered], status: [UsageStatus.enabled] }
              : { status: [UsageStatus.enabled] };

          return (
            <Form.Item
              label={'供应商'}
              name={'supplier'}
              rules={[{ required: true, message: '请选择供应商' }]}
              {...FormItemProps}
            >
              <SearchSelect
                fetchType={'supplier'}
                style={{ width: '100%' }}
                labelInValue
                params={params}
                disabled={orderStatus !== PurchaseExecStatus.created || !haveSource}
                onFocus={() => {
                  // 获取form表单值
                  formDataBefore.current = form.getFieldsValue();
                }}
                onChange={(value) => {
                  function handleOk() {
                    // 进行切换,清空物料行
                    form.setFieldsValue({
                      itemList: [
                        {
                          seqNum: 1,
                          demandTime: _Time.daysAfter(7),
                        },
                      ],
                    });
                  }
                  function handleCancel() {
                    // 不切换，保留原值
                    form.setFieldsValue(formDataBefore.current);
                  }
                  const content = '切换供应商后将清空当前物料信息，是否确认清空？';
                  const hasValue = () => {
                    // 物料行 是否有值

                    const materials = !_.isUndefined(
                      formDataBefore.current?.itemList?.[0].materialId,
                    );

                    return materials;
                  };

                  if (formDataBefore.current?.supplier !== value && hasValue()) {
                    handleSwich(content, handleOk, handleCancel);
                  }
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        dependencies: ['source'],
        noStyle: true,
        render: (FormItemProps: any) => () => {
          return (
            <Form.Item
              label={'带料方式'}
              name={'materialCarryMode'}
              rules={[{ required: true, message: '请选择带料方式' }]}
              {...FormItemProps}
            >
              <Select
                options={purchaseOrderMaterialCarryMode}
                allowClear
                placeholder="请选择"
                disabled={orderStatus !== PurchaseExecStatus.created}
                onChange={carryModeChange}
              />
            </Form.Item>
          );
        },
      },
      {
        label: '订单所有人',
        name: 'owner',
        rules: [{ required: true, message: '请选择订单所有人' }],
        getValueProps: (value?: any) => {
          if (value) {
            return { value: [_.pick(value, ['id', 'name'])] };
          }
          return {};
        },
        getValueFromEvent: (value) => {
          return value ? value[0] : '';
        },
        render: () => (
          <UserOrDepartmentSelectWithDialog<UserColumnProps>
            isNewFormat
            isMultiple={false}
            isSelectUser
            disabled={orderStatus !== PurchaseExecStatus.created}
          />
        ),
      },
      {
        dependencies: ['source'],
        noStyle: true,
        render: (FormItemProps: any) => () => {
          const source = form.getFieldValue('source');

          return (
            <Form.Item
              label={'交货方式'}
              name={'deliveryMode'}
              rules={[{ required: true, message: '请选择订单交货方式' }]}
              {...FormItemProps}
            >
              <Select
                options={purchaseOrderdeliveryMode}
                allowClear
                placeholder="请选择"
                disabled={
                  orderStatus !== PurchaseExecStatus.created || source === PurchaseSource.common
                }
              />
            </Form.Item>
          );
        },
      },
      {
        label: '币种',
        name: 'currency',
        render: () => (
          <SearchSelect
            onChange={changeCurrency}
            disabled={orderStatus !== PurchaseExecStatus.created}
            fetchType={'currency'}
            onMouseEnter={() => {
              formDataBefore.current = form.getFieldsValue();
            }}
          />
        ),
      },
      {
        label: '订单备注',
        name: 'remark',
        rules: [
          { max: 1000, message: '不超过1000个字符' },
          { validator: validateBlTextAndWhiteSpace() },
        ],
        render: () => <TextArea showCount rows={1} placeholder="非必填" allowClear />,
      },
      {
        label: '订单要求',
        name: 'requirement',
        rules: [
          { max: 1000, message: '不超过1000个字符' },
          { validator: validateBlTextAndWhiteSpace() },
        ],
        render: () => (
          <TextArea
            disabled={orderStatus !== PurchaseExecStatus.created}
            showCount
            rows={1}
            placeholder="非必填"
            allowClear
          />
        ),
      },
      {
        label: '附件',
        name: 'attachments',
        render: () => <BcAttachmentForm form={form} />,
      },
      {
        label: '协同附件',
        name: 'coordinationAttachments',
        render: () => (
          <BcAttachmentForm disabled={orderStatus !== PurchaseExecStatus.created} form={form} />
        ),
      },
      ...(!isCreating ? [editReasonFormItems] : []),
    ],
  };

  const footer = (
    <>
      <Button
        onClick={() => {
          history.push(toList());
        }}
      >
        取消
      </Button>
      <Button type="primary" loading={isSaving} onClick={handleFinish}>
        保存
      </Button>
    </>
  );

  return (
    <DataFormLayout
      form={form}
      title={`${isCreating ? '新建' : '编辑'}采购订单`}
      info={[
        baseInfo,
        injectCustomFieldInfos({
          customFields,
          type: 'form',
          formConfig: { form }, // form相关配置
          disabled: !isCreating && orderStatus !== PurchaseExecStatus.created,
        }),
        materialInfo,
      ]}
      footer={footer}
      loading={loading}
      extra={isCreating && extra}
    />
  );
};

export default CreateAndEditPurchaseOrder;
