import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form, message as Message, Checkbox } from 'antd';
import CreateAndEditBaseInfo from './components/createAndEditBaseInfo';
import { ShiftId, ShiftRuleInfoCreateCOS } from './interface';
import { toSchedulingList } from '../shift/navigation';
import { fetchShiftRuleCreate, fetchShiftRuleGet } from 'src/api/ytt/calendar-domain';
import { throttleFn } from '../../warehouse/utils';

interface createProps extends RouteComponentProps {
  location: any;
}

const CreatScheduling = (props: createProps) => {
  const { location, history } = props;

  const { state } = location;

  const [createForm] = Form.useForm();

  const [continuousCreate, setContinuousCreate] = useState(false); // 连续新建

  const [detailData, setDetailData] = useState({} as any);
  const [stateId, setStateId] = useState(0);

  const [batched, setBatched] = useState(false);
  const [batchValue, setBatchValue] = useState<ShiftId>({} as any);

  const handleSetBatched = (batch: boolean) => {
    setBatched(batch);
  };
  const handleSetBatchValue = (batchValue: ShiftId) => {
    setBatchValue(batchValue);
  };

  const handleFinish = async () => {
    try {
      const value = await createForm?.validateFields();

      const { shiftRuleInfoCreateCOS } = value;

      const _shiftRuleInfoCreateCOS = shiftRuleInfoCreateCOS?.map(
        (node: ShiftRuleInfoCreateCOS) => {
          return {
            ...node,
            shiftId: node?.shiftId?.key,
          };
        },
      );

      const { data, message } = await fetchShiftRuleCreate({
        ...value,
        shiftRuleInfoCreateCOS: _shiftRuleInfoCreateCOS,
      });

      if (data) {
        Message.success('创建成功');
        if (continuousCreate) {
          createForm.resetFields();
          setBatched(false);
          setStateId(0);
          setBatchValue({} as any);
          return;
        }
        history.push(toSchedulingList());
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  const extra = (
    <Checkbox
      onChange={(e) => {
        setContinuousCreate(e?.target?.checked ?? false);
      }}
    >
      连续新建
    </Checkbox>
  );

  const fetchData = async () => {
    try {
      const { data } = await fetchShiftRuleGet({ id: state }, { legacy: true });

      if (data) {
        setDetailData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (state) {
      setStateId(state);
      fetchData();
    }
  }, [state]);

  return (
    <CreateAndEditBaseInfo
      onFinish={throttleFn(handleFinish)}
      onCancel={() => {
        history.push(toSchedulingList());
      }}
      title={stateId ? '复制排班规则' : '新建排班规则'}
      form={createForm}
      extra={extra}
      edit={false}
      initialData={detailData}
      batched={batched}
      batchValue={batchValue}
      handleSetBatched={handleSetBatched}
      handleSetBatchValue={handleSetBatchValue}
    />
  );
};

export default CreatScheduling;
