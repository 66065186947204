export const BASE_URL = '/productionPlanning/engineering/process';

export const toProcessDetail = (id: number) => {
  return `${BASE_URL}/${id}/detail`;
};
export const toProcessEdit = (id: number) => {
  return `${BASE_URL}/${id}/edit`;
};
export const toProcessCreate = () => {
  return `${BASE_URL}/create`;
};

export const toProcessList = () => {
  return `${BASE_URL}`;
};
