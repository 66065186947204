import React, { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import _ from 'lodash';
import { DetailLayoutInfoBlock, DetailLayout, DetailLayoutInfoItem } from 'layout';
import { message } from 'antd';
import {
  fetchRepairTaskDetail,
  fetchRepairTaskCancel,
} from 'src/api/ytt/resource-domain/repairTask';
import { AvatarDisplay } from 'src/components/avatar/show';
import TagTooltip from 'src/components/tooltip/tagTooltip';
import { BcAttachmentDetail, BlIcon, SingleOperationLogComponent } from 'components';
import { gcTime } from 'src/utils';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { valueOrHolder } from 'src/utils/formatters';
import lookup, { appEnum } from 'src/dict';
import { RepairTaskSource } from 'src/dict/resources';
import { replaceSign } from 'src/utils/constants';
import renderQrCode from 'src/page/share/renderQrCode';
import { renderUserArray } from 'src/page/share/renderUser';
//
import { RepairTaskDetailType } from '../index.d';
import { goToEdit, getDetailPath } from '../navigates';
import { BL_BUSINESS_NAME } from '../config';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { ValueType } from 'src/dict/types';
import AutoTimer from '../components/autoTimer';
import TimeOutRecord from '../list/timeOutRecord';
import { fieldLabels } from '../constants';
import MalfunctionTagTable from './MalfunctionTagTable';

interface DetailContentProps extends RouteChildrenProps<{ id: string }> {}

const Detail = (props: DetailContentProps) => {
  const { match, history } = props;
  const [detailData, setDetailData] = useState<RepairTaskDetailType>();
  const [loading, setLoading] = useState(false);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [timeOutLog, setTimeOutLog] = useState<boolean>(false);

  const { status } = detailData ?? {};

  const isCanceled = status === appEnum.Resources.RepairTaskStatus.canceled;
  const isEnded = status === appEnum.Resources.RepairTaskStatus.ended;
  const isNotStarted =
    status === appEnum.Resources.RepairTaskStatus.notStarted ||
    status === appEnum.Resources.RepairTaskStatus.toExcute;
  const isExecuting = status === appEnum.Resources.RepairTaskStatus.executing;
  const isSuspended = status === appEnum.Resources.RepairTaskStatus.suspended;

  const fetchAndSetData = (id?: string) => {
    if (id) {
      setLoading(true);
      return fetchRepairTaskDetail({ id: _.toNumber(id) }).then((res) => {
        const { data } = res;

        data && setDetailData(data);

        return res;
      });
    }

    return Promise.reject();
  };

  useEffect(() => {
    fetchAndSetData(match?.params?.id)
      .catch(() => {
        setTimeout(() => {
          history.goBack();
        }, 500);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const baseMenu = () => {
    const { id, status } = detailData ?? {};

    if (_.isNil(status) || _.isNil(id)) return [];

    const buttonList = [
      {
        title: '取消',
        key: 'cancel',
        auth: authDict.repairTask_cancel,
        disabled: isCanceled || isEnded,
        onClick: () => {
          fetchRepairTaskCancel({ ids: [id] }).then((res) => {
            if (res.code === 200) {
              message.success('取消成功!');
              fetchAndSetData(match?.params?.id).finally(() => {
                setLoading(false);
              });
            }
          });
        },
      },
      {
        title: '暂停记录',
        key: 'pauseRecord',
        auth: authDict.repairTask_pause_record,
        onClick: () => {
          setTimeOutLog(true);
        },
      },
      {
        title: '操作记录',
        key: 'operRecord',
        auth: authDict.repairTask_operrecord,
        onClick: () => {
          setVisibleLog(true);
        },
      },
      {
        title: '编辑',
        key: 'edit',
        auth: authDict.repairTask_edit,
        type: 'primary',
        icon: <BlIcon type="iconbianji" />,
        disabled: !isNotStarted,
        onClick: () => {
          goToEdit(id);
        },
      },
    ];

    return _.compact(buttonList);
  };

  const baseInfo = (): DetailLayoutInfoBlock => {
    const baseItems: DetailLayoutInfoItem[] = _.compact([
      { label: fieldLabels.code, dataIndex: 'code' },
      { label: fieldLabels.name, dataIndex: 'name' },
      {
        label: fieldLabels.repairPlan,
        dataIndex: ['repairCase', 'name'],
      },
      {
        label: fieldLabels.identificationCode,
        dataIndex: 'identificationCode',
        render: (identificationCode: RepairTaskDetailType['identificationCode']) =>
          valueOrHolder(identificationCode, () => renderQrCode(identificationCode) ?? ''),
      },
      {
        label: fieldLabels.businessObject,
        dataIndex: 'bizType',
        render: (type: boolean | ValueType | undefined) =>
          valueOrHolder(lookup('resources.RESORCE_BUSINESS_TYPE_MAP', type)),
      },
      {
        label: fieldLabels.repairTarget,
        dataIndex: ['bizVO', 'name'],
      },
      {
        label: fieldLabels.repairTargetCode,
        dataIndex: ['bizVO', 'code'],
      },
      {
        label: fieldLabels.repairTargetArea,
        dataIndex: ['bizVO', 'location', 'name'],
      },

      {
        label: fieldLabels.channelType,
        dataIndex: 'channelType',
        render: (type: boolean | ValueType | undefined) =>
          valueOrHolder(lookup('resources.repairChannel', type)),
      },
      {
        label: fieldLabels.repairRequestor,
        dataIndex: 'reporterList',
        render: (reporterList: RepairTaskDetailType['reporterList']) =>
          renderUserArray(
            reporterList?.map((reporter) => ({
              id: reporter.id!,
              name: reporter.name!,
              avatarUrl: reporter.avatarUrl,
            })),
            2,
          ),
      },
      {
        label: fieldLabels.status,
        dataIndex: 'status',
        render: (status: boolean | ValueType | undefined) =>
          valueOrHolder(lookup('resources.repairTaskStatus', status)),
      },
      {
        label: fieldLabels.classification,
        dataIndex: 'repairClassificationList',
        render: (list: RepairTaskDetailType['repairClassificationList']) =>
          valueOrHolder(_.map(list, 'name').join('/')),
      },
      // todo 暂时不上
      // {
      //   label: '资源占用',
      //   dataIndex: 'field',
      //   render: (type) => valueOrHolder(lookup('common.yn', type)),
      // },
      {
        label: fieldLabels.scanCodeFlag,
        dataIndex: 'scanCodeFlag',
        render: (type: boolean | ValueType | undefined) =>
          valueOrHolder(lookup('common.ynb', type)),
      },
      {
        label: fieldLabels.standardWorkTime,
        dataIndex: 'standardWorkTime',
        render: (time: number | undefined) => <AutoTimer startSec={time} />,
      },
      {
        label: fieldLabels.planStartTime,
        dataIndex: 'planStartTime',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: fieldLabels.planEndTime,
        dataIndex: 'planEndTime',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: fieldLabels.planExecutorList,
        dataIndex: 'planExecutorList',
        render: (users: RepairTaskDetailType['planExecutorList']) => (
          <TagTooltip
            list={users?.map((user) => ({ id: user.bizId!, name: user.name!, ...user })) || []}
            isUser
            max={2}
          />
        ),
      },
      {
        label: fieldLabels.realStartTime,
        dataIndex: 'realStartTime',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: fieldLabels.realEndTime,
        dataIndex: 'realEndTime',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: fieldLabels.currentExecutorList,
        dataIndex: 'currentExecutorList',
        render: (users: RepairTaskDetailType['currentExecutorList']) => (
          <TagTooltip
            list={users?.map((user) => ({ id: user.bizId!, name: user.name!, ...user })) || []}
            isUser
            max={2}
          />
        ),
      },
      {
        label: fieldLabels.executedDuration,
        dataIndex: 'executedDuration',
        render: (time: number | undefined) => {
          return isExecuting || isSuspended ? (
            <AutoTimer
              startSec={time}
              auto={detailData?.status === appEnum.Resources.RepairTaskStatus.executing}
            />
          ) : (
            replaceSign
          );
        },
      },
      {
        label: fieldLabels.sumDuration,
        dataIndex: 'sumDuration',
        render: (time: number | undefined) => {
          return isEnded ? (
            <AutoTimer
              startSec={time}
              auto={detailData?.status === appEnum.Resources.RepairTaskStatus.executing}
            />
          ) : (
            replaceSign
          );
        },
      },

      {
        label: fieldLabels.executeType,
        dataIndex: 'executeType',
        render: (type: boolean | ValueType | undefined) =>
          valueOrHolder(lookup('resources.repairExecuteType', type)),
      },
      {
        label: fieldLabels.sopPlan,
        dataIndex: 'sopList',
        render: (list: RepairTaskDetailType['sopList']) => {
          if (
            _.isNil(list) ||
            detailData?.executeType === appEnum.Resources.RepairExecuteType.reportTemplate
          ) {
            return replaceSign;
          }
          return _.map(list, 'name');
        },
      },
      {
        label: fieldLabels.sopTask,
        dataIndex: 'sopTaskCode',
        render: (sopTaskCode: RepairTaskDetailType['sopTaskCode']) => {
          if (
            _.isNil(detailData?.sopTaskId) ||
            detailData?.executeType === appEnum.Resources.RepairExecuteType.reportTemplate
          ) {
            return replaceSign;
          }

          return (
            <a
              onClick={() => {
                window.open(`/sop/exec/task/${detailData?.sopTaskId}/detail`);
              }}
            >
              {sopTaskCode ?? 'SOP任务-维修任务'}
            </a>
          );
        },
      },
      {
        label: fieldLabels.reportTemplate,
        dataIndex: 'reportTemplateList',
        render: (list: RepairTaskDetailType['reportTemplateList']) => {
          if (
            _.isNil(list) ||
            detailData?.executeType === appEnum.Resources.RepairExecuteType.SOP
          ) {
            return replaceSign;
          }
          return _.map(list, 'name').join('，');
        },
      },
      {
        label: fieldLabels.createResourceType,
        dataIndex: 'createResourceType',
        render: (type: boolean | ValueType | undefined) =>
          valueOrHolder(lookup('resources.repairTaskSource', type)),
      },
      {
        label: fieldLabels.sourceInstanceCode,
        dataIndex: 'createResourceCode',
        render: (code: string) =>
          valueOrHolder(code, () => {
            if (!detailData) return null;
            const { createResourceCode, createResourceId, createResourceType } = detailData;

            return !_.isNil(createResourceId) &&
              !_.isNil(createResourceCode) &&
              !_.isNil(createResourceType) &&
              createResourceType !== RepairTaskSource.manual ? (
              // eslint-disable-next-line react/jsx-indent
              <a
                onClick={() => {
                  window.open(`${getDetailPath(createResourceType)}/${createResourceId}/detail`);
                }}
              >
                {createResourceCode}
              </a>
            ) : null;
          }),
      },
      {
        label: fieldLabels.attachment,
        dataIndex: 'fileIds',
        render: (fileIds: number[]) =>
          valueOrHolder(fileIds, () => <BcAttachmentDetail fileIds={fileIds} />),
        span: 2,
      },
      {
        label: fieldLabels.remark,
        dataIndex: 'remark',
        isFullLine: true,
        render: valueOrHolder,
      },
    ]);

    return {
      title: '基本信息',
      items: baseItems,
      column: 2,
    };
  };

  const malfunctionInfo: DetailLayoutInfoBlock = {
    title: '故障信息',
    items: [
      {
        label: fieldLabels.malfunctonClassification,
        dataIndex: 'malfunctionClassificationList',
        render: (list: RepairTaskDetailType['malfunctionClassificationList']) =>
          valueOrHolder(_.map(list, 'name').join('/')),
      },
      {
        label: fieldLabels.malfunctionLevel,
        dataIndex: 'malfunctionLevel',
        render: (level: number) => valueOrHolder(lookup('resources.malfunctionLevel', level)),
      },
      {
        label: fieldLabels.malfunctionReason,
        dataIndex: 'malfunctionReason',
        render: (malfunctionReason: string) => valueOrHolder(malfunctionReason),
      },
      {
        label: fieldLabels.malfunctionDetail,
        dataIndex: 'malfunctionDetail',
        render: (malfunctionDetail: string) => valueOrHolder(malfunctionDetail),
      },
    ],
  };

  const malfunctionTagInfo: DetailLayoutInfoBlock = {
    title: fieldLabels.malfunctionTags,
    items: [
      {
        dataIndex: 'malfunctionLabelList',
        render: (malfunctionLabelList: RepairTaskDetailType['malfunctionLabelList']) => (
          <MalfunctionTagTable
            dataSource={malfunctionLabelList?.map((tag) => ({
              name: tag.name,
              labelValue: tag.labelValue,
            }))}
          />
        ),
      },
    ],
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: fieldLabels.creator,
        dataIndex: 'creator',
        render: (creator: RepairTaskDetailType['creator']) =>
          valueOrHolder(creator, () => (
            <AvatarDisplay
              id={creator?.id!}
              name={creator?.name!}
              avatarUrl={creator?.avatarUrl}
              isShowTag={false}
              isUser
            />
          )),
      },
      {
        label: fieldLabels.createdAt,
        dataIndex: 'createdAt',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: fieldLabels.operator,
        dataIndex: 'operator',
        render: (operator: RepairTaskDetailType['operator']) =>
          valueOrHolder(operator, () => (
            <AvatarDisplay
              id={operator?.id!}
              name={operator?.name!}
              avatarUrl={operator?.avatarUrl}
              isShowTag={false}
              isUser
            />
          )),
      },
      {
        label: fieldLabels.updatedAt,
        dataIndex: 'updatedAt',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
    ],
    column: 2,
  };

  return (
    <>
      <DetailLayout
        loading={loading}
        baseMenu={baseMenu()}
        title={`${BL_BUSINESS_NAME}详情`}
        info={[baseInfo(), malfunctionInfo, malfunctionTagInfo, otherInfo]}
        dataSource={detailData}
        userAuth={getAuthFromLocalStorage()}
      >
        {visibleLog && (
          <SingleOperationLogComponent
            visible={visibleLog}
            instanceId={detailData?.id ?? 0}
            objectCode={OBJECT_OF_CODE.RepairTask}
            closeDetail={() => {
              setVisibleLog(false);
            }}
          />
        )}
      </DetailLayout>
      {timeOutLog && (
        <TimeOutRecord recordId={detailData?.id} onClose={() => setTimeOutLog(false)} />
      )}
    </>
  );
};

export default Detail;
