import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { BlTable } from '@blacklake-web/component';
import { fetchCustomAqlDetail, FetchCustomAqlDetailResponse } from 'src/api/ytt/quality-domain/aql';
import { BasePath } from './constant';
import { replaceSign } from 'src/utils/constants';
import { turnAQL } from './utils';
import { appEnum } from 'src/dict';
import _, { isEmpty, isNumber } from 'lodash';
import moment from 'moment';
import { SingleOperationLogComponent } from 'src/components';
import authDict from 'src/utils/auth';

interface Props extends RouteComponentProps {}
interface Params {
  id?: number | string;
}

type DataSource = Exclude<FetchCustomAqlDetailResponse['data'], undefined>;

const column = [
  {
    dataIndex: 'minBatch',
    title: '最小批量',
    render: (text: number) => (isNumber(text) ? text : '-'),
  },
  {
    dataIndex: 'maxBatch',
    title: '最大批量',
    render: (text: number) => (isNumber(text) ? text : '-'),
  },
  {
    dataIndex: 'sampleCount',
    title: '样本量',
    render: (text: number) => (isNumber(text) ? text : '-'),
  },
];

const recieve_column = [
  {
    dataIndex: 'name',
    title: '质量限名称',
    render: (text: any, row: any) => {
      return {
        children: text,
        props: {
          rowSpan: row.len || 0,
        },
      };
    },
  },
  {
    dataIndex: 'maxBatch',
    title: '样本量',
    width: 400,
    render: (text: any, row: any) => {
      const { sample = {} } = row || {};

      return `批量${sample.minBatch}~${sample.maxBatch} 样本量：${sample.sampleCount}`;
    },
  },
  {
    dataIndex: 'acceptCount',
    title: '接收数',
    render: (text: number) => (isNumber(text) ? text : '-'),
  },
  {
    dataIndex: 'rejectCount',
    title: '拒收数',
    render: (text: number) => (isNumber(text) ? text : '-'),
  },
];

export default function AqlDetail(props: Props) {
  const { history, match } = props;
  const params: Params = match.params;
  const { id } = params;
  const [dataSource, setDataSource] = useState<DataSource>({});
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const acceptListFormat = (acceptLimitList?: any[]) => {
    const { sampleList = [] } = dataSource;
    let formatList: any = [];

    acceptLimitList?.forEach((item: any) => {
      const { acceptLimitDetail, name } = item;

      formatList = formatList.concat(
        acceptLimitDetail?.map((row: any, key: number) => {
          const sampleItem = sampleList.find((s) => s.id === row.sampleId);

          if (key === 0) {
            return { name, ...row, sample: sampleItem, len: acceptLimitDetail.length };
          }
          return { name, ...row, sample: sampleItem };
        }),
      );
    });

    return formatList;
  };

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      { label: '方案名称', dataIndex: 'name' },
      {
        label: '批量修约规则',
        dataIndex: 'roundRule',
        render: (value: number) => (value === 1 ? '向上圆整' : '向下圆整'),
      },
    ],
  };
  const amount: DetailLayoutInfoBlock = {
    title: '样本量表',
    items: [
      {
        label: '',
        dataIndex: 'sampleList',
        render: (sampleList: any) => {
          return <BlTable dataSource={sampleList} columns={column} />;
        },
      },
    ],
  };
  const receiveAmount: DetailLayoutInfoBlock = {
    title: '接收质量限表',
    items: [
      {
        label: '',
        dataIndex: 'acceptLimitList',
        render: (acceptLimitList: any) => {
          return (
            <BlTable
              pagination={false}
              columns={recieve_column}
              dataSource={acceptListFormat(acceptLimitList)}
            />
          );
        },
      },
    ],
  };
  const other: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: any) => {
          return creator ? <AvatarDisplay {...creator} isShowTag={false} isUser /> : replaceSign;
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: (value: number) => moment(value).format('YYYY-MM-DD HH:mm:ss') ?? replaceSign,
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: any) => {
          return operator ? <AvatarDisplay {...operator} isShowTag={false} isUser /> : replaceSign;
        },
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: (value: number) => moment(value).format('YYYY-MM-DD HH:mm:ss') ?? replaceSign,
      },
    ],
  };
  const toAqlEdit = () => {
    if (!id) {
      return;
    }
    history.push(`${BasePath}/edit/${id}`);
  };
  const toAqlCopy = () => {
    if (!id) {
      return;
    }
    history.push(`${BasePath}/copy/${id}`);
  };
  const getDetail = async () => {
    const res = await fetchCustomAqlDetail({ id: Number(id) });

    setDataSource(res?.data || {});
  };

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, [id]);

  return (
    <>
      <DetailLayout
        title="自定义AQL方案详情"
        info={[baseInfo, amount, receiveAmount, other]}
        baseMenu={_.compact([
          {
            key: 'operate',
            title: '操作记录',
            auth: authDict.customizedaql_operrecord,
            onClick: () => {
              setVisibleLog(true);
            },
          },
          {
            key: 'turn',
            title: dataSource?.state === appEnum.Common.Turn.on ? '停用' : '启用',
            onClick: () => turnAQL(dataSource.id, dataSource?.state, getDetail),
            auth: authDict.customizedaql_enable_disable,
          },
          {
            key: 'copy',
            title: '复制',
            onClick: toAqlCopy,
            auth: authDict.customizedaql_add,
          },
          dataSource?.state !== appEnum.Common.Turn.on && {
            key: 'edit',
            title: '编辑',
            onClick: toAqlEdit,
            auth: authDict.customizedaql_edit,
          },
        ])}
        dataSource={dataSource}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={Number(id)}
          objectCode={'CustomizedAQL'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
}
