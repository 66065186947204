/**
 * @description 对象属性值配置, 包装FormItem的HOC
 */
import { ReactNode } from 'react';
import { Form } from 'antd';
import _ from 'lodash';

// import styles from './styles.module.scss';

export const getShouldUpdateFn = (namePathList: string[]) => (pre: any, cur: any) => {
  let update = false;

  for (let i = 0; i < namePathList.length; i++) {
    const preValue = _.get(pre, namePathList[i]);
    const curValue = _.get(cur, namePathList[i]);

    if (!_.isEqual(preValue, curValue)) {
      update = true;
      break;
    }
  }
  return update;
};

type selectData = (data: any) => any;
const defaultSelectData: selectData = () => ({});

const withFormItemUpdate =
  (Component: ReactNode, selectData = defaultSelectData) =>
  ({ name, field, formItemPorps, dependenceParamsPath, rules, ...restProps }: any) => {
    return (
      <Form.Item noStyle shouldUpdate={getShouldUpdateFn(dependenceParamsPath)}>
        {(form) => {
          const dependenceValue = dependenceParamsPath.map((item: string) =>
            form.getFieldValue(item?.split('.')),
          );
          const params = form.getFieldsValue();

          return (
            <Form.Item
              {...field}
              {...formItemPorps}
              rules={rules ?? formItemPorps?.rules}
              name={[field?.name, name]}
            >
              <Component
                {...restProps}
                {...selectData(params)}
                dependenceValue={dependenceValue}
                key={field.key}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  };

export const withFormItem =
  (Component: ReactNode) =>
  ({ name, field, initialValue, formItemPorps = {}, ...restProps }: any) => {
    return (
      <Form.Item
        {...formItemPorps}
        {...field}
        name={[field?.name, name]}
        initialValue={initialValue}
      >
        <Component {...restProps} />
      </Form.Item>
    );
  };

export default withFormItemUpdate;
