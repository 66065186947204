import React, { useEffect, useState } from 'react';
import { DataFormLayout } from 'layout';
import _ from 'lodash';
import { BlTable } from 'src/components';
import { Badge, FormInstance, Table, Tabs } from 'antd';
import lookup, { appEnum } from 'src/dict';
import {
  fetchQcConfigListTriggerRule,
  fetchQcConfigListTriggerAction,
  FetchQcConfigListTriggerActionResponse,
  FetchQcConfigListTriggerRuleResponse,
} from 'src/api/ytt/quality-domain/qcConfig';

type TriggerEventsData = FetchQcConfigListTriggerActionResponse['data'];
type TriggerRulessData = FetchQcConfigListTriggerRuleResponse['data'];
interface TriggerRulesTabProps {
  id: number;
}

const TriggerRules = (props: TriggerRulesTabProps) => {
  const { id } = props;
  const [triggerEventsData, setTriggerEventsData] = useState<TriggerEventsData>();
  const [triggerRulesData, setTriggerRulesData] = useState<TriggerRulessData>();

  const fetchTriggerRulesData = async (params = {}) => {
    try {
      const { data } = await fetchQcConfigListTriggerRule({ id, ...params });

      setTriggerRulesData(data);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const fetchTriggerEventsData = async (params = {}) => {
    try {
      const { data } = await fetchQcConfigListTriggerAction({ id, ...params });

      setTriggerEventsData(data);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  useEffect(() => {
    fetchTriggerEventsData();
    fetchTriggerRulesData();
  }, []);

  const tabPaneListConfig = _.compact([
    {
      key: 'triggerRule',
      tabTitle: '预置触发规则',
    },
    {
      key: 'triggerEvent',
      tabTitle: '预置触发事件',
    },
  ]);

  const paginationConfig = {
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total: any) => `共 ${total} 条`,
  };

  return (
    <div style={{ height: '100%' }}>
      <Tabs defaultActiveKey="trigger" tabBarStyle={{ marginLeft: 20 }}>
        {tabPaneListConfig.map((tabPaneConfig) => (
          <Tabs.TabPane
            style={{ overflow: 'auto' }}
            tab={tabPaneConfig.tabTitle}
            key={tabPaneConfig.key}
          >
            <div style={{ paddingLeft: 24 }}>
              {tabPaneConfig.key === 'triggerRule' && (
                <BlTable
                  dataSource={triggerRulesData?.list}
                  columns={[
                    {
                      dataIndex: 'key',
                      title: '序号',
                      render: (key, record, index) => index,
                    },
                    {
                      dataIndex: 'code',
                      title: '触发规则编号',
                    },
                    {
                      dataIndex: 'name',
                      title: '触发规则名称',
                    },
                    {
                      dataIndex: 'status',
                      title: '状态',
                      render: (status) => (
                        <span>
                          <Badge
                            status={
                              status === appEnum.Common.UsageStatus.enabled ? 'success' : 'default'
                            }
                          />
                          {lookup('common.usageStatus', status)}
                        </span>
                      ),
                    },
                  ]}
                  pagination={{
                    total: triggerRulesData?.total,
                    current: triggerRulesData?.page,
                    ...paginationConfig,
                    onChange: (page, size) => {
                      fetchTriggerRulesData({ page, size });
                    },
                  }}
                  rowKey="id"
                />
              )}
              {tabPaneConfig.key === 'triggerEvent' && (
                <BlTable
                  columns={[
                    {
                      dataIndex: 'key',
                      title: '序号',
                      render: (key, record, index) => index,
                    },
                    {
                      dataIndex: 'code',
                      title: '触发事件编号',
                    },
                    {
                      dataIndex: 'name',
                      title: '触发事件名称',
                    },
                    {
                      dataIndex: 'status',
                      title: '状态',
                      render: (status) => (
                        <span>
                          <Badge
                            status={
                              status === appEnum.Common.UsageStatus.enabled ? 'success' : 'default'
                            }
                          />
                          {lookup('common.usageStatus', status)}
                        </span>
                      ),
                    },
                  ]}
                  dataSource={triggerEventsData?.list}
                  rowKey="id"
                  scroll={{ x: 'max-content' }}
                  pagination={{
                    total: triggerEventsData?.total,
                    current: triggerEventsData?.page,
                    ...paginationConfig,
                    onChange: (page, size) => {
                      fetchTriggerEventsData({ page, size });
                    },
                  }}
                />
              )}
            </div>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default TriggerRules;
