export const STATUS_VALUE = {
  ENABLED: 1,
  DISABLED: 2,
};

export const STATUS_TYPE = new Map([
  [STATUS_VALUE.ENABLED, '启用中'],
  [STATUS_VALUE.DISABLED, '停用中'],
]);

export const STATUS_OPERATE = new Map([
  [STATUS_VALUE.ENABLED, '停用'],
  [STATUS_VALUE.DISABLED, '启用'],
]);
