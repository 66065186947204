import warehouseManagementRoute from 'src/page/warehouseManagement';
import { qualityRouteConfig } from 'src/page/quality/navigation';
import { sopRouteConfig } from 'src/page/sop/navigation';
import { modelingRouteConfig } from 'src/page/modeling/navigation';
import { universalRouteConfig } from 'src/page/universal/navigation';
import { resourceRouteConfig } from 'src/page/resource/navigation';
import { productionPlanningRouteConfig } from 'src/page/productionPlanning';
import SalesRoute from 'src/page/sales';
import { purchaseRouteConfig } from 'src/page/purchase/navigation';
import UserSettingRoute from 'src/page/userSettings';
import { RouteProp } from './index.d';
import { AnalyseRoute } from 'src/page/analyse/navigation';
import { NotificationRoute } from 'src/page/notificationManagement/navigation';
import { recursionRouteAddExImport } from 'src/utils/formatters/route';
import { materialPlanRouteConfig } from 'src/page/planned';
import { TraceRoute } from 'src/page/trace';
import { customObjectRouteConfig } from 'src/page/customObject/navigation';

const frontendRoutes: RouteProp[] = [
  ...productionPlanningRouteConfig,
  ...materialPlanRouteConfig,
  ...warehouseManagementRoute,
  ...qualityRouteConfig,
  ...resourceRouteConfig,
  ...sopRouteConfig,
  ...SalesRoute,
  ...TraceRoute,
  ...purchaseRouteConfig,
  ...modelingRouteConfig,
  ...universalRouteConfig,
  ...UserSettingRoute,
  ...AnalyseRoute,
  ...NotificationRoute,
  ...customObjectRouteConfig,
];

recursionRouteAddExImport(frontendRoutes);

export default frontendRoutes;
