import React from 'react';
import { Badge } from 'antd';
import lookup, { appEnum } from 'src/dict';
import _ from 'lodash';
import { replaceSign } from 'src/utils/constants';

const EnergyMeterStatus = ({ status }: { status?: number }) => {
  if (_.isNil(status)) return <span>{replaceSign}</span>;

  return (
    <span>
      {status === appEnum.Resources.EnergyMeterStatus.enabled && <Badge status="success" />}
      {status === appEnum.Resources.EnergyMeterStatus.scrap && <Badge status="error" />}
      {status === appEnum.Resources.EnergyMeterStatus.disabeld && <Badge status="default" />}
      {lookup('resources.energyMeterStatus', status)}
    </span>
  );
};

export default EnergyMeterStatus;
