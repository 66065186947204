import { useEffect, useState, useRef } from 'react';
import { Menu, MenuProps } from 'antd';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { BlIcon } from '@blacklake-web/component';
import { gcArray } from 'utils';
import styles from './styles.module.scss';

const { Item } = Menu;

interface BlMenuProps extends RouteComponentProps {
  menuData?: any[];
  onSwitch: (item: any) => void;
  onHover: (item: any) => void;
}

const BlMenu = (props: BlMenuProps & MenuProps) => {
  const { menuData = [], location, onSwitch, onHover } = props;
  const { pathname } = location;
  const [currentPath, setCurrentPath] = useState(pathname);
  // 延时触发菜单的hover预览
  const timerId = useRef<number>();

  useEffect(() => {
    setCurrentPath(location?.pathname);
  }, [location]);

  const handleMenuItenHover = (menuItem: any) => {
    timerId.current = window.setTimeout(() => {
      onHover(menuData.find((item) => menuItem?.key === item?.path));
    }, 200);
  };

  const renderMenu = (itemArray: any[]) => {
    const pathRoot = currentPath.split('/').slice(0, 2);

    return itemArray.map((item: any) => {
      if (!item.name) return null;

      const isLeaf = !gcArray.isEmpty(item.children);
      const { icon } = item;
      const option: any = {};
      const isActive = item.path === pathRoot.join('/');

      if (icon) {
        option.icon = (
          <BlIcon style={{ fontSize: 18 }} type={isActive ? `${icon}-mianxing` : icon} />
        );
      }

      if (!isLeaf) {
        return (
          <Item
            {...option}
            className={`${isActive ? 'ant-menu-item-selected' : ''}`}
            key={item.path}
          >
            <Link to={item.path}>{item.name}</Link>
          </Item>
        );
      }

      return (
        <Item
          {...option}
          key={item.path}
          className={classNames(
            styles['root-menu-item'],
            `${isActive ? 'ant-menu-item-selected' : ''}`,
          )}
          onClick={(menuItem) => {
            onSwitch(
              itemArray.find((item) => {
                return menuItem?.key && menuItem?.key === item?.path;
              }),
            );
            setCurrentPath(menuItem?.key);
          }}
          onMouseEnter={handleMenuItenHover}
          onMouseLeave={() => clearTimeout(timerId.current)}
        >
          {item.name}
        </Item>
      );
    });
  };

  return (
    <Menu style={{ borderRight: 0 }} selectedKeys={[currentPath]} id={styles['root-menu']}>
      {renderMenu(menuData)}
    </Menu>
  );
};

export default withRouter(BlMenu);
