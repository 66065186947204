const BASE_URL = '/logManagement/operationLogList';

export const OPERATION_TERMINAL_ENUM = {
  WEB: 0,
  APP: 1,
};

export const OPERATION_TERMINAL_TYPE = new Map([
  [OPERATION_TERMINAL_ENUM.WEB, 'WEB'],
  [OPERATION_TERMINAL_ENUM.APP, 'APP'],
]);

export const toDetail = (id: number) => {
  return `${BASE_URL}/${id}/detail`;
};
