/**
 * @description 对象属性值配置, 字段组件
 */

import { Select } from 'antd';

import RedRemindText from '../../triggerRule/share/RedRemindText';
import withFormItemUpdate from './withFormItem';

import { BaseOption } from '../constant';

interface FieldCascaderProps {
  dependenceValue: any[];
  disabled: boolean;
  fieldIdOptions: BaseOption[];
  value?: any;
  onChange?: any;
}

const FieldSelect: React.FC<FieldCascaderProps> = ({
  dependenceValue,
  disabled,
  fieldIdOptions,
  ...restProps
}) => {
  // 此处的 required 从 dependenceValue 中获取得到，指该字段为"必选项"
  const [objectCode, required] = dependenceValue;

  if (objectCode) {
    return (
      <Select
        {...restProps}
        labelInValue
        allowClear={false}
        disabled={required || disabled}
        placeholder="请选择"
        options={fieldIdOptions.map((item: any) => ({
          ...item,
          label: JSON.parse(item.value).isRequired ? (
            <span>
              <span style={{ color: 'red' }}>* </span>
              {item.label}
            </span>
          ) : (
            item.label
          ),
        }))}
      />
    );
  }

  // 依赖业务字段的code去请求接口获取字段
  return <RedRemindText text="请先选择业务对象" />;
};

export default withFormItemUpdate(FieldSelect);
