export const VIEW_MODAL = {
  DOCUMENTS: '1',
  DETAIL: '2',
};

export const DOCUMENTS_FIELD_MAP = new Map([
  ['code', 'code'],
  ['customer', 'customerName'],
  ['owners', 'owner'],
  ['status', 'status'],
  ['creator', 'creator'],
  ['createdAt', 'createdAt'],
  ['operator', 'operator'],
  ['updatedAt', 'updatedAt'],
  ['origin', 'origin'],
  ['scmPurchaseOrderCode', 'scmPurchaseOrderCode'],
  ['shipmentStatus', 'shipmentStatus'],
]);
export const DETAIL_FIELD_MAP = new Map([
  ['orderCode', 'code'],
  ['owners', 'owner'],
  ['name', 'customerName'],
  ['deliveryDate', 'delivery_date'],
  ['origin', 'origin'],
  ['scmPurchaseOrderCode', 'scmPurchaseOrderCode'],
  ['shipmentStatus', 'shipmentStatus'],
]);
