import React, { useEffect, useState } from 'react';
import { BlIcon, BlTable, BlCascader } from 'src/components';
import { Input, Select, InputNumber, Form, Switch, FormInstance, message } from 'antd';
import { replaceSign } from 'src/utils/constants';
import { TABLE_TYPE, TABLE_TYPE_ENUM, WATER_SYSTEM_ENUM, REST_PERIOD_ENUM } from '../constant';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import { RuleObject } from 'antd/lib/form';
import { DetailData, ElementList, FormatConfigData, SourceDataType } from '../interface';
import {
  inputNumberIntRules,
  validateBlText1,
} from 'src/page/knowledgeManagement/share/warehouseRules';
import { isUndefined } from 'lodash';
import { haveVariableOrTimeType, isHaveSerialNumber } from '../utils';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import styles from './number.module.scss';
import { validateBlInputNumberIntervalInt } from 'src/page/custom_fields/fieldsList/components/verificationRules';

const { Option } = Select;

interface StoreDetailProps {
  initialData?: ElementList[];
  form: FormInstance;
  formatConfigData: FormatConfigData[];
  sourceData: SourceDataType;
}

const FormTable = (props: StoreDetailProps) => {
  const { initialData, form, sourceData, formatConfigData } = props;

  const [typeNotHaveVariableOrDate, setTypeNotVariableOrDate] = useState(false); // 未引入变量或日期时 重置周期不可选择
  const [dataSource, setDataSource] = useState<any>([]);

  const formatDetailDataToForm = (values: ElementList[]) => {
    const elementValue = values?.map((item: any) => {
      return {
        elementType: item?.elementType,
        elementLength: item?.elementLength,
        constantValue: item?.constantValue,
        elementFields: item?.elementFields,
        resetPeriod: item?.resetPeriod,
        startValue: item?.startValue,
        stepSize: item?.stepSize,
        codeFormat: item?.codeFormat,
        formatConfigId: item?.formatConfigId,
        numberBasis: item?.numberBasis,
        numberRuleId: item?.numberRuleId,
        id: item?.id,
      };
    });

    const haveVariable = haveVariableOrTimeType(values);

    setTypeNotVariableOrDate(!haveVariable);

    setDataSource(elementValue);

    return elementValue;
  };

  // 类型变化以及删除时 元素的disable的通用处理
  const elementChange = () => {
    const elements = form.getFieldValue('elementList') ?? {};

    const isTypeHaveVariableOrDate = haveVariableOrTimeType(elements); // 是否还有变量元素

    setTypeNotVariableOrDate(!isTypeHaveVariableOrDate);

    const haveSerialNumber = isHaveSerialNumber(elements); // 判断是都还有流水号 如果没有流水号类型元素 整个编号依据重置为0

    if (!haveSerialNumber) {
      // 当无流水号时  需要将所有编号依据重置为0 并且禁用
      const newElement = elements.map((node: any) => {
        const newNode = { ...node, numberBasis: 0 };

        return newNode;
      });

      form.setFieldsValue({ elementList: newElement });
    }

    if (!isTypeHaveVariableOrDate) {
      // 如果删除后 当前类型中无变量或者日期类型了  流水号周期需要重置为仅按编号规则
      const newElement = elements.map((node: any) => {
        const newNode = node;

        if (node?.elementType === TABLE_TYPE_ENUM.SERIAL_NUMBER) {
          newNode.resetPeriod = 0;
        }
        return newNode;
      });

      form.setFieldsValue({ elementList: newElement });
    }
  };

  const scrollToBottom = () => {
    const wrapper = document.getElementById('numberRules');

    if (wrapper) {
      const dom = wrapper.getElementsByClassName('ant-table-body')[0];

      if (dom) {
        dom.scrollTop = dom.scrollHeight;
      }
    }
  };

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue({
        elementList: formatDetailDataToForm(initialData),
      });
    }
  }, [initialData]);

  const DragHandle = SortableHandle(() => <BlIcon type="iconrenyituozhuai" />);

  /**
   * @description: 长度对起始值的限制校验
   */
  const startValueLengthCheck = (maxLength: any) => {
    return (_rule: RuleObject, value: string, _callback: (error?: string) => void) => {
      return new Promise<void>((resolve, reject) => {
        if (value && !inputNumberIntRules(value)) {
          return reject('必须是整数');
        }
        if (value && value?.toString()?.length > maxLength) {
          return reject('长度不得超过[长度]字段限制的长度');
        }

        return resolve();
      });
    };
  };

  return (
    <Form.List name="elementList">
      {(fields, { add, remove, move }) => {
        const getFooter = () => {
          return (
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <span
                onClick={() => {
                  add();
                  setTimeout(() => {
                    scrollToBottom();
                  }, 300);
                }}
                style={{
                  cursor: 'pointer',
                }}
              >
                <span style={{ marginRight: 8 }}>
                  <BlIcon type="iconxinjiantianjia" />
                </span>
                添加元素
              </span>
            </div>
          );
        };
        const getColumns = (): ColumnProps<DetailData & FormListFieldData>[] => {
          return [
            {
              width: 30,
              fixed: 'left',
              render: () => (
                <div title="拖动">
                  <DragHandle />
                </div>
              ),
            },
            {
              width: 50,
              fixed: 'left',
              render: (_, record) => {
                return (
                  <div style={{ color: '#FF4D4F' }}>
                    <BlIcon
                      type="iconlieshanchu"
                      onClick={() => {
                        const elements = form.getFieldValue('elementList') ?? {};

                        if (elements?.length < 2) {
                          // 提示 至少需要一条
                          message.error('编号规则元素至少有一条');
                          return;
                        }
                        remove(record?.name);
                        elementChange();
                      }}
                    />
                  </div>
                );
              },
            },
            {
              title: '类型',
              dataIndex: 'elementType',
              key: 'elementType',
              width: 120,
              render: (_, field) => {
                return (
                  <Form.Item
                    name={[field?.name, 'elementType']}
                    fieldKey={[field?.key, 'elementType']}
                  >
                    <Select
                      disabled={Boolean(!form.getFieldValue('suitObjId'))}
                      onChange={() => {
                        const elements = form.getFieldValue('elementList') ?? {};

                        // 只要类型发生改变,重置所有当前行的值

                        const newElement = elements.map((node: any, index: number) => {
                          const newNode = { ...node };

                          if (index !== field?.name) return newNode;

                          if (node?.elementType === TABLE_TYPE_ENUM.SERIAL_NUMBER) {
                            return {
                              ...newNode,
                              resetPeriod: 0, // 手动设置点击类型为流水号时 初值为0
                            };
                          }

                          if (node?.elementType === TABLE_TYPE_ENUM.DATE) {
                            return {
                              ...newNode,
                              elementFields: ['0'], // 如果选择类型为日期  初始值为当前日期
                              formatConfigId: null,
                            };
                          }

                          if (node?.elementType === TABLE_TYPE_ENUM.VARIABLE) {
                            return {
                              ...newNode,
                              formatConfigId: null,
                              elementFields: null,
                            };
                          }
                          return node;
                        });

                        form.setFieldsValue({ elementList: newElement });
                        elementChange();
                      }}
                    >
                      {TABLE_TYPE.map(({ name, id }) => (
                        <Option value={id} key={id}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                );
              },
            },
            {
              title: '长度',
              dataIndex: 'elementLength',
              key: 'elementLength',
              width: 120,
              render: (_, field) => {
                return (
                  <Form.Item shouldUpdate>
                    {() => {
                      const { elementType, constantValue, formatConfigId } =
                        form.getFieldValue('elementList')[field.name] ?? {};

                      const formatConfigDisplayLength = formatConfigData?.find(
                        (node: any) => node?.id === formatConfigId,
                      )?.displayType?.length;

                      const constantValueLength = constantValue?.length;

                      const elementLengthRequired = elementType === TABLE_TYPE_ENUM.SERIAL_NUMBER;

                      if (elementType === TABLE_TYPE_ENUM.SERIAL_NUMBER) {
                        return (
                          <Form.Item
                            name={[field?.name, 'elementLength']}
                            fieldKey={[field?.key, 'elementLength']}
                            validateFirst
                            rules={[
                              {
                                required: elementLengthRequired,
                                message: '长度不能为空',
                              },
                              { validator: validateBlInputNumberIntervalInt(1, 20) },
                            ]}
                          >
                            <InputNumber />
                          </Form.Item>
                        );
                      }

                      if (
                        !isUndefined(constantValueLength) &&
                        elementType === TABLE_TYPE_ENUM.CONSTANT
                      ) {
                        return constantValueLength || replaceSign;
                      }

                      if (
                        !isUndefined(formatConfigDisplayLength) &&
                        elementType === TABLE_TYPE_ENUM.DATE
                      ) {
                        return formatConfigDisplayLength || replaceSign;
                      }

                      return replaceSign;
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: '元素来源',
              dataIndex: 'elementFields',
              key: 'elementFields',
              width: 180,
              render: (_, field) => {
                return (
                  <Form.Item shouldUpdate>
                    {() => {
                      const { elementType } = form.getFieldValue('elementList')[field.name] ?? {};

                      const sourceDataSource =
                        elementType === TABLE_TYPE_ENUM.DATE
                          ? sourceData?.dateData
                          : sourceData?.variableData;

                      if (
                        elementType !== TABLE_TYPE_ENUM.DATE &&
                        elementType !== TABLE_TYPE_ENUM.VARIABLE
                      ) {
                        return replaceSign;
                      }

                      return (
                        <Form.Item
                          name={[field?.name, 'elementFields']}
                          fieldKey={[field?.key, 'elementFields']}
                          initialValue={elementType === TABLE_TYPE_ENUM.DATE && ['0']}
                          rules={[
                            {
                              required: true,
                              message: '元素来源不能为空',
                            },
                          ]}
                        >
                          <BlCascader
                            options={sourceDataSource}
                            showSearch
                            inputDisplayIsOnlyLeaf
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: '格式',
              dataIndex: 'formatConfigId',
              key: 'formatConfigId',
              width: 150,
              render: (_, field) => {
                return (
                  <Form.Item shouldUpdate>
                    {() => {
                      const { elementType } = form.getFieldValue('elementList')[field.name] ?? {};

                      if (
                        elementType === TABLE_TYPE_ENUM.CONSTANT ||
                        elementType === TABLE_TYPE_ENUM.SERIAL_NUMBER
                      ) {
                        return replaceSign;
                      }

                      const selectData = formatConfigData?.filter(
                        (node: FormatConfigData) => node?.suitType === elementType,
                      );

                      return (
                        <Form.Item
                          name={[field?.name, 'formatConfigId']}
                          fieldKey={[field?.key, 'formatConfigId']}
                          rules={[
                            {
                              required: true,
                              message: '格式不能为空',
                            },
                          ]}
                        >
                          <Select>
                            {selectData?.map(({ displayType, id }) => (
                              <Option value={id} key={id}>
                                {displayType}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: '设置值',
              dataIndex: 'constantValue',
              key: 'constantValue',
              width: 120,
              render: (_, field) => {
                return (
                  <Form.Item shouldUpdate>
                    {() => {
                      const { elementType } = form.getFieldValue('elementList')[field.name] ?? {};

                      if (elementType !== TABLE_TYPE_ENUM.CONSTANT) {
                        return replaceSign;
                      }

                      return (
                        <Form.Item
                          name={[field?.name, 'constantValue']}
                          fieldKey={[field?.key, 'constantValue']}
                          validateFirst
                          rules={[
                            { max: 50, message: '不可超过50个字符' },
                            {
                              required: true,
                              message: '设置值不能为空',
                            },
                            { validator: validateBlText1() },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: '起始值',
              dataIndex: 'startValue',
              key: 'startValue',
              width: 180,
              render: (_, field) => {
                return (
                  <Form.Item shouldUpdate>
                    {() => {
                      const { elementType, elementLength } =
                        form.getFieldValue('elementList')[field.name] ?? {};

                      if (elementType !== TABLE_TYPE_ENUM.SERIAL_NUMBER) {
                        return replaceSign;
                      }

                      return (
                        <Form.Item
                          name={[field?.name, 'startValue']}
                          fieldKey={[field?.key, 'startValue']}
                          validateFirst
                          rules={[
                            {
                              required: true,
                              message: '起始值不能为空',
                            },
                            {
                              validator: startValueLengthCheck(elementLength),
                            },
                          ]}
                        >
                          <InputNumber min={0} disabled={!(elementLength && elementLength >= 0)} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: '步长',
              dataIndex: 'stepSize',
              key: 'stepSize',
              width: 120,
              render: (_, field) => {
                return (
                  <Form.Item shouldUpdate>
                    {() => {
                      const { elementType } = form.getFieldValue('elementList')[field.name] ?? {};

                      if (elementType !== TABLE_TYPE_ENUM.SERIAL_NUMBER) {
                        return replaceSign;
                      }

                      return (
                        <Form.Item
                          name={[field?.name, 'stepSize']}
                          fieldKey={[field?.key, 'stepSize']}
                          validateFirst
                          rules={[
                            {
                              required: true,
                              message: '步长不能为空',
                            },
                            { validator: validateBlInputNumberIntervalInt(1, 32) },
                          ]}
                          initialValue={1}
                        >
                          <InputNumber />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: '流水码制',
              dataIndex: 'codeFormat',
              key: 'codeFormat',
              width: 150,
              render: (_, field) => {
                return (
                  <Form.Item shouldUpdate>
                    {() => {
                      const { elementType } = form.getFieldValue('elementList')[field.name] ?? {};

                      if (elementType !== TABLE_TYPE_ENUM.SERIAL_NUMBER) {
                        return replaceSign;
                      }

                      return (
                        <Form.Item
                          name={[field?.name, 'codeFormat']}
                          fieldKey={[field?.key, 'codeFormat']}
                          rules={[
                            {
                              required: true,
                              message: '流水码制不能为空',
                            },
                          ]}
                        >
                          <Select>
                            {WATER_SYSTEM_ENUM.map(({ name, id }) => (
                              <Option value={id} key={id}>
                                {name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: '编号依据',
              dataIndex: 'numberBasis',
              key: 'numberBasis',
              width: 100,
              render: (_, field) => {
                return (
                  <Form.Item shouldUpdate>
                    {() => {
                      const { elementType } = form.getFieldValue('elementList')[field.name] ?? {};
                      const elements = form.getFieldValue('elementList') ?? {};

                      const haveSerialNumber = isHaveSerialNumber(elements);

                      const numberBasisDisabled =
                        !haveSerialNumber || elementType === TABLE_TYPE_ENUM.SERIAL_NUMBER; //  类型为流水号或者当没有流水号元素时 整个编号依据列禁用

                      return (
                        <Form.Item
                          name={[field?.name, 'numberBasis']}
                          fieldKey={[field?.key, 'numberBasis']}
                          valuePropName="checked"
                        >
                          <Switch disabled={numberBasisDisabled} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: '重置周期',
              dataIndex: 'resetPeriod',
              key: 'resetPeriod',
              width: 150,
              render: (_, field) => {
                return (
                  <Form.Item shouldUpdate>
                    {() => {
                      const { elementType } = form.getFieldValue('elementList')[field.name] ?? {};

                      // 非流水类型返回-
                      if (elementType !== TABLE_TYPE_ENUM.SERIAL_NUMBER) {
                        return replaceSign;
                      }

                      return (
                        <Form.Item
                          name={[field?.name, 'resetPeriod']}
                          fieldKey={[field?.key, 'resetPeriod']}
                          initialValue={0}
                        >
                          <Select disabled={typeNotHaveVariableOrDate}>
                            {REST_PERIOD_ENUM.map(({ name, id }) => (
                              <Option key={id} value={id}>
                                {name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
          ];
        };

        const data: any = fields?.map((f: any) => {
          return { ...f, ...dataSource[f?.name] } ?? {};
        });

        // 拖动的元素
        const SortableItem = SortableElement((itemProps: any) => <tr {...itemProps} />);
        const TableContainer = SortableContainer((tableProps: any) => <tbody {...tableProps} />);

        const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
          move(oldIndex, newIndex);
        };

        const DraggableBodyRow = (bodyRowProps: any) => {
          const index = data.findIndex((x: any) => x.key === bodyRowProps['data-row-key']);

          return <SortableItem index={index} {...bodyRowProps} />;
        };

        const DraggableContainer = (containerProps: any) => (
          <TableContainer
            useDragHandle
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...containerProps}
          />
        );

        return (
          <BlTable
            columns={getColumns()}
            dataSource={data}
            footer={getFooter}
            scroll={{ x: 1200, y: 350 }}
            rowKey={(field) => field?.key}
            id="numberRules"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
            className={styles?.numberTable}
          />
        );
      }}
    </Form.List>
  );
};

export default FormTable;
