/**
 * @page SOP方案列表
 */
import { useCallback, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { message, Modal } from 'antd';
import _ from 'lodash';
import { RecordListLayout } from 'layout';
import { LinkTooltip } from 'components';
import {
  fetchSopList,
  fetchSopEnable,
  fetchSopDisable,
  fetchSopDelete,
} from 'src/api/ytt/sop-domain';
import CopySopModal from './components/copyModal';
import renderUsageStatus from 'src/page/share/renderUsageStatus';
import lookup from 'src/dict';
import { UsageStatus } from 'src/dict/common';
import { usageStatus as usageStatusMappings } from 'src/dict/common/mappings';
import { BizType } from 'src/dict/sop';
import { bizType as bizTypeMappings } from 'src/dict/sop/mappings';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { fieldTypeList } from 'utils';
import { formatTimeForRender, formatTimeRangeForQuery } from 'src/utils/formatters/dateTime';
import { renderUser } from 'src/page/share/renderUser';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { schemePath } from '../path';
import type { SopOverviewData } from './types';

interface Props {
  history: any;
}

const initialCopyModalState = {
  visible: false,
  fromSopName: '',
  bizType: BizType.production,
  sopId: 0,
  referenceId: 0,
};

const SOPList = (props: Props) => {
  const { history } = props;
  const [copyModalState, setCopyModalState] = useState(initialCopyModalState); // 复制弹窗
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新页面
  const refresh = () => setRefreshMarker(Date.now());

  const handleDeleteSop = useCallback((id) => {
    fetchSopDelete({ sopId: id }).then(() => {
      message.success('删除成功');
      refresh();
    });
  }, []);

  const dataColumns = [
    {
      title: '编号',
      dataIndex: 'code',
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
      sorter: true,
    },
    {
      title: '名称',
      dataIndex: 'name',
      width: 180,
      isFilter: true,
      type: fieldTypeList.text,
      render: (text: string, record: SopOverviewData, index: number, config: any) => (
        <LinkTooltip
          text={text}
          to={generatePath(schemePath.detail, { id: record.sopId })}
          width={config.contentWidth}
          auth={authDict.sopscheme_detail}
        />
      ),
    },
    {
      title: 'SOP方案版本号',
      dataIndex: 'version',
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: usageStatusMappings,
      },
      render: renderUsageStatus,
    },
    {
      title: '业务类型',
      dataIndex: 'bizType',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: bizTypeMappings,
      },
      render: lookup('sop.bizType'),
    },
    {
      title: '创建人',
      dataIndex: 'creatorId',
      width: 150,
      render: renderUser,
      isFilter: true,
      type: fieldTypeList.reference,
      renderItem: <UserOrDepartmentSelectWithDialog isSelectUser isMultiple isNewFormat />,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 180,
      render: formatTimeForRender,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
    },
  ];

  const mainMenu = _.compact([
    hasAuth(authDict.sopscheme_add) && {
      title: '新建SOP方案',
      icon: 'iconxinjiantianjia',
      isPureDropdown: true,
      items: [
        {
          title: '生产业务类型',
          onClick: () => {
            history.push(generatePath(schemePath.create, { bizType: BizType.production }));
          },
        },
        {
          title: '通用业务类型',
          onClick: () => {
            history.push(generatePath(schemePath.create, { bizType: BizType.general }));
          },
        },
        {
          title: '维保业务类型',
          onClick: () => {
            history.push(generatePath(schemePath.create, { bizType: BizType.maintenance }));
          },
        },
        {
          title: '维修业务类型',
          onClick: () => {
            history.push(generatePath(schemePath.create, { bizType: BizType.repair }));
          },
        },
      ],
    },
  ]);

  const filterList = dataColumns
    .filter((i) => i.isFilter)
    .map((column) => {
      const filter = {
        label: column.title,
        name: column.dataIndex,
        type: column.type,
        renderItem: column.renderItem,
      } as any;

      if (column.selectProps) {
        filter.selectProps = column.selectProps;
      }

      return filter;
    });

  const formatDataToQuery = useCallback((values: any) => {
    const { creatorId, createdAt } = values;
    const submitValue = {
      ...values,
      creatorId: creatorId ? _.map(creatorId, 'id') : undefined,
      createdAt: formatTimeRangeForQuery(createdAt),
    };

    return submitValue;
  }, []);

  const getOperationList = (record: SopOverviewData) => {
    const { sopId, status } = record;

    return [
      {
        title: '查看',
        auth: authDict.sopscheme_detail,
        onClick: () => history.push(`/sop/processEngine/scheme/${sopId}/detail`),
      },
      {
        title: '编辑',
        auth: authDict.sopscheme_edit,
        disabled: status === UsageStatus.enabled,
        onClick: () => history.push(`/sop/processEngine/scheme/${sopId}/edit`),
      },
      {
        title: lookup('common.changeStatusAction', status ?? UsageStatus.enabled),
        auth: authDict.sopscheme_enable_disable,
        onClick: () => {
          const fn = status === UsageStatus.enabled ? fetchSopDisable : fetchSopEnable;
          const action = lookup('common.changeStatusAction', status);

          fn({ sopId }).then(() => {
            message.success(`${action}成功！`);
            refresh();
          });
        },
      },
      {
        title: '复制',
        auth: authDict.sopscheme_add,
        onClick: () => {
          setCopyModalState({
            visible: true,
            fromSopName: record.name,
            bizType: record.bizType,
            sopId,
            referenceId: record.referenceId,
          });
        },
      },
      {
        title: '删除',
        auth: authDict.sopscheme_remove,
        disabled: status !== UsageStatus.disabled,
        onClick: () => {
          Modal.confirm({
            title: '确认要删除当前SOP方案吗？',
            onOk: () => handleDeleteSop(sopId),
          });
        },
      },
      {
        title: '操作记录',
        auth: authDict.sopscheme_operrecord,
        onClick: () => history.push(`/sop/processEngine/scheme/${sopId}/operationLog`),
      },
    ];
  };

  return (
    <>
      <RecordListLayout<any>
        columns={dataColumns}
        filterList={filterList}
        rowKey={'sopId'}
        mainMenu={mainMenu}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={_.identity}
        formatDataToFormDisplay={_.identity}
        configcacheKey={'sop'}
        requestFn={fetchSopList}
        refreshMarker={refreshMarker}
        userAuth={getAuthFromLocalStorage()}
        getOperationList={getOperationList}
      />
      <CopySopModal
        onCancel={() => setCopyModalState(initialCopyModalState)}
        onSuccess={refresh}
        {...copyModalState}
      />
    </>
  );
};

export default SOPList;
