/**
 * @page SOP方案详情
 */
import { useCallback, useEffect, useState } from 'react';
import { DetailLayout, DetailLayoutInfoBlock, DetailLayoutMenuItem } from 'layout';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { renderUsageStatus } from 'src/page/share/renderUsageStatus';
import { valueOrHolder } from 'src/utils/formatters';
import StepTable from '../components/stepTable';
import {
  fetchSopDelete,
  fetchSopDetail,
  fetchSopDisable,
  fetchSopEnable,
} from 'src/api/ytt/sop-domain';
import { RouterProps, useParams } from 'react-router-dom';
import _ from 'lodash';
import lookup from 'src/dict';
import { UsageStatus } from 'src/dict/common';
import { message, Modal } from 'antd';
import { SopDetailsResponseData } from '../types';
import { renderUser } from 'src/page/share/renderUser';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';

interface RouteParams {
  id: string;
}

interface Props extends RouterProps {}
const SOPDetial = (props: Props) => {
  const { history } = props;
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<SopDetailsResponseData>();
  const handleDeleteSop = useCallback((id) => {
    fetchSopDelete({ sopId: id }).then(() => {
      message.success('删除成功');
      history.push('/sop/processEngine/scheme');
    });
  }, []);
  const { id } = useParams<RouteParams>();
  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      { label: '编号', dataIndex: 'code' },
      { label: '名称', dataIndex: 'name' },
      {
        label: 'SOP方案版本号',
        dataIndex: 'version',
        render: valueOrHolder,
      },
      {
        label: '业务类型',
        dataIndex: 'bizType',
        render: lookup('sop.bizType'),
      },
      {
        label: '状态',
        dataIndex: 'status',
        render: renderUsageStatus,
      },
      {
        label: '创建人',
        dataIndex: 'creatorId',
        render: renderUser,
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: formatTimeForRender,
      },
      {
        label: '更新人',
        dataIndex: 'operatorId',
        render: renderUser,
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: formatTimeForRender,
      },
    ],
    column: 2,
  };
  const processInfo: DetailLayoutInfoBlock = {
    title: '流程',
    items: [
      {
        dataIndex: 'steps',
        render: () => (
          <StepTable referenceId={dataSource?.referenceId as number} steps={dataSource?.steps} />
        ),
      },
    ],
  };
  const infoArray = [baseInfo, processInfo];
  const baseMenu: DetailLayoutMenuItem[] = [
    {
      title: lookup('changeStatusAction', dataSource?.status),
      key: 'enableOrDisable',
      auth: authDict.sopscheme_enable_disable,
      icon: dataSource?.status === UsageStatus.disabled ? 'iconqiyong' : 'icontingyong',
      onClick: () => {
        const fn = dataSource?.status === UsageStatus.enabled ? fetchSopDisable : fetchSopEnable;
        const action = lookup('changeStatusAction', dataSource?.status);

        fn({ sopId: dataSource?.sopId }).then(() => {
          message.success(`${action}成功！`);
          fetchDetails();
        });
      },
    },
    {
      title: '删除',
      key: 'delete',
      auth: authDict.sopscheme_remove,
      disabled: dataSource?.status === UsageStatus.enabled,
      onClick: () => {
        Modal.confirm({
          title: '确认要删除当前SOP方案吗？',
          onOk: () => handleDeleteSop(id),
        });
      },
    },
    {
      title: '查看操作记录',
      key: 'record',
      auth: authDict.sopscheme_operrecord,
      onClick: () => {
        history.push(`/sop/processEngine/scheme/${id}/detail/operationLog`);
      },
    },
    {
      title: '编辑',
      key: 'edit',
      auth: authDict.sopscheme_edit,
      icon: 'iconbianji',
      disabled: dataSource?.status === UsageStatus.enabled,
      onClick: () => {
        history.push(`/sop/processEngine/scheme/${id}/edit`);
      },
    },
  ];

  const fetchDetails = async () => {
    setLoading(true);
    try {
      const { data } = await fetchSopDetail({ sopId: _.toNumber(id) }, { legacy: true });

      setDataSource(data as SopDetailsResponseData);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);
  return (
    <DetailLayout
      title="SOP方案详情"
      info={infoArray}
      dataSource={dataSource}
      baseMenu={baseMenu}
      loading={loading}
      userAuth={getAuthFromLocalStorage()}
    />
  );
};

export default SOPDetial;
