import { BUSINESS_TYPE, EXCEL_TYPE } from 'src/dict/objImport';
import { fetchExportExcelCustomObjectExport, FetchExportExcelExportResponse } from 'ytt/import';
import { message as Message } from 'antd';

type ExportRequestBodyType = {
  applicationName: string;
  businessType: string;
  excelType?: number;
  businessData: any;
};

/** 导出文件 */
export const exportObjectFile = async (props: ExportRequestBodyType) => {
  const { businessType } = props;
  const params = {
    excelType: EXCEL_TYPE.customObject,
    ...props,
  };

  if (businessType === BUSINESS_TYPE.workerCalendar) {
    params.excelType = EXCEL_TYPE.special;
  }
  const response: FetchExportExcelExportResponse = await fetchExportExcelCustomObjectExport(params);
  const { code, message } = response;

  if (code === 200) {
    Message.success('操作成功，可通过日志查看导出情况');
  } else {
    Message.error(`导出失败：${message}`);
  }
};
