import { ReactNode } from 'react';
import { Input, Select, Form, Radio, Space, Checkbox } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import lookup, { appDict, appEnum } from 'src/dict';
import MyDatePicker from '../share/FormItemComponents/MyDatePicker';
import classNames from 'classnames';
import styles from './styles.module.scss';

const { triggerRule } = appDict;
const { TriggerRule } = appEnum;

const withCom = (render: ReactNode, label: string, ...restProps: any) => (
  <Form.Item label={label} {...restProps}>
    {render}
  </Form.Item>
);

export const CustomizeItem = ({ execTime, execTimeUnit, finishCount, finishTime }: any) => {
  return (
    <>
      <Form.Item label="触发" className={styles.innerFormItemGlobal}>
        <Space>
          <div>首次触发之后，每</div>
          <Form.Item style={{ width: '120px' }}>
            <Input value={execTime} disabled />
          </Form.Item>
          <Form.Item style={{ width: '100px' }}>
            <Select value={execTimeUnit} options={triggerRule.delayTriggerTimeUnit} disabled />
          </Form.Item>
          <div>触发一次</div>
        </Space>
      </Form.Item>
      <Form.Item label="结束触发" className={styles.innerFormItemGlobal}>
        <Space>
          <div>累计触发</div>
          <Form.Item style={{ width: '120px' }}>
            <Input value={finishCount} disabled />
          </Form.Item>
          <div>次</div>
        </Space>
        <div className={styles.or}>或</div>
        <Space>
          <div>时间到达</div>
          <Form.Item>
            <MyDatePicker width={160} format="YYYY-MM-DD HH:mm:ss" value={finishTime} disabled />
          </Form.Item>
        </Space>
        <div className={styles.or}>或</div>
        <span>数据变更，导致数据不满足触发条件</span>
      </Form.Item>
    </>
  );
};

export const EffectiveType = ({
  effectiveType,
  delayTriggerField,
  delayTriggerCondition,
  delayTriggerTime,
  delayTriggerTimeUnit,
}: any) => (
  <>
    <Form.Item
      className={classNames(styles.innerFormItem, styles.noneMarginBottom)}
      style={{ marginBottom: 0 }}
    >
      <Radio disabled checked>
        {lookup('triggerRule.effectiveType', effectiveType)}
      </Radio>
    </Form.Item>
    {effectiveType === TriggerRule.EffectiveType.immediately ? null : (
      <Space className={classNames(styles.innerFormItemGlobal, styles.spaceTop)}>
        <Form.Item>
          <Select labelInValue value={delayTriggerField} disabled />
        </Form.Item>
        <Form.Item>
          <Select
            value={delayTriggerCondition}
            disabled
            options={triggerRule.delayTriggerCondition}
          />
        </Form.Item>
        <Form.Item>
          <Input value={delayTriggerTime} disabled />
        </Form.Item>
        <Form.Item>
          <Select
            disabled
            value={delayTriggerTimeUnit}
            options={triggerRule.delayTriggerTimeUnit}
          />
        </Form.Item>
      </Space>
    )}
  </>
);

export const TimeIntervalTrigger = (props: any) => {
  const {
    triggerEveryDayTime,
    triggerWeekDay,
    triggerMonthDay,
    triggerYearMonth,
    triggerDays,
    startTime,
    execType,
  } = props;

  const getTimeIntervalItem = (render: (value: string) => ReactNode, values: string[] = []) =>
    values?.map((value) => withCom(render(value), '每日'));
  const TriggerEveryDayTime = getTimeIntervalItem(
    (value: string) => <MyDatePicker picker="time" format="HH:mm:ss" value={value} disabled />,
    triggerEveryDayTime,
  );
  const TriggerWeekDay = withCom(
    <Checkbox.Group options={triggerRule.triggerWeekDay} disabled value={triggerWeekDay} />,
    '每周',
  );
  const triggerMonthDayOptions = Array.from({ length: 31 }, (_, index) => String(index + 1)).map(
    (item) => ({
      label: item,
      value: item.padStart(2, '0'),
    }),
  );
  const tagRender = (props: any) => <span>{props.label},</span>;

  const TriggerMonthDay = withCom(
    <Select
      options={triggerMonthDayOptions}
      disabled
      value={triggerMonthDay}
      mode="multiple"
      tagRender={tagRender}
    />,
    '每月几号',
  );
  const triggerYearMonthOptions = triggerRule.triggerYearMonth.map(({ label, value }) => ({
    label,
    value: String(value).padStart(2, '0'),
  }));
  const TriggerYearMonth = withCom(
    <Checkbox.Group options={triggerYearMonthOptions} disabled value={triggerYearMonth} />,
    '每月',
  );
  const TriggerDays = withCom(<TextArea disabled value={triggerDays} />, '日期');

  const getExecTypeItems = (execTypeValue: number) => {
    switch (execTypeValue) {
      case TriggerRule.ExecType.everyDay:
        return TriggerEveryDayTime;
      case TriggerRule.ExecType.everWeek:
        return (
          <>
            {TriggerWeekDay}
            {TriggerEveryDayTime}
          </>
        );
      case TriggerRule.ExecType.everyMonth:
        return (
          <>
            {TriggerMonthDay}
            {TriggerEveryDayTime}
          </>
        );
      case TriggerRule.ExecType.everyYear:
        return (
          <>
            {TriggerYearMonth}
            {TriggerMonthDay}
            {TriggerEveryDayTime}
          </>
        );
      case TriggerRule.ExecType.fixedCycle:
        return (
          <>
            <Form.Item label="首次触发时间">
              <MyDatePicker
                key="triggerEveryDayTime"
                format="YYYY-MM-DD HH:mm:ss"
                value={startTime}
                disabled
              />
            </Form.Item>
            <CustomizeItem {...props} />
          </>
        );
      case TriggerRule.ExecType.customize:
        return (
          <>
            {TriggerDays}
            {TriggerEveryDayTime}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Form.Item>
        <Radio.Group disabled options={triggerRule.execType} value={execType} />
      </Form.Item>
      {getExecTypeItems(execType)}
    </>
  );
};
