import React, { useContext, useState } from 'react';
import { DataFormLayoutForModal } from 'layout';
import { Checkbox, Form, message as Message } from 'antd';
import { LEVEL_TYPE_ENUM, StoreContext } from '../constant';
import AreaBaseInfo from '../components/areCreateAndEditBaseInfo';
import { fetchStorageAreaCreate } from 'src/api/ytt/storage-domain/area';
import { throttleFn } from '../utils';

interface createProps {
  visible: Boolean;
  onCancel: () => void;
}

const CreateArea = (props: createProps) => {
  const { visible, onCancel } = props;

  const [continuousCreate, setContinuousCreate] = useState(false);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const { parentAreaSource, storeHouseSource } = useContext(StoreContext);
  const { parentAreaId, parentAreaLevel } = parentAreaSource;

  const { StoreHouseId } = storeHouseSource;

  const [modalForm] = Form.useForm();

  const extra = (
    <Checkbox
      onChange={(e) => {
        setContinuousCreate(e?.target?.checked);
      }}
    >
      连续新建
    </Checkbox>
  );

  const handleFinish = async () => {
    try {
      let value = await modalForm?.validateFields();

      value =
        parentAreaLevel === LEVEL_TYPE_ENUM?.STORE
          ? { ...value, warehouseId: StoreHouseId }
          : { ...value, warehouseId: StoreHouseId, parentAreaId };

      const { code, message } = await fetchStorageAreaCreate(value);

      if (code === 200) {
        Message.success('新建区域成功');
        if (continuousCreate) {
          modalForm.resetFields();
          setRefreshMarker(Date.now());
          return;
        }
        onCancel && onCancel();
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      onClose={handleCancel}
      width={800}
      content={
        <AreaBaseInfo
          onCancel={onCancel}
          onFinish={throttleFn(handleFinish)}
          title="新建区域"
          form={modalForm}
          edit={false}
          extra={extra}
          refreshMarker={refreshMarker}
        />
      }
    />
  );
};

export default CreateArea;
