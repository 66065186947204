import React from 'react';
import { Button, Modal } from 'antd';
import SoftwareAgreement from './softwareAgreement';
import PrivacyAgreement from './privacyAgreement';
import styles from './styles.module.scss';

interface Props {
  visible: boolean;
  privacyType: number;
  onCancel: () => void;
}

export default function BlackPrivacyModal({ visible, privacyType, onCancel }: Props) {
  return (
    <Modal
      closable={false}
      maskClosable={false}
      visible={Boolean(visible)}
      width={1000}
      title={privacyType ? <div>个人信息保护政策</div> : <div>黑湖软件服务协议</div>}
      onCancel={onCancel}
      className={styles.privacyModal}
      footer={[
        <Button key="back" onClick={onCancel} type="primary">
          知道了
        </Button>,
      ]}
      centered
    >
      {privacyType ? <PrivacyAgreement /> : <SoftwareAgreement />}
    </Modal>
  );
}
