import React, { useEffect, useState } from 'react';
import { DataFormLayoutForModal } from 'layout';
import { Form, message as Message } from 'antd';
import AreaBaseInfo from '../components/areCreateAndEditBaseInfo';
import { fetchStorageAreaInfo, fetchStorageAreaModify } from 'src/api/ytt/storage-domain/area';
import { throttleFn } from '../utils';

interface editProps {
  visible: Boolean;
  onCancel: () => void;
  refreshTreeSource: () => void;
  refreshDetails?: () => void;
  id: number;
}

const AreaEdit = (props: editProps) => {
  const { visible, onCancel, id, refreshTreeSource, refreshDetails } = props;

  const [detailData, setDetailData] = useState({} as any);

  const [modalForm] = Form.useForm();

  const fetchData = async () => {
    try {
      const { data } = await fetchStorageAreaInfo({ id });

      if (data) {
        setDetailData(data);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();
      const jsonData = {
        ...value,
        id: detailData?.id,
        warehouseId: detailData?.warehouseId,
        parentAreaId: detailData?.parentId,
      };
      const { code, message } = await fetchStorageAreaModify(jsonData);

      if (code === 200) {
        Message.success('编辑成功');
        typeof onCancel === 'function' && onCancel();
        typeof refreshDetails === 'function' && refreshDetails();
        typeof refreshTreeSource === 'function' && refreshTreeSource();
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      width={800}
      onClose={onCancel}
      content={
        <AreaBaseInfo
          onCancel={onCancel}
          onFinish={throttleFn(handleFinish)}
          title="编辑区域"
          initialData={detailData}
          form={modalForm}
          edit
        />
      }
    />
  );
};

export default AreaEdit;
