import G6, { Graph, IG6GraphEvent } from '@antv/g6';
import { useEffect, useRef } from 'react';
import {
  fetchTraceTraceRelationshipInventoryGraphRelationships,
  FetchTraceTraceRelationshipInventoryGraphRelationshipsResponse,
} from 'src/api/ytt/trace-domain/traceRelationship';
import { match, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { Button, Form, Input } from 'antd';
import {
  fetchTraceTraceNodeInventoryGetNodeDetail,
  FetchTraceTraceNodeInventoryGetNodeDetailResponse,
} from 'src/api/ytt/trace-domain/traceNode';
import styles from './style.module.scss';
import moment from 'moment';
import { replaceSign } from 'src/utils/constants';
import { toTraceNodeDetail } from 'src/page/trace';
import { numberMinMaxCheck } from 'src/utils/formValidators';
import { ReletiveLevel } from './config';
import { Node } from './node';
import { getNeighbors } from './utils';

interface Props extends RouteComponentProps {
  match: match<{ id: string }>;
}

let graph: Graph | null = null;
let timerId: any = 0;

const TraceChart = (props: Props) => {
  let traceId = Number(props.match?.params?.id);
  const ref = useRef(null);
  const [form] = Form.useForm();

  // 自定义节点
  G6.registerNode('node', Node);

  // 插件tooltip的内容
  const getToolTipContainer = (
    data?: FetchTraceTraceNodeInventoryGetNodeDetailResponse['data'],
  ) => {
    if (!data) {
      data = {};
    }
    return `
      <ul style="min-width: 246px; min-height: 174px;">
        <li style="
          margin-bottom: 14px;
          font-size: 14px; color: #262626;
          font-weight: 700;
          display: flex;
          justify-content: space-between;
          align-items: center;
          user-select: none;
        ">
          <div style="margin-right: 20px">
            <span>批次号：</span>
            <span>${data?.traceNode?.batchNo || '-'}</span>
          </div>
          <a onclick="traceChartClickEvent(${data?.traceNode?.traceId})">详情</a>
        </li>
        ${getDescription('标识码', data?.traceNode?.qrCode)}
        ${getDescription('物料名称', data?.traceNode?.material?.name)}
        ${getDescription('物料编号', data?.traceNode?.material?.code)}
        ${getDescription('供应商名称', data?.supplier?.name)}
        ${getDescription('供应商批次', data?.supplier?.supplierBatchNo)}
        ${getDescription(
          '生产日期',
          data?.productionTime
            ? moment(data?.productionTime).format('YYYY-MM-DD HH:mm:ss')
            : replaceSign,
        )}
      </ul>
    `;
  };

  const getDescription = (label: string, value?: string) => {
    return `
      <li style="user-select: none; margin-top: 8px; line-height: 20px; font-size: 12px; color: #262626">
        <span>${label}：</span>
        <span>${value || '-'}</span>
      </li>
    `;
  };

  // 路由跳转挂载到window对象上，便于innerHTML访问
  window.traceChartClickEvent = (id: string) => {
    if (!id) return;
    window.reactRouterHistoryInstance.push(toTraceNodeDetail(Number(id)));
  };

  const tooltip = new G6.Tooltip({
    offsetX: 0,
    offsetY: -5,
    itemTypes: ['node'],
    className: styles.g6ToolTip,
    fixToNode: [0.5, 1],
    shouldBegin: (evt?: IG6GraphEvent) => {
      if (evt?.item?.hasState('tooltip')) {
        return true;
      }
      return false;
    },
    getContent: () => {
      const outDiv = document.createElement('div');

      outDiv.style.width = 'fit-content';

      outDiv.innerHTML = getToolTipContainer(window.traceItemData as any);

      return outDiv;
    },
  });

  // 获取节点数据与当前节点的关联程度，UI分四种样式
  const getRelative = (id: number, neighbors: number[], nextNeighbors: number[]) => {
    let level = ReletiveLevel.xs;

    if (nextNeighbors.includes(id)) {
      level = ReletiveLevel.sm;
    }
    if (neighbors.includes(id)) {
      level = ReletiveLevel.md;
    }
    if (id === traceId) {
      level = ReletiveLevel.lg;
    }
    return level;
  };

  const formatterData = (res: FetchTraceTraceRelationshipInventoryGraphRelationshipsResponse) => {
    // formatter数据
    const neighbors = getNeighbors(traceId, res.data?.edges);
    const nextNeighbors = _.flatten(
      neighbors.map((item: number) => {
        return getNeighbors(item, res.data?.edges);
      }),
    );

    return {
      ...res,
      data: {
        nodes: _.map(res.data?.nodes, (item) => {
          return {
            ...item,
            relativeLevel: getRelative(item.id, neighbors, nextNeighbors),
            id: String(item.id),
          };
        }),
        edges: _.map(res.data?.edges, (item) => {
          const targetRelative = getRelative(item.target, neighbors, nextNeighbors);
          const sourceRelative = getRelative(item.source, neighbors, nextNeighbors);

          return {
            ...item,
            target: String(item.target),
            relativeLevel: Math.min(targetRelative, sourceRelative),
            label: item.operationType?.message,
            source: String(item.source),
          };
        }),
      },
    };
  };

  const initData = async (traceId: number) => {
    // 获取并格式化数据
    const values = form.getFieldsValue();

    return fetchTraceTraceRelationshipInventoryGraphRelationships(
      {
        level: Number(values.level),
        traceId,
      },
      { legacy: false },
    ).then(formatterData);
  };

  const initChart = () => {
    // 创建图表实例
    if (ref?.current) {
      const tranceChartContainer = document.getElementById('tranceWrapId');

      graph = new G6.Graph({
        container: ref?.current,
        height: tranceChartContainer?.offsetHeight,
        width: tranceChartContainer?.offsetWidth,
        plugins: [tooltip],
        autoPaint: false,
        minZoom: 0.2,
        animate: false,
        modes: {
          default: ['zoom-canvas', 'drag-canvas', 'drag-node'],
        },
        edgeStateStyles: {
          disable: {
            opacity: 0.2,
            'text-shape': {
              fill: 'rgba(0,0,0,0.1)',
            },
          },
        },
        layout: {
          type: 'force',
          preventOverlap: true,
          linkDistance: 120,
          nodeStrength: -220,
          collideStrength: 1,
          nodeSize: 130,
          nodeSpacing: 40,
          alphaDecay: 0.2,
        },
        defaultNode: {
          type: 'node',
        },
        defaultEdge: {
          type: 'line',
          style: {
            stroke: '#D8D8D8',
            opacity: 1,
            endArrow: {
              path: G6.Arrow.vee(3, 5, 5),
              d: 5,
            },
          },
          labelCfg: {
            autoRotato: true,
            style: {
              fill: '#8C8C8C',
            },
          },
        },
      });

      graph?.on('node:click', (e: any) => {
        // 点击节点，突出相关节点和边
        const activeNodes = e?.item?.getNeighbors?.().concat([e.item]);
        const activeEdges = e?.item?.getEdges();
        const nodes = graph?.getNodes();
        const edges = graph?.getEdges();

        tooltip.hide();
        nodes?.forEach((item: any) => {
          graph?.setItemState(item, 'disable', true);
        });

        edges?.forEach((item: any) => {
          graph?.setItemState(item, 'disable', true);
        });

        activeNodes.forEach((item: any) => {
          graph?.clearItemStates(item, 'disable');
        });

        activeEdges.forEach((item: any) => {
          graph?.clearItemStates(item, 'disable');
        });
      });

      graph?.on('canvas:click', () => {
        // 画布点击恢复显示
        const nodes = graph?.getNodes();
        const edges = graph?.getEdges();

        nodes?.forEach((item: any) => {
          graph?.clearItemStates(item, 'disable');
        });

        edges?.forEach((item: any) => {
          graph?.clearItemStates(item, 'disable');
        });
      });

      graph?.on('node:dblclick', (e: any) => {
        // 单击切换当前节点
        const { id } = e.item._cfg;

        traceId = Number(id);
        refresh();
      });
      graph?.on('node:mouseover', (e: any) => {
        graph?.setItemState(e.item, 'hover', true);
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          // 延迟1000毫秒触发
          fetchTraceTraceNodeInventoryGetNodeDetail({ traceId: Number(e.item._cfg.id) }).then(
            (res) => {
              const { data = {} } = res;

              graph?.setItemState(e.item, 'tooltip', true);
              window.traceItemData = {
                ...e.item.getModel(),
                ...data,
              };
              tooltip.showTooltip(e);
            },
          );
        }, 700);
      });
      graph?.on('node:mouseleave', (e: any) => {
        graph?.clearItemStates(e.item, 'hover');
        graph?.clearItemStates(e.item, 'tooltip');
        clearTimeout(timerId);
      });
    }
  };

  const refresh = () => {
    // 查询时刷新数据并渲染
    initData(traceId).then((res) => {
      graph?.changeData(res.data || {});
      graph?.refresh();
    });
  };

  useEffect(() => {
    if (traceId && ref?.current) {
      initData(traceId).then((res) => {
        initChart(); // 实例化图表
        graph?.data(res.data || {}); // 设置图表数据
        graph?.render();
      });
    }
  }, [traceId]);

  return (
    <div id="tranceWrapId" style={{ background: '#fff', width: '100%', height: '100%' }}>
      <Form
        onFinish={() => refresh()}
        form={form}
        style={{
          position: 'absolute',
          padding: '16px 0 0 16px',
        }}
        layout="inline"
      >
        <Form.Item
          initialValue={5}
          name="level"
          extra="查询的层数"
          rules={[
            { required: true, message: '请输入层数' },
            {
              validator: numberMinMaxCheck({
                min: 0,
                minAllowEqual: false,
                isInteger: true,
              }),
            },
          ]}
        >
          <Input placeholder="层数" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            查询
          </Button>
        </Form.Item>
      </Form>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '100%', height: '100%' }} ref={ref} />
      </div>
    </div>
  );
};

export default TraceChart;
