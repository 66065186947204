import ProductionOrderList from './productionOrder/list';
import ProductionOrderCreateAndEdit from './productionOrder/createAndEdit';
import ProductionOrderDeatil from './productionOrder/detail';
import StoreRequisitionList from './storeRequisition/StoreRequisitionList';
import BatchCreateStoreRequisition from './storeRequisition/BatchCreateStoreRequisition';
import SingleStoreRequisitionOperator from './storeRequisition/SingleStoreRequisitionOperator';
import IntegratePick from './storeRequisition/IntegratePick';
import StoreRequisitionDetail from './storeRequisition/StoreRequisitionDetail';
import MergeTransferOrder from './storeRequisition/MergeTransferOrder';
import ProcessPlanCOList from './processPlanCO/list';
import BomInputMaterialsList from './bomInputMaterials/list';
import ProductionTaskList from './productionTask';
import ProductionDetail from './productionTask/detail';
import ProductionOrderLog from './productionOrder/log/list';
import ProductionOrderLogDetail from './productionOrder/log/detail';

import { engineerDataRouteConfig } from 'src/page/knowledgeManagement/engineerData';
import authDict from 'src/utils/auth';
import EditProductionTask from 'src/page/productionPlanning/productionTask/edit';
import ProgressReportList from 'src/page/productionPlanning/progressReport/list';
import ProgressReportDetail from 'src/page/productionPlanning/progressReport/detail';

import FeedingRecordList from 'src/page/productionPlanning/feedingRecord/list';
import FeedingRecordDetail from 'src/page/productionPlanning/feedingRecord/detail';
import FeedingRetractRecordList from 'src/page/productionPlanning/feedingRetractRecord/list';

export const PRODUCTION_PLANNING_PATH = '/productionPlanning/execution';

const productionPlanningRouteConfig = [
  {
    path: '/productionPlanning',
    menu: '生产',
    breadcrumb: '生产',
    icon: 'iconyijicaidan-shengchan',
    redirectPath: `${PRODUCTION_PLANNING_PATH}/productionOrder`,
    children: [
      {
        path: `${PRODUCTION_PLANNING_PATH}`,
        menu: '生产执行',
        breadcrumb: '生产执行',
        redirectPath: `${PRODUCTION_PLANNING_PATH}/productionOrder`,
        children: [
          {
            path: `${PRODUCTION_PLANNING_PATH}/productionOrder`,
            component: ProductionOrderList,
            menu: '生产工单',
            logPage: true,
            breadcrumb: '生产工单',
            auth: authDict.productionorder_view,
            children: [
              {
                path: `${PRODUCTION_PLANNING_PATH}/productionOrder/create`,
                component: ProductionOrderCreateAndEdit,
                menu: '新建生产工单',
                breadcrumb: '新建生产工单',
                auth: authDict.productionorder_edit,
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/productionOrder/edit/:id`,
                component: ProductionOrderCreateAndEdit,
                menu: '编辑生产工单',
                breadcrumb: '编辑生产工单',
                auth: authDict.productionorder_edit,
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/productionOrder/copy/:id`,
                component: ProductionOrderCreateAndEdit,
                menu: '复制生产工单',
                breadcrumb: '复制生产工单',
                auth: authDict.productionorder_edit,
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/productionOrder/detail/:id`,
                component: ProductionOrderDeatil,
                menu: '生产工单详情',
                breadcrumb: '生产工单详情',
                auth: authDict.productionorder_detail,
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/productionOrder/log`,
                component: ProductionOrderLog,
                menu: '下发日志',
                breadcrumb: '下发日志',
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/productionOrder/log/detail/:id`,
                component: ProductionOrderLogDetail,
                menu: '下发日志详情',
                breadcrumb: '下发日志详情',
              },
            ],
          },
          {
            path: `${PRODUCTION_PLANNING_PATH}/bomInputMaterials`,
            component: BomInputMaterialsList,
            menu: '用料清单',
            breadcrumb: '用料清单',
            auth: authDict.productionorder_view,
          },
          {
            path: `${PRODUCTION_PLANNING_PATH}/processPlanCO`,
            component: ProcessPlanCOList,
            menu: '工序计划',
            breadcrumb: '工序计划',
            auth: authDict.productionorder_view,
          },
          {
            path: `${PRODUCTION_PLANNING_PATH}/productManagement/storeRequisition`,
            menu: '领料申请',
            component: StoreRequisitionList,
            breadcrumb: '领料申请',
            logPage: true,
            auth: authDict.pickorder_view,
            children: [
              {
                path: `${PRODUCTION_PLANNING_PATH}/productManagement/storeRequisition/batchCreate`,
                auth: authDict.pickorder_add,
                component: BatchCreateStoreRequisition,
                breadcrumb: '新建',
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/productManagement/storeRequisition/create`,
                auth: authDict.pickorder_add,
                component: SingleStoreRequisitionOperator,
                breadcrumb: '新建',
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/productManagement/storeRequisition/edit/:id`,
                auth: authDict.pickorder_edit,
                component: SingleStoreRequisitionOperator,
                breadcrumb: '编辑',
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/productManagement/storeRequisition/integratePick`,
                auth: authDict.pickorder_add,
                component: IntegratePick,
                breadcrumb: '齐套领料',
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/productManagement/storeRequisition/detail/:id`,
                auth: authDict.pickorder_detail,
                component: StoreRequisitionDetail,
                breadcrumb: '详情',
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/productManagement/storeRequisition/mergeTransferOrder`,
                auth: authDict.transferorder_add,
                component: MergeTransferOrder,
                breadcrumb: '领料调拨',
              },
            ],
          },
          {
            path: `${PRODUCTION_PLANNING_PATH}/productionTaskList`,
            auth: authDict.producetask_view,
            component: ProductionTaskList,
            menu: '生产任务',
            breadcrumb: '生产任务',
            children: [
              {
                path: `${PRODUCTION_PLANNING_PATH}/productionTaskList/:id/detail`,
                auth: authDict.producetask_detail,
                component: ProductionDetail,
                breadcrumb: '详情',
              },
              {
                path: `${PRODUCTION_PLANNING_PATH}/productionTaskList/:id/edit`,
                auth: authDict.producetask_edit,
                component: EditProductionTask,
                breadcrumb: '编辑',
              },
            ],
          },
          {
            path: `${PRODUCTION_PLANNING_PATH}/feedingRecord`,
            auth: authDict.materialfeedrecord_view,
            menu: '投料记录',
            logPage: true,
            breadcrumb: '投料记录',
            component: FeedingRecordList,
            children: [
              {
                path: `${PRODUCTION_PLANNING_PATH}/feedingRecord/detail`,
                auth: authDict.materialfeedrecord_detail,
                component: FeedingRecordDetail,
                breadcrumb: '详情',
              },
            ],
          },
          {
            path: `${PRODUCTION_PLANNING_PATH}/feedingRetractRecord`,
            auth: authDict.materialretractrecord_view,
            logPage: true,
            menu: '投料回撤记录',
            breadcrumb: '投料回撤记录',
            component: FeedingRetractRecordList,
          },
          {
            path: `${PRODUCTION_PLANNING_PATH}/progressReport`,
            auth: authDict.progressreportrecord_view,
            component: ProgressReportList,
            menu: '报工记录',
            breadcrumb: '报工记录',
            logPage: true,
            children: [
              {
                path: `${PRODUCTION_PLANNING_PATH}/progressReport/detail/:id`,
                component: ProgressReportDetail,
                breadcrumb: '详情',
              },
            ],
          },
        ],
      },
      ...engineerDataRouteConfig,
    ],
  },
];

export default productionPlanningRouteConfig;
