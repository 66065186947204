import { map, omit } from 'lodash';
import { arrayIsEmpty } from 'src/utils/array';
import { GroupTypesEnum, BIZTYPE_TO_DISPLAY, GRPUPTYPE_TO_DISPLAY } from './constants';

export const wcgCOlistToForm = (_workCenterGroupCOList: { [key: string]: number }[]) => {
  return _workCenterGroupCOList?.map((item) => {
    const { groupType, bizType, ...rest } = item;

    let bizList: any = [];

    bizList = item[GRPUPTYPE_TO_DISPLAY.get(groupType) as string];

    if (groupType === GroupTypesEnum.USER) {
      bizList = item[BIZTYPE_TO_DISPLAY.get(bizType) as string];
    }

    if (groupType === GroupTypesEnum.GROUPTYPE) {
      bizList = map(
        item[GRPUPTYPE_TO_DISPLAY.get(groupType) as string],
        ({ paramsVOList, ...rest }) => ({
          ...rest,
          resourceParamsList:
            !arrayIsEmpty(paramsVOList) &&
            map(paramsVOList, ({ ...resourceRest }) => ({
              ...resourceRest,
              param: {
                value: JSON.stringify({ ...resourceRest, id: resourceRest?.paramsId }),
                label: resourceRest.paramsName,
              },
            })),
        }),
      );
    }

    return {
      ...rest,
      groupType,
      bizType,
      bizList,
    };
  });
};
// workCenterGroupCOList
export const wcgCOlistToApi = (workCenterGroupCOList: any) => {
  const COlist = map(workCenterGroupCOList, ({ bizList, ...rest }) => ({
    ...rest,
    bizList: !arrayIsEmpty(bizList)
      ? map(bizList, ({ id, resourceParamsList }) => ({
          id,
          paramsCOList: !arrayIsEmpty(resourceParamsList)
            ? map(resourceParamsList, ({ param, ...paramRest }) => ({
                ...JSON.parse(param?.value),
                ...omit({ ...paramRest }, ['id']),
                paramsId: param?.value && JSON.parse(param?.value)?.id,
              }))
            : undefined,
        }))
      : [],
  }));

  return COlist;
};
