import React, { useState } from 'react';
import { Button, message as Message, Modal, Progress, Space } from 'antd';
import { BUSINESS_TYPE, EXCEL_TYPE } from 'src/dict/objImport';
import { fetchImportExcelCustomObjectImport, FetchImportExcelImportResponse } from 'ytt/import';
import { UploadFile } from 'antd/lib/upload/interface';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './import.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import PageHeader from 'src/page/import/components/PageHeader';
import { qs } from 'utils';
import CardContainer from 'src/page/import/components/CardContainer';
import { CUSTOM_TEMPLATE_HELP_TIPS, FILE_EXTENSION_LIMIT_HINT } from 'src/page/import/constaint';
import { DownloadOutlined } from '@ant-design/icons';
import { BcUpload } from 'components';
import { downloadImportTemplate } from 'src/services/file';

const Import = () => {
  const history = useHistory();
  const { search } = useLocation();
  const businessData = useSelector((state: RootState) => state.excelImport.businessData);
  const [uploadedFileId, setUploadedFileId] = useState<number | null>(null);
  const [uploadedFileUrl, setUploadedFileUrl] = useState<string | null>(null);
  const [importPercent, setImportPercent] = useState(0);
  const { applicationName, businessType, templateType } = qs.parse(search.slice(1));

  const onCancel = () => {
    history.goBack();
  };

  /**
   * 开始导入
   */
  const importFile = async () => {
    if (uploadedFileId) {
      const params: any = {
        applicationName,
        businessType,
        businessData: {},
        fileId: uploadedFileId,
        excelType: EXCEL_TYPE.customObject,
      };

      if (businessType === BUSINESS_TYPE.workerCalendar) {
        params.excelType = EXCEL_TYPE.special;
        params.businessData = {
          fileUrl: uploadedFileUrl,
          workerCalendarId: businessData?.workerCalendarId,
        };
      }
      const response: FetchImportExcelImportResponse = await fetchImportExcelCustomObjectImport(
        params,
      );
      const { code, message } = response;

      if (code === 200) {
        Message.success('操作成功，可通过日志查看导出情况');
        onCancel();
        // Modal.info({
        //   title: '导入文件',
        //   width: 560,
        //   okText: '收起窗口',
        //   onOk: () => {
        //     console.log('ok');
        //   },
        //   content: (
        //     <div>
        //       <div style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.65)' }}>
        //         正在导入数据，请耐心等待
        //       </div>
        //       <Progress percent={importPercent} style={{ marginTop: 8 }} />
        //     </div>
        //   ),
        // });
      } else {
        Message.error(`导入失败：${message}`);
      }
    }
  };

  /** 文件完成上传时，取得上传文件的uri */
  const fileUploaded = (files: UploadFile[]) => {
    // 文件删除时也会触发onUploaded方法，如果文件全部删除则必须disable导入按钮
    if (files.length === 0) {
      setUploadedFileId(null);
      setUploadedFileUrl(null);
    } else {
      const { status, response } = files[0];

      if (status === 'done' && response) {
        setUploadedFileId(response.id);
        setUploadedFileUrl(response.uri);
      }
    }
  };

  /** 下载模板 */
  const handleDownloadTemplate = () => {
    downloadImportTemplate({
      applicationName,
      businessType,
      templateType,
    } as any)
      .then((res) => {})
      .catch(() => {
        console.log('%c [ error ]-143', 'font-size:px; color:#bf2c9f;', '获取模板文件失败');
      });
  };

  return (
    <div className={styles.pageContainer}>
      <PageHeader title={'导入'} />
      <div className={styles.content}>
        <CardContainer title={'1、下载导入模版'}>
          <div>
            <Button
              type={'primary'}
              ghost
              icon={<DownloadOutlined />}
              onClick={handleDownloadTemplate}
            >
              下载数据模板
            </Button>
          </div>
          <div style={{ marginTop: 16 }}>
            {CUSTOM_TEMPLATE_HELP_TIPS.map((text) => (
              <div className={styles.temText}>{text}</div>
            ))}
          </div>
        </CardContainer>
        <CardContainer title={'2、上传导入文件'} style={{ marginTop: 34 }}>
          <div style={{ width: 600 }}>
            <BcUpload
              style={{ background: '#fff' }}
              maxSize={10}
              multiple={false}
              maxCount={1}
              limit="xlsx"
              fileExtensionLimitHint={FILE_EXTENSION_LIMIT_HINT}
              onUploaded={fileUploaded}
            />
          </div>
        </CardContainer>
      </div>
      <div className={styles.footer}>
        <Space>
          <Button onClick={onCancel}>取消</Button>
          <Button type={'primary'} onClick={importFile} disabled={!uploadedFileId}>
            开始导入
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default Import;
