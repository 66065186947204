import { RequestFunctionParams } from 'yapi-to-typescript';
import { request as yapiRequest, pickCode } from '../utils';
export interface RequestOptions {
  /**
   * 使用的服务器。
   *
   * - `prod`: 生产服务器
   * - `dev`: 测试服务器
   * - `mock`: 模拟服务器
   *
   * @default prod
   */
  server?: 'prod' | 'dev' | 'mock';
  /** 是否使用旧的枚举格式，即仅取code,默认开启 */
  legacy?: boolean;
  mock?: boolean;
  /**
   * 是否弹出接口错误信息
   * @default true
   */
  showErrorMessage?: boolean;
}

function swichMathod(param: string) {
  let method;

  switch (param) {
    case 'GET':
      method = 'get';
      break;

    case 'POST':
      method = 'post';
      break;

    case 'DELETE':
      method = 'delete';
      break;

    case 'PATCH':
      method = 'patch';
      break;

    case 'PUT':
      method = 'put';
      break;

    case 'HEAD':
      method = 'head';
      break;

    case 'OPTIONS':
      method = 'options';
      break;

    default:
      method = 'post';
      break;
  }
  return method;
}

export default function request<TResponseData>(
  payload: RequestFunctionParams,
  options: RequestOptions = {
    server: 'prod',
    legacy: true,
    mock: false,
    showErrorMessage: true,
  },
): Promise<TResponseData> {
  return new Promise<TResponseData>((resolve, reject) => {
    const isMockUrl = process.env.REACT_APP_MOCK || options?.mock;
    // 延迟毫秒数
    const DELAY_TIME = 1000;
    // 基本地址
    const baseUrl = isMockUrl
      ? payload.mockUrl.split('http://yapi.blacklake.tech').reverse()[0]
      : payload.prodUrl;

    const path = isMockUrl ? payload.path.split('/domain').reverse()[0] : payload.path;
    // 请求地址
    const url = `${baseUrl}${path}`;

    // 具体请求逻辑
    const method = swichMathod(payload.method);
    const data = payload.data;
    const yapi: any = yapiRequest;

    yapi[method](
      url,
      { loading: false, mock: false, error: options?.showErrorMessage },
      payload.hasFileData ? payload.getFormData() : data,
    )
      .then((res: any) => {
        resolve(options.legacy ? pickCode(res) : res);
      })
      .catch(reject);
  });
}
