import { FilterCondition } from '../interface';
import Container from './container';
import { cloneDeep, map, last } from 'lodash';
import { filterConditionSingle, filterInitDataByCreate } from '../constant';
import { FormInstance } from 'antd';
import _Array from 'src/utils/dataTypes/array';

interface Props {
  dataSource: any; // 数据来源
  form: FormInstance; // 绑定的form
  conditionGroupFieldName: string; // 条件组的字段名
  conditionItemFieldName: string; // 条件行字段名
  conditionTypeName: string; // 条件且/或字段名
  refresh: () => void; // 添加以后需要更新显示
  maxCount: number; // 最大添加数量
  renderCustomerFormItem?: (namePath: any, previewFieldData: any) => void; // 条件行渲染的自定义formItem
  level?: number; // 可转化的最深层级
  addText?: string; // 添加按钮文本
  needDelete?: Boolean; // 是否需要删除
  needCopy?: Boolean; // 是否需要复制
  needConversion?: Boolean; // 是否需要转化
}

const Condition = (props: Props) => {
  const { form, conditionGroupFieldName, refresh, ...restProps } = props;

  const modifyData = (type: string, namePath: any[]) => {
    const currentIndex = namePath?.[namePath?.length - 2];

    switch (type) {
      case 'add':
        {
          // 添加
          const data = form?.getFieldValue(namePath);

          if (_Array.isEmpty(data?.nextFloorCondition) || !data) {
            // 第一次添加

            form?.setFields([{ name: conditionGroupFieldName, value: [filterInitDataByCreate] }]);
            refresh();
          } else {
            const _data = cloneDeep(data);

            const _nextFloorCondition = _data?.nextFloorCondition.concat(filterConditionSingle);

            _data.nextFloorCondition = _nextFloorCondition;

            form?.setFields([{ name: namePath, value: _data }]);
            refresh();
          }
        }
        break;
      case 'conversion':
        // 转化
        {
          const parentNamePath = namePath.slice(0, namePath?.length - 3);

          const parentData = form?.getFieldValue(parentNamePath);

          const _parentData = cloneDeep(parentData);

          const _newNextFloorCondition = map(
            parentData?.nextFloorCondition,
            (node: FilterCondition, i: number) => {
              if (i === currentIndex) {
                // 需要转化的当行数据
                const addFieldFilterInfo = node?.fieldFilterInfo;

                const _nextFloorCondition = node?.nextFloorCondition?.concat([
                  {
                    fieldFilterInfo: addFieldFilterInfo,
                    nextFloorCondition: [],
                    nextType: undefined,
                  },
                ]);

                const _node = {
                  ...node,
                  nextFloorCondition: _nextFloorCondition,
                  fieldFilterInfo: undefined,
                };

                return _node;
              }
              return node;
            },
          );

          _parentData.nextFloorCondition = _newNextFloorCondition;

          form?.setFields([{ name: parentNamePath, value: _parentData }]);
          refresh();
        }

        break;
      case 'delete':
        {
          const parentNamePath = namePath.slice(0, namePath?.length - 3);

          const parentData = form?.getFieldValue(parentNamePath);

          if (parentData?.nextFloorCondition?.length <= 1) {
            // 代表当前删除的是组的最后一条 直接把整个组删掉 则需要再往上找一层

            const parentFatherNamePath = namePath.slice(0, namePath?.length - 5);

            const parentFatherData = form?.getFieldValue(parentFatherNamePath);

            const _parentFatherData = cloneDeep(parentFatherData);

            _parentFatherData?.nextFloorCondition?.splice(last(parentNamePath), 1);

            form?.setFields([{ name: parentFatherNamePath, value: _parentFatherData }]);
          } else {
            const newData = cloneDeep(parentData);

            newData?.nextFloorCondition?.splice(currentIndex, 1);
            form?.setFields([{ name: parentNamePath, value: newData }]);
          }

          refresh();
        }

        break;
      case 'copy':
        {
          const parentNamePath = namePath.slice(0, namePath?.length - 3);

          const parentData = form?.getFieldValue(parentNamePath);

          const currentCopy = parentData?.nextFloorCondition?.[currentIndex];

          const _parentData = cloneDeep(parentData);

          _parentData?.nextFloorCondition?.splice(currentIndex, 0, currentCopy);
          form?.setFields([{ name: parentNamePath, value: _parentData }]);
          refresh();
        }

        break;
      default:
        break;
    }
  };

  return (
    <div style={{ maxHeight: 600, overflow: 'scroll', marginBottom: 30 }} id="containerBox">
      <Container
        modifyData={modifyData}
        namePath={[conditionGroupFieldName, 0]}
        form={form}
        conditionGroupFieldName={conditionGroupFieldName}
        {...restProps}
      />
    </div>
  );
};

export default Condition;
