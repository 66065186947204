import { DataFormLayout, DataFormLayoutForModal, DataFormLayoutInfoBlock } from 'layout';
import { FormInstance, message } from 'antd';
import { useState, useEffect } from 'react';
import { SearchSelect } from 'src/components';
import Condition from '../../filter/condition';
import { TemplateDataSetInfoListDetail } from '../../interface';
import { formatDetailToForm, formatFormFilterToGetList } from '../../utils';
import { map, filter, findIndex } from 'lodash';
import {
  fetchEReportTemplateDataSetCreate,
  fetchEReportTemplateDataSetModify,
} from 'src/api/ytt/e-report-domain/customReport';
import lookup, { appEnum } from 'src/dict';
import _String from 'src/utils/dataTypes/string';
import {
  fetchDataSetQueryCanFilterDataSetField,
  fetchDataSetQueryCheckedDataSetPreviewField,
} from 'src/api/ytt/e-report-domain/dataSet';
import TemplateDataFieldCanEditTable from './templateDataFieldTable';
import _Object from 'src/utils/dataTypes/object';
import _Array from 'src/utils/dataTypes/array';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';

interface Props {
  visible: boolean;
  onCancel: () => void;
  refreshDataList: () => void;
  setDataListForm: FormInstance;
  initLineData?: TemplateDataSetInfoListDetail;
  operationType: string;
}

export default function AddSetTemplateData({
  visible,
  onCancel,
  refreshDataList,
  setDataListForm,
  operationType,
  initLineData,
}: Props) {
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 为了更改过滤条件form以后刷新页面

  const [fieldData, setFieldData] = useState([] as any); // 创建模板数据集时，选择数据集后，在过滤条件可选的对象.字段的数据

  const [dataFieldList, setDataFieldList] = useState([] as any); // 创建模板数据集时，选择数据集后，模板数据集字段table的数据源  fieldData和dataFieldList 存在一个包含关系，fieldData只能选择根节点相关的 所以包含在dataFieldList内

  const [selectedRowKeys, setSelectRowKeys] = useState<React.Key[]>([]); // 模板数据集字段的table选中key

  const [selectedRows, setSelectedRows] = useState([] as any); // 勾选中的模板数据集字段的数据

  const [previewList, setPreviewList] = useState([] as any); // 根据条件和字段筛选出来的预览列表数据
  const [searchValue, setSearchValue] = useState<string>(''); // 预览字段快捷搜索的值

  const [isChangeField, setIsChangeField] = useState<boolean>(false);

  const [isPreSorterField, setIsPreSorterField] = useState<string>('');

  const handleResetChangeField = (rest: boolean) => {
    setIsChangeField(rest);
  };

  // 根据条件和字段筛选出来的预览列表数据
  const [loading, setLoading] = useState(false);

  const resetPreviewFieldData = (data: any) => {
    // 后端返回的temDataSetPreviewFieldInfoList 模板数据集fieldDisplayName字段是可编辑的。但是整个数据集字段是获取的对象平台的数据集。fieldDisplayName就会出现差异，所以需要重新整理数据
    // 根据是否勾选重新排序  勾选的展示在上

    const checkList: any = [];
    const unCheckList: any = [];

    data?.forEach((node: any) => {
      if (map(initLineData?.temDataSetPreviewFieldInfoList, 'fieldId')?.includes(node?.fieldId)) {
        // 代表是选中数据 更换为temDataSetPreviewFieldInfoList的数据  并且追加到checkList中

        const replaceList =
          initLineData?.temDataSetPreviewFieldInfoList?.[
            findIndex(
              initLineData?.temDataSetPreviewFieldInfoList,
              (e: any) => e.fieldId === node?.fieldId,
            )
          ];

        checkList.push(replaceList);
      } else {
        unCheckList.push(node);
      }
    });

    const formatList = checkList.concat(unCheckList);

    return formatList;
  };

  // 获取过滤条件可选的字段
  const getFilterData = async (dataSetId: number) => {
    const { data } = await fetchDataSetQueryCanFilterDataSetField({ dataSetId });

    let relData = data;

    if (operationType !== appEnum.Common.CRUD.create) {
      // 只要不是创建，都需要根据后端返回的详情里的模板数据集字去重置数据
      relData = resetPreviewFieldData(data);
    }

    // 重新设置预览字段table的时候需要默认全选

    setFieldData(relData);
  };

  // 获取模板数据集字段可选的数据
  const getDataField = async (dataSetId: number, params?: any) => {
    setIsPreSorterField(JSON.stringify(params.sorter));
    const { data } = await fetchDataSetQueryCheckedDataSetPreviewField({
      dataSetId,
      ...params,
    });

    let relData = data;

    if (!data) {
      setDataFieldList([]);
      return;
    }

    if (operationType !== appEnum.Common.CRUD.create) {
      // 只要不是创建，都需要根据后端返回的详情里的模板数据集字去重置数据
      relData = resetPreviewFieldData(data);
    } else {
      //  创建时需要默认全选
      const rowKeys = map(relData, 'fieldId');

      setSelectRowKeys(rowKeys);

      setSelectedRows(relData);
    }

    setDataFieldList(relData);
  };

  // 设置模板数据集字段列表的选中keys
  const handleSelectedRowKeys = (keys: React.Key[]) => {
    setSelectRowKeys(keys);
  };

  // 设置模板数据集字段列表的选中数据
  const handleSetSelectedRows = (data: any[]) => {
    setSelectedRows(data);
  };

  // 设置预览列表数据
  const handleSetPreviewList = (list: any[]) => {
    setPreviewList(list);
  };

  // 更换预览字段searchValue的值
  const handleSetSearchValue = (value: string) => {
    setSearchValue(value);
  };

  const onDataFieldTableChange = (sorter?: any) => {
    const dataSetId = setDataListForm.getFieldValue('dataSetId')?.value;

    const relParams: any = {};

    if (
      isPreSorterField ===
      JSON.stringify([
        {
          field: sorter?.field,
          order: sorter?.order === 'ascend' ? 'asc' : 'desc',
        },
      ])
    ) {
      // 因为是个假分页如果排序和搜索的字段值都没变 就不发请求
      return;
    }

    if (sorter?.field && sorter?.order) {
      relParams.sorter = [
        {
          field: sorter?.field,
          order: sorter?.order === 'ascend' ? 'asc' : 'desc',
        },
      ];
    }

    if (searchValue) {
      relParams.quickSearch = searchValue;
    }

    (relParams.sorter || relParams.quickSearch) && getDataField(dataSetId, relParams);
  };

  const onSearchField = () => {
    const dataSetId = setDataListForm.getFieldValue('dataSetId')?.value;

    const relParams: any = {};

    if (searchValue) {
      relParams.quickSearch = searchValue;
    }

    getDataField(dataSetId, relParams);
  };

  useEffect(() => {
    if (operationType === appEnum.Common.CRUD.edit || operationType === appEnum.Common.CRUD.copy) {
      setSelectRowKeys(map(initLineData?.temDataSetPreviewFieldInfoList, 'fieldId'));
      setSelectedRows(initLineData?.temDataSetPreviewFieldInfoList);

      initLineData?.dataSetId && getFilterData(initLineData?.dataSetId);
      initLineData?.dataSetId && getDataField(initLineData?.dataSetId, { page: 1, size: 10 });

      setDataListForm.setFieldsValue({
        dataSetId: {
          value: initLineData?.dataSetId,
          label: initLineData?.dataSetName,
        },
        nextFloorCondition: formatDetailToForm(
          initLineData?.filterCondition,
          initLineData?.temDataSetPreviewFieldInfoList ?? [],
        ),
      });
    }
    if (operationType === appEnum.Common.CRUD.create) {
      // 新增需要清空form,数据集字段list
      setSelectedRows([]);
      setDataListForm.resetFields();
    }
  }, [initLineData, operationType]);

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,
    items: [
      {
        label: '模板数据集来源',
        name: 'dataSetId',
        rules: [{ required: true, message: '请选择' }],
        render: () => (
          <SearchSelect
            fetchType={'dataTemplate'}
            style={{ width: '100%' }}
            params={{ bizSetStatus: [2] }}
            searchFieldName="dataSetName"
            onChange={() => {
              const dataSetId = setDataListForm.getFieldValue('dataSetId')?.value;

              // 更改数据集来源需要重置 过滤条件重新获取预览字段，重置选中的kes,重置选中的模板数据集字段数据
              setDataListForm.setFieldsValue({ nextFloorCondition: null });

              setSelectRowKeys([]);
              setSelectedRows([]);
              setPreviewList([]);
              dataSetId && getFilterData(dataSetId);
              dataSetId && getDataField(dataSetId, { page: 1, size: 10 });
            }}
          />
        ),
      },
      {
        label: '模板数据集过滤条件',
        isFullLine: true,
        shouldUpdate: true,
        render: () => () => {
          const templateDataFieldTable = setDataListForm.getFieldValue('templateDataFieldTable');

          const formatFieldData = fieldData.map((node: any) => {
            if (map(templateDataFieldTable, 'fieldId')?.includes(node?.fieldId)) {
              const replaceList =
                templateDataFieldTable?.[
                  findIndex(templateDataFieldTable, (e: any) => e.fieldId === node?.fieldId)
                ];

              return replaceList;
            }
            return node;
          });

          return (
            <Condition
              dataSource={formatFieldData || fieldData}
              form={setDataListForm}
              conditionGroupFieldName={'nextFloorCondition'}
              conditionItemFieldName={'fieldFilterInfo'}
              conditionTypeName={'nextType'}
              refresh={() => {
                setRefreshMarker(Date.now());
              }}
              maxCount={20}
            />
          );
        },
      },
      {
        label: '模板数据集字段',
        required: true,
        isFullLine: true,
        render: () => (
          <TemplateDataFieldCanEditTable
            fieldData={dataFieldList} // 过滤条件和数据集字段数据
            setDataListForm={setDataListForm}
            selectedRowKeys={selectedRowKeys} // 模板数据集勾选的keys
            handleSelectedRowKeys={handleSelectedRowKeys}
            selectedRows={selectedRows} // 模板数据集勾选的数据
            handleSetSelectedRows={handleSetSelectedRows}
            handleSetPreviewList={handleSetPreviewList} // 根据条件和字段筛选出的预览数据
            previewList={previewList}
            operationType={operationType}
            onDataFieldTableChange={onDataFieldTableChange}
            handleSetSearchValue={handleSetSearchValue}
            onSearchField={onSearchField}
            isChangeField={isChangeField}
            handleResetChangeField={handleResetChangeField}
          />
        ),
      },
    ],
  };

  const onFinish = async () => {
    setLoading(true);
    try {
      const value = await setDataListForm?.validateFields();

      const templateIdLocal = Number(localStorage.getItem('templateId'));

      const filterCondition = value?.nextFloorCondition?.[0]
        ? formatFormFilterToGetList(value?.nextFloorCondition?.[0])
        : {}; //  最外层的是为了符合数组结构 前端为展示结构加的，后端数据不需要最外层的List,是一个对象

      const templateDataFieldTableList = setDataListForm.getFieldValue('templateDataFieldTable');

      const checkId = map(selectedRows, 'fieldId');

      const relTemDataSetPreviewFieldInfoList = filter(templateDataFieldTableList, (node: any) =>
        checkId.includes(node?.fieldId),
      );

      const newTemDataSetPreviewFieldInfoList = map(
        relTemDataSetPreviewFieldInfoList,
        (node: any) => {
          const { fieldDisplayName, fieldDisplayNameDefault, order, ...rest } = node;

          return {
            ...rest,
            fieldDisplayName: _String.isEmpty(fieldDisplayName)
              ? fieldDisplayNameDefault
              : fieldDisplayName,
            dataSetId: value?.dataSetId?.value,
          };
        },
      );

      const fetchFn =
        operationType === appEnum.Common.CRUD.edit
          ? fetchEReportTemplateDataSetModify
          : fetchEReportTemplateDataSetCreate;

      const { data, code } = await fetchFn({
        templateDataSetInfo: {
          dataSetId: value?.dataSetId?.value,
          dataSetName: value?.dataSetId?.label,
          filterCondition:
            _Object.isEmpty(filterCondition) ||
            _Array.isEmpty(filterCondition?.objectFilterConditionList)
              ? undefined
              : filterCondition,
          temDataSetPreviewFieldInfoList: newTemDataSetPreviewFieldInfoList,
          templateDataSetId:
            operationType === appEnum.Common.CRUD.edit
              ? initLineData?.templateDataSetId
              : undefined,
        },
        templateId: templateIdLocal,
      });

      setLoading(false);
      if (code == 200 && data) {
        message.success(
          `${operationType === appEnum.Common.CRUD.edit ? '编辑' : '添加'}模板数据集成功`,
        );
        onCancel();
        setDataListForm?.resetFields();
        refreshDataList?.();
      }
    } catch (error) {
      setLoading(false);
      console.log('error: ', error);
    }
  };

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      onClose={onCancel}
      width={1400}
      content={
        <DataFormLayout
          formProps={{
            onValuesChange: (changedValues: any) => {
              if (changedValues) {
                setIsChangeField(true);
              }
            },
          }}
          title={`${lookup('common.crud', operationType)}模板数据集`}
          info={[baseInfo]}
          form={setDataListForm}
          onFinish={throttleFn(onFinish)}
          onCancel={onCancel}
          loading={loading}
        />
      }
    />
  );
}
