const mainProductLicensed = true;

const fieldLabels = {
  code: '编号',
  origin: '来源',
  orderOrigin: '订单来源',
  purchaseReturnOrderCode: '协同采购退货单编号',
  salesOrderCode: '关联销售订单编号',
  customerCode: '客户编号',
  customerName: '客户名称',
  customerInfo: '客户信息',
  material: '物料',
  relatedInboundOrder: '关联入库单',
  status: '状态',
  materialCode: '物料编号',
  materialName: '物料名称',
  materialProperty: '物料属性',
  plannedReturnAmount: '计划退货数',
  inboundPlannedAmount: '仓库应收数',
  inboundAmount: '仓库实收数',
  customerReturnedAmount: '客户已发数',
  unit: '单位',
  lineNo: '行号',
  returnOrderStatus: '销售退货单状态',
  inboundOrderCode: '入库单编号',
  salesOrderItemLineNo: '销售订单行号',
  creator: '创建人',
  createdAt: '创建时间',
  operator: '更新人',
  updatedAt: '更新时间',
  closeUser: '关闭人',
  closedAt: '关闭时间',
  finishUser: '结束人',
  finishedAt: '结束时间',
  modifyReason: '编辑原因',
  remark: '备注',
  info: '退货信息',
  availableAmount: '可退货数',
  returnAuditNoteCode: '退货申请单编号',
  customerMaterialCode: '客户物料编号',
  customerMaterialName: '客户物料名称',
  customerReturnAmount: '退货数量',
  ourMaterialCode: '自建物料编号',
  ourMaterialName: '自建物料名称',
  ourMaterialAttr: '自建物料属性',
  convertedReturnAmount: '转换后退货数量',
  rejectReason: '拒绝原因',
};

export { fieldLabels, mainProductLicensed };
