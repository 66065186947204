import { replaceSign } from 'src/utils/constants';
import moment from 'moment';

interface Amount {
  amount: number;
  amountDisplay: string;
  unitCode: string;
  unitId: number;
  unitName: string;
}

export const renderAmount = (amount: Amount) => {
  return amount ? amount?.amountDisplay + amount?.unitName : replaceSign;
};

export const formatFetchValue = (values: any) => {
  //  此处的supplier为供应商编号/名称的纯展示字段，需要丢掉
  const { itemList, supplier, ...rest } = values;

  const supplierId = itemList[0].supplier?.id;
  const _itemList = itemList?.map((node: any, index: number) => {
    const { seqNo, material, id, purchaseOrderItemId, returnAmount, returnTime, unitId } = node;

    const _returnTime = moment(returnTime).valueOf();

    return {
      seqNo: String(index + 1),
      materialId: material?.baseInfo?.id,
      purchaseOrderItemId,
      returnAmount: Number(returnAmount),
      returnTime: _returnTime,
      id,
      unitId,
    };
  });

  return {
    itemList: _itemList,
    supplierId,
    ...rest,
  };
};
