import { useState, useEffect } from 'react';
import { match, RouteComponentProps, useHistory } from 'react-router-dom';
import { DetailLayout } from 'layout';
import { BlTable, BlIcon, SingleOperationLogComponent, TagList } from 'components';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import moment from 'moment';
import { replaceSign } from 'src/utils/constants';
import {
  fetchOutboundOrderDetail,
  fetchOutboundOrderIssue,
  fetchOutboundOrderClose,
} from 'src/api/ytt/inventory-domain/boundOrder';
import type { OutboundDetailType } from '../type';
import lookup, { appEnum } from 'src/dict';
import _, { isEmpty } from 'lodash';
import WriteOffForm from './writeOff';
import { BOUND_PATH as basePath } from '../index';
import { patchRequest } from '../utils';
import { Tag } from 'antd';
import authDict, { hasAuth } from 'src/utils/auth';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import {
  injectCustomFieldColumns,
  injectCustomFieldInfos,
  useCustomFieldCombinedData,
} from 'src/components/customField';

interface DetailPageProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const { StatusEnum } = appEnum.Bound;
const DetailPage = (props: DetailPageProps) => {
  const { match, history } = props;
  const id = Number(match?.params?.id);

  const [dataSource, setDataSource] = useState<OutboundDetailType>({});
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.outboundOrder);

  const fetchData = async () => {
    try {
      if (!id) {
        return;
      }
      const { data } = await fetchOutboundOrderDetail({ id });

      setDataSource(data || {});
    } catch (err) {
      console.log(err);
    }
  };
  const getIdVersionList = (ids: (string | number)[]) => ids.map((id) => ({ id }));

  useEffect(() => {
    fetchData();
  }, [id]);

  const baseInfo = [
    {
      title: '基本信息',
      items: [
        { label: '出库单编号', dataIndex: 'code' },
        {
          label: '业务类型',
          dataIndex: 'bizType',
          render: lookup('bound.OutBoundType'),
        },
        {
          label: '状态',
          dataIndex: 'bizStatus',
          render: lookup('bound.Status'),
        },
        {
          label: '仓库',
          dataIndex: 'wareHouse',
          render: () => dataSource?.wareHouse?.name ?? replaceSign,
        },
        {
          label: '客户',
          dataIndex: 'customer',
          render: () => dataSource?.customer?.name ?? replaceSign,
        },
        {
          label: '计划出库时间',
          dataIndex: 'planTime',
          render: (value: number) => value && moment(value).format('YYYY/MM/DD HH:mm'),
        },
        {
          label: '来源类型',
          dataIndex: 'sourceType',
          render: (value: number) => lookup('warehouse.sourceTypeEnum', value),
        },
        {
          label: '备注',
          dataIndex: 'remark',
          render: (value: string) => value ?? replaceSign,
        },
      ],
    },
    injectCustomFieldInfos({ customFields, type: 'detail' }),
    {
      title: '物料列表',
      items: [
        {
          label: '',
          dataIndex: 'item',
          render: () => (
            <BlTable
              style={{ width: '100%' }}
              scroll={{
                x: 'max-content',
              }}
              pagination={false}
              rowKey={'lineNo'}
              columns={injectCustomFieldColumns({
                columns: _.compact([
                  {
                    title: '行号',
                    dataIndex: 'lineNo',
                  },
                  {
                    title: '物料编号',
                    dataIndex: 'material',
                    render: (value: any) => value?.baseInfo?.code,
                  },
                  {
                    title: '物料名称',
                    dataIndex: 'material',
                    render: (value: any) => value?.baseInfo?.name,
                  },
                  {
                    title: '物料属性',
                    dataIndex: 'material',
                    render: (value: any) => {
                      if (_.isEmpty(value.attribute)) return replaceSign;
                      return (
                        <TagList
                          dataSource={_.map(value.attribute, ({ name, attributeItem }) => ({
                            label: `${name}:${attributeItem.content}`,
                            value: attributeItem.content,
                          }))}
                        />
                      );
                    },
                  },
                  {
                    title: '物料规格',
                    dataIndex: 'material',
                    render: (value: any) => value?.baseInfo?.specification,
                  },
                  {
                    title: '批次',
                    dataIndex: 'batchNo',
                  },
                  {
                    title: '应发数量',
                    dataIndex: 'planAmount',
                    render: (value: number, record: any) => `${value} ${record?.unit?.name}`,
                  },
                  {
                    title: '实发数量',
                    dataIndex: 'doneAmount',
                    render: (value: number, record: any) => {
                      return (
                        <a
                          href={`/warehouseManagement/executiveManagement/outRecord?itemId=${record?.id}`}
                          target={'_blank'}
                          rel="noreferrer"
                        >
                          {`${value} ${record?.unit?.name}`}
                        </a>
                      );
                    },
                  },
                  {
                    title: '备注',
                    dataIndex: 'remark',
                  },
                  {
                    title: '销售订单',
                    dataIndex: 'outboundOrderBizCodes',
                    render: (value: any, record: any) => {
                      if (isEmpty(value)) {
                        return replaceSign;
                      }
                      return value.map((i: any) => <Tag>{i.bizCode}</Tag>);
                    },
                  },
                  hasAuth(authDict.outboundorder_outbound_reversal) &&
                    (dataSource?.bizStatus === StatusEnum.zhixing ||
                      dataSource?.bizStatus === StatusEnum.yiwancheng) && {
                      title: '操作',
                      dataIndex: 'action',
                      render: (text: any, record: any) => (
                        <WriteOffForm
                          onFinish={() => fetchData()}
                          outboundOrder={dataSource}
                          material={record}
                        />
                      ),
                    },
                ]), // 原本的列
                customFields, // 自定义字段信息
                objectCode: OBJECT_OF_CODE.outboundOrderItem, // 从对象code
                type: 'detail', // 使用类型
              })}
              dataSource={dataSource.items}
            />
          ),
        },
      ],
    },
    {
      title: '其他信息',
      items: [
        {
          label: '创建人',
          dataIndex: 'creator',
          render: (creator: any) => {
            return creator ? <AvatarDisplay {...creator} isShowTag={false} isUser /> : replaceSign;
          },
        },
        {
          label: '创建时间',
          dataIndex: 'createTime',
          isFullLine: true,
          render: (value: number) => moment(value).format('YYYY-MM-DD HH:mm:ss') ?? replaceSign,
        },

        {
          label: '更新人',
          dataIndex: 'operator',
          render: (operator: any) => {
            return operator ? (
              <AvatarDisplay {...operator} isShowTag={false} isUser />
            ) : (
              replaceSign
            );
          },
        },
        {
          label: '更新时间',
          dataIndex: 'updateTime',
          render: (value: number) =>
            (value && moment(value).format('YYYY-MM-DD HH:mm:ss')) ?? replaceSign,
        },
      ],
    },
  ];
  const { bizStatus } = dataSource;
  const baseMenu = _.compact([
    {
      key: 'operate',
      title: '操作记录',
      auth: authDict.outboundorder_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
    },
    bizStatus === StatusEnum.xinjian && {
      key: 'xiafa',
      onClick: () => {
        if (!dataSource.id) return;
        patchRequest(fetchOutboundOrderIssue, getIdVersionList([dataSource.id]), '下发', fetchData);
      },
      icon: <BlIcon type="iconbianji" />,
      auth: authDict.outboundorder_dispatch,
      title: '下发',
    },
    (bizStatus === StatusEnum.xinjian || bizStatus === StatusEnum.zhixing) && {
      key: 'close',
      onClick: () => {
        if (!dataSource.id) return;
        patchRequest(fetchOutboundOrderClose, getIdVersionList([dataSource.id]), '关闭', fetchData);
      },
      auth: authDict.outboundorder_close,
      icon: <BlIcon type="iconbianji" />,
      title: '关闭',
    },
    bizStatus !== StatusEnum.yiguanbi && {
      key: 'edit',
      auth: authDict.outboundorder_edit,
      onClick: () => history.push(`${basePath}/outBound/${id}/edit`),
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ]);

  return (
    <>
      <DetailLayout
        title="出库单详情"
        info={baseInfo}
        dataSource={dataSource}
        baseMenu={baseMenu}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={Number(id)}
          objectCode={'OutboundOrder'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailPage;
