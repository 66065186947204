import React from 'react';

interface Props {
  color: string;
}

const ColorBox = (props: Props) => {
  const { color } = props;

  const style = {
    width: 20,
    height: 20,
    backgroundColor: `${color}`,
    color: `${color}`,
  };

  return <div style={style}>-</div>;
};

export default ColorBox;
