import type { Dispatch } from 'src/store';
import type { ListenerFuncType } from './index.d';

import { BL_BUSINESS_CODE as MaintenanceCaseCacheKey } from 'src/page/resource/maintenancePlan/config';
import { BL_BUSINESS_CODE as MaintenanceTaskCacheKey } from 'src/page/resource/maintenanceTask/config';
import { BL_BUSINESS_CODE as MalfunctionRecordCacheKey } from 'src/page/resource/malfunctionRecord/config';
import { BL_BUSINESS_CODE as RepairPlanCacheKey } from 'src/page/resource/repairPlan/config';
import { BL_BUSINESS_CODE as RepairTaskCacheKey } from 'src/page/resource/repairTask/config';

const resourcePath = '/resource/definitions/standingBook';
const energyPath = '/resource/energyMeter/standingBook';
const maintenanceCasePath = '/resource/implement/maintenance/plan';
const maintenanceTaskPath = '/resource/implement/maintenance/task';
const malfunctionRecordPath = '/resource/implement/malfunctionRecord';
const resourceRecord = '/resource/params/resourceRecord';
const resourceMonitor = '/resource/params/resourceMonitor';
const repairPlan = '/resource/implement/repair/plan';
const repairTask = '/resource/implement/repair/task';

// 设备路由监听函数
const resourceListeners: ListenerFuncType[] = [
  (from: string, to: string, dispatch: Dispatch) => {
    if (from.indexOf(resourcePath) >= 0 && to.indexOf(resourcePath) < 0) {
      dispatch({ type: 'resources/reset' });
    }
  },
  (from: string, to: string, dispatch: Dispatch) => {
    if (from.indexOf(energyPath) >= 0 && to.indexOf(energyPath) < 0) {
      dispatch({ type: 'energyMeter/reset' });
    }
  },
  (from: string, to: string) => {
    if (from.indexOf(maintenanceCasePath) >= 0 && to.indexOf(maintenanceCasePath) < 0) {
      sessionStorage.removeItem(MaintenanceCaseCacheKey);
    }
  },
  (from: string, to: string) => {
    if (from.indexOf(maintenanceTaskPath) >= 0 && to.indexOf(maintenanceTaskPath) < 0) {
      sessionStorage.removeItem(MaintenanceTaskCacheKey);
    }
  },
  (from: string, to: string) => {
    if (from.indexOf(malfunctionRecordPath) >= 0 && to.indexOf(malfunctionRecordPath) < 0) {
      sessionStorage.removeItem(MalfunctionRecordCacheKey);
    }
  },
  (from: string, to: string) => {
    if (from.indexOf(resourceRecord) >= 0 && to.indexOf(resourceRecord) < 0) {
      sessionStorage.removeItem('resourceRecord');
    }
  },
  (from: string, to: string) => {
    if (from.indexOf(resourceMonitor) >= 0 && to.indexOf(resourceMonitor) < 0) {
      sessionStorage.removeItem('resourceMonitor');
    }
  },
  (from: string, to: string) => {
    if (from.indexOf(repairPlan) >= 0 && to.indexOf(repairPlan) < 0) {
      sessionStorage.removeItem(RepairPlanCacheKey);
    }
  },
  (from: string, to: string) => {
    if (from.indexOf(repairTask) >= 0 && to.indexOf(repairTask) < 0) {
      sessionStorage.removeItem(RepairTaskCacheKey);
    }
  },
];

export default resourceListeners;
