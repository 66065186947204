/**
 * 工序表单
 */
import { Input, Select, Form, DatePicker, FormInstance } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { SearchSelect, BcAttachmentForm, SearchSelectModal } from 'components';
import { memo } from 'react';
import { getWorkCenterGroupfieldValue } from '../../utils';
import { appDict } from 'src/dict';
import _, { isEmpty, uniqueId } from 'lodash';
import { BizType } from 'src/dict/sop';
import { numberStringCheck } from 'src/utils/formValidators/regularValidator';
import ResourceGroup from 'src/page/knowledgeManagement/basicData/workCenter/resourceGroup';
import { TemplateType } from 'src/dict/reportTemplate';

interface Props {
  form: FormInstance;
  nextName: string;
  name: string;
  isWorkOrder?: number;
  processConName: string;
  index: number;
  edgeLength: number;
  nodeLength: number;
  isEditMode: Boolean;
}

const ProcessForm = memo((props: Props) => {
  const {
    name,
    nodeLength,
    edgeLength,
    processConName,
    index,
    form,
    nextName,
    isWorkOrder = 0,
    isEditMode,
  } = props;

  const setWorkCenterGroupfieldValue = async (id?: number) => {
    let value;

    if (id) {
      const workCenterGroupCOList = await getWorkCenterGroupfieldValue(id);

      value = _.cloneDeep(workCenterGroupCOList);
    }

    form.setFields([
      {
        name: [name, nextName, index, 'workCenterGroupCOList'],
        value,
      },
    ]);
  };
  // 工序数据变化 修改前后接续关系数据
  const setConnection = (field: string, value?: any) => {
    const fields = [];

    if (field === 'processNum') {
      if (index > 0) {
        fields.push({
          name: [name, processConName, index - 1, 'nextProcessNum'],
          value,
        });
      }
      if (index < nodeLength - 1 && edgeLength > 0) {
        fields.push({
          name: [name, processConName, index, 'prevProcessNum'],
          value,
        });
      }
    }
    if (field === 'process') {
      if (index > 0) {
        fields.push(
          ...[
            {
              name: [name, processConName, index - 1, 'nextProcessId'],
              value: value?.id,
            },
            {
              name: [name, processConName, index - 1, 'nextProcessName'],
              value: value?.name,
            },
          ],
        );
      }
      if (index < nodeLength - 1 && edgeLength > 0) {
        fields.push(
          ...[
            {
              name: [name, processConName, index, 'prevProcessId'],
              value: value?.id,
            },
            {
              name: [name, processConName, index, 'prevProcessName'],
              value: value?.name,
            },
          ],
        );
      }
    }
    if (field === 'sop') {
      if (index > 0) {
        fields.push({
          name: [
            name,
            processConName,
            index - 1,
            'processManufacturedGoods',
            'materialFeedControl',
          ],
          value: value?.id,
        });
      }
      if (index < nodeLength - 1 && edgeLength > 0) {
        fields.push({
          name: [name, processConName, index, 'processManufacturedGoods', 'workReportControl'],
          value: value?.id,
        });
      }
    }
    if (!isEmpty(fields)) {
      form.setFields(fields);
    }
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    title: '基本信息',
    items: [
      {
        label: '顺序号',
        name: [name, nextName, index, 'processSeq'],
        render: () => <Input disabled />,
      },
      {
        label: '工序号',
        name: [name, nextName, index, 'processNum'],
        dependencies: [[name, nextName]],
        rules: [
          { required: true, message: '请填写工序号' },
          numberStringCheck,
          { max: 255, message: '不可超过255个字符' },
          () => ({
            validator(v, value) {
              const formValue = form.getFieldsValue();
              const list = _.map(formValue[name].processes, (o) => String(o.processNum));

              if (_.filter(list, (o) => String(o) === String(value))?.length > 1) {
                return Promise.reject(new Error('工序号不能重复'));
              }
              return Promise.resolve();
            },
          }),
        ],
        render: () => <Input onChange={(e) => setConnection('processNum', e.target.value)} />,
      },
      {
        label: '工序编号',
        name: [name, nextName, index, 'processCode'],
        rules: [{ required: true, message: '请选择' }],
        render: () => (
          <SearchSelectModal
            stringify
            fetchType="processCompleteInfo"
            renderLabel="code"
            onChange={(item: any) => {
              const valueJSON = item && item.value ? JSON.parse(item.value) : {};

              form.setFields([
                {
                  name: [name, nextName, index, 'processId'],
                  value: valueJSON?.id,
                },
                {
                  name: [name, nextName, index, 'processName'],
                  value: valueJSON?.name,
                },
                {
                  name: [name, nextName, index, 'workCenterId'],
                  value: {
                    label: valueJSON.workCenterName,
                    value: valueJSON?.workCenterId,
                  },
                  errors: [],
                },
                {
                  name: [name, nextName, index, 'description'],
                  value: valueJSON?.description,
                },
                {
                  name: [name, nextName, index, 'fileIds'],
                  value: valueJSON?.fileIds,
                },
              ]);
              setConnection('process', valueJSON);
              setWorkCenterGroupfieldValue(valueJSON.workCenterId);
            }}
          />
        ),
      },
      {
        label: '工序名称',
        hidden: true,
        name: [name, nextName, index, 'processId'],
        render: () => <Input disabled />,
      },
      {
        label: '工序名称',
        name: [name, nextName, index, 'processName'],
        render: () => <Input disabled />,
      },
      {
        label: '标识码',
        ...(isWorkOrder === 1 ? { name: [name, nextName, index, 'identifier'] } : {}),
        hidden: isWorkOrder !== 1,
        rules: [{ max: 255, message: '不可超过255个字符' }],
        render: () => <Input />,
      },
      {
        label: '工作中心',
        name: [name, nextName, index, 'workCenterId'],
        rules: [{ required: true, message: '请选择' }],
        render: () => (
          <SearchSelect
            fetchType="workCenter"
            labelInValue
            onChange={(value: any) => setWorkCenterGroupfieldValue(value?.value)}
          />
        ),
      },
      {
        label: '工序说明',
        name: [name, nextName, index, 'description'],
        rules: [{ max: 1000, message: '不可超过1000个字符' }],
        render: () => <Input.TextArea />,
      },
      {
        label: '文件',
        name: [name, nextName, index, 'fileIds'],
        render: () => <BcAttachmentForm form={form} />,
      },
    ],
  };
  const produceInfo: DataFormLayoutInfoBlock = {
    column: 1,
    title: '生产信息',
    items: [
      // {
      //   label: '前序工序号',
      //   hidden: isWorkOrder,
      //   render: () => <Input />,
      // },
      {
        label: '计划开始时间',
        ...(isWorkOrder ? { name: [name, nextName, index, 'plannedStartTime'] } : {}),
        hidden: !isWorkOrder,
        render: () => <DatePicker showTime format="YYYY/MM/DD HH:mm:ss" />,
      },
      {
        label: '计划完工时间',
        ...(isWorkOrder ? { name: [name, nextName, index, 'plannedEndTime'] } : {}),
        hidden: !isWorkOrder,
        rules: [
          ({ getFieldValue }) => ({
            validator(_, value) {
              const startAt = getFieldValue([name, nextName, index, 'plannedStartTime']);

              if (!value || startAt < value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('计划完工时间必须大于计划开始时间'));
            },
          }),
        ],
        render: () => <DatePicker showTime format="YYYY/MM/DD HH:mm:ss" />,
      },
      {
        label: 'SOP方案',
        dependencies: [['enableSop']],
        render: () => () => {
          const enableSop = form.getFieldValue(['enableSop']);
          const sopSnapshotId = form.getFieldValue([name, nextName, index, 'sopSnapshotId']);

          return (
            <Form.Item
              // rules={_.compact([enableSop && { required: true, message: '请选择' }])}
              style={{ marginBottom: 0 }}
              name={[name, nextName, index, 'sop']}
            >
              <SearchSelect
                // 生产工单存在sopSnapshotId 禁用 （sop快照id
                disabled={!enableSop || (sopSnapshotId && isWorkOrder === 1)}
                params={{ status: 1, bizType: BizType.production }}
                fetchType="SOP"
                onChange={(val) => {
                  setConnection('sop');
                  /** 工单的编辑在更改时清除sopSnapshotId
                   *  无更改则为生产工单快照sopId
                   *  获取当前的工序号  name: [name, nextName, index, 'processNum'],
                   * sop方案更改 对应产出物料选择对工序的报工工序号更新 报工控件编号清空
                   */
                  if (isWorkOrder) {
                    const recordProcessNum = form.getFieldValue([
                      name,
                      nextName,
                      index,
                      'processNum',
                    ]);
                    const _bomOutputMaterials = form.getFieldValue('bomOutputMaterials');
                    const _bomInputMaterials = form.getFieldValue('bomInputMaterials');

                    const bomOutputMaterials = _bomOutputMaterials?.map((item: any) => {
                      if (item.outputProcessId?.label === recordProcessNum) {
                        return {
                          ...item,
                          outputProcessId: {
                            key: `${uniqueId()}-${val?.value}`,
                            label: recordProcessNum,
                            value: `${uniqueId()}-${val?.value}`,
                          },
                          outputSopControlId: undefined,
                        };
                      }
                      return item;
                    });

                    const bomInputMaterials = _bomInputMaterials?.map((item: any) => {
                      if (item.inputProcessId?.label === recordProcessNum) {
                        return {
                          ...item,
                          inputProcessId: {
                            key: `${uniqueId()}-${val?.value}`,
                            label: recordProcessNum,
                            value: `${uniqueId()}-${val?.value}`,
                          },
                        };
                      }
                      return item;
                    });

                    form.setFields([
                      {
                        name: [name, nextName, index, 'sopSnapshotId'],
                        value: null,
                      },
                      {
                        name: ['bomInputMaterials'],
                        value: bomInputMaterials,
                      },
                      {
                        name: ['bomOutputMaterials'],
                        value: bomOutputMaterials,
                      },
                    ]);
                  }
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        label: '报告模板',
        dependencies: [['enableSop']],
        render: () => () => {
          const enableSop = form.getFieldValue(['enableSop']);

          return (
            <Form.Item style={{ marginBottom: 0 }} name={[name, nextName, index, 'reportIds']}>
              <SearchSelect
                disabled={Boolean(enableSop)}
                mode="multiple"
                fetchType={'reportTemplate'}
                params={{ category: [TemplateType.productionReport] }}
              />
            </Form.Item>
          );
        },
      },
      {
        label: '计划报工数量管控',
        initialValue: 2,
        name: [name, nextName, index, 'planWorkReportQuantityControl'],
        rules: [{ required: true, message: '请选择' }],
        render: () => <Select options={appDict.processRoute.PlanType} placeholder="请选择" />,
      },
      {
        label: '生产任务状态操作管控',
        name: [name, nextName, index, 'productionStatusControl'],
        rules: [{ required: true, message: '请选择' }],
        initialValue: 2,
        render: () => <Select options={appDict.processRoute.ProduceType} placeholder="请选择" />,
      },
    ],
  };
  const resource: DataFormLayoutInfoBlock = {
    column: 1,
    title: '资源清单',
    items: [
      {
        label: '',
        isFullLine: true,
        dependencies: [name, nextName, index, 'workCenterId'],
        render: () => () =>
          (
            <ResourceGroup
              form={form}
              filedName={[name, nextName, index, 'workCenterGroupCOList']}
              onlySelectBiz
            />
          ),
      },
    ],
  };

  return (
    <DataFormLayout
      getAdaptiveContainer={() =>
        document.querySelector('.process-routing-action-form-body') as HTMLDivElement
      }
      form={form}
      title=""
      footer={false}
      info={[baseInfo, produceInfo, resource]}
    />
  );
});

export default ProcessForm;
