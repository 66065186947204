import { useState } from 'react';
import { Button, Select, Space } from 'antd';

import UserOrDepartModal from 'src/page/organization/components/userAndDepartmentSelect/index';
import RoleSelectModal from './roleSelectModal';
import { UserDeptRoleSelectProps, UserDeptRoleItem, LabeledValue } from './index.d';
import { BlIcon } from 'src/components';
import { ListItem, SimpleUserItem } from '../userAndDepartmentSelect/index.d';
import _ from 'lodash';

enum SelectType {
  'user' = 0,
  'dept',
  'role',
}

/** LabeldValue to new format */
const lb2nf = (v: UserDeptRoleItem): SimpleUserItem => ({
  id: +v.value,
  name: v.label,
  avatarUrl: null,
});
/** new format to LabeldValue */
const nf2lb = (v: SimpleUserItem) => ({
  label: v.name,
  value: v.id,
});

export default function UserDeptRoleSelect<VT>(props: UserDeptRoleSelectProps<VT>) {
  const [modalType, setModalType] = useState<SelectType>();

  const {
    isMultiple,
    placeholder = '请选择',
    maxTagCount,
    value = [],
    onChange,
    defaultChecked,
    disabled = false,
    userActives,
  } = props;

  /**
   * 格式化 Select组件 数据到 form
   */
  const formatSelectToForm = (changeValue: LabeledValue[]): UserDeptRoleItem[] => {
    return _.intersectionBy(value, changeValue, 'value');
  };

  /**
   * 格式化 form 数据到 UserSelectModal组件
   * @params value form中的数据
   * @params type 选中的类型用户/部门
   */
  const formatFormToUserSelectModal = (value: UserDeptRoleItem[], type: number): ListItem[] => {
    return _.map(_.filter(value, ['type', type]), lb2nf);
  };

  /**
   * 格式化 form 数据到 RoleSelectModal组件
   * @param value
   */
  const formatFormToRoleModal = (value: UserDeptRoleItem[]): LabeledValue[] => {
    return _.map(_.filter(value, ['type', SelectType.role]), (item) => ({
      label: item.label,
      value: item.value,
    }));
  };

  /**
   * 格式化 UserSelectModal组件 数据到 form
   */
  const formatUserOrDeptToForm = (value: ListItem[], type: number): UserDeptRoleItem[] => {
    return _.map(value.map(nf2lb), (item) => ({ ...item, type }));
  };

  /**
   * 处理用户，部门，角色change
   * @param changeVal
   * @param changeType
   */
  const handleChange = (changeVal: UserDeptRoleItem[], changeType?: number) => {
    // 如果传了changeType，需要把原来value过滤后 和changeVal进行合并
    if (_.isNil(changeType)) {
      if (_.isFunction(onChange)) onChange(changeVal);
    } else {
      const notChangeValue = _.filter(value, ({ type }) => type !== changeType);
      const newVal = _.concat(notChangeValue, changeVal);

      if (_.isFunction(onChange)) onChange(newVal);
    }
  };

  // Select组件 选择值变化, 如删除一项
  const handleSelectChange = (value: LabeledValue[]) => {
    handleChange(formatSelectToForm(value));
  };

  /**
   * UserSelectModal组件 选择值变化
   */
  const handleUserOrDeptChange = (value: ListItem[]) => {
    if (modalType === SelectType.user) {
      handleChange(formatUserOrDeptToForm(value, SelectType.user), SelectType.user);
    }

    if (modalType === SelectType.dept) {
      handleChange(formatUserOrDeptToForm(value, SelectType.dept), SelectType.dept);
    }
  };

  const handleRoleChange = (value: LabeledValue[]) => {
    const newValue = _.map(value, (item) => ({ ...item, type: SelectType.role }));

    handleChange(newValue, SelectType.role);
  };

  const renderAddButton = (title: string, onClick: () => void) => {
    return (
      <Button
        icon={<BlIcon type="iconxinjiantianjia" />}
        type="dashed"
        size="small"
        onClick={onClick}
      >
        {title}
      </Button>
    );
  };

  const renderModal = () => {
    if (modalType === SelectType.user || modalType === SelectType.dept) {
      return (
        <UserOrDepartModal
          isMultiple={isMultiple}
          isSelectUser={modalType === SelectType.user}
          visible={!_.isNil(modalType)}
          onClose={() => setModalType(undefined)}
          submitData={handleUserOrDeptChange}
          choosedData={formatFormToUserSelectModal(value, modalType)}
          defaultChecked={defaultChecked}
          userActives={userActives}
        />
      );
    }

    if (modalType === SelectType.role) {
      return (
        <RoleSelectModal
          isMultiple={isMultiple}
          visible={!_.isNil(modalType)}
          onClose={() => setModalType(undefined)}
          submitData={handleRoleChange}
          choosedData={formatFormToRoleModal(value)}
        />
      );
    }

    return null;
  };

  return (
    <>
      <Select
        placeholder={placeholder}
        maxTagCount={maxTagCount}
        labelInValue
        allowClear
        open={false}
        showArrow={false}
        options={[]}
        mode={isMultiple ? 'tags' : undefined}
        value={value}
        onChange={(newValue) => {
          handleSelectChange(newValue);
        }}
        disabled={disabled}
      />
      <Space style={{ marginTop: 10 }}>
        {renderAddButton('添加用户', () => {
          if (!disabled) setModalType(SelectType.user);
        })}
        {renderAddButton('添加部门', () => {
          if (!disabled) setModalType(SelectType.dept);
        })}
        {renderAddButton('添加角色', () => {
          if (!disabled) setModalType(SelectType.role);
        })}
      </Space>
      {renderModal()}
    </>
  );
}
