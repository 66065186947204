/**
 * 表格中超长自动截断的Link
 */

import { Link } from 'react-router-dom';
import { TextToolTip } from '@blacklake-web/component';
import { hasAuth } from 'src/utils/auth';

interface Props {
  /** 文本 */
  text: string;
  /** 跳转地址 */
  to: string;
  /** 权限 */
  auth?: string;
  /** 最大长度，超出截断 */
  width?: number;
  /** 最大字数，超出截断。与 width 二选一 */
  length?: number;
}

const LinkTooltip = (props: Props) => {
  const { text, to, width, length, auth } = props;
  const canGo = !auth || hasAuth(auth);
  const content = <TextToolTip text={text} width={width} length={length} />;

  return canGo ? <Link to={to}>{content}</Link> : content;
};

export default LinkTooltip;
