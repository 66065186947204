/**
 * 控件编辑器
 *
 */
import { FC, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useToggle } from 'react-use';
import _ from 'lodash';
import { Row, Col, FormInstance, message, FormProps } from 'antd';
import { dividerColor } from 'src/components/styles/color';
import Source from './source';
import Previewer from './previews/previewer';
import PropertyEditor from './properties';
import { ControlEditorContext } from '../constants';
import { BizType } from 'src/dict/sop';
import { StepDetailData } from '../types';
import { useLocation } from 'react-router-dom';
import { BASIC_PATH } from 'src/page/knowledgeManagement/reportTemplate/constants';

interface Props {
  form: FormInstance<StepDetailData>;
  loading: boolean;
  localKeys: string[];
  setLocalKeys: (keys: string[], isInit?: boolean) => void;
  bizType: BizType;
  objectCode?: string; // 对象code
  formProps?: FormProps;
}

const commonStyles: React.CSSProperties = {
  borderLeft: `1px solid ${dividerColor}`,
  maxHeight: '100%',
  overflowY: 'auto',
};

const ControlEditor: FC<Props> = ({
  form,
  loading,
  localKeys,
  setLocalKeys,
  bizType,
  objectCode = 'SOPControl',
  formProps,
}) => {
  const { pathname } = useLocation();
  const isReportTemplate = pathname.includes(BASIC_PATH);

  // 选中的控件, 用 _localKey 作唯一标识, 提交到后端前应去除
  const [selectedControl, setSelectedControl] = useState<string | null>(null);
  // 让右侧表单与预览区实时联动
  const [, forceUpdate] = useToggle(false);
  const [hasError, setHasError] = useState(false);

  // 校验控件字段, 返回校验是否通过
  const validate = () =>
    form
      .validateFields()
      .then(() => {
        setHasError(false);
        return true;
      })
      .catch(({ errorFields }) => {
        // 查看是否有controls域下报错, 有的话就阻止选择其他控件
        const hasControlError = errorFields.find(
          (err: any) => _.isArray(err.name) && _.first(err.name) === 'controls',
        );

        if (hasControlError) {
          if (!hasError) {
            setHasError(true);
            message.error('请先正确填写当前控件的配置项');
          }
          return false;
        }
        setHasError(false);
        return true;
      });

  return (
    <ControlEditorContext.Provider
      value={{
        forceUpdate: _.debounce(forceUpdate, 100),
        formProps: formProps || {},
        bizType,
        sopBasicInfo: {
          isReportTemplate,
          stepId: form.getFieldValue('id'),
        },
      }}
    >
      <Row style={{ height: 624, display: 'flex', border: '1px solid #eee' }}>
        <DndProvider backend={HTML5Backend}>
          <Col span={6} style={{ ...commonStyles, display: 'flex' }}>
            <Source validate={validate} hasError={hasError} bizType={bizType} />
          </Col>
          <Col span={12} style={{ ...commonStyles, display: 'flex', flexDirection: 'column' }}>
            <Previewer
              form={form}
              loading={loading}
              localKeys={localKeys}
              setLocalKeys={setLocalKeys}
              selectedControl={selectedControl}
              selectControl={setSelectedControl}
              validate={validate}
            />
          </Col>
        </DndProvider>
        <Col span={6} style={{ ...commonStyles, padding: 12 }}>
          <PropertyEditor form={form} selectedControl={selectedControl} objectCode={objectCode} />
        </Col>
      </Row>
    </ControlEditorContext.Provider>
  );
};

export default ControlEditor;
