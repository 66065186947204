import { useState } from 'react';
import { Modal, message } from 'antd';
import { FilterItem, RecordListLayout } from 'layout';
import { gcArray, gcObject } from 'utils';
import _, { isEmpty } from 'lodash';
import {
  fetchInventoryElementListPage,
  FetchInventoryElementListPageResponse,
} from 'src/api/ytt/inventory-domain';
import lookup, { appDict, appEnum } from 'src/dict';
import { SearchSelect } from 'src/components';
import moment from 'moment';

type TableRowType = Exclude<
  FetchInventoryElementListPageResponse['data'],
  undefined
>['list'][number];

interface Props {
  visible: boolean;
  onCancel?: () => void;
  materialId?: number;
  wareHouseId?: number;
  onSubmit?: (rows?: any[]) => void;
}

const SelectInventory = (props: Props) => {
  const { visible, onCancel, wareHouseId, materialId, onSubmit } = props;
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>();
  const dataColumns = [
    {
      title: '仓位名称',
      dataIndex: 'storageLocationId',
      isFilter: true,
      fixed: 'left',
      width: 150,
      renderItem: (
        <SearchSelect
          params={{ storageId: wareHouseId }}
          mode="multiple"
          labelInValue
          fetchType="storageLocation"
        />
      ),
      render: (text: string, record: TableRowType) => <a>{record.location?.storage?.name}</a>,
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '现有量',
      width: 150,
      dataIndex: 'opAmount',
      render: (opAmount: any) => {
        const { amount = {}, auxAmounts = [] } = opAmount || {};

        return (
          <div>
            <span>
              {amount.amount}
              {amount.unit?.name}
            </span>
            {auxAmounts.map((aux: any) => (
              <span>
                , {aux.amount}
                {aux.unit?.name}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      title: '位置状态',
      dataIndex: 'storageStatus',
      width: 150,
      render: lookup('bound.storageStatus'),
    },
    {
      title: '质量状态',
      dataIndex: 'qcStatus',
      render: lookup('bound.qualityStatus'),
      width: 150,
    },
    {
      title: '批次号',
      dataIndex: 'batchNo',
      isFilter: true,
      width: 150,
      render: (text: any, record: TableRowType) => record.bizKey?.batchNo,
    },
    {
      title: '标识码',
      dataIndex: 'qrCode',
      width: 150,
      isFilter: true,
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '供应商',
      dataIndex: 'supplier',
      width: 150,
      isFilter: true,
      render: (value: any, record: TableRowType) => record.bizKey?.supplier?.name,
      renderItem: <SearchSelect mode="multiple" labelInValue fetchType="supplier" />,
    },
    {
      title: '供应商批次',
      dataIndex: 'supplierBatchNo',
      width: 150,
      render: (value: any, record: TableRowType) => record.bizKey?.supplierBatchNo,
    },
    {
      title: '客户',
      dataIndex: 'customer',
      width: 150,
      render: (value: any, record: TableRowType) => record.bizKey?.customer?.name,
    },
    {
      title: '入厂日期',
      dataIndex: 'inboundTime',
      width: 150,
      render: (value: any, record: TableRowType) => {
        if (!record.bizKey?.inboundTime) {
          return '-';
        }
        return moment(record.bizKey?.inboundTime).format('YYYY/MM/DD HH:mm:ss');
      },
    },
    {
      title: '生产日期',
      width: 150,
      dataIndex: 'productTime',
      render: (value: any, record: TableRowType) => {
        if (!record.bizKey?.productTime) {
          return '-';
        }
        return moment(record.bizKey?.productTime).format('YYYY/MM/DD HH:mm:ss');
      },
    },
    {
      title: '有效期至',
      dataIndex: 'validityPeriod',
      width: 150,
      render: (value: any, record: TableRowType) => {
        if (!record.bizKey?.validityPeriod) {
          return '-';
        }
        return moment(record.bizKey?.validityPeriod).format('YYYY/MM/DD HH:mm:ss');
      },
    },
  ];

  const config = {
    rowKey: (record: TableRowType) => String(record.id),
    columns: dataColumns,
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((columns: any): FilterItem => {
        const filter = {
          label: columns.title,
          name: columns.dataIndex,
          ...columns,
        };

        return filter;
      }),
  };

  return (
    <Modal
      destroyOnClose
      keyboard={false}
      maskClosable={false}
      width={1200}
      title="添加库存"
      visible={visible}
      onCancel={() => onCancel?.()}
      onOk={() => {
        if (gcArray.isEmpty(selectedRowKeys)) {
          message.error('请选择库存');
        } else {
          onSubmit?.(selectedRows);
        }
      }}
    >
      <RecordListLayout<TableRowType>
        placeholder={'请输入关键字'}
        style={{ height: 700 }}
        useIndex={false}
        batchMenu={[]}
        useColConfig={false}
        filterContaniner={false}
        useFilterWithUrl={false}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey: any, selectRows?: any[]) => {
          setSelectRowKeys(selectKey);
          setSelectedRows(selectRows);
        }}
        requestFn={(param: any) =>
          fetchInventoryElementListPage(
            gcObject.filterEmptyProperty({
              ..._.omit(param, ['storageLocationId', 'supplier']),
              ...(!isEmpty(param.storageLocationId)
                ? {
                    storageLocationId: _.map(param.storageLocationId, 'value'),
                  }
                : {
                    warehouseId: wareHouseId ? [wareHouseId] : null,
                  }),
              bizStatus: [appEnum.Bound.inventoryBizStatusEnum.noBiz],
              materialId,
              supplierIds: _.map(param?.supplier, 'value'),
            }),
          )
        }
        {...config}
      />
    </Modal>
  );
};

export default SelectInventory;
