/**
 *  @control 日期时间
 */
import { FC } from 'react';
import { Input } from 'antd';
import { BlIcon } from 'src/components';
import type { PreviewCompProps } from '../../types';

export const Time: FC<PreviewCompProps> = ({ inputPrompt }) => {
  return (
    <Input
      style={{ pointerEvents: 'none' }}
      tabIndex={-1}
      suffix={<BlIcon type="iconshijian" />}
      placeholder={inputPrompt}
    />
  );
};
