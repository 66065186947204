import { useState, useEffect, useMemo } from 'react';
import { Space, Button, Table, message as Message, Modal } from 'antd';
import { ColumnType } from 'antd/lib/table';
import moment, { Moment } from 'moment';

import {
  fetchWorkerCalendarPreView,
  FetchWorkerCalendarPreViewRequest,
  fetchWorkerCalendarModifyShift,
  FetchWorkerCalendarModifyShiftRequest,
  FetchWorkerCalendarShiftImportRequest,
  fetchWorkerCalendarShiftImport,
  FetchShiftListRequest,
  FetchShiftListResponse,
  fetchShiftList,
  FetchShiftRuleListRequest,
  FetchShiftRuleListResponse,
  fetchShiftRuleList,
} from 'src/api/ytt/calendar-domain';
import { exportWorkCalendar } from 'src/services/workCalendar';
import { BlIcon } from 'src/components';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import authDict, { hasAuth } from 'src/utils/auth';
import {
  CalendarDataType,
  GetElementType,
  UserColumnProps,
  ScheduleShiftValueType,
  ScheduleTableDateInfoType,
  ShiftsListProps,
  ShiftRulesListProps,
  ShiftContext,
} from '../../constants';
import ImportAndExport from '../ImportAndExport';

import CalendarDateSwitcher from '../../components/CalendarDateSwitcher';
import FlexCalendarTableCell from './FlexCalendarTableCell';
import styles from './index.module.scss';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { useDispatch } from 'react-redux';
import { BUSINESS_TYPE } from 'src/dict/objImport';

const { confirm } = Modal;

interface ScheduleShiftCalendarProps {
  scheduleData: CalendarDataType;
  calendarLoading: boolean;
  loadFunction: (startTime?: number, endTime?: number) => void;
  readOnly?: boolean;
}

/**
 * 排班表格单行数据格式
 * key是该行user的id，仅作前端表格渲染用
 * userVO是user的信息
 * 之后为日期的数字 日期: 当日排班信息 这样的格式，数量为当前月的天数，不固定
 */
interface ScheduleShiftTableRecordProps {
  key: number;
  userVO: UserColumnProps;
  [date: number]: ScheduleTableDateInfoType;
}

/** 排班表格最左的用户列 */
const userColumn = {
  title: '用户',
  dataIndex: 'userVO',
  width: 200,
  sorter: false,
  fixed: true,
  render: (userInfo: UserColumnProps) => (
    <div className={styles.userCell}>
      <AvatarDisplay
        id={userInfo.id}
        name={userInfo.name}
        avatarUrl={userInfo.avatarUrl}
        isUser
        isShowTag={false}
      />
    </div>
  ),
};

const weekDaysDisplayName = ['日', '一', '二', '三', '四', '五', '六'];
/**
 * 日期列表头renderer
 * @param dateInfo 当前月的moment
 * @param dayOfMonth 当前日期
 * @return 渲染日期列表头的模版
 * */
const getDateHeader = (dateInfo: Moment, dayOfMonth: number) => {
  return (
    <div>
      {dayOfMonth}
      <hr />
      {weekDaysDisplayName[dateInfo.date(dayOfMonth).day()]}
    </div>
  );
};

/** 导入排班文件的帮助信息模版 */
const importHelpTip = (
  <ol className={styles.helpTipList}>
    <li>导出 排班.xlsx</li>
    <li>打开导出表，将对应信息填入或黏贴进本表。为保证导入成功，请使用纯文本或数字</li>
    <li>信息输入完毕并保存后，拖拽已保存的excel文档上传文件。</li>
    <li>点击&lt;开始导入&gt;。</li>
  </ol>
);

// 虚拟的班次列表分类信息，加载班次下拉框时用，size1000来模拟全部拉取，超过1000条会有bug
// 等lazy loading模式的新下拉框公用组件
const fetchShiftRequestParam: FetchShiftListRequest = {
  shiftWebListBaseCO: { page: 1, size: 1000 },
};
// 虚拟的排班规则列表分类信息，加载排班规则下拉框时用，size1000来模拟全部拉取，超过1000条会有bug
// 等lazy loading模式的新下拉框公用组件
const shiftRulesRequestParam: FetchShiftRuleListRequest = {
  shiftWebListBaseCO: {
    page: 1,
    size: 1000,
  },
};

const tableScroll = { y: 536 };

export default function FlexCalendar({
  scheduleData,
  calendarLoading,
  loadFunction,
  readOnly,
}: ScheduleShiftCalendarProps) {
  /** 初始参数，工作日历的开始时间和结束时间 */
  const calendarStartDateMoment = scheduleData ? moment(scheduleData.startTime) : undefined;
  const calendarEndDateMoment = scheduleData ? moment(scheduleData.endTime) : undefined;
  /** 排班表格的绑定数据，转换格式后的排班信息 */
  const [calendarTableData, setCalendarTableData] = useState<ScheduleShiftTableRecordProps[]>();
  /** 排班表格的绑定数据，转换格式后的排班信息 */
  const [resetInitData, setResetInitData] = useState<ScheduleShiftTableRecordProps[]>();
  /** 当前排班表格是否被编辑过，用于切换月份/重置之前校验，提示用户保存 */
  const [dirty, setDirty] = useState<boolean>(false);
  /** 当前选中的月份moment对象，只要月份正确，日期无所谓 */
  const [selectedDate, setSelectedDate] = useState<Moment | undefined>(calendarStartDateMoment);
  /** 班次数据 */
  const [shifts, setShifts] = useState<ShiftsListProps>([]);
  /** 排班规则数据 */
  const [shiftRules, setShiftRules] = useState<ShiftRulesListProps>([]);
  /** 是否让所有的单元格都刷新 */
  const [cellRefreshAll, setCellRefreshAll] = useState(false);
  const [savingUpdate, setSavingUpdate] = useState(false);
  /** 导入导出公共模块接入 */
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  /**
   * 刷新当前日历的方法
   * 在切换日期/导入完成对时候调用
   */
  const reloadCurrentCalendar = () => {
    if (selectedDate) {
      loadFunction(
        selectedDate.startOf('month').valueOf(),
        selectedDate.endOf('month').startOf('day').valueOf(),
      );
    }
  };

  /**
   * 切换月份，设置当前选中的月份，并重新加载目标月的排班数据
   * @param newDate 目标月份moment
   */
  const switchDate = (newDate: Moment) => {
    setSelectedDate(newDate);
  };

  /**
   * 格式化排班数据成适合前端显示的格式，主要是将原来的排班信息数组转换成日期：排班信息的key-value形式，并且初始化showPopover为false
   * @param originData 从后台请求到的排班详情数据
   */
  const formatSheduleData = (originData: CalendarDataType) => {
    if (originData) {
      const { shiftVos } = originData;
      let momentDate: Moment = moment(scheduleData?.startTime);

      const formattedShiftVos = shiftVos?.map((shiftVo) => {
        if (shiftVo && shiftVo.dateInfos) {
          return Object.assign(
            {},
            { userVO: shiftVo.userVO, key: shiftVo.userVO?.id },
            ...shiftVo?.dateInfos?.map((dateInfo) => {
              const { day } = dateInfo;

              momentDate = moment(day);
              return {
                [momentDate.date()]: {
                  ...dateInfo,
                  showPopover: false,
                },
              };
            }),
          );
        }
      });

      setCalendarTableData(formattedShiftVos);
      setResetInitData(formattedShiftVos);
    }
  };

  /**
   * 排班方法，在排班弹窗中选择了按天/排班规则排班以后，更新表格数据
   * @param selectedValue 有一个flag 'isShift'表示是否按天排班，其值代表选中的班次/排班规则对象
   * @param updatingKey 当前需要更新行的key
   * @param dataIndex 当前更新单元格的dataIndex，即更新哪一天的班次信息
   * @param dateInfo 当前单元格的表格数据，其实可以通过前面2个参数取到，但是因为在外层调用者中可以拿到，传进来避免重复查询
   */
  const scheduleShift = async (
    selectedValue: ScheduleShiftValueType,
    updatingKey: number,
    dataIndex: number,
    dateInfo: ScheduleTableDateInfoType,
  ) => {
    const { isShift, ...rest } = selectedValue;

    // 排班方法被调用表示当前表格被编辑，标识为dirty
    setDirty(true);
    setCellRefreshAll(true);
    // 区分按天排班/按排班规则排班
    if (isShift) {
      // 如果是按天排班，生成新的表格数据，找到这一天并更新班次信息，并且关闭当前格的排班弹窗
      const updatedTableData = [...(calendarTableData || [])].map(
        (tableRowRecord: ScheduleShiftTableRecordProps) => {
          if (tableRowRecord.key === updatingKey) {
            const dateInfo = tableRowRecord[dataIndex];

            return {
              ...tableRowRecord,
              [dataIndex]: { ...dateInfo, shiftDetail: rest, showPopover: false },
            };
          }
          return tableRowRecord;
        },
      );

      // 设置新的表格数据
      setCalendarTableData(updatedTableData);
    } else {
      // 如果是按排班规则排班，需要访问后台获取排班预览
      // 首先生成请求参数，排班的开始时间和结束时间
      const monthEndDate = moment(dateInfo.day).endOf('month');

      if (rest.id && calendarEndDateMoment) {
        const queryParam: FetchWorkerCalendarPreViewRequest = {
          shiftRuleId: rest.id,
          startTime: dateInfo.day,
          endTime: moment.min(calendarEndDateMoment, monthEndDate).startOf('day').valueOf(),
        };

        setSavingUpdate(true);

        // 发出请求获取排班数据
        const { code, data, message } = await fetchWorkerCalendarPreView(queryParam);

        setSavingUpdate(false);
        // 如果请求成功返回，把获取到的新排班数据merge到现有排班数据中：重复的覆盖，不重复的保留
        if (code === 200 && data) {
          const formattedData = data.map((dateInfoItem) => {
            const momentOfDay = moment(dateInfoItem.day);

            return { [momentOfDay.date()]: dateInfoItem };
          });

          const updatedTableData = [...(calendarTableData || [])].map(
            (tableRowRecord: ScheduleShiftTableRecordProps) => {
              if (tableRowRecord.key === updatingKey) {
                const { key, userVO, ...rest } = tableRowRecord;
                let recordMap = new Map(Object.entries(rest));

                formattedData.forEach((formattedDateInfo) => {
                  // 可以确定每一个formattedDateInfo都只有一个属性，是format中构成的{date: dateInfo}
                  const objectKey = Object.keys(formattedDateInfo)[0];

                  recordMap = recordMap.set(objectKey, {
                    ...formattedDateInfo[Number(objectKey)],
                    showPopover: false,
                  });
                });
                const newDateInfos = Object.fromEntries(recordMap);

                return {
                  key,
                  userVO,
                  ...newDateInfos,
                };
              }
              return tableRowRecord;
            },
          );

          // 设置新的表格数据
          setCalendarTableData(updatedTableData);
        } else {
          Message.error(message);
        }
      }
    }
  };

  /**
   * 更改排班浮层的显示状态，打开或者关闭
   * 为了做“打开弹窗时，被点击的单元格持续高亮”这个效果，必须加上showPopover这个属性
   * 虽然因为表格单元格的结构比较简单，这样写并不影响性能，但是这种做法会导致整个组件的重绘
   * @param showPopover 是否显示浮层
   * @param updatingKey 当前编辑行的key
   * @param dataIndex 当前编辑单元格的dataIndex，即日期
   */
  const changePopoverVisible = (showPopover: boolean, updatingKey: number, dataIndex: number) => {
    // map产生新的数据，找到对应单元格更新showPopover字段
    const updatedTableData = [...(calendarTableData || [])].map(
      (tableRowRecord: ScheduleShiftTableRecordProps) => {
        if (tableRowRecord.key === updatingKey) {
          const dateInfo = tableRowRecord[dataIndex];

          return {
            ...tableRowRecord,
            [dataIndex]: { ...dateInfo, showPopover },
          };
        }
        return tableRowRecord;
      },
    );

    // 设置新的表格数据
    setCalendarTableData(updatedTableData);
  };

  /**
   * 生成列定义
   * 由于列不固定，所以排班表格的列定义需要动态生成。
   * @param momentDate 当前月份
   * @returns 计算得到的列定义数组，其中每一项表示某一天的列
   */
  const generateColumns = (momentDate?: Moment) => {
    if (!momentDate) return [];
    // 首先插入用户列
    const newColumns: ColumnType<ScheduleShiftTableRecordProps>[] = [userColumn];

    // 根据本月的天数，一个个生成列，每天为一列
    for (let i = 0; i < momentDate.daysInMonth(); i++) {
      newColumns.push({
        // 列头由getDateHeader计算得出
        title: getDateHeader(momentDate, i + 1),
        // dataIndex就是当前日期的字符串
        dataIndex: `${i + 1}`,
        width: 72,
        sorter: false,
        fixed: false,
        // 为了解决性能问题，必须设置shouldCellUpdate方法，否则排班浮层出现的时候会有一点卡顿
        // shouldCellUpdate: (newRecord, oldRecord) => {
        //   if (cellRefreshAll) return true;
        //   // 获取单元格更新前后的数据记录
        //   const newCellData = newRecord[i + 1];
        //   const oldCellData = oldRecord[i + 1];

        //   // 如果这2条记录对应的日期变了，说明当前工作日历切换了月份，必须重新渲染
        //   if (newCellData?.day !== oldCellData?.day) return true;
        //   // 否则的话，只有当浮层显示状体啊发生改变的时候，或者当天班次信息发生改变的时候，需要更新变动的那一格，其他格都保持不变
        //   return (
        //     newCellData?.showPopover !== oldCellData?.showPopover ||
        //     newCellData?.shiftDetail?.id !== oldCellData?.shiftDetail?.id
        //   );
        // },
        // 绘制单元格的方法
        render: (dateInfo: ScheduleTableDateInfoType, { key }) => (
          <FlexCalendarTableCell
            dateInfo={dateInfo}
            rowKey={key}
            dataIndex={i + 1}
            readOnly={readOnly}
            scheduleShift={scheduleShift}
            changePopoverVisible={changePopoverVisible}
          />
        ),
      });
    }

    if (cellRefreshAll) setCellRefreshAll(false);
    return newColumns;
  };

  /**
   * 更新排班信息的请求，把前端数据转换为接口要求的格式，并请求更新排班数据
   * @returns 请求是否成功，成功返回response，不成功返回false
   */
  const modifyShiftForCalendar = async () => {
    if (scheduleData && calendarTableData) {
      const shiftRecords: FetchWorkerCalendarModifyShiftRequest['shiftRecords'] = [];

      type ShiftsType = GetElementType<
        FetchWorkerCalendarModifyShiftRequest['shiftRecords']
      >['shifts'];

      calendarTableData.forEach((calendarTableRecord) => {
        const { userVO, ...rest } = calendarTableRecord;
        const shifts: ShiftsType = [];

        Object.keys(rest).forEach((dataKey) => {
          if (rest[Number(dataKey)]?.shiftDetail) {
            shifts.push({
              day: rest[Number(dataKey)].day,
              shiftId: rest[Number(dataKey)].shiftDetail.id as number,
            });
          }
        });

        shiftRecords.push({
          businessId: userVO.id,
          businessType: 1,
          shifts,
        });
      });
      const requestParams: FetchWorkerCalendarModifyShiftRequest = {
        workerCalendarId: scheduleData.id,
        shiftRecords,
      };

      try {
        setSavingUpdate(true);
        const response = await fetchWorkerCalendarModifyShift(requestParams);

        setSavingUpdate(false);
        if (response.code === 200) {
          setDirty(false);
          setResetInitData(calendarTableData);
          Message.success('保存完成');
          return response;
        }
        Message.error(`保存失败: ${response.message}`);
      } catch (e) {
        console.log(e);
      } finally {
        setSavingUpdate(false);
      }

      return null;
    }
    return null;
  };

  /**
   * 切换日历时的脏检测提示确认框
   * @returns 是否继续切换月份的promise，给切换日期组件
   */
  const confirmChange = () => {
    return new Promise<boolean>((resolve, reject) => {
      confirm({
        title: '是否保存当月排班信息',
        icon: <BlIcon type="iconmianxingtixing-jingshi" />,
        content: '当月排班信息尚未保存，切换月份将丢失当前排班，请确认是否保存？',
        okText: '确定',
        cancelText: '取消',
        onOk: async () => {
          const result = await modifyShiftForCalendar();

          if (result) resolve(true);
          else reject();
        },
        onCancel() {
          resolve(true);
          setDirty(false);
        },
      });
    });
  };

  /** 重置方法，恢复之前保存的数据并清空脏flag */
  const resetScheduleTable = () => {
    setCalendarTableData(resetInitData);
    setCellRefreshAll(true);
    setDirty(false);
  };

  /** 重置确认框，仅当dirty时点击重置按钮触发 */
  const resetScheduleClick = () => {
    if (dirty) {
      confirm({
        title: '是否重置当月排班信息',
        icon: <BlIcon type="iconmianxingtixing-jingshi" />,
        content: '当月排班信息尚未保存，重置表格将丢失当前排班，请确认是否重置？',
        okText: '重置',
        cancelText: '取消',
        onOk: async () => {
          resetScheduleTable();
        },
      });
    }
  };

  /** 导出排班模版文件 */
  const exportCurrentMonthSchedule = async () => {
    if (scheduleData && selectedDate) {
      try {
        exportWorkCalendar(
          scheduleData.id,
          selectedDate.startOf('month').valueOf(),
          selectedDate.endOf('month').valueOf(),
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  /** 导入排班文件 */
  const importSchedule = async (importFileUri: string) => {
    // 在点击导入按钮的时候，日历基本信息已经load完毕，不可能为空
    const importParam: FetchWorkerCalendarShiftImportRequest = {
      workerCalendarId: scheduleData?.id as number,
      fileUrl: importFileUri,
    };

    return fetchWorkerCalendarShiftImport(importParam);
  };

  // 请求加载班次
  const loadShift = async () => {
    const response: FetchShiftListResponse = await fetchShiftList(fetchShiftRequestParam);

    if (response.code === 200) {
      setShifts(response.data?.list ?? []);
    } else {
      Message.error(response.message);
    }
  };

  const loadShiftRules = async () => {
    const response: FetchShiftRuleListResponse = await fetchShiftRuleList(shiftRulesRequestParam);

    if (response.code === 200) {
      setShiftRules(response.data?.list ?? []);
    } else {
      Message.error(response.message);
    }
  };

  useEffect(() => {
    loadShift();
    loadShiftRules();
  }, []);

  useEffect(() => {
    if (scheduleData) {
      formatSheduleData(scheduleData);
    }
  }, [scheduleData]);

  useEffect(() => {
    if (selectedDate) {
      reloadCurrentCalendar();
    }
  }, [selectedDate]);

  const columns = useMemo(() => {
    return generateColumns(selectedDate);
  }, [selectedDate, calendarTableData]);

  return (
    <div className={styles.flexCalendarTableContainer}>
      <div className={styles.flexCalendarTableHeader}>
        <div className={styles.fitRangePanelForFlexCalendar}>
          <h5 className={styles.pannelTitle}>适用范围</h5>
        </div>
        <div className={styles.calendarDisplayPanel}>
          <h5 className={styles.pannelTitle}>
            <span className={styles.pannelTitleText}>工作日历</span>
            <Space size={16}>
              {!readOnly &&
                (dirty ? (
                  <>
                    <Button type="primary" onClick={modifyShiftForCalendar}>
                      保存
                    </Button>
                    <Button onClick={resetScheduleClick}>重置</Button>
                  </>
                ) : (
                  // <ImportAndExport
                  //   importTargetName="排班"
                  //   exportMethod={exportCurrentMonthSchedule}
                  //   onImport={importSchedule}
                  //   onImportFinished={reloadCurrentCalendar}
                  //   helpTipContent={importHelpTip}
                  //   fileExtensionLimitHint="文件支持类型：XLSX，单个文件限制不能超过10M"
                  // />
                  <div>
                    {hasAuth(authDict.workcalendar_export) && (
                      <Button
                        icon={<BlIcon type={'icondaochu'} />}
                        style={{ marginRight: 16 }}
                        onClick={() => {
                          dispatch.excelImport.updateBusinessData({
                            businessData: {
                              workerCalendarId: scheduleData?.id,
                            },
                          });
                          openModal({
                            optType: 'export',
                            businessType: BUSINESS_TYPE.workerCalendar,
                          });
                        }}
                      >
                        导出排班
                      </Button>
                    )}
                    {hasAuth(authDict.workcalendar_import) && (
                      <Button
                        icon={<BlIcon type={'icondaoru'} />}
                        onClick={() => {
                          dispatch.excelImport.updateBusinessData({
                            businessData: {
                              workerCalendarId: scheduleData?.id,
                            },
                          });
                          openModal({
                            optType: 'import',
                            businessType: BUSINESS_TYPE.workerCalendar,
                          });
                        }}
                      >
                        导入排班
                      </Button>
                    )}
                  </div>
                ))}
              {selectedDate && (
                <CalendarDateSwitcher
                  value={selectedDate}
                  startDate={calendarStartDateMoment}
                  endDate={calendarEndDateMoment}
                  onChange={switchDate}
                  confirmChange={dirty ? confirmChange : undefined}
                />
              )}
            </Space>
          </h5>
        </div>
      </div>
      <ShiftContext.Provider value={{ shifts, shiftRules }}>
        <Table
          columns={columns}
          dataSource={calendarTableData}
          className={styles.flexCalendarTable}
          pagination={false}
          scroll={tableScroll}
          bordered
          loading={calendarLoading || savingUpdate}
        />
      </ShiftContext.Provider>
    </div>
  );
}
