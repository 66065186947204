import {
  CustomerMaterialRegAuditAcceptRequestItems,
  CustomerMaterialRegAuditListMaterialInfoGetItemProps,
} from './interface';

/**
 * 数据处理 提交数据api
 *
 * 接受物料注册邀请:建立物料转换关系
 */

export const handleFormatTransformUnits = (
  list: (CustomerMaterialRegAuditListMaterialInfoGetItemProps & {
    materialId: {
      label: string;
      value: string;
    };
    materialUnit: {
      label: string;
      value: number;
    };
    materialAmount: number;
  })[],
): CustomerMaterialRegAuditAcceptRequestItems[] => {
  return list?.map((item) => {
    const material = JSON.parse(item?.materialId?.value ?? '{}');

    return {
      itemId: item?.id as number,
      materialAmount: item?.materialAmount,
      materialId: material?.baseInfo?.id,
      unitId: item?.materialUnit?.value,
    };
  });
};
