import { useContext, useState } from 'react';
import { Form, Input, message as Message } from 'antd';
import {
  fetchUserGetUserOrgList,
  fetchUserResetPassword,
  fetchUserUpdatePassword,
  FetchUserDetailResponse,
} from 'src/api/ytt/user-domain';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';

import { DataFormLayoutForModal } from 'layout';

import styles from '../styles.module.scss';
import { validatorCheckPassword, logout } from 'src/utils/login';
import UseLayout from '../useLayout';
import { RESET_PASSWORD, UserInfoContext } from '../constant';
import { useDispatch } from 'react-redux';
import { hashPassword } from 'src/utils/string';
import _ from 'lodash';
import MessageAuth from './messageAuth';
import volcano from 'src/utils/volcano';

interface Props {
  layout?: string;
  modalVisible?: boolean;
  onModalClose?: () => void;
  userInfo?: FetchUserDetailResponse['data'];
}

export default function Password({ layout, modalVisible, onModalClose, userInfo }: Props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [visible, setVisible] = useState<boolean>(false);
  const [hiddenOldPassWord, setHiddenOldPassWord] = useState<boolean>(false); // 是否隐藏旧密码

  const [uuid, setUuid] = useState<string>('');

  let { dataSource } = useContext(UserInfoContext);

  if (layout === 'modal') dataSource = userInfo;

  const handleHiddenOldPassWord = (hidden: boolean) => {
    setHiddenOldPassWord(hidden);
  };

  const handleFinish = async () => {
    try {
      const value = await form?.validateFields();

      const [{ hashHex: hashNew }, { hashHex: hashMakeSure }] = await Promise.all([
        hashPassword(value?.newPassword),
        hashPassword(value?.makeSureNewPassword),
      ]);

      let res;

      if (!uuid) {
        const { hashHex: hashOld } = value.oldPassword && (await hashPassword(value.oldPassword));

        res = await fetchUserUpdatePassword({
          oldPassword: hashOld,
          newPassword: hashNew,
        });
      } else {
        const { data } = await fetchUserGetUserOrgList({ phone: dataSource?.phone });

        const orgCode = _.find(data, (node) => node?.orgId === dataSource?.orgId)?.code;

        if (orgCode) {
          res = await fetchUserResetPassword({
            code: orgCode,
            firstLoginStatus: 1,
            phone: dataSource?.phone,
            makeSureNewPassword: hashMakeSure,
            newPassword: hashNew,
            username: dataSource?.username,
            uuid,
          });
        }
      }

      const code = _.get(res, 'code');

      if (code === 200) {
        onModalClose?.();
        Message.success(uuid ? '重置密码成功,请重新登录' : '修改密码成功,请重新登录');
        volcano.webLogout();
        dispatch({ type: 'user/clearUser' });
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const InputStyle = layout === 'modal' ? {} : { width: '33%' };
  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    items: [
      {
        label: '',
        isFullLine: true,
        render: () => (
          <div className={styles?.password}>
            密码至少8位，必须包含数字、大写字母、小写字母、字符符号中的至少3种，不支持中文、空格
          </div>
        ),
      },
      {
        label: '用户名称',
        isFullLine: true,
        render: () => dataSource?.name,
      },
      {
        label: '账号',
        isFullLine: true,
        render: () => dataSource?.username,
      },
      {
        label: '手机号',
        isFullLine: true,
        render: () => (dataSource?.phone ? dataSource?.phone : '暂无'),
      },
      {
        label: '旧密码',
        name: 'oldPassword',
        isFullLine: true,
        validateFirst: true,
        hidden: hiddenOldPassWord,
        rules: [
          { required: !hiddenOldPassWord, message: '请输入旧密码' },
          {
            validator: hiddenOldPassWord ? undefined : validatorCheckPassword(),
          },
        ],
        render: () => (
          <div style={InputStyle}>
            <Input.Password placeholder="请输入旧密码" />
            {dataSource?.phone && (
              <a
                style={{ color: '#02B980', display: 'block', width: 70, height: 22, marginTop: 8 }}
                onClick={() => {
                  setVisible(true);
                }}
              >
                忘记密码？
              </a>
            )}
          </div>
        ),
      },
      {
        label: '新密码',
        name: 'newPassword',
        isFullLine: true,
        validateFirst: true,
        rules: [
          { required: true, message: '请输入新密码' },
          {
            validator: validatorCheckPassword(),
          },
        ],
        render: () => <Input.Password placeholder="请输入新密码" style={InputStyle} />,
      },
      {
        label: '确认新密码',
        isFullLine: true,
        name: 'makeSureNewPassword',
        validateFirst: true,
        rules: [
          { required: true, message: '请确认新密码' },
          {
            validator: validatorCheckPassword(),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('与新密码不一致'));
            },
          }),
        ],
        render: () => <Input.Password placeholder="请确认新密码" style={InputStyle} />,
      },
    ],
  };

  const handleModalClose = () => {
    form.resetFields();
    setHiddenOldPassWord(false);
    setUuid('');
    onModalClose?.();
    logout();
  };

  const renderTopContent = () => (
    <div style={{ paddingLeft: 40, paddingTop: 10, color: '#ffa940' }}>
      <div>管理员账号首次登录需要修改密码，密码规则：</div>
      <div>
        密码至少8位，必须包含数字、大写字母、小写字母、字符符号中的至少三种，不支持中文、空格
      </div>
    </div>
  );

  return (
    <>
      {layout === 'modal' ? (
        <DataFormLayoutForModal
          visible={Boolean(modalVisible)}
          width={800}
          onClose={handleModalClose}
          content={
            <DataFormLayout
              title="修改密码"
              form={form}
              info={[{ ...baseInfo, items: _.slice(baseInfo.items, 1) }]}
              topContext={renderTopContent()}
              onCancel={handleModalClose}
              onFinish={handleFinish}
            />
          }
        />
      ) : (
        <UseLayout
          title="修改密码"
          renderContent={
            <DataFormLayout
              info={[baseInfo]}
              form={form}
              formLayout={'horizontal'}
              onFinish={handleFinish}
              onCancel={() => {
                form.resetFields();
                setHiddenOldPassWord(false);
                setUuid('');
              }}
            />
          }
        />
      )}
      <MessageAuth
        hiddenOldPassWord={handleHiddenOldPassWord}
        handleUuid={(uuid: string) => {
          setUuid(uuid);
        }}
        showType={RESET_PASSWORD}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        phone={dataSource?.phone}
        bindPhoneTag={false}
      />
    </>
  );
}
