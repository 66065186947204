import { Popover, Space } from 'antd';
import { BlIcon, BlTooltip, QrCode } from 'src/components';

// import { replaceSign } from 'src/utils/constants';

export default function renderQrCode(qrCode: string | undefined) {
  return qrCode ? (
    <Space>
      <BlTooltip text={qrCode} width={200} />
      <Popover
        placement="rightTop"
        content={
          <div>
            <QrCode value={qrCode} />
            <div style={{ textAlign: 'center', justifyContent: 'center' }}>
              <BlTooltip text={qrCode} width={120} />
            </div>
          </div>
        }
      >
        <BlIcon type="iconerweima" />
      </Popover>
    </Space>
  ) : undefined;
}
