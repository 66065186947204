import { number } from 'echarts';
import { BomResponseMaterial } from 'src/page/knowledgeManagement/engineerData/bom/index.type';

// 工单
export const ENGINEER_PATH = '/productionPlanning/execution';

type WorkOrderDto = {
  id: string;
  orderCode: string;
  lineNo: number;
  deliveryDate: number;
  amount: number;
  material: BomResponseMaterial;
};

/**
 * 创建工单
 * @returns string
 */
//  : string | Parameters<RouteComponentProps['history']['push']>
export const toCreateProductionOrder = (state?: WorkOrderDto, layoutId?: number) => {
  const path = `${ENGINEER_PATH}/productionOrder/create`;

  return {
    pathname: path,
    state: state ?? undefined,
    search: `?layoutId=${layoutId}`,
  };
};

/**
 * 编辑工单
 * @param id
 * @returns string
 */
export const toEditProductionOrder = (id: number, layoutId?: number) =>
  `${ENGINEER_PATH}/productionOrder/edit/${id}?layoutId=${layoutId}`;

/**
 * 复制工单
 * @param id
 * @returns string
 */
export const toCopyProductionOrder = (id: number, layoutId?: number) =>
  `${ENGINEER_PATH}/productionOrder/copy/${id}?layoutId=${layoutId}`;

/**
 * 查看工单
 * @param id
 * @returns string
 */
export const toDetailProductionOrder = (id: number, layoutId?: number) =>
  `${ENGINEER_PATH}/productionOrder/detail/${id}?layoutId=${layoutId}`;

/**
 * 下发日志
 * @returns
 */
export const toLogProductionOrder = () => `${ENGINEER_PATH}/productionOrder/log`;

/**
 * 下发日志详情
 * @param id
 * @returns
 */
export const toLogDetailProductionOrder = (id: number) =>
  `${ENGINEER_PATH}/productionOrder/log/detail/${id}`;

/**
 * 工单列表
 * @returns
 */
export const toProductionOrderList = () => `${ENGINEER_PATH}/productionOrder`;
