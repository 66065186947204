import { appEnum } from 'src/dict';
import _, { isUndefined } from 'lodash';
import { getValueFromStringfy } from './tools';
const { TriggerRule, CustomField } = appEnum;

type option = {
  label: string | number;
  value: string | number;
  key: string | number;
};
const isEmpty = (type: number) =>
  type === TriggerRule.ConditionType.NOT_NULL || type === TriggerRule.ConditionType.NULL;

const getValuesFromOptionType = (value: option | option[]) => {
  if (_.isArray(value)) {
    return [_.map(value, 'value'), _.map(value, 'label')];
  }
  return [[_.get(value, 'value')], [_.get(value, 'label')]];
};
const getValueToRequest = (
  fieldType: number,
  valueType: number,
  value: number | string | option[],
  conditionType: number,
) => {
  if (_.isNil(value) || isEmpty(conditionType)) {
    return [];
  }
  if (valueType === TriggerRule.ValueType.biz) {
    const values = _.map(value as option[], 'value');
    const displayValues = _.map(value as option[], 'label');

    return [[values.join(',')], [displayValues.join(',')]];
  }
  switch (fieldType) {
    case CustomField.FieldType.text:
    case CustomField.FieldType.textArea:
    case CustomField.FieldType.number:
    case CustomField.FieldType.integer:
    case CustomField.FieldType.boolean:
      return [[value], [value]];
    case CustomField.FieldType.date:
      return [[value], [new Date(value as number).toLocaleString().replaceAll('/', '-')]];
    case CustomField.FieldType.select:
      return getValuesFromOptionType(value as option | option[]);
    case CustomField.FieldType.multiSelect:
    case CustomField.FieldType.relation:
    case CustomField.FieldType.relationSub:
    case CustomField.FieldType.subordinate:
      return getValuesFromOptionType(value as option | option[]);
  }
  return [];
};

let seq = 1;
const formatCondition = (data: any) => {
  const {
    updateDataCheck,
    fieldId,
    beforeValues,
    beforeValueType,
    beforeConditionType,
    afterValues,
    afterValueType,
    afterConditionType,
    ...rest
  } = data;
  const lastfield: any = _.last(fieldId);
  const lastfieldInfo: any = lastfield?.info;
  const fieldType = lastfieldInfo?.fieldType;

  const [_afterValues, afterDisplayValues] = getValueToRequest(
    fieldType,
    afterValueType,
    afterValues,
    afterConditionType,
  );
  const [_beforeValues, beforeDisplayValues] = updateDataCheck
    ? getValueToRequest(fieldType, beforeValueType, beforeValues, beforeConditionType)
    : [];

  return {
    seq: seq++,
    ...rest,
    updateDataCheck: isUndefined(updateDataCheck) ? 0 : Number(updateDataCheck),
    fieldId: _.map(fieldId, 'value').join(','),
    beforeConditionType: _.isNil(beforeConditionType) ? undefined : beforeConditionType,
    afterConditionType,
    beforeValueType:
      isEmpty(beforeConditionType) || _.isNil(beforeValueType) ? undefined : beforeValueType,
    afterValueType: isEmpty(afterConditionType) ? undefined : afterValueType,
    afterValues: _afterValues,
    beforeValues: _beforeValues,
    afterDisplayValues,
    beforeDisplayValues,
  };
};

const formatTimeIntervalTrigger = (data: any) => {
  if (!data) return data;
  const {
    triggerWeekDay,
    triggerYearMonth,
    triggerMonthDay,
    execTypeValue,
    triggerEveryDayTime,
    ...rest
  } = data;
  let _triggerEveryDayTime: string | undefined = _.isArray(triggerEveryDayTime)
    ? triggerEveryDayTime.join(',')
    : undefined;

  if (execTypeValue === appEnum.TriggerRule.ExecType.customize) {
    _triggerEveryDayTime = undefined;
  }

  return {
    execTypeValue,
    triggerEveryDayTime: _triggerEveryDayTime,
    triggerWeekDay: triggerWeekDay?.join(','),
    triggerYearMonth: triggerYearMonth?.join(','),
    triggerMonthDay: triggerMonthDay?.join(','),
    ...rest,
  };
};

export const formatBizEventTrigger = (data: any) => {
  if (_.isNil(data)) return undefined;
  return {
    ...data,
    triggerEventType: _.isArray(data?.triggerEventType)
      ? _.map(data?.triggerEventType, 'value')
      : data?.triggerEventType,
    delayTriggerField: data.delayTriggerField?.value,
  };
};

export const formatConditionGroups = (data: any) =>
  _.map(data, (item) => {
    const condition = _.get(item, 'condition');
    const innerConditions = _.get(item, 'innerConditions');
    const type = _.get(item, 'type');
    let _condition;
    let _innerConditions;

    if (condition && _.isUndefined(type)) {
      _condition = formatCondition(condition);
    } else if (!_.isUndefined(type)) {
      _innerConditions = innerConditions.map(formatCondition);
    }

    return {
      condition: _condition,
      innerConditions: _innerConditions,
      type,
    };
  });

export const formatDataToRequest = (data: any, id?: number) => {
  seq = 1;
  const {
    objectCode,
    parentRuleId,
    timeIntervalTrigger,
    bizEventTrigger,
    triggerCondition,
    triggerType,
    ...rest
  } = data;

  return {
    ...rest,
    id,
    triggerType,
    objectCode: getValueFromStringfy(objectCode, 'objectCode'),
    parentRuleId: parentRuleId?.value,
    ...(triggerType === appEnum.TriggerRule.TriggerType.bizEventTrigger
      ? { bizEventTrigger: formatBizEventTrigger(bizEventTrigger) }
      : { timeIntervalTrigger: formatTimeIntervalTrigger(timeIntervalTrigger) }),
    triggerCondition: {
      type: triggerCondition.type,
      conditionGroups: formatConditionGroups(triggerCondition.conditionGroups),
    },
  };
};
