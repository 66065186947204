/*
 * @Author: huangsijia
 * @Date: 2022-02-09 17:21:30
 * @LastEditTime: 2022-06-13 16:28:28
 * @LastEditors: huangsijia
 * @Description:
 * @FilePath: /bf-main-3/src/components/modal/useBlModal.tsx
 */
import React, { useCallback, useEffect, useRef } from 'react';
import { Button, Modal, ModalFuncProps } from 'antd';
import { BlIcon } from 'components';
import { ButtonProps } from 'antd/lib/button/button';
import styles from './modal.module.scss';

type ShowConfig = ModalFuncProps & {
  header?: boolean;
  footer?: boolean;
  isDisplayCloseBtn?: boolean;
};

const mergeConfig = (
  config: ShowConfig,
  modalRender: (node: React.ReactNode, title?: any) => React.ReactNode,
): ModalFuncProps => {
  if (config) {
    const { title, header, footer, onCancel, ...other } = config;
    const defaultConfig = {
      centered: true,
      icon: null,
      okText: '确认',
      cancelText: '取消',
      destroyOnClose: true,
      cancelButtonProps: { size: 'large' } as ButtonProps,
      okButtonProps: { size: 'large' } as ButtonProps,
      modalRender: (modal: any) => modalRender(modal, config),
    };

    return { ...defaultConfig, ...other };
  }
  return {};
};

/**
 *  ⚠️️️️⚠️⚠️ 该方法只可以弹出一些内容， 若要获取context，redux， 会有问题
 *  https://ant.design/components/modal-cn/#FAQ
 *
 * @param config
 */
const useBlModal = () => {
  const modalInstance = useRef<any>(null);
  const hide = useCallback(() => {
    modalInstance.current && modalInstance.current.destroy();
  }, []);

  const modalRender = useCallback(
    (modal, customOptions) => {
      const {
        title,
        header = true,
        footer = true,
        isDisplayCloseBtn = true,
        onCancel,
      } = customOptions;
      const cs = [styles.modal];

      if (!footer) {
        cs.push(styles.hideFooter);
      }

      return (
        <div className={cs.join(' ')}>
          {header && (
            <div className={styles.headerStyle}>
              {title ? (
                <div style={{ fontSize: 18, fontWeight: 'bold', padding: '0 20px' }}>{title}</div>
              ) : null}
              {isDisplayCloseBtn && (
                <Button
                  className={styles.closeBtn}
                  type="text"
                  onClick={() => {
                    onCancel?.();
                    hide();
                  }}
                >
                  <BlIcon type="iconguanbi" style={{ fontSize: 24 }} />
                </Button>
              )}
            </div>
          )}
          {modal}
        </div>
      );
    },
    [hide],
  );

  const show = useCallback(
    (config?: ShowConfig) => {
      modalInstance.current = Modal.confirm(mergeConfig(config || {}, modalRender));
    },
    [modalRender],
  );

  const update = useCallback(
    (config?: ShowConfig) =>
      modalInstance.current && modalInstance.current.update(mergeConfig(config || {}, modalRender)),
    [modalRender],
  );

  // 当这个页面被注销后，销毁全部modal
  useEffect(() => () => Modal.destroyAll());

  return { show, hide, update };
};

export default useBlModal;
