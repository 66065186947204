/**
 * @description 工具方法
 */

import { message } from 'antd';
import {
  fetchPurchaseWorkbenchConfigUpsert,
  FetchPurchaseWorkbenchConfigUpsertRequest,
} from 'src/api/ytt/order-domain/workBench';

/** 更新配置 */
export const updateConfig = async (
  params: FetchPurchaseWorkbenchConfigUpsertRequest,
  callback: () => void,
) => {
  const { code, message: _message } = await fetchPurchaseWorkbenchConfigUpsert(params);

  if (code !== 200) {
    message.error(_message);
    return;
  }
  message.success('保存成功');
  if (typeof callback === 'function') {
    callback();
  }
};

/**
 * 格式化eCharts图表Tooltip Dom样式
 * @param html HTML字符串
 * @param title Tooltip标题
 */
export const formatTooltipHtml = (html: string, title: string) => {
  return `
    <div style="max-width: 246px; position: relative; padding: 15px; background-color: #fff;">
      <div style="
        position: absolute;
        right: -14px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-left-width: 6px;
        border-left-color: #fff;
      "></div>
      <div style="
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #262626;
        white-space: normal;
        word-wrap: break-word;
        word-break: break-all;
      ">
        ${title}
      </div>
      ${html}
    </div>
  `;
};
