/**
 * @file SOP任务详情
 */
import { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import _ from 'lodash';
import { Modal } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock, DetailLayoutMenuItem } from 'layout';
import { fetchSopExecTaskDetail } from 'src/api/ytt/sop-domain';
import { replaceSign } from 'src/utils/constants';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { BizType, TaskStatus } from 'src/dict/sop';
import lookup from 'src/dict';
import useTaskOperations from '../share/useTaskOperations';
import { renderUser, renderUserArray } from 'src/page/share/renderUser';
import type { SopTaskDetail } from '../type';
import { DISPATCH_WARNING, taskCanWithApproval } from '../constants';
import { valueOrHolder } from 'src/utils/formatters';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';

interface Params {
  sopTaskId: string;
}

const baseInfo: DetailLayoutInfoBlock = {
  title: '基本信息',
  column: 2,
  items: [
    { label: '编号', dataIndex: 'code' },
    { label: '状态', dataIndex: 'status', render: lookup('sop.taskStatus') },
    { label: 'SOP任务名称', dataIndex: 'name' },
    { label: 'SOP任务类型', dataIndex: 'bizType', render: lookup('sop.bizType') },
    {
      label: '执行人',
      dataIndex: 'executor',
      render: (list) => renderUserArray(list),
    },
    {
      label: '关联SOP方案',
      dataIndex: 'relateId',
      render: valueOrHolder((sop: any) => `${sop.code}/${sop.name}`),
    },
    {
      label: '任务可执行范围',
      dataIndex: 'canExecUser',
      render: (__, dataSource) => {
        if (_.isEmpty(dataSource)) {
          return replaceSign;
        }
        return renderUserArray(
          [
            ...dataSource.canExecUser,
            ...dataSource.canExecRole,
            ...dataSource.canExecDept,
            ...dataSource.canExecShift,
          ],
          6,
        );
      },
    },
    {
      label: '创建人',
      dataIndex: 'creatorId',
      render: renderUser,
    },
    {
      label: '创建时间',
      dataIndex: 'createdAt',
      render: formatTimeForRender,
    },
    {
      label: '编辑人',
      dataIndex: 'operatorId',
      render: renderUser,
    },
    {
      label: '编辑时间',
      dataIndex: 'updatedAt',
      render: formatTimeForRender,
    },
    {
      label: '备注',
      dataIndex: 'remark',
    },
  ],
};

const cancelReason: DetailLayoutInfoBlock = {
  title: '取消原因',
  items: [
    {
      label: '取消人',
      dataIndex: 'cancelExecutor',
      render: renderUser,
    },
    {
      label: '取消时间',
      dataIndex: 'cancelTime',
      render: formatTimeForRender,
    },
    {
      label: '取消原因',
      dataIndex: 'reason',
    },
  ],
};

const approvalInfo: DetailLayoutInfoBlock = {
  title: '审批内容',
  items: [
    {
      label: '审批单',
      dataIndex: 'approvalCode',
      render: (approvalCode, dataSource) => {
        if (_.isNil(approvalCode)) {
          return replaceSign;
        }
        return (
          <a
            href={`/universal/approvalManagement/approval/${dataSource.approvalId}/detail`}
            target={'_blank'}
            rel="noreferrer"
          >
            {approvalCode}
          </a>
        );
      },
    },
    {
      label: '审批状态',
      dataIndex: 'approvalStatus',
      render: (status) => {
        if (_.isNil(status)) {
          return replaceSign;
        }
        return lookup('approval.approvalStatus', status);
      },
    },
  ],
};

const forceEndReason: DetailLayoutInfoBlock = {
  title: '强制结束原因',
  items: [
    {
      label: '强制结束操作人',
      dataIndex: 'finishExecutor',
      render: renderUser,
    },
    {
      label: '强制结束时间',
      dataIndex: 'finishTime',
      render: formatTimeForRender,
    },
    {
      label: '强制结束原因',
      dataIndex: 'reason',
    },
  ],
};

const SOPTaskDetial = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState({} as SopTaskDetail);
  const { sopTaskId: stringId } = useParams<Params>();
  const [refreshFlag, setRefreshFlag] = useState(0);
  const refresh = useCallback(() => setRefreshFlag(performance.now()), []);

  const { handleDispatch, handleSuspend, handleResume, handleEnd, handleCancel, handleDelete } =
    useTaskOperations({
      refresh,
      afterDelete: () => history.replace('/sop/exec/task'),
    });

  const { status, reason, approvalStatus, bizType } = dataSource;
  const isGeneralCommonTask = bizType === BizType.general;

  const sopTaskId = _.toNumber(stringId);
  const infoArray = [baseInfo];
  const baseMenu: DetailLayoutMenuItem[] = [
    {
      title: '查看任务执行记录',
      key: 'record',
      auth: authDict.soptask_exec_record,
      disabled: !taskCanWithApproval('taskExecLog', status, approvalStatus),
      icon: 'iconcaozuojilu',
      onClick: () => {
        history.push(`/sop/exec/task/${sopTaskId}/detail/execRecord`);
      },
    },
    {
      title: '下发',
      key: 'dispatch',
      auth: authDict.soptask_dispatch,
      disabled: !isGeneralCommonTask || !taskCanWithApproval('dispatch', status, approvalStatus),
      onClick: () => {
        if (
          _.isEmpty(dataSource.canExecUser) &&
          _.isEmpty(dataSource.canExecDept) &&
          _.isEmpty(dataSource.canExecRole)
        ) {
          Modal.confirm({
            title: '提示',
            content: DISPATCH_WARNING,
            onOk: () => {
              handleDispatch(dataSource);
            },
          });
        } else {
          handleDispatch(dataSource);
        }
      },
    },
    {
      title: taskCanWithApproval('resume', status, approvalStatus) ? '继续' : '暂停',
      key: 'suspend',
      auth: authDict.soptask_pause_continue,
      disabled:
        !isGeneralCommonTask ||
        (!taskCanWithApproval('suspend', status, approvalStatus) &&
          !taskCanWithApproval('resume', status, approvalStatus)),
      onClick: () => {
        if (taskCanWithApproval('suspend', status, approvalStatus)) {
          handleSuspend(sopTaskId, status);
        } else {
          handleResume(sopTaskId, status);
        }
      },
    },
    {
      title: '强制结束',
      key: 'forceEnd',
      auth: authDict.soptask_forced_finish,
      disabled: !isGeneralCommonTask || !taskCanWithApproval('forceEnd', status, approvalStatus),
      onClick: () => handleEnd(sopTaskId),
    },
    {
      title: '取消',
      key: 'cancelTask',
      auth: authDict.soptask_cancel,
      disabled: !isGeneralCommonTask || !taskCanWithApproval('cancel', status, approvalStatus),
      onClick: () => handleCancel(sopTaskId),
    },
    {
      title: '删除',
      key: 'deleteTask',
      auth: authDict.soptask_remove,
      disabled: !isGeneralCommonTask || !taskCanWithApproval('delete', status, approvalStatus),
      onClick: () => handleDelete(sopTaskId),
    },
    {
      title: '查看操作记录',
      auth: authDict.soptask_operrecord,
      disabled: !taskCanWithApproval('operationLog', status, approvalStatus),
      key: 'record',
      onClick: () => {
        history.push(`/sop/exec/task/${sopTaskId}/detail/operationLog`);
      },
    },
    {
      title: '编辑',
      key: 'edit',
      auth: authDict.soptask_edit,
      icon: 'iconbianji',
      disabled: !isGeneralCommonTask || !taskCanWithApproval('edit', status, approvalStatus),
      onClick: () => {
        history.push(`/sop/exec/task/${sopTaskId}/edit`);
      },
    },
  ];

  if (status === TaskStatus.canceled) {
    infoArray.push(cancelReason);
  }
  // 审批内容 一直显示
  infoArray.push(approvalInfo);

  // 强制结束的判断标准: 有reason
  if (status === TaskStatus.finished && !!reason) {
    infoArray.push(forceEndReason);
  }

  useEffect(() => {
    setLoading(true);
    fetchSopExecTaskDetail({ sopTaskId: _.toNumber(sopTaskId) })
      .then((res) => {
        setDataSource(res.data as any);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refreshFlag]);

  return (
    <DetailLayout
      title="SOP任务详情"
      info={infoArray}
      dataSource={dataSource}
      baseMenu={baseMenu}
      loading={loading}
      userAuth={getAuthFromLocalStorage()}
    />
  );
};

export default SOPTaskDetial;
