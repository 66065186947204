import React from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import DetailBaseInfo from './components/detailBaseInfo';

interface DetailPageProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const ProcessDetailPage = (props: DetailPageProps) => {
  const { match } = props;
  const processId = Number(match?.params?.id);

  return <DetailBaseInfo processId={processId} type="page" />;
};

export default ProcessDetailPage;
