import { Form, Input, FormInstance, Space, Button } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/es/table';
import _ from 'lodash';
import { TextToolTip } from '@blacklake-web/component';

import {
  BlSortFormList,
  SearchSelect,
  BatchOperateTableHeader as TableHeader,
  setFormDeleteList,
  SearchSelectModal,
} from 'src/components';
import { materialEntity } from 'src/entity';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { lookup } from 'src/dict';
import { CRUD, UsageStatus } from 'src/dict/common';
import { PickOrderType } from 'src/dict/storeRequisition';
import {
  numberAlphabetSpecialSymbols,
  numberChineseAlphabetSpecialSymbols,
  withoutSpaceBothSides,
  positiveInt,
  numberMinMaxCheck,
  fractionLengthCheck,
} from 'src/utils/formValidators';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import authDict, { hasAuth } from 'src/utils/auth';
import BatchSearchSelect from 'src/page/warehouseManagement/batchManagement/components/batchSearchSelect';

import { fieldLabels, StoreRequisitionTableRowDataType } from '../../constants';
import {
  goToMaterialDetail,
  goToProcessDetail,
  goToWorkCenterDetail,
  goToWorkOrderDetail,
} from '../../utils';

interface StoreRequisitionTableProps {
  form: FormInstance;
  mode?: CRUD;
  type?: PickOrderType;
  noDataHandler?: () => void;
}

/** 表单字段名 */
const tableDataFormFieldName = 'materials';
/** 默认单位精度 */
const DEFAULT_PRECISION = 10;

/**
 * 更新表单数据中，领料申请编码列的rowSpan
 * @param 更新前的表单数据
 * @return 更新后的表单数据
 * */
const updateRowSpan: (
  prevList: StoreRequisitionTableRowDataType[],
) => StoreRequisitionTableRowDataType[] = (prevList) => {
  if (prevList.length !== 0) {
    let updateIndex = 0;
    let lastCode = prevList[0].code;
    let rowSpan = 0;

    // 计算rowSpan，当本行领料单号不变，rowSpan+1，否则重置变量，设置rowSpan
    prevList.forEach((line, index) => {
      if (line.code === lastCode) {
        rowSpan += 1;
        line.rowSpan = 0;
      } else {
        prevList[updateIndex].rowSpan = rowSpan;
        updateIndex = index;
        lastCode = line.code;
        rowSpan = 1;
      }
    });

    // 循环结束后设置最后一项rowSpan
    prevList[updateIndex].rowSpan = rowSpan;
  }
  return prevList;
};

export default function StoreRequisitionTable({
  form,
  mode = CRUD.create,
  type = PickOrderType.product,
  noDataHandler,
}: StoreRequisitionTableProps) {
  const { getFieldValue } = form;

  /**
   * 复制 领料单物料行
   * @param fields
   */
  const handleCopyMaterialLine = (field: FormListFieldData) => {
    const copyItem: StoreRequisitionTableRowDataType = _.cloneDeep(
      form.getFieldValue([tableDataFormFieldName, field.name]),
    );
    const formList: StoreRequisitionTableRowDataType[] = _.cloneDeep(
      form.getFieldValue(tableDataFormFieldName),
    );

    copyItem.rowSpan = 0;
    copyItem.pickOrderDetailId = undefined;
    formList.splice(field.name + 1, 0, copyItem);
    form.setFieldsValue({ [tableDataFormFieldName]: updateRowSpan(formList) });
  };

  /** delete方法是BlSortFormList暴露的，在那之后要重新计算更新所有行的rowSpan */
  const updateAfterDelete = () => {
    const formList: StoreRequisitionTableRowDataType[] = _.cloneDeep(
      form.getFieldValue(tableDataFormFieldName),
    );

    if (formList.length === 0) {
      noDataHandler?.();
    }

    form.setFieldsValue({ [tableDataFormFieldName]: updateRowSpan(formList) });
  };

  /**
   * 校验行号,不能重复
   */
  const validatorDuplicatedIndex = (rule: any, value: number) => {
    const { field } = rule;
    const name = field.split('.')[1];
    const { code } = form.getFieldValue([tableDataFormFieldName, name]);

    const formList = form
      .getFieldValue(tableDataFormFieldName)
      .filter((materialLine: StoreRequisitionTableRowDataType) => materialLine.code === code);

    const firstIndex = _.findIndex(formList, { line: value });
    const lastIndex = _.findLastIndex(formList, { line: value });

    if (firstIndex !== lastIndex) {
      return Promise.reject('不能有重复的行号');
    }

    return Promise.resolve('');
  };

  /**
   * 批量更新表格表单数据
   * @param batchInput 批量设置的值
   * @param path 批量设置路径
   *  */
  const handleBatchSet = (batchInput: string | number, path: Array<string> | string) => {
    const formList: StoreRequisitionTableRowDataType[] = _.cloneDeep(
      form.getFieldValue(tableDataFormFieldName),
    );

    formList.forEach((line) => {
      _.set(line, path, batchInput);
    });

    form.setFieldsValue({ [tableDataFormFieldName]: formList });
  };

  const getColumns: (
    remove: (index: number | number[]) => void,
  ) => ColumnProps<any & FormListFieldData>[] = (remove: (index: number | number[]) => void) => {
    return _.compact([
      mode === CRUD.create && {
        title: <TableHeader titleText={fieldLabels.storeRequisitionCode} required />,
        dataIndex: 'code',
        width: 170,
        fixed: true,
        // onCell: ({ name }) => {
        //   return {
        //     rowSpan: getFieldValue([tableDataFormFieldName, name, 'rowSpan']) as number,
        //   };
        // },
        render: (text, field) => ({
          children: (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'code']}
              name={[field.name, 'code']}
              rules={[
                {
                  required: true,
                  message: `${fieldLabels.storeRequisitionCode}必填`,
                },
                numberAlphabetSpecialSymbols,
                withoutSpaceBothSides,
                { max: 255, message: '不可超过255个字符' },
              ]}
            >
              <Input placeholder="请输入" />
            </Form.Item>
          ),
          props: {
            rowSpan: getFieldValue([tableDataFormFieldName, field.name, 'rowSpan']) as number,
          },
        }),
      },
      {
        title:
          mode === CRUD.view ? (
            fieldLabels.lineNumber
          ) : (
            <TableHeader titleText={fieldLabels.lineNumber} required />
          ),
        dataIndex: ['line'],
        width: 100,
        render: (text, field) => {
          return mode === CRUD.view ? (
            getFieldValue([tableDataFormFieldName, field.name, 'line'])
          ) : (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'line']}
              name={[field.name, 'line']}
              getValueFromEvent={(event) => {
                const curValue = event.target.value;

                if (isNaN(curValue) || _.endsWith(curValue, '.')) return curValue;
                if (curValue === '') return undefined;
                return Number(curValue);
              }}
              rules={[
                {
                  required: true,
                  message: `${fieldLabels.lineNumber}必填`,
                },
                positiveInt,
                {
                  validator: validatorDuplicatedIndex,
                },
              ]}
            >
              <Input
                placeholder="请输入"
                onChange={() => {
                  const allRows = form.getFieldValue(tableDataFormFieldName);

                  form.validateFields(
                    allRows.map((_row: StoreRequisitionTableRowDataType, idx: number) => [
                      tableDataFormFieldName,
                      idx,
                      'line',
                    ]),
                  );
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: fieldLabels.materialCode,
        dataIndex: ['material', 'baseInfo', 'code'],
        width: 130,
        render: (text, field) => {
          return hasAuth(authDict.material_detail) ? (
            <a
              onClick={() =>
                goToMaterialDetail(
                  getFieldValue([tableDataFormFieldName, field.name, 'material', 'baseInfo', 'id']),
                )
              }
            >
              <TextToolTip
                text={getFieldValue([
                  tableDataFormFieldName,
                  field.name,
                  'material',
                  'baseInfo',
                  'code',
                ])}
                width={130}
              />
            </a>
          ) : (
            getFieldValue([tableDataFormFieldName, field.name, 'material', 'baseInfo', 'code'])
          );
        },
      },
      {
        title: fieldLabels.materialName,
        dataIndex: ['material', 'baseInfo', 'name'],
        width: 160,
        render: (text, field) =>
          getFieldValue([tableDataFormFieldName, field.name, 'material', 'baseInfo', 'name']),
      },
      {
        title: fieldLabels.materialCategory,
        dataIndex: ['material', 'category', 'name'],
        width: 110,
        render: (text, field) =>
          getFieldValue([tableDataFormFieldName, field.name, 'material', 'category', 'name']),
      },
      {
        title: fieldLabels.specification,
        dataIndex: ['material', 'baseInfo', 'specification'],
        width: 160,
        render: (text, field) =>
          getFieldValue([
            tableDataFormFieldName,
            field.name,
            'material',
            'baseInfo',
            'specification',
          ]),
      },
      {
        title: fieldLabels.materialProperty,
        dataIndex: ['material', 'attribute'],
        width: 150,
        render: (text, field) => {
          const attributes = materialEntity.getMaterialAttrs(
            getFieldValue([tableDataFormFieldName, field.name, 'material']),
          );

          return attributes.map((attr) => attr.label).join(', ');
        },
      },
      {
        title: fieldLabels.unit,
        dataIndex: ['requestPickAmount', 'unitName'],
        width: 100,
        render: (text, field) =>
          getFieldValue([tableDataFormFieldName, field.name, 'requestPickAmount', 'unitName']),
      },
      {
        title:
          mode === CRUD.view ? (
            fieldLabels.requireCount
          ) : (
            <TableHeader
              titleText={fieldLabels.requireCount}
              required
              batchOperation={(batchInput) => {
                handleBatchSet(batchInput, ['requestPickAmount', 'amount']);
              }}
              rules={[
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    max: 10000000,
                    minAllowEqual: false,
                    maxAllowEqual: false,
                    fieldName: fieldLabels.requireCount,
                  }),
                },
                {
                  // 批量设置的精度只能按默认精度来算
                  validator: fractionLengthCheck(DEFAULT_PRECISION),
                },
              ]}
            />
          ),
        dataIndex: ['requestPickAmount', 'amount'],
        width: 120,
        render: (text, field) => {
          const requestPickAmount = getFieldValue([
            tableDataFormFieldName,
            field.name,
            'requestPickAmount',
          ]);
          const material = getFieldValue([tableDataFormFieldName, field.name, 'material']);
          const { precisionFigure } = materialEntity.getMaterialUnitInfo(
            material,
            requestPickAmount.unitId,
          );

          return mode === CRUD.view ? (
            requestPickAmount.amount
          ) : (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'requestPickAmount', 'amount']}
              name={[field.name, 'requestPickAmount', 'amount']}
              rules={[
                { required: true, message: `${fieldLabels.requireCount}必填` },
                {
                  // 申请数必须小于10000000，大于0
                  validator: numberMinMaxCheck({
                    min: 0,
                    max: 10000000,
                    minAllowEqual: mode === CRUD.edit,
                    maxAllowEqual: false,
                    fieldName: fieldLabels.requireCount,
                  }),
                },
                {
                  // 精度校验从单位中获取
                  validator: fractionLengthCheck(precisionFigure ?? DEFAULT_PRECISION),
                },
              ]}
            >
              <Input placeholder="请输入" />
            </Form.Item>
          );
        },
      },
      {
        title:
          mode === CRUD.view ? (
            fieldLabels.dispatchWarehouse
          ) : (
            <TableHeader
              titleText={fieldLabels.dispatchWarehouse}
              batchOperation={(batchInput) => {
                handleBatchSet(batchInput, ['sourceWarehouseId']);
              }}
              popoverFieldComponent={
                <SearchSelectModal
                  fetchType="warehouse"
                  params={{ enableFlag: UsageStatus.enabled }}
                  placeholder={`请选择${fieldLabels.dispatchWarehouse}`}
                />
              }
            />
          ),
        dataIndex: ['sourceWarehouseId'],
        width: 300,
        render: (text, field) => {
          return mode === CRUD.view ? (
            getFieldValue([tableDataFormFieldName, field.name, 'sourceWarehouseId', 'label'])
          ) : (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'sourceWarehouseId']}
              name={[field.name, 'sourceWarehouseId']}
            >
              <SearchSelectModal
                fetchType="warehouse"
                params={{ enableFlag: UsageStatus.enabled }}
                placeholder={`请选择${fieldLabels.dispatchWarehouse}`}
              />
            </Form.Item>
          );
        },
      },
      {
        title:
          mode === CRUD.view ? (
            fieldLabels.acceptWarehouse
          ) : (
            <TableHeader
              titleText={fieldLabels.acceptWarehouse}
              required
              batchOperation={(batchInput) => {
                handleBatchSet(batchInput, ['targetWarehouseId']);
              }}
              popoverFieldComponent={
                <SearchSelectModal
                  fetchType="warehouse"
                  params={{ enableFlag: UsageStatus.enabled }}
                  placeholder={`请选择${fieldLabels.acceptWarehouse}`}
                />
              }
            />
          ),
        dataIndex: ['targetWarehouseId'],
        width: 300,
        render: (text, field) => {
          return mode === CRUD.view ? (
            getFieldValue([tableDataFormFieldName, field.name, 'targetWarehouseId', 'label'])
          ) : (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'targetWarehouseId']}
              name={[field.name, 'targetWarehouseId']}
              rules={[{ required: true, message: `${fieldLabels.acceptWarehouse}必填` }]}
            >
              <SearchSelectModal
                fetchType="warehouse"
                params={{ enableFlag: UsageStatus.enabled }}
                placeholder={`请选择${fieldLabels.acceptWarehouse}`}
              />
            </Form.Item>
          );
        },
      },
      type === PickOrderType.product && {
        title: fieldLabels.requireTime,
        dataIndex: ['requirementTime'],
        width: 180,
        render: (text, field) =>
          formatTimeForRender(
            getFieldValue([tableDataFormFieldName, field.name, 'requirementTime']),
          ),
        // return (
        //   <Form.Item
        //     style={{ margin: 0 }}
        //     fieldKey={[field.fieldKey, 'requirementTime']}
        //     name={[field.name, 'requirementTime']}
        //     getValueProps={(value: number) => ({ value: moment(value) })}
        //     getValueFromEvent={(value: Moment) => value.valueOf()}
        //   >
        //     <DatePicker showTime />
        //   </Form.Item>
        // );
      },
      type === PickOrderType.product && {
        title: fieldLabels.supplier,
        dataIndex: ['supplierList'],
        width: 120,
        render: (text, field) =>
          getFieldValue([tableDataFormFieldName, field.name, 'supplierList'])
            ?.map((supplier: { code: string; id: number; name: string }) => supplier.name)
            .join(','),
      },
      {
        title: fieldLabels.batchNumber,
        dataIndex: ['batchNo'],
        width: 130,
        render: (text, field) => {
          const materialinfo = getFieldValue([tableDataFormFieldName, field.name, 'material']);

          return mode === CRUD.view ? (
            getFieldValue([tableDataFormFieldName, field.name, 'batchNo', 'label'])
          ) : (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'batchNo']}
              name={[field.name, 'batchNo']}
              required
            >
              <BatchSearchSelect
                style={{ width: '100' }}
                materialinfo={materialinfo}
                labelInValue
                rendervalue="all"
                saveCallback={(val) => {
                  form.setFields([
                    {
                      name: [tableDataFormFieldName, field.name, 'batchNo'],
                      value: val,
                    },
                  ]);
                }}
                params={{
                  materialIds: [
                    getFieldValue([
                      tableDataFormFieldName,
                      field.name,
                      'material',
                      'baseInfo',
                      'id',
                    ]),
                  ],
                }}
              />
            </Form.Item>
          );
        },
      },
      type === PickOrderType.product && {
        title: fieldLabels.seq,
        dataIndex: ['seq'],
        width: 160,
        render: (text, field) => getFieldValue([tableDataFormFieldName, field.name, 'seq']),
      },
      type === PickOrderType.product && {
        title: fieldLabels.alternativeMaterial,
        dataIndex: ['alternativeMaterialFlag'],
        width: 160,
        render: (text, field) =>
          lookup(
            'common.yn',
            getFieldValue([tableDataFormFieldName, field.name, 'alternativeMaterialFlag']),
          ),
      },
      type === PickOrderType.product && {
        title: fieldLabels.processNumber,
        dataIndex: ['inputProcessCode'],
        width: 160,
        render: (text, field) =>
          getFieldValue([tableDataFormFieldName, field.name, 'inputProcessCode']),
      },
      type === PickOrderType.product && {
        title: fieldLabels.processName,
        dataIndex: ['inputProcessName'],
        width: 160,
        render: (text, field) => {
          const processName = getFieldValue([
            tableDataFormFieldName,
            field.name,
            'inputProcessName',
          ]);

          if (!_.isNil(processName)) {
            return hasAuth(authDict.process_detail) ? (
              <a
                onClick={() => {
                  goToProcessDetail(
                    getFieldValue([tableDataFormFieldName, field.name, 'inputProcessId']),
                  );
                }}
              >
                <TextToolTip text={processName} width={160} />
              </a>
            ) : (
              processName
            );
          }
          return undefined;
        },
      },
      type === PickOrderType.product && {
        title: fieldLabels.workCenter,
        dataIndex: ['workCenter'],
        width: 160,
        render: (text, field) => {
          const workCenterName = getFieldValue([
            tableDataFormFieldName,
            field.name,
            'workCenter',
            'name',
          ]);

          if (!_.isNil(workCenterName)) {
            return hasAuth(authDict.workcenter_detail) ? (
              <a
                onClick={() => {
                  goToWorkCenterDetail(
                    getFieldValue([tableDataFormFieldName, field.name, 'workCenter', 'sampleId']),
                  );
                }}
              >
                <TextToolTip text={workCenterName} width={160} />
              </a>
            ) : (
              workCenterName
            );
          }
          return undefined;
        },
      },
      type === PickOrderType.product && {
        title: fieldLabels.productionOrderCode,
        dataIndex: ['workOrderCode'],
        width: 160,
        render: (text, field) => {
          const workOrderCode = getFieldValue([
            tableDataFormFieldName,
            field.name,
            'workOrderCode',
          ]);

          if (!_.isNil(workOrderCode)) {
            return hasAuth(authDict.productionorder_detail) ? (
              <a
                onClick={() => {
                  goToWorkOrderDetail(
                    getFieldValue([tableDataFormFieldName, field.name, 'workOrderId']),
                  );
                }}
              >
                <TextToolTip text={workOrderCode} width={160} />
              </a>
            ) : (
              workOrderCode
            );
          }
          return undefined;
        },
      },
      type === PickOrderType.product && {
        title: fieldLabels.productionDepartment,
        dataIndex: ['productionDepartment'],
        width: 140,
        render: (text, field) => {
          const productionDepartment = getFieldValue([
            tableDataFormFieldName,
            field.name,
            'productionDepartment',
          ]);

          return productionDepartment ? (
            <AvatarDisplay
              isUser={false}
              isShowTag={false}
              id={productionDepartment?.id}
              name={productionDepartment?.name}
              maxWidth={120}
            />
          ) : undefined;
        },
      },
      type === PickOrderType.product && {
        title: fieldLabels.mainOutputBatchNumber,
        dataIndex: 'mainOutputBatchNumber',
        width: 160,
        render: (text, field) =>
          getFieldValue([tableDataFormFieldName, field.name, 'mainOutputBatchNumber']),
      },
      {
        title:
          mode === CRUD.view ? (
            fieldLabels.lineRemark
          ) : (
            <TableHeader
              titleText={fieldLabels.lineRemark}
              rules={[
                { max: 1000, message: '不可超过1000个字符' },
                numberChineseAlphabetSpecialSymbols,
                withoutSpaceBothSides,
              ]}
              batchOperation={(batchInput) => {
                handleBatchSet(batchInput, ['remark']);
              }}
            />
          ),
        dataIndex: ['remark'],
        width: 180,
        render: (text, field) => {
          return mode === CRUD.view ? (
            getFieldValue([tableDataFormFieldName, field.name, 'remark'])
          ) : (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'remark']}
              name={[field.name, 'remark']}
              rules={[{ max: 1000, message: '不可超过1000个字符' }, withoutSpaceBothSides]}
            >
              <Input placeholder="请输入" maxLength={1000} />
            </Form.Item>
          );
        },
      },
      mode !== CRUD.view && {
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width: 100,
        render: (text, field) => {
          return (
            <Form.Item style={{ margin: 0 }}>
              <Space>
                {(mode === CRUD.create ||
                  _.isEmpty(
                    getFieldValue([tableDataFormFieldName, field.name, 'relatedTransferOrder']),
                  )) && (
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    onClick={() => {
                      remove(field?.name);
                      updateAfterDelete();
                    }}
                  >
                    {lookup('crud', CRUD.delete)}
                  </Button>
                )}
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={() => {
                    handleCopyMaterialLine(field);
                  }}
                >
                  {lookup('crud', CRUD.copy)}
                </Button>
              </Space>
            </Form.Item>
          );
        },
      },
    ]);
  };

  return (
    <BlSortFormList
      name={tableDataFormFieldName}
      renderColumns={getColumns}
      form={mode === CRUD.view ? form : undefined}
      onDelete={(index) => {
        setFormDeleteList({
          form,
          setValueName: ['deleteList', 'deleteLineIds'],
          getValueName: [tableDataFormFieldName, index[0]],
          checkAndFormat: (item) => item?.pickOrderDetailId,
        });
      }}
      isNeedDrag={false}
      isNeedAdd={false}
      isNeedDelete={false}
    />
  );
}
