import { useEffect, useState } from 'react';
import _ from 'lodash';
import { fetchFileListByIds } from 'src/api/ytt/holyfile-domain';
import UploadFileList from './components/attachmentList';
import authDict, { hasAuth } from 'src/utils/auth';
import {
  fetchAttachmentsList,
  FetchAttachmentsListRequest,
} from 'src/api/ytt/supply-chain-domain/attachments';

interface Props {
  fileIds?: number[];
  defaultFileList?: any[];
  type?: 'collaborative' | 'default' | 'detail'; // 跨租户的协同附件,需要特殊的获取文件接口, 预览时也需要特别的接口
  collaborativeParams?: {
    /**
     * 客户ID
     */
    customerId?: number | undefined;
    supplierId?: number | undefined;
  };
}

const fileFormatter = (file: any) => {
  return {
    uid: file?.id,
    name: decodeURIComponent(file?.filename ?? ''),
    url: file?.fileUri,
    status: 'done',
    ...file,
  };
};

const FilesDetail = (props: Props) => {
  const { fileIds, type = 'default', collaborativeParams, defaultFileList = [], ...rest } = props;
  const [fileList, setFileList] = useState<any[]>(
    defaultFileList?.map((file) => fileFormatter(file)),
  );
  const getFilesByIds = async (idList: number[]) => {
    switch (type) {
      case 'collaborative':
        if (!_.isEmpty(idList) && collaborativeParams) {
          const { customerId, supplierId } = collaborativeParams;
          const params: FetchAttachmentsListRequest = {
            coordinationAttachments: idList,
          };
          if (customerId) {
            params.customerId = customerId;
          }
          if (supplierId) {
            params.supplierId = supplierId;
          }
          const { data: collaborativeData } = await fetchAttachmentsList(params);
          setFileList(collaborativeData?.map((item: any) => fileFormatter(item)) || []);
        }
        break;
      default:
        const { data } = await fetchFileListByIds({ idList });

        setFileList(data?.map((item: any) => fileFormatter(item)) || []);
        break;
    }
  };

  useEffect(() => {
    if (fileIds) {
      getFilesByIds(fileIds);
    }
  }, [fileIds]);
  return (
    <UploadFileList
      files={fileList}
      preview
      showDownloadIcon={hasAuth(authDict.document_down_file)}
      customerId={collaborativeParams?.customerId || collaborativeParams?.supplierId} // 跨租户的协同附件预览时，需要的参数
      {...rest}
    />
  );
};

export default FilesDetail;
