import React, { useRef, useState } from 'react';
import { FilterItem, RecordListLayout } from 'layout';
import _ from 'lodash';
import { fetchQcMaterialChangeQcStatusLogDetail } from 'src/api/ytt/quality-domain/adjustRecord';
import { fieldTypeList, gcTime, gcUrl } from 'src/utils';
import lookup, { appDict } from 'src/dict';

const RecordLine = () => {
  const [selectedRowKeys, setSelectRowKeys] = useState<(string | number)[]>([]);
  const [storageNames, setStorageNames] = useState<string[]>([]);

  const columns = _.compact([
    {
      title: '标识码',
      dataIndex: 'code',
      isFilter: true,
      filterType: fieldTypeList.text,
      width: 150,
    },
    {
      title: '原质量状态',
      dataIndex: 'qcStatusBefore',
      isFilter: true,
      filterType: fieldTypeList.select,
      selectProps: {
        options: appDict.quality.inspectionResultOptions,
      },
      width: 150,
      render: lookup('quality.qcStatusEnum'),
    },
    {
      title: '存储位置',
      dataIndex: 'storageName',
      filterType: fieldTypeList.select,
      isFilter: true,
      width: 150,
      selectProps: {
        options: storageNames.map((storage) => {
          return {
            label: storage,
            value: storage,
          };
        }),
      },
    },
    {
      title: '调整数量',
      dataIndex: 'count',
      width: 150,
    },
    {
      title: '主单位',
      dataIndex: 'unitName',
      width: 150,
    },
    {
      title: '辅助数量',
      dataIndex: ['bizKey', 'opAmount', 'auxAmounts'],
      width: 120,
      render: (amounts: { amount: number }[]) => amounts?.[0] && amounts[0]?.amount,
    },
    {
      title: '辅助单位',
      dataIndex: ['bizKey', 'opAmount'],
      width: 120,
      render: (opAmount: { auxAmounts: { unit: { name: string } }[] }) =>
        opAmount?.auxAmounts[0] && opAmount?.auxAmounts[0]?.unit?.name,
    },
    {
      title: '批次号',
      dataIndex: 'batchNumber',
      filterType: fieldTypeList.text,
      isFilter: true,
      width: 150,
    },
    {
      title: '有效期',
      dataIndex: 'validityPeriod',
      filterType: fieldTypeList.date,
      isFilter: true,
      sorter: true,
      width: 150,
      render: (date: string) => date && gcTime.formatLine(date),
    },
  ]);

  const fetchData = async (params: any) => {
    const { id } = gcUrl.getParams();

    params.id = id;
    const res = await fetchQcMaterialChangeQcStatusLogDetail(params);

    setStorageNames(res?.data?.storageNames || []);
    return { data: res?.data?.materialList };
  };

  return (
    <RecordListLayout
      columns={columns}
      mainMenu={[]}
      batchMenu={[]}
      filterList={[]}
      requestFn={fetchData}
      useQuickFilter={false}
      selectedRowKeys={selectedRowKeys}
      style={{ paddingTop: 24 }}
      placeholder={'输入标识码、存储位置、批号搜索'}
      onSelectedRowKeys={(selectKey) => {
        setSelectRowKeys(selectKey);
      }}
    />
  );
};

export default RecordLine;
