import React from 'react';
import {
  BcAttachmentDetail,
  BcMultilineText,
  BlTable,
  SearchSelect,
  TagList,
  Tooltip,
} from 'src/components';
import { replaceSign } from 'src/utils/constants';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import _Time from 'src/utils/dataTypes/time';
import { fieldTypeList } from 'src/utils';
import { FilterItem } from 'src/layout';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import {
  Creator,
  Customer,
  ItemsWorkListType,
  PurchaseAuditDetailItemPrice,
  PurchaseAuditDetailItemPurchaseOrderCurrency,
  SalesOrderDetailsItems,
  salesOrderReturnItem,
  shipmentNoteListItem,
  WorkOrderListTypeHeader,
  WorkOrderListTypeItems,
} from '../interface';
import _Array from 'src/utils/dataTypes/array';
import _ from 'lodash';
import { Popover } from 'antd';
import { Auth } from 'src/dict/sales';
import lookup, { appDict } from 'src/dict';
import {
  originType as originTypeMapping,
  shipmentStatus as shipmentStatusMapping,
  purchaseRequisitionStatus as purchaseRequisitionStatusMapping,
} from 'src/dict/sales/mappings';
import { formatTimeForRender } from 'src/utils/formatters';
import { mappingMaterialInfoAttrList } from 'src/page/warehouseManagement/utils';
import { Amount } from 'src/page/share';
import { CreatorData } from 'src/page/knowledgeManagement/calendarManagement/shift/interface';
import { renderTotalPrice } from 'src/page/purchase/purchaseManagement/purchaseOrder/utils';
import {
  purchaseOrderdeliveryMode,
  purchaseOrderMaterialCarryMode,
} from 'src/dict/purchase/mappings';

const overflowStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  verticalAlign: 'middle',
};

const workOrderListContent = (data: any[], type: string) => {
  return (
    <>
      <h4>关联工单</h4>
      <BlTable
        columns={[
          {
            title: '工单编号',
            dataIndex: type === 'header' ? 'code' : 'workOrderCode',
            width: 200,
            render: (code: string) => code ?? replaceSign,
          },
          {
            title: '工单状态',
            dataIndex: 'workOrderStatus',
            width: 200,
            render: (workOrderStatus: number) =>
              lookup('sales.associatedWorkOrder', workOrderStatus) ?? replaceSign,
          },
        ]}
        dataSource={data}
        rowKey={(record) => (type === 'header' ? record?.code : record?.workOrderCode)}
        id="workOrderListTable"
      />
    </>
  );
};
const ListContent = (data: any[], columns: any[], title: string, id: string) => {
  return (
    <>
      <h4>{title}</h4>
      <BlTable columns={columns} dataSource={data} rowKey={'id'} id={`${id}Table`} />
    </>
  );
};
/**
 * 创建人，创建时间，更新人，更新时间
 */
const renderDate = (type: 'salesDocuments' | 'salesMaterial') => {
  let creator = ['header', 'creator'];
  let createdAt = ['header', 'createdAt'];
  let operator = ['header', 'operator'];
  let updatedAt = ['header', 'updatedAt'];

  switch (type) {
    case 'salesMaterial':
      creator = ['creator'];
      createdAt = ['createdAt'];
      operator = ['operator'];
      updatedAt = ['updatedAt'];
      break;

    default:
      break;
  }
  return [
    {
      title: '创建人',
      dataIndex: creator,
      width: 150,
      render: (creator: Creator) => {
        return (
          <AvatarDisplay
            id={creator?.id}
            name={creator?.name}
            avatarUrl={creator?.avatarUrl}
            key={creator?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: createdAt,
      width: 200,
      sorter: true,
      render: (createdAt: Date) => {
        if (!createdAt) return replaceSign;
        return _Time.format(createdAt);
      },
    },
    {
      title: '更新人',
      dataIndex: operator,
      width: 150,
      render: (operator: Creator) => {
        return (
          <AvatarDisplay
            id={operator?.id}
            name={operator?.name}
            avatarUrl={operator?.avatarUrl}
            key={operator?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: updatedAt,
      width: 200,
      sorter: true,
      render: (updatedAt: Date) => {
        if (!updatedAt) return replaceSign;
        return _Time.format(updatedAt);
      },
    },
  ];
};
/**
 * 物料特殊展示【 code:name x 数量 单位 】
 */
const renderTitle = (items: SalesOrderDetailsItems[]) => {
  return _.map(items, (node: SalesOrderDetailsItems) => {
    const { material, amount, unit, id } = node;

    return (
      <div key={id}>
        {material
          ? `${material?.baseInfo?.code}：${material?.baseInfo?.name} x ${amount?.amount}${unit?.name}`
          : '-'}
      </div>
    );
  });
};
/**
 * 物料展示列
 */
const renderMaterial = (title?: string) => {
  title = title || '';

  return [
    {
      title: `${title}物料编号`,
      dataIndex: ['material', 'baseInfo', 'code'],
      width: 200,
      render: (code: string) => code ?? replaceSign,
    },
    {
      title: `${title}物料名称`,
      dataIndex: ['material', 'baseInfo', 'name'],
      width: 200,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: `${title}物料属性`,
      dataIndex: ['material', 'attribute'],
      width: 200,
      render: (
        text: { name: string; attributeItem: { content: string; id: number }; id: number }[],
      ) => {
        if (_.isEmpty(text)) return replaceSign;

        return (
          <TagList
            dataSource={_.map(text, ({ name, attributeItem, number }) => ({
              label: `${name}:${attributeItem.content}`,
              value: number,
            }))}
          />
        );
      },
    },
    {
      title: `${title}物料规格`,
      dataIndex: ['material', 'baseInfo', 'specification'],
      width: 200,
      render: (name: string) => name ?? replaceSign,
    },
  ];
};
/**
 * 币种
 */
const renderCurrency = (type: 'salesDetail' | 'purchaseRequisitionDetail') => {
  let currencyDataIndex = ['purchaseOrder', 'currency'];
  let requirementDataIndex = ['purchaseOrder', 'requirement'];

  switch (type) {
    case 'salesDetail':
      currencyDataIndex = ['header', 'currency'];
      requirementDataIndex = ['header', 'scmPurchaseRequestRequirement'];
      break;

    default:
      break;
  }
  return [
    {
      label: '币种',
      dataIndex: currencyDataIndex,
      render: (text: PurchaseAuditDetailItemPurchaseOrderCurrency) => {
        return _.isNil(text) ? replaceSign : text?.name;
      },
    },
    {
      label: '订单要求',
      dataIndex: requirementDataIndex,
      render: (text: string) => <BcMultilineText text={text} needWordCount />,
    },
  ];
};
/**
 * 价格
 */
const renderPrice = () => {
  return [
    {
      title: '单价',
      width: 150,
      dataIndex: 'unitPrice',
      render: (text: PurchaseAuditDetailItemPrice) => {
        return _.isNil(text) ? replaceSign : `${text?.priceDisplay}`;
      },
    },
    {
      title: '税率',
      width: 150,
      dataIndex: 'taxRate',
      render: (text: string) => (text ? `${text} %` : replaceSign),
    },
    {
      title: '金额',
      width: 150,
      dataIndex: 'totalPrice',
      render: (text: PurchaseAuditDetailItemPrice) => {
        return _.isNil(text)
          ? replaceSign
          : `${text?.currencySymbol}${renderTotalPrice(text?.priceDisplay, text?.amountAccuracy)}`;
      },
    },
  ];
};
/**
 * 交货方式
 */
const renderDeliveryMethod = (type: 'salesDetail' | 'purchaseRequisitionDetail') => {
  let deliveryMethodIndex = ['deliveryMode'];

  switch (type) {
    case 'salesDetail':
      deliveryMethodIndex = ['header', 'deliveryMode'];
      break;

    default:
      break;
  }
  return [
    {
      label: '交货方式',
      dataIndex: deliveryMethodIndex,
      render: (text: string) => {
        return _.isNil(text) ? replaceSign : lookup('purchase.purchaseOrderdeliveryMode', text);
      },
    },
  ];
};

// 订单单据展示列
export const dataColumnsForDocuments = _.compact([
  Auth.supplyChain && {
    title: '订单来源',
    dataIndex: ['header', 'origin'],
    width: 150,
    sorter: true,
    render: (text: number) => lookup('sales.originType', text) ?? replaceSign,
  },
  Auth.supplyChain && {
    title: '协同采购单号',
    dataIndex: ['header', 'scmPurchaseOrderCode'],
    width: 150,
    sorter: true,
    render: (text: number) => text ?? replaceSign,
  },
  {
    title: '交货方式',
    dataIndex: ['header', 'deliveryMode'],
    width: 150,
    render: (text: number) => {
      return _.isNil(text) ? replaceSign : lookup('purchase.purchaseOrderdeliveryMode', text);
    },
  },
  {
    title: '客户名称',
    dataIndex: ['header', 'customer'],
    width: 150,
    sorter: true,
    render: (customer: Customer) => customer?.name ?? replaceSign,
  },
  {
    title: '订单所有人',
    dataIndex: ['header', 'owners'],
    width: 150,
    sorter: true,
    render: (owners: Creator[]) => {
      return _.map(_.filter(owners, 'id'), (owner: Creator) => (
        <AvatarDisplay
          id={owner.id}
          name={owner.name}
          avatarUrl={owner.avatarUrl}
          key={owner.id}
          isShowTag
          isUser
        />
      ));
    },
  },
  {
    title: '物料',
    dataIndex: 'items',
    width: 200,
    render: (items: SalesOrderDetailsItems[]) => {
      const materials = _.filter(_.map(items, 'material'));

      if (_Array.isEmpty(materials)) return replaceSign;

      return materials?.length > 1 ? (
        <Tooltip.AntTooltip title={renderTitle(items)}>
          <span style={{ maxWidth: 180, whiteSpace: 'pre', ...overflowStyle }}>
            {` ${items?.[0]?.material?.baseInfo?.code}：${items?.[0]?.material?.baseInfo?.name} x ${items?.[0]?.amount?.amount}${items?.[0]?.unit?.name}`}
          </span>
        </Tooltip.AntTooltip>
      ) : (
        <Tooltip
          text={`${items?.[0]?.material?.baseInfo?.code}：${items?.[0]?.material?.baseInfo?.name} x ${items?.[0]?.amount?.amount}${items?.[0]?.unit?.name}`}
          width={200}
        />
      );
    },
  },
  {
    title: '关联工单',
    dataIndex: ['header', 'workOrderList'],
    width: 200,
    render: (workOrderList: WorkOrderListTypeHeader[]) => {
      if (_Array.isEmpty(workOrderList)) return replaceSign;

      return (
        <Popover
          placement="bottom"
          content={workOrderListContent(workOrderList, 'header')}
          trigger="hover"
        >
          <div>
            {workOrderList[0]?.code ?? replaceSign}
            <span style={{ color: '#02B980' }}>{`(${workOrderList?.length})`}</span>
          </div>
        </Popover>
      );
    },
  },
  {
    title: '订单状态',
    dataIndex: ['header', 'status'],
    width: 150,
    sorter: true,
    render: (status: number) => lookup('sales.orderStatus', status) ?? replaceSign,
  },
  Auth.supplyChain && {
    title: '发货状态',
    dataIndex: ['header', 'shipmentStatus'],
    width: 150,
    sorter: true,
    render: (text: number) => lookup('sales.shipmentStatus', text) ?? replaceSign,
  },
  {
    title: '生产状态',
    dataIndex: ['header', 'produceStatus'],
    width: 150,
    render: (produceStatus: number) =>
      lookup('sales.productionStatus', produceStatus) ?? replaceSign,
  },
  ...renderDate('salesDocuments'),
]);
// 订单明细展示列
export const dataColumnsForDetail = _.compact([
  Auth.supplyChain && {
    title: '订单来源',
    dataIndex: 'origin',
    width: 150,
    sorter: true,
    render: (text: number) => lookup('sales.originType', text) ?? replaceSign,
  },
  Auth.supplyChain && {
    title: '协同采购单号',
    dataIndex: 'scmPurchaseOrderCode',
    width: 150,
    sorter: true,
    render: (text: number) => text ?? replaceSign,
  },
  {
    title: '行号',
    dataIndex: 'lineNo',
    width: 100,
    render: (lineNo: number) => lineNo ?? replaceSign,
  },
  {
    title: '订单所有人',
    dataIndex: 'owners',
    width: 200,
    sorter: true,
    render: (owners: Creator[]) => {
      return _.map(_.filter(owners, 'id'), (owner: Creator) => (
        <AvatarDisplay
          id={owner.id}
          name={owner.name}
          avatarUrl={owner.avatarUrl}
          key={owner.id}
          isShowTag={false}
          isUser
        />
      ));
    },
  },
  {
    title: '客户名称',
    dataIndex: ['customer', 'name'],
    sorter: true,
    width: 200,
    render: (name: string) => name ?? replaceSign,
  },
  {
    title: '关联工单',
    dataIndex: 'workOrderList',
    width: 200,
    render: (workOrderList: WorkOrderListTypeItems[]) => {
      if (_Array.isEmpty(workOrderList)) return replaceSign;

      return (
        <Popover
          placement="bottom"
          content={workOrderListContent(workOrderList, 'items')}
          trigger="hover"
        >
          <div>
            {workOrderList[0]?.workOrderCode ?? replaceSign}
            <span style={{ color: '#02B980' }}>{`(${workOrderList?.length})`}</span>
          </div>
        </Popover>
      );
    },
  },
  {
    title: '交货日期',
    dataIndex: 'deliveryDate',
    width: 200,
    sorter: true,
    render: (deliveryDate: Date) => {
      if (!deliveryDate) return replaceSign;
      return _Time.format(deliveryDate);
    },
  },
  {
    title: '生产状态',
    dataIndex: 'produceStatus',
    width: 150,
    render: (produceStatus: number) =>
      lookup('sales.productionStatus', produceStatus) ?? replaceSign,
  },
  {
    title: '计划生产数',
    dataIndex: ['plannedAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '生产完工数',
    dataIndex: ['producedAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '合格完工数',
    dataIndex: ['qualifiedAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '完工入库数',
    dataIndex: ['inventoryAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  Auth.supplyChain && {
    title: '发货状态',
    dataIndex: 'shipmentStatus',
    width: 150,
    sorter: true,
    render: (text: number) => lookup('sales.shipmentStatus', text) ?? replaceSign,
  },
  Auth.supplyChain && {
    title: '计划发货数',
    dataIndex: ['plannedIssueAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  Auth.supplyChain && {
    title: '仓库应发数',
    dataIndex: ['warehousePlannedIssueAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  Auth.supplyChain && {
    title: '仓库实发数',
    dataIndex: ['warehouseIssueAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  Auth.supplyChain && {
    title: '客户收货数',
    dataIndex: ['customerReceivedAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  Auth.supplyChain && {
    title: '发货单号',
    dataIndex: 'shipmentNoteList',
    width: 150,
    render: (shipmentNoteList: shipmentNoteListItem[]) => {
      if (_Array.isEmpty(shipmentNoteList)) return replaceSign;
      const columns = [
        {
          title: '编号',
          dataIndex: 'code',
          width: 200,
          render: (code: string) => code ?? replaceSign,
        },
        {
          title: '状态',
          dataIndex: 'status',
          width: 200,
          render: (text: number) => lookup('sales.invoiceStatus', text) ?? replaceSign,
        },
      ];

      return (
        <Popover
          placement="bottom"
          content={ListContent(shipmentNoteList, columns, '关联发货单', 'shipmentNoteList')}
          trigger="hover"
        >
          <div>
            {shipmentNoteList[0]?.code ?? replaceSign}
            <span style={{ color: '#02B980' }}>{`(${shipmentNoteList?.length})`}</span>
          </div>
        </Popover>
      );
    },
  },
  Auth.supplyChain && {
    title: '计划退货数',
    dataIndex: ['plannedReturnAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  Auth.supplyChain && {
    title: '客户已发数',
    dataIndex: ['customerReturnedAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  Auth.supplyChain && {
    title: '仓库已收数',
    dataIndex: ['warehouseReceivedAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  Auth.supplyChain && {
    title: '退货单号',
    dataIndex: 'salesOrderReturnList',
    width: 150,
    render: (salesOrderReturnList: salesOrderReturnItem[]) => {
      if (_Array.isEmpty(salesOrderReturnList)) return replaceSign;
      const columns = [
        {
          title: '编号',
          dataIndex: 'code',
          width: 200,
          render: (code: string) => code ?? replaceSign,
        },
        {
          title: '状态',
          dataIndex: 'returnStatus',
          width: 200,
          render: (text: number) => lookup('sales.shipmentStatus', text) ?? replaceSign,
        },
      ];

      return (
        <Popover
          placement="bottom"
          content={ListContent(salesOrderReturnList, columns, '关联退货单', 'salesOrderReturnList')}
          trigger="hover"
        >
          <div>
            {salesOrderReturnList[0]?.code ?? replaceSign}
            <span style={{ color: '#02B980' }}>{`(${salesOrderReturnList?.length})`}</span>
          </div>
        </Popover>
      );
    },
  },
  ...renderDate('salesMaterial'),
]);
// 协同申请单列表 展示列
export const dataColumnsForPurchaseRequisition = _.compact([
  {
    title: '客户编号',
    dataIndex: ['customerCode'],
    width: 200,
    render: (code: string) => code ?? replaceSign,
  },
  {
    title: '客户名称',
    dataIndex: ['customerName'],
    width: 200,
    render: (name: string) => name ?? replaceSign,
  },
  {
    title: '协同采购单号',
    dataIndex: 'customerPurchaseOrderCode',
    width: 150,
    render: (text: number) => text ?? replaceSign,
  },
  {
    title: '带料方式',
    dataIndex: 'materialCarryMode',
    width: 150,
    render: (text: number) =>
      lookup('purchase.purchaseOrderMaterialCarryMode', text) ?? replaceSign,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 150,
    render: (text: number) => lookup('sales.purchaseRequisitionStatus', text) ?? replaceSign,
  },
  {
    title: '交货方式',
    dataIndex: 'deliveryMode',
    width: 150,
    render: (text: number) => {
      return _.isNil(text) ? replaceSign : lookup('purchase.purchaseOrderdeliveryMode', text);
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    width: 150,
    sorter: true,
    render: (createdAt: Date) => {
      if (!createdAt) return replaceSign;
      return _Time.format(createdAt);
    },
  },
  {
    title: '更新人',
    dataIndex: 'operator',
    width: 150,
    render: (operator: Creator) => {
      return (
        <AvatarDisplay
          id={operator?.id}
          name={operator?.name}
          avatarUrl={operator?.avatarUrl}
          key={operator?.id}
          isShowTag={false}
          isUser
        />
      );
    },
  },
  {
    title: '更新时间',
    dataIndex: 'updatedAt',
    width: 150,
    sorter: true,
    render: (updatedAt: Date) => {
      if (!updatedAt) return replaceSign;
      return _Time.format(updatedAt);
    },
  },
]);

// 订单单据筛选列
export const documentsFilterList: FilterItem[] = _.compact([
  {
    label: '订单编号',
    name: 'code',
    type: fieldTypeList.text,
  },
  Auth.supplyChain && {
    label: '订单来源',
    name: 'origin',
    type: fieldTypeList?.multiSelect,
    selectProps: {
      options: originTypeMapping,
      mode: 'multiple',
    },
  },
  Auth.supplyChain && {
    label: '协同采购单号',
    name: 'scmPurchaseOrder',
    type: fieldTypeList.text,
  },
  {
    label: '交货方式',
    name: 'deliveryMode',
    type: fieldTypeList.multiSelect,
    selectProps: {
      options: purchaseOrderdeliveryMode,
      mode: 'multiple',
    },
  },
  {
    label: '客户名称',
    name: 'customerIds',
    type: fieldTypeList.multiSelect,
    renderItem: <SearchSelect fetchType={'customer'} labelInValue mode="multiple" />,
  },
  {
    label: '订单所有人',
    name: 'ownerIds',
    type: fieldTypeList.text,
    renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
  },
  {
    label: '订单状态',
    name: 'status',
    type: fieldTypeList?.multiSelect,
    selectProps: {
      options: appDict.sales.orderStatus,
    },
  },
  Auth.supplyChain && {
    label: '发货状态',
    name: 'shipmentStatus',
    type: fieldTypeList?.multiSelect,
    selectProps: {
      options: shipmentStatusMapping,
      mode: 'multiple',
    },
  },
  {
    label: '创建人',
    name: 'creatorIds',
    type: fieldTypeList.text,
    renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
  },
  {
    label: '创建时间',
    name: 'createdAt',
    type: fieldTypeList.date,
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
  },
  {
    label: '更新人',
    name: 'operatorIds',
    type: fieldTypeList.text,
    renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
  },
  {
    label: '更新时间',
    name: 'updatedAt',
    type: fieldTypeList.date,
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
  },
]);
// 明细筛选列
export const detailFilterList: FilterItem[] = _.compact([
  {
    label: '物料编号',
    name: 'materialIdsCode',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <SearchSelect
        fetchType={'material'}
        labelInValue
        labelPath="code"
        valuePath="id"
        mode="multiple"
        params={{ enableFlag: 1 }} // 选择启用中的 mode="multiple"
      />
    ),
  },
  {
    label: '物料名称',
    name: 'materialIdsName',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <SearchSelect
        fetchType={'material'}
        mode="multiple"
        labelInValue
        labelPath="name"
        valuePath="id"
        params={{ enableFlag: 1 }} // 选择启用中的 mode="multiple"
      />
    ),
  },
  {
    label: '物料规格',
    name: 'specification',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <SearchSelect
        fetchType={'material'}
        mode="multiple"
        labelInValue
        labelPath="specification" // 没有物料规格是显示为 '-'
        valuePath="id"
        params={{ enableFlag: 1 }} // 选择启用中的 mode="multiple"
      />
    ),
  },
  {
    label: '单位',
    name: 'unitIds',
    type: fieldTypeList.multiSelect,
    renderItem: <SearchSelect fetchType={'unit'} labelInValue mode="multiple" />,
  },
  {
    label: '订单编号',
    name: 'code',
    type: fieldTypeList.text,
  },
  Auth.supplyChain && {
    label: '订单来源',
    name: 'origin',
    type: fieldTypeList?.multiSelect,
    selectProps: {
      options: originTypeMapping,
      mode: 'multiple',
    },
  },
  Auth.supplyChain && {
    label: '协同采购单号',
    name: 'scmPurchaseOrder',
    type: fieldTypeList.text,
  },
  {
    label: '订单所有人',
    name: 'ownerIds',
    type: fieldTypeList.text,
    renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
  },
  {
    label: '客户名称',
    name: 'customerIds',
    type: fieldTypeList.multiSelect,
    renderItem: <SearchSelect fetchType={'customer'} labelInValue mode="multiple" />,
  },
  {
    label: '交货日期',
    name: 'deliveryDate',
    type: fieldTypeList.date,
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
  },
  Auth.supplyChain && {
    label: '发货状态',
    name: 'shipmentStatus',
    type: fieldTypeList?.multiSelect,
    selectProps: {
      options: shipmentStatusMapping,
      mode: 'multiple',
    },
  },
  Auth.supplyChain && {
    label: '发货单号',
    name: 'shipmentNoteCode',
    type: fieldTypeList.text,
  },
  Auth.supplyChain && {
    label: '退货单号',
    name: 'salesOrderReturnCode',
    type: fieldTypeList.text,
  },
  {
    label: '创建人',
    name: 'creatorIds',
    type: fieldTypeList.text,
    renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
  },
  {
    label: '创建时间',
    name: 'createdAt',
    type: fieldTypeList.date,
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
  },
  {
    label: '更新人',
    name: 'operatorIds',
    type: fieldTypeList.text,
    renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
  },
  {
    label: '更新时间',
    name: 'updatedAt',
    type: fieldTypeList.date,
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
  },
]);
// 协同申请单列表 筛选列
export const purchaseRequisitionFilterList: FilterItem[] = _.compact([
  {
    label: '订单编号',
    name: 'code',
    type: fieldTypeList.text,
  },
  {
    label: '客户名称',
    name: 'customerIds',
    type: fieldTypeList.multiSelect,
    renderItem: <SearchSelect fetchType={'customer'} labelInValue mode="multiple" />,
  },
  {
    label: '带料方式',
    name: 'materialCarryMode',
    type: fieldTypeList.multiSelect,
    selectProps: {
      options: purchaseOrderMaterialCarryMode,
      mode: 'multiple',
    },
  },
  {
    label: '状态',
    name: 'status',
    type: fieldTypeList?.multiSelect,
    selectProps: {
      options: purchaseRequisitionStatusMapping,
      mode: 'multiple',
    },
  },
  {
    label: '创建时间',
    name: 'createdAt',
    type: fieldTypeList?.date,
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
    render: formatTimeForRender,
  },
  {
    label: '更新人',
    name: 'operatorIds',
    type: fieldTypeList?.multiSelect,
    renderItem: (
      <UserOrDepartmentSelectWithDialog placeholder="请选择更新人" isMultiple isSelectUser />
    ),
  },
  {
    label: '更新时间',
    name: 'updatedAt',
    type: fieldTypeList?.date,
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
    render: formatTimeForRender,
  },
]);

// 详情基本信息
export const detailBaseInfo = [
  { label: '订单编号', dataIndex: ['header', 'code'] },
  {
    label: '客户名称',
    dataIndex: ['header', 'customer'],
    render: (customer: Customer) => customer?.name || replaceSign,
  },
  {
    label: '订单来源',
    dataIndex: ['header', 'origin'],
    width: 200,
    render: (origin: number) => lookup('sales.originType', origin) ?? replaceSign,
  },
  {
    label: '订单所有人',
    dataIndex: ['header', 'owners'],
    render: (owners: Creator[]) => {
      return _.map(_.filter(owners, 'id'), (owner: Creator) => (
        <AvatarDisplay
          id={owner.id}
          name={owner.name}
          avatarUrl={owner.avatarUrl}
          key={owner.id}
          isShowTag
          isUser
        />
      ));
    },
  },
  {
    label: '订单状态',
    dataIndex: ['header', 'status'],
    width: 200,
    render: (status: number) => lookup('sales.orderStatus', status) ?? replaceSign,
  },
  {
    label: '生产状态',
    dataIndex: ['header', 'produceStatus'],
    width: 200,
    render: (produceStatus: number) =>
      lookup('sales.productionStatus', produceStatus) ?? replaceSign,
  },
  {
    label: '发货状态',
    dataIndex: ['header', 'shipmentStatus'],
    width: 200,
    render: (shipmentStatus: number) =>
      lookup('sales.shipmentStatus', shipmentStatus) ?? replaceSign,
  },
  {
    label: '创建人',
    dataIndex: ['header', 'creator'],
    render: (creator: CreatorData) => {
      return (
        <AvatarDisplay
          id={creator?.id}
          name={creator?.name}
          avatarUrl={creator?.avatarUrl}
          key={creator?.id}
          isShowTag={false}
          isUser
        />
      );
    },
  },
  {
    label: '创建时间',
    dataIndex: ['header', 'createdAt'],
    render: (createdAt: Date) => {
      if (!createdAt) return replaceSign;
      return _Time.format(createdAt);
    },
  },
  {
    label: '更新人',
    dataIndex: ['header', 'operator'],
    render: (operator: CreatorData) => {
      return (
        <AvatarDisplay
          id={operator?.id}
          name={operator?.name}
          avatarUrl={operator?.avatarUrl}
          key={operator?.id}
          isShowTag={false}
          isUser
        />
      );
    },
  },
  {
    label: '更新时间',
    dataIndex: ['header', 'updatedAt'],
    render: (updatedAt: Date) => {
      if (!updatedAt) return replaceSign;
      return _Time.format(updatedAt);
    },
  },
  {
    label: '附件',
    dataIndex: ['header', 'attachments'],
    render: (attachments: number[]) => {
      if (_Array.isEmpty(attachments)) return replaceSign;

      return <BcAttachmentDetail fileIds={attachments} />;
    },
  },
  {
    label: '订单备注',
    dataIndex: ['header', 'remark'],
    render: (text: string) => <BcMultilineText text={text} needWordCount />,
  },
];
// 详情-协同采购信息
export const renderDetailPurchaseInfo = (customerId: number) => {
  return [
    {
      label: '协同采购申请单号',
      dataIndex: ['header', 'scmPurchaseRequestCode'],
      render: (text: string) => text ?? replaceSign,
    },
    {
      label: '协同采购单号',
      dataIndex: ['header', 'scmPurchaseOrderCode'],
      render: (text: string) => text ?? replaceSign,
    },
    ...renderDeliveryMethod('salesDetail'),
    {
      label: '带料方式',
      dataIndex: ['header', 'scmMaterialCarryMode'],
      width: 150,
      render: (text: number) =>
        lookup('purchase.purchaseOrderMaterialCarryMode', text) ?? replaceSign,
    },
    ...renderCurrency('salesDetail'),
    {
      label: '客户备注',
      dataIndex: ['header', 'purchaseOrder', 'remark'],
      render: (text: string) => <BcMultilineText text={text} needWordCount />,
    },
    {
      label: '协同附件',
      dataIndex: ['header', 'coordinationAttachments'],
      render: (files: number[]) => {
        if (_.isEmpty(files)) return replaceSign;

        return (
          <BcAttachmentDetail
            fileIds={files}
            type="collaborative"
            collaborativeParams={{ customerId }}
          />
        );
      },
    },
  ];
};
// 详情-协同采购信息-明细行
export const renderDetailPurchaseColumn = [
  {
    title: '序号',
    dataIndex: 'seqNo',
    width: 80,
    render: (text: string) => text ?? replaceSign,
  },
  ...renderMaterial('自建'),
  {
    title: '转换后需求数量',
    width: 200,
    dataIndex: 'amountConverted',
    render: (text: Amount) => (text?.amount ? `${text?.amount} ${text?.unitName}` : replaceSign),
  },
  {
    title: '需求时间',
    dataIndex: 'demandDate',
    width: 250,
    render: (text: Date) => {
      if (!text) return replaceSign;
      return _Time.format(text);
    },
  },
  ...renderPrice(),
];

// 详情-订单明细行
export const orderDetailColumn = [
  {
    title: '行号',
    dataIndex: 'lineNo',
    width: 80,
    render: (lineNo: string) => lineNo ?? replaceSign,
  },
  ...renderMaterial(),
  {
    title: '需求数量',
    dataIndex: ['amount', 'amountDisplay'],
    width: 200,
    render: (amountDisplay: string, record: SalesOrderDetailsItems) =>
      `${amountDisplay}${record?.amount?.unitName}` ?? replaceSign,
  },
  {
    title: '生产状态',
    dataIndex: 'produceStatus',
    width: 150,
    render: (produceStatus: number) =>
      lookup('sales.productionStatus', produceStatus) ?? replaceSign,
  },
  {
    title: '交货日期',
    dataIndex: 'deliveryDate',
    width: 300,
    render: (deliveryDate: Date) => {
      if (!deliveryDate) return replaceSign;
      return _Time.format(deliveryDate);
    },
  },
  {
    title: '关联工单',
    dataIndex: 'workOrderList',
    width: 200,
    render: (workOrderList: WorkOrderListTypeItems[]) => {
      if (_Array.isEmpty(workOrderList)) return replaceSign;

      return (
        <Popover
          placement="bottom"
          content={workOrderListContent(workOrderList, 'items')}
          trigger="hover"
        >
          <div>
            {workOrderList[0]?.workOrderCode ?? replaceSign}
            <span style={{ color: '#02B980' }}>{`(${workOrderList?.length})`}</span>
          </div>
        </Popover>
      );
    },
  },
  {
    title: '计划生产数',
    dataIndex: ['plannedAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '生产完工数',
    dataIndex: ['producedAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '合格完工数',
    dataIndex: ['qualifiedAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '完工入库数',
    dataIndex: ['inventoryAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '备注',
    dataIndex: 'remark',
    width: 150,
    render: (remark: string) => remark ?? replaceSign,
  },
];

// 详情-工单信息主行
export const WorkOrderDetailMainColumn = [
  {
    title: '工单编号',
    dataIndex: 'code',
    width: 300,
    render: (code: string) => code ?? replaceSign,
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    width: 200,
    render: (creator: Creator) => {
      return (
        <AvatarDisplay
          id={creator?.id}
          name={creator?.name}
          avatarUrl={creator?.avatarUrl}
          key={creator?.id}
          isShowTag={false}
          isUser
        />
      );
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    width: 200,
    render: (createdAt: Date) => {
      if (!createdAt) return replaceSign;
      return _Time.format(createdAt);
    },
  },
];
// 详情-工单信息子行
export const WorkOrderDetailColumn = [
  {
    title: '订单明细行号',
    dataIndex: 'lineNo',
    width: 100,
    render: (lineNo: string) => lineNo ?? replaceSign,
  },
  {
    title: '物料名称',
    dataIndex: 'materialName',
    width: 150,
    render: (materialName: string) => materialName ?? replaceSign,
  },
  {
    title: '物料编号',
    dataIndex: 'materialCode',
    width: 150,
    render: (materialCode: string) => materialCode ?? replaceSign,
  },
  {
    title: '计划生产数',
    dataIndex: 'plannedAmount',
    width: 150,
    render: (plannedAmount: string, { unit }: ItemsWorkListType) =>
      `${plannedAmount}${unit}` ?? replaceSign,
  },
  {
    title: '生产完成数',
    dataIndex: 'producedAmount',
    width: 150,
    render: (producedAmount: string, { unit }: ItemsWorkListType) =>
      `${producedAmount}${unit}` ?? replaceSign,
  },
  {
    title: '合格完工数',
    dataIndex: 'qualifiedAmount',
    width: 150,
    render: (qualifiedAmount: string, { unit }: ItemsWorkListType) =>
      `${qualifiedAmount}${unit}` ?? replaceSign,
  },
];
// 详情-发货信息主行
export const invoiceDetailsMainColumn = [
  {
    title: '发货单号',
    dataIndex: ['code'],
    width: 200,
    render: (code: string) => code ?? replaceSign,
  },
  {
    title: '创建人',
    dataIndex: ['creator'],
    width: 200,
    sorter: true,
    render: (creator: Creator) => {
      return (
        <AvatarDisplay
          id={creator?.id}
          name={creator?.name}
          avatarUrl={creator?.avatarUrl}
          key={creator?.id}
          isShowTag={false}
          isUser
        />
      );
    },
  },
  {
    title: '创建时间',
    dataIndex: ['createdAt'],
    width: 200,
    sorter: true,
    render: (createdAt: Date) => {
      if (!createdAt) return replaceSign;
      return _Time.format(createdAt);
    },
  },
];
// 详情-发货信息子行
export const invoiceDetailsColumn = [
  {
    title: '订单明细行号',
    dataIndex: ['lineNo'],
    width: 80,
    render: (text: string) => text || replaceSign,
  },
  {
    title: '客户物料编号',
    dataIndex: ['materialCode'],
    width: 200,
    render: (code: string) => code ?? replaceSign,
  },
  {
    title: '客户物料名称',
    dataIndex: ['materialName'],
    width: 200,
    render: (name: string) => name ?? replaceSign,
  },
  {
    title: '计划发货数',
    dataIndex: ['plannedIssueAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '仓库应发数',
    dataIndex: ['warehousePlannedIssueAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '仓库实发数',
    dataIndex: ['warehouseIssueAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
  {
    title: '客户收货数',
    dataIndex: ['customerReceivedAmount', 'amountDisplay'],
    width: 150,
    render: (amountDisplay: string) => amountDisplay ?? replaceSign,
  },
];
/**
 * 协同采购申请单-详情
 *
 * 基本信息
 */
export const purchaseBaseColumn = [
  { label: '订单编号', dataIndex: ['code'], render: (text: string) => text || replaceSign },
  {
    label: '客户编号',
    dataIndex: ['customerCode'],
    render: (text: string) => text || replaceSign,
  },
  {
    label: '客户名称',
    dataIndex: ['customerName'],
    render: (text: string) => text || replaceSign,
  },
  {
    label: '协同采购单号',
    dataIndex: ['customerPurchaseOrderCode'],
    render: (text: string) => text || replaceSign,
  },
  {
    label: '状态',
    dataIndex: ['status'],
    width: 200,
    render: (status: number) => lookup('sales.purchaseRequisitionStatus', status) ?? replaceSign,
  },
  {
    label: '带料方式',
    dataIndex: ['purchaseOrder', 'materialCarryMode'],
    width: 150,
    render: (text: number) =>
      lookup('purchase.purchaseOrderMaterialCarryMode', text) ?? replaceSign,
  },
  {
    label: '创建时间',
    dataIndex: ['createdAt'],
    width: 200,
    render: (createdAt: Date) => {
      if (!createdAt) return replaceSign;
      return _Time.format(createdAt);
    },
  },
  {
    label: '更新人',
    dataIndex: ['operator'],
    width: 200,
    render: (operator: Creator) => {
      if (operator?.id) {
        return (
          <AvatarDisplay
            id={operator?.id}
            name={operator?.name}
            avatarUrl={operator?.avatarUrl}
            key={operator?.id}
            isShowTag={false}
            isUser
          />
        );
      }
      return replaceSign;
    },
  },
  {
    label: '更新时间',
    dataIndex: ['updatedAt'],
    width: 200,
    render: (updatedAt: Date) => {
      if (!updatedAt) return replaceSign;
      return _Time.format(updatedAt);
    },
  },
];
/**
 * 协同采购申请单-详情
 *
 * 协同采购信息
 */
export const renderCollaboratePurchaseColumn = (customerId: number) => {
  return [
    {
      label: '协同采购单号',
      dataIndex: ['purchaseOrder', 'code'],
      render: (text: string) => text || replaceSign,
    },
    ...renderDeliveryMethod('purchaseRequisitionDetail'),
    ...renderCurrency('purchaseRequisitionDetail'),
    {
      label: '客户备注',
      dataIndex: ['purchaseOrder', 'remark'],
      width: 150,
      render: (text: string) => <BcMultilineText text={text} needWordCount />,
    },
    {
      label: '协同附件',
      dataIndex: ['purchaseOrder', 'coordinationAttachments'],
      render: (files: number[]) => {
        if (_.isEmpty(files)) return replaceSign;

        return (
          <BcAttachmentDetail
            fileIds={files}
            type="collaborative"
            collaborativeParams={{ customerId }}
          />
        );
      },
    },
  ];
};
/**
 * 协同采购申请单-详情
 *
 * 采购明细
 */
export const purchaseDetailColumn = [
  {
    title: '序号',
    width: 80,
    dataIndex: ['lineNo'],
    render: (text: string) => text || replaceSign,
  },
  {
    title: '客户物料编号',
    dataIndex: ['customerMaterialCode'],
    width: 200,
    render: (code: string) => code ?? replaceSign,
  },
  {
    title: '客户物料名称',
    dataIndex: ['customerMaterialName'],
    width: 200,
    render: (name: string) => name ?? replaceSign,
  },
  {
    title: '需求数量',
    width: 200,
    dataIndex: ['customerDemandAmount', 'amount'],
    render: (text: string) => text || replaceSign,
  },
  {
    title: '自建物料编号',
    dataIndex: ['materialCode'],
    width: 200,
    render: (code: string) => code ?? replaceSign,
  },
  {
    title: '自建物料名称',
    dataIndex: ['materialName'],
    width: 200,
    render: (name: string) => name ?? replaceSign,
  },
  {
    title: '自建物料属性',
    dataIndex: ['materialAttribute'],
    width: 200,
    render: mappingMaterialInfoAttrList,
  },
  {
    title: '自建物料规格',
    dataIndex: 'materialSpecification',
    width: 200,
    render: (name: string) => name ?? replaceSign,
  },
  {
    title: '转换后需求数量',
    dataIndex: ['convertedDemandAmount', 'amount'],
    width: 200,
    render: (name: string) => name ?? replaceSign,
  },
  {
    title: '需求时间',
    dataIndex: ['demandDate'],
    width: 200,
    render: (createdAt: Date) => {
      if (!createdAt) return replaceSign;
      return _Time.format(createdAt);
    },
  },
  ...renderPrice(),
];

export { renderMaterial };
