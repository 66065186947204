import { SupplyRegStatus, SupplierOperateMode } from '.';
import { mappingsFactory } from '../utils';

export const crud = mappingsFactory(
  ['未注册', SupplyRegStatus.unregistered],
  ['注册中', SupplyRegStatus.registering],
  ['已注册', SupplyRegStatus.registered],
);

export const SupplierOperateModeTitleMap = {
  [SupplierOperateMode.create]: '新建供应商',
  [SupplierOperateMode.edit]: '编辑供应商',
  [SupplierOperateMode.view]: '供应商详情',
  [SupplierOperateMode.log]: '操作记录',
  [SupplierOperateMode.hide]: undefined,
  [SupplierOperateMode.invitateRegisterSupplier]: '邀请注册供应商',
  [SupplierOperateMode.removeRegisterSupplier]: '解除注册供应商',
  [SupplierOperateMode.invitateRegisterMaterial]: '邀请注册物料',
  [SupplierOperateMode.removeRegisterMaterial]: '解除注册物料',
};

export const fieldLabels = {
  supplierCode: '供应商编号',
  supplierName: '供应商名称',
  supplierStatus: '状态',
  remark: '备注',
  creator: '创建人',
  createdAt: '创建时间',
  operator: '更新人',
  updatedAt: '更新时间',
  modifyReason: '修改原因',
  supplierOwner: '供应商所有人',
  supplierRegStatus: '供应商注册状态',
  regEnterprise: '注册企业',
  materialNumber: '物料编号',
  materialName: '物料名称',
  materialProperties: '物料属性',
  number: '数量',
  unit: '单位',
  registrationStatus: '注册状态',
  supplierMaterial: '供应商注册物料',
  supplierNumber: '供应商数量',
  supplierUnit: '供应商单位',
  supplierRegister: '注册供应商',
};
