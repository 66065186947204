import React, { useCallback, useEffect, useState } from 'react';
import { Form, message, Switch, Table, Checkbox, Tag } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import {
  BcMultilineText,
  BcTextControl,
  BlIcon,
  BlTooltip,
  SingleOperationLogComponent,
  ThumbnailImage,
} from 'src/components';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import confirm from 'src/page/knowledgeManagement/share/modalConfirm';
import { storageValidityUnit } from '../constant';
import { IAutoGeneratedMaterialDetailResponse } from '..';
import {
  fetchMaterialDelete,
  fetchMaterialDetail,
  fetchMaterialStart,
  fetchMaterialStop,
} from 'src/api/ytt/material-domain/Web_MaterialBaseInfo';
import { fetchMaterialInventoryDetail } from 'src/api/ytt/material-domain/Web_MaterialInventory';
import lookup, { appEnum } from 'src/dict';
import renderUsageStatus from 'src/page/share/renderUsageStatus';
import _, { isNil } from 'lodash';
import { fetchMaterialPurchaseDetail } from 'src/api/ytt/material-domain/Web_MaterialPurchase';
import { fetchMaterialProductionDetail } from 'src/api/ytt/material-domain/Web_MaterialProduction';
import { BizRange } from 'src/dict/material';
import { replaceSign } from 'src/utils/constants';
import { RouteComponentProps } from 'react-router-dom';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import authDict from 'src/utils/auth';
import { fetchMaterialFeedDetail } from 'src/api/ytt/material-domain/web_MaterialFeed';
import { ICustomFields, injectCustomFieldInfos } from 'src/components/customField';
import { fetchCustomFieldInstanceGetByObjectCode } from 'src/api/ytt/custom-object-domain';
import { valueOrHolder } from 'src/utils/formatters';

interface Unit {
  id: number;
  name: string;
  relationId: number;
}
interface Category {
  categoryName: string;
  relationId: number;
  categoryIds: number[];
}
interface Attributes {
  id: number;
  name: string;
  itemId: number;
  itemName: string;
  relationId: number;
}
interface Documents {
  id: number;
  url: string;
  relationId: number;
}
interface BatchAttrs {
  displayEnable: number;
  id: number;
  name: number;
  nullEnable: number;
  serialNo: number;
  type: number;
}
interface Props extends RouteComponentProps {
  id: number;
  showEdit?: () => void;
  refreshTable?: () => void;
  onCancel?: () => void;
}
const DetailsBaseInfo = (props: Props) => {
  const { id, showEdit, refreshTable, onCancel, history } = props;
  const [dataSource, setDataSource] = useState<IAutoGeneratedMaterialDetailResponse>({});
  const [delForm] = Form.useForm();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [customFields, setCustomFields] = useState<ICustomFields>();

  const init = async () => {
    if (id) {
      try {
        const resMaterial: any = await fetchMaterialDetail({ id }, { legacy: true });
        const resWarehousing: any = await fetchMaterialInventoryDetail(
          { materialId: id },
          { legacy: true },
        );
        const resPurchase: any = await fetchMaterialPurchaseDetail(
          { materialId: id },
          { legacy: true },
        );
        const resProduction: any = await fetchMaterialProductionDetail(
          { materialId: id },
          { legacy: true },
        );
        const resInput: any = await fetchMaterialFeedDetail({
          materialId: id,
        });
        const resCustom = await fetchCustomFieldInstanceGetByObjectCode({
          objectCode: OBJECT_OF_CODE.material,
        });

        setLoading(false);
        setCustomFields((resCustom?.data as any) || {});
        setDataSource({
          ...resMaterial?.data,
          warehousingInfo: resWarehousing?.data,
          purchasegInfo: resPurchase?.data,
          productionInfo: resProduction?.data,
          feedInfo: resInput?.data,
          customFields: resMaterial?.data?.customFields,
        });
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

  const handleFinish = async (id: number | number[]) => {
    try {
      const value = await delForm?.validateFields();
      const ids = typeof id === 'number' ? [id] : id;
      const { code, message: mes } = await fetchMaterialDelete({
        ids,
        reason: value.delReason,
      });

      if (code === 200) {
        message.success('删除成功');
        // 关闭details
        typeof onCancel === 'function' && onCancel();
        // 刷新 list
        typeof refreshTable === 'function' && refreshTable();
        return;
      }
      message.error(mes);
    } catch (error) {
      console.log(error);
    }
  };
  const initBaseMenu = useCallback(
    (status: number) =>
      _.compact([
        status && {
          key: 'stop',
          auth: authDict.material_enable_disable,
          onClick: async () => {
            try {
              await fetchMaterialStop({ ids: [id] });
              init();
              typeof refreshTable === 'function' && refreshTable();
              message.success('停用成功');
            } catch (err) {
              console.log(err);
            }
          },
          icon: <BlIcon type="icontingyong" />,
          title: '停用',
        },
        !status && {
          key: 'start',
          auth: authDict.material_enable_disable,
          onClick: async () => {
            try {
              await fetchMaterialStart({ ids: [id] });
              init();
              typeof refreshTable === 'function' && refreshTable();
              message.success('启用成功');
            } catch (err) {
              console.log(err);
            }
          },
          icon: <CheckCircleOutlined />,
          title: '启用',
        },
        // {
        //   key: 'delete',
        //   onClick: () => {
        //     confirm('是否删除当前物料', delForm, () => {
        //       handleFinish(id);
        //     });
        //   },
        //   // icon: <<BlIcon type="iconshanchulajitong1" /> />,
        //   title: '删除',
        // },
        {
          key: 'record',
          auth: authDict.material_operrecord,
          onClick: () => {
            setVisibleLog(true);
          },
          // icon: <FileTextOutlined />,
          title: '操作记录',
        },
        {
          key: 'edit',
          auth: authDict.material_edit,
          onClick: () => {
            if (typeof showEdit === 'function') {
              showEdit();
              return;
            }
            history.push(`/modeling/materialModeling/materialList/${id}/edit`);
          },
          icon: <BlIcon type="iconbianji" />,
          title: '编辑',
        },
      ]),
    [id],
  );

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    column: 2,
    items: _.compact([
      { label: '物料编号', dataIndex: 'code', render: (text: string) => text ?? replaceSign },
      { label: '物料名称', dataIndex: 'name', render: (text: string) => text ?? replaceSign },
      {
        label: '主单位',
        dataIndex: 'unit',
        render: (text: Unit) => text?.name ?? replaceSign,
      },
      {
        label: '辅助单位',
        dataIndex: 'auxUnit',
        render: (text: Unit, record: any) => {
          // 需要展示启用中的 辅助单位
          const unitList = _.map(
            _.filter(record?.auxUnitList, ['enableFlag', appEnum.Common.UsageStatus.enabled]),
            'toUnitName',
          );

          return _.isEmpty(unitList) ? replaceSign : _.head(unitList);
        },
      },
      {
        label: '转换单位',
        dataIndex: 'conversionUnits',
        render: (text = []) => {
          // 需要展示启用中的 转换单位
          const unitList = _.map(
            _.filter(text, ['enableFlag', appEnum.Common.UsageStatus.enabled]),
            ({ toUnitCount, toUnitName, fromUnitCount, fromUnitName }) => {
              return (
                <>
                  <span style={{ verticalAlign: ' middle' }}>{toUnitCount} </span>
                  <BlTooltip text={toUnitName} length={10} />
                  <span> = </span>
                  <span style={{ verticalAlign: ' middle' }}>{fromUnitCount} </span>
                  <BlTooltip text={fromUnitName} length={10} />
                  <span>；</span>
                </>
              );
            },
          );

          return _.isEmpty(unitList) ? replaceSign : unitList;
        },
      },
      {
        label: '物料类型',
        dataIndex: 'bizType',
        render: (text: number) => lookup('material.bizType', text) ?? replaceSign,
      },
      {
        label: '业务范围',
        dataIndex: 'bizRange',
        render: (text: number[]) => {
          if (text) {
            const arr = text.map((item: number) => {
              return lookup('material.bizRange', item);
            });

            return arr.join('、');
          }
          return replaceSign;
        },
      },
      {
        label: '物料分类',
        dataIndex: 'category',
        render: (text: Category) => text?.categoryName || replaceSign,
      },
      {
        label: '物料状态',
        dataIndex: 'enableFlag',
        isFullLine: true,
        render: renderUsageStatus,
      },
      {
        label: '物料属性',
        dataIndex: 'attributes',
        isFullLine: true,
        render: (list: Attributes[]) => {
          return (
            <Table
              style={{ width: '100%' }}
              pagination={false}
              rowKey={'id'}
              columns={[
                {
                  title: '属性项',
                  dataIndex: 'name',
                },
                {
                  title: '属性值',
                  dataIndex: 'itemName',
                },
              ]}
              dataSource={list}
            />
          );
        },
      },
      {
        label: '物料规格',
        dataIndex: 'specification',
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '物料图片',
        dataIndex: 'documents',
        isFullLine: true,
        render: (data: Documents[]) => {
          return data?.length > 0 ? (
            <div style={{ display: 'flex' }}>
              {data?.map((item) => {
                return <ThumbnailImage documentId={item?.id} documentPath={item?.url} />;
              })}
            </div>
          ) : (
            replaceSign
          );
        },
      },
      {
        label: '批次管理',
        dataIndex: 'batchManagementEnable',
        isFullLine: true,
        valuePropName: 'checked',
        render: (text: number) => (
          <Checkbox checked={Boolean(text)} disabled>
            启用批次管理
          </Checkbox>
        ),
      },
      dataSource?.batchManagementEnable && {
        label: '批次属性',
        dataIndex: 'batchAttrs',
        isFullLine: true,
        render: (list: BatchAttrs[]) => {
          return (
            <Table
              style={{ width: '100%' }}
              pagination={false}
              columns={[
                {
                  title: '批次属性名称',
                  dataIndex: 'name',
                  render: (text) => lookup('material.batchAttrs', text),
                },
                {
                  title: '属性类型',
                  dataIndex: 'type',
                  render: (text) => (
                    <BcTextControl value={lookup('material.batchAttrsType', text)} />
                  ),
                },
                {
                  title: '是否显示',
                  dataIndex: 'displayEnable',
                  render: (text) => <Switch disabled checked={text} />,
                },
                {
                  title: '是否必填',
                  dataIndex: 'nullEnable',
                  render: (text) => <Switch disabled checked={!text} />,
                },
              ]}
              dataSource={list}
            />
          );
        },
      },
      dataSource?.batchManagementEnable && {
        label: '批次号规则',
        dataIndex: 'batchNoRule',
        isFullLine: true,
        render: (text: { id: number; name: string }) => text?.name ?? replaceSign,
      },
      {
        label: '物料备注',
        dataIndex: 'remark',
        isFullLine: true,
        render: (text: string) => <BcMultilineText text={text} needWordCount />,
      },
    ]),
  };
  const renderWarehousingInfo = () => {
    return {
      title: '仓储信息',
      column: 2,
      items: _.compact([
        {
          label: '转换单位',
          dataIndex: ['warehousingInfo', 'convertUnit'],
          render: (text: Unit) => text?.name ?? replaceSign,
        },
        {
          label: '调拨批量',
          dataIndex: ['warehousingInfo', 'transferBatchDisplay'],
          render: (text: number, record: any) => {
            const unitId = _.get(record, 'warehousingInfo.transferUnitId');
            const mainUnit = _.get(record, 'unit');
            const conversionUnits = _.get(record, 'conversionUnits');
            const unitName =
              mainUnit?.id === unitId
                ? mainUnit?.name
                : conversionUnits?.find((unit: any) => unit?.toUnitId === unitId)?.toUnitName;

            return _.isNil(text) ? replaceSign : `${text} ${unitName || ''}`;
          },
        },
        {
          label: '默认仓库',
          dataIndex: ['warehousingInfo', 'warehouse'],
          render: (text: { id: number; name: string }) => text?.name ?? replaceSign,
        },
        {
          label: '默认仓位',
          dataIndex: ['warehousingInfo', 'location'],
          render: (text: { id: number; name: string }) => text?.name ?? replaceSign,
        },
        {
          label: '存储有效期',
          dataIndex: ['warehousingInfo', 'validity'],
          span: 2,
          render: (text: { time: string; unit: string }) => {
            return text && text?.time
              ? `${text?.time} ${storageValidityUnit.get(Number(text.unit))}`
              : replaceSign;
          },
        },
        {
          label: '先进先出',
          dataIndex: ['warehousingInfo', 'fifoEnabled'],
          render: (text: number | undefined) => <Switch disabled checked={Boolean(text)} />,
        },
        Boolean(dataSource?.warehousingInfo?.fifoEnabled) && {
          label: '先进先出属性',
          dataIndex: ['warehousingInfo', 'fifoAttr'],
          render: (text: number) => lookup('material.firstInFirstOutRules', text) ?? replaceSign,
        },
        dataSource?.warehousingInfo?.inventoryAttrs?.length > 0 && {
          label: '库存属性',
          dataIndex: ['warehousingInfo', 'inventoryAttrs'],
          isFullLine: true,
          render: (list: BatchAttrs[]) => {
            return (
              <Table
                style={{ width: '100%' }}
                pagination={false}
                rowKey={'id'}
                columns={[
                  {
                    title: '库存属性名称',
                    dataIndex: 'name',
                    render: (text) => lookup('material.batchAttrs', text),
                  },
                  {
                    title: '属性类型',
                    dataIndex: 'type',
                    render: (text) => (
                      <BcTextControl value={lookup('material.batchAttrsType', text)} />
                    ),
                  },
                  {
                    title: '是否显示',
                    dataIndex: 'displayEnable',
                    render: (text) => <Switch disabled checked={text} />,
                  },
                  {
                    title: '是否必填',
                    dataIndex: 'nullEnable',
                    render: (text) => <Switch disabled checked={!text} />,
                  },
                ]}
                dataSource={list}
              />
            );
          },
        },
        {
          label: '库存标识码规则',
          dataIndex: ['warehousingInfo', 'inventoryQrCodeRule'],
          span: 2,
          render: (text: { id: number; name: string }) => text?.name ?? replaceSign,
        },
      ]),
    };
  };

  const renderPurchasegInfo = () => {
    return {
      title: '采购信息',
      column: 2,
      items: [
        {
          label: '采购单位',
          dataIndex: ['purchasegInfo', 'purchaseUnit'],
          render: (text: Unit) => text?.name ?? replaceSign,
        },
        {
          label: '采购批量',
          dataIndex: ['purchasegInfo', 'purchaseAmount'],
          render: (text: number) => (typeof text === 'string' ? text : replaceSign),
        },
        {
          label: '最小采购量',
          dataIndex: ['purchasegInfo', 'purchaseAmountMin'],
          render: (text: number) => (typeof text === 'string' ? text : replaceSign),
        },
        {
          label: '货源控制',
          dataIndex: ['purchasegInfo', 'sourceControl'],
          render: (text: number) => lookup('common.yn', text),
        },
        {
          label: '税率',
          dataIndex: ['purchasegInfo', 'taxRate'],
          render: (text: number) => (!isNil(text) ? `${text}%` : replaceSign),
        },
      ],
    };
  };
  const renderProductionInfo = () => {
    return {
      title: '自制信息',
      column: 2,
      items: [
        {
          label: '自制单位',
          dataIndex: ['productionInfo', 'productionUnit'],
          render: (text: Unit) => text?.name ?? replaceSign,
        },
        {
          label: '工单批号生成规则',
          dataIndex: ['productionInfo', 'orderBatchRule'],
          render: (orderBatchRule: any) =>
            valueOrHolder(
              lookup('productionPlanning.BatchGenerationTypeDisplayMap', orderBatchRule),
            ),
        },
      ],
    };
  };

  const renderManufacture = () => {
    return {
      title: '投料信息',
      column: 2,
      items: [
        {
          label: '投料质量',
          dataIndex: ['feedInfo', 'qcStatus'],
          render: (text: number[]) => {
            if (!text) return replaceSign;
            return text.map((value: number) => (
              <Tag>{lookup('material.feedingQualityStatus')(value)}</Tag>
            ));
          },
        },
        {
          label: '投料单位',
          dataIndex: ['feedInfo', 'feedUnit'],
          render: (text: Unit) => text?.name ?? replaceSign,
        },
      ],
    };
  };

  const renderCustom = () => {
    if (!customFields) {
      return null;
    }
    return injectCustomFieldInfos({ customFields, type: 'detail' });
  };

  const infoArray = _.compact([
    baseInfo,
    !_.isEmpty(customFields?.customFields) && renderCustom(),
    dataSource?.bizRange?.includes(BizRange.warehousing) && renderWarehousingInfo(),
    dataSource?.bizRange?.includes(BizRange.purchase) && renderPurchasegInfo(),
    dataSource?.bizRange?.includes(BizRange.production) && renderProductionInfo(),
    dataSource?.bizRange?.includes(BizRange.manufacture) && renderManufacture(),
  ]);

  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <DetailLayout
        loading={loading}
        title={'物料详情'}
        baseMenu={initBaseMenu(dataSource?.enableFlag as number)}
        info={infoArray}
        dataSource={dataSource}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={id as number}
          objectCode={OBJECT_OF_CODE.material}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailsBaseInfo;
