import { message, message as Message, Modal } from 'antd';
import {
  fetchDeliveryScheduleNoteBatchDistribute,
  fetchDeliveryScheduleNoteBatchClosed,
  fetchDeliveryScheduleNoteBatchEnded,
  fetchDeliveryScheduleNoteBatchEndedEnsure,
} from 'src/api/ytt/order-domain/deliveryScheduleNote';
import { replaceSign } from 'src/utils/constants';
import { showErrorListMessage } from 'src/components';
import { DeliveryScheduleNoteExecStatus, EndResultType } from 'src/dict/purchase';
import _ from 'lodash';
import lookup from 'src/dict';

const getErrorColumns = (isException?: boolean) => [
  {
    title: '序号',
    dataIndex: 'num',
    width: 150,
    render: (code: number) => code || replaceSign,
  },
  {
    title: '编号',
    dataIndex: 'code',
    width: 150,
    render: (code: string) => code || replaceSign,
  },
  {
    title: '供应商名称',
    dataIndex: ['supplier', 'name'],
    width: 150,
  },
  {
    title: isException ? '中断原因' : '失败原因',
    dataIndex: 'errorReason',
    width: 300,
    render: (detail: string) => detail || replaceSign,
  },
];

const handleDistribute = async (
  list: any,
  ids: number[],
  onSuccess?: () => void,
  onFail?: () => void,
  refresh?: () => void,
) => {
  try {
    const { data } = await fetchDeliveryScheduleNoteBatchDistribute({ ids });

    if (data) {
      const { successCount, failCount, failDetails } = data;

      if (failCount === 0) {
        Message.success('下发成功');
        onSuccess?.();
        refresh?.();
        return;
      }
      if (ids.length === 1) {
        Message.error(`下发失败，${failDetails?.[0].errorReason}`);
        onFail?.();
        return;
      }
      if (successCount === 0) {
        onFail?.();
        // 全部失败
      } else if (failCount! > 0) {
        onSuccess?.();
        // 部分失败 需要展示失败信息
      }

      showErrorListMessage({
        title: '下发出现失败',
        data: (failDetails as Object[]).map((item, index) => {
          return { ...item, num: index + 1 };
        }),
        columns: getErrorColumns(),
        operateName: '下发',
        successCount,
        failCount,
        width: 800,
      });

      return;
    }
  } catch (error) {
    onFail?.();
    console.log(error);
  }
};

const handleClosed = async (
  list: any,
  ids: number[],
  onSuccess?: () => void,
  onFail?: () => void,
  refresh?: () => void,
) => {
  try {
    const { data } = await fetchDeliveryScheduleNoteBatchClosed({ ids });

    if (data) {
      const { successCount, failCount, failDetails } = data;

      if (failCount === 0) {
        Message.success('关闭成功');
        onSuccess?.();
        refresh?.();
        return;
      }
      if (ids.length === 1) {
        Message.error(`关闭失败，${failDetails?.[0].errorReason}`);
        onFail?.();
        return;
      }
      if (successCount === 0) {
        onFail?.();
        // 全部失败
      } else if (failCount! > 0) {
        onSuccess?.();
        // 部分失败 需要展示失败信息
      }

      showErrorListMessage({
        title: '关闭出现失败',
        data: (failDetails as Object[]).map((item, index) => {
          return { ...item, num: index + 1 };
        }),
        columns: getErrorColumns(),
        operateName: '关闭',
        successCount,
        failCount,
        width: 800,
      });

      return;
    }

    return;
  } catch (error) {
    onFail?.();
    console.log(error);
  }
};

const showError = (res: any, option: any) => {
  const { failCount = 0, successCount = 0, failDetails } = res?.data ?? {};

  if (failCount) {
    if (option.type === 'multiple') {
      showErrorListMessage({
        failCount,
        successCount,
        title: '结束出现失败',
        width: 800,
        data: (failDetails as Object[]).map((item, index) => {
          return { ...item, num: index + 1 };
        }),
        desc: `交货计划单结束完成，结束成功：${successCount}个，结束失败：${failCount}个，具体失败原因如下：`,
        columns: getErrorColumns(),
        ...option,
      });
    } else {
      const first = _.first(failDetails) as any;

      message.error(first?.errorReason);
    }
  } else {
    message.success(`${option.display}！`);
  }
};

const endOrderconfirm = async (
  ids: number[],
  type: 'multiple' | 'single',
  display: string,
  onSuccess?: () => void,
  onFail?: () => void,
) => {
  try {
    const response = await fetchDeliveryScheduleNoteBatchEnded({ ids });

    if (response && response.code === 200 && response.data) {
      showError(response, {
        method: 'error',
        okText: '知道了',
        type,
        display,
      });
    }
    onSuccess?.(); // 如果出现弹窗闪退的情况 建议参照采购退货，移动 至 showError 里
  } catch (error) {
    onFail?.();
    console.log(error);
  }
};
const returnErrorColumns = [
  {
    title: '编号',
    dataIndex: 'code',
    width: 100,
  },
  {
    title: '所有人',
    dataIndex: ['owner', 'name'],
    width: 100,
  },
  {
    title: '供应商名称',
    dataIndex: ['supplier', 'name'],
    width: 100,
  },
  {
    title: '供应商编号',
    dataIndex: ['supplier', 'code'],
    width: 100,
  },
  {
    title: '状态',
    dataIndex: 'execStatus',
    width: 100,
    render: lookup('purchase.deliveryScheduleNoteExecStatus'),
  },
  {
    title: '中断原因',
    dataIndex: 'errorReason',
    width: 200,
  },
];
const endOrder = async (
  list: any,
  ids: number[],
  codes: string[],
  type: 'multiple' | 'single',
  onSuccess?: () => void,
  onFail?: () => void,
  setLoading?: (loading: boolean) => void,
) => {
  try {
    setLoading?.(true);
    const response = await fetchDeliveryScheduleNoteBatchEndedEnsure({ ids });

    if (response && response.code === 200 && response.data) {
      const { successCount, failCount, failDetails } = response.data;
      const hasException = failDetails?.some(
        (failDetail) => failDetail.procStatus === EndResultType.exception,
      );
      const hasFailed = failDetails?.some(
        (failDetail) => failDetail.procStatus === EndResultType.fail,
      );
      const display = hasException ? '异常结束成功' : '结束成功';

      if (failCount === 0) {
        Modal.confirm({
          title: '结束交货计划单',
          content: '结束交货计划单后不能再进行任何操作，是否确定结束？',
          onOk: () => endOrderconfirm(ids, type, display, onSuccess, onFail),
          onCancel: onFail,
          cancelText: '暂不结束',
          okText: '结束',
        });
      } else {
        const first = _.first(failDetails) || {};

        showErrorListMessage({
          title: '结束出现失败',
          data: failDetails ?? [],
          columns: returnErrorColumns,
          operateName: '结束',
          successCount,
          failCount,
          width: 800,
          onOk: () => endOrderconfirm(ids, type, display, onSuccess, onFail),
          onCancel: onFail,
          okCancel: true,
          cancelText: '暂不结束',
          okText: '结束',
        });
      }
    }
  } catch (error) {
    onFail?.();
    console.log(error);
  } finally {
    setLoading?.(false);
  }
};

export { handleDistribute, handleClosed, endOrder };
