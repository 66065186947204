const getQcConfigEditUrl = (id: number) => `/quality/qualityModeling/qcConfig/edit?id=${id}`;
const getQcConfigCopyUrl = (id: number) =>
  `/quality/qualityModeling/qcConfig/copy?id=${id}&isCopy=1`;
const getQcConfigCreateUrl = () => '/quality/qualityModeling/qcConfig/create';
const getQcConfigDetailUrl = (id: number, Snapshot?: boolean) => {
  const isSnapshot = Snapshot ? `&isSnapshot=${Snapshot}` : '';

  return `/quality/qualityModeling/qcConfig/detail?id=${id}${isSnapshot}`;
};

export { getQcConfigEditUrl, getQcConfigCopyUrl, getQcConfigCreateUrl, getQcConfigDetailUrl };
