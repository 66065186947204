/**
 * 菜单排序
 */
import { FC, useEffect, useState } from 'react';
import { Form, message, Modal } from 'antd';
import { DraggableList } from 'src/components';
import {
  fetchStandardBizObjectMenuListAll,
  fetchStandardBizObjectMenuUpdateMenuOrder,
  FetchStandardBizObjectMenuListAllResponse,
} from 'ytt/metadata-domain/customMenu';
import { useBlocker } from 'src/utils';
import styles from '../styles.module.scss';

interface Props {
  visible: boolean;
  hide: () => void;
}

const SortModal: FC<Props> = ({ visible, hide }) => {
  const [loadingComp, setLoadingComp] = useState<'data' | 'submit' | null>(null);
  const [menuList, setMenuList] = useState<
    Required<FetchStandardBizObjectMenuListAllResponse>['data']
  >([]);
  const [form] = Form.useForm();
  const { resetForm, shouldLeave } = useBlocker(form);

  const handleCancel = () => shouldLeave() && hide();
  const handleFinish = () => {
    const values = form.getFieldsValue();

    setLoadingComp('submit');
    fetchStandardBizObjectMenuUpdateMenuOrder(values)
      .then(() => {
        message.success('保存完成。');
        resetForm(values);
        handleCancel();
      })
      .catch(() => setLoadingComp(null));
  };

  useEffect(() => {
    if (visible) {
      setLoadingComp('data');
      fetchStandardBizObjectMenuListAll({ sortByMenuSeq: true })
        .then((res) => {
          const list = res.data ?? [];

          setMenuList(list);
          resetForm({ menuIdList: list.map((i) => i.id) });
        })
        .finally(() => setLoadingComp(null));
    }
  }, [visible]);

  return (
    <Modal
      className="bl-modal"
      visible={visible}
      title="前台菜单排序"
      onCancel={handleCancel}
      onOk={handleFinish}
      okButtonProps={{ loading: loadingComp === 'submit' }}
      maskClosable={false}
      destroyOnClose
      bodyStyle={{
        maxHeight: 'calc(100vh - 300px)',
        overflowY: 'auto',
      }}
    >
      <DraggableList
        name="menuIdList"
        withDndProvider
        withForm
        form={form}
        renderItem={({ data }) => (
          <span className={styles['sort-modal-item-title']}>
            {menuList.find((m) => m.id === data)?.menuName}
          </span>
        )}
      />
    </Modal>
  );
};

export default SortModal;
