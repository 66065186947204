export const fieldLabels = {
  code: '数采规则编号',
  name: '数采规则名称',
  autoBeginTime: '开始时间',
  autoEndTime: '结束时间',
  autoFrequency: '数采频率',
  editReason: '编辑原因',
};

export const MAX_INPUT_LENGTH = 255;
export const MAX_TEXTAREA_LENGTH = 1000;
