import { Form, Checkbox, DatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import {
  DataFormLayoutForModal,
  DataFormLayout,
  DataFormLayoutInfoBlock,
} from '@blacklake-web/layout';
import moment, { Moment } from 'moment';

import { lookup } from 'src/dict';
import { CalcRange, ProvidedRange } from 'src/dict/storeRequisition';
import LocalStorage from 'src/utils/localStorage';
import { FIELDS } from 'src/utils/constants';
import { goToIntegratePick } from '../utils';

import Styles from './index.module.scss';

interface IntegratePickConfigProps {
  visible: boolean;
  onClose: () => void;
}

const calcRangeOptions = [
  {
    label: lookup('storeRequisiton.CalcRangeMap', CalcRange.planOrder),
    value: CalcRange.planOrder,
  },
  {
    label: lookup('storeRequisiton.CalcRangeMap', CalcRange.produceOrder),
    value: CalcRange.produceOrder,
    disabled: true,
  },
];

const providedRangeOptions = [
  {
    label: lookup('storeRequisiton.ProvidedRangeMap', ProvidedRange.toReceive),
    value: ProvidedRange.toReceive,
    disabled: true,
  },
  {
    label: lookup('storeRequisiton.ProvidedRangeMap', ProvidedRange.workshopStorage),
    value: ProvidedRange.workshopStorage,
    disabled: true,
  },
];

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days before today and today
  return (
    current && (current > moment().endOf('day').add(60, 'days') || current < moment().endOf('day'))
  );
};

/** 缓存齐套设置，存入LocalStorage */
const saveIntegratePickConfig = (config: {
  pickingCalculateRange?: CalcRange[];
  suppliedCalculateRange?: ProvidedRange[];
  deadline?: Moment;
}) => {
  LocalStorage.set(FIELDS.INTEGRATE_PICK_CONFIG, config);
};

export default function IntegratePickConfig({ onClose, visible }: IntegratePickConfigProps) {
  const [configForm] = Form.useForm();
  // 从缓存中读取历史配置，设置在当前表单的initialValues里
  const savedIntegratePickConfig = LocalStorage.get(FIELDS.INTEGRATE_PICK_CONFIG);
  const { pickingCalculateRange, suppliedCalculateRange } = savedIntegratePickConfig ?? {};

  // 如果缓存中没有配置信息，使用默认值作为初始值
  const initialValues = {
    pickingCalculateRange: pickingCalculateRange ?? [CalcRange.planOrder, CalcRange.produceOrder],
    suppliedCalculateRange: suppliedCalculateRange ?? [
      ProvidedRange.toReceive,
      ProvidedRange.workshopStorage,
    ],
    deadline: moment().add(1, 'day'),
  };
  const integratePickConfigInfo: DataFormLayoutInfoBlock[] = [
    {
      items: [
        {
          label: '领料计算范围',
          name: 'pickingCalculateRange',
          render: () => <Checkbox.Group options={calcRangeOptions} />,
        },
        {
          label: '已供应计算范围',
          name: 'suppliedCalculateRange',
          render: () => <Checkbox.Group options={providedRangeOptions} />,
        },
        {
          label: '计算截止时间',
          name: 'deadline',
          render: () => <DatePicker showTime disabledDate={disabledDate} />,
        },
      ],
    },
  ];
  const submitData = async () => {
    await configForm.validateFields();
    const config = configForm.getFieldsValue();

    saveIntegratePickConfig(config);
    goToIntegratePick();
  };

  return (
    <DataFormLayoutForModal
      width={648}
      onClose={onClose}
      visible={visible}
      content={
        <DataFormLayout
          form={configForm}
          bodyClassName={Styles.integratePickConfigContainer}
          formProps={{ labelAlign: 'right', initialValues }}
          infoBlockStyleProps={{ margin: 0 }}
          info={integratePickConfigInfo}
          title="齐套领料"
          onCancel={onClose}
          onFinish={submitData}
          okText="计算并申请领料"
          footerStyle={{ justifyContent: 'end', paddingRight: 24 }}
        />
      }
    />
  );
}
