// 计划运算
export const ENGINEER_PATH = '/planned/execution';
/**
 * 计划运算日志
 * @returns
 */
export const toLogPlanningOperation = () => `${ENGINEER_PATH}/planningOperation/log`;

/**
 * 计划运算日志详情
 * @returns
 */
export const toLogDetailPlanningOperation = (id: number) =>
  `${ENGINEER_PATH}/planningOperation/log/detail/${id}`;
