import { useEffect, useState } from 'react';
import _ from 'lodash';

import { fetchSopExecTaskListRelateObjects } from 'src/api/ytt/sop-domain';
import { BlTable } from 'src/components';
import { ShowFieldObj } from 'src/page/sop/scheme/types';
import { DataColumn } from '../../type';

interface TableProps {
  controlValue: any;
}

const ControlRecordTable = (props: TableProps) => {
  const { controlValue } = props;

  const [dataSource, setdataSource] = useState<any>([]);
  const [loading, setloading] = useState(true);

  /**
   * fetch 接口
   */
  const handleFetch = async () => {
    try {
      const { data } = await fetchSopExecTaskListRelateObjects({
        ..._.omit(controlValue, 'referenceFields'),
        showField: _.get(controlValue, 'referenceFields'),
        mainField: _.get(controlValue, 'mainField'),
        objectCode: _.get(controlValue, 'objectCode'),
      });

      setdataSource(data?.list);
      setloading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };
  /**
   * columns 格式
   */
  const getColumns = () => {
    const mainField = _.get(controlValue, 'mainField');
    const referenceFields: ShowFieldObj[] = _.get(controlValue, 'referenceFields');

    const dataColumns: DataColumn[] = [
      mainField,
      ...referenceFields.filter((item) => item.fieldCode !== mainField.fieldCode),
    ].map((item) => {
      return {
        title: item.fieldName,
        dataIndex: item.fieldCode,
        key: item.fieldCode,
        width: 100,
      };
    });

    return dataColumns;
  };

  /**
   * 初始化
   */
  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <>
      <BlTable
        bordered
        useIndex
        columns={getColumns()}
        dataSource={dataSource}
        // pagination={false}
        pagination={{
          showTotal: (total) => `共 ${total} 条`,
        }}
        rowKey={(record) => record?.id}
        style={{ width: '100%' }}
        scroll={{ x: 640, y: 400 }}
        loading={loading}
      />
    </>
  );
};

export default ControlRecordTable;
