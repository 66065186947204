/**
 * 审批单状态
 */
export enum ApprovalStatus {
  /**
   * 审批中
   */
  pending,
  /**
   * 已通过
   */
  approved,
  /**
   * 已驳回
   */
  rejected,
}

/**
 * 审批任务状态
 */
export enum ApprovalTaskStatus {
  /**
   * 待审批
   */
  pending,
  /**
   * 已通过
   */
  approved,
  /**
   * 已驳回
   */
  rejected,
  /**
   * 已关闭
   */
  closed,
}

/**
 * 审批节点状态
 */
export enum ApprovalNodeStatus {
  /**
   * 未激活
   */
  waiting,
  /**
   * 已通过
   */
  approved,
  /**
   * 已驳回
   */
  rejected,
  /**
   * 审批中
   */
  pending,
}
