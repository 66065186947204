import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { fetchSourceListDetailSupplier } from 'src/api/ytt/order-domain/sourceList';
import { replaceSign } from 'src/utils/constants';

interface SupplierListProps {
  /** 货源清单ID */
  id: number;
}

const SupplierList: React.FC<SupplierListProps> = (props) => {
  const { id } = props;
  const [dataSource, setDataSource] = useState<any[]>([]);
  const columns = [
    {
      title: '供应商编号',
      with: 200,
      dataIndex: 'supplierCode',
    },
    {
      title: '供应商名称',
      with: 200,
      dataIndex: 'supplierName',
    },
    {
      title: '采购周期',
      dataIndex: 'purchaseCycle',
      render: (purchaseCycle: number) => (purchaseCycle ? purchaseCycle + '天' : replaceSign),
    },
  ];
  /**
   * 获取供应商列表
   * @param id 货源清单ID
   */
  const fetchSupplierList = async (id: number) => {
    const res = await fetchSourceListDetailSupplier({ id });
    const { data, code } = res as {
      code: number;
      data: any[];
    };

    if (code === 200) {
      setDataSource(data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSupplierList(id);
    }
  }, []);
  return <Table columns={columns} dataSource={dataSource} pagination={false} />;
};

export default SupplierList;
