import { useState } from 'react';
import { Space, Dropdown, Menu, message as Message, Form, Modal } from 'antd';
import _ from 'lodash';
import { RecordListLayout, FilterItem } from 'layout';
import {
  fetchSupplierList,
  FetchSupplierListRequest,
  fetchSupplierEnable,
  FetchSupplierEnableRequest,
  fetchSupplierDisable,
  FetchSupplierDisableRequest,
  fetchSupplierListDeregMaterial,
  // fetchSupplierDelete,
  // FetchSupplierDeleteRequest,
  // FetchSupplierDeleteResponse,
} from 'src/api/ytt/supply-domain/supplier';
import { fieldTypeList, gcObject, gcTime } from 'src/utils';
import { replaceSign } from 'src/utils/constants';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { mapLabeledValueToValue } from 'src/utils/array';
import { lookup } from 'src/dict';
import { CRUD, UsageStatus } from 'src/dict/common';
import { usageStatus as usageStatusMappings } from 'src/dict/common/mappings';
// import { showErrorListMessage } from 'src/components/message/errorMessageList';
// import { ErrorNotification } from 'src/components/notification';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
// import BLDelConfirm from '../../../share/modalConfirm';
import { renderUsageStatus } from 'src/page/share/renderUsageStatus';
import type { SupplierListItemProps, SupplierRegisterPorps, RemoveRegister } from '../interface';
import { SupplierOperateMode, SupplyRegStatus } from 'src/dict/supplier';
import {
  crud as registerStatus,
  fieldLabels,
  SupplierOperateModeTitleMap,
} from 'src/dict/supplier/mappings';
import OperateSupplierDialog from '../OperateSupplierDialog';
import SupplierDetail from '../SupplierDetail';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
// import SupplierRegister from '../RegisterRemoveModal/suppliterRegister';
import useBlModal from 'src/components/modal/useBlModal';
import SupplierRegisterFormTable from '../../components/supplierRegisterTable';
import MaterialRegisterFormTable from '../../components/materialRegisterTable';
import {
  fetchSupplierRegisterMaterialDeregister,
  fetchSupplierRegisterMaterialRegister,
  FetchSupplierRegisterMaterialRegisterRequest,
  fetchSupplierRegisterDeregister,
  fetchSupplierRegisterIsDeregister,
  FetchSupplierRegisterIsDeregisterRequest,
  fetchSupplierRegisterRegister,
} from 'src/api/ytt/supply-chain-domain/supplier/register_material';
import RemoveMaterialRegisterFormTable from '../../components/removeMaterialList';

import { BlIcon, SingleOperationLogComponent } from 'src/components';
import { TextToolTip } from '@blacklake-web/component';
import authDict, { hasAuth } from 'src/utils/auth';

// param是由antd的table发出，经由RecordListLayout封装过一次的参数，描述的是表格的变化(分页，排序，筛选，搜索信息)
// 将这些信息转换成后台分页查询列表数据的参数格式，即requestFn的传入参数应有的格式
const formatDataToQuery = (param: any): FetchSupplierListRequest => {
  const { createdAt, updatedAt, creator, operator, regStatus, owner, ...rest } = param;
  const [createdAtFrom, createdAtTo] = gcTime.formatRangeUnixToInt(createdAt);
  const [updatedAtFrom, updatedAtTo] = gcTime.formatRangeUnixToInt(updatedAt);

  const options = {
    createdAtFrom,
    createdAtTo,
    updatedAtFrom,
    updatedAtTo,
    creator: creator?.length ? _.map(creator, 'value') : null,
    operator: operator?.length ? _.map(operator, 'value') : null,
    regStatus,
    ownerIds: owner?.length ? _.map(owner, 'value') : null,
    ...rest,
  };

  return gcObject.filterEmptyProperty(options);
};

// 把param转换成{key: value}[], 显示在RecordListInfo的tag里
const formatDataToDisplay = (param: any) => {
  return param;
};

const formatDataToFormDisplay = (param: any) => {
  const { createdAt, updateAt, ...rest } = param;
  const result = {
    ...rest,
    createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
    updateAt: gcTime.formatRangeTimeToMoment(updateAt || []),
  };

  return gcObject.filterEmptyProperty(result);
};

// const errorColumns = [
// {
// title: '供应商名称',
// dataIndex: 'name',
// render: (text: string) => <a >{text}</a>,
// },
// {
// title: '失败原因',
// dataIndex: 'failReason',
// },
// ];

export default function SupplierListSpupply() {
  // 选中行的key，用以批量删除
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [selectedRows, setSelectRows] = useState<any[]>();

  // 刷新用的时间戳，在refresh方法里用
  const [refreshMarker, setRefreshMarker] = useState<number>(Date.now());
  const [mode, setMode] = useState<SupplierOperateMode>(SupplierOperateMode.hide);
  const [currentData, setCurrentData] = useState<SupplierListItemProps>();
  // const [delForm] = Form.useForm(); // 用在确认删除的弹框中，填删除原因的form上
  const openModal = useOpenExImportModal();
  const [form] = Form.useForm();
  const { show } = useBlModal();
  const dispatch = useDispatch();

  // param是由antd的table发出，经由RecordListLayout封装过一次的参数，描述的是表格的变化(分页，排序，筛选，搜索信息)
  // 将这些信息转换成后台分页查询列表数据的参数格式，即requestFn的传入参数应有的格式
  const formatDataToQuery = (param: any): FetchSupplierListRequest => {
    const { createdAt, updatedAt, creator, operator, owner, ...rest } = param;

    const params = {
      createdAtFrom: createdAt && createdAt[0] ? Date.parse(createdAt[0]) : undefined,
      createdAtTo: createdAt && createdAt[1] ? Date.parse(createdAt[1]) : undefined,
      updatedAtFrom: updatedAt && updatedAt[0] ? Date.parse(updatedAt[0]) : undefined,
      updatedAtTo: updatedAt && updatedAt[1] ? Date.parse(updatedAt[1]) : undefined,
      creatorIds: mapLabeledValueToValue(creator),
      operatorIds: mapLabeledValueToValue(operator),
      ownerIds: mapLabeledValueToValue(owner),
      ...rest,
    };

    dispatch.excelImport.updateBusinessData({ businessData: params });

    // 接口需要的创建时间和更新时间为number型的timestamp
    return params;
  };

  // 批量删除供应商，在确认删除弹窗点了确认后的回掉
  // onSuccess, onFail: batchMenu onClick回调的参数，onSuccess会刷新列表并清空批量按钮的loading状态，onFail只清空loading状态
  // idToDeleteAlone: 单选一行在操作菜单里删除供应商时传入的对应行的供应商id
  // 批量删除时，idToDeleteAlone为undefined，删 除参数是selectedRowKeys
  // const handleDelete = async (
  // onSuccess?: () => void,
  // onFail?: () => void,
  // idToDeleteAlone?: number,
  // ) => {
  // try {
  // // 从确认删除的form里得到删除原因
  // const reason = delForm.getFieldValue('delReason');
  // const idsInParam = idToDeleteAlone ? [idToDeleteAlone] : (selectedRowKeys as number[]);
  // const requestParam: FetchSupplierDeleteRequest = {
  // editReason: reason,
  // ids: idsInParam,
  // };
  // // 不明原因，ytt的FetchHandlingEquipmentDeleteResponse中data没有描述，只能强行转换成自定义类型
  // const { data }: FetchSupplierDeleteResponse = await fetchSupplierDelete(requestParam);

  // if (data) {
  // const { success, fail } = data;

  // // 全部删除成功，显示删除成功
  // if (_.isNil(fail) || _.isEmpty(fail)) {
  // Message.success('删除成功');
  // onSuccess?.();
  // } else if (_.isNil(success) || _.isEmpty(success)) {
  // // 全部删除失败，提示删除失败及原因
  // onFail?.();
  // if (fail.length > 0) {
  // ErrorNotification({
  // message: '删除失败',
  // description: (
  // <>
  // {fail.map((failItem) => (
  // <div key={failItem.id}>
  // {`${failItem.name}(${failItem.code})`}: {failItem.detail}
  // </div>
  // ))}
  // </>
  // ),
  // });
  // } else {
  // Message.error('删除失败！');
  // }
  // } else if (fail.length > 0) {
  // onSuccess?.();
  // // 否则用表格显示删除结果及原因统计的table
  // showErrorListMessage({
  // title: '删除出现错误',
  // data: fail,
  // columns: errorColumns,
  // operateName: '删除',
  // successCount: success.length,
  // failCount: fail.length,
  // });
  // }
  // // 删除成功以后清空删除表单里已经填的删除原因
  // delForm.resetFields();
  // } else {
  // onFail?.();
  // console.log('api返回的response里没有data');
  // }
  // } catch (error) {
  // onFail?.();
  // console.log(error);
  // }
  // };

  const mainMenu = _.compact([
    {
      title: '导出',
      showExport: true,
      auth: authDict.supplier_export,
      icon: <BlIcon type="icondaochu" />,
      onClick: () =>
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.supplier,
        }),
    },
    {
      title: '导出', // 供应链-供应商
      showExport: true,
      auth: authDict.supplier_scm_export,
      icon: <BlIcon type="icondaochu" />,
      onClick: () =>
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.supplierSCM,
        }),
    },
    {
      title: SupplierOperateModeTitleMap[SupplierOperateMode.create],
      auth: authDict.supplier_add,
      showExport: true,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        setMode(SupplierOperateMode.create);
      },
      items: _.compact([
        {
          title: '导入',
          showExport: true,
          auth: authDict.supplier_import,
          onClick: () =>
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.supplier,
            }),
        },
        {
          title: '导入', // 供应链-供应商
          auth: authDict.supplier_scm_import,
          showExport: true,
          onClick: () =>
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.supplierSCM,
            }),
        },
      ]),
    },
  ]);

  const handleShowSupplierRegister = (
    isSingleSupResgister: boolean,
    onSuccess?: () => void,
    onFail?: () => void,
    record?: any,
  ) => {
    show({
      title: '邀请注册供应商',
      content: (
        <SupplierRegisterFormTable
          selectedRows={
            isSingleSupResgister
              ? [
                  {
                    code: record?.code,
                    name: record?.name,
                  },
                ]
              : selectedRows
          }
          form={form}
        />
      ),
      width: 800,
      onOk: async () => {
        const supplierRegisterProps: SupplierRegisterPorps[] = form
          .getFieldValue('supplierRegister')
          .map((node: SupplierRegisterPorps) => {
            return {
              code: node.code,
              email: node.email,
              supplierOrgCode: node.supplierOrgId,
            };
          });
        const response = await fetchSupplierRegisterRegister(supplierRegisterProps);

        if (response && response.code === 200) {
          Message.success('邀请注册供应商成功');
          onSuccess?.();
          refresh();
        } else {
          Message.error(response.message);
        }
      },
      onCancel: async () => {
        onFail?.();
      },
      closable: false,
      afterClose: async () => {
        onFail?.();
      },
    });
  };

  const RemoveSupplierReg = async (
    judegRemoveSupplierList: number[],
    onSuccess?: () => void,
    onFail?: () => void,
  ) => {
    try {
      const res = await fetchSupplierRegisterDeregister(judegRemoveSupplierList);

      if (res && res.code === 200) {
        Message.success('解除注册供应商成功');
      } else {
        Message.error(res.message);
      }
      onFail?.();
      refresh();
    } catch (error) {
      onSuccess?.();
      refresh();
    }
  };

  const RemoveMaterialReg = async (onSuccess?: () => void, onFail?: () => void) => {
    const removeListProps: number[] = [];
    const removeProps = form.getFieldValue('removeMaterialRegister').map((node: any) => {
      return removeListProps.push(node.itemId);
    });

    const removeResProps = {
      ids: removeListProps,
    };

    const res = await fetchSupplierRegisterMaterialDeregister(removeResProps);

    if (res && res.data?.failCount === 0) {
      Message.success('解除注册物料成功');
    } else {
      Message.error('有采购订单/收货单/采购退货单未结束，解除注册物料失败');
    }
    onSuccess?.();
    refresh();
  };

  const judegRemoveSupplierReg = async (
    isSingleSupResgister: boolean,
    onSuccess?: () => void,
    onFail?: () => void,
    record?: any,
  ) => {
    try {
      form.resetFields();
      const judegRemoveSupplierList: number[] = [];

      if (selectedRows && selectedRows?.length > 0) {
        for (const value of selectedRows) {
          if (value.regStatus !== SupplyRegStatus.registered) {
            Message.error('供应商注册状态为已注册时才可进行解除');
            onFail?.();
            return;
          }
          judegRemoveSupplierList.push(value.id);
        }
      } else {
        judegRemoveSupplierList.push(record.id);
      }
      const judegRemoveSupplierProps: FetchSupplierRegisterIsDeregisterRequest = {
        supplierIds: judegRemoveSupplierList,
      };

      const response = await fetchSupplierRegisterIsDeregister(judegRemoveSupplierProps);

      if (response.data?.failCount == 0) {
        Modal.confirm({
          title: '解除注册供应商',
          content: <div>{'确定解除注册的供应商吗'}</div>,
          visible: true,
          onOk() {
            RemoveSupplierReg(judegRemoveSupplierList, onSuccess, onFail);
          },
          onCancel() {
            onFail?.();
          },
          okText: '解除',
          cancelText: '暂不解除',
        });
      }
    } catch (error) {
      console.log(error);
      onFail?.();
    }
  };

  const judegRemoveMaterialReg = async (
    onSuccess?: () => void,
    onFail?: () => void,
    record?: any,
  ) => {
    try {
      form.resetFields();
      const judegRemoveMaterialList: number[] = [];

      if (selectedRows && selectedRows?.length > 0) {
        for (const value of selectedRows) {
          judegRemoveMaterialList.push(value.id);
        }
      } else {
        judegRemoveMaterialList.push(record.id);
      }

      const { data } = await fetchSupplierListDeregMaterial(judegRemoveMaterialList);

      const removeRegister = data?.map((node: RemoveRegister) => {
        return {
          code: node.code,
          name: node.name,
          materialCode: node.materialCode,
          materialName: node.materialName,
          materialAttr: node.materialAttr,
          materialAmount: node.materialAmount?.amount,
          materialUnitId: node.materialAmount?.unitId,
          materialUnit: node.materialAmount?.unitName,
          regStatus: node.regStatus,
          supMaterialCode: node.supMaterialCode,
          supMaterialName: node.supMaterialName,
          supMaterialAmount: node.supMaterialAmount?.amount,
          supMaterialUnit: node.supMaterialAmount?.unitName,
          materialId: node.materialId,
          supplierId: node.id,
          itemId: node.itemId,
        };
      });

      form.setFieldsValue({ removeMaterialRegister: removeRegister });

      Modal.confirm({
        title: '解除注册物料',
        width: 800,
        content: <RemoveMaterialRegisterFormTable form={form} />,
        visible: true,
        onOk() {
          RemoveMaterialReg(onSuccess, onFail);
        },
        onCancel() {
          onFail?.();
        },
        okText: '解除',
        cancelText: '暂不解除',
      });
    } catch (error) {
      console.log(error);
      onSuccess?.();
      refresh();
    }
  };

  const handleShowMaterailRegister = (
    isSingleSupResgister: boolean,
    onSuccess?: () => void,
    onFail?: () => void,
    record?: any,
  ) => {
    show({
      title: '邀请注册物料',
      content: (
        <MaterialRegisterFormTable
          selectedRows={isSingleSupResgister ? [record] : selectedRows}
          form={form}
        />
      ),
      width: 1080,
      onOk: async () => {
        interface register {
          amount: number;
          id?: number | undefined;
          materialId: number;
          supplierId: number;
          unitId: number;
        }

        const registerMaterial: register[] = form
          .getFieldValue('materialRegister')
          .map((node: any) => {
            return {
              amount: parseInt(node.amount),
              materialId: node.materialsId,
              supplierId: node.suppliersId,
              unitId: node.unitId,
            };
          });
        const registerMaterialListProps: FetchSupplierRegisterMaterialRegisterRequest = {
          registerMaterialList: registerMaterial,
        };

        const response = await fetchSupplierRegisterMaterialRegister(registerMaterialListProps);

        if (response && response.code === 200) {
          Message.success('邀请注册物料成功');
          onSuccess?.();
        } else {
          Message.error(response.message);
        }
      },
      onCancel: async () => {
        onFail?.();
      },
      closable: false,
      afterClose: async () => {
        onFail?.();
      },
    });
  };

  const batchMenu = _.compact([
    {
      title: lookup('changeStatusAction', UsageStatus.disabled),
      auth: authDict.supplier_enable_disable,
      // onSuccess, onFail: batchMenu onClick回调的参数，onSuccess会刷新列表并清空批量按钮的loading状态，onFail只清空loading状态
      onClick: (onSuccess?: () => void, onFail?: () => void) => {
        enableOrDisableSupplier(
          selectedRowKeys.map((rowKey) => Number(rowKey)),
          UsageStatus.disabled,
          onSuccess,
          onFail,
        );
      },
    },
    {
      title: lookup('changeStatusAction', UsageStatus.enabled),
      auth: authDict.supplier_enable_disable,
      // onSuccess, onFail: batchMenu onClick回调的参数，onSuccess会刷新列表并清空批量按钮的loading状态，onFail只清空loading状态
      onClick: (onSuccess?: () => void, onFail?: () => void) => {
        enableOrDisableSupplier(
          selectedRowKeys.map((rowKey) => Number(rowKey)),
          UsageStatus.enabled,
          onSuccess,
          onFail,
        );
      },
    },
    {
      title: '邀请注册供应商',
      auth: authDict.supplier_register_supplier,
      onClick: (onSuccess?: () => void, onFail?: () => void) => {
        handleShowSupplierRegister(false, onSuccess, onFail);
      },
    },
    {
      title: '解除注册供应商',
      auth: authDict.supplier_remove_register_supplier,
      onClick: (onSuccess?: () => void, onFail?: () => void) => {
        judegRemoveSupplierReg(false, onSuccess, onFail);
      },
    },
    {
      title: '邀请注册物料',
      auth: authDict.supplier_register_material,
      onClick: (onSuccess?: () => void, onFail?: () => void) => {
        handleShowMaterailRegister(false, onSuccess, onFail);
      },
    },
    {
      title: '解除注册物料',
      auth: authDict.supplier_remove_register_material,
      onClick: (onSuccess?: () => void, onFail?: () => void) => {
        judegRemoveMaterialReg(onSuccess, onFail);
      },
    },
    // {
    // title: lookup('common.crud', CRUD.delete),
    // // onSuccess, onFail: batchMenu onClick回调的参数，onSuccess会刷新列表并清空批量按钮的loading状态，onFail只清空loading状态
    // onClick: (onSuccess?: () => void, onFail?: () => void) => {
    // BLDelConfirm('你确定要删除吗？', delForm, () => {
    // handleDelete(onSuccess, onFail);
    // });
    // },
    // },
  ]);

  const enableOrDisableSupplier = async (
    ids: Array<number>,
    enableFlag: UsageStatus,
    onSuccess?: () => void,
    onFail?: () => void,
  ) => {
    const requestParam: FetchSupplierEnableRequest | FetchSupplierDisableRequest = { ids };
    const requestFn = enableFlag ? fetchSupplierDisable : fetchSupplierEnable;
    const response = await requestFn(requestParam);

    if (response && response.code === 200) {
      Message.success(enableFlag ? '停用成功' : '启用成功');
      onSuccess?.();
      refresh();
    } else {
      Message.error(response.message);
      onFail?.();
    }
  };

  const columns = [
    {
      title: fieldLabels.supplierCode,
      dataIndex: 'code',
      width: 150,
      fixed: 'left',
      sorter: true,
      isFilter: true,
      type: fieldTypeList.text,
      render: (text: string, record: SupplierListItemProps, index: number, config: any) => {
        return hasAuth(authDict.supplier_detail) ? (
          <a
            onClick={() => {
              setCurrentData(record);
              setMode(SupplierOperateMode.view);
            }}
          >
            <TextToolTip text={text} width={config.contentWidth} />
          </a>
        ) : (
          <TextToolTip text={text} width={config.contentWidth} />
        );
      },
    },
    {
      title: fieldLabels.supplierName,
      dataIndex: 'name',
      width: 150,
      fixed: 'left',
      sorter: true,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: fieldLabels.supplierStatus,
      dataIndex: 'status',
      width: 120,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: usageStatusMappings,
        mode: 'multiple',
      },
      render: renderUsageStatus,
    },
    {
      title: fieldLabels.supplierOwner,
      dataIndex: 'owner',
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
      render: (_text: string, record: SupplierListItemProps) => {
        return record.owner && record.owner.name ? record.owner.name : replaceSign;
      },
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: fieldLabels.supplierRegStatus,
      dataIndex: 'regStatus',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: registerStatus,
        mode: 'multiple',
      },
      render: (_text: string, record: SupplierListItemProps) => {
        return lookup('supplyRegStatus.crud', record.regStatus);
      },
    },
    {
      title: fieldLabels.regEnterprise,
      dataIndex: 'supplierOrg',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: SupplierListItemProps) => {
        return record.supplierOrg && record.supplierOrg.orgName
          ? record.supplierOrg.orgName
          : replaceSign;
      },
    },
    // {
    // title: fieldLabels.regEnterprise,
    // dataIndex: '',
    // width: 150,
    // fixed: 'left',
    // type: fieldTypeList.text,
    // },
    {
      title: fieldLabels.creator,
      dataIndex: 'creator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (_text: string, record: SupplierListItemProps) => {
        return record.creator && record.creator.id ? (
          <AvatarDisplay
            id={record.creator.id}
            name={record.creator.name!}
            avatarUrl={record.creator.avatarUrl}
            key={record.creator.id}
            isShowTag={false}
            isUser
          />
        ) : (
          replaceSign
        );
      },
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: fieldLabels.createdAt,
      dataIndex: 'createdAt',
      width: 180,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: fieldLabels.operator,
      dataIndex: 'operator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (_text: string, record: SupplierListItemProps) => {
        return record.operator && record.operator.id ? (
          <AvatarDisplay
            id={record.operator.id}
            name={record.operator.name!}
            avatarUrl={record.operator.avatarUrl}
            key={record.operator.id}
            isShowTag={false}
            isUser
          />
        ) : (
          replaceSign
        );
      },
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: fieldLabels.updatedAt,
      dataIndex: 'updatedAt',
      width: 180,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
  ];
  const getOperationList = (record: SupplierListItemProps) => {
    return [
      {
        title: lookup('common.crud', CRUD.edit),
        auth: authDict.supplier_edit,
        onClick: () => {
          setCurrentData(record);
          setMode(SupplierOperateMode.edit);
        },
      },
      {
        title: lookup('changeStatusAction', record.status),
        auth: authDict.supplier_enable_disable,
        onClick: () => {
          enableOrDisableSupplier([record.id!], record.status!);
        },
      },
      {
        title: lookup('common.crud', CRUD.view),
        auth: authDict.supplier_detail,
        onClick: () => {
          setCurrentData(record);
          setMode(SupplierOperateMode.view);
        },
      },
      {
        title: '邀请注册供应商',
        auth: authDict.supplier_register_supplier,
        disabled: record.regStatus !== SupplyRegStatus.unregistered,
        onClick: () => {
          handleShowSupplierRegister(
            true,
            () => {},
            () => {},
            record,
          );
        },
      },
      {
        title: '解除注册供应商',
        auth: authDict.supplier_remove_register_supplier,
        disabled: record.regStatus !== SupplyRegStatus.registered,
        onClick: () => {
          judegRemoveSupplierReg(
            true,
            () => {},
            () => {},
            record,
          );
        },
      },
      {
        title: '邀请注册物料',
        auth: authDict.supplier_register_material,
        disabled: record.regStatus !== SupplyRegStatus.registered,
        onClick: () => {
          handleShowMaterailRegister(
            true,
            () => {},
            () => {},
            record,
          );
        },
      },
      {
        title: '解除注册物料',
        auth: authDict.supplier_remove_register_material,
        onClick: () => {
          judegRemoveMaterialReg(
            () => {},
            () => {},
            record,
          );
        },
      },
      {
        title: '操作记录',
        auth: authDict.supplier_operrecord,
        onClick: () => {
          setCurrentData(record);
          setMode(SupplierOperateMode.log);
        },
      },
      // {
      //   title: lookup('crud', CRUD.view),
      //   onClick: () => {
      //     BLDelConfirm('你确定要删除吗？', delForm, () => {
      //       handleDelete(refresh, undefined, record.id);
      //     });
      //   },
      // },
    ];
  };

  const filterList: FilterItem[] = columns
    .filter((i) => i.isFilter)
    .map((column) => {
      return _.pick(
        {
          ...column,
          label: column.title,
          name: column.dataIndex,
          type: column.type,
        },
        [
          'label',
          'name',
          'type',
          'rules',
          'renderItem',
          'selectProps',
          'inputProps',
          'datePickerProps',
          'dateFormat',
        ],
      ) as FilterItem;
    });

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  const refreshDetailStatus = () => {
    refresh();
    if (currentData) {
      setCurrentData({
        ...currentData,
        status: Number(!currentData.status) as UsageStatus,
      });
    }
  };

  const switchToEditMode = () => {
    setMode(SupplierOperateMode.edit);
  };

  return (
    <>
      <RecordListLayout<SupplierListItemProps>
        refreshMarker={refreshMarker}
        filterList={filterList}
        requestFn={(params) => fetchSupplierList(params, { legacy: true })}
        placeholder="请输入供应商编号、供应商名称"
        batchMenu={batchMenu}
        mainMenu={mainMenu}
        getOperationList={getOperationList}
        rowKey="id"
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectedKeys: any, selectRows?: any[]) => {
          setSelectedRowKeys(selectedKeys);
          setSelectRows(selectRows);
        }}
        columns={columns}
        formatDataToDisplay={formatDataToDisplay}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
      />
      {mode === SupplierOperateMode.create || mode === SupplierOperateMode.edit ? (
        <OperateSupplierDialog
          operateMode={mode}
          supplierData={currentData}
          onClose={(needListRefresh: boolean) => {
            setCurrentData(undefined);
            setMode(SupplierOperateMode.hide);
            if (needListRefresh) refresh();
          }}
        />
      ) : null}
      {mode === SupplierOperateMode.view && currentData ? (
        <SupplierDetail
          supplierData={currentData}
          onClose={() => {
            setCurrentData(undefined);
            setMode(SupplierOperateMode.hide);
          }}
          refresh={refreshDetailStatus}
          goToEdit={switchToEditMode}
        />
      ) : null}
      {mode === SupplierOperateMode.log && currentData ? (
        <SingleOperationLogComponent
          visible={mode === SupplierOperateMode.log}
          instanceId={currentData.id!}
          objectCode={'Supplier'} // 对象在对象平台唯一定义的objectCode
          closeDetail={() => {
            setCurrentData(undefined);
            setMode(SupplierOperateMode.hide);
          }}
        />
      ) : null}
    </>
  );
}
