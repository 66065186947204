import React from 'react';
import type { FC } from 'react';
import { Form, Input, Select, Button, message } from 'antd';
import type { FormInstance, SelectProps } from 'antd';

import { FormListProps } from 'antd/lib/form';
import { BlIcon } from 'src/components';

import { GroupTypesEnum, BIZTYPE_MAP, GRPUPTYPE_MAP } from './constants';
import UserTable from './optionalResources/userTable';
import { workCenterGroupCOListDisableList } from '../constants';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { isNil, max } from 'lodash';
import { validatePositiveInteger } from 'src/page/knowledgeManagement/share/warehouseRules';
import _Array from 'src/utils/dataTypes/array';
import { NamePath } from 'antd/es/form/interface';

// 最大添加数量
const MAX_SUPPORTED_ITEM = 10;

const { Option } = Select;

// 资源分类选项
const OPTIONS = [
  {
    label: '用户',
    key: GroupTypesEnum.USER,
  },
  {
    label: '部门',
    key: GroupTypesEnum.DEPARTMENT,
  },
  {
    label: '设备',
    key: GroupTypesEnum.GROUPTYPE,
  },
];

// 用户可选资源类型
const USER_OPTIONS: SelectProps<number>['options'] = [
  {
    label: '指定用户',
    value: GroupTypesEnum.USER,
  },
  {
    label: '指定部门',
    value: GroupTypesEnum.DEPARTMENT,
  },
];

interface Props {
  form: FormInstance;
  filedName: FormListProps['name'];
  onlySelectBiz?: boolean; // 只可编辑可选资源 不可删除 不可添加
}

const ResourceGroup: FC<Props> = (props) => {
  const { filedName, form: formInst, onlySelectBiz = false } = props;

  const baseFieldName =
    typeof filedName === 'string' || typeof filedName === 'number' ? [filedName] : filedName;

  /**
   * 可选资源展示组建
   * @param componmentFieldName
   * @param  groupType 资源分组
   * @param bizType 资源分组为用户时所选项
   * @returns Componment
   */
  const optionalResources = (
    componentFieldName: Props['filedName'],
    groupType: number,
    bizType: number,
  ) => {
    if (groupType === GroupTypesEnum.USER) {
      const Component = BIZTYPE_MAP.get(bizType) || UserTable;

      return <Component form={formInst} filedName={componentFieldName} />;
    }

    const Component = GRPUPTYPE_MAP.get(groupType) || UserTable;

    return <Component form={formInst} filedName={componentFieldName} />;
  };

  const scrollToBottom = () => {
    const wrapper = document.getElementById('attrTable');

    if (wrapper) {
      //   const dom = wrapper.getElementsByClassName('ant-table-body')[0];

      // if (dom) {
      wrapper.scrollTop = wrapper.scrollHeight;
      // }
    }
  };

  // 校验重复
  const validateRepeat = (recordForm: any, filedName: NamePath, validateName: string) => {
    return (_rule: any, value: any) => {
      return new Promise((resolve, reject) => {
        if (!value) {
          return resolve(true);
        }
        const valueList = recordForm.getFieldValue(filedName);

        // 从 valueList 找出重复的项组成 []
        const list = valueList.map((el: any) => String(el?.[validateName]));

        const repeatList = _Array.findDuplicates(list);

        // value 如果存在 在重复项 [] 中，则 reject
        const condition = repeatList.indexOf(value);

        if (condition !== -1) {
          reject('已存在该选项!');
          return;
        }
        return resolve(true);
      });
    };
  };

  const getDefaultValue = (field: FormListFieldData, fieldStr: string, defaultValue: any) => {
    const hasValue = !isNil(formInst.getFieldValue([...baseFieldName, field.name, fieldStr]));

    if (hasValue) return undefined;

    return defaultValue;
  };

  return (
    <Form.Item>
      <Form.List name={baseFieldName}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field) => {
                // 工作中心预设前三组不可删除
                const disableItem =
                  workCenterGroupCOListDisableList.indexOf(Number(field?.name)) !== -1 ||
                  onlySelectBiz;

                return (
                  <div
                    style={{
                      borderStyle: 'dashed',
                      borderColor: '#d9d9d9',
                      padding: 20,
                      marginBottom: 20,
                      position: 'relative',
                    }}
                  >
                    {!disableItem && (
                      <BlIcon
                        type="iconlieshanchu"
                        style={{ color: 'red', position: 'absolute', left: -7, top: '45%' }}
                        onClick={() => {
                          remove(field?.name);
                        }}
                      />
                    )}

                    <div
                      style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
                    >
                      <Form.Item
                        fieldKey={[field.fieldKey, 'lineNo']}
                        name={[field.name, 'lineNo']}
                        label={'行号'}
                        style={{ width: '28%', marginBottom: '0' }}
                        initialValue={getDefaultValue(field, 'lineNo', 1)}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          { required: true, message: '行号必填' },
                          { validator: validateRepeat(formInst, filedName, 'lineNo') },
                          { validator: validatePositiveInteger() },
                        ]}
                      >
                        <Input disabled={disableItem} />
                      </Form.Item>

                      <Form.Item
                        style={{ width: '40%', marginBottom: '0' }}
                        label={'资源组分类'}
                        colon={false}
                      >
                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                          <Form.Item
                            fieldKey={[field.fieldKey, 'groupType']}
                            name={[field.name, 'groupType']}
                            style={{ flex: 1 }}
                            rules={[{ required: true, message: '资源组分类必填' }]}
                          >
                            <Select
                              showArrow
                              onChange={(val) => {
                                if (val === GroupTypesEnum.USER) {
                                  formInst.setFields([
                                    {
                                      name: [...baseFieldName, field.name, 'bizType'],
                                      value: GroupTypesEnum.USER,
                                    },
                                  ]);
                                }
                                formInst.setFields([
                                  {
                                    name: [...baseFieldName, field.name, 'bizList'],
                                    value: undefined,
                                  },
                                ]);
                              }}
                              style={{ width: '100%' }}
                              disabled={disableItem}
                            >
                              {OPTIONS.map(({ label, key }) => (
                                <Option key={key} value={key}>
                                  {label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            dependencies={[[...baseFieldName, field.name, 'groupType']]}
                            style={{ flex: 1 }}
                          >
                            {({ getFieldValue }) => {
                              return (
                                <Form.Item
                                  name={[field.name, 'bizType']}
                                  fieldKey={[field.fieldKey, 'bizType']}
                                  style={{ marginBottom: 0, marginLeft: 10 }}
                                  hidden={
                                    getFieldValue([
                                      ...baseFieldName,
                                      field.fieldKey,
                                      'groupType',
                                    ]) !== GroupTypesEnum.USER
                                  }
                                  rules={[
                                    {
                                      required:
                                        getFieldValue([
                                          ...baseFieldName,
                                          field.fieldKey,
                                          'groupType',
                                        ]) === GroupTypesEnum.USER,
                                      message: '资源组分类必填',
                                    },
                                  ]}
                                >
                                  <Select
                                    allowClear
                                    showArrow
                                    placeholder={'请选择'}
                                    onChange={() => {
                                      formInst.setFields([
                                        {
                                          name: [...baseFieldName, field.name, 'bizList'],
                                          value: undefined,
                                        },
                                      ]);
                                    }}
                                  >
                                    {USER_OPTIONS.map(({ label, value }) => (
                                      <Option key={value} value={value}>
                                        {label}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        </div>
                      </Form.Item>
                      <Form.Item
                        fieldKey={[field.fieldKey, 'name']}
                        name={[field.fieldKey, 'name']}
                        label={'资源组名称'}
                        style={{ width: '28%', marginBottom: '0' }}
                        rules={[{ required: true, message: '资源组名称必填' }]}
                      >
                        <Input disabled={disableItem} />
                      </Form.Item>
                    </div>
                    <Form.Item
                      label={'可选资源'}
                      dependencies={[
                        [...baseFieldName, field.name, 'groupType'],
                        [...baseFieldName, field.name, 'bizType'],
                      ]}
                    >
                      {({ getFieldValue }) => {
                        const groupType = getFieldValue([
                          ...baseFieldName,
                          field.fieldKey,
                          'groupType',
                        ]);
                        const bizType = getFieldValue([
                          ...baseFieldName,
                          field.fieldKey,
                          'bizType',
                        ]);

                        return (
                          <Form.Item style={{ width: '100%', marginBottom: '0' }}>
                            {optionalResources(
                              [...baseFieldName, field.name, 'bizList'],
                              groupType,
                              bizType,
                            )}
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </div>
                );
              })}
              {!onlySelectBiz && (
                <div style={{ width: '100%' }}>
                  <Button
                    type="dashed"
                    block
                    style={{ width: '100%' }}
                    onClick={() => {
                      // add();
                      const elements = formInst.getFieldValue(baseFieldName) ?? {};

                      if (elements?.length >= MAX_SUPPORTED_ITEM) {
                        message.error(`最多只能添加${MAX_SUPPORTED_ITEM}项`);
                        return;
                      }

                      const list = elements.map((el: any) => Number(el?.lineNo));

                      const num = Number(max(list)) + 1;

                      const newLine = elements.push({
                        lineNo: num,
                      });

                      formInst.setFieldsValue({ filedName: newLine });
                      setTimeout(() => {
                        scrollToBottom();
                      }, 300);
                    }}
                    // disabled={isOveredMaxCount}
                    icon={<BlIcon type="iconxinjiantianjia" />}
                  >
                    添加一行
                  </Button>
                </div>
              )}
            </>
          );
        }}
      </Form.List>
    </Form.Item>
  );
};

export default ResourceGroup;
