import { DataFormLayoutForModal } from 'src/layout';
import { useState, useEffect, FC } from 'react';
import AlternativeMaterialsForm from './alternativeMaterials';
import _, { get } from 'lodash';
import { Button } from 'antd';
import { formatMaterialToApi } from 'src/page/knowledgeManagement/engineerData/bom/utils';

interface Props {
  form: any;
  field: any;
  name: any;
}

const AlternativeMaterialsWrap: FC<Props> = (props: any) => {
  const [materialFeedingControl, setMaterialFeedingControl] = useState<any>(); // 设置替代物料,存的当前子项物料行的field信息

  const { field, isSplitProcessInput, name, form } = props;

  const { getFieldValue, setFields } = form;

  const [paramsData, setParamsData] = useState<any>(get(getFieldValue([name]), field?.name)); // props信息

  /**
   * 处理 替代料变动 变动
   * @param value
   */
  const handleAlternativeMaterialsChange = (value?: any) => {
    if (field && !_.isNil(value) && !_.isEmpty(value)) {
      setFields([
        {
          name: [name, field.name, 'workOrderAlternativePlan'],
          value,
        },
        {
          name: [name, field.name, 'useRatio'],
          value: value?.originalAlternativeMaterials?.[0]?.useRatio,
        },
      ]);
    }
    setMaterialFeedingControl(undefined);
  };

  useEffect(() => {
    const recordLine = get(getFieldValue([name]), field?.name);
    const mainPlannedAmount = getFieldValue(['bomOutputMaterials', 0, 'plannedAmount']);

    const {
      inputAmountDenominator,
      inputAmountNumerator,
      unitId,
      lossRate,
      workOrderAlternativePlan,
      materialId,
      useRatio,
    } = recordLine || {};

    // 批量请求
    const calculateData = {
      // 用料行信息
      inputAmountDenominator,
      inputAmountNumerator,
      inputUnitId: unitId,
      lossRate,
      // 主产出信息
      mainPlannedAmount,
      // 被替代料信息
      // 一期数量分子 分母为1且不可修改
      originalAlternativeMaterialAmountDenominator: 1,
      originalAlternativeMaterialAmountNumerator: 1,
      originalAlternativeMaterialId: workOrderAlternativePlan?.originalAlternativeMaterials?.[0]
        ?.materialId
        ? formatMaterialToApi(
            workOrderAlternativePlan?.originalAlternativeMaterials?.[0]?.materialId,
          )
        : formatMaterialToApi(materialId),
      originalAlternativeMaterialUnitId:
        workOrderAlternativePlan?.originalAlternativeMaterials?.[0]?.unitId ?? unitId,
      useRatio: workOrderAlternativePlan?.originalAlternativeMaterials?.[0]?.useRatio ?? useRatio,
    };

    setParamsData({ ...recordLine, calculateData, mainPlannedAmount });
  }, [props]);

  return (
    <>
      <Button
        type="link"
        style={{ padding: 0 }}
        onClick={() => {
          setMaterialFeedingControl(true);
        }}
        disabled={isSplitProcessInput}
      >
        设置
      </Button>
      <DataFormLayoutForModal
        visible={materialFeedingControl}
        onClose={() => {
          setMaterialFeedingControl(undefined);
        }}
        content={
          <AlternativeMaterialsForm
            params={paramsData}
            onChange={handleAlternativeMaterialsChange}
            name={'workOrderAlternativePlan'}
          />
        }
      />
    </>
  );
};

export default AlternativeMaterialsWrap;
