import _ from 'lodash';
import { NEW_STEP_NUMBER_ID } from '../constants';
import { StepOverviewList, StepTreeData } from '../types';
import { getById } from './stepUtils';

export const formatTree = (steps: StepOverviewList, ancestorCounts?: number) => {
  const resArr: StepTreeData[] = [];

  if (_.isArray(steps)) {
    for (const step of steps) {
      resArr.push(formatItem(step, ancestorCounts));
    }
  }

  return resArr;
};

const formatItem = (item: any, ancestorCounts = 0) => {
  const res: StepTreeData = {
    ...item,
    ...{
      title: item.name,
      key: item.id,
      referenceId: item.referenceId,
      stepId: item.id,
      isGroup: item.type,
      children: item?.children,
      ancestorCounts,
    },
  };

  res.children = formatTree(item.children, ++ancestorCounts);
  return res;
};

/** 插入节点 */
export function insertNewTreeNode(
  steps: StepTreeData[],
  parentId: number,
  prevId: number,
  type: number,
  title: string,
  referenceId: number,
) {
  const newSteps = steps.slice();

  let parent = null;

  if (parentId) {
    parent = getById(newSteps, parentId);
  }

  if (!parent || parent === 'root' || typeof parent === 'string') {
    parent = {
      id: 0,
      children: newSteps,
    };
  }

  let siblings: StepTreeData[];

  if (parent.children) {
    siblings = parent.children as StepTreeData[];
  } else {
    siblings = [];
  }

  let insertIndex = 0;

  if (!prevId) {
    if (siblings.length) {
      prevId = siblings[siblings.length - 1].id ?? 0;
    } else {
      prevId = 0;
    }
    insertIndex = siblings.length;
  } else {
    for (const item of siblings) {
      insertIndex++;
      if (item.id == prevId) {
        break;
      }
    }
  }

  const newNode: any = {
    title,
    key: NEW_STEP_NUMBER_ID,
    id: NEW_STEP_NUMBER_ID,
    name: title,
    stepId: 0,
    isGroup: type,
    type,
    children: [],
    prevId,
    parentId,
    referenceId,
  };

  siblings.splice(insertIndex, 0, newNode);
  parent.children = siblings;

  return { newSteps, newNode };
}

export function delTreeNode(steps: StepTreeData[], id: number) {
  const newArr = [];

  for (let i = 0; i < steps.length; i++) {
    const item = steps[i];

    if (item.id === id) {
      steps.splice(i--, 1);
    } else {
      if (item.children) {
        item.children = delTreeNode(item.children as StepTreeData[], id);
      }
      newArr.push(item);
    }
  }
  return newArr;
}
