import React, { useState } from 'react';
import { Form, Checkbox, message as Message, Modal } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import CreateAndEditBaseInfo from './createAndEditBaseInfo';
import { toDetail, toList } from '../navigation';
import { formatItemsValue } from '../utils';
import { ShipmentNoteForm } from '../interface';
import { fetchShipmentNoteCreate, fetchShipmentNoteUpdate } from 'src/api/ytt/order-domain/invoice';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { primary } from 'src/styles/color';
import { formatCustomFieldsInData } from 'src/components/customField';
interface OperateInvoiceOrderProps {
  id?: string;
}

const CreateAndEditInvoiceOrder = (props: RouteComponentProps<OperateInvoiceOrderProps>) => {
  const { history, match } = props;

  const [form] = Form.useForm();
  const [keepCreate, setKeepCreate] = useState(false);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [orderEditId, setOrderEditId] = useState<number>(Number(match.params.id));
  const lastPath = history.location.pathname.split('/').pop();
  const isCreating = lastPath?.startsWith('create') || false;
  const dialogTitle = isCreating ? '新建发货单' : '编辑发货单';
  const extra = (
    <Checkbox
      onChange={(e) => {
        setKeepCreate(e?.target?.checked);
      }}
    >
      连续新建
    </Checkbox>
  );

  const handleFinish = async (
    setLoading: (loading: boolean) => void,
    customFields: any,
    itemCustomFields: any,
  ) => {
    try {
      const value: ShipmentNoteForm = await form?.validateFields();
      const items = formatItemsValue(value, isCreating, itemCustomFields);
      const formatValue = formatCustomFieldsInData({ data: value, customFields });

      setLoading(true);
      const params = isCreating
        ? {
            code: value.invoiceCode,
            items,
            remark: value.remark,
            origin: value.invoiceType,
          }
        : {
            id: orderEditId,
            code: value.invoiceCode,
            items,
            remark: value.remark,
            editReason: value.editReason,
            origin: value.invoiceType,
          };

      delete formatValue.materials;
      delete formatValue.invoiceType;
      const { code, data } = isCreating
        ? await fetchShipmentNoteCreate({ ...formatValue, ...params })
        : await fetchShipmentNoteUpdate({ ...formatValue, ...params });

      setLoading(false);
      if (code && code == 200) {
        setOrderEditId(data?.id!);

        Message.success(isCreating ? '创建成功' : '编辑成功');
        if (keepCreate) {
          form.resetFields();
          form.setFieldsValue({
            materials: [],
          });
          setRefreshMarker(Date.now());
          return;
        }
        if (data?.code) {
          Modal.confirm({
            title: `发货单${isCreating ? '创建成功' : '编辑成功'}`,
            content: <div>{`发货单编号${data.code}`}</div>,
            visible: true,
            onOk() {
              history.push(toList());
            },
            onCancel() {
              history.push(toDetail(data.id as number));
            },
            okText: '返回发货单列表',
            cancelText: '查看发货单详情',
            icon: <CheckCircleTwoTone twoToneColor={primary} />,
          });
        } else {
          history.goBack();
        }
      }
    } catch (error) {
      setLoading(false);
      console.log('error: ', error);
    }
  };

  return (
    <CreateAndEditBaseInfo
      isCreating={isCreating}
      title={dialogTitle}
      onCancel={() => {
        history.goBack();
      }}
      orderEditId={orderEditId}
      onFinish={handleFinish}
      form={form}
      extra={isCreating ? extra : undefined}
      refreshMarker={refreshMarker}
    />
  );
};

export default CreateAndEditInvoiceOrder;
