/**
 * @description 多级条件配置表单 操作按钮
 */

import styles from '../styles.module.scss';
import { Button, FormInstance } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';
import { BlIcon } from 'src/components';
import { appEnum } from 'src/dict';
import { NamePath } from '../constants';

interface Props {
  form: FormInstance;
  namePath?: NamePath;
  /** 转化层级 比如2 代表至多可转化两级 */
  // level?: number;
  /** 删除一个条件行的函数 */
  onRemove: () => void;
  /** 刷新函数 */
  refresh: () => void;
  copy?: boolean;
  /** 是否展示转化操作按钮 */
  conversion?: boolean;
  disabled?: boolean;
}
/** 多级条件配置表单 操作按钮 */
export default function Options({
  form,
  namePath = [],
  onRemove,
  refresh,
  copy = true,
  conversion = false,
  disabled,
}: Props) {
  return (
    <div className={styles?.operation}>
      <div className={styles?.operationItem}>
        <Button
          block
          icon={<BlIcon type="iconguanbi" />}
          onClick={() => onRemove()}
          type="text"
          size="small"
          disabled={disabled}
        />
      </div>
      {copy && (
        <div className={styles?.operationItem}>
          <Button
            block
            icon={<BlIcon type="iconmianxingfuzhi" />}
            onClick={() => {
              // modifyData('copy', namePath);
            }}
            type="text"
            size="small"
            disabled={disabled}
          />
        </div>
      )}
      {conversion && (
        <div className={styles?.operationItem}>
          <Button
            block
            icon={<RetweetOutlined />}
            className={styles?.operationItem}
            type="text"
            size="small"
            disabled={disabled}
            onClick={() => {
              const conditionValue = form.getFieldValue([...namePath, 'condition']);

              form.setFields([
                { name: [...namePath, 'type'], value: appEnum.TriggerRule.ConnectionType.and },
                { name: [...namePath, 'condition'], value: undefined },
                // 此处内联条件的值一行没有意义，转化的时候，设置value的值为两行
                { name: [...namePath, 'innerConditions'], value: [conditionValue, {}] },
              ]);
              refresh();
            }}
          />
        </div>
      )}
    </div>
  );
}
