import _ from 'lodash';
import React from 'react';
import { BlTable } from 'src/components';
import { replaceSign } from 'src/utils/constants';
import { EnergyMeterDetailType } from '../index.d';

interface DetailListProps {
  dataSource: EnergyMeterDetailType['energyInstrumentResourceLinkList'];
}

const DetailList = (props: DetailListProps) => {
  const { dataSource } = props;

  return (
    <BlTable
      dataSource={dataSource}
      rowKey="id"
      columns={[
        {
          title: '设备',
          dataIndex: 'name',
          width: 300,
        },
        {
          title: '设备层级',
          dataIndex: 'level',
          width: 100,
        },
        {
          title: '包含子设备',
          dataIndex: 'hasChildren',
          width: 100,
          render: (text, record) => {
            if (
              _.findIndex(dataSource, ({ name }) => {
                return record?.name === name;
              }) !== -1
            ) {
              return text ? '是' : '否';
            }

            return replaceSign;
          },
        },
      ]}
    />
  );
};

export default DetailList;
