import React, { useEffect, useState } from 'react';
import { FormInstance } from 'antd';
import color from 'src/styles/color/index';
import { BcUpload, BlIcon } from 'src/components';

interface Props {
  form: FormInstance;
  // defaultFiles?: Documents[];
}
const UploadImg = (props: Props) => {
  const { form, value, onChange } = props;

  const [values, setValues] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const [relationIds, setRelationIds] = useState([]);

  useEffect(() => {
    const documentsIds = value?.map((i) => i.id);
    const relationIds = value?.map((i) => {
      return { id: i.id, relationId: i.relationId };
    });

    setDefaultValues(documentsIds);
    setRelationIds(relationIds);
  }, [value]);

  return (
    <>
      <BcUpload
        canPreview
        autoDelErrorFile
        maxSize={10}
        maxCount={3}
        draggable={false}
        limit={'image'}
        listType="picture-card"
        defaultFiles={defaultValues}
        value={values}
        onUploaded={(files) => {
          console.log('onUploaded', files);
          const ids = files.map((o: any) => {
            if (o.response) {
              return o.response.id;
            }
            return o.id;
          });
          // 为后端添加relationId
          const newFiles = files.map((o: any) => {
            if (o.id) {
              const Oarray = relationIds?.map((el: { id: number; relationId: number }) => {
                if (el.id === o.id) {
                  return { ...o, relationId: el.relationId };
                }
                return o;
              });

              return Oarray[0];
            }
            return o;
          });

          setValues(ids);
          onChange?.(newFiles);
        }}
      >
        <div>
          <BlIcon type="iconxinjiantianjia" />
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
      </BcUpload>
      <p style={{ color: color.gray }}>附件支持类型：JPG/PNG/JPEG，最大不超过10M</p>
    </>
  );
};

export default UploadImg;
