/**
 * @description 触发事件 列表页
 */

import { Key, useState } from 'react';
import { message } from 'antd';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';

import { fieldTypeList } from 'src/utils';
import lookup, { appEnum, appDict } from 'src/dict';
import { CRUD } from 'src/dict/common';
import authDict from 'src/utils/auth';

import { RecordListLayout, FilterItem } from 'layout';
import { BlIcon, SearchSelect, showErrorListMessage, LinkTooltip } from 'src/components';

import renderUsageStatus from 'src/page/share/renderUsageStatus';

import {
  fetchTriggerActionPageList,
  fetchTriggerActionEnable,
  fetchTriggerActionDisable,
} from 'src/api/ytt/trigger-domain/triggerAction';

import { getDetailPageUrl, getCreatePageUrl, getEditPageUrl } from './utils/navigation';
import { isGeneralAction, isBusinessAction, VIEW_MODAL } from './constant';
import { replaceSign } from 'src/utils/constants';

interface TriggerActionProps extends RouteComponentProps {
  actionSource: string;
}

const TriggerAction: React.FC<TriggerActionProps> = ({ history, actionSource }) => {
  // 刷新用的时间戳，在refresh方法里用
  const [refreshMarker, setRefreshMarker] = useState<number>(Date.now());
  // 选中行的key，用以批量删除
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  /** 刷新列表函数 */
  const refreshTable = () => setRefreshMarker(Date.now() + Math.random() * 100);

  const columns = [
    {
      title: '事件编号',
      dataIndex: 'code',
      width: 150,
      sorter: true,
      isFilter: true,
      filterType: fieldTypeList.text,
      render: (name: string, record: any, index: number, config: any) => (
        <LinkTooltip
          to={getDetailPageUrl(actionSource, record.id)}
          text={name}
          width={config.contentWidth}
          auth={authDict.triggeraction_detail}
        />
      ),
    },
    {
      title: '事件名称',
      dataIndex: 'name',
      width: 150,
      sorter: true,
      isFilter: true,
      filterType: fieldTypeList.text,
      render: (code: string, record: any, index: number, config: any) => (
        <LinkTooltip
          to={getDetailPageUrl(actionSource, record.id)}
          text={code}
          width={config.contentWidth}
          auth={authDict.triggeraction_detail}
        />
      ),
    },
    {
      title: '触发规则',
      dataIndex: 'triggerRuleName',
      filterName: 'triggerRuleIds',
      width: 150,
      isFilter: true,
      filterType: fieldTypeList.select,
      renderItem: <SearchSelect fetchType="triggerRule" mode="multiple" allowClear />,
    },
    {
      title: '事件类型',
      dataIndex: 'actionType',
      filterName: 'actionType',
      width: 150,
      isFilter: true,
      filterType: fieldTypeList.select,
      selectProps: { options: appDict.triggerAction.actionTypeOptions },
      render: (actionType: number) => lookup('triggerAction.actionTypeOptions', actionType),
    },
    {
      title: '更新对象来源',
      dataIndex: 'instanceSourceType',
      filterName: 'instanceSourceType',
      width: 150,
      isFilter: true,
      filterType: fieldTypeList.select,
      selectProps: { options: appDict.triggerAction.instanceSourceTypeOptions },
      render: (instanceSourceType: number) => {
        return lookup('triggerAction.instanceSourceTypeOptions', instanceSourceType) || replaceSign;
      },
    },
    {
      title: '关联对象',
      dataIndex: 'objectFieldPathName',
      width: 150,
    },
    {
      title: '业务对象',
      dataIndex: 'objectName',
      filterName: 'objectCodes',
      width: 150,
      isFilter: true,
      filterType: fieldTypeList.select,
      renderItem: (
        <SearchSelect fetchType="bizObjectByCode" labelInValue mode="multiple" allowClear />
      ),
    },
    // {
    //   title: '业务类型',
    //   dataIndex: 'objectBizType',
    //   filterName: 'objectBizType',
    //   width: 150,
    //   isFilter: true,
    //   filterType: fieldTypeList.select,
    //   selectProps: { options: appDict.triggerAction.actionTypeOptions },
    // },
    {
      title: '事件来源',
      dataIndex: 'sourceType',
      width: 150,
      isFilter: actionSource === VIEW_MODAL.generalAction,
      filterType: fieldTypeList.select,
      selectProps: { options: _.initial(appDict.triggerAction.actionSourceType), mode: 'multiple' },
      render: (sourceType: number) => (
        <div>{lookup('triggerAction.actionSourceType', sourceType)}</div>
      ),
    },
    ...isBusinessAction(actionSource, [
      {
        title: '来源对象',
        dataIndex: 'bizSourceObjectName',
        width: 150,
        isFilter: true,
        filterType: fieldTypeList.select,
        renderItem: <SearchSelect fetchType="bizObjectByCode" mode="multiple" />,
      },
      {
        title: '来源对象实例',
        dataIndex: 'bizSourceInstanceCode',
        width: 150,
      },
    ]),
    {
      title: '启用状态',
      dataIndex: 'status',
      width: 150,
      isFilter: true,
      filterType: fieldTypeList.select,
      selectProps: { options: appDict.common.usageStatus },
      render: renderUsageStatus,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
    },
  ];

  const filterList: FilterItem[] = columns
    .filter((i) => i.isFilter)
    .map((column) => {
      const filter: any = {
        label: column.title,
        name: column.filterName ?? column.dataIndex,
        type: column.filterType,
        renderItem: column.renderItem,
      };

      if (column.filterType === fieldTypeList.select) {
        filter.selectProps = column.selectProps;
      }
      return filter;
    });

  const showRuleErrorListMessage = (
    successCount: number,
    failCount: number,
    failReasons: any,
    title: string,
  ) =>
    showErrorListMessage({
      failCount,
      successCount,
      title,
      data: _.map(failReasons, (reason) => ({ reason })),
      columns: [{ title: '失败原因', dataIndex: 'reason' }],
    });

  /** @description 批量操作按钮 */
  const batchMenu = [
    //  * batchMenu onClick回调的参数
    //  * onSuccess会刷新列表并清空批量按钮的loading状态
    //  * onFail只清空loading状态
    {
      title: lookup('common.crud', CRUD.enable),
      auth: authDict.triggeraction_enable_disable,
      onClick: (onSuccess?: () => void, onFail?: () => void) => {
        fetchTriggerActionEnable({ actionIds: selectedRowKeys as number[] })
          .then((res) => {
            const { failCount = 0, successCount = 0, failReasons = [] } = res?.data ?? {};

            if (failCount) {
              showRuleErrorListMessage(successCount, failCount, failReasons, '启用出现失败');
            } else {
              message.success('启用成功');
            }
            onSuccess?.();
          })
          .catch(() => onFail?.());
      },
    },
    {
      title: lookup('common.crud', CRUD.disable),
      auth: authDict.triggeraction_enable_disable,
      onClick: (onSuccess?: () => void, onFail?: () => void) => {
        fetchTriggerActionDisable({ actionIds: selectedRowKeys as number[] })
          .then((res) => {
            const { failCount = 0, successCount = 0, failReasons = [] } = res?.data ?? {};

            if (failCount) {
              showRuleErrorListMessage(successCount, failCount, failReasons, '停用出现失败');
            } else {
              message.success('停用成功');
            }
            onSuccess?.();
          })
          .catch(() => onFail?.());
      },
    },
  ];

  /** 右上角主要按钮 */
  const mainMenu = [
    {
      title: '新建',
      auth: authDict.triggeraction_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => history.push(getCreatePageUrl()),
      items: [],
    },
  ];

  /** 获取列表页每一项的操作按钮 */
  const getOperationList = (record: any) => {
    const { id: actionId, status } = record;
    const isDisabled = status === appEnum.Common.UsageStatus.disabled;
    const statusTitle = isDisabled ? '启用' : '停用';

    return [
      {
        title: lookup('common.crud', CRUD.view),
        auth: authDict.triggeraction_detail,
        onClick: () => history.push(getDetailPageUrl(actionSource, actionId)),
      },
      ...isGeneralAction(actionSource, [
        {
          title: lookup('common.crud', CRUD.edit),
          auth: authDict.triggeraction_edit,
          onClick: () => history.push(getEditPageUrl(actionId)),
          disabled: status === appEnum.Common.UsageStatus.enabled,
        },
        {
          title: lookup('common.crud', CRUD.copy),
          auth: authDict.triggeraction_add,
          onClick: () => history.push(getCreatePageUrl(actionId)),
        },
        {
          title: statusTitle,
          auth: authDict.triggeraction_enable_disable,
          onClick: () => {
            let reqFn = fetchTriggerActionDisable;

            if (status === appEnum.Common.UsageStatus.disabled) {
              reqFn = fetchTriggerActionEnable;
            }

            reqFn({ actionIds: [actionId] as number[] }).then((res) => {
              if (res.code === 200) {
                const { failCount, failReasons } = res.data;

                if (failCount > 0) {
                  message.error(`${statusTitle}失败`);
                  message.error(failReasons[0]);
                } else {
                  message.success(`${statusTitle}成功`);
                  refreshTable();
                }
              }
            });
          },
        },
      ]),
      // {
      //   title: lookup('common.crud', CRUD.delete),
      //   onClick: () => {
      //     console.log('删除');
      //   },
      // },
    ];
  };

  const formatDataToQuery = (params: any) => {
    const { triggerRuleIds, objectCodes, sourceType, bizSourceObjectName, ...restParams } = params;
    // 通用事件列表，事件来源于「手动创建」和 「系统预置」
    // 业务事件列表，事件来源于 「业务自动创建」
    const _sourceType =
      actionSource === VIEW_MODAL.generalAction
        ? [
            appEnum.TriggerAction.ActionSourceType.manual,
            appEnum.TriggerAction.ActionSourceType.preset,
          ]
        : [appEnum.TriggerAction.ActionSourceType.businessAutomation];
    const _params = {
      ...restParams,
      triggerRuleIds: _.isEmpty(triggerRuleIds) ? undefined : _.map(triggerRuleIds, 'value'),
      objectCodes: _.isEmpty(objectCodes) ? undefined : _.map(objectCodes, 'value'),
      sourceType: _.isEmpty(sourceType) ? _sourceType : sourceType,
      bizSourceObjectCodes: _.isEmpty(bizSourceObjectName)
        ? undefined
        : _.map(bizSourceObjectName, 'value'),
    };

    return _params;
  };

  return (
    <RecordListLayout<any>
      columns={columns}
      refreshMarker={refreshMarker}
      filterList={filterList}
      requestFn={fetchTriggerActionPageList}
      placeholder="请输入事件编号或名称"
      batchMenu={isGeneralAction(actionSource, batchMenu)}
      mainMenu={isGeneralAction(actionSource, mainMenu)}
      rowKey="id"
      selectedRowKeys={selectedRowKeys}
      onSelectedRowKeys={(selectedKeys) => setSelectedRowKeys(selectedKeys)}
      // formatDataToDisplay={formatDataToDisplay}
      formatDataToQuery={formatDataToQuery}
      // formatDataToFormDisplay={formatDataToFormDisplay}
      getOperationList={getOperationList}
    />
  );
};

export default TriggerAction;
