import { appEnum } from 'src/dict';

/** 产出物料 */
export const BOM_OUTPUT_MATERIALS = 'bomOutputMaterials';
/** 用料清单 */
export const BOM_INPUT_MATERIALS = 'bomInputMaterials';
/** 工序计划 */
export const PROCESS_PLAN_CO = 'processPlanCO';
/** 来源信息 */
export const PLAN_ORDER_SOURCE_CO = 'planOrderSourceCO';
/** 其他信息 */
export const OTHER = 'other';

// BOM清单其他信息tab
export enum ProductionOrderTab {
  'SUB_ITEM_MATERIAL' = 1, // 子项物料
  'COPRODUCT', // 产出物料
  'PROCESS_PLAN', // 工序信息
  'SOURCE', // 来源信息
  'INTERMEDIATE_MATERIAL', // 中间品
  'OTHER', // 其他
}

// 计划订单字段转化
export const FIELD_MAP = new Map([
  ['planOrderCode', 'planOrderCode'],
  ['requirementTime', 'requirementTime'],
  ['plannedStartTime', 'plannedStartTime'],
  ['plannedEndTime', 'plannedEndTime'],
  ['createdAt', 'createdAt'],
  ['updatedAt', 'updatedAt'],
]);

export const ProductionOrderTabMap = new Map([
  [
    ProductionOrderTab.COPRODUCT,
    { value: ProductionOrderTab.COPRODUCT, title: '产出物料', filedName: BOM_OUTPUT_MATERIALS },
  ],
  [
    ProductionOrderTab.SUB_ITEM_MATERIAL,
    {
      value: ProductionOrderTab.SUB_ITEM_MATERIAL,
      title: '用料清单',
      filedName: BOM_INPUT_MATERIALS,
    },
  ],
  [
    ProductionOrderTab.PROCESS_PLAN,
    { value: ProductionOrderTab.PROCESS_PLAN, title: '工序计划', filedName: PROCESS_PLAN_CO },
  ],
  [
    ProductionOrderTab.SOURCE,
    { value: ProductionOrderTab.SOURCE, title: '来源信息', filedName: PLAN_ORDER_SOURCE_CO },
  ],
  // [
  //   BomTab.INTERMEDIATE_MATERIAL,
  //   { value: BomTab.INTERMEDIATE_MATERIAL, title: '中间品', filedName: 'intermediateMaterial' },
  // ],
  [
    ProductionOrderTab.OTHER,
    { value: ProductionOrderTab.OTHER, title: '其他信息', filedName: OTHER },
  ],
]);

/**
 * BOM启用状态颜色
 */
export const BomActiveColorMap = new Map([
  [appEnum.Common.YN.yes, 'green'],
  [appEnum.Common.YN.no, 'red'],
]);
