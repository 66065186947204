import { useEffect, useState } from 'react';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { DataFormLayout } from 'layout';
import { Checkbox, Form, message } from 'antd';
//
import {
  fetchInstrumentDetail,
  fetchInstrumentCreate,
  fetchInstrumentUpdate,
} from 'src/api/ytt/resource-domain/energyMeter';
import lookup, { appEnum } from 'src/dict';
import {
  initCustomFieldsInData,
  formatCustomFieldsInData,
  useCustomFieldCombinedData,
} from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
//
import { EnergyMeterDetailType, IEnergyMeterForm } from '../index.d';

import ActionContent, {
  formatDataToApi as formatBaseInfoToApi,
  formatDataToForm as formatBaseInfoToForm,
} from './baseForm';
import {
  formatDataToApi as formatEnergyTagToApi,
  formatDataToForm as formatEnergyTagToForm,
} from './energyTag';
import {
  formatDataToApi as formatCensusEquimentToApi,
  formatDataToForm as formatCensusEquimentToForm,
} from './censusEquiment';
import {
  formatDataToApi as formatEnergyParamterToApi,
  formatDataToForm as formatEnergyParamterToForm,
} from './energyParameter';
import { goToList } from '../navigates';

interface ActionProps {
  id: string;
}

const DefinitionAction = (props: RouteComponentProps<ActionProps>) => {
  const { match, history } = props;
  const id = Number(match.params.id);
  const type = history.location.pathname.split('/').pop();

  const [modalForm] = Form.useForm<IEnergyMeterForm>();
  const [keepOn, setKeepOn] = useState<boolean>(false);
  const [detailData, setDetailData] = useState<EnergyMeterDetailType>();
  const [loading, setLoading] = useState(false);

  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.EnergyInstrument);

  const formatDateToSetForm = (data: EnergyMeterDetailType): IEnergyMeterForm => {
    const { tagsList, tagsTree, resourceParamsList, energyInstrumentResourceLinkList, ...rest } =
      data;

    return {
      ...formatBaseInfoToForm(rest),
      tagsIdList: formatEnergyTagToForm(tagsList, tagsTree),
      energyInstrumentResourceLinkList: formatCensusEquimentToForm(
        energyInstrumentResourceLinkList,
      ),
      resourceParamsList: formatEnergyParamterToForm(resourceParamsList),
    };
  };

  const formatDataToSend = (data: IEnergyMeterForm) => {
    const { resourceParamsList, energyInstrumentResourceLinkList, tagsIdList, ...resData } = data;

    return {
      ...formatBaseInfoToApi(resData, type),
      tagsList: formatEnergyTagToApi(tagsIdList),
      energyInstrumentResourceLinkList: formatCensusEquimentToApi(energyInstrumentResourceLinkList),
      resourceParamsList: formatEnergyParamterToApi(resourceParamsList, type),
    };
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await fetchInstrumentDetail({ id });

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return {};
    } finally {
      setLoading(false);
    }
  };

  const fetchApi = (data: any) => {
    let fetchFn;

    if (_.isNil(data)) return Promise.reject();

    if (type === appEnum.Common.CRUD.create) fetchFn = fetchInstrumentCreate;
    if (type === appEnum.Common.CRUD.edit) fetchFn = fetchInstrumentUpdate;
    if (type === appEnum.Common.CRUD.copy) fetchFn = fetchInstrumentCreate;

    return fetchFn?.(data) ?? Promise.resolve({ code: 500, data: {} });
  };

  useEffect(() => {
    if (id) {
      fetchData()
        .then((dataRes) => {
          // 设置表单初始值
          if (id && dataRes) {
            const initialFormValue = initCustomFieldsInData(formatDateToSetForm(dataRes ?? {}));

            if (type === appEnum.Common.CRUD.copy) {
              _.unset(initialFormValue, 'code');
            }

            modalForm.setFieldsValue(initialFormValue);

            setDetailData(dataRes);
          }
        })
        .catch(() => {
          history.goBack();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const handleFinish = async () => {
    setLoading(true);
    try {
      const value = await modalForm?.validateFields();

      const submitData = formatCustomFieldsInData({ data: formatDataToSend(value), customFields });

      const { code } = await fetchApi(submitData);

      if (code === 200) {
        message.success(`${lookup('common.crud', type)}成功！`);

        if (keepOn) {
          modalForm.resetFields();
        } else {
          goToList();
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DataFormLayout
        loading={loading}
        form={modalForm}
        title={`${lookup('crud', type)}能源仪表台账`}
        extra={
          type === appEnum.Common.CRUD.create && (
            <Checkbox
              onChange={() => {
                setKeepOn(!keepOn);
              }}
              defaultChecked={keepOn}
            >
              连续新建
            </Checkbox>
          )
        }
        info={[]}
        onFinish={handleFinish}
        onCancel={() => {
          history.goBack();
        }}
      >
        <ActionContent
          form={modalForm}
          type={type!}
          detailData={detailData}
          customFields={customFields}
        />
      </DataFormLayout>
    </>
  );
};

export default DefinitionAction;
