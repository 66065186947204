import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RecordListLayout } from 'layout';
import { dataColumnsForDetail, detailFilterList } from '../components/columns';
import { BlTooltip, SingleOperationLogComponent } from 'src/components';
import {
  CustomerDeliveryScheduleNoteListItemProps,
  CustomerDeliveryScheduleNoteListItems,
} from '../interface';
import { replaceSign } from 'src/utils/constants';
import { toCreateInvoice_URL, toDetail, toInvoiceList_URL } from '../navigation';
import _ from 'lodash';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { handleCreateInvoice } from '../batchOperate';
import { InvoiceOrigin } from 'src/dict/invoice';
import { useDispatch } from 'react-redux';
import { fetchCustomerDeliveryScheduleNoteListItem } from 'src/api/ytt/supply-chain-domain/customer_delivery_schedule_note';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { appEnum } from 'src/dict';

const SalesOrderDetailList = () => {
  const history = useHistory();
  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  const [selectedRows, setSelectRows] = useState<CustomerDeliveryScheduleNoteListItemProps[]>([]);
  const [salesOrderId, setSalesOrderId] = useState<number>(0);
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const dispatch = useDispatch();

  /**
   * 跳转到新建发货单页面
   */
  const handleJumpToCreateInvoice = (
    data: {
      items: CustomerDeliveryScheduleNoteListItems[];
      plannedIssueStatus: { code?: number | undefined; message?: string | undefined } | undefined;
      status: number;
    }[],
  ) => {
    // 只有执行中的单据支持新建发货单
    const res = _.remove(
      data,
      (n) => n.status === appEnum.CollaborativeDeliverySchedule.OrderStatus.inprogress,
    );

    dispatch({
      type: 'supplyChain/formatterCdsnInvoiceInfo',
      payload: { invoiceType: InvoiceOrigin.collaborativeDeliverySchedule, data: res },
    });
    history.push(toCreateInvoice_URL());
  };
  /**
   * 跳转到发货单列表页面
   */
  const handleJumpToInvoiceList = () => {
    history.push(toInvoiceList_URL());
  };
  /**
   * 批量操作按钮
   */
  const toolbar = _.compact([
    {
      title: '新建发货单',
      auth: authDict.shipmentsnote_add,
      onClick: (onSuccess: any, onFail: any) => {
        // 需要把明细行数据包装成单据的数据格式
        const data = selectedRows?.map((o) => {
          return { items: [o], plannedIssueStatus: o.plannedIssueStatus, status: o.status };
        });

        handleCreateInvoice(
          data,
          () => {
            onSuccess();
            handleJumpToCreateInvoice(data);
          },
          onFail,
          handleJumpToInvoiceList,
          'multiple',
        );
      },
    },
  ]);
  /**
   * 列操作 ...
   */
  const getOperationList = (record: CustomerDeliveryScheduleNoteListItemProps) => {
    return [
      {
        title: '查看',
        auth: authDict.CustomerDeliveryScheduleNote_detail,
        onClick: () => {
          history.push(toDetail(record?.noteId as number));
        },
      },
      {
        title: '操作记录',
        auth: authDict.CustomerDeliveryScheduleNote_operrecord,
        onClick: () => {
          setSalesOrderId(record?.noteId!);
          setVisibleLog(true);
        },
      },
    ];
  };
  /**
   * Columns
   */
  const dataColumns = [
    {
      title: '协同交货计划单编号',
      dataIndex: 'code',
      sorter: true,
      width: 200,
      render: (
        orderCode: string,
        record: CustomerDeliveryScheduleNoteListItemProps,
        index: number,
        config: any,
      ) => {
        return (
          <>
            {hasAuth(authDict.CustomerDeliveryScheduleNote_detail) ? (
              <a
                onClick={() => {
                  history.push(toDetail(record?.noteId as number));
                }}
              >
                <BlTooltip text={orderCode ?? replaceSign} width={config.contentWidth} />
              </a>
            ) : (
              <BlTooltip text={orderCode ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },

    ...dataColumnsForDetail,
  ];
  /**
   * 传给接口数据处理函数
   */
  const formatDataToQuery = (params: any) => {
    const { customerIds, materialIds, saleOrderCode, ...rest } = params;

    const relParams = {
      customerIds: customerIds?.length ? _.map(customerIds, 'value') : null,
      materialIds: materialIds?.length ? _.map(materialIds, 'value') : null,
      soId: saleOrderCode?.length ? _.map(saleOrderCode, 'value') : null,
      ...rest,
    };

    return relParams;
  };
  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (data: any) => {
    return data;
  };
  /**
   * RecordListLayout 配置
   */
  const config = {
    rowKey: (record: CustomerDeliveryScheduleNoteListItemProps) => String(record.id),
    columns: dataColumns,
    batchMenu: toolbar,
    getOperationList,
    filterList: detailFilterList,
    formatDataToQuery,
    formatDataToFormDisplay,
  };

  return (
    <>
      <RecordListLayout<CustomerDeliveryScheduleNoteListItemProps>
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey: any, selectRows?: any[]) => {
          setSelectRowKeys(selectKey);
          setSelectRows(selectRows ?? []);
        }}
        userAuth={getAuthFromLocalStorage()}
        placeholder={'请输入协同交货计划单编号'}
        configcacheKey="salesOrderDetail"
        requestFn={fetchCustomerDeliveryScheduleNoteListItem}
        {...config}
      />{' '}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={salesOrderId}
          objectCode={OBJECT_OF_CODE.CustomerDeliveryScheduleNote}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default SalesOrderDetailList;
