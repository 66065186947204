import React, { useRef, useState } from 'react';
import { FilterItem, RecordListLayout } from 'layout';
import { fieldTypeList, gcTime } from 'src/utils';
import { Badge, message, Form } from 'antd';
import { QcConfigListProps } from '../index.d';
import { QcConfig } from 'src/page/quality/models/dto';
import {
  fetchQcConfigPageList,
  fetchQcConfigDelete,
  fetchQcConfigDisable,
  fetchQcConfigEnable,
} from 'src/api/ytt/quality-domain/qcConfig';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import { BlIcon } from '@blacklake-web/component';
import {
  getQcConfigEditUrl,
  getQcConfigCopyUrl,
  getQcConfigCreateUrl,
  getQcConfigDetailUrl,
} from '../navigator';
import _ from 'lodash';
import { SearchSelect, SingleOperationLogComponent, LinkTooltip } from 'src/components';
import { usageStatus } from 'src/dict/common/mappings';
import { appDict, appEnum, lookup } from 'src/dict';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { AvatarDisplay } from 'src/components/avatar/show';
import { useDispatch } from 'react-redux';
import { ObjectCode } from 'src/dict/common';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';

const QcConfigList = (props: QcConfigListProps) => {
  const [selectedRowKeys, setSelectRowKeys] = useState<(string | number)[]>([]);
  const refreshCountRef = useRef(0);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [qcConfigId, setQcConfigId] = useState<number>(0);
  const [delForm] = Form.useForm();
  const { history } = props;
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const toQcConfigDetail = (id: number) => history.push(getQcConfigDetailUrl(id, false));
  const toQcConfigEdit = (id: number) => history.push(getQcConfigEditUrl(id));
  const toQcConfigCopy = (id: number) => history.push(getQcConfigCopyUrl(id));
  const toQcConfigCreate = () => history.push(getQcConfigCreateUrl());

  const handleDelete = async (ids: any[], onSuccess?: () => void) => {
    // 删除
    try {
      const res = await fetchQcConfigDelete({ ids: ids.map((i) => Number(i)) });
      const { data } = res;

      if (res.code !== 200) {
        message.error(res?.message);
        return;
      }
      if (data?.code === 1) {
        // 结果标识符 1成功 2失败 3出现失败(批量删除)
        typeof onSuccess === 'function' && onSuccess?.();
        message.success('删除成功');
      }
      if (data?.code === 2) {
        message.error('删除失败');
      }
      if (data?.code === 3) {
        typeof onSuccess === 'function' && onSuccess?.();
        message.warn('批量删除出现失败');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      icon: <BlIcon type="icondaochu" />,
      auth: authDict.qualityinspectionscheme_export,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.qcConfig,
        });
      },
    },
    {
      title: '新建检验方案',
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => toQcConfigCreate(),
      auth: authDict.qualityinspectionscheme_add,
      items: [
        {
          title: '导入',
          showExport: true,
          auth: authDict.qualityinspectionscheme_import,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.qcConfig,
            });
          },
        },
      ],
    },
  ];

  const getColumns = () => {
    return [
      {
        title: '检验方案编号',
        dataIndex: 'code',
        type: fieldTypeList.text,
        width: 200,
        render: (code: string, record: QcConfig, index: number, config: any) => (
          <LinkTooltip
            to={getQcConfigDetailUrl(record.id, false)}
            text={code}
            width={config.contentWidth}
            auth={authDict.qualityinspectionscheme_detail}
          />
        ),
      },
      {
        title: '检验方案名称',
        dataIndex: 'name',
        width: 200,
      },
      {
        title: '业务类型',
        dataIndex: 'checkType',
        width: 200,
        render: lookup('quality.checkType'),
      },
      {
        title: '状态',
        dataIndex: 'state',
        width: 200,
        render: (text: number) => (
          <span>
            <Badge status={text ? 'success' : 'error'} /> {lookup('common.usageStatus', text)}
          </span>
        ),
      },
      {
        title: '备注',
        dataIndex: 'remark',
        width: 200,
      },
      {
        title: '创建人',
        dataIndex: 'creator',
        width: 200,
        render: (value: any) => {
          if (!value) return '-';
          return (
            <AvatarDisplay
              id={value.id}
              isUser
              isShowTag={false}
              name={value.name}
              avatarUrl={value.avatarUrl}
            />
          );
        },
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        width: 200,
        render: (createdAt: number) => gcTime.formatLine(createdAt),
      },
      {
        title: '更新人',
        dataIndex: 'operator',
        width: 200,
        render: (value: any) => {
          if (!value) return '-';
          return (
            <AvatarDisplay
              id={value.id}
              isShowTag={false}
              isUser
              name={value.name}
              avatarUrl={value.avatarUrl}
            />
          );
        },
      },
      {
        title: '更新时间',
        dataIndex: 'updatedAt',
        width: 200,
        render: (updatedAt: number) => gcTime.formatLine(updatedAt),
      },
    ];
  };
  const getOperationList = (record: QcConfig) => {
    return [
      {
        title: '查看',
        onClick: () => toQcConfigDetail(record.id),
        auth: authDict.qualityinspectionscheme_detail,
      },
      {
        title: '编辑',
        disabled: record.state === appEnum.Common.UsageStatus.enabled,
        onClick: () => toQcConfigEdit(record.id),
        auth: authDict.qualityinspectionscheme_edit,
      },
      {
        title: lookup('common.changeStatusAction', record.state),
        auth: authDict.qualityinspectionscheme_enable_disable,
        onClick: async () => {
          const res =
            record.state === appEnum.Common.UsageStatus.enabled
              ? await fetchQcConfigDisable({ id: record.id })
              : await fetchQcConfigEnable({ id: record.id });
          const action = lookup('common.changeStatusAction', record.state);

          if (res.code === 200) {
            message.success(`${action}成功`);
            refreshTable();
          } else {
            message.success(res.message);
          }
        },
      },
      {
        title: '复制',
        onClick: () => toQcConfigCopy(record.id),
        auth: authDict.qualityinspectionscheme_add,
      },
      {
        title: '操作记录',
        auth: authDict.qualityinspectionscheme_operrecord,
        onClick: () => {
          setVisibleLog(true);
          setQcConfigId(record.id);
        },
      },
    ];
  };

  const filterList: FilterItem[] = [
    {
      label: '检验方案编号',
      name: 'code',
      type: fieldTypeList.text,
    },
    {
      label: '检验方案名称',
      name: 'name',
      type: fieldTypeList.text,
    },
    {
      label: '状态',
      name: 'status',
      type: fieldTypeList.multiSelect,
      selectProps: {
        options: usageStatus,
      },
    },
    {
      label: '业务类型',
      name: 'checkType',
      type: fieldTypeList.multiSelect,
      selectProps: {
        options: appDict.quality.checkType,
      },
    },
    {
      label: '关联物料',
      name: 'materialIds',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType={'materialCodeName'} mode="multiple" />,
    },
    {
      label: '关联工序',
      name: 'processIds',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType={'processCodeAndName'} mode="multiple" />,
    },
    {
      label: '关联设备',
      name: 'resourceIds',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType={'resource'} mode="multiple" />,
    },
  ];

  const refreshTable = () => {
    // 刷新列表页
    refreshCountRef.current++;
    setRefreshMarker(refreshCountRef.current);
  };

  const batchMenu = [
    {
      title: '删除',
      icon: <BlIcon type="iconshanchulajitong1" />,
      onClick: (onSuccess: any, onFail: any) => {
        BLDelConfirm(
          '是否确认删除选中的方案？',
          delForm,
          () => {
            handleDelete(selectedRowKeys);
            onSuccess();
          },
          onFail,
        );
      },
    },
  ];

  const formatDataToQuery = (data: any) => {
    const { materialIds, processIds, resourceIds, ...rest } = data;
    const param = {
      materialIds: materialIds?.map((i: { value: any }) => i.value),
      processIds: processIds?.map((i: { value: any }) => i.value),
      resourceIds: resourceIds?.map((i: { value: any }) => i.value),
      ...rest,
    };

    dispatch.excelImport.updateBusinessData({ businessData: param });
    return param;
  };

  return (
    <>
      <RecordListLayout<QcConfig>
        columns={getColumns()}
        mainMenu={mainMenu}
        batchMenu={batchMenu}
        filterList={filterList}
        requestFn={(params) => fetchQcConfigPageList(params, { legacy: true })}
        // selectedRowKeys={selectedRowKeys}
        formatDataToQuery={formatDataToQuery}
        refreshMarker={refreshMarker}
        userAuth={getAuthFromLocalStorage()}
        getOperationList={getOperationList}
        // onSelectedRowKeys={(selectKey) => {
        //   setSelectRowKeys(selectKey);
        // }}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={qcConfigId}
          objectCode={ObjectCode.qcConfig}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default QcConfigList;
