import React, { useRef, useState } from 'react';
import { RecordListLayout, FilterItem } from 'layout';
import _ from 'lodash';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import {
  fetchQcMaterialChangeQcStatusLogList,
  FetchQcMaterialChangeQcStatusLogListRequest,
} from 'src/api/ytt/quality-domain/adjustRecord';
import { Space, Button } from 'antd';
import { fieldTypeList, gcArray, gcTime } from 'src/utils';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { SearchSelect } from 'src/components';
import { TableImageShow } from './imageDetail';
import lookup, { appDict, appEnum } from 'src/dict';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';

const QcTaskList = (props: { history: any }) => {
  const [selectedRowKeys, setSelectRowKeys] = useState<(string | number)[]>([]);
  const refreshCountRef = useRef(0);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const { history } = props;

  const toDetail = (id: number) =>
    history.push(`/quality/qualityModeling/adjustRecord/detail?id=${id}`);

  const columns = [
    {
      title: '记录编号',
      dataIndex: 'code',
      isFilter: true,
      width: 150,
      filterType: fieldTypeList.text,
    },
    {
      title: '物料名称',
      dataIndex: 'materialName',
      width: 150,
    },
    {
      title: '物料编号',
      dataIndex: 'materialCode',
      isFilter: true,
      width: 150,
      filterKey: 'materialId',
      filterType: fieldTypeList.select,
      renderItem: <SearchSelect fetchType={'materialCodeName'} labelInValue />,
    },
    {
      title: '调整后质量状态',
      dataIndex: 'qcStatusAfter',
      isFilter: true,
      width: 150,
      filterType: fieldTypeList.select,
      selectProps: {
        options: appDict.quality.inspectionResultOptions,
      },
      render: lookup('quality.qcStatusEnum'),
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
    },
    {
      title: '图片',
      dataIndex: 'attachmentIds',
      width: 150,
      render: (ids: number[]) => (ids ? <TableImageShow ids={ids || []} /> : '-'),
    },
    {
      title: '操作人',
      dataIndex: 'operator',
      isFilter: true,
      width: 150,
      type: fieldTypeList.select,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple={false} isSelectUser />,
      render: (value: any) => {
        if (!value?.id) return '-';
        return (
          <AvatarDisplay
            id={value.id}
            isShowTag={false}
            name={value.name}
            avatarUrl={value.avatarUrl}
            isUser
          />
        );
      },
    },
    {
      title: '调整时间',
      dataIndex: 'createdAt',
      sorter: true,
      isFilter: true,
      width: 180,
      filterType: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: (createdAt: Date) => gcTime.formatLine(createdAt),
    },
  ];

  const filterList: FilterItem[] = columns
    .filter((column) => column.isFilter)
    .map(({ title, dataIndex, filterKey, filterType, renderItem, selectProps, dateFormat }) => {
      return {
        label: title,
        name: filterKey || dataIndex,
        type: filterType || fieldTypeList.text,
        renderItem,
        dateFormat,
        selectProps,
      };
    });

  const formatDataToQuery = (
    params: {
      createdAt?: string[];
      operator?: { label: string; value: number }[];
      materialId?: { label: string; value: number };
    } = {},
  ) => {
    const { createdAt, operator, materialId, ...rest } = params;
    const formData: FetchQcMaterialChangeQcStatusLogListRequest = {
      ...rest,
    };

    if (createdAt && !gcArray.isEmpty(createdAt)) {
      formData.createdAtFrom = gcTime.formatToUnix(createdAt[0]);
      formData.createdAtTo = gcTime.formatToUnix(createdAt[1]);
    }
    if (materialId) {
      formData.materialId = materialId.value;
    }

    if (operator) {
      formData.operatorId = operator[0].value;
    }

    return formData;
  };

  const getOperationList = (record: any) => {
    return [
      {
        title: '查看',
        disabled: record.state === appEnum.Common.UsageStatus.enabled,
        auth: authDict.qualityadjustmentrecord_detail,
        onClick: () => toDetail(record.logId),
      },
    ];
  };

  return (
    <>
      <RecordListLayout
        columns={columns}
        batchMenu={[]}
        filterList={filterList}
        formatDataToQuery={formatDataToQuery}
        requestFn={(params: FetchQcMaterialChangeQcStatusLogListRequest) =>
          fetchQcMaterialChangeQcStatusLogList(params, { legacy: true })
        }
        selectedRowKeys={selectedRowKeys}
        refreshMarker={refreshMarker}
        placeholder={'输入记录编号、物料编号、物料名称、备注、操作人搜索'}
        rowKey="logId"
        onSelectedRowKeys={(selectKey: React.SetStateAction<(string | number)[]>) => {
          setSelectRowKeys(selectKey);
        }}
        getOperationList={getOperationList}
        userAuth={getAuthFromLocalStorage()}
      />
    </>
  );
};

export default QcTaskList;
