import { BL_BUSINESS_URL } from './config';

export const goToCreate = () => {
  window.reactRouterHistoryInstance.push(`${BL_BUSINESS_URL}/create`);
};

export const goToEdit = (id: number) => {
  window.reactRouterHistoryInstance.push(`${BL_BUSINESS_URL}/${id}/edit`);
};

export const goToDetail = (id: number) => {
  window.reactRouterHistoryInstance.push(`${BL_BUSINESS_URL}/${id}/detail`);
};

export const goToList = () => {
  window.reactRouterHistoryInstance.push(BL_BUSINESS_URL);
};
