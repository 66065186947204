import { message } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import qs from 'qs';

import { FIELDS, LOCATION_INFO, LoginRoutePathname, LOGOUT_CODE } from '../constants';
import LocalStorage from '../localStorage';
import { checkLoginStatus, showLogoutModal } from './authAndLogin';
import { needSignature, signNeeded } from './sign';
import * as constants from 'src/utils/constants';
import { handleSignature } from 'src/page/systemManagement/eSignature/utils';

const httpCode = {
  400: '请求参数错误，接口报错',
  401: '用户认证失败, 请重新登录',
  403: '服务器拒绝本次访问',
  404: '请求资源未找到',
  500: '内部服务器错误',
  501: '服务器不支持该请求中使用的方法',
  502: '网关错误',
  504: '网关超时',
};

// let axiosInstance;

export interface Response {
  message?: string;
  response: {
    status: 400 | 401 | 403 | 404 | 500 | 501 | 502 | 504;
    data: { message?: string };
  };
}

/** 获取请求的auth认证的头部
 * @description 从LocalStorage中获取用户身份认证信息，如果没有认证信息会直接清空请求url
 * @returns
 */
export const genAuthHeaders = () => {
  const token = LocalStorage.get(FIELDS.TOKEN_NAME);
  const { pathname } = document.location;

  if ((token && token !== 'expired') || LoginRoutePathname.includes(pathname)) {
    return {
      'X-AUTH': LocalStorage.get(FIELDS.TOKEN_NAME),
      'X-SERVICE': 'gc-graphql',
      'X-CLIENT': 'web',
    };
  }
  return {};
};

/** 用户电子签名
 * @description 签名失败会再次签名
 * @param error
 * @returns
 */
const signAndReRequest = async (error: any) => {
  if (!error && !error.config) return;

  const { config } = error;
  const { retry, url } = config;

  if (retry && retry.url === url) {
    message.warn('签名失败，请检查用户名和密码是否正确', 1);
    error.retry = undefined;
    return Promise.reject(new Error('签名失败，请检查用户名和密码是否正确'));
  }
  // try {
  //   const ret = await sign();
  //   if (!ret) return;
  //   const orgId = getOrgId();
  //   const header = {
  //     'X-Signature-Org-Code': orgId,
  //     'X-Signature-User-Name': ret?.username || '',
  //     'X-Signature-Password': ret?.password || '',
  //     'X-Signature-Token': ret?.signToken || '',
  //   };
  //   config.headers = { ...header, ...config.headers };
  //   config.retry = { url, time: new Date() };
  //   config.baseURL = '';
  //   if (!axiosInstance) return;
  //   return axiosInstance.request(config);
  // } catch (error) {
  //   if (error === 'canceled') {
  //     message.warn('电子签名取消');
  //   }
  //   return Promise.reject(new Error(''));
  // }

  return Promise.reject(new Error(''));
};

/** 用户位置
 * @description 获取用户位置信息，并放在请求头中
 * @returns
 */
export const getLocationHeaders = (): any => {
  const location = LocalStorage.get(LOCATION_INFO);

  if (location && location?.latitude && location?.longitude) {
    return {
      'x-latitude': location?.latitude,
      'x-longitude': location?.longitude,
    };
  }
};

/**
 * 发送请求过滤器
 * @description 添加对应请求头的检查用户登录状态
 * @param config axios默认参数
 * @returns
 */
const requestInterceptor = async (config: any) => {
  const hasAccess = await checkLoginStatus();

  if (!hasAccess) {
    // 增加setTimeout临时解决Reducers may not dispatch actions的报错
    setTimeout(() => {
      window?.globalStore?.dispatch({ type: 'user/clearUser' });
    }, 1);
    return Promise.reject(new Error('登录失效，请重新登录'));
  }

  const authHeaders = genAuthHeaders();
  const locationHeaders = getLocationHeaders();

  if (!authHeaders) {
    return {
      headers: {},
      method: config.method,
      url: '',
    };
  }
  const configHeaders = config && config.headers ? config.headers : {};
  // 操作原因，可能出现的字段名
  // 从前到后，如果有值则填充成body里的operateReason字段
  const operateReasonKeys = ['updateReason', 'modifyReason', 'editReason', 'reason', 'logRemark'];
  let operateReason = null;
  for (let key of operateReasonKeys) {
    if (config?.data && config?.data[key]) {
      operateReason = config?.data[key];
      break;
    }
  }
  const headers = {
    ...authHeaders,
    ...locationHeaders,
    'content-type': 'application/json',
    ...configHeaders,
  };
  if (operateReason && config?.data) {
    config.data.operateReason = operateReason;
  }

  return {
    ...config,
    headers,
  };
};
/**
 * response过滤器
 * @description 可以添加response处理，目前只是返回
 * @param response
 * @returns
 */
const responseInterceptor = async (response: any): Promise<any> => {
  // if (
  //   response?.status === 200 &&
  //   response?.data?.code !== 200 &&
  //   response?.headers['content-type'] === 'application/json'
  // ) {
  //   throw new Error(response?.data?.message);
  // }

  // 仅在强制登出的时候，后台在返回的data 数据中新增 logoutIndex 字段，值为true
  const logoutIndex = _.get(response, 'data.data.logoutIndex');
  const subCode = _.get(response, 'data.subCode');
  // 判断subCode是否为登录失效的情况，若是，则弹出 登出 弹窗
  // 判断logoutIndex是否为true，为true时 弹出 登出弹窗
  if (logoutIndex || (subCode && _.indexOf(Object.values(LOGOUT_CODE), subCode) > -1)) {
    const _message = _.get(response, 'data.message');
    showLogoutModal({ message: _message });
    return Promise.reject({ message: _message || '未知错误' });
  }

  return new Promise(async (resolve, reject) => {
    // 需要电子签名
    if (needSignature(response)) {
      return handleSignature({ resolve, response });
    } else {
      resolve(response);
    }
  });
};

/**
 * 请求错误处理
 * @description 针对不同的网络错误类型给出不同处理方法，如果需要是需要电子签名，或者登录信息过期或重新签名或登出
 * @param error 错误信息
 * @returns
 */
const responseErrorInterceptor = async (error: Response): Promise<any> => {
  if (error?.response) {
    const statusCode = _.get(error, 'response.status');
    const _message = _.get(error, 'response.data.message');
    const token = LocalStorage.get(FIELDS.TOKEN_NAME);
    const tips =
      error.response.status in httpCode
        ? httpCode[error.response.status]
        : error.response.data.message;

    if (signNeeded(error)) {
      return signAndReRequest(error);
    }
    return Promise.reject({ code: error.response.status, message: tips });
  } else {
    return Promise.reject({ message: error?.message || '请求失败, 请刷新重试' });
  }
};

const config = {
  baseURL: '',
  // timeout: 10000,
  paramsSerializer: (params: any) => {
    return qs.stringify(params, { arrayFormat: 'brackets', skipNulls: true });
  },
};

const instance = axios.create(config);

instance.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));
instance.interceptors.response.use(responseInterceptor, (error) => responseErrorInterceptor(error));
// axiosInstance = instance;

export default instance;
