import React from 'react';
import { Tooltip } from 'antd';
import { replaceSign } from 'src/utils/constants';

interface BlTooltipProps {
  containerStyle?: {};
  width: number;
  title: string & React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}

const BlTooltip = (props: BlTooltipProps) => {
  const { width, containerStyle, title, children, className } = props;

  const overflowStyle = {
    maxWidth: width,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    verticalAlign: 'middle',
  };

  return (
    <Tooltip title={title} className={className}>
      <span style={containerStyle ?? overflowStyle}>{children ?? title ?? replaceSign}</span>
    </Tooltip>
  );
};

export default BlTooltip;
