import { FormInstance, Select } from 'antd';
import AddUser from './addUser';
import Styles from '../components/styles.module.scss';
import AddRole from './addRole';
import AddField from './addField';
import { cloneDeep, map } from 'lodash';
import { ReceiverType } from '../interface';
import { getTypeSelect } from '../utils';

interface Props {
  onChange?: () => void;
  value?: any;
  form: FormInstance;
  roleForm: FormInstance;
  fieldForm: FormInstance;
}

export default function AddUserRoleDepartment(props: Props) {
  const { onChange, value = [], form, roleForm, fieldForm } = props;

  /**
   * 格式化 form 数据到 Select组件
   */
  const formatFormToSelect = (value: any) => {
    return value;
  };

  /**
   * 格式化 Select组件 数据到 form
   */
  const formatSelectToForm = (value: any[]) => {
    return value;
  };

  // Select组件 选择值变化, 如删除一项
  const handleSelectChange = (value: any[]) => {
    const _value = map(value, (node: any) => {
      const _node = cloneDeep(node);

      const newNode = JSON.parse(_node?.value);

      return {
        ...node,
        key: newNode?.id,
        value: _node?.value,
      };
    });

    const roleSelect = getTypeSelect(value, ReceiverType.role);

    roleForm.setFieldsValue({ roleSelect }); // 更新角色的value

    const fieldSelect = getTypeSelect(value, ReceiverType.field);

    fieldForm.setFieldsValue({ fieldSelect }); // 更新字段的value

    return onChange?.(formatSelectToForm(_value));
  };

  return (
    <div className={Styles?.addUser}>
      <Select
        placeholder="请选择"
        labelInValue
        allowClear
        mode="multiple"
        open={false}
        value={formatFormToSelect(value)}
        onChange={handleSelectChange}
      />
      <div
        style={{
          marginTop: 10,
          width: 600,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <AddUser {...props} isSelectUser form={form} />
        <AddUser {...props} isSelectUser={false} form={form} />
        <AddRole form={form} roleForm={roleForm} />
        <AddField form={form} fieldForm={fieldForm} />
      </div>
    </div>
  );
}
