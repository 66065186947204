import React, { useState } from 'react';
import { FormInstance, Select, Form } from 'antd';
import {
  fetchMaterialAttributeDetail,
  fetchMaterialAttributeList,
} from 'src/api/ytt/material-domain/Web_MaterialAttribute';
import { LabeledValue, SelectProps } from 'antd/lib/select';
import _Array from 'src/utils/dataTypes/array';
import { RuleObject } from 'antd/lib/form';
import _ from 'lodash';

const { Option } = Select;

interface Props extends SelectProps<any> {
  onChange?: (value: any) => void;
  form?: FormInstance;
  field: any;
  fieldName: string;
  formatDataToForm: any;
  type: string;
  options?: any[];
}
export const SearchSelectInput = (props: Props) => {
  const { type, onChange, form, field, formatDataToForm, options = [], fieldName } = props;
  const [inputvalue, setInputValue] = useState<{ [x: string]: LabeledValue }>({});
  const [optionsList, setOptionsList] = useState<LabeledValue[]>(options);
  const [, setFetching] = useState(false);

  const getList = async () => {
    setFetching(true);
    let selectOptionsList: LabeledValue[] = [];

    switch (type) {
      case 'materialAttributeList':
        {
          const res: any = await fetchMaterialAttributeList({ page: 1, size: 1000 });

          selectOptionsList = res?.data?.list.map((item: any) => ({
            label: item.name,
            value: item.id,
            key: item.id,
          }));
        }

        break;

      case 'getMaterialAttributeItemList':
        {
          const attributeId = form?.getFieldValue(['attributes', field.name, 'attributeId']); // 依赖 属性项的值，去查该属性项下的属性值

          if (attributeId && typeof attributeId.value === 'number') {
            const res: any = await fetchMaterialAttributeDetail({ id: attributeId.value });
            const list = res?.data?.attributeItemsList;

            if (list) {
              selectOptionsList = list.map((item: any) => ({
                label: item.content,
                value: item.id,
                key: item.id,
              }));
            }
          } else {
            selectOptionsList = [];
          }
        }

        break;

      default:
        break;
    }
    setOptionsList(selectOptionsList);
    setFetching(false);
  };

  const onChangeSelect = (value: any, index: string) => {
    typeof onChange === 'function' && onChange(value);
    const oldData = form?.getFieldValue('attributes');

    // 同步更新到FormList;
    oldData[field.name] = {
      ...oldData[field.name],
      [fieldName]: value,
    };
    form?.setFieldsValue({
      attributes: formatDataToForm(oldData),
    });

    setInputValue({
      [index]: value,
    });
  };
  const onSearchSelect = (value: any, index: string) => {
    if (value) {
      setInputValue({
        [index]: { value: '-1', label: value }, // value都为-1的话会报存在重复项
      });
    }
  };
  const onBlurSelect = (index: any) => {
    if (inputvalue[index]) {
      onChangeSelect(inputvalue[index], index);
      // form?.validateFields();

      delete inputvalue[index]; // 在onBlur后将对应的key删除，防止当从下拉框中选择后再次触发onBlur时经过此处恢复成原来的值
    }
  };
  const onFocusSelect = () => {
    getList();
  };
  const filterOption = (input: any, option: any) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  // 校验重复
  // 采用了 labelValue, 注意value:LabeledValue
  const validateRepeat = (name: string, formm?: any) => {
    return (_rule: RuleObject, value: LabeledValue) => {
      return new Promise((resolve, reject) => {
        if (!value) {
          return resolve(true);
        }
        if (name !== 'attributeId') {
          return resolve(true);
        }
        const valueList = formm.getFieldValue('attributes');
        // 从 valueList 找出目标项组成 []
        const list = valueList.map(
          (el: { attributeId: LabeledValue; attributeItemId: LabeledValue }) => el[name]?.value,
        );
        const repeatList = _Array.findDuplicates(list);

        // value 如果存在 在重复项 [] 中，则 reject
        if (repeatList.indexOf(value?.value) !== -1) {
          reject('已存在该选项！');
          return;
        }

        return resolve(true);
      });
    };
  };
  // 校验字符长度
  const validateLength = (name: string, max: number) => {
    return (_rule: RuleObject, value: LabeledValue) => {
      return new Promise<void>((resolve, reject) => {
        if (!value) {
          return resolve();
        }
        if (value.label && String(value.label).length > max) {
          return reject(`${name}不超过${max}个字符`);
        }

        return resolve();
      });
    };
  };
  // 校验前后空格
  const validatorCheckTwoSidesTrim = (name: string = '') => {
    return (_rule: RuleObject, value: LabeledValue, _callback: (error?: string) => void) => {
      if (!value) {
        return;
      }
      const str = String(value.label);

      if (_.startsWith(str, ' ') || _.endsWith(str, ' ')) {
        _callback(`${name}前后不能包含空格`);
        return;
      }
      _callback();
    };
  };

  return (
    <Form.Item
      fieldKey={[field.fieldKey, fieldName]}
      name={[field.name, fieldName]}
      rules={[
        { required: true, message: '必填' },
        { validator: validatorCheckTwoSidesTrim() },
        { validator: validateLength('', 256) },
        { validator: validateRepeat('attributeId', form) },
      ]}
      validateTrigger={['onChange', 'onBlur']}
      validateFirst
      style={{ marginBottom: '0' }}
    >
      <Select
        allowClear
        showSearch
        placeholder="请选择"
        onChange={(e) => onChangeSelect(e, '0')}
        onSearch={(value) => onSearchSelect(value, '0')}
        onBlur={() => onBlurSelect('0')}
        onFocus={() => {
          onFocusSelect();
        }}
        filterOption={filterOption}
        labelInValue
        value={inputvalue['0']}
      >
        {optionsList.map((item) => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SearchSelectInput;
