import { BlTable } from 'src/components';
import { Modal, TableColumnsType } from 'antd';
import lookup from 'src/dict';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import { renderUser } from 'src/page/share/renderUser';
import React, { useState } from 'react';
import { fetchFile } from 'src/utils/files';
import FileViewer from 'src/components/fileViewer';

interface Props {
  retractRecordDataSource: any;
}

interface RecordType {}

const RetractRecordTable = (props: Props) => {
  const { retractRecordDataSource } = props;
  const [fileViewVisible, setFileViewVisible] = useState(false);
  const [filePathList, setFilePathList] = useState<string[]>([]);
  const columns: TableColumnsType<RecordType> = [
    {
      title: '回撤数量',
      dataIndex: ['retractAmount', 'amountDisplay'],
      width: 150,
    },
    {
      title: '单位',
      dataIndex: ['retractAmount', 'unitName'],
      width: 150,
    },
    {
      title: '辅助单位数量',
      dataIndex: ['retractAuxAmount1', 'amountDisplay'],
      width: 150,
    },
    {
      title: '辅助单位',
      dataIndex: ['retractAuxAmount1', 'unitName'],
      width: 150,
    },
    {
      title: '批次号',
      dataIndex: 'batchNo',
      width: 150,
    },
    {
      title: '回撤标识码',
      dataIndex: 'inventoryIdentifier',
      width: 150,
    },
    {
      title: '回撤质量状态',
      dataIndex: 'qualityStatus',
      render: (value: number) => lookup('material.feedingQualityStatus', value) || replaceSign,
      width: 150,
    },
    {
      title: '回撤仓位',
      dataIndex: ['locationInfo', 'location', 'name'],
      width: 150,
    },
    {
      title: '回撤时间',
      dataIndex: 'retractTime',
      width: 200,
      render: (time: Date) => {
        if (!time) return replaceSign;
        return _Time.format(time);
      },
    },
    {
      title: '回撤人员',
      dataIndex: 'retractUser',
      width: 150,
      render: (value) => (value ? renderUser(value) : replaceSign),
    },
    {
      title: '回撤原因',
      dataIndex: 'retractReason',
      width: 150,
    },
    {
      title: '附件',
      dataIndex: 'appendices',
      width: 150,
      render: (value) => {
        if (value && value.length >= 1) {
          return (
            <a
              onClick={async () => {
                const filePathList = await fetchFile(value);

                if (filePathList) {
                  setFilePathList(filePathList);
                  setFileViewVisible(true);
                }
              }}
            >
              查看附件
            </a>
          );
        }
        return replaceSign;
      },
    },
  ];

  return (
    <>
      <BlTable
        scroll={{ x: 'max-content' }}
        useIndex
        columns={columns}
        dataSource={retractRecordDataSource}
      />
      {fileViewVisible && (
        <Modal
          title={'查看附件'}
          width={900}
          visible={fileViewVisible}
          footer={null}
          onCancel={() => setFileViewVisible(false)}
        >
          <FileViewer fileType={'png'} filePathList={filePathList} />
        </Modal>
      )}
    </>
  );
};

export default RetractRecordTable;
