import { FetchImportExcelGetExcelTemplateRequest } from 'src/api/ytt/import';
import { request } from 'utils';

const SERVICE_PREFIX = '/holyfile/domain';
const BASE_PREFIX_WEB = `${SERVICE_PREFIX}/web/v1/file/`;

export const getFilelist = (data: any) =>
  request.post(`${BASE_PREFIX_WEB}_list_by_search`, { loading: false }, data);

export const getFileDetail = (data: any) =>
  request.post(`${BASE_PREFIX_WEB}_detail`, { loading: false }, data);

export const fileBindDep = (data: any) =>
  request.post(`${BASE_PREFIX_WEB}_bind_dep`, { loading: false }, data);

export const fileBindBiz = (data: any) =>
  request.post(`${BASE_PREFIX_WEB}_bind_biz`, { loading: false }, data);

export const downloadImportTemplate = (params: FetchImportExcelGetExcelTemplateRequest) => {
  return request.download(
    '/imexport/domain/web/v1/import/excel/_get_excel_template',
    'post',
    '导入模板.xlsx',
    undefined,
    params,
  );
};
