import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { FilterItem, RecordListLayout } from 'layout';
import { fieldTypeList, gcObject, gcTime } from 'src/utils';
import { BlIcon, SearchSelect } from 'src/components';
import { qcStatusEnum } from 'src/dict/quality/mappings';
import { replaceSign } from 'src/utils/constants';
import { lookup } from 'src/dict';
import { AvatarDisplay } from 'src/components/avatar/show';
import _Time from 'src/utils/dataTypes/time';
import { fetchTransferOrderListRecord } from 'src/api/ytt/inventory-domain/transferOrder';
import moment, { Moment } from 'moment';
import qs from 'qs';
import { recordCommitExecuteTypeEnum } from 'src/dict/warehouse/mappings';
import { fetchCustomFieldInstanceGetByObjectCode } from 'src/api/ytt/custom-object-domain';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { injectCustomFieldColumns } from 'src/components/customField';

import authDict from 'src/utils/auth';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';

const CommitRecordList: React.FC = () => {
  const [itemId, setItemId] = useState(qs.parse(window.location.search.slice(1))?.itemId);
  const dispatch = useDispatch();
  const [customFields, setCustomFields] = useState<any>();

  const dataColumns = [
    {
      title: '业务类型',
      dataIndex: 'recordBizType',
      width: 150,
      isFilter: true,
      type: fieldTypeList?.multiSelect,
      selectProps: {
        options: recordCommitExecuteTypeEnum,
      },
      render: (recordBizType: number) => {
        return lookup('warehouse.recordCommitExecuteTypeEnum', recordBizType);
      },
    },
    {
      title: '调拨单编号',
      dataIndex: 'transferOrderCode',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList?.text,
    },
    {
      title: '调拨单行号',
      dataIndex: 'transferOrderItemLineNo',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList?.text,
    },
    {
      title: '物料编号',
      dataIndex: ['materialInfo', 'baseInfo', 'code'],
      width: 180,
    },
    {
      title: '物料名称',
      dataIndex: ['materialInfo', 'baseInfo', 'name'],
      width: 180,
    },
    {
      title: '物料',
      dataIndex: 'material',
      width: 180,
      isFilter: true,
      disabled: true,
      type: fieldTypeList?.multiSelect,
      renderItem: (
        <SearchSelect placeholder="请选择物料" fetchType={'materialCodeName'} mode={'multiple'} />
      ),
    },
    {
      title: '发出仓库',
      dataIndex: 'sourceWarehouse',
      width: 180,
      isFilter: true,
      disabled: true,
      type: fieldTypeList?.multiSelect,
      renderItem: <SearchSelect fetchType={'warehouse'} mode="multiple" />,
    },
    {
      title: '发出仓位',
      dataIndex: 'sourceLocation',
      width: 180,
      isFilter: true,
      type: fieldTypeList?.multiSelect,
      renderItem: <SearchSelect fetchType={'storageLocation'} mode={'multiple'} />,
      render: (text: unknown, record: any) => {
        return _.get(record, 'sourceLocation.location.name', replaceSign);
      },
    },
    {
      title: '接收仓库',
      dataIndex: 'targetWarehouse',
      width: 180,
      isFilter: true,
      disabled: true,
      type: fieldTypeList?.multiSelect,
      renderItem: <SearchSelect fetchType={'warehouse'} mode="multiple" />,
    },
    {
      title: '接收仓位',
      dataIndex: 'targetLocation',
      width: 180,
      isFilter: true,
      type: fieldTypeList?.multiSelect,
      renderItem: <SearchSelect fetchType={'storageLocation'} mode={'multiple'} />,
      render: (text: unknown, record: any) => {
        return _.get(record, 'targetLocation.location.name', replaceSign);
      },
    },
    {
      title: '发出数量',
      dataIndex: 'amount',
      width: 120,
      render: (text: unknown, record: any) => {
        return _.get(record, 'issueAmount.amount.amount', replaceSign);
      },
    },
    {
      title: '单位',
      dataIndex: 'unit',
      width: 120,
      render: (text: unknown, record: any) => {
        return _.get(record, 'issueAmount.amount.unit.name', replaceSign);
      },
    },
    {
      title: '发出辅助数量',
      dataIndex: 'amount1',
      width: 140,
      render: (text: unknown, record: any) => {
        return _.get(record, 'issueAmount.auxAmountList[0].amount', replaceSign);
      },
    },
    {
      title: '发出辅助单位',
      dataIndex: 'unit1',
      width: 145,
      render: (text: unknown, record: any) => {
        return _.get(record, 'issueAmount.auxAmountList[0].unit.name', replaceSign);
      },
    },
    {
      title: '接收数量',
      dataIndex: 'amount2',
      width: 120,
      render: (text: unknown, record: any) => {
        return _.get(record, 'receiveAmount.amount.amount', replaceSign);
      },
    },
    {
      title: '接收单位',
      dataIndex: 'unit2',
      width: 120,
      render: (text: unknown, record: any) => {
        return _.get(record, 'receiveAmount.amount.unit.name', replaceSign);
      },
    },
    {
      title: '接收辅助数量',
      dataIndex: 'amount3',
      width: 140,
      render: (text: unknown, record: any) => {
        return _.get(record, 'receiveAmount.auxAmountList[0].amount', replaceSign);
      },
    },
    {
      title: '接收辅助单位',
      dataIndex: 'unit3',
      width: 140,
      render: (text: unknown, record: any) => {
        return _.get(record, 'receiveAmount.auxAmountList[0].unit.name', replaceSign);
      },
    },
    {
      title: '质量状态',
      dataIndex: 'qcStatus',
      width: 120,
      isFilter: true,
      type: fieldTypeList?.multiSelect,
      selectProps: {
        options: qcStatusEnum,
      },
      render: (qcStatus: number, record: any) => {
        const qcS = _.get(record, 'issueRecord.qcStatus', replaceSign);

        return lookup('quality.qcStatusEnum', qcS);
      },
    },
    {
      title: '业务状态',
      dataIndex: 'bizStatus',
      width: 120,
      render: (bizStatus: number, record: any) => {
        const bizS = _.get(record, 'issueRecord.bizStatus', replaceSign);

        return lookup('bound.inventoryBizStatus', bizS);
      },
    },
    {
      title: '批次号',
      dataIndex: 'batchNo',
      width: 200,
      isFilter: true,
      type: fieldTypeList?.text,
      render: (batchNo: number, record: any) => {
        return _.get(record, 'issueRecord.bizKey.batchNo', replaceSign);
      },
    },
    {
      title: '标识码',
      dataIndex: 'qrCode',
      width: 180,
      isFilter: true,
      type: fieldTypeList?.text,
      render: (qrCode: number, record: any) => {
        return _.get(record, 'issueRecord.qrCode', replaceSign);
      },
    },
    {
      title: '供应商',
      dataIndex: 'supplier',
      width: 200,
      render: (text: unknown, record: any) => {
        return _.get(record, 'issueRecord.bizKey.supplier.name', replaceSign);
      },
    },
    {
      title: '供应商批次',
      dataIndex: 'supplierBatchNo',
      width: 200,
      render: (text: unknown, record: any) => {
        return _.get(record, 'issueRecord.bizKey.supplierBatchNo', replaceSign);
      },
    },
    {
      title: '客户',
      dataIndex: 'customer',
      width: 200,
      render: (text: unknown, record: any) => {
        return _.get(record, 'issueRecord.bizKey.customer.name', replaceSign);
      },
    },
    {
      title: '生产日期',
      dataIndex: 'productTime',
      width: 180,
      render: (text: unknown, record: any) => {
        const productTime = _.get(record, 'issueRecord.bizKey.productTime', 0);

        if (!productTime) return replaceSign;
        return _Time.format(productTime);
      },
    },
    {
      title: '保质期至',
      dataIndex: 'validityPeriod',
      width: 180,
      render: (text: unknown, record: any) => {
        const validityPeriod = _.get(record, 'issueRecord.bizKey.validityPeriod', 0);

        if (!validityPeriod) return replaceSign;
        return _Time.format(validityPeriod);
      },
    },
    {
      title: '入厂日期',
      dataIndex: 'inboundTime',
      width: 180,
      render: (text: unknown, record: any) => {
        const inboundTime = _.get(record, 'issueRecord.bizKey.inboundTime', 0);

        if (!inboundTime) return replaceSign;
        return _Time.format(inboundTime);
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 200,
    },
    {
      title: '附件',
      dataIndex: 'documents',
      width: 200,
      render: (documents: number[]) => {
        return <a onClick={() => {}}>查看</a>;
      },
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 160,
      isFilter: true,
      type: fieldTypeList?.multiSelect,
      renderItem: <SearchSelect fetchType={'user'} mode={'multiple'} />,
      render: (creator: any = {}) => {
        if (!creator) return replaceSign;
        return <AvatarDisplay {...creator} key={creator?.id} isShowTag isUser />;
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 180,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      render: (createdAt: number) => {
        if (!createdAt) return replaceSign;
        return _Time.format(createdAt);
      },
    },
    {
      title: '',
      dataIndex: 'action',
      width: 60,
      fixed: 'right',
      render: () => ' ',
    },
  ];

  const openModal = useOpenExImportModal();

  const config = {
    rowKey: (record: any) => String(record.id),
    columns: injectCustomFieldColumns({
      columns: dataColumns.filter((d) => !d.disabled), // 原本的列
      customFields, // 自定义字段信息
      objectCode: OBJECT_OF_CODE.transferRecord, // 从对象code
      type: 'detail', // 使用类型
    }),
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((columns: any): FilterItem => {
        const filter = {
          label: columns.title,
          name: columns.dataIndex,
          ...columns,
        };

        return filter;
      }),
    formatDataToFormDisplay: (filterData: any) => {
      const { createdAt, ...rest } = filterData;

      const result = {
        ...rest,
        createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
      };

      return gcObject.filterEmptyProperty(result);
    },
    formatDataToQuery: (params: any) => {
      const {
        bizType,
        transferOrderCode,
        transferOrderItemLineNo,
        qrCode,
        batchNo,
        sourceWarehouse,
        targetWarehouse,
        sourceLocation,
        targetLocation,
        createdAt,
        creator,
        material,
        recordBizType,
        sorter,
        ...rest
      } = params;
      const [createdAtFrom, createdAtTill] = createdAt?.map((time: Moment) =>
        moment(time).set('millisecond', 0).format('x'),
      ) || [undefined, undefined];

      const options = {
        ...rest,
        transferOrderCodes: transferOrderCode?.split(','),
        transferOrderLineNos: transferOrderItemLineNo?.split(','),
        bizTypes: bizType,
        qrCodes: qrCode?.split(','),
        batchNos: batchNo?.split(','),
        creatorIds: creator?.map((item: any) => item.value),
        materialIds: material?.map((item: any) => item.value),
        sourceWarehouseIds: sourceWarehouse?.map((item: any) => item.value),
        targetWarehouseIds: targetWarehouse?.map((item: any) => item.value),
        sourceLocationIds: sourceLocation?.map((item: any) => item.value),
        targetLocationIds: targetLocation?.map((item: any) => item.value),
        createdAtFrom,
        createdAtTill,
        transferOrderItemId: itemId,
        recordBizTypes: recordBizType,
        sorter: sorter?.map((sort: { field: string; order: string }) => {
          let _field = sort.field;

          if (_field === 'transferOrderCode') {
            _field = 'code';
          } else if (_field === 'transferOrderItemLineNo') {
            _field = 'lineNo';
          }

          return {
            ...sort,
            field: _field,
          };
        }),
      };

      setItemId(undefined);

      const _options = gcObject.filterEmptyProperty(options);

      dispatch.excelImport.updateBusinessData({ businessData: _options });

      return gcObject.filterEmptyProperty(_options);
    },
    mainMenu: [
      {
        title: '导出',
        icon: <BlIcon type="icondaochu" />,
        showExport: true,
        auth: authDict.transferrecord_export,
        onClick: () =>
          openModal({
            optType: 'export',
            businessType: BUSINESS_TYPE.transferOrderRecord,
          }),
      },
    ],
  };

  /** 获取对象自定义字段 */
  const fetchCustomFields = async () => {
    const res = await fetchCustomFieldInstanceGetByObjectCode({
      objectCode: OBJECT_OF_CODE.transferRecord,
    });

    setCustomFields((res?.data as any) || {});
  };

  useEffect(() => {
    fetchCustomFields();
  }, []);

  return (
    <>
      <RecordListLayout<any>
        placeholder={'输入标识码，回车快速查询'}
        configcacheKey="commitRecordList"
        {...config}
        requestFn={fetchTransferOrderListRecord}
      />
    </>
  );
};

export default CommitRecordList;
