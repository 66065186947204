import { useState, useEffect } from 'react';
import { Modal, Form, Button, Space } from 'antd';
import { RecordListLayoutProps } from '@blacklake-web/layout';
import { BlIcon, BlFilterForm } from '@blacklake-web/component';
import _ from 'lodash';

import { RecordListLayout } from 'layout';

import Styles from './index.module.scss';

export interface BatchListSelectorProps<RecordType> extends RecordListLayoutProps<RecordType> {
  visible: boolean;
  defaultQueryConditions?: { [query: string]: any };
  title?: React.ReactNode;
  okText?: string;
  cancelText?: string;
  maxCount?: number;
  originCount?: number;
  selectedKeys?: React.Key[];
  countLimitHint?: string;
  /**
   * 筛选展示模式
   */
  filterMode?: 'record' | 'normal';
  onCancel: () => void;
  onFinish: (selectedRowKeys: React.Key[], selectedRows: RecordType[]) => void;
}

const onFinishFailed = (errorInfo: any) => {
  // eslint-disable-next-line no-console
  console.log('Failed:', errorInfo);
};

/** 从列表批量选择多行添加的弹窗组件 */
/**
 * @param visible 弹窗是否显示
 * @param title 弹窗标题
 * @param okText 弹窗确认按钮文字
 * @param cancelText 弹窗取消按钮文字
 * @param onCancel 取消弹窗事件处理函数
 * @param onFinish 确定按钮处理函数
 * @param defaultQueryConditions 弹窗的默认筛选条件（不显示在表单中，却需要传的默认参数）
 * @param maxCount 最多选择行数
 * @param originCount 原有行数，外层表单已有行数
 * @param selectedKeys 回填数据时已选择行的key，如不需要回填可不传
 * @param countLimitHint 行数超过限制时的提示文字
 * 透传RecordListLayout的其他参数
 */
export default function BatchListSelector<RecordType extends object>({
  visible,
  title = '批量添加多行',
  okText = '添加',
  cancelText = '暂不添加',
  onCancel,
  onFinish,
  filterList,
  maxCount,
  originCount = 0,
  selectedKeys,
  countLimitHint,
  formatDataToQuery,
  defaultQueryConditions = {},
  filterMode = 'normal',
  columns,
  ...restProps
}: BatchListSelectorProps<RecordType>) {
  const [refreshMarker, setRefreshMarker] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(selectedKeys ?? []);
  const [selectedRows, setSelectedRows] = useState<RecordType[]>([]);
  const [showCountLimit, setShowCountLimit] = useState(false);
  const [queryForm] = Form.useForm();

  const modifiedFormatDatatoQuery = (params: any) => {
    try {
      const formData = queryForm.getFieldsValue() ?? {};

      const queryPram = formatDataToQuery
        ? formatDataToQuery({
            ...params,
            ...formData,
          })
        : {
            ...params,
            ...formData,
          };

      return {
        ...queryPram,
        ...defaultQueryConditions,
      };
    } catch (err) {
      console.log('validate error :>> ', err);
    }
  };

  const getFilterList = () => {
    switch (filterMode) {
      case 'normal':
        // 返回空的占位
        return undefined;
      case 'record':
        return filterList;
      default:
        return undefined;
    }
  };

  const handleFinish = () => {
    if (_.isNil(maxCount) || selectedRowKeys.length <= maxCount) {
      onFinish(selectedRowKeys, selectedRows);
    } else {
      setShowCountLimit(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSelectedRowKeys(selectedKeys ?? []);
    });
  }, [selectedKeys]);

  useEffect(() => {
    if (!visible) queryForm.resetFields();
  }, [visible]);

  const renderNormalFilter = () => {
    return (
      <div className={Styles.conditionContainer}>
        <BlFilterForm
          formProps={{ layout: 'inline', style: { width: '100%' } }}
          form={queryForm}
          onFinishFailed={onFinishFailed}
          onFinish={() => setRefreshMarker(Date.now())}
          filterList={filterList ?? []}
        />
        <Space align="start" size="middle">
          <Button
            type="primary"
            onClick={() => {
              setRefreshMarker(Date.now());
            }}
          >
            查询
          </Button>
          <Button
            onClick={() => {
              queryForm.resetFields();
              setRefreshMarker(Date.now());
            }}
          >
            重置
          </Button>
        </Space>
      </div>
    );
  };

  const renderInfo = () => {
    let showInfo = true;

    if (filterMode === 'record') {
      showInfo = false;
    }

    return (
      showInfo && (
        <div className={Styles.hintInfo}>
          <span>{`已选择${originCount + selectedRowKeys.length}项`}</span>
          {!_.isNil(maxCount) && (
            <span className={Styles.maxHint}>{`，还可选择${
              maxCount - originCount - selectedRowKeys.length
            }项（包含重复数据）`}</span>
          )}
          {showCountLimit && (
            <span className={Styles.errorHint}>
              {countLimitHint ?? `最多只可选择${maxCount}项`}
            </span>
          )}
        </div>
      )
    );
  };

  return (
    <Modal
      visible={visible}
      title={title}
      width={1200}
      zIndex={1050}
      bodyStyle={{ padding: 0 }}
      okText={okText}
      cancelText={cancelText}
      onCancel={onCancel}
      okButtonProps={{ disabled: selectedRowKeys.length === 0 }}
      onOk={handleFinish}
      destroyOnClose
    >
      {filterMode === 'normal' && renderNormalFilter()}
      {renderInfo()}
      <RecordListLayout<RecordType>
        {...restProps}
        columns={_.map(columns, (item) => _.omit(item, 'filterConfig'))}
        style={{ height: 500 }}
        refreshMarker={refreshMarker}
        useFilterWithUrl={false}
        useColConfig={false}
        useFilterConfig={false}
        useQuickFilter={filterMode === 'record'}
        filterList={getFilterList()}
        useIndex={false}
        filterContaniner={false}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(sKeys, selectedRows) => {
          setSelectedRowKeys(sKeys);
          setSelectedRows(selectedRows!);
          setShowCountLimit(false);
        }}
        formatDataToQuery={modifiedFormatDatatoQuery}
      />
    </Modal>
  );
}
