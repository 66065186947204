import React, { useEffect, useState } from 'react';
import { Checkbox, Empty, Form, Pagination, Select } from 'antd';
import { BlIcon } from 'src/components';
import { appEnum } from 'src/dict';
import NotificationMessageContent from './messageContent';
import styles from './styles.module.scss';
import _Array from 'src/utils/dataTypes/array';
import { NOTIFICATION_TYPE } from 'src/dict/notification';
import { throttle } from 'lodash';
import {
  fetchMessageBulkUpdateMessageStatus,
  fetchMessageGroup,
  fetchMessageList,
  fetchMessageUpdateAllStatus,
} from 'src/api/ytt/message-domain/messageList';
import _Url from 'src/utils/url';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { useHistory } from 'react-router-dom';
import { getLayout, notifyJump, notifyJumpByLayout, notifyNeedModal } from './notifyJumpDict';
import { GetCustomLayoutForNotify } from './notifyModal';

interface Props {
  type: string;
}
export interface ListParams {
  page?: number;
  size?: number;
  moduleId?: number;
  bizTypeId?: number;
  type?: number;
  chooseType?: string;
  readStatus?: number;
  urgent?: number;
}

const CHOOSE_TYPE = new Map([
  ['tile', 0],
  ['business_object', 1],
  ['business_type', 2],
]);

export default function NotificationMessage({ type }: Props) {
  const [list, setList] = useState([] as any);
  const [total, setTotal] = useState<number>(0);
  const [oldType, setOldType] = useState<string>('tile'); // 存储一次旧类型是为了点击返回的时候，获取上一次进入聚合模式时选择的类型
  const [updateAll, setUpdateAll] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [objectData, setObjectData] = useState({} as any);

  const history = useHistory();
  const dispatch = useDispatch();

  const [messageForm] = Form.useForm();

  const { totalUnread = 0 } = useSelector((state: RootState) => state?.message, shallowEqual);

  const urlParams = _Url.getParams().urlParams && JSON.parse(_Url.getParams().urlParams);

  const { pagination, ...rest } = urlParams ?? {};

  /**
   * 把查询数据同步到url上
   */
  const setFilterToUrl = (params: ListParams) => {
    const { page, size, bizTypeId, moduleId, type, chooseType, readStatus, urgent } = params;

    const _params: any = {
      pagination: {
        currentPage: page ?? 1,
        currentSize: size ?? 10,
      },
    };

    if (type) {
      // 选择的显示方式值的映射参数
      _params.type = type;
    }
    if (chooseType) {
      // 选择的显示方式文本值的映射参数
      _params.chooseType = chooseType;
    }

    if (bizTypeId) {
      // 业务类型
      _params.bizTypeId = bizTypeId;
    }
    if (moduleId) {
      // 业务对象
      _params.moduleId = moduleId;
    }

    if (!readStatus) {
      // 未读0
      _params.readStatus = readStatus;
    }

    if (urgent) {
      // 紧急
      _params.urgent = urgent;
    }

    _Url.setParams({ urlParams: JSON.stringify(_params) });
  };

  // 获取平铺和单个聚合下的消息
  const fetchList = throttle(async (params: any) => {
    try {
      const { data } = await fetchMessageList({
        page: params?.page,
        size: params?.size,
        ...params,
      });

      if (data) {
        setFilterToUrl(params);
        setList(data?.list);
        setTotal(data?.total);
        setUpdateAll(Boolean(!data?.totalUnread));
      }
    } catch (err) {
      console.log(err);
    }
  }, 500);

  // 根绝类型获取聚合数据
  const getAggregationData = throttle(async (params: any) => {
    try {
      const { data } = await fetchMessageGroup({
        page: params?.page,
        size: params?.size,
        type: params?.type,
      });

      if (data) {
        setFilterToUrl(params);
        setList(data?.list);
        setTotal(data?.total ?? 0);
        setUpdateAll(Boolean(!data?.totalUnread));
      }
    } catch (err) {
      console.log(err);
    }
  }, 500);

  useEffect(() => {
    setFilterToUrl({ page: 1, size: 10 });
    fetchList({ page: 1, size: 10 });
    messageForm.setFieldsValue({ showType: 'tile' });
  }, []);

  const onChange = (page: number, size: number) => {
    const chooseType = messageForm.getFieldValue('showType');

    // 分页器的触发有以下几种情况
    switch (chooseType) {
      case NOTIFICATION_TYPE.tile:
        fetchList({ page, size, ...rest });
        break;
      case NOTIFICATION_TYPE.business_object:
        getAggregationData({ page, size, type: 1 });
        break;
      // case NOTIFICATION_TYPE.business_type:
      //   getAggregationData({ page, size, type: 2 });
      //   break;
      case 'categoryDetail':
        fetchList({ page, size, ...rest });
        break;
      default:
        break;
    }
  };

  const onChooseChange = (value: string) => {
    // 显示方式改变时，要根据类型去获取聚合的数据
    setOldType(value);
    if (value === NOTIFICATION_TYPE.tile) {
      // 平铺
      fetchList({ page: pagination?.currentPage, size: pagination?.currentSize });
    } else {
      // 聚合分类
      messageForm.setFieldsValue({ urgent: false, unRead: false });
      getAggregationData({
        page: pagination?.currentPage,
        size: pagination?.currentSize,
        type: CHOOSE_TYPE.get(value),
        chooseType: value,
      });
    }
  };

  const updateMessageStatus = async (nodeData: any) => {
    try {
      // 1.判断已读/未读 未读消息需要先转已读   status 0未读/1已读
      // 2. 有详情并跳转对应详情页

      if (nodeData?.status === 0) {
        // 更新未读消息
        const { code } = await fetchMessageBulkUpdateMessageStatus([nodeData?.id]);

        if (code === 200) {
          // 未读变已读  重新获取已读后的数据
          fetchList({
            page: pagination?.currentPage,
            size: pagination?.currentSize,
            ...rest,
          });

          dispatch({ type: 'message/getMessageUnReadTotal' });
        }
      }

      if (JSON.stringify(nodeData?.meta) === '{}') return;

      if (!notifyNeedModal(nodeData?.meta?.objectCode)) return notifyJump(nodeData?.meta); // 代表具有详情实例

      // 接了自定义详情布局的需要先选择布局

      const data = await getLayout(nodeData?.meta);

      // 如果只有一条数据 直接跳转

      if (data?.length === 1) {
        notifyJumpByLayout(nodeData?.meta, data?.[0]?.value);
      } else {
        setObjectData(nodeData?.meta);
        setVisible(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const readAllMessage = async () => {
    try {
      // 全部已读消息的时候 如果是平铺/业务类型/业务对象 均是全部已读
      // 在业务类型或者业务对象下的模块 属于部分全部已读
      const { moduleId, bizTypeId, urgent, readStatus, type } = rest;

      const { code } = await fetchMessageUpdateAllStatus({ moduleId, bizTypeId, urgent });

      if (code === 200) {
        setUpdateAll(true);

        if (type) {
          // 代表是聚合模式下的全部已读
          getAggregationData({ page: 1, size: 10, type });
          dispatch({ type: 'message/getMessageUnReadTotal' });
        } else {
          fetchList({ page: 1, size: 10, moduleId, bizTypeId, urgent, readStatus });
          dispatch({ type: 'message/getMessageUnReadTotal' });
        }
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const unReadAndUrgentHidden =
    messageForm.getFieldValue('showType') === NOTIFICATION_TYPE.business_object;
  // messageForm.getFieldValue('showType') === NOTIFICATION_TYPE.business_type;

  return (
    <>
      <div className={styles?.notificationCardHeader}>
        <div className={styles?.left}>
          <Form form={messageForm}>
            {messageForm.getFieldValue('showType') !== 'categoryDetail' && (
              <div className={styles?.showType} style={{ width: 300 }}>
                <span className={styles?.showSelectLabel}>显示方式 :</span>
                <span className={styles?.showSelect}>
                  <Form.Item name="showType">
                    <Select
                      onChange={(value) => {
                        onChooseChange(value);
                      }}
                      defaultValue={oldType}
                      style={{ width: '100%' }}
                      options={appEnum.notification.notification_type}
                    />
                  </Form.Item>
                </span>
              </div>
            )}
            {messageForm.getFieldValue('showType') === 'categoryDetail' && (
              <div
                className={styles?.showType}
                style={{ width: 80, cursor: 'pointer' }}
                onClick={() => {
                  getAggregationData({
                    page: 1,
                    size: pagination?.currentSize ?? 10,
                    type: CHOOSE_TYPE.get(oldType),
                    chooseType: oldType,
                  });
                  messageForm.setFieldsValue({ showType: oldType });
                }}
              >
                <BlIcon type="iconfanhui" />
                <span style={{ marginLeft: 5 }}>返回</span>
              </div>
            )}
            <div className={styles?.showType}>
              <Form.Item name="urgent" valuePropName="checked" hidden={unReadAndUrgentHidden}>
                <Checkbox
                  onChange={(e: any) => {
                    const { urgent, ...relRest } = rest;

                    if (e?.target?.checked) {
                      setFilterToUrl({
                        ...relRest,
                        page: 1,
                        size: pagination?.currentSize,
                        urgent: 1,
                      });
                      fetchList({ ...relRest, urgent: 1, page: 1, size: pagination?.currentSize });
                    } else {
                      setFilterToUrl({ ...relRest, page: 1, size: pagination?.currentSize });
                      fetchList({ ...relRest, page: 1, size: pagination?.currentSize });
                    }
                  }}
                  disabled={unReadAndUrgentHidden}
                >
                  只看紧急
                </Checkbox>
              </Form.Item>
            </div>
            <div className={styles?.showType}>
              <Form.Item name="unRead" valuePropName="checked" hidden={unReadAndUrgentHidden}>
                <Checkbox
                  onChange={(e: any) => {
                    const { readStatus, ...relRest } = rest;

                    if (e?.target?.checked) {
                      setFilterToUrl({
                        ...relRest,
                        page: 1,
                        size: pagination?.currentSize,
                        readStatus: 0,
                      });
                      fetchList({
                        ...relRest,
                        readStatus: 0,
                        page: 1,
                        size: pagination?.currentSize,
                      });
                    } else {
                      setFilterToUrl({ ...relRest, page: 1, size: pagination?.currentSize });
                      fetchList({ ...relRest, page: 1, size: pagination?.currentSize });
                    }
                  }}
                  disabled={unReadAndUrgentHidden}
                >
                  只看未读
                </Checkbox>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className={styles?.right}>
          <div className={styles?.single}>
            <div>未读消息</div>
            <div className={styles?.num}>{totalUnread > 99 ? '99+' : totalUnread}</div>
          </div>
          <div
            className={styles?.single}
            style={{ color: updateAll ? 'rgba(0, 0, 0, 0.65)' : '#02b980' }}
            onClick={() => {
              readAllMessage();
            }}
          >
            全部已读
          </div>
          <div
            className={styles?.single}
            onClick={() => {
              history.push('/notification/notificationMessageUrl/set');
            }}
          >
            <div style={{ marginRight: 3 }}>
              <BlIcon type="iconshezhi" />
            </div>
            <div style={{ cursor: 'pointer' }}>通知设置</div>
          </div>
        </div>
      </div>
      <div className={styles?.notificationCardContent}>
        {_Array.isEmpty(list) ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <NotificationMessageContent
            messageForm={messageForm}
            data={list}
            type={type}
            getAggregationListFn={fetchList}
            updateMessageStatus={(nodeData: any) => {
              updateMessageStatus(nodeData);
            }}
          />
        )}
      </div>
      <div className={styles?.notificationPagination}>
        <Pagination
          current={pagination?.currentPage ?? 1}
          size={pagination?.currentSize ?? 10}
          showSizeChanger
          total={total}
          showTotal={(total) => `共 ${total} 条`}
          showQuickJumper
          onChange={onChange}
        />
      </div>
      {visible && (
        <GetCustomLayoutForNotify
          objectData={objectData}
          visible={visible}
          handleCancel={() => {
            setVisible(false);
          }}
        />
      )}
    </>
  );
}
