import { Worker, Viewer, RotateDirection } from '@react-pdf-viewer/core';
import { RenderZoomOutProps, zoomPlugin } from '@react-pdf-viewer/zoom';
import { RenderRotateProps, rotatePlugin } from '@react-pdf-viewer/rotate';
import { getFilePlugin, RenderDownloadProps } from '@react-pdf-viewer/get-file';

import {
  ZoomOutOutlined,
  ZoomInOutlined,
  RotateLeftOutlined,
  OneToOneOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import './style.scss';
import authDict, { hasAuth } from 'src/utils/auth';
import { BlIcon } from 'src/components';

interface PdfViewProps {
  file: any;
  style?: any;
}

const PdfView = (props: PdfViewProps) => {
  const { file, style = {} } = props;
  const isShow = file.type !== 'application/pdf' || !file.url || !file.fileUri;
  const zoomPluginInstance = zoomPlugin();
  const rotatePluginInstance = rotatePlugin();
  const getFilePluginInstance = getFilePlugin();

  const { ZoomIn, ZoomOut, CurrentScale, zoomTo } = zoomPluginInstance;
  const { Rotate } = rotatePluginInstance;
  const { Download } = getFilePluginInstance;

  return (
    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js">
      <div style={style} className="pdf-view-wrapper">
        {isShow && (
          <div className="pdf-view-actions">
            <ZoomOut>{(props: RenderZoomOutProps) => <ZoomOutOutlined {...props} />}</ZoomOut>
            <CurrentScale />
            <ZoomIn>{(props: RenderZoomOutProps) => <ZoomInOutlined {...props} />}</ZoomIn>
            <a onClick={() => zoomTo(1)}>
              <OneToOneOutlined />
            </a>
            <Rotate direction={RotateDirection.Backward}>
              {(props: RenderRotateProps) => <RotateLeftOutlined {...props} />}
            </Rotate>
            {hasAuth(authDict.document_down_file) && (
              <Download>
                {(props: RenderDownloadProps) => <BlIcon {...props} type="iconxiazai" />}
              </Download>
            )}
          </div>
        )}
        {isShow ? (
          <Viewer
            fileUrl={file.url || file.fileUri}
            plugins={[zoomPluginInstance, rotatePluginInstance, getFilePluginInstance]}
          />
        ) : (
          <div style={{ color: '#fff' }}>
            <InfoCircleOutlined style={{ color: '#FAAD14' }} /> 此文件暂不支持预览
          </div>
        )}
      </div>
    </Worker>
  );
};

export default PdfView;
