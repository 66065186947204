import React, { useContext, useEffect, useState } from 'react';
import { DataFormLayoutForModal } from 'layout';
import { Form, Checkbox, message as Message } from 'antd';
import { QUALITY, StoreContext } from '../constant';
import PositionBaseInfo from '../components/positionsCreateAndEditBaseInfo';
import { fetchStorageLocationCreate } from 'src/api/ytt/storage-domain/position';
import { fetchStorageWarehouseInfo } from 'src/api/ytt/storage-domain/warehouse';
import { throttleFn } from '../utils';

interface createProps {
  visible: Boolean;
  onCancel: () => void;
  refreshTable: () => void;
  storeQcCtrl?: number;
}

const CreatePositions = (props: createProps) => {
  const { visible, onCancel, refreshTable, storeQcCtrl } = props;

  const [modalForm] = Form.useForm();
  const [continuousCreate, setContinuousCreate] = useState(false);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [storeCtrlList, setStoreCtrlList] = useState<number[]>();

  const [qcCtrl, setQcCtrl] = useState(false);

  const {
    parentAreaSource: { parentAreaId, parentAreaStorageType },
    storeHouseSource: { StoreHouseId },
  } = useContext(StoreContext);

  // 获取仓库的质量管理状态
  const getStoreCtrlList = async () => {
    try {
      const { data } = await fetchStorageWarehouseInfo({ id: StoreHouseId }, { legacy: true });

      if (data) {
        setStoreCtrlList(data?.qcCtrlList);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getStoreCtrlList();
  }, []);

  const setQcCtrlFn = (e: boolean) => {
    setQcCtrl(e);
  };

  const extra = (
    <Checkbox
      onChange={(e) => {
        setContinuousCreate(e?.target?.checked);
      }}
    >
      连续新建
    </Checkbox>
  );

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();

      const formatEnableQcCtrl = value?.enableQcCtrl ? 1 : 0;
      const formatQcCtrlList = value?.enableQcCtrl ? value?.qcCtrlList : [];
      const newValue = {
        ...value,
        qcCtrlList: formatQcCtrlList,
        enableQcCtrl: formatEnableQcCtrl,
        warehouseId: StoreHouseId,
        parentId: parentAreaId,
        parentType: parentAreaStorageType,
      };

      const { code, message } = await fetchStorageLocationCreate(newValue);

      if (code === 200) {
        Message.success('创建仓位成功');
        if (continuousCreate) {
          setQcCtrl(false);
          modalForm.resetFields();
          modalForm?.setFieldsValue({
            qcCtrlList: QUALITY.map((node) => node?.id),
            code_isUseRules: true,
          });
          typeof refreshTable === 'function' && refreshTable();
          setRefreshMarker(Date.now());
          return;
        }
        typeof refreshTable === 'function' && refreshTable();
        typeof onCancel === 'function' && onCancel();
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      onClose={onCancel}
      width={800}
      content={
        <PositionBaseInfo
          edit={false}
          title="新建仓位"
          onCancel={onCancel}
          onFinish={throttleFn(handleFinish)}
          form={modalForm}
          extra={extra}
          qcCtrl={qcCtrl}
          setQcCtrlFn={setQcCtrlFn}
          refreshMarker={refreshMarker}
          storeQcCtrl={storeQcCtrl}
          storeCtrlList={storeCtrlList}
        />
      }
    />
  );
};

export default CreatePositions;
