import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { customEvents } from 'src/utils/events';
import _ from 'lodash';
import { formatTooltipHtml } from '../utils';

type BarGroupProps = {
  title: string;
  unit: string | string[];
  yAxisTitle: string[];
  chartData: any;
};

const BarGroup: React.FC<BarGroupProps> = (props) => {
  const { title, unit, yAxisTitle, chartData } = props;
  const chartRef = useRef<HTMLDivElement>(null);
  const chart = useRef<echarts.ECharts>();
  const colors = ['#b384ff', '#88ecbe', '#ff8e7f', '#72d7f6', '#f9bd32'];

  useEffect(() => {
    chart.current = echarts.init(chartRef.current as HTMLDivElement);
    setTimeout(() => {
      chart?.current?.resize();
    }, 0);

    customEvents.on('resize', () => {
      chart?.current?.resize();
    });
  }, []);

  const renderHtmlChunk = (params: { seriesName: string; color: string; value: any }[]) => {
    let resultHtml = '';

    params.forEach(({ seriesName, color, value }) => {
      resultHtml += `
        <div>
          <div style="margin-top: 8px; font-size: 14px; font-weight: 400; color: #262626;">
            <span style="display: inline-block; margin-right: 4px; width: 10px; height: 10px; background-color: ${color};"></span>
            <span style="
              white-space: normal;
              word-wrap: break-word;
              word-break: break-all;
            ">${seriesName}</span>
          </div>
          <div style="font-size: 12px; color: #262626;">
            <span>${title}: </span>
            <span style="color:${color};">${value[seriesName]}${unit}</span>
          </div>
        </div>
      `;
    });

    return resultHtml;
  };

  /** 刷新数据 */
  const setOption = (source: any[], dimensions: string[]) => {
    const option = {
      legend: {
        show: true,
        itemWidth: 6,
        itemHeight: 6,
        right: 20,
        top: 15,
        formatter: (name: string) => {
          return name.length > 8 ? name.slice(0, 8) + '...' : name;
        },
      },
      grid: {
        left: 50,
        right: 50,
        top: 60,
        bottom: 45,
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        // enterable: true,
        padding: 0,
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        position: (point: number[], params: any, dom: HTMLElement, rect: any, size: any) => {
          const [x, y] = point;
          const {
            contentSize: [width, height],
          } = size;

          return [x - width - 20, y - height / 2];
        },
        appendToBody: true,
        formatter: _.debounce(
          (params: any[]) => {
            return formatTooltipHtml(renderHtmlChunk(params), params[0]?.axisValueLabel);
          },
          300,
          { leading: true, maxWait: 100 },
        ),
      },
      color: colors,
      xAxis: {
        type: 'category',
        axisLabel: {
          show: true,
          interval: 0,
          formatter: (value: string) => {
            return value.length > 8 ? value.slice(0, 7) + '...' : value;
          },
          width: 48,
          color: 'rgba(0, 0, 0, 0.65)',
          overflow: 'breakAll',
        },
      },
      yAxis: {
        name: yAxisTitle[0],
        nameTextStyle: {
          align: 'center',
          padding: [0, 0, 0, 10],
          color: 'rgba(0, 0, 0, 0.25)',
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
        axisLabel: {
          color: 'rgba(0, 0, 0, 0.25)',
        },
      },
      dataZoom: [
        {
          type: 'inside',
          show: true,
          xAxisIndex: [0],
          start: 0,
          end: 99,
          textStyle: {
            color: '#ccd7d7',
          },
        },
      ],
      dataset: {
        dimensions,
        source,
      },
      series: source[0]
        ? Object.keys(source[0])
            .slice(1)
            .map(() => ({ type: 'bar', barMinWidth: 6, barMaxWidth: 12, barGap: '0%' }))
        : [],
    };

    chart?.current?.setOption(option, true);
  };

  useEffect(() => {
    if (_.isEmpty(chartData)) {
      return;
    }
    const _chartData = _.groupBy(chartData, 'supplier.name');
    const source: any[] = [];
    const dimensions = ['supplier'];

    Object.keys(_chartData)?.forEach((key: string, index: number) => {
      const tempArr: any = {
        supplier: '',
      };

      tempArr.supplier = key;
      _chartData[key].forEach((item) => {
        tempArr[item.material.baseInfo.name] = item.rate ?? item.deliveryCycle;
      });
      source.push(tempArr);

      if (index === 0) {
        _chartData[key].forEach((item) => {
          dimensions.push(item.material.baseInfo.name);
        });
      }
    });

    setOption(source, dimensions);
  }, [chartData]);

  return <div ref={chartRef} style={{ height: 280, width: '100%' }} />;
};

export default BarGroup;
