import { useEffect, ReactElement } from 'react';
import { max } from 'lodash';
import { Input, Form, FormInstance, Select } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import type { NamePath } from 'antd/lib/form/interface';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { SearchSelect } from 'src/components';
import BlSortFormList from 'src/components/sortList/BlSortFormList';
import { arrayIsEmpty } from 'src/utils/array';
import _Array from 'src/utils/dataTypes/array';
import { validatePositiveInteger } from 'src/page/knowledgeManagement/share/warehouseRules';
import { validatorCheckTwoSidesTrim } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { UserListItem, workCenterGroupCOListDisableList } from '../constants';

/**
 *  可选资源item
 */
interface BizIdListItem {
  key?: string | number;
  label: string;
  value?: string | number;
}
interface TypeItem {
  code?: number;
  message?: string;
}
/**
 *  Form
 */
export interface WorkCenterGroupCOListFormField {
  lineNo?: number; // 行号
  groupType?: number | TypeItem; // 资源组分类
  resourceGroupName?: string; // 资源组名称
  name?: number | string; // 工作中心详情后端返回 资源组名称
  bizType?: number | TypeItem; // 当组员组分类为用户时 可选资源类型
  userList?: UserListItem[]; // 工作中心详情后端返回 拼接好name的 用户list
  departmentList?: UserListItem[] | null; // 工作中心详情后端返回 拼接好name的 部门list
  resourceList?: UserListItem[] | null; // 工作中心详情后端返回 拼接好name的 资源list
  bizIdList?: number[]; // 可选资源list
  id?: number;
  departmentListFilter?: number[]; // 用来存创建生产任务 用户下部门的searchSelect的筛选条件
}

export interface WorkCenterGroupCOListFormForSubmit
  extends Omit<WorkCenterGroupCOListFormField, 'bizIdList'> {
  bizIdList?: BizIdListItem[]; // 可选资源list
}

interface ResourcesProps {
  initialData?: WorkCenterGroupCOListFormField[] | Boolean;
  form: FormInstance;
  filedName: NamePath;
  defaultDataList?: Boolean; // 预置资源组 不可删除
  onlySelectBiz?: Boolean; // 只可编辑可选资源 不可删除 不可添加
  limit?: Boolean; // 限制可选资源选择范围（目前只有创建生产任务使用
}

// 最大添加数量
const MAX_SUPPORTED_ITEM = 10;

const { Option } = Select;

// 资源组分类
const GroupTypesEnum = {
  USER: 0, // 用户
  DEPARTMENT: 1, // 部门
  GROUPTYPE: 2, // 资源分类
};

// 资源分类选项
const OPTIONS = [
  {
    label: '用户',
    key: GroupTypesEnum.USER,
  },
  {
    label: '部门',
    key: GroupTypesEnum.DEPARTMENT,
  },
  {
    label: '设备',
    key: GroupTypesEnum.GROUPTYPE,
  },
];

// 用户可选资源类型
const USEROPTIONS = [
  {
    label: '指定用户',
    key: GroupTypesEnum.USER,
  },
  {
    label: '指定部门',
    key: GroupTypesEnum.DEPARTMENT,
  },
];

// 初始数据
export const DEFAULTDATA = [
  {
    lineNo: 1,
    groupType: GroupTypesEnum.DEPARTMENT,
    name: '生产部门',
    resourceGroupName: '生产部门',
    // 暂时起的名字 因为bizIdList会被fmt
    // bizIdListCopy: [{ name: 'name', userName: '123' }],
  },
  {
    lineNo: 2,
    groupType: GroupTypesEnum.USER,
    bizType: GroupTypesEnum.USER,
    name: '执行人',
    resourceGroupName: '执行人',
  },
  {
    lineNo: 3,
    groupType: GroupTypesEnum.GROUPTYPE,
    name: '设备',
    resourceGroupName: '设备',
  },
];

// 将form的数据格式化提交给后端
export const formatDataForSubmit = (data: {
  workCenterGroupCOList: WorkCenterGroupCOListFormForSubmit[];
}) => {
  const { workCenterGroupCOList: _workCenterGroupCOList, ...rest } = data;

  const workCenterGroupCOList = _workCenterGroupCOList.map((item: any) => {
    const { bizIdList: _bizIdList, bizType, ...workCenterGroupCOListrest } = item;
    const bizIdList = _bizIdList?.map((i: any) => {
      return Number(i.value);
    });
    // resourceGroupName工作中心使用
    // name 工艺路线使用

    return {
      ...workCenterGroupCOListrest,
      bizIdList,
      name: item?.resourceGroupName,
      lineId: item?.id,
      bizType: typeof bizType === 'number' ? Number(bizType) : Number(item?.groupType),
    };
  });

  return { ...rest, workCenterGroupCOList };
};

// 将form的数据格式化用来编辑展示
export const formatDataToInitEdit = (data: {
  workCenterGroupCOList: WorkCenterGroupCOListFormField[];
}) => {
  const { workCenterGroupCOList: _workCenterGroupCOList, ...rest } = data;

  const workCenterGroupCOList = _workCenterGroupCOList?.map((e) => {
    const {
      groupType,
      bizType,
      userList,
      departmentList,
      departmentListFilter, // 类型为用户下部门的筛选条件
      resourceList,
      ...workCenterGroupCOListRest
    } = e;

    let bizIdList: any = [];
    let _departmentListFilter: any = departmentListFilter;

    switch (groupType) {
      case GroupTypesEnum.USER:
        if (!arrayIsEmpty(e.bizIdList)) {
          if (bizType === GroupTypesEnum.USER) {
            bizIdList = userList
              ?.filter((ele: any) => e.bizIdList?.includes(ele.id))
              ?.map(({ id, name }) => {
                return { key: id, label: name, value: id };
              });
          } else if (bizType === GroupTypesEnum.DEPARTMENT) {
            bizIdList = departmentList
              ?.filter((ele: any) => e.bizIdList?.includes(ele.id))
              ?.map(({ id, name }) => {
                return { key: id, label: name, value: id };
              });

            /** 初始化创建生产任务中departmentList为空 给筛选的parma */
            _departmentListFilter = !arrayIsEmpty(departmentListFilter)
              ? departmentListFilter
              : e.bizIdList;
          }
        }
        break;
      case GroupTypesEnum.DEPARTMENT:
        if (!arrayIsEmpty(e.bizIdList)) {
          bizIdList = departmentList
            ?.filter((ele: any) => e.bizIdList?.includes(ele.id))
            ?.map(({ id, name }) => {
              return { key: id, label: name, value: id };
            });
        }
        break;
      case GroupTypesEnum.GROUPTYPE:
        if (!arrayIsEmpty(e.bizIdList)) {
          bizIdList = resourceList
            ?.filter((ele: any) => e.bizIdList?.includes(ele.id))
            ?.map(({ id, name }) => {
              return { key: id, label: name, value: id };
            });
        }
        break;
      default:
        bizIdList = [];
        break;
    }

    return {
      ...workCenterGroupCOListRest,
      groupType,
      bizType,
      resourceGroupName: e.name,
      bizIdList,
      lineId: e?.id,
      userList,
      departmentListFilter: _departmentListFilter,
      resourceList,
      departmentList,
    };
  });

  return { ...rest, workCenterGroupCOList };
};

// 校验重复
const validateRepeat = (recordForm: any, filedName: NamePath, validateName: string) => {
  return (_rule: any, value: any) => {
    return new Promise((resolve, reject) => {
      if (!value) {
        return resolve(true);
      }
      const valueList = recordForm.getFieldValue(filedName);

      // 从 valueList 找出重复的项组成 []
      const list = valueList.map((el: any) => String(el?.[validateName]));

      const repeatList = _Array.findDuplicates(list);

      // value 如果存在 在重复项 [] 中，则 reject
      const condition = repeatList.indexOf(value);

      if (condition !== -1) {
        reject('已存在该选项!');
        return;
      }
      return resolve(true);
    });
  };
};

const AttrFormTable = (props: ResourcesProps) => {
  const {
    initialData = DEFAULTDATA,
    form,
    filedName = ['workCenterGroupCOList'],
    defaultDataList,
    onlySelectBiz,
  } = props;

  const formatDetailDataToForm = (values: any) => {
    const value = formatDataToInitEdit({ workCenterGroupCOList: values });

    return value.workCenterGroupCOList;
  };

  useEffect(() => {
    if (initialData) {
      form.setFields([
        {
          name: filedName,
          value: initialData
            ? formatDetailDataToForm(initialData)
            : formatDetailDataToForm([defaultDataList, initialData]),
        },
      ]);
    }
  }, [initialData]);

  /**
   * 可选资源展示
   * 用户 部门 资源
   */
  const displaySearchSelect = (recordData: any, fields?: any) => {
    // const recordData = forms?.[fields?.key];

    let displayElement: ReactElement = <Input disabled />;
    let userSelect: ReactElement = (
      <Form.Item
        hidden
        name={[fields.name, 'bizType']}
        initialValue={recordData?.groupType}
        style={{ marginBottom: 0, width: '100%' }}
      >
        {recordData?.groupType}
      </Form.Item>
    );

    if (recordData?.groupType === GroupTypesEnum.USER) {
      userSelect = (
        <Form.Item name={[fields.name, 'bizType']} style={{ marginBottom: 0, marginRight: 10 }}>
          <Select
            allowClear
            showArrow
            onChange={(e) => {
              const baseFiledName: any = !arrayIsEmpty(filedName) ? filedName : [filedName];

              form.setFields([
                {
                  name: [...baseFiledName, fields.name, 'bizIdList'],
                  value: undefined,
                },
                {
                  name: [...baseFiledName, fields.name, 'bizType'],
                  value: e,
                },
              ]);
            }}
            placeholder={'请选择'}
            disabled={Boolean(props?.limit)}
          >
            {USEROPTIONS.map(({ label, key }) => (
              <Option key={key} value={key}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    }
    switch (recordData?.groupType) {
      case GroupTypesEnum.USER:
        if (recordData?.bizType === GroupTypesEnum.USER) {
          displayElement = props?.limit ? (
            <Select allowClear showArrow placeholder={'请选择'} mode="multiple" labelInValue>
              {recordData?.userList?.map(({ id, name }: UserListItem) => (
                <Option key={id} value={id} label={name}>
                  {name}
                </Option>
              ))}
            </Select>
          ) : (
            <UserOrDepartmentSelectWithDialog placeholder="请选择" isMultiple isSelectUser />
          );
        } else if (recordData?.bizType === GroupTypesEnum.DEPARTMENT) {
          displayElement = props?.limit ? (
            <SearchSelect
              fetchType={'departmentListUserUnderDepartment'}
              mode="multiple"
              labelInValue
              placeholder={'请选择部门下用户'}
              searchFieldName="search"
              params={{
                departmentIds: recordData?.departmentListFilter,
              }}
            />
          ) : (
            <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple />
          );
        }
        break;
      case GroupTypesEnum.DEPARTMENT:
        displayElement = props?.limit ? (
          <Select allowClear showArrow placeholder={'请选择'} mode="multiple" labelInValue>
            {recordData?.departmentList?.map(({ id, name }: UserListItem) => (
              <Option key={id} value={id} label={name}>
                {name}
              </Option>
            ))}
          </Select>
        ) : (
          <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple />
        );
        break;
      case GroupTypesEnum.GROUPTYPE:
        displayElement = props?.limit ? (
          <Select allowClear showArrow placeholder={'请选择'} mode="multiple" labelInValue>
            {recordData?.resourceList?.map(({ id, name }: UserListItem) => (
              <Option key={id} value={id} label={name}>
                {name}
              </Option>
            ))}
          </Select>
        ) : (
          <SearchSelect
            fetchType={'resource'}
            mode="multiple"
            labelInValue
            placeholder={'请选择资源'}
            searchFieldName={'searchContent'}
          />
        );

        break;
      default:
        displayElement = <Input disabled />;
        break;
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {userSelect}
        <Form.Item
          fieldKey={[fields.fieldKey, 'bizIdList']}
          name={[fields.name, 'bizIdList']}
          validateFirst
          style={{ width: '100%', marginBottom: '0' }}
          validateTrigger={['onChange', 'onBlur']}
        >
          {displayElement}
        </Form.Item>
      </div>
    );
  };

  const handleAdd = () => {
    const elements = form.getFieldValue(filedName) ?? {};

    const list = elements.map((el: any) => Number(el?.lineNo));

    const num = Number(max(list)) + 1;

    const newLine = elements.push({
      lineNo: num,
    });

    form.setFieldsValue({ filedName: newLine });
  };

  return (
    <Form form={form}>
      <Form.List name={filedName}>
        {() => {
          const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
            return [
              {
                title: '行号',
                render: (text: string, field: any) => {
                  const disableItem =
                    workCenterGroupCOListDisableList.indexOf(Number(field?.name)) !== -1;

                  return (
                    <div key={field.key}>
                      <Form.Item
                        fieldKey={[field.fieldKey, 'lineNo']}
                        name={[field.name, 'lineNo']}
                        validateFirst
                        style={{ width: '100%', marginBottom: '0' }}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          { required: true, message: '行号必填' },
                          { validator: validateRepeat(form, filedName, 'lineNo') },
                          { validator: validatePositiveInteger() },
                        ]}
                      >
                        {/* value={field.name + 1} */}
                        <Input disabled={Boolean(onlySelectBiz || disableItem)} />
                      </Form.Item>
                    </div>
                  );
                },
              },
              {
                title: '资源组分类',
                render: (text: string, field: any) => {
                  const disableItem =
                    workCenterGroupCOListDisableList.indexOf(Number(field?.name)) !== -1;

                  return (
                    <div key={field.key}>
                      <Form.Item
                        fieldKey={[field.fieldKey, 'groupType']}
                        name={[field.name, 'groupType']}
                        validateFirst
                        style={{ width: '100%', marginBottom: '0' }}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[{ required: true, message: '资源组分类必填' }]}
                      >
                        <Select
                          // allowClear
                          showArrow
                          disabled={Boolean(onlySelectBiz || disableItem)}
                          onChange={() => {
                            const baseFiledName: any = !arrayIsEmpty(filedName)
                              ? filedName
                              : [filedName];

                            form.setFields([
                              {
                                name: [...baseFiledName, field.name, 'bizIdList'],
                                value: undefined,
                              },
                              {
                                name: [...baseFiledName, field.name, 'bizType'],
                                value: undefined,
                              },
                            ]);
                          }}
                        >
                          {OPTIONS.map(({ label, key }) => (
                            <Option key={key} value={key}>
                              {label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  );
                },
              },
              {
                title: '资源组名称',
                render: (text: string, field: any) => {
                  const disableItem =
                    workCenterGroupCOListDisableList.indexOf(Number(field?.name)) !== -1;

                  return (
                    <div key={field.key}>
                      <Form.Item
                        fieldKey={[field.fieldKey, 'resourceGroupName']}
                        name={[field.name, 'resourceGroupName']}
                        validateFirst
                        style={{ width: '100%', marginBottom: '0' }}
                        rules={[
                          { required: true, message: '资源组名称必填' },
                          { max: 255, message: '不超过255字符' },
                          { validator: validatorCheckTwoSidesTrim() },
                          { validator: validateRepeat(form, filedName, 'resourceGroupName') },
                        ]}
                        validateTrigger={['onChange', 'onBlur']}
                      >
                        <Input disabled={Boolean(onlySelectBiz || disableItem)} />
                      </Form.Item>
                    </div>
                  );
                },
              },
              {
                title: '可选资源',
                // width: 400,
                render: (text: string, field: any) => {
                  const baseFiledName: any = !arrayIsEmpty(filedName) ? filedName : [filedName];

                  return (
                    <div key={field.key}>
                      <Form.Item
                        dependencies={[
                          [...baseFiledName, field.name, 'groupType'],
                          [...baseFiledName, field.name, 'bizType'],
                        ]}
                        style={{ marginBottom: 0, width: '100%' }}
                      >
                        {() => {
                          const recordItem = form.getFieldValue([...baseFiledName, field.name]);

                          return displaySearchSelect(recordItem, field);
                        }}
                      </Form.Item>
                    </div>
                  );
                },
              },
            ];
          };

          return (
            <BlSortFormList
              form={form}
              name={filedName as string}
              renderColumns={() => getColumns()}
              isNeedDrag={false}
              fixedRowFn={(field: any, index: number) => {
                // 工艺路线所有行都不可删除
                if (onlySelectBiz) {
                  return index < MAX_SUPPORTED_ITEM;
                }
                return index < 3;
              }}
              isNeedAdd={!onlySelectBiz}
              handleAdd={handleAdd}
              maxCount={MAX_SUPPORTED_ITEM}
            />
          );
        }}
      </Form.List>
    </Form>
  );
};

export default AttrFormTable;
