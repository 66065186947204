/** 工作日历适用范围类型(固定班制) */
export enum FitRangeType {
  /** 用户 */
  users = 1,
  /** 部门 */
  departments,
  /** 设备 */
  equipments,
  /** 工作中心 */
  workCenters,
}

/** 工作日历类型 */
export enum CalendarType {
  /** 固定班制 */
  fixed = 1,
  /** 排班制 */
  flex,
}
