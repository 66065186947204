import iconOne from './icon/iconOne.png';
import iconTwo from './icon/iconTwo.png';
import iconThree from './icon/iconThree.png';
import styles from '../../styles.module.scss';

interface Props {
  circleName: string;
  authNumber: number | undefined;
  availableNumber: number | undefined;
  index: number;
}

const getIcon = (index: number) => {
  switch (index) {
    case 1:
      return iconOne;
    case 2:
      return iconTwo;
    case 3:
      return iconThree;
  }
  return iconOne;
};

const AvailableInfo = (props: Props) => {
  const { circleName, authNumber, availableNumber, index } = props;

  return (
    <div className={styles['available-info-card']}>
      <div className={styles['available-info-title']}>{circleName}</div>
      <div className={styles['available-info-lower-part']}>
        <div>
          <div>剩余可用个数</div>
          <div style={{ fontSize: 60, fontWeight: 700 }}>{availableNumber}</div>
          <div
            style={{
              border: '1px solid #8C8C8C',
              borderRadius: '4px',
              textAlign: 'center',
              width: 80,
              backgroundColor: '#D4D4D4',
              opacity: 0.5,
            }}
          >
            总量{authNumber}
          </div>
        </div>
        <img className={styles['available-info-icon']} src={getIcon(index)} />
      </div>
    </div>
  );
};

export default AvailableInfo;
