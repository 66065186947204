import React from 'react';
import PurchaseReturnOrderList from './ordersList';
import MaterialsLineList from './materialsList';
import { TabList } from 'src/components';
// 视图模式
const VIEW_MODAL = {
  ORDERS: '1',
  MATERIALS: '2',
};
const List = () => {
  const tabList = [
    {
      tab: '采购退货单视图',
      key: VIEW_MODAL.ORDERS,
      component: <PurchaseReturnOrderList />,
    },
    {
      tab: '物料行视图',
      key: VIEW_MODAL.MATERIALS,
      component: <MaterialsLineList />,
    },
  ];

  return <TabList tabList={tabList} defaultKey={VIEW_MODAL.ORDERS} />;
};

export default List;
