import { BlSortFormList, SearchSelect } from 'src/components';
import { Form, FormInstance, Input, InputNumber, Select } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import _Time from 'src/utils/dataTypes/time';
import _, { compact, find } from 'lodash';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import { appEnum } from 'src/dict';
import { replaceSign } from 'src/utils/constants';

const { Option } = Select;

interface StoreDetailProps {
  selectedRows?: any[];
  form: FormInstance;
}

interface MaterialRegisterPorps {
  code: string;
  name: string;
  suppliersId: number;
}

const MaterialRegisterFormTable = (props: StoreDetailProps) => {
  const { selectedRows, form } = props;

  const newMaterialRegister = selectedRows?.map((node: any) => {
    const supplier = {
      id: node.id,
      code: node.code,
      name: node.name,
    };

    return {
      supplierName: node.name,
      suppliersId: node.id,
      supplierId: {
        key: JSON.stringify(supplier),
        value: JSON.stringify(supplier),
        label: node.code,
      },
    };
  });

  form.setFieldsValue({ materialRegister: newMaterialRegister });

  console.log(form.getFieldValue('materialRegister'), 'lll');

  const materialOnchange = (index: number) => {
    // 选择物料编号的时候 给单位赋值 默认为主单位
    const { materialId } = form.getFieldValue('materialRegister')[index] ?? {};

    const material = materialId && JSON.parse(materialId?.value);

    const newMaterials = form.getFieldValue('materialRegister')?.map((node: any, i: number) => {
      let newNode = { ...node };

      if (index === i) {
        newNode = {
          ...node,
          amount: 1,
          materialsId: material?.baseInfo?.id,
          unitId: material?.unit?.id,
        };
      }
      return newNode;
    });

    form.setFieldsValue({ materialRegister: newMaterials });
  };

  const supplierOnchange = (index: number) => {
    const { supplierId } = form.getFieldValue('materialRegister')[index] ?? {};
    const supplier = supplierId && JSON.parse(supplierId?.value);

    const newSuppliers = form.getFieldValue('materialRegister')?.map((node: any, i: number) => {
      let newNode = { ...node };

      if (index === i) {
        newNode = {
          ...node,
          supplierName: supplier?.name,
          suppliersId: supplier?.id,
        };
      }
      return newNode;
    });

    form.setFieldsValue({ materialRegister: newSuppliers });
  };

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    const baseColumns: ColumnProps<any & FormListFieldData>[] = [
      {
        title: '行号3',
        dataIndex: 'lineNo',
        key: 'lineNo',
        width: 100,
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field?.name, 'lineNo']}
              fieldKey={[field?.key, 'lineNo']}
            >
              {field?.name + 1}
            </Form.Item>
          );
        },
      },
      {
        title: '供应商编号',
        width: 200,
        dataIndex: 'supplierId',
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field.name, 'supplierId']}
              fieldKey={[field.key, 'supplierId']}
            >
              <SearchSelect
                fetchType={'supplierInfo'}
                params={{ regStatus: [appEnum.SupplyRegStatus.SupplyRegStatus.registered] }}
                labelInValue
                onChange={(val) => {
                  supplierOnchange(field?.name);
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '供应商名称',
        width: 120,
        dataIndex: 'supplierName',
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field.name, 'supplierName']}
              fieldKey={[field.key, 'supplierName']}
            >
              {form.getFieldValue('materialRegister')[field.name].supplierName}
              <Input style={{ display: 'none' }} />
            </Form.Item>
          );
        },
      },
      {
        title: '物料',
        dataIndex: 'materialId',
        key: 'materialId',
        width: 320,
        render: (_, field) => {
          return (
            <Form.Item
              style={{ margin: 'auto' }}
              name={[field?.name, 'materialId']}
              fieldKey={[field?.key, 'materialId']}
              rules={[{ required: true, message: '请选择物料编号' }]}
            >
              <SearchSelect
                fetchType={'materialCompleteInfo'}
                labelInValue
                params={{ enableFlag: appEnum.Common.UsageStatus.enabled }}
                onChange={() => {
                  materialOnchange(field?.name);
                }}
                renderOption={(item) => ({
                  label: `${item.baseInfo.code}/${item.baseInfo.name}`,
                  value: JSON.stringify(item),
                  key: item.baseInfo.id,
                })}
              />
            </Form.Item>
          );
        },
      },
      // {
      //   title: '物料名称',
      //   width: 200,
      //   dataIndex: 'materialName',
      //   render: (_, field) => {
      //     const material = JSON.parse(
      //       form.getFieldValue('materialRegister')[field.name]?.materialId?.value || '{}',
      //     );

      //     return (
      //       <Form.Item
      //         style={{ margin: 'auto' }}
      //         name={[field.name, 'materialName']}
      //         fieldKey={[field.key, 'materialName']}
      //       >
      //         {material?.baseInfo?.name || replaceSign}
      //         <Input style={{ display: 'none' }} />
      //       </Form.Item>
      //     );
      //   },
      // },
      {
        title: '数量',
        dataIndex: 'amount',
        key: 'amount',
        width: 120,
        render: (_, field) => {
          return (
            <Form.Item style={{ margin: 'auto' }} shouldUpdate>
              {() => {
                // 默认值为销售单位 无则物料主单位
                const { materialId, unitId } =
                  form.getFieldValue('materialRegister')[field.name] ?? {};

                const material = materialId && JSON.parse(materialId?.value);

                const unitData = material && [material?.unit];
                const { enablePrecision, precisionFigure } = find(unitData, ['id', unitId]) || {};

                return (
                  <Form.Item
                    style={{ margin: 'auto' }}
                    name={[field?.name, 'amount']}
                    fieldKey={[field?.key, 'amount']}
                    rules={compact([
                      { required: true, message: '请输入数量' },
                      {
                        validator: numberMinMaxCheck({
                          max: 999999999,
                          min: 0,
                          minAllowEqual: false,
                        }),
                      },
                      enablePrecision && {
                        validator: fractionLengthCheck(precisionFigure),
                      },
                    ])}
                    initialValue={1}
                    shouldUpdate
                  >
                    <InputNumber stringMode disabled={!unitId} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '单位',
        width: 120,
        dataIndex: 'unitId',
        key: 'unitId',
        render: (_, field) => {
          return (
            <Form.Item style={{ margin: 'auto' }} shouldUpdate>
              {() => {
                // 默认值为销售单位 无则物料主单位
                const { materialId } = form.getFieldValue('materialRegister')[field.name] ?? {};

                const material = materialId && JSON.parse(materialId?.value);

                const unitData = material && material?.unitList;

                return (
                  <Form.Item
                    style={{ margin: 'auto' }}
                    name={[field?.name, 'unitId']}
                    fieldKey={[field?.key, 'unitId']}
                    rules={[{ required: true, message: '请选择单位' }]}
                    shouldUpdate
                  >
                    <Select style={{ width: '100%' }} placeholder="请选择" disabled={!materialId}>
                      {unitData?.map((item: any) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ];

    return baseColumns;
  };

  return (
    <BlSortFormList
      form={form}
      name={'materialRegister'}
      renderColumns={() => getColumns()}
      isNeedDrag={false}
    />
  );
};

export default MaterialRegisterFormTable;
