import React, { useState, useEffect } from 'react';
import { BlIcon, BlTable } from '@blacklake-web/component';
import { Button, Form, message, Space } from 'antd';
import { replaceSign } from 'src/utils/constants';
import AddSetTemplateData from './addSetTemplateData';
import { TemplateDataSetInfoListDetail } from '../../interface';
import { appEnum } from 'src/dict';
import { fetchEReportQueryTemDataSetListNoStatus } from 'src/api/ytt/e-report-domain/customReport';
import styles from '../../filter/styles.module.scss';
interface Props {
  onFinish: (operationType: string) => void;
  addDataVisible: boolean;
  edit: boolean;
  handelSetAddDataVisible: (visible: boolean) => void;
}

export default function AddDataTabContent({
  onFinish,
  addDataVisible,
  edit,
  handelSetAddDataVisible,
}: Props) {
  const [initLineData, setInitLineData] = useState<TemplateDataSetInfoListDetail>({} as any);
  const [initDataTable, setInitDataTable] = useState([] as any);

  const [operationType, setOperationType] = useState<string>(appEnum.Common.CRUD.create);

  const [setDataListForm] = Form.useForm();

  const templateId = Number(localStorage.getItem('templateId'));

  useEffect(() => {
    if (templateId) {
      refreshTemplateDataList();
    }
  }, [templateId]);

  const refreshTemplateDataList = async () => {
    try {
      const { data } = await fetchEReportQueryTemDataSetListNoStatus({ templateId });

      if (data) {
        setInitDataTable(data?.templateDataSetInfoList);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const handleDeleteTemplateData = async (templateDataSetId: number) => {
  //   try {
  //     const { code, data } = await fetchEReportDeleteTemplateDataSet({
  //       templateDataSetId,
  //       templateId,
  //     });

  //     if (code === 200 && data) {
  //       message.success('删除成功');
  //       refreshTemplateDataList();
  //     }
  //   } catch (error) {
  //     console.log('error: ', error);
  //   }
  // };

  const dataColumns = [
    {
      title: '序号',
      dataIndex: 'serialNumber',
      width: 100,
      render: (serialNumber: number) => serialNumber,
    },
    {
      title: '模板数据集名称',
      dataIndex: 'name',
      width: 200,
      render: (_: any, { serialNumber = 0, dataSetName }: TemplateDataSetInfoListDetail) => {
        const line = '_';

        return serialNumber < 10
          ? `0${serialNumber}${line}${dataSetName}`
          : `${serialNumber}${line}${dataSetName}`;
      },
    },
    {
      title: '数据集来源',
      width: 200,
      dataIndex: 'dataSetName',
      render: (dataSetName: string) => dataSetName ?? replaceSign,
    },
    {
      title: '操作',
      width: 200,
      dataIndex: 'operation',
      render: (_: any, record: TemplateDataSetInfoListDetail) => {
        return (
          <>
            <Space size={20}>
              <Button
                type="link"
                onClick={() => {
                  handelSetAddDataVisible(true);
                  setInitLineData(record);
                  setOperationType(appEnum.Common.CRUD.edit);
                }}
              >
                编辑
              </Button>
              <Button
                type="link"
                onClick={() => {
                  if (initDataTable?.length >= 20) {
                    message.error('模板数据集最多添加20条');
                  } else {
                    handelSetAddDataVisible(true);
                    setInitLineData(record);
                    setOperationType(appEnum.Common.CRUD.copy);
                  }
                }}
              >
                复制
              </Button>
              {/* <Button
                type="link"
                onClick={() => {
                  Modal.confirm({
                    title:
                      '该模板数据集如果被cpt模板使用，在cpt模板中会失效，且删除后无法恢复，确认删除？',
                    icon: <ExclamationCircleOutlined />,
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async () => {
                      record?.templateDataSetId &&
                        handleDeleteTemplateData(record?.templateDataSetId);
                    },
                  });
                }}
              >
                删除
              </Button> */}
            </Space>
          </>
        );
      },
    },
  ];

  const getFooter = () => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <div
            className="add"
            onClick={() => {
              if (initDataTable?.length >= 20) {
                return message.error('模板数据集最多添加20条');
              }
              if (edit) {
                setOperationType(appEnum.Common.CRUD.create);
                return handelSetAddDataVisible(true);
              }

              // 新建没有模板id 必须绑定了模板id才能创建数据集

              if (!templateId) {
                onFinish?.('add');
              } else {
                handelSetAddDataVisible(true);
              }

              setOperationType(appEnum.Common.CRUD.create);

              setInitLineData({});
            }}
          >
            <span style={{ marginRight: 8 }}>
              <BlIcon type="iconxinjiantianjia" />
            </span>
            添加模板数据集
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <BlTable
        columns={dataColumns}
        dataSource={initDataTable}
        footer={getFooter}
        rowKey={(record: any) => record?.templateDataSetId}
        id="templateDataSetId"
        scroll={{ x: 1000 }}
        style={{ width: '100%' }}
        className={styles?.templateTable}
      />
      {addDataVisible && (
        <AddSetTemplateData
          visible={addDataVisible}
          initLineData={initLineData}
          setDataListForm={setDataListForm}
          operationType={operationType}
          onCancel={() => {
            handelSetAddDataVisible(false);
          }}
          refreshDataList={refreshTemplateDataList}
        />
      )}
    </div>
  );
}
