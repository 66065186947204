import { Button, message, Modal, Select, Spin } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchEReportInstanceInstancePrint } from 'src/api/ytt/e-report-domain/electronicDocuments';
import {
  fetchMatchRuleMatchRuleTemplatePreviewForBizBatchMergePdf,
  fetchMatchRuleQueryMatchTemplate,
} from 'src/api/ytt/e-report-domain/matchRule';
import Report from 'src/page/analyse/templateManagement/components/preview/report';

interface PrintProps {
  instanceId: number;
  disabled?: boolean;
}

export const PrintByReportComponent = (props: PrintProps) => {
  const { instanceId, disabled } = props;

  const loadPdf = async () => {
    try {
      const { data } = await fetchEReportInstanceInstancePrint({ instanceId });

      Modal.info({
        content: printContent({ data }),
        icon: null,
        width: 800,
        bodyStyle: { height: 800 },
        closable: true,
        okText: '关闭',
      });
    } catch (err) {
      message.error('下载内容加载出错了');
    }
  };

  const printContent = ({ data }: { data?: { previewUrl?: string; params?: any } }) => (
    <div style={{ padding: 16 }}>
      <Report previewUrl={data?.previewUrl ?? ''} params={data?.params} canFullScreen={false} />
    </div>
  );

  return (
    <Button
      onClick={async () => {
        loadPdf();
      }}
      type="link"
      disabled={disabled}
    >
      打印
    </Button>
  );
};
