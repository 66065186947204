import React from 'react';
import { DetailLayoutForDrawer } from 'layout';
import DetailInfo from './components/detailBaseInfo';

interface SchedulingDetailProps {
  visible: boolean;
  closeDetail: () => void;
  schedulingId: number;
}

const SchedulingDetail = (props: SchedulingDetailProps) => {
  const { visible, closeDetail, schedulingId } = props;

  return (
    <DetailLayoutForDrawer
      visible={visible}
      onClose={closeDetail}
      width={680}
      content={<DetailInfo schedulingId={schedulingId} type="drawer" />}
    />
  );
};

export default SchedulingDetail;
