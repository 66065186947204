import React from 'react';
import { fetchLogGetOperationList } from 'src/api/ytt/log-domain';
import { Space } from 'antd';
import { SearchSelect } from 'src/components';
import Tooltip from 'src/components/tooltip';
import { FilterItem, RecordListLayout } from 'src/layout';
import { fieldTypeList } from 'src/utils';
import { replaceSign } from 'src/utils/constants';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { CreatorData } from 'src/page/knowledgeManagement/calendarManagement/shift/interface';
import { OPERATION_TERMINAL_TYPE, toDetail } from './constant';
import _Time from 'src/utils/dataTypes/time';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import _Array from 'src/utils/dataTypes/array';
import { useHistory } from 'react-router-dom';
import _String from 'src/utils/dataTypes/string';
import { TableRowType } from './interface';
import { renderUser } from 'src/page/share/renderUser';
import { formatTimeForRender } from 'src/utils/formatters';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { map } from 'lodash';

const OperationLogList = () => {
  const history = useHistory();

  const hasAuthRes = hasAuth(authDict.operationlog_detail);

  const dataColumns = [
    {
      title: '对象名称',
      dataIndex: 'objectDisplayName',
      width: 200,
      isFilter: true,
      sorter: true,
      render: (objectDisplayName: string) => objectDisplayName ?? replaceSign,
    },
    {
      title: '对象实例编号',
      dataIndex: 'instanceCode',
      width: 200,
      isFilter: true,
      sorter: true,
      render: (instanceCode: string, record: TableRowType, index: number, config: any) => {
        return (
          <>
            {hasAuthRes ? (
              <a
                onClick={() => {
                  history.push(toDetail(record?.logId));
                }}
              >
                <Tooltip
                  text={!_String.isEmpty(instanceCode) ? instanceCode : replaceSign}
                  width={config.contentWidth}
                />
              </a>
            ) : (
              <Tooltip
                text={!_String.isEmpty(instanceCode) ? instanceCode : replaceSign}
                width={config.contentWidth}
              />
            )}
          </>
        );
      },
    },
    {
      title: '对象实例名称',
      dataIndex: 'instanceName',
      width: 200,
      isFilter: true,
      sorter: true,
      render: (instanceName: string, record: TableRowType, index: number, config: any) => {
        return (
          <>
            {hasAuthRes ? (
              <a
                onClick={() => {
                  history.push(toDetail(record?.logId));
                }}
              >
                <Tooltip text={instanceName || replaceSign} width={config.contentWidth} />
              </a>
            ) : (
              <Tooltip text={instanceName || replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },
    {
      title: '操作人',
      dataIndex: 'operator',
      width: 150,
      isFilter: true,
      render: renderUser,
    },
    {
      title: '操作时间',
      dataIndex: 'loggedAt',
      width: 200,
      sorter: true,
      isFilter: true,
      render: formatTimeForRender,
    },
    {
      title: '操作类型',
      dataIndex: 'operationName',
      width: 150,
    },
    {
      title: '操作终端',
      dataIndex: 'sourceType',
      width: 150,
      render: (sourceType: number) => OPERATION_TERMINAL_TYPE.get(sourceType) ?? replaceSign,
    },
    {
      title: '操作原因',
      dataIndex: 'operationReason',
      width: 150,
      render: (operationReason: string) => operationReason ?? replaceSign,
    },
    {
      title: '电子签名',
      dataIndex: 'signList',
      width: 150,
      render: (signList: CreatorData[]) => {
        if (_Array.isEmpty(signList)) return replaceSign;
        return (
          <Space>
            {signList?.map((manager: CreatorData) => (
              <AvatarDisplay
                id={manager.id}
                name={manager.name}
                avatarUrl={manager.avatarUrl}
                key={manager.id}
                isShowTag={false}
                isUser
              />
            ))}
          </Space>
        );
      },
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '对象名称',
      name: 'objectCode',
      type: fieldTypeList?.select,
      renderItem: <SearchSelect fetchType={'bizObjectByCode'} labelInValue />,
    },
    {
      label: '对象实例编号',
      name: 'instanceCode',
      type: fieldTypeList?.text,
    },
    {
      label: '对象实例名称',
      name: 'instanceName',
      type: fieldTypeList?.text,
    },
    {
      label: '操作人',
      name: 'operatorId',
      type: fieldTypeList.text,
      renderItem: (
        <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple={false} isSelectUser />
      ),
    },
    {
      label: '操作时间',
      name: 'logTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '操作类型',
      name: 'operationTypeCode',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType={'operationCode'} mode="multiple" labelInValue />,
    },
    {
      label: '操作原因',
      name: 'operationReason',
      type: fieldTypeList.text,
    },
    {
      label: '电子签名',
      name: 'signIds',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
  ];

  const formatDataToQuery = (params: any) => {
    const { logTime, operatorId, signIds, objectCode, operationTypeCode, ...rest } = params;

    const realParams = {
      ...rest,
      objectCode: objectCode?.value,
      operatorId: operatorId?.[0]?.value,
    };

    if (logTime) {
      realParams.logStartTime = Number(_Time.formatToUnix(logTime[0]));
      realParams.logEndTime = Number(_Time.formatToUnix(logTime[1]));
    }
    if (signIds) {
      realParams.signIds = signIds?.map((node: any) => node.value);
    }
    if (!_Array.isEmpty(operationTypeCode)) {
      realParams.operationTypeCode = map(operationTypeCode, 'key');
    }

    const { sort, field, ...restProps } = realParams;

    if (sort && field) {
      restProps.orderBy = `${field} ${sort}`;
    }

    return restProps;
  };

  const config = {
    rowKey: (record: TableRowType) => String(record.logId),
    columns: dataColumns,
    getOperationList: (record: TableRowType) => {
      return [
        {
          title: '查看',
          auth: authDict.operationlog_detail,
          onClick: () => {
            history.push(toDetail(record?.logId));
          },
        },
      ];
    },
    filterList,
    formatDataToQuery,
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        userAuth={getAuthFromLocalStorage()}
        placeholder={'输入对象实例编号、对象实例名称'}
        configcacheKey="operationLog"
        requestFn={(params) => fetchLogGetOperationList(params, { legacy: true })}
        {...config}
      />
    </>
  );
};

export default OperationLogList;
