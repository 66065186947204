import { useState } from 'react';
import _, { isEmpty } from 'lodash';
import { Button, message } from 'antd';
import { FilterItem, RecordListLayout, DetailLayoutForModal } from 'src/layout';
import { fieldTypeList } from 'src/utils';
import { fetchWorkOrderInputMaterialList } from 'src/api/ytt/med-domain/work_order';
import _Array from 'src/utils/dataTypes/array';
import _Time from 'src/utils/dataTypes/time';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { replaceSign } from 'src/utils/constants';
import { LinkTooltip, SearchSelect, TagList } from 'src/components';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import { LabelType } from 'src/page/knowledgeManagement/warehouse/interface';
import { Attribute } from 'src/page/sales/salesManagement/salesOrder/interface';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import { appDict, lookup } from 'src/dict';
import { intersectionValueOfId } from 'src/utils/array';
import { getMaterialAttrs } from 'src/entity/material';
import authDict from 'src/utils/auth';
import { AmountType } from '../../processPlanCO/list';
import { toDetailProductionOrder } from '../../productionOrder/navigation';
import {
  FIELD_MAP,
  PICK_MODE_TYPE,
  PICK_MODE_TYPE_TYPE,
  TASK_STATUS_ENUM_MAP,
  TASK_STATUS_STATUS_MAP_ENUM,
  TASK_STATUS_TYPE_TYPE,
  WORK_ORDER_STATUS_MAP_ENUM,
  WORK_ORDER_TYPE_TYPE,
} from '../../processPlanCO/list/constant';
import AlternativeMaterialsDetail from '../../productionOrder/detail/alternativeMaterialsDetail';
import QueryReservationDetail from 'src/page/planned/reserve/queryReservation/detail';
import { ReservedOrderType } from 'src/page/planned/planningOperation/constants';
import { fetchReservedCommonData } from 'src/page/planned/reserve/utils';
import { BusinessStatusEnum } from 'src/dict/productionPlanning';

interface userProps {
  id: number;
  name: string;
  code: string;
  avatarUrl: string;
}

const BomInputMaterialsList = (props: { history: any }) => {
  const { history } = props;

  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]); // 选中的keys
  const [selectRows, setSelectRows] = useState<any[]>([]);
  const [alternativeMaterialsData, setAlternativeMaterialsData] = useState<any>();
  const [queryReservation, setQueryReservation] = useState<any>(false);

  //  校验是否可以申请领料以及批量选择时 过滤不符合领料申请的数据
  const checkCanStoreRequisition = (data: any) => {
    const checkCanStoreRequisitionFilterState = _.map(
      _.filter(data, (node: any) => {
        return (
          node?.pickMode === PICK_MODE_TYPE?.ON_DEMAND &&
          [
            TASK_STATUS_ENUM_MAP.CRATE,
            TASK_STATUS_ENUM_MAP.BEEN_ISSUED,
            TASK_STATUS_ENUM_MAP.EXECUTION,
          ].includes(node?.workOrderStatus)
        );
      }),
      'inputId',
    );

    return checkCanStoreRequisitionFilterState;
  };

  const columns = [
    {
      title: '工单编码',
      dataIndex: 'workOrderCode',
      width: 150,
      sorter: true,
      render: (workOrderCode: string, record: any, index: number, config: any) => (
        <LinkTooltip
          to={toDetailProductionOrder(record?.workOrderId)}
          text={workOrderCode}
          width={config.contentWidth}
          auth={authDict.productionorder_detail}
        />
      ),
    },
    {
      title: '工单类型',
      dataIndex: 'workOrderType',
      width: 120,
      render: (workOrderType: number) => WORK_ORDER_TYPE_TYPE.get(workOrderType) || replaceSign,
    },
    {
      title: '业务状态',
      dataIndex: 'workOrderStatus',
      width: 120,
      sorter: true,
      render: (workOrderStatus: number, record: any) => {
        if (workOrderStatus === TASK_STATUS_ENUM_MAP.CANCEL) {
          return `${TASK_STATUS_TYPE_TYPE.get(workOrderStatus)}(${lookup(
            'productionPlanning.WorkOrderCloseTypeMap',
            record.workOrderCloseType,
          )})`;
        }
        return TASK_STATUS_TYPE_TYPE.get(workOrderStatus) || replaceSign;
      },
    },
    {
      title: '计划开始时间',
      dataIndex: 'plannedStartTime',
      width: 180,
      sorter: true,
      render: (plannedStartTime: Date) => {
        if (!plannedStartTime) return replaceSign;
        return _Time.format(plannedStartTime);
      },
    },
    {
      title: '计划完工时间',
      dataIndex: 'plannedFinishTime',
      width: 180,
      sorter: true,
      render: (plannedFinishTime: Date) => {
        if (!plannedFinishTime) return replaceSign;
        return _Time.format(plannedFinishTime);
      },
    },
    {
      title: '生产部门',
      dataIndex: 'productionDepartment',
      width: 150,
      render: (productionDepartment: userProps) => {
        if (!productionDepartment) return replaceSign;
        return (
          <AvatarDisplay
            id={productionDepartment?.id}
            name={productionDepartment?.name}
            key={productionDepartment?.id}
            isShowTag
          />
        );
      },
    },
    {
      title: '生产主管',
      dataIndex: 'productionSupervisor',
      width: 150,
      render: (productionSupervisor: userProps) => {
        if (!productionSupervisor) return replaceSign;
        return (
          <AvatarDisplay
            id={productionSupervisor?.id}
            name={productionSupervisor?.name}
            key={productionSupervisor?.id}
            isUser
            avatarUrl={productionSupervisor?.avatarUrl}
            isShowTag={false}
          />
        );
      },
    },
    {
      title: '计划部门',
      dataIndex: 'planningDepartment',
      width: 150,
      render: (planningDepartment: userProps) => {
        if (!planningDepartment) return replaceSign;
        return (
          <AvatarDisplay
            id={planningDepartment?.id}
            name={planningDepartment?.name}
            key={planningDepartment?.id}
          />
        );
      },
    },
    {
      title: '计划员',
      dataIndex: 'planningUser',
      width: 150,
      render: (planningUser: userProps) => {
        if (!planningUser) return replaceSign;
        return (
          <AvatarDisplay
            id={planningUser?.id}
            name={planningUser?.name}
            key={planningUser?.id}
            isUser
            avatarUrl={planningUser?.avatarUrl}
            isShowTag={false}
          />
        );
      },
    },
    {
      title: '物料编号',
      dataIndex: ['mainMaterialDO', 'baseInfo', 'code'],
      width: 150,
      render: (code: string) => code ?? replaceSign,
    },
    {
      title: '物料名称',
      dataIndex: ['mainMaterialDO', 'baseInfo', 'name'],
      width: 150,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '物料分类',
      dataIndex: ['mainMaterialDO', 'category', 'name'],
      width: 150,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '物料属性',
      dataIndex: ['mainMaterialDO', 'attribute'],
      width: 150,
      render: (_text: any, record: any) => (
        <TagList dataSource={getMaterialAttrs(record.mainMaterialDO)} />
      ),
    },
    {
      title: '批次号',
      dataIndex: 'mainOutputBatchNumber',
      width: 150,
    },
    {
      title: '版本号',
      dataIndex: 'bomVersion',
      width: 150,
      render: (bomVersion: string) => bomVersion ?? replaceSign,
    },
    {
      title: '项次',
      dataIndex: 'seq',
      width: 150,
      render: (seq: number) => seq ?? replaceSign,
    },
    {
      title: '子项物料编号',
      dataIndex: ['materialDO', 'baseInfo', 'code'],
      width: 150,
      render: (code: string) => code ?? replaceSign,
    },
    {
      title: '子项物料名称',
      dataIndex: ['materialDO', 'baseInfo', 'name'],
      width: 150,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '子项物料分类',
      dataIndex: ['materialDO', 'category', 'name'],
      width: 150,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '子项物料属性',
      dataIndex: ['materialDO', 'attribute'],
      width: 150,
      render: (attribute: Attribute[]) => {
        if (_Array.isEmpty(attribute)) return replaceSign;
        const dataSource = attribute?.map((item) => {
          const { name, attributeItem, id } = item;

          return {
            label: `${name}:${attributeItem?.content}`,
            value: id,
          };
        });

        return <TagList dataSource={dataSource} />;
      },
    },
    // {
    //   title: '子项类型',
    //   dataIndex: 'inputType',
    //   width: 150,
    //   render: (text: any) =>
    //     lookup('productionPlanning.ItemTypeEnumDisplayMap', text) || replaceSign,
    // },
    {
      title: '用量:分子',
      dataIndex: 'inputAmountNumerator',
      width: 150,
      render: (inputAmountNumerator: number) => inputAmountNumerator ?? replaceSign,
    },
    {
      title: '用量:分母',
      dataIndex: 'inputAmountDenominator',
      width: 150,
      render: (inputAmountDenominator: number) => inputAmountDenominator ?? replaceSign,
    },
    {
      title: '标准用量',
      dataIndex: 'standardUseAmount',
      width: 150,
      render: (standardUseAmount: AmountType) => standardUseAmount?.amountDisplay ?? replaceSign,
    },
    {
      title: '需求数',
      dataIndex: 'requirementAmount',
      width: 150,
      render: (requirementAmount: AmountType) => requirementAmount?.amountDisplay ?? replaceSign,
    },
    {
      title: '申请领料数',
      dataIndex: 'requestPickAmount',
      width: 150,
      render: (requestPickAmount: AmountType) => requestPickAmount?.amountDisplay ?? replaceSign,
    },
    {
      title: '领料发料数',
      dataIndex: 'pickOrderIssuedAmount',
      width: 150,
      render: (pickOrderIssuedAmount: AmountType) =>
        pickOrderIssuedAmount?.amountDisplay ?? replaceSign,
    },
    {
      title: '领料收料数',
      dataIndex: 'receivePickAmount',
      width: 150,
      render: (receivePickAmount: AmountType) => receivePickAmount?.amountDisplay ?? replaceSign,
    },
    {
      title: '实际投料数',
      dataIndex: 'inputAmount',
      width: 150,
      render: (inputAmount: AmountType) => inputAmount?.amountDisplay ?? replaceSign,
    },
    {
      title: '单位',
      dataIndex: 'unit',
      width: 150,
      render: (unit: any) => unit?.name ?? replaceSign,
    },
    {
      title: '损耗率%',
      dataIndex: 'lossRate',
      width: 150,
      render: (lossRate: number) => lossRate ?? replaceSign,
    },
    {
      title: '展开版本',
      dataIndex: 'expandVersion',
      width: 150,
      render: (expandVersion: number) => expandVersion ?? replaceSign,
    },
    {
      title: '需求时间',
      dataIndex: 'requirementTime',
      width: 150,
      render: (requirementTime: Date) => {
        if (!requirementTime) return replaceSign;
        return _Time.format(requirementTime);
      },
    },
    {
      title: '领料方式',
      dataIndex: 'pickMode',
      width: 150,
      render: (pickMode: number) => PICK_MODE_TYPE_TYPE.get(pickMode) ?? replaceSign,
    },
    {
      title: '指定供应商',
      dataIndex: 'supplierList',
      width: 150,
      render: (supplierList: userProps[]) => {
        if (_Array.isEmpty(supplierList)) return replaceSign;
        return <TagList dataSource={supplierList} labelPath="name" valuePath="id" />;
      },
    },
    {
      title: '投料工序号',
      dataIndex: 'inputProcessNum',
      width: 150,
      render: (inputProcessNum: string) => inputProcessNum ?? replaceSign,
    },
    {
      title: '拆分控件投料',
      dataIndex: 'splitSopControlInput',
      width: 150,
      render: (splitSopControlInput: boolean) => (splitSopControlInput ? '是' : '否'),
    },
    {
      title: '行备注',
      dataIndex: 'remark',
      width: 150,
      render: (remark: string) => remark ?? replaceSign,
    },
    {
      title: '替代方案',
      dataIndex: 'workOrderAlternativePlan',
      width: 200,
      render: (text: any, record: any) => {
        const { workOrderAlternativePlan } = record;

        return !isEmpty(workOrderAlternativePlan) ? (
          <Button
            type="link"
            onClick={() => {
              setAlternativeMaterialsData(record);
            }}
            style={{ padding: 0 }}
          >
            查看
          </Button>
        ) : null;
      },
    },
  ];

  const getOperationList = (record: any) => {
    const disabledStatus =
      record?.pickMode === PICK_MODE_TYPE?.ON_DEMAND &&
      [
        TASK_STATUS_ENUM_MAP.CRATE,
        TASK_STATUS_ENUM_MAP.BEEN_ISSUED,
        TASK_STATUS_ENUM_MAP.EXECUTION,
      ].includes(record?.workOrderStatus);

    const list = [
      {
        title: '查看',
        auth: authDict.productionorder_detail,
        onClick: () => {
          history.push(toDetailProductionOrder(record?.workOrderId));
        },
      },
      {
        title: '申请领料',
        auth: authDict.pickorder_add,
        disabled: !disabledStatus,
        onClick: () => {
          history.push(
            `/productionPlanning/execution/productManagement/storeRequisition/batchCreate?ids=${[
              record?.inputId,
            ]}&originType=${1}`,
          );
        },
      },
    ];

    if (record?.workOrderStatus !== BusinessStatusEnum.CLOSED) {
      list.push({
        title: '查询预留',
        auth: authDict.PlanReserved_detail,
        onClick: () => {
          fetchReservedCommonData({
            lineId: record?.inputId,
            materialId: record?.materialDO?.baseInfo?.id,
            orderId: record?.workOrderId,
            type: ReservedOrderType.PRODUCT_ORDER,
          }).then((reservedCommonData) => {
            if (!reservedCommonData) {
              message.error('暂无预留');
            }
            setQueryReservation(reservedCommonData);
          });
        },
      });
    }

    return list;
  };

  const filterList: FilterItem[] = [
    {
      label: '工单编码',
      name: 'workOrderCode',
      type: fieldTypeList.text,
    },
    {
      label: '工单类型',
      name: 'workOrderTypeList',
      type: fieldTypeList.multiSelect,
      selectProps: {
        options: WORK_ORDER_STATUS_MAP_ENUM.map((node: LabelType) => {
          return {
            label: node?.name,
            value: node?.id,
          };
        }),
      },
    },
    {
      label: '子项类型',
      name: 'inputTypeList',
      type: fieldTypeList.multiSelect,
      selectProps: {
        options: appDict.productionPlanning.ItemTypeEnumDisplayMap,
      },
    },
    {
      label: '业务状态',
      name: 'workOrderStatusList',
      type: fieldTypeList.multiSelect,
      selectProps: {
        options: TASK_STATUS_STATUS_MAP_ENUM.map((node: LabelType) => {
          return {
            label: node?.name,
            value: node?.id,
          };
        }),
      },
    },
    {
      label: '计划开始时间',
      name: 'plannedStartTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '计划完工时间',
      name: 'plannedEndTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '生产部门',
      name: 'productionDepartmentIdList',
      type: fieldTypeList.multiSelect,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple />,
    },
    {
      label: '生产主管',
      name: 'productionSupervisorIdList',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
    {
      label: '计划部门',
      name: 'planningDepartmentIdList',
      type: fieldTypeList.multiSelect,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple />,
    },
    {
      label: '计划员',
      name: 'planningUserIdList',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
    {
      label: '批次号',
      name: 'mainOutputBatchNumber',
      type: fieldTypeList.text,
    },
    {
      label: '物料名称',
      name: 'mainOutputMaterialNameList',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          labelPath="name"
          valuePath="id"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '物料编号',
      name: 'mainOutputMaterialCodeList',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          labelPath="code"
          valuePath="id"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '子项物料名称',
      name: 'inputMaterialNameList',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="name"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '子项物料编号',
      name: 'inputMaterialCodeList',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="code"
          labelInValue
          mode="multiple"
        />
      ),
    },
  ];

  const toolbar = [
    {
      title: '申请领料',
      auth: authDict.pickorder_add,
      onClick: (onSuccess: any, onFail: any) => {
        if (selectedRowKeys?.length > 200) {
          message.error('最多支持选择200个');
          onFail?.();
        } else {
          const checkCanStoreRequisitionFilterState = checkCanStoreRequisition(selectRows);

          if (_Array.isEmpty(checkCanStoreRequisitionFilterState)) {
            message.error('暂无可领料数据');
            return onFail?.();
          }

          history.push(
            `/productionPlanning/execution/productManagement/storeRequisition/batchCreate?ids=${checkCanStoreRequisitionFilterState}&originType=${1}`,
          );
        }
      },
    },
  ];

  const formatDataToQuery = (params: any) => {
    const {
      productionDepartmentIdList,
      productionSupervisorIdList,
      planningDepartmentIdList,
      planningUserIdList,
      mainOutputMaterialNameList,
      mainOutputMaterialCodeList,
      inputMaterialNameList,
      inputMaterialCodeList,
      plannedStartTime,
      plannedEndTime,
      sorter,
      ...rest
    } = params;

    const relParams = {
      ...rest,
    };

    if (plannedStartTime) {
      relParams.plannedStartTimeFrom = Number(_Time.formatToUnix(plannedStartTime[0]));
      relParams.plannedStartTimeTo = Number(_Time.formatToUnix(plannedStartTime[1]));
    }
    if (plannedEndTime) {
      relParams.plannedEndTimeFrom = Number(_Time.formatToUnix(plannedEndTime[0]));
      relParams.plannedEndTimeTo = Number(_Time.formatToUnix(plannedEndTime[1]));
    }

    if (!_Array.isEmpty(productionDepartmentIdList)) {
      relParams.productionDepartmentIdList = _.map(productionDepartmentIdList, 'value');
    }

    if (!_Array.isEmpty(productionSupervisorIdList)) {
      relParams.productionSupervisorIdList = _.map(productionSupervisorIdList, 'value');
    }
    if (!_Array.isEmpty(planningDepartmentIdList)) {
      relParams.planningDepartmentIdList = _.map(planningDepartmentIdList, 'value');
    }
    if (!_Array.isEmpty(planningUserIdList)) {
      relParams.planningUserIdList = _.map(planningUserIdList, 'value');
    }
    if (
      !_Array.isEmpty(mainOutputMaterialNameList) ||
      !_Array.isEmpty(mainOutputMaterialCodeList)
    ) {
      relParams.mainOutputMaterialIdList = intersectionValueOfId(
        mainOutputMaterialNameList,
        mainOutputMaterialCodeList,
      );
    }
    if (!_Array.isEmpty(inputMaterialNameList) || !_Array.isEmpty(inputMaterialCodeList)) {
      relParams.inputMaterialIdList = intersectionValueOfId(
        inputMaterialNameList,
        inputMaterialCodeList,
      );
    }

    if (sorter) {
      relParams.sorter = _.map(sorter, (node: any) => {
        return {
          order: node?.order,
          field: FIELD_MAP.get(node?.field),
        };
      });
    }

    return relParams;
  };

  const formatDataToFormDisplay = (data: any) => {
    const { plannedStartTime, plannedEndTime, processPlannedStartTime, processPlannedFinishTime } =
      data;

    const retData = { ...data };

    if (plannedStartTime) {
      retData.plannedStartTime = momentData(plannedStartTime);
    }
    if (plannedEndTime) {
      retData.plannedEndTime = momentData(plannedEndTime);
    }
    if (processPlannedStartTime) {
      retData.processPlannedStartTime = momentData(processPlannedStartTime);
    }
    if (processPlannedFinishTime) {
      retData.processPlannedFinishTime = momentData(processPlannedFinishTime);
    }

    return retData;
  };

  return (
    <>
      <RecordListLayout<any>
        getOperationList={getOperationList}
        requestFn={fetchWorkOrderInputMaterialList}
        formatDataToQuery={formatDataToQuery}
        batchMenu={toolbar}
        placeholder={'请输入工单编码'} // 快速搜索input placeholder
        formatDataToFormDisplay={formatDataToFormDisplay}
        filterList={filterList}
        columns={columns}
        configcacheKey="BomInputMaterialsList"
        rowKey="inputId"
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey: any, selectRows: any) => {
          setSelectRowKeys(selectKey);
          setSelectRows(selectRows);
        }}
        pagination={{ pageSizeOptions: [10, 20] }}
      />
      <DetailLayoutForModal
        visible={!isEmpty(alternativeMaterialsData)}
        onClose={() => {
          setAlternativeMaterialsData(undefined);
        }}
        content={<AlternativeMaterialsDetail dataSource={alternativeMaterialsData} />}
      />
      <DetailLayoutForModal
        visible={queryReservation}
        onClose={() => {
          setQueryReservation(false);
        }}
        content={
          <QueryReservationDetail
            dataSource={queryReservation}
            onChange={() => {
              setQueryReservation(false);
            }}
          />
        }
      />
    </>
  );
};

export default BomInputMaterialsList;
