/**
 * 日期时间 控件属性
 */
import { CSSProperties, FC, useContext } from 'react';
import _ from 'lodash';
import { Form, Input, Select, Radio, Checkbox, DatePicker } from 'antd';
import type { FormInstance } from 'antd';
import moment, { Moment } from 'moment';
import { DefaultLogic, ShowLogic, BizProperty, TimeType, ControlType } from 'src/dict/sop';
import {
  editable as editableMappings,
  timeShowLogic as timeShowLogicMappings,
  timeDefaultLogic as timeDefaultLogicMappings,
  timeBizProperty as timeBizPropertyMappings,
  timeType as timeTypeMappings,
} from 'src/dict/sop/mappings';
import type { StepDetailData, LocalControlItem } from '../../types';
import {
  ControlEditorContext,
  CONTROL_TITLE_LENGTH,
  INPUT_DEFAULT_PLACEHOLDER,
} from '../../constants';
import getTimeFormatString from '../../share/getTimeFormatString';
import { getNameFactory, titleRules, resetUnusedItems } from './share';

interface Props {
  form: FormInstance<StepDetailData>;
  localKey: string;
}

const closeMarginBottom: CSSProperties = {
  marginBottom: 10,
};

const timeItemProps = {
  getValueProps: (val: number | string | undefined | null) => {
    if (_.isNil(val)) {
      return { value: val };
    }
    const timestamp = _.toNumber(val);

    return {
      value: _.isNumber(timestamp) ? moment(timestamp) : val,
    };
  },
  getValueFromEvent: (val: Moment | null) => val?.valueOf(),
};

const getDemoTime = (timeType: TimeType) =>
  moment('2020-2-21 08:08:59').format(getTimeFormatString(timeType));

export const TextInputProperties: FC<Props> = ({ form, localKey }) => {
  const { forceUpdate } = useContext(ControlEditorContext);
  const controlList = form.getFieldValue('controls');

  if (_.isEmpty(controlList)) {
    return null;
  }
  const dataIndex = controlList.findIndex((item: LocalControlItem) => item._localKey === localKey);

  if (dataIndex === -1) {
    return null;
  }
  const getName = getNameFactory(dataIndex);
  const { timeType, enableEdit, showLogic, defaultLogic } = controlList[dataIndex];
  const demoTime = getDemoTime(timeType);
  const datePickerProps = {
    style: { width: '100%' },
    format: getTimeFormatString(timeType),
    placeholder: INPUT_DEFAULT_PLACEHOLDER,
    showTime:
      timeType === TimeType.hms ||
      timeType === TimeType.ymdhms ||
      timeType === TimeType.ymdhm ||
      timeType === TimeType.mdhm ||
      timeType === TimeType.dhm,
  };

  // 当『显示内容』发生变化时, 联动修改showValue的默认值
  const setShowValue = (logic: ShowLogic) => {
    let value: any;

    if (logic === ShowLogic.biz) {
      value = BizProperty.sopTaskPlanStartTime;
    }
    form.setFields([
      { name: getName('showLogic'), value: logic },
      { name: getName('showValue'), value },
    ]);
  };
  // 当『默认值』选项发生变化时, 联动修改defaultValue的默认值
  const setDefaultValue = (logic: DefaultLogic) => {
    let value: any;

    if (logic === DefaultLogic.biz) {
      value = BizProperty.sopTaskPlanStartTime;
    }
    form.setFields([
      { name: getName('defaultLogic'), value: logic },
      { name: getName('defaultValue'), value },
    ]);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={() => {
        forceUpdate();
        resetUnusedItems(form, ControlType.time, dataIndex, forceUpdate);
      }}
    >
      <Form.Item label="标题" name={getName('name')} rules={titleRules}>
        <Input max={CONTROL_TITLE_LENGTH} placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>
      <Form.Item label="日期时间类型">
        <Form.Item
          name={getName('timeType')}
          rules={[{ required: true, message: '请选择格式' }]}
          style={closeMarginBottom}
        >
          <Select options={timeTypeMappings} />
        </Form.Item>
        <div className="text-format-previewer">{demoTime}</div>
      </Form.Item>
      <Form.Item label="权限" name={getName('enableEdit')} rules={[{ required: true }]}>
        <Radio.Group options={editableMappings} />
      </Form.Item>
      {!enableEdit && (
        <Form.Item
          label="显示内容"
          name={getName('showLogic')}
          rules={[{ required: true }]}
          style={
            showLogic === ShowLogic.biz || showLogic === ShowLogic.fixed ? closeMarginBottom : {}
          }
        >
          <Select options={timeShowLogicMappings} onSelect={setShowValue} />
        </Form.Item>
      )}
      {!enableEdit && showLogic === ShowLogic.fixed && (
        <Form.Item
          name={getName('showValue')}
          rules={[{ required: true, message: '请输入显示内容' }]}
          {...timeItemProps}
        >
          <DatePicker {...datePickerProps} />
        </Form.Item>
      )}
      {!enableEdit && showLogic === ShowLogic.biz && (
        <Form.Item
          name={getName('showValue')}
          rules={[{ required: true, message: '请选择显示内容' }]}
        >
          <Select options={timeBizPropertyMappings} />
        </Form.Item>
      )}
      {enableEdit && (
        <Form.Item
          label="默认值"
          name={getName('defaultLogic')}
          rules={[{ required: true, message: '请选择默认值' }]}
          style={
            defaultLogic === DefaultLogic.biz || defaultLogic === DefaultLogic.fixed
              ? closeMarginBottom
              : {}
          }
        >
          <Select options={timeDefaultLogicMappings} onSelect={setDefaultValue} />
        </Form.Item>
      )}
      {enableEdit && defaultLogic === DefaultLogic.fixed && (
        <Form.Item
          name={getName('defaultValue')}
          rules={[{ required: true, message: '请输入默认值' }]}
          {...timeItemProps}
        >
          <DatePicker {...datePickerProps} />
        </Form.Item>
      )}
      {enableEdit && defaultLogic === DefaultLogic.biz && (
        <Form.Item
          name={getName('defaultValue')}
          rules={[{ required: true, message: '请选择默认值' }]}
        >
          <Select options={timeBizPropertyMappings} />
        </Form.Item>
      )}
      {enableEdit && (
        <Form.Item label="校验" style={{ marginBottom: 0 }}>
          <Form.Item name={getName('isRequired')} valuePropName="checked">
            <Checkbox>必填</Checkbox>
          </Form.Item>
        </Form.Item>
      )}
      <Form.Item label="描述信息" name={getName('remark')}>
        <Input.TextArea placeholder={INPUT_DEFAULT_PLACEHOLDER} maxLength={1000} showCount />
      </Form.Item>
      {enableEdit && (
        <Form.Item
          label="提示文字"
          name={getName('inputPrompt')}
          rules={[{ max: 20, message: '字符数最大为20' }]}
        >
          <Input allowClear placeholder={INPUT_DEFAULT_PLACEHOLDER} />
        </Form.Item>
      )}
    </Form>
  );
};

export default TextInputProperties;
