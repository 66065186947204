export const getStatus = ({ successNum = 0, failNum = 0, launchingNum = 0 }) => {
  if (launchingNum > 0) return '投放中';
  if (failNum === 0 && successNum > 0) {
    return '成功';
  } else if (failNum >= 0 && successNum === 0) {
    return '失败';
  }
  return '部分成功';
};

export const getResult = ({ successNum = 0, failNum = 0, launchingNum = 0 }) => {
  let result = `成功数：${successNum}，失败数：${failNum}`;

  if (launchingNum > 0) result += `，投放中：${launchingNum}`;

  return result;
};

/** 计划订单状态字段map */
export enum StatusEnum {
  /** 成功 */
  SUCCESS = 1,
  /** 部分成功 */
  PART_SUCCESS,
  /** 失败 */
  FAIL,
  /** 投放中 */
  ING,
}

export const StatusMap = new Map([
  [StatusEnum.SUCCESS, '成功'],
  [StatusEnum.PART_SUCCESS, '部分成功'],
  [StatusEnum.FAIL, '失败'],
  [StatusEnum.ING, '投放中'],
]);
