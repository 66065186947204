import { RelationObject } from '../index.d';
import React, { useEffect, useState } from 'react';
import { List, Input, Button, Tooltip } from 'antd';

const SelectList = ({
  list,
  isAddParent,
  title,
  addFunc,
  editNodeData,
}: {
  list: RelationObject[];
  title?: string;
  addFunc: (value: { object: RelationObject; isRoot?: boolean }) => void;
  isAddParent?: boolean;
  editNodeData?: RelationObject;
}) => {
  const [listData, setListData] = useState(list);
  const handleSearch = (value: string) => {
    setListData(list.filter((i) => i.objectName?.includes(value)));
  };

  useEffect(() => {
    setListData(list);
  }, [list]);

  return (
    <List
      header={
        <div>
          {title || '关联子对象'}
          <Input.Search
            style={{ margin: '10px 0px' }}
            placeholder="请输入对象名称"
            onSearch={(value) => handleSearch(value)}
          />
        </div>
      }
      bordered
      dataSource={listData}
      renderItem={(object) => (
        <List.Item>
          <Tooltip
            title={
              editNodeData?.objectId === object.objectId
                ? '关联该对象会导致数据重复错乱，无法选择'
                : ''
            }
          >
            <Button
              onClick={() => addFunc({ object, isAddParent })}
              disabled={editNodeData?.objectId === object.objectId}
            >
              {object.oneToManyMark && <span style={{ color: 'red' }}>*</span>}
              {object.objectName}
            </Button>
          </Tooltip>
        </List.Item>
      )}
    />
  );
};

export default SelectList;
