import React from 'react';
import { BlTable } from 'src/components';
//
import { EnergyMeterDetailType } from '../index.d';

interface DetailListProps {
  dataSource: EnergyMeterDetailType['tagsList'];
}

const DetailList = (props: DetailListProps) => {
  const { dataSource } = props;

  return (
    <BlTable
      dataSource={dataSource}
      columns={[
        {
          title: '标签项',
          dataIndex: 'name',
          width: 200,
        },
        {
          title: '标签值',
          dataIndex: 'content',
          width: 200,
        },
      ]}
    />
  );
};

export default DetailList;
