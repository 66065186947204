import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Form, Select, Checkbox, Input, message as Message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { NumberRulesStandardHook, SearchSelect } from 'src/components';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { textValidator1, textValidator2, withoutSpaceBothSides } from 'src/utils/formValidators';
import { Source } from 'src/dict/delivery/mappings';
import { SourceEnum } from 'src/dict/delivery';
import { useEffect, useState } from 'react';
import MaterialTable from './components/materialTable';
import { fetchReceiveNoteCreate } from 'src/api/ytt/order-domain/deliveryOrder';
import {
  fetchPurchaseOrderDetail,
  fetchPurchaseOrderItemDetail,
} from 'src/api/ytt/order-domain/purchaseOrder';
import { formatFetchValue } from './utils';
import { basePath } from './index';
import moment from 'moment';
import EditableText from 'src/components/editableText';
import { replaceSign } from 'src/utils/constants';

const { TextArea } = Input;

interface createProps extends RouteComponentProps {}
export const goToList = () => {
  return `${basePath}`;
};
const CreatePage = (props: createProps) => {
  const { history } = props;
  const [loading, setLoading] = useState(false);
  const [continuousCreate, setContinuousCreate] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const url = new URL(window.location.href);
    const purchaseOrderId = url.searchParams.get('id');

    if (purchaseOrderId) {
      Promise.all([
        fetchPurchaseOrderDetail({ id: Number(purchaseOrderId) }),
        fetchPurchaseOrderItemDetail({ id: Number(purchaseOrderId) }),
      ]).then(([{ data }, { data: materialLine }]) => {
        form.setFieldsValue({
          supplier: `${data?.supplier?.code} / ${data?.supplier?.name}`,
        });
        const itemList = materialLine?.map((node, index: number) => {
          const { seqNum, id: purchaseOrderItemId, demandAmount, purchaseOrderId, material } = node;

          return {
            material,
            returnAmount: 1,
            unitName: demandAmount?.unitName,
            unitId: demandAmount?.unitId,
            purchaseOrderCode: data?.code,
            supplier: data?.supplier,
            purchaseOrderItemSeqNum: seqNum,
            purchaseOrderItemId,
            purchaseOrderId,
            planReceiveTime: moment(node.demandTime),
            planReceiveAmount:
              Number(node?.demandAmount?.amount || 0) -
              Number(node?.planReceivedAmount?.amount || 0) +
              Number(node?.planReturnAmount?.amount || 0),
          };
        });

        form.setFieldsValue({ itemList });
      });
    } else {
      form.setFieldsValue({
        supplier: replaceSign,
      });
    }
  }, []);

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 3,
    items: [
      ...NumberRulesStandardHook({
        label: '编号',
        form,
        edit: false,
        objectCode: OBJECT_OF_CODE.receiveNote,
        fieldCode: 'code',
        disabledState: false,
        rules: [{ max: 255, message: '不超过255字符' }, { validator: textValidator2 }],
      }),
      {
        label: '来源',
        name: 'source',
        rules: [{ required: true, message: '请选择来源' }],
        render: () => <Select options={Source} disabled />,
      },
      {
        label: '供应商',
        name: 'supplier',
        render: () => <EditableText canEdit={false} />,
      },
      {
        label: '订单备注',
        name: 'remark',
        rules: [
          { max: 1000, message: '不超过1000个字符' },
          { validator: textValidator1 },
          withoutSpaceBothSides,
        ],
        render: () => <TextArea showCount maxLength={1000} placeholder="非必填" />,
      },
    ],
  };
  const materialInfo: DataFormLayoutInfoBlock = {
    title: '物料信息',
    column: 1,
    items: [
      {
        label: '',
        name: 'itemList',
        isFullLine: true,
        required: true,
        render: () => <MaterialTable form={form} name="itemList" source={SourceEnum.ordinary} />,
      },
    ],
  };
  const onCancel = () => {
    history.push(goToList());
  };
  const onFinish = async () => {
    setLoading(true);
    try {
      const value = await form?.validateFields();
      const newValue = formatFetchValue(value);
      const { data } = await fetchReceiveNoteCreate(newValue);

      setLoading(false);
      if (data) {
        Message.success('创建成功');
        if (continuousCreate) {
          form.resetFields();
          form.setFieldsValue({
            source: 1,
            itemList: [],
          });
          return;
        }
        history.push(goToList());
      }
    } catch (error) {
      console.log('error: ', error);
      setLoading(false);
    }
  };
  const extra = (
    <>
      <Checkbox
        onChange={(e) => {
          setContinuousCreate(e?.target?.checked);
        }}
      >
        连续新建
      </Checkbox>
    </>
  );

  useEffect(() => {
    form.setFieldsValue({ source: SourceEnum.ordinary });
  }, []);
  return (
    <>
      <DataFormLayout
        form={form}
        title={'新建收货单'}
        info={[baseInfo, materialInfo]}
        onCancel={onCancel}
        onFinish={onFinish}
        extra={extra}
        loading={loading}
      />
    </>
  );
};

export default CreatePage;
