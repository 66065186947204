import { VIEW_MODAL } from './constant';
import { TabList } from 'src/components';
import TriggerActionList from './list';

const TriggerRulesTabList = (props: any) => {
  const tabList = [
    {
      tab: '通用事件',
      key: VIEW_MODAL.generalAction,
      component: <TriggerActionList {...props} actionSource={VIEW_MODAL.generalAction} />,
    },
    {
      tab: '业务事件',
      key: VIEW_MODAL.businessAction,
      component: <TriggerActionList {...props} actionSource={VIEW_MODAL.businessAction} />,
    },
  ];

  return <TabList tabList={tabList} defaultKey={VIEW_MODAL.generalAction} />;
};

export default TriggerRulesTabList;
