/**
 * 用户控件属性
 */
import { CSSProperties, FC, useContext, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { Form, Input, Select, Radio, Checkbox } from 'antd';
import type { FormInstance } from 'antd';
import { ControlType, DefaultLogic, ShowLogic, UserOptionalRange } from 'src/dict/sop';

import {
  editable as editableMappings,
  userDefaultLogic as userDefaultLogicMappings,
  userShowLogic as userShowLogicMappings,
  userOptionalRange as userOptionalRangeMappings,
} from 'src/dict/sop/mappings';
import type { StepDetailData, LocalControlItem } from '../../types';
import {
  ControlEditorContext,
  CONTROL_TITLE_LENGTH,
  INPUT_DEFAULT_PLACEHOLDER,
} from '../../constants';
import { getNameFactory, resetUnusedItems, titleRules } from './share';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import {
  SimpleUserItem,
  LabelInValueSelectorProps,
} from 'src/page/organization/components/userAndDepartmentSelect/index.d';
import FormatSelect from '../../components/formatSelect';

interface Props {
  form: FormInstance<StepDetailData>;
  localKey: string;
}

const closeMarginBottom: CSSProperties = {
  marginBottom: 10,
};

// 用户最大选择数
const MAX_USER_SELECTED = 100;
// 描述信息最大值
const MAX_REMARK_LEN = 1000;
// 提示文字最大长度
const MAX_PROMPT_LEN = 20;

const mapFn = (v: SimpleUserItem) => ({
  label: v.name,
  value: v.id,
});

export const UserInputProperties: FC<Props> = ({ form, localKey }) => {
  const { forceUpdate } = useContext(ControlEditorContext);
  const controlList = form.getFieldValue('controls');
  const dataIndex = controlList.findIndex((item: LocalControlItem) => item._localKey === localKey);
  const getName = getNameFactory(dataIndex);
  const { enableEdit, defaultLogic, optionalRange, showLogic } = controlList[dataIndex];

  // 映射：可选范围-->默认逻辑
  const limitMap = new Map([
    [UserOptionalRange.current, DefaultLogic.currentUser],
    [UserOptionalRange.appoint, DefaultLogic.fixedUser],
  ]);
  const formatOptionRangeList = useMemo(() => {
    const filterUserOptionalRange = userDefaultLogicMappings.filter(
      (item) =>
        item.value === DefaultLogic.none ||
        item.value === limitMap.get(form.getFieldValue(getName('optionalRange'))),
    );

    return filterUserOptionalRange;
  }, [form.getFieldValue(getName('optionalRange'))]);

  // 表单未触控，取上层组件传的值（含初始化和编辑初进来）；表单元素触控，则重置为各字段默认值
  const setFieldValueofCondition = (
    conditions: string[],
    formItemName: string,
    defaultValue: any,
  ) => {
    let isTouched = false;

    for (const value of conditions) {
      if (form.isFieldTouched(getName(value))) {
        isTouched = true;
        break;
      }
    }
    const fieldValue = !isTouched ? form.getFieldValue(getName(formItemName)) : defaultValue;

    form.setFields([
      {
        name: getName(formItemName),
        value: fieldValue,
      },
    ]);
  };
  const appointDefaultList = useMemo(() => {
    return form.getFieldValue(getName('optionalUser'))?.map(mapFn) ?? [];
  }, [form.getFieldValue(getName('optionalUser'))]);

  useEffect(() => {
    const defaultUserOption: SimpleUserItem[] = form.getFieldValue(getName('defaultUserOption'));
    const allIds = appointDefaultList?.map((item: LabelInValueSelectorProps) => item.value);

    form.setFields([
      {
        name: getName('defaultUserOption'),
        value: defaultUserOption?.filter((item) => allIds.includes(item.id)),
      },
    ]);
  }, [appointDefaultList]);

  useEffect(() => {
    setFieldValueofCondition(['enableEdit'], 'optionalRange', UserOptionalRange.current);
    setFieldValueofCondition(['enableEdit'], 'showLogic', ShowLogic.currentUser);
  }, [form.getFieldValue(getName('enableEdit'))]);
  useEffect(() => {
    setFieldValueofCondition(['showLogic', 'optionalRange'], 'showValueList', []);
  }, [form.getFieldValue(getName('showLogic')), form.getFieldValue(getName('optionalRange'))]);
  useEffect(() => {
    setFieldValueofCondition(['optionalRange'], 'enableCheckbox', true);
    setFieldValueofCondition(['optionalRange'], 'defaultLogic', DefaultLogic.none);
    // 可选范围变化时，默认值下拉options随着变化
  }, [form.getFieldValue(getName('optionalRange'))]);

  useEffect(() => {
    setFieldValueofCondition(['defaultLogic'], 'defaultUserOption', []);
  }, [form.getFieldValue(getName('defaultLogic'))]);

  useEffect(() => {
    if (!form.getFieldValue(getName('enableCheckbox'))) {
      setFieldValueofCondition(['enableCheckbox'], 'defaultUserOption', []);
    }
  }, [form.getFieldValue(getName('enableCheckbox'))]);

  if (_.isEmpty(controlList)) {
    return null;
  }

  if (dataIndex === -1) {
    return null;
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={() => {
        forceUpdate();
        resetUnusedItems(form, ControlType.user, dataIndex, forceUpdate);
      }}
    >
      <Form.Item label="标题" name={getName('name')} rules={titleRules}>
        <Input max={CONTROL_TITLE_LENGTH} placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>
      <Form.Item label="权限" name={getName('enableEdit')} rules={[{ required: true }]}>
        <Radio.Group options={editableMappings} />
      </Form.Item>

      {/* 显示内容相关逻辑 */}
      {!enableEdit && (
        <Form.Item label="显示内容" name={getName('showLogic')} rules={[{ required: true }]}>
          <Select options={userShowLogicMappings} placeholder={'请选择'} />
        </Form.Item>
      )}
      {!enableEdit && showLogic === ShowLogic.fixedUser && (
        <>
          <Form.Item shouldUpdate noStyle>
            {() => {
              return (
                <Form.Item
                  name={getName('showValueList')}
                  rules={[
                    { required: true, message: '请选择添加用户' },
                    {
                      type: 'array',
                      max: MAX_USER_SELECTED,
                      message: `最多添加${MAX_USER_SELECTED}个用户，当前已选择${
                        form.getFieldValue(getName('showValueList'))?.length
                      }个`,
                    },
                  ]}
                >
                  <UserOrDepartmentSelectWithDialog
                    isNewFormat
                    isMultiple
                    isSelectUser
                    userActives={[0, 1]}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </>
      )}

      {/* 可选范围相关 */}
      {enableEdit && (
        <Form.Item
          label="可选范围"
          name={getName('optionalRange')}
          rules={[{ required: true }]}
          style={optionalRange === UserOptionalRange.appoint ? closeMarginBottom : {}}
        >
          <Select options={userOptionalRangeMappings} placeholder={'请选择'} />
        </Form.Item>
      )}
      {enableEdit && optionalRange === UserOptionalRange.appoint && (
        <>
          <Form.Item shouldUpdate noStyle>
            {() => {
              return (
                <Form.Item
                  name={getName('optionalUser')}
                  rules={[
                    { required: true, message: '请选择添加用户' },
                    {
                      type: 'array',
                      max: MAX_USER_SELECTED,
                      message: `最多添加${MAX_USER_SELECTED}个用户，当前已选择${
                        form.getFieldValue(getName('optionalUser'))?.length
                      }个`,
                    },
                  ]}
                >
                  <UserOrDepartmentSelectWithDialog
                    isNewFormat
                    isMultiple
                    isSelectUser
                    userActives={[0, 1]}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item name={getName('enableCheckbox')} valuePropName="checked">
            <Checkbox>在APP执行时，支持多选</Checkbox>
          </Form.Item>
        </>
      )}
      {enableEdit && (
        <Form.Item
          label="默认值"
          name={getName('defaultLogic')}
          rules={[{ required: true, message: '请选择默认值' }]}
          style={closeMarginBottom}
        >
          <Select options={formatOptionRangeList} placeholder={'请选择'} />
        </Form.Item>
      )}
      {enableEdit &&
        optionalRange === UserOptionalRange.appoint &&
        defaultLogic === DefaultLogic.fixedUser && (
          <Form.Item shouldUpdate noStyle>
            {() => {
              const enableCheckbox = form.getFieldValue(getName('enableCheckbox'));

              return (
                <Form.Item
                  name={getName('defaultUserOption')}
                  rules={[{ required: true, message: '请选择用户' }]}
                >
                  <FormatSelect isMultiple={enableCheckbox} options={appointDefaultList} />
                </Form.Item>
              );
            }}
          </Form.Item>
        )}

      <Form.Item label="校验" style={{ marginBottom: 0 }}>
        <Form.Item name={getName('isRequired')} valuePropName="checked">
          <Checkbox>必填</Checkbox>
        </Form.Item>
      </Form.Item>
      <Form.Item label="描述信息" name={getName('remark')}>
        <Input.TextArea
          placeholder={INPUT_DEFAULT_PLACEHOLDER}
          maxLength={MAX_REMARK_LEN}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="提示文字"
        name={getName('inputPrompt')}
        rules={[{ max: MAX_PROMPT_LEN, message: `请限制在${MAX_PROMPT_LEN}个字符` }]}
      >
        <Input allowClear placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>
    </Form>
  );
};

export default UserInputProperties;
