import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FilterItem, RecordListLayout } from 'layout';
import { fieldTypeList, gcObject, gcTime } from 'utils';
import { SearchSelect } from 'src/components';
import { fetchAmountAdjustListRecord } from 'src/api/ytt/inventory-domain/adjust';
import { AvatarDisplay } from 'src/components/avatar/show';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import moment, { Moment } from 'moment';
import lookup from 'src/dict';
import { fetchCustomFieldInstanceGetByObjectCode } from 'src/api/ytt/custom-object-domain';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { injectCustomFieldColumns } from 'src/components/customField';

const AdjustRecordList: React.FC = () => {
  const [customFields, setCustomFields] = useState<any>();
  const dataColumns: any[] = [
    {
      title: '业务类型',
      dataIndex: 'bizType',
      width: 150,
      render: (bizType: number) => {
        return lookup('warehouse.recordAdjustExecuteTypeEnum', bizType);
      },
    },
    {
      title: '仓库',
      dataIndex: 'warehouse',
      width: 180,
      render: (text: any, record: any) => {
        const warehouse = _.get(record, 'invElement.location.warehouse', {});

        if (_.isEmpty(warehouse)) {
          return replaceSign;
        }
        return `${warehouse?.code}/${warehouse?.name}`;
      },
    },
    {
      title: '区域',
      dataIndex: 'area',
      defaultColConfig: {
        display: false,
      },
      width: 180,
      render: (text: unknown, record: any) => {
        const area = _.get(record, 'invElement.location.area');

        if (_.isEmpty(area)) {
          return replaceSign;
        }
        return `${area?.code}/${area?.name}`;
      },
    },
    {
      title: '仓位',
      dataIndex: 'location',
      width: 180,
      isFilter: true,
      type: fieldTypeList?.select,
      renderItem: <SearchSelect fetchType={'storageLocation'} />,
      render: (text: any, record: any) => {
        const location = _.get(record, 'invElement.location.location', {});

        if (_.isEmpty(location)) {
          return replaceSign;
        }
        return `${location?.code}/${location?.name}`;
      },
    },
    {
      title: '关联单号',
      dataIndex: 'field4',
      defaultColConfig: {
        display: false,
      },
      width: 200,
      render: (text: unknown, record: any) => {
        return _.get(record, 'invElement.field4', replaceSign);
      },
    },
    {
      title: '标识码',
      dataIndex: 'qrCode',
      width: 180,
      isFilter: true,
      type: fieldTypeList?.text,
      render: (text: unknown, record: any) => {
        return _.get(record, 'invElement.qrCode', replaceSign);
      },
    },
    {
      title: '供应商',
      dataIndex: 'supplier',
      width: 200,
      render: (text: unknown, record: any) => {
        return _.get(record, 'invElement.supplier', replaceSign);
      },
    },
    {
      title: '物料编号',
      dataIndex: ['material', 'code'],
      width: 180,
    },
    {
      title: '物料名称',
      dataIndex: ['material', 'name'],
      width: 180,
    },
    {
      title: '物料',
      dataIndex: 'material',
      isFilter: true,
      disabled: true,
      type: fieldTypeList.select,
      renderItem: <SearchSelect placeholder="请选择物料" fetchType={'materialCodeName'} />,
    },
    {
      title: '调前数量',
      dataIndex: 'beforeAmount',
      width: 150,
      render: (text: unknown, record: any) => {
        const beforeAmount = _.get(record, 'invElement.beforeAmount', {
          amount: '-',
          unitName: '-',
        });

        return beforeAmount?.amount + ' ' + beforeAmount?.unitName;
      },
    },
    {
      title: '调至数量',
      dataIndex: 'afterAmount',
      width: 150,
      render: (text: unknown, record: any) => {
        const afterAmount = _.get(record, 'invElement.afterAmount', {
          amount: '-',
          unitName: '-',
        });

        return afterAmount?.amount + ' ' + afterAmount?.unitName;
      },
    },
    {
      title: '差异数量',
      dataIndex: 'changAmount',
      width: 150,
      render: (text: unknown, record: any) => {
        const changAmount = _.get(record, 'invElement.changAmount', { amount: '-', unitName: '-' });

        return changAmount?.amount + ' ' + changAmount?.unitName;
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 200,
    },
    {
      title: '操作人',
      dataIndex: 'creator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      renderItem: <SearchSelect fetchType={'user'} />,
      render: (user: any) => {
        return <AvatarDisplay {...user} key={user?.id} isShowTag isUser />;
      },
    },
    {
      title: '操作时间',
      dataIndex: 'createdAt',
      width: 180,
      isFilter: true,
      type: fieldTypeList.date,
      render: (operateAt: number) => {
        if (!operateAt) return replaceSign;
        return _Time.format(operateAt);
      },
    },
    {
      title: '',
      dataIndex: 'action',
      width: 60,
      fixed: 'right',
      render: () => ' ',
    },
  ];

  const config = {
    rowKey: (record: any) => String(record.id),
    columns: injectCustomFieldColumns({
      columns: dataColumns.filter((d) => !d.disabled), // 原本的列
      customFields, // 自定义字段信息
      objectCode: OBJECT_OF_CODE.amountAdjustRecord, // 从对象code
      type: 'detail', // 使用类型
    }),
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((columns: any): FilterItem => {
        const filter = {
          label: columns.title,
          name: columns.dataIndex,
          ...columns,
        };

        return filter;
      }),
    formatDataToFormDisplay: (filterData: any) => {
      const { createdAt, ...rest } = filterData;

      const result = {
        ...rest,
        createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
      };

      return gcObject.filterEmptyProperty(result);
    },
    formatDataToQuery: (params: any) => {
      const { quickSearch, qrCode, location, createdAt, creator, material, ...rest } = params;
      const [createdAtFrom, createdAtTill] = createdAt?.map((time: Moment) =>
        moment(time).format('x'),
      ) || [undefined, undefined];

      const options = {
        qrCode: qrCode || quickSearch,
        operatorId: creator?.value,
        materialId: material?.value,
        locationId: location?.value,
        createdAtFrom,
        createdAtTill,
        ...rest,
      };

      return gcObject.filterEmptyProperty(options);
    },
  };

  /** 获取对象自定义字段 */
  const fetchCustomFields = async () => {
    const res = await fetchCustomFieldInstanceGetByObjectCode({
      objectCode: OBJECT_OF_CODE.amountAdjustRecord,
    });

    setCustomFields((res?.data as any) || {});
  };

  useEffect(() => {
    fetchCustomFields();
  }, []);

  return (
    <>
      <RecordListLayout<any>
        placeholder={'输入标识码，回车快速查询'}
        configcacheKey="adjustRecordList"
        {...config}
        requestFn={fetchAmountAdjustListRecord}
      />
    </>
  );
};

export default AdjustRecordList;
