import _ from 'lodash';

interface funcProps {
  genArr: (length: number, from: number, step: number) => Array<number>;
  convertConfig: (arr: any[]) => any;
  splitRequestData: (arr: Array<any>) => Array<any>;
  slice: (arr: Array<any>, chunkSize: number) => Array<any>;
  isEmpty: (array: any) => boolean;
  splitRequestDataByFifty: (arr: any[], _splitKey: any, chunkSize: number) => Array<any>;
  swap: (arr: Array<any>, index: number, afterIndex: number) => Array<any>;
  removeDuplicates: (array: Array<any>) => Array<any>;
  findDuplicates: (array: Array<any>) => Array<any>;
  joinWithStr: (array: Array<any>, string: string) => string | undefined;
  move: (array: Array<any>, moveIndex: number, toIndex: number) => Array<any>;
}

const REQUEST_DATA_MAX_LENGTH = 10;

/**
 * @description 自增数字数组的生成
 * @example
 * @param length 数组的长度
 * @param from  数组的首位数字
 * @param step  数组的内数字的间隔
 * @returns
 */
const genArr = (length: number, from = 0, step = 1): Array<number> => {
  const arr: number[] = [];

  for (let i: number = from; i < length * step + from; i += step) {
    arr.push(i);
  }
  return arr;
};

/**
 * @description 对象数组的抽取, 抽取出对象数组中有值的对象
 * @example [{"configKey": "erpSyncConfig", "configValue": "true"}] => {erpSyncConfig:"true"}
 * @param configArray
 * @returns
 */
const convertConfig = (configArray: Array<any>): any => {
  const config = {} as any;

  if (Array.isArray(configArray)) {
    configArray.forEach((element: { configKey: string; configValue: any }) => {
      config[element.configKey] = element.configValue;
    });
  }
  return config;
};

/**
 * @description requestData的分割，最大长度为 10, split data to [ data ]
 * @param arr
 * @returns
 */
const splitRequestData = (arr: Array<any>): Array<any> => {
  const result: Array<any> = [];

  if (arr.length < REQUEST_DATA_MAX_LENGTH) {
    result.push(arr);
  } else {
    const times =
      arr.length % REQUEST_DATA_MAX_LENGTH === 0
        ? REQUEST_DATA_MAX_LENGTH
        : REQUEST_DATA_MAX_LENGTH + 1; // 分为几次发送
    const partAmount = Math.floor(arr.length / REQUEST_DATA_MAX_LENGTH); // 每次发送多少条

    for (let i = 0; i < times; i += 1) {
      const start = i * partAmount;
      const end = i === REQUEST_DATA_MAX_LENGTH ? arr.length : (i + 1) * partAmount;

      result.push(arr.slice(start, end));
    }
  }
  return result;
};

/**
 * @description 是否为空数组,
 * @example '' ,[] 皆为空数组，不可进行循环取值
 * @param array
 * @returns
 */
const isEmpty = (array: any) => {
  return Array.isArray(array) ? !(array.length > 0) : true;
};

/**
 * @description 根据字节数分割请求数据 split data to [ data ]
 * @param arr 请求数据的数组
 * @param _splitKey
 * @param chunkSize
 * @returns [data]
 */
const splitRequestDataByFifty = (arr: any[], _splitKey: any, chunkSize = 500) => {
  let splitKey = _splitKey;

  if (typeof _splitKey === 'string') {
    splitKey = [_splitKey];
  }
  const result: any[] = [];

  if (arr.length < chunkSize) {
    result.push(arr);
  } else {
    const times = Math.ceil(arr.length / chunkSize); // 分为几次发送
    const partAmount = times === 1 ? arr.length : chunkSize; // 每次发送多少条
    let lastEnd = 0;

    for (let i = 0; lastEnd + 1 < arr.length; i += 1) {
      const start = lastEnd;
      let end: any = (i + 1) * partAmount;

      if (i === times - 1) {
        end = arr.length;
      }
      if (start < end) {
        if (!isEmpty(splitKey)) {
          while (
            end !== arr.length &&
            Boolean(
              _.isEqualWith(arr[end - 1], arr[end], (a, b) => {
                return Boolean(
                  _.reduce(splitKey, (pre, cur) => pre && _.get(a, cur) === _.get(b, cur), true),
                );
              }),
            )
          ) {
            end += 1;
          }
        }
        lastEnd = end;
        result.push(arr.slice(start, end));
      }
    }
  }
  return result;
};

/**
 * @description 等长分割数组
 * @example slice([1,23,44,5], 4) => [[1, 23, 44, 5]]
 * @param arr 数组
 * @param chunkSize 数组的最大长度
 * @returns 分割后的数组
 */
const slice = (arr: Array<any> = [], chunkSize = 0) => {
  let result: Array<any> = [];

  if (arr.length <= chunkSize) {
    result.push(arr);
  } else {
    const length = Math.floor(arr.length / chunkSize);

    result = Array.from({ length }).map(() => arr.splice(0, chunkSize));
  }
  return result;
};

/**
 * @description 数组元素的交换
 * @param arr  数组
 * @param index 交换元素index
 * @param afterIndex 交换后的index
 * @returns 交换后的数组
 */
const swap = (arr: Array<any>, index: number, afterIndex: number) => {
  if (!Array.isArray(arr)) {
    return arr;
  }
  const temp = arr[index];

  arr[index] = arr[afterIndex];
  arr[afterIndex] = temp;
  return [...arr];
};

/**
 * @description 数组去重
 * @param array 数组
 * @returns     去重后的数组
 */
const removeDuplicates = (array: Array<any>) => {
  if (isEmpty(array)) {
    return array;
  }
  return array.filter((item, index) => array.indexOf(item) === index);
};

/**
 * @description 找出数组中重复的项
 * @param array 数组
 * @returns     重复的数组
 */
const findDuplicates = (arr: Array<any>) => {
  const newArr = [];

  arr.sort();
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] == arr[i + 1] && newArr.indexOf(arr[i]) == -1) {
      newArr.push(arr[i]);
      i++;
    }
  }

  return newArr;
};

/**
 * @description 数组转换为使用指定符号分割的字符串
 * @example joinWithStr([a,b,c], ',') => 'a,b,c'
 * @param array  数组
 * @param string 分割符号
 * @returns      分割后的字符串
 */
const joinWithStr = (array: Array<any>, string: string): string | undefined => {
  if (isEmpty(array)) {
    return;
  }
  return array.join(string);
};

function move(array: any[], moveIndex: number, toIndex: number) {
  const { length } = array;

  if (moveIndex < 0 || moveIndex >= length || toIndex < 0 || toIndex >= length) {
    return array;
  }
  const item = array[moveIndex];
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  }
  if (diff < 0) {
    // move right
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, toIndex + 1),
      item,
      ...array.slice(toIndex + 1, length),
    ];
  }
  return array;
}

const _Array = {} as funcProps;

_Array.genArr = genArr;
_Array.convertConfig = convertConfig;
_Array.splitRequestData = splitRequestData;
_Array.slice = slice;
_Array.isEmpty = isEmpty;
_Array.splitRequestDataByFifty = splitRequestDataByFifty;
_Array.swap = swap;
_Array.removeDuplicates = removeDuplicates;
_Array.findDuplicates = findDuplicates;
_Array.joinWithStr = joinWithStr;
_Array.move = move;

export default _Array;
