import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';
import { message as Message } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock, DataFormLayoutForModal } from 'layout';
import { BlTable } from '@blacklake-web/component';
import _ from 'lodash';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { lookup } from 'src/dict';
import { ReturnOrigin, ReturnStatus } from 'src/dict/salesReturnOrder';
import { SingleOperationLogComponent } from 'src/components';
import { renderUser } from 'src/page/share/renderUser';
import { amountRender } from 'src/page/share/renderAmount';
import renderMultiLinks from 'src/page/share/renderMultiLinks';
import { loadSalesReturnOrderDetailService } from '../services';
import { goToList, goToEdit, goToCreateInbound, goToSalesOrder } from '../navigates';
import { ReceiveGoodsDialog, renderInboundOrder } from '../components';
import { fieldLabels } from '../constants';
import { SalesReturnOrderDetailType } from '../interface';
import { useCloseSalesReturnOrderModal, useFinishReturnOrderModal } from '../hooks';
interface SalesReturnOrderDetailProps {
  id: string;
}

export default function SalesReturnOrderDetail({
  match,
}: RouteComponentProps<SalesReturnOrderDetailProps>) {
  const orderId = Number(match.params.id);
  const [orderDetail, setOrderDetail] = useState<SalesReturnOrderDetailType>();
  const [loading, setLoading] = useState(false);
  const [receiveGoodsVisible, setReceiveGoodsVisible] = useState(false);
  const [visibleLog, setVisibleLog] = useState(false);
  const dispatch = useDispatch();
  const { show: showCloseSalesReturnOrderModal } = useCloseSalesReturnOrderModal();
  const { show: showFinishReturnOrderModal } = useFinishReturnOrderModal();

  if (!orderId) goToList();
  /**
   * 新建入库单操作
   */
  const goToInbound = () => {
    dispatch({
      type: 'supplyChain/formatSalesReturnOrderToInboundOrder',
      payload: [orderDetail],
    });
    goToCreateInbound();
  };
  /**
   * 收货操作
   */
  const receiveGoods = () => {
    setReceiveGoodsVisible(true);
  };
  /**
   * 协同采购退货单 - Columns
   */
  const returnInfoColumns = [
    {
      title: fieldLabels.lineNo,
      dataIndex: 'lineNo',
      width: 80,
    },
    {
      title: fieldLabels.materialName,
      dataIndex: ['material', 'baseInfo', 'name'],
      width: 120,
    },
    {
      title: fieldLabels.materialCode,
      dataIndex: ['material', 'baseInfo', 'code'],
      width: 120,
    },
    {
      title: fieldLabels.plannedReturnAmount,
      dataIndex: 'amount',
      render: amountRender,
      width: 120,
    },
    {
      title: fieldLabels.inboundPlannedAmount,
      dataIndex: 'inboundPlannedAmount',
      render: amountRender,
      width: 120,
    },
    {
      title: fieldLabels.inboundAmount,
      dataIndex: 'inboundAmount',
      render: amountRender,
      width: 120,
    },
    {
      title: fieldLabels.customerReturnedAmount,
      dataIndex: 'customerReturnedAmount',
      render: amountRender,
      width: 120,
    },
    {
      title: fieldLabels.salesOrderCode,
      dataIndex: 'salesOrderCode',
      width: 160,
    },
    {
      title: fieldLabels.salesOrderItemLineNo,
      dataIndex: 'salesOrderItemLineNo',
      width: 160,
    },
    {
      title: fieldLabels.relatedInboundOrder,
      dataIndex: 'inboundOrderList',
      render: renderInboundOrder,
      width: 120,
    },
  ];
  /**
   * 基本信息
   */
  const basicInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: fieldLabels.code,
        dataIndex: 'code',
      },
      {
        label: fieldLabels.status,
        dataIndex: 'returnStatus',
        render: lookup('salesReturnOrder.returnStatus'),
      },
      {
        label: fieldLabels.origin,
        dataIndex: 'origin',
        render: lookup('salesReturnOrder.returnOrigin'),
      },
      {
        label: fieldLabels.salesOrderCode,
        dataIndex: 'salesOrderList',
        render: (salesOrderList: NonNullable<SalesReturnOrderDetailType>['salesOrderList']) =>
          renderMultiLinks(salesOrderList, 'code', goToSalesOrder),
      },
      {
        label: fieldLabels.customerInfo,
        dataIndex: 'customer',
        render: (customer: NonNullable<SalesReturnOrderDetailType>['customer']) =>
          `${customer?.code}/${customer?.name}`,
      },
      {
        label: fieldLabels.orderOrigin,
        dataIndex: 'origin',
        render: lookup('salesReturnOrder.returnOrigin'),
      },
      {
        label: fieldLabels.remark,
        dataIndex: 'remark',
      },
      {
        label: fieldLabels.creator,
        dataIndex: 'creator',
        render: renderUser,
      },
      {
        label: fieldLabels.createdAt,
        dataIndex: 'createdAt',
        render: formatTimeForRender,
      },
      {
        label: fieldLabels.operator,
        dataIndex: 'operator',
        render: renderUser,
      },
      {
        label: fieldLabels.updatedAt,
        dataIndex: 'updatedAt',
        render: formatTimeForRender,
      },
      {
        label: fieldLabels.finishUser,
        dataIndex: 'finishUser',
        render: renderUser,
      },
      {
        label: fieldLabels.finishedAt,
        dataIndex: 'finishedAt',
        render: formatTimeForRender,
      },
      {
        label: fieldLabels.closeUser,
        dataIndex: 'closeUser',
        render: renderUser,
      },
      {
        label: fieldLabels.closedAt,
        dataIndex: 'closedAt',
        render: formatTimeForRender,
      },
    ],
  };
  /**
   * 协同采购退货单信息
   */
  const scmInfo: DetailLayoutInfoBlock = {
    title: '协同采购退货单信息',
    items: [
      {
        label: fieldLabels.returnAuditNoteCode,
        dataIndex: 'returnAuditNoteCode',
      },
      {
        label: fieldLabels.purchaseReturnOrderCode,
        dataIndex: 'purchaseOrderReturnCode',
      },
      {
        label: fieldLabels.remark,
        dataIndex: ['purchaseOrderReturnInfo', 'remark'],
      },
    ],
  };
  /**
   * 退货信息
   */
  const returnInfo: DetailLayoutInfoBlock = {
    title: '退货信息',
    items: [
      {
        dataIndex: 'items',
        render: (items) => (
          <BlTable
            dataSource={items}
            columns={returnInfoColumns}
            pagination={false}
            rowKey="id"
            scroll={{ x: 'max-content', y: 300 }}
          />
        ),
      },
    ],
  };
  /**
   * 详情 - 信息块
   */
  const detailInfo: DetailLayoutInfoBlock[] =
    orderDetail?.origin === ReturnOrigin.cowork
      ? [basicInfo, scmInfo, returnInfo]
      : [basicInfo, returnInfo];
  /**
   * 详情 - 操作
   */
  const baseMenu = _.isNil(orderDetail)
    ? []
    : _.compact([
        // (orderDetail?.returnStatus === ReturnStatus.new ||
        //   orderDetail?.returnStatus === ReturnStatus.executing) && {
        //   title: mainProductLicensed ? '新建入库单' : '收货',
        //   auth: mainProductLicensed
        //     ? authDict.repo_outbound_inbound_inbound_add_edit
        //     : authDict.sale_return_audit_note_receipt,
        //   key: 'receive',
        //   onClick: () => {
        //     mainProductLicensed ? goToInbound() : receiveGoods();
        //   },
        // },
        // TODO: 许可项
        (orderDetail?.returnStatus === ReturnStatus.new ||
          orderDetail?.returnStatus === ReturnStatus.executing) && {
          title: '新建入库单',
          auth: authDict.inboundorder_add,
          key: 'inbound',
          onClick: () => {
            const amountInvalid = orderDetail.items?.every(
              (item) => item.amount?.amount! - item.inboundPlannedAmount?.amount! <= 0,
            );

            if (amountInvalid) {
              Message.error('暂无可入库物料');
            } else {
              goToInbound();
            }
          },
        },
        (orderDetail?.returnStatus === ReturnStatus.new ||
          orderDetail?.returnStatus === ReturnStatus.executing) && {
          title: '收货',
          auth: authDict.salesorderreturn_receipt,
          key: 'receive',
          onClick: () => {
            receiveGoods();
          },
        },
        (orderDetail?.returnStatus === ReturnStatus.new ||
          orderDetail?.returnStatus === ReturnStatus.executing) && {
          key: 'finish',
          onClick: () => {
            showFinishReturnOrderModal(
              orderDetail.code!,
              orderDetail.id!,
              fetchSalesReturnOrderDetail,
            );
          },
          title: '结束',
          auth: authDict.salesorderreturn_finish,
        },
        orderDetail?.returnStatus === ReturnStatus.new &&
          orderDetail?.origin === ReturnOrigin.normal && {
            key: 'close',
            onClick: () => {
              showCloseSalesReturnOrderModal(orderDetail.id!, fetchSalesReturnOrderDetail);
            },
            title: '关闭',
            auth: authDict.salesorderreturn_close,
          },
        {
          key: 'record',
          onClick: () => {
            setVisibleLog(true);
          },
          title: '操作记录',
          auth: authDict.salesorderreturn_operrecord,
        },
        orderDetail?.origin === ReturnOrigin.normal &&
          (orderDetail?.returnStatus === ReturnStatus.new ||
            orderDetail?.returnStatus === ReturnStatus.executing) && {
            key: 'edit',
            onClick: () => {
              goToEdit(Number(orderDetail.id));
            },
            title: '编辑',
            auth: authDict.salesorderreturn_edit,
          },
      ]);

  const fetchSalesReturnOrderDetail = async () => {
    setLoading(true);
    const detailData = await loadSalesReturnOrderDetailService(orderId);

    setLoading(false);
    if (detailData) setOrderDetail(detailData);
  };

  /**
   * 初始化
   */
  useEffect(() => {
    fetchSalesReturnOrderDetail();
  }, []);

  return (
    <>
      <DetailLayout
        title="销售退货单详情"
        userAuth={getAuthFromLocalStorage()}
        dataSource={orderDetail}
        loading={loading}
        baseMenu={baseMenu}
        info={detailInfo}
      />
      {receiveGoodsVisible && (
        <DataFormLayoutForModal
          visible={receiveGoodsVisible}
          onClose={() => {
            setReceiveGoodsVisible(false);
          }}
          content={
            <ReceiveGoodsDialog
              returnOrder={orderDetail!}
              onFinish={fetchSalesReturnOrderDetail}
              onCancel={() => {
                setReceiveGoodsVisible(false);
              }}
            />
          }
        />
      )}
      {visibleLog && orderDetail && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={Number(orderDetail?.id)}
          objectCode={'SalesOrderReturn'} // 对象在对象平台唯一定义的objectCode
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
}
