import React from 'react';
import { DetailLayout, DetailLayoutMenuItem } from 'layout';
import { Button, Space, Tooltip } from 'antd';
import {
  detailBaseInfo,
  renderDetailPurchaseInfo,
  invoiceDetailsColumn,
  invoiceDetailsMainColumn,
  orderDetailColumn,
  WorkOrderDetailColumn,
  WorkOrderDetailMainColumn,
  renderDetailPurchaseColumn,
} from '../components/columns';
import { BlTable } from 'src/components';
import {
  PurchaseOrderCrossCustomFields,
  SalesOrderDetailsHeaderShipmentNoteList,
  SalesOrderDetailsResponse,
  WorkOrderListTypeHeader,
} from '../interface';
import _ from 'lodash';
import { toCreateProductionOrder } from 'src/page/productionPlanning/productionOrder/navigation';
import { useHistory } from 'react-router-dom';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { appEnum } from 'src/dict';
import {
  injectCustomFieldColumns,
  injectCustomFieldInfos,
  ICustomFields,
} from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { FieldType } from 'src/dict/customField';
import { customLayoutChooseButton } from 'src/components/customLayout/hooks/customLayoutForButton';
import { ObjectCode } from 'src/dict/common';

interface DetailBaseInfoProps {
  detail: SalesOrderDetailsResponse;
  loading?: boolean;
  baseMenu?: DetailLayoutMenuItem[];
  crossCustomFields?: PurchaseOrderCrossCustomFields;
  customFields?: { document: ICustomFields; material: ICustomFields };
}

export const DetailBaseInfo = ({
  detail,
  loading,
  baseMenu,
  crossCustomFields,
  customFields,
}: DetailBaseInfoProps) => {
  const history = useHistory();
  /**
   * 工单信息
   */
  const expandedRowRender = (record: WorkOrderListTypeHeader) => {
    return (
      <BlTable columns={WorkOrderDetailColumn} dataSource={record?.items} pagination={false} />
    );
  };
  /**
   * 发货信息
   */
  const invoiceExpandedRowRender = (record: SalesOrderDetailsHeaderShipmentNoteList) => {
    return <BlTable columns={invoiceDetailsColumn} dataSource={record?.items} pagination={false} />;
  };
  /**
   *  订单明细行 Column
   */
  const columnDetail = [
    ...orderDetailColumn,
    {
      title: '操作',
      dataIndex: 'action',
      width: 150,
      fixed: 'right',
      render: (_: any, record: any) => {
        return (
          <>
            <Space>
              {record?.status === appEnum.Sales.OrderStatus.create ? (
                customLayoutChooseButton({
                  title: '创建工单',
                  objectCode: ObjectCode.POConfig,
                  type: 'edit',
                  onClick: (layoutId) => {
                    history.push(
                      toCreateProductionOrder(
                        {
                          ...record,
                          orderCode: detail?.header?.code,
                        },
                        layoutId,
                      ),
                    );
                  },
                })
              ) : (
                <Tooltip title={'数量已全部生成工单，禁止创建工单'}>
                  <Button type="link" style={{ padding: 0 }} disabled>
                    创建工单
                  </Button>
                </Tooltip>
              )}
            </Space>
          </>
        );
      },
    },
  ];
  /**
   * 权限点
   */
  const hasAuthRes = hasAuth(authDict.productionorder_add);
  const relColumnDetail = hasAuthRes
    ? columnDetail
    : _.filter(orderDetailColumn, (node: any) => node.dataIndex !== 'action');
  /**
   * 跨租户的采购订单的自定义字段
   */
  const crossDocumentCustomFields = injectCustomFieldInfos({
    customFields: crossCustomFields?.document || [],
    dataIndex: ['header', 'scmPurchaseOrderCustomFields'],
    type: 'detail',
    componentsProps: new Map([
      [
        FieldType.appendix, // 附件类型的组件的参数(跨租户)
        {
          type: 'collaborative',
          collaborativeParams: { customerId: crossCustomFields?.customerId },
        },
      ],
    ]),
  });
  /**
   * 跨租户的采购订单物料行的自定义字段
   */
  const crossMaterialCustomFields = injectCustomFieldColumns({
    columns: renderDetailPurchaseColumn, // 原本的列
    customFields: crossCustomFields?.material || [], // 自定义字段信息
    dataIndex: 'scmPurchaseOrderItemCustomFields', // 自定义字段
    objectCode: OBJECT_OF_CODE.purchaseOrderItem, // 采购订单物料行的code（是跨租户配置的自定义字段）
    type: 'detail', // 使用类型
    componentsProps: new Map([
      [
        FieldType.appendix, // 附件类型的组件的参数(跨租户)
        {
          type: 'collaborative',
          collaborativeParams: { customerId: crossCustomFields?.customerId },
        },
      ],
    ]),
  });
  /**
   * 销售订单物料行的自定义字段
   */
  const materialCustomFields = injectCustomFieldColumns({
    columns: relColumnDetail, // 原本的列
    customFields: customFields?.material, // 自定义字段信息
    objectCode: OBJECT_OF_CODE.salesOrderItem, // 从对象code
    type: 'detail', // 使用类型
  });
  const baseInfo = _.compact([
    {
      title: '基本信息',
      items: detailBaseInfo,
    },
    injectCustomFieldInfos({
      customFields: customFields?.document,
      type: 'detail',
      dataIndex: ['header', 'customFields'],
    }),
    detail?.header?.origin === appEnum.Sales.OriginType.collaborate && {
      title: '协同采购信息',
      items: [
        ...renderDetailPurchaseInfo(detail?.header?.customer?.id!),
        // 注入主对象自定义字段列
        ...crossDocumentCustomFields.items,
      ],
    },
    {
      title: '',
      items: [
        // 协同采购信息 - 明细
        {
          label: '',
          isFullLine: true,
          dataIndex: 'scmPurchaseRequestItemList',
          render: () => {
            return (
              detail?.header?.origin === appEnum.Sales.OriginType.collaborate && (
                <div
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  <BlTable
                    columns={crossMaterialCustomFields}
                    scroll={{ x: 1200, y: 800 }}
                    dataSource={detail?.header?.scmPurchaseRequestItemList}
                    style={{ width: '100%' }}
                    rowKey={(record) => record}
                  />
                </div>
              )
            );
          },
        },
      ],
    },
    {
      title: '订单明细',
      items: [
        {
          label: '',
          dataIndex: 'table',
          render: () => {
            return (
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                }}
              >
                <BlTable
                  columns={materialCustomFields}
                  scroll={{ x: 1200, y: 800 }}
                  dataSource={detail?.items}
                  style={{ width: '100%' }}
                  rowKey={(record) => record?.id}
                />
              </div>
            );
          },
        },
      ],
    },
    {
      title: '工单信息',
      items: [
        {
          label: '',
          dataIndex: 'workTable',
          render: () => {
            return (
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                }}
              >
                <BlTable
                  columns={WorkOrderDetailMainColumn}
                  scroll={{ x: 1200, y: 800 }}
                  dataSource={detail?.header?.workOrderList ?? []}
                  expandable={{ expandedRowRender }}
                  style={{ width: '100%' }}
                  rowKey={(record) => record?.code}
                />
              </div>
            );
          },
        },
      ],
    },
    {
      title: '发货信息',
      items: [
        {
          label: '',
          dataIndex: ['header', 'shipmentNoteList'],
          render: () => {
            return (
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                }}
              >
                <BlTable
                  columns={invoiceDetailsMainColumn}
                  scroll={{ x: 1200, y: 800 }}
                  dataSource={detail?.header?.shipmentNoteList}
                  expandable={{ expandedRowRender: invoiceExpandedRowRender }}
                  style={{ width: '100%' }}
                  rowKey={(record) => record?.id!}
                />
              </div>
            );
          },
        },
      ],
    },
  ]);

  return (
    <DetailLayout
      userAuth={getAuthFromLocalStorage()}
      title="订单详情"
      info={baseInfo}
      dataSource={detail}
      baseMenu={baseMenu}
      loading={loading}
    />
  );
};
