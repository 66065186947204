import React, { useCallback, useEffect, useState } from 'react';
import { DataFormLayoutForModal, DataFormLayout } from 'layout';
import { Checkbox, Form, Input, message, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  fetchLocationGetById,
  fetchLocationCreate,
  fetchLocationUpdate,
} from 'src/api/ytt/resource-domain/areaConfig';
import { numberAlphabetSpecialSymbols } from 'src/utils/formValidators';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { NumberRulesStandardHook } from 'src/components';
import { ObjectCode } from 'src/dict/common';

interface ActionProps {
  visible: Boolean;
  id: number;
  type: string;
  parentLevel?: any;
  onCancel?: () => void;
  refreshTable?: () => void;
}
const AreaAction = (props: ActionProps) => {
  const { visible, onCancel, type, id, refreshTable, parentLevel } = props;
  const [modalForm] = Form.useForm();
  const [keepOn, setKeepOn] = useState<boolean>(false);
  const [refreshMarker, setRefreshMarker] = useState<number>();

  const getData = async (_id: number) => {
    try {
      const { data } = await fetchLocationGetById({ id: _id });

      if (data) {
        let chooseValue: { value: any; label: any }[] = [];

        if (data?.manager?.id) {
          chooseValue = [
            {
              value: data?.manager?.id,
              label: data?.manager?.name,
            },
          ];
        }

        modalForm.setFieldsValue({
          ...data,
          parentId: { value: data.parentId, label: data.parentName },
          manager: chooseValue,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (type === 'edit' && id) {
      getData(id);
    }
    if (type === 'create' && parentLevel) {
      modalForm.setFieldsValue({
        parentId: { label: parentLevel.name, value: parentLevel.id },
        level: parentLevel.level + 1,
      });
    }
  }, [id]);

  const baseInfo = useCallback(() => {
    const info = {
      title: '',
      column: 1,
      items: [
        ...NumberRulesStandardHook({
          label: '区域编号',
          form: modalForm,
          edit: type === 'edit',
          objectCode: ObjectCode.Location,
          fieldCode: 'code',
          refreshMarker,
          rules: [{ max: 100, message: '不超过100个字符' }, numberAlphabetSpecialSymbols],
        }),
        {
          label: '区域名称',
          name: 'name',
          rules: [
            { required: true, message: '请输入区域名称' },
            { max: 100, message: '不超过100字符' },
          ],
          render: () => <Input placeholder="请输入区域名称" />,
        },
        {
          label: '上级区域',
          name: 'parentId',
          rules: [{ required: true, message: '请选择上级区域' }],
          render: () => <Select placeholder="请选择上级区域" disabled labelInValue />,
        },
        {
          label: '区域层级',
          name: 'level',
          rules: [{ required: true, message: '请输入区域层级' }],
          render: () => <Input placeholder="请输入区域层级" disabled />,
        },
        {
          label: '负责人',
          name: 'manager',
          render: () => (
            <UserOrDepartmentSelectWithDialog
              placeholder="请选择"
              isMultiple={false}
              isSelectUser
            />
          ),
        },
        {
          label: '标识码',
          name: 'identifier',
          render: () => <Input placeholder="请输入标识码" />,
          rules: [{ max: 100, message: '不超过100个字符' }],
        },
        {
          label: '备注',
          name: 'remark',
          rules: [{ max: 1000, message: '不超过1000字符' }],
          render: () => <TextArea placeholder="请输入备注" showCount maxLength={1000} />,
        },
      ],
    };

    if (type === 'edit') {
      info.items.push({
        label: '编辑原因',
        name: 'reason',
        rules: [{ max: 1000, message: '不超过1000字符' }],
        render: () => <TextArea placeholder="请输入编辑原因" showCount maxLength={1000} />,
      });
    }
    return info;
  }, [type, refreshMarker]);

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();
      const requestData = value;

      if (requestData.parentId) {
        requestData.parentId = requestData.parentId.value;
      }
      if (value.manager) {
        value.managerId = value.manager?.map((_manager: any) => _manager.value).join(',');
        delete value.manager;
      }
      if (type === 'edit') {
        requestData.reason = value.reason;
        requestData.id = id;
        await fetchLocationUpdate(requestData);
      } else {
        await fetchLocationCreate(requestData);
      }

      message.success(type === 'create' ? '创建成功！' : '编辑成功！');
      if (type === 'create' && keepOn) {
        modalForm.resetFields();
        setRefreshMarker(Math.random());
        modalForm.setFieldsValue({
          parentId: { label: parentLevel.name, value: parentLevel.id },
          level: parentLevel.level + 1,
        });
        return;
      }
      onCancel && onCancel();
      refreshTable?.();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      width={800}
      onClose={onCancel}
      content={
        <DataFormLayout
          form={modalForm}
          title={type === 'create' ? '新建子区域' : '编辑区域'}
          extra={
            type === 'create' && (
              <Checkbox
                onChange={() => {
                  setKeepOn(!keepOn);
                }}
                defaultChecked={keepOn}
              >
                连续新建
              </Checkbox>
            )
          }
          info={[baseInfo()]}
          onCancel={onCancel}
          onFinish={handleFinish}
        />
      }
    />
  );
};

export default AreaAction;
