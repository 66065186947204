import React, { useContext, useEffect, useState } from 'react';
import { Alert, Form } from 'antd';
import styles from '../styles.module.scss';
import { BlIcon } from 'src/components';
import UseLayout from '../useLayout';
import { BIND_PHONE, REPLACE_PHONE, BIND_EXISTING_PHONE, UserInfoContext } from '../constant';
import MessageAuth from '../password/messageAuth';
import SmsCodeBaseInfo from '../SmsCodeBaseInfo';

export default function BindPhone() {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const [bindPhoneTag, setBindPhoneTag] = useState<boolean>(false); // 区别绑定手机和更换手机
  const [replacePhoneTag, setReplaceBindPhoneTag] = useState<boolean>(false);

  const [phone, setPhone] = useState<string>('');
  const { dataSource } = useContext(UserInfoContext);
  const [timing, setTiming] = useState(false); // 正在获取验证码

  useEffect(() => {
    setPhone(dataSource?.phone);
    setBindPhoneTag(Boolean(dataSource?.phone));
  }, [dataSource]);

  // 绑定手机和更换手机
  const unBindingContent = () => {
    return (
      <div className={styles?.bindPhone}>
        {!bindPhoneTag && !replacePhoneTag && (
          <Alert
            message="您尚未绑定手机号！绑定后可用于手机号直接登录、找回密码等，保障您的账号更加安全！"
            type="warning"
            showIcon
            closable
            style={{ marginBottom: 48 }}
          />
        )}
        <SmsCodeBaseInfo
          timing={timing}
          handleTiming={(time: boolean) => {
            setTiming(time);
          }}
          showType={BIND_PHONE}
          form={form}
          bindPhoneTag={bindPhoneTag}
          replacePhoneTag={replacePhoneTag}
          handleDisable={() => {}}
        />
      </div>
    );
  };

  // 已绑定
  const bindingContent = () => {
    return (
      <div className={styles?.bindingPhone}>
        <BlIcon type="iconmianxingchenggong" style={{ marginRight: 8, color: '#52C41A' }} />
        {dataSource?.bound && <span style={{ color: '#8c8c8c' }}>已绑定手机</span>}
        {!dataSource?.bound && <span style={{ color: '#8c8c8c' }}>该手机号暂未绑定</span>}
        <span className={styles?.phoneNumber}>{phone}</span>
        <div
          className={styles?.changePhone}
          onClick={() => {
            setVisible(true);
          }}
        >
          {dataSource?.bound ? '更换手机' : '绑定手机'}
        </div>
      </div>
    );
  };

  return (
    <>
      <UseLayout
        title="绑定手机"
        renderContent={bindPhoneTag ? bindingContent() : unBindingContent()}
      />
      <MessageAuth
        showType={dataSource?.bound ? REPLACE_PHONE : BIND_EXISTING_PHONE}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        phone={phone}
        handleBindTag={(bindPhoneTag: boolean) => {
          setBindPhoneTag(bindPhoneTag);
        }}
        handleReplaceBindTag={(replacePhoneTag: boolean) => {
          setReplaceBindPhoneTag(replacePhoneTag);
        }}
        bindPhoneTag={bindPhoneTag}
      />
    </>
  );
}
