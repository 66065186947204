/**
 * 工序业务配置下的追溯关系
 */

export enum TraceabilityType {
  /** 单件顺序生产自动更新 */
  SingleAutomatic = 0,
  /** 批量生产手动更新 */
  batchManually = 1,
  /** 批量生产自动更新 */
  batchAutomatic = 2,
  /** 按配比更新 */
  matchingUpdates = 3,
  /** 无追溯关系 */
  noTraceRelationship = 4,
}
