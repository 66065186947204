/**
 * 资源参数-固定参数，资源参数-选择参数控件属性
 */
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Form, Input, Select, Checkbox, InputNumber, message, Radio } from 'antd';
import type { FormInstance } from 'antd';

import lookup from 'src/dict';
import {
  BizType,
  ControlType,
  ObjectEntitySource,
  ParamGetType,
  ResourceBusinessType,
} from 'src/dict/sop';
import {
  paramGetType as paramGetTypeMapping,
  objectEntitySource as objectEntitySourceMapping,
  resourceType as resourceTypeMapping,
} from 'src/dict/sop/mappings';

import type { StepDetailData, LocalControlItem } from '../../types';
import {
  ControlEditorContext,
  CONTROL_TITLE_LENGTH,
  INPUT_DEFAULT_PLACEHOLDER,
} from '../../constants';
import { getNameFactory, resetUnusedItems, titleRules } from './share';
import { SearchSelect } from 'src/components';
import { setFieldValueofCondition } from '../../share/setFormWithCondition';
import { DetailResourceParamsType } from 'src/page/resource/definitions/index.d';

import {
  fetchResourcesDetail,
  FetchResourcesDetailResponse,
} from 'src/api/ytt/resource-domain/resource';
import {
  fetchInstrumentDetail,
  FetchInstrumentDetailResponse,
} from 'src/api/ytt/resource-domain/energyMeter';
interface Props {
  form: FormInstance<StepDetailData>;
  localKey: string;
}
// 设备、能源仪表最大选择数
const MAX_OBJECT_ENTITY_SELECTED = 20;
// 描述信息最大值
// const MAX_REMARK_LEN = 1000;
// 提示文字最大长度
// const MAX_PROMPT_LEN = 20;

export const ResourceInputProperties: FC<Props> = ({ form, localKey }) => {
  const { forceUpdate, bizType } = useContext(ControlEditorContext);
  const controlList = form.getFieldValue('controls');
  const dataIndex = controlList.findIndex((item: LocalControlItem) => item._localKey === localKey);
  const getName = getNameFactory(dataIndex);
  const { bizField, controlType } = controlList[dataIndex];

  const { paramGetType, resourceType, resourceSource, resourceIds } = bizField;

  const [paramList, setParamList] = useState<any[]>([]);

  // 手动选择控件，对象实例来源
  const objectEntitySourceList = useMemo(() => {
    const objectEntitySourceList = objectEntitySourceMapping.slice();

    const objectEntitySourceFactory = objectEntitySourceList.map((item: any) => {
      switch (item.value) {
        case ObjectEntitySource.product:
          if (bizType === BizType.production && resourceType === ResourceBusinessType.equipment) {
            item.isHidden = false;
          } else {
            item.isHidden = true;
          }
          break;
        case ObjectEntitySource.maintenance:
          item.isHidden = bizType !== BizType.maintenance;
          break;
        case ObjectEntitySource.repairTask:
          item.isHidden = bizType !== BizType.repair;
          break;
        case ObjectEntitySource.manual:
          item.isHidden = false;
          break;
        default:
          item.isHidden = false;
          break;
      }
      return item;
    });

    return objectEntitySourceFactory
      .filter((item) => !item.isHidden)
      .map((item) => _.omit(item, 'isHidden'));
  }, [bizType, resourceType, objectEntitySourceMapping]);

  const fetchData = async (id: number, type: ResourceBusinessType, paramGetType: ParamGetType) => {
    try {
      let res: FetchResourcesDetailResponse | FetchInstrumentDetailResponse;

      if (type === ResourceBusinessType.equipment) {
        res = await fetchResourcesDetail({ id });
      } else {
        res = await fetchInstrumentDetail({ id });
      }
      const resourceParamsList =
        paramGetType === ParamGetType.api
          ? res?.data?.resourceParamsList?.filter((item) => item.paramsReadApi)
          : res?.data?.resourceParamsList;

      const formatList =
        resourceParamsList?.map((item: DetailResourceParamsType) => {
          const { id, paramsName } = item;

          return {
            value: id!,
            label: paramsName!,
          };
        }) || [];

      setParamList(formatList);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (controlType === ControlType.resourceWithFixedParams) {
      setFieldValueofCondition(['bizField.resourceType'], 'bizField.resourceIds', undefined, {
        form,
        getName,
      });
      setFieldValueofCondition(['bizField.resourceType'], 'bizField.paramIds', undefined, {
        form,
        getName,
      });
    } else if (controlType === ControlType.resourceWithSelectedParams) {
      setFieldValueofCondition(['bizField.resourceType'], 'bizField.resourceIds', undefined, {
        form,
        getName,
      });
      setFieldValueofCondition(
        ['bizField.resourceType'],
        'bizField.resourceSource',
        ObjectEntitySource.manual,
        {
          form,
          getName,
        },
      );
    }
  }, [resourceType, localKey, controlType]);

  useEffect(() => {
    setFieldValueofCondition(
      ['bizField.paramGetType', 'bizField.resourceIds'],
      'bizField.paramIds',
      undefined,
      {
        form,
        getName,
      },
    );
  }, [localKey, paramGetType, resourceIds]);

  useEffect(() => {
    if (controlType === ControlType.resourceWithSelectedParams) {
      setFieldValueofCondition(['bizField.resourceSource'], 'bizField.resourceIds', undefined, {
        form,
        getName,
      });
    }
  }, [localKey, controlType, resourceSource]);

  useEffect(() => {
    // wendy TODO form.getFieldValue(getName('bizField.resourceIds')) 才是最新数据
    // 资源参数-固定参数,初始化时未选择参数

    if (
      controlType === ControlType.resourceWithFixedParams &&
      _.get(form.getFieldValue(getName('bizField.resourceIds')), ['0', 'value'])
    ) {
      fetchData(resourceIds[0].value, resourceType, paramGetType);
    } else {
      setParamList([]);
    }
  }, [localKey, resourceType, paramGetType, resourceIds]);

  if (_.isEmpty(controlList)) {
    return null;
  }

  if (dataIndex === -1) {
    return null;
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={(changeValues) => {
        //  处理form 更新时，searchSelect options突然无值，可能跟focus时获取options数据有关
        if (
          controlType === ControlType.resourceWithSelectedParams &&
          changeValues.controls?.some((item: any) => {
            return _.isArray(_.get(item, 'bizField.resourceIds'));
          })
        ) {
          return;
        }

        forceUpdate();
        resetUnusedItems(form, controlType, dataIndex, forceUpdate);
      }}
    >
      <Form.Item label="标题" name={getName('name')} rules={titleRules}>
        <Input max={CONTROL_TITLE_LENGTH} placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>
      {/* 业务相关表单字段 */}
      <Form.Item
        label="参数值获取方式"
        name={getName('bizField.paramGetType')}
        rules={[{ required: true }]}
      >
        <Radio.Group options={paramGetTypeMapping} />
      </Form.Item>
      <Form.Item
        label="资源业务对象"
        name={getName('bizField.resourceType')}
        rules={[{ required: true, message: '请选择资源业务对象' }]}
      >
        <Select options={resourceTypeMapping} placeholder={'请选择'} />
      </Form.Item>
      {controlType === ControlType.resourceWithFixedParams && (
        <>
          <Form.Item
            label="对象实例"
            name={getName('bizField.resourceIds')}
            rules={[{ required: true, message: '请选择对象实例' }]}
            getValueFromEvent={(value) => {
              const val = !value ? [] : [value];

              return val;
            }}
          >
            <SearchSelect
              fetchType="resource"
              placeholder="请选择"
              labelInValue
              params={{
                type: resourceType,
                status: 0,
              }}
            />
          </Form.Item>
          <Form.Item
            label="资源参数"
            name={getName('bizField.paramIds')}
            rules={[{ required: true, message: '请选择资源参数' }]}
          >
            <Select<any>
              options={paramList}
              mode="multiple"
              labelInValue
              placeholder={'请选择'}
              listHeight={186}
              filterOption={(input, option) =>
                _.toString(option?.label)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        </>
      )}
      {/* 手动选择资源及参数相关表单 */}
      {controlType === ControlType.resourceWithSelectedParams && (
        <>
          <Form.Item
            label="对象实例来源"
            name={getName('bizField.resourceSource')}
            rules={[{ required: true, message: '请选择对象实例来源' }]}
          >
            <Select options={objectEntitySourceList} placeholder={'请选择'} />
          </Form.Item>
          {resourceSource === ObjectEntitySource.manual && (
            <Form.Item
              label="对象实例可选范围"
              name={getName('bizField.resourceIds')}
              rules={[
                { required: true, message: '请选择对象实例可选范围' },
                {
                  type: 'array',
                  max: MAX_OBJECT_ENTITY_SELECTED,
                  message: `最多添加${MAX_OBJECT_ENTITY_SELECTED}个${lookup(
                    'sop.resourceType',
                    _.toNumber(resourceType),
                  )}`,
                },
              ]}
              getValueFromEvent={(value) => {
                if (value.length > MAX_OBJECT_ENTITY_SELECTED) {
                  message.error(
                    `最多添加${MAX_OBJECT_ENTITY_SELECTED}个${lookup(
                      'sop.resourceType',
                      _.toNumber(resourceType),
                    )}`,
                  );
                  return value.splice(0, MAX_OBJECT_ENTITY_SELECTED);
                }
                return value;
              }}
            >
              <SearchSelect
                fetchType="resource"
                placeholder="请选择"
                labelInValue
                mode="multiple"
                params={{
                  type: resourceType,
                  status: 0,
                }}
              />
            </Form.Item>
          )}
        </>
      )}
      <Form.Item
        label="参数值最大记录次数"
        name={getName('bizField.maxCount')}
        validateFirst
        rules={[
          { required: true, message: '请输入参数值最大记录次数' },
          // {
          //   validator: validatorMax(20, true, false),
          // },
        ]}
      >
        <InputNumber
          min={1}
          max={20}
          precision={0}
          placeholder={INPUT_DEFAULT_PLACEHOLDER}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item label="校验" style={{ marginBottom: 0 }}>
        <Form.Item
          name={getName('bizField.required')}
          valuePropName="checked"
          style={{ marginBottom: 0 }}
        >
          <Checkbox>必填</Checkbox>
        </Form.Item>
      </Form.Item>
      {/* 需求变更：不展示 描述信息和提示文字 */}
      {/* <Form.Item label="描述信息" name={getName('remark')}>
        <Input.TextArea
          placeholder={INPUT_DEFAULT_PLACEHOLDER}
          maxLength={MAX_REMARK_LEN}
          showCount
        />
      </Form.Item>
      <Form.Item
        label="提示文字"
        name={getName('inputPrompt')}
        rules={[{ max: MAX_PROMPT_LEN, message: `请限制在${MAX_PROMPT_LEN}个字符` }]}
      >
        <Input allowClear placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item> */}
    </Form>
  );
};

export default ResourceInputProperties;
