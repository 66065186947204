import React, { useCallback } from 'react';
import { Form, Table, Select, FormInstance, ConfigProvider } from 'antd';
import { BlIcon } from 'components';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { OptionsType } from 'src/page/organization/components/dataPrivilege/types';
import { dataPrivilegeOperations } from 'src/dict/user/mappings';
import { Mappings } from 'src/dict/types';
import { DATA_PRIVILEGE_OPERATION_ENUM } from 'src/dict/user';
import {
  getFieldValueItem,
  hasValueByOperation,
} from 'src/page/organization/components/dataPrivilege/utils';

type DataPrivilegeTableProps = {
  dataSource: any;
  fields: any[];
  form: FormInstance<any>;
  disabled?: boolean;
  remove: (index: number) => void;
  listFieldName: string;
  fieldOptions: OptionsType[];
};

const DataPrivilegeTable: React.FC<DataPrivilegeTableProps> = (props) => {
  const { dataSource, fields, disabled, remove, form, listFieldName, fieldOptions } = props;

  const checkList = useCallback(
    (rule, val) => {
      const list = form.getFieldValue(listFieldName);

      if (_.filter(list, { field: val }).length > 1) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    [dataSource, form],
  );

  const columns: ColumnsType<FormListFieldData> = [
    {
      title: '',
      dataIndex: 'remove',
      width: 60,
      render: (text: unknown, record) => {
        return (
          <BlIcon
            onClick={() => remove(record.name)}
            type={'iconlieshanchu'}
            style={{
              color: '#f5222d',
              fontSize: 20,
            }}
          />
        );
      },
    },
    {
      title: '字段名称',
      dataIndex: 'field',
      width: 200,
      render: (text: unknown, record: any) => {
        return (
          <Form.Item
            rules={[
              {
                required: true,
                message: '字段名称不能为空',
              },
              {
                validator: checkList,
                message: '一个字段只可以配置一次条件',
              },
            ]}
            name={[record.name, 'field']}
            style={{ marginBottom: 0 }}
          >
            <Select
              labelInValue
              placeholder={'请选择'}
              disabled={disabled}
              options={fieldOptions}
              onChange={(value, option) => {
                form.resetFields([
                  [listFieldName, record.name, 'operation'],
                  [listFieldName, record.name, 'value'],
                ]);
                form.setFields([
                  {
                    name: [listFieldName, record.name, 'option'],
                    value: value ? option : null,
                  },
                ]);
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: '筛选条件',
      dataIndex: 'operation',
      width: 200,
      render: (text: unknown, record) => {
        return (
          <Form.Item dependencies={[[listFieldName, record.name, 'field']]} noStyle>
            {() => {
              const fieldOption = form.getFieldValue([listFieldName, record.name, 'option']);
              const operationList = _.filter(dataPrivilegeOperations, (opt) =>
                fieldOption?.dataPermissionTypes?.includes(opt.value),
              ) as Mappings<string, DATA_PRIVILEGE_OPERATION_ENUM>;

              return (
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: '筛选条件不能为空',
                    },
                  ]}
                  name={[record.name, 'operation']}
                  style={{ marginBottom: 0 }}
                >
                  <Select
                    disabled={disabled || !fieldOption}
                    placeholder={'请选择'}
                    options={operationList}
                    onChange={() => {
                      form.resetFields([[listFieldName, record.name, 'value']]);
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '筛选值',
      dataIndex: 'value',
      width: 200,
      render: (text: any, record) => {
        return (
          <Form.Item
            dependencies={[
              [listFieldName, record.name, 'field'],
              [listFieldName, record.name, 'operation'],
            ]}
            noStyle
          >
            {() => {
              const fieldOption = form.getFieldValue([listFieldName, record.name, 'option']);
              const operation = form.getFieldValue([listFieldName, record.name, 'operation']);

              return (
                <Form.Item
                  rules={[
                    {
                      required: !_.isNil(operation) && hasValueByOperation(operation),
                      message: '筛选值不能为空',
                    },
                  ]}
                  name={[record.name, 'value']}
                  style={{ marginBottom: 0 }}
                >
                  {getFieldValueItem(fieldOption, operation)}
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
  ];

  const renderEmpty = () => {
    return <div>暂无数据</div>;
  };

  return (
    <ConfigProvider renderEmpty={renderEmpty}>
      <Table dataSource={fields} size={'small'} columns={columns} pagination={false} />
    </ConfigProvider>
  );
};

export default DataPrivilegeTable;
