/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../../request';

type UserRequestRestArgs = RequestFunctionRestArgs<typeof request>;

// Request: 目前 React Hooks 功能有用到
export type Request<
  TRequestData,
  TRequestConfig extends RequestConfig,
  TRequestResult,
> = (TRequestConfig['requestDataOptional'] extends true
  ? (requestData?: TRequestData, ...args: RequestFunctionRestArgs<typeof request>) => TRequestResult
  : (
      requestData: TRequestData,
      ...args: RequestFunctionRestArgs<typeof request>
    ) => TRequestResult) & {
  requestConfig: TRequestConfig;
};

const mockUrl_0_0_0_0 = 'http://yapi.blacklake.tech/mock/519' as any;
const devUrl_0_0_0_0 = '' as any;
const prodUrl_0_0_0_0 = '' as any;
const dataKey_0_0_0_0 = undefined as any;

/**
 * 接口 [新增电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86312) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_add`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureAddRequest {
  /**
   * 签名人配置列表
   */
  digitalSignatureConfigCOList: {
    /**
     * 是否选择所有用户 0-false 1-true
     */
    allUserFlag: number;
    /**
     * 是否选择当前登录人 0-false 1-true
     */
    currentUserFlag: number;
    /**
     * 业务id，id存在时为编辑
     */
    id?: number;
    /**
     * 角色ids
     */
    roleIds?: number[];
    /**
     * 角色列表
     */
    roles?: {
      /**
       * 角色编号
       */
      code?: string;
      /**
       * 角色描述
       */
      desc?: string;
      /**
       * 业务id
       */
      id?: number;
      /**
       * 角色名称
       */
      name?: string;
    }[];
    /**
     * 序号
     */
    seq: number;
    /**
     * 用户ids
     */
    userIds?: number[];
    /**
     * 用户列表
     */
    users?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    }[];
  }[];
  /**
   * 功能点code
   */
  functionCode: string;
  /**
   * 电子签名名称
   */
  name: string;
  /**
   * 关联对象code
   */
  relatedObjectCode: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 签名规则 0并行，1串行
   */
  ruleType: number;
}

/**
 * 接口 [新增电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86312) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_add`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureAddResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [新增电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86312) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_add`
 * @更新时间 `2021-12-14 19:24:00`
 */
type FetchDigitalSignatureAddRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_add',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [新增电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86312) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_add`
 * @更新时间 `2021-12-14 19:24:00`
 */
const fetchDigitalSignatureAddRequestConfig: FetchDigitalSignatureAddRequestConfig = {
  mockUrl: mockUrl_0_0_0_0,
  devUrl: devUrl_0_0_0_0,
  prodUrl: prodUrl_0_0_0_0,
  path: '/user/domain/web/v1/digital_signature/_add',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDigitalSignatureAdd',
};

/**
 * 接口 [新增电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86312) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_add`
 * @更新时间 `2021-12-14 19:24:00`
 */
export const fetchDigitalSignatureAdd = (
  requestData: FetchDigitalSignatureAddRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureAddResponse>(
    prepare(fetchDigitalSignatureAddRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureAdd.requestConfig = fetchDigitalSignatureAddRequestConfig;

/**
 * 接口 [批量启用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86318) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_activate`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureBulkActivateRequest {
  /**
   * 业务id List
   */
  ids: number[];
}

/**
 * 接口 [批量启用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86318) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_activate`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureBulkActivateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量启用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86318) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_activate`
 * @更新时间 `2021-12-14 19:24:00`
 */
type FetchDigitalSignatureBulkActivateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_bulk_activate',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量启用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86318) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_activate`
 * @更新时间 `2021-12-14 19:24:00`
 */
const fetchDigitalSignatureBulkActivateRequestConfig: FetchDigitalSignatureBulkActivateRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_0,
    devUrl: devUrl_0_0_0_0,
    prodUrl: prodUrl_0_0_0_0,
    path: '/user/domain/web/v1/digital_signature/_bulk_activate',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchDigitalSignatureBulkActivate',
  };

/**
 * 接口 [批量启用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86318) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_activate`
 * @更新时间 `2021-12-14 19:24:00`
 */
export const fetchDigitalSignatureBulkActivate = (
  requestData: FetchDigitalSignatureBulkActivateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureBulkActivateResponse>(
    prepare(fetchDigitalSignatureBulkActivateRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureBulkActivate.requestConfig = fetchDigitalSignatureBulkActivateRequestConfig;

/**
 * 接口 [批量停用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86324) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_deactivate`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureBulkDeactivateRequest {
  /**
   * 业务id List
   */
  ids: number[];
}

/**
 * 接口 [批量停用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86324) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_deactivate`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureBulkDeactivateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量停用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86324) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_deactivate`
 * @更新时间 `2021-12-14 19:24:00`
 */
type FetchDigitalSignatureBulkDeactivateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_bulk_deactivate',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量停用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86324) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_deactivate`
 * @更新时间 `2021-12-14 19:24:00`
 */
const fetchDigitalSignatureBulkDeactivateRequestConfig: FetchDigitalSignatureBulkDeactivateRequestConfig =
  {
    mockUrl: mockUrl_0_0_0_0,
    devUrl: devUrl_0_0_0_0,
    prodUrl: prodUrl_0_0_0_0,
    path: '/user/domain/web/v1/digital_signature/_bulk_deactivate',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_0_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchDigitalSignatureBulkDeactivate',
  };

/**
 * 接口 [批量停用电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86324) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_deactivate`
 * @更新时间 `2021-12-14 19:24:00`
 */
export const fetchDigitalSignatureBulkDeactivate = (
  requestData: FetchDigitalSignatureBulkDeactivateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureBulkDeactivateResponse>(
    prepare(fetchDigitalSignatureBulkDeactivateRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureBulkDeactivate.requestConfig =
  fetchDigitalSignatureBulkDeactivateRequestConfig;

/**
 * 接口 [批量删除电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86330) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_delete`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureBulkDeleteRequest {
  /**
   * 业务id List
   */
  ids: number[];
}

/**
 * 接口 [批量删除电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86330) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_delete`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureBulkDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量删除电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86330) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_delete`
 * @更新时间 `2021-12-14 19:24:00`
 */
type FetchDigitalSignatureBulkDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_bulk_delete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量删除电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86330) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_delete`
 * @更新时间 `2021-12-14 19:24:00`
 */
const fetchDigitalSignatureBulkDeleteRequestConfig: FetchDigitalSignatureBulkDeleteRequestConfig = {
  mockUrl: mockUrl_0_0_0_0,
  devUrl: devUrl_0_0_0_0,
  prodUrl: prodUrl_0_0_0_0,
  path: '/user/domain/web/v1/digital_signature/_bulk_delete',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDigitalSignatureBulkDelete',
};

/**
 * 接口 [批量删除电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86330) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_bulk_delete`
 * @更新时间 `2021-12-14 19:24:00`
 */
export const fetchDigitalSignatureBulkDelete = (
  requestData: FetchDigitalSignatureBulkDeleteRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureBulkDeleteResponse>(
    prepare(fetchDigitalSignatureBulkDeleteRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureBulkDelete.requestConfig = fetchDigitalSignatureBulkDeleteRequestConfig;

/**
 * 接口 [查看电子签名详情↗](http://yapi.blacklake.tech/project/519/interface/api/86336) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_detail`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureDetailRequest {
  /**
   * 业务id
   */
  id: number;
}

/**
 * 接口 [查看电子签名详情↗](http://yapi.blacklake.tech/project/519/interface/api/86336) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_detail`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureDetailResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    activeStatus?: {
      /**
       * 枚举 code
       */
      code?: number;
      /**
       * 枚举 message
       */
      message?: string;
    };
    /**
     * 创建时间
     */
    createdAt?: string;
    creator?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    /**
     * 签名人配置列表
     */
    digitalSignatureConfigVOList?: {
      /**
       * 是否选择所有用户 0-false 1-true
       */
      allUserFlag: number;
      /**
       * 是否选择当前登录人 0-false 1-true
       */
      currentUserFlag: number;
      /**
       * 业务id，id存在时为编辑
       */
      id?: number;
      /**
       * 角色ids
       */
      roleIds?: number[];
      /**
       * 角色列表
       */
      roles?: {
        /**
         * 角色编号
         */
        code?: string;
        /**
         * 角色描述
         */
        desc?: string;
        /**
         * 业务id
         */
        id?: number;
        /**
         * 角色名称
         */
        name?: string;
      }[];
      /**
       * 序号
       */
      seq: number;
      /**
       * 用户ids
       */
      userIds?: number[];
      /**
       * 用户列表
       */
      users?: {
        avatarUrl?: string;
        email?: string;
        id?: number;
        name?: string;
        orgId?: number;
        phone?: string;
        username?: string;
      }[];
    }[];
    /**
     * 功能点名称
     */
    functionName?: string;
    /**
     * 业务id
     */
    id?: number;
    /**
     * 电子签名名称(支持模糊搜索)
     */
    name?: string;
    operator?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    /**
     * 关联对象名称
     */
    relatedObjectName?: string;
    /**
     * 备注
     */
    remark?: string;
    /**
     * 签名规则 0并行，1串行
     */
    ruleType?: number;
    /**
     * 更新时间
     */
    updatedAt?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [查看电子签名详情↗](http://yapi.blacklake.tech/project/519/interface/api/86336) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_detail`
 * @更新时间 `2021-12-14 19:24:00`
 */
type FetchDigitalSignatureDetailRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_detail',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [查看电子签名详情↗](http://yapi.blacklake.tech/project/519/interface/api/86336) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_detail`
 * @更新时间 `2021-12-14 19:24:00`
 */
const fetchDigitalSignatureDetailRequestConfig: FetchDigitalSignatureDetailRequestConfig = {
  mockUrl: mockUrl_0_0_0_0,
  devUrl: devUrl_0_0_0_0,
  prodUrl: prodUrl_0_0_0_0,
  path: '/user/domain/web/v1/digital_signature/_detail',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDigitalSignatureDetail',
};

/**
 * 接口 [查看电子签名详情↗](http://yapi.blacklake.tech/project/519/interface/api/86336) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_detail`
 * @更新时间 `2021-12-14 19:24:00`
 */
export const fetchDigitalSignatureDetail = (
  requestData: FetchDigitalSignatureDetailRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureDetailResponse>(
    prepare(fetchDigitalSignatureDetailRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureDetail.requestConfig = fetchDigitalSignatureDetailRequestConfig;

/**
 * 接口 [查看电子签名列表↗](http://yapi.blacklake.tech/project/519/interface/api/86342) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_page_list`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignaturePageListRequest {
  /**
   * 启用状态，1-启用中（默认） 2-停用中 0-已删除
   */
  activeStatus?: number;
  /**
   * 功能点code
   */
  functionCode?: string;
  /**
   * 电子签名名称(支持模糊搜索)
   */
  name?: string;
  /**
   * 请求页
   */
  page?: number;
  /**
   * 关联对象code
   */
  relatedObjectCode?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 每页大小
   */
  size?: number;
  /**
   * 排序 列表顺序表示排序顺序
   */
  sorter?: {
    /**
     * 排序字段
     */
    field?: string;
    /**
     * 排序规律 默认 asc，asc 升序 desc 降序
     */
    order?: string;
  }[];
}

/**
 * 接口 [查看电子签名列表↗](http://yapi.blacklake.tech/project/519/interface/api/86342) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_page_list`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignaturePageListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      activeStatus?: {
        /**
         * 枚举 code
         */
        code?: number;
        /**
         * 枚举 message
         */
        message?: string;
      };
      /**
       * 功能点名称
       */
      functionName?: string;
      /**
       * 业务id
       */
      id?: number;
      /**
       * 电子签名名称(支持模糊搜索)
       */
      name?: string;
      /**
       * 关联对象名称
       */
      relatedObjectName?: string;
      /**
       * 备注
       */
      remark?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [查看电子签名列表↗](http://yapi.blacklake.tech/project/519/interface/api/86342) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_page_list`
 * @更新时间 `2021-12-14 19:24:00`
 */
type FetchDigitalSignaturePageListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_page_list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [查看电子签名列表↗](http://yapi.blacklake.tech/project/519/interface/api/86342) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_page_list`
 * @更新时间 `2021-12-14 19:24:00`
 */
const fetchDigitalSignaturePageListRequestConfig: FetchDigitalSignaturePageListRequestConfig = {
  mockUrl: mockUrl_0_0_0_0,
  devUrl: devUrl_0_0_0_0,
  prodUrl: prodUrl_0_0_0_0,
  path: '/user/domain/web/v1/digital_signature/_page_list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDigitalSignaturePageList',
};

/**
 * 接口 [查看电子签名列表↗](http://yapi.blacklake.tech/project/519/interface/api/86342) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_page_list`
 * @更新时间 `2021-12-14 19:24:00`
 */
export const fetchDigitalSignaturePageList = (
  requestData: FetchDigitalSignaturePageListRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignaturePageListResponse>(
    prepare(fetchDigitalSignaturePageListRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignaturePageList.requestConfig = fetchDigitalSignaturePageListRequestConfig;

/**
 * 接口 [编辑电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86348) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_update`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureUpdateRequest {
  /**
   * 签名人配置列表
   */
  digitalSignatureConfigCOList: {
    /**
     * 是否选择所有用户 0-false 1-true
     */
    allUserFlag: number;
    /**
     * 是否选择当前登录人 0-false 1-true
     */
    currentUserFlag: number;
    /**
     * 业务id，id存在时为编辑
     */
    id?: number;
    /**
     * 角色ids
     */
    roleIds?: number[];
    /**
     * 角色列表
     */
    roles?: {
      /**
       * 角色编号
       */
      code?: string;
      /**
       * 角色描述
       */
      desc?: string;
      /**
       * 业务id
       */
      id?: number;
      /**
       * 角色名称
       */
      name?: string;
    }[];
    /**
     * 序号
     */
    seq: number;
    /**
     * 用户ids
     */
    userIds?: number[];
    /**
     * 用户列表
     */
    users?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    }[];
  }[];
  /**
   * 业务id
   */
  id: number;
  /**
   * 电子签名名称
   */
  name: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 编辑原因
   */
  updateReason?: string;
}

/**
 * 接口 [编辑电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86348) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_update`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureUpdateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [编辑电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86348) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_update`
 * @更新时间 `2021-12-14 19:24:00`
 */
type FetchDigitalSignatureUpdateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_update',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [编辑电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86348) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_update`
 * @更新时间 `2021-12-14 19:24:00`
 */
const fetchDigitalSignatureUpdateRequestConfig: FetchDigitalSignatureUpdateRequestConfig = {
  mockUrl: mockUrl_0_0_0_0,
  devUrl: devUrl_0_0_0_0,
  prodUrl: prodUrl_0_0_0_0,
  path: '/user/domain/web/v1/digital_signature/_update',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDigitalSignatureUpdate',
};

/**
 * 接口 [编辑电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/86348) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_update`
 * @更新时间 `2021-12-14 19:24:00`
 */
export const fetchDigitalSignatureUpdate = (
  requestData: FetchDigitalSignatureUpdateRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureUpdateResponse>(
    prepare(fetchDigitalSignatureUpdateRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureUpdate.requestConfig = fetchDigitalSignatureUpdateRequestConfig;

/**
 * 接口 [查看电子签名配置人列表（用于开始签名）↗](http://yapi.blacklake.tech/project/519/interface/api/86624) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_config_list`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureConfigListRequest {
  /**
   * 功能点 code
   */
  functionCode: string;
  /**
   * 签名会话 id（由电子签名服务生成）
   */
  signatureSessionId: number;
}

/**
 * 接口 [查看电子签名配置人列表（用于开始签名）↗](http://yapi.blacklake.tech/project/519/interface/api/86624) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_config_list`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureConfigListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 电子签名配置人列表（包含签名情况）
     */
    digitalSignatureSignConfigVOList?: {
      /**
       * 是否选择所有用户 0-false 1-true
       */
      allUserFlag?: number;
      /**
       * 是否选择当前登录人 0-false 1-true
       */
      currentUserFlag?: number;
      /**
       * 角色列表
       */
      roles?: {
        /**
         * 角色编号
         */
        code?: string;
        /**
         * 角色描述
         */
        desc?: string;
        /**
         * 业务id
         */
        id?: number;
        /**
         * 角色名称
         */
        name?: string;
      }[];
      /**
       * 序号
       */
      seq: number;
      signer?: {
        avatarUrl?: string;
        email?: string;
        id?: number;
        name?: string;
        orgId?: number;
        phone?: string;
        username?: string;
      };
      /**
       * 用户列表
       */
      users?: {
        avatarUrl?: string;
        email?: string;
        id?: number;
        name?: string;
        orgId?: number;
        phone?: string;
        username?: string;
      }[];
      /**
       * 校验状态 true-通过 false-不通过
       */
      verifyStatus?: boolean;
    }[];
    /**
     * 功能点Code
     */
    functionCode?: string;
    /**
     * 签名规则 0并行，1串行
     */
    ruleType?: number;
    /**
     * 签名会话 id（由电子签名服务生成）
     */
    signatureSessionId?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [查看电子签名配置人列表（用于开始签名）↗](http://yapi.blacklake.tech/project/519/interface/api/86624) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_config_list`
 * @更新时间 `2021-12-14 19:24:00`
 */
type FetchDigitalSignatureConfigListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_config_list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [查看电子签名配置人列表（用于开始签名）↗](http://yapi.blacklake.tech/project/519/interface/api/86624) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_config_list`
 * @更新时间 `2021-12-14 19:24:00`
 */
const fetchDigitalSignatureConfigListRequestConfig: FetchDigitalSignatureConfigListRequestConfig = {
  mockUrl: mockUrl_0_0_0_0,
  devUrl: devUrl_0_0_0_0,
  prodUrl: prodUrl_0_0_0_0,
  path: '/user/domain/web/v1/digital_signature/_config_list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDigitalSignatureConfigList',
};

/**
 * 接口 [查看电子签名配置人列表（用于开始签名）↗](http://yapi.blacklake.tech/project/519/interface/api/86624) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_config_list`
 * @更新时间 `2021-12-14 19:24:00`
 */
export const fetchDigitalSignatureConfigList = (
  requestData: FetchDigitalSignatureConfigListRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureConfigListResponse>(
    prepare(fetchDigitalSignatureConfigListRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureConfigList.requestConfig = fetchDigitalSignatureConfigListRequestConfig;

/**
 * 接口 [签名↗](http://yapi.blacklake.tech/project/519/interface/api/86630) 的 **请求类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_sign`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureSignRequest {
  /**
   * 功能点 code
   */
  functionCode: string;
  /**
   * 密码
   */
  password?: string;
  /**
   * 序号
   */
  seq: number;
  /**
   * 签名会话 id（由电子签名服务生成）
   */
  signatureSessionId: number;
  /**
   * 账号
   */
  username?: string;
}

/**
 * 接口 [签名↗](http://yapi.blacklake.tech/project/519/interface/api/86630) 的 **返回类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_sign`
 * @更新时间 `2021-12-14 19:24:00`
 */
export interface FetchDigitalSignatureSignResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 序号
     */
    seq?: number;
    /**
     * 签名会话 id（由电子签名服务生成）
     */
    signatureSessionId?: number;
    signer?: {
      avatarUrl?: string;
      email?: string;
      id?: number;
      name?: string;
      orgId?: number;
      phone?: string;
      username?: string;
    };
    /**
     * 校验状态 true-通过 false-不通过
     */
    verifyStatus?: boolean;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [签名↗](http://yapi.blacklake.tech/project/519/interface/api/86630) 的 **请求配置的类型**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_sign`
 * @更新时间 `2021-12-14 19:24:00`
 */
type FetchDigitalSignatureSignRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/519',
    '',
    '',
    '/user/domain/web/v1/digital_signature/_sign',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [签名↗](http://yapi.blacklake.tech/project/519/interface/api/86630) 的 **请求配置**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_sign`
 * @更新时间 `2021-12-14 19:24:00`
 */
const fetchDigitalSignatureSignRequestConfig: FetchDigitalSignatureSignRequestConfig = {
  mockUrl: mockUrl_0_0_0_0,
  devUrl: devUrl_0_0_0_0,
  prodUrl: prodUrl_0_0_0_0,
  path: '/user/domain/web/v1/digital_signature/_sign',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchDigitalSignatureSign',
};

/**
 * 接口 [签名↗](http://yapi.blacklake.tech/project/519/interface/api/86630) 的 **请求函数**
 *
 * @分类 [web电子签名↗](http://yapi.blacklake.tech/project/519/interface/api/cat_11968)
 * @标签 `web电子签名`
 * @请求头 `POST /user/domain/web/v1/digital_signature/_sign`
 * @更新时间 `2021-12-14 19:24:00`
 */
export const fetchDigitalSignatureSign = (
  requestData: FetchDigitalSignatureSignRequest,
  ...args: UserRequestRestArgs
) => {
  return request<FetchDigitalSignatureSignResponse>(
    prepare(fetchDigitalSignatureSignRequestConfig, requestData),
    ...args,
  );
};

fetchDigitalSignatureSign.requestConfig = fetchDigitalSignatureSignRequestConfig;

/* prettier-ignore-end */
