import { LabeledValue } from 'antd/lib/select';

import {
  FetchPickOrderSplitByInputMaterialListResponse,
  FetchPickOrderSplitByProcessListResponse,
  FetchPickOrderSplitByWorkOrderListResponse,
  FetchPickOrderListResponse,
  FetchPickOrderGetResponse,
  FetchPickOrderGetCreateGeneralPickOrderListResponse,
} from 'src/api/ytt/med-domain/store-requisition';
import { FetchTransferOrderBulkCreateRequest } from 'src/api/ytt/inventory-domain/transferOrder';
import { ListItemType, GetElementType } from 'src/types';
import { MaterialEntity } from 'src/entity';
import { TransferOrderBusinessType } from 'src/dict/storeRequisition';
import { SearchSeletcModalValue } from 'src/components/searchSelectModal';

/** 列表接口返回值的单行数据 */
type StoreRequisitionListItemProps = ListItemType<FetchPickOrderListResponse>;
/** 分单接口response */
type SplitResponse =
  | FetchPickOrderSplitByInputMaterialListResponse
  | FetchPickOrderSplitByProcessListResponse
  | FetchPickOrderSplitByWorkOrderListResponse;
type SplitResultListType = SplitResponse['data'];
/** 分单接口返回值单行数据 */
type SplitResultItemType = ListItemType<SplitResponse>;

type StoreRequisitionDetailType = NonNullable<FetchPickOrderGetResponse['data']>;
type OrderLineType = GetElementType<SplitResultItemType['orderLines']>;
/** 新建表格单行数据 */
type StoreRequisitionTableRowDataType = Omit<
  OrderLineType,
  'sourceWarehouseId' | 'targetWarehouseId' | 'lineNo'
> & {
  code: string;
  rowSpan: number;
  batchNo?: LabeledValue;
  sourceWarehouseId?: SearchSeletcModalValue;
  targetWarehouseId?: SearchSeletcModalValue;
  pickOrderDetailId?: number;
  line: number;
};
type TransferOrderCreateItemType = GetElementType<
  NonNullable<FetchTransferOrderBulkCreateRequest['list']>
>;

type TransferOrderTableRowType = {
  code?: string;
  sourceWarehouse?: SearchSeletcModalValue;
  targetWarehouse?: SearchSeletcModalValue;
  bizType: TransferOrderBusinessType;
  remark: string;
  line: number;
  material: MaterialEntity.MaterialEntity;
  requestAmount: number;
  receiveAmount: number;
  issueAmount: number;
  unitId: number;
  lineRemark: string;
  batchNo?: LabeledValue;
  supplier?: LabeledValue;
  pickOrderCode: string;
  pickOrderLine: number;
  pickOrderId: number;
  pickOrderDetailId: number;
  rowSpan: number;
  requirementTime: number;
  mergeCredential: string;
};

type GeneralPickOrderDataType = ListItemType<FetchPickOrderGetCreateGeneralPickOrderListResponse>;
type GeneralPickOrderLineType = GetElementType<GeneralPickOrderDataType['pickOrderLines']>;

type GeneralTableRowDataType = Omit<
  GeneralPickOrderLineType,
  'sourceWarehouseId' | 'targetWarehouseId' | 'lineNo'
> & {
  code: string;
  rowSpan: number;
  line?: number;
  batchNo?: LabeledValue;
  sourceWarehouseId?: SearchSeletcModalValue;
  targetWarehouseId?: SearchSeletcModalValue;
  unit: LabeledValue;
  pickOrderDetailId?: number;
};

const fieldLabels = {
  storeRequisitionCode: '领料申请编号',
  lineNumber: '行号',
  materialCode: '物料编号',
  materialName: '物料名称',
  materialCategory: '物料分类',
  materialProperty: '物料属性',
  requireCount: '申请数',
  unit: '单位',
  dispatchWarehouse: '发料仓库',
  acceptWarehouse: '收料仓库',
  requireTime: '需求时间',
  processNumber: '投料工序号',
  processName: '投料工序名称',
  workCenter: '工作中心',
  supplier: '指定供应商',
  batchNumber: '指定批次号',
  productionOrderCode: '生产工单编号',
  productionDepartment: '生产部门',
  mainOutputBatchNumber: '工单主产出批次号',
  status: '状态',
  allocateNumber: '发起调拨数',
  issuedCount: '发料数',
  receivedCount: '收料数',
  relatedAllocateOrder: '相关调拨单',
  lineRemark: '行备注',
  remark: '备注',
  creator: '创建人',
  createdAt: '创建时间',
  operator: '更新人',
  updatedAt: '更新时间',
  modifyReason: '修改原因',
  seq: '项次',
  alternativeMaterial: '替代物料',
  specification: '物料规格',
  type: '领料申请类型',
};

const transferFieldLabels = {
  needMergeOrder: '是否合单',
  mergePrinciple: '合单原则',
  transferCodeNeedNumberRule: '调拨单编号启用编号规则',
  transferOrderCode: '调拨单编号',
  sourceWarehouse: '发出仓库',
  targetWarehouse: '接收仓库',
  businessType: '业务类型',
  remark: '备注',
  line: '行号',
  material: '物料',
  requestAmount: '申请数',
  unit: '单位',
  lineRemark: '行备注',
  batchNo: '指定批次号',
  supplier: '指定供应商',
  storeRequisitionCode: '领料申请编号',
  storeRequisitionLine: '领料申请行号',
};

enum StoreRequisitionOperateMode {
  create,
  edit,
  view,
  hide,
}

const StoreRequisitionOperateModeTitleMap = {
  [StoreRequisitionOperateMode.create]: '新建领料单',
  [StoreRequisitionOperateMode.edit]: '编辑领料单',
  [StoreRequisitionOperateMode.view]: '领料单详情',
  [StoreRequisitionOperateMode.hide]: undefined,
};

const NOTHING_TO_TRANSFER = '暂无可调拨物料，请重新选择';

export {
  fieldLabels,
  transferFieldLabels,
  StoreRequisitionOperateMode,
  StoreRequisitionOperateModeTitleMap,
  NOTHING_TO_TRANSFER,
};
export type {
  StoreRequisitionListItemProps,
  SplitResultListType,
  SplitResultItemType,
  StoreRequisitionTableRowDataType,
  StoreRequisitionDetailType,
  TransferOrderTableRowType,
  TransferOrderCreateItemType,
  GeneralPickOrderDataType,
  GeneralPickOrderLineType,
  GeneralTableRowDataType,
};
