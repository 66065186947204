import axios from 'axios';
import { request } from 'utils';

const SERVICE_PREFIX = '/user/domain';
const BASE_PREFIX_WEB = `${SERVICE_PREFIX}/web/v1/`;

const generateCancelToken = () => {
  const { CancelToken } = axios;
  const source = CancelToken.source();

  return source;
};

export const getRoleList = (data?: any) => {
  const { token, cancel } = generateCancelToken();
  const roles = request.post(
    `${BASE_PREFIX_WEB}role/_list`,
    {
      loading: true,
      cancelToken: token,
    },
    { ...(data ?? {}) },
  );
  const _cancel = (msg?: string) => {
    cancel(msg);
  };

  return { roles, cancel: _cancel };
};

export const getRoleById = (id: number) => {
  const { token, cancel } = generateCancelToken();
  const role = request.post(
    `${BASE_PREFIX_WEB}role/_get`,
    {
      loading: true,
      cancelToken: token,
    },
    { id },
  );
  const _cancel = (msg?: string) => {
    cancel(msg);
  };

  return { role, cancel: _cancel };
};

export const deleteRoles = (roleIds: number[]) => {
  const { token, cancel } = generateCancelToken();
  const role = request.post(
    `${BASE_PREFIX_WEB}role/_delete`,
    {
      loading: false,
      cancelToken: token,
    },
    { roleIds },
  );
  const _cancel = (msg?: string) => {
    cancel(msg);
  };

  return { role, cancel: _cancel };
};

// 被替换   /privilege/domain
export const getAllObjectList = () => {
  const { token, cancel } = generateCancelToken();
  const privilegeTree = request.post(`${BASE_PREFIX_WEB}field_permission/_objectList`, {
    cancelToken: token,
  });
  const _cancel = (msg?: string) => {
    cancel(msg);
  };

  return { privilegeTree, cancel: _cancel };
};

// 被替换   /privilege/domain
export const getFieldPermissionByObject = (bizObjectCode: string) => {
  const { token, cancel } = generateCancelToken();
  const fields = request.post(
    `${BASE_PREFIX_WEB}field_permission/_getDefault`,
    {
      cancelToken: token,
    },
    {
      bizObjectCode,
    },
  );
  const _cancel = (msg?: string) => {
    cancel(msg);
  };

  return { fields, cancel: _cancel };
};

// 被替换   /privilege/domain
export const getFieldPermissionByRole = (data: any) => {
  const { token, cancel } = generateCancelToken();
  const fields = request.post(
    `${BASE_PREFIX_WEB}field_permission/_getByRole`,
    {
      cancelToken: token,
    },
    data,
  );
  const _cancel = (msg?: string) => {
    cancel(msg);
  };

  return { fields, cancel: _cancel };
};

// 被替换   /privilege/domain
export const fieldPermissionUpdate = (data: any) => {
  const { token, cancel } = generateCancelToken();
  const fields = request.post(
    `${BASE_PREFIX_WEB}field_permission/_update`,
    {
      cancelToken: token,
    },
    data,
  );
  const _cancel = (msg?: string) => {
    cancel(msg);
  };

  return { fields, cancel: _cancel };
};
