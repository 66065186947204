export const ENGINEER_PATH = '/productionPlanning/engineering/workCenter';
// history.push(`/productionPlanning/engineering/workCenter/detail/${record.id}`);

/**
 * 创建工作中心
 * @returns string
 */
export const toCreateWorkCenter = () => `${ENGINEER_PATH}/create`;

/**
 * 编辑工作中心
 * @param id
 * @returns string
 */
export const toEditWorkCenter = (id: number) => `${ENGINEER_PATH}/edit/${id}`;
/**
 * 复制工作中心
 * @param id
 * @returns string
 */
export const toCopyWorkCenter = (id: number) => `${ENGINEER_PATH}/copy/${id}`;
/**
 * 查看工作中心
 * @param id
 * @returns string
 */
export const toDetailWorkCenter = (id: number) => `${ENGINEER_PATH}/detail/${id}`;
