import { Button, Space } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import {
  fetchImportExcelTaskCustomObjectImportProgress,
  fetchImportExcelTaskImportProgress,
} from 'src/api/ytt/import';
import { AvatarDisplay } from 'src/components/avatar/show';
import lookup from 'src/dict';
import { DetailLayoutForDrawer, RecordListLayout } from 'src/layout';
import ImportLogDetail from './ImportLogDetail';
import { useLocation } from 'react-router-dom';
import { qs } from 'utils';

export type TableRowType = {
  fileName: string;
  fileId?: number;
  failFileName?: string;
  failFileId?: number;
  avatarUrl?: string;
  userName: string;
  userId?: number;
  updatedAt: number;
  status: number;
  resultMsg: string;
};

const ImportLog: React.FC = () => {
  const { search } = useLocation();
  const { applicationName, businessType, isCustomObject } = qs.parse(search.slice(1));
  const [detailVisible, setDetailVisible] = useState(false);
  const [detailItem, setDetailItem] = useState<TableRowType>();

  const dataColumns = [
    {
      title: '文件名',
      dataIndex: 'fileName',
      width: 200,
    },
    {
      title: '操作人',
      dataIndex: 'userName',
      width: 120,
      render: (userName: any, record: TableRowType) => {
        const { avatarUrl } = record;

        return (
          <AvatarDisplay
            name={userName}
            avatarUrl={avatarUrl}
            id={record?.userId || ''}
            key={record?.userId}
            isShowTag
            isUser
          />
        );
      },
    },
    {
      title: '操作时间',
      dataIndex: 'updatedAt',
      width: 160,
      render: (text: number) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''),
    },
    {
      title: '操作结果',
      dataIndex: 'status',
      width: 120,
      render: (text: number) => lookup('objectImport.importStatus', text),
    },
    {
      title: '操作详情',
      dataIndex: 'resultMsg',
      width: 200,
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 100,
      render: (text: any, record: TableRowType) => {
        return (
          <Space>
            <Button
              type={'link'}
              onClick={() => {
                setDetailVisible(true);
                setDetailItem(record);
              }}
            >
              查看详情
            </Button>
          </Space>
        );
      },
    },
  ];

  /**
   * 处理查询条件
   * @param params 原查询条件 默认的字段有 quickSearch(快速查询内容), page(当前页), size(分页大小), sorter(排序)
   */
  const formatDataToQuery = (params: any) => {
    delete params.sorter;
    delete params.quickSearch;
    params.applicationName = applicationName;
    params.businessType = businessType;
    params.sorter = [
      {
        field: 'updatedAt',
        order: 'desc',
      },
    ];

    return params;
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        columns={dataColumns}
        useQuickFilter={false}
        formatDataToQuery={formatDataToQuery}
        requestFn={
          isCustomObject
            ? fetchImportExcelTaskCustomObjectImportProgress
            : fetchImportExcelTaskImportProgress
        }
      />
      <DetailLayoutForDrawer
        visible={detailVisible}
        width={680}
        content={<ImportLogDetail dataSource={detailItem} />}
        onClose={() => setDetailVisible(false)}
      />
    </>
  );
};

export default ImportLog;
