import { useState } from 'react';
import _ from 'lodash';
import { RecordListLayout } from 'layout';
import { fetchInventoryChangeLogListPage } from 'src/api/ytt/inventory-domain/inventoryChangeLog';
import { formatDateForRender } from 'src/utils/formatters/dateTime';
import { SearchSelect, TagList } from 'src/components';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { inventoryChangeAction } from 'src/dict/bound/mappings';
import { gcObject, fieldTypeList } from 'utils';
import { InventoryChangeLogProps } from './types';
import { DatePicker, Tag } from 'antd';
import moment from 'moment';
import { replaceSign } from 'src/utils/constants';

const List = () => {
  const [refreshMarker, setRefreshMarker] = useState<number>(Date.now()); // 刷新页面
  const refresh = () => setRefreshMarker(Date.now());
  const { RangePicker } = DatePicker;

  // const disabledDate = (current: any) => {
  //   return current <= moment().subtract(30, 'day') || current > moment().endOf('day');
  // };

  // 设置日期最多选择一个月
  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState();
  const [value, setValue] = useState();
  const disabledDate = (current: any) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;

    return tooEarly || tooLate;
  };

  const onOpenChange = (open: any) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  const dataColumns = [
    {
      title: '物料名称',
      dataIndex: ['material', 'name'],
      width: 150,
      fixed: true,
    },
    {
      title: '物料编号',
      dataIndex: ['material', 'code'],
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
      key: 'materialIds',
      fixed: true,
      renderItem: <SearchSelect placeholder={'请选择物料'} fetchType="materialCodeName" />,
    },
    {
      title: '物料属性',
      dataIndex: 'materialAttr',
      width: 150,
      fixed: true,
      render: (value: any, record: any) => {
        if (_.isEmpty(record.attrList)) return replaceSign;
        return (
          <TagList
            dataSource={_.map(record.attrList, ({ name, attributeItem }) => ({
              label: `${name}:${attributeItem.content}`,
              value: attributeItem.content,
            }))}
          />
        );
      },
    },
    {
      title: '物料规格',
      dataIndex: 'specification',
      width: 150,
      fixed: true,
    },
    {
      title: '仓库',
      dataIndex: 'warehouse',
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
      key: 'warehouseIds',
      renderItem: <SearchSelect placeholder={'请选择仓库'} fetchType="warehouse" />,
    },
    {
      title: '仓位',
      dataIndex: 'storageLocation',
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
      key: 'storageLocationIds',
      renderItem: <SearchSelect placeholder={'请选择仓位'} fetchType="storageLocation" />,
    },
    {
      title: '批次号',
      dataIndex: 'batchNo',
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
      key: 'batchNos',
    },
    {
      title: '标识码',
      dataIndex: 'qrCode',
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
      key: 'qrCodes',
    },
    {
      title: '变动方向',
      dataIndex: ['amount', 'direction'],
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      key: 'direction',
      selectProps: {
        options: [
          {
            label: '+',
            value: true,
          },
          {
            label: '-',
            value: false,
          },
        ],
      },
      render: (text: boolean) => {
        return text ? '+' : '-';
      },
    },
    {
      title: '变动数量',
      dataIndex: 'amount',
      width: 150,
      sorter: true,
      render: (text: string, record: any) => {
        return record?.amount?.amount?.amount;
      },
    },
    {
      title: '单位',
      dataIndex: ['amount', 'amount', 'unit', 'name'],
      width: 150,
    },
    {
      title: '结余数量',
      dataIndex: 'afterAmount',
      width: 150,
      sorter: true,
      render: (text: string, record: any) => {
        return record?.amount?.afterAmount?.amount;
      },
    },
    {
      title: '单位',
      dataIndex: ['amount', 'afterAmount', 'unit', 'name'],
      width: 150,
    },
    {
      title: '辅助数量1',
      dataIndex: 'auxAmounts1',
      width: 150,
      // sorter: true,
      defaultColConfig: {
        display: false,
      },
      render: (text: string, record: any) => {
        return record?.amount?.auxAmounts?.[0]?.amount;
      },
    },
    {
      title: '单位',
      dataIndex: ['amount', 'auxAmounts', 0, 'unit', 'name'],
      width: 150,
      defaultColConfig: {
        display: false,
      },
    },
    {
      title: '辅助数量2',
      dataIndex: 'auxAmounts2',
      width: 150,
      // sorter: true,
      defaultColConfig: {
        display: false,
      },
      render: (text: string, record: any) => {
        return record?.amount?.auxAmounts?.[1]?.amount;
      },
    },
    {
      title: '单位',
      dataIndex: ['amount', 'auxAmounts', 1, 'unit', 'name'],
      width: 150,
      defaultColConfig: {
        display: false,
      },
    },
    {
      title: '操作时间',
      dataIndex: 'createdAt',
      width: 150,
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      key: 'operateDate',
      render: formatDateForRender,
      renderItem: (
        <RangePicker
          defaultValue={[moment().subtract(6, 'days'), moment()]}
          style={{ width: '100%' }}
          value={hackValue || value}
          disabledDate={disabledDate}
          onCalendarChange={(val) => setDates(val)}
          onChange={(val) => setValue(val)}
          onOpenChange={onOpenChange}
        />
      ),
    },
    {
      title: '操作类型',
      dataIndex: ['action', 'desc'],
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      key: 'action',
      selectProps: {
        options: inventoryChangeAction,
      },
    },
    {
      title: '操作人',
      dataIndex: 'operator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      key: 'operatorId',
      renderItem: (
        <UserOrDepartmentSelectWithDialog
          placeholder={'请选择操作人'}
          isMultiple={false}
          isSelectUser
        />
      ),
    },
    {
      title: '业务状态',
      dataIndex: ['bizStatus', 'message'],
      width: 150,
    },
    {
      title: '仓储状态',
      dataIndex: ['storageStatus', 'message'],
      width: 150,
    },
    {
      title: '质量状态',
      dataIndex: ['qcStatus', 'message'],
      width: 150,
    },
    {
      title: '供应商',
      dataIndex: ['bizAttr', 'supplierName'],
      width: 150,
    },
    {
      title: '供应商批次',
      dataIndex: ['bizAttr', 'supplierBatch'],
      width: 150,
    },
    {
      title: '客户',
      dataIndex: ['bizAttr', 'customer'],
      width: 150,
    },
    {
      title: '入厂日期',
      dataIndex: ['bizAttr', 'inboundTime'],
      width: 150,
      render: formatDateForRender,
    },
    {
      title: '生产日期',
      dataIndex: ['bizAttr', 'productTime'],
      width: 150,
      render: formatDateForRender,
    },
    {
      title: '有效期至',
      dataIndex: ['bizAttr', 'validityPeriod'],
      width: 150,
      render: formatDateForRender,
    },
    {
      title: '',
      dataIndex: 'action',
      width: 60,
      fixed: 'right',
      render: () => ' ',
    },
  ];

  const filterList = dataColumns
    .filter((i) => i.isFilter)
    .map((column) => {
      const filter = {
        label: column.title,
        name: column.key,
        type: column.type,
        renderItem: column.renderItem,
      } as any;

      if (column.selectProps) {
        filter.selectProps = column.selectProps;
      }

      return filter;
    });

  const formatDataToQuery = (params: any) => {
    const {
      batchNos,
      materialIds,
      qrCodes,
      operateDate,
      operatorId,
      storageLocationIds,
      warehouseIds,
      ...rest
    } = params || {};
    const [dateStart, dateEnd] = operateDate || [];

    const options = {
      batchNos: batchNos && [batchNos],
      materialIds: materialIds && [materialIds.value],
      qrCodes: qrCodes && [qrCodes],
      operatorId: operatorId?.[0]?.value,
      storageLocationIds: storageLocationIds?.value && [storageLocationIds.value],
      warehouseIds: warehouseIds?.value && [warehouseIds.value],
      dateStart: dateStart?.startOf('day').valueOf(),
      dateEnd: dateEnd?.endOf('day').valueOf(),
      ...rest,
    };

    return gcObject.filterEmptyProperty(options);
  };

  return (
    <>
      <RecordListLayout<InventoryChangeLogProps>
        columns={dataColumns}
        filterList={filterList}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={_.identity}
        formatDataToFormDisplay={_.identity}
        configcacheKey={'inventoryChangeLog'}
        requestFn={(params) => fetchInventoryChangeLogListPage(params, { legacy: false })}
        refreshMarker={refreshMarker}
        placeholder={'输入标识码，回车快速搜索'}
      />
    </>
  );
};

export default List;
