import { useState } from 'react';
import _ from 'lodash';
import { RecordListLayout, FilterItem } from 'layout';
import {
  FetchSupplierListRegMaterialRequest,
  fetchSupplierListRegMaterial,
} from 'src/api/ytt/supply-domain/supplier';
import { fieldTypeList, gcObject, gcTime } from 'src/utils';
import { replaceSign } from 'src/utils/constants';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { lookup } from 'src/dict';
import { CRUD, UsageStatus } from 'src/dict/common';
import { usageStatus as usageStatusMappings } from 'src/dict/common/mappings';
import { crud as registerStatus, fieldLabels } from 'src/dict/supplier/mappings';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { renderUsageStatus } from 'src/page/share/renderUsageStatus';
import type { MaterialListItemProps, SupplierListItemProps } from '../interface';
import { SupplierOperateMode } from 'src/dict/supplier';
import OperateSupplierDialog from '../OperateSupplierDialog';
import SupplierDetail from '../SupplierDetail';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { fetchMaterialList } from 'src/api/ytt/material-domain/Web_MaterialBaseInfo';
import { SearchSelect, BlIcon, TagList } from 'src/components';
import authDict, { hasAuth } from 'src/utils/auth';
import { TextToolTip } from '@blacklake-web/component';

// param是由antd的table发出，经由RecordListLayout封装过一次的参数，描述的是表格的变化(分页，排序，筛选，搜索信息)
// 将这些信息转换成后台分页查询列表数据的参数格式，即requestFn的传入参数应有的格式
const formatDataToQuery = (param: any): FetchSupplierListRegMaterialRequest => {
  const { createdAt, updatedAt, creator, operator, regStatus, owner, materialCode, ...rest } =
    param;
  const [createdAtFrom, createdAtTo] = gcTime.formatRangeUnixToInt(createdAt);
  const [updatedAtFrom, updatedAtTo] = gcTime.formatRangeUnixToInt(updatedAt);

  const options = {
    createdAtFrom,
    createdAtTo,
    updatedAtFrom,
    updatedAtTo,
    creatorIds: creator?.length ? _.map(creator, 'value') : null,
    operatorIds: operator?.length ? _.map(operator, 'value') : null,
    regStatus,
    ownerIds: owner?.length ? _.map(owner, 'value') : null,
    materialIds: materialCode?.length ? _.map(materialCode, 'value') : null,
    ...rest,
  };

  return gcObject.filterEmptyProperty(options);
};

// 把param转换成{key: value}[], 显示在RecordListInfo的tag里
const formatDataToDisplay = (param: any) => {
  return param;
};

const formatDataToFormDisplay = (param: any) => {
  const { createdAt, updateAt, ...rest } = param;
  const result = {
    ...rest,
    createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
    updateAt: gcTime.formatRangeTimeToMoment(updateAt || []),
  };

  return gcObject.filterEmptyProperty(result);
};

export default function SupplierListMaterial() {
  // 选中行的key，用以批量删除
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // 刷新用的时间戳，在refresh方法里用
  const [refreshMarker, setRefreshMarker] = useState<number>(Date.now());
  const [mode, setMode] = useState<SupplierOperateMode>(SupplierOperateMode.hide);

  const [currentData, setCurrentData] = useState<SupplierListItemProps>();

  const columns = [
    {
      title: fieldLabels.supplierCode,
      dataIndex: 'code',
      width: 150,
      fixed: 'left',
      sorter: true,
      isFilter: true,
      type: fieldTypeList.text,
      render: (text: string, record: MaterialListItemProps, index: number, config: any) => {
        return hasAuth(authDict.supplier_detail) ? (
          <a
            onClick={() => {
              setCurrentData(record);
              setMode(SupplierOperateMode.view);
            }}
          >
            <TextToolTip text={text} width={config.contentWidth} />
          </a>
        ) : (
          <TextToolTip text={text} width={config.contentWidth} />
        );
      },
    },
    {
      title: fieldLabels.supplierName,
      dataIndex: 'name',
      width: 150,
      fixed: 'left',
      sorter: true,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: fieldLabels.supplierStatus,
      dataIndex: 'status',
      width: 120,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: usageStatusMappings,
        mode: 'multiple',
      },
      render: renderUsageStatus,
    },
    {
      title: fieldLabels.supplierOwner,
      dataIndex: 'owner',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      render: (_text: string, record: MaterialListItemProps) => {
        return record.owner && record.owner.name ? record.owner.name : replaceSign;
      },
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple={false} isSelectUser />,
    },
    {
      title: fieldLabels.supplierRegStatus,
      dataIndex: 'regStatus',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: registerStatus,
        mode: 'multiple',
      },
      render: (_text: string, record: MaterialListItemProps) => {
        return lookup('supplyRegStatus.crud', record.regStatus);
      },
    },
    {
      title: fieldLabels.regEnterprise,
      dataIndex: 'supplierOrg',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: MaterialListItemProps) => {
        return record.supplierOrg && record.supplierOrg.orgName
          ? record.supplierOrg.orgName
          : replaceSign;
      },
    },
    {
      title: fieldLabels.materialNumber,
      dataIndex: 'materialCode',
      width: 150,
      type: fieldTypeList.select,
      isFilter: true,
      selectProps: {
        options: fetchMaterialList,
        mode: 'multiple',
      },
      render: (_text: string, record: MaterialListItemProps) => {
        return record.materialCode ? record.materialCode : replaceSign;
      },
      renderItem: (
        <SearchSelect
          placeholder="请选择物料"
          fetchType={'materialCodeName'}
          mode={'multiple'}
          labelInValue
        />
      ),
    },
    {
      title: fieldLabels.materialName,
      dataIndex: 'materialName',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: MaterialListItemProps) => {
        return record.materialName ? record.materialName : replaceSign;
      },
    },
    {
      title: fieldLabels.materialProperties,
      dataIndex: 'materialAttr',
      width: 150,
      render: (text: { name: string; attributeItem: { content: string }; id: number }[]) => {
        if (_.isEmpty(text)) return replaceSign;

        return (
          <TagList
            dataSource={_.map(text, ({ name, attributeItem, id }) => ({
              label: `${name}:${attributeItem.content}`,
              value: id,
            }))}
          />
        );
      },
    },
    {
      title: fieldLabels.number,
      dataIndex: 'materialAmount',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: MaterialListItemProps) => {
        return record.materialAmount && record.materialAmount.amount
          ? record.materialAmount.amount
          : replaceSign;
      },
    },
    {
      title: fieldLabels.unit,
      dataIndex: 'materialUnit',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: MaterialListItemProps) => {
        return record.materialAmount && record.materialAmount.unitName
          ? record.materialAmount.unitName
          : replaceSign;
      },
    },
    {
      title: fieldLabels.registrationStatus,
      dataIndex: 'materialRegStatus',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: MaterialListItemProps) => {
        return lookup('supplyRegStatus.crud', record.materialRegStatus);
      },
    },
    {
      title: fieldLabels.supplierMaterial,
      dataIndex: 'supMaterialCode',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: MaterialListItemProps) => {
        return record.supMaterialCode ? record.supMaterialCode : replaceSign;
      },
    },
    {
      title: fieldLabels.supplierNumber,
      dataIndex: 'supMaterialAmount',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: MaterialListItemProps) => {
        return record.supMaterialAmount && record.supMaterialAmount.amount
          ? record.supMaterialAmount.amount
          : replaceSign;
      },
    },
    {
      title: fieldLabels.supplierUnit,
      dataIndex: 'supMaterialUnit',
      width: 150,
      type: fieldTypeList.text,
      render: (_text: string, record: MaterialListItemProps) => {
        return record.supMaterialAmount && record.supMaterialAmount.unitName
          ? record.supMaterialAmount.unitName
          : replaceSign;
      },
    },
    {
      title: fieldLabels.creator,
      dataIndex: 'creator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (_text: string, record: MaterialListItemProps) => {
        return record.creator && record.creator.id ? (
          <AvatarDisplay
            id={record.creator.id}
            name={record.creator.name!}
            avatarUrl={record.creator.avatarUrl}
            key={record.creator.id}
            isShowTag={false}
            isUser
          />
        ) : (
          replaceSign
        );
      },
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple={false} isSelectUser />,
    },
    {
      title: fieldLabels.createdAt,
      dataIndex: 'createdAt',
      width: 150,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: fieldLabels.operator,
      dataIndex: 'operator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (_text: string, record: MaterialListItemProps) => {
        return record.operator && record.operator.id ? (
          <AvatarDisplay
            id={record.operator.id}
            name={record.operator.name!}
            avatarUrl={record.operator.avatarUrl}
            key={record.operator.id}
            isShowTag={false}
            isUser
          />
        ) : (
          replaceSign
        );
      },
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple={false} isSelectUser />,
    },
    {
      title: fieldLabels.updatedAt,
      dataIndex: 'updatedAt',
      width: 150,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
  ];
  const getOperationList = (record: MaterialListItemProps) => {
    return [
      {
        title: lookup('crud', CRUD.view),
        auth: authDict.supplier_detail,
        onClick: () => {
          setCurrentData(record);
          setMode(SupplierOperateMode.view);
        },
      },
      {
        title: lookup('crud', CRUD.edit),
        auth: authDict.supplier_edit,
        onClick: () => {
          setCurrentData(record);
          setMode(SupplierOperateMode.edit);
        },
      },
      // {
      //   title: lookup('crud', CRUD.delete),
      //   onClick: () => {
      //     BLDelConfirm('是否删除当前物料', delForm, () => {
      //       handleFinish(id);
      //     });
      //   },
      // },
    ];
  };
  const filterList: FilterItem[] = columns
    .filter((i) => i.isFilter)
    .map((column) => {
      return _.pick(
        {
          ...column,
          label: column.title,
          name: column.dataIndex,
          type: column.type,
        },
        [
          'label',
          'name',
          'type',
          'rules',
          'renderItem',
          'selectProps',
          'inputProps',
          'datePickerProps',
          'dateFormat',
        ],
      ) as FilterItem;
    });

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  const refreshDetailStatus = () => {
    refresh();
    if (currentData) {
      setCurrentData({
        ...currentData,
        status: Number(!currentData.status) as UsageStatus,
      });
    }
  };

  const switchToEditMode = () => {
    setMode(SupplierOperateMode.edit);
  };

  return (
    <>
      <RecordListLayout<MaterialListItemProps>
        refreshMarker={refreshMarker}
        filterList={filterList}
        requestFn={(params) => fetchSupplierListRegMaterial(params, { legacy: true })}
        placeholder="请输入供应商编号、供应商名称、物料编号"
        rowKey="itemId"
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectedKeys) => {
          setSelectedRowKeys(selectedKeys);
        }}
        columns={columns}
        formatDataToDisplay={formatDataToDisplay}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        getOperationList={getOperationList}
      />
      {mode === SupplierOperateMode.create || mode === SupplierOperateMode.edit ? (
        <OperateSupplierDialog
          operateMode={mode}
          supplierData={currentData}
          onClose={(needListRefresh: boolean) => {
            setCurrentData(undefined);
            setMode(SupplierOperateMode.hide);
            if (needListRefresh) refresh();
          }}
        />
      ) : null}
      {mode === SupplierOperateMode.view && currentData ? (
        <SupplierDetail
          supplierData={currentData}
          onClose={() => {
            setMode(SupplierOperateMode.hide);
          }}
          refresh={refreshDetailStatus}
          goToEdit={switchToEditMode}
        />
      ) : null}
    </>
  );
}
