import { FC, useMemo } from 'react';
import { FormInstance, Form } from 'antd';
import { ControlType } from 'src/dict/sop';
import { NumberRulesStandardHook } from 'src/components';
import { textValidator2 } from 'src/utils/formValidators';
import Title, { TooltipContentProps } from './title';
import { LocalControlItem } from '../../types';
import TextInput from './textInput';
import NumericInput from './numericInput';
import TimeInput from './timeInput';
import UserInput from './userInput';
import StationInput from './stationInput';
import FileInput from './fileInput';
import SelectorInput from './selectorInput';
import FeedOrReportInput from './feedOrReportInput';
import ResourceInput from './resourceInput';
import { NEW_CONTROL_ID } from '../../constants';
import AssociatedDataInput from './associatedDataInput';
import AssociatedFieldInput from './associatedFieldInput';
import ProductInput from './productInput';
import NumberRulesInput from './numberRulesInput';
import QcTaskOfSOPInput from './qcTaskOfSOPInput';
import '../../style.scss';

interface Props {
  selectedControl: string | null;
  form: FormInstance;
  objectCode: string;
}

const PropertyEditor: FC<Props> = ({ selectedControl, form, objectCode }) => {
  const dataIndex = selectedControl
    ? form
        .getFieldValue('controls')
        ?.findIndex((item: LocalControlItem) => item._localKey === selectedControl) ?? -1
    : -1;
  const namePath = useMemo(() => ['controls', dataIndex], [dataIndex]);
  const data = form.getFieldValue('controls')[dataIndex];
  const isEdit = data ? 'id' in data && data.id !== NEW_CONTROL_ID : true;
  const [numberRuleItem] = NumberRulesStandardHook({
    label: '控件编号',
    form,
    edit: isEdit,
    disabledState: true,
    objectCode,
    fieldCode: 'code',
    namePath,
    checkboxLayout: 'vertical',
    rules: [{ max: 64, message: '字符数最大为64' }, { validator: textValidator2 }],
  });

  if (!selectedControl || dataIndex === -1) {
    return null;
  }

  let content = null;
  let tooltipContent: TooltipContentProps = {
    title: '',
  };

  switch (data.controlType) {
    case ControlType.single:
    case ControlType.multiLine:
      content = <TextInput form={form} localKey={selectedControl} />;
      break;
    case ControlType.numeric:
      content = <NumericInput form={form} localKey={selectedControl} />;
      break;
    case ControlType.time:
      content = <TimeInput form={form} localKey={selectedControl} />;
      break;
    case ControlType.user:
      content = <UserInput form={form} localKey={selectedControl} />;
      break;
    case ControlType.station:
      content = <StationInput form={form} localKey={selectedControl} />;
      break;
    case ControlType.file:
      content = <FileInput form={form} localKey={selectedControl} />;
      break;
    case ControlType.selector:
      content = <SelectorInput form={form} localKey={selectedControl} />;
      break;
    case ControlType.feed:
    case ControlType.report:
      content = <FeedOrReportInput form={form} localKey={selectedControl} />;
      break;
    case ControlType.associatedData:
      content = <AssociatedDataInput form={form} localKey={selectedControl} />;
      break;
    case ControlType.resourceWithFixedParams:
      content = <ResourceInput form={form} localKey={selectedControl} />;
      tooltipContent = {
        title: '执行SOP任务时，无需选择资源及参数，对配置的参数直接记录参数值。',
      };
      break;
    case ControlType.resourceWithSelectedParams:
      content = <ResourceInput form={form} localKey={selectedControl} />;
      tooltipContent = {
        title: '执行SOP任务时，需要先手动选择资源及参数，再记录参数值。',
      };
      break;
    case ControlType.associatedField:
      content = <AssociatedFieldInput form={form} localKey={selectedControl} />;
      break;
    case ControlType.qcTaskOfSOP:
      content = <QcTaskOfSOPInput form={form} localKey={selectedControl} />;
      break;
    case ControlType.resourceRepair:
      content = <ProductInput form={form} localKey={selectedControl} />;
      break;
    case ControlType.numberRules:
      content = <NumberRulesInput form={form} localKey={selectedControl} />;
      break;
    default:
      content = null;
  }

  return (
    <div>
      <Title controlType={data.controlType} tooltipContent={tooltipContent} />
      <div className="ant-form ant-form-vertical">
        <Form.Item label="控件编号" required>
          {numberRuleItem.render()}
        </Form.Item>
      </div>
      {content}
    </div>
  );
};

export default PropertyEditor;
