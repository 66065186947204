import React from 'react';
import MaterialTable from './materialTable';
import OutSourceMaterialTable from './outSourceMaterialTable';
import { FormInstance, Tabs } from 'antd';
import { PurchaseOrderMaterialCarryMode } from 'src/dict/purchase';
// 视图模式
export const VIEW_MODAL = {
  ORDERS: '1',
  MATERIALS: '2',
};

interface Props {
  // initialData?: any[];
  outItemList?: any[];
  orderStatus: number;
  // name: string;
  edit: boolean;
  form: FormInstance;
  itemCustomFields: any;
  outItemCustomFields: any;
  carryMode: PurchaseOrderMaterialCarryMode | undefined;
}
const { TabPane } = Tabs;
const MaterialTab = (props: Props) => {
  return (
    <>
      <Tabs type="card">
        <TabPane tab="采购订单" key={VIEW_MODAL.ORDERS}>
          <MaterialTable
            {...props}
            name="itemList"
            customFields={props.itemCustomFields}
            outItemCustomFields={props.outItemCustomFields}
            carryMode={props.carryMode}
          />
        </TabPane>
        <TabPane tab="外协用料清单" key={VIEW_MODAL.MATERIALS}>
          <OutSourceMaterialTable
            {...props}
            name="outItemList"
            customFields={props.outItemCustomFields}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default MaterialTab;
