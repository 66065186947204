/**
 * @component 轻量型穿梭框
 *
 * 点击选项直接穿梭，无中间的操作按钮
 */

import { FC, ReactNode, useState } from 'react';
import _ from 'lodash';
import { Input, List, TransferProps } from 'antd';
import { BlIcon, OverflowTooltip } from 'src/components';
import './styles.scss';

interface Props
  extends Pick<
    TransferProps<{ label: string; value: string }>,
    'dataSource' | 'render' | 'titles'
  > {
  value?: null | string[];
  onChange?: (...args: any) => void;
  extra?: ReactNode;
  emptyText?: ReactNode;
}

export const LightTransfer: FC<Props> = ({
  dataSource,
  render,
  titles,
  value,
  onChange,
  extra,
  emptyText,
}) => {
  const [searchWord, setSearchWord] = useState<string>('');
  const formattedValue = _.isArray(value) ? value : [];
  const remainingOptions = dataSource.filter(
    (item) => !formattedValue.includes(item.value) && item.label.includes(searchWord),
  );
  const selectedOptions = dataSource.filter((item) => formattedValue.includes(item.value));

  return (
    <div className="bl-light-transfer-container">
      <div className="bl-light-transfer-list bl-light-transfer-options">
        <div className="bl-light-transfer-list-title">{_.nth(titles, 0) ?? '待选'}</div>
        <div className="bl-light-transfer-list-search-wrapper">
          <Input
            placeholder="请输入搜索关键字"
            onChange={(e) => setSearchWord(e.target.value)}
            suffix={<BlIcon type="iconsousuo" style={{ color: 'rgba(0,0,0,0.45)' }} />}
            allowClear
          />
        </div>
        <List
          dataSource={remainingOptions}
          split={false}
          renderItem={(item) => (
            <List.Item
              className={'bl-light-transfer-list-item'}
              onClick={() => onChange?.([...formattedValue, item.value])}
            >
              {render ? render(item) : <OverflowTooltip text={item.label} placement="right" />}
            </List.Item>
          )}
        />
      </div>
      <div className="bl-light-transfer-list bl-light-transfer-selected">
        <div className="bl-light-transfer-list-title">{_.nth(titles, 1) ?? '已选'}</div>
        {extra && <div className="bl-light-transfer-list-extra">{extra}</div>}
        <List
          dataSource={selectedOptions}
          split={false}
          locale={emptyText ? { emptyText } : undefined}
          renderItem={(item) => (
            <List.Item className={'bl-light-transfer-list-item'}>
              <BlIcon
                type="icona-lieshanchu2"
                style={{ marginRight: 12, cursor: 'pointer' }}
                onClick={() => onChange?.(_.without(formattedValue, item.value))}
              />
              {render ? (
                render(item)
              ) : (
                <OverflowTooltip
                  text={item.label}
                  placement="right"
                  textStyle={{ display: 'inline-block' }}
                />
              )}
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};
