/**
 * @description 文件导入导出 枚举值
 * @author xuzhiyong
 */

/** 应用 */
export enum APPLICATION_NAME {
  /** 用户 */
  userDomain = 'user-domain',
  /** 物料 */
  materialDomain = 'material-domain',
  /** 订单 */
  orderDomain = 'order-domain',
  /** 仓储 */
  inventoryDomain = 'inventory-domain',
  /** 工程 */
  medDomain = 'med-domain',
  /** 质量 */
  qualityDomain = 'quality-domain',
  /** 仓储定义 */
  storageDomain = 'storage-domain',
  /** 资源 */
  resourceDomain = 'resource-domain',
  /** 工作中心 */
  workCenterDomain = 'work-center-domain',
  /** 工作日历 */
  workerCalendarDomain = 'workercalendar-domain',
  /** 计划订单 */
  planOrderDomain = 'plo-domain',
  /**
   * 生产任务
   */
  workTaskDomain = 'mfg-domain',
  /** 自定义对象 */
  customDomain = 'custom-object-domain',
}

/** 业务类型 */
export enum BUSINESS_TYPE {
  /** ====================== materialDomain ====================== */
  /** 单位 */
  unit = 'unit',
  /** 批次 */
  batchNo = 'materialBatchNo',
  /** 物料 */
  material = 'material',
  /** 物料分类 */
  materialType = 'materialCategory',
  /** 物料属性 */
  materialAttribute = 'materialAttribute',
  /** ====================== orderDomain ====================== */
  /** 部门 */
  dept = 'department',
  /** 用户 */
  user = 'user',
  /** 客户 */
  customer = 'customer',
  /** 销售订单 */
  salesOrder = 'salesOrder',
  /** 供应商 */
  supplier = 'supplier',
  /** 供应链-供应商 */
  supplierSCM = 'supplierSCM',
  /** 采购订单 */
  purchaseOrder = 'purchaseOrder',
  /** 采购退货单 */
  purchaseReturnOrder = 'purchaseReturnOrder',
  /** 交货计划单 */
  deliveryScheduleNote = 'deliveryScheduleNote',
  /** 货源清单 */
  sourceList = 'sourceList',
  /** 发货单 */
  shipmentNote = 'shipmentNote',
  /** 销售退货单 */
  salesReturnOrder = 'salesOrderReturn',
  /** ====================== inventoryDomain ====================== */
  /** 库存明细 */
  materialInventory = 'materialInventory',
  /** 入库单 */
  inboundOrder = 'inboundOrder',
  /** 入库记录 */
  inboundOrderRecord = 'inboundRecord',
  /** 出库单 */
  outboundOrder = 'outboundOrder',
  /** 出库记录 */
  outboundOrderRecord = 'outboundRecord',
  /** 调拨单 */
  transferOrder = 'transferOrder',
  /** 调拨记录 */
  transferOrderRecord = 'transferRecord',
  /** ====================== storageDomain ====================== */
  /** 仓库 */
  storageWarehouse = 'storageWarehouse',
  /** 区域 */
  storageArea = 'storageArea',
  /** 仓位 */
  storageLocation = 'storageLocation',
  /** ====================== qualityDomain ====================== */
  /** 检验项 */
  qcCheckItem = 'qcCheckItem',
  /** 检验项分类 */
  qcCheckItemCategory = 'qcCheckItemCategory',
  /** 不良等级 */
  qcDefectRank = 'qcDefectRank',
  /** 不良原因 */
  qcDefectReason = 'qcDefectReason',
  /** 检验方案 */
  qcConfig = 'qcConfig',
  /** ====================== medDomain ====================== */
  /** 领料申请 */
  pickOrder = 'pickOrder',
  /** 工序 */
  process = 'process',
  /** 工艺路线 */
  processRoute = 'processRoute',
  /** bom */
  bom = 'bom',
  /** 生产工单 */
  productionOrder = 'workOrder',
  /** ====================== resourceDomain ====================== */
  /** 设备 */
  resource = 'equipment',
  /** 资源标签 */
  resources_tags = 'resourceLabel',
  /** 能源仪表主数据  */
  energyMeter = 'energyInstrument',
  /** 资源参数定义 */
  params_def = 'paramsDef',
  /** 区域 */
  location = 'location',
  /** 设备参数记录 */
  equipmentParamRecord = 'equipmentParamRecord',
  /** 能源仪表参数记录 */
  energyInstrumentParamRecord = 'energyInstrumentParamRecord',
  /** 参数监控 */
  paramMonitor = 'paramMonitor',
  /** 资源分类 */
  ResourceType = 'resourceType',
  /** 维保方案 */
  maintenanceCase = 'maintenanceCase',
  /** 维保任务 */
  maintenanceTask = 'maintenanceTask',
  /** 故障记录 */
  malfunctionRecord = 'malfunctionRecord',
  /** 维修任务 */
  repairTask = 'repairTask',
  /** 维修方案 */
  RepairCase = 'repairCase',
  /** ====================== workerCalendarDomain ====================== */
  workerCalendar = 'workerCalendar',
  /** ====================== workCenterDomain ====================== */
  /** 工作中心 */
  workCenter = 'workCenter',
  /** ====================== ploDomain ====================== */
  /** 计划订单 */
  planOrder = 'planOrder',
  /** 计划运算 */
  planningOperationr = 'planningOperationr',
  /** ====================== end ====================== */
  /** 收货单 */
  receiveNote = 'receiveNote',

  /**
   * 替代方案
   */
  alternativePlan = 'alternativePlan',
  /**
   * 报工记录
   */
  progressReport = 'progressReportRecord',
  /**
   * 投料记录
   */
  feedingRecord = 'materialFeedRecord',
  /**
   * 投料回撤记录
   */
  feedingRetractRecord = 'materialRetractRecord',
}

/** 导入模板文件ID --- 临时前端处理 */
export enum TEMPLATE_ID {
  /** ====================== materialDomain ====================== */
  /** 单位 */
  unit = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220112/zh/%E5%8D%95%E4%BD%8D%E5%AF%BC%E5%85%A5.xlsx',
  /** 批次 */
  batchNo = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220102/zh/%E6%89%B9%E6%AC%A1%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 物料 */
  material = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220727/zh/%E7%89%A9%E6%96%99%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 物料分类 */
  materialType = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220112/zh/%E7%89%A9%E6%96%99%E5%88%86%E7%B1%BB%E5%AF%BC%E5%85%A5.xlsx',
  /** 物料属性 */
  materialAttribute = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220112/zh/%E7%89%A9%E6%96%99%E5%B1%9E%E6%80%A7%E5%AF%BC%E5%85%A5.xlsx',
  /** ====================== orderDomain ====================== */
  /** 部门 */
  dept = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220407/zh/%E9%83%A8%E9%97%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 用户 */
  user = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220407/zh/%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 供应商 */
  supplier = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220102/zh/%E4%BE%9B%E5%BA%94%E5%95%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 供应链 - 供应商 */
  supplierSCM = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220102/zh/%E4%BE%9B%E5%BA%94%E5%95%86-%E4%BE%9B%E5%BA%94%E9%93%BE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 客户 */
  customer = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220102/zh/%E5%AE%A2%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 销售订单 */
  salesOrder = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220102/zh/%E9%94%80%E5%94%AE%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 采购订单 */
  purchaseOrder = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220715/zh/%E9%87%87%E8%B4%AD%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5_%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BF.xlsx',
  /** 采购退货单 */
  purchaseReturnOrder = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220715/zh/%E9%87%87%E8%B4%AD%E9%80%80%E8%B4%A7%E5%8D%95%E5%AF%BC%E5%85%A5_%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BF.xlsx',
  /** 交货计划单 */
  deliveryScheduleNote = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220531/zh/%E4%BA%A4%E8%B4%A7%E8%AE%A1%E5%88%92%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx',
  /** 货源清单 */
  sourceList = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220215/zh/%E8%B4%A7%E6%BA%90%E6%B8%85%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 发货单 */
  shipmentNote = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220606/zh/%E5%8F%91%E8%B4%A7%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 销售退货单 */
  salesReturnOrder = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220412/zh/%E9%94%80%E5%94%AE%E9%80%80%E8%B4%A7%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** ====================== inventoryDomain ====================== */
  /** 库存明细 */
  materialInventory = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220615/zh/%E5%BA%93%E5%AD%98%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 入库单 */
  inboundOrder = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220707/zh/%E5%85%A5%E5%BA%93%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 出库单 */
  outboundOrder = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220628/zh/%E5%87%BA%E5%BA%93%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 入库记录 */
  inboundOrderRecord = '',
  /** 出库记录 */
  outboundOrderRecord = '',
  /** 调拨单 */
  transferOrder = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220102/zh/%E8%B0%83%E6%8B%A8%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 调拨记录 */
  transferOrderRecord = '',
  /** ====================== storageDomain ====================== */
  /** 仓库 */
  storageWarehouse = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220112/zh/%E4%BB%93%E5%BA%93%E5%AF%BC%E5%85%A5.xlsx',
  /** 区域 */
  storageArea = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220112/zh/%E4%BB%93%E5%BA%93%E5%8C%BA%E5%9F%9F%E5%AF%BC%E5%85%A5.xlsx',
  /** 仓位 */
  storageLocation = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220112/zh/%E4%BB%93%E4%BD%8D%E5%AF%BC%E5%85%A5.xlsx',
  /** ====================== qualityDomain ====================== */
  /** 检验项 */
  qcCheckItem = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220112/zh/%E6%A3%80%E9%AA%8C%E9%A1%B9%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 检验项分类 */
  qcCheckItemCategory = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220107/zh/%E6%A3%80%E9%AA%8C%E9%A1%B9%E5%88%86%E7%B1%BB%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 不良等级 */
  qcDefectRank = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220112/zh/%E4%B8%8D%E8%89%AF%E7%AD%89%E7%BA%A7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 不良原因 */
  qcDefectReason = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220112/zh/%E4%B8%8D%E8%89%AF%E5%8E%9F%E5%9B%A0%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 检验方案 */
  qcConfig = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/HHZZ3-29903/zh/%E6%A3%80%E9%AA%8C%E6%96%B9%E6%A1%88%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** ====================== medDomain ====================== */
  /** 领料申请 */
  pickOrder = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220614/zh/%E9%A2%86%E6%96%99%E7%94%B3%E8%AF%B7%E5%AF%BC%E5%85%A5%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BF.xlsx',
  /** 工序 */
  process = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220107/zh/%E5%B7%A5%E5%BA%8F%E5%AF%BC%E5%85%A5%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BF.xlsx',
  /** 工艺路线 */
  processRoute = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220624/zh/%E5%B7%A5%E8%89%BA%E8%B7%AF%E7%BA%BF%E5%AF%BC%E5%85%A5%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BFV1.5.xlsx',
  /** bom 物料清单 */
  bom = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220624/zh/%E7%89%A9%E6%96%99%E6%B8%85%E5%8D%95%E5%AF%BC%E5%85%A5%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BFV1.7.xlsx',
  /** 生产工单 */
  productionOrder = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220707/zh/%E7%94%9F%E4%BA%A7%E5%B7%A5%E5%8D%95%E5%AF%BC%E5%85%A5%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BFV1.9.xlsx',
  /** ====================== resourceDomain ====================== */
  /** 设备 */
  resource = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220505/zh/%E8%AE%BE%E5%A4%87%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 资源标签 */
  resources_tags = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220329/zh/%E8%B5%84%E6%BA%90%E6%A0%87%E7%AD%BE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 能源仪表主数据 */
  energyMeter = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220418/zh/%E8%83%BD%E6%BA%90%E4%BB%AA%E8%A1%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 资源参数定义 */
  params_def = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220406/zh/%E8%B5%84%E6%BA%90%E5%8F%82%E6%95%B0%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 区域 */
  location = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220112/zh/%E5%8C%BA%E5%9F%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 设备参数记录 */
  equipmentParamRecord = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220429/zh/%E8%AE%BE%E5%A4%87%E5%8F%82%E6%95%B0%E8%AE%B0%E5%BD%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 能源仪表参数记录 */
  energyInstrumentParamRecord = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220429/zh/%E8%83%BD%E6%BA%90%E4%BB%AA%E8%A1%A8%E5%8F%82%E6%95%B0%E8%AE%B0%E5%BD%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 维修方案 */
  RepairCase = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220725/zh/%E7%BB%B4%E4%BF%AE%E6%96%B9%E6%A1%88%E5%AF%BC%E5%85%A5%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BF.xlsx',
  /** 维保方案 */
  maintenanceCase = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220714/zh/%E7%BB%B4%E4%BF%9D%E6%96%B9%E6%A1%88%E5%AF%BC%E5%85%A5%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BF.xlsx',
  /** 资源分类 */
  ResourceType = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220328/zh/%E8%B5%84%E6%BA%90%E5%88%86%E7%B1%BB%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
  /** 维修任务 */
  repairTask = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220725/zh/%E7%BB%B4%E4%BF%AE%E4%BB%BB%E5%8A%A1%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BF.xlsx',
  /** 故障记录 */
  malfunctionRecord = '',
  /** ====================== workerCalendarDomain ====================== */
  /** 工作日历 */
  workerCalendar = 'workerCalendar',
  /** ====================== workCenterDomain ====================== */
  /** 工作中心 */
  workCenter = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220127/zh/%E5%B7%A5%E4%BD%9C%E4%B8%AD%E5%BF%83%E5%AF%BC%E5%85%A5%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BF.xlsx',
  /** ====================== end ====================== */
  receiveNote = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220215/zh/%E6%94%B6%E8%B4%A7%E5%8D%95%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BF.xlsx',

  /**
   * 替代方案
   */
  alternativePlan = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220624/zh/%E6%9B%BF%E4%BB%A3%E6%96%B9%E6%A1%88%E5%AF%BC%E5%85%A5%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BFV1.2.xlsx',
  /** ====================== ploDomain ====================== */
  /** 计划订单 */
  planOrder = 'https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/import-template/20220704/zh/%E8%AE%A1%E5%88%92%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BF.xlsx',
}

/** 导入状态 */
export enum IMPORT_STATUS {
  /** 导入中 */
  pending = 0,
  /** 导入成功 */
  success,
  /** 部分导入成功 */
  partialSuccess,
  /** 导入失败 */
  fail,
}

/** 导出状态 */
export enum EXPORT_STATUS {
  /** 导出中 */
  pending = 0,
  /** 导出成功 */
  success,
  /** 导出失败 */
  fail,
}

/** 导入导出Excel类型 */
export enum EXCEL_TYPE {
  /** 通用 */
  common,
  /** 特殊 */
  special,
  /** 对象平台 */
  object,
  /** 自定义对象 */
  customObject,
}
