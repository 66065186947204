/**
 * @enum 触发事件模块枚举值
 */

/** 事件类型 */
export enum ActionTypeEnum {
  /** 新建记录 */
  createRecord,

  /** 更新记录 */
  updateRecord,
}

/* 对象属性值配置 中「值类型」的枚举 */
export enum ActionValueTypeEnum {
  /** 值 */
  value,
  /** 字段引用 */
  biz,
  /** 引用触发规则业务字段 */
  triggerRuleBiz,
  /** 公式 */
  formula,
  /** 引用触发规则业务对象 */
  triggerRuleObj,
}

/** 来源类型 */
export enum ActionSourceType {
  /** 手动创建 */
  manual,
  /** 系统预置 */
  preset,
  /** 业务自动创建 */
  businessAutomation,
}

/**
 * 更新对象来源
 */
export enum InstanceSourceTypeEnum {
  /** 手动选择业务对象 */
  manualSelectBizObject,
  /** 触发规则业务对象关联对象 */
  triggerRuleBizObjectAssociatedObject,
  /**  触发规则业务对象 */
  triggerRuleBizObject,
}
