import * as Sentry from '@sentry/react';
import envConfig from 'src/configs/envConfig';
import { Integrations } from '@sentry/tracing';
import LocalStorage from 'src/utils/localStorage';
import * as constants from 'src/utils/constants';

class SentryEntity {
  private readonly debug: boolean;
  private readonly sentryDSN: string;
  private readonly environment: string;
  private isInit: boolean;

  constructor() {
    this.isInit = false;
    this.environment = envConfig.currentApiEnv;
    this.debug = false;
    this.sentryDSN = 'https://ef2014eabcc342dcae65052d1606cdec@sentry2.blacklake.cn/5';
  }

  init() {
    const options: Sentry.BrowserOptions = {
      dsn: this.sentryDSN,
      integrations: [new Integrations.BrowserTracing()],
      release: envConfig.releaseVersion,
      tracesSampleRate: 1.0,
      debug: this.debug,
      environment: this.environment,
      attachStacktrace: true,
      autoSessionTracking: true,
      beforeSend(event: any) {
        // 错误拦截器
        return event;
      },
    };

    if (process.env.NODE_ENV === 'production') {
      console.log('sentry init:', true);
      Sentry.init(options);
      this.isInit = true;
      const userInfo = LocalStorage.get(constants.FIELDS.USER);

      this.setUser(userInfo);
    }
  }

  private setUserHandler(userInfo?: Sentry.User) {
    userInfo && Sentry.setUser(userInfo);
  }

  setUser(userInfo?: Sentry.User | undefined) {
    if (!this.isInit) {
      return;
    }
    this.setUserHandler({ ...userInfo });
  }
}

const sentry = new SentryEntity();

export default sentry;
