import { useState, useEffect } from 'react';
import { Form, Checkbox, message as Message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import {
  fetchPurchaseReturnOrderBatchDistribute,
  fetchPurchaseReturnOrderCreate,
} from 'src/api/ytt/order-domain/purchaseReturnOrder';
import CreateAndEditBaseInfo from '../components/createAndEditBaseInfo';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'src/store/models';
import { goToList } from '../navigates';
import { formatFetchValue } from '../utils';
import { replaceSign } from 'src/utils/constants';
import authDict, { hasAuth } from 'src/utils/auth';

interface createProps extends RouteComponentProps {}

const CreateSalesOrder = (props: createProps) => {
  const { history } = props;
  const [loading, setLoading] = useState(true);
  const [createForm] = Form.useForm();
  const [continuousCreate, setContinuousCreate] = useState(false);
  const [isDistribute, setIsDistribute] = useState(false);
  const [refreshMarker, setRefreshMarker] = useState<number>();

  const { purchaseReturnInfo } = useSelector(
    (state: RootState) => state?.supplyChain,
    shallowEqual,
  );
  const isFromPurchaseOrder = history.location.pathname.includes('purchaseOrder');

  useEffect(() => {
    if (isFromPurchaseOrder && purchaseReturnInfo) {
      const { itemList } = purchaseReturnInfo;
      const supplierCode = (itemList && itemList[0]?.supplier?.code) || replaceSign;
      const supplierName = (itemList && itemList[0]?.supplier?.name) || replaceSign;

      createForm.setFieldsValue({
        supplier: `${supplierCode} / ${supplierName}`,
        source: itemList[0]?.source,
        ...purchaseReturnInfo,
      });
    }
    setLoading(false);
  }, []);

  const extra = (
    <>
      {hasAuth(authDict.purchasereturnorder_dispatch) && (
        <Checkbox
          onChange={(e) => {
            setIsDistribute(e?.target?.checked);
          }}
        >
          直接下发
        </Checkbox>
      )}
      <Checkbox
        onChange={(e) => {
          setContinuousCreate(e?.target?.checked);
        }}
      >
        连续新建
      </Checkbox>
    </>
  );

  const handleFinish = async () => {
    setLoading(true);
    try {
      const value = await createForm?.validateFields();
      const newValue = formatFetchValue(value);

      // newValue.isDistribute = isDistribute;

      const { data } = await fetchPurchaseReturnOrderCreate(newValue);

      if (data?.id) {
        setLoading(false);
        if (isDistribute) {
          await fetchPurchaseReturnOrderBatchDistribute({ ids: [data.id] });
          Message.success('下发成功');
        } else {
          Message.success('创建成功');
        }

        if (continuousCreate) {
          createForm.resetFields();
          setRefreshMarker(Date.now());
          createForm.setFieldsValue({
            source: 1,
            itemList: [],
          });
          return;
        }
        history.push(goToList());
      }
      setLoading(false);
    } catch (error) {
      console.log('error: ', error);
      setLoading(false);
    }
  };

  return (
    <CreateAndEditBaseInfo
      edit={false}
      title="新建采购退货单"
      onCancel={() => {
        history.push(goToList());
      }}
      onFinish={handleFinish}
      form={createForm}
      loading={loading}
      extra={extra}
      refreshMarker={refreshMarker}
    />
  );
};

export default CreateSalesOrder;
