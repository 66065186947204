import { appEnum } from 'src/dict';

// BOM清单其他信息tab
export enum BomTab {
  'SUB_ITEM_MATERIAL' = 1, // 子项物料
  'COPRODUCT', // 多产出物料
  'INTERMEDIATE_MATERIAL', // 中间品
  'OTHER', // 其他
}

export const BomTabMap = new Map([
  [
    BomTab.SUB_ITEM_MATERIAL,
    { value: BomTab.SUB_ITEM_MATERIAL, title: '子项物料', filedName: 'bomInputMaterials' },
  ],
  [
    BomTab.COPRODUCT,
    { value: BomTab.COPRODUCT, title: '多产出物料', filedName: 'bomOutputMaterials' },
  ],
  // [
  //   BomTab.INTERMEDIATE_MATERIAL,
  //   { value: BomTab.INTERMEDIATE_MATERIAL, title: '中间品', filedName: 'intermediateMaterial' },
  // ],
  [BomTab.OTHER, { value: BomTab.OTHER, title: '其他信息', filedName: 'other' }],
]);

/**
 * BOM启用状态颜色
 */
export const BomActiveColorMap = new Map([
  [appEnum.Common.YN.yes, 'green'],
  [appEnum.Common.YN.no, 'red'],
]);
