/**
 * 业务名称
 */
export const BL_BUSINESS_NAME = '维修任务';

/**
 * 业务基础URL
 */
export const BL_BUSINESS_URL = '/resource/implement/repair/task';

/**
 * 业务编码
 */
export const BL_BUSINESS_CODE = 'repairTask';
