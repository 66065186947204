import React, { useEffect, useState } from 'react';
import { Form, message as Message } from 'antd';
import { match, RouteComponentProps } from 'react-router-dom';
import CreateAndEditBaseInfo from './components/createAndEditBaseInfo';
import {
  fetchQcCheckItemCreate,
  fetchQcCheckItemDetail,
  fetchQcCheckItemUpdate,
} from 'src/api/ytt/quality-domain/checkItem';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';

interface StoreEditProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const CheckItemEdit = (props: StoreEditProps) => {
  const { match, history } = props;
  const checkId = Number(match?.params?.id);
  const [modalForm] = Form.useForm();

  const handleFinish = async () => {
    try {
      await modalForm?.validateFields();
      const { code_isUseRules, modifyReason, qcCheckItemCategory, customFields, ...rest } =
        modalForm.getFieldsValue(true);

      const { code } = checkId
        ? await fetchQcCheckItemUpdate({
            ...rest,
            id: checkId,
            categoryId: qcCheckItemCategory?.key || qcCheckItemCategory?.value,
          })
        : await fetchQcCheckItemCreate({ ...rest, categoryId: qcCheckItemCategory?.key });

      if (code === 200) {
        Message.success('更新成功');

        history.goBack();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CreateAndEditBaseInfo
      edit={Boolean(checkId)}
      title={`${checkId ? '编辑' : '创建'}检验项`}
      onFinish={throttleFn(handleFinish)}
      form={modalForm}
      initialData={{ id: checkId }}
    />
  );
};

export default CheckItemEdit;
