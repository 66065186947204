import React, { useEffect, useState } from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import {
  fetchMatchRuleMatchRuleStartStop,
  fetchMatchRuleQueryMatchRuleDetail,
  FetchMatchRuleQueryMatchRuleDetailResponse,
} from 'src/api/ytt/e-report-domain/matchRule';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import authDict from 'src/utils/auth';
import { STATUS_ENUM } from '../templateManagement/constant';
import { toMatchRuleEdit } from '../templateManagement/navigation';
import { DetailBaseInfo } from './components/detailBaseInfo';
import { batchRequest } from './utils';

interface Props extends RouteComponentProps {
  match: match<{ id: string }>;
}

export default function MatchRuleDetail(props: Props) {
  const { match, history } = props;
  const matchRuleId = Number(match?.params?.id);
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录

  const [dataSource, setDetail] = useState<FetchMatchRuleQueryMatchRuleDetailResponse['data']>(
    {} as any,
  );

  const fetchData = async () => {
    try {
      const { data } = await fetchMatchRuleQueryMatchRuleDetail({ matchRuleId });

      if (data) {
        setDetail(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [matchRuleId]);

  const baseMenu = [
    {
      title: dataSource?.status === STATUS_ENUM.ENABLED ? '停用' : '启用',
      key: 'enable',
      auth: authDict.eReportMatch_enable_disable,
      onClick: async () => {
        const storeDisplay = dataSource?.status === STATUS_ENUM.ENABLED ? '停用' : '启用';

        batchRequest(
          'single',
          fetchMatchRuleMatchRuleStartStop,
          {
            matchRuleIdList: [matchRuleId],
            status:
              dataSource?.status === STATUS_ENUM.ENABLED
                ? STATUS_ENUM.DISABLED
                : STATUS_ENUM.ENABLED,
          },
          storeDisplay,
          fetchData,
        );
      },
    },
    {
      key: 'log',
      auth: authDict.eReportMatch_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      icon: <BlIcon type="iconcaozuojilu" />,
      title: '操作记录',
    },
    {
      title: '编辑',
      key: 'edit',
      auth: authDict.eReportMatch_edit,
      disabled: dataSource?.status === STATUS_ENUM.ENABLED,
      onClick: () => history.push(toMatchRuleEdit(matchRuleId, 'detail')),
    },
  ];

  return (
    <>
      <DetailBaseInfo detail={dataSource} baseMenu={baseMenu} />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={matchRuleId}
          objectCode={'eReportMatch'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      ;
    </>
  );
}
