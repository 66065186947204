import lookup from 'src/dict';
import { TraceabilityType } from 'src/dict/process';

export interface TableRowType {
  id: number;
  code: string;
  name: string;
  createdAt: Date;
  creator: string;
  creatorId: number;
  operator: string;
  operatorId: number;
  departmentId: number;
  departmentName: string;
  description: string;
  workCenterId: number;
  workCenterName: string;
}
export interface DetailType {
  id: number;
  code: string;
  name: string;
  createdAt: Date;
  creator: string;
  creatorId: number;
  department: DepartmentType;
  description: string;
  workCenterId: number;
  workCenterName: string;
  files: FieldsType[];
  updatedAt: Date;
  operator: string;
  operatorId: number;
  configurationItems: ConfigurationItems;
}

export interface ConfigurationItems {
  traceRelationship: number;
}

interface DepartmentType {
  name: string;
  code: string;
  id: number;
  active: {
    code: number;
  };
}

export interface FieldsType {
  id: number;
  fileUri: string;
  filename: string;
}

export const FIELD_MAP = new Map([
  ['code', 'code'],
  ['name', 'name'],
  ['description', 'description'],
  ['createdAt', 'createdAt'],
  ['updatedAt', 'updatedAt'],
]);

//  追溯关系

export const traceabilityOptions = [
  {
    label: lookup('process.traceabilityType', TraceabilityType.SingleAutomatic),
    value: TraceabilityType.SingleAutomatic,
  },
  {
    label: lookup('process.traceabilityType', TraceabilityType.batchManually),
    value: TraceabilityType.batchManually,
  },
  {
    label: lookup('process.traceabilityType', TraceabilityType.batchAutomatic),
    value: TraceabilityType.batchAutomatic,
  },
  {
    label: lookup('process.traceabilityType', TraceabilityType.matchingUpdates),
    value: TraceabilityType.matchingUpdates,
  },
  {
    label: lookup('process.traceabilityType', TraceabilityType.noTraceRelationship),
    value: TraceabilityType.noTraceRelationship,
  },
];
