import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { textValidator1, textValidator2 } from 'src/utils/formValidators';
import { Checkbox, Form, FormInstance, Input, Tooltip } from 'antd';
import {
  BlCascader,
  BlIcon,
  NumberRulesStandardHook,
  SearchSelect,
  SearchSelectWithCreate,
} from 'src/components';
import { appEnum } from 'src/dict';
import { noticeOptions, remindTypeOptions } from '../constant';
import AddUserRoleDepartment from '../share/addUserRoleDepartment';
import { fetchStandardBizObjectTreeByCode } from 'src/api/ytt/metadata-domain/custom_object';
import {
  formatSelectOptions,
  getBracketStr,
  getPosition,
  getRelFieldSelections,
  validatorMaxCount,
  validatorRulesMaxCount,
  formatData,
} from '../utils';
import Styles from './styles.module.scss';
import { CascaderOptionType, CascaderValueType } from 'antd/lib/cascader';
import _, { cloneDeep, filter, map, some } from 'lodash';
import { FieldObjType } from '../interface';
import { fetchOrganizationGetThirdPlatformInfo } from 'src/api/ytt/user-domain/organization';
import { NoticeType } from 'src/dict/noticeMessage';

const { TextArea } = Input;

interface baseInfoProps {
  onCancel: () => void;
  onFinish: () => void;
  title: string;
  initialData?: any;
  extra?: ReactNode;
  edit: boolean;
  form: FormInstance;
  handleSetSelectOptions: (type: string, options?: any[]) => void;
  handleResetSelectOptions: () => void;
  inputSelectedOptions: FieldObjType[];
  textSelectedOptions: FieldObjType[];
}

const CreateAndEditBaseInfo = (props: baseInfoProps) => {
  const {
    onCancel,
    title,
    onFinish,
    initialData,
    form,
    extra,
    edit,
    handleSetSelectOptions,
    inputSelectedOptions,
    textSelectedOptions,
    handleResetSelectOptions,
  } = props;

  const [sourceData, setSourceData] = useState<any>([]);
  const [hasConfigFeiShu, setHasConfigFeiShu] = useState<boolean>(false);

  const inputRef = useRef<any>(null);
  const textRef = useRef<any>(null);

  const [roleForm] = Form.useForm();
  const [fieldForm] = Form.useForm();
  // 新宝域名下 不启用邮件订阅
  const { forbidInformByEmail } = window.hostConfig;

  // 获取当前对象下的所有字段
  const getSourceData = async () => {
    try {
      // 新建编辑状态业务对象都是可编辑的，直接从form获取

      const objectCode =
        form.getFieldsValue()?.objectCode?.value &&
        JSON.parse(form.getFieldsValue()?.objectCode?.value)?.objectCode;

      const { data, code } = await fetchStandardBizObjectTreeByCode({
        code: objectCode,
      });

      if (code === 200) {
        const formatDataSource = formatData(data);

        setSourceData(formatDataSource);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  // 获取配置的第三方平台信息  获取租户中是否选择飞书对接
  const getThirdInfo = async () => {
    try {
      const { data } = await fetchOrganizationGetThirdPlatformInfo();

      if (data) {
        const hasConfig = some(data?.thirdPlatforms, (e: number) => e === 1); // 返回了1 代表配置了飞书

        setHasConfigFeiShu(hasConfig);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const onChange = (type: string, dom: any, selectedOptions?: FieldObjType[]) => {
    const start = getPosition(dom)?.start;

    const addField =
      type === 'input'
        ? form.getFieldValue('inputField').join('.')
        : form.getFieldValue('textField').join('.');

    const value =
      type === 'input'
        ? inputRef.current?.props?.value ?? ''
        : textRef.current?.resizableTextArea?.props?.value ?? '';

    const startValue = value?.slice(0, start);
    const endValue = value?.slice(start);

    const content = `${startValue}{${addField}}${endValue}`;

    if (type === 'input') {
      form.setFieldsValue({ messageTitle: content });
      form.setFieldsValue({ inputField: [] });
    } else {
      form.setFieldsValue({ messageContent: content });
      form.setFieldsValue({ textField: [] });
    }

    if (type === 'input') {
      handleSetSelectOptions('input', selectedOptions);
    } else {
      handleSetSelectOptions('text', selectedOptions);
    }
  };

  const objectOnchange = async () => {
    try {
      getSourceData();
      // 当适用对象发生改变时 需要处理一下几点：1 .需要重新获取字段来源 2. 重置标题和内容的字段和储存的数据  3.重置接收人 4.重置触发规则

      form.setFieldsValue({
        messageTitle: '',
        messageContent: '',
        messageReceivers: [],
        triggerRuleIds: [],
      });
      roleForm.resetFields();
      fieldForm.resetFields();
      handleResetSelectOptions();
    } catch (error) {
      console.log('error: ', error);
    }
  };

  /**
   * 校验标题 最大字段数量限制
   */
  const validatorTitleFieldCount = (rule: any, value: string) => {
    const text = getBracketStr(value) ?? [];

    const relSelectionList = getRelFieldSelections(text, inputSelectedOptions);

    const onToMoreSelect = map(
      filter(
        map(relSelectionList, (e: any) => e?.fieldInformationList).flat(3),
        (e: any) => e?.fieldTargetType === 1,
      ),
      'fieldName',
    );

    const onToMoreSelectName = onToMoreSelect.join('、');

    if (relSelectionList?.length > 3) {
      return Promise.reject('最多添加3个字段');
    }

    if (onToMoreSelect?.length > 1) {
      return Promise.reject(`只允许一个对多字段， ${onToMoreSelectName}均是对多字段`);
    }

    return Promise.resolve(true);
  };

  /**
   * 校验内容 最大字段数量限制
   */
  const validatorContentFieldCount = (rule: any, value: string) => {
    const text = getBracketStr(value) ?? [];

    const relSelectionList = getRelFieldSelections(text, textSelectedOptions);

    const onToMoreSelect = map(
      filter(
        map(relSelectionList, (e: any) => e?.fieldInformationList).flat(3),
        (e: any) => e?.fieldTargetType === 1,
      ),
      'fieldName',
    );

    const onToMoreSelectName = onToMoreSelect.join('、');

    if (relSelectionList?.length > 10) {
      return Promise.reject('最多添加10个字段');
    }

    if (onToMoreSelect?.length > 1) {
      return Promise.reject(`只允许一个对多字段， ${onToMoreSelectName}均是对多字段`);
    }

    return Promise.resolve(true);
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    title: '基本信息',
    items: [
      ...NumberRulesStandardHook({
        label: '通知模板编号',
        form,
        edit,
        objectCode: 'MessageTemplate',
        fieldCode: 'code',
        rules: [{ max: 200, message: '不可超过200个字符' }, { validator: textValidator2 }],
      }),
      {
        label: '通知模板名称',
        name: 'name',
        rules: [
          { required: true, message: '通知模板名称不能为空' },
          { validator: textValidator1 },
          { max: 200, message: '不超过200个字符' },
        ],
        render: () => <Input placeholder="请输入" />,
      },
      {
        label: '备注',
        name: 'remark',
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
    ],
  };

  const configInfo: DataFormLayoutInfoBlock = {
    column: 1,
    title: '通知信息',
    items: _.compact([
      {
        label: '业务对象',
        name: 'objectCode',
        rules: [{ required: true, message: '请选择业务对象' }],
        render: () => (
          <SearchSelect
            renderOption={(data: any) => {
              const newData = cloneDeep(data);

              const _value = JSON.stringify(newData);

              return { label: data?.objectName, value: _value, key: data?.objectCode };
            }}
            fetchType={'bizObjectByCode'}
            labelInValue
            allowClear
            onChange={objectOnchange}
          />
        ),
      },
      // {
      //   label: '业务类型',
      //   name: 'customerId',
      //   render: () => <SearchSelect fetchType={'role'} style={{ width: '100%' }} labelInValue />,
      // },
      {
        label: '通知标题',
        required: true,
        render: () => (
          <div className={Styles?.addField}>
            <Form.Item
              name="messageTitle"
              rules={[
                { required: true, message: '请输入通知标题' },
                { max: 50, message: '不超过50个字符' },
                { validator: validatorTitleFieldCount },
              ]}
            >
              <Input id="input" placeholder="请输入" ref={inputRef} allowClear />
            </Form.Item>
            <div className={Styles?.addFieldItem}>
              <div className={Styles?.addText}>插入字段</div>
              <Form.Item name="inputField">
                <BlCascader
                  options={sourceData}
                  showSearch
                  onChange={(value: CascaderValueType, selectedOptions?: CascaderOptionType[]) => {
                    onChange(
                      'input',
                      document.getElementById('input'),

                      formatSelectOptions(value, selectedOptions),
                    );
                  }}
                />
              </Form.Item>
            </div>
          </div>
        ),
      },
      {
        label: '通知内容',
        required: true,
        render: () => (
          <div className={Styles?.addField}>
            <Form.Item
              name="messageContent"
              rules={[
                { required: true, message: '请输入通知内容' },
                { max: 500, message: '不超过500个字符' },
                { validator: validatorContentFieldCount },
              ]}
            >
              <TextArea id="text" placeholder="请输入" ref={textRef} allowClear />
            </Form.Item>
            <div className={Styles?.addFieldItem}>
              <div className={Styles?.addText}>插入字段</div>
              <Form.Item name="textField">
                <BlCascader
                  options={sourceData}
                  showSearch
                  onChange={(value: CascaderValueType, selectedOptions?: CascaderOptionType[]) => {
                    onChange(
                      'text',
                      document.getElementById('text'),
                      formatSelectOptions(value, selectedOptions),
                    );
                  }}
                />
              </Form.Item>
            </div>
          </div>
        ),
      },
      {
        label: '接收人',
        name: 'messageReceivers',
        rules: [{ required: true, message: '请选择接收人' }, { validator: validatorMaxCount }],
        render: () => (
          <AddUserRoleDepartment form={form} roleForm={roleForm} fieldForm={fieldForm} />
        ),
      },
      {
        label: '提醒形式',
        render: () => (
          <div style={{ display: 'flex', alignItems: 'center' }} className={Styles?.addUser}>
            <Form.Item name="remindMethod">
              <Checkbox.Group options={remindTypeOptions} />
            </Form.Item>
            <Tooltip title="对于需要接收人立即处理的通知，可以设置紧急通知，通知会在查看前一直以浮层形式展示在接收人设备界面">
              <BlIcon type="iconmianxingwentizhiyinbangzhu1" />
            </Tooltip>
          </div>
        ),
      },
      !forbidInformByEmail && {
        label: (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 4 }}>通知订阅</span>
            <Tooltip title="仅当接收人在用户信息中维护了手机和邮箱，系统才能向接收人发送短信和邮件通知">
              <BlIcon type="iconmianxingwentizhiyinbangzhu1" />
            </Tooltip>
          </span>
        ),
        name: 'messageSubscription',
        render: () => (
          <Checkbox.Group
            options={
              hasConfigFeiShu
                ? noticeOptions
                : noticeOptions?.filter((e) => e?.value !== NoticeType.feiShu)
            }
          />
        ),
      },
      {
        label: '拒收设置',
        render: () => (
          <Form.Item name="allowRejection" valuePropName="checked" noStyle>
            <Checkbox>允许拒收</Checkbox>
          </Form.Item>
        ),
      },
      {
        label: '触发规则',
        name: 'triggerRuleIds',
        rules: [
          { required: true, message: '请选择触发规则' },
          { validator: validatorRulesMaxCount },
        ],
        render: () => (
          <SearchSelectWithCreate
            enableAdd
            onCreateClick={() => {
              window.open('/systemConfig/triggerRule/create');
            }}
            createFieldName="触发规则"
            mode="multiple"
            fetchType={'triggerRule'}
            style={{ width: '100%' }}
            labelInValue
            params={{
              objectCodes: form.getFieldsValue()?.objectCode?.value && [
                JSON.parse(form.getFieldsValue()?.objectCode?.value)?.objectCode,
              ],
              status: 1,
              sourceType: [
                appEnum.TriggerAction.ActionSourceType.manual,
                appEnum.TriggerAction.ActionSourceType.preset,
              ],
            }}
          />
        ),
      },
      {
        label: '编辑原因',
        name: 'updateReason',
        hidden: !edit,
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
    ]),
  };

  useEffect(() => {
    getThirdInfo();
    if (initialData) {
      form?.setFieldsValue({
        id: initialData?.id,
        code: initialData?.code,
        name: initialData?.name,
        remark: initialData?.remark,
        objectCode: {
          key: initialData?.bizObjectCode,
          label: initialData?.bizObjectName,
          value: JSON.stringify({
            objectCode: initialData?.bizObjectCode,
            objectName: initialData?.bizObjectName,
            objectId: initialData?.bizObjectId,
          }),
        },
        allowRejection: initialData?.allowRejection,
        messageTitle: initialData?.messageTitle,
        messageContent: initialData?.messageContent,
        remindMethod: initialData?.remindMethod,
        messageSubscription: initialData?.messageSubscription,
        triggerRuleIds: map(initialData?.rules, (node: any) => {
          return {
            key: node?.triggerRuleId,
            label: node?.triggerRuleName,
          };
        }),
        messageReceivers: initialData?.messageReceivers,
      });
      roleForm?.setFieldsValue({
        roleSelect: initialData?.roleReceivers,
      });
      fieldForm?.setFieldsValue({
        fieldSelect: initialData?.fieldReceivers,
      });
      getSourceData();
    }
  }, [initialData]);

  return (
    <DataFormLayout
      form={form}
      title={title}
      info={[baseInfo, configInfo]}
      onCancel={onCancel}
      onFinish={onFinish}
      extra={extra}
      formLayout="horizontal"
    />
  );
};

export default CreateAndEditBaseInfo;
