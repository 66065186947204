import React from 'react';
import { DataFormLayoutForModal, DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Form, Input, Select, message as Message } from 'antd';
import { QUALITY } from '../constant';
import { fetchStorageLocationBulkModifyQcCtrlList } from 'src/api/ytt/storage-domain/position';
import { showErrorListMessage } from 'src/components';
import { replaceSign } from 'src/utils/constants';

const { Option } = Select;

const { TextArea } = Input;

interface createProps {
  visible: Boolean;
  onCancel: () => void;
  closeQcCtrl: () => void;
  selectedRowKeys: number[];
}

const QcCtrlListModal = (props: createProps) => {
  const { visible, onCancel, selectedRowKeys, closeQcCtrl } = props;

  const [modalForm] = Form.useForm();

  const options = QUALITY.map(({ name, id }) => <Option value={id}>{name}</Option>);

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    items: [
      {
        label: '质量范围',
        name: 'qcCtrlList',
        rules: [{ required: true, message: '质量范围必选' }],
        render: () => <Select mode="multiple">{options}</Select>,
      },
      {
        label: '编辑原因',
        name: 'modifyReason',
        render: () => <TextArea placeholder="非必填" />,
      },
    ],
  };

  const errorColumns = [
    {
      title: '仓位名称',
      dataIndex: 'name',
      width: 200,
      render: (name: string) => name || replaceSign,
    },
    {
      title: '仓位编号',
      dataIndex: 'code',
      width: 200,
      render: (code: string) => code || replaceSign,
    },
    {
      title: '失败原因',
      dataIndex: 'errorDesc',
      width: 300,
      render: (errorDesc: string) => errorDesc,
    },
  ];

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();

      fetchStorageLocationBulkModifyQcCtrlList({
        ids: selectedRowKeys,
        qcCtrlList: value?.qcCtrlList,
      })
        .then((res) => {
          const { data } = res;

          if (data?.failCount === 0) {
            Message.success('批量修改质量管理成功');
            closeQcCtrl && closeQcCtrl();
            return;
          }
          if (data?.failCount !== 0) {
            showErrorListMessage({
              title: '批量修改质量管理失败',
              data: data?.failDetailList ?? [],
              columns: errorColumns,
              operateName: '批量修改质量管理',
              successCount: data?.successCount,
              failCount: data?.failCount,
              width: 1000,
            });
          }
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    } catch (error) {
      console.log(error);
    } finally {
      onCancel && onCancel();
    }
  };

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      onClose={onCancel}
      width={800}
      content={
        <DataFormLayout
          form={modalForm}
          title="批量设置"
          info={[baseInfo]}
          onCancel={onCancel}
          onFinish={handleFinish}
        />
      }
    />
  );
};

export default QcCtrlListModal;
