import { useEffect, useState } from 'react';
import { Form, Input, Checkbox, Select, Button, message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import _ from 'lodash';
import { BlIcon } from '@blacklake-web/component';
import CopyAql from './components/copyAql';
import SampleList from './components/sampleList';
import AcceptLimitList from './components/acceptLimitList';
import { roundRule } from './constant';
import { primary, fontSub } from 'src/styles/color';
import {
  fetchCustomAqlCreate,
  fetchCustomAqlUpdate,
  fetchCustomAqlDetail,
} from 'src/api/ytt/quality-domain/aql';

interface Props extends RouteComponentProps {}
interface Params {
  id?: number | string;
}

enum Mode {
  'CREATE' = 1,
  'EDIT',
  'COPY',
}

const ModeMap = new Map([
  [1, '创建'],
  [2, '编辑'],
  [3, '复制'],
]);

export default function AqlAction(props: Props) {
  const { history, match } = props;
  const params: Params = match.params;
  const id = Number(params.id);
  const [form] = Form.useForm();
  const [sampleData, setSampleData] = useState([]);
  const getMode = () => {
    if (!('id' in params)) {
      return Mode.CREATE;
    } else if (match.path.indexOf('copy') > -1) {
      return Mode.COPY;
    }
    return Mode.EDIT;
  };
  const pageMode = getMode();
  const title = ModeMap.get(pageMode);
  const [keepOn, setKeepOn] = useState(false);

  const refreshSample = async () => {
    const { sampleList: samples, acceptLimitList } = await form?.getFieldsValue();
    const fieldList: any = [];

    samples?.forEach((row: any, index: number) => {
      Object.keys(row).forEach((field: string) => {
        fieldList.push(['sampleList', index, field]);
      });
    });
    // 只校验样本量表
    const values = await form?.validateFields(fieldList);

    setSampleData(values.sampleList || []);
    form.setFieldsValue({
      acceptLimitList: acceptLimitList?.map((item: any) => {
        return {
          ...item,
          limit: values.sampleList?.map((s: any, key: number) => {
            return {
              ...s,
              id: item?.limit[key]?.id,
              acceptCount: item?.limit[key]?.acceptCount,
              rejectCount: item?.limit[key]?.rejectCount,
            };
          }),
        };
      }),
    });
  };
  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,

    items: [
      {
        label: '方案名称：',
        name: 'name',
        rules: [
          { required: true, message: '请输入方案名称' },
          { max: 255, message: '长度过长，请限制在255个字符内' },
        ],
        render: () => <Input placeholder="请输入" />,
      },
      {
        label: '批量修约规则：',
        name: 'roundRule',
        rules: [{ required: true, message: '请选择修约规则' }],
        render: () => (
          <Select placeholder="请选择">
            {Array.from(roundRule).map(([value, label]) => (
              <Select.Option value={value} key={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        ),
      },
      {
        label: '样本量表：',
        name: 'sampleList',
        isFullLine: true,
        render: () => (
          <>
            <div>
              <CopyAql
                style={{
                  marginBottom: 16,
                  border: `1px dashed ${primary}`,
                  color: primary,
                }}
                setSampleData={setSampleData}
                sampleData={sampleData}
                aqlForm={form}
              />
            </div>
            <SampleList name="sampleList" form={form} />
          </>
        ),
      },
      {
        label: '接收质量限表：',
        name: 'acceptLimitList',
        isFullLine: true,
        render: () => (
          <>
            <div style={{ marginBottom: 16 }}>
              <Button
                style={{
                  border: `1px dashed ${primary}`,
                  color: primary,
                  marginRight: 10,
                }}
                icon={<BlIcon type="iconshuaxin" />}
                onClick={refreshSample}
              >
                刷新
              </Button>
              <span style={{ color: fontSub }}> 点击刷新会根据样本量表自动更新接收质量限表</span>
            </div>
            {sampleData?.length ? <AcceptLimitList name="acceptLimitList" form={form} /> : null}
          </>
        ),
      },
      {
        label: '编辑原因',
        name: 'operateReason',
        hidden: !(pageMode === Mode.EDIT),
        rules: [{ max: 1000, message: '不超过1000个字符' }],
        render: () => <Input.TextArea placeholder="请输入" />,
      },
    ],
  };
  const validateSampleAndAcceptLimit = (sampleList: any[], acceptLimitList: any[]) => {
    // 校验样本量和接收表
    // 校验长度
    const validateLen = _.isEmpty(
      _.find(acceptLimitList, (o) => o?.limit.length !== sampleList.length),
    );

    if (!validateLen) return false;
    // 校验数字
    let validateCount = true;

    acceptLimitList?.forEach((item) => {
      item?.limit.forEach((_item: any, key: number) => {
        console.log(_item);
        console.log(sampleList[key]);
        if (
          _item.maxBatch !== sampleList[key].maxBatch ||
          _item.minBatch !== sampleList[key].minBatch ||
          _item.sampleCount !== sampleList[key].sampleCount
        ) {
          validateCount = false;
        }
      });
    });
    return validateCount;
  };

  const onCancel = () => {
    history.goBack();
  };
  const onFinish = async () => {
    const values = await form.validateFields();
    const { sampleList, acceptLimitList } = values;

    if (!sampleList?.length) {
      message.warn('【样本量表】至少有一行数据');
      return;
    }
    if (acceptLimitList?.length && !validateSampleAndAcceptLimit(sampleList, acceptLimitList)) {
      message.warn('样本量表和质量限表的样本不一致，请刷新');
      return;
    }

    const options = {
      ...values,
      acceptLimitList: acceptLimitList?.map((acceptItem: any) => {
        return {
          name: acceptItem.name,
          id: acceptItem.id,
          limit: sampleList.map((sampleItem: any, i: number) => {
            return {
              ...acceptItem.limit[i],
              maxBatch: sampleItem.maxBatch,
              minBatch: sampleItem.minBatch,
              sampleCount: sampleItem.sampleCount,
            };
          }),
        };
      }),
    };

    const res = await (pageMode === Mode.EDIT
      ? fetchCustomAqlUpdate({ ...options, id })
      : fetchCustomAqlCreate(options));

    if (res && res.code === 200) {
      message.success('操作成功');
      if (keepOn) {
        form.resetFields();
        return;
      }
      onCancel();
    }
  };
  const acceptListFormat = (acceptLimitList?: any[], sampleList?: any[]) => {
    if (!acceptLimitList || !sampleList) return [];

    const result = acceptLimitList.map((item: any) => {
      const { name, id, acceptLimitDetail } = item;

      return {
        name,
        id,
        limit: acceptLimitDetail?.map((row: any) => ({
          ..._.find(sampleList, ['id', row.sampleId]),
          ...row,
        })),
      };
    });

    return result;
  };

  const getDetail = async () => {
    const { data } = await fetchCustomAqlDetail({ id: Number(id) });

    if (data) {
      const options = {
        name: data.name,
        roundRule: data.roundRule,
        sampleList: data.sampleList,
        acceptLimitList: acceptListFormat(data?.acceptLimitList, data?.sampleList),
      };

      setSampleData((data?.sampleList as any) || []);
      form?.setFieldsValue(options);
    }
  };

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, [id]);

  return (
    <DataFormLayout
      form={form}
      title={`${title}自定义AQL`}
      info={[baseInfo]}
      onCancel={onCancel}
      onFinish={onFinish}
      extra={
        pageMode === Mode.CREATE && (
          <Checkbox
            onChange={() => {
              setKeepOn(!keepOn);
            }}
            defaultChecked={keepOn}
          >
            连续新建
          </Checkbox>
        )
      }
    />
  );
}
