import { Form, FormInstance, Input, Modal, Radio } from 'antd';
import { WorkOrderCloseTypeEnum } from 'src/dict/productionPlanning';
import { WorkOrderCloseTypeMap } from 'src/dict/productionPlanning/mappings';
import React from 'react';

const showConfirmCloseOrder = (form: FormInstance, onOk: () => any) => {
  Modal.confirm({
    title: '关闭后将无法对工单进行操作，确认关闭？',
    width: 500,
    content: (
      <Form form={form}>
        <Form.Item
          name="workOrderCloseType"
          label="关闭类型"
          initialValue={WorkOrderCloseTypeEnum.normalShutdown}
        >
          <Radio.Group>
            {WorkOrderCloseTypeMap.map(({ label, value }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item label="关闭原因" name="operateReason">
          <Input.TextArea maxLength={1000} />
        </Form.Item>
      </Form>
    ),
    onOk,
  });
};

export default showConfirmCloseOrder;
