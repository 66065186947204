import { useEffect, useState } from 'react';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { DataFormLayout } from 'layout';
import { Checkbox, Form, message } from 'antd';
//
import {
  fetchMaintenanceTaskDetail,
  fetchMaintenanceTaskCreate,
  fetchMaintenanceTaskUpdate,
} from 'src/api/ytt/resource-domain/maintenanceTask';
import lookup, { appEnum } from 'src/dict';
//
import { MaintenanceDetailType, IMaintenanceForm } from '../index.d';

import ActionContent, {
  formatDataToApi as formatBaseInfoToApi,
  formatDataToForm as formatBaseInfoToForm,
} from './baseForm';
import { BL_BUSINESS_NAME } from '../config';
import { goToList } from '../navigates';

interface ActionProps {
  id: string;
}

const DefinitionAction = (props: RouteComponentProps<ActionProps>) => {
  const { match, history } = props;
  const id = Number(match.params.id);
  const type = history.location.pathname.split('/').pop();

  const [modalForm] = Form.useForm<IMaintenanceForm>();
  const [keepOn, setKeepOn] = useState<boolean>(false);
  const [detailData, setDetailData] = useState<MaintenanceDetailType>();
  const [loading, setLoading] = useState(false);
  const [refreshMarker, setRefreshMarker] = useState(0);

  const formatDateToSetForm = (data: MaintenanceDetailType): IMaintenanceForm => {
    const { ...rest } = data;

    return {
      ...formatBaseInfoToForm(rest),
    };
  };

  const formatDataToSend = (data: IMaintenanceForm) => {
    const { ...resData } = data;

    return {
      ...formatBaseInfoToApi(resData, type),
    };
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await fetchMaintenanceTaskDetail({ id });

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return {};
    } finally {
      setLoading(false);
    }
  };

  const fetchApi = (data: any) => {
    let fetchFn;

    if (_.isNil(data)) return Promise.reject();

    switch (type) {
      case appEnum.Common.CRUD.create: {
        fetchFn = fetchMaintenanceTaskCreate;
        break;
      }
      case appEnum.Common.CRUD.edit: {
        fetchFn = fetchMaintenanceTaskUpdate;
        break;
      }
      default:
        break;
    }

    return fetchFn?.(data) ?? Promise.resolve({ code: 500, data: {} });
  };

  useEffect(() => {
    if (type === appEnum.Common.CRUD.edit && id) {
      fetchData().then((data) => {
        const initialFormValue = formatDateToSetForm(data ?? {});

        modalForm.setFieldsValue(initialFormValue);
        setDetailData(data);
      });
    }
  }, [id]);

  const handleFinish = async () => {
    setLoading(true);
    try {
      const value = await modalForm?.validateFields();

      const submitData = formatDataToSend(value);

      const { code } = await fetchApi(submitData);

      if (code === 200) {
        message.success(`${lookup('common.crud', type)}成功！`);

        if (keepOn) {
          setRefreshMarker(Math.random);
          modalForm.resetFields();
        } else {
          goToList();
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DataFormLayout
        loading={loading}
        form={modalForm}
        title={`${lookup('crud', type)}${BL_BUSINESS_NAME}`}
        extra={
          type === appEnum.Common.CRUD.create && (
            <Checkbox
              onChange={() => {
                setKeepOn(!keepOn);
              }}
              defaultChecked={keepOn}
            >
              连续新建
            </Checkbox>
          )
        }
        info={[]}
        onFinish={handleFinish}
        onCancel={() => {
          history.goBack();
        }}
      >
        <ActionContent
          form={modalForm}
          type={type!}
          detailData={detailData}
          refreshMarker={refreshMarker}
        />
      </DataFormLayout>
    </>
  );
};

export default DefinitionAction;
