import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { DatePicker, Input, Select, Tabs, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Moment } from 'moment';
import _ from 'lodash';

import { DataFormLayoutInfoBlock, DataFormLayout } from 'layout';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import AvatarUpload from 'src/components/avatar/upload';
import AreaCascader from 'src/components/Cascader/areaCascader';
import LocalStorage from 'src/utils/localStorage';
import { FIELDS } from 'src/utils/constants';
import {
  checkTwoSidesTrim,
  validateChineseNotSupported,
  numberAlphabetSpecialSymbols,
} from 'src/utils/formValidators';
import { RootState } from 'src/store';
import { BcAttachmentForm, BlIcon, NumberRulesStandardHook, SearchSelect } from 'components';
import { fetchFileListByIds } from 'src/api/ytt/holyfile-domain';
import { lookup } from 'src/dict';
import { UsageStatus, ObjectCode } from 'src/dict/common';
import { ResourceBusinessType, UseAgeUnit } from 'src/dict/resources';
import { equipmentBusinessType } from 'src/dict/resources/mappings';
import { valueOrHolder } from 'src/utils/formatters';
import { injectCustomFieldInfos } from 'src/components/customField';

import ResourceClassificationCascader from '../../components/ResourceClassificationCascader';
import UseAgeFilter from '../components/UseAgeFilter';
import { MAX_INPUT_LENGTH, MAX_TEXTAREA_LENGTH, TypeEnum, MAX_CHILDREN_COUNT } from '../constants';
import { ResourceContentProps } from '../index.d';

import EquipTagFormList from './EquipTagFormList';
import EquipParamsFormList from './EquipParamsFormList';

const ActionContent = (props: ResourceContentProps) => {
  const { form, detailData, type, customFields } = props;
  const [coverFileUrl, setCoverFileUrl] = useState<string | undefined>('');
  const [bussinessType, setBussinessType] = useState<number>(0);
  // 批量添加参数的弹窗是否显示
  const areaValue = useSelector((state: RootState) => state.resources.areaValue, shallowEqual);

  useEffect(() => {
    // // type error: backend removed custom field related fields but we have to keep custom field related code?
    // setCustomData(detailData?.customFieldItemList);
    setBussinessType(detailData?.businessType ?? 0);
  }, [detailData]);

  const getBaseInfo = (): DataFormLayoutInfoBlock => {
    if (form.getFieldValue('coverFileId')) {
      fetchFileListByIds({ idList: [form.getFieldValue('coverFileId')] }).then(({ data }) => {
        setCoverFileUrl(data?.[0]?.fileUri);
      });
    }

    return {
      title: '基本信息',
      column: 2,
      items: _.compact([
        {
          label: '',
          name: 'coverFile',
          render: () => (
            <div style={{ width: '90%' }}>
              <AvatarUpload
                form={form}
                formKey={'coverFile'}
                uploadedUrl={coverFileUrl}
                icon={<BlIcon type="iconshebei" />}
              />
            </div>
          ),
        },
        ...NumberRulesStandardHook({
          label: '设备编号',
          form,
          edit: type === 'edit',
          objectCode: ObjectCode.Equipment,
          fieldCode: 'code',
          rules: [
            { max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` },
            numberAlphabetSpecialSymbols,
          ],
        }),
        {
          label: '设备名称',
          name: 'name',
          rules: [
            { required: true, message: '请输入设备名称' },
            { max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` },
            { validator: checkTwoSidesTrim },
          ],
          render: () => <Input placeholder="请输入设备名称" />,
        },
        {
          label: '业务类型',
          name: 'businessType',
          initialValue: { value: TypeEnum.product },
          rules: [{ required: true, message: '请选择业务类型' }],
          render: () => (
            <Select
              placeholder="请选择业务类型"
              labelInValue
              options={equipmentBusinessType}
              onChange={(type: any) => {
                setBussinessType(Number(type.value));
              }}
            />
          ),
        },
        {
          label: '标识码',
          name: 'entityLinkCode',
          rules: [{ max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` }],
          render: () => <Input placeholder="请输入标识码" />,
        },
        {
          label: '设备分类',
          name: 'classificationId',
          render: () => (
            <ResourceClassificationCascader
              // resFetchData={resFetchData}
              showSearch
              placeholder={'请选择设备分类'}
              enableAddLabel={'新建分类'}
              onCreateClick={() => {
                LocalStorage.set(FIELDS.RESOURCE_TYPE_IS_CREATE, true);
                window.open('/resource/resourceConfig/category');
              }}
              params={{ businessType: ResourceBusinessType.equipment, cascade: true }}
              enableAdd
            />
          ),
        },
        {
          label: '设备层级',
          render: () => (
            <Form.Item
              name="level"
              getValueProps={(value) => {
                return { value: valueOrHolder(lookup('resources.equipmentLevel', value)) };
              }}
            >
              <Input disabled />
            </Form.Item>
          ),
        },
        {
          label: '父级设备',
          name: 'parent',
          render: () => (
            <SearchSelect
              fetchType="resource"
              placeholder="请选择父级设备"
              labelInValue
              // 层数限制不能超过5层，因此5层设备不可再被选为父设备
              params={{
                levelLimit: true,
              }}
            />
          ),
        },
        {
          label: '子级设备',
          name: 'children',
          rules: [
            () => ({
              validator(val: any, value: string | any[]) {
                if (_.isEmpty(value) || value?.length <= MAX_CHILDREN_COUNT) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(`子设备数量不超过${MAX_CHILDREN_COUNT}个`));
              },
            }),
          ],
          render: () => (
            <SearchSelect
              fetchType="resource"
              placeholder="请选择子级设备"
              labelInValue
              mode="multiple"
              // 子设备必须是现在没有父设备的设备
              params={{ hasParent: false }}
            />
          ),
        },
        {
          label: '供应商',
          name: 'supplier',
          render: () => (
            <SearchSelect
              fetchType="supplier"
              // 供应商列表参数的状态要求传数组
              params={{ status: [UsageStatus.enabled] }}
              placeholder="请输入"
              labelInValue
            />
          ),
        },
        {
          label: '序列号',
          name: 'serialNo',
          rules: [{ max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` }],
          render: () => <Input placeholder="请输入序列号" />,
        },
        {
          label: '外部ID',
          name: 'externalNo',
          rules: [
            { required: Number(bussinessType) === TypeEnum.weighting, message: '请输入外部ID' },
            { max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` },
            {
              validator: validateChineseNotSupported(),
            },
          ],
          render: () => <Input placeholder="请输入外部ID" />,
        },
        {
          label: '负责人',
          name: 'responsibleUser',
          rules: [
            () => ({
              validator(val: any, value: string | any[]) {
                if (_.isEmpty(value) || value?.length <= 10) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('负责人数量不超过10个'));
              },
            }),
          ],
          render: () => (
            <UserOrDepartmentSelectWithDialog placeholder="请选择负责人" isMultiple isSelectUser />
          ),
        },
        {
          label: '负责部门',
          name: 'responsibleDepartment',
          rules: [
            () => ({
              validator(val: any, value: string | any[]) {
                if (_.isEmpty(value) || value?.length <= 10) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('负责部门数量不超过10个'));
              },
            }),
          ],
          render: () => (
            <UserOrDepartmentSelectWithDialog placeholder="请选择负责部门" isMultiple />
          ),
        },
        {
          label: '出厂日期',
          name: 'outFactoryDate',
          render: () => (
            <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime style={{ width: '100%' }} />
          ),
        },
        {
          label: '入厂日期',
          name: 'enterFactoryDate',
          render: () => (
            <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime style={{ width: '100%' }} />
          ),
        },
        {
          label: '启用日期',
          name: 'enableDate',
          render: () => (
            <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime style={{ width: '100%' }} />
          ),
        },
        {
          label: '存储单位',
          name: 'unit',
          render: () => (
            <SearchSelect
              fetchType="unit"
              placeholder="请选择单位"
              labelInValue
              params={{ enableFlag: UsageStatus.enabled }}
            />
          ),
        },
        {
          label: '存储区域',
          name: 'locationId',
          // 新建时的初始值，列表页区域树的选中值，由rematch传入
          initialValue: areaValue,
          render: () => <AreaCascader placeholder="请输入选择存储区域" />,
        },
        {
          label: '使用年限',
          render: () => <UseAgeFilter defaultUnit={UseAgeUnit.year} />,
        },
        {
          label: '计划报废日期',
          dependencies: ['enableDate', 'useAge', 'useAgeType'],
          render: (formItemConfig) => () => {
            // 新建时，如果计划报废日期未填，启用日期和使用年限已填，需要自动计算计划报废日期的缺省值
            if (type === 'create') {
              const planScrapDate = form.getFieldValue('planScrapDate');
              const enableDate: Moment = form.getFieldValue('enableDate');
              const useAge = form.getFieldValue('useAge');
              const useAgeType = form.getFieldValue('useAgeType');

              if (
                _.isNil(planScrapDate) &&
                !_.isNil(useAge) &&
                !_.isNil(useAgeType) &&
                !_.isNil(enableDate)
              ) {
                const defaultPlanScrapDate = enableDate
                  .clone()
                  .add(useAge, lookup('resources.useAgeUnitCalc', useAgeType));

                form.setFieldsValue({ planScrapDate: defaultPlanScrapDate });
              }
            }
            return (
              <Form.Item {...formItemConfig} name="planScrapDate" style={{ marginBottom: 0 }}>
                <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime style={{ width: '100%' }} />
              </Form.Item>
            );
          },
        },
        {
          label: '关联仓位',
          name: 'storageId',
          render: () => (
            <SearchSelect
              fetchType="storageLocation"
              labelInValue
              params={{ enableFlag: UsageStatus.enabled }}
            />
          ),
        },
        {
          label: '品牌型号',
          name: 'brandModel',
          hidden: bussinessType !== TypeEnum.weighting,
          rules: [{ required: bussinessType === TypeEnum.weighting, message: '请选择品牌型号' }],
          render: () => (
            <SearchSelect placeholder="请选择品牌型号" fetchType="brandModel" labelInValue />
          ),
        },
        {
          label: '附件',
          name: 'fileIdList',
          render: () => <BcAttachmentForm form={form} />,
        },
        {
          label: '备注',
          name: 'remark',
          rules: [{ max: MAX_TEXTAREA_LENGTH, message: `不超过${MAX_TEXTAREA_LENGTH}字符` }],
          render: () => <TextArea placeholder="请输入" />,
        },
        type === 'edit' && {
          label: '编辑原因',
          name: 'reason',
          rules: [{ max: 100, message: '不超过100字符' }],
          render: () => <Input placeholder="请输入编辑原因" />,
        },
      ]),
    };
  };

  const tagInfo = {
    title: '设备标签',
    column: 2,
    items: [
      {
        isFullLine: true,
        render: () => <EquipTagFormList form={form} />,
      },
    ],
  };

  const paramsInfo = {
    title: '设备参数',
    column: 2,
    items: [
      {
        isFullLine: true,
        render: () => <EquipParamsFormList form={form} />,
      },
    ],
  };

  const tabPaneListConfig = [
    {
      key: 'baseInfo',
      tabTitle: '设备台账',
      data: _.compact([
        getBaseInfo(),
        customFields?.customFields?.length &&
          injectCustomFieldInfos({
            customFields,
            type: 'form',
            formConfig: { form }, // form相关配置
          }),
        tagInfo,
        paramsInfo,
      ]),
    },
  ];

  return (
    <>
      <div className="detailTab" style={{ overflowY: 'auto', marginTop: 20 }}>
        <Tabs defaultActiveKey="baseInfo" type="card">
          {tabPaneListConfig.map((tabPaneConfig) => (
            <Tabs.TabPane tab={tabPaneConfig.tabTitle} key={tabPaneConfig.key} forceRender>
              <DataFormLayout footer={false} info={[...tabPaneConfig.data]} form={form} />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default ActionContent;
