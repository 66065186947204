import React, { useContext } from 'react';
import {
  INode,
  IRegisterNode,
  IDisplayComponent,
  NodeContext,
  useAction,
  BuilderContext,
} from 'react-flow-builder';
import ConfigForm from './configForm';
import Styles from './styles.module.scss';
import './index.css';
import { TagTooltip } from '../tooltip';
import lookup from 'src/dict';
import { map } from 'lodash';
import {
  ApprovalDesignationMethodEnum,
  ApprovalDesignationObjectEnum,
} from 'src/dict/processManagement';
import { message, Popconfirm } from 'antd';
import { BlIcon } from '..';

// 获取node 节点有两种方式 第一种
const startAndEndNodeDisplay: React.FC<IDisplayComponent> = ({ node }) => {
  return <div className={Styles?.startNode}>{node.name}</div>;
};

const delElement = (nodesLength: number, delFn: any) => {
  if (nodesLength <= 3) {
    return (
      <div
        className="delIcon"
        onClick={(e: any) => {
          e.stopPropagation();
          message.warning('至少添加一个审批节点');
        }}
      >
        <BlIcon type="icona-lieshanchu2" />
      </div>
    );
  }

  return (
    <Popconfirm
      title="你确定要删除吗？"
      onConfirm={(e: any) => {
        e.stopPropagation();
        delFn();
      }}
      onCancel={(e: any) => {
        e.stopPropagation();
      }}
      okText="确定"
      cancelText="取消"
      trigger="hover"
    >
      <div
        className="delIcon"
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <BlIcon type="icona-lieshanchu2" />
      </div>
    </Popconfirm>
  );
};

const NodeDisplay: React.FC = () => {
  const node = useContext(NodeContext); // 获取node节点方式第二种

  const { nodes } = useContext(BuilderContext);
  const { removeNode } = useAction();

  const delFn = () => {
    removeNode();
  };

  const {
    data: { approverList },
  } = node;

  const _approverList = map(approverList, (node: any) => {
    return {
      name: node?.label ?? node?.name,
      id: node?.value ?? node?.id,
    };
  });

  const newNode = { configured: 0, ...node }; // configured避免Undefined给的默认值 可被覆盖

  return (
    <div className={'other-node'}>
      <div
        className={`${
          newNode?.data?.configured
            ? 'node-status-configured-title'
            : 'node-status-un-configured-title'
        } title`}
      >
        {node?.data?.name ?? node?.name}
        {delElement(nodes?.length, delFn)}
      </div>
      <div
        className={`${
          newNode?.data?.configured
            ? 'node-status-configured-content'
            : 'node-status-un-configured-content'
        }`}
      >
        <div
          className={`${newNode?.data?.configured ? 'show-way' : ''}`}
          style={{
            color:
              newNode?.data?.approveMethod === ApprovalDesignationMethodEnum.andSing
                ? '#018eff'
                : '#FAAD14',
            backgroundColor:
              newNode?.data?.approveMethod === ApprovalDesignationMethodEnum.andSing
                ? '#B8E7FF'
                : '#FFF1B8',
            height: 22,
            borderRadius: 2,
          }}
        >
          {Boolean(newNode?.data?.configured) &&
            lookup('processManagement.approvalDesignationOptions', newNode?.data?.approveMethod)}
        </div>
        <div className="show-tag">
          {newNode?.data?.configured ? (
            <TagTooltip
              text="..."
              list={_approverList}
              isUser={newNode?.data?.approverType === ApprovalDesignationObjectEnum.user}
              max={5}
              type={
                newNode?.data?.approverType === ApprovalDesignationObjectEnum.role
                  ? 'role'
                  : undefined
              }
            />
          ) : (
            '请选择审批人和审批方式'
          )}
        </div>
        <div className="node-status-right">
          <BlIcon type="iconxiangyou" />
        </div>
      </div>
    </div>
  );
};

const ConditionNodeDisplay: React.FC = () => {
  const node = useContext(NodeContext);
  const { nodes } = useContext(BuilderContext);
  const { removeNode } = useAction();

  const delFn = () => {
    removeNode();
  };

  return (
    <div
      className={`condition-node ${
        node?.data?.configured ? 'node-status-configured' : 'node-status-un-configured'
      } ${node?.data?.configured ? 'configured-condition' : 'no-configured-condition'}`}
    >
      <div className="condition-content">
        {node?.data?.configured ? node?.data?.name : '请输入条件参数'}
      </div>
      <div className="node-status-right">
        <BlIcon type="iconxiangyou" />
      </div>
      {delElement(nodes?.length, delFn)}
    </div>
  );
};

const defaultRegisterNodes: IRegisterNode[] = [
  {
    type: 'start',
    name: '开始节点',
    displayComponent: startAndEndNodeDisplay,
    isStart: true,
    addableNodeTypes: [], // 开始节点不允许插入新节点
  },
  {
    type: 'end',
    name: '结束节点',
    displayComponent: startAndEndNodeDisplay,
    isEnd: true,
  },
  {
    type: 'node',
    name: '审批节点名称',
    displayComponent: NodeDisplay,
    configComponent: ConfigForm,
    configTitle: '编辑审批节点',
    initialNodeData: {
      data: {
        configured: 0,
        nodeFormType: 'approvingCommonNodes',
        isChangeFieldValue: 0,
        isEdit: 0,
      }, // isChangeField 是否更新字段值
    },
    customRemove: true,
    addableNodeTypes: ['node'],
  },
  {
    type: 'condition',
    name: '条件节点',
    configTitle: '编辑条件节点',
    displayComponent: ConditionNodeDisplay,
    configComponent: ConfigForm,
    customRemove: true,
    initialNodeData: { data: { configured: 0, nodeFormType: 'approvingConditionNodes' } },
  },
  {
    type: 'branch',
    name: '分支节点',
    conditionNodeType: 'condition',
  },
];

export const defaultNodes = [
  {
    id: '1',
    type: 'start',
    name: '开始',
    path: ['0'],
  },
  {
    id: '2',
    type: 'node',
    name: '审批节点名称',
    data: {
      configured: 0,
      nodeFormType: 'approvingCommonNodes',
      isChangeFieldValue: 0,
      isEdit: 0,
    }, // configured 是否配置过 0 未配置 1 已配置过   nodeFormType：根据type显示对应的formItem
    path: ['1'],
  },
  {
    id: '3',
    type: 'end',
    name: '结束',
    path: ['2'],
  },
];

interface FlowNodesConfigs {
  [index: string]: {
    registerNodes: IRegisterNode[];
    nodesDefault: INode[];
  };
}

export const customerFlowNodes: FlowNodesConfigs = {
  // 审批流程管理审批节点  也是默认的目前ui第一版  目前这版只支持配置基本节点不支持分支 条件节点
  defaultType: {
    registerNodes: defaultRegisterNodes,
    nodesDefault: defaultNodes,
  },
};
