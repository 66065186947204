/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../request';

// makeRequest
function makeRequestRequired<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequestOptional<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData?: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequest<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const optional = makeRequestOptional<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  const required = makeRequestRequired<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  return (
    requestConfig.requestDataOptional ? optional : required
  ) as TRequestConfig['requestDataOptional'] extends true ? typeof optional : typeof required;
}

// Request
export type Request<TReqeustData, TRequestConfig extends RequestConfig, TRequestResult> =
  (TRequestConfig['requestDataOptional'] extends true
    ? (
        requestData?: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult
    : (
        requestData: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult) & {
    requestConfig: TRequestConfig;
  };

const mockUrl_0_22_0_0 = 'http://yapi.blacklake.tech/mock/911' as any;
const devUrl_0_22_0_0 = '' as any;
const prodUrl_0_22_0_0 = '' as any;
const dataKey_0_22_0_0 = undefined as any;

/**
 * 接口 [标准业务对象列表获取↗](http://yapi.blacklake.tech/project/911/interface/api/85262) 的 **请求类型**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/911/interface/api/cat_11856)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /metadata/domain/web/v1/standard_biz_object/_list_page`
 * @更新时间 `2021-12-03 13:52:01`
 */
export interface FetchStandardBizObjectListPageRequest {
  /**
   * 创建时间
   */
  createdAt?: string;
  /**
   * 创建人id
   */
  creatorId?: number;
  /**
   * 业务id
   */
  id?: number;
  /**
   * 对象编号
   */
  objectCode?: string;
  /**
   * 对象描述
   */
  objectDesc?: string;
  /**
   * 是否支持字段设置
   */
  objectFieldConfig?: number;
  /**
   * 对象名称
   */
  objectName?: string;
  /**
   * 操作人id
   */
  operatorId?: number;
  /**
   * 排序字段
   */
  orderBy?: string;
  /**
   * 升降序
   */
  orderDirection?: string;
  /**
   * 分页参数
   */
  page: number;
  /**
   * 搜索条件
   */
  search?: string;
  /**
   * 分页参数
   */
  size: number;
  /**
   * 更新时间
   */
  updatedAt?: string;
}

/**
 * 接口 [标准业务对象列表获取↗](http://yapi.blacklake.tech/project/911/interface/api/85262) 的 **返回类型**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/911/interface/api/cat_11856)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /metadata/domain/web/v1/standard_biz_object/_list_page`
 * @更新时间 `2021-12-03 13:52:01`
 */
export interface FetchStandardBizObjectListPageResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * 创建时间
       */
      createdAt?: string;
      /**
       * 创建人id
       */
      creatorId?: number;
      /**
       * 业务id
       */
      id?: number;
      /**
       * 对象编号
       */
      objectCode?: string;
      /**
       * 对象描述
       */
      objectDesc?: string;
      /**
       * 是否支持字段设置
       */
      objectFieldConfig?: number;
      /**
       * 对象名称
       */
      objectName?: string;
      /**
       * 操作人id
       */
      operatorId?: number;
      /**
       * 排序字段
       */
      orderBy?: string;
      /**
       * 升降序
       */
      orderDirection?: string;
      /**
       * 分页参数
       */
      page: number;
      /**
       * 搜索条件
       */
      search?: string;
      /**
       * 分页参数
       */
      size: number;
      /**
       * 更新时间
       */
      updatedAt?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [标准业务对象列表获取↗](http://yapi.blacklake.tech/project/911/interface/api/85262) 的 **请求配置的类型**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/911/interface/api/cat_11856)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /metadata/domain/web/v1/standard_biz_object/_list_page`
 * @更新时间 `2021-12-03 13:52:01`
 */
type FetchStandardBizObjectListPageRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/911',
    '',
    '',
    '/metadata/domain/web/v1/standard_biz_object/_list_page',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [标准业务对象列表获取↗](http://yapi.blacklake.tech/project/911/interface/api/85262) 的 **请求配置**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/911/interface/api/cat_11856)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /metadata/domain/web/v1/standard_biz_object/_list_page`
 * @更新时间 `2021-12-03 13:52:01`
 */
const fetchStandardBizObjectListPageRequestConfig: FetchStandardBizObjectListPageRequestConfig = {
  mockUrl: mockUrl_0_22_0_0,
  devUrl: devUrl_0_22_0_0,
  prodUrl: prodUrl_0_22_0_0,
  path: '/metadata/domain/web/v1/standard_biz_object/_list_page',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_22_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStandardBizObjectListPage',
};

/**
 * 接口 [标准业务对象列表获取↗](http://yapi.blacklake.tech/project/911/interface/api/85262) 的 **请求函数**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/911/interface/api/cat_11856)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /metadata/domain/web/v1/standard_biz_object/_list_page`
 * @更新时间 `2021-12-03 13:52:01`
 */
export const fetchStandardBizObjectListPage = makeRequest<
  FetchStandardBizObjectListPageRequest,
  FetchStandardBizObjectListPageResponse,
  FetchStandardBizObjectListPageRequestConfig
>(fetchStandardBizObjectListPageRequestConfig);

/**
 * 接口 [标准业务对象列表无分页获取↗](http://yapi.blacklake.tech/project/911/interface/api/85268) 的 **请求类型**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/911/interface/api/cat_11856)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /metadata/domain/web/v1/standard_biz_object/_object_list`
 * @更新时间 `2021-12-03 13:52:01`
 */
export interface FetchStandardBizObjectObjectListRequest {}

/**
 * 接口 [标准业务对象列表无分页获取↗](http://yapi.blacklake.tech/project/911/interface/api/85268) 的 **返回类型**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/911/interface/api/cat_11856)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /metadata/domain/web/v1/standard_biz_object/_object_list`
 * @更新时间 `2021-12-03 13:52:01`
 */
export interface FetchStandardBizObjectObjectListResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    createdAt?: string;
    creatorId?: number;
    id?: number;
    /**
     * 对象code
     */
    objectCode?: string;
    /**
     * 对象描述
     */
    objectDesc?: string;
    /**
     * 是否支持字段配置 0不可配置1可配置
     */
    objectFieldConfig?: number;
    /**
     * 对象name
     */
    objectName?: string;
    operatorId?: number;
    orgId?: number;
    updatedAt?: string;
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [标准业务对象列表无分页获取↗](http://yapi.blacklake.tech/project/911/interface/api/85268) 的 **请求配置的类型**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/911/interface/api/cat_11856)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /metadata/domain/web/v1/standard_biz_object/_object_list`
 * @更新时间 `2021-12-03 13:52:01`
 */
type FetchStandardBizObjectObjectListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/911',
    '',
    '',
    '/metadata/domain/web/v1/standard_biz_object/_object_list',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [标准业务对象列表无分页获取↗](http://yapi.blacklake.tech/project/911/interface/api/85268) 的 **请求配置**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/911/interface/api/cat_11856)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /metadata/domain/web/v1/standard_biz_object/_object_list`
 * @更新时间 `2021-12-03 13:52:01`
 */
const fetchStandardBizObjectObjectListRequestConfig: FetchStandardBizObjectObjectListRequestConfig =
  {
    mockUrl: mockUrl_0_22_0_0,
    devUrl: devUrl_0_22_0_0,
    prodUrl: prodUrl_0_22_0_0,
    path: '/metadata/domain/web/v1/standard_biz_object/_object_list',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_22_0_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: true,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchStandardBizObjectObjectList',
  };

/**
 * 接口 [标准业务对象列表无分页获取↗](http://yapi.blacklake.tech/project/911/interface/api/85268) 的 **请求函数**
 *
 * @分类 [web端标准业务对象接口↗](http://yapi.blacklake.tech/project/911/interface/api/cat_11856)
 * @标签 `web端标准业务对象接口`
 * @请求头 `POST /metadata/domain/web/v1/standard_biz_object/_object_list`
 * @更新时间 `2021-12-03 13:52:01`
 */
export const fetchStandardBizObjectObjectList = makeRequest<
  FetchStandardBizObjectObjectListRequest,
  FetchStandardBizObjectObjectListResponse,
  FetchStandardBizObjectObjectListRequestConfig
>(fetchStandardBizObjectObjectListRequestConfig);

/* prettier-ignore-end */
