import { useState, useEffect } from 'react';
import { DetailBaseInfo } from './detailBaseInfo';
import { match, RouteComponentProps } from 'react-router-dom';
import { OutBoundCreatePath, toEdit } from '../navigation';
import { SingleOperationLogComponent } from 'src/components';
import _ from 'lodash';
import { message, Modal, message as Message } from 'antd';
import { ShipmentNoteDetailResponse } from '../interface';
import {
  fetchShipmentNoteClose,
  fetchShipmentNoteDetail,
  fetchShipmentNotePlaceGoodsIssue,
} from 'src/api/ytt/order-domain/invoice';
import { useDispatch } from 'react-redux';
import openFinishInvoiceDialog from '../operate/endOrder';
import authDict from 'src/utils/auth';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { appEnum } from 'src/dict';
import DeliverGoodsModal from '../operate/delivery/deliverGoodsModal';
import { useCustomFieldCombinedData } from 'src/components/customField';

interface DetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const InvoiceDetailPage = (props: DetailProps) => {
  const dispatch = useDispatch();
  const { match, history } = props;
  const salesId = Number(match?.params?.id);
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [loading, setLoading] = useState(true);
  // 发货按钮的弹窗，只有当没有主系统许可项的时候才有
  const [deliverGoodsVisible, setDeliverGoodsVisible] = useState(false);
  const [dataSource, setDataSource] = useState<ShipmentNoteDetailResponse>({});
  const [refresh, setRefresh] = useState(false);
  // 自定义字段
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.ShipmentsNote);
  const itemCustomFields = useCustomFieldCombinedData(OBJECT_OF_CODE.ShipmentsNoteItem);
  const refreshStatus = () => {
    setRefresh(!refresh);
  };

  const fetchData = async () => {
    try {
      const [{ data }] = await Promise.all([fetchShipmentNoteDetail({ id: salesId })]);

      data && setDataSource(data);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  /**
   * 初始化
   */
  useEffect(() => {
    fetchData();
  }, [salesId, refresh]);

  /**
   * 发货操作
   */
  const handleDelivery = () => {
    setDeliverGoodsVisible(true);
  };
  /**
   * 新建出库单 操作
   */
  const handleCreateOutBound = (data: ShipmentNoteDetailResponse) => {
    const origin = data?.origin;
    const materialList: any[] = data?.items?.map((i) => ({ ...i, origin })) || [];
    const filterMaterial = _.filter(materialList, (o) => o?.expectedIssueAmount?.amount > 0);

    if (_.isEmpty(filterMaterial)) return message.error('暂无可出库物料');
    dispatch({
      type: 'supplyChain/formatterOutBoundInfo',
      payload: {
        items: filterMaterial,
        customerId: {
          label: data?.customer?.name,
          value: data?.customer?.id,
        },
      },
    });
    return history.push(OutBoundCreatePath());
  };

  /**
   * 下发操作
   */
  const handleIssuedOrder = async () => {
    const { code } = await fetchShipmentNotePlaceGoodsIssue({ ids: [dataSource.id] });

    if (code && code == 200) {
      message.success('下发成功');
    }
    setRefresh(!refresh);
  };

  /**
   * 关闭
   */
  const handleCloseOrder = async (id: number) => {
    const response = await fetchShipmentNoteClose({ shipmentNoteId: id });

    if (response?.code === 200) {
      Message.success('发货单已关闭');
    } else {
      Message.error(response.message);
    }
    setRefresh(!refresh);
  };
  /**
   * 关闭操作
   */
  const handleCloseOrderComfirm = (id: number) => {
    Modal.confirm({
      title: '关闭发货单',
      content: (
        <div>
          确认关闭发货单？
          <br />
          关闭后将无法执行任何操作
        </div>
      ),
      okText: '确认关闭',
      cancelText: '取消',
      onOk: () => handleCloseOrder(id),
    });
  };
  /**
   * 详情 - 操作按钮
   */
  const baseMenu = _.compact([
    {
      key: 'issue',
      onClick: () => handleIssuedOrder(),
      auth: authDict.shipmentsnote_dispatch,
      title: '下发',
    },
    {
      key: 'outBound',
      auth: authDict.outboundorder_add,
      onClick: () => handleCreateOutBound(dataSource),
      title: '新建出库单',
    },
    {
      key: 'deliver',
      auth: authDict.shipmentsnote_deliver,
      onClick: () => {
        handleDelivery();
      },
      title: '发货',
    },
    {
      key: 'over',
      auth: authDict.shipmentsnote_finish,
      onClick: () => {
        openFinishInvoiceDialog(dataSource?.code!, dataSource?.id!, refreshStatus);
      },
      title: '结束',
    },
    {
      key: 'close',
      auth: authDict.shipmentsnote_close,
      onClick: () => {
        handleCloseOrderComfirm(dataSource?.id!);
      },
      title: '关闭',
    },
    {
      key: 'log',
      auth: authDict.shipmentsnote_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'edit',
      auth: authDict.shipmentsnote_edit,
      onClick: () => {
        history.push(toEdit(salesId));
      },
      title: '编辑',
    },
  ]);

  const menuKey =
    dataSource?.noteStatus === appEnum.Invoice.InvoiceStatus.new
      ? ['edit', 'issue', 'close', 'log']
      : ['log'];

  const menuKey2 =
    dataSource?.noteStatus === appEnum.Invoice.InvoiceStatus.executing
      ? ['outBound', 'deliver', 'over', 'log']
      : ['log'];

  const relMenu =
    dataSource?.noteStatus === appEnum.Invoice.InvoiceStatus.executing
      ? _.filter(baseMenu, (node: any) => menuKey2.includes(node?.key))
      : _.filter(baseMenu, (node: any) => menuKey.includes(node?.key));

  return (
    <>
      <DetailBaseInfo
        detail={dataSource}
        customFields={customFields}
        itemCustomFields={itemCustomFields}
        baseMenu={relMenu}
        loading={loading}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={salesId}
          objectCode={OBJECT_OF_CODE.ShipmentsNote}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      {deliverGoodsVisible && (
        <DeliverGoodsModal
          dataSource={dataSource}
          visible={deliverGoodsVisible}
          onClose={() => {
            setDeliverGoodsVisible(false);
            setRefresh(!refresh);
          }}
        />
      )}
    </>
  );
};

export default InvoiceDetailPage;
