import { Form } from 'antd';
import ReferControl, { ReferControlProps } from './referControl';
import type { FieldDTO } from '../types';

/** 引用字段的渲染方法, 用于编辑页。（详情页后端直接返回值） */
export const getReferControlRenderer = (
  field: FieldDTO,
  extra: Omit<ReferControlProps, 'field'>,
) => {
  return () => (
    <Form.Item noStyle dependencies={[extra.targetNamePath]}>
      {() => <ReferControl field={field} {...extra} />}
    </Form.Item>
  );
};
