import { FieldType, TargetType } from 'src/dict/common';
import { LayoutItemType } from 'src/dict/customLayout';
import {
  getDisplayNodeRenderer,
  getSubObjectDisplayNodeRenderer,
  excludeSubObjFields,
} from './renderUtils';
import { FORMAT_RULE_LINE } from 'src/utils/dataTypes/time';
import type {
  FormLayoutRootConfigProps,
  FormLayoutConfigProps,
  FieldDTO,
  SubObjectDTO,
} from './types';

/** 为从对象code添加前缀, 以防在并入datasource时与字段名相混淆 */
export const getPrefixedSubObjectCode = (objectCode: string) => `$sub$${objectCode}`;

/** 默认布局，将系统维护的字段放在『其他信息』里 */
const otherInfoFieldListOfDefaultLayout: FormLayoutConfigProps[] = [
  {
    type: LayoutItemType.field,
    id: 'creator',
    title: '创建人',
    render: getDisplayNodeRenderer({
      fieldType: FieldType.relation,
      reference: 'User',
      targetType: TargetType.singleChoice,
    }),
  },
  {
    type: LayoutItemType.field,
    id: 'createdAt',
    title: '创建时间',
    render: getDisplayNodeRenderer({ fieldType: FieldType.date, datetimeFormat: FORMAT_RULE_LINE }),
    itemProps: { isFullLine: true },
  },
  {
    type: LayoutItemType.field,
    id: 'operator',
    title: '更新人',
    render: getDisplayNodeRenderer({
      fieldType: FieldType.relation,
      reference: 'User',
      targetType: TargetType.singleChoice,
    }),
  },
  {
    type: LayoutItemType.field,
    id: 'updatedAt',
    title: '更新时间',
    render: getDisplayNodeRenderer({ fieldType: FieldType.date, datetimeFormat: FORMAT_RULE_LINE }),
    itemProps: { isFullLine: true },
  },
];

/** 为业务对象生成默认的平铺布局 */
export const getDefaultDetailLayout = (
  entityName: string,
  fieldList: FieldDTO[],
  subObjectList: SubObjectDTO[] = [],
): FormLayoutRootConfigProps => {
  const fieldListWithoutSubObjs = excludeSubObjFields(fieldList);
  const layoutFieldList: FormLayoutConfigProps[] = [
    ...fieldListWithoutSubObjs.map((field) => ({
      type: LayoutItemType.field,
      id: field.fieldCode,
      title: field.fieldName,
      render: getDisplayNodeRenderer(field),
    })),
    ...subObjectList.map((subObj) => {
      return {
        type: LayoutItemType.field,
        id: getPrefixedSubObjectCode(subObj.objectCode!),
        title: subObj.referName,
        render: getSubObjectDisplayNodeRenderer(subObj.fieldList!),
        itemProps: { isFullLine: true },
      };
    }),
  ];

  // 确保从对象表格另起一行展示
  if (fieldListWithoutSubObjs.length > 0 && subObjectList.length > 0) {
    layoutFieldList[fieldListWithoutSubObjs.length - 1].itemProps = { isFullLine: true };
  }
  return {
    title: `${entityName}详情`,
    type: LayoutItemType.container,
    children: [
      {
        type: LayoutItemType.infoBlock,
        title: '基本信息',
        children: layoutFieldList,
      },
      {
        type: LayoutItemType.infoBlock,
        title: '其他信息',
        children: otherInfoFieldListOfDefaultLayout,
      },
    ],
  };
};
