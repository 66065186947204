import React, { useEffect, useMemo, useState } from 'react';
import { Form, FormInstance, InputNumber, message, Select } from 'antd';
import _ from 'lodash';
import { YN } from 'src/dict/common';
import { UnitsConvertType } from 'src/dict/material';
import { fetchUnitDetail } from 'src/api/ytt/material-domain/web-unit';
import Big from 'big.js';

interface Props {
  form: FormInstance;
}

const MAX_BATCH = '10000000';
const MIN_BATCH = 0;

const WarehousingTransferInfo: React.FC<Props> = ({ form }) => {
  const transformUnit = form.getFieldValue('transformUnit');
  const transferUnitId = form.getFieldValue('transferUnitId');
  const [precisionFigure, setPrecisionFigure] = useState(0);
  /** 启用中的主单位、转换单位 */
  const unitList = useMemo(() => {
    return (
      transformUnit
        ?.filter(
          (unit: any) =>
            !_.isNil(unit?.toUnitId) &&
            unit?.enableFlag === YN.yes &&
            [UnitsConvertType.main, UnitsConvertType.fixed].includes(unit?.convertType),
        )
        ?.map((unit: any) => unit?.toUnitId) || []
    );
  }, [transformUnit]);

  const validateTransferBatch = (rule: any, value: any) => {
    if (_.isNil(value)) {
      return Promise.resolve();
    }
    const _value = Big(value);

    if (_value.gt(MIN_BATCH) && _value.lt(Big(MAX_BATCH))) {
      const [integerBits, decimalPlaces] = String(value).split('.');

      if (decimalPlaces && decimalPlaces.length > precisionFigure) {
        return Promise.reject(`最多保留${precisionFigure}个小数位`);
      }
      return Promise.resolve();
    }
    return Promise.reject(`必须大于${MIN_BATCH}，小于${MAX_BATCH}`);
  };

  const validateUnit = (rule: any, value: any) => {
    const batch = form.getFieldValue('transferBatch');

    if (!_.isNil(batch) && !value) {
      return Promise.reject('单位必填');
    }
    return Promise.resolve();
  };

  /**
   * 切换单位
   * 获取单位精度
   * @param id 单位ID
   */
  const unitChange = async (id: number) => {
    const res = await fetchUnitDetail({ id });
    const { data, code, message: msg } = res || {};

    if (code !== 200) {
      message.error(msg);
      return;
    }
    setPrecisionFigure(_.get(data, 'precisionFigure', 0));
  };

  useEffect(() => {
    if (transferUnitId) {
      unitChange(transferUnitId);
    }
  }, [transferUnitId]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Form.Item
        name={'transferBatch'}
        style={{ marginBottom: 0, flexGrow: 1 }}
        rules={[{ validator: validateTransferBatch }]}
      >
        <InputNumber placeholder="请输入" stringMode style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        name={'transferUnitId'}
        style={{ marginLeft: '12px', marginBottom: 0, flexBasis: 150 }}
        rules={[{ validator: validateUnit }]}
      >
        <Select
          options={unitList}
          placeholder="单位"
          onChange={_.debounce((value: any) => {
            unitChange(value);
          }, 300)}
        />
      </Form.Item>
    </div>
  );
};

export default WarehousingTransferInfo;
