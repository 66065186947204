import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Table, DatePicker } from 'antd';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { fetchSopOperationLog, FetchSopOperationLogResponse } from 'src/api/ytt/sop-domain';
import lookup from 'src/dict';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';

const SopOperationLog = () => {
  const { id } = useParams<{ id: string }>();
  const [dataSource, setDataSource] = useState<FetchSopOperationLogResponse['data']>([]);
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null] | null>();

  const displayData =
    dateRange && !_.isEmpty(dateRange)
      ? dataSource!.filter((record) =>
          moment(record.createdAt).isBetween(
            dateRange[0]!.startOf('day'),
            dateRange[1]!.endOf('day'),
          ),
        )
      : dataSource;

  const dataColumns = [
    {
      title: 'SOP方案编号',
      dataIndex: 'code',
    },
    {
      title: '操作人',
      dataIndex: 'operatorName',
    },
    {
      title: '操作类型',
      dataIndex: 'operationType',
      render: lookup('sop.sopOperationType'),
    },
    {
      title: '操作时间',
      dataIndex: 'createdAt',
      render: formatTimeForRender,
    },
    {
      title: '操作原因',
      dataIndex: 'reason',
    },
    {
      title: '操作',
      dataIndex: 'operationContent',
    },
  ];

  useEffect(() => {
    fetchSopOperationLog({ sopId: +id }).then((res) => {
      setDataSource(res.data);
    });
  }, []);

  return (
    <>
      <header style={{ marginBottom: 16 }}>
        <DatePicker.RangePicker value={dateRange} onChange={setDateRange} />
      </header>
      <Table columns={dataColumns} dataSource={displayData} rowKey={'id'} pagination={false} />
    </>
  );
};

export default SopOperationLog;
