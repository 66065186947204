import { useState } from 'react';
import { Form, Input, Button, Checkbox, Modal, message as Message } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { DataFormLayoutForModal, DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import _ from 'lodash';
import { fetchCustomerCreate, fetchCustomerUpdate } from 'src/api/ytt/order-domain/customer';
import {
  validateBlText1AndWhiteSpace,
  validateBlTextAndWhiteSpace,
} from 'src/page/knowledgeManagement/share/warehouseRules';
import type { CreateAndEditProps } from '../interface';
import { CRUD, UsageStatus } from 'src/dict/common';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
const { TextArea } = Input;

// 传给form的设置
// preserve: 在删除字段时保留字段值
// antd的form会在rerender的时候删除所有字段，如果该字段为false，使用form全程不可以rerender
const formProps = {
  preserve: true,
};

export default function CreateAndEdit({
  customerData,
  operateMode,
  editShowMode,
  onClose,
  refresh,
}: CreateAndEditProps) {
  // 正在保存客户数据，用来保存期间disable保存按钮
  const [customerFormLoading, setCustomerFormLoading] = useState(false);
  // 是否连续新建
  const [continueToCreate, setContinueToCreate] = useState(true);
  // 新建/编辑表单实例
  const [customerOperateForm] = Form.useForm();

  /**
   * 编辑原因字段
   */
  const renderEditReasonFormItems = {
    label: '修改原因',
    name: 'editReason',
    hidden: operateMode !== CRUD.edit,
    rules: [
      { max: 1000, message: '不可超过1000个字符' },
      { validator: validateBlTextAndWhiteSpace() },
    ],
    render: () => <TextArea showCount rows={1} maxLength={1000} placeholder="请输入" allowClear />,
  };
  /**
   * Columns
   */
  const customerBasicInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '客户名称',
        name: 'name',
        rules: [
          { required: true, message: '客户名称必填' },
          { validator: validateBlTextAndWhiteSpace() },
          { max: 255, message: '不可超过255个字符' },
        ],
        render: () => <Input placeholder="请输入" allowClear />,
      },
      {
        label: '客户编号',
        name: 'code',
        rules: [
          { required: true, message: '客户编号必填' },
          { validator: validateBlText1AndWhiteSpace() },
          { max: 255, message: '不可超过255个字符' },
        ],
        render: () => (
          <Input placeholder="请输入" disabled={operateMode === CRUD.edit} allowClear />
        ),
      },
      {
        label: '客户所有人',
        name: 'owner',
        rules: [{ required: true, message: '客户所有人必选' }],
        getValueProps: (value?: any) => {
          if (value) {
            return { value: [_.pick(value, ['id', 'name', 'avatarUrl'])] };
          }
          return {};
        },
        getValueFromEvent: (value) => {
          return value[0];
        },
        render: () => (
          <UserOrDepartmentSelectWithDialog
            isNewFormat
            placeholder={'请选择'}
            isMultiple={false} // 是否多选
            isSelectUser // 切换选择用户
          />
        ),
      },
      {
        label: '备注',
        name: 'remark',
        rules: [
          { max: 1000, message: '不可超过1000个字符' },
          { validator: validateBlTextAndWhiteSpace() },
        ],
        render: () => <TextArea showCount maxLength={1000} placeholder="请输入" allowClear />,
      },
      ...(operateMode === CRUD.edit ? [renderEditReasonFormItems] : []),
    ],
  };

  /**
   * 如果有，初始化数据
   */
  if (customerData) {
    customerOperateForm.setFieldsValue(customerData);
  }

  /**
   * 保存 客户修改/新建
   */
  const handleFinish = async () => {
    try {
      // 表单校验
      await customerOperateForm.validateFields();
      const formDirty = customerOperateForm.isFieldsTouched();

      // 脏检测
      if (!formDirty) {
        onClose();
        return;
      }

      // 表单里没有id和enableFlag字段，加上
      const { owner, ...formCustomerData } = customerOperateForm.getFieldsValue();
      const requestParam = {
        ...formCustomerData,
        ownerId: owner.id,
        id: operateMode === CRUD.edit ? customerData?.id : undefined,
        // 新建状态下，客户状态默认为启用中
        enableFlag: customerData ? customerData.status : UsageStatus.enabled,
      };

      const requestFn = operateMode === CRUD.create ? fetchCustomerCreate : fetchCustomerUpdate;

      await requestFn(requestParam);

      // 连续新建则清空表单
      if (continueToCreate && operateMode === CRUD.create) {
        Message.success('保存成功');
        customerOperateForm.resetFields();
        refresh();
      } else if (operateMode !== CRUD.create) {
        onClose();
        // 如果是编辑，弹出消息提示窗
        Modal.confirm({
          title: '客户编辑成功',
          content: `编号：${customerData?.code}`,
          okText: '查看客户详情',
          cancelText: '返回客户列表',
          icon: <CheckCircleOutlined style={{ fontSize: '26px', color: '#02B980' }} />,
          onOk: async () => {
            editShowMode({ ...customerData, ...formCustomerData });
          },
          okButtonProps: { size: 'middle' },
          cancelButtonProps: { size: 'middle' },
          afterClose: refresh,
        });
      } else {
        Message.success('保存成功');
        // 关闭对话框，刷新列表
        onClose();
        refresh();
      }
    } finally {
      setCustomerFormLoading(false);
    }
  };
  /**
   * footer
   */
  const renderFooter = (
    <>
      <Button
        onClick={() => {
          onClose();
        }}
      >
        取消
      </Button>
      <Button type="primary" loading={customerFormLoading} onClick={handleFinish}>
        保存
      </Button>
    </>
  );
  /**
   * footer - extra
   */
  const renderExtra = (
    <>
      {operateMode === CRUD.create && (
        <Checkbox
          onChange={() => {
            setContinueToCreate(!continueToCreate);
          }}
          defaultChecked={continueToCreate}
        >
          连续新建
        </Checkbox>
      )}
    </>
  );

  return (
    <DataFormLayoutForModal
      content={
        <DataFormLayout
          form={customerOperateForm}
          info={[customerBasicInfo]}
          title={operateMode === CRUD.edit ? '编辑客户' : '创建客户'}
          formProps={formProps}
          footer={renderFooter}
          extra={renderExtra}
        />
      }
      width={800}
      visible={operateMode !== undefined}
      onClose={() => {
        onClose();
      }}
    />
  );
}
