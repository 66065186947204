/**
 * @description 用户相关枚举
 */

/** 用户批量操作方式 */
export enum USER_BATCH_ACTION {
  /** 配置账号类型 */
  accountType = 'accountType',
  /** 激活 */
  activate = 'activate',
  /** 启用/停用 */
  updateStatus = 'updateStatus',
  /** 解锁 */
  lock = 'lock',
}

/** 账号状态 */
export enum USER_STATUS_ENUM {
  /** 停用 */
  disable = 0,
  /** 启用 */
  enable,
  /** 激活 */
  active,
}

/** 部门状态 */
export enum DEPARTMENT_STATUS_ENUM {
  /** 停用 */
  disable = 0,
  /** 启用 */
  enable,
}

/** 数据权限筛选条件 */
export enum LOGIC_OPERATION_ENUM {
  /** 或 */
  or = 0,
  /** 与 */
  and,
}

/**
 * 数据权限字段筛选条件
 */
export enum DATA_PRIVILEGE_OPERATION_ENUM {
  /** 等于 */
  equal = 1,
  /** 包含 */
  include,
  /** 不等于 */
  notEqual,
  /** 不包含 */
  exclude,
  /** 为空 */
  isnull,
  /** 不为空 */
  notNull,
  /** 当前用户 */
  curUser = 10,
  /** 当前部门 */
  curDepartment,
  /** 等于任意一个 */
  equalAnyone,
  /** 不等于任意一个 */
  notEqualAnyone,
  /** 同时包含（多选） */
  containsAll,
  /** 大于等于 */
  gte,
  /** 小于等于 */
  lte,
  /** 选择范围 */
  range,
}
