import { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  fetchDataPrivilegeGetDataPermission,
  fetchDataPrivilegeListByBizObjectId,
  fetchDataPrivilegeListObjectCodes,
} from 'ytt/privilege-domain/dataPrivilege';
import { BizObjectFieldType, PermissionType } from './types';
import { DatePicker, Input, InputNumber, Radio, Select } from 'antd';
import { appDict, appEnum } from 'src/dict';
import { SearchSelect } from 'src/components';
import { DATA_PRIVILEGE_OPERATION_ENUM } from 'src/dict/user';
import { FieldType } from 'src/dict/customField';
import moment from 'moment';
import SearchSelectNoFetch from 'src/components/select/SearchSelectNoFetch';

/**
 * 获取数据权限业务对象枚举列表
 * @param roleId 角色ID
 */
export const useDataPrivilege = (roleId: number) => {
  const [treeData, setTreeData] = useState<any[]>([]);

  const formatDataPrivilegeTree = (data: any) => {
    return _.map(data, (item) => ({
      key: item.bizObjectId,
      title: item.bizObjectName,
    }));
  };

  useEffect(() => {
    if (roleId) {
      fetchDataPrivilegeListObjectCodes({ roleId }).then((data) => {
        const _treeData = formatDataPrivilegeTree(_.get(data, 'data', []));

        setTreeData(_treeData);
      });
    }
  }, [roleId]);
  return treeData;
};

/**
 * 获取当前对象的字段List
 * @param roleId 角色ID
 * @param bizObjectId 对象ID
 */
export const useGetFieldByBizObjectId = (roleId?: number, bizObjectId?: number) => {
  const [data, setData] = useState<BizObjectFieldType>();

  useEffect(() => {
    if (roleId && bizObjectId) {
      fetchDataPrivilegeListByBizObjectId({
        roleId,
        bizObjectId,
      }).then((res) => {
        setData(_.get(res, 'data', []) as BizObjectFieldType);
      });
    }
  }, [roleId, bizObjectId]);
  return data;
};

/**
 * 根据对象ID获取已配置数据权限
 * @param roleId 角色ID
 * @param bizObjectId 对象ID
 */
export const useGetDataByBizObjectId = (roleId?: number, bizObjectId?: number) => {
  const [data, setData] = useState<PermissionType>();

  useEffect(() => {
    if (roleId && bizObjectId) {
      fetchDataPrivilegeGetDataPermission({
        roleId,
        bizObjectId,
      }).then((res) => {
        setData(_.get(res, 'data', []) as PermissionType);
      });
    }
  }, [roleId, bizObjectId]);
  return data || [];
};

/**
 * 根据筛选条件判断是否有字段值
 * @param operation 筛选条件
 */
export const hasValueByOperation = (operation: number) => {
  return ![
    DATA_PRIVILEGE_OPERATION_ENUM.isnull,
    DATA_PRIVILEGE_OPERATION_ENUM.notNull,
    DATA_PRIVILEGE_OPERATION_ENUM.containsAll,
    DATA_PRIVILEGE_OPERATION_ENUM.curUser,
    DATA_PRIVILEGE_OPERATION_ENUM.curDepartment,
  ].includes(operation);
};

/**
 * 是否多选值
 * @param operation 筛选条件
 */
export const needMultiple = (operation: number) => {
  return [DATA_PRIVILEGE_OPERATION_ENUM.include, DATA_PRIVILEGE_OPERATION_ENUM.exclude].includes(
    operation,
  );
};

/**
 * 获取字段值
 * @param fieldType 字段类型
 * @param operation 筛选类型
 * @param value 值
 * @param label
 */
const getValue = (fieldType: number, operation: number, value: any, label: string) => {
  if (!hasValueByOperation(operation)) {
    return null;
  }

  if (
    needMultiple(operation) &&
    [FieldType.relation, FieldType.select, FieldType.multiSelect].includes(fieldType)
  ) {
    const labelList = label?.split(',');

    return (
      value?.split(',')?.map((item: any, index: number) => ({
        label: labelList?.[index],
        value: item,
        key: item,
      })) || []
    );
  }

  if (fieldType === FieldType.relation) {
    return {
      label,
      value,
    };
  }

  if (fieldType === FieldType.date) {
    return moment(value);
  }

  if (fieldType === FieldType.boolean) {
    return Number(value);
  }

  return value;
};

/**
 * 格式化已配置权限
 * @param fieldList 对象字段List
 * @param dataPrivilegeList 已配置权限List
 */
export const formatList = (fieldList: any, dataPrivilegeList: any) => {
  const map: any = [];

  _.forEach(dataPrivilegeList, (item) => {
    const curField = _.find(fieldList, { fieldId: Number(item.fieldId) });

    if (curField) {
      const { fieldId, fieldName, ...rest } = curField;
      const field = {
        value: fieldId,
        key: fieldId,
        label: fieldName,
      };
      const option = rest;
      const operation = item.dataPermissionType;

      map.push({
        field,
        option,
        operation,
        value: getValue(option.fieldType, operation, item.value, item.label),
      });
    }
  });

  return map;
};

/**
 * 根据字段获取对应输入组件
 * @param dataSource 字段详情
 * @param operation 筛选条件
 */
export const getFieldValueItem = (dataSource: any, operation: number) => {
  if (_.isNil(dataSource) || _.isNil(operation)) {
    return null;
  }
  const { fieldType, choiceValues, ref, refObjectCode } = dataSource;
  const multiple = needMultiple(operation);
  const formatChoices = (choiceValues: { choiceValue: string; choiceCode: string }[]) => {
    return choiceValues.map((item) => ({
      label: item.choiceValue,
      value: item.choiceCode,
    }));
  };

  if (!hasValueByOperation(operation)) {
    return null;
  }

  // 关联关系
  if (ref) {
    return (
      <SearchSelect
        fetchType={'customizedObject'}
        mode={multiple ? 'multiple' : undefined}
        params={{ objectCode: refObjectCode || [] }}
        placeholder={'请选择'}
      />
    );
  }

  if ([FieldType.select, FieldType.multiSelect].includes(fieldType)) {
    return (
      <SearchSelectNoFetch
        mode={multiple ? 'multiple' : undefined}
        labelInValue={multiple}
        options={formatChoices(choiceValues || [])}
        placeholder={'请选择'}
      />
    );
  }

  if (fieldType === FieldType.boolean) {
    return <Radio.Group options={appDict.common.yn} />;
  }

  if (fieldType === FieldType.date) {
    return <DatePicker showTime />;
  }

  if ([FieldType.integer, FieldType.number].includes(fieldType)) {
    return <InputNumber style={{ width: '100%' }} placeholder={'请输入'} />;
  }

  if (fieldType === FieldType.textArea) {
    return <Input.TextArea autoSize={{ minRows: 1, maxRows: 3 }} placeholder={'请输入'} />;
  }

  return <Input placeholder={'请输入'} />;
};
