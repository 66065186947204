import React, { useState } from 'react';
import { DetailLayout, DetailLayoutForModal } from 'layout';
import { BlTable, TagList } from 'src/components';
import lookup from 'src/dict';
import { getMaterialAttrs } from 'src/entity/material';
import {
  FetchReservedCommonGetResponse,
  fetchReservedLiberate,
} from 'src/api/ytt/plo-domain/reserved';
import _Time from 'src/utils/dataTypes/time';
import { replaceSign } from 'src/utils/constants';
import { message, Space } from 'antd';
import ReservationBaseForm from './editReservation';
import authDict, { hasAuth } from 'src/utils/auth';

interface FeedingControlDetailProps {
  dataSource: FetchReservedCommonGetResponse['data'];
  onChange: () => void;
}

const QueryReservationDetail = (props: FeedingControlDetailProps) => {
  const { onChange, dataSource } = props;

  const [reservationBaseForm, setReservationBaseForm] = useState<any>(false);

  const renderDemandInfo = () => {
    const columns = [
      {
        title: '需求单据类型',
        dataIndex: ['orderType'],
        width: 150,
        render: (text: any) => lookup('planned.reqSourceTypeNameMap', text),
      },
      {
        title: '需求单据编号',
        dataIndex: ['code'],
        width: 150,
      },
      {
        title: '需求单据分录',
        dataIndex: ['seq'],
        width: 150,
      },
      {
        title: '物料编号',
        dataIndex: ['material', 'baseInfo', 'code'],
        width: 200,
      },
      {
        title: '物料名称',
        dataIndex: ['material', 'baseInfo', 'name'],
        width: 150,
      },
      {
        title: '物料分类',
        dataIndex: ['material', 'category', 'name'],
        width: 150,
      },
      {
        title: '物料规格',
        dataIndex: ['material', 'baseInfo', 'specification'],
        width: 150,
      },
      {
        title: '物料属性',
        dataIndex: ['material'],
        width: 150,
        render: (material: any) => <TagList dataSource={getMaterialAttrs(material)} />,
      },
      {
        title: '单位',
        dataIndex: ['unitName'],
        width: 150,
      },
      {
        title: '需求数量',
        dataIndex: ['amount'],
        width: 150,
        render: (amount: any) => amount?.amountDisplay,
      },
      {
        title: '已预留数量',
        dataIndex: ['reservedAmount'],
        width: 150,
        render: (reservedAmount: any) => reservedAmount?.amountDisplay,
      },
      {
        title: '需求时间',
        dataIndex: ['requirementTime'],
        width: 150,
        render: (text: any) => (text ? _Time.format(text) : replaceSign),
      },
    ];

    return (
      <BlTable
        scroll={{ x: 'max-content', y: 'auto' }}
        dataSource={dataSource?.demandInfo ? [dataSource?.demandInfo] : []}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
    );
  };

  const renderSupplyInfoTable = () => {
    const columns = [
      {
        title: '预留编号',
        dataIndex: ['reservedCode'],
        width: 150,
      },
      {
        title: '供应单据类型',
        dataIndex: ['orderType'],
        width: 150,
        render: (text: any) => lookup('planned.reqSourceTypeNameMap', text),
      },
      {
        title: '供应单据编号',
        dataIndex: ['code'],
        width: 150,
      },
      {
        title: '供应单据分录',
        dataIndex: ['seq'],
        width: 150,
      },
      {
        title: '物料编号',
        dataIndex: ['material', 'baseInfo', 'code'],
        width: 200,
      },
      {
        title: '物料名称',
        dataIndex: ['material', 'baseInfo', 'name'],
        width: 150,
      },
      {
        title: '总供应数量',
        dataIndex: ['supplyAmount'],
        width: 150,
        render: (supplyAmount: any) => {
          return supplyAmount?.amountDisplay;
        },
      },
      {
        title: '单位',
        dataIndex: ['unitName'],
        width: 150,
      },
      {
        title: '供应时间',
        dataIndex: ['supplyTime'],
        width: 150,
        render: (text: any) => (text ? _Time.format(text) : replaceSign),
      },
      {
        title: '预留数量',
        width: 150,
        dataIndex: ['reservedAmount', 'amountDisplay'],
      },
      {
        title: '预留单位',
        width: 150,
        dataIndex: ['reservedUnitName'],
      },
      {
        title: '生成方式',
        width: 150,
        dataIndex: ['generationMethod'],
        render: (text: any) => lookup('planned.ReservedGenerationMethodDisplayMap', text),
      },
      {
        title: '预留类型',
        width: 150,
        dataIndex: ['reservedType'],
        render: (text: any) => lookup('planned.reservedTypeMap', text),
      },
      {
        title: '操作',
        render: (text: any, record: any) => {
          const { id } = record;

          return (
            <Space>
              {hasAuth(authDict.PlanReserved_release) && (
                <a
                  onClick={() => {
                    fetchReservedLiberate({ id })
                      .then(() => {
                        onChange();
                        message.success('释放成功');
                      })
                      .catch((err) => {
                        console.log('err: ', err);
                      });
                  }}
                >
                  释放
                </a>
              )}
              {hasAuth(authDict.PlanReserved_edit) && (
                <a
                  onClick={() => {
                    setReservationBaseForm(record);
                  }}
                >
                  编辑
                </a>
              )}
            </Space>
          );
        },
      },
    ];

    return (
      <BlTable
        scroll={{ x: 'max-content', y: 'auto' }}
        dataSource={dataSource?.supplyInfo}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
    );
  };

  const getBaseInfo = () => {
    const baseInfo = [
      {
        title: '需求信息',
        items: [
          {
            dataIndex: 'DemandInfo',
            render: () => renderDemandInfo(),
          },
        ],
      },
      {
        title: '供应信息',
        items: [
          {
            dataIndex: 'DemandInfo',
            render: () => renderSupplyInfoTable(),
          },
        ],
      },
    ];

    return baseInfo;
  };

  return (
    <>
      <DetailLayout title="查询预留" info={getBaseInfo()} dataSource={dataSource} />

      <DetailLayoutForModal
        visible={reservationBaseForm}
        onClose={() => {
          setReservationBaseForm(undefined);
        }}
        content={
          <ReservationBaseForm
            params={reservationBaseForm}
            onChange={() => {
              onChange();
              setReservationBaseForm(undefined);
            }}
          />
        }
      />
    </>
  );
};

export default QueryReservationDetail;
