import React, { useState } from 'react';
import { BlIcon, SingleOperationLogComponent, TabList } from 'src/components';
import { SOURCE_VIEW_TAB } from 'src/dict/sourceList';
import SourceListView from './list/SourceListView';
import SupplierView from './list/SupplierView';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { useDispatch } from 'react-redux';
import { gcObject, gcTime } from 'src/utils';
import { message } from 'antd';
import { fetchSourceListDisable, fetchSourceListEnable } from 'src/api/ytt/order-domain/sourceList';
import { CRUD } from 'src/dict/common';
import { DetailLayoutForDrawer } from 'layout';
import SourceListDetail from './detail/detail';
import CreateAndEdit from './createAndEdit';
import { formatDateTimeRangeToUnix } from 'src/utils/formatters/dateTime';
import authDict from 'src/utils/auth';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import _ from 'lodash';

const SourceList: React.FC = () => {
  const [refreshMarker, setRefreshMarker] = useState(new Date().getTime());
  const [detailVisible, setDetailVisible] = useState(false);
  const [actionVisible, setActionVisible] = useState(false);
  const [visibleLog, setVisibleLog] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [currentId, setCurrentId] = useState<number>();
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  /** 刷新列表 */
  const refreshList = () => {
    setRefreshMarker(new Date().getTime());
  };

  /**
   * 批量启用
   * @ids id List
   */
  const bulkActivate = async (ids: number[], onSuccess?: () => void, onError?: () => void) => {
    const res = await fetchSourceListEnable({ ids });

    const { code, message: msg } = res;

    if (code !== 200) {
      onError?.();
      message.error(msg);
      return;
    }
    onSuccess?.();
    message.success(msg);
    refreshList();
  };

  /**
   * 批量停用
   * @ids id List
   */
  const bulkDeactivate = async (ids: number[], onSuccess?: () => void, onError?: () => void) => {
    const res = await fetchSourceListDisable({ ids });

    const { code, message: msg } = res;

    if (code !== 200) {
      onError?.();
      message.error(msg);
      return;
    }
    onSuccess?.();
    message.success(msg);
    refreshList();
  };

  /**
   * 页面操作
   * @param type 增删改查
   * @param id 行id
   */
  const pageAction = (type: CRUD, id: number) => {
    switch (type) {
      case CRUD.create:
        setCurrentId(undefined);
        setIsEdit(false);
        setActionVisible(true);
        break;
      case CRUD.edit:
        setCurrentId(id);
        setIsEdit(true);
        setActionVisible(true);
        break;
      case CRUD.view:
        setCurrentId(id);
        setDetailVisible(true);
        break;
      case CRUD.enable:
        bulkActivate([id]);
        break;
      case CRUD.disable:
        bulkDeactivate([id]);
        break;
      case CRUD.operrecord:
        setCurrentId(id);
        setVisibleLog(true);
        break;
      default:
        console.log('未知操作');
        break;
    }
  };
  /** 列表配置 */
  const config = {
    rowKey: (record: any) => String(record.id),
    formatDataToFormDisplay: (filterData: any) => {
      const { createdAt, ...rest } = filterData;

      const result = {
        ...rest,
        createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
      };

      return gcObject.filterEmptyProperty(result);
    },
    formatDataToQuery: (params: any) => {
      const { enableStatus, materialName, createdAt, creator, updatedAt, operator, ...rest } =
        params;
      const { createdAtFrom, createdAtTo } = formatDateTimeRangeToUnix(
        'createdAtFrom',
        'createdAtTo',
        createdAt,
      );
      const { updatedAtFrom, updatedAtTo } = formatDateTimeRangeToUnix(
        'updatedAtFrom',
        'updatedAtTo',
        updatedAt,
      );
      const retParams = {
        ...rest,
        enableStatus: enableStatus?.map((item: any) => item?.value),
        materialIds: materialName?.map((mat: any) => mat?.value),
        createdAtFrom,
        createdAtTo,
        creatorIds: creator?.map((item: any) => item?.value),
        updatedAtFrom,
        updatedAtTo,
        operatorIds: operator?.map((item: any) => item?.value),
      };

      dispatch.excelImport.updateBusinessData({ businessData: retParams });
      return retParams;
    },
    mainMenu: [
      {
        title: '导出',
        icon: <BlIcon type="icondaochu" />,
        auth: authDict.sourcelist_export,
        showExport: true,
        onClick: () => {
          openModal({
            optType: 'export',
            businessType: BUSINESS_TYPE.sourceList,
          });
        },
      },
      {
        title: '新建货源清单',
        auth: authDict.sourcelist_add,
        showExport: true,
        icon: <BlIcon type="iconxinjiantianjia" />,
        onClick: () => {
          pageAction(CRUD.create, 0);
        },
        items: [
          {
            title: '导入',
            auth: authDict.sourcelist_import,
            showExport: true,
            onClick: () => {
              openModal({
                optType: 'import',
                businessType: BUSINESS_TYPE.sourceList,
              });
            },
          },
        ],
      },
    ],
  };
  /** 列表Tab */
  const tabList = [
    {
      tab: '货源清单视图',
      key: SOURCE_VIEW_TAB.source,
      component: (
        <SourceListView
          tableConfig={config}
          refreshMarker={refreshMarker}
          action={pageAction}
          bulkActivate={bulkActivate}
          bulkDeactivate={bulkDeactivate}
        />
      ),
    },
    {
      tab: '供应商行视图',
      key: SOURCE_VIEW_TAB.supplier,
      component: (
        <SupplierView
          tableConfig={config}
          refreshMarker={refreshMarker}
          action={pageAction}
          bulkActivate={bulkActivate}
          bulkDeactivate={bulkDeactivate}
        />
      ),
    },
  ];

  return (
    <>
      <TabList tabList={tabList} defaultKey={SOURCE_VIEW_TAB.supplier} />
      {/* 新增/编辑 */}
      {actionVisible && (
        <CreateAndEdit
          visible={actionVisible}
          refetch={refreshList}
          onClose={() => setActionVisible(false)}
          isEdit={isEdit}
          id={currentId}
        />
      )}
      {/* 查看详情 */}
      {detailVisible && currentId && (
        <DetailLayoutForDrawer
          visible={detailVisible}
          width={680}
          content={
            <SourceListDetail id={currentId} action={pageAction} refreshMarker={refreshMarker} />
          }
          onClose={() => setDetailVisible(false)}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={currentId as number}
          objectCode={OBJECT_OF_CODE.sourceList}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default SourceList;
