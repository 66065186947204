import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import _ from 'lodash';
import { DetailLayoutInfoBlock, DetailLayout } from 'layout';
import { Avatar, Badge, message, Tabs, Form } from 'antd';
import { avatarStyle } from 'src/page/organization/userManagement/constants';
import { fetchFileListByIds } from 'src/api/ytt/holyfile-domain';
import {
  // fetchResourcesDelete,
  fetchResourcesDetail,
  fetchResourcesStatusStop,
  fetchResourcesStatusScrap,
  fetchResourcesStatusEnable,
} from 'src/api/ytt/resource-domain/resource';
import { fetchCustomFieldInstanceGetByObjectCode } from 'src/api/ytt/custom-object-domain';
import DetailLayoutContent from 'src/layout/detail/components/DetailLayoutContent';
import { DetailLayoutInfoItem } from 'src/layout/detail/DetailLayout.type';
import { BcAttachmentDetail, BlIcon, SingleOperationLogComponent } from 'components';
import { avatarPictuer } from 'src/components/avatar/show';
import { injectCustomFieldInfos } from 'src/components/customField';
import TagTooltip from 'src/components/tooltip/tagTooltip';
import { gcTime } from 'src/utils';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import authDict from 'src/utils/auth';
import { replaceSign } from 'src/utils/constants';
import { lookup } from 'src/dict';
import { EquipmentBusinessType, EquipmentLevel } from 'src/dict/resources';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { RESORCE_STATUS_TYPE, RESORCE_STATUS_VALUE, STATUS_OPERATE } from '../constants';
import { goToCopy, goToEdit, goToCreate } from '../navigates';
import '../index.scss';
import EquipmentTagTable from './EquipmentTagTable';
import EquipmentParamsTable from './EquipmentParamsTable';
import { DetailType } from '../index.d';

interface DetailContentProps {
  id: string;
}

const DetailContent = (props: RouteComponentProps<DetailContentProps>) => {
  const [detailData, setDetailData] = useState({} as any);
  const { history, match } = props;
  const id = Number(match.params.id);
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [customFields, setCustomFields] = useState<any>();
  const [delForm] = Form.useForm();

  const fetchData = async () => {
    try {
      Promise.all([
        fetchCustomFieldInstanceGetByObjectCode({
          objectCode: OBJECT_OF_CODE.equipment,
        }),
        fetchResourcesDetail({ id }),
      ]).then(([customFieldRes, dataRes]) => {
        // 设置自定义字段信息
        if (customFieldRes) {
          const { data } = customFieldRes;

          setCustomFields(data);
        }

        if (dataRes) {
          const { data } = dataRes;

          setDetailData(data);
          getFileUrl(data?.coverFileId);
        }
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChangeStatus = (stauts: number, idList: number[]) => {
    if (stauts === RESORCE_STATUS_VALUE.ENABLED) {
      return fetchResourcesStatusStop({
        idList,
        status: RESORCE_STATUS_VALUE.DISABLED,
      });
    }
    return fetchResourcesStatusEnable({
      idList: [id],
      status: RESORCE_STATUS_VALUE.ENABLED,
    });
  };

  const baseMenu = () => {
    return _.compact([
      {
        key: 'copy',
        onClick: () => {
          goToCopy(id);
        },
        auth: authDict.equipment_add,
        icon: <BlIcon type="iconfuzhi" />,
        title: '复制',
      },
      // {
      //   key: 'delete',
      //   onClick: () => {
      //     fetchResourcesDelete({ idList: [detailData.id] }).then((res) => {
      //       if (res.code === 200) {
      //         message.success('删除成功');
      //         onCloseDetail();
      //         refreshTable();
      //       }
      //     });
      //   },
      //   title: '删除',
      //   disabled: true,
      // },
      !_.isNil(detailData?.status) &&
        detailData?.status !== RESORCE_STATUS_VALUE.SCRAP && {
          key: 'changeStatus',
          auth: authDict.equipment_enable_disable,
          onClick: () => {
            getChangeStatus(detailData?.status, [id]).then((res) => {
              if (res.code === 200) {
                message.success(
                  `${detailData?.status === RESORCE_STATUS_VALUE.ENABLED ? '停用' : '启用'}成功`,
                );
                fetchData();
              }
            });
          },
          title: STATUS_OPERATE.get(detailData.status)!,
        },
      {
        key: 'unUsed',
        title: '报废',
        auth: authDict.equipment_scrap,
        disabled: detailData.status === RESORCE_STATUS_VALUE.SCRAP,
        onClick: () => {
          BLDelConfirm('是否确认报废该设备？', delForm, () => {
            return fetchResourcesStatusScrap({
              idList: [id],
              status: RESORCE_STATUS_VALUE.SCRAP,
            }).then((res) => {
              if (res.code === 200) {
                fetchData();
              }
            });
          });
        },
      },
      detailData?.level !== EquipmentLevel.five && {
        key: 'subEquip',
        title: '新建子设备',
        auth: authDict.equipment_add,
        onClick: () => {
          goToCreate(id);
        },
      },
      {
        key: 'look',
        onClick: () => {
          setVisibleLog(true);
        },
        auth: authDict.equipment_operrecord,
        title: '操作记录',
      },
      {
        key: 'edit',
        onClick: () => {
          goToEdit(id);
        },
        auth: authDict.equipment_edit,
        type: 'primary',
        icon: <BlIcon type="iconbianji" />,
        title: '编辑',
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getFileUrl = async (fileId?: number) => {
    if (fileId) {
      const { data } = await fetchFileListByIds({ idList: [fileId] });

      setAvatarUrl(data?.[0]?.fileUri);
    }
  };

  const baseInfo = useCallback((): DetailLayoutInfoBlock => {
    const model: DetailLayoutInfoItem = {
      label: '品牌型号',
      dataIndex: 'brandModelMessage',
      render: (text: string) => text ?? replaceSign,
    };

    const baseItems: DetailLayoutInfoItem[] = [
      {
        label: '',
        dataIndex: 'coverFileId',
        render: () => (
          <div style={{ width: '100%' }}>
            {avatarPictuer(avatarUrl || '', <BlIcon type="iconzuzhijiagou" />)}
          </div>
        ),
      },
      { label: '设备编号', dataIndex: 'code' },
      { label: '设备名称', dataIndex: 'name', render: (text: string) => text ?? replaceSign },
      {
        label: '业务类型',
        dataIndex: 'businessType',
        render: (type: EquipmentBusinessType) =>
          lookup('resources.equipmentBusinessType', type) ?? replaceSign,
      },
      {
        label: '标识码',
        dataIndex: 'entityLinkCode',
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '设备分类',
        dataIndex: 'classificationList',
        render: (classificationList) => {
          return classificationList?.length
            ? classificationList
                ?.map((classification: { name: string }) => classification?.name)
                ?.join('/')
            : replaceSign;
        },
      },
      {
        label: '设备层级',
        dataIndex: 'level',
        render: (level: EquipmentLevel) => lookup('resources.equipmentLevel', level) ?? replaceSign,
      },
      {
        label: '父级设备',
        dataIndex: 'parentName',
        render: (text: string) => text || replaceSign,
      },
      {
        label: '子级设备',
        dataIndex: 'children',
        render: (children) =>
          children?.map((child: DetailType) => child?.name)?.join(',') ?? replaceSign,
      },
      {
        label: '供应商',
        dataIndex: ['supplierResDTO', 'name'],
        render: (text: string) => text || replaceSign,
      },
      { label: '序列号', dataIndex: 'serialNo', render: (text: string) => text ?? replaceSign },
      {
        label: '负责人',
        dataIndex: 'responsibleUser',
        render: (users: any[]) => <TagTooltip list={users || []} isUser max={1} />,
      },
      {
        label: '部门',
        dataIndex: 'responsibleDepartment',
        render: (departments: any[]) => (
          <TagTooltip list={departments || []} isUser={false} max={1} />
        ),
      },
      {
        label: '出厂日期',
        dataIndex: 'outFactoryDate',
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '入厂日期',
        dataIndex: 'enterFactoryDate',
        render: (text: string) => text ?? replaceSign,
      },
      { label: '启用日期', dataIndex: 'enableDate', render: (text: string) => text ?? replaceSign },
      { label: '存储单位', dataIndex: 'unitName', render: (text: string) => text ?? replaceSign },
      {
        label: '存储区域',
        dataIndex: 'locationList',
        render: (list: { name: string }[]) =>
          list?.map((location: { name: string }) => location.name).join('/') ?? replaceSign,
      },
      { label: '外部ID', dataIndex: 'externalNo', render: (text: string) => text ?? replaceSign },
      {
        label: '使用年限',
        dataIndex: 'useAge',
        render: (useAge: number, record: DetailType) => {
          return _.isNil(useAge)
            ? replaceSign
            : `${record?.useAge}${lookup('resources.useAgeUnit', record?.useAgeType)}`;
        },
      },
      {
        label: '计划报废日期',
        dataIndex: 'planScrapDate',
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '关联仓位',
        dataIndex: 'storageName',
        render: (text: string) => text ?? replaceSign,
      },
      {
        label: '附件',
        dataIndex: 'fileIdList',
        render: (fileIds: number[]) => <BcAttachmentDetail fileIds={fileIds} />,
        span: 2,
      },
      {
        label: '备注',
        dataIndex: 'remark',
        span: 2,
        render: (desc: string) => desc || replaceSign,
      },
    ];

    if (detailData.businessType === EquipmentBusinessType.weighting) {
      baseItems.splice(baseItems.length - 3, 0, model);
    }

    return {
      title: '基本信息',
      items: baseItems,
    };
  }, [detailData, avatarUrl]);

  const tagInfo: DetailLayoutInfoBlock = {
    title: '设备标签',
    items: [
      {
        dataIndex: 'tagsList',
        render: (tagsList) => <EquipmentTagTable dataSource={tagsList} />,
      },
    ],
  };

  const paramsInfo: DetailLayoutInfoBlock = {
    title: '设备参数',
    items: [
      {
        dataIndex: 'resourceParamsList',
        render: (resourceParamsList) => <EquipmentParamsTable dataSource={resourceParamsList} />,
      },
    ],
  };

  const stausInfo: DetailLayoutInfoBlock = {
    title: '状态信息',
    items: [
      {
        label: '启用状态',
        dataIndex: 'status',
        render: (status) => (
          <span>
            {status === RESORCE_STATUS_VALUE.ENABLED && <Badge status="success" />}
            {status === RESORCE_STATUS_VALUE.SCRAP && <Badge status="error" />}
            {status === RESORCE_STATUS_VALUE.DISABLED && <Badge status="default" />}
            {RESORCE_STATUS_TYPE.get(status)}
          </span>
        ),
      },
    ],
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'createUser',
        render: (creator: any) => {
          if (!creator) {
            return replaceSign;
          }
          return (
            <span>
              {creator?.avatarUrl ? (
                <Avatar size={24} src={creator?.avatarUrl} />
              ) : (
                <Avatar icon={<BlIcon type="icongeren" />} size={24} style={avatarStyle} />
              )}
              {creator?.name}
            </span>
          );
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createdTime',
        render: (time: number) => gcTime.formatLine(time),
      },
      {
        label: '更新人',
        dataIndex: 'operateUser',
        render: (operator: any) => {
          if (!operator) {
            return replaceSign;
          }
          return (
            <span>
              {operator?.avatarUrl ? (
                <Avatar size={24} src={operator?.avatarUrl} />
              ) : (
                <Avatar icon={<BlIcon type="icongeren" />} size={24} style={avatarStyle} />
              )}
              {operator?.name}
            </span>
          );
        },
      },
      {
        label: '更新时间',
        dataIndex: 'updatedTime',
        render: (time: number) => gcTime.formatLine(time),
      },
    ],
    column: 2,
  };

  // const bussinessInfo: DetailLayoutInfoBlock = {
  //   title: '业务信息',
  //   column: 1,
  //   items: [
  //     {
  //       label: '业务范围',
  //       dataIndex: 'businessSelectVOS',
  //       render: (businessSelectList: any[]) => {
  //         if (!businessSelectList) {
  //           return null;
  //         }
  //         return (
  //           <span>
  //             {businessSelectList?.map((business) => (
  //               <Checkbox disabled checked key={business.businessId}>
  //                 {business.businessName}
  //               </Checkbox>
  //             ))}
  //           </span>
  //         );
  //       },
  //     },
  //     {
  //       label: '',
  //       dataIndex: 'bussinessList',
  //       render: (bussinessList: number, record: any) => {
  //         if (!record.businessSelectVOS) {
  //           return null;
  //         }
  //         const tagList = _.flatMap(
  //           record.businessSelectVOS.map((businessSelect: any) => businessSelect.tagSelectVOList),
  //         );

  //         return (
  //           <div className="detailContentTab">
  //             <Tabs type="card">
  //               {tagList.map((tag: any) => (
  //                 <Tabs.TabPane tab={tag.businessTagName} key={tag.businessTagId}>
  //                   {tag.subBusinessSelectVOS?.map((subBussiness: any) => (
  //                     <span>
  //                       {`${subBussiness.subBusinessName}: ${subBussiness.configSelectVOList
  //                         .map((item: any) => item.subBusinessConfigName)
  //                         .join(',')}`}
  //                     </span>
  //                   ))}
  //                 </Tabs.TabPane>
  //               ))}
  //             </Tabs>
  //           </div>
  //         );
  //       },
  //     },
  //   ],
  // };

  const detailContent = () => (
    <div className="detailTab">
      <Tabs defaultActiveKey="1" type="card">
        <Tabs.TabPane tab="设备台账" key="1">
          <DetailLayoutContent
            info={_.compact([
              baseInfo(),
              customFields?.customFields?.length &&
                injectCustomFieldInfos({ customFields, type: 'detail' }),
              tagInfo,
              paramsInfo,
              stausInfo,
              otherInfo,
            ])}
            dataSource={detailData}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );

  return (
    <div style={{ background: history ? '#fff' : '' }}>
      <DetailLayout baseMenu={baseMenu()} title={'设备台账详情'} info={[]} dataSource={detailData}>
        {detailContent()}
      </DetailLayout>
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={id}
          objectCode={'Equipment'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </div>
  );
};

export default DetailContent;
