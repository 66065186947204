import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Row, Col, Card } from 'antd';
import { Dom, Graph } from '@antv/x6';
import '@antv/x6-react-shape';
import 'src/page/analyse/dataSetManagement/components/relationChart/basicLayout/customEdge';
import {
  getBasicConfig,
  baseEdgeInfo,
  getbaseNodeInfo,
} from 'src/page/analyse/dataSetManagement/components/relationChart/basicLayout/graphConfig';
import dagreLayout from '../components/relationChart/basicLayout/layout';
import lookup from 'src/dict';

const Show = ({ data }: { data: { nodes: any[]; edges: any[] } }) => {
  let graph: Graph | null = null;
  const ref = React.useRef(null);

  const layout = (_graph: Graph, data: any) => {
    _graph?.fromJSON(dagreLayout.layout(data));
    _graph?.centerContent();
  };
  const getGraphData = () => {
    const { nodes, edges } = data || {
      nodes: [],
      edges: [],
    };

    return {
      nodes: nodes?.map((node) => ({
        attrs: {
          label: {
            text: Dom.breakText(
              `${node?.displayName}${node?.rootObject === 0 ? '（根节点）' : ''}`,
              { width: 130, height: 45 },
            ),
          },
        },
        object: node,
        id: String(node.nodeUniqueValue),
        ...getbaseNodeInfo(false),
      })),
      edges: edges?.map((edge) => ({
        source: edge.preNodeUniqueValue.toString(),
        target: edge.postNodeUniqueValue.toString(),
        label:
          edge.refField?.[0]?.preRefFieldDisplayName +
          lookup('customReport.relationShip', edge.joinRelation),
        ...baseEdgeInfo,
      })),
    };
  };

  useEffect(() => {
    if (ref?.current) {
      const data = getGraphData();

      console.log(ref?.current?.getBoundingClientRect());
      graph = new Graph({
        ...getBasicConfig(false),
        container: ref?.current,
        width: ref?.current?.getBoundingClientRect().width,
      });
      layout(graph, data);
    }
  }, []);

  return (
    <div style={{ width: '100%', height: 500 }}>
      <div ref={ref} />
    </div>
  );
};

export default Show;
