import { useEffect, useState } from 'react';
import { message } from 'antd';
import _ from 'lodash';
import { RouteChildrenProps } from 'react-router';
import { appEnum } from 'src/dict';
import {
  fetchWorkOrderCreate,
  fetchWorkOrderBaseGet,
  fetchWorkOrderOutputGet,
  fetchWorkOrderInputMaterialGet,
  fetchWorkOrderProcessPlanGet,
  fetchWorkOrderSourceGet,
  fetchWorkOrderUpdate,
  fetchWorkOrderBatchUpdateDraftFlag,
} from 'src/api/ytt/med-domain/work_order';
import BaseForm from './baseForm';
import { handleBatchMessage } from '../list/productionOrderList';

interface CreateAndEditPorps extends RouteChildrenProps<{ id: string }> {}

const CreateAndEdit = (props: CreateAndEditPorps) => {
  const { history, location, match } = props;

  const [loading, setLoading] = useState(true);
  const [initialValue, setInitialValue] = useState<any>();

  const [type, setType] = useState(appEnum.Common.CRUD.create);

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      setType(appEnum.Common.CRUD.edit);
      if (match?.params?.id) {
        fetchAndSetData(_.toNumber(match?.params?.id));
      }
    } else if (location.pathname.includes('copy')) {
      setType(appEnum.Common.CRUD.copy);
      if (match?.params?.id) {
        fetchAndSetData(_.toNumber(match?.params?.id));
      }
    } else {
      setType(appEnum.Common.CRUD.create);
      setLoading(false);
    }
  }, []);

  const fetchAndSetData = (id?: number) => {
    if (id) {
      setLoading(true);
      const workOrderBaseGet = new Promise((resolve) => {
        fetchWorkOrderBaseGet({ workOrderId: id }).then((res) => {
          const { data } = res;

          resolve(data);
        });
      });

      const workOrderOutputGet = new Promise((resolve) => {
        fetchWorkOrderOutputGet({ workOrderId: id }).then((res) => {
          const { data } = res;

          resolve(data);
        });
      });
      const workOrderInputMaterialGet = new Promise((resolve) => {
        fetchWorkOrderInputMaterialGet({ workOrderId: id }).then((res) => {
          const { data } = res;

          resolve(data);
        });
      });
      const workOrderProcessPlanGet = new Promise((resolve) => {
        fetchWorkOrderProcessPlanGet({ workOrderId: id }).then((res) => {
          const { data } = res;

          resolve(data);
        });
      });

      const WorkOrderSourceGet = new Promise((resolve) => {
        fetchWorkOrderSourceGet({ workOrderId: _.toNumber(id) }).then((res) => {
          const { data } = res;

          resolve(data);
        });
      });

      return Promise.all([
        workOrderBaseGet,
        workOrderOutputGet,
        workOrderInputMaterialGet,
        workOrderProcessPlanGet,
        WorkOrderSourceGet,
      ])
        .then((res) => {
          const dataSourceList = {
            workOrderBaseCO: res[0],
            bomOutputMaterials: res[1],
            bomInputMaterials: res[2],
            processPlanCO: res[3],
            workOrderSource: res[4],
          };

          setInitialValue(dataSourceList);
          return res;
        })
        .catch((err) => {
          console.log('err: ', err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return Promise.reject();
  };

  const handleCreate = (value: any, cb?: () => void) => {
    console.log('%c [ create value ]', 'font-size:px; color:#bf2c9f;', value);
    fetchWorkOrderCreate(value).then((res) => {
      console.log('[ res==>>> ]', res);
      message.success('创建成功!');

      cb?.();
    });
  };

  const handleEdit = (value: any, cb?: () => void, draftSubmitFlag?: boolean) => {
    setLoading(true);
    fetchWorkOrderUpdate(value)
      // @ts-ignore
      .then(() => {
        if (!draftSubmitFlag) return Promise.resolve();
        return fetchWorkOrderBatchUpdateDraftFlag({
          idList: [value?.workOrderBaseCO?.id],
        });
      })
      .then((res) => {
        const { failAmount = 0 } = res?.data ?? {};

        if (failAmount) {
          setLoading(false);
          handleBatchMessage(`编辑${draftSubmitFlag ? '并提交' : ''}`, {
            ...res.data,
            successAmount: res.data?.successCount ?? 0,
          });
          return;
        }

        message.success(`编辑${draftSubmitFlag ? '并提交' : ''}成功!`);
        // 自定义字段异步更新，需要延迟操作
        setTimeout(() => {
          history.goBack();
          setLoading(false);
        }, 1500);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <BaseForm
      loading={loading}
      type={type}
      history={history}
      initialValue={initialValue}
      onSubmit={(value, cb, draftSubmitFlag) => {
        if (type === appEnum.Common.CRUD.create) handleCreate(value, cb);
        if (type === appEnum.Common.CRUD.edit) handleEdit(value, cb, draftSubmitFlag);
        if (type === appEnum.Common.CRUD.copy) handleCreate(value, cb);
      }}
      match={match}
      location={location}
    />
  );
};

export default CreateAndEdit;
