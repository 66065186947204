import { Tag } from 'antd';
import _ from 'lodash';
import { renderUser } from 'src/page/share/renderUser';
import lookup from 'src/dict';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { fieldTypeList } from 'utils';
import { FilterItem } from 'src/layout';
import { SearchSelect, TagList } from 'src/components';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import {
  purchaseExecStatus,
  purchaseSource,
  purchaseOrderdeliveryMode,
  purchaseOrderMaterialCarryMode,
} from 'src/dict/purchase/mappings';
import { renderAmount, renderCurrency } from './utils';
import { replaceSign } from 'src/utils/constants';

// 采购订单视图列左半部分
const ordersListLeft = [
  {
    title: '供应商编号',
    dataIndex: ['supplier', 'code'],
    width: 150,
  },
  {
    title: '供应商名称',
    dataIndex: ['supplier', 'name'],
    width: 150,
  },
  {
    title: '订单所有人',
    dataIndex: 'owner',
    width: 200,
    render: renderUser,
  },
  {
    title: '来源',
    dataIndex: 'source',
    width: 150,
    render: lookup('purchase.purchaseSource'),
  },

  {
    title: '状态',
    dataIndex: 'execStatus',
    width: 150,
    render: lookup('purchase.purchaseExecStatus'),
  },
  {
    title: '带料方式',
    dataIndex: 'materialCarryMode',
    width: 150,
    render: lookup('purchase.purchaseOrderMaterialCarryMode'),
  },
  {
    title: '交货方式',
    dataIndex: 'deliveryMode',
    width: 150,
    render: lookup('purchase.purchaseOrderdeliveryMode'),
  },
];

// 采购订单视图列右半部分
const ordersListRight = [
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    width: 150,
    sorter: true,
    render: formatTimeForRender,
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    width: 150,
    render: renderUser,
  },
  {
    title: '更新时间',
    dataIndex: 'updatedAt',
    width: 150,
    sorter: true,
    render: formatTimeForRender,
  },
  {
    title: '更新人',
    dataIndex: 'operator',
    width: 150,
    render: renderUser,
  },
];
const ordersListMedium = [
  {
    title: '币种',
    dataIndex: 'currency',
    width: 150,
    render: renderCurrency,
  },
];
// 物料行视图列中间部分
const materialsListMedium = [
  {
    title: '行号',
    dataIndex: 'seqNum',
    width: 150,
  },
  {
    title: '物料编号',
    dataIndex: ['material', 'baseInfo', 'code'],
    width: 150,
  },
  {
    title: '物料名称',
    dataIndex: ['material', 'baseInfo', 'name'],
    width: 150,
  },
  {
    title: '物料属性',
    dataIndex: ['material', 'attribute'],
    width: 150,
    render: (value: any) => {
      if (_.isEmpty(value)) return replaceSign;
      return (
        <TagList
          dataSource={_.map(value, ({ name, attributeItem }) => ({
            label: `${name}:${attributeItem.content}`,
            value: attributeItem.content,
          }))}
        />
      );
    },
  },
  {
    title: '需求数',
    dataIndex: 'demandAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '需求时间',
    dataIndex: 'demandTime',
    width: 150,
    sorter: true,
    render: formatTimeForRender,
  },
  {
    title: '计划交货数',
    dataIndex: 'planDeliveryAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '计划收货数',
    dataIndex: 'planReceivedAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '收货发出数',
    dataIndex: 'supplierDeliverAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '收货接收数',
    dataIndex: 'receivedAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '计划退货数',
    dataIndex: 'planReturnAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '退货发出数',
    dataIndex: 'returnDeliverAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '退货接收数',
    dataIndex: 'returnReceivedAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '含税单价',
    dataIndex: 'unitPrice',
    width: 150,
    render: (unitPrice: any) => {
      return unitPrice ? unitPrice?.priceDisplay : replaceSign;
    },
  },
  {
    title: '含税金额',
    dataIndex: 'totalPrice',
    width: 150,
    render: (totalPrice: any) => {
      return totalPrice ? totalPrice?.priceDisplay : replaceSign;
    },
  },
  {
    title: '币种',
    dataIndex: 'currency',
    width: 150,
    render: renderCurrency,
  },
  {
    title: '税率',
    dataIndex: 'taxRate',
    width: 150,
    render: (taxRate: any) => {
      return taxRate ? taxRate + '%' : replaceSign;
    },
  },
];

// 采购订单视图展示列
export const dataColumnsForOrdersList = [
  ...ordersListLeft,
  ...ordersListMedium,
  ...ordersListRight,
];

// 物料行视图展示列
export const dataColumnsForMaterialList = [
  ...ordersListLeft.slice(0, 6),
  ...materialsListMedium,
  ...ordersListRight,
];

// 采购订单视图筛选列
export const ordersFilterList: FilterItem[] = [
  {
    label: '编号',
    name: 'code',
    type: fieldTypeList.text,
  },
  {
    label: '供应商',
    name: 'supplier',
    type: fieldTypeList.multiSelect,
    renderItem: <SearchSelect fetchType={'supplierWithCode'} mode={'multiple'} />,
  },
  {
    label: '订单所有人',
    name: 'owner',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <UserOrDepartmentSelectWithDialog placeholder={'请选择订单所有人'} isMultiple isSelectUser />
    ),
  },
  {
    label: '来源',
    name: 'sourceList',
    type: fieldTypeList.multiSelect,
    selectProps: {
      options: purchaseSource,
      mode: 'multiple',
    },
  },
  {
    label: '交货方式',
    name: 'deliveryModeList',
    type: fieldTypeList.multiSelect,
    selectProps: {
      options: purchaseOrderdeliveryMode,
      mode: 'multiple',
    },
  },
  {
    label: '状态',
    name: 'execStatusList',
    type: fieldTypeList.multiSelect,
    selectProps: {
      options: purchaseExecStatus,
      mode: 'multiple',
    },
  },
  {
    label: '带料方式',
    name: 'materialCarryModeList',
    type: fieldTypeList.multiSelect,
    selectProps: {
      options: purchaseOrderMaterialCarryMode,
      mode: 'multiple',
    },
  },
  {
    label: '创建时间',
    name: 'createdAt',
    type: fieldTypeList.date,
  },
  {
    label: '创建人',
    name: 'creator',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <UserOrDepartmentSelectWithDialog placeholder={'请选择订单创建人'} isMultiple isSelectUser />
    ),
  },
  {
    label: '更新时间',
    name: 'updatedAt',
    type: fieldTypeList.date,
  },
  {
    label: '更新人',
    name: 'operator',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <UserOrDepartmentSelectWithDialog placeholder={'请选择更新人'} isMultiple isSelectUser />
    ),
  },
];

// 物料行视图筛选列
const cloneFilterList = _.cloneDeep(ordersFilterList);

cloneFilterList.splice(
  7,
  0,
  {
    label: '物料',
    name: 'material',
    type: fieldTypeList.multiSelect,
    renderItem: (
      <SearchSelect placeholder={'请选择物料'} mode="multiple" fetchType="materialCodeName" />
    ),
  },
  {
    label: '需求时间',
    name: 'demandTime',
    type: fieldTypeList.date,
  },
);
export const materialsFilterList: FilterItem[] = cloneFilterList;
