import { appEnum } from 'src/dict';

// BOM清单其他信息tab
export enum ProductionOrderTab {
  'SUB_ITEM_MATERIAL' = 1, // 子项物料
  'COPRODUCT', // 产出物料
  'PROCESS_PLAN', // 工序信息
  'SOURCE', // 来源信息
  'INTERMEDIATE_MATERIAL', // 中间品
  'OTHER', // 其他
}

// 工单字段转化
export const FIELD_MAP = new Map([
  ['workOrderCode', 'workOrderCode'],
  ['workOrderStatus', 'workOrderStatus'],
  ['plannedStartTime', 'plannedStartTime'],
  ['plannedEndTime', 'plannedEndTime'],
  ['createdAt', 'createdAt'],
]);

export const ProductionOrderTabMap = new Map([
  [
    ProductionOrderTab.COPRODUCT,
    { value: ProductionOrderTab.COPRODUCT, title: '产出物料', filedName: 'bomOutputMaterials' },
  ],
  [
    ProductionOrderTab.SUB_ITEM_MATERIAL,
    {
      value: ProductionOrderTab.SUB_ITEM_MATERIAL,
      title: '用料清单',
      filedName: 'bomInputMaterials',
    },
  ],
  [
    ProductionOrderTab.PROCESS_PLAN,
    { value: ProductionOrderTab.PROCESS_PLAN, title: '工序计划', filedName: 'processPlanCO' },
  ],
  [
    ProductionOrderTab.SOURCE,
    { value: ProductionOrderTab.SOURCE, title: '来源信息', filedName: 'workOrderSourceCO' },
  ],
  // [
  //   BomTab.INTERMEDIATE_MATERIAL,
  //   { value: BomTab.INTERMEDIATE_MATERIAL, title: '中间品', filedName: 'intermediateMaterial' },
  // ],
  [
    ProductionOrderTab.OTHER,
    { value: ProductionOrderTab.OTHER, title: '其他信息', filedName: 'other' },
  ],
]);

/**
 * BOM启用状态颜色
 */
export const BomActiveColorMap = new Map([
  [appEnum.Common.YN.yes, 'green'],
  [appEnum.Common.YN.no, 'red'],
]);
