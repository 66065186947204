/**
 * @enum 工艺路线业务模块枚举值
 */
/**
 * 启用
 */
export enum YN {
  /**
   * 否
   */
  no = 0,
  /**
   * 是
   */
  yes,
}
/**
 * 表单类型
 */
export enum clickTypeEnum {
  /**
   * 工序
   */
  node = 1,
  /**
   * 工序接续
   */
  edge,
}

/**
 * 计划报工数量管控
 */
export enum PlanTypeEnum {
  /**
   * 强管控
   */
  strong = 1,
  /**
   * 弱管控
   */
  week,
  /**
   * 不管控
   */
  no,
}

/**
 * 生产任务状态操作管控
 */
export enum ProduceTypeEnum {
  /**
   * 管控
   */
  yes = 1,
  /**
   * 不管控
   */
  no,
}

/**
 * 报工方式
 */
export enum reportingMethodEnum {
  /**
   * 扫码报工
   */
  scan = 1,
  /**
   * 记录报工
   */
  note,
}

/**
 * 报工方式
 */
export enum FeedingQualityEnum {
  /**
   * 合格
   */
  qualified = 0,
  /**
   * 不合格
   */
  unqualified,
  /**
   * 让步合格
   */
  concession,
  /**
   * 待检
   */
  toBeInspected,
}

/**
 * 投料上下限类型
 */
export enum feedingLimitEnum {
  /**
   * 不管控
   */
  no = 1,
  /**
   * 比例
   */
  scale,
  /**
   * 固定值
   */
  fixed,
}

/**
 * 报工方式
 */
export enum reportingMethodsEnum {
  /**
   * 扫码报工-合格
   */
  qualifiedScan = 1,
  /**
   * 记录报工-合格
   */
  qualifiedNote,
  /**
   * 扫码报工-不合格
   */
  unqualifiedScan,
  /**
   * 记录报工-不合格
   */
  unqualifiedNote,
}
