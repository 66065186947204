const FineBIView = () => {
  const url =
    'https://bi-test.blacklake.cn/webroot/decision/login?origin=0a7c499a-c374-4099-a407-23eca61e40cf';

  return (
    <div
      id="FineBIIframe"
      style={{
        width: '100%',
        height: '100%',
        margin: '0 auto',
        transition: 'all 0.5s',
      }}
    >
      <iframe width="100%" height="100%" src={url} />
    </div>
  );
};

export default FineBIView;
