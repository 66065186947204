import { DetailLayout } from 'layout';
import { Image } from 'antd';
import styles from './style.module.scss';

export default function AqlDetail() {
  return (
    <DetailLayout title="国标AQL" dataSource={{}}>
      <div className={styles.inner}>
        <div className={styles.standard}>国标文件号：GB2828-2012</div>
        <div className={styles.box}>
          <div>
            {/* <div>样本量字码表：</div> */}
            <Image
              alt="样本量字码表"
              src="https://web3file.blacklake.cn/aql/%E6%A0%B7%E6%9C%AC%E9%87%8F%E5%AD%97%E7%A0%81.png"
            />
          </div>
          <div>
            {/* <div>样本量字码表：加严检验一次抽样方案（主表）</div> */}
            <Image
              alt="加严检验一次抽样方案"
              src="https://web3file.blacklake.cn/aql/%E6%A0%B7%E6%9C%AC%E9%87%8F%E5%AD%97%E7%A0%81%E8%A1%A8%EF%BC%9A%E5%8A%A0%E4%B8%A5%E6%A3%80%E9%AA%8C%E4%B8%80%E6%AC%A1%E6%8A%BD%E6%A0%B7%E6%96%B9%E6%A1%88%EF%BC%88%E4%B8%BB%E8%A1%A8%EF%BC%89.png"
            />
          </div>
          <div>
            {/* <div>样本量字码表：正常检验一次抽样方案（主表）</div> */}
            <Image
              alt="正常检验一次抽样方案"
              src="https://web3file.blacklake.cn/aql/%E6%A0%B7%E6%9C%AC%E9%87%8F%E5%AD%97%E7%A0%81%E8%A1%A8%EF%BC%9A%E6%AD%A3%E5%B8%B8%E6%A3%80%E9%AA%8C%E4%B8%80%E6%AC%A1%E6%8A%BD%E6%A0%B7%E6%96%B9%E6%A1%88%EF%BC%88%E4%B8%BB%E8%A1%A8%EF%BC%89.png"
            />
          </div>
          <div>
            {/* <div>样本量字码表：放宽检验一次抽样方案（主表）</div> */}
            <Image
              alt="放宽检验一次抽样方案"
              src="https://web3file.blacklake.cn/aql/%E6%A0%B7%E6%9C%AC%E9%87%8F%E5%AD%97%E7%A0%81%E8%A1%A8%EF%BC%9A%E6%94%BE%E5%AE%BD%E6%A3%80%E9%AA%8C%E4%B8%80%E6%AC%A1%E6%8A%BD%E6%A0%B7%E6%96%B9%E6%A1%88%EF%BC%88%E4%B8%BB%E8%A1%A8%EF%BC%89.png"
            />
          </div>
        </div>
      </div>
    </DetailLayout>
  );
}
