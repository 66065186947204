import React, { useState, useEffect, useRef } from 'react';
import { RouterProps } from 'react-router-dom';
import _ from 'lodash';
import { Form, Checkbox, Input, Tabs, InputNumber, Badge, Select, Radio, Space } from 'antd';
import { MaterialSelect, SearchSelect, TagList } from 'src/components';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';
import lookup, { appDict, appEnum } from 'src/dict';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import {
  getMaterialUnits,
  getMaterialAttrs,
  judgeMaterialIsVirtual,
  getMaterialUnitInfo,
  getMaterialProduceUnit,
} from 'src/entity/material';
import { MaterialEntity } from 'src/entity/material/index.type';
import {
  fetchProcessRouteDetail,
  FetchProcessRouteDetailResponse,
} from 'src/api/ytt/med-domain/process-route';
import { LabeledValue } from 'antd/lib/select';
import {
  fractionLengthCheck,
  numberAlphabetSpecialSymbols,
  numberMinMaxCheck,
} from 'src/utils/formValidators';
import { ICustomFields } from 'src/components/customField/interface';
//
import { BomTab, BomTabMap } from '../constants';
import { formatMaterialToApi, formatMaterialToForm, setAssociatedFormFields } from '../utils';
import { BomForm, BomFormMaterial, BomRequest, BomResponse } from '../index.type';
import {
  SubItemMaterialForm,
  formatBomInputMaterialsToApi,
  formatBomInputMaterialsToForm,
} from './subItemMaterialForm';
import {
  CoproductForm,
  formatBomOutputMaterialsToApi,
  formatBomOutputMaterialsToForm,
} from './coproductForm';
import { OtherInfoForm, formatOtherToApi, formatOtherToForm } from './otherInfoForm';
import IntermediateMaterialForm from './intermediateMaterialForm';
import { formatCustomFieldsInData, initCustomFieldsInData } from 'src/components/customField';
import { toBomList } from '../navigation';

interface BomFormProps extends RouterProps {
  loading: boolean;
  type: string;
  onSubmit?: (value: any, cb?: () => void) => void;
  initialValue?: BomResponse;
  bomCustomFields?: ICustomFields;
}

interface TabList {
  key: BomTab;
  errorCount: number;
}

const { TabPane } = Tabs;

/**
 * 格式化数据给接口
 * @param value
 * @returns
 */
const formatValueToApi = (
  value: BomForm,
  prossessRouteDetail?: FetchProcessRouteDetailResponse['data'],
): BomRequest => {
  const {
    other,
    materialId,
    productRate = '100',
    bomOutputMaterials,
    bomInputMaterials,
    ownedDepartmentId,
    manufactureDepartmentId,
    defaultVersion = appEnum.Common.YN.no,
    unitId = 0,
    version = '',
    virtual = appEnum.Common.YN.no,
    processRouteId,
    workReportProcessId,
    workReportSopControlId,
    ...resValue
  } = value;

  return {
    ...resValue,
    ...formatOtherToApi(other),
    unitId,
    version,
    virtual,
    productRate,
    defaultVersion,
    processRouteId: _.toNumber(processRouteId?.value),
    workReportProcessId: _.toNumber(workReportProcessId?.value),
    workReportSopControlCode: _.toString(workReportSopControlId?.value),
    ownedDepartmentId: _.head(ownedDepartmentId)?.value,
    manufactureDepartmentId: _.head(manufactureDepartmentId)?.value,
    materialId: formatMaterialToApi(materialId),
    bomInputMaterials: formatBomInputMaterialsToApi(bomInputMaterials, prossessRouteDetail),
    bomOutputMaterials: formatBomOutputMaterialsToApi(bomOutputMaterials),
  };
};

/**
 * 格式化数据给form
 * @param value
 * @returns
 */
const formatValueToForm = (value?: BomResponse): BomForm | undefined => {
  if (_.isEmpty(value)) return undefined;

  const {
    material,
    bomInputMaterials,
    bomOutputMaterials,
    ownedDepartmentId,
    ownedDepartmentName,
    manufactureDepartmentId,
    manufactureDepartmentName,
    processRouteSimpleVO,
    workReportProcessRouteNodeSimpleVO,
    workReportSopControlDTO,
    ...resValue
  } = value ?? {};

  return {
    ...resValue,
    ownedDepartmentId: ownedDepartmentId
      ? [{ label: ownedDepartmentName, value: ownedDepartmentId }]
      : [],
    manufactureDepartmentId: manufactureDepartmentId
      ? [{ label: manufactureDepartmentName, value: manufactureDepartmentId }]
      : [],
    materialId: formatMaterialToForm(material),
    processRouteId: processRouteSimpleVO?.id
      ? { label: processRouteSimpleVO?.name, value: processRouteSimpleVO?.id }
      : undefined,
    workReportProcessId: workReportProcessRouteNodeSimpleVO?.id
      ? {
          label: workReportProcessRouteNodeSimpleVO?.processNum,
          value: workReportProcessRouteNodeSimpleVO?.id,
        }
      : undefined,
    workReportSopControlId: workReportSopControlDTO?.id
      ? {
          label: workReportSopControlDTO?.code,
          value: workReportSopControlDTO?.code,
        }
      : undefined,
    bomInputMaterials: bomInputMaterials && formatBomInputMaterialsToForm(bomInputMaterials),
    bomOutputMaterials: bomOutputMaterials && formatBomOutputMaterialsToForm(bomOutputMaterials),
    other: formatOtherToForm(resValue),
  };
};

const BaseForm = (props: BomFormProps) => {
  const { loading, type, history, onSubmit, initialValue, bomCustomFields } = props;

  const [keepCreate, setKeepCreate] = useState(false);
  const [tabList, setTabList] = useState<TabList[]>(
    Array.from(BomTabMap.keys()).map((n) => ({ key: n, errorCount: 0 })), // tab列表和form错误数
  );
  const [activeTab, setActiveTab] = useState(
    _.head(_.map(Array.from(BomTabMap.keys()), _.toString)),
  ); // 选中的tab
  const [isVirtualMaterial, setIsVirtualMaterial] = useState(false); // 父项物料业务范围是否是虚拟件

  const [form] = Form.useForm<BomForm>();
  const { getFieldValue, setFieldsValue } = form;

  const formProps = {
    preserve: true,
  };

  const selectProssessRouteRef = useRef<FetchProcessRouteDetailResponse['data']>(); // 选中的工艺路线详情

  useEffect(() => {
    if (initialValue) {
      const afterFormatInitialValue = formatValueToForm(initialValue) ?? {};

      // 把需要格式化的数据一次传入 initCustomFieldsInData 中，格式化内所有自定义字段，也可分步处理
      const afterFormatValue = initCustomFieldsInData(afterFormatInitialValue);

      setIsVirtualMaterial(judgeMaterialIsVirtual(initialValue?.material));

      form.setFieldsValue(afterFormatValue);

      getProcessRouteProcesses(afterFormatValue.processRouteId?.value).then(setLastProcess);
    }
  }, [initialValue, type]);

  const getDefaultValue = (fieldStr: string, defaultValue: any) => {
    const hasValue = !_.isNil(form.getFieldValue([fieldStr]));

    if (hasValue) return undefined;

    return defaultValue;
  };

  /**
   * 获取父项物料其他信息
   */
  const getMaterialInfo = (key?: keyof MaterialEntity, value?: BomFormMaterial) => {
    let material = {};

    if (value) {
      material = JSON.parse(value?.value ?? '{}');
    } else {
      material = JSON.parse(getFieldValue('materialId')?.value ?? '{}');
    }

    if (key) {
      return _.get(material, key);
    }

    return material;
  };

  /**
   * 获取工艺路线上所有的工序
   * @param processRouteId
   */
  const getProcessRouteProcesses = (processRouteId?: number | string) => {
    if (_.isNil(processRouteId)) return Promise.resolve([]);
    return fetchProcessRouteDetail({ id: _.toNumber(processRouteId) }).then((res) => {
      selectProssessRouteRef.current = res?.data;

      const processes = res?.data?.processes ?? [];

      return processes;
    });
  };

  /**
   * 设置父项物料报工工序为工艺路线最后一道工序
   * @param processes
   */
  const setLastProcess = (
    processes: NonNullable<FetchProcessRouteDetailResponse['data']>['processes'],
  ) => {
    const lastProcess = _.last(processes);
    const currentLaseProcess = form.getFieldValue('workReportProcessId')?.value;

    if (lastProcess?.id && currentLaseProcess !== lastProcess?.id) {
      form.setFieldsValue({
        workReportProcessId: {
          label: lastProcess.processNum,
          value: lastProcess.id,
        },
        workReportSopControlId: undefined,
      });
    }
  };

  /**
   * 重置工艺路线相关
   */
  const resetProssessRouteAbout = (resetSelf?: boolean) => {
    // 子项物料
    const subItemMaterialForm = _.cloneDeep(
      form.getFieldValue(BomTabMap.get(BomTab.SUB_ITEM_MATERIAL)?.filedName ?? ''),
    );
    // 多产出物料
    const coproductForm = _.cloneDeep(
      form.getFieldValue(BomTabMap.get(BomTab.COPRODUCT)?.filedName ?? ''),
    );

    // 工艺路线改变需要重置的字段 1.父项物料报工工序号 2.报工控件编号 3.子项物料的投料工序 4.投料管控投料控件 5.多产出物料的报工工序 6.多产出物料报工控件
    let resetFormObj = setAssociatedFormFields({}, [
      ['workReportProcessId', undefined],
      ['workReportSopControlId', undefined],
      [
        BomTabMap.get(BomTab.SUB_ITEM_MATERIAL)?.filedName ?? '',
        setAssociatedFormFields(subItemMaterialForm, [
          ['inputProcessId', undefined],
          [
            'bomFeedingControls',
            (value: any) => setAssociatedFormFields(value, [['inputSopControlId']]),
          ],
        ]),
      ],
      [
        BomTabMap.get(BomTab.COPRODUCT)?.filedName ?? '',
        setAssociatedFormFields(coproductForm, [
          ['outputProcessId', undefined],
          ['outputSopControlId', undefined],
        ]),
      ],
    ]);

    if (resetSelf) {
      resetFormObj = setAssociatedFormFields(resetFormObj, [['processRouteId', undefined]]);
    }

    setFieldsValue(resetFormObj);
  };

  /**
   * 处理表单校验错误
   * @param error 不传时重置Tab错误数量
   */
  const handleFormError = (error?: unknown) => {
    const errorFields: { name: (string | number)[] }[] = _.get(error, 'errorFields', [
      { name: [] },
    ]);

    const newTabList: TabList[] = [];

    tabList.map(({ key }) => {
      let errorCount = 0;
      const tabField = BomTabMap.get(key)?.filedName ?? '';

      if (!_.isEmpty(errorFields)) {
        _.unionBy([...errorFields], (item) => {
          if (_.find(item.name, (n) => _.isEqual(n, tabField))) errorCount++;
        });
      }

      newTabList.push({ key, errorCount });
    });

    setTabList(newTabList);
    setTimeout(form.validateFields);
  };

  /**
   * tab页切换
   */
  const handleTabChange = (acticeKey: string) => {
    setActiveTab(acticeKey);
    const { errorCount } = _.find(tabList, ['key', Number(acticeKey)]) ?? {};

    !!errorCount && setTimeout(form.validateFields);
  };

  const handleFinish = async () => {
    try {
      const value = formatCustomFieldsInData({
        data: formatValueToApi(await form?.validateFields()),
        customFields: bomCustomFields,
      });

      if (typeof onSubmit === 'function') {
        onSubmit(value, () => {
          if (keepCreate) {
            form.resetFields();
            selectProssessRouteRef.current = undefined; // 置空选择工艺路线
          } else if (type === appEnum.Common.CRUD.edit) {
            history.goBack();
          } else {
            history.push(toBomList());
          }
        });
      }

      handleFormError();
    } catch (error) {
      handleFormError(error);
    }
  };

  /**
   * 父项物料变动
   * @param value
   */
  const handleMaterialChange = (value: any) => {
    const materialInfo = getMaterialInfo(undefined, value);

    const unit = getMaterialProduceUnit(materialInfo, true);

    const currentMaterialIsVirtualPart = judgeMaterialIsVirtual(materialInfo);

    setIsVirtualMaterial(currentMaterialIsVirtualPart);

    setFieldsValue({
      unitId: unit?.value ? _.toNumber(unit?.value) : undefined,
      virtual: currentMaterialIsVirtualPart ? appEnum.Common.YN.yes : appEnum.Common.YN.no,
    });

    // 重置工艺路线相关
    resetProssessRouteAbout(true);
  };

  /**
   * 工艺路线变动
   * @param value
   */
  const handleProcessRouteChange = (value: LabeledValue) => {
    if (value?.value) {
      getProcessRouteProcesses(value?.value)
        .then((processes) => {
          resetProssessRouteAbout();

          setLastProcess(processes);
        })
        .catch(() => {
          resetProssessRouteAbout(true);
        });
    } else {
      resetProssessRouteAbout();
    }
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    items: _.compact([
      type === appEnum.Common.CRUD.edit && {
        label: 'id',
        name: 'id',
        hidden: true,
        render: () => <Input />,
      },
      {
        label: '父项物料编号',
        name: 'materialId',
        rules: [{ required: true, message: '父项物料不能为空' }],
        render: () => (
          <MaterialSelect
            placeholder="请输入"
            labelInValue
            params={{
              enableFlag: appEnum.Common.UsageStatus.enabled,
              bizType: [appEnum.Material.BizType.default, appEnum.Material.BizType.phantom],
            }}
            onChange={handleMaterialChange}
          />
        ),
      },
      {
        label: '父项物料名称',
        dependencies: ['materialId'],
        render: () => () => {
          const { name } = getMaterialInfo('baseInfo') ?? {};

          return <Input value={name} disabled />;
        },
      },
      {
        label: '物料分类',
        dependencies: ['materialId'],
        render: () => () => {
          const value = _.get(getMaterialInfo('category'), 'name');

          return <Input value={value} disabled />;
        },
      },
      {
        label: '物料属性',
        dependencies: ['materialId'],
        render: () => () => <TagList dataSource={getMaterialAttrs(getMaterialInfo())} />,
      },
      {
        label: '物料规格',
        dependencies: ['materialId'],
        width: 150,
        render: () => () => {
          const { specification } = getMaterialInfo('baseInfo') ?? {};

          return <Input value={specification} disabled />;
        },
      },
      {
        label: '虚拟件',
        name: 'virtual',
        dependencies: ['materialId'],
        initialValue: getDefaultValue(
          'virtual',
          isVirtualMaterial ? appEnum.Common.YN.yes : appEnum.Common.YN.no,
        ),
        render: () => <Radio.Group disabled options={appDict.common.yn} />,
      },
      {
        label: '单位',
        dependencies: ['materialId'],
        render: () => () => {
          return (
            <Form.Item style={{ marginBottom: 0 }} name="unitId">
              <Select placeholder={'请选择'} options={getMaterialUnits(getMaterialInfo())} />
            </Form.Item>
          );
        },
      },
      {
        label: '成品率',
        render: () => {
          return (
            <Space>
              <Form.Item
                style={{ marginBottom: 0 }}
                name="productRate"
                initialValue={getDefaultValue('productRate', 100)}
                rules={[
                  {
                    validator: numberMinMaxCheck({
                      min: 0,
                      minAllowEqual: false,
                      max: 1000,
                      fieldName: '成品率',
                    }),
                  },
                  { validator: fractionLengthCheck(4) },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={'请输入'}
                  max={1000}
                  min={0.0001}
                  precision={4}
                />
              </Form.Item>
              <span> % </span>
            </Space>
          );
        },
      },
      {
        label: '版本号',
        name: 'version',
        rules: [{ required: true, message: '版本号不能为空' }, numberAlphabetSpecialSymbols],
        render: () => <Input placeholder={'请选择'} maxLength={225} />,
      },
      {
        label: '状态',
        name: 'active',
        initialValue: getDefaultValue('active', appEnum.Common.UsageStatus.disabled),
        render: () => <Radio.Group disabled options={appDict.common.usageStatus} />,
      },
      {
        label: '默认版本',
        name: 'defaultVersion',
        initialValue: getDefaultValue('defaultVersion', appEnum.Common.YN.yes),
        render: () => <Radio.Group options={appDict.common.yn} />,
      },
      {
        label: '工艺路线',
        dependencies: ['materialId'],
        render: () => () => {
          const { id } = getMaterialInfo('baseInfo') ?? {};
          const params = { materialId: id, status: appEnum.Common.UsageStatus.enabled };

          return (
            <Form.Item style={{ marginBottom: 0 }} name="processRouteId">
              <SearchSelect
                placeholder={'请选择'}
                fetchType="processRouting"
                disabled={isVirtualMaterial || _.isNil(id)}
                params={params}
                labelInValue
                onChange={handleProcessRouteChange}
              />
            </Form.Item>
          );
        },
      },
      {
        label: '报工工序号',
        name: 'workReportProcessId',
        render: () => <Select labelInValue disabled placeholder="请选择工艺路线" />,
      },
      {
        shouldUpdate: true,
        noStyle: true,
        render: (formItemProps: any) => () => {
          const { workReportProcessId, processRouteId } = form.getFieldsValue();

          const params = {
            processRouteId: processRouteId?.value,
            processNodeNum: workReportProcessId?.label,
            controlType: appEnum.Sop.ControlType.report,
          };

          return (
            <Form.Item
              {...formItemProps}
              label="报工控件号"
              name="workReportSopControlId"
              hidden={_.isNil(workReportProcessId)}
            >
              <SearchSelect
                placeholder={'请选择'}
                params={params}
                fetchType="sopControlOfProcessRouting"
                valuePath="code"
                disabled={_.isNil(workReportProcessId)}
              />
            </Form.Item>
          );
        },
      },
      {
        label: '单次报工数量',
        dependencies: ['unitId'],
        render: () => () => {
          const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
            getMaterialInfo(),
            form.getFieldValue('unitId'),
          );

          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name="singleWorkReportAmount"
              rules={_.compact([
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: false,
                    max: 10000000,
                    fieldName: '单次报工数量',
                  }),
                },
                enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
              ])}
            >
              <InputNumber style={{ width: '100%' }} placeholder="请输入" />
            </Form.Item>
          );
        },
      },
      {
        label: '报工方式',
        name: 'reportingMethods',
        initialValue: [1, 2, 3, 4],
        rules: [{ required: true, message: '报工方式不能为空' }],
        render: () => (
          <Select
            placeholder="请选择报工方式"
            mode="multiple"
            options={appDict.bom.reportingMethods}
          />
        ),
      },
      {
        label: '是否入库',
        name: 'warehousing',
        initialValue: appEnum.Common.YN.yes,
        render: () => (
          <Radio.Group
            options={appDict.common.yn}
            onChange={(e) => {
              if (!e?.target?.value) {
                form.setFieldsValue({
                  autoWarehousingFlag: null,
                });
              }
            }}
          />
        ),
      },
      {
        label: '自动入库',
        dependencies: ['warehousing'],
        render: () => () => {
          const disableFlag = !form.getFieldValue('warehousing');

          return (
            <Form.Item
              style={{ marginBottom: 0 }}
              name="autoWarehousingFlag"
              initialValue={appEnum.Common.YN.yes}
            >
              <Radio.Group disabled={disableFlag} options={appDict.common.yn} />
            </Form.Item>
          );
        },
      },
      {
        label: '所属部门',
        name: 'ownedDepartmentId',
        render: () => (
          <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple={false} />
        ),
      },
      {
        label: '生产部门',
        name: 'manufactureDepartmentId',
        render: () => (
          <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple={false} />
        ),
      },
      {
        label: '版本说明',
        name: 'versionDescription',
        render: () => <Input.TextArea placeholder={'请输入'} maxLength={1000} showCount />,
      },
      type === appEnum.Common.CRUD.edit && {
        label: '编辑原因',
        name: 'editReason',
        render: () => <Input.TextArea placeholder={'请输入'} maxLength={1000} showCount />,
      },
    ]),
  };

  const renderBottom = () => {
    const tabWithComponent = new Map([
      [
        BomTab.SUB_ITEM_MATERIAL,
        <div style={{ padding: 24 }}>
          <SubItemMaterialForm
            form={form}
            name={BomTabMap.get(BomTab.SUB_ITEM_MATERIAL)?.filedName ?? ''}
            useType={0}
            bomCustomFields={bomCustomFields}
          />
        </div>,
      ],
      [
        BomTab.COPRODUCT,
        <div style={{ padding: 24 }}>
          <CoproductForm form={form} name={BomTabMap.get(BomTab.COPRODUCT)?.filedName ?? ''} />
        </div>,
      ],
      [
        BomTab.INTERMEDIATE_MATERIAL,
        <IntermediateMaterialForm
          form={form}
          name={BomTabMap.get(BomTab.COPRODUCT)?.filedName ?? ''}
        />,
      ],
      [
        BomTab.OTHER,
        <OtherInfoForm form={form} name={BomTabMap.get(BomTab.OTHER)?.filedName ?? ''} />,
      ],
    ]);

    /**
     * 父项物料是虚拟件时，需过滤
     */
    const filterVirtual = (item: TabList) => {
      const disabledTabs = [BomTab.COPRODUCT]; // 不支持父项物料为虚拟件的tab

      if (isVirtualMaterial) {
        return !disabledTabs.includes(item.key);
      }
      return true;
    };

    return (
      <Tabs type="card" activeKey={activeTab} onChange={handleTabChange}>
        {_.map(_.filter(tabList, filterVirtual), ({ key, errorCount }) => {
          const title = BomTabMap.get(key)?.title;

          return (
            <TabPane
              key={key}
              forceRender
              tab={
                <Badge size="small" count={errorCount}>
                  {title}
                </Badge>
              }
              style={{ height: '100%' }}
            >
              {tabWithComponent.get(key)}
            </TabPane>
          );
        })}
      </Tabs>
    );
  };

  const renderExtra = () => {
    return (
      type === appEnum.Common.CRUD.create && (
        <Checkbox
          onChange={() => {
            setKeepCreate(!keepCreate);
          }}
          checked={keepCreate}
        >
          连续新建
        </Checkbox>
      )
    );
  };

  return (
    <DataFormLayout
      loading={loading}
      form={form}
      info={[baseInfo]}
      title={`${lookup('crud', type) ?? ''}物料清单`}
      bottomContext={renderBottom()}
      extra={renderExtra()}
      onCancel={() => {
        history.goBack();
      }}
      onFinish={handleFinish}
      formProps={formProps}
    />
  );
};

export default BaseForm;
export { formatValueToApi };
