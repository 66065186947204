import React, { useContext, useEffect } from 'react';
import { Form, Input, message as Message, Tooltip } from 'antd';
import { fetchUserUpdate } from 'src/api/ytt/user-domain';
import { avatarDisplay } from 'src/components/avatar/show';
import { gcArray } from 'src/utils';
import { ACCOUNT_TYPE, UserInfoContext } from '../constant';
import { BcAttachmentForm, BlIcon, UploadAvatar } from 'src/components';
import TagTooltip from 'src/components/tooltip/tagTooltip';
import _ from 'lodash';
import UseLayout from '../useLayout';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { replaceSign } from 'src/utils/constants';
import _Array from 'src/utils/dataTypes/array';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';

interface Props {
  onChangeActive: (active: string) => void;
}

export default function UserInfo({ onChangeActive }: Props) {
  const [form] = Form.useForm();

  const { dataSource, refresh } = useContext(UserInfoContext);

  useEffect(() => {
    form.setFieldsValue({
      avatarUrl: dataSource?.avatarUrl,
      email: dataSource?.email,
      attachments: _.every(dataSource?.attachments, (item) => Number(item))
        ? dataSource?.attachments
        : [],
    });
  }, [dataSource]);

  const handleFinish = async () => {
    try {
      const value = await form?.validateFields();

      const newValue = {
        ...value,
        avatarUrl: value?.avatarUrl?.uri ?? dataSource?.avatarUrl,
        code: dataSource?.code,
        departmentIds: _.map(dataSource?.departmentVOList, 'id'),
        desc: dataSource?.desc,
        id: dataSource?.id,
        name: dataSource?.name,
        phone: dataSource?.phone,
        roleIds: _.map(dataSource?.roleList, 'id'),
        superior: dataSource?.superior?.id,
        terminal: dataSource?.terminal,
        username: dataSource?.username,
      };

      const { code } = await fetchUserUpdate(newValue);

      if (code === 200) {
        Message.success('更新成功');
        form.resetFields();
        refresh();
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,
    items: [
      {
        label: '',
        name: 'avatarUrl',
        isFullLine: true,
        render: () => <UploadAvatar form={form} uploadedUrl={dataSource.avatarUrl} />,
      },
      {
        label: '账号类型',
        isFullLine: true,
        render: () => ACCOUNT_TYPE.get(dataSource?.terminal ?? 0),
      },
      {
        label: '用户名称',
        isFullLine: true,
        render: () => dataSource?.name ?? replaceSign,
      },
      {
        label: (
          <div style={{ cursor: 'pointer' }}>
            手机号
            <Tooltip
              getPopupContainer={() => document.body}
              title="绑定后可用于手机号直接登录、找回密码等，保障您的账号更加安全！"
            >
              <span
                style={{
                  width: 16,
                  height: 16,
                  cursor: 'pointer',
                  color: '#bfbfbf',
                  marginLeft: 5,
                  fontSize: 16,
                }}
              >
                <BlIcon type="iconwentizhiyinbangzhu" />
              </span>
            </Tooltip>
          </div>
        ),
        isFullLine: true,
        render: () => {
          const onBtnClick = (e: any) => {
            e.preventDefault();
            onChangeActive('3');
          };
          const changePhoneBtn = <a onClick={onBtnClick}>更换手机</a>;
          const bindPhoneBtn = (
            <a style={{ marginRight: 10 }} onClick={onBtnClick}>
              去绑定
            </a>
          );

          return (
            <div>
              <span style={{ marginRight: 16 }}>
                {dataSource?.phone
                  ? dataSource?.phone + (dataSource?.bound ? '' : '（该手机号暂未绑定）')
                  : '暂无'}
              </span>
              {(!dataSource?.phone || !dataSource?.bound) && bindPhoneBtn}
              {dataSource?.phone && dataSource?.bound && changePhoneBtn}
            </div>
          );
        },
      },
      {
        label: '用户编号',
        isFullLine: true,
        render: () => dataSource?.code ?? replaceSign,
      },
      {
        label: '账号',
        isFullLine: true,
        render: () => dataSource?.username ?? replaceSign,
      },
      {
        label: '邮箱',
        name: 'email',
        isFullLine: true,
        render: () => <Input style={{ width: '20%' }} />,
      },
      {
        label: '直属上级',
        isFullLine: true,
        render: () => {
          if (!dataSource?.superior) {
            return replaceSign;
          }
          return avatarDisplay({
            ...dataSource?.superior,
            isShowTag: false,
            isUser: true,
          });
        },
      },
      {
        label: '部门',
        isFullLine: true,
        render: () => {
          if (_Array.isEmpty(dataSource?.departmentVOList)) {
            return replaceSign;
          }
          return <TagTooltip list={dataSource?.departmentVOList} isUser={false} max={3} />;
        },
      },
      {
        label: '角色',
        isFullLine: true,
        render: () => {
          if (gcArray.isEmpty(dataSource?.roleList)) {
            return replaceSign;
          }
          return <TagTooltip list={dataSource?.roleList} isUser max={3} />;
        },
      },
      {
        label: '附件',
        name: 'attachments',
        isFullLine: true,
        render: () => <BcAttachmentForm form={form} />,
      },
    ],
  };

  return (
    <UseLayout
      title="个人信息"
      renderContent={
        <DataFormLayout
          info={[baseInfo]}
          form={form}
          formLayout={'horizontal'}
          onFinish={throttleFn(handleFinish)}
          onCancel={() => {
            form.resetFields();
            refresh();
          }}
        />
      }
    />
  );
}
