import _ from 'lodash';
import { TabList } from 'src/components';
import { VIEW_MODAL } from 'src/page/sales/salesManagement/salesOrder/constant';
import SupplierListMaterial from './materialTabList';
import SupplierListSpupply from './supplierTabList';

const SupplierList = () => {
  const tabList = _.compact([
    {
      tab: '供应商',
      key: VIEW_MODAL.DOCUMENTS,
      component: <SupplierListSpupply />,
    },
    {
      tab: '注册物料行',
      key: VIEW_MODAL.DETAIL,
      component: <SupplierListMaterial />,
    },
  ]);

  return <TabList tabList={tabList} defaultKey={VIEW_MODAL.DOCUMENTS} />;
};

export default SupplierList;
