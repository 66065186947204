// 工单字段转化
export const FIELD_MAP = new Map([
  ['workOrderCode', 'workOrderCode'],
  ['workOrderStatus', 'workOrderStatus'],
  ['plannedStartTime', 'plannedStartTime'],
  ['plannedFinishTime', 'plannedEndTime'],
]);

// 工单类型
export const WORK_ORDER_TYPE_TYPE = new Map([
  [1, '普通'],
  [2, '返工'],
  [3, '改制'],
  [4, '维修'],
]);

export const WORK_ORDER_STATUS_MAP_ENUM = [
  { id: 1, name: '普通' },
  { id: 2, name: '返工' },
  { id: 3, name: '改制' },
  { id: 4, name: '维修' },
];
// 业务状态
export const TASK_STATUS_TYPE_TYPE = new Map([
  [0, '草稿'],
  [1, '新建'],
  [2, '已下发'],
  [3, '执行中'],
  [4, '已完工'],
  [5, '已关闭'],
]);

export const TASK_STATUS_STATUS_MAP_ENUM = [
  { id: 0, name: '草稿' },
  { id: 1, name: '新建' },
  { id: 2, name: '已下发' },
  { id: 3, name: '执行中' },
  { id: 4, name: '已完工' },
  { id: 5, name: '已关闭' },
];
// 领料方式

export const TASK_STATUS_ENUM_MAP = {
  DRAFT: 0,
  CRATE: 1,
  BEEN_ISSUED: 2,
  EXECUTION: 3,
  FINISHED: 4,
  CANCEL: 5,
};

export const PICK_MODE_TYPE = {
  ON_DEMAND: 1, // 按需领料
  NOT_PICK: 2, // 不领料
};

export const PICK_MODE_TYPE_TYPE = new Map([
  [1, '按需领料'],
  [2, '不领料'],
]);

export const PICK_MODE_STATUS_MAP_ENUM = [
  { id: 1, name: '按需领料' },
  { id: 2, name: '不领料' },
];
