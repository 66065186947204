import List from './list';
import ObjectSettings from './objectSettings';
import { LayoutEdit, LayoutAssign } from './layout';
import { BizTypeEdit } from './bizType';
import NumberRulesList from '../numberRules/list';
import EditNumberRules from '../numberRules/edit';
import CreateNumberRules from '../numberRules/create';
import NumberRulesDetailPage from '../numberRules/detailPage';
import ESignature from '../systemManagement/eSignature';
import authDict from 'src/utils/auth';
import SignatureAction from '../systemManagement/eSignature/action';
import ESignatureDetailPage from '../systemManagement/eSignature/detaiPage';
import ApprovalProcessList from '../processManagement/approvalProcess/list';
import CreateAndEdit from '../processManagement/approvalProcess/createAndEdit';
import ApprovalProcessDetail from '../processManagement/approvalProcess/detail';
import FieldCascadingList from './fieldCascading';
import { pathPattens } from './constants';

const customFieldRoute = [
  {
    path: '/systemManagement',
    menu: '对象管理',
    icon: 'iconduixiangheziduan',
    breadcrumb: '对象管理',
    redirectPath: '/systemManagement/bizObject',
    children: [
      {
        path: '/systemManagement/bizObject',
        component: List,
        auth: authDict.object_view,
        menu: '业务对象',
        breadcrumb: '业务对象',
        children: [
          {
            path: '/systemManagement/bizObject/:objectId/settings',
            component: ObjectSettings,
            auth: authDict.object_detail,
            breadcrumb: '{{objectName}}',
            children: [
              {
                path: pathPattens.createLayout,
                component: LayoutEdit,
                auth: authDict.layout_add,
                breadcrumb: '新建布局',
              },
              {
                path: pathPattens.editLayout,
                component: LayoutEdit,
                auth: authDict.layout_edit,
                breadcrumb: '编辑布局',
              },
              {
                path: pathPattens.copyLayout,
                component: LayoutEdit,
                auth: authDict.layout_add,
                breadcrumb: '复制布局',
              },
              {
                path: pathPattens.viewLayout,
                component: LayoutEdit,
                auth: authDict.layout_detail,
                breadcrumb: '查看布局',
              },
              {
                path: pathPattens.assignLayout,
                component: LayoutAssign,
                auth: authDict.layout_assign_layout,
                breadcrumb: '分配布局',
              },
              {
                path: pathPattens.createBizType,
                component: BizTypeEdit,
                auth: authDict.businessType_add,
                breadcrumb: '新建业务类型',
              },
              {
                path: pathPattens.editBizType,
                component: BizTypeEdit,
                auth: authDict.businessType_edit,
                breadcrumb: '编辑业务类型',
              },
              {
                path: pathPattens.fieldCascadingList,
                component: FieldCascadingList,
                auth: authDict.businessType_edit,
                breadcrumb: '字段级联关系',
              },
            ],
          },
        ],
      },
      {
        path: '/systemManagement/numberRulesList',
        auth: authDict.numberingrule_view,
        component: NumberRulesList,
        menu: '编号规则',
        breadcrumb: '编号规则',
        children: [
          {
            path: '/systemManagement/numberRulesList/:id/detail',
            auth: authDict.numberingrule_detail,
            component: NumberRulesDetailPage,
            breadcrumb: '详情',
          },
          {
            path: '/systemManagement/numberRulesList/:id/edit',
            auth: authDict.numberingrule_edit,
            component: EditNumberRules,
            breadcrumb: '编辑编号规则',
          },
          {
            path: '/systemManagement/numberRulesList/create',
            auth: authDict.numberingrule_add,
            component: CreateNumberRules,
            breadcrumb: '创建编号规则',
          },
        ],
      },
      {
        path: '/systemManagement/eS-M',
        component: ESignature,
        auth: authDict.electronicsignature_view,
        menu: '电子签名',
        breadcrumb: '电子签名',
        children: [
          {
            path: '/systemManagement/eS-M/:id/detail',
            auth: authDict.electronicsignature_detail,
            component: ESignatureDetailPage,
            breadcrumb: '详情',
          },
          {
            path: '/systemManagement/eS-M/create',
            auth: authDict.electronicsignature_add,
            component: SignatureAction,
            breadcrumb: '新建电子签名',
          },
          {
            path: '/systemManagement/eS-M/edit/:id',
            auth: authDict.electronicsignature_edit,
            component: SignatureAction,
            breadcrumb: '编辑电子签名',
          },
        ],
      },
    ],
  },
  {
    path: '/processManagement',
    menu: '流程管理',
    icon: 'iconliucheng',
    breadcrumb: '流程管理',
    redirectPath: '/processManagement/approvalProcessList',
    children: [
      {
        path: '/processManagement/approvalProcessList',
        auth: authDict.approvalScheme_view,
        component: ApprovalProcessList,
        menu: '审批流程管理',
        breadcrumb: '审批流程管理',
        children: [
          {
            path: '/processManagement/approvalProcessList/:id/detail',
            auth: authDict.approvalScheme_detail,
            component: ApprovalProcessDetail,
            breadcrumb: '审批流程方案详情',
          },
          {
            path: '/processManagement/approvalProcessList/:id/edit',
            auth: authDict.approvalScheme_edit,
            component: CreateAndEdit,
            breadcrumb: '编辑审批流程方案',
          },
          {
            path: '/processManagement/approvalProcessList/create',
            auth: authDict.approvalScheme_add,
            component: CreateAndEdit,
            breadcrumb: '新建审批流程方案',
          },
        ],
      },
    ],
  },
];

export default customFieldRoute;
