import React, { useEffect, useState } from 'react';
import { Form, message as Message } from 'antd';
import { match, RouteComponentProps } from 'react-router-dom';
import CreateAndEditBaseInfo from './components/createAndEditBaseInfo';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';
import { fetchMessageTemplateDetail, fetchMessageTemplateEdit } from 'src/api/ytt/trigger-domain';
import { FieldObjType, ReceiverType } from './interface';
import {
  formatCrateAndEditValue,
  formatInitDataToSelectOptions,
  initMessageReceiversToForm,
} from './utils';
import { filter } from 'lodash';

interface createProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const EditNoticeTemplate = (props: createProps) => {
  const { match, history } = props;
  const templateNoticeId = Number(match?.params?.id);

  const [editForm] = Form.useForm();
  const [detailData, setDetailData] = useState<any>();

  const [inputSelectedOptions, setInputSelectedOptions] = useState<FieldObjType[]>([]);

  const [textSelectedOptions, setTextSelectedOptions] = useState<FieldObjType[]>([]);

  // 切换对象需要重置字段选择数据
  const handleResetSelectOptions = () => {
    setInputSelectedOptions([]);

    setTextSelectedOptions([]);
  };

  const handleSetSelectOptions = (type: string, options?: FieldObjType[]) => {
    if (type === 'input') {
      setInputSelectedOptions(inputSelectedOptions?.concat(options ?? []));
    } else {
      setTextSelectedOptions(textSelectedOptions?.concat(options ?? []));
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await fetchMessageTemplateDetail({ id: templateNoticeId });

      if (data) {
        const userAndDepartmentReceivers = initMessageReceiversToForm(
          filter(
            data?.messageReceivers,
            (node: any) =>
              node?.receiverType === ReceiverType?.department ||
              node?.receiverType === ReceiverType?.user,
          ),
          ReceiverType?.department,
        );

        const roleReceivers = initMessageReceiversToForm(
          filter(data?.messageReceivers, (node: any) => node?.receiverType === ReceiverType?.role),
          ReceiverType?.role,
        );

        const fieldReceivers = initMessageReceiversToForm(
          filter(data?.messageReceivers, (node: any) => node?.receiverType === ReceiverType?.field),
          ReceiverType?.field,
        );

        setDetailData({
          ...data,
          userAndDepartmentReceivers,
          roleReceivers,
          fieldReceivers,
          messageReceivers: [...userAndDepartmentReceivers, ...roleReceivers, ...fieldReceivers],
        });

        const titleSelectOptions = formatInitDataToSelectOptions(data?.titleFieldSelections);

        setInputSelectedOptions(titleSelectOptions);

        const contentSelectOptions = formatInitDataToSelectOptions(data?.contentFieldSelections);

        setTextSelectedOptions(contentSelectOptions);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [templateNoticeId]);

  const handleFinish = async () => {
    try {
      const value = await editForm?.validateFields();
      const newValue = formatCrateAndEditValue(value, inputSelectedOptions, textSelectedOptions);

      const { message, code } = await fetchMessageTemplateEdit({
        ...newValue,
        id: templateNoticeId,
      });

      if (code == 200) {
        Message.success('更新成功');
        history.goBack();
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <CreateAndEditBaseInfo
      edit
      title="编辑通知模板"
      onCancel={() => {
        history.goBack();
      }}
      onFinish={throttleFn(handleFinish)}
      form={editForm}
      initialData={detailData}
      handleSetSelectOptions={handleSetSelectOptions}
      inputSelectedOptions={inputSelectedOptions}
      textSelectedOptions={textSelectedOptions}
      handleResetSelectOptions={handleResetSelectOptions}
    />
  );
};

export default EditNoticeTemplate;
