import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { valueOrHolder } from 'src/utils/formatters';
import { getStandardWorkTime } from '../../maintenancePlan/utils';

interface AutoTimerProps {
  /** 起始数（秒） */
  startSec?: number;
  /** 是否自动计时 */
  auto?: boolean;
}

const AutoTimer = (props: AutoTimerProps) => {
  const { startSec, auto } = props;

  const [time, setTime] = useState<number>(0);

  const { H, M, S } = getStandardWorkTime(time);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (auto) {
      timer = setInterval(() => {
        setTime((oldTime) => {
          return oldTime + 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [auto]);

  useEffect(() => {
    if (_.isNumber(startSec)) {
      setTime(startSec);
    }
  }, [startSec]);

  return valueOrHolder(startSec, () => {
    return <span key={startSec}>{`${H}小时${M}分钟${S}秒`}</span>;
  });
};

export default AutoTimer;
