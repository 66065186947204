import { useState } from 'react';
import { Form, Input, message as Message } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/es/table';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { BlSortFormList } from '@blacklake-web/component';
import _ from 'lodash';
import { materialEntity } from 'src/entity';
import { numberMinMaxCheck, fractionLengthCheck } from 'src/utils/formValidators';
import { ShipmentNoteDetailItemsResponse, ShipmentNoteDetailResponse } from '../../interface';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import _Array from 'src/utils/dataTypes/array';
import { replaceSign } from 'src/utils/constants';
import { fetchShipmentNoteGoodsIssue } from 'src/api/ytt/order-domain/invoice';
import { TagList } from 'src/components';

// 表单字段名
const deliverGoodsFormName = 'receivingGoods';
/** 默认单位精度 */
const DEFAULT_PRECISION = 6;

interface Props {
  deliverOrder: ShipmentNoteDetailResponse;
  onCancel: () => void;
}
export default function InvoiceGoodsDialog(props: Props) {
  const { deliverOrder, onCancel } = props;
  const [loading, setLoading] = useState(false);
  const [deliverGoodsForm] = Form.useForm();
  const { getFieldValue } = deliverGoodsForm;

  deliverGoodsForm.setFieldsValue({ [deliverGoodsFormName]: deliverOrder.items });

  const getColumns: (
    remove: (index: number | number[]) => void,
  ) => ColumnProps<any & FormListFieldData>[] = () => {
    return [
      {
        title: '序号',
        dataIndex: 'num',
        width: 100,
        render: (text, field) => field.name + 1,
      },
      {
        title: '发货单编号',
        dataIndex: 'code',
        width: 140,
        render: (text, field) =>
          deliverGoodsForm.getFieldValue([deliverGoodsFormName, field.name, 'code']),
      },
      {
        title: '协同发货单号',
        dataIndex: 'scmReceiveNoteCode',
        width: 150,
        render: () => deliverOrder.scmReceiveNoteCode,
      },
      {
        title: '物料编号',
        dataIndex: 'materialCode',
        width: 150,
        render: (text, field) =>
          deliverGoodsForm.getFieldValue([
            deliverGoodsFormName,
            field.name,
            'material',
            'baseInfo',
            'code',
          ]),
      },
      {
        title: '物料名称',
        dataIndex: 'materialName',
        width: 150,
        render: (text, field) =>
          deliverGoodsForm.getFieldValue([
            deliverGoodsFormName,
            field.name,
            'material',
            'baseInfo',
            'name',
          ]),
      },
      {
        title: '物料属性',
        dataIndex: ['material', 'attribute'],
        width: 250,
        render: (text, field) => {
          const value = deliverGoodsForm.getFieldValue([
            deliverGoodsFormName,
            field.name,
            'material',
            'attribute',
          ]);

          if (_Array.isEmpty(value)) {
            return replaceSign;
          }
          return (
            <TagList
              dataSource={_.map(value, ({ id, name, attributeItem }) => ({
                label: `${name}:${attributeItem?.content}`,
                value: id,
              }))}
            />
          );
        },
      },
      {
        title: '计划发货数',
        dataIndex: 'plannedIssueAmount',
        width: 120,
        render: (text, field) => {
          const amount = deliverGoodsForm.getFieldValue([
            deliverGoodsFormName,
            field.name,
            'plannedIssueAmount',
            'amount',
          ]);
          const unit = deliverGoodsForm.getFieldValue([
            deliverGoodsFormName,
            field.name,
            'plannedIssueAmount',
            'unitName',
          ]);

          return `${amount} ${unit}`;
        },
      },
      {
        title: '交货时间',
        dataIndex: 'deliveryDate',
        width: 200,
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        render: (text, field) =>
          formatTimeForRender(
            deliverGoodsForm.getFieldValue([deliverGoodsFormName, field.name, 'deliveryDate']),
          ),
      },
      {
        title: '仓库已发数',
        dataIndex: 'warehouseIssueAmount',
        width: 150,
        render: (_text, field) => {
          const unit = getFieldValue([deliverGoodsFormName, field.name, 'warehouseIssueAmount']);
          const material = getFieldValue([deliverGoodsFormName, field.name, 'material']);
          const { precisionFigure } = materialEntity.getMaterialUnitInfo(material, unit.unitId);

          return (
            <Form.Item
              style={{ margin: 0 }}
              fieldKey={[field.fieldKey, 'warehouseIssueAmount', 'amount']}
              name={[field.name, 'warehouseIssueAmount', 'amount']}
              rules={[
                {
                  required: true,
                  message: '请输入仓库实发数',
                },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: false,
                    fieldName: '仓库已发数',
                    message: '请输入大于0的数字',
                  }),
                },
                {
                  // 精度校验从单位中获取
                  validator: fractionLengthCheck(
                    precisionFigure ?? DEFAULT_PRECISION,
                    `请输入${precisionFigure ?? DEFAULT_PRECISION}位精度的数字`,
                  ),
                },
              ]}
            >
              <Input placeholder="请输入" />
            </Form.Item>
          );
        },
      },
      {
        title: '销售订单编号',
        dataIndex: 'salesOrderCode',
        width: 150,
        render: (text, field) =>
          deliverGoodsForm.getFieldValue([deliverGoodsFormName, field.name, 'salesOrderCode']),
      },
      {
        title: '销售订单明细行号',
        dataIndex: 'salesOrderLineNo',
        width: 180,
        render: (text, field) =>
          deliverGoodsForm.getFieldValue([deliverGoodsFormName, field.name, 'salesOrderLineNo']),
      },
    ];
  };
  const baseInfo: DataFormLayoutInfoBlock[] = [
    {
      items: [
        {
          label: '',
          isFullLine: true,
          render: () => (
            <BlSortFormList
              name={deliverGoodsFormName}
              renderColumns={getColumns}
              isNeedDrag={false}
              isNeedAdd={false}
              isNeedDelete={false}
            />
          ),
        },
      ],
    },
  ];
  const handleFinish = async () => {
    try {
      await deliverGoodsForm.validateFields();
      const formData: ShipmentNoteDetailItemsResponse[] =
        deliverGoodsForm.getFieldValue(deliverGoodsFormName);

      const requestParam = {
        id: deliverOrder.id,
        code: deliverOrder.code!,
        items: formData.map((good) => ({
          id: good.id,
          noteId: good.shipmentNoteId,
          warehouseIssueAmount: Number(good.warehouseIssueAmount?.amount),
        })),
      };

      setLoading(true);

      const response = await fetchShipmentNoteGoodsIssue(requestParam);

      setLoading(false);
      if (response.code === 200) {
        Message.success('发货成功');
        onCancel();
      } else {
        Message.error(response.message);
        onCancel();
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <DataFormLayout
      form={deliverGoodsForm}
      info={baseInfo}
      loading={loading}
      onCancel={onCancel}
      onFinish={handleFinish}
    />
  );
}
