import type {
  FetchSopListResponse,
  FetchSopCreateRequest,
  FetchSopStepDetailResponse,
  FetchSopDetailResponse,
} from 'src/api/ytt/sop-domain';
import { FetchCustomFieldGetListByObjectCodeResponse } from 'src/api/ytt/metadata-domain/customField';
import { ControlType } from 'src/dict/sop';

export type SopOverviewData = Required<Required<FetchSopListResponse>['data']['list'][number]>;
export type SopDetailsResponseData = Exclude<PickCode<FetchSopDetailResponse>['data'], undefined>;
export type StepDetailResponseData = Exclude<
  PickCode<FetchSopStepDetailResponse>['data'],
  undefined
>;
export type StepDetailControl = Exclude<StepDetailResponseData['controls'], undefined>[number];
export type StepItem = Exclude<FetchSopCreateRequest['steps'], undefined>[number];
export type UserItem = {
  id?: number;
  name?: string;
};

export type StepItemOfTree = {
  key: number;
  title: string;
  referenceId: number;
  stepId: number;
  isGroup: number | undefined;
  children?: StepItemOfTree[];
};

export interface ControlItem
  extends Omit<
    Exclude<StepItem['controls'], undefined>[number],
    | 'showValue'
    | 'defaultValue'
    | 'creatorId'
    | 'operatorId'
    | 'uploadMethod'
    | 'fileType'
    | 'optionalUser'
    | 'defaultUserOption'
    | 'relateObject'
    | 'showField'
    | 'mainField'
  > {
  id?: number | undefined;
  controlType: ControlType;
  creatorId?: number;
  operatorId?: number;
  showValue?: string | number;
  defaultValue?: string | number;
  // uploadMethod和fileType在本地采用array适配<Select>, 提交前求和转为number
  uploadMethod?: number | number[];
  fileType?: number | number[];
  showValueList?: any[];
  optionalUser?: UserItem[];
  defaultUserOption?: UserItem[];
  relateObject?: string;
  showField?: ShowFieldObj[];
  mainField?: ShowFieldObj;
}
export interface LocalControlItem extends ControlItem {
  _localKey: string;
}
// 修正控件数据类型
export interface StepDetailData
  extends Omit<StepDetailResponseData, 'controls' | 'creatorId' | 'operatorId' | 'orgId'> {
  controls: ControlItem[];
}

export type StepOverviewList = Exclude<
  Exclude<PickCode<FetchSopDetailResponse>['data'], undefined>['steps'],
  undefined
>;
export interface StepOverviewData extends Omit<Required<StepOverviewList[number]>, 'children'> {
  children: StepOverviewData[];
  childrenSteps?: StepDetailData[];
}

export enum DragItemTypes {
  cover = 'cover',
  component = 'component',
}

export type DragItem = {
  index: number;
  id: string;
  controlType: ControlType;
};

export interface PreviewCompProps {
  inputPrompt?: string;
  selectMethod?: number;
}

export interface StepTreeData extends StepOverviewData {
  key: number;
  title: string;
  // type: number;
  // // referenceId: number;
  // // stepId: number;
  // // // isGroup: number | undefined;
  // children?: StepTreeData[] | undefined | unknown[];
  [x: string]: any;
}

export interface StepRowData {
  id: number;
  code: string;
  name: string;
  prevId: number;
  parentId: number;
  children: StepRowData[];
  [x: string]: any;
}
export interface ShowFieldObj {
  fieldName: string;
  fieldCode: string;
}
export interface LabelInValueSelectorProps {
  label: string;
  value: string;
  key: string;
}

export type CustomFieldGetListByObjectCode = Exclude<
  PickCode<FetchCustomFieldGetListByObjectCodeResponse>['data'],
  undefined
>[number];
export interface CustomField {
  [x: string]: any;
}

export type FormulaInfo = {
  linkText: string;
  label?: string;
  formulaId: number;
};

export type CascaderTreeNode = {
  label: string;
  value: string;
  isLeaf: boolean;
  disabled: boolean;
  [x: string]: any;
};
