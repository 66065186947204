/**
 * @enum 客户模块枚举值
 */

/** 物料Tab类型 */
export enum TabType {
  /** 客户视图 */
  customer = '1',
  /** 物料行视图 */
  registeredMaterials = '2',
}

export enum RegistrationStatus {
  /** 未注册 */
  unregistered,
  /** 注册中 */
  registering,
  /** 已注册 */
  registered,
}
export enum AuditStatus {
  /** 待处理 */
  pending,
  /** 已接受 */
  accepted,
  /** 已拒绝 */
  rejected,
}
export enum AssociationType {
  /** 与已有客户关联 */
  withExistinging = 1,
  /** 新建客户并关联 */
  createNew,
}
