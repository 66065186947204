import { useState, useEffect } from 'react';
import { useLocation, RouteComponentProps } from 'react-router-dom';
import { DetailLayout } from 'layout';
import { BlIcon, BlTable, SingleOperationLogComponent, TagList } from 'components';
import {
  fetchReceiveNoteDetail,
  fetchReceiveNoteItemDetail,
} from 'src/api/ytt/order-domain/deliveryOrder';
import { appEnum } from 'src/dict';
import _, { compact } from 'lodash';
import qs from 'qs';
import { editPath } from '../index';
import {
  endOrder,
  dispatchOrder,
  closeOrder,
  createInbound,
  fetchCustomFieldCrossColumns,
} from '../utils';
import ReceiveModel from '../components/receive';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import type { DataResource, CrossCustomFields } from '../interface';
import authDict from 'src/utils/auth';
import { otherInfoColumns, materialInfoColumns, baseInfoColumns } from './columns';
import { injectCustomFieldColumns, injectCustomFieldInfos } from 'src/components/customField';
import { Auth } from 'src/dict/sales';
import { FieldType } from 'src/dict/customField';

interface DetailPageProps extends RouteComponentProps {}

const { execStatusEnum } = appEnum.DeliveryEnum;
const DetailPage = (props: DetailPageProps) => {
  const { history } = props;
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  const [visibleLog, setVisibleLog] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<DataResource>({});
  const [itemList, setItemList] = useState<any[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [crossCustomFields, setCrossCustomFields] = useState<CrossCustomFields>();

  const fetchData = async () => {
    try {
      if (!query.id) {
        return;
      }
      const { data = {} } = await fetchReceiveNoteDetail({ id: Number(query.id) });
      const res = await fetchReceiveNoteItemDetail({ id: Number(query.id) });
      const crossCustomFieldsColumns = await fetchCustomFieldCrossColumns(data?.supplierId);

      setDataSource(data);
      setCrossCustomFields(crossCustomFieldsColumns);

      setDataSource(data);
      setItemList(res.data || []);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  /**
   * 跨租户的采购订单的自定义字段
   */
  const crossDocumentCustomFields = injectCustomFieldInfos({
    customFields: crossCustomFields?.document,
    dataIndex: 'scmShipmentNoteCustomFields',
    type: 'detail',
    componentsProps: new Map([
      [
        FieldType.appendix, // 附件类型的组件的参数(跨租户)
        {
          type: 'collaborative',
          collaborativeParams: { supplierId: dataSource?.supplierId },
        },
      ],
    ]),
  });
  /**
   * 跨租户的采购订单物料行的自定义字段
   */

  console.log('crossCustomFields?.material', crossCustomFields?.material);
  const crossMaterialCustomFields = injectCustomFieldColumns({
    columns: materialInfoColumns(dataSource, history), // 原本的列
    customFields: crossCustomFields?.material, // 自定义字段信息
    dataIndex: 'scmShipmentNoteItemCustomFields', // 自定义字段自定义字段scmShipmentNoteItemCustomFields
    objectCode: OBJECT_OF_CODE.ShipmentsNoteItem, // 采购订单物料行的code（是跨租户配置的自定义字段）
    type: 'detail', // 使用类型
    componentsProps: new Map([
      [
        FieldType.appendix, // 附件类型的组件的参数(跨租户)
        {
          type: 'collaborative',
          collaborativeParams: { supplierId: dataSource?.supplierId },
        },
      ],
    ]),
  });
  const baseInfo = compact([
    {
      title: '基本信息',
      items: baseInfoColumns(dataSource),
    },
    crossDocumentCustomFields.items?.length > 0 && {
      title: '协同收货信息',
      items: crossDocumentCustomFields.items,
    },
    {
      title: '物料信息',
      items: [
        // 协同收货信息 - 明细
        {
          label: '',
          isFullLine: true,
          dataIndex: 'scmShipmentNoteItemCustomFields',
          render: () => {
            return (
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                }}
              >
                <BlTable
                  rowKey={'id'}
                  style={{ width: '100%' }}
                  scroll={{
                    x: (crossCustomFields?.material?.customFields?.length || 0) > 0 ? 2500 : 1800,
                  }}
                  pagination={false}
                  columns={crossMaterialCustomFields}
                  dataSource={itemList}
                />
              </div>
            );
          },
        },
      ],
    },
    {
      title: '其他信息',
      column: 2,
      items: otherInfoColumns,
    },
  ]);
  const { execStatus } = dataSource;
  const baseMenu = _.compact([
    {
      key: 'over',
      auth: authDict.receivenote_finish,
      onClick: () => {
        if (!dataSource.id) return;
        endOrder({
          list: [dataSource],
          type: 'single',
          success: fetchData,
        });
      },
      title: '结束',
      disabled: execStatus !== execStatusEnum.executing,
    },
    {
      key: 'recieve',
      auth: authDict.receivenote_receipt,
      onClick: () => {
        if (!dataSource.id) return;
        setVisible(true);
      },
      icon: <BlIcon type="iconbianji" />,
      title: '收货',
      disabled: execStatus !== execStatusEnum.executing,
    },
    {
      key: 'dispatch',
      auth: authDict.receivenote_dispatch,
      onClick: () => {
        if (!dataSource.id) return;
        dispatchOrder([dataSource.id], fetchData);
      },
      title: '下发',
      disabled: execStatus !== execStatusEnum.created,
    },
    {
      key: 'close',
      auth: authDict.receivenote_close,
      onClick: () => {
        if (!dataSource.id) return;
        closeOrder([dataSource.id], fetchData);
      },
      title: '关闭',
      disabled: execStatus !== execStatusEnum.created,
    },
    {
      key: 'createInbound',
      title: '新建入库单',
      disabled: execStatus !== execStatusEnum.executing,
      auth: authDict.inboundorder_add,
      onClick: () => createInbound(history, [dataSource]),
    },
    {
      key: 'operate',
      auth: authDict.receivenote_operrecord,
      title: '操作记录',
      onClick: () => {
        setVisibleLog(true);
      },
    },
    {
      key: 'edit',
      auth: authDict.receivenote_edit,
      onClick: () => history.push(`${editPath}?id=${query.id}`),
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
      disabled: execStatus !== execStatusEnum.executing && execStatus !== execStatusEnum.created,
    },
  ]);

  return (
    <>
      <ReceiveModel
        ids={[Number(query.id)]}
        onSubmit={() => {
          setVisible(false);
          fetchData();
        }}
        onCancel={() => setVisible(false)}
        visible={visible}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={Number(query.id)}
          objectCode={OBJECT_OF_CODE.receiveNote}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      <DetailLayout
        title="收货单详情"
        info={baseInfo}
        dataSource={dataSource}
        baseMenu={baseMenu}
      />
    </>
  );
};

export default DetailPage;
