export enum DragItemType {
  /** 从源拖入新的项 */
  source = 'source',
  /** 拖拽已有的项进行排序 */
  listItem = 'listItem',
}

/** 拖拽组件间传递的数据 */
export type DragItem = {
  /** 拖拽项的下标 */
  index: number;
  /** 自定义数据 */
  data: any;
};

export type GetInitItemDataFn = (dragItem: DragItem, insertIndex: number) => any;
