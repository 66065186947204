import React, { useEffect, useState } from 'react';
import { Form, FormInstance, message, Select } from 'antd';
import { BlIcon, SearchSelect } from 'src/components';
import styles from '../../filter/styles.module.scss';
import { map, filter, debounce, toString } from 'lodash';
import { fetchDataSetQueryCanFilterRelateParamsField } from 'src/api/ytt/e-report-domain/dataSet';
import { getSymbolSelectList } from '../../filter/FilterItem';
import { FIELD_TYPE_ONE_TO_ONE_SYMBOL_MAP } from '../../constant';
import { FieldType } from 'src/dict/customField';

interface Props {
  form: FormInstance;
}

export default function CptRelatedParam({ form }: Props) {
  const [fieldList, setFieldList] = useState([] as any);

  const templateId = Number(localStorage.getItem('templateId'));

  const getFieldList = async () => {
    const { data } = await fetchDataSetQueryCanFilterRelateParamsField({
      templateId,
    });

    if (data) {
      setFieldList(data);
    }
  };

  useEffect(() => {
    getFieldList();
  }, [templateId]);

  const onChange = (field: any) => {
    // 数据集的改变 清空对应的关联参数

    const oldCptRelatedParam = form.getFieldValue('cptRelatedParam');
    const newCptRelatedParam = map(oldCptRelatedParam, (node, index) => {
      if (index === field?.name) {
        return {
          ...node,
          fieldId: null,
        };
      }
      return node;
    });

    form.setFieldsValue({ cptRelatedParam: newCptRelatedParam });
  };

  return (
    <Form.List name="cptRelatedParam">
      {(fields, { add, remove }) => {
        const getFooter = () => {
          return (
            <div
              onClick={() => {
                if (fields?.length >= 10) {
                  message.error('每个版本最多添加10个关联参数');
                } else {
                  add();
                }
              }}
              style={{ cursor: 'pointer', width: 110 }}
            >
              <span style={{ marginRight: 8 }}>
                <BlIcon type="iconxinjiantianjia" />
              </span>
              添加关联参数
            </div>
          );
        };

        const cptRelatedParam = () => {
          return fields.map((field) => {
            return (
              <div className={styles?.cptRelatedParam} key={field?.name}>
                <div className={styles?.cptRelatedParamItem}>
                  <Form.Item
                    name={[field?.name, 'dataSetId']}
                    fieldKey={[field?.key, 'dataSetId']}
                    rules={[{ required: true, message: '请选择数据集' }]}
                  >
                    <SearchSelect
                      labelInValue
                      fetchType={'dataForTemDataSetListNoStatus'}
                      onChange={() => {
                        onChange(field);
                      }}
                      params={{
                        templateId,
                      }}
                      searchFieldName="templateId"
                    />
                  </Form.Item>
                </div>
                <div className={styles?.cptRelatedParamItem}>
                  <Form.Item shouldUpdate>
                    {() => {
                      const temData =
                        form.getFieldValue('cptRelatedParam')?.[field?.name]?.dataSetId?.key;

                      const fieldData = filter(
                        fieldList,
                        (node: any) => node?.temDataSetId === temData,
                      );

                      let filterFieldData = fieldData;

                      const onSearch = debounce((value: string, fieldData: any[]) => {
                        if (!value) {
                          return;
                        }

                        filterFieldData = fieldData.filter(
                          ({ fieldDisplayName }) => toString(fieldDisplayName).indexOf(value) > -1,
                        );

                        return filterFieldData;
                      }, 200);

                      return (
                        <Form.Item
                          name={[field?.name, 'fieldId']}
                          fieldKey={[field?.key, 'fieldId']}
                          rules={[{ required: true, message: '请选择字段' }]}
                        >
                          <Select
                            onChange={() => {
                              // 切换选择的字段 更新条件的选择值
                              form.setFields([
                                {
                                  name: ['cptRelatedParam', `${field?.key}`, 'condition'],
                                  value: '',
                                },
                              ]);
                            }}
                            showSearch
                            onSearch={(value: string) => {
                              onSearch(value, fieldData);
                            }}
                            labelInValue
                            placeholder="请选择"
                            options={map(filterFieldData, (item: any) => {
                              // 只有日期类型支持重复选 其他类型一个字段只支持选择一次
                              const dataCptParam = filter(
                                form.getFieldValue('cptRelatedParam'),
                                (node) => node?.dataSetId?.key === temData,
                              ).filter((node: any) => {
                                return (
                                  node?.fieldId?.value &&
                                  JSON.parse(node?.fieldId?.value)?.fieldType !== FieldType.date
                                );
                              });

                              const checkedList = map(
                                dataCptParam,
                                (node) =>
                                  node?.fieldId?.value && JSON.parse(node?.fieldId?.value)?.fieldId,
                              );

                              return {
                                label: item?.fieldDisplayName,
                                key: item?.fieldId,
                                value: JSON.stringify(item),
                                disabled: checkedList.includes(item.fieldId),
                              };
                            })}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </div>
                <div className={styles?.cptRelatedParamItem}>
                  <Form.Item shouldUpdate>
                    {() => {
                      const fieldType =
                        (form.getFieldValue('cptRelatedParam')?.[field?.name]?.fieldId?.value &&
                          JSON.parse(
                            form.getFieldValue('cptRelatedParam')?.[field?.name]?.fieldId?.value,
                          )?.fieldType) ??
                        1;

                      const symbolSelectList = getSymbolSelectList(
                        FIELD_TYPE_ONE_TO_ONE_SYMBOL_MAP.get(fieldType) ?? [],
                      );

                      return (
                        <Form.Item
                          name={[field?.name, 'condition']}
                          fieldKey={[field?.key, 'condition']}
                          rules={[{ required: true, message: '请选择条件' }]}
                        >
                          <Select>
                            {symbolSelectList?.map((item: any) => {
                              return (
                                <Select.Option key={item?.key} value={item?.key}>
                                  {item?.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </div>
                <div className={styles?.cptRelatedParamDelete}>
                  <div className={styles?.cptRelatedParamDeleteIcon}>
                    <BlIcon
                      type="iconlieshanchu"
                      onClick={() => {
                        remove(field?.name);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          });
        };

        return (
          <>
            <div>{cptRelatedParam()}</div>
            <div>{getFooter()}</div>
          </>
        );
      }}
    </Form.List>
  );
}
