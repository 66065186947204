import InventoryList from 'src/page/warehouseManagement/inventoryManagement/inventoryList';
import InventoryDetail from 'src/page/warehouseManagement/inventoryManagement/inventoryDetail';
import BatchList from 'src/page/warehouseManagement/batchManagement/batchList';
import CarrierInventory from './carrierManagement/CarrierInventory';
import AddCarrier from './carrierManagement/CarrierInventory/AddCarrier';
import CarrierDispatchLedger from './carrierManagement/CarrierDispatchLedger';
import CarrierBusinessLedger from './carrierManagement/CarrierBusinessLedger';
import { BoundOrderNavigation } from './boundOrderManagement';
import { warehouseRouteConfig } from 'src/page/knowledgeManagement/warehouse/navigation';
import EntryRecordList from './recordManagement/entryRecord/entryRecordList';
import OutRecordList from './recordManagement/outRecord/outRecordList';
import CommitRecordList from './recordManagement/commitRecord/commitRecordList';
import AdjustRecordList from './recordManagement/adjustRecord/adjustRecordList';
import PropertyChangeRecordList from './recordManagement/propertyChangeRecord/propertyChangeRecordList';
import InventoryChangeLog from './inventoryManagement/inventoryChangeRecord';
import InventoryBatchList from './inventoryManagement/inventoryBatchList';
import authDict from 'src/utils/auth';

const warehouseManagementRoute = [
  {
    path: '/warehouseManagement',
    icon: 'iconyijicaidan-cangchu',
    menu: '仓储',
    breadcrumb: '仓储',
    redirectPath: '/warehouseManagement/inventoryManagement/inventoryList',
    children: [
      {
        path: '/warehouseManagement/inventoryManagement',
        menu: '库存管理',
        breadcrumb: '库存管理',
        redirectPath: '/warehouseManagement/inventoryManagement/inventoryList',
        children: [
          {
            path: '/warehouseManagement/inventoryManagement/inventoryList',
            component: InventoryList,
            menu: '库存明细',
            auth: authDict.inventoryelement_view,
            logPage: true,
            breadcrumb: '库存明细',
            children: [
              {
                path: '/warehouseManagement/inventoryManagement/inventoryList/detail',
                auth: authDict.inventoryelement_detail,
                component: InventoryDetail,
                breadcrumb: '库存明细详情',
              },
            ],
          },
          {
            path: '/warehouseManagement/inventoryManagement/inventoryChangeRecord',
            auth: authDict.inventoryelementchangelog_view,
            component: InventoryChangeLog,
            menu: '库存变动记录',
            breadcrumb: '库存变动记录',
          },
          {
            path: '/warehouseManagement/inventoryManagement/inventoryBatch',
            auth: authDict.inventoryelement_view_batch_inventory,
            component: InventoryBatchList,
            menu: '批次库存',
            breadcrumb: '批次库存',
          },
        ],
      },
      ...BoundOrderNavigation,
      {
        path: '/warehouseManagement/executiveManagement',
        menu: '仓储执行管理',
        breadcrumb: '仓储执行管理',
        children: [
          {
            path: '/warehouseManagement/executiveManagement/entryRecord',
            component: EntryRecordList,
            auth: authDict.inboundrecord_view,
            logPage: true,
            menu: '入库记录',
            breadcrumb: '入库记录',
          },
          {
            path: '/warehouseManagement/executiveManagement/outRecord',
            component: OutRecordList,
            auth: authDict.outboundrecord_view,
            logPage: true,
            menu: '出库记录',
            breadcrumb: '出库记录',
          },
          {
            path: '/warehouseManagement/executiveManagement/commitRecord',
            auth: authDict.transferrecord_view,
            component: CommitRecordList,
            menu: '调拨记录',
            breadcrumb: '调拨记录',
            logPage: true,
          },
          {
            path: '/warehouseManagement/executiveManagement/adjustRecord',
            component: AdjustRecordList,
            auth: authDict.amountadjustrecord_view,
            menu: '库存调整记录',
            breadcrumb: '库存调整记录',
          },
          {
            path: '/warehouseManagement/executiveManagement/propertyChangeRecord',
            component: PropertyChangeRecordList,
            auth: authDict.attradjustrecord_view,
            menu: '属性变更记录',
            breadcrumb: '属性变更记录',
          },
        ],
      },
      ...warehouseRouteConfig,
    ],
  },
];

export default warehouseManagementRoute;
