/**
 * @description 触发条件，条件选项组件
 */

import { Select, Form, FormInstance } from 'antd';
import _ from 'lodash';

import withFormItem from '../../share/ConditionEditTableLayout/withFormItem';
import { getConditionTypeOption } from '../../utils/tools';
import RedRemindText from '../../share/RedRemindText';

interface RuleConditionTypeProps {
  dependenceValues: any[];
  disabled?: boolean;
  name: (string | number)[];
  form: FormInstance;
  field: any;
}

interface RuleConditionTypeItemProps {
  dependenceValues: any[];
  form: FormInstance;
  parentPath: (string | number)[];
  disabled?: boolean;
}

const RuleConditionTypeItem: React.FC<RuleConditionTypeItemProps> = ({
  dependenceValues,
  ...restProps
}) => {
  const [fieldId] = dependenceValues;

  const lastFieldInfo: any = _.last(fieldId);
  const { targetType, fieldType } = lastFieldInfo?.info ?? {};

  if (!fieldType) return <RedRemindText text="请先选择字段" />;
  const options = getConditionTypeOption(fieldType as number, targetType);

  return <Select {...restProps} placeholder="请选择条件" options={options} />;
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};

const RuleConditionType: React.FC<RuleConditionTypeProps> = ({
  field,
  dependenceValues,
  ...restProps
}) => {
  const [updateDataCheck, ...restDep] = dependenceValues;
  const rules = [{ required: true, message: '条件不能为空' }];

  return (
    <div style={style}>
      {!updateDataCheck ? null : (
        <Form.Item name={_.concat(field?.name, 'beforeConditionType')} rules={rules}>
          <RuleConditionTypeItem
            {...restProps}
            dependenceValues={restDep}
            parentPath={_.concat(['triggerCondition', 'conditionGroups'], field?.name)}
          />
        </Form.Item>
      )}
      <Form.Item name={_.concat(field?.name, 'afterConditionType')} rules={rules}>
        <RuleConditionTypeItem
          {...restProps}
          dependenceValues={restDep}
          parentPath={_.concat(['triggerCondition', 'conditionGroups'], field?.name)}
        />
      </Form.Item>
    </div>
  );
};

export default withFormItem(RuleConditionType);
