/**
 * @enum 布局模块枚举值
 */

/** 布局项目类型 */
export enum LayoutItemType {
  /** 字段 */
  field,
  /** 标题栏 */
  titlebar,
  subTable,
  /** Tab页 */
  tabpane,
  /** Tab容器 */
  tabs,
  container,
  infoBlock,
}

/** 布局适用的页面类型 */
export enum PageType {
  /** 详情页 */
  detail,
  /** 新建/编辑页 */
  edit,
  /** 列表页 */
  list,
}

/** 布局所属终端 */
export enum Terminal {
  web,
  app,
}

/** 后端定义的布局控件类型 */
export enum LayoutComponentType {
  /** 字段 */
  field,
  /** 标题栏 */
  titlebar,
  /** 主从列表 */
  sonObjectTable,
  /** 主从标签页 */
  sonObjectTab,
}
