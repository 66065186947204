import { truncate, split, map, toString, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
//
import envConfig from 'src/configs/envConfig';

const DEFAULT_DROP_NUMBER = 1; // 按大版本的位数来定第一位截取数量 10->2 100->3 以此类推!!!!!

// 根据当前环境判断是否展示appVersion
const isProdEnv = !['ndev', 'feature', 'test', 'localhost'].filter((envKeyword) => {
  return location.host.includes(envKeyword);
}).length;

const AppVersion = () => {
  const getAppVersion = () => {
    const packageVersions = map(split(envConfig.releaseVersion, '.'), toString);

    if (isEmpty(packageVersions)) return '1.0.0.0';

    const first = packageVersions[0][0];
    const second = packageVersions[0].substring(DEFAULT_DROP_NUMBER) || '0';
    const third = packageVersions[1];
    const fourth = packageVersions[2];

    return [first, second, third, fourth].join('.');
  };

  const renderDevVersionInfo = () => {
    return (
      <>
        <div>commit:{truncate(envConfig.commitHash, { length: 6, omission: '' })}</div>
        <div>time:{envConfig.buildAt}</div>
      </>
    );
  };

  const renderProdVersionInfo = () => {
    return (
      <>
        <div>version:{getAppVersion()}</div>
        <div>time:{truncate(envConfig.buildAt, { length: 10, omission: '' })}</div>
      </>
    );
  };

  return (
    <div
      style={{
        fontSize: 10,
        width: '100%',
        textAlign: 'center',
        color: 'rgba(255,255,255,.65)',
        marginTop: 10,
      }}
    >
      {isProdEnv ? renderProdVersionInfo() : renderDevVersionInfo()}
    </div>
  );
};

export default AppVersion;
