/**
 * 查找步骤树的工具方法
 */
import _ from 'lodash';
import { GroupType, StepEntityType } from 'src/dict/sop';
import type { StepOverviewList, StepOverviewData, StepRowData } from '../types';

/**
 * 根据id查找步骤/步骤组
 */
export function getById(rootSteps: StepOverviewList, id: number | undefined) {
  if (id === 0) {
    return 'root';
  }
  let searchList = rootSteps;

  while (searchList.length > 0) {
    const res = searchList.find((step) => step.id === id);

    if (res) {
      return res;
    }
    searchList = _.flatten(
      searchList
        .filter((item) => _.isArray(item.children))
        .map(({ children }) => children as StepOverviewList),
    );
  }
  return null;
}

/**
 * 根据id查找父级步骤组
 */
export function getParentById(rootSteps: StepOverviewList, id: number | undefined) {
  // 先在顶级找自己, 如果找到了, parent就是根节点
  const rootRes = rootSteps.find((step) => step.id === id);

  if (rootRes) {
    return 'root';
  }

  let searchList = rootSteps;

  while (searchList.length > 0) {
    const res = searchList
      .filter((step) => step.type === StepEntityType.stepGroup)
      .find((step) => (step.children as StepOverviewList).find((s) => s.id === id));

    if (res) {
      return res;
    }
    searchList = _.flatten(
      searchList
        .filter((item) => _.isArray(item.children))
        .map(({ children }) => children as StepOverviewList),
    );
  }
  return null;
}

/**
 * 根据id查找自己的兄弟姐妹
 */
export function getSiblingsById(
  rootSteps: StepOverviewList | StepRowData[],
  id: number,
  keepSelf = false,
) {
  let sublingsList = [rootSteps];

  while (sublingsList.length > 0) {
    const sublings = sublingsList.shift() as StepOverviewList;

    if (sublings.find((step) => step.id === id)) {
      return keepSelf ? sublings : sublings.filter((step) => step.id !== id);
    }
    sublingsList = sublingsList.concat(
      sublings.map((step) => (step?.children as StepOverviewList) ?? []),
    );
  }
  return [];
}

/**
 * 根据父级步骤组的id查找自己的兄弟姐妹
 */
export function getSiblingsByParentId(rootSteps: StepOverviewList, parentId: number | undefined) {
  if (!parentId) {
    return rootSteps;
  }
  let searchList = rootSteps.slice(0);

  while (searchList.length > 0) {
    const step = searchList.shift() as StepOverviewData;

    if (step.id === parentId) {
      return step?.children as StepOverviewList;
    }
    searchList = searchList.concat((step?.children as StepOverviewList) ?? []);
  }
  return [];
}

/**
 * 判断是否为所在步骤组的最后一个步骤/组
 */
export function isLastStep(rootSteps: StepOverviewList, id: number) {
  // 插在首位, 只能是空步骤组
  if (id === 0) {
    return true;
  }
  const siblings = getSiblingsById(rootSteps, id, true);

  return _.last(siblings)?.id === id;
}

/**
 * 提取只有步骤组的树, 供复制步骤时选择
 * @param rootSteps 根步骤列表
 */
export function getGroupTree(rootSteps: StepOverviewList) {
  function getNode(step: StepOverviewList[number]): any {
    return {
      title: step.name,
      value: step.id,
      children: (step.children as StepOverviewList)
        ?.filter((item) => item.type === StepEntityType.stepGroup)
        .map(getNode),
    };
  }

  const root = {
    title: '根节点',
    value: 0,
    children: rootSteps.filter((item) => item.type === StepEntityType.stepGroup).map(getNode),
  };

  return [root];
}

/** 对所有步骤递归进行格式转换 */
export function mapSteps(
  steps: StepOverviewList | undefined,
  mapper: (step: StepOverviewList[number]) => StepOverviewData,
): any[] {
  if (!steps) {
    return [];
  }
  return steps.map((step) => ({
    ...mapper(step),
    children: mapSteps(step.children as StepOverviewList, mapper),
  }));
}

/** 统计总共有多少个步骤、步骤组 */
export function countSteps(steps: StepOverviewList) {
  let stepCount = 0;
  let stepGroupCount = 0;
  let toBeTravelled = steps.slice(0);

  while (toBeTravelled.length > 0) {
    const current = toBeTravelled.shift();

    if (current?.type === StepEntityType.stepGroup) {
      ++stepGroupCount;
      toBeTravelled = toBeTravelled.concat(current.children as StepOverviewList);
    } else {
      ++stepCount;
    }
  }
  return { stepCount, stepGroupCount };
}

/** 父节点是否为并行步骤组 */
export function isParentParallel(
  rootSteps: StepOverviewList,
  id: number,
  isParentId = false,
): boolean {
  const parent = isParentId ? getById(rootSteps, id) : getParentById(rootSteps, id);

  if (!parent || parent === 'root') {
    return false;
  }
  return parent.groupType === GroupType.parallel;
}

/** 是否存在循环的祖先节点 */
export function hasLoopableAncestor(
  rootSteps: StepOverviewList,
  id: number,
  isParentId = false,
): boolean {
  const parent = isParentId ? getById(rootSteps, id) : getParentById(rootSteps, id);

  if (!parent || parent === 'root') {
    return false;
  }
  if (parent.enableSelfLoop) {
    return true;
  }
  return hasLoopableAncestor(rootSteps, parent.id);
}

/** 查找一个步骤被其他哪些步骤用作后续步骤 */
export function getPreviousSiblings(steps: StepOverviewList, stepId: number) {
  const siblings = getSiblingsById(steps, stepId);

  return siblings.filter((step) => step.manualNextStepIds?.includes(stepId));
}

// 查找节点，并返回当前所属的父节点
export function treeFindParent(tree: StepRowData[], id: number) {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].id === id) return tree;
    if (tree[i].children) {
      const res: any = treeFindParent(tree[i].children, id);

      if (res) return res;
    }
  }
  return null;
}

/** 查找祖先节点id */
export function getAncestorIds(steps: StepOverviewList, stepId: number) {
  let parent = getParentById(steps, stepId);
  const res = [];

  while (parent && typeof parent !== 'string') {
    res.push(parent.id);
    parent = getParentById(steps, parent.id);
  }
  return res;
}

export function getAncestorIdsByParentId(steps: StepOverviewList, parentId: number) {
  const res: number[] = [];

  if (!parentId) {
    return res;
  }
  res.push(parentId);
  let parent = getParentById(steps, parentId);

  while (parent && typeof parent !== 'string') {
    res.push(parent.id);
    parent = getParentById(steps, parent.id);
  }
  return res;
}
