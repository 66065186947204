import React, { useContext, useState } from 'react';
import { BuilderContext, useDrawer } from 'react-flow-builder';
import { Form, Button, Input, Select, Row, Col } from 'antd';
import {
  approvalDesignationMethodOptions,
  approvalDesignationObjectOptions,
} from 'src/dict/processManagement/mappings';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import {
  ApprovalDesignationMethodEnum,
  ApprovalDesignationObjectEnum,
} from 'src/dict/processManagement';
import Styles from './styles.module.scss';
import RoleSelect from 'src/page/organization/components/roleSelect';
import { textValidator1, textValidator2 } from 'src/utils/formValidators';
import NumberRulesForm from '../NumberRulesStandardHook/NumberRulesForm';
import { Item } from 'src/page/organization/noticeTemplate/interface';

const ConfigForm: React.FC = () => {
  const { selectedNode: node } = useContext(BuilderContext);

  const { closeDrawer: cancel, saveDrawer: save } = useDrawer();
  const [isChangeField, setIsChangeField] = useState<boolean>(false);

  const [form] = Form.useForm();

  const handleSetIsChangeField = () => {
    setIsChangeField(true);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      if (isChangeField) {
        // 当前节点做了修改 才去保存没有就直接取消
        save?.({
          ...values,
          configured: 1,
          nodeFormType: node?.data?.nodeFormType,
          isChangeFieldValue: isChangeField ? 1 : 0,
        });
      } else {
        cancel();
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  /**
   * 校验审批人最大数量限制
   */
  const validatorMaxCount = (rule: any, value: Item[]) => {
    const approverType = form.getFieldValue('approverType');

    if (approverType === ApprovalDesignationObjectEnum.user && value?.length > 100) {
      return Promise.reject('最多添加100个审批人');
    }

    return Promise.resolve(true);
  };

  let infoBlock;

  switch (node?.data?.nodeFormType) {
    case 'approvingCommonNodes':
      infoBlock = (
        <div>
          <div className={Styles?.title}>节点信息</div>
          <NumberRulesForm
            label="编号"
            form={form}
            edit={node?.data?.isEdit}
            objectCode="ApprovalNode"
            fieldCode="code"
            rules={[{ max: 64, message: '请限制在64个字符以内' }, { validator: textValidator2 }]}
            onChangeCheckBox={handleSetIsChangeField}
          />
          <Row>
            <Col span={node?.data?.isEdit ? 24 : 20}>
              <Form.Item
                name="name"
                label="名称"
                rules={[
                  { required: true, message: '请输入名称' },
                  { max: 255, message: '请限制在255个字符以内' },
                  { validator: textValidator1 },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="审批对象" className={Styles?.approvalObject} required>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form.Item
                name="approverType"
                rules={[{ required: true }]}
                style={{ width: 100, marginRight: 10 }}
                initialValue={ApprovalDesignationObjectEnum.user}
              >
                <Select
                  options={approvalDesignationObjectOptions}
                  onChange={() => {
                    form.setFieldsValue({ approverList: [] });
                  }}
                />
              </Form.Item>
              <Form.Item dependencies={['approverType']} style={{ width: 370 }}>
                {() => {
                  const { approverType } = form.getFieldsValue();

                  if (approverType === ApprovalDesignationObjectEnum.role) {
                    return (
                      <Form.Item
                        name="approverList"
                        rules={[{ required: true, message: '请选择审批对象' }]}
                      >
                        <RoleSelect isMultiple params={{ excludeCodes: ['SCM_virtual_role'] }} />
                      </Form.Item>
                    );
                  }

                  return (
                    <Form.Item
                      name="approverList"
                      rules={[
                        { required: true, message: '请选择审批对象' },
                        {
                          validator: validatorMaxCount,
                        },
                      ]}
                    >
                      <UserOrDepartmentSelectWithDialog<UserColumnProps>
                        isMultiple
                        isSelectUser={approverType === ApprovalDesignationObjectEnum.user}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </div>
          </Form.Item>
          <Row>
            <Col span={node?.data?.isEdit ? 24 : 20}>
              <Form.Item
                name="approveMethod"
                label="多人审批方式"
                rules={[{ required: true, message: '请选择多人审批方式' }]}
                initialValue={ApprovalDesignationMethodEnum.orSing}
              >
                <Select options={approvalDesignationMethodOptions} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      );
      break;
    case 'approvingConditionNodes':
      infoBlock = (
        <>
          <Form.Item name="name" label="名称" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </>
      );
      break;

    default:
      infoBlock = (
        <Form.Item name="code" label="编号" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      );
      break;
  }

  return (
    <div className={Styles?.flowForm}>
      <Form
        form={form}
        initialValues={node?.data}
        onValuesChange={(changedValues: any) => {
          if (changedValues) {
            setIsChangeField(true);
          }
        }}
      >
        {infoBlock}
      </Form>
      <div className={Styles?.boxButton}>
        <Button onClick={cancel} style={{ marginRight: 16 }}>
          取消
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          保存并返回
        </Button>
      </div>
    </div>
  );
};

export default ConfigForm;
