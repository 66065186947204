/**
 * 工序串
 */
import { forwardRef, memo, useEffect, useState } from 'react';
import { FormInstance } from 'antd';
import FlowChart from './flowChart';
import _ from 'lodash';

interface ProcessProps {
  processes: any;
  processConnections: any;
}
interface Props {
  name?: string;
  form?: FormInstance;
  value?: ProcessProps;
  isWorkOrder?: Boolean;
  useType?: number; // 确定该组件用于哪个模块下，0-bom(不传即可)、1-生产工单(暂不用这个确定，暂用isWorkOrder)、2-计划订单(传 2)
  isEditMode: Boolean;
}

const ProcessRoutingForm = forwardRef((props: Props, ref) => {
  const { form, value, isWorkOrder, name, useType, isEditMode } = props;

  const [data, setData] = useState([]);
  const [edge, setEdge] = useState([]);

  useEffect(() => {
    if (value) {
      setData(value?.processes);
      setEdge(value?.processConnections);
    }
  }, [value]);

  return (
    <FlowChart
      ref={ref}
      data={data}
      edge={edge}
      isWorkOrder={isWorkOrder}
      useType={useType}
      name={name}
      form={form}
      startSeq={10}
      defaultSelectedKey={0}
      isEditMode={isEditMode}
    />
  );
});

export default memo(ProcessRoutingForm);
