import { VIEW_MODAL } from './constant';
import { TabList } from 'src/components';
import TriggerRulesList from './list';

const TriggerRulesTabList = (props: any) => {
  const tabList = [
    {
      tab: '通用规则',
      key: VIEW_MODAL.generalRule,
      component: <TriggerRulesList {...props} ruleSource={VIEW_MODAL.generalRule} />,
    },
    {
      tab: '业务规则',
      key: VIEW_MODAL.businessRule,
      component: <TriggerRulesList {...props} ruleSource={VIEW_MODAL.businessRule} />,
    },
  ];

  return <TabList tabList={tabList} defaultKey={VIEW_MODAL.generalRule} />;
};

export default TriggerRulesTabList;
