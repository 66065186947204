/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../request';

// makeRequest
function makeRequestRequired<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequestOptional<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData?: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequest<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const optional = makeRequestOptional<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  const required = makeRequestRequired<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  return (
    requestConfig.requestDataOptional ? optional : required
  ) as TRequestConfig['requestDataOptional'] extends true ? typeof optional : typeof required;
}

// Request
export type Request<TReqeustData, TRequestConfig extends RequestConfig, TRequestResult> =
  (TRequestConfig['requestDataOptional'] extends true
    ? (
        requestData?: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult
    : (
        requestData: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult) & {
    requestConfig: TRequestConfig;
  };

const mockUrl_0_6_0_1 = 'http://yapi.blacklake.tech/mock/543' as any;
const devUrl_0_6_0_1 = '' as any;
const prodUrl_0_6_0_1 = '' as any;
const dataKey_0_6_0_1 = undefined as any;

/**
 * 接口 [编号规则新增↗](http://yapi.blacklake.tech/project/543/interface/api/77696) 的 **请求类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_create`
 * @更新时间 `2021-11-09 15:16:00`
 */
export interface FetchNumberRuleCreateRequest {
  /**
   * 规则编号元素
   */
  elementList?: {
    /**
     * 编码格式
     */
    codeFormat?: number;
    /**
     * 设置值
     */
    constantValue?: string;
    /**
     * 变量型规则明细关联的字段
     */
    elementFields?: {
      /**
       * 关联字段的层级
       */
      diffLevel?: number;
      /**
       * 业务id
       */
      id?: number;
      /**
       * 关联的字段显示名称
       */
      relFieldDisplayName?: string;
      /**
       * 关联的字段id
       */
      relFieldId?: number;
      /**
       * 关联的字段name
       */
      relFieldName?: string;
      /**
       * 关联的字段对象code
       */
      relFieldObjCode?: string;
      /**
       * 关联的自定义规则明细的id
       */
      relRuleItemId?: number;
    }[];
    /**
     * 元素长度
     */
    elementLength?: number;
    /**
     * 元素类型
     */
    elementType: number;
    /**
     * 格式配置ID
     */
    formatConfigId?: number;
    /**
     * 编号依据（1:是/0:否）
     */
    numberBasis: number;
    /**
     * 编号规则id
     */
    numberRuleId: number;
    /**
     * 重置周期
     */
    resetPeriod?: number;
    /**
     * 序号
     */
    sortNo: number;
    /**
     * 起始值
     */
    startValue?: number;
    /**
     * 步长
     */
    stepSize?: number;
  }[];
  /**
   * 启停状态(1:启用/0:停用)
   */
  enableFlag?: number;
  /**
   * 规则编号
   */
  ruleCode: string;
  /**
   * 规则名称
   */
  ruleName: string;
  /**
   * 描述
   */
  ruleRemark?: string;
  /**
   * 适用对象ID
   */
  suitObjId: number;
}

/**
 * 接口 [编号规则新增↗](http://yapi.blacklake.tech/project/543/interface/api/77696) 的 **返回类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_create`
 * @更新时间 `2021-11-09 15:16:00`
 */
export interface FetchNumberRuleCreateResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 创建时间
     */
    createdAt?: string;
    /**
     * 创建人id
     */
    creatorId?: number;
    /**
     * 启停状态(1:启用/0:停用)
     */
    enableFlag?: number;
    /**
     * 规则id
     */
    id?: number;
    /**
     * 操作人id
     */
    operatorId?: number;
    /**
     * 规则编号
     */
    ruleCode: string;
    /**
     * 规则名称
     */
    ruleName: string;
    /**
     * 描述
     */
    ruleRemark?: string;
    /**
     * 适用对象ID
     */
    suitObjId: number;
    /**
     * 更新时间
     */
    updatedAt?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [编号规则新增↗](http://yapi.blacklake.tech/project/543/interface/api/77696) 的 **请求配置的类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_create`
 * @更新时间 `2021-11-09 15:16:00`
 */
type FetchNumberRuleCreateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/543',
    '',
    '',
    '/custom/domain/web/v1/number_rule/_create',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [编号规则新增↗](http://yapi.blacklake.tech/project/543/interface/api/77696) 的 **请求配置**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_create`
 * @更新时间 `2021-11-09 15:16:00`
 */
const fetchNumberRuleCreateRequestConfig: FetchNumberRuleCreateRequestConfig = {
  mockUrl: mockUrl_0_6_0_1,
  devUrl: devUrl_0_6_0_1,
  prodUrl: prodUrl_0_6_0_1,
  path: '/custom/domain/web/v1/number_rule/_create',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_6_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchNumberRuleCreate',
};

/**
 * 接口 [编号规则新增↗](http://yapi.blacklake.tech/project/543/interface/api/77696) 的 **请求函数**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_create`
 * @更新时间 `2021-11-09 15:16:00`
 */
export const fetchNumberRuleCreate = makeRequest<
  FetchNumberRuleCreateRequest,
  FetchNumberRuleCreateResponse,
  FetchNumberRuleCreateRequestConfig
>(fetchNumberRuleCreateRequestConfig);

/**
 * 接口 [根据id删除编号规则↗](http://yapi.blacklake.tech/project/543/interface/api/77702) 的 **请求类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_delete`
 * @更新时间 `2021-11-09 15:16:00`
 */
export type FetchNumberRuleDeleteRequest = {
  /**
   * 编码规则id
   */
  id: number;
}[];

/**
 * 接口 [根据id删除编号规则↗](http://yapi.blacklake.tech/project/543/interface/api/77702) 的 **返回类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_delete`
 * @更新时间 `2021-11-09 15:16:00`
 */
export interface FetchNumberRuleDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [根据id删除编号规则↗](http://yapi.blacklake.tech/project/543/interface/api/77702) 的 **请求配置的类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_delete`
 * @更新时间 `2021-11-09 15:16:00`
 */
type FetchNumberRuleDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/543',
    '',
    '',
    '/custom/domain/web/v1/number_rule/_delete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [根据id删除编号规则↗](http://yapi.blacklake.tech/project/543/interface/api/77702) 的 **请求配置**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_delete`
 * @更新时间 `2021-11-09 15:16:00`
 */
const fetchNumberRuleDeleteRequestConfig: FetchNumberRuleDeleteRequestConfig = {
  mockUrl: mockUrl_0_6_0_1,
  devUrl: devUrl_0_6_0_1,
  prodUrl: prodUrl_0_6_0_1,
  path: '/custom/domain/web/v1/number_rule/_delete',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_6_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchNumberRuleDelete',
};

/**
 * 接口 [根据id删除编号规则↗](http://yapi.blacklake.tech/project/543/interface/api/77702) 的 **请求函数**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_delete`
 * @更新时间 `2021-11-09 15:16:00`
 */
export const fetchNumberRuleDelete = makeRequest<
  FetchNumberRuleDeleteRequest,
  FetchNumberRuleDeleteResponse,
  FetchNumberRuleDeleteRequestConfig
>(fetchNumberRuleDeleteRequestConfig);

/**
 * 接口 [编号规则详情↗](http://yapi.blacklake.tech/project/543/interface/api/77708) 的 **请求类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_detail`
 * @更新时间 `2021-11-09 15:16:00`
 */
export interface FetchNumberRuleDetailRequest {
  /**
   * 编码规则id
   */
  id: number;
}

/**
 * 接口 [编号规则详情↗](http://yapi.blacklake.tech/project/543/interface/api/77708) 的 **返回类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_detail`
 * @更新时间 `2021-11-09 15:16:00`
 */
export interface FetchNumberRuleDetailResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 创建时间
     */
    createdAt?: string;
    /**
     * 创建人id
     */
    creatorId?: number;
    /**
     * 规则编号元素
     */
    elementList?: {
      /**
       * 编码格式
       */
      codeFormat?: number;
      /**
       * 设置值
       */
      constantValue?: string;
      /**
       * 变量型规则明细关联的字段
       */
      elementFields?: {
        /**
         * 关联字段的层级
         */
        diffLevel?: number;
        /**
         * 业务id
         */
        id?: number;
        /**
         * 关联的字段显示名称
         */
        relFieldDisplayName?: string;
        /**
         * 关联的字段id
         */
        relFieldId?: number;
        /**
         * 关联的字段name
         */
        relFieldName?: string;
        /**
         * 关联的字段对象code
         */
        relFieldObjCode?: string;
        /**
         * 关联的自定义规则明细的id
         */
        relRuleItemId?: number;
      }[];
      /**
       * 元素长度
       */
      elementLength?: number;
      /**
       * 元素类型
       */
      elementType: number;
      /**
       * 元素来源
       */
      formatConfigId?: number;
      /**
       * 规则元素id
       */
      id?: number;
      /**
       * 编号依据（1:是/0:否）
       */
      numberBasis: number;
      /**
       * 编号规则id
       */
      numberRuleId: number;
      /**
       * 重置周期
       */
      resetPeriod?: number;
      /**
       * 序号
       */
      sortNo: number;
      /**
       * 起始值
       */
      startValue?: number;
      /**
       * 步长
       */
      stepSize?: number;
    }[];
    /**
     * 启停状态(1:启用/0:停用)
     */
    enableFlag?: number;
    /**
     * 规则id
     */
    id?: string;
    /**
     * 操作人id
     */
    operatorId?: number;
    /**
     * 规则编号
     */
    ruleCode?: string;
    /**
     * 规则名称
     */
    ruleName?: string;
    /**
     * 描述
     */
    ruleRemark?: string;
    suitObj?: {
      createdAt?: string;
      creatorId?: number;
      id?: number;
      /**
       * 对象code
       */
      objectCode?: string;
      /**
       * 对象描述
       */
      objectDesc?: string;
      /**
       * 是否支持字段配置 0不可配置1可配置
       */
      objectFieldConfig?: number;
      /**
       * 对象name
       */
      objectName?: string;
      operatorId?: number;
      orgId?: number;
      updatedAt?: string;
    };
    /**
     * 更新时间
     */
    updatedAt?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [编号规则详情↗](http://yapi.blacklake.tech/project/543/interface/api/77708) 的 **请求配置的类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_detail`
 * @更新时间 `2021-11-09 15:16:00`
 */
type FetchNumberRuleDetailRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/543',
    '',
    '',
    '/custom/domain/web/v1/number_rule/_detail',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [编号规则详情↗](http://yapi.blacklake.tech/project/543/interface/api/77708) 的 **请求配置**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_detail`
 * @更新时间 `2021-11-09 15:16:00`
 */
const fetchNumberRuleDetailRequestConfig: FetchNumberRuleDetailRequestConfig = {
  mockUrl: mockUrl_0_6_0_1,
  devUrl: devUrl_0_6_0_1,
  prodUrl: prodUrl_0_6_0_1,
  path: '/custom/domain/web/v1/number_rule/_detail',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_6_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchNumberRuleDetail',
};

/**
 * 接口 [编号规则详情↗](http://yapi.blacklake.tech/project/543/interface/api/77708) 的 **请求函数**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_detail`
 * @更新时间 `2021-11-09 15:16:00`
 */
export const fetchNumberRuleDetail = makeRequest<
  FetchNumberRuleDetailRequest,
  FetchNumberRuleDetailResponse,
  FetchNumberRuleDetailRequestConfig
>(fetchNumberRuleDetailRequestConfig);

/**
 * 接口 [编号规则停用↗](http://yapi.blacklake.tech/project/543/interface/api/77714) 的 **请求类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_disable`
 * @更新时间 `2021-11-09 15:16:00`
 */
export type FetchNumberRuleDisableRequest = {
  /**
   * 编码规则id
   */
  id: number;
}[];

/**
 * 接口 [编号规则停用↗](http://yapi.blacklake.tech/project/543/interface/api/77714) 的 **返回类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_disable`
 * @更新时间 `2021-11-09 15:16:00`
 */
export interface FetchNumberRuleDisableResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [编号规则停用↗](http://yapi.blacklake.tech/project/543/interface/api/77714) 的 **请求配置的类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_disable`
 * @更新时间 `2021-11-09 15:16:00`
 */
type FetchNumberRuleDisableRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/543',
    '',
    '',
    '/custom/domain/web/v1/number_rule/_disable',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [编号规则停用↗](http://yapi.blacklake.tech/project/543/interface/api/77714) 的 **请求配置**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_disable`
 * @更新时间 `2021-11-09 15:16:00`
 */
const fetchNumberRuleDisableRequestConfig: FetchNumberRuleDisableRequestConfig = {
  mockUrl: mockUrl_0_6_0_1,
  devUrl: devUrl_0_6_0_1,
  prodUrl: prodUrl_0_6_0_1,
  path: '/custom/domain/web/v1/number_rule/_disable',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_6_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchNumberRuleDisable',
};

/**
 * 接口 [编号规则停用↗](http://yapi.blacklake.tech/project/543/interface/api/77714) 的 **请求函数**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_disable`
 * @更新时间 `2021-11-09 15:16:00`
 */
export const fetchNumberRuleDisable = makeRequest<
  FetchNumberRuleDisableRequest,
  FetchNumberRuleDisableResponse,
  FetchNumberRuleDisableRequestConfig
>(fetchNumberRuleDisableRequestConfig);

/**
 * 接口 [编号规则启用↗](http://yapi.blacklake.tech/project/543/interface/api/77720) 的 **请求类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_enable`
 * @更新时间 `2021-11-09 15:16:00`
 */
export type FetchNumberRuleEnableRequest = {
  /**
   * 编码规则id
   */
  id: number;
  /**
   * 适用对象ID
   */
  suitObjId: number;
}[];

/**
 * 接口 [编号规则启用↗](http://yapi.blacklake.tech/project/543/interface/api/77720) 的 **返回类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_enable`
 * @更新时间 `2021-11-09 15:16:00`
 */
export interface FetchNumberRuleEnableResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [编号规则启用↗](http://yapi.blacklake.tech/project/543/interface/api/77720) 的 **请求配置的类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_enable`
 * @更新时间 `2021-11-09 15:16:00`
 */
type FetchNumberRuleEnableRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/543',
    '',
    '',
    '/custom/domain/web/v1/number_rule/_enable',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [编号规则启用↗](http://yapi.blacklake.tech/project/543/interface/api/77720) 的 **请求配置**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_enable`
 * @更新时间 `2021-11-09 15:16:00`
 */
const fetchNumberRuleEnableRequestConfig: FetchNumberRuleEnableRequestConfig = {
  mockUrl: mockUrl_0_6_0_1,
  devUrl: devUrl_0_6_0_1,
  prodUrl: prodUrl_0_6_0_1,
  path: '/custom/domain/web/v1/number_rule/_enable',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_6_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchNumberRuleEnable',
};

/**
 * 接口 [编号规则启用↗](http://yapi.blacklake.tech/project/543/interface/api/77720) 的 **请求函数**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_enable`
 * @更新时间 `2021-11-09 15:16:00`
 */
export const fetchNumberRuleEnable = makeRequest<
  FetchNumberRuleEnableRequest,
  FetchNumberRuleEnableResponse,
  FetchNumberRuleEnableRequestConfig
>(fetchNumberRuleEnableRequestConfig);

/**
 * 接口 [取发号器的号↗](http://yapi.blacklake.tech/project/543/interface/api/77726) 的 **请求类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_number`
 * @更新时间 `2021-11-05 10:12:00`
 */
export interface FetchNumberRuleGetNumberRequest {
  /**
   * 序列号类型
   */
  settings: {
    /**
     * 请求数量
     */
    count: number;
    /**
     * 重置周期:日
     */
    factorD?: number;
    /**
     * 重置周期:月
     */
    factorM?: number;
    /**
     * 重置周期:周
     */
    factorW?: number;
    /**
     * 重置周期:年
     */
    factorY?: number;
    /**
     * 重置初始值
     */
    initSequence: number;
    /**
     * 长度
     */
    length: number;
    /**
     * 序列号类型
     */
    sequenceType: string;
    /**
     * 步长
     */
    step: number;
  }[];
}

/**
 * 接口 [取发号器的号↗](http://yapi.blacklake.tech/project/543/interface/api/77726) 的 **返回类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_number`
 * @更新时间 `2021-11-05 10:12:00`
 */
export interface FetchNumberRuleGetNumberResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    sequences?: {};
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [取发号器的号↗](http://yapi.blacklake.tech/project/543/interface/api/77726) 的 **请求配置的类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_number`
 * @更新时间 `2021-11-05 10:12:00`
 */
type FetchNumberRuleGetNumberRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/543',
    '',
    '',
    '/custom/domain/web/v1/number_rule/_get_number',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [取发号器的号↗](http://yapi.blacklake.tech/project/543/interface/api/77726) 的 **请求配置**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_number`
 * @更新时间 `2021-11-05 10:12:00`
 */
const fetchNumberRuleGetNumberRequestConfig: FetchNumberRuleGetNumberRequestConfig = {
  mockUrl: mockUrl_0_6_0_1,
  devUrl: devUrl_0_6_0_1,
  prodUrl: prodUrl_0_6_0_1,
  path: '/custom/domain/web/v1/number_rule/_get_number',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_6_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchNumberRuleGetNumber',
};

/**
 * 接口 [取发号器的号↗](http://yapi.blacklake.tech/project/543/interface/api/77726) 的 **请求函数**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_number`
 * @更新时间 `2021-11-05 10:12:00`
 */
export const fetchNumberRuleGetNumber = makeRequest<
  FetchNumberRuleGetNumberRequest,
  FetchNumberRuleGetNumberResponse,
  FetchNumberRuleGetNumberRequestConfig
>(fetchNumberRuleGetNumberRequestConfig);

/**
 * 接口 [根据对象code获取树结构↗](http://yapi.blacklake.tech/project/543/interface/api/77732) 的 **请求类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_source_by_obj_code`
 * @更新时间 `2021-11-09 15:16:00`
 */
export interface FetchNumberRuleGetSourceByObjCodeRequest {
  code?: string;
}

/**
 * 接口 [根据对象code获取树结构↗](http://yapi.blacklake.tech/project/543/interface/api/77732) 的 **返回类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_source_by_obj_code`
 * @更新时间 `2021-11-09 15:16:00`
 */
export interface FetchNumberRuleGetSourceByObjCodeResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    creatorId?: number;
    fields?: {
      /**
       * 日期格式
       */
      datetimeFormat?: string;
      /**
       * 小数位
       */
      decimalNumber?: number;
      /**
       * 默认值
       */
      defaultValue?: string;
      /**
       * 字段类别
       */
      fieldCategory?: number;
      /**
       * 字段code
       */
      fieldCode?: string;
      /**
       * 字段名称
       */
      fieldName?: string;
      /**
       * 字段权限
       */
      fieldPermission?: number;
      /**
       * 字段提示
       */
      fieldRemind?: string;
      /**
       * 字段类型
       */
      fieldType?: number;
      id?: number;
      /**
       * 编号规则是否支持 0 不支持 1 支持
       */
      isNumberRuleConfig?: number;
      /**
       * 是否必填
       */
      isRequired?: number;
      /**
       * 是否唯一
       */
      isUnique?: number;
      /**
       * 是否使用
       */
      isUsed?: number;
      /**
       * 最大长度
       */
      maxLength?: number;
      /**
       * 最大值
       */
      maxValue?: number;
      /**
       * 编号规则id
       */
      numberRuleId?: number;
      object?: unknown;
      orgId?: number;
      /**
       * 引用关系
       */
      reference?: string;
      /**
       * 所属标准对象code
       */
      relatedObjectCode?: string;
      /**
       * 所属标准对象id
       */
      relatedObjectId?: number;
    }[];
    id?: number;
    /**
     * 对象code
     */
    objectCode?: string;
    /**
     * 对象描述
     */
    objectDesc?: string;
    /**
     * 是否支持字段配置 0不可配置1可配置
     */
    objectFieldConfig?: number;
    /**
     * 对象name
     */
    objectName?: string;
    operatorId?: number;
    orgId?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [根据对象code获取树结构↗](http://yapi.blacklake.tech/project/543/interface/api/77732) 的 **请求配置的类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_source_by_obj_code`
 * @更新时间 `2021-11-09 15:16:00`
 */
type FetchNumberRuleGetSourceByObjCodeRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/543',
    '',
    '',
    '/custom/domain/web/v1/number_rule/_get_source_by_obj_code',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [根据对象code获取树结构↗](http://yapi.blacklake.tech/project/543/interface/api/77732) 的 **请求配置**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_source_by_obj_code`
 * @更新时间 `2021-11-09 15:16:00`
 */
const fetchNumberRuleGetSourceByObjCodeRequestConfig: FetchNumberRuleGetSourceByObjCodeRequestConfig =
  {
    mockUrl: mockUrl_0_6_0_1,
    devUrl: devUrl_0_6_0_1,
    prodUrl: prodUrl_0_6_0_1,
    path: '/custom/domain/web/v1/number_rule/_get_source_by_obj_code',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_6_0_1,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchNumberRuleGetSourceByObjCode',
  };

/**
 * 接口 [根据对象code获取树结构↗](http://yapi.blacklake.tech/project/543/interface/api/77732) 的 **请求函数**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_source_by_obj_code`
 * @更新时间 `2021-11-09 15:16:00`
 */
export const fetchNumberRuleGetSourceByObjCode = makeRequest<
  FetchNumberRuleGetSourceByObjCodeRequest,
  FetchNumberRuleGetSourceByObjCodeResponse,
  FetchNumberRuleGetSourceByObjCodeRequestConfig
>(fetchNumberRuleGetSourceByObjCodeRequestConfig);

/**
 * 接口 [根据对象获id取元素来源字段↗](http://yapi.blacklake.tech/project/543/interface/api/77738) 的 **请求类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_source_by_obj_id`
 * @更新时间 `2021-11-09 15:16:01`
 */
export interface FetchNumberRuleGetSourceByObjIdRequest {
  objectId?: number;
  orgId?: number;
}

/**
 * 接口 [根据对象获id取元素来源字段↗](http://yapi.blacklake.tech/project/543/interface/api/77738) 的 **返回类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_source_by_obj_id`
 * @更新时间 `2021-11-09 15:16:01`
 */
export interface FetchNumberRuleGetSourceByObjIdResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    createdAt?: string;
    creatorId?: number;
    /**
     * 日期格式
     */
    datetimeFormat?: string;
    /**
     * 小数位
     */
    decimalNumber?: number;
    /**
     * 默认值
     */
    defaultValue?: string;
    /**
     * 字段类别
     */
    fieldCategory?: number;
    /**
     * 字段code
     */
    fieldCode?: string;
    /**
     * 字段名称
     */
    fieldName?: string;
    /**
     * 字段权限
     */
    fieldPermission?: number;
    /**
     * 字段提示
     */
    fieldRemind?: string;
    /**
     * 字段类型
     */
    fieldType?: number;
    id?: number;
    /**
     * 编号规则是否支持 0 不支持 1 支持
     */
    isNumberRuleConfig?: number;
    /**
     * 是否必填
     */
    isRequired?: number;
    /**
     * 是否唯一
     */
    isUnique?: number;
    /**
     * 是否使用
     */
    isUsed?: number;
    /**
     * 最大长度
     */
    maxLength?: number;
    /**
     * 最大值
     */
    maxValue?: number;
    /**
     * 编号规则id
     */
    numberRuleId?: number;
    operatorId?: number;
    orgId?: number;
    /**
     * 引用关系
     */
    reference?: string;
    /**
     * 所属标准对象code
     */
    relatedObjectCode?: string;
    /**
     * 所属标准对象id
     */
    relatedObjectId?: number;
    updatedAt?: string;
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [根据对象获id取元素来源字段↗](http://yapi.blacklake.tech/project/543/interface/api/77738) 的 **请求配置的类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_source_by_obj_id`
 * @更新时间 `2021-11-09 15:16:01`
 */
type FetchNumberRuleGetSourceByObjIdRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/543',
    '',
    '',
    '/custom/domain/web/v1/number_rule/_get_source_by_obj_id',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [根据对象获id取元素来源字段↗](http://yapi.blacklake.tech/project/543/interface/api/77738) 的 **请求配置**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_source_by_obj_id`
 * @更新时间 `2021-11-09 15:16:01`
 */
const fetchNumberRuleGetSourceByObjIdRequestConfig: FetchNumberRuleGetSourceByObjIdRequestConfig = {
  mockUrl: mockUrl_0_6_0_1,
  devUrl: devUrl_0_6_0_1,
  prodUrl: prodUrl_0_6_0_1,
  path: '/custom/domain/web/v1/number_rule/_get_source_by_obj_id',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_6_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchNumberRuleGetSourceByObjId',
};

/**
 * 接口 [根据对象获id取元素来源字段↗](http://yapi.blacklake.tech/project/543/interface/api/77738) 的 **请求函数**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_source_by_obj_id`
 * @更新时间 `2021-11-09 15:16:01`
 */
export const fetchNumberRuleGetSourceByObjId = makeRequest<
  FetchNumberRuleGetSourceByObjIdRequest,
  FetchNumberRuleGetSourceByObjIdResponse,
  FetchNumberRuleGetSourceByObjIdRequestConfig
>(fetchNumberRuleGetSourceByObjIdRequestConfig);

/**
 * 接口 [编号规则列表↗](http://yapi.blacklake.tech/project/543/interface/api/77744) 的 **请求类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_list`
 * @更新时间 `2021-11-09 15:16:01`
 */
export interface FetchNumberRuleListRequest {
  /**
   * 规则编号元素
   */
  elementList?: {
    /**
     * 编码格式
     */
    codeFormat?: number;
    /**
     * 设置值
     */
    constantValue?: string;
    /**
     * 变量型规则明细关联的字段
     */
    elementFields?: {
      /**
       * 关联字段的层级
       */
      diffLevel?: number;
      /**
       * 业务id
       */
      id?: number;
      /**
       * 关联的字段显示名称
       */
      relFieldDisplayName?: string;
      /**
       * 关联的字段id
       */
      relFieldId?: number;
      /**
       * 关联的字段name
       */
      relFieldName?: string;
      /**
       * 关联的字段对象code
       */
      relFieldObjCode?: string;
      /**
       * 关联的自定义规则明细的id
       */
      relRuleItemId?: number;
    }[];
    /**
     * 元素长度
     */
    elementLength?: number;
    /**
     * 元素类型
     */
    elementType: number;
    /**
     * 格式配置ID
     */
    formatConfigId?: number;
    /**
     * 编号依据（1:是/0:否）
     */
    numberBasis: number;
    /**
     * 编号规则id
     */
    numberRuleId: number;
    /**
     * 重置周期
     */
    resetPeriod?: number;
    /**
     * 序号
     */
    sortNo: number;
    /**
     * 起始值
     */
    startValue?: number;
    /**
     * 步长
     */
    stepSize?: number;
  }[];
  /**
   * 启停状态(1:启用/0:停用)
   */
  enableFlag?: number;
  /**
   * 排序所依据的字段名:如根据名称排序 不关心顺序倒叙 传入name
   */
  orderBy?: string;
  /**
   * 分页page
   */
  page: number;
  /**
   * 快速搜索框内容
   */
  quickQueryParam?: string;
  /**
   * 规则编号
   */
  ruleCode?: string;
  /**
   * 规则名称
   */
  ruleName?: string;
  /**
   * 描述
   */
  ruleRemark?: string;
  /**
   * 分页size
   */
  size: number;
  /**
   * 排序方式 顺序ASC 倒叙DESC 不关心根据什么字段排序 如根据名称顺序排列传人ASC
   */
  sort?: string;
  /**
   * 适用对象ID
   */
  suitObjId?: number;
}

/**
 * 接口 [编号规则列表↗](http://yapi.blacklake.tech/project/543/interface/api/77744) 的 **返回类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_list`
 * @更新时间 `2021-11-09 15:16:01`
 */
export interface FetchNumberRuleListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * 创建时间
       */
      createdAt?: string;
      /**
       * 创建人id
       */
      creatorId?: number;
      /**
       * 启停状态(1:启用/0:停用)
       */
      enableFlag?: number;
      /**
       * 规则id
       */
      id?: number;
      /**
       * 操作人id
       */
      operatorId?: number;
      /**
       * 规则编号
       */
      ruleCode?: string;
      /**
       * 规则名称
       */
      ruleName?: string;
      /**
       * 描述
       */
      ruleRemark?: string;
      suitObj?: {
        createdAt?: string;
        creatorId?: number;
        id?: number;
        /**
         * 对象code
         */
        objectCode?: string;
        /**
         * 对象描述
         */
        objectDesc?: string;
        /**
         * 是否支持字段配置 0不可配置1可配置
         */
        objectFieldConfig?: number;
        /**
         * 对象name
         */
        objectName?: string;
        operatorId?: number;
        orgId?: number;
        updatedAt?: string;
      };
      /**
       * 更新时间
       */
      updatedAt?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [编号规则列表↗](http://yapi.blacklake.tech/project/543/interface/api/77744) 的 **请求配置的类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_list`
 * @更新时间 `2021-11-09 15:16:01`
 */
type FetchNumberRuleListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/543',
    '',
    '',
    '/custom/domain/web/v1/number_rule/_list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [编号规则列表↗](http://yapi.blacklake.tech/project/543/interface/api/77744) 的 **请求配置**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_list`
 * @更新时间 `2021-11-09 15:16:01`
 */
const fetchNumberRuleListRequestConfig: FetchNumberRuleListRequestConfig = {
  mockUrl: mockUrl_0_6_0_1,
  devUrl: devUrl_0_6_0_1,
  prodUrl: prodUrl_0_6_0_1,
  path: '/custom/domain/web/v1/number_rule/_list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_6_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchNumberRuleList',
};

/**
 * 接口 [编号规则列表↗](http://yapi.blacklake.tech/project/543/interface/api/77744) 的 **请求函数**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_list`
 * @更新时间 `2021-11-09 15:16:01`
 */
export const fetchNumberRuleList = makeRequest<
  FetchNumberRuleListRequest,
  FetchNumberRuleListResponse,
  FetchNumberRuleListRequestConfig
>(fetchNumberRuleListRequestConfig);

/**
 * 接口 [获取标准对象↗](http://yapi.blacklake.tech/project/543/interface/api/77750) 的 **请求类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_list_biz_object`
 * @更新时间 `2021-11-09 15:16:01`
 */
export interface FetchNumberRuleListBizObjectRequest {}

/**
 * 接口 [获取标准对象↗](http://yapi.blacklake.tech/project/543/interface/api/77750) 的 **返回类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_list_biz_object`
 * @更新时间 `2021-11-09 15:16:01`
 */
export interface FetchNumberRuleListBizObjectResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    createdAt?: string;
    creatorId?: number;
    id?: number;
    /**
     * 对象code
     */
    objectCode?: string;
    /**
     * 对象描述
     */
    objectDesc?: string;
    /**
     * 是否支持字段配置 0不可配置1可配置
     */
    objectFieldConfig?: number;
    /**
     * 对象name
     */
    objectName?: string;
    operatorId?: number;
    orgId?: number;
    updatedAt?: string;
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取标准对象↗](http://yapi.blacklake.tech/project/543/interface/api/77750) 的 **请求配置的类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_list_biz_object`
 * @更新时间 `2021-11-09 15:16:01`
 */
type FetchNumberRuleListBizObjectRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/543',
    '',
    '',
    '/custom/domain/web/v1/number_rule/_list_biz_object',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [获取标准对象↗](http://yapi.blacklake.tech/project/543/interface/api/77750) 的 **请求配置**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_list_biz_object`
 * @更新时间 `2021-11-09 15:16:01`
 */
const fetchNumberRuleListBizObjectRequestConfig: FetchNumberRuleListBizObjectRequestConfig = {
  mockUrl: mockUrl_0_6_0_1,
  devUrl: devUrl_0_6_0_1,
  prodUrl: prodUrl_0_6_0_1,
  path: '/custom/domain/web/v1/number_rule/_list_biz_object',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_6_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchNumberRuleListBizObject',
};

/**
 * 接口 [获取标准对象↗](http://yapi.blacklake.tech/project/543/interface/api/77750) 的 **请求函数**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_list_biz_object`
 * @更新时间 `2021-11-09 15:16:01`
 */
export const fetchNumberRuleListBizObject = makeRequest<
  FetchNumberRuleListBizObjectRequest,
  FetchNumberRuleListBizObjectResponse,
  FetchNumberRuleListBizObjectRequestConfig
>(fetchNumberRuleListBizObjectRequestConfig);

/**
 * 接口 [获取格式信息↗](http://yapi.blacklake.tech/project/543/interface/api/77756) 的 **请求类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_list_format_config`
 * @更新时间 `2021-11-09 15:16:01`
 */
export interface FetchNumberRuleListFormatConfigRequest {}

/**
 * 接口 [获取格式信息↗](http://yapi.blacklake.tech/project/543/interface/api/77756) 的 **返回类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_list_format_config`
 * @更新时间 `2021-11-09 15:16:01`
 */
export interface FetchNumberRuleListFormatConfigResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 展示样式
     */
    displayType?: string;
    /**
     * 格式化样式
     */
    formatContext?: string;
    /**
     * 字段id
     */
    id?: number;
    /**
     * 元素类型
     */
    suitType?: number;
    /**
     * 变量类型元素
     */
    textCase?: number;
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取格式信息↗](http://yapi.blacklake.tech/project/543/interface/api/77756) 的 **请求配置的类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_list_format_config`
 * @更新时间 `2021-11-09 15:16:01`
 */
type FetchNumberRuleListFormatConfigRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/543',
    '',
    '',
    '/custom/domain/web/v1/number_rule/_list_format_config',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [获取格式信息↗](http://yapi.blacklake.tech/project/543/interface/api/77756) 的 **请求配置**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_list_format_config`
 * @更新时间 `2021-11-09 15:16:01`
 */
const fetchNumberRuleListFormatConfigRequestConfig: FetchNumberRuleListFormatConfigRequestConfig = {
  mockUrl: mockUrl_0_6_0_1,
  devUrl: devUrl_0_6_0_1,
  prodUrl: prodUrl_0_6_0_1,
  path: '/custom/domain/web/v1/number_rule/_list_format_config',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_6_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchNumberRuleListFormatConfig',
};

/**
 * 接口 [获取格式信息↗](http://yapi.blacklake.tech/project/543/interface/api/77756) 的 **请求函数**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_list_format_config`
 * @更新时间 `2021-11-09 15:16:01`
 */
export const fetchNumberRuleListFormatConfig = makeRequest<
  FetchNumberRuleListFormatConfigRequest,
  FetchNumberRuleListFormatConfigResponse,
  FetchNumberRuleListFormatConfigRequestConfig
>(fetchNumberRuleListFormatConfigRequestConfig);

/**
 * 接口 [编号规则修改↗](http://yapi.blacklake.tech/project/543/interface/api/77762) 的 **请求类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_update`
 * @更新时间 `2021-11-09 15:16:01`
 */
export interface FetchNumberRuleUpdateRequest {
  CODE_PT?: {
    cursor?: number;
  };
  DESC_NAME_AND_REMARK_PT?: {
    cursor?: number;
  };
  /**
   * 规则编号元素
   */
  elementList?: {
    /**
     * 编码格式
     */
    codeFormat?: number;
    /**
     * 设置值
     */
    constantValue?: string;
    /**
     * 变量型规则明细关联的字段
     */
    elementFields?: {
      createdAt?: string;
      creatorId?: number;
      diffLevel?: number;
      id?: number;
      operatorId?: number;
      orgId?: number;
      relFieldId?: number;
      relFieldName?: string;
      relFieldObjCode?: string;
      relRuleItemId?: number;
      updatedAt?: string;
    }[];
    /**
     * 元素长度
     */
    elementLength?: number;
    /**
     * 元素类型
     */
    elementType: number;
    /**
     * 格式配置ID
     */
    formatConfigId?: number;
    /**
     * 规则元素id
     */
    id?: number;
    /**
     * 编号依据（1:是/0:否）
     */
    numberBasis: number;
    /**
     * 编号规则id
     */
    numberRuleId: number;
    /**
     * 重置周期
     */
    resetPeriod?: number;
    /**
     * 序号
     */
    sortNo: number;
    /**
     * 起始值
     */
    startValue?: number;
    /**
     * 步长
     */
    stepSize?: number;
  }[];
  /**
   * 启停状态(1:启用/0:停用)
   */
  enableFlag?: number;
  /**
   * 规则id
   */
  id: number;
  /**
   * 规则编号
   */
  ruleCode: string;
  /**
   * 规则名称
   */
  ruleName: string;
  /**
   * 描述
   */
  ruleRemark?: string;
  /**
   * 适用对象ID
   */
  suitObjId: number;
}

/**
 * 接口 [编号规则修改↗](http://yapi.blacklake.tech/project/543/interface/api/77762) 的 **返回类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_update`
 * @更新时间 `2021-11-09 15:16:01`
 */
export interface FetchNumberRuleUpdateResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 创建时间
     */
    createdAt?: string;
    /**
     * 创建人id
     */
    creatorId?: number;
    /**
     * 启停状态(1:启用/0:停用)
     */
    enableFlag?: number;
    /**
     * 操作人id
     */
    operatorId?: number;
    /**
     * 规则编号
     */
    ruleCode: string;
    /**
     * 规则名称
     */
    ruleName: string;
    /**
     * 描述
     */
    ruleRemark?: string;
    /**
     * 适用对象ID
     */
    suitObjId: number;
    /**
     * 更新时间
     */
    updatedAt?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [编号规则修改↗](http://yapi.blacklake.tech/project/543/interface/api/77762) 的 **请求配置的类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_update`
 * @更新时间 `2021-11-09 15:16:01`
 */
type FetchNumberRuleUpdateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/543',
    '',
    '',
    '/custom/domain/web/v1/number_rule/_update',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [编号规则修改↗](http://yapi.blacklake.tech/project/543/interface/api/77762) 的 **请求配置**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_update`
 * @更新时间 `2021-11-09 15:16:01`
 */
const fetchNumberRuleUpdateRequestConfig: FetchNumberRuleUpdateRequestConfig = {
  mockUrl: mockUrl_0_6_0_1,
  devUrl: devUrl_0_6_0_1,
  prodUrl: prodUrl_0_6_0_1,
  path: '/custom/domain/web/v1/number_rule/_update',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_6_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchNumberRuleUpdate',
};

/**
 * 接口 [编号规则修改↗](http://yapi.blacklake.tech/project/543/interface/api/77762) 的 **请求函数**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_update`
 * @更新时间 `2021-11-09 15:16:01`
 */
export const fetchNumberRuleUpdate = makeRequest<
  FetchNumberRuleUpdateRequest,
  FetchNumberRuleUpdateResponse,
  FetchNumberRuleUpdateRequestConfig
>(fetchNumberRuleUpdateRequestConfig);

/**
 * 接口 [取号-web↗](http://yapi.blacklake.tech/project/543/interface/api/77768) 的 **请求类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_number_web`
 * @更新时间 `2021-11-09 15:16:00`
 */
export interface FetchNumberRuleGetNumberWebRequest {
  /**
   * 规则id
   */
  numberRuleId?: number;
  /**
   * 标准对象Code
   */
  objectCode: string;
  /**
   * 标准对象id
   */
  objectId?: number;
  /**
   * 来源对象信息(取几个号传几个对象)
   */
  objects: {}[];
}

/**
 * 接口 [取号-web↗](http://yapi.blacklake.tech/project/543/interface/api/77768) 的 **返回类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_number_web`
 * @更新时间 `2021-11-09 15:16:00`
 */
export interface FetchNumberRuleGetNumberWebResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: string[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [取号-web↗](http://yapi.blacklake.tech/project/543/interface/api/77768) 的 **请求配置的类型**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_number_web`
 * @更新时间 `2021-11-09 15:16:00`
 */
type FetchNumberRuleGetNumberWebRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/543',
    '',
    '',
    '/custom/domain/web/v1/number_rule/_get_number_web',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [取号-web↗](http://yapi.blacklake.tech/project/543/interface/api/77768) 的 **请求配置**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_number_web`
 * @更新时间 `2021-11-09 15:16:00`
 */
const fetchNumberRuleGetNumberWebRequestConfig: FetchNumberRuleGetNumberWebRequestConfig = {
  mockUrl: mockUrl_0_6_0_1,
  devUrl: devUrl_0_6_0_1,
  prodUrl: prodUrl_0_6_0_1,
  path: '/custom/domain/web/v1/number_rule/_get_number_web',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_6_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchNumberRuleGetNumberWeb',
};

/**
 * 接口 [取号-web↗](http://yapi.blacklake.tech/project/543/interface/api/77768) 的 **请求函数**
 *
 * @分类 [web端-编号规则接口↗](http://yapi.blacklake.tech/project/543/interface/api/cat_11172)
 * @标签 `web端-编号规则接口`
 * @请求头 `POST /custom/domain/web/v1/number_rule/_get_number_web`
 * @更新时间 `2021-11-09 15:16:00`
 */
export const fetchNumberRuleGetNumberWeb = makeRequest<
  FetchNumberRuleGetNumberWebRequest,
  FetchNumberRuleGetNumberWebResponse,
  FetchNumberRuleGetNumberWebRequestConfig
>(fetchNumberRuleGetNumberWebRequestConfig);

/* prettier-ignore-end */
