import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import FlowBuilder, { IFlowBuilderMethod, INode } from 'react-flow-builder';
import './index.css';
import { customerFlowNodes } from './flowConfig';
import Styles from './styles.module.scss';
import { useHistory } from 'react-router-dom';
import { isUndefined } from 'lodash';

interface Props {
  objectType: string; // 配置的类型
  prefix: string; // 每个模块使用组件的标识，进行缓存区分，获取对应的Nodes缓存数据
}

const FlowBuilderComponent = forwardRef((props: Props, ref: any) => {
  const { objectType, prefix } = props;
  const { registerNodes, nodesDefault } = customerFlowNodes[objectType];
  const [nodes, setNodes] = useState<INode[]>(nodesDefault);

  const flowRef = useRef<IFlowBuilderMethod>(null) ?? {};

  const history = useHistory();

  const handleChange = (nodes: INode[]) => {
    setNodes(nodes);
    if (isUndefined(prefix) || !prefix) return;

    sessionStorage.setItem(`${prefix}flowData`, JSON.stringify(nodes)); // 开源组件原生的缓存机制有点问题，在进行页面跳转的时候缓存不理想，所以采用了sessionStorage去缓存数据的方法代替
  };

  //  自定义暴露给父组件的值和方法
  useImperativeHandle(ref, () => ({
    data: nodes,
  }));

  const includePathName = ['/processManagement/approvalProcessList/create'];

  useEffect(() => {
    const nodesData = sessionStorage.getItem(`${prefix}flowData`);

    if (nodesData) {
      setNodes(JSON.parse(nodesData));
    }

    return () => {
      history.listen((location) => {
        if (!includePathName.includes(location.pathname)) {
          // 路由发生了改变 清空当前的nodes节点数据
          // TODO: 注意:react-flow-builder缓存永远依赖id绑定的当前节点，每次新建初始化的时候nodesDefault 由于都使用同一个id所以会导致缓存数据 这里采用了，每次都塞新id的方法清空所有缓存
          sessionStorage.setItem(
            `${prefix}flowData`,
            JSON.stringify([
              {
                id: '1',
                type: 'start',
                name: '开始',
                path: ['0'],
              },
              {
                id: `${Math.random() * 99}`,
                type: 'node',
                name: '审批节点名称',
                data: {
                  configured: 0,
                  nodeFormType: 'approvingCommonNodes',
                  isChangeFieldValue: 0,
                  isEdit: 0,
                },
                path: ['1'],
              },
              {
                id: '3',
                type: 'end',
                name: '结束',
                path: ['2'],
              },
            ]),
          );
        }
      });
    };
  }, [objectType]);

  // tip: 数据都在子组件，需要获取子组件内数据 请使用ref获取。 重置节点请使用sessionStorage去塞值 原生的有些许问题，待研究透

  return (
    <div ref={ref} className={Styles.flowBuilder}>
      <FlowBuilder
        ref={flowRef}
        nodes={nodes}
        onChange={handleChange}
        registerNodes={registerNodes}
        lineColor={'#D9D9D9'}
        backgroundColor="#fff"
        drawerProps={{ width: 640 }}
        drawerVisibleWhenAddNode // 增加节点默认打开抽屉
      />
    </div>
  );
});

export default FlowBuilderComponent;
