/**
 * @function 『未注册/注册中/已注册』列表项渲染方法
 */

import { Badge } from 'antd';
import lookup, { pickCode } from 'src/dict';
import { SupplyRegStatus } from 'src/dict/supplier';

const regStatusMap = new Map([
  [
    SupplyRegStatus.unregistered,
    <Badge text={lookup('supplyRegStatus.crud', SupplyRegStatus.unregistered)} status="error" />,
  ],
  [
    SupplyRegStatus.registering,
    <Badge text={lookup('supplyRegStatus.crud', SupplyRegStatus.registering)} status="error" />,
  ],
  [
    SupplyRegStatus.registered,
    <Badge text={lookup('supplyRegStatus.crud', SupplyRegStatus.registered)} status="success" />,
  ],
]);

export const renderRegStatus = (statusFlag: SupplyRegStatus) =>
  regStatusMap.get(pickCode(statusFlag));

export const getUsageStatusActionLink = (
  key: string,
  enableFlag: SupplyRegStatus,
  onClick: () => void,
) => {
  return (
    <a key={key} onClick={onClick}>
      {lookup('changeStatusAction', enableFlag)}
    </a>
  );
};

export default renderRegStatus;
