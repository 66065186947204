import { isUndefined } from 'lodash-es';
import moment from 'moment';
import { gcTime } from 'src/utils';
import _Time from 'src/utils/dataTypes/time';
import { TYPE_CODE_TYPE } from './constant';

export const oneDay = Number(86400000);

// 处理拿到的毫米毫秒数 变成对应的时间
export const formatTime = (time: number) => {
  const mins = Math.floor(time / 1000 / 60);
  const hr = Math.floor(mins / 60);
  const showHour = hr < 10 ? `0${hr}` : hr;
  const min = Math.floor(mins % 60);
  const showMin = min < 10 ? `0${min}` : min;

  return `${showHour} 小时 ${showMin} 分钟`;
};

// 处理时间段的展示
export const formatPeriodTime = (time: number) => {
  const hr = Math.floor(time / 1000 / 60 / 60);
  const showTime = moment.utc(time).format('HH:mm');
  const periodTime = hr >= 24 ? `次日 ${showTime}` : showTime;

  return periodTime;
};

// 处理班次时间段
export const dealWithPeriodOfTime = (shiftInfoDetailVOS: any) => {
  const newShiftInfoDetailVOS = shiftInfoDetailVOS.map((node: any) => {
    return {
      startTime: formatPeriodTime(node?.startTime),
      endTime: formatPeriodTime(node?.endTime),
    };
  });

  return newShiftInfoDetailVOS;
};

// 处理失败的结果 by 日历
export const formatDeleteFailsByCalendar = (usingShiftObjs: any) => {
  const useCalendarItems = usingShiftObjs.filter(
    (node: any) => node?.typeCode === TYPE_CODE_TYPE.CALENDAR,
  );

  const useCalendarNames = useCalendarItems
    ?.map((node: any) => {
      if (node?.typeCode === TYPE_CODE_TYPE.CALENDAR) {
        return node?.name;
      }
    })
    ?.join();

  return useCalendarNames;
};

// 处理失败的结果 by 排班规则
export const formatDeleteFailsBySchedulingRules = (usingShiftObjs: any) => {
  const useSchedulingRulesItems = usingShiftObjs.filter(
    (node: any) => node?.typeCode === TYPE_CODE_TYPE.SCHEDULING_RULES,
  );

  const useSchedulingRulesNames = useSchedulingRulesItems
    ?.map((node: any) => {
      if (node?.typeCode === TYPE_CODE_TYPE.SCHEDULING_RULES) {
        return node?.name;
      }
    })
    ?.join();

  return useSchedulingRulesNames;
};

// 处理编辑前check的使用班次和日历
export const formatUseNames = (data: any) => {
  const newData = data
    ?.map((node: any) => {
      return node.name;
    })
    ?.join();

  return newData;
};

// 获取系统当前日期的时间戳
export const getNowTimeStamp = () => {
  const now = new Date().toLocaleDateString();
  const timeStamp = new Date(`${now} 00:00:00`).getTime();

  return timeStamp;
};

// 根据时间戳差值 获取总工时/有效工时的display
export const workHourDisplay = (time: any) => {
  const [startTime, endTime] = gcTime.formatMomentRangeToUnix(time);

  const diffStamp = endTime - startTime;

  const workDisplay = formatTime(diffStamp);

  return workDisplay;
};

// moment转化URL储存的数据 回校筛选框
export const momentData = (time: any) => {
  const formatMoment = time?.map((node: any) => {
    return moment(node);
  });

  return formatMoment;
};

export const changeUnix = (time: any) => {
  if (isUndefined(time)) return;
  return moment(time).unix();
};

// 判断是否是次日
export const judgeIsNextDay = (time: any, firstStartTime: any) => {
  const timeStamp = _Time.formatToUnix(time);

  if (timeStamp <= firstStartTime) {
    return true;
  }
  return false;
};

export const getNeedObject = (times: any) => {
  const list: any = {};

  list.firstStartTimeNode = times[0];
  list.endTimeNode = times[times?.length - 1];
  list.firstStartStamp = times[0]?.startTime;
  list.firstEndStamp = times[0]?.endTime;
  list.endTimeStamp = times[times?.length - 1]?.endTime;

  return list;
};

export const checkStartTime = (times: any, index: number, value: any) => {
  // 校验非第一行的开始时间的规则: 1. 开始时间永远在上一段的开始时间 - 当前段的结束时间时间
  // 2. 如果没有选择当前结束时间，默认是次日的第一段开始时间
  // 所以只要保证上一段的开始时间存在即可校验
  const firstStartTime = Number(_Time.formatToUnix(times[0].startTime));
  const nowItem = times[index];
  const nowItemEndTime = nowItem?.endTime;
  const beforeItem = times[index - 1];
  const beforeItemEndTime = beforeItem?.endTime;
  const valueTime = Number(_Time.formatToUnix(value));

  if (beforeItemEndTime) {
    const beforeEndTime = Number(_Time.formatToUnix(beforeItemEndTime));
    const endTime = nowItemEndTime ? Number(_Time.formatToUnix(nowItemEndTime)) : firstStartTime;

    if (beforeItem?.endTag === 1) {
      // 1. 判断上一段结束时间是否是次日，如果是次日，后面都是次日
      if (valueTime >= beforeEndTime && valueTime < endTime) {
        return true;
      }
      return false;
    }
    if (nowItemEndTime) {
      // 当前结束时间存在
      if (nowItem?.endTag === 1) {
        // 结束是次日，开始是今天
        if (valueTime >= beforeEndTime || valueTime < endTime) {
          return true;
        }
        return false;
      }
      if (valueTime >= beforeEndTime && valueTime < endTime) {
        return true;
      }
      return false;
    }
    if (valueTime >= beforeEndTime || valueTime < endTime) {
      return true;
    }
    return false;
  }
  return true;
};

export const checkEndTime = (times: any, index: number, value: any) => {
  // 校验非最后一行的结束时间的规则: 1. 结束时间永远在当前开始时间 - 下一段段的开始时间 范围内
  // 2. 如果没有选择当前结束时间，默认是次日的第一段开始时间
  // 所以只要保证上一段的开始时间存在即可校验
  const firstStartTime = Number(_Time.formatToUnix(times[0].startTime));
  const nowItem = times[index]; // 当前行
  const nowItemStart = nowItem?.startTime; // 当前行开始时间
  const nextItem = times[index + 1]; // 下一行开始时间 没有默认为是次日第一段开始时间
  const nextItemStart = nextItem?.startTime; // 下一行开始时间
  const valueTime = Number(_Time.formatToUnix(value));

  const endTime = nextItemStart ? Number(_Time.formatToUnix(nextItemStart)) : firstStartTime;

  const currentStartTime = Number(_Time.formatToUnix(nowItemStart));

  // 如果下一段结束时间不存在  默认是第一段开始时间
  if (!nextItemStart) {
    // 1.当前行开始是次日还是非次日

    if (nowItem.startTag === 1) {
      // 开始次日后面都是次日
      if (valueTime > currentStartTime && valueTime <= endTime) {
        return true;
      }
      return false;
    }
    if (valueTime > currentStartTime || valueTime <= endTime) {
      return true;
    }
    return false;
  }
  // 下一段开始时间存在 需要判断下段开始是不是次日
  if (nowItem.startTag === 1) {
    // 开始次日后面都是次日
    if (valueTime > currentStartTime && valueTime <= endTime) {
      return true;
    }
    return false;
  }
  if (nowItem.startTag === 0 && nextItem.startTag === 0) {
    if (valueTime > currentStartTime && valueTime <= endTime) {
      return true;
    }
    return false;
  }
  if (nowItem.startTag === 0 && nextItem.startTag === 1) {
    if (valueTime > currentStartTime || valueTime <= endTime) {
      return true;
    }
    return false;
  }
  return false;
};

export const checkFirstStartTime = (times: any, index: number, value: any) => {
  const list = getNeedObject(times);
  const firstEndTime = Number(_Time.formatToUnix(list.firstEndStamp));

  const firstStartTime = Number(_Time.formatToUnix(list.firstStartStamp));

  const endTime = list.endTimeStamp
    ? Number(_Time.formatToUnix(list.endTimeStamp))
    : Number(firstStartTime);

  const valueTime = Number(_Time.formatToUnix(value));

  if (firstEndTime < endTime) {
    if (valueTime >= endTime || valueTime < firstEndTime) {
      return true;
    }
    return false;
  }

  if (valueTime >= endTime && valueTime < firstEndTime) {
    return true;
  }
  return false;
};

export const changeValues = (values: any) => {
  const nowStamp = getNowTimeStamp();
  const formatValue = values?.map((node: any, i: number) => {
    const { startTag, endTag, startTime, endTime, ...rest } = node;
    const newNode = { ...rest };

    newNode.startTime =
      startTag === 1
        ? Number(_Time.formatToUnix(startTime)) + oneDay - nowStamp
        : Number(_Time.formatToUnix(startTime)) - nowStamp;
    newNode.endTime =
      endTag === 1
        ? Number(_Time.formatToUnix(endTime)) + oneDay - nowStamp
        : Number(_Time.formatToUnix(endTime)) - nowStamp;
    newNode.sequenceNumber = i + 1;
    return newNode;
  });

  return formatValue;
};
