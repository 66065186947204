import { useState } from 'react';
import { Form, Input, Checkbox, message as Message } from 'antd';
import { DataFormLayoutForModal, DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { fetchSupplierCreate, fetchSupplierUpdate } from 'src/api/ytt/supply-domain/supplier';
import {
  numberAlphabetSpecialSymbols,
  numberChineseAlphabetSpecialSymbols,
  withoutSpaceBothSides,
} from 'src/utils/formValidators';
import { SupplierOperateModeTitleMap, fieldLabels } from 'src/dict/supplier/mappings';
import type { SupplierListItemProps } from '../interface';
import { SupplierOperateMode } from 'src/dict/supplier';
import { UsageStatus } from 'src/dict/common';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import _ from 'lodash';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { NumberRulesStandardHook } from 'src/components';

const { TextArea } = Input;

interface OperateSupplierDialogProps {
  supplierData?: SupplierListItemProps;
  operateMode: SupplierOperateMode;
  onClose: (needRefresh: boolean) => void;
}

// 传给form的设置
// preserve: 在删除字段时保留字段值
// antd的form会在rerender的时候删除所有字段，如果该字段为false，使用form全程不可以rerender
const formProps = {
  preserve: true,
};

/** 新建/编辑供应商对话框 */
export default function OperateSupplierDialog({
  supplierData,
  operateMode,
  onClose,
}: OperateSupplierDialogProps) {
  // 正在保存供应商数据，用来保存期间disable保存按钮
  const [supplierFormLoading, setSupplierFormLoading] = useState(false);
  // 是否连续新建
  const [continueToCreate, setContinueToCreate] = useState(true);
  // 关闭弹窗以后是否需要刷新列表，仅在有数据保存成功的情况下刷新
  const [needListRefresh, setNeedListRefresh] = useState(false);
  // 新建/编辑表单实例
  const [supplierOperateForm] = Form.useForm();
  const [refreshMarker, setRefreshMarker] = useState<number>();

  // 修改原因字段
  const editReasonFormItems = {
    label: fieldLabels.modifyReason,
    name: 'editReason',
    hidden: operateMode !== SupplierOperateMode.edit,
    rules: [{ max: 1000, message: '不可超过1000个字符', withoutSpaceBothSides }],
    render: () => <TextArea showCount maxLength={1000} placeholder="请输入" allowClear />,
  };

  const supplierBasicInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    items: [
      ...NumberRulesStandardHook({
        label: fieldLabels.supplierCode,
        name: 'code',
        form: supplierOperateForm,
        edit: Boolean(operateMode === SupplierOperateMode.edit),
        objectCode: OBJECT_OF_CODE.supplier,
        fieldCode: 'code',
        rules: [
          numberAlphabetSpecialSymbols,
          withoutSpaceBothSides,
          { max: 255, message: '不可超过255个字符' },
        ],
        refreshMarker,
        disabledState: operateMode === SupplierOperateMode.edit,
      }),
      {
        label: fieldLabels.supplierName,
        name: 'name',
        rules: [
          { required: true, message: '供应商名称必填' },
          numberChineseAlphabetSpecialSymbols,
          withoutSpaceBothSides,
          { max: 255, message: '不可超过255个字符' },
        ],
        render: () => <Input placeholder="请输入" allowClear />,
      },
      {
        label: fieldLabels.supplierOwner,
        name: 'owner',
        rules: [{ required: true, message: '供应商所有人必选' }],
        getValueProps: (value?: any) => {
          if (value) {
            return { value: [_.pick(value, ['id', 'name', 'avatarUrl'])] };
          }
          return {};
        },
        getValueFromEvent: (value) => {
          return value[0];
        },
        render: () => (
          <UserOrDepartmentSelectWithDialog
            isNewFormat
            placeholder={'请选择'}
            isMultiple={false} // 是否多选
            isSelectUser // 切换选择用户
          />
        ),
      },
      {
        label: fieldLabels.remark,
        name: 'remark',
        rules: [{ max: 1000, message: '不可超过1000个字符' }, withoutSpaceBothSides],
        render: () => <TextArea showCount maxLength={1000} placeholder="请输入" allowClear />,
      },
      ...(operateMode === SupplierOperateMode.edit ? [editReasonFormItems] : []),
    ],
  };

  // 如果有，初始化数据
  if (supplierData) {
    supplierOperateForm.setFieldsValue(supplierData);
  }

  /** 保存供应商修改/新建 */
  const saveSupplier = async () => {
    try {
      // 表单校验
      await supplierOperateForm.validateFields();
      const formDirty = supplierOperateForm.isFieldsTouched([], true);

      // 脏检测
      if (!formDirty) {
        onClose(false);
        return;
      }

      // 表单里没有id和enableFlag字段，加上
      const formSupplierData = supplierOperateForm.getFieldsValue();
      const requestParam = {
        ...formSupplierData,
        id: operateMode === SupplierOperateMode.edit ? supplierData?.id : undefined,
        // 新建状态下，供应商状态默认为启用中
        enableFlag: supplierData ? supplierData.status : UsageStatus.enabled,
        ownerId: formSupplierData.owner.id,
      };

      const requestFn =
        operateMode === SupplierOperateMode.create ? fetchSupplierCreate : fetchSupplierUpdate;

      setSupplierFormLoading(true);
      const response = await requestFn(requestParam);

      setSupplierFormLoading(false);

      if (response.code === 200) {
        Message.success('保存成功');
        // 连续新建则清空表单
        if (continueToCreate && operateMode === SupplierOperateMode.create) {
          supplierOperateForm.resetFields();
          setNeedListRefresh(true);
          setRefreshMarker(Date.now());
        } else {
          // 否则关闭对话框，刷新列表
          onClose(true);
        }
      } else {
        Message.error(response.message);
      }
    } catch (e) {
      setSupplierFormLoading(false);
      console.log(e);
    }
  };

  return (
    <DataFormLayoutForModal
      content={
        <DataFormLayout
          form={supplierOperateForm}
          info={[supplierBasicInfo]}
          title={SupplierOperateModeTitleMap[operateMode]}
          formProps={formProps}
          loading={supplierFormLoading}
          onCancel={() => {
            onClose(needListRefresh);
          }}
          onFinish={saveSupplier}
          extra={
            <>
              {operateMode === SupplierOperateMode.create && (
                <Checkbox
                  onChange={() => {
                    setContinueToCreate(!continueToCreate);
                  }}
                  defaultChecked={continueToCreate}
                >
                  连续新建
                </Checkbox>
              )}
            </>
          }
        />
      }
      width={800}
      visible={operateMode !== SupplierOperateMode.hide}
      onClose={() => {
        onClose(needListRefresh);
      }}
    />
  );
}
