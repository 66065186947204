import React from 'react';
import { Badge, Table } from 'antd';

interface Option {
  optionTitle: string;
  optionStatuse: string;
  isDefault: number;
}

const selectOptionInfo = {
  title: '选项信息',
  column: 1,
  items: [
    {
      label: '',
      span: 2,
      dataIndex: 'options',
      render: (options: Option[]) => {
        return (
          <Table
            style={{ width: '100%' }}
            pagination={false}
            columns={[
              {
                title: '选项',
                dataIndex: 'optionTitle',
                render: (optionTitle: string, record: Option) =>
                  `${optionTitle}${record.isDefault ? ' (默认值) ' : ''}`,
              },
              {
                title: '状态',
                dataIndex: 'optionStatus',
                render: (status: boolean) => (
                  <span>
                    <Badge status={status ? 'success' : 'default'} /> {status ? '启用' : '停用'}
                  </span>
                ),
              },
            ]}
            dataSource={options}
          />
        );
      },
    },
  ],
};

export default selectOptionInfo;
