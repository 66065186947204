/**
 * 综合查询预留
 */

import React, { useState } from 'react';
import _, { map } from 'lodash';
import lookup from 'src/dict';
import { ColumnProps } from 'antd/es/table';
import { TagList } from 'src/components';
import { getMaterialAttrs } from 'src/entity/material';
import {
  fetchReservedSynthesizeSuperscriptGet,
  fetchReservedSynthesizeSubscriptGet,
  FetchReservedSynthesizeSuperscriptGetResponse,
} from 'src/api/ytt/plo-domain/reserved';
import { RecordListLayout } from 'src/layout';

import _Time from 'src/utils/dataTypes/time';
import { replaceSign } from 'src/utils/constants';
import { arrayIsEmpty } from 'src/utils/array';

type ReservedSynthesizeSuperscript = NonNullable<
  FetchReservedSynthesizeSuperscriptGetResponse['data']
>;

interface MultiDetailProps {
  parameter: {
    orderId: number;
    orderType: number;
  };
  initData: any;
}

const CpsiveReservationList = (props: MultiDetailProps) => {
  const { parameter, initData } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [dataSource, setDataSource] = useState<any[]>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>();

  const formatChildren = (node: any) => {
    if (node.subscript) {
      node.children = [];
    } else {
      node.children = null;
    }
    return node;
  };

  const formatSearchData = (data: any, expandedRowKeys: number[]) => {
    if (!data.subscript) {
      data.children = null;
    }
    data.hasLoadedData = true;
    if (data?.children && data?.children.length) {
      const childrenList = data.children;

      childrenList.forEach((_item: any) => {
        formatSearchData(_item, expandedRowKeys);
      });
      if (!expandedRowKeys.includes(data.id)) {
        expandedRowKeys.unshift(data.id);
      }
    }
    return expandedRowKeys;
  };

  const getColumns = () => {
    const baseColumns: ColumnProps<ReservedSynthesizeSuperscript>[] = [
      {
        title: '层级',
        dataIndex: 'level',
        fixed: 'left',
        width: 200,
      },
      {
        title: '单据类型',
        dataIndex: 'orderType',
        width: 200,
        render: (text: any) => lookup('planned.reqSourceTypeNameMap', text),
      },
      {
        title: '单据编号',
        dataIndex: 'code',
        width: 200,
      },
      {
        title: '物料编号',
        dataIndex: ['material', 'baseInfo', 'code'],
        width: 150,
      },
      {
        title: '物料名称',
        dataIndex: ['material', 'baseInfo', 'name'],
        width: 150,
      },
      {
        title: '物料分类',
        dataIndex: ['material', 'category', 'name'],
        width: 150,
      },
      {
        title: '物料规格',
        dataIndex: ['material', 'baseInfo', 'specification'],
        width: 150,
      },
      {
        title: '物料属性',
        dataIndex: ['material'],
        width: 150,
        render: (material) => <TagList dataSource={getMaterialAttrs(material)} />,
      },
      {
        title: '单位',
        dataIndex: ['unitName'],
        width: 150,
      },
      {
        title: '预留数量',
        dataIndex: ['reservedAmount', 'amountDisplay'],
        width: 150,
      },
      {
        title: '业务数量',
        dataIndex: ['businessAmount', 'amountDisplay'],
        width: 150,
      },
      {
        title: '业务时间',
        dataIndex: ['businessTime'],
        width: 150,
        render: (text: any) => (text ? _Time.format(text) : replaceSign),
      },
      {
        title: '生成方式',
        dataIndex: ['generationMethod'],
        width: 150,
        render: (text: any) => lookup('planned.ReservedGenerationMethodDisplayMap', text),
      },
      {
        title: '预留类型',
        dataIndex: ['reservedType'],
        width: 150,
        render: (text: any) => lookup('planned.reservedTypeMap', text),
      },
    ];

    return baseColumns;
  };

  const fetchChildrenNode = async (currentNodeLevel = 0, record: any) => {
    setIsLoading(true);
    fetchReservedSynthesizeSubscriptGet({
      orderId: record?.orderId,
      orderType: parameter.orderType,
    }).then((res) => {
      const reservedNodes = !arrayIsEmpty(res?.data?.reservedNodes)
        ? map(res?.data?.reservedNodes, ({ ...rest }) => ({
            ...rest,
            level: currentNodeLevel + 1,
          }))
        : [];

      findTreeNode(dataSource as [], record?.id, reservedNodes || []);

      setDataSource(_.cloneDeep(dataSource));

      setIsLoading(false);
    });
  };

  const onExpand = (expanded: boolean, record: any) => {
    if (expanded && !record.hasLoadedData) {
      fetchChildrenNode(record?.level, record);
    }
  };

  const findTreeNode = (data: any[], id: number, nodeData: any[]) => {
    data?.find((node) => {
      if (node.id === id) {
        node.hasLoadedData = true;
        if (nodeData && nodeData.length) {
          node.children = nodeData.map((_node) => {
            return { ...formatChildren(_node), parentName: node.name };
          });
        } else {
          node.children = null;
        }
        return true;
      }
      if (node.children && node.children.length) {
        findTreeNode(node.children, id, nodeData);
      }
      return false;
    });
  };

  const searchData = async (params: any) => {
    const sendData = _.cloneDeep(params);

    if (sendData) {
      delete sendData.page;
      delete sendData.size;
    }
    // if (!gcObject.isEmpty(gcObject.filterEmptyProperty(sendData))) {
    const data = initData;

    console.log('initData: ', initData);

    const addChildrenFlag = map([initData], (item) => {
      return { ...formatChildren(item), level: 0 };
    });

    const expandedKeys = formatSearchData(initData, []);

    setDataSource(addChildrenFlag);
    setExpandedRowKeys(expandedKeys);
    return { data: { list: addChildrenFlag } };

    // }
  };

  /**
   * 第一次请求数据 判断subscript(是否存在子集)
   */

  return (
    <div
      style={{
        height: (document.documentElement.clientHeight - 100) / 2,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: '16px 16px 50px 16px',
      }}
    >
      <h4
        style={{
          padding: 16,
        }}
      >
        综合查询预留
      </h4>
      <RecordListLayout<ReservedSynthesizeSuperscript>
        filterList={undefined}
        requestFn={searchData}
        mainMenu={undefined}
        batchMenu={undefined}
        placeholder={'请输入区域名称或编号'}
        columns={getColumns()}
        refreshMarker={refreshMarker}
        rowKey="id"
        useQuickFilter={false}
        useIndex={false}
        configcacheKey={'location'}
        expandable={{
          onExpand,
          expandedRowKeys,
          expandRowByClick: false,
          onExpandedRowsChange: (expandedRows: number[]) => {
            setExpandedRowKeys(expandedRows);
          },
        }}
        pagination={false}
        getOperationList={undefined}
        customDataSource={dataSource}
        isLoading={isLoading}
      />
    </div>
  );
};

export default CpsiveReservationList;
