import { LabeledValue } from 'antd/lib/select';
import { LayoutComponentType } from 'src/dict/customLayout';
import type {
  FetchStandardBizObjectListPageResponse,
  FetchStandardBizObjectCustomObjectListPageResponse,
  FetchStandardBizObjectCustomObjectGetSonObjectAndFieldByIdResponse,
} from 'src/api/ytt/metadata-domain/custom_object';
import type { FetchCustomFieldGetListByObjectCodeResponse } from 'src/api/ytt/metadata-domain/custom_field';
import type {
  FetchLayoutListResponse,
  FetchLayoutDetailResponse,
  FetchLayoutCreateRequest,
} from 'src/api/ytt/layout-domain';

export type StandardObjectData = Required<
  Required<FetchStandardBizObjectListPageResponse>['data']['list'][number]
>;
export type CustomObjectData = Required<
  Required<FetchStandardBizObjectCustomObjectListPageResponse>['data']['list'][number]
>;
export type Field = Required<FetchCustomFieldGetListByObjectCodeResponse>['data'][number];
export type SonObject =
  Required<FetchStandardBizObjectCustomObjectGetSonObjectAndFieldByIdResponse>['data'][number];
export type LayoutListItem = Required<FetchLayoutListResponse>['data']['list'][number];
export type LayoutData = Required<FetchLayoutDetailResponse>['data'] &
  Pick<FetchLayoutCreateRequest, 'detailLayoutId'>;
export type LayoutComponent = Required<LayoutData>['components'][number];
export type LayoutParsedComponent = Omit<LayoutComponent, 'type' | 'content' | 'children'> & {
  type: LayoutComponentType;
  content: {
    fieldPath?: string;
    fieldName?: string;
    /** 标题栏的标题 */
    title?: string;
    /** 是否必填。基本类型和从对象都统一用这个字段 */
    required?: number;
    frequent?: number;
    /** 从对象的对象code */
    objectCode?: string;
    fieldCategory?: number;
  };
  children?: LayoutParsedComponent[];
};
export type LayoutParsedData = Omit<LayoutData, 'components'> & {
  components: LayoutParsedComponent[];
};
export enum DragItemType {
  cover = 'cover',
  component = 'component',
}
export type DragItem = {
  type: LayoutComponentType;
  key: string;
  index: number;
};
