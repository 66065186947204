import { useState, useEffect, useRef } from 'react';
import { Button, Form, Input, message, Select } from 'antd';
import { useParams, RouteComponentProps } from 'react-router-dom';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { BlIcon, BlTable } from 'src/components';
import SelectInventory from './selectInventory';
import { ColumnProps } from 'antd/lib/table';
import _ from 'lodash';
import {
  fetchQcTaskDetail,
  fetchQcTaskListMaterial,
  fetchQcTaskPlanCheckCount,
  fetchQcTaskTaskConfigDetail,
  fetchQcTaskUpdateMaterial,
} from 'src/api/ytt/quality-domain/qc_task';
import { mappingsFactory } from 'src/dict/utils';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators/customValidator';
import {
  CategoryEnum,
  CheckEntityTypeEnum,
  MaterialProcessEnum,
  RecordSampleEnum,
  ReportFormatTypeEnum,
  SampleProcessEnum,
} from 'src/dict/quality';
import { Mappings } from 'src/dict/types';
import { NUMBER_SCALE } from 'src/page/quality/models/constants/qcConfig';
import { useBlocker } from 'src/utils/useBlocker';
import authDict, { hasAuth } from 'src/utils/auth';

interface DetailPageProps extends RouteComponentProps {}

const Inventory = (props: DetailPageProps) => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [num, setNum] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [, setProcessingLine] = useState<any[]>();
  const [materialId, setMaterialId] = useState<number>();
  const refreshCountRef = useRef(0);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [deleteIds, setDeleteIds] = useState<number[] | React.Key[]>([]);
  const [toSamples, setToSamples] = useState<any[]>([]);
  const [toTotalIds, setTotalIds] = useState<any[]>([]);
  const [subSimpleCountChanges, setSubSimpleCountChanges] = useState<number[]>([]);
  const [planCheckCount, setPlanCheckCount] = useState<number>();
  const [, setCheckCount] = useState<number>();
  const [, setTotalCount] = useState<number>();
  const [materialAuxUnitList, setMaterialAuxUnitList] = useState<any>();
  const [inputCheckCount, setInputCheckCount] = useState<boolean>(true);
  const [haveInvenList, setHaveInvenList] = useState<boolean>();
  const { resetForm, history } = useBlocker(form);

  // 样本是否需要记录
  const [isRecordSample, setIsRecordSample] = useState<boolean>();
  // 每个库存是否分别填写
  const [isReportFormatType, setIsReportFormatType] = useState<boolean>();
  // /** 总体处理方式是否为记录不更新质量状态或者记录更新质量状态 */
  const [isMaterialBatchRecordType, setIsMaterialBatchRecordType] = useState<boolean>();

  // 样本处理方式是否为报废
  const [sampleProcess, setSampleProcess] = useState<boolean>();

  // 检验方案中填写分数是否和抽样量一致
  const [sameAsSampleSize, setSameAsSampleSize] = useState<boolean>();

  const fetchMaterialId = async (id: number) => {
    const { data } = await fetchQcTaskDetail({ id });

    if (data?.material && data?.material.id) {
      setMaterialId(data?.material.id);
    }
  };

  // 样本库存抽样量变动集合
  const handelCheckCountChanges = (key: number, isCheckNum?: boolean) => {
    if (_.indexOf(subSimpleCountChanges, key) === -1) {
      setSubSimpleCountChanges(_.concat(subSimpleCountChanges, key));
    }
    if (isCheckNum) linkageCheckCount();
  };

  const categoryMap: Mappings<string, number> = mappingsFactory(['总体', 1], ['样本', 2]);

  // 获取库存列表
  const fetchInvotoryList = async (id: number) => {
    const { data } = await fetchQcTaskListMaterial({ id });

    _.isEmpty(data?.list) ? setHaveInvenList(false) : setHaveInvenList(true);

    const invotoryList = data?.list.map((node: any) => {
      return {
        ...node,
        key: node?.qcTaskMaterialId,
        newQcCount: node?.qcCheckCount?.count ?? node?.qcCount?.count,
        newAuxAmounts:
          node?.auxAmounts && node?.auxAmounts[0] ? node?.auxAmounts[0]?.count : undefined,
      };
    });

    // 获取qcConfig信息
    fetchQcConfig(id);

    // 获取计划抽样量信息
    fetchPlanCheckCount(id, data?.totalCount);

    // 比对库存添加前后的数据
    const originInvotoryIds = form?.getFieldValue('inventoryList')?.map((node: any) => {
      return node.qcTaskMaterialId;
    });
    const newInvotoryList = invotoryList?.filter((node: any) => {
      if (_.indexOf(originInvotoryIds, node.qcTaskMaterialId) === -1) return node;
    });

    linkageCheckCount();
    linkageTotalCount();

    resetForm({
      inventoryList: form.getFieldValue('inventoryList')
        ? _.concat(newInvotoryList, form.getFieldValue('inventoryList'))
        : invotoryList,
      checkCount: data?.checkCountTotal,
      totalCount: data?.totalCount,
      planCheckCount: data?.planCheckCount,
    });
  };

  // qcConfig信息
  const fetchQcConfig = async (id: number) => {
    const detailData = await fetchQcTaskTaskConfigDetail({ id });

    setIsRecordSample(detailData?.data?.recordSample === RecordSampleEnum.record);
    setIsReportFormatType(detailData?.data?.reportFormatType === ReportFormatTypeEnum.materialUnit);
    setIsMaterialBatchRecordType(
      detailData?.data?.materialBatchRecordType === MaterialProcessEnum.MATERIAL_ONLY_RECORD ||
        detailData?.data?.materialBatchRecordType ===
          MaterialProcessEnum.MATERIAL_RECORD_UPDATE_STATUS,
    );
    setSampleProcess(detailData?.data?.sampleProcessMethod === SampleProcessEnum.scrap);
    setSameAsSampleSize(detailData?.data?.checkEntityType === CheckEntityTypeEnum.SameAsSample);
    // 关联物料上是否有辅助单位
    setMaterialAuxUnitList(detailData?.data?.material?.auxUnitList);

    if (
      detailData?.data?.recordSample !== RecordSampleEnum.record ||
      (detailData?.data?.recordSample == RecordSampleEnum.record &&
        detailData?.data?.materialBatchRecordType === MaterialProcessEnum.MATERIAL_NO_RECORD)
    ) {
      setInputCheckCount(false);
    } else {
      setInputCheckCount(true);
    }
  };

  // 计算计划抽样量
  const fetchPlanCheckCount = async (id: number, totalCount?: number | undefined) => {
    const planCheckCounData = await fetchQcTaskPlanCheckCount({
      id,
      totalCount,
    });

    setPlanCheckCount(planCheckCounData.data);
  };

  // 刷新
  const refreshTable = () => {
    refreshCountRef.current++;
    setRefreshMarker(refreshCountRef.current);
  };

  useEffect(() => {
    const qcId = Number(id);

    fetchMaterialId(qcId);
    fetchInvotoryList(qcId);
  }, [refreshMarker]);

  // 删除
  const handelRemove = () => {
    onDelete(_.concat(deleteIds, selectedRowKeys));
  };

  // 修改联动总抽样量
  const linkageCheckCount = () => {
    if (isRecordSample && isReportFormatType) {
      const arrList: number[] = [];

      _.forEach(form.getFieldValue('inventoryList'), (node: any) => {
        if (node.category === CategoryEnum.SAMPLE) {
          arrList.push(node.newQcCount ? Number(node.newQcCount) : node.qcCount.count);
        }
      });

      if (!_.isNaN(_.sum(arrList))) {
        form.setFieldsValue({ checkCount: _.sum(arrList ?? 0) });

        setCheckCount(_.sum(arrList) ?? 0);
      }
    } else {
      const arrList: number[] = [];

      _.forEach(form.getFieldValue('inventoryList'), (node: any) => {
        arrList.push(
          node.qcCheckCount?.count ? Number(node.qcCheckCount?.count) : node.qcCount?.count,
        );
      });

      if (!_.isNaN(_.sum(arrList))) {
        form.setFieldsValue({ checkCount: _.sum(arrList) ?? 0 });
        setCheckCount(_.sum(arrList) ?? 0);
      }
    }
  };

  // 修改联动总量
  const linkageTotalCount = () => {
    const arrList: number[] = [];

    _.forEach(form.getFieldValue('inventoryList'), (node: any) => {
      arrList.push(node.qcCount.count);
    });
    form.setFieldsValue({ totalCount: _.sum(arrList) });

    setTotalCount(_.sum(arrList));
    handelPlanCheckCount();
  };

  const handleOverOrSimId = (isOverAll: boolean, key?: number, inde?: number) => {
    if (isOverAll) {
      setTotalIds(_.uniq(_.concat(toTotalIds, key ? [key] : selectedRowKeys)));

      const newToSamples = _.remove(toSamples, (node) => {
        return _.indexOf(key ? [key] : selectedRowKeys, node) === -1;
      });

      setToSamples(newToSamples);
    } else {
      setToSamples(_.uniq(_.concat(toSamples, key ? [key] : selectedRowKeys)));

      const newToTotalIds = _.remove(toTotalIds, (node) => {
        return _.indexOf(key ? [key] : selectedRowKeys, node) === -1;
      });

      setTotalIds(newToTotalIds);
    }
    linkageCheckCount();
  };

  // 设为总体或样本
  const asOverAllOrSimple = (isOverAll: boolean) => {
    handleOverOrSimId(isOverAll);

    const newSelectedRows = form.getFieldValue('inventoryList').map((row: any) => {
      if (_.indexOf(selectedRowKeys, row.key) !== -1) {
        return {
          ...row,
          category: isOverAll ? 1 : 2,
        };
      }
      return row;
    });

    setNum(0);
    setSelectedRowKeys([]);
    form.setFieldsValue({
      inventoryList: newSelectedRows,
    });
    linkageCheckCount();
  };

  /**
   * 渲染 - 批量多选 - 交互操作
   */
  const renderBatchLine = () => {
    return (
      <div
        style={{
          marginBottom: 10,
          marginTop: 10,
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
        }}
      >
        <div style={{ marginRight: 20 }}>
          已选：
          <span>{num}</span>条
        </div>
        <Button
          onClick={() => {
            handelRemove();
          }}
          style={{ marginRight: 10 }}
        >
          删除
        </Button>
        <Button
          disabled={isRecordSample && !isMaterialBatchRecordType}
          style={{ marginRight: 10 }}
          onClick={() => {
            asOverAllOrSimple(true);
          }}
        >
          设为总体
        </Button>
        <Button
          disabled={!isRecordSample && isMaterialBatchRecordType}
          onClick={() => {
            asOverAllOrSimple(false);
          }}
        >
          设为样本
        </Button>
        <div style={{ position: 'absolute', right: 0 }}>
          <Button
            onClick={() => {
              setSelectedRowKeys([]);
              setNum(0);
            }}
          >
            取消选择
          </Button>
        </div>
      </div>
    );
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setNum(selectedRows.length);
    setProcessingLine(selectedRows);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // web-计算任务计划抽样量
  const handelPlanCheckCount = async () => {
    const valueDate = await form?.validateFields();
    const { data } = await fetchQcTaskPlanCheckCount({
      id: Number(id),
      totalCount: Number(form.getFieldValue('totalCount')),
    });

    setPlanCheckCount(data);
  };

  const getColumns = (): ColumnProps<any>[] => {
    return _.compact([
      {
        title: '物料名称',
        dataIndex: ['inventory', 'material', 'name'],
        width: 120,
      },
      {
        title: '物料编号',
        dataIndex: ['inventory', 'material', 'code'],
        width: 120,
      },
      {
        title: '物料规格',
        dataIndex: ['inventory', 'material', 'specification'],
        width: 120,
      },
      {
        title: '物料分类',
        dataIndex: ['inventory', 'material', 'categoryName'],
        width: 120,
      },
      {
        title: '仓库',
        dataIndex: 'storage',
        width: 120,
        render: (_: any, field: any) => {
          return field?.inventory?.location?.storage?.name;
        },
      },
      {
        title: '仓位',
        dataIndex: 'warehouse',
        width: 120,
        render: (_: any, field: any) => {
          return field?.inventory?.location?.warehouse?.name;
        },
      },
      {
        title: '批次号',
        dataIndex: ['inventory', 'bizKey', 'batchNo'],
        width: 120,
      },
      {
        title: '标识码',
        dataIndex: ['inventory', 'qrCode'],
        width: 120,
      },
      {
        title: '库存类型',
        dataIndex: 'category',
        width: 120,
        render: (_: any, field: any, index: number) => {
          return (
            <Form.Item
              name={[index, 'category']}
              fieldKey={[field?.key, 'category']}
              style={{ margin: 'auto' }}
            >
              <Select
                disabled={
                  (isRecordSample && !isMaterialBatchRecordType) ||
                  (!isRecordSample && isMaterialBatchRecordType)
                }
                style={{ width: 120 }}
                options={categoryMap}
                onChange={(value) => {
                  if (value === CategoryEnum.OVERALL) {
                    form?.setFields([{ name: ['inventoryList', index, 'newQcCount'], value: '' }]);
                  }
                  handleOverOrSimId(value === CategoryEnum.OVERALL, field.key, index);
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '检验数量',
        dataIndex: ['qcCount', 'count'],
        width: 120,
      },
      {
        title: '检验单位',
        dataIndex: ['qcCount', 'unitName'],
        width: 120,
      },
      materialAuxUnitList && {
        title: '辅助数量',
        dataIndex: ['inventory', 'opAmount', 'auxAmounts', 0, 'amount'],
        width: 120,
        render: (_: any, field: any) => {
          return field?.inventory?.opAmount?.auxAmounts[0]?.amount;
        },
      },
      materialAuxUnitList && {
        title: '辅助单位',
        dataIndex: ['inventory', 'opAmount', 'auxAmounts', 0, 'unit', 'name'],
        width: 120,
        render: (_: any, field: any) => {
          return field?.inventory?.opAmount?.auxAmounts[0]?.unit?.name;
        },
      },
      isRecordSample &&
        isReportFormatType && {
          title: '抽样量',
          dataIndex: 'newQcCount',
          width: 120,
          render: (_: any, field: any, index: number) => {
            const precisionFigure = field?.qcCheckCount?.precisionFigure ?? NUMBER_SCALE;

            return (
              <Form.Item
                rules={[
                  {
                    validator: numberMinMaxCheck({
                      min: 0,
                      minAllowEqual: false,
                      max: field?.qcCount?.count,
                      maxAllowEqual: true,
                      message: `抽样量量需大于0且小于等于${field?.qcCount?.count}`,
                    }),
                  },
                  {
                    validator: numberMinMaxCheck({
                      min: 0,
                      minAllowEqual: false,
                      message: '请输入大于0的数',
                    }),
                  },
                  {
                    validator: fractionLengthCheck(precisionFigure),
                  },
                ]}
                name={[index, 'newQcCount']}
                fieldKey={[field.key, 'newQcCount']}
                style={{ margin: 'auto' }}
              >
                <Input
                  disabled={field.category === CategoryEnum.OVERALL}
                  onBlur={() => handelCheckCountChanges(field.qcTaskMaterialId, true)}
                />
              </Form.Item>
            );
          },
        },
      isRecordSample &&
        isReportFormatType &&
        materialAuxUnitList &&
        sampleProcess && {
          title: '辅助抽样量',
          dataIndex: 'newAuxAmounts',
          width: 120,
          render: (_: any, field: any, index: number) => {
            const precisionFigure = field?.auxAmounts[0]?.unit?.precisionFigure ?? NUMBER_SCALE;

            return (
              <Form.Item
                rules={[
                  {
                    validator: numberMinMaxCheck({
                      min: 0,
                      minAllowEqual: true,
                      max: field?.inventory?.opAmount?.auxAmounts[0]?.amount,
                      maxAllowEqual: true,
                      message: `辅助抽样量量需大于等于0且小于等于${field?.inventory?.opAmount?.auxAmounts[0]?.amount}`,
                    }),
                  },
                  {
                    validator: numberMinMaxCheck({
                      min: 0,
                      minAllowEqual: true,
                      message: '请输入大于等于0的数',
                    }),
                  },
                  {
                    validator: fractionLengthCheck(precisionFigure),
                  },
                ]}
                name={[index, 'newAuxAmounts']}
                fieldKey={[field.key, 'newAuxAmounts']}
                style={{ margin: 'auto' }}
              >
                <Input
                  disabled={field.category !== CategoryEnum.SAMPLE}
                  onBlur={() => handelCheckCountChanges(field.key)}
                />
              </Form.Item>
            );
          },
        },
    ]);
  };

  const inventory: DataFormLayoutInfoBlock = {
    title: '',
    column: 2,
    items: [
      {
        label: '总量:',
        render: () => {
          return isMaterialBatchRecordType ? (
            form.getFieldValue('totalCount')
          ) : (
            <Form.Item
              name={'totalCount'}
              style={{ width: 120 }}
              rules={_.compact([
                haveInvenList && {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: false,
                    message: '总量需大于0',
                  }),
                },
                {
                  validator: fractionLengthCheck(NUMBER_SCALE),
                },
              ])}
            >
              <Input onBlur={handelPlanCheckCount} disabled={isMaterialBatchRecordType} />
            </Form.Item>
          );
        },
      },
      {
        label: '计划抽样量:',
        name: 'planCheckCount',
        render: () => {
          return planCheckCount ?? '-';
        },
      },
      {
        label: '总抽样量:',
        render: () => {
          return isRecordSample && isReportFormatType ? (
            form.getFieldValue('checkCount')
          ) : (
            <Form.Item
              name={'checkCount'}
              style={{ width: 120 }}
              rules={_.compact([
                !inputCheckCount && {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: false,
                    message: '总抽样量需大于0',
                  }),
                },
                {
                  validator: fractionLengthCheck(NUMBER_SCALE),
                },
              ])}
            >
              <Input disabled={inputCheckCount} />
            </Form.Item>
          );
        },
      },
      {
        label: '',
        isFullLine: true,
        render: () => (
          <>
            {num ? (
              renderBatchLine()
            ) : (
              <Button
                icon={<BlIcon type="iconxinjiantianjia" />}
                onClick={() => {
                  setVisible(true);
                }}
                disabled={!hasAuth(authDict.qualityinspectiontask_search_add)}
                style={{ marginBottom: 10 }}
                type="primary"
              >
                添加库存
              </Button>
            )}
            <Form.List name={'inventoryList'}>
              {() => {
                return (
                  <BlTable
                    columns={getColumns()}
                    dataSource={form.getFieldValue('inventoryList')}
                    useColConfig
                    rowSelection={rowSelection}
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                    tableConfigKey={'inventoryListTable'}
                    sticky
                  />
                );
              }}
            </Form.List>
          </>
        ),
      },
      {
        label: '',
        name: 'qcMaterials',
        render: () => (
          <SelectInventory
            visible={visible}
            onCancel={() => setVisible(false)}
            onRefresh={() => {
              refreshTable();
            }}
            haveAuxUnitList={materialAuxUnitList}
            inventoryForm={form}
            materialId={materialId}
            qcTaskId={Number(id)}
          />
        ),
      },
    ],
  };

  const footer = (
    <>
      <Button type="primary" onClick={() => history.goBack()}>
        返回
      </Button>
      <Button type="primary" onClick={() => onFinish()}>
        保存
      </Button>
    </>
  );

  const onFinish = async () => {
    const valueDate = visible ? '' : await form?.validateFields();

    setLoading(true);
    const value = form.getFieldValue('inventoryList');

    // 样本库存抽样量或辅助抽样量变动
    const checkCountChanges = _.filter(
      value.map((node: any) => {
        if (_.indexOf(subSimpleCountChanges, node.key) !== -1 && node.category === 2) {
          return {
            auxAmounts: node.newAuxAmounts
              ? [
                  {
                    count: node.newAuxAmounts ? Number(node.newAuxAmounts) : undefined,
                    unitId: node?.inventory?.opAmount?.auxAmounts[0]?.unit?.id,
                  },
                ]
              : [],
            id: node.qcTaskMaterialId,
            qcCheckCount: node.newQcCount ? Number(node.newQcCount) : undefined,
          };
        }
      }),
      (node) => {
        return !_.isUndefined(node);
      },
    );

    const toSamplesList = _.filter(
      value.map((node: any) => {
        if (_.indexOf(toSamples, node.key) !== -1) {
          return {
            auxAmounts: node.newAuxAmounts
              ? [
                  {
                    count: node.newAuxAmounts ? Number(node.newAuxAmounts) : undefined,
                    unitId: node?.inventory?.opAmount?.auxAmounts[0]?.unit?.id,
                  },
                ]
              : [],
            id: node.qcTaskMaterialId,
            qcCheckCount: node.newQcCount ? Number(node.newQcCount) : undefined,
          };
        }
      }),
      (node) => {
        return !_.isUndefined(node);
      },
    );

    const qcMaterials = {
      checkCountChanges,
      toSamples: toSamplesList,
      toTotalIds,
      deleteIds: deleteIds.map((node: any) => {
        return Number(node);
      }),
    };

    try {
      const data = await fetchQcTaskUpdateMaterial({
        id: Number(id),
        qcMaterials,
        checkCount: Number(form.getFieldValue('checkCount')),
        totalCount: Number(form.getFieldValue('totalCount')),
      });

      if (data?.code === 200) {
        message.success('保存成功');
        if (sameAsSampleSize && form.getFieldValue('checkCount') >= 100) {
          message.warning('当前抽样量过大，填写分数归置为1');
        }
        refreshTable();
        resetForm({
          inventoryList: form.getFieldValue('inventoryList'),
          checkCount: form.getFieldValue('checkCount'),
          totalCount: form.getFieldValue('totalCount'),
          planCheckCount,
          qcMaterials: undefined,
        });
        history.goBack();
      }
      setLoading(false);
    } catch (error) {
      console.log(error, 'error');
      setLoading(false);
    }
  };

  const onDelete = async (ids: any) => {
    setLoading(true);

    const qcMaterials = {
      checkCountChanges: [],
      toSamples: [],
      toTotalIds: [],
      deleteIds: ids.map((node: any) => {
        return Number(node);
      }),
    };

    try {
      const data = await fetchQcTaskUpdateMaterial({
        id: Number(id),
        qcMaterials,
        checkCount: Number(form.getFieldValue('checkCount')),
        totalCount: Number(form.getFieldValue('totalCount')),
      });

      if (data?.code === 200) {
        message.success('删除成功');
        const newSelectedRows = form.getFieldValue('inventoryList').filter((row: any) => {
          if (_.indexOf(selectedRowKeys, row.key) === -1) {
            return row;
          }
        });

        form.setFieldsValue({
          inventoryList: newSelectedRows,
        });
        setNum(0);
        setDeleteIds(_.concat(deleteIds, selectedRowKeys));
        setSelectedRowKeys([]);

        linkageCheckCount();
        linkageTotalCount();
      }
      setLoading(false);
    } catch (error) {
      console.log(error, 'error');
      setLoading(false);
    }
  };

  return (
    <>
      <DataFormLayout
        formLayout="horizontal"
        form={form}
        confirmLoading={loading}
        title="库存列表"
        info={[inventory]}
        footer={footer}
        loading={loading}
      />
    </>
  );
};

export default Inventory;
