import React from 'react';
import { DetailLayout } from 'layout';
import { BlTable, TagList } from 'src/components';
import lookup from 'src/dict';
import { getMaterialAttrs } from 'src/entity/material';
import { gcArray } from 'src/utils';

//

interface FeedingControlDetailProps {
  dataSource?: any;
}

const AlternativeMaterialsDetail = (props: FeedingControlDetailProps) => {
  const { dataSource } = props;

  const renderOriginalAlternative = () => {
    const columns = [
      {
        title: '序号',
        dataIndex: 'lineNo',
        fixed: 'left',
        width: 120,
        render: (text: any, _record, index) => index + 1,
      },
      {
        title: '使用比例',
        dataIndex: 'useRatio',
        width: 120,
      },
      {
        title: '物料编码',
        dataIndex: ['materialDO', 'baseInfo', 'code'],
        width: 120,
      },
      {
        title: '物料名称',
        dataIndex: ['materialDO', 'baseInfo', 'name'],
        width: 120,
      },
      {
        title: '物料属性',
        dataIndex: ['materialDO'],
        width: 150,
        render: (text, record) => <TagList dataSource={getMaterialAttrs(record.materialDO)} />,
      },
      {
        title: '物料分类',
        dataIndex: ['materialDO', 'category', 'name'],
        width: 150,
      },
      {
        title: '物料规格',
        dataIndex: ['materialDO', 'baseInfo', 'specification'],
        width: 150,
      },
      {
        title: '数量:分子',
        dataIndex: 'amountNumerator',
        width: 150,
      },
      {
        title: '数量:分母',
        dataIndex: 'amountDenominator',
        width: 150,
      },
      {
        title: '单位',
        dataIndex: ['unit', 'name'],
        width: 150,
      },
    ];

    return (
      <BlTable
        scroll={{ x: 'max-content', y: 'auto' }}
        dataSource={dataSource?.workOrderAlternativePlan?.originalAlternativeMaterials}
        columns={columns}
        rowKey="id"
      />
    );
  };

  const renderAlternativeMaterialsTable = () => {
    const columns = [
      {
        title: '序号',
        dataIndex: 'lineNo',
        fixed: 'left',
        width: 120,
        render: (text: any, _record, index) => index + 1,
      },
      {
        title: '使用比例',
        dataIndex: 'useRatio',
        width: 120,
      },
      {
        title: '标准用量',
        dataIndex: ['standardUseAmount', 'amountDisplay'],
        width: 120,
      },
      {
        title: '需求数',
        dataIndex: ['requirementAmount', 'amountDisplay'],
        width: 120,
      },
      {
        title: '优先级',
        dataIndex: 'priority',
        width: 120,
      },
      {
        title: '物料编码',
        dataIndex: ['materialDO', 'baseInfo', 'code'],
        width: 120,
      },
      {
        title: '物料名称',
        dataIndex: ['materialDO', 'baseInfo', 'name'],
        width: 120,
      },
      {
        title: '物料属性',
        dataIndex: ['materialDO'],
        width: 150,
        render: (text, record) => <TagList dataSource={getMaterialAttrs(record.materialDO)} />,
      },
      {
        title: '物料分类',
        dataIndex: ['materialDO', 'category', 'name'],
        width: 150,
      },
      {
        title: '物料规格',
        dataIndex: ['materialDO', 'baseInfo', 'specification'],
        width: 150,
      },
      {
        title: '数量:分子',
        dataIndex: 'amountNumerator',
        width: 150,
      },
      {
        title: '数量:分母',
        dataIndex: 'amountDenominator',
        width: 150,
      },
      {
        title: '单位',
        dataIndex: ['unit', 'name'],
        width: 150,
      },
      {
        title: '领料方式',
        dataIndex: 'pickMode',
        width: 150,
        render: (text: any) => lookup('productionPlanning.pickModeMap', text),
      },
      {
        title: '投料质量状态',
        dataIndex: 'feedingQualityStatus',
        width: 150,
        render: (text: any, record: any) => {
          const { feedingQualityStatus } = record;

          return !gcArray.isEmpty(feedingQualityStatus)
            ? feedingQualityStatus
                ?.map((item: any) => lookup('material.feedingQualityStatus', item))
                .join(',')
            : null;
        },
      },
      {
        title: '使用比例上限',
        dataIndex: 'useUpperLimitRatio',
        width: 150,
      },
      {
        title: '备注',
        dataIndex: 'remark',
        width: 150,
      },
      {
        title: '申请领料数',
        dataIndex: ['requestPickAmount', 'amountDisplay'],
        width: 150,
      },
      {
        title: '领料发料数',
        dataIndex: ['pickOrderIssuedAmount', 'amountDisplay'],
        width: 150,
      },
      {
        title: '领料收料数',
        dataIndex: ['receivePickAmount', 'amountDisplay'],
        width: 150,
      },
      {
        title: '实际投料数',
        dataIndex: ['inputAmount', 'amountDisplay'],
        width: 150,
      },
    ];

    return (
      <BlTable
        scroll={{ x: 'max-content', y: 'auto' }}
        dataSource={dataSource?.workOrderAlternativePlan?.alternativeMaterials}
        columns={columns}
        rowKey="id"
      />
    );
  };

  const getBaseInfo = () => {
    const baseInfo = [
      {
        title: '基本信息',
        items: [
          {
            label: '替代策略',
            dataIndex: 'substitutionStrategy',
            render: (text: any) => lookup('productionPlanning.SubstitutionStrategyMap', text),
          },
        ],
      },
      {
        title: '被替代物料',
        items: [
          {
            render: () => renderOriginalAlternative(),
          },
        ],
      },
      {
        title: '替代物料',
        items: [
          {
            render: () => renderAlternativeMaterialsTable(),
          },
        ],
      },
    ];

    return baseInfo;
  };

  return (
    <DetailLayout
      title="查看替代方案"
      info={getBaseInfo()}
      dataSource={dataSource?.workOrderAlternativePlan}
    />
  );
};

export default AlternativeMaterialsDetail;
