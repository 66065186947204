/**
 *  @control 工位
 */
import { FC } from 'react';
import { Select } from 'antd';
import { PreviewCompProps } from '../../types';

export const Station: FC<PreviewCompProps> = ({ inputPrompt }) => {
  return <Select style={{ pointerEvents: 'none' }} tabIndex={-1} placeholder={inputPrompt} />;
};
