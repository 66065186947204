import _ from 'lodash';
//
import { BomFormMaterial, BomResponseMaterial } from './index.type';

/**
 * 获取默认行号，项次
 */
export const getLineSeq = (index: number, baseSeq: number = 0) => {
  return (index + 1) * 10 + baseSeq * 100;
};

// 格式化 物料 给接口
export const formatMaterialToApi = (material?: BomFormMaterial): number => {
  if (_.isNil(material?.value) || !material?.value) return 0;

  const id = _.get(JSON.parse(material.value), ['baseInfo', 'id']);

  if (id) return Number(id);

  return 0;
};

// 格式化 物料 给form
export const formatMaterialToForm = (
  material: BomResponseMaterial,
): BomFormMaterial | undefined => {
  if (material) {
    return {
      label: material?.baseInfo?.code,
      value: JSON.stringify(material),
    };
  }

  return undefined;
};
/**
 * 设置 form 关联的字段值
 */
export const setAssociatedFormFields = (
  formValue: any,
  fields: [name: string, value?: any | ((value: any) => any)][],
  checkFn?: (item: any, index: number) => boolean,
) => {
  const newFormValue = _.cloneDeep(formValue);

  const setFieldsValue = (obj: any) => {
    _.forEach(fields, ([name, value = undefined]) => {
      if (typeof value === 'function') {
        _.set(obj, name, value(_.get(obj, name)));
      } else {
        _.set(obj, name, value);
      }
    });
  };

  // 数组的情况
  if (_.isArray(newFormValue)) {
    _.forEach(newFormValue, (item, index) => {
      if (!checkFn || checkFn?.(item, index)) {
        setFieldsValue(item);
      }
    });
  } else {
    setFieldsValue(newFormValue);
  }

  return newFormValue;
};
