import { message as Message, Modal } from 'antd';

import { fetchSalesOrderReturnClose } from 'src/api/ytt/order-domain/salesReturnOrder';

export const useCloseSalesReturnOrderModal = () => {
  const show = (id: number, refresh?: () => void) => {
    Modal.confirm({
      title: '关闭销售退货单',
      content: (
        <div>
          确认关闭销售退货单？
          <br />
          关闭后将无法执行任何操作
        </div>
      ),
      okText: '确认关闭',
      cancelText: '取消',
      onOk: async () => {
        const response = await fetchSalesOrderReturnClose({ id });

        if (response?.code === 200) {
          Message.success('销售退货单已关闭成功');
          refresh?.();
        } else {
          Message.error(response.message);
        }
      },
    });
  };

  return { show };
};
