/**
 * @component SOP方案编辑页的步骤列表
 */
import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import _ from 'lodash';
import { Button, Form, Input, message, Modal } from 'antd';
import { BlIcon } from 'components';
import { fetchSopStepDelete, fetchSopStepSort } from 'src/api/ytt/sop-domain';
import { fieldTypeList } from 'utils';
import { BlTable } from 'src/components';
import CopyStepModal, { CopyStepModalProps } from './copyStepModal';
import lookup from 'src/dict';
import { CRUD } from 'src/dict/common';
import { StepEntityType, PrivilegeType, GroupType } from 'src/dict/sop';
import authDict, { hasAuth, NO_AUTH_MESSAGE } from 'src/utils/auth';
import { getSiblingsById, getGroupTree, getById, mapSteps } from '../../share/stepUtils';
import {
  getRenderStepName,
  renderExecAuth,
  renderSelfLoop,
  renderDigitalSign,
  getRenderNextStep,
} from '../../share/stepRenderers';
import { StepOverviewData, StepOverviewList, StepRowData } from '../../types';
import useStepSearch from '../../share/useStepSearch';
import extractIdsForStep from '../../share/extractIdsForStep';
import DraggableRow from './DraggableRow';
import styles from './styles.module.scss';

interface Props {
  history: any;
  isEdit: boolean;
  sopId?: number; // 方案id
  data: any;
  reFetch: () => void;
}

type CopyModalState = Omit<CopyStepModalProps, 'onOk' | 'onCancel' | 'steps'>;

const initialCopyModalState = {
  visible: false,
  initValues: {
    id: 0,
    referenceId: 0,
    name: '',
    type: StepEntityType.step,
    privilegeType: PrivilegeType.executors,
    groupType: GroupType.serial,
    authUsers: [],
    authDepartments: [],
    authRoles: [],
  },
  groupTree: [],
};

/** 查找一个步骤被其他哪些步骤用作后续步骤 */
const getPreviousSiblings = (steps: StepOverviewList, stepId: number) => {
  const siblings = getSiblingsById(steps, stepId);

  return siblings.filter((step) => step.manualNextStepIds?.includes(stepId));
};

// 查找节点，并返回当前所属的父节点
function treeFindParent(tree: StepRowData[], id: number) {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].id === id) return tree;
    if (tree[i].children) {
      const res: any = treeFindParent(tree[i].children, id);

      if (res) return res;
    }
  }
  return null;
}
// 拖拽 END

const renameChildren = (steps: StepRowData[]): any[] => {
  return steps.map((item) => {
    if (item.type === StepEntityType.stepGroup) {
      return { ..._.omit(item, 'children'), childrenSteps: renameChildren(item.children) };
    }
    return _.omit(item, 'children');
  });
};

const MAX_OPERATION_COUNT = 3;
const DEFAULT_WIDTH = 20;
const OPERATION_BUTTON_SPACE = 16;
const FONT_SIZE = 14;
const CELL_PADDING = 24;

const StepList = (props: Props) => {
  const { history, sopId, data = [], reFetch } = props;
  // 完整的步骤数据
  const [dataSource, setDataSource] = useState(data);
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [copyModalState, setCopyModalState] = useState<CopyModalState>(initialCopyModalState); // 复制弹窗
  const [loading, setLoading] = useState(false);
  const [delForm] = Form.useForm();
  const { searchSteps, getDisplaySteps } = useStepSearch();

  const dataColumns = [
    // 这一列必须是dataColumns里的第一列，否则步骤组没有层级展开按钮
    {
      title: '',
      dataIndex: 'name',
      width: 50,
      render: getRenderStepName(dataSource),
    },
    // 用两个配置项列来渲染第一列, 保证拖拽手柄和层级展开按钮都可以正常展示, 有点hack
    {
      title: '步骤/步骤组名称',
      width: 500,
      render: () => ({
        props: {
          colSpan: 0,
          style: { padding: 0 },
        },
      }),
    },
    {
      title: '步骤/步骤组编号',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: '执行权限',
      dataIndex: 'privilegeType',
      width: 150,
      render: renderExecAuth,
    },
    {
      title: '电子签名',
      key: 'digitalType',
      width: 150,
      type: fieldTypeList.select,
      render: renderDigitalSign,
    },
    {
      title: '循环',
      dataIndex: 'selfLoopRule',
      type: fieldTypeList.select,
      width: 180,
      render: renderSelfLoop,
    },
    {
      title: '跳转方式',
      dataIndex: 'nextStepType',
      type: fieldTypeList.text,
      width: 180,
      render: getRenderNextStep(dataSource),
    },
  ];
  const ds = renameChildren(getDisplaySteps(dataSource));

  const moveRow = async (
    dragIndex: number,
    dropTargetIndex: number,
    dragId: number,
    dropTargetId: number,
  ) => {
    // 先检查权限
    if (!hasAuth(authDict.sopscheme_step_edit)) {
      message.error(NO_AUTH_MESSAGE);
      return;
    }
    const dragObject = getById(dataSource, dragId) as StepOverviewList[number];
    const dropTargetObject = getById(dataSource, dropTargetId) as StepOverviewList[number];
    const dropTargetPrevId = dropTargetObject.prevId;

    // 方便起见，直接修改 dataSource
    // ① 找出操作的 list，并去除 dragObject
    const list: StepOverviewList = treeFindParent(dataSource, dropTargetId);

    list.splice(
      list.findIndex((step) => step.id === dragId),
      1,
    );
    // ② 判断插入的位置, 修改prevId
    let insertIndex = 0;

    // dragObj 的后一项的 prev 指向 dragObj的 原prev
    if (dragIndex < list.length) {
      // 考虑到 dragObj 已被滤出
      list[dragIndex].prevId = dragObject.prevId!;
    }
    if (dragIndex > dropTargetIndex) {
      // 上移：dragObj 放到 dropTarget 之前
      insertIndex = dropTargetIndex;
      // dropTarget 的 prev 指向 dragObj
      dropTargetObject.prevId = dragId;
      // dragObj 的 prev 指向 dropTarget 原来的 prev
      dragObject.prevId = dropTargetPrevId;
    } else {
      // 下移: dragId 放到 dropTarget 之后, 但注意 list 里已经去掉了 dragObj, 故 dropTargetIndex 需 -1
      insertIndex = dropTargetIndex - 1 + 1;
      // dropTarget 的 prev 不变
      // dragObj 的 prev 指向 dropTarget
      dragObject.prevId = dropTargetId;
      // dropTarget 原来的后一项(如有)的 prev 指向 dragObj
      if (dropTargetIndex < list.length + 1 - 1) {
        list[insertIndex].prevId = dragId;
      }
    }
    // ③ 插入 dragObject
    list.splice(insertIndex, 0, dragObject);
    setLoading(true);
    try {
      await fetchSopStepSort(mapSteps(dataSource, extractIdsForStep));
      await reFetch();
      setLoading(false);
    } catch (error) {
      message.error('拖动排序失败，请刷新页面重试');
    }
  };

  // 删除步骤
  const handleDeleteSopStep = useCallback(async (step: StepOverviewData) => {
    try {
      await fetchSopStepDelete({
        id: step.id,
        referenceId: _.toNumber(step.referenceId),
        name: step.name,
        reason: delForm.getFieldValue('delReason'),
        type: _.toNumber(step.type),
      });
      await reFetch();
      delForm.resetFields();
      message.success('删除成功！');
    } catch (error) {
      console.log('error', error);
    }
  }, []);

  const getOperationList = (record: StepOverviewData) => {
    return [
      {
        title: lookup('common.crud', CRUD.copy),
        key: 'copy',
        auth: authDict.sopscheme_step_add,
        onClick: () => {
          setCopyModalState({
            visible: true,
            initValues: _.pick(record, [
              'id',
              'referenceId',
              'name',
              'type',
              'groupType',
              'privilegeType',
              'authUsers',
              'authDepartments',
              'authRoles',
            ]),
            groupTree: getGroupTree(dataSource),
          });
        },
      },
      {
        title: lookup('common.crud', CRUD.delete),
        key: 'delete',
        auth: authDict.sopscheme_step_remove,
        onClick: () => {
          const previousSiblings = getPreviousSiblings(dataSource, record.id);

          if (previousSiblings.length > 0) {
            message.error(
              `不能删除，因为当前步骤是以下步骤：${_.map(previousSiblings, 'name').join(
                '、',
              )} 的后续步骤。`,
              8,
            );
            return;
          }

          Modal.confirm({
            title: `确认要删除当前${lookup('sop.stepEntityType', record.type)}吗？`,
            onOk: () => handleDeleteSopStep(record),
          });
        },
      },
    ].filter(({ auth }) => hasAuth(auth));
  };

  const appendOperationColumn = useCallback(
    (columns: any[]) => {
      const visibleOps = getOperationList({} as any);
      const textNumber =
        _.sumBy(visibleOps.slice(0, MAX_OPERATION_COUNT), 'title.length') +
        (visibleOps.length > MAX_OPERATION_COUNT ? 1 : 0);

      const contentWidth =
        CELL_PADDING * 2 +
        Math.min(visibleOps.length - 1, MAX_OPERATION_COUNT) * OPERATION_BUTTON_SPACE +
        textNumber * FONT_SIZE;
      const opColumn = {
        title: '操作',
        className: styles['step-operation-column'],
        fixed: 'right',
        width: contentWidth || DEFAULT_WIDTH,
        render: (__: unknown, record: StepOverviewData) => {
          // 新建子步骤
          // 如果新建的是步骤组下的第一个项，则 prev 为 0
          // 否则 parent = record.id,  prev = 最后一个child的id

          const ops = getOperationList(record);
          const buttons = ops.slice(0, MAX_OPERATION_COUNT).map(({ title, onClick, key }) => {
            // 步骤的新建按钮特殊处理为Dropdown
            // if (title === lookup('crud', CRUD.create)) {
            //   return (
            //     <Dropdown
            //       overlay={
            //         <Menu>
            //           <Menu.Item
            //             disabled={type !== StepEntityType.stepGroup}
            //             key={'addSubStep'}
            //             onClick={() => {
            //               history.push(
            //                 `/sop/processEngine/scheme/${sopId}/edit/step/create?type=${StepEntityType.step}&parentId=${_parentId}&prevId=${_prevId}`,
            //               );
            //             }}
            //           >
            //             子步骤
            //           </Menu.Item>
            //           <Menu.Item
            //             disabled={type !== StepEntityType.stepGroup}
            //             key={'addSubStepGroup'}
            //             onClick={() => {
            //               history.push(
            //                 `/sop/processEngine/scheme/${sopId}/edit/step/create?type=${StepEntityType.stepGroup}&parentId=${_parentId}&prevId=${_prevId}`,
            //               );
            //             }}
            //           >
            //             子步骤组
            //           </Menu.Item>
            //           <Menu.Item
            //             key={'addStep'}
            //             onClick={() => {
            //               // 顶级步骤 - 创建后续步骤，parent = 0 ， prev = record.id
            //               // 子步骤 - 创建后续步骤， parent = record.parentId ， prev = record.id
            //               // parent 可直接使用后端返回的 record.parentId
            //               history.push(
            //                 `/sop/processEngine/scheme/${sopId}/edit/step/create?type=${StepEntityType.step}&parentId=${parentId}&prevId=${id}`,
            //               );
            //             }}
            //           >
            //             后续步骤
            //           </Menu.Item>
            //           <Menu.Item
            //             key={'addStepGroup'}
            //             onClick={() => {
            //               history.push(
            //                 `/sop/processEngine/scheme/${sopId}/edit/step/create?type=${StepEntityType.stepGroup}&parentId=${parentId}&prevId=${id}`,
            //               );
            //             }}
            //           >
            //             后续步骤组
            //           </Menu.Item>
            //         </Menu>
            //       }
            //     >
            //       <Button type="link">{title}</Button>
            //     </Dropdown>
            //   );
            // }
            return (
              <Button type="link" onClick={onClick} key={key}>
                {title}
              </Button>
            );
          });
          // 后面的操作收进下拉框
          // const dropdown =
          //   ops.length > MAX_OPERATION_COUNT ? (
          //     <Dropdown
          //       overlay={
          //         <Menu>
          //           {ops.slice(MAX_OPERATION_COUNT).map(({ title, onClick }) => (
          //             <Menu.Item onClick={onClick}>{title}</Menu.Item>
          //           ))}
          //         </Menu>
          //       }
          //     >
          //       <Button type="link">
          //         <BlIcon type="icongengduo" />
          //       </Button>
          //     </Dropdown>
          //   ) : null;

          return (
            <>
              {buttons}
              {/* {dropdown} */}
            </>
          );
        },
      };

      return !hasAuth(authDict.sopscheme_step_add) && !hasAuth(authDict.sopscheme_step_remove)
        ? [...columns]
        : [...columns, opColumn];
    },
    [history, dataSource],
  );

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  return (
    <div style={{ height: '600px' }}>
      <header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        {
          <>
            <Input.Search
              style={{ width: '200px' }}
              placeholder="请输入搜索关键字"
              onSearch={(val) => {
                const result = searchSteps(val, dataSource);

                if (!result) {
                  return;
                }
                const { ancestors } = result;

                setExpandedRowKeys(ancestors);
              }}
              allowClear
            />
            {hasAuth(authDict.sopscheme_step_add) && hasAuth(authDict.sopscheme_step_edit) && (
              <Button
                type="primary"
                onClick={() => {
                  history.push(`/sop/processEngine/scheme/${sopId}/edit/step/edit`);
                }}
              >
                <BlIcon type="iconbianji" />
                {lookup('common.crud', CRUD.edit)}
              </Button>
            )}
          </>
        }
      </header>
      <DndProvider backend={HTML5Backend}>
        <BlTable<StepOverviewData>
          className="step-list-table"
          scroll={{ y: 500 }}
          resizableCol
          pagination={false}
          columns={appendOperationColumn(dataColumns)}
          dataSource={ds}
          onRow={(record: any, index: any): any => ({
            index,
            moveRow,
            parentId: record.parentId,
          })}
          id="attrTable"
          rowKey="id"
          // rowKey={(record) => record.id}
          expandable={{
            childrenColumnName: 'childrenSteps',
            onExpand: (expanded, record) => {
              const nextExpandedRowKeys = expanded
                ? [...expandedRowKeys, record.id]
                : _.without(expandedRowKeys, record.id);

              setExpandedRowKeys(nextExpandedRowKeys);
            },
            expandedRowKeys,
          }}
          components={{
            body: {
              row: DraggableRow,
            },
          }}
          loading={loading}
        />
      </DndProvider>
      <CopyStepModal
        onOk={reFetch}
        onCancel={() => setCopyModalState(initialCopyModalState)}
        steps={dataSource}
        {...copyModalState}
      />
    </div>
  );
};

export default StepList;
