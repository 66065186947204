import { ApprovalStatus, ApprovalTaskStatus, ApprovalNodeStatus } from '.';
import { mappingsFactory } from '../utils';

/**
 * 我发起的审批单状态
 */

export const approvalStatus = mappingsFactory(
  ['审批中', ApprovalStatus.pending],
  ['已通过', ApprovalStatus.approved],
  ['已驳回', ApprovalStatus.rejected],
);

/**
 * 待我处理的审批任务状态
 */

export const approvalPendingStatus = mappingsFactory(
  ['待审批', ApprovalTaskStatus.pending],
  ['已关闭', ApprovalTaskStatus.closed],
);

/**
 * 我已处理的审批任务状态
 */

export const approvalHandledStatus = mappingsFactory(
  ['已通过', ApprovalTaskStatus.approved],
  ['已驳回', ApprovalTaskStatus.rejected],
);

/**
 * 审批任务所有状态
 */
export const approvalTaskStatus = mappingsFactory(
  ['待审批', ApprovalTaskStatus.pending],
  ['已通过', ApprovalTaskStatus.approved],
  ['已驳回', ApprovalTaskStatus.rejected],
  ['已关闭', ApprovalTaskStatus.closed],
);

/**
 * 审批节点状态
 */
export const approvalNodeStatus = mappingsFactory(
  ['未激活', ApprovalNodeStatus.waiting],
  ['已通过', ApprovalNodeStatus.approved],
  ['已驳回', ApprovalNodeStatus.rejected],
  ['审批中', ApprovalNodeStatus.pending],
);
