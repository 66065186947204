import { DetailLayout, DetailLayoutMenuItem } from 'layout';
import { detailBaseInfo, orderDetailColumn } from '../components/columns';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { BlTable } from 'src/components';
import { injectCustomFieldInfos, injectCustomFieldColumns } from 'src/components/customField';
interface DetailBaseInfoProps {
  detail: any;
  loading: boolean;
  baseMenu?: DetailLayoutMenuItem[];
  customFields?: any;
  itemCustomFields?: any;
}

export const DetailBaseInfo = ({
  detail,
  baseMenu,
  loading,
  customFields,
  itemCustomFields,
}: DetailBaseInfoProps) => {
  const baseInfo = [
    {
      title: '基本信息',
      items: detailBaseInfo,
    },
    injectCustomFieldInfos({ customFields, type: 'detail' }),
    {
      title: '发货单明细',
      items: [
        {
          label: '',
          dataIndex: 'table',
          render: () => {
            return (
              <div
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                }}
              >
                <BlTable
                  scroll={{ x: 1200, y: 800 }}
                  dataSource={
                    detail?.items &&
                    detail?.items.map((val: any) => {
                      if (detail.origin === 1) val.customerReceivedAmount.amount = undefined;
                      return val;
                    })
                  }
                  columns={injectCustomFieldColumns({
                    columns: orderDetailColumn(detail.origin),
                    customFields: itemCustomFields,
                    objectCode: OBJECT_OF_CODE.ShipmentsNoteItem,
                    type: 'detail',
                  })}
                  style={{ width: '100%' }}
                  rowKey={(record) => record?.id}
                />
              </div>
            );
          },
        },
      ],
    },
  ];

  return (
    <DetailLayout
      title="发货单详情"
      info={baseInfo}
      dataSource={detail}
      baseMenu={baseMenu}
      loading={loading}
    />
  );
};
