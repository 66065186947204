import { Cascader, CascaderProps, Form } from 'antd';
import { CascaderOptionType } from 'antd/lib/cascader';
import {
  fetchCustomFieldCustomFieldRelatedOneFieldList,
  fetchCustomFieldCustomFieldRelatedOneList,
} from 'src/api/ytt/metadata-domain/custom_field';
import React, { useState, useEffect } from 'react';
import { TargetType } from 'src/dict/customField';
import styles from '../index.scss';
import { number } from 'echarts';

interface Props extends CascaderProps {
  objectId: number | string;
  value?: any;
  onChange?: any;
}

interface Item {
  id: number | string;
  fieldName: string;
}

const BlCascader = (props: Props) => {
  const [options, setOptions] = useState([] as any[]);
  const { objectId, ...resProps } = props;

  const fetchData = async () => {
    const res = await fetchCustomFieldCustomFieldRelatedOneList({ objectId: Number(objectId) });

    return res;
  };

  const formateDataToOption = (list: any, isLeaf: any) => {
    return list
      ?.filter((i: { targetType: TargetType }) => i.targetType !== TargetType.multiChoice)
      .map((item: Item) => ({
        value: item.id,
        label: item.fieldName,
        isLeaf,
      }));
  };

  useEffect(() => {
    fetchData()
      .then((res) => {
        setOptions(formateDataToOption(res.data, false));
        return res;
      })
      .catch((e) => {});
  }, []);

  const loadData = (selectedOptions?: CascaderOptionType['options'] | undefined) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];

    targetOption.loading = true;
    fetchCustomFieldCustomFieldRelatedOneFieldList({ id: targetOption.value })
      .then((res) => {
        if (res.data) {
          const { data } = res;

          targetOption.loading = false;
          targetOption.children = formateDataToOption(data, true);
          setOptions([...options]);
        }
        return null;
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  return <Cascader options={options} loadData={loadData} changeOnSelect {...resProps} />;
};

export { BlCascader };
