import { Form, InputNumber, message, Select } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import { cloneDeep, compact, debounce, get, map, remove } from 'lodash';
import { useState, useEffect, FC } from 'react';
import {
  fetchSchedulingSchedulingMaterialAdd,
  FetchSchedulingSchedulingMaterialAddRequest,
  fetchSchedulingSchedulingMaterialGet,
  fetchSchedulingSchedulingMaterialUpdate,
} from 'src/api/ytt/wos-domain';
import { BlSortFormList, MaterialSelect, TagList } from 'src/components';
import lookup, { appEnum } from 'src/dict';
import { getMaterialAttrs, getMaterialUnitInfo, getMaterialUnits } from 'src/entity/material';
import { DataFormLayout } from 'src/layout';
import { validateBlInputNumberIntervalInt } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import ResourceSelectModal from 'src/page/knowledgeManagement/components/resourceConfig/selectModal';
import { arrayIsEmpty } from 'src/utils/array';
import { replaceSign } from 'src/utils/constants';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';

interface Props {
  params: any;
  //   name: string;
  onChange: (value?: any[]) => void;
  type: string;
  id?: string;
}

const maxCount = 100;

const MaterialScheduling: FC<Props> = (props) => {
  const { type, onChange, params } = props;

  const [form] = Form.useForm<any>();
  const [choosedValue, setChoosedValue] = useState<any>();
  const [showSelectModal, setShowSelectModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (type === appEnum.Common.CRUD.edit) {
      const { id } = params;

      fetchSchedulingSchedulingMaterialGet({ id: Number(id) })
        .then((res) => {
          const data = formatData(res?.data);

          form.setFieldsValue(data);
        })
        .catch((err) => {
          console.log('err: ', err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  const baseInfo = [
    {
      column: 3,
      items: [
        {
          label: '物料编号',
          name: 'materialId',
          rules: [{ required: true, message: '物料编号不能为空' }],
          render: () => (
            <MaterialSelect
              placeholder="请输入"
              labelInValue
              params={{
                enableFlag: appEnum.Common.UsageStatus.enabled,
                bizType: [appEnum.Material.BizType.default],
              }}
              disabled={type === appEnum.Common.CRUD.edit}
            />
          ),
        },
        {
          label: '物料名称',
          name: 'materialName',
          width: 120,
          render: () => {
            return (
              <Form.Item dependencies={[['materialId']]} style={{ marginBottom: '0' }}>
                {() => {
                  const material = JSON.parse(form.getFieldValue(['materialId'])?.value ?? '{}');

                  return get(material, ['baseInfo', 'name']);
                }}
              </Form.Item>
            );
          },
        },
        {
          label: '物料属性',
          name: 'attributes',
          width: 120,
          render: () => {
            return (
              <Form.Item dependencies={[['materialId']]} style={{ marginBottom: '0' }}>
                {() => {
                  const material = JSON.parse(form.getFieldValue(['materialId'])?.value ?? '{}');

                  return <TagList dataSource={getMaterialAttrs(material)} />;
                }}
              </Form.Item>
            );
          },
        },
        {
          label: '物料分类',
          name: 'category',
          width: 120,
          render: () => {
            return (
              <Form.Item dependencies={[['materialId']]} style={{ marginBottom: '0' }}>
                {() => {
                  const material = JSON.parse(form.getFieldValue(['materialId'])?.value ?? '{}');

                  return get(material, ['category', 'name']);
                }}
              </Form.Item>
            );
          },
        },
        {
          label: '物料规格',
          name: 'materialName',
          width: 120,
          render: () => {
            return (
              <Form.Item dependencies={[['materialId']]} style={{ marginBottom: '0' }}>
                {() => {
                  const material = JSON.parse(form.getFieldValue(['materialId'])?.value ?? '{}');

                  return get(material, ['baseInfo', 'specification']);
                }}
              </Form.Item>
            );
          },
        },
        {
          label: '业务类型',
          name: 'materialName',
          width: 120,
          render: () => {
            return (
              <Form.Item dependencies={[['materialId']]} style={{ marginBottom: '0' }}>
                {() => {
                  const material = JSON.parse(form.getFieldValue(['materialId'])?.value ?? '{}');

                  if (!arrayIsEmpty(material?.baseInfo?.bizRange)) {
                    const arr = material.baseInfo.bizRange.map((item: number) => {
                      return lookup('material.bizRange', item);
                    });

                    return arr.join('、');
                  }
                  return replaceSign;
                  // return get(material, ['baseInfo', 'specification']);
                }}
              </Form.Item>
            );
          },
        },
        {
          label: '排程优先级',
          name: 'priority',
          width: 120,
          render: () => {
            return (
              <Form.Item
                name={['priority']}
                fieldKey={['priority']}
                style={{ marginBottom: '0' }}
                rules={[
                  { validator: validateBlInputNumberIntervalInt(0, 100000) },
                  { required: true, message: '优先级必填' },
                ]}
              >
                <InputNumber placeholder="请输入" min={1} precision={0} />
              </Form.Item>
            );
          },
        },
        {
          name: ['id'],
        },
      ],
    },
    {
      isFullLine: true,
      title: '可选设备',
      items: [
        {
          isFullLine: true,
          name: ['usableResources'],
          render: () => {
            return (
              <BlSortFormList
                // form={form}
                name={'usableResources' as string}
                renderColumns={() => getColumns()}
                isNeedDrag={false}
                handleAdd={() => {
                  setShowSelectModal(true);
                }}
                buttonText={'批量添加'}
                onDelete={(index) => {
                  const choosedValueCopy = cloneDeep(choosedValue);

                  const evens = remove(choosedValueCopy, (i, n) => {
                    return index.find((e) => e !== n);
                  });

                  setChoosedValue(evens);
                }}
                maxCount={maxCount}
              />
            );
          },
        },
      ],
    },
  ];

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '资源序号',
        dataIndex: 'lineNos',
        render: (_text: string, field) => {
          return (
            <Form.Item
              fieldKey={[field.fieldKey, 'lineNos']}
              name={[field.name, 'lineNos']}
              style={{ marginBottom: '0' }}
            >
              {field.name + 1}
            </Form.Item>
          );
        },
      },
      {
        title: '设备编号',
        dataIndex: 'resourceCode',
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'resourceCode']}
              fieldKey={[field.fieldKey, 'resourceCode']}
              style={{ marginBottom: '0' }}
            >
              {form.getFieldValue(['usableResources', field.name, 'code']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '设备名称',
        dataIndex: 'resourceName',
        render: (_text: string, field) => {
          return (
            <Form.Item
              fieldKey={[field.fieldKey, 'resourceName']}
              name={[field.name, 'resourceName']}
              style={{ marginBottom: '0' }}
            >
              {form.getFieldValue(['usableResources', field.name, 'name']) ?? replaceSign}
            </Form.Item>
          );
        },
      },
      {
        title: '标准产能-数量',
        dataIndex: 'standardCapacity',
        render: (text: string, field) => {
          return (
            <Form.Item noStyle dependencies={[['materialId']]}>
              {() => {
                const materialInfo = JSON.parse(form.getFieldValue(['materialId'])?.value ?? '{}');

                // TODO limeirong unitID
                const { enablePrecision, precisionFigure } = getMaterialUnitInfo(materialInfo);

                return (
                  <Form.Item
                    fieldKey={[field.fieldKey, 'standardCapacity']}
                    name={[field.name, 'standardCapacity']}
                    initialValue={1}
                    style={{ marginBottom: '0' }}
                    rules={compact([
                      { required: true, message: '产能数量必填' },
                      {
                        validator: (opt, val) => {
                          return new Promise<void>((resolve, reject) => {
                            const base = (val && val.toString()?.split('.')[0]) ?? val;

                            if (val <= 0) reject('产能数量必必须大于0');

                            if (base > 10000000) reject('产能数量必必须小于10000000');
                            return resolve();
                          });
                        },
                      },
                      enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
                    ])}
                  >
                    <InputNumber style={{ width: '100%' }} placeholder="请输入" />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '标准产能-单位',
        dataIndex: 'capacityUnitId',
        render: (_text: string, field) => {
          return (
            <Form.Item noStyle dependencies={[['materialId']]}>
              {() => {
                const material = JSON.parse(form.getFieldValue(['materialId'])?.value ?? '{}');

                return (
                  <Form.Item
                    fieldKey={[field.fieldKey, 'capacityUnitId']}
                    name={[field.name, 'capacityUnitId']}
                    style={{ marginBottom: '0' }}
                    rules={[{ required: true, message: '产能单位必填' }]}
                  >
                    <Select
                      options={getMaterialUnits(material)}
                      // disabled={getFieldValue([name, field.name, 'isChildren'])}
                      allowClear
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '标准产能-时间',
        dataIndex: 'standardCapacityTime',
        render: (_text: string, field) => {
          return (
            <Form.Item
              fieldKey={[field.fieldKey, 'standardCapacityTime']}
              name={[field.name, 'standardCapacityTime']}
              style={{ marginBottom: '0' }}
              rules={[
                { required: true, message: '产能时间必填' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    minAllowEqual: true,
                    max: 10000000,
                    maxAllowEqual: false,
                    fieldName: '标准产能-时间',
                  }),
                },
              ]}
            >
              <InputNumber style={{ width: '100%' }} placeholder="请输入" max={10000} />
            </Form.Item>
          );
        },
      },
      {
        title: '标准产能-时间单位',
        dataIndex: 'standardCapacityTimeUnit',
        width: 180,
        render: (_text: string, field) => {
          return (
            <Form.Item
              fieldKey={[field.fieldKey, 'standardCapacityTimeUnit']}
              name={[field.name, 'standardCapacityTimeUnit']}
              rules={[{ required: true, message: '产能时间单位必填' }]}
              style={{ marginBottom: 0 }}
              initialValue={1}
            >
              <Select disabled>
                <Select.Option value={1}>小时</Select.Option>
              </Select>
            </Form.Item>
          );
        },
      },

      {
        title: '标准产能-优先级',
        dataIndex: 'priority',
        width: 180,
        render: (_text: string, field) => {
          return (
            <Form.Item
              fieldKey={[field.fieldKey, 'priority']}
              name={[field.name, 'priority']}
              style={{ marginBottom: 0 }}
              rules={[
                { validator: validateBlInputNumberIntervalInt(1, 100000) },
                // { validator: validatorIndex },
                { required: true, message: '产能优先级必填' },
              ]}
              initialValue={field.name + 1}
            >
              <InputNumber style={{ width: '100%' }} placeholder="请输入" max={100000} />
            </Form.Item>
          );
        },
      },
    ];
  };

  const formatData = (params: any) => {
    const { material, usableResources, ...rest } = params || {};

    const initValue = {
      ...rest,
      materialId: {
        key: JSON.stringify(material),
        value: JSON.stringify(material),
        label: material?.baseInfo?.code,
      },
      usableResources: map(
        usableResources,
        ({
          priority,
          standardCapacity,
          standardCapacityTime,
          standardCapacityTimeUnit,
          resourceCode,
          resourceName,
          resourceId,
        }) => {
          return {
            capacityUnitId: standardCapacity?.unitId,
            id: resourceId,
            priority,
            standardCapacity: standardCapacity?.amount,
            standardCapacityTime,
            standardCapacityTimeUnit,
            code: resourceCode,
            name: resourceName,
          };
        },
      ),
    };

    setChoosedValue(initValue?.usableResources);

    return initValue;
  };
  const formatValueToApi = (values: any): FetchSchedulingSchedulingMaterialAddRequest => {
    const { usableResources, materialId } = values;

    const material = JSON.parse(materialId?.value ?? '{}');

    return {
      ...values,
      materialId: material?.baseInfo?.id,
      usableResources: map(
        usableResources,
        ({
          capacityUnitId,
          id,
          priority,
          standardCapacity,
          standardCapacityTime,
          standardCapacityTimeUnit,
          code,
          name,
        }) => {
          return {
            capacityUnitId,
            // id,
            priority,
            resourceCode: code,
            resourceId: id,
            resourceName: name,
            standardCapacity,
            standardCapacityTime,
            standardCapacityTimeUnit,
          };
        },
      ),
    };
    // onChange(values);
  };

  const handleFinish = async () => {
    try {
      form
        ?.validateFields()
        .then((values) => {
          const value = formatValueToApi(values);

          if (arrayIsEmpty(value.usableResources)) {
            message.error('至少添加一个设备');
            return;
          }

          if (type === appEnum.Common.CRUD.edit) {
            fetchSchedulingSchedulingMaterialUpdate(value).then((res) => {
              const { code = 0 } = res ?? {};

              if (code === 200) message.success('编辑成功!');
              onChange();
            });
          } else {
            fetchSchedulingSchedulingMaterialAdd(value).then((res) => {
              const { code = 0 } = res ?? {};

              if (code === 200) message.success('创建成功!');
              onChange();
            });
          }
        })
        .catch((error) => {
          const { errorFields } = error;

          form.scrollToField(errorFields?.[0]?.name);
        });
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <>
      <DataFormLayout
        loading={loading}
        form={form}
        info={baseInfo}
        title={`${lookup('crud', type) ?? ''}排程物料`}
        onCancel={() => {
          onChange();
        }}
        onFinish={debounce(handleFinish, 1500)}
      />
      {showSelectModal && (
        <ResourceSelectModal
          isMultiple
          visible={showSelectModal}
          submitData={(val: any, resourceSelect: any) => {
            let data = resourceSelect;

            if (data?.length > maxCount) {
              data = resourceSelect.splice(1, maxCount);
              message.error(`最多可添加${maxCount}条`);
            }

            setChoosedValue(data);

            form.setFields([
              {
                name: 'usableResources',
                value: map(data, ({ resourceParamsList, ...rest }) => {
                  return {
                    ...rest,
                    resourceParamsList: map(resourceParamsList, ({ ...resourceRest }) => ({
                      ...resourceRest,
                      resourceCode: resourceRest?.code,
                      resourceName: resourceRest?.name,
                      id: resourceRest?.id,
                    })),
                  };
                }),
              },
            ]);
          }}
          params={{
            parentFlag: true,
          }}
          closeModal={() => {
            setShowSelectModal(false);
          }}
          defaultSelected={choosedValue}
        />
      )}
    </>
  );
};

export default MaterialScheduling;
