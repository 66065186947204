import React, { useState } from 'react';
import { Form, Select, Radio, InputNumber, Input } from 'antd';
import { SearchSelect } from 'src/components';
import { QC_LOGIC_TYPE, LOGIC } from 'src/page/quality/models/constants';
import { percentCheck } from 'src/page/custom_fields/fieldsList/components/verificationRules/verificationRules';
import {
  validatorNumberDigtis,
  naturalNumber,
  numberStringCheck,
  numberCheck,
} from 'src/utils/formValidators';
import styles from '../style.module.scss';
import _, { min } from 'lodash';
import { RuleObject } from 'antd/lib/form';
import { CheckItemInputTypeEnum, CheckValueTypeEnum } from 'src/dict/quality';
import { appDict } from 'src/dict';

const NumberInput = (props: { form: any; defaultScale: number }) => {
  const [, setReresh] = useState(0);
  const [standardScale, setStandardScale] = useState<number>(0);
  const { defaultScale, form } = props;
  const ENABLE_STATUS = 1;

  const chekItemInputType = form.getFieldValue('executeItemType');

  const isSingleNumber = (logic = form.getFieldValue('logic')) =>
    logic === LOGIC.LT ||
    logic === LOGIC.LTE ||
    logic === LOGIC.EQ ||
    logic === LOGIC.GT ||
    logic === LOGIC.GTE;

  const initialDefaultValue = {
    defaultMax: null,
    defaultMin: null,
    defaultValue: null,
    extraResults: [],
  };
  const initialValues = {
    base: null,
    min: null,
    max: null,
    unit: null,
    scale: defaultScale,
    checkValueType: form.getFieldValue('checkValueType') || CheckValueTypeEnum.singleNumber,
    defaultMax: null,
    defaultMin: null,
    defaultValue: null,
  };

  const resetFormValues = (value: number) => {
    if (!isSingleNumber(value)) {
      initialValues.checkValueType = CheckValueTypeEnum.between;
    } else {
      initialValues.checkValueType = CheckValueTypeEnum.singleNumber;
    }
    initialValues.logic = value;
    form.setFieldsValue(initialValues);
  };

  const numberMinMaxCheck = (config: {
    isMax?: boolean;
    isMin?: boolean;
    fieldName?: string;
    maxName?: string;
    minName?: string;
    maxKey?: string;
    minKey?: string;
    isMust?: boolean;
  }) => {
    return (_rule: any, value: any) => {
      const {
        isMax,
        isMin,
        fieldName = '数字',
        maxName = '最大值',
        minName = '最小值',
        maxKey = 'max',
        minKey = 'min',
        isMust = false,
      } = config;
      const max = form.getFieldValue(maxKey);
      const min = form.getFieldValue(minKey);
      const reg = /^(-?\d+)(\.\d+)?$/;

      return new Promise<void>((resolve, reject) => {
        if (_.isNil(value)) return resolve();
        if (isMust && !reg.test(value)) {
          return reject(new Error('请输入数字'));
        }
        if (!isMust && !reg.test(value)) {
          return resolve();
        }

        if ((!_.isNil(max) || !_.isNil(min)) && !_.isNil(value)) {
          if (isMin && max && Number(value) > Number(max)) {
            reject(`${fieldName}必须小于${maxName}`);
          } else if (isMax && Number(value) <= Number(min)) {
            reject(`${fieldName}必须大于${minName}`);
          }
        }

        return resolve();
      });
    };
  };

  const getBaseNumberRules = (config?: { name?: string }) => {
    const name = config?.name;

    if (chekItemInputType === CheckItemInputTypeEnum.inputFormatPercent) {
      return [
        {
          required: true,
          message: `请输入${name || '数值'}`,
        },
        {
          validator: validatorNumberDigtis(defaultScale),
        },
        {
          validator: percentCheck('请输入大于0小于100的数字'),
        },
        numberCheck,
      ];
    }
    return [
      {
        required: true,
        message: `请输入${name || '数值'}`,
      },
      {
        validator: validatorNumberDigtis(defaultScale),
      },
      numberCheck,
    ];
  };

  const scaleCheck = () => {
    return (_rule: RuleObject, value: string | number) => {
      return new Promise<void>((resolve, reject) => {
        if (value < standardScale) {
          reject(new Error('实测值精度不能小于标准值精度'));
        }
        if (value > defaultScale) {
          reject(new Error('实测值精度不能大于精度最大值'));
        }
        resolve();
      });
    };
  };

  const getInputNumber = ({ placeholder = '数值' }: { placeholder?: string }) => {
    return (
      <Input
        placeholder={placeholder}
        style={{ width: 88 }}
        onBlur={() => {
          const valueList = [
            form.getFieldValue('base'),
            form.getFieldValue('min'),
            form.getFieldValue('max'),
          ];

          setStandardScale(
            _.max(
              valueList
                .filter((value) => !_.isNil(value))
                .map((value) => value?.toString()?.split('.')[1]?.length || 0),
            ),
          );
        }}
      />
    );
  };

  const itemStyle = {
    marginBottom: 16,
  };

  return (
    <div className={styles.groupContent}>
      <div style={{ display: 'flex' }}>
        <Form.Item label="标准值" name="logic" className={styles.formulaInput} style={itemStyle}>
          <Select
            style={{ minWidth: 100 }}
            allowClear
            onChange={(value: number) => {
              setReresh(Math.random());
              resetFormValues(value);
            }}
          >
            {_.map(QC_LOGIC_TYPE, (item: { key: number; display: string }) => (
              <Select.Option key={item.key} value={Number(item.key)}>
                {item.display}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {form.getFieldValue('logic') === LOGIC.BETWEEN && (
          <>
            <Form.Item
              label=""
              name="min"
              className={styles.formulaInput}
              validateFirst
              rules={[
                ...getBaseNumberRules(),
                {
                  validator: numberMinMaxCheck({
                    isMin: true,
                    fieldName: '最小值',
                  }),
                },
              ]}
              style={itemStyle}
            >
              {getInputNumber({ placeholder: '最小值' })}
            </Form.Item>
            <Form.Item
              label=""
              name="max"
              className={styles.formulaInput}
              style={itemStyle}
              validateFirst
              rules={[
                ...getBaseNumberRules(),
                {
                  validator: numberMinMaxCheck({
                    isMax: true,
                    fieldName: '最大值',
                  }),
                },
              ]}
            >
              {getInputNumber({ placeholder: '最大值' })}
            </Form.Item>
          </>
        )}
        {isSingleNumber() && (
          <Form.Item
            label=""
            name="base"
            className={styles.formulaInput}
            style={itemStyle}
            rules={getBaseNumberRules()}
            validateFirst
          >
            {getInputNumber({ placeholder: '标准值' })}
          </Form.Item>
        )}
        {form.getFieldValue('logic') === LOGIC.TOLERANCE && (
          <>
            <Form.Item
              label=""
              name="base"
              className={styles.formulaInput}
              style={itemStyle}
              rules={getBaseNumberRules()}
              validateFirst
            >
              {getInputNumber({ placeholder: '标准值' })}
            </Form.Item>
            <Form.Item
              label=""
              name="max"
              className={styles.formulaInput}
              style={itemStyle}
              rules={[
                ...getBaseNumberRules({ name: '上偏差' }),
                {
                  validator: numberMinMaxCheck({
                    isMax: true,
                    fieldName: '上偏差',
                    minName: '下偏差',
                  }),
                },
              ]}
            >
              {getInputNumber({ placeholder: '上偏差' })}
            </Form.Item>
            <Form.Item
              label=""
              name="min"
              className={styles.formulaInput}
              style={itemStyle}
              rules={[
                ...getBaseNumberRules({ name: '下偏差' }),
                {
                  validator: numberMinMaxCheck({
                    isMin: true,
                    fieldName: '下偏差',
                    maxName: '上偏差',
                  }),
                },
              ]}
            >
              {getInputNumber({ placeholder: '下偏差' })}
            </Form.Item>
          </>
        )}
        {chekItemInputType === CheckItemInputTypeEnum.inputFormatNum &&
          form.getFieldValue('logic') && (
            <Form.Item
              label=""
              name="unit"
              className={styles.formulaInput}
              style={{ ...itemStyle, minWidth: 100 }}
              rules={[{ required: form.getFieldValue('logic'), message: '请选择单位' }]}
            >
              <SearchSelect
                fetchType="unit"
                params={{ enableFlag: ENABLE_STATUS, page: 1 }}
                style={{ minWidth: 100 }}
              />
            </Form.Item>
          )}
        {chekItemInputType === CheckItemInputTypeEnum.inputFormatPercent && (
          <span style={{ lineHeight: '32px' }}>%</span>
        )}
      </div>
      <Form.Item
        label="实测值格式"
        name="checkValueType"
        labelCol={{ flex: '100px' }}
        style={itemStyle}
        rules={[
          {
            required: true,
            message: '请选择实测值格式',
          },
        ]}
      >
        <Radio.Group
          options={appDict.quality.checkValueType}
          disabled={isSingleNumber()}
          onChange={() => {
            setReresh(Math.random());
            form.setFieldsValue({ ...initialDefaultValue });
          }}
        />
      </Form.Item>
      <Form.Item
        label={
          <span>
            <span style={{ color: '#ff4d4f', fontSize: 14 }}>* </span>实测值精度
          </span>
        }
        style={{ paddingBottom: 16, marginBottom: 0 }}
        labelCol={{ flex: '100px' }}
      >
        <Form.Item
          name="scale"
          rules={[
            {
              required: true,
              message: '请输入实测值精度',
            },
            {
              validator: scaleCheck(),
            },
            naturalNumber,
          ]}
          noStyle
        >
          <InputNumber />
        </Form.Item>
        <span className="ant-form-text" onChange={() => setReresh(Math.random())}>
          {' '}
          位小数
        </span>
      </Form.Item>
      <div style={{ display: 'flex' }}>
        <Form.Item
          label="默认值"
          name="defaultValue"
          className={styles.formulaInput}
          style={itemStyle}
          validateFirst
        >
          {form.getFieldValue('checkValueType') === CheckValueTypeEnum.between ? (
            <div style={{ display: 'flex' }}>
              <Form.Item
                label=""
                name="defaultMin"
                className={styles.formulaInput}
                validateFirst
                rules={_.compact([
                  {
                    validator: validatorNumberDigtis(form.getFieldValue('scale')),
                  },
                  {
                    validator: numberMinMaxCheck({
                      isMin: true,
                      fieldName: '最小值',
                      maxKey: 'defaultMax',
                    }),
                  },
                  chekItemInputType === CheckItemInputTypeEnum.inputFormatPercent && {
                    validator: percentCheck('请输入大于0小于100的数字'),
                  },
                ])}
                style={itemStyle}
              >
                {getInputNumber({ placeholder: '最小值' })}
              </Form.Item>
              <span style={{ marginRight: 10 }}>~</span>
              <Form.Item
                label=""
                name="defaultMax"
                className={styles.formulaInput}
                style={itemStyle}
                validateFirst
                rules={_.compact([
                  {
                    validator: validatorNumberDigtis(form.getFieldValue('scale')),
                  },
                  {
                    validator: numberMinMaxCheck({
                      isMax: true,
                      fieldName: '最大值',
                      minKey: 'defaultMin',
                    }),
                  },
                  chekItemInputType === CheckItemInputTypeEnum.inputFormatPercent && {
                    validator: percentCheck('请输入大于0小于100的数字'),
                  },
                  numberCheck,
                ])}
              >
                {getInputNumber({ placeholder: '最大值' })}
              </Form.Item>
            </div>
          ) : (
            <Form.Item
              label=""
              name="defaultValue"
              className={styles.formulaInput}
              style={itemStyle}
              validateFirst
              rules={_.compact([
                {
                  validator: validatorNumberDigtis(form.getFieldValue('scale')),
                },
                chekItemInputType === CheckItemInputTypeEnum.inputFormatPercent && {
                  validator: percentCheck('请输入大于0小于100的数字'),
                },
                numberCheck,
              ])}
            >
              {getInputNumber({ placeholder: '默认值' })}
            </Form.Item>
          )}
        </Form.Item>
      </div>
    </div>
  );
};

export default NumberInput;
