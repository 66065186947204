import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  DatePicker,
  Form,
  FormItemProps,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';
import moment from 'moment';

import { BcAttachmentForm, NumberRulesStandardHook, SearchSelect } from 'components';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { RootState } from 'src/store';
import { checkTwoSidesTrim, numberAlphabetSpecialSymbols } from 'src/utils/formValidators';
import { appDict, appEnum } from 'src/dict';
import { ResourceBusinessType } from 'src/dict/resources';
import UserDeptRoleSelect from 'src/page/organization/components/userDeptRoleSelect';
import ResourceClassificationCascader from 'src/page/resource/components/ResourceClassificationCascader';
import { fetchResourcesList } from 'src/api/ytt/resource-domain/resource';
import { fetchRepairCaseDetail } from 'src/api/ytt/resource-domain/repairPlan';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { formatDataToForm as formatCaseDataToForm } from 'src/page/resource/repairPlan/actions';
import { getStandardWorkTime, setStandardWorkTime } from 'src/page/resource/repairPlan/utils';
//
import { MAX_INPUT_LENGTH, MAX_TEXTAREA_LENGTH, MAX_USER_COUNT, fieldLabels } from '../constants';
import {
  ContentProps,
  RepairTaskCreateApiType,
  RepairTaskDetailType,
  IBaseInfoForm,
} from '../index.d';
import { BL_BUSINESS_CODE } from '../config';
import { MaintenanceTreeStateParams } from '../../components/maintenanceTree';
import MalfunctionTagFormList from 'src/page/resource/components/resourceTagFormList/TagFormList';

export const formatDataToApi = (data: IBaseInfoForm, type?: string): RepairTaskCreateApiType => {
  const {
    id,
    code = '',
    bizId,
    repairClassificationId,
    standardWorkTimeH,
    standardWorkTimeM,
    standardWorkTimeS,
    planExecutorList,
    currentExecutorList,
    repairCaseId,
    executeIds,
    planStartTime,
    planEndTime,
    malfunctionClassificationId,
    malfunctionLabelList,
    reporterList,
    ...resData
  } = data;

  return {
    ...resData,
    id: type === appEnum.Common.CRUD.edit ? id : undefined,
    code,
    repairClassificationId: !_.isEmpty(repairClassificationId)
      ? _.last(repairClassificationId)
      : undefined,
    malfunctionClassificationId: !_.isEmpty(malfunctionClassificationId)
      ? _.last(malfunctionClassificationId)
      : undefined,
    malfunctionTagsLink: malfunctionLabelList?.map((tag) => ({
      tagsId: tag.tagsId,
      id: tag.id,
    })),
    planExecutorList: _.map(planExecutorList, ({ value, type }) => ({
      bizId: value,
      bizType: type,
    })),
    currentExecutorList: _.map(currentExecutorList, ({ value }) => ({
      bizId: value,
      bizType: 0,
    })),
    bizId: !_.isNil(bizId) ? JSON.parse(bizId.value)?.id : undefined,
    standardWorkTime: setStandardWorkTime(standardWorkTimeH, standardWorkTimeM, standardWorkTimeS),
    executeIds: _.compact(_.map(executeIds, 'value')),
    repairCaseId: _.get(repairCaseId, 'value', 0),
    planStartTime: !_.isNil(planStartTime) ? _.toNumber(planStartTime.valueOf()) : 0,
    planEndTime: !_.isNil(planEndTime) ? _.toNumber(planEndTime.valueOf()) : 0,
    reporterIds: reporterList?.map((reporter) => reporter.id ?? reporter.value),
  };
};

export const formatDataToForm = (data: RepairTaskDetailType): IBaseInfoForm => {
  const {
    name = '',
    bizVO,
    bizType = appEnum.Resources.ResourceBusinessType.equipment,
    repairClassificationList,
    executeType = appEnum.Resources.RepairExecuteType.reportTemplate,
    standardWorkTime,
    planExecutorList,
    currentExecutorList,
    scanCodeFlag = appEnum.Common.YNB.no,
    reportTemplateList,
    sopList,
    repairCase,
    planStartTime,
    planEndTime,
    malfunctionClassificationList,
    malfunctionLabelList,
    ...resData
  } = data;

  const {
    H: standardWorkTimeH,
    M: standardWorkTimeM,
    S: standardWorkTimeS,
  } = getStandardWorkTime(standardWorkTime);

  const isSopExecute = executeType === appEnum.Resources.RepairExecuteType.SOP;

  return {
    ...resData,
    name,
    bizType,
    bizId: !_.isNil(bizVO)
      ? {
          label: bizVO.name,
          value: JSON.stringify({
            ..._.pick(bizVO, ['id', 'level', 'hasChildren', 'code']),
            locationName: _.get(bizVO, 'location.name'),
          }),
        }
      : undefined,
    executeType,
    scanCodeFlag,
    repairClassificationId: !_.isEmpty(repairClassificationList)
      ? _.compact(_.map(repairClassificationList, 'id'))
      : undefined,
    planExecutorList: _.map(planExecutorList, ({ name, bizId, bizType }) => ({
      label: name,
      value: bizId,
      type: bizType,
    })),
    currentExecutorList: _.map(currentExecutorList, ({ name, bizId }) => ({
      label: name,
      value: bizId,
    })),
    repairCaseId: !_.isNil(repairCase)
      ? { label: repairCase?.name, value: repairCase?.id }
      : undefined,
    standardWorkTimeH,
    standardWorkTimeM,
    standardWorkTimeS,
    planStartTime: !_.isNil(planStartTime) ? moment(planStartTime) : undefined,
    planEndTime: !_.isNil(planEndTime) ? moment(planEndTime) : undefined,
    executeIds: isSopExecute
      ? _.map(sopList, ({ id, name }) => ({ label: name, value: id }))
      : _.map(reportTemplateList, ({ id, name }) => ({ label: name, value: id })),
    malfunctionClassificationId: !_.isEmpty(malfunctionClassificationList)
      ? _.compact(_.map(malfunctionClassificationList, 'id'))
      : undefined,
    malfunctionLabelList: malfunctionLabelList?.map((tag) => {
      return {
        tagParentId: {
          label: tag.name,
          value: JSON.stringify({ id: tag.id, children: tag.children }),
        },
        tagsId: tag.labelValueId!,
        id: tag.tagsLinkId,
      };
    }),
  };
};

/**
 * 获取维修方案中 任务需要的信息
 * @param params
 */
export const getRepairCaseForTask = async (repairCaseId?: number) => {
  try {
    if (repairCaseId) {
      const res = await fetchRepairCaseDetail({ id: repairCaseId });

      const data = _.get(res, 'data');

      if (!_.isNil(data)) {
        const {
          executeType,
          executeIds,
          planExecutorList,
          standardWorkTimeH,
          standardWorkTimeM,
          standardWorkTimeS,
          channelType,
          classificationId,
          scanCodeFlag,
          bizId,
          fileIds,
          malfunctionClassificationId,
          malfunctionTagsLink,
          malfunctionLevel,
        } = formatCaseDataToForm(data);

        return {
          executeType,
          executeIds,
          planExecutorList,
          standardWorkTimeH,
          standardWorkTimeM,
          standardWorkTimeS,
          channelType,
          repairClassificationId: classificationId,
          scanCodeFlag,
          bizId,
          fileIds,
          malfunctionClassificationId,
          malfunctionLabelList: malfunctionTagsLink,
          malfunctionLevel,
        };
      }

      return {};
    }

    return {};
  } catch (error) {
    return {};
  }
};

const ActionContent = (props: ContentProps) => {
  const { form, type, refreshMarker } = props;

  const currentUser = useSelector((state: RootState) => state.user.userInfo);

  const listCacheData: MaintenanceTreeStateParams = JSON.parse(
    sessionStorage.getItem(BL_BUSINESS_CODE) ?? '{}',
  );

  useEffect(() => {
    initailBiz();
  }, []);

  /** 默认空的维修方案会同步为空的字段对象 */
  const defaultEmptyCaseObj = {
    repairCaseId: undefined,
    scanCodeFlag: undefined,
    standardWorkTimeH: undefined,
    standardWorkTimeM: undefined,
    standardWorkTimeS: undefined,
    channelType: undefined,
    planExecutorList: undefined,
    executeType: undefined,
    executeIds: undefined,
    malfunctionClassificationId: undefined,
    malfunctionLevel: undefined,
    malfunctionReason: undefined,
    malfunctionDetail: undefined,
    malfunctionLabelList: undefined,
    fileIds: undefined,
  };

  /**
   * 创建时，需要根据列表页所选资源初始化维修目标
   */
  const initailBiz = () => {
    // 创建时
    const isCreate = type === appEnum.Common.CRUD.create;

    // 所选为资源类型
    const isEquipment = listCacheData?.target?.type === 'Equipment';

    const targetId = listCacheData?.target?.id;
    const businessType = listCacheData?.businessType;

    if (isCreate && isEquipment && targetId) {
      fetchResourcesList({ idList: [_.toNumber(targetId)], type: businessType }).then((res) => {
        const bizInfo = _.head(res?.data?.list);

        if (!_.isNil(bizInfo)) {
          const bizId = {
            label: bizInfo.name,
            value: JSON.stringify(
              _.pick(bizInfo, ['id', 'level', 'hasChildren', 'code', 'locationName']),
            ),
          };

          form.setFieldsValue({ bizId });
        }
      });
    }

    // if (isCreate) {
    //   form.setFieldsValue({ bizType: businessType });
    // }
  };

  const validatorWorkTime = () => {
    const { standardWorkTimeH, standardWorkTimeM, standardWorkTimeS } = form.getFieldsValue();

    // 存在一个或多个0且总和是0的时候
    if (
      (standardWorkTimeM ?? 0) + (standardWorkTimeH ?? 0) + (standardWorkTimeS ?? 0) === 0 &&
      (standardWorkTimeM === 0 || standardWorkTimeH === 0 || standardWorkTimeS === 0)
    ) {
      return Promise.reject('标准工时总时长不能为0');
    }
    return Promise.resolve('');
  };

  const validatePlanTime = () => {
    const { planStartTime, planEndTime } = form.getFieldsValue();

    if (!_.isNil(planStartTime) && !_.isNil(planEndTime) && planStartTime.isAfter(planEndTime)) {
      return Promise.reject('计划开始时间必须早于计划结束时间');
    }

    return Promise.resolve('');
  };

  /**
   * 自动设置 计划报废时间
   */
  const setPlanEndTime = () => {
    setTimeout(() => {
      const {
        planStartTime,
        standardWorkTimeH,
        standardWorkTimeM,
        standardWorkTimeS,
        planEndTime,
      } = form.getFieldsValue();

      const totalTime = setStandardWorkTime(
        standardWorkTimeH,
        standardWorkTimeM,
        standardWorkTimeS,
      );

      if (_.isNil(planEndTime) && !_.isNil(planStartTime) && totalTime) {
        const realPlanEndTime = moment(planStartTime).add(totalTime, 's');

        form.setFieldsValue({
          planEndTime: realPlanEndTime,
          standardWorkTimeH: standardWorkTimeH ?? 0,
          standardWorkTimeM: standardWorkTimeM ?? 0,
          standardWorkTimeS: standardWorkTimeS ?? 0,
        });
      }
    });
  };

  /**
   * 维修业务对象 change
   * 维修业务对象变动会重置维修方案，进而影响一堆维修方案相关的项
   * @param value
   */
  const handleBizTypeChange = () => {
    form.setFieldsValue({
      bizId: undefined,
      repairClassificationId: undefined,
      ...defaultEmptyCaseObj,
    });
  };

  /**
   * 维修方案 change
   * @param value
   */
  const handleRepairCaseChange = (value?: { value: number; label: string }) => {
    if (_.isNil(value)) {
      form.setFieldsValue(defaultEmptyCaseObj);
      return;
    }

    const repairCaseId = _.get(value, 'value');

    getRepairCaseForTask(repairCaseId)
      .then((caseData) => {
        // 没有拿到维修方案详细信息时，判定为错误数据，置空维修方案
        if (_.isEmpty(caseData)) {
          form.setFieldsValue(defaultEmptyCaseObj);
        } else {
          form.setFieldsValue(caseData);
        }
      })
      .catch(() => {
        form.setFieldsValue(defaultEmptyCaseObj);
      });
  };

  const getBaseInfo = (): DataFormLayoutInfoBlock => {
    const baseInfo = {
      title: '基本信息',
      column: 2,
      items: [
        {
          label: '',
          name: 'id',
          span: 2,
          hidden: true,
          render: () => <Input />,
        },
        ...NumberRulesStandardHook({
          label: fieldLabels.code,
          form,
          edit: type === 'edit',
          objectCode: OBJECT_OF_CODE.RepairTask,
          fieldCode: 'code',
          refreshMarker,
          rules: [
            { max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` },
            numberAlphabetSpecialSymbols,
          ],
        }),
        {
          label: fieldLabels.name,
          name: 'name',
          rules: [
            { required: true, message: `请输入${fieldLabels.name}` },
            { max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` },
            { validator: checkTwoSidesTrim },
          ],
          render: () => <Input placeholder={`请输入${fieldLabels.name}`} />,
        },
        {
          label: fieldLabels.identificationCode,
          name: 'identificationCode',
          rules: [{ max: MAX_INPUT_LENGTH, message: `不超过${MAX_INPUT_LENGTH}字符` }],
          render: () => <Input placeholder="请输入标识码" />,
        },
        {
          label: fieldLabels.businessObject,
          name: 'bizType',
          initialValue: listCacheData?.businessType,
          rules: [{ required: true, message: `请选择${fieldLabels.businessObject}` }],
          render: () => (
            <Select
              placeholder={`请选择${fieldLabels.businessObject}`}
              options={appDict.resources.RESORCE_BUSINESS_TYPE_MAP}
              onChange={handleBizTypeChange}
            />
          ),
        },
        {
          label: fieldLabels.repairTarget,
          dependencies: ['bizType'],
          required: true,
          render: () => () => {
            const bizType = form.getFieldValue('bizType');

            return (
              <Form.Item
                name="bizId"
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: `请选择${fieldLabels.repairTarget}` }]}
              >
                <SearchSelect
                  fetchType="resourceCompleteInfo"
                  params={{
                    type: bizType,
                    statusList: [
                      appEnum.Common.UsageStatus.enabled,
                      appEnum.Common.UsageStatus.disabled,
                    ],
                  }}
                  placeholder="请输入"
                />
              </Form.Item>
            );
          },
        },
        {
          label: fieldLabels.repairTargetCode,
          shouldUpdate: true,
          render: () => () => {
            const { value = '{}' } = form.getFieldValue('bizId') ?? {};

            const { code } = JSON.parse(value);

            return <Input placeholder="请选择维修目标" disabled value={code} />;
          },
        },
        {
          label: fieldLabels.repairTargetArea,
          shouldUpdate: true,
          render: () => () => {
            const { value = '{}' } = form.getFieldValue('bizId') ?? {};

            const { locationName } = JSON.parse(value);

            return <Input placeholder="请选择维修目标" disabled value={locationName} />;
          },
        },
        {
          label: fieldLabels.repairPlan,
          dependencies: [['bizType'], ['bizId'], ['malfunctionLabelList']],
          shouldUpdate: () => true, //
          required: true,
          render: () => () => {
            const bizType = form.getFieldValue('bizType') ?? listCacheData?.businessType;
            const bizIdLabeledValue = form.getFieldValue('bizId');
            const bizId = bizIdLabeledValue ? JSON.parse(bizIdLabeledValue?.value)?.id : undefined;
            const malfunctionLabelList = form.getFieldValue('malfunctionLabelList');
            const malfunctionLabelIds =
              malfunctionLabelList?.length > 0 ? _.map(malfunctionLabelList, 'tagsId') : undefined;

            return (
              <Form.Item
                name="repairCaseId"
                rules={[{ required: true, message: '请选择维修方案' }]}
                style={{ marginBottom: 0 }}
              >
                <SearchSelect
                  fetchType={'repairCaseForTask'}
                  placeholder="请选择维修方案"
                  params={{
                    statusList: [appEnum.Common.UsageStatus.enabled],
                    bizType,
                    bizId,
                    labelValueIdList: malfunctionLabelIds,
                  }}
                  onChange={handleRepairCaseChange}
                />
              </Form.Item>
            );
          },
        },

        {
          label: fieldLabels.channelType,
          name: 'channelType',
          initialValue: appEnum.Resources.RepairChannel.autonomy,
          render: () => (
            <Select
              placeholder="请选择维修途径"
              options={appDict.resources.repairChannel}
              allowClear
            />
          ),
        },
        {
          label: fieldLabels.repairRequestor,
          name: 'reporterList',
          initialValue: [currentUser],
          rules: [
            {
              type: 'array' as 'array',
              max: 10,
              message: `${fieldLabels.repairRequestor}最多不超过10个`,
            },
          ],
          render: () => (
            <UserOrDepartmentSelectWithDialog isMultiple isSelectUser userActive={1} isNewFormat />
          ),
        },
        {
          label: fieldLabels.classification,
          dependencies: ['bizType'],
          render: () => () => {
            const bizType = form.getFieldValue('bizType');

            return (
              <Form.Item key={bizType} name="repairClassificationId" style={{ marginBottom: 0 }}>
                <ResourceClassificationCascader
                  // resFetchData={resFetchData}
                  params={{
                    businessType: appEnum.Resources.ResourceBusinessType.repair,
                    status: appEnum.Common.UsageStatus.enabled,
                    cascade: true,
                  }}
                  showSearch
                  placeholder={fieldLabels.classification}
                  onCreateClick={() => {}}
                />
              </Form.Item>
            );
          },
        },
        {
          label: fieldLabels.scanCodeFlag,
          name: 'scanCodeFlag',
          initialValue: appEnum.Common.YNB.no,
          render: () => <Radio.Group options={appDict.common.ynb} />,
        },
        {
          label: fieldLabels.standardWorkTime,
          render: () => (
            <div>
              <Space>
                <Form.Item
                  name={'standardWorkTimeH'}
                  noStyle
                  rules={[{ validator: validatorWorkTime }]}
                >
                  <InputNumber max={999} min={0} precision={0} />
                </Form.Item>
                <Button type="text" size="small">
                  小时
                </Button>
                <Form.Item name={'standardWorkTimeM'} noStyle>
                  <InputNumber
                    max={59}
                    min={0}
                    precision={0}
                    onChange={() => {
                      form.validateFields(['standardWorkTimeH']);
                    }}
                  />
                </Form.Item>
                <Button type="text" size="small">
                  分钟
                </Button>
                <Form.Item name={'standardWorkTimeS'} noStyle>
                  <InputNumber
                    max={59}
                    min={0}
                    precision={0}
                    onChange={() => {
                      form.validateFields(['standardWorkTimeH']);
                    }}
                  />
                </Form.Item>
                <Button type="text" size="small">
                  秒
                </Button>
              </Space>
            </div>
          ),
        },
        {
          label: fieldLabels.planStartTime,
          dependencies: ['planEndTime'],
          required: true,
          render: () => () => {
            const planEndTime = form.getFieldValue('planEndTime');

            return (
              <Form.Item
                name="planStartTime"
                dependencies={['planEndTime']}
                style={{ marginBottom: 0 }}
                rules={[
                  { required: true, message: `请选择${fieldLabels.planStartTime}` },
                  {
                    validator: validatePlanTime,
                  },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime
                  style={{ width: '100%' }}
                  disabledDate={(date) => planEndTime && date.isAfter(planEndTime)}
                />
              </Form.Item>
            );
          },
        },
        {
          label: fieldLabels.planEndTime,
          dependencies: [
            ['planStartTime'],
            ['standardWorkTimeH'],
            ['standardWorkTimeM'],
            ['standardWorkTimeS'],
          ],
          required: true,
          render: () => () => {
            setPlanEndTime();

            const planStartTime = form.getFieldValue('planStartTime');

            return (
              <Form.Item
                name="planEndTime"
                dependencies={[
                  ['planStartTime'],
                  ['standardWorkTimeH'],
                  ['standardWorkTimeM'],
                  ['standardWorkTimeS'],
                ]}
                style={{ marginBottom: 0 }}
                rules={[
                  { required: true, message: `请选择${fieldLabels.planEndTime}` },
                  {
                    validator: validatePlanTime,
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) => {
                    return planStartTime && current && moment(current).isBefore(planStartTime);
                  }}
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime
                  style={{ width: '100%' }}
                />
              </Form.Item>
            );
          },
        },
        {
          label: fieldLabels.planExecutorList,
          name: 'planExecutorList',
          rules: [
            { required: true, message: '请选择计划执行人' },
            { type: 'array' as 'array', max: MAX_USER_COUNT, message: '计划执行人最多不超过20个' },
          ],
          render: () => <UserDeptRoleSelect isMultiple />,
        },
        {
          label: fieldLabels.currentExecutorList,
          name: 'currentExecutorList',
          rules: [
            { required: true, message: '请选择当前执行人' },
            { type: 'array' as 'array', max: MAX_USER_COUNT, message: '当前执行人最多不超过20个' },
          ],

          render: () => <UserOrDepartmentSelectWithDialog isMultiple isSelectUser userActive={1} />,
        },
        {
          label: fieldLabels.executeType,
          name: 'executeType',
          rules: [{ required: true, message: '请选择维修方案' }],
          render: () => (
            <Select
              disabled
              placeholder="请选择维修方案"
              options={appDict.resources.repairExecuteType}
            />
          ),
        },
        {
          noStyle: true,
          shouldUpdate: true,
          render: (formItemConfig: JSX.IntrinsicAttributes & FormItemProps<any>) => () => {
            const executeType = form.getFieldValue('executeType');

            if (executeType === appEnum.Resources.MaintenanceExecuteType.reportTemplate) {
              return (
                <Form.Item
                  {...formItemConfig}
                  label="报告模板"
                  name={'executeIds'}
                  rules={[{ required: true, message: '请检查维修方案' }]}
                >
                  <SearchSelect
                    disabled
                    fetchType="reportTemplate"
                    placeholder="请选择维修方案"
                    mode="multiple"
                    params={{ category: [appEnum.ReportTemplate.TemplateType.repairReport] }}
                  />
                </Form.Item>
              );
            }

            if (executeType === appEnum.Resources.MaintenanceExecuteType.SOP) {
              return (
                <Form.Item
                  {...formItemConfig}
                  label="SOP方案"
                  name={'executeIds'}
                  rules={[{ required: true, message: '请选择SOP方案' }]}
                >
                  <SearchSelect
                    disabled
                    fetchType="SOP"
                    placeholder="请选择SOP方案"
                    mode="multiple"
                    params={{
                      status: appEnum.Common.UsageStatus.enabled,
                      bizType: appEnum.Sop.BizType.repair,
                    }}
                  />
                </Form.Item>
              );
            }

            return null;
          },
        },
        {
          label: fieldLabels.attachment,
          name: 'fileIds',
          isFullLine: true,
          render: () => <BcAttachmentForm form={form} />,
        },
        {
          label: fieldLabels.remark,
          name: 'remark',
          isFullLine: true,
          rules: [{ max: MAX_TEXTAREA_LENGTH, message: `不超过${MAX_TEXTAREA_LENGTH}字符` }],
          render: () => <TextArea placeholder="请输入备注" />,
        },
        {
          label: fieldLabels.status,
          name: 'status',
          span: 2,
          hidden: true,
          render: () => <Input />,
        },
      ],
    };

    if (type === appEnum.Common.CRUD.edit) {
      baseInfo.items.push({
        label: fieldLabels.editReason,
        name: 'reason',
        isFullLine: true,
        rules: [{ max: MAX_TEXTAREA_LENGTH, message: `不超过${MAX_TEXTAREA_LENGTH}字符` }],
        render: () => <TextArea placeholder="请输入编辑原因" />,
      });
    }

    return baseInfo;
  };

  const malfunctionInfo: DataFormLayoutInfoBlock = {
    title: '故障信息',
    column: 2,
    items: [
      {
        label: fieldLabels.malfunctonClassification,
        name: 'malfunctionClassificationId',
        render: () => (
          <ResourceClassificationCascader
            onChange={() => {}}
            showSearch
            multiple
            params={{ businessType: ResourceBusinessType.malfunction, cascade: true }}
            placeholder={'请选择故障分类'}
            enableAddLabel={'新建分类'}
            onCreateClick={() => {}}
            // enableAdd={hasAuth()}
          />
        ),
      },
      {
        label: fieldLabels.malfunctionLevel,
        name: 'malfunctionLevel',
        render: () => (
          <Select
            placeholder={`请选择${fieldLabels.malfunctionLevel}`}
            options={appDict.resources.malfunctionLevel}
            allowClear
          />
        ),
      },
      {
        label: fieldLabels.malfunctionReason,
        name: 'malfunctionReason',
        render: () => <Input placeholder={`请输入${fieldLabels.malfunctionReason}`} />,
      },
      {
        label: fieldLabels.malfunctionDetail,
        name: 'malfunctionDetail',
        rules: [{ max: MAX_TEXTAREA_LENGTH, message: `不超过${MAX_TEXTAREA_LENGTH}字符` }],
        render: () => <Input.TextArea placeholder={`请输入${fieldLabels.malfunctionDetail}名称`} />,
      },
    ],
  };

  const malfunctionTagInfo: DataFormLayoutInfoBlock = {
    title: fieldLabels.malfunctionTags,
    column: 2,
    items: [
      {
        name: 'malfunctionLabelList',
        isFullLine: true,
        render: () => <MalfunctionTagFormList form={form} formFieldName="malfunctionLabelList" />,
      },
    ],
  };

  return (
    <DataFormLayout
      info={[getBaseInfo(), malfunctionInfo, malfunctionTagInfo]}
      form={form}
      footer={false}
    />
  );
};

export default ActionContent;
