import React from 'react';
import styles from './styles.module.scss';

export default function PrivacyAgreement() {
  return (
    <iframe
      src="https://web-v3.s3.cn-northwest-1.amazonaws.com.cn/3.0privacy/privacy.html"
      className={styles.userPrivacyIframe}
      seamless
      frameBorder={0}
      marginHeight={0}
      marginWidth={0}
    />
  );
}
