/**
 * @enum 替代方案(alternativePlan)业务模块枚举值
 */

/**
 * 替代策略
 */
export enum SubstitutionStrategyEnum {
  /**
   * 混用替代
   */
  mixedSubstitute = 1,
  /**
   * 整批替代
   */
  fullSubstitute = 2,
}
