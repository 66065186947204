import StoreHouseList from '../warehouse/storehouse/list';
import StoreDetailPage from '../warehouse/storehouse/detailPage';
import StoreEdit from '../warehouse/storehouse/edit';
import CreateStoreHouse from '../warehouse/storehouse/create';
import Positions from '../warehouse/positions';
import PositionDetail from '../warehouse/positions/detail';
import CreatePositions from '../warehouse/positions/create';
import { DetailContent as ResourceDetailContent } from 'src/page/resource/definitions/detail';
import AreaConfigList from 'src/page/resource/areaConfig/list';
import AreaDetailPage from './area/detailPage';
import AreaEditPage from './area/editPage';
import authDict from 'src/utils/auth';

export const BASE_URL = '/warehouseManagement/warehouse';
export const STORAGE_URL = `${BASE_URL}/storehouseList`;
export const POSITION_URL = `${BASE_URL}/positionList`;
export const AREA_URL = `${STORAGE_URL}/area`;

// 仓库

export const toStoreDetail = (id: number) => {
  return `${STORAGE_URL}/${id}/detail`;
};
export const toStoreEdit = (id: number) => {
  return `${STORAGE_URL}/${id}/edit`;
};
export const toStoreCreate = () => {
  return `${STORAGE_URL}/create`;
};

export const toStoreList = () => {
  return `${STORAGE_URL}`;
};

// 仓位

export const toPositionList = () => {
  return `${POSITION_URL}`;
};

export const toPositionDetail = (id: number) => {
  return `${POSITION_URL}/${id}/detail`;
};
export const toPositionEdit = (id: number) => {
  return `${POSITION_URL}/${id}/edit`;
};
export const toPositionCreate = () => {
  return `${POSITION_URL}/create`;
};

export const toOperationLogList = () => {
  return `${BASE_URL}/operationLog`;
};

export const toAreaDetail = (id: number) => {
  return `${AREA_URL}/${id}/detail`;
};
export const toAreaEdit = (id: number) => {
  return `${AREA_URL}/${id}/edit`;
};

export const warehouseRouteConfig = [
  {
    path: '/warehouseManagement/warehouse',
    menu: '仓储主数据',
    breadcrumb: '仓储主数据',
    redirectPath: '/warehouseManagement/warehouse/storehouseList',
    children: [
      {
        path: '/warehouseManagement/warehouse/storehouseList',
        auth: authDict.warehouse_view,
        component: StoreHouseList,
        menu: '仓库',
        breadcrumb: '仓库',
        logPage: true,
        children: [
          {
            path: '/warehouseManagement/warehouse/storehouseList/:id/detail',
            component: StoreDetailPage,
            auth: authDict.warehouse_detail,
            breadcrumb: '仓库详情',
          },
          {
            path: '/warehouseManagement/warehouse/storehouseList/:id/edit',
            auth: authDict.warehouse_edit,
            component: StoreEdit,
            breadcrumb: '仓库编辑',
          },
          {
            path: '/warehouseManagement/warehouse/storehouseList/create',
            auth: authDict.warehouse_add,
            component: CreateStoreHouse,
            breadcrumb: '新建仓库',
          },
          {
            path: '/warehouseManagement/warehouse/storehouseList/area/:id/detail',
            component: AreaDetailPage,
            breadcrumb: '区域详情',
          },
          {
            path: '/warehouseManagement/warehouse/storehouseList/area/:id/edit',
            component: AreaEditPage,
            breadcrumb: '区域编辑',
          },
        ],
      },
      // {
      //   path: '/resource/resourceconfig/area',
      //   component: AreaConfigList,
      //   menu: '仓储区域',
      //   breadcrumb: '仓储区域',
      //   children: [
      //     {
      //       path: '/resource/resourceconfig/area/detail',
      //       component: ResourceDetailContent,
      //       breadcrumb: '区域详情',
      //     },
      //   ],
      // },
      {
        path: '/warehouseManagement/warehouse/positionList',
        auth: authDict.storagelocation_view,
        component: Positions,
        menu: '仓位',
        breadcrumb: '仓位',
        logPage: true,
        children: [
          {
            path: '/warehouseManagement/warehouse/positionList/:id/detail',
            component: PositionDetail,
            auth: authDict.storagelocation_detail,
            breadcrumb: '详情',
          },
          {
            path: '/warehouseManagement/warehouse/positionList/create',
            auth: authDict.storagelocation_add,
            component: CreatePositions,
            breadcrumb: '新建仓位',
          },
        ],
      },
    ],
  },
];
