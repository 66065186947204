import { appEnum } from 'src/dict';
import { message } from 'antd';
import { fetchCustomAqlDisable, fetchCustomAqlEnable } from 'src/api/ytt/quality-domain/aql';
import { isNumber } from 'lodash-es';

export const turnAQL = async (id?: number, state?: number, success?: () => void) => {
  if (!isNumber(id) || !isNumber(state)) {
    return;
  }
  // 启用/停用
  const res = await (state === appEnum.Common.Turn.on
    ? fetchCustomAqlDisable({ id })
    : fetchCustomAqlEnable({ id }));

  if (res.code === 200) {
    message.success('操作成功');
    success?.();
    return;
  }
  message.error(res?.message);
};
