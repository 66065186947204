import _ from 'lodash';
import { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useToggle } from 'react-use';
import { Form, Input, message, Modal, Select, Table, Checkbox, Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import {
  fetchSopDetailForSopTask,
  fetchSopExecTaskCreate,
  fetchSopExecTaskUpdate,
  fetchSopExecTaskDetail,
} from 'src/api/ytt/sop-domain';
import lookup from 'src/dict';
import { BizType, PrivilegeType, TaskStatus } from 'src/dict/sop';
import { UsageStatus } from 'src/dict/common';
import { executiveStaff } from 'src/dict/sop/mappings';
import SearchSelect from 'src/components/searchSelect';
import EditableText from 'src/components/editableText';
import { BlIcon } from 'src/components';
import { validateBlText } from 'src/page/knowledgeManagement/share';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { extractIds } from 'src/utils/formatters';
// import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { textValidator2 } from 'src/utils/formValidators';
import RoleSelect from 'src/page/organization/components/roleSelect';
import type { User } from 'src/page/share';
import { black40 } from 'src/styles/color';
import authDict, { hasAuth } from 'src/utils/auth';
import { StepOverviewData } from '../../scheme/types';
import {
  // renderUser,
  renderUserArray,
  renderDepartmentArray,
  renderRoleArray,
} from 'src/page/share/renderUser';
import { lv2Sop, sop2Lv } from '../share/sopFormat';
import { DISPATCH_WARNING } from '../constants';
import styles from '../styles.module.scss';
interface RouteParams {
  sopTaskId?: string;
}

interface DisplayData {
  creatorId: any;
  operatorId: any;
  status?: TaskStatus;
  planExecUser: User[];
  planExecDept: User[];
  planExecRole: User[];
  createdAt: number;
  updatedAt: number;
}

const defaultValues = {
  alsoDispatch: true,
  bizType: 0,
  code: '',
  name: '',
  canExecUser: [],
  canExecDept: [],
  canExecRole: [],
  remark: '',
  relateId: undefined,
};
const defaultDisplayData: DisplayData = {
  creatorId: undefined,
  operatorId: undefined,
  status: undefined,
  planExecUser: [],
  planExecDept: [],
  planExecRole: [],
  createdAt: Date.now(),
  updatedAt: Date.now(),
};
const defaultSuccessModalProps = {
  visible: false,
  alsoDispatch: false,
  approvalEnabledFlag: false,
  taskId: 0,
  code: '',
  name: '',
  tipText: '',
};
const getSuccessMessage = (approvalEnabledFlag: boolean, alsoDispatch: boolean) =>
  `新建${!approvalEnabledFlag && alsoDispatch ? '并下发' : ''}成功`;
// 执行权限类型与列表字段名的对应关系
const canExecMap: [PrivilegeType, string][] = [
  [PrivilegeType.users, 'canExecUser'],
  [PrivilegeType.departments, 'canExecDept'],
  [PrivilegeType.roles, 'canExecRole'],
];
const getCanExecType = (data: any) => {
  for (const [privType, prop] of canExecMap) {
    if (!_.isEmpty(data[prop])) {
      return privType;
    }
  }
  return PrivilegeType.users;
};

const EditSOPTask = () => {
  const history = useHistory();
  const { sopTaskId } = useParams<RouteParams>();
  const [form] = Form.useForm();
  const isEdit = !_.isUndefined(sopTaskId);
  const [initValues, setInitValues] = useState(defaultValues);
  const [displayData, setDisplayData] = useState(defaultDisplayData);
  const [successiveCreation, setSuccessiveCreation] = useState(false);
  const [overallLoading, setOverallLoading] = useState(false);
  const [execLoading, setExecLoading] = useState(false);
  const [canExecType, setCanExecType] = useState<PrivilegeType>(PrivilegeType.users);
  const [successModalProps, setSuccessModalProps] = useState(defaultSuccessModalProps);
  const [, forceUpdate] = useToggle(false);
  const { relateId } = form.getFieldsValue();

  const formProps = {
    initialValues: initValues,
  };
  const onClearRelatedSop = () => {
    setDisplayData({
      ...displayData,
      ..._.pick(defaultDisplayData, ['planExecUser', 'planExecDept', 'planExecRole']),
    });
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 3,
    title: '基本信息',
    items: _.compact([
      {
        label: 'SOP任务编号',
        name: 'code',
        rules: [
          { required: true, message: '请输入SOP任务编号' },
          { validator: textValidator2 },
          { max: 255, message: '不超过255个字符' },
        ],
        render: () => {
          if (isEdit) {
            return <EditableText canEdit={false} />;
          }
          return <Input max={255} placeholder={'请输入'} />;
        },
      },
      {
        label: 'SOP任务名称',
        name: 'name',
        rules: [
          { required: true, message: '请输入SOP任务名称' },
          { validator: validateBlText() },
          { max: 255, message: '不超过255个字符' },
        ],
        render: () => <Input placeholder={'请输入'} />,
      },
      isEdit
        ? {
            label: 'SOP任务状态',
            render: () => lookup('sop.taskStatus', displayData.status),
          }
        : null,
      {
        label: '关联SOP方案',
        required: true,
        render: () => (
          <div style={{ display: 'flex' }}>
            <Form.Item
              name="relateId"
              rules={[{ required: true, message: '请选择关联SOP方案' }]}
              style={{ width: 'calc(100% - 88px)' }}
              className={styles.controlInputFullWidth}
              getValueProps={(val) => {
                if (val && !_.isEmpty(val)) {
                  return { value: sop2Lv(val) };
                }
                return {};
              }}
              getValueFromEvent={lv2Sop}
            >
              <SearchSelect
                fetchType="SOP"
                params={{ status: UsageStatus.enabled, bizType: BizType.general }}
                onSelect={(val: any) => onSelectRelatedSop(val, displayData)}
                labelInValue
                allowClear
                onClear={onClearRelatedSop}
              />
            </Form.Item>
            <Button
              type="link"
              disabled={!relateId?.id}
              onClick={() => window.open(`/sop/processEngine/scheme/${relateId?.id}/detail`)}
            >
              查看详情
            </Button>
          </div>
        ),
      },
      {
        label: (
          <div className={styles.labelBottom}>
            方案已添加的可执行范围
            <br />
            <span className={styles.noticeUnderLabel}>
              <BlIcon type="icontixing-jingshi" />
              当前可执行范围仅支持执行SOP方案已配置的可执行步骤。
            </span>
          </div>
        ),
        isFullLine: true,
        render: () => (
          <Table
            loading={execLoading}
            pagination={false}
            columns={[
              {
                title: '序号',
                key: 'index',
                width: 70,
                render: (_, __, index) => index + 1,
              },
              {
                title: '用户/部门/角色',
                key: 'authType',
                dataIndex: 'authType',
                width: 180,
                render: lookup('sop.taskPrivilegeType'),
              },
              {
                title: '计划执行人',
                dataIndex: 'planExec',
                render: (_, record) => {
                  switch (record.authType) {
                    case PrivilegeType.users:
                      return renderUserArray(displayData.planExecUser, 0);
                    case PrivilegeType.departments:
                      return renderDepartmentArray(displayData.planExecDept, 0);
                    case PrivilegeType.roles:
                    default:
                      return renderRoleArray(displayData.planExecRole, 0);
                  }
                },
              },
            ]}
            dataSource={executiveStaff.map((item) => ({ authType: item.value as number }))}
          />
        ),
      },
      {
        label: (
          <div className={styles.labelBottom}>
            任务可执行范围
            <br />
            <span className={styles.noticeUnderLabel}>
              <BlIcon type="icontixing-jingshi" />
              任务可执行范围支持执行任务的所有步骤。
            </span>
          </div>
        ),
        render: () => {
          let name;
          let content;
          let max;

          switch (canExecType) {
            case PrivilegeType.users:
              name = 'canExecUser';
              content = <UserOrDepartmentSelectWithDialog isNewFormat isMultiple isSelectUser />;
              max = 100;
              break;
            case PrivilegeType.departments:
              name = 'canExecDept';
              content = <UserOrDepartmentSelectWithDialog isNewFormat isMultiple />;
              max = 50;
              break;
            case PrivilegeType.roles:
            default:
              name = 'canExecRole';
              content = <RoleSelect isMultiple allowClear />;
              max = 50;
              break;
          }
          return (
            <>
              <Select
                value={canExecType}
                onChange={setCanExecType}
                options={executiveStaff}
                style={{ marginBottom: 10 }}
              />
              <Form.Item
                noStyle
                name={name}
                rules={[
                  {
                    type: 'array',
                    max,
                    message: `最多选择${max}个${lookup('sop.privilegeType', canExecType)}`,
                  },
                ]}
              >
                {content}
              </Form.Item>
            </>
          );
        },
      },
      {
        label: '备注',
        name: 'remark',
        rules: [{ max: 1000, message: '不超过1000个字符' }],
        render: () => <Input.TextArea showCount maxLength={1000} />,
      },
      hasAuth(authDict.soptask_dispatch) &&
        !isEdit && {
          label: ' ',
          colon: false,
          isFullLine: true,
          name: 'alsoDispatch',
          valuePropName: 'checked',
          render: () => <Checkbox>同时下发</Checkbox>,
        },
      // isEdit && {
      //   label: '创建人',
      //   render: () => renderUser(displayData.creatorId),
      // },
      // isEdit && {
      //   label: '创建时间',
      //   render: () => formatTimeForRender(displayData.createdAt),
      // },
      // isEdit && {
      //   label: '更新人',
      //   render: () => renderUser(displayData.operatorId),
      // },
      // isEdit && {
      //   label: '更新时间',
      //   render: () => formatTimeForRender(displayData.updatedAt),
      // },
    ]),
  };

  // 选择关联SOP方案时
  const onSelectRelatedSop = async (selected: any, displayData: any) => {
    setExecLoading(true);
    const res = await fetchSopDetailForSopTask({ sopId: selected.key });
    const bizType = res?.data?.bizType;
    const steps = res?.data?.steps ?? [];
    let toBeTravelled = steps.slice(0);
    let planExecUser: any[] = [];
    let planExecDept: any[] = [];
    let planExecRole: any[] = [];

    while (!_.isEmpty(toBeTravelled)) {
      const { privilegeType, authUsers, authDepartments, authRoles, children } =
        toBeTravelled.shift() as StepOverviewData;

      if (privilegeType === PrivilegeType.users && authUsers) {
        planExecUser = planExecUser.concat(authUsers);
      }
      if (privilegeType === PrivilegeType.departments && authDepartments) {
        planExecDept = planExecDept.concat(authDepartments);
      }
      if (privilegeType === PrivilegeType.roles && authRoles) {
        planExecRole = planExecRole.concat(authRoles);
      }

      if (!_.isEmpty(children)) {
        toBeTravelled = toBeTravelled.concat(children as typeof steps);
      }
    }

    form.setFieldsValue({
      relateId: lv2Sop(selected),
      bizType,
    });
    setDisplayData({
      ...displayData,
      planExecUser: _.uniqBy(planExecUser, 'id'),
      planExecDept: _.uniqBy(planExecDept, 'id'),
      planExecRole: _.uniqBy(planExecRole, 'id'),
    });
    _.delay(forceUpdate, 10);
    setExecLoading(false);
  };

  const goToList = useCallback(() => {
    history.push('/sop/exec/task');
  }, []);

  const createFn = async (values: any) => {
    const { alsoDispatch } = values;

    const confirmPassed = await new Promise((resolve) => {
      if (!alsoDispatch) {
        resolve(true);
        return;
      }
      if (
        _.isEmpty(form.getFieldValue('canExecUser')) &&
        _.isEmpty(form.getFieldValue('canExecDept')) &&
        _.isEmpty(form.getFieldValue('canExecRole'))
      ) {
        Modal.confirm({
          title: '提示',
          content: DISPATCH_WARNING,
          onOk: () => resolve(true),
          onCancel: () => resolve(false),
        });
      } else {
        resolve(true);
      }
    });

    if (confirmPassed) {
      try {
        const res = await fetchSopExecTaskCreate(values);

        const approvalEnabledFlag = res?.data?.approvalEnabledFlag;

        if (approvalEnabledFlag) {
          setSuccessModalProps({
            visible: true,
            alsoDispatch,
            approvalEnabledFlag,
            taskId: res.data?.sopTaskId!,
            code: values.code,
            name: values.name,
            tipText:
              '由于SOP任务启用了审批流程，需要审批通过后才能执行其他内容；您可在SOP任务详情内查看审批流程进度',
          });
        } else if (successiveCreation) {
          message.success(getSuccessMessage(!!approvalEnabledFlag, alsoDispatch));
          form.setFieldsValue(_.omit(defaultValues, 'alsoDispatch'));
          setDisplayData(defaultDisplayData);
        } else {
          setSuccessModalProps({
            visible: true,
            alsoDispatch,
            approvalEnabledFlag: !!approvalEnabledFlag,
            taskId: res.data?.sopTaskId!,
            code: values.code,
            name: values.name,
            tipText: !alsoDispatch
              ? ''
              : '提示：可执行人将会接到任务通知，并在任务列表中查看此任务',
          });
        }
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  const editFn = async (values: any) => {
    try {
      await fetchSopExecTaskUpdate(values);
      message.success('编辑成功');
      goToList();
    } catch (err) {
      console.log(err);
    }
  };

  const clearUnusedCanList = (submitValue: any) => {
    const obj = Object.assign({}, submitValue);

    if (canExecType === PrivilegeType.users || canExecType === PrivilegeType.departments) {
      obj.canExecRole = [];
    }
    if (canExecType === PrivilegeType.users || canExecType === PrivilegeType.roles) {
      obj.canExecDept = [];
    }
    if (canExecType === PrivilegeType.departments || canExecType === PrivilegeType.roles) {
      obj.canExecUser = [];
    }
    return obj;
  };

  const handleFinish = useCallback(async () => {
    await form?.validateFields();
    const values = form.getFieldsValue(true);
    const submitValue = extractIds(
      ['canExecUser', 'canExecDept', 'canExecRole'],
      clearUnusedCanList(values),
    );
    const fn = isEdit ? editFn : createFn;

    if (!isEdit && !hasAuth(authDict.soptask_dispatch)) {
      submitValue.alsoDispatch = false;
    }
    await fn(submitValue);
  }, [successiveCreation, canExecType]);

  useEffect(() => {
    if (isEdit) {
      setOverallLoading(true);
      fetchSopExecTaskDetail({ sopTaskId: _.toNumber(sopTaskId) }, { legacy: true })
        .then((res) => {
          const displayKeys = _.keys(defaultDisplayData);

          setInitValues(_.omit(res.data, displayKeys) as any);
          form.resetFields();
          const nextDisplayData = _.pick(res.data, displayKeys) as any;
          const relateId = {
            ...res.data?.relateId,
            name: `${res.data?.relateId?.code}/${res.data?.relateId?.name}`,
          };

          setDisplayData(nextDisplayData);
          onSelectRelatedSop(sop2Lv(relateId as any), nextDisplayData);
          setCanExecType(getCanExecType(res.data));
        })
        .finally(() => setOverallLoading(false));
    }
  }, [isEdit]);

  return (
    <>
      <DataFormLayout
        loading={overallLoading}
        form={form}
        formProps={formProps}
        info={[baseInfo]}
        onCancel={goToList}
        onFinish={handleFinish}
        extra={
          isEdit ? null : (
            <Checkbox
              checked={successiveCreation}
              onChange={(e) => setSuccessiveCreation(e.target.checked)}
            >
              连续新建
            </Checkbox>
          )
        }
      />
      <Modal
        title={
          <span>
            <CheckCircleOutlined style={{ color: '#52C41A', marginRight: 10 }} />
            {getSuccessMessage(
              successModalProps.approvalEnabledFlag,
              successModalProps.alsoDispatch,
            )}
          </span>
        }
        okText="查看任务详情"
        onOk={() => {
          history.push(`/sop/exec/task/${successModalProps.taskId}/detail`);
        }}
        cancelText="返回任务列表"
        onCancel={goToList}
        visible={successModalProps.visible}
        closable={false}
      >
        <>
          {/* {successModalProps.alsoDispatch && (
            <p style={{ color: black40 }}>
              {successModalProps.tipText}
            </p>
          )} */}
          <p style={{ color: black40 }}>
            {/* 提示：可执行人将会接到任务通知，并在任务列表中查看此任务 */}
            {successModalProps.tipText}
          </p>
          <p>
            SOP任务编号：{successModalProps.code}
            <br />
            SOP任务名称：{successModalProps.name}
          </p>
        </>
      </Modal>
    </>
  );
};

export default EditSOPTask;
