/**
 *  @control 数字
 */
import { FC } from 'react';
import { InputNumber } from 'antd';
import type { PreviewCompProps } from '../../types';

export const Numeric: FC<PreviewCompProps> = ({ inputPrompt }) => {
  return (
    <InputNumber
      className="control-numeric"
      style={{ width: '100%', pointerEvents: 'none' }}
      tabIndex={-1}
      placeholder={inputPrompt}
    />
  );
};
