import { LoadingOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Menu, Spin } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  fetchLayoutDetailByObjectCode,
  FetchLayoutDetailByObjectCodeRequest,
} from 'src/api/ytt/layout-domain';
import {
  fetchBizTypeListWithoutPage,
  FetchBizTypeListWithoutPageRequest,
} from 'src/api/ytt/metadata-domain/businessType';
import { PageType } from 'src/dict/customLayout';
import { v4 as uuidv4 } from 'uuid';

interface LayoutChooseButton {
  objectCode: string;
  title: string | React.ReactNode;
  instanceId?: number;
  type?: 'create' | 'edit' | 'detail';
  icon?: React.ReactNode;
  buttonType?: 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed';
  defaultBizType?: number;
  onClick?: (id: number, bizType?: number) => void;
}

interface MenuItemData {
  label?: string;
  key?: number | string;
  children?: { key?: number; label?: string }[];
}

export const customLayoutChooseButton = ({
  title,
  objectCode,
  instanceId,
  type,
  icon,
  onClick,
  buttonType,
}: LayoutChooseButton) => {
  const [layoutItems, setLayoutItems] = useState<MenuItemData[]>([]);
  const [isloading, setIsloading] = useState<boolean>(false);

  const fecthLayoutList = () => {
    setIsloading(true);
    const params: FetchLayoutDetailByObjectCodeRequest = { objectCode };
    if (type === 'edit') {
      params.type = PageType.edit;
      params.instanceId = instanceId;
    }
    if (type === 'detail') {
      params.type = PageType.detail;
      params.instanceId = instanceId;
    }

    fetchLayoutDetailByObjectCode(params).then(({ data }) => {
      setLayoutItems((data || [])?.map(({ id, name }) => ({ key: id, label: name })));
      setIsloading(false);
    });
  };

  const layoutChooseMenu = ({
    layoutItems,
    menuTitle,
    chooseFunc,
    icon,
  }: {
    layoutItems?: MenuItemData[];
    menuTitle: string | React.ReactNode;
    chooseFunc?: (id: number) => void;
    icon?: null | React.ReactNode;
  }) => {
    const menu =
      layoutItems && layoutItems?.length > 1 ? (
        <Menu onClick={({ key }) => chooseFunc?.(Number(key))}>
          {(layoutItems || [])?.map(({ key, label }) => (
            <Menu.Item key={key}>{label}</Menu.Item>
          ))}
        </Menu>
      ) : (
        <></>
      );

    return (
      <Dropdown key={`menu-${uuidv4()}`} overlay={menu}>
        <Button
          onMouseOver={() => {
            if (!layoutItems?.length) {
              fecthLayoutList();
            }
          }}
          onClick={() => {
            if (layoutItems?.length === 1) {
              onClick?.(Number(_.first(layoutItems)?.key));
            }
          }}
          type={buttonType || 'link'}
          key={`submenu-${uuidv4()}`}
        >
          {icon}
          {/* {isloading && <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} />} */}
          {menuTitle}
        </Button>
      </Dropdown>
    );
  };

  return layoutChooseMenu({
    layoutItems,
    menuTitle: title,
    icon,
    chooseFunc: onClick,
  });
};

export const customLayoutCreateButton = ({
  title,
  objectCode,
  type,
  icon,
  defaultBizType,
  buttonType,
  onClick,
}: LayoutChooseButton) => {
  const [bizTypeList, setBizTypeList] = useState<MenuItemData[]>([]);
  const [layoutItems, setLayoutItems] = useState<MenuItemData[]>([]);
  const [isloading, setIsloading] = useState<boolean>(false);

  const fecthLayoutList = async (_params = {}) => {
    const params: FetchLayoutDetailByObjectCodeRequest = { objectCode, ..._params };
    if (type === 'edit') {
      params.type = PageType.edit;
    }
    if (type === 'create') {
      params.type = PageType.edit;
    }

    const { data } = await fetchLayoutDetailByObjectCode(params);

    setLayoutItems((data || [])?.map(({ id, name }) => ({ key: id, label: name })));
    setIsloading(false);

    return { data };
  };

  const loadData = async ({ code, name }: { code?: string; name?: string }) => {
    const { data } = await fecthLayoutList({ bizType: code });

    return {
      key: code,
      label: name,
      children: (data || [])?.map(({ id, name }) => ({ key: id, label: name })),
    };
  };

  const fecthBizTypeList = () => {
    setIsloading(true);
    const params: FetchBizTypeListWithoutPageRequest = { objectCode, sonObjectCode: '' };

    fetchBizTypeListWithoutPage(params).then(({ data }) => {
      if (data?.length) {
        Promise.all(data?.map((item) => loadData(item))).then((data) => {
          setBizTypeList(data);
          setIsloading(false);
        });
      } else {
        fecthLayoutList();
      }
    });
  };

  const loadingContainer = (
    <div style={{ width: '100%', padding: 20, textAlign: 'center', background: '#ffffff' }}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} tip="加载中..." />
    </div>
  );

  const fetchData = () => {
    if (defaultBizType) {
      fecthLayoutList({ bizType: defaultBizType });
    } else {
      fecthBizTypeList();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const layoutChooseMenu = ({
    items,
    menuTitle,
    chooseFunc,
    icon,
  }: {
    items?: MenuItemData[];
    menuTitle: string | React.ReactNode;
    chooseFunc?: (id: number, bizType?: number) => void;
    icon?: null | React.ReactNode;
  }) => {
    const menu =
      (items || [])?.length > 1 ? (
        <Menu>
          {(items || [])?.map(({ key, label, children }) => (
            <>
              {children?.length ? (
                <Menu.SubMenu title={label} key={key}>
                  {children?.map((i) => (
                    <Menu.Item key={i.key} onClick={() => chooseFunc?.(Number(i.key), Number(key))}>
                      {i.label}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ) : (
                <Menu.Item
                  key={key}
                  onClick={({ key }) => chooseFunc?.(Number(key), defaultBizType)}
                >
                  {label}
                </Menu.Item>
              )}
            </>
          ))}
        </Menu>
      ) : (
        <></>
      );

    return (
      <Dropdown overlay={isloading ? loadingContainer : menu} key={`mainmenu-${uuidv4()}`}>
        <Button
          key={`button-${uuidv4()}`}
          type={buttonType || 'primary'}
          onClick={() => {
            if (layoutItems?.length === 1) {
              onClick?.(Number(_.first(layoutItems)?.key), defaultBizType);
            }
          }}
        >
          {icon && <span style={{ marginRight: 10 }}>{icon}</span>}
          {menuTitle}
        </Button>
      </Dropdown>
    );
  };

  return layoutChooseMenu({
    items: bizTypeList?.length ? bizTypeList : layoutItems,
    menuTitle: title,
    icon,
    chooseFunc: onClick,
  });
};

export const EditButton = ({
  title,
  objectCode,
  instanceId,
  type,
  icon,
  onClick,
  buttonType,
}: LayoutChooseButton) => {
  const [layoutItems, setLayoutItems] = useState<MenuItemData[]>([]);
  const [isloading, setIsloading] = useState<boolean>(false);

  const fecthLayoutList = () => {
    setIsloading(true);
    const params: FetchLayoutDetailByObjectCodeRequest = { objectCode };
    if (type === 'edit') {
      params.type = PageType.edit;
      params.instanceId = instanceId;
    }
    if (type === 'detail') {
      params.type = PageType.detail;
      params.instanceId = instanceId;
    }

    fetchLayoutDetailByObjectCode(params).then(({ data }) => {
      setLayoutItems((data || [])?.map(({ id, name }) => ({ key: id, label: name })));
      setIsloading(false);
    });
  };

  const layoutChooseMenu = ({
    layoutItems,
    menuTitle,
    chooseFunc,
    icon,
  }: {
    layoutItems?: MenuItemData[];
    menuTitle: string | React.ReactNode;
    chooseFunc?: (id: number) => void;
    icon?: null | React.ReactNode;
  }) => {
    const menu =
      layoutItems && layoutItems?.length > 1 ? (
        <Menu onClick={({ key }) => chooseFunc?.(Number(key))}>
          {(layoutItems || [])?.map(({ key, label }) => (
            <Menu.Item key={key}>{label}</Menu.Item>
          ))}
        </Menu>
      ) : (
        <></>
      );

    return (
      <Dropdown key={`menu-${uuidv4()}`} overlay={menu}>
        <Button
          onMouseOver={() => {
            if (!layoutItems?.length) {
              fecthLayoutList();
            }
          }}
          onClick={() => {
            if (layoutItems?.length === 1) {
              onClick?.(Number(_.first(layoutItems)?.key));
            }
          }}
          type={buttonType || 'link'}
          key={`submenu-${uuidv4()}`}
        >
          {icon}
          {/* {isloading && <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} />} */}
          {menuTitle}
        </Button>
      </Dropdown>
    );
  };

  return layoutChooseMenu({
    layoutItems,
    menuTitle: title,
    icon,
    chooseFunc: onClick,
  });
};
