import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BlIcon } from 'components';
import { Button, Form, FormInstance, Input, InputNumber, message, message as Message } from 'antd';
import _ from 'lodash';
import { fetchSmsGetCode, fetchSendCaptcha, fetchVerifyCaptcha } from 'src/api/ytt/user-domain';
import { fetchUserVerityPhone, fetchUserUpdatePhone } from 'src/api/ytt/user-domain/user';
import { MESSAGE_AUTH_TYPE, UserInfoContext } from '../constant';
import styles from '../styles.module.scss';

const SmsCodeBaseInfo: React.FC<{
  form: FormInstance;
  showType: number;
  timing: boolean;
  curPhoneNum?: string; // 外部传入的电话，防止Context未拿到
  bindPhoneTag?: boolean;
  replacePhoneTag?: boolean;
  handleTiming: (time: boolean) => void;
  handleDisable: (status: boolean) => void;
}> = (props) => {
  const {
    form,
    showType,
    bindPhoneTag,
    replacePhoneTag,
    timing,
    handleTiming,
    curPhoneNum,
    handleDisable,
  } = props;

  const [loading, setLoading] = useState<boolean>();
  const [flag, setFlag] = useState<boolean>(true); // 是否能获取验证码
  const [imageSource, setImageSource] = useState<string | null>(null); // 图文校验的图片地址
  const [uuid, setUuid] = useState<string | null>(null); // 存储在redis的校验码的key值
  const [imageStatus, setImageStatus] = useState({});
  const [countDown, setCountDown] = useState(60);

  let {
    dataSource: { phone: oldPhone },
  } = useContext(UserInfoContext);

  const { refresh } = useContext(UserInfoContext);

  if (!oldPhone) oldPhone = curPhoneNum;

  useEffect(() => {
    let interval: number = 0;

    if (timing) {
      interval = window.setInterval(() => {
        setCountDown((preSecond) => {
          if (preSecond <= 1) {
            handleTiming(false);
            clearInterval(interval);
            // 重置秒数
            return countDown || 60;
          }
          return preSecond - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [countDown, timing]);

  // 获取图文验证码
  const handleFetchSendCaptcha = useCallback(async () => {
    const phone =
      showType === MESSAGE_AUTH_TYPE.BIND_PHONE ? form.getFieldValue('phone') : oldPhone;

    await fetchSendCaptcha({ phone }).then((res) => {
      const _image = _.get(res, 'data.image');

      const _uuid = _.get(res, 'data.uuid');

      handleDisable(true);

      setUuid(_uuid);
      setImageSource(_image);
    });
  }, [form]);

  // 校验图文验证码
  const handleImageInputChange = useCallback(
    (e) => {
      const { value } = e.target;

      if (value?.length === 4 && uuid) {
        const phone =
          showType === MESSAGE_AUTH_TYPE.BIND_PHONE ? form.getFieldValue('phone') : oldPhone;

        fetchVerifyCaptcha({ captchaCode: value, phone, uuid }).then((res) => {
          const _flag = _.get(res, 'data.flag');

          if (_flag === false) {
            setImageStatus({
              validateStatus: 'error',
              help: '验证码输入错误！',
            });
          } else {
            handleDisable(false);
            setImageStatus({});
            handleTiming(true);
            setCountDown(60);
          }

          setFlag(_flag);
        });
      }
    },
    [form, uuid],
  );

  const bindOrReplacePhone = async () => {
    try {
      const { authCode } = await form?.validateFields();

      const { code, message } = await fetchUserUpdatePhone({
        message: authCode,
      });

      if (code === 200) {
        Message.success(bindPhoneTag ? '绑定成功' : '更换成功');
        form.resetFields();
        handleTiming(false);
        refresh();
        return;
      }

      Message.error(message);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <div className={styles?.smsCodeBase}>
      <Form form={form}>
        {showType !== MESSAGE_AUTH_TYPE.BIND_PHONE ? ( // 重置密码和重置手机号 自带phone
          <div className={styles?.messageAuthTitle}>
            <span className={styles?.messageTitle}>短信验证将发送短信验证码至您的手机</span>
            <span className={styles?.bindingPhone}>{oldPhone}</span>
          </div>
        ) : (
          <Form.Item
            name="phone"
            rules={[
              {
                required: showType === MESSAGE_AUTH_TYPE.BIND_PHONE,
                message: replacePhoneTag ? '请输入新手机号' : '请输入手机号',
              },
              {
                pattern: /^1\d{10}$/,
                message: '不合法的手机号格式!',
              },
            ]}
          >
            <Input
              placeholder={replacePhoneTag ? '请输入新手机号' : '请输入手机号'}
              style={{ width: 480 }}
              className={styles.input}
            />
          </Form.Item>
        )}
        {flag ? (
          <div className={styles?.codeBox}>
            <Form.Item
              name="authCode"
              rules={[
                {
                  required: flag,
                  message: '请输入验证码!',
                },
                {
                  pattern: /[0-9]{0,4}/,
                  message: replacePhoneTag ? '请输入新手机号验证码' : '请输入正确的验证码',
                },
              ]}
            >
              <Input
                placeholder={replacePhoneTag ? '请输入新手机号验证码' : '请输入验证码'}
                style={{ width: 340 }}
                className={styles.input}
              />
            </Form.Item>
            <Button
              className={styles?.codeButton}
              loading={loading}
              disabled={timing}
              onClick={async () => {
                try {
                  const phone =
                    showType === MESSAGE_AUTH_TYPE.BIND_PHONE
                      ? form.getFieldValue('phone')
                      : oldPhone;

                  if (!phone) {
                    return message.error('手机号不能为空');
                  }

                  // 只有绑定手机需要输入手机号 忘记密码和更换手机都是存在手机号的
                  const pictureCode = form.getFieldValue('pictureCode');

                  setLoading(true);

                  let res;

                  if (
                    showType === MESSAGE_AUTH_TYPE.BIND_PHONE && // 绑定手机
                    !replacePhoneTag &&
                    !bindPhoneTag
                  ) {
                    // 未绑定手机
                    res = await fetchUserVerityPhone({ oldPhone, newPhone: phone });
                  } else if (showType === MESSAGE_AUTH_TYPE.BIND_PHONE && replacePhoneTag) {
                    // 更换手机
                    res = await fetchUserVerityPhone({ oldPhone, newPhone: phone });
                  } else if (showType === MESSAGE_AUTH_TYPE.BIND_EXISTING_PHONE) {
                    // 绑定已存在的手机
                    res = await fetchUserVerityPhone({ newPhone: phone, toBound: true });
                  } else {
                    // 旧手机短信校验
                    res = await fetchSmsGetCode({ phone, code: pictureCode });
                  }

                  const _flag = _.get(res, 'data.flag');

                  if (_flag) {
                    // 正常获取 设置loading 倒数计时
                    setCountDown(60);
                    handleTiming(true);
                  } else {
                    // 显示图文校验
                    await handleFetchSendCaptcha();
                    Message.warn('请输入图形验证码');
                  }
                  setFlag(_flag);
                } catch (error) {
                  console.log('error: ', error);
                } finally {
                  setLoading(false);
                }
              }}
            >
              {timing ? `${countDown}秒后重新获取` : '获取验证码'}
            </Button>
          </div>
        ) : (
          <Form.Item
            {...imageStatus}
            name="pictureCode"
            rules={[
              { required: flag === false, message: '图形验证码必填' },
              { pattern: /[0-9]{0,4}/, message: '请输入正确的图形验证码' },
            ]}
          >
            <div className={styles.code}>
              <InputNumber
                onBlur={_.throttle(handleImageInputChange, 500)}
                maxLength={4}
                className={styles.input}
                placeholder="请输入图形验证码"
              />
              <div className={styles.imgWrap}>
                {imageSource ? (
                  <img
                    style={{ height: 32, width: 110, border: '1px solid #D3D8E3' }}
                    src={imageSource}
                  />
                ) : null}
                <div className={styles.handler} onClick={handleFetchSendCaptcha}>
                  <BlIcon type="iconshuaxin" />
                  <span>刷新</span>
                </div>
              </div>
            </div>
          </Form.Item>
        )}
        {!bindPhoneTag && showType !== MESSAGE_AUTH_TYPE.RESET_PASSWORD && (
          <Button className={styles?.changePhoneBtn} type="primary" onClick={bindOrReplacePhone}>
            {replacePhoneTag ? '立即更换' : '立即绑定'}
          </Button>
        )}
      </Form>
    </div>
  );
};

export default SmsCodeBaseInfo;
