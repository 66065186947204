import { useState } from 'react';
import { message, Popover, Space } from 'antd';
import { replaceSign } from 'src/utils/constants';
import { DataFormLayoutForModal, FilterItem, RecordListLayout } from 'layout';
import { fieldTypeList, gcTime } from 'src/utils';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import _Time from 'src/utils/dataTypes/time';
import { BlIcon, LinkTooltip, QrCode, SearchSelect, TagList, Tooltip } from 'src/components';
import _ from 'lodash';
import { LabelType } from 'src/page/knowledgeManagement/warehouse/interface';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import _Array from 'src/utils/dataTypes/array';
import {
  FIELD_MAP,
  TASK_STATUS_ENUM_MAP,
  TASK_STATUS_STATUS_MAP_ENUM,
  TASK_STATUS_TYPE_TYPE,
  WORK_ORDER_STATUS_MAP_ENUM,
  WORK_ORDER_TYPE_TYPE,
} from './constant';
import {
  fetchWorkOrderDispatchInfoGet,
  FetchWorkOrderDispatchInfoGetResponse,
  fetchWorkOrderProcessPlanList,
} from 'src/api/ytt/med-domain/work_order';
import { intersectionValueOfId } from 'src/utils/array';
import { toDetailProductionOrder } from '../../productionOrder/navigation';
import { AvatarDisplay } from 'src/components/avatar/show';
import ProductionTaskForm from '../../productionOrder/creatProductionTask/ProductionTaskForm';
import { Attribute } from 'src/page/sales/salesManagement/salesOrder/interface';
import authDict from 'src/utils/auth';
import lookup from 'src/dict';

export interface AmountType {
  amount: number;
  amountDisplay: string;
  unitCode: string;
  unitId: number;
  unitName: string;
}

interface userProps {
  id: number;
  name: string;
  code: string;
  avatarUrl: string;
}

export default function ProcessPlanCOList(props: { history: any }) {
  const { history } = props;
  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]); // 选中的keys
  const [selectRows, setSelectRows] = useState<any[]>([]);
  const [IssueTask, setIssueTask] = useState<FetchWorkOrderDispatchInfoGetResponse['data']>();

  //  批量选择时过滤不符合领料申请的数据
  const checkCanStoreRequisition = (data: any) => {
    const checkCanStoreRequisitionFilterState = _.map(
      _.filter(data, (node: any) => {
        return [
          TASK_STATUS_ENUM_MAP.CRATE,
          TASK_STATUS_ENUM_MAP.BEEN_ISSUED,
          TASK_STATUS_ENUM_MAP.EXECUTION,
        ].includes(node?.workOrderStatus);
      }),
      'workOrderProcessId',
    );

    return checkCanStoreRequisitionFilterState;
  };

  const dataColumns = [
    {
      title: '工单编号',
      dataIndex: 'workOrderCode',
      width: 150,
      sorter: true,
      render: (text: string, record: any, index: number, config: any) => (
        <LinkTooltip
          to={toDetailProductionOrder(record?.workOrderId)}
          text={text}
          width={config.contentWidth}
          auth={authDict.productionorder_detail}
        />
      ),
    },
    {
      title: '工单类型',
      dataIndex: 'workOrderType',
      width: 150,
      render: (workOrderType: number) => WORK_ORDER_TYPE_TYPE.get(workOrderType) || replaceSign,
    },
    {
      title: '业务状态',
      dataIndex: 'workOrderStatus',
      width: 150,
      sorter: true,
      render: (workOrderStatus: number, record: any) => {
        if (workOrderStatus === TASK_STATUS_ENUM_MAP.CANCEL) {
          return `${TASK_STATUS_TYPE_TYPE.get(workOrderStatus)}(${lookup(
            'productionPlanning.WorkOrderCloseTypeMap',
            record.workOrderCloseType,
          )})`;
        }
        return TASK_STATUS_TYPE_TYPE.get(workOrderStatus) || replaceSign;
      },
    },
    {
      title: '计划开始时间',
      dataIndex: 'plannedStartTime',
      sorter: true,
      width: 180,
      render: (plannedStartTime: Date) => {
        if (!plannedStartTime) return replaceSign;
        return _Time.format(plannedStartTime);
      },
    },
    {
      title: '计划完工时间',
      dataIndex: 'plannedFinishTime',
      sorter: true,
      width: 180,
      render: (plannedFinishTime: Date) => {
        if (!plannedFinishTime) return replaceSign;
        return _Time.format(plannedFinishTime);
      },
    },
    {
      title: '生产部门',
      dataIndex: 'productionDepartment',
      width: 150,
      render: (productionDepartment: userProps) => {
        if (!productionDepartment) return replaceSign;
        return (
          <AvatarDisplay
            id={productionDepartment?.id}
            name={productionDepartment?.name}
            key={productionDepartment?.id}
          />
        );
      },
    },
    {
      title: '生产主管',
      dataIndex: 'productionSupervisor',
      width: 150,
      render: (productionSupervisor: userProps) => {
        if (!productionSupervisor) return replaceSign;
        return (
          <AvatarDisplay
            id={productionSupervisor?.id}
            name={productionSupervisor?.name}
            key={productionSupervisor?.id}
            avatarUrl={productionSupervisor?.avatarUrl}
            isUser
            isShowTag={false}
          />
        );
      },
    },
    {
      title: '计划部门',
      dataIndex: 'planningDepartment',
      width: 150,
      render: (planningDepartment: userProps) => {
        if (!planningDepartment) return replaceSign;
        return (
          <AvatarDisplay
            id={planningDepartment?.id}
            name={planningDepartment?.name}
            key={planningDepartment?.id}
          />
        );
      },
    },
    {
      title: '计划员',
      dataIndex: 'planningUser',
      width: 150,
      render: (planningUser: userProps) => {
        if (!planningUser) return replaceSign;
        return (
          <AvatarDisplay
            id={planningUser?.id}
            name={planningUser?.name}
            key={planningUser?.id}
            isUser
            isShowTag={false}
            avatarUrl={planningUser?.avatarUrl}
          />
        );
      },
    },
    {
      title: '工单主产出物料编号',
      dataIndex: ['mainOutputMaterial', 'code'],
      width: 200,
      render: (code: string) => code || replaceSign,
    },
    {
      title: '工单主产出物料名称',
      dataIndex: ['mainOutputMaterial', 'name'],
      width: 200,
      render: (name: string) => name || replaceSign,
    },
    {
      title: '工单主产出物料分类',
      dataIndex: ['mainOutputMaterial', 'category', 'name'],
      width: 200,
      render: (name: string) => name || replaceSign,
    },
    {
      title: '工单主产出物料属性',
      dataIndex: ['mainOutputMaterial', 'attribute'],
      width: 200,
      render: (attribute: Attribute[]) => {
        if (_Array.isEmpty(attribute)) return replaceSign;
        const dataSource = attribute?.map((item) => {
          const { name, attributeItem, id } = item;

          return {
            label: `${name}:${attributeItem?.content}`,
            value: id,
          };
        });

        return <TagList dataSource={dataSource} />;
      },
    },
    {
      title: '工艺路线编号',
      dataIndex: ['processRoute', 'code'],
      width: 150,
      render: (code: string) => code ?? replaceSign,
    },
    {
      title: '顺序号',
      dataIndex: ['processDO', 'processSeq'],
      width: 150,
      render: (processSeq: number) => processSeq ?? replaceSign,
    },
    {
      title: '工序号',
      dataIndex: ['processDO', 'processNum'],
      width: 150,
      render: (processSeq: string) => processSeq ?? replaceSign,
    },
    {
      title: '工序编号',
      dataIndex: ['processDO', 'processCode'],
      width: 150,
      render: (processCode: string) => processCode ?? replaceSign,
    },
    {
      title: '工序名称',
      dataIndex: ['processDO', 'processName'],
      width: 150,
      render: (processName: string) => processName ?? replaceSign,
    },
    {
      title: '工序说明',
      dataIndex: ['processDO', 'description'],
      width: 150,
      render: (description: string) => description ?? replaceSign,
    },
    {
      title: '标识码',
      dataIndex: 'identifier',
      width: 180,
      render: (identifier: string, record: any, index: number, config: any) => {
        return (
          <div>
            <Tooltip text={identifier ?? replaceSign} width={config.contentWidth} />
            <Space style={{ position: 'absolute', right: '10px' }}>
              <Popover
                placement="rightTop"
                content={
                  <div>
                    <QrCode value={identifier} />
                    <div style={{ textAlign: 'center' }}>
                      <Tooltip text={identifier ?? replaceSign} width={config.contentWidth} />
                    </div>
                  </div>
                }
              >
                <BlIcon type="iconerweima" />
              </Popover>
            </Space>
          </div>
        );
      },
    },
    {
      title: '工作中心',
      dataIndex: ['workCenterDO', 'name'],
      width: 150,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '物料编号',
      dataIndex: ['materialDO', 'baseInfo', 'code'],
      width: 150,
      render: (code: string) => code ?? replaceSign,
    },
    {
      title: '物料名称',
      dataIndex: ['materialDO', 'baseInfo', 'name'],
      width: 150,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '基准数',
      dataIndex: 'standardAmount',
      width: 150,
      render: (standardAmount: string) => standardAmount ?? replaceSign,
    },
    {
      title: '计划生产数',
      dataIndex: 'plannedAmount',
      width: 150,
      render: (plannedAmount: AmountType) => plannedAmount?.amountDisplay,
    },
    {
      title: '已下发数',
      dataIndex: 'dispatchedAmount',
      width: 150,
      render: (dispatchedAmount: AmountType) => dispatchedAmount?.amountDisplay,
    },
    {
      title: '正在下发数',
      dataIndex: 'nowDispatchedAmount',
      width: 150,
      render: (nowDispatchedAmount: AmountType) => nowDispatchedAmount?.amountDisplay,
    },
    {
      title: '可下发数',
      dataIndex: 'dispatchedRemainAmount',
      width: 150,
      render: (dispatchedRemainAmount: AmountType) => dispatchedRemainAmount?.amountDisplay,
    },
    {
      title: '总报工数',
      dataIndex: 'totalReportAmount',
      width: 150,
      render: (totalReportAmount: AmountType) => totalReportAmount?.amountDisplay,
    },
    {
      title: '合格报工数',
      dataIndex: 'qualifiedAmount',
      width: 150,
      render: (qualifiedAmount: AmountType) => qualifiedAmount?.amountDisplay,
    },
    {
      title: '不合格报工数',
      dataIndex: 'disqualifiedAmount',
      width: 150,
      render: (disqualifiedAmount: AmountType) => disqualifiedAmount?.amountDisplay,
    },
    {
      title: '单位',
      dataIndex: 'unitDO',
      width: 150,
      render: (unitDO: any) => unitDO?.name,
    },
    {
      title: '工序计划开始时间',
      dataIndex: 'processPlannedStartTime',
      width: 180,
      render: (processPlannedStartTime: Date) => {
        if (!processPlannedStartTime) return replaceSign;
        return _Time.format(processPlannedStartTime);
      },
    },
    {
      title: '工序计划完工时间',
      dataIndex: 'processPlannedEndTime',
      width: 180,
      render: (processPlannedEndTime: Date) => {
        if (!processPlannedEndTime) return replaceSign;
        return _Time.format(processPlannedEndTime);
      },
    },
    {
      title: '实际开始时间',
      dataIndex: 'processActualStartTime',
      width: 180,
      render: (processActualStartTime: Date) => {
        if (!processActualStartTime) return replaceSign;
        return _Time.format(processActualStartTime);
      },
    },
    {
      title: '实际结束时间',
      dataIndex: 'processActualEndTime',
      width: 180,
      render: (processActualEndTime: Date) => {
        if (!processActualEndTime) return replaceSign;
        return _Time.format(processActualEndTime);
      },
    },
  ];

  const getOperationList = (record: any) => {
    return [
      {
        title: '查看',
        auth: authDict.productionorder_detail,
        onClick: () => {
          history.push(toDetailProductionOrder(record?.workOrderId));
        },
      },
      {
        title: '申请领料',
        auth: authDict.pickorder_add,
        disabled: ![
          TASK_STATUS_ENUM_MAP.CRATE,
          TASK_STATUS_ENUM_MAP.BEEN_ISSUED,
          TASK_STATUS_ENUM_MAP.EXECUTION,
        ].includes(record?.workOrderStatus),
        onClick: () => {
          history.push(
            `/productionPlanning/execution/productManagement/storeRequisition/batchCreate?ids=${[
              record?.workOrderProcessId,
            ]}&originType=${2}`,
          );
        },
      },
      {
        title: '下发',
        auth: authDict.productionorder_dispatch,
        onClick: async () => {
          await fetchWorkOrderDispatchInfoGet({
            workOrderIds: [record?.workOrderId],
          }).then((res) => {
            const { data } = res;

            const formatData = data?.filter(
              (e) =>
                e?.workOrderId === record?.workOrderId &&
                e?.processInfo?.processNum === record?.processDO?.processNum,
            );

            setIssueTask(formatData);
          });
        },
      },
    ];
  };

  const toolbar = [
    {
      title: '申请领料',
      auth: authDict.pickorder_add,
      onClick: (onSuccess: any, onFail: any) => {
        if (selectedRowKeys?.length > 100) {
          message.error('最多支持选择100个');
          onFail?.();
        } else {
          const checkCanStoreRequisitionFilterState = checkCanStoreRequisition(selectRows);

          if (_Array.isEmpty(checkCanStoreRequisitionFilterState)) {
            message.error('暂无可领料数据');
            return onFail?.();
          }

          history.push(
            `/productionPlanning/execution/productManagement/storeRequisition/batchCreate?ids=${checkCanStoreRequisitionFilterState}&originType=${2}`,
          );
        }
      },
    },
    {
      title: '下发',
      auth: authDict.productionorder_dispatch,
      onClick: () =>
        fetchWorkOrderDispatchInfoGet({
          workOrderIds: selectRows.map((e) => e.workOrderId),
        }).then((res) => {
          const { data } = res;

          const formatData: any = data
            ?.map((item: any) => {
              if (
                selectRows?.find(
                  (e) =>
                    e?.workOrderId === item?.workOrderId &&
                    e?.processDO?.processNum === item?.processInfo?.processNum,
                )
              ) {
                return item;
              }
            })
            .filter((e) => e);

          setIssueTask(formatData);
        }),
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '工单编号',
      name: 'code',
      type: fieldTypeList.text,
    },
    {
      label: '工单类型',
      name: 'workOrderType',
      type: fieldTypeList.multiSelect,
      selectProps: {
        options: WORK_ORDER_STATUS_MAP_ENUM.map((node: LabelType) => {
          return {
            label: node?.name,
            value: node?.id,
          };
        }),
      },
    },
    {
      label: '业务状态',
      name: 'status',
      type: fieldTypeList.multiSelect,
      selectProps: {
        options: TASK_STATUS_STATUS_MAP_ENUM.map((node: LabelType) => {
          return {
            label: node?.name,
            value: node?.id,
          };
        }),
      },
    },

    {
      label: '生产部门',
      name: 'productionDepartmentId',
      type: fieldTypeList.multiSelect,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple />,
    },
    {
      label: '生产主管',
      name: 'productionSupervisorId',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
    {
      label: '计划部门',
      name: 'planningDepartmentId',
      type: fieldTypeList.multiSelect,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple />,
    },
    {
      label: '计划员',
      name: 'planningUserId',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
    {
      label: '工艺路线编号',
      name: 'processRouteId',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect fetchType={'processRouting'} labelPath="code" labelInValue mode="multiple" />
      ),
    },
    {
      label: '工序名称',
      name: 'processName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect fetchType={'process'} labelPath="name" labelInValue mode="multiple" />
      ),
    },
    {
      label: '工序编号',
      name: 'processCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect fetchType={'process'} labelPath="code" labelInValue mode="multiple" />
      ),
    },
    {
      label: '标识码',
      name: 'identifier',
      type: fieldTypeList.text,
    },
    {
      label: '工作中心',
      name: 'workCenterId',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType={'workCenter'} labelInValue mode="multiple" />,
    },
    {
      label: '工单主产出物料名称',
      name: 'mainOutputMaterialName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          labelPath="name"
          valuePath="id"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '工单主产出物料编号',
      name: 'mainOutputMaterialCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          labelPath="code"
          valuePath="id"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '计划开始时间',
      name: 'plannedStartTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '计划完工时间',
      name: 'plannedEndTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '工序计划开始时间',
      name: 'processPlannedStartTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '工序计划完工时间',
      name: 'processPlannedFinishTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
  ];

  const formatDataToQuery = (params: any) => {
    const {
      productionDepartmentId,
      productionSupervisorId,
      planningDepartmentId,
      planningUserId,
      processRouteId,
      processName,
      processCode,
      workCenterId,
      mainOutputMaterialName,
      mainOutputMaterialCode,
      plannedStartTime,
      plannedEndTime,
      processPlannedStartTime,
      processPlannedFinishTime,
      sorter,
      ...rest
    } = params;

    const relParams = {
      ...rest,
    };

    if (plannedStartTime) {
      relParams.planStartTimeFrom = Number(_Time.formatToUnix(plannedStartTime[0]));
      relParams.planStartTimeTo = Number(_Time.formatToUnix(plannedStartTime[1]));
    }
    if (plannedEndTime) {
      relParams.planEndTimeFrom = Number(_Time.formatToUnix(plannedEndTime[0]));
      relParams.planEndTimeTo = Number(_Time.formatToUnix(plannedEndTime[1]));
    }
    if (processPlannedStartTime) {
      relParams.processPlannedStartTimeFrom = Number(
        _Time.formatToUnix(processPlannedStartTime[0]),
      );
      relParams.processPlannedStartTimeTo = Number(_Time.formatToUnix(processPlannedStartTime[1]));
    }
    if (processPlannedFinishTime) {
      relParams.processPlannedFinishTimeFrom = Number(
        _Time.formatToUnix(processPlannedFinishTime[0]),
      );
      relParams.processPlannedFinishTimeTo = Number(
        _Time.formatToUnix(processPlannedFinishTime[1]),
      );
    }

    if (!_Array.isEmpty(productionDepartmentId)) {
      relParams.productionDepartmentId = _.map(productionDepartmentId, 'value');
    }

    if (!_Array.isEmpty(productionSupervisorId)) {
      relParams.productionSupervisorId = _.map(productionSupervisorId, 'value');
    }
    if (!_Array.isEmpty(planningDepartmentId)) {
      relParams.planningDepartmentId = _.map(planningDepartmentId, 'value');
    }
    if (!_Array.isEmpty(planningUserId)) {
      relParams.planningUserId = _.map(planningUserId, 'value');
    }
    if (!_Array.isEmpty(processRouteId)) {
      relParams.processRouteId = _.map(processRouteId, 'value');
    }
    if (!_Array.isEmpty(processName) || !_Array.isEmpty(processCode)) {
      relParams.processId = intersectionValueOfId(processName, processCode);
    }
    if (!_Array.isEmpty(workCenterId)) {
      relParams.workCenterId = _.map(workCenterId, 'value');
    }

    if (!_Array.isEmpty(mainOutputMaterialName) || !_Array.isEmpty(mainOutputMaterialCode)) {
      relParams.mainOutputMaterialId = intersectionValueOfId(
        mainOutputMaterialName,
        mainOutputMaterialCode,
      );
    }

    if (sorter) {
      relParams.sorter = _.map(sorter, (node: any) => {
        return {
          order: node?.order,
          field: FIELD_MAP.get(node?.field),
        };
      });
    }

    return relParams;
  };

  const formatDataToDisplay = (data: any) => {
    return data;
  };

  const formatDataToFormDisplay = (data: any) => {
    const {
      plannedStartTime,
      plannedEndTime,
      processPlannedStartTime,
      processPlannedFinishTime,
      ...rest
    } = data;

    return {
      ...rest,
      plannedStartTime: plannedStartTime && gcTime.formatRangeTimeToMoment(plannedStartTime),
      plannedEndTime: plannedEndTime && gcTime.formatRangeTimeToMoment(plannedEndTime),
      processPlannedStartTime:
        processPlannedStartTime && gcTime.formatRangeTimeToMoment(processPlannedStartTime),
      processPlannedFinishTime:
        processPlannedFinishTime && gcTime.formatRangeTimeToMoment(processPlannedFinishTime),
    };
  };

  return (
    <>
      <RecordListLayout<any>
        getOperationList={getOperationList}
        columns={dataColumns}
        filterList={filterList}
        batchMenu={toolbar}
        rowKey="workOrderProcessId"
        placeholder={'请输入工单编码'} // 快速搜索input placeholder
        selectedRowKeys={selectedRowKeys}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={formatDataToDisplay}
        formatDataToFormDisplay={formatDataToFormDisplay}
        configcacheKey="ProcessPlanCO" // 储存列配置
        onSelectedRowKeys={(selectKey: any, selectRows: any) => {
          setSelectRowKeys(selectKey);
          setSelectRows(selectRows);
        }}
        requestFn={fetchWorkOrderProcessPlanList}
        pagination={{ pageSizeOptions: [10, 20] }}
      />
      <DataFormLayoutForModal
        visible={!_.isEmpty(IssueTask)}
        onClose={() => {
          setIssueTask(undefined);
        }}
        content={
          <ProductionTaskForm
            name="dispatchRequests"
            params={IssueTask}
            history={history}
            onCancel={() => {
              setIssueTask(undefined);
            }}
          />
        }
      />
    </>
  );
}
