import React from 'react';
import { BlTable } from 'src/components';
import _Array from 'src/utils/dataTypes/array';
import { dealWithPeriodOfTime } from '../../shift/utils';
import { CYCLE_NUMBER_TYPE, WEED_TYPE_ENUM } from '../constant';
import {
  ShiftInfoDetailVOSType,
  ShiftInShiftRuleDetailVOType,
  ShiftRuleInfoVOSType,
} from '../interface';

interface TableProps {
  dataSource: ShiftRuleInfoVOSType[];
  type: number;
}

const BaseTable = (props: TableProps) => {
  const { dataSource, type } = props;

  const dataColumns = [
    {
      title: '天',
      width: 80,
      dataIndex: 'day',
      render: (day: number, _: any, index: number) => {
        const num = index + 1;

        if (type === CYCLE_NUMBER_TYPE.DAY) {
          return `第${num}天`;
        } else if (type === CYCLE_NUMBER_TYPE.WEEK) {
          return `周${WEED_TYPE_ENUM.get(day % 7)}`;
        }
        return `${num}号`;
      },
    },
    {
      title: '班次',
      width: 300,
      dataIndex: 'shiftInShiftRuleDetailVO',
      render: (shiftInShiftRuleDetailVO: ShiftInShiftRuleDetailVOType) => {
        const { shiftInfoDetailVOS, name } = shiftInShiftRuleDetailVO;

        const formatTime = dealWithPeriodOfTime(shiftInfoDetailVOS)
          .map((element: ShiftInfoDetailVOSType) => {
            return element?.startTime + ' - ' + element?.endTime;
          })
          .join('；');

        return `${name}${!_Array.isEmpty(shiftInfoDetailVOS) ? `(${formatTime})` : ''}`;
      },
    },
  ];

  return (
    <>
      <BlTable
        columns={dataColumns}
        scroll={{ y: 500 }}
        dataSource={dataSource}
        pagination={false}
        rowKey={(record) => {
          const { id } = record;

          return id;
        }}
      />
    </>
  );
};

export default BaseTable;
