import { RouteProp } from 'src/route';
/**
 * 单位主数据管理
 */
import UnitsList from 'src/page/knowledgeManagement/materialModeling/units/list';
import DetailsPage from 'src/page/knowledgeManagement/materialModeling/units/details/detailsPage';
/**
 * 物料主数据管理
 */
import MaterialList from 'src/page/knowledgeManagement/materialModeling/material/list';
import MaterialDetailsPage from 'src/page/knowledgeManagement/materialModeling/material/details/detailsPage';
import MaterialEditPage from 'src/page/knowledgeManagement/materialModeling/material/createAndEdit/editPage';
import MaterialCategoryList from 'src/page/knowledgeManagement/materialModeling/materialCategory/list';
import MaterialPropertyList from 'src/page/knowledgeManagement/materialModeling/materialProperty/list';
import MaterialPropertyDetailsPage from 'src/page/knowledgeManagement/materialModeling/materialProperty/details/detailsPage';
/**
 * 批次主数据管理
 */
import BatchList from 'src/page/warehouseManagement/batchManagement/batchList';
/**
 * 日历主数据管理
 */
import ShiftList from 'src/page/knowledgeManagement/calendarManagement/shift/list';
import ShiftDetailPage from 'src/page/knowledgeManagement/calendarManagement/shift/detailPage';
import EditShift from 'src/page/knowledgeManagement/calendarManagement/shift/edit';
import CreatShift from 'src/page/knowledgeManagement/calendarManagement/shift/create';
import SchedulingRulesList from 'src/page/knowledgeManagement/calendarManagement/SchedulingRules/list';
import SchedulingDetailPage from 'src/page/knowledgeManagement/calendarManagement/SchedulingRules/detailPage';
import EditScheduling from 'src/page/knowledgeManagement/calendarManagement/SchedulingRules/edit';
import CreatScheduling from 'src/page/knowledgeManagement/calendarManagement/SchedulingRules/create';
import WorkCalendar from 'src/page/knowledgeManagement/calendarManagement/workCalendar';
import CalendarDetail from 'src/page/knowledgeManagement/calendarManagement/workCalendar/CalendarDetail';
import OperateCalendar from 'src/page/knowledgeManagement/calendarManagement/workCalendar/OperateCalendar';
import ScheduleShift from 'src/page/knowledgeManagement/calendarManagement/workCalendar/ScheduleShift';
/**
 * 客户主数据管理
 */
import CustomerList from 'src/page/modeling/customerModeling/customer';
import CustomerRegisterList from 'src/page/modeling/customerModeling/customer/registerList/customerRegister/list';
import MaterialsRegisterList from 'src/page/modeling/customerModeling/customer/registerList/materialsRegister/list';
/**
 * 供应商主数据管理
 */
import SupplierList from './supplyModeling/supplier';
/** 货源清单 */
import SourceList from 'src/page/modeling/supplyModeling/sourceList';
import authDict from 'src/utils/auth';
import BatchEdit from '../warehouseManagement/batchManagement/createAndEdit/BatchEdit';
/** 币种 */
import CurrencyList from './supplyChainModeling/currency/list';

export const modelingRouteConfig: RouteProp[] = [
  {
    path: '/modeling',
    menu: '建模',
    icon: 'iconyijicaidan-jianmo',
    breadcrumb: '建模',
    redirectPath: '/modeling/unitModeling/unitsList',
    children: [
      {
        path: '/modeling/unitModeling',
        menu: '单位主数据',
        breadcrumb: '单位主数据',
        redirectPath: '/modeling/unitModeling/unitsList',
        children: [
          {
            path: '/modeling/unitModeling/unitsList',
            logPage: true,
            auth: authDict.unit_view,
            component: UnitsList,
            menu: '单位',
            breadcrumb: '单位',
            children: [
              {
                path: '/modeling/unitModeling/unitsList/details/:id',
                component: DetailsPage,
                auth: authDict.unit_detail,
                breadcrumb: '详情',
              },
            ],
          },
        ],
      },
      {
        path: '/modeling/materialModeling',
        menu: '物料主数据',
        breadcrumb: '物料主数据',
        redirectPath: '/modeling/materialModeling/materialList',
        children: [
          {
            path: '/modeling/materialModeling/materialList',
            component: MaterialList,
            menu: '物料',
            logPage: true,
            auth: authDict.material_view,
            breadcrumb: '物料',
            children: [
              {
                path: '/modeling/materialModeling/materialList/:id/details',
                component: MaterialDetailsPage,
                auth: authDict.material_detail,
                breadcrumb: '详情',
              },
              {
                path: '/modeling/materialModeling/materialList/create',
                component: MaterialEditPage,
                auth: authDict.material_add,
                breadcrumb: '新建物料',
              },
              {
                path: '/modeling/materialModeling/materialList/:id/edit',
                component: MaterialEditPage,
                auth: authDict.material_edit,
                breadcrumb: '编辑物料',
              },
            ],
          },
          {
            path: '/modeling/materialModeling/materialCategoryList',
            component: MaterialCategoryList,
            menu: '物料分类',
            logPage: true,
            auth: authDict.materialcategory_view,
            breadcrumb: '物料分类',
          },
          {
            path: '/modeling/materialModeling/materialPropertyList',
            component: MaterialPropertyList,
            menu: '物料属性',
            logPage: true,
            auth: authDict.materialattributeitem_view,
            breadcrumb: '物料属性',
            children: [
              {
                path: '/modeling/materialModeling/materialPropertyList/details/:id',
                auth: authDict.materialattributeitem_detail,
                component: MaterialPropertyDetailsPage,
                breadcrumb: '详情',
              },
            ],
          },
        ],
      },
      {
        path: '/modeling/batchManagement',
        menu: '批次主数据',
        breadcrumb: '批次主数据',
        redirectPath: '/modeling/batchManagement/batchList',
        children: [
          {
            path: '/modeling/batchManagement/batchList',
            component: BatchList,
            logPage: true,
            auth: authDict.batchno_view,
            menu: '批次管理',
            breadcrumb: '批次管理',
            children: [
              {
                path: '/modeling/batchManagement/batchList/edit',
                component: BatchEdit,
                auth: authDict.batchno_edit,
                menu: '编辑',
                breadcrumb: '编辑',
              },
            ],
          },
        ],
      },
      {
        path: '/modeling/calendarManagement',
        menu: '日历主数据',
        breadcrumb: '日历主数据',
        redirectPath: '/modeling/calendarManagement/shiftList',
        children: [
          {
            path: '/modeling/calendarManagement/shiftList',
            auth: authDict.shift_view,
            component: ShiftList,
            menu: '班次',
            breadcrumb: '班次',
            children: [
              {
                path: '/modeling/calendarManagement/shiftList/:id/detail',
                auth: authDict.shift_detail,
                component: ShiftDetailPage,
                breadcrumb: '详情',
              },
              {
                path: '/modeling/calendarManagement/shiftList/:id/edit',
                auth: authDict.shift_edit,
                component: EditShift,
                breadcrumb: '编辑班次',
              },
              {
                path: '/modeling/calendarManagement/shiftList/create',
                auth: authDict.shift_add,
                component: CreatShift,
                breadcrumb: '新建班次',
              },
            ],
          },
          {
            path: '/modeling/calendarManagement/schedulingRules',
            auth: authDict.shiftrule_view,
            component: SchedulingRulesList,
            menu: '排班规则',
            breadcrumb: '排班规则',
            children: [
              {
                path: '/modeling/calendarManagement/schedulingRules/:id/detail',
                auth: authDict.shiftrule_detail,
                component: SchedulingDetailPage,
                breadcrumb: '详情',
              },
              {
                path: '/modeling/calendarManagement/schedulingRules/:id/edit',
                auth: authDict.shiftrule_edit,
                component: EditScheduling,
                breadcrumb: '编辑排班规则',
              },
              {
                path: '/modeling/calendarManagement/schedulingRules/create',
                auth: authDict.shiftrule_add,
                component: CreatScheduling,
                breadcrumb: '新建排班规则',
              },
            ],
          },
          {
            path: '/modeling/calendarManagement/workCalendar',
            auth: authDict.workcalendar_view,
            component: WorkCalendar,
            menu: '工作日历',
            breadcrumb: '工作日历',
            children: [
              {
                path: '/modeling/calendarManagement/workCalendar/detail:id',
                auth: authDict.workcalendar_detail,
                component: CalendarDetail,
                breadcrumb: '详情',
              },
              {
                path: '/modeling/calendarManagement/workCalendar/create',
                auth: authDict.workcalendar_add,
                component: OperateCalendar,
                breadcrumb: '新建工作日历',
              },
              {
                path: '/modeling/calendarManagement/workCalendar/edit:id',
                auth: authDict.workcalendar_edit,
                component: OperateCalendar,
                breadcrumb: '编辑工作日历',
              },
              {
                path: '/modeling/calendarManagement/workCalendar/copy:id',
                auth: authDict.workcalendar_add,
                component: OperateCalendar,
                breadcrumb: '复制工作日历',
              },
              {
                path: '/modeling/calendarManagement/workCalendar/schedule',
                auth: [authDict.workcalendar_add, authDict.workcalendar_edit],
                component: ScheduleShift,
                logPage: true,
                breadcrumb: '排班',
              },
            ],
          },
        ],
      },
      {
        path: '/modeling/customerModeling',
        menu: '客户主数据',
        breadcrumb: '客户主数据',
        redirectPath: '/modeling/customerModeling/customerList',
        children: [
          {
            path: '/modeling/customerModeling/customerList',
            logPage: true,
            auth: authDict.customer_view,
            component: CustomerList,
            menu: '客户',
            breadcrumb: '客户',
          },
          {
            path: '/modeling/customerModeling/customerRegisterList',
            auth: authDict.customerregisteredaudit_reg_list,
            component: CustomerRegisterList,
            breadcrumb: '注册邀请列表',
          },
          {
            path: '/modeling/customerModeling/materialsRegisterList',
            auth: authDict.customermaterialregisteredaudit_reg_list_material,
            component: MaterialsRegisterList,
            breadcrumb: '物料注册邀请列表',
          },
        ],
      },
      {
        path: '/modeling/supplyModeling',
        menu: '供应商主数据',
        breadcrumb: '供应商主数据',
        redirectPath: '/modeling/supplyModeling/supplierList',
        children: [
          {
            path: '/modeling/supplyModeling/supplierList',
            logPage: true,
            auth: authDict.supplier_view,
            component: SupplierList,
            menu: '供应商',
            breadcrumb: '供应商',
          },
          {
            path: '/modeling/supplyModeling/sourceList',
            logPage: true,
            component: SourceList,
            auth: authDict.sourcelist_view,
            menu: '货源清单',
            breadcrumb: '货源清单',
          },
        ],
      },
      {
        path: '/modeling/supplyChainModeling',
        menu: '供应链主数据',
        breadcrumb: '供应链主数据',
        redirectPath: '/modeling/supplyChainModeling/currency',
        children: [
          {
            path: '/modeling/supplyChainModeling/currency',
            logPage: true,
            component: CurrencyList,
            auth: authDict.currency_view,
            menu: '币种',
            breadcrumb: '币种',
          },
        ],
      },
    ],
  },
];
