import { FilterItem, RecordListLayout } from 'layout';
import {
  BlIcon,
  SearchSelect,
  showErrorListMessage,
  SingleOperationLogComponent,
  TagList,
  Tooltip,
} from 'components';
import _, { isEmpty } from 'lodash';
import {
  fetchAltDelete,
  fetchAltList,
  FetchAltListResponse,
} from 'src/api/ytt/med-domain/alternative-plan';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import authDict from 'src/utils/auth';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { ENGINEER_PATH } from 'src/page/knowledgeManagement/engineerData/navigation';
import { Form, message, Modal } from 'antd';
import { fieldTypeList, gcObject, gcTime } from 'utils';
import MaterialAttributeCascader, {
  formatMaterialAttributeToQuery,
} from 'src/page/knowledgeManagement/materialModeling/material/share/Cascader/BcMultiCascader';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { getMaterialAttrs } from 'src/entity/material';
import BcBlCascader from 'src/components/Cascader';
import { renderUser } from 'src/page/share/renderUser';
import moment from 'moment';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import { useDispatch } from 'react-redux';
import {
  pauseSomeTime,
  DELAY_GET_LIST_MS_GAP,
} from 'src/page/knowledgeManagement/engineerData/alternativePlan/helper/utils';

interface Props extends RouteComponentProps {}

type TableRowType = Exclude<FetchAltListResponse['data'], undefined>['list'][number];

const AlternativePlanList = (props: Props) => {
  const { history } = props;
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [detailId, setDetailId] = useState<number>(0);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  const toAlternativePlanCreate = () => {
    history.push(`${ENGINEER_PATH}/alternativePlan/create`);
  };
  const toAlternativePlanDetail = (id?: number) => {
    if (!id) return;
    history.push(`${ENGINEER_PATH}/alternativePlan/detail/${id}`);
  };
  const toAlternativePlanEdit = (id?: number) => {
    history.push(`${ENGINEER_PATH}/alternativePlan/edit/${id}`);
  };
  const toAlternativePlanCopy = (id?: number) => {
    history.push(`${ENGINEER_PATH}/alternativePlan/copy/${id}`);
  };
  const [delForm] = Form.useForm();
  const refreshTable = () => {
    setRefreshMarker(Math.random() * 100);
  };
  const dataColumns = [
    {
      title: '替代方案编号',
      dataIndex: 'code',
      isFilter: false,
      sorter: true,
      width: 150,
      render: (value: string, record: TableRowType) => (
        <a onClick={() => toAlternativePlanDetail(record?.id)}>
          <Tooltip text={value ?? '-'} width={150} />
        </a>
      ),
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '替代方案名称',
      dataIndex: 'name',
      isFilter: false,
      sorter: true,
      width: 150,
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '所属部门',
      dataIndex: 'ownedDepartmentId',
      isFilter: true,
      width: 150,
      render: (text: any, record: TableRowType) => record?.ownedDepartmentName,
      renderItem: <UserOrDepartmentSelectWithDialog placeholder="请选择" isMultiple />,
    },
    {
      title: '物料编码',
      filterTitle: '物料',
      dataIndex: 'materialCode',
      isFilter: true,
      width: 150,
      render: (text: any, record: TableRowType) => {
        return record?.material?.baseInfo?.code;
      },
      renderItem: <SearchSelect mode="multiple" fetchType="materialCodeName" />,
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '物料名称',
      dataIndex: 'materialName',
      width: 150,
      render: (text: any, record: TableRowType) => {
        return record?.material?.baseInfo?.name;
      },
    },
    {
      title: '物料属性',
      dataIndex: 'materialAttributeIdList',
      isFilter: true,
      width: 150,
      inputProps: {
        placeholder: '请输入关键字',
      },
      renderItem: <MaterialAttributeCascader />,
      render: (_text: any, record: any) => (
        <TagList dataSource={getMaterialAttrs(record.material)} />
      ),
    },
    {
      title: '物料分类',
      dataIndex: 'materialCategory',
      isFilter: true,
      width: 150,
      renderItem: (
        <BcBlCascader
          fetchType={'materialCategory'}
          nameLabel={'物料分类'}
          popupPlacement={'bottomRight'}
        />
      ),
      render: (text: any, record: TableRowType) => {
        return record?.material?.category?.name;
      },
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '物料规格',
      dataIndex: 'materialSpecification',
      isFilter: false,
      width: 150,
      render: (text: any, record: TableRowType) => {
        return record?.material?.baseInfo?.specification;
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      isFilter: true,
      width: 150,
      render: renderUser,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择创建人" isMultiple isSelectUser />
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      width: 180,
      render: (text: any) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      isFilter: true,
      width: 150,
      render: renderUser,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择更新人" isMultiple isSelectUser />
      ),
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      type: fieldTypeList.date,
      isFilter: true,
      sorter: true,
      width: 180,
      render: (text: any) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  const handleDelete = async (ids: (number | string)[] | undefined, onSuccess?: () => void) => {
    // 删除
    try {
      if (!ids) return;
      const res = await fetchAltDelete({
        ids: ids.map((i) => Number(i)),
        // @ts-ignore
        reason: delForm.getFieldValue('reason'),
      });
      const { data } = res;

      if (res.code !== 200) {
        message.error(res?.message);
        return;
      }

      if (!data) return;
      const deleteCount = ids.length;
      const { successCount, failCount, failDetails } = data;

      if (deleteCount === successCount) {
        if (typeof onSuccess === 'function') {
          await pauseSomeTime(DELAY_GET_LIST_MS_GAP);
          message.success('删除成功');
          onSuccess();
        }
      } else if (deleteCount === 1) {
        Modal.error({
          title: '删除失败！',
          content: failDetails[0].failReason,
        });
      } else {
        showErrorListMessage({
          failCount, // 成功数量
          successCount, // 失败数量
          title: '删除出现失败', // 弹窗标题
          data: _.map(failDetails, (item) => ({ ...item, key: item.id })), // 列表数据
          columns: [
            {
              title: '方案名称',
              dataIndex: 'name',
              render(text: any, field: any) {
                return <a onClick={() => toAlternativePlanDetail(field.id)}>{text}</a>;
              },
            },
            { title: '失败原因', dataIndex: 'failReason', width: 200 },
          ],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const config = {
    rowKey: 'id',
    columns: dataColumns,
    getOperationList: (record: TableRowType) => {
      return [
        {
          title: '查看',
          auth: authDict.alternativeplan_detail,
          onClick: () => toAlternativePlanDetail(record.id),
        },
        {
          title: '编辑',
          auth: authDict.alternativeplan_edit,
          onClick: () => toAlternativePlanEdit(record.id),
        },
        {
          title: '复制',
          auth: authDict.alternativeplan_add,
          onClick: () => toAlternativePlanCopy(record.id),
        },
        {
          title: '删除',
          auth: authDict.alternativeplan_remove,
          onClick: () => {
            BLDelConfirm(
              '删除后将无法恢复，确认删除？',
              delForm,
              () => {
                return handleDelete(_.compact([record.id]), refreshTable);
              },
              () => {},
              'reason',
            );
          },
        },
        {
          title: '操作记录',
          auth: authDict.alternativeplan_operrecord,
          onClick: () => {
            setDetailId(record.id ?? 0);
            setVisibleLog(true);
          },
        },
      ];
    },

    batchMenu: [
      {
        title: '删除',
        key: 'delete',
        auth: authDict.alternativeplan_remove,
        onClick: (value: any) => {
          return new Promise((resolve, reject) => {
            BLDelConfirm(
              '是否确认删除该方案？',
              delForm,
              () => {
                handleDelete(selectedRowKeys, () => {
                  resolve(true);
                  refreshTable();
                }).then();
              },
              () => {
                reject(false);
              },
            );
          });
        },
      },
    ],
    filterList: [
      {
        label: '替代方案编号/名称',
        name: 'quickSearch',
        type: fieldTypeList.text,
      },
    ].concat(
      dataColumns
        .filter((i) => i.isFilter)
        .map((columns: any): FilterItem => {
          return {
            label: columns.filterTitle || columns.title,
            name: columns.dataIndex,
            type: columns.type,
            ...columns,
          };
        }),
    ),
    formatDataToQuery: (param: any) => {
      const {
        creator, // 创建人清单
        operator, // 更新人清单
        quickSearch, // 快速搜索
        createdAt = [], // 创建时间
        updatedAt = [], // 更新时间
        ownedDepartmentId, // 所属部门
        materialCode, // 替代料 code
        materialAttributeIdList, //  属性列表
        materialCategory, // 分类列表
        ...rest
      } = param;
      const [createdStartAt, createdEndAt] = gcTime.formatTimeRangeUnix(createdAt);
      const [updatedStartAt, updatedEndAt] = gcTime.formatTimeRangeUnix(updatedAt);

      const options = {
        quickSearch,
        ownedDepartmentIds: isEmpty(ownedDepartmentId) ? null : _.map(ownedDepartmentId, 'value'),
        createdAtFrom: createdStartAt,
        createdAtTo: createdEndAt,
        updatedAtFrom: updatedStartAt,
        updatedAtTo: updatedEndAt,
        creatorIds: isEmpty(creator) ? null : _.map(creator, 'value'),
        operatorIds: isEmpty(operator) ? null : _.map(operator, 'value'),
        materialIds: isEmpty(materialCode) ? null : _.map(materialCode, 'value'),
        materialCategoryIds:
          Array.isArray(materialCategory) && materialCategory.length > 0
            ? [_.last(materialCategory)]
            : null,
        materialAttributes: formatMaterialAttributeToQuery(materialAttributeIdList),
        ...rest,
      };

      dispatch.excelImport.updateBusinessData({
        businessData: gcObject.filterEmptyProperty(options),
      });
      return gcObject.filterEmptyProperty(options);
    },
    formatDataToFormDisplay: (data: any) => {
      const { createdAt, updatedAt, ...rest } = data;

      return {
        ...rest,
        createdAt: createdAt && gcTime.formatRangeTimeToMoment(createdAt),
        updatedAt: updatedAt && gcTime.formatRangeTimeToMoment(updatedAt),
      };
    },
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        placeholder={'替代方案编号/名称'}
        useIndex={false}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey) => {
          setSelectRowKeys(selectKey);
        }}
        refreshMarker={refreshMarker}
        mainMenu={[
          {
            title: '导出',
            showExport: true,
            auth: authDict.alternativeplan_export,
            icon: <BlIcon type="icondaochu" />,
            onClick: () => {
              openModal({
                optType: 'export',
                businessType: BUSINESS_TYPE.alternativePlan,
              });
            },
          },
          {
            title: '新建替代方案',
            auth: authDict.alternativeplan_add,
            icon: <BlIcon type="iconxinjiantianjia" />,
            onClick: toAlternativePlanCreate,
            items: [
              {
                title: '导入',
                showExport: true,
                auth: authDict.alternativeplan_import,
                onClick: () => {
                  openModal({
                    optType: 'import',
                    businessType: BUSINESS_TYPE.alternativePlan,
                  });
                },
              },
            ],
          },
        ]}
        requestFn={fetchAltList}
        configcacheKey="alternativePlanModule"
        {...config}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={'AlternativePlan'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default AlternativePlanList;
