import { createModel } from '@rematch/core';
import _ from 'lodash';
import {
  fetchStandardBizObjectObjectList,
  FetchStandardBizObjectObjectListResponse,
} from 'src/api/ytt/metadata-domain';
import {
  fetchPrivilegeListEnableSignatureFunction,
  FetchPrivilegeListEnableSignatureFunctionResponse,
} from 'src/api/ytt/user-domain/privilege';
import { Mapping } from 'src/dict/types';
import { RootModel } from 'src/store/models/index';

export interface SignatureStateType {
  relatedObjectEnum: Mapping[];
  signatureFunctionEnum: Mapping[];
}

const defaultState: SignatureStateType = {
  relatedObjectEnum: [],
  signatureFunctionEnum: [],
};

export const signature = createModel<RootModel>()({
  state: defaultState,
  reducers: {
    setRelatedObjectEnum(state, payload) {
      return { ...state, relatedObjectEnum: payload };
    },
    setSignatureFunctionEnum(state, payload) {
      return { ...state, signatureFunctionEnum: payload };
    },
  },
  effects: (dispatch) => ({
    async getRelatedObject(): Promise<any> {
      try {
        const res: FetchStandardBizObjectObjectListResponse =
          await fetchStandardBizObjectObjectList();

        const objectList = _.get(res, 'data', []);

        dispatch({
          type: 'signature/setRelatedObjectEnum',
          payload: _.map(objectList, (item) => ({
            label: item.objectName,
            value: item.objectCode,
          })),
        });
      } catch (e) {
        // @ts-ignore
      }
    },
    async getSignatureFunction(): Promise<any> {
      try {
        const res: FetchPrivilegeListEnableSignatureFunctionResponse =
          await fetchPrivilegeListEnableSignatureFunction();

        const functionList = _.get(res, 'data', []);

        dispatch({
          type: 'signature/setSignatureFunctionEnum',
          payload: _.map(functionList, (item) => ({
            label: item.name,
            value: item.code,
          })),
        });
      } catch (e) {
        // @ts-ignore
      }
    },
  }),
});
