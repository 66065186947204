import { FetchTriggerActionCreateRequest } from 'src/api/ytt/trigger-domain/triggerAction';
import _ from 'lodash';
import { formatConditionGroups } from 'src/page/organization/triggerRule/utils/formatDataToRequest';
import {
  ActionValueTypeEnum,
  ActionTypeEnum,
  InstanceSourceTypeEnum,
} from 'src/dict/triggerAction';

type Option = {
  label: string;
  key: string | number;
  value: string;
  dataSource?: { fieldType: number };
};

type detailItem = {
  fieldId: Option;
  id?: number;
  seq: number;
  valueType: number;
  value: Option[] | string | number;
};

interface TriggerActionFormData
  extends Pick<
    FetchTriggerActionCreateRequest,
    'name' | 'id' | 'actionType' | 'code' | 'numberRuleId' | 'remark'
  > {
  objectCode: Option;
  triggerRuleId: Option;
  details: detailItem[];
  objectFieldPath: string[];
  triggerCondition: any;
  instanceSourceType: number;
}

let seq = 1;
const formatDetails = (detail: detailItem) => {
  const { value, fieldId, valueType, ...rest } = detail;
  const obj: any = {
    ...rest,
    seq: seq++,
    fieldId: JSON.parse(fieldId?.value)?.id,
    valueType,
  };

  const formatValue = (value: string | Option[] | number | Option, keyString: string) => {
    if (_.isNumber(value)) return [value];
    if (_.isEmpty(value)) return null;
    if (_.isString(value)) return [value];
    if (_.isArray(value)) {
      // 值类型选了「引用触发规则业务字段」or「字段引用」，值都是 FieldIdCascader 组件 即 Cascader 组件的值的类型
      if ([ActionValueTypeEnum.triggerRuleBiz, ActionValueTypeEnum.biz].includes(valueType)) {
        return [_.map(value as Option[], keyString).join(',')];
      }
      return _.map(value, keyString);
    }
    if (_.isObject(value)) {
      // 公式 编辑器
      if (valueType === ActionValueTypeEnum.formula) {
        return keyString === 'value' ? [_.get(value, 'formulaId')] : [_.get(value, 'formulaText')];
      }
      return [_.get(value, keyString)];
    }
    return [value];
  };

  // value 有可能没值，或者空数组
  obj.value = _.isNil(value) ? null : formatValue(value, 'value');
  obj.displayValues = _.isNil(value) ? null : formatValue(value, 'label');

  return obj;
};

type FormatDataToRequest = (
  data: TriggerActionFormData,
  id?: number,
) => FetchTriggerActionCreateRequest;
export const formatDataToRequest: FormatDataToRequest = (data, id) => {
  const {
    objectCode,
    triggerRuleId,
    details,
    numberRuleId,
    objectFieldPath,
    triggerCondition,
    actionType,
    instanceSourceType,
    ...restData
  } = data;
  const _triggerRuleId = JSON.parse(triggerRuleId.value);
  const _objectCode = JSON.parse(objectCode.value)?.objectCode;
  const _objectFieldPath = objectFieldPath && _.map(objectFieldPath, 'value');

  seq = 1;
  if (actionType === ActionTypeEnum.createRecord) {
    // 事件类型是"新建记录"
    return {
      ...restData,
      id,
      actionType,
      objectCode: _objectCode,
      triggerRuleId: Number(_triggerRuleId.ruleId ?? _triggerRuleId.value),
      details: details.map(formatDetails),
      numberRuleId: typeof numberRuleId === 'number' ? numberRuleId : undefined,
    };
  } else if (instanceSourceType === InstanceSourceTypeEnum.manualSelectBizObject) {
    // 事件类型是"更新记录"，更新对象来源是"手动选择业务对象"
    return {
      ...restData,
      id,
      actionType,
      objectCode: _objectCode,
      triggerRuleId: Number(_triggerRuleId.ruleId ?? _triggerRuleId.value),
      details: details.map(formatDetails),
      numberRuleId: typeof numberRuleId === 'number' ? numberRuleId : undefined,
      objectFieldPath: _objectFieldPath?.join('.'),
      instanceSourceType,
      instanceFilterCondition: {
        // 手动选择业务对象时，需要发送"记录筛选条件"
        type: triggerCondition.type,
        conditionGroups: formatConditionGroups(triggerCondition.conditionGroups),
      },
    };
  }
  // 事件类型是"更新记录"，更新对象来源是"触发规则业务对象"或"触发规则业务对象关联对象"
  return {
    ...restData,
    id,
    actionType,
    objectCode: _objectCode,
    triggerRuleId: Number(_triggerRuleId.ruleId ?? _triggerRuleId.value),
    details: details.map(formatDetails),
    numberRuleId: typeof numberRuleId === 'number' ? numberRuleId : undefined,
    objectFieldPath: _objectFieldPath?.join('.'),
    instanceSourceType,
    // 这里不用发送"记录筛选条件"了，因为使用的是触发规则中的，后台知道这个数据
  };
};
