/**
 * @description 电子签名枚举类
 * @author xuzhiyong
 */

/** 质量状态 */
export enum QC_STATUS {
  /** 合格 */
  QUALIFIED = 1,
  /** 让步合格 */
  CONCESSION_QUALIFIED,
  /** 待检 */
  WAITING_FOR_CHECK,
  /** 不合格 */
  FAULTY,
  /** 暂控 */
  TEMP_CONTROL,
}

/** 检验结论 */
export enum ResultOptionEnum {
  qualified = 1 /** 合格 */,
  concessionQualified /** 让步合格 */,
  pending /** 待检 */,
  unqualified /** 不合格 */,
}

export enum CheckTypeEnum {
  /** 入厂检 */
  inputFactoryQc = 1,
  /** 出厂检 */
  outputFactoryQc,
  /** 首检 */
  produceOriginQc,
  /** 生产检 */
  produceQc,
  /** 巡检 */
  inspectionQc,
  /** 通用检 */
  generalQc,
}

export enum CheckEntityTypeEnum {
  /** 和抽样的库存明细个数一致 */
  SameAsBatchCount = 1,
  /** 和抽样量一致 */
  SameAsSample,
  /** 固定页数 */
  Custom,
  /** 执行时自行添加 */
  useExecuteAdd,
  /** 按检验项分别配置 */
  useCheckItemConfig,
}

/** 执行页填写方式 */
export enum ReportPageTypeEnum {
  /** 按检验单填写 */
  pageByReport = 1,
  /** 按检验项填写 */
  pageByCheckItem,
}

/** 检验项格式  */
export enum CheckItemInputTypeEnum {
  inputFormatNum = 1, // 数值
  inputFormatText, // 文本
  inputFormatPercent, // 百分比
  inputFormatSingle, // 单选项
  inputFormatMultiple, // 多选项
  inputFormatRecordDefectCount, // 记录次品数
  multipleText, // 多行文本
}

/** 检验项实测值格式 */
export enum CheckValueTypeEnum {
  /** 单一数值 */
  singleNumber = 1,
  /** 数值区间 */
  between,
}

/** 检验项抽检类型 */
export enum CheckCountTypeEnum {
  /** 无 */
  none = 0,
  /** 比例抽检 */
  ratioCheck,
  /** 固定抽检 */
  customCheck,
  /** 国标AQL */
  aqlCheck,
  /** 自定义QL */
  customAqlCheck,
  /** 执行时自行设置 */
  useExecuteSet,
}

/** 样本记录填写格式 */
export enum ReportFormatTypeEnum {
  /** 每个库存明细分别记录 */
  materialUnit = 1,
  /** 根据抽样总量记录 */
  SampleCount,
}

/** 是否记录样本 */
export enum RecordSampleEnum {
  /** 记录 */
  record = 1,
  /** 不记录 */
  notRecord,
}

/** 样本处理方式 */
export enum SampleProcessEnum {
  /** 放回 */
  restore = 1,
  /** 废弃 */
  scrap,
}

/** 附加结论 */
export enum ExtraResultenum {
  /** 样本平均值 */
  averageSample = 1,
  /** 样本平均值偏差 */
  standardDeviationSample,
}

/** 检验项格式文本类型 */
export enum TextInputEnum {
  /** 单行文本 */
  singleText = 2,
  /** 多行文本 */
  multipleText = 7,
}

/** 检验项是否必填 */
export enum RecordCheckItemTypeEnum {
  yes = 1,
  no,
}

/** Aql类型 */
export enum AqlTypeEnum {
  /** 正常 */
  normal = 1,
  /** 加严 */
  tightPlus,
  /** 放宽 */
  widenPlus,
}

/** 入库自动触发触发类型 */
export enum inputFactoryAutoTriggerEnum {
  /** 销售入库 */
  purchaseInbound = 1,
  /** 退货入库 */
  returnInbound,
  /** 其他入库 */
  otherInbound,
}

/** 出库自动触发触发类型 */
export enum outputFactoryAutoTriggerEnum {
  /** 销售出库 */
  salesOutbound = 4,
  /** 退厂出库 */
  backFactoryOutbound,
  /** 其他出库 */
  otherOutbound,
}

/** 自动触发触发方式 */
export enum TriggerValueEnum {
  /** 入库 */
  inbound = 1,
  /** 出库 */
  outbound,
  /** 生产工单 */
  reportProduction,
}

/** 总体处理方式 */
export enum MaterialProcessEnum {
  /** 不记录 */
  MATERIAL_NO_RECORD = 1,
  /** 仅记步更新质量状态 */
  MATERIAL_ONLY_RECORD,
  /** 记录且更新质量状态 */
  MATERIAL_RECORD_UPDATE_STATUS,
}

/** 单项判定 */
export enum SingleJudgmentEnum {
  /** 不合格 */
  unqualified,
  /** 合格 */
  qualified,
}

/** 检验任务状态 */
export enum QcTaskStatusEnum {
  /** 未开始 */
  unstarted = 0,
  /** 执行中 */
  executing,
  /** 已结束 */
  finished,
  /** 已取消 */
  cancled,
}

/** 检验任务领取状态 */
export enum AssignStatusEnum {
  /** 未领取 */
  unAssign = 0,
  /** 已领取 */
  assigned,
}

export enum isRecordCountSampleEnum {
  yes = 1,
  no,
}
/** 检验项总量抽样量是否必填 */
export enum isRecordCountSampleEnum {
  yes = 1,
  no = 2,
}

export enum PerformTaskTab {
  MATERIAL_INFO = 1, // 子项物料
  QS_RECORD, // 产出物料
}

/** 检验项总量抽样量是否必填 */
export enum CategoryEnum {
  OVERALL = 1,
  SAMPLE = 2,
}

/** 轮询任务上库存添加进度 */
export enum ProgressEnum {
  unstarted = 1,
  executing = 2,
  finished = 3,
  fail = 4,
}

/** 检验任务添加库存 */
export enum BindMaterialResEnum {
  success = 1,
  fail = 2,
  partSucess = 3,
}

/** 检验任务创建方式 */
export enum CreateTypeEnum {
  handle = 1,
  auto,
  api,
}
