import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import styles from './styles.module.scss';
import UserInfo from './userInfo';
import Password from './password';
import BindPhone from './bindPhone';
import { fetchUserDetail } from 'src/api/ytt/user-domain';
import { UserInfoContext } from './constant';
import { useDispatch } from 'react-redux';

const { TabPane } = Tabs;

export default function UserSettings() {
  const [activeKey, setActiveKey] = useState('1');
  const [dataSource, setDataSource] = useState({} as any);
  const [refreshMarker, setRefreshMarker] = useState<number>(0);
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const { data } = await fetchUserDetail();

      setDataSource(data);

      dispatch({
        type: 'user/setUserInfo',
        payload: data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [refreshMarker]);

  const onChange = (active: string) => {
    setActiveKey(active);
  };

  return (
    <UserInfoContext.Provider
      value={{
        dataSource,
        refresh: () => {
          setRefreshMarker(Date.now());
        },
        refreshMarker,
      }}
    >
      <div className={styles?.parcel}>
        <Tabs
          tabPosition={'left'}
          className={styles?.tabs}
          activeKey={activeKey}
          onChange={onChange}
        >
          <TabPane tab="个人信息" key="1">
            <UserInfo onChangeActive={onChange} />
          </TabPane>
          <TabPane tab="修改密码" key="2">
            <Password />
          </TabPane>
          <TabPane tab="绑定手机" key="3">
            <BindPhone />
          </TabPane>
        </Tabs>
      </div>
    </UserInfoContext.Provider>
  );
}
