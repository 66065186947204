/**
 * @component 可配置选择上限的选择器
 */
import { FC, useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import _ from 'lodash';

interface Props extends SelectProps<any> {
  /** 最大选择个数 */
  maxCount?: number;
  /** 选项, 必传 */
  options: SelectProps<any>['options'];
}

const LimitableSelect: FC<Props> = (props) => {
  const { options, maxCount, mode, value } = props;
  const [opts, setOpts] = useState(options);

  useEffect(() => {
    if (mode === 'multiple' && _.isArray(value) && _.isNumber(maxCount) && maxCount > 0) {
      if (value.length >= maxCount) {
        setOpts(options?.map((item) => ({ ...item, disabled: !value.includes(item.value) })));
      } else if (opts?.some((item) => item.disabled)) {
        setOpts(options);
      }
    }
  }, [value, options, maxCount, mode]);

  return <Select {..._.omit(props, 'maxCount')} options={opts} />;
};

export default LimitableSelect;
