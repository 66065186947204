/**
 * 预览区底部可拖拽添加控件的区域
 */
import { FC } from 'react';
import { useDrop } from 'react-dnd';
import { Space } from 'antd';
import { BlIcon } from 'src/components';
import { warningColor } from 'src/components/styles/color';
import { ControlType } from 'src/dict/sop';
import { DragItemTypes, DragItem } from '../../types';

interface Props {
  addControl: (ctrlType: ControlType, index: number) => void;
  controlCount: number;
}

const Empty: FC<Props> = ({ addControl, controlCount }) => {
  const [{ isOver }, drop] = useDrop({
    accept: [DragItemTypes.cover],
    collect(monitor) {
      return { isOver: monitor.isOver() };
    },
    drop(item: DragItem) {
      addControl(item.controlType, controlCount);
    },
  });
  const iconType = isOver ? 'icontianjia' : 'icontixing-jingshi';
  const text = isOver ? '添加第一个控件' : '暂无控件';

  return (
    <div
      ref={drop}
      style={{
        flex: '1 1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 18,
        background: isOver ? 'rgba(0,0,0,.05)' : 'none',
      }}
    >
      {controlCount === 0 && (
        <Space>
          <BlIcon type={iconType} style={{ color: warningColor }} />
          <span>{text}</span>
        </Space>
      )}
    </div>
  );
};

export default Empty;
