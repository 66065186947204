import { ReactNode, useEffect, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Form, FormInstance, Input } from 'antd';
import { PurchaseExecStatus } from 'src/dict/purchase';
import EditableText from 'src/components/editableText';
import {
  numberAlphabetSpecialSymbols,
  withoutSpaceBothSides,
  textValidator1,
} from 'src/utils/formValidators';
import MaterialTable from './materialTable';
import moment from 'moment';
import lookup from 'src/dict';
import { replaceSign } from 'src/utils/constants';
import _ from 'lodash';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { NumberRulesStandardHook } from 'src/components';

const { TextArea } = Input;

interface baseInfoProps {
  onCancel: () => void;
  onFinish: () => void;
  title: string;
  loading: boolean;
  initialData?: any;
  extra?: ReactNode;
  edit: boolean;
  form: FormInstance;
  refreshMarker?: number;
}

const CreateAndEditBaseInfo = (props: baseInfoProps) => {
  const { onCancel, title, onFinish, initialData, form, extra, edit, loading, refreshMarker } =
    props;
  const [orderStatus, setOrderStatus] = useState<number>(0);

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 3,
    items: [
      ...NumberRulesStandardHook({
        label: '编号',
        name: 'code',
        form,
        edit,
        objectCode: OBJECT_OF_CODE.purchaseReturnOrder,
        fieldCode: 'code',
        rules: [
          numberAlphabetSpecialSymbols,
          withoutSpaceBothSides,
          { max: 255, message: '不可超过255个字符' },
        ],
        refreshMarker,
        disabledState: edit && orderStatus === PurchaseExecStatus.executing,
      }),
      {
        dependencies: ['itemList'],
        noStyle: true,
        render: (FormItemProps) => () => {
          const value = form.getFieldValue('source');

          return (
            <Form.Item label={'来源'} name={'source'} {...FormItemProps}>
              {_.isUndefined(value) ? replaceSign : lookup('purchase.purchaseReturnSource', value)}
            </Form.Item>
          );
        },
      },
      {
        dependencies: ['itemList'],
        noStyle: true,
        render: (FormItemProps) => () => {
          const value = form.getFieldValue('supplier');

          return (
            <Form.Item label={'供应商'} name={'supplier'} {...FormItemProps}>
              {_.isUndefined(value) ? replaceSign : <EditableText canEdit={false} />}
            </Form.Item>
          );
        },
      },
      {
        label: '物料',
        isFullLine: true,
        required: true,
        render: () => (
          <MaterialTable
            form={form}
            name="itemList"
            orderStatus={orderStatus}
            initialData={initialData?.items}
            edit={edit}
            style={{ width: '100%' }}
          />
        ),
      },
      {
        label: '订单备注',
        name: 'remark',
        rules: [
          { max: 1000, message: '不超过1000个字符' },
          { validator: textValidator1 },
          withoutSpaceBothSides,
        ],
        render: () => <TextArea showCount maxLength={1000} placeholder="非必填" />,
      },
      {
        label: '编辑原因',
        name: 'editReason',
        hidden: !edit,
        rules: [
          { max: 255, message: '不超过255个字符' },
          { validator: textValidator1 },
          withoutSpaceBothSides,
        ],
        render: () => <TextArea showCount maxLength={255} placeholder="非必填" />,
      },
    ],
  };

  useEffect(() => {
    if (initialData) {
      form?.setFieldsValue({
        id: initialData?.id, // 业务id
        code: initialData?.code,
        source: initialData?.source,
        supplier: `${initialData?.supplier?.code} / ${initialData?.supplier?.name}`,
        itemList: initialData?.itemList?.map((value: any) => {
          // 此处id为采购退货单物料行id
          const {
            id,
            seqNo,
            material,
            returnAmount,
            purchaseOrderCode,
            purchaseOrderSeqNo,
            purchaseOrderItemId,
            returnTime,
          } = value;

          return {
            id,
            seqNo,
            material,
            returnAmount: returnAmount?.amount,
            unitName: returnAmount?.unitName,
            unitId: returnAmount?.unitId,
            purchaseOrderCode,
            supplier: initialData?.supplier,
            seqNum: purchaseOrderSeqNo,
            purchaseOrderItemId,
            returnTime: moment(returnTime),
            source: initialData?.source,
          };
        }),
        remark: initialData?.remark,
      });
      setOrderStatus(initialData?.execStatus);
    }
  }, [initialData]);

  return (
    <DataFormLayout
      form={form}
      title={title}
      info={[baseInfo]}
      onCancel={onCancel}
      onFinish={onFinish}
      extra={extra}
      loading={loading}
    />
  );
};

export default CreateAndEditBaseInfo;
