import { useState } from 'react';
import { RecordListLayout } from 'layout';
import { approvalFilterList, dataColumnsForApprovalList } from './columns';
import { fetchApprovalTaskListHandled } from 'src/api/ytt/metadata-domain/approvalTask';
import { formatDateTimeRangeToUnix } from 'src/utils/formatters/dateTime';
import { gcObject } from 'utils';
import { useHistory } from 'react-router-dom';
import authDict from 'src/utils/auth';
import { ApprovalListItemProps } from './constant';
import _ from 'lodash';
import { BlTooltip, SingleOperationLogComponent } from 'src/components';
import { toTaskDetail } from './navigate';
import _Array from 'src/utils/dataTypes/array';
import _Url from 'src/utils/url';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';

const PendingList = () => {
  const history = useHistory();
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [id, setId] = useState<number>(0);
  const refresh = () => setRefreshMarker(Date.now());

  const query = _Url.getParams();
  const { tabKey } = query;

  const dataColumns = [
    {
      title: '编号',
      dataIndex: 'code',
      sorter: true,
      width: 150,
      render: (code: string, record: ApprovalListItemProps, index: number, config: any) => {
        return (
          <a
            onClick={() => {
              history.push(toTaskDetail(record.id!));
            }}
          >
            <BlTooltip text={code} width={config.contentWidth} />
          </a>
        );
      },
    },
    ...dataColumnsForApprovalList(tabKey).filter(
      (e: any) => e?.title !== '更新人' && e?.title !== '更新时间',
    ),
  ];

  const getOperationList = (record: ApprovalListItemProps) => {
    return [
      {
        title: '查看',
        onClick: () => {
          history.push(toTaskDetail(record.id!));
        },
      },
      {
        title: '操作记录',
        auth: authDict.approvalTask_operrecord,
        onClick: () => {
          setId(record.id ?? -1);
          setVisibleLog(true);
        },
      },
    ];
  };

  const formatDataToQuery = (params: any) => {
    const { createdAt, creator, approvalCode, ...rest } = params || {};
    const options = {
      ...formatDateTimeRangeToUnix('createdAtBegin', 'createdAtEnd', createdAt),
      creatorId: _.isEmpty(creator) ? null : _.map(creator, 'value'),
      approvalCode: _Array.isEmpty(approvalCode) ? null : _.map(approvalCode, 'value'),
      ...rest,
    };

    return gcObject.filterEmptyProperty(options);
  };

  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (data: any) => {
    const { createdAt } = data;

    const retData = { ...data };

    if (createdAt) {
      retData.createdAt = momentData(createdAt);
    }

    return retData;
  };

  return (
    <>
      <RecordListLayout<ApprovalListItemProps>
        columns={dataColumns}
        filterList={approvalFilterList(tabKey).filter(
          (e: any) => e?.name !== 'operator' && e?.name !== 'updatedAt',
        )}
        rowKey={'id'}
        useIndex={false}
        configcacheKey={'approvalTaskHandled'}
        getOperationList={getOperationList}
        formatDataToQuery={formatDataToQuery}
        requestFn={fetchApprovalTaskListHandled}
        refreshMarker={refreshMarker}
        placeholder={'请输入编号、名称'}
        formatDataToFormDisplay={formatDataToFormDisplay}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          closeDetail={() => {
            setVisibleLog(false);
          }}
          visible={visibleLog}
          instanceId={id}
          objectCode={'ApprovalTask'}
        />
      )}
    </>
  );
};

export default PendingList;
