/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../../request';

// makeRequest
function makeRequestRequired<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequestOptional<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData?: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequest<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const optional = makeRequestOptional<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  const required = makeRequestRequired<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  return (
    requestConfig.requestDataOptional ? optional : required
  ) as TRequestConfig['requestDataOptional'] extends true ? typeof optional : typeof required;
}

// Request
export type Request<TReqeustData, TRequestConfig extends RequestConfig, TRequestResult> =
  (TRequestConfig['requestDataOptional'] extends true
    ? (
        requestData?: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult
    : (
        requestData: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult) & {
    requestConfig: TRequestConfig;
  };

const mockUrl_0_0_1_0 = 'http://yapi.blacklake.tech/mock/735' as any;
const devUrl_0_0_1_0 = '' as any;
const prodUrl_0_0_1_0 = '' as any;
const dataKey_0_0_1_0 = undefined as any;

/**
 * 接口 [接受质量限列表↗](http://yapi.blacklake.tech/project/735/interface/api/79754) 的 **请求类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/accept_limit_list`
 * @更新时间 `2021-12-20 14:23:57`
 */
export interface FetchCustomAqlAcceptLimitListRequest {
  /**
   * 自定义aql检验水平id
   */
  id?: number;
}

/**
 * 接口 [接受质量限列表↗](http://yapi.blacklake.tech/project/735/interface/api/79754) 的 **返回类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/accept_limit_list`
 * @更新时间 `2021-12-20 14:23:57`
 */
export interface FetchCustomAqlAcceptLimitListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [接受质量限列表↗](http://yapi.blacklake.tech/project/735/interface/api/79754) 的 **请求配置的类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/accept_limit_list`
 * @更新时间 `2021-12-20 14:23:57`
 */
type FetchCustomAqlAcceptLimitListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/custom_aql/accept_limit_list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [接受质量限列表↗](http://yapi.blacklake.tech/project/735/interface/api/79754) 的 **请求配置**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/accept_limit_list`
 * @更新时间 `2021-12-20 14:23:57`
 */
const fetchCustomAqlAcceptLimitListRequestConfig: FetchCustomAqlAcceptLimitListRequestConfig = {
  mockUrl: mockUrl_0_0_1_0,
  devUrl: devUrl_0_0_1_0,
  prodUrl: prodUrl_0_0_1_0,
  path: '/quality/domain/web/v1/custom_aql/accept_limit_list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_1_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchCustomAqlAcceptLimitList',
};

/**
 * 接口 [接受质量限列表↗](http://yapi.blacklake.tech/project/735/interface/api/79754) 的 **请求函数**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/accept_limit_list`
 * @更新时间 `2021-12-20 14:23:57`
 */
export const fetchCustomAqlAcceptLimitList = makeRequest<
  FetchCustomAqlAcceptLimitListRequest,
  FetchCustomAqlAcceptLimitListResponse,
  FetchCustomAqlAcceptLimitListRequestConfig
>(fetchCustomAqlAcceptLimitListRequestConfig);

/**
 * 接口 [新建自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79760) 的 **请求类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/create`
 * @更新时间 `2021-12-20 14:23:57`
 */
export interface FetchCustomAqlCreateRequest {
  /**
   * 接受质量限列表
   */
  acceptLimitList: {
    /**
     * 接受质量限明细
     */
    limit: {
      /**
       * 接收数
       */
      acceptCount: number;
      /**
       * 拒收数
       */
      rejectCount: number;
      /**
       * 样本量
       */
      sampleCount: number;
    }[];
    /**
     * 接受质量限名称
     */
    name: string;
  }[];
  /**
   * 名称
   */
  name: string;
  /**
   * 批量修约规则（1:向上圆整，2:向下圆整）
   */
  roundRule: number;
  /**
   * 样本量列表
   */
  sampleList: {
    /**
     * 最大批量
     */
    maxBatch: number;
    /**
     * 最小批量
     */
    minBatch: number;
    /**
     * 样本量
     */
    sampleCount: number;
  }[];
}

/**
 * 接口 [新建自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79760) 的 **返回类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/create`
 * @更新时间 `2021-12-20 14:23:57`
 */
export interface FetchCustomAqlCreateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: number;
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [新建自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79760) 的 **请求配置的类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/create`
 * @更新时间 `2021-12-20 14:23:57`
 */
type FetchCustomAqlCreateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/custom_aql/create',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [新建自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79760) 的 **请求配置**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/create`
 * @更新时间 `2021-12-20 14:23:57`
 */
const fetchCustomAqlCreateRequestConfig: FetchCustomAqlCreateRequestConfig = {
  mockUrl: mockUrl_0_0_1_0,
  devUrl: devUrl_0_0_1_0,
  prodUrl: prodUrl_0_0_1_0,
  path: '/quality/domain/web/v1/custom_aql/create',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_1_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchCustomAqlCreate',
};

/**
 * 接口 [新建自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79760) 的 **请求函数**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/create`
 * @更新时间 `2021-12-20 14:23:57`
 */
export const fetchCustomAqlCreate = makeRequest<
  FetchCustomAqlCreateRequest,
  FetchCustomAqlCreateResponse,
  FetchCustomAqlCreateRequestConfig
>(fetchCustomAqlCreateRequestConfig);

/**
 * 接口 [删除自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79766) 的 **请求类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/delete`
 * @更新时间 `2021-12-20 14:23:58`
 */
export interface FetchCustomAqlDeleteRequest {
  /**
   * id集合
   */
  ids?: number[];
  /**
   * 删除原因
   */
  reason?: string;
}

/**
 * 接口 [删除自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79766) 的 **返回类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/delete`
 * @更新时间 `2021-12-20 14:23:58`
 */
export interface FetchCustomAqlDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 结果标识符 1成功 2失败 3出现失败(批量删除)
     */
    code?: number;
    /**
     * 失败数量
     */
    failedCount?: number;
    /**
     * 删除失败明细
     */
    failedList?: {
      /**
       * 自定义aql删除失败明细-id
       */
      id?: number;
      /**
       * 自定义aql删除失败明细-名称
       */
      name?: string;
      /**
       * 失败原因
       */
      reason?: string;
    }[];
    /**
     * 删除最终结果
     */
    result?: string;
    /**
     * 成功数量
     */
    successCount?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [删除自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79766) 的 **请求配置的类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/delete`
 * @更新时间 `2021-12-20 14:23:58`
 */
type FetchCustomAqlDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/custom_aql/delete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [删除自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79766) 的 **请求配置**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/delete`
 * @更新时间 `2021-12-20 14:23:58`
 */
const fetchCustomAqlDeleteRequestConfig: FetchCustomAqlDeleteRequestConfig = {
  mockUrl: mockUrl_0_0_1_0,
  devUrl: devUrl_0_0_1_0,
  prodUrl: prodUrl_0_0_1_0,
  path: '/quality/domain/web/v1/custom_aql/delete',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_1_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchCustomAqlDelete',
};

/**
 * 接口 [删除自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79766) 的 **请求函数**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/delete`
 * @更新时间 `2021-12-20 14:23:58`
 */
export const fetchCustomAqlDelete = makeRequest<
  FetchCustomAqlDeleteRequest,
  FetchCustomAqlDeleteResponse,
  FetchCustomAqlDeleteRequestConfig
>(fetchCustomAqlDeleteRequestConfig);

/**
 * 接口 [自定义aql详情↗](http://yapi.blacklake.tech/project/735/interface/api/79772) 的 **请求类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/detail`
 * @更新时间 `2021-12-20 14:23:58`
 */
export interface FetchCustomAqlDetailRequest {
  /**
   * id
   */
  id?: number;
}

/**
 * 接口 [自定义aql详情↗](http://yapi.blacklake.tech/project/735/interface/api/79772) 的 **返回类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/detail`
 * @更新时间 `2021-12-20 14:23:58`
 */
export interface FetchCustomAqlDetailResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 接受质量限列表
     */
    acceptLimitList?: {
      /**
       * 接受质量限行列表
       */
      acceptLimitDetail?: {
        /**
         * 接收数
         */
        acceptCount?: number;
        /**
         * id
         */
        id?: number;
        /**
         * 拒收数
         */
        rejectCount?: number;
        /**
         * 样本量id
         */
        sampleId?: number;
      }[];
      /**
       * id
       */
      id?: number;
      /**
       * 接受质量限名称
       */
      name?: string;
    }[];
    /**
     * 创建时间
     */
    createdAt?: string;
    creator?: {
      /**
       * web-User信息VO-头像
       */
      avatarUrl?: string;
      /**
       * web-User信息VO-编号
       */
      code?: string;
      /**
       * web-User信息VO-描述
       */
      desc?: string;
      /**
       * web-User信息VO-id
       */
      id?: number;
      /**
       * web-User信息VO-name
       */
      name?: string;
      /**
       * web-User信息VO-username
       */
      username?: string;
    };
    /**
     * id
     */
    id?: number;
    /**
     * 自定义aql名称
     */
    name?: string;
    operator?: {
      /**
       * web-User信息VO-头像
       */
      avatarUrl?: string;
      /**
       * web-User信息VO-编号
       */
      code?: string;
      /**
       * web-User信息VO-描述
       */
      desc?: string;
      /**
       * web-User信息VO-id
       */
      id?: number;
      /**
       * web-User信息VO-name
       */
      name?: string;
      /**
       * web-User信息VO-username
       */
      username?: string;
    };
    /**
     * 修约规则（1:向上圆整，2:向下圆整）
     */
    roundRule?: number;
    /**
     * 样本量列表
     */
    sampleList?: {
      /**
       * id
       */
      id?: number;
      /**
       * 最大批量
       */
      maxBatch: number;
      /**
       * 最小批量
       */
      minBatch: number;
      /**
       * 样本量
       */
      sampleCount: number;
    }[];
    /**
     * 状态（1：启用，0：停用）
     */
    state?: number;
    /**
     * 修改时间
     */
    updatedAt?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [自定义aql详情↗](http://yapi.blacklake.tech/project/735/interface/api/79772) 的 **请求配置的类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/detail`
 * @更新时间 `2021-12-20 14:23:58`
 */
type FetchCustomAqlDetailRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/custom_aql/detail',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [自定义aql详情↗](http://yapi.blacklake.tech/project/735/interface/api/79772) 的 **请求配置**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/detail`
 * @更新时间 `2021-12-20 14:23:58`
 */
const fetchCustomAqlDetailRequestConfig: FetchCustomAqlDetailRequestConfig = {
  mockUrl: mockUrl_0_0_1_0,
  devUrl: devUrl_0_0_1_0,
  prodUrl: prodUrl_0_0_1_0,
  path: '/quality/domain/web/v1/custom_aql/detail',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_1_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchCustomAqlDetail',
};

/**
 * 接口 [自定义aql详情↗](http://yapi.blacklake.tech/project/735/interface/api/79772) 的 **请求函数**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/detail`
 * @更新时间 `2021-12-20 14:23:58`
 */
export const fetchCustomAqlDetail = makeRequest<
  FetchCustomAqlDetailRequest,
  FetchCustomAqlDetailResponse,
  FetchCustomAqlDetailRequestConfig
>(fetchCustomAqlDetailRequestConfig);

/**
 * 接口 [停用自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79778) 的 **请求类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/disable`
 * @更新时间 `2021-12-20 14:23:58`
 */
export interface FetchCustomAqlDisableRequest {
  /**
   * id
   */
  id?: number;
}

/**
 * 接口 [停用自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79778) 的 **返回类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/disable`
 * @更新时间 `2021-12-20 14:23:58`
 */
export interface FetchCustomAqlDisableResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [停用自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79778) 的 **请求配置的类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/disable`
 * @更新时间 `2021-12-20 14:23:58`
 */
type FetchCustomAqlDisableRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/custom_aql/disable',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [停用自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79778) 的 **请求配置**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/disable`
 * @更新时间 `2021-12-20 14:23:58`
 */
const fetchCustomAqlDisableRequestConfig: FetchCustomAqlDisableRequestConfig = {
  mockUrl: mockUrl_0_0_1_0,
  devUrl: devUrl_0_0_1_0,
  prodUrl: prodUrl_0_0_1_0,
  path: '/quality/domain/web/v1/custom_aql/disable',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_1_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchCustomAqlDisable',
};

/**
 * 接口 [停用自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79778) 的 **请求函数**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/disable`
 * @更新时间 `2021-12-20 14:23:58`
 */
export const fetchCustomAqlDisable = makeRequest<
  FetchCustomAqlDisableRequest,
  FetchCustomAqlDisableResponse,
  FetchCustomAqlDisableRequestConfig
>(fetchCustomAqlDisableRequestConfig);

/**
 * 接口 [启用自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79784) 的 **请求类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/enable`
 * @更新时间 `2021-12-20 14:23:58`
 */
export interface FetchCustomAqlEnableRequest {
  /**
   * id
   */
  id?: number;
}

/**
 * 接口 [启用自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79784) 的 **返回类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/enable`
 * @更新时间 `2021-12-20 14:23:58`
 */
export interface FetchCustomAqlEnableResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [启用自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79784) 的 **请求配置的类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/enable`
 * @更新时间 `2021-12-20 14:23:58`
 */
type FetchCustomAqlEnableRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/custom_aql/enable',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [启用自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79784) 的 **请求配置**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/enable`
 * @更新时间 `2021-12-20 14:23:58`
 */
const fetchCustomAqlEnableRequestConfig: FetchCustomAqlEnableRequestConfig = {
  mockUrl: mockUrl_0_0_1_0,
  devUrl: devUrl_0_0_1_0,
  prodUrl: prodUrl_0_0_1_0,
  path: '/quality/domain/web/v1/custom_aql/enable',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_1_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchCustomAqlEnable',
};

/**
 * 接口 [启用自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79784) 的 **请求函数**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/enable`
 * @更新时间 `2021-12-20 14:23:58`
 */
export const fetchCustomAqlEnable = makeRequest<
  FetchCustomAqlEnableRequest,
  FetchCustomAqlEnableResponse,
  FetchCustomAqlEnableRequestConfig
>(fetchCustomAqlEnableRequestConfig);

/**
 * 接口 [检验水平列表↗](http://yapi.blacklake.tech/project/735/interface/api/79790) 的 **请求类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/inspection_level_list`
 * @更新时间 `2021-12-20 14:23:58`
 */
export interface FetchCustomAqlInspectionLevelListRequest {}

/**
 * 接口 [检验水平列表↗](http://yapi.blacklake.tech/project/735/interface/api/79790) 的 **返回类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/inspection_level_list`
 * @更新时间 `2021-12-20 14:23:58`
 */
export interface FetchCustomAqlInspectionLevelListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [检验水平列表↗](http://yapi.blacklake.tech/project/735/interface/api/79790) 的 **请求配置的类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/inspection_level_list`
 * @更新时间 `2021-12-20 14:23:58`
 */
type FetchCustomAqlInspectionLevelListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/custom_aql/inspection_level_list',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [检验水平列表↗](http://yapi.blacklake.tech/project/735/interface/api/79790) 的 **请求配置**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/inspection_level_list`
 * @更新时间 `2021-12-20 14:23:58`
 */
const fetchCustomAqlInspectionLevelListRequestConfig: FetchCustomAqlInspectionLevelListRequestConfig =
  {
    mockUrl: mockUrl_0_0_1_0,
    devUrl: devUrl_0_0_1_0,
    prodUrl: prodUrl_0_0_1_0,
    path: '/quality/domain/web/v1/custom_aql/inspection_level_list',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_0_1_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: true,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchCustomAqlInspectionLevelList',
  };

/**
 * 接口 [检验水平列表↗](http://yapi.blacklake.tech/project/735/interface/api/79790) 的 **请求函数**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/inspection_level_list`
 * @更新时间 `2021-12-20 14:23:58`
 */
export const fetchCustomAqlInspectionLevelList = makeRequest<
  FetchCustomAqlInspectionLevelListRequest,
  FetchCustomAqlInspectionLevelListResponse,
  FetchCustomAqlInspectionLevelListRequestConfig
>(fetchCustomAqlInspectionLevelListRequestConfig);

/**
 * 接口 [自定义aql列表↗](http://yapi.blacklake.tech/project/735/interface/api/79796) 的 **请求类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/list`
 * @更新时间 `2021-12-20 14:23:58`
 */
export interface FetchCustomAqlListRequest {
  /**
   * 创建时间-开始
   */
  createdAtFrom?: string;
  /**
   * 创建时间-结束
   */
  createdAtTo?: string;
  /**
   * 创建人搜索(废弃)
   */
  creatorIds?: number[];
  /**
   * 创建人名称模糊搜索
   */
  creatorName?: string;
  /**
   * 名称搜索
   */
  name?: string;
  /**
   * 排序规则（asc正序，desc倒叙）
   */
  orderDirection?: string;
  /**
   * 请求页
   */
  page?: number;
  /**
   * 筛选标识 1 全选 0 不全选 默认为 0
   */
  selectFlag?: number;
  /**
   * 每页大小
   */
  size?: number;
  /**
   * 排序 列表顺序表示排序顺序
   */
  sorter?: {
    /**
     * 排序字段
     */
    field?: string;
    /**
     * 排序规律 默认 asc，asc 升序 desc 降序
     */
    order?: string;
  }[];
  /**
   * 状态
   */
  state?: number;
  /**
   * 更新时间-开始
   */
  updatedAtFrom?: string;
  /**
   * 更新时间-结束
   */
  updatedAtTo?: string;
}

/**
 * 接口 [自定义aql列表↗](http://yapi.blacklake.tech/project/735/interface/api/79796) 的 **返回类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/list`
 * @更新时间 `2021-12-20 14:23:58`
 */
export interface FetchCustomAqlListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * 创建时间
       */
      createdAt?: string;
      creator?: {
        /**
         * web-User信息VO-头像
         */
        avatarUrl?: string;
        /**
         * web-User信息VO-编号
         */
        code?: string;
        /**
         * web-User信息VO-描述
         */
        desc?: string;
        /**
         * web-User信息VO-id
         */
        id?: number;
        /**
         * web-User信息VO-name
         */
        name?: string;
        /**
         * web-User信息VO-username
         */
        username?: string;
      };
      /**
       * id
       */
      id?: number;
      /**
       * 自定义aql名称
       */
      name?: string;
      operator?: {
        /**
         * web-User信息VO-头像
         */
        avatarUrl?: string;
        /**
         * web-User信息VO-编号
         */
        code?: string;
        /**
         * web-User信息VO-描述
         */
        desc?: string;
        /**
         * web-User信息VO-id
         */
        id?: number;
        /**
         * web-User信息VO-name
         */
        name?: string;
        /**
         * web-User信息VO-username
         */
        username?: string;
      };
      /**
       * 修约规则（1:向上圆整，2:向下圆整）
       */
      roundRule?: number;
      /**
       * 状态（1：启用，0：停用）
       */
      state?: number;
      /**
       * 修改时间
       */
      updatedAt?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [自定义aql列表↗](http://yapi.blacklake.tech/project/735/interface/api/79796) 的 **请求配置的类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/list`
 * @更新时间 `2021-12-20 14:23:58`
 */
type FetchCustomAqlListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/custom_aql/list',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [自定义aql列表↗](http://yapi.blacklake.tech/project/735/interface/api/79796) 的 **请求配置**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/list`
 * @更新时间 `2021-12-20 14:23:58`
 */
const fetchCustomAqlListRequestConfig: FetchCustomAqlListRequestConfig = {
  mockUrl: mockUrl_0_0_1_0,
  devUrl: devUrl_0_0_1_0,
  prodUrl: prodUrl_0_0_1_0,
  path: '/quality/domain/web/v1/custom_aql/list',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_1_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchCustomAqlList',
};

/**
 * 接口 [自定义aql列表↗](http://yapi.blacklake.tech/project/735/interface/api/79796) 的 **请求函数**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/list`
 * @更新时间 `2021-12-20 14:23:58`
 */
export const fetchCustomAqlList = makeRequest<
  FetchCustomAqlListRequest,
  FetchCustomAqlListResponse,
  FetchCustomAqlListRequestConfig
>(fetchCustomAqlListRequestConfig);

/**
 * 接口 [修改自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79802) 的 **请求类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/update`
 * @更新时间 `2021-12-20 14:23:58`
 */
export interface FetchCustomAqlUpdateRequest {
  /**
   * 接受质量限列表
   */
  acceptLimitList: {
    /**
     * 接受质量限id(新增的质量限id为空)
     */
    id?: number;
    /**
     * 接受质量限明细
     */
    limit: {
      /**
       * 接收数
       */
      acceptCount: number;
      /**
       * 接受质量限行id(新增行的id为空)
       */
      id: number;
      /**
       * 拒收数
       */
      rejectCount: number;
      /**
       * 样本量
       */
      sampleCount: number;
    }[];
    /**
     * 接受质量限名称
     */
    name: string;
  }[];
  /**
   * id
   */
  id: number;
  /**
   * 名称
   */
  name: string;
  /**
   * 批量修约规则（1:向上圆整，2:向下圆整）
   */
  roundRule: number;
  /**
   * 样本量列表
   */
  sampleList: {
    /**
     * 样本量id(新增样本量时id为空)
     */
    id?: number;
    /**
     * 最大批量
     */
    maxBatch: number;
    /**
     * 最小批量
     */
    minBatch: number;
    /**
     * 样本量
     */
    sampleCount: number;
  }[];
}

/**
 * 接口 [修改自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79802) 的 **返回类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/update`
 * @更新时间 `2021-12-20 14:23:58`
 */
export interface FetchCustomAqlUpdateResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 接受质量限列表
     */
    acceptLimitList?: {
      /**
       * 接受质量限行列表
       */
      acceptLimitDetail?: {
        /**
         * 接收数
         */
        acceptCount?: number;
        /**
         * id
         */
        id?: number;
        /**
         * 拒收数
         */
        rejectCount?: number;
        /**
         * 样本量id
         */
        sampleId?: number;
      }[];
      /**
       * id
       */
      id?: number;
      /**
       * 接受质量限名称
       */
      name?: string;
    }[];
    /**
     * 创建时间
     */
    createdAt?: string;
    creator?: {
      /**
       * web-User信息VO-头像
       */
      avatarUrl?: string;
      /**
       * web-User信息VO-编号
       */
      code?: string;
      /**
       * web-User信息VO-描述
       */
      desc?: string;
      /**
       * web-User信息VO-id
       */
      id?: number;
      /**
       * web-User信息VO-name
       */
      name?: string;
      /**
       * web-User信息VO-username
       */
      username?: string;
    };
    /**
     * id
     */
    id?: number;
    /**
     * 自定义aql名称
     */
    name?: string;
    operator?: {
      /**
       * web-User信息VO-头像
       */
      avatarUrl?: string;
      /**
       * web-User信息VO-编号
       */
      code?: string;
      /**
       * web-User信息VO-描述
       */
      desc?: string;
      /**
       * web-User信息VO-id
       */
      id?: number;
      /**
       * web-User信息VO-name
       */
      name?: string;
      /**
       * web-User信息VO-username
       */
      username?: string;
    };
    /**
     * 修约规则（1:向上圆整，2:向下圆整）
     */
    roundRule?: number;
    /**
     * 样本量列表
     */
    sampleList?: {
      /**
       * id
       */
      id?: number;
      /**
       * 最大批量
       */
      maxBatch: number;
      /**
       * 最小批量
       */
      minBatch: number;
      /**
       * 样本量
       */
      sampleCount: number;
    }[];
    /**
     * 状态（1：启用，0：停用）
     */
    state?: number;
    /**
     * 修改时间
     */
    updatedAt?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 是否需要二次确认 0 强管控 不需要确认 1 弱管控 需要用户两次确认
   */
  needCheck?: number;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [修改自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79802) 的 **请求配置的类型**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/update`
 * @更新时间 `2021-12-20 14:23:58`
 */
type FetchCustomAqlUpdateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/custom_aql/update',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [修改自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79802) 的 **请求配置**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/update`
 * @更新时间 `2021-12-20 14:23:58`
 */
const fetchCustomAqlUpdateRequestConfig: FetchCustomAqlUpdateRequestConfig = {
  mockUrl: mockUrl_0_0_1_0,
  devUrl: devUrl_0_0_1_0,
  prodUrl: prodUrl_0_0_1_0,
  path: '/quality/domain/web/v1/custom_aql/update',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_1_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchCustomAqlUpdate',
};

/**
 * 接口 [修改自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/79802) 的 **请求函数**
 *
 * @分类 [web-自定义aql↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11344)
 * @标签 `web-自定义aql`
 * @请求头 `POST /quality/domain/web/v1/custom_aql/update`
 * @更新时间 `2021-12-20 14:23:58`
 */
export const fetchCustomAqlUpdate = makeRequest<
  FetchCustomAqlUpdateRequest,
  FetchCustomAqlUpdateResponse,
  FetchCustomAqlUpdateRequestConfig
>(fetchCustomAqlUpdateRequestConfig);

/* prettier-ignore-end */
