/**
 *排程方案设置
 */
import { useEffect } from 'react';
import { RouterProps } from 'react-router-dom';
import { map, debounce } from 'lodash';
import { SearchSelect } from 'components';
import { Form, InputNumber, Space, Select, Checkbox, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';

import _Array from 'src/utils/dataTypes/array';
import { DataFormLayout } from 'src/layout';
import { BlSortFormList } from 'src/components';
import { maintenanceType } from 'src/dict/resources/mappings';
import { quantitySplitDisplayMap } from 'src/dict/planned/mappings';
import { dealWithPeriodOfTime } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import { ShiftInfoDetailVOS } from 'src/page/knowledgeManagement/calendarManagement/shift/interface';
import { validateBlInputNumberIntervalInt } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import {
  fetchSchedulingSchedulingConfigGet,
  fetchSchedulingSchedulingConfigUpdate,
  FetchSchedulingSchedulingConfigUpdateRequest,
} from 'src/api/ytt/wos-domain';
import { NamePath } from 'antd/es/form/interface';
import { FormInstance } from 'antd/es/form/Form';
import { arrayIsEmpty } from 'src/utils/array';

interface FeedingControlFormProps extends RouterProps {
  onCancel: () => void;
  onChange: () => void;
}

const SchSetModal = (props: FeedingControlFormProps) => {
  const { onCancel, onChange } = props;

  const [form] = Form.useForm();

  const fetchData = async () => {
    try {
      const { data } = await fetchSchedulingSchedulingConfigGet();

      const { shiftList, ...rest } = data;

      const dataToForm = {
        ...rest,
        shiftList: map(shiftList, (item) => {
          return {
            shiftId: { key: JSON.stringify(item), label: item?.name, value: JSON.stringify(item) },
          };
        }),
        // 一起默认全选不可更改
        breakTypes: [1, 2, 3],
      };

      form.setFieldsValue(dataToForm);
      // setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [props]);

  const validateRepeat = (recordForm: FormInstance, filedName: NamePath, validateName: string) => {
    return (_rule: any, value: any) => {
      return new Promise((resolve, reject) => {
        const valueList = recordForm.getFieldValue(filedName);

        const newList = map(valueList, ({ shiftId }) => ({
          ...JSON.parse(shiftId?.key ?? '{}'),
        }));

        // 从 valueList 找出重复的项组成 []
        const list = newList.map((el: any) => String(el?.id));

        const repeatList = _Array.findDuplicates(list);

        // 当前时间段
        const { id } = JSON.parse(value?.key ?? '{}');

        // value 如果存在 在重复项 [] 中，则 reject
        const condition = repeatList.indexOf(String(id));

        if (condition !== -1) {
          reject('已存在该选项！');
          return;
        }
        return resolve(true);
      });
    };
  };

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '序号',
        dataIndex: 'lineNo',
        width: 200,
        render: (_text, field, index) => {
          return <Form.Item style={{ marginBottom: '0' }}>{index}</Form.Item>;
        },
      },
      {
        title: '班次',
        dataIndex: 'shiftId',
        width: 200,
        render: (_text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              name={[field.name, 'shiftId']}
              fieldKey={[field.fieldKey, 'shiftId']}
              rules={[{ validator: validateRepeat(form, 'shiftList', 'shiftId') }]}
            >
              <SearchSelect fetchType="shiftListInfo" />
            </Form.Item>
          );
        },
      },
      {
        title: '时间段',
        dataIndex: 'time',
        width: 200,
        render: (_text, field) => {
          return (
            <Form.Item
              style={{ marginBottom: '0' }}
              dependencies={[['shiftList', field.name, 'shiftId']]}
            >
              {() => {
                const shift = form.getFieldValue(['shiftList', field.name, 'shiftId']) || {};

                const { shiftInfoDetailVOS } = JSON.parse(shift?.key ?? '{}');

                const formatTime =
                  shiftInfoDetailVOS &&
                  dealWithPeriodOfTime(shiftInfoDetailVOS)
                    .map((element: ShiftInfoDetailVOS) => {
                      return `${element?.startTime} -  ${element?.endTime}`;
                    })
                    .join('；');

                return <Form.Item style={{ marginBottom: '0' }}>{formatTime}</Form.Item>;
              }}
            </Form.Item>
          );
        },
      },
    ];
  };

  console.log('quantitySplitDisplayMap: ', quantitySplitDisplayMap);

  const baseInfo = {
    items: [
      {
        isFullLine: true,
        label: '生产日历',
        render: () => (
          <BlSortFormList
            name={'shiftList'}
            renderColumns={() => getColumns()}
            isNeedDrag={false}
            maxCount={3}
            buttonText={'添加班次'}
          />
        ),
      },
      {
        isFullLine: true,
        label: '最短可排时长',
        render: () => (
          <Space>
            <Form.Item
              name={['minimumSchedulableTime']}
              fieldKey={['minimumSchedulableTime']}
              rules={[
                { validator: validateBlInputNumberIntervalInt(1, 24) },
                { required: true, message: '最短可排时长必填' },
              ]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              name={['minimumSchedulableTimeUnit']}
              fieldKey={['minimumSchedulableTimeUnit']}
              initialValue={1}
            >
              <Select disabled>
                <Select.Option value={1}>小时</Select.Option>
              </Select>
            </Form.Item>
          </Space>
        ),
      },
      {
        isFullLine: true,
        label: '维保类型',
        name: 'repairTypes',
        render: () => <Checkbox.Group options={maintenanceType} />,
      },
      {
        isFullLine: true,
        label: '数量拆分规则',
        name: 'breakTypes',
        render: () => <Checkbox.Group disabled options={quantitySplitDisplayMap} />,
      },
    ],
  };

  /**
   * 格式化数据给接口
   * @param value
   * @returns
   */
  // : WorkOrderRequest
  const formatValueToApi = (value: any): FetchSchedulingSchedulingConfigUpdateRequest => {
    const { shiftList, ...rest } = value;

    return {
      ...rest,
      shiftIds: map(shiftList, ({ shiftId }) => {
        return JSON.parse(shiftId?.value ?? '{}')?.id;
      }).filter(Boolean),
    };
  };

  const handleFinish = async () => {
    try {
      const formValue: any = await form.validateFields();

      const value = await formatValueToApi(formValue);

      if (arrayIsEmpty(value.shiftIds)) {
        message.error('至少添加一个班次');
        return;
      }

      fetchSchedulingSchedulingConfigUpdate(value).then((res) => {
        onCancel();
        onChange();
      });
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <>
      <DataFormLayout
        form={form}
        info={[baseInfo]}
        title="排程方案设置"
        onCancel={() => {
          onCancel();
        }}
        onFinish={debounce(handleFinish, 1500)}
        formProps={{
          preserve: true,
        }}
      />
    </>
  );
};

export default SchSetModal;
