import _ from 'lodash';
import { LabeledValue } from 'src/page/share';
import { CustomFieldGetListByObjectCode, LabelInValueSelectorProps, ShowFieldObj } from '../types';

export const sf2lb = (v: CustomFieldGetListByObjectCode): LabelInValueSelectorProps => ({
  label: v?.fieldName || '',
  value: v.fieldCode,
  key: v.fieldCode,
});

export const associadateData2Lv = (showField: ShowFieldObj[] | undefined): LabeledValue[] => {
  const newData = showField?.map((item) => {
    return {
      label: item.fieldName,
      value: item.fieldCode,
      key: item.fieldCode,
    };
  });

  return newData ?? [];
};

export const lv2AssociadateData = (lv: LabeledValue[] | undefined): ShowFieldObj[] | undefined => {
  if (!lv || !_.isArray(lv)) return [];

  const newLv = lv.map((item) => {
    return {
      fieldName: item.label,
      fieldCode: _.toString(item.value),
    };
  });

  return newLv;
};
