import React, { useEffect, useState } from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import { fetchLogGetOperationDetail } from 'src/api/ytt/log-domain';

import { BaseInfo } from './detailBaseInfo';

interface Props extends RouteComponentProps {
  match: match<{ id: string }>;
}

const OperationLogDetailPage = (props: Props) => {
  const { match } = props;
  const operationId = Number(match?.params?.id);

  const [detail, setDetail] = useState<any>({} as any);

  const fetchData = async () => {
    try {
      const { data } = await fetchLogGetOperationDetail({ logId: operationId }, { legacy: true });

      if (data) {
        setDetail(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [operationId]);

  return <BaseInfo detail={detail} />;
};

export default OperationLogDetailPage;
