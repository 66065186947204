import React, { useContext } from 'react';
import moment from 'moment';
import { Button } from 'antd';

import { formatDateForRender } from 'src/utils/formatters';
import { SpecialDayContext, CalendarDayDataProps } from '../../../constants';
import styles from './index.module.scss';

interface CalendarTooltipProps {
  dataSource: CalendarDayDataProps;
  readOnly?: boolean;
  onClose?: () => void;
}

const formatDuration = (duration: number) => {
  const momentDuration = moment.duration(duration, 'ms');
  const hours = momentDuration.hours();
  const minutes = momentDuration.minutes();
  const days = momentDuration.days();

  return `${days * 24 + hours} 小时 ${minutes} 分钟`;
};

const formatPeriod = (startTime?: number, endTime?: number) => {
  const startOfToday = moment().startOf('day');
  const startMoment = startOfToday.clone().add(startTime, 'ms');
  const endMoment = startOfToday.clone().add(endTime, 'ms');
  const nextDay = endMoment.diff(startMoment, 'days') > 0 ? '次日' : '';

  return `${startMoment.format('HH:mm')}-${nextDay}${endMoment.format('HH:mm')}`;
};

export default function CalendarTooltip(props: CalendarTooltipProps) {
  const { dataSource, readOnly, onClose } = props;
  const { day, shiftDetail, specialDayIndex, specialDayName } = dataSource;
  const { name, sumDuration, effectiveDuration, shiftInfoDetailVOS, id } = shiftDetail;
  const { showSpecialDayDialog } = useContext(SpecialDayContext);
  // 从日历预览上点编辑特殊日，通过后台取到当前天的特殊日情况，用specialDayIndex和specialDayName两个字段来描述（没有specialDayId）
  // 有些历史特殊日数据是没有specialDayIndex的，但如果不是特殊日，这2个字段都是null
  const isEditing = specialDayName || specialDayIndex;

  // 添加为特殊日/编辑特殊日
  const tooltipButtonClicked = () => {
    // 判断当前特殊日是否已经存在，存在就是编辑模式
    if (isEditing) {
      // 编辑特殊日时，从日历预览只能得到弹出tooltip当日的日期，不能得到特殊日的开始日期和结束日期，所以不传这2个参数
      // 在特殊日弹窗中，通过specialDayIndex遍历全部特殊日获得开始日期和结束日期
      showSpecialDayDialog?.({
        shiftName: name as string,
        shiftId: id as number,
        name: specialDayName as string,
        specialDayIndex,
      });
    } else {
      // 添加为特殊日时，新建特殊日，当前天原来的班次失效，所以不传；特殊日名称也为空
      showSpecialDayDialog?.({
        startTime: day,
        endTime: day,
        name: '',
      });
    }
    // 关闭日历特殊日tooltip
    onClose?.();
  };

  return (
    <div className={styles.calendarTooltipContent}>
      <div className={styles.calendarTooltipDetail}>
        <div className={styles.calendarTooltipItem}>{`日期：${formatDateForRender(day)}`}</div>
        <div className={styles.calendarTooltipItem}>{`班次：${name}`}</div>
        {shiftInfoDetailVOS && (
          <div className={`${styles.calendarPeriodContainer} ${styles.calendarTooltipItem}`}>
            <div>时段：</div>
            <div>
              {shiftInfoDetailVOS.map((shiftDuration) => (
                <div key={shiftDuration.id}>
                  {formatPeriod(shiftDuration.startTime, shiftDuration.endTime)}
                </div>
              ))}
            </div>
          </div>
        )}
        {effectiveDuration && (
          <div className={styles.calendarTooltipItem}>
            {`有效工时：${formatDuration(effectiveDuration)}`}
          </div>
        )}
        {sumDuration && (
          <div className={styles.calendarTooltipItem}>
            {`总工时：${formatDuration(sumDuration)}`}
          </div>
        )}
      </div>
      {!readOnly && (
        <div className={styles.calendarTooltipButton}>
          <Button type="primary" size="small" onClick={tooltipButtonClicked}>
            {isEditing ? '编辑特殊日' : '添加为特殊日'}
          </Button>
        </div>
      )}
    </div>
  );
}
