import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { RouteChildrenProps } from 'react-router';
import { Badge, message, Tabs } from 'antd';
//
import { fetchBomBulkUpdateStatus, fetchBomGet } from 'src/api/ytt/med-domain/bom';
import { DetailLayout } from 'src/layout';
import { BlIcon, showErrorListMessage, TagList } from 'src/components';
import lookup, { appEnum } from 'src/dict';
import { DetailLayoutMenuItem } from 'src/layout/detail/DetailLayout.type';
import { getMaterialAttrs, judgeMaterialIsVirtual } from 'src/entity/material';
//
import { toCopyBom, toEditBom } from '../navigation';
import { BomActiveColorMap, BomTab, BomTabMap } from '../constants';
import { BomResponse } from '../index.type';
import CoproductTab from './coproductDetail';
import SubItemMaterialTab from './subItemMaterialDetail';
import OtherInfoTab from './otherInfoDetail';
import MultiDetail from '../multiDetail';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { fetchCustomFieldInstanceGetByObjectCode } from 'src/api/ytt/custom-object-domain';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { replaceSign } from 'src/utils/constants';

interface BomDetailProps extends RouteChildrenProps<{ id: string }> {}

const Detail = (props: BomDetailProps) => {
  const { match, history } = props;

  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState<BomResponse>();
  const [isVirtualMaterial, setIsVirtualMaterial] = useState(false); // 父项物料业务范围是否是虚拟件
  const [multiDetailId, setMultiDetailId] = useState<number>();
  const [bomCustomFields, setBomCustomFields] = useState<any>();

  const fetchAndSetData = (id?: string) => {
    if (id) {
      setLoading(true);

      return Promise.all([
        fetchCustomFieldInstanceGetByObjectCode({
          objectCode: OBJECT_OF_CODE.SubItemMaterialChild,
        }),
        fetchBomGet({ id: _.toNumber(id) }),
      ]).then(([customFieldRes, dataRes]) => {
        // 设置自定义字段信息
        if (customFieldRes) {
          const { data } = customFieldRes;

          setBomCustomFields(data);
        }

        if (dataRes) {
          const { data } = dataRes;

          setIsVirtualMaterial(judgeMaterialIsVirtual(data?.material));
          setDataSource(data);
        }
      });
    }

    return Promise.reject();
  };

  useEffect(() => {
    fetchAndSetData(match?.params?.id)
      .catch(() => {
        history.goBack();
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  /**
   * 更新BOM状态
   */
  const updateBomsStatus = (currentAction: number, ids: React.Key[]) => {
    const toActive =
      currentAction === appEnum.Common.UsageStatus.enabled
        ? appEnum.Common.Turn.off
        : appEnum.Common.Turn.on;

    if (_.isEmpty(ids)) return Promise.reject();

    return fetchBomBulkUpdateStatus({
      active: toActive,
      ids: _.map(ids, _.toNumber),
    }).then((res) => {
      const { failCount = 0, successCount = 0, failDetails = [] } = res?.data ?? {};

      if (failCount) {
        showErrorListMessage({
          failCount,
          successCount,
          title: `${lookup('turn', toActive)}出现失败`,
          data: _.map(failDetails, (item) => ({ ...item, key: item.id })),
          columns: [
            { title: '物料编号', dataIndex: ['material', 'baseInfo', 'code'] },
            { title: '物料名称', dataIndex: ['material', 'baseInfo', 'name'] },
            { title: '版本号', dataIndex: 'version' },
            { title: '失败原因', dataIndex: 'failReason', width: 200 },
            { title: '', dataIndex: 'key', width: 1, render: () => null },
          ],
        });
      } else {
        message.success(`${lookup('turn', toActive)}成功！`);
      }

      return res;
    });
  };

  /**
   * 启停用操作点击
   */
  const handleActionClick = () => {
    if (typeof dataSource?.active === 'number' && typeof dataSource?.id === 'number') {
      setLoading(true);
      updateBomsStatus(dataSource.active, [dataSource.id])
        .then((res) => {
          const { successCount = 0 } = res?.data ?? {};

          if (successCount > 0) {
            fetchAndSetData(match?.params?.id).finally(() => {
              setLoading(false);
            });
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const getBaseMenu = (): DetailLayoutMenuItem[] => {
    return _.compact([
      {
        key: 'openAll',
        icon: <BlIcon type="iconzuzhijiagou" />,
        title: '多层展开',
        auth: authDict.bom_multi_lev_detail,
        onClick: () => {
          setMultiDetailId(dataSource?.id);
        },
      },
      {
        key: appEnum.Common.CRUD.copy,
        icon: <BlIcon type="iconfuzhi" />,
        title: lookup('crud', appEnum.Common.CRUD.copy),
        auth: authDict.bom_add,
        onClick: () => {
          dataSource?.id && history.push(toCopyBom(dataSource?.id));
        },
      },
      {
        key: 'active',
        icon: <BlIcon type="iconqiyong" />,
        title: lookup('changeStatusAction', dataSource?.active),
        auth: authDict.bom_enable_disable,
        onClick: handleActionClick,
      },
      dataSource?.active === appEnum.Common.UsageStatus.disabled && {
        key: appEnum.Common.CRUD.edit,
        icon: <BlIcon type="iconbianji" />,
        title: lookup('crud', appEnum.Common.CRUD.edit),
        auth: authDict.bom_edit,
        onClick: () => {
          dataSource?.id && history.push(toEditBom(dataSource?.id));
        },
      },
    ]);
  };

  const renderTabs = () => {
    const tabWithComponent = new Map([
      [
        BomTab.SUB_ITEM_MATERIAL,
        <SubItemMaterialTab
          dataSource={dataSource}
          bomCustomFields={bomCustomFields}
          history={history}
        />,
      ],
      [BomTab.COPRODUCT, <CoproductTab dataSource={dataSource} />],
      [BomTab.OTHER, <OtherInfoTab dataSource={dataSource} />],
    ]);
    /**
     * 父项物料是虚拟件时，需过滤
     */
    const filterVirtual = (item: BomTab) => {
      const disabledTabs = [BomTab.COPRODUCT]; // 不支持父项物料为虚拟件的tab

      if (isVirtualMaterial) {
        return !disabledTabs.includes(item);
      }
      return true;
    };

    return (
      <Tabs type="card">
        {_.map(_.filter(Array.from(BomTabMap.keys()), filterVirtual), (key) => {
          const title = BomTabMap.get(key)?.title;

          return (
            <Tabs.TabPane key={key} tab={title} style={{ height: '100%' }}>
              {tabWithComponent.get(key)}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    );
  };

  const baseInfo = [
    {
      title: '基本信息',
      items: [
        {
          label: '父项物料编号',
          dataIndex: ['material', 'baseInfo', 'code'],
        },
        {
          label: '父项物料名称',
          dataIndex: ['material', 'baseInfo', 'name'],
        },
        {
          label: '物料属性',
          dataIndex: ['material', 'attribute'],
          render: (_text: any, record: any) => (
            <TagList dataSource={getMaterialAttrs(record?.material)} />
          ),
        },
        {
          label: '物料分类',
          dataIndex: ['material', 'category', 'name'],
        },
        {
          label: '物料规格',
          dataIndex: ['material', 'baseInfo', 'specification'],
        },
        {
          label: '成品率(%)',
          dataIndex: 'productRate',
        },
        {
          label: '单位',
          dataIndex: 'unitName',
        },
        {
          label: '工艺路线',
          dataIndex: ['processRouteSimpleVO', 'name'],
        },
        {
          label: '报工工序号',
          dataIndex: ['workReportProcessRouteNodeSimpleVO', 'processNum'],
        },
        {
          label: '报工控件编号',
          dataIndex: ['workReportSopControlDTO', 'code'],
        },
        {
          label: '单次报工数量',
          dataIndex: 'singleWorkReportAmount',
        },
        {
          label: '报工方式',
          dataIndex: 'reportingMethods',
          render: (text: number[]) => {
            return text?.map((item) => lookup('bom.reportingMethods', item)).join(',');
          },
        },
        {
          label: '是否入库',
          dataIndex: 'warehousing',
          render: (text: number) => {
            return lookup('common.yn', text);
          },
        },
        {
          label: '自动入库',
          dataIndex: 'autoWarehousingFlag',
          render: (text: number) => {
            return lookup('common.yn', text) || replaceSign;
          },
        },
        {
          label: '版本号',
          dataIndex: 'version',
        },
        {
          label: '状态',
          dataIndex: 'active',
          render: (text: any) => (
            <Badge color={BomActiveColorMap.get(text)} text={lookup('common.usageStatus', text)} />
          ),
        },
        {
          label: '默认版本',
          dataIndex: 'defaultVersion',
          render: (text: any) => lookup('yn', text),
        },
        {
          label: '版本说明',
          dataIndex: 'versionDescription',
        },
        {
          label: '所属部门',
          dataIndex: 'ownedDepartmentName',
        },
        {
          label: '生产部门',
          dataIndex: 'manufactureDepartmentName',
        },
      ],
    },
    {
      title: '详细信息',
      items: [
        {
          dataIndex: 'xxx',
          render: () => renderTabs(),
        },
      ],
    },
  ];

  return (
    <>
      <DetailLayout
        loading={loading}
        title="物料清单详情"
        info={baseInfo}
        dataSource={dataSource}
        baseMenu={getBaseMenu()}
        userAuth={getAuthFromLocalStorage()}
      />
      <MultiDetail bomId={multiDetailId} onCancel={() => setMultiDetailId(undefined)} />
    </>
  );
};

export default Detail;
