import { useState } from 'react';
import { BlIcon } from 'components';
import _ from 'lodash';
import { RecordListLayout, FilterItem } from 'layout';
import { Form, message as Message } from 'antd';
import {
  fetchCustomerListByRegisterItems,
  fetchCustomerDelete,
  FetchCustomerDeleteRequest,
  FetchCustomerDeleteResponse,
  FetchCustomerListByRegisterItemsRequest,
} from 'src/api/ytt/order-domain/customer';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { appDict, lookup } from 'src/dict';
import { ErrorNotification, showErrorListMessage } from 'src/components';
import { renderUsageStatus } from 'src/page/share/renderUsageStatus';
import { valueOrHolder } from 'src/utils/formatters';
import { replaceSign } from 'src/utils/constants';
import { avatarDisplay } from 'src/components/avatar/show';
import { fieldTypeList, gcObject, gcTime } from 'src/utils';
import type { CustomerListByRegisterItemProps, CustomerListItemPropsUser } from '../interface';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { mappingMaterialInfoAttrList } from 'src/page/warehouseManagement/utils';
import authDict from 'src/utils/auth';

const errorColumns = [
  {
    title: '客户名称',
    dataIndex: 'name',
  },
  {
    title: '失败原因',
    dataIndex: 'failReason',
  },
];

const RegisteredMaterialsList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新页面

  const [delForm] = Form.useForm();

  /**
   * 删除操作
   */
  const handleDelete = async (
    onSuccess?: () => void,
    onFail?: () => void,
    idToDeleteAlone?: number,
  ) => {
    try {
      // 从确认删除的form里得到删除原因
      const reason = delForm.getFieldValue('delReason');
      const idsInParam = idToDeleteAlone ? [idToDeleteAlone] : (selectedRowKeys as number[]);
      const requestParam: FetchCustomerDeleteRequest = {
        editReason: reason,
        ids: idsInParam,
      };
      // 不明原因，ytt的FetchHandlingEquipmentDeleteResponse中data没有描述，只能强行转换成自定义类型
      const { data }: FetchCustomerDeleteResponse = await fetchCustomerDelete(requestParam);

      if (data) {
        const { success, fail } = data;

        // 全部删除成功，显示删除成功
        if (_.isNil(fail) || _.isEmpty(fail)) {
          Message.success('删除成功');
          onSuccess?.();
        } else if (_.isNil(success) || _.isEmpty(success)) {
          // 全部删除失败，提示删除失败及原因
          onFail?.();
          if (fail.length > 0) {
            ErrorNotification({
              message: '删除失败',
              description: (
                <>
                  {fail.map((failItem) => (
                    <div key={failItem.id}>
                      {`${failItem.name}(${failItem.code})`}: {failItem.detail}
                    </div>
                  ))}
                </>
              ),
            });
          } else {
            Message.error('删除失败！');
          }
        } else if (fail.length > 0) {
          onSuccess?.();
          // 否则用表格显示删除结果及原因统计的table
          showErrorListMessage({
            title: '删除出现错误',
            data: fail,
            columns: errorColumns,
            operateName: '删除',
            successCount: success.length,
            failCount: fail.length,
          });
        }
        // 删除成功以后清空删除表单里已经填的删除原因
        delForm.resetFields();
      } else {
        onFail?.();
        console.log('api返回的response里没有data');
      }
    } catch (error) {
      onFail?.();
      console.log(error);
    }
  };
  /**
   * Columns
   */
  const dataColumns = _.compact([
    {
      title: '客户编号',
      dataIndex: 'code',
      width: 150,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: '客户名称',
      dataIndex: 'name',
      width: 150,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 120,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.common.usageStatus,
        mode: 'multiple',
      },
      render: renderUsageStatus,
    },
    {
      title: '客户所有人',
      dataIndex: 'owner',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (user: Required<CustomerListItemPropsUser>) => {
        return user.id ? avatarDisplay({ ...user, isUser: true }) : replaceSign;
      },
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择客户所有人" isMultiple isSelectUser />
      ),
    },
    {
      title: '客户注册状态',
      dataIndex: 'regStatus',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.customer.registrationStatus,
        mode: 'multiple',
      },
      render: (text: number) => lookup('customer.registrationStatus', text) || replaceSign,
    },
    {
      title: '注册企业',
      dataIndex: 'regOrg',
      width: 150,
      type: fieldTypeList.text,
      render: (text: { orgId: number | null; orgCode: string | null; orgName: string }) =>
        text?.orgName || replaceSign,
    },
    {
      title: '物料编号',
      dataIndex: 'materialCode',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '物料名称',
      dataIndex: 'materialName',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '物料注册状态',
      dataIndex: 'matRegStatus',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.customer.registrationStatus,
        mode: 'multiple',
      },
      render: (text: number) => lookup('customer.registrationStatus', text) || replaceSign,
    },
    {
      title: '物料属性',
      dataIndex: 'attribute',
      width: 150,
      type: fieldTypeList.reference,
      render: mappingMaterialInfoAttrList,
    },
    {
      title: '数量',
      dataIndex: ['amount', 'amountDisplay'],
      width: 150,
      type: fieldTypeList.text,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '单位',
      dataIndex: ['amount', 'unitName'],
      width: 150,
      type: fieldTypeList.reference,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '客户物料编号',
      dataIndex: 'customerMaterialCode',
      width: 150,
      type: fieldTypeList.reference,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '客户物料名称',
      dataIndex: 'customerMaterialName',
      width: 150,
      type: fieldTypeList.reference,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '客户物料数量',
      dataIndex: ['customerAmount', 'amountDisplay'],
      width: 150,
      type: fieldTypeList.reference,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    {
      title: '客户物料单位',
      dataIndex: ['customerAmount', 'unitName'],
      width: 150,
      type: fieldTypeList.reference,
      render: (text: string) => {
        return text || replaceSign;
      },
    },
    // 供应链新增
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (user: Required<CustomerListItemPropsUser>) => {
        return user?.id ? avatarDisplay({ ...user, isUser: true }) : replaceSign;
      },
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择创建人" isMultiple isSelectUser />
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 150,
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: '变更人',
      dataIndex: 'operator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (user: Required<CustomerListItemPropsUser>) => {
        return user?.id ? avatarDisplay({ ...user, isUser: true }) : replaceSign;
      },
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择更新人" isMultiple isSelectUser />
      ),
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 150,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    // {
    //   title: '自定义字段',
    //   dataIndex: 'customFieldItemList',
    //   width: 150,
    //   isFilter: true,
    //   type: fieldCategory.customFields,
    //   render: valueOrHolder,
    // },
    {
      width: 60,
      fixed: 'right',
      render: () => <div />,
    },
  ]);
  /**
   * 主操作按钮
   */
  const mainMenu = [
    {
      title: '查看注册邀请列表',
      icon: <BlIcon type="iconcaozuojilu" />,
      onClick: () => {
        window.open('/modeling/customerModeling/customerRegisterList');
      },
      auth: authDict.customerregisteredaudit_reg_list,
    },
    {
      title: '查看物料注册邀请列表',
      icon: <BlIcon type="iconcaozuojilu" />,
      onClick: () => {
        window.open('/modeling/customerModeling/materialsRegisterList');
      },
      auth: authDict.customermaterialregisteredaudit_reg_list_material,
    },
  ];
  /**
   * 批量操作按钮
   */
  const batchMenu = [
    // {
    //   title: lookup('common.crud', CRUD.delete),
    //   // onSuccess, onFail: batchMenu onClick回调的参数，onSuccess会刷新列表并清空批量按钮的loading状态，onFail只清空loading状态
    //   onClick: (onSuccess?: () => void, onFail?: () => void) => {
    //     BLDelConfirm('你确定要删除吗？', delForm, () => {
    //       handleDelete(onSuccess, onFail);
    //     });
    //   },
    // },
  ];
  /**
   * 列表筛选处理函数
   */
  const filterList: FilterItem[] = dataColumns
    .filter((i) => i.isFilter)
    .map((column) => {
      return _.pick(
        {
          ...column,
          label: column.title,
          name: column.dataIndex,
          type: column.type,
        },
        [
          'label',
          'name',
          'type',
          'rules',
          'renderItem',
          'selectProps',
          'inputProps',
          'datePickerProps',
          'dateFormat',
        ],
      ) as FilterItem;
    });
  /**
   * 传给接口数据处理函数
   */
  const formatDataToQuery = (param: any): FetchCustomerListByRegisterItemsRequest => {
    const { creator, operator, owner, createdAt, updatedAt, ...rest } = param;
    const [createdAtFrom, createdAtTo] = gcTime.formatRangeUnixToInt(createdAt);
    const [updatedAtFrom, updatedAtTo] = gcTime.formatRangeUnixToInt(updatedAt);

    const options = {
      createdAtFrom,
      createdAtTo,
      updatedAtFrom,
      updatedAtTo,
      creator: creator?.length ? _.map(creator, 'value') : null,
      operator: operator?.length ? _.map(operator, 'value') : null,
      owner: owner?.length ? _.map(owner, 'value') : null,
      ...rest,
    };

    return gcObject.filterEmptyProperty(options);
  };
  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (param: any) => {
    const { createdAt, updatedAt, ...rest } = param;

    const result = {
      ...rest,
      createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
      updatedAt: gcTime.formatRangeTimeToMoment(updatedAt || []),
    };

    return gcObject.filterEmptyProperty(result);
  };

  return (
    <>
      <RecordListLayout<CustomerListByRegisterItemProps>
        columns={dataColumns}
        filterList={filterList}
        rowKey={'id'}
        mainMenu={mainMenu}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        configcacheKey={'registeredMaterials'}
        requestFn={fetchCustomerListByRegisterItems}
        refreshMarker={refreshMarker}
        placeholder={'请输入客户编号或者名称、物料编号'}
      />
    </>
  );
};

export default RegisteredMaterialsList;
