/**
 * @component 主从对象业务类型匹配器
 */

import { useState, FC, useEffect } from 'react';
import _ from 'lodash';
import { Checkbox, Form } from 'antd';
import type { BizList } from '../../types';
import styles from './styles.module.scss';

interface Props {
  main: BizList;
  son: BizList;
}

export const Matcher: FC<Props> = ({ main, son }) => {
  const [selectedMainIndex, selectMain] = useState<number>(-1);

  useEffect(() => {
    if (!_.isEmpty(main)) {
      selectMain(0);
    }
  }, [main]);

  return (
    <div className={styles['matcher-container']}>
      <div className={`${styles['matcher-part']} ${styles['matcher-part-left']}`}>
        <div className={styles['matcher-header']}>主对象业务类型</div>
        <ul
          className={styles['matcher-content']}
          onClick={(e) => {
            const node = e.target as HTMLElement;

            if (node.tagName === 'LI') {
              selectMain(_.toNumber(node.dataset.index));
            }
          }}
        >
          {main.map((item, index) => (
            <li
              className={`${styles['matcher-item']} ${
                selectedMainIndex === index ? styles.selected : ''
              }`}
              data-index={index}
              key={item.id}
              title={item.name}
            >
              {item.name}
              <Form.Item name={['mapping', selectedMainIndex, 'mainId']} hidden />
            </li>
          ))}
        </ul>
      </div>
      <div className={styles['matcher-part']}>
        <div className={styles['matcher-header']}>从对象业务类型</div>
        <Form.Item name={['mapping', selectedMainIndex, 'sonIds']} noStyle>
          <Checkbox.Group
            className={styles['matcher-content']}
            options={son.map((item) => ({
              label: <span title={item.name}>{item.name}</span>,
              value: item.id!,
            }))}
            disabled={selectedMainIndex === -1}
          />
        </Form.Item>
      </div>
    </div>
  );
};
