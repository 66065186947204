/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../request';

// makeRequest
function makeRequestRequired<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequestOptional<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData?: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequest<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const optional = makeRequestOptional<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  const required = makeRequestRequired<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  return (
    requestConfig.requestDataOptional ? optional : required
  ) as TRequestConfig['requestDataOptional'] extends true ? typeof optional : typeof required;
}

// Request
export type Request<TReqeustData, TRequestConfig extends RequestConfig, TRequestResult> =
  (TRequestConfig['requestDataOptional'] extends true
    ? (
        requestData?: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult
    : (
        requestData: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult) & {
    requestConfig: TRequestConfig;
  };

const mockUrl_0_18_0_1 = 'http://yapi.blacklake.tech/mock/583' as any;
const devUrl_0_18_0_1 = '' as any;
const prodUrl_0_18_0_1 = '' as any;
const dataKey_0_18_0_1 = undefined as any;

/**
 * 接口 [文件-对象关系绑定↗](http://yapi.blacklake.tech/project/583/interface/api/74534) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_bind_biz`
 * @更新时间 `2021-11-29 16:04:00`
 */
export interface FetchFileBindBizRequest {
  /**
   * 业务实体类型识别码
   */
  bizCode?: string;
  /**
   * 业务实体ID
   */
  bizId?: number;
  /**
   * 业务实体类型名称
   */
  bizTypeName?: string;
  /**
   * 文件ID
   */
  fileId?: number;
  /**
   * 文件ID
   */
  fileIds?: number[];
  /**
   * 工厂ID
   */
  orgId?: number;
}

/**
 * 接口 [文件-对象关系绑定↗](http://yapi.blacklake.tech/project/583/interface/api/74534) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_bind_biz`
 * @更新时间 `2021-11-29 16:04:00`
 */
export interface FetchFileBindBizResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 业务实体类型识别码
     */
    bizCode?: string;
    /**
     * 业务实体ID
     */
    bizId?: number;
    /**
     * 业务实体类型名称
     */
    bizTypeName?: string;
    /**
     * 文件ID
     */
    fileId?: number;
    /**
     * 文件ID
     */
    fileIds?: number[];
    /**
     * 工厂ID
     */
    orgId?: number;
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [文件-对象关系绑定↗](http://yapi.blacklake.tech/project/583/interface/api/74534) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_bind_biz`
 * @更新时间 `2021-11-29 16:04:00`
 */
type FetchFileBindBizRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/583',
    '',
    '',
    '/holyfile/domain/web/v1/file/_bind_biz',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [文件-对象关系绑定↗](http://yapi.blacklake.tech/project/583/interface/api/74534) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_bind_biz`
 * @更新时间 `2021-11-29 16:04:00`
 */
const fetchFileBindBizRequestConfig: FetchFileBindBizRequestConfig = {
  mockUrl: mockUrl_0_18_0_1,
  devUrl: devUrl_0_18_0_1,
  prodUrl: prodUrl_0_18_0_1,
  path: '/holyfile/domain/web/v1/file/_bind_biz',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_18_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchFileBindBiz',
};

/**
 * 接口 [文件-对象关系绑定↗](http://yapi.blacklake.tech/project/583/interface/api/74534) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_bind_biz`
 * @更新时间 `2021-11-29 16:04:00`
 */
export const fetchFileBindBiz = makeRequest<
  FetchFileBindBizRequest,
  FetchFileBindBizResponse,
  FetchFileBindBizRequestConfig
>(fetchFileBindBizRequestConfig);

/**
 * 接口 [文件-部门关系绑定↗](http://yapi.blacklake.tech/project/583/interface/api/74540) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_bind_dep`
 * @更新时间 `2021-11-29 16:04:00`
 */
export interface FetchFileBindDepRequest {
  /**
   * 文件ID
   */
  fileId?: number;
  /**
   * 文件ID
   */
  fileIds?: number[];
  /**
   * 可见部门ID列表
   */
  visibleDepList?: number[];
}

/**
 * 接口 [文件-部门关系绑定↗](http://yapi.blacklake.tech/project/583/interface/api/74540) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_bind_dep`
 * @更新时间 `2021-11-29 16:04:00`
 */
export interface FetchFileBindDepResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 文件ID
     */
    fileId?: number;
    /**
     * 文件ID
     */
    fileIds?: number[];
    /**
     * 可见部门ID列表
     */
    visibleDepList?: number[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [文件-部门关系绑定↗](http://yapi.blacklake.tech/project/583/interface/api/74540) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_bind_dep`
 * @更新时间 `2021-11-29 16:04:00`
 */
type FetchFileBindDepRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/583',
    '',
    '',
    '/holyfile/domain/web/v1/file/_bind_dep',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [文件-部门关系绑定↗](http://yapi.blacklake.tech/project/583/interface/api/74540) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_bind_dep`
 * @更新时间 `2021-11-29 16:04:00`
 */
const fetchFileBindDepRequestConfig: FetchFileBindDepRequestConfig = {
  mockUrl: mockUrl_0_18_0_1,
  devUrl: devUrl_0_18_0_1,
  prodUrl: prodUrl_0_18_0_1,
  path: '/holyfile/domain/web/v1/file/_bind_dep',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_18_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchFileBindDep',
};

/**
 * 接口 [文件-部门关系绑定↗](http://yapi.blacklake.tech/project/583/interface/api/74540) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_bind_dep`
 * @更新时间 `2021-11-29 16:04:00`
 */
export const fetchFileBindDep = makeRequest<
  FetchFileBindDepRequest,
  FetchFileBindDepResponse,
  FetchFileBindDepRequestConfig
>(fetchFileBindDepRequestConfig);

/**
 * 接口 [批量文件上传↗](http://yapi.blacklake.tech/project/583/interface/api/74546) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_bulk_upload`
 * @更新时间 `2021-11-29 16:04:00`
 */
export interface FetchFileBulkUploadRequest {
  /**
   * files
   */
  files: string;
}

/**
 * 接口 [批量文件上传↗](http://yapi.blacklake.tech/project/583/interface/api/74546) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_bulk_upload`
 * @更新时间 `2021-11-29 16:04:00`
 */
export interface FetchFileBulkUploadResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 上传时间
     */
    createdAt?: string;
    creator?: {
      /**
       * 描述
       */
      desc?: string;
      /**
       * 用户业务ID
       */
      id?: number;
      /**
       * 用户名
       */
      name?: string;
      /**
       * 工厂ID
       */
      orgId?: number;
      /**
       * 账号
       */
      username?: string;
    };
    /**
     * 文件拓展名
     */
    extension?: string;
    /**
     * 文件名
     */
    filename?: string;
    /**
     * 文件ID
     */
    id?: number;
    /**
     * 文件大小
     */
    size?: number;
    /**
     * 文件链接
     */
    uri?: string;
    /**
     * 文件可见部门列表
     */
    visibleDepartments?: {
      /**
       * 编号
       */
      code?: string;
      /**
       * 备注
       */
      desc?: string;
      /**
       * 部门实体ID
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
      /**
       * 工厂id
       */
      orgId?: number;
    }[];
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量文件上传↗](http://yapi.blacklake.tech/project/583/interface/api/74546) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_bulk_upload`
 * @更新时间 `2021-11-29 16:04:00`
 */
type FetchFileBulkUploadRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/583',
    '',
    '',
    '/holyfile/domain/web/v1/file/_bulk_upload',
    undefined,
    string,
    'files',
    false
  >
>;

/**
 * 接口 [批量文件上传↗](http://yapi.blacklake.tech/project/583/interface/api/74546) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_bulk_upload`
 * @更新时间 `2021-11-29 16:04:00`
 */
const fetchFileBulkUploadRequestConfig: FetchFileBulkUploadRequestConfig = {
  mockUrl: mockUrl_0_18_0_1,
  devUrl: devUrl_0_18_0_1,
  prodUrl: prodUrl_0_18_0_1,
  path: '/holyfile/domain/web/v1/file/_bulk_upload',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_18_0_1,
  paramNames: [],
  queryNames: ['files'],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchFileBulkUpload',
};

/**
 * 接口 [批量文件上传↗](http://yapi.blacklake.tech/project/583/interface/api/74546) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_bulk_upload`
 * @更新时间 `2021-11-29 16:04:00`
 */
export const fetchFileBulkUpload = makeRequest<
  FetchFileBulkUploadRequest,
  FetchFileBulkUploadResponse,
  FetchFileBulkUploadRequestConfig
>(fetchFileBulkUploadRequestConfig);

/**
 * 接口 [详情查询↗](http://yapi.blacklake.tech/project/583/interface/api/74552) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_detail`
 * @更新时间 `2021-11-29 16:04:01`
 */
export interface FetchFileDetailRequest {
  /**
   * 文件ID
   */
  id: number;
}

/**
 * 接口 [详情查询↗](http://yapi.blacklake.tech/project/583/interface/api/74552) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_detail`
 * @更新时间 `2021-11-29 16:04:01`
 */
export interface FetchFileDetailResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 文件类型
     */
    contentType?: string;
    /**
     * 上传时间
     */
    createdAt?: string;
    creator?: {
      /**
       * 描述
       */
      desc?: string;
      /**
       * 用户业务ID
       */
      id?: number;
      /**
       * 用户名
       */
      name?: string;
      /**
       * 工厂ID
       */
      orgId?: number;
      /**
       * 账号
       */
      username?: string;
    };
    /**
     * 文件拓展名
     */
    extension?: string;
    /**
     * 文件链接
     */
    fileUri?: string;
    /**
     * 文件名
     */
    filename?: string;
    /**
     * 文件ID
     */
    id?: number;
    operator?: {
      /**
       * 描述
       */
      desc?: string;
      /**
       * 用户业务ID
       */
      id?: number;
      /**
       * 用户名
       */
      name?: string;
      /**
       * 工厂ID
       */
      orgId?: number;
      /**
       * 账号
       */
      username?: string;
    };
    /**
     * 文件大小，默认Byte
     */
    size?: number;
    /**
     * 修改时间
     */
    updatedAt?: string;
    /**
     * 文件可见部门列表
     */
    visibleDepartments?: {
      /**
       * 编号
       */
      code?: string;
      /**
       * 备注
       */
      desc?: string;
      /**
       * 部门实体ID
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
      /**
       * 工厂id
       */
      orgId?: number;
    }[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [详情查询↗](http://yapi.blacklake.tech/project/583/interface/api/74552) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_detail`
 * @更新时间 `2021-11-29 16:04:01`
 */
type FetchFileDetailRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/583',
    '',
    '',
    '/holyfile/domain/web/v1/file/_detail',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [详情查询↗](http://yapi.blacklake.tech/project/583/interface/api/74552) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_detail`
 * @更新时间 `2021-11-29 16:04:01`
 */
const fetchFileDetailRequestConfig: FetchFileDetailRequestConfig = {
  mockUrl: mockUrl_0_18_0_1,
  devUrl: devUrl_0_18_0_1,
  prodUrl: prodUrl_0_18_0_1,
  path: '/holyfile/domain/web/v1/file/_detail',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_18_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchFileDetail',
};

/**
 * 接口 [详情查询↗](http://yapi.blacklake.tech/project/583/interface/api/74552) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_detail`
 * @更新时间 `2021-11-29 16:04:01`
 */
export const fetchFileDetail = makeRequest<
  FetchFileDetailRequest,
  FetchFileDetailResponse,
  FetchFileDetailRequestConfig
>(fetchFileDetailRequestConfig);

/**
 * 接口 [模糊查询↗](http://yapi.blacklake.tech/project/583/interface/api/74558) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_list_by_search`
 * @更新时间 `2021-11-29 16:04:01`
 */
export interface FetchFileListBySearchRequest {
  /**
   * 模糊搜索条件
   */
  keyword: string;
  page?: number;
  size?: number;
}

/**
 * 接口 [模糊查询↗](http://yapi.blacklake.tech/project/583/interface/api/74558) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_list_by_search`
 * @更新时间 `2021-11-29 16:04:01`
 */
export interface FetchFileListBySearchResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * 上传时间
       */
      createdAt?: string;
      creator?: {
        /**
         * 描述
         */
        desc?: string;
        /**
         * 用户业务ID
         */
        id?: number;
        /**
         * 用户名
         */
        name?: string;
        /**
         * 工厂ID
         */
        orgId?: number;
        /**
         * 账号
         */
        username?: string;
      };
      /**
       * 文件拓展名
       */
      extension?: string;
      /**
       * 文件链接
       */
      fileUri?: string;
      /**
       * 文件名
       */
      filename?: string;
      /**
       * 文件ID
       */
      id?: number;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [模糊查询↗](http://yapi.blacklake.tech/project/583/interface/api/74558) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_list_by_search`
 * @更新时间 `2021-11-29 16:04:01`
 */
type FetchFileListBySearchRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/583',
    '',
    '',
    '/holyfile/domain/web/v1/file/_list_by_search',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [模糊查询↗](http://yapi.blacklake.tech/project/583/interface/api/74558) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_list_by_search`
 * @更新时间 `2021-11-29 16:04:01`
 */
const fetchFileListBySearchRequestConfig: FetchFileListBySearchRequestConfig = {
  mockUrl: mockUrl_0_18_0_1,
  devUrl: devUrl_0_18_0_1,
  prodUrl: prodUrl_0_18_0_1,
  path: '/holyfile/domain/web/v1/file/_list_by_search',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_18_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchFileListBySearch',
};

/**
 * 接口 [模糊查询↗](http://yapi.blacklake.tech/project/583/interface/api/74558) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_list_by_search`
 * @更新时间 `2021-11-29 16:04:01`
 */
export const fetchFileListBySearch = makeRequest<
  FetchFileListBySearchRequest,
  FetchFileListBySearchResponse,
  FetchFileListBySearchRequestConfig
>(fetchFileListBySearchRequestConfig);

/**
 * 接口 [文件上传↗](http://yapi.blacklake.tech/project/583/interface/api/74564) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_upload`
 * @更新时间 `2021-11-29 16:04:01`
 */
export interface FetchFileUploadRequest {
  /**
   * file
   */
  file: FileData;
}

/**
 * 接口 [文件上传↗](http://yapi.blacklake.tech/project/583/interface/api/74564) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_upload`
 * @更新时间 `2021-11-29 16:04:01`
 */
export interface FetchFileUploadResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 上传时间
     */
    createdAt?: string;
    creator?: {
      /**
       * 描述
       */
      desc?: string;
      /**
       * 用户业务ID
       */
      id?: number;
      /**
       * 用户名
       */
      name?: string;
      /**
       * 工厂ID
       */
      orgId?: number;
      /**
       * 账号
       */
      username?: string;
    };
    /**
     * 文件拓展名
     */
    extension?: string;
    /**
     * 文件名
     */
    filename?: string;
    /**
     * 文件ID
     */
    id?: number;
    /**
     * 文件大小
     */
    size?: number;
    /**
     * 文件链接
     */
    uri?: string;
    /**
     * 文件可见部门列表
     */
    visibleDepartments?: {
      /**
       * 编号
       */
      code?: string;
      /**
       * 备注
       */
      desc?: string;
      /**
       * 部门实体ID
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
      /**
       * 工厂id
       */
      orgId?: number;
    }[];
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [文件上传↗](http://yapi.blacklake.tech/project/583/interface/api/74564) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_upload`
 * @更新时间 `2021-11-29 16:04:01`
 */
type FetchFileUploadRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/583',
    '',
    '',
    '/holyfile/domain/web/v1/file/_upload',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [文件上传↗](http://yapi.blacklake.tech/project/583/interface/api/74564) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_upload`
 * @更新时间 `2021-11-29 16:04:01`
 */
const fetchFileUploadRequestConfig: FetchFileUploadRequestConfig = {
  mockUrl: mockUrl_0_18_0_1,
  devUrl: devUrl_0_18_0_1,
  prodUrl: prodUrl_0_18_0_1,
  path: '/holyfile/domain/web/v1/file/_upload',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.form,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_18_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchFileUpload',
};

/**
 * 接口 [文件上传↗](http://yapi.blacklake.tech/project/583/interface/api/74564) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_upload`
 * @更新时间 `2021-11-29 16:04:01`
 */
export const fetchFileUpload = makeRequest<
  FetchFileUploadRequest,
  FetchFileUploadResponse,
  FetchFileUploadRequestConfig
>(fetchFileUploadRequestConfig);

/**
 * 接口 [列表查询↗](http://yapi.blacklake.tech/project/583/interface/api/84194) 的 **请求类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_list_by_ids`
 * @更新时间 `2021-11-29 16:04:01`
 */
export interface FetchFileListByIdsRequest {
  idList?: number[];
}

/**
 * 接口 [列表查询↗](http://yapi.blacklake.tech/project/583/interface/api/84194) 的 **返回类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_list_by_ids`
 * @更新时间 `2021-11-29 16:04:01`
 */
export interface FetchFileListByIdsResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 上传时间
     */
    createdAt?: string;
    creator?: {
      /**
       * 描述
       */
      desc?: string;
      /**
       * 用户业务ID
       */
      id?: number;
      /**
       * 用户名
       */
      name?: string;
      /**
       * 工厂ID
       */
      orgId?: number;
      /**
       * 账号
       */
      username?: string;
    };
    /**
     * 文件拓展名
     */
    extension?: string;
    /**
     * 文件链接
     */
    fileUri?: string;
    /**
     * 文件名
     */
    filename?: string;
    /**
     * 文件ID
     */
    id?: number;
    /**
     * 文件大小
     */
    size?: number;
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [列表查询↗](http://yapi.blacklake.tech/project/583/interface/api/84194) 的 **请求配置的类型**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_list_by_ids`
 * @更新时间 `2021-11-29 16:04:01`
 */
type FetchFileListByIdsRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/583',
    '',
    '',
    '/holyfile/domain/web/v1/file/_list_by_ids',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [列表查询↗](http://yapi.blacklake.tech/project/583/interface/api/84194) 的 **请求配置**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_list_by_ids`
 * @更新时间 `2021-11-29 16:04:01`
 */
const fetchFileListByIdsRequestConfig: FetchFileListByIdsRequestConfig = {
  mockUrl: mockUrl_0_18_0_1,
  devUrl: devUrl_0_18_0_1,
  prodUrl: prodUrl_0_18_0_1,
  path: '/holyfile/domain/web/v1/file/_list_by_ids',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_18_0_1,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchFileListByIds',
};

/**
 * 接口 [列表查询↗](http://yapi.blacklake.tech/project/583/interface/api/84194) 的 **请求函数**
 *
 * @分类 [web↗](http://yapi.blacklake.tech/project/583/interface/api/cat_10934)
 * @标签 `web`
 * @请求头 `POST /holyfile/domain/web/v1/file/_list_by_ids`
 * @更新时间 `2021-11-29 16:04:01`
 */
export const fetchFileListByIds = makeRequest<
  FetchFileListByIdsRequest,
  FetchFileListByIdsResponse,
  FetchFileListByIdsRequestConfig
>(fetchFileListByIdsRequestConfig);

/* prettier-ignore-end */
