import { useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Modal } from 'antd';
import { FilterItem, RecordListLayout } from 'layout';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import MaterialAttributeCascader, {
  formatMaterialAttributeToQuery,
} from 'src/page/knowledgeManagement/materialModeling/material/share/Cascader/BcMultiCascader';
import { renderUser } from 'src/page/share/renderUser';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { gcObject, gcTime, fieldTypeList } from 'utils';
import { appEnum, appDict } from 'src/dict';
import { ENGINEER_PATH } from 'src/page/knowledgeManagement/engineerData/navigation';
import {
  fetchProcessRouteList,
  fetchProcessRouteUpdateStatus,
  FetchProcessRouteResponse,
} from 'src/api/ytt/med-domain/process-route';
import { patchRequest, updateStatus } from './utils';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import {
  BlIcon,
  LinkTooltip,
  MaterialSelect,
  SingleOperationLogComponent,
  TagList,
} from 'src/components';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { useDispatch } from 'react-redux';
import { getMaterialAttrs } from 'src/entity/material';
import BcBlCascader from 'src/components/Cascader';
import renderUsageStatus from 'src/page/share/renderUsageStatus';
import authDict from 'src/utils/auth';
import { gotoRoutingCreate, gotoRoutingDetail, gotoRoutingEdit } from './navigation';

type TableRowType = Exclude<FetchProcessRouteResponse['data'], undefined>['list'][number];

interface Props extends RouteComponentProps {}

const { UsageStatus } = appEnum.Common;
const { usageStatus } = appDict.common;
const RoutingManage = (props: Props) => {
  const { history } = props;
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const refreshCountRef = useRef(0);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [detailId, setDetailId] = useState<number>(0);
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const refreshTable = () => {
    // 刷新列表页
    refreshCountRef.current++;
    setRefreshMarker(refreshCountRef.current);
  };

  const toRoutingCopy = (id?: number) => {
    history.push(`${ENGINEER_PATH}/routing/${id}/copy`);
  };

  const dataColumns = [
    {
      title: '工艺路线编码',
      dataIndex: 'code',
      isFilter: true,
      sorter: true,
      width: 150,
      render: (value: string, record: TableRowType, index: number, config: any) => (
        <LinkTooltip
          to={`${ENGINEER_PATH}/routing/${record.id}/detail`}
          text={value}
          width={config.contentWidth}
          auth={authDict.routing_detail}
        />
      ),
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '工艺路线名称',
      dataIndex: 'name',
      isFilter: true,
      sorter: true,
      width: 150,
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      type: fieldTypeList.select,
      isFilter: true,
      sorter: true,
      width: 150,
      render: renderUsageStatus,
      selectProps: {
        placeholder: '请选择',
        options: usageStatus,
      },
    },
    {
      title: '所属部门',
      dataIndex: 'departmentId',
      isFilter: true,
      width: 150,
      render: (text: any, record: TableRowType) => record?.department?.name,
      renderItem: <UserOrDepartmentSelectWithDialog placeholder="请选择" isMultiple />,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
    },
    {
      title: '物料编号',
      filterTitle: '物料',
      dataIndex: 'materialCode',
      isFilter: true,
      width: 150,
      render: (text: any, record: TableRowType) => {
        return record?.material?.baseInfo?.code;
      },
      // TODO 允许多选
      renderItem: <MaterialSelect />,
      // <SearchSelect mode="multiple" fetchType="materialCodeName" />,
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '物料名称',
      dataIndex: 'materialName',
      width: 150,
      render: (text: any, record: TableRowType) => {
        return record?.material?.baseInfo?.name;
      },
    },
    {
      title: '物料属性',
      dataIndex: 'materialAttributeIdList',
      isFilter: true,
      width: 150,
      inputProps: {
        placeholder: '请输入关键字',
      },
      renderItem: <MaterialAttributeCascader />,
      render: (_text: any, record: any) => (
        <TagList dataSource={getMaterialAttrs(record.material)} />
      ),
    },
    {
      title: '物料分类',
      dataIndex: 'materialCategoryIdList',
      isFilter: true,
      width: 150,
      renderItem: (
        <BcBlCascader
          fetchType={'materialCategory'}
          nameLabel={'物料分类'}
          popupPlacement={'bottomRight'}
        />
      ),
      render: (text: any, record: TableRowType) => {
        return record?.material?.category?.name;
      },
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '物料规格',
      width: 150,
      dataIndex: 'specification',
      render: (text: any, record: TableRowType) => {
        return record?.material?.baseInfo?.specification;
      },
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      isFilter: true,
      width: 150,
      render: renderUser,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择创建人" isMultiple isSelectUser />
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      width: 180,
      render: (text: any) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '更新人',
      dataIndex: 'operator',
      isFilter: true,
      width: 150,
      render: renderUser,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择更新人" isMultiple isSelectUser />
      ),
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      type: fieldTypeList.date,
      isFilter: true,
      sorter: true,
      width: 180,
      render: (text: any) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  const config = {
    rowKey: 'id',
    columns: dataColumns,
    getOperationList: (record: TableRowType) => {
      const isEnable = record.status === UsageStatus.enabled;
      const stateOperateText = isEnable ? '停用' : '启用';
      const displayText = isEnable ? '停用成功。关联的物料清单也全部停用成功' : '启用成功';
      const actionStatus = isEnable ? UsageStatus.disabled : UsageStatus.enabled;

      return [
        {
          title: '查看',
          auth: authDict.routing_detail,
          onClick: () => gotoRoutingDetail(record.id),
        },
        {
          title: '编辑',
          disabled: record.status !== UsageStatus.disabled,
          auth: authDict.routing_edit,
          onClick: () => gotoRoutingEdit(record.id),
        },
        {
          title: '复制',
          auth: authDict.routing_add,
          onClick: () => toRoutingCopy(record.id),
        },
        {
          title: stateOperateText,
          auth: authDict.routing_enable_disable,
          onClick: () => {
            Modal.confirm({
              title: `确定${stateOperateText}？`,
              content: '',
              onOk: () => updateStatus(record.id, actionStatus, displayText, refreshTable),
            });
          },
        },
        {
          title: '操作记录',
          auth: authDict.routing_operrecord,
          onClick: () => {
            setDetailId(record.id);
            setVisibleLog(true);
          },
        },
      ];
    },
    // 批量
    batchMenu: [
      {
        title: '启用',
        auth: authDict.routing_enable_disable,
        onClick: () =>
          patchRequest(
            fetchProcessRouteUpdateStatus,
            {
              status: UsageStatus.enabled,
              idList: selectedRowKeys.map((key) => Number(key)),
            },
            '启用',
            '启用成功',
          ),
      },
      {
        title: '停用',
        auth: authDict.routing_enable_disable,
        onClick: () =>
          patchRequest(
            fetchProcessRouteUpdateStatus,
            {
              status: UsageStatus.disabled,
              idList: selectedRowKeys.map((key) => Number(key)),
            },
            '停用',
            '停用成功。关联的物料清单也全部停用成功',
          ),
      },
    ],
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((columns: any): FilterItem => {
        const filter = {
          label: columns.filterTitle || columns.title,
          name: columns.dataIndex,
          type: columns.type,
          ...columns,
        };

        return filter;
      }),
    formatDataToFormDisplay: (filterData: any) => {
      const { createdAt, updatedAt, ...rest } = filterData;

      const result = {
        ...rest,
        createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
        updatedAt: gcTime.formatRangeTimeToMoment(updatedAt || []),
      };

      return gcObject.filterEmptyProperty(result);
    },
    formatDataToQuery: (param: any) => {
      const {
        creator,
        operator,
        quickSearch,
        createdAt = [],
        updatedAt = [],
        departmentId,
        materialCode,
        materialAttributeIdList,
        materialCategoryIdList,
        ...rest
      } = param;
      const [createdStartAt, createdEndAt] = gcTime.formatTimeRangeUnix(createdAt);
      const [updatedStartAt, updatedEndAt] = gcTime.formatTimeRangeUnix(updatedAt);
      const material = JSON.parse(materialCode?.value || '{}');

      const options = {
        quickSearch,
        departmentIdList: isEmpty(departmentId) ? null : _.map(departmentId, 'value'),
        createdStartAt,
        createdEndAt,
        updatedStartAt,
        updatedEndAt,
        creatorIdList: isEmpty(creator) ? null : _.map(creator, 'value'),
        operatorIdList: isEmpty(operator) ? null : _.map(operator, 'value'),
        materialIdListByCode: material?.baseInfo ? [material?.baseInfo?.id] : null,
        materialCategoryIdList: materialCategoryIdList ? [_.last(materialCategoryIdList)] : null,
        materialAttributeIdList: formatMaterialAttributeToQuery(materialAttributeIdList),
        ...rest,
      };

      dispatch.excelImport.updateBusinessData({
        businessData: gcObject.filterEmptyProperty(options),
      });
      return gcObject.filterEmptyProperty(options);
    },
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        placeholder={'工艺路线编号/名称'}
        useIndex={false}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey) => {
          setSelectRowKeys(selectKey);
        }}
        refreshMarker={refreshMarker}
        mainMenu={[
          {
            title: '导出',
            showExport: true,
            auth: authDict.routing_export,
            icon: <BlIcon type="icondaochu" />,
            onClick: () => {
              openModal({
                optType: 'export',
                businessType: BUSINESS_TYPE.processRoute,
              });
            },
          },
          {
            title: '新建工艺路线',
            auth: authDict.routing_add,
            icon: <BlIcon type="iconxinjiantianjia" />,
            onClick: gotoRoutingCreate,
            items: [
              {
                title: '导入',
                showExport: true,
                auth: authDict.routing_import,
                onClick: () => {
                  openModal({
                    optType: 'import',
                    businessType: BUSINESS_TYPE.processRoute,
                  });
                },
              },
            ],
          },
        ]}
        requestFn={(params) => {
          return fetchProcessRouteList(params, { legacy: true });
        }}
        configcacheKey="processRoutingModule"
        {...config}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={'Routing'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default RoutingManage;
