import { mappingsFactory } from '../utils';
import { ListViewType, ReturnOrigin, ReturnStatus, SalesOrderOrigin, ApplicationStatus } from '.';

export const listViewType = mappingsFactory(
  ['按单据展示', ListViewType.order],
  ['按明细展示', ListViewType.detail],
);

export const returnOrigin = mappingsFactory(
  ['普通退货', ReturnOrigin.normal],
  ['协同退货', ReturnOrigin.cowork],
);

export const returnStatus = mappingsFactory(
  ['新建', ReturnStatus.new],
  ['执行中', ReturnStatus.executing],
  ['已结束', ReturnStatus.finished],
  ['已关闭', ReturnStatus.closed],
);

export const orderOrigin = mappingsFactory(
  ['普通订单', SalesOrderOrigin.normal],
  ['协同订单', SalesOrderOrigin.cowork],
);

export const applicationStatus = mappingsFactory(
  ['待处理', ApplicationStatus.waiting],
  ['已接受', ApplicationStatus.accepted],
  ['已拒绝', ApplicationStatus.rejected],
);
