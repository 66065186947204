const fieldsLabel = {
  code: '参数编号',
  name: '参数名称',
  businessType: '业务类型',
  paramsType: '参数类型',
  paramsClassification: '参数分类',
  unit: '参数单位',
  status: '启用状态',
  remark: '备注',
};

export { fieldsLabel };
