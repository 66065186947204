import React, { useEffect, useState } from 'react';
import { CascaderOptionType, CascaderProps } from 'antd/lib/cascader';
import { BlCascader } from '@blacklake-web/component';
import { configs } from './config';
import { STATUS_VALUE } from 'src/page/resource/areaConfig/constants';
import _ from 'lodash';

export interface Props {
  onChange?: (value: any) => void;
  value?: string[] | number[] | any[];
  nameLabel?: string;
  placeholder?: string;
  showSearch?: boolean;
  fetchType: keyof typeof configs;
  popupPlacement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
  disabled?: boolean;
  optionInValue?: boolean;
  params?: any;
  displayRender?: CascaderProps['displayRender'];
  changeOnSelect?: CascaderProps['changeOnSelect'];
  expandTrigger?: CascaderProps['expandTrigger'];
}
const BcBlCascader = (props: Props) => {
  const {
    onChange,
    value,
    nameLabel,
    fetchType,
    placeholder = '请选择',
    popupPlacement = 'bottomLeft',
    disabled = false,
    optionInValue = false,
    params,
    ...restProps
  } = props;
  const [optionsList, setOptionsList] = useState<CascaderOptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchFn, formatter, loadData, fieldNames } = configs[fetchType];

  function handelOnChange(value: any, selectedOptions: any) {
    const selectedOption = selectedOptions.map(({ label, value, dataSource }: any) => ({
      label,
      value,
      dataSource,
    }));

    onChange && onChange(optionInValue ? selectedOption : value);
  }
  const handleFormatData = (
    list: any[],
    fieldNames: { label: string; value: string | string[]; children: string; getDataSource?: any },
  ): any[] => {
    const data = list?.map((item: any) => {
      const children = _.get(item, fieldNames.children);
      const dataSource = fieldNames.getDataSource && fieldNames.getDataSource(item);
      if (item?.leaf === 0 || children?.length) {
        return {
          label: _.get(item, fieldNames.label),
          value: _.get(item, fieldNames.value),
          children: handleFormatData(children, fieldNames),
          dataSource,
        };
      }
      return {
        label: _.get(item, fieldNames.label),
        value: _.get(item, fieldNames.value),
        isLeaf: !item.hasChildren,
        children: undefined,
        dataSource,
      };
    });

    return data;
  };
  const handleFetchData = async (searchParams?: string) => {
    let selectOptionsList: CascaderOptionType[] = [];

    let res: any;

    res = await fetchFn({ searchParams, ...params });

    const data = typeof formatter === 'function' ? formatter(res?.data) : res?.data?.list;

    selectOptionsList = handleFormatData(
      data,
      fieldNames || {
        label: 'name',
        value: 'id',
        children: 'children',
      },
    );

    return selectOptionsList;
  };
  const getList = async (searchParams?: string) => {
    setLoading(true);
    const selectOptionsList: CascaderOptionType[] = await handleFetchData(searchParams);

    if (selectOptionsList?.length > 0) {
      setOptionsList(selectOptionsList);
    }
    setLoading(false);
  };

  const handleFormatNodeData = (
    list: any[],
    fieldNames: { label: string; value: string },
  ): any[] => {
    const data = list?.map((item: any) => {
      return {
        label: item.name,
        value: item.id,
        isLeaf: !item.hasChildren,
      };
    });

    return data;
  };

  const getNode = async (options: CascaderOptionType[] | undefined) => {
    const targetOption = options?.[options.length - 1];
    if (targetOption) {
      targetOption.loading = true;
      const res: any = (await loadData?.(Number(targetOption?.value), STATUS_VALUE.ENABLED)) || [];
      const data = typeof formatter === 'function' ? formatter(res?.data) : res?.data?.list;
      const list = handleFormatNodeData(
        data,
        fieldNames || {
          label: 'name',
          value: 'id',
        },
      );

      targetOption.loading = false;
      if (optionsList.length !== 0) {
        targetOption.children = list.length ? list : undefined;
        setOptionsList([...optionsList]);
      } else {
        setOptionsList([...list]);
      }
    }
  };

  useEffect(() => {
    if (loadData) {
      getNode([{ value: 0 }]);
    } else {
      getList();
    }
  }, [params]);
  return loadData ? (
    <BlCascader
      {...restProps}
      placeholder={`${placeholder}${nameLabel || ''}`}
      value={optionInValue ? _.map(_.isArray(value) ? value : [value], 'value') : value}
      options={optionsList}
      onChange={handelOnChange}
      popupPlacement={popupPlacement}
      changeOnSelect
      loadData={getNode}
      disabled={disabled}
      loading={loading}
    />
  ) : (
    <BlCascader
      {...restProps}
      placeholder={`${placeholder}${nameLabel || ''}`}
      value={optionInValue ? _.map(_.isArray(value) ? value : [value], 'value') : value}
      options={optionsList}
      onChange={handelOnChange}
      popupPlacement={popupPlacement}
      showSearch
      disabled={disabled}
      loading={loading}
    />
  );
};

export default BcBlCascader;
