import React from 'react';
import PurchaseOrderList from './ordersList';
import MaterialsLineList from './materialsList';
import { VIEW_MODAL } from './constant';
import { TabList } from 'src/components';

const List = () => {
  const tabList = [
    {
      tab: '采购订单视图',
      key: VIEW_MODAL.ORDERS,
      component: <PurchaseOrderList />,
    },
    {
      tab: '物料行视图',
      key: VIEW_MODAL.MATERIALS,
      component: <MaterialsLineList />,
    },
  ];

  return <TabList tabList={tabList} defaultKey={VIEW_MODAL.ORDERS} />;
};

export default List;
