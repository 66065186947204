import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Row, Select, Tag } from 'antd';
import styles from '../style.module.scss';
import _ from 'lodash';
import { CheckItemInputTypeEnum } from 'src/dict/quality';
import { TextToolTip } from '@blacklake-web/component';

const SelectOptions = (props: { form: any; type: number }) => {
  const [optionList, setOptionList] = useState<string[]>([]);
  const [optionCheckInfo, setOptionCheckInfo] =
    useState<{ help?: string; validateStatus: string }>();

  const { form, type } = props;

  const key = 'radios';

  const initData = () => {
    form.setFieldsValue({ selectOptionInput: [] });
    const optionsList = form.getFieldValue([key]);

    setOptionList(optionsList ?? []);
  };

  useEffect(() => {
    initData();
  }, []);

  const addOption = (value: string) => {
    const checkInfo = checkOption(value, optionList);

    setOptionCheckInfo(checkInfo);
    if (checkInfo.validateStatus === 'success') {
      form.setFieldsValue({ selectOptionInput: null });
      form.setFieldsValue({ [key]: optionList.concat(value) });
      setOptionList(optionList.concat(value));
    }
  };

  const deleteOption = (removedTag: string) => {
    const newList = optionList.filter((i) => i !== removedTag);
    const qualityItems = form.getFieldValue('qualityItems').filter((i: string) => i !== removedTag);
    let defaultValue = form.getFieldValue('defaultValue');

    if (type === CheckItemInputTypeEnum.inputFormatMultiple) {
      defaultValue = defaultValue.filter((i: string) => i !== removedTag);
    } else if (defaultValue === removedTag) {
      defaultValue = null;
    }

    setOptionList(newList);
    form.setFieldsValue({ [key]: newList, qualityItems, defaultValue });
  };

  const checkOption = (value: string, optionsList: string[]) => {
    if (!value) {
      return { help: '选项值不能为空', validateStatus: 'error' };
    }
    if (optionsList.includes(value)) {
      return { help: '选项值不能重复', validateStatus: 'error' };
    }

    return { validateStatus: 'success' };
  };

  return (
    <div className={styles.groupContent}>
      <div>
        <Row>
          <Form.Item
            name="selectOptionInput"
            label="全部选项"
            style={{ marginBottom: 6, width: '80%' }}
            labelCol={{ flex: '80px' }}
            {...optionCheckInfo}
          >
            <Input
              placeholder="输入完成后按下Enter键进行保存"
              onPressEnter={(e: any) => addOption(e.target.value)}
            />
          </Form.Item>
          <Button
            type="primary"
            onClick={() => addOption(form.getFieldValue('selectOptionInput'))}
            style={{ marginLeft: 10 }}
          >
            {'确认添加'}
          </Button>
        </Row>
        <Form.Item name="radios" rules={[{ required: true, message: '至少设置一个选项' }]} noStyle>
          <div style={{ marginLeft: 80, marginBottom: 16 }}>
            {optionList?.map((option) => (
              <Tag closable onClose={() => deleteOption(option)} key={option}>
                <TextToolTip text={option} length={35} />
              </Tag>
            ))}
          </div>
        </Form.Item>
      </div>
      <div style={{ paddingBottom: 16 }}>
        <Form.Item
          name="qualityItems"
          label="合格选项"
          style={{ marginBottom: 6, width: '80%' }}
          labelCol={{ flex: '80px' }}
        >
          <Select
            options={optionList.map((option) => ({ value: option, label: option }))}
            allowClear
            mode="multiple"
          />
        </Form.Item>
        <Form.Item
          name="defaultValue"
          label="默认值"
          style={{ marginBottom: 6, width: '80%' }}
          labelCol={{ flex: '80px' }}
        >
          <Select
            options={optionList.map((option) => ({ value: option, label: option }))}
            allowClear
            mode={type === CheckItemInputTypeEnum.inputFormatMultiple ? 'multiple' : undefined}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default SelectOptions;
