/**
 * @description 流程管理 审批流
 * @author chenyurou
 */

/** 审批指定对象类型 */
export enum ApprovalDesignationObjectEnum {
  user = 0 /* 用户 */,
  role = 1 /* 角色 */,
  department = 2 /* 部门 */,
}

/** 审批方式 */
export enum ApprovalDesignationMethodEnum {
  orSing = 0 /* 或签 */,
  andSing = 1 /* 会签 */,
}

/** 关联触发规则 */
export enum AssociationTriggeringRuleEnum {
  exist = 1 /* 关联已有触发规则 */,
  customer /* 自定义 */,
}

/** 审批方案特定触发动作 */
export enum TriggeringActionEnum {
  add = 'add' /* 新建 */,
}

/** 类型 */
export enum typeEnum {
  customer = 0 /* 自定义类型 */,
  preset = 1 /* 预置类型 */,
}
