/**
 * @modal 匹配业务类型关系
 */

import { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import { Form, Modal, Steps, Select, Button, Spin, Descriptions, message, SelectProps } from 'antd';
import { useBlocker, qs } from 'src/utils';
import { fetchStandardBizObjectCustomObjectGetSonObjectAndFieldById } from 'ytt/metadata-domain/custom_object';
import {
  fetchBizTypeListWithoutPage,
  fetchBizTypeGetMapping,
  fetchBizTypeUpdateMapping,
  FetchBizTypeUpdateMappingRequest,
} from 'ytt/metadata-domain/bizType';
import { Matcher } from './matcher';
import type { BizList, BizMappings } from '../../types';

interface Props {
  visible: boolean;
  hide: () => void;
}

const defaultBizTypes = { main: [], son: [] };
const { Step } = Steps;

const getFullMapping = (mainBizList: BizList, mappings: BizMappings): BizMappings =>
  mainBizList.map(({ id }) => {
    const existingMapping = _.find(mappings, { mainId: id });

    return existingMapping ?? { mainId: id, sonIds: [] };
  });

const MatchModal: FC<Props> = ({ visible, hide }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [sonObjects, setSonObjects] = useState<SelectProps<any>['options']>([]);
  const [loadingComp, setLoadingComp] = useState<'data' | 'submit' | null>(null);
  const [bizTypes, setBizTypes] = useState<{ main: BizList; son: BizList }>(defaultBizTypes);
  const [form] = Form.useForm<FetchBizTypeUpdateMappingRequest>();
  const { objectCode, objectName } = qs.parse();
  const initValues: FetchBizTypeUpdateMappingRequest = {
    objectCode,
    sonObjectCode: undefined,
    mapping: [],
  };
  const { resetForm, shouldLeave } = useBlocker(form, { initValues });

  const onStepChange = (value: number) => {
    if (value === 1) {
      const sonObjectCode = form.getFieldValue('sonObjectCode');

      if (!sonObjectCode) {
        message.error('请先选择从对象');
        return;
      }
      setLoadingComp('data');
      Promise.all([
        // @ts-ignore
        fetchBizTypeListWithoutPage({ objectCode }),
        // @ts-ignore
        fetchBizTypeListWithoutPage({ objectCode: sonObjectCode }),
        fetchBizTypeGetMapping({ objectCode, sonObjectCode }),
      ])
        .then((resList) => {
          const main = resList[0].data!;

          setBizTypes({
            main,
            son: resList[1].data!,
          });
          resetForm({
            ...form.getFieldsValue(true),
            mapping: getFullMapping(main, resList[2].data?.mapping ?? []),
          });
        })
        .finally(() => setLoadingComp(null));
    } else {
      setBizTypes(defaultBizTypes);
    }
    setCurrentStep(value);
  };

  const handleCancel = () => {
    if (currentStep === 0 || shouldLeave()) {
      resetForm(initValues);
      hide();
    }
  };

  const handleFinish = () => {
    const values = form.getFieldsValue(true);

    setLoadingComp('submit');
    fetchBizTypeUpdateMapping(values)
      .then(() => {
        message.success('保存完成。');
        resetForm(values);
        handleCancel();
      })
      .catch(() => setLoadingComp(null));
  };

  const footer = (
    <div style={{ display: 'flex', justifyContent: 'center', gap: 16 }}>
      <Button type="default" onClick={handleCancel}>
        取消
      </Button>
      {currentStep === 0 && (
        <Button type="primary" onClick={() => onStepChange(1)}>
          下一步
        </Button>
      )}
      {currentStep === 1 && (
        <>
          <Button onClick={() => onStepChange(0)}>上一步</Button>
          <Button type="primary" onClick={handleFinish}>
            确定
          </Button>
        </>
      )}
    </div>
  );

  useEffect(() => {
    if (visible) {
      setCurrentStep(0);
      fetchStandardBizObjectCustomObjectGetSonObjectAndFieldById({ objectCode }).then((res) => {
        setSonObjects(
          (res.data ?? []).map((item) => ({
            label: item.objectName!,
            value: item.objectCode!,
          })),
        );
      });
    }
  }, [visible]);

  return (
    <Modal
      className="bl-modal"
      visible={visible}
      title="匹配业务类型关系"
      width={608}
      okButtonProps={{ loading: loadingComp === 'submit' }}
      onCancel={handleCancel}
      footer={footer}
      maskClosable={false}
      destroyOnClose
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Steps
          onChange={onStepChange}
          current={currentStep}
          style={{ width: 376, marginBottom: 24 }}
        >
          <Step title="选择从对象" />
          <Step title="匹配业务类型" />
        </Steps>
      </div>
      <Form form={form}>
        {currentStep === 0 && (
          <>
            <Descriptions>
              <Descriptions.Item label="当前主对象">{objectName}</Descriptions.Item>
            </Descriptions>
            <Form.Item name="objectCode" hidden />
            <Form.Item name="sonObjectCode" label="匹配从对象">
              <Select options={sonObjects} allowClear />
            </Form.Item>
          </>
        )}
        {currentStep === 1 && (
          <Spin spinning={loadingComp === 'data'}>
            <Matcher {...bizTypes} />
          </Spin>
        )}
      </Form>
    </Modal>
  );
};

export default MatchModal;
