import { appEnum } from 'src/dict';
import { UsageStatus } from 'src/dict/common';
import { CheckItemInputTypeEnum } from 'src/dict/quality';

export enum LOGIC {
  BETWEEN = 1, // 区间
  LT, // 小于
  GT, // 大于
  EQ, // 等于
  LTE, // 小于等于
  GTE, // 大于等于
  TOLERANCE, // 允差
  null,
}

/** 检验项中数值范围的显示和计算 */
export const QC_LOGIC_TYPE = [
  {
    value: 'between',
    display: '区间',
    key: LOGIC.BETWEEN,
  },
  {
    value: 'lt',
    display: '<',
    key: LOGIC.LT,
  },
  {
    value: 'gt',
    display: '>',
    key: LOGIC.GT,
  },
  {
    value: 'equal',
    display: '=',
    key: LOGIC.EQ,
  },
  {
    value: 'lte',
    display: '≤',
    key: LOGIC.LTE,
  },
  {
    value: 'gte',
    display: '≥',
    key: LOGIC.GTE,
  },
  {
    value: 'tolerance',
    display: '允差',
    key: LOGIC.TOLERANCE,
  },
];

/** 检验项标准值的显示 */
export const getStandardValue = (type: LOGIC, logicValues: any, showUnit = true): string => {
  const { min, max, base, unit, executeItemType, qualityItems } = logicValues;
  let value: string = base;
  const unitDisply =
    executeItemType === CheckItemInputTypeEnum.inputFormatPercent
      ? '%'
      : `${unit?.label || unit?.name || ''}${
          unit?.status === UsageStatus.disabled ? '(停用)' : ''
        }`;

  if (type === LOGIC.null) {
    return '-';
  }
  if (type === LOGIC.BETWEEN) {
    value = `${min} ~ ${max}`;
  }
  if (type === LOGIC.GT) {
    value = `>${base}`;
  }
  if (type === LOGIC.GTE) {
    value = `>=${base}`;
  }
  if (type === LOGIC.LT) {
    value = `<${base}`;
  }
  if (type === LOGIC.LTE) {
    value = `<=${base}`;
  }
  if (type === LOGIC.TOLERANCE) {
    value = `${base}(${max}, ${min})`;
  }
  if (type === LOGIC.EQ) {
    value = `=${base}`;
  }

  return type ? `${value || '-'} ${showUnit ? unitDisply : ''}` || '-' : '-';
};

export const NUMBER_SCALE = 6;
const PERCENT_SCALE = 4;

export const getDefaultScale = (chekItemInputType: number) => {
  return chekItemInputType === appEnum.Quality.CheckItemInputTypeEnum.inputFormatPercent
    ? PERCENT_SCALE
    : NUMBER_SCALE;
};
