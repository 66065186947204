import { message } from 'antd';
import { AxiosRequestConfig } from 'axios';

import instance from './instance';
import { recursiveTrim } from '../string';
import envConfig from 'src/configs/envConfig';

interface RequestOptions extends AxiosRequestConfig {
  mock?: boolean;
  loading?: boolean;
  error?: boolean | undefined;
}

const defaultOptions = {
  mock: false,
  loading: true,
  error: true,
};

type MethodType = 'get' | 'post' | 'put' | 'delete';

const mockUrl = 'https://getman.cn/mock';
const hideCache: any = {}; // 全局loading
const generateHideKey = (): string => {
  const key = (Math.random() * 1000000).toFixed(0);

  if (hideCache[key]) {
    return generateHideKey();
  }

  return key;
};

/**
 * 传入参数处理
 * @description 全局去除字符串首尾空格
 */
const formatReqeustParams = (params: any) => {
  if (typeof params === 'object') {
    // 测试是否存在循环依赖, 如果存在则会直接报错
    try {
      JSON.stringify(params);
    } catch (error) {
      console.error(error);
      message.error('请求参数无法序列化，请检查是否存在循环依赖');
      return params;
    }
  }
  return recursiveTrim(params);
};

/**
 * 返回数据处理
 * @description 加了code不为0的处理，需要后端配合规范返回内容
 * @param response
 * @param resolve
 * @param reject
 * @param hasError
 * @returns
 */
const resolveResponseData = (
  response: any,
  resolve: any,
  reject: any,
  hasError = true,
  isMockRequest = false,
) => {
  if (response?.status === 200) {
    if (
      !process.env.REACT_APP_MOCK &&
      !isMockRequest &&
      response.data?.code !== 200 &&
      response?.config?.headers['Content-Type'] === 'application/json'
    ) {
      // 后端返回的code 只有为200的时候才是正确返回
      // 通过配置可关闭错误提示
      if (hasError) message.error(response.data?.message);
      reject?.(response);
    } else {
      if ((process.env.REACT_APP_MOCK || isMockRequest) && response?.data?.data?.list?.length) {
        response.data.data = {
          ...response?.data?.data,
          page: 1,
          total: Math.ceil(Math.random() * 1000),
        };
      }
      resolve?.(response.data);
    }
  }
  return response.data;
};

/** 封装后的request
 * @param url    接口地址
 * @param method 调用类型，目前有 'get' | 'post' | 'put' | 'delete';
 * @param options 增加三个选项
 * mock: 是否使用mock数据;
 * loading: 请求时是否loading效果;
 * error: 如果response.status !== 0 是否提示错误信息
 * @param params 接口参数，get为URL后的传参的 quaryParma，post 和 put为 requestData
 * @returns Promise对象
 */
function request(
  url: string,
  method: MethodType,
  options?: RequestOptions | undefined,
  params?: any,
) {
  const key = generateHideKey();

  // if (options && options.loading) {
  //   hideCache[key] = message.loading({
  //     content: "加载中...",
  //     key,
  //     duration: 0,
  //   });
  // }
  let requestUrl = options && options.mock ? mockUrl + url : url;
  const isMockRequest = /^\/mock/.test(requestUrl);
  requestUrl = isMockRequest ? requestUrl : envConfig.API + requestUrl;

  return new Promise((resolve, reject) => {
    let data = {};
    const formattedParams = formatReqeustParams(params);

    if (method === 'get' || method === 'delete') data = { params: formattedParams };
    if (method === 'post' || method === 'put') data = { data: formattedParams };
    if (options?.cancelToken) data = { ...data, cancelToken: options.cancelToken };

    return instance({
      url: requestUrl,
      method,
      ...data,
    })
      .then(
        (res: any) => {
          return resolveResponseData(res, resolve, reject, options?.error ?? true, isMockRequest);
          // 此处作用很大，可以扩展很多功能。比如对接多个后台，数据结构不一致，可做接口适配器, 也可对返回日期/金额/数字等统一做集中处理
        },
        (error) => {
          if (error?.message) {
            message.error(error.message);
          }
          return reject(error);
        },
      )
      .catch((error) => {
        return reject(error);
      })
      .finally(() => {
        hideCache[key] && hideCache[key]();
        delete hideCache[key];
      });
  });
}

/** 封装后的request
 * @param url    接口地址
 * @param method 调用类型，目前有 'get' | 'post' | 'put' | 'delete';
 * @param options 增加三个选项
 * mock: 是否使用mock数据;
 * loading: 请求时是否loading效果;
 * error: 如果response.status !== 0 是否提示错误信息
 * @param params 接口参数，get为URL后的传参的 quaryParma，post 和 put为 requestData
 * @returns Promise对象
 */
function download(
  url: string,
  method: MethodType,
  defaultFileName: string,
  options?: RequestOptions | undefined,
  params?: any,
) {
  const key = generateHideKey();

  if (options && options.loading) {
    hideCache[key] = message.loading({
      content: '下载中...',
      key,
      duration: 0,
    });
  }
  let requestUrl = options && options.mock ? mockUrl + url : url;
  const isMockRequest = /^\/mock/.test(requestUrl);
  requestUrl = isMockRequest ? requestUrl : envConfig.API + requestUrl;

  return new Promise((resolve, reject) => {
    let data = {};

    if (method === 'get' || method === 'delete') data = { params };
    if (method === 'post' || method === 'put') data = { data: params };
    if (options?.cancelToken) data = { ...data, cancelToken: options.cancelToken };

    return instance({
      url: requestUrl,
      method,
      responseType: 'blob',
      ...data,
    })
      .then(
        (res: any) => {
          const fileName = res.headers['content-disposition']?.split('filename=')[1];
          const blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const link = document.createElement('a');

          link.href = window.URL.createObjectURL(blob);
          link.download = decodeURIComponent(fileName) || defaultFileName;
          link.click();
          resolve(link.download);
        },
        (error) => {
          if (options?.error ?? true) error.message && message.error(error.message);
          reject();
        },
      )
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        hideCache[key] && hideCache[key]();
        delete hideCache[key];
      });
  });
}

export const get = (url: string, options = defaultOptions as RequestOptions, params?: any) => {
  return request(url, 'get', options, params);
};

export const post = (url: string, options = defaultOptions as RequestOptions, data?: any) => {
  return request(url, 'post', options, data);
};

export const put = (url: string, options = defaultOptions as RequestOptions, data?: any) => {
  return request(url, 'put', options, data);
};

export const _delete = (url: string, options = defaultOptions as RequestOptions, params?: any) => {
  return request(url, 'delete', options, params);
};

export const _download = (
  url: string,
  method: MethodType,
  defaultFileName: string,
  options = defaultOptions as RequestOptions,
  params?: any,
) => {
  return download(url, method, defaultFileName, options, params);
};

export interface RequestProps {
  get: (url: string, options: RequestOptions, params?: any) => Promise<any>;
  post: (url: string, options: RequestOptions, params?: any) => Promise<any>;
  put: (url: string, options: RequestOptions, params?: any) => Promise<any>;
  delete: (url: string, options: RequestOptions, params?: any) => Promise<any>;
  download: (
    url: string,
    method: MethodType,
    defaultFileName: string,
    options?: RequestOptions,
    params?: any,
  ) => Promise<any>;
}

const _request: RequestProps = {} as RequestProps;

_request.get = get;
_request.post = post;
_request.put = put;
_request.delete = _delete;
_request.download = _download;

export default _request;

export * from './getSharableFetch';
