import _ from 'lodash';
import { FC, useCallback, useEffect, useReducer, useState } from 'react';
import { useHistory, useParams, Prompt } from 'react-router';
import { Button, Form, Input, message, Space, Steps } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { fetchSopCreate, fetchSopDetail, fetchSopUpdate } from 'src/api/ytt/sop-domain';
import lookup from 'src/dict';
import { BizType, SopScheduleType } from 'src/dict/sop';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { AvatarDisplay } from 'src/components/avatar/show';
import StepList from '../components/stepList';
import { BlIcon, NumberRulesStandardHook } from 'src/components';
import { textValidator1, textValidator2 } from 'src/utils/formValidators';
import extractIdsForStep from '../share/extractIdsForStep';
import { mapSteps } from '../share/stepUtils';
import type { User } from 'src/page/share';
import styles from './style.module.scss';
import ConfirmModal from '../components/confirmModal';
import _Url from 'src/utils/url';
import { Status } from 'rc-steps/lib/interface';

interface RouteParams {
  bizType?: string;
  id?: string;
}

interface DisplayData {
  creator?: User;
  createdAt?: number;
  operator?: User;
  updatedAt?: number;
}

const BizTypeTextCom: FC<{ value?: BizType | string }> = ({ value }) => {
  if (_.isNil(value)) {
    return null;
  }
  const textMapping = new Map([
    [BizType.production, '支持与工序进行关联，常用于生产场景下的工艺路线内的工序'],
    [BizType.general, '常用于非生产场景下的作业流程，可以在创建SOP任务时关联SOP方案'],
    [BizType.maintenance, '与维保方案进行关联，常用于设备维护保养场景'],
    [BizType.repair, '与维修方案进行关联，常用于设备维修场景'],
  ]);

  const bizType = _.toNumber(value);
  const text = textMapping.get(bizType);

  return (
    <div>
      {lookup('sop.bizType', bizType)}
      <span style={{ marginLeft: '8px', fontSize: '12px' }}>
        <BlIcon type="icontixing-jingshi" />
        {text}
      </span>
    </div>
  );
};

const EditSOP = () => {
  const history = useHistory();
  const { bizType, id } = useParams<RouteParams>();
  const [form] = Form.useForm();
  const isEdit = _.isUndefined(bizType);
  const [loading, setLoading] = useState(isEdit);
  const [stepsList, setStepsList] = useState<any>([]);
  const [displayData, setDisplayData] = useState<DisplayData>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [, forceUpdate] = useReducer((i) => i + 1, 0);
  const [currentStep, setCurrentStep] = useState<SopScheduleType>(
    !_Url.getParams().lockedFirst && isEdit
      ? SopScheduleType.schemeConfiguration
      : SopScheduleType.basicInfo,
  );
  const [schemeConfigurationStauts, setSchemeConfigurationStauts] = useState<Status>('wait');
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isChangeField, setIsChangeField] = useState<boolean>(false);

  const fetchDetails = async () => {
    const { data } = await fetchSopDetail({ sopId: _.toNumber(id) }, { legacy: true });

    form.setFields(
      _.entries(
        _.pick(data, ['bizType', 'code', 'name', 'version', 'steps', 'sopId', 'referenceId']),
      ).map(([name, value]) => ({ name, value })),
    );
    data?.steps && setStepsList(data.steps);
    setDisplayData({
      creator: data?.creatorId,
      createdAt: data?.createdAt,
      operator: data?.operatorId,
      updatedAt: data?.updatedAt,
    });
    setLoading(false);
    setIsChangeField(false);
  };

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,
    title: '基本信息',
    items: _.compact([
      ...NumberRulesStandardHook({
        label: 'SOP方案编号',
        form,
        edit: isEdit,
        disabledState: isEdit,
        objectCode: 'SOPScheme',
        fieldCode: 'code',
        rules: [{ max: 120, message: '不可超过120个字符' }, { validator: textValidator2 }],
        onChange: forceUpdate,
      }),
      {
        label: 'SOP方案名称',
        name: 'name',
        rules: [
          { required: true },
          { validator: textValidator1 },
          { max: 200, message: '不超过200个字符' },
        ],
        render: () => <Input placeholder={'请输入名称'} onChange={forceUpdate} />,
      },
      {
        label: 'SOP方案版本号',
        name: 'version',
        rules: [
          { required: true, message: '请输入SOP方案版本号' },
          { validator: textValidator1 },
          { max: 200, message: '不超过200个字符' },
        ],
        render: () => <Input placeholder={'请输入SOP方案版本号'} onChange={forceUpdate} />,
      },
      {
        label: '业务类型',
        name: 'bizType',
        render: () => <BizTypeTextCom />,
      },
      isEdit && {
        label: '创建人',
        render: () => <AvatarDisplay {...displayData?.creator!} isUser />,
      },
      isEdit && {
        label: '创建时间',
        render: () => formatTimeForRender(displayData?.createdAt!),
      },
      isEdit && {
        label: '更新人',
        render: () => <AvatarDisplay {...displayData?.operator!} isUser />,
      },
      isEdit && {
        label: '更新时间',
        render: () => formatTimeForRender(displayData?.updatedAt!),
      },
      isEdit && {
        name: 'sopId',
        hidden: true,
        render: () => null,
      },
      isEdit && {
        name: 'referenceId',
        hidden: true,
        render: () => null,
      },
    ]),
  };

  const stepList: DataFormLayoutInfoBlock = {
    column: 1,
    title: 'SOP步骤列表',
    items: [
      {
        name: 'steps',
        isFullLine: true,
        render: () => (
          <StepList
            history={history}
            isEdit={isEdit}
            data={stepsList}
            sopId={Number(id)}
            reFetch={fetchDetails}
          />
        ),
      },
    ],
  };
  const handleCurrentStepChange = useCallback(
    (step) => {
      // TODO
      if (isChangeField) {
        message.error('您当前SOP方案编辑的内容尚未保存,请保存');
        return;
      }

      _Url.setParams({
        lockedFirst: false,
      });
      if (!isEdit) {
        return;
      }

      setCurrentStep(step);
    },
    [isEdit, isChangeField],
  );

  const handleCancel = useCallback(() => {
    history.push('/sop/processEngine/scheme');
  }, []);

  function handleFormatCreateData(values: any) {
    return { ...values, steps: [], bizType: Number(values.bizType) };
  }
  const createFn = async (values: any) => {
    try {
      const res = await fetchSopCreate(handleFormatCreateData(values));

      _Url.setParams({
        sopId: res?.data?.sopId ?? 0,
      });
      // setHasSaved(true);
      setModalVisible(true);
    } catch (err) {
      console.log('err', err);
    }
  };

  function handleFormatEditData(values: any) {
    return { ...values, bizType: Number(values.bizType) };
  }
  const editFn = async (values: any) => {
    if (isChangeField) {
      try {
        await fetchSopUpdate(handleFormatEditData(values));
        setModalVisible(true);
        setIsChangeField(false);
      } catch (err) {
        console.log('err', err);
      }
    } else {
      setCurrentStep(SopScheduleType.schemeConfiguration);
    }
  };

  const getSubmitValue = (values: any) => {
    return { ...values, steps: mapSteps(values.steps, extractIdsForStep) };
  };

  const handleFinish = async () => {
    const values = await form?.validateFields();
    // const submitValue = { ...values, steps: mapSteps(values.steps, extractIdsForStep) };
    const submitValue = getSubmitValue(values);
    const fn = isEdit ? editFn : createFn;

    setIsSubmitting(true);
    try {
      await fn(submitValue);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalOk = async () => {
    setCurrentStep(SopScheduleType.schemeConfiguration);
    setModalVisible(false);
    history.push(`/sop/processEngine/scheme/${!isEdit ? _Url.getParams().sopId : id}/edit`);
  };
  const handleClose = () => {
    setModalVisible(false);

    // form.resetFields();
    // setHasSaved(false);
    history.push(
      `/sop/processEngine/scheme/${!isEdit ? _Url.getParams().sopId : id}/edit?lockedFirst=true`,
    );
  };
  const handleModalCancel = () => {
    _.delay(() => {
      history.push('/sop/processEngine/scheme');
    }, 0);
  };

  useEffect(() => {
    if (stepsList.length) {
      setSchemeConfigurationStauts('finish');
    } else {
      setSchemeConfigurationStauts(
        currentStep === SopScheduleType.schemeConfiguration ? 'process' : 'wait',
      );
    }
  }, [currentStep, stepsList]);

  useEffect(() => {
    if (isEdit) {
      // 编辑
      fetchDetails();
    } else {
      // 新建
      form.setFields([{ name: 'bizType', value: bizType }]);
    }
  }, [isEdit, id]);

  return (
    <div className={styles.sopEditWrap}>
      <div className={styles.stepsCon}>
        <Steps current={currentStep} onChange={handleCurrentStepChange}>
          <Steps.Step title="基本信息" />
          <Steps.Step title="配置步骤" status={schemeConfigurationStauts} disabled={!isEdit} />
        </Steps>
      </div>
      <div className={styles.sopEditCon}>
        {currentStep === 0 && (
          <DataFormLayout
            form={form}
            formProps={{
              onValuesChange: (changedValues: any) => {
                if (changedValues) {
                  setIsChangeField(true);
                }
              },
            }}
            info={[baseInfo]}
            onCancel={handleCancel}
            onFinish={handleFinish}
            confirmLoading={isSubmitting}
            loading={loading}
          />
        )}
        {currentStep === 1 && (
          <DataFormLayout
            form={form}
            info={[stepList]}
            confirmLoading={isSubmitting}
            loading={loading}
            footer={
              <div className="steps-action" style={{ display: 'flex', justifyContent: 'center' }}>
                <Space size={24}>
                  <Button
                    key="cancel"
                    onClick={() => {
                      history.push('/sop/processEngine/scheme');
                    }}
                  >
                    取消
                  </Button>
                  <Button
                    key="prevStep"
                    type="primary"
                    onClick={() => {
                      setCurrentStep(SopScheduleType.basicInfo);
                    }}
                  >
                    上一步
                  </Button>
                </Space>
              </div>
            }
          />
        )}
      </div>
      <ConfirmModal
        visible={modalVisible}
        isEdit={isEdit}
        handleOk={handleModalOk}
        handleCancel={handleModalCancel}
        handleClose={handleClose}
      />
      {!modalVisible && isChangeField && (
        <Prompt message="您当前SOP方案编辑的内容尚未保存，是否退出？" />
      )}
    </div>
  );
};

export default EditSOP;
