import _ from 'lodash';
import { replaceSign } from 'src/utils/constants';
import { TagList } from 'src/components';

export const mappingMaterialInfoAttrList = (list: any) => {
  if (_.isArray(list) && !_.isEmpty(list)) {
    const attrList = _.map(list, (el) => {
      const attributeItem = _.get(el, 'attributeItem', {});

      return {
        label: `${el.name}: ${attributeItem.content}`,
        value: _.get(el, 'id'),
      };
    });

    return <TagList dataSource={attrList} />;
  }
  return replaceSign;
};

export function areaNum(obj: any, num: number = 0): number {
  if (!obj) return num;

  num++;

  if (obj?.sonArea) {
    return areaNum(obj.sonArea, num);
  }
  return num;
}

/**
 * 校验小数位长度
 * @param precisionFigure
 * @param roundingRole
 */
export const validateDecimalLength =
  (precisionFigure: number, roundingRole?: number) => (rule: any, value: any) => {
    const decimalLength = String(value).split('.')?.[1] || '';

    if (decimalLength.length > precisionFigure) {
      return Promise.reject(`小数点后最多保留${precisionFigure}位小数`);
    }
    return Promise.resolve();
  };
