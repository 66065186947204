/** 通知消息显示方式 */
export enum NOTIFICATION_TYPE {
  /** 平铺 */
  tile = 'tile',
  /** 业务对象 */
  business_object = 'business_object',
  /** 业务类型 */
  // business_type = 'business_type',
  /** 标题 */
  // title = 'title',
}

// /** 通知消息显示方式 */
// export enum CHOOSE_TYPE {
//   /** 平铺 */
//   tile = 0,
//   /** 业务对象 */
//   business_object = 1,
//   /** 业务类型 */
//   business_type = 2,
//   /** 标题 */
//   // title = 'title',
// }
