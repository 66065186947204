import { RecordListLayout as RecordList } from '@blacklake-web/layout';
import { RecordListLayoutProps } from '@blacklake-web/layout';
import { getAuthFromLocalStorage } from 'src/utils/auth';

const RecordListLayout = <RecordType extends object = any>(
  props: RecordListLayoutProps<RecordType>,
) => {
  return <RecordList userAuth={getAuthFromLocalStorage()} {...props} />;
};

export default RecordListLayout;
