import _ from 'lodash';
import { FeedingQualityStatus } from 'src/dict/material';
import { FormInstance } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { SubstitutionStrategyEnum } from 'src/dict/alternativePlan';
import {
  FormAlternativePlanBaseInfo,
  FormOriginalAlternativeMaterialItem,
  FormAlternativeMaterialItem,
  FormAlternativePlan,
  SubmitAlternativePlanBaseInfo,
  SubmitOriginalAlternativeMaterialItem,
  SubmitAlternativeMaterialItem,
  SubmitAlternativePlan,
} from 'src/page/knowledgeManagement/engineerData/alternativePlan/types/create.type';
import { FetchAltGetResponse } from 'src/api/ytt/med-domain/alternative-plan';
import { FetchBomGetResponse } from 'src/api/ytt/med-domain/bom';
import { PickMode } from 'src/dict/bom';
import {
  ALTERNATIVE_MATERIALS,
  ORIGINAL_ALTERNATIVE_MATERIALS,
} from 'src/page/knowledgeManagement/engineerData/alternativePlan/helper/constants';

// 表单格式转换为提交格式
export const handleFormDataBeforeSubmit = (
  formData: FormAlternativePlan,
  neglectId?: boolean,
): SubmitAlternativePlan => {
  // 转换基本信息
  const baseInfo: SubmitAlternativePlanBaseInfo = {
    code: formData.code,
    name: formData.name,
    ownedDepartmentId: formData.ownedDepartmentId && formData.ownedDepartmentId[0].value,
    substitutionStrategy: formData.substitutionStrategy,
    specificBom: formData.specificBom,
    remark: formData.remark,
    bomId: formData.suitedBom && formData.suitedBom[0]?.version?.value,
    materialId: JSON.parse(formData.originalAlternativeMaterials[0].materialId.value).baseInfo
      .id as number,
    id: neglectId ? undefined : formData.id,
    editReason: formData.editReason,
  };
  // 转换被替代物料列表
  const originalAlternativeMaterials: SubmitOriginalAlternativeMaterialItem[] =
    formData.originalAlternativeMaterials.map((item) => ({
      materialId: JSON.parse(item.materialId.value).baseInfo.id as number,
      amountNumerator: String(item.amountNumerator),
      amountDenominator: String(item.amountDenominator),
      unitId: item.unitId.value,
      mainFlag: item.mainFlag,
      id: neglectId ? undefined : item.id,
      useRatio: item.useRatio,
    }));
  // 转换替代物料列表
  const alternativeMaterials: SubmitAlternativeMaterialItem[] = formData.alternativeMaterials.map(
    (item) => ({
      priority: item.priority,
      materialId: JSON.parse(item.materialId.value).baseInfo.id as number,
      amountNumerator: String(item.amountNumerator),
      amountDenominator: String(item.amountDenominator),
      unitId: item.unitId.value,
      pickMode: item.pickMode,
      feedingQualityStatus: _.isEmpty(item.feedingQualityStatus)
        ? [FeedingQualityStatus.qualified, FeedingQualityStatus.concession]
        : item.feedingQualityStatus,
      useUpperLimitRatio: _.isNil(item.useUpperLimitRatio)
        ? undefined
        : String(item.useUpperLimitRatio),
      id: neglectId ? undefined : item.id,
      mainFlag: item.mainFlag,
      remark: item.remark,
      useRatio: item.useRatio,
    }),
  );

  return {
    ...baseInfo,
    originalAlternativeMaterials,
    alternativeMaterials,
  };
};

// 响应数据格式转换为表单格式
export const handleResponseToFormData = (
  res: FetchAltGetResponse,
): FormAlternativePlan | undefined => {
  if (res.code !== 200 || !res.data) return;
  const { data } = res;
  // 转换基本信息
  const baseInfo: FormAlternativePlanBaseInfo = {
    code: data.code ?? '',
    name: data.name ?? '',
    ownedDepartmentId: data.ownedDepartmentId
      ? [
          {
            label: data.ownedDepartmentName ?? '',
            value: data.ownedDepartmentId,
          },
        ]
      : undefined,
    substitutionStrategy: data.substitutionStrategy ?? SubstitutionStrategyEnum.mixedSubstitute,
    specificBom: data.specificBom ?? 0,
    remark: data.remark,
    suitedBom: undefined,
    id: data.id,
  };
  // 转换被替代物料列表
  const originalAlternativeMaterials: FormOriginalAlternativeMaterialItem[] =
    data!.originalAlternativeMaterials!.map((item) => ({
      materialId: {
        label: item?.material?.baseInfo?.code ?? '',
        value: JSON.stringify(item.material),
      },
      amountNumerator: Number(item.amountNumerator),
      amountDenominator: Number(item.amountDenominator),
      unitId: {
        label: item?.unitName ?? '',
        value: item.unitId ?? 0,
      },
      mainFlag: item.mainFlag,
      id: item.id,
      useRatio: item?.useRatio,
    }));
  // 转换替代物料列表
  const alternativeMaterials: FormAlternativeMaterialItem[] = data!.alternativeMaterials!.map(
    (item) => ({
      priority: item.priority ?? 1,
      materialId: {
        label: item?.material?.baseInfo?.code ?? '',
        value: JSON.stringify(item.material),
      },
      amountNumerator: Number(item.amountNumerator),
      amountDenominator: Number(item.amountDenominator),
      unitId: {
        label: item?.unitName ?? '',
        value: item.unitId ?? 0,
      },
      pickMode: item.pickMode ?? PickMode.demandPick,
      feedingQualityStatus: item.feedingQualityStatus ?? [
        FeedingQualityStatus.qualified,
        FeedingQualityStatus.concession,
      ],
      useUpperLimitRatio: _.isNil(item.useUpperLimitRatio)
        ? undefined
        : Number(item.useUpperLimitRatio),
      id: item.id,
      mainFlag: item.mainFlag,
      remark: item.remark,
      useRatio: item?.useRatio,
    }),
  );

  return {
    ...baseInfo,
    originalAlternativeMaterials,
    alternativeMaterials,
  };
};

// 响应数据格式转换为适用的物料清单格式
export const handleResponseToSuitedBomFormData = (
  res: FetchBomGetResponse,
): Pick<FormAlternativePlanBaseInfo, 'suitedBom'> => ({
  suitedBom: [
    {
      materialId: {
        label: res?.data?.material?.baseInfo?.code ?? '',
        value: JSON.stringify(res?.data?.material),
      },
      version: {
        label: res?.data?.version ?? '',
        value: res?.data?.id ?? 0,
      },
    },
  ],
});

// 从被替代物料和替代物料中提取出物料编号列表
export const getMaterialCodeList = (
  nameList: (typeof ORIGINAL_ALTERNATIVE_MATERIALS | typeof ALTERNATIVE_MATERIALS)[],
  form: FormInstance,
) => {
  const codeList: string[] = [];

  nameList.forEach((item) => {
    const list = form.getFieldValue(item);

    list?.forEach((item: FormAlternativeMaterialItem | FormOriginalAlternativeMaterialItem) => {
      codeList.push(_.get(item, 'materialId.label'));
    });
  });

  return _.compact(codeList);
};

// 检查物料编号是否重复
export const checkMaterialCodeRepeat = (materialCodeList: string[], message: string) => {
  return (rule: RuleObject, value: { label: string }) => {
    const materialCode = value?.label;

    return new Promise((resolve, reject) => {
      let count = 0;

      for (let i = 0; i < materialCodeList.length; i++) {
        if (materialCodeList[i] === materialCode) {
          count++;
        }
        if (count >= 2) {
          break;
        }
      }
      if (count >= 2) return reject(message);
      return resolve(true);
    });
  };
};

// 延迟获取列表毫秒数
export const DELAY_GET_LIST_MS_GAP = 1000;

// 暂停一段时间
export const pauseSomeTime = (time: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};
