import { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import { Space } from 'antd';
import lookup, { appEnum } from 'src/dict';
import { BcAttachmentDetail, BlIcon } from 'src/components';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import { replaceSign } from 'src/utils/constants';
import { DetailLayoutMenuItem } from 'src/layout/detail/DetailLayout.type';
import _Time from 'src/utils/dataTypes/time';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import {
  fetchWorkCenterDetail,
  FetchWorkCenterDetailResponse,
} from 'src/api/ytt/work-center-domain/index';
import { CreatorData, UserListItem } from '../constants';
import { toEditWorkCenter, toCopyWorkCenter } from '../navigation';
import { WorkCenterGroupCOListFormField } from '../shared/attrFormTable';
import authDict from 'src/utils/auth';
import ResourceGroupDetails from '../resourceGroup/details';
interface Props extends RouteChildrenProps<{ id: string }> {
  id: number;
  refreshDetailsMarker: number | undefined;
}

const DetailsBaseInfo = (props: Props) => {
  const { refreshDetailsMarker, history } = props;

  const { match } = props;

  const [dataSource, setDataSource] = useState<FetchWorkCenterDetailResponse['data']>();

  const init = async () => {
    try {
      const res: any = await fetchWorkCenterDetail({ id: Number(match?.params?.id) });

      setDataSource(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  // const handleFinish = async (id: number | number[]) => {
  //   try {
  //     // const value = await delForm?.validateFields();
  //     const idList = typeof id === 'number' ? [id] : id;
  //     const { code, message: mes } = await fetchWorkCenterDelete({
  //       idList,
  //       // reason: value.delReason,
  //     });

  //     if (code === 200) {
  //       message.success('删除成功');
  //       // 关闭details
  //       typeof onCancel === 'function' && onCancel();
  //       // 刷新 list
  //       typeof refreshTable === 'function' && refreshTable();
  //       return;
  //     }
  //     message.error(mes);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const initBaseMenu = () => {
    const baseMenu: DetailLayoutMenuItem[] = [
      {
        key: appEnum.Common.CRUD.copy,
        icon: <BlIcon type="iconfuzhi" />,
        title: lookup('crud', appEnum.Common.CRUD.copy),
        auth: authDict.workcenter_add,
        onClick: () => {
          dataSource?.id && history.push(toCopyWorkCenter(dataSource.id));
        },
      },
      {
        key: appEnum.Common.CRUD.edit,
        icon: <BlIcon type="iconbianji" />,
        title: lookup('crud', appEnum.Common.CRUD.edit),
        auth: authDict.workcenter_edit,
        onClick: () => {
          dataSource?.id && history.push(toEditWorkCenter(dataSource?.id));
        },
      },
    ];

    return [...baseMenu];
  };

  const baseInfo: DetailLayoutInfoBlock = {
    items: [
      { label: '工作中心编号', dataIndex: 'code', render: (text: string) => text ?? '-' },
      { label: '工作中心名称', dataIndex: 'name', render: (text: string) => text ?? '-' },
      {
        label: '所属部门',
        dataIndex: 'departmentListResponseDTOList',
        render: (text: UserListItem[]) => text?.map(({ name }) => name).join(',') ?? '-',
      },
      // 一期不做
      // {
      //   label: '日历标识',
      //   dataIndex: 'calendar',
      //   render: (text: number) => calendarMap?.get(text) ?? replaceSign,
      // },
      {
        label: '收料仓库',
        dataIndex: 'defaultReceiveWarehouse',
        render: (text: { name: string }) => text?.name ?? '-',
      },
      {
        label: '投料仓位',
        dataIndex: 'inputStorageList',
        render: (text: UserListItem[]) => text?.map(({ name }) => name).join(',') ?? '-',
      },
      {
        label: '产出仓位',
        dataIndex: 'outputStorageList',
        render: (text: UserListItem[]) => text?.map(({ name }) => name).join(',') ?? '-',
      },
      { label: '备注', dataIndex: 'remark', render: (text: string) => text ?? '-' },
      {
        label: '文件',
        dataIndex: 'fileIds',
        render: (value: number[]) => <BcAttachmentDetail fileIds={value} />,
      },
    ],
    column: 2,
    title: '基本信息',
  };
  const workCenterGroupCOList = {
    title: '资源清单',
    column: 2,
    items: [
      {
        isFullLine: true,
        label: '',
        dataIndex: 'workCenterGroupCOList',
        render: (list: WorkCenterGroupCOListFormField[]) => (
          // <ReplaceDetailTable initialData={list} />
          <ResourceGroupDetails initialData={list} />
        ),
      },
    ],
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: CreatorData) => {
          return (
            <Space>
              <AvatarDisplay
                id={creator?.id}
                name={creator?.name}
                avatarUrl={creator?.avatarUrl}
                key={creator?.id}
                isShowTag={false}
                isUser
              />
            </Space>
          );
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createTime',
        render: (createdAt: Date) => _Time.format(createdAt) ?? replaceSign,
      },

      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: CreatorData) => {
          return (
            <Space>
              <AvatarDisplay
                id={operator?.id}
                name={operator?.name}
                avatarUrl={operator?.avatarUrl}
                key={operator?.id}
                isShowTag={false}
                isUser
              />
            </Space>
          );
        },
      },
      {
        label: '更新时间',
        dataIndex: 'updateTime',
        render: (createdAt: Date) => _Time.format(createdAt) ?? replaceSign,
      },
    ],
    column: 2,
  };

  const infoArray = [baseInfo, workCenterGroupCOList, otherInfo];

  useEffect(() => {
    if (Number(match?.params?.id)) {
      init();
    }
  }, [refreshDetailsMarker]);
  return (
    <DetailLayout
      title={'工作中心详情'}
      baseMenu={initBaseMenu()}
      info={infoArray}
      dataSource={dataSource}
    />
  );
};

export default DetailsBaseInfo;
