import React, { useState } from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import DetailsBaseInfo from './detailsBaseInfo';
import CreateAndEdit from '../createAndEdit';

interface Props extends RouteComponentProps {
  match: match<{ id: string }>;
}
const DetailsPage = (props: Props) => {
  const { match: m } = props;
  const [createAndeditVisible, setCreateAndeditVisible] = useState(false);
  const [, setRefreshDetailsMarker] = useState<number | undefined>(undefined);

  return (
    <div style={{ background: '#fff', height: '100%' }}>
      <DetailsBaseInfo
        id={Number(m.params.id)}
        // 传该方法，则编辑为弹窗显示
        // showEdit={() => {
        //   setCreateAndeditVisible(true);
        // }}
        // refreshDetailsMarker={1}
        {...props}
      />
      {createAndeditVisible && (
        <CreateAndEdit
          id={Number(m.params.id)}
          visible={createAndeditVisible}
          onCancel={() => setCreateAndeditVisible(false)}
          // refreshDetails={() => {
          // setRefreshDetailsMarker(new Date().getTime());
          // }}
          {...props}
        />
      )}
    </div>
  );
};

export default DetailsPage;
