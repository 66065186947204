import UserTable from './optionalResources/userTable';
import Department from './optionalResources/department';
import Resources from './optionalResources/resources';

import { UserDetailTable } from './details/userDetailTable';
import { DepartmentDetailtable } from './details/departmentDetailTable';
import { ResourcesDetailTable } from './details/resourcesDetailTable';

/**
 * 资源组分类
 */
export const GroupTypesEnum = {
  /**
   * 用户
   */
  USER: 0,
  /**
   * 部门
   */
  DEPARTMENT: 1,
  /**
   * 资源分类
   */
  GROUPTYPE: 2,
};

/**
 * 创建｜编辑 groupType 转换 Componment
 */
export const GRPUPTYPE_MAP = new Map([
  [GroupTypesEnum.USER, UserTable],
  [GroupTypesEnum.DEPARTMENT, Department],
  [GroupTypesEnum.GROUPTYPE, Resources],
]);
/**
 * 创建｜编辑  bizType 转换 Componment
 */
export const BIZTYPE_MAP = new Map([
  [GroupTypesEnum.USER, UserTable],
  [GroupTypesEnum.DEPARTMENT, Department],
]);

/**
 * groupType 对应 displayList
 */
export const GRPUPTYPE_TO_DISPLAY = new Map([
  [GroupTypesEnum.USER, 'userList'],
  [GroupTypesEnum.DEPARTMENT, 'departmentList'],
  [GroupTypesEnum.GROUPTYPE, 'resourceList'],
]);

/**
 * bizType 对应 displayList
 */
export const BIZTYPE_TO_DISPLAY = new Map([
  [GroupTypesEnum.USER, 'userList'],
  [GroupTypesEnum.DEPARTMENT, 'departmentList'],
]);

/**
 * 详情 groupType 转换 Componment
 */
export const GRPUPTYPE_DETAILS_MAP = new Map([
  [GroupTypesEnum.USER, UserDetailTable],
  [GroupTypesEnum.DEPARTMENT, DepartmentDetailtable],
  [GroupTypesEnum.GROUPTYPE, ResourcesDetailTable],
]);
/**
 * 详情  bizType 转换 Componment
 */
export const BIZTYPE_DETAILS_MAP = new Map([
  [GroupTypesEnum.USER, UserDetailTable],
  [GroupTypesEnum.DEPARTMENT, DepartmentDetailtable],
]);

export const GroupTypesMap = new Map([
  [GroupTypesEnum.USER, '用户'],
  [GroupTypesEnum.DEPARTMENT, '部门'],
  [GroupTypesEnum.GROUPTYPE, '资源'],
]);
