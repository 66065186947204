import { CascaderValueType } from 'antd/lib/cascader';
import _, { cloneDeep, filter, findIndex, map } from 'lodash';
import { AllowType } from 'src/dict/noticeMessage';
import _Array from 'src/utils/dataTypes/array';
import _String from 'src/utils/dataTypes/string';
import { FieldObjType, Item, ReceiverType } from './interface';
import { message as Message } from 'antd';
import { showErrorListMessage } from 'src/components';
import { replaceSign } from 'src/utils/constants';

// 处理第三层数据
const formatThirdNode = (e: any) => {
  if (_Array.isEmpty(e?.fields)) {
    // 没有字段对象
    return [];
  }
  const newE = cloneDeep(e);

  const newData = newE?.fields?.map((node: any) => {
    return formatE(node);
  });

  return newData;
};

const formatE = (e: any) => {
  return {
    label: e?.fieldName,
    value: e?.fieldName,
    fieldName: e?.fieldName,
    fieldCode: e?.fieldCode,
    fieldId: e?.id,
    fieldTargetType: e?.targetType,
    fieldType: e?.fieldType,
    children: null,
  };
};

const formatNode = (e: any) => {
  if (_Array.isEmpty(e?.fields)) {
    // 没有字段对象
    return null;
  }
  const newE = cloneDeep(e);

  const newData = newE?.fields?.map((node: any) => {
    const newNode = cloneDeep(node);

    const relNode = formatE(newNode);

    if (node?.object) {
      // 代表有children
      relNode.children = formatThirdNode(node?.object);
    }

    return relNode;
  });

  return newData;
};

// 格式化处理后端tree_code接口返回的对象数据 处理成级联需要的数据格式
export const formatData = (data: any) => {
  const { objectCode } = data;

  if (_Array.isEmpty(data?.fields)) {
    // 没有字段对象
    return [];
  }

  const newE = cloneDeep(data);

  const newData = newE?.fields?.map((node: any) => {
    const newNode = formatE(node);

    const _newNode = { ...newNode, objectCode }; // 第一层需要返回objectCode 为了后端用于校验字段是否来自选择的对象

    if (node?.object) {
      // 代表有children
      _newNode.children = formatNode(node?.object);
    }

    return _newNode;
  });

  return newData;
};

// 获取输入框的焦点
export const getPosition = (ref: any) => {
  const CaretPos = {
    start: 0,
    end: 0,
  };

  if (ref.selectionStart) {
    CaretPos.start = ref.selectionStart;
  }
  if (ref.selectionEnd) {
    CaretPos.end = ref.selectionEnd;
  }
  return CaretPos;
};

// 获取当前传入类型的所有选择数据
export const getTypeSelect = (data: Item[], type: number) => {
  const _data =
    filter(data, (e: Item) => {
      const node = JSON.parse(e?.value);

      return node?.type === type;
    }) ?? [];

  return _data;
};

// 获取当前传入类型外的所有选择数据
export const getOtherTypeSelect = (data: Item[], type: number) => {
  const _data =
    filter(data, (e: Item) => {
      const node = JSON.parse(e?.value);

      return node?.type !== type;
    }) ?? [];

  return _data;
};

export const formatSelectToMessageReceivers = (
  messageReceivers: Item[],
  select: Item[],
  receiverType: number,
) => {
  const otherTypeSelect = getOtherTypeSelect(messageReceivers, receiverType);

  const _Select = map(select, (e: Item) => {
    const _e = cloneDeep(e);
    const newE = JSON.parse(_e?.value);

    return {
      ...e,
      key: newE?.id,
    };
  });

  const relMessageReceivers = otherTypeSelect?.concat(_Select);

  return relMessageReceivers;
};

// 格式化处理 选择级联后 获取到的value 和 options处理成 key-label的形成 便于后期提交时根据title和content的String去获取对应的字段
export const formatSelectOptions = (value: CascaderValueType, data?: any[]) => {
  const fileName = value.join('.');

  const _data = cloneDeep(data);

  const fieldObj: FieldObjType = {
    key: '',
    value: {},
  };

  const relData = map(_data, (node: any) => _.omit(node, ['children', 'label', 'value']));

  fieldObj.key = fileName;
  fieldObj.value = relData;

  return [fieldObj];
};

// 获取title和content的String的{}里的字段名称
export const getBracketStr = (text: string) => {
  if (_String.isEmpty(text)) return [];

  const regex = /\{(.+?)\}/g;

  const options = text.match(regex);

  if (_Array.isEmpty(options)) return [];

  const _options = map(options, (e: string) => {
    const _e = e.substring(1, e.length - 1);

    return _e;
  });

  return _options;
};

// 根据从title和content的String去获取到的字段以及保存的选择过的options  获取真实用到的字段
export const getRelFieldSelections = (field: string[], selectFields: FieldObjType[]) => {
  const relSelectFields = map(field, (e: any) => {
    const _selectFieldsIndex = findIndex(selectFields, (node: any) => e === node?.key);

    if (_selectFieldsIndex < 0) {
      // 代表不存在 直接返回null
      return null;
    }

    const res = selectFields[_selectFieldsIndex]?.value;

    return { fieldInformationList: res };
  }).filter((e) => e);

  return relSelectFields;
};

export const formatKeyLabelData = (data: any[]) => {
  return map(data, (node: any) => {
    const _node = JSON.parse(node?.value);

    return _node;
  });
};

// 处理创建和详情获取的values 处理成后端需要的格式
export const formatCrateAndEditValue = (
  value: any,
  inputSelectedOptions: FieldObjType[],
  textSelectedOptions: FieldObjType[],
) => {
  const {
    messageTitle,
    messageContent,
    allowRejection,
    objectCode,
    triggerRuleIds,
    messageReceivers,
    ...restValue
  } = value;

  const objectItem = JSON.parse(objectCode?.value);

  const _messageTitle = getBracketStr(messageTitle) ?? [];
  const _messageContent = getBracketStr(messageContent) ?? [];

  const titleSelectionList = getRelFieldSelections(_messageTitle, inputSelectedOptions);

  const contentSelectionList = getRelFieldSelections(_messageContent, textSelectedOptions);

  const _messageReceivers = map(formatKeyLabelData(messageReceivers), (node: any) => {
    return {
      receiverCode: node?.fieldCode ?? '',
      receiverId: node?.id ?? node?.fieldId,
      receiverName: node?.name ?? node?.fieldName,
      receiverType: node?.type,
      objectCode: node?.objectCode,
    };
  });

  return {
    ..._.omit(restValue, ['inputField', 'textField']),
    allowRejection: allowRejection ? AllowType.allow : AllowType.unAllow,
    messageTitle,
    messageContent,
    objectCode: objectItem?.objectCode,
    objectId: objectItem?.id ?? objectItem?.objectId,
    objectName: objectItem?.objectName,
    messageReceivers: _messageReceivers,
    contentFieldSelections: { fieldSelectionList: contentSelectionList },
    titleFieldSelections: { fieldSelectionList: titleSelectionList },
    triggerRuleIds: map(triggerRuleIds, 'key'),
  };
};

// 格式化从详情获取的title和content的selectOptions储存为需要的格式
export const formatInitDataToSelectOptions = (data?: any[]) => {
  return map(data, (e: any) => {
    const key = map(e?.singleSelection, 'fieldName').join('.');

    return {
      key,
      value: e?.singleSelection,
    };
  });
};

export const initMessageReceiversToForm = (data: any[], type: number) => {
  if (type === ReceiverType?.user || type === ReceiverType?.department) {
    // 用户或者部门
    return map(data, (node: any) => {
      const _node = {
        id: node?.receiverId,
        name: node?.receiverName,
        code: node?.receiverCode,
        type: node?.receiverType,
      };

      return {
        key: node?.receiverId,
        label: node?.receiverName,
        value: JSON.stringify(_node),
      };
    });
  }

  if (type === ReceiverType?.role) {
    // 角色
    return map(data, (node: any) => {
      const _node = {
        id: node?.receiverId,
        name: node?.receiverName,
        type: node?.receiverType,
        code: node?.receiverCode,
      };

      return {
        key: node?.receiverId,
        label: node?.receiverName,
        value: JSON.stringify(_node),
      };
    });
  }
  // 字段
  return map(data, (node: any) => {
    const _node = {
      fieldId: node?.receiverId,
      fieldName: node?.receiverName,
      fieldCode: node?.receiverCode,
      type: node?.receiverType,
      objectCode: node?.objectCode,
    };

    return {
      key: node?.receiverId,
      label: node?.receiverName,
      value: JSON.stringify(_node),
    };
  });
};

/**
 * 校验接收人最大数量限制
 */
export const validatorMaxCount = (rule: any, value: Item[]) => {
  if (value?.length > 20) {
    return Promise.reject('最多支持添加20个实例');
  }

  return Promise.resolve(true);
};

/**
 * 校验触发规则限制最大数量
 */
export const validatorRulesMaxCount = (rule: any, value: Item[]) => {
  if (value?.length > 10) {
    return Promise.reject('最多支持添加10个');
  }

  return Promise.resolve(true);
};

const errorColumns = [
  {
    title: '名称',
    dataIndex: '',
    width: 200,
    render: (_: any, record: any) => record || replaceSign,
  },
];

// 列表批量操作请求通用方法
export const batchRequest = (
  type: string,
  patchApi: any,
  selectedIds: number[],
  display: string,
  onSuccess: any,
  onFail?: any,
) => {
  patchApi({ messageTemplateIds: selectedIds })
    .then((res: any) => {
      const {
        data: { failCount, successCount, failReasons },
      } = res;

      if (failCount === 0) {
        Message.success(`${display}成功`);
        onSuccess?.();
        return;
      }
      if (successCount === 0) {
        onFail?.();
        // 全部删除失败
      } else if (failCount) {
        onSuccess?.();
        // 有失败的删除 需要展示失败信息
      }

      if (type === 'single') {
        Message.error(failReasons[0]);
      } else {
        showErrorListMessage({
          title: `${display}出现失败`,
          data: failReasons,
          columns: errorColumns,
          operateName: display,
          successCount,
          failCount,
          width: 800,
        });
      }
    })
    .catch((err: any) => {
      console.log('err: ', err);
      onFail();
    });
};
