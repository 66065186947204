import { useState } from 'react';
import { SearchSelect, SingleOperationLogComponent } from 'components';
import _ from 'lodash';
import { RecordListLayout, FilterItem } from 'layout';
import { Form, message as Message, message, Modal, Select } from 'antd';
import {
  fetchCustomerDelete,
  FetchCustomerDeleteRequest,
  FetchCustomerDeleteResponse,
} from 'src/api/ytt/order-domain/customer';
import {
  associationType as associationTypeMapping,
  auditStatus as auditStatusMapping,
} from 'src/dict/customer/mappings';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { lookup } from 'src/dict';
import { showErrorListMessage } from 'src/components/message/errorMessageList';
import { ErrorNotification } from 'src/components/notification';
import { replaceSign } from 'src/utils/constants';
import { fieldTypeList, gcTime, gcObject } from 'src/utils';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import {
  fetchCustomerRegAuditAccept,
  fetchCustomerRegAuditAcceptCheck,
  fetchCustomerRegAuditDecline,
  fetchCustomerRegAuditListCustomer,
  FetchCustomerRegAuditListCustomerRequest,
} from 'src/api/ytt/supply-chain-domain/customer_reg_audit';
import useBlModal from 'src/components/modal/useBlModal';
import { CustomerListItemPropsUser, CustomerRegAuditListCustomerItemProps } from '../../interface';
import { AssociationType, AuditStatus } from 'src/dict/customer';
import { avatarDisplay } from 'src/components/avatar/show';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import authDict from 'src/utils/auth';

const errorColumns = [
  {
    title: '客户名称',
    dataIndex: 'name',
  },
  {
    title: '失败原因',
    dataIndex: 'failReason',
  },
];

const CustomerRegisterList = () => {
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新页面
  const [selectedRowKeys] = useState<React.Key[]>([]); // 选中行的key，用以批量删除
  const [delForm] = Form.useForm();
  const refresh = () => setRefreshMarker(Date.now());
  const { show } = useBlModal();
  const [form] = Form.useForm();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const [detailId, setDetailId] = useState<number>(0);

  /**
   * 接受操作
   */
  const handleAccept = async (record: CustomerRegAuditListCustomerItemProps) => {
    try {
      const { id } = record;
      const acceptFn = async () => {
        const { code } = await fetchCustomerRegAuditAccept({
          customerId: form.getFieldValue('customerId')?.value,
          supplierRegisterAuditId: id as number,
        });

        if (code == 200) {
          refresh();
          message.success('接受邀请成功');
        } else {
          refresh();
          message.error('接受邀请失败');
        }
      };
      const checkFn = async () => {
        const { data } = await fetchCustomerRegAuditAcceptCheck({
          supplierRegisterAuditId: id as number,
        });

        return data;
      };
      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
      const content = (
        <Form form={form}>
          <Form.Item
            {...layout}
            name="type"
            label={'与客户关联方式：'}
            rules={[{ required: true, message: '与客户关联方式必选' }]}
          >
            <Select options={associationTypeMapping} placeholder={'请选择'} />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Form.Item
                {...layout}
                name="customerId"
                label={'关联客户：'}
                rules={[
                  {
                    required: form.getFieldValue('type') !== AssociationType.createNew,
                    message: '关联客户必选',
                  },
                ]}
                hidden={form.getFieldValue('type') === AssociationType.createNew}
              >
                <SearchSelect fetchType="customer" />
              </Form.Item>
            )}
          </Form.Item>
        </Form>
      );
      const config = {
        title: '接受邀请提示',
        content,
        width: 500,
        onOk: async () => {
          const values = await form.validateFields();

          if (values?.type) {
            acceptFn();
          }
        },
        okText: '确认',
        onCancel: () => {},
        cancelText: '取消',
      };

      checkFn().then(({ linked, customerCode, customerName }) => {
        // 未关联客户
        if (linked === false) {
          form.resetFields();
          show(config);
          return;
        }
        // 已存在关联客户
        message.error(
          `当前租户已与 ${customerCode || replaceSign}/${
            customerName || replaceSign
          } 客户已关联，不支持多次关联`,
        );
      });
    } catch (error) {
      console.log('error', error);
    }
  };
  /**
   * 拒绝操作
   */
  const handleReject = (record: CustomerRegAuditListCustomerItemProps) => {
    try {
      const { id } = record;
      const rejectFn = async () => {
        const { code } = await fetchCustomerRegAuditDecline({
          supplierRegisterAuditId: id as number,
        });

        if (code == 200) {
          refresh();
          message.success('拒绝成功');
        } else {
          refresh();
          message.error('拒绝失败');
        }
      };
      const config = {
        title: '确认要拒绝邀请吗？',
        content: '若确认拒绝，当前租户将不会向您发起采购',
        width: 436,
        onOk: () => {
          rejectFn();
        },
        okText: '确认拒绝',
        onCancel: () => {},
        cancelText: '再想想',
      };

      Modal.confirm(config);
    } catch (error) {
      console.log('error', error);
    }
  };
  /**
   * 删除操作
   */
  const handleDelete = async (
    onSuccess?: () => void,
    onFail?: () => void,
    idToDeleteAlone?: number,
  ) => {
    try {
      // 从确认删除的form里得到删除原因
      const reason = delForm.getFieldValue('delReason');
      const idsInParam = idToDeleteAlone ? [idToDeleteAlone] : (selectedRowKeys as number[]);
      const requestParam: FetchCustomerDeleteRequest = {
        editReason: reason,
        ids: idsInParam,
      };
      // 不明原因，ytt的FetchHandlingEquipmentDeleteResponse中data没有描述，只能强行转换成自定义类型
      const { data }: FetchCustomerDeleteResponse = await fetchCustomerDelete(requestParam);

      if (data) {
        const { success, fail } = data;

        // 全部删除成功，显示删除成功
        if (_.isNil(fail) || _.isEmpty(fail)) {
          Message.success('删除成功');
          onSuccess?.();
        } else if (_.isNil(success) || _.isEmpty(success)) {
          // 全部删除失败，提示删除失败及原因
          onFail?.();
          if (fail.length > 0) {
            ErrorNotification({
              message: '删除失败',
              description: (
                <>
                  {fail.map((failItem) => (
                    <div key={failItem.id}>
                      {`${failItem.name}(${failItem.code})`}: {failItem.detail}
                    </div>
                  ))}
                </>
              ),
            });
          } else {
            Message.error('删除失败！');
          }
        } else if (fail.length > 0) {
          onSuccess?.();
          // 否则用表格显示删除结果及原因统计的table
          showErrorListMessage({
            title: '删除出现错误',
            data: fail,
            columns: errorColumns,
            operateName: '删除',
            successCount: success.length,
            failCount: fail.length,
          });
        }
        // 删除成功以后清空删除表单里已经填的删除原因
        delForm.resetFields();
      } else {
        onFail?.();
        console.log('api返回的response里没有data');
      }
    } catch (error) {
      onFail?.();
      console.log(error);
    }
  };
  /**
   * Columns
   */
  const dataColumns = _.compact([
    {
      title: '租户编号',
      dataIndex: ['cusOrg', 'orgCode'],
      width: 150,
      type: fieldTypeList.text,
      render: (text: string) => text || replaceSign,
    },
    {
      title: '租户名称',
      dataIndex: ['cusOrg', 'orgName'],
      width: 150,
      type: fieldTypeList.text,
      render: (text: string) => text || replaceSign,
    },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 150,
      type: fieldTypeList.text,
      render: (text: string) => text || replaceSign,
    },
    {
      title: '状态',
      dataIndex: 'auditStatus',
      width: 120,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: auditStatusMapping,
        mode: 'multiple',
      },
      render: (text: number) => lookup('customer.auditStatus', text) || replaceSign,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 250,
      isFilter: true,
      sorter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: '操作人',
      dataIndex: 'operator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (user: Required<CustomerListItemPropsUser>) => {
        return user?.id ? avatarDisplay({ ...user, isUser: true }) : replaceSign;
      },
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择更新人" isMultiple isSelectUser />
      ),
    },
    {
      title: '操作时间',
      dataIndex: 'updatedAt',
      width: 250,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
  ]);
  /**
   * 列操作 ...
   */
  const getOperationList = (record: CustomerRegAuditListCustomerItemProps) => {
    const { id } = record;

    return [
      {
        title: '接受',
        disabled:
          record?.auditStatus === AuditStatus.accepted ||
          record?.auditStatus === AuditStatus.rejected,
        onClick: () => handleAccept(record),
        auth: authDict.customerregisteredaudit_accept_reg,
      },
      {
        title: '拒绝',
        disabled:
          record?.auditStatus === AuditStatus.accepted ||
          record?.auditStatus === AuditStatus.rejected,
        onClick: () => handleReject(record),
        auth: authDict.customerregisteredaudit_decline_reg,
      },

      {
        title: '操作记录',
        auth: authDict.customerregisteredaudit_operrecord,
        onClick: () => {
          setDetailId(id!);
          setVisibleLog(true);
        },
      },
    ];
  };
  /**
   * 列表筛选处理函数
   */
  const filterList: FilterItem[] = dataColumns
    .filter((i) => i.isFilter)
    .map((column) => {
      return _.pick(
        {
          ...column,
          label: column.title,
          name: column.dataIndex,
          type: column.type,
        },
        [
          'label',
          'name',
          'type',
          'rules',
          'renderItem',
          'selectProps',
          'inputProps',
          'datePickerProps',
          'dateFormat',
        ],
      ) as FilterItem;
    });

  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (param: any) => {
    const { createdAt, updatedAt, ...rest } = param;

    const result = {
      ...rest,
      createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
      updatedAt: gcTime.formatRangeTimeToMoment(updatedAt || []),
    };

    return gcObject.filterEmptyProperty(result);
  };
  /**
   * 传给接口数据处理函数
   */
  const formatDataToQuery = (param: any): FetchCustomerRegAuditListCustomerRequest => {
    const { creator, operator, createdAt, updatedAt, ...rest } = param;
    const [createdAtFrom, createdAtTo] = gcTime.formatRangeUnixToInt(createdAt);
    const [updatedAtFrom, updatedAtTo] = gcTime.formatRangeUnixToInt(updatedAt);

    const options = {
      createdAtFrom,
      createdAtTo,
      updatedAtFrom,
      updatedAtTo,
      creator: creator?.length ? _.map(creator, 'value') : null,
      operator: operator?.length ? _.map(operator, 'value') : null,
      ...rest,
    };

    return gcObject.filterEmptyProperty(options);
  };

  return (
    <>
      <RecordListLayout<CustomerRegAuditListCustomerItemProps>
        columns={dataColumns}
        filterList={filterList}
        rowKey={'id'}
        configcacheKey={'customerRegisterList'}
        placeholder={'请输入租户编号'}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        requestFn={fetchCustomerRegAuditListCustomer}
        refreshMarker={refreshMarker}
        getOperationList={getOperationList}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={OBJECT_OF_CODE.customerRegisteredAudit}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default CustomerRegisterList;
