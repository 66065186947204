import { RecordListLayout, FilterItem } from 'layout';
import { TextToolTip } from '@blacklake-web/component';
import _ from 'lodash';

import { fetchSalesOrderReturnListItem } from 'src/api/ytt/order-domain/salesReturnOrder';
import { SearchSelect } from 'src/components';
import { renderUser } from 'src/page/share/renderUser';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { fieldTypeList } from 'src/utils';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { intersectionValueOfId, mapLabeledValueToValue } from 'src/utils/array';
import { lookup } from 'src/dict';
import { returnOrigin, returnStatus } from 'src/dict/salesReturnOrder/mappings';
import { fieldLabels } from '../constants';
import type { SalesReturnDetailListItemType } from '../interface';
import { goToDetail } from '../navigates';
/**
 * 渲染数量
 */
const amountRender = (amountObj: SalesReturnDetailListItemType['amount']) => {
  return _.isNil(amountObj) ? undefined : `${amountObj?.amount}${amountObj?.unitName}`;
};

export default function DetailList() {
  /**
   * Columns
   */
  const columns = [
    {
      title: fieldLabels.materialName,
      dataIndex: ['material', 'baseInfo', 'name'],
      width: 120,
      isFilter: true,
      filterName: 'materialName',
      renderItem: (
        <SearchSelect fetchType="material" labelPath="name" labelInValue mode="multiple" />
      ),
    },
    {
      title: fieldLabels.materialCode,
      dataIndex: ['material', 'baseInfo', 'code'],
      width: 120,
      isFilter: true,
      filterName: 'materialCode',
      renderItem: (
        <SearchSelect fetchType="material" labelPath="code" labelInValue mode="multiple" />
      ),
    },
    {
      title: fieldLabels.plannedReturnAmount,
      dataIndex: 'amount',
      width: 110,
      render: amountRender,
    },
    {
      title: fieldLabels.inboundPlannedAmount,
      dataIndex: 'inboundPlannedAmount',
      width: 120,
      render: amountRender,
    },
    {
      title: fieldLabels.inboundAmount,
      dataIndex: 'inboundAmount',
      width: 120,
      render: amountRender,
    },
    {
      title: fieldLabels.customerReturnedAmount,
      dataIndex: 'customerReturnedAmount',
      width: 120,
      render: amountRender,
    },
    {
      title: fieldLabels.unit,
      dataIndex: ['amount', 'unitName'],
      width: 80,
    },
    {
      title: fieldLabels.lineNo,
      dataIndex: 'lineNo',
      width: 80,
    },
    {
      title: fieldLabels.code,
      dataIndex: 'returnOrderCode',
      width: 150,
      sorter: true,
      isFilter: true,
      filterName: 'code',
      type: fieldTypeList.text,
      render: (text: string, record: SalesReturnDetailListItemType, index: number, config: any) => (
        <a
          onClick={() => {
            goToDetail(record.returnOrderId!);
          }}
        >
          <TextToolTip text={text} width={config.contentWidth} />
        </a>
      ),
    },
    {
      title: fieldLabels.returnOrderStatus,
      dataIndex: 'returnStatus',
      width: 150,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: returnStatus,
        mode: 'multiple',
      },
      render: lookup('salesReturnOrder.returnStatus'),
    },
    {
      title: fieldLabels.origin,
      dataIndex: 'origin',
      width: 100,
      render: lookup('salesReturnOrder.returnOrigin'),
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: returnOrigin,
        mode: 'multiple',
      },
    },
    {
      title: fieldLabels.inboundOrderCode,
      dataIndex: 'inboundOrderCode',
      width: 120,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: fieldLabels.salesOrderItemLineNo,
      dataIndex: 'salesOrderItemLineNo',
      width: 140,
    },
    {
      title: fieldLabels.creator,
      dataIndex: 'creator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: renderUser,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: fieldLabels.createdAt,
      dataIndex: 'createdAt',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: fieldLabels.operator,
      dataIndex: 'operator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: renderUser,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: fieldLabels.updatedAt,
      dataIndex: 'updatedAt',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      width: 60,
      fixed: 'right',
      render: () => <div />,
    },
  ];
  /**
   * 列表筛选处理函数
   */

  const filterList: FilterItem[] = columns
    .filter((i) => i.isFilter)
    .map((column) => {
      return _.pick(
        {
          ...column,
          label: column.title,
          name: column.filterName ?? column.dataIndex,
          type: column.type,
        },
        [
          'label',
          'name',
          'type',
          'rules',
          'renderItem',
          'selectProps',
          'inputProps',
          'datePickerProps',
          'dateFormat',
        ],
      ) as FilterItem;
    });
  /**
   * 传给接口数据处理函数
   */
  const formatDataToQuery = (param: any) => {
    const { createdAt, updatedAt, materialCode, materialName, creator, operator, ...rest } = param;

    // 接口需要的创建时间和更新时间为number型的timestamp
    return {
      createdAtFrom: createdAt && createdAt[0] ? Date.parse(createdAt[0]) : undefined,
      createdAtTo: createdAt && createdAt[1] ? Date.parse(createdAt[1]) : undefined,
      updatedAtFrom: updatedAt && updatedAt[0] ? Date.parse(updatedAt[0]) : undefined,
      updatedAtTo: updatedAt && updatedAt[1] ? Date.parse(updatedAt[1]) : undefined,
      materialIds: intersectionValueOfId(materialCode, materialName),
      creatorIds: mapLabeledValueToValue(creator),
      operatorIds: mapLabeledValueToValue(operator),
      ...rest,
    };
  };
  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (param: any) => {
    return param;
  };
  /**
   * 筛选标签，数据显示
   */
  const formatDataToDisplay = (param: any) => {
    return param;
  };

  return (
    <RecordListLayout<SalesReturnDetailListItemType>
      filterList={filterList}
      requestFn={fetchSalesOrderReturnListItem}
      placeholder="请输入物料编号、销售退货单编号"
      rowKey="id"
      columns={columns}
      formatDataToDisplay={formatDataToDisplay}
      formatDataToQuery={formatDataToQuery}
      formatDataToFormDisplay={formatDataToFormDisplay}
    />
  );
}
