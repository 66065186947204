import { Button, Form, FormInstance, Input, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import { BlTable } from 'src/components';
import { QcTaskEntity } from 'src/page/quality/models/entities';
import { RecordEditModal } from './editReocrdModal';
import styles from 'src/page/quality/qcTask/styles.module.scss';
import _ from 'lodash';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import { CheckItem } from 'src/page/quality/models/dto';
import { CheckCountTypeEnum, RecordCheckItemTypeEnum } from 'src/dict/quality';
import { ColumnProps } from 'antd/lib/table';
import { ICustomFields } from 'src/components/customField';
import { TextControl } from 'src/components/text';
interface CheckItemRecordProps {
  form: FormInstance;
  qcTask?: QcTaskEntity;
  customFields?: ICustomFields;
  setActiveKey?: (key: string) => void;
}
interface ReportProps extends CheckItem {
  filledReportCount?: number;
}

const CheckItemRecord = (props: CheckItemRecordProps) => {
  const { qcTask, form, customFields, setActiveKey } = props;
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
  const [editCheckItem, setEditCheckItem] = useState<any>();
  const [list, setList] = useState<any[]>(_.values(form.getFieldValue(['reports'])) || []);
  const defaultScale = 6;

  useEffect(() => {
    setList(_.values(form.getFieldValue(['reports'])));
  }, [form.getFieldValue(['reports'])]);

  const columns: ColumnProps<any & ReportProps>[] = _.compact([
    {
      title: '分组',
      dataIndex: 'groupName',
      width: 160,
    },
    {
      title: '检验项名称',
      dataIndex: 'checkItemName',
      width: 160,
      render: (name, record) => (
        <div style={{ display: 'flex' }}>
          {form.getFieldValue(['reports', record.id, 'recordCheckItemType']) ===
            RecordCheckItemTypeEnum.yes && <span style={{ color: 'red' }}>*</span>}
          {name}
        </div>
      ),
    },
    {
      title: '检验项编号',
      dataIndex: 'checkItemCode',
      width: 160,
    },
    {
      title: '必填份数',
      dataIndex: 'requireReportCount',
      width: 160,
    },
    {
      title: '已填份数',
      dataIndex: 'filledReportCount',
      width: 160,
      render: (filledReportCount, record) => {
        return (
          <Form.Item
            name={['reports', record.id, 'filledReportCount']}
            rules={[
              {
                validator: numberMinMaxCheck({
                  min: record?.requireReportCount,
                  minAllowEqual: true,
                }),
                message: '还有检验报告未填写',
              },
            ]}
          >
            <TextControl />
          </Form.Item>
        );
      },
    },
    {
      title: '总份数',
      dataIndex: 'totalReportCount',
      width: 160,
    },
    !qcTask?.isGeneralQc() && {
      title: '抽检数量',
      dataIndex: 'taskCheckCount',
      width: 160,
      render: (checkCount, record) => {
        if (record.checkCountType === CheckCountTypeEnum.useExecuteSet) {
          return (
            <Form.Item
              name={['reports', record.id, 'taskCheckCount']}
              rules={[
                { validator: fractionLengthCheck(checkCount?.precisionFigure || defaultScale) },
              ]}
              style={{ margin: 'auto' }}
            >
              <InputNumber />
            </Form.Item>
          );
        }
        return checkCount;
      },
    },
    {
      title: '检验设备',
      dataIndex: 'equipments',
      width: 160,
      render: (equipments: { name: string }[]) => _.map(equipments, 'name').join(','),
    },
    {
      title: '备注',
      dataIndex: 'checkItemRemark',
      width: 160,
    },
    {
      title: '标准值',
      dataIndex: 'standValue',
      width: 160,
    },
    {
      title: '标准值单位',
      dataIndex: ['unit', 'name'],
      width: 160,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 160,
      fixed: 'right',
      render: (i: any, record: any) => (
        <a
          onClick={() => {
            setEditCheckItem(form.getFieldValue(['reports', `${record?.id}`]) || record);
            setVisibleEdit(true);
          }}
        >
          {'编辑'}
        </a>
      ),
    },
  ]);

  const searchByCheckItemName = (name: string) => {
    setList(
      (_.values(form.getFieldValue(['reports'])) || []).filter(
        (checkItem: { checkItemName: string }) => checkItem.checkItemName.includes(name),
      ),
    );
  };

  if (
    !qcTask?.getReportCount() &&
    (qcTask?.qcConfigEntity()?.isSameAsSampleCount() ||
      qcTask?.qcConfigEntity()?.reportPageSameAsInventory())
  ) {
    return (
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <Button
          type="primary"
          style={{ display: 'bloack' }}
          disabled={qcTask?.isUnStarted()}
          onClick={() => setActiveKey?.('checkMaterials')}
        >
          去设置
        </Button>
        <div>填写份数为0</div>
      </div>
    );
  }

  return (
    <div>
      <Input.Search
        placeholder="请输入检验项名称"
        className={styles.searchContent}
        onSearch={(name) => searchByCheckItemName(name)}
      />
      <BlTable<ReportProps>
        dataSource={list}
        columns={columns}
        useColConfig
        tableConfigKey="recordByCheckItem"
        resizableCol
        sticky
        rowKey={'id'}
        scroll={{ x: 'max-content' }}
        pagination={false}
      />
      {visibleEdit && (
        <RecordEditModal
          form={form}
          checkItem={editCheckItem}
          visible={visibleEdit}
          onClose={() => setVisibleEdit(false)}
          canAddReport={qcTask?.canAddReport()}
          customFields={customFields}
          isShowQrCode={
            qcTask?.qcConfigEntity().isRecordSample() &&
            qcTask?.isReportFormatType() &&
            Boolean(_.filter(qcTask?.sampleMaterials, 'qrCode')?.length)
          }
        />
      )}
    </div>
  );
};

export default CheckItemRecord;
