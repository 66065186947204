/**
 * @enum 触发规则模块枚举值
 */

/** 触发方式 */
export enum TriggerType {
  /** 按业务动作触发 */
  bizEventTrigger,
  /** 定时触发 */
  timeIntervalTrigger,
}

/** -------------------- 以下是【按业务动作触发】的相关枚举值 ------------------ */

/** 触发动作 */
export enum TriggerEventType {
  /** 新建 */
  created = 'add',
  /** 编辑 */
  edit = 'edit',
  // /** 启用 */
  // enable,
  // /** 停用 */
  // disable,
  // /** 导入 */
  // import,
  // /** 导出 */
  // export,
}

/** 生效时机 */
export enum EffectiveType {
  /** 即时生效 */
  immediately,
  /** 首次触发延时生效 */
  firstTriggerDelay,
}

/** 延时触发字段 */
export enum DelayTriggerField {
  /** 规则触发时 */
  triggering = -1,
  /** 创建时间 */
  createdAt,
  /** 更新时间 */
  updateAt,
  /** 入库时间 */
  warehousingAt,
  /** 有效期至 */
  expirationDate,
}

/** 延时条件 */
export enum DelayTriggerCondition {
  /** 之前 */
  before,
  /** 之后 */
  after,
}

/** 延时时间单位 */
export enum DelayTriggerTimeUnit {
  /** 天 */
  date,
  /** 小时 */
  hour,
  /** 分钟 */
  minute,
}

/** 按业务动作触发，执行频率的枚举值 */
export enum BizEventExecType {
  /** 触发一次 */
  triggerOnce,
  /** 自定义 */
  customize,
}

/** ------------------------- 以上是【按业务动作触发】的相关枚举值 ------------------ */

/** -------------------------- 以下是【定时执行】的相关枚举值 ------------------ */

/** 定时执行时，执行频率的枚举值 */
export enum ExecType {
  /** 自定义 */
  customize = 1,
  /** 每日 */
  everyDay,
  /** 每周 */
  everWeek,
  /** 每月 */
  everyMonth,
  /** 每年 */
  everyYear,
  /** 固定周期 */
  fixedCycle,
}

/** 每周的周几触发 */
export enum TriggerWeekDay {
  /** 周一 */
  Monday = 1,
  /** 周二 */
  Tuesday,
  /** 周三 */
  Wednesday,
  /** 周四 */
  Thursday,
  /** 周五 */
  Friday,
  /** 周六 */
  Saturday,
  /** 周日 */
  Sunday,
}

/** 每年的几月触发 */
export enum TriggerYearMonth {
  /** 一月 */
  January = 1,
  /** 二月 */
  February,
  /** 三月 */
  March,
  /** 四月 */
  April,
  /** 五月 */
  May,
  /** 六月 */
  June,
  /** 七月 */
  July,
  /** 八月 */
  August,
  /** 九月 */
  September,
  /** 十月 */
  October,
  /** 十一月 */
  November,
  /** 十二月 */
  December,
}

/** --------------------------- 以上是【定时执行】的相关枚举值 ------------------------- */

/** 条件配置方式 */
export enum TriggerConditionType {
  /** 业务字段 */
  biz,
  /** 自定义公式 */
  customizeTrigger,
}

/** 触发条件配置，条件类型 */
export enum ConditionType {
  /** 等于 */
  EQUAL,
  /** 不等于 */
  NOT_EQUAL,
  /** 包含 */
  INCLUDE,
  /** 不包含 */
  EXCLUDE,
  /** 为空 */
  NULL,
  /** 不为空 */
  NOT_NULL,
  /** 大于 */
  LARGER_THAN,
  /** 小于 */
  SMALLER_THAN,
  /** 大于等于 */
  LARGER_THAN_AND_EQUAL,
  /** 小于等于 */
  SMALLER_THAN_AND_EQUAL,
  /** 属于 */
  BELONG_TO,
}

/** 触发条件配置，值类型 */
export enum ValueType {
  /** 字段引用 */
  biz,
  /** 值 */
  value,
  /** 公式 */
  customizeTrigger,
}

/** 触发条件配置，条件值一件关系关联类型 */
export enum ConnectionType {
  /** 且 */
  and,
  /** 或 */
  or,
}

/** 业务类型 */
export enum ObjectBizType {
  /** 默认业务类型 */
  default,
}

/** 来源类型 */
export enum RuleSourceType {
  /** 手动创建 */
  manual,
  /** 系统预置 */
  preset,
  /** 业务自动创建 */
  businessAutomation,
}
