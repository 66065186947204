import React, { useEffect, useState } from 'react';
import { BlIcon, BlTable, SearchSelect } from 'src/components';
import { Form, FormInstance } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import {
  ShiftId,
  ShiftInfoDetailVOSType,
  ShiftRuleInfoCreateCOS,
  ShiftRuleInfoVOSType,
} from '../interface';
import styles from './scheduling.module.scss';
import { CYCLE_NUMBER_TYPE, WEED_TYPE_ENUM } from '../constant';
import { dealWithPeriodOfTime } from '../../shift/utils';
import BatchSetCycleShiftModal from './batchSetModal';
import _Array from 'src/utils/dataTypes/array';

interface StoreDetailProps {
  initialData?: ShiftRuleInfoVOSType[];
  form: FormInstance;
  batched: boolean;
  batchValue: ShiftId;
  handleSetBatched: (batch: boolean) => void;
  handleSetBatchValue: (batch: ShiftId) => void;
}

const FormTable = (props: StoreDetailProps) => {
  const { initialData, form, batched, batchValue, handleSetBatched, handleSetBatchValue } = props;

  const { getFieldsValue, setFieldsValue } = form;
  const [batchVisible, setBatchVisible] = useState(false); // 显示批量设置

  const formatDetailDataToForm = (values: ShiftRuleInfoVOSType[]) => {
    const formatValues = values?.map((node: ShiftRuleInfoVOSType) => {
      const { id, day, shiftId, shiftInShiftRuleDetailVO } = node;

      const formatTime = dealWithPeriodOfTime(shiftInShiftRuleDetailVO?.shiftInfoDetailVOS)
        ?.map((element: ShiftInfoDetailVOSType) => {
          return `${element?.startTime} - ${element?.endTime}`;
        })
        ?.join('；');

      const newShiftId = {
        key: shiftId,
        label: `${shiftInShiftRuleDetailVO?.name}${
          !_Array.isEmpty(shiftInShiftRuleDetailVO) ? `(${formatTime})` : ''
        }`,
      };

      return {
        id,
        day,
        shiftId: newShiftId,
      };
    });

    return formatValues;
  };

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue({
        shiftRuleInfoCreateCOS: formatDetailDataToForm(initialData),
      });
    } else {
      typeof handleSetBatched === 'function' && handleSetBatched(false);
      typeof handleSetBatchValue === 'function' && handleSetBatchValue({} as any);
    }
  }, [initialData]);

  const batchFinish = (value: ShiftId) => {
    const { shiftRuleInfoCreateCOS } = getFieldsValue();

    typeof handleSetBatched === 'function' && handleSetBatched(true);
    typeof handleSetBatchValue === 'function' && handleSetBatchValue(value);
    const newShiftRuleInfoCreateCOS = shiftRuleInfoCreateCOS?.map(
      (node: ShiftRuleInfoCreateCOS) => {
        return {
          ...node,
          shiftId: value,
        };
      },
    );

    setFieldsValue({ shiftRuleInfoCreateCOS: newShiftRuleInfoCreateCOS });
  };

  return (
    <Form.List name="shiftRuleInfoCreateCOS">
      {(fields) => {
        const getColumns = (): ColumnProps<FormListFieldData>[] => {
          return [
            {
              title: '天',
              dataIndex: 'day',
              key: 'day',
              width: 100,
              render: (_, field, index) => {
                const { type } = getFieldsValue();

                const num = index + 1;

                if (type === CYCLE_NUMBER_TYPE.DAY) {
                  return `第${num}天`;
                } else if (type === CYCLE_NUMBER_TYPE.WEEK) {
                  return `周${WEED_TYPE_ENUM.get(num % 7)}`;
                }
                return `${num}号`;
              },
            },
            {
              title: '班次',
              dataIndex: 'shiftId',
              key: 'shiftId',
              width: 250,
              filterDropdown: true,
              filterIcon: () => {
                return (
                  <div
                    onClick={() => {
                      setBatchVisible(true);
                    }}
                  >
                    <BlIcon
                      type="iconpiliang"
                      style={{ fontSize: 20, color: batched ? '#02B980' : '#6e6e6e' }}
                    />
                  </div>
                );
              },
              render: (_, field) => {
                return (
                  <Form.Item
                    rules={[{ required: true, message: '请选择当天班次' }]}
                    name={[field?.name, 'shiftId']}
                    fieldKey={[field?.key, 'shiftId']}
                  >
                    <SearchSelect fetchType="shiftRuleListByDetailCol" />
                  </Form.Item>
                );
              },
            },
          ];
        };

        return (
          <>
            <BlTable
              columns={getColumns()}
              style={{ minWidth: 438, maxWidth: '96%' }}
              dataSource={fields}
              scroll={{ x: 550, y: 650 }}
              rowKey={(field) => field?.key}
              id="scheduling"
              pagination={false}
              className={styles?.shiftTable}
            />
            {batchVisible && (
              <BatchSetCycleShiftModal
                visible={batchVisible}
                onCancel={() => {
                  setBatchVisible(false);
                }}
                batchFinish={batchFinish}
                batchValue={batchValue}
              />
            )}
          </>
        );
      }}
    </Form.List>
  );
};

export default FormTable;
