import React, { useEffect, useState } from 'react';
import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import { Space } from 'antd';
import _Time from 'src/utils/dataTypes/time';
import { replaceSign } from 'src/utils/constants';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { CreatorData, DetailDataType } from '../interface';
import { BcAttachmentDetail, BlIcon, SingleOperationLogComponent } from 'src/components';
import { useHistory } from 'react-router-dom';
import { toCheckItemEdit } from '../navigation';
import { fetchQcCheckItemDetail } from 'src/api/ytt/quality-domain/checkItem';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { customLayoutChooseButton } from 'src/components/customLayout/hooks/customLayoutForButton';
import { CustomDetailLayout } from 'src/components/customLayout';
import { gcUrl } from 'src/utils';

interface DetailBaseInfoProps {
  checkId: number;
  type: string;
}

const DetailInfo = (props: DetailBaseInfoProps) => {
  const { checkId, type } = props;
  const history = useHistory();

  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录

  const [dataSource, setDataSource] = useState({} as any);

  const fetchData = async () => {
    try {
      const { data } = await fetchQcCheckItemDetail({ id: checkId });

      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [checkId]);

  const baseMenu = [
    {
      key: 'record',
      auth: authDict.inspectionitem_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'edit',
      auth: authDict.inspectionitem_edit,
      title: '编辑',
      buttonRender: customLayoutChooseButton({
        title: '编辑',
        objectCode: 'InspectionItem',
        type: 'edit',
        buttonType: 'primary',
        icon: <BlIcon type="iconbianji" />,
        onClick: (layoutId) => history.push(toCheckItemEdit(Number(checkId), layoutId)),
        instanceId: checkId,
      }),
    },
  ];

  return (
    <>
      <CustomDetailLayout
        objectCode={'InspectionItem'}
        instanceIdParamKey={checkId}
        layoutId={gcUrl.getParams()?.layoutId}
        title="检验项详情"
        dataSource={dataSource}
        baseMenu={baseMenu}
        customInfoItems={[
          {
            label: '创建人',
            dataIndex: 'creator',
            render: (creator: CreatorData) => {
              return (
                <Space>
                  <AvatarDisplay
                    id={creator?.id}
                    name={creator?.name}
                    avatarUrl={creator?.avatarUrl}
                    key={creator?.id}
                    isShowTag={false}
                    isUser
                  />
                </Space>
              );
            },
          },
          {
            label: '创建时间',
            dataIndex: 'createdAt',
            isFullLine: true,
            render: (createdAt: Date) => _Time.format(createdAt) ?? replaceSign,
          },
          {
            label: '更新人',
            dataIndex: 'operator',
            render: (operator: CreatorData, { creator }: DetailDataType) => {
              return (
                <Space>
                  <AvatarDisplay
                    id={operator?.id ?? creator?.id}
                    name={operator?.name ?? creator?.name}
                    avatarUrl={operator?.avatarUrl ?? creator?.avatarUrl}
                    key={operator?.id ?? creator?.id}
                    isShowTag={false}
                    isUser
                  />
                </Space>
              );
            },
          },
          {
            label: '更新时间',
            dataIndex: 'updatedAt',
            isFullLine: true,
            render: (updatedAt: Date, record: any) =>
              _Time.format(updatedAt) ?? _Time.format(record?.createdAt) ?? replaceSign,
          },
        ]}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={checkId}
          objectCode={'InspectionItem'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailInfo;
