import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { BlIcon } from '@blacklake-web/component';
import _ from 'lodash';
import { RecordListLayout, FilterItem } from 'src/layout';
import { fieldTypeList } from 'src/utils';
import {
  fetchMalfunctionList,
  FetchMalfunctionListResponse,
} from 'src/api/ytt/resource-domain/malfunctionRecord';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { SingleOperationLogComponent, Tooltip, TagList, SearchSelect } from 'src/components';
import ResourceTagCascader from './components/ResourceTagCascader';
import { lookup } from 'src/dict';
import { replaceSign } from 'src/utils/constants';
import { goToCreate, goToEdit, goToDetail } from './navigates';
import ResourceClassificationCascader from '../components/ResourceClassificationCascader';
import { MaintenanceTreeFnParams } from '../components/maintenanceTree';
import { ResourceBusinessType } from 'src/dict/resources';
import {
  malfunctionTypes,
  malfunctionLevel,
  malfunctionRecordOrign,
  malfunctionRecordOrignObj,
} from 'src/dict/resources/mappings';
import { BL_BUSINESS_CODE, BL_OBJECT_CODE, BL_BUSINESS_NAME } from './config';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { ListItemType } from 'src/types';
import _Time from 'src/utils/dataTypes/time';

interface ListProps extends RouteComponentProps, MaintenanceTreeFnParams {}
export type ResourceListItemType = ListItemType<FetchMalfunctionListResponse>;

const List = (props: ListProps) => {
  const [detailId, setDetailId] = useState<number>(0);
  const [sourceObject, setSourceObject] = useState<number | undefined>();
  const [, setSourceObjectId] = useState<number | undefined>(undefined);
  const [refreshMarker] = useState<number>(0);
  const [resetRefreshMarker, setResetRefreshMarker] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  const { target, businessType, subLocationFlag } = props;

  const getRequestFn = () => {
    return fetchMalfunctionList;
  };

  const showDetail = (id: number) => {
    goToDetail(id);
  };

  useEffect(() => {
    setTimeout(() => {
      setResetRefreshMarker(Date.now());
    });
  }, [target, businessType, subLocationFlag]);

  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      auth: authDict.resourceMalfunctionRecord_export,
      icon: <BlIcon type="icondaochu" />,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.malfunctionRecord,
        });
      },
    },
    {
      title: `新建${BL_BUSINESS_NAME}`,
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.resourceMalfunctionRecord_add,
      onClick: () => {
        goToCreate();
      },
      items: [],
    },
  ];

  const sourceObjectChange = (e: number) => {
    setSourceObject(e);
    setSourceObjectId(undefined);
  };

  const getOperationList = ({ id }: { id: number }) => {
    return _.compact([
      {
        title: '查看',
        auth: authDict.resourceMalfunctionRecord_detail,
        onClick: () => {
          showDetail(id);
        },
      },
      {
        title: '编辑',
        auth: authDict.resourceMalfunctionRecord_edit,
        onClick: () => {
          goToEdit(id);
        },
      },
      {
        title: '操作记录',
        auth: authDict.resourceMalfunctionRecord_operrecord,
        onClick: () => {
          setDetailId(id);
          setVisibleLog(true);
        },
      },
    ]);
  };
  const dataColumns = [
    {
      title: '故障编号',
      dataIndex: 'code',
      sorter: true,
      fixed: 'left',
      width: 130,
      render: (value: string, record: any, index: number, config: any) => {
        return hasAuth(authDict.resourceMalfunctionRecord_detail) ? (
          <a onClick={() => showDetail(record.id)}>
            <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
          </a>
        ) : (
          <Tooltip text={value ?? replaceSign} />
        );
      },
    },
    {
      title: '故障标题',
      dataIndex: 'name',
      fixed: 'left',
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '业务对象',
      dataIndex: 'resourceType',
      width: 110,
      render: (value: number, record: any, index: number, config: any) => (
        <Tooltip
          text={lookup('resources.RESORCE_BUSINESS_TYPE_MAP', value) ?? replaceSign}
          width={config.contentWidth}
        />
      ),
    },
    {
      title: '故障资源',
      dataIndex: ['resource', 'name'],
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '资源编号',
      dataIndex: ['resource', 'code'],
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '故障类型',
      dataIndex: 'type',
      width: 110,
      render: (value: number, record: any, index: number, config: any) => (
        <Tooltip
          text={lookup('resources.malfunctionTypes', value) ?? replaceSign}
          width={config.contentWidth}
        />
      ),
    },
    {
      title: '故障分类',
      dataIndex: ['classification', 'fullName'], // fullName
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '故障标签',
      dataIndex: 'tagsLinks',
      width: 110,
      render: (tags: ResourceListItemType['tagsLinks']) => {
        return (
          tags && (
            <TagList
              dataSource={tags.map((tag) => ({
                value: tag.id,
                label: `${tag?.label}:${tag?.labelValue}`,
              }))}
            />
          )
        );
      },
    },
    {
      title: '故障等级',
      dataIndex: 'level',
      width: 110,
      render: (value: number, record: any, index: number, config: any) => (
        <Tooltip
          text={lookup('resources.malfunctionLevel', value) ?? replaceSign}
          width={config.contentWidth}
        />
      ),
    },
    {
      title: '记录来源',
      dataIndex: 'recordSource',
      width: 110,
      render: (value: number, record: any, index: number, config: any) => (
        <Tooltip
          text={lookup('resources.malfunctionRecordOrign', value) ?? replaceSign}
          width={config.contentWidth}
        />
      ),
    },
    {
      title: '来源对象',
      dataIndex: 'sourceObject',
      width: 110,
      render: (value: number, record: any, index: number, config: any) => (
        <Tooltip
          text={lookup('resources.malfunctionRecordOrignObj', value) ?? replaceSign}
          width={config.contentWidth}
        />
      ),
    },
    {
      title: '来源对象实例',
      dataIndex: 'sourceObjectVO',
      width: 140,
      render: (value: any, record: any, index: number, config: any) => {
        return (
          <Tooltip
            text={value ? `${value.paramName} ${value.recordTime}` : replaceSign}
            width={config.contentWidth}
          />
        );
      },
    },
    {
      title: '故障记录时间',
      dataIndex: 'recordTime',
      sorter: true,
      width: 150,
      render: (recordTime: Date) => {
        if (!recordTime) return replaceSign;
        return _Time.format(recordTime);
      },
    },
    {
      title: '故障原因',
      dataIndex: 'description',
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '故障明细',
      dataIndex: 'detail',
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '故障上报人',
      dataIndex: ['reportor', 'name'], // username
      width: 120,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 110,
      render: (value: string, record: any, index: number, config: any) => (
        <Tooltip text={value ?? replaceSign} width={config.contentWidth} />
      ),
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '故障编号',
      name: 'code',
      type: fieldTypeList.text,
    },
    {
      label: '故障标题',
      name: 'name',
      type: fieldTypeList.text,
    },
    {
      label: '故障资源',
      name: 'resourceName',
      type: fieldTypeList.select,
      renderItem: (
        <SearchSelect
          fetchType="resource"
          params={{ type: businessType }}
          placeholder="请输入故障资源"
          disabled={target?.type !== 'Location'}
        />
      ),
    },
    {
      label: '资源编号',
      name: 'resourceCode',
      type: fieldTypeList.select,
      renderItem: (
        <SearchSelect
          fetchType="resource"
          params={{ type: businessType }}
          placeholder="请输入资源编号"
          disabled={target?.type !== 'Location'}
          labelPath="code"
        />
      ),
    },
    {
      label: '故障类型',
      name: 'type',
      type: fieldTypeList.select,
      selectProps: {
        options: malfunctionTypes,
        // mode: 'multiple',
        // filterOption: (input: any, option: any) => {
        //   return (option!.label as unknown as string)?.includes(input);
        // },
      },
    },
    {
      label: '故障分类',
      name: 'classificationId',
      type: fieldTypeList.relation,
      renderItem: (
        <ResourceClassificationCascader
          onChange={() => {}}
          showSearch
          multiple
          params={{ businessType: ResourceBusinessType.malfunction, cascade: true }}
          placeholder={'请选择故障分类'}
          enableAddLabel={'新建分类'}
          onCreateClick={() => {}}
          enableAdd={false}
        />
      ),
    },
    {
      label: '故障标签',
      name: 'tagsLinks',
      type: fieldTypeList.relation,
      renderItem: (
        <ResourceTagCascader nameLabel="故障标签" businessType={ResourceBusinessType.malfunction} />
      ),
    },
    {
      label: '故障等级',
      name: 'level',
      type: fieldTypeList.select,
      selectProps: {
        options: malfunctionLevel,
      },
    },
    {
      label: '记录来源',
      name: 'recordSource',
      type: fieldTypeList.select,
      selectProps: {
        options: malfunctionRecordOrign,
      },
    },
    {
      label: '来源对象',
      name: 'sourceObject',
      type: fieldTypeList.select,
      selectProps: {
        options: malfunctionRecordOrignObj,
        onChange: sourceObjectChange,
      },
    },
    {
      label: '来源对象实例',
      name: 'sourceObjectId',
      type: fieldTypeList.select,
      renderItem: (
        <SearchSelect
          fetchType="malfunctionSourceObjectList"
          params={{ sourceObject }}
          placeholder="请输入来源对象实例"
        />
      ),
    },
    {
      label: '故障记录时间',
      name: 'recordTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '故障原因',
      name: 'description',
      type: fieldTypeList.text,
    },
    {
      label: '故障明细',
      name: 'detail',
      type: fieldTypeList.text,
    },
    {
      label: '故障上报人',
      name: 'reportorId',
      type: fieldTypeList.reference,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple={false} isSelectUser />
      ),
    },
  ];

  const formatDataToQuery = (params: any) => {
    const {
      classificationId,
      recordTime,
      tagsLinks: labelValueIds,
      // recordSource,
      sourceObjectId,
      resourceCode,
      resourceName,
      reportorId,
      ...rest
    } = params;
    const queryParams = {
      resourceId: target?.type === 'Equipment' ? target?.id : _.get(resourceName, 'value'),
      resourceCodeId: target?.type === 'Equipment' ? target?.id : _.get(resourceCode, 'value'),
      /** 区域ID（侧边栏） */
      locationId: target?.type === 'Location' ? target?.id : undefined,
      resourceType: businessType,
      labelValueIds,
      classificationId: _.isEmpty(classificationId) ? null : classificationId.concat().pop(),
      reportorId: !_.isEmpty(reportorId) ? reportorId[0].value : undefined,
      sourceObjectId: !_.isEmpty(sourceObjectId) ? sourceObjectId.value : undefined,
      hasSubLocation: subLocationFlag,
      ...rest,
    };

    if (recordTime) {
      queryParams.beginRecordTime = new Date(recordTime[0]).getTime();
      queryParams.endRecordTime = new Date(recordTime[1]).getTime();
    }

    dispatch.excelImport.updateBusinessData({
      businessData: { ...queryParams },
    });

    return {
      ...queryParams,
    };
  };
  const formatDataToFormDisplay = (data: any) => {
    const { ...rest } = data;

    return {
      ...rest,
    };
  };

  return (
    <>
      <RecordListLayout
        filterList={filterList}
        requestFn={getRequestFn()}
        useIndex={false}
        mainMenu={mainMenu}
        // batchMenu={batchMenu}
        placeholder={'请输入故障标题或编号'}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        columns={dataColumns}
        refreshMarker={refreshMarker}
        resetRefreshMarker={resetRefreshMarker}
        selectedRowKeys={selectedRowKeys}
        rowKey="id"
        configcacheKey={BL_BUSINESS_CODE}
        onSelectedRowKeys={(selectKey: React.Key[]) => {
          setSelectedRowKeys(selectKey.map((key) => Number(key)));
        }}
        getOperationList={getOperationList}
        userAuth={getAuthFromLocalStorage()}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={BL_OBJECT_CODE}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default List;
