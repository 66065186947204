import { FieldType, TargetType } from 'src/dict/common';
import { isSelectableField, choiceToLabelValue } from 'src/page/custom_fields/utils';
import { getInputControlRenderer } from './getInputControlRenderer';
import type { FieldDTO } from '../types';

/** 列表筛选输入控件，主要针对关联类型（因其他类型列表有内置输入控件） */
export const getFilterInputControl = (field: FieldDTO) => {
  if (field.fieldType !== FieldType.relation) {
    return undefined;
  }
  return getInputControlRenderer({ ...field, targetType: TargetType.multiChoice })();
};

/** 列表筛选单选/多选的选项 */
export const getFilterSelectProps = (field: FieldDTO) => {
  if (!isSelectableField(field.fieldType!)) {
    return undefined;
  }
  const result: any = { options: field.choiceValues?.map(choiceToLabelValue) };

  if (field.fieldType === FieldType.multiSelect) {
    result.mode = 'multiple';
  }
  return result;
};
