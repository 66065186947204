import authDict from 'src/utils/auth';
import { FilterItem, RecordListLayout } from 'layout';
import { BlIcon, SearchSelect, TagList } from 'components';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import React from 'react';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BlColumnType } from '@blacklake-web/component/dist/BlTable/BlTable.type';
import { RouteComponentProps } from 'react-router-dom';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import { fieldTypeList, gcObject, gcTime } from 'utils';
import { renderDepartmentArray, renderUserArray } from 'src/page/share/renderUser';
import { formatTime } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import _Array from 'src/utils/dataTypes/array';
import { TASK_STATUS_MAP_ENUM } from 'src/page/productionPlanning/productionTask/constant';
import { LabelType } from 'src/page/knowledgeManagement/warehouse/interface';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import { appDict } from 'src/dict';
import { PRODUCTION_PLANNING_PATH } from 'src/page/productionPlanning/navigation';
import { useDispatch } from 'react-redux';
import {
  fetchProgressReportList,
  FetchProgressReportListResponse,
} from 'src/api/ytt/mfg-domain/productionTask';
import _ from 'lodash';
import { intersectionValueOfId } from 'src/utils/array';

type RecordType = Exclude<FetchProgressReportListResponse['data'], undefined>['list'][number];

interface userProps {
  id: number;
  name: string;
  code: string;
}

interface Props extends RouteComponentProps {}

const ProgressReportList = (props: Props) => {
  const { history } = props;
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  const toProgressReportDetail = (id?: number) => {
    history.push(`${PRODUCTION_PLANNING_PATH}/progressReport/detail/${id}`);
  };
  const columns: BlColumnType<RecordType>[] = [
    {
      title: '报工时间',
      dataIndex: 'reportTime',
      width: 200,
      sorter: true,
      render: (workReportTime: Date) => {
        if (!workReportTime) return replaceSign;
        return _Time.format(workReportTime);
      },
    },
    {
      title: '生产任务编号',
      dataIndex: 'produceTaskCode',
      width: 150,
    },
    {
      title: '工序编号',
      dataIndex: 'processCode',
      width: 150,
    },
    {
      title: '工序名称',
      dataIndex: 'processName',
      width: 150,
    },
    {
      title: '报工物料编号',
      dataIndex: 'progressReportMaterialCode',
      width: 150,
    },
    {
      title: '报工物料名称',
      dataIndex: 'progressReportMaterialName',
      width: 150,
      render: (name: string) => name,
    },
    {
      title: '生产人员',
      dataIndex: 'producers',
      width: 150,
      render: (value) => renderUserArray(value),
    },
    {
      title: '质量状态',
      dataIndex: 'qcStatus',
      width: 150,
    },
    {
      title: '报工数量1',
      dataIndex: ['progressReportAmount1', 'amountDisplay'],
      width: 150,
    },
    {
      title: '单位1',
      dataIndex: ['progressReportAmount1', 'unitName'],
      width: 100,
    },
    {
      title: '报工数量2',
      dataIndex: ['progressReportAmount2', 'amountDisplay'],
      width: 150,
    },
    {
      title: '单位2',
      dataIndex: ['progressReportAmount2', 'unitName'],
      width: 100,
    },
    {
      title: '开始时间',
      dataIndex: 'startTime',
      width: 200,
      render: (workReportTime: Date) => {
        if (!workReportTime) return replaceSign;
        return _Time.format(workReportTime);
      },
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
      width: 200,
      render: (workReportTime: Date) => {
        if (!workReportTime) return replaceSign;
        return _Time.format(workReportTime);
      },
    },
    {
      title: '工时',
      dataIndex: 'workHour',
      width: 150,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 150,
    },
    {
      title: '部门',
      dataIndex: 'departments',
      width: 150,
      render: (departments: userProps[]) => renderDepartmentArray(departments),
    },
    {
      title: '设备',
      dataIndex: 'equipments',
      width: 150,
      render: (equipments: userProps[]) => {
        if (_Array.isEmpty(equipments)) return replaceSign;

        return <TagList dataSource={equipments} labelPath="name" valuePath="id" />;
      },
    },
    {
      title: '执行人',
      dataIndex: 'executors',
      width: 150,
      render: (executors: userProps[]) => renderUserArray(executors),
    },
    {
      title: '工单编号',
      dataIndex: 'workOrderCode',
      width: 150,
    },
    {
      title: '工单物料编号',
      dataIndex: 'workOrderMaterialCode',
      width: 150,
    },
    {
      title: '工单物料名称',
      dataIndex: 'workOrderMaterialName',
      width: 150,
    },
    {
      title: '订单编号',
      dataIndex: 'salesOrderCode',
      width: 150,
      render: (text: userProps[]) => {
        if (_Array.isEmpty(text)) return replaceSign;
        const options = text.map((item) => ({ label: item, value: item }));

        return <TagList dataSource={options} />;
      },
    },
  ];
  const filterList: FilterItem[] = [
    {
      label: '报工时间',
      name: 'reportTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '任务编号',
      name: 'quickSearch',
      type: fieldTypeList.text,
    },
    {
      label: '任务状态',
      name: 'taskStatusList',
      type: fieldTypeList?.multiSelect,
      selectProps: {
        options: TASK_STATUS_MAP_ENUM.map((node: LabelType) => {
          return {
            label: node?.name,
            value: node?.id,
          };
        }),
      },
    },
    {
      label: '工序编号',
      name: 'processDefIdListCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'process'}
          valuePath="id"
          labelPath="code"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '工序名称',
      name: 'processDefIdListName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'process'}
          valuePath="id"
          labelPath="name"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '报工物料编号',
      name: 'reportMaterialIdListCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="code"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '报工物料名称',
      name: 'reportMaterialIdListName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="name"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '生产人员',
      name: 'producerIdList',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <UserOrDepartmentSelectWithDialog placeholder="请选择生产人员" isMultiple isSelectUser />
      ),
    },
    {
      label: '质量状态',
      name: 'qcStatusList',
      type: fieldTypeList.multiSelect,
      selectProps: {
        options: appDict.productionPlanning.QualityStatusOptions,
      },
    },
    {
      label: '开始时间',
      name: 'startTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '结束时间',
      name: 'endTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '设备',
      name: 'equipmentIdList',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType={'resource'} labelInValue mode="multiple" />,
    },
    {
      label: '部门',
      name: 'departmentIdList',
      type: fieldTypeList.multiSelect,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple />,
    },
    {
      label: '执行人',
      name: 'executorIdList',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
    {
      label: '工单编号',
      name: 'workOrderIdList',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType={'workOrderFilterList'} labelInValue mode="multiple" />,
    },
    {
      label: '工单业务状态',
      name: 'workOrderBusinessStatusList',
      type: fieldTypeList.multiSelect,
      selectProps: {
        options: appDict.productionPlanning.BusinessStatusEnumDisplayMap,
      },
    },
    {
      label: '工单暂停状态',
      name: 'workOrderPauseStatusList',
      type: fieldTypeList.boolean,
    },
    {
      label: '工单物料编号',
      name: 'mainMaterialIdListCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="code"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '工单物料名称',
      name: 'mainMaterialIdListName',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'material'}
          valuePath="id"
          labelPath="name"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      label: '订单编号',
      name: 'salesOrderIdList',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect fetchType={'salesOrder'} labelInValue mode="multiple" />,
    },
  ];
  const formatDataToQuery: any = (params: any) => {
    const {
      reportTime,
      processDefIdListCode,
      processDefIdListName,
      reportMaterialIdListCode,
      reportMaterialIdListName,
      producerIdList,
      startTime,
      endTime,
      equipmentIdList,
      departmentIdList,
      executorIdList,
      workOrderIdList,
      mainMaterialIdListCode,
      mainMaterialIdListName,
      salesOrderIdList,
      ...rest
    } = params;
    const [reportTimeFrom, reportTimeTo] = gcTime.formatTimeRangeUnix(reportTime);
    const [startTimeFrom, startTimeTo] = gcTime.formatTimeRangeUnix(startTime);
    const [endTimeFrom, endTimeTo] = gcTime.formatTimeRangeUnix(endTime);
    const relParams = {
      ...rest,
      reportTimeFrom: reportTimeFrom && Number(reportTimeFrom),
      reportTimeTo: reportTimeTo && Number(reportTimeTo),
      processDefIdList: intersectionValueOfId(processDefIdListCode, processDefIdListName),
      reportMaterialIdList: intersectionValueOfId(
        reportMaterialIdListCode,
        reportMaterialIdListName,
      ),
      producerIdList: _.isEmpty(producerIdList) ? null : _.map(producerIdList, 'value'),
      startTimeFrom: _.isEmpty(startTimeFrom) ? null : Number(startTimeFrom),
      startTimeTo: _.isEmpty(startTimeTo) ? null : Number(startTimeTo),
      endTimeFrom: _.isEmpty(endTimeFrom) ? null : Number(endTimeFrom),
      endTimeTo: _.isEmpty(endTimeTo) ? null : Number(endTimeTo),
      equipmentIdList: _.isEmpty(equipmentIdList) ? null : _.map(equipmentIdList, 'value'),
      departmentIdList: _.isEmpty(departmentIdList) ? null : _.map(departmentIdList, 'value'),
      executorIdList: _.isEmpty(executorIdList) ? null : _.map(executorIdList, 'value'),
      workOrderIdList: _.isEmpty(workOrderIdList) ? null : _.map(workOrderIdList, 'value'),
      mainMaterialIdList: intersectionValueOfId(mainMaterialIdListCode, mainMaterialIdListName),
      salesOrderIdList: _.isEmpty(salesOrderIdList) ? null : _.map(salesOrderIdList, 'value'),
    };

    dispatch.excelImport.updateBusinessData({
      businessData: gcObject.filterEmptyProperty(relParams),
    });
    return gcObject.filterEmptyProperty(relParams);
  };
  const formatDataToFormDisplay = (data: any) => {
    const { reportTime, startTime, endTime, ...rest } = data;

    return {
      ...rest,
      reportTime: reportTime && gcTime.formatRangeTimeToMoment(reportTime),
      startTime: startTime && gcTime.formatRangeTimeToMoment(startTime),
      endTime: endTime && gcTime.formatRangeTimeToMoment(endTime),
    };
  };

  return (
    <RecordListLayout<RecordType>
      rowKey="id"
      placeholder="输入生产任务编号"
      mainMenu={[
        {
          title: '导出',
          showExport: true,
          auth: authDict.progressreportrecord_export,
          icon: <BlIcon type="icondaochu" />,
          onClick: () => {
            openModal({
              optType: 'export',
              businessType: BUSINESS_TYPE.progressReport,
            });
          },
        },
      ]}
      requestFn={(params) =>
        fetchProgressReportList({
          ...params,
          sorter: params?.sorter ? params?.sorter : [{ field: 'reportTime', order: 'desc' }], // 后端不好写 前端给的默认排序
        })
      }
      columns={columns}
      getOperationList={(record: RecordType) => {
        return [
          {
            title: '查看',
            onClick: () => toProgressReportDetail(record?.reportRecordId),
          },
        ];
      }}
      filterList={filterList}
      formatDataToQuery={formatDataToQuery}
      formatDataToFormDisplay={formatDataToFormDisplay}
      configcacheKey="progressReportModule"
    />
  );
};

export default ProgressReportList;
