import { ReactNode, useEffect } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Input } from 'antd';
import UserOrDepartmentSelectWithDialog from '../../components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';

interface baseInfoProps {
  onCancel: () => void;
  onFinish: () => void;
  title: string;
  initialData?: any;
  extra?: ReactNode;
  form: any;
}

const EditBaseInfo = (props: baseInfoProps) => {
  const { onCancel, title, onFinish, initialData, form, extra } = props;

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    items: [
      {
        label: '报表名称',
        name: 'name',
        render: () => initialData.name,
      },
      {
        label: '查看用户',
        name: 'allowViewUser',
        rules: [{ required: true, message: '请选择用户' }],
        render: () => (
          <UserOrDepartmentSelectWithDialog placeholder="请选择用户" isMultiple isSelectUser />
        ),
      },
      {
        label: '备注:',
        name: 'remark',
        rules: [{ max: 200, message: '不超过200个字符' }],
        render: () => <Input.TextArea placeholder="请输入备注,最长200字" />,
      },
    ],
  };

  useEffect(() => {
    if (initialData) {
      form?.setFieldsValue({
        id: initialData?.id,
        name: initialData?.name,
        // 查看用户初始化名单
        allowViewUser: initialData?.allowViewUser?.map((user: any) => ({
          value: user.id,
          label: user.name,
        })),
        remark: initialData?.remark,
      });
    } else {
      form?.setFieldsValue({});
    }
  }, [initialData]);

  return (
    <DataFormLayout
      form={form}
      title={title}
      info={[baseInfo]}
      onCancel={onCancel}
      onFinish={onFinish}
      extra={extra}
    />
  );
};

export default EditBaseInfo;
