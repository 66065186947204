import type { FetchStandardBizObjectCustomObjectGetByIdOrCodeResponse } from 'src/api/ytt/metadata-domain/objectPlatform';
import type {
  FetchCustomObjectListResponse,
  FetchCustomObjectDetailResponse,
} from 'src/api/ytt/custom-object-domain';

export type CustomObjectDTO =
  Required<FetchStandardBizObjectCustomObjectGetByIdOrCodeResponse>['data'];
export type CustomObjectListItemDTO =
  Required<FetchCustomObjectListResponse>['data']['list'][number];
export type CustomObjectEntityDTO = Required<FetchCustomObjectDetailResponse>['data'];
export type FieldValueDTO = Required<CustomObjectEntityDTO>['fields'][number];
export type SubObjectValueDTO = Required<CustomObjectEntityDTO>['sonObjects'][number];

/** 操作动作 */
export enum OperationCode {
  /** 新建 */
  add = 'add',
  /** 编辑 */
  edit = 'edit',
  /** 删除 */
  delete = 'delete',
  /** 查看 */
  view = 'view',
  /** 查看 */
  detail = 'detail',
  /** 复制 */
  copy = 'copy',
  /** 下发 */
  issued = 'issued',
  /** 启用 */
  enable = 'enable',
  /** 停用 */
  disable = 'disable',
  /** 导入 */
  import = 'import',
  /** 导出 */
  export = 'export',
  /** 操作记录 */
  operrecord = 'operrecord',
}
