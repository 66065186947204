import React, { useEffect, useState } from 'react';
import { Form, Select, SelectProps } from 'antd';
import { fetchResourcesList } from 'src/api/ytt/resource-domain/resource';
import ResourceSelectModal from './selectModal';
import _ from 'lodash';
import { RESORCE_STATUS_VALUE } from 'src/page/resource/definitions/constants';

interface ResourceSelectProps extends SelectProps<any> {
  isMultiple?: boolean;
  form?: any;
  dataKey?: string;
  queryParam?: any;
}

const ResourceSelect = (props: ResourceSelectProps) => {
  const [list, setList] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [choosedData, setChoosedData] = useState<number[]>([]);
  const { isMultiple, form, dataKey, queryParam = {}, ...rest } = props;

  const fetchData = async (searchValue?: string) => {
    const params = { page: 1, size: 10, ...queryParam } as any;

    params.status = RESORCE_STATUS_VALUE.ENABLED;
    if (searchValue) {
      params.name = searchValue;
    }
    const { data } = await fetchResourcesList(params, { legacy: true });

    setList(data?.list || []);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = _.debounce((value: string) => fetchData(value), 500);

  const submitSelectData = (ids: number[], data: any[]) => {
    if (form && dataKey) {
      form.setFieldsValue({ [dataKey]: data.map(({ name, id }) => ({ value: id, label: name })) });
    } else {
      setChoosedData(data);
    }
  };

  return (
    <>
      <Form.Item name={dataKey}>
        <Select
          onSearch={handleSearch}
          mode={isMultiple ? 'multiple' : undefined}
          onChange={(value) => setChoosedData(value)}
          showSearch
          dropdownRender={(menu) => (
            <div>
              {menu}
              <a
                style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                更多
              </a>
            </div>
          )}
          value={choosedData}
          labelInValue
          {...rest}
        >
          {list.map((item) => {
            return <Select.Option value={item.id}>{item.name}</Select.Option>;
          })}
        </Select>
      </Form.Item>

      {showModal && (
        <ResourceSelectModal
          visible={showModal}
          submitData={submitSelectData}
          closeModal={() => setShowModal(false)}
          defaultSelected={form
            .getFieldValue([dataKey])
            ?.map((i: { value: number; label: string }) => ({
              id: i.value,
              name: i.label,
            }))}
        />
      )}
    </>
  );
};

export default ResourceSelect;
