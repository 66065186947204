const color = {
  // 主色调绿色系列
  primaryColor: '#02B980', // 主色调
  // 灰色
  black: '#000',
  titleColor: '#262626',
  textColorPrimary: '#595959', // 主文本
  textColorSecondary: '#8C8C8C', // 次文本 和ant design的一致
  disabledColor: 'rgba(0, 0, 0, 0.25)', // 禁用color和ant design的一致
  disabledBg: 'rgba(0, 0, 0, 0.04)', // 禁用背景色
  borderColorBase: 'rgba(0, 0, 0, 0.15)', // 边框基础色
  dividerColor: '#F0F0F0',
  background: '#F5F5F5',
  headingColor: '#FAFAFA',
  white: '#FFF',
  // 功能系列
  linkColor: '#1890FF', // 链接
  successColor: '#262626', // 成功
  warningColor: '#FAAD14', // 警告
  errorColor: '#FF4D4F', // 错误
  itemHoverBg: '#22C78D', // hover
  itemActiveBg: '#00946A', // active
  itemNormalBg: '#02B980', // normal
};

export default color;
export const {
  primaryColor,
  black,
  titleColor,
  textColorPrimary,
  textColorSecondary,
  disabledColor,
  disabledBg,
  borderColorBase,
  dividerColor,
  background,
  headingColor,
  white,
  linkColor,
  successColor,
  warningColor,
  errorColor,
  itemHoverBg,
  itemActiveBg,
  itemNormalBg,
} = color;
