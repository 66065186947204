import React, { useState } from 'react';
import { Form, Radio, Input } from 'antd';
import { appDict } from 'src/dict';
import { CheckItemInputTypeEnum, TextInputEnum } from 'src/dict/quality';
import { validatorCharacterLen } from 'src/utils/formValidators';

const TextInput = (props: { form: any }) => {
  const { form } = props;
  const TEXT_MAXLENGTH_MULTIPLE = 1000;
  const TEXT_MAXLENGTH_SINGLE = 255;
  const [textMaxLength, setTextMaxLength] = useState<CheckItemInputTypeEnum>(
    form.getFieldValue('textInputType') === TextInputEnum.multipleText
      ? TEXT_MAXLENGTH_MULTIPLE
      : TEXT_MAXLENGTH_SINGLE,
  );

  return (
    <>
      <Form.Item name="textInputType" label="文本格式">
        <Radio.Group
          options={appDict.quality.TextInputType}
          onChange={(e) => {
            if (e.target.value === TextInputEnum.multipleText) {
              setTextMaxLength(TEXT_MAXLENGTH_MULTIPLE);
            } else {
              setTextMaxLength(TEXT_MAXLENGTH_SINGLE);
            }
            form.setFieldsValue({ chekItemInputType: CheckItemInputTypeEnum.inputFormatMultiple });
          }}
        />
      </Form.Item>
      <Form.Item
        name="defaultValue"
        label="默认值"
        style={{ marginBottom: 0 }}
        rules={[
          {
            validator: validatorCharacterLen(textMaxLength, '默认值'),
          },
        ]}
      >
        <Input />
      </Form.Item>
    </>
  );
};

export default TextInput;
