import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Modal } from 'antd';
import _ from 'lodash';
import { RecordListLayout } from 'layout';
import { BlIcon, LinkTooltip } from 'components';
import { fetchSopExecTaskListTask } from 'src/api/ytt/sop-domain';
import { BizType, TaskStatus } from 'src/dict/sop';
import { fieldTypeList } from 'utils';
import { formatTimeForRender, valueOrHolder, formatTimeRangeForQuery } from 'src/utils/formatters';
import lookup from 'src/dict';
import {
  taskStatus as taskStatusMappings,
  bizType as bizTypeMappings,
} from 'src/dict/sop/mappings';
import SearchSelect from 'src/components/searchSelect';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { SopTaskOverviewData } from './type';
import { taskCan, DISPATCH_WARNING, taskCanWithApproval } from './constants';
import {
  renderUser,
  renderUserArray,
  renderDepartmentArray,
  renderRoleArray,
} from 'src/page/share/renderUser';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import type { User } from 'src/page/share';
import useTaskOperations from './share/useTaskOperations';

const SOPTaskList = (props: RouteComponentProps) => {
  const { history } = props;
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新页面

  const refresh = () => setRefreshMarker(Date.now());
  const { handleDispatch, handleSuspend, handleResume, handleEnd, handleCancel, handleDelete } =
    useTaskOperations({ refresh });

  const dataColumns = [
    {
      title: 'SOP任务编号',
      dataIndex: 'code',
      width: 150,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: 'SOP任务名称',
      dataIndex: 'name',
      width: 180,
      render: (text: string, record: SopTaskOverviewData, index: number, config: any) => (
        <LinkTooltip
          to={`/sop/exec/task/${record.sopTaskId}/detail`}
          text={text}
          width={config.contentWidth}
          auth={authDict.soptask_detail}
        />
      ),
      isFilter: true,
      type: fieldTypeList.text,
    },
    {
      title: 'SOP任务类型',
      dataIndex: 'bizType',
      width: 150,
      render: lookup('sop.bizType'),
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: bizTypeMappings,
        mode: 'multiple',
      },
    },
    {
      title: 'SOP任务状态',
      dataIndex: 'status',
      width: 150,
      render: lookup('sop.taskStatus'),
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: taskStatusMappings.filter((item) => item.value !== TaskStatus.deleted),
        mode: 'multiple',
      },
    },
    {
      title: '关联SOP方案',
      dataIndex: ['relateId', 'name'],
      key: 'sopId',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      renderItem: (
        <SearchSelect
          fetchType="SOPFilter"
          params={{
            bizTypes: [BizType.general, BizType.production, BizType.maintenance, BizType.repair],
          }}
          allowClear
        />
      ),
    },
    {
      title: '可执行用户',
      dataIndex: 'canExecUser',
      width: 150,
      render: (text: User[]) => renderUserArray(text),
    },
    {
      title: '可执行部门',
      dataIndex: 'canExecDept',
      width: 150,
      render: (text: User[]) => renderDepartmentArray(text),
    },
    {
      title: '可执行角色',
      dataIndex: 'canExecRole',
      width: 150,
      render: (text: User[]) => renderRoleArray(text),
    },
    // {
    //   title: '可执行班次',
    //   dataIndex: 'canExecShift',
    //   width: 150,
    //   render: renderLabelArray,
    // },
    {
      title: '执行人',
      dataIndex: 'executor',
      width: 200,
      render: (text: User[]) => renderUserArray(text),
      isFilter: true,
      type: fieldTypeList.reference,
      renderItem: <UserOrDepartmentSelectWithDialog isSelectUser isMultiple isNewFormat />,
    },
    {
      title: 'SOP任务备注',
      dataIndex: 'remark',
      width: 150,
      render: valueOrHolder,
    },
    {
      title: '创建人',
      dataIndex: 'creatorId',
      width: 100,
      render: renderUser,
      isFilter: true,
      type: fieldTypeList.reference,
      renderItem: <UserOrDepartmentSelectWithDialog isSelectUser isMultiple isNewFormat />,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 180,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      render: valueOrHolder(formatTimeForRender),
    },
    {
      title: '更新人',
      dataIndex: 'operatorId',
      width: 100,
      render: renderUser,
      isFilter: true,
      type: fieldTypeList.reference,
      renderItem: <UserOrDepartmentSelectWithDialog isSelectUser isMultiple isNewFormat />,
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 180,
      render: valueOrHolder(formatTimeForRender),
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
    },
  ];

  const getOperationList = (record: Required<SopTaskOverviewData>) => {
    const { status, approvalStatus, bizType } = record;
    const isGeneralCommonTask = bizType === BizType.general;

    return [
      {
        title: '查看',
        auth: authDict.soptask_detail,
        onClick: () => history.push(`/sop/exec/task/${record.sopTaskId}/detail`),
      },
      {
        title: '编辑',
        auth: authDict.soptask_edit,
        disabled: !isGeneralCommonTask || !taskCanWithApproval('edit', status, approvalStatus),
        onClick: () => history.push(`/sop/exec/task/${record.sopTaskId}/edit`),
      },
      {
        title: '下发',
        auth: authDict.soptask_dispatch,
        disabled: !isGeneralCommonTask || !taskCanWithApproval('dispatch', status, approvalStatus),
        onClick: () => {
          if (
            _.isEmpty(record.canExecUser) &&
            _.isEmpty(record.canExecDept) &&
            _.isEmpty(record.canExecRole)
          ) {
            Modal.confirm({
              title: '提示',
              content: DISPATCH_WARNING,
              onOk: () => {
                handleDispatch(record);
              },
            });
          } else {
            handleDispatch(record);
          }
        },
      },
      {
        title: taskCan.resume(status) ? '继续任务' : '暂停任务',
        auth: authDict.soptask_pause_continue,
        disabled:
          !isGeneralCommonTask ||
          (!taskCanWithApproval('suspend', status, approvalStatus) &&
            !taskCanWithApproval('resume', status, approvalStatus)),

        onClick: () => {
          if (taskCanWithApproval('suspend', status, approvalStatus)) {
            handleSuspend(record.sopTaskId, status);
          } else {
            handleResume(record.sopTaskId, status);
          }
        },
      },
      {
        title: '强制结束',
        auth: authDict.soptask_forced_finish,
        disabled: !isGeneralCommonTask || !taskCanWithApproval('forceEnd', status, approvalStatus),
        onClick: () => handleEnd(record.sopTaskId),
      },
      {
        title: '取消',
        auth: authDict.soptask_cancel,
        disabled: !isGeneralCommonTask || !taskCanWithApproval('cancel', status, approvalStatus),
        onClick: () => handleCancel(record.sopTaskId),
      },
      {
        title: '删除',
        auth: authDict.soptask_remove,
        disabled: !isGeneralCommonTask || !taskCanWithApproval('delete', status, approvalStatus),
        onClick: () => handleDelete(record.sopTaskId),
      },
      {
        title: '操作记录',
        auth: authDict.soptask_operrecord,
        disabled: !taskCanWithApproval('operationLog', status, approvalStatus),
        onClick: () => history.push(`/sop/exec/task/${record.sopTaskId}/operationLog`),
      },
    ];
  };

  const leftMenu = _.compact([
    hasAuth(authDict.soptask_add) && {
      title: '新建SOP任务',
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        history.push('/sop/exec/task/create');
      },
      items: [],
    },
  ]);

  const filterList = dataColumns
    .filter((i) => i.isFilter)
    .map((column) => {
      const filter = {
        label: column.title,
        name: column.key ?? column.dataIndex,
        type: column.type,
        renderItem: column.renderItem,
      } as any;

      if (column.selectProps) {
        filter.selectProps = column.selectProps;
      }

      return filter;
    });

  const formatDataToQuery = (values: any) => {
    const { creatorId, operatorId, executor, createdAt, updatedAt, sopId } = values;

    return {
      ...values,
      creatorId: _.isArray(creatorId) ? _.map(creatorId, 'id') : undefined,
      operatorId: _.isArray(operatorId) ? _.map(operatorId, 'id') : undefined,
      executor: _.isArray(executor) ? _.map(executor, 'id') : undefined,
      sopId: sopId?.value,
      createdAt: formatTimeRangeForQuery(createdAt),
      updatedAt: formatTimeRangeForQuery(updatedAt),
    };
  };

  return (
    <>
      <RecordListLayout<any>
        columns={dataColumns}
        filterList={filterList}
        rowKey={'id'}
        mainMenu={leftMenu}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={_.identity}
        formatDataToFormDisplay={_.identity}
        configcacheKey={'sopTask'}
        requestFn={fetchSopExecTaskListTask}
        refreshMarker={refreshMarker}
        userAuth={getAuthFromLocalStorage()}
        getOperationList={getOperationList}
      />
    </>
  );
};

export default SOPTaskList;
