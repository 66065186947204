import React, { useState, useContext } from 'react';
import { Form, message as Message, Modal } from 'antd';
import SmsCodeBaseInfo from '../SmsCodeBaseInfo';
import { MESSAGE_AUTH_TYPE, UserInfoContext } from '../constant';
import styles from '../styles.module.scss';
import { fetchSmsVerifyMessage, fetchSmsVerifySmsCode } from 'src/api/ytt/user-domain';
import { fetchUserUpdatePhone } from 'src/api/ytt/user-domain/user';
interface Props {
  visible: Boolean;
  onCancel: () => void;
  hiddenOldPassWord?: (replace: boolean) => void;
  handleBindTag?: (replace: boolean) => void;
  handleReplaceBindTag?: (replace: boolean) => void;
  handleUuid?: (uuid: string) => void;
  phone?: string;
  showType: number;
  bindPhoneTag: boolean;
}

export default function MessageAuth({
  visible,
  onCancel,
  phone,
  showType,
  hiddenOldPassWord,
  handleBindTag,
  handleReplaceBindTag,
  handleUuid,
  bindPhoneTag,
}: Props) {
  const [form] = Form.useForm();

  const [timing, setTiming] = useState(false); // 正在获取验证码
  const [okDisable, setOkDisable] = useState(false); // 确认按钮的禁用
  const { refresh } = useContext(UserInfoContext);

  const handleDisable = (status: boolean) => {
    setOkDisable(status);
  };

  /**
    会用到messageAuth的handleFinish 只有两种情况：1.重置密码手机校验 2. 更换手机前的旧手机验证 这两种情况都是自带手机号的
    2022-04-21 @zengkunmei 添加了一种情况： 3、存在未绑定的手机号，输入验证码进行绑定，这种情况也是自带手机号的
    http://jira2.blacklake.tech/browse/HHZZ3-14852
  */
  const handleFinish = async () => {
    try {
      const value = await form?.validateFields();

      let res;

      if (showType === MESSAGE_AUTH_TYPE.RESET_PASSWORD) {
        // 重置密码
        res = await fetchSmsVerifyMessage({
          phone,
          code: value?.authCode,
        });
      } else if (showType === MESSAGE_AUTH_TYPE.BIND_EXISTING_PHONE) {
        // 绑定已存在的手机号
        res = await fetchUserUpdatePhone({
          message: value?.authCode,
        });
      } else {
        res = await fetchSmsVerifySmsCode({
          phone,
          code: value?.authCode,
        });
      }

      if (res?.code === 200) {
        if (showType === MESSAGE_AUTH_TYPE.RESET_PASSWORD) {
          // 修改密码成功 回到修改页面
          typeof handleUuid === 'function' && handleUuid?.((res?.data as string) ?? '');

          typeof hiddenOldPassWord === 'function' && hiddenOldPassWord?.(true);
        } else if (showType === MESSAGE_AUTH_TYPE.REPLACE_PHONE) {
          // 更换手机
          typeof handleBindTag === 'function' && handleBindTag?.(false);
          typeof handleReplaceBindTag === 'function' && handleReplaceBindTag?.(true);
        } else if (showType === MESSAGE_AUTH_TYPE.BIND_EXISTING_PHONE) {
          // 绑定已存在的手机号
          refresh();
        }
        Message.success('验证成功');
        form.resetFields();
        setTiming(false);
        onCancel && onCancel();
        return;
      }
      Message.error(res?.message);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  if (showType === MESSAGE_AUTH_TYPE.BIND_EXISTING_PHONE) {
    form.setFieldsValue({ phone });
  }

  return (
    <Modal
      visible={Boolean(visible)}
      width={540}
      title="短信验证"
      onCancel={onCancel}
      className={styles.authModal}
      onOk={handleFinish}
      okButtonProps={{ disabled: okDisable }}
    >
      <SmsCodeBaseInfo
        handleDisable={handleDisable}
        curPhoneNum={phone}
        form={form}
        showType={showType}
        bindPhoneTag={bindPhoneTag}
        timing={timing}
        handleTiming={(time: boolean) => {
          setTiming(time);
        }}
      />
    </Modal>
  );
}
