import React, { useState, useEffect, useCallback } from 'react';
import { Badge, Button, message, Spin } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock, DetailLayoutInfoItem } from 'layout';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { fetchQcTaskDetail } from 'src/api/ytt/quality-domain/qc_task';
import { gcUrl } from 'src/utils';
import { QcTask } from 'src/page/quality/models/dto';
import { QcTaskEntity } from 'src/page/quality/models/entities/qcTask';
import { RecordDetailTable } from './recordTable';
import { BlIcon, BlTable, TagList } from 'src/components';
import { CheckMaterial } from 'src/page/quality/models/dto/checkMaterial';
import _ from 'lodash';
import { MaterialQualityStatusValue } from 'src/page/warehouseManagement/constants';
import { appEnum, lookup } from 'src/dict';
import { QcTaskStatusEnum, RecordSampleEnum, ReportFormatTypeEnum } from 'src/dict/quality';
import { CustomDetailLayout } from 'src/components/customLayout';
import RecordDetailDrawer from './recordDetail';
import ChooseTemplateModal from 'src/page/analyse/objectMatchingRule/share/chooseTemplateModal';
import { fetchMatchRuleQueryMatchTemplateBatch } from 'src/api/ytt/e-report-domain/matchRule';
import authDict from 'src/utils/auth';
import { EditButton } from 'src/components/customLayout/hooks/customLayoutForButton';
import { useHistory } from 'react-router-dom';
import { getTaskEditUrl } from '../../models/utils/qcTask';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

const QcTaskDetail = () => {
  const [data, setData] = useState<QcTask>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSameAsBatchCount, setIsSameAsBatchCount] = useState<boolean>(false);
  const [reportCount, setReportCount] = useState<number>(0);
  const [detailVisible, setDetailVisible] = useState<boolean>(false);
  const [recordDetailData, setRecordDetailData] = useState<any>();
  const [chooseVisible, setChooseVisible] = useState<boolean>(false);
  const [templateData, setTemplateData] = useState<any>([]);
  const [taskId, setTaskId] = useState<number>(0);
  const history = useHistory();
  const isHidden = ['hw', 'ali', 'xbmes', 'qcloud'].filter((envKeyword) => {
    return location.host.includes(envKeyword);
  })?.length;
  const toCheckTaskEdit = (id: number, layoutId: number, bizType: number) => {
    history.push(getTaskEditUrl(id, layoutId, bizType));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkAreaInfo = {
    title: '检验范围',
    items: [
      {
        label: '检验范围编号',
        dataIndex: ['qcTaskRange', 'code'],
      },
      {
        label: '业务类型',
        dataIndex: 'checkType',
        render: lookup('quality.checkType'),
      },
      {
        label: '物料编号',
        dataIndex: ['qcTaskRange', 'material', 'code'],
      },
      {
        label: '物料名称',
        dataIndex: ['qcTaskRange', 'material', 'name'],
      },
      {
        label: '批次号',
        dataIndex: ['qcTaskRange', 'batchNumbers'],
        render: (batchNumbers: { batchNumber: string }[]) =>
          batchNumbers?.map(({ batchNumber }) => batchNumber).join(','),
      },
      {
        label: '生产任务编号',
        dataIndex: ['qcTaskRange', 'productTaskCode'],
      },
      {
        label: '入厂数量',
        dataIndex: 'inputFactoryCount',
      },
      {
        label: '仓库名称/编号',
        dataIndex: 'warehouse',
      },
      {
        label: '仓位名称/编号',
        dataIndex: 'area',
      },
    ],
  };

  const getSampleDetailColumns = () => {
    return _.compact([
      {
        title: '物料编号',
        dataIndex: 'materialCode',
        fixed: 'left',
      },
      {
        title: '物料名称',
        dataIndex: 'materialName',
        fixed: 'left',
      },
      { title: '批次号', width: 200, dataIndex: 'batchNumber' },
      {
        title: '位置',
        dataIndex: 'location',
        width: 200,
        render: (locationInfo: any) =>
          `${locationInfo?.storage?.name}/${locationInfo?.warehouse?.name}`,
      },
      { title: '标识码', dataIndex: 'qrCode' },
      {
        title: '质量状态',
        dataIndex: 'qcStatus',
        width: 100,
        render: (value: any) => MaterialQualityStatusValue.get(value),
      },
      {
        title: '检验数量',
        dataIndex: 'qcCount',
        width: 200,
      },
      {
        title: '检验单位',
        dataIndex: 'qcUnit',
        width: 200,
        render: () => data?.qcUnit?.name,
      },
      {
        title: '辅助数量',
        dataIndex: ['inventory', 'opAmount', 'auxAmounts'],
        width: 120,
        render: (amounts: { amount: number }[]) => amounts[0] && amounts[0]?.amount,
      },
      {
        title: '辅助单位',
        dataIndex: ['inventory', 'opAmount'],
        width: 120,
        render: (opAmount: { auxAmounts: { unit: { name: string } }[] }) =>
          opAmount?.auxAmounts[0] && opAmount?.auxAmounts[0]?.unit?.name,
      },
      data?.qcConfig?.reportFormatType === ReportFormatTypeEnum.materialUnit && {
        title: '合格数/率',
        dataIndex: 'qualifiedCount',
        width: 200,
        render: (count: number, record: CheckMaterial) =>
          `${count} ${data?.qcUnit?.name}/${record?.qualifiedRate}%`,
      },
      data?.qcConfig?.reportFormatType === ReportFormatTypeEnum.materialUnit && {
        title: '让步合格数/率',
        width: 200,
        dataIndex: 'qualifiedConcessionCount',
        render: (count: number, record: CheckMaterial) =>
          `${count} ${data?.qcUnit?.name}/${Number(record?.qualifiedConcessionRate)}%`,
      },
      data?.qcConfig?.reportFormatType === ReportFormatTypeEnum.materialUnit && {
        title: '不合格数/率',
        dataIndex: 'defectCount',
        width: 200,
        render: (count: number, record: CheckMaterial) =>
          `${count} ${data?.qcUnit?.name}/${Number(record?.defectRate)}%`,
      },
      data?.qcConfig?.reportFormatType === ReportFormatTypeEnum.materialUnit && {
        title: '待检数/率',
        dataIndex: 'pendingCount',
        width: 200,
        render: (count: number, record: CheckMaterial) =>
          `${count} ${data?.qcUnit?.name}/${Number(record?.pendingRate)}%`,
      },
    ]);
  };

  const checkMaterialInfo = {
    title: '总体信息',
    items: [
      {
        label: '',
        dataIndex: 'OverallInventoryRecord',
        isFullLine: true,
        render: () => (
          <BlTable
            columns={[
              {
                title: '物料编号',
                dataIndex: 'materialCode',
                fixed: 'left',
                width: 200,
              },
              {
                title: '物料名称',
                dataIndex: 'materialName',
                fixed: 'left',
                width: 200,
              },
              { title: '批次号', width: 200, dataIndex: 'batchNumber' },
              {
                title: '存储位置',
                dataIndex: 'location',
                width: 200,
                render: (locationInfo: any) =>
                  `${locationInfo?.storage?.name}/${locationInfo?.warehouse?.name}`,
              },
              {
                title: '质量状态',
                dataIndex: 'qcStatus',
                width: 100,
                render: (value) => MaterialQualityStatusValue.get(value),
              },
              { title: '标识码', width: 200, dataIndex: 'qrCode' },
              {
                title: '检验数量',
                dataIndex: 'qcCount',
                width: 200,
              },
              {
                title: '检验单位',
                dataIndex: 'qcUnit',
                width: 200,
                render: () => data?.qcUnit?.name,
              },
              {
                title: '辅助数量',
                dataIndex: ['inventory', 'opAmount', 'auxAmounts'],
                width: 120,
                render: (amounts: { amount: number }[]) => amounts?.[0] && amounts[0]?.amount,
              },
              {
                title: '辅助单位',
                dataIndex: ['inventory', 'opAmount'],
                width: 120,
                render: (opAmount: { auxAmounts: { unit: { name: string } }[] }) =>
                  opAmount?.auxAmounts[0] && opAmount?.auxAmounts[0]?.unit?.name,
              },
            ]}
            dataSource={data?.checkMaterials || []}
            scroll={{ x: 'max-content' }}
          />
        ),
      },
    ],
  };

  useEffect(() => {
    const { id } = gcUrl.getParams();

    setTaskId(id);
    fetchQcTaskDetail({ id }).then(({ data }: any) => {
      setData(data);
      const qcTask = new QcTaskEntity(data);

      setReportCount(qcTask?.getReportCount(data) || 0);
      setIsSameAsBatchCount(qcTask?.isSameAsBatchCount(data));
    });
  }, []);

  const getBaseMenu = useCallback(
    () =>
      _.compact([
        {
          key: 'print',
          title: '打印',
          auth: authDict.qualityinspectiontask_print,
          onClick: async () => {
            try {
              const { id } = gcUrl.getParams();

              const { data } = await fetchMatchRuleQueryMatchTemplateBatch({
                instanceId: id,
                objectCodeList: ['QualityInspectionTask', 'InspectionItemRecord'], // 目前支持检验任务和检验项记录。预览入口都是在检验任务 但是根据匹配规则查询模板可以是检验任务或检验项记录作为根对象 后期再增加直接在这个参数追加即可
                bizObjectCode: 'QualityInspectionTask',
              });

              if (data) {
                setTemplateData(
                  _.map(data, (e: any) => {
                    return {
                      ...e,
                      objectCode: e?.rootObjectCode, // 定义一个objectCode是因为在自定义对象那边用的另一个接口没有返回rootObjectCode关于对象code的数据  便于选择模板的时候两个接口兼容
                      instanceId: id, // 实例id
                    };
                  }),
                );

                setChooseVisible(true);
              }
            } catch (error) {
              console.log('error: ', error);
            }
          },
        },
        !isHidden && {
          title: 'edit',
          auth: authDict.qualityinspectiontask_edit,
          buttonRender:
            data?.status === QcTaskStatusEnum.unstarted ? (
              <EditButton
                title="编辑"
                instanceId={taskId}
                objectCode={OBJECT_OF_CODE.qcTask}
                type={'edit'}
                buttonType="primary"
                onClick={(layoutId) => {
                  toCheckTaskEdit(taskId, layoutId, data?.checkType!);
                }}
              />
            ) : (
              <Button disabled>编辑</Button>
            ),
        },
      ]),
    [data],
  );

  return isLoading ? (
    <Spin style={{ width: '100%' }} />
  ) : (
    <>
      <CustomDetailLayout
        objectCode={'QualityInspectionTask'}
        dataSource={data}
        instanceIdParamKey={gcUrl.getParams()?.id}
        layoutId={gcUrl.getParams()?.layoutId}
        baseMenu={getBaseMenu()}
        title="任务详情"
        customInfoItems={[
          {
            label: '执行人',
            dataIndex: 'executor',
            render: (operator: { id: number; mainProperty: string; avatarUrl: string } | null) => {
              if (!operator) return '-';
              return (
                <AvatarDisplay
                  id={operator.id}
                  isShowTag
                  name={operator.mainProperty}
                  avatarUrl={operator.avatarUrl}
                />
              );
            },
          },
          {
            label: '可领取人',
            dataIndex: 'candidates',
            render: (list: { id: number; mainProperty: string }[] | null) => {
              if (!list?.length) return '-';
              return list.map((value) => <AvatarDisplay id={value.id} name={value.mainProperty} />);
            },
          },
          {
            label: '可领取部门',
            dataIndex: 'candidateDeps',
            render: (list: { id: number; mainProperty: string }[] | null) => {
              if (!list?.length) return '-';
              return (
                <TagList dataSource={list} maxShow={1} labelPath="mainProperty" valuePath="id" />
              );
            },
          },
          {
            label: '样本信息',
            dataIndex: 'SampleInventoryRecord',
            isFullLine: true,
            render: () => (
              <BlTable<CheckMaterial>
                columns={getSampleDetailColumns()}
                dataSource={data?.sampleMaterials || []}
                scroll={{ x: 'max-content' }}
              />
            ),
          },
          ...checkMaterialInfo.items,
          {
            label: '检验任务检验项记录',
            dataIndex: 'InspectionItemRecord',
            isFullLine: true,
            render: () => (
              <RecordDetailTable
                data={data?.checkItems || []}
                chekItemList={data?.qcConfig?.qcConfigCheckItemList || []}
                reportCount={reportCount}
                sampleMaterials={data?.sampleMaterials || []}
                isSameAsBatchCount={isSameAsBatchCount}
                setRecordDetailData={setRecordDetailData}
                setDetailVisible={setDetailVisible}
              />
            ),
          },
        ]}
      />
      {detailVisible && (
        <RecordDetailDrawer
          visible={detailVisible}
          closeDetail={() => {
            setDetailVisible(false);
          }}
          data={recordDetailData}
        />
      )}
      {chooseVisible && (
        <ChooseTemplateModal
          visible={chooseVisible}
          initData={templateData}
          onCancel={() => {
            setChooseVisible(false);
          }}
        />
      )}
    </>
  );
};

export default QcTaskDetail;
