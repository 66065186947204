import { Form, FormInstance, Input } from 'antd';
import { isNumber } from 'lodash';
import { BlSortFormList } from 'src/components';
import {
  numberMinMaxCheck,
  numberStringCheck,
  validateFormListRepeat,
} from 'src/utils/formValidators';

interface Props {
  form: FormInstance;
  name: string;
}

interface SampleProps {
  id?: number;
  maxBatch?: number;
  minBatch?: number;
  sampleCount?: number;
}

const requireNode = <span style={{ color: 'red' }}>*</span>;
const width = '100%';
const REJECT = 'rejectCount';
const ACCEPT = 'acceptCount';

function AcceptLimitList(props: Props) {
  const { form, name } = props;
  const getOptions = (field: any, fieldName: string) => ({
    fieldKey: [field.fieldKey, fieldName],
    name: [field.name, fieldName],
    validateFirst: true,
    validateTrigger: ['onChange', 'onBlur'],
    style: { marginBottom: '0' },
  });

  const columns = [
    {
      dataIndex: 'name',
      title: <div>{requireNode} 质量限名称</div>,
      render: (text: string, field: any) => {
        return (
          <div key={field.key}>
            <Form.Item
              {...getOptions(field, 'name')}
              style={{ margin: '5px 0' }}
              rules={[
                { required: true, message: '请输入质量限名称' },
                { max: 100, message: '长度过长，须不超过100个字符' },
                {
                  validator: validateFormListRepeat(
                    name,
                    'name',
                    form,
                    '此同名质量限名称已经存在，请勿重复创建',
                  ),
                },
              ]}
            >
              <Input style={{ width }} placeholder="质量限名称" />
            </Form.Item>
          </div>
        );
      },
    },
    {
      dataIndex: 'limit',
      title: <div>{requireNode} 样本量</div>,
      require: true,
      render: (text: string, field: any) => {
        const { sampleList = [] } = form.getFieldsValue();

        return sampleList?.map((item: any, key: number) => {
          return (
            <div key={item.minBatch}>
              <Form.Item style={{ margin: '5px 0' }}>
                <Input
                  disabled
                  style={{ width }}
                  value={`批量${item?.minBatch}~${item?.maxBatch} 样本量：${item?.sampleCount}`}
                />
              </Form.Item>
              <Form.Item
                initialValue={item?.minBatch}
                hidden
                name={[field.name, 'limit', key, 'minBatch']}
              />
              <Form.Item
                initialValue={item?.maxBatch}
                hidden
                name={[field.name, 'limit', key, 'maxBatch']}
              />
              <Form.Item
                initialValue={item?.sampleCount}
                hidden
                name={[field.name, 'limit', key, 'sampleCount']}
              />
            </div>
          );
        });
      },
    },
    {
      dataIndex: 'acceptCount',
      title: <div>{requireNode} 接收数</div>,
      render: (text: string, field: any) => {
        const { sampleList = [] } = form.getFieldsValue();

        return sampleList?.map((item: any, key: number) => {
          return (
            <Form.Item
              name={[field.name, 'limit', key, ACCEPT]}
              validateFirst
              dependencies={[[field.name, 'limit', key, REJECT]]}
              style={{ margin: '5px 0' }}
              validateTrigger={['onChange', 'onBlur']}
              rules={[
                { required: true, message: '请输入接收数' },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    max: 9999999,
                    maxAllowEqual: false,
                    isInteger: true,
                  }),
                },
                () => ({
                  validator(_, value) {
                    if (Number(value) <= Number(item.sampleCount)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('不可大于样本量'));
                  },
                }),
              ]}
            >
              <Input
                onChange={(e: any) => {
                  const acceptCount = e.target.value;

                  if (!numberStringCheck.pattern.test(acceptCount)) {
                    return;
                  }

                  if (acceptCount) {
                    form.setFields([
                      {
                        name: [name, field.name, 'limit', key, REJECT],
                        value: String(Number(acceptCount) + 1),
                      },
                    ]);
                  }
                }}
                style={{ width }}
                placeholder="接收数"
              />
            </Form.Item>
          );
        });
      },
    },
    {
      dataIndex: 'rejectCount',
      title: <div>{requireNode} 拒收数</div>,
      render: (text: string, field: any) => {
        const { sampleList = [] } = form.getFieldsValue();

        return sampleList?.map((item: any, key: number) => {
          return (
            <Form.Item
              dependencies={[[field.name, 'limit', key, ACCEPT]]}
              style={{ margin: '5px 0' }}
            >
              {({ getFieldValue }) => {
                return (
                  <Form.Item
                    style={{ margin: 0 }}
                    validateFirst
                    dependencies={[[field.name, 'limit', key, ACCEPT]]}
                    name={[field.name, 'limit', key, REJECT]}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      { required: true, message: '请输入拒收数' },
                      {
                        validator: numberMinMaxCheck({
                          min: 1,
                          max: 9999999,
                          minAllowEqual: true,
                          maxAllowEqual: false,
                          isInteger: true,
                        }),
                      },
                      () => ({
                        validator(_, value) {
                          const acceptCount = getFieldValue([
                            name,
                            field.name,
                            'limit',
                            key,
                            ACCEPT,
                          ]);

                          if (Number(value) - 1 === Number(acceptCount)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('拒收数等于接收上限+1'));
                        },
                      }),
                      () => ({
                        validator(_, value) {
                          if (Number(value) <= Number(item.sampleCount)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('不可大于样本量'));
                        },
                      }),
                    ]}
                  >
                    <Input
                      onChange={(e: any) => {
                        const rejectCount = e.target.value;

                        if (!numberStringCheck.pattern.test(rejectCount)) {
                          return;
                        }

                        if (rejectCount) {
                          form.setFields([
                            {
                              name: [name, field.name, 'limit', key, ACCEPT],
                              value: String(Number(rejectCount) - 1),
                            },
                          ]);
                        }
                      }}
                      style={{ width }}
                      placeholder="拒收数"
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        });
      },
    },
    {
      dataIndex: 'action',
      title: '操作',
      render: (text: string, field: any) => (
        <div key={field.key}>
          <a
            onClick={() => {
              const { sampleList = [] } = form.getFieldsValue();
              const rowFields: any = []; // 列出需要清空的所有接收数和拒收数的表单项

              sampleList?.forEach((s: SampleProps, i: number) => {
                rowFields.push([name, field.name, 'limit', i, REJECT]);
                rowFields.push([name, field.name, 'limit', i, ACCEPT]);
              });
              form.resetFields([[name, field.name, 'name'], ...rowFields]);
            }}
          >
            清空
          </a>
        </div>
      ),
    },
  ];

  return (
    <BlSortFormList
      buttonText="添加质量限"
      form={form}
      addInit={{
        name: '',
        limit: [
          {
            [REJECT]: '',
            [ACCEPT]: '',
          },
        ],
      }}
      name={name}
      renderColumns={() => {
        return columns;
      }}
    />
  );
}

export default AcceptLimitList;
