import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, message as Message, Badge } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import _ from 'lodash';
import { RecordListLayout, FilterItem } from 'layout';
import { TextToolTip } from '@blacklake-web/component';

import { fetchPickOrderList } from 'src/api/ytt/med-domain/store-requisition';
import { fieldTypeList, gcTime } from 'src/utils';
import { replaceSign } from 'src/utils/constants';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { intersectionValueOfId, mapLabeledValueToValue } from 'src/utils/array';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { lookup } from 'src/dict';
import { CRUD } from 'src/dict/common';
import { StatusEnum } from 'src/dict/storeRequisition';
import { StatusDisplayMap, PickOrderTypeMap } from 'src/dict/storeRequisition/mappings';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { materialEntity, MaterialEntity } from 'src/entity';
import { SearchSelect, SingleOperationLogComponent, TagList, BlIcon } from 'src/components';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import type { StoreRequisitionListItemProps } from '../constants';
import { fieldLabels, NOTHING_TO_TRANSFER } from '../constants';
import { goToEdit, goToDetail, goToMergeTranfer, goToCommonCreate } from '../utils';
import { closeStoreRequisiton } from '../services';
import IntegratePickConfig from '../IntegratePick/IntegratePickConfig';

/** 把param转换成{key: value}[], 显示在RecordListInfo的tag里 */
const formatDataToDisplay = (param: any) => {
  return param;
};

// const errorColumns = [
//   {
//     title: '领料申请编号',
//     dataIndex: 'name',
//     render: (text: string) => <a>{text}</a>,
//   },
//   {
//     title: '失败原因',
//     dataIndex: 'failReason',
//   },
// ];

export const statusBadgeMap = new Map([
  [
    StatusEnum.new,
    <Badge text={lookup('storeRequisiton.StatusDisplayMap', StatusEnum.new)} status="success" />,
  ],
  [
    StatusEnum.closed,
    <Badge text={lookup('storeRequisiton.StatusDisplayMap', StatusEnum.closed)} status="error" />,
  ],
]);

export default function StoreRequisitionList() {
  // 选中行的key，用以批量删除
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<StoreRequisitionListItemProps[]>([]);
  // 刷新用的时间戳，在refresh方法里用
  const [refreshMarker, setRefreshMarker] = useState<number>(Date.now());
  const [visibleLog, setVisibleLog] = useState(false);
  // 当前操作行的id，传给操作记录用
  const [currentOrderId, setCurrentOrderId] = useState<number>();
  // 齐套领料弹窗
  const [showIntegratePickConfig, setShowIntegratePickConfig] = useState(false);
  const [closeForm] = Form.useForm();

  /** 用来打开弹框 */
  const openModal = useOpenExImportModal();
  /** 用来存储当前页面查询条件到 redux */
  const dispatch = useDispatch();

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  // param是由antd的table发出，经由RecordListLayout封装过一次的参数，描述的是表格的变化(分页，排序，筛选，搜索信息)
  // 将这些信息转换成后台分页查询列表数据的参数格式，即requestFn的传入参数应有的格式
  const formatDataToQuery = (param: any) => {
    const {
      createdAt,
      updatedAt,
      pickOrderStatus,
      materialCode,
      materialName,
      requirementTime,
      sourceWarehouseName,
      targetWarehouseName,
      inputProcessCode,
      inputProcessName,
      workCenter,
      batchCodeId,
      workOrderCode,
      productionDepartment,
      supplierList,
      creator,
      operator,
      ...rest
    } = param;

    // 接口需要的创建时间和更新时间为number型的timestamp
    const queryParam = {
      createdAtStart: createdAt && createdAt[0] ? Date.parse(createdAt[0]) : undefined,
      createdAtEnd: createdAt && createdAt[1] ? Date.parse(createdAt[1]) : undefined,
      updatedAtStart: updatedAt && updatedAt[0] ? Date.parse(updatedAt[0]) : undefined,
      updatedAtEnd: updatedAt && updatedAt[1] ? Date.parse(updatedAt[1]) : undefined,
      status: pickOrderStatus?.join(','),
      materialId: intersectionValueOfId(materialCode, materialName),
      sourceWarehouseId: mapLabeledValueToValue(sourceWarehouseName),
      targetWarehouseId: mapLabeledValueToValue(targetWarehouseName),
      requirementTimeStart: requirementTime?.[0] ? Date.parse(requirementTime[0]) : undefined,
      requirementTimeEnd: requirementTime?.[1] ? Date.parse(requirementTime[1]) : undefined,
      inputProcessId: intersectionValueOfId(inputProcessCode, inputProcessName),
      workCenterId: mapLabeledValueToValue(workCenter),
      batchCodeId: mapLabeledValueToValue(batchCodeId),
      workOrderId: mapLabeledValueToValue(workOrderCode),
      productionDepartmentId: productionDepartment?.map((item: LabeledValue) => item.value),
      supplierId: mapLabeledValueToValue(supplierList),
      creatorId: mapLabeledValueToValue(creator),
      operatorId: mapLabeledValueToValue(operator),
      ...rest,
    };

    dispatch.excelImport.updateBusinessData({ businessData: queryParam });
    return queryParam;
  };

  const formatDataToFormDisplay = (data: any) => {
    const { createdAt, updatedAt, requirementTime, ...rest } = data;

    return {
      ...rest,
      createdAt: createdAt && gcTime.formatRangeTimeToMoment(createdAt),
      updatedAt: updatedAt && gcTime.formatRangeTimeToMoment(updatedAt),
      requirementTime: requirementTime && gcTime.formatRangeTimeToMoment(requirementTime),
    };
  };

  // TODO: 导入导出和操作日志
  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      auth: authDict.pickorder_export,
      icon: <BlIcon type="icondaochu" />,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.pickOrder,
        });
      },
    },
    {
      title: '新建领料申请',
      auth: authDict.pickorder_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: goToCommonCreate,
      items: [
        {
          title: '导入',
          showExport: true,
          auth: authDict.pickorder_import,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.pickOrder,
            });
          },
        },
        {
          title: '齐套领料',
          auth: authDict.pickorder_complete,
          onClick: () => setShowIntegratePickConfig(true),
        },
      ],
    },
  ];

  const formatWarehouseToTransfer = (rowData: StoreRequisitionListItemProps) => {
    const {
      sourceWarehouseId,
      sourceWarehouseName,
      sourceWarehouse,
      targetWarehouseId,
      targetWarehouseName,
      targetWarehouse,
      ...rest
    } = rowData;

    return {
      ...rest,
      sourceWarehouse: sourceWarehouseId
        ? {
            label: sourceWarehouseName,
            value: {
              id: sourceWarehouseId,
              name: sourceWarehouseName,
              code: sourceWarehouse?.code,
            },
          }
        : undefined,
      targetWarehouse: targetWarehouseId
        ? {
            label: targetWarehouseName,
            value: {
              id: targetWarehouseId,
              name: targetWarehouseName,
              code: targetWarehouse?.code,
            },
          }
        : undefined,
    };
  };

  const batchMenu = [
    {
      title: '调拨',
      auth: authDict.transferorder_add,
      // onSuccess, onFail: batchMenu onClick回调的参数，onSuccess会刷新列表并清空批量按钮的loading状态，onFail只清空loading状态
      onClick: (onSuccess?: () => void, onFail?: () => void) => {
        // TODO pickOrderIssuedAmount只是暂时站位，等后台接口更新之后，这里要改成发起调拨数
        const rowsToTransfer = selectedRows
          .filter(
            (row) =>
              row.pickOrderStatus === StatusEnum.new &&
              row.requestPickAmount.amount! - (row.warehouseIssuedAmount.amount ?? 0) > 0,
            // HHZZ3-33061 允许调拨数计算方法变更：发起调拨数更改为仓库调拨数（扣除那些发起调拨但未完成的数量）
          )
          ?.map(formatWarehouseToTransfer);

        if (rowsToTransfer.length === 0) {
          Message.error(NOTHING_TO_TRANSFER);
          onFail?.();
        } else {
          // save to redux store
          dispatch({
            type: 'transferMaterials/setTansferMaterials',
            payload: rowsToTransfer,
          });
          goToMergeTranfer();
        }
      },
    },
  ];

  const columns = [
    {
      title: fieldLabels.storeRequisitionCode,
      dataIndex: 'pickOrderCode',
      width: 150,
      fixed: 'left',
      sorter: true,
      isFilter: true,
      type: fieldTypeList.text,
      render: (text: string, record: StoreRequisitionListItemProps, index: number, config: any) => {
        return hasAuth(authDict.pickorder_detail) ? (
          <a
            onClick={() => {
              goToDetail(record.pickOrderId!);
            }}
          >
            <TextToolTip text={text} width={config.contentWidth} />
          </a>
        ) : (
          text
        );
      },
    },
    {
      title: fieldLabels.type,
      dataIndex: 'pickOrderType',
      width: 140,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: PickOrderTypeMap,
        mode: 'multiple',
      },
      render: lookup('storeRequisiton.PickOrderTypeMap'),
    },
    {
      title: fieldLabels.status,
      dataIndex: 'pickOrderStatus',
      width: 110,
      isFilter: true,
      type: fieldTypeList.select,
      selectProps: {
        options: StatusDisplayMap,
        mode: 'multiple',
      },
      render: (status: StatusEnum) => statusBadgeMap.get(status),
    },
    {
      title: fieldLabels.lineNumber,
      dataIndex: 'line',
      width: 100,
    },
    {
      title: fieldLabels.materialCode,
      dataIndex: ['material', 'baseInfo', 'code'],
      width: 150,
      isFilter: true,
      filterName: 'materialCode',
      renderItem: (
        <SearchSelect
          fetchType="material"
          labelPath="code"
          valuePath="id"
          placeholder="请输入"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      title: fieldLabels.materialName,
      dataIndex: ['material', 'baseInfo', 'name'],
      width: 150,
      isFilter: true,
      filterName: 'materialName',
      renderItem: (
        <SearchSelect
          fetchType="material"
          labelPath="name"
          valuePath="id"
          placeholder="请输入"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      title: fieldLabels.materialCategory,
      dataIndex: ['material', 'category', 'name'],
      width: 150,
    },
    {
      title: fieldLabels.materialProperty,
      dataIndex: ['material', 'attribute'],
      width: 150,
      render: (_text: string, record: StoreRequisitionListItemProps) => (
        <TagList
          dataSource={materialEntity.getMaterialAttrs(
            record.material as MaterialEntity.MaterialEntity,
          )}
        />
      ),
    },
    {
      title: fieldLabels.specification,
      dataIndex: ['material', 'baseInfo', 'specification'],
      width: 150,
    },
    {
      title: fieldLabels.requireCount,
      dataIndex: ['requestPickAmount', 'amount'],
      width: 100,
    },
    {
      title: fieldLabels.issuedCount,
      dataIndex: ['pickOrderIssuedAmount', 'amount'],
      width: 100,
    },
    {
      title: fieldLabels.receivedCount,
      dataIndex: ['receivePickAmount', 'amount'],
      width: 100,
    },
    {
      title: fieldLabels.unit,
      dataIndex: ['requestPickAmount', 'unitName'],
      width: 100,
    },
    {
      title: fieldLabels.dispatchWarehouse,
      dataIndex: 'sourceWarehouseName',
      width: 300,
      isFilter: true,
      render: (sourceWarehouseName: string, { sourceWarehouse }: StoreRequisitionListItemProps) =>
        sourceWarehouse && `${sourceWarehouse?.code}|${sourceWarehouseName}`,
      renderItem: (
        <SearchSelect fetchType="warehouse" placeholder="请输入" labelInValue mode="multiple" />
      ),
    },
    {
      title: fieldLabels.acceptWarehouse,
      dataIndex: 'targetWarehouseName',
      width: 300,
      isFilter: true,
      render: (targetWarehouseName: string, { targetWarehouse }: StoreRequisitionListItemProps) =>
        targetWarehouse && `${targetWarehouse?.code}|${targetWarehouseName}`,
      renderItem: (
        <SearchSelect fetchType="warehouse" placeholder="请输入" labelInValue mode="multiple" />
      ),
    },
    {
      title: fieldLabels.requireTime,
      dataIndex: 'requirementTime',
      width: 150,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: fieldLabels.processNumber,
      dataIndex: 'inputProcessCode',
      width: 120,
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="process"
          labelPath="code"
          valuePath="id"
          placeholder="请输入"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      title: fieldLabels.processName,
      dataIndex: 'inputProcessName',
      width: 150,
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="process"
          labelPath="name"
          valuePath="id"
          placeholder="请输入"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      title: fieldLabels.workCenter,
      dataIndex: ['workCenter', 'name'],
      width: 150,
      isFilter: true,
      filterName: 'workCenter',
      renderItem: (
        <SearchSelect fetchType="workCenter" placeholder="请输入" labelInValue mode="multiple" />
      ),
    },
    {
      title: fieldLabels.supplier,
      dataIndex: 'supplierList',
      width: 150,
      isFilter: true,
      render: (suppliers: { code: string; id: number; name: string }[]) =>
        suppliers?.map((supplier) => supplier.name).join(','),
      renderItem: (
        <SearchSelect fetchType="supplier" placeholder="请输入" labelInValue mode="multiple" />
      ),
    },
    {
      title: fieldLabels.batchNumber,
      dataIndex: ['batchCode', 'code'],
      width: 150,
      isFilter: true,
      filterName: 'batchCodeId',
      renderItem: (
        <SearchSelect fetchType="batchNo" placeholder="请输入" mode="multiple" labelInValue />
      ),
    },
    {
      title: fieldLabels.productionOrderCode,
      dataIndex: 'workOrderCode',
      width: 150,
      isFilter: true,
      renderItem: (
        <SearchSelect
          fetchType="workOrderFilterList"
          placeholder="请输入"
          labelInValue
          mode="multiple"
        />
      ),
    },
    {
      title: fieldLabels.productionDepartment,
      dataIndex: 'productionDepartment',
      width: 150,
      isFilter: true,
      render: (department: { code: string; id: number; name: string }) =>
        department && (
          <AvatarDisplay
            isUser={false}
            isShowTag={false}
            id={department.id}
            name={department.name}
          />
        ),
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser={false} />,
    },
    {
      title: fieldLabels.mainOutputBatchNumber,
      dataIndex: 'mainOutputBatchNumber',
      width: 160,
      isFilter: true,
    },
    {
      title: fieldLabels.lineRemark,
      dataIndex: 'remark',
      width: 150,
    },
    {
      title: fieldLabels.creator,
      dataIndex: 'creator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (_text: string, record: StoreRequisitionListItemProps) => {
        return record.creator && record.creator.id ? (
          <AvatarDisplay
            id={record.creator.id}
            name={record.creator.name}
            avatarUrl={record.creator.avatarUrl}
            key={record.creator.id}
            isShowTag={false}
            isUser
          />
        ) : (
          replaceSign
        );
      },
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: fieldLabels.createdAt,
      dataIndex: 'createdAt',
      width: 180,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: fieldLabels.operator,
      dataIndex: 'operator',
      width: 150,
      isFilter: true,
      type: fieldTypeList.reference,
      render: (_text: string, record: StoreRequisitionListItemProps) => {
        return record.operator && record.operator.id ? (
          <AvatarDisplay
            id={record.operator.id}
            name={record.operator.name}
            avatarUrl={record.operator.avatarUrl}
            key={record.operator.id}
            isShowTag={false}
            isUser
          />
        ) : (
          replaceSign
        );
      },
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      title: fieldLabels.updatedAt,
      dataIndex: 'updatedAt',
      width: 180,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
  ];

  const filterList: FilterItem[] = columns
    .filter((i) => i.isFilter)
    .map((column) => {
      return _.pick(
        {
          ...column,
          label: column.title,
          name: column.filterName ?? column.dataIndex,
          type: column.type,
        },
        [
          'label',
          'name',
          'type',
          'rules',
          'renderItem',
          'selectProps',
          'inputProps',
          'datePickerProps',
          'dateFormat',
        ],
      ) as FilterItem;
    });

  const getOperationList = (record: StoreRequisitionListItemProps) => {
    const { pickOrderStatus, pickOrderId } = record;

    return [
      {
        title: lookup('common.crud', CRUD.view),
        auth: authDict.pickorder_detail,
        onClick: () => {
          goToDetail(pickOrderId!);
        },
      },
      {
        title: '调拨',
        auth: authDict.transferorder_add,
        // HHZZ3-33061 允许调拨数计算方法变更：发起调拨数更改为仓库调拨数（扣除那些发起调拨但未完成的数量）
        disabled:
          pickOrderStatus === StatusEnum.closed ||
          (record.requestPickAmount?.amount! ?? 0) - (record.warehouseIssuedAmount?.amount ?? 0) <=
            0,
        onClick: () => {
          // HHZZ3-30101 列表与详情页的仓库字段格式不同，跳转前分别处理
          const formattedRecord = formatWarehouseToTransfer(record);

          dispatch({
            type: 'transferMaterials/setTansferMaterials',
            payload: [formattedRecord],
          });
          goToMergeTranfer();
        },
      },
      {
        title: lookup('common.crud', CRUD.edit),
        auth: authDict.pickorder_edit,
        disabled: pickOrderStatus === StatusEnum.closed,
        onClick: () => {
          goToEdit(pickOrderId!);
        },
      },
      {
        title: '关闭',
        auth: authDict.pickorder_close,
        disabled: pickOrderStatus === StatusEnum.closed,
        onClick: () => {
          BLDelConfirm('关闭后将无法对领料申请进行操作，确认关闭吗？', closeForm, async () => {
            await closeStoreRequisiton(record.pickOrderId!, closeForm);
            refresh();
          });
        },
      },
      {
        title: '操作记录',
        auth: authDict.pickorder_operrecord,
        onClick: () => {
          setCurrentOrderId(record.pickOrderId);
          setVisibleLog(true);
        },
      },
    ];
  };

  return (
    <>
      <RecordListLayout<StoreRequisitionListItemProps>
        refreshMarker={refreshMarker}
        filterList={filterList}
        configcacheKey="storeRequisition"
        requestFn={fetchPickOrderList}
        placeholder="请输入搜索关键字"
        batchMenu={batchMenu}
        mainMenu={mainMenu}
        rowKey="pickOrderDetailId"
        pagination={{
          pageSizeOptions: ['10', '20'],
        }}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectedKeys, selectedRows) => {
          setSelectedRowKeys(selectedKeys);
          setSelectedRows(selectedRows!);
        }}
        columns={columns}
        userAuth={getAuthFromLocalStorage()}
        getOperationList={getOperationList}
        formatDataToDisplay={formatDataToDisplay}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={Number(currentOrderId)}
          objectCode={'PickOrder'} // 对象在对象平台唯一定义的objectCode
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      {showIntegratePickConfig && (
        <IntegratePickConfig
          onClose={() => setShowIntegratePickConfig(false)}
          visible={showIntegratePickConfig}
        />
      )}
    </>
  );
}
