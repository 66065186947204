import React from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import StoreDetailInfo from '../components/StoreDetailBaseInfo';

interface Props extends RouteComponentProps {
  match: match<{ id: string }>;
}

const StoreDetailPage = (props: Props) => {
  const { match } = props;
  const storeId = Number(match?.params?.id);

  return <StoreDetailInfo storeId={storeId} type="page" />;
};

export default StoreDetailPage;
