import React, { useEffect, useState } from 'react';
import { Form, message as Message } from 'antd';
import BaseInfo from './components/createAndEditBaseInfo';
import { changeValues, formatTime } from './utils';
import { match, RouteComponentProps } from 'react-router-dom';
import { fetchShiftGet, fetchShiftUpdate } from 'src/api/ytt/calendar-domain';
import { throttleFn } from '../../warehouse/utils';

interface EditProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const EditShift = (props: EditProps) => {
  const { match, history } = props;
  const shiftId = Number(match?.params?.id);
  const [detailData, setDetailData] = useState({} as any);

  const [editForm] = Form.useForm();

  const [workHourDisplay, setWorkHour] = useState('');

  const [totalHourDisplay, setTotalHour] = useState('');

  // 获取有效工时
  const getWorkHour = (time: number) => {
    const workDisplay = formatTime(time);

    editForm.setFieldsValue({ effectiveDuration: time });

    setWorkHour(workDisplay);
  };

  // 获取总工时

  const getTotalHour = (time: number) => {
    const workDisplay = formatTime(time);

    editForm.setFieldsValue({ sumDuration: time });

    setTotalHour(workDisplay);
  };

  const handleFinish = async () => {
    try {
      const { shiftInfos, ...restValue } = await editForm?.validateFields();

      const newShiftInfos = changeValues(shiftInfos);

      const newValue = { ...restValue, shiftInfoUpdateCOS: newShiftInfos, id: shiftId };

      const { data, message } = await fetchShiftUpdate(newValue);

      if (data) {
        Message.success('编辑成功');
        history.goBack();
        return;
      }
      Message.error(message);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await fetchShiftGet({ id: shiftId });

      if (data) {
        setDetailData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [shiftId]);

  return (
    <BaseInfo
      onFinish={throttleFn(handleFinish)}
      onCancel={() => {
        history.goBack();
      }}
      title={'编辑班次'}
      form={editForm}
      initialData={detailData}
      edit
      getWorkHour={getWorkHour}
      getTotalHour={getTotalHour}
      workHourDisplay={workHourDisplay}
      totalHourDisplay={totalHourDisplay}
    />
  );
};

export default EditShift;
