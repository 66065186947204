import React, { useEffect, useState } from 'react';
import { DataFormLayoutForModal } from 'layout';
import { Form, message as Message } from 'antd';
import PositionBaseInfo from '../components/positionsCreateAndEditBaseInfo';
import {
  fetchStorageLocationInfo,
  fetchStorageLocationModify,
} from 'src/api/ytt/storage-domain/position';
import { throttleFn } from '../utils';

interface editProps {
  visible: Boolean;
  onCancel: () => void;
  refreshDetails?: () => void;
  refreshTable?: () => void;
  positionId: number;
  storeQcCtrl?: number;
}

const PositionsEdit = (props: editProps) => {
  const { visible, onCancel, positionId, refreshTable, refreshDetails, storeQcCtrl } = props;
  const [detailData, setDetailData] = useState({} as any);
  const [modalForm] = Form.useForm();
  const fetchData = async () => {
    try {
      const { data } = await fetchStorageLocationInfo({ id: positionId }, { legacy: true });

      if (data) {
        setDetailData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();
      const formatEnableQcCtrl = value?.enableQcCtrl ? 1 : 0;
      const formatQcCtrlList = formatEnableQcCtrl ? value?.qcCtrlList : [];
      const newValue = {
        ...value,
        enableQcCtrl: formatEnableQcCtrl,
        qcCtrlList: formatQcCtrlList,
        id: positionId,
      };

      fetchStorageLocationModify(newValue)
        .then((res) => {
          const { code, message } = res;

          if (code === 200) {
            Message.success('编辑成功');
            typeof onCancel === 'function' && onCancel();
            typeof refreshDetails === 'function' && refreshDetails();
            typeof refreshTable === 'function' && refreshTable();
            return;
          }
          Message.error(message);
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      width={800}
      onClose={onCancel}
      content={
        <PositionBaseInfo
          initialData={detailData}
          title="编辑仓位"
          onCancel={onCancel}
          onFinish={throttleFn(handleFinish)}
          form={modalForm}
          edit
          storeQcCtrl={storeQcCtrl}
        />
      }
    />
  );
};

export default PositionsEdit;
