import { useState } from 'react';
import { FormInstance, Form, Input, Switch } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { LabeledValue } from 'antd/lib/select';
import { ColumnProps } from 'antd/es/table';
import _ from 'lodash';

import { BlSortFormList, SearchSelect } from 'src/components';
import { lookup } from 'src/dict';
import { UsageStatus } from 'src/dict/common';
import { ResourceBusinessType, ParmTypeEnum } from 'src/dict/resources';
import { numberLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import { ResourceParamsListItemType } from '../index.d';
import BatchParamSelector from '../components/BatchParamSelector';
import {
  getNumberFromInput,
  formatResourceParamToFormList,
  formatResourceParamOption,
} from '../utils';

const MAX_PARAMS_COUNT = 100;

export default function EquipParamsFormList({ form }: { form: FormInstance }) {
  const [paramSelectorVisible, setParamSelectorVisible] = useState(false);

  const handleSelectParamFinish = (
    selectedRowKeys: React.Key[],
    selectedRows: ResourceParamsListItemType[],
  ) => {
    const originParams = form.getFieldValue('resourceParamsList');
    // 去重，只有原来不存在的新行才可以被加入
    const newRows = selectedRows
      .filter((row) => _.isNil(_.find(originParams, { paramsId: row.id })))
      .map(formatResourceParamToFormList);
    const fullRows = [...(originParams ?? []), ...newRows];

    form.setFieldsValue({ resourceParamsList: fullRows });
    setParamSelectorVisible(false);
  };
  /**
   * 校验设备标签/设备参数,不能重复
   */
  const validateDuplicated =
    (listField: string, field: string, idField: string, message: string) =>
    (rule: any, value: LabeledValue) => {
      if (!value?.key) return Promise.resolve('');
      const valueId = JSON.parse(value.value as string)[idField];
      const formList = form.getFieldValue(listField);

      if (!formList?.length) return Promise.resolve('');
      // 新添加未做任何编辑的行，在formList里的值不是object，是空字符串
      const objList = formList?.map((formItem: any) => {
        return _.isObject(formItem as any) ? JSON.parse(formItem[field]?.value) : undefined;
      });
      const firstIndex = _.findIndex(objList, { [idField]: valueId });
      const lastIndex = _.findLastIndex(objList, { [idField]: valueId });

      if (firstIndex !== lastIndex) {
        return Promise.reject(message);
      }

      return Promise.resolve('');
    };

  const getParamColumns: (
    remove: (index: number | number[]) => void,
  ) => ColumnProps<any & FormListFieldData>[] = () => [
    {
      title: '设备参数',
      width: 180,
      render: (text, field) => (
        <Form.Item
          name={[field.name, 'param']}
          style={{ marginBottom: 0 }}
          rules={[
            {
              validator: validateDuplicated(
                'resourceParamsList',
                'param',
                'paramsId',
                '不能选择重复的设备参数',
              ),
            },
            {
              required: true,
              message: '已添加的参数必填',
            },
          ]}
        >
          <SearchSelect
            placeholder="请选择设备参数"
            fetchType="resourceParams"
            params={{ businessType: ResourceBusinessType.equipment, status: UsageStatus.enabled }}
            renderOption={formatResourceParamOption}
            onChange={(selectedLabeledValue: LabeledValue) => {
              const paramString = selectedLabeledValue?.value;
              const paramValueObj = paramString && {
                ...JSON.parse(paramString as string),
                param: selectedLabeledValue,
              };
              const params: ResourceParamsListItemType[] = form.getFieldValue('resourceParamsList');

              form.setFieldsValue({
                resourceParamsList: params?.map((param, index: number) => {
                  return index === field.name ? paramValueObj : param;
                }),
              });
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: '参数类型',
      width: 140,
      render: (text, field) => {
        return (
          <Form.Item dependencies={[['resourceParamsList', field.name, 'param']]} noStyle>
            {() => {
              const paramString = form.getFieldValue(['resourceParamsList', field.name, 'param']);
              const paramValue = paramString && JSON.parse(paramString.value);

              return (
                <Input disabled value={lookup('resources.paramType', paramValue?.paramsType)} />
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '参数单位',
      width: 140,
      render: (text, field) => {
        return (
          <Form.Item dependencies={[['resourceParamsList', field.name, 'param']]} noStyle>
            {() => {
              const paramString = form.getFieldValue(['resourceParamsList', field.name, 'param']);
              const paramValue = paramString && JSON.parse(paramString.value);

              return <Input disabled value={paramValue?.unitName} />;
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '参数标准值',
      width: 120,
      render: (text, field) => {
        return (
          <Form.Item dependencies={[['resourceParamsList', field.name, 'param']]} noStyle>
            {() => {
              const paramString = form.getFieldValue(['resourceParamsList', field.name, 'param']);
              const paramValue = paramString && JSON.parse(paramString.value);
              const disabled = paramValue?.paramsType === ParmTypeEnum.text;

              return (
                <Form.Item
                  getValueFromEvent={getNumberFromInput}
                  name={[field.name, 'paramsStandard']}
                  style={{ marginBottom: 0 }}
                  rules={[{ validator: numberLengthCheck(9, 6) }]}
                >
                  <Input disabled={disabled} />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '参数上限',
      width: 120,
      render: (text, field) => {
        return (
          <Form.Item
            dependencies={[
              ['resourceParamsList', field.name, 'param'],
              ['resourceParamsList', field.name, 'paramsStandard'],
            ]}
            noStyle
          >
            {() => {
              const paramString = form.getFieldValue(['resourceParamsList', field.name, 'param']);
              const paramValue = paramString && JSON.parse(paramString.value);
              const disabled = paramValue?.paramsType === ParmTypeEnum.text;
              const standard = form.getFieldValue([
                'resourceParamsList',
                field.name,
                'paramsStandard',
              ]);

              return (
                <Form.Item
                  name={[field.name, 'paramsUpperLimit']}
                  getValueFromEvent={getNumberFromInput}
                  style={{ marginBottom: 0 }}
                  dependencies={[['resourceParamsList', field.name, 'paramsStandard']]}
                  rules={_.compact([
                    { validator: numberLengthCheck(9, 6) },
                    !_.isNil(standard)
                      ? {
                          validator: numberMinMaxCheck({
                            min: standard,
                            minAllowEqual: false,
                            message: '参数上限必须大于标准值',
                          }),
                        }
                      : undefined,
                  ])}
                >
                  <Input disabled={disabled} />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '参数下限',
      width: 120,
      render: (text, field) => {
        return (
          <Form.Item
            dependencies={[
              ['resourceParamsList', field.name, 'param'],
              ['resourceParamsList', field.name, 'paramsStandard'],
            ]}
            noStyle
          >
            {() => {
              const paramString = form.getFieldValue(['resourceParamsList', field.name, 'param']);
              const paramValue = paramString && JSON.parse(paramString.value);
              const disabled = paramValue?.paramsType === ParmTypeEnum.text;
              const standard = form.getFieldValue([
                'resourceParamsList',
                field.name,
                'paramsStandard',
              ]);

              return (
                <Form.Item
                  name={[field.name, 'paramsLowerLimit']}
                  getValueFromEvent={getNumberFromInput}
                  style={{ marginBottom: 0 }}
                  dependencies={[['resourceParamsList', field.name, 'paramsStandard']]}
                  rules={_.compact([
                    { validator: numberLengthCheck(9, 6) },
                    !_.isNil(standard)
                      ? {
                          validator: numberMinMaxCheck({
                            max: standard,
                            maxAllowEqual: false,
                            message: '参数下限必须小于标准值',
                          }),
                        }
                      : undefined,
                  ])}
                >
                  <Input disabled={disabled} />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '参数上上限',
      width: 120,
      render: (text, field) => {
        return (
          <Form.Item
            dependencies={[
              ['resourceParamsList', field.name, 'param'],
              ['resourceParamsList', field.name, 'paramsUpperLimit'],
            ]}
            noStyle
          >
            {() => {
              const paramString = form.getFieldValue(['resourceParamsList', field.name, 'param']);
              const paramValue = paramString && JSON.parse(paramString.value);
              const disabled = paramValue?.paramsType === ParmTypeEnum.text;
              const upperLimit = form.getFieldValue([
                'resourceParamsList',
                field.name,
                'paramsUpperLimit',
              ]);

              return (
                <Form.Item
                  name={[field.name, 'paramsTopLimit']}
                  getValueFromEvent={getNumberFromInput}
                  style={{ marginBottom: 0 }}
                  dependencies={[['resourceParamsList', field.name, 'paramsUpperLimit']]}
                  rules={_.compact([
                    { validator: numberLengthCheck(9, 6) },
                    !_.isNil(upperLimit)
                      ? {
                          validator: numberMinMaxCheck({
                            min: upperLimit,
                            minAllowEqual: false,
                            message: '参数上上限必须大于参数上限',
                          }),
                        }
                      : undefined,
                  ])}
                >
                  <Input disabled={disabled} />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '参数下下限',
      width: 120,
      render: (text, field) => {
        return (
          <Form.Item
            dependencies={[
              ['resourceParamsList', field.name, 'param'],
              ['resourceParamsList', field.name, 'paramsLowerLimit'],
            ]}
            noStyle
          >
            {() => {
              const paramString = form.getFieldValue(['resourceParamsList', field.name, 'param']);
              const paramValue = paramString && JSON.parse(paramString.value);
              const disabled = paramValue?.paramsType === ParmTypeEnum.text;
              const lowerLimit = form.getFieldValue([
                'resourceParamsList',
                field.name,
                'paramsLowerLimit',
              ]);

              return (
                <Form.Item
                  name={[field.name, 'paramsFloorLimit']}
                  getValueFromEvent={getNumberFromInput}
                  style={{ marginBottom: 0 }}
                  dependencies={[['resourceParamsList', field.name, 'paramsLowerLimit']]}
                  rules={_.compact([
                    { validator: numberLengthCheck(9, 6) },
                    !_.isNil(lowerLimit)
                      ? {
                          validator: numberMinMaxCheck({
                            max: lowerLimit,
                            maxAllowEqual: false,
                            message: '参数下下限必须小于参数下限',
                          }),
                        }
                      : undefined,
                  ])}
                >
                  <Input disabled={disabled} />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '参数读取API',
      width: 180,
      render: (text, field) => {
        return (
          <Form.Item
            name={[field.name, 'paramsReadApi']}
            style={{ marginBottom: 0 }}
            dependencies={[
              ['resourceParamsList', field.name, 'param'],
              ['resourceParamsList', field.name, 'paramsAutoFlag'],
            ]}
            rules={[
              { max: 255, message: '不可超过255字符' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const paramsAutoFlag = getFieldValue([
                    'resourceParamsList',
                    field.name,
                    'paramsAutoFlag',
                  ]);

                  if (!paramsAutoFlag || value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('自动获取参数值开启时不能为空'));
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
        );
      },
    },
    {
      title: '参数下发API',
      width: 180,
      render: (text, field) => {
        return (
          <Form.Item dependencies={[['resourceParamsList', field.name, 'param']]} noStyle>
            {() => (
              <Form.Item
                name={[field.name, 'paramsDownApi']}
                style={{ marginBottom: 0 }}
                rules={[{ max: 255, message: '不可超过255字符' }]}
              >
                <Input />
              </Form.Item>
            )}
          </Form.Item>
        );
      },
    },
    {
      title: 'API自动获取参数值',
      width: 180,
      render: (text, field) => {
        return (
          <Form.Item
            name={[field.name, 'paramsAutoFlag']}
            style={{ marginBottom: 0 }}
            valuePropName="checked"
          >
            <Switch
              onChange={(paramsAutoFlag: boolean) => {
                if (!paramsAutoFlag) {
                  const params: ResourceParamsListItemType[] =
                    form.getFieldValue('resourceParamsList');

                  form.setFieldsValue({
                    resourceParamsList: params?.map((param, index: number) => {
                      return index === field.name
                        ? {
                            ...param,
                            schedulerJobId: undefined,
                          }
                        : param;
                    }),
                  });
                }
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: '数采规则',
      width: 180,
      render: (text, field) => (
        <Form.Item dependencies={[['resourceParamsList', field.name, 'paramsAutoFlag']]} noStyle>
          {() => {
            const paramsAutoFlag = form.getFieldValue([
              'resourceParamsList',
              field.name,
              'paramsAutoFlag',
            ]);

            return (
              <Form.Item
                name={[field.name, 'schedulerJobId']}
                style={{ marginBottom: 0 }}
                dependencies={[['resourceParamsList', field.name, 'paramsAutoFlag']]}
                getValueProps={(value) => {
                  return { value: paramsAutoFlag ? value : undefined };
                }}
                getValueFromEvent={(value) => {
                  return paramsAutoFlag ? value : undefined;
                }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const paramsAutoFlag = getFieldValue([
                        'resourceParamsList',
                        field.name,
                        'paramsAutoFlag',
                      ]);

                      if (!paramsAutoFlag || value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('自动获取参数值开启时不能为空'));
                    },
                  }),
                ]}
              >
                <SearchSelect
                  placeholder="请选择数采规则"
                  fetchType="resourceDataCollectionRules"
                  disabled={!paramsAutoFlag}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      ),
    },
  ];

  return (
    <>
      <BlSortFormList
        name="resourceParamsList"
        maxCount={MAX_PARAMS_COUNT}
        listRules={[
          {
            validator: async (_param, names) => {
              if (names?.length > MAX_PARAMS_COUNT) {
                return Promise.reject(new Error(`最多只能选择${MAX_PARAMS_COUNT}个参数`));
              }
              return Promise.resolve();
            },
          },
        ]}
        isNeedDrag={false}
        renderColumns={getParamColumns}
        extraButton={[
          {
            title: '批量添加',
            onClick: () => {
              setParamSelectorVisible(true);
            },
          },
        ]}
        form={form}
      />
      <BatchParamSelector
        visible={paramSelectorVisible}
        onCancel={() => {
          setParamSelectorVisible(false);
        }}
        onFinish={handleSelectParamFinish}
        maxCount={100}
        originCount={form?.getFieldValue('resourceParamsList')?.length}
      />
    </>
  );
}
