import {
  FetchProduceTaskDetailResponse,
  FetchProduceTaskUpdateRequest,
} from 'src/api/ytt/mfg-domain/productionTask';
import { ProductionTaskFormData } from 'src/page/productionPlanning/productionTask/types/edit.type';
import _Time from 'src/utils/dataTypes/time';
import { FetchWorkOrderGetResourceGroupsForProcessResponse } from 'src/api/ytt/med-domain/work_order';
import {
  ResourceGroupTypeEnum,
  UserResourceGroupBizTypeEnum,
} from 'src/page/productionPlanning/productionTask/helper/constants';
import { TASK_TYPE_TYPE } from 'src/page/productionPlanning/productionTask/constant';

export const formatResponseToFormData = (
  res: FetchProduceTaskDetailResponse['data'],
  res2?: NonNullable<
    FetchWorkOrderGetResourceGroupsForProcessResponse['data']
  >['workCenterGroupResponseList'],
): ProductionTaskFormData => {
  const otherResources: ProductionTaskFormData['otherResources'] = [];

  res?.otherResources?.forEach((item) => {
    let resourceIdsScope;
    let bizType;
    const matchedResource = res2?.find((item2) => item2.id === item.groupId);

    if (item.groupType === ResourceGroupTypeEnum.user) {
      if (matchedResource?.bizType === UserResourceGroupBizTypeEnum.user) {
        resourceIdsScope = matchedResource?.userList?.map(({ id, name }) => ({
          label: name || '',
          value: id || 0,
        }));
      } else {
        resourceIdsScope = matchedResource?.departmentList?.map(({ id, name }) => ({
          label: name || '',
          value: id || 0,
        }));
      }
      bizType = matchedResource?.bizType;
    } else if (item.groupType === ResourceGroupTypeEnum.department) {
      resourceIdsScope = matchedResource?.departmentList?.map(({ id, name }) => ({
        label: name || '',
        value: id || 0,
      }));
    } else {
      resourceIdsScope = matchedResource?.resourceList?.map(({ id, name }) => ({
        label: name || '',
        value: id || 0,
      }));
    }
    otherResources.push({
      resourceGroupId: item.groupId || 0,
      groupType: item.groupType || 0,
      groupName: item.groupName || '',
      resourceIds:
        item?.resourceList?.map((item) => ({ label: item.name || '', value: item.id || 0 })) || [],
      id: item.id || 0,
      resourceIdsScope,
      bizType,
    });
  });

  const resTaskStatus = res?.taskStatus;

  return {
    taskCode: res?.taskCode || '',
    taskStatus: (resTaskStatus && TASK_TYPE_TYPE.get(resTaskStatus)) || '',
    materialCode: res?.taskOutputMaterialList[0]?.baseInfo?.code || '',
    materialName: res?.taskOutputMaterialList[0]?.baseInfo?.name || '',
    relatedProcessCode: res?.relatedProcessCode,
    relatedProcessName: res?.relatedProcessName,
    workCenter: res?.workCenterVO?.name,
    taskIdentifyCode: res?.taskIdentifyCode || '',
    executorIds: res?.executors?.map(({ name, id }) => ({ label: name, value: id })) || [],
    departmentIds:
      res?.departments?.map(({ name, id }) => ({ label: name || '', value: id || 0 })) || [],
    equipmentIds:
      res?.equipments?.map(({ name, id }) => ({ label: name || '', value: id || 0 })) || [],
    planStartTime: _Time.formatUnixMoment(res?.planStartTime || 0),
    planEndTime: _Time.formatUnixMoment(res?.planEndTime || 0),
    remark: res?.remark,
    otherResources,
  };
};

export const formatFormDataToSubmitData = (
  formData: ProductionTaskFormData,
  options: { taskId: number },
): FetchProduceTaskUpdateRequest => {
  return {
    taskId: options.taskId,
    taskCode: formData.taskCode,
    taskIdentifyCode: formData.taskIdentifyCode,
    executorIds: formData.executorIds.map((item) => item.value),
    departmentIds: formData.departmentIds.map((item) => item.value),
    equipmentIds: formData.equipmentIds.map((item) => item.value),
    planStartTime: formData.planStartTime.valueOf(),
    planEndTime: formData.planEndTime.valueOf(),
    remark: formData.remark,
    otherResources: formData?.otherResources?.map((item) => ({
      resourceGroupId: item.resourceGroupId,
      groupType: item.groupType,
      resourceIds: item.resourceIds.map((item) => item.value),
      id: item?.id,
    })),
  };
};
