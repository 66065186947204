import React, { useState } from 'react';
import { Space, Badge, Form, Popover } from 'antd';
import { useHistory } from 'react-router-dom';
import { FilterItem, RecordListLayout } from 'layout';
import { QrCode, BlIcon, Tooltip, SingleOperationLogComponent, SearchSelect } from 'components';
import {
  BIZ_TYPE,
  QUALITY_ENUM,
  LOCK_STATUS,
  QUALITY,
  BIZ_TYPE_ENUM,
  LOCK_STATUS_ENUM,
  STORAGE_LOCATION_TYPE_ENUM,
  STORAGE_LOCATION_TYPE,
  FIELD_MAP,
  STATUS_ENUM,
  STORE_ENUM_TYPE,
} from '../constant';
import { replaceSign } from 'src/utils/constants';
import { fieldTypeList } from 'utils';
import { isUndefined, map } from 'lodash';
import { patchRequest } from '../utils';
import BLDelConfirm from '../../share/modalConfirm';
import { toStoreCreate, toStoreDetail, toStoreEdit } from '../navigation';
import _Array from 'src/utils/dataTypes/array';
import { LabelType, StoreType } from '../interface';
import StoreDetail from './detail';
import {
  fetchStorageWarehouseBulkDelete,
  fetchStorageWarehouseBulkDisable,
  fetchStorageWarehouseBulkEnable,
  fetchStorageWarehouseBulkLock,
  fetchStorageWarehouseBulkUnlock,
  fetchStorageWarehousePageList,
} from 'src/api/ytt/storage-domain/warehouse';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { intersectionValueOfId } from 'src/utils/array';

const StoreHouseList = () => {
  const history = useHistory();
  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新页面
  const [visibleDetail, setVisibleDetail] = useState(false);
  const [storeId, setStoreId] = useState<any>();
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const [delForm] = Form.useForm();

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  // const handleDelete = async (onSuccess?: () => void, onFail?: () => void, id?: number) => {
  //   try {
  //     // const value = await delForm?.validateFields();
  //     // TODO：后面补充删除的原因

  //     const delId = id ? [id] : selectedRowKeys;

  //     const { data } = await fetchStorageWarehouseBulkDelete({ ids: delId });

  //     if (_Array.isEmpty(data)) {
  //       // 为空表面删除成功 有数据代表删除失败
  //       typeof onSuccess === 'function' && onSuccess?.();
  //       Message.success('删除成功');
  //       return;
  //     }
  //     // 删除失败

  //     typeof onFail === 'function' && onFail?.();

  //     Message.error(data?.errorDesc);
  //   } catch (error) {
  //     onFail?.();
  //     console.log(error);
  //   }
  // };

  const hasAuthRes = hasAuth(authDict.warehouse_detail);

  const dataColumns = [
    {
      title: '仓库名称',
      dataIndex: 'name',
      width: 150,
      sorter: true,
      fixed: 'left',
      render: (name: string, record: StoreType, index: number, config: any) => {
        return (
          <>
            {hasAuthRes ? (
              <a
                onClick={() => {
                  history.push(toStoreDetail(record?.id));
                }}
              >
                <Tooltip text={name ?? replaceSign} width={config.contentWidth} />
              </a>
            ) : (
              <Tooltip text={name ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },
    {
      title: '仓库编号',
      dataIndex: 'code',
      width: 150,
      sorter: true,
      render: (code: string, record: StoreType, index: number, config: any) => {
        return (
          <div>
            <Space style={{ position: 'relative' }}>
              {hasAuthRes ? (
                <a
                  onClick={() => {
                    history.push(toStoreDetail(record?.id));
                  }}
                >
                  <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
                </a>
              ) : (
                <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
              )}
            </Space>
            <Space style={{ position: 'absolute', right: '10px' }}>
              <Popover
                placement="rightTop"
                content={
                  <div>
                    <QrCode value={record?.qrCode ?? code} />
                    <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                      <Tooltip text={record?.qrCode ?? code} width={config.contentWidth} />
                    </div>
                  </div>
                }
              >
                <BlIcon type="iconerweima" />
              </Popover>
            </Space>
          </div>
        );
      },
    },
    {
      title: '仓库类型',
      dataIndex: 'bizType',
      width: 150,
      sorter: true,
      isFilter: true,
      render: (bizType: number) => BIZ_TYPE.get(bizType) || replaceSign,
    },
    {
      title: '使用状态',
      dataIndex: 'enableFlag',
      width: 150,
      sorter: true,
      isFilter: true,
      render: (enableFlag: number) => {
        const useStatusMap = new Map([
          [STATUS_ENUM.ENABLED, <Badge text="启用中" status="success" />],
          [STATUS_ENUM.DISABLED, <Badge text="停用中" status="error" />],
        ]);

        return useStatusMap.get(enableFlag);
      },
    },
    {
      title: '锁定状态',
      dataIndex: 'isLock',
      isFilter: true,
      sorter: true,
      width: 150,
      render: (isLock: number) => <span>{LOCK_STATUS.get(isLock) || replaceSign}</span>,
    },
    {
      title: '仓位管理',
      dataIndex: 'enableStorageLocation',
      isFilter: true,
      width: 150,
      render: (enableStorageLocation: number) => STORAGE_LOCATION_TYPE.get(enableStorageLocation),
    },
    {
      title: '质量管理',
      dataIndex: 'qcCtrlList',
      isFilter: true,
      width: 200,
      render: (qcCtrlList: [], record: StoreType) => {
        if (record?.enableQcCtrl === STATUS_ENUM.DISABLED) return '无限制';
        return <span>{qcCtrlList?.map((node: number) => QUALITY_ENUM.get(node)).join('、')}</span>;
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      type: 'string',
      width: 150,
      render: (remark: string) => remark ?? replaceSign,
    },
  ];

  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      icon: <BlIcon type="icondaochu" />,
      auth: authDict.warehouse_export,
      onClick: () => {
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.storageWarehouse,
        });
      },
    },
    {
      title: '新建仓库',
      auth: authDict.warehouse_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        history.push(toStoreCreate());
      },
      items: [
        {
          title: '导入',
          showExport: true,
          auth: authDict.warehouse_import,
          onClick: () => {
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.storageWarehouse,
            });
          },
        },
      ],
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '仓库名称',
      name: 'name',
      type: fieldTypeList?.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'warehouse'}
          labelInValue
          labelPath="name"
          valuePath="id"
          mode="multiple"
        />
      ),
    },
    {
      label: '仓库编号',
      name: 'code',
      type: fieldTypeList?.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'warehouse'}
          labelInValue
          labelPath="code"
          valuePath="id"
          mode="multiple"
        />
      ),
    },
    {
      label: '仓库类型',
      name: 'bizType',
      type: fieldTypeList?.select,
      selectProps: {
        options: BIZ_TYPE_ENUM.map((node: LabelType) => {
          return {
            label: node?.name,
            value: node?.id,
          };
        }),
      },
    },
    {
      label: '使用状态',
      name: 'enableFlag',
      type: fieldTypeList?.select,
      selectProps: {
        options: STORE_ENUM_TYPE.map((node: LabelType) => {
          return {
            label: node.name,
            value: node.id,
          };
        }),
      },
    },
    {
      label: '锁定状态',
      name: 'isLock',
      type: fieldTypeList?.select,
      selectProps: {
        options: LOCK_STATUS_ENUM.map((node: LabelType) => {
          return {
            label: node.name,
            value: node.id,
          };
        }),
      },
    },
    {
      label: '仓位管理',
      name: 'enableStorageLocation',
      type: fieldTypeList?.select,
      selectProps: {
        options: STORAGE_LOCATION_TYPE_ENUM.map((node: LabelType) => {
          return {
            label: node.name,
            value: node.id,
          };
        }),
      },
    },
    {
      label: '质量管理',
      name: 'qcStatus',
      type: fieldTypeList?.select,
      selectProps: {
        options: QUALITY?.map((node: LabelType) => {
          return {
            label: node.name,
            value: node.id,
          };
        }),
      },
    },
  ];

  const getOperationList = (record: StoreType) => {
    const { enableFlag, isLock } = record;

    return [
      {
        title: '查看',
        auth: authDict.warehouse_detail,
        onClick: () => {
          history.push(toStoreDetail(record?.id));
        },
      },
      {
        title: '编辑',
        auth: authDict.warehouse_edit,
        onClick: () => history.push(toStoreEdit(record?.id)),
      },
      {
        title: enableFlag ? '停用' : '启用',
        auth: authDict.warehouse_enable_disable,
        onClick: async () => {
          const storeApi = record?.enableFlag
            ? fetchStorageWarehouseBulkDisable
            : fetchStorageWarehouseBulkEnable;
          const storeDisplay = record?.enableFlag ? '停用' : '启用';

          patchRequest('single', storeApi, [record?.id], storeDisplay, refresh);
        },
      },
      {
        title: isLock ? '解锁' : '锁定',
        auth: authDict.warehouse_lock_unlock,
        onClick: async () => {
          const lockApi = record?.isLock
            ? fetchStorageWarehouseBulkUnlock
            : fetchStorageWarehouseBulkLock;
          const lockDisplay = record?.isLock ? '解锁' : '锁定';

          patchRequest('single', lockApi, [record?.id], lockDisplay, refresh);
        },
      },
      {
        title: '操作记录',
        auth: authDict.warehouse_operrecord,
        onClick: () => {
          setStoreId(record?.id);
          setVisibleLog(true);
        },
      },
    ];
  };

  const batchMenu = [
    {
      title: '启用',
      auth: authDict.warehouse_enable_disable,
      icon: <BlIcon type="iconqiyong" />,
      onClick: (onSuccess: any, onFail: any) => {
        patchRequest(
          'patch',
          fetchStorageWarehouseBulkEnable,
          selectedRowKeys,
          '启用',
          onSuccess,
          onFail,
        );
      },
    },
    {
      title: '停用',
      auth: authDict.warehouse_enable_disable,
      icon: <BlIcon type="icontingyong" />,
      onClick: (onSuccess: any, onFail: any) => {
        patchRequest(
          'patch',
          fetchStorageWarehouseBulkDisable,
          selectedRowKeys,
          '停用',
          onSuccess,
          onFail,
        );
      },
    },
    // {
    //   title: '删除',
    //   icon: <BlIcon type="iconshanchulajitong1" />,
    //   onClick: (onSuccess: any, onFail: any) => {
    //     BLDelConfirm(
    //       '是否删除当前仓库及仓库下所有仓位',
    //       delForm,
    //       () => {
    //         handleDelete(onSuccess, onFail);
    //       },
    //       () => {
    //         onFail();
    //       },
    //     );
    //   },
    // },
    {
      title: '锁定',
      auth: authDict.warehouse_lock_unlock,
      icon: <BlIcon type="iconmima" />,
      onClick: (onSuccess: any, onFail: any) => {
        patchRequest(
          'patch',
          fetchStorageWarehouseBulkLock,
          selectedRowKeys,
          '锁定',
          onSuccess,
          onFail,
        );
      },
    },
    {
      title: '解锁',
      auth: authDict.warehouse_lock_unlock,
      icon: <BlIcon type="icona-mimajiesuo" />,
      onClick: (onSuccess: any, onFail: any) => {
        patchRequest(
          'patch',
          fetchStorageWarehouseBulkUnlock,
          selectedRowKeys,
          '解锁',
          onSuccess,
          onFail,
        );
      },
    },
  ];
  const formatDataToQuery = (params: any) => {
    const { sorter, code, name, enableFlag, enableStorageLocation, isLock, bizType, ...rest } =
      params;

    const relParams = { ...rest };

    if (!isUndefined(bizType)) {
      relParams.bizType = bizType;
    }
    if (!isUndefined(enableFlag)) {
      relParams.enableFlag = enableFlag;
    }
    if (!isUndefined(enableStorageLocation)) {
      relParams.enableStorageLocation = enableStorageLocation;
    }
    if (!isUndefined(isLock)) {
      relParams.isLock = isLock;
    }

    if (!_Array.isEmpty(code) || !_Array.isEmpty(name)) {
      relParams.idList = intersectionValueOfId(code, name);
    }

    if (sorter) {
      relParams.sorter = map(sorter, (node: any) => {
        return {
          order: node?.order,
          field: FIELD_MAP.get(node?.field),
        };
      });
    }

    dispatch.excelImport.updateBusinessData({ businessData: relParams });

    return relParams;
  };

  return (
    <>
      <RecordListLayout<StoreType>
        columns={dataColumns}
        rowKey={(record: StoreType) => String(record.id)}
        userAuth={getAuthFromLocalStorage()}
        selectedRowKeys={selectedRowKeys}
        configcacheKey="storehouse"
        placeholder={'输入仓库名称、仓库编号'}
        onSelectedRowKeys={(selectKey: any) => {
          setSelectRowKeys(selectKey);
        }}
        requestFn={fetchStorageWarehousePageList}
        refreshMarker={refreshMarker}
        formatDataToQuery={formatDataToQuery}
        mainMenu={mainMenu}
        filterList={filterList}
        batchMenu={batchMenu}
        getOperationList={getOperationList}
      />
      {visibleDetail && (
        <StoreDetail
          visible={visibleDetail}
          closeDetail={() => {
            setVisibleDetail(false);
          }}
          storeId={storeId}
          refreshTable={refresh}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={storeId}
          objectCode={'Warehouse'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default StoreHouseList;
