import { message as Message } from 'antd';

import {
  fetchSalesOrderReturnFinish,
  fetchSalesOrderReturnFinishCheck,
  fetchSalesOrderReturnDetail,
} from 'src/api/ytt/order-domain/salesReturnOrder';

export const finishCheckService = async (id: number) => {
  const response = await fetchSalesOrderReturnFinishCheck({ id });

  if (response.code === 200 && response.data) {
    return response.data;
  }
  Message.error(response.message);
  return null;
};

export const finishSalesReturnOrderService = async (id: number) => {
  const response = await fetchSalesOrderReturnFinish({ id });

  if (response?.code === 200) {
    Message.success('销售退货单结束成功');
  } else {
    Message.error(response.message);
  }
};

export const loadSalesReturnOrderDetailService = async (id: number) => {
  const response = await fetchSalesOrderReturnDetail({ id });

  if (response?.code === 200) {
    return response.data;
  }
  Message.error(response.message);
  return null;
};
