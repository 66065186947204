/**
 * @description 电子签名枚举类
 * @author xuzhiyong
 */

/** 出库类型 */
export enum OUT_EXECUTE_TYPE {
  /** 普通出库 */
  outBound = 0,
  /** 生产出库 */
  mfgOut,
  /** 出库冲销 */
  chargeAgainst,
  /** 按单出库 */
  order,
  /** 其他 */
  other = 9,
}

/** 入库类型 */
export enum IN_EXECUTE_TYPE {
  /** 按单入库 */
  order = 1,
  /** 普通入库 */
  inBound,
  /** 生产入库 */
  mfgIn,
  /** 冲销入库 */
  chargeAgainst,
  /** 跨厂入库 */
  straddle,
}

/** 业务类型 */
export enum BIZ_TYPE {
  /** 其他 */
  other = 0,
  /** 采购收货 */
  purchaseReceiving,
  /** 销售退货 */
  saleRevert,
  /** 生产入库 */
  productionWarehousing,
}

/** 来源类型 */
export enum SOURCE_TYPE {
  /** 无源出库 */
  emptySource = 0,
  /** 协同发货单 */
  invoice,
  /** 协同采购退货单 */
  purchaseReturnOrder,
}

/** 收货单来源类型 */
export enum IN_SOURCE_TYPE {
  /** 无源入库 */
  emptySource = 0,
  /** 协同收货单 */
  receipt,
  /** 协同销售退货单 */
  saleReturnOrder,
}

/** 入库记录业务类型 */
export enum RECORD_IN_EXECUTE_TYPE {
  /** 按单入库 */
  order = 1,
  /** 普通入库 */
  inBound,
  /** 生产报工 */
  report,
  /** 冲销入库 */
  chargeAgainst,
  /** 跨厂入库 */
  straddle,
  /** 报工调整 */
  reportAdjustment,
}

/** 出库记录业务类型 */
export enum RECORD_OUT_EXECUTE_TYPE {
  /** 普通出库 */
  outBound = 0,
  /** 生产投料 */
  feeding,
  /** 出库冲销 */
  chargeAgainst,
  /** 按单出库 */
  order,
  /** 投料撤回 */
  withdraw,
  /** 跨厂出库 */
  // crossPlant,
  /** 其他 */
  // other = 9,
}

/** 调拨记录业务类型 */
export enum RECORD_COMMIT_EXECUTE_TYPE {
  /** 按单调拨 */
  order = 0,
  /** 普通调拨 */
  common,
}

/** 库存调整记录业务类型 */
export enum RECORD_ADJUST_EXECUTE_TYPE {
  /** 库存调整 */
  adjust = 1,
  /** 质检报废 */
  scrap,
}

/** 属性变更记录业务类型 */
export enum RECORD_PROPERTY_EXECUTE_TYPE {
  /** 属性变更 */
  propertyChange = 1,
  /** 标识码绑定 */
  bind,
  /** 标识码解绑 */
  unbind,
  /** 标识码拆分 */
  split,
  /** 质量变更 */
  change,
}
