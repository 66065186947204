import React, { useEffect, useState } from 'react';
import { Badge, Space } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import BaseTable from './component/detailBaseTable';
import { BlIcon, SingleOperationLogComponent, Tooltip } from 'components';
import { replaceSign } from 'src/utils/constants';
import { NUMBER_RULES_STATUS_ENUM } from './constant';
import { SuitObjectData } from './interface';
import { RouteComponentProps, match } from 'react-router-dom';
import { toNumberEdit } from './navigation';
import {
  fetchNumberRuleDetail,
  fetchNumberRuleDisable,
  fetchNumberRuleEnable,
} from 'src/api/ytt/custom-domain';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import _Time from 'src/utils/dataTypes/time';
import { STATUS_ENUM } from '../knowledgeManagement/warehouse/constant';
import { formatDemo, formatDetailElementFields } from './utils';
import { patchRequest } from '../knowledgeManagement/warehouse/utils';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';

interface NumberRulesDetailProps extends RouteComponentProps {
  match: match<{ id: string }>;
  location: any;
  history: any;
}

const NumberRulesDetailPage = (props: NumberRulesDetailProps) => {
  const { match, location, history } = props;
  const { formatConfigData, suitObjectData } = location?.state || {};
  const numberRuleId = Number(match?.params?.id);

  const [detail, setDetail] = useState({} as any);
  const [demo, setDemo] = useState('');
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录

  const fetchData = async () => {
    try {
      const { data } = await fetchNumberRuleDetail({ id: numberRuleId }, { legacy: true });

      if (data) {
        const newElementList = formatDetailElementFields(data?.elementList, 'detail');

        const demoStr = formatDemo(newElementList, formatConfigData);

        setDemo(demoStr);

        setDetail({ ...data, elementList: newElementList });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const baseMenu = [
    {
      key: 'enable',
      auth: authDict.numberingrule_enable_disable,
      onClick: async () => {
        const numberRuleApi = detail?.enableFlag ? fetchNumberRuleDisable : fetchNumberRuleEnable;
        const numberRuleDisplay = detail?.enableFlag ? '停用' : '启用';

        patchRequest('single', numberRuleApi, [numberRuleId], numberRuleDisplay, fetchData);
      },
      icon:
        detail?.enableFlag === STATUS_ENUM?.ENABLED ? (
          <BlIcon type="icontingyong" />
        ) : (
          <BlIcon type="iconqiyong" />
        ),
      title: detail?.enableFlag ? '停用' : '启用',
    },
    {
      key: 'record',
      auth: authDict.numberingrule_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'edit',
      auth: authDict.numberingrule_edit,
      onClick: () => {
        history.push({
          pathname: toNumberEdit(numberRuleId),
          state: { suitObjectData, formatConfigData },
        });
      },
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ];

  const detailInfo: DetailLayoutInfoBlock = {
    title: '·基本信息',
    items: [
      {
        label: '规则编号',
        dataIndex: 'ruleCode',
        render: (ruleCode) => ruleCode || replaceSign,
      },
      {
        label: '规则名称',
        dataIndex: 'ruleName',
        render: (ruleName) => ruleName || replaceSign,
      },
      {
        label: '适用对象',
        dataIndex: 'suitObj',
        render: (suitObj: SuitObjectData) => suitObj?.objectName ?? replaceSign,
      },
      {
        label: '状态',
        dataIndex: 'enableFlag',
        isFullLine: true,
        render: (enableFlag: number) => {
          const statusMap = new Map([
            [NUMBER_RULES_STATUS_ENUM.ENABLED, <Badge text="启用中" status="success" />],
            [NUMBER_RULES_STATUS_ENUM.DISABLED, <Badge text="已停用" status="error" />],
          ]);

          return statusMap.get(enableFlag);
        },
      },
      {
        label: '',
        dataIndex: 'sample',
        isFullLine: true,
        render: () => {
          return (
            <div
              style={{
                height: 70,
                backgroundColor: '#FAFAFA',
                padding: 14,
                marginLeft: 20,
              }}
            >
              <div>编号示例</div>
              <div style={{ fontWeight: 700 }}>
                <Tooltip text={demo ?? replaceSign} width={560} />
              </div>
            </div>
          );
        },
      },
      {
        label: '',
        dataIndex: 'table',
        isFullLine: true,
        render: () => {
          return (
            <div
              style={{
                marginLeft: 20,
              }}
            >
              <BaseTable dataSource={detail?.elementList} formatConfigData={formatConfigData} />
            </div>
          );
        },
      },
      {
        label: '备注',
        dataIndex: 'ruleRemark',
        isFullLine: true,
        render: (ruleRemark) => ruleRemark || replaceSign,
      },
    ],
    column: 2,
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: any) => {
          return (
            <Space>
              <AvatarDisplay
                id={creator?.id}
                name={creator?.name}
                avatarUrl={creator?.avatarUrl}
                key={creator?.id}
                isShowTag={false}
                isUser
              />
            </Space>
          );
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        isFullLine: true,
        render: (createdAt: Date) => _Time.format(createdAt) ?? replaceSign,
      },

      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: any) => {
          return (
            <Space>
              <AvatarDisplay
                id={operator?.id}
                name={operator?.name}
                avatarUrl={operator?.avatarUrl}
                key={operator?.id}
                isShowTag={false}
                isUser
              />
            </Space>
          );
        },
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        isFullLine: true,
        render: (updatedAt: Date, record: any) =>
          _Time.format(updatedAt) ?? _Time.format(record?.createdAt) ?? replaceSign,
      },
    ],
    column: 2,
  };

  return (
    <>
      <DetailLayout
        userAuth={getAuthFromLocalStorage()}
        title="编号规则详情"
        baseMenu={baseMenu}
        info={[detailInfo, otherInfo]}
        dataSource={detail}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={numberRuleId}
          objectCode={'NumberingRule'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default NumberRulesDetailPage;
