/**
 *  文件详情预览弹窗
 */
import { useEffect, useState } from 'react';
import { Modal, Form, message, Descriptions } from 'antd';
import _ from 'lodash';
import ImageView from './imageView';
import PdfView from './pdfView';
import { fetchFileDetail, fetchFileBindDep } from 'src/api/ytt/holyfile-domain';
// import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { bytesFormatter } from 'utils';
import moment from 'moment';
import { formatterUrl } from '../../share';
import { File } from 'src/components/upload/BcAttachment';

interface Props {
  visible: boolean;
  fileId: any; // 通过id，拿到文件详情
  fileList: Array<File>;
  onCancel?: () => void;
}

const Preview = (props: Props) => {
  const { fileId, fileList, ...antdMoadlProps } = props;
  const [editStatus, setEditStatus] = useState(false);
  const [fileInfo, setFileInfo] = useState<File>({} as File);
  const [currentFileId, setCurrentFileId] = useState<number>(fileId);
  const [form] = Form.useForm();

  const fetchData = async (id: any) => {
    const res = await fetchFileDetail({ id });

    if (res && res.code === 200) {
      setFileInfo({
        ...res.data,
        filename: decodeURIComponent(res.data?.filename || ''),
        fileUri: formatterUrl(res.data?.fileUri),
        type: res.data?.contentType,
      });
      form.setFieldsValue({
        visibleDepList: res.data?.visibleDepartments?.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      });
    }
  };

  // const submit = async () => {
  //   const values = await form.validateFields();
  //   const res = await fetchFileBindDep({
  //     visibleDepList: _.map(values.visibleDepList, 'value'),
  //     fileIds: [detail.id],
  //   });

  //   if (res && res.code === 200) {
  //     message.success('设置成功');
  //     fetchData(detail.id);
  //     setEditStatus(false);
  //   }
  // };

  const formatTimastampToDateString = (time: number) => {
    if (!time) {
      return '-';
    }

    return moment(new Date(time)).format('YYYY/MM/DD HH:mm:ss');
  };

  useEffect(() => {
    if (currentFileId) {
      fetchData(currentFileId);
    }
  }, [fileId, currentFileId]);

  const renderView = (file: any) => {
    const currentIndex = _.findIndex(fileList, (i) => i?.id === fileInfo?.id);
    const toNext =
      currentIndex < fileList?.length - 1
        ? () => setCurrentFileId(Number(fileList[currentIndex + 1]?.id))
        : undefined;
    const toPrve =
      currentIndex > 0 ? () => setCurrentFileId(Number(fileList[currentIndex - 1]?.id)) : undefined;

    if (file?.type === 'application/pdf') return <PdfView style={{ flex: 1 }} file={file} />;

    return (
      <ImageView
        style={{ flex: 1 }}
        file={file}
        toNext={toNext}
        toPrve={toPrve}
        pageText={`${currentIndex + 1}/${fileList?.length}`}
      />
    );
  };

  return (
    <Modal
      getContainer={false}
      className="preview-file-modal"
      title={fileInfo?.name || '预览文件'}
      style={{ minWidth: '100vw' }}
      footer={false}
      bodyStyle={{ height: '80vh' }}
      {...antdMoadlProps}
    >
      <div style={{ display: 'flex', height: '100%' }}>
        {renderView(fileInfo)}
        <Descriptions title="" column={1} style={{ padding: '0 16px', width: 300 }}>
          <Descriptions.Item label="文件名称">{fileInfo?.filename}</Descriptions.Item>
          <Descriptions.Item label="文件大小">{bytesFormatter(fileInfo?.size)}</Descriptions.Item>
          <Descriptions.Item label="上传人：">{fileInfo?.creator?.name}</Descriptions.Item>
          <Descriptions.Item label="上传时间">
            {formatTimastampToDateString(Number(fileInfo?.createdAt))}
          </Descriptions.Item>
        </Descriptions>
        {/* <div className="item" style={{ display: 'flex' }}>
            <label>可见部门：</label>
            {editStatus ? (
              <Form style={{ flex: 1 }} form={form}>
                <Form.Item
                  rules={[{ required: true, message: '请选择部门' }]}
                  name="visibleDepList"
                >
                  <UserOrDepartmentSelectWithDialog isMultiple style={{ width: '100%' }} />
                </Form.Item>
                <Space>
                  <Button type={'primary'} onClick={submit}>
                    保存
                  </Button>
                  <Button onClick={() => setEditStatus(false)}>取消</Button>
                </Space>
              </Form>
            ) : (
              <div>
                {fileInfo.visibleDepartments?.map((item: any) => (
                  <span>{item.name} </span>
                ))}
                <a onClick={() => setEditStatus(true)}>
                  <BlIcon type="iconbianji" />
                </a>
              </div>
            )}
          </div> */}
      </div>
    </Modal>
  );
};

export default Preview;
