import React, { useMemo, useState } from 'react';
import { BlIcon, BlResizableContent, BlTreeComponent } from 'components';
import { DataNode } from 'rc-tree/lib/interface';
import _ from 'lodash';
import styles from './styles.module.scss';

export type DetailContent = (treeNode?: any) => React.ReactNode | null | undefined;
export type TreeRender = React.ReactNode | null | undefined;

export type UseTreeContent = (props: {
  treeData?: DataNode[];
  treeRender?: TreeRender;
  updateTreeData?: boolean;
  detailContent?: DetailContent;
  onSelect?: any;
  onCheck?: any;
  resizableBoxStyle?: {};
  overflowAble?: boolean;
  resizableProp?: { [prop: string]: any }; // treeResizable属性透传
  [prop: string]: any; // tree 属性透传
}) => React.ReactNode;

const useTreeContent: UseTreeContent = ({
  treeData,
  treeRender,
  detailContent,
  onSelect,
  onCheck,
  resizableProp,
  resizableBoxStyle,
  updateTreeData = false,
  isSearch = true,
  overflowAble = false,
  ...restProps
}) => {
  const [treeNode, setTreeNode] = useState<any>(null);
  const [isExpand, setIsExpand] = useState<boolean>(true);

  const [maxHeight] = useState<number>(document.body.clientHeight - 200); // tree可滚动高度 默认值

  const contentRender = useMemo(
    () =>
      treeRender ? (
        treeRender
      ) : (
        <BlTreeComponent
          onSelect={async (nodeKey, e) => {
            const { node, currentTree } = e;
            _.isFunction(onSelect) && (await onSelect(node, currentTree));
            setTreeNode(node);
          }}
          height={overflowAble ? undefined : maxHeight}
          onCheck={(...args) => _.isFunction(onCheck) && onCheck(...args)}
          isSearch={isSearch}
          checkable
          overflowAble={overflowAble}
          updateTreeData={updateTreeData}
          treeData={treeData}
          {...restProps}
        />
      ),
    [maxHeight, onCheck, onSelect, restProps, treeData, treeRender],
  );

  const treeContent = useMemo(() => {
    return (
      <>
        <div
          style={{ display: 'flex', height: '100%', ...resizableBoxStyle }}
          className="treeContent"
        >
          <BlResizableContent
            contentRender={() => contentRender}
            height={500}
            isChangeHeight
            isChangeWidth
            width={300}
            style={{
              backgroundColor: '#fafafa',
              borderRight: '1px solid #D9D9D9',
              display: isExpand ? 'block' : 'none',
            }}
            {...resizableProp}
          />
          <div
            style={{ flex: 1, width: 0, position: 'relative' }}
            className={styles?.detailContent}
          >
            {detailContent ? detailContent(treeNode) : null}
            <div
              className={styles?.collapse}
              onClick={() => {
                setIsExpand(!isExpand);
              }}
            >
              {isExpand ? (
                <span className={styles?.caretLeft}>
                  <BlIcon type="iconxiang-zuo" />
                </span>
              ) : (
                <span className={styles?.caretLeft}>
                  <BlIcon type="iconxiang-you" />
                </span>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }, [contentRender, detailContent, isExpand, resizableBoxStyle, resizableProp, treeNode]);

  return treeContent;
};

export default useTreeContent;
