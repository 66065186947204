import { BlTable } from 'src/components';
import { Button, Space } from 'antd';
import { ColumnProps } from 'antd/es/table';
import {
  DefaultPaginationType,
  defaultPagination,
  ParentAndSubNodeItemType,
  ParentAndSubNodeType,
} from '../interface';
import { FC, useEffect, useState } from 'react';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';

import { fetchTraceTraceRelationshipInventoryListRelationships } from 'src/api/ytt/trace-domain/traceRelationship';
import { toTraceNodeChart, toTraceNodeDetail } from 'src/page/trace';
import { useHistory } from 'react-router-dom';

interface SubItemMaterialTabProps {
  traceId?: number;
}

const ParentNodeTab: FC<SubItemMaterialTabProps> = ({ traceId }) => {
  const [pagination, setPagination] = useState<DefaultPaginationType>(defaultPagination);

  const [dataSource, setDataSource] = useState<ParentAndSubNodeType>();

  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const fetchParentData = async (params: any) => {
    try {
      if (traceId) {
        setLoading(true);
        const { data } = await fetchTraceTraceRelationshipInventoryListRelationships(
          { traceId, page: params?.current ?? 1, size: params?.pageSize ?? 10 },
          { legacy: false },
        );

        if (data) {
          setDataSource(data);
          setPagination({
            ...pagination,
            current: params?.current ?? 1,
            pageSize: params?.pageSize ?? 10,
            total: data?.total ?? 0,
          });
        }
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchParentData(defaultPagination);
  }, [traceId]);

  const parentColumns: ColumnProps<ParentAndSubNodeItemType>[] = [
    {
      title: '序号',
      dataIndex: 'seq',
      width: 100,
      render: (_: any, record: ParentAndSubNodeItemType, index: number) => index + 1,
    },
    {
      title: '批次号',
      dataIndex: ['node', 'batchNo'],
      width: 200,
    },
    {
      title: '标识码 ',
      dataIndex: ['node', 'qrCode'],
      width: 200,
    },
    {
      title: '物料编号 ',
      dataIndex: ['node', 'material', 'code'],
      width: 200,
    },
    {
      title: '物料名称 ',
      dataIndex: ['node', 'material', 'name'],
      width: 200,
    },
    {
      title: '追溯关系建立方式 ',
      dataIndex: ['operationType', 'message'],
      width: 200,
    },
    {
      title: '追溯关系建立时间 ',
      dataIndex: 'createdAt',
      width: 200,
      render: (createdAt: Date) => {
        if (!createdAt) return replaceSign;
        return _Time.format(createdAt);
      },
    },
    {
      title: '操作',
      width: 200,
      dataIndex: 'operation',
      render: (_: any, record: ParentAndSubNodeItemType) => {
        return (
          <>
            <Space>
              <Button
                type="link"
                onClick={() => {
                  history.push(toTraceNodeDetail(record?.node?.traceId));
                }}
              >
                详情
              </Button>
              <Button
                type="link"
                onClick={() => {
                  history.push(toTraceNodeChart(record?.node?.traceId));
                }}
              >
                关系图
              </Button>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <BlTable
      loading={loading}
      dataSource={dataSource?.list}
      columns={parentColumns}
      rowKey={(record: ParentAndSubNodeItemType) => record?.node?.traceId}
      tableConfigKey={'traceNodeParentCol'}
      scroll={{ y: 580 }}
      onChange={(pagination: any) => {
        const { current, pageSize } = pagination;

        fetchParentData({ current, pageSize });
      }}
      pagination={{
        ...pagination,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: () => `共 ${dataSource?.total} 条`,
      }}
    />
  );
};

export default ParentNodeTab;
