import { Progress } from 'antd';
import React from 'react';

interface ProgressItemProps {
  value: number;
  label: string;
}

const ProgressItem: React.FC<ProgressItemProps> = (props) => {
  const { value, label } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: 100,
      }}
    >
      <Progress type="circle" percent={value} width={50} format={() => `${value}%`} />
      <span style={{ marginTop: 5, fontSize: 12 }}>{label}</span>
    </div>
  );
};

export default ProgressItem;
