export const getStatus = ({ successNum = 0, failNum = 0, disPatchNum = 0 }) => {
  if (disPatchNum > 0) return '下发中';
  if (failNum === 0 && successNum > 0) {
    return '成功';
  } else if (failNum >= 0 && successNum === 0) {
    return '失败';
  }
  return '部分成功';
};

export const getResult = ({ successNum = 0, failNum = 0, disPatchNum = 0 }) => {
  let result = `成功数：${successNum}，失败数：${failNum}`;

  if (disPatchNum > 0) result += `，下发中：${disPatchNum}`;

  return result;
};
