// import { ApprovalStatus } from './../../../dict/approval/index';
import _ from 'lodash';
import { TaskStatus } from 'src/dict/sop';

/**
 * 审批状态
 */
export enum ApprovalStatus {
  /**
   * 审批中
   */
  pending,
  /**
   * 已通过
   */
  approved,
  /**
   * 已驳回
   */
  rejected,
}

export const initialDispatchCheckModalState = {
  visible: false,
  sopTaskData: undefined,
  stepsData: [],
};

// 不同操作所要求的任务状态
export const taskOpConditions = {
  edit: [TaskStatus.created],
  dispatch: [TaskStatus.created],
  delete: [TaskStatus.created, TaskStatus.canceled],
  start: [TaskStatus.ready],
  cancel: [TaskStatus.ready],
  suspend: [TaskStatus.executing],
  resume: [TaskStatus.suspended],
  forceEnd: [TaskStatus.executing, TaskStatus.suspended],
  operationLog: [
    TaskStatus.created,
    TaskStatus.deleted,
    TaskStatus.ready,
    TaskStatus.canceled,
    TaskStatus.executing,
    TaskStatus.suspended,
    TaskStatus.finished,
  ],
  taskExecLog: [
    TaskStatus.created,
    TaskStatus.deleted,
    TaskStatus.ready,
    TaskStatus.canceled,
    TaskStatus.executing,
    TaskStatus.suspended,
    TaskStatus.finished,
  ],
};

// 任务状态：新建 时介入的审批流状态
export const approvalOpConditions = {
  edit: [ApprovalStatus.approved, ApprovalStatus.rejected],
  dispatch: [ApprovalStatus.approved],
  delete: [ApprovalStatus.approved],
  operationLog: [ApprovalStatus.approved, ApprovalStatus.rejected],
  taskExecLog: [ApprovalStatus.approved, ApprovalStatus.rejected],
};

/** 判断当前状态是否可以执行某个任务操作 */
export const taskCan = _.mapValues(
  taskOpConditions,
  (value) => (currentStatus: TaskStatus | undefined) => value.includes(currentStatus!),
);

export const actionCan = (opConditions: TaskStatus | ApprovalStatus | undefined) => {
  return _.mapValues(
    opConditions as any,
    (value) => (currentStatus: TaskStatus | undefined) => value.includes(currentStatus!),
  );
};

export const taskCanWithApproval = (
  actionType: string,
  taskStatus: TaskStatus | undefined,
  approvalStatus: ApprovalStatus | undefined,
) => {
  if (!_.isNil(approvalStatus) && taskStatus === TaskStatus.created) {
    return (
      actionCan(taskOpConditions as unknown as TaskStatus)[actionType](taskStatus) &&
      actionCan(approvalOpConditions as unknown as ApprovalStatus)[actionType](approvalStatus)
    );
  }

  return actionCan(taskOpConditions as unknown as TaskStatus)[actionType](taskStatus);
};

export const DISPATCH_WARNING =
  '当前任务未填写可执行人，确认下发后，所有部门下的用户都能看到此任务。';
