/**
 * @description 触发规则 返回页面url函数
 */

import _ from 'lodash';

export const TriggerRule_PATH = '/systemConfig/triggerRule';

export const getListPageUrl = () => TriggerRule_PATH;

export const getCreatePageUrl = (id?: number) => {
  return _.isNil(id) ? `${TriggerRule_PATH}/create` : `${TriggerRule_PATH}/create?id=${id}`;
};

export const getDetailPageUrl = (ruleId: number, ruleSource: number) =>
  `${TriggerRule_PATH}/${ruleId}/detail/${ruleSource}`;

export const getEditPageUrl = (ruleId: number) => `${TriggerRule_PATH}/${ruleId}/edit`;
