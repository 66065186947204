import { CascaderOption as _CascaderOption } from '../triggerRule/share/FieldIdCascader';
import { FetchTriggerActionDetailResponse } from 'src/api/ytt/trigger-domain';

export interface CascaderOption extends _CascaderOption {}

export const VIEW_MODAL = {
  /** 通用事件 */
  generalAction: '1',
  /** 业务事件 */
  businessAction: '2',
};

/** 非【通用事件】时，返回空数组 */
export const isGeneralAction = (actionSource: string, arr: any) => {
  return actionSource === VIEW_MODAL.generalAction ? arr : [];
};

/** 非【业务事件】时，返回空数组 */
export const isBusinessAction = (actionSource: string, arr: any) => {
  return actionSource === VIEW_MODAL.businessAction ? arr : [];
};

/** 基础选项类型  */
export interface BaseOption {
  label: React.ReactNode | string;
  value: string | number;
  key?: string | number;
  dataSource?: any;
}

/** 对象属性值配置的每一项类型 */
export interface DetailItem {
  id?: number;
  seq?: number;
  fieldId?: BaseOption | BaseOption[];
  valueType?: number;
  value?: BaseOption[] | BaseOption | string | number;
  defaultField?: number;
  isRequired?: number;
}

export type FetchTriggerActionDetailData = FetchTriggerActionDetailResponse['data'];
