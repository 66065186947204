/**
 * 启停用角色的函数
 */
import { message, Modal } from 'antd';
import { fetchRoleActiveRole } from 'src/api/ytt/user-domain/user/role';
import lookup, { appEnum } from 'src/dict';

/** 改变角色启停用状态 */
const fetchRoleAction = (
  id: number,
  enableFlag: number,
  onSuccess?: () => void,
  onFail?: () => void,
) => {
  const { enabled, disabled } = appEnum.Common.UsageStatus;
  const _active = enableFlag === enabled ? disabled : enabled;

  fetchRoleActiveRole({ ids: [id], enableFlag: _active }).then((res) => {
    if (res.code === 200) {
      onSuccess?.();
      message.success(`${lookup('common.changeStatusAction', enableFlag)}成功`);
    } else onFail?.();
  });
};

/** 改变角色启停用状态的函数 */
export const handleRoleStatus = (
  id: number,
  enableFlag: number,
  inUse: boolean,
  ...rest: (() => void | undefined)[]
) => {
  // 启用中且角色有被使用，停用时弹窗确认
  if (inUse && enableFlag === appEnum.Common.UsageStatus.enabled) {
    Modal.confirm({
      title: '提示',
      content: '角色已被用户使用，是否移除用户后停用？',
      okText: '移除并停用',
      closable: true,
      onOk: () => fetchRoleAction(id, enableFlag, ...rest),
    });
  } else fetchRoleAction(id, enableFlag, ...rest);
};
