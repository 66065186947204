import { Steps, Row, Col, Tag } from 'antd';
import { formatTimeForRender } from 'src/utils/formatters';
import { ApprovalNodeStatus } from 'src/dict/approval';
import _ from 'lodash';
import { ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { renderApprovalStatus, renderApprovalTaskStatus } from '../../utils';
import { BcMultilineText } from 'src/components';
import styles from './styles.module.scss';

interface ApplyNode {
  /**
   * 发起人id
   */
  applicantId?: number;
  /**
   * 发起人姓名
   */
  applicantName?: string;
  /**
   * 申请时间
   */
  applyTime?: number;
}

interface FinishedTaskNode {
  /**
   * 审批意见
   */
  approveComments?: string;
  /**
   * 审批时间
   */
  approveTime?: number;
  /**
   * 审批人id
   */
  approverId?: number;
  /**
   * 审批人姓名
   */
  approverName?: string;
  status?: number;
}

interface NodeList {
  finishedTaskList: FinishedTaskNode[];
  /**
   * 节点名称
   */
  name?: string;
  status?: number;
  /**
   * 待审批人姓名
   */
  toApproveNameList?: string[];
}

interface ApprovalNodeStepsProps {
  applyNode: ApplyNode;
  nodeList: NodeList[];
  isApprovalTask?: Boolean;
}

export default function ApprovalNodeSteps(props: ApprovalNodeStepsProps) {
  const { applyNode, nodeList, isApprovalTask } = props;
  const { Step } = Steps;

  const renderApprovalStep = (task: FinishedTaskNode) => (
    <div style={{ backgroundColor: '#F8F8F8', margin: '10px 0', padding: '12px', width: 430 }}>
      <Row justify="space-between">
        <Col span={20} style={{ fontSize: 16 }}>
          <b>
            审批人：<span style={{ color: '#02B980' }}>{task?.approverName}</span>
          </b>
        </Col>
        <Col span={4}>
          {isApprovalTask
            ? renderApprovalTaskStatus(task.status!)
            : renderApprovalStatus(task.status!)}
        </Col>
      </Row>
      <Row>
        <Col span={20} style={{ fontSize: 14 }}>
          审批时间：{formatTimeForRender(task?.approveTime)}
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <div style={{ display: 'flex', fontSize: 14 }}>
            <div style={{ width: 70 }}>审批意见:</div>
            <div style={{ flex: 1 }}>
              <BcMultilineText
                text={task?.approveComments ?? ''}
                openText="更多"
                closeText="收起"
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <Steps
      size="small"
      current={1}
      direction="vertical"
      style={{ marginLeft: 16 }}
      className={styles?.BlSteps}
    >
      <Step
        title="发起审批"
        description={
          <Row justify="space-between" style={{ width: 580, color: '#666' }}>
            <Col span={17}>
              由<span style={{ color: '#02B980' }}>{applyNode?.applicantName}</span>发起审批
            </Col>
            <Col span={7}>
              <div style={{ textAlign: 'right', fontSize: 14 }}>
                {formatTimeForRender(applyNode?.applyTime)}
              </div>
            </Col>
          </Row>
        }
        status="finish"
      />
      {nodeList?.map((item: NodeList) => {
        if (item?.status === ApprovalNodeStatus.approved) {
          return (
            <Step
              title={item?.name}
              subTitle={
                <Tag color="success" style={{ color: '#52C41A', background: '#D9F7BE' }}>
                  已通过
                </Tag>
              }
              description={item?.finishedTaskList!.map((task: FinishedTaskNode) => {
                return renderApprovalStep(task);
              })}
              status="finish"
            />
          );
        }
        if (item?.status === ApprovalNodeStatus.pending) {
          return (
            <Step
              title={item.name}
              subTitle={
                <Tag color="gold" style={{ color: '#FAAD14', background: '#FFF1B8' }}>
                  {'审批中'}
                </Tag>
              }
              description={
                <>
                  {item?.finishedTaskList!.map((task: FinishedTaskNode) => {
                    return renderApprovalStep(task);
                  })}
                  <Row style={{ width: 430, color: '#666666' }}>
                    <Col>
                      {`${
                        item?.finishedTaskList.length > 0 ? '待' : ''
                      }审批人：${item?.toApproveNameList?.join('、')}`}
                    </Col>
                  </Row>
                </>
              }
              icon={<ClockCircleOutlined style={{ color: '#FAAD14' }} />}
              status="process"
            />
          );
        }
        if (item?.status === ApprovalNodeStatus.waiting) {
          return (
            <Step
              title={item.name}
              subTitle={
                <Tag color="default" style={{ color: '#595959', background: '#F0F0F0' }}>
                  未激活
                </Tag>
              }
              description={
                _.isEmpty(item?.toApproveNameList) || (
                  <Row style={{ width: 430, color: '#666666' }}>
                    <Col>{`审批人：${item?.toApproveNameList?.join('、')}`}</Col>
                  </Row>
                )
              }
              status="wait"
            />
          );
        }
        if (item?.status === ApprovalNodeStatus.rejected) {
          return (
            <Step
              title={item.name}
              subTitle={
                <Tag color="error" style={{ color: '#FF4D4F', background: '#FFDCDD' }}>
                  已驳回
                </Tag>
              }
              description={item?.finishedTaskList!.map((task: FinishedTaskNode) => {
                if (task.status === ApprovalNodeStatus.approved) {
                  return renderApprovalStep(task);
                }
                if (task.status === ApprovalNodeStatus.rejected) {
                  return renderApprovalStep(task);
                }
              })}
              icon={<CloseCircleOutlined style={{ color: '#FF4D4F' }} />}
              // status="error"
            />
          );
        }
      })}
    </Steps>
  );
}
