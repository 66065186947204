import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './page/app/index';
import reportWebVitals from './reportWebVitals';
import sentry from 'src/utils/sentry';
import arms from 'src/utils/arms';
import 'antd/dist/antd.less';
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { Store, store } from 'src/store';
import { setAntdConfigs } from './setGlobalConfigs';
import volcano from './utils/volcano';

// 解决全局环境下使用react-router-dom 中 history 方法
declare global {
  interface Window {
    reactRouterHistoryInstance: any;
    globalStore: Store;
    argon2: any;
    isProdEnv: boolean;
    volcanoEvent: any;
  }
}

arms.init();
sentry.init();
window.globalStore = store;
setAntdConfigs();
// 初始化火山SDK
volcano.initSDK();

ReactDOM.render(
  <Provider store={store as any}>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById('mainapp'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
