import React, { useState, useEffect } from 'react';
import { Space } from 'antd';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { BlTable, SingleOperationLogComponent, BlIcon } from 'src/components';
import { useHistory } from 'react-router-dom';
import { toCheckClassEdit } from '../navigation';
import { CreatorData } from '../../checkItem/interface';
import { fetchQcCheckItemCategoryDetail } from 'src/api/ytt/quality-domain/checkClass';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';

interface DetailBaseInfoProps {
  checkClassId: number;
}

const DetailBaseInfo = (props: DetailBaseInfoProps) => {
  const { checkClassId } = props;
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录

  const [dataSource, setDataSource] = useState({} as any);
  const history = useHistory();

  const fetchData = async () => {
    try {
      const { data } = await fetchQcCheckItemCategoryDetail({ id: checkClassId });

      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [checkClassId]);

  const detailInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [{ label: '分类名称', dataIndex: 'name', isFullLine: true }],

    column: 2,
  };

  const checkItemInfo: DetailLayoutInfoBlock = {
    title: '绑定检验项',
    items: [
      {
        label: '',
        dataIndex: 'table',
        isFullLine: true,
        render: () => {
          return (
            <div
              style={{
                marginLeft: 20,
              }}
            >
              <BlTable
                columns={[
                  {
                    title: '检验项编号',
                    dataIndex: 'code',
                    width: 200,
                  },
                  {
                    title: '检验项名称',
                    dataIndex: 'name',
                    width: 200,
                  },
                ]}
                dataSource={dataSource?.qcCheckItemList}
                rowKey={(record) => record?.id}
                scroll={{ y: 650 }}
              />
            </div>
          );
        },
      },
    ],
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: CreatorData) => {
          return creator ? (
            <Space>
              <AvatarDisplay
                id={creator?.id}
                name={creator?.name}
                avatarUrl={creator?.avatarUrl}
                key={creator?.id}
                isShowTag={false}
                isUser
              />
            </Space>
          ) : (
            replaceSign
          );
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        isFullLine: true,
        render: (createdAt: Date) => _Time.format(createdAt) ?? replaceSign,
      },

      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: CreatorData) => {
          return operator ? (
            <Space>
              <AvatarDisplay
                id={operator?.id}
                name={operator?.name}
                avatarUrl={operator?.avatarUrl}
                key={operator?.id}
                isShowTag={false}
                isUser
              />
            </Space>
          ) : (
            replaceSign
          );
        },
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        isFullLine: true,
        render: (updatedAt: Date, record: any) =>
          _Time.format(updatedAt) ?? _Time.format(record?.createdAt) ?? replaceSign,
      },
    ],
    column: 2,
  };

  const infoArray = [detailInfo, checkItemInfo, otherInfo];

  const baseMenu = [
    {
      key: 'record',
      auth: authDict.inspectionitemcategory_operrecord,
      disabled: checkClassId === 0,
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'edit',
      auth: authDict.inspectionitemcategory_edit,
      disabled: checkClassId === 0,
      onClick: () => {
        history.push(toCheckClassEdit(checkClassId));
      },
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ];

  return (
    <>
      <DetailLayout
        userAuth={getAuthFromLocalStorage()}
        title="检验项分类详情"
        info={infoArray}
        dataSource={dataSource}
        baseMenu={baseMenu}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={checkClassId}
          objectCode={'InspectionItemCategory'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailBaseInfo;
