import { blacklakeGreen, white } from 'src/styles/color';

export enum ReletiveLevel {
  /**
   * 第一级尺寸
   */
  lg = 1,
  /**
   * 第二级
   */
  md = 2,
  /**
   * 第三级
   */
  sm = 3,
  /**
   * 第四级
   */
  xs = 4,
}

export const nodeStateStyles = {
  [ReletiveLevel.lg]: {
    style: {
      r: 63,
      fill: 'l(90) 0:#05DDB7 1:#00CE8E',
      stroke: 'l(90) 0:#C7FCED 1:#C3FFEC',
      lineWidth: 10,
      shadowBlur: 0,
    },
    hover: {
      fill: white,
      labelFill: blacklakeGreen,
      stroke: 'l(90) 0:#DCFDF4 1:#ACF2DD',
    },
    labelCfg: {
      fill: white,
      fontSize: 16,
      fontWeight: 500,
      cursor: 'pointer',
    },
    subLabelcfg: {
      fill: white,
      lineHeight: 1.6,
      fontSize: 12,
      cursor: 'pointer',
    },
  },
  [ReletiveLevel.md]: {
    style: {
      r: 56,
      fill: 'l(90) 0:#F5FFFD 1:#D5FFF2',
      stroke: '#ACF2DD',
      lineWidth: 3,
      shadowBlur: 0,
    },
    labelCfg: {
      fill: blacklakeGreen,
      fontSize: 14,
      fontWeight: 500,
      cursor: 'pointer',
    },
    subLabelcfg: {
      fill: blacklakeGreen,
      lineHeight: 1.6,
      fontSize: 12,
      cursor: 'pointer',
    },
  },
  [ReletiveLevel.sm]: {
    style: {
      r: 56,
      fill: 'l(90) 0:#F5FFFD 1:#D5FFF2',
      stroke: '#ACF2DD',
      lineWidth: 3,
      shadowBlur: 0,
    },
    labelCfg: {
      fill: blacklakeGreen,
      fontSize: 14,
      fontWeight: 500,
      cursor: 'pointer',
    },
    subLabelcfg: {
      fill: blacklakeGreen,
      lineHeight: 1.6,
      fontSize: 12,
      cursor: 'pointer',
    },
  },
  [ReletiveLevel.xs]: {
    style: {
      r: 45,
      fill: white,
      stroke: 'l(90) 0:#F9F9F9 1:#EAEAEA',
      lineWidth: 3,
      cursor: 'pointer',
    },
    labelCfg: {
      fill: '#8C8C8C',
      fontSize: 14,
      fontWeight: 500,
      cursor: 'pointer',
    },
  },
};
