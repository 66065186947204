import { createModel } from '@rematch/core';
import { RootModel } from 'src/store/models/index';
import {
  FetchInboundOrderCreateRequest,
  FetchOutboundOrderCreateRequest,
} from 'src/api/ytt/inventory-domain/boundOrder';
import _ from 'lodash';
import {
  SalesOrderListProps,
  SalesOrderListPropsItems,
} from 'src/page/sales/salesManagement/salesOrder/interface';
import { appEnum } from 'src/dict';
import {
  SalesReturnDetailListItemType,
  SalesReturnOrderListItemType,
} from 'src/page/sales/salesManagement/salesReturnOrder/interface';
import { InBoundTypeEnum } from 'src/dict/bound';
import { InvoiceOrigin } from 'src/dict/invoice';
import { IN_SOURCE_TYPE, SOURCE_TYPE } from 'src/dict/warehouse';
import {
  CustomerDeliveryScheduleNoteListItems,
  CustomerDeliveryScheduleNoteListProps,
} from 'src/page/sales/salesManagement/collaborativeDeliverySchedule/interface';
import type {
  FetchPurchaseOrderListMaterialByIdsResponseDataElementType,
  MaterialType,
  SupplierType,
  AmountType,
} from 'src/page/purchase/purchaseManagement/purchaseOrder/interface';
interface DeliveryScheduleNoteInfoType {
  itemList?: {
    material: MaterialType;
    purchaseOrderCode: string;
    supplier: SupplierType;
    purchaseOrderItemSeqNum: string;
    purchaseOrderItemId: number;
    planDeliveryAmount: AmountType;
    waitPlanDeliveryAmount: AmountType;
  }[];
}
interface StateType {
  /** 入库单 */
  inBoundInfo: FetchInboundOrderCreateRequest;
  /** 出库单 */
  outBoundInfo: FetchOutboundOrderCreateRequest;
  /** 发货单 */
  invoiceInfo: any; // TODO  待补充一下
  /** 采购退货单 */
  purchaseReturnInfo: any;
  /** 交货计划单 */
  deliveryScheduleNoteInfo: DeliveryScheduleNoteInfoType;
}

/** 初始数据 */
const initialState: StateType = {
  inBoundInfo: {},
  outBoundInfo: {},
  invoiceInfo: {},
  purchaseReturnInfo: {},
  deliveryScheduleNoteInfo: {},
};

export const supplyChain = createModel<RootModel>()({
  state: initialState,
  reducers: {
    /** 入库单 */
    setInBoundInfo(state, inBoundInfo: FetchInboundOrderCreateRequest) {
      return { ...state, inBoundInfo };
    },
    /** 出库单 */
    setOutBoundInfo(state, outBoundInfo: FetchInboundOrderCreateRequest) {
      return { ...state, outBoundInfo };
    },
    /** 发货单 */
    setInvoiceInfo(state, invoiceInfo: any) {
      return { ...state, invoiceInfo };
    },
    /** 采购退货单 */
    setPurchaseReturnInfo(state, purchaseReturnInfo: any) {
      return { ...state, purchaseReturnInfo };
    },
    setDeliveryScheduleNoteInfo(state, deliveryScheduleNoteInfo: DeliveryScheduleNoteInfoType) {
      return { ...state, deliveryScheduleNoteInfo };
    },
  },
  effects: (dispatch) => ({
    /** 入库单 */
    formatterInBoundInfo(payload: FetchInboundOrderCreateRequest) {
      dispatch({
        type: 'supplyChain/setInBoundInfo',
        payload: {
          bizType: appEnum.Bound.InBoundTypeEnum.purchase,
          sourceType: IN_SOURCE_TYPE.receipt,
          items: payload?.items?.map((row: any) => {
            const { inboundReceiveAmount = {}, material = {} } = row;

            return {
              planAmount: inboundReceiveAmount?.amount,
              seqNum: row.seqNum,
              entityCode: row.entityCode,
              relationItems: [
                {
                  entityId: row.id,
                  entityType: appEnum.Bound.inBoundEntityTypeEnum.receive,
                },
              ],
              materialId: {
                label: material.baseInfo?.code,
                value: JSON.stringify(material),
                key: JSON.stringify(material),
              },
              unitId: {
                label: inboundReceiveAmount?.unitName,
                value: inboundReceiveAmount?.unitId,
              },
            };
          }),
          supplierId: payload?.supplierId,
        },
      });
    },
    /** 出库单 */
    formatterOutBoundInfo(payload: FetchOutboundOrderCreateRequest) {
      dispatch({
        type: 'supplyChain/setOutBoundInfo',
        payload: {
          bizType: appEnum.Bound.OutBoundTypeEnum.sales,
          sourceType: SOURCE_TYPE.invoice,
          items: payload?.items?.map((row: any) => {
            const { expectedIssueAmount = {}, material = {} } = row;

            return {
              planAmount: expectedIssueAmount?.amount,
              entityCode: row.code,
              seqNum: row.lineNo,
              relationItems: [
                {
                  entityId: row.id,
                  entityType:
                    row.origin === InvoiceOrigin.normal
                      ? appEnum.Bound.outBoundEntityTypeEnum.invoiceOrdinary
                      : appEnum.Bound.outBoundEntityTypeEnum.invoiceCollaborate,
                },
              ],
              unitId: {
                label: expectedIssueAmount?.unitName,
                value: expectedIssueAmount?.unitId,
              },
              materialId: {
                label: material?.baseInfo?.code,
                value: JSON.stringify(material),
                key: JSON.stringify(material),
              },
            };
          }),
          customerId: payload?.customerId,
        },
      });
    },
    /** 发货单 */
    formatterInvoiceInfo(payload: { invoiceType: string | number; data: SalesOrderListProps }) {
      dispatch({
        type: 'supplyChain/setInvoiceInfo',
        payload: {
          invoiceType: payload?.invoiceType,
          items: _.flatten(_.map(payload?.data, 'items'))?.map(
            (materialRow: SalesOrderListPropsItems) => {
              // 合并物料行，并格式化
              const {
                deliveryDate,
                material,
                id,
                orderId,
                plannedReturnAmount,
                plannedIssueAmount,
                availableIssueAmount,
                plannedAmount,
                lineNo,
                orderCode,
                amount,
                status,
              } = materialRow;

              console.log('materialRow', materialRow);
              return {
                amount, // 下单需求数量
                material, // 物料id
                plannedReturnAmount, // 已计划退货数
                plannedIssueAmount, // 已计划发货数
                plannedAmount, // 计划发货
                availableIssueAmount, // 待发货 = 销售明细行需求数 - 已计划退货 - 已计划发货
                deliveryDate, // 交货日期
                salesOrderLineNo: lineNo, // 销售订单明细行 行号,
                salesOrderCode: orderCode, // 销售订单编号
                salesOrderId: orderId, // 销售订单id
                salesOrderItemId: id, // 销售订单明细行id,
                soStatus: status, // 销售订单状态
              };
            },
          ),
        },
      });
    },
    /** 协同交货计划 发货单 */
    formatterCdsnInvoiceInfo(payload: {
      invoiceType: string | number;
      data: CustomerDeliveryScheduleNoteListProps;
    }) {
      dispatch({
        type: 'supplyChain/setInvoiceInfo',
        payload: {
          invoiceType: payload?.invoiceType,
          items: _.flatten(_.map(payload?.data, 'items'))?.map(
            (materialRow: CustomerDeliveryScheduleNoteListItems) => {
              // 合并物料行，并格式化
              const {
                id,
                noteId,
                code,
                material,
                plannedIssueAmount,
                availableIssueAmount,
                deliveryTime,
                amount,
                lineNo, // 明细行行号
                status, // 协同交货计划单状态
                soId,
                saleOrderCode,
                soItemId,
                saleOrderLineNo,
              } = materialRow;

              return {
                amount, // 交货数量
                material, // 物料id
                plannedIssueAmount, // 计划发货
                availableIssueAmount, // 待发货
                deliveryDate: deliveryTime, // 交货时间
                cdsnId: noteId, // 协同交货计划单id
                cdsnCode: code, // 协同交货计划单code
                cdsnItemId: id, // 协同交货计划单明细行id
                cdsnLineNo: lineNo, // 协同交货计划单明细行 行号,
                cdsnStatus: status, // 协同交货计划单 状态,
                salesOrderId: soId, // 销售订单id
                salesOrderCode: saleOrderCode,
                salesOrderItemId: soItemId, // 销售订单明细行id
                salesOrderLineNo: saleOrderLineNo,
              };
            },
          ),
        },
      });
    },
    /** 销售退货单 */
    formatSalesReturnOrderToInboundOrder(payload: SalesReturnOrderListItemType[]) {
      const salesReturnOrderDetailLines = payload.reduce(
        (prev: Array<SalesReturnDetailListItemType>, curr) => {
          if (curr.items) {
            return prev.concat(
              curr.items.map((item: any) => ({
                ...item,
                returnOrderCode: curr.code,
              })),
            );
          }
          return prev;
        },
        [],
      );

      dispatch({
        type: 'supplyChain/setInBoundInfo',
        payload: {
          bizType: InBoundTypeEnum.sales,
          sourceType: IN_SOURCE_TYPE.saleReturnOrder,
          items: salesReturnOrderDetailLines.map(
            (salesReturnOrderDetail: SalesReturnDetailListItemType) => {
              return {
                materialId: {
                  label: salesReturnOrderDetail.material?.baseInfo?.code,
                  value: JSON.stringify(salesReturnOrderDetail.material),
                },
                planAmount: salesReturnOrderDetail.expectedInboundAmount?.amount,
                relationItems: [
                  {
                    entityType: appEnum.Bound.inBoundEntityTypeEnum.return,
                    entityId: salesReturnOrderDetail.id,
                  },
                ],
                unitId: {
                  label: salesReturnOrderDetail.amount?.unitName,
                  value: salesReturnOrderDetail.amount?.unitId,
                },
                seqNum: salesReturnOrderDetail.lineNo,
                entityCode: salesReturnOrderDetail.returnOrderCode,
              };
            },
          ),
        },
      });
    },
    /** 采购退货单 */
    formatterPurchaseReturnOrderInfo(payload) {
      dispatch({
        type: 'supplyChain/setPurchaseReturnInfo',
        payload: {
          itemList: payload?.map((row: any, index: number) => {
            const {
              code,
              seqNum,
              id: purchaseOrderItemId,
              demandAmount,
              supplier,
              material,
              source,
            } = row;

            return {
              seqNo: index + 1,
              material,
              returnAmount: 1,
              unitName: demandAmount?.unitName,
              unitId: demandAmount?.unitId,
              purchaseOrderCode: code,
              supplier,
              seqNum,
              purchaseOrderItemId,
              source,
            };
          }),
        },
      });
    },
    /** 采购退货单 出库单 */
    formatterPurchaseReturnOrderToOutBoundInfo(payload) {
      dispatch({
        type: 'supplyChain/setOutBoundInfo',
        payload: {
          bizType: appEnum.Bound.OutBoundTypeEnum.purchase,
          sourceType: SOURCE_TYPE.purchaseReturnOrder,
          items: payload?.map((row: any) => {
            const { outboundSendAmount = {}, material = {} } = row;

            return {
              planAmount: outboundSendAmount?.amount,
              entityCode: row.purchaseReturnOrderCode || row.code, // 订单视图 || 物料行视图
              seqNum: row.seqNo,
              relationItems: [
                {
                  entityId: row.itemId || row.id, // 采购退货单「行」id
                  entityType: appEnum.Bound.outBoundEntityTypeEnum.return,
                },
              ],
              unitId: {
                label: outboundSendAmount?.unitName,
                value: outboundSendAmount?.unitId,
              },
              materialId: {
                label: material?.baseInfo?.code,
                value: JSON.stringify(material),
                key: JSON.stringify(material),
              },
            };
          }),
        },
      });
    },
    /** 交货计划单 */
    formatterDeliveryScheduleNote(payload) {
      dispatch({
        type: 'supplyChain/setDeliveryScheduleNoteInfo',
        payload: {
          itemList: payload?.map(
            (row: FetchPurchaseOrderListMaterialByIdsResponseDataElementType) => {
              const {
                code,
                seqNum,
                id: purchaseOrderItemId,
                supplier,
                material,
                planDeliveryAmount,
                waitPlanDeliveryAmount,
              } = row;

              return {
                material,
                purchaseOrderCode: code,
                supplier,
                purchaseOrderItemSeqNum: seqNum,
                purchaseOrderItemId,
                planDeliveryAmount,
                waitPlanDeliveryAmount,
              };
            },
          ),
        },
      });
    },
  }),
});
