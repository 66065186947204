import { Popover, Image, Avatar } from 'antd';
import { avatarStyle, avatarDisplay } from '../avatar/show';
import styles from './index.module.scss';
import { BlIcon } from '..';

export interface UserProps {
  id: number;
  name: string;
  active?: number;
  avatarUrl?: string;
  departmentVOList?: any[];
  rolePOList?: any[];
  code?: string;
  phone?: string;
}

const UserTooltip = (props: UserProps) => {
  const { avatarUrl, name, departmentVOList, rolePOList, code, phone, active } = props;

  const renderTooltip = () => {
    return (
      <div style={{ width: 330, height: 158 }}>
        {active === 0 && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: 158,
              backgroundColor: 'rgba(0, 0,0, 0.45)',
              zIndex: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p style={{ color: '#fff', opacity: 1 }}>
              <BlIcon type="icontingyong" />
              该账号已停用
            </p>
          </div>
        )}
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: 16,
          }}
        >
          <div style={{ paddingRight: 16 }}>
            {avatarUrl ? (
              <Image
                src={avatarUrl}
                width={126}
                height={126}
                style={{ borderRadius: 20, display: 'block' }}
              />
            ) : (
              <Avatar style={avatarStyle} icon={<BlIcon type="icongeren" />} size={116} />
            )}
          </div>
          <div>
            <p>{name}</p>
            {departmentVOList && (
              <p>
                {departmentVOList?.map((item) => item.name).join(',')} |{' '}
                {rolePOList?.map((item) => item.name).join(',')}
              </p>
            )}
            <p>用户编号：{code}</p>
            <p>手机号：{phone}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Popover content={renderTooltip()} overlayClassName={styles.userTooltipContent}>
      {avatarDisplay({ ...props, isUser: true, isShowTag: true })}
    </Popover>
  );
};

export default UserTooltip;
