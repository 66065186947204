import { useEffect, useState } from 'react';
import _ from 'lodash';
import { PrivilegeTreeVO } from 'src/page/organization/helper/apiTypes';
import { TreeNodeProps } from 'antd';
import {
  fetchBizOperationTree,
  FetchBizOperationTreeResponse,
} from 'src/api/ytt/metadata-domain/biz_operation';

/**
 * 后端返回的并不是标准的antd的树形结构，需要转化为antd标准的树形结构
 * @param _tree
 * @param disabled
 */
const formatTree = (
  _tree: PrivilegeTreeVO[],
  disabled?: boolean,
): { privilegeTree: any[]; items: any[] } => {
  /** 获取最后一层可选择的权限items, 提交时需要将选择的item传给后端 * */
  let items: any[] = [];
  const _formatTree = (tree: FetchBizOperationTreeResponse['data']) => {
    const newRet: any = [];

    if (!_.isArray(tree)) {
      return newRet;
    }

    for (let i = 0; i < tree.length; i++) {
      const currentTree = tree[i];
      const obj: TreeNodeProps & { items?: any[] } = {};

      /** 判断是不是最后的节点* */
      const isEndChild = _.get(currentTree, 'children[0].leaf');

      obj.key = currentTree.id?.toString();
      obj.title = currentTree.name;
      obj.code = currentTree.code;
      obj.id = currentTree?.id;
      obj.disableCheckbox = currentTree?.enable === true ? disabled : !currentTree.enable;
      obj.enable = currentTree?.enable;

      if (isEndChild) {
        obj.items = _.get(currentTree, 'children', []).map((item: any) => {
          return {
            key: item?.id?.toString(),
            title: item?.name,
            code: item?.code,
            id: item?.id,
            enable: item?.enable,
            disableCheckbox: item?.enable !== undefined ? !currentTree.enable : true,
          };
        });

        items = _.concat(items, _.get(currentTree, 'children', []));
      } else if (_.has(currentTree, 'children')) {
        /** 循环递归子节点 * */
        obj.children = _formatTree(currentTree.children as PrivilegeTreeVO[]);
      }
      newRet.push(obj);
    }
    return newRet;
  };

  return { privilegeTree: _formatTree(_tree), items: _.cloneDeep(items) };
};

/**
 * 封装 web端权限树的hook
 */
type usePrivilegeTreeType = (
  usePrivilegeTree: typeof fetchBizOperationTree,
  disabled?: boolean,
  roleId?: string | number,
) => any;
const usePrivilegeTree: usePrivilegeTreeType = (getPrivilegeTreeFunction, disabled, roleId) => {
  const [tree, setTree] = useState<PrivilegeTreeVO[]>([]);

  useEffect(() => {
    if (getPrivilegeTreeFunction) {
      getPrivilegeTreeFunction({ roleId }).then((res: FetchBizOperationTreeResponse) => {
        setTree(_.get(res, 'data', []));
      });
    }
  }, [getPrivilegeTreeFunction, roleId]);

  const { privilegeTree, items } = formatTree(tree, disabled);

  return { privilegeTree: tree, domTree: privilegeTree, selectItems: items };
};

export default usePrivilegeTree;
