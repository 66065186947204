import { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import loadable from '@loadable/component';
import _ from 'lodash';

import LocalStorage from 'src/utils/localStorage';
import styles from 'src/styles/common.scss';
import { RootState } from 'src/store';
import { LoginRoutePathname } from 'src/utils/constants';
import type { Dispatch } from 'src/store';
import { ContextType, FIELDS } from './constant';
import Layout from './layout';

import listeners from '../routeListeners';

const LoadableLoginPage = loadable(() => import('src/page/login'));

function HistoryContent() {
  const history = useHistory();

  useEffect(() => {
    window.reactRouterHistoryInstance = history;
    return () => {
      window.reactRouterHistoryInstance = null;
    };
  }, [history]);
  return null;
}

function RouteWatcher({ dispatch }: { dispatch: Dispatch }) {
  const location = useLocation();
  const prevLocation = useRef(location);

  useEffect(() => {
    if (location?.pathname !== prevLocation.current?.pathname) {
      // 登录成功后，加载页面时同时更新用户相关数据（用户详情和用户权限）
      if (
        !LoginRoutePathname.includes(location.pathname) &&
        !LoginRoutePathname.includes(prevLocation.current?.pathname)
      ) {
        dispatch({ type: 'user/getUserInfo' });
      }
      listeners.forEach((listener) => {
        if (_.isFunction(listener)) {
          listener(prevLocation.current?.pathname, location?.pathname, dispatch);
        }
      });

      prevLocation.current = location;
    }
  }, [location]);

  return null;
}

function App() {
  const privilegeInfo = useSelector((state: RootState) => state?.user?.privilegeInfo, shallowEqual);
  const dispatch = useDispatch<any>();
  const contextType = LocalStorage.get(FIELDS.CONTEXT_TYPE) || ContextType.FRONTEND;

  useEffect(() => {
    dispatch({
      type: 'contextInfo/setContextType',
      payload: contextType,
    });
  }, [dispatch]);

  useEffect(() => {
    const { pathname } = document.location;

    if (_.isEmpty(privilegeInfo) && !LoginRoutePathname.includes(pathname)) {
      dispatch({ type: 'user/getUserInfo' });
    }
  }, [dispatch, privilegeInfo]);

  return (
    <Router>
      <div
        className={styles.tableLayout}
        style={{
          height: '100vh',
        }}
      >
        <HistoryContent />
        <RouteWatcher dispatch={dispatch} />
        <Switch>
          <Route path="/login" component={LoadableLoginPage} />
          <Route render={() => <Layout />} />
        </Switch>
        ;
      </div>
    </Router>
  );
}

export default App;
