import { useEffect, useState } from 'react';
import { Form, Input, FormInstance, DatePicker, Button, message, Tag } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';
import { BlSortFormList, TagList } from 'src/components';
import EditableText from 'src/components/editableText';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import BatchAddMaterials from './batchAddMaterials';
import { PurchaseExecStatus } from 'src/dict/purchase';
import _Time from 'src/utils/dataTypes/time';
import _, { max, map } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import { replaceSign } from 'src/utils/constants';
import _Array from 'src/utils/dataTypes/array';
import { getMaterialUnitInfo } from 'src/entity/material';

interface Props {
  initialData?: any[];
  orderStatus: number;
  name: string;
  edit: boolean;
  form: FormInstance;
  style: React.CSSProperties;
}

export default function MaterialTable({
  initialData,
  name,
  form,
  edit,
  orderStatus,
  style,
}: Props) {
  const [batchAddVisible, setBatchAddVisible] = useState(false);
  const [addForm] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      itemList: initialData ?? [],
    });
  }, [initialData]);

  const handleMaterialFinish = (rows: any[], selectedKeys: number[]) => {
    const materials = form.getFieldValue(name);
    const baseSupplier = rows[0]?.supplier;
    const isFalse = _.some(rows, (node) => node?.supplier?.id !== baseSupplier?.id);

    if (isFalse) {
      message.error('只能选择来源相同且供应商一致的采购订单');
      return;
    }

    const baseSource = rows?.[0]?.source;
    const isSameSource = rows?.every((value) => value?.source === baseSource);

    if (!isSameSource) {
      message.error('只能选择来源相同且供应商一致的采购订单');
      return;
    }

    // 新增行号为当前行号值最大加1
    const seqNoList = materials.map((el: any) => Number(el?.seqNo));
    const num = seqNoList.length ? Number(max(seqNoList)) + 1 : 1;

    // 过滤掉重复purchaseOrderItemId的物料行
    const filterRows = materials.length
      ? rows?.filter((node) => !map(materials, 'purchaseOrderItemId').includes(node.id))
      : rows;

    const addMaterial = filterRows?.map((node, index: number) => {
      // 此处id为采购订单物料行id,
      const {
        code,
        seqNum,
        id: purchaseOrderItemId,
        demandAmount,
        supplier,
        material,
        source,
      } = node;

      return {
        seqNo: index + num,
        material,
        returnAmount: 1,
        unitName: demandAmount?.unitName,
        unitId: demandAmount?.unitId,
        purchaseOrderCode: code,
        supplier,
        seqNum,
        purchaseOrderItemId,
        source,
      };
    });

    const newMaterials = _.cloneDeep(materials).concat(addMaterial);
    // 根据用户选择selectedKeys的做删减 (反选的情况)
    const itemList = newMaterials.filter((item) => {
      const { purchaseOrderItemId } = item;
      const index = _.findIndex(selectedKeys, (o) => o == purchaseOrderItemId);

      if (index !== -1) {
        return item;
      }
    });

    form.setFieldsValue({
      itemList,
      source: itemList[0]?.source,
      supplier: `${itemList[0]?.supplier?.code} / ${itemList[0]?.supplier?.name}`,
    });

    setBatchAddVisible(false);
  };

  const renderAddActon = () => {
    return (
      <div style={{ marginBottom: 20, marginTop: 10 }}>
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          style={{ color: '#02B980', borderColor: '#02B980' }}
          disabled={edit && orderStatus === PurchaseExecStatus.executing}
          onClick={() => {
            setBatchAddVisible(true);
          }}
        >
          按采购订单添加
        </Button>
      </div>
    );
  };

  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '行号',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'seqNo']}
              fieldKey={[field?.key, 'seqNo']}
              style={{ marginBottom: 0 }}
            >
              {field?.name + 1}
            </Form.Item>
          );
        },
      },
      {
        title: '物料编号',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'material', 'baseInfo', 'code']}
              fieldKey={[field?.key, 'materialCode']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '物料名称',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'material', 'baseInfo', 'name']}
              fieldKey={[field?.key, 'materialName']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '物料属性',
        width: 250,
        render: (_, field) => {
          const attribute = form.getFieldValue(name)[field.name]?.material?.attribute;

          if (_Array.isEmpty(attribute)) {
            return replaceSign;
          }
          return (
            <TagList
              dataSource={map(attribute, ({ id, name, attributeItem }) => ({
                label: `${name}:${attributeItem?.content}`,
                value: id,
              }))}
            />
          );
        },
      },
      {
        title: '应退时间',
        width: 250,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'returnTime']}
              fieldKey={[field?.key, 'returnTime']}
              rules={[{ required: true, message: '请选择应退时间' }]}
              shouldUpdate
              initialValue={_Time.daysAfter(7)}
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                showTime
                format={'YYYY-MM-DD HH:mm:ss'}
                disabled={orderStatus !== PurchaseExecStatus.created}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '应退数',
        width: 200,
        render: (_, field) => {
          return (
            <Form.Item shouldUpdate noStyle>
              {() => {
                // 查询出该单位的精度信息
                const material = form.getFieldValue(name)[field.name]?.material;
                const unitId = form.getFieldValue(name)[field.name]?.unitId;
                const unitInfo = getMaterialUnitInfo(material, unitId);
                const { precisionFigure } = unitInfo;

                return (
                  <Form.Item
                    name={[field?.name, 'returnAmount']}
                    fieldKey={[field?.key, 'returnAmount']}
                    rules={[
                      { required: true, message: '请输入应退数' },
                      {
                        validator: numberMinMaxCheck({
                          min: 0,
                          minAllowEqual: false,
                          message: '请输入大于0的数字',
                        }),
                      },
                      {
                        // 精度校验从单位中获取
                        validator: fractionLengthCheck(
                          precisionFigure,
                          `请输入${precisionFigure}位精度的数字`,
                        ),
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      addonAfter={unitInfo?.name}
                      type="number"
                      placeholder="请输入应退数"
                      disabled={edit && orderStatus === PurchaseExecStatus.executing} // 状态为执行中时，不允许增删物料行
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '采购订单编号',
        width: 250,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'purchaseOrderCode']}
              fieldKey={[field?.key, 'purchaseOrderCode']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '采购订单行号',
        width: 250,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'seqNum']}
              fieldKey={[field?.key, 'seqNum']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
    ];
  };

  return (
    <div style={style}>
      <BlSortFormList
        buttonText="添加物料行"
        form={form}
        name={name}
        isNeedDrag={false}
        isNeedAdd={false}
        isNeedDelete={!(edit && orderStatus === PurchaseExecStatus.executing)} // 状态为执行中时，不允许增删物料行
        renderColumns={() => getColumns()}
        listRules={[
          {
            validator: async (__, items) => {
              if (!items || _.isEmpty(items.filter((i: any) => !!i))) {
                return Promise.reject(Error('请至少添加一行物料'));
              } else if (_.uniqBy(items, 'seqNo').length < items.length) {
                return Promise.reject(Error('不能有重复行号'));
              }
              return Promise.resolve(true);
            },
          },
        ]}
        onDelete={(index) => {
          const length = form.getFieldValue('itemList').length;

          if (length === 1) {
            form.setFieldsValue({
              source: undefined,
              supplier: undefined,
            });
          }
        }}
      />
      {renderAddActon()}
      {batchAddVisible && (
        <BatchAddMaterials
          addForm={addForm}
          visible={batchAddVisible}
          handleFinish={handleMaterialFinish}
          selectedKeys={() => {
            const items = form.getFieldValue(name) || [];
            const selectedKeys = items?.map((item) => item?.purchaseOrderItemId);

            return selectedKeys;
          }}
          onCancel={() => {
            setBatchAddVisible(false);
          }}
        />
      )}
    </div>
  );
}
