import React, { useEffect, useState } from 'react';
import { DataFormLayout } from '@blacklake-web/layout';
import { Input } from 'antd';
import _ from 'lodash';
import BaseInfo from './base';
import ResultInfo from './result';
import CheckItemInfo from '../checkItem/list';
import { CheckTypeEnum, RecordSampleEnum, CheckEntityTypeEnum } from 'src/dict/quality';
import { injectCustomFieldInfos } from 'src/components/customField';
import { ICustomFields } from 'src/components/customField/interface';
interface BaseTabProps {
  form: any;
  isEdit: boolean;
  checkType: CheckTypeEnum;
  recordSample: RecordSampleEnum;
  checkEntityType: CheckEntityTypeEnum;
  formProps: any;
  customFields: ICustomFields;
}

const ActionContent = (props: BaseTabProps) => {
  const { form, isEdit, checkType, recordSample, formProps, checkEntityType, customFields } = props;

  const info = _.compact([
    BaseInfo({ form, checkType, checkEntityValue: checkEntityType, isEdit }),
    injectCustomFieldInfos({
      customFields: customFields || {},
      type: 'form',
      formConfig: { form },
    }),
    ResultInfo({ form, recordSample }),
    CheckItemInfo({ form }),
    isEdit && {
      title: '',
      items: [
        {
          label: '编辑原因',
          name: 'editReason',
          isFullLine: true,
          rules: [{ max: 1000, message: '不超过1000字符' }],
          render: () => <Input.TextArea placeholder="请输入编辑原因" showCount maxLength={1000} />,
        },
      ],
    },
  ]);

  return (
    <div style={{ overflow: 'auto' }}>
      <DataFormLayout info={info} form={form} formProps={formProps} footer={false} />
    </div>
  );
};

export default ActionContent;
