import { useState } from 'react';
import { Select } from 'antd';

import UserOrDepartModal from './index';
import {
  UserSelectWithDialogProps,
  LabelInValueSelectorProps,
  ListItem,
  SimpleUserItem,
} from './index.d';

/** LabeldValue to new format */
const lb2nf = (v: LabelInValueSelectorProps) => ({
  id: +v.value,
  name: v.label,
  avatarUrl: null,
});
/** new format to LabeldValue */
const nf2lb = (v: SimpleUserItem) => ({
  label: v.name,
  value: v.id,
});

export default function UserOrDepartmentSelectWithDialog<VT>(props: UserSelectWithDialogProps<VT>) {
  const [showSelectModal, setShowSelectModal] = useState<boolean>(false);
  const {
    isMultiple,
    isSelectUser,
    placeholder = '请选择',
    maxTagCount,
    value = [],
    onChange,
    isNewFormat,
    defaultChecked,
    disabled = false,
    userActives,
  } = props;

  /**
   * 格式化 form 数据到 Select组件
   */
  const formatFormToSelect = (value: LabelInValueSelectorProps[] | SimpleUserItem[]) => {
    return isNewFormat
      ? (value as SimpleUserItem[])?.map(nf2lb)
      : (value as LabelInValueSelectorProps[]);
  };

  /**
   * 格式化 Select组件 数据到 form
   */
  const formatSelectToForm = (value: LabelInValueSelectorProps[]) => {
    return isNewFormat ? value?.map(lb2nf) : value;
  };

  /**
   * 格式化 form 数据到 UserSelectModal组件
   */
  const formatFormToUserSelectModal = (value: LabelInValueSelectorProps[] | SimpleUserItem[]) => {
    return (
      (isNewFormat
        ? (value as SimpleUserItem[])
        : (value as LabelInValueSelectorProps[])?.map(lb2nf)) ?? []
    );
  };

  /**
   * 格式化 UserSelectModal组件 数据到 form
   */
  const formatUserSelectModalToForm = (value: ListItem[]) => {
    return isNewFormat ? value : value?.map(nf2lb);
  };

  // Select组件 选择值变化, 如删除一项
  const handleSelectChange = (value: LabelInValueSelectorProps[]) => {
    onChange?.(formatSelectToForm(value));
  };

  /**
   * UserSelectModal组件 选择值变化
   */
  const handleUserSelectModalChange = (value: ListItem[]) => {
    onChange?.(formatUserSelectModalToForm(value));
  };

  return (
    <>
      <Select
        placeholder={placeholder}
        maxTagCount={maxTagCount}
        labelInValue
        allowClear
        mode={isMultiple ? 'multiple' : undefined}
        open={false}
        value={formatFormToSelect(value)}
        onClick={() => {
          if (!disabled) setShowSelectModal(true);
        }}
        onChange={handleSelectChange}
        disabled={disabled}
      />
      {showSelectModal && (
        <UserOrDepartModal
          isMultiple={isMultiple}
          isSelectUser={isSelectUser}
          visible={showSelectModal}
          onClose={() => setShowSelectModal(false)}
          submitData={handleUserSelectModalChange}
          choosedData={formatFormToUserSelectModal(value)}
          defaultChecked={defaultChecked}
          userActives={userActives}
        />
      )}
    </>
  );
}

export type { LabelInValueSelectorProps };
