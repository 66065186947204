import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ChartList from './components/ChartList';
import SummaryList from './components/SummaryList';
import Title from './components/Title';

const PurchaseWorkbench: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'workbench/fetchConfig' });
  }, []);

  return (
    <div
      style={{
        height: '100%',
        overflowY: 'auto',
        minWidth: 1200,
        padding: '26px 24px 12px',
        backgroundColor: '#fff',
      }}
    >
      <Title title={'采购管理'} />
      <SummaryList />
      <ChartList />
    </div>
  );
};

export default PurchaseWorkbench;
