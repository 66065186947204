/**
 * @mappings 销售订单 -值映射表
 */

import { TraceabilityType } from '.';
import { mappingsFactory } from '../utils';

/**
 * 工序业务配置下的追溯关系
 */
export const traceabilityType = mappingsFactory(
  ['单件顺序生产自动更新', TraceabilityType.SingleAutomatic],
  ['批量生产手动更新', TraceabilityType.batchManually],
  ['批量生产自动更新', TraceabilityType.batchAutomatic],
  ['按配比更新', TraceabilityType.matchingUpdates],
  ['无追溯关系', TraceabilityType.noTraceRelationship],
);
