/*
 * @Author: zhaolin
 * @Description: 侧边栏，包括前台，后台菜单
 */
import { Dropdown, Layout, Menu, Avatar, Space } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { gcArray } from 'utils';
import { BackendMenu, FrontendMenu, BlSubMenu, AppVersion, User } from 'src/page/app/appSider/menu';
import { ContextType } from 'src/page/app/constant';
import { FirstLoginStatus } from '../constant';
//
import styles from './styles.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { BlIcon, BlTooltip } from 'src/components';
import { isAdminUser } from 'src/page/organization/share/utils';
import FirstLogin from 'src/page/userSettings/password';
import _ from 'lodash';
import defaultAvatar from 'src/assets/default-avatar.png';
import type { MenuData } from 'src/route';
import { ResizableSider } from './menu/resizableSider';
import { getFirstLeaf } from '../share';
import volcano from 'src/utils/volcano';

interface SiderProps extends RouteComponentProps {
  type?: ContextType;
  menuData?: MenuData[];
  onSwitchContext?: (type: string) => void;
}

const { Sider } = Layout;
const SUB_MENU_MIN_WIDTH = 190;
const SUB_MENU_MAX_WIDTH = (() => {
  if (screen.width >= 1920) {
    return 520;
  } else if (screen.width <= 1280) {
    return 283;
  }
  return 400;
})();

const AppSider = (props: SiderProps) => {
  const { type = ContextType.FRONTEND, menuData, location, onSwitchContext, history } = props;
  const { avatarUrl, firstLoginStatus, roleList } = useSelector(
    (state: RootState) => state?.user.userInfo,
    shallowEqual,
  );
  const { userInfo } = useSelector((state: RootState) => state?.user, shallowEqual);
  const { url } = useSelector((state: RootState) => state?.logoDisplay, shallowEqual);
  const { pathname } = location;
  const pathRoot = pathname.split('/').slice(0, 2).join('/');
  const [menuRoot, setMenuRoot] = useState(menuData?.find((menu) => menu?.path === pathRoot));
  const [menuHover, setMenuHover] = useState<MenuData | null>();
  const [visible, setVisible] = useState<boolean>(
    !!isAdminUser(roleList) && firstLoginStatus === FirstLoginStatus.PASSWORD_NOT_CHANGED,
  ); // 管理员修改密码弹窗visible，从未修改密码时需要求修改密码

  const dispatch = useDispatch();
  const logout = useCallback(() => {
    volcano.webLogout();
    dispatch({ type: 'user/clearUser' });
  }, [dispatch]);

  const menu = (
    <Menu>
      <Menu.Item onClick={logout} icon={<BlIcon type="icontuichudenglu" />}>
        退出登录
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    setMenuRoot(menuData?.find((menu) => menu?.path === pathRoot));
  }, [location, menuData]);
  useEffect(() => {
    dispatch({
      type: 'logoDisplay/fetchInfoData',
    });
  }, []);

  const handleMenuItenHoverMove = _.debounce(() => {
    setMenuHover(null);
  }, 200);

  const renderMenuHeader = (width: number) => {
    return (
      <>
        <img width={60} src={type === ContextType.BACKEND ? url.logoUrl : url.logoUrlWhite} />
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <BlTooltip className={styles.name} text={url.displayName} width={width} />
        </div>
      </>
    );
  };

  const renderBackend = () => {
    return (
      <Sider theme="light" width={208}>
        <div className={styles.blMenu}>
          <div className={styles.backHeader}>{renderMenuHeader(114)}</div>
          <div className={styles.body}>
            <BackendMenu menuData={menuData} />
          </div>
          <div className={styles.footer}>
            <Dropdown overlay={menu} placement="topLeft" arrow>
              <Avatar size={40} src={avatarUrl || defaultAvatar} />
            </Dropdown>
            <div
              onClick={() => {
                onSwitchContext?.(ContextType.FRONTEND);
              }}
            >
              <Space style={{ marginTop: 10 }}>
                <BlIcon type="iconfanhui" />
                <span>返回前台</span>
              </Space>
            </div>
          </div>
        </div>
      </Sider>
    );
  };

  const renderFrontend = () => {
    // hover时显示hover的子菜单
    const subMenuData = menuHover ?? menuRoot;

    return (
      <div style={{ display: 'flex' }} onMouseLeave={handleMenuItenHoverMove}>
        <Sider className="dark-sider" width={124}>
          <div className={styles.blMenu}>
            <div className={styles.header} onMouseEnter={handleMenuItenHoverMove}>
              {renderMenuHeader(80)}
            </div>
            <div className={styles.body}>
              <FrontendMenu
                menuData={menuData}
                onSwitch={(item) => {
                  history.push(getFirstLeaf(item).path);
                }}
                onHover={(item) => {
                  setMenuHover(item);
                }}
              />
            </div>
            <div className={styles.footer} onMouseEnter={handleMenuItenHoverMove}>
              <User
                onSwitchContext={() => {
                  onSwitchContext?.(ContextType.BACKEND);
                }}
              />
              <AppVersion />
            </div>
          </div>
        </Sider>
        {!gcArray.isEmpty(subMenuData?.children) && (
          <ResizableSider
            defaultWidth={SUB_MENU_MIN_WIDTH}
            minWidth={SUB_MENU_MIN_WIDTH}
            maxWidth={SUB_MENU_MAX_WIDTH}
            theme="light"
            storageKey="submenu_width"
          >
            <BlSubMenu
              itemArray={subMenuData?.children!}
              path={pathname}
              title={subMenuData?.name!}
              initCollapsed={subMenuData?.initCollapsed!}
            />
          </ResizableSider>
        )}
      </div>
    );
  };

  return (
    <>
      {type === ContextType.BACKEND ? renderBackend() : renderFrontend()}
      {visible && (
        <FirstLogin
          layout="modal"
          modalVisible={visible}
          userInfo={userInfo}
          onModalClose={() => setVisible(false)}
        />
      )}
    </>
  );
};

export default withRouter(AppSider);
