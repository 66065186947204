import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { Select, Tooltip, Button, Form, Empty, Tag } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import _ from 'lodash';

import { BlIcon } from 'src/components';
import {
  fetchResourcesDetail,
  FetchResourcesDetailResponse,
} from 'src/api/ytt/resource-domain/resource';
import {
  fetchInstrumentDetail,
  FetchInstrumentDetailResponse,
} from 'src/api/ytt/resource-domain/energyMeter';
import { fetchResourceMonitorSelectList } from 'src/api/ytt/resource-domain/resourceMonitor';
import { ResourceBusinessType, ParmTypeEnum } from 'src/dict/resources';
import { customEvents } from 'src/utils/events';
import { DetailResourceParamsType } from '../../definitions/index.d';
import { ResourceIdContext } from '../constants';
import MonitorDashboard from './MonitorDashboard';
import ChartViewHint from './ChartViewHint';
import Styles from './index.module.scss';

/**
 * 获取指定参数的全部参数选项列表
 * @param id 资源id
 * @param type 业务类型
 * @returns 指定资源的所有参数选项
 */
const fetchParamOptions = async (id: number, type: ResourceBusinessType) => {
  try {
    let response: FetchResourcesDetailResponse | FetchInstrumentDetailResponse;

    if (type === ResourceBusinessType.equipment) {
      response = await fetchResourcesDetail({ id });
    } else {
      response = await fetchInstrumentDetail({ id });
    }

    return (
      response?.data?.resourceParamsList
        // 参数类型不能是文字型
        ?.filter((item: DetailResourceParamsType) => item.paramsType !== ParmTypeEnum.text)
        .map((item: DetailResourceParamsType) => {
          const { paramsId, paramsName } = item;

          return {
            value: paramsId!,
            label: paramsName!,
          };
        }) || []
    );
  } catch (err) {
    console.log(err);
    return [];
  }
};

/**
 * 获取当前用户指定资源的历史资源选择结果
 * @param deviceId 资源id
 * @returns
 */
const fetchUserSelection = async (deviceId: number) => {
  try {
    const response = await fetchResourceMonitorSelectList({ deviceId });

    return response.data?.selectedParamIds;
  } catch (err) {
    console.log(err);
    return null;
  }
};

/**
 *
 * @param deviceId 资源id
 * @param paramIds 当前用户选中的参数数组
 */
const updateUserSelection = _.debounce(async (deviceId: number, paramIds: number[]) => {
  try {
    await fetchResourceMonitorSelectList({
      deviceId,
      selectingParamIds: paramIds,
    });
  } catch (err) {
    console.log(err);
  }
}, 2000);

interface ChartViewProps {
  businessType?: ResourceBusinessType;
}

export default function CharView({ businessType }: ChartViewProps) {
  const [selectedParams, setSelectedParams] = useState<Array<LabeledValue>>();
  const [selectOptions, setSelectOptions] = useState<Array<LabeledValue>>([]);
  const [refreshMarker, setRefreshMarker] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [mouseMoving, setMouseMoving] = useState(true);
  const { resourceId } = useContext(ResourceIdContext);
  const fullScreenRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!_.isNil(resourceId) && !_.isNil(businessType)) {
      // 加载全部选项和用户选项
      Promise.all([
        fetchParamOptions(resourceId, businessType),
        fetchUserSelection(resourceId),
      ]).then(([options, selections]) => {
        // 根据用户选项id获取完整选项信息
        const fullSelections = _.compact(
          selections?.map((selectedId) => _.find(options, { value: selectedId })),
        );

        // 把全部选项绑定到参数下拉框的选项上
        setSelectOptions(options.map((option) => ({ ...option, disabled: false })));
        // 把用户选项绑定到参数下拉框的value上
        setSelectedParams(fullSelections);
      });
    } else {
      setSelectOptions([]);
      setSelectedParams(undefined);
    }
  }, [resourceId]);

  const selectedParamsChanged = (selectedOptions: LabeledValue[]) => {
    setSelectedParams(selectedOptions);
    updateUserSelection(resourceId!, _.map(selectedOptions, 'value') as number[]);
  };

  // 至少选择一个
  const onDeselect = () => {
    if (selectedParams?.length === 1) {
      setSelectedParams(selectedParams);
    }
  };

  // 最多选择九个
  const onSelect = () => {
    if (selectedParams?.length === 9) {
      setSelectedParams(selectedParams);
    }
  };

  const refreshCharts = () => {
    setRefreshMarker(Date.now());
  };

  const startFullScreen = () => {
    const elem: any = fullScreenRef.current;

    if (elem?.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem?.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem?.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  const exitFullScreen = () => {
    // 避免ts报错
    const elem: any = document;

    if (elem.exitFullscreen) {
      elem.exitFullscreen();
    } else if (elem.webkitExitFullscreen) {
      /* Safari */
      elem.webkitExitFullscreen();
    } else if (elem.msExitFullscreen) {
      /* IE11 */
      elem.msExitFullscreen();
    }
  };

  const displayExitIcon = () => {
    // 重置mouseMoving状态
    setMouseMoving(true);
    stopMouseMoving();
  };

  const onDashboardMouseMove = () => {
    if (isFullScreen) {
      displayExitIcon();
    }
  };

  const stopMouseMoving = useCallback(
    _.debounce(() => {
      setMouseMoving(false);
    }, 3000),
    [],
  );

  useEffect(() => {
    document.body.addEventListener('fullscreenchange', () => {
      setIsFullScreen(!!document.fullscreenElement);
      customEvents.emit('resize');
      if (document.fullscreenElement) displayExitIcon();
    });
  }, []);

  return (
    <div className={Styles.resourceMonitorChart}>
      <div className={Styles.monitorHeader}>
        <Form.Item
          colon={false}
          style={{ marginBottom: 0, flex: 1, marginRight: 10 }}
          label={
            <>
              <span className={Styles.monitorHeaderLabel}>选择参数</span>
              <Tooltip title={<ChartViewHint />}>
                <BlIcon type="iconguanyu" />
              </Tooltip>
            </>
          }
        >
          <Select
            options={selectOptions}
            placeholder="请选择参数"
            mode="multiple"
            labelInValue
            maxTagCount="responsive"
            tagRender={(props) => {
              const { label, ...rest } = props;

              return (
                <Tag {...rest} closable={selectedParams!.length > 1}>
                  {label}
                </Tag>
              );
            }}
            value={selectedParams}
            onChange={selectedParamsChanged}
            onDeselect={onDeselect}
            onSelect={onSelect}
            allowClear={false}
            className={Styles.monitorHeaderSelector}
          />
        </Form.Item>

        <Button type="primary" onClick={refreshCharts}>
          刷新
        </Button>
        <Button
          className={Styles.fullScreenIcon}
          icon={<BlIcon type="iconquanping" />}
          onClick={startFullScreen}
        />
      </div>
      <div
        className={`${Styles.monitorContent} ${isFullScreen ? Styles.fullscreen : ''} ${
          mouseMoving ? '' : Styles.mouseStop
        }`}
        ref={fullScreenRef}
        onMouseMove={onDashboardMouseMove}
      >
        {selectedParams?.length ? (
          <MonitorDashboard
            paramIds={_.map(selectedParams as [], 'value')}
            refreshMarker={refreshMarker}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="请选择参数" />
        )}
        {isFullScreen && (
          <Button
            className={Styles.exitFullScreenIcon}
            icon={<BlIcon type="iconquxiaoquanping" />}
            onClick={exitFullScreen}
          />
        )}
      </div>
    </div>
  );
}
