import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RecordListLayout } from 'layout';
import { map } from 'lodash';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import { filterList, triggerListCol } from './columns';
import { toCreate, toDetail, toEdit } from './navigation';
import {
  fetchMessageTemplateDisable,
  fetchMessageTemplateEnable,
  fetchMessageTemplatePageList,
} from 'src/api/ytt/trigger-domain';
import { batchRequest } from './utils';
import _Array from 'src/utils/dataTypes/array';
import _String from 'src/utils/dataTypes/string';
import { triggerColType } from './interface';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';

export default function TriggerRulesList() {
  const history = useHistory();
  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新页面
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  const [noticeTemplateId, setNoticeTemplateId] = useState<number>(0);

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  const mainMenu = [
    {
      title: '新建通知模板',
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.messagetemplate_add,
      onClick: () => {
        history.push(toCreate());
      },
      items: [],
    },
  ];

  const formatDataToQuery = (params: any) => {
    const {
      objectCodes,
      triggerRuleIds,
      code,
      name,
      messageTitle,
      messageContent,
      messageSubscription,
      remindMethod,
      remark,
      sorter,
      userIds,
      departmentIds,
      roleIds,
      ...rest
    } = params;

    const relParams = { ...rest };

    if (!_Array.isEmpty(objectCodes)) {
      relParams.objectCodes = map(objectCodes, 'key');
    }

    if (!_String.isEmpty(name)) {
      relParams.name = name;
    }
    if (!_String.isEmpty(code)) {
      relParams.code = code;
    }
    if (!_String.isEmpty(messageTitle)) {
      relParams.messageTitle = messageTitle;
    }
    if (!_String.isEmpty(messageContent)) {
      relParams.messageContent = messageContent;
    }
    if (!_String.isEmpty(remark)) {
      relParams.remark = remark;
    }

    if (!_Array.isEmpty(triggerRuleIds)) {
      relParams.triggerRuleIds = map(triggerRuleIds, 'key');
    }
    if (!_Array.isEmpty(messageSubscription)) {
      relParams.messageSubscription = messageSubscription;
    }
    if (!_Array.isEmpty(remindMethod)) {
      relParams.remindMethod = remindMethod;
    }
    if (!_Array.isEmpty(userIds)) {
      relParams.userIds = map(userIds, 'value');
    }
    if (!_Array.isEmpty(departmentIds)) {
      relParams.departmentIds = map(departmentIds, 'value');
    }
    if (!_Array.isEmpty(roleIds)) {
      relParams.roleIds = map(roleIds, 'value');
    }

    if (sorter) {
      relParams.sorter = map(sorter, (node: any) => {
        return {
          order: node?.order,
          field: node?.field,
        };
      });
    }

    return relParams;
  };

  const getOperationList = (record: triggerColType) => {
    return [
      {
        title: '查看',
        auth: authDict.messagetemplate_detail,
        onClick: () => {
          history.push(toDetail(record?.id as number));
        },
      },
      {
        title: '编辑',
        auth: authDict.messagetemplate_edit,
        disabled: record?.status,
        onClick: () => history.push(toEdit(record?.id as number)),
      },
      {
        title: record?.status ? '停用' : '启用',
        auth: authDict.messagetemplate_enable_disable,
        onClick: async () => {
          const storeApi = record?.status
            ? fetchMessageTemplateDisable
            : fetchMessageTemplateEnable;
          const storeDisplay = record?.status ? '停用' : '启用';

          batchRequest('single', storeApi, [record?.id], storeDisplay, refresh);
        },
      },
      {
        title: '操作记录',
        onClick: () => {
          setNoticeTemplateId(record.id);
          setVisibleLog(true);
        },
      },
    ];
  };

  const batchMenu = [
    {
      title: '启用',
      icon: <BlIcon type="iconqiyong" />,
      auth: authDict.messagetemplate_enable_disable,
      onClick: (onSuccess: any, onFail: any) => {
        batchRequest(
          'batch',
          fetchMessageTemplateEnable,
          selectedRowKeys,
          '启用',
          onSuccess,
          onFail,
        );
      },
    },
    {
      title: '停用',
      icon: <BlIcon type="icontingyong" />,
      auth: authDict.messagetemplate_enable_disable,
      onClick: (onSuccess: any, onFail: any) => {
        batchRequest(
          'batch',
          fetchMessageTemplateDisable,
          selectedRowKeys,
          '停用',
          onSuccess,
          onFail,
        );
      },
    },
  ];

  return (
    <>
      <RecordListLayout<triggerColType>
        userAuth={getAuthFromLocalStorage()}
        columns={triggerListCol(history)}
        rowKey={(record: triggerColType) => record?.id}
        placeholder={'输入通知模板编号、名称'}
        selectedRowKeys={selectedRowKeys}
        configcacheKey="triggerRulesList"
        onSelectedRowKeys={(selectKey: any) => {
          setSelectRowKeys(selectKey);
        }}
        batchMenu={batchMenu}
        mainMenu={mainMenu}
        formatDataToQuery={formatDataToQuery}
        filterList={filterList}
        requestFn={fetchMessageTemplatePageList}
        getOperationList={getOperationList}
        refreshMarker={refreshMarker}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          closeDetail={() => {
            setVisibleLog(false);
          }}
          visible={visibleLog}
          instanceId={noticeTemplateId}
          objectCode={'MessageTemplate'}
        />
      )}
    </>
  );
}
