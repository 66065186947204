import { BlNotificationProps } from './BlNotification.d';
import { notification } from 'antd';

const ErrorNotification = (props: BlNotificationProps) => {
  const { description, message, style, ...rest } = props;

  const errorStyle = { backgroundColor: '#FFF1F0', border: '2px solid #FFA39E' };

  return notification.error({
    message,
    description,
    style: errorStyle ?? style,
    ...rest,
  });
};

export { ErrorNotification };
