import InvoiceDetailList from './list/detailList';
import InvoiceDocumentsList from './list/documentList';
import { TabList } from 'src/components';
import { InvoiceViewList } from 'src/dict/invoice';

const InvoiceList = () => {
  const tabList = [
    {
      tab: '按单据展示',
      key: InvoiceViewList.document,
      component: <InvoiceDocumentsList />,
    },
    {
      tab: '按明细展示',
      key: InvoiceViewList.detail,
      component: <InvoiceDetailList />,
    },
  ];

  return <TabList tabList={tabList} defaultKey={InvoiceViewList.document} />;
};

export default InvoiceList;
