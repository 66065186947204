import React, { useState } from 'react';
import _ from 'lodash';
import { Form, FormInstance, Input, InputNumber } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { BatchOperateTableHeader, BlSortFormList, SearchSelect } from 'src/components';
import lookup, { appEnum } from 'src/dict';
import { numberLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators';
//
import BatchParamSelector from '../../definitions/components/BatchParamSelector';
import { getNumberFromInput } from '../../definitions/utils';
import {
  EnergyMeterCreateApiType,
  EnergyMeterDetailType,
  IEnergyMeterForm,
  IEnergyParameterForm,
} from '../index.d';
import { gitUniqueList } from '../utils';

const MAX_COUNT = 100;

interface IParamsValue {
  id: number;
  name?: string;
  code?: string;
  paramsType: number;
  unitId?: number;
  unitName?: string;
}

export const formatDataToApi = (
  data: IEnergyParameterForm[],
  type?: string,
): EnergyMeterCreateApiType['resourceParamsList'] => {
  if (_.isEmpty(data)) return undefined;

  return _.map(data, (paramItem) => {
    const {
      id,
      param,
      paramsDownApi,
      paramsFloorLimit,
      paramsLowerLimit,
      paramsReadApi,
      paramsStandard,
      paramsTopLimit,
      paramsUpperLimit,
    } = paramItem;
    const paramObj: IParamsValue = JSON.parse(param?.value ?? '{}');

    return {
      id: type === appEnum.Common.CRUD.edit ? id : undefined,
      paramsDownApi,
      paramsFloorLimit,
      paramsLowerLimit,
      paramsReadApi,
      paramsStandard,
      paramsTopLimit,
      paramsUpperLimit,
      paramsId: paramObj?.id,
    };
  });
};

export const formatDataToForm = (
  data: EnergyMeterDetailType['resourceParamsList'],
): IEnergyParameterForm[] => {
  if (_.isEmpty(data)) return [];

  return (data ?? []).map(
    ({ paramsCode, paramsId, paramsName, paramsType, unitId, unitName, ...resData }) => {
      const paramsValue: IParamsValue = {
        id: paramsId,
        code: paramsCode,
        name: paramsName,
        paramsType,
        unitId,
        unitName,
      };

      return {
        ...resData,
        param: {
          label: paramsName,
          value: JSON.stringify(paramsValue),
        },
      };
    },
  );
};

interface FormListProps {
  name: string;
  form: FormInstance<IEnergyMeterForm>;
}

const FormList = (props: FormListProps) => {
  const { form, name } = props;

  const [paramSelectorVisible, setParamSelectorVisible] = useState(false);

  const getRowParamsValue = (field: FormListFieldData): IParamsValue => {
    const paramString = form.getFieldValue([name, field.name, 'param']);

    const paramValue: IParamsValue = JSON.parse(paramString?.value ?? '{}');

    return paramValue;
  };

  const handleSelectParamFinish = (selectKeys: any[], selectRows: any[]) => {
    if (_.isEmpty(selectKeys)) return;

    const curSelectData: IEnergyParameterForm[] = _.filter(
      form.getFieldValue(name),
      ({ param }) => !_.isNil(param),
    );

    const formatAddData = (data: any[]): IEnergyParameterForm[] => {
      const addData = _.map(data, (item) => {
        return {
          param: {
            label: item.name,
            value: JSON.stringify(item),
          },
        };
      });

      return addData;
    };

    const newData = {
      [name]: gitUniqueList<IEnergyParameterForm>({
        curList: curSelectData,
        addList: formatAddData(selectRows),
        maxCount: MAX_COUNT,
        getUniqueKey: ({ param = {} }) => {
          const { value = '{}' } = param;

          return JSON.parse(value)?.id;
        },
      }),
    };

    form.setFieldsValue(newData);
    setParamSelectorVisible(false);
  };

  /**
   * 校验标签/参数,不能重复
   */
  const validateDuplicated =
    (listField: string, field: string, message: string) => (rule: any, value: any) => {
      if (_.isNil(value)) return Promise.resolve('');
      const formList = form.getFieldValue(listField);

      if (_.isEmpty(formList)) return Promise.resolve('');

      const { id: curParamsId } = JSON.parse(value?.value ?? '{}');
      const keyList = formList?.map((formItem: any) => {
        const { id } = JSON.parse(formItem[field]?.value ?? '{}');

        return id;
      });

      const firstIndex = _.findIndex(keyList, (key) => key === curParamsId);
      const lastIndex = _.findLastIndex(keyList, (key) => key === curParamsId);

      if (firstIndex !== lastIndex) {
        return Promise.reject(message);
      }

      return Promise.resolve('');
    };

  const resetParams = (field: FormListFieldData) => {
    const formList = _.cloneDeep(form.getFieldValue(name));

    const resetFields = [
      'paramsDownApi',
      'paramsFloorLimit',
      'paramsLowerLimit',
      'paramsReadApi',
      'paramsStandard',
      'paramsTopLimit',
      'paramsUpperLimit',
    ];

    resetFields.forEach((fieldStr) => {
      _.set(formList[field.name], fieldStr, undefined);
    });

    return formList;
  };

  const handleParmasChange = (value: any, field: FormListFieldData) => {
    if (!_.isNil(value)) {
      const { type } = JSON.parse(value?.value ?? '{}');

      if (type === appEnum.Resources.ParmTypeEnum.text) {
        form.setFields([{ name, value: resetParams(field) }]);
      }
    }
  };

  return (
    <div>
      <BlSortFormList
        form={form}
        name={name}
        isNeedDrag={false}
        maxCount={MAX_COUNT}
        extraButton={[
          {
            title: '批量添加',
            onClick: () => {
              setParamSelectorVisible(true);
            },
          },
        ]}
        renderColumns={() => {
          return [
            {
              title: '能源仪表参数',
              width: 150,
              render: (text, field) => (
                <Form.Item
                  name={[field.name, 'param']}
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: '能源仪表参数不能为空',
                    },
                    {
                      validator: validateDuplicated(name, 'param', '不能选择重复的能源仪表参数'),
                    },
                  ]}
                >
                  <SearchSelect
                    placeholder="请选择设备参数"
                    fetchType="resourceParams"
                    params={{
                      businessType: appEnum.Resources.ResourceBusinessType.energy,
                      status: appEnum.Common.UsageStatus.enabled,
                    }}
                    onChange={(value) => handleParmasChange(value, field)}
                  />
                </Form.Item>
              ),
            },
            {
              title: '参数类型',
              width: 150,
              render: (text, field) => {
                return (
                  <Form.Item dependencies={[[name, field.name, 'param']]} noStyle>
                    {() => {
                      const paramString = form.getFieldValue([name, field.name, 'param']);
                      const paramValue = paramString && JSON.parse(paramString.value);

                      return (
                        <Input
                          disabled
                          value={lookup('resources.paramType', paramValue?.paramsType)}
                        />
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: '参数单位',
              width: 150,
              render: (text, field) => {
                return (
                  <Form.Item dependencies={[[name, field.name, 'param']]} noStyle>
                    {() => {
                      const paramString = form.getFieldValue([name, field.name, 'param']);
                      const paramValue = paramString && JSON.parse(paramString.value);

                      return <Input disabled value={paramValue?.unitName} />;
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: (
                <BatchOperateTableHeader
                  titleText="参数标准值"
                  popoverFieldComponent={<InputNumber style={{ width: '100%' }} />}
                  // batchOperation={(value) => {}}
                />
              ),
              width: 150,
              render: (text, field) => {
                return (
                  <Form.Item dependencies={[[name, field.name, 'param']]} noStyle>
                    {() => {
                      const { paramsType } = getRowParamsValue(field);

                      const disabled = paramsType === appEnum.Resources.ParmTypeEnum.text;

                      return (
                        <Form.Item
                          getValueFromEvent={getNumberFromInput}
                          name={[field.name, 'paramsStandard']}
                          style={{ marginBottom: 0 }}
                          rules={[{ validator: numberLengthCheck(9, 6) }]}
                        >
                          <Input disabled={disabled} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: (
                <BatchOperateTableHeader
                  titleText="参数上限"
                  popoverFieldComponent={<InputNumber style={{ width: '100%' }} />}
                  // batchOperation={(value) => {}}
                />
              ),
              width: 150,
              render: (text, field) => {
                return (
                  <Form.Item
                    dependencies={[
                      [name, field.name, 'param'],
                      [name, field.name, 'paramsStandard'],
                    ]}
                    noStyle
                  >
                    {() => {
                      const paramsStandard = form.getFieldValue([
                        name,
                        field.name,
                        'paramsStandard',
                      ]);

                      const { paramsType } = getRowParamsValue(field);

                      const disabled = paramsType === appEnum.Resources.ParmTypeEnum.text;

                      return (
                        <Form.Item
                          name={[field.name, 'paramsUpperLimit']}
                          getValueFromEvent={getNumberFromInput}
                          style={{ marginBottom: 0 }}
                          rules={[
                            { validator: numberLengthCheck(9, 6) },
                            {
                              validator: numberMinMaxCheck({
                                min: paramsStandard,
                                minAllowEqual: false,
                                message: '参数上限必须大于标准值',
                              }),
                            },
                          ]}
                        >
                          <Input disabled={disabled} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: (
                <BatchOperateTableHeader
                  titleText="参数下限"
                  popoverFieldComponent={<InputNumber style={{ width: '100%' }} />}
                  // batchOperation={(value) => {}}
                />
              ),
              width: 150,
              render: (text, field) => {
                return (
                  <Form.Item
                    dependencies={[
                      [name, field.name, 'param'],
                      [name, field.name, 'paramsStandard'],
                    ]}
                    noStyle
                  >
                    {() => {
                      const paramsStandard = form.getFieldValue([
                        name,
                        field.name,
                        'paramsStandard',
                      ]);
                      const { paramsType } = getRowParamsValue(field);

                      const disabled = paramsType === appEnum.Resources.ParmTypeEnum.text;

                      return (
                        <Form.Item
                          name={[field.name, 'paramsLowerLimit']}
                          getValueFromEvent={getNumberFromInput}
                          style={{ marginBottom: 0 }}
                          rules={[
                            { validator: numberLengthCheck(9, 6) },
                            {
                              validator: numberMinMaxCheck({
                                max: paramsStandard,
                                maxAllowEqual: false,
                                message: '参数下限必须小于标准值',
                              }),
                            },
                          ]}
                        >
                          <Input disabled={disabled} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: (
                <BatchOperateTableHeader
                  titleText="参数上上限"
                  popoverFieldComponent={<InputNumber style={{ width: '100%' }} />}
                  // batchOperation={(value) => {}}
                />
              ),
              width: 150,
              render: (text, field) => {
                return (
                  <Form.Item
                    dependencies={[
                      [name, field.name, 'param'],
                      [name, field.name, 'paramsUpperLimit'],
                    ]}
                    noStyle
                  >
                    {() => {
                      const upperLimit = form.getFieldValue([name, field.name, 'paramsUpperLimit']);
                      const { paramsType } = getRowParamsValue(field);

                      const disabled = paramsType === appEnum.Resources.ParmTypeEnum.text;

                      return (
                        <Form.Item
                          name={[field.name, 'paramsTopLimit']}
                          getValueFromEvent={getNumberFromInput}
                          style={{ marginBottom: 0 }}
                          rules={[
                            { validator: numberLengthCheck(9, 6) },
                            {
                              validator: numberMinMaxCheck({
                                min: upperLimit,
                                minAllowEqual: false,
                                message: '参数上上限必须大于参数上限',
                              }),
                            },
                          ]}
                        >
                          <Input disabled={disabled} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: (
                <BatchOperateTableHeader
                  titleText="参数下下限"
                  popoverFieldComponent={<InputNumber style={{ width: '100%' }} />}
                  // batchOperation={(value) => {}}
                />
              ),
              width: 150,
              render: (text, field) => {
                return (
                  <Form.Item
                    dependencies={[
                      [name, field.name, 'param'],
                      [name, field.name, 'paramsLowerLimit'],
                    ]}
                    noStyle
                  >
                    {() => {
                      const { paramsType } = getRowParamsValue(field);

                      const disabled = paramsType === appEnum.Resources.ParmTypeEnum.text;

                      const lowerLimit = form.getFieldValue([name, field.name, 'paramsLowerLimit']);

                      return (
                        <Form.Item
                          name={[field.name, 'paramsFloorLimit']}
                          getValueFromEvent={getNumberFromInput}
                          style={{ marginBottom: 0 }}
                          rules={[
                            { validator: numberLengthCheck(9, 6) },
                            {
                              validator: numberMinMaxCheck({
                                max: lowerLimit,
                                maxAllowEqual: false,
                                message: '参数下下限必须小于参数下限',
                              }),
                            },
                          ]}
                        >
                          <Input disabled={disabled} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                );
              },
            },
            {
              title: (
                <BatchOperateTableHeader
                  titleText="参数读取API"
                  popoverFieldComponent={<InputNumber style={{ width: '100%' }} />}
                  // batchOperation={(value) => {}}
                />
              ),
              width: 150,
              render: (text, field) => {
                return (
                  <Form.Item dependencies={[[name, field.name, 'param']]} noStyle>
                    {() => (
                      <Form.Item
                        name={[field.name, 'paramsReadApi']}
                        style={{ marginBottom: 0 }}
                        rules={[{ max: 255, message: '不可超过255字符' }]}
                      >
                        <Input />
                      </Form.Item>
                    )}
                  </Form.Item>
                );
              },
            },
            {
              title: (
                <BatchOperateTableHeader
                  titleText="参数下发API"
                  popoverFieldComponent={<InputNumber style={{ width: '100%' }} />}
                  // batchOperation={(value) => {}}
                />
              ),
              width: 150,
              render: (text, field) => {
                return (
                  <Form.Item dependencies={[[name, field.name, 'param']]} noStyle>
                    {() => (
                      <Form.Item
                        name={[field.name, 'paramsDownApi']}
                        style={{ marginBottom: 0 }}
                        rules={[{ max: 255, message: '不可超过255字符' }]}
                      >
                        <Input />
                      </Form.Item>
                    )}
                  </Form.Item>
                );
              },
            },
            {
              title: '',
              width: 1,
              render: (text, field) => (
                <Form.Item hidden name={[field.name, 'id']} noStyle>
                  <Input />
                </Form.Item>
              ),
            },
          ];
        }}
      />
      {paramSelectorVisible && (
        <BatchParamSelector
          visible={paramSelectorVisible}
          selectorType={appEnum.Resources.ResourceBusinessType.energy}
          onCancel={() => {
            setParamSelectorVisible(false);
          }}
          defaultQueryConditions={{
            businessType: appEnum.Resources.ResourceBusinessType.energy,
            status: appEnum.Common.UsageStatus.enabled,
          }}
          onFinish={handleSelectParamFinish}
          maxCount={MAX_COUNT}
        />
      )}
    </div>
  );
};

export default FormList;
