import React, { useState, useEffect } from 'react';
import { Table, Checkbox, Space, Input, Button } from 'antd';
import { AUTH_TYPE, statusEnum } from './constant';
import _ from 'lodash';
import { BlIcon } from 'src/components';

export interface DataTableProps<RecordType> {
  dataSource: RecordType[];
  disabled?: boolean;
  treeNode?: any;
  getChangeItems?: (prop: any) => void;
}

interface RecordProps {
  id: number;
  isCustom?: number;
  visible?: number;
  editable?: number;
}

// 两种权限 可见，编辑
const { VISIBLE, EDITABLE } = AUTH_TYPE;
// 四种状态，0，1，2，3
const { disable_uncheck, disable_check, enable_uncheck, enable_check } = statusEnum;

const isDisabled = (status?: number) => status === disable_uncheck || status === disable_check;
const isChecked = (status?: number) => status === disable_check || status === enable_check;

const DataTableForm = <RecordType extends object = any>(props: DataTableProps<RecordType>) => {
  const { disabled, treeNode, dataSource, getChangeItems, ...tableProps } = props;
  const [_dataSource, setDataSource] = useState<any>([]);
  const [changeItems, setChangeItems] = useState<any>({}); // 保存的数据
  const [indetVisible, setIndetVisible] = React.useState(true); // 可见列的indeterminate状态
  const [indetEdit, setIndetEdit] = React.useState(true); // 编辑列的indeterminate状态
  const [checkAllVisible, setCheckAllVisible] = React.useState(false); // 可见列的全选状态
  const [checkAllEdit, setCheckAllEdit] = React.useState(false); // 编辑列的全选状态

  // 设置全选checkbox的状态
  const setCheckBoxStatus = (field: string) => {
    const listEnable = _dataSource.filter((item: any) => !isDisabled(item[field]));
    const len = listEnable.filter((item: any) => isChecked(item[field])).length;
    const indeterminate = !!len && len < listEnable?.length;
    const checkAll = len === listEnable?.length;

    if (field === VISIBLE) {
      setIndetVisible(indeterminate);
      setCheckAllVisible(checkAll);
    } else {
      setIndetEdit(indeterminate);
      setCheckAllEdit(checkAll);
    }
  };

  // 全选
  const onCheckAllChange = (e: any, field: string) => {
    setDataSource(
      _dataSource?.map((item: any) => {
        // 跳过不可操作的复选框，以及跳过编辑列是选中状态的可见列
        if (isDisabled(item[field]) || (field === VISIBLE && isChecked(item[EDITABLE]))) {
          return item;
        }
        return {
          ...item,
          [field]: e.target.checked ? enable_check : enable_uncheck,
        };
      }),
    );
  };

  // 单个权限的checkbox
  const handleChange = (field: string, e?: any, record?: any) => {
    // 更新当前复选框状态
    setDataSource(
      _dataSource?.map((item: any) => {
        if (record.id === item.id) {
          return {
            ...item,
            [field]: e.target.checked ? enable_check : enable_uncheck,
          };
        }
        return item;
      }),
    );
    // 更新全选框状态
    setCheckBoxStatus(field);
  };

  const columns = [
    {
      dataIndex: 'name',
      title: '字段名称',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="搜索字段"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<BlIcon type="iconsousuo" />}
              size="small"
              style={{ width: 90 }}
            >
              搜索
            </Button>
            <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
              重置
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value: any, record: any) => {
        if (record.name) {
          return record.name.toString().toLowerCase().includes(value.toLowerCase());
        }
        return '';
      },
      filterIcon: (filtered: any) => (
        <BlIcon type="iconsousuo" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    },
    {
      dataIndex: 'visible',
      title: (
        <Space>
          <Checkbox
            indeterminate={indetVisible}
            checked={checkAllVisible}
            disabled={disabled}
            onChange={(e) => onCheckAllChange(e, VISIBLE)}
          />
          可见
        </Space>
      ),
      render: (t: any, record: RecordProps) => {
        return (
          <Checkbox
            disabled={disabled ?? (isDisabled(record.visible) || isChecked(record.editable))}
            checked={isChecked(record.visible) || isChecked(record.editable)}
            onChange={(e: any) => handleChange(VISIBLE, e, record)}
          />
        );
      },
    },
    {
      dataIndex: 'editable',
      title: (
        <Space>
          <Checkbox
            indeterminate={indetEdit}
            disabled={disabled}
            checked={checkAllEdit}
            onChange={(e) => onCheckAllChange(e, EDITABLE)}
          />
          编辑
        </Space>
      ),
      render: (t: any, record: RecordProps) => {
        return (
          <Checkbox
            disabled={disabled ?? isDisabled(record.editable)}
            checked={isChecked(record.editable)}
            onChange={(e: any) => handleChange(EDITABLE, e, record)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setCheckBoxStatus(VISIBLE);
    setCheckBoxStatus(EDITABLE);
    if (_dataSource.length) {
      setChangeItems({
        ...changeItems,
        [treeNode.key]: _dataSource,
      });
    }
  }, [_dataSource]);

  useEffect(() => {
    getChangeItems?.(changeItems);
  }, [changeItems]);

  useEffect(() => {
    setDataSource(_.cloneDeep(dataSource));
  }, [dataSource]);

  return (
    <Table
      scroll={{ y: 350 }}
      dataSource={_dataSource}
      rowKey={(row) => String(row.id)}
      columns={columns}
      {...tableProps}
    />
  );
};

export default DataTableForm;
