import { useState, useEffect, useMemo } from 'react';
import { RouterProps, Prompt } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  cloneDeep,
  debounce,
  isEmpty,
  get,
  unionBy,
  find,
  isEqual,
  omit,
  map,
  filter,
} from 'lodash';
import { Form, Checkbox, Tabs, Badge, Radio } from 'antd';
import { DataFormLayout } from 'src/layout';
import lookup, { appEnum } from 'src/dict';
import { RootState } from 'src/store';
import { YN } from 'src/dict/common';
import { yn } from 'src/dict/common/mappings';
import _Time from 'src/utils/dataTypes/time';
import { judgeMaterialIsVirtual } from 'src/entity/material';
import { SubItemMaterialForm } from 'src/page/knowledgeManagement/engineerData/bom/createAndEdit/subItemMaterialForm';
import { CoproductForm } from 'src/page/knowledgeManagement/engineerData/bom/createAndEdit/coproductForm';
import { ProcessRoutingForm } from 'src/page/knowledgeManagement/engineerData/processRouting/components';
import {
  injectCustomFieldInfos,
  formatCustomFieldsInData,
  initCustomFieldsInData,
  ICustomFields,
} from 'src/components/customField';

import {
  ProductionOrderTab,
  ProductionOrderTabMap,
  BOM_OUTPUT_MATERIALS,
  BOM_INPUT_MATERIALS,
  PROCESS_PLAN_CO,
} from '../constants';
import { PlanOrderRequest, PlanOrderForm } from './index.type';
import { OtherInfoForm } from './otherInfoForm';
import SourcesForm from './sourcesForm';

import baseInfo, {
  coproductExtraColumns,
  subItemMaterialExtraColumns,
} from './plannedOrderColumns';

import {
  formatProductionOrderOutputMaterialsToApi,
  formatProductionOrderInputMaterialsToApi,
  formatProcessPlanCOToApi,
  formatWorkOrderBaseCOToApi,
  formatWorkOrderSourceCOToApi,
  formatWorkOrderBaseCOToForm,
  formatBomOutputMaterialsToForm,
  formatBomInputMaterialsToForm,
  formatProcessPlanCOToForm,
  formatWorkOrderSourceCOToForm,
} from './utils';
import {
  formatMaterialToForm,
  setAssociatedFormFields,
} from 'src/page/knowledgeManagement/engineerData/bom/utils';
import { fetchSalesOrderListSimple } from 'src/api/ytt/order-domain/salesOrder';
import { arrayIsEmpty } from 'src/utils/array';
import { toPlannedOrderList } from '../navigation';

interface ProductionOrderFormProps extends RouterProps {
  type: string;
  onSubmit?: (value: any, cb?: () => void) => void;
  initialValue?: any;
  loading: boolean;
  customFields: ICustomFields;
}

interface TabList {
  key: ProductionOrderTab;
  errorCount: number;
}

const { TabPane } = Tabs;

/**
 * 格式化数据给form
 * @param value
 * @returns
 */
const formatValueToForm = (value: any): PlanOrderForm | undefined => {
  if (isEmpty(value)) return undefined;

  const {
    bomOutputMaterials,
    bomInputMaterials,
    processPlanCO,
    planOrderBaseCO,
    workOrderSource,
    type,
    ...resValue
  } = value ?? {};

  const baseType: {
    status?: number;
    pauseFlag?: number;
    operator?: string;
    creator?: string;
  } = {};

  /** 复制初始值 */
  if (type === appEnum.Common.CRUD.copy) {
    baseType.status = appEnum.ProductionPlanning.BusinessStatusEnum.CREATED;
    baseType.pauseFlag = appEnum.ProductionPlanning.SuspendedStatusEnum.NOTSUSPENDED;
    baseType.operator = undefined;
    baseType.creator = undefined;
  }
  return {
    ...resValue,
    planOrderBaseCO:
      planOrderBaseCO && formatWorkOrderBaseCOToForm({ ...planOrderBaseCO, ...baseType }),
    planOrderSourceCO: workOrderSource && formatWorkOrderSourceCOToForm(workOrderSource),
    processPlanCO: processPlanCO && formatProcessPlanCOToForm(processPlanCO),
    bomInputMaterials: bomInputMaterials && formatBomInputMaterialsToForm(bomInputMaterials),
    bomOutputMaterials: bomOutputMaterials && formatBomOutputMaterialsToForm(bomOutputMaterials),
    enableSop: processPlanCO?.enableSop ? processPlanCO?.enableSop : YN.no,
    customFields: planOrderBaseCO?.customFields,
  };
};

/**
 * 格式化数据给接口
 * @param value
 * @returns
 */
const formatValueToApi = (value: PlanOrderForm): PlanOrderRequest => {
  const {
    bomInputMaterials,
    processPlanCO,
    planOrderBaseCO,
    planOrderSourceCO,
    bomOutputMaterials,
    enableSop,
    type,
    customFields,
    operateReason,
  } = value;

  return {
    planOrderBaseCO: formatWorkOrderBaseCOToApi({ ...planOrderBaseCO, customFields }),
    bomOutputMaterials: formatProductionOrderOutputMaterialsToApi(bomOutputMaterials),
    bomInputMaterials: formatProductionOrderInputMaterialsToApi(bomInputMaterials),
    processPlanCO: formatProcessPlanCOToApi({ ...processPlanCO, enableSop, workOrderType: type }),
    planOrderSourceCO: formatWorkOrderSourceCOToApi(planOrderSourceCO),
    operateReason,
  };
};

/**
 * 格式化销售订单数据初始化界面
 * @param value
 * @returns
 */
const fmtSOToWO = async (value: any, form: any) => {
  if (!value) {
    return;
  }
  const { material, amount, orderCode, deliveryDate, lineNo } = value || {};

  await fetchSalesOrderListSimple({ code: orderCode }).then((res) => {
    const { data } = res;

    const recordItem = data?.list?.[0];
    const recordLine: any = recordItem?.items?.find((e) => e.lineNo === lineNo);

    form.setFieldsValue({
      bomOutputMaterials: [
        {
          materialId: formatMaterialToForm(material),
          plannedAmount: amount?.amount,
          lineSeq: 0,
          main: appEnum.Common.YN.yes,
        },
      ],
      workOrderSourceCO: {
        sourceSalesOrderList: [
          {
            salesOrderId: {
              label: orderCode,
              value: recordItem?.header?.id,
              key: recordItem?.header?.id,
            },
            salesOrderLine: {
              label: lineNo,
              value: JSON.stringify({
                materialId: recordLine?.material?.id,
                deliveryDate: recordLine?.deliveryDate,
              }),
              key: JSON.stringify({
                materialId: recordLine?.material?.id,
                deliveryDate: recordLine?.deliveryDate,
              }),
            },
            salesOrderDeliveryTime: _Time.format(deliveryDate),
          },
        ],
      },
    });
  });
};

const BaseForm = (props: ProductionOrderFormProps) => {
  const { type, history, onSubmit, initialValue, loading, customFields } = props;

  const currentUser = useSelector((state: RootState) => state.user.userInfo);

  const [keepCreate, setKeepCreate] = useState(false);

  const [sopEnable, setSopEnable] = useState(appEnum.Common.YN.no); // 是否启用sop 控制产出物料报工控件编号列

  const [tabList, setTabList] = useState<TabList[]>(
    Array.from(ProductionOrderTabMap.keys()).map((n) => ({ key: n, errorCount: 0 })), // tab列表和form错误数
  );
  const [activeTab, setActiveTab] = useState(
    Array.from(ProductionOrderTabMap.keys()).map((n) => `${n}`)[0],
  ); // 选中的tab
  const [isVirtualMaterial, setIsVirtualMaterial] = useState(false); // 父项物料业务范围是否是虚拟件

  const [form] = Form.useForm<PlanOrderForm>();

  const formProps = {
    preserve: true,
    initialValues: formatValueToForm(initialValue),
    scrollToFirstError: true,
  };

  const [hasSaved, setHasSaved] = useState(false);

  const hasUnsavedChange = form.isFieldsTouched() && !hasSaved;

  useEffect(() => {
    if ((initialValue && type === appEnum.Common.CRUD.edit) || type === appEnum.Common.CRUD.copy) {
      const afterFormatInitialValue: any = formatValueToForm({ ...initialValue, type }) ?? {};

      /** 初始化用来创建指定用料flag 控制用料清单显示 */

      if (afterFormatInitialValue?.processPlanCO?.enableSop) {
        setSopEnable(afterFormatInitialValue?.processPlanCO?.enableSop);
      }

      // 把需要格式化的数据一次传入 initCustomFieldsInData 中，格式化内所有自定义字段，也可分步处理
      const afterFormatCustomFielsValue = initCustomFieldsInData(afterFormatInitialValue);

      setIsVirtualMaterial(judgeMaterialIsVirtual(initialValue?.material));

      form.setFieldsValue(afterFormatCustomFielsValue);
    }
  }, [initialValue, type]);

  /**
   * 从销售订单创建订单 初始化数据
   */
  useEffect(() => {
    // 销售订单创建订单;
    if (history.location.state && type === appEnum.Common.CRUD.create) {
      fmtSOToWO(history.location.state, form);
    }

    // 工序计划初始框 + 产出物料主产出
    if (type === appEnum.Common.CRUD.create) {
      let planningDepartment: any = [];

      if (!arrayIsEmpty(currentUser?.departmentVOList)) {
        planningDepartment = [
          {
            label: currentUser?.departmentVOList?.[0]?.name,
            value: currentUser?.departmentVOList?.[0]?.id,
          },
        ];
      }

      form.setFields([
        {
          name: [BOM_OUTPUT_MATERIALS],
          value: [{}],
        },
        {
          name: ['planOrderBaseCO', 'planningUserId'],
          value: [{ label: currentUser.name, value: currentUser.id }],
        },
        {
          name: ['planOrderBaseCO', 'planningDepartmentId'],
          value: planningDepartment,
        },
      ]);
    }
  }, [history, type]);

  /**
   * 处理表单校验错误
   * @param error 不传时重置Tab错误数量
   */
  const handleFormError = (error?: unknown) => {
    const errorFields: { name: (string | number)[] }[] = get(error, 'errorFields', [{ name: [] }]);

    const newTabList: TabList[] = [];

    tabList.map(({ key }) => {
      let errorCount = 0;
      const tabField = ProductionOrderTabMap.get(key)?.filedName ?? '';

      if (!isEmpty(errorFields)) {
        unionBy([...errorFields], (item) => {
          if (find(item.name, (n) => isEqual(n, tabField))) errorCount++;
        });
      }

      newTabList.push({ key, errorCount });
    });

    setTabList(newTabList);
    setTimeout(form.validateFields);
  };

  /**
   * tab页切换
   */
  const handleTabChange = (activeKey: string) => {
    setActiveTab(activeKey);

    const { errorCount } = find(tabList, ['key', Number(activeKey)]) ?? {};

    !!errorCount && setTimeout(form.validateFields);
  };

  const handleFinish = async (draftFlag = false) => {
    form
      ?.validateFields()
      .then(async (res) => {
        Promise.all([formatValueToApi({ ...res, draftFlag, type })])
          .then((afterFormat) => {
            const value = formatCustomFieldsInData({
              data: cloneDeep(afterFormat[0]),
              customFields,
            });

            if (typeof onSubmit === 'function') {
              onSubmit(value, () => {
                if (keepCreate) {
                  form.resetFields();
                } else if (type === appEnum.Common.CRUD.edit) {
                  history.goBack();
                } else {
                  history.push(toPlannedOrderList());
                }
              });
              setHasSaved(true);
            }
          })
          .catch((err) => {
            console.log('err: ', err);
          });
      })
      .catch(async (error) => {
        const { errorFields } = error;

        form.scrollToField(errorFields?.[0]?.name);

        handleFormError(error);
      });
  };

  /**
   * 是否启用sop
   * 启用停用都清空相关的值
   * */
  const enableSopChange = (ele: any) => {
    const list = form.getFieldValue([PROCESS_PLAN_CO, 'processes']);

    setSopEnable(ele.target.value);

    if (ele.target.value) {
      form.setFieldsValue({
        processPlanCO: {
          processes: list.map((item: any) => ({ ...omit(item, 'reportIds') })),
        },
      });
    }

    // 用料清单
    const bomInputMaterials = cloneDeep(form.getFieldValue([BOM_INPUT_MATERIALS]));
    // 产出物料
    const bomOutputMaterials = cloneDeep(form.getFieldValue([BOM_OUTPUT_MATERIALS]));
    // 工序
    const processPlanCOProcesses = cloneDeep(form.getFieldValue([PROCESS_PLAN_CO, 'processes']));

    /** 清空用料清单投料管控 投料控件编号 */
    const copyBomInputMaterials = setAssociatedFormFields(bomInputMaterials, [
      ['inputProcessId', undefined],
      [
        'bomFeedingControls',
        (value: any) => setAssociatedFormFields(value, [['inputSopControlId']]),
      ],
    ]);

    /** 清空产出物料 投料控件编号 */
    const copyBomOutputMaterials = setAssociatedFormFields(bomOutputMaterials, [
      ['outputProcessId', undefined],
      ['outputSopControlId', undefined],
    ]);

    /** 清空产出物料 投料控件编号 */
    const copyProcessPlanCO = setAssociatedFormFields(processPlanCOProcesses, [['sop', undefined]]);

    form.setFields([
      {
        name: [BOM_INPUT_MATERIALS],
        value: copyBomInputMaterials,
      },
      {
        name: [BOM_OUTPUT_MATERIALS],
        value: copyBomOutputMaterials,
      },
    ]);

    form.setFieldsValue({
      processPlanCO: {
        processes: copyProcessPlanCO,
      },
    });
  };

  const coproductExtra = useMemo(
    () =>
      coproductExtraColumns({
        form,
        name: ProductionOrderTabMap.get(ProductionOrderTab.COPRODUCT)?.filedName ?? '',
        type,
        setSopEnable,
        sopEnable,
      }),
    [form, type, setSopEnable, sopEnable],
  );

  const renderBottom = () => {
    const tabWithComponent = new Map([
      [
        ProductionOrderTab.COPRODUCT,
        <div style={{ paddingBottom: 24 }}>
          <CoproductForm
            form={form}
            name={ProductionOrderTabMap.get(ProductionOrderTab.COPRODUCT)?.filedName ?? ''}
            resetColumns={coproductExtra}
            fixedRowFn={(field: any, index: number) => {
              return index === 0;
            }}
            type={type}
          />
        </div>,
      ],
      [
        ProductionOrderTab.SUB_ITEM_MATERIAL,
        <div style={{ paddingBottom: 24 }}>
          <SubItemMaterialForm
            form={form}
            name={ProductionOrderTabMap.get(ProductionOrderTab.SUB_ITEM_MATERIAL)?.filedName ?? ''}
            resetColumns={subItemMaterialExtraColumns({
              form,
              name:
                ProductionOrderTabMap.get(ProductionOrderTab.SUB_ITEM_MATERIAL)?.filedName ?? '',
              type,
            })}
            dependenciesFieldPath={{
              bomMaterial: [BOM_OUTPUT_MATERIALS, 0, 'materialId'],
              processRoute: [BOM_OUTPUT_MATERIALS, 0, 'processRouteId'],
              sop: [BOM_OUTPUT_MATERIALS, 0, 'outputProcessId'],
            }}
            type={type}
            useType={2}
          />
        </div>,
      ],
      [
        ProductionOrderTab.PROCESS_PLAN,
        <Form form={form} labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
          <Form.Item
            dependencies={[[BOM_OUTPUT_MATERIALS, 0, 'processRouteId']]}
            label={'工艺路线编号:'}
          >
            {() => form.getFieldValue([PROCESS_PLAN_CO, 'workOrderProcessCode'])}
          </Form.Item>
          <Form.Item
            dependencies={[[BOM_OUTPUT_MATERIALS, 0, 'processRouteId']]}
            label={'工艺路线名称:'}
          >
            {() => form.getFieldValue([PROCESS_PLAN_CO, 'workOrderProcessName'])}
          </Form.Item>
          <Form.Item
            dependencies={[[BOM_OUTPUT_MATERIALS, 0, 'processRouteId']]}
            label={'工艺路线备注:'}
          >
            {() => form.getFieldValue([PROCESS_PLAN_CO, 'workOrderProcessRemark'])}
          </Form.Item>
          <Form.Item dependencies={[[BOM_OUTPUT_MATERIALS, 0, 'processRouteId']]}>
            {() => (
              <Form.Item
                name={'enableSop'}
                label={'启用SOP:'}
                initialValue={YN.no}
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 20 }}
              >
                <Radio.Group
                  onChange={(ele) => {
                    enableSopChange(ele);
                  }}
                >
                  {yn.map(({ label, value }) => (
                    <Radio key={value} value={value}>
                      {label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            name={PROCESS_PLAN_CO}
            initialValue={{
              processes: [{ processSeq: 1, processNum: '10' }],
            }}
          >
            <ProcessRoutingForm name="processPlanCO" form={form} isWorkOrder={2} useType={2} />
          </Form.Item>
        </Form>,
      ],
      [
        ProductionOrderTab.SOURCE,
        <SourcesForm
          form={form}
          name={ProductionOrderTabMap.get(ProductionOrderTab.SOURCE)?.filedName ?? ''}
        />,
      ],
      [ProductionOrderTab.OTHER, <OtherInfoForm form={form} name={'planOrderBaseCO'} />],
    ]);

    /**
     * 父项物料是虚拟件时，需过滤
     */
    const filterVirtual = (item: TabList) => {
      const disabledTabs = [ProductionOrderTab.COPRODUCT]; // 不支持父项物料为虚拟件的tab

      if (isVirtualMaterial) {
        return !disabledTabs.includes(item.key);
      }
      return true;
    };

    return (
      <Tabs type="card" activeKey={activeTab} onChange={handleTabChange} style={{ marginTop: 50 }}>
        {map(filter(tabList, filterVirtual), ({ key, errorCount }) => {
          const title = ProductionOrderTabMap.get(key)?.title;

          return (
            <TabPane
              key={key}
              tab={
                <Badge size="small" count={errorCount}>
                  {title}
                </Badge>
              }
              style={{ height: '100%' }}
              forceRender
            >
              {tabWithComponent.get(key)}
            </TabPane>
          );
        })}
      </Tabs>
    );
  };

  const renderExtra = () => {
    return (
      <>
        {type === appEnum.Common.CRUD.create && (
          <Checkbox
            onChange={() => {
              setKeepCreate(!keepCreate);
            }}
            checked={keepCreate}
          >
            连续新建
          </Checkbox>
        )}
      </>
    );
  };

  const baseInfoList = useMemo(
    () => baseInfo({ form, name: 'planOrderBaseCO', type }),
    [form, type],
  );

  return (
    <>
      <DataFormLayout
        form={form}
        loading={loading}
        // info={baseInfoList}
        info={[
          ...baseInfoList,
          injectCustomFieldInfos({
            customFields: customFields || {},
            type: 'form',
            formConfig: { form }, // form相关配置
          }),
        ]}
        title={`${lookup('crud', type) ?? ''}计划订单`}
        bottomContext={renderBottom()}
        extra={renderExtra()}
        onCancel={() => {
          history.goBack();
        }}
        onFinish={debounce(handleFinish, 1500)}
        formProps={formProps}
        bodyClassName="process-routing-action-form-body"
      />
      {hasUnsavedChange && <Prompt message="您当前的计划订单内容尚未保存，是否退出？" />}
    </>
  );
};

export default BaseForm;
export { formatValueToApi };
