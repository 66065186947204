/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../../request';

// makeRequest
function makeRequestRequired<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequestOptional<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData?: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequest<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const optional = makeRequestOptional<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  const required = makeRequestRequired<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  return (
    requestConfig.requestDataOptional ? optional : required
  ) as TRequestConfig['requestDataOptional'] extends true ? typeof optional : typeof required;
}

// Request
export type Request<TReqeustData, TRequestConfig extends RequestConfig, TRequestResult> =
  (TRequestConfig['requestDataOptional'] extends true
    ? (
        requestData?: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult
    : (
        requestData: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult) & {
    requestConfig: TRequestConfig;
  };

const mockUrl_0_7_4_0 = 'http://yapi.blacklake.tech/mock/735' as any;
const devUrl_0_7_4_0 = '' as any;
const prodUrl_0_7_4_0 = '' as any;
const dataKey_0_7_4_0 = undefined as any;

/**
 * 接口 [新建不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/79976) 的 **请求类型**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/create`
 * @更新时间 `2021-11-22 14:50:05`
 */
export interface FetchQcDefectReasonCreateRequest {
  /**
   * 名称
   */
  name: string;
  /**
   * 备注
   */
  remark?: string;
}

/**
 * 接口 [新建不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/79976) 的 **返回类型**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/create`
 * @更新时间 `2021-11-22 14:50:05`
 */
export interface FetchQcDefectReasonCreateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: number;
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [新建不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/79976) 的 **请求配置的类型**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/create`
 * @更新时间 `2021-11-22 14:50:05`
 */
type FetchQcDefectReasonCreateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_defect_reason/create',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [新建不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/79976) 的 **请求配置**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/create`
 * @更新时间 `2021-11-22 14:50:05`
 */
const fetchQcDefectReasonCreateRequestConfig: FetchQcDefectReasonCreateRequestConfig = {
  mockUrl: mockUrl_0_7_4_0,
  devUrl: devUrl_0_7_4_0,
  prodUrl: prodUrl_0_7_4_0,
  path: '/quality/domain/web/v1/qc_defect_reason/create',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_4_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcDefectReasonCreate',
};

/**
 * 接口 [新建不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/79976) 的 **请求函数**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/create`
 * @更新时间 `2021-11-22 14:50:05`
 */
export const fetchQcDefectReasonCreate = makeRequest<
  FetchQcDefectReasonCreateRequest,
  FetchQcDefectReasonCreateResponse,
  FetchQcDefectReasonCreateRequestConfig
>(fetchQcDefectReasonCreateRequestConfig);

/**
 * 接口 [删除不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/79982) 的 **请求类型**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/delete`
 * @更新时间 `2021-11-22 14:50:05`
 */
export interface FetchQcDefectReasonDeleteRequest {
  ids?: number[];
}

/**
 * 接口 [删除不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/79982) 的 **返回类型**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/delete`
 * @更新时间 `2021-11-22 14:50:05`
 */
export interface FetchQcDefectReasonDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {};
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [删除不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/79982) 的 **请求配置的类型**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/delete`
 * @更新时间 `2021-11-22 14:50:05`
 */
type FetchQcDefectReasonDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_defect_reason/delete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [删除不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/79982) 的 **请求配置**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/delete`
 * @更新时间 `2021-11-22 14:50:05`
 */
const fetchQcDefectReasonDeleteRequestConfig: FetchQcDefectReasonDeleteRequestConfig = {
  mockUrl: mockUrl_0_7_4_0,
  devUrl: devUrl_0_7_4_0,
  prodUrl: prodUrl_0_7_4_0,
  path: '/quality/domain/web/v1/qc_defect_reason/delete',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_4_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcDefectReasonDelete',
};

/**
 * 接口 [删除不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/79982) 的 **请求函数**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/delete`
 * @更新时间 `2021-11-22 14:50:05`
 */
export const fetchQcDefectReasonDelete = makeRequest<
  FetchQcDefectReasonDeleteRequest,
  FetchQcDefectReasonDeleteResponse,
  FetchQcDefectReasonDeleteRequestConfig
>(fetchQcDefectReasonDeleteRequestConfig);

/**
 * 接口 [查询不良原因详情↗](http://yapi.blacklake.tech/project/735/interface/api/79988) 的 **请求类型**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/detail`
 * @更新时间 `2021-11-22 14:50:05`
 */
export interface FetchQcDefectReasonDetailRequest {
  /**
   * 创建时间
   */
  createdAt?: string;
  creator?: {
    /**
     * web-User信息VO-头像
     */
    avatarUrl?: string;
    /**
     * web-User信息VO-编号
     */
    code?: string;
    /**
     * web-User信息VO-描述
     */
    desc?: string;
    /**
     * web-User信息VO-id
     */
    id?: number;
    /**
     * web-User信息VO-name
     */
    name?: string;
    /**
     * web-User信息VO-username
     */
    username?: string;
  };
  /**
   * id
   */
  id?: number;
  /**
   * 名称
   */
  name?: string;
  operator?: {
    /**
     * web-User信息VO-头像
     */
    avatarUrl?: string;
    /**
     * web-User信息VO-编号
     */
    code?: string;
    /**
     * web-User信息VO-描述
     */
    desc?: string;
    /**
     * web-User信息VO-id
     */
    id?: number;
    /**
     * web-User信息VO-name
     */
    name?: string;
    /**
     * web-User信息VO-username
     */
    username?: string;
  };
  /**
   * 备注
   */
  remark?: string;
  /**
   * 修改时间
   */
  updatedAt?: string;
}

/**
 * 接口 [查询不良原因详情↗](http://yapi.blacklake.tech/project/735/interface/api/79988) 的 **返回类型**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/detail`
 * @更新时间 `2021-11-22 14:50:05`
 */
export interface FetchQcDefectReasonDetailResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 创建时间
     */
    createdAt?: string;
    creator?: {
      /**
       * web-User信息VO-头像
       */
      avatarUrl?: string;
      /**
       * web-User信息VO-编号
       */
      code?: string;
      /**
       * web-User信息VO-描述
       */
      desc?: string;
      /**
       * web-User信息VO-id
       */
      id?: number;
      /**
       * web-User信息VO-name
       */
      name?: string;
      /**
       * web-User信息VO-username
       */
      username?: string;
    };
    /**
     * id
     */
    id?: number;
    /**
     * 名称
     */
    name?: string;
    operator?: {
      /**
       * web-User信息VO-头像
       */
      avatarUrl?: string;
      /**
       * web-User信息VO-编号
       */
      code?: string;
      /**
       * web-User信息VO-描述
       */
      desc?: string;
      /**
       * web-User信息VO-id
       */
      id?: number;
      /**
       * web-User信息VO-name
       */
      name?: string;
      /**
       * web-User信息VO-username
       */
      username?: string;
    };
    /**
     * 备注
     */
    remark?: string;
    /**
     * 修改时间
     */
    updatedAt?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [查询不良原因详情↗](http://yapi.blacklake.tech/project/735/interface/api/79988) 的 **请求配置的类型**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/detail`
 * @更新时间 `2021-11-22 14:50:05`
 */
type FetchQcDefectReasonDetailRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_defect_reason/detail',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [查询不良原因详情↗](http://yapi.blacklake.tech/project/735/interface/api/79988) 的 **请求配置**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/detail`
 * @更新时间 `2021-11-22 14:50:05`
 */
const fetchQcDefectReasonDetailRequestConfig: FetchQcDefectReasonDetailRequestConfig = {
  mockUrl: mockUrl_0_7_4_0,
  devUrl: devUrl_0_7_4_0,
  prodUrl: prodUrl_0_7_4_0,
  path: '/quality/domain/web/v1/qc_defect_reason/detail',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_4_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcDefectReasonDetail',
};

/**
 * 接口 [查询不良原因详情↗](http://yapi.blacklake.tech/project/735/interface/api/79988) 的 **请求函数**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/detail`
 * @更新时间 `2021-11-22 14:50:05`
 */
export const fetchQcDefectReasonDetail = makeRequest<
  FetchQcDefectReasonDetailRequest,
  FetchQcDefectReasonDetailResponse,
  FetchQcDefectReasonDetailRequestConfig
>(fetchQcDefectReasonDetailRequestConfig);

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79994) 的 **请求类型**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/pageList`
 * @更新时间 `2021-11-22 14:50:05`
 */
export interface FetchQcDefectReasonPageListRequest {
  /**
   * 排序规律：字段名 排序 ,隔开
   */
  orderBy?: string;
  /**
   * 请求页
   */
  page?: number;
  /**
   * 快速搜索条件(名称或备注)
   */
  quickSearch?: string;
  /**
   * 每页大小
   */
  size?: number;
}

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79994) 的 **返回类型**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/pageList`
 * @更新时间 `2021-11-22 14:50:05`
 */
export interface FetchQcDefectReasonPageListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
      /**
       * 备注
       */
      remark?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79994) 的 **请求配置的类型**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/pageList`
 * @更新时间 `2021-11-22 14:50:05`
 */
type FetchQcDefectReasonPageListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_defect_reason/pageList',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79994) 的 **请求配置**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/pageList`
 * @更新时间 `2021-11-22 14:50:05`
 */
const fetchQcDefectReasonPageListRequestConfig: FetchQcDefectReasonPageListRequestConfig = {
  mockUrl: mockUrl_0_7_4_0,
  devUrl: devUrl_0_7_4_0,
  prodUrl: prodUrl_0_7_4_0,
  path: '/quality/domain/web/v1/qc_defect_reason/pageList',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_4_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcDefectReasonPageList',
};

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/735/interface/api/79994) 的 **请求函数**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/pageList`
 * @更新时间 `2021-11-22 14:50:05`
 */
export const fetchQcDefectReasonPageList = makeRequest<
  FetchQcDefectReasonPageListRequest,
  FetchQcDefectReasonPageListResponse,
  FetchQcDefectReasonPageListRequestConfig
>(fetchQcDefectReasonPageListRequestConfig);

/**
 * 接口 [编辑不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/80000) 的 **请求类型**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/update`
 * @更新时间 `2021-11-22 14:50:05`
 */
export interface FetchQcDefectReasonUpdateRequest {
  /**
   * 业务id
   */
  id: number;
  /**
   * 名称
   */
  name: string;
  /**
   * 备注
   */
  remark?: string;
}

/**
 * 接口 [编辑不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/80000) 的 **返回类型**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/update`
 * @更新时间 `2021-11-22 14:50:05`
 */
export interface FetchQcDefectReasonUpdateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: number;
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [编辑不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/80000) 的 **请求配置的类型**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/update`
 * @更新时间 `2021-11-22 14:50:05`
 */
type FetchQcDefectReasonUpdateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/735',
    '',
    '',
    '/quality/domain/web/v1/qc_defect_reason/update',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [编辑不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/80000) 的 **请求配置**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/update`
 * @更新时间 `2021-11-22 14:50:05`
 */
const fetchQcDefectReasonUpdateRequestConfig: FetchQcDefectReasonUpdateRequestConfig = {
  mockUrl: mockUrl_0_7_4_0,
  devUrl: devUrl_0_7_4_0,
  prodUrl: prodUrl_0_7_4_0,
  path: '/quality/domain/web/v1/qc_defect_reason/update',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_7_4_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchQcDefectReasonUpdate',
};

/**
 * 接口 [编辑不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/80000) 的 **请求函数**
 *
 * @分类 [web-不良原因↗](http://yapi.blacklake.tech/project/735/interface/api/cat_11350)
 * @标签 `web-不良原因`
 * @请求头 `POST /quality/domain/web/v1/qc_defect_reason/update`
 * @更新时间 `2021-11-22 14:50:05`
 */
export const fetchQcDefectReasonUpdate = makeRequest<
  FetchQcDefectReasonUpdateRequest,
  FetchQcDefectReasonUpdateResponse,
  FetchQcDefectReasonUpdateRequestConfig
>(fetchQcDefectReasonUpdateRequestConfig);

/* prettier-ignore-end */
