/**
 * @mappings 物料清单(BOM)-值映射表
 */

import { PickMode, InputBoundType, FeedingControl, ReportingMethodsEnum } from '.';
import { mappingsFactory } from '../utils';

export const pickMode = mappingsFactory(
  ['不领料', PickMode.notPick],
  ['按需领料', PickMode.demandPick],
);

export const inputBoundType = mappingsFactory(
  ['固定值', InputBoundType.constant],
  ['比例', InputBoundType.percentage],
  ['不管控', InputBoundType.notControl],
);

export const feedingControl = mappingsFactory(
  ['必投', FeedingControl.yes],
  ['非必投', FeedingControl.no],
);

export const reportingMethods = mappingsFactory(
  ['扫码报工-合格', ReportingMethodsEnum.scanQualified],
  ['扫码报工-不合格', ReportingMethodsEnum.scanUnqualified],
  ['记录报工-合格', ReportingMethodsEnum.noteQualified],
  ['记录报工-不合格', ReportingMethodsEnum.noteUnqualified],
);
