/**
 *  @control 多行文本
 */
import { FC } from 'react';
import { Input } from 'antd';
import type { PreviewCompProps } from '../../types';

export const MultiLine: FC<PreviewCompProps> = ({ inputPrompt }) => {
  return (
    <Input.TextArea style={{ pointerEvents: 'none' }} tabIndex={-1} placeholder={inputPrompt} />
  );
};
