/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from '../../../request';

// makeRequest
function makeRequestRequired<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequestOptional<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const req = function (
    requestData?: TReqeustData,
    ...args: RequestFunctionRestArgs<typeof request>
  ) {
    return request<TResponseData>(prepare(requestConfig, requestData), ...args);
  };
  req.requestConfig = requestConfig;
  return req;
}
function makeRequest<TReqeustData, TResponseData, TRequestConfig extends RequestConfig>(
  requestConfig: TRequestConfig,
) {
  const optional = makeRequestOptional<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  const required = makeRequestRequired<TReqeustData, TResponseData, TRequestConfig>(requestConfig);
  return (
    requestConfig.requestDataOptional ? optional : required
  ) as TRequestConfig['requestDataOptional'] extends true ? typeof optional : typeof required;
}

// Request
export type Request<TReqeustData, TRequestConfig extends RequestConfig, TRequestResult> =
  (TRequestConfig['requestDataOptional'] extends true
    ? (
        requestData?: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult
    : (
        requestData: TReqeustData,
        ...args: RequestFunctionRestArgs<typeof request>
      ) => TRequestResult) & {
    requestConfig: TRequestConfig;
  };

const mockUrl_0_20_0_0 = 'http://yapi.blacklake.tech/mock/503' as any;
const devUrl_0_20_0_0 = '' as any;
const prodUrl_0_20_0_0 = '' as any;
const dataKey_0_20_0_0 = undefined as any;

/**
 * 接口 [全量查询↗](http://yapi.blacklake.tech/project/503/interface/api/71942) 的 **请求类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/allList`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseAllListRequest {}

/**
 * 接口 [全量查询↗](http://yapi.blacklake.tech/project/503/interface/api/71942) 的 **返回类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/allList`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseAllListResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: {
    /**
     * 编码
     */
    code: string;
    /**
     * id
     */
    id: number;
    /**
     * 节点层级
     */
    level: number;
    /**
     * 名称
     */
    name: string;
    /**
     * 子节点
     */
    sonStorage?: unknown[];
    /**
     * 仓储类型 0仓库 1区域 2仓位
     */
    storageType: number;
  }[];
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [全量查询↗](http://yapi.blacklake.tech/project/503/interface/api/71942) 的 **请求配置的类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/allList`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageWarehouseAllListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_warehouse/allList',
    undefined,
    string,
    string,
    true
  >
>;

/**
 * 接口 [全量查询↗](http://yapi.blacklake.tech/project/503/interface/api/71942) 的 **请求配置**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/allList`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageWarehouseAllListRequestConfig: FetchStorageWarehouseAllListRequestConfig = {
  mockUrl: mockUrl_0_20_0_0,
  devUrl: devUrl_0_20_0_0,
  prodUrl: prodUrl_0_20_0_0,
  path: '/storage/domain/web/v1/storage_warehouse/allList',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_20_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageWarehouseAllList',
};

/**
 * 接口 [全量查询↗](http://yapi.blacklake.tech/project/503/interface/api/71942) 的 **请求函数**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/allList`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageWarehouseAllList = makeRequest<
  FetchStorageWarehouseAllListRequest,
  FetchStorageWarehouseAllListResponse,
  FetchStorageWarehouseAllListRequestConfig
>(fetchStorageWarehouseAllListRequestConfig);

/**
 * 接口 [批量删除↗](http://yapi.blacklake.tech/project/503/interface/api/71948) 的 **请求类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkDelete`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseBulkDeleteRequest {
  /**
   * 仓库id列表
   */
  ids?: number[];
}

/**
 * 接口 [批量删除↗](http://yapi.blacklake.tech/project/503/interface/api/71948) 的 **返回类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkDelete`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseBulkDeleteResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 失败数量
     */
    failCount?: number;
    failDetailList?: {
      /**
       * 编码
       */
      code?: string;
      /**
       * 错误描述
       */
      errorDesc?: string;
      /**
       * 操作出现异常的记录id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 成功数量
     */
    successCount?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量删除↗](http://yapi.blacklake.tech/project/503/interface/api/71948) 的 **请求配置的类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkDelete`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageWarehouseBulkDeleteRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_warehouse/bulkDelete',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量删除↗](http://yapi.blacklake.tech/project/503/interface/api/71948) 的 **请求配置**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkDelete`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageWarehouseBulkDeleteRequestConfig: FetchStorageWarehouseBulkDeleteRequestConfig = {
  mockUrl: mockUrl_0_20_0_0,
  devUrl: devUrl_0_20_0_0,
  prodUrl: prodUrl_0_20_0_0,
  path: '/storage/domain/web/v1/storage_warehouse/bulkDelete',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_20_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageWarehouseBulkDelete',
};

/**
 * 接口 [批量删除↗](http://yapi.blacklake.tech/project/503/interface/api/71948) 的 **请求函数**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkDelete`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageWarehouseBulkDelete = makeRequest<
  FetchStorageWarehouseBulkDeleteRequest,
  FetchStorageWarehouseBulkDeleteResponse,
  FetchStorageWarehouseBulkDeleteRequestConfig
>(fetchStorageWarehouseBulkDeleteRequestConfig);

/**
 * 接口 [批量禁用↗](http://yapi.blacklake.tech/project/503/interface/api/71954) 的 **请求类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkDisable`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseBulkDisableRequest {
  /**
   * 仓库id列表
   */
  ids?: number[];
}

/**
 * 接口 [批量禁用↗](http://yapi.blacklake.tech/project/503/interface/api/71954) 的 **返回类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkDisable`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseBulkDisableResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 失败数量
     */
    failCount?: number;
    failDetailList?: {
      /**
       * 编码
       */
      code?: string;
      /**
       * 错误描述
       */
      errorDesc?: string;
      /**
       * 操作出现异常的记录id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 成功数量
     */
    successCount?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量禁用↗](http://yapi.blacklake.tech/project/503/interface/api/71954) 的 **请求配置的类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkDisable`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageWarehouseBulkDisableRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_warehouse/bulkDisable',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量禁用↗](http://yapi.blacklake.tech/project/503/interface/api/71954) 的 **请求配置**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkDisable`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageWarehouseBulkDisableRequestConfig: FetchStorageWarehouseBulkDisableRequestConfig =
  {
    mockUrl: mockUrl_0_20_0_0,
    devUrl: devUrl_0_20_0_0,
    prodUrl: prodUrl_0_20_0_0,
    path: '/storage/domain/web/v1/storage_warehouse/bulkDisable',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_20_0_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchStorageWarehouseBulkDisable',
  };

/**
 * 接口 [批量禁用↗](http://yapi.blacklake.tech/project/503/interface/api/71954) 的 **请求函数**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkDisable`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageWarehouseBulkDisable = makeRequest<
  FetchStorageWarehouseBulkDisableRequest,
  FetchStorageWarehouseBulkDisableResponse,
  FetchStorageWarehouseBulkDisableRequestConfig
>(fetchStorageWarehouseBulkDisableRequestConfig);

/**
 * 接口 [批量启用↗](http://yapi.blacklake.tech/project/503/interface/api/71960) 的 **请求类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkEnable`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseBulkEnableRequest {
  /**
   * 仓库id列表
   */
  ids?: number[];
}

/**
 * 接口 [批量启用↗](http://yapi.blacklake.tech/project/503/interface/api/71960) 的 **返回类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkEnable`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseBulkEnableResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 失败数量
     */
    failCount?: number;
    failDetailList?: {
      /**
       * 编码
       */
      code?: string;
      /**
       * 错误描述
       */
      errorDesc?: string;
      /**
       * 操作出现异常的记录id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 成功数量
     */
    successCount?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量启用↗](http://yapi.blacklake.tech/project/503/interface/api/71960) 的 **请求配置的类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkEnable`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageWarehouseBulkEnableRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_warehouse/bulkEnable',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量启用↗](http://yapi.blacklake.tech/project/503/interface/api/71960) 的 **请求配置**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkEnable`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageWarehouseBulkEnableRequestConfig: FetchStorageWarehouseBulkEnableRequestConfig = {
  mockUrl: mockUrl_0_20_0_0,
  devUrl: devUrl_0_20_0_0,
  prodUrl: prodUrl_0_20_0_0,
  path: '/storage/domain/web/v1/storage_warehouse/bulkEnable',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_20_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageWarehouseBulkEnable',
};

/**
 * 接口 [批量启用↗](http://yapi.blacklake.tech/project/503/interface/api/71960) 的 **请求函数**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkEnable`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageWarehouseBulkEnable = makeRequest<
  FetchStorageWarehouseBulkEnableRequest,
  FetchStorageWarehouseBulkEnableResponse,
  FetchStorageWarehouseBulkEnableRequestConfig
>(fetchStorageWarehouseBulkEnableRequestConfig);

/**
 * 接口 [批量锁定↗](http://yapi.blacklake.tech/project/503/interface/api/71966) 的 **请求类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkLock`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseBulkLockRequest {
  /**
   * 仓库id列表
   */
  ids?: number[];
}

/**
 * 接口 [批量锁定↗](http://yapi.blacklake.tech/project/503/interface/api/71966) 的 **返回类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkLock`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseBulkLockResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 失败数量
     */
    failCount?: number;
    failDetailList?: {
      /**
       * 编码
       */
      code?: string;
      /**
       * 错误描述
       */
      errorDesc?: string;
      /**
       * 操作出现异常的记录id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 成功数量
     */
    successCount?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量锁定↗](http://yapi.blacklake.tech/project/503/interface/api/71966) 的 **请求配置的类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkLock`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageWarehouseBulkLockRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_warehouse/bulkLock',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量锁定↗](http://yapi.blacklake.tech/project/503/interface/api/71966) 的 **请求配置**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkLock`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageWarehouseBulkLockRequestConfig: FetchStorageWarehouseBulkLockRequestConfig = {
  mockUrl: mockUrl_0_20_0_0,
  devUrl: devUrl_0_20_0_0,
  prodUrl: prodUrl_0_20_0_0,
  path: '/storage/domain/web/v1/storage_warehouse/bulkLock',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_20_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageWarehouseBulkLock',
};

/**
 * 接口 [批量锁定↗](http://yapi.blacklake.tech/project/503/interface/api/71966) 的 **请求函数**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkLock`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageWarehouseBulkLock = makeRequest<
  FetchStorageWarehouseBulkLockRequest,
  FetchStorageWarehouseBulkLockResponse,
  FetchStorageWarehouseBulkLockRequestConfig
>(fetchStorageWarehouseBulkLockRequestConfig);

/**
 * 接口 [批量解锁↗](http://yapi.blacklake.tech/project/503/interface/api/71972) 的 **请求类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkUnlock`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseBulkUnlockRequest {
  /**
   * 仓库id列表
   */
  ids?: number[];
}

/**
 * 接口 [批量解锁↗](http://yapi.blacklake.tech/project/503/interface/api/71972) 的 **返回类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkUnlock`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseBulkUnlockResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 失败数量
     */
    failCount?: number;
    failDetailList?: {
      /**
       * 编码
       */
      code?: string;
      /**
       * 错误描述
       */
      errorDesc?: string;
      /**
       * 操作出现异常的记录id
       */
      id?: number;
      /**
       * 名称
       */
      name?: string;
    }[];
    /**
     * 成功数量
     */
    successCount?: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [批量解锁↗](http://yapi.blacklake.tech/project/503/interface/api/71972) 的 **请求配置的类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkUnlock`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageWarehouseBulkUnlockRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_warehouse/bulkUnlock',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [批量解锁↗](http://yapi.blacklake.tech/project/503/interface/api/71972) 的 **请求配置**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkUnlock`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageWarehouseBulkUnlockRequestConfig: FetchStorageWarehouseBulkUnlockRequestConfig = {
  mockUrl: mockUrl_0_20_0_0,
  devUrl: devUrl_0_20_0_0,
  prodUrl: prodUrl_0_20_0_0,
  path: '/storage/domain/web/v1/storage_warehouse/bulkUnlock',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_20_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageWarehouseBulkUnlock',
};

/**
 * 接口 [批量解锁↗](http://yapi.blacklake.tech/project/503/interface/api/71972) 的 **请求函数**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/bulkUnlock`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageWarehouseBulkUnlock = makeRequest<
  FetchStorageWarehouseBulkUnlockRequest,
  FetchStorageWarehouseBulkUnlockResponse,
  FetchStorageWarehouseBulkUnlockRequestConfig
>(fetchStorageWarehouseBulkUnlockRequestConfig);

/**
 * 接口 [校验能否修改'是否开启仓位管理'↗](http://yapi.blacklake.tech/project/503/interface/api/71978) 的 **请求类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/checkEnableStorageLocation`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseCheckEnableStorageLocationRequest {
  /**
   * 准备修改成的值，1代表启用仓位管理 0代表关闭
   */
  enableStorageLocation: number;
  /**
   * 仓库id
   */
  id: number;
}

/**
 * 接口 [校验能否修改'是否开启仓位管理'↗](http://yapi.blacklake.tech/project/503/interface/api/71978) 的 **返回类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/checkEnableStorageLocation`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseCheckEnableStorageLocationResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 校验结果
     */
    checkResult: boolean;
    /**
     * 校验失败原因
     */
    failReason?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [校验能否修改'是否开启仓位管理'↗](http://yapi.blacklake.tech/project/503/interface/api/71978) 的 **请求配置的类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/checkEnableStorageLocation`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageWarehouseCheckEnableStorageLocationRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_warehouse/checkEnableStorageLocation',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [校验能否修改'是否开启仓位管理'↗](http://yapi.blacklake.tech/project/503/interface/api/71978) 的 **请求配置**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/checkEnableStorageLocation`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageWarehouseCheckEnableStorageLocationRequestConfig: FetchStorageWarehouseCheckEnableStorageLocationRequestConfig =
  {
    mockUrl: mockUrl_0_20_0_0,
    devUrl: devUrl_0_20_0_0,
    prodUrl: prodUrl_0_20_0_0,
    path: '/storage/domain/web/v1/storage_warehouse/checkEnableStorageLocation',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_20_0_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchStorageWarehouseCheckEnableStorageLocation',
  };

/**
 * 接口 [校验能否修改'是否开启仓位管理'↗](http://yapi.blacklake.tech/project/503/interface/api/71978) 的 **请求函数**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/checkEnableStorageLocation`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageWarehouseCheckEnableStorageLocation = makeRequest<
  FetchStorageWarehouseCheckEnableStorageLocationRequest,
  FetchStorageWarehouseCheckEnableStorageLocationResponse,
  FetchStorageWarehouseCheckEnableStorageLocationRequestConfig
>(fetchStorageWarehouseCheckEnableStorageLocationRequestConfig);

/**
 * 接口 [创建↗](http://yapi.blacklake.tech/project/503/interface/api/71984) 的 **请求类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/create`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseCreateRequest {
  /**
   * 仓库类型 0车间仓库 1普通仓库
   */
  bizType: number;
  /**
   * 编码
   */
  code?: string;
  /**
   * 自定义字段
   */
  customFieldItems?: {
    /**
     * 字段id
     */
    id: number;
    /**
     * 字段值
     */
    value: {};
  }[];
  /**
   * 是否开启质量管控，0关 1开
   */
  enableQcCtrl: number;
  /**
   * 是否可以拥有仓位，0不可 1可
   */
  enableStorageLocation: number;
  /**
   * 名称
   */
  name: string;
  /**
   * 质量管控的范围,1合格 2让步合格 3待检 4不合格 5暂控
   */
  qcCtrlList?: number[];
  /**
   * 识别码
   */
  qrCode?: string;
  /**
   * 描述/备注
   */
  remark?: string;
}

/**
 * 接口 [创建↗](http://yapi.blacklake.tech/project/503/interface/api/71984) 的 **返回类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/create`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseCreateResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 数据
   */
  data?: number;
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [创建↗](http://yapi.blacklake.tech/project/503/interface/api/71984) 的 **请求配置的类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/create`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageWarehouseCreateRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_warehouse/create',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [创建↗](http://yapi.blacklake.tech/project/503/interface/api/71984) 的 **请求配置**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/create`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageWarehouseCreateRequestConfig: FetchStorageWarehouseCreateRequestConfig = {
  mockUrl: mockUrl_0_20_0_0,
  devUrl: devUrl_0_20_0_0,
  prodUrl: prodUrl_0_20_0_0,
  path: '/storage/domain/web/v1/storage_warehouse/create',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_20_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageWarehouseCreate',
};

/**
 * 接口 [创建↗](http://yapi.blacklake.tech/project/503/interface/api/71984) 的 **请求函数**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/create`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageWarehouseCreate = makeRequest<
  FetchStorageWarehouseCreateRequest,
  FetchStorageWarehouseCreateResponse,
  FetchStorageWarehouseCreateRequestConfig
>(fetchStorageWarehouseCreateRequestConfig);

/**
 * 接口 [获取仓库能否管控质量状态属性↗](http://yapi.blacklake.tech/project/503/interface/api/71990) 的 **请求类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/getEnableQcCtrl`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseGetEnableQcCtrlRequest {
  /**
   * 仓库id
   */
  id: number;
}

/**
 * 接口 [获取仓库能否管控质量状态属性↗](http://yapi.blacklake.tech/project/503/interface/api/71990) 的 **返回类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/getEnableQcCtrl`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseGetEnableQcCtrlResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 是否开启质量管控，1开启 0关闭
     */
    enableQcCtrl: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [获取仓库能否管控质量状态属性↗](http://yapi.blacklake.tech/project/503/interface/api/71990) 的 **请求配置的类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/getEnableQcCtrl`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageWarehouseGetEnableQcCtrlRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_warehouse/getEnableQcCtrl',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [获取仓库能否管控质量状态属性↗](http://yapi.blacklake.tech/project/503/interface/api/71990) 的 **请求配置**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/getEnableQcCtrl`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageWarehouseGetEnableQcCtrlRequestConfig: FetchStorageWarehouseGetEnableQcCtrlRequestConfig =
  {
    mockUrl: mockUrl_0_20_0_0,
    devUrl: devUrl_0_20_0_0,
    prodUrl: prodUrl_0_20_0_0,
    path: '/storage/domain/web/v1/storage_warehouse/getEnableQcCtrl',
    method: Method.POST,
    requestHeaders: {},
    requestBodyType: RequestBodyType.json,
    responseBodyType: ResponseBodyType.json,
    dataKey: dataKey_0_20_0_0,
    paramNames: [],
    queryNames: [],
    requestDataOptional: false,
    requestDataJsonSchema: {},
    responseDataJsonSchema: {},
    requestFunctionName: 'fetchStorageWarehouseGetEnableQcCtrl',
  };

/**
 * 接口 [获取仓库能否管控质量状态属性↗](http://yapi.blacklake.tech/project/503/interface/api/71990) 的 **请求函数**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/getEnableQcCtrl`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageWarehouseGetEnableQcCtrl = makeRequest<
  FetchStorageWarehouseGetEnableQcCtrlRequest,
  FetchStorageWarehouseGetEnableQcCtrlResponse,
  FetchStorageWarehouseGetEnableQcCtrlRequestConfig
>(fetchStorageWarehouseGetEnableQcCtrlRequestConfig);

/**
 * 接口 [查询详情↗](http://yapi.blacklake.tech/project/503/interface/api/71996) 的 **请求类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/info`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseInfoRequest {
  /**
   * 仓库id
   */
  id: number;
}

/**
 * 接口 [查询详情↗](http://yapi.blacklake.tech/project/503/interface/api/71996) 的 **返回类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/info`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseInfoResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 仓库类型 1普通仓库 0车间仓库
     */
    bizType: number;
    /**
     * 编号
     */
    code: string;
    /**
     * 自定义字段
     */
    customFieldItems?: {
      /**
       * 字段值
       */
      displayValue: {};
      /**
       * 字段code
       */
      fieldCode: string;
      /**
       * 字段id
       */
      fieldId: number;
      /**
       * 字段名称
       */
      fieldName: string;
    }[];
    /**
     * 状态 1启用 0禁用
     */
    enableFlag: number;
    /**
     * 是否启用质量管理 1启用0禁用
     */
    enableQcCtrl: number;
    /**
     * 标识码
     */
    enableStorageLocation: number;
    /**
     * id
     */
    id: number;
    /**
     * 是否锁定 1锁定 0未锁定
     */
    isLock: number;
    /**
     * 名称
     */
    name: string;
    /**
     * 管控的质量状态列表 1合格 2让步合格 3待检 4不合格 5暂控
     */
    qcCtrlList?: number[];
    /**
     * 标识码
     */
    qrCode?: string;
    /**
     * 备注
     */
    remark?: string;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [查询详情↗](http://yapi.blacklake.tech/project/503/interface/api/71996) 的 **请求配置的类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/info`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageWarehouseInfoRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_warehouse/info',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [查询详情↗](http://yapi.blacklake.tech/project/503/interface/api/71996) 的 **请求配置**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/info`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageWarehouseInfoRequestConfig: FetchStorageWarehouseInfoRequestConfig = {
  mockUrl: mockUrl_0_20_0_0,
  devUrl: devUrl_0_20_0_0,
  prodUrl: prodUrl_0_20_0_0,
  path: '/storage/domain/web/v1/storage_warehouse/info',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_20_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageWarehouseInfo',
};

/**
 * 接口 [查询详情↗](http://yapi.blacklake.tech/project/503/interface/api/71996) 的 **请求函数**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/info`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageWarehouseInfo = makeRequest<
  FetchStorageWarehouseInfoRequest,
  FetchStorageWarehouseInfoResponse,
  FetchStorageWarehouseInfoRequestConfig
>(fetchStorageWarehouseInfoRequestConfig);

/**
 * 接口 [编辑↗](http://yapi.blacklake.tech/project/503/interface/api/72002) 的 **请求类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/modify`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseModifyRequest {
  /**
   * 仓库类型 0车间仓库 1普通仓库
   */
  bizType: number;
  /**
   * 编码
   */
  code?: string;
  /**
   * 自定义字段
   */
  customFieldItems?: {
    /**
     * 字段id
     */
    id: number;
    /**
     * 字段值
     */
    value: {};
  }[];
  /**
   * 是否开启质量管控，0关 1开
   */
  enableQcCtrl: number;
  /**
   * 是否可以拥有仓位，0不可 1可
   */
  enableStorageLocation: number;
  /**
   * 仓库id
   */
  id: number;
  /**
   * 编辑原因
   */
  modifyReason?: string;
  /**
   * 名称
   */
  name: string;
  /**
   * 质量管控的范围,1合格 2让步合格 3待检 4不合格 5暂控
   */
  qcCtrlList?: number[];
  /**
   * 识别码
   */
  qrCode?: string;
  /**
   * 描述/备注
   */
  remark?: string;
}

/**
 * 接口 [编辑↗](http://yapi.blacklake.tech/project/503/interface/api/72002) 的 **返回类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/modify`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehouseModifyResponse {
  /**
   * 状态码
   */
  code: number;
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [编辑↗](http://yapi.blacklake.tech/project/503/interface/api/72002) 的 **请求配置的类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/modify`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageWarehouseModifyRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_warehouse/modify',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [编辑↗](http://yapi.blacklake.tech/project/503/interface/api/72002) 的 **请求配置**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/modify`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageWarehouseModifyRequestConfig: FetchStorageWarehouseModifyRequestConfig = {
  mockUrl: mockUrl_0_20_0_0,
  devUrl: devUrl_0_20_0_0,
  prodUrl: prodUrl_0_20_0_0,
  path: '/storage/domain/web/v1/storage_warehouse/modify',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_20_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageWarehouseModify',
};

/**
 * 接口 [编辑↗](http://yapi.blacklake.tech/project/503/interface/api/72002) 的 **请求函数**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/modify`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageWarehouseModify = makeRequest<
  FetchStorageWarehouseModifyRequest,
  FetchStorageWarehouseModifyResponse,
  FetchStorageWarehouseModifyRequestConfig
>(fetchStorageWarehouseModifyRequestConfig);

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/503/interface/api/72008) 的 **请求类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/pageList`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehousePageListRequest {
  /**
   * 仓库类型 0车间仓库 1普通仓库
   */
  bizType?: number;
  /**
   * 编号
   */
  code?: string;
  /**
   * 状态1启用0禁用
   */
  enableFlag?: number;
  /**
   * 是否开启质量管理
   */
  enableQcCtrl?: number;
  /**
   * 是否开启仓位管理
   */
  enableStorageLocation?: number;
  /**
   * 是否锁定1锁定0未锁定
   */
  isLock?: number;
  /**
   * 名称
   */
  name?: string;
  /**
   * 排序规律：字段名 排序 ,隔开
   */
  orderBy?: string;
  /**
   * 请求页
   */
  page?: number;
  /**
   * 质量状态 1合格 2让步合格 3待检 4不合格 5暂控
   */
  qcStatus?: number;
  /**
   * 快速查询
   */
  quickSearch?: string;
  /**
   * 每页大小
   */
  size?: number;
}

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/503/interface/api/72008) 的 **返回类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/pageList`
 * @更新时间 `2021-10-26 16:52:00`
 */
export interface FetchStorageWarehousePageListResponse {
  /**
   * 状态码
   */
  code: number;
  data?: {
    /**
     * 列表
     */
    list: {
      /**
       * 仓库类型 1车间仓库 0普通仓库
       */
      bizType: number;
      /**
       * 编码
       */
      code: string;
      /**
       * 自定义字段数据
       */
      customFieldItems?: {
        /**
         * 字段值
         */
        displayValue: {};
        /**
         * 字段code
         */
        fieldCode: string;
        /**
         * 字段id
         */
        fieldId: number;
        /**
         * 字段名称
         */
        fieldName: string;
      }[];
      /**
       * 仓库状态，1启用 0禁用
       */
      enableFlag: number;
      /**
       * 是否开启质量管控 1开启 0未开启
       */
      enableQcCtrl: number;
      /**
       * 是否开启仓位管理 1启用0禁用
       */
      enableStorageLocation: number;
      /**
       * 仓库id
       */
      id: number;
      /**
       * 是否锁定 1锁定 0未锁定
       */
      isLock: number;
      /**
       * 名称
       */
      name: string;
      /**
       * 质量状态 1合格 2让步合格 3待检 4不合格 5暂控
       */
      qcCtrlList?: number[];
      /**
       * 标识码
       */
      qrCode: string;
      /**
       * 备注
       */
      remark?: string;
    }[];
    /**
     * 当前页
     */
    page: number;
    /**
     * 总条数
     */
    total: number;
  };
  /**
   * 返回信息
   */
  message: string;
  /**
   * 错误码
   */
  subCode?: string;
}

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/503/interface/api/72008) 的 **请求配置的类型**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/pageList`
 * @更新时间 `2021-10-26 16:52:00`
 */
type FetchStorageWarehousePageListRequestConfig = Readonly<
  RequestConfig<
    'http://yapi.blacklake.tech/mock/503',
    '',
    '',
    '/storage/domain/web/v1/storage_warehouse/pageList',
    undefined,
    string,
    string,
    false
  >
>;

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/503/interface/api/72008) 的 **请求配置**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/pageList`
 * @更新时间 `2021-10-26 16:52:00`
 */
const fetchStorageWarehousePageListRequestConfig: FetchStorageWarehousePageListRequestConfig = {
  mockUrl: mockUrl_0_20_0_0,
  devUrl: devUrl_0_20_0_0,
  prodUrl: prodUrl_0_20_0_0,
  path: '/storage/domain/web/v1/storage_warehouse/pageList',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.json,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_20_0_0,
  paramNames: [],
  queryNames: [],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'fetchStorageWarehousePageList',
};

/**
 * 接口 [分页查询↗](http://yapi.blacklake.tech/project/503/interface/api/72008) 的 **请求函数**
 *
 * @分类 [仓库↗](http://yapi.blacklake.tech/project/503/interface/api/cat_10728)
 * @标签 `仓库`
 * @请求头 `POST /storage/domain/web/v1/storage_warehouse/pageList`
 * @更新时间 `2021-10-26 16:52:00`
 */
export const fetchStorageWarehousePageList = makeRequest<
  FetchStorageWarehousePageListRequest,
  FetchStorageWarehousePageListResponse,
  FetchStorageWarehousePageListRequestConfig
>(fetchStorageWarehousePageListRequestConfig);

/* prettier-ignore-end */
