import { Input, InputNumber, Select, DatePicker, Radio, FormInstance, TimePicker } from 'antd';
import _ from 'lodash';
import { fetchCustomObjectFuzzySearch } from 'src/api/ytt/custom-object-domain';
import { BcAttachmentForm, BlSearchSelect, SearchSelect } from 'src/components';
import { TargetType, FieldType, ObjectCategory } from 'src/dict/common';
import { ynb as ynbMappings } from 'src/dict/common/mappings';
import RoleSelect from 'src/page/organization/components/roleSelect';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import UseTextNumberRulesStandardHook from 'src/page/customObject/edit/useTextNumberRulesStandardHook';
import { validatorCharacterLen } from 'src/utils/formValidators';
import { getSelectableControlCascadingEnhancer } from 'src/page/custom_fields/fieldCascading/utils';
import { FieldCascadingDTO, FieldDTO, NamePath } from '../types';
import { judegFieldPermissionEditable, FieldPermission } from 'src/utils/auth/fieldPermission';

interface Options {
  /** 为框式输入控件（单行文本、数值、整数、单选、多选、日期）设置固定宽度 */
  inputWidth?: number;
  namePath?: (string | number)[];
  form?: FormInstance;
  isEdit?: boolean;
  isSonObj?: boolean;
  instancePath?: Options['namePath'];
  fieldCascadings?: FieldCascadingDTO[];
  getNamePathByFieldCode?: (fieldCode: string) => NamePath;
  instanceId?: any;
  // 所属对象的字段权限
  fieldsPermission?: FieldPermission[];
}

const TEXTAREA_HEIGHT = 22 * 3 + (4 + 1) * 2; // 行高22，展示3行；上/下padding 4，border 1
const SELECTABLE_FIELD_TYPE = [
  FieldType.select,
  FieldType.multiSelect,
  FieldType.date,
  FieldType.relation,
];

const getOptionsFromChoiceValues = (choiceValues: FieldDTO['choiceValues']) =>
  (choiceValues ?? []).map((item) => ({
    label: item.choiceValue!,
    value: item.choiceCode!,
    key: item.choiceCode!,
  }));

/** 字段输入控件的渲染方法，用于编辑页。不包括引用字段和主从关系(从对象) */
export const getInputControlRenderer = (field: FieldDTO, options: Options = {}) => {
  const {
    id,
    fieldType,
    fieldName,
    targetType,
    decimalNumber,
    choiceValues,
    datetimeFormat,
    reference,
    relatedObjectCategory,
    isRefer,
    fieldCode,
    isNumberRuleConfig,
    defaultValue,
    isRequired,
    maxLength,
  } = field;

  const {
    inputWidth,
    namePath,
    form,
    isEdit,
    isSonObj,
    instancePath,
    fieldCascadings = [],
    getNamePathByFieldCode = _.identity,
    fieldsPermission = [],
  } = options;

  let ctrl: React.ReactElement;
  const opName = SELECTABLE_FIELD_TYPE.includes(fieldType!) ? '选择' : '输入';
  // 是否有字段有编辑权限
  const fieldHasEditAuth = judegFieldPermissionEditable(fieldsPermission, fieldName, id);

  const ctrlProps: any = {
    placeholder: `请${opName}${fieldName}`,
    disabled: Boolean(isRefer) || !fieldHasEditAuth,
  };
  const isMultiple = targetType === TargetType.multiChoice;

  /** 获取基础规则 */
  const getBaseRule = () => {
    return isRequired ? { required: Boolean(isRequired), message: `${fieldName}不能为空` } : null;
  };

  const getTextRule = () => {
    const defaultLength = fieldType === FieldType.text ? 255 : 1000;

    return [{ validator: validatorCharacterLen(maxLength || defaultLength, fieldName) }];
  };

  switch (fieldType) {
    case FieldType.text:
      if (
        (isNumberRuleConfig === 1 && !isEdit && !_.isUndefined(isEdit)) ||
        (isNumberRuleConfig === 1 &&
          isSonObj &&
          isEdit &&
          !form?.getFieldValue(instancePath!)?.$instanceId)
      ) {
        ctrl = (
          <UseTextNumberRulesStandardHook
            form={form || ({} as FormInstance)}
            namePath={namePath}
            fieldCode={fieldCode!}
            isNumberRuleConfig
            defaultValue={defaultValue}
            rules={_.compact([getBaseRule(), ...getTextRule()])}
            isSonObj={isSonObj}
            disabled={ctrlProps.disabled}
          />
        );
      } else {
        ctrl = <Input {...ctrlProps} />;
      }
      break;
    case FieldType.url:
      ctrl = <Input {...ctrlProps} />;
      break;
    case FieldType.textArea:
      ctrlProps.style = { height: TEXTAREA_HEIGHT };
      ctrl = <Input.TextArea {...ctrlProps} />;
      break;
    case FieldType.number:
      if (inputWidth) {
        ctrlProps.style = { width: inputWidth };
      }
      ctrlProps.precision = decimalNumber;
      ctrl = <InputNumber {...ctrlProps} />;
      break;
    // @ts-ignore
    case FieldType.multiSelect:
      ctrlProps.mode = 'multiple';
    case FieldType.select:
      if (inputWidth) {
        ctrlProps.style = { width: inputWidth };
      }
      ctrlProps.options = getOptionsFromChoiceValues(choiceValues);
      ctrlProps.labelInValue = true;
      ctrlProps.allowClear = true;
      ctrl = <Select {...ctrlProps} />;
      break;
    case FieldType.boolean:
      ctrl = <Radio.Group {...ctrlProps} options={ynbMappings} />;
      break;
    case FieldType.integer:
      ctrlProps.precision = 0;
      if (inputWidth) {
        ctrlProps.style = { width: inputWidth };
      }
      ctrl = <InputNumber {...ctrlProps} />;
      break;
    case FieldType.date:
      if (inputWidth) {
        ctrlProps.style = { width: inputWidth };
      }
      ctrlProps.format = datetimeFormat;
      ctrlProps.showTime = datetimeFormat?.includes('HH');

      if (datetimeFormat === 'HH:mm:ss' || datetimeFormat === 'HHmmss') {
        ctrl = <TimePicker {...ctrlProps} />;
      } else {
        ctrl = <DatePicker {...ctrlProps} />;
      }
      break;
    case FieldType.appendix:
      ctrl = (
        <BcAttachmentForm
          {...ctrlProps}
          uploadConfig={{
            maxSize: 20,
            totalMaxSize: 200,
            limit: undefined,
          }}
        />
      );
      break;
    case FieldType.relation:
      switch (reference) {
        case 'User':
          ctrlProps.isSelectUser = true;
          ctrlProps.isNewFormat = true;
          ctrlProps.isMultiple = isMultiple;
          ctrl = <UserOrDepartmentSelectWithDialog {...ctrlProps} />;
          break;
        case 'Department':
          ctrlProps.isNewFormat = true;
          ctrlProps.isMultiple = isMultiple;
          ctrl = <UserOrDepartmentSelectWithDialog {...ctrlProps} />;
          break;
        case 'Role':
          ctrlProps.isMultiple = isMultiple;
          ctrl = <RoleSelect {...ctrlProps} />;
          break;
        case 'Document':
          ctrl = (
            <BcAttachmentForm
              uploadConfig={{
                maxSize: 20,
                totalMaxSize: 200,
                limit: undefined,
              }}
            />
          );
          break;
        default:
          ctrlProps.mode = isMultiple ? 'multiple' : undefined;
          // 如果关联对象是自定义对象, 走自定义对象统一的实例列表接口
          if (relatedObjectCategory === ObjectCategory.customObject) {
            ctrlProps.fetchType = 'customizedObject';
            ctrlProps.params = {
              objectCode: reference,
            };
            ctrl = <SearchSelect {...ctrlProps} />;
          } else {
            const selectFormatter = (res: any) => {
              return {
                options: res.data.map((i: { mainProperty: string; instanceId: number }) => {
                  return {
                    label: i.mainProperty,
                    value: i.instanceId,
                  };
                }),
                total: res.data.total,
              };
            };

            ctrl = (
              <BlSearchSelect
                placeholder="请选择"
                {...ctrlProps}
                fetchFn={(params: any) =>
                  fetchCustomObjectFuzzySearch({
                    ...params,
                    objectCode: reference,
                    search: params.searchParams,
                  })
                }
                formatter={selectFormatter}
                labelInValue
              />
            );
          }
      }
      break;
    default:
      break;
  }

  const relatedCascading = _.find(fieldCascadings, { refFieldCode: fieldCode });

  // 如果是受控字段，要根据级联关系过滤选项
  if (relatedCascading) {
    const enhanceSelectableControlWithCascading = getSelectableControlCascadingEnhancer({
      relatedCascading,
      fullOptions: getOptionsFromChoiceValues(choiceValues),
      form,
      fieldInfo: field,
      labelInValue: true,
      getNamePathByFieldCode,
    });

    return () => enhanceSelectableControlWithCascading(ctrl);
  }

  return () => ctrl;
};
