import React, { useEffect, useState } from 'react';
import { Form, message as Message } from 'antd';
import { match, RouteComponentProps } from 'react-router-dom';
import EditBaseInfo from './components/editBaseInfo';
import { QcCheckItemListType } from './interface';
import {
  fetchQcCheckItemCategoryDetail,
  fetchQcCheckItemCategoryUpdate,
} from 'src/api/ytt/quality-domain/checkClass';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';

interface StoreEditProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

const CheckItemClassificationEdit = (props: StoreEditProps) => {
  const { match, history } = props;
  const checkClassId = Number(match?.params?.id);
  const [detailData, setDetailData] = useState({} as any);
  const [checkSource, setCheckSource] = useState([] as any); // 关联检验项列表数据 因为没有form收集  所以需要一个变量和方法 实时拿到最新的关联检验项数据

  const [modalForm] = Form.useForm();

  const fetchData = async () => {
    try {
      const { data } = await fetchQcCheckItemCategoryDetail({ id: checkClassId });

      setDetailData(data);
      setCheckSource(data?.qcCheckItemList);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();

      const { modifyReason, ...rest } = value;

      const qcCheckItemIdList = checkSource?.map((node: QcCheckItemListType) => node?.id);

      const newValue = { ...rest, qcCheckItemIdList, id: checkClassId };

      const { data } = await fetchQcCheckItemCategoryUpdate(newValue);

      if (data) {
        Message.success('更新成功');
        history.goBack();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [checkClassId]);

  return (
    <EditBaseInfo
      title="编辑检验项分类"
      onCancel={() => {
        history.goBack();
      }}
      onFinish={throttleFn(handleFinish)}
      form={modalForm}
      initialData={detailData}
      setCheckItemList={(lists: QcCheckItemListType[]) => {
        setCheckSource(lists);
      }}
      checkSource={checkSource}
    />
  );
};

export default CheckItemClassificationEdit;
