import { BlTable, LinkTooltip } from 'src/components';
import { ColumnProps } from 'antd/es/table';
import { DefaultPaginationType, defaultPagination, OutBoundItemType } from '../../interface';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import authDict from 'src/utils/auth';
import { BOUND_PATH } from 'src/page/warehouseManagement/boundOrderManagement/navigation';
import { FC, useEffect, useState } from 'react';
import { fetchTraceTraceContentInventoryListOutbounds } from 'src/api/ytt/trace-domain/traceContent';

interface WarehouseTabProps {
  traceId?: number;
}

const OutBound: FC<WarehouseTabProps> = ({ traceId }) => {
  const [outPagination, setOutPagination] = useState<DefaultPaginationType>(defaultPagination);

  const [outBoundDataSource, setOutBoundDataSource] = useState({} as any);

  const [loading, setLoading] = useState(true);

  const fetchOutBoundData = async (params: any) => {
    try {
      if (traceId) {
        const { data } = await fetchTraceTraceContentInventoryListOutbounds({
          traceId,
          page: params?.current ?? 1,
          size: params?.pageSize ?? 10,
        });

        if (data) {
          setOutBoundDataSource(data);
          setOutPagination({
            ...outPagination,
            current: params?.current ?? 1,
            pageSize: params?.pageSize ?? 10,
            total: data?.total ?? 0,
          });
        }
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOutBoundData(defaultPagination);
  }, [traceId]);

  const OutBoundColumns: ColumnProps<OutBoundItemType>[] = [
    {
      title: '序号',
      dataIndex: 'seq',
      width: 100,
      render: (_: any, record: OutBoundItemType, index: number) => index + 1,
    },
    {
      title: '单据类型',
      dataIndex: 'type',
      width: 200,
      render: () => '出库单',
    },
    {
      title: '单据号',
      dataIndex: ['outboundOrder', 'code'],
      width: 200,
      render: (text: string, record: OutBoundItemType) => {
        return text ? (
          <LinkTooltip
            to={`${BOUND_PATH}/outBound/${record?.outboundOrder?.id}/detail`}
            text={text}
            width={200}
            auth={authDict.outboundorder_detail}
          />
        ) : (
          replaceSign
        );
      },
    },
    {
      title: '操作时间',
      dataIndex: 'operateTime',
      width: 200,
      render: (operateTime: Date) => {
        if (!operateTime) return replaceSign;
        return _Time.format(operateTime);
      },
    },
  ];

  return (
    <BlTable
      loading={loading}
      scroll={{ y: 580 }}
      dataSource={outBoundDataSource?.list}
      columns={OutBoundColumns}
      rowKey={(record: OutBoundItemType) => record?.outboundRecordId}
      tableConfigKey={'traceNodeOutBoundCol'}
      onChange={(pagination: any) => {
        const { current, pageSize } = pagination;

        fetchOutBoundData({ current, pageSize });
      }}
      pagination={{
        ...outPagination,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: () => `共 ${outBoundDataSource?.total} 条`,
      }}
    />
  );
};

export default OutBound;
