import { FetchSalesOrderListItemDetailResponse } from 'src/api/ytt/order-domain/salesOrder';
import { SalesOrderOrigin, SalesOrderShipmentStatus } from 'src/dict/salesReturnOrder';
import { ListItemType } from 'src/types/apiTypes';

type SalesOrderDetailListItemType = ListItemType<FetchSalesOrderListItemDetailResponse>;

const fieldLabels = {
  code: '销售订单编号',
  origin: '订单来源',
  customer: '客户名称',
  materialName: '物料名称',
  materialCode: '物料编号',
  amount: '需求数量',
  plannedReturnAmount: '已计划退货数',
  warehousePlannedReceiveAmount: '仓库应收数',
  warehouseReceivedAmount: '仓库实收数',
  customerReturnedAmount: '客户发出数',
  detailLineNo: '销售明细行号',
  shipmentStatus: '销售明细行发货状态',
};

const defaultQueryCondition = {
  origin: [SalesOrderOrigin.normal],
  shipmentStatus: [SalesOrderShipmentStatus.partial, SalesOrderShipmentStatus.all],
};

export type { SalesOrderDetailListItemType };
export { fieldLabels, defaultQueryCondition };
