import { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Button, DatePicker, Space } from 'antd';
import { RecordListLayout } from 'src/layout';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { formateDateRangeForQuery2 } from 'src/utils/formatters/dateTime';

import { AvatarDisplay } from 'src/components/avatar/show';
import { ListItemType } from 'src/types';
//
import { toLogDetailPlanningOperation } from '../navigation';
import { getResult, getStatus } from './utils';
import {
  fetchPlanOperationLogList,
  FetchPlanOperationLogListResponse,
} from 'src/api/ytt/plo-domain/plannOrderOperation';

type RecordType = ListItemType<FetchPlanOperationLogListResponse>;

const FILTER_KEY = 'productionOrderLogFilter';

const List = () => {
  const [filterDate, setFilterDate] = useState<any>([
    moment(moment().subtract(1, 'months').valueOf()),
    moment(moment().valueOf()),
  ]);
  const [resetRefreshMarker, setResetRefreshMarker] = useState(0);

  const columns: ColumnProps<RecordType>[] = [
    {
      title: '操作时间',
      dataIndex: 'operationTime',
      width: 200,
      render: (text) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''),
    },
    {
      title: '操作用户',
      dataIndex: 'operationUser',
      width: 100,
      render: (info) => <AvatarDisplay {...info} isUser />,
    },
    {
      title: '计划运算方案',
      dataIndex: ['schemeSimple', 'code'],
      width: 100,
    },
    {
      title: '计划运算结束时间',
      dataIndex: 'operationEndTime',
      width: 100,
      render: (text) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''),
    },
    {
      title: '操作结果',
      dataIndex: 'operatorId',
      width: 200,
      render: (text, record) => getStatus(record ?? {}),
    },
    {
      title: '操作详情',
      dataIndex: 'successCount',
      width: 200,
      render: (text, record) => getResult(record ?? {}),
    },
    {
      title: '详情',
      dataIndex: 'id',
      width: 100,
      fixed: 'right',
      render: (operatorId) => <Link to={toLogDetailPlanningOperation(operatorId)}>查看</Link>,
    },
  ];

  const formatDataToQuery = (params: any) => {
    return { ...params, ...formateDateRangeForQuery2('updatedAtFrom', 'updatedAtTo', filterDate) };
  };

  return (
    <div
      style={{ height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}
    >
      <Space style={{ padding: 16 }} size={16}>
        <span>操作时间:</span>
        <DatePicker.RangePicker
          style={{ width: 300 }}
          value={filterDate}
          onChange={(value) => {
            setFilterDate(value);
          }}
        />
        <Button
          type="primary"
          onClick={() => {
            setResetRefreshMarker(Math.random());
            sessionStorage.setItem(FILTER_KEY, JSON.stringify(filterDate));
          }}
        >
          查询
        </Button>
      </Space>
      <RecordListLayout<RecordType>
        useQuickFilter={false}
        useColConfig={false}
        formatDataToQuery={formatDataToQuery}
        resetRefreshMarker={resetRefreshMarker}
        requestFn={fetchPlanOperationLogList}
        columns={columns}
      />
    </div>
  );
};

export default List;
