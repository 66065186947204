import { Form, message as Message } from 'antd';
import { match, RouteComponentProps } from 'react-router-dom';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';
import CreateAndEditBaseInfo from './createAndEditBaseInfo';
import { gcUrl } from 'src/utils';
import _ from 'lodash';
import { fetchQcTaskCreate, fetchQcTaskUpdateBaseInfo } from 'src/api/ytt/quality-domain/qc_task';
import { formatCustomFieldsInData, initCustomFieldsInData } from 'src/components/customField';
import { formatCustomFieldValueToSubmit } from 'src/components/customLayout/uitls/dataFormat';

interface StoreEditProps extends RouteComponentProps {
  match: match<{ id: string; bizType: string }>;
}

const QcTaskEdit = (props: StoreEditProps) => {
  const { match, history } = props;
  const checkId = Number(match?.params?.id);
  const [modalForm] = Form.useForm();

  const { bizType, layoutId } = gcUrl.getParams();

  const handleFinish = async () => {
    try {
      const value = await modalForm?.validateFields();
      const {
        batchNoId,
        material,
        supplier,
        candidates,
        candidateDeps,
        customer,
        process,
        equipment,
        qcConfig,
      } = value;
      // 处理自定义字段
      const customFields = formatCustomFieldValueToSubmit(
        modalForm.getFieldValue('customFields'),
        layoutId,
      );
      const submmitData = {
        batchNoId: batchNoId && batchNoId.value,
        checkType: bizType,
        materialId: material && Number(material.value),
        supplierId: supplier && supplier && Number(supplier.value),
        candidates:
          candidates &&
          candidates.map((node: any) => {
            return node.value;
          }),
        candidateDeps:
          candidateDeps &&
          candidateDeps.map((node: any) => {
            return node.value;
          }),
        customerId: customer && Number(customer.value),
        processId: process && process.value,
        resourceId: equipment && equipment.value,
        customFields,
        configSnapshotId: qcConfig && qcConfig.value,
      };

      const { code } = checkId
        ? await fetchQcTaskUpdateBaseInfo({
            customFields,
            id: checkId,
          })
        : await fetchQcTaskCreate({
            ...submmitData,
          });

      if (code === 200) {
        Message.success(checkId ? '更新成功' : '新建成功');
        history.goBack();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CreateAndEditBaseInfo
      edit={Boolean(checkId)}
      title={`${checkId ? '编辑' : '创建'}检验任务`}
      onFinish={throttleFn(handleFinish)}
      form={modalForm}
      initialData={{ id: checkId }}
      bizType={bizType}
    />
  );
};

export default QcTaskEdit;
