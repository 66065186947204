import { useState, useEffect } from 'react';
import { message as Message } from 'antd';
import moment from 'moment';

import { fetchWorkerCalendarGet } from 'src/api/ytt/calendar-domain';
import { CalendarDataType, WorkCalendarParamItemProps } from '../../constants';
import CalendarBasicInfo from '../../components/CalendarBasicInfo';
import FlexCalendar from './FlexCalendar';

/**
 * calendarParams里包含了id, startTime, endTime三个字段
 * 虽然请求日历详情的接口支持只传入id，但是在没有传入viewStartTime/viewEndTime的时候，会默认返回从工作日历的开始日期开始的后30天数据
 * 而前端需要默认显示的，是从开始日期开始到该月月底的数据。也就是说，如果某月有31天，不传入viewEndTime，会缺失1天的数据
 * 解决这个问题可以通过：
 * 1. 重复调用加载方法，发送2次请求
 * 2. 从排班页面的外层把日历的开始日期传进来
 * 两种方法的任意一种来解决
 * 排班窗口有4个入口，分别是新建页面，编辑页面，复制页面的排班制基本信息填写完毕以后，直接选择“保存并排班”按钮进入，以及在列表页对排班制工作日历的操作菜单中选择“排班”选项进入
 * 无论是哪一种情况，在入口时其实都已经请求到了开始日期/结束日期，props参数传进来可以避免重复的请求
 * 所以对于排班组件，在获取详情明明可以通过id取到所有数据的前提下，冗余传了开始日期和结束日期
 */
interface ScheduleShiftCalendarProps {
  calendarParams: WorkCalendarParamItemProps | undefined;
}

export default function ScheduleShiftContent({ calendarParams }: ScheduleShiftCalendarProps) {
  /** 当前工作日历的详情，包括基本信息和排班信息 */
  const [scheduleData, setScheduleData] = useState<CalendarDataType>();

  /** 排班表格加载状态 */
  const [tableLoading, setTableLoading] = useState<boolean>(false);

  /**
   * 请求指定时间段的工作日历详情
   * @param startTime 指定时间段开始时间
   * @param endTime 指定时间段结束时间
   */
  const loadCalendarDetail = async (startTime?: number, endTime?: number) => {
    // 更新表格的加载状态
    setTableLoading(true);
    // 如果开始时间为空，则取当前选中日期的月初第一天为开始时间
    const viewStartTime = startTime;
    // 如果结束时间为空，取开始时间的月底最后一天为结束时间
    const viewEndTime =
      endTime ??
      (startTime ? moment(startTime).endOf('month').startOf('day').valueOf() : undefined);

    try {
      // 生成请求参数并请求
      const response = await fetchWorkerCalendarGet(
        {
          id: calendarParams?.id as number,
          viewStartTime,
          viewEndTime,
        },
        { legacy: true },
      );

      // 结束表格加载状态
      setTableLoading(false);

      // 后台：当业务逻辑出错， 返回response的code可能不是200.
      // 比如请求一个已经删除的工作日历
      // 在这种情况下，错误信息保存在response.message里.
      if (response.code === 200) {
        // 编辑或复制工作日历时，把传进来的calendarData回填到本弹窗的表单上
        // formatSheduleData(response.data);
        setScheduleData(response.data);
      } else {
        Message.error(response.message);
      }
    } catch (e) {
      console.log(e);
      // 结束表格加载状态
      setTableLoading(false);
    }
  };

  /** 初始化方法，加载日历详情 */
  useEffect(() => {
    if (calendarParams?.id !== undefined) {
      // 如果是编辑/复制工作日历, 并且calendarId从列表页被传来进来, 用calendarId从后台把该工作日历的详情信息请求回来
      loadCalendarDetail(calendarParams.startTime);
    }
  }, []);

  return scheduleData ? (
    <>
      <CalendarBasicInfo basicInfoData={scheduleData} />
      <FlexCalendar
        scheduleData={scheduleData}
        calendarLoading={tableLoading}
        loadFunction={loadCalendarDetail}
      />
    </>
  ) : null;
}
