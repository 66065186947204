import React, { ReactElement } from 'react';
import _ from 'lodash';
import { Badge, Select, Space } from 'antd';
import { BlIcon, SearchSelect, TagList } from 'src/components';
import { RecordListLayout } from 'src/layout';
import { fieldTypeList } from 'src/utils';
import lookup, { appDict, appEnum } from 'src/dict';
import { fetchBpmList, FetchBpmListResponse } from 'src/api/ytt/med-domain/bom';
import { ListItemType } from 'src/types';
import { ColumnProps } from 'antd/es/table';
import { LabeledValue } from 'antd/lib/select';
import { getMaterialAttrs } from 'src/entity/material';
import MaterialAttributeCascader, {
  formatMaterialAttributeToQuery,
} from 'src/page/knowledgeManagement/materialModeling/material/share/Cascader/BcMultiCascader';
//
import { toCreateBom, toDetailBom } from '../navigation';
import { BomActiveColorMap } from '../constants';
import BcBlCascader from 'src/components/Cascader';
import { intersectionValueOfId } from 'src/utils/array';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';

type BomOutputMaterialItem = ListItemType<FetchBpmListResponse>;

const CoproductList = (props: { history: any }) => {
  const { history } = props;

  const formatDataToDisplay = (data: any) => {
    const displayData = _.cloneDeep(data);

    return displayData;
  };

  const formatDataToQuery = (data: any) => {
    const {
      quickSearch,
      materialCode,
      materialName,
      bomMaterialCodeIds,
      bomMaterialNameIds,
      materialAttributes,
      ...sendData
    } = _.cloneDeep(data);

    return {
      ...sendData,
      materialCodeIds: _.isEmpty(materialCode)
        ? undefined
        : _.map(materialCode, (n: LabeledValue) => n.value),
      materialNameIds: _.isEmpty(materialName)
        ? undefined
        : _.map(materialName, (n: LabeledValue) => n.value),
      bomMaterialLike: quickSearch,
      materialAttributes: formatMaterialAttributeToQuery(materialAttributes),
      bomMaterialIds: intersectionValueOfId(bomMaterialCodeIds, bomMaterialNameIds),
    };
  };

  const mainMenu = [
    {
      title: '新建物料清单',
      auth: authDict.bom_add,
      icon: <BlIcon type="iconxinjiantianjia" />,
      onClick: () => {
        history.push(toCreateBom());
      },
      items: [],
    },
  ];

  const columns: (ColumnProps<BomOutputMaterialItem> & {
    filterIndex?: string;
    type?: number;
    isFilter?: boolean;
    selectProps?: any;
    renderItem?: ReactElement;
    dateFormat?: any;
  })[] = [
    {
      title: '父项物料编号',
      dataIndex: ['bomMaterial', 'baseInfo', 'code'],
      filterIndex: 'bomMaterialCodeIds',
      renderItem: <SearchSelect fetchType="material" labelPath="code" mode="multiple" />,
      isFilter: true,
      fixed: 'left',
      width: 150,
    },
    {
      title: '父项物料名称',
      dataIndex: ['bomMaterial', 'baseInfo', 'name'],
      filterIndex: 'bomMaterialNameIds',
      renderItem: <SearchSelect fetchType="material" labelPath="name" mode="multiple" />,
      isFilter: true,
      fixed: 'left',
      width: 150,
    },
    {
      title: '父项物料版本号',
      dataIndex: 'bomVersion',
      filterIndex: 'version',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
    },
    {
      title: '状态',
      dataIndex: 'active',
      type: fieldTypeList.select,
      isFilter: true,
      selectProps: { options: appDict.common.usageStatus },
      width: 150,
      render: (text) => (
        <Badge color={BomActiveColorMap.get(text)} text={lookup('common.usageStatus', text)} />
      ),
    },
    {
      title: '父项物料属性',
      dataIndex: ['bomMaterial', 'attribute'],
      filterIndex: 'materialAttributes',
      renderItem: <MaterialAttributeCascader />,
      isFilter: true,
      width: 150,
      render: (text, record) => <TagList dataSource={getMaterialAttrs(record.bomMaterial)} />,
    },
    {
      title: '父项物料分类',
      dataIndex: ['bomMaterial', 'category', 'name'],
      filterIndex: 'materialCategoryIds',
      renderItem: (
        <BcBlCascader
          fetchType={'materialCategory'}
          nameLabel={'物料分类'}
          popupPlacement={'bottomRight'}
        />
      ),
      isFilter: true,
      width: 150,
    },
    {
      title: '父项物料规格',
      dataIndex: ['bomMaterial', 'baseInfo', 'specification'],
      width: 150,
    },
    {
      title: '虚拟件',
      dataIndex: 'virtual',
      isFilter: true,
      renderItem: <Select options={appDict.common.yn} allowClear />,
      width: 150,
      render: (text) => lookup('yn', text),
    },
    {
      title: '单位',
      dataIndex: 'unitName',
      width: 150,
    },
    {
      title: '成品率%',
      dataIndex: 'productRate',
      width: 150,
    },
    {
      title: '默认版本',
      dataIndex: 'defaultVersion',
      sorter: true,
      isFilter: true,
      renderItem: <Select options={appDict.common.yn} allowClear />,
      width: 150,
      render: (text) => lookup('yn', text),
    },
    {
      title: '版本说明',
      dataIndex: 'versionDescription',
      width: 150,
    },
    {
      title: '备注',
      dataIndex: 'bomRemark',
      width: 150,
    },
    {
      title: '行号',
      dataIndex: 'lineSeq',
      type: fieldTypeList.text,
      isFilter: true,
      width: 150,
    },
    {
      title: '物料编号',
      dataIndex: 'materialCode',
      sorter: true,
      renderItem: <SearchSelect fetchType="material" labelPath="code" mode="multiple" />,
      isFilter: true,
      width: 150,
      render: (text, record) => record?.material?.baseInfo?.code,
    },
    {
      title: '物料名称',
      dataIndex: 'materialName',
      sorter: true,
      renderItem: <SearchSelect fetchType="material" labelPath="name" mode="multiple" />,
      isFilter: true,
      width: 150,
      render: (text, record) => record?.material?.baseInfo?.name,
    },
    {
      title: '物料规格',
      dataIndex: 'specification',
      width: 150,
      render: (text, record) => {
        return record?.material?.baseInfo?.specification;
      },
    },
    {
      title: '数量',
      dataIndex: 'amount',
      width: 150,
    },
    {
      title: '报工工序号',
      dataIndex: ['outputProcessProcessRouteNodeSimpleVO', 'processNum'],
      width: 150,
    },
    {
      title: '报工控件编号',
      dataIndex: ['outputSopControlDTO', 'code'],
      width: 150,
    },
  ];

  const getFilterList = () => {
    return columns
      .filter((i) => i.isFilter)
      .map((column: any) => {
        const filter: any = {
          label: column.title,
          name: column.filterIndex || column.dataIndex,
          type: column.type,
          rules: column.rules,
          renderItem: column.renderItem,
        };

        if (column.type === fieldTypeList.select || column.type === fieldTypeList.multiSelect) {
          filter.selectProps = column.selectProps;
        }
        return filter;
      });
  };

  const getOperationList = (record: any) => {
    return [
      {
        title: lookup('crud', appEnum.Common.CRUD.view),
        auth: authDict.bom_detail,
        onClick: () => {
          history.push(toDetailBom(record.bomId));
        },
      },
    ];
  };

  return (
    <RecordListLayout<BomOutputMaterialItem>
      configcacheKey="bomCoproductRecordList"
      filterList={getFilterList()}
      requestFn={fetchBpmList}
      mainMenu={mainMenu}
      placeholder={'请输入父项物料编号或名称'} // 快速搜索input placeholder
      formatDataToQuery={formatDataToQuery} // 转换搜索条件进行查询（快速查询字段："quickSearch",当前页："page",分页大小："size"）
      formatDataToDisplay={formatDataToDisplay} // 转换搜索条件进行展示
      columns={columns} // table columns
      getOperationList={getOperationList}
      userAuth={getAuthFromLocalStorage()}
    />
  );
};

export default CoproductList;
