import { Modal } from 'antd';
import { goToList, goToDetail } from '../navigates';

export function useShowOperateResultModal() {
  const show = (isCreating: boolean, orderCode: string, orderId: number) =>
    Modal.confirm({
      title: isCreating ? '销售退货单新建成功' : '销售退货单编辑成功',
      content: `编号：${orderCode}`,
      okText: '返回销售退货单列表',
      cancelText: '查看退货单详情',
      onOk: goToList,
      onCancel: () => {
        goToDetail(orderId);
      },
      okButtonProps: { size: 'middle' },
      cancelButtonProps: { size: 'middle' },
    });

  return { show };
}
