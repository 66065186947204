import React, { useState } from 'react';
import { Badge, Button, Dropdown, Menu, message, Select, Space } from 'antd';
import {
  fetchDataSetQueryDataSetPage,
  fetchDataSetDataSetStartStopProcess,
} from 'src/api/ytt/e-report-domain/dataSet';
import { BlIcon, showErrorListMessage, SingleOperationLogComponent, Tooltip } from 'src/components';
import { FilterItem, RecordListLayout } from 'src/layout';
import { gcTime } from 'src/utils';
import lookup, { appDict, appEnum } from 'src/dict';
import { getCopyUrl, getDetailUrl, getEditUrl } from '../navigation';
import { avatarDisplay } from 'src/components/avatar/show';
import { FieldType } from 'src/dict/customField';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { DetailTheme } from 'src/dict/customReport/mappings';
import _ from 'lodash';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { replaceSign } from 'src/utils/constants';
import { fetchEReportQueryDataSetIsUsed } from 'src/api/ytt/e-report-domain/customReport';

interface RowType {
  dataSetCode: string;
  dataSetName: string;
  themeDetail: number;
  status: number;
  creator?: any;
  id: number;
  bizSetStatus: number;
}

const DataSetList = (props: { history: any }) => {
  const { history } = props;
  const [refreshMarker, setRefreshMarker] = useState<number>(Date.now());
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [detailTheme, setDetailTheme] = useState<any>();
  const [visibleLog, setVisibleLog] = useState<boolean>();
  const [datasetId, setDataSetId] = useState<number>(0);

  const data = [
    {
      title: '数据集编号',
      dataIndex: 'code',
      width: 150,
      sorter: true,
      isFilter: true,
      fixed: 'left',
      render: (code: string, record: RowType, index: number, config: any) => (
        <a
          type="link"
          onClick={() => {
            history.push(getDetailUrl(record.id));
          }}
        >
          <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
        </a>
      ),
    },
    {
      title: '数据集名称',
      dataIndex: 'dataSetName',
      isFilter: true,
      width: 150,
      render: (name: string, record: RowType, index: number, config: any) => (
        <a
          type="link"
          onClick={() => {
            history.push(getDetailUrl(record.id));
          }}
        >
          <Tooltip text={name ?? replaceSign} width={config.contentWidth} />
        </a>
      ),
    },
    {
      title: '业务主题',
      dataIndex: 'bizTheme',
      isFilter: true,
      sorter: true,
      width: 150,
      render: (bizTheme: number) => {
        return bizTheme ? lookup('customReport.businessTheme', bizTheme) : '-';
      },
      renderItem: (
        <Select
          placeholder={'请选择业务主题'}
          options={appDict.customReport.businessTheme}
          onSelect={(value) => setDetailTheme(DetailTheme[Number(value)])}
        />
      ),
    },
    {
      title: '细分主题',
      dataIndex: 'themeDetail',
      isFilter: true,
      sorter: true,
      width: 150,
      render: (value: number, record: RowType) => {
        return DetailTheme[record?.bizTheme]?.[value] || '-';
      },
      renderItem: (
        <Select
          placeholder={'请选择细分主题'}
          options={_.map(detailTheme, (label: string, value: number) => ({
            label,
            value: Number(value),
          }))}
        />
      ),
    },
    {
      title: '数据集状态',
      dataIndex: 'bizSetStatus',
      sorter: true,
      isFilter: true,
      type: FieldType.multiSelect,
      selectProps: { options: appDict.customReport.status },
      width: 150,
      render: (text: number) => (
        <Badge
          status={text === appEnum.CustomReport.STATUS.enabled ? 'success' : 'error'}
          text={lookup('customReport.status', text)}
        />
      ),
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      isFilter: true,
      width: 150,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
      render: (creator: any) => {
        if (!creator) {
          return '-';
        }
        return avatarDisplay({ ...creator, isUser: true, isShowTag: true });
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      type: FieldType.date,
      isFilter: true,
      sorter: true,
      width: 180,
      render: (createdAt: Date) => gcTime.formatLine(createdAt),
    },
    {
      title: '修改人',
      dataIndex: 'modifier',
      isFilter: true,
      width: 150,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
      render: (modifier: any) => {
        if (!modifier) {
          return '-';
        }
        return avatarDisplay({ ...modifier, isUser: true, isShowTag: true });
      },
    },
    {
      title: '修改时间',
      dataIndex: 'updatedAt',
      isFilter: true,
      sorter: true,
      type: FieldType.date,
      width: 180,
      render: (createdAt: Date) => gcTime.formatLine(createdAt),
    },
  ];

  const handleBatchChangeStatus = async ({ status, ids }: { status: number; ids: number[] }) => {
    const res = await fetchDataSetDataSetStartStopProcess({
      dataSetStatus: status,
      dataSetIdList: ids,
    });
    const { failNum, successNum, failDetail } = res.data || {};

    if (res.code === 200) {
      if (failNum === 0) {
        message.success(`${status === appEnum.CustomReport.STATUS.enabled ? '启用' : '停用'}成功`);
      } else {
        showErrorListMessage({
          failCount: failNum,
          successCount: successNum,
          title: `${status === appEnum.CustomReport.STATUS.enabled ? '启用' : '停用'}出现失败`,
          data: _.map(failDetail, (item) => ({ ...item, key: item.id })),
          columns: [
            { title: '数据集编号', dataIndex: 'code', width: 200 },
            { title: '数据集名称', dataIndex: 'name', width: 200 },
            { title: '失败原因', dataIndex: 'reason', width: 200 },
          ],
        });
      }
    }
  };

  const filterList: any[] = data
    .filter((item) => item.isFilter)
    .map(({ title, dataIndex, type, selectProps, renderItem }) => ({
      label: title,
      name: dataIndex,
      type: type || FieldType.text,
      selectProps,
      renderItem,
    }));

  const batchMenu = [
    {
      title: '启用',
      key: 'enable',
      auth: authDict.dataset_enable_disable,
      onClick: () =>
        handleBatchChangeStatus({
          status: appEnum.CustomReport.STATUS.enabled,
          ids: selectedRowKeys.map((id) => Number(id)),
        }),
    },
    {
      title: '停用',
      key: 'disable',
      auth: authDict.dataset_enable_disable,
      onClick: () =>
        handleBatchChangeStatus({
          status: appEnum.CustomReport.STATUS.disabled,
          ids: selectedRowKeys.map((id) => Number(id)),
        }),
    },
    // {
    //   title: '删除',
    //   key: 'delete',
    // },
  ];

  const mainMenu = [
    {
      title: '创建数据集',
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.dataset_add,
      onClick: () => toCreate(),
    },
  ];

  const formatDataToFormDisplay = (data: any) => {
    const { createdAt, updatedAt, ...rest } = data;

    return {
      ...rest,
      createdAt: gcTime.formatRangeTimeToMoment(createdAt),
      updatedAt: gcTime.formatRangeTimeToMoment(updatedAt),
    };
  };

  const toCreate = () => {
    props.history.push('/analyse/dataset/manage/create');
  };

  const formatDataToQuery = (res: any) => {
    const { creator, modifier, createdAt, updatedAt, ...rest } = res;
    const [createdAtStart, createdAtEnd] = gcTime.formatMomentRangeToUnix(createdAt);
    const [updatedAtStart, updatedAtEnd] = gcTime.formatMomentRangeToUnix(updatedAt);

    return {
      ...rest,
      creatorId: _.map(creator, 'value'),
      operatorId: _.map(modifier, 'value'),
      createdAtStart,
      createdAtEnd,
      updatedAtStart,
      updatedAtEnd,
    };
  };

  const getOperationList = (record: RowType) => {
    return [
      {
        title: '查看',
        auth: authDict.dataset_detail,
        onClick: () => history.push(getDetailUrl(record.id)),
      },
      {
        title: '复制',
        auth: authDict.dataset_add,
        onClick: () => props.history.push(getCopyUrl(record?.id)),
      },
      {
        title: lookup('customReport.changeStatusAction', record.bizSetStatus),
        auth: authDict.dataset_enable_disable,
        onClick: () => {
          fetchDataSetDataSetStartStopProcess({
            dataSetStatus:
              record.bizSetStatus === appEnum.CustomReport.STATUS.enabled
                ? appEnum.CustomReport.STATUS.disabled
                : appEnum.CustomReport.STATUS.enabled,
            dataSetIdList: [record.id],
          }).then(() => {
            return setRefreshMarker(Date.now());
          });
        },
      },
      {
        title: '编辑',
        auth: authDict.dataset_edit,
        onClick: async () => {
          const { data } = await fetchEReportQueryDataSetIsUsed({ dataSetId: record.id });

          if (data?.allowEditing) {
            history.push(getEditUrl(record.id));
          } else {
            showErrorListMessage({
              title: '该数据集已被使用，不支持编辑，请修改后重试',
              method: 'warning',
              desc: '具体模版明细如下：',
              data: _.map(data?.usedTemplateList, (item) => ({ ...item, key: item.id })), // 列表数据
              columns: [
                {
                  title: '模版编号',
                  dataIndex: 'code',
                },
                {
                  title: '模版名称',
                  dataIndex: 'name',
                },
              ],
            });
          }
        },
      },
      {
        title: '操作记录',
        auth: authDict.dataset_operrecord,
        onClick: () => {
          setVisibleLog(true);
          setDataSetId(record?.id);
        },
      },
    ];
  };

  return (
    <>
      <RecordListLayout<RowType>
        columns={data}
        filterList={filterList}
        rowKey="id"
        configcacheKey="templateList"
        placeholder="请输入数据集编号和名称"
        batchMenu={batchMenu}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatDataToFormDisplay}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectedKeys) => {
          setSelectedRowKeys(selectedKeys);
        }}
        mainMenu={mainMenu}
        refreshMarker={refreshMarker}
        requestFn={(params) => fetchDataSetQueryDataSetPage(params)}
        getOperationList={getOperationList}
        userAuth={getAuthFromLocalStorage()}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={datasetId}
          objectCode={'Dataset'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DataSetList;
