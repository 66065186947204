import { useEffect, useRef, useState } from 'react';
import { Modal, Select, Form, Input, message, Tag } from 'antd';
import { FilterItem, RecordListLayout } from 'layout';
import { fieldTypeList, gcArray, gcObject } from 'utils';
import _ from 'lodash';
import {
  fetchInventoryElementListPage,
  FetchInventoryElementListPageResponse,
} from 'src/api/ytt/inventory-domain';
import { FormInstance } from 'antd/es/form/Form';
import BcBlMultiCascader from 'src/page/knowledgeManagement/materialModeling/material/share/Cascader/BcBlMultiCascader';
import BcBlCascader from 'src/components/Cascader';
import { SearchSelect } from 'src/components';
import lookup, { appDict } from 'src/dict';
import {
  fetchQcTaskBindMaterial,
  fetchQcTaskMaterialBindProgress,
} from 'src/api/ytt/quality-domain/qc_task';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { BindMaterialResEnum, ProgressEnum } from 'src/dict/quality';
import { fractionLengthCheck, numberMinMaxCheck } from 'src/utils/formValidators/customValidator';
import { NUMBER_SCALE } from 'src/page/quality/models/constants/qcConfig';

type TableRowType = Exclude<
  FetchInventoryElementListPageResponse['data'],
  undefined
>['list'][number];

interface Props {
  visible: boolean;
  onCancel?: () => void;
  onRefresh?: () => void;
  materialId?: number;
  inventoryForm: FormInstance;
  qcTaskId: number;
  haveAuxUnitList: any;
}

const SelectInventory = (props: Props) => {
  const { visible, onCancel, onRefresh, materialId, inventoryForm, qcTaskId, haveAuxUnitList } =
    props;
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const [, setSelectedRows] = useState<any[]>();
  const [, setBaseUnitCount] = useState<number | undefined>(0);
  const refreshCountRef = useRef(0);
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [isFirstOpen, setIsFirstOpen] = useState<boolean>(visible);

  // 刷新列表页，保留筛选条件
  const refreshTable = () => {
    refreshCountRef.current++;
    setRefreshMarker(refreshCountRef.current);
  };

  const fetchData = async (params: any) => {
    const res = await fetchInventoryElementListPage({
      ...params,
      bizStatus: [0],
      materialId,
    });

    if (res && res.code === 200) {
      const { list = [] } = res.data || {};

      const arrList: any[] = [];
      // 保留原来输入的值

      list.map((node: any) => {
        const newNode = _.filter(inventoryForm.getFieldValue('qcMaterials'), (item: any) => {
          if (item.id === node.id) arrList.push(item);
          return item.id === node.id;
        });

        if (_.isEmpty(newNode)) {
          arrList.push(node);
        }
        return newNode ?? node;
      });

      inventoryForm.setFieldsValue({
        qcMaterials: isFirstOpen ? list : arrList,
      });
      setBaseUnitCount(res.data?.total);
      setIsFirstOpen(false);
      refreshTable();
    }
    return res;
  };

  const handelFinish = async (qcMaterials: any, qcTaskId: number) => {
    setLoading(true);
    try {
      const { data } = await fetchQcTaskBindMaterial({ qcMaterials, id: qcTaskId });

      if (data?.code === BindMaterialResEnum.fail) {
        setLoading(false);
        Modal.confirm({
          title: '库存添加失败',
          icon: <ExclamationCircleOutlined />,
          content: '要重新添加库存吗',
          okText: '确认',
          cancelText: '取消',
          onCancel: () => {
            onCancel?.();
            setIsFirstOpen(true);
          },
          onOk: () => {},
        });
      } else {
        let progressResult: number | undefined;
        const resInterval = setInterval(async () => {
          const res = await fetchQcTaskMaterialBindProgress({ id: qcTaskId });

          progressResult = res.data?.status;
          if (progressResult === ProgressEnum.finished || progressResult === ProgressEnum.fail) {
            clearInterval(resInterval);
            if (progressResult === ProgressEnum.fail) {
              setLoading(false);
              Modal.confirm({
                title: '库存添加失败',
                icon: <ExclamationCircleOutlined />,
                content: '要重新添加库存吗',
                okText: '确认',
                cancelText: '取消',
                onCancel: () => {
                  onCancel?.();
                  setIsFirstOpen(true);
                },
                onOk: () => {},
              });
            } else if (data?.code === BindMaterialResEnum.success) {
              clearInterval(resInterval);
              setLoading(false);
              message.success(`${data.successCount}条库存添加成功`);
              onCancel?.();
              setIsFirstOpen(true);
              onRefresh?.();
            } else if (data?.code === BindMaterialResEnum.partSucess) {
              setLoading(false);
              Modal.confirm({
                title: `${data.failedCount}条库存添加失败，${data.successCount}条库存添加成功`,
                icon: <ExclamationCircleOutlined />,
                content: '继续添加吗',
                okText: '确认',
                cancelText: '取消',
                onCancel: () => {
                  onCancel?.();
                  setIsFirstOpen(true);
                },
                onOk: () => {
                  refreshTable();
                },
              });
            }
          }
        }, 500);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Form.List name={'qcMaterials'}>
      {() => {
        const dataColumns = _.compact([
          {
            title: '物料名称',
            dataIndex: 'materialName',
            width: 150,
            render: (text: any, field: TableRowType) => {
              return field.material?.name;
            },
          },
          {
            title: '物料属性',
            dataIndex: 'materialAttr',
            defaultColConfig: {
              display: false,
            },
            width: 200,
            type: fieldTypeList.select,
            render: (value: any, field: TableRowType) => {
              const attribute = field.material?.attrList;

              return attribute && attribute.length > 0
                ? attribute.map((item) => {
                    const { name, attributeItem } = item;

                    return (
                      <span>
                        <Tag>
                          {name}: {attributeItem?.content}
                        </Tag>
                      </span>
                    );
                  })
                : '-';
            },
            renderItem: <BcBlMultiCascader type={'materialProperty'} nameLabel={'物料属性'} />,
          },
          {
            title: '物料编号',
            dataIndex: 'materialCode',
            width: 150,
            render: (text: any, field: TableRowType) => field.material?.code,
          },
          {
            title: '物料规格',
            dataIndex: 'specification',
            width: 150,
            render: (text: any, field: TableRowType) => field.material?.specification,
          },
          {
            title: '物料分类',
            dataIndex: 'category',
            width: 150,
            renderItem: (
              <BcBlCascader
                fetchType={'materialCategory'}
                nameLabel={'物料分类'}
                popupPlacement={'bottomRight'}
              />
            ),
            render: (text: any, field: TableRowType) => field.material?.categoryName,
          },
          {
            title: '仓库',
            dataIndex: 'warehouse',
            isFilter: true,
            width: 200,
            renderItem: <SearchSelect mode="multiple" labelInValue fetchType="warehouse" />,
            render: (value: any, field: TableRowType) => field?.location?.warehouse?.name,
          },
          {
            title: '仓位',
            dataIndex: 'storageLocationId',
            isFilter: true,
            width: 200,
            renderItem: <SearchSelect mode="multiple" labelInValue fetchType="storageLocation" />,
            render: (value: any, field: TableRowType) => field?.location?.storage?.name,
          },
          {
            title: '位置状态',
            dataIndex: 'storageStatus',
            width: 120,
            isFilter: true,
            type: fieldTypeList.select,
            render: lookup('bound.storageStatus'),
            selectProps: {
              mode: 'multiple',
              options: appDict.bound.storageStatus,
            },
          },
          {
            title: '批次号',
            dataIndex: 'batchNo',
            width: 150,
            isFilter: true,
            render: (value: any, field: TableRowType) => field.bizKey?.batchNo,
          },
          {
            title: '标识码',
            dataIndex: 'qrCode',
            isFilter: true,
            width: 150,
            render: (value: string) => value,
          },
          {
            title: '参检数量',
            dataIndex: 'count',
            width: 150,
            render: (_: any, field: any, index: number) => {
              const precisionFigure =
                field?.opAmount?.amount?.unit?.precisionFigure ?? NUMBER_SCALE;

              return (
                <Form.Item
                  rules={[
                    { required: true, message: '参检数量必填' },
                    {
                      validator: numberMinMaxCheck({
                        min: 0,
                        minAllowEqual: false,
                        max: field.opAmount.amount.amount,
                        maxAllowEqual: true,
                        message: `参检数量需大于0且小于等于${field.opAmount.amount.amount}`,
                      }),
                    },
                    {
                      validator: fractionLengthCheck(precisionFigure),
                    },
                  ]}
                  name={[index, 'count']}
                  fieldKey={[field.id, 'count']}
                  style={{ margin: 'auto' }}
                >
                  <Input
                    defaultValue={
                      inventoryForm.getFieldValue('qcMaterials')[index]?.opAmount?.amount?.amount
                    }
                    disabled={field.qrCode}
                  />
                </Form.Item>
              );
            },
          },
          {
            title: '主单位',
            dataIndex: ['material', 'baseUnit', 'name'],
            width: 150,
          },
          haveAuxUnitList && {
            title: '辅助数量',
            dataIndex: 'auxAmountsCount',
            width: 150,
            render: (_: any, field: any, index: number) => {
              const precisionFigure =
                field?.opAmount?.auxAmounts[0]?.unit?.precisionFigure ?? NUMBER_SCALE;

              return (
                <Form.Item
                  rules={[
                    {
                      validator: numberMinMaxCheck({
                        min: 0,
                        minAllowEqual: true,
                        max: field?.opAmount?.auxAmounts[0]?.amount,
                        maxAllowEqual: true,
                        message: `辅助数量需大于等于0且小于${field?.opAmount?.auxAmounts[0]?.amount}`,
                      }),
                    },
                    {
                      validator: fractionLengthCheck(precisionFigure),
                    },
                  ]}
                  name={[index, 'auxAmountsCount']}
                  fieldKey={[field.id, 'auxAmountsCount']}
                  style={{ margin: 'auto' }}
                >
                  <Input
                    disabled={field.qrCode || !field?.opAmount?.auxAmounts[0]}
                    defaultValue={field.opAmount?.auxAmounts[0]?.amount ?? undefined}
                  />
                </Form.Item>
              );
            },
          },
          haveAuxUnitList && {
            title: '辅助单位',
            dataIndex: 'auxUnitId',
            width: 150,
            render: (text: string, field: any, index: number) => {
              const auxUnitList =
                field.opAmount &&
                field.opAmount.auxAmounts &&
                field.opAmount?.auxAmounts?.map((node: any) => {
                  return { label: node.unit.name, value: node.unit.id };
                });

              return (
                <Form.Item
                  name={[index, 'auxUnitId']}
                  style={{ margin: 'auto' }}
                  fieldKey={[index, 'auxUnitId']}
                >
                  <Select
                    disabled={field.qrCode || field?.opAmount?.auxAmounts?.length < 2}
                    defaultValue={field?.opAmount?.auxAmounts[0]?.unit.id}
                    style={{ width: 120 }}
                    options={auxUnitList}
                  />
                </Form.Item>
              );
            },
          },
          {
            title: '',
            width: 20,
            fixed: 'right',
          },
        ]);

        const config = {
          rowKey: (record: TableRowType) => String(record.id),
          columns: dataColumns,
          filterList: dataColumns
            .filter((i) => i.isFilter)
            .map((columns: any): FilterItem => {
              const filter = {
                label: columns.title,
                name: columns.dataIndex,
                ...columns,
              };

              return filter;
            }),
          formatDataToFormDisplay: (filterData: any) => {
            return gcObject.filterEmptyProperty(filterData);
          },
          formatDataToQuery: (param: any) => {
            const { materialName, storageLocationId, warehouse, ...rest } = param;
            const options = {
              warehouseId: warehouse ? _.map(warehouse, 'value') : undefined,
              storageLocationId: storageLocationId ? _.map(storageLocationId, 'value') : undefined,
              materialId: materialName?.value,
              ...rest,
            };

            const relParams = gcObject.filterEmptyProperty(options);

            return relParams;
          },
        };

        return (
          <Modal
            destroyOnClose
            keyboard={false}
            maskClosable={false}
            width={1200}
            title="添加库存"
            visible={visible}
            onCancel={() => {
              if (!loading) {
                onCancel?.();
                setIsFirstOpen(true);
              }
            }}
            onOk={async () => {
              if (gcArray.isEmpty(selectedRowKeys)) {
                message.error('请选择库存');
              } else {
                const qcMaterial = inventoryForm.getFieldValue('qcMaterials');

                const newSelectedRows = qcMaterial!.filter((row: any) => {
                  if (_.indexOf(selectedRowKeys, row.id + '') !== -1) {
                    return row;
                  }
                });

                const qcMaterials = newSelectedRows.map((row: any) => {
                  // 校验是否改变参检数量或者辅助抽样量
                  const haveRowCount = _.isUndefined(row.count);
                  const rowCount = haveRowCount ? row.opAmount.amount.amount : Number(row.count);

                  const haveAuxAmountsCount = _.isUndefined(row.auxAmountsCount);
                  const rowAuxAmountsCount = haveAuxAmountsCount
                    ? row?.opAmount?.auxAmounts[0]?.amount
                    : Number(row.auxAmountsCount);

                  const inventory = {
                    auxAmounts: [
                      {
                        count: rowAuxAmountsCount,
                        unitId: row.auxUnitId ?? row?.opAmount?.auxAmounts[0]?.unit.id,
                      },
                    ],
                    count: rowCount,
                    qrCode: row.qrCode,
                  };

                  return {
                    ...inventory,
                    inventory: row,
                  };
                });

                const checkFlag = haveAuxUnitList
                  ? _.filter(qcMaterials, (node: any) => {
                      return (
                        !Number(node.count) ||
                        node.count.toString().split('.')[1]?.length >
                          node.inventory.opAmount.amount.unit.precisionFigure ||
                        node.count > node.inventory.opAmount.amount.amount ||
                        (!_.isUndefined(node.auxAmounts[0].count) &&
                          !node?.qrCode &&
                          !_.isEmpty(node.inventory?.opAmount?.auxAmounts) &&
                          Number(node.auxAmounts[0].count) < 0) ||
                        (!node?.qrCode &&
                          !_.isEmpty(node.inventory?.opAmount?.auxAmounts) &&
                          _.isNaN(Number(node.auxAmounts[0].count))) ||
                        (!node?.qrCode &&
                          !_.isEmpty(node.inventory?.opAmount?.auxAmounts) &&
                          node.auxAmounts[0].count >
                            node.inventory?.opAmount?.auxAmounts[0]?.amount) ||
                        (!node?.qrCode &&
                          !_.isEmpty(node.inventory?.opAmount?.auxAmounts) &&
                          node.auxAmounts[0].count.toString().split('.')[1]?.length >
                            node.inventory.opAmount?.auxAmounts[0]?.unit?.precisionFigure)
                      );
                    })
                  : _.filter(qcMaterials, (node: any) => {
                      return (
                        !Number(node.count) ||
                        node.count > node.inventory.opAmount.amount.amount ||
                        (!node?.qrCode &&
                          node.count.toString().split('.')[1]?.length >
                            node.inventory.opAmount.amount.unit.precisionFigure)
                      );
                    });

                console.log(checkFlag, 'checkFlag');
                !loading && checkFlag.length === 0 && handelFinish(qcMaterials, qcTaskId);
              }
            }}
          >
            <RecordListLayout<TableRowType>
              placeholder={'请输入物料名称、物料编号'}
              style={{ height: 700 }}
              useIndex={false}
              batchMenu={[]}
              filterContaniner={false}
              useFilterWithUrl={false}
              refreshMarker={refreshMarker}
              selectedRowKeys={selectedRowKeys}
              onSelectedRowKeys={(selectKey: any, selectRows?: any[]) => {
                setSelectRowKeys(selectKey);
                setSelectedRows(selectRows);
              }}
              requestFn={fetchData}
              isLoading={loading}
              {...config}
              configcacheKey={'qcMaterialsTable'}
            />
          </Modal>
        );
      }}
    </Form.List>
  );
};

export default SelectInventory;
