/**
 * 基本信息Columns
 * 产出物料Columns
 * 用料清单Columns
 */
import { Form, Input, InputNumber, Select, DatePicker, FormInstance, Space } from 'antd';
import {
  get,
  toNumber,
  findIndex,
  findLastIndex,
  map,
  omit,
  uniqueId,
  split,
  cloneDeep,
  isNil,
  filter,
  isEmpty,
  head,
  maxBy,
  compact,
} from 'lodash';
import { SearchSelect, MaterialSelect } from 'components';
import moment from 'moment';
import { Big } from 'big.js';

import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/es/table';
import { LabeledValue } from 'antd/lib/select';
import TextArea from 'antd/lib/input/TextArea';

import {
  LaunchTypeEnumDisplayMap,
  RequirementTypeEnumDisplayMap,
  StatusEnumDisplayMap,
} from 'src/dict/planned/mappings';
import { StatusEnum } from 'src/dict/planned';
import { appDict, appEnum } from 'src/dict';
import { arrayIsEmpty } from 'src/utils/array';
import { replaceSign } from 'src/utils/constants';
import { SearchSelect as BlSearchSelect, BatchOperateTableHeader } from 'src/components';
import { ControlType } from 'src/dict/sop';
import { fetchBomGetDetailForWo, fetchBomList } from 'src/api/ytt/med-domain/bom';
import { fetchProcessRouteDetail } from 'src/api/ytt/med-domain/process-route';
import { BatchGenerationTypeEnum } from 'src/dict/productionPlanning';
import {
  fractionLengthCheck,
  numberMinMaxCheck,
  textValidator1,
  textValidator2,
} from 'src/utils/formValidators';
import { YN } from 'src/dict/common';
import { getDepartmentDetail } from 'src/services/organization/userManage';
import { fetchMaterialDetail } from 'src/api/ytt/material-domain/Web_MaterialBaseInfo';
import {
  formatMaterialToForm,
  setAssociatedFormFields,
} from 'src/page/knowledgeManagement/engineerData/bom/utils';
import { OBJECT_OF_ID } from 'src/entity/objectPlatform';
import { MaterialEntity } from 'src/entity/material/index.type';
import { BatchGenerationTypeDisplayMap } from 'src/dict/productionPlanning/mappings';
import { validatorCheckTwoSidesTrim } from 'src/page/custom_fields/fieldsList/components/verificationRules';
import { formatBomInputMaterialsToForm } from 'src/page/knowledgeManagement/engineerData/bom/createAndEdit/subItemMaterialForm';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import BatchSearchSelect from 'src/page/warehouseManagement/batchManagement/components/batchSearchSelect';
import { formatBomOutputMaterialsToForm } from 'src/page/knowledgeManagement/engineerData/bom/createAndEdit/coproductForm';
import {
  formatterProcess,
  formatterProcessConnections,
} from 'src/page/knowledgeManagement/engineerData/processRouting/utils';
import { formatBomFeedingControlToForm } from 'src/page/knowledgeManagement/engineerData/bom/createAndEdit/feedingControlForm';
import { getDemandAmount, getExpectAmount, getStandardAmount } from '../utils';
import { getMaterialProduceUnit, getMaterialUnitInfo } from 'src/entity/material';
import AlternativeMaterialsWrap from './alternativeMaterialsWrap';
import { formatAlternativeMaterials, formatOriginalAlternativeMaterials } from './utils';
import { BOM_OUTPUT_MATERIALS, BOM_INPUT_MATERIALS, PROCESS_PLAN_CO } from '../constants';

interface baseInfoFormProps {
  form: FormInstance;
  name: string;
  type?: string;
  setDisplaySubItemMaterialForm?: (value?: any) => void;
  setSopEnable?: (value?: any) => void;
  sopEnable?: number;
}

const { Option } = Select;

/**
 * 获取行物料其他信息
 */

const baseInfo = (props: baseInfoFormProps) => {
  const { form: formInst, type } = props;

  return [
    {
      title: '基本信息',
      items: compact([
        type === appEnum.Common.CRUD.edit && {
          label: 'id',
          name: ['planOrderBaseCO', 'id'],
          hidden: true,
          render: () => <Input />,
        },
        {
          label: '计划订单编号',
          name: ['planOrderBaseCO', 'code'],
          rules: [
            { required: true, message: '订单编号必填' },
            { max: 255, message: '不超过255字符' },
            { validator: validatorCheckTwoSidesTrim() },
            { validator: textValidator2 },
          ],
          render: () => {
            return <Input />;
          },
        },
        {
          label: '需求类型',
          name: ['planOrderBaseCO', 'requirementType'],
          rules: [{ required: true, message: '需求类型必填' }],
          render: () => {
            return <Select options={RequirementTypeEnumDisplayMap} allowClear />;
          },
        },
        {
          label: '投放类型',
          name: ['planOrderBaseCO', 'launchType'],
          rules: [{ required: true, message: '投放类型必填' }],
          render: () => {
            return <Select options={LaunchTypeEnumDisplayMap} allowClear />;
          },
        },
        {
          label: '状态',
          name: ['planOrderBaseCO', 'status'],
          rules: [{ required: true, message: '业务状态必填' }],
          initialValue: StatusEnum.PLAN,
          render: () => {
            return <Select options={StatusEnumDisplayMap} allowClear disabled />;
          },
        },
        {
          label: '需求时间',
          name: ['planOrderBaseCO', 'requirementTime'],
          initialValue: moment(moment().startOf('day').add(1, 'days').format('YYYY-MM-DD')),
          render: () => {
            return <DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />;
          },
        },
        {
          label: '计划部门',
          name: ['planOrderBaseCO', 'planningDepartmentId'],
          render: () => (
            <UserOrDepartmentSelectWithDialog placeholder={'请选择'} isMultiple={false} />
          ),
        },
        {
          label: '计划员',
          name: ['planOrderBaseCO', 'planningUserId'],
          render: () => (
            <UserOrDepartmentSelectWithDialog
              placeholder="请选择"
              isMultiple={false}
              isSelectUser
            />
          ),
        },
        // {
        //   label: '指定用料',
        //   name: ['planOrderBaseCO', 'specifiedMaterial'],
        //   rules: [{ required: true, message: '指定用料必填' }],
        //   initialValue: appEnum.Common.YN.yes,
        //   render: () => {
        //     return (
        //       <Radio.Group
        //         options={appDict.common.yn}
        //         onChange={(e: any) => {
        //           setDisplaySubItemMaterialForm && setDisplaySubItemMaterialForm(e?.target?.value);
        //         }}
        //       />
        //     );
        //   },
        // },
        {
          label: '计划开始时间',
          name: ['planOrderBaseCO', 'planStartTime'],
          render: () => {
            return <DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />;
          },
        },
        {
          label: '计划完工时间',
          name: ['planOrderBaseCO', 'planFinishTime'],
          rules: [
            {
              validator: (_: any, value: any) => {
                const startAt = formInst.getFieldValue(['planOrderBaseCO', 'planStartTime']);

                if (!value || startAt < value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('计划完工时间必须大于计划开始时间'));
              },
            },
          ],
          render: () => {
            return <DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} />;
          },
        },
        {
          label: '生产部门',
          name: ['planOrderBaseCO', 'productionDepartmentId'],
          render: () => (
            <UserOrDepartmentSelectWithDialog
              placeholder={'请选择'}
              isMultiple={false}
              onChange={(val: any) => {
                if (!arrayIsEmpty(val)) {
                  /** 带出部门上的主管 */
                  getDepartmentDetail(val?.[0]?.value).then((res) => {
                    const { data } = res;

                    formInst.setFields([
                      {
                        name: ['planOrderBaseCO', 'productionSupervisorId'],
                        value: [{ label: data?.owner?.name, value: data?.owner?.id }],
                      },
                    ]);
                  });
                }
              }}
            />
          ),
        },
        {
          label: '生产主管',
          name: ['planOrderBaseCO', 'productionSupervisorId'],
          render: () => (
            <UserOrDepartmentSelectWithDialog
              placeholder="请选择"
              isMultiple={false}
              isSelectUser
            />
          ),
        },
        {
          label: '班次',
          name: ['planOrderBaseCO', 'shiftId'],
          // rules: [{ required: true, message: '班次必选' }],
          render: () => <SearchSelect fetchType="shiftList" />,
        },
        {
          label: '编辑原因',
          name: ['operateReason'],
          rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
          hidden: type !== appEnum.Common.CRUD.edit,
          render: () => <TextArea placeholder="非必填" />,
        },
      ]),
    },
  ];
};

/**
 * 产出物料
 *
 */
export const coproductExtraColumns = (
  props: baseInfoFormProps,
): ColumnProps<any & FormListFieldData>[] => {
  const { form: formInst, name, setSopEnable, sopEnable } = props;

  const { getFieldValue, setFields } = formInst;

  /** 工序是否开启sop */
  const enableSop = !!sopEnable;

  const getMaterialInfo = (fieldKey: number, key?: string, value?: any) => {
    let material = {};

    if (value) {
      material = JSON.parse(value?.value ?? '{}');
    } else {
      material = JSON.parse(getFieldValue([name, fieldKey, 'materialId'])?.value ?? '{}');
    }

    if (key) {
      return get(material, key);
    }

    return material;
  };

  /**
   * 校验行号,不能重复
   */
  const validatorIndex = (rule: any, value: any) => {
    const listValue = formInst.getFieldValue(name);

    const firstIndex = findIndex(listValue, { lineSeq: value });
    const lastIndex = findLastIndex(listValue, { lineSeq: value });

    if (firstIndex != lastIndex) {
      return Promise.reject('不能有重复的行号');
    }

    return Promise.resolve('');
  };

  /**
   * 主产出物料版本号 =》 非主产出+用料清单
   */
  const setBomInputMaterials = (materialId: number, val: LabeledValue, field: any) => {
    fetchBomGetDetailForWo({
      materialId: toNumber(materialId),
      version: String(val?.label),
    })
      .then((res) => {
        const { data } = res;

        const { manufactureDepartmentId, manufactureDepartmentName } = data || {};

        if (manufactureDepartmentId && manufactureDepartmentName) {
          /** 带出部门上的主管 */
          getDepartmentDetail(manufactureDepartmentId).then((res) => {
            const { data } = res;

            setFields([
              {
                name: ['planOrderBaseCO', 'productionSupervisorId'],
                value: [{ label: data?.owner?.name, value: data?.owner?.id }],
              },
              {
                name: ['planOrderBaseCO', 'productionDepartmentId'],
                value: [{ label: manufactureDepartmentName, value: manufactureDepartmentId }],
              },
            ]);
          });
        }

        if (isEmpty(data?.bomInputMaterialLines)) return;
        /** 格式化用料清单 inputProcessId id为所选工序的 sopPlanId */
        const _bomInputMaterials = formatBomInputMaterialsToForm(
          data?.bomInputMaterialLines,
          (item) => {
            const { inputProcessRouteNodeSimpleVO, bomFeedingControls, alternativePlanDetailVO } =
              item;

            return {
              inputProcessId: inputProcessRouteNodeSimpleVO?.id
                ? {
                    label: inputProcessRouteNodeSimpleVO?.processNum,
                    value: inputProcessRouteNodeSimpleVO?.sopPlanId
                      ? `${uniqueId()}-${Number(inputProcessRouteNodeSimpleVO?.sopPlanId)}`
                      : `${uniqueId()}-${Number(inputProcessRouteNodeSimpleVO?.id)}`,
                  }
                : undefined,
              useRatio: alternativePlanDetailVO?.originalAlternativeMaterials?.[0]?.useRatio ?? 100,
              bomFeedingControls: formatBomFeedingControlToForm(
                bomFeedingControls,
                (bomFeedingControlsItem) => {
                  const { inputSopControlDTO } = bomFeedingControlsItem;

                  return {
                    inputSopControlId: inputSopControlDTO?.id
                      ? {
                          label: inputSopControlDTO?.code,
                          value: inputSopControlDTO?.id,
                        }
                      : undefined,
                    id: undefined,
                  };
                },
              ),
              id: undefined,
              workOrderAlternativePlan: {
                substitutionStrategy: alternativePlanDetailVO?.substitutionStrategy,
                originalAlternativeMaterials: formatOriginalAlternativeMaterials(
                  alternativePlanDetailVO?.originalAlternativeMaterials,
                  (item: any) => {
                    const { unitId, material, useRatio } = item;

                    return {
                      materialId: formatMaterialToForm(material),
                      unitId,
                      useRatio: useRatio ?? 100,
                      id: undefined,
                    };
                  },
                ),
                alternativeMaterials: formatAlternativeMaterials(
                  alternativePlanDetailVO?.alternativeMaterials,
                  (item: any) => {
                    const { unitId, material, useRatio } = item;

                    return {
                      materialId: formatMaterialToForm(material),
                      unitId,
                      useRatio: useRatio ?? 0,
                      id: undefined,
                    };
                  },
                ),
              },
            };
          },
        );

        if (arrayIsEmpty(_bomInputMaterials)) {
          return;
        }

        /** 设置用料清单
         *  设置主产出成品率
         */
        setFields([
          {
            name: [BOM_INPUT_MATERIALS],
            value: _bomInputMaterials,
          },
          {
            name: [BOM_OUTPUT_MATERIALS, 0, 'productRate'],
            value: data?.productRate,
          },
          {
            name: [BOM_OUTPUT_MATERIALS, 0, 'unitId'],
            value: data?.unitId,
          },
          {
            name: [BOM_OUTPUT_MATERIALS, 0, 'outputSopControlId'],
            value: {
              key: data?.workReportSopControlDTO?.id,
              label: data?.workReportSopControlDTO?.code,
              value: data?.workReportSopControlDTO?.id,
            },
          },
          {
            name: [BOM_OUTPUT_MATERIALS, 0, 'autoWarehousingFlag'],
            value: data?.autoWarehousingFlag,
          },
          {
            name: [BOM_OUTPUT_MATERIALS, 0, 'warehousing'],
            value: data?.warehousing,
          },
          {
            name: [BOM_OUTPUT_MATERIALS, 0, 'reportingMethods'],
            value: data?.reportingMethods,
          },
        ]);

        /**
         *  设置多产出物料（非主产出
         */
        if (!arrayIsEmpty(data?.bomOutputMaterials)) {
          const mainBomOutputMaterials = map(
            getFieldValue([BOM_OUTPUT_MATERIALS]),
            ({ ...item }) => ({
              ...item,
              standardAmount: 1,
            }),
          );
          const _BomOutputMaterials = formatBomOutputMaterialsToForm(
            data?.bomOutputMaterials,
            (item) => {
              const {
                amount,
                outputProcessProcessRouteNodeSimpleVO,
                outputSopControlDTO,
                autoWarehousingFlag,
                warehousing,
                reportingMethods,
                unitId,
              } = item;

              return {
                standardAmount: amount,
                outputProcessId: outputProcessProcessRouteNodeSimpleVO?.id
                  ? {
                      label: outputProcessProcessRouteNodeSimpleVO?.processNum,
                      value: outputProcessProcessRouteNodeSimpleVO?.sopPlanId
                        ? `${uniqueId()}-${Number(
                            outputProcessProcessRouteNodeSimpleVO?.sopPlanId,
                          )}`
                        : `${uniqueId()}-${Number(outputProcessProcessRouteNodeSimpleVO?.id)}`,
                    }
                  : undefined,
                outputSopControlId: outputSopControlDTO?.id
                  ? {
                      label: outputSopControlDTO?.code,
                      value: outputSopControlDTO?.id,
                    }
                  : undefined,
                id: undefined,
                autoWarehousingFlag,
                warehousing,
                reportingMethods,
                unitId,
              };
            },
          );

          setFields([
            {
              name: [BOM_OUTPUT_MATERIALS],
              value: mainBomOutputMaterials.concat(_BomOutputMaterials),
            },
          ]);
        }

        if (!data?.processRouteSimpleVO) return;

        /** 设置用料清单上绑定的工艺路线 */
        setFields([
          {
            name: [BOM_OUTPUT_MATERIALS, field.fieldKey, 'processRouteId'],
            value: {
              key: data?.processRouteSimpleVO?.id,
              value: data?.processRouteSimpleVO?.id,
              label: data?.processRouteSimpleVO?.code,
            },
          },
        ]);

        /** 拉取工艺路线详set工序计划 */
        setProcessPlanCO({
          key: data?.processRouteSimpleVO?.id,
          value: data?.processRouteSimpleVO?.id,
          label: data?.processRouteSimpleVO?.code,
        });
      })
      .catch((err) => {
        console.log('err: ', err);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  /**
   * 工艺路线版本号 =》 工序计划
   */

  const setProcessPlanCO = async (val: any) => {
    try {
      const id = Number(val?.key);
      const { data } = await fetchProcessRouteDetail({ id });

      if (arrayIsEmpty(data?.processes)) return [];

      /** 去除工序详情中的id */
      const _processes = map(data?.processes, ({ workCenterGroupCOList, processCode, ...rest }) => {
        return {
          ...rest,
          processCode,
          code: processCode,
          /** 去除资源清单中的id */
          workCenterGroupCOList,
          singleWorkReportNum: null,
        };
      });

      /** 去除在制品详情中的id */
      const _processConnections = map(
        data?.processConnections,
        ({ processManufacturedGoods, ...rest }) => {
          return {
            ...rest,
            processManufacturedGoods: {
              ...processManufacturedGoods,
              standardAmount: processManufacturedGoods?.amount,
            },
          };
        },
      );
      const options = {
        workOrderProcessCode: data?.code,
        workOrderProcessName: data?.name,
        workOrderProcessRemark: data?.remark,
        processes: formatterProcess(_processes),
        processConnections: formatterProcessConnections(_processConnections),
      };

      setSopEnable && setSopEnable(data?.enableSop);

      /** 获取最大工序 */
      const mainOutputProcessId: any = maxBy(_processes, 'processSeq');

      /** 工序是否开启sop */
      const enableSop = formInst.getFieldValue(['enableSop']) ?? {};

      /**
       * 获取主产出最大工序的sop
       * 第一次请求工艺路线接口返回的是format前的sop 所以取sop.id
       *  */
      const MainSop = enableSop
        ? `${uniqueId()}-${mainOutputProcessId?.sop?.value || mainOutputProcessId?.sop?.id}`
        : undefined;

      setFields([
        {
          name: PROCESS_PLAN_CO,
          value: options,
        },
        {
          name: 'enableSop',
          value: data?.enableSop,
        },
        {
          name: [name, 0, 'outputProcessId'],
          value: { key: MainSop, value: MainSop, label: mainOutputProcessId?.processNum },
        },
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * 根据物料获取默认版本号
   * @param material
   */
  const getDefaultVersionWithMaterial = async (material: MaterialEntity) => {
    try {
      const materialId = get(material, 'baseInfo.id', undefined);

      if (isNil(materialId)) return undefined;

      const res = await fetchBomList({
        materialIds: [materialId],
        active: appEnum.Common.UsageStatus.enabled,
      });

      const defaultVersion = head(
        filter(get(res, 'data.list', []), (item) => {
          return (
            item?.defaultVersion === appEnum.Common.YN.yes &&
            item?.active === appEnum.Common.UsageStatus.enabled
          );
        }),
      );

      if (isEmpty(defaultVersion)) return undefined;

      return { label: defaultVersion.version, value: defaultVersion.id, key: defaultVersion.id };
    } catch (error) {
      return undefined;
    }
  };

  /**
   * 批号控件
   *
   * parameter  batchGenerationType：number  <规则编号 | 批号 | 不生成>
   */
  const renderProductBatchComp = (batchGenerationType: number, lineFieldName: any) => {
    let displayEle = <Select style={{ width: '100' }} disabled />;
    const materialinfo = getMaterialInfo(lineFieldName);

    switch (batchGenerationType) {
      case BatchGenerationTypeEnum.NUMBERINGRULES: {
        displayEle = (
          <SearchSelect
            labelInValue
            fetchType={'numberingRules'}
            placeholder={'请选择'}
            style={{ width: '100' }}
            searchFieldName={'quickQueryParam'}
            params={{
              enableFlag: appEnum.Common.UsageStatus.enabled,
              suitObjId: OBJECT_OF_ID.batchcode,
            }}
          />
        );
        break;
      }
      case BatchGenerationTypeEnum.BATCH: {
        displayEle = (
          <BatchSearchSelect
            style={{ width: '100' }}
            materialinfo={materialinfo}
            labelInValue
            rendervalue="all"
            saveCallback={(val) => {
              formInst.setFields([
                {
                  name: [name, lineFieldName, 'batch'],
                  value: val,
                },
              ]);
            }}
            params={{ materialIds: [materialinfo?.baseInfo?.id] }}
          />
        );
        break;
      }
      case BatchGenerationTypeEnum.DOESNOTGENERATE: {
        displayEle = <Select disabled style={{ width: '100' }} />;
        break;
      }

      default:
        break;
    }
    return displayEle;
  };

  const changeMaterialValue = async (field: any, value: any) => {
    const materialInfo = getMaterialInfo(field.name, undefined, value);

    const version = await getDefaultVersionWithMaterial(materialInfo);

    if (version?.value && field?.name === 0) {
      const materialId = get(getMaterialInfo(field.name, 'baseInfo'), 'id');

      setBomInputMaterials(Number(materialId), version, field);
    }

    setFields([
      {
        name: [name, field.name, 'version'],
        value: version,
      },
    ]);
  };

  const getDefaultValue = (field: FormListFieldData, fieldStr: string, defaultValue: any) => {
    const hasValue = !isNil(formInst.getFieldValue([name, field.name, fieldStr]));

    if (hasValue) return undefined;

    return defaultValue;
  };

  return [
    {
      title: '行号',
      dataIndex: 'lineSeq',
      width: 120,
      render: (_text, field, index) => {
        return (
          <Form.Item
            initialValue={index ? getDefaultValue(field, 'lineSeq', index * 10) : index}
            name={[field.name, 'lineSeq']}
            fieldKey={[field.fieldKey, 'lineSeq']}
            style={{ marginBottom: '0' }}
            rules={[{ required: true, message: '行号不能为空' }, { validator: validatorIndex }]}
          >
            <InputNumber
              placeholder="请输入"
              min={0}
              precision={0}
              step={5}
              disabled={index === 0}
            />
          </Form.Item>
        );
      },
    },
    {
      title: '主产出',
      dataIndex: 'main',
      width: 120,
      render: (_text, field, index) => {
        const initialValue = index === 0 ? appEnum.Common.YN.yes : appEnum.Common.YN.no;

        return (
          <Form.Item
            initialValue={initialValue}
            name={[field.name, 'main']}
            fieldKey={[field.fieldKey, 'main']}
            style={{ marginBottom: '0' }}
          >
            <Select options={appDict.common.yn} disabled />
          </Form.Item>
        );
      },
    },
    {
      title: <BatchOperateTableHeader titleText="物料编号" />,
      dataIndex: 'materialId',
      width: 200,
      render: (text, field, index) => {
        return (
          <div>
            <Form.Item
              style={{ marginBottom: '0' }}
              fieldKey={[field.fieldKey, 'materialId']}
              name={[field.name, 'materialId']}
              rules={[{ required: index === 0, message: '物料不能为空' }]}
            >
              <MaterialSelect
                params={{
                  enableFlag: appEnum.Common.UsageStatus.enabled,
                  bizRange: [appEnum.Material.BizRange.production],
                }}
                onChange={(value: any) => {
                  const materialInfo = getMaterialInfo(field.name, undefined, value);

                  const unit = getMaterialProduceUnit(materialInfo, true);

                  const batchGenerationType =
                    get(materialInfo, ['productionInfo', 'orderBatchRule']) ??
                    appEnum.ProductionPlanning.BatchGenerationTypeEnum.DOESNOTGENERATE;

                  setFields([
                    {
                      name: [name, field.name, 'unitId'],
                      value: unit?.value,
                    },
                    {
                      name: [name, field.name, 'batchGenerationType'],
                      value: batchGenerationType,
                    },
                  ]);
                  /** 非主产出 */
                  if (index !== 0) return;

                  setSopEnable && setSopEnable(appEnum.Common.UsageStatus.disabled);

                  /** 获取产出物料 */
                  const outputMaterials = formInst.getFieldValue([name]);

                  /** 清空主产出 所有内容清空 */
                  if (!value) {
                    setFields([
                      {
                        name: [name],
                        value: [
                          {
                            lineSeq: 0,
                            main: 1,
                            standardAmount: 1,
                            batchGenerationType:
                              appEnum.ProductionPlanning.BatchGenerationTypeEnum.DOESNOTGENERATE,
                            batch: undefined,
                          },
                        ],
                      },
                      {
                        name: 'enableSop',
                        value: YN.no,
                      },
                    ]);
                  }

                  /** 切换版本号 */
                  setFields([
                    {
                      name: [name],
                      value: !arrayIsEmpty(outputMaterials) && outputMaterials?.splice(0, 1),
                    },
                    { name: [name, field.name, 'version'], value: undefined },
                    { name: [name, field.name, 'processRouteId'], value: undefined },
                    {
                      name: [name, field.name, 'outputProcessId'],
                      value: undefined,
                    },
                    {
                      name: [name, field.name, 'outputSopControlId'],
                      value: undefined,
                    },
                    {
                      name: [name, field.name, 'batchGenerationType'],
                      value: batchGenerationType,
                    },
                    {
                      name: [name, field.name, 'batch'],
                      value: undefined,
                    },
                    {
                      name: 'enableSop',
                      value: YN.no,
                    },
                  ]);

                  formInst.resetFields([PROCESS_PLAN_CO, BOM_INPUT_MATERIALS]);
                  changeMaterialValue(field, value);
                }}
              />
            </Form.Item>
          </div>
        );
      },
    },
    { dataIndex: 'materialName' },
    { dataIndex: 'category' },
    { dataIndex: 'attributes' },
    { dataIndex: 'specification' },
    {
      title: <BatchOperateTableHeader titleText="计划生产数" required />,
      dataIndex: 'plannedAmount',
      width: 130,
      render: (_text, field, index) => {
        return (
          <Form.Item
            dependencies={[
              [name, 0, 'plannedAmount'],
              [name, field.name, 'standardAmount'],
            ]}
            noStyle
          >
            {() => {
              if (index !== 0) {
                const mainPlannedAmount = formInst.getFieldValue([name, 0, 'plannedAmount']);
                const recordStandardAmount = formInst.getFieldValue([
                  name,
                  field.name,
                  'standardAmount',
                ]);

                formInst.setFields([
                  {
                    name: [name, field.name, 'plannedAmount'],
                    value:
                      recordStandardAmount && mainPlannedAmount
                        ? mainPlannedAmount * recordStandardAmount
                        : null,
                  },
                ]);
              }
              const { enablePrecision, precisionFigure } = getMaterialUnitInfo(
                getMaterialInfo(field.name),
                formInst.getFieldValue([name, field.name, 'unitId']),
              );

              return (
                <Form.Item
                  style={{ marginBottom: '0' }}
                  fieldKey={[field.fieldKey, 'plannedAmount']}
                  name={[field.name, 'plannedAmount']}
                  rules={compact([
                    { required: index === 0, message: '计划生产数必填' },
                    {
                      validator: (opt, val) => {
                        return new Promise<void>((resolve, reject) => {
                          const base = (val && val.toString()?.split('.')[0]) ?? val;

                          if (index !== 0) {
                            if (val < 0) reject('计划生产数必须大于0');
                          } else if (val <= 0) reject('计划生产数必须大于0');

                          if (base > 10000000) reject('计划生产数必须小于10000000');
                          return resolve();
                        });
                      },
                    },
                    enablePrecision && { validator: fractionLengthCheck(precisionFigure) },
                  ])}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder="请输入"
                    disabled={index !== 0}
                    stringMode
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },

    {
      title: '预计生产数',
      dataIndex: 'expectAmount',
      width: 120,
      render: (_text, field) => {
        // 计划生产数*成品率
        return (
          <Form.Item
            dependencies={[
              [name, 0, 'plannedAmount'],
              [name, 0, 'productRate'],
              [name, field.name, 'standardAmount'],
            ]}
            noStyle
          >
            {() =>
              getExpectAmount({
                plannedAmount: getFieldValue([name, field.name, 'plannedAmount']),
                productRate: getFieldValue([name, 0, 'productRate']),
              })
            }
          </Form.Item>
        );
      },
    },
    {
      title: '版本号',
      dataIndex: 'version',
      width: 150,
      render: (_text, field, index) => {
        if (index !== 0) {
          return replaceSign;
        }

        return (
          <Form.Item dependencies={[[name, field.name, 'materialId']]} noStyle>
            {() => {
              const materialId = get(getMaterialInfo(field.name, 'baseInfo'), 'id');

              return (
                <Form.Item
                  name={[field.name, 'version']}
                  fieldKey={[field.fieldKey, 'version']}
                  style={{ marginBottom: '0' }}
                >
                  <BlSearchSelect
                    fetchType={'bomVersions'}
                    params={{
                      materialIds: [materialId],
                      active: appEnum.Common.UsageStatus.enabled,
                    }}
                    onChange={(val: LabeledValue) => {
                      /** 切换版本号 清除非主产出 */
                      const outputMaterials = formInst.getFieldValue([name]);

                      /** 因为非主产出不允许操作版本号 所以不用判断 */
                      setFields([
                        {
                          name: [name],
                          value: !arrayIsEmpty(outputMaterials) && outputMaterials?.splice(0, 1),
                        },
                        {
                          name: [name, field.name, 'processRouteId'],
                          value: undefined,
                        },
                        {
                          name: [name, field.name, 'outputProcessId'],
                          value: undefined,
                        },
                        {
                          name: [name, field.name, 'outputSopControlId'],
                          value: undefined,
                        },
                      ]);

                      formInst.resetFields([PROCESS_PLAN_CO, BOM_INPUT_MATERIALS]);

                      val && setBomInputMaterials(Number(materialId), val, field);
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '工艺路线编号',
      dataIndex: 'processRouteId',
      width: 150,
      render: (_text, field, index) => {
        if (index !== 0) {
          return replaceSign;
        }
        return (
          <Form.Item dependencies={[[name, field.name, 'version']]} noStyle>
            {() => {
              const materialId = get(getMaterialInfo(0, 'baseInfo'), 'id')
                ? Number(get(getMaterialInfo(0, 'baseInfo'), 'id'))
                : undefined;

              return (
                <Form.Item
                  name={[field.name, 'processRouteId']}
                  fieldKey={[field.fieldKey, 'processRouteId']}
                  style={{ marginBottom: '0' }}
                >
                  <SearchSelect
                    fetchType={'processRouting'}
                    params={{
                      status: appEnum.Common.UsageStatus.enabled,
                      materialId,
                    }}
                    onChange={(val: LabeledValue) => {
                      setSopEnable && setSopEnable(appEnum.Common.UsageStatus.disabled);
                      // 用料清单
                      const bomInputMaterials = cloneDeep(
                        formInst.getFieldValue([BOM_INPUT_MATERIALS]),
                      );
                      // 产出物料
                      const bomOutputMaterials = cloneDeep(
                        formInst.getFieldValue([BOM_OUTPUT_MATERIALS]),
                      );

                      /** 清空用料清单投料管控 投料控件编号 */
                      const copyBomInputMaterials = setAssociatedFormFields(bomInputMaterials, [
                        ['inputProcessId', undefined],
                        [
                          'bomFeedingControls',
                          (value: any) => setAssociatedFormFields(value, [['inputSopControlId']]),
                        ],
                      ]);

                      /** 清空产出物料 投料控件编号 */
                      const copyBomOutputMaterials = setAssociatedFormFields(bomOutputMaterials, [
                        ['outputProcessId', undefined],
                        ['outputSopControlId', undefined],
                      ]);

                      setFields([
                        {
                          name: [BOM_INPUT_MATERIALS],
                          value: copyBomInputMaterials,
                        },
                        {
                          name: [BOM_OUTPUT_MATERIALS],
                          value: copyBomOutputMaterials,
                        },
                        {
                          name: 'enableSop',
                          value: appEnum.Common.UsageStatus.enabled,
                        },
                      ]);

                      formInst.resetFields([PROCESS_PLAN_CO]);

                      setFields([
                        {
                          name: [name, field.name, 'outputProcessId'],
                          value: undefined,
                        },
                        {
                          name: [name, field.name, 'outputSopControlId'],
                          value: undefined,
                        },
                      ]);

                      val && setProcessPlanCO(val);
                    }}
                    labelPath="code"
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '基准数',
      dataIndex: 'standardAmount',
      width: 120,
      render: (_text, field, index) => {
        return (
          <Form.Item
            initialValue={1}
            name={[field.name, 'standardAmount']}
            fieldKey={[field.fieldKey, 'standardAmount']}
            style={{ marginBottom: '0' }}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder={'请输入'}
              max={10000000}
              min={0}
              disabled={index === 0}
            />
          </Form.Item>
        );
      },
    },
    { dataIndex: 'unitId' },
    {
      title: '成品率',
      dataIndex: 'productRate',
      width: 120,
      render: (_text, field, index) => {
        return (
          <Form.Item noStyle dependencies={[[name, 0, 'productRate']]}>
            {() => {
              if (index !== 0) {
                const mainProductRate = getFieldValue([name, 0, 'productRate']);

                formInst.setFields([
                  {
                    name: [name, field.name, 'productRate'],
                    value: mainProductRate,
                  },
                ]);
              }

              return (
                <Space>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name={[field.name, 'productRate']}
                    fieldKey={[field.fieldKey, 'productRate']}
                    rules={[
                      {
                        validator: numberMinMaxCheck({
                          min: 0,
                          minAllowEqual: false,
                          max: 1000,
                          fieldName: '成品率',
                        }),
                      },
                      { validator: fractionLengthCheck(4) },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder={'请输入'}
                      max={1000}
                      min={0}
                      disabled={index !== 0}
                    />
                  </Form.Item>
                  <span> % </span>
                </Space>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: <BatchOperateTableHeader titleText="生成规则" required />,
      dataIndex: 'batchGeneration',
      width: 150,
      render: (_text, field) => {
        return (
          <Form.Item
            style={{ marginBottom: '0' }}
            dependencies={[[name, field.name, 'materialId']]}
          >
            {() => {
              // 创建需要看物料中是否启用
              const batchManagementEnable = getMaterialInfo(field.name, 'batchManagementEnable');

              return (
                <Form.Item
                  name={[field.name, 'batchGenerationType']}
                  fieldKey={[field.fieldKey, 'batchGenerationType']}
                  rules={[{ required: true, message: '批号不能为空' }]}
                  style={{ marginBottom: 0 }}
                  initialValue={appEnum.ProductionPlanning.BatchGenerationTypeEnum.DOESNOTGENERATE}
                >
                  <Select
                    style={{ width: 100 }}
                    options={
                      batchManagementEnable
                        ? BatchGenerationTypeDisplayMap
                        : BatchGenerationTypeDisplayMap.filter((e) => e.value === 3)
                    }
                    allowClear
                    onChange={(val: number) => {
                      formInst.setFields([{ name: [name, field.name, 'batch'], value: undefined }]);

                      if (
                        val === appEnum.ProductionPlanning.BatchGenerationTypeEnum.NUMBERINGRULES
                      ) {
                        /** 默认带出物料上的编号规则 */
                        const materialId = get(getMaterialInfo(field.name, 'baseInfo'), 'id');

                        fetchMaterialDetail({ id: materialId }).then((res) => {
                          const { data } = res;

                          const batchSelectValue = {
                            key: data?.batchNoRule?.id,
                            value: data?.batchNoRule?.id,
                            label: data?.batchNoRule?.name,
                          };

                          formInst.setFields([
                            { name: [name, field.name, 'batch'], value: batchSelectValue },
                          ]);
                        });
                      }
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: <BatchOperateTableHeader titleText="批次号" />,
      dataIndex: 'batch',
      width: 200,
      render: (_text, field) => {
        return (
          <Form.Item
            style={{ marginBottom: '0' }}
            dependencies={[
              [name, field.name, 'materialId'],
              [name, field.name, 'batchGenerationType'],
            ]}
          >
            {() => {
              const batchGenerationType = formInst.getFieldValue([
                name,
                field.name,
                'batchGenerationType',
              ]);

              return (
                <Form.Item
                  name={[field.name, 'batch']}
                  fieldKey={[field.fieldKey, 'batch']}
                  noStyle
                  style={{ marginBottom: 0 }}
                >
                  {renderProductBatchComp(batchGenerationType, field.name)}
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '报工工序号',
      dataIndex: 'outputProcessId',
      width: 150,
      render: (text, field, index) => {
        return (
          <Form.Item
            style={{ marginBottom: '0' }}
            dependencies={[
              [name, field.name, 'materialId'],
              [name, field.name, 'version'],
              [name, field.name, 'processRouteId'],
              [PROCESS_PLAN_CO],
              ['enableSop'],
            ]}
          >
            {() => {
              /** 页面初始值以及工序暂存值 */
              const processesList = formInst.getFieldValue([PROCESS_PLAN_CO, 'processes']) ?? [];

              /** 获取最大工序 */
              const mainOutputProcessId: any = maxBy(processesList, 'processSeq');

              /** 工序是否开启sop */
              const enableSop = formInst.getFieldValue(['enableSop']) ?? {};

              /**
               * 获取主产出最大工序的sop
               * 第一次请求工艺路线接口返回的是format前的sop 所以取sop.id
               *  */
              const MainSop = enableSop
                ? `${uniqueId()}-${mainOutputProcessId?.sop?.value || mainOutputProcessId?.sop?.id}`
                : undefined;

              /** 设置主产出工序号 */
              setFields([
                {
                  name: [name, 0, 'outputProcessId'],
                  value: { key: MainSop, value: MainSop, label: mainOutputProcessId?.processNum },
                },
              ]);

              /** 主产出可选范围为工序 */
              return (
                <Form.Item
                  style={{ marginBottom: '0' }}
                  fieldKey={[field.fieldKey, 'outputProcessId']}
                  name={[field.name, 'outputProcessId']}
                >
                  <Select
                    allowClear
                    showArrow
                    onChange={() => {
                      formInst.setFields([
                        { name: [name, field.name, 'outputSopControlId'], value: undefined },
                      ]);
                    }}
                    placeholder={'请选择'}
                    labelInValue
                    disabled={arrayIsEmpty(processesList) || index === 0}
                  >
                    {processesList.map(({ processNum, sop }: any) => (
                      <Option
                        key={enableSop ? `${uniqueId()}-${sop?.value}` : uniqueId()}
                        value={enableSop ? `${uniqueId()}-${sop?.value}` : uniqueId()}
                      >
                        {processNum}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    { dataIndex: 'reportingMethods' },
    { dataIndex: 'warehousing' },
    { dataIndex: 'autoWarehousingFlag' },
    enableSop
      ? {
          title: '报工控件编号',
          dataIndex: 'outputSopControlId',
          width: 200,
          render: (text, field) => {
            return (
              <Form.Item
                noStyle
                dependencies={[[name, field.name, 'outputProcessId'], ['enableSop']]}
              >
                {() => {
                  const outputProcessId = formInst.getFieldValue([
                    name,
                    field.name,
                    'outputProcessId',
                  ]);

                  /** 工序是否开启sop */
                  const enableSop = formInst.getFieldValue(['enableSop']) ?? {};

                  if (!enableSop || !Number(split(outputProcessId?.value, '-')?.[1])) {
                    return replaceSign;
                  }

                  return (
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      fieldKey={[field.fieldKey, 'outputSopControlId']}
                      name={[field.name, 'outputSopControlId']}
                      rules={[
                        {
                          required: enableSop && split(outputProcessId?.value, '-')?.[1],
                          message: '报工控件编号不能为空',
                        },
                      ]}
                    >
                      <SearchSelect
                        params={{
                          id: Number(split(outputProcessId?.value, '-')?.[1]) ?? undefined,
                          controlType: ControlType.report,
                        }}
                        disabled={!enableSop}
                        fetchType="sopBizControl"
                        labelPath="code"
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            );
          },
        }
      : {},
    { dataIndex: 'singleWorkReportAmount' },
    // { dataIndex: 'id' },
  ];
};

/**
 * 物料清单
 *
 */
export const subItemMaterialExtraColumns = (
  props: baseInfoFormProps,
): ColumnProps<any & FormListFieldData>[] => {
  const { form: formInst, name } = props;
  const { getFieldValue } = formInst;

  return compact([
    // { dataIndex: 'id' },
    { dataIndex: 'seq' },
    { dataIndex: 'materialId' },
    { dataIndex: 'materialName' },
    { dataIndex: 'attributes' },
    { dataIndex: 'specification' },
    { dataIndex: 'category' },
    { dataIndex: 'inputAmountNumerator' },
    { dataIndex: 'inputAmountDenominator' },
    {
      title: '标准用量',
      dataIndex: '标准用量',
      width: 120,
      render: (_text, field) => {
        return (
          <Form.Item
            dependencies={[
              [BOM_OUTPUT_MATERIALS, 0, 'plannedAmount'],
              [name, field.name, 'inputAmountNumerator'],
              [name, field.name, 'inputAmountDenominator'],
              [name, field.name, 'useRatio'],
            ]}
            noStyle
          >
            {() => {
              const useRatio = getFieldValue([name, field.name, 'useRatio']) ?? 0;
              const plannedAmount = getFieldValue([BOM_OUTPUT_MATERIALS, 0, 'plannedAmount']) ?? 0;

              const mainPlannedAmount = new Big(plannedAmount)
                .times(new Big(useRatio))
                .div(new Big('100'));

              return getStandardAmount({
                mainPlannedAmount: Number(mainPlannedAmount),
                inputAmountNumerator: getFieldValue([name, field.name, 'inputAmountNumerator']),
                inputAmountDenominator: getFieldValue([name, field.name, 'inputAmountDenominator']),
              });
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '需求数',
      dataIndex: '需求数',
      width: 120,
      render: (_text, field) => {
        return (
          <Form.Item
            dependencies={[
              [BOM_OUTPUT_MATERIALS, 0, 'plannedAmount'],
              [name, field.name, 'inputAmountNumerator'],
              [name, field.name, 'inputAmountDenominator'],
              [name, field.name, 'lossRate'],
              [name, field.name, 'useRatio'],
            ]}
            noStyle
          >
            {() => {
              const useRatio = getFieldValue([name, field.name, 'useRatio']) ?? 0;
              const plannedAmount = getFieldValue([BOM_OUTPUT_MATERIALS, 0, 'plannedAmount']) ?? 0;

              const mainPlannedAmount = new Big(plannedAmount)
                .times(new Big(useRatio))
                .div(new Big('100'));

              return getDemandAmount({
                mainPlannedAmount: Number(mainPlannedAmount),
                inputAmountNumerator: getFieldValue([name, field.name, 'inputAmountNumerator']),
                inputAmountDenominator: getFieldValue([name, field.name, 'inputAmountDenominator']),
                lossRate: getFieldValue([name, field.name, 'lossRate']),
              });
            }}
          </Form.Item>
        );
      },
    },
    { dataIndex: 'unitId' },
    { dataIndex: 'lossRate' },
    { dataIndex: 'version' },

    {
      title: '需求时间',
      dataIndex: 'requirementTime',
      width: 250,
      render: (_text, field) => {
        return (
          <Form.Item
            name={[field.name, 'requirementTime']}
            fieldKey={[field.fieldKey, 'requirementTime']}
            style={{ marginBottom: '0' }}
          >
            <DatePicker showTime format={'YYYY-MM-DD HH:mm:ss'} style={{ width: 220 }} />
          </Form.Item>
        );
      },
    },
    { dataIndex: 'pickMode' },
    {
      title: '指定供应商',
      dataIndex: 'supplierList',
      width: 120,
      render: (_text, field) => {
        return (
          <Form.Item
            name={[field.name, 'supplierList']}
            fieldKey={[field.fieldKey, 'supplierList']}
            style={{ marginBottom: '0' }}
          >
            {/* <InputNumber placeholder="请输入" min={1} precision={0} step={5} /> */}
            <SearchSelect
              fetchType={'supplier'}
              labelInValue
              params={{ status: [appEnum.Common.UsageStatus.enabled] }}
              mode="multiple"
            />
          </Form.Item>
        );
      },
    },
    // { dataIndex: 'inputProcessId' },
    { dataIndex: 'specificProcessInput' },
    {
      title: '投料工序号',
      dataIndex: 'inputProcessId',
      width: 150,
      render: (text, field, index) => {
        return (
          <Form.Item
            style={{ marginBottom: '0' }}
            dependencies={[
              [name, field.name, 'specificProcessInput'],
              [BOM_OUTPUT_MATERIALS, 0, 'materialId'],
              [BOM_OUTPUT_MATERIALS, 0, 'version'],
              [PROCESS_PLAN_CO],
              ['enableSop'],
            ]}
          >
            {() => {
              /** 页面初始值以及工序暂存值 */
              const processesList = formInst.getFieldValue([PROCESS_PLAN_CO, 'processes']) ?? [];

              const specificProcessInput = formInst.getFieldValue([
                name,
                field.name,
                'specificProcessInput',
              ]);

              return (
                <Form.Item
                  style={{ marginBottom: '0' }}
                  fieldKey={[field.fieldKey, 'inputProcessId']}
                  name={[field.name, 'inputProcessId']}
                >
                  <Select
                    allowClear
                    showArrow
                    onChange={() => {
                      formInst.setFields([
                        { name: [name, field.name, 'outputSopControlId'], value: undefined },
                      ]);
                    }}
                    placeholder={'请选择'}
                    labelInValue
                    disabled={(arrayIsEmpty(processesList) && index === 0) || !specificProcessInput}
                  >
                    {processesList.map(({ processNum, sop }: any) => (
                      <Option
                        key={sop?.value ? `${uniqueId()}-${sop?.value}` : uniqueId()}
                        value={sop?.value ? `${uniqueId()}-${sop?.value}` : uniqueId()}
                      >
                        {processNum}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    { dataIndex: 'inputProcessSeq' },
    { dataIndex: 'bomFeedingControls' },
    { dataIndex: 'splitSopControlInput' },
    { dataIndex: 'remark' },
    {
      title: '使用比例',
      dataIndex: 'useRatio',
      width: 120,
      render: (text, field) => {
        return (
          <Form.Item noStyle dependencies={[[name, field.name, 'workOrderAlternativePlan']]}>
            {() => {
              return (
                <Form.Item
                  style={{ marginBottom: '0' }}
                  fieldKey={[field.fieldKey, 'useRatio']}
                  name={[field.name, 'useRatio']}
                  initialValue={100}
                >
                  <Input disabled />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '替代方案',
      dataIndex: 'workOrderAlternativePlan',
      width: 150,
      render: (text, field) => {
        return (
          <Form.Item
            noStyle
            dependencies={[
              [name, field.name, 'splitProcessInput'],
              [name, field.name, 'materialId'],
            ]}
          >
            {() => {
              const { specificProcessInput, materialId } =
                formInst.getFieldValue([name, field.name]) ?? {};

              if (!specificProcessInput || !materialId) {
                return replaceSign;
              }

              return (
                <Form.Item
                  style={{ marginBottom: '0' }}
                  fieldKey={[field.fieldKey, 'workOrderAlternativePlan']}
                  name={[field.name, 'workOrderAlternativePlan']}
                >
                  <AlternativeMaterialsWrap field={field} form={formInst} name={name} />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
  ]);
};

export default baseInfo;
