import { DatePicker, Form, FormInstance, Modal } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';

const scheduling = (form: FormInstance, onOk: () => any) => {
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current < moment() || current > moment().add(30, 'days');
  };

  Modal.confirm({
    title: '排程',
    width: 500,
    content: (
      <Form form={form}>
        <Form.Item
          label="排程截止日期"
          name="deadlineTime"
          initialValue={moment(moment().startOf('day').add(1, 'days').format('YYYY-MM-DD'))}
        >
          <DatePicker
            disabledDate={disabledDate}
            format={'YYYY-MM-DD HH:mm:ss'}
            placeholder={'请输入'}
            showTime
          />
        </Form.Item>
      </Form>
    ),
    onOk,
    okText: '开始排程',
    cancelText: '取消',
  });
};

export default scheduling;
