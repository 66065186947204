import type { SupplierListItemProps } from '../interface';
import SupplierDetailBaseInfo from './supplierDetailBaseInfo';

interface SupplierDetailProps {
  supplierData: SupplierListItemProps;
  onClose: () => void;
  refresh: () => void;
  goToEdit: () => void;
}

/** 供应商详情 */
const SupplierDetail = (props: SupplierDetailProps) => {
  // const { supplierData, refresh, goToEdit, onClose } = props;

  return <SupplierDetailBaseInfo {...props} />;
};

export default SupplierDetail;
