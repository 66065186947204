/**
 * @mappings 通用的名-值映射表
 */

import {
  BizType,
  BatchAttrsType,
  FirstInFirstOutRules,
  FeedingQualityStatus,
  TabType,
  UnitType,
  BizRange,
  RoundingRule,
  BatchAttrs,
  FeedingType,
} from '.';
import { mappingsFactory } from '../utils';

export const tabType = mappingsFactory(['基本信息', TabType.basic]);
export const tabCustom = mappingsFactory(['自定义信息', TabType.customize]);
export const unitType = mappingsFactory(
  ['主单位', UnitType.mainUnit],
  ['转换单位', UnitType.conversionUnit], // 固定单位（叫法已废弃）
  ['辅助单位', UnitType.auxUnit], // 浮动单位（叫法已废弃）
);
export const bizType = mappingsFactory(
  ['默认', BizType.default],
  ['虚拟件', BizType.phantom],
  ['在制品', BizType.workInProgress],
);
export const bizRange = mappingsFactory(
  ['仓储', BizRange.warehousing],
  ['采购', BizRange.purchase],
  // 待物料调整之后添加
  ['销售', BizRange.sales],
  ['自制', BizRange.production],
  // 一期没有质量
  ['质量', BizRange.quality],
  ['投料', BizRange.manufacture],
);
export const BizRange_DEFAULT = [
  BizRange.warehousing,
  BizRange.purchase,
  // 待物料调整之后添加
  BizRange.sales,
  BizRange.production,
  // 一期没有质量
  BizRange.quality,
  BizRange.manufacture,
];
/** 物料 【批次/库存】属性名称 */
export const batchAttrs = mappingsFactory(
  ['供应商批次', BatchAttrs.supplierBatch],
  ['供应商', BatchAttrs.supplier],
  ['客户', BatchAttrs.customer],
  ['生产日期', BatchAttrs.productionDate],
  ['有效期至', BatchAttrs.validUntil],
  ['入厂日期', BatchAttrs.entryDate],
  ['货主', BatchAttrs.consignor],
);
/** 物料 【批次/库存】属性类型 */
export const batchAttrsType = mappingsFactory(
  ['单行文本', BatchAttrsType.singleText],
  ['关联关系', BatchAttrsType.connectionRelation],
  ['日期时间', BatchAttrsType.dateTime],
);
/** 物料仓储 先进先出属性 */
export const firstInFirstOutRules = mappingsFactory(
  ['批次号', FirstInFirstOutRules.batchNumber],
  ['生产日期', FirstInFirstOutRules.productionDate],
  ['有效期至', FirstInFirstOutRules.validUntil],
);
/** 物料投料 投料质量 */
export const feedingQualityStatus = mappingsFactory(
  ['合格', FeedingQualityStatus.qualified],
  ['不合格', FeedingQualityStatus.unqualified],
  ['让步合格', FeedingQualityStatus.concession],
  ['待检', FeedingQualityStatus.pending],
);
/** 物料单位舍入规则 */
export const roundingRule = mappingsFactory(
  ['四舍五入', RoundingRule.round],
  ['奇进偶舍', RoundingRule.bankerRounding],
  ['进位', RoundingRule.floor],
  ['舍位', RoundingRule.ceil],
);

/** 投料类型 */
export const feedingTypeMap = mappingsFactory(
  ['投料', FeedingType.feeding],
  ['投料-添加申请', FeedingType.feedingAddApplication],
);
