import {
  BUSINESS_THEME,
  BUSSINESS_DETAIL_THEME,
  PROCURE_DETAIL_THEME,
  PRODUCT_DETAIL_THEME,
  QUALITY_DETAIL_THEME,
  RELATION_SHIP,
  RESOURCE_DETAIL_THEME,
  SALES_DETAIL_THEME,
  STATUS,
  STORE_DETAIL_THEME,
  WORK_CALENDAR_DETAIL_THEME,
} from '.';
import { mappingsFactory } from '../utils';

export const relationShip = mappingsFactory(
  ['1:1', RELATION_SHIP.onToOne],
  ['1:N', RELATION_SHIP.onToManay],
);

export const businessTheme = mappingsFactory(
  ['生产主题', BUSINESS_THEME.product],
  ['质量主题', BUSINESS_THEME.quality],
  ['仓储主题', BUSINESS_THEME.store],
  ['资源主题', BUSINESS_THEME.resource],
  ['销售主题', BUSINESS_THEME.sales],
  ['采购主题', BUSINESS_THEME.procure],
  ['业务建模', BUSINESS_THEME.businessModeling],
  ['工作日历', BUSINESS_THEME.workCalendar],
);

export const product = {
  [PRODUCT_DETAIL_THEME.projectData]: '工程数据',
  [PRODUCT_DETAIL_THEME.productPlan]: '生产计划',
  [PRODUCT_DETAIL_THEME.productExecute]: '生产执行',
};

export const quality = {
  [QUALITY_DETAIL_THEME.qcConfig]: '检验方案',
  [QUALITY_DETAIL_THEME.qcTask]: '检验任务',
};

export const store = {
  [STORE_DETAIL_THEME.store]: '库存',
  [STORE_DETAIL_THEME.batch]: '批次',
  [STORE_DETAIL_THEME.bound]: '出入库',
  [STORE_DETAIL_THEME.allocate]: '出库存调拨',
  [STORE_DETAIL_THEME.adjust]: '库存调整',
};

export const resource = {
  [RESOURCE_DETAIL_THEME.equipment]: '设备',
};

export const sales = {
  [SALES_DETAIL_THEME.customer]: '客户',
  [SALES_DETAIL_THEME.salesOrder]: '销售订单',
};

export const procure = {
  [PROCURE_DETAIL_THEME.supplier]: '供应商',
};

export const businessModeling = {
  [BUSSINESS_DETAIL_THEME.material]: '物料定义',
  [BUSSINESS_DETAIL_THEME.unit]: '单位定义',
};

export const workCalendar = {
  [WORK_CALENDAR_DETAIL_THEME.workCalendar]: '工作日历',
  [WORK_CALENDAR_DETAIL_THEME.shiftList]: '班次',
  [WORK_CALENDAR_DETAIL_THEME.scheduling]: '排班',
};

export const DetailTheme = {
  [BUSINESS_THEME.product]: product,
  [BUSINESS_THEME.quality]: quality,
  [BUSINESS_THEME.store]: store,
  [BUSINESS_THEME.resource]: resource,
  [BUSINESS_THEME.sales]: sales,
  [BUSINESS_THEME.procure]: procure,
  [BUSINESS_THEME.businessModeling]: businessModeling,
  [BUSINESS_THEME.workCalendar]: workCalendar,
};

export const status = mappingsFactory(['启用中', STATUS.enabled], ['停用中', STATUS.disabled]);

/** 根据当前状态返回动作名, 如: 当前状态为『启用中』, 则返回『停用』 */
export const changeStatusAction = mappingsFactory(
  ['启用', STATUS.disabled],
  ['停用', STATUS.enabled],
);
