import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Select, Tag, Form, message, Modal } from 'antd';
import { FilterItem, RecordListLayout } from 'layout';
import { BlIcon, LinkTooltip, SearchSelect, ThumbnailImage, Tooltip } from 'components';
import BcBlMultiCascader from 'src/page/knowledgeManagement/materialModeling/material/share/Cascader/BcBlMultiCascader';
import _ from 'lodash';
import { gcObject, fieldTypeList } from 'utils';
import lookup, { appDict } from 'src/dict';
import {
  fetchInventoryElementListPage,
  FetchInventoryElementListPageResponse,
} from 'src/api/ytt/inventory-domain';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import BcBlCascader from 'src/components/Cascader';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import authDict, { hasAuth } from 'src/utils/auth';
import { fetchMatchRuleQueryMatchTemplate } from 'src/api/ytt/e-report-domain/matchRule';
import { PrintContent } from './printContent';

type TableRowType = Exclude<
  FetchInventoryElementListPageResponse['data'],
  undefined
>['list'][number];

interface Props extends RouteComponentProps {}

const InventoryList = (props: Props) => {
  const { history } = props;
  const [selectedRowKeys, setSelectRowKeys] = useState<(number | string)[]>([]);
  const [auxAmountMaxItem, setAuxAmountMaxItem] = useState<any[]>([]);
  const [conversionAmounts, setConversionAmounts] = useState<any[]>([]);

  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const requestFn = async (param: any) => {
    const res = await fetchInventoryElementListPage(param);

    if (res && res.code === 200) {
      const { list = [] } = res.data || {};
      const maxItem = _.maxBy(list, (o) => o?.opAmount?.auxAmounts?.length);
      const maxConversionItem = _.maxBy(list, (o) => o?.opAmount?.conversionAmounts?.length);

      setAuxAmountMaxItem(maxItem?.opAmount?.auxAmounts || []);
      setConversionAmounts(maxConversionItem?.opAmount?.conversionAmounts || []);
    }
    return res;
  };
  const toDetail = (id?: number) => {
    if (!id) {
      return;
    }
    history.push(`/warehouseManagement/inventoryManagement/inventoryList/detail?id=${id}`);
  };
  const dataColumns = [
    {
      title: '物料名称',
      dataIndex: 'materialName',
      sorter: true,
      isFilter: true,
      width: 200,
      render: (value: string, record: TableRowType, index: number, config: any) => (
        <LinkTooltip
          to={`/warehouseManagement/inventoryManagement/inventoryList/detail?id=${record?.id}`}
          text={record.material?.name || ''}
          width={config.contentWidth}
          auth={authDict.inventoryelement_detail}
        />
      ),
      renderItem: <SearchSelect labelInValue fetchType={'material'} />,
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '物料编号',
      dataIndex: 'materialCode',
      sorter: true,
      width: 200,
      type: fieldTypeList.select,
      render: (value: any, record: TableRowType) => record.material?.code,
    },
    {
      title: '物料属性',
      dataIndex: 'materialAttr',
      defaultColConfig: {
        display: false,
      },
      isFilter: true,
      width: 200,
      type: fieldTypeList.select,
      render: (value: any, record: TableRowType) => {
        const attribute = record.material?.attrList;

        return attribute && attribute.length > 0
          ? attribute.map((item) => {
              const { name, attributeItem } = item;

              return (
                <span>
                  <Tag>
                    {name}: {attributeItem?.content}
                  </Tag>
                </span>
              );
            })
          : '-';
      },
      renderItem: <BcBlMultiCascader type={'materialProperty'} nameLabel={'物料属性'} />,
    },
    {
      title: '物料规格',
      dataIndex: 'specification',
      width: 200,
      type: fieldTypeList.select,
      render: (value: any, record: TableRowType) => record.material?.specification,
    },
    {
      title: '物料分类',
      dataIndex: 'category',
      isFilter: true,
      width: 150,
      renderItem: (
        <BcBlCascader
          fetchType={'materialCategory'}
          nameLabel={'物料分类'}
          popupPlacement={'bottomRight'}
        />
      ),
      render: (text: any, record: TableRowType) => {
        return record?.material?.categoryName;
      },
      inputProps: {
        placeholder: '请输入关键字',
      },
    },
    {
      title: '物料图片',
      dataIndex: ['material', 'documents'],
      defaultColConfig: {
        display: false,
      },
      width: 200,
      render: (data: any[]) => {
        return data?.length > 0 ? (
          <div style={{ display: 'flex' }}>
            {data?.map((item) => {
              return (
                <ThumbnailImage
                  documentId={item?.documentId}
                  key={item?.documentId}
                  documentPath={item?.documentPath}
                />
              );
            })}
          </div>
        ) : (
          '-'
        );
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      defaultColConfig: {
        display: false,
      },
      render: (text: any, record: TableRowType) => record?.material?.remark,
      width: 200,
    },
    {
      title: '仓库',
      dataIndex: 'warehouse',
      isFilter: true,
      sorter: true,
      width: 200,
      renderItem: <SearchSelect mode="multiple" labelInValue fetchType="warehouse" />,
      render: (value: any, record: TableRowType) => record.location?.warehouse?.name,
    },
    {
      title: '仓位',
      dataIndex: 'storageLocationId',
      sorter: true,
      isFilter: true,
      width: 200,
      renderItem: <SearchSelect mode="multiple" labelInValue fetchType="storageLocation" />,
      render: (value: any, record: TableRowType) => record.location?.storage?.name,
    },
    {
      title: '数量',
      dataIndex: 'amount',
      width: 100,
      render: (value: any, record: TableRowType) => {
        const { amount, unit = {} } = record.opAmount?.amount || {};

        if (typeof amount === 'undefined') {
          return '-';
        }
        return `${amount} ${unit?.name}`;
      },
    },
    ...auxAmountMaxItem.map((item: any, i: number): any => {
      return {
        title: '辅助数量',
        dataIndex: `auxAmounts${String(i)}`,
        width: 120,
        render: (value: any, record: any) => {
          const { amount, unit = {} } = record.opAmount?.auxAmounts?.[i] || {};

          if (typeof amount === 'undefined') {
            return '-';
          }
          return `${amount} ${unit?.name}`;
        },
      };
    }),
    ...conversionAmounts?.map((item: any, i: number): any => {
      return {
        title: `转换数量${i + 1}`,
        dataIndex: `converAmount${String(i)}`,
        width: 120,
        render: (value: any, record: any) => {
          const { amount, unit = {} } = record.opAmount?.conversionAmounts?.[i] || {};

          if (typeof amount === 'undefined') {
            return '-';
          }
          return `${amount} ${unit?.name}`;
        },
      };
    }),
    {
      title: '位置状态',
      dataIndex: 'storageStatus',
      width: 120,
      isFilter: true,
      type: fieldTypeList.select,
      render: lookup('bound.storageStatus'),
      selectProps: {
        mode: 'multiple',
        options: appDict.bound.storageStatus,
      },
    },
    {
      title: '质量状态',
      dataIndex: 'qcStatus',
      type: fieldTypeList.select,
      width: 120,
      isFilter: true,
      render: lookup('bound.qualityStatus'),
      selectProps: {
        mode: 'multiple',
        options: appDict.bound.qualityStatus,
      },
    },
    {
      title: '业务状态',
      dataIndex: 'bizStatus',
      width: 120,
      isFilter: true,
      type: fieldTypeList.select,
      render: lookup('bound.inventoryBizStatus'),
      selectProps: {
        mode: 'multiple',
        options: appDict.bound.inventoryBizStatus,
      },
    },
    {
      title: '批次号',
      dataIndex: 'batchNo',
      width: 150,
      sorter: true,
      isFilter: true,
      render: (value: any, record: TableRowType) => record.bizKey?.batchNo,
    },
    {
      title: '标识码',
      dataIndex: 'qrCode',
      isFilter: true,
      sorter: true,
      width: 150,
      render: (value: string) => value,
    },
    {
      title: '供应商',
      dataIndex: 'supplier',
      width: 150,
      render: (value: any, record: TableRowType) => record.bizKey?.supplier?.name,
    },
    {
      title: '供应商批次',
      dataIndex: 'supplierBatchNo',
      width: 150,
      render: (value: any, record: TableRowType) => record.bizKey?.supplierBatchNo,
    },
    {
      title: '客户',
      dataIndex: 'customer',
      width: 150,
      render: (value: any, record: TableRowType) => record.bizKey?.customer?.name,
    },
    {
      title: '入厂日期',
      dataIndex: 'inboundTime',
      width: 180,
      render: (value: any, record: TableRowType) => {
        if (!record.bizKey?.inboundTime) {
          return '-';
        }
        return formatTimeForRender(record.bizKey?.inboundTime);
      },
    },
    {
      title: '生产日期',
      width: 180,
      dataIndex: 'productTime',
      render: (value: any, record: TableRowType) => {
        if (!record.bizKey?.productTime) {
          return '-';
        }
        return formatTimeForRender(record.bizKey?.productTime);
      },
    },
    {
      title: '有效期至',
      dataIndex: 'validityPeriod',
      width: 180,
      render: (value: any, record: TableRowType) => {
        if (!record.bizKey?.validityPeriod) {
          return '-';
        }
        return formatTimeForRender(record.bizKey?.validityPeriod);
      },
    },
  ];

  const config = {
    rowKey: (record: TableRowType) => String(record.id),
    getOperationList: (record: TableRowType) => {
      return [
        {
          title: '查看',
          auth: authDict.inventoryelement_detail,
          onClick: () => toDetail(record.id),
        },
      ];
    },
    columns: dataColumns,
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((columns: any): FilterItem => {
        const filter = {
          label: columns.title,
          name: columns.dataIndex,
          ...columns,
        };

        return filter;
      }),
    formatDataToFormDisplay: (filterData: any) => {
      return gcObject.filterEmptyProperty(filterData);
    },
    formatDataToQuery: (param: any) => {
      const { materialName, storageLocationId, warehouse, ...rest } = param;

      const options = {
        warehouseId: warehouse ? _.map(warehouse, 'value') : undefined,
        storageLocationId: storageLocationId ? _.map(storageLocationId, 'value') : undefined,
        materialId: materialName?.value,
        ...rest,
      };

      const relParams = gcObject.filterEmptyProperty(options);

      dispatch.excelImport.updateBusinessData({ businessData: relParams });
      return relParams;
    },
  };

  const mainMenu = [
    {
      title: '导出',
      showExport: true,
      auth: authDict.inventoryelement_export,
      icon: <BlIcon type="icondaochu" />,
      onClick: () =>
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.materialInventory,
        }),
    },
    {
      title: '导入',
      showExport: true,
      auth: authDict.inventoryelement_import,
      icon: <BlIcon type="icondaoru" />,
      onClick: () =>
        openModal({
          optType: 'import',
          businessType: BUSINESS_TYPE.materialInventory,
        }),
    },
  ];

  const batchMenu = [
    {
      title: '打印',
      key: 'print',
      auth: authDict.inventoryelement_batch_print,
      onClick: (onSuccess: any, onFail: any) => {
        if (selectedRowKeys?.length > 100) {
          message.error('一次最多打印100条数据');
          onFail();
          return;
        }
        Modal.info({
          title: '请选择打印模版：',
          content: <PrintContent selectedRowKeys={selectedRowKeys} />,
          width: 500,
          afterClose: () => {
            onSuccess();
            Modal.destroyAll();
          },
          okText: '确认',
          closable: true,
        });
      },
    },
  ];

  return (
    <>
      <RecordListLayout<TableRowType>
        placeholder={'请输入编号关键字'}
        useIndex={false}
        batchMenu={batchMenu}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeys={(selectKey) => {
          setSelectRowKeys(selectKey);
        }}
        mainMenu={mainMenu}
        requestFn={requestFn}
        configcacheKey="inventoryModule"
        {...config}
      />
    </>
  );
};

export default InventoryList;
