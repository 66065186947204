import React, { useEffect, useState } from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import DetailsBaseInfo from './detailsBaseInfo';
import CreateAndEdit from '../createAndEdit/createAndEdit';
import { CRUD } from 'src/dict/common';

interface Props extends RouteComponentProps {
  match: match<{ id: string }>;
}
const DetailsPage = (props: Props) => {
  const { match: m } = props;
  const [createAndeditVisible, setCreateAndeditVisible] = useState(false);
  const [operationRecordVisible, setOperationRecordVisible] = useState(false);
  const [id, setId] = useState<number | undefined>(undefined);
  const [, setRefreshDetailsMarker] = useState<number | undefined>(undefined);

  useEffect(() => {
    setId(Number(m.params.id));
  }, []);
  return (
    <div style={{ background: '#fff', height: '100%' }}>
      <DetailsBaseInfo
        id={Number(id)}
        showEdit={() => {
          setCreateAndeditVisible(true);
        }}
        showOperationRecord={() => {
          setOperationRecordVisible(true);
        }}
        refreshDetailsMarker={new Date().getTime()}
      />
      <CreateAndEdit
        id={id}
        type={CRUD.edit}
        visible={createAndeditVisible}
        onCancel={() => setCreateAndeditVisible(false)}
        refreshDetails={() => {
          setRefreshDetailsMarker(new Date().getTime());
        }}
      />
    </div>
  );
};

export default DetailsPage;
