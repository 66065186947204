/**
 * 路由相关操作方法
 */

import _ from 'lodash';
import { ExportLog, ImportLog } from 'src/components/excelBatchOption';

/** 格式化路由 */
export const recursionRouteAddExImport = (routes: any[]) => {
  if (!_.isArray(routes)) {
    return;
  }
  for (const route of routes) {
    if (route.logPage) {
      const logRoutes = [
        {
          path: `${route.path}/exportLog`,
          component: ExportLog,
          auth: route?.exportAuth || route?.auth,
          breadcrumb: '导出日志',
        },
        {
          path: `${route.path}/importLog`,
          component: ImportLog,
          auth: route?.importAuth || route?.auth,
          breadcrumb: '导入日志',
        },
      ];

      if (!_.isArray(route.children)) {
        route.children = [];
      }
      route.children = route.children.concat(logRoutes);
    }

    recursionRouteAddExImport(route.children || []);
  }
  return false;
};
