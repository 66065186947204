import React, { useEffect } from 'react';
import { Button, Form, Modal, Space } from 'antd';
import { SearchSelect } from 'src/components';
import styles from './styles.module.scss';

interface RoleSelectModalProps {
  visible?: boolean;
  onClose?: (e?: any) => void;
  width?: string | number;
  submitData: (data: any[]) => void;
  isMultiple: boolean;
  choosedData?: { label: string; value: any }[];
}

const RoleSelectModal = (props: RoleSelectModalProps) => {
  const { visible, onClose, width, isMultiple, submitData: onSubmit, choosedData = [] } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (choosedData) {
      form.setFieldsValue({
        role: choosedData,
      });
    }
  }, [choosedData]);

  const renderFooter = () => {
    return (
      <div className={styles.roleSelectModalfooter}>
        <Space size={10}>
          <Button onClick={onClose}>取消</Button>
          <Button
            type="primary"
            onClick={() => {
              const { role } = form.getFieldsValue();

              onSubmit(role);
              onClose?.();
            }}
          >
            确定
          </Button>
        </Space>
      </div>
    );
  };

  return (
    <Modal
      centered
      visible={visible}
      width={width ?? 500}
      bodyStyle={{ padding: 0 }}
      destroyOnClose
      title={'选择角色'}
      keyboard={false}
      maskClosable={false}
      footer={renderFooter()}
      onCancel={onClose}
    >
      <div className={styles.roleSelectModalbody}>
        <Form form={form} style={{ width: '80%' }}>
          <Form.Item name={'role'} style={{ marginBottom: 0 }}>
            <SearchSelect fetchType="role" mode={isMultiple ? 'multiple' : undefined} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default RoleSelectModal;
