import React, { useState } from 'react';
import { Form, Modal, Radio, Space } from 'antd';
import { Tooltip } from 'src/components';
import styles from './styles.module.scss';
import { map } from 'lodash';
import {
  fetchMatchRuleMatchRuleTemplatePreviewForBiz,
  fetchMatchRuleMatchRuleTemplatePreviewForBizFieldMatch,
} from 'src/api/ytt/e-report-domain/matchRule';
import Report from 'src/page/analyse/templateManagement/components/preview/report';

interface Props {
  visible: boolean;
  onCancel: () => void;
  initData: any;
}

export default function ChooseTemplateModal({ visible, onCancel, initData }: Props) {
  const [params, setParams] = useState<any>();
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [isShowReport, setIsShowReport] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onOk = async () => {
    try {
      await form?.validateFields();
      const data = await form?.getFieldValue('checkTemplate');

      const params = data && JSON.parse(data);

      if (params?.transField) {
        // 代表是传字段
        const { data: Data } = await fetchMatchRuleMatchRuleTemplatePreviewForBizFieldMatch({
          fieldMatchInfoList: [{ fieldName: params?.refFieldName, fieldValue: params?.instanceId }],
          mappingId: params?.id,
          objectCode: params?.rootObjectCode,
          templateId: params?.templateId,
        });

        if (Data) {
          setIsShowReport(true);
          setParams(Data?.params);
          setPreviewUrl(Data?.previewUrl);
        }
      } else {
        // 传实例id
        const { data: Data } = await fetchMatchRuleMatchRuleTemplatePreviewForBiz({
          instanceId: params?.instanceId,
          mappingId: params?.id,
          objectCode: params?.objectCode,
          templateId: params?.templateId,
        });

        if (Data) {
          setIsShowReport(true);
          setParams(Data?.params);
          setPreviewUrl(Data?.previewUrl);
        }
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <>
      <Modal
        visible={Boolean(visible)}
        width={isShowReport ? 1000 : 500}
        title={isShowReport ? '预览结果' : '选择要使用的单据打印模板'}
        onCancel={onCancel}
        bodyStyle={{ minHeight: 500, maxHeight: 800, overflowY: 'scroll' }}
        className={styles.templateModal}
        centered
        onOk={isShowReport ? () => onCancel?.() : () => onOk()}
      >
        {isShowReport ? (
          <Report previewUrl={previewUrl ?? ''} params={params} />
        ) : (
          <Form form={form}>
            <Form.Item
              name="checkTemplate"
              rules={[{ required: true, message: '请选择要打印的模版' }]}
              initialValue={JSON.stringify(initData?.[0])}
            >
              <Radio.Group>
                <Space direction="vertical">
                  {map(initData, (e: any) => (
                    <Radio.Button style={{ width: 450 }} value={JSON.stringify(e)} key={e?.id}>
                      <Tooltip text={e?.templateName} width={400} />
                    </Radio.Button>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
}
