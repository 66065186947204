import { useEffect, useState } from 'react';
import { Checkbox, Form, FormInstance, Input, FormItemProps } from 'antd';
import { Rule } from 'antd/lib/form';
import { ICustomFieldItem } from '../interface';
import _ from 'lodash';

interface Props extends FormItemProps {
  form?: FormInstance;
  disabledState?: boolean; // 编辑状态是否可编辑,默认可编辑状态
  rules: Rule[];
  namePath?: (string | number)[]; // 针对多层路径下的嵌套字段的code编号 为[]就是第一层
  checkboxLayout?: 'horizontal' | 'vertical'; // checkbox 方向
  isFullLine?: boolean;
  span?: number;
  refreshMarker?: number; // 刷新
  defaultValue?: string; // 默认值
  fieldValue?: string; // 输入值
  dataSource?: ICustomFieldItem;
  onChange?: (e: any) => void; // code输入框的onChange
  formName?: string[];
  from?: FormInstance;
}

const defaultNamePath: (string | number)[] = [];

export default function TextNumberRulesStandardHook({
  label,
  rules,
  namePath = defaultNamePath,
  disabledState = false,
  checkboxLayout = 'horizontal',
  isFullLine,
  span,
  onChange,
  defaultValue,
  fieldValue,
  dataSource,
  refreshMarker = 0,
  formName,
  form,
  ...restProps
}: Props) {
  const [checkState, setCheckState] = useState<boolean>(true); // 是否勾选复选框

  const clearvValue: any = _.concat(formName, 'value');

  useEffect(() => {
    setCheckState(true);
  }, [namePath, fieldValue]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: checkboxLayout === 'horizontal' ? 'row' : 'column',
      }}
    >
      <Form.Item
        style={{ flex: 1 }}
        validateFirst
        name={[...(formName || []), 'value']}
        rules={checkState ? [] : [...rules]}
        {...restProps}
      >
        <Input
          placeholder={checkState ? '保存后按规则生成' : `${defaultValue ?? '请输入'}`}
          disabled={checkState}
        />
      </Form.Item>
      <Form.Item
        name={[...(formName || []), 'isNumberRuleConfig']}
        valuePropName="checked"
        initialValue={checkState}
        style={{ marginLeft: 16 }}
      >
        <Checkbox
          onChange={(e: any) => {
            setCheckState(e?.target?.checked);
            if (e?.target?.checked) {
              form?.setFields([{ name: [...(clearvValue || [])], value: '' }]);
            }
          }}
        >
          编号规则
        </Checkbox>
      </Form.Item>
    </div>
  );
}
