import { map } from 'lodash';
import { fetchParamsDefList } from 'src/api/ytt/resource-domain/parmDefine';
import { BatchListSelector, BatchListSelectorProps } from 'src/components';
import { fieldTypeList } from 'src/utils';
import { lookup } from 'src/dict';
import { UsageStatus } from 'src/dict/common';
import { ResourceBusinessType } from 'src/dict/resources';
import { arrayIsEmpty } from 'src/utils/array';
import { paramType } from 'src/dict/resources/mappings';
import ResourceClassificationCascader from '../../../components/ResourceClassificationCascader';
import { ResourceParamsListItemType } from '../../index.d';
import { fieldsLabel } from './constants';
import Styles from './index.module.scss';

const getColumnsAndFilters = (
  defaultQueryConditions?: BatchListSelectorProps<{
    [query: string]: any;
  }>['defaultQueryConditions'],
) => {
  const paramsTypeOption = !arrayIsEmpty(defaultQueryConditions?.paramsTypeList)
    ? map(paramType, (item: any) => {
        if (defaultQueryConditions?.paramsTypeList.indexOf(item?.value) !== -1) {
          return item;
        }
      }).filter((e) => e)
    : paramType;

  const columns = [
    {
      title: fieldsLabel.code,
      dataIndex: 'code',
      isFilter: true,
      sorter: true,
      fixed: 'left',
      width: 140,
    },
    {
      title: fieldsLabel.name,
      dataIndex: 'name',
      isFilter: true,
      sorter: true,
      fixed: 'left',
      width: 140,
    },
    {
      title: fieldsLabel.paramsType,
      dataIndex: 'paramsType',
      type: fieldTypeList.select,
      isFilter: true,
      width: 140,
      render: lookup('resources.paramType'),
      selectProps: {
        options: paramsTypeOption,
        // mode: 'multiple',
      },
    },
    {
      title: fieldsLabel.unit,
      dataIndex: 'unitName',
      width: 140,
    },
    {
      title: fieldsLabel.paramsClassification,
      dataIndex: 'classificationId',
      isFilter: true,
      width: 140,
      render: (text: number, record: any) =>
        record?.classificationList
          ?.map((item: { name: string; id: number }) => item.name)
          .join('/'),
      renderItem: (
        <ResourceClassificationCascader
          showSearch
          placeholder={'请选择参数分类'}
          onCreateClick={() => {}}
          enableAdd={false}
          params={{ businessType: ResourceBusinessType.parameter }}
        />
      ),
    },
  ];
  const filterList = columns
    .filter((i) => i.isFilter)
    .map((column: any) => {
      const filter: any = {
        label: column.title,
        name: column.filterName || column.dataIndex,
        type: column.type,
        rules: column.rules,
        renderItem: column.renderItem,
      };

      if (column.type === fieldTypeList.select || column.type === fieldTypeList.multiSelect) {
        filter.selectProps = column.selectProps;
      }
      if (column.type === fieldTypeList.date && column.dateFormat) {
        filter.dateFormat = column.dateFormat;
      }
      if (column.filterRender) {
        filter.renderItem = column.filterRender;
      }
      return filter;
    });

  return {
    columns,
    filterList,
  };
};

const formatDataToQuery = ({ classificationId, ...restParams }: any) => {
  return {
    ...restParams,
    classificationId: classificationId?.[classificationId.length - 1],
  };
};

const getTitle = (selectorType: ResourceBusinessType = ResourceBusinessType.equipment) => (
  <div>
    <span>{`批量添加${lookup('resources.resourceBusinessType', selectorType)}参数`}</span>
    <span className={Styles.titleHint}>
      不支持添加重复参数，勾选已经添加的参数，保存时会自动删除
    </span>
  </div>
);

export default function BatchParamSelector(
  props: Omit<
    BatchListSelectorProps<ResourceParamsListItemType>,
    'columns' | 'filterList' | 'requestFn'
  > & {
    selectorType?: ResourceBusinessType;
  },
) {
  const { selectorType, defaultQueryConditions } = props;
  const columnsAndFilters = getColumnsAndFilters(defaultQueryConditions);

  return (
    <BatchListSelector<ResourceParamsListItemType>
      requestFn={fetchParamsDefList}
      columns={columnsAndFilters.columns}
      filterList={columnsAndFilters.filterList}
      title={getTitle(selectorType)}
      defaultQueryConditions={{
        businessType: ResourceBusinessType.equipment,
        status: UsageStatus.enabled,
        ...defaultQueryConditions,
      }}
      filterMode="record"
      formatDataToQuery={formatDataToQuery}
      {...props}
    />
  );
}
