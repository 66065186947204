import { useState, useEffect } from 'react';
import { map } from 'lodash';
import { DetailLayout } from 'layout';

import _Time from 'src/utils/dataTypes/time';
import { arrayIsEmpty } from 'src/utils/array';
import { ProcessRoutingDetail } from 'src/page/knowledgeManagement/engineerData/processRouting/components';
interface DetailPageProps {
  dataSource: any;
}

const DetailPage = (props: DetailPageProps) => {
  const { dataSource } = props;
  const [processes, setProcesses] = useState<any>(dataSource?.processes);

  useEffect(() => {
    if (arrayIsEmpty(dataSource?.processes)) return;
    const processesData = map(
      dataSource.processes,
      ({ plannedStartTime, plannedEndTime, files, sopName, sopId, ...rest }) => ({
        ...rest,
        plannedEndTime: plannedEndTime ? _Time.format(plannedEndTime) : undefined,
        plannedStartTime: plannedStartTime ? _Time.format(plannedStartTime) : undefined,
        fileIds: files,
        sop: { name: sopName, id: sopId },
      }),
    );

    setProcesses(processesData);
  }, []);

  const baseInfo = [
    {
      title: '工艺路线',
      items: [
        {
          label: '工艺路线编号',
          dataIndex: ['originalProcessRoute', 'code'],
        },
        {
          label: '工艺路线名称',
          dataIndex: ['originalProcessRoute', 'name'],
        },
        {
          label: '工艺路线备注',
          dataIndex: ['originalProcessRoute', 'remark'],
        },
      ],
    },
    {
      title: '工序列表',
      items: [
        {
          label: '',
          dataIndex: 'processPlanCO',
          render: () => (
            <ProcessRoutingDetail
              isWorkOrder
              processes={processes}
              processConnections={dataSource?.processConnections}
              useType={2}
            />
          ),
        },
      ],
    },
  ];

  return (
    <DetailLayout
      // title="工艺路线详情"
      info={baseInfo}
      dataSource={dataSource}
      // baseMenu={baseMenu}
    />
  );
};

export default DetailPage;
