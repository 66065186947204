import { CYCLE_NUMBER_TYPE } from './constant';
import { FormInstance } from 'antd';

// 根据选择的生成方式，和重复周期，循环生成对应的行数
export const setInitialCycleShift = (type: number, periodNum?: number) => {
  const initial = [];
  let num = periodNum ?? 1;

  if (type === CYCLE_NUMBER_TYPE.WEEK) {
    // 选择的按月类型 重复周期只能为1-4 自动生成*7行
    num *= 7;
  }
  if (type === CYCLE_NUMBER_TYPE.MONTH) {
    num = 31;
  }
  for (let i = 0; i < num; i++) {
    const obj = { day: i + 1 };

    initial?.push(obj);
  }
  return initial;
};

export const setCycleShift = (type: number, periodNum: number, form: FormInstance) => {
  const shiftRuleInfoCreateCOS = form.getFieldValue('shiftRuleInfoCreateCOS');
  // 比较当前的shiftRuleInfoCreateCOS长度和传进来的periodNum的值 小于则从后往前删  大于从后往后增
  const previousNum = shiftRuleInfoCreateCOS?.length;
  let _shiftRuleInfoCreateCOS = shiftRuleInfoCreateCOS;
  const _periodNum = type === CYCLE_NUMBER_TYPE.WEEK ? periodNum * 7 : periodNum;
  const differenceNum = _periodNum - previousNum;

  if (previousNum < _periodNum) {
    // 代表递增
    for (let i = 0; i < differenceNum; i++) {
      const obj = { day: i + previousNum };

      _shiftRuleInfoCreateCOS?.push(obj);
    }
    return _shiftRuleInfoCreateCOS;
  }
  // 代表递减，从后往前删除
  if (type === CYCLE_NUMBER_TYPE.DAY) {
    _shiftRuleInfoCreateCOS = _shiftRuleInfoCreateCOS?.slice(0, _periodNum);
  } else if (type === CYCLE_NUMBER_TYPE.WEEK) {
    // 一周的递增递减需要按照*7来处理
    _shiftRuleInfoCreateCOS = _shiftRuleInfoCreateCOS?.slice(0, _periodNum);
  }
  return _shiftRuleInfoCreateCOS;
};
