/**
 * 区分系统前后台
 */
export enum ContextType {
  FRONTEND = 'frontend', // 前台frontend
  BACKEND = 'backend', // 后台backend
}

export const FIELDS = {
  CONTEXT_TYPE: 'context_type',
};

export enum FirstLoginStatus {
  // 还未修改密码
  PASSWORD_NOT_CHANGED = 0,

  // 已修改过密码
  PASSWORD_CHANGED,
}
