import React, { useEffect, useState } from 'react';
import { Form, message } from 'antd';
import { useHistory } from 'react-router-dom';
import ElectronicTemplateCreateAndEditBastInfo from './components/createAndEditBastInfo';
import { toElectronicTemplateList } from './navigation';
import {
  fetchEReportTemplateCreate,
  fetchEReportTemplateModify,
  fetchEReportTemplateStatusProcess,
} from 'src/api/ytt/e-report-domain/customReport';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';
import { APPROVE_ENUM } from './constant';

export default function CreateElectronicTemplate() {
  const history = useHistory();
  const [form] = Form.useForm();

  const [addDataVisible, setAddDataVisible] = useState(false);

  const handelSetAddDataVisible = (visible: boolean) => {
    setAddDataVisible(visible);
  };

  const templateId = Number(localStorage.getItem('templateId'));

  useEffect(() => {
    localStorage.removeItem('templateId');
    localStorage.removeItem('subTemplateIdList');
    localStorage.removeItem('subTemIds');

    return () => {
      localStorage.removeItem('templateId');
      localStorage.removeItem('subTemplateIdList');
      localStorage.removeItem('subTemIds');
    };
  }, []);

  const handleFinish = async (operationType: string) => {
    try {
      const value = await form?.validateFields();

      const { approvePlan, ...rest } = value;

      if (operationType === 'add') {
        // 代表点击的是添加模板数据集，创建一个草稿态，返回一个临时id
        const { data } = await fetchEReportTemplateCreate({
          ...rest,
          type: value?.type?.value,
          approvalPlanId: value?.approval === APPROVE_ENUM.YES ? approvePlan?.value : 0,
          approvalPlanName: value?.approval === APPROVE_ENUM.YES ? approvePlan?.label : '',
        });

        const templateIdValue = data?.toString() ?? '';

        localStorage.setItem('templateId', templateIdValue);

        setAddDataVisible(true);
        return;
      }

      //  以下代表点击的是创建确认的接口 有三种情况 1. 没有创建数据集和模板文件,只创建一个主模板载体  2. 创建了数据集再保存  3. 有数据集和子模板再保存

      const subTemIds = localStorage.getItem('subTemIds');

      if (subTemIds) {
        // 代表创建了两者再保存
        const subTemplateIdList = localStorage
          .getItem('subTemIds')
          ?.split(',')
          .map((e) => Number(e));

        // 子模板数据存在一定是去修改状态 创建真正的显示的List数据 并且此时主模板已经创建成功，再发请求做的是修改处理
        const { data, code } = await fetchEReportTemplateStatusProcess({
          subTemplateIdList,
          status: 1,
        });

        const { data: Data, code: Code } = await fetchEReportTemplateModify({
          // 用户在创建页面，点击了添加以后再走确认有可能会修改之前储存的基础信息
          ...rest,
          templateId,
          type: value?.type?.value,
          approvalPlanId: value?.approval === APPROVE_ENUM.YES ? approvePlan?.value : 0,
          approvalPlanName: value?.approval === APPROVE_ENUM.YES ? approvePlan?.label : '',
        });

        if (code === 200 && Code === 200 && !data?.failNum && Data) {
          message.success('新建电子单据模板成功');
          history.push(toElectronicTemplateList());
          return;
        }
      }

      if (templateId) {
        // 只创建了数据集以后直接点保存

        const { data: Data, code: Code } = await fetchEReportTemplateModify({
          // 用户在创建页面，点击了添加以后再走确认有可能会修改之前储存的基础信息
          ...rest,
          templateId,
          type: value?.type?.value,
          approvalPlanId: value?.approval === APPROVE_ENUM.YES ? approvePlan?.value : 0,
          approvalPlanName: value?.approval === APPROVE_ENUM.YES ? approvePlan?.label : '',
        });

        const { data, code } = await fetchEReportTemplateStatusProcess({
          status: 1,
          templateId,
        });

        if (code === 200 && data && Code === 200 && Data) {
          message.success('新建电子单据模板成功');
          history.push(toElectronicTemplateList());
          return;
        }
      }

      // 无数据创建 无主模板 只填写了基本信息 先去创建数据获取id  再去修改状态
      const { data: Data, code: Code } = await fetchEReportTemplateCreate({
        // 创建数据
        ...rest,
        type: value?.type?.value,
        approvalPlanId: value?.approval === APPROVE_ENUM.YES ? approvePlan?.value : 0,
        approvalPlanName: value?.approval === APPROVE_ENUM.YES ? approvePlan?.label : '',
      });

      if (Code === 200 && Data) {
        // 更改状态
        const { data, code } = await fetchEReportTemplateStatusProcess({
          status: 1,
          templateId: Data,
        });

        if (code === 200 && data) {
          message.success('新建电子单据模板成功');
          history.push(toElectronicTemplateList());
          return;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ElectronicTemplateCreateAndEditBastInfo
      form={form}
      title="新建电子单据模板"
      onCancel={() => {
        history.push(toElectronicTemplateList());
      }}
      onFinish={throttleFn(handleFinish)}
      addDataVisible={addDataVisible}
      handelSetAddDataVisible={handelSetAddDataVisible}
      edit={false}
    />
  );
}
