import React, { useState, useEffect } from 'react';
import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import { replaceSign } from 'src/utils/constants';
import { fetchEReportQueryTemplateDetail } from 'src/api/ytt/e-report-domain/customReport';
import { Tabs } from 'antd';
import AddTemplateTabComponent from './template/addTemplateTabComponent';
import DetailDataSetTab from './data/detailDataSetTab';
import { toElectronicTemplateEdit } from '../navigation';
import { useHistory } from 'react-router-dom';
import { APPROVE_ENUM, APPROVE_TYPE, TEMPLATE_TYPE_MAP } from '../constant';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { filter } from 'lodash';

interface DetailBaseInfoProps {
  templateId: number;
}

export default function ElectronicTemplateDetailBaseInfo({ templateId }: DetailBaseInfoProps) {
  const [dataSource, setDataSource] = useState({} as any);
  const [visibleLog, setVisibleLog] = useState(false);
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新页面
  const history = useHistory();

  const { TabPane } = Tabs;
  const fetchData = async () => {
    try {
      const { data: detailData } = await fetchEReportQueryTemplateDetail({ templateId });

      if (detailData) {
        setDataSource(detailData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  useEffect(() => {
    localStorage.setItem('templateId', templateId.toString());
    fetchData();

    return () => {
      localStorage.removeItem('templateId');
    };
  }, [templateId, refreshMarker]);

  const baseCol = [
    {
      label: '模板编号',
      dataIndex: 'code',
      render: (code: string) => code || replaceSign,
    },
    {
      label: '模板名称',
      dataIndex: 'name',
      render: (name: string) => name || replaceSign,
    },
    {
      label: '模板类别',
      dataIndex: 'type',
      render: (type: number) => TEMPLATE_TYPE_MAP.get(type),
    },
    {
      label: '是否需要审批',
      dataIndex: 'approval',
      render: (approval: number) => APPROVE_TYPE.get(approval),
    },
    {
      label: '审批方案',
      dataIndex: 'approvalPlanName',
      render: (approvalPlanName: string) => approvalPlanName,
    },
    {
      label: '模板说明',
      dataIndex: 'remark',
      render: (remark: string) => remark || replaceSign,
    },
  ];

  const detailInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items:
      dataSource?.approval === APPROVE_ENUM.YES
        ? baseCol
        : filter(baseCol, (node: any) => node?.dataIndex !== 'approvalPlanName'),
    column: 2,
  };

  const renderTabs = () => {
    const tabList = [
      {
        tab: '模板文件',
        key: 'templateFile',
        component: (
          <AddTemplateTabComponent
            isEdit={false}
            initData={dataSource?.subTemplateFileInfoList}
            refresh={refresh}
            approval={dataSource?.approval}
          />
        ),
      },
      {
        tab: '模板数据集',
        key: 'dataSet',
        component: <DetailDataSetTab initData={dataSource?.templateDataSetInfoList} />,
      },
    ];

    return (
      <Tabs destroyInactiveTabPane>
        {tabList.map(({ component, key, ...resItem }) => (
          <TabPane key={key} {...resItem}>
            {component}
          </TabPane>
        ))}
      </Tabs>
    );
  };

  const dataInfo: DetailLayoutInfoBlock = {
    items: [
      {
        label: '',
        dataIndex: 'data',
        isFullLine: true,
        render: () => renderTabs(),
      },
    ],
  };

  const baseMenu = [
    {
      key: 'record',
      auth: authDict.ereport_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'edit',
      auth: authDict.ereport_edit,
      onClick: () => {
        history.push(toElectronicTemplateEdit(templateId, 'detail'));
      },
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ];

  const infoArray = [detailInfo, dataInfo];

  return (
    <>
      <DetailLayout
        userAuth={getAuthFromLocalStorage()}
        title="电子单据模版详情"
        baseMenu={baseMenu}
        info={infoArray}
        dataSource={dataSource}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={templateId}
          objectCode={'eReport'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
}
