import React from 'react';
import { DataFormLayoutForModal } from '../../../../../layout';
import UnitsBaseInfo from './unitsBaseInfo';

interface Props {
  visible: Boolean;
  type: string;
  refreshTable?: () => void;
  refreshDetails: () => void;
  onCancel: () => void;
  id?: number | undefined;
}

const CreateAndEdit = (props: Props) => {
  const { visible, ...rest } = props;

  return (
    <DataFormLayoutForModal
      visible={Boolean(visible)}
      width={800}
      content={<UnitsBaseInfo {...rest} />}
      onClose={props.onCancel}
    />
  );
};

export default CreateAndEdit;
