import { Modal, message, Button } from 'antd';
import { USER_STATUS_ENUM } from 'src/dict/user/index';
import lookup from 'src/dict';
import { useUserGetOrgUserNum } from '../userUtils';
import { showErrorListMessage } from 'src/components';
import { fetchUserActivateUser, fetchUserAwakenUser } from 'src/api/ytt/user-domain/user';
import { useEffect } from 'react';

export interface BulkConfirmProps {
  visible: boolean;
  idList: number[];
  type: number;
  close: () => void;
  refreshList: () => void;
}

const ShowBulkConfirmModal = (props: BulkConfirmProps) => {
  const { visible, idList, type, close, refreshList } = props;
  const actionText = lookup('user.userStatus', type);
  const { orgUserNum, updateNumber } = useUserGetOrgUserNum();

  const errorColumns = [
    {
      title: '用户编号',
      dataIndex: 'code',
    },
    {
      title: '用户名称',
      dataIndex: 'name',
    },
    {
      title: '失败原因',
      dataIndex: 'failureReason',
    },
  ];

  /**
   * 渲染提示标签
   * @param type 启用/停用/激活
   * @param appFreeNum 移动端账号剩余
   * @param appAndWebFreeNum 网页端+移动端账号剩余
   * @param interfaceFreeNum 接口账号剩余
   */
  const renderContentTap = (
    type: number,
    appFreeNum: number,
    appAndWebFreeNum: number,
    interfaceFreeNum: number,
  ) => {
    const actionText = lookup('user.userStatus', type);

    if (type === USER_STATUS_ENUM.disable) {
      return (
        <>
          <p>停用是否移除账号关联角色?</p>
        </>
      );
    }
    return (
      <>
        <p>{`请确认需要批量${actionText}的账号数量在可用数量范围：`}</p>
        <p>{`移动端账号剩余可用 ${appFreeNum} 个`}</p>
        <p>{`网页端+移动端账号剩余可用 ${appAndWebFreeNum} 个`}</p>
        <p>{`接口账号剩余可用 ${interfaceFreeNum} 个`}</p>
      </>
    );
  };

  const updateStatus = async (fn: any, params: any) => {
    const res = await fn(params);
    const { code, data } = res;
    const { updateFailureVOList, failureNum, successNum } = data;

    if (code !== 200) {
      return;
    }
    if (updateFailureVOList && updateFailureVOList.length > 0) {
      showErrorListMessage({
        title: `${actionText}出现失败`,
        data: updateFailureVOList,
        columns: errorColumns,
        operateName: actionText,
        successCount: successNum,
        failCount: failureNum,
        width: 600,
      });
    } else {
      message.success(`${actionText}成功`);
    }
    refreshList();
    close();
  };

  const handleOk = (removeRelation?: boolean) => {
    const params = {
      idList,
    };

    if (type === USER_STATUS_ENUM.disable) {
      updateStatus(fetchUserAwakenUser, {
        ...params,
        active: USER_STATUS_ENUM.disable,
        removeRelation,
      });
    } else if (type === USER_STATUS_ENUM.enable) {
      updateStatus(fetchUserAwakenUser, { ...params, active: USER_STATUS_ENUM.enable });
    } else if (type === USER_STATUS_ENUM.active) {
      updateStatus(fetchUserActivateUser, params);
    }
  };

  useEffect(() => {
    updateNumber();
  }, []);

  return (
    <Modal
      visible={visible}
      title="提示"
      onCancel={close}
      footer={[
        <Button
          onClick={() => {
            if (type === USER_STATUS_ENUM.disable) {
              handleOk(true);
            } else {
              close();
            }
          }}
        >
          {type === USER_STATUS_ENUM.disable ? '停用并移除' : '取消'}
        </Button>,
        <Button type="primary" onClick={() => handleOk(false)}>
          {type === USER_STATUS_ENUM.disable ? '仅停用账号' : actionText}
        </Button>,
      ]}
    >
      {renderContentTap(
        type,
        orgUserNum.appFreeNum,
        orgUserNum.appAndWebFreeNum,
        orgUserNum.interfaceFreeNum,
      )}
    </Modal>
  );
};

export default ShowBulkConfirmModal;
