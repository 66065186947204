/**
 * 角色详情展示
 * 基本信息 + 权限tab切换
 */
import { useCallback, useEffect, useState } from 'react';
import { Tag } from 'antd';
import { DetailLayoutInfoBlock, DetailLayout } from 'layout';
import UsePrivilegeContent from 'src/page/organization/components/usePrivilegeContent';
import { getRoleById } from 'src/services/organization/rolesApi';
import TagTooltip from 'src/components/tooltip/tagTooltip';
import { replaceSign } from 'src/utils/constants';
import { SCM_VIRTUAL_ROLE, SYSTEM_ADMIN } from '../constants';
import { BcMultilineText } from 'components';
import { appEnum } from 'src/dict';
import authDict from 'src/utils/auth';
import { handleRoleStatus } from '../roleManagement/handleRoleStatus';
import _ from 'lodash';
import { DetailLayoutMenuItem } from 'src/layout/detail/DetailLayout.type';

interface RoleDisplayProps {
  id: number | null;
  title?: string;
  baseMenu?: DetailLayoutMenuItem[];
  disabled?: boolean;
  showOtherInfo?: boolean;
  refreshMarker?: number;
  onRefresh?: () => void;
}

const RoleDisplay = (props: RoleDisplayProps) => {
  const {
    id,
    title,
    baseMenu,
    disabled,
    showOtherInfo,
    refreshMarker,
    onRefresh = () => {},
  } = props;
  const [dataSource, setDataSource] = useState({} as any);

  const fetchData = useCallback(async () => {
    if (id === null) return;
    const { data } = await getRoleById(id).role;

    setDataSource(data || {});
    return data || {};
  }, [id, refreshMarker]);

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    column: 2,
    items: [
      { label: '角色编号', dataIndex: 'code', isFullLine: true },
      { label: '角色名称', dataIndex: 'name', isFullLine: true },
      {
        label: '许可项',
        isFullLine: true,
        dataIndex: 'roleOwnedLicenses',
        render: (val?: any[]) => {
          return val?.map((item: any) => <Tag key={item.code}>{item.name}</Tag>) || replaceSign;
        },
      },
      {
        label: '使用人',
        dataIndex: 'users',
        isFullLine: true,
        render: (users) => {
          return users?.length > 0 ? <TagTooltip list={users} type="role" /> : replaceSign;
        },
      },
      {
        label: '角色描述',
        dataIndex: 'desc',
        isFullLine: true,
        render: (text) => (!text ? replaceSign : text),
      },
      {
        label: '备注',
        dataIndex: 'remark',
        isFullLine: true,
        render: (text: string) => <BcMultilineText text={text} />,
      },
    ],
  };

  const roleInfo: DetailLayoutInfoBlock = {
    title: '',
    items: [
      {
        label: '',
        dataIndex: '',
        render: () =>
          UsePrivilegeContent({
            roleId: id,
            disabled,
          }),
      },
    ],
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator) => {
          return creator?.name;
        },
      },
      { label: '创建时间', dataIndex: 'createdAt' },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator) => {
          return operator?.name;
        },
      },
      { label: '更新时间', dataIndex: 'updatedAt' },
    ],
    column: 2,
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getBaseMenu = (dataSource: any = {}) => {
    const { enableFlag = {}, code, id, users = [] } = dataSource;
    const ableAction: DetailLayoutMenuItem = {
      key: 'able',
      auth: authDict.role_enable_disable,
      onClick: () => {
        handleRoleStatus(id, enableFlag.code, users?.length > 0, onRefresh);
      },
      title: enableFlag.code === appEnum.Common.UsageStatus.disabled ? '启用' : '停用',
    };

    if (code && code !== SYSTEM_ADMIN && code !== SCM_VIRTUAL_ROLE) {
      // baseMenu 没传时，为弹窗展示权限配置，右上角没有操作按钮
      if (!baseMenu) return baseMenu;
      const menu = _.concat(_.initial(baseMenu) ?? [], ableAction, _.last(baseMenu)) ?? [];

      return _.compact(menu);
    }
    return [];
  };

  return (
    <DetailLayout
      title={title}
      baseMenu={getBaseMenu(dataSource)}
      info={[baseInfo, roleInfo].concat(showOtherInfo ? [otherInfo] : [])}
      dataSource={dataSource}
    />
  );
};

export default RoleDisplay;
