import React, { useEffect, useState } from 'react';

import { fetchWorkCenterList } from 'src/api/ytt/work-center-domain/index';

import { Checkbox, List, Input, Breadcrumb, Modal } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { RightOutlined } from '@ant-design/icons';
import { blacklakeGreen, deepGrey } from 'src/styles/color';
import { userSelectProps, ListItem } from './index.d';
import './index.css';
import { gcArray } from 'src/utils';
import { BlIcon } from 'src/components';

/**
 *
 * @param props
 * visible 弹窗是否可见，如果使用trigger可不用加
 * onClose 关闭弹窗的回调方法
 * width 弹窗宽度
 * closable 是否可以点击关闭弹窗
 * isMultiple 是否为多选
 * isSelectUser 是否为选择用户，不传则认为是选择部门
 * submitData 选择后的提交选择的回调函数，单选和多选都会返回已选择内容的数组
 * chooesdData 已选择内容
 * @returns
 */
const WorkCenterSelectModal = (props: userSelectProps) => {
  const [leftListData, setLeftListData] = useState<ListItem[]>([]);
  const [chooseListData, setChooseListData] = useState<ListItem[]>([]);
  const [departmentId, setDepartmentId] = useState<number | undefined>();
  const [currentDepartment, setCurrentDepartment] = useState<ListItem>();
  const [departmentSelected, setDepartmentSelected] = useState<ListItem[]>([]);
  const { visible, onClose, width, closable, isMultiple, submitData, choosedData } = props;
  const bodyStyle = {
    padding: 0,
    maxHeight: document.body.clientHeight - 100,
    minHeight: 300,
    display: 'flex',
  };

  const avatarStyle = {
    backgroundColor: blacklakeGreen,
    marginRight: 4,
    marginBottom: 2,
    fontSize: 16,
  };

  const listStyle = {
    width: isMultiple ? '50%' : '100%',
    padding: '24px 24px 0 24px',
    borderRight: 'solid 1px #eee',
  };

  const getLeftListData = async () => {
    const res: any = await fetchWorkCenterList({ size: 50, page: 1 });

    const listData = res?.data?.list;

    setLeftListData(listData);
  };

  useEffect(() => {
    getLeftListData();
  }, [departmentId]); // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    choosedData && setChooseListData(choosedData);
  }, [choosedData]);

  const clickMenu = (index: number) => {
    setCurrentDepartment(departmentSelected[index]);
    const newSelect = departmentSelected.slice(0, index + 1);

    setDepartmentSelected(newSelect);
  };

  const _isChooseData = (item: ListItem) =>
    !gcArray.isEmpty(chooseListData) && chooseListData.findIndex((i) => i.id === item.id) !== -1;

  const chooseWorkCenterData = async (isChoose: boolean, node: ListItem, isSearch?: boolean) => {
    const chooseNode = [node];

    setChooseListData(
      isChoose ? chooseListData.concat(chooseNode) : chooseListData.filter((i) => i.id !== node.id),
    );
  };

  const singleChoose = (node: ListItem) => {
    if (_isChooseData(node)) {
      setChooseListData([]);
    } else {
      setChooseListData([node]);
    }
  };

  const removeChooseData = async (_id?: number | string) => {
    setChooseListData(chooseListData.filter((item) => item.id !== _id));
  };

  const workCenterRender = (userInfo: ListItem) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar icon={<BlIcon type="icongeren" />} size={24} style={avatarStyle} />
        <span style={{ margin: '0 8px' }}>{userInfo.name} </span>
      </div>
    );
  };

  const multipleWorkCenterRender = (item: ListItem) => {
    return (
      <List.Item key={item.id}>
        <div>
          <Checkbox
            // disabled={item.isDisabled}
            onChange={(e) => chooseWorkCenterData(e.target.checked, item)}
            checked={_isChooseData(item)}
          >
            {workCenterRender(item)}
          </Checkbox>
        </div>
      </List.Item>
    );
  };

  const singleWorkCenterRender = (item: ListItem) => {
    return (
      <List.Item
        className={_isChooseData(item) ? 'selected' : ''}
        key={item.id}
        onClick={() => singleChoose(item)}
      >
        {workCenterRender(item)}
      </List.Item>
    );
  };

  const WorkCenterRenderSelected = (item: ListItem) => {
    return (
      <List.Item key={item.id}>
        {workCenterRender(item)}
        <a onClick={() => removeChooseData(item?.id)} style={{ color: deepGrey }}>
          <BlIcon type="iconguanbi" />
        </a>
      </List.Item>
    );
  };

  const search = async (value: string) => {
    // setDepartmentSelected([]);
    if (value) {
      try {
        const res: any = await fetchWorkCenterList({ name: value, page: 1, size: 50 });
        const listData = res?.data?.list || [];

        setLeftListData(listData);
      } catch (err) {
        console.log(err);
      }
    } else {
      getLeftListData();
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      onCancel={onClose}
      width={width ?? '45%'}
      bodyStyle={bodyStyle}
      destroyOnClose
      title={'选择工作中心'}
      keyboard={false}
      maskClosable={false}
      closable={closable}
      onOk={() => {
        submitData(chooseListData);
        onClose?.();
      }}
    >
      <div style={{ width: '100%', display: 'flex' }}>
        <div style={listStyle}>
          <Input.Search placeholder="可搜索工作中心" onSearch={search} />
          <Breadcrumb separator={<RightOutlined />}>
            {departmentSelected.map((menu: ListItem, index: number) => (
              <Breadcrumb.Item key={menu.id}>
                <a onClick={() => clickMenu(index)}>{menu.name}</a>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <List
            style={{ maxHeight: 300, overflow: 'auto' }}
            dataSource={leftListData}
            split={false}
            renderItem={(item: ListItem) => {
              if (isMultiple) {
                return multipleWorkCenterRender(item);
              }
              return singleWorkCenterRender(item);
            }}
          />
        </div>
        {isMultiple && (
          <div style={listStyle}>
            <span style={{ display: 'inline-block', padding: 8 }}>
              已选：{chooseListData.length} {'条'}
            </span>
            <List
              style={{ maxHeight: 420, overflow: 'auto' }}
              dataSource={chooseListData}
              renderItem={(item: any) => {
                return WorkCenterRenderSelected(item);
              }}
              split={false}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default WorkCenterSelectModal;
