import { FC, useEffect } from 'react';
import produce from 'immer';
import _ from 'lodash';
import { List } from 'antd';
import type { FormInstance } from 'antd';
import { ControlType } from 'src/dict/sop';
import DraggableItem from './draggableItem';
import Empty from './empty';
import { NEW_CONTROL_ID, NEW_CONTROL_INDEX, controlDefaultValueMap } from '../../constants';
import type { StepDetailData, ControlItem, LocalControlItem } from '../../types';

interface Props {
  form: FormInstance<StepDetailData>;
  loading: boolean;
  localKeys: string[];
  setLocalKeys: (keys: string[], isInit?: boolean) => void;
  selectedControl: string | null;
  selectControl: (id: string | null) => void;
  validate: () => Promise<boolean>;
}

let addKey = 0;
const getLocalKey = (idx: number) => `ctrl-${idx}`;

/** 控件预览区 */
const Previewer: FC<Props> = ({
  form,
  loading,
  localKeys,
  setLocalKeys,
  selectedControl,
  selectControl,
  validate,
}) => {
  // 表单中的控件数据
  const controls: LocalControlItem[] = form.getFieldValue('controls');

  // 选择其他控件时, 当前控件必须校验无报错
  const valiateAndSelect = (key: string) => {
    if (key === selectedControl) {
      return;
    }
    validate().then((success) => {
      if (success) {
        selectControl(key);
      }
    });
  };

  /** 新增控件 */
  const addControl = (controlType: ControlType, idx: number = 0) => {
    const newKey = getLocalKey(addKey++);
    const newControl = {
      ...(controlDefaultValueMap.get(controlType) as ControlItem),
      _localKey: newKey,
    };

    form.setFieldsValue({ controls: [...form.getFieldValue('controls'), newControl] });
    setLocalKeys(
      produce(localKeys, (draft) => {
        draft.splice(idx, 0, newControl._localKey);
      }),
    );
    selectControl(newControl._localKey);
  };

  /** 拖动/拖入控件 */
  const moveControl = (dragIndex: number, hoverIndex: number, controlType: ControlType) => {
    let nextLocalKeys: string[];

    if (dragIndex === NEW_CONTROL_INDEX) {
      // 新拖入控件
      addControl(controlType, hoverIndex);
    } else {
      nextLocalKeys = produce(localKeys, (draft) => {
        draft.splice(dragIndex, 1);
        draft.splice(hoverIndex, 0, localKeys[dragIndex]);
      });
      setLocalKeys(nextLocalKeys);
    }
  };

  /** 删除控件 */
  const removeControl = (localKey: string) => {
    // 当前选中的控件被删除后, 默认选中下一个控件; 如果没有, 就中选前一个控件
    if (selectedControl === localKey) {
      const index = localKeys.indexOf(localKey);
      let nextSelected = null;

      if (localKey === _.last(localKeys)) {
        if (localKeys.length > 1) {
          nextSelected = localKeys[index - 1];
        }
      } else {
        nextSelected = localKeys[index + 1];
      }
      selectControl(nextSelected);
    }
    setLocalKeys(_.without(localKeys, localKey));
    const showControls = form
      .getFieldValue('controls')
      .filter((item: LocalControlItem) => item?._localKey !== localKey);

    form.setFieldsValue({
      controls: [...showControls],
    });
  };

  /** 复制控件 */
  const copyControl = async (localKey: string) => {
    const sourceControl = controls?.find((ctrl) => ctrl._localKey === localKey);

    if (_.isEmpty(sourceControl)) {
      return;
    }
    const success = await validate();

    if (!success) {
      return;
    }
    const newKey = getLocalKey(addKey++);
    const target = {
      ..._.cloneDeep(sourceControl),
      _localKey: newKey,
      id: NEW_CONTROL_ID,
      code: '',
    };
    const sourceIndex = localKeys.indexOf(localKey);

    form.setFieldsValue({ controls: [...form.getFieldValue('controls'), target] });
    setLocalKeys(
      produce(localKeys, (draft) => {
        draft.splice(sourceIndex + 1, 0, newKey);
      }),
    );
    // 自动选中新复制的控件
    selectControl(newKey);
  };

  useEffect(() => {
    // 初次加载时, 为现有的控件分配localKey
    if (controls.length > 0) {
      const controlsWithLocalKeys = controls?.map((item, idx) => ({
        ...item,
        _localKey: getLocalKey(idx),
      }));

      form.setFieldsValue({ controls: controlsWithLocalKeys });
      setLocalKeys(_.map(controlsWithLocalKeys, '_localKey') as string[], true);

      addKey = controls.length;
    }
  }, [loading]);

  return (
    <>
      <List style={{ flex: '0 0' }}>
        {localKeys.map((localKey, idx) => {
          const controlData = controls.find((item) => item._localKey === localKey);
          const isSelected = localKey === selectedControl;

          return controlData ? (
            <DraggableItem
              key={localKey}
              id={localKey}
              index={idx}
              data={controlData}
              selected={isSelected}
              moveControl={moveControl}
              selectControl={valiateAndSelect}
              removeControl={removeControl}
              copyControl={copyControl}
            />
          ) : null;
        })}
      </List>
      <Empty addControl={addControl} controlCount={localKeys.length} />
    </>
  );
};

export default Previewer;
