/**
 * 检验校验任务控件 控件属性
 */
import { FC, useContext } from 'react';
import _ from 'lodash';
import { Form, Input, Radio } from 'antd';
import type { FormInstance } from 'antd';
import type { StepDetailData, LocalControlItem } from '../../types';
import { ControlEditorContext, INPUT_DEFAULT_PLACEHOLDER } from '../../constants';
import { getNameFactory, titleRules } from './share';
import { appDict } from 'src/dict';
import { BlIcon } from 'src/components';

interface Props {
  form: FormInstance<StepDetailData>;
  localKey: string;
}

const QcTaskOfSOPInputProperties: FC<Props> = ({ form, localKey }) => {
  const { forceUpdate, formProps } = useContext(ControlEditorContext);
  const controlList = form.getFieldValue('controls');

  if (_.isEmpty(controlList)) {
    return null;
  }
  const dataIndex = controlList.findIndex((item: LocalControlItem) => item._localKey === localKey);

  if (dataIndex === -1) {
    return null;
  }
  const getName = getNameFactory(dataIndex);

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={(changeValues, allValues) => {
        if (formProps) {
          const { onValuesChange } = formProps;

          onValuesChange && onValuesChange(changeValues, allValues);
        }

        forceUpdate();
      }}
    >
      <Form.Item label="标题" name={getName('name')} rules={titleRules}>
        <Input max={16} placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>

      <Form.Item
        label="自动获取生产任务工序"
        name={getName('bizField.autoProcess')}
        rules={[{ required: true, message: '请选择自动获取生产任务工序' }]}
        tooltip={{
          title: '仅适用于生产检、巡检、首检。',
          icon: <BlIcon type="iconwentizhiyinbangzhu" />,
        }}
      >
        <Radio.Group options={appDict.common.yn} />
      </Form.Item>
      <Form.Item
        label="自动获取工单主产出物料"
        name={getName('bizField.autoMaterial')}
        rules={[{ required: true, message: '请选择自动获取工单主产出物料' }]}
        tooltip={{
          title: '仅适用于生产检、巡检、首检。',
          icon: <BlIcon type="iconwentizhiyinbangzhu" />,
        }}
      >
        <Radio.Group options={appDict.common.yn} />
      </Form.Item>
      <Form.Item
        label="自动获取工单主产出批次号"
        name={getName('bizField.autoBatchNumber')}
        rules={[{ required: true, message: '请选择自动获取工单主产出批次号' }]}
        tooltip={{
          title: '仅适用于生产检、巡检、首检。',
          icon: <BlIcon type="iconwentizhiyinbangzhu" />,
        }}
      >
        <Radio.Group options={appDict.common.yn} />
      </Form.Item>
    </Form>
  );
};

export default QcTaskOfSOPInputProperties;
