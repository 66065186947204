import { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import qs from 'qs';
import { DataFormLayout } from 'layout';
import { Checkbox, Form, message as Message } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import _ from 'lodash';
import moment from 'moment';

import {
  fetchResourcesDetail,
  fetchResourcesCreate,
  fetchResourcesUpdate,
} from 'src/api/ytt/resource-domain/resource';
import {
  initCustomFieldsInData,
  formatCustomFieldsInData,
  useCustomFieldCombinedData,
} from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import '../index.scss';
import ActionContent from './content';
import { gcObject, gcTime } from 'utils';
import { lookup } from 'src/dict';
import {
  DetailType,
  CreateResourceParamsType,
  DetailResourceParamsType,
  ResourceTagListItemType,
} from '../index.d';
import { TypeEnum } from '../constants';
import { goToList } from '../navigates';

interface ActionProps {
  id: string;
}

const DefinitionAction = (props: RouteComponentProps<ActionProps>) => {
  const { match, history } = props;
  const { search } = useLocation();
  const id = Number(match.params.id);
  const type = history.location.pathname.split('/').pop();
  const { parentId } = qs.parse(search, { ignoreQueryPrefix: true });
  let operateString: string;

  if (type === 'edit') {
    operateString = '编辑';
  } else if (type === 'copy') {
    operateString = '复制';
  } else {
    operateString = '新建';
  }

  const [modalForm] = Form.useForm();
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.equipment);
  const [keepOn, setKeepOn] = useState<boolean>(false);
  const [detailData, setDetailData] = useState<DetailType>();
  const [loading, setLoading] = useState(false);

  const formatDateToSetForm = (data: any) => {
    const {
      responsibleDepartment,
      responsibleUser,
      resourceParamsList,
      outFactoryDate,
      enterFactoryDate,
      enableDate,
      planScrapDate,
      unitId,
      unitName,
      brandModelMessage,
      brandModelCode,
      businessType,
      locationList,
      tagsTree,
      tagsList,
      parentId: parentIdEdit,
      parentName: parentNameEdit,
      children,
      classificationList,
      storageId,
      storageName,
      supplierResDTO,
      ...rest
    } = data;
    const formatedData = rest;

    if (data.unitId) {
      formatedData.unitId = { value: data.unitId, label: `${data.unitCode}|${data.unitName}` };
    }
    if (data.responsibleDepartment) {
      formatedData.responsibleDepartment = responsibleDepartment.map(
        (department: { id: any; name: any }) => {
          return {
            value: department.id,
            label: department.name,
          };
        },
      );
    }
    if (!_.isNil(brandModelMessage) && !_.isNil(brandModelCode)) {
      formatedData.brandModel = { value: brandModelCode, label: brandModelMessage };
    }
    if (businessType) {
      formatedData.businessType = {
        value: businessType,
        label: lookup('resources.equipmentBusinessType', businessType),
      };
    }

    // TODO 资源分类稍后接入
    // if (resourceClassificationId) {
    //   formatedData.classificationId = resourceClassificationId;
    // }

    if (responsibleUser) {
      formatedData.responsibleUser = responsibleUser.map((user: { id: any; name: any }) => {
        return {
          value: user.id,
          label: user.name,
        };
      });
    }
    if (outFactoryDate) {
      formatedData.outFactoryDate = moment(outFactoryDate);
    }
    if (enterFactoryDate) {
      formatedData.enterFactoryDate = moment(enterFactoryDate);
    }
    if (enableDate) {
      formatedData.enableDate = moment(enableDate);
    }

    if (planScrapDate) {
      formatedData.planScrapDate = moment(planScrapDate);
    }
    if (unitId) {
      formatedData.unit = { value: unitId, label: unitName };
    }
    if (locationList) {
      formatedData.locationId = locationList.map((location: { id: number }) => location.id);
    }
    if (resourceParamsList) {
      formatedData.resourceParamsList = resourceParamsList.map(
        (paramItem: DetailResourceParamsType) => {
          const {
            paramsId,
            paramsCode,
            paramsName,
            paramsType,
            unitName,
            schedulerJobName,
            schedulerJobId,
          } = paramItem;

          return {
            ...paramItem,
            schedulerJobId: !_.isNil(schedulerJobId)
              ? {
                  label: schedulerJobName,
                  value: schedulerJobId,
                }
              : undefined,
            param: {
              value: JSON.stringify({ paramsId, paramsCode, paramsName, paramsType, unitName }),
              label: paramItem.paramsName,
              key: paramItem.paramsId,
            },
          };
        },
      );
    }
    if (tagsTree) {
      formatedData.tagsTree = tagsTree.map((tag: ResourceTagListItemType) => {
        const tagValueId = tag.children?.filter((child) => _.find(tagsList, { id: child.id }))?.[0]
          .id;

        return {
          name: {
            label: tag.name,
            value: JSON.stringify(tag),
            key: tag.id,
          },
          content: tagValueId,
          id: _.find(tagsList, { id: tagValueId })?.tagsLinkId,
        };
      });
    }

    if (!_.isNil(parentIdEdit) && parentNameEdit) {
      formatedData.parent = {
        label: parentNameEdit,
        value: parentIdEdit,
      };
    }

    if (children?.length) {
      formatedData.children = children.map((child: DetailType) => ({
        label: child?.name,
        value: child?.id,
      }));
    }

    if (classificationList?.length) {
      formatedData.classificationId = _.map(classificationList, 'id');
    }

    if (!_.isNil(storageId)) {
      formatedData.storageId = {
        label: storageName,
        value: storageId,
      };
    }

    if (supplierResDTO) {
      formatedData.supplier = { label: supplierResDTO?.name, value: supplierResDTO?.id };
    }

    return formatedData;
  };

  const formateDataToSend = (data: any) => {
    const submitData = _.cloneDeep(data);

    if (submitData.customFieldItems) {
      const customItemArr = [];

      for (const key in submitData.customFieldItems) {
        if (key && submitData.customFieldItems[key]) {
          customItemArr.push({
            id: key,
            value: submitData.customFieldItems[key],
          });
        }
      }
      submitData.customFieldItems = customItemArr;
    }
    if (type === 'edit') {
      submitData.id = id;
    }
    // 复制时不传level，由后台计算
    if (type === 'copy') {
      delete submitData.level;
    }

    if (submitData.unit) {
      submitData.unitId = submitData.unit.value;
      delete submitData.unit;
    }
    if (submitData.businessType) {
      submitData.businessType = Number(submitData.businessType.value);
      if (submitData.businessType === TypeEnum.weighting && submitData.brandModel) {
        submitData.brandModel = submitData.brandModel.value;
      } else {
        delete submitData.brandModel;
      }
    }

    if (submitData.responsibleDepartment) {
      submitData.departmentIdList = submitData.responsibleDepartment.map(
        (department: any) => department.value,
      );
      delete submitData.responsibleDepartment;
    }
    if (submitData.responsibleUser) {
      submitData.userIdList = submitData.responsibleUser.map(
        (user: { value: number; label: string }) => user.value,
      );
      delete submitData.responsibleUser;
    }
    if (submitData.outFactoryDate) {
      submitData.outFactoryDate = gcTime.formatLine(submitData.outFactoryDate);
    }
    if (submitData.enterFactoryDate) {
      submitData.enterFactoryDate = gcTime.formatLine(submitData.enterFactoryDate);
    }
    if (submitData.enableDate) {
      submitData.enableDate = gcTime.formatLine(submitData.enableDate);
    }

    if (submitData.coverFile) {
      submitData.coverFileId = submitData.coverFile.id;
      delete submitData.coverFile;
    }

    if (submitData.isUseAutocode) {
      submitData.code = null;
      delete submitData.isUseAutocode;
    }

    if (submitData.locationId?.length) {
      submitData.locationId = submitData.locationId[submitData.locationId.length - 1];
    } else {
      delete submitData.locationId;
    }

    if (submitData.planScrapDate) {
      submitData.planScrapDate = gcTime.formatLine(submitData.planScrapDate);
    }

    // 新建无使用年限的设备，编辑时从后台读了为null的单位回填，即使显示的时候恢复了默认值，在单位被重新选择之前，form底下的数据都一直是空，更改方案，单独保存单位
    // 使用年限的单位有默认值，如果时长没有填就不能单独保存单位
    // if (_.isNil(submitData.useAge)) {
    //   delete submitData.useAgeType;
    // }

    if (submitData.classificationId) {
      submitData.classificationId = submitData.classificationId.pop();
    }

    if (submitData.tagsTree?.length) {
      submitData.tagsList = submitData.tagsTree.map(
        ({ id, content }: { content: number; name: LabeledValue; id: number }) => ({
          tagsId: content,
          // id是编辑的时候用来追溯资源标签记录的，新建/复制/新建子设备的时候不需要传
          id: type === 'edit' ? id : undefined,
        }),
      );
      delete submitData.tagsTree;
    }

    if (submitData.supplier) {
      submitData.supplierId = submitData.supplier.value;
      delete submitData.supplier;
    }

    if (submitData.parent) {
      submitData.parentId = submitData.parent.value;
      delete submitData.parent;
    }

    if (submitData.children?.length) {
      submitData.subResourceIdList = submitData.children.map((child: LabeledValue) => child.value);
      delete submitData.children;
    }

    if (submitData.resourceParamsList?.length) {
      submitData.resourceParamsList = submitData.resourceParamsList.map(
        (
          paramItem: CreateResourceParamsType & {
            param: LabeledValue;
            schedulerJobId: LabeledValue;
          },
        ) => {
          const {
            id,
            param,
            paramsDownApi,
            paramsReadApi,
            paramsLowerLimit,
            paramsFloorLimit,
            paramsUpperLimit,
            paramsTopLimit,
            paramsStandard,
            paramsAutoFlag,
            schedulerJobId,
          } = paramItem;
          const paramObj = JSON.parse(param.value as string);

          return {
            paramsId: paramObj.paramsId,
            paramsName: paramObj.name,
            paramsCode: paramObj.code,
            paramsDownApi,
            paramsReadApi,
            paramsLowerLimit,
            paramsFloorLimit,
            paramsUpperLimit,
            paramsTopLimit,
            paramsStandard,
            paramsAutoFlag,
            schedulerJobId: schedulerJobId?.value,
            id: type === 'edit' ? id : undefined,
          };
        },
      );
    }

    if (submitData.storageId) {
      submitData.storageId = submitData.storageId.value;
    }

    submitData.schedulerJobId = submitData.schedulerJobId?.value;

    return gcObject.filterEmptyProperty(submitData);
  };

  const initData = (data: DetailType) => {
    if (type === 'create') {
      // 新建子设备，以下字段不继承
      const { name, level, ...rest } = _.omit(data, [
        'id',
        'code',
        // 子设备
        'hasChildren',
        'children',
        // 父设备
        'parentId',
        'parentName',
        // 附件
        'fileIdList',
        'fileList',
        // 图片
        'coverFile',
        'coverFileId',
        // 标识码
        'entityLinkCode',
        // 备注
        'remark',
        // 关联仓位
        'storageId',
        'storageCode',
        'storageName',
        // 创建时间
        'createdTime',
        'createdAt',
        // 更新人
        'operateUser',
        'operatorId',
        // 更新时间
        'updatedAt',
        'updatedTime',
      ]);

      modalForm.setFieldsValue(
        initCustomFieldsInData(
          formatDateToSetForm({
            ...rest,
            parent: {
              label: name,
              value: Number(parentId),
            },
            level: level ?? 1 + 1,
          }),
        ),
      );
    } else if (type === 'copy') {
      // 复制时去掉原来的唯一值字段
      const copyData = _.omit(data, [
        // 编号
        'code',
        // 标识码
        'entityLinkCode',
        // 子设备
        'hasChildren',
        'children',
        // 关联仓位
        'storageId',
        'storageCode',
        'storageName',
      ]);

      modalForm.setFieldsValue(initCustomFieldsInData(formatDateToSetForm(copyData)));
    } else {
      // 编辑时保留所有字段
      modalForm.setFieldsValue(initCustomFieldsInData(formatDateToSetForm(data)));
    }
    setDetailData(data);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const idToSend = type === 'create' ? Number(parentId) : id;
      const response = await fetchResourcesDetail({ id: idToSend });
      const { data, code, message } = response;

      if (code === 200 && data) {
        const { data } = response;

        initData(data);
      } else {
        Message.error(message);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      // 有parentId， 新建子设备，请求数据
      ((type === 'edit' || type === 'copy') && id) ||
      (type === 'create' && !_.isNil(parentId))
    ) {
      fetchData();
    }
  }, [id]);

  const handleFinish = async () => {
    try {
      setLoading(true);
      const value = await modalForm?.validateFields();

      const submitData = formatCustomFieldsInData({ data: formateDataToSend(value), customFields });
      const { code, message } =
        type === 'edit'
          ? await fetchResourcesUpdate(submitData)
          : await fetchResourcesCreate(submitData);

      setLoading(false);
      if (code === 200) {
        Message.success(`${operateString}成功`);
        if (keepOn) {
          modalForm.resetFields();
        } else {
          goToList();
        }
      } else {
        Message.error(message);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  return (
    <div className="tabFormLayout">
      <DataFormLayout
        form={modalForm}
        loading={loading}
        title={`${operateString}设备台账`}
        extra={
          type === 'create' ? (
            <Checkbox
              onChange={() => {
                setKeepOn(!keepOn);
              }}
              defaultChecked={keepOn}
            >
              连续新建
            </Checkbox>
          ) : null
        }
        info={[]}
        onFinish={handleFinish}
        onCancel={goToList}
      >
        <ActionContent
          form={modalForm}
          type={type!}
          detailData={detailData}
          customFields={customFields}
        />
      </DataFormLayout>
    </div>
  );
};

export default DefinitionAction;
