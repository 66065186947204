import React, { useEffect, useState } from 'react';
import { TreeProps } from 'antd/lib/tree/Tree';
import styles from './BlListTree.module.scss';

/**
 * 针对于某些像 tree 又不是tree的只有一层的 dom 结构
 * @param props
 * @constructor
 */
const BlListTree: React.FC<TreeProps> = (props) => {
  const { treeData, className, disabled, checkedKeys, onSelect, style } = props;
  const [selectedKey, setSelectKey] = useState<Array<string | number>>([]);

  useEffect(() => {
    setSelectKey((checkedKeys ?? []) as string[]);
  }, [checkedKeys]);

  return (
    <div style={style}>
      <ul className={className}>
        {treeData?.map((item) => (
          <li
            className={`${styles.li} ${disabled ? styles.disable : ''} ${
              selectedKey.indexOf(item.key) > -1 ? styles.active : null
            }`}
            onClick={() => {
              setSelectKey([item.key]);
              onSelect &&
                onSelect([item.key], {
                  event: 'select',
                  selected: false,
                  selectedNodes: [],
                  node: item as any,
                  nativeEvent: undefined as any,
                });
            }}
            key={item.key}
          >
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BlListTree;
