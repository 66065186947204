/**
 * 带头像的用户、部门、角色
 */
import _ from 'lodash';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { replaceSign } from 'src/utils/constants';
import TagTooltip, { TagTooltipType } from 'src/components/tooltip/tagTooltip';
import type { User } from '.';

const renderUserOrDept = (obj: User | undefined, isUser = true) => {
  if (!obj || _.isEmpty(obj)) {
    return replaceSign;
  }
  return <AvatarDisplay {...obj} isUser isShowTag={false} />;
};

export const renderUser = renderUserOrDept;
export const renderDept = (obj: User | undefined) => renderUserOrDept(obj, false);

const renderArray = (list: User[] | undefined, type: TagTooltipType, max: number) => {
  if (_.isNil(list)) {
    return replaceSign;
  }
  const compacted = _.compact(list);

  return _.isEmpty(compacted) ? (
    replaceSign
  ) : (
    <TagTooltip list={compacted} text={'···'} type={type} max={max} isShowAll={max === 0} />
  );
};

export const renderUserArray = (list: User[] | undefined, max = 3) =>
  renderArray(list, 'user', max);
export const renderDepartmentArray = (list: User[], max = 3) =>
  renderArray(list, 'deparement', max);
export const renderRoleArray = (list: User[], max = 3) => renderArray(list, 'role', max);

export const renderMultiChoiceRefrence = (value: any) => _.map(value, 'mainProperty').join(',');

export const singleRefrence = (value: any) => value?.mainProperty;
