import { createModel } from '@rematch/core';
import { RootModel } from 'src/store/models/index';
import LocalStorage from 'src/utils/localStorage';
import * as constants from 'src/utils/constants';

export const contextInfo = createModel<RootModel>()({
  state: {
    contextType: null,
  },
  reducers: {
    setContextType(state, contextType) {
      return { ...state, contextType };
    },
    clearContextType(state) {
      LocalStorage.remove(constants.FIELDS.CONTEXT_TYPE); // 删除
      return { ...state, contextType: null };
    },
  },
});
