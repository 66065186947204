/**
 * @component SOP方案详情页的步骤表格
 */
import { FC, useCallback, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Table, Input, Modal, Button } from 'antd';
import { useMap } from 'react-use';
import { TextToolTip } from '@blacklake-web/component';
import { fetchSopStepDetail } from 'src/api/ytt/sop-domain';
import {
  ControlType,
  DefaultLogic,
  FieldSource,
  NumericRangeLimitLogic,
  ShowLogic,
  StepEntityType,
  UserOptionalRange,
} from 'src/dict/sop';
import lookup from 'src/dict';
import { replaceSign } from 'src/utils/constants';
import { valueOrHolder } from 'src/utils/formatters';
import { BcAttachmentDetail } from 'src/components/upload';
import {
  getRenderStepName,
  renderExecAuth,
  renderSelfLoop,
  renderDigitalSign,
  getRenderNextStep,
} from '../../share/stepRenderers';
import { renderLabelArray } from '../../../task/share/renderLabel';
import { controlDefaultValueMap } from '../../constants';
import { splitIntegerIntoBinaryArray } from '../../control/properties/share';
import useStepSearch from '../../share/useStepSearch';
import getTimeFormatString from '../../share/getTimeFormatString';
import { limitation } from '../../control/properties/textInput';
import type {
  ControlItem,
  ShowFieldObj,
  StepRowData,
  StepDetailControl,
  FormulaInfo,
} from '../../types';
import './index.scss';
import { fetchFormulaDetail } from 'src/api/ytt/formula-domain/calculationFormula';

/**
 * 每个步骤和步骤组都可以展开, 步骤展开后是控件列表, 步骤组展开后是子步骤列表
 * 子步骤列表放在children里, 控件列表在展开步骤时拉取
 */

interface Props {
  referenceId: number;
  steps: any;
}

const hideColumnProps = {
  colSpan: 0,
  style: { padding: 0 },
};
const renderHiddableColumn = (renderFn?: Function) => (text: any, record: any) => {
  if (record.isControlList) {
    return { props: hideColumnProps };
  }
  return renderFn ? renderFn(text, record) : text;
};

const getDefaultLogicMappingsName = (controlType: ControlType) => {
  if (controlType === ControlType.file) {
    return 'fileDefaultLogic';
  }
  if (controlType === ControlType.user) {
    return 'userDefaultLogic';
  }
  return 'defaultLogic';
};

const getBizPropertyMappingsName = (controlType: ControlType) => {
  switch (controlType) {
    case ControlType.file:
      return 'fileBizProperty';
    case ControlType.numeric:
      return 'numericBizProperty';
    case ControlType.time:
      return 'timeBizProperty';
    default:
      return 'bizProperty';
  }
};

const propsInternalUsed = ['bizType', 'referenceId', 'stepId', 'operatorId', 'sortIndex'];
const propsHandledElsewhere = ['showLogic', 'showFileList', 'showValueList'];
const propsHandledForUser = [
  'optionalRange',
  'optionalUser',
  'enableCheckbox',
  'defaultLogic',
  'defaultUserOption',
];
const propsHandledForNumberRules = ['relateObject'];

// 业务字段：1.资源控件，2.检验任务控件
// 在表格的【其他】列中
const renderOtherControlOfResource = (
  bizField: Exclude<StepDetailControl['bizField'], undefined> | undefined,
  record: ControlItem,
) => {
  if (!bizField) {
    return replaceSign;
  }
  let textString = '';
  let bizFieldItemContent = '';

  const lableValeArray = _.keys(controlDefaultValueMap.get(record.controlType)?.bizField);

  lableValeArray.map((item) => {
    switch (item) {
      case 'paramGetType':
        bizFieldItemContent = `参数值获取方式：${lookup('sop.paramGetType', bizField[item])}`;
        break;
      case 'resourceType':
        bizFieldItemContent = `资源业务对象：${lookup(
          'sop.resourceType',
          _.toNumber(bizField[item]),
        )}`;
        break;
      // 对象实例（固定参数） 对象实例可选范围（选择参数）
      case 'resourceIds': {
        const labelText =
          record.controlType === ControlType.resourceWithSelectedParams
            ? '对象实例可选范围'
            : '对象实例';
        const valueText = bizField[item]?.map((item) => item.name).join('、') ?? replaceSign;

        bizFieldItemContent = `${labelText}：${valueText}`;
        break;
      }
      // 参数列表（固定参数）
      case 'paramIds': {
        const labelText = '资源参数';
        const valueText = bizField[item]?.map((item) => item.paramsName).join('、');

        bizFieldItemContent = !valueText ? '' : `${labelText}:${valueText}`;
        break;
      }
      case 'maxCount':
        bizFieldItemContent = `参数值最大记录次数：${bizField[item]}`;
        break;
      case 'required':
        bizFieldItemContent = `必填:${lookup('ynb', bizField[item])}`;
        break;
      // 对象实例来源（选择参数）
      case 'resourceSource': {
        const valueText = lookup('sop.objectEntitySource', _.toNumber(bizField[item]));

        bizFieldItemContent = !valueText ? '' : `对象实例来源：${valueText}`;
        break;
      }
      // 检验任务相关
      case 'autoProcess':
        bizFieldItemContent = `自动获取生产任务工序：${lookup('yn', bizField[item])}`;
        break;
      case 'autoMaterial':
        bizFieldItemContent = `自动获取工单主产出物料：${lookup('yn', bizField[item])}`;
        break;
      case 'autoBatchNumber':
        bizFieldItemContent = `自动获取工单主产出批次号：${lookup('yn', bizField[item])}`;
        break;
      default:
        break;
    }
    if (bizFieldItemContent && bizFieldItemContent.length) {
      if (textString && textString.length) {
        textString += '；';
      }
      textString += bizFieldItemContent;
    }
  });
  return textString;
};

function insertControllRow(steps: StepRowData[]) {
  const arr: any[] = [];

  steps.forEach((step, idx) => {
    const item = _.clone(_.omit(step, 'children'));

    // 步骤, 插入控件行
    if (item.type === StepEntityType.step) {
      if (_.isNil(step?.children) || _.isEmpty(step?.children)) {
        item.childrenSteps = [
          {
            isControlList: true,
            stepId: item.id,
            // id用来干掉烦人的『缺key warning』
            id: `control-of-step-${item.id}-${idx}`,
          },
        ];
      }
    } else {
      item.childrenSteps = insertControllRow(step.children);
    }
    arr.push(item);
  });
  return arr;
}

const StepTable: FC<Props> = ({ referenceId, steps }) => {
  const [controlListMap, { set: addControlList }] = useMap<{ [s: string]: any[] }>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [modalInfo, setModalInfo] = useState<any>({});

  // 各控件属性的渲染方法
  const fullControlInfo = {
    defaultLogic: {
      title: '默认值',
      render: (value: DefaultLogic, record: ControlItem) => {
        const dl = lookup(`sop.${getDefaultLogicMappingsName(record.controlType)}`, value);

        switch (value) {
          case DefaultLogic.trigger:
            if (
              record.controlType === ControlType.numeric &&
              record.enableEdit &&
              record?.defaultFormulaId
            ) {
              return renderModalOfNumber({
                linkText: '查看',
                label: '自定义函数内容',
                formulaId: record?.defaultFormulaId,
              });
            }
            return `${dl}-${record.defaultValue}`;
          case DefaultLogic.fixed:
            if (record.controlType === ControlType.file) {
              return (
                <div style={{ width: '300px' }}>
                  <BcAttachmentDetail fileIds={record.defaultFileList} />
                </div>
              );
            } else if (record.controlType === ControlType.time) {
              const format = getTimeFormatString(record.timeType!);
              const displayValue = moment(_.toNumber(record.defaultValue)).format(format);

              return `${dl}-${displayValue}`;
            }
            return `${dl}-${record.defaultValue}`;
          case DefaultLogic.biz:
            return `${dl}-${lookup(
              `sop.${getBizPropertyMappingsName(record.controlType)}`,
              _.toNumber(record.defaultValue),
            )}`;
          case DefaultLogic.currentUser:
            if (record.controlType === ControlType.user && record.enableEdit) {
              return '当前执行用户';
            }
            return '';
          case DefaultLogic.fixedUser:
            if (
              record.controlType === ControlType.user &&
              record.enableEdit &&
              record.optionalRange === UserOptionalRange.appoint &&
              record.defaultLogic === DefaultLogic.fixedUser
            ) {
              const nameString = record?.defaultUserOption?.map((item) => item.name).join('、');

              return `指定用户；指定用户默认值：${nameString}`;
            }
            return '';
          case DefaultLogic.none:
          case DefaultLogic.fillTime:
          default:
            return dl;
        }
      },
    },
    defaultValue: { title: '' },
    enableLimit: {
      title: (record: ControlItem) => {
        if (record.controlType === ControlType.file) {
          return '仅允许上传单个文件';
        }
        return (record.controlType === ControlType.numeric ? limitation.numeric : limitation.text)
          .title;
      },
      render: (value: boolean, record: ControlItem) => {
        const result = lookup('ynb', value);

        if (record.controlType === ControlType.file || !value) {
          return result;
        }
        return `${result}-最少${record.limitMin}，最多${record.limitMax}`;
      },
    },
    numericLimitType: {
      title: '数值范围限制',
      render: (value: number, record: ControlItem) => {
        const result = lookup('sop.numericRangeLimitLogic', value);

        if (record?.controlType === ControlType.numeric) {
          if (value === NumericRangeLimitLogic.range) {
            return `${result}；下限：${record?.lowLimit ?? replaceSign}，上限：${
              record.highLimit ?? replaceSign
            }，下下限：${record.lowestLimit ?? replaceSign}，上上限：${
              record.highestLimit ?? replaceSign
            }`;
          }
        }
        return result;
      },
    },

    inputPrompt: { title: '提示文字' },
    isRequired: { title: '必填', render: lookup('ynb') },
    scanInput: { title: '扫码输入', render: lookup('ynb') },
    scanResultCanEdit: { title: '可修改扫码结果', render: lookup('ynb') },
    limitMin: { title: '' },
    limitMax: { title: '' },
    // wendy TODO 8.11
    // 详情展示

    enableDecimal: {
      title: '保留小数',
      render: (value: boolean, record: ControlItem) => {
        const isKeep = lookup('ynb', value);

        return value ? `${isKeep}；保留小数位数：${record.decimalLength}` : isKeep;
      },
    },
    decimalLength: { title: '' },
    enableSeparator: { title: '显示千分符', render: lookup('ynb') },
    numericType: { title: '格式', render: lookup('sop.numericType') },
    unitName: {
      title: '单位',
    },
    fileSize: { title: '上传文件最大体积', render: (text: number) => `${text}M` },
    defaultFileList: { title: '' },
    fileType: {
      title: '文件类型',
      render: (value: number) =>
        splitIntegerIntoBinaryArray(value).map(lookup('sop.fileType')).join('、'),
    },
    uploadMethod: {
      title: '上传方式',
      render: (value: number) =>
        splitIntegerIntoBinaryArray(value).map(lookup('sop.uploadMethod')).join('、'),
    },
    defaultOption: { title: '默认选项', render: renderLabelArray },
    optionValue: { title: '选项', render: renderLabelArray },
    selectMethod: { title: '选择方式', render: lookup('sop.selectorMethodType') },
    timeType: { title: '日期时间类型', render: lookup('sop.timeType') },
    // 用户控件
    optionalRange: {
      title: '可选范围',
      render: (value: UserOptionalRange, record: ControlItem) => {
        const dl = lookup('sop.userOptionalRange', value);

        switch (value) {
          case UserOptionalRange.current:
            if (record.controlType === ControlType.user && record.enableEdit) {
              return '当前执行用户';
            }
            return '';
          case UserOptionalRange.appoint:
            if (
              record.controlType === ControlType.user &&
              record.enableEdit &&
              record.optionalRange === UserOptionalRange.appoint
            ) {
              const nameString = record?.optionalUser?.map((item: any) => item.name).join('、');
              const enableCheckboxString = record?.enableCheckbox ? '是' : '否';

              return `指定用户；可选指定范围：${nameString}；是否支持多选：${enableCheckboxString}`;
            }
            return '';
          default:
            return dl;
        }
      },
    },
    fieldSource: {
      title: '字段来源',
      render: lookup('sop.fieldSource'),
    },
    relateObject: {
      title: (record: ControlItem) => {
        const controlType = _.get(record, 'controlType');

        switch (controlType) {
          case ControlType.associatedData:
            return '关联数据目标';
          case ControlType.associatedField: {
            return '他表目标';
          }
          default:
            return '';
        }
      },
      render: (value: string, record: ControlItem) => {
        const dl = lookup('sop.objectCodeRange', value);
        const controlType = _.get(record, 'controlType');

        switch (controlType) {
          case ControlType.associatedField: {
            if (record.fieldSource === FieldSource.currentSheet) {
              return '';
            }
            return dl;
          }
          default:
            return dl;
        }
      },
    },
    showField: {
      title: '展示字段',
      render: (value: ShowFieldObj[]) => {
        const nameString = value.map((item) => item.fieldName).join('、');

        return nameString;
      },
    },
    referenceField: {
      title: '关联他表字段',
      render: (value: ShowFieldObj[]) => {
        const nameString = value.map((item) => item.fieldName).join('、');

        return nameString;
      },
    },
    // 关联本表控件名称
    localControl: {
      title: '关联本表控件名称',
      render: (value) => {
        return value?.name;
      },
    },
    enableCheckbox: {
      title: '是否支持多选',
      render: (value: boolean, record: ControlItem) => {
        const enableCheckboxString = value ? '是' : '否';

        switch (record.controlType) {
          case ControlType.user:
            return '';
          case ControlType.associatedData:
            return enableCheckboxString;
          default:
            return enableCheckboxString;
        }
      },
    },
    // ['bizField']['resourceControlField']
    bizField: {
      title: '业务字段',
      groupNameHidden: true,
      render: (value: StepDetailControl['bizField'], record: ControlItem) => {
        return renderOtherControlOfResource(value, record);
      },
    },
    numberRule: {
      title: '编号规则',
      render: (value: StepDetailControl['numberRule'], record: ControlItem) => {
        const controlType = _.get(record, 'controlType');

        switch (controlType) {
          case ControlType.numberRules:
            return `${value?.name},${value?.code}`;
          default:
            return replaceSign;
        }
      },
    },
  };

  const renderModalOfNumber = (config: FormulaInfo) => {
    const { linkText, label, formulaId } = config;

    return (
      <span>
        {label && <span>{label} -</span>}
        <a
          type="link"
          onClick={async () => {
            try {
              const res = await fetchFormulaDetail({ id: formulaId });

              if (res?.code === 200) {
                setModalInfo({
                  visible: true,
                  func: res?.data?.text,
                });
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {linkText}
        </a>
      </span>
    );
  };

  const fetchControlList = useCallback(
    (stepId) => {
      fetchSopStepDetail(
        {
          id: stepId,
          referenceId,
        },
        { legacy: true },
      ).then((res) => {
        addControlList(stepId, res.data?.controls ?? []);
      });
    },
    [referenceId],
  );
  const { searchSteps, getDisplaySteps } = useStepSearch();

  // 控件的列
  const ctrlColumns = [
    { title: '标题', dataIndex: 'name' },
    { title: '控件编号', dataIndex: 'code' },
    { title: '类型', dataIndex: 'controlType', render: lookup('sop.controlType') },
    {
      title: '权限',
      dataIndex: 'enableEdit',
      render: (text: boolean, record: ControlItem) => {
        if (_.isNil(text)) {
          return replaceSign;
        }
        const value = record.controlType === ControlType.selector ? true : text;

        return lookup('sop.editable', value);
      },
    },
    {
      title: '显示内容',
      dataIndex: 'showValue',
      render: (text: string, record: ControlItem) => {
        if (
          record.enableEdit ||
          (_.isNil(record.showLogic) && record.controlType !== ControlType.file) ||
          record.controlType === ControlType.selector
        ) {
          return replaceSign;
        }
        // 只读控件
        if (record.controlType === ControlType.file) {
          return (
            <div style={{ width: '300px' }}>
              <BcAttachmentDetail fileIds={record.showFileList} />
            </div>
          );
        }
        const showLogic = lookup('sop.showLogic', record.showLogic);

        // 无值的显示逻辑
        if (
          record.showLogic === ShowLogic.fillTime ||
          record.showLogic === ShowLogic.currentStation
        ) {
          return showLogic;
        }
        if (record.showLogic === ShowLogic.biz) {
          return `${showLogic}-${lookup('sop.bizProperty', _.toNumber(text))}`;
        }
        if (record.controlType === ControlType.time && record.showLogic === ShowLogic.fixed) {
          const format = getTimeFormatString(record.timeType!);
          const displayValue = moment(_.toNumber(text)).format(format);

          return `${showLogic}-${displayValue}`;
        }

        if (record.controlType === ControlType.user) {
          if (record.showLogic === ShowLogic.fixedUser) {
            const nameString = record?.showValueList?.map((item) => item?.name)?.join('、');

            return `指定用户：${nameString}`;
          }
          if (record.showLogic === ShowLogic.currentUser) {
            return '当前执行用户';
          }
        }
        if (
          record.controlType === ControlType.numeric &&
          !record.enableEdit &&
          record?.showFormulaId
        ) {
          return renderModalOfNumber({
            linkText: '自定义函数值',
            formulaId: record?.showFormulaId,
          });
        }

        return `${showLogic}-${text}`;
      },
    },
    {
      title: '其他信息',
      key: 'others',
      width: 300,
      render: renderOtherInfoOfCtrl,
    },
    {
      title: '描述信息',
      dataIndex: 'remark',
      render: valueOrHolder,
    },
  ];

  function renderOtherInfoOfCtrl(__: unknown, ctrl: ControlItem) {
    const excludeProps = _.compact([
      ..._.map(ctrlColumns, 'dataIndex'),
      ...propsInternalUsed,
      ...propsHandledElsewhere,
      ...(ctrl.controlType === ControlType.user && !ctrl.enableEdit ? propsHandledForUser : []),
      ...(ctrl.controlType === ControlType.numberRules ? propsHandledForNumberRules : []),
    ]);
    const mapped = _.mapValues(
      _.omit(controlDefaultValueMap.get(ctrl.controlType), excludeProps),
      (___: unknown, key) => _.get(ctrl, key),
    );
    const nilExcluded = _.omitBy(mapped, (val) => _.isNil(val) || val === '');
    const entries = _.toPairs(nilExcluded);

    if (_.isEmpty(entries)) {
      return replaceSign;
    }

    return (
      <>
        {_.compact(
          _.map(entries, ([key, value], idx) => {
            const item = _.get(fullControlInfo, key);

            if (_.isNil(item)) {
              console.log(key);
              return '';
            }
            if (!item.title) {
              return null;
            }

            const title = _.isFunction(item.title) ? item.title(ctrl) : item.title;
            const displayValue = item.render ? item.render(value, ctrl) : value;
            const sep = idx === entries.length - 1 ? '' : '；';

            return (
              displayValue && (
                <>
                  {/* {title}：{displayValue} */}
                  {item.groupNameHidden ? (
                    displayValue
                  ) : (
                    <>
                      {title}：{displayValue}
                    </>
                  )}
                  {sep}
                </>
              )
            );
          }),
        )}
      </>
    );
  }

  // 步骤的列
  const columns = [
    {
      title: '步骤/步骤组名称',
      dataIndex: 'name',
      key: 'name',
      // width: 250,
      width: 500,
      render: (__: string, record: any) => {
        if (record.isControlList) {
          return {
            children: (
              <Table
                rowKey={'id'}
                columns={ctrlColumns}
                dataSource={controlListMap[_.toString(record.stepId)]}
                pagination={false}
              />
            ),
            props: {
              colSpan: 7,
              className: 'control-list-in-step-list',
              style: { padding: 0 },
            },
          };
        }
        return getRenderStepName(steps)(__, record);
      },
    },
    {
      title: '步骤/步骤组编号',
      dataIndex: 'code',
      key: 'code',
      width: 250,
      render: renderHiddableColumn(),
    },
    {
      title: '执行权限',
      dataIndex: 'privilegeType',
      key: 'privilegeType',
      width: 200,
      render: renderHiddableColumn(renderExecAuth),
    },
    {
      title: '电子签名',
      key: 'digitalType',
      width: 200,
      render: renderHiddableColumn(renderDigitalSign),
    },
    {
      title: '循环',
      dataIndex: 'loopExecRestrictRule',
      width: 200,
      render: renderHiddableColumn(renderSelfLoop),
    },
    {
      title: '后续步骤',
      dataIndex: 'nextStepType',
      key: 'nextStepType',
      width: 150,
      render: renderHiddableColumn((__: unknown, record: any) => (
        <TextToolTip text={getRenderNextStep(steps)(__, record)} width={150} />
      )),
    },
    // {
    //   title: '结束后执行',
    //   dataIndex: 'execAfterFinish',
    //   key: 'execAfterFinish',
    //   render: renderHiddableColumn(valueOrHolder),
    // },
  ];

  const ds = insertControllRow(getDisplaySteps(steps));

  return (
    <>
      <header style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: 10 }}>
        <Input.Search
          style={{ width: '200px' }}
          placeholder="请输入搜索关键字"
          onSearch={(val) => {
            const result = searchSteps(val, steps);

            if (!result) {
              return;
            }
            const { ancestors } = result;

            setExpandedRowKeys(ancestors);
          }}
          allowClear
        />
      </header>
      <Table
        className="components-table-demo-nested"
        columns={columns}
        rowKey="id"
        expandable={{
          childrenColumnName: 'childrenSteps',
          onExpand: (expanded, record) => {
            const nextExpandedRowKeys = expanded
              ? [...expandedRowKeys, record.id]
              : _.without(expandedRowKeys, record.id);

            setExpandedRowKeys(nextExpandedRowKeys);
            if (expanded && record.type === StepEntityType.step) {
              fetchControlList(record.id);
            }
          },
          expandedRowKeys,
        }}
        dataSource={ds}
      />
      <Modal
        visible={modalInfo?.visible}
        destroyOnClose
        title="自定义函数值表达式"
        centered
        onCancel={() => {
          setModalInfo({ ...modalInfo, ...{ visible: false } });
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => setModalInfo({ ...modalInfo, ...{ visible: false } })}
          >
            关闭
          </Button>,
        ]}
      >
        <span>{modalInfo?.func}</span>
      </Modal>
    </>
  );
};

export default StepTable;
