// Bom
export const ENGINEER_PATH = '/productionPlanning/engineering';

/**
 * 创建BOM
 * @returns string
 */
export const toCreateBom = () => `${ENGINEER_PATH}/Bom/create`;

/**
 * 编辑BOM
 * @param id
 * @returns string
 */
export const toEditBom = (id: number) => `${ENGINEER_PATH}/Bom/edit/${id}`;

/**
 * 复制BOM
 * @param id
 * @returns string
 */
export const toCopyBom = (id: number) => `${ENGINEER_PATH}/Bom/copy/${id}`;

/**
 * 查看BOM
 * @param id
 * @returns string
 */
export const toDetailBom = (id: number) => `${ENGINEER_PATH}/Bom/detail/${id}`;

/**
 * BOM 列表
 * @returns string
 */
export const toBomList = () => `${ENGINEER_PATH}/Bom`;
