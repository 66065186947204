/**
 * 选择器控件属性
 */
import { FC, useContext } from 'react';
import _ from 'lodash';
import { Form, Input, Select, Radio, Checkbox } from 'antd';
import type { FormInstance } from 'antd';
import { SelectorMethodType, ControlType } from 'src/dict/sop';
import { selectorMethodType as selectorMethodTypeMappings } from 'src/dict/sop/mappings';
import type { StepDetailData, LocalControlItem } from '../../types';
import {
  ControlEditorContext,
  CONTROL_TITLE_LENGTH,
  INPUT_DEFAULT_PLACEHOLDER,
} from '../../constants';
import { BlSortFormList } from 'src/components';
import { validateFormListRepeat } from 'src/utils/formValidators';
import { resetUnusedItems, titleRules } from './share';

interface Props {
  form: FormInstance<StepDetailData>;
  localKey: string;
}

const getNameFactory = (dataIndex: number) => (name: string) => ['controls', dataIndex, name];

export const UserInputProperties: FC<Props> = ({ form, localKey }) => {
  const { forceUpdate } = useContext(ControlEditorContext);
  const controlList = form.getFieldValue('controls');

  if (_.isEmpty(controlList)) {
    return null;
  }
  const dataIndex = controlList.findIndex((item: LocalControlItem) => item._localKey === localKey);

  if (dataIndex === -1) {
    return null;
  }
  const getName = getNameFactory(dataIndex);
  const { selectMethod, defaultOption, optionValue } = controlList[dataIndex];

  // 删除选项时，同步清除默认值中的被删除项
  const onDeleteOptionValue = (indexes: number[]) => {
    if (!_.isArray(defaultOption) || _.isEmpty(defaultOption)) {
      return;
    }
    // 注意: 新创建而尚未保存到后端的选项, 只有label属性
    const deletedValues = indexes.map((idx) => optionValue[idx]?.label);
    const nextDefaultOption = defaultOption.filter((item) => !deletedValues.includes(item.value));

    form.setFields([{ name: getName('defaultOption'), value: nextDefaultOption }]);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={(changeValues) => {
        // 如果是在编辑选项则不进行forceUpdate，否则会造成选项输入失去焦点
        if (changeValues.controls?.some((item: any) => _.isArray(item?.optionValue))) {
          return;
        }
        forceUpdate();
        resetUnusedItems(form, ControlType.selector, dataIndex, forceUpdate);
      }}
    >
      <Form.Item label="标题" name={getName('name')} rules={titleRules}>
        <Input max={CONTROL_TITLE_LENGTH} placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>
      <Form.Item label="选择方式" name={getName('selectMethod')}>
        <Radio.Group
          options={selectorMethodTypeMappings}
          onChange={() => {
            form.resetFields([getName('defaultOption')]);
          }}
        />
      </Form.Item>
      <Form.Item label="选项" required>
        <BlSortFormList
          name={getName('optionValue')}
          renderColumns={() => {
            return [
              {
                title: '选项名称',
                dataIndex: 'label',
                render: (text: string, field: any) => (
                  <Form.Item
                    key={field.key}
                    fieldKey={[field.fieldKey, 'label']}
                    name={[field.name, 'label']}
                    validateTrigger={['onChange', 'onBlur']}
                    style={{ marginBottom: '0' }}
                    rules={[
                      {
                        validator: validateFormListRepeat(getName('optionValue'), 'label', form),
                      },
                    ]}
                  >
                    <Input placeholder="请输入" onBlur={() => form.validateFields()} />
                  </Form.Item>
                ),
              },
            ];
          }}
          maxCount={30}
          listRules={[
            {
              validator: async (__, items) => {
                if (!items || _.isEmpty(items.filter((i: any) => !!i))) {
                  return Promise.reject(Error('选项不能为空'));
                } else if (items.some((item: any) => !item || item?.label?.trim() === '')) {
                  return Promise.reject(Error('不能有空选项'));
                } else if (_.uniqBy(items, 'label').length < items.length) {
                  return Promise.reject(Error('不能有重复选项'));
                }
                return Promise.resolve(true);
              },
            },
          ]}
          onDelete={onDeleteOptionValue}
        />
      </Form.Item>
      <Form.Item label="默认值" shouldUpdate style={{ marginBottom: 0 }}>
        {() => {
          const options = (form.getFieldValue(getName('optionValue')) ?? [])
            .filter((item: any) => !!item)
            .map((n: any) => ({ ...n, value: n?.value ?? n.label }));

          return (
            <Form.Item
              name={getName('defaultOption')}
              getValueFromEvent={(value) => {
                if (_.isNil(value)) {
                  return value;
                }
                return selectMethod === SelectorMethodType.multiple ? value : [value];
              }}
              getValueProps={(value) => {
                return selectMethod === SelectorMethodType.single && value
                  ? { value: value[0] }
                  : { value };
              }}
            >
              <Select
                mode={selectMethod === SelectorMethodType.multiple ? 'multiple' : undefined}
                options={options}
                placeholder={'请选择默认值'}
                labelInValue
                allowClear
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item label="校验">
        <Form.Item name={getName('isRequired')} valuePropName="checked" style={{ marginBottom: 0 }}>
          <Checkbox>必填</Checkbox>
        </Form.Item>
      </Form.Item>
      <Form.Item label="描述信息" name={getName('remark')}>
        <Input.TextArea placeholder={INPUT_DEFAULT_PLACEHOLDER} maxLength={1000} />
      </Form.Item>
      <Form.Item
        label="提示文字"
        name={getName('inputPrompt')}
        rules={[{ max: 20, message: '字符数最大为20' }]}
      >
        <Input allowClear placeholder={INPUT_DEFAULT_PLACEHOLDER} />
      </Form.Item>
    </Form>
  );
};

export default UserInputProperties;
