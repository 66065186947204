// 失败结果中引用班次的类型
export const TYPE_CODE_ENUM = new Map([
  [0, '工作日历'],
  [1, '排班规则'],
]);
export const TYPE_CODE_TYPE = {
  SCHEDULING_RULES: 1,
  CALENDAR: 0,
};

export const FIELD_MAP = new Map([
  ['name', 'name'],
  ['sumDuration', 'sum_duration'],
  ['effectiveDuration', 'effective_duration'],
  ['updatedAt', 'created_at'],
  ['createdAt', 'updated_at'],
]);

export const COLOR_ENUM = {
  BLUE: '#91D5FF',
  GREEN: '#B7EB8F',
  PURPLE: '#D3ADF7',
  GOLD: '#FFE58F',
  VOLCANO: '#FFBB96',
  MAGENTA: '#FFADD2',
};

export const COLOR = [
  COLOR_ENUM.BLUE,
  COLOR_ENUM.GREEN,
  COLOR_ENUM.PURPLE,
  COLOR_ENUM.GOLD,
  COLOR_ENUM.VOLCANO,
  COLOR_ENUM.MAGENTA,
];
