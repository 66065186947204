/**
 * 渲染常用的公共函数
 */
import { join, map, compose } from 'lodash/fp';
import { lookup } from 'src/dict';
import { valueOrHolder } from 'src/utils/formatters';

export const renderNull = () => null;
export const renderLabelArray = (arr: any[]) => map('label', arr).join(', ');
export const renderTextPreLine = valueOrHolder((text: string) => (
  <span style={{ whiteSpace: 'pre-line' }}>{text}</span>
));
export const renderMultiSelect = valueOrHolder(compose(join('、'), map('choiceValue')));
export const renderBool = valueOrHolder(lookup('ynb'));
export const renderUrl = valueOrHolder((url: string) => (
  <a href={url} target="_blank" rel="noreferrer">
    {url}
  </a>
));
