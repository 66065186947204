import React, { useEffect, useRef, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock, DataFormLayoutForModal } from 'layout';
import { Form, Radio, Space, Select } from 'antd';
import {
  CARRIER_TYPE,
  SINGLE_PAGE_NUMBER_TYPE,
  CODE_SYSTEM,
  CARRIER_TYPE_ENUM,
  CODE_SYSTEM_ENUM,
} from './constant';
import ComponentToPrint from './components/componentToPrint';
import { useReactToPrint } from 'react-to-print';
import { SuccessListType } from './interface';

const { Option } = Select;
const RadioGroup = Radio.Group;

interface printProps {
  visible: Boolean;
  onCancel: () => void;
  data: SuccessListType[];
}

const PrintModal = (props: printProps) => {
  const { visible, onCancel, data } = props;

  const [printForm] = Form.useForm();

  const componentRef = useRef(null);

  const [single, setSingle] = useState(0);

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    items: [
      {
        label: '标识载体类型',
        name: 'carrierType',
        rules: [{ required: true, message: '标识载体类型必选' }],
        render: () => (
          <Select allowClear>
            {CARRIER_TYPE.map(({ name, id }) => (
              <Option value={id} key={id}>
                {name}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        label: '码制',
        name: 'codeSystem',
        rules: [{ required: true, message: '码制必选' }],
        render: () => (
          <Select allowClear>
            {CODE_SYSTEM.map(({ name, id }) => (
              <Option value={id} key={id}>
                {name}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        label: '单页数量',
        name: 'singlePageNum',
        rules: [{ required: true, message: '单页数量必选' }],
        render: () => (
          <RadioGroup>
            <Radio value={SINGLE_PAGE_NUMBER_TYPE?.SINGLE}>
              <Space>每页一个</Space>
            </Radio>
            <Radio value={SINGLE_PAGE_NUMBER_TYPE?.MULTIPLE}>
              <Space>每页多个</Space>
            </Radio>
          </RadioGroup>
        ),
      },
    ],
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleFinish = async () => {
    try {
      const value = await printForm?.validateFields();

      setSingle(value?.singlePageNum);

      handlePrint && handlePrint();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    printForm.setFieldsValue({
      carrierType: CARRIER_TYPE_ENUM.QRCode,
      codeSystem: CODE_SYSTEM_ENUM.QRCode,
      singlePageNum: SINGLE_PAGE_NUMBER_TYPE.SINGLE,
    });
  }, []);

  return (
    <>
      <ComponentToPrint ref={componentRef} data={data} singlePageNum={single} />
      <DataFormLayoutForModal
        visible={Boolean(visible)}
        onClose={onCancel}
        width={800}
        content={
          <DataFormLayout
            form={printForm}
            title="打印标识码"
            info={[baseInfo]}
            onCancel={onCancel}
            onFinish={handleFinish}
            okText={'打印'}
          />
        }
      />
    </>
  );
};

export default PrintModal;
