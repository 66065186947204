import { useState, useEffect } from 'react';
import { useParams, RouteComponentProps } from 'react-router-dom';
import { DetailLayout } from 'layout';
import { BlIcon, BlTable, SingleOperationLogComponent, TagList } from 'components';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import moment from 'moment';
import { replaceSign } from 'src/utils/constants';
import {
  fetchInboundOrderClose,
  fetchInboundOrderDetail,
  fetchInboundOrderIssue,
} from 'src/api/ytt/inventory-domain/boundOrder';
import type { InboundDetailType } from '../type';
import lookup, { appEnum } from 'src/dict';
import _ from 'lodash';
import { BOUND_PATH as basePath } from '../index';
import { patchRequest, getIdVersionList } from '../utils';
import authDict, { hasAuth } from 'src/utils/auth';
import {
  injectCustomFieldColumns,
  injectCustomFieldInfos,
  useCustomFieldCombinedData,
} from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

interface DetailPageProps extends RouteComponentProps {}

const { StatusEnum } = appEnum.Bound;
const DetailPage = (props: DetailPageProps) => {
  const { history } = props;
  const { id } = useParams<{ id: string }>();

  const [dataSource, setDataSource] = useState<InboundDetailType>({});
  const [visibleLog, setVisibleLog] = useState<boolean>(false);
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.inboundOrder);

  const fetchData = async () => {
    try {
      if (!id) {
        return;
      }
      const { data = {} } = await fetchInboundOrderDetail({ inboundOrderId: Number(id) });

      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };
  const toWriteOf = (material: any) => {
    history.push(`${basePath}/inBound/${id}/detail/writeoff?id=${material.id}`);
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const baseInfo = [
    {
      title: '基本信息',
      items: [
        { label: '入库单编号', dataIndex: 'code' },
        {
          label: '状态',
          dataIndex: 'bizStatus',
          render: lookup('bound.Status'),
        },
        {
          label: '业务类型',
          dataIndex: 'bizType',
          render: lookup('bound.InBoundType'),
        },
        {
          label: '仓库',
          dataIndex: 'wareHouse',
          render: (value: any) => value?.name ?? replaceSign,
        },
        {
          label: '供应商',
          dataIndex: 'supplier',
          render: (value: any) => value?.name ?? replaceSign,
        },
        {
          label: '来源类型',
          dataIndex: 'sourceType',
          render: (value: number) => lookup('warehouse.inSourceTypeEnum', value),
        },
        {
          label: '备注',
          dataIndex: 'remark',
          render: (value: any) => value ?? replaceSign,
        },
      ],
    },
    injectCustomFieldInfos({ customFields, type: 'detail' }),
    {
      title: '物料列表',
      items: [
        {
          label: '',
          dataIndex: 'item',
          render: () => (
            <BlTable
              rowKey={'id'}
              style={{ width: '100%' }}
              scroll={{
                x: 'max-content',
              }}
              pagination={false}
              columns={injectCustomFieldColumns({
                columns: _.compact([
                  {
                    title: '行号',
                    with: 80,
                    dataIndex: 'lineNo',
                  },
                  {
                    title: '物料编号',
                    dataIndex: 'material',
                    with: 160,
                    render: (value: any) => value?.baseInfo?.code,
                  },
                  {
                    title: '物料名称',
                    dataIndex: 'material',
                    with: 160,
                    render: (value: any) => value?.baseInfo?.name,
                  },
                  {
                    title: '物料属性',
                    dataIndex: 'material',
                    with: 160,
                    render: (value: any) => {
                      if (_.isEmpty(value.attribute)) return replaceSign;
                      return (
                        <TagList
                          dataSource={_.map(value.attribute, ({ name, attributeItem }) => ({
                            label: `${name}:${attributeItem.content}`,
                            value: attributeItem.content,
                          }))}
                        />
                      );
                    },
                  },
                  {
                    title: '物料规格',
                    dataIndex: 'material',
                    with: 160,
                    render: (value: any) => value?.baseInfo?.specification,
                  },
                  {
                    title: '应收数量',
                    dataIndex: 'planAmount',
                    with: 160,
                    render: (value: any, record: any) =>
                      `${record.planAmount} ${record?.unit?.name}`,
                  },
                  {
                    title: '实收数量',
                    dataIndex: 'doneAmount',
                    with: 160,
                    render: (value: any, record: any) => {
                      // if (!hasAuth(authDict.inboundrecord_view)) {
                      //   return `${record.doneAmount} ${record?.unit?.name}`;
                      // }
                      return (
                        <a
                          href={`/warehouseManagement/executiveManagement/entryRecord?itemId=${record?.id}`}
                          target={'_blank'}
                          rel="noreferrer"
                        >
                          {`${record.doneAmount} ${record?.unit?.name}`}
                        </a>
                      );
                    },
                  },
                  {
                    title: '入库位置',
                    dataIndex: 'storageLocation',
                    with: 160,
                    render: (value: any, record: any) => record.storageLocation?.storage?.name,
                  },
                  {
                    title: '批次号',
                    dataIndex: 'batchNo',
                    with: 160,
                    render: (value: any, record: any) => record.batchNo?.batchNo,
                  },
                  {
                    title: '备注',
                    dataIndex: 'remark',
                    with: 160,
                  },
                  hasAuth(authDict.inboundorder_reversal) &&
                    (dataSource.bizStatus === StatusEnum.zhixing ||
                      dataSource.bizStatus === StatusEnum.yiwancheng) && {
                      title: '操作',
                      dataIndex: 'action',
                      with: 120,
                      render: (text: any, record: any) => (
                        <a onClick={() => toWriteOf(record)}>冲销</a>
                      ),
                    },
                ]), // 原本的列
                customFields, // 自定义字段信息
                objectCode: OBJECT_OF_CODE.inboundOrderItem, // 从对象code
                type: 'detail', // 使用类型
              })}
              dataSource={dataSource.items}
            />
          ),
        },
      ],
    },
    {
      title: '其他信息',
      items: [
        {
          label: '创建人',
          dataIndex: 'creator',
          render: (creator: any) => {
            return creator ? <AvatarDisplay {...creator} isShowTag={false} isUser /> : replaceSign;
          },
        },
        {
          label: '创建时间',
          dataIndex: 'createdAt',
          isFullLine: true,
          render: (value: number) => moment(value).format('YYYY-MM-DD HH:mm:ss') ?? replaceSign,
        },
        {
          label: '更新人',
          dataIndex: 'operator',
          render: (operator: any) => {
            return operator ? (
              <AvatarDisplay {...operator} isShowTag={false} isUser />
            ) : (
              replaceSign
            );
          },
        },
        {
          label: '更新时间',
          dataIndex: 'updatedAt',
          render: (updatedAt: number) =>
            (updatedAt && moment(updatedAt).format('YYYY-MM-DD HH:mm:ss')) ?? replaceSign,
        },
      ],
    },
  ];
  const { bizStatus } = dataSource;
  const baseMenu = _.compact([
    {
      key: 'operate',
      auth: authDict.inboundorder_operrecord,
      title: '操作记录',
      onClick: () => {
        setVisibleLog(true);
      },
    },
    bizStatus === StatusEnum.xinjian && {
      key: 'xiafa',
      auth: authDict.inboundorder_dispatch,
      onClick: () => {
        if (!dataSource.id) return;
        patchRequest(fetchInboundOrderIssue, getIdVersionList([dataSource]), '下发', fetchData);
      },
      icon: <BlIcon type="iconbianji" />,
      title: '下发',
    },
    bizStatus !== StatusEnum.yiguanbi && {
      key: 'close',
      auth: authDict.inboundorder_close,
      onClick: () => {
        if (!dataSource.id) return;
        patchRequest(fetchInboundOrderClose, getIdVersionList([dataSource]), '关闭', fetchData);
      },
      icon: <BlIcon type="iconbianji" />,
      title: '关闭',
    },
    bizStatus !== StatusEnum.yiguanbi && {
      key: 'edit',
      auth: authDict.inboundorder_edit,
      onClick: () => history.push(`${basePath}/inBound/${id}/edit`),
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
    },
  ]);

  return (
    <>
      <DetailLayout
        title="入库单详情"
        info={baseInfo}
        dataSource={dataSource}
        baseMenu={baseMenu}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={Number(id)}
          objectCode={'InboundOrder'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailPage;
