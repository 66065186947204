/**
 * @enum 收货单模块枚举值
 */

/** 来源 */
export enum SourceEnum {
  /** 普通收货 */
  ordinary,
  /** 协同收货 */
  coordination = 1,
  /** 交货计划单 */
  deliverySchedule = 2,
}
/** 执行状态 */
export enum execStatusEnum {
  /** 新建 */
  created = 10,
  /** 待确认 */
  undetermined = 20,
  /** 已退回 */
  rejected = 30,
  /** 执行中 */
  executing = 40,
  /** 已关闭 */
  closed = 50,
  /** 已结束 */
  finished = 60,
}
/** 结束异常枚举 */
export enum procStatusEnum {
  /** 异常结束 */
  unusual = 1,
  /** 结束 */
  usual,
}
