import moment, { Moment } from 'moment';
import _ from 'lodash';
import { FieldType } from 'src/dict/common';
import { getRules } from './getRules';
import type { FormItemProps } from 'antd';
import type { FieldDTO, NamePath, FieldCascadingDTO } from '../types';

type Params = {
  field: FieldDTO;
  namePath: NamePath;
  fieldCascadings?: FieldCascadingDTO[];
  getNamePathByFieldCode?: (fieldCode: string) => NamePath;
};

/**
 * 字段输入控件的 FormItemProps，用于编辑页。
 * 注：引用字段不设置 name，其 namePath 应传被引字段的
 */
export const getFormItemProps = ({
  field,
  namePath,
  fieldCascadings,
  getNamePathByFieldCode = _.identity,
}: Params) => {
  const result: FormItemProps = {
    label: field.fieldName,
    name: field.isRefer ? undefined : namePath,
    rules: getRules(field),
    tooltip: field.fieldRemind,
    required: !!field.isRequired,
  };

  if (field.isRefer) {
    return result;
  }

  if (field.fieldType === FieldType.date) {
    Object.assign(result, {
      getValueProps(val: number | undefined) {
        return val ? { value: moment(val) } : {};
      },
      getValueFromEvent(date: Moment | undefined) {
        // 毫秒级时间忽略
        return date ? moment(date).startOf('s').valueOf() : date;
      },
    });
  }
  const relatedCascading = (fieldCascadings ?? []).find(
    (cas) => cas.refFieldCode === field.fieldCode,
  );

  if (relatedCascading) {
    const controlFieldNamePath = getNamePathByFieldCode(relatedCascading.fieldCode!);

    result.shouldUpdate = (prevStore, nextStore) =>
      !_.isEqual(_.get(prevStore, controlFieldNamePath), _.get(nextStore, controlFieldNamePath));
  }

  return result;
};
