/**
 * @description 多级条件配置表单组件
 */

import { Col, Form } from 'antd';

import { ConditionItemInfo, ConditionTableLayoutItem } from '../constants';

const FormItem = ({
  renderFormItem,
  render,
  colProps,
  namePath,
  parentPath,
  name,
  ...restProps
}: ConditionTableLayoutItem) => {
  return (
    <Col {...colProps}>
      {renderFormItem ? (
        <Form.Item {...restProps} name={[...namePath, name]}>
          {renderFormItem()}
        </Form.Item>
      ) : (
        render?.(parentPath, namePath)
      )}
    </Col>
  );
};

const ConditionItem = ({ columns, colProps, namePath, parentPath }: ConditionItemInfo) => {
  return (
    <>
      {columns.map((item) => (
        <FormItem
          {...item}
          namePath={namePath}
          colProps={item.colProps ?? colProps}
          parentPath={parentPath}
        />
      ))}
    </>
  );
};

export default ConditionItem;
