import { createModel } from '@rematch/core';
import { fetchFileDetail } from 'src/api/ytt/holyfile-domain';
import { fetchOrganizationGetDisplayInfo } from 'src/api/ytt/user-domain';
import { RootModel } from 'src/store/models/index';

const { factoryLogoBack, factoryLogoFront } = window.hostConfig;

export const logoDisplay = createModel<RootModel>()({
  state: {
    url: { logoUrl: '', logoUrlWhite: '', displayName: '' },
  },
  reducers: {
    setFlag(state, payload) {
      return { url: payload };
    },
  },
  effects: (dispatch) => ({
    async fetchInfoData(): Promise<any> {
      try {
        const { data } = await fetchOrganizationGetDisplayInfo();

        if (data?.logoId) {
          const res = await fetchFileDetail({ id: data?.logoId });

          dispatch({
            type: 'logoDisplay/setFlag',
            payload: {
              logoUrl: res.data?.fileUri,
              logoUrlWhite: res.data?.fileUri,
              displayName: data.displayName,
            },
          });
        } else {
          dispatch({
            type: 'logoDisplay/setFlag',
            payload: {
              logoUrl: factoryLogoBack,
              logoUrlWhite: factoryLogoFront,
              displayName: data?.displayName,
            },
          });
        }
      } catch (e) {
        // @ts-ignore
      }
    },
  }),
});
