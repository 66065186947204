import React, { useEffect } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Input, FormInstance, Radio, Space, Form } from 'antd';
import { DetailSource } from '../interface';
import FormTable from './createAndEditTable';
import styles from './shift.module.scss';
import { textValidator1, withoutSpaceBothSides } from 'src/utils/formValidators';
import { COLOR } from '../constant';

const { TextArea } = Input;

interface baseInfoProps {
  onCancel: () => void;
  onFinish: () => void;
  getTotalHour: (time: number) => void;
  getWorkHour: (time: number) => void;
  title: string;
  initialData?: DetailSource;
  form: FormInstance;
  extra?: any;
  edit: boolean;
  totalHourDisplay: string;
  workHourDisplay: string;
}

const BaseInfo = (props: baseInfoProps) => {
  const {
    onCancel,
    title,
    onFinish,
    initialData,
    form,
    extra,
    edit,
    getTotalHour,
    getWorkHour,
    totalHourDisplay,
    workHourDisplay,
  } = props;

  const baseInfo: DataFormLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '班次名称',
        name: 'name',
        rules: [
          { required: true, message: '班次名称必填' },
          { max: 10, message: '不可超过10个字符' },
          { validator: textValidator1 },
          withoutSpaceBothSides,
        ],
        render: () => <Input placeholder="请输入" allowClear />,
      },
      {
        label: '班次颜色',
        name: 'color',
        rules: [{ required: true, message: '班次颜色必选' }],
        initialValue: edit ? initialData?.color : '',
        render: () => {
          return (
            <div className={styles?.colorBox}>
              <Form.Item name="color">
                <Radio.Group name="color">
                  {COLOR?.map((node: string) => {
                    return (
                      <Space className="colorSpace">
                        <Radio.Button
                          value={node}
                          style={{ backgroundColor: node }}
                          id={node}
                          className={styles.radioButton}
                        />
                      </Space>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
            </div>
          );
        },
      },
    ],
    column: 2,
  };

  const timeInfo: DataFormLayoutInfoBlock = {
    title: '时段信息',
    items: [
      {
        label: '',
        name: 'effectiveDuration',
        render: () => {
          return (
            <div
              style={{
                height: 70,
                backgroundColor: '#FAFAFA',
                padding: 14,
              }}
            >
              <div>有效工时</div>
              <div style={{ fontWeight: 700 }}>{workHourDisplay}</div>
            </div>
          );
        },
      },
      {
        label: '',
        name: 'sumDuration',
        render: () => {
          return (
            <div
              style={{
                height: 70,
                backgroundColor: '#FAFAFA',
                padding: 14,
              }}
            >
              <div>总工时</div>
              <div style={{ fontWeight: 700 }}>{totalHourDisplay}</div>
            </div>
          );
        },
      },
      {
        label: '',
        isFullLine: true,
        render: () => {
          return (
            <div>
              <FormTable
                initialData={initialData?.shiftInfoDetailVOS}
                form={form}
                getWorkHour={getWorkHour}
                getTotalHour={getTotalHour}
              />
            </div>
          );
        },
      },
      {
        label: '备注',
        name: 'remark',
        rules: [{ max: 1000, message: '不可超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="请输入" />,
      },
      {
        label: '编辑原因',
        name: 'operateReason',
        hidden: !edit,
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
    ],
    column: 2,
  };

  useEffect(() => {
    if (initialData) {
      form?.setFieldsValue({
        name: initialData?.name,
        color: initialData?.color,
        effectiveDuration: initialData?.effectiveDuration,
        remark: initialData?.remark,
      });
      getWorkHour(initialData?.effectiveDuration);
      getTotalHour(initialData?.sumDuration);
    }
  }, [initialData]);

  return (
    <DataFormLayout
      formLayout="vertical"
      form={form}
      title={title}
      info={[baseInfo, timeInfo]}
      extra={extra || ''}
      onCancel={onCancel}
      onFinish={onFinish}
    />
  );
};

export default BaseInfo;
