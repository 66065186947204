import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Input, message, Radio, Checkbox } from 'antd';
import { RecordListLayout, DataFormLayoutForModal, DataFormLayout } from 'layout';
import {
  fetchStandardBizObjectCustomObjectListPage,
  fetchStandardBizObjectCustomObjectSwitch,
  fetchStandardBizObjectCustomObjectCreate,
  fetchStandardBizObjectCustomObjectUpdate,
} from 'src/api/ytt/metadata-domain/custom_object';
import { BlIcon, LinkTooltip, SearchSelect } from 'src/components';
import lookup, { appDict, appEnum } from 'src/dict';
import { CRUD, YN, FieldType, ObjectCategory } from 'src/dict/common';
import { usageStatus as usageStatusMappings, yn as ynMappings } from 'src/dict/common/mappings';
import authDict from 'src/utils/auth';
import renderUsageStatus from 'src/page/share/renderUsageStatus';
import { textValidator3, checkTwoSidesTrim, noDigitStart } from 'src/utils/formValidators';
import { useForm } from 'antd/es/form/Form';
import { toFieldDetailList } from '../navigation';
import type { DataFormLayoutInfoBlock } from 'src/layout/dataForm';
import type { CustomObjectData } from '../type';
import { fetchCustomFieldGetById } from 'src/api/ytt/metadata-domain/custom_field';

const defaultValue = {
  objectCode: '',
  objectName: '',
  objectCategory: ObjectCategory.customObject,
  objectDesc: '',
  mainProperty: {
    fieldCode: '',
    fieldName: '',
    fieldType: FieldType.text,
    isUnique: YN.yes,
    isName: YN.yes,
  },
};

const initModalValue = {
  visible: false,
  opType: CRUD.create,
  initialFormValues: defaultValue,
};

function CustomObjectList() {
  const history = useHistory();
  const [form] = useForm();
  const [modalState, setModalState] = useState<typeof initModalValue>(initModalValue);
  const [refreshMarker, setRefreshMarker] = useState<number>(); // 刷新页面
  const [submitting, setSubmitting] = useState(false);
  const [succeedingCreate, setSucceedingCreate] = useState(true);
  const [enableNumberRule, setEnableNumberRule] = useState(false);
  const [suitObjId, setSuitObjId] = useState(-1);
  const [mainPropertieId, setMainPropertieId] = useState<number | undefined>(-1);

  const refresh = () => setRefreshMarker(Date.now());
  const isEdit = modalState.opType === CRUD.edit;

  const columns = [
    {
      title: '对象编号',
      dataIndex: 'objectCode',
      width: 150,
      render: (text: string, record: CustomObjectData) => (
        <LinkTooltip
          text={text}
          to={toFieldDetailList(record)}
          auth={authDict.object_view}
          width={150}
        />
      ),
      sorter: true,
      isFilter: true,
      type: FieldType.text,
    },
    {
      title: '对象名称',
      dataIndex: 'objectName',
      width: 200,
      render: (text: string, record: CustomObjectData) => (
        <LinkTooltip
          text={text}
          to={toFieldDetailList(record)}
          auth={authDict.object_view}
          width={200}
        />
      ),
      sorter: true,
      isFilter: true,
      type: FieldType.text,
    },
    {
      title: '状态',
      dataIndex: 'isUsed',
      width: 110,
      render: renderUsageStatus,
      sorter: true,
      isFilter: true,
      type: FieldType.select,
      selectProps: {
        options: usageStatusMappings,
      },
    },
    {
      title: '对象描述',
      dataIndex: 'objectDesc',
      width: 200,
    },
    {
      title: '主属性名称',
      dataIndex: ['primeAttribute', 'fieldName'],
      width: 150,
    },
  ];

  const filterList = _.filter(columns, 'isFilter').map((column) => {
    const filter = {
      label: column.title,
      name: column.dataIndex,
      type: column.type,
    } as any;

    if (column.selectProps) {
      filter.selectProps = column.selectProps;
    }

    return filter;
  });

  const getNumberRuleName = async (record: any) => {
    const _record = _.cloneDeep(record);
    const mainPropertieId: number = _record.primeAttribute.id;
    const { data } = await fetchCustomFieldGetById({ id: mainPropertieId, fieldCategory: 1 });

    _record.mainProperty = _record.primeAttribute;

    if (data?.isNumberRuleConfig) {
      setEnableNumberRule(true);
    }

    setSuitObjId(_record.id);
    setMainPropertieId(data?.id);
    setModalState({
      visible: true,
      opType: CRUD.edit,
      initialFormValues: {
        ..._record,
        objectCategory: ObjectCategory.customObject,
        numberRuleId: { value: data?.numberRuleId, label: data?.numberRuleName },
      } as any,
    });

    _.delay(form.resetFields, 0);
  };

  const getOperationList = (record: CustomObjectData) => {
    const { isUsed, objectCode } = record;
    const switchAction = lookup('common.changeStatusAction', isUsed);

    return [
      {
        title: '查看',
        onClick: () => history.push(toFieldDetailList(record)),
        auth: authDict.object_detail,
      },
      {
        title: '编辑',
        auth: authDict.object_edit,
        onClick: () => {
          getNumberRuleName(record);
        },
      },
      {
        title: lookup('common.changeStatusAction', isUsed),
        auth: authDict.object_enable_disable,
        onClick: () => {
          fetchStandardBizObjectCustomObjectSwitch({
            objectCode,
            switchValue: !isUsed,
          }).then(() => {
            message.success(`${switchAction}成功！`);
            refresh();
          });
        },
      },
    ];
  };

  const mainMenu = [
    {
      title: '新建自定义对象',
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.object_add,
      onClick: () => {
        setModalState({
          visible: true,
          opType: CRUD.create,
          initialFormValues: defaultValue,
        });
        _.delay(form.resetFields, 0);
      },
      items: [],
    },
  ];

  const formInfo: DataFormLayoutInfoBlock[] = [
    {
      title: '基本信息',
      items: [
        {
          label: '对象编号',
          name: 'objectCode',
          rules: [
            { required: true, message: '对象编号必填' },
            { max: 100, message: '不可超过100个字符' },
            { validator: textValidator3 },
            { validator: noDigitStart },
          ],
          render: () => <Input disabled={isEdit} />,
        },
        {
          label: '对象名称',
          name: 'objectName',
          required: true,
          rules: [
            { required: true, message: '对象名称必填' },
            { max: 255, message: '不可超过255个字符' },
            { validator: checkTwoSidesTrim },
          ],
          render: () => <Input />,
        },
        {
          label: '对象描述',
          name: 'objectDesc',
          rules: [{ max: 1000, message: '不可超过1000个字符' }],
          render: () => <Input.TextArea showCount />,
        },
        {
          name: 'objectCategory',
          hidden: true,
          render: () => null,
        },
      ],
    },
    {
      title: '主属性',
      items: [
        {
          label: '字段编号',
          name: ['mainProperty', 'fieldCode'],
          rules: [
            { required: true, message: '字段编号必填' },
            { max: 100, message: '不可超过100个字符' },
            { validator: textValidator3 },
          ],
          render: () => <Input disabled={isEdit} />,
        },
        {
          label: '字段名称',
          name: ['mainProperty', 'fieldName'],
          rules: [
            { required: true, message: '字段名称必填' },
            { max: 255, message: '不可超过255个字符' },
          ],
          render: () => <Input disabled={isEdit} />,
        },
        {
          label: '字段类型',
          name: ['mainProperty', 'fieldType'],
          render: () => lookup('fieldType', FieldType.text),
        },
        {
          label: '数据唯一性',
          name: ['mainProperty', 'isUnique'],
          render: () => <Radio.Group options={ynMappings} disabled />,
        },
        {
          label: '显示属性',
          name: ['mainProperty', 'isName'],
          render: () => <Radio.Group options={ynMappings} disabled />,
        },
        {
          label: '启用编号规则',
          name: 'enableNumberRule',
          initialValue: enableNumberRule ? appEnum.Common.YN.yes : appEnum.Common.YN.no,
          rules: enableNumberRule ? [{ required: true, message: '请选择编号规则' }] : [],
          render: () => (
            <Radio.Group
              disabled={!isEdit}
              onChange={(e) => {
                e.target.value === appEnum.Common.YN.no
                  ? setEnableNumberRule(false)
                  : setEnableNumberRule(true);
              }}
              options={appDict.common.yn}
            />
          ),
        },
        {
          label: '编号规则',
          name: 'numberRuleId',
          rules: [{ required: enableNumberRule, message: '请选择编号规则' }],
          render: () => (
            <SearchSelect
              labelInValue
              fetchType={'numberingRules'}
              placeholder={'请选择'}
              params={{ suitObjId, enableFlag: appEnum.Common.UsageStatus.enabled }}
              disabled={!enableNumberRule || !isEdit}
              onChange={() => refresh()}
            />
          ),
        },
      ],
    },
  ];

  const closeModal = () => {
    setModalState(initModalValue);
    setEnableNumberRule(false);
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const fn = isEdit
        ? fetchStandardBizObjectCustomObjectUpdate
        : fetchStandardBizObjectCustomObjectCreate;

      _.set(values, 'mainProperty', {
        ...values.mainProperty,
        isNumberRuleConfig: isEdit ? values.enableNumberRule : 0,
        numberRule:
          isEdit && values.numberRuleId && values.enableNumberRule ? values.numberRuleId.value : -1,
        id: isEdit ? mainPropertieId : undefined,
      });
      const submitValues = isEdit
        ? _.pick(values, ['objectCode', 'objectName', 'objectDesc', 'mainProperty'])
        : values;

      setSubmitting(true);
      if (isEdit && enableNumberRule && !submitValues.mainProperty.numberRule) {
        message.error('请选择编号规则');
        setSubmitting(false);
      } else {
        fn(submitValues)
          .then(() => {
            message.success(`${lookup('crud', modalState.opType)}成功！`);
            if (!isEdit && succeedingCreate) {
              form.resetFields();
            } else {
              closeModal();
            }
            refresh();
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    });
  };

  useEffect(() => form.resetFields(), [modalState]);

  const formatDataToQuery = (params: any) => {
    return {
      ..._.omit(params, ['quickSearch']),
      search: params.quickSearch,
    };
  };

  return (
    <>
      <RecordListLayout<CustomObjectData>
        placeholder={'请输入对象名称或编号'}
        columns={columns}
        filterList={filterList}
        rowKey={'objectCode'}
        mainMenu={mainMenu}
        refreshMarker={refreshMarker}
        getOperationList={getOperationList}
        formatDataToQuery={formatDataToQuery}
        requestFn={fetchStandardBizObjectCustomObjectListPage}
      />
      <DataFormLayoutForModal
        visible={modalState.visible}
        onClose={closeModal}
        width={1100}
        content={
          <DataFormLayout
            title={`${lookup('crud', modalState.opType)}自定义对象`}
            info={formInfo}
            form={form}
            formProps={{ initialValues: modalState.initialFormValues }}
            confirmLoading={submitting}
            onFinish={handleSubmit}
            onCancel={closeModal}
            extra={
              !isEdit && (
                <Checkbox
                  onChange={(e) => setSucceedingCreate(e.target.checked)}
                  checked={succeedingCreate}
                >
                  连续新建
                </Checkbox>
              )
            }
            bodyStyle={{ height: document.body.clientHeight - 210 }}
          />
        }
      />
    </>
  );
}

export default CustomObjectList;
