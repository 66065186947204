import { createContext } from 'react';
import _ from 'lodash';
import type { FormInstance } from 'antd';
import { YN } from 'src/dict/common';
import { LayoutComponentType, PageType } from 'src/dict/customLayout';
import type { Field, LayoutParsedData, LayoutParsedComponent, SonObject } from '../../type';

export interface EditorProps {
  pageType: PageType;
  fieldList: Field[];
  sonObjectList: SonObject[];
  form?: FormInstance<LayoutParsedData>;
  supportTab: boolean;
  disabled?: boolean;
}

interface EditorContextContent extends EditorProps {
  forceUpdate: () => void;
}

export const EditorContext = createContext<EditorContextContent>({
  fieldList: [],
  sonObjectList: [],
  form: undefined,
  pageType: PageType.detail,
  supportTab: false,
  forceUpdate: _.noop,
  disabled: false,
});

export const isSonObjectComp = (type: LayoutComponentType) =>
  type === LayoutComponentType.sonObjectTab || type === LayoutComponentType.sonObjectTable;

export const getInitContent = (
  type: LayoutComponentType,
  pageType?: PageType,
  extra: {
    fieldCode?: string;
    fieldList?: Field[];
    sonObjectCode?: string;
    sonObjectList?: SonObject[];
  } = {},
): LayoutParsedComponent['content'] => {
  if (isSonObjectComp(type)) {
    const { sonObjectCode, sonObjectList } = extra;
    const sonObj =
      sonObjectCode && sonObjectList
        ? sonObjectList!.find((item) => item.objectCode === sonObjectCode)!
        : undefined;
    const content: ReturnType<typeof getInitContent> = { objectCode: sonObjectCode };

    if (pageType === PageType.edit && type === LayoutComponentType.sonObjectTable) {
      content.required = sonObj?.childNecessary ?? YN.no;
      content.frequent = sonObj?.childNecessary ?? YN.no;
    }
    return content;
  } else if (type === LayoutComponentType.field) {
    const { fieldList, fieldCode } = extra;
    const field = _.find(fieldList, { fieldCode })!;

    return {
      fieldName: field.fieldName,
      fieldPath: fieldCode,
      fieldCategory: field.fieldCategory,
      ...(pageType === PageType.edit
        ? {
            required: field.isRequired,
            frequent: field.isRequired,
          }
        : {}),
    };
  }
  return { title: '' };
};

export const getComponentInitialValue = (
  type: LayoutComponentType,
  pageType: PageType,
  extra?: Parameters<typeof getInitContent>[2],
): LayoutParsedData['components'][number] => {
  return {
    type,
    content: getInitContent(type, pageType, extra),
  };
};

const SYSTEM_FIELDS = ['creator', 'createdAt', 'operator', 'updatedAt'];

export const isSystemField = (field: Field) => SYSTEM_FIELDS.includes(field.fieldCode!);
export const isEditableField = (field: Field) => field.createdSup || field.editAble;

export const NEW_COMPONENT_INDEX = -10086;
