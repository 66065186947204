import { Table } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { fetchDigitalSignatureConfigList } from 'src/api/ytt/user-domain/eSignature';
import { AvatarDisplay, RoleDisplay } from 'src/components/avatar/show';
import { RoleType, UserType } from '../type';
// eslint-disable-next-line import/no-cycle
import SignatureModal from './SignatureModal';

type DetailSignConfigListProps = {
  signConfigList?: any[];
  inBusiness?: boolean;
  functionCode?: string;
  signatureSessionId?: number;
};

const DetailSignConfigList: React.FC<DetailSignConfigListProps> = (props) => {
  const { signConfigList, inBusiness, functionCode = '', signatureSessionId = 0 } = props;
  const [currentRow, setCurrentRow] = useState();
  const [tableData, setTableData] = useState([]);
  const [showSignModal, setShowSignModal] = useState(false);

  const getColumns = () => {
    const columns: any[] = [
      {
        title: '序号',
        dataIndex: 'seq',
        width: 60,
        render: (seq: number, record: any, index: number) => index + 1,
      },
      {
        title: '签名人范围',
        dataIndex: 'signArea',
        render: (_: any, record: any) => {
          const { roles, users, allUserFlag, currentUserFlag } = record;

          return (
            <>
              {allUserFlag ? (
                <AvatarDisplay
                  id={allUserFlag}
                  name={'所有用户'}
                  key={allUserFlag}
                  isShowTag
                  isUser
                />
              ) : null}
              {currentUserFlag ? (
                <AvatarDisplay
                  id={currentUserFlag}
                  name={'当前登录用户'}
                  key={currentUserFlag}
                  isShowTag
                  isUser
                />
              ) : null}
              {users
                ?.filter((item: any) => item)
                .map((user: UserType) => (
                  <AvatarDisplay id={user?.id} name={user?.name} key={user?.id} isShowTag isUser />
                ))}
              {roles
                ?.filter((item: any) => item)
                .map((role: RoleType) => (
                  <RoleDisplay id={role?.id} name={role?.name} isShowTag />
                ))}
            </>
          );
        },
      },
    ];

    if (inBusiness) {
      columns.push({
        title: '操作',
        dataIndex: 'action',
        width: 100,
        render: (_: any, record: any) => {
          const { verifyStatus } = record || {};

          return verifyStatus ? (
            <span>签名</span>
          ) : (
            <a
              onClick={() => {
                setShowSignModal(true);
                setCurrentRow(record);
              }}
            >
              签名
            </a>
          );
        },
      });
    }

    return columns;
  };

  /**
   * 查看电子签名配置人列表（用于开始签名）
   * @param functionCode 功能点Code
   * @param signatureSessionId 唯一ID
   */
  const fetchSignatureConfigList = async (functionCode: string, signatureSessionId: number) => {
    const res = await fetchDigitalSignatureConfigList({ functionCode, signatureSessionId });

    setTableData(_.get(res, 'data.digitalSignatureSignConfigVOList', []));
  };

  useEffect(() => {
    if (inBusiness) {
      fetchSignatureConfigList(functionCode, signatureSessionId);
    }
  }, []);

  return (
    <>
      <Table
        columns={getColumns()}
        dataSource={inBusiness ? tableData : signConfigList}
        pagination={false}
      />
      {showSignModal && (
        <SignatureModal
          visible={showSignModal}
          close={(isSuccess?: boolean) => {
            setShowSignModal(false);
            if (isSuccess) {
              fetchSignatureConfigList(functionCode, signatureSessionId);
            }
          }}
          currentRow={currentRow}
          functionCode={functionCode}
          signatureSessionId={signatureSessionId}
        />
      )}
    </>
  );
};

export default DetailSignConfigList;
