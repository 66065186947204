import React, { useState } from 'react';
import { Form, Input, InputNumber, Radio } from 'antd';
import _, { isInteger } from 'lodash';
import { getDefaultScale } from 'src/page/quality/models/constants/qcConfig';
import NumberStandard from './numberStandard';
import SelectOptions from './selectOptions';
import styles from '../style.module.scss';
import { CheckItemInputTypeEnum, CheckValueTypeEnum, TextInputEnum } from 'src/dict/quality';
import { appDict } from 'src/dict';
import { numberCheck, numberMinMaxCheck } from 'src/utils/formValidators';
import TextInput from './textInput';

const ExecuteItemType = (props: { form: any }) => {
  const [inputType, setInputType] = useState<CheckItemInputTypeEnum>();
  const { form } = props;
  const chekItemInputType = inputType || form.getFieldValue('executeItemType');
  const isNumberStandard =
    chekItemInputType === CheckItemInputTypeEnum.inputFormatNum ||
    chekItemInputType === CheckItemInputTypeEnum.inputFormatPercent;
  const isTextType = chekItemInputType === CheckItemInputTypeEnum.inputFormatText;

  const getInitialValues = (chekItemInputType: CheckItemInputTypeEnum) => ({
    logic: null,
    base: null,
    unit: null,
    max: null,
    min: null,
    scale: getDefaultScale(chekItemInputType),
    checkValueType: CheckValueTypeEnum.singleNumber,
    textInputType: CheckItemInputTypeEnum.inputFormatText,
    qualityItems: [],
    radios: [],
    defaultValue: chekItemInputType === CheckItemInputTypeEnum.inputFormatMultiple ? [] : null,
    extraResults: [],
  });

  return (
    <>
      <Form.Item name="executeItemType" noStyle>
        <Radio.Group
          options={appDict.quality.CheckItemInputType}
          onChange={(e: {
            target: { value: React.SetStateAction<CheckItemInputTypeEnum | undefined> };
          }) => {
            setInputType(e.target.value);
            form.setFieldsValue(getInitialValues(e.target.value));
          }}
        />
      </Form.Item>
      {isNumberStandard && (
        <NumberStandard form={form} defaultScale={getDefaultScale(chekItemInputType)} />
      )}
      {isTextType && (
        <div className={styles.groupContent} style={{ paddingBottom: 16 }}>
          <TextInput form={form} />
        </div>
      )}
      {chekItemInputType === CheckItemInputTypeEnum.inputFormatSingle && (
        <SelectOptions form={form} type={chekItemInputType} />
      )}
      {chekItemInputType === CheckItemInputTypeEnum.inputFormatMultiple && (
        <SelectOptions form={form} type={chekItemInputType} />
      )}
      {chekItemInputType === CheckItemInputTypeEnum.inputFormatRecordDefectCount && (
        <div className={styles.groupContent}>
          <Form.Item
            label="默认值"
            name="defaultValue"
            className={styles.formulaInput}
            style={{ paddingBottom: 16, marginBottom: 0 }}
            rules={[
              {
                validator: numberMinMaxCheck({
                  min: 0,
                  minAllowEqual: true,
                  max: 999999999999,
                  maxAllowEqual: true,
                  fieldName: '默认值',
                  isInteger: true,
                }),
              },
            ]}
          >
            <Input style={{ width: 88 }} />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default ExecuteItemType;
