export const setFieldValueofCondition = (
  conditions: string[],
  formItemName: string,
  defaultValue: any,
  dependcyObj,
) => {
  const { form, getName } = dependcyObj;
  let isTouched = false;

  for (const value of conditions) {
    if (form.isFieldTouched(getName(value))) {
      isTouched = true;
      break;
    }
  }
  const fieldValue = !isTouched ? form.getFieldValue(getName(formItemName)) : defaultValue;

  form.setFields([
    {
      name: getName(formItemName),
      value: fieldValue,
    },
  ]);
};
