import SalesOrderList from './salesManagement/salesOrder';
import InvoiceList from './salesManagement/invoice';
import CreateSalesOrder from './salesManagement/salesOrder/createAndEdit/create';
import SalesOrderDetailPage from './salesManagement/salesOrder/detail/detailPage';
import EditSalesOrder from './salesManagement/salesOrder/createAndEdit/edit';
import SalesReturnOrderList from './salesManagement/salesReturnOrder/list';
import SalesReturnOrderOperator from './salesManagement/salesReturnOrder/createAndEdit';
import SalesReturnOrderDetail from './salesManagement/salesReturnOrder/detail';
import ReturnApplicationList from './salesManagement/salesReturnOrder/returnApplicationList/ReturnApplicationList';
import ReturnApplicationDetail from './salesManagement/salesReturnOrder/returnApplicationList/ReturnApplicationDetail';
import CollaborativeDeliveryScheduleList from './salesManagement/collaborativeDeliverySchedule';
import CollaborativeDeliverySchedulePage from './salesManagement/collaborativeDeliverySchedule/detail/detailPage';

import CreateAndEditInvoiceOrder from './salesManagement/invoice/createAndEdit';
import InvoiceDetailPage from './salesManagement/invoice/detail';
import PurchaseRequisitionrDetailPage from './salesManagement/salesOrder/purchaseRequisitionList/detail/detailPage';
import PurchaseRequisitionList from './salesManagement/salesOrder/purchaseRequisitionList/list';

import OutBoundOrderCreate from 'src/page/warehouseManagement/boundOrderManagement/outBound/create';
import InBoundCreate from 'src/page/warehouseManagement/boundOrderManagement/inBound/create';
import authDict from 'src/utils/auth';

const SalesRoute = [
  {
    path: '/sales',
    menu: '销售',
    breadcrumb: '销售',
    icon: 'iconyijicaidan-xiaoshou',
    redirectPath: '/sales/salesManagement/salesOrder',
    children: [
      {
        path: '/sales/salesManagement',
        menu: '销售管理',
        breadcrumb: '销售管理',
        redirectPath: '/sales/salesManagement/salesOrder',
        children: [
          {
            path: '/sales/salesManagement/salesOrder',
            auth: authDict.salesorder_view,
            logPage: true,
            component: SalesOrderList,
            menu: '销售订单',
            breadcrumb: '销售订单',
            children: [
              {
                path: '/sales/salesManagement/salesOrder/create',
                auth: authDict.salesorder_add,
                component: CreateSalesOrder,
                menu: '新建销售订单',
                breadcrumb: '新建销售订单',
              },
              {
                path: '/sales/salesManagement/salesOrder/:id/edit',
                auth: authDict.salesorder_edit,
                component: EditSalesOrder,
                menu: '编辑销售订单',
                breadcrumb: '编辑销售订单',
              },
              {
                path: '/sales/salesManagement/salesOrder/:id/detail',
                auth: authDict.salesorder_detail,
                component: SalesOrderDetailPage,
                menu: '销售订单详情',
                breadcrumb: '销售订单详情',
              },
              {
                path: '/sales/salesManagement/salesOrder/invoice/create',
                auth: authDict.shipmentsnote_add,
                component: CreateAndEditInvoiceOrder,
                breadcrumb: '新建发货单',
              },
            ],
          },
          {
            path: '/sales/salesManagement/purchaseRequisition',
            auth: authDict.purchaseauditnote_view,
            logPage: true,
            component: PurchaseRequisitionList,
            breadcrumb: '协同采购申请单',
            children: [
              {
                path: '/sales/salesManagement/purchaseRequisition/:id/detail',
                auth: authDict.purchaseauditnote_detail,
                component: PurchaseRequisitionrDetailPage,
                menu: '详情',
                breadcrumb: '详情',
              },
            ],
          },
          {
            path: '/sales/salesManagement/salesReturnOrder',
            auth: authDict.salesorderreturn_view,
            logPage: true,
            component: SalesReturnOrderList,
            menu: '销售退货单',
            breadcrumb: '销售退货单',
            children: [
              {
                path: '/sales/salesManagement/salesReturnOrder/create',
                auth: authDict.salesorderreturn_add,
                component: SalesReturnOrderOperator,
                breadcrumb: '新建销售退货单',
              },
              {
                path: '/sales/salesManagement/salesReturnOrder/:id/edit',
                auth: authDict.salesorderreturn_edit,
                component: SalesReturnOrderOperator,
                breadcrumb: '编辑销售退货单',
              },
              {
                path: '/sales/salesManagement/salesReturnOrder/:id/detail',
                auth: authDict.salesorderreturn_detail,
                component: SalesReturnOrderDetail,
                breadcrumb: '销售退货单详情',
              },
              {
                path: '/sales/salesManagement/salesReturnOrder/inbound',
                auth: authDict.inboundorder_add,
                component: InBoundCreate,
                breadcrumb: '新建入库单',
              },
              {
                path: '/sales/salesManagement/salesReturnOrder/returnApplication',
                auth: authDict.returnauditnote_view,
                component: ReturnApplicationList,
                breadcrumb: '退货申请单',
              },
              {
                path: '/sales/salesManagement/salesReturnOrder/returnApplication/:id/detail',
                auth: authDict.returnauditnote_detail,
                component: ReturnApplicationDetail,
                breadcrumb: '退货申请单详情',
              },
            ],
          },
          {
            path: '/sales/salesManagement/invoice',
            auth: authDict.shipmentsnote_view,
            logPage: true,
            component: InvoiceList,
            menu: '发货单',
            breadcrumb: '发货单',
            children: [
              {
                path: '/sales/salesManagement/invoice/create',
                auth: authDict.shipmentsnote_add,
                component: CreateAndEditInvoiceOrder,
                menu: '新建发货单',
                breadcrumb: '新建发货单',
              },
              {
                path: '/sales/salesManagement/invoice/:id/edit',
                auth: authDict.shipmentsnote_edit,
                component: CreateAndEditInvoiceOrder,
                menu: '编辑发货单',
                breadcrumb: '编辑发货单',
              },
              {
                path: '/sales/salesManagement/invoice/createOutBound',
                auth: authDict.outboundorder_add,
                component: OutBoundOrderCreate,
                menu: '新建出库单',
                breadcrumb: '新建出库单',
              },
              {
                path: '/sales/salesManagement/invoice/:id/detail',
                auth: authDict.shipmentsnote_detail,
                component: InvoiceDetailPage,
                menu: '发货单详情',
                breadcrumb: '发货单详情',
              },
            ],
          },
          {
            path: '/sales/salesManagement/collaborativeDeliverySchedule',
            auth: authDict.CustomerDeliveryScheduleNote_view,
            logPage: true,
            component: CollaborativeDeliveryScheduleList,
            menu: '协同交货计划单',
            breadcrumb: '协同交货计划单',
            children: [
              {
                path: '/sales/salesManagement/collaborativeDeliverySchedule/:id/detail',
                auth: authDict.CustomerDeliveryScheduleNote_detail,
                component: CollaborativeDeliverySchedulePage,
                menu: '协同交货计划详情',
                breadcrumb: '协同交货计划详情',
              },
              {
                path: '/sales/salesManagement/collaborativeDeliverySchedule/invoice/create',
                auth: authDict.shipmentsnote_add,
                component: CreateAndEditInvoiceOrder,
                breadcrumb: '新建发货单',
              },
            ],
          },
        ],
      },
    ],
  },
];

export default SalesRoute;
