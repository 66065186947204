import _ from 'lodash';
import { appEnum } from 'src/dict';
import { YN } from 'src/dict/common';
//
import { MaterialEntity } from './index.type';

interface Option {
  label: string;
  value: string | number;
}

/**
 * 根据物料信息获取物料可选单位简单信息，用于Select
 */
export const getMaterialUnits = (material?: MaterialEntity): Option[] => {
  return _.map(getMaterialUnitsInfo(material), (item: any) => ({
    label: item.name,
    value: item.id,
  }));
};

/**
 * 根据物料信息获取物料可选单位及详情
 */
export const getMaterialUnitsInfo = (material?: MaterialEntity): MaterialEntity['unitList'] => {
  let options: MaterialEntity['unitList'] = [];

  if (material && !_.isEmpty(material?.unitList)) {
    const { unitList } = material;

    options = _.filter(
      _.compact(unitList),
      ({ unitType, enableFlag }) =>
        unitType !== appEnum.Material.UnitType.auxUnit &&
        enableFlag === appEnum.Common.UsageStatus.enabled,
    );
  }

  return options;
};

/**
 * 获取物料单个单位信息
 * @param material
 * @param unitId
 */
export const getMaterialUnitInfo = (
  material?: MaterialEntity,
  unitId?: number,
): NonNullable<MaterialEntity['unitList']>[number] => {
  let retUnitInfo: NonNullable<MaterialEntity['unitList']>[number] = {} as NonNullable<
    MaterialEntity['unitList']
  >[number];

  if (!_.isEmpty(material?.unitList) && unitId) {
    const searchUnitInfo = _.find(material?.unitList, ({ id }) => id === unitId);

    if (searchUnitInfo) retUnitInfo = searchUnitInfo;
  }

  return retUnitInfo;
};

/**
 * 获取物料上的主单位信息
 */
export const getMaterialMainUnit = (material?: MaterialEntity): Option | undefined => {
  const mainUnit = _.find(getMaterialUnitsInfo(material), [
    'unitType',
    appEnum.Material.UnitType.mainUnit,
  ]);

  if (!mainUnit) {
    return undefined;
  }

  return {
    label: mainUnit?.name as string,
    value: mainUnit?.id as string | number,
  };
};
/**
 * 获取物料上的报工单位信息
 * @param material
 * @param needDefault 是否需要返回默认主单位
 */
export const getMaterialProduceUnit = (
  material?: MaterialEntity,
  needDefault?: boolean,
): Option | undefined => {
  const { productionUnitId } = material?.productionInfo || {};
  const productionUnit = _.find(getMaterialUnitsInfo(material), ['id', productionUnitId]);

  if (!productionUnitId || !productionUnit) {
    if (needDefault) {
      return getMaterialMainUnit(material);
    }
    return undefined;
  }
  return {
    label: productionUnit?.name as string,
    value: productionUnit?.id as string | number,
  };
};

/**
 * 获取物料的属性列表
 * @param material
 */
export const getMaterialAttrs = (material?: MaterialEntity): Option[] => {
  const options: Option[] = [];

  if (material && !_.isEmpty(material)) {
    const { attribute } = material;

    _.forEach(attribute, (item) => {
      if (item?.id) {
        options.push({
          label: `${item.name}:${item.attributeItem?.content}`,
          value: item?.id,
        });
      }
    });
  }

  return options;
};

/**
 * 获取物料的批次属性
 * @param material
 * @param name 批次属性 name
 * @return
 *  {
 *    displayEnable: number,  是否显示
 *    nullEnable: number,     是否必填
 *  }
 */
export const getMaterialbBatchAttrs = (
  material: MaterialEntity,
  name: number,
): {
  displayEnable: number;
  nullEnable: number;
  batchManagementEnable: number;
} => {
  if (material && !_.isEmpty(material)) {
    const { batchManagementEnable, batchAttrs } = material;

    // 物料启用了批次管理，
    if (batchManagementEnable && batchAttrs) {
      for (let i = 0; i < batchAttrs.length; i++) {
        const item = batchAttrs[i];

        if (item?.name === name) {
          return {
            displayEnable: item.displayEnable as number,
            nullEnable: item.nullEnable as number,
            batchManagementEnable,
          };
        }
      }
    }

    return {
      displayEnable: YN.no,
      nullEnable: YN.yes,
      batchManagementEnable: YN.no,
    };
  }

  return {
    displayEnable: YN.no,
    nullEnable: YN.yes,
    batchManagementEnable: YN.no,
  };
};
/**
 * 判断物料是否是虚拟件
 */
export const judgeMaterialIsVirtual = (material?: MaterialEntity) => {
  const bizType = _.get(material, ['baseInfo', 'bizType'], appEnum.Material.BizType.default);

  return bizType === appEnum.Material.BizType.phantom;
};

/**
 * 获取物料上的投料单位信息
 * @param material
 * @param needDefault 是否需要返回默认主单位
 */
export const getMaterialFeedUnit = (
  material?: MaterialEntity,
  needDefault?: boolean,
): Option | undefined => {
  const { feedUnitId } = material?.feedInfo || {};
  const feedUnit = _.find(getMaterialUnitsInfo(material), ['id', feedUnitId]);

  if (!feedUnitId || !feedUnit) {
    if (needDefault) {
      return getMaterialMainUnit(material);
    }
    return undefined;
  }
  return {
    label: feedUnit?.name as string,
    value: feedUnit?.id as string | number,
  };
};
