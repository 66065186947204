import React, { useState, useEffect } from 'react';
import { Avatar } from 'antd';
import styles from './styles.module.scss';
import { BcUpload, BlIcon } from '..';
import defaultAvatar from 'src/assets/default-avatar.png';

interface uploadProps {
  formKey?: string;
  form?: any;
  uploadedUrl?: string;
}

// 点击头像 进行上传
const UploadAvatar = (props: uploadProps) => {
  const { form, uploadedUrl, formKey = 'avatarUrl' } = props;
  const [preViewUrl, setPreViewUrl] = useState<string>('');

  useEffect(() => {
    if (uploadedUrl) {
      setPreViewUrl(uploadedUrl);
    }
  }, [uploadedUrl]);

  return (
    <div className={styles?.avatarUpload}>
      <Avatar size={86} src={preViewUrl || defaultAvatar} style={{ marginRight: 8 }} />
      <div className={styles?.avatarUploadBtn}>
        <BcUpload
          draggable={false}
          showUploadList={false}
          limit={'image'}
          maxCount={1}
          maxSize={10}
          onUploaded={(data: any) => {
            setPreViewUrl(data[0]?.response?.uri);
            form?.setFieldsValue({ [formKey]: data[0]?.response });
          }}
        >
          <div className={styles?.avatarBtn}>{preViewUrl ? '修改头像' : '上传头像'}</div>
        </BcUpload>
      </div>
      <span style={{ marginRight: 24 }}>大图</span>
      <Avatar size={32} src={preViewUrl || defaultAvatar} style={{ marginRight: 8 }} />
      缩略图
    </div>
  );
};

export default UploadAvatar;
