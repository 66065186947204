import { Tabs } from 'antd';
import { BlTable } from 'src/components';
import { DetailLayout, DetailLayoutInfoBlock, DetailLayoutMenuItem } from 'src/layout';
import _ from 'lodash';
import _Array from 'src/utils/dataTypes/array';
import { injectCustomFieldInfos, injectCustomFieldColumns } from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { PurchaseOrderMaterialCarryMode } from 'src/dict/purchase';
import {
  detailItemColumns,
  detailOutItemColumns,
  baseInfoColumns,
  otherInfoColumns,
} from './columns';
interface Props {
  dataSource: any;
  baseMenu?: DetailLayoutMenuItem[];
  customFields?: any;
  itemCustomFields?: any;
  outItemCustomFields?: any;
}
const { TabPane } = Tabs;
const DetailsBaseInfo = (props: Props) => {
  const { dataSource, baseMenu, customFields, itemCustomFields, outItemCustomFields } = props;

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: baseInfoColumns,
  };

  const materialInfo: DetailLayoutInfoBlock = {
    title: '物料信息',
    items: [
      {
        label: '',
        dataIndex: 'material',
        render: () => {
          if (dataSource.materialCarryMode == PurchaseOrderMaterialCarryMode.carry) {
            return (
              <Tabs type="card">
                <TabPane tab="采购订单" key="1">
                  <BlTable
                    scroll={{ y: 630 }}
                    rowKey={(record) => record?.id}
                    // 注入从对象自定义字段列
                    columns={injectCustomFieldColumns({
                      columns: detailItemColumns(dataSource.materialCarryMode), // 原本的列
                      customFields: itemCustomFields, // 自定义字段信息
                      objectCode: OBJECT_OF_CODE.purchaseOrderItem, // 从对象code
                      type: 'detail', // 使用类型
                    })}
                    dataSource={dataSource.itemList}
                    pagination={false}
                  />
                </TabPane>
                <TabPane tab="外协用料清单" key="2">
                  <BlTable
                    scroll={{ y: 630 }}
                    rowKey={(record) => record?.id}
                    // 注入从对象自定义字段列
                    columns={injectCustomFieldColumns({
                      columns: detailOutItemColumns, // 原本的列
                      customFields: outItemCustomFields, // 自定义字段信息
                      objectCode: OBJECT_OF_CODE.outsourceMaterialList, // 从对象code
                      type: 'detail', // 使用类型
                    })}
                    dataSource={dataSource.outItemList}
                    pagination={false}
                  />
                </TabPane>
              </Tabs>
            );
          } else {
            return (
              <BlTable
                scroll={{ y: 630 }}
                rowKey={(record) => record?.id}
                // 注入从对象自定义字段列
                columns={injectCustomFieldColumns({
                  columns: detailItemColumns(dataSource.materialCarryMode), // 原本的列
                  customFields: itemCustomFields, // 自定义字段信息
                  objectCode: OBJECT_OF_CODE.purchaseOrderItem, // 从对象code
                  type: 'detail', // 使用类型
                })}
                dataSource={dataSource.itemList}
                pagination={false}
              />
            );
          }
        },
      },
    ],
  };

  const customFileds: DetailLayoutInfoBlock = injectCustomFieldInfos({
    customFields,
    type: 'detail',
  });

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: otherInfoColumns,
  };

  const infoArray = [baseInfo, customFileds, materialInfo, otherInfo];

  return (
    <DetailLayout
      title={'采购订单详情'}
      baseMenu={baseMenu}
      info={infoArray}
      dataSource={dataSource}
    />
  );
};

export default DetailsBaseInfo;
