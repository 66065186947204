import { message, message as Message, Modal } from 'antd';
import {
  fetchPurchaseOrderBatchDistribute,
  fetchPurchaseOrderBatchClosed,
  fetchPurchaseOrderBatchEnded,
  fetchPurchaseOrderBatchReboot,
  fetchPurchaseOrderBatchEndedEnsure,
  fetchPurchaseOrderListMaterialByIds,
} from 'src/api/ytt/order-domain/purchaseOrder';
import { replaceSign } from 'src/utils/constants';
import { showErrorListMessage } from 'src/components';
import { showEndListMessage } from './components/endMessageList';
import {
  PurchaseExecStatus,
  EndResultType,
  PurchaseSource,
  PurchaseOrderdeliveryMode,
} from 'src/dict/purchase';
import _ from 'lodash';

const getErrorColumns = (isException?: boolean) => [
  {
    title: '序号',
    dataIndex: 'num',
    width: 150,
    render: (code: number) => code || replaceSign,
  },
  {
    title: '编号',
    dataIndex: 'code',
    width: 150,
    render: (code: string) => code || replaceSign,
  },
  {
    title: '供应商名称',
    dataIndex: ['supplier', 'name'],
    width: 150,
  },
  {
    title: isException ? '中断原因' : '失败原因',
    dataIndex: 'errorReason',
    width: 300,
    render: (detail: string) => detail || replaceSign,
  },
];

const handleDistribute = async (
  ids: number[],
  onSuccess?: () => void,
  onFail?: () => void,
  refresh?: () => void,
) => {
  try {
    const { data } = await fetchPurchaseOrderBatchDistribute({ ids });

    if (data) {
      const { successCount, failCount, failDetails } = data;

      if (failCount === 0) {
        Message.success('下发成功');
        onSuccess?.();
        refresh?.();
        return;
      }
      if (ids.length === 1) {
        Message.error(`下发失败，${failDetails?.[0].errorReason}`);
        onFail?.();
        return;
      }
      if (successCount === 0) {
        onFail?.();
        // 全部失败
      } else if (failCount! > 0) {
        onSuccess?.();
        // 部分失败 需要展示失败信息
      }

      showErrorListMessage({
        title: '下发出现失败',
        data: (failDetails as Object[]).map((item, index) => {
          return { ...item, num: index + 1 };
        }),
        columns: getErrorColumns(),
        operateName: '下发',
        successCount,
        failCount,
        width: 800,
      });

      return;
    }
  } catch (error) {
    onFail?.();
    console.log(error);
  }
};

const handleClosed = async (
  ids: number[],
  onSuccess?: () => void,
  onFail?: () => void,
  refresh?: () => void,
) => {
  try {
    const { data } = await fetchPurchaseOrderBatchClosed({ ids });

    if (data) {
      const { successCount, failCount, failDetails } = data;

      if (failCount === 0) {
        Message.success('关闭成功');
        onSuccess?.();
        refresh?.();
        return;
      }
      if (ids.length === 1) {
        Message.error(`关闭失败，${failDetails?.[0].errorReason}`);
        onFail?.();
        return;
      }
      if (successCount === 0) {
        onFail?.();
        // 全部失败
      } else if (failCount! > 0) {
        onSuccess?.();
        // 部分失败 需要展示失败信息
      }

      showErrorListMessage({
        title: '关闭出现失败',
        data: (failDetails as Object[]).map((item, index) => {
          return { ...item, num: index + 1 };
        }),
        columns: getErrorColumns(),
        operateName: '关闭',
        successCount,
        failCount,
        width: 800,
      });

      return;
    }

    return;
  } catch (error) {
    onFail?.();
    console.log(error);
  }
};

const handleReboot = async (
  ids: number[],
  onSuccess?: () => void,
  onFail?: () => void,
  refresh?: () => void,
) => {
  try {
    const { data } = await fetchPurchaseOrderBatchReboot({ ids });

    if (data) {
      const { successCount, failCount, failDetails } = data;

      if (failCount === 0) {
        Message.success('重启成功');
        onSuccess?.();
        refresh?.();
        return;
      }
      if (ids.length === 1) {
        Message.error(`重启失败，${failDetails?.[0].errorReason}`);
        onFail?.();
        return;
      }
      if (successCount === 0) {
        onFail?.();
        // 全部失败
      } else if (failCount! > 0) {
        onSuccess?.();
        // 部分失败 需要展示失败信息
      }

      showErrorListMessage({
        title: '重启出现失败',
        data: (failDetails as Object[]).map((item, index) => {
          return { ...item, num: index + 1 };
        }),
        columns: getErrorColumns(),
        operateName: '重启',
        successCount,
        failCount,
        width: 800,
      });

      return;
    }

    return;
  } catch (error) {
    onFail?.();
    console.log(error);
  }
};

const showError = (res: any, option: any) => {
  const { failCount = 0, successCount = 0, failDetails } = res?.data ?? {};

  if (failCount) {
    if (option.type === 'multiple') {
      showErrorListMessage({
        failCount,
        successCount,
        title: '结束出现失败',
        width: 800,
        data: (failDetails as Object[]).map((item, index) => {
          return { ...item, num: index + 1 };
        }),
        desc: `采购订单结束完成，结束成功：${successCount}个，结束失败：${failCount}个，具体失败原因如下：`,
        columns: getErrorColumns(),
        ...option,
      });
    } else {
      const first = _.first(failDetails) as any;

      message.error(first?.errorReason);
    }
  } else {
    message.success(`${option.display}！`);
  }
};

const endOrderconfirm = async (
  ids: number[],
  type: 'multiple' | 'single',
  display: string,
  onSuccess?: () => void,
  onFail?: () => void,
) => {
  try {
    const response = await fetchPurchaseOrderBatchEnded({ ids });

    if (response && response.code === 200 && response.data) {
      showError(response, {
        method: 'error',
        okText: '知道了',
        type,
        display,
      });
    }
    onSuccess?.(); // 如果出现弹窗闪退的情况 建议参照采购退货，移动 至 showError 里
  } catch (error) {
    onFail?.();
    console.log(error);
  }
};

const endOrder = async (
  ids: number[],
  codes: string[],
  type: 'multiple' | 'single',
  onSuccess?: () => void,
  onFail?: () => void,
  setLoading?: (loading: boolean) => void,
) => {
  try {
    setLoading?.(true);
    const response = await fetchPurchaseOrderBatchEndedEnsure({ ids });

    if (response && response.code === 200 && response.data) {
      const { successCount, failCount, failDetails } = response.data;
      const hasException = failDetails?.some(
        (failDetail) => failDetail.procStatus === EndResultType.exception,
      );
      const display = hasException ? '异常结束成功' : '结束成功';

      if (failCount === 0) {
        Modal.confirm({
          title: '结束采购订单',
          content: `结束采购订单后不能再进行任何操作，确定结束${codes?.join(', ')}吗？`,
          onOk: () => endOrderconfirm(ids, type, display, onSuccess, onFail),
          onCancel: onFail,
          cancelText: '暂不结束',
          okText: '结束',
        });
      } else {
        if (type === 'multiple') {
          showEndListMessage({
            title: '提示',
            message: '以下采购订单结束后不能再进行任何操作，确认结束吗？',
            data: (failDetails as Object[]).map((item, index) => {
              return { ...item, num: index + 1 };
            }),
            columns: getErrorColumns(true),
            onCancel: onFail,
            onOk: () => endOrderconfirm(ids, type, display, onSuccess, onFail),
            successCount,
            failCount,
            cancelText: '暂不结束',
            okText: '结束',
          });
          return;
        }
        const first = _.first(failDetails) || {};

        Modal.confirm({
          title: '结束采购订单',
          content: `${first.errorReason}，确定结束采购订单${first.code}吗？`,
          onCancel: () => onFail?.(),
          onOk: () => endOrderconfirm(ids, type, display, onSuccess, onFail),
          cancelText: '暂不结束',
          okText: '结束',
        });
      }
    }
  } catch (error) {
    onFail?.();
    console.log(error);
  } finally {
    setLoading?.(false);
  }
};

const beforeCreateReturnOrder = async (
  ids: number[],
  type: string,
  onSuccess?: () => void,
  onFail?: () => void,
  list?: any[],
) => {
  try {
    let data;

    if (list) {
      // 物料行视图
      data = list;
    } else {
      const { data: res } = await fetchPurchaseOrderListMaterialByIds({ ids });

      data = res;
    }
    const baseSource = data?.[0]?.source;
    const isSameSource = data?.every((value) => value.source === baseSource);
    const baseSupplier = data?.[0]?.supplier?.id;
    const isSameSupplier = data?.every((value) => value?.supplier?.id === baseSupplier);

    if (type == '交货计划单') {
      const isAllCollaborate = data?.every((value) => value.source === PurchaseSource.collaborate);
      const isALlDeliveryMode = data?.every(
        (value) => value.deliveryMode === PurchaseOrderdeliveryMode.deliverySchedule,
      );

      if (!isAllCollaborate) {
        Message.error('只有协同采购订单可以新建交货计划单');
        onFail?.();
        return false;
      }
      if (!isALlDeliveryMode) {
        Message.error('只有交货方式为按交货计划的采购订单可以新建交货计划单');
        onFail?.();
        return false;
      }
      if (!isSameSupplier) {
        Message.error('只能选择供应商一致的采购订单');
        onFail?.();
        return false;
      }
    }

    const isAllExecuting = data?.every(
      (value) => value.execStatus === PurchaseExecStatus.executing,
    );

    if (!isAllExecuting) {
      Message.error(`只有执行中的采购订单可以新建${type}`);
      onFail?.();
      return false;
    }
    // 校验
    if (ids.length > 1) {
      if (!isSameSource) {
        Message.error('只能选择来源相同且供应商一致的采购订单');
        onFail?.();
        return false;
      }
      if (!isSameSupplier) {
        Message.error('只能选择来源相同且供应商一致的采购订单');
        onFail?.();
        return false;
      }
    }
    onSuccess?.();
    return data;
  } catch (error) {
    onFail?.();
    console.log(error);
    return false;
  }
};
const handleSwich = (content: string, onOk: () => void, onCancel: () => void) => {
  Modal.confirm({
    title: '提示',
    content,
    onOk,
    onCancel,
    cancelText: '取消',
    okText: '确认',
  });
};

export {
  handleDistribute,
  handleClosed,
  handleReboot,
  endOrder,
  beforeCreateReturnOrder,
  handleSwich,
};
