import React, { useEffect, useState } from 'react';
import { Form, message } from 'antd';
import { match, RouteComponentProps } from 'react-router-dom';
import ElectronicTemplateCreateAndEditBastInfo from './components/createAndEditBastInfo';
import {
  fetchEReportQueryTemplateDetail,
  fetchEReportTemplateModify,
  fetchEReportTemplateStatusProcess,
} from 'src/api/ytt/e-report-domain/customReport';
import { toElectronicTemplateDetail, toElectronicTemplateList } from './navigation';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';
import { APPROVE_ENUM } from './constant';

interface EditProps extends RouteComponentProps {
  match: match<{ id: string }>;
}

export default function EditElectronicTemplate(props: EditProps) {
  const { match, history } = props;

  const {
    location: { search },
  } = history;

  const backType = search.slice(6);
  const templateId = Number(match?.params?.id);
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState({} as any);

  const [addDataVisible, setAddDataVisible] = useState(false);

  const handelSetAddDataVisible = (visible: boolean) => {
    setAddDataVisible(visible);
  };

  const fetchData = async () => {
    try {
      const { data: detailData } = await fetchEReportQueryTemplateDetail({ templateId });

      if (detailData) {
        setDataSource(detailData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    localStorage.setItem('templateId', match?.params?.id);
    fetchData();
    return () => {
      localStorage.removeItem('templateId');
      localStorage.removeItem('subTemplateIdList');
      localStorage.removeItem('subTemIds');
    };
  }, [templateId]);

  const onCancel = () => {
    if (backType === 'detail') {
      history.push(toElectronicTemplateDetail(templateId));
    } else {
      history.push(toElectronicTemplateList());
    }
  };

  const handleFinish = async () => {
    try {
      const value = await form?.validateFields();

      const { approvePlan, ...rest } = value;

      const subTemIds = localStorage.getItem('subTemIds');

      if (subTemIds) {
        // 需要修改模板文件的
        const subTemplateIdList = localStorage
          .getItem('subTemIds')
          ?.split(',')
          .map((e) => Number(e));

        const { data, code } = await fetchEReportTemplateStatusProcess({
          subTemplateIdList,
          status: 1,
        });

        const { data: Data, code: Code } = await fetchEReportTemplateModify({
          ...rest,
          templateId,
          type: value?.type?.value,
          approvalPlanId: value?.approval === APPROVE_ENUM.YES ? approvePlan?.value : 0,
          approvalPlanName: value?.approval === APPROVE_ENUM.YES ? approvePlan?.label : '',
        });

        if (code === 200 && Code === 200 && !data?.failNum && Data) {
          message.success('编辑电子单据模板成功');
          onCancel();
          localStorage.removeItem('templateId');
          localStorage.removeItem('subTemplateIdList');
        }
        return;
      }

      // 只修改了基础信息
      const { data: Data, code: Code } = await fetchEReportTemplateModify({
        ...value,
        templateId,
        type: value?.type?.value,
        approvalPlanId: value?.approval === APPROVE_ENUM.YES ? approvePlan?.value : 0,
        approvalPlanName: value?.approval === APPROVE_ENUM.YES ? approvePlan?.label : '',
      });

      if (Code === 200 && Data) {
        message.success('编辑电子单据模板成功');
        onCancel();
        localStorage.removeItem('templateId');
        localStorage.removeItem('subTemplateIdList');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ElectronicTemplateCreateAndEditBastInfo
      form={form}
      title="编辑电子单据模板"
      onCancel={onCancel}
      onFinish={throttleFn(handleFinish)}
      initialData={dataSource}
      addDataVisible={addDataVisible}
      handelSetAddDataVisible={handelSetAddDataVisible}
      edit
    />
  );
}
