/**
 * @modal 业务类型排序
 */

import { FC, useEffect, useState } from 'react';
import { Form, message, Modal } from 'antd';
import { pick } from 'lodash/fp';
import { BlSortFormList } from 'src/components';
import { DisplayText } from 'src/components/editableText';
import { fetchBizTypeListWithoutPage, fetchBizTypeResequence } from 'ytt/metadata-domain/bizType';
import { useBlocker, qs } from 'src/utils';

interface Props {
  visible: boolean;
  hide: () => void;
  refreshList: () => void;
}

const renderColumns = () => [
  {
    dataIndex: 'name',
    width: 300,
    render: (__: unknown, field: any) => {
      return (
        <Form.Item name={[field.name, 'name']} style={{ marginBottom: 0 }}>
          <DisplayText />
        </Form.Item>
      );
    },
  },
];

const SortModal: FC<Props> = ({ visible, hide, refreshList }) => {
  const [loadingComp, setLoadingComp] = useState<'data' | 'submit' | null>(null);
  const [form] = Form.useForm();
  const { resetForm, shouldLeave } = useBlocker(form);
  const { objectCode } = qs.parse();

  const handleCancel = () => shouldLeave() && hide();
  const handleFinish = () => {
    const values = form.getFieldsValue();

    setLoadingComp('submit');
    fetchBizTypeResequence(values.list.map(pick('id')))
      .then(() => {
        message.success('保存完成。');
        resetForm(values);
        refreshList();
        handleCancel();
      })
      .catch(() => setLoadingComp(null));
  };

  useEffect(() => {
    if (visible) {
      setLoadingComp('data');
      // @ts-ignore
      fetchBizTypeListWithoutPage({ objectCode })
        .then((res) => {
          resetForm({ list: res.data ?? [] });
        })
        .finally(() => setLoadingComp(null));
    }
  }, [visible]);

  return (
    <Modal
      className="bl-modal"
      visible={visible}
      title="业务类型排序"
      onCancel={handleCancel}
      onOk={handleFinish}
      okButtonProps={{ loading: loadingComp === 'submit' }}
      maskClosable={false}
      destroyOnClose
    >
      <BlSortFormList
        name="list"
        form={form}
        isNeedAdd={false}
        isNeedDelete={false}
        tableProps={{ showHeader: false, loading: loadingComp === 'data' }}
        renderColumns={renderColumns}
      />
    </Modal>
  );
};

export default SortModal;
