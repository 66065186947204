import React, { useEffect, useState } from 'react';
import DeliverySchedule from 'src/assets/workbench/deliverySchedule.png';
import ReceiveSchedule from 'src/assets/workbench/receiveSchedule.png';
import AfterReceiveCount from 'src/assets/workbench/afterReceiveCount.png';
import { Button, Select, Space } from 'antd';
import { dateRange } from 'src/dict/purchase/mappings';
import { CHART_NAME, DATE_RANGE } from 'src/dict/purchase';
import { updateConfig } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { useHistory } from 'react-router-dom';
import { getDetailUrl } from '../navigation';

type SummaryCardProps = {
  title: string;
  unit: string;
  dataIndex: string;
  showDateFilter?: boolean;
};

const SummaryCard: React.FC<SummaryCardProps> = (props) => {
  const { title, unit, showDateFilter, dataIndex } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const workbench = useSelector((state: RootState) => state.workbench);
  const [value, setValue] = useState(DATE_RANGE.day);

  const fetchData = () => {
    dispatch({ type: 'workbench/fetchChartsData', payload: dataIndex });
  };

  const renderBg = (dataIndex: string) => {
    switch (dataIndex) {
      case CHART_NAME.deliverySchedule:
        return DeliverySchedule;
      case CHART_NAME.receiveSchedule:
        return ReceiveSchedule;
      case CHART_NAME.overdueRecOrderSchedule:
        return AfterReceiveCount;
      default:
        return null;
    }
  };

  const changeConfig = (value: number) => {
    setValue(value);
    const params = {
      configItem: dataIndex,
      dateRange: value,
    };

    updateConfig(params, () => {
      fetchData();
      dispatch({ type: 'workbench/fetchConfig' });
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (workbench?.config) {
      setValue(workbench.config?.[dataIndex]);
    }
  }, [workbench.config]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 138,
        padding: '18px 0 22px',
        background: `url(${renderBg(dataIndex)}) right bottom no-repeat`,
        backgroundColor: '#fcfcfc',
        border: '1px solid #ededed',
        borderRadius: 4,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <span style={{ fontSize: 16, fontWeight: 500, opacity: 0.65 }}>{title}</span>
        <Space>
          {showDateFilter && (
            <Select value={value} size={'small'} options={dateRange} onChange={changeConfig} />
          )}
          <Button
            type={'primary'}
            ghost
            size={'small'}
            onClick={() => {
              history.push(
                getDetailUrl({ key: dataIndex, dataRange: workbench?.config?.[dataIndex] }),
              );
            }}
          >
            查看明细
          </Button>
        </Space>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '56%',
          height: 56,
          paddingLeft: 20,
          background: 'linear-gradient(270deg, #fcfcfc, #f2f2f2)',
          fontSize: 38,
          fontWeight: 600,
          color: '#262626',
        }}
      >
        <span>{workbench?.[dataIndex]}</span>
        <span style={{ fontSize: unit === '个' ? 20 : 38 }}>{unit}</span>
      </div>
    </div>
  );
};

export default SummaryCard;
