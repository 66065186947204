import React from 'react';
import { ShipmentNoteDetailResponse } from '../../interface';
import InvoiceGoodsDialog from './baseInfo';
import { DataFormLayoutForModal } from 'layout';

interface Props {
  dataSource: ShipmentNoteDetailResponse;
  visible: boolean;
  onClose: () => void;
}
const DeliverGoodsModal = (props: Props) => {
  const { visible, onClose, dataSource } = props;

  return (
    <DataFormLayoutForModal
      visible={visible}
      onClose={onClose}
      content={<InvoiceGoodsDialog deliverOrder={dataSource} onCancel={onClose} />}
    />
  );
};

export default DeliverGoodsModal;
