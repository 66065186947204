import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import { BlTable } from 'src/components';
import moment from 'moment';
import {
  fetchDispatchRequestLogGet,
  FetchDispatchRequestLogGetResponse,
} from 'src/api/ytt/med-domain/work_order';
import { DetailLayout } from 'src/layout';
import { AvatarDisplay } from 'src/components/avatar/show';
import { replaceSign } from 'src/utils/constants';
import { getResult, getStatus } from './utils';

interface Props extends RouteChildrenProps<{ id: string }> {}

const ResultMap = new Map([
  ['0', '下发中'],
  ['2', '成功'],
  ['3', '失败'],
]);

const LogDetail = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState<FetchDispatchRequestLogGetResponse['data']>();
  const [pagination, setPagination] = useState({ page: 1, size: 10 });

  const { match } = props;

  useEffect(() => {
    fetchAndSetData(pagination);
  }, []);

  const fetchAndSetData = (pagination: { page: number; size: number }) => {
    const id = match?.params?.id ? _.toNumber(match?.params?.id) : undefined;

    fetchDispatchRequestLogGet({ id, ...pagination })
      .then((res) => {
        setDataSource(res?.data);
        setPagination(pagination);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const baseInfo = [
    {
      title: '',
      items: [
        {
          label: '操作时间',
          isFullLine: true,
          dataIndex: 'updatedAt',
          render: (text: any) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : replaceSign),
        },
        {
          label: '操作用户',
          isFullLine: true,
          dataIndex: 'operator',
          render: (info: any) => <AvatarDisplay {...info} isUser />,
        },
        {
          label: '操作结果',
          isFullLine: true,
          dataIndex: 'failedCount',
          render: (text: any, record: any) => getStatus(record ?? {}),
        },
        {
          label: '操作详情',
          isFullLine: true,
          dataIndex: 'successCount',
          render: (text: any, record: any) => getResult(record ?? {}),
        },
        {
          label: '',
          isFullLine: true,
          dataIndex: 'productRate',
          render: () => {
            return (
              <BlTable
                dataSource={dataSource?.processLogs}
                onChange={(pagination) => {
                  fetchAndSetData({
                    page: pagination.current ?? 1,
                    size: pagination.pageSize ?? 10,
                  });
                }}
                columns={[
                  {
                    title: '操作结果',
                    width: 80,
                    dataIndex: 'result',
                    render: (result: any) => ResultMap.get(result),
                  },
                  { title: '工单编号', width: 200, dataIndex: 'workOrderCode' },
                  { title: '工序编号', width: 100, dataIndex: 'processNum' },
                  { title: '工序名称', width: 200, dataIndex: 'processName' },
                  { title: '任务编号', width: 150, dataIndex: 'produceTaskCode' },
                  { title: '失败原因', width: 200, dataIndex: 'failedReason' },
                ]}
                pagination={{
                  pageSize: pagination.size,
                  current: pagination.page,
                }}
              />
            );
          },
        },
      ],
    },
  ];

  return (
    <DetailLayout loading={loading} title="下发日志详情" info={baseInfo} dataSource={dataSource} />
  );
};

export default LogDetail;
