import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import moment from 'moment';
import { replaceSign } from 'src/utils/constants';
import { compact, isNumber } from 'lodash';
import { toDetail as purchaseOrderDetail } from '../../purchaseOrder/navigate';
import { toDetail as deliveryScheduleNoteDetail } from '../../deliveryScheduleNote/navigate';
import { TagList } from 'components';
import { getMaterialAttrs } from 'src/entity/material';
import lookup, { appEnum } from 'src/dict';
import type { DataResource, DetailPageProps } from '../interface';

const { SourceEnum } = appEnum.DeliveryEnum;

type History = DetailPageProps['history'];
export const baseInfoColumns = (dataSource: DataResource) => {
  return [
    { label: '编号', dataIndex: 'code' },
    {
      label: '来源',
      dataIndex: 'source',
      render: lookup('DeliveryMap.Source'),
    },
    {
      label: '状态',
      dataIndex: 'execStatus',
      render: lookup('DeliveryMap.execStatus'),
    },
    {
      label: '协同发货单号',
      dataIndex: 'shipmentNoteCode',
      render: (value: string) => value ?? replaceSign,
    },
    {
      label: '供应商',
      dataIndex: 'supplierName',
      render: (value: string) =>
        `${dataSource.supplierCode}/${dataSource.supplierName}` ?? replaceSign,
    },
    {
      label: '备注',
      dataIndex: 'remark',
      render: (value: string) => value ?? replaceSign,
    },
  ];
};
export const otherInfoColumns = [
  {
    label: '创建人',
    dataIndex: 'creator',
    render: (creator: any) => {
      return creator ? <AvatarDisplay {...creator} isShowTag={false} isUser /> : replaceSign;
    },
  },
  {
    label: '创建时间',
    dataIndex: 'createdAt',
    render: (value: number) => moment(value).format('YYYY-MM-DD HH:mm:ss') ?? replaceSign,
  },
  {
    label: '更新人',
    dataIndex: 'operator',
    render: (operator: any) => {
      return operator ? <AvatarDisplay {...operator} isShowTag={false} isUser /> : replaceSign;
    },
  },
  {
    label: '更新时间',
    dataIndex: 'updatedAt',
    render: (updatedAt: number) =>
      (updatedAt && moment(updatedAt).format('YYYY-MM-DD HH:mm:ss')) ?? replaceSign,
  },
];
export const materialInfoColumns = (dataSource: DataResource, history: History) => [
  {
    title: '行号',
    width: 80,
    dataIndex: 'seqNum',
  },
  {
    title: '物料编号',
    width: 150,
    dataIndex: 'materialCode',
    render: (_text: any, record: any) => record.material?.baseInfo?.code,
  },
  {
    title: '物料名称',
    width: 150,
    dataIndex: 'materialName',
    render: (_text: any, record: any) => record.material?.baseInfo?.name,
  },
  {
    title: '物料属性',
    width: 150,
    dataIndex: 'materialAttr',
    render: (_text: any, record: any) => <TagList dataSource={getMaterialAttrs(record.material)} />,
  },
  {
    title: '应收数',
    width: 120,
    dataIndex: 'planReceiveAmount',
    render: (value: any) => `${value?.amountDisplay} ${value?.unitName}`,
  },
  {
    title: '应收时间',
    width: 180,
    dataIndex: 'planReceiveTime',
    render: (value: number, record: any) => {
      if (!isNumber(value)) {
        return '-';
      }
      return moment(value).format('YYYY/MM/DD HH:mm:ss');
    },
  },
  {
    title: '计划入库数',
    width: 150,
    dataIndex: 'planStorageAmount',
    render: (value: any) => `${value?.amountDisplay} ${value?.unitName}`,
  },
  {
    title: '发出数',
    width: 120,
    dataIndex: 'deliverAmount',
    render: (value: any, record: any) => {
      if (dataSource.source == SourceEnum.ordinary) {
        return replaceSign;
      }
      return `${value?.amountDisplay} ${value?.unitName}`;
    },
  },
  {
    title: '接收数',
    width: 120,
    dataIndex: 'receiveAmount',
    render: (value: any) => `${value?.amountDisplay} ${value?.unitName}`,
  },
  {
    title: '采购订单编号',
    width: 150,
    dataIndex: 'purchaseOrderCode',
    render: (value: string, record: any) => {
      if (!value) {
        return '-';
      }
      return (
        <a
          onClick={() => {
            history.push(purchaseOrderDetail(record.purchaseOrderId));
          }}
        >
          {value}
        </a>
      );
    },
  },
  {
    title: '采购订单行号',
    width: 150,
    dataIndex: 'purchaseOrderItemSeqNum',
    render: (value: string) => value,
  },
  {
    title: '交货计划单编号',
    width: 150,
    dataIndex: 'deliveryScheduleNoteCode',
    render: (value: string, record: any) => {
      if (!value) {
        return '-';
      }
      return (
        <a
          onClick={() => {
            history.push(deliveryScheduleNoteDetail(record.deliveryScheduleNoteId));
          }}
        >
          {value}
        </a>
      );
    },
  },
  {
    title: '交货计划单行号',
    width: 150,
    dataIndex: 'deliveryScheduleNoteLineNo',
    render: (value: string) => value,
  },
];
