/**
 * 多行展开列表抽屉
 */

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, Drawer, Modal, Spin } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { BlTable, TagList } from 'src/components';
import { getMaterialAttrs } from 'src/entity/material';
import {
  fetchBomGetBomDevelop,
  FetchBomGetBomDevelopResponse,
  FetchBfcGetResponse,
} from 'src/api/ytt/med-domain/bom';
//
import FeedingControlTable from '../detail/feedingControlDetail/feedingControlTable';
import { toDetailBom } from '../navigation';

type BomDevelopDataSource = NonNullable<FetchBomGetBomDevelopResponse['data']>;

interface MultiDetailProps {
  bomId?: number;
  onCancel: () => void;
}

const MultiDetail = (props: MultiDetailProps) => {
  const { bomId, onCancel } = props;

  const [loading, setloading] = useState(true);
  const [dataSource, setDataSource] = useState<BomDevelopDataSource[]>();
  const [feedingControl, setFeedingControl] = useState<FetchBfcGetResponse['data']>();

  useEffect(() => {
    if (bomId) {
      fetchBomGetBomDevelop({ id: bomId })
        .then((res) => {
          res?.data && setDataSource([res?.data]);
        })
        .finally(() => {
          setloading(false);
        });
    }

    return () => {
      setDataSource([]);
      setloading(true);
    };
  }, [bomId]);

  const getColumns = () => {
    const baseColumns: ColumnProps<BomDevelopDataSource>[] = [
      {
        title: '层级',
        dataIndex: 'level',
        fixed: 'left',
        width: 200,
      },
      {
        title: '物料编号',
        dataIndex: ['material', 'baseInfo', 'code'],
        fixed: 'left',
        width: 150,
      },
      {
        title: '物料名称',
        dataIndex: ['material', 'baseInfo', 'name'],
        width: 150,
      },
      {
        title: '物料属性',
        dataIndex: 'attributes',
        width: 150,
        render: (text, record) => <TagList dataSource={getMaterialAttrs(record?.material)} />,
      },
      {
        title: '物料分类',
        dataIndex: ['material', 'category', 'name'],
        width: 150,
      },
      {
        title: '用量',
        dataIndex: 'amount',
        width: 150,
      },
      {
        title: '单位',
        dataIndex: 'unitName',
        width: 150,
      },
      {
        title: '损耗率(%)',
        dataIndex: 'lossRate',
        width: 150,
      },
      {
        title: '版本号',
        dataIndex: 'version',
        width: 150,
        render: (version, record) => {
          return (
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                record?.bomId && window.open(toDetailBom(record?.bomId));
              }}
            >
              {version}
            </Button>
          );
        },
      },
      // 一期不支持
      // {
      //   title: '投料设置',
      //   dataIndex: 'splitSopControlInput',
      //   width: 150,
      //   render: (splitSopControlInput, record) => {
      //     const { bomId, bomInputMaterialLineId } = record ?? {};

      //     return splitSopControlInput ? (
      //       <Button
      //         type="link"
      //         style={{ padding: 0 }}
      //         onClick={() => {
      //           if (bomId && bomInputMaterialLineId) {
      //             fetchBfcGet({ bomId, bomInputMaterialLineId }).then((res) => {
      //               res.data && setFeedingControl(res?.data);
      //             });
      //           }
      //         }}
      //       >
      //         查看
      //       </Button>
      //     ) : null;
      //   },
      // },
      {
        title: '行备注',
        dataIndex: 'remark',
        width: 200,
      },
      {
        title: '备注',
        dataIndex: 'bomRemark',
        width: 200,
      },
    ];

    return baseColumns;
  };

  return (
    <Drawer
      visible={!_.isNil(bomId) && !loading}
      title="物料清单展开"
      width={'70%'}
      footer={null}
      onClose={onCancel}
      destroyOnClose
    >
      <Spin spinning={loading}>
        <BlTable<BomDevelopDataSource>
          columns={getColumns()}
          dataSource={dataSource}
          rowKey="developCoordinate"
          expandable={{ childrenColumnName: 'childBomDevelops' }}
          scroll={{ y: 'max-content', x: 'max-content' }}
          pagination={false}
        />
      </Spin>
      <Modal
        width={'60%'}
        title="投料管控详情"
        visible={!_.isNil(feedingControl)}
        footer={null}
        onCancel={() => {
          setFeedingControl(undefined);
        }}
        destroyOnClose
      >
        <FeedingControlTable dataSource={feedingControl} />
      </Modal>
    </Drawer>
  );
};

export default MultiDetail;
