import React, { useState, useEffect } from 'react';
import {
  ZoomOutOutlined,
  ZoomInOutlined,
  RotateLeftOutlined,
  OneToOneOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
  BorderHorizontalOutlined,
} from '@ant-design/icons';
import './style.scss';
import { BlIcon } from 'src/components';
import authDict, { hasAuth } from 'src/utils/auth';
/**
 * @description 图片预览，包括放大缩小，旋转，下载
 * @param props
 * file 文件
 * style
 * minScale 最小百分比
 * maxScale 最大百分比
 * @returns
 */

interface Props {
  file: any;
  style?: any;
  minScale?: number;
  maxScale?: number;
  pageText?: string;
  toPrve?: () => void | Boolean;
  toNext?: () => void | Boolean;
}

const ImageView = (props: Props) => {
  const { file, style, minScale = 20, maxScale = 300, pageText, toPrve, toNext } = props;
  const [imageSource, setImageSource] = useState<any>('');
  const [rotato, setRotato] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(100);
  const img = React.createRef() as any;

  const getBase64 = (f: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(f);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const getImageSource = async (f: any) => {
    const source = await getBase64(f.originFileObj);

    setImageSource(source || '');
  };

  const handleZoom = (type: string, rate?: number) => {
    if ((type === 'in' && zoom <= minScale) || (type === 'out' && zoom >= maxScale)) {
      return;
    }
    if (typeof rate !== 'undefined') {
      setZoom(rate);
      return;
    }
    if (type === 'in') {
      setZoom(zoom - 10);
    } else if (type === 'out') {
      setZoom(zoom + 10);
    } else {
      setZoom(100);
    }
  };

  const handleRotate = (direction: string) => {
    if (direction === 'left') {
      setRotato(rotato - 90);
    } else if (direction === 'right') {
      setRotato(rotato + 90);
    } else {
      setRotato(0);
    }
  };

  const canView = (f: any) => {
    const viewType = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    return viewType.includes(f.type);
  };

  useEffect(() => {
    if (!canView(file)) {
      setImageSource(null);
      return;
    }
    if (file.url || file.fileUri) {
      setImageSource(file.url || file.fileUri);
      return;
    }
    getImageSource(file);
  }, [file]);

  useEffect(() => {
    const z = zoom / 100;

    img.current.style.transform = `scale3d(${z}, ${z}, 1) rotate3d(0, 0, 1, ${rotato}deg)`;
  }, [rotato, zoom]);

  console.log(file, imageSource);

  return (
    <div style={style} className="image-view-wrap">
      {imageSource !== null ? (
        <img
          ref={img}
          style={{
            height: '100%',
          }}
          src={imageSource}
          className="image-view"
        />
      ) : (
        <div style={{ color: '#fff' }}>
          <InfoCircleOutlined style={{ color: '#FAAD14' }} /> 此文件暂不支持预览
        </div>
      )}
      {imageSource !== null ? (
        <div className="image-actions">
          <div>
            <a onClick={() => toPrve?.()}>
              <LeftOutlined />
            </a>
            <span className="zoom-number">{pageText}</span>
            <a onClick={() => toNext?.()}>
              <RightOutlined disabled={!toNext} />
            </a>
          </div>
          <div className="zoom">
            <a onClick={() => handleZoom('in')}>
              <ZoomOutOutlined />
            </a>
            <span className="zoom-number">{zoom}%</span>
            <a onClick={() => handleZoom('out')}>
              <ZoomInOutlined />
            </a>
          </div>
          <a onClick={() => handleZoom('', 100)}>
            <OneToOneOutlined />
          </a>
          <a onClick={() => handleRotate('left')}>
            <RotateLeftOutlined />
          </a>
          {hasAuth(authDict.document_down_file) && (
            <a
              onClick={() => {
                const a = document.createElement('a');

                a.href = imageSource;
                a.download = file.name;
                a.click();
              }}
            >
              <BlIcon type="iconxiazai" />
            </a>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ImageView;
