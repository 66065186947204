import { useState } from 'react';
import _ from 'lodash';
import { StepEntityType } from 'src/dict/sop';
import { getById } from '../share/stepUtils';
import { StepOverviewList, StepRowData } from '../types';

const useStepSearch = () => {
  // 搜索结果: 可展示的步骤id数组
  const [searchResult, setSearchResult] = useState<number[]>([0]);

  /** 步骤、步骤组名称模糊搜索, 结果存放在 searchResult 里，同时分别返回所有匹配节点和祖先节点 */
  const searchSteps = (searchValue: string, dataSource: StepRowData[]) => {
    if (searchValue === '') {
      setSearchResult([0]);
      return null;
    }

    function stepMatch(ele: StepRowData) {
      return ele.name.includes(searchValue) || ele.code.includes(searchValue);
    }

    function getAncestors(ele: StepRowData) {
      const arr = [];
      let current: any = ele;

      while (current && current.parentId !== 0) {
        arr.push(current.parentId);
        current = getById(dataSource as StepOverviewList, current.parentId);
      }
      return arr;
    }

    // 递归遍历整棵树, 找出所有名称匹配的结点
    let toBeTraveled = dataSource.slice(0);
    let matched: number[] = [];
    let ancestors: number[] = [];

    while (!_.isEmpty(toBeTraveled)) {
      const current = toBeTraveled.shift();

      if (!_.has(current, 'name') || !_.has(current, 'code')) {
        continue;
      }
      if (stepMatch(current!)) {
        // 对于每个匹配结点, 将其自己和所有祖先添加到搜索结果
        matched = [...matched, current!.id];
        ancestors = [...ancestors, ...getAncestors(current!)];
      }
      toBeTraveled = toBeTraveled.concat(current!.children);
    }
    // 去重
    setSearchResult(_.uniq([...matched, ...ancestors]));
    return { matched, ancestors };
  };

  // 根据搜索结果筛选出展示的步骤树
  function getDisplaySteps(dataSource: StepRowData[]) {
    if (!dataSource || _.isEmpty(dataSource)) {
      return [];
    }
    // 展示全部
    const showAll = _.head(searchResult) === 0;
    const list = [];
    const rest = dataSource.slice(0);

    while (!_.isEmpty(rest)) {
      // 因为要修改children, 所以clone一下, 浅拷贝就行了
      const current = _.clone(rest.shift() as StepRowData);

      if (showAll || searchResult.includes(current.id)) {
        list.push(current);
        if (current.type === StepEntityType.stepGroup) {
          current.children = getDisplaySteps(current.children);
        }
      }
    }
    return list;
  }

  return { searchSteps, getDisplaySteps };
};

export default useStepSearch;
