import React from 'react';
import { Switch, Form, FormInstance, InputNumber, Select } from 'antd';
import { validatePositiveInteger } from 'src/page/knowledgeManagement/share/warehouseRules';
import { firstInFirstOutRules as firstInFirstOutRulesMapping } from 'src/dict/material/mappings';
import { SearchSelect } from 'src/components';
import { UsageStatus } from 'src/dict/common';
import { storageValidityUnitOptionsList } from '../../constant';

interface Props {
  form: FormInstance;
  setUpdateMark?: (param: number) => void;
}

export const warehousingStorageInfo = (props: Props) => {
  const { form, setUpdateMark } = props;

  return [
    {
      label: '默认仓库',
      name: 'warehouse',
      render: () => (
        <SearchSelect
          fetchType="warehouse"
          params={{ enableFlag: UsageStatus.enabled }}
          style={{ width: '100%' }}
          placeholder="请输入"
          labelInValue
          onChange={() => {
            form.resetFields(['location']);
          }}
        />
      ),
    },
    {
      dependencies: ['warehouse'],
      noStyle: true,
      render: (FormItemProps: any) => () =>
        (
          <Form.Item label={'默认仓位'} name={'location'} {...FormItemProps}>
            <SearchSelect
              fetchType="storageLocation"
              labelInValue
              disabled={form.getFieldValue('warehouse')?.value === undefined}
              params={{
                storageId: form.getFieldValue('warehouse')?.value,
                storageType: 0,
                enableFlag: UsageStatus.enabled,
                enableStorageLocation: UsageStatus.enabled,
              }}
            />
          </Form.Item>
        ),
    },
    {
      label: '存储有效期',
      render: () => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            name={'time'}
            style={{ marginBottom: 0, flexGrow: 1 }}
            rules={[{ validator: validatePositiveInteger() }]}
          >
            <InputNumber placeholder="请输入" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name={'timeUnit'}
            style={{ marginLeft: '12px', marginBottom: 0, flexBasis: 150 }}
          >
            <Select options={storageValidityUnitOptionsList} placeholder="请选择" labelInValue />
          </Form.Item>
        </div>
      ),
    },
    {
      label: '先进先出',
      name: 'fifoEnabled',
      valuePropName: 'checked',
      required: true,
      getValueFromEvent: (value: boolean) => {
        return Number(value);
      },
      render: () => <Switch />,
    },
    {
      dependencies: ['fifoEnabled'],
      noStyle: true,
      render: (FormItemProps: any) => () =>
        (
          <Form.Item
            label={'先进先出属性'}
            name={'fifoAttr'}
            hidden={!form.getFieldValue('fifoEnabled')}
            {...FormItemProps}
          >
            <Select options={firstInFirstOutRulesMapping} placeholder="请选择" />
          </Form.Item>
        ),
    },
  ];
};
