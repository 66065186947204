import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { LinkTooltip } from 'src/components/tooltip';
import { BcAttachmentDetail } from 'src/components/upload';
import lookup, { appEnum } from 'src/dict';
import { TargetType } from 'src/dict/customField';
import { replaceSign } from 'src/utils/constants';
//
import {
  CustomFieldComponentPropsMap,
  ICustomFieldItem,
  ICustomFieldsFnColumnProps,
} from '../interface';

interface IGetFieldDetailInfo {
  label?: string;
  format: (value: any, record?: any) => React.ReactNode;
}
export interface IDetailCustomFieldItemConfig {
  inTable?: boolean;
  columnProps?: ICustomFieldsFnColumnProps;
}

const getFieldComponentProp = (fieldType?: number, propsMap?: CustomFieldComponentPropsMap) => {
  if (_.isNil(propsMap) || _.isNil(fieldType)) return {};

  const props = propsMap.get(fieldType);

  if (_.isNil(props)) return {};

  if (!_.isObject(props)) return {};

  return props;
};

const { FieldType } = appEnum.CustomField;

export const getDetailCustomFieldItem = (
  dataSource: ICustomFieldItem,
  config: IDetailCustomFieldItemConfig = {},
  componentsProps?: CustomFieldComponentPropsMap,
) => {
  const { fieldType, fieldName, choiceValues, datetimeFormat = '', targetType } = dataSource;

  /** 列表页方法更新后，将删除 todo:zhiyong */
  const { inTable, columnProps } = config;

  // 字段组件可自定义的配置
  const componentProp = getFieldComponentProp(fieldType, componentsProps);

  /** 单行文本 */
  const getText = (): IGetFieldDetailInfo => {
    return {
      label: fieldName,
      format: (value) => (!_.isNil(value) ? value : replaceSign),
    };
  };
  /** 多行文本 */
  const getTextArea = (): IGetFieldDetailInfo => {
    return {
      label: fieldName,
      format: (value) => (!_.isNil(value) ? <p>{value}</p> : replaceSign),
    };
  };
  /** 单选 */
  const getSelect = (): IGetFieldDetailInfo => {
    const options = _.map(choiceValues, ({ choiceCode, choiceValue }) => ({
      label: choiceValue,
      value: choiceCode,
    }));

    return {
      label: fieldName,
      format: (value) => options.find((i) => value === i.value)?.label || replaceSign,
    };
  };
  /** 多选 */
  const getMulSelect = (): IGetFieldDetailInfo => {
    const options = _.map(choiceValues, ({ choiceCode, choiceValue }) => ({
      label: choiceValue,
      value: choiceCode,
    }));

    return {
      label: fieldName,
      format: (value) =>
        value?.length
          ? options
              .filter((i) => value?.includes(i.value))
              .map((i) => i.label)
              .join(',')
          : replaceSign,
    };
  };
  /** 布尔值 */
  const getBoolean = (): IGetFieldDetailInfo => {
    return {
      label: fieldName,
      format: (value) => (!_.isNil(value) ? lookup('yn', Number(value)) : replaceSign),
    };
  };
  /** 整数 */
  const getIntNumber = (): IGetFieldDetailInfo => {
    return {
      label: fieldName,
      format: (value) => (!_.isNil(value) ? value : replaceSign),
    };
  };
  /** 数值 */
  const getNumber = (): IGetFieldDetailInfo => {
    return {
      label: fieldName,
      format: (value) => (!_.isNil(value) ? value : replaceSign),
    };
  };
  /** 日期时间 */
  const getDate = (): IGetFieldDetailInfo => {
    return {
      label: fieldName,
      format: (value) => (!_.isNil(value) ? moment(value).format(datetimeFormat) : replaceSign),
    };
  };
  /** URL */
  const getUrl = (): IGetFieldDetailInfo => {
    return {
      label: fieldName,
      format: (value) => (!_.isNil(value) ? <a href={value}>{value}</a> : replaceSign),
    };
  };
  /** 引用字段 */
  const getReference = (): IGetFieldDetailInfo => {
    return {
      label: fieldName,
      format: (value) => (!_.isNil(value) ? value : replaceSign),
    };
  };
  /** 关联关系 */
  const getRelation = (): IGetFieldDetailInfo => {
    const getValue = (value: any) => {
      if (targetType === TargetType.multiChoice) {
        return _.map(value, 'mainProperty').join(',');
      }
      return value?.mainProperty;
    };
    return {
      label: fieldName,
      format: (value) => getValue(value) || replaceSign,
    };
  };

  /** 附件类型 */
  const getAppendix = (): IGetFieldDetailInfo => {
    const getValue = (value: number[]) => {
      if (typeof value[0] === 'object') return value?.map((v: any) => v?.id);
      return value;
    };
    return {
      label: fieldName,
      format: (value: any[] = [], record: any) => {
        if (_.isNil(value) || _.isEmpty(value)) {
          return '-';
        }

        if (inTable) {
          const result = value?.map((v) => decodeURIComponent(v?.mainProperty || '')).join('、');
          const to =
            typeof columnProps?.to === 'function' ? columnProps?.to(record) : columnProps?.to || '';
          if (!to) return result;
          return (
            <LinkTooltip
              to={to}
              text={result}
              auth={columnProps?.auth}
              width={columnProps?.width || 150}
            />
          );
        }

        return (
          <BcAttachmentDetail
            {...componentProp}
            // defaultFileList={value}
            fileIds={getValue(value)}
          />
        );
      },
    };
  };

  const fieldMapping = new Map<number, () => IGetFieldDetailInfo>([
    [FieldType.text, getText],
    [FieldType.textArea, getTextArea],
    [FieldType.select, getSelect],
    [FieldType.multiSelect, getMulSelect],
    [FieldType.boolean, getBoolean],
    [FieldType.number, getNumber],
    [FieldType.integer, getIntNumber],
    [FieldType.date, getDate],
    [FieldType.url, getUrl],
    [FieldType.reference, getReference],
    [FieldType.relation, getRelation],
    [FieldType.appendix, getAppendix],
  ]);

  if (_.isNil(fieldType)) return null;

  const getFiledFn = fieldMapping.get(fieldType);

  if (_.isNil(getFiledFn)) return null;

  return getFiledFn();
};
