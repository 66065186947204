import { CalendarType, FitRangeType } from '.';
import { mappingsFactory } from '../utils';

export const calendarTypeDisplayMap = mappingsFactory(
  ['固定班制', CalendarType.fixed],
  ['排班制', CalendarType.flex],
);

export const fitRangeTypeDisplayMap = mappingsFactory(
  ['用户', FitRangeType.users],
  ['部门', FitRangeType.departments],
  ['设备', FitRangeType.equipments],
  ['工作中心', FitRangeType.workCenters],
);
