import { CreatorData } from 'src/page/knowledgeManagement/calendarManagement/shift/interface';

export interface TableRowType {
  code: string;
  name: string;
  objectCategory: number;
  objectCode: string;
  objectId: string;
  objectName: string;
  status: number;
  createAt: number;
  updateAt: number;
  creator: CreatorData;
  modifier: CreatorData;
  id: number;
}

export const MATCH_METHOD_ENUM = {
  UNITED: 1,
  CONDITION: 2,
};

export const MATCH_METHOD_MAP = [
  { value: MATCH_METHOD_ENUM.UNITED, label: '统一匹配' },
  { value: MATCH_METHOD_ENUM.CONDITION, label: '按条件匹配' },
];

export const OBJECT_CATEGORY_ENUM = {
  DEFAULT_ALL: 0,
  CUSTOM: 1,
  PRESET_OBJECT: 2,
};

export const OBJECT_MAP = [
  { value: OBJECT_CATEGORY_ENUM.CUSTOM, label: '自定义对象' },
  { value: OBJECT_CATEGORY_ENUM.PRESET_OBJECT, label: '预置对象' },
];

export const OBJECT_CATEGORY_MAP = new Map([
  [OBJECT_CATEGORY_ENUM.DEFAULT_ALL, '默认全部'],
  [OBJECT_CATEGORY_ENUM.CUSTOM, '自定义对象'],
  [OBJECT_CATEGORY_ENUM.PRESET_OBJECT, '预置对象'],
]);

export interface templateNode {
  subTemplateId: number;
  templateId: number;
  templateCode: string;
  templateName: string;
}
