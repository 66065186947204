import React, { useState } from 'react';
import { message as Message, Modal, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { RecordListLayout } from 'layout';
import {
  fetchSalesOrderFinish,
  fetchSalesOrderFinishCheck,
  fetchSalesOrderList,
} from 'src/api/ytt/order-domain/salesOrder';
import { dataColumnsForDocuments, documentsFilterList } from '../components/columns';
import { BlIcon, showErrorListMessage, SingleOperationLogComponent, Tooltip } from 'src/components';
import { DOCUMENTS_FIELD_MAP } from '../constant';
import { SalesOrderListProps, SalesOrderFinishCheckFailDetails } from '../interface';
import SalesOrderDetail from '../detail/detail';
import { toCreate, toCreateInvoice_URL, toDetail, toEdit, toInvoiceList_URL } from '../navigation';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import _ from 'lodash';
import _Array from 'src/utils/dataTypes/array';
import _String from 'src/utils/dataTypes/string';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import { useDispatch } from 'react-redux';
import { Auth, OriginType, ShipmentStatus } from 'src/dict/sales';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';
import { appEnum } from 'src/dict';
import { PurchaseOrderdeliveryMode } from 'src/dict/purchase';

const SalesOrderDocumentsList = () => {
  const history = useHistory();
  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]);
  const [selectedRows, setSelectRows] = useState<any[]>([]);

  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [visibleDetail, setVisibleDetail] = useState(false);
  const [salesOrderId, setSalesOrderId] = useState<number>(0);
  const [checkVisible, setCheckVisible] = useState(false);
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录
  /**
   * 导入导出
   */
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  const errorColumns = [
    {
      title: '订单编号',
      dataIndex: 'code',
      width: 200,
      render: (code: string) => code || replaceSign,
    },
    {
      title: '失败原因',
      dataIndex: 'detail',
      width: 300,
      render: (detail: string) => detail || replaceSign,
    },
  ];
  /**
   * 结束操作
   */
  const handleEndOrder = async (
    ids: number[],
    overMessage: string,
    onSuccess: () => void,
    onFail?: () => void,
  ) => {
    try {
      const { message, data } = await fetchSalesOrderFinish({ ids });

      if (data) {
        if (_Array.isEmpty(data?.fail)) {
          Message.success(overMessage);
          onSuccess?.();
          return;
        }
        if (_Array.isEmpty(data?.success)) {
          onFail?.();
        } else if (!_Array.isEmpty(data?.fail)) {
          onSuccess?.();
        }

        showErrorListMessage({
          title: '结束出现失败',
          data: data?.fail ?? [],
          columns: errorColumns,
          operateName: '结束',
          successCount: data?.success?.length,
          failCount: data?.fail?.length,
          width: 800,
        });

        return;
      }
      Message.error(message);
    } catch (error) {
      onFail?.();
      console.log(error);
    }
  };
  /**
   * 结束操作 - 确认
   */
  const handleEndOrderConfirm = async (
    ids: number[],
    type: 'single' | 'multiple',
    onSuccess?: () => void,
    onFail?: () => void,
  ) => {
    let modalConfig = {
      content: <></>,
      message: '',
    };
    const { failCount = 0, successCount = 0, failDetails } = (await checkFn()) || {};
    const orderCodeList = _.map(failDetails, 'code') || [];

    async function checkFn() {
      const { data } = await fetchSalesOrderFinishCheck({ ids });

      return data;
    }
    function renderContent(
      data: (string | undefined)[] | SalesOrderFinishCheckFailDetails[],
      type: 'success' | 'multipleAbnormal' | 'singleAbnormal',
    ) {
      const modalConfig = {
        content: <></>,
        message: '',
      };

      switch (type) {
        case 'singleAbnormal':
          {
            const line = (data as SalesOrderFinishCheckFailDetails[])?.map((item) => {
              return `${item?.lineNo}行的<${item?.materialCode}>`;
            });
            const code = (data as SalesOrderFinishCheckFailDetails[])?.[0]?.code;

            modalConfig.content = (
              <div>{`${line?.join('，')}还未完成，确定结束<${code}>订单吗?`}</div>
            );
            modalConfig.message = '销售订单异常结束';
          }

          break;

        case 'multipleAbnormal':
          modalConfig.content = (
            <>
              <div>以下订单内存在未完成的明细行，确定要结束销售订单嘛?</div>
              <div style={{ color: '#02B980' }}>{data?.join(',')}</div>
            </>
          );
          modalConfig.message = '部分销售订单异常结束';
          break;
        default:
          modalConfig.content = (
            <>
              <div>结束销售订单后，将不再进行任何操作，确定结束以下订单吗?</div>
              <div style={{ color: '#02B980' }}>{data?.join('，')}</div>
            </>
          );
          modalConfig.message = '销售订单结束成功';
          break;
      }
      return modalConfig;
    }

    switch (type) {
      case 'multiple':
        if (failCount === 0 && successCount === ids?.length) {
          modalConfig = renderContent(orderCodeList, 'success');
        } else {
          // 有异常状态的订单（发货状态=待发货/部分发货，生产状态=待计划/部分计划/已计划 ）
          modalConfig = renderContent(orderCodeList, 'multipleAbnormal');
        }
        break;

      default:
        if (failCount === 0 && successCount === ids?.length) {
          modalConfig = renderContent(orderCodeList, 'success');
        } else {
          modalConfig = renderContent(failDetails!, 'singleAbnormal');
        }
        break;
    }

    Modal.confirm({
      title: '结束销售订单',
      content: <div>{modalConfig.content}</div>,
      visible: checkVisible,
      onOk() {
        handleEndOrder(
          ids,
          modalConfig.message,
          () => {
            onSuccess?.();
          },
          () => {
            onFail?.();
          },
        );
      },
      onCancel() {
        onFail?.();
        setCheckVisible(false);
      },
      okText: '结束',
      cancelText: '暂不结束',
    });
  };
  /**
   * 删除操作
   */
  // const handleDelete = async (ids: number[], onSuccess?: () => void, onFail?: () => void) => {
  //   try {
  //     const value = await delForm?.validateFields();
  //     const { message, data } = await fetchSalesOrderDelete({ ids });

  //     if (data) {
  //       if (_Array.isEmpty(data?.fail)) {
  //         Message.success('删除成功');
  //         onSuccess?.();
  //         return;
  //       }
  //       if (_Array.isEmpty(data?.success)) {
  //         onFail?.();
  //         // 全部删除失败
  //       } else if (!_Array.isEmpty(data?.fail)) {
  //         onSuccess?.();
  //         // 有失败的删除 需要展示失败信息
  //       }

  //       showErrorListMessage({
  //         title: '删除出现失败',
  //         data: data?.fail ?? [],
  //         columns: errorColumns,
  //         operateName: '删除',
  //         successCount: data?.success?.length,
  //         failCount: data?.fail?.length,
  //         width: 800,
  //       });

  //       return;
  //     }
  //     Message.error(message);
  //   } catch (error) {
  //     onFail?.();
  //     console.log(error);
  //   }
  // };

  /**
   * 新建发货单
   */
  const handleCreateInvoice = (
    data: SalesOrderListProps[],
    type?: 'single' | 'multiple',
    onSuccess?: () => void,
    onFail?: () => void,
  ) => {
    const jumpToCreateInvoice = (data: SalesOrderListProps[]) => {
      dispatch({
        type: 'supplyChain/formatterInvoiceInfo',
        payload: { invoiceType: data[0]?.header?.origin, data },
      });
      history.push(toCreateInvoice_URL());
    };
    const handleNext = () => {
      if (_.findIndex(data, (o) => o.header?.shipmentStatus === ShipmentStatus.allShipped) > -1) {
        message.error('只有待发货/部分发货的销售订单可以新建发货单');
        onFail?.();
      } else if (
        _.findIndex(
          data,
          (o) => o.header?.deliveryMode === PurchaseOrderdeliveryMode.deliverySchedule,
        ) > -1
      ) {
        message.error('交货方式为「按交货计划」不支持新建发货单，请重新选择');
        onFail?.();
      } else {
        jumpToCreateInvoice(data);
      }
    };
    const config = {
      title: '提示',
      content: '物料已全部计划发货，发货计划不得超过需求数量，可以到发货单内执行其他操作',
      width: 436,
      onOk: () => {
        history.push(toInvoiceList_URL());
      },
      okText: '查看发货单',
      onCancel: () => {},
      cancelText: '关闭',
    };

    switch (type) {
      case 'multiple': {
        // 确认来源是否一致
        const headerList = _.map(data, 'header');
        const originList = _.map(headerList, 'origin');

        if (_.uniq(originList).length === 1) {
          handleNext();
        } else {
          message.error('只有相同来源的销售订单可以新建发货单');
          onFail?.();
        }

        break;
      }

      default: {
        const shipmentStatus = data[0]?.header?.shipmentStatus;

        if (shipmentStatus === ShipmentStatus.allShipped) {
          // 弹窗提示
          Modal.confirm(config);
        } else {
          // 发货状态=待发货/部分发货时，带物料编号、物料名称等信息新页面打开新建发货单页面；
          jumpToCreateInvoice(data);
        }

        break;
      }
    }
  };
  /**
   * 主操作按钮
   */
  const left = _.compact([
    {
      title: '查看协同采购申请单',
      icon: <BlIcon type="iconcaozuojilu" />,
      auth: authDict.purchaseauditnote_view,
      onClick: () => {
        window.open('/sales/salesManagement/purchaseRequisition');
      },
    },
    {
      title: '导出',
      showExport: true,
      icon: <BlIcon type="icondaochu" />,
      auth: authDict.salesorder_export,
      onClick: () =>
        openModal({
          optType: 'export',
          businessType: BUSINESS_TYPE.salesOrder,
        }),
    },
    {
      title: '新建销售订单',
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.salesorder_add,
      showExport: true,
      onClick: () => {
        history.push(toCreate());
      },
      items: [
        {
          title: '导入',
          showExport: true,
          auth: authDict.salesorder_import,
          onClick: () =>
            openModal({
              optType: 'import',
              businessType: BUSINESS_TYPE.salesOrder,
            }),
        },
      ],
    },
  ]);
  /**
   * 批量操作按钮
   */
  const toolbar = _.compact([
    // {
    //   title: '删除',
    //   onClick: (onSuccess: any, onFail: any) => {
    //     BLDelConfirm(
    //       '删除后将无法恢复，确认删除？',
    //       delForm,
    //       () => {
    //         handleDelete(selectedRowKeys, onSuccess, onFail);
    //       },
    //       () => {
    //         onFail();
    //       },
    //     );
    //   },
    // },
    {
      title: '批量新建发货单',
      auth: authDict.shipmentsnote_add,
      onClick: (onSuccess: any, onFail: any) => {
        handleCreateInvoice(selectedRows, 'multiple', onSuccess, onFail);
      },
    },
    {
      title: '结束',
      auth: authDict.salesorder_finish,
      onClick: (onSuccess: any, onFail: any) => {
        handleEndOrderConfirm(selectedRowKeys, 'multiple', onSuccess, onFail);
      },
    },
  ]);
  /**
   * 列操作 ...
   */
  const getOperationList = (record: SalesOrderListProps) => {
    return [
      {
        title: '查看',
        auth: authDict.salesorder_detail,
        onClick: () => {
          history.push(toDetail(record?.header?.id as number));
        },
      },
      {
        title: '编辑',
        auth: authDict.salesorder_edit,
        //  订单来源=普通订单时，订单状态=新建时支持编辑，其他状态暂不支持编辑；  订单来源=协同订单时，任何状态都不支持编辑
        disabled:
          Auth.supplyChain && record?.header?.origin !== null
            ? (record?.header?.origin === OriginType.ordinary &&
                record?.header?.status !== appEnum.Sales.OrderStatus.create) ||
              record?.header?.origin === OriginType.collaborate
            : record?.header?.status !== appEnum.Sales.OrderStatus.create,
        onClick: () => history.push(toEdit(record?.header?.id as number)),
      },
      {
        title: '新建发货单',
        auth: authDict.shipmentsnote_add,
        //  销售订单状态=全部发货时，不支持新建发货单;当交货方式 = 「按交货计划」时，不支持根据销售订单创建发货单
        disabled:
          record?.header?.shipmentStatus === ShipmentStatus.allShipped ||
          record?.header?.deliveryMode === PurchaseOrderdeliveryMode.deliverySchedule,
        onClick: () => handleCreateInvoice([record], 'single'),
      },
      {
        title: '结束',
        auth: authDict.salesorder_finish,
        onClick: () => handleEndOrderConfirm([record?.header?.id!], 'single', refresh, refresh),
      },
      {
        title: '操作记录',
        auth: authDict.salesorder_operrecord,
        onClick: () => {
          setSalesOrderId(record?.header?.id!);
          setVisibleLog(true);
        },
      },
    ];
  };
  /**
   * Columns
   */
  const dataColumns = [
    {
      title: '订单编号',
      dataIndex: ['header', 'code'],
      width: 200,
      sorter: true,
      render: (code: string, record: SalesOrderListProps, index: number, config: any) => {
        return (
          <>
            {hasAuth(authDict.salesorder_detail) ? (
              <a
                onClick={() => {
                  history.push(toDetail(record?.header?.id as number));
                }}
              >
                <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
              </a>
            ) : (
              <Tooltip text={code ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },
    ...dataColumnsForDocuments,
  ];
  /**
   * 传给接口数据处理函数
   */
  const formatDataToQuery = (params: any) => {
    const {
      sorter,
      createdAt,
      updatedAt,
      ownerIds,
      operatorIds,
      creatorIds,
      produceStatus,
      customerIds,
      code,
      status,
      origin,
      scmPurchaseOrderCode,
      shipmentStatus,
      ...rest
    } = params;

    const relParams = {
      ...rest,
    };

    if (createdAt) {
      relParams.createdAtFrom = Number(_Time.formatToUnix(createdAt[0]));
      relParams.createdAtTo = Number(_Time.formatToUnix(createdAt[1]));
    }
    if (updatedAt) {
      relParams.updatedAtFrom = Number(_Time.formatToUnix(updatedAt[0]));
      relParams.updatedAtTo = Number(_Time.formatToUnix(updatedAt[1]));
    }

    if (!_Array.isEmpty(ownerIds)) {
      relParams.ownerIds = _.map(ownerIds, 'value');
    }
    if (!_Array.isEmpty(status)) {
      relParams.status = status;
    }
    if (!_Array.isEmpty(creatorIds)) {
      relParams.creatorIds = _.map(creatorIds, 'value');
    }
    if (!_Array.isEmpty(operatorIds)) {
      relParams.operatorIds = _.map(operatorIds, 'value');
    }
    if (!_Array.isEmpty(produceStatus)) {
      relParams.produceStatus = produceStatus;
    }
    if (customerIds && !_Array.isEmpty(customerIds)) {
      relParams.customerIds = _.map(customerIds, 'value');
    }

    if (!_String.isEmpty(code)) {
      relParams.code = code;
    }
    if (origin) {
      relParams.origin = origin;
    }
    if (scmPurchaseOrderCode) {
      relParams.scmPurchaseOrderCode = scmPurchaseOrderCode;
    }
    if (shipmentStatus) {
      relParams.shipmentStatus = shipmentStatus;
    }

    if (sorter) {
      relParams.sorter = _.map(sorter, (node: any) => {
        return {
          order: node?.order,
          field: DOCUMENTS_FIELD_MAP.get(node?.field?.[1]),
        };
      });
    }

    dispatch.excelImport.updateBusinessData({ businessData: relParams });
    return relParams;
  };
  /**
   * 筛选数据显示处理函数
   */
  const formatDataToFormDisplay = (data: any) => {
    const { createdAtTo, updatedAtFrom } = data;

    const retData = { ...data };

    if (createdAtTo) {
      retData.createdAtTo = momentData(createdAtTo);
    }
    if (updatedAtFrom) {
      retData.updatedAtFrom = momentData(updatedAtFrom);
    }

    return retData;
  };
  /**
   * RecordListLayout 配置
   */
  const config = {
    rowKey: (record: SalesOrderListProps) => String(record?.header?.id),
    columns: dataColumns,
    getOperationList,
    mainMenu: left,
    batchMenu: toolbar,
    filterList: documentsFilterList, // 筛选列
    formatDataToQuery,
    formatDataToFormDisplay,
  };

  return (
    <>
      <RecordListLayout<SalesOrderListProps>
        userAuth={getAuthFromLocalStorage()}
        placeholder={'请输入订单编号、客户名称'}
        selectedRowKeys={selectedRowKeys}
        configcacheKey="salesOrderDocuments"
        onSelectedRowKeys={(selectKey: any, selectRows?: any[]) => {
          setSelectRowKeys(selectKey);
          setSelectRows(selectRows ?? []);
        }}
        requestFn={(params) => {
          return fetchSalesOrderList(params, { delayCallback: true, legacy: true });
        }}
        refreshMarker={refreshMarker}
        {...config}
      />
      {visibleDetail && (
        <SalesOrderDetail
          visible={visibleDetail}
          closeDetail={() => {
            setVisibleDetail(false);
          }}
          salesOrderId={salesOrderId}
        />
      )}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={salesOrderId}
          objectCode={OBJECT_OF_CODE.salesOrder}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default SalesOrderDocumentsList;
