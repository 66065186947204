import { DagreLayout, Model } from '@antv/layout';

const dagreLayout = new DagreLayout({
  type: 'dagre',
  rankdir: 'TB',
  align: 'UR',
  ranksep: 50,
  nodesep: 40,
  controlPoints: true,
});

export default dagreLayout;
