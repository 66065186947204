import { DetailLayout } from 'layout';
import { BlTable } from '@blacklake-web/component';
// import { BomResponseInputMaterial } from '../createAndEdit/index.type';
import lookup from 'src/dict';
import { gcArray } from 'src/utils';

interface FeedingControlDetailProps {
  dataSource?: any;
}

const FeedingControlDetail = (props: FeedingControlDetailProps) => {
  const { dataSource } = props;

  const renderFeedingControlTable = () => {
    const columns = [
      {
        title: '行号',
        dataIndex: 'lineSeq',
        fixed: 'left',
        width: 120,
      },
      {
        title: '用量:分子',
        dataIndex: 'inputAmountNumerator',
        width: 150,
      },
      {
        title: '用量:分母',
        dataIndex: 'inputAmountDenominator',
        width: 150,
      },
      {
        title: '投料控件编号',
        dataIndex: ['inputSopControlVO', 'code'],
        width: 200,
      },
      {
        title: '是否必投',
        dataIndex: 'inputMaterialControl',
        width: 100,
        render: (text: any) => lookup('yn', text),
      },
      {
        title: '投料上下限类型',
        dataIndex: 'inputBoundType',
        width: 200,
        render: (text: any) => lookup('bom.inputBoundType', text),
      },
      {
        title: '投料上限',
        dataIndex: 'inputUpperLimit',
        width: 150,
      },
      {
        title: '投料下限',
        dataIndex: 'inputLowerLimit',
        width: 150,
      },
      {
        title: '投料上限比例',
        dataIndex: 'inputUpperLimitRatio',
        width: 150,
      },
      {
        title: '投料下限比例',
        dataIndex: 'inputLowerLimitRatio',
        width: 150,
      },
      {
        title: '投料质量状态',
        dataIndex: 'inputQcState',
        width: 150,
        render: (text: any, record: any) => {
          const { inputQcState } = record;

          return !gcArray.isEmpty(inputQcState)
            ? inputQcState
                ?.map((item: any) => lookup('material.feedingQualityStatus', item))
                .join(',')
            : null;
        },
      },
      {
        title: '投料类型',
        dataIndex: 'feedType',
        width: 150,
        render: (text: any, record: any) => {
          const { feedType } = record;

          return lookup('material.feedingTypeMap', feedType);
        },
      },
    ];

    return (
      <BlTable
        scroll={{ x: 'max-content', y: 'auto' }}
        dataSource={dataSource?.bomFeedingControls}
        columns={columns}
        rowKey="id"
      />
    );
  };

  const getBaseInfo = () => {
    const baseInfo = [
      {
        title: '',
        items: [
          {
            label: '所属子项',
            dataIndex: 'seq',
          },
          {
            label: '父项物料编号',
            dataIndex: ['material', 'baseInfo', 'code'],
          },
          {
            label: '父项物料名称',
            dataIndex: ['material', 'baseInfo', 'name'],
          },
          {
            label: '用量：分子',
            dataIndex: 'inputAmountNumerator',
          },
          {
            label: '用量：分母',
            dataIndex: 'inputAmountDenominator',
          },
          {
            label: '单位',
            dataIndex: 'unitName',
          },
          {
            label: '投料工序号',
            isFullLine: true,
            dataIndex: 'inputProcessNum',
          },
          {
            label: '',
            dataIndex: 'placehold_3',
            isFullLine: true,
            render: () => renderFeedingControlTable(),
          },
        ],
      },
    ];

    return baseInfo;
  };

  return (
    <DetailLayout
      title="投料管控详情"
      info={getBaseInfo()}
      dataSource={dataSource}
      loading={false}
    />
  );
};

export default FeedingControlDetail;
