import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form, Input, message as Message } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import _ from 'lodash';

import {
  fetchSalesOrderReturnCreate,
  fetchSalesOrderReturnUpdate,
} from 'src/api/ytt/order-domain/salesReturnOrder';
import { NumberRulesStandardHook } from 'src/components';
import { lookup } from 'src/dict';
import { ReturnOrigin, ReturnStatus } from 'src/dict/salesReturnOrder';
import {
  numberAlphabetSpecialSymbols,
  numberChineseAlphabetSpecialSymbols,
  withoutSpaceBothSides,
} from 'src/utils/formValidators';
import { goToList } from '../navigates';
import { fieldLabels } from '../constants';
import { SalesReturnOrderDetailType, SalesReturnOrderAddItemType } from '../interface';
import { loadSalesReturnOrderDetailService } from '../services';
import { useShowOperateResultModal } from '../hooks';
import AddItemList from './AddItemList';

// 传给form的设置
// preserve: 在删除字段时保留字段值
// antd的form会在rerender的时候删除所有字段，如果该字段为false，使用form全程不可以rerender
const formProps = {
  preserve: true,
};

interface OperateSalesReturnOrderProps {
  id?: string;
}

const createInitData = {
  origin: ReturnOrigin.normal,
  returnStatus: ReturnStatus.new,
};

export default function SalesReturnOrderOperator(
  props: RouteComponentProps<OperateSalesReturnOrderProps>,
) {
  const [operateForm] = Form.useForm();
  const { match, history } = props;
  const orderId = Number(match.params.id);
  const lastPath = history.location.pathname.split('/').pop();
  const isCreating = lastPath?.startsWith('create');
  const dialogTitle = isCreating ? '新建销售退货单' : '编辑销售退货单';
  const { show: showOperateResultModal } = useShowOperateResultModal();

  if (!isCreating && _.isNil(orderId)) goToList();

  const [initialData, setInitialData] = useState<SalesReturnOrderDetailType>(createInitData);
  const [loading, setLoading] = useState(false);

  const baseInfo: DataFormLayoutInfoBlock = {
    items: _.compact([
      {
        name: 'id',
        hidden: true,
        render: () => <Input />,
      },
      ...NumberRulesStandardHook({
        label: fieldLabels.code,
        form: operateForm,
        edit: !isCreating,
        objectCode: 'SalesOrderReturn',
        fieldCode: 'code',
        rules: [
          numberAlphabetSpecialSymbols,
          withoutSpaceBothSides,
          { max: 64, message: '请限制在64个字符以内' },
        ],
      }),
      {
        label: fieldLabels.origin,
        // 可以新建和编辑的销售退货单默认为普通退货
        render: () => lookup('salesReturnOrder.returnOrigin', ReturnOrigin.normal),
      },
      {
        label: fieldLabels.remark,
        name: 'remark',
        isFullLine: true,
        rules: [
          withoutSpaceBothSides,
          numberChineseAlphabetSpecialSymbols,
          { max: 1000, message: '请限制在1000个字符以内' },
        ],
        render: () => <Input.TextArea maxLength={1000} showCount />,
      },
      !isCreating && {
        label: fieldLabels.modifyReason,
        name: 'editReason',
        isFullLine: true,
        rules: [withoutSpaceBothSides, { max: 1000, message: '请限制在1000个字符以内' }],
        render: () => <Input.TextArea maxLength={1000} showCount />,
      },
      {
        label: fieldLabels.info,
        name: 'items',
        isFullLine: true,
        rules: [{ required: true, message: `${fieldLabels.info}必填` }],
        render: () => <AddItemList form={operateForm} isCreating={isCreating ?? false} />,
      },
    ]),
  };

  const handleFinish = async () => {
    try {
      await operateForm.validateFields();

      const formData = operateForm.getFieldsValue();
      const { items, ...rest } = formData;
      const requestParam = {
        ...rest,
        items: items.map((item: SalesReturnOrderAddItemType) => ({
          lineNo: String(item?.lineNo),
          materialId: item?.material?.baseInfo?.id,
          returnAmount: Number(item?.amount?.amount),
          salesOrderId: item?.salesOrderId,
          salesOrderItemId: item?.salesOrderItemId,
          unitId: item?.amount?.unitId,
          id: item?.id,
        })),
      };

      let response;

      setLoading(true);
      if (isCreating) {
        response = await fetchSalesOrderReturnCreate(requestParam);
      } else {
        response = await fetchSalesOrderReturnUpdate(requestParam);
      }
      setLoading(false);
      if (response && response.code === 200) {
        const { data } = response;

        showOperateResultModal(isCreating!, data?.code!, data?.id!);
      } else {
        Message.error(response.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchSalesReturnOrderDetail = async () => {
    setLoading(true);
    const detailData = await loadSalesReturnOrderDetailService(orderId!);

    if (detailData) setInitialData(detailData);
    setLoading(false);
  };

  useEffect(() => {
    if (!isCreating) {
      fetchSalesReturnOrderDetail();
    }
  }, []);

  useEffect(() => {
    operateForm.resetFields();
  }, [initialData]);

  return (
    <DataFormLayout
      form={operateForm}
      info={[baseInfo]}
      loading={loading}
      title={dialogTitle}
      onCancel={goToList}
      onFinish={handleFinish}
      infoBlockStyleProps={{ paddingBottom: 0, borderBottom: 'none' }}
      formProps={{ ...formProps, initialValues: initialData }}
    />
  );
}
