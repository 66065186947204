import { useEffect, useState } from 'react';
import { Form, Input, message, DatePicker, Checkbox, Select } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import _, { get, isEmpty } from 'lodash';
import { UsageStatus } from 'src/dict/common';
import { SearchSelect, NumberRulesStandardHook } from 'components';
import OutBoundMaterial from '../components/outBoundMaterial';
import { appDict, appEnum } from 'src/dict';
import {
  fetchOutboundOrderCreate,
  fetchOutboundOrderDetail,
  fetchOutboundOrderUpdate,
} from 'src/api/ytt/inventory-domain/boundOrder';
import moment from 'moment';
import { checkTwoSidesTrim, validateBlInputText } from 'src/utils/formValidators';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'src/store/models';
import qs from 'qs';
import { SOURCE_TYPE } from 'src/dict/warehouse';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import {
  formatCustomFieldsInData,
  initCustomFieldsInData,
  injectCustomFieldInfos,
  useCustomFieldCombinedData,
} from 'src/components/customField';

interface Props extends RouteComponentProps {}
interface Params {
  id?: number;
}

const { StatusEnum } = appEnum.Bound;
const now = moment();

export default function OutBoundOrderCreate(props: Props) {
  const [form] = Form.useForm();
  const { history, match } = props;
  const params: Params = match.params;
  const { search } = useLocation();
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  const id = Number(params.id);
  const isEditMode = 'id' in params;
  const pageTitle = isEditMode ? '编辑' : '创建';
  const [keepOn, setKeepOn] = useState(false);
  const [detail, setDetail] = useState<any>(null);
  const [refreshMarker, setRefreshMaker] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const customFields = useCustomFieldCombinedData(OBJECT_OF_CODE.outboundOrder);

  /**
   * 判断在编辑模式下，某些来源类型是否可控制某些字段不可编辑
   * @param sourceType 来源类型
   * @returns Boolean
   */
  const editDisabledBySourceType = (sourceType: SOURCE_TYPE) => {
    const disabledTypes = [SOURCE_TYPE.invoice, SOURCE_TYPE.purchaseReturnOrder];

    return isEditMode && disabledTypes.includes(sourceType);
  };

  const editDisabledToCustomer = () => {
    if (isEditMode) {
      return !(
        detail?.bizStatus === StatusEnum.xinjian && detail?.sourceType === SOURCE_TYPE.emptySource
      );
    }
    return !!query.source;
  };

  const disabledDate = (current: any) => {
    return current && current < now;
  };
  const { outBoundInfo } = useSelector((state: RootState) => state?.supplyChain, shallowEqual);

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,
    title: '基本信息',
    items: [
      ...NumberRulesStandardHook({
        label: '出库单编号',
        form,
        edit: isEditMode,
        objectCode: 'OutboundOrder',
        fieldCode: 'code',
        refreshMarker,
        rules: [
          { max: 255, message: '不超过255字符' },
          { validator: checkTwoSidesTrim },
          { validator: validateBlInputText('出库单编号') },
        ],
      }),
      {
        label: '业务类型:',
        name: 'bizType',
        validateFirst: true,
        rules: [{ required: true, message: '请选择业务类型' }],
        render: () => (
          <Select
            options={appDict.bound.OutBoundType}
            disabled={isEditMode || Boolean(query.source)}
            placeholder="请选择"
          />
        ),
      },
      {
        label: '仓库:',
        name: 'wareHouseId',
        rules: [{ required: true, message: '请选择仓库' }],
        render: () => (
          <SearchSelect
            labelInValue
            disabled={
              editDisabledBySourceType(detail?.sourceType) ||
              (detail && detail.bizStatus !== StatusEnum.xinjian)
            }
            params={{ enableFlag: UsageStatus.enabled }}
            fetchType="warehouse"
          />
        ),
      },
      {
        label: '客户:',
        name: 'customerId',
        render: () => (
          <SearchSelect
            labelInValue
            disabled={editDisabledToCustomer()}
            params={{ status: [UsageStatus.enabled] }}
            fetchType="customer"
          />
        ),
      },
      {
        label: '计划出库时间:',
        name: 'planTime',
        render: () => (
          <DatePicker
            style={{ width: '100%' }}
            showTime
            format="YYYY/MM/DD HH:mm"
            disabled={editDisabledBySourceType(detail?.sourceType)}
            disabledDate={disabledDate}
          />
        ),
      },
      {
        label: '来源类型:',
        name: 'sourceType',
        render: () => <Select options={appDict.warehouse.sourceTypeEnum} disabled />,
      },
      {
        label: '备注:',
        name: 'remark',
        rules: [{ max: 1000, message: '不超过1000个字符' }],
        render: () => <Input.TextArea placeholder="请输入" />,
      },
    ],
  };
  const materialInfo: DataFormLayoutInfoBlock = {
    title: '物料列表',
    items: [
      {
        label: '',
        isFullLine: true,
        render: () => (
          <OutBoundMaterial
            source={query.source}
            name="items"
            form={form}
            items={detail?.items}
            editDisabled={editDisabledBySourceType(detail?.sourceType) || !!query.source}
            customFields={customFields}
          />
        ),
      },
    ],
  };

  const getDetail = async () => {
    const { data } = await fetchOutboundOrderDetail({ id });

    setDetail(data);

    const afterFormatInitialValue = {
      ...data,
      wareHouseId: {
        label: data?.wareHouse?.name,
        value: data?.wareHouse?.id,
      },
      customerId: {
        label: data?.customer?.name,
        value: data?.customer?.id,
      },
      ...(data?.planTime ? { planTime: moment(data?.planTime) } : {}),
      items: data?.items?.map((row: any) => {
        const { material = {}, unit = {}, ...rest } = row;

        return {
          ...rest,
          materialId: {
            label: material.baseInfo?.code,
            value: JSON.stringify(material),
            key: JSON.stringify(material),
          },
          batchNo: {
            label: row.batchNo,
            value: row.batchNo,
          },
          unitId: {
            label: unit.name,
            value: unit.id,
          },
        };
      }),
    };

    const afterFormatCustomFieldsValue = initCustomFieldsInData(afterFormatInitialValue);

    form?.setFieldsValue(afterFormatCustomFieldsValue);
  };

  const formatItemsData = (items: any) => {
    const initItems = get(detail, 'items');

    if (isEmpty(items)) return [];

    let lastLineNo = Number(get(initItems, [initItems?.length - 1, 'lineNo'], 0));

    return items?.map((row: any) => {
      return {
        ...row,
        materialId: getMaterialInfo(row.materialId)?.baseInfo?.id,
        batchNo: row?.batchNo?.value,
        unitId: row.unitId?.value,
        storageLocationId: row.storageLocationId?.value,
        lineNo: typeof row?.lineNo === 'string' ? row?.lineNo : ++lastLineNo,
      };
    });
  };

  const onCancel = () => {
    history.goBack();
  };
  const getMaterialInfo = (material: any) => {
    const value = JSON.parse(material?.value ?? '{}');

    return value;
  };
  const onFinish = async () => {
    const values = await form.validateFields();
    const _options = {
      ..._.omit(values, ['code_isUseRules']),
      ...(values.planTime ? { planTime: +values.planTime } : {}),
      wareHouseId: values.wareHouseId?.value,
      customerId: values.customerId?.value,
      items: formatItemsData(values.items),
    };

    const options = formatCustomFieldsInData({ data: _options, customFields });

    setLoading(true);
    (isEditMode ? fetchOutboundOrderUpdate({ ...options, id }) : fetchOutboundOrderCreate(options))
      .then((res) => {
        if (res && res.code === 200) {
          message.success('操作成功');
          if (keepOn) {
            setRefreshMaker(Math.random());
            form.resetFields();
            return;
          }
          onCancel();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getDetail();
    } else {
      form?.setFieldsValue({
        sourceType: SOURCE_TYPE.emptySource,
      });
    }
    if (query.source) {
      form?.setFieldsValue(outBoundInfo);
    }
  }, [id, query.source]);

  return (
    <DataFormLayout
      form={form}
      confirmLoading={loading}
      title={`${pageTitle}出库单`}
      info={[
        baseInfo,
        injectCustomFieldInfos({
          customFields,
          type: 'form',
          formConfig: { form },
        }),
        materialInfo,
      ]}
      onCancel={onCancel}
      onFinish={onFinish}
      extra={
        !isEditMode && // 非编辑
        !query?.source && ( // 非 其他来源=>新建出库单
          <Checkbox
            onChange={() => {
              setKeepOn(!keepOn);
            }}
            defaultChecked={keepOn}
          >
            连续新建
          </Checkbox>
        )
      }
    />
  );
}
