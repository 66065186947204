/**
 * @page 布局新建/编辑/复制
 */
import { useEffect, useState } from 'react';
import { useParams, matchPath, generatePath } from 'react-router-dom';
import _ from 'lodash';
import { Checkbox, Form, Input, Radio, Button, message, Modal } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'src/layout';
import { BlIcon, SearchSelect } from 'src/components';
import lookup from 'src/dict';
import { yn as ynMappings } from 'src/dict/common/mappings';
import { terminal as terminalMappings } from 'src/dict/customLayout/mappings';
import { LayoutComponentType, PageType, Terminal } from 'src/dict/customLayout';
import { fetchCustomFieldGetListByObjectCode } from 'src/api/ytt/metadata-domain/custom_field';
import {
  fetchLayoutDetailForEdit,
  fetchLayoutCreate,
  fetchLayoutUpdate,
  fetchLayoutSupportTab,
  fetchLayoutDetailPer,
} from 'src/api/ytt/layout-domain';
import { checkTwoSidesTrim } from 'src/utils/formValidators';
import { useBlocker, qs } from 'src/utils';
import Editor from './editor';
import { isSystemField, isEditableField, getComponentInitialValue } from './share';
import { backToSettings } from '../../navigation';
import { parseLayoutTypes } from '../../utils';
import { pathPattens } from '../../constants';
import type {
  LayoutData,
  LayoutParsedData,
  Field,
  LayoutParsedComponent,
  SonObject,
} from '../../type';
import type { FetchLayoutDetailResponse } from 'src/api/ytt/layout-domain';
import { fetchStandardBizObjectCustomObjectGetSonObjectAndFieldById } from 'src/api/ytt/metadata-domain/standardBizObject';
import { FieldType } from 'src/dict/common';
import authDict, { hasAuth } from 'src/utils/auth';
import { name2label, IdName, getCopiedName } from 'src/utils/formatters';
import * as colors from 'src/styles/color';
import styles from './styles.module.scss';

type PathParams = {
  objectId: string;
  layoutId?: string;
};

type LoadingComp = null | 'page' | 'save' | 'saveBack';

/**
 * 格式化编辑 "编辑/新增布局" Form初始值
 * 2022/08/30 改动：编辑 "编辑/新增布局" 必填字段需同步到右侧框
 * 新增components入参为新增布局时的component初始值
 * @param components 必填字段组件
 * @param data 编辑布局页接口返回值
 * @param isCopy
 * @param isEdit
 */
const parseContents = (
  components: LayoutParsedComponent[],
  data: LayoutData,
  isCopy = false,
  isEdit?: boolean,
) => {
  const detailComps = _.map(data.components, (item) =>
    _.omit({ ...item, content: JSON.parse(item.content!) }, 'seq'),
  );

  // 在编辑布局时需要拉取所有必填字段，新增的必填字段放置在最下面
  if (isEdit) {
    components.forEach((comp) => {
      const curCompIndex = _.findIndex(
        detailComps,
        ({ content }) => content?.fieldPath === comp?.content?.fieldPath,
      );

      if (curCompIndex < 0) {
        detailComps.push(comp);
      }
    });
  }

  return {
    ..._.omit(data, _.compact(['detailLayout', isCopy ? 'id' : null])),
    name: isCopy ? getCopiedName(data.name) : data.name,
    preset: isCopy ? 0 : data.preset, // 管理后台只能创建自定义布局，复制时改成0
    detailLayoutId: data?.detailLayout ? name2label(data.detailLayout as IdName) : undefined,
    components: detailComps,
  };
};

// 新建布局时，默认添加字段到右侧
const getInitialLayout = (
  objectCode: string,
  pageType: PageType,
  fieldList: Field[],
  sonObjectList: SonObject[],
): LayoutParsedData => {
  const basicInfo: Omit<LayoutParsedData, 'components'> = {
    type: pageType,
    objectCode,
    name: '',
    terminal: [Terminal.web, Terminal.app],
    defaultSup: 0,
    preset: 0,
  };
  let preAddedFields: Field[] = [];
  let preAddedSonObjects: SonObject[] = [];

  // 详情页 必须有主属性和创建/更新信息字段
  if (pageType === PageType.detail) {
    preAddedFields = fieldList.filter((f) => f.isName || isSystemField(f));
  }
  // 新建/编辑页 必须有所有的必填字段和从对象
  else if (pageType === PageType.edit) {
    basicInfo.detailLayoutId = undefined;
    preAddedFields = fieldList.filter((f) => f.isRequired && isEditableField(f));
    preAddedSonObjects = sonObjectList.filter((s) => s.childNecessary);
  }

  return {
    ...basicInfo,
    components: [
      ...preAddedFields.map((f) =>
        getComponentInitialValue(LayoutComponentType.field, pageType, {
          fieldCode: f.fieldCode,
          fieldList: preAddedFields,
        }),
      ),
      ...preAddedSonObjects.map((s) =>
        getComponentInitialValue(LayoutComponentType.sonObjectTable, pageType, {
          sonObjectCode: s.objectCode,
          sonObjectList: preAddedSonObjects,
        }),
      ),
    ],
  };
};

// 找出不在布局里的、可编辑的必填字段
const chechMissingRequiredFields = (fields: Field[], components: LayoutParsedComponent[]) => {
  const addedFieldCodes = components
    .filter((c) => c.type === LayoutComponentType.field)
    .map((c) => c.content.fieldPath);

  return fields.filter(
    (f) => isEditableField(f) && f.isRequired && !addedFieldCodes.includes(f.fieldCode),
  );
};

// 常用字段必须排在非常用字段之前
const getFieldBlocks = (components: LayoutParsedComponent[]) => {
  const blocks: any[] = [];
  const titlebars: any[] = [];
  const tabs: any[] = [];
  let currentBlockIndex = -1;

  components.forEach((comp) => {
    if (
      comp.type === LayoutComponentType.field ||
      comp.type === LayoutComponentType.sonObjectTable
    ) {
      if (_.isEmpty(blocks)) {
        blocks.push([]);
        currentBlockIndex = 0;
      }
      blocks[currentBlockIndex].push(comp);
    } else if (comp.type === LayoutComponentType.titlebar) {
      blocks.push([]);
      titlebars.push(comp);
      ++currentBlockIndex;
    } else {
      tabs.push(comp);
    }
  });
  return { blocks, titlebars, tabs };
};
const checkFrequentFirst = (blocks: LayoutParsedComponent[][]) => {
  for (const block of blocks) {
    for (let i = 0; i < block.length - 1; i++) {
      // 如果在非常用字段之后出现了常用字段，则报错
      if (!block[i].content.frequent && block[i + 1].content.frequent) {
        return false;
      }
    }
  }
  return true;
};
// 自动排版
const getAlignedComponents = (
  blocks: LayoutParsedComponent[][],
  titlebars: LayoutParsedComponent[],
  tabs: LayoutParsedComponent[],
) => {
  let aligned: LayoutParsedComponent[] = tabs.slice(0);

  if (blocks.length > titlebars.length) {
    (titlebars as any).unshift(null);
  }
  for (let i = 0; i < titlebars.length; i++) {
    aligned.push(titlebars[i]);
    aligned = aligned.concat(
      blocks[i].filter((f) => f.content.frequent),
      blocks[i].filter((f) => !f.content.frequent),
    );
  }
  return _.compact(aligned);
};

export const LayoutEdit = () => {
  const [loadingComp, setLoadingComp] = useState<LoadingComp>(null);
  const [fieldList, setFieldList] = useState<Field[]>([]);
  const [sonObjectList, setSonObjectList] = useState<SonObject[]>([]);
  const [supportTab, setSupportTab] = useState<boolean>(false);
  const { objectId, layoutId } = useParams<PathParams>();
  const [form] = Form.useForm();
  const { resetForm, history } = useBlocker(form);

  const isView = !!matchPath(location.pathname, { path: pathPattens.viewLayout });
  const isEdit = !!matchPath(location.pathname, { path: pathPattens.editLayout });
  const isCopy = !!matchPath(location.pathname, { path: pathPattens.copyLayout });
  const isCreate = !!matchPath(location.pathname, { path: pathPattens.createLayout });

  const { tabKey, objectCode, layoutTypes: layoutTypesStr } = qs.parse();
  const pageType = _.toNumber(tabKey);
  const layoutTypes = parseLayoutTypes(layoutTypesStr);

  const info: DataFormLayoutInfoBlock[] = [
    {
      title: '基本信息',
      items: _.compact([
        { name: 'type', hidden: true },
        { name: 'objectCode', hidden: true },
        isEdit ? { name: 'id', hidden: true } : null,
        {
          label: '布局名称',
          name: 'name',
          rules: [
            { required: true, message: '布局名称必填' },
            { type: 'string', max: 255, message: '不超过255个字符' },
            { validator: checkTwoSidesTrim },
          ],
          render: () => <Input placeholder="请输入" disabled={isView} />,
        },
        {
          label: '所属终端',
          name: 'terminal',
          rules: [{ required: true, message: '所属终端必选' }],
          render: () => <Checkbox.Group options={terminalMappings} disabled={isView} />,
        },
        {
          label: '是否默认',
          name: 'defaultSup',
          render: () => <Radio.Group options={ynMappings} disabled={isView} />,
        },
        {
          label: '是否预置',
          name: 'preset',
          render: () => <Radio.Group options={ynMappings} disabled />,
        },
        pageType === PageType.edit && layoutTypes.includes(PageType.detail)
          ? {
              label: '关联布局',
              name: 'detailLayoutId',
              render: () => (
                <SearchSelect
                  fetchType="customizedLayout"
                  placeholder="请选择要关联的详情页布局"
                  params={{ objectCode, type: PageType.detail }}
                  disabled={isView}
                />
              ),
            }
          : { name: 'detailLayoutId', hidden: true },
      ]),
    },
    {
      title: `${lookup('customLayout.pageType', pageType)}布局`,
      items: [
        {
          label: '',
          isFullLine: true,
          render: () => (
            <Editor
              form={form}
              pageType={pageType}
              fieldList={fieldList}
              sonObjectList={sonObjectList}
              supportTab={supportTab}
              disabled={isView}
            />
          ),
        },
      ],
    },
  ];

  const goBack = () => history.push(backToSettings(objectId));
  const getSaveFn = (btn: LoadingComp) => () => {
    form
      .validateFields()
      .then((values) => {
        // 对于新建/编辑页布局
        if (pageType === PageType.edit) {
          // 1. 检查必填字段是否都在布局里
          const missingList = chechMissingRequiredFields(fieldList, values.components);

          if (!_.isEmpty(missingList)) {
            Modal.confirm({
              title: '提示',
              width: 450,
              icon: <BlIcon type="icontixing-jingshi" style={{ color: colors.warning }} />,
              content: (
                <div>
                  <p>
                    当前布局缺失对象上的必填字段：{missingList.map((f) => f.fieldName).join('、')}
                    ，请将对应的字段拖到布局中。
                  </p>
                  <span style={{ fontSize: 12, color: colors.gray }}>
                    注意：如果字段设置错误，可进入到对象管理页面修改。
                  </span>
                </div>
              ),
              closable: false,
              okText: '知道了',
              className: styles['missing-required-fields-modal'],
              cancelText: '进入对象管理页面',
              cancelButtonProps: { type: 'link' },
              onCancel: () => {
                const search = new URLSearchParams(location.search);

                search.set('subject', 'field');
                window.open(`/systemManagement/bizObject/${objectId}/settings?${search}`);
              },
            });
            return;
          }
          // 2. 检查是否每个信息块中，常用字段都排在非常用字段之前
          const { blocks, titlebars, tabs } = getFieldBlocks(values.components);
          const allFrequentFirst = checkFrequentFirst(blocks);

          if (!allFrequentFirst) {
            Modal.confirm({
              title: '提示',
              icon: <BlIcon type="icontixing-jingshi" style={{ color: colors.warning }} />,
              content:
                '常用字段必须全部排在非常用字段之前，当前的排列不符合要求，是否为你一键排版？',
              okText: '一键排版',
              onOk() {
                form.setFieldsValue({
                  components: getAlignedComponents(blocks, titlebars, tabs),
                });
                message.success('自动排版完成，可以保存布局了。');
              },
              cancelText: '返回',
              closable: false,
            });
            return;
          }
        }

        // 处理defaultValue空值
        values?.components.forEach((com: any) => {
          if (typeof com.content.defaultValue === 'object' && _.isEmpty(com.content.defaultValue)) {
            delete com.content.defaultValue;
          }
        });
        // 将labeledValue提取出来
        const submitValues = {
          ...values,
          detailLayoutId: values.detailLayoutId?.value,
        };

        setLoadingComp(btn);
        (isEdit ? fetchLayoutUpdate(submitValues) : fetchLayoutCreate(submitValues))
          .then(() => {
            message.success('保存完成');
            resetForm(values);
            setLoadingComp(null);
            if (btn === 'saveBack' || !isEdit) {
              goBack();
            }
          })
          .catch(() => {
            setLoadingComp(null);
          });
      })
      .catch(({ errorFields }) => {
        console.error(errorFields);
        form.scrollToField(errorFields[0].name, { behavior: 'smooth' });
      });
  };

  const footer = (
    <div style={{ display: 'flex', justifyContent: 'center', gap: 16 }}>
      <Button type="default" onClick={goBack}>
        取消
      </Button>
      {isEdit && (
        <Button
          type="default"
          id={styles['save-back-btn']}
          onClick={getSaveFn('saveBack')}
          loading={loadingComp === 'saveBack'}
          disabled={loadingComp === 'save'}
        >
          保存并返回
        </Button>
      )}
      {isView && hasAuth(authDict.layout_add) && (
        <Button
          type="primary"
          onClick={() =>
            history.push(
              `${generatePath(pathPattens.copyLayout, { objectId, layoutId })}${location.search}`,
            )
          }
        >
          复制并新建
        </Button>
      )}
      {!isView && (
        <Button
          type="primary"
          onClick={getSaveFn('save')}
          loading={loadingComp === 'save'}
          disabled={loadingComp === 'saveBack'}
        >
          保存
        </Button>
      )}
    </div>
  );

  useEffect(() => {
    setLoadingComp('page');
    Promise.all([
      fetchCustomFieldGetListByObjectCode({ objectCode, fieldCategory: null, isUsed: [1] } as any),
      (() => {
        if (isCreate) {
          return {} as any;
        }
        if (isEdit) {
          return fetchLayoutDetailForEdit({ id: +layoutId! });
        }
        return fetchLayoutDetailPer({ id: +layoutId! });
      })(),
      fetchStandardBizObjectCustomObjectGetSonObjectAndFieldById({ objectCode }),
      fetchLayoutSupportTab({ objectCode }),
    ])
      .then((resList) => {
        const fieldList = resList[0].data!;
        const sonObjectList = resList[2].data!;

        setFieldList(fieldList.filter((item) => item.fieldType !== FieldType.subordinate));
        setSonObjectList(sonObjectList);
        setSupportTab(resList[3].data!);
        const { components, ...basicInfo } = getInitialLayout(
          objectCode,
          pageType,
          fieldList,
          sonObjectList,
        );

        if (isCreate) {
          resetForm({
            ...basicInfo,
            components,
          });
        } else {
          resetForm(
            parseContents(
              components,
              resList[1].data as Required<Required<FetchLayoutDetailResponse>['data']>,
              isCopy,
              isEdit,
            ),
          );
        }
      })
      .finally(() => setLoadingComp(null));
  }, [objectCode]);

  return (
    <DataFormLayout loading={loadingComp === 'page'} form={form} info={info} footer={footer} />
  );
};
