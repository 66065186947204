/**
 * @enum 协同交货计划 业务模块枚举值
 */

/**
 * 协同交货计划单状态
 */
export enum OrderStatus {
  /**
   * 新建
   */
  create = 10,
  /**
   * 待确认
   */
  pending = 20,

  /**
   * 已退回
   */
  returned = 30,
  /**
   * 执行中
   */
  inprogress = 40,
  /**
   * 已关闭
   */
  closed = 50,
  /**
   * 已结束
   */
  finished = 60,
}
/**
 * 计划发货状态
 */
export enum PlannedIssueStatus {
  /**
   * 待计划
   */
  toPlan = 1,
  /**
   * 部分计划
   */
  partPlan,
  /**
   * 已计划
   */
  planned,
}

/**
 * 发货单状态
 */
export enum InvoiceStatus {
  /**
   * 新建
   */
  create = 1,
  /**
   * 执行中
   */
  inprogress,
  /**
   * 结束
   */
  finish,
  /**
   * 关闭
   */
  close,
}
