import { Select, Input, Space, Form } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import _ from 'lodash';

import { lookup } from 'src/dict';
import { AutoFrequencyUnit } from 'src/dict/resources';
import { autoFrequencyUnit } from 'src/dict/resources/mappings';
import { numberMinMaxCheck, positiveInt } from 'src/utils/formValidators';
import { CollectionRulesListItemType } from '../../interface';
import Styles from './index.module.scss';

interface AutoFrequencyProps {
  defaultUnit?: AutoFrequencyUnit;
  defaultNumber?: number;
  form?: FormInstance;
}

export const autoFrequencyRender = (
  autoFrequency: CollectionRulesListItemType['autoFrequency'],
  { autoFrequencyUnitType }: CollectionRulesListItemType,
) => {
  return !_.isNil(autoFrequency) && !_.isNil(autoFrequencyUnitType) ? (
    <span>
      {autoFrequency}
      {lookup('resources.autoFrequencyUnit', autoFrequencyUnitType)}
    </span>
  ) : undefined;
};

export default function AutoFrequency({ defaultUnit, defaultNumber, form }: AutoFrequencyProps) {
  return (
    <Space className={Styles.autoFrequencyContainer}>
      <Form.Item
        name="autoFrequency"
        className={Styles.autoFrequencyInput}
        initialValue={defaultNumber}
        dependencies={[['autoFrequencyUnitType']]}
        rules={[
          form
            ? ({ getFieldValue }) => ({
                validator(_rule, value) {
                  const unitType = getFieldValue('autoFrequencyUnitType');
                  const minValue = unitType === AutoFrequencyUnit.minute ? 5 : 1;

                  return numberMinMaxCheck({
                    min: minValue,
                    max: 999,
                    isInteger: true,
                    fieldName: '数采频率',
                  })(_rule, value);
                },
              })
            : positiveInt,
        ]}
      >
        <Input allowClear placeholder="请输入时间间隔" />
      </Form.Item>
      <Form.Item
        name="autoFrequencyUnitType"
        className={Styles.autoFrequencySelect}
        initialValue={defaultUnit}
        getValueProps={(unitValue) => {
          return { value: unitValue ?? defaultUnit };
        }}
      >
        <Select placeholder="请选择单位" options={autoFrequencyUnit} />
      </Form.Item>
    </Space>
  );
}
