import FieldIdCascader from './FieldIdCascader';

import useStandardBizToCascaderOption, {
  CascaderOption as CascaderOptionProp,
} from './useStandardBizToCascaderOption';

export type CascaderOption = CascaderOptionProp;

export { useStandardBizToCascaderOption };

export default FieldIdCascader;
