import React from 'react';
import { DetailLayoutForDrawer } from 'layout';
import StoreDetailInfo from '../components/StoreDetailBaseInfo';

interface StoreDetailProps {
  visible: boolean;
  closeDetail: () => void;
  refreshTable: () => void;
  storeId: number;
}

const StoreDetail = (props: StoreDetailProps) => {
  const { visible, closeDetail, storeId, refreshTable } = props;

  return (
    <DetailLayoutForDrawer
      visible={visible}
      onClose={closeDetail}
      width={680}
      content={<StoreDetailInfo storeId={storeId} refreshTable={refreshTable} type="drawer" />}
    />
  );
};

export default StoreDetail;
