import React, { useState } from 'react';
import { RouterProps } from 'react-router-dom';
import _ from 'lodash';
import { BlTable } from '@blacklake-web/component';
import { Button } from 'antd';
import { ColumnProps } from 'antd/es/table';
import lookup from 'src/dict';
import { getMaterialAttrs } from 'src/entity/material';
//
import { BomResponse, BomResponseInputMaterial } from '../index.type';
import FeedingControlDetail from './feedingControlDetail';
import { DetailLayoutForModal } from 'src/layout';
import { TagList } from 'src/components';
import AlternativeDetail from './alternativeDetail';
import { ICustomFields, injectCustomFieldColumns } from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';

interface SubItemMaterialTabProps extends RouterProps {
  dataSource?: BomResponse;
  /**
   * 自定义字段
   */
  bomCustomFields?: ICustomFields;
}

const SubItemMaterialTab = (props: SubItemMaterialTabProps) => {
  const { dataSource, bomCustomFields, history } = props;

  const [feedingControlVisiableData, setFeedingControlVisiableData] =
    useState<BomResponseInputMaterial>();
  const [alternativeVisiableData, setAlternativeVisiableData] =
    useState<BomResponseInputMaterial>();

  const getColumns = (): ColumnProps<BomResponseInputMaterial>[] => {
    const baseColumns: ColumnProps<BomResponseInputMaterial>[] = [
      {
        title: '项次',
        dataIndex: 'seq',
        fixed: 'left',
        width: 160,
      },
      {
        title: '物料编号',
        dataIndex: ['material', 'baseInfo', 'code'],
        fixed: 'left',
        width: 200,
      },
      {
        title: '物料名称',
        dataIndex: ['material', 'baseInfo', 'name'],
        width: 150,
      },
      {
        title: '物料属性',
        dataIndex: 'attributes',
        width: 150,
        render: (text, record) => <TagList dataSource={getMaterialAttrs(record?.material)} />,
      },
      {
        title: '物料分类',
        dataIndex: ['material', 'category', 'name'],
        width: 150,
      },
      {
        title: '物料规格',
        dataIndex: ['material', 'baseInfo', 'specification'],
        width: 150,
      },
      {
        title: '用量:分子',
        dataIndex: 'inputAmountNumerator',
        width: 150,
      },
      {
        title: '用量:分母',
        dataIndex: 'inputAmountDenominator',
        width: 150,
      },
      {
        title: '单位',
        dataIndex: 'unitName',
        width: 150,
      },
      {
        title: '损耗率%',
        dataIndex: 'lossRate',
        width: 150,
      },
      {
        title: '版本号',
        dataIndex: 'version',
        width: 200,
      },
      {
        title: '领料方式',
        dataIndex: 'pickMode',
        width: 200,
        render: (text) => lookup('bom.pickMode', text),
      },
      {
        title: '指定投料工序',
        dataIndex: 'specificProcessInput',
        width: 200,
        render: (text) => lookup('yn', text),
      },
      {
        title: '投料工序号',
        dataIndex: ['inputProcessRouteNodeSimpleVO', 'processNum'],
        width: 200,
      },
      {
        title: '投料管控',
        dataIndex: 'bomFeedingControls',
        width: 120,
        render: (text, record) => {
          const { bomFeedingControls } = record;

          return !_.isEmpty(bomFeedingControls) ? (
            <Button
              type="link"
              onClick={() => {
                setFeedingControlVisiableData(record);
              }}
              style={{ padding: 0 }}
            >
              查看
            </Button>
          ) : null;
        },
      },
      {
        title: '拆分工序投料',
        dataIndex: 'splitProcessInput',
        width: 150,
        render: (text: any) => lookup('yn', text),
      },
      {
        title: '拆分控件投料',
        dataIndex: 'splitSopControlInput',
        width: 150,
        render: (text: any) => lookup('yn', text),
      },
      {
        title: '行备注',
        dataIndex: 'remark',
        width: 300,
      },
      {
        title: '替代方案',
        dataIndex: 'alternativePlanDetailVO',
        width: 120,
        render: (alternativePlanDetailVO, record) => {
          return !_.isEmpty(alternativePlanDetailVO) ? (
            <Button
              type="link"
              onClick={() => {
                setAlternativeVisiableData(record);
              }}
              style={{ padding: 0 }}
            >
              查看
            </Button>
          ) : null;
        },
      },
      {
        title: '',
        dataIndex: 'replaceHold',
        fixed: 'right',
        width: 20,
        render: () => <span />,
      },
    ];

    return baseColumns;
  };

  return (
    <div style={{ padding: 24 }}>
      <BlTable<BomResponseInputMaterial>
        dataSource={dataSource?.bomInputMaterials}
        columns={injectCustomFieldColumns({
          columns: getColumns(), // 原本的列
          customFields: bomCustomFields, // 自定义字段信息
          objectCode: OBJECT_OF_CODE.SubItemMaterialChild, // 从对象code
          type: 'detail', // 使用类型
        })}
        scroll={{ x: 'max-content' }}
        expandable={{ childrenColumnName: 'bomInputMaterialLines' }}
        rowKey="id"
        resizableCol
        useColConfig
        tableConfigKey={'SubItemMaterialTab'}
      />
      <DetailLayoutForModal
        visible={!_.isEmpty(feedingControlVisiableData)}
        onClose={() => {
          setFeedingControlVisiableData(undefined);
        }}
        content={<FeedingControlDetail dataSource={feedingControlVisiableData} />}
      />
      <DetailLayoutForModal
        visible={!_.isEmpty(alternativeVisiableData)}
        onClose={() => {
          setAlternativeVisiableData(undefined);
        }}
        content={<AlternativeDetail dataSource={alternativeVisiableData} history={history} />}
      />
    </div>
  );
};

export default SubItemMaterialTab;
