import React from 'react';
import { Input, Form, Button, Row, Space, Popconfirm } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { BcAttachmentDetail, BlIcon } from 'src/components';
import styles from './style.module.scss';
import { checkTwoSidesTrim } from 'src/utils/formValidators';
import { CheckItemGroup as Group } from 'src/page/quality/qcConfig/index.d';
import { LOGIC, getStandardValue } from 'src/page/quality/models/constants';
import { CheckCountTypeEnum, CheckItemInputTypeEnum, CheckTypeEnum } from 'src/dict/quality';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { DragSortingTable } from '../../DragComponents/dragTable';
import DraggableItem from '../../DragComponents/draggableItem';
import { CheckItem } from 'src/page/quality/models/dto';
import lookup, { appEnum } from 'src/dict';
import { formatCustomFieldsInData, injectCustomFieldColumns } from 'src/components/customField';
import { ICustomFields } from 'src/components/customField/interface';
import _ from 'lodash';
interface CheckItemGroupProps {
  form: any;
  groupKey: number;
  checkItemList: CheckItem[];
  deleteGroup: (groupKey: number) => void;
  openAddModal: (groupKey: number) => void;
  openEditModal: (groupKey: number, record: CheckItem) => void;
  delteCheckItem: (groupKey: number, list: CheckItem[]) => void;
  extendGroup: (groupKey: number) => void;
  judgeVisible: (groupKey: number) => boolean;
  deleteVisible: (groupKey: number) => void;
  changeCheckItemList: (groupKey: number, list: CheckItem[]) => void;
  moveGroup: (dragIndex: number, hoverIndex: number) => void;
  checkType: CheckTypeEnum;
  customFields: ICustomFields;
  openCopyModal: (groupKey: number, record: CheckItem) => void;
}

const CheckItemGroup = (props: CheckItemGroupProps) => {
  const {
    checkItemList,
    deleteGroup,
    openAddModal,
    openEditModal,
    delteCheckItem,
    extendGroup,
    judgeVisible,
    deleteVisible,
    changeCheckItemList,
    form,
    groupKey,
    moveGroup,
    checkType,
    customFields,
    openCopyModal,
  } = props;

  const validateGroupRepeat = (key: number, groups: Group[]) => {
    return (_rule: any, value: string) => {
      return new Promise((resolve, reject) => {
        const valGroups = groups.filter((i) => i.key !== key);

        if (
          valGroups?.length &&
          value &&
          valGroups.findIndex((group) => group.name === value) !== -1
        ) {
          return reject(`${value}存在重复`);
        }
        return resolve(true);
      });
    };
  };

  const getCheckCount = (values: any) => {
    const { checkCountType, checkCount, aqlDetail } = values;

    switch (checkCountType) {
      case CheckCountTypeEnum.ratioCheck:
        return `${checkCount}%`;
      case CheckCountTypeEnum.customCheck:
        return `${checkCount}`;
      case CheckCountTypeEnum.aqlCheck:
        return aqlDetail
          ? `${aqlDetail?.qcAqlInspectionLevelName}/${aqlDetail?.acceptLimitName}`
          : '-';
      case CheckCountTypeEnum.customAqlCheck:
        return aqlDetail
          ? `${aqlDetail?.qcAqlInspectionLevelName}/${aqlDetail?.acceptLimitName}`
          : '-';
      default:
        return '-';
    }
  };

  const renderTableArray = (list: string | string[] | number[]) => {
    if (list instanceof Array) {
      return list?.join(',') || '-';
    }
    return list || '-';
  };

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const dragRow = checkItemList[dragIndex];
    const newList = update(checkItemList, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragRow],
      ],
    });

    changeCheckItemList(groupKey, newList);
  };

  const scaleRender = (scale: number | null, executeItemType: CheckItemInputTypeEnum) => {
    if (
      executeItemType === CheckItemInputTypeEnum.inputFormatPercent ||
      executeItemType === CheckItemInputTypeEnum.inputFormatNum
    ) {
      return typeof scale === 'number' ? `${scale}位小数` : '-';
    }
    return '-';
  };

  const columns = _.compact([
    {
      title: '',
      width: 40,
      fixed: 'left',
      render: () => <BlIcon type="iconrenyituozhuai" />,
    },
    {
      title: '序号',
      width: 60,
      render: (_value: undefined, _record: CheckItem, index: number) => index,
    },
    {
      title: '排序',
      dataIndex: 'sortButton',
      width: 60,
      render: (_value: undefined, _record: CheckItem, index: number) => {
        return (
          <Space>
            <Button
              type="text"
              style={{ padding: 0 }}
              onClick={() => moveRow(index, index - 1)}
              disabled={index === 0}
            >
              <ArrowUpOutlined />
            </Button>
            <Button
              type="text"
              style={{ padding: 0 }}
              onClick={() => moveRow(index, index + 1)}
              disabled={checkItemList.length - 1 === index}
            >
              <ArrowDownOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: '检验项名称/编号',
      dataIndex: 'checkItem',
      width: 200,
      render: (checkItem: { label: string; value: number }) => checkItem?.label,
    },
    {
      title: '填写格式',
      dataIndex: 'executeItemType',
      width: 100,
      render: (value: CheckItemInputTypeEnum, record: CheckItem) => {
        if (
          value === CheckItemInputTypeEnum.inputFormatText ||
          value === CheckItemInputTypeEnum.multipleText
        ) {
          return lookup('quality.TextInputType', record.textInputType);
        }
        return lookup('quality.CheckItemInputType', value);
      },
    },
    checkType !== CheckTypeEnum.generalQc && {
      title: '抽检类型',
      dataIndex: 'checkCountType',
      width: 100,
      render: lookup('quality.CheckCountType'),
    },
    checkType !== CheckTypeEnum.generalQc && {
      title: '抽检数量',
      dataIndex: 'checkCount',
      width: 100,
      render: (checkCount: number, record: CheckItem) => getCheckCount(record),
    },
    {
      title: '标准值',
      dataIndex: 'logic',
      width: 100,
      render: (value: LOGIC, record: CheckItem) => getStandardValue(value, record) || '-',
    },
    {
      title: '精度',
      dataIndex: 'scale',
      width: 60,
      render: (scale: number, record: CheckItem) => scaleRender(scale, record.executeItemType),
    },
    {
      title: '全部选项',
      dataIndex: 'radios',
      width: 200,
      render: (text: string[]) => {
        return renderTableArray(text);
      },
    },
    {
      title: '合格选项',
      dataIndex: 'qualityItems',
      width: 200,
      render: renderTableArray,
    },
    {
      title: '是否必填',
      dataIndex: 'recordCheckItemType',
      width: 100,
      render: lookup('quality.recordCheckItemType'),
    },
    {
      title: '必填份数',
      dataIndex: 'requireReportCount',
    },
    {
      title: '总份数',
      dataIndex: 'totalReportCount',
    },
    {
      dataIndex: 'qcAqlInspectionLevel',
      title: '检验水平',
      width: 100,
      render: (value: any) => value?.name || value?.label,
    },
    {
      dataIndex: 'qcAqlCategory',
      title: '国标严格度',
      width: 100,
      render: (value: any) => value?.name || value?.label,
    },
    {
      dataIndex: 'qcAql',
      title: '接收质量限',
      width: 150,
      render: (value: any) => value?.name || value?.label,
    },
    {
      title: '默认值',
      dataIndex: 'defaultValue',
      width: 100,
      render: (defaultValue: any, record: CheckItem) => {
        const { defaultMin, defaultMax, executeItemType } = record;

        if (defaultMin || defaultMax) {
          return `${defaultMin || '-'} ~ ${defaultMax || '-'}`;
        }
        if (executeItemType === CheckItemInputTypeEnum.inputFormatMultiple) {
          return renderTableArray(defaultValue);
        }
        return defaultValue;
      },
    },
    {
      title: '不良原因',
      dataIndex: 'defectReasons',
      width: 100,
      render: (defectReasons: { label: string; value: number }[]) =>
        defectReasons?.map((reason) => reason.label).join(','),
    },
    {
      title: '不良等级',
      dataIndex: 'defectRanks',
      width: 100,
      render: (defectRanks: { label: string; value: number }[]) =>
        defectRanks?.map((rank) => rank.label).join(','),
    },
    {
      title: '检验设备',
      dataIndex: 'equipments',
      width: 100,
      render: (equipments: { label: string }[]) =>
        equipments?.map((equipment) => equipment.label).join(','),
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 100,
    },
    {
      title: '附加结论',
      dataIndex: 'extraResults',
      width: 100,
      render: (extraResults: number[] | null) => {
        if (extraResults?.length) {
          return extraResults?.map((result) => lookup('quality.ExtraResult', result)).join(',');
        }
        return '-';
      },
    },
    {
      title: '文件',
      dataIndex: 'attachmentIds',
      width: 200,
      render: (attachmentIds: number[]) => {
        if (attachmentIds?.length) {
          return <BcAttachmentDetail fileIds={attachmentIds} />;
        }
        return '-';
      },
    },
    {
      title: '执行时可填写备注',
      dataIndex: 'executeRemark',
      width: 150,
      render: (executeRemark: boolean) => lookup('common.yn', Number(executeRemark)),
    },
    {
      title: '执行时可上传图片',
      dataIndex: 'executeUpload',
      width: 150,
      render: (executeUpload: boolean) => lookup('common.yn', Number(executeUpload)),
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 200,
      fixed: 'right',
      render: (text: string, record: CheckItem, index: number) => (
        <Space>
          <Button
            type="link"
            onClick={() => {
              openEditModal(groupKey, {
                ...record,
                customFields: checkItemList[index]?.customFields,
              });
            }}
            style={{ padding: 4 }}
          >
            {'编辑'}
          </Button>
          <Button
            type="link"
            onClick={() => {
              const copyData = _.cloneDeep(checkItemList[index]);

              delete copyData.key;
              openCopyModal(groupKey, copyData);
            }}
            style={{ padding: 4 }}
          >
            {'复制'}
          </Button>
          <Button
            type="link"
            onClick={() => delteCheckItem(groupKey, [record])}
            style={{ padding: 4 }}
          >
            {'删除'}
          </Button>
        </Space>
      ),
    },
  ]);

  const validateRequired = (list: any[]) => {
    return () => {
      return new Promise((resolve, reject) => {
        if (!list || !list?.length) {
          return reject('检验分组至少添加一个检验项');
        }
        return resolve(true);
      });
    };
  };

  const formatCheckItemCustomFieldData = (data: CheckItem[]) => {
    const newFormatData = _.cloneDeep(data);

    return _.map(newFormatData, (item) => {
      const formatCustomField = formatCustomFieldsInData({ data: item, customFields });

      return { ...data, ...formatCustomField };
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <DraggableItem id={groupKey} index={groupKey} moveCard={moveGroup}>
        <div key={groupKey} className={styles.groupContent}>
          <Row className={styles.groupTitleContent}>
            <BlIcon type="iconrenyituozhuai" />
            <Button
              type="text"
              onClick={() => {
                judgeVisible(groupKey) ? deleteVisible(groupKey) : extendGroup(groupKey);
              }}
              style={{ padding: 0 }}
            >
              {judgeVisible(groupKey) ? (
                <BlIcon type="iconxiang-you" />
              ) : (
                <BlIcon type="iconxiang-xia" />
              )}
            </Button>
            <Form.Item
              label="分组名称"
              name={['checkItemList', groupKey, 'name']}
              style={{ marginBottom: 10 }}
              rules={[
                { max: 255, message: '不可超过255个字符' },
                { required: true },
                { validator: checkTwoSidesTrim },
                {
                  validator: validateGroupRepeat(
                    groupKey,
                    form.getFieldValue('checkItemList') || [],
                  ),
                },
              ]}
            >
              <Input placeholder="请输入分组名称" />
            </Form.Item>
            <Popconfirm
              title={'删除分组将会删除其下所有检验项明细，确定要继续删除吗？'}
              onConfirm={() => deleteGroup(groupKey)}
            >
              <Button
                type="link"
                icon={<BlIcon type={'icona-lieshanchu2'} />}
                style={{ position: 'absolute', right: 20 }}
              />
            </Popconfirm>
          </Row>
          <Form.Item
            hidden={judgeVisible(groupKey)}
            name={['checkItemList', groupKey, 'list']}
            style={{ width: '100%' }}
            rules={[
              {
                validator: validateRequired(checkItemList),
              },
            ]}
          >
            <DragSortingTable
              dataSource={formatCheckItemCustomFieldData(checkItemList)}
              columns={injectCustomFieldColumns({
                columns, // 原本的列
                customFields, // 自定义字段信息
                objectCode: appEnum.Common.ObjectCode.qcCheckItem, // 从对象code
                type: 'detail', // 使用类型
              })}
              moveRow={moveRow}
              rowKey={(data) => data.checkItem.value}
              index={groupKey}
              handleDelete={(rows) => delteCheckItem(groupKey, rows)}
              footer={() => (
                <Button
                  type="dashed"
                  className={styles.tableAddButton}
                  icon={<BlIcon type="iconxinjiantianjia" />}
                  onClick={() => {
                    openAddModal(groupKey);
                  }}
                >
                  {'添加检验项'}
                </Button>
              )}
            />
          </Form.Item>
        </div>
      </DraggableItem>
    </DndProvider>
  );
};

export default CheckItemGroup;
