/**
 * 页面信息区底部可拖拽添加控件的区域
 */
import { FC, useContext } from 'react';
import { useDrop } from 'react-dnd';
import { EditorContext, getComponentInitialValue } from '../../share';
import { DragItemType, DragItem } from '../../../../type';

const Empty: FC = () => {
  const { fieldList, pageType, form, forceUpdate } = useContext(EditorContext);

  const [{ isOver }, drop] = useDrop({
    accept: [DragItemType.cover],
    collect(monitor) {
      return { isOver: monitor.isOver() };
    },
    drop(item: DragItem) {
      form!.setFieldsValue({
        components: [
          ...form!.getFieldValue('components'),
          getComponentInitialValue(item.type, pageType, { fieldCode: item.key, fieldList }),
        ],
      });
      forceUpdate();
    },
  });

  return (
    <div
      ref={drop}
      style={{
        flex: '1 1',
        background: isOver ? 'rgba(0,0,0,.05)' : '#fafafa',
      }}
    />
  );
};

export default Empty;
