/**
 * @function 『启用中/停用中』列表项渲染方法
 */

import { Badge } from 'antd';
import lookup, { pickCode } from 'src/dict';
import { UsageStatus } from 'src/dict/common';

const usageStatusMap = new Map([
  [
    UsageStatus.enabled,
    <Badge text={lookup('usageStatus', UsageStatus.enabled)} status="success" />,
  ],
  [
    UsageStatus.disabled,
    <Badge text={lookup('usageStatus', UsageStatus.disabled)} status="error" />,
  ],
]);

export const renderUsageStatus = (enableFlag: UsageStatus) =>
  usageStatusMap.get(pickCode(enableFlag));

export const getUsageStatusActionLink = (
  key: string,
  enableFlag: UsageStatus,
  onClick: () => void,
) => {
  return (
    <a key={key} onClick={onClick}>
      {lookup('changeStatusAction', enableFlag)}
    </a>
  );
};

export default renderUsageStatus;
