import _ from 'lodash';
import { BlTable } from 'src/components';
import { lookup } from 'src/dict';
import { valueOrHolder } from 'src/utils/formatters';
import { DetailType } from '../index.d';

interface ParamsListProps {
  dataSource: NonNullable<DetailType>['resourceParamsList'];
}

// temp solution
const renderNumberCouldBeZero = (numberInput: number | null) =>
  valueOrHolder(!_.isNil(numberInput) ? `${numberInput}` : numberInput);

const EquipmentParamsTable = (props: ParamsListProps) => {
  const { dataSource } = props;

  return (
    <BlTable
      style={{ width: '100%' }}
      dataSource={dataSource}
      scroll={{ x: 'max-content', y: 300 }}
      rowKey="paramsId"
      columns={[
        {
          title: '设备参数',
          dataIndex: 'paramsName',
          width: 150,
        },
        {
          title: '参数类型',
          dataIndex: 'paramsType',
          width: 150,
          render: lookup('resources.paramType'),
        },
        {
          title: '参数单位',
          dataIndex: 'unitName',
          width: 150,
        },
        {
          title: '参数标准值',
          dataIndex: 'paramsStandard',
          width: 150,
          render: renderNumberCouldBeZero,
        },
        {
          title: '参数上限',
          dataIndex: 'paramsUpperLimit',
          width: 150,
          render: renderNumberCouldBeZero,
        },
        {
          title: '参数下限',
          dataIndex: 'paramsLowerLimit',
          width: 150,
          render: renderNumberCouldBeZero,
        },
        {
          title: '参数上上限',
          dataIndex: 'paramsTopLimit',
          width: 150,
          render: renderNumberCouldBeZero,
        },
        {
          title: '参数下下限',
          dataIndex: 'paramsFloorLimit',
          width: 150,
          render: renderNumberCouldBeZero,
        },
        {
          title: '参数读取API',
          dataIndex: 'paramsReadApi',
          width: 150,
        },
        {
          title: '参数下发API',
          dataIndex: 'paramsDownApi',
          width: 150,
        },
        {
          title: 'API自动获取参数值',
          dataIndex: 'paramsAutoFlag',
          render: (paramsAutoFlag) =>
            // eslint-disable-next-line no-nested-ternary
            valueOrHolder(_.isNil(paramsAutoFlag) ? undefined : paramsAutoFlag ? '是' : '否'),
          width: 180,
        },
        {
          title: '数采规则',
          dataIndex: 'schedulerJobName',
          width: 160,
        },
      ]}
    />
  );
};

export default EquipmentParamsTable;
