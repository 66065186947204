import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Space, Steps, Input, Radio, message as Message, Modal } from 'antd';
import Styles from '../styles.module.scss';
import FlowBuilder from 'src/components/flowBuilder';
import { throttleFn } from 'src/page/knowledgeManagement/warehouse/utils';
import {
  fetchApprovalPlanAdd,
  fetchApprovalPlanUpdate,
} from 'src/api/ytt/metadata-domain/approvalProcessScheme';
import { filter, initial, isUndefined, last, map, some } from 'lodash';
import { fetchApprovalPlanNodeSaveNodes } from 'src/api/ytt/metadata-domain/approvalNode';
import ConfirmModal from './confirmModal';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { textValidator1, textValidator2 } from 'src/utils/formValidators';
import { NumberRulesStandardHook, SearchSelect } from 'src/components';
import { appDict, appEnum } from 'src/dict';
import BizEventTrigger from 'src/page/organization/triggerRule/components/BizEventTrigger';
import RuleConditionEditTable from 'src/page/organization/triggerRule/components/RuleConditionEditTable';
import { toList } from '../navigation';
import { useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import _Array from 'src/utils/dataTypes/array';
import { TriggerType } from 'src/dict/triggerRule';
import {
  formatBizEventTrigger,
  formatConditionGroups,
} from 'src/page/organization/triggerRule/utils/formatDataToRequest';
import {
  getFieldValueOfForm,
  getValueFromStringfy,
} from 'src/page/organization/triggerRule/utils/tools';

const { Step } = Steps;

const { TextArea } = Input;

const { TriggerRule } = appEnum;

interface baseInfoProps {
  initialData?: any;
  edit: boolean;
  handleSetApprovalPlanId: (id: number) => void;
  approvalPlanId: number;
  referenceId: number;
  handleSetCurrent: (id: number) => void;
  current: number;
}

const CreateAndEditBaseInfo = (props: baseInfoProps) => {
  const {
    edit,
    initialData,
    handleSetApprovalPlanId,
    referenceId,
    approvalPlanId,
    current,
    handleSetCurrent,
  } = props;

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isChangeField, setIsChangeField] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [hiddenRuleObjectCode, setHiddenRuleObjectCode] = useState<boolean>(false);

  const hiddenRuleObjectName = ['电子单据']; // 预置对象审批方案 无需配置触发规则和触发条件

  const prefix = 'approval';

  useEffect(() => {
    if (current === 0 && (edit || approvalPlanId)) {
      // 只有在基本信息页面 才会在新建编辑的时候都可能不断的更新值 重置form
      form.setFieldsValue(initialData);

      setHiddenRuleObjectCode(hiddenRuleObjectName.includes(initialData?.objectCode?.label));
    }
    form.setFieldsValue({ triggerType: TriggerType.bizEventTrigger });
    setIsChangeField(false);
  }, [initialData]);

  const [form] = Form.useForm();

  const history = useHistory();

  const flowBuilderRef = useRef(null);

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 1,
    title: '基本信息',
    items: [
      ...NumberRulesStandardHook({
        label: '编号',
        form,
        edit,
        objectCode: 'ApprovalScheme',
        fieldCode: 'code',
        rules: [{ max: 64, message: '不可超过64字符' }, { validator: textValidator2 }],
      }),
      {
        label: '名称',
        name: 'name',
        rules: [
          { required: true, message: '名称不能为空' },
          { validator: textValidator1 },
          { max: 90, message: '不超过90个字符' },
        ],
        render: () => <Input placeholder="请输入名称" />,
      },
      {
        label: '审批对象',
        name: 'objectCode', // 实际后端字段是approvalObjectCode  为了兼容触发规则获取code的值改成了和触发规则一致的字段名
        rules: [{ required: true, message: '请选择审批对象' }],
        render: () => (
          <SearchSelect
            disabled={edit}
            fetchType={'approvalObject'}
            allowClear
            placeholder={'请选择审批对象'}
            onSelect={(_: string, option: any) => {
              const hiddenRuleObjectCode = hiddenRuleObjectName.includes(option?.label);

              setHiddenRuleObjectCode(hiddenRuleObjectCode);

              form.setFields([
                { name: ['triggerCondition', 'conditionGroups'], value: undefined },
                { name: ['bizEventTrigger', 'triggerEventType'], value: undefined },
              ]);
            }}
          />
        ),
      },
      {
        label: '编辑原因',
        name: 'updateReason',
        hidden: !edit,
        rules: [{ max: 1000, message: '不超过1000个字符' }, { validator: textValidator1 }],
        render: () => <TextArea placeholder="非必填" />,
      },
    ],
  };

  const configInfo: DataFormLayoutInfoBlock = {
    column: 1,
    title: '触发规则',
    items: [
      {
        isFullLine: true,
        render: (FormItemProps: any) => (
          <BizEventTrigger
            FormItemProps={FormItemProps}
            form={form}
            objectCode={getFieldValueOfForm(form, 'objectCode', 'objectCode')}
            moduleType="approval"
          />
        ),
      },
    ],
  };

  const triggerCondition: DataFormLayoutInfoBlock = {
    title: '触发条件',
    column: 1,
    items: [
      {
        label: '配置方式',
        name: 'triggerConditionType',
        required: true,
        initialValue: TriggerRule.TriggerConditionType.biz,
        render: () => <Radio.Group options={appDict.triggerRule.triggerConditionType} />,
      },
      {
        label: '',
        dependencies: ['triggerConditionType', 'objectCode'],
        isFullLine: true,
        render: () => () => {
          const objectCode = getFieldValueOfForm(form, 'objectCode', 'objectCode');

          const objectCategory = getFieldValueOfForm(form, 'objectCode', 'objectCategory');

          return (
            <RuleConditionEditTable
              form={form}
              namePath={['triggerCondition', 'conditionGroups']}
              triggerType={TriggerType.bizEventTrigger}
              code={objectCode}
              objectCategory={objectCategory}
              haveEditTriggerEventType={false} // 目前触发动作只有新建
            />
          );
        },
      },
    ],
  };

  const handleStepOneFinish = async () => {
    try {
      const value = await form?.validateFields();

      const hiddenRule = hiddenRuleObjectName.includes(value?.objectCode?.label);

      const { objectCode, triggerCondition, triggerConditionType, bizEventTrigger, ...rest } =
        value;

      if (!hiddenRule) {
        if (
          isUndefined(triggerCondition?.conditionGroups) ||
          _Array.isEmpty(triggerCondition?.conditionGroups)
        ) {
          // 没有配置触发条件
          return Message.error('至少需要一行触发条件');
        }
        rest.triggerRule = {
          ...formatBizEventTrigger(bizEventTrigger),
          triggerCondition: {
            type: triggerCondition.type,
            conditionGroups: formatConditionGroups(triggerCondition.conditionGroups),
          },
          triggerConditionType,
        };
      }

      const fetchFn = edit ? fetchApprovalPlanUpdate : fetchApprovalPlanAdd;

      setIsEdit(fetchFn === fetchApprovalPlanUpdate);

      const relValue = approvalPlanId
        ? {
            ...rest,
            approvalObjectCode: getValueFromStringfy(objectCode, 'objectCode'),
            id: approvalPlanId,
            referenceId: referenceId ?? initialData?.referenceId,
          }
        : {
            ...rest,
            approvalObjectCode: getValueFromStringfy(objectCode, 'objectCode'),
          };

      if (isChangeField) {
        // 发请求更改;
        const { data } = await fetchFn(relValue);

        if (data) {
          !approvalPlanId && handleSetApprovalPlanId && handleSetApprovalPlanId(data);
          setModalVisible(true);
          setIsChangeField(false);
        }
      } else {
        //  直接进入下一步
        handleSetCurrent && handleSetCurrent(current + 1);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleStepTwoFinish = async () => {
    try {
      const { data: nodesData }: any = flowBuilderRef.current;

      const nodes = filter(
        nodesData.slice(1, nodesData?.length - 1),
        (e: any) => e?.data?.configured, // 过滤配置了的数据
      );

      if (nodesData?.length - 2 !== nodes?.length) return Message.error('请完成所有审批节点的配置');

      const nodeList = map(nodes, (e: any, i: number) => {
        const { data } = e;

        return {
          approverType: data?.approverType,
          approveMethod: data?.approveMethod,
          code: data?.code,
          name: data?.name,
          approverList: map(data?.approverList, (e: any) => e?.id ?? e?.value),
          sortIndex: i,
          id: data?.id,
          referenceId: data?.referenceId,
          first: i === 0,
          last: i === nodes?.length - 1,
          numberRuleId: data?.numberRuleId,
        };
      });

      const { code, message } = await fetchApprovalPlanNodeSaveNodes({
        // 能配置节点 不管是新建还是编辑 表明都已经有了基本信息 则具备了initialData
        approvalPlanId,
        nodeList,
      });

      if (code === 200) {
        Message.success(`${edit ? '更新成功' : '新建成功'}`);

        history.push(toList());
        return;
      }

      Message.error(message);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const onCancel = () => {
    const { data: nodesData }: any = flowBuilderRef.current;
    const haveNodeChange = some(
      nodesData.slice(1, nodesData?.length - 1),
      (e: any) => e?.data?.isChangeFieldValue,
    );

    if (haveNodeChange) {
      Modal.confirm({
        title: '你确定要取消当前页面吗',
        icon: <ExclamationCircleOutlined />,
        content: '现在取消将不保存当前页面的操作，请确认是否取消',
        okText: '确认取消',
        cancelText: '继续编辑',
        onOk: () => {
          history.push(toList());
        },
      });
      return;
    }

    history.push(toList());
  };

  const onFieldsChange = (fields: any) => {
    if (fields) {
      setIsChangeField(true);
    }
    // 仅当 fields 为单个变动时执行依赖
    // 规避提交时的校验引起值改变
    if (fields.length > 1) return;
    fields.forEach((field: any) => {
      /** 当前变动的字段名 */
      const curfieldName: string = last(field.name) as string;
      /** 当前变动的字段所在的父级 字段的路径 */
      const fieldName: (string | number)[] = initial(field.name) as (string | number)[];
      const value = form.getFieldValue(fieldName);

      switch (curfieldName) {
        case 'fieldId':
          form.setFields([
            {
              name: fieldName,
              value: {
                ...value,
                beforeValues: undefined,
                beforeConditionType: undefined,
                afterValues: undefined,
                afterConditionType: undefined,
              },
            },
          ]);
          break;
        case 'beforeValueType':
        case 'beforeConditionType':
          if (
            value.beforeConditionType === TriggerRule.ConditionType.NULL ||
            value.beforeConditionType === TriggerRule.ConditionType.NOT_NULL
          ) {
            form.setFields([
              {
                name: fieldName,
                value: { ...value, beforeValues: undefined, beforeValueType: undefined },
              },
            ]);
          } else {
            form.setFields([{ name: fieldName, value: { ...value, beforeValues: undefined } }]);
          }
          break;
        case 'afterValueType':
        case 'afterConditionType':
          if (
            value.afterConditionType === TriggerRule.ConditionType.NULL ||
            value.afterConditionType === TriggerRule.ConditionType.NOT_NULL
          ) {
            form.setFields([
              {
                name: fieldName,
                value: { ...value, afterValues: undefined, afterValueType: undefined },
              },
            ]);
          } else form.setFields([{ name: fieldName, value: { ...value, afterValues: undefined } }]);
          break;
        default:
          break;
      }
    });
  };

  const steps = [
    {
      title: '基本信息',
      content: (
        <DataFormLayout
          formProps={{ onFieldsChange }}
          form={form}
          info={hiddenRuleObjectCode ? [baseInfo] : [baseInfo, configInfo, triggerCondition]}
          okText="下一步"
          formLayout="horizontal"
          onCancel={() => {
            if (isChangeField) {
              // 更改了字段 没有保存
              Modal.confirm({
                title: '你确定要取消当前页面吗',
                icon: <ExclamationCircleOutlined />,
                content: '现在取消将不保存当前页面的操作，请确认是否取消',
                okText: '确认取消',
                cancelText: '继续编辑',
                onOk: () => {
                  history.goBack();
                },
              });
              return;
            }
            history.goBack();
          }}
          onFinish={throttleFn(handleStepOneFinish)}
        />
      ),
    },
    {
      title: '流程设置',
      content: <FlowBuilder ref={flowBuilderRef} objectType="defaultType" prefix={prefix} />,
    },
  ];

  return (
    <div className={Styles.stepWBox}>
      <div className={Styles.stepWrapper}>
        <Steps current={current}>
          {steps?.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </div>
      <div
        className={Styles.content}
        style={{
          overflowY: current === 1 ? 'scroll' : 'hidden',
          paddingBottom: current === 1 ? 52 : 0,
        }}
      >
        {steps[current].content}
      </div>
      {current === 1 && (
        <div className={Styles.footer} style={{ display: 'flex', justifyContent: 'center' }}>
          <Space>
            <Button onClick={onCancel}>取消</Button>
            {current > 0 && (
              <Button
                onClick={() => {
                  const { data: nodesData }: any = flowBuilderRef.current;

                  const nodes = filter(
                    nodesData.slice(1, nodesData?.length - 1),
                    (e: any) => e?.data?.configured, // 过滤配置了的数据
                  );

                  if (nodesData?.length - 2 !== nodes?.length) {
                    Message.error('请完成所有审批节点的配置');
                  } else {
                    handleSetCurrent && handleSetCurrent(current - 1);
                  }
                }}
              >
                上一步
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" onClick={throttleFn(handleStepTwoFinish)}>
                保存
              </Button>
            )}
          </Space>
        </div>
      )}
      <ConfirmModal
        visible={modalVisible}
        isEdit={isEdit}
        handleOk={() => {
          handleSetCurrent && handleSetCurrent(current + 1);
          setModalVisible(false);
        }}
        handleCancel={() => {
          // 返回列表
          setModalVisible(false);
          history.push(toList());
        }}
        handleClose={() => {
          setModalVisible(false);
        }}
      />
    </div>
  );
};

export default CreateAndEditBaseInfo;
