/**
 * @description 多级条件配置表单组件  条件间关系组件
 */
import React from 'react';
import { Form, Radio } from 'antd';

import { appDict, appEnum } from 'src/dict';
import { NamePath } from './constants';
import styles from './styles.module.scss';

interface ConditionConnectTypeProps {
  name: NamePath;
  disabled?: boolean;
}

const ConditionConnectType: React.FC<ConditionConnectTypeProps> = ({ name, disabled }) => (
  <div className={styles.filterType}>
    <div className={styles.filterLine} />
    <Form.Item name={name} initialValue={appEnum.TriggerRule.ConnectionType.and}>
      <Radio.Group
        options={appDict.triggerRule.connectionType}
        optionType="button"
        buttonStyle="outline"
        size="small"
        disabled={disabled}
      />
    </Form.Item>
    <div className={styles.filterLine} />
  </div>
);

export default ConditionConnectType;
