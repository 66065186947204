import _ from 'lodash';
import moment from 'moment';
import { fetchNumberRuleListFormatConfig } from 'src/api/ytt/custom-domain';
import { fetchStandardBizObjectListPage } from 'src/api/ytt/metadata-domain';
import _Array from 'src/utils/dataTypes/array';
import { RESET_CYCLE_TYPE_ENUM, TABLE_TYPE_ENUM } from './constant';
import {
  ElementFields,
  ElementList,
  FormatConfigData,
  SourceDataSingleType,
  SourceDataType,
} from './interface';

moment.locale('en', {
  week: {
    dow: 1, // 设定moment计算周的方式
  },
});

// 格式化创建和编辑时  需要返回后端的数据格式
export const formatCreateAndEditValue = (value: any, formatConfigData: FormatConfigData[]) => {
  const newElements = value?.elementList.map((node: any, i: number) => {
    let newtIem = { ...node };

    if (node?.elementType === TABLE_TYPE_ENUM.CONSTANT) {
      newtIem = { ...newtIem, elementLength: node?.constantValue?.length };
    }

    if (node?.elementType === TABLE_TYPE_ENUM.DATE) {
      newtIem = {
        ...newtIem,
        elementLength: formatConfigData?.find(
          (e: FormatConfigData) => e?.id === node?.formatConfigId,
        )?.displayType?.length,
      };
    }

    if (node?.numberBasis) {
      newtIem = { ...newtIem, numberBasis: 1, sortNo: i + 1 };
      return newtIem;
    }
    newtIem = { ...newtIem, numberBasis: 0, sortNo: i + 1 };
    return newtIem;
  });

  const newValue = {
    ...value,
    elementList: newElements,
  };

  return newValue;
};
export const formatElementList = (elementList: any, sourceData: SourceDataType) => {
  const _elementList = elementList?.map((node: any) => {
    const { elementFields, elementType, ...rest } = node;

    if (elementType !== TABLE_TYPE_ENUM.DATE && elementType !== TABLE_TYPE_ENUM.VARIABLE) {
      return { ...node, elementFields: null };
    }

    let newElementFields: any = [];

    if (elementFields?.[0] === '0' || elementFields?.[0] === '当前日期') {
      // 代表选择的默认时间 ，返回后端一个[]
      newElementFields = [];
    } else {
      const nodeFirstItem =
        elementType === TABLE_TYPE_ENUM.DATE
          ? sourceData?.dateData?.find(
              (node: SourceDataSingleType) => node?.value === elementFields[0],
            )
          : sourceData?.variableData?.find(
              (node: SourceDataSingleType) => node?.value === elementFields[0],
            );

      if (elementFields?.length === 1) {
        // 代表是一级
        newElementFields = [
          {
            diffLevel: 1,
            relFieldName: elementFields[0],
            relFieldObjCode: nodeFirstItem?.relFieldObjCode,
            relFieldId: nodeFirstItem?.relFieldId,
          },
        ];
      } else {
        // 代表是二级
        const nodeItem = nodeFirstItem?.children?.find(
          (node: SourceDataSingleType) => node?.value === elementFields[1],
        );

        newElementFields = [
          {
            diffLevel: 1,
            relFieldName: elementFields[0],
            relFieldObjCode: nodeFirstItem?.relFieldObjCode,
            relFieldId: nodeFirstItem?.relFieldId,
          },
          {
            diffLevel: 2,
            relFieldName: elementFields[1],
            relFieldObjCode: nodeItem?.relFieldObjCode,
            relFieldId: nodeItem?.relFieldId,
          },
        ];
      }
    }

    return {
      ...rest,
      elementType,
      elementFields: newElementFields,
    };
  });

  return _elementList;
};

// 判断是数据中是否还有变量类型或者日期元素元素
export const haveVariableOrTimeType = (values: any) => {
  const isHaveVariableOrDate = values.some(
    (item: any) =>
      item?.elementType === TABLE_TYPE_ENUM.VARIABLE || item?.elementType === TABLE_TYPE_ENUM.DATE,
  );

  return isHaveVariableOrDate;
};

// 判断日期是否可作为编号依据  当元素类型为流水周期并且重置周期为非仅按编号依据时  日期不可作为编号依据
export const dateCanAsBasis = (values: any) => {
  const asBasisForVariable = values.some((item: any) => {
    return (
      item?.elementType === TABLE_TYPE_ENUM.SERIAL_NUMBER &&
      item?.resetPeriod === RESET_CYCLE_TYPE_ENUM.BY_NUMBER_BASIS_ONLY
    );
  });

  return asBasisForVariable;
};

// 判断是否有流水号元素 无则需要禁用编码依据列
export const isHaveSerialNumber = (values: any) => {
  const haveSerialNumber = values.some(
    (item: any) => item?.elementType === TABLE_TYPE_ENUM.SERIAL_NUMBER,
  );

  return haveSerialNumber;
};

// 数字小于length长度时在前面补0
export const truncate = (num: number | string, length: number) => {
  return (Array(length).join('0') + num).slice(-length);
};

/**
 * 格式化日期
 * @param start 开始格式化值
 * @param current 当前值
 */
const formatDateByChar = (start: number, current: number) => {
  // 默认以A开始
  const startChar = 65;

  if (current < start) {
    return current;
  }
  return String.fromCharCode(startChar + (current - start));
};

// 格式化返回对应格式需要显示的时间
export const formatDate = (timeFormat: string) => {
  let date;

  switch (timeFormat) {
    case '年份日':
      date = truncate(moment().dayOfYear(), 3);
      break;

    case '年份周':
      date = truncate(moment().week(), 2);
      break;

    case 'M.1':
      date = formatDateByChar(10, moment().month() + 1);
      break;

    case 'M.2':
      date = formatDateByChar(1, moment().month() + 1);
      break;

    case 'D.1':
      date = formatDateByChar(10, moment().date());
      break;

    default:
      date = moment().format(timeFormat);
  }

  return date;
};

export const formatDemo = (elementList: any, formatConfigData: FormatConfigData[]) => {
  const newElementList = _.cloneDeep(elementList);

  const sample = newElementList
    ?.map((node: ElementList) => {
      let item;

      if (node?.elementType === TABLE_TYPE_ENUM.CONSTANT) {
        // 常量取设置值
        item = node?.constantValue;
      }
      if (node?.elementType === TABLE_TYPE_ENUM.DATE) {
        // 日期 根据格式取当前时间
        const time = formatConfigData?.find(
          (e: FormatConfigData) => e?.id === node?.formatConfigId,
        )?.displayType;

        const date = formatDate(time ?? '');

        item = date;
      }
      if (node?.elementType === TABLE_TYPE_ENUM.VARIABLE) {
        // 变量取元素来源
        item =
          node?.elementFields?.length > 1 ? node?.elementFields?.[1] : node?.elementFields?.[0];
      }
      if (node?.elementType === TABLE_TYPE_ENUM.SERIAL_NUMBER) {
        let startValue;
        // 流水号取初始值 根据进制转化 然后再根据长度补0

        if (node?.codeFormat === 1) {
          startValue = node?.startValue.toString(); // 10进制
        } else if (node?.codeFormat === 2) {
          startValue = node?.startValue.toString(16); // 16进制
        } else {
          startValue = node?.startValue.toString(32); // 32进制
        }

        const startValueLength = startValue.length;
        const lengthNumber = node?.elementLength;
        const serialNumberLength =
          startValueLength < lengthNumber ? truncate(startValue, lengthNumber) : startValue; // 长度大于初始值长度才会需要补0

        item = serialNumberLength;
      }
      return item;
    })
    .join('');

  return sample;
};

export const formatDetailElementFields = (elementList: any, type: string) => {
  const newElementList = elementList?.map((node: any) => {
    if (
      node?.elementType !== TABLE_TYPE_ENUM.DATE &&
      node?.elementType !== TABLE_TYPE_ENUM.VARIABLE &&
      !node?.elementFields
    ) {
      return node;
    }

    if (node?.elementType === TABLE_TYPE_ENUM.DATE && !node?.elementFields) {
      return { ...node, elementFields: type === 'edit' ? ['0'] : ['当前日期'] };
    }

    const newElementFields = node?.elementFields?.map((e: ElementFields) => {
      return type === 'edit' ? e?.relFieldName : e?.relFieldDisplayName;
    });

    return { ...node, elementFields: newElementFields };
  });

  return newElementList;
};

export const formatEditElementFields = (elementList: any, sourceData: any) => {
  const newElementList =
    sourceData &&
    elementList?.map((node: any) => {
      if (node?.elementType !== TABLE_TYPE_ENUM.VARIABLE) {
        return node;
      }

      let newElementFields;

      const nodeFirstItem = sourceData?.find(
        (e: SourceDataSingleType) => e?.value === node?.elementFields?.[0],
      );

      if (node?.elementFields?.length === 1) {
        // 代表是一级
        newElementFields = [nodeFirstItem?.label];
      } else {
        // 代表是二级
        const nodeItem = nodeFirstItem?.children?.find(
          (e: SourceDataSingleType) => e?.value === node?.elementFields[1],
        );

        newElementFields = [nodeFirstItem?.label, nodeItem?.label];
      }

      return { ...node, elementFields: newElementFields };
    });

  return newElementList;
};

// // 变量的元素来源根据格式转换大小写
// export const convertCase = (formatConfigId: number, name: string) => {
//   if (formatConfigId === FORMAT_VARIABLE_ENUM?.CAPITAL) {
//     // 大写转换
//     return name.toUpperCase();
//   }
//   if (formatConfigId === FORMAT_VARIABLE_ENUM?.LOWERCASE) {
//     // 大写转换
//     return name.toLowerCase();
//   }
//   return name;
// };

// 格式化处理后端返回的元素来源数据
export const formatData = (data: any) => {
  // 获取对象code以后，元素来源只会根据类型为日期和变量分为两种数据。在一开始的时候就处理好，不需要等确定类型再去过滤
  if (_Array.isEmpty(data?.fields)) {
    // 没有字段对象
    return {
      dateData: [{ label: '当前日期', value: '0' }],
      variableData: [],
    };
  }
  // 1. 日期元素来源：启用中的日期时间、关联关系类型字段。其中关联关系类型字段支持二级，选择该关联对象下启用中的「日期时间，关联关系」类型字段
  // 2. 变量元素来源：启用中的单行文本、整数、单选、关联关系类型字段，其中关联关系支持选择该关联对象下启用中的「单行文本、整数、单选、关联关系」类型字段
  // 3. 1期目前只支持二级，所以二级需要过滤二级的关联关系字段，不能支持可选第三级
  const sourceData: any = {
    dateData: [],
    variableData: [],
  };
  const newDateData = data?.fields
    ?.map((item: any) => {
      if (item?.fieldType === 8 || item?.fieldType === 11) {
        // 日期或者关联关系
        const newItem = {
          label: item?.fieldName,
          value: item?.fieldCode,
          relFieldObjCode: data?.objectCode,
          relFieldName: data?.objectName,
          relFieldId: item?.id,
          children: null,
        };

        if (!_Array.isEmpty(item?.object?.fields)) {
          // 存在二级元素 并且二级元素1期只留 日期类型， 如果一级的关联关系在二级下的日期和关联关系为[] 则该关联关系也不应该在选项中 需要过滤。关联关系必须选到一个可选值才能存在
          const newFields = item?.object?.fields
            ?.map((node: any) => {
              if (node?.fieldType === 8) {
                return {
                  label: node?.fieldName,
                  value: node?.fieldCode,
                  relFieldObjCode: item?.object?.objectCode,
                  relFieldName: item?.object?.objectName,
                  relFieldId: node?.id,
                };
              }
              return null;
            })
            .filter((node: any) => node);

          if (_Array.isEmpty(newFields)) {
            // 二级为空 1级为关联关系的字段直接过滤
            newItem.children = null;
            return null;
          }
          newItem.children = newFields;
        }

        return newItem;
      }
      return null;
    })
    .filter((node: any) => node);

  const newVariableData = data?.fields
    ?.map((item: any) => {
      if (
        item?.fieldType === 1 ||
        item?.fieldType === 4 ||
        item?.fieldType === 7 ||
        item?.fieldType === 11
      ) {
        const newItem = {
          label: item?.fieldName,
          value: item?.fieldCode,
          relFieldObjCode: data?.objectCode,
          relFieldName: data?.objectName,
          relFieldId: item?.id,
          children: null,
        };

        if (!_Array.isEmpty(item?.object?.fields)) {
          const newFields = item?.object?.fields
            ?.map((node: any) => {
              if (node?.fieldType === 1 || node?.fieldType === 4 || node?.fieldType === 7) {
                return {
                  label: node?.fieldName,
                  value: node?.fieldCode,
                  relFieldObjCode: item?.object?.objectCode,
                  relFieldName: item?.object?.objectName,
                  relFieldId: node?.id,
                };
              }
              return null;
            })
            .filter((node: any) => node);

          if (_Array.isEmpty(newFields)) {
            // 二级为空 1级为关联关系的字段直接过滤
            newItem.children = null;
            return null;
          }

          newItem.children = newFields;
        }
        return newItem;
      }
      return null;
    })
    .filter((node: any) => node);

  sourceData.dateData = [{ label: '当前日期', value: '0' }].concat(newDateData);
  sourceData.variableData = newVariableData;

  return sourceData;
};

// 用于列表/创建/编辑获取适用对象数据
export const gteSuitObjIdData = async () => {
  try {
    const { data } = await fetchStandardBizObjectListPage({ page: 1, size: 10000 });

    if (data) {
      const selectData = data?.list?.map((item: any) => {
        return {
          id: item?.id,
          objectName: item?.objectName,
          objectCode: item?.objectCode,
        };
      });

      return selectData;
    }
  } catch (error) {
    console.log(error);
  }
};
// 用于列表/创建/编辑获取格式枚举
export const gteFormatConfig = async () => {
  try {
    const { data } = await fetchNumberRuleListFormatConfig();

    return data;
  } catch (error) {
    console.log(error);
  }
};
