/**
 * 角色管理 - 角色编辑 / 创建
 * prd: https://blacklake.feishu.cn/docs/doccnoz7fL9O0wJq0VHZymNvlth
 */
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, Form, Input, message } from 'antd';
import DataFormLayoutForModal from 'src/layout/dataForm/DataFormLayoutForModal';
import { DataFormLayoutInfoBlock, DataFormLayout } from 'layout';
import { checkTwoSidesTrim, checkFormWithLetterDigitalUnderscore } from 'src/utils/formValidators';
import {
  fetchRoleCreate,
  fetchRoleGet,
  FetchRoleGetResponse,
  fetchRoleUpdate,
} from 'src/api/ytt/user-domain';
import { BlSelect } from 'src/components/select';
import {
  fetchUserLicenseGetOrganizationOwned,
  FetchUserLicenseGetOrganizationOwnedResponse,
} from 'src/api/ytt/user-domain/user';
import { fetchRoleCopy } from 'src/api/ytt/user-domain/user/role';

type useRoleActionType = (props: {
  visible: boolean;
  roleId: number | null;
  isCreate: boolean;
  isCopy: boolean;
}) => {
  view: React.ReactNode;
  show: boolean;
  createSuccess: boolean;
};

/**
 *
 * 对于编辑角色信息，需要传入 角色ID
 * @param visible 是否需要弹出
 * @param roleId 角色id, 编辑时需要
 * @param isCreate
 * @param isCopy
 *
 */
const useRoleAction: useRoleActionType = ({ visible, roleId, isCreate, isCopy }) => {
  const [form] = Form.useForm();
  const [show, setShow] = useState<boolean>(visible);
  const [autoNew, setAutoNew] = useState<boolean>(true);
  const [roleInfo, setRoleInfo] = useState<FetchRoleGetResponse['data']>({});
  const [createSuccess, setCreateSuccess] = useState<boolean>(false);
  const handleRequired = useCallback(
    (msg: string) => ({ required: true, message: `${msg}必填` }),
    [],
  );

  const onSubmit = useCallback(() => {
    const { validateFields } = form;

    validateFields().then(async (data) => {
      const value = _.cloneDeep(data);

      value.name = _.trim(value.name);
      if (value.remark) {
        value.remark = _.trim(value.remark);
      }
      if (value.desc) {
        value.desc = _.trim(value.desc);
      }

      if (value.authorizedLicenseCodes) {
        value.authorizedLicenseCodes = value.authorizedLicenseCodes.map(
          (i: any) => i.value ?? i.name,
        );
      }

      if (isCreate || isCopy) {
        let reqFn: any = fetchRoleCopy;

        if (isCreate) {
          reqFn = fetchRoleCreate;
        }

        await reqFn({
          copyId: roleId ?? undefined,
          ...value,
        });

        message.success('创建角色成功');
        if (!autoNew) {
          setCreateSuccess(true);
          setShow(false);
        } else {
          form.resetFields();
        }
      } else {
        await fetchRoleUpdate({ ...value, id: roleId });

        message.success('更新角色信息成功');
        setCreateSuccess(true);
        setShow(false);
      }
    });
  }, [autoNew, form, isCreate, roleId]);

  const view = useMemo(() => {
    const baseInfo: DataFormLayoutInfoBlock = {
      title: '基本信息',
      column: 2,
      items: [
        {
          label: '角色编号',
          name: 'code',
          rules: [
            handleRequired('角色编号'),
            {
              validator: checkFormWithLetterDigitalUnderscore,
              required: true,
            },
          ],
          render: () => <Input placeholder="请输入角色编号" maxLength={100} />,
        },
        {
          label: '角色名称',
          name: 'name',
          rules: [
            handleRequired('角色名称'),
            {
              validator: checkTwoSidesTrim,
              required: true,
            },
          ],
          render: () => <Input placeholder="请输入角色名称" maxLength={100} />,
        },
        {
          label: '角色描述',
          name: 'desc',
          rules: [
            {
              validator: checkTwoSidesTrim,
            },
          ],
          render: () => <Input placeholder="请输入角色描述" maxLength={255} />,
        },
        {
          label: '许可项',
          name: 'authorizedLicenseCodes',
          required: true,
          rules: [handleRequired('许可项')],
          render: () => (
            <BlSelect
              placeholder="请选择许可项"
              selectOptions={{ mode: 'multiple' }}
              requestFn={fetchUserLicenseGetOrganizationOwned}
              handleData={(data: FetchUserLicenseGetOrganizationOwnedResponse['data'] | any) =>
                data?.userLicenses?.map((item: any) => ({
                  value: item?.code,
                  name: item?.name,
                  label: item?.name,
                  key: item?.code,
                }))
              }
            />
          ),
        },
        {
          label: '备注',
          name: 'remark',
          span: 2,
          rules: [
            {
              validator: checkTwoSidesTrim,
            },
          ],
          render: () => <Input.TextArea placeholder="请选择备注" maxLength={1000} rows={4} />,
        },
      ],
    };

    if (!isCreate && !isCopy) {
      baseInfo.items.push({
        label: '编辑原因',
        name: 'editReason',
        span: 2,
        rules: [
          {
            validator: checkTwoSidesTrim,
          },
        ],
        render: () => <Input.TextArea maxLength={1000} />,
      });
    }

    return (
      <DataFormLayoutForModal
        visible={show}
        onClose={() => setShow(false)}
        width={800}
        content={
          <DataFormLayout
            form={form}
            title={isCreate || isCopy ? '新建角色' : '编辑角色'}
            extra={
              (isCreate || isCopy) && (
                <Checkbox onChange={(e) => setAutoNew(e.target.checked)} defaultChecked={autoNew}>
                  连续新建
                </Checkbox>
              )
            }
            info={[baseInfo]}
            onCancel={() => setShow(false)}
            onFinish={onSubmit}
          />
        }
      />
    );
  }, [handleRequired, show, form, isCreate, autoNew, onSubmit]);

  useEffect(() => {
    setShow(visible);
  }, [visible]);

  useEffect(() => {
    /**
     * 查看用户详情时，获取用户详情
     */
    if (isCreate === false && roleId !== null) {
      fetchRoleGet({ id: roleId })
        .then((res) => {
          setRoleInfo(_.get(res, 'data', {}));
        })
        .catch((e) => {
          message.error(e?.message);
        });
    }
  }, [roleId, isCreate]);

  useEffect(() => {
    /**
     * 查看用户详情时，需要给 form 设置内容
     */
    if (!_.isEmpty(roleInfo)) {
      const roleInfoFields = {
        code: roleInfo?.code,
        name: roleInfo?.name,
        desc: roleInfo?.desc,
        remark: roleInfo?.remark,
        authorizedLicenseCodes: roleInfo?.roleOwnedLicenses?.map((item: any) => ({
          label: item?.name,
          name: item?.name,
          value: item?.code,
          key: item?.code,
        })),
      };

      form.setFieldsValue(roleInfoFields);
    }
  }, [form, roleInfo]);

  return { view, show, createSuccess };
};

const RoleActions: React.FC<{
  hideCallback?: (isCreated: boolean) => void;
  roleId: number | null;
  isCopy: boolean;
}> = ({ hideCallback, roleId, isCopy }) => {
  const { view, show, createSuccess } = useRoleAction({
    visible: true,
    roleId,
    isCreate: _.isUndefined(roleId) || _.isNil(roleId),
    isCopy,
  });

  useEffect(() => {
    hideCallback && !show && hideCallback(createSuccess);
  }, [createSuccess, hideCallback, show]);
  return <>{view}</>;
};

export default RoleActions;
