import React, { useState } from 'react';
import { FilterItem, RecordListLayout } from 'layout';
import { Badge, message, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  fetchEReportQuerySubTemplateListNoStatus,
  fetchEReportQueryTemplatePage,
  fetchEReportTemplateStatusProcess,
} from 'src/api/ytt/e-report-domain/customReport';
import { BlIcon, SearchSelect, SingleOperationLogComponent, Tooltip } from 'src/components';
import { AvatarDisplay } from 'src/components/avatar/show';
import lookup from 'src/dict';
import { CreatorData } from 'src/page/knowledgeManagement/calendarManagement/shift/interface';
import { UserColumnProps } from 'src/page/knowledgeManagement/calendarManagement/workCalendar/constants';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { fieldTypeList } from 'src/utils';
import { map, filter } from 'lodash';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import { APPROVE_MAP, APPROVE_TYPE, STATUS_ENUM, STATUS_ENUM_MAP, TEMPLATE_ENUM } from './constant';
import { TableRowType } from './interface';
import {
  toElectronicTemplateCreate,
  toElectronicTemplateDetail,
  toElectronicTemplateEdit,
} from './navigation';
import _Array from 'src/utils/dataTypes/array';
import { momentData } from 'src/page/knowledgeManagement/calendarManagement/shift/utils';
import _String from 'src/utils/dataTypes/string';
import PreviewModal from './components/preview';
import authDict, { getAuthFromLocalStorage, hasAuth } from 'src/utils/auth';

export default function ElectronicTemplateSubList() {
  const history = useHistory();
  const [selectedRowKeys, setSelectRowKeys] = useState<number[]>([]); // 选中的keys
  const [selectedRows, setSelectRows] = useState([] as any);
  const [refreshMarker, setRefreshMarker] = useState<number>();
  const [visibleLog, setVisibleLog] = useState(false);
  const [detailId, setDetailId] = useState<any>(); // 详情id
  const [visiblePreview, setVisiblePreview] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState([] as any);
  const [approval, setApprove] = useState<number>(0);

  const [templateId, setTemplateId] = useState<number>(0);

  const refresh = () => {
    setRefreshMarker(Date.now());
  };

  const getSubTemplateList = async (templateId: number, id: number) => {
    try {
      const { data } = await fetchEReportQuerySubTemplateListNoStatus({ templateId });

      if (data) {
        const currentData = filter(
          data?.subTemplateFileInfoList,
          (e: any) => e?.subTemplateId === id,
        )?.[0];

        setPreviewData(currentData);
        setVisiblePreview(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const refreshSubTemplateStatus = async (
    ids: number[],
    subTemplateStatus: number,
    type: string,
    onSuccess: any,
    onFail?: any,
  ) => {
    try {
      const { data, code } = await fetchEReportTemplateStatusProcess({
        subTemplateIdList: ids,
        status: subTemplateStatus,
      });

      if (code == 200 && !data?.failNum) {
        if (type === 'single') {
          message.success(
            `${subTemplateStatus === STATUS_ENUM.ENABLED ? '启用' : '停用'}电子单据模板成功`,
          );
        } else {
          message.success(
            `批量${subTemplateStatus === STATUS_ENUM.ENABLED ? '启用' : '停用'}电子单据模板成功`,
          );
        }

        onSuccess?.();
      }
    } catch (error) {
      onFail?.();
    }
  };

  const hasAuthRes = hasAuth(authDict.ereport_detail);

  const dataColumns = [
    {
      title: '模板编号',
      dataIndex: 'templateCode',
      width: 150,
      sorter: true,
      render: (templateCode: string, record: TableRowType, index: number, config: any) => {
        if (!templateCode) return replaceSign;
        return (
          <>
            {hasAuthRes ? (
              <a
                onClick={() => {
                  history.push(toElectronicTemplateDetail(record?.templateId));
                }}
              >
                <Tooltip text={templateCode ?? replaceSign} width={config.contentWidth} />
              </a>
            ) : (
              <Tooltip text={templateCode ?? replaceSign} width={config.contentWidth} />
            )}
          </>
        );
      },
    },
    {
      title: '模板名称',
      dataIndex: 'name',
      width: 200,
      render: (name: string) => name || replaceSign,
    },
    {
      title: '模板类别',
      dataIndex: 'type',
      width: 150,
      sorter: true,
      render: (bizTheme: number) => {
        return lookup('customReport.businessTheme', bizTheme) || '-';
      },
    },
    {
      title: 'cpt模板',
      dataIndex: 'cptFileName',
      width: 250,
      render: (cptFileName: string, record: any) =>
        <a href={record?.cptTemplatePath}>{cptFileName}</a> || replaceSign,
    },
    {
      title: 'cpt模板版本号',
      dataIndex: 'cptVersion',
      width: 150,
      sorter: true,
      render: (cptVersion: string) => cptVersion || replaceSign,
    },
    {
      title: 'cpt模板状态',
      dataIndex: 'subTemplateStatus',
      width: 150,
      sorter: true,
      render: (subTemplateStatus: number) => {
        const useStatusMap = new Map([
          [STATUS_ENUM.ENABLED, <Badge text="启用中" status="success" />],
          [STATUS_ENUM.DISABLED, <Badge text="停用中" status="error" />],
        ]);

        return useStatusMap.get(subTemplateStatus);
      },
    },
    {
      title: '是否需要审批',
      dataIndex: 'approval',
      width: 150,
      render: (approval: number) => APPROVE_TYPE.get(approval),
    },
    {
      title: '审批方案',
      dataIndex: 'approvalPlanName',
      width: 150,
      render: (approvalPlanName: string) => approvalPlanName ?? replaceSign,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 120,
      render: (creator: CreatorData) => {
        return (
          <AvatarDisplay
            id={creator?.id}
            name={creator?.name}
            avatarUrl={creator?.avatarUrl}
            key={creator?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 150,
      sorter: true,
      render: (createAt: Date) => {
        if (!createAt) return replaceSign;
        return _Time.format(createAt);
      },
    },
    {
      title: '更新人',
      dataIndex: 'modifier',
      width: 150,
      defaultColConfig: {
        display: false,
      },
      render: (modifier: CreatorData) => {
        return (
          <AvatarDisplay
            id={modifier?.id}
            name={modifier?.name}
            avatarUrl={modifier?.avatarUrl}
            key={modifier?.id}
            isShowTag={false}
            isUser
          />
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 150,
      defaultColConfig: {
        display: false,
      },
      sorter: true,
      render: (updateAt: Date, record: TableRowType) => {
        if (!updateAt) return _Time.format(record?.createAt) ?? replaceSign;
        return _Time.format(updateAt);
      },
    },
  ];

  const filterList: FilterItem[] = [
    {
      label: '模板编号',
      name: 'templateCode',
      type: fieldTypeList.text,
    },
    {
      label: '模板名称',
      name: 'name',
      type: fieldTypeList.text,
    },
    {
      label: '模板类别',
      name: 'type',
      type: fieldTypeList.select,
      selectProps: {
        options: TEMPLATE_ENUM.map((node: any) => {
          return {
            label: node?.label,
            value: node?.key,
          };
        }),
      },
    },
    {
      label: 'cpt模板',
      name: 'cptFileName',
      type: fieldTypeList.text,
    },
    {
      label: 'cpt模板版本号',
      name: 'cptVersion',
      type: fieldTypeList.text,
    },
    {
      label: 'cpt模板状态',
      name: 'subTemplateStatus',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <Select
          mode="multiple"
          placeholder={'请选择'}
          options={map(STATUS_ENUM_MAP, (item: any) => {
            return {
              label: item?.name,
              value: item?.id,
            };
          })}
        />
      ),
    },
    {
      label: '是否需要审批',
      name: 'approval',
      type: fieldTypeList.select,
      renderItem: <Select placeholder={'请选择'} options={APPROVE_MAP} />,
    },
    {
      label: '审批方案',
      name: 'approvalPlanIdList',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect fetchType={'approvePlan'} mode="multiple" params={{ status: [1] }} />
      ),
    },
    {
      label: '创建人',
      name: 'creatorId',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
    {
      label: '创建时间',
      name: 'createdAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '更新人',
      name: 'operatorId',
      type: fieldTypeList.text,
      renderItem: <UserOrDepartmentSelectWithDialog<UserColumnProps> isMultiple isSelectUser />,
    },
    {
      label: '更新时间',
      name: 'updatedAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
  ];

  const mainMenu = [
    {
      title: '新建电子单据模板',
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.ereport_add,
      onClick: () => {
        history.push(toElectronicTemplateCreate());
      },
      items: [],
    },
  ];

  const toolbar = [
    {
      title: '启用',
      icon: <BlIcon type="iconqiyong" />,
      auth: authDict.ereport_enable_disable,
      onClick: (onSuccess: any, onFail: any) => {
        const enableCheck = map(selectedRows, 'subTemplateStatus');

        const unEnable = enableCheck.includes(1) && enableCheck.includes(2);

        if (unEnable) {
          onFail?.();
          message.error('只能选择停用中的状态进行启用');
        } else {
          refreshSubTemplateStatus(
            selectedRowKeys,
            STATUS_ENUM.ENABLED,
            'batch',
            onSuccess,
            onFail,
          );
        }
      },
    },
    {
      title: '停用',
      icon: <BlIcon type="icontingyong" />,
      auth: authDict.ereport_enable_disable,
      onClick: (onSuccess: any, onFail: any) => {
        refreshSubTemplateStatus(selectedRowKeys, STATUS_ENUM.DISABLED, 'batch', onSuccess, onFail);
      },
    },
  ];

  const formatDataToQuery = (params: any) => {
    const {
      templateCode,
      name,
      cptFileName,
      cptVersion,
      operatorId,
      creatorId,
      createdAt,
      updatedAt,
      approvalPlanIdList,
      ...rest
    } = params;

    const relParams = {
      ...rest,
    };

    if (!_String.isEmpty(templateCode)) {
      relParams.templateCode = templateCode;
    }

    if (!_String.isEmpty(name)) {
      relParams.name = name;
    }
    if (!_String.isEmpty(cptFileName)) {
      relParams.cptFileName = cptFileName;
    }
    if (!_String.isEmpty(cptVersion)) {
      relParams.cptVersion = cptVersion;
    }

    if (createdAt) {
      relParams.createdAtStart = Number(_Time.formatToUnix(createdAt[0]));
      relParams.createdAtEnd = Number(_Time.formatToUnix(createdAt[1]));
    }
    if (updatedAt) {
      relParams.updatedAtStart = Number(_Time.formatToUnix(updatedAt[0]));
      relParams.updatedAtEnd = Number(_Time.formatToUnix(updatedAt[1]));
    }

    if (!_Array.isEmpty(creatorId)) {
      relParams.creatorId = map(creatorId, 'value');
    }
    if (!_Array.isEmpty(operatorId)) {
      relParams.operatorId = map(operatorId, 'value');
    }

    if (!_Array.isEmpty(approvalPlanIdList)) {
      relParams.approvalPlanIdList = map(approvalPlanIdList, 'value');
    }

    return relParams;
  };

  const formatDataToFormDisplay = (data: any) => {
    const { createdAt, updatedAt } = data;

    const retData = { ...data };

    if (createdAt) {
      retData.createdAt = momentData(createdAt);
    }
    if (updatedAt) {
      retData.updatedAt = momentData(updatedAt);
    }

    return retData;
  };

  const config = {
    rowKey: (record: TableRowType) => String(record.id),
    columns: dataColumns,
    getOperationList: (record: TableRowType) => {
      return [
        {
          title: '查看',
          auth: authDict.ereport_enable_disable,
          onClick: () => {
            history.push(toElectronicTemplateDetail(record?.templateId));
          },
        },
        {
          title: '编辑',
          auth: authDict.ereport_edit,
          onClick: () => history.push(toElectronicTemplateEdit(record?.templateId, 'list')),
        },
        {
          title: record?.subTemplateStatus === STATUS_ENUM.ENABLED ? '停用' : '启用',
          auth: authDict.ereport_enable_disable,
          onClick: async () => {
            const subTemplateStatus =
              record?.subTemplateStatus === STATUS_ENUM.ENABLED
                ? STATUS_ENUM.DISABLED
                : STATUS_ENUM.ENABLED;

            refreshSubTemplateStatus([record?.id], subTemplateStatus, 'single', refresh);
          },
        },
        {
          title: '预览',
          onClick: () => {
            getSubTemplateList(record?.templateId, record?.id);
            setTemplateId(record?.templateId);
            setApprove(record?.approval);
          },
        },
        {
          title: '操作记录',
          auth: authDict.ereport_operrecord,
          onClick: () => {
            setDetailId(record?.templateId);
            setVisibleLog(true);
          },
        },
      ];
    },
    mainMenu,
    filterList,
    batchMenu: toolbar,
    formatDataToQuery,
    formatDataToFormDisplay,
  };

  return (
    <>
      <RecordListLayout<TableRowType>
        userAuth={getAuthFromLocalStorage()}
        placeholder="请输入模板编号或模板名称"
        selectedRowKeys={selectedRowKeys}
        configcacheKey="electronicTemplateListCol" // 储存列配置
        onSelectedRowKeys={(selectKey: any, selectRows?: any[]) => {
          setSelectRowKeys(selectKey);
          setSelectRows(selectRows);
        }}
        requestFn={fetchEReportQueryTemplatePage}
        refreshMarker={refreshMarker}
        {...config}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailId}
          objectCode={'eReport'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
      {visiblePreview && previewData && (
        <PreviewModal
          visible={visiblePreview}
          initData={previewData}
          onClose={() => setVisiblePreview(false)}
          templateId={templateId}
          approval={approval}
          operationType={'needApprove'}
        />
      )}
    </>
  );
}
