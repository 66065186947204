export enum clickTypeEnum {
  node = 0,
  edge = 1,
}

export enum PageModeEnum {
  create = 0,
  edit,
  copy,
}
