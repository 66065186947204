import _, {
  map,
  isEmpty,
  toString,
  toNumber,
  set,
  omit,
  pick,
  forEach,
  uniqueId,
  split,
} from 'lodash';
import { LabeledValue } from 'antd/lib/select';
import { gcTime } from 'src/utils';
import _Time from 'src/utils/dataTypes/time';
import { appEnum } from 'src/dict';
import { arrayIsEmpty } from 'src/utils/array';
import { BatchGenerationTypeEnum } from 'src/dict/productionPlanning';
import {
  formatMaterialToApi,
  formatMaterialToForm,
} from 'src/page/knowledgeManagement/engineerData/bom/utils';
import {
  formatBomFeedingControlToApi,
  formatBomFeedingControlToForm,
} from 'src/page/knowledgeManagement/engineerData/bom/createAndEdit/feedingControlForm';
import {
  formatterProcessApi,
  formatterProcessConnApi,
  formatterProcess,
  formatterProcessConnections,
} from 'src/page/knowledgeManagement/engineerData/processRouting/utils';
import {
  WorkOrderRequestOutputMaterial,
  WorkOrderFormOutputMaterial,
  WorkOrderFormInputMaterial,
  WorkOrderRequestInputMaterial,
  WorkOrderRequestProcessPlanCO,
  WorkOrderRequestSourceCO,
  WorkOrderRequestBaseCO,
  WorkOrderFormBaseCO,
  WorkOrderFormSourceCO,
  WorkOrderFormProcessPlanCO,
  WorkOrderResponseBaseCO,
  WorkOrderResponseOutputMaterial,
  WorkOrderResponseInputMaterial,
  WorkOrderResponseProcessPlanCO,
  WorkOrderResponseSourceCO,
} from './index.type';

/** 格式化 产出物料 给接口 */
export const formatProductionOrderOutputMaterialsToApi = (
  bomOutputMaterials?: WorkOrderFormOutputMaterial[],
): WorkOrderRequestOutputMaterial[] => {
  if (isEmpty(bomOutputMaterials)) return [];

  const formatBomOutputMaterials = map(
    bomOutputMaterials,
    ({
      materialId,
      unitId = 0,
      singleWorkReportAmount,
      outputProcessId,
      plannedAmount,
      outputSopControlId,
      processRouteId,
      version,
      batchGenerationType,
      batch,
      main,
      ...resItem
    }) => ({
      ...resItem,
      unitId,
      materialId: formatMaterialToApi(materialId),
      outputProcessNum: outputProcessId?.label ? toString(outputProcessId?.label) : undefined,
      singleWorkReportAmount: singleWorkReportAmount ? toString(singleWorkReportAmount) : undefined,
      plannedAmount: plannedAmount ? toString(plannedAmount) : toString(0),
      outputSopControlId: toNumber(outputSopControlId?.value),
      processRouteId: main === 1 && processRouteId?.key ? toNumber(processRouteId?.key) : undefined,
      bomId: main === 1 && version?.key ? toNumber(version?.key) : undefined,
      batchGenerationType,
      batchNumber:
        batchGenerationType === BatchGenerationTypeEnum.BATCH && batch?.key
          ? JSON.parse(batch?.key)?.batchNo
          : undefined,
      batchNumberId:
        batchGenerationType === BatchGenerationTypeEnum.BATCH && batch?.key
          ? JSON.parse(batch?.key)?.id
          : undefined,
      batchRuleId:
        batchGenerationType === BatchGenerationTypeEnum.NUMBERINGRULES
          ? Number(batch?.key)
          : undefined,
      main,
    }),
  );

  /** 主产出报工工序号不传 后端自动获取最后一道序 */
  formatBomOutputMaterials[0].outputProcessNum = undefined;

  return formatBomOutputMaterials;
};

/** 格式化 用料清单 到接口 */
export const formatProductionOrderInputMaterialsToApi = (
  bomInputMaterials?: WorkOrderFormInputMaterial[],
): WorkOrderRequestInputMaterial[] => {
  if (isEmpty(bomInputMaterials)) return [];

  const retBomInputMaterials: WorkOrderRequestInputMaterial[] = [];

  bomInputMaterials?.forEach(
    ({
      materialId,
      bomFeedingControls,
      inputAmountDenominator,
      inputAmountNumerator,
      lossRate,
      isChildren,
      inputProcessId,
      inputType = appEnum.ProductionPlanning.ItemTypeEnum.STANDARD,
      version,
      requirementTime,
      supplierList,
      workOrderAlternativePlan,
      specificProcessInput,
      ...resItem
    }) => {
      const formatItem = {
        ...resItem,
        inputAmountDenominator: toString(inputAmountDenominator),
        inputAmountNumerator: toString(inputAmountNumerator),
        lossRate: toString(lossRate),
        bomId: version ? toNumber(version?.value) : undefined,
        bomFeedingControls: formatBomFeedingControlToApi(bomFeedingControls, 1),
        materialId: formatMaterialToApi(materialId),
        inputType,
        useRatio: '1',
        inputProcessId: inputProcessId?.value
          ? toNumber(split(inputProcessId?.value, '-')?.[1])
          : undefined,
        inputProcessNum: inputProcessId?.value ? toString(inputProcessId?.label) : undefined,
        supplierIds: !arrayIsEmpty(supplierList) ? map(supplierList, 'value') : undefined,
        requirementTime: Number(gcTime.formatToUnix(requirementTime)),
        specificProcessInput,
        workOrderAlternativePlan: specificProcessInput
          ? formatAlternativePlanToApi(workOrderAlternativePlan)
          : null,
      };

      if (!isChildren) {
        set(formatItem, 'bomInputMaterialLines', []);

        retBomInputMaterials.push(formatItem);
      } else {
        const lastIndex = retBomInputMaterials.length - 1;

        if (retBomInputMaterials[lastIndex]) {
          retBomInputMaterials[lastIndex]?.bomInputMaterialLines?.push(formatItem);
        }
      }
    },
  );
  return retBomInputMaterials;
};

export const formatAlternativePlanToApi = (workOrderAlternativePlan: any) => {
  if (!workOrderAlternativePlan) {
    return null;
  }

  const { substitutionStrategy, originalAlternativeMaterials, alternativeMaterials } =
    workOrderAlternativePlan;

  if (!substitutionStrategy || !originalAlternativeMaterials || isEmpty(alternativeMaterials)) {
    return null;
  }

  return {
    substitutionStrategy,
    originalAlternativeMaterials: map(originalAlternativeMaterials, ({ materialId, ...rest }) => ({
      materialId: formatMaterialToApi(materialId),
      ...rest,
    })),
    alternativeMaterials: map(alternativeMaterials, ({ materialId, ...rest }) => ({
      materialId: formatMaterialToApi(materialId),
      ...rest,
    })),
  };
};

/** 格式化 工艺路线 给接口 */
export const formatProcessPlanCOToApi = (
  processPlanCO: WorkOrderFormProcessPlanCO,
): WorkOrderRequestProcessPlanCO => {
  // const processData = processRef?.current?.formatterProcessInfo();
  const values = processPlanCO;

  const params = omit(values, [
    'node',
    'edge',
    'process',
    'materialCateg',
    'materialName',
    'materialStyle',
  ]) as any;

  params.processes = formatterProcessApi(params.processes);
  params.processConnections = formatterProcessConnApi(params.processConnections);

  map(params?.processes, (item: any) => {
    omit(item, ['workCenterName', 'id']);
    // item.reportIds = [];
    item.plannedEndTime = item?.plannedEndTime
      ? Number(gcTime.formatToUnix(item?.plannedEndTime))
      : undefined;
    item.plannedStartTime = item?.plannedStartTime
      ? Number(gcTime.formatToUnix(item?.plannedStartTime))
      : undefined;
    item.processCode = item?.processCode ? item?.processCode : item?.code;
    item.fileIds = !arrayIsEmpty(item?.fileIds) ? item?.fileIds : [];
    item.identifier = item.identifier ?? null;
    // item.sopSnapshotId = item.sop;

    item.workCenterGroupCOList = !arrayIsEmpty(item.workCenterGroupCOList)
      ? map(item.workCenterGroupCOList, ({ bizList, ...rest }) => ({
          ...rest,
          bizList,
          bizIdList: bizList.map((item: any) => item?.id),
        }))
      : [];

    // *  没有重新选择sop方案，直接用保存草稿时选的，sopSnapshotId = 后端返回的sop方案ID。sop=null
    // *  重新选择sop方案， sop=所选择的sop方案ID， sopSnapshotId=null

    /** 创建 复制快照id为null */
    if (
      values?.workOrderType === appEnum.Common.CRUD.create ||
      values?.workOrderType === appEnum.Common.CRUD.copy
    ) {
      item.sopSnapshotId = null;
    } else if (values?.workOrderType === appEnum.Common.CRUD.edit && item.sopSnapshotId) {
      /** item.sopSnapshotId存在即未编辑sop方案 则后端优先使用工单快照sop方案 */
      item.sop = null;
    }
    return item;
  });

  const options = {
    ...params,
    // unitId: params.unitId?.value,
    originalProcessRouteId: values?.id,
    // ...processPlanCO,
  };

  // options.processes[0].code = '2021111900001-code';
  if (!arrayIsEmpty(options.processConnections)) {
    options.processConnections.map((item: any) => {
      item.connectionType = '1';
      // item.processManufacturedGoods.singleWorkReportNum = '10';
      // item.processManufacturedGoods.unitId =
      //   item.processManufacturedGoods.unitId ?? item?.processManufacturedGoods?.unit?.id;
      item.feedingQualityStatus = item.feedingQualityStatu ?? null;
      return item;
    });
  }

  return pick(options, [
    'enableSop',
    'originalProcessRouteId',
    'processConnections',
    'processes',
    'processRouteSnapshotId',
  ]);
  // return options;
};

/** 格式化 基础信息 给接口 */
export const formatWorkOrderBaseCOToApi = (
  workOrderBaseCO: WorkOrderFormBaseCO,
): WorkOrderRequestBaseCO => {
  const {
    planFinishTime,
    planStartTime,
    productionDepartmentId,
    productionSupervisorId,
    planningDepartmentId,
    planningUserId,
    pauseFlag,
    identifier,
    shiftId,
    resourceId,
    ...rest
  } = workOrderBaseCO;

  return {
    ...rest,
    planFinishTime: planFinishTime ? Number(gcTime.formatToUnix(planFinishTime)) : undefined,
    planStartTime: planStartTime ? Number(gcTime.formatToUnix(planStartTime)) : undefined,
    productionDepartmentId: _.head(productionDepartmentId)?.value,
    productionSupervisorId: _.head(productionSupervisorId)?.value,
    planningDepartmentId: _.head(planningDepartmentId)?.value,
    planningUserId: _.head(planningUserId)?.value,
    pauseFlag: Boolean(pauseFlag),
    identifier: identifier ?? null,
    shiftId: shiftId?.value,
    resourceId: resourceId ? JSON.parse(resourceId?.value ?? '{}')?.id : undefined,
  };
};

// 格式化 来源信息 给接口
export const formatWorkOrderSourceCOToApi = (workOrderSourceCO: any): WorkOrderRequestSourceCO => {
  if (!workOrderSourceCO) return { generationMethod: 1 };

  const { sourceSalesOrderList: _sourceSalesOrderList, ...rest } = workOrderSourceCO;

  const sourceSalesOrderList = map(_sourceSalesOrderList, ({ salesOrderId, salesOrderLine }) => {
    return {
      deliveryTime: Number(
        gcTime.formatToUnix(JSON.parse(salesOrderLine?.value ?? '{}')?.deliveryDate),
      ),
      salesOrderId: salesOrderId?.value,
      salesOrderLine: salesOrderLine?.label,
    };
  });

  return {
    ...rest,
    sourceSalesOrderList,
    generationMethod: 1,
  };
};

/** 格式化 基本信息 给Form */
export const formatWorkOrderBaseCOToForm = (
  workOrderSourceCO: WorkOrderResponseBaseCO,
): WorkOrderFormBaseCO => {
  const {
    status,
    workOrderType,
    plannedFinishTime,
    plannedStartTime,
    shift,
    planningDepartment,
    planningUser,
    productionDepartment,
    productionSupervisor,
    files,
    resource,
    identifier,
    type,
    code,
    ...rest
  } = workOrderSourceCO;

  return {
    ...rest,
    status,
    workOrderType,
    planFinishTime: plannedFinishTime ? _Time.formatUnixMoment(plannedFinishTime) : undefined,
    planStartTime: plannedStartTime ? _Time.formatUnixMoment(plannedStartTime) : undefined,
    shiftId: shift && { label: shift.name, value: shift.id },
    resourceId: resource && {
      label: resource.name,
      key: JSON.stringify(resource),
      value: JSON.stringify(resource),
    },
    productionDepartmentId: productionDepartment
      ? [{ label: productionDepartment?.name, value: productionDepartment?.id }]
      : [],
    planningUserId: planningUser ? [{ label: planningUser?.name, value: planningUser?.id }] : [],
    planningDepartmentId: planningDepartment
      ? [{ label: planningDepartment?.name, value: planningDepartment?.id }]
      : [],
    productionSupervisorId: productionSupervisor
      ? [{ label: productionSupervisor?.name, value: productionSupervisor?.id }]
      : [],
    fileIds: files,
    identifier: type === appEnum.Common.CRUD.copy ? undefined : identifier,
    code: type === appEnum.Common.CRUD.copy ? undefined : code,
  };
};

/** 格式化 产出物料 给form */
export const formatBomOutputMaterialsToForm = (
  value: WorkOrderResponseOutputMaterial[],
  type?: string,
): WorkOrderFormOutputMaterial[] => {
  if (isEmpty(value)) return [];

  return map(value, (item) => {
    const {
      lineSeq = 10,
      material,
      outputProcessSimpleVO,
      singleWorkReportAmount,
      main,
      plannedAmount,
      batchGenerationType,
      productRate,
      version,
      processRouteId,
      processRouteCode,
      outputSopControlCode,
      outputSopControlId,
      bomId,
      batchRule,
      batchNumber,
      batchNumberId,
      sop,
      ...resItem
    } = item;

    let _batch: LabeledValue;

    if (BatchGenerationTypeEnum.NUMBERINGRULES === batchGenerationType) {
      _batch = { key: String(batchRule?.id), label: batchRule?.name, value: String(batchRule?.id) };
    }

    if (BatchGenerationTypeEnum.BATCH === batchGenerationType) {
      _batch = {
        key: JSON.stringify({ id: batchNumberId, batchNo: batchNumber }),
        label: batchNumber,
        value: JSON.stringify({ id: batchNumberId, batchNo: batchNumber }),
      };
    }

    const copyOrEditSopId = type === appEnum.Common.CRUD.copy ? sop?.sopId : sop?.id;

    return {
      ...resItem,
      lineSeq,
      singleWorkReportAmount: singleWorkReportAmount ? toNumber(singleWorkReportAmount) : undefined,
      outputProcessId: outputProcessSimpleVO?.processDefId
        ? {
            key: `${uniqueId()}-${
              copyOrEditSopId
                ? Number(copyOrEditSopId)
                : Number(outputProcessSimpleVO?.processDefId)
            }`,
            value: `${uniqueId()}-${
              copyOrEditSopId
                ? Number(copyOrEditSopId)
                : Number(outputProcessSimpleVO?.processDefId)
            }`,
            label: outputProcessSimpleVO?.processNum,
          }
        : undefined,
      outputSopControlId: outputSopControlId
        ? {
            label: outputSopControlCode,
            value: outputSopControlId,
            key: String(outputSopControlId),
          }
        : undefined,
      materialId: formatMaterialToForm(material) ?? {},
      batchGenerationType,
      batch: _batch,
      main,
      plannedAmount: plannedAmount?.amount,
      productRate,
      version: bomId && version ? { key: String(bomId), label: version, value: bomId } : undefined,
      processRouteId: {
        key: toString(processRouteId),
        label: processRouteCode,
        value: Number(processRouteId),
      },
    };
  });
};

/**
 * 格式化 替代物料 给form
 * @param value
 * @returns
 */

// 被替代料格式化
export const formatOriginalAlternativeMaterials = (
  originalAlternativeMaterials: any,
  fn?: (item: any) => { [index: string]: any },
) => {
  return map(originalAlternativeMaterials, (item) => {
    const { materialDO, unit, amountDenominator, amountNumerator, useRatio, ...rest } = item;

    return {
      ...rest,
      materialId: formatMaterialToForm(materialDO),
      unitId: unit?.id,
      amountDenominator: toNumber(amountDenominator),
      amountNumerator: toNumber(amountNumerator),
      useRatio: toNumber(useRatio),
      ...(fn?.(item) ?? {}),
    };
  });
};

// 替代料格式化
export const formatAlternativeMaterials = (
  alternativeMaterials: any,
  fn?: (item: any) => { [index: string]: any },
) => {
  return map(alternativeMaterials, (item) => {
    const {
      materialDO,
      material,
      unit,
      amountDenominator,
      amountNumerator,
      useRatio,
      useUpperLimitRatio,
      ...rest
    } = item;

    return {
      ...rest,
      materialId: materialDO ? formatMaterialToForm(materialDO) : formatMaterialToForm(material),
      unitId: unit?.id,
      amountDenominator: amountDenominator ? toNumber(amountDenominator) : amountDenominator,
      amountNumerator: amountNumerator ? toNumber(amountNumerator) : amountNumerator,
      useRatio: useRatio ? toNumber(useRatio) : useRatio,
      useUpperLimitRatio: useUpperLimitRatio ? toNumber(useUpperLimitRatio) : useUpperLimitRatio,
      ...(fn?.(item) ?? {}),
    };
  });
};

export const formatAlternativePlanToForm = (workOrderAlternativePlan: any) => {
  const { substitutionStrategy, originalAlternativeMaterials, alternativeMaterials } =
    workOrderAlternativePlan || {};

  return {
    substitutionStrategy,
    originalAlternativeMaterials: formatOriginalAlternativeMaterials(originalAlternativeMaterials),
    alternativeMaterials: formatAlternativeMaterials(alternativeMaterials),
  };
};

/**
 * 格式化 用料清单 给form
 * @param value
 * @returns
 */
export const formatBomInputMaterialsToForm = (
  value?: WorkOrderResponseInputMaterial[],
  type?: string,
): WorkOrderFormInputMaterial[] => {
  if (isEmpty(value)) return [];

  const retBomInputMaterials: WorkOrderFormInputMaterial[] = [];

  const formatItem = (
    item: WorkOrderResponseInputMaterial,
    index: number,
    isChildren: boolean = false,
  ): WorkOrderFormInputMaterial => {
    const {
      seq = index * 10,
      unitId = 0,
      version = '',
      lossRate = 0,
      pickMode,
      material = {},
      inputAmountNumerator = 1,
      inputAmountDenominator = 1,
      specificProcessInput,
      splitSopControlInput,
      bomInputMaterialLines = [],
      bomFeedingControls,
      requirementTime,
      inputProcessNum,
      inputProcessId,
      bomId,
      supplierList,
      workOrderAlternativePlan,
      sop,
      ...resItem
    } = item;

    const copyOrEditSopId = type === appEnum.Common.CRUD.copy ? sop?.sopId : sop?.id;

    return {
      ...resItem,
      seq,
      unitId,
      lossRate: toNumber(lossRate),
      pickMode: pickMode ?? appEnum.Bom.PickMode.demandPick,
      materialId: formatMaterialToForm(material),
      isChildren,
      splitProcessInput:
        !isChildren && !isEmpty(bomInputMaterialLines)
          ? appEnum.Common.YN.yes
          : appEnum.Common.YN.no,
      specificProcessInput: specificProcessInput ?? appEnum.Common.YN.no,
      splitSopControlInput: splitSopControlInput ?? appEnum.Common.YN.no,
      inputProcessId: inputProcessId
        ? {
            key: `${uniqueId()}-${Number(copyOrEditSopId)}`,
            label: inputProcessNum,
            value: `${uniqueId()}-${Number(copyOrEditSopId)}`,
          }
        : undefined,
      inputAmountNumerator: toNumber(inputAmountNumerator),
      inputAmountDenominator: toNumber(inputAmountDenominator),
      bomInputMaterials: map<WorkOrderResponseInputMaterial, WorkOrderFormInputMaterial>(
        bomInputMaterialLines,
        (item, index) => formatItem(item, Number(index), true),
      ),
      bomFeedingControls: formatBomFeedingControlToForm(bomFeedingControls, (item) => {
        const { inputSopControlDTO } = item;

        return {
          inputSopControlId: inputSopControlDTO?.id
            ? {
                label: inputSopControlDTO?.code,
                value: inputSopControlDTO?.id,
              }
            : undefined,
        };
      }),
      requirementTime: requirementTime ? _Time.formatUnixMoment(requirementTime) : undefined,
      version: bomId ? { key: bomId, label: version, value: bomId } : undefined,
      supplierList: !arrayIsEmpty(supplierList)
        ? map(supplierList, ({ id, name }) => ({
            key: id,
            value: id,
            label: name,
          }))
        : undefined,
      workOrderAlternativePlan: formatAlternativePlanToForm(workOrderAlternativePlan),
    };
  };

  forEach(value, (item, index) => {
    const bomInputMaterials = formatItem(item, index);

    retBomInputMaterials.push(bomInputMaterials);

    // 如果有子行,把子行平铺到子项物料
    // if (bomInputMaterials && !isEmpty(bomInputMaterials)) {
    //   retBomInputMaterials.push(bomInputMaterials);
    // }
  });

  return retBomInputMaterials;
};

// 格式化 工序 给Form
export const formatProcessPlanCOToForm = (processPlanCO: WorkOrderResponseProcessPlanCO) => {
  const { originalProcessRoute, type, ...rest } = processPlanCO;

  if (processPlanCO) {
    // const { department } = processPlanCO.processes;
    const processes = map(
      processPlanCO.processes,
      ({ plannedEndTime, plannedStartTime, processCode, sop, identifier, ...rest }) => {
        return {
          ...rest,
          plannedEndTime: plannedEndTime ? _Time.formatUnixMoment(plannedEndTime) : undefined,
          plannedStartTime: plannedStartTime ? _Time.formatUnixMoment(plannedStartTime) : undefined,
          processCode,
          code: processCode,
          // sopId 是定义id
          sop: { id: type === appEnum.Common.CRUD.copy ? sop?.sopId : sop?.id, name: sop?.name },
          sopSnapshotId: type === appEnum.Common.CRUD.copy ? undefined : sop?.id,
          identifier: type === appEnum.Common.CRUD.copy ? undefined : identifier,
        };
      },
    );
    const options = {
      ...rest,
      processes: !arrayIsEmpty(formatterProcess(processes))
        ? formatterProcess(processes)
        : [
            {
              processSeq: 1, // 首道序
              processNum: '10', // 工序号
              reportingMethod: [1, 2], // 报工方式
              planWorkReportQuantityControl: 2, // 计划报工 数量管控
              productionStatusControl: 2, // 生产任务状 态操作管控
            },
          ],
      processConnections: formatterProcessConnections(processPlanCO.processConnections),
      workOrderProcessCode: originalProcessRoute?.code,
      workOrderProcessName: originalProcessRoute?.name,
      workOrderProcessRemark: originalProcessRoute?.remark,
    };

    return options;
  }
};

// 格式化 基本信息 给Form
export const formatWorkOrderSourceCOToForm = (
  workOrderSourceCO: WorkOrderResponseSourceCO,
): WorkOrderFormSourceCO => {
  const {
    // generationMethod,
    sourceSalesOrderList,
    // sourceType,
    // sourceWorkOrderList,
    ...rest
  } = workOrderSourceCO || {};

  return {
    ...rest,
    sourceSalesOrderList: map(
      sourceSalesOrderList,
      ({ salesOrderValue, salesOrderLine, deliveryTime, ...rest }) => ({
        ...rest,
        salesOrderId: {
          key: salesOrderValue?.id,
          label: salesOrderValue?.code,
          value: salesOrderValue?.id,
        },
        salesOrderLine: {
          key: JSON.stringify({ deliveryDate: deliveryTime, lineNo: salesOrderLine }),
          label: salesOrderLine,
          value: JSON.stringify({ deliveryDate: deliveryTime, lineNo: salesOrderLine }),
        },
      }),
    ),
  };
};
