import { ReactNode, useState } from 'react';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Steps, Space, Button, message, Form, StepsProps } from 'antd';
import styles from './style.module.scss';

interface Item {
  title?: string;
  content?: ReactNode;
  onSubmit?: () => boolean;
  onCancel?: () => void;
}

interface Props extends StepsProps {
  title?: ReactNode;
  steps?: Item[];
  style?: any;
  onClose?: () => void;
  onFinish?: () => Promise<void>;
}

const { Step } = Steps;

function BcStepForm(props: Props) {
  const { steps = [], onChange, onClose, onFinish, ...rest } = props;
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const baseInfo: DataFormLayoutInfoBlock = {
    column: 2,
    items: [
      {
        isFullLine: true,
        render: () => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Steps className={styles.stepWrapper} onChange={onChange} current={current} {...rest}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>
        ),
      },
      {
        isFullLine: true,
        render: () => <div className="steps-content">{steps[current].content}</div>,
      },
    ],
  };

  const next = () => {
    if (steps[current].onSubmit?.()) {
      setCurrent(current + 1);
      onChange?.(current);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
    onChange?.(current);
  };

  return (
    <DataFormLayout
      footer={
        <div className="steps-action" style={{ display: 'flex', justifyContent: 'center' }}>
          <Space>
            {current === 0 && <Button onClick={() => onClose?.()}>取消</Button>}
            {current > 0 && <Button onClick={prev}>上一步</Button>}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={next}>
                下一步
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                loading={loading}
                type="primary"
                onClick={async () => {
                  setLoading(true);
                  onFinish?.().finally(() => {
                    setLoading(false);
                  });
                }}
              >
                确定
              </Button>
            )}
          </Space>
        </div>
      }
      // footer={false}
      form={form}
      info={[baseInfo]}
      onFinish={() => {}}
      {...rest}
    />
  );
}

export default BcStepForm;
