import React, { useCallback, useEffect, useState } from 'react';
import { DataFormLayout } from 'layout';
import { Spin, Form, Input, Select, message, Badge } from 'antd';
import DataEditRender from './components/dataEdit';
import {
  fetchDataSetQueryDataSetDetail,
  FetchDataSetQueryDataSetDetailResponse,
  fetchDataSetDataSetStartStopProcess,
  fetchDataSetModifyDataSet,
  fetchDataSetDataSetPreviewProcess,
} from 'src/api/ytt/e-report-domain/dataSet';
import { gcUrl } from 'src/utils';
import lookup, { appDict, appEnum } from 'src/dict';
import { DetailTheme } from 'src/dict/customReport/mappings';
import _ from 'lodash';
import { NumberRulesStandardHook } from 'src/components';

export type DataSet = FetchDataSetQueryDataSetDetailResponse['data'];

const CreatAndEdit = (props: { history: any }) => {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [createForm] = Form.useForm();
  const [dataSet, setDataSet] = useState<DataSet>();
  const [detailTheme, setDetailTheme] = useState<any>();
  const [preivewFields, setPreivewFields] = useState<any>();
  const [isChangeField, setIsChangeField] = useState<boolean>(false);
  const { id, isCopy } = gcUrl.getParams();

  const baseInfo = {
    title: '基本信息',
    items: [
      ...NumberRulesStandardHook({
        label: '数据集编号',
        form: createForm,
        name: 'code',
        edit: id && !isCopy,
        objectCode: 'Dataset',
        fieldCode: 'code',
        isFullLine: true,
        style: { width: 600 },
        disabledState: id && !isCopy,
        rules: [],
      }),
      {
        label: '数据集名称',
        name: 'dataSetName',
        rules: [{ required: true }, { max: 255, message: '数据集名称不可超过255个字符' }],
        render: () => <Input placeholder={'请输入数据集名称'} />,
      },
      {
        label: '业务主题',
        name: 'bizTheme',
        render: () => (
          <Select
            placeholder={'请选择业务主题'}
            options={appDict.customReport.businessTheme}
            onChange={() => {
              createForm.setFieldsValue({ themeDetail: null });
            }}
          />
        ),
      },
      {
        label: '细分主题',
        name: 'themeDetail',
        dependencies: ['bizTheme'],
        render: () => (
          <Select
            placeholder={'请选择细分主题'}
            onFocus={() => {
              setDetailTheme(DetailTheme[createForm.getFieldValue('bizTheme')]);
            }}
            options={_.map(detailTheme, (label: string, value: number) => ({
              label,
              value: Number(value),
            }))}
          />
        ),
      },
      {
        label: '数据集状态',
        name: 'bizSetStatus',
        render: () => {
          return (
            <Badge
              status={
                dataSet?.bizSetStatus === appEnum.CustomReport.STATUS.enabled ? 'success' : 'error'
              }
              text={lookup(
                'customReport.status',
                dataSet?.bizSetStatus || appEnum.CustomReport.STATUS.disabled,
              )}
            />
          );
        },
      },
      {
        label: '数据集说明',
        name: 'remark',
        rules: [{ max: 50, message: '数据集说明不可超过50个字符' }],
        render: () => <Input.TextArea placeholder={'请输入数据集说明'} />,
      },
    ],
  };

  const dataInfo = useCallback(
    () => ({
      title: '',
      items: [
        {
          label: '',
          isFullLine: true,
          render: () => (
            <DataEditRender
              isEdit={Boolean(id)}
              form={createForm}
              dataSetData={dataSet}
              isCopy={isCopy}
              setDataSet={setDataSet}
              refreshDataSet={(id: number) => fetchData(id, false)}
              setPreivewFields={setPreivewFields}
              isChangeField={isChangeField}
              setIsChangeField={setIsChangeField}
            />
          ),
        },
      ],
    }),
    [dataSet, isChangeField],
  );

  const saveData = async () => {
    try {
      const baseData = await createForm.validateFields();
      const fields = _.filter(
        preivewFields?.map((field: { fieldId: string | number; objectNodeId: number }) => ({
          ...field,
          checked: Number(
            createForm.getFieldValue([
              'fieldList',
              `${field.fieldId}${field.objectNodeId}`,
              'checked',
            ]),
          ),
          fieldDisplayName: createForm.getFieldValue([
            'fieldList',
            `${field.fieldId}${field.objectNodeId}`,
            'fieldDisplayName',
          ]),
        })),
        (field) => field.fieldId,
      )?.filter((i) => i.checked);

      delete dataSet?.previewFieldInfos;

      /** 更新数据集内容 */
      await fetchDataSetModifyDataSet({
        ...dataSet,
        ...baseData,
        dataSetId: dataSet?.id,
        themeDetail: baseData?.themeDetail || 0,
      });

      /** 更新预览字段 */
      await fetchDataSetDataSetPreviewProcess({
        dataSetId: dataSet?.id,
        previewInfos: fields,
        saveProcess: true,
      });

      /** 创建数据集修改数据集为停用状态 */
      if (!id || isCopy) {
        await fetchDataSetDataSetStartStopProcess({
          dataSetStatus: appEnum.CustomReport.STATUS.disabled,
          dataSetIdList: [dataSet?.id],
        });
      }
      props.history.push('/analyse/dataset/manage');
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = async () => {
    if (createForm.getFieldValue('isUnSave')) {
      message.error('请先保存对象视图');
      return;
    }
    setSubmitting(true);
    await saveData();
    setSubmitting(false);
  };

  const fetchData = async (_id: number, isFirstLoad = true) => {
    setIsloading(isFirstLoad);
    const { data } = await fetchDataSetQueryDataSetDetail({ dataSetId: _id });

    if (isCopy) {
      const { objectNodeInfoList, objectRelationShipInfoList, ...rest } = data || {};
      const newData = {
        ...rest,
        objectNodeInfoList: objectNodeInfoList?.map(({ id, dataSetId, ...rest }) => ({
          ...rest,
          id: !isFirstLoad ? id : null,
        })),
        objectRelationShipInfoList: objectRelationShipInfoList?.map(
          ({ id, dataSetId, ...rest }) => ({
            ...rest,
            id: !isFirstLoad ? id : null,
          }),
        ),
      };

      if (isFirstLoad) {
        delete newData.id;
      }
      setDataSet(newData);
    } else {
      setDataSet(data);
    }
    setDetailTheme(DetailTheme[data?.bizTheme]);
    setPreivewFields(data?.previewFieldInfos || []);
    createForm.setFieldsValue({
      ...data,
      code: isCopy && isFirstLoad ? null : data?.code,
      fieldList: _.keyBy(
        data?.previewFieldInfos,
        (field) => `${field.fieldId}${field.objectNodeId}`,
      ),
      themeDetail: DetailTheme[data?.bizTheme] && data.themeDetail ? data.themeDetail : null,
      bizTheme: DetailTheme[data?.bizTheme] ? data.bizTheme : null,
    });
    setIsloading(false);
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
    if (isCopy) {
      createForm.setFieldsValue({ isUnSave: true });
    }
  }, []);

  return isLoading ? (
    <div style={{ width: '100%' }}>
      <Spin style={{ width: '100%' }} />
    </div>
  ) : (
    <DataFormLayout
      title={`${id ? `${isCopy ? '复制' : '编辑'}` : '新建'}数据集`}
      form={createForm}
      info={[baseInfo, dataInfo()]}
      onFinish={handleFinish}
      onCancel={() => props.history.push('/analyse/dataset/manage')}
      confirmLoading={submitting}
      formProps={{
        scrollToFirstError: true,
        onValuesChange: (changedValues: any) => {
          if (changedValues.fieldList) {
            setIsChangeField(true);
          }
        },
      }}
    />
  );
};

export default CreatAndEdit;
