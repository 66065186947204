import { nodeStateStyles, ReletiveLevel } from './config';
import { getOverflowText } from './utils';
import _ from 'lodash';

export const Node = {
  draw: (cfg: any, group: any) => {
    const relativeLevel: number = cfg.relativeLevel;
    const styles: any = _.get(nodeStateStyles, relativeLevel) || {};

    const keyShape = group.addShape('circle', {
      attrs: {
        cursor: 'pointer',
        ...styles.style,
      },
      name: 'circle',
      draggable: true,
    });

    group.addShape('text', {
      attrs: {
        x: 0,
        y: relativeLevel === ReletiveLevel.xs ? 0 : -10,
        text: getOverflowText(cfg?.batchNo || cfg?.qrCode),
        textAlign: 'center',
        textBaseline: 'middle',
        ...styles.labelCfg,
      },
      name: 'code-text',
      draggable: true,
    });
    if (relativeLevel !== ReletiveLevel.xs) {
      group.addShape('text', {
        attrs: {
          x: 0,
          y: 10,
          text: getOverflowText(cfg?.material?.name),
          textAlign: 'center',
          textBaseline: 'middle',
          ...styles.subLabelcfg,
        },
        name: 'material-text',
        draggable: true,
      });
    }

    return keyShape;
  },
  setState: (name: any, value: any, item: any) => {
    const group = item.get('group');

    if (name === 'hover') {
      const circle = group.find((e: any) => e.get('name') === 'circle');
      const codeText = group.find((e: any) => e.get('name') === 'code-text');
      const materialText = group.find((e: any) => e.get('name') === 'material-text');
      const relativeLevel: number = item._cfg.model.relativeLevel;
      const style = nodeStateStyles[ReletiveLevel.lg] || {};
      const itemStyle: any = _.get(nodeStateStyles, relativeLevel) || {};

      if (value) {
        circle?.attr('fill', style.hover.fill);
        circle?.attr('stroke', style.hover.stroke);
        codeText?.attr('fill', style.hover.labelFill);
        materialText?.attr('fill', style.hover.labelFill);
      } else {
        circle?.attr('fill', itemStyle.style.fill);
        circle?.attr('stroke', itemStyle.style.stroke);
        codeText?.attr('fill', itemStyle.labelCfg.fill);
        materialText?.attr('fill', itemStyle.subLabelcfg.fill);
      }
    }

    if (name === 'disable') {
      if (value) {
        group.attr('opacity', 0.2);
      } else {
        group.attr('opacity', 1);
      }
    }
  },
};
