import {
  StatusEnum,
  MergePrinciples,
  TransferOrderBusinessType,
  PickOrderType,
  CalcRange,
  ProvidedRange,
} from '.';
import { mappingsFactory } from '../utils';

export const StatusDisplayMap = mappingsFactory(
  ['新建', StatusEnum.new],
  ['已关闭', StatusEnum.closed],
);

export const MergePrinciplesDisplayMap = mappingsFactory(
  ['发出仓库+接收仓库', MergePrinciples.warehouses],
  ['物料', MergePrinciples.materials],
  ['需求时间', MergePrinciples.requireTime],
);

export const TransferOrderBusinessTypeMap = mappingsFactory(
  ['仓储调拨', TransferOrderBusinessType.inventory],
  ['领料调拨', TransferOrderBusinessType.storeRequisition],
);

export const PickOrderTypeMap = mappingsFactory(
  ['普通领料', PickOrderType.common],
  ['生产领料', PickOrderType.product],
);

export const CalcRangeMap = mappingsFactory(
  ['“计划确认”状态的计划订单', CalcRange.planOrder],
  ['“新建、已下发、执行中”且“未暂停”的生产工单', CalcRange.produceOrder],
);

export const ProvidedRangeMap = mappingsFactory(
  ['领料申请待收料数量', ProvidedRange.toReceive],
  ['车间仓库库存数量', ProvidedRange.workshopStorage],
);
