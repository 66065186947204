import React, { useState, useEffect } from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import TableTransfer from '../share/TableTransfer';
import { CheckItemType } from '../../checkItem/interface';
import { Pagination } from '../interface';
import { fetchQcCheckItemPageList } from 'src/api/ytt/quality-domain/checkItem';

interface TableTransferComponentProps {
  leftTableColumns: ColumnsType; // 左列Columns
  rightTableColumns: ColumnsType;
  targetKeysSource: any[]; // 显示在右列的目标数据的key集合
  setTargetSourceFn: (keys: []) => void;
  changeSetDisabled: () => void;
}

const defaultPagination = {
  page: 1,
  size: 10,
};

const UseTableTransferComponent = (props: TableTransferComponentProps) => {
  const {
    leftTableColumns,
    rightTableColumns,
    targetKeysSource,
    setTargetSourceFn,
    changeSetDisabled,
  } = props;

  const [allItemSource, setAllItemSource] = useState<any>([]);
  const [targetKeys, setTargetKeys] = useState<any[]>([]);
  const [pagination, setPagination] = useState<Pagination>(defaultPagination);

  const getCheckItemData = async (params: any) => {
    const { data } = await fetchQcCheckItemPageList({ ...params });

    setAllItemSource(data?.list);
  };

  useEffect(() => {
    getCheckItemData({ page: 1, size: 1000000 });

    setTargetKeys(targetKeysSource);
  }, []);

  const onChange = (nextTargetKeys: any[]) => {
    const targetSourceData = allItemSource?.filter((node: CheckItemType) =>
      nextTargetKeys.includes(node?.id),
    );

    setTargetKeys(nextTargetKeys);
    changeSetDisabled();
    setTargetSourceFn(targetSourceData); // 发送onChange实时拿到目标source但不点击确定不会真的保存
  };

  const handleTableChange = (_pagination: any) => {
    const { current, pageSize } = _pagination;

    setPagination({
      page: current,
      size: pageSize,
    });
  };

  return (
    <>
      <TableTransfer
        locale={{ searchPlaceholder: '请输入检验项编号/名称/分类搜索' }}
        listStyle={{}}
        rowKey={(record: any) => record?.id}
        dataSource={allItemSource}
        targetKeys={targetKeys}
        showSearch
        onChange={onChange}
        filterOption={(inputValue, item) =>
          item?.categoryName?.indexOf(inputValue) !== -1 ||
          item?.code?.indexOf(inputValue) !== -1 ||
          item?.name?.indexOf(inputValue) !== -1
        }
        titles={['可选', '已选']}
        operations={['加入右侧', '加入左侧']}
        leftColumns={leftTableColumns}
        rightColumns={rightTableColumns}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
          ...pagination,
        }}
        handleTableChange={handleTableChange}
      />
    </>
  );
};

export default UseTableTransferComponent;
