import React, { useRef, useState } from 'react';
import { FilterItem, RecordListLayout } from 'layout';
import type { OperationListItem } from '@blacklake-web/layout';
import { fieldTypeList, gcArray, gcTime } from 'src/utils';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { Badge, Button, Form, message, Modal } from 'antd';
import _ from 'lodash';
import {
  fetchQcTaskList,
  FetchQcTaskListRequest,
  fetchQcTaskCancel,
  fetchQcTaskGetTask,
} from 'src/api/ytt/quality-domain/qc_task';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { QcTask } from 'src/page/quality/models/dto';
import { getQcConfigDetailUrl } from 'src/page/quality/qcConfig/navigator';
import { BlIcon, LinkTooltip, SearchSelect, showErrorListMessage, TagList } from 'src/components';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import lookup, { appDict } from 'src/dict';
import {
  customLayoutChooseButton,
  customLayoutCreateButton,
  EditButton,
} from 'src/components/customLayout/hooks/customLayoutForButton';
import { AssignStatusEnum, QcTaskStatusEnum } from 'src/dict/quality';
import { assignStatus, qcTaskStatus } from 'src/dict/quality/mappings';
import { fetchMatchRuleQueryMatchTemplateBatch } from 'src/api/ytt/e-report-domain/matchRule';
import { blueViolet, errorRed } from 'src/styles/color';
import LocalStorage from 'src/utils/localStorage';
import { FIELDS } from 'src/utils/constants';
import { getTaskEditUrl } from '../../models/utils/qcTask';
import ChooseTemplateModal from 'src/page/analyse/objectMatchingRule/share/chooseTemplateModal';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
const QcTaskList = (props: { history: any }) => {
  const [selectRowKeys, setSelectRowKeys] = useState<(string | number)[]>([]);
  const refreshCountRef = useRef(0);
  const [delForm] = Form.useForm();
  const [refreshMarker, setRefreshMarker] = useState<number | undefined>(undefined);
  const [chooseVisible, setChooseVisible] = useState<boolean>(false);
  const [templateData, setTemplateData] = useState<any>([]);
  const { history } = props;

  const isHidden = ['hw', 'ali', 'xbmes', 'qcloud'].filter((envKeyword) => {
    return location.host.includes(envKeyword);
  })?.length;
  const toDetail = (id: number, layoutId: number) =>
    history.push(`/quality/qualityExecution/qcTask/detail?id=${id}&layoutId=${layoutId}`);

  const toExecute = (id: number) => history.push(`/quality/qualityExecution/qcTask/execute/${id}`);

  const toCheckTaskCreate = (layoutId: number, bizType: number) =>
    history.push(`/quality/qualityExecution/qcTask/create?layoutId=${layoutId}&bizType=${bizType}`);

  const toCheckTaskEdit = (id: number, layoutId: number, bizType: number) => {
    history.push(getTaskEditUrl(id, layoutId, bizType));
  };

  const cancelTask = (ids: number[]) => fetchQcTaskCancel({ ids });

  const fetchData = async (params: any) => {
    const res = await fetchQcTaskList(params, { legacy: true });

    return res;
  };

  const getColumns = () => {
    return [
      {
        title: '检验任务编号',
        dataIndex: 'code',
        width: 150,
      },
      {
        title: '业务类型',
        dataIndex: 'checkType',
        width: 120,
        render: lookup('quality.checkType'),
      },
      {
        title: '物料编号',
        dataIndex: 'materialCode',
        width: 150,
      },
      {
        title: '物料名称',
        dataIndex: 'materialName',
        width: 150,
      },
      {
        title: '检验方案编号',
        dataIndex: 'configCode',
        width: 150,
        render: (code: string, record: QcTask, index: number, config: any) => (
          <LinkTooltip
            to={getQcConfigDetailUrl(Number(record.qcConfigSnapshotId), true)}
            text={`${code}`}
            width={config.contentWidth}
            auth={authDict.qualityinspectionscheme_detail}
          />
        ),
      },
      {
        title: '检验方案名称',
        dataIndex: 'configName',
        width: 150,
        render: (configName: string, record: QcTask, index: number, config: any) => (
          <LinkTooltip
            to={getQcConfigDetailUrl(Number(record.qcConfigSnapshotId), true)}
            text={configName}
            width={config.contentWidth}
            auth={authDict.qualityinspectionscheme_detail}
          />
        ),
      },
      {
        title: '任务状态',
        dataIndex: 'status',
        width: 120,
        render: (status: QcTaskStatusEnum) => (
          <span>
            {status === QcTaskStatusEnum.unstarted && <Badge color={'yellow'} />}
            {status === QcTaskStatusEnum.finished && <Badge color={blueViolet} />}
            {status === QcTaskStatusEnum.executing && <Badge status={'success'} />}
            {status === QcTaskStatusEnum.cancled && <Badge color={errorRed} />}
            {lookup('quality.qcTaskStatus', status)}
          </span>
        ),
      },
      {
        title: '结论判定',
        dataIndex: 'inspectionResult',
        width: 120,
        render: lookup('quality.inspectionResultOptions'),
      },
      {
        title: '创建人',
        dataIndex: 'creator',
        width: 150,
        render: (value: any) => {
          if (!value) return '-';
          return (
            <AvatarDisplay
              id={value.id}
              isShowTag={false}
              name={value.name}
              avatarUrl={value.avatarUrl}
              isUser
            />
          );
        },
      },
      {
        title: '领取状态',
        dataIndex: 'getStatus',
        width: 120,
        render: (status: AssignStatusEnum) => (
          <span>
            {status === AssignStatusEnum.unAssign && <Badge status={'success'} />}
            {status === AssignStatusEnum.assigned && <Badge status="default" />}
            {lookup('quality.assignStatus', status)}
          </span>
        ),
      },
      {
        title: '执行人',
        dataIndex: 'executor',
        width: 150,
        render: (value: any) => {
          if (!value) return '-';
          return (
            <AvatarDisplay
              id={value.id}
              isShowTag={false}
              name={value.name}
              avatarUrl={value.avatarUrl}
              isUser
            />
          );
        },
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        sorter: true,
        width: 180,
        render: (date: number) => gcTime.formatLine(date),
      },
      {
        title: '开始时间',
        dataIndex: 'startTime',
        sorter: true,
        width: 180,
        render: (date: number) => (date ? gcTime.formatLine(date) : '-'),
      },
      {
        title: '结束时间',
        dataIndex: 'endTime',
        sorter: true,
        width: 180,
        render: (date: number) => (date ? gcTime.formatLine(date) : '-'),
      },
      {
        title: '取消时间',
        dataIndex: 'cancelTime',
        sorter: true,
        width: 180,
        render: (date: number) => (date ? gcTime.formatLine(date) : '-'),
      },
      {
        title: '更新人',
        dataIndex: 'operator',
        width: 180,
        render: (value: any) => {
          if (!value) return '-';
          return (
            <AvatarDisplay
              id={value.id}
              isShowTag={false}
              name={value.name}
              avatarUrl={value.avatarUrl}
              isUser
            />
          );
        },
      },
      {
        title: '更新时间',
        width: 180,
        dataIndex: 'updatedAt',
        width: 180,
        render: (date: number) => gcTime.formatLine(date),
      },
      {
        title: '可领取人',
        dataIndex: 'candidates',
        width: 200,
        render: (list: { id: number; name: string }[] | null) => {
          if (!list?.length) return '-';
          return (
            <TagList dataSource={list} maxShow={1} isUserTag valuePath="id" labelPath="name" />
          );
        },
      },
      {
        title: '可领取部门',
        dataIndex: 'candidateDeps',
        width: 200,
        render: (list: { id: number; name: string }[] | null) => {
          if (!list?.length) return '-';
          return <TagList dataSource={list} maxShow={1} valuePath="id" labelPath="name" />;
        },
      },
    ];
  };

  const filterList: FilterItem[] = [
    {
      label: '业务类型',
      name: 'checkType',
      type: fieldTypeList.select,
      selectProps: {
        options: appDict.quality.checkType,
      },
    },
    {
      label: '任务状态',
      name: 'status',
      type: fieldTypeList.select,
      selectProps: {
        options: qcTaskStatus,
      },
    },
    {
      label: '执行人',
      name: 'executor',
      type: fieldTypeList.select,
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple={false} isSelectUser />,
    },
    {
      label: '检验方案',
      name: 'qcConfig',
      type: fieldTypeList.select,
      renderItem: (
        <SearchSelect fetchType="qcConfigList" mode="multiple" labelPath={['code', 'name']} />
      ),
    },
    {
      label: '关联物料',
      name: 'material',
      type: fieldTypeList.select,
      renderItem: (
        <SearchSelect fetchType="material" mode="multiple" labelPath={['code', 'name']} />
      ),
    },
    {
      label: '关联工序',
      name: 'process',
      type: fieldTypeList.select,
      renderItem: <SearchSelect fetchType="process" mode="multiple" labelPath={['code', 'name']} />,
    },
    {
      label: '关联设备',
      name: 'resource',
      type: fieldTypeList.select,
      renderItem: (
        <SearchSelect fetchType="resource" labelPath={['code', 'name']} mode="multiple" />
      ),
    },
    {
      label: '关联供应商',
      name: 'supplier',
      type: fieldTypeList.select,
      renderItem: (
        <SearchSelect fetchType="supplier" mode="multiple" labelPath={['code', 'name']} />
      ),
    },
    {
      label: '关联客户',
      name: 'customer',
      type: fieldTypeList.select,
      renderItem: <SearchSelect fetchType="customer" mode="multiple" />,
    },
    {
      label: '创建时间',
      name: 'createdAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '开始时间',
      name: 'startTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '更新时间',
      name: 'updatedAt',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '结束时间',
      name: 'endTime',
      type: fieldTypeList.date,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    {
      label: '领取状态',
      name: 'getStatus',
      type: fieldTypeList.multiSelect,
      selectProps: {
        options: assignStatus,
      },
    },
    {
      label: '可领取人',
      name: 'candidates',
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple isSelectUser />,
    },
    {
      label: '可领取部门',
      name: 'candidateDeps',
      renderItem: <UserOrDepartmentSelectWithDialog isMultiple />,
    },
  ];

  const refreshTable = () => {
    // 刷新列表页
    refreshCountRef.current++;
    setRefreshMarker(refreshCountRef.current);
  };

  const formatDataToQuery = (
    params: {
      startTime?: string[];
      createdAt?: string[];
      qcConfigs?: { label: string; value: number }[];
      customer?: { label: string; value: number }[];
      material?: { label: string; value: number }[];
      process?: { label: string; value: number }[];
      resource?: { label: string; value: number }[];
      supplier?: { label: string; value: number }[];
      qcConfig?: { label: string; value: number }[];
      executor?: { label: string; value: number }[];
      candidateDeps?: { label: string; value: number }[];
      updatedAt?: string[];
      endTime?: string[];
      candidates?: { label: string; value: number }[];
    } = {},
  ) => {
    const {
      startTime,
      createdAt,
      qcConfigs,
      customer,
      material,
      process,
      resource,
      supplier,
      qcConfig,
      updatedAt,
      endTime,
      executor,
      candidateDeps,
      candidates,
      ...rest
    } = params;
    const formData: FetchQcTaskListRequest = { ...rest };

    if (executor) {
      formData.executor = _.last(executor)?.value;
    }
    if (candidateDeps) {
      formData.candidateDepIds = _.map(candidateDeps, 'value');
    }
    if (candidates) {
      formData.candidateIds = _.map(candidates, 'value');
    }
    if (startTime) {
      [formData.startTimeFrom, formData.startTimeTo] = gcTime
        .formatTimeRangeUnix(startTime)
        ?.map(Number);
    }
    if (createdAt) {
      [formData.createdAtFrom, formData.createdAtTo] = gcTime
        .formatTimeRangeUnix(createdAt)
        ?.map(Number);
    }
    if (updatedAt) {
      [formData.updatedAtFrom, formData.updatedAtTo] = gcTime
        .formatTimeRangeUnix(updatedAt)
        ?.map(Number);
    }
    if (endTime) {
      [formData.endTimeFrom, formData.endTimeTo] = gcTime.formatTimeRangeUnix(endTime)?.map(Number);
    }
    if (qcConfigs) {
      formData.qcConfigIds = qcConfigs.map((qcConfig) => qcConfig.value);
    }
    if (!gcArray.isEmpty(customer)) {
      formData.customerIds = _.map(customer, 'value');
    }
    if (!gcArray.isEmpty(material)) {
      formData.materialIds = _.map(material, 'value');
    }
    if (!gcArray.isEmpty(process)) {
      formData.processIds = _.map(process, 'value');
    }
    if (!gcArray.isEmpty(supplier)) {
      formData.supplierIds = _.map(supplier, 'value');
    }
    if (!gcArray.isEmpty(resource)) {
      formData.equipmentIds = _.map(resource, 'value');
    }
    if (!gcArray.isEmpty(qcConfig)) {
      formData.qcConfigIds = _.map(qcConfig, 'value');
    }

    return formData;
  };

  const getMatchTemplate = async (instanceId: string) => {
    try {
      const { data } = await fetchMatchRuleQueryMatchTemplateBatch({
        instanceId,
        objectCodeList: ['QualityInspectionTask', 'InspectionItemRecord'], // 目前支持检验任务和检验项记录。预览入口都是在检验任务 但是根据匹配规则查询模板可以是检验任务或检验项记录作为根对象 后期再增加直接在这个参数追加即可
        bizObjectCode: 'QualityInspectionTask',
      });

      if (data) {
        setTemplateData(
          _.map(data, (e: any) => {
            return {
              ...e,
              objectCode: e?.rootObjectCode, // 定义一个objectCode是因为在自定义对象那边用的另一个接口没有返回rootObjectCode关于对象code的数据  便于选择模板的时候两个接口兼容
              instanceId, // 实例id
            };
          }),
        );

        setChooseVisible(true);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const left = [
    {
      title: 'create',
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: authDict.qualityinspectiontask_add,
      customRender: customLayoutCreateButton({
        title: '新建检验任务',
        icon: <BlIcon type="iconxinjiantianjia" />,
        objectCode: 'QualityInspectionTask',
        type: 'create',
        onClick: (layoutId, bizType) => {
          if (bizType && layoutId) {
            toCheckTaskCreate(layoutId, bizType!);
          }
        },
      }),
    },
  ];

  const config = {
    mainMenu: isHidden ? [] : left,
  };

  const getOperationList = (record: QcTask): OperationListItem[] => {
    const user = LocalStorage.get(FIELDS.USER);

    return _.compact([
      {
        title: 'view',
        customRender: customLayoutChooseButton({
          title: '查看',
          objectCode: OBJECT_OF_CODE.qcTask,
          type: 'detail',
          onClick: (layoutId) => toDetail(Number(record?.id), layoutId),
          instanceId: record?.id,
        }),
        auth: authDict.qualityinspectiontask_detail,
      },
      !isHidden && {
        title: 'edit',
        auth: authDict.qualityinspectiontask_edit,
        customRender:
          record?.status === QcTaskStatusEnum.unstarted ? (
            <EditButton
              title="编辑"
              instanceId={record?.id}
              objectCode={OBJECT_OF_CODE.qcTask}
              type={'edit'}
              buttonType="link"
              onClick={(layoutId) => {
                toCheckTaskEdit(Number(record?.id), layoutId, record?.checkType!);
              }}
            />
          ) : (
            <Button disabled type="link">
              编辑
            </Button>
          ),
      },
      {
        title: '打印',
        auth: authDict.qualityinspectiontask_print,
        onClick() {
          getMatchTemplate(String(record?.id));
        },
      },
      !isHidden && {
        title: '领取',
        auth: authDict.qualityinspectiontask_receive,
        disabled:
          record.getStatus === AssignStatusEnum.assigned ||
          record.status === QcTaskStatusEnum.cancled,
        onClick: () => {
          Modal.confirm({
            title: '确定领取当前任务吗？',
            onOk: () => {
              fetchQcTaskGetTask({ id: record?.id }).then(({ code }) => {
                if (code === 200) {
                  message.success('领取成功');
                  toExecute(Number(record?.id));
                } else {
                  message.error('领取失败');
                }
              });
            },
          });
        },
      },
      !isHidden && {
        title: '执行',
        disabled:
          record.getStatus !== AssignStatusEnum.assigned ||
          record.status === QcTaskStatusEnum.cancled ||
          record.status === QcTaskStatusEnum.finished ||
          user.id !== record?.executor?.id,
        onClick: () => toExecute(Number(record?.id)),
      },
      {
        title: '取消',
        disabled:
          record.status !== QcTaskStatusEnum.unstarted &&
          record.status !== QcTaskStatusEnum.executing,
        onClick: () => {
          BLDelConfirm('是否确认取消该任务？', delForm, () => {
            return cancelTask([Number(record?.id)]).then((res) => {
              if (res.code === 200) {
                message.success('取消成功');
                refreshTable();
              }
            });
          });
        },
        auth: authDict.qualityinspectiontask_cancel,
      },
    ]);
  };

  const batchMenu = [
    {
      title: '取消',
      auth: authDict.qualityinspectiontask_cancel,
      onClick: (onSuccess: any, onFail: any) => {
        BLDelConfirm(
          '是否确认取消选中任务？',
          delForm,
          () => {
            return cancelTask(selectRowKeys?.map(Number)).then((res) => {
              if (res.code === 200 && res?.data?.failCount === 0) {
                message.success('取消成功');
                onSuccess();
              } else {
                const { failCount, successCount, failDetails } = res.data || {};

                onFail();
                showErrorListMessage({
                  failCount,
                  successCount,
                  title: '取消出现失败',
                  width: 800,
                  data:
                    failDetails?.map((item, index) => {
                      return { ...item, num: index + 1 };
                    }) || [],
                  desc: `取消成功：${successCount}个，取消失败：${failCount}个，具体失败原因如下：`,
                  columns: [
                    {
                      title: '检验任务编号',
                      dataIndex: 'code',
                      width: 150,
                    },
                    {
                      title: '失败原因',
                      dataIndex: 'reason',
                      width: 300,
                      render: (reason: string) => reason || '-',
                    },
                  ],
                  onOk: () => onSuccess(),
                });
              }
            });
          },
          onFail(),
        );
      },
    },
  ];

  const formatToForm = (data: any) => {
    if (data?.updatedAt) {
      data.updatedAt = gcTime.formatRangeTimeToMoment(data?.updatedAt);
    }
    if (data?.endTime) {
      data.endTime = gcTime.formatRangeTimeToMoment(data?.endTime);
    }
    return data;
  };

  return (
    <>
      <RecordListLayout<QcTask>
        columns={getColumns()}
        batchMenu={batchMenu}
        filterList={filterList}
        formatDataToQuery={formatDataToQuery}
        formatDataToFormDisplay={formatToForm}
        getOperationList={getOperationList}
        requestFn={fetchData}
        refreshMarker={refreshMarker}
        placeholder={'输入任务编号、物料名称、物料编号搜索'}
        userAuth={getAuthFromLocalStorage()}
        onSelectedRowKeys={(selectKey) => {
          setSelectRowKeys(selectKey);
        }}
        selectedRowKeys={selectRowKeys}
        {...config}
      />
      {chooseVisible && (
        <ChooseTemplateModal
          visible={chooseVisible}
          initData={templateData}
          onCancel={() => {
            setChooseVisible(false);
          }}
        />
      )}
    </>
  );
};

export default QcTaskList;
