import { Popover, Divider, Descriptions } from 'antd';
import { SearchSelect } from 'src/components';
import { FetchBatchNumberItem } from '../type';
import _Time from 'src/utils/dataTypes/time';
import { replaceSign } from 'src/utils/constants';
import CreateAndEdit from '../createAndEdit';
import { BlIcon, BlSearchSelectProps, TextToolTip } from '@blacklake-web/component';
import { SelectValue } from 'antd/lib/select';
import authDict from 'src/utils/auth';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { getDetailCustomFieldItem } from 'src/components/customField';

interface Props<VT> extends Omit<BlSearchSelectProps<VT>, 'fetchFn' | 'formatter'> {
  /**
   *  默认value的值为batchNo;
   *  rendervalue='all'时，value的值则返回一个 所有字段 的字符串
   */
  rendervalue?: 'all' | 'default';
  materialinfo?: any;
  enableAdd?: Boolean;
  saveCallback?: (data: any) => void;
}
const BatchSearchSelect = <VT extends SelectValue>(props: Props<VT>) => {
  const { rendervalue = 'default', enableAdd = true, materialinfo, saveCallback } = props;
  const [visitable, setVisitable] = useState<boolean>(false);

  return (
    <>
      <SearchSelect
        fetchType="batchList"
        onDropdownVisibleChangeFn={(open, refresh) => {
          if (open) {
            refresh('');
          }
        }}
        dropdownRender={(menu) => {
          return (
            <div>
              {enableAdd && authDict.batchno_add && (
                <>
                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: '6px 12px' }}>
                    <a
                      style={{
                        width: '100%',
                        flex: 'none',
                        display: 'block',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setVisitable(true);
                      }}
                    >
                      <BlIcon type="icontianjia" /> 新建批次
                    </a>
                  </div>
                  <Divider style={{ margin: '4px 0' }} />
                </>
              )}
              {menu}
            </div>
          );
        }}
        renderOption={(item: FetchBatchNumberItem) => {
          return {
            label: (
              <Popover
                placement="rightTop"
                content={
                  <div style={{ maxHeight: '100vh', overflow: 'auto' }}>
                    <Descriptions size="small" bordered column={1} title="">
                      <Descriptions.Item label="批次号">
                        {item?.batchNo || replaceSign}
                      </Descriptions.Item>
                      <Descriptions.Item label="客户">
                        {item?.customer?.name || replaceSign}
                      </Descriptions.Item>
                      <Descriptions.Item label="供应商">
                        {item?.supplier?.name || replaceSign}
                      </Descriptions.Item>
                      <Descriptions.Item label="供应商批次">
                        {item?.supplierBatch || replaceSign}
                      </Descriptions.Item>
                      <Descriptions.Item label="生产日期">
                        {item?.produceAt ? _Time.formatDate(item?.produceAt) : replaceSign}
                      </Descriptions.Item>
                      <Descriptions.Item label="有效期至">
                        {item?.validTo ? _Time.formatDate(item?.validTo) : replaceSign}
                      </Descriptions.Item>
                      {!isEmpty(item?.customFields) &&
                        item?.customFields?.map((fields) => {
                          const fieldItem: any = getDetailCustomFieldItem(fields);

                          return (
                            <Descriptions.Item label={fieldItem?.label}>
                              <TextToolTip
                                text={fieldItem?.format(fields?.fieldValue)}
                                width={150}
                              />
                            </Descriptions.Item>
                          );
                        })}
                    </Descriptions>
                  </div>
                }
              >
                <div style={{ width: '100%' }}>{item?.batchNo}</div>
              </Popover>
            ),
            value: rendervalue === 'default' ? item?.batchNo! : JSON.stringify(item),
            key: rendervalue === 'default' ? item?.batchNo! : JSON.stringify(item),
          };
        }}
        {...props}
      />
      {visitable && (
        <CreateAndEdit
          visible={visitable}
          materialinfo={materialinfo}
          saveCallback={saveCallback}
          onCancel={() => setVisitable(false)}
        />
      )}
    </>
  );
};

export default BatchSearchSelect;
