import { FormInstance, Form, DatePicker, Modal, Table } from 'antd';
import { Moment } from 'moment';
import { DataFormLayoutForModal, DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { ColumnsType } from 'antd/es/table';
import { GetElementType } from 'src/types';

import { FetchRepairTaskUpdatePlanTimeBatchResponse } from 'src/api/ytt/resource-domain/repairTask';
import { fieldLabels } from '../constants';
import _ from 'lodash';

interface AdjustPlanTimeModalProps {
  visible: boolean;
  resultHandler?: { onSuccess?: () => void; onFail?: () => void };
  onAdjust: (planTimes: { planStartTime?: Moment; planEndTime?: Moment }) => void;
}

interface AdjustPlanTimeContentProps {
  form: FormInstance;
  onAdjust: (planTimes: { planStartTime?: Moment; planEndTime?: Moment }) => void;
  onCancel: () => void;
}

type ErrorDetailType = GetElementType<
  NonNullable<FetchRepairTaskUpdatePlanTimeBatchResponse['data']>['detail']
>;

const resultColumns: ColumnsType<ErrorDetailType> = [
  {
    title: fieldLabels.code,
    ellipsis: true,
    width: 200,
    dataIndex: 'code',
  },
  {
    title: fieldLabels.name,
    ellipsis: true,
    width: 200,
    dataIndex: 'name',
  },
  {
    title: '失败原因',
    width: 300,
    dataIndex: 'failReason',
  },
];

const AdjustPlanTimeContent = ({ form, onAdjust, onCancel }: AdjustPlanTimeContentProps) => {
  const timeInfo: DataFormLayoutInfoBlock = {
    title: '调整计划时间',
    items: [
      {
        noStyle: true,
        dependencies: ['planEndTime'],
        render: (formItemStyles) => () => {
          const planEndTime = form.getFieldValue('planEndTime');

          return (
            <Form.Item {...formItemStyles} label={fieldLabels.planStartTime} name="planStartTime">
              <DatePicker
                format="YYYY-MM-DD HH:mm:ss"
                showTime
                style={{ width: '100%' }}
                disabledDate={(date) => planEndTime && date.isAfter(planEndTime)}
              />
            </Form.Item>
          );
        },
      },
      {
        noStyle: true,
        dependencies: ['planStartTime'],
        render: (formItemStyles) => () => {
          const planStartTime = form.getFieldValue('planStartTime');

          return (
            <Form.Item {...formItemStyles} label={fieldLabels.planEndTime} name="planEndTime">
              <DatePicker
                format="YYYY-MM-DD HH:mm:ss"
                showTime
                style={{ width: '100%' }}
                disabledDate={(date) => planStartTime && date.isSameOrBefore(planStartTime)}
              />
            </Form.Item>
          );
        },
      },
    ],
  };

  const handleFinish = () => {
    const planTimes = form.getFieldsValue();

    if (
      _.isNil(planTimes) ||
      (_.isNil(planTimes.planStartTime) && _.isNil(planTimes.planEndTime))
    ) {
      onCancel();
      return;
    }

    onAdjust(planTimes);
  };

  return (
    <DataFormLayout form={form} info={[timeInfo]} onFinish={handleFinish} onCancel={onCancel} />
  );
};

export const showAdjustError = (
  result: NonNullable<FetchRepairTaskUpdatePlanTimeBatchResponse['data']>,
) => {
  const { failNum, successNum, detail } = result;

  Modal.error({
    okText: '知道了',
    title: '调整出现失败',
    width: 1000,
    content: (
      <div>
        <div>
          调整成功数： {successNum}，调整失败数：{failNum}，具体失败明细如下：
        </div>
        <Table columns={resultColumns} dataSource={detail} />
      </div>
    ),
  });
};

export default function AdjustPlanTimeModal({
  visible,
  resultHandler,
  onAdjust,
}: AdjustPlanTimeModalProps) {
  const [adjustForm] = Form.useForm();

  return (
    <DataFormLayoutForModal
      visible={visible}
      onClose={resultHandler?.onFail}
      width={700}
      content={
        <AdjustPlanTimeContent
          form={adjustForm}
          onAdjust={onAdjust}
          onCancel={resultHandler?.onFail!}
        />
      }
    />
  );
}
