import { DataFormLayout, DataFormLayoutInfoItem } from '@blacklake-web/layout';
import { Empty, Spin } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useHistory } from 'react-router-dom';
import { DataFormLayoutProps } from 'src/layout/dataForm';
import { useCustomLayoutForEdit } from './hooks/useCustomLayoutForEdit';

interface CustomDDataFormProps extends DataFormLayoutProps {
  objectCode: string;
  instanceIdParamKey?: string | number; // 路由上的实例id参数名，可选（默认为 'instanceId'）
  form: FormInstance;
  isCopy?: boolean;
  customInfoItems?: DataFormLayoutInfoItem[];
  extraInfoItems?: DataFormLayoutInfoItem[];
  layoutId?: number;
  onFinish: () => void;
  formatToApiData?: (data: any) => any;
  /**format form数据 */
  formatFormData?: (data: any) => any;
}

export default function CustomDataFormLayout(props: CustomDDataFormProps & DataFormLayoutProps) {
  const history = useHistory();
  const {
    objectCode,
    form,
    instanceIdParamKey,
    isCopy,
    customInfoItems,
    extraInfoItems,
    layoutId,
    formatToApiData,
    formatFormData,
    onFinish,
    ...rest
  } = props;
  const {
    loading,
    configLoading,
    confirmLoading,
    info,
    onFinish: formateFinish,
    containerRender,
  } = useCustomLayoutForEdit({
    objectCode,
    instanceId: Number(instanceIdParamKey),
    form,
    isCopy,
    customInfoItems,
    extraInfoItems,
    formatToApiData,
    formatFormData,
    layoutId,
  });

  if (configLoading) {
    return (
      <div
        style={{
          height: '100%',
          background: '#fff',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Spin spinning={configLoading}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Spin>
      </div>
    );
  }

  return containerRender ? (
    <DataFormLayout
      form={form}
      onCancel={() => history.go(-1)}
      onFinish={() => onFinish()}
      confirmLoading={confirmLoading}
      loading={loading}
      {...rest}
    >
      {containerRender}
    </DataFormLayout>
  ) : (
    <DataFormLayout
      form={form}
      info={info}
      onCancel={() => history.go(-1)}
      onFinish={() => onFinish()}
      confirmLoading={confirmLoading}
      loading={loading}
      {...rest}
    />
  );
}
