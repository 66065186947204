/**
 * 拆分生产任务
 */
import { useState } from 'react';
import { debounce } from 'lodash';
import { Descriptions, Form, InputNumber, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { DataFormLayout } from 'src/layout';
import BlSortFormList from 'src/components/sortList/BlSortFormList';
import { getInsertedRecordList } from '../utils';
import { arrayIsEmpty } from 'src/utils/array';

const SplitProductionTasks = (props: any) => {
  const { name, base, form, onChange } = props;

  const [recordCount, setRecordCount] = useState<number>(0); // 拆分生产任务

  const getColumns = (): ColumnProps<FormListFieldData & any>[] => {
    return [
      {
        title: '每任务数',
        dataIndex: 'plannedAmount',
        fixed: 'left',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'plannedAmount']}
              fieldKey={[field.fieldKey, 'plannedAmount']}
              style={{ marginBottom: '0' }}
              //   rules={[{ required: true, message: '行号不能为空' }, { validator: validatorIndex }]}
            >
              <InputNumber placeholder="请输入" min={1} precision={0} />
            </Form.Item>
          );
        },
      },
      {
        title: '个数',
        dataIndex: 'num',
        fixed: 'left',
        width: 120,
        render: (_text, field) => {
          return (
            <Form.Item
              name={[field.name, 'num']}
              fieldKey={[field.fieldKey, 'num']}
              style={{ marginBottom: '0' }}
            >
              <InputNumber placeholder="请输入" min={1} precision={0} />
            </Form.Item>
          );
        },
      },
    ];
  };

  const baseInfo = {
    items: [
      {
        isFullLine: true,
        render: () => (
          <BlSortFormList
            name={name}
            renderColumns={() => getColumns()}
            isNeedDrag={false}
            maxCount={50}
          />
        ),
      },
    ],
  };

  const handleFinish = (): any => {
    const { processInfo } = base;

    const splitTaskList = form.getFieldValue([name]);
    // let sumNumber: number = 0;

    if (arrayIsEmpty(splitTaskList)) return false;

    const sumNumber = splitTaskList.reduce((prev: number, item: any) => {
      return Number(prev) + Number(item.num * item.plannedAmount);
    }, []);

    setRecordCount(sumNumber);

    if (sumNumber > processInfo?.dispatchedRemainAmount?.amount) {
      message.error('本次拆分数不可超过可拆分数');
      return;
    }

    const sumLines = splitTaskList.map((item: any) => {
      return getInsertedRecordList(
        {
          ...base,
          plannedAmount: item.plannedAmount,
        },
        item.num,
      )!;
    });

    if (sumLines?.[0].length > 50) {
      message.error('单次最多支持拆分50个');
      return;
    }

    const valueList = form.getFieldValue(['dispatchRequests']);

    const noIncludeRecord = valueList
      .concat(...sumLines)
      .sort((a: any, b: any) => Number(a.order) - Number(b.order));

    form.setFields([
      {
        name: 'dispatchRequests',
        value: noIncludeRecord,
      },
    ]);
    onChange();
  };

  const renderTopContext = () => {
    const { processInfo } = base;

    return (
      <Descriptions style={{ marginTop: 20 }}>
        <Descriptions.Item label="可拆分数">
          {processInfo?.dispatchedRemainAmount?.amountDisplay}
        </Descriptions.Item>
        <Descriptions.Item label="本次拆分数合计">{recordCount}</Descriptions.Item>
      </Descriptions>
    );
  };

  return (
    <DataFormLayout
      form={form}
      info={[baseInfo]}
      topContext={renderTopContext()}
      title="拆分生产任务"
      onCancel={() => {
        onChange();
      }}
      onFinish={debounce(handleFinish, 1500)}
    />
  );
};

export default SplitProductionTasks;
