import { useState, useEffect } from 'react';
import { BlTable } from '@blacklake-web/component';
import { Modal, Input, Tag, Form, Button, Space, TablePaginationConfig } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';

import {
  fetchMaterialListToApi,
  FetchMaterialListToApiRequest,
} from 'src/api/ytt/material-domain/Web_MaterialBaseInfo';
import { TagList, Tooltip } from 'components';
import { MaterialEntity } from 'src/entity/material/index.type';
import { replaceSign } from 'src/utils/constants';
import _ from 'lodash';

interface MaterialModalProps {
  /** Modal标题 */
  title: string;
  /** 单选还是多选，multiple多选 */
  mode?: 'multiple';
  /** 物料的查询参数 */
  params?: any;
  parentNode?: any;
  /** 是否显示 */
  visible: boolean;
  selectedValue: any;
  onCancel: () => void;
  onOk: (rows: MaterialEntity[]) => void;
}
const MaterialSelectModal = (props: MaterialModalProps) => {
  const { title, visible, mode, onCancel, onOk, params, selectedValue, parentNode } = props;
  let initialSelectedRows = [],
    initialSelectedRowKeys = [];
  if (selectedValue?.label || selectedValue?.length > 0) {
    initialSelectedRows =
      mode === 'multiple'
        ? selectedValue.map((v: any) => JSON.parse(v.value) || '')
        : [JSON.parse(selectedValue.value)];
    initialSelectedRowKeys =
      mode === 'multiple'
        ? selectedValue.map((v: any) => JSON.parse(v.value).baseInfo.id || '')
        : [JSON.parse(selectedValue.value).baseInfo.id];
  }
  const initialSearchParams = { size: 10, page: 1, ...params };
  const [dataSource, setDataSource] = useState<MaterialEntity[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<MaterialEntity[]>(initialSelectedRows);
  const [selectedRowKeys, setSelectedRowKeyss] = useState<React.Key[]>(initialSelectedRowKeys);
  const [searchParams, setSearchParams] =
    useState<FetchMaterialListToApiRequest>(initialSearchParams);
  const [searchForm] = Form.useForm();

  useEffect(() => {
    fetchMaterialListToApi(searchParams).then((res) => {
      const list = res?.data?.list ?? [];
      setDataSource(list);
      setTotal(res?.data?.total || 0);
    });
  }, [searchParams]);
  const columns = [
    { title: '物料名称', dataIndex: ['baseInfo', 'name'], width: 200 },
    { title: '物料编号', dataIndex: ['baseInfo', 'code'], width: 200 },
    {
      title: '物料属性',
      dataIndex: ['attribute'],
      width: 200,
      render: (text: { name: string; attributeItem: any }[]) => {
        if (_.isEmpty(text)) return replaceSign;
        console.log(68, text);
        return (
          <TagList
            dataSource={_.map(text, ({ name, attributeItem }) => ({
              label: `${name}:${attributeItem.content}`,
              value: attributeItem.content,
            }))}
          />
        );
      },
    },
    {
      title: '物料规格',
      dataIndex: ['baseInfo', 'specification'],
      width: 200,
      render: (text: string) => {
        if (_.isEmpty(text)) return replaceSign;
        return <Tooltip text={text} width={200} />;
      },
    },
  ];
  const rowSelection: TableRowSelection<MaterialEntity> = {
    type: mode ? 'checkbox' : 'radio',
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: MaterialEntity[]) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeyss(selectedRowKeys);
    },
  };
  const paginationConfig: TablePaginationConfig = {
    total,
    current: searchParams.page,
    showSizeChanger: false,
    onChange: (page, size) => {
      setSearchParams({ ...searchParams, page, size });
    },
  };
  const confirmCallbock = () => {
    onOk(selectedRows);
  };

  const SearchForm = () => {
    return (
      <Form
        name="materialSearce"
        layout="inline"
        form={searchForm}
        onFinish={(values) => {
          setSearchParams({ ...initialSearchParams, ...values });
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        <Form.Item label="物料编号" name="code">
          <Input style={{ width: 150 }} allowClear />
        </Form.Item>
        <Form.Item label="物料名称" name="name">
          <Input style={{ width: 150 }} allowClear />
        </Form.Item>
        <Form.Item label="物料规格" name="specification">
          <Input style={{ width: 150 }} allowClear />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            查询
          </Button>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            onClick={() => {
              searchForm.resetFields();
              setSearchParams(initialSearchParams);
            }}
          >
            重置
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      getContainer={parentNode || document.body}
      centered
      title={title}
      visible={visible}
      destroyOnClose
      width={1000}
      onCancel={onCancel}
      onOk={confirmCallbock}
    >
      <Space direction="vertical" size={16}>
        <SearchForm />
        <BlTable
          rowSelection={rowSelection}
          rowKey={(r: MaterialEntity) => r.baseInfo?.id!}
          columns={columns}
          pagination={paginationConfig}
          dataSource={dataSource}
        />
      </Space>
    </Modal>
  );
};

export default MaterialSelectModal;
