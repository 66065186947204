import React, { useState } from 'react';
import { Popover } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { BlIcon } from 'src/components';
import { RootState } from 'src/store';
import NotificationTabList from '../notificationTabList';
import styles from './styles.module.scss';

export default function NotificationMessagePopover() {
  const [notificationVisible, setNotificationVisible] = useState(false);

  const { totalUnread = 0 } = useSelector((state: RootState) => state?.message, shallowEqual);

  const unReadDisplay = Boolean(totalUnread);

  const handleVisibleChange = (visible: boolean) => {
    setNotificationVisible(visible);
  };

  return (
    <Popover
      overlayClassName={styles.notificationPopover}
      placement="bottomRight"
      visible={notificationVisible}
      destroyTooltipOnHide={{ keepParent: false }}
      content={
        <NotificationTabList
          type="popover"
          handleClose={(visible: boolean) => {
            handleVisibleChange(visible);
          }}
        />
      }
      trigger="click"
      onVisibleChange={handleVisibleChange}
      arrowPointAtCenter
    >
      <BlIcon type="iconxiaoxi1" />
      <div className={styles?.num} style={{ display: unReadDisplay ? 'inline' : 'none' }}>
        {totalUnread > 99 ? '99+' : totalUnread}
      </div>
    </Popover>
  );
}
