import React, { useEffect, useState } from 'react';
import { Select, Form, Input, Radio, InputNumber } from 'antd';
import _ from 'lodash';
import { BlSearchSelect } from '@blacklake-web/component';
import { CheckCountTypeEnum } from 'src/dict/quality';
import {
  fetchQcAqlInspectionLevelList,
  fetchQcAqlAcceptLimitList,
} from 'src/api/ytt/quality-domain/qc_aql';

import styles from './style.module.scss';
import {
  fetchCustomAqlAcceptLimitList,
  fetchCustomAqlInspectionLevelList,
} from 'src/api/ytt/quality-domain/aql';
import { numberMinMaxCheck, validatorNumberDigtis } from 'src/utils/formValidators';
import { appDict } from 'src/dict';
import { aqlTypes } from 'src/dict/quality/mappings';

const CheckCountTypeRender = (props: { form: any }) => {
  const [checkCountValue, setCheckCountValue] = useState<CheckCountTypeEnum>();
  const [aqlList, setAqlList] = useState<any[]>([]);
  const { form } = props;
  const checkCount = checkCountValue || form.getFieldValue('checkCountType');
  const isAql = checkCount === CheckCountTypeEnum.aqlCheck;
  const isCustomAql = checkCount === CheckCountTypeEnum.customAqlCheck;

  const initialValues = {
    checkCount: null,
    qcAqlInspectionLevel: null,
    qcAql: null,
    qcAqlCategory: null,
  };

  const selectFormatter = (res: any) => {
    return {
      options: res.data.list.map((i: { name: any; value: any; id: any }) => {
        return {
          label: i.name || i.value,
          value: i.id,
          key: i.id,
        };
      }),
      total: res.data.total,
    };
  };

  const getAqlList = async () => {
    const { data } = await fetchQcAqlAcceptLimitList({});
    const result: any[] =
      data?.list?.map((item: any) => {
        return {
          label: `${item.value}`,
          value: item.id,
        };
      }) || [];

    setAqlList(result || []);
  };

  useEffect(() => {
    getAqlList();
  }, []);

  return (
    <>
      <Form.Item name="checkCountType" noStyle>
        <Radio.Group
          options={appDict.quality.CheckCountType}
          onChange={(e: {
            target: { value: React.SetStateAction<CheckCountTypeEnum | undefined> };
          }) => {
            setCheckCountValue(e.target.value);
            form.setFieldsValue(initialValues);
          }}
        />
      </Form.Item>
      {isAql && (
        <div className={styles.groupContent}>
          <Form.Item name="qcAqlInspectionLevel" label="检验水平" rules={[{ required: true }]}>
            <BlSearchSelect
              placeholder="请选择检验水平"
              fetchFn={fetchQcAqlInspectionLevelList}
              formatter={selectFormatter}
              labelInValue
            />
          </Form.Item>
          <Form.Item name="qcAqlCategory" label="国标严格度" rules={[{ required: true }]}>
            <Select placeholder="请选接国标严格度" labelInValue options={aqlTypes} />
          </Form.Item>
          <Form.Item name="qcAql" label="国标接收质量限" rules={[{ required: true }]}>
            <Select placeholder="请选接国标接收质量限" labelInValue>
              {aqlList.map((item: any) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      )}
      {isCustomAql && (
        <div className={styles.groupContent}>
          <Form.Item
            name="qcAqlInspectionLevel"
            label="自定义AQL方案"
            rules={[{ required: true }]}
            style={{ marginBottom: 16, width: '80%' }}
          >
            <BlSearchSelect
              placeholder="请选择自定义AQL方案"
              fetchFn={fetchCustomAqlInspectionLevelList}
              formatter={selectFormatter}
              onSelect={() => {
                form.setFieldsValue({ qcAql: null });
              }}
              labelInValue
            />
          </Form.Item>
          <Form.Item
            name="qcAql"
            label="接收质量限"
            style={{ paddingBottom: 16, width: '80%' }}
            rules={[{ required: true }]}
          >
            <BlSearchSelect
              placeholder="请选接收质量限"
              fetchFn={() =>
                fetchCustomAqlAcceptLimitList({
                  id: form.getFieldValue('qcAqlInspectionLevel').value,
                })
              }
              formatter={selectFormatter}
              labelInValue
            />
          </Form.Item>
        </div>
      )}
      {checkCount === CheckCountTypeEnum.ratioCheck && (
        <div className={styles.groupContent} style={{ display: 'flex' }}>
          <Form.Item
            name="checkCount"
            label="抽检数量"
            rules={[
              { required: true },
              {
                validator: numberMinMaxCheck({
                  max: 100,
                  min: 0,
                  minAllowEqual: false,
                  maxAllowEqual: true,
                  isInteger: false,
                }),
              },
              {
                validator: validatorNumberDigtis(4),
              },
            ]}
          >
            <InputNumber placeholder="请输入数量" style={{ width: 150 }} />
          </Form.Item>
          {checkCount === CheckCountTypeEnum.ratioCheck && (
            <span style={{ lineHeight: '32px' }}>%</span>
          )}
        </div>
      )}
      {checkCount === CheckCountTypeEnum.customCheck && (
        <div className={styles.groupContent} style={{ display: 'flex' }}>
          <Form.Item
            name="checkCount"
            label="抽检数量"
            rules={[
              { required: true },
              {
                validator: numberMinMaxCheck({
                  max: 100000,
                  min: 0,
                  minAllowEqual: false,
                  maxAllowEqual: false,
                  isInteger: false,
                }),
              },
              {
                validator: validatorNumberDigtis(6),
              },
            ]}
          >
            <InputNumber placeholder="请输入数量" style={{ width: 150 }} />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default CheckCountTypeRender;
