import React from 'react';
import { QrCode } from 'src/components';

const DEFAULT_WIDTH = 260;
const DEFAULT_HEIGHT = 220;
const DEFAULT_PADDING = 20;
const DEFAULT_MARGIN = 2;

interface Props {
  length?: number;
  height?: number;
  value: string;
}

const TagQrCode = (props: Props) => {
  const { value, height, length } = props;

  return (
    <div
      style={{
        display: 'inline-block',
        background: '#fff',
        minHeight: height || DEFAULT_HEIGHT,
        width: length || DEFAULT_WIDTH,
        padding: `${DEFAULT_PADDING}px`,
        margin: `${DEFAULT_MARGIN}px`,
        overflow: 'hidden',
        wordWrap: 'break-word',
        textAlign: 'center',
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div>
          <QrCode value={value} />
        </div>
        <div
          style={{
            color: '#000',
            fontSize: 18,
            fontWeight: 700,
            wordWrap: 'break-word',
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            marginTop: 10,
          }}
        >
          {value}
        </div>
      </div>
    </div>
  );
};

export default TagQrCode;
