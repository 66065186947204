/** 供应商注册状态(Unregistered, Registering, Registered) */
export enum SupplyRegStatus {
  /** 未注册 */
  unregistered,
  /** 注册中 */
  registering,
  /** 已注册 */
  registered,
}
/** 供应商相关操作 */
export enum SupplierOperateMode {
  create,
  edit,
  view,
  log,
  hide,
  invitateRegisterSupplier,
  removeRegisterSupplier,
  invitateRegisterMaterial,
  removeRegisterMaterial,
}
