import moment, { Moment } from 'moment';
import _ from 'lodash';
import { replaceSign } from 'src/utils/constants';
import timeFormatter from '../dataTypes/time';

const DISPLAY_DATE_FORMAT = 'YYYY-MM-DD';

const formatDateForRender = (
  backendDate: string | number | undefined | null,
): string | undefined => {
  return _.isNil(backendDate)
    ? replaceSign
    : timeFormatter.format(moment(backendDate), DISPLAY_DATE_FORMAT);
};

const formatTimeForRender = (
  backendDate: string | number | undefined | null,
): string | undefined => {
  return _.isNil(backendDate) ? replaceSign : timeFormatter.formatLine(moment(backendDate));
};

/**
 * 格式化一个字段的日期区间查询，会自动置为起始日期的起始时刻和结束日期的终止时刻
 * @param dateRange
 */
const formateDateRangeForQuery = (dateRange: [Moment, Moment] | undefined | null) => {
  if (!dateRange) {
    return undefined;
  }
  return [
    moment(dateRange[0].valueOf()).startOf('day').valueOf(),
    moment(dateRange[1].valueOf()).endOf('day').valueOf(),
  ];
};

/**
 * 格式化一个字段的时间区间查询
 * @param dateRange
 */
const formatTimeRangeForQuery = (dateRange: [Moment, Moment] | undefined | null) => {
  if (!dateRange) {
    return undefined;
  }
  return _.invokeMap(dateRange, 'valueOf');
};

/**
 * 格式化两个字段的时间区间查询
 * @param dateRange
 * @param fromStr
 * @param toStr
 */
const formateDateRangeForQuery2 = (
  fromStr: string,
  toStr: string,
  dateRange?: [Moment, Moment],
) => {
  const formatAfterDate = timeFormatter.formatRangeUnix(dateRange || []) ?? [];

  if (_.isEmpty(formatAfterDate)) return {};

  return {
    [fromStr]: formatAfterDate[0],
    [toStr]: formatAfterDate[1],
  };
};

/**
 * 格式化日期时间范围
 */
const formatDateTimeRangeToUnix = (
  fromStr: string,
  toStr: string,
  dateRange?: [Moment, Moment],
) => {
  const formatAfterDate = timeFormatter.formatTimeRangeUnix(dateRange || []) ?? [];

  if (_.isEmpty(formatAfterDate)) return {};

  return {
    [fromStr]: Number(formatAfterDate[0]),
    [toStr]: Number(formatAfterDate[1]),
  };
};

const getDateFormatter = (format: string) => (time: number | undefined) => {
  if (_.isNil(time)) {
    return replaceSign;
  }
  return moment(time).format(format);
};

export {
  formatDateForRender,
  formatTimeForRender,
  formateDateRangeForQuery,
  formatTimeRangeForQuery,
  formateDateRangeForQuery2,
  formatDateTimeRangeToUnix,
  getDateFormatter,
};
