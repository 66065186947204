import { Radio, InputNumber } from 'antd';
import { validateBlInputNumberIntervalInt } from '../verificationRules/index';
import { appDict, appEnum } from 'src/dict';

const MAX = 9;

export const appendixItems = () => {
  return [
    {
      label: '文件数量',
      name: ['extInfo', 'fileCount'],
      initialValue: MAX,
      rules: [{ validator: validateBlInputNumberIntervalInt(1, MAX) }],
      render: () => <InputNumber min={1} max={MAX} style={{ width: 300 }} placeholder="请输入" />,
    },
    {
      label: '必填',
      name: 'isRequired',
      initialValue: appEnum.Common.YN.no,
      rules: [{ required: true, message: '请选择' }],
      render: () => (
        <Radio.Group
          //   disabled={form.getFieldValue('isRequired') === appEnum.Common.YN.yes}
          options={appDict.common.yn}
        />
      ),
    },
  ];
};
