import { FormInstance, Select, Form } from 'antd';
import _ from 'lodash';
import { appDict, appEnum } from 'src/dict';
import { UnitType } from 'src/dict/material';
import { ConvertUnit } from '../..';

interface Props {
  form: FormInstance;
}

export const productionBasicInfo = (props: Props) => {
  const { form } = props;

  return [
    {
      shouldUpdate: (prev, next) => {
        return prev.transformUnit !== next.transformUnit;
      },
      noStyle: true,
      render: (FormItemProps: any) => () => {
        const transformUnit = _.filter(
          form.getFieldValue('transformUnit'),
          ({ enableFlag }) => enableFlag,
        );
        const productionUnit = form.getFieldValue('productionUnit');
        const unitList = transformUnit
          .map((item: ConvertUnit) => {
            if (item.convertType !== UnitType.auxUnit && item.enableFlag) {
              return {
                label: item.toUnitId?.label,
                value: item.toUnitId?.value,
              };
            }
          })
          .filter(Boolean);

        // 确认此次修改的单位，是否还在formList
        if (!_.find(transformUnit, ({ toUnitId }) => toUnitId?.value === productionUnit?.value)) {
          // 否，productionUnit = 主单位
          form.setFields([{ name: 'productionUnit', value: transformUnit[0].fromUnitId }]);
        }

        return (
          <Form.Item
            name={'productionUnit'}
            label={'自制单位'}
            rules={[{ required: true, message: '请选择自制单位' }]}
            {...FormItemProps}
          >
            <Select labelInValue placeholder={'请选择'} options={unitList} />
          </Form.Item>
        );
      },
    },
    {
      shouldUpdate: (prev, next) => {
        return prev.batchManagementEnable !== next.batchManagementEnable;
      },
      noStyle: true,
      render: (FormItemProps: any) => () => {
        const batchManagementEnable = form.getFieldValue('batchManagementEnable');
        const orderBatchRule = form.getFieldValue('orderBatchRule');

        if (!batchManagementEnable) return null;

        if (_.isNil(orderBatchRule)) {
          form.setFieldsValue({
            orderBatchRule: appEnum.ProductionPlanning.BatchGenerationTypeEnum.DOESNOTGENERATE,
          });
        }

        return (
          <Form.Item
            name={'orderBatchRule'}
            label={'工单批号生成规则'}
            rules={[{ required: true, message: '请选择工单批号生成规则' }]}
            {...FormItemProps}
            initialValue={appEnum.ProductionPlanning.BatchGenerationTypeEnum.DOESNOTGENERATE}
          >
            <Select
              placeholder={'请选择'}
              options={appDict.productionPlanning.BatchGenerationTypeDisplayMap}
            />
          </Form.Item>
        );
      },
    },
  ];
};
