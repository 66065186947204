import {
  PurchaseExecStatus,
  PurchaseReturnSource,
  PurchaseSource,
  DATE_RANGE,
  OVERDUE_RISK,
  DeliveryScheduleNoteExecStatus,
  DeliveryScheduleNoteReceiveStatus,
  DeliveryScheduleNoteDeliveryStatus,
  PurchaseOrderdeliveryMode,
  DeliveryScheduleNoteSource,
  PurchaseOrderMaterialCarryMode,
} from '.';
import { mappingsFactory } from '../utils';

export const purchaseExecStatus = mappingsFactory(
  ['新建', PurchaseExecStatus.created],
  ['待确认', PurchaseExecStatus.undetermined],
  ['执行中', PurchaseExecStatus.executing],
  ['已结束', PurchaseExecStatus.finished],
  ['已关闭', PurchaseExecStatus.closed],
);

/** 采购订单来源 */
export const purchaseSource = mappingsFactory(
  ['普通采购', PurchaseSource.common],
  ['协同采购', PurchaseSource.collaborate],
);
/** 采购订单交货方式 */
export const purchaseOrderdeliveryMode = mappingsFactory(
  ['按订单', PurchaseOrderdeliveryMode.purchaseOrder],
  ['按交货计划', PurchaseOrderdeliveryMode.deliverySchedule],
);
export const purchaseOrderMaterialCarryMode = mappingsFactory(
  ['不带料', PurchaseOrderMaterialCarryMode.notCarry],
  ['带料', PurchaseOrderMaterialCarryMode.carry],
);
/** 采购退货单来源 */
export const purchaseReturnSource = mappingsFactory(
  ['普通退货', PurchaseReturnSource.common],
  ['协同退货', PurchaseReturnSource.collaborate],
);

/** 日期范围 */
export const dateRange = mappingsFactory(
  ['今天', DATE_RANGE.day],
  ['本周', DATE_RANGE.week],
  ['本月', DATE_RANGE.month],
);

/** 逾期风险 */
export const overdueRisk = mappingsFactory(['低', OVERDUE_RISK.low], ['高', OVERDUE_RISK.high]);

/** 交货计划单状态 */
export const deliveryScheduleNoteExecStatus = mappingsFactory(
  ['新建', DeliveryScheduleNoteExecStatus.created],
  ['待确认', DeliveryScheduleNoteExecStatus.undetermined],
  ['执行中', DeliveryScheduleNoteExecStatus.executing],
  ['已退回', DeliveryScheduleNoteExecStatus.rejected],
  ['已结束', DeliveryScheduleNoteExecStatus.finished],
  ['已关闭', DeliveryScheduleNoteExecStatus.closed],
);

/** 交货计划单供应商发货状态 */
export const deliveryScheduleNoteDeliveryStatus = mappingsFactory(
  ['待发货', DeliveryScheduleNoteDeliveryStatus.waitingDelivery],
  ['部分发货', DeliveryScheduleNoteDeliveryStatus.partDelivery],
  ['全部发货', DeliveryScheduleNoteDeliveryStatus.allDelivery],
  ['全部收货', DeliveryScheduleNoteDeliveryStatus.allReceived],
);
/** 交货计划单收货状态 */
export const deliveryScheduleNoteReceiveStatus = mappingsFactory(
  ['待收货', DeliveryScheduleNoteReceiveStatus.waitingReceived],
  ['部分收货', DeliveryScheduleNoteReceiveStatus.partReceived],
  ['全部收货', DeliveryScheduleNoteReceiveStatus.allReceived],
);
/** 交货计划单来源 */
export const deliveryScheduleNoteSource = mappingsFactory(
  ['无源', DeliveryScheduleNoteSource.noSource],
  ['采购订单', DeliveryScheduleNoteSource.purchaseOrder],
);
export const logisticsTimeUnit = [
  { label: '天', value: 1 },
  { label: '小时', value: 2 },
  { label: '分钟', value: 3 },
];
