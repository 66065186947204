import { renderAmount, renderTotalPrice } from '../utils';
import _Array from 'src/utils/dataTypes/array';
import { replaceSign } from 'src/utils/constants';
import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { valueOrHolder } from 'src/utils/formatters';
import _, { compact, isEmpty } from 'lodash';
import { BcAttachmentDetail, TagList, BcMultilineText } from 'src/components';
import { renderUser } from 'src/page/share/renderUser';
import lookup from 'src/dict';
import { PurchaseOrderMaterialCarryMode } from 'src/dict/purchase';
export const baseInfoColumns = [
  {
    label: '编号',
    dataIndex: 'code',
  },
  {
    label: '供应商',
    dataIndex: ['supplier', 'name'],
    render: (text: string) => (typeof text === 'string' ? text : replaceSign),
  },
  {
    label: '订单所有人',
    dataIndex: 'owner',
    render: renderUser,
  },
  {
    label: '来源',
    dataIndex: 'source',
    render: lookup('purchase.purchaseSource'),
  },
  {
    label: '交货方式',
    dataIndex: 'deliveryMode',
    render: lookup('purchase.purchaseOrderdeliveryMode'),
  },
  {
    label: '币种',
    dataIndex: ['currency', 'name'],
  },
  {
    label: '状态',
    dataIndex: 'execStatus',
    render: lookup('purchase.purchaseExecStatus'),
  },
  {
    label: '协同销售订单号',
    dataIndex: 'salesOrderCode',
    render: valueOrHolder,
  },
  {
    label: '带料方式',
    dataIndex: 'materialCarryMode',
    render: lookup('purchase.purchaseOrderMaterialCarryMode'),
  },
  {
    label: '订单备注',
    dataIndex: 'remark',
    render: valueOrHolder,
  },
  {
    label: '附件',
    dataIndex: 'attachments',
    render: (files: number[]) => {
      if (isEmpty(files)) return replaceSign;

      return <BcAttachmentDetail fileIds={files} />;
    },
  },
  {
    label: '协同附件',
    dataIndex: 'coordinationAttachments',
    render: (files: number[]) => {
      if (isEmpty(files)) return replaceSign;

      return <BcAttachmentDetail fileIds={files} />;
    },
  },
  {
    label: '订单要求',
    dataIndex: 'requirement',
    render: (text: string) => {
      return <BcMultilineText text={text} />;
    },
  },
];
export const otherInfoColumns = [
  {
    label: '创建人',
    dataIndex: 'creator',
    render: renderUser,
  },
  {
    label: '创建时间',
    dataIndex: 'createdAt',
    render: formatTimeForRender,
  },
  {
    label: '更新人',
    dataIndex: 'operator',
    render: renderUser,
  },
  {
    label: '更新时间',
    dataIndex: 'updatedAt',
    render: formatTimeForRender,
  },
];
export const detailItemColumns = (carryMode: PurchaseOrderMaterialCarryMode) => {
  return compact([
    {
      title: '行号',
      dataIndex: 'seqNum',
      width: 150,
    },
    {
      title: '物料编号',
      dataIndex: ['material', 'baseInfo', 'code'],
      width: 200,
    },
    {
      title: '物料名称',
      dataIndex: ['material', 'baseInfo', 'name'],
      width: 200,
    },
    {
      title: '物料属性',
      dataIndex: ['material', 'attribute'],
      width: 250,

      render: (attribute: any) => {
        if (_Array.isEmpty(attribute)) {
          return replaceSign;
        }
        return (
          <TagList
            dataSource={_.map(attribute, ({ id, name, attributeItem }) => ({
              label: `${name}:${attributeItem?.content}`,
              value: id,
            }))}
          />
        );
      },
    },
    {
      title: '需求数',
      dataIndex: 'demandAmount',
      width: 150,
      render: renderAmount,
    },
    {
      title: '需求时间',
      dataIndex: 'demandTime',
      width: 200,
      render: formatTimeForRender,
    },
    {
      title: '含税单价',
      dataIndex: 'unitPrice',
      width: 200,
      render: (unitPrice: any) => {
        return unitPrice ? unitPrice?.currencySymbol + unitPrice?.priceDisplay : replaceSign;
      },
    },
    {
      title: '税率',
      dataIndex: 'taxRate',
      width: 150,
      render: (taxRate: any) => {
        return taxRate ? taxRate + '%' : replaceSign;
      },
    },
    {
      title: '含税金额',
      dataIndex: 'totalPrice',
      width: 150,
      render: (totalPrice: any) => {
        return renderTotalPrice(totalPrice?.price, totalPrice?.amountAccuracy);
      },
    },
    {
      title: '计划交货数',
      dataIndex: 'planDeliveryAmount',
      width: 150,
      render: renderAmount,
    },
    {
      title: '计划收货数',
      dataIndex: 'planReceivedAmount',
      width: 150,
      render: renderAmount,
    },
    {
      title: '收货发出数',
      dataIndex: 'supplierDeliverAmount',
      width: 150,
      render: renderAmount,
    },
    {
      title: '收货接收数',
      dataIndex: 'receivedAmount',
      width: 150,
      render: renderAmount,
    },
    {
      title: '计划退货数',
      dataIndex: 'planReturnAmount',
      width: 150,
      render: renderAmount,
    },
    {
      title: '退货发出数',
      dataIndex: 'returnDeliverAmount',
      width: 150,
      render: renderAmount,
    },
    {
      title: '退货接收数',
      dataIndex: 'returnReceivedAmount',
      width: 150,
      render: renderAmount,
    },
    carryMode === PurchaseOrderMaterialCarryMode.carry && {
      title: '物料清单版本号',
      dataIndex: 'bomVersion',
      width: 150,
    },
  ]);
};

export const detailOutItemColumns = [
  {
    title: '采购订单行号',
    dataIndex: 'purchaseOrderItemLineNo',
    width: 150,
  },
  {
    title: '项次',
    dataIndex: 'seq',
    width: 150,
  },
  {
    title: '物料编号',
    dataIndex: ['material', 'baseInfo', 'code'],
    width: 200,
  },
  {
    title: '物料名称',
    dataIndex: ['material', 'baseInfo', 'name'],
    width: 200,
  },
  {
    title: '物料属性',
    dataIndex: ['material', 'attribute'],
    width: 250,

    render: (attribute: any) => {
      if (_Array.isEmpty(attribute)) {
        return replaceSign;
      }
      return (
        <TagList
          dataSource={_.map(attribute, ({ id, name, attributeItem }) => ({
            label: `${name}:${attributeItem?.content}`,
            value: id,
          }))}
        />
      );
    },
  },
  {
    title: '物料规格',
    dataIndex: ['material', 'baseInfo', 'specification'],
    width: 200,
  },
  {
    title: '用量:分子',
    dataIndex: 'inputAmountNumerator',
    width: 200,
  },
  {
    title: '用量:分母',
    dataIndex: 'inputAmountDenominator',
    width: 200,
  },
  {
    title: '标准用量',
    dataIndex: 'standardDosage',
    width: 200,
    render: renderAmount,
  },
  {
    title: '需求数',
    dataIndex: 'demandAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '损耗率',
    dataIndex: 'inputAmountNumerator',
    width: 200,
  },
  {
    title: '计划发出数',
    dataIndex: 'plannedIssueAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '仓库实发数',
    dataIndex: 'warehouseIssueAmount',
    width: 150,
    render: renderAmount,
  },
  {
    title: '收货接收数',
    dataIndex: 'receivedAmount',
    width: 150,
    render: renderAmount,
  },
];
