/**
 *
 */
export const OBJECT_OF_ID = {
  /** 物料批次: 285 */
  materialBatchNo: 285,
  /** 库存明细: 78 */
  inventoryElement: 78,
  /** 批号: 69 */
  batchcode: 69,
  /** 标识码: 15 */
  identifier: 15,
  /** 标识码关系表: 106 */
  codeEntityLink: 106,
  /** SOP任务记录: 1652172915074076 */
  SOPTaskRecord: 1652172915074076,
};

export const OBJECT_OF_CODE = {
  /** 「用户 */
  user: 'User',
  /** 「物料」 对象编号 */
  material: 'Material',
  /** 「物料」物料单位 */
  MaterialConversionUnit: 'MaterialConversionUnitList',
  /** 「物料」批次属性 */
  BatchAttributeConfig: 'BatchAttributeConfig',
  /** 「物料」库存属性 */
  InventoryAttributeConfig: 'InventoryAttributeConfig',
  /** 「物料分类」 对象编号 */
  materialCategory: 'MaterialCategory',
  /** 「单位」 对象编号 */
  materialUnit: 'Unit',
  /** 「物料属性-项」 对象编号 */
  materialAttributeItem: 'MaterialAttributeItem',
  /** 「物料属性-值」 对象编号 */
  materialAttributeValue: 'MaterialAttributeValue',
  /** 「批号」 对象编号 */
  batchNo: 'BatchNo',
  /** 「自定义AQL」 对象编号 */
  customizedAQL: 'CustomizedAQL',
  /** 「出库单」 对象编号 */
  outboundOrder: 'OutboundOrder',
  /** 「出库单-行」 对象编号 */
  outboundOrderItem: 'OutboundOrderItem',
  /** 「入库单」 对象编号 */
  inboundOrder: 'InboundOrder',
  /** 「入库单-行」 对象编号 */
  inboundOrderItem: 'InboundOrderItem',
  /** 「调拨单」 对象编号 */
  transferOrder: 'TransferOrder',
  /** 「「调拨单」-行」 对象编号 */
  transferOrderItem: 'TransferOrderItem',
  /** 出库记录 */
  outboundRecord: 'OutboundRecord',
  /** 入库记录 */
  inboundRecord: 'InboundRecord',
  /** 调拨记录 */
  transferRecord: 'TransferRecord',
  /** 库存调整记录 */
  amountAdjustRecord: 'AmountAdjustRecord',
  /** 属性变更记录 */
  attrAdjustRecord: 'AttrAdjustRecord',
  /** 「客户」 对象编号 */
  customer: 'Customer',
  /** 「客户注册审核单」 对象编号 */
  customerRegisteredAudit: 'CustomerRegisteredAudit',
  /** 「客户物料注册审核单」 对象编号 */
  customerMaterialRegisteredAudit: 'CustomerMaterialRegisteredAudit',
  /** 「采购订单」 对象编号 */
  purchaseOrder: 'PurchaseOrder',
  /** 「采购订单物料行」 对象编号 */
  purchaseOrderItem: 'PurchaseOrderItem',
  /** 「采购订单外协用料清单子表」 对象编号 */
  outsourceMaterialList: 'OutsourceMaterialList',
  /** 「交货计划单」 对象编号 */
  deliveryScheduleNote: 'DeliveryScheduleNote',
  /** 「采购退货单」 对象编号 */
  purchaseReturnOrder: 'PurchaseReturnOrder',
  /** 「采购申请单」 对象编号 */
  purchaseAuditNote: 'PurchaseAuditNote',
  /** 「采购申请单物料行」 对象编号 */
  purchaseAuditNoteItem: 'PurchaseAuditNoteItem',
  /** 「销售订单」 对象编号 */
  salesOrder: 'SalesOrder',
  /** 「销售订单行」 对象编号 */
  salesOrderItem: 'SalesOrderItem',
  /** 收货单 对象编号 */
  receiveNote: 'ReceiveNote',
  /** 收货单 物料视图 */
  ReceiveNoteItem: 'ReceiveNoteItem',
  /** 发货单 对象编号 */
  ShipmentsNote: 'ShipmentsNote',
  /** 发货单 物料视图 */
  ShipmentsNoteItem: 'ShipmentsNoteItem',
  /** 货源清单 对象编号 */
  sourceList: 'SourceList',
  /** 销售退货单 对象编号 */
  SalesOrderReturn: 'SalesOrderReturn',
  /** 物料清单 */
  Bom: 'BOM',
  /** 物料清单 子项物料 */
  SubItemMaterial: 'SubItemMaterial',
  /** 物料清单 子项物料-子行 */
  SubItemMaterialChild: 'SubItemMaterialChild',
  /** 维保方案 */
  MaintenanceCase: 'MaintenanceCase',
  /** 维保任务 */
  MaintenanceTask: 'MaintenanceTask',
  /** 能源主数据 */
  EnergyInstrument: 'EnergyInstrument',
  /** 协同交货计划 对象编号 */
  CustomerDeliveryScheduleNote: 'CustomerDeliveryScheduleNote',
  /** 「设备」 对象编号 */
  equipment: 'Equipment',
  /** 投料记录 */
  feedingRecord: 'MaterialFeedRecord',
  /** 供应商 */
  supplier: 'Supplier',
  /** 维修任务 */
  RepairTask: 'RepairTask',
  qcTask: 'QualityInspectionTask',
  checkItemRecord: 'InspectionItemRecord',
  /** 计划订单 */
  planOrder: 'PlanOrder',
  qcConfig: 'QualityInspectionScheme',
};
