import { useEffect, useState } from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import { fetchCustomMadeReportQueryCustomMadeReportConfigDetailBack } from 'src/api/ytt/e-report-domain/customizedReports';

import BaseInfo from './detailBaseInfo';

interface Props extends RouteComponentProps {
  match: match<{ id: string }>;
}

const CustomizedReportDetail = (props: Props) => {
  const { match } = props;
  const customizedReportId = Number(match?.params?.id);

  const [detail, setDetail] = useState<any>({} as any);

  const fetchData = async () => {
    try {
      const { data } = await fetchCustomMadeReportQueryCustomMadeReportConfigDetailBack({
        id: customizedReportId,
      });

      if (data) {
        setDetail(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [customizedReportId]);

  return <BaseInfo detail={detail} />;
};

export default CustomizedReportDetail;
