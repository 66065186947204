/**
 * @page 新建/编辑自定义对象实例
 */

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { matchPath, useParams } from 'react-router-dom';
import _ from 'lodash';
import { Form, message } from 'antd';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { CRUD } from 'src/dict/common';
import { fetchCustomFieldGetListByCustomObjectCode } from 'ytt/metadata-domain/customField';
import { fetchStandardBizObjectCustomObjectGetSonObjectAndFieldById } from 'ytt/metadata-domain/objectPlatform';
import {
  fetchCustomObjectCreate,
  fetchCustomObjectUpdate,
  fetchCustomObjectDetail,
} from 'ytt/custom-object-domain';
import { fetchCascadeBatchTree } from 'ytt/metadata-domain/fieldCascading';
import {
  getInputControlRenderer,
  getReferControlRenderer,
  getSubObjectInputRenderer,
  getFormItemProps,
  excludeSubObjFields,
} from 'src/page/customLayout';
import lookup, { appEnum } from 'src/dict';
import { replaceSign } from 'src/utils/constants';
import { useBlocker } from 'src/utils';
import {
  constructControlFieldForest,
  postorderTraversal,
} from 'src/page/custom_fields/fieldCascading/utils';
import {
  getFieldsValueForReset,
  getSubObjectsValueForReset,
  getFieldsDefaultValueForPreset,
  formatFieldValuesForSubmit,
  getEditPath,
  getCopyPath,
  getListPath,
} from '../utils';
import type {
  EntityFieldDTO,
  EntitySubObjectDTO,
  FieldDTO,
  SubObjectDTO,
  FieldCascadingListItem,
} from 'src/page/customLayout/types';
import type {
  FetchCustomObjectCreateRequest,
  FetchCustomObjectUpdateRequest,
} from 'src/api/ytt/custom-object-domain';
import {
  getObjectFieldsPermissionList,
  ObjectFieldPermission,
} from 'src/utils/auth/fieldPermission';
interface Props {
  objectCode: string;
}
interface Params {
  instanceId?: string;
}

const getAddFieldHiddenItem = (field: FieldDTO, index: number) => (prop: keyof FieldDTO) => ({
  name: ['fields', index, prop],
  hidden: true,
  initialValue: field[prop],
});

const getAddSubObjectHiddenItem =
  (subObj: SubObjectDTO, index: number) => (prop: keyof SubObjectDTO) => ({
    name: ['sonObjects', index, prop],
    hidden: true,
    initialValue: subObj[prop],
  });

const EditCustomObject: FC<Props> = ({ objectCode }) => {
  const [loadingComp, setLoadingComp] = useState<'data' | 'submit' | null>(null);
  const [fields, setFields] = useState<FieldDTO[]>([]);
  const [subObjects, setSubObjects] = useState<SubObjectDTO[]>([]);
  const [entityFields, setEntityFields] = useState<EntityFieldDTO[]>([]);
  const [entitySubObjects, setEntitySubObjects] = useState<EntitySubObjectDTO[]>([]);
  // 主、从对象里的字段级联规则
  const [fieldCascadingsList, setFieldCascadingsList] = useState<FieldCascadingListItem[]>([]);
  // 主、从对象里的字段权限
  const [fieldsPermissionList, setFieldsPermissionList] = useState<ObjectFieldPermission[]>([]);
  const { instanceId } = useParams<Params>();
  const [form] = Form.useForm();
  const { history, resetForm } = useBlocker(form);

  const isEdit = !!matchPath(location.pathname, { path: getEditPath(), sensitive: true });
  const isCopy = !!matchPath(location.pathname, { path: getCopyPath(), sensitive: true });

  const fetchData = useCallback(async () => {
    setLoadingComp('data');
    try {
      const resList = await Promise.all([
        // 获取对象的字段定义
        fetchCustomFieldGetListByCustomObjectCode({ objectCode }),
        // 获取对象的从对象定义
        fetchStandardBizObjectCustomObjectGetSonObjectAndFieldById({ objectCode }),
        // 编辑/复制时，加载实例数据
        isEdit || isCopy
          ? fetchCustomObjectDetail({
              instanceId: _.toNumber(instanceId),
              objectCode,
            })
          : undefined,
      ]);

      const fields = excludeSubObjFields(resList[0].data ?? []);
      const subObjects = resList[1].data ?? [];
      const objectCodes = _.compact(_.concat([objectCode], _.map(subObjects, 'objectCode')));

      // 批量获取主对象及所有从对象的字段级联关系
      const cascadingRes = await fetchCascadeBatchTree(
        objectCodes.map((c) => ({
          objectCode: c!,
          status: appEnum.Common.UsageStatus.enabled,
        })),
      );

      // 主/从对象所有字段的字段权限数据
      const fieldsPermissionList = await getObjectFieldsPermissionList(objectCodes);

      setFields(fields);
      setSubObjects(subObjects);
      setFieldCascadingsList(cascadingRes.data!);
      setFieldsPermissionList(fieldsPermissionList);
      // 回填数据
      if (isEdit || isCopy) {
        const entityFields = resList[2]!.data?.fields ?? [];
        const entitySubObjects = resList[2]!.data?.sonObjects ?? [];

        setEntityFields(entityFields);
        setEntitySubObjects(entitySubObjects);
        // 实例数据的顺序要与定义保持一致
        resetForm({
          fields: getFieldsValueForReset(fields, entityFields),
          sonObjects: getSubObjectsValueForReset(subObjects, entitySubObjects),
        });
      } else {
        // 填充默认值
        const allFieldsDefaultValues = getFieldsDefaultValueForPreset(fields);
        const fieldCascadings = _.find(cascadingRes.data!, { objectCode })?.cascades ?? [];
        const controlFields = fieldCascadings.map((cas) => cas.fieldCode);
        const isControlFieldIdx = (idx: number) => controlFields.includes(fields[idx].fieldCode!);

        // - 首先，填充所有非控制字段的默认值
        form.setFieldsValue({
          fields: allFieldsDefaultValues.map((val, idx) => {
            return isControlFieldIdx(idx) ? undefined : val;
          }),
        });
        // - 然后，逆着控制顺序，依次填充所有控制字段的默认值
        // 这样，前序的控制字段填入默认值时，后续受控字段可以触发shouldUpdate，清除不符合级联规则的默认值
        constructControlFieldForest(fieldCascadings).forEach((tree) => {
          const indexList = postorderTraversal(tree).map((fieldCode) =>
            _.findIndex(fields, { fieldCode }),
          );

          form.setFields(
            indexList.map((idx) => ({ name: ['fields', idx], value: allFieldsDefaultValues[idx] })),
          );
        });
        resetForm(form.getFieldsValue(true));
      }
    } finally {
      setLoadingComp(null);
    }
  }, [objectCode, instanceId]);

  useEffect(() => {
    fetchData();
  }, [objectCode, instanceId]);

  const baseInfo: DataFormLayoutInfoBlock = useMemo(
    () => ({
      column: 2,
      title: '基本信息',
      items: [
        ...fields.map((field, idx) => {
          if (field.isRefer) {
            const targetIndex = _.findIndex(fields, {
              id: _.toNumber(field.referenceChain?.split('#')[0]),
            });
            const targetNamePath = ['fields', targetIndex, 'fieldValue'];

            // 引用了不可编辑字段
            if (targetIndex === -1) {
              let referredValue = replaceSign;

              // 新建时，直接展示空；编辑/复制时，从实例数据中取值展示，因为不可编辑所以无需考虑联动
              if (isEdit || isCopy) {
                referredValue = _.find(entityFields, { fieldCode: field.fieldCode })
                  ?.fieldValue as string;
              }
              return {
                label: field.fieldName,
                tooltip: field.fieldRemind,
                render: () => referredValue,
              };
            }
            return {
              ...getFormItemProps({ field, namePath: targetNamePath }),
              render: getReferControlRenderer(field, {
                parentObjectCode: objectCode,
                targetNamePath,
                form,
                fields,
              }),
            };
          }
          const addHiddenItem = getAddFieldHiddenItem(field, idx);
          const namePath = ['fields', idx];
          const getNamePathByFieldCode = (fieldCode: string) => [
            'fields',
            _.findIndex(fields, { fieldCode }),
            'fieldValue',
          ];
          const fieldCascadings = _.find(fieldCascadingsList, { objectCode })?.cascades ?? [];
          // 当前对象字段权限
          const fieldsPermission = _.find(fieldsPermissionList, ['objectCode', objectCode]);

          const formItemProps = getFormItemProps({
            field,
            namePath: ['fields', idx, 'fieldValue'],
            fieldCascadings,
            getNamePathByFieldCode,
          });
          const inputControlRenderer = getInputControlRenderer(field, {
            namePath,
            form,
            isEdit,
            fieldCascadings,
            getNamePathByFieldCode,
            fieldsPermission: fieldsPermission?.fields,
          });

          return [
            addHiddenItem('fieldCode'),
            addHiddenItem('id'),
            addHiddenItem('fieldType'),
            addHiddenItem('targetType'),
            {
              name: ['fields', idx, 'isNumberRuleActuallyConfig'],
              hidden: true,
              initialValue: 1,
            },
            // 存在依赖关系时，需要分两层渲染<Form.Item>
            formItemProps.shouldUpdate
              ? {
                  ..._.omit(formItemProps, ['name', 'rules']),
                  render: () => () =>
                    (
                      <Form.Item noStyle {..._.pick(formItemProps, ['name', 'rules'])}>
                        {inputControlRenderer()}
                      </Form.Item>
                    ),
                }
              : {
                  ...formItemProps,
                  render: inputControlRenderer,
                },
          ];
        }),
        ...subObjects.map((subObj, idx) => {
          const addHiddenItem = getAddSubObjectHiddenItem(subObj, idx);
          // 当前对象字段权限
          const fieldsPermission = _.find(fieldsPermissionList, ['objectCode', subObj.objectCode]);

          return [
            addHiddenItem('objectCode'),
            {
              label: subObj.referName,
              required: !!subObj.childNecessary,
              isFullLine: true,
              render: getSubObjectInputRenderer({
                subObj,
                namePath: ['sonObjects', idx, 'instances'],
                form,
                namePathOfMainObject: ['fields'],
                subObjectEntities: entitySubObjects.filter(
                  (instance) => instance.objectCode === subObj.objectCode,
                ),
                isEdit,
                fieldCascadings:
                  _.find(fieldCascadingsList, { objectCode: subObj.objectCode! })?.cascades ?? [],
                fieldsPermission: fieldsPermission?.fields,
              }),
            },
          ];
        }),
      ].flat(),
    }),
    [fields, subObjects, entityFields, entitySubObjects, fieldCascadingsList, fieldsPermissionList],
  );

  const handleCancel = () => history.push(getListPath());

  // 将各类型从对象的实例转换格式，平铺到一个list里
  const formatSubObjectValuesForSubmit = useCallback(
    (sonObjectFormValues: any) => {
      if (!_.isArray(sonObjectFormValues)) {
        return undefined;
      }
      return _.compact(
        _.flatMap(sonObjectFormValues, (subObjVal) => {
          if (!_.isArray(subObjVal.instances)) {
            return undefined;
          }
          const subObjFields = _.find(subObjects, { objectCode: subObjVal.objectCode })?.fieldList!;

          return subObjVal.instances.map((instance: any) => {
            const fields = _.compact(
              Object.entries(instance)
                .filter(([k]) => !k.startsWith('$'))
                .map(([k, v]) => {
                  const field = _.find(subObjFields, { fieldCode: k })!;

                  // 去掉引用字段
                  return field.isRefer
                    ? null
                    : {
                        ..._.pick(field, ['fieldCode', 'fieldType', 'id', 'targetType']),
                        fieldValue: v as any,
                      };
                }),
            );

            const result: any = {
              fields: formatFieldValuesForSubmit(fields),
              objectCode: subObjVal.objectCode,
            };

            if (!_.isNil(instance.$instanceId)) {
              result.instanceId = _.toNumber(instance.$instanceId);
            }
            return result;
          });
        }),
      );
    },
    [subObjects],
  );

  const handleSubmit = () => {
    form.validateFields().then(() => {
      const formValue = form.getFieldsValue();
      let op = CRUD.create;

      if (isEdit) {
        op = CRUD.edit;
      } else if (isCopy) {
        op = CRUD.copy;
      }
      const submitValue: FetchCustomObjectCreateRequest = {
        objectCode,
        fields: formatFieldValuesForSubmit(formValue.fields),
        sonObjects: formatSubObjectValuesForSubmit(formValue.sonObjects),
      };

      if (isEdit) {
        (submitValue as FetchCustomObjectUpdateRequest).instanceId = _.toNumber(instanceId);
      }
      setLoadingComp('submit');
      (isEdit
        ? fetchCustomObjectUpdate(submitValue as FetchCustomObjectUpdateRequest)
        : fetchCustomObjectCreate(submitValue)
      )
        .then(() => {
          message.success(`${lookup('crud', op)}成功！`);
          resetForm(formValue);
          _.delay(handleCancel, 0);
        })
        .finally(() => setLoadingComp(null));
    });
  };

  return (
    <DataFormLayout
      form={form}
      info={[baseInfo]}
      onCancel={handleCancel}
      onFinish={handleSubmit}
      confirmLoading={loadingComp === 'submit'}
      loading={loadingComp === 'data'}
    />
  );
};

export default EditCustomObject;
