import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { Checkbox, Form, message, Tabs } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { RouterProps } from 'react-router-dom';
import { NumberRulesStandardHook } from 'src/components';
import lookup from 'src/dict';
import { CRUD, ObjectCode } from 'src/dict/common';
import { TabType } from 'src/dict/material';
import { bizRange as bizRangeMapping } from 'src/dict/material/mappings';
import { checkTwoSidesTrim, textValidator2 } from 'src/utils/formValidators';
import { FieldPermission, getObjectFieldsPermission } from 'src/utils/auth/fieldPermission';
import { BizRangeOptionsList } from '..';
import { handleBizRangeChangeTabDefaults, handleBizTypeChangeDefaults } from '../utils';
import * as businessFieldForm from './components';
import { injectCustomFieldInfos } from 'src/components/customField';

interface Props extends RouterProps {
  loading: boolean;
  type: string;
  onSubmit?: (values: any, cb?: (msg: string) => void) => void;
  initialValue?: any;
  customFields?: any;
}
const BaseForm = (props: Props) => {
  const { loading, type, history, onSubmit, initialValue, customFields } = props;
  const [keepCreate, setKeepCreate] = useState(true);
  const [activeKey, setActiveKey] = useState<string>(String(TabType.basic));
  const [updateMark, setUpdateMark] = useState(0);
  const [, setUpdateMBizRangeMark] = useState(0);
  const [bizRangeOptionsList, setBizRangeOptionsList] =
    useState<BizRangeOptionsList[]>(bizRangeMapping);
  /** 当前用户角色 的 物料对象的字段权限 */
  const [fieldsPermission, setFieldsPermission] = useState<FieldPermission[]>([]);

  const [form] = Form.useForm();
  const formProps = {
    preserve: true,
  };

  useEffect(() => {
    getObjectFieldsPermission(ObjectCode.material).then((curFieldsPermission) => {
      setFieldsPermission(curFieldsPermission);
    });
  }, []);

  // 自定义 HOOK 需放在最顶层
  const renderMaterialCode = NumberRulesStandardHook({
    label: '物料编号',
    form,
    edit: type === CRUD.edit,
    objectCode: ObjectCode.material,
    fieldCode: 'code',
    rules: [
      {
        max: 255,
        message: '不可超过255个字符',
      },
      { validator: textValidator2 },
      { validator: checkTwoSidesTrim },
    ],
    refreshMarker: updateMark,
  });
  // 自定义 HOOK 需放在最顶层 END

  const renderInfo = (activeKey: number): DataFormLayoutInfoBlock[] => {
    switch (Number(activeKey)) {
      // 基本信息
      case TabType.basic:
        return [
          {
            items: businessFieldForm.baseInfo({
              form,
              type,
              renderMaterialCode,
              setUpdateMark,
              setUpdateMBizRangeMark,
              bizRangeOptionsList,
              setBizRangeOptionsList,
              // customFields,
              fieldsPermission,
            }),
            column: 2,
          },
        ];
      // 仓储信息
      case TabType.warehousing:
        return [
          {
            title: '基本设置',
            items: businessFieldForm.warehousingBasicInfo({ form, type }),
            column: 2,
          },
          {
            title: '调拨设置',
            items: [
              {
                label: '调拨批量',
                render: () => <businessFieldForm.WarehousingTransferInfo form={form} />,
              },
            ],
            column: 2,
          },
          {
            title: '存储设置',
            items: businessFieldForm.warehousingStorageInfo({ form, setUpdateMark }),
            column: 2,
          },
          {
            title: '管理设置',
            items: businessFieldForm.warehousingManagementInfo({
              form,
              // customFields
            }),
            column: 2,
          },
        ];
      // 物料 采购信息
      case TabType.purchase:
        return [
          {
            items: businessFieldForm.purchaseBasicInfo({ form }),
            column: 2,
          },
        ];
      // 物料 采购信息
      case TabType.production:
        return [
          {
            items: businessFieldForm.productionBasicInfo({ form }),
            column: 2,
          },
        ];

      case TabType.manufacture:
        return [
          {
            items: businessFieldForm.manufactureBasicInfo({ form }),
            column: 2,
          },
        ];
      case TabType.customize:
        return [
          {
            items: injectCustomFieldInfos({
              customFields: customFields || {},
              type: 'form',
              formConfig: { form },
            }).items,
            column: 2,
          },
        ];
      default:
        return [
          {
            items: [],
          },
        ];
    }
  };
  const renderTab = () => {
    const bizRange = form.getFieldValue('bizRange');
    const tabTypeMapping = handleBizRangeChangeTabDefaults(
      bizRange || initialValue?.bizRange,
      customFields?.customFields,
    );

    return (
      <Tabs
        activeKey={activeKey}
        onTabClick={(key: string) => {
          setActiveKey(key);
        }}
      >
        {_.remove(
          [...tabTypeMapping],
          (n) =>
            n?.value === TabType.basic ||
            n?.value === TabType.customize ||
            n?.value === TabType.production ||
            n?.value === TabType.purchase ||
            n?.value === TabType.warehousing ||
            n?.value === TabType.manufacture,
        ).map((item) => (
          <Tabs.TabPane tab={item.label} key={String(item.value)} forceRender>
            <DataFormLayout form={form} info={renderInfo(item.value)} footer={false} />
          </Tabs.TabPane>
        ))}
      </Tabs>
    );
  };
  const renderExtra = () => {
    return (
      type === CRUD.create && (
        <Checkbox
          onChange={() => {
            setKeepCreate(!keepCreate);
          }}
          checked={keepCreate}
        >
          连续新建
        </Checkbox>
      )
    );
  };
  const handleFinish = async () => {
    try {
      const values = await form?.validateFields();

      await onSubmit?.(values, (msg: string) => {
        message.success(msg);
        if (type === CRUD.create && keepCreate) {
          form.resetFields();
          form.setFieldsValue(initialValue);
          setUpdateMark(new Date().getTime());
          return;
        }
        history.goBack();
      });
    } catch (error) {
      console.log('校验error', error);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValue);
    // 根据 bizType 设置 bizRange disabled 初始值
    const handleBizTypeOnChange = (selectedValues: any) => {
      const { optionsList } = handleBizTypeChangeDefaults(selectedValues);

      setBizRangeOptionsList(optionsList);
    };

    handleBizTypeOnChange(initialValue?.bizType);
  }, [initialValue]);

  return (
    <DataFormLayout
      form={form}
      info={[
        {
          title: '',
          items: [],
        },
      ]}
      loading={loading}
      title={`${lookup('common.crud', type) ?? ''}物料`}
      topContext={renderTab()}
      extra={renderExtra()}
      onCancel={() => {
        history.goBack();
      }}
      onFinish={handleFinish}
      formProps={formProps}
    />
  );
};

export default BaseForm;
