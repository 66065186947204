import { useRef, FC, useContext } from 'react';
import { useDrag } from 'react-dnd';
import classNames from 'classnames';
import { compact } from 'lodash';
import { Card } from 'antd';
import { BlTooltip } from 'src/components';
import { LayoutComponentType, PageType } from 'src/dict/customLayout';
import { EditorContext, isEditableField, NEW_COMPONENT_INDEX } from '../share';
import { LayoutParsedComponent, DragItemType } from '../../../type';
import styles from '../styles.module.scss';

interface CoverProps {
  type: LayoutComponentType;
  title: string;
  itemKey: string;
  draggable?: boolean;
}

type Comp = {
  type: LayoutComponentType;
  itemKey: string;
  title: string;
  draggable: boolean;
};

const CONTENT_WIDTH = 187;

const renderCovers = (list: Comp[]) =>
  list.map((item) => <ComponentCover key={item.itemKey} {...item} />);

const ComponentCover: FC<CoverProps> = ({ type, itemKey, title, draggable = true }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drag] = useDrag(() => ({
    type: DragItemType.cover,
    item: () => ({
      type,
      key: itemKey,
      index: NEW_COMPONENT_INDEX,
    }),
  }));
  const hasTooltip = CONTENT_WIDTH / (14 * title.length) < 1;

  if (draggable) {
    drag(ref);
  }

  return (
    <div ref={ref} draggable={draggable}>
      <Card
        className={classNames(
          styles['source-list-item'],
          draggable ? '' : styles['source-list-item-disabled'],
        )}
        hoverable={draggable}
        key={itemKey}
      >
        {hasTooltip ? <BlTooltip text={title} width={CONTENT_WIDTH} /> : title}
      </Card>
    </div>
  );
};

const Source: FC = () => {
  const { fieldList, pageType, form, supportTab, disabled } = useContext(EditorContext);

  const containerComps = compact([
    {
      type: LayoutComponentType.titlebar,
      itemKey: '$titlebar',
      title: '标题栏',
      draggable: !disabled,
    },
    pageType === PageType.detail &&
      supportTab && {
        type: LayoutComponentType.sonObjectTab,
        itemKey: '$sonObjectTab',
        title: '标签页',
        draggable: !disabled,
      },
    pageType === PageType.detail && {
      type: LayoutComponentType.sonObjectTable,
      itemKey: '$sonObjectTable',
      title: '主从列表',
      draggable: !disabled,
    },
  ]);

  const visibleFieldList =
    pageType === PageType.edit ? fieldList.filter(isEditableField) : fieldList;
  const fieldComps = visibleFieldList.map(({ fieldCode, fieldName }) => ({
    type: LayoutComponentType.field,
    itemKey: fieldCode!,
    title: fieldName!,
    draggable:
      !disabled &&
      !form
        ?.getFieldValue('components')
        ?.find(({ content }: LayoutParsedComponent) => content.fieldPath === fieldCode), // 字段不可重复添加
  }));

  return (
    <div className={styles['source-list']}>
      <div className={styles['source-list-part']}>{renderCovers(containerComps)}</div>
      <div className={styles['source-list-separator']} />
      <div className={styles['source-list-part']}>{renderCovers(fieldComps)}</div>
    </div>
  );
};

export default Source;
