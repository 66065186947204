import { useEffect, useState } from 'react';
import { Button, Form, FormInstance, Input, message, Modal, Space } from 'antd';
import styles from './styles.module.scss';
import { replaceSign } from 'src/utils/constants';
import { RecordListLayout } from 'src/layout';
import _Array from 'src/utils/dataTypes/array';

import _ from 'lodash';
import { fetchSalesOrderListItemDetail } from 'src/api/ytt/order-domain/salesOrder';
import { formatDateForRender, formatTimeForRender } from 'src/utils/formatters/dateTime';
import { SearchSelect, TagList } from 'src/components';
import { mapLabeledValueToValue } from 'src/utils/array';
import lookup, { appEnum } from 'src/dict';
import { ShipmentStatus } from 'src/dict/sales';
import { SalesOrderListDetailProps } from '../../salesOrder/interface';
import { fetchCustomerDeliveryScheduleNoteListItem } from 'src/api/ytt/supply-chain-domain/customer_delivery_schedule_note';
import { formatTime } from 'src/page/purchase/purchaseManagement/deliveryScheduleNote/utils';

interface Props {
  visible: Boolean;
  onCancel: () => void;
  handleFinish: (rows?: any[], selectedKeys?: React.Key[]) => void;
  addForm: FormInstance;
  invoiceType: number;
  selectedKeys?: React.Key[];
}

export default function BatchAddMaterials({
  visible,
  onCancel,
  handleFinish,
  addForm,
  invoiceType,
  selectedKeys = [],
}: Props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(selectedKeys ?? []);
  const [selectedRows, setSelectRows] = useState<any[]>();
  const [refreshMarker, setRefreshMarker] = useState(0);
  const [queryForm] = Form.useForm();

  const formatDataToQuery = ({ page, size, sorter }: { page: number; size: number }) => {
    const { materialIds, customerIds, ...rest } = queryForm.getFieldsValue();

    return {
      sorter,
      ...rest,
      materialIds: mapLabeledValueToValue(materialIds),
      customerIds: mapLabeledValueToValue(customerIds),
      page,
      size,
    };
  };

  useEffect(() => {
    setSelectedRowKeys(selectedKeys);
  }, []);
  // 销售订单-明细行 - 列
  const salesMaterialColumns = [
    {
      title: '销售订单编号',
      dataIndex: 'orderCode',
      width: 200,
      render: (orderCode: string) => orderCode ?? replaceSign,
    },
    {
      title: '来源',
      dataIndex: 'origin',
      width: 200,
      render: lookup('invoice.sailOrderOrigin'),
    },
    {
      title: '客户',
      dataIndex: ['customer', 'name'],
      width: 200,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '物料名称',
      dataIndex: ['material', 'baseInfo', 'name'],
      width: 200,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '物料编号',
      dataIndex: ['material', 'baseInfo', 'code'],
      width: 200,
      render: (code: string) => code ?? replaceSign,
    },
    {
      title: '需求数量',
      dataIndex: ['amount', 'amount'],
      width: 200,
      render: (amount: number) => amount ?? replaceSign,
    },
    {
      title: '已计划发货数',
      dataIndex: ['plannedIssueAmount', 'amount'],
      width: 200,
      render: (amount: number) => amount ?? replaceSign,
    },
    {
      title: '仓库应发数',
      dataIndex: ['warehousePlannedIssueAmount', 'amount'],
      width: 200,
      render: (amount: number) => amount ?? replaceSign,
    },
    {
      title: '仓库已发数',
      dataIndex: ['warehouseIssueAmount', 'amount'],
      width: 200,
      render: (amount: number) => amount ?? replaceSign,
    },
    {
      title: '客户已收数',
      dataIndex: ['customerReceivedAmount', 'amount'],
      width: 200,
      render: (amount: number) => amount ?? replaceSign,
    },
    {
      title: '已计划退货数',
      dataIndex: ['plannedReturnAmount', 'amount'],
      width: 200,
      render: (amount: number) => amount ?? replaceSign,
    },
    {
      title: '客户发出数',
      dataIndex: ['customerReturnedAmount', 'amount'],
      width: 200,
      render: (amount: number) => amount ?? replaceSign,
    },
    {
      title: '仓库已收数',
      dataIndex: ['warehouseReceivedAmount', 'amount'],
      width: 200,
      render: (amount: number) => amount ?? replaceSign,
    },
    {
      title: '交货日期',
      dataIndex: 'deliveryDate',
      width: 200,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      render: formatTimeForRender,
    },
    {
      title: '销售订单明细行号',
      dataIndex: 'lineNo',
      width: 200,
      render: (lineNo: string) => lineNo ?? replaceSign,
    },
    {
      title: '销售明细行发货状态',
      dataIndex: 'shipmentStatus',
      width: 200,
      render: lookup('sales.shipmentStatus'),
    },
    {
      title: '',
      dataIndex: 'action',
      width: 40,
      fixed: 'right',
      render: () => {
        return <></>;
      },
    },
  ];
  // 交货计划-明细行 - 列
  const cdsnMaterialColumns = [
    {
      title: '协同交货计划单编号',
      dataIndex: 'code',
      width: 200,
      sorter: true,
      render: (orderCode: string) => orderCode ?? replaceSign,
    },
    {
      title: '协同交货计划单行号',
      dataIndex: 'lineNo',
      width: 200,
      render: (lineNo: string) => lineNo ?? replaceSign,
    },
    {
      title: '销售订单编号',
      dataIndex: 'saleOrderCode',
      width: 200,
      render: (orderCode: string) => orderCode ?? replaceSign,
    },
    {
      title: '销售订单明细行号',
      dataIndex: 'saleOrderLineNo',
      width: 200,
      render: (orderCode: string) => orderCode ?? replaceSign,
    },
    {
      title: '客户编号',
      dataIndex: 'customerCode',
      width: 200,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 200,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '物料编号',
      dataIndex: ['material', 'baseInfo', 'code'],
      width: 200,
      render: (code: string) => code ?? replaceSign,
    },
    {
      title: '物料名称',
      dataIndex: ['material', 'baseInfo', 'name'],
      width: 200,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '物料属性',
      dataIndex: ['material', 'attribute'],
      width: 200,
      render: (
        text: { name: string; attributeItem: { content: string; id: number }; id: number }[],
      ) => {
        if (_.isEmpty(text)) return replaceSign;

        return (
          <TagList
            dataSource={_.map(text, ({ name, attributeItem, id }) => ({
              label: `${name}:${attributeItem.content}`,
              value: id,
            }))}
          />
        );
      },
    },
    {
      title: '物料规格',
      dataIndex: ['material', 'baseInfo', 'specification'],
      width: 200,
      render: (name: string) => name ?? replaceSign,
    },
    {
      title: '计划发货状态',
      dataIndex: 'plannedIssueStatus',
      width: 150,
      render: (plannedIssueStatus: number) =>
        lookup('collaborativeDeliverySchedule.plannedIssueStatus', plannedIssueStatus) ??
        replaceSign,
    },
    {
      title: '交货数量',
      dataIndex: ['amount', 'amountDisplay'],
      width: 150,
      sorter: true,
      render: (amount: number) => amount ?? replaceSign,
    },
    {
      title: '已计划发货数',
      dataIndex: ['plannedIssueAmount', 'amountDisplay'],
      width: 150,
      sorter: true,
      render: (amount: number) => amount ?? replaceSign,
    },
    {
      title: '待发货数',
      dataIndex: ['availableIssueAmount', 'amountDisplay'],
      width: 150,
      sorter: true,
      render: (amount: number) => amount ?? replaceSign,
    },
    {
      title: '交货日期',
      dataIndex: 'deliveryTime',
      width: 200,
      sorter: true,
      render: formatDateForRender,
    },
    {
      title: '最早到货时间',
      dataIndex: 'earliestArrivalTime',
      width: 200,
      sorter: true,
      dateFormat: 'HH:mm:ss',
      render: (deliveryDate: number) => {
        return formatTime(deliveryDate) ?? replaceSign;
      },
    },
    {
      title: '最晚到货时间',
      dataIndex: 'latestArrivalTime',
      width: 200,
      sorter: true,
      dateFormat: 'HH:mm:ss',
      render: (deliveryDate: number) => {
        return formatTime(deliveryDate) ?? replaceSign;
      },
    },
    {
      title: '最早发货时间',
      dataIndex: 'earliestDeliveryTime',
      width: 200,
      dateFormat: 'HH:mm:ss',
      render: (deliveryDate: number) => {
        return formatTime(deliveryDate) ?? replaceSign;
      },
    },
    {
      title: '最晚发货时间',
      dataIndex: 'latestDeliveryTime',
      width: 200,
      dateFormat: 'HH:mm:ss',
      render: (deliveryDate: number) => {
        return formatTime(deliveryDate) ?? replaceSign;
      },
    },
    {
      title: '',
      dataIndex: 'action',
      width: 40,
      fixed: 'right',
      render: () => {
        return <></>;
      },
    },
  ];

  return (
    <Modal
      visible={Boolean(visible)}
      title={
        invoiceType === appEnum.Invoice.InvoiceOrigin.collaborativeDeliverySchedule
          ? '按协同交货计划单添加物料'
          : '按销售订单添加物料'
      }
      cancelText="暂不添加"
      okText="添加"
      onCancel={() => {
        typeof onCancel === 'function' && onCancel();
        addForm.resetFields();
      }}
      width={1600}
      onOk={() => {
        if (_Array.isEmpty(selectedRows) && _Array.isEmpty(selectedRowKeys)) {
          message.error('请选中订单后再添加');
        } else {
          handleFinish(selectedRowKeys, selectedRows);
        }
      }}
      className={styles.addMaterialModal}
      destroyOnClose
      keyboard={false}
      maskClosable={false}
    >
      <Form form={queryForm} size="small" colon={false} className={styles.queryForm}>
        <Space wrap>
          <Form.Item
            label={
              invoiceType === appEnum.Invoice.InvoiceOrigin.collaborativeDeliverySchedule
                ? '协同交货计划单编号'
                : '订单编号'
            }
            name="code"
          >
            <Input placeholder={'请输入订单编号'} />
          </Form.Item>

          <Form.Item
            label={
              invoiceType === appEnum.Invoice.InvoiceOrigin.collaborativeDeliverySchedule
                ? '客户'
                : '客户名称'
            }
            name="customerIds"
          >
            <SearchSelect fetchType="customer" mode="multiple" />
          </Form.Item>
          <Form.Item
            label={
              invoiceType === appEnum.Invoice.InvoiceOrigin.collaborativeDeliverySchedule
                ? '物料'
                : '物料编号'
            }
            name="materialIds"
          >
            <SearchSelect fetchType="material" mode="multiple" labelPath="code" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                setRefreshMarker(Date.now);
              }}
            >
              搜索
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <RecordListLayout<SalesOrderListDetailProps>
        columns={
          invoiceType === appEnum.Invoice.InvoiceOrigin.collaborativeDeliverySchedule
            ? cdsnMaterialColumns
            : salesMaterialColumns
        }
        refreshMarker={refreshMarker}
        rowKey="id"
        filterContaniner={false}
        useFilterWithUrl={false}
        useQuickFilter={false}
        selectedRowKeys={selectedRowKeys}
        configcacheKey="batchAddMaterial"
        onSelectedRowKeys={(selectKey: any, selectRows?: any[]) => {
          setSelectedRowKeys(selectKey);
          setSelectRows(selectRows);
        }}
        requestFn={async (params) => {
          switch (invoiceType) {
            case appEnum.Invoice.InvoiceOrigin.collaborativeDeliverySchedule:
              return fetchCustomerDeliveryScheduleNoteListItem({
                ...params,
                status: [appEnum.CollaborativeDeliverySchedule.OrderStatus.inprogress],
                plannedIssueStatus: [
                  appEnum.CollaborativeDeliverySchedule.PlannedIssueStatus.toPlan,
                  appEnum.CollaborativeDeliverySchedule.PlannedIssueStatus.partPlan,
                ],
              });

            default:
              return fetchSalesOrderListItemDetail({
                ...params,
                // 发货单来源的枚举值会比销售订单枚举值增1
                origin: [invoiceType - 1],
                shipmentStatus: [ShipmentStatus.toBeDelivered, ShipmentStatus.partiallyShipped],
                deliveryMode: [appEnum.Purchase.PurchaseOrderdeliveryMode.purchaseOrder],
              });
          }
        }}
        formatDataToQuery={formatDataToQuery}
      />
    </Modal>
  );
}
