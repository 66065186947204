import _ from 'lodash';

/**
 * 根据标准总工时(秒)计算各个时间单位上的数值
 * @param time 总标准工时
 */
export function getStandardWorkTime(time?: number) {
  const baseH = 60 * 60;
  const baseM = 60;

  const retTime = {
    H: time,
    M: time,
    S: time,
  };

  if (_.isNil(time) || time === 0) return retTime;

  retTime.H = Math.floor(time / baseH);
  retTime.M = Math.floor((time - retTime.H * baseH) / baseM);
  retTime.S = time - retTime.H * baseH - retTime.M * baseM;

  return retTime;
}

/**
 * 把标准工时各个时间单位数值加起来算总
 * @param H
 * @param M
 * @param S
 */
export function setStandardWorkTime(H?: number, M?: number, S?: number) {
  if (_.isNil(H) && _.isNil(M) && _.isNil(S)) return undefined;

  const baseH = 60 * 60;
  const baseM = 60;

  return (H || 0) * baseH + (M || 0) * baseM + (S || 0);
}
