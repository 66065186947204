/**
 * @page 自定义对象实例列表
 */
import { useCallback, useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import _, { map } from 'lodash';
import { RecordListLayout } from 'layout';
import { BlIcon, LinkTooltip, SingleOperationLogComponent } from 'components';
import { fetchCustomFieldGetListByCustomObjectCode } from 'src/api/ytt/metadata-domain/customField';
import { fetchCustomObjectList } from 'src/api/ytt/custom-object-domain';
import lookup from 'src/dict';
import UserOrDepartmentSelectWithDialog from 'src/page/organization/components/userAndDepartmentSelect/UserOrDepartmentSelectWithDialog';
import { CRUD, FieldType } from 'src/dict/common';
import { formatTimeForRender, formatTimeRangeForQuery } from 'src/utils/formatters';
import { renderUser } from 'src/page/share/renderUser';
import { SORTABLE_FIELD_TYPES, FILTABLE_FIELD_TYPES } from 'src/page/custom_fields/constants';
import {
  getDisplayNodeRenderer,
  getFilterInputControl,
  getFilterSelectProps,
  excludeSubObjFields,
} from 'src/page/customLayout';
import {
  escalateFieldsToList,
  getDetailPath,
  getCreatePath,
  getEditPath,
  getCopyPath,
  getObjectAuthCode,
  getImportPath,
  getListPath,
} from './utils';
import type { Moment } from 'moment';
import type { FieldDTO } from 'src/page/customLayout/types';
import type { CustomObjectData } from 'src/page/custom_fields/type';
import { CustomObjectListItemDTO, OperationCode } from './types';
import { fetchMatchRuleQueryMatchTemplate } from 'src/api/ytt/e-report-domain/matchRule';
import ChooseTemplateModal from '../analyse/objectMatchingRule/share/chooseTemplateModal';
import { exportObjectFile } from 'src/page/import/utils';
import { APPLICATION_NAME } from 'src/dict/objImport';
import { qs } from 'utils';
import { CUSTOM_APPLICATION_NAME, TEMPLATE_TYPE } from 'src/page/import/constaint';
import { getPathList } from 'echarts/types/src/animation/morphTransitionHelper';

const getAddOrPush = (obj: { [p: string]: any }, value: any) => (key: string) => {
  if (_.isArray(obj[key])) {
    obj[key].push(value);
  } else {
    obj[key] = [value];
  }
};

const CustomObjectList = (props: CustomObjectData) => {
  const history = useHistory();
  const { objectCode, objectName } = props;
  // 对象的自定义字段
  const [fields, setFields] = useState<FieldDTO[]>([]);

  const [refreshMarker, setRefreshMarker] = useState<number>(Date.now()); // 刷新页面
  const [logInstanceId, setLogInstanceId] = useState(0);
  const refresh = () => setRefreshMarker(Date.now());
  const shortObjectName = _.truncate(objectName, { length: 9 });
  const [chooseVisible, setChooseVisible] = useState<boolean>(false);
  const [templateData, setTemplateData] = useState<any>([]);

  const [searchData, setSearchData] = useState<any>();

  useEffect(() => {
    // 获取列信息（从对象不展示）
    fetchCustomFieldGetListByCustomObjectCode({ objectCode }).then((res) => {
      setFields(excludeSubObjFields(res.data ?? []));
    });
  }, [objectCode]);

  const dataColumns = [
    // 本期不做, 只有一个默认业务类型
    // {
    //   title: '业务类型',
    //   dataIndex: 'entityType',
    //   width: 150,
    //   isFilter: true,
    //   type: FieldType.reference,
    // },
    ...fields.map((item) => {
      const isFilter = FILTABLE_FIELD_TYPES.includes(item.fieldType!);
      const col: any = {
        title: item.fieldName,
        dataIndex: item.fieldCode,
        width: 150,
        sorter: SORTABLE_FIELD_TYPES.includes(item.fieldType!),
        render: (value: any, record: any) => {
          const result = getDisplayNodeRenderer(item, { isInTable: true })(value);

          if (item.isName || item.fieldType === FieldType.appendix) {
            return (
              <LinkTooltip
                text={result}
                to={generatePath(getDetailPath(), { instanceId: record.$instanceId })}
                width={200}
                auth={getObjectAuthCode(objectCode, OperationCode.detail)}
              />
            );
          }
          return result;
        },
      };

      if (isFilter) {
        col.isFilter = true;
        col.type = item.fieldType;
        col.selectProps = getFilterSelectProps(item);
        col.renderItem = getFilterInputControl(item);
      }
      return col;
    }),
    {
      title: '创建人',
      dataIndex: '$creator',
      width: 150,
      render: renderUser,
      isFilter: true,
      type: FieldType.reference,
      renderItem: <UserOrDepartmentSelectWithDialog isSelectUser isMultiple isNewFormat />,
    },
    {
      title: '创建时间',
      dataIndex: '$createdAt',
      width: 180,
      render: formatTimeForRender,
      sorter: true,
      isFilter: true,
      type: FieldType.date,
    },
    {
      title: '更新人',
      dataIndex: '$operator',
      width: 150,
      render: renderUser,
      isFilter: true,
      type: FieldType.reference,
      renderItem: <UserOrDepartmentSelectWithDialog isSelectUser isMultiple isNewFormat />,
    },
    {
      title: '更新时间',
      dataIndex: '$updatedAt',
      width: 180,
      render: formatTimeForRender,
      sorter: true,
      isFilter: true,
      type: FieldType.date,
    },
  ];

  const mainMenu = [
    {
      title: '导出日志',
      auth: getObjectAuthCode(objectCode, OperationCode.export),
      showExport: true,
      onClick: () => {
        history.push(
          `${getListPath()}/exportLog?${qs.stringify({
            applicationName: CUSTOM_APPLICATION_NAME,
            businessType: objectCode,
            isCustomObject: true,
          })}`,
        );
      },
    },
    {
      title: '导入日志',
      auth: getObjectAuthCode(objectCode, OperationCode.import),
      showExport: true,
      onClick: () => {
        history.push(
          `${getListPath()}/importLog?${qs.stringify({
            applicationName: CUSTOM_APPLICATION_NAME,
            businessType: objectCode,
            isCustomObject: true,
          })}`,
        );
      },
    },
    {
      title: '导出',
      icon: <BlIcon type="icondaochu" />,
      auth: getObjectAuthCode(objectCode, OperationCode.export),
      showExport: true,
      onClick: async () => {
        await exportObjectFile({
          applicationName: CUSTOM_APPLICATION_NAME,
          businessData: searchData,
          businessType: objectCode,
        });
      },
    },
    {
      title: '导入',
      icon: <BlIcon type="icondaoru" />,
      auth: getObjectAuthCode(objectCode, OperationCode.import),
      showExport: true,
      onClick: () => {
        history.push(
          `${getImportPath()}?${qs.stringify({
            applicationName: CUSTOM_APPLICATION_NAME,
            businessType: objectCode,
            templateType: TEMPLATE_TYPE.custom,
          })}`,
        );
      },
    },
    {
      title: `${lookup('crud', CRUD.create)}${shortObjectName}`,
      icon: <BlIcon type="iconxinjiantianjia" />,
      auth: getObjectAuthCode(objectCode, OperationCode.add),
      onClick: () => {
        history.push(getCreatePath());
      },
      items: [],
    },
  ];

  const filterList = dataColumns
    .filter((i) => i.isFilter)
    .map((column) => {
      const filter = {
        label: column.title,
        name: column.dataIndex,
        type: column.type,
        renderItem: column.renderItem,
        selectProps: column.selectProps,
      } as any;

      return filter;
    });

  const formatDataToQuery = useCallback(
    (values: any) => {
      const { quickSearch, page, size, $creator, $createdAt, $operator, $updatedAt } = values;
      const submitValue: any = {
        objectCode,
        page,
        size,
      };

      // 快捷搜索针对主属性
      if (!_.isNil(quickSearch) && quickSearch !== '') {
        submitValue.mainPropertyCondition = quickSearch;
      }
      // 将原生字段归类到相应的查询组
      if (!_.isEmpty($creator)) {
        getAddOrPush(submitValue, {
          fieldCode: 'creator',
          fieldValues: _.map($creator, 'id'),
        })('relateCondition');
      }
      if (!_.isEmpty($operator)) {
        getAddOrPush(submitValue, {
          fieldCode: 'operator',
          fieldValues: _.map($operator, 'id'),
        })('relateCondition');
      }
      if ($createdAt) {
        getAddOrPush(submitValue, {
          fieldCode: 'createdAt',
          fieldValues: formatTimeRangeForQuery($createdAt),
        })('datetimeCondition');
      }
      if ($updatedAt) {
        getAddOrPush(submitValue, {
          fieldCode: 'updatedAt',
          fieldValues: formatTimeRangeForQuery($updatedAt),
        })('datetimeCondition');
      }
      // 将不同的字段归类到不同的查询组
      _.entries(values).forEach(([fieldCode, v]) => {
        const fieldDef = _.find(fields, { fieldCode });

        if (!fieldDef || _.isNil(v) || v === '') {
          return;
        }
        let fieldValues = _.isArray(v) ? v : [v];

        if (fieldDef.fieldType === FieldType.date) {
          fieldValues = formatTimeRangeForQuery(fieldValues as [Moment, Moment])!;
        } else if (fieldDef.fieldType === FieldType.relation) {
          fieldValues = fieldValues.map((val) => val?.value ?? val?.id);
        } else if (fieldDef.fieldType === FieldType.boolean) {
          fieldValues = fieldValues.map((n) => !!n);
        }
        const addOrPush = getAddOrPush(submitValue, {
          fieldCode,
          fieldValues,
        });

        switch (fieldDef.fieldType) {
          case FieldType.boolean:
            addOrPush('boolCondition');
            break;
          case FieldType.date:
            addOrPush('datetimeCondition');
            break;
          case FieldType.integer:
            addOrPush('integerCondition');
            break;
          case FieldType.number:
            addOrPush('numericCondition');
            break;
          case FieldType.multiSelect:
            addOrPush('multipleChoiceCondition');
            break;
          case FieldType.reference:
            addOrPush('referenceCondition');
            break;
          case FieldType.relation:
            addOrPush('relateCondition');
            break;
          case FieldType.select:
            addOrPush('singleChoiceCondition');
            break;
          case FieldType.text:
            addOrPush('singleTextCondition');
            break;
        }
      });

      setSearchData(_.omit(submitValue, ['page', 'size']));
      return submitValue;
    },
    [objectCode, fields],
  );

  const getMatchTemplate = async (instanceId: string, objectCode: string) => {
    try {
      const { data } = await fetchMatchRuleQueryMatchTemplate({
        instanceId,
        objectCode,
      });

      if (data) {
        setTemplateData(
          map(data, (e: any) => {
            return {
              ...e,
              instanceId,
              objectCode,
            };
          }),
        );

        setChooseVisible(true);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getOperationList = (record: any) => {
    const { $instanceId: instanceId } = record;

    return [
      {
        title: lookup('crud', CRUD.view),
        auth: getObjectAuthCode(objectCode, OperationCode.detail),
        onClick() {
          history.push(generatePath(getDetailPath(), { instanceId }));
        },
      },
      {
        title: lookup('crud', CRUD.edit),
        auth: getObjectAuthCode(objectCode, OperationCode.edit),
        onClick() {
          history.push(generatePath(getEditPath(), { instanceId }));
        },
      },
      {
        title: lookup('crud', CRUD.copy),
        auth: getObjectAuthCode(objectCode, OperationCode.add),
        onClick() {
          history.push(generatePath(getCopyPath(), { instanceId }));
        },
      },
      {
        title: '操作记录',
        auth: getObjectAuthCode(objectCode, OperationCode.operrecord),
        onClick() {
          setLogInstanceId(instanceId);
        },
      },
      {
        title: '打印预览',
        onClick() {
          getMatchTemplate(instanceId, objectCode);
        },
      },
    ];
  };

  return (
    <>
      <RecordListLayout<CustomObjectListItemDTO>
        columns={dataColumns}
        filterList={filterList}
        rowKey={'id'}
        mainMenu={mainMenu}
        formatDataToQuery={formatDataToQuery}
        formatDataToDisplay={_.identity}
        formatDataToFormDisplay={_.identity}
        configcacheKey={`customObject_${objectCode}`}
        requestFn={escalateFieldsToList(fetchCustomObjectList)}
        refreshMarker={refreshMarker}
        getOperationList={getOperationList}
      />
      <SingleOperationLogComponent
        visible={!!logInstanceId}
        instanceId={logInstanceId}
        objectCode={objectCode}
        closeDetail={() => {
          setLogInstanceId(0);
        }}
      />
      {chooseVisible && (
        <ChooseTemplateModal
          visible={chooseVisible}
          initData={templateData}
          onCancel={() => {
            setChooseVisible(false);
          }}
        />
      )}
    </>
  );
};

export default CustomObjectList;
