import { FC, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { fetchCustomObjectReferenceFieldByRelateField } from 'src/api/ytt/custom-object-domain';
import { getDisplayNodeRenderer } from './getDisplayNodeRenderer';
import { getSharableFetch } from 'src/utils/request';
import { replaceSign } from 'src/utils/constants';
import { getFieldRealValue } from 'src/page/customObject/utils';
import { isSelectableField } from 'src/page/custom_fields/utils';
import type { FormInstance } from 'antd/lib/form';
import type { FetchCustomObjectReferenceFieldByRelateFieldResponse } from 'src/api/ytt/custom-object-domain';
import type { FieldDTO, NamePath } from '../types';

export interface ReferControlProps {
  parentObjectCode: string;
  form: FormInstance;
  targetNamePath: NamePath;
  fields: FieldDTO[];
  field: FieldDTO;
  isReferringMainObj?: boolean;
}
type ReferEntityField = NonNullable<
  NonNullable<FetchCustomObjectReferenceFieldByRelateFieldResponse['data']>['fields']
>[number];

// 监听同一个关联字段的不同引用字段控件，共用同一个请求
const fetchReferenceFieldValue = getSharableFetch(fetchCustomObjectReferenceFieldByRelateField);

/** 构造字段实例数据，与后端返回的格式保持一致 */
const constructEntityField = (field: FieldDTO, formValue: any) => {
  if (isSelectableField(field.fieldType!)) {
    const choiceValues = formValue
      ? field?.choiceValues?.filter((c) => {
          return _.isArray(formValue)
            ? _.map(formValue, 'value').includes(c.choiceCode)
            : formValue.value === c.choiceCode;
        })
      : undefined;

    return { ...field, choiceValues };
  }
  return { ...field, fieldValue: formValue };
};

/**
 * 引用字段控件
 * 与 FormItem上的 dependencies 属性配合, 监听被引用字段值的变化, 并拉取对应的字段值
 */
const ReferControl: FC<ReferControlProps> = ({
  parentObjectCode,
  form,
  targetNamePath,
  fields,
  field,
  isReferringMainObj = false,
}) => {
  const [entityField, setEntityField] = useState<ReferEntityField>();
  const [targetFieldCode, setTargetFieldCode] = useState<string>('默认值');
  const [targetInstanceId, setTargetInstanceId] = useState(0);
  const [targetObjectId, setTargetObjectId] = useState(0);
  // 这个state用于引用主对象的情况
  const [targetValue, setTargetValue] = useState<any>();

  // 当引用指向主从关系字段时，这个值是主对象上被引用的字段对应的输入控件（如<Input>）的值
  // 当引用指向关联关系字段时，这个值是选择关联对象的控件（如<SearchSelect>）的值
  const nextTargetFormValue = form.getFieldValue(targetNamePath);

  if (isReferringMainObj && nextTargetFormValue !== targetValue) {
    setTargetValue(nextTargetFormValue);
  } else if (!_.isEmpty(nextTargetFormValue)) {
    const nextTargetInstanceId = _.isArray(nextTargetFormValue)
      ? nextTargetFormValue[0].id
      : nextTargetFormValue.value;

    if (nextTargetInstanceId !== targetInstanceId) {
      setTargetInstanceId(nextTargetInstanceId);
    }
  }

  useEffect(() => {
    if (!isReferringMainObj) {
      // 找出目标关联关系的 objectId
      const targetFieldId = _.toNumber(field.referenceChain!.split('#')[0]);
      const targetField = _.find(fields, { id: targetFieldId });

      setTargetFieldCode(targetField?.fieldCode!);
      setTargetObjectId(targetField?.relatedObjectId!);
    }
  }, [fields, field, isReferringMainObj]);

  useEffect(() => {
    // 如果是引用主对象的属性，直接从表单里取值
    if (isReferringMainObj) {
      setEntityField(constructEntityField(field, nextTargetFormValue));
    }
    // 监听到引用目标变化时, 调接口拿数据
    else if (targetObjectId && targetInstanceId) {
      fetchReferenceFieldValue({
        fieldCode: targetFieldCode,
        parentObjectCode,
        relateFieldInstanceId: targetInstanceId,
      }).then((res) => {
        const values = res.data?.fields ?? [];

        if (!_.isEmpty(values)) {
          setEntityField(_.find(values, { fieldCode: field.fieldCode }));
        }
      });
    }
  }, [targetObjectId, targetInstanceId, targetValue, isReferringMainObj, field]);

  const render = useMemo(() => {
    if (entityField) {
      return getDisplayNodeRenderer(entityField);
    }
    return null;
  }, [entityField]);

  return render ? render(getFieldRealValue(entityField!)) : replaceSign;
};

export default ReferControl;
