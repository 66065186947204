import { BlUpload, BlUploadProps } from '@blacklake-web/component';

import { BlIcon } from 'src/components';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { fetchFileListByIds } from 'src/api/ytt/holyfile-domain';
import { customUploadFile, formatterUrl } from '../share';
import type { FetchFileListByIdsResponse } from 'src/api/ytt/holyfile-domain';

type fileResponse = Exclude<FetchFileListByIdsResponse['data'], undefined>[number];

export type FileUploaderProps = BlUploadProps & {
  // 上传文件拖拽区域的提示文字
  fileExtensionLimitHint?: string;
  value?: number[];
  onChange?: (ids: any[]) => void;
};

const FileUploader: React.FC<FileUploaderProps> = (props) => {
  const {
    value,
    onChange,
    showUploadList,
    fileExtensionLimitHint,
    children,
    defaultFiles,
    ...rest
  } = props;
  const [fileList, setFileList] = useState<any>([]);

  const fileFormatter = (file: fileResponse) => {
    return {
      uid: file.id,
      name: decodeURIComponent(file.filename || ''),
      url: formatterUrl(file.fileUri),
      status: 'done',
      ...file,
    };
  };

  const getFilesByIds = async (idList: number[]) => {
    const { data } = await fetchFileListByIds({ idList });

    setFileList(data?.map((item: fileResponse) => fileFormatter(item)) || []);
  };

  useEffect(() => {
    if (value && !isEmpty(value)) {
      getFilesByIds(value);
    } else {
      getFilesByIds(defaultFiles);
    }
  }, [value, defaultFiles]);

  return (
    <BlUpload
      onUploaded={(files: any) => {
        const ids = files.map((o: any) => {
          if (o.response) {
            return o.response.id;
          }
          return o.id;
        });
        onChange?.(ids);
      }}
      defaultFiles={fileList}
      draggable
      customRequest={customUploadFile}
      {...rest}
    >
      {children ? (
        children
      ) : (
        <div
          style={{
            cursor: 'pointer',
            padding: '40px 50px',
            textAlign: 'center',
            borderRadius: 2,
          }}
        >
          <BlIcon type="iconshangchuanwenjian1" className={styles.uploadIcon} />
          <h4>点击或将文件拖拽到这里上传</h4>
          <p>{fileExtensionLimitHint}</p>
        </div>
      )}
    </BlUpload>
  );
};

export default FileUploader;
