import { CheckCircleOutlined } from '@ant-design/icons';
import { Badge, Form, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import {
  fetchUnitDetail,
  FetchUnitDetailResponse,
  fetchUnitStop,
  fetchUnitStart,
  fetchUnitDelete,
} from 'src/api/ytt/material-domain/web-unit';
import { DetailLayout, DetailLayoutInfoBlock } from 'src/layout';
import confirm from 'src/page/knowledgeManagement/share/modalConfirm';
import _ from 'lodash';
import lookup from 'src/dict';
import { replaceSign } from 'src/utils/constants';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { getDefaultPrecisionFigure } from '../utils';
import authDict from 'src/utils/auth';

interface Props {
  id: number;
  showEdit: () => void;
  showOperationRecord: () => void;
  refreshDetailsMarker: number | undefined;
  refreshTable?: () => void;
  onCancel?: () => void;
}
type IAutoGeneratedUnitDetailResponse = FetchUnitDetailResponse['data'];
const UnitsDetailsBaseInfo = (props: Props) => {
  const { id, showEdit, showOperationRecord, refreshDetailsMarker, refreshTable, onCancel } = props;
  const [dataSource, setDataSource] = useState<IAutoGeneratedUnitDetailResponse>({});
  const [delForm] = Form.useForm();
  const [visibleLog, setVisibleLog] = useState<boolean>(false);

  const init = async () => {
    try {
      const { data } = await fetchUnitDetail({ id });

      setDataSource(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinish = async (id: number | number[]) => {
    try {
      const value = await delForm?.validateFields();
      const ids = typeof id === 'number' ? [id] : id;
      const { code, message: mes } = await fetchUnitDelete({
        ids,
        reason: value.delReason,
      });

      if (code === 200) {
        message.success('删除成功');
        // 关闭details
        typeof onCancel === 'function' && onCancel();
        // 刷新 list
        typeof refreshTable === 'function' && refreshTable();
        return;
      }
      message.error(mes);
    } catch (error) {
      console.log(error);
    }
  };
  const initBaseMenu = useCallback(
    (status: number) =>
      _.compact([
        status && {
          key: 'stop',
          auth: authDict.unit_enable_disable,
          onClick: async () => {
            try {
              await fetchUnitStop({ ids: [id] });
              init();
              typeof refreshTable === 'function' && refreshTable();
              message.success('停用成功');
            } catch (err) {
              console.log(err);
            }
          },
          icon: <BlIcon type="icontingyong" />,
          title: '停用',
        },
        !status && {
          key: 'start',
          auth: authDict.unit_enable_disable,
          onClick: async () => {
            try {
              await fetchUnitStart({ ids: [id] });
              init();
              typeof refreshTable === 'function' && refreshTable();
              message.success('启用成功');
            } catch (err) {
              console.log(err);
            }
          },
          icon: <CheckCircleOutlined />,
          title: '启用',
        },
        // {
        //   key: 'delete',
        //   onClick: () => {
        //     confirm('是否删除当前单位', delForm, () => {
        //       handleFinish(id);
        //     });
        //   },
        //   // icon: <<BlIcon type="iconshanchulajitong1" /> />,
        //   title: '删除',
        // },
        {
          key: 'record',
          auth: authDict.unit_operrecord,
          onClick: () => {
            setVisibleLog(true);
          },
          // icon: <FileTextOutlined />,
          title: '操作记录',
        },
        {
          key: 'edit',
          onClick: () => {
            showEdit();
          },
          icon: <BlIcon type="iconbianji" />,
          auth: authDict.unit_edit,
          title: '编辑',
        },
      ]),
    [id],
  );

  const baseInfo: DetailLayoutInfoBlock = {
    items: [
      { label: '单位编号', dataIndex: 'code', render: (text: string) => text ?? replaceSign },
      { label: '单位名称', dataIndex: 'name', render: (text: string) => text ?? replaceSign },
      {
        label: '舍入规则',
        dataIndex: 'roundingRole',
        render: (text: number) => lookup('material.roundingRule', text),
      },
      {
        label: '单位精度',
        dataIndex: 'precisionFigure',
        render: (text: number | null) => {
          return typeof text === 'number' ? `${getDefaultPrecisionFigure(text)}位` : replaceSign;
        },
      },
      {
        label: '单位状态',
        dataIndex: 'enableFlag',
        span: 2,
        render: (text: number) => (
          <span>
            <Badge status={text ? 'success' : 'error'} /> {lookup('common.usageStatus', text)}
          </span>
        ),
      },
      {
        label: '单位备注',
        dataIndex: 'remark',
        span: 2,
        render: (text: string) => text ?? replaceSign,
      },
    ],
    column: 2,
  };
  const infoArray = [baseInfo];

  useEffect(() => {
    init();
  }, [refreshDetailsMarker]);
  return (
    <>
      <DetailLayout
        title={'单位详情'}
        baseMenu={initBaseMenu(dataSource?.enableFlag as number)}
        info={infoArray}
        dataSource={dataSource}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={id as number}
          objectCode={OBJECT_OF_CODE.materialUnit}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default UnitsDetailsBaseInfo;
