import { forwardRef } from 'react';
import { BlIcon, Tooltip } from 'components';
import type { NodeProps } from '../index.d';
import styles from '../style.module.scss';

interface Props {
  ref?: any;
  name?: string;
  nodeInfo: any;
  index: number;
  onAdd?: (id: any) => void;
  onDelete?: (id: any) => void;
  onClick?: (index: number) => void;
  selecting?: boolean;
}

const NodeContainer = forwardRef((props: Props) => {
  const { selecting, name, index, onClick, onDelete, onAdd, nodeInfo, ref, ...rest } = props;
  const nodeStyle = [styles.nodeContainer];

  if (selecting) {
    nodeStyle.push(styles.editingNodeContainer);
  }

  const addNextNode = (e: any) => {
    e?.stopPropagation();
    onAdd?.(index);
  };

  const handleClick = (e: any) => {
    e?.stopPropagation();
    onClick?.(index);
  };

  const delNode = (e: any) => {
    e?.stopPropagation();
    onDelete?.(index);
  };

  const renderBadge = () => {
    return <div className={styles.badge}>{index + 1}</div>;
  };
  const renderAddIcon = () => {
    return (
      <BlIcon
        disabled
        onClick={addNextNode}
        type="icona-mianxingtianjia2"
        className={styles.spot}
      />
    );
  };

  const renderDeleteIcon = () => {
    return (
      <BlIcon disabled onClick={delNode} type="iconlieshanchu" className={styles.deleteIcon} />
    );
  };

  return (
    <div {...rest} ref={ref} onClick={handleClick} className={nodeStyle.join(' ')}>
      {nodeInfo?.processSeq ? renderBadge() : null}
      {onAdd && renderAddIcon()}
      {onDelete && renderDeleteIcon()}
      <div title={nodeInfo?.processName} className={styles.nodeInner}>
        <Tooltip text={nodeInfo?.processName ?? ''} width={130} />
      </div>
    </div>
  );
});

export default NodeContainer;
