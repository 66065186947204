/**
 * 获取某对象下的字段
 * 并将字段转为 cascader 组件可接受的option的格式
 */
import { useState, useEffect, ReactNode } from 'react';
import _ from 'lodash';

import { fetchTriggerRuleTreeByCode } from 'src/api/ytt/trigger-domain/triggerRule';

export interface CascaderOption {
  value: string | number;
  label?: string | ReactNode;
  disabled?: boolean;
  children?: CascaderOption[];
  // 标记是否为叶子节点，设置了 `loadData` 时有效
  // 设为 `false` 时会强制标记为父节点，即使当前节点没有 children，也会显示展开图标
  isLeaf?: boolean;
  info?: any;
  dataSource?: any;
  key?: number | string;
}

const handleFormatData = (
  list: any[],
  fieldNames: {
    label: string;
    value: string | string[];
    children: string;
    getDataInfo?: (item: any) => any;
  },
  discardedItem?: (item: any) => boolean,
): any[] => {
  const data = list?.map((item: any) => {
    // 判断item是否满足要求
    if (discardedItem?.(item)) return undefined;

    const children = _.get(item, fieldNames.children);
    const info = fieldNames.getDataInfo?.(item);

    if (item?.leaf === 0 || children?.length) {
      return {
        label: _.get(item, fieldNames.label),
        value: _.get(item, fieldNames.value),
        children: handleFormatData(children, fieldNames),
        info,
      };
    }
    return {
      label: _.get(item, fieldNames.label),
      value: _.get(item, fieldNames.value),
      isLeaf: !item.hasChildren,
      children: undefined,
      info,
    };
  });

  return data?.filter((item) => !!item);
};

/**
 * 返回一个对象下的子孙字段及其从对象下子孙字段组成的树
 *
 * code 为空字符串或空时，返回空数组
 */
const useStandardBizToCascaderOption = (
  code: string | undefined,
  depth: number,
  config?: {
    setLoading?: (loading: boolean) => void;
    discardedItem?: (item: any) => boolean;
    getDataInfo?: (item: any) => any;
  },
): CascaderOption[] => {
  const [option, setOption] = useState<CascaderOption[]>([]);
  const { setLoading, discardedItem, getDataInfo } = config || {};

  useEffect(() => {
    if (!code) return;
    setLoading?.(true);
    fetchTriggerRuleTreeByCode({ code, depth })
      .then((res) => {
        if (res.code === 200) {
          const selectOptionsList = handleFormatData(
            res?.data?.fields ?? [],
            {
              label: 'fieldName',
              value: 'id',
              children: 'object.fields',
              getDataInfo,
            },
            discardedItem,
          );

          setOption(selectOptionsList);
        }
      })
      .catch(() => {})
      .finally(() => setLoading?.(false));
  }, [code, depth]);

  return option;
};

export default useStandardBizToCascaderOption;
