import { LabeledValue } from 'antd/lib/select';
import _ from 'lodash';
import { KNOWN_EMPTY_LIST_PARAM } from '@blacklake-web/layout';

export const arrayIsEmpty = (array: any) => {
  return !(Array.isArray(array) && array.length > 0);
};

export const intersectionValueOfId = (...args: LabeledValue[][]) => {
  const nonEmptyArgs = args.filter((arg) => {
    return arg?.length;
  });

  // 没有选项有值，不管用户是否点选过表单
  if (!nonEmptyArgs?.length) {
    return undefined;
  }
  // 只有一个选项有值，返回该选项列表
  if (nonEmptyArgs?.length === 1) {
    return nonEmptyArgs[0].map((item) => Number(item.value));
  }
  // 如果交集为空，则输出特殊状态，不实际发送列表请求
  const intersectionArgs = _.intersectionBy(...nonEmptyArgs, 'value').map((arrItem) =>
    Number(arrItem.value),
  );

  return intersectionArgs?.length ? intersectionArgs : KNOWN_EMPTY_LIST_PARAM;
};

export const mapLabeledValueToValue = (labeledValue: LabeledValue[] | undefined) =>
  labeledValue?.map((item) => item.value);
