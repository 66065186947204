/**
 * @enum 出入库，调拨单业务模块枚举值
 */

/**
 * 出库业务类型
 */
export enum OutBoundTypeEnum {
  /**
   * 其他出库
   */
  other = 0,
  /**
   * 销售发货
   */
  sales,
  /**
   * 采购退料
   */
  purchase,
}

/**
 * 入库业务类型
 */
export enum InBoundTypeEnum {
  /**
   * 其他入库
   */
  other = 0,
  /**
   * 采购收货
   */
  purchase,
  /**
   * 销售退货
   */
  sales,
}

/**
 * 调拨单业务类型
 */
export enum TransferTypeEnum {
  /**
   * 仓储调拨
   */
  storage = 0,
  /**
   * 领料调拨
   */
  picking,
}

/**
 * 出入库状态
 */
export enum StatusEnum {
  /**
   * 新建
   */
  xinjian = 0,
  /**
   * 已下发
   */
  xiafa,
  /**
   * 执行中
   */
  zhixing,
  /**
   * 已完成
   */
  yiwancheng,
  /**
   * 已关闭
   */
  yiguanbi,
}

/**
 * 库存质量状态
 */
export enum qualityStatusEnum {
  /**
   * 合格
   */
  qualified = 1,
  /**
   * 让步合格
   */
  concession,
  /**
   * 待检
   */
  toBeInspected,
  /**
   * 不合格
   */
  unqualified,
}

/**
 * 库存位置状态
 */
export enum storageStatusEnum {
  /**
   * 存储中
   */
  inStorage = 1,
  /**
   * 在途中
   */
  onWay,
  /**
   * 已出厂
   */
  delivered,
}
/**
 * 库存明细业务状态
 */
export enum inventoryBizStatusEnum {
  /**
   * 无业务
   */
  noBiz = 0,
  /**
   * 质检中
   */
  inQualityCheck,
}
/**
 * 库存变更记录操作类型
 */
export enum InventoryChangeActionEnum {
  /**  入库 */
  in = 'in',
  /** 出库 */
  out = 'out',
  /** 转移入库 */
  receive = 'receive',
  /** 转移出库 */
  issue = 'issue',
  /** 数量调整 */
  amountAdjust = 'amount_adjust',
  /** 属性调整 */
  attrAdjust = 'attr_adjust',
  /** 导入 */
  import = 'import',
}
/**
 * 入库单关联实体
 */
export enum inBoundEntityTypeEnum {
  /** 采购收货单 */
  receive = 1,
  /** 销售退货单 */
  return,
}
/**
 * 出库单关联实体
 */
export enum outBoundEntityTypeEnum {
  /** 协同采购退货单 */
  return = 1,
  /** 销售发货单 */
  invoiceOrdinary,
  /** 协同销售发货单 */
  invoiceCollaborate,
}

/**
 * 出库单行状态
 */
export enum outBoundItemEnum {
  /**
   * 待执行
   */
  TO_EXECUTE = 0,
  /**
   * 执行中
   */
  EXECUTING,
  /**
   * 已完成
   */
  DONE,
  /**
   * 已结束
   */
  OVER,
}

/**
 * 入库单行状态
 */
export enum inBoundItemEnum {
  /**
   * 待执行
   */
  TO_EXECUTE = 0,
  /**
   * 执行中
   */
  EXECUTING,
  /**
   * 已完成
   */
  DONE,
  /**
   * 已结束
   */
  OVER,
}
