import React from 'react';
//
import { TabList } from 'src/components';
//
import BomList from './bomList';
import CoproductList from './coproductList';
import BomSubItemList from './bomSubItemList';
import authDict, { hasAuth } from 'src/utils/auth';

enum TabKey {
  Bom = '1',
  SubBom = '2',
  Coproduct = '3',
}

const List = (props: { history: any }) => {
  const { history } = props;

  const tabList = [
    {
      tab: '物料清单',
      key: TabKey.Bom,
      component: <BomList history={history} />,
    },
  ];

  if (hasAuth(authDict.bom_view_list_subItemmaterial)) {
    tabList.push({
      tab: '子项物料行',
      key: TabKey.SubBom,
      component: <BomSubItemList history={history} />,
    });
  }

  if (hasAuth(authDict.bom_view_list_coproduct)) {
    tabList.push({
      tab: '多产出物料',
      key: TabKey.Coproduct,
      component: <CoproductList history={history} />,
    });
  }

  return <TabList tabList={tabList} defaultKey={TabKey.Bom} />;
};

export default List;
