import { ChangeEvent } from 'react';
import _ from 'lodash';

import { ResourceParamsListItemType } from './index.d';

export const getNumberFromInput = (event: ChangeEvent<HTMLInputElement>) => {
  const curValue = event.target.value;

  if (isNaN(Number(curValue)) || curValue === '' || _.endsWith(curValue, '.')) return curValue;
  return Number(curValue);
};

export const formatResourceParamToFormList = (
  resourceParamFromListApi: ResourceParamsListItemType,
) => {
  const { id, code, name, paramsType, unitName } = resourceParamFromListApi;
  const formattedParamFields = {
    paramsId: id,
    paramsName: name,
    paramsCode: code,
    paramsType,
    unitName,
  };

  return {
    ...formattedParamFields,
    param: {
      label: formattedParamFields.paramsName,
      // 把formlist联动/比对需要用到的字段打包进json
      value: JSON.stringify(formattedParamFields),
      key: formattedParamFields.paramsId,
    },
    // ...rest,
  };
};

export const formatResourceParamOption = (resourceParamFromListApi: ResourceParamsListItemType) => {
  const { id, code, name, paramsType, unitName } = resourceParamFromListApi;
  const formattedParamFields = {
    paramsId: id,
    paramsName: name,
    paramsCode: code,
    paramsType,
    unitName,
  };

  return {
    label: formattedParamFields.paramsName,
    // 把formlist联动/比对需要用到的字段打包进json
    value: JSON.stringify(formattedParamFields),
    key: formattedParamFields.paramsId!,
  };
};
