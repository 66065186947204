/**
 * 生成可复用的请求方法
 *
 * 场景：表单中，多个控件监听同一个控件的值的变化，触发相同参数的请求，拿到相同的返回结果后
 *      各自取出想要的字段。此时可以考虑只发一个请求，后续控件直接复用第一个请求的返回。
 */

type RequestFn = (params: { [p: string]: any }) => Promise<any>;

export const getSharableFetch = <T extends RequestFn>(requestFn: T) => {
  /** 请求参数到未完成请求的Promise的映射关系 */
  const paramsToPendingFetchMap = new Map<string, Promise<any>>();

  return (...args: Parameters<T>): ReturnType<T> => {
    const paramsString = JSON.stringify(args[0]);
    const pendingFetch = paramsToPendingFetchMap.get(paramsString);

    if (pendingFetch) {
      return pendingFetch as ReturnType<T>;
    }
    // 将fetch函数返回的 Promise 添加到map
    const fetchPromise = requestFn(args[0]).then(
      (res) => {
        paramsToPendingFetchMap.delete(paramsString);
        return res;
      },
      (err) => {
        paramsToPendingFetchMap.delete(paramsString);
        throw err;
      },
    );

    paramsToPendingFetchMap.set(paramsString, fetchPromise);
    return fetchPromise as ReturnType<T>;
  };
};
