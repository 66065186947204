import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty, isEqual, difference, keys, noop } from 'lodash';
import type { FormInstance } from 'antd';

const BLOCKER_DEFAULT_MESSAGE = '您当前编辑的内容尚未保存，是否退出？';
const defaultOptions = {
  initValues: {},
  message: BLOCKER_DEFAULT_MESSAGE,
  debug: false,
};

/**
 * @hook 离开编辑页时弹窗提示未保存
 */
export const useBlocker = <T extends {} = any>(
  /** 检查的表单 */
  form: FormInstance,
  options: {
    /** 表单初始值 */
    initValues?: T;
    /** 弹窗提示内容 */
    message?: string;
    /** debug模式 */
    debug?: boolean;
  } = {},
) => {
  const { initValues, message, debug } = Object.assign({}, defaultOptions, options);
  const initialValues = useRef(initValues);
  const unblockFn = useRef(noop);
  const history = useHistory();

  const hasChange = useCallback(() => {
    const formValues = form.getFieldsValue(true);
    if (debug) {
      console.group('== shouldLeave ==');
      console.log('initialValues:', initialValues.current);
      console.log('formValues:', formValues);
      console.log('⬇️ %cOnly first-level props will be compared', 'font-style: italic');
      console.log(
        'initialValues unique props:',
        difference(keys(initialValues.current), keys(formValues)),
      );
      console.log(
        'formValues unique props:',
        difference(keys(formValues), keys(initialValues.current)),
      );
      console.groupEnd();
    }
    // <Modal>在页面初始渲染时也会执行渲染逻辑（此时visible为false时）, 如果传了initValues, 这时就会修改 initialValues,
    // 但<Modal>内部的<Form>不会渲染, 表单值仍为空值。这就需要另行判断一下。
    const noChange = isEqual(formValues, initialValues.current) || isEmpty(formValues);

    return !noChange;
  }, [form]);

  const shouldLeave = useCallback(() => {
    // 1. 无改动，直接放行
    // 2. 有改动，用户手动放行（这里需要同步阻塞，所以无法换成 antd 的 Modal.confirm）
    const result = !hasChange() || window.confirm(message);

    // 确认放行，解除拦截
    if (result) {
      unblockFn.current();
    }
    return result;
  }, [hasChange, message, initialValues]);

  useEffect(() => {
    const unblock = history.block(() => {
      if (shouldLeave()) {
        return;
      }
      // 阻止跳转
      return false;
    });
    // 拦截关闭、刷新操作
    function beforeUnloadHandler(e: BeforeUnloadEvent) {
      if (hasChange()) {
        e.preventDefault();
        e.returnValue = '';
      }
    }
    window.addEventListener('beforeunload', beforeUnloadHandler);

    unblockFn.current = () => {
      unblock();
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [hasChange, shouldLeave]);

  const resetForm = useCallback(
    (values: T) => {
      initialValues.current = values;
      // 不能直接用 resetFields 设置字段值，因为对于当前未渲染的<Form.Item>字段无效
      // setFieldsValue 的缺点是会与原有表单值对象合并，不是全量替换，这样就无法清除不需要的值
      // 目前策略是先用 resetFields 清空原值，然后用 setFieldsValue 设置新值，确保每次都是全量更新
      form.resetFields();
      form.setFieldsValue(values);
      if (debug) {
        console.group('== resetForm ==');
        console.log(values);
        console.groupEnd();
      }
    },
    [form],
  );

  // 自动填充初始值
  useEffect(() => {
    if (!isEmpty(initValues)) {
      form.setFieldsValue(initialValues.current);
    }
  }, [initialValues]);

  return { resetForm, history, shouldLeave, initialValues: initialValues.current };
};
