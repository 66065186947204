import { formatTimeForRender } from 'src/utils/formatters/dateTime';
import { FilterItem } from 'src/layout';
import { fieldTypeList } from 'utils';
import { appDict } from 'src/dict';
import { renderApprovalStatus, renderApprovalTaskStatus } from './utils';
import { SearchSelect } from 'src/components';
import { VIEW_MODAL } from './constant';

/**
 * 列表项
 */
export const dataColumnsForApprovalList = (tabKey: string) => {
  let statusRender: any;

  switch (`${tabKey}`) {
    case VIEW_MODAL.PENDING:
      statusRender = renderApprovalTaskStatus;
      break;

    case VIEW_MODAL.HANDLED:
      statusRender = renderApprovalTaskStatus;
      break;

    case VIEW_MODAL.INITIATE:
      statusRender = renderApprovalStatus;
      break;

    default:
      statusRender = renderApprovalTaskStatus;
      break;
  }

  return [
    {
      title: '名称',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: '审批对象',
      dataIndex: 'approvalObjectName',
      width: 150,
    },
    {
      title: '审批内容',
      dataIndex: 'approvalObjectInstanceCode',
      width: 150,
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 150,
      render: statusRender,
    },
    {
      title: '创建人',
      dataIndex: ['creator', 'name'],
      width: 150,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 150,
      sorter: true,
      render: formatTimeForRender,
    },
    {
      title: '更新人',
      dataIndex: ['operator', 'name'],
      width: 150,
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 150,
      sorter: true,
      render: formatTimeForRender,
    },
  ];
};

export const approvalFilterList = (tabKey: string) => {
  let selectOption: any;

  switch (`${tabKey}`) {
    case VIEW_MODAL.PENDING:
      selectOption = {
        options: appDict.approval.approvalPendingStatus,
        mode: 'multiple',
      };
      break;

    case VIEW_MODAL.HANDLED:
      selectOption = {
        options: appDict.approval.approvalHandledStatus,
        mode: 'multiple',
      };
      break;

    case VIEW_MODAL.INITIATE:
      selectOption = {
        options: appDict.approval.approvalStatus,
        mode: 'multiple',
      };
      break;

    default:
      selectOption = {
        options: appDict.approval.approvalPendingStatus,
        mode: 'multiple',
      };

      break;
  }

  const approvalFilterList: FilterItem[] = [
    {
      label: '编号',
      name: 'code',
      type: fieldTypeList.text,
    },
    {
      label: '名称',
      name: 'name',
      type: fieldTypeList.text,
    },
    {
      label: '审批对象',
      name: 'approvalCode',
      type: fieldTypeList.multiSelect,
      renderItem: (
        <SearchSelect
          fetchType={'bizObjectByCode'}
          allowClear
          placeholder={'请选择审批对象'}
          mode="multiple"
        />
      ),
    },
    {
      label: '状态',
      name: 'status',
      type: fieldTypeList.select,
      selectProps: selectOption,
    },
    {
      label: '更新时间',
      name: 'updatedAt',
      type: fieldTypeList.date,
    },
    {
      label: '创建人',
      name: 'creator',
      type: fieldTypeList.multiSelect,
      renderItem: <SearchSelect labelInValue fetchType={'user'} mode="multiple" />,
    },
    {
      label: '创建时间',
      name: 'createdAt',
      type: fieldTypeList.date,
    },
  ];

  return approvalFilterList;
};
