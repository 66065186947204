export const ReceiverType = {
  user: 1,
  department: 2,
  role: 3,
  field: 4,
};

export interface Item {
  key: number | string;
  label: string;
  value: string;
}

export interface FieldObjType {
  key: string;
  value: {};
}
export interface ReceiverObjType {
  receiverCode: string;
  receiverName: string;
  receiverId: number;
  receiverType: number;
}
export interface TriggerObjType {
  triggerRuleId: number;
  triggerRuleName: string;
}

export interface triggerColType {
  id: number;
  bizObjectName: string;
  bizType: number;
  code: string;
  name: string;
  status: string;
  messageTitle: string;
  messageContent: string;
  messageReceivers: ReceiverObjType[];
  remindMethod: number[];
  messageSubscription: number[];
  allowRejection: number;
  rules: number;
  remark: String;
}

export interface unTriggerColType {
  active: number;
  bizTypeName: string;
  code: string;
  content: string;
  contentI18nId: number;
  moduleId: string;
  moduleName: string;
  name: string;
  receiverDesc: string;
  reject: boolean;
  sendToAppStatus: number;
  sendToEmailStatus: number;
  sendToPhoneStatus: number;
  templateId: number;
  title: '{0}';
  urgent: number;
}
