import { FC, useEffect, useRef, useState, useCallback, cloneElement, ReactElement } from 'react';
import _ from 'lodash';
import { Layout } from 'antd';
import classnames from 'classnames';
import { useWidthObserver } from 'src/components';
import type { SiderProps } from 'antd/lib/layout';
import styles from './styles.module.scss';

const { Sider } = Layout;

interface ResizableSiderProps extends SiderProps {
  defaultWidth: number;
  /** 在localStorage里存储拖拽的宽度 */
  storageKey: string;
  minWidth?: number;
  maxWidth?: number;
}

/** 拖拽时遮住整个界面，防止鼠标响应hover或变成别的样式 */
const shade = document.createElement('div');

shade.classList.add(styles['resize-shade']);

export const ResizableSider: FC<ResizableSiderProps> = ({
  defaultWidth,
  storageKey,
  style = {},
  minWidth = 0,
  maxWidth = 9999,
  children,
  ...rest
}) => {
  const [width, setWidth] = useState<number>(defaultWidth);
  const [isDraggling, setIsDraggling] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const siderRef = useRef<HTMLDivElement>(null);
  const prevX = useRef<number>(0);
  // 为什么要单独搞一个 prevWidth 的ref？因为不希望 handleResize 依赖于 width 而频繁更新
  const prevWidth = useRef<number>(0);

  const handleResize = useCallback(
    _.throttle(
      (e: MouseEvent) => {
        if (e.clientX === prevX.current || !ref.current) {
          return;
        }
        const mouseMove = e.clientX - prevX.current;
        const mouseHandleDistance = e.clientX - ref.current.getBoundingClientRect().left;

        // 为改善拖拽体验，鼠标只能将侧栏『拖着走』，不能『推着走』
        if (
          (mouseMove > 0 && mouseHandleDistance < 0) ||
          (mouseMove < 0 && mouseHandleDistance > 0)
        ) {
          return;
        }
        let newWidth = prevWidth.current + mouseMove;

        if (newWidth < minWidth) {
          newWidth = minWidth;
        } else if (newWidth > maxWidth) {
          newWidth = maxWidth;
        } else {
          prevX.current = e.clientX;
        }
        setWidth(newWidth);
        prevWidth.current = newWidth;
        window.localStorage.setItem(storageKey, newWidth.toString());
      },
      50,
      { trailing: true },
    ),
    [],
  );

  const [reRenderFlag] = useWidthObserver({ ref: siderRef });

  useEffect(() => {
    const storedWidth = window.localStorage.getItem(storageKey);

    if (storedWidth !== null) {
      setWidth(+storedWidth);
      prevWidth.current = +storedWidth;
    } else {
      setWidth(defaultWidth);
      prevWidth.current = defaultWidth;
      window.localStorage.setItem(storageKey, defaultWidth.toString());
    }

    if (ref.current) {
      ref.current.addEventListener('mousedown', (e) => {
        prevX.current = e.clientX;
        setIsDraggling(true);
        document.addEventListener('mousemove', handleResize);
        document.body.appendChild(shade);
      });
      document.addEventListener('mouseup', () => {
        setIsDraggling(false);
        document.removeEventListener('mousemove', handleResize);
        if (shade.parentNode) {
          shade.parentNode.removeChild(shade);
        }
      });
    }
  }, [handleResize]);

  const hasReachLeftBoundary = width === minWidth;
  const hasReachRightBoundary = width === maxWidth;

  if (hasReachLeftBoundary) {
    shade.classList.add(styles['resize-cursor-left-boundary']);
  } else if (hasReachRightBoundary) {
    shade.classList.add(styles['resize-cursor-right-boundary']);
  } else {
    shade.classList.remove(
      styles['resize-cursor-left-boundary'],
      styles['resize-cursor-right-boundary'],
    );
  }

  return (
    <Sider ref={siderRef} width={width} style={{ ...style, position: 'relative' }} {...rest}>
      <div className={styles['resize-component-wrapper']}>
        {cloneElement(children as ReactElement, { reRenderFlag })}
      </div>
      <div
        ref={ref}
        className={classnames(
          styles['resize-handle'],
          isDraggling ? styles['resize-handle-draggling'] : '',
          hasReachLeftBoundary ? styles['resize-cursor-left-boundary'] : '',
          hasReachRightBoundary ? styles['resize-cursor-right-boundary'] : '',
        )}
      />
    </Sider>
  );
};
