import React from 'react';
import { DetailLayoutForDrawer } from 'layout';
import PositionDetailInfo from '../components/positionDetailBaseInfo';

interface StoreDetailProps {
  visible: boolean;
  closeDetail: () => void;
  refreshTable: () => void;
  positionId: number;
}

const PositionsDetailDrawer = (props: StoreDetailProps) => {
  const { visible, closeDetail, positionId, refreshTable } = props;

  return (
    <DetailLayoutForDrawer
      visible={visible}
      onClose={closeDetail}
      width={680}
      content={
        <PositionDetailInfo positionId={positionId} refreshTable={refreshTable} type="drawer" />
      }
    />
  );
};

export default PositionsDetailDrawer;
