import React, { useCallback, useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import _ from 'lodash';
import { DetailLayoutInfoBlock, DetailLayout, DetailLayoutInfoItem } from 'layout';
import { message, Tabs, Form } from 'antd';
import { fetchFileListByIds } from 'src/api/ytt/holyfile-domain';
import {
  fetchInstrumentDetail,
  fetchInstrumentStatusEnable,
  fetchInstrumentStatusStop,
  fetchInstrumentStatusScrap,
} from 'src/api/ytt/resource-domain/energyMeter';
import { fetchCustomFieldInstanceGetByObjectCode } from 'src/api/ytt/custom-object-domain';
import { injectCustomFieldInfos } from 'src/components/customField';
import { AvatarDisplay, avatarPictuer } from 'src/components/avatar/show';
import TagTooltip from 'src/components/tooltip/tagTooltip';
import { BcAttachmentDetail, BlIcon, SingleOperationLogComponent } from 'components';
import { gcTime } from 'src/utils';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { valueOrHolder } from 'src/utils/formatters';
import lookup, { appEnum } from 'src/dict';
import { middleGrey } from 'src/styles/color';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
//
import styles from '../index.module.scss';
import { EnergyMeterDetailType } from '../index.d';
import { goToCopy, goToEdit } from '../navigates';
import CensusEquimentDetailList from './censusEquiment';
import EnergyParameterDetailList from './energyParameter';
import EnergyTagDetailList from './energyTag';
import EnergyMeterStatus from '../components/EnergyMeterStatus';

interface DetailContentProps extends RouteChildrenProps<{ id: string }> {}

export const DetailContent = (props: DetailContentProps) => {
  const { match, history } = props;
  const [detailData, setDetailData] = useState<EnergyMeterDetailType>({});
  const [loading, setLoading] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();
  const [visibleLog, setVisibleLog] = useState(false);
  const [customFields, setCustomFields] = useState<any>();
  const [delForm] = Form.useForm();

  const getFileUrl = async (fileId?: number) => {
    if (fileId) {
      const { data } = await fetchFileListByIds({ idList: [fileId] });

      setAvatarUrl(data?.[0]?.fileUri);
    }
  };

  const fetchAndSetData = (id?: string) => {
    if (id) {
      setLoading(true);
      return fetchInstrumentDetail({ id: _.toNumber(id) }).then((res) => {
        const { data } = res;

        data && setDetailData(data);
        getFileUrl(data?.coverFileId);

        return res;
      });
    }

    return Promise.reject();
  };

  useEffect(() => {
    Promise.all([
      fetchCustomFieldInstanceGetByObjectCode({
        objectCode: OBJECT_OF_CODE.EnergyInstrument,
      }),
      fetchAndSetData(match?.params?.id),
    ])
      .then(([customFieldRes]) => {
        // 设置自定义字段信息
        if (customFieldRes) {
          const { data } = customFieldRes;

          setCustomFields(data);
        }
      })
      .catch(() => {
        setTimeout(() => {
          history.goBack();
        }, 500);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getChangeStatus = (stauts: number, idList: number[]) => {
    if (stauts === appEnum.Resources.EnergyMeterStatus.enabled) {
      return fetchInstrumentStatusStop({
        idList,
        status: appEnum.Resources.EnergyMeterStatus.disabeld,
      });
    }
    return fetchInstrumentStatusEnable({
      idList,
      status: appEnum.Resources.EnergyMeterStatus.enabled,
    });
  };

  const baseMenu = useCallback(() => {
    const { id, status } = detailData;

    if (_.isNil(status) || _.isNil(id)) return [];

    const statusOperation =
      status === appEnum.Resources.EnergyMeterStatus.disabeld ? '启用' : '停用';

    const buttonList = [
      status !== appEnum.Resources.EnergyMeterStatus.scrap && {
        title: statusOperation,
        key: 'changeStatus',
        icon: <BlIcon type="iconqiyong" />,
        auth: authDict.energyinstrument_enable_disable,
        onClick: () => {
          getChangeStatus(status, [id]).then((res) => {
            if (res.code === 200) {
              message.success(`${statusOperation}成功!`);
              fetchAndSetData(match?.params?.id).finally(() => {
                setLoading(false);
              });
            }
          });
        },
      },
      {
        title: '复制',
        key: 'copy',
        icon: <BlIcon type="iconfuzhi" />,
        onClick: () => {
          goToCopy(id);
        },
        auth: authDict.energyinstrument_add,
      },
      // {
      //   key: 'delete',
      //   onClick: () => {
      //     fetchInstrumentDelete({ idList: [id] }).then((res) => {
      //       if (res.code === 200) {
      //         message.success('删除成功');
      //       }
      //     });
      //   },
      //   title: '删除',
      //   disabled: true,
      // },
      {
        title: '报废',
        key: 'unUsed',
        auth: authDict.energyinstrument_scrap,
        disabled: status === appEnum.Resources.EnergyMeterStatus.scrap,
        onClick: () => {
          BLDelConfirm('是否确认报废该能源仪表？', delForm, () => {
            return fetchInstrumentStatusScrap({
              idList: [id],
              status: appEnum.Resources.EnergyMeterStatus.scrap,
            }).then((res) => {
              if (res.code === 200) {
                message.success('报废成功！');
                fetchAndSetData(match?.params?.id).finally(() => {
                  setLoading(false);
                });
              }
            });
          });
        },
      },
      {
        title: '操作记录',
        key: 'look',
        onClick: () => {
          setVisibleLog(true);
        },
        auth: authDict.energyinstrument_operrecord,
      },
      {
        title: '编辑',
        key: 'edit',
        icon: <BlIcon type="iconbianji" />,
        onClick: () => {
          goToEdit(id);
        },
        auth: authDict.energyinstrument_edit,
        type: 'primary',
      },
    ];

    return _.compact(buttonList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);

  const baseInfo = useCallback((): DetailLayoutInfoBlock => {
    const baseItems: DetailLayoutInfoItem[] = [
      {
        label: '',
        dataIndex: 'coverFileId',
        render: () => (
          <div style={{ width: '100%' }}>
            {avatarPictuer(avatarUrl || '', <BlIcon type="iconzuzhijiagou" />)}
          </div>
        ),
        span: 2,
      },
      { label: '能源仪表编号', dataIndex: 'code' },
      { label: '能源仪表名称', dataIndex: 'name' },
      {
        label: '业务类型',
        dataIndex: ['businessType'],
        render: (type) => valueOrHolder(lookup('resources.energyMeterType', type)),
      },
      { label: '能源仪表分类', dataIndex: 'classificationVo.name' },
      { label: '标识码', dataIndex: 'entityLinkCode' },
      { label: '供应商', dataIndex: 'supplierResDTO.name' },
      { label: '序列号', dataIndex: 'serialNo' },
      {
        label: '负责人',
        dataIndex: 'responsibleUser',
        render: (users: any[]) => <TagTooltip list={users || []} isUser max={2} />,
      },
      {
        label: '负责部门',
        dataIndex: 'responsibleDepartment',
        render: (departments: any[]) => (
          <TagTooltip list={departments || []} isUser={false} max={2} />
        ),
      },
      { label: '出厂日期', dataIndex: 'outFactoryDate' },
      { label: '入厂日期', dataIndex: 'enterFactoryDate' },
      { label: '启用日期', dataIndex: 'enableDate' },
      {
        label: '使用年限',
        dataIndex: 'useAge',
        render: (text, record) =>
          valueOrHolder(text, () => `${text} ${lookup('resources.useAgeUnit', record.useAgeType)}`),
      },
      { label: '计划报废日期', dataIndex: 'planScrapDate' },
      { label: '存储单位', dataIndex: 'unitName' },
      {
        label: '存储区域',
        dataIndex: 'locationList',
        render: (list: { name: string }[]) =>
          list?.map((location: { name: string }) => location.name).join('/') ?? '-',
      },
      {
        label: '附件',
        dataIndex: 'fileIdList',
        render: (fileIds: number[]) => <BcAttachmentDetail fileIds={fileIds} />,
        span: 2,
      },
      {
        label: '备注',
        dataIndex: 'remark',
        isFullLine: true,
      },
    ];

    return {
      title: '基本信息',
      items: baseItems,
      column: 2,
    };
  }, [detailData, avatarUrl]);

  const energyTagInfo: DetailLayoutInfoBlock = {
    title: '能源仪表标签',
    items: [
      {
        label: '',
        dataIndex: 'tagsList',
        render: (dataSource: EnergyMeterDetailType['tagsList']) => (
          <EnergyTagDetailList dataSource={dataSource} />
        ),
      },
    ],
  };

  const censusEquimentInfo: DetailLayoutInfoBlock = {
    title: (
      <span>
        <span>统计设备</span>
        <span style={{ fontSize: 14, color: middleGrey, marginLeft: 5 }}>
          共用该能源仪表的能耗统计的所有设备
        </span>
      </span>
    ),
    items: [
      {
        label: '',
        dataIndex: 'energyInstrumentResourceLinkList',
        render: (dataSource: EnergyMeterDetailType['energyInstrumentResourceLinkList']) => (
          <CensusEquimentDetailList dataSource={dataSource} />
        ),
      },
    ],
  };

  const energyParameterInfo: DetailLayoutInfoBlock = {
    title: '能源仪表参数',
    items: [
      {
        label: '',
        dataIndex: 'resourceParamsList',
        render: (dataSource: EnergyMeterDetailType['resourceParamsList']) => (
          <EnergyParameterDetailList dataSource={dataSource} />
        ),
      },
    ],
  };

  const stausInfo: DetailLayoutInfoBlock = {
    title: '状态信息',
    items: [
      {
        label: '启用状态',
        dataIndex: 'status',
        render: (status) => <EnergyMeterStatus status={status} />,
      },
    ],
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'createUser',
        render: (creator: any) =>
          valueOrHolder(creator, (creator: any) => (
            <AvatarDisplay {...creator} isShowTag={false} isUser />
          )),
      },
      {
        label: '创建时间',
        dataIndex: 'createdTime',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
      {
        label: '更新人',
        dataIndex: 'operateUser',
        render: (operator: any) =>
          valueOrHolder(operator, (operator: any) => (
            <AvatarDisplay {...operator} isShowTag={false} isUser />
          )),
      },
      {
        label: '更新时间',
        dataIndex: 'updatedTime',
        render: (time: number) => valueOrHolder(time, gcTime.formatLine),
      },
    ],
    column: 2,
  };

  const detailContent = () => (
    <div className={styles.detailTab}>
      <Tabs defaultActiveKey="1" type="card">
        <Tabs.TabPane tab="能源仪表台账" key="1">
          <DetailLayout
            info={_.compact([
              baseInfo(),
              customFields && injectCustomFieldInfos({ customFields, type: 'detail' }),
              energyTagInfo,
              censusEquimentInfo,
              energyParameterInfo,
              stausInfo,
              otherInfo,
            ])}
            dataSource={detailData}
            // dataSource={{}}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );

  return (
    <DetailLayout
      loading={loading}
      baseMenu={baseMenu()}
      title={'能源仪表台账详情'}
      info={[]}
      dataSource={{}}
      userAuth={getAuthFromLocalStorage()}
    >
      {!loading && detailContent()}
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={detailData?.id ?? 0}
          objectCode={appEnum.Common.ObjectCode.EnergyInstrument}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </DetailLayout>
  );
};
