import React, { useEffect, useState } from 'react';
import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import { replaceSign } from 'src/utils/constants';
import _Time from 'src/utils/dataTypes/time';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { useHistory } from 'react-router-dom';
import _String from 'src/utils/dataTypes/string';
import { Modal } from 'antd';
import { BlIcon, SingleOperationLogComponent } from 'src/components';
import { fetchShiftRuleCheck, fetchShiftRuleGet } from 'src/api/ytt/calendar-domain';
import { formatUseNames } from '../../shift/utils';
import { toSchedulingCreate, toSchedulingEdit } from '../../shift/navigation';
import { CYCLE_TYPE_DETAIL_ENUM, CYCLE_TYPE_ENUM } from '../constant';
import { DetailSourceType, ShiftRuleInfoVOSType } from '../interface';
import BaseTable from './detailBaseTable';
import { CreatorData } from '../../shift/interface';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';

interface DetailBaseInfoProps {
  schedulingId: number;
  type: string;
}

const DetailInfo = (props: DetailBaseInfoProps) => {
  const { schedulingId, type } = props;
  const history = useHistory();

  const [dataSource, setDetail] = useState({} as any);

  const fetchData = async () => {
    try {
      const { data } = await fetchShiftRuleGet({ id: schedulingId }, { legacy: true });

      if (data) {
        setDetail(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [checkVisible, setCheckVisible] = useState(false); // 显示确定编辑弹框
  const [visibleLog, setVisibleLog] = useState(false); // 显示单条操作记录

  const baseMenu = [
    {
      key: 'copy',
      icon: <BlIcon type="iconfuzhi" />,
      onClick: () => {
        history.push({
          pathname: toSchedulingCreate(),
          state: schedulingId,
        });
      },
      title: '复制',
    },
    {
      key: 'record',
      auth: authDict.shiftrule_operrecord,
      onClick: () => {
        setVisibleLog(true);
      },
      title: '操作记录',
    },
    {
      key: 'edit',
      auth: authDict.shiftrule_edit,
      icon: <BlIcon type="iconbianji" />,
      title: '编辑',
      onClick: async () => {
        const { data } = await fetchShiftRuleCheck({ id: schedulingId });

        if (data?.inUse) {
          // 被使用中，弹框提醒

          const calendarName = formatUseNames(data?.workerCalendarVOS);

          Modal.confirm({
            title: '确定要编辑这条班次吗?',
            content: (
              <div>
                有
                {_String.isEmpty(calendarName) ? (
                  ''
                ) : (
                  <span>
                    <span style={{ color: '#02B980' }}>{calendarName}</span>等日历
                  </span>
                )}
                正在使用该排班规则，排班规则修改后，将影响到这些工作日历，你确定要修改吗？
              </div>
            ),
            visible: checkVisible,
            onOk() {
              history.push(toSchedulingEdit(schedulingId));
            },
            onCancel() {
              setCheckVisible(false);
            },
            okText: '确定',
            cancelText: '取消',
          });
        } else {
          history.push(toSchedulingEdit(schedulingId));
        }
      },
    },
  ];

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      { label: '规则名称', dataIndex: 'name' },
      {
        label: '周期类型',
        dataIndex: 'type',
        render: (type: number) => CYCLE_TYPE_ENUM.get(type) ?? replaceSign,
      },
      {
        label: '重复周期',
        dataIndex: 'periodNum',
        render: (periodNum: number, { type }) =>
          `${periodNum}${CYCLE_TYPE_DETAIL_ENUM.get(type)} ` ?? replaceSign,
      },
    ],
    column: 3,
  };

  const shiftInfo: DetailLayoutInfoBlock = {
    items: [
      {
        label: '周期班次',
        dataIndex: 'shiftRuleInfoVOS',
        isFullLine: true,
        render: (shiftRuleInfoVOS: ShiftRuleInfoVOSType[], { type }) => {
          return <BaseTable dataSource={shiftRuleInfoVOS} type={type} />;
        },
      },
      {
        label: '备注',
        dataIndex: 'remark',
        render: (remark: string) => (_String.isEmpty(remark) ? replaceSign : remark),
      },
    ],
    column: 1,
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: CreatorData) => {
          return (
            <AvatarDisplay
              id={creator?.id}
              name={creator?.name}
              avatarUrl={creator?.avatarUrl}
              key={creator?.id}
              isShowTag={false}
              isUser
            />
          );
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        isFullLine: true,
        render: (createdAt: Date) => _Time.format(createdAt) ?? replaceSign,
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: CreatorData) => {
          return (
            <AvatarDisplay
              id={operator?.id}
              name={operator?.name}
              avatarUrl={operator?.avatarUrl}
              key={operator?.id}
              isShowTag={false}
              isUser
            />
          );
        },
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        isFullLine: true,
        render: (updatedAt: Date, record: DetailSourceType) =>
          _Time.format(updatedAt) ?? _Time.format(record?.createdAt) ?? replaceSign,
      },
    ],
    column: 2,
  };

  return (
    <>
      <DetailLayout
        userAuth={getAuthFromLocalStorage()}
        title="排班规则详情"
        baseMenu={baseMenu}
        info={[baseInfo, shiftInfo, otherInfo]}
        dataSource={dataSource}
      />
      {visibleLog && (
        <SingleOperationLogComponent
          visible={visibleLog}
          instanceId={schedulingId}
          objectCode={'ShiftRule'}
          closeDetail={() => {
            setVisibleLog(false);
          }}
        />
      )}
    </>
  );
};

export default DetailInfo;
