import { useState, useEffect, ReactNode } from 'react';
import { Tabs, TabsProps } from 'antd';
import styles from './styles.module.scss';
import _Url from 'src/utils/url';
import _ from 'lodash';

const { TabPane } = Tabs;

interface Props extends TabsProps {
  tabList: TabListItem[];
  defaultKey: string; // 默认key
  /** 将各tab页的url参数保存在sessionSotorage里，以便切换时恢复 */
  storageKey?: string;
  /** 各tab页共享的url参数 */
  sharedUrlParams?: string[];
}

interface TabListItem {
  key: string;
  component: ReactNode;
  tab: string;
}
interface sessionTabsNode {
  key: string;
  value: UrlParams;
}
interface UrlParams {
  filterData: string;
  pagination: string;
  sorter: string;
  tabKey: number;
}

const TabList = ({
  tabList,
  defaultKey,
  storageKey = 'tabs',
  sharedUrlParams = [],
  ...restProps
}: Props) => {
  const [activeKey, setActiveKey] = useState<string>();

  useEffect(() => {
    const query = _Url.getParams({ parseNumbers: false });
    const { tabKey } = query;

    if (tabKey) {
      // 如果url上的tabKey存在 直接赋值
      setActiveKey(tabKey);
    } else {
      // 不存在给默认值
      _Url.setParams({ ...query, tabKey: defaultKey });
      setActiveKey(defaultKey);
    }

    return () => {
      sessionStorage.removeItem(storageKey);
    };
  }, []);

  const onChange = (activeKey: string) => {
    // 一律用字符串
    const query = _Url.getParams({ parseNumbers: false });
    const { tabKey } = query;

    setActiveKey(activeKey);

    // 将当前tab的query参数保存下来，读取并恢复目标tab的query参数
    const sessionTabs = sessionStorage.getItem(storageKey) ?? ''; // 储存的值

    let sessionTabsData = sessionTabs && JSON.parse(sessionTabs);

    if (sessionTabs) {
      sessionTabsData = sessionTabsData?.map(({ key, value }: sessionTabsNode) => ({
        key,
        value: tabKey === key ? query : value,
      }));
    } else {
      // 第一次根据所需枚举值往里面塞值
      sessionTabsData = tabList?.map(({ key }: TabListItem) => ({
        key,
        value: tabKey === key ? query : _.pick(query, sharedUrlParams),
      }));
    }
    sessionStorage.setItem(storageKey, JSON.stringify(sessionTabsData)); // 获取最新的url参数 每次重新赋值

    const urlParams = _.find(sessionTabsData, { key: activeKey })?.value;

    _Url.setParams({ ...urlParams, tabKey: activeKey });
  };

  return (
    <Tabs
      className={styles.tabs}
      activeKey={activeKey}
      onChange={onChange}
      destroyInactiveTabPane
      {...restProps}
    >
      {activeKey &&
        tabList.map(({ component, ...resItem }) => (
          <TabPane {...resItem} style={{ height: '100%' }}>
            {component}
          </TabPane>
        ))}
    </Tabs>
  );
};

export default TabList;
