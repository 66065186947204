import { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import { BlTable } from 'src/components';
import moment from 'moment';
import _ from 'lodash';
import lookup from 'src/dict';

import { DetailLayout } from 'src/layout';
import { AvatarDisplay } from 'src/components/avatar/show';
import { replaceSign } from 'src/utils/constants';
import { getResult, getStatus } from './utils';
import {
  fetchPlanOperationLogBase,
  FetchPlanOperationLogBaseResponse,
  fetchPlanOperationLogDemandList,
  FetchPlanOperationLogDemandListResponse,
  fetchPlanOperationLogOrderList,
  FetchPlanOperationLogOrderListResponse,
} from 'src/api/ytt/plo-domain/plannOrderOperation';

interface Props extends RouteChildrenProps<{ id: string }> {}

const LogDetail = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState<{
    base: FetchPlanOperationLogBaseResponse['data'];
    demandList: FetchPlanOperationLogDemandListResponse['data'];
    orderList: FetchPlanOperationLogOrderListResponse['data'];
  }>();
  const [pagination, setPagination] = useState({ page: 1, size: 10, total: 10 });
  const [orderPagination, setOrderPagination] = useState({ page: 1, size: 10, total: 10 });

  const { match } = props;

  useEffect(() => {
    fetchAndSetData(pagination);
  }, []);

  const fetchAndSetData = (pagination: { page: number; size: number }) => {
    const id = match?.params?.id ? _.toNumber(match?.params?.id) : undefined;

    // fetchPlanOperationLogBase  fetchPlanOperationLogDemandList  fetchPlanOperationLogOrderList

    Promise.all([
      fetchPlanOperationLogBase({ id: Number(id) }),
      fetchPlanOperationLogDemandList({ id: Number(id), ...pagination }),
      fetchPlanOperationLogOrderList({ id: Number(id), ...orderPagination }),
    ])
      .then((res) => {
        console.log('res: ', res);
        setDataSource({
          base: res[0].data,
          demandList: res[1].data,
          orderList: res[2].data,
        });
        setPagination({ ...pagination, total: res[1].data?.total ?? 10 });
        setOrderPagination({ ...orderPagination, total: res[2].data?.total ?? 10 });
      })
      .catch((err) => {
        console.log('err: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const baseInfo = [
    {
      title: '基本信息',
      items: [
        {
          label: '操作时间',
          dataIndex: ['base', 'operationTime'],
          render: (text: any) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : replaceSign),
        },
        {
          label: '操作用户',
          dataIndex: ['base', 'operationUser'],
          render: (info: any) => <AvatarDisplay {...info} isUser />,
        },
        {
          label: '计划运算方案',
          dataIndex: ['base', 'schemeSimple', 'code'],
          width: 100,
        },
        {
          label: '计划运算结束时间',
          dataIndex: ['base', 'operationEndTime'],
          render: (text: any) => (text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''),
        },
        {
          label: '操作结果',
          dataIndex: ['failedCount'],
          render: (text: any, record: any) => getStatus(record?.base ?? {}),
        },
        {
          label: '操作详情',
          dataIndex: ['successCount'],
          render: (text: any, record: any) => {
            return `${getResult(record?.base ?? {})} 。${record?.base?.failReason ?? ''}`;
          },
        },
      ],
    },
    {
      title: '独立需求单据信息',
      items: [
        {
          // label: '独立需求单据信息',
          isFullLine: true,
          dataIndex: 'productRate',
          render: () => {
            return (
              <BlTable
                dataSource={dataSource?.demandList?.list}
                onChange={(pagination) => {
                  const id = match?.params?.id ? _.toNumber(match?.params?.id) : undefined;

                  fetchPlanOperationLogDemandList({
                    id: Number(id),
                    page: pagination.current ?? 1,
                    size: pagination.pageSize ?? 10,
                  }).then((res) => {
                    setDataSource({
                      orderList: dataSource?.orderList,
                      base: dataSource?.base,
                      demandList: res.data,
                    });
                    setPagination({
                      page: pagination.current ?? 1,
                      size: pagination.pageSize ?? 10,
                      total: res.data?.total ?? 10,
                    });
                  });
                }}
                columns={[
                  {
                    title: '单据类型',
                    width: 100,
                    dataIndex: 'orderType',
                    render: (text: any) => lookup('planned.reqSourceTypeNameMap', text),
                  },
                  { title: '单据编号', width: 100, dataIndex: 'code' },
                  {
                    title: '操作结果',
                    width: 100,
                    dataIndex: 'result',
                    render: (text: any) => (text ? '成功' : '失败'),
                  },
                  { title: '失败原因', width: 200, dataIndex: 'failReason' },
                ]}
                pagination={{
                  pageSize: pagination.size,
                  current: pagination.page,
                  total: pagination?.total,
                }}
              />
            );
          },
        },
      ],
    },
    {
      title: '计划订单信息',
      items: [
        {
          // label: '独立需求单据信息',
          isFullLine: true,
          dataIndex: 'productRate',
          render: () => {
            return (
              <BlTable
                dataSource={dataSource?.orderList?.list}
                onChange={(pagination) => {
                  const id = match?.params?.id ? _.toNumber(match?.params?.id) : undefined;

                  fetchPlanOperationLogOrderList({
                    id: Number(id),
                    page: pagination.current ?? 1,
                    size: pagination.pageSize ?? 10,
                  }).then((res) => {
                    setDataSource({
                      demandList: dataSource?.demandList,
                      base: dataSource?.base,
                      orderList: res.data,
                    });

                    setOrderPagination({
                      page: pagination.current ?? 1,
                      size: pagination.pageSize ?? 10,
                      total: res.data?.total ?? 10,
                    });
                  });
                }}
                columns={[
                  { title: '计划订单编号', width: 200, dataIndex: 'code' },
                  {
                    title: '投放类型',
                    width: 100,
                    dataIndex: 'launchType',
                    render: (text: any) => lookup('planned.LaunchTypeEnumDisplayMap', text),
                  },
                ]}
                pagination={{
                  pageSize: orderPagination.size,
                  current: orderPagination.page,
                  total: orderPagination?.total,
                }}
              />
            );
          },
        },
      ],
    },
  ];

  return (
    <DetailLayout loading={loading} title="运算日志详情" info={baseInfo} dataSource={dataSource} />
  );
};

export default LogDetail;
