import { useCallback, useEffect, useState } from 'react';
import { DetailLayoutForDrawer, DetailLayoutInfoBlock, DetailLayout } from 'layout';
import { fetchResourceTypeGet } from 'src/api/ytt/resource-domain/resourceCategory';
import { avatarDisplay } from 'src/components/avatar/show';
import { BlIcon } from 'src/components';
import authDict from 'src/utils/auth';

interface DetailContentProps {
  id: number;
  showEdit?: (obj: any) => void;
  showLog?: () => void;
  businessTypeEnum?: any;
}
interface categoryDetailProps extends DetailContentProps {
  visible: boolean;
  onCloseDetail: () => void;
}

const DetailContent = (props: DetailContentProps) => {
  const { id, showEdit, showLog, businessTypeEnum } = props;
  const [detailData, setDetailData] = useState({} as any);

  const fetchData = async (_id?: number) => {
    try {
      const res = await fetchResourceTypeGet({ id: _id || id });

      setDetailData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      { label: '分类编号', dataIndex: 'code' },
      { label: '分类名称', dataIndex: 'name', render: (text: string) => text ?? '-' },
      {
        label: '业务类型',
        dataIndex: 'businessType',
        render: (text: number) => businessTypeEnum[text],
      },
      { label: '分类层级', dataIndex: 'businessLevel', render: (text: string) => text ?? '-' },
      { label: '上级分类', dataIndex: 'parentName', render: (text: string) => text ?? '-' },
      {
        label: '备注',
        dataIndex: 'remarks',
        render: (desc: string) => desc || '-',
      },
    ],
    column: 2,
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: any) => {
          if (!creator) {
            return '-';
          }
          return avatarDisplay({ ...creator, isUser: true });
        },
      },
      { label: '创建时间', dataIndex: 'createdAt' },
      {
        label: '编辑人',
        dataIndex: 'operator',
        render: (operator: any) => {
          if (!operator) {
            return '-';
          }
          return avatarDisplay({ ...operator, isUser: true });
        },
      },
      { label: '编辑时间', dataIndex: 'updatedAt' },
    ],
    column: 2,
  };

  const baseMenu = useCallback(() => {
    const { businessLevel, businessType, name, id } = detailData;

    return [
      {
        key: 'look',
        auth: authDict.resourcetype_operrecord,
        onClick: () => showLog?.(),
        title: '操作记录',
      },
      // {
      //   key: 'delete',
      //   onClick: () => showLog?.(),
      //   icon: <BlIcon type="iconbianji" />,
      //   title: '删除',
      // },
      {
        key: 'create',
        auth: authDict.resourcetype_add,
        onClick: () =>
          showEdit?.({
            type: 'create',
            businessLevel: businessLevel + 1,
            businessType,
            parentName: name,
            parendId: id,
          }),
        icon: <BlIcon type="iconbianji" />,
        title: '创建子分类',
        disabled: businessLevel == 5,
      },
      {
        key: 'edit',
        auth: authDict.resourcetype_edit,
        onClick: () => showEdit?.({ type: 'edit', businessLevel, businessType, parentName: name }),
        icon: <BlIcon type="iconbianji" />,
        title: '编辑',
      },
    ];
  }, [detailData]);

  return (
    <DetailLayout
      baseMenu={baseMenu()}
      title={'分类详情'}
      info={[baseInfo, otherInfo]}
      dataSource={detailData}
    />
  );
};

const CategoryDetailDrawer = (props: categoryDetailProps) => {
  const { visible, onCloseDetail } = props;

  return (
    <DetailLayoutForDrawer
      visible={visible}
      onClose={onCloseDetail}
      width={680}
      content={<DetailContent {...props} />}
    />
  );
};

export { CategoryDetailDrawer, DetailContent };
