/**
 * @enum 采购订单业务模块枚举值
 */

/** 采购订单状态 */
export enum PurchaseExecStatus {
  /** 新建 */
  created = 10,
  /** 待确认 */
  undetermined = 20,
  /** 已退回 */
  rejected = 30,
  /** 执行中 */
  executing = 40,
  /** 已关闭 */
  closed = 50,
  /** 已结束 */
  finished = 60,
}

export enum PurchaseSource {
  /** 普通采购 */
  common,
  /** 协同采购 */
  collaborate,
}

export enum PurchaseOrderdeliveryMode {
  /** 按订单交货 */
  purchaseOrder,
  /** 按交货计划交货 */
  deliverySchedule,
}
export enum PurchaseOrderMaterialCarryMode {
  /** 不带料 */
  notCarry,
  /** 带料 */
  carry,
}
export enum PurchaseReturnSource {
  /** 普通退货 */
  common,
  /** 协同退货 */
  collaborate,
}

export enum EndResultType {
  /** 正常结束 */
  success,
  /** 异常结束 */
  exception,
  /** 结束失败 */
  fail,
}

/** 日期范围枚举 */
export enum DATE_RANGE {
  /** 今天 */
  day = 0,
  /** 本周 */
  week = 1,
  /** 本月 */
  month = 2,
}

/** 工作台图表名称 */
export enum CHART_NAME {
  /** 发货进度 */
  deliverySchedule = 'deliverySchedule',
  /** 收货进度 */
  receiveSchedule = 'receiveSchedule',
  /** 逾期收货单数 */
  overdueRecOrderSchedule = 'overdueRecOrderSchedule',
  /** 按时完成率 */
  onTimeCompletionRate = 'onTimeCompletionRate',
  /** 按时交货率 */
  onTimeDeliveryRate = 'onTimeDeliveryRate',
  /** 平均交付周期 */
  averageDeliveryCycle = 'averageDeliveryCycle',
}

/** 逾期风险 */
export enum OVERDUE_RISK {
  /** 低 */
  low = 0,
  /** 高 */
  high,
}

/** 交货计划单状态 */
export enum DeliveryScheduleNoteExecStatus {
  /** 新建 */
  created = 10,
  /** 待确认 */
  undetermined = 20,
  /** 已退回 */
  rejected = 30,
  /** 执行中 */
  executing = 40,
  /** 已关闭 */
  closed = 50,
  /** 已结束 */
  finished = 60,
}

/** 交货计划单供应商发货状态 */
export enum DeliveryScheduleNoteDeliveryStatus {
  /** 待发货 */
  waitingDelivery,
  /** 部分发货 */
  partDelivery,
  /** 全部发货 */
  allDelivery,
  /** 全部收货 */
  allReceived,
}
export enum DeliveryScheduleNoteReceiveStatus {
  /** 待收货 */
  waitingReceived,
  /** 部分收货 */
  partReceived,
  /** 全部收货 */
  allReceived,
}

export enum DeliveryScheduleNoteSource {
  /** 无源 */
  noSource,
  /** 采购订单 */
  purchaseOrder,
}
