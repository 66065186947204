import { useState, useRef } from 'react';
import { message } from 'antd';
import { BcStepForm } from 'src/page/warehouseManagement/boundOrderManagement/share/step';
import { showErrorListMessage } from 'src/components';
import { DetailLayout, DataFormLayoutForModal } from 'layout';
import { fetchOutboundOrderChargeAgainst } from 'src/api/ytt/inventory-domain/boundOrder';
import WriteOffRecord from './writeOffRecord';
import WriteOffCreate from './writeOffCreate';
import _ from 'lodash';
import Modal from 'antd/lib/modal/Modal';

interface Props {
  outboundOrder: any;
  material: any;
  onFinish?: () => void;
}

function WriteOff(props: Props) {
  const { outboundOrder, material, onFinish } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [operateRecord, setOperateRecord] = useState<any[]>([]);
  const recordRef = useRef<any>(null);
  const chargeRef = useRef<any>(null);

  const writeOfFunction = async () => {
    setVisible(true);
  };
  const onClose = () => setVisible(false);

  return (
    <>
      <a onClick={() => writeOfFunction()}>冲销</a>
      <Modal
        title="出库冲销"
        bodyStyle={{ padding: 0 }}
        width={900}
        visible={visible}
        onCancel={onClose}
        footer={false}
        destroyOnClose
      >
        <BcStepForm
          onClose={onClose}
          title=""
          style={{ width: '70%' }}
          onFinish={async () => {
            const values = await chargeRef?.current?.submit();

            if (!values.list || !values.list?.length) {
              message.error('请选择冲销记录！');
              return;
            }

            const formatterValue = values.list.map((item: any) => ({
              ...item,
              remark: values?.remark,
              inventoryKey: {
                ...item.inventoryKey,
                storageLocationId: item.inventoryKey.storageLocationId.value,
              },
            }));

            return fetchOutboundOrderChargeAgainst(formatterValue).then((res: any) => {
              const { failCount = 0, successCount = 0, failDetails = [] } = res?.data ?? {};

              if (failCount) {
                showErrorListMessage({
                  failCount,
                  successCount,
                  title: '出库冲销出现失败',
                  data: _.map(failDetails, (item) => ({ ...item, key: item.id })),
                  columns: [
                    { title: '编号', dataIndex: 'code' },
                    { title: '业务类型', dataIndex: 'bizType' },
                    { title: '失败原因', dataIndex: 'reason', width: 200 },
                    { title: '', dataIndex: 'key', width: 1, render: () => null },
                  ],
                });
              } else {
                message.success('冲销成功！');
                onFinish?.();
              }
              onClose();

              return res;
            });
          }}
          steps={[
            {
              title: '冲销记录选择',
              onSubmit: () => {
                const records = recordRef?.current?.getRecord();

                if (records.length) {
                  setOperateRecord(records || []);
                  return true;
                }
                message.warn('请选择出库记录');
                return false;
              },
              content: (
                <WriteOffRecord ref={recordRef} outboundOrder={outboundOrder} material={material} />
              ),
            },
            {
              title: '冲销库存创建',
              content: (
                <WriteOffCreate
                  ref={chargeRef}
                  operateRecord={operateRecord}
                  outboundOrder={outboundOrder}
                  material={material}
                />
              ),
            },
          ]}
        />
      </Modal>
    </>
  );
}

export default WriteOff;
