import { useEffect, useState } from 'react';
import _ from 'lodash';
import { DataFormLayoutInfoBlock, DetailLayoutInfoBlock } from '@blacklake-web/layout';
//
import {
  ICustomFieldItem,
  ICustomFields,
  ICustomFieldsFnFormConfig,
  ICustomFieldSubmitData,
  ICustomFieldsFnColumnProps,
  CustomFieldComponentPropsMap,
} from './interface';
import { getFormCustomFieldItem } from './formModel';
import { ColumnProps } from 'antd/lib/table';
import { fetchCustomFieldInstanceGetByObjectCode } from 'src/api/ytt/custom-object-domain';
import { fetchCascadeBatchTree } from 'ytt/metadata-domain/fieldCascading';
//
import { DEFAULT_DATAINDEX, getCustomFieldNameOrkey, getObjectCustomFields } from './base';
import { getMainObjCustomFieldsToEdit, getMainObjCustomFieldsToDetail } from './mainObject';
import {
  getSubObjCustomFieldsToEdit,
  getSubObjCustomFieldsToDetail,
  ISubObjCustomFieldsToEditConfig,
} from './subObject';
import { appEnum } from 'src/dict';

interface BaseInjectFnParams {
  /** 对象自定字段所有信息，通过useCustomFieldCombinedData获取的 */
  customFields?: ICustomFields;
  /** 编辑 或 详情 */
  type: 'form' | 'detail';
  /** form相关配置 */
  formConfig?: ICustomFieldsFnFormConfig;
  /** type='form'时，自定义字段是否可编辑（优先级最高）*/
  disabled?: boolean;
  /** 自定义字段组件通用配置，直接透传到输入控件上 */
  componentsProps?: CustomFieldComponentPropsMap;
  /** type='detail'时，重写自定义字段的挂载位置 */
  dataIndex?: string | string[];
}

interface InjectColumnsFnParams extends BaseInjectFnParams {
  /** 对象code */
  objectCode: string;
  /** 原有的columns。可以不传，返回纯自定义字段的列由自己拼接 */
  columns?: ColumnProps<any>[];
  /** type="form"时，判断是否需要隐藏自定义字段控件的方法 */
  hiddenRender?: ISubObjCustomFieldsToEditConfig['hiddenRender'];
  /** 列表页方法更新后，将删除 todo:zhiyong */
  inTable?: boolean;
  /** 列表页方法更新后，将删除 todo:zhiyong */
  columnProps?: ICustomFieldsFnColumnProps;
}

interface InjectInfosFnParams extends BaseInjectFnParams {}

/**
 * 检查formConfing
 * @param formConfig
 */
const checkFormConfig = (formConfig?: ICustomFieldsFnFormConfig, objectCode?: string) => {
  if (!formConfig?.form) {
    console.warn(
      `提示】${objectCode}对象的新建编辑页使用自定义字段模块时，请务必传入form实例，否则部分功能将无法工作!`,
    );
  }
};

/**
 * 在 Table 注入 从对象的自定义字段 列
 */
export const injectCustomFieldColumns = ({
  objectCode,
  customFields,
  columns = [],
  type,
  hiddenRender,
  formConfig,
  inTable,
  columnProps,
  dataIndex = DEFAULT_DATAINDEX,
  disabled,
  componentsProps,
}: InjectColumnsFnParams): ColumnProps<any>[] => {
  if (_.isNil(customFields)) return columns;

  const setCustomFieldsToColumns = (
    columns: ColumnProps<any>[],
    fields?: any[],
  ): ColumnProps<any>[] => {
    const lastPushIndex = _.findIndex(columns, (item) => {
      return (
        (_.has(item, 'fixed') && _.isEqual(item.fixed, 'right')) || _.isEqual(item.title, '操作')
      );
    });

    if (lastPushIndex !== -1) {
      const baseColumns = _.cloneDeep(columns);

      // 固定列
      const fixedColumns = _.slice(baseColumns, lastPushIndex);

      // 移除原有固定列
      _.remove(baseColumns, (i, index) => index >= lastPushIndex);

      // 重新组合columns
      return _.concat(baseColumns, fields, fixedColumns);
    } else {
      return _.concat(columns, fields);
    }
  };

  const getFormColumns = () => {
    return setCustomFieldsToColumns(
      columns,
      getSubObjCustomFieldsToEdit(
        { customFields, objectCode },
        { ...((formConfig ?? {}) as ICustomFieldsFnFormConfig), disabled, hiddenRender },
        componentsProps,
      ),
    );
  };

  const getDetailColumns = () => {
    return setCustomFieldsToColumns(
      columns,
      getSubObjCustomFieldsToDetail(
        { customFields, objectCode },
        { inTable, columnProps, dataIndex },
        componentsProps,
      ),
    );
  };

  if (type === 'form') {
    checkFormConfig(formConfig, objectCode);
    return getFormColumns();
  }
  if (type === 'detail') {
    return getDetailColumns();
  }

  return columns;
};

/**
 * 在 DataFormLayout 或 DetailLayout 注入 主对象的自定义字段 模块
 */
export const injectCustomFieldInfos = ({
  customFields,
  type,
  formConfig,
  dataIndex = DEFAULT_DATAINDEX,
  disabled,
  componentsProps,
}: InjectInfosFnParams) => {
  if (_.isNil(customFields)) {
    return {
      title: '',
      items: [],
    };
  }

  const getFormInfos = () => {
    const items = getMainObjCustomFieldsToEdit(
      { customFields },
      { ...((formConfig ?? {}) as ICustomFieldsFnFormConfig), disabled },
      componentsProps,
    );
    return {
      title: items?.length ? '自定义信息' : '',
      items,
    };
  };

  const getDetailInfos = () => {
    const items = getMainObjCustomFieldsToDetail({ customFields }, { dataIndex }, componentsProps);
    return {
      title: items?.length ? '自定义信息' : '',
      items,
    };
  };

  if (type === 'form') {
    checkFormConfig(formConfig, customFields.objectCode);
    return getFormInfos() as DataFormLayoutInfoBlock;
  }
  if (type === 'detail') {
    return getDetailInfos() as DetailLayoutInfoBlock;
  }

  return {
    title: '',
    items: [],
  };
};

/**
 * 格式化数据中的自定义字段
 * @param param.data 从form获取的所有数据
 * @param param.customFields 整个对象的自定义字段信息
 */
export const formatCustomFieldsInData = ({
  data,
  customFields,
}: {
  data: any;
  customFields?: ICustomFields;
}) => {
  if (_.isEmpty(data) || _.isNil(customFields)) return data;

  // 格式化 数据
  const formatFn = (data: any): any => {
    const newCustomFields: ICustomFieldSubmitData[] = [];

    _.forIn(data, (value, key) => {
      const [objectCode, fieldId] = key.split('_');
      const { customFields: objectCustomFields } = getObjectCustomFields({
        customFields,
        objectCode,
      });
      const copyFieldItem = _.cloneDeep(
        _.find(objectCustomFields, ['fieldId', _.toNumber(fieldId)]),
      );

      if (!_.isNil(copyFieldItem)) {
        // 字段格式化 接口方法
        const { formatToApi } = getFormCustomFieldItem(copyFieldItem) ?? {};
        if (_.isFunction(formatToApi)) {
          _.set(copyFieldItem, 'fieldValue', formatToApi(value));
        } else {
          _.set(copyFieldItem, 'fieldValue', value);
        }
        const { fieldId, fieldName, fieldValue } = copyFieldItem;

        if (fieldValue && !_.isUndefined(fieldValue.isNumberRuleConfig)) {
          const isNumberRuleActuallyConfig = Number(fieldValue.isNumberRuleConfig);
          const newFieldValue = fieldValue.value;
          newCustomFields.push({
            fieldId,
            fieldName,
            fieldValue: newFieldValue,
            isNumberRuleActuallyConfig,
          });
        } else {
          newCustomFields.push({ fieldId, fieldName, fieldValue });
        }
      }
    });

    return newCustomFields;
  };

  // 递归 数据
  const recursiveFn = (data: any): any => {
    if (_.isArray(data)) {
      return _.map(data, recursiveFn);
    }

    if (_.isObject(data)) {
      _.forIn(data, (value, key) => {
        if (key === 'customFields') {
          _.set(data, 'customFields', formatFn(value));
        } else {
          _.set(data, key, recursiveFn(value));
        }
      });

      return data;
    }

    return data;
  };

  return recursiveFn(data);
};

/**
 * 初始化数据中的自定义字段数据,可以一次性处理所有，也可以分步处理每个一对象，看具体业务怎么初始化form数据
 * @param data 从form获取的所有数据
 */
export const initCustomFieldsInData = (data: any) => {
  if (_.isEmpty(data)) return data;

  // 初始化 数据
  const initialFn = (data: ICustomFieldItem[]): any => {
    const newCustomFields = {};

    _.forEach(data, (dataSource) => {
      const { fieldId, fieldValue, objectCode } = dataSource;
      const currentPath: string[] = _.tail(getCustomFieldNameOrkey({ objectCode, fieldId }));

      // 字段格式化 form方法
      const { formatToForm } = getFormCustomFieldItem(dataSource) ?? {};

      if (_.isFunction(formatToForm)) {
        _.set(newCustomFields, currentPath, formatToForm(fieldValue));
      } else {
        _.set(newCustomFields, currentPath, fieldValue);
      }
    });

    return newCustomFields;
  };

  // 递归 数据
  const recursiveFn = (data: any): any => {
    if (_.isArray(data)) {
      return _.map(data, recursiveFn);
    }

    if (_.isObject(data)) {
      _.forIn(data, (value, key) => {
        if (key === 'customFields') {
          _.set(data, 'customFields', initialFn(value));
        } else {
          _.set(data, key, recursiveFn(value));
        }
      });

      return data;
    }

    return data;
  };

  return recursiveFn(data);
};

/**
 * 获取自定义字段的聚合信息
 * 目前包括：自定义字段，级联关系
 *
 * @param objectCode 主对象code
 * @param subObjectCodes 从对象code列表
 */
export const useCustomFieldCombinedData = (objectCode: string) => {
  const [customFields, setCustomFields] = useState<ICustomFields>({
    objectCode,
    customFields: [],
    subObjects: [],
  });

  const fetchData = async () => {
    const res1 = await fetchCustomFieldInstanceGetByObjectCode({ objectCode });
    const fields = {
      customFields: res1.data?.customFields ?? [],
      subObjects: res1.data?.subObjects ?? [],
      sonObjects: res1.data?.sonObjects ?? [],
      objectCode: res1.data?.objectCode ?? objectCode,
    };
    const subObjectCodes = _.map(fields.subObjects, 'objectCode');
    const res2 = await fetchCascadeBatchTree(
      [objectCode, ...subObjectCodes].map((objectCode) => ({
        objectCode,
        status: appEnum.Common.UsageStatus.enabled,
      })),
    );
    const result = {
      ...fields,
      fieldCascadingsList: res2.data!,
    };

    setCustomFields(result as unknown as ICustomFields);
  };
  useEffect(() => {
    fetchData();
  }, [objectCode]);

  return customFields;
};
