import { Form, Input, FormInstance, DatePicker } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { ColumnProps } from 'antd/lib/table';

import { TagList, BlSortFormList } from 'src/components';
import { PurchaseExecStatus } from 'src/dict/purchase';
import _Time from 'src/utils/dataTypes/time';
import _, { map, max, compact } from 'lodash';
import { replaceSign } from 'src/utils/constants';
import { useEffect } from 'react';
import { numberMinMaxCheck, fractionLengthCheck } from 'src/utils/formValidators';
import { materialEntity } from 'src/entity';
import { injectCustomFieldColumns } from 'src/components/customField';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import EditableText from 'src/components/editableText';
import _Array from 'src/utils/dataTypes/array';

interface Props {
  orderStatus: number;
  name: string;
  edit: boolean;
  form: FormInstance;
  customFields: any;
}
/** 默认单位精度 */
const DEFAULT_PRECISION = 10;

export default function MaterialTable({ name, form, edit, orderStatus, customFields }: Props) {
  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        title: '采购订单行号',
        dataIndex: 'purchaseOrderItemLineNo',
        key: 'purchaseOrderItemLineNo',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'purchaseOrderItemLineNo']}
              fieldKey={[field?.key, 'purchaseOrderItemLineNo']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '项次',
        dataIndex: 'seq',
        key: 'seq',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'seq']}
              fieldKey={[field?.key, 'seq']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '物料编号',
        dataIndex: 'materialCode',
        key: 'materialCode',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'material', 'baseInfo', 'code']}
              fieldKey={[field?.key, 'materialCode']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '物料名称',
        dataIndex: 'materialName',
        key: 'materialName',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'material', 'baseInfo', 'name']}
              fieldKey={[field?.key, 'materialName']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '物料属性',
        dataIndex: 'materialAttribute',
        key: 'materialAttribute',
        width: 150,
        render: (_, field) => {
          const attribute = form.getFieldValue(name)[field.name]?.material?.attribute;

          if (_Array.isEmpty(attribute)) {
            return replaceSign;
          }
          return (
            <TagList
              dataSource={map(attribute, ({ id, name, attributeItem }) => ({
                label: `${name}:${attributeItem?.content}`,
                value: id,
              }))}
            />
          );
        },
      },
      {
        title: '物料规格',
        dataIndex: 'materialSpecification',
        key: 'seq',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'material', 'baseInfo', 'specification']}
              fieldKey={[field?.key, 'materialSpecification']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '用量:分子',
        dataIndex: 'inputAmountNumerator',
        key: 'inputAmountNumerator',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'inputAmountNumerator']}
              fieldKey={[field?.key, 'inputAmountNumerator']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '用量:分母',
        dataIndex: 'inputAmountDenominator',
        key: 'inputAmountDenominator',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'inputAmountDenominator']}
              fieldKey={[field?.key, 'inputAmountDenominator']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '标准用量',
        dataIndex: 'standardDosage',
        key: 'standardDosage',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'standardDosage', 'amountDisplay']}
              fieldKey={[field?.key, 'standardDosage']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '需求数',
        dataIndex: 'demandAmount',
        key: 'demandAmount',
        width: 150,
        render: (_, field) => {
          const { material } = form.getFieldValue('outItemList')[field.name] ?? {};
          const unitId = material?.inventoryAmount?.unitId;
          const { enablePrecision, precisionFigure } = material?.unit;

          return (
            <Form.Item
              name={[field?.name, 'demandAmount', 'amount']}
              fieldKey={[field?.key, 'demandAmount']}
              rules={compact([
                { required: true, message: '请输入需求数' },
                enablePrecision && {
                  validator: fractionLengthCheck(
                    precisionFigure ?? DEFAULT_PRECISION,
                    `请输入${precisionFigure ?? DEFAULT_PRECISION}位精度的数字`,
                  ),
                },
                {
                  validator: numberMinMaxCheck({
                    min: 0,
                    fieldName: '需求数',
                    message: '请输入大于0的数字',
                  }),
                },
              ])}
              style={{ marginBottom: 0 }}
            >
              <Input
                disabled={
                  orderStatus !== PurchaseExecStatus.created &&
                  orderStatus !== PurchaseExecStatus.undetermined
                }
              />
            </Form.Item>
          );
        },
      },
      {
        title: '单位',
        dataIndex: 'unitName',
        key: 'unitName',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'unit', 'name']}
              fieldKey={[field?.key, 'unitName']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
      {
        title: '损耗率',
        dataIndex: 'lossRate',
        key: 'lossRate',
        width: 150,
        render: (_, field) => {
          return (
            <Form.Item
              name={[field?.name, 'lossRate']}
              fieldKey={[field?.key, 'lossRate']}
              style={{ marginBottom: 0 }}
            >
              <EditableText canEdit={false} />
            </Form.Item>
          );
        },
      },
    ];
  };

  return (
    <BlSortFormList
      form={form}
      name={name}
      isNeedDrag={false}
      isNeedAdd={false}
      isNeedDelete={orderStatus === PurchaseExecStatus.created}
      // 注入从对象自定义字段列
      renderColumns={() =>
        injectCustomFieldColumns({
          dataIndex: '',
          columns: getColumns(), // 原本的列
          customFields, // 自定义字段信息
          objectCode: OBJECT_OF_CODE.outsourceMaterialList, // 从对象code
          type: 'form', // 使用类型
          formConfig: { form, formName: name }, // form相关配置
          disabled: edit && orderStatus !== PurchaseExecStatus.created,
        })
      }
    />
  );
}
