import { textRules, textRules1, whiteSpaceValidator, positiveIntegerValidator } from './rules';
import { trim } from 'lodash';
import { RuleObject } from 'antd/lib/form';

/**
 * @description: 必须是字母、中文、支持所有特殊字符
 * @param {*}
 * @return {*}
 */
export const validateBlText = () => {
  return (_rule: any, value: any) => {
    return new Promise((resolve, reject) => {
      if (!value) return resolve(true);
      if (!textRules(value)) reject('必须是字母、数字、支持中文、特殊字符');

      return resolve(true);
    });
  };
};

export const validateBlText1 = () => {
  return (_rule: any, value: any) => {
    return new Promise((resolve, reject) => {
      if (!value) return resolve(true);
      if (!textRules1(value)) reject('必须是字母、数字、特殊字符');

      return resolve(true);
    });
  };
};

export const validateBlWhiteSpace = () => {
  return (_rule: any, value: any) => {
    return new Promise((resolve, reject) => {
      if (!value) return resolve(true);
      if (!whiteSpaceValidator(value)) reject('前后不支持空格');

      return resolve(true);
    });
  };
};

/**
 * @description: 必须是字母、中文、支持所有特殊字符且前后不能有空格
 * @param {*}
 * @return {*}
 */
export const validateBlTextAndWhiteSpace = () => {
  return (_rule: any, value: any) => {
    return new Promise((resolve, reject) => {
      if (!value) return resolve(true);
      if (!whiteSpaceValidator(value)) reject('前后不支持空格');
      if (!textRules(value)) reject('必须是字母、数字、支持中文、特殊字符');
      return resolve(true);
    });
  };
};

/**
 * @description: 必须是字母、数字、特殊字符且前后不能有空格
 * @param {*}
 * @return {*}
 */
export const validateBlText1AndWhiteSpace = () => {
  return (_rule: any, value: any) => {
    return new Promise((resolve, reject) => {
      if (!value) return resolve(true);
      if (!whiteSpaceValidator(value)) reject('前后不支持空格');
      if (!textRules1(value)) reject('必须是字母、数字、特殊字符');
      return resolve(true);
    });
  };
};

export const validateEmpty = () => {
  return (_rule: any, value: any) => {
    return new Promise((resolve, reject) => {
      const _value = trim(value);

      if (!_value) {
        reject('不能为空');
      }
      if (!whiteSpaceValidator(value)) return resolve(true);
    });
  };
};

export const validatePositiveInteger = () => {
  return (_rule: any, value: any) => {
    return new Promise((resolve, reject) => {
      if (!value) return resolve(true);
      if (!positiveIntegerValidator(value)) reject('必须为正整数');

      return resolve(true);
    });
  };
};

/**
 * @description: 必须是整数
 * @param {*} value
 * @return {*}
 */
export const inputNumberIntRules = (value: any): boolean => {
  const reg = new RegExp('^-?\\d*$');

  return reg.test(value);
};

/**
 * @description: 数值不能超过上限
 * @param {number} max: 最大值
 * @param {*} maxAllowEqual 最大值是否允许等于，默认true
 * @param {*} decimalAllow 是否允许小数，默认true
 */
export const validatorMax = (
  max = Infinity,
  maxAllowEqual = true,
  decimalAllow = true,
  name: string = '',
) => {
  return (_rule: RuleObject, value: any) => {
    return new Promise<void>((resolve, reject) => {
      if (!value) return resolve();
      if (!decimalAllow && !inputNumberIntRules(value)) reject(`${name}必须是整数`);
      if (maxAllowEqual && value > max) reject(`${name}数值不能超过${max}`);
      if (!maxAllowEqual && value >= max) reject(`${name}数值必须小于${max}`);
      return resolve();
    });
  };
};
