import { RouteProp } from 'src/route';
import PurchaseOrderList from './purchaseManagement/purchaseOrder';
import PurchaseOrderDetail from './purchaseManagement/purchaseOrder/details';
import OperatePurchaseOrder from './purchaseManagement/purchaseOrder/createAndEdit';
import DeliveryOrderList from './purchaseManagement/deliveryOrder/list';
import DeliveryOrderDetail from './purchaseManagement/deliveryOrder/detail';
import DeliveryOrderEdit from './purchaseManagement/deliveryOrder/edit';
import DeliveryOrderCreate from './purchaseManagement/deliveryOrder/create';
import InBoundCreate from 'src/page/warehouseManagement/boundOrderManagement/inBound/create';
import PurchaseReturnOrderList from './purchaseManagement/purchaseReturnOrder/purchaseReturnOrderList';
import CreatePurchaseReturnOrder from './purchaseManagement/purchaseReturnOrder/purchaseReturnOrderOperator/create';
import EditPurchaseReturnOrder from './purchaseManagement/purchaseReturnOrder/purchaseReturnOrderOperator/edit';
import PurchaseReturnOrderDetail from './purchaseManagement/purchaseReturnOrder/purchaseReturnOrderDetail';
import authDict from 'src/utils/auth';
import OutBoundOrderCreate from '../warehouseManagement/boundOrderManagement/outBound/create';
import PurchaseWorkbench from './purchaseManagement/purchaseWorkbench';
import PurchaseProgress from './purchaseManagement/purchaseProgress';
import deliveryScheduleNoteList from './purchaseManagement/deliveryScheduleNote';
import deliveryScheduleNoteDetail from './purchaseManagement/deliveryScheduleNote/details';
import createAndEditDeliveryScheduleNote from './purchaseManagement/deliveryScheduleNote/createAndEdit';

export const purchaseRouteConfig: RouteProp[] = [
  {
    path: '/purchase',
    menu: '采购',
    breadcrumb: '采购',
    icon: 'iconyijicaidan-caigou',
    redirectPath: '/purchase/purchaseManagement/purchaseOrder',
    children: [
      {
        path: '/purchase/purchaseManagement',
        menu: '采购管理',
        breadcrumb: '采购管理',
        redirectPath: '/purchase/purchaseManagement/purchaseOrder',
        children: [
          {
            path: '/purchase/purchaseManagement/purchaseOrder',
            auth: authDict.purchaseorder_view,
            logPage: true,
            component: PurchaseOrderList,
            menu: '采购订单',
            breadcrumb: '采购订单',
            children: [
              {
                path: '/purchase/purchaseManagement/purchaseOrder/:id/detail',
                component: PurchaseOrderDetail,
                auth: authDict.purchaseorder_detail,
                menu: '采购订单详情',
                breadcrumb: '采购订单详情',
              },
              {
                path: '/purchase/purchaseManagement/purchaseOrder/:id/edit',
                component: OperatePurchaseOrder,
                auth: authDict.purchaseorder_edit,
                menu: '编辑采购订单',
                breadcrumb: '编辑采购订单',
              },
              {
                path: '/purchase/purchaseManagement/purchaseOrder/create',
                component: OperatePurchaseOrder,
                auth: authDict.purchaseorder_add,
                menu: '新建采购订单',
                breadcrumb: '新建采购订单',
              },
              {
                path: '/purchase/purchaseManagement/purchaseOrder/createPurchaseReturnOrder',
                component: CreatePurchaseReturnOrder,
                auth: authDict.purchasereturnorder_add,
                breadcrumb: '新建采购退货单',
              },
              {
                path: '/purchase/purchaseManagement/purchaseOrder/createDeliveryOrder',
                component: DeliveryOrderCreate,
                auth: authDict.receivenote_add,
                breadcrumb: '新建收货单',
              },
              {
                path: '/purchase/purchaseManagement/purchaseOrder/createDeliveryScheduleNote',
                component: createAndEditDeliveryScheduleNote,
                auth: authDict.deliveryScheduleNote_add,
                breadcrumb: '新建交货计划单',
              },
            ],
          },
          {
            path: '/purchase/purchaseManagement/deliveryOrder',
            component: DeliveryOrderList,
            menu: '收货单',
            logPage: true,
            auth: authDict.receivenote_view,
            breadcrumb: '收货单',
            children: [
              {
                path: '/purchase/purchaseManagement/deliveryOrder/detail',
                component: DeliveryOrderDetail,
                auth: authDict.receivenote_detail,
                breadcrumb: '收货单详情',
              },
              {
                path: '/purchase/purchaseManagement/deliveryOrder/edit',
                component: DeliveryOrderEdit,
                auth: authDict.receivenote_edit,
                breadcrumb: '编辑收货单',
              },
              {
                path: '/purchase/purchaseManagement/deliveryOrder/create',
                component: DeliveryOrderCreate,
                auth: authDict.receivenote_add,
                breadcrumb: '新建收货单',
              },
              {
                path: '/purchase/purchaseManagement/deliveryOrder/inbound',
                component: InBoundCreate,
                auth: authDict.inboundorder_add,
                breadcrumb: '新建入库单',
              },
            ],
          },
          {
            path: '/purchase/purchaseManagement/purchaseReturnOrder',
            logPage: true,
            auth: authDict.purchasereturnorder_view,
            component: PurchaseReturnOrderList,
            menu: '采购退货单',
            breadcrumb: '采购退货单',
            children: [
              {
                path: '/purchase/purchaseManagement/purchaseReturnOrder/create',
                component: CreatePurchaseReturnOrder,
                auth: authDict.purchasereturnorder_add,
                breadcrumb: '新建采购退货单',
              },
              {
                path: '/purchase/purchaseManagement/purchaseReturnOrder/:id/edit',
                component: EditPurchaseReturnOrder,
                auth: authDict.purchasereturnorder_edit,
                breadcrumb: '编辑采购退货单',
              },
              {
                path: '/purchase/purchaseManagement/purchaseReturnOrder/:id/detail',
                component: PurchaseReturnOrderDetail,
                auth: authDict.purchasereturnorder_detail,
                breadcrumb: '采购退货单详情',
              },
              {
                path: '/purchase/purchaseManagement/purchaseReturnOrder/createOutBound',
                auth: authDict.outboundorder_add,
                component: OutBoundOrderCreate,
                menu: '新建出库单',
                breadcrumb: '新建出库单',
              },
            ],
          },
          {
            path: '/purchase/purchaseManagement/purchaseWorkbench',
            auth: authDict.purchaseorder_workbench,
            component: PurchaseWorkbench,
            menu: '采购工作台',
            breadcrumb: '采购工作台',
          },
          {
            path: '/purchase/purchaseManagement/purchaseProgress',
            auth: authDict.purchaseorder_purchase_progress_report,
            component: PurchaseProgress,
            menu: '采购进度报表',
            breadcrumb: '采购进度报表',
          },
          {
            path: '/purchase/purchaseManagement/deliveryScheduleNote',
            auth: authDict.deliveryScheduleNote_view,
            logPage: true,
            component: deliveryScheduleNoteList,
            menu: '交货计划单',
            breadcrumb: '交货计划单',
            children: [
              {
                path: '/purchase/purchaseManagement/deliveryScheduleNote/:id/detail',
                component: deliveryScheduleNoteDetail,
                auth: authDict.deliveryScheduleNote_detail,
                menu: '交货计划单详情',
                breadcrumb: '交货计划单详情',
              },
              {
                path: '/purchase/purchaseManagement/deliveryScheduleNote/:id/edit',
                component: createAndEditDeliveryScheduleNote,
                auth: authDict.deliveryScheduleNote_edit,
                menu: '编辑交货计划单',
                breadcrumb: '编辑交货计划单',
              },
              {
                path: '/purchase/purchaseManagement/deliveryScheduleNote/create',
                component: createAndEditDeliveryScheduleNote,
                auth: authDict.deliveryScheduleNote_add,
                menu: '新建交货计划单',
                breadcrumb: '新建交货计划单',
              },
            ],
          },
        ],
      },
    ],
  },
];
