import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { TabList } from 'src/components';
// import ResourceRecordTree from '../components/resourceRecordTree';
import TreeContent, { MaintenanceTreeFnParams } from '../components/maintenanceTree';
import { ResourceIdContext } from './constants';
import ListView from './ListView';
import ChartView from './ChartView';

const listViewKey = 'resourceMoniter-listView';
const chartViewKey = 'resourceMoniter-charView';
const resourceMonitorTabs = {
  [listViewKey]: '列表视图',
  [chartViewKey]: '动态视图',
};

const ResourceMonitor = () => {
  // 设备/能源仪表 ID
  const [definId, setDefinId] = useState<number | undefined>();
  // 业务类型
  const [businessType, setBussinessType] = useState<number>(0);

  const tabList = [
    {
      tab: resourceMonitorTabs[listViewKey],
      key: listViewKey,
      component: <ListView definId={definId} businessType={businessType} />,
    },
    {
      tab: resourceMonitorTabs[chartViewKey],
      key: chartViewKey,
      component: (
        <ResourceIdContext.Provider value={{ resourceId: definId }}>
          <ChartView businessType={businessType} />
        </ResourceIdContext.Provider>
      ),
    },
  ];

  // 右侧组件
  const children = <TabList tabList={tabList} defaultKey={listViewKey} />;

  return (
    <TreeContent
      onChange={(value: MaintenanceTreeFnParams) => {
        console.log(value);
        const { businessType, target } = value;
        const { id } = target as any;

        setBussinessType(businessType ?? 0);
        setDefinId(id);
      }}
      onlySelectEquipment
      cacheKey={'resourceMonitor'}
      hasSubLocationFlag={false}
      businessTypeLabel={'业务对象'}
    >
      {children}
    </TreeContent>
  );
};

export default withRouter(ResourceMonitor);
