/**
 * SOP任务执行记录
 */
import { useState } from 'react';
import { useParams } from 'react-router';
import _ from 'lodash';
import { Button, Modal, Popover } from 'antd';
import { RecordListLayout } from 'layout';

import { fetchSopExecTaskExecRecords } from 'src/api/ytt/sop-domain';
import { formatTimeForRender } from 'src/utils/formatters';
import lookup from 'src/dict';
import { renderUser } from 'src/page/share/renderUser';
import { User } from 'src/page/share';
import OperationRecordTable from './components/operationRecordTable';
import { AssociateFieldRecordType, StepRecord } from '../type';
import ControlValue from '../components/controlValue';

import styles from '../styles.module.scss';

type LoopInfo = Required<StepRecord['loopInfo']>;

type ModalInfo = {
  visible: boolean;
  [x: string]: any;
};

const TaskExecRecord = () => {
  const { sopTaskId: id } = useParams<{ sopTaskId: string }>();
  const [operationRecordModal, setOperationRecordModal] = useState<ModalInfo>({ visible: false });

  const formatDataToQuery = (params: any) => {
    const { ...rest } = params;

    const queryParams = {
      sopTaskId: _.toNumber(id),
      // sopTaskId: 1653893982173012,
      ...rest,
    };

    return {
      ...queryParams,
    };
  };

  const fetchFn = async (params: any) => {
    const res = await fetchSopExecTaskExecRecords(params);

    const flattenedList = _.flatMap(res?.data?.list ?? [], (record: StepRecord) => {
      if (_.isEmpty(record.controlRecord)) {
        return record;
      }

      return {
        ..._.omit(record, 'controlRecord'),
        ..._.mapKeys(record.controlRecord, (__, key) => `control${_.upperFirst(_.toString(key))}`),
      };
    });

    return {
      ...res,
      ...{ data: { list: flattenedList, page: res?.data?.page, total: res?.data?.total } },
    };
  };

  const dataColumns = [
    {
      title: '步骤编号',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: '步骤名称',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: '步骤结束时间',
      dataIndex: 'finishTime',
      width: 190,
      render: formatTimeForRender,
    },
    {
      title: '电子签名',
      dataIndex: 'authUser',
      width: 150,
      render: renderUser,
    },
    {
      title: '执行人',
      dataIndex: 'execUser',
      width: 150,
      render: renderUser,
    },
    {
      title: '循环执行',
      dataIndex: 'loopInfo',
      width: 170,
      render: (loop: LoopInfo, record: StepRecord) => {
        const enableText = lookup('ynb', record.loopFlag);

        if (!record.loopFlag) {
          return enableText;
        }
        const content = (
          <section>
            <p>步骤名称：{loop?.name}</p>
            <p>循环次数：第{loop?.currentLoopTimes}次循环</p>
            <p>本次循环结束时间：{formatTimeForRender(loop?.loopFinishTime)}</p>
            <p>本次执行计划结束时间：{formatTimeForRender(loop?.planFinishTime)}</p>
          </section>
        );

        return (
          <div>
            {enableText}；
            <Popover content={content}>
              <a onClick={(e) => e.preventDefault()}>查看循环信息</a>
            </Popover>
          </div>
        );
      },
    },
    {
      title: '控件编号',
      dataIndex: 'controlCode',
      width: 150,
    },
    {
      title: '控件名称',
      dataIndex: 'controlName',
      width: 150,
    },
    {
      title: '记录结果',
      dataIndex: 'controlValue',
      width: 150,
      render: (
        controlValue: string | number[] | User[] | AssociateFieldRecordType,
        record: StepRecord,
      ) => {
        const controlType = _.get(record, 'controlControlType');

        return (
          <div className={styles.controlValueWrap}>
            <ControlValue controlType={controlType} controlValue={controlValue} />
          </div>
        );
      },
    },
    {
      title: '控件编辑时间',
      dataIndex: 'editTime',
      width: 190,
      render: formatTimeForRender,
    },
  ];
  const getOperationList = (record) => {
    return [
      {
        title: '查看操作记录',
        onClick: () => {
          setOperationRecordModal({
            visible: true,
            controlRecordId: record?.controlId,
          });
        },
      },
    ];
  };

  return (
    <>
      <RecordListLayout<any>
        columns={dataColumns}
        rowKey={`finishTime-${+new Date()}`}
        useColConfig={false}
        formatDataToQuery={formatDataToQuery}
        requestFn={(parmas: any) => fetchFn(parmas)}
        getOperationList={getOperationList}
      />

      <Modal
        visible={operationRecordModal.visible}
        destroyOnClose
        title="操作记录"
        width={1080}
        centered
        onCancel={() => {
          setOperationRecordModal({ ...operationRecordModal, ...{ visible: false } });
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() =>
              setOperationRecordModal({ ...operationRecordModal, ...{ visible: false } })
            }
          >
            关闭
          </Button>,
        ]}
      >
        <OperationRecordTable controlRecordId={operationRecordModal?.controlRecordId} />
      </Modal>
    </>
  );
};

export default TaskExecRecord;
