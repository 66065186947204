import React, { memo, useState } from 'react';
import { Dropdown, DropDownProps, Menu, message, Modal, Tree } from 'antd';
import { BlIcon } from 'src/components';
import styles from './styles.module.scss';
import { GroupType, PrivilegeType, StepEntityType } from 'src/dict/sop';
import { getRenderStepName } from '../../share/stepRenderers';
import { CRUD } from 'src/dict/common';
import lookup from 'src/dict';
import { getById, getGroupTree, getPreviousSiblings, treeFindParent } from '../../share/stepUtils';
import _ from 'lodash';
import CopyStepModal, { CopyStepModalProps } from '../stepList/copyStepModal';
import { StepOverviewData, StepOverviewList, StepRowData, StepTreeData } from '../../types';
import authDict, { hasAuth } from 'src/utils/auth';
import { EventDataNode } from 'antd/lib/tree';
import { NEW_STEP_NUMBER_ID } from '../../constants';

type CopyModalState = Omit<CopyStepModalProps, 'onOk' | 'onCancel' | 'steps'>;
const initialCopyModalState = {
  visible: false,
  initValues: {
    id: 0,
    referenceId: 0,
    name: '',
    type: StepEntityType.step,
    privilegeType: PrivilegeType.executors,
    groupType: GroupType.serial,
    authUsers: [],
    authDepartments: [],
    authRoles: [],
  },
  groupTree: [],
};

export interface LeftTreeProps {
  // treeData: StepOverviewList | StepTreeData[] | StepOverviewData[] | StepDetailData[];
  treeData: any[];
  originTreeData: any[];
  selectedKeys: number[];
  expandedKeys: React.Key[];
  selectNodeFn: (node: StepOverviewData) => void;
  expandFn:
    | ((
        expandedKeys: React.Key[],
        info: {
          node: EventDataNode;
          expanded: boolean;
          nativeEvent: MouseEvent;
        },
      ) => void)
    | undefined;
  createStepFn: (config: {
    parentId: number;
    prevId: number;
    type: StepEntityType;
    isDelete?: boolean;
    node: StepTreeData;
  }) => void;
  sopStepSortFn: (treeData: StepOverviewList, id: any) => void;
  needSaveFn: () => boolean;
  reFetch: () => void;
}

const BlTreeComponent = (props: LeftTreeProps) => {
  const {
    treeData = [],
    originTreeData = [],
    selectNodeFn,
    expandFn,
    createStepFn,
    sopStepSortFn,
    selectedKeys,
    expandedKeys,
    needSaveFn,
    reFetch,
  } = props;

  const [hovingNode, setHovingNode] = useState<StepTreeData>({});
  const [copyModalState, setCopyModalState] = useState<CopyModalState>(initialCopyModalState); // 复制弹窗
  const [placement, setPlacement] = useState<DropDownProps['placement']>('bottomLeft'); // 复制弹窗

  const onDrop = (info: any) => {
    // if (!hasAuth(authDict.sop_scheme_step_add_edit)) {
    //   message.error(NO_AUTH_MESSAGE);
    //   return;
    // }

    if (needSaveFn()) {
      return;
    }
    const dragKey = info.dragNode.key;
    const dragPos = info.dragNode.pos.split('-');
    const dragIndex = Number(dragPos[dragPos.length - 1]);
    const dropKey = info.node.key;
    const dropTargetPos = info.node.pos.split('-');
    let dropTargetIndex = Number(dropTargetPos[dropTargetPos.length - 1]);

    if (info.dropPosition == -1) {
      dropTargetIndex = 0;
    } else {
      dropTargetIndex += 1;
    }

    const dragObject = getById(treeData, dragKey) as StepOverviewList[number];

    if (info.node.id === info.dragNode.parentId) {
      dropTargetIndex = 0;
    } else if (
      info.node.parentId !== info.dragNode.parentId ||
      (info.node.props.expanded && !info.dropToGap)
    ) {
      message.warning('仅允许同级之间拖拽');
      return;
    }
    if (dragKey === dropKey || dropTargetIndex === dragIndex) {
      return;
    }

    // 方便起见，直接修改 dataSource
    // ① 找出操作的 list，并去除 dragObject
    const list: StepOverviewList = treeFindParent(treeData as StepRowData[], dragKey);

    list.splice(
      list.findIndex((step) => step.id === dragKey),
      1,
    );
    // ② 判断插入的位置, 修改prevId
    let insertIndex = 0;

    // dragObj 的后一项的 prev 指向 dragObj的 原prev
    if (dragIndex < list.length) {
      // 考虑到 dragObj 已被滤出
      list[dragIndex].prevId = dragObject.prevId!;
    }
    if (dragIndex > dropTargetIndex) {
      const dropTargetObject = list[dropTargetIndex];
      const dropTargetPrevId = dropTargetObject.prevId;

      // 上移：dragObj 放到 dropTarget 之前
      insertIndex = dropTargetIndex;
      // dropTarget 的 prev 指向 dragObj
      dropTargetObject.prevId = dragKey;
      // dragObj 的 prev 指向 dropTarget 原来的 prev
      dragObject.prevId = dropTargetPrevId;
    } else {
      // 下移: dragKey 放到 dropTarget 之后, 但注意 list 里已经去掉了 dragObj, 故 dropTargetIndex 需 -1
      insertIndex = dropTargetIndex - 1;
      // dropTarget 的 prev 不变
      // dragObj 的 prev 指向 dropTarget
      dragObject.prevId = dropKey;
      // dropTarget 原来的后一项(如有)的 prev 指向 dragObj
      if (dropTargetIndex < list.length + 1) {
        list[insertIndex].prevId = dragKey;
      }
    }

    // ③ 插入 dragObject
    list.splice(insertIndex, 0, dragObject);
    sopStepSortFn(treeData as StepOverviewList, dragKey);
  };
  const handleSelect = (selectedKeysValue: React.Key[], info: any) => {
    const { node } = info;

    selectNodeFn(node);
  };

  const handleTreeNodeEnter = _.debounce(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, node: StepTreeData) => {
      if (window.innerHeight - e?.clientY < 200) {
        setPlacement('topRight');
      } else {
        setPlacement('bottomLeft');
      }

      setHovingNode(node);
    },
    300,
  );

  const handleCreateStep = (config: {
    parentId: number;
    prevId: number;
    type: StepEntityType;
    isDelete?: boolean;
    node: StepTreeData;
    isChild?: boolean;
  }) => {
    createStepFn(config);
  };

  const titleRender = (node: any) => {
    return (
      <div
        className={styles.titleWrap}
        onMouseEnter={(e) => handleTreeNodeEnter(e, node)}
        // onMouseLeave={(e) => handleTreeNodeLeave(e, node)}
      >
        <span style={{ minWidth: 200, overflow: 'auto' }}>
          <span className="iconDrag">
            <BlIcon type="iconrenyituozhuai" />
          </span>
          <div className="titleCon">
            {node.id === NEW_STEP_NUMBER_ID ? (
              <span className="treeNodeTitletext"> {node.title}</span>
            ) : (
              getRenderStepName(treeData, true)(node.title, node)
            )}
            <div className="iconNode">
              <Dropdown
                placement={placement}
                overlay={
                  <Menu onClick={(e) => e.domEvent.stopPropagation()}>
                    <Menu.Item
                      disabled={hovingNode?.type !== StepEntityType.stepGroup}
                      key={`addSubStep_${node.id}`}
                      onClick={() =>
                        handleCreateStep({
                          parentId: node.id,
                          prevId: 0,
                          type: StepEntityType.step,
                          node,
                          isChild: true,
                        })
                      }
                    >
                      子步骤
                    </Menu.Item>
                    <Menu.Item
                      disabled={hovingNode?.type !== StepEntityType.stepGroup}
                      key={'addSubStepGroup'}
                      onClick={() =>
                        handleCreateStep({
                          parentId: node.id,
                          prevId: 0,
                          type: StepEntityType.stepGroup,
                          node,
                          isChild: true,
                        })
                      }
                    >
                      子步骤组
                    </Menu.Item>

                    <Menu.Item
                      key={'addNextStep'}
                      onClick={() => {
                        handleCreateStep({
                          parentId: node.parentId,
                          prevId: node.id,
                          type: StepEntityType.step,
                          node,
                        });
                      }}
                    >
                      后续步骤
                    </Menu.Item>
                    <Menu.Item
                      key={'addNextStepGroup'}
                      onClick={() => {
                        handleCreateStep({
                          parentId: node.parentId,
                          prevId: node.id,
                          type: StepEntityType.stepGroup,
                          node,
                        });
                      }}
                    >
                      后续步骤组
                    </Menu.Item>
                    <Menu.Item
                      key={'copyStepGroup'}
                      onClick={() => {
                        if (needSaveFn()) {
                          return;
                        }
                        setCopyModalState({
                          visible: true,
                          initValues: _.pick(node, [
                            'id',
                            'referenceId',
                            'name',
                            'type',
                            'groupType',
                            'privilegeType',
                            'authUsers',
                            'authDepartments',
                            'authRoles',
                          ]),
                          groupTree: getGroupTree(originTreeData),
                        });
                      }}
                    >
                      {lookup('common.crud', CRUD.copy)}
                    </Menu.Item>
                    {hasAuth(authDict.sopscheme_step_remove) && (
                      <Menu.Item
                        key={'delStepGroup'}
                        onClick={() => {
                          if (needSaveFn()) {
                            return;
                          }
                          const previousSiblings = getPreviousSiblings(treeData, node.id);

                          if (previousSiblings.length > 0) {
                            message.error(
                              `不能删除，因为当前步骤是以下步骤：${_.map(
                                previousSiblings,
                                'name',
                              ).join('、')} 的后续步骤。`,
                              8,
                            );
                            return;
                          }

                          Modal.confirm({
                            title: `确认要删除当前${lookup('sop.stepEntityType', node.type)}吗？`,
                            onOk: () =>
                              handleCreateStep({
                                parentId: node.parentId,
                                prevId: node.id,
                                type: StepEntityType.stepGroup,
                                isDelete: true,
                                node,
                              }),
                          });
                        }}
                      >
                        {lookup('common.crud', CRUD.delete)}
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <BlIcon type="icongengduo" />
              </Dropdown>
            </div>
          </div>
        </span>
      </div>
    );
  };

  return (
    <>
      <Tree
        className={styles.draggableTree}
        draggable
        showLine={{ showLeafIcon: false }}
        showIcon
        switcherIcon={<span className="customIcon" />}
        onDrop={onDrop}
        treeData={treeData}
        onSelect={handleSelect}
        onExpand={expandFn}
        selectedKeys={selectedKeys}
        expandedKeys={expandedKeys}
        titleRender={(node: any) => titleRender(node)}
      />
      <CopyStepModal
        onOk={reFetch}
        onCancel={() => setCopyModalState(initialCopyModalState)}
        steps={treeData as StepOverviewList}
        {...copyModalState}
      />
    </>
  );
};

export default memo(BlTreeComponent);
