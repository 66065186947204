import { FetchTraceTraceRelationshipInventoryGraphRelationshipsResponse } from 'src/api/ytt/trace-domain/traceRelationship';

type Relation = Exclude<
  FetchTraceTraceRelationshipInventoryGraphRelationshipsResponse['data'],
  undefined
>['edges'];

const getNeighbors = (id: number, relations: Relation) => {
  const neighbors: number[] = [];

  relations?.forEach((item) => {
    if (item.source === id) {
      neighbors.push(item.target);
    }
    if (item.target === id) {
      neighbors.push(item.source);
    }
  });
  return neighbors;
};

const getOverflowText = (text?: string) => {
  if (!text) return '-';
  const maxlen = 8;

  if (text.length > maxlen) {
    return `${text.slice(0, maxlen)}...`;
  }
  return text;
};

export { getNeighbors, getOverflowText };
