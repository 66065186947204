/**
 * 列表、详情页共用的SOP任务操作
 */
import { useCallback } from 'react';
import { Form, message, Modal } from 'antd';
import {
  fetchSopExecTaskForceFinish,
  fetchSopExecTaskDelete,
  fetchSopExecTaskCancel,
  fetchSopExecTaskDispatch,
  fetchSopExecTaskPause,
  fetchSopExecTaskResume,
} from 'src/api/ytt/sop-domain';
import { TaskStatus } from 'src/dict/sop';
import BLDelConfirm from 'src/page/knowledgeManagement/share/modalConfirm';
import type { SopTaskOverviewData } from '../type';

interface Props {
  refresh: () => void;
  afterDelete?: () => void;
}

const useTaskOperations = (props: Props) => {
  const { refresh, afterDelete } = props;
  const [reasonForm] = Form.useForm();

  /** 下发 */
  const handleDispatch = useCallback((record: SopTaskOverviewData) => {
    fetchSopExecTaskDispatch({ sopTaskId: record.sopTaskId }).then(() => {
      message.success('下发成功');
      refresh();
    });
  }, []);

  /** 暂停 */
  const handleSuspend = useCallback((sopTaskId: number, sopTaskStatus: TaskStatus) => {
    if (sopTaskStatus === TaskStatus.executing) {
      fetchSopExecTaskPause({ sopTaskId }).then(() => {
        message.success('任务已暂停');
        refresh();
      });
    } else {
      message.error('执行中的任务才能暂停');
    }
  }, []);

  /** 继续 */
  const handleResume = useCallback((sopTaskId: number, sopTaskStatus: TaskStatus) => {
    if (sopTaskStatus === TaskStatus.suspended) {
      fetchSopExecTaskResume({ sopTaskId }).then(() => {
        message.success('任务继续');
        refresh();
      });
    } else {
      message.error('暂停中的任务才能继续');
    }
  }, []);

  /** 强制结束 */
  const handleEnd = useCallback((sopTaskId: number) => {
    BLDelConfirm('确认要强制结束当前SOP任务吗？', reasonForm, async () => {
      await fetchSopExecTaskForceFinish({
        sopTaskId,
        reason: reasonForm.getFieldValue('delReason'),
      });
      message.success('强制结束成功');
      refresh();
    });
  }, []);

  /** 取消 */
  const handleCancel = useCallback((sopTaskId: number) => {
    BLDelConfirm('确认要取消当前SOP任务吗？', reasonForm, async () => {
      await fetchSopExecTaskCancel({
        sopTaskId,
        reason: reasonForm.getFieldValue('delReason'),
      });
      message.success('取消成功');
      refresh();
    });
  }, []);

  /** 删除 */
  const handleDelete = useCallback((sopTaskId: number) => {
    Modal.confirm({
      title: '确认要删除当前SOP任务吗？',
      onOk: () =>
        fetchSopExecTaskDelete({ sopTaskId }).then(() => {
          message.success('删除成功');
          (afterDelete ?? refresh)();
        }),
    });
  }, []);

  return { handleDispatch, handleSuspend, handleResume, handleEnd, handleCancel, handleDelete };
};

export default useTaskOperations;
