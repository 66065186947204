import _ from 'lodash';
import { formatConditionGroups } from '../../triggerRule/utils/formatToFormData';

interface Option {
  value: string | number;
  label?: string;
  disabled?: boolean;
  children?: Option[];
  // 标记是否为叶子节点，设置了 `loadData` 时有效
  // 设为 `false` 时会强制标记为父节点，即使当前节点没有 children，也会显示展开图标
  isLeaf?: boolean;
  info?: any;
  key?: number | string;
}

export const formatObjectTreeToOptions = (treeData: any): Option[] => {
  if (Object.prototype.toString.call(treeData) === '[object Object]') {
    if (treeData.fields) return formatObjectTreeToOptions(treeData.fields);
  } else if (Object.prototype.toString.call(treeData) === '[object Array]') {
    const result: Option[] = [];

    treeData.forEach((item: any) => {
      const label = item.fieldName;
      const value = item.fieldCode;
      const reference = item.reference;
      const fieldCode = item.fieldCode;

      if (reference && item.object) {
        const children = formatObjectTreeToOptions(item.object);

        result.push({
          label,
          value,
          children,
          key: fieldCode,
          info: {
            objectCode: item.reference,
            objectName: item.referenceName,
          },
        });
      } else if (reference && !item.object) {
        result.push({
          label,
          value,
          key: fieldCode,
          info: {
            objectCode: item.reference,
            objectName: item.referenceName,
          },
        });
      }
    });
    return result;
  }
  return [];
};

export const formatObjectTreeAddDisable: any = (treeData: any, field: string) => {
  if (!treeData) return null;
  const newTreeData = JSON.parse(JSON.stringify(treeData));

  newTreeData.forEach((item: any) => {
    if (item.info.objectCode === field) {
      item.disabled = true;
    }
    if (item.children) {
      item.children = formatObjectTreeAddDisable(item.children, field);
    }
  });
  return newTreeData;
};

export const formatTriggerRuleConditionToForm = (data: any) => {
  if (!data) return data;
  const { objectCode, triggerCondition } = data;

  return {
    // 这里的 objectCode 字段仅仅是为了让条件 Table 正确显示，没有其他实际意义
    objectCode: { value: JSON.stringify({ objectCode }) },
    triggerCondition: {
      conditionGroups: triggerCondition?.conditionGroups?.map(formatConditionGroups), // (triggerConditionType.conditionGroups),
      type: _.isNil(triggerCondition?.type) ? undefined : triggerCondition?.type,
    },
  };
};
