import _ from 'lodash';
import { FieldType } from 'src/dict/common';
import moment from 'moment';

export const formatDataPrivilege = (roleId: number, data: any): any => {
  const ret: any = { roleId, items: [] };
  const listKeys = _.filter(_.keys(data), (i) => i.indexOf('list') > -1);

  _.forEach(listKeys, (key) => {
    const bizObjectCode = key.split('_')[0];
    const filter = data[`${bizObjectCode}_logicOperation`];

    _.forEach(data[key], (item) => {
      const { field, operation, value } = item || {};
      let retValue;
      let retLabel;

      if (Array.isArray(value)) {
        const valueList: number[] = [];
        const labelList: string[] = [];

        value.forEach(({ label, value }: { label: string; value: number }) => {
          valueList.push(value);
          labelList.push(label);
        });
        retValue = valueList.join(',');
        retLabel = labelList.join(',');
      } else if (typeof value === 'object') {
        retValue = value?.value;
        retLabel = value?.label;
      } else retValue = value;

      if (field) {
        const obj = {
          value: retValue, // 过滤占位
          labelValue: retLabel, // 过滤占位
          operation: operation?.key,
          logicOperation: filter?.key,
          fieldId: field.key,
          bizObjectCode,
          roleId,
        };

        ret.items.push(obj);
      }
    });
  });

  return ret;
};

/** 格式化接口入参 */
export const formatFormData = (roleId: number, data: any): any => {
  const ret: any = [];
  const listKeys = _.filter(_.keys(data), (i) => i.indexOf('list') > -1);

  _.forEach(listKeys, (key) => {
    const bizObjectCode = key.split('_')[0];
    const filter = data[`${bizObjectCode}_logicOperation`];
    const updates: any[] = [];

    _.forEach(data[key], (item) => {
      const { field, operation, value, option } = item || {};
      let retValue;
      let retLabel;

      if (Array.isArray(value)) {
        const valueList: number[] = [];
        const labelList: string[] = [];

        value.forEach(({ label, value }: { label: string; value: number }) => {
          valueList.push(value);
          labelList.push(label);
        });
        retValue = valueList.join(',');
        retLabel = labelList.join(',');
      } else if (typeof value === 'object') {
        retValue = value?.value;
        retLabel = value?.label;
      } else {
        retValue = value;
      }

      if (option.fieldType === FieldType.date) {
        retValue = moment(value).format('YYYY-MM-DD HH:mm:ss');
      }

      if (field) {
        updates.push({
          value: retValue,
          labelValue: retLabel,
          logicOperation: filter,
          operation,
          fieldId: field.key,
        });
      }
    });

    ret.push({
      bizObjectCode,
      roleId,
      updates,
    });
  });

  return ret;
};
