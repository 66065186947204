import React from 'react';
import { Table, Transfer } from 'antd';
import { TransferProps } from 'antd/lib/transfer';
import { difference } from 'lodash';
import { Pagination, TransferColType } from '../interface';
import styles from '../components/transferModal.module.scss';

interface TableTransferProps extends TransferProps<TransferColType> {
  leftColumns: any;
  rightColumns: any;
  pagination: any;
  handleTableChange: (pagination: Pagination) => void;
}

const TableTransfer = ({
  leftColumns,
  rightColumns,
  handleTableChange,
  pagination,
  ...restProps
}: TableTransferProps) => (
  <Transfer {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;

      const rowSelection = {
        onSelectAll(selected: any, selectedRows: any) {
          const treeSelectedKeys = selectedRows.map((node: any) => node?.id);

          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);

          onItemSelectAll(diffKeys, selected);
        },
        onSelect(record: any, selected: any) {
          onItemSelect(record?.id, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          className={styles.tableTransfer}
          dataSource={filteredItems}
          rowKey={(record: any) => record?.id}
          scroll={{ y: 560 }}
          onChange={(pagination: any) => {
            handleTableChange(pagination);
          }}
          onRow={({ key }: any) => ({
            onClick: () => {
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
          pagination={pagination}
        />
      );
    }}
  </Transfer>
);

export default TableTransfer;
