/**
 * 工艺路线-工序列表详情
 */
import { DetailLayout } from 'layout';
import { BcAttachmentDetail, QrCode, BlIcon } from 'components';
import { Tag, Space, Popover } from 'antd';
import { memo } from 'react';
import { replaceSign } from 'src/utils/constants';
import _, { isEmpty } from 'lodash';
import lookup, { appDict } from 'src/dict';
import moment from 'moment';
import ResourceGroupDetails from 'src/page/knowledgeManagement/basicData/workCenter/resourceGroup/details';

interface Props {
  dataSource: any;
  isWorkOrder?: number;
}

const processRouteMap = appDict.processRoute;
const ProcessDetail = memo((props: Props) => {
  const { dataSource, isWorkOrder = 0 } = props;
  const baseInfo = {
    title: '基本信息',
    items: _.compact([
      {
        label: '顺序号',
        dataIndex: 'processSeq',
      },
      {
        label: '工序号',
        dataIndex: 'processNum',
      },
      {
        label: '工序编号',
        dataIndex: 'processCode',
      },
      {
        label: '工序名称',
        dataIndex: 'processName',
      },
      isWorkOrder === 1 && {
        label: '标识码',
        dataIndex: 'identifier',
        render: (text: string) => {
          return text ? (
            <Space>
              {text}
              <Popover
                placement="rightTop"
                content={
                  <div>
                    <QrCode value={text} />
                    <div style={{ textAlign: 'center' }}>{text}</div>
                  </div>
                }
              >
                <BlIcon type="iconerweima" style={{ fontSize: 20 }} />
              </Popover>
            </Space>
          ) : (
            replaceSign
          );
        },
      },
      {
        label: '工作中心',
        dataIndex: 'workCenterName',
      },
      {
        label: '工序说明',
        dataIndex: 'description',
        render: (value: string) => value || replaceSign,
      },
      {
        label: '文件',
        dataIndex: 'fileIds',
        render: (value: number[]) => {
          return isEmpty(value) ? replaceSign : <BcAttachmentDetail fileIds={value} />;
        },
      },
    ]),
  };
  const produceInfo = {
    column: 1,
    title: '生产信息',
    items: _.compact([
      isWorkOrder && {
        label: '计划开始时间',
        dataIndex: 'plannedStartTime',
        render: (value: number) => {
          return value ? moment(value).format('YYYY/MM/DD HH:mm:ss') : replaceSign;
        },
      },
      isWorkOrder && {
        label: '计划完工时间',
        dataIndex: 'plannedEndTime',
        render: (value: number) => {
          return value ? moment(value).format('YYYY/MM/DD HH:mm:ss') : replaceSign;
        },
      },
      isWorkOrder && {
        label: '实际开始时间',
        dataIndex: 'actualStartTime',
        render: (value: number) => {
          return value ? moment(value).format('YYYY/MM/DD HH:mm:ss') : replaceSign;
        },
      },
      isWorkOrder && {
        label: '实际完工时间',
        dataIndex: 'actualEndTime',
        render: (value: number) => {
          return value ? moment(value).format('YYYY/MM/DD HH:mm:ss') : replaceSign;
        },
      },
      {
        label: 'SOP方案',
        dataIndex: 'sop',
        render: (value: any) => value?.name || replaceSign,
      },
      {
        label: '报告模板',
        dataIndex: 'reportTemplates',
        render: (value: any[]) => {
          if (!value || !value.length) {
            return replaceSign;
          }
          return value.map((i) => <Tag>{i?.name}</Tag>);
        },
      },

      {
        label: '计划报工数量管控',
        dataIndex: 'planWorkReportQuantityControl',
        render: lookup('processRoute.PlanType'),
      },
      {
        label: '生产任务状态操作管控',
        dataIndex: 'productionStatusControl',
        render: lookup('processRoute.ProduceType'),
      },
    ]),
  };
  const resource = {
    column: 1,
    title: '资源清单',
    items: [
      {
        label: '',
        isFullLine: true,
        dataIndex: 'workCenterGroupCOList',
        render: (list: any[]) => <ResourceGroupDetails initialData={list} />,
      },
    ],
  };

  return (
    <DetailLayout
      title=""
      info={[baseInfo, produceInfo, resource]}
      dataSource={dataSource}
      baseMenu={[]}
    />
  );
});

export default ProcessDetail;
