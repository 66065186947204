import React, { useState } from 'react';
import { DetailLayout, DetailLayoutMenuItem } from 'layout';
import { Badge, Button, Space } from 'antd';
import { replaceSign } from 'src/utils/constants';
import { avatarDisplay as AvatarDisplay } from 'src/components/avatar/show';
import { CreatorData } from 'src/page/knowledgeManagement/calendarManagement/shift/interface';
import { BlTable } from 'src/components';
import _Time from 'src/utils/dataTypes/time';
import { getAuthFromLocalStorage } from 'src/utils/auth';
import { DetailLayoutInfoBlock } from 'src/layout/detail';
import { OBJECT_CATEGORY_MAP } from '../interface';
import { fetchEReportGetRelationParamsBySubTemplateId } from 'src/api/ytt/e-report-domain/customReport';
import PreviewModal from '../../templateManagement/components/preview';
import _String from 'src/utils/dataTypes/string';
import { FetchMatchRuleQueryMatchRuleDetailResponse } from 'src/api/ytt/e-report-domain/matchRule';
import { STATUS_ENUM } from '../../templateManagement/constant';

interface DetailBaseInfoProps {
  detail: FetchMatchRuleQueryMatchRuleDetailResponse['data'];
  baseMenu?: DetailLayoutMenuItem[];
}

export const DetailBaseInfo = ({ detail, baseMenu }: DetailBaseInfoProps) => {
  const [initLineData, setInitLineData] = useState({} as any);
  const [checkData, setCheckData] = useState({} as any);

  const [visiblePreview, setVisiblePreview] = useState<boolean>(false);

  const getRelationParamsBySubTemplateId = async (templateId: number) => {
    try {
      const { data } = await fetchEReportGetRelationParamsBySubTemplateId({ templateId });

      setInitLineData({ cptRelatedParam: data, templateId });

      setVisiblePreview(true);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '规则编号',
        dataIndex: 'code',
        render: (code: string) => code ?? replaceSign,
      },
      {
        label: '规则名称',
        dataIndex: 'name',
        render: (name: string) => name ?? replaceSign,
      },
      {
        label: '对象类别',
        dataIndex: 'objectCategory',
        render: (objectCategory: number) => OBJECT_CATEGORY_MAP.get(objectCategory),
      },
      {
        label: '启用状态',
        dataIndex: 'status',
        render: (status: number) => {
          const useStatusMap = new Map([
            [STATUS_ENUM.ENABLED, <Badge text="启用中" status="success" />],
            [STATUS_ENUM.DISABLED, <Badge text="停用中" status="error" />],
          ]);

          return useStatusMap.get(status);
        },
      },
      {
        label: '业务对象',
        dataIndex: 'objectName',
        render: (objectName: string) => objectName ?? replaceSign,
      },
      {
        label: '备注',
        dataIndex: 'remark',
        render: (remark: string) => (!_String.isEmpty(remark) ? remark : replaceSign),
      },
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: CreatorData) => {
          return (
            <Space>
              <AvatarDisplay
                id={creator?.id}
                name={creator?.name}
                avatarUrl={creator?.avatarUrl}
                key={creator?.id}
                isShowTag={false}
                isUser
              />
            </Space>
          );
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: (createdAt: Date) => {
          if (!createdAt) return replaceSign;
          return _Time.format(createdAt);
        },
      },
      {
        label: '更新人',
        dataIndex: 'modifier',
        render: (modifier: CreatorData) => {
          return (
            <Space>
              <AvatarDisplay
                id={modifier?.id}
                name={modifier?.name}
                avatarUrl={modifier?.avatarUrl}
                key={modifier?.id}
                isShowTag={false}
                isUser
              />
            </Space>
          );
        },
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: (updatedAt: Date) => {
          if (!updatedAt) return replaceSign;
          return _Time.format(updatedAt);
        },
      },
    ],
  };

  const dataColumns = [
    {
      title: '序号',
      width: 150,
      dataIndex: 'sequenceNumber',
      render: (_: any, record: any, index: number) => {
        return index + 1;
      },
    },
    {
      title: '电子单据模板编号',
      width: 300,
      dataIndex: 'templateCode',
    },
    {
      title: '电子单据模板名称',
      width: 300,
      dataIndex: 'templateName',
    },
    {
      title: '操作',
      width: 150,
      dataIndex: 'operate',
      render: (_: any, record: any) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setCheckData(record);
              getRelationParamsBySubTemplateId(record?.templateId);
            }}
          >
            预览
          </Button>
        );
      },
    },
  ];

  const matchInfo: DetailLayoutInfoBlock = {
    title: '电子单据模板',
    items: [
      {
        label: '',
        dataIndex: 'matchRuleObjectTemplateDTOList',
        render: () => {
          return (
            <div
              style={{
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <BlTable
                scroll={{ x: 600 }}
                columns={dataColumns}
                dataSource={detail?.subMatchRuleDTOList?.[0]?.matchRuleObjectTemplateDTOList}
                style={{ width: '67%' }}
                rowKey={(record) => record?.id!}
              />
            </div>
          );
        },
      },
    ],
  };

  return (
    <>
      <DetailLayout
        userAuth={getAuthFromLocalStorage()}
        title="对象匹配规则详情"
        info={[baseInfo, matchInfo]}
        dataSource={detail}
        baseMenu={baseMenu}
      />
      {visiblePreview && initLineData && (
        <PreviewModal
          visible={visiblePreview}
          initData={initLineData}
          onClose={() => setVisiblePreview(false)}
          templateId={checkData?.templateId}
          operationType={'noApprove'}
        />
      )}
    </>
  );
};
