import { BomResponseMaterial } from 'src/page/knowledgeManagement/engineerData/bom/index.type';

// 计划订单
export const ENGINEER_PATH = '/planned/execution';

type WorkOrderDto = {
  id: string;
  orderCode: string;
  lineNo: number;
  deliveryDate: number;
  amount: number;
  material: BomResponseMaterial;
};

/**
 * 创建计划订单
 * @returns string
 */
//  : string | Parameters<RouteComponentProps['history']['push']>
export const toCreatePlannedOrder = (state?: WorkOrderDto) => {
  const path = `${ENGINEER_PATH}/plannedOrder/create`;

  if (!state) {
    return { pathname: path };
  }
  // const args = [path, state];

  return { pathname: `${path}`, state };
  // return path, state;
};

/**
 * 编辑计划订单
 * @param id
 * @returns string
 */
export const toEditPlannedOrder = (id: number) => `${ENGINEER_PATH}/plannedOrder/edit/${id}`;

/**
 * 查看计划订单
 * @param id
 * @returns string
 */
export const toDetailPlannedOrder = (id: number) => `${ENGINEER_PATH}/plannedOrder/detail/${id}`;

/**
 * 计划订单 列表
 * @returns string
 */
export const toPlannedOrderList = () => `${ENGINEER_PATH}/plannedOrder`;

/**
 * 投放日志
 * @returns
 */
export const toLogPlannedOrder = () => `${ENGINEER_PATH}/plannedOrder/log`;

/**
 * 投放日志详情
 * @param id
 * @returns
 */
export const toLogDetailPlannedOrder = (id: number) =>
  `${ENGINEER_PATH}/plannedOrder/log/detail/${id}`;

/**
 * 物料排程设置列表
 * @returns
 */
export const toMaterialScheduling = () => `${ENGINEER_PATH}/plannedOrder/materialScheduling`;
