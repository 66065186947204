import React from 'react';
import type { FC } from 'react';
import { BlTable } from 'src/components';
import type { ColumnProps } from 'antd/lib/table';
import type { FormListFieldData } from 'antd/lib/form/FormList';

interface Props {
  dataSource: {}[];
}

export const DepartmentDetailtable: FC<Props> = (props) => {
  const { dataSource } = props;
  const columns: ColumnProps<any & FormListFieldData>[] = [
    {
      title: '序号',
      dataIndex: 'lineNo',
      fixed: 'left',
      width: 120,
      render: (text, _record, index) => index + 1,
    },
    {
      title: '部门编号',
      dataIndex: 'code',
      fixed: 'left',
      width: 120,
    },
    {
      title: '部门名称',
      dataIndex: 'name',
      fixed: 'left',
      width: 120,
    },
  ];

  return (
    <BlTable
      scroll={{ x: 'max-content', y: 'auto' }}
      dataSource={dataSource?.departmentList}
      columns={columns}
      rowKey="id"
    />
  );
};
