import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import qs from 'qs';
import { FilterItem, RecordListLayout } from 'layout';
import { fetchInboundOrderListOperateRecord } from 'src/api/ytt/inventory-domain/boundOrder';
import { fieldTypeList, gcObject, gcTime } from 'src/utils';
import { SearchSelect, BlIcon } from 'src/components';
import { inSourceTypeEnum, recordInExecuteTypeEnum } from 'src/dict/warehouse/mappings';
import { qcStatusEnum } from 'src/dict/quality/mappings';
import { replaceSign } from 'src/utils/constants';
import { lookup } from 'src/dict';
import { AvatarDisplay } from 'src/components/avatar/show';
import _Time from 'src/utils/dataTypes/time';
import { fetchFile } from 'src/utils/files';
import moment, { Moment } from 'moment';
import { Modal } from 'antd';
import FileViewer from 'src/components/fileViewer';
import { useOpenExImportModal } from 'src/components/excelBatchOption/utiles';
import { useDispatch } from 'react-redux';
import { BUSINESS_TYPE } from 'src/dict/objImport';
import authDict from 'src/utils/auth';
import { OBJECT_OF_CODE } from 'src/entity/objectPlatform';
import { fetchCustomFieldInstanceGetByObjectCode } from 'src/api/ytt/custom-object-domain/custom_field';
import { injectCustomFieldColumns } from 'src/components/customField';

const EntryRecordList: React.FC = () => {
  const [itemId, setItemId] = useState(qs.parse(window.location.search.slice(1))?.itemId);
  const [fileViewVisible, setFileViewVisible] = useState(false);
  const [filePathList, setFilePathList] = useState<string[]>([]);
  const openModal = useOpenExImportModal();
  const dispatch = useDispatch();
  const [customFields, setCustomFields] = useState<any>();
  const dataColumns: any[] = [
    {
      title: '入库单编号',
      dataIndex: 'inboundOrderCode',
      width: 160,
    },
    {
      title: '来源类型',
      dataIndex: 'sourceType',
      width: 160,
      isFilter: true,
      type: fieldTypeList?.select,
      selectProps: {
        options: inSourceTypeEnum,
      },
      render: (sourceType: number) => {
        return <span>{lookup('warehouse.inSourceTypeEnum', sourceType)}</span>;
      },
    },
    {
      title: '入库单类型',
      dataIndex: 'bizType',
      width: 160,
      render: (bizType: number) => {
        return <span>{lookup('bound.InBoundType', bizType)}</span>;
      },
    },
    {
      title: '业务类型',
      dataIndex: 'entityType',
      width: 150,
      isFilter: true,
      type: fieldTypeList?.select,
      selectProps: {
        options: recordInExecuteTypeEnum,
      },
      render: (entityType: number) => {
        return <span>{lookup('warehouse.recordInExecuteTypeEnum', entityType)}</span>;
      },
    },
    {
      title: '物料编号',
      dataIndex: 'materialCode',
      width: 200,
      render: (text: unknown, record: any) => {
        return _.get(record, 'material.code', replaceSign);
      },
    },
    {
      title: '物料名称',
      dataIndex: 'materialName',
      width: 200,
      isFilter: true,
      type: fieldTypeList?.multiSelect,
      renderItem: <SearchSelect fetchType={'materialCodeName'} mode={'multiple'} />,
      render: (text: unknown, record: any) => {
        return _.get(record, 'material.name', replaceSign);
      },
    },
    {
      title: '仓库',
      dataIndex: 'warehouse',
      width: 180,
      isFilter: true,
      disabled: true,
      type: fieldTypeList?.multiSelect,
      renderItem: <SearchSelect fetchType={'warehouse'} mode="multiple" />,
    },
    {
      title: '仓位编号',
      dataIndex: 'storageCode',
      width: 180,
      defaultColConfig: {
        display: false,
      },
      render: (text: unknown, record: any) => {
        return _.get(record, 'storageLocation.storage.code', replaceSign);
      },
    },
    {
      title: '仓位名称',
      dataIndex: 'storageName',
      width: 180,
      isFilter: true,
      type: fieldTypeList?.multiSelect,
      renderItem: <SearchSelect fetchType={'storageLocation'} mode={'multiple'} />,
      render: (text: unknown, record: any) => {
        return _.get(record, 'storageLocation.storage.name', replaceSign);
      },
    },
    {
      title: '批次号',
      dataIndex: 'batchNo',
      width: 200,
      sorter: true,
      isFilter: true,
      type: fieldTypeList?.text,
      render: (text: unknown, record: any) => {
        return _.get(record, 'bizKsyAttr.batchNo', replaceSign);
      },
    },
    {
      title: '质量状态',
      dataIndex: 'qcStatus',
      width: 120,
      isFilter: true,
      type: fieldTypeList?.multiSelect,
      selectProps: {
        options: qcStatusEnum,
      },
      render: (qcStatus: number) => {
        return <span>{lookup('quality.qcStatusEnum', qcStatus)}</span>;
      },
    },
    {
      title: '数量',
      dataIndex: 'amount',
      width: 120,
      render: (text: unknown, record: any) => {
        return <span>{_.get(record, 'amount.amount.amount', replaceSign)}</span>;
      },
    },
    {
      title: '入库单位',
      dataIndex: 'unit',
      width: 120,
      render: (text: unknown, record: any) => {
        return <span>{_.get(record, 'amount.amount.unit.name', replaceSign)}</span>;
      },
    },
    {
      title: '辅助数量',
      dataIndex: 'amount1',
      defaultColConfig: {
        display: false,
      },
      width: 120,
      render: (text: unknown, record: any) => {
        return <span>{_.get(record, 'amount.auxAmountList[0].amount', replaceSign)}</span>;
      },
    },
    {
      title: '辅助单位',
      dataIndex: 'unit1',
      defaultColConfig: {
        display: false,
      },
      width: 120,
      render: (text: unknown, record: any) => {
        return <span>{_.get(record, 'amount.auxAmountList[0].unit.name', replaceSign)}</span>;
      },
    },
    {
      title: '标识码',
      dataIndex: 'qrCode',
      width: 180,
      sorter: true,
      isFilter: true,
      type: fieldTypeList?.text,
    },
    {
      title: '供应商',
      dataIndex: 'supplier',
      width: 200,
      isFilter: true,
      type: fieldTypeList?.select,
      render: (text: unknown, record: any) => {
        return _.get(record, 'bizKsyAttr.supplier.name', replaceSign);
      },
      renderItem: <SearchSelect mode="multiple" fetchType={'supplier'} />,
    },
    {
      title: '供应商批次',
      dataIndex: 'supplierBatchNo',
      width: 200,
      render: (text: unknown, record: any) => {
        return _.get(record, 'bizKsyAttr.supplierBatchNo', replaceSign);
      },
    },
    {
      title: '客户',
      dataIndex: 'customer',
      width: 200,
      render: (text: unknown, record: any) => {
        return _.get(record, 'bizKsyAttr.customer.name', replaceSign);
      },
    },
    {
      title: '生产日期',
      dataIndex: 'productTime',
      width: 180,
      render: (text: unknown, record: any) => {
        const productTime = _.get(record, 'bizKsyAttr.productTime', 0);

        if (!productTime) return replaceSign;
        return _Time.format(productTime);
      },
    },
    {
      title: '保质期至',
      dataIndex: 'validityPeriod',
      width: 180,
      render: (text: unknown, record: any) => {
        const validityPeriod = _.get(record, 'bizKsyAttr.validityPeriod', 0);

        if (!validityPeriod) return replaceSign;
        return _Time.format(validityPeriod);
      },
    },
    {
      title: '入厂日期',
      dataIndex: 'inboundTime',
      width: 180,
      render: (text: unknown, record: any) => {
        const inboundTime = _.get(record, 'bizKsyAttr.inboundTime', 0);

        if (!inboundTime) return replaceSign;
        return _Time.format(inboundTime);
      },
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 160,
      sorter: true,
      isFilter: true,
      type: fieldTypeList?.select,
      renderItem: <SearchSelect fetchType={'user'} />,
      render: (text: unknown, record: any) => {
        const creator = _.get(record, 'operator', {});

        if (_.isEmpty(creator)) return replaceSign;
        return <AvatarDisplay {...creator} key={creator?.id} isShowTag isUser />;
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 180,
      sorter: true,
      isFilter: true,
      type: fieldTypeList.date,
      render: (text: unknown, record: any) => {
        const createdAt = _.get(record, 'operateTime', 0);

        if (!createdAt) return replaceSign;
        return _Time.format(createdAt);
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 200,
    },
    {
      title: '附件',
      dataIndex: 'attachmentIdList',
      width: 200,
      render: (attachmentIdList: number[]) => {
        return (
          <a
            onClick={async () => {
              const filePathList = await fetchFile(attachmentIdList);

              if (filePathList) {
                setFilePathList(filePathList);
                setFileViewVisible(true);
              }
            }}
          >
            查看
          </a>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      width: 60,
      fixed: 'right',
      render: () => <div />,
    },
  ];

  const config = {
    rowKey: (record: any) => String(record.id),
    mainMenu: [
      {
        title: '导出',
        icon: <BlIcon type="icondaochu" />,
        showExport: true,
        auth: authDict.inboundrecord_export,
        onClick: () =>
          openModal({
            optType: 'export',
            businessType: BUSINESS_TYPE.inboundOrderRecord,
          }),
      },
    ],
    columns: injectCustomFieldColumns({
      columns: dataColumns?.filter((d) => !d?.disabled), // 原本的列
      customFields, // 自定义字段信息
      objectCode: OBJECT_OF_CODE.inboundRecord, // 从对象code
      type: 'detail', // 使用类型
    }),
    filterList: dataColumns
      .filter((i) => i.isFilter)
      .map((columns: any): FilterItem => {
        const filter = {
          label: columns.title,
          name: columns.dataIndex,
          ...columns,
        };

        return filter;
      }),
    formatDataToFormDisplay: (filterData: any) => {
      const { createdAt, ...rest } = filterData;

      const result = {
        ...rest,
        createdAt: gcTime.formatRangeTimeToMoment(createdAt || []),
      };

      return gcObject.filterEmptyProperty(result);
    },
    formatDataToQuery: (params: any) => {
      const {
        createdAt,
        creator,
        storageName,
        materialName,
        supplier,
        entityType,
        sorter,
        warehouse,
        ...rest
      } = params;
      const [createdAtFrom, createdAtTill] = createdAt?.map((time: Moment) =>
        moment(time).format('x'),
      ) || [undefined, undefined];

      const _options = {
        ...rest,
        creator: creator?.value,
        materialId: materialName?.map((item: any) => item.value),
        locationId: storageName?.map((item: any) => item.value),
        warehouseIds: warehouse?.map((item: any) => item?.value),
        supplierIds: _.map(supplier, 'value'),
        createdAtFrom,
        createdAtTill,
        inboundOrderItemId: itemId,
        recordEntityType: entityType,
        sorter: sorter?.map((sort: { field: string; order: string }) => {
          let _field = sort?.field;

          if (_field === 'creator') {
            _field = 'creatorId';
          }

          return {
            ...sort,
            field: _field,
          };
        }),
      };

      setItemId(undefined);

      const options = gcObject.filterEmptyProperty(_options);

      dispatch.excelImport.updateBusinessData({ businessData: options });
      return options;
    },
  };

  /** 获取对象自定义字段 */
  const fetchCustomFields = async () => {
    const res = await fetchCustomFieldInstanceGetByObjectCode({
      objectCode: OBJECT_OF_CODE.inboundRecord,
    });

    setCustomFields((res?.data as any) || {});
  };

  useEffect(() => {
    fetchCustomFields();
  }, []);

  return (
    <>
      <RecordListLayout<any>
        placeholder={'输入标识码，回车快速查询'}
        configcacheKey="adjustRecordList"
        {...config}
        requestFn={(params) => fetchInboundOrderListOperateRecord(params)}
      />
      {fileViewVisible && (
        <Modal
          title={'查看附件'}
          width={900}
          visible={fileViewVisible}
          footer={null}
          onCancel={() => setFileViewVisible(false)}
        >
          <FileViewer fileType={'png'} filePathList={filePathList} />
        </Modal>
      )}
    </>
  );
};

export default EntryRecordList;
