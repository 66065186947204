import { useEffect, useState } from 'react';
import { Avatar, Form, message, Space, Tooltip } from 'antd';
import _ from 'lodash';

import { avatarStyle } from 'src/page/organization/userManagement/constants';
import { DetailLayout, DetailLayoutInfoBlock } from 'layout';
import { BcMultilineText, BlIcon } from 'src/components';
import {
  fetchTriggerActionDetail,
  fetchTriggerActionDisable,
  fetchTriggerActionEnable,
} from 'src/api/ytt/trigger-domain/triggerAction';
import authDict from 'src/utils/auth';

import { gcTime } from 'src/utils';
import { DetailLayoutMenuItem } from 'src/layout/detail/DetailLayout.type';
import { USER_STATUS_ENUM } from 'src/dict/user';
import lookup, { appEnum } from 'src/dict';
import { CRUD } from 'src/dict/common';
import renderUsageStatus from 'src/page/share/renderUsageStatus';

import { getEditPageUrl, getCreatePageUrl } from './utils/navigation';
import { formatToDetailData } from './utils/formatToDetailData';
import ObjectConfigEditTable from './components/ObjectConfigEditTable';
import { formatToFormData } from './utils/formatToFormData';
import { replaceSign } from 'src/utils/constants';
import { isBusinessAction, isGeneralAction, FetchTriggerActionDetailData } from './constant';
import RuleConditionEditTable from 'src/page/organization/triggerRule/components/RuleConditionEditTable/detail';
import { fetchTriggerRuleDetail } from 'src/api/ytt/trigger-domain/triggerRule';

const Detail = (props: any) => {
  const [statusType, setStatusType] = useState<boolean>(false);
  const {
    history,
    match: {
      params: { id, actionSource },
    },
  } = props;

  const [dataSource, setDataSource] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [actionConfig, setActionConfig] = useState<DetailLayoutInfoBlock>({
    title: '事件配置',
    column: 1,
    items: [
      { label: '事件类型', dataIndex: 'actionType', isFullLine: true },
      {
        label: '更新对象来源',
        dataIndex: 'instanceSourceType',
        isFullLine: true,
        render: (value) => {
          return lookup('triggerAction.instanceSourceTypeOptions', value);
        },
      },
      {
        label: '关联对象',
        dataIndex: 'objectFieldPathName',
        isFullLine: true,
        render: (value) => {
          return value || replaceSign;
        },
      },
      {
        label: '业务对象',
        dataIndex: 'objectName',
        isFullLine: true,
      },
      {
        label: (
          <Space style={{ gap: 0 }}>
            <span>记录筛选条件</span>
            <Tooltip title="配置筛选条件来筛选需要更新的对象记录">
              <BlIcon type="iconwentizhiyinbangzhu" className="bl-title-icon" />
            </Tooltip>
          </Space>
        ),
        dataIndex: 'instanceFilterCondition',
        isFullLine: true,
        render: (value, dataSource) => {
          if (!value) return replaceSign;
          return (
            <RuleConditionEditTable
              useParent="triggerAction"
              disabled
              data={formatToFormData(dataSource)}
            />
          );
        },
      },
      {
        label: '对象属性值配置',
        dataIndex: 'details',
        isFullLine: true,
        render: () => (
          <Form form={form}>
            <ObjectConfigEditTable
              form={form}
              namePath="details"
              disabled
              objectCode={dataSource.objectCode}
            />
          </Form>
        ),
      },
    ],
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      fetchTriggerActionDetail({ actionId: Number(id) })
        .then((res) => {
          if (res.code === 200 && res.data) {
            const instanceSourceType = res.data.instanceSourceType;

            if (res.data.actionType === appEnum.TriggerAction.ActionTypeEnum.createRecord) {
              const actionConfigItems = actionConfig.items;

              setActionConfig({
                ...actionConfig,
                items: actionConfigItems.filter(
                  (item) =>
                    ['actionType', 'objectName', 'details'].indexOf(String(item.dataIndex)) >= 0,
                ),
              });
            }
            if (
              res.data.actionType === appEnum.TriggerAction.ActionTypeEnum.updateRecord &&
              (res.data.instanceSourceType ===
                appEnum.TriggerAction.InstanceSourceTypeEnum.manualSelectBizObject ||
                res.data.instanceSourceType ===
                  appEnum.TriggerAction.InstanceSourceTypeEnum.triggerRuleBizObject)
            ) {
              const actionConfigItems = actionConfig.items;

              setActionConfig({
                ...actionConfig,
                items: actionConfigItems.filter((item) => item.dataIndex !== 'objectFieldPathName'),
              });
            }
            // 如果更新对象来源是触发规则关联对象或触发规则对象，则需要去拉取触发规则详情页中的条件
            if (
              instanceSourceType ===
                appEnum.TriggerAction.InstanceSourceTypeEnum.triggerRuleBizObjectAssociatedObject ||
              instanceSourceType ===
                appEnum.TriggerAction.InstanceSourceTypeEnum.triggerRuleBizObject
            ) {
              fetchTriggerRuleDetail({ ruleId: res?.data.ruleId }).then((res2) => {
                if (res2.code === 200 && res2.data) {
                  const newData: FetchTriggerActionDetailData = {
                    ...res.data,
                    instanceFilterCondition: res2.data.triggerCondition,
                  };

                  setDataSource(formatToDetailData(newData));
                  form.setFieldsValue(formatToFormData(newData));
                }
              });
            } else {
              setDataSource(formatToDetailData(res.data));
              form.setFieldsValue(formatToFormData(res.data));
            }
            setStatusType(res.data?.status === USER_STATUS_ENUM.enable);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [statusType]);

  const getAbleMenuItem = (status: boolean) => {
    return status
      ? {
          key: 'enable',
          title: lookup('common.crud', CRUD.enable),
          auth: authDict.triggeraction_enable_disable,
          onClick: () => {
            fetchTriggerActionEnable({ actionIds: [Number(id)] }).then((res) => {
              if (res.code === 200) {
                const { failCount, failReasons } = res.data;

                if (failCount > 0) {
                  message.error('启用失败');
                  message.error(failReasons[0]);
                } else {
                  message.success('启用成功');
                  setStatusType(!statusType);
                }
              }
            });
          },
        }
      : {
          key: 'disable',
          title: lookup('common.crud', CRUD.disable),
          auth: authDict.triggeraction_enable_disable,
          onClick: () => {
            fetchTriggerActionDisable({ actionIds: [Number(id)] }).then((res) => {
              if (res.code === 200) {
                const { failCount, failReasons } = res.data;

                if (failCount > 0) {
                  message.error('停用失败');
                  message.error(failReasons[0]);
                } else {
                  message.success('停用成功');
                  setStatusType(!statusType);
                }
              }
            });
          },
        };
  };

  const baseMenu: DetailLayoutMenuItem[] = isGeneralAction(
    actionSource,
    _.compact([
      getAbleMenuItem(dataSource?.status === 0),
      {
        key: 'copy',
        title: lookup('common.crud', CRUD.copy),
        auth: authDict.triggeraction_add,
        onClick: () => history.push(getCreatePageUrl(id)),
      },
      {
        key: 'edit',
        title: lookup('common.crud', CRUD.edit),
        disabled: dataSource?.status === appEnum.Common.UsageStatus.enabled,
        auth: authDict.triggeraction_edit,
        onClick: () => history.push(getEditPageUrl(id)),
      },
    ]),
  );

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    column: 2,
    items: [
      { label: '事件名称', dataIndex: 'name' },
      { label: '事件编号', dataIndex: 'code' },
      { label: '启用状态', dataIndex: 'status', render: renderUsageStatus },
      { label: '触发规则', dataIndex: 'ruleName', render: (text) => text ?? replaceSign },
      {
        label: '事件来源',
        dataIndex: 'sourceType',
        render: (sourceType) => lookup('triggerAction.actionSourceType', sourceType),
      },
      ...isBusinessAction(actionSource, [
        { label: '来源对象', dataIndex: 'bizSourceObjectName' },
        { label: '来源对象实例', dataIndex: 'bizSourceInstanceCode' },
      ]),
      {
        label: '备注',
        dataIndex: 'remark',
        render: (text: string) => <BcMultilineText text={text} needWordCount />,
        isFullLine: true,
      },
    ],
  };

  const otherInfo: DetailLayoutInfoBlock = {
    title: '其他信息',
    column: 2,
    items: [
      {
        label: '创建人',
        dataIndex: 'creator',
        render: (creator: any) => {
          return !creator ? (
            replaceSign
          ) : (
            <span>
              {creator?.avatarUrl ? (
                <Avatar size={24} src={creator?.avatarUrl} />
              ) : (
                <Avatar icon={<BlIcon type="icongeren" />} size={24} style={avatarStyle} />
              )}
              {creator?.name}
            </span>
          );
        },
      },
      {
        label: '创建时间',
        dataIndex: 'createdAt',
        render: (time: number) => gcTime.formatLine(time),
      },
      {
        label: '更新人',
        dataIndex: 'operator',
        render: (operator: any) => {
          return !operator ? (
            replaceSign
          ) : (
            <span>
              {operator?.avatarUrl ? (
                <Avatar size={24} src={operator?.avatarUrl} />
              ) : (
                <Avatar icon={<BlIcon type="icongeren" />} size={24} style={avatarStyle} />
              )}
              {operator?.name}
            </span>
          );
        },
      },
      {
        label: '更新时间',
        dataIndex: 'updatedAt',
        render: (time: number) => gcTime.formatLine(time),
      },
    ],
  };

  return (
    <>
      <DetailLayout
        baseMenu={baseMenu}
        title={'触发事件详情'}
        info={[baseInfo, actionConfig, otherInfo]}
        dataSource={dataSource}
        loading={loading}
      />
    </>
  );
};

export default Detail;
