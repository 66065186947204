import { useState, useEffect } from 'react';
import { Form, Input, InputNumber, message } from 'antd';
import { useLocation, useParams, RouteComponentProps } from 'react-router-dom';
import { DataFormLayout, DataFormLayoutInfoBlock } from 'layout';
import { BlSortFormList } from 'src/components';
import { replaceSign } from 'src/utils/constants';
import {
  fetchInboundOrderDetail,
  fetchInboundOrderExecuteRevert,
} from 'src/api/ytt/inventory-domain/boundOrder';
import { InboundDetailType, InboundMaterialRowType } from '../../type';
import SelectInventory from './selectInventory';
import _, { isEmpty } from 'lodash';
import qs from 'qs';
import { ColumnProps } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { validateDecimalLength } from 'src/page/warehouseManagement/utils';

interface DetailPageProps extends RouteComponentProps {}

const WriteOff = (props: DetailPageProps) => {
  const { history } = props;
  const [form] = Form.useForm();
  const name = 'executeItemList';
  const { search } = useLocation();
  const { id } = useParams<{ id: string }>();
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  const [dataSource, setDataSource] = useState<InboundDetailType>({});
  const [material, setMaterial] = useState<InboundMaterialRowType>({});
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      const { data = {} } = await fetchInboundOrderDetail({ inboundOrderId: Number(id) });

      setDataSource(data);
      setMaterial(_.find(data?.items, ['id', Number(query.id)]) || {});
    } catch (err) {
      console.log(err);
    }
  };
  const getColumns = (): ColumnProps<any & FormListFieldData>[] => {
    return [
      {
        dataIndex: 'lineNo',
        title: '行号',
        width: 80,
        render: (text: string, field) => field.name + 1,
      },
      {
        dataIndex: 'batchNo',
        title: '批次',
        width: 150,
        render: (text: string, field) => {
          const { bizKey = {} } = selectedRows?.[field.name] || {};

          return bizKey?.batchNo;
        },
      },
      {
        dataIndex: 'qrCode',
        title: '标识码',
        width: 150,
        render: (text: string, field) => selectedRows?.[field.name]?.qrCode,
      },
      {
        dataIndex: 'storage',
        title: '仓储位置',
        width: 150,
        render: (text: string, field) => {
          const { location = {} } = selectedRows?.[field.name] || {};

          return location?.storage?.name;
        },
      },
      {
        dataIndex: 'amount',
        title: '现有数量',
        width: 200,
        render: (text: string, field) => {
          const { opAmount = {} } = selectedRows?.[field.name] || {};
          const { amount = {}, auxAmounts = [] } = opAmount;

          return (
            <div key={field.name}>
              <span>
                {amount.amount}
                {amount.unit?.name}
              </span>
              {auxAmounts.map((aux: any) => (
                <span>
                  , {aux.amount}
                  {aux.unit?.name}
                </span>
              ))}
            </div>
          );
        },
      },
      {
        dataIndex: 'executeAmount',
        title: '冲销数量',
        width: 200,
        render: (text: string, field) => {
          const { opAmount = {} } = selectedRows?.[field.name] || {};
          const { amount = {}, auxAmounts = [] } = opAmount;

          return (
            <div style={{ display: 'flex' }} key={field.name}>
              <div style={{ display: 'flex', verticalAlign: 'center' }}>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  rules={[
                    { required: true, message: '请输入数量' },
                    {
                      type: 'number',
                      min: 0,
                      transform: (value) => Number(value),
                      message: '不能输入负数',
                    },
                    { validator: validateDecimalLength(amount?.unit?.precisionFigure) },
                  ]}
                  name={[field.name, 'executeAmount', 'amount']}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  name={[field.name, 'executeAmount', 'unitId']}
                  hidden
                >
                  <Input />
                </Form.Item>
                <div
                  style={{ whiteSpace: 'nowrap', lineHeight: '30px', height: 30, margin: '0 5px' }}
                >
                  {amount.unit?.name}
                </div>
              </div>
              {auxAmounts.map((aux: any, key: number) => (
                <div style={{ marginLeft: 10, display: 'flex', verticalAlign: 'center' }}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    rules={[
                      { required: true, message: '请输入数量' },
                      {
                        type: 'number',
                        min: 0,
                        transform: (value) => Number(value),
                        message: '不能输入负数',
                      },
                      { validator: validateDecimalLength(aux?.unit?.precisionFigure) },
                    ]}
                    name={[field.name, 'executeAmount', 'auxAmounts', key, 'amount']}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name={[field.name, 'executeAmount', 'auxAmounts', key, 'unitId']}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      lineHeight: '30px',
                      height: 30,
                      margin: '0 5px',
                    }}
                  >
                    {aux.unit?.name}
                  </div>
                </div>
              ))}
            </div>
          );
        },
      },
    ];
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const baseInfo: DataFormLayoutInfoBlock = {
    title: '冲销信息',
    column: 2,
    items: [
      { label: '入库单编号', render: () => dataSource.code },
      {
        label: '行号',
        render: () => material?.lineNo,
      },
      {
        label: '冲销物料',
        render: () => {
          const { baseInfo = {} } = material?.material || {};

          return `${baseInfo.code} / ${baseInfo.name}`;
        },
      },
      {
        label: '仓库',
        render: () => dataSource?.wareHouse?.name ?? replaceSign,
      },
      {
        label: '供应商',
        render: () => dataSource?.supplier?.name ?? replaceSign,
      },
      {
        label: '入库进度',
        render: () => `${material.doneAmount} / ${material.planAmount} ${material.unit?.name}`,
      },
      {
        label: '备注',
        isFullLine: true,
        name: 'remark',
        rules: [{ max: 1000, message: '不超过1000个字符' }],
        render: () => <Input.TextArea placeholder="请输入备注" showCount maxLength={1000} />,
      },
    ],
  };

  const inventory: DataFormLayoutInfoBlock = {
    title: '冲销库存列表',
    column: 2,
    items: [
      {
        label: '',
        isFullLine: true,
        render: () => (
          <BlSortFormList
            buttonText="添加库存"
            form={form}
            name={name}
            renderColumns={() => getColumns()}
            handleAdd={() => setVisible(true)}
          />
        ),
      },
    ],
  };

  const onFinish = async () => {
    const { executeItemList, ...rest } = await form.validateFields();

    if (isEmpty(executeItemList)) {
      message.error('请添加冲销库存！');
      return;
    }

    try {
      setLoading(true);
      const res = await fetchInboundOrderExecuteRevert({
        inboundOrderId: Number(id),
        inboundOrderItemId: Number(query.id),
        executeItemList,
        ...rest,
      });

      setLoading(false);
      if (res && res.code === 200) {
        message.success('冲销成功！');
        history.goBack();
      }
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <DataFormLayout
        formLayout="horizontal"
        form={form}
        confirmLoading={loading}
        title="入库冲销"
        info={[baseInfo, inventory]}
        onCancel={() => history.goBack()}
        onFinish={onFinish}
      />
      <SelectInventory
        visible={visible}
        wareHouseId={dataSource?.wareHouse?.id}
        materialId={material?.material?.baseInfo?.id}
        onCancel={() => setVisible(false)}
        onSubmit={(selectRows) => {
          setSelectedRows(selectRows);
          form.setFieldsValue({
            [name]: selectRows?.map((item: any) => {
              const { opAmount = {} } = item;

              return {
                inventoryElementId: item?.id,
                executeAmount: {
                  amount: 0,
                  unitId: opAmount.amount?.unit?.id,
                  auxAmounts: opAmount.auxAmounts?.map((aux: any) => {
                    return {
                      amount: 0,
                      unitId: aux?.unit?.id,
                    };
                  }),
                },
              };
            }),
          });
          setVisible(false);
        }}
      />
    </>
  );
};

export default WriteOff;
