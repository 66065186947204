import React, { useEffect, useState } from 'react';
import { match, RouteComponentProps } from 'react-router-dom';
import authDict, { getAuthFromLocalStorage } from 'src/utils/auth';
import { DetailLayout } from 'layout';
import { replaceSign } from 'src/utils/constants';
import { DetailLayoutInfoBlock } from 'src/layout/detail';
import ConditionRender from '../templateManagement/filter/detailContainer';
import { formatDetailCondition } from '../templateManagement/utils';
import {
  fetchEReportInstanceEReportInstanceDetail,
  FetchEReportInstanceEReportInstanceDetailResponse,
} from 'src/api/ytt/e-report-domain/electronicDocuments';
import { renderElectronicValueDisplay, submitApprove } from './utils';
import { ApprovalStatus } from 'src/dict/approval';
import _Array from 'src/utils/dataTypes/array';
import Report from '../templateManagement/components/preview/report';
import { map } from 'lodash';
import { cptRelatedParamType } from './interface';
import { SYMBOL_MAP } from '../templateManagement/constant';

interface Props extends RouteComponentProps {
  match: match<{ id: string }>;
}

export default function ElectronicDocumentsDetail(props: Props) {
  const { match } = props;
  const instanceId = Number(match?.params?.id);

  const [dataSource, setDetail] = useState<
    FetchEReportInstanceEReportInstanceDetailResponse['data']
  >({} as any);

  const fetchData = async () => {
    try {
      const { data } = await fetchEReportInstanceEReportInstanceDetail({ instanceId });

      if (data) {
        setDetail(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [instanceId]);

  const baseInfo: DetailLayoutInfoBlock = {
    title: '基本信息',
    items: [
      {
        label: '模板编号',
        dataIndex: 'templateCode',
        render: (templateCode: string) => templateCode ?? replaceSign,
      },
      {
        label: '模板名称',
        dataIndex: 'name',
        render: (name: string) => name ?? replaceSign,
      },
    ],
  };

  const filterConditionInfo: DetailLayoutInfoBlock = {
    items: [
      {
        label: '查询条件',
        dataIndex: 'cptRelatedParam',
        render: (cptRelatedParam: cptRelatedParamType[]) => {
          return cptRelatedParam?.map((node: cptRelatedParamType) => {
            return (
              <div>
                {`${node?.dataSetName}.${node?.fieldDisplayName}： ${SYMBOL_MAP.get(
                  node?.condition,
                )}  ${renderElectronicValueDisplay(node)}`}
              </div>
            );
          });
        },
      },
      // {
      //   label: '字段过滤',
      //   dataIndex: 'filterConditionInfoList',
      //   render: (filterConditionInfoList: any) => {
      //     if (_Array.isEmpty(filterConditionInfoList)) return '无';
      //     return map(dataSource?.filterConditionInfoList, (node: any) => {
      //       return <ConditionRender previewFieldData={formatDetailCondition(node)?.[0]} />;
      //     });
      //   },
      // },
    ],
  };

  const previewInfo: DetailLayoutInfoBlock = {
    title: '模板预览',
    items: [
      {
        label: '',
        dataIndex: 'preview',
        render: () => {
          return (
            <div style={{ width: '100%', height: '100%', padding: 24, position: 'relative' }}>
              <Report previewUrl={dataSource?.previewUrl ?? ''} params={dataSource?.params} />
            </div>
          );
        },
      },
    ],
  };

  const baseMenu = [
    {
      key: 'submit',
      auth: authDict.eReportCase_approve,
      disabled: dataSource?.approvalStatus !== ApprovalStatus.rejected,
      onClick: () => {
        submitApprove(instanceId, () => {
          fetchData();
        });
      },
      title: '提交审批',
    },
  ];

  return (
    <DetailLayout
      baseMenu={baseMenu}
      userAuth={getAuthFromLocalStorage()}
      title="电子单据详情"
      info={[baseInfo, filterConditionInfo, previewInfo]}
      dataSource={dataSource}
    />
  );
}
